import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import './styles.scss'
import Config from '../../../../../config'
import _ from 'lodash'
import FliptInput from '../../../../../components/form/fliptInput'
import FliptDatePicker from '../../../../../components/form/fliptDatePicker'
import { Creators as AppActions } from '../../../../../redux/reducers/app'
import { Creators as AutoSuggestActions } from '../../../../../redux/reducers/api/autoSuggest'
import { Creators as PriorAuthorizationCreationActions } from '../../../../../redux/reducers/api/priorAuthorizationManagement'
import { Creators as RPMActions } from '../../../../../redux/reducers/rpm'
import FliptButton from '../../../../../components/form/fliptButton'
import { drugGroupValues } from '../../../../../config/constants/clinical/drugGroups'
import { convertStrToDateObj, addRemoveDaysToDate } from '../../../../../utils/utilities'
import FliptHierarchyDropdown from '../../../../../components/form/fliptHierarchyDropdown'


class CopyPriorAuthorizationObject extends Component {
    constructor(props) {
        super(props)
        this.state = {
            form: {
                doc_id: props.state?.priorAuthorizationData?.doc_id || '',
                doc_name: props.data[0]?.['module_name'],
                effective_start_date: '',
                module: 'PRIOR_AUTHORIZATION',
                status: 'DRAFT',
                version: props.data[0]?.['version'],
                hierarchy: [],
            },
            activePAConditionLevel: 0,
            showQualifier: [false],
            dropdownValues: [[{
                drug_group: drugGroupValues, drug_class: [], drug_subclass: [],
            }]],
            autoSuggestionValues: [[{
                manufacturer: { values: [], loading: false }, drug_name: { values: [], loading: false },
            }]],
            rowUpdated: null,
            columnUpdated: null,
            levelUpdated: null,
            autoSuggestionMinChars: 3,
        }
        this.conditions = {}
    }

    componentDidMount() {
        const { actions, data } = this.props
        actions.clearPriorAuthorizationData()
        if (data[0]?.id) {
            actions.getPriorAuthorizationData({ doc_id: data[0]?.id })
        }
    }

    componentDidUpdate(prevProps) {
        const { priorAuthorizationCondData, priorAuthorizationQualifierCondData, priorAuthorizationCriteriaIdData } = this.props.state
        if (prevProps.state.priorAuthorizationCondData !== priorAuthorizationCondData ||
            prevProps.state.priorAuthorizationQualifierCondData !== priorAuthorizationQualifierCondData) {
            this.conditions = {
                paConditions: [...priorAuthorizationCondData],
                qualifierConditions: [...priorAuthorizationQualifierCondData],
                criteriaIds: [...priorAuthorizationCriteriaIdData],
            }
        }
    }

    componentWillUnmount() {
        this.props.actions.clearPriorAuthorizationData()
    }

    _updateFields = (el, dateData) => {
        const { name, value } = dateData || el.currentTarget
        this.setState((prevState) => ({
            ...prevState,
            form: {
                ...prevState.form,
                [name]: value,
            },
        }))
    }

    setForm = (newForm) => {
        this.setState((prevState) => ({
            ...prevState,
            form: newForm
        }))
    }

    _savePriorAuthorization = () => {
        const { props: { actions }, state } = this
        const { form } = state

        if (!form.doc_name || !form.effective_start_date) {
            const transitionalPortal = {
                header: 'Prior Authorization Save Failed',
                copy: !form.doc_name ? 'Please input a Prior Authorization Name before saving' : 'Please select a start date before saving',
            }
            actions.displayTransitionalPortal(transitionalPortal)
        } else {
            const { showTabFlag, setShowTabFlag } = this.props
            actions.savePriorAuthorizationData({ ...form, conditions: this.conditions });
            setShowTabFlag(!showTabFlag);
            setTimeout(() => actions.getAllPriorAuthorizationData(), Config.COMPONENT_DATA_LOAD_TIME)
        }
    }

    render() {
        const { state, props } = this
        const { form } = state
        const { showTabFlag, setShowTabFlag, data } = props

        const {
            doc_name, effective_start_date,
        } = form

        const minPAStartDate = addRemoveDaysToDate(1, false)

        return (
            <div id="copyPriorAuthorization ">
                <div className='content copy-object'>
                    <div className="pa-inputs">
                        <span>Prior Authorization Name</span>
                        <FliptInput className="createPAInputs" placeholder="Prior Authorization Name" name="doc_name" value={doc_name} onChange={this._updateFields} />
                    </div>
                    <div className="pa-inputs">
                        <span>Effective Start Date</span>
                        <FliptDatePicker className="create-pa-start-date" placeholder="Effective Start Date" name="effective_start_date" minDate={minPAStartDate} value={convertStrToDateObj(effective_start_date)} onChange={this._updateFields} />
                    </div>
                    <FliptHierarchyDropdown setForm={this.setForm} form={form} />
                    <div className="buttonContainer">
                        <FliptButton name="Copy Object" className="primary searchButton" onClick={this._savePriorAuthorization} />
                    </div>
                    <div className="buttonContainer">
                        <FliptButton name="Cancel" className="secondary searchButton" onClick={() => setShowTabFlag(!showTabFlag)} />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    state: {
        priorAuthorizationData: state.priorAuthorizationCreation.priorAuthorizationData,
        priorAuthorizationCondData: state.priorAuthorizationCreation.priorAuthorizationCondData,
        priorAuthorizationQualifierCondData: state.priorAuthorizationCreation.priorAuthorizationQualifierCondData,
        priorAuthorizationCriteriaIdData: state.priorAuthorizationCreation.priorAuthorizationCriteriaIdData,
    },
})

const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...AppActions,
        ...AutoSuggestActions,
        ...PriorAuthorizationCreationActions,
        ...RPMActions,
    }

    return {
        actions: bindActionCreators(allActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CopyPriorAuthorizationObject)

