import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { Table } from "semantic-ui-react";

import { Creators as AppActions } from "../../../../../../redux/reducers/app";
import { Creators as FileConfigurationActions } from "../../../../../../redux/reducers/api/fileConfiguration";

import './styles.scss';
// import FliptDropdown from '../../../../../../components/v2/fliptDropdown';
import FliptPaDropdown from '../../../../../../components/form/fliptPaDropdown';

function EDI834Renderer(props) {
    const { actions, state } = props;
    const {
        formatStandard,
    } = state;
    if (!formatStandard) return null;
    if (!formatStandard?.fields) return null;
    const allFields = formatStandard?.fields;
    const [configurableFields, nonConfigurableFields] = _.partition(
        allFields,
        (field) => field.required === 'configurable'
    )
    const [requiredFields, allRemainingFields] = _.partition(
        nonConfigurableFields,
        (field) => field.required === 'yes'
    )

    const renderReadOnlyFields = (data) => {
        const flatData = data.reduce((flattened, row) => {
            if (!row.qualifier) {
                flattened.push(row)
            } else {
                row.qualifier?.forEach((qualifier, i) => {
                    if (i === 0) {
                        flattened.push({
                            ...row,
                            code: qualifier.code,
                            value: qualifier.value,
                        })
                    } else {
                        flattened.push({
                            std_field_name: '',
                            std_field: '',
                            loop_id: '',
                            segment_id: '',
                            code: qualifier.code,
                            value: qualifier.value,
                        })
                    }
                })
            }
            return flattened
        }, [])
        return (
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Field Name</Table.HeaderCell>
                        <Table.HeaderCell>Loop ID</Table.HeaderCell>
                        <Table.HeaderCell>Segment ID</Table.HeaderCell>
                        <Table.HeaderCell>Qualifier Code</Table.HeaderCell>
                        <Table.HeaderCell>Qualifier Value</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {flatData.map((row, index) => (
                        <Table.Row key={index}>
                            <Table.Cell>{row.std_field_name || row.std_field}</Table.Cell>
                            <Table.Cell>{row.loop_id}</Table.Cell>
                            <Table.Cell>{row.segment_id}</Table.Cell>
                            <Table.Cell>{row.code}</Table.Cell>
                            <Table.Cell>{row.value}</Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        );
    }

    const renderDropDownOptions = (row) => {
        const rawOptions = row.config_loop_id;
        if (!rawOptions || rawOptions?.length === 0 || !Array.isArray(rawOptions)) return row.loop_id || <section />
        const options = rawOptions.map((option) => {
            return {
                key: option,
                text: option,
                value: option,
            }
        })
        return (
            <FliptPaDropdown
                options={options}
                name={row.std_field || row.std_field_name}
                placeholder="Loop ID"
                defaultValue={options[0]?.value}
                onChange={(event, dropdown) => actions.setEdiMapping(dropdown)}
            />
        )
    }

    const renderConfigurableFields = (data) => {
        return (
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Field Name</Table.HeaderCell>
                        <Table.HeaderCell>Loop ID</Table.HeaderCell>
                        <Table.HeaderCell>Segment ID</Table.HeaderCell>
                        <Table.HeaderCell>Qualifier Code</Table.HeaderCell>
                        <Table.HeaderCell>Qualifier Value</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {data.map((row, index) => {
                        const qualifier = row.qualifier || []
                        const codes = qualifier[0] || {}
                        const { code, value } = codes;
                        return (
                            <Table.Row key={index}>
                                <Table.Cell>{row.std_field_name || row.std_field}</Table.Cell>
                                <Table.Cell>
                                    {renderDropDownOptions(row)}
                                </Table.Cell>
                                <Table.Cell>{row.segment_id}</Table.Cell>
                                <Table.Cell>{code}</Table.Cell>
                                <Table.Cell>{value}</Table.Cell>
                            </Table.Row>
                        )
                    })}
                </Table.Body>
            </Table>
        )
    }
    return (
        <section id='EDI834Renderer'>
            <h2 className="edi-title">EDI 834</h2>
            <section className='section-container'>
                <h3 className='section-header'>Required Fields</h3>
                <section>
                    {renderReadOnlyFields(requiredFields)}
                </section>
            </section>
            <section className='section-container'>
                <h3 className='section-header'>Fields that can be Configured</h3>
                <section >
                    {renderConfigurableFields(configurableFields)}
                </section>
            </section>
            <section className='section-container'>
                <h3 className='section-header'>View all fields</h3>
                <section>
                    {renderReadOnlyFields(allRemainingFields)}
                </section>
            </section>
        </section>
    )
}

const mapStateToProps = (state) => {
    const fileType = state.fileConfiguration.file_type
    const fileStandardKey = fileType + '_standard'
    const standard = state.fileConfiguration[fileStandardKey]
    const formatStandard = state.fileConfiguration['834_format_standard']
    return {
        state: {
            app: state.app,
            planDetails: state.planManagement?.planDetails,
            fileFormat: state.fileConfiguration?.importFormat?.import_file_type,
            formatStandard: formatStandard,
            fileMapping: state.fileConfiguration.fileMapping,
            fileStandardFields: standard?.fields,
            uploadedFileData: state.fileConfiguration.uploadedFileData,
            definedConfigurationList: state.fileConfiguration.importFormat?.defined_configuration_list,
            mapDirection: state.fileConfiguration.map_direction || true,
        },
    }
};

const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...AppActions,
        ...FileConfigurationActions,
    };
    return {
        actions: bindActionCreators(allActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EDI834Renderer);
