
import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'


import { Creators as MemberLookupActions } from '../../../../redux/reducers/api/memberLookup'
import { Creators as PriorAuthorizationCreationActions } from '../../../../redux/reducers/api/priorAuthorizationManagement'

import './styles.scss'
import { Creators as AppActions } from '../../../../redux/reducers/app'
import FliptButton from '../../../../components/form/fliptButton'
import { Icon } from 'semantic-ui-react'
import { convertDateTimeToEstStr } from '../../../../utils/utilities'
class Attachments extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selectedIndex: -1,
            tempSelectedIndex: -1,
            viewFile: true,
            pdfUrl: '',
            attachmentData: []
        }
    }

    componentDidMount() {
        this._getDocumentData()
    }


    _getDocumentData = () => {
        const { state: { paCaseDetails }, actions } = this.props
        const formsLettersData = [
            ...paCaseDetails.letters,
            ...paCaseDetails.forms,
            ...paCaseDetails.attachments
        ]
        actions.getDownloadDocument(formsLettersData)
    }
    componentDidUpdate(prevProps) {
        const { state: { downloadDocumentData, uploadDocumentData, paCaseDetails }, actions, pa_case_id } = this.props
        if (prevProps.state?.downloadDocumentData !== downloadDocumentData) {
            this.setState({ attachmentData: [...downloadDocumentData] })
        }
        if (prevProps.state?.uploadDocumentData !== uploadDocumentData && uploadDocumentData.length) {
            this.setState({ imageData: null })
            document.getElementById('myfile').value = ''
            actions.getPaCaseDetails({ pa_case_id })
        }
        if (prevProps.state?.paCaseDetails !== paCaseDetails && paCaseDetails) {
            this._getDocumentData()
        }
    }

    //not used
    _arrayBufferToBase64 = (buffer) => {
        var binary = '';
        var bytes = new Uint8Array(buffer);
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return btoa(binary);
    }
    //not used
    _viewFile = (data, index) => {
        const { actions } = this.props
        actions.viewAttachment({ attachment_id: data.id })
        this.setState({ tempSelectedIndex: index })
    }

    //not used
    pdfViewer = () => {
        const { pdfUrl } = this.state
        return (
            <embed src={`data:application/pdf;base64,${pdfUrl}`} type="application/pdf" height="400px" width="100%"></embed>

        )
    }

    //not used
    renderAttachments() {
        const { attachmentData, selectedIndex, viewFile } = this.state
        const attachments = attachmentData.map((data, index) => {
            return (
                <>
                    <div className={index === selectedIndex ? 'notes-container selected-background' : 'notes-container'}>
                        <div className={index === selectedIndex ? 'selected-icon-background' : 'notes-icon-container'}>
                            <Icon color='grey' name='file pdf outline' size='large' />
                        </div>
                        <div className='notes-devider-container'>
                            <div className='notes-inner-container'>
                                <div className='text-main-container'>
                                    <div className='date-time-container'>
                                        {data.date}  |  {data.time}
                                    </div>
                                    <div className='text-container'>
                                        {data.name}
                                    </div>
                                </div>
                                <div className='button-container'>
                                    <FliptButton className={viewFile ? 'primary-button' : 'primary'} name='VIEW' onClick={() => this._viewFile(data, index)} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='notes-devider' />
                </>
            )
        })
        return attachments;
    }
    //not used
    _showTransitionalPortal = (header, message) => {
        const { props } = this;
        const transitionalPortal = {
            header: header,
            copy: message,
        }
        props.actions.displayTransitionalPortal(transitionalPortal)
    }
    //not used
    _fileToBlob = async (file) => new Blob([new Uint8Array(await file.arrayBuffer())],
        { type: file.type });

    _uploadFile = async () => {
        const { actions, pa_case_id, state: { user } } = this.props

        const { imageData } = this.state
        if (!imageData || !imageData?.target?.files.length) {
            const header = 'Fields Missing'
            const message = 'Please select a file to upload'
            this._showTransitionalPortal(header, message)
            return;
        }
        let formdata = new FormData()
        const user_name = user["first_name"] + " " + user["last_name"]
        formdata.append(
            "files", imageData.target.files[0]);
        formdata.append('pa_case_id', pa_case_id);
        formdata.append('user', user_name)
        actions.saveAttachment(formdata)
    }
    //not used
    _downloadFile = async (file_name) => {
        this.props.actions.getDownloadDocument(file_name)
    }

    closeModal = () => {
        const { actions } = this.props
        actions.toggleSideDrawer()
    }

    render() {
        const { attachmentData } = this.state
        return (
            <div id="Attachments">
                <div className='header-container'>
                    <span className='header-text'>Attachments</span>
                    <Icon onClick={() => this.closeModal()} name='close' color='grey' />
                </div>
                <div className='upload-button-container'>
                    <input type="file" id="myfile" name="myfile"
                        onChange={(data) => { this.setState({ imageData: data }) }} />
                    <FliptButton className='primary' name='UPLOAD' onClick={() => this._uploadFile()} />
                </div>
                <div className='notes-devider' />
                <div className='upload-button-container'>
                    <div className='forms-letters'>
                        <table>
                            <tr>
                                <th><b>Document Name</b></th>
                                <th><b>Create Date</b></th>
                                <th><b>User</b></th>
                            </tr>
                            {attachmentData ? attachmentData.map((each_data, i) => {
                                return (
                                    <tr>
                                        <td><a href={each_data["url"]}> {each_data["type"]}</a></td>
                                        <td>{convertDateTimeToEstStr(each_data["create_date"])}</td>
                                        <td>{each_data["user"]}</td>
                                    </tr>
                                )
                            }) : []}
                        </table>
                    </div>



                </div>
            </div>
            // </div >
        )
    }
}

const mapStateToProps = (state) => ({
    state: {
        app: state.app,
        user: state.user,
        memberLookup: state.memberLookup,
        createPaCaseData: state.priorAuthorizationCreation.createPaCaseData,
        downloadDocumentData: state.priorAuthorizationCreation.downloadDocumentData,
        viewAttachmentData: state.priorAuthorizationCreation.viewAttachmentData,
        paCaseDetails: state.priorAuthorizationCreation.paCaseDetails,
        uploadDocumentData: state.priorAuthorizationCreation.uploadDocumentData
    },
})

const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...MemberLookupActions,
        ...AppActions,
        ...PriorAuthorizationCreationActions,
    }

    return {
        actions: bindActionCreators(allActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Attachments)
