import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import _ from 'lodash'

import { Creators as AppActions } from '../../../../redux/reducers/app'
import FliptGrid from '../../../../components/fliptGrid'
import { DeleteRowRenderer, DropdownRenderer } from '../../../../components/fliptGrid/cellRenderers'
import { DropdownEditor, InputTextEditor } from '../../../../components/fliptGrid/cellEditors'
import AddRow from '../addRow'
import './styles.scss'
import { buildGridData } from '../../../../utils/utilities'

class CustomMessaging extends Component {
	constructor(props) {
		super(props)
		const { state: { customMessagingData: custom_messaging = [] } } = this.props
		this.state = { custom_messaging }
	}

	renderGrid = (data, grid) => {
		const { fieldDetails, editMode } = this.props
		const { headers, cellRendererParams, cellEditorParams, rowCellInfo } = this.getGridParams(grid)

		// Remove delete row if not in edit mode
		if (!editMode) {
      headers.splice(0, 1)
    }
		fieldDetails?.forEach((element) => {
      const { field } = element
      if (!(field in rowCellInfo)) {
        return
      }

      rowCellInfo[field].options = element.options.map((code, index) => ({
        key: index,
        text: code.display_name,
        value: code.value,
      }))
    })

		const gridProps = editMode ? {
			cellEditorParams,
			cellRendererParams,
			rowCellInfo,
		} : {}

		return (
			<div className="grid-container">
				<FliptGrid
					data={data}
					headers={headers}
					{...gridProps}
				/>
			</div>
		)
	}

	addRow = (grid) => {
		const { headers } = this.getGridParams(grid)
		const { custom_messaging } = this.state
		// remove action header
		headers.splice(0, 1)
		let dataToUpdate = []
		const row = headers.reduce((obj, v) => {
      obj[v] = ''
			if (['doc_name'].includes(v)) obj[v] = [] // TODO: eventually set fields based off of backend
      return obj
    }, {})
		switch(grid) {
			case 'custom_messaging': {
				dataToUpdate = custom_messaging
				break
			}
			default:
				break
		}
		const newState = {
			...this.state,
			[grid]: [...dataToUpdate, row],
		}
		this.setState(newState)
		this.validateAndUpdateData({...newState})
	}

	delRow = (rowIndex, grid) => {
		const { custom_messaging } = this.state
		let dataToUpdate = []
		switch(grid) {
			case 'custom_messaging': {
				dataToUpdate = custom_messaging
				break
			}
			default:
				break
		}

		const newSource = [...dataToUpdate]
		newSource.splice(rowIndex, 1)
		const newState = {
			...this.state,
			[grid]: newSource,
		}
		this.setState(newState)
		this.validateAndUpdateData({ ...newState })
	}

	getRejectMessage = (reject_code) => {
		const { props: { state: { rejectCodeMessageMapping } } } = this
		return rejectCodeMessageMapping ? rejectCodeMessageMapping[reject_code] || 'No Reject Code Found' : 'No Reject Code Found'
	}

	handleChange = (e, dropdown, rowKey, grid) => {
		const { state: { custom_messaging } } = this
		const { name, value } = dropdown || e.currentTarget
		let dataToUpdate = []
		switch(grid) {
			case 'custom_messaging': {
				dataToUpdate = custom_messaging
				break
			}
			default:
				break
		}
		const rowToUpdate = dataToUpdate[rowKey]

		if (grid === 'custom_messaging' && name === 'reject_code') {
			const reject_code = value
			rowToUpdate.reject_message = this.getRejectMessage(reject_code)
		}
		const dataCopy = [...dataToUpdate]
		dataCopy[rowKey] = { ...dataCopy[rowKey], [name]: value }
		_.debounce(() => this.setState({ [grid]: dataCopy	 }), 100)

		this.validateAndUpdateData({ ...this.state, [grid]: dataCopy })
	}

	validateAndUpdateData = (data) => {
		const { copyFormData } = this.props

		copyFormData({ data })
	}

	getGridParams = (grid) => {
		const handleChange = (e, dropdown, rowKey) => {
			this.handleChange(e, dropdown, rowKey, grid)
		}

		const gridParams = {
			custom_messaging: {
				delete: {
					header: true,
					cellRendererParams: {
						cellRenderer: DeleteRowRenderer,
						state: {
							onClick: (index) => this.delRow(index, grid),
						},
						width: 100,
					},
				},
				reject_code: {
					header: true,
					cellRendererParams: {
						width: 130,
						overrideHeader: 'Reject Code',
					},
					cellEditorParams: {
						editable: true,
						cellEditor: DropdownEditor,
						onChange: handleChange,
					},
					rowCellInfo: {
						type: 'dropdown',
					},
				},
				message_type: {
					header: true,
					cellRendererParams: {
						width: 145,
						overrideHeader: 'Message Type',
					},
					cellEditorParams: {
						editable: true,
						cellEditor: DropdownEditor,
						onChange: handleChange,
					},
					rowCellInfo: {
						type: 'dropdown',
					},
				},
				reject_message: {
					header: true,
					cellRendererParams: {
						width: 320,
						overrideHeader: 'Reject Message',
					},
					cellEditorParams: {
						cellEditor: InputTextEditor,
						onChange: handleChange,
						disabled: false,
						isDisabled: function(data) {
							const { message_type = '' } = data
							return message_type === 'standard' 
						},
					},
				},
				doc_name: {
					header: true,
					cellRendererParams: {
						width: 150,
						overrideHeader: 'Program Name',
					},
					cellEditorParams: {
						editable: true,
						cellEditor: DropdownEditor,
						onChange: handleChange,
					},
					rowCellInfo: {
						type: 'dropdown',
						multiple: true,
					},
				},
				app_drug_not_covered_message_key: {
					header: true,
					cellRendererParams: {
						width: 200,
						overrideHeader: 'Flipt App Message Key',
						cellRenderer: DropdownRenderer,
					},
					cellEditorParams: {
						editable: true,
						cellEditor: DropdownEditor,
						onChange: handleChange,
					},
					rowCellInfo: {
						type: 'dropdown',
					},
				},
			},
		}

		return buildGridData(gridParams[grid])
	}

	render() {
		const { props: { editMode }, state: { custom_messaging = [] } } = this

		return (
			<div id="CustomMessaging">
				{this.renderGrid(custom_messaging, 'custom_messaging')}
				{editMode && (
					<div className="addRowButtonContainer">
						<AddRow addRow={() => this.addRow('custom_messaging')} />
					</div>
				)}
			</div>
		)
	}
}

const mapStateToProps = (state, props) => {
	const { fieldDetails, modelData: { programs = [] } } = props
	
	fieldDetails.find(x => x.field === 'doc_name').options = programs?.map(p => ({
		display_name: p.program_name,
		value: p.program_name,
	})) || []

	const rejectMessageField = fieldDetails.find(x => x.field === 'reject_message')
	const rejectMessageOptions = {...rejectMessageField.options}
	
	return ({
		state: {
			app: state.app,
			customMessagingData: state?.planManagement?.customMessagingData?.custom_messaging,
			fieldDetails,
			rejectCodeMessageMapping: rejectMessageOptions,
			rejectMessageOptions,
		}
	})
}

const mapDispatchToProps = (dispatch) => {
	const allActions = {
		...AppActions,
	}

	return {
		actions: bindActionCreators(allActions, dispatch)
	}
}

const customMessagingContainer = (props) => (
	<CustomMessaging
		editMode
		{...props}
	/>
)

export default CustomMessaging

export const CustomMessagingContainer = connect(mapStateToProps, mapDispatchToProps)(customMessagingContainer)