import React from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Creators as AppActions } from '../../../../../redux/reducers/app'
import { Creators as GlobalEditsActions } from '../../../../../redux/reducers/api/globalEdits'
import NetworkPricing from './NetworkPricing';
import PlanOverride from './PlanOverride';

import './styles.scss'

function GlobalEdits(props) {
    const { actions, state } = props
    return (
        <section id='global-edits-configuration'>
            <PlanOverride />
            <NetworkPricing />
        </section>
    )
}

const mapStateToProps = (state) => ({
    state: {
        app: state.app,
        globalEdits: state.globalEdits,
    },
})

const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...AppActions,
        ...GlobalEditsActions,
    }

    return {
        actions: bindActionCreators(allActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GlobalEdits);
