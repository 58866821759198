import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import './styles.scss'
import BreadCrumb from '../../../../components/breadCrumb'
import FliptGrid from '../../../../components/fliptGrid'
import FliptButton from '../../../../components/form/fliptButton'
import { Creators as ApiActions } from '../../../../redux/reducers/api/bulkReprocessingRestacking'
import { Creators as IntegrationManagementActions } from '../../../../redux/reducers/api/integrationManagement'
import { Creators as AppActions } from '../../../../redux/reducers/app'


class ClaimSummary extends Component {
    constructor(props) {
        super(props)
        this.state = {
            claimSummary: []
        }
    }

    componentDidMount() {
        this.setClaimData()
    }

    setClaimData = () => {
        const { state: { claimSummary }, location } = this.props
        const finalClaimSummary = [...claimSummary]
        const oldClaim = claimSummary.filter((claim) => claim.desc === 'Old')[0]
        const newClaim = claimSummary.filter((claim) => claim.desc === 'New')[0]
        const diff = {
            desc: 'Diff'
        }
        if (oldClaim && newClaim) {
            const keyArray = Object.keys(oldClaim)
            keyArray.map((objKey) => {
                if (objKey !== 'desc') {
                    const numericKeys = ['patient_pay_amount', 'calculated_drug_cost', 'quantity_dispensed']
                    diff[objKey] = 'No'
                    if (oldClaim[objKey] != newClaim[objKey]) {
                        diff[objKey] = 'Yes'
                        const numericDiff = newClaim[objKey] - oldClaim[objKey]
                        if (numericKeys.includes(objKey)) diff[objKey] = 'Yes (' + numericDiff + ')'
                    }
                }
            })
            finalClaimSummary.push(diff)
        }
        this.setState({ claimSummary: finalClaimSummary })
    }


    processClaim = (approve) => {
        const { state: { batchDetailsData: { batch_id } }, location } = this.props
        const params = {
            batch_id,
            reviewed_claims: {
                APPROVED: approve ? [location?.state?.auth_id] : [],
                REJECTED: approve ? [] : [location?.state?.auth_id]
            },
            approval_status: "SAVE_REVIEW"
        }
        this.props.actions.queueClaims({ claims: params, goBack: true })
    }


    render() {
        const { location: { state } } = this.props
        const { claimSummary } = this.state
        const authHeaders = ['desc', 'auth_id', 'flipt_person_id', 'cardholder_id', 'group_id', 'date_of_service', 'claim_status', 'drug_name',
            'drug_dosage', 'days_supply', 'drug_strength', 'pharmacy_name', 'quantity_dispensed',
            'transaction_response_status', 'calculated_drug_cost', 'patient_pay_amount']
        const cellRendererParams = {
            desc: {
                width: '100px'
            },
        }
        return (
            <div id='claim-summary'>
                <BreadCrumb {...this.props} claimSummary={true} />
                <div className='header'>
                    Claim Summary
                </div>
                <section className='claim-summary-section'>
                    <FliptGrid
                        headers={authHeaders}
                        data={claimSummary}
                        cellRendererParams={cellRendererParams}
                    />
                </section>
                {!state?.batchCompleted && <div className='claim-summary-container'>
                    <FliptButton
                        name='Approve Claim'
                        className='primary claim-summary-button'
                        onClick={() => this.processClaim(true)} />
                    <FliptButton
                        name='Reject Claim'
                        className='primary'
                        onClick={() => this.processClaim(false)} />
                </div>}
            </div>)
    }
}
const mapStateToProps = (state) => ({
    state: {
        user: state.user,
        claimSummary: state.integrationMgmt.claimSummary,
        batchDetailsData: state.integrationMgmt.batchDetailsData,

    },
})
const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...AppActions,
        ...ApiActions,
        ...IntegrationManagementActions,
    }

    return {
        actions: bindActionCreators(allActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClaimSummary)