import Immutable from 'seamless-immutable'
import { createActions, createReducer } from 'reduxsauce'

export const INITIAL_STATE = Immutable({
    opioidSummaryData: [],
    opioidProgramData: [],
})

export const setOpioidSummaryDataReducer = (state = INITIAL_STATE, { payload }) => ({
    ...state,
    opioidSummaryData: payload
})

export const setOpioidProgramDataReducer = (state = INITIAL_STATE, { payload }) => ({
    ...state,
    opioidProgramData: payload
})

export const { Types, Creators } = createActions({
    getOpioidSummary: ['payload'],
    getOpioidProgram: ['payload'],
    addOpioidProgram: ['payload'],
    setOpioidProgramData: ['payload'],
    setOpioidSummaryData: ['payload'],
    editGlobalOpioid: ['payload'],
    publishGlobalOpioid: ['payload'],
    createNewGlobalOpioid: ['payload'],
})

const HANDLERS = {
    [Types.SET_OPIOID_PROGRAM_DATA]: setOpioidProgramDataReducer,
    [Types.SET_OPIOID_SUMMARY_DATA]: setOpioidSummaryDataReducer,
}

export default createReducer(INITIAL_STATE, HANDLERS)