import React from 'react'
import './styles.scss'
import '@nosferatu500/react-sortable-tree/style.css'
import SortableTree from '@nosferatu500/react-sortable-tree'

export default function FliptTree(props) {
  const {
    treeData, onChange, canDrag, generateNodeProps, searchMethod, searchQuery, searchFocusOffset, searchFinishCallback, className, style
  } = props
  return (
    <div scrolling className="react-sortable-tree-container">
      <SortableTree
        style={style}
        treeData={treeData}
        onChange={onChange}
        canDrag={canDrag}
        generateNodeProps={generateNodeProps}
        scaffoldBlockPxWidth={55}
        searchMethod={searchMethod}
        searchQuery={searchQuery}
        searchFocusOffset={searchFocusOffset}
        searchFinishCallback={searchFinishCallback}
        className={className}
        autoHeight={true}
      />
    </div>
  )
}
