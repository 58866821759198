import React from 'react'
import { Link } from 'react-router-dom'
import FliptButton from '../../../../components/form/fliptButton'
import './styles.scss'
import { valueTypeHelper } from '../../../../utils/utilities'
import FormRenderer from '../../../../components/form/formRender.js'

export default (props) => {
  const { accountDetails, cancelPath, onSave } = props
  const { details: { finance, system } } = accountDetails
  return (
    <div id="accountDetails">
      <header>
        <span>{accountDetails.domain}</span>
        <span>{valueTypeHelper(system.fields.find((field) => field.field === 'flipt_group_id'))}</span>
        <span>{valueTypeHelper(finance.fields.find((field) => field.field === 'companylegalname'))}</span>
      </header>
      <FormRenderer
        formData={accountDetails}
        renderActionButtons={(formData) => (
          <div className="footer">
            <Link to={{ pathname: cancelPath }}>
              <FliptButton
                className="primary searchButton"
                name="CANCEL"
              />
            </Link>
            <FliptButton
              className="primary searchButton"
              onClick={() => onSave(formData)}
              name="SAVE"
            />
          </div>
        )}
      />
    </div>
  )
}
