import { statesData } from "./fieldHelpers";

export const montlyValues = (n = 30) => {
  let values = []
  for (let i = 1; i <= n; i++) {
    values = [...values, {
      key: i,
      text: `${i}`,
      value: `${i}`,
    }]
  }
  return values;
}

export const BillingFieldAttribute = [
  {
    header: "",
    type: "text",
    label: "Billing Address Line 1",
    name: "billingaddress1",
    value: "",
    info: "Billing Address Line 1",
    validation: "",
    placeholder: "Enter",
  },
  {
    header: "",
    type: "text",
    label: "Billing Address Line 2",
    name: "billingaddress2",
    value: "",
    info: "Billing Address Line 2",
    validation: "",
    placeholder: "Enter",
  },
  {
    header: "",
    type: "text",
    label: "Billing City",
    name: "billingcity",
    value: "",
    info: "Billing City",
    validation: "",
    placeholder: "Enter",
  },
  {
    header: "",
    type: "text",
    label: "Billing State",
    name: "billingstate",
    value: "",
    info: "Billing State",
    validation: "",
    placeholder: "Enter",
  },
  {
    header: "",
    type: "text",
    label: "Billing Zip Code",
    name: "billingzip",
    value: "",
    info: "Billing Zip Code",
    validation: "zipCode",
    placeholder: "Enter",
  },
  {
    header: "",
    type: "text",
    label: "Invoicing Fields",
    name: "invoicing_fields",
    value: "",
    info: "Invoicing Fields",
    validation: "",
    placeholder: "Enter",
  },
  {
    header: "",
    type: "dropdown",
    label: "Billing Type",
    name: "pepm_pmpm",
    value: "",
    info: "per employee per month OR per member per month",
    validation: "",
    placeholder: "Enter",
    optionList: [{
      key: 1,
      text: "PEPM",
      value: "PEPM",
    },
    {
      key: 1,
      text: "PMPM",
      value: "PMPM",
    },]
  },
  {
    header: "",
    type: "text",
    label: "Billing Rate",
    name: "pepm_rate",
    value: "",
    info: "per month per employee",
    validation: "numeric",
    placeholder: "Enter",
  },
  {
    header: "",
    type: "text",
    label: "Billing Rate",
    name: "pmpm_rate",
    value: "",
    info: "per month per member",
    validation: "numeric",
    placeholder: "Enter",
  },


];


export const BusinessFieldAttribute = [
  {
    header: "",
    type: "text",
    label: "Sage Line of business",
    name: "sage_line_of_business",
    value: "",
    info: "Sage Line of business",
    validation: "",
    placeholder: "Enter",
  },
  {
    header: "",
    type: "text",
    label: "Sage: Location ID",
    name: "sage_locationid",
    value: "",
    info: "Sage: Location ID",
    validation: "",
    placeholder: "Enter",
  },
  {
    header: "",
    type: "text",
    label: "Sage: Payment instructions",
    name: "sage_payment_instructions",
    value: "",
    info: "Sage: Payment instructions",
    validation: "",
    placeholder: "Enter",
  },
  {
    header: "",
    type: "text",
    label: "Sage: Summary Fields",
    name: "sage_summary_fields",
    value: "",
    info: "Sage: Summary Fields",
    validation: "",
    placeholder: "Enter",
  },
  {
    header: "",
    type: "text",
    label: "Sage: Term Name",
    name: "sage_termname",
    value: "",
    info: "Sage: Term Name",
    validation: "",
    placeholder: "Enter",
  },

]

export const BillingAttribute = [
  {
    "header": "",
    "type": "text",
    "label": "Billing Address Line 1",
    "name": "billingaddress1",
    "value": "",
    "info": "billingaddress1",
    "validation": "",
    "placeholder": "Enter",
    "subgroup": "Billing",
    "order": 0
  },
  {
    "header": "",
    "type": "text",
    "label": "Billing Address Line 2",
    "name": "billingaddress2",
    "value": "",
    "info": "billingaddress2",
    "validation": "",
    "placeholder": "Enter",
    "subgroup": "Billing",
    "order": 0
  },
  {
    "header": "",
    "type": "text",
    "label": "Billing City",
    "name": "billingcity",
    "value": "",
    "info": "billing city",
    "validation": "",
    "placeholder": "Enter",
    "subgroup": "Billing",
    "order": 0
  },
  {
    "header": "",
    "type": "dropdown",
    "label": "Billing State",
    "name": "billingstate",
    "value": "",
    "info": "billingstate",
    "validation": "",
    "placeholder": "Enter",
    "subgroup": "Billing",
    "order": 0,
    "optionList": statesData || [],
  },
  {
    "header": "",
    "type": "text",
    "label": "Billing Zip",
    "name": "billingzip",
    "value": "",
    "info": "billingzip",
    "validation": "zipCode",
    "placeholder": "Enter",
    "subgroup": "Billing",
    "order": 0,
    "maxLength": 10,
  },
  {
    "header": "",
    "type": "dropdown",
    "label": "Billing Type",  //need to discuss
    "name": "pepm_pmpm",
    "value": "",
    "info": "per employee per month OR per member per month ",
    "validation": "",
    "placeholder": "Enter",
    "subgroup": "Billing",
    "order": 0,
    optionList: [{
      key: 1,
      text: "PEPM",
      value: "PEPM",
    },
    {
      key: 1,
      text: "PMPM",
      value: "PMPM",
    },]
  },
  {
    "header": "",
    "type": "text",
    "label": "Billing Rate",   // "Pepm Rate",
    "name": "pepm_rate",
    "value": "",
    "info": "should be populated if pepm_pmpm =  'per month per employee'",
    "validation": "numeric",
    "placeholder": "Enter",
    "subgroup": "Billing",
    "order": 0
  },
  {
    "header": "",
    "type": "text",
    "label": "Billing Rate", // "Pmpm Rate",
    "name": "pmpm_rate",
    "value": "",
    "info": "should be populated if pepm_pmpm =  'per month per member '",
    "validation": "numeric",
    "placeholder": "Enter",
    "subgroup": "Billing",
    "order": 0
  },
  {
    "header": "",
    "type": "text",
    "label": "Sage Line of Business",
    "name": "sage_line_of_business",
    "value": "",
    "info": "Line of Business for Sage",
    "validation": "",
    "placeholder": "Enter",
    "subgroup": "Sage",
    "order": 0
  },
  {
    "header": "",
    "type": "text",
    "label": "Sage Locationid",
    "name": "sage_locationid",
    "value": "",
    "info": "Location ID for Sage",
    "validation": "",
    "placeholder": "Enter",
    "subgroup": "Sage",
    "order": 0
  },
  {
    "header": "",
    "type": "text",
    "label": "Sage Payment Instructions",
    "name": "sage_payment_instructions",
    "value": "",
    "info": "Payment Instructions for Sage",
    "validation": "",
    "placeholder": "Enter",
    "subgroup": "Sage",
    "order": 0
  },
  {
    "header": "",
    "type": "text",
    "label": "Sage Summary Fields",
    "name": "sage_summary_fields",
    "value": "",
    "info": "Summary fields for Sage",
    "validation": "",
    "placeholder": "Enter",
    "subgroup": "Sage",
    "order": 0
  },
  {
    "header": "",
    "type": "text",
    "label": "Sage Termname",
    "name": "sage_termname",
    "value": "",
    "info": "Term name for Sage",
    "validation": "",
    "placeholder": "Enter",
    "subgroup": "Sage",
    "order": 0
  },
  {
    "header": "",
    "type": "text",
    "label": "Invoicing Fields",
    "name": "invoicing_fields",
    "value": "",
    "info": "Invoicing fields: Client, Pharmacy or Calculated",
    "validation": "",
    "placeholder": "Enter",
    "subgroup": "Billing",
    "order": 0
  },
  {
    "header": "",
    "type": "dropdown",
    "label": "No Bill No Pay",  //need to discuss
    "name": "no_bill_no_pay",
    "value": "",
    "info": "",
    "validation": "",
    "placeholder": "Enter",
    "subgroup": "Billing",
    "order": 0,
    optionList: [{
      key: 1,
      text: "Yes",
      value: true,
    },
    {
      key: 1,
      text: "No",
      value: false,
    },]
  },

]

export const ClaimOptions = {
  Weekly: [
    {
      key: 1,
      text: "Monday",
      value: "Monday",
    },
    {
      key: 2,
      text: "Tuesday",
      value: "Tuesday",
    },
    {
      key: 3,
      text: "Wednesday",
      value: "Wednesday",
    },
    {
      key: 4,
      text: "Thrusday",
      value: "Thrusday",
    },
    {
      key: 5,
      text: "Friday",
      value: "Friday",
    },
    {
      key: 6,
      text: "Saturday",
      value: "Saturday",
    },
    {
      key: 7,
      text: "Sunday",
      value: "Sunday",
    },
  ],
  Monthly: montlyValues(30),
  "Semi-Monthly": [
    {
      key: 1,
      text: "1",
      value: "1",
    },
    {
      key: 2,
      text: "16",
      value: "16",
    },
  ],
  "Bi Weekly": [
    {
      key: 1,
      text: "Monday",
      value: "Monday",
    },
    {
      key: 2,
      text: "Tuesday",
      value: "Tuesday",
    },
    {
      key: 3,
      text: "Wednesday",
      value: "Wednesday",
    },
    {
      key: 4,
      text: "Thrusday",
      value: "Thrusday",
    },
    {
      key: 5,
      text: "Friday",
      value: "Friday",
    },
    {
      key: 6,
      text: "Saturday",
      value: "Saturday",
    },
    {
      key: 7,
      text: "Sunday",
      value: "Sunday",
    },
  ],
};