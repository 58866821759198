import {
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects'

import { Types, Creators as DrugLookupActions } from '../../reducers/api/drugLookup'
import { Creators as ManualClaimEntryActions } from '../../reducers/api/manualClaimEntry'

import { Creators as AppActions } from '../../reducers/app'
import { getAppState, getApiPath, isUserEmpty, getUserState } from '../../reducers/selectors'
import { fetchPost } from '../../../utils/fetchApi'

export default [
  getDrugModuleDataWatcher,
  getDrugInfoBySearchWatcher,
  getDrugInfoWithUmWatcher,
]

/* WATCHERS */
function* getDrugModuleDataWatcher() {
  yield takeLatest(Types.GET_DRUG_MODULE_DATA, getDrugModuleDataHandler)
}

function* getDrugInfoBySearchWatcher() {
  yield takeLatest(Types.GET_DRUG_INFO_BY_SEARCH, getDrugInfoBySearchHandler)
}

function* getDrugInfoWithUmWatcher() {
  yield takeLatest(Types.GET_DRUG_INFO_WITH_UM, getDrugInfoWithUmHandler)
}

/* HANDLERS */
function* getDrugModuleDataHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const cd = yield select(getApiPath, 'drug-module-lookup')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`

    const userEmpty = yield select(isUserEmpty)
    if (userEmpty) {
      yield take([UserTypes.USER_UPDATE])
    }
    const userState = yield select(getUserState)
    const { hierarchyAccess = [] } = userState
    payload.cag = hierarchyAccess
    const { data } = yield call(fetchPost, url, payload)
    if (!data.length) throw Error('No Module found for the entered drug')
    yield put(DrugLookupActions.setDrugModuleData(data))
  } catch (err) {
    console.log('getDrugModuleDataHandler Error >Data ', err)

    const transitionalPortal = {
      header: 'Drug Module Lookup Failed',
      copy: err.message || err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}


function* getDrugInfoBySearchHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const cd = yield select(getApiPath, 'drug-db-lookup')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`
    const { data } = yield call(fetchPost, url, payload)
    if (!data.length) {
      const transitionalPortal = {
        header: 'Drug Search Failed',
        copy: 'Error - We are unable to find a drug that matches the search criteria',
      }
      yield put(ManualClaimEntryActions.setNdcLookupFailed(true))
      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
      return
    }
    yield put(DrugLookupActions.setDrugInfoBySearch(data))
  } catch (err) {
    console.log('getDrugInfoBySearchHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Drug Search Failed',
      copy: err.message || err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* getDrugInfoWithUmHandler({payload}) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const cd = yield select(getApiPath, 'drug-db-lookup-with-um')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`
    const { data } = yield call(fetchPost, url, payload)
    if (!data.length) {
      const transitionalPortal = {
        header: 'Drug Search Failed',
        copy: 'Error - We are unable to find a drug that matches the search criteria',
      }
      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
      return
    }
    yield put(DrugLookupActions.setDrugInfoWithUm(data))
  } catch (err) {
    console.log('getDrugInfoWithObjHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Drug Search Failed',
      copy: err.message || err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

