/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */
import _ from 'lodash';
import moment from 'moment-timezone'

export function convertToLocalTime(date) {
  return new Date(date).toLocaleDateString(undefined, { year: 'numeric', month: 'numeric', day: 'numeric' })
}

export function convertSnakeCaseToString(str) {
  return str?.split('_').join(' ')
}

export function formatPhoneNumber(phoneNumberString) {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return null;
}

export function convertStringToSnakeCase(str) {
  return str.split(' ').join('_')
}

export function downloadCSV(content, filename) {
  if (!content) return 
  const headers = Object.keys(content[0]).sort()
  const csvString = [
    headers,
      ...content.map(row => headers.map(header => row[header]))
  ].map(e => e.join(",")).join("\n");
  let pom = document.createElement('a');
  let blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
  let url = URL.createObjectURL(blob);
  pom.href = url;

  pom.setAttribute('download', filename);
  pom.click();

}

// export function downloadConditonalDrugsToCSV (module, doc_name, version, level, conditions, callback) {

//   const mapping = {
//     "formulary_tier": "",
//     "program_list": "programConditionLevel",
//     "prior_auth_list": "paConditionLevel",
//     "quantity_limit_list": "qlConditionLevel"
//   }

//   //let filename = `${module}_list_${doc_name}_${version}_level_${conditionLevel+1}_${Date.now()}.csv`
//   if (conditions.length > 0) {
//     const { formattedConditions, invalidDate } = checkDateValidation(conditions);
//     if (invalidDate) return;
//     let payload = {
//       drugConditions: formattedConditions,
//       mapping[module]: level,
//       filename: filename,
//     }
//     props.actions.generateProgramListLevelExport( payload, downloadCSV)
//   }
// }

export function convertCamelCaseToTitle(str) {
  const result = str.replace(/([A-Z])/g, ' $1')

  return result.charAt(0).toUpperCase() + result.slice(1)
}

export function capitalizeStr(str) {
  const words = str?.split(' ')
  return words?.map((word) => word[0].toUpperCase() + word.substring(1)).join(' ')
}

export function parseQueryString(qs) {
  const qsAry = qs.replace('?', '').split('&')

  return qsAry.reduce((acc, curr) => {
    const v = curr.split('=').filter((d) => !!d)
    acc[v[0]] = v[1] ? v[1].replaceAll('%20', ' ') : ''

    return acc
  }, {})
}

export function createQueryString(form) {
  const qs = Object.keys(form).reduce((accum, key) => {
    const val = (form[key] || form[key] === 0) && typeof form[key] !== 'string' ? form[key].toString() : form[key]

    accum += form[key] || form[key] === 0 ? `${key}=${val.trim()}&` : ''
    return accum
  }, '?')

  return qs.slice(0, -1)
}

export function getVerifiedPhone(phones) {
  const personalPhones = phones || []

  const verifiedAndPreferred = personalPhones.filter((phone) => !!phone.verified && !!phone.preferred)
  return verifiedAndPreferred ? verifiedAndPreferred.sort((a, b) => (a.date_verified > b.date_verified ? -1 : 1)) : false
}

export function filterRowData(row, allowedCols, headerMapping = {}) {
  return Object.keys(row).filter((key) => key in headerMapping || allowedCols?.includes(key))
    .reduce((obj, key) => {
      let newObj = {}

      if (key in headerMapping) {
        newObj = { [headerMapping[key]]: row[key] ?? '--' }
      } else {
        newObj = { [key]: row[key] ?? '--' }
      }
      return { ...obj, ...newObj }
    }, {})
}

export function addRemoveDaysToDate(daysToAdd, add = true, currentDate = new Date()) {
  const newDate = currentDate
  return add ? newDate.setDate(newDate.getDate() + daysToAdd) : newDate.setDate(newDate.getDate() - daysToAdd)
}

export function filterDistinct(aryObj, propName) {
  const uniq = [...new Set(aryObj.map((i) => i[propName]))]

  return uniq.map((i) => aryObj.find((ii) => ii[propName] === i)).flat()
}

export function convertStrToDateObj(inputDate, options = { format: 'YYYY-MM-DD', toISO: true }) {
  if (!inputDate) return ''

  const date = moment(inputDate, options.format)

  return options.toISO ? date.toDate() : date.format()
}

export function convertStrUi(dateStr) {
  let finaldateStr = ''
  let month = []
  if (!dateStr) return ''
  dateStr = dateStr.split('-')
  month = dateStr[2].split(' ')
  finaldateStr = `${dateStr[1]}/${month[0]}/${dateStr[0]}`
  return finaldateStr
}

export function convertDateToStartTimeObj(inputDate) {
  if (!inputDate) return ''
  let date = moment(inputDate).startOf('day').format('YYYY-MM-DD HH:mm:ss')

  return date
}

export function convertDateToStrObj(inputDate) {
  if (!inputDate) return ''
  let date = moment(inputDate).startOf('day').format('YYYY-MM-DD')

  return date
}

export function convertDateTimeToEstStr(inputDate, { skipTime = false, skipDate = false } = {}) {
  if (!inputDate) return ''
  const date = moment(`${inputDate} UTC`).tz('America/New_York')
  if (skipTime)
    return date.format('MM/DD/YYYY')
  if (skipDate)
    return date.format('HH:mm:ss')
  return date.format('MM/DD/YYYY HH:mm:ss')
}

export function getAge(dateString) {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

export function convertDateToEndTimeObj(inputDate) {
  if (!inputDate) return ''

  const date = moment(inputDate).endOf('day').format('YYYY-MM-DD HH:mm:ss')
  return date
}

export function incrementVersion(currentVersion) {
  const versionSplit = currentVersion.split('.')
  const version = versionSplit[0]
  const subVersion = versionSplit[1]
  const newVersion = parseInt(version, 10) + 1
  return `${newVersion.toString()}.${subVersion}`
}

export function buildDropdownOptions(dropdownValues) {
  if (!dropdownValues) return []
  return dropdownValues.map((dropdownValue, index) => ({
    key: index,
    text: dropdownValue,
    value: dropdownValue,
  }))
}

export function formatAddress(loc) {
  const { formatted_address } = loc

  if (formatted_address && formatted_address.match(/[0-9]+/)) return formatted_address

  const {
    city,
    state,
    street_address,
    street_address_2,
    zip_code,
  } = loc

  return `${street_address} ${street_address_2 ? `${street_address_2}` : ''}${city}, ${state} ${zip_code}`
}

export function buildDataForAccountsTable(accounts) {
  const data = accounts && accounts.length ? accounts.map((account) => {
    const details = account.details
    const { finance, system } = details
    const companyLegalName = finance.fields.find((field) => field.field === 'companylegalname')
    const pepmField = finance.fields.find((field) => field.field === 'pepm_rate')
    const pmpmField = finance.fields.find((field) => field.field === 'pmpm_rate')
    const effectiveDate = system.fields.find((field) => field.field === 'create_date')
    const group = system.fields.find((field) => field.field === 'flipt_group_id')

    return {
      carrier: 'Flipt',
      account: account.domain,
      group: valueTypeHelper(group),
      company_name: valueTypeHelper(companyLegalName),
      PEPM_rate: convertToCurrency(valueTypeHelper(pepmField)),
      PMPM_rate: convertToCurrency(valueTypeHelper(pmpmField)),
      effective_date: moment(valueTypeHelper(effectiveDate)).format('YYYY-MM-DD'),
    }
  }) : []

  return data
}

export const valueTypeHelper = (field) => {
  const val = (field?.type?.toUpperCase() === 'DROPDOWN') ? field?.value[0] : field?.value

  return val || ''
}

export const convertToCurrency = (value) => {
  try {
    let valDecimals = value
    if (value) {
      valDecimals = `$${JSON.parse(value).toFixed(2)}`
    }

    return valDecimals
  } catch (err) {
    return value
  }
}

export function generateFilterOptions(accounts) {
  const options = {
    accountOptions: [{ text: 'None', value: false }],
    groupOptions: [{ text: 'None', value: false }],
    companyOptions: [{ text: 'None', value: false }],
  }

  accounts.forEach((account) => {
    const { details, domain } = account
    const groupField = details.system.fields.find((field) => field.field.toLowerCase() === 'flipt_group_id')
    const nameField = details.finance.fields.find((field) => field.field.toLowerCase() === 'companylegalname')

    options.accountOptions.push({ text: domain, value: domain })
    options.groupOptions.push({ text: valueTypeHelper(groupField), value: valueTypeHelper(groupField) })
    options.companyOptions.push({ text: valueTypeHelper(nameField), value: valueTypeHelper(nameField) })
  })

  return options
}

export function getCAGOptions(carriers, selectedCarrierName = {}, selectedAccountName = {}, getAllOptions, isUpdatedHierarchy = false, addALLOptions = false) {
  const defaultOptions = []
  if (addALLOptions) {
    defaultOptions.push({ text: 'All', value: 'ALL' })
  }
  let carrierName = null
  let carrierID = null
  let carrierKey = null
  let carrierPath = null
  let accountName = null
  let accountID = null
  let accountKey = null
  let accountPath = null
  if (isUpdatedHierarchy) {
    ({ name: carrierName, value: carrierID, key: carrierKey, path: carrierPath } = selectedCarrierName)
    if (!selectedAccountName) {
      selectedAccountName = {}
    }
    ({ name: accountName, value: accountID, key: accountKey, path: accountPath } = selectedAccountName)
    selectedCarrierName = carrierID
    selectedAccountName = accountID
  }
  const options = {
    carrierOptions: getAllOptions ? [...defaultOptions] : [...defaultOptions, { text: 'None', value: false }],
    accountOptions: getAllOptions ? [...defaultOptions] : [...defaultOptions, { text: 'None', value: false }],
    groupOptions: getAllOptions ? [...defaultOptions] : [...defaultOptions, { text: 'None', value: false }],
  }
  if (getAllOptions) {
    carriers.map((carrier) => {
      options.carrierOptions.push({ text: carrier.display_name, value: carrier.name, key: carrier?.key, path: carrier?.path });
      (carrier?.account || []).map((account) => {
        const { name, display_name, key, path } = account;
        options.accountOptions.push({ text: display_name, value: name, key, path });
        account.group.forEach((group) => {
          options.groupOptions.push({ text: group?.display_name ?? 'None', value: group?.name ?? false, key: group?.key, path: group?.path });
        })
      })
    })
    return options
  }

  // populate carriers
  carriers.forEach((carrier) => {
    options.carrierOptions.push({ text: carrier.display_name, value: carrier.name, key: carrier?.key, path: carrier?.path })
  })
  const selectedCarrier = carriers.find((carrier) => carrier.name === selectedCarrierName)
  // get accounts for carrier
  let selectedAccount = {}
  if (selectedCarrierName) {
    if (typeof selectedCarrierName === 'string' && selectedCarrierName?.toUpperCase() === 'ALL') {
      carriers.forEach((carrier) => {
        (carrier?.account || []).map((account) => {
          const { name, display_name, key, path } = account;
          options.accountOptions.push({ text: display_name, value: name, key, path })
        })
      })
    } else {
      (selectedCarrier?.account || []).forEach((account) => {
        const { name, display_name, key, path } = account
        options.accountOptions.push({ text: display_name, value: name, key, path })
      })
    }
    selectedAccount = carriers?.find((carrier) => carrier?.account?.find(((account) => account?.name === selectedAccountName)))?.account?.find((account) => account.name === selectedAccountName)
    if (typeof selectedAccountName === 'string' && selectedAccountName?.toUpperCase() === 'ALL') {
      selectedCarrier?.account.forEach((account) => {
        (account?.group || []).forEach((group) => {
          options.groupOptions.push({ text: group?.display_name ?? 'None', value: group?.name ?? false, key: group?.key, path: group?.path })
        })
      })
    } else {
      let { group = [] } = selectedAccount ?? ((selectedCarrier?.account) ? selectedCarrier.account[0] : {})
      !_.isEmpty(selectedAccount) && group.forEach((group) => {
        options.groupOptions.push({ text: group?.display_name ?? 'None', value: group?.name ?? false, key: group?.key, path: group?.path })
      })
    }
  }

  return options
}
export function convertDateTimeToDate(datetime) {
  if (!datetime) return datetime
  return moment(datetime.split('T')[0]).format('MM-DD-YYYY')
}

export function calcGridRowHeight(data, unitRowHeight = 42, singleUnitRow = 50) {
  if (!data || data.length === 0 || data.length == 1) return singleUnitRow
  const totalRowHeight = unitRowHeight * data.length
  return totalRowHeight
}

export function removeLeadingZeros(strWithLeadingZeros) {
  return parseInt(strWithLeadingZeros, 10).toString()
}

export function buildGridData(gridParams) {
  let headers = []
  let cellRendererParams = {}
  let cellEditorParams = {}
  let rowCellInfo = {}
  Object.entries(gridParams).forEach(([name, { header, cellRendererParams: columnCellRendererParams, cellEditorParams: columnCellEditorParams, rowCellInfo: columnRowCellInfo }]) => {
    if (!!header) headers.push(name)
    if (!!columnCellRendererParams) cellRendererParams[name] = columnCellRendererParams
    if (!!columnCellEditorParams) cellEditorParams[name] = columnCellEditorParams
    if (!!columnRowCellInfo) rowCellInfo[name] = columnRowCellInfo
  })
  return { headers, cellRendererParams, cellEditorParams, rowCellInfo }
}

export function sortByName(valueA, valueB) {
  if (valueA.name === valueB.name) return 0;
  let x = valueA.name.toLowerCase();
  let y = valueB.name.toLowerCase();
  return (x > y) ? 1 : -1;
}

export function sortObjByStrField(a, b, field = '') {
  let x = a[field]?.toLowerCase(), y = b[field]?.toLowerCase()
  if (x < y) //sort string ascending
    return -1
  if (x > y)
    return 1
  return 0
}

export function millisToMinutesAndSeconds(millis) {
  const minutes = Math.floor(millis / 60000)
  const seconds = ((millis % 60000) / 1000).toFixed(0)
  return (
    seconds == 60 ?
      (minutes + 1) + ":00" :
      minutes + ":" + (seconds < 10 ? "0" : "") + seconds
  )
}

function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
}

export function convertMsToTime(milliseconds) {
  let seconds = Math.floor(milliseconds / 1000)
  let minutes = Math.floor(seconds / 60)
  let hours = Math.floor(minutes / 60)

  minutes = minutes % 60

  return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}`
}

export function convertMsToHours(milliseconds) {
  return (milliseconds / (1000 * 60 * 60))
}

export async function readFileAsDataURL(file) {
  let result_base64 = await new Promise((resolve) => {
    let fileReader = new FileReader();
    fileReader.onload = (e) => resolve(fileReader.result);
    return fileReader.readAsDataURL(file);
  });

  return result_base64;
}

export function getVisibilityFunction(visibilityTable, title) {
  if (!title) return title
  if (!visibilityTable) return visibilityTable
  return visibilityTable[title]
}

export const formatAmt = (num, currency_symbol, decimals = 2) => {
  let n = num;
  if (n) {
    if (typeof (n) == 'string') {
      n = parseFloat(num)
    }
  } else {
    return `${currency_symbol || ''}0.00`
  }
  return `${currency_symbol || ''}${n.toLocaleString('en-US', {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  })}`;
}

const DATE_FORMAT = 'MM/DD/YYYY';

export function convertStrToDate(inputDate, format = DATE_FORMAT) {
  if (!inputDate) return ''
  if (moment(inputDate).isValid()) {
    const date = moment(inputDate).format(format)
    return date
  } else {
    console.log('Invalid date: ', inputDate)
    return ''
  }
}

export const DEFAULT_ERROR_HEADER = 'Something went wrong'

export const formatErrorMessage = (err) => {
  if (err && typeof err === 'string') {
    return err
  } else {
    return DEFAULT_ERROR_HEADER
  }
}

export const filterEmptyfromObj = (obj) => {
  const newObj = {}

  Object.entries(obj).forEach(el => {
    const [k, v] = el

    if (!!v.length) {
      newObj[k] = v
    }
  })

  return newObj
}
