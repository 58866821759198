import React from 'react'
import { Icon } from 'semantic-ui-react'
import * as Constants from './constants'

import './styles.scss';

export const DocCreationQueueMessage = ({ queue_action, queue_status, selectedStatus }) => {

  if (!queue_status || !queue_action ||
    (selectedStatus && queue_action !== selectedStatus)
  ) return null

  let queueMessage = '', queueMessageIconColor = ''
  switch (queue_status) {
    case Constants.QUEUE_IN_PROGRESS_STR:
      queueMessage = `Document creation is in progress for action: ${queue_action}`
      queueMessageIconColor = 'yellow'
      break
    case Constants.QUEUE_SUCCESS_STR:
      queueMessage = `Documents creation successful for action: ${queue_action}`
      queueMessageIconColor = 'green'
      break
    case Constants.QUEUE_FAILED_STR:
      queueMessage = `Documents creation failed for action: ${queue_action}`
      queueMessageIconColor = 'red'
      break
  }
  return (
    <div className='queue-message-container'>
      <Icon name='exclamation triangle' color={queueMessageIconColor} />
      <span className='queue-message-text'>{queueMessage}</span>
    </div>
  )
}


export const FaxQueueMessage = ({ fax_action, fax_status, selectedStatus, fax_error_message = "" }) => {
  if (!fax_status || !fax_action ||
    (selectedStatus && fax_action !== selectedStatus)
  ) return null

  let faxQueueMessage = '', faxMessageIconColor = ''
  switch (fax_status) {
    case Constants.FAX_IN_PROGRESS_STR:
      faxQueueMessage = `Outbound fax is being processed for action: ${fax_action}`
      faxMessageIconColor = 'yellow'
      break
    case Constants.FAX_SUCCESS_STR:
      faxQueueMessage = `Outbound fax successfully sent for action: ${fax_action}`
      faxMessageIconColor = 'green'
      break
    case Constants.FAX_FAILED_STR:
      faxQueueMessage = `Outbound fax failed for action: ${fax_action}. Error: ${fax_error_message}`
      faxMessageIconColor = 'red'
      break
  }
  return (
    <div className='queue-message-container'>
      <Icon name='exclamation triangle' color={faxMessageIconColor} />
      <span className='queue-message-text'>{faxQueueMessage}</span>
    </div>
  )
}
