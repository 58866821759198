import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Accordion, Button, Icon } from 'semantic-ui-react'

import { Creators as AppActions } from '../../../../../../redux/reducers/app'

import '../styles.scss'
import rowCellInfo from '../data/rowCellInfo'
import { qualifierType, qualifierOperator } from '../data/qualifier'
import qualifierRowCellInfo from '../../../../programList/createProgramList/data/qualifierRowCellInfo'
import subListRowCellInfo from '../data/subListRowCellInfo'
import FliptInput from '../../../../../../components/form/fliptInput'
import FliptDropdown from '../../../../../../components/form/fliptDropdown'
import FliptGrid from '../../../../../../components/fliptGrid'
import FliptButton from '../../../../../../components/form/fliptButton'
import Qualifiers from '../../../../../../components/Qualifiers'
import AddRow from '../addRow'
import { ButtonRenderer, DeleteRowRenderer, LevelCheckboxRenderer } from '../../../../../../components/fliptGrid/cellRenderers'
import { InputTextEditor, DropdownEditor, SearchEditor } from '../../../../../../components/fliptGrid/cellEditors'
// import StepLevelDrugsModal from '../stepLevelDrugsModal'
import { filterRowData, buildDropdownOptions } from '../../../../../../utils/utilities'
import * as ApprovalsConstants from '../../../../../../redux/sagas/rpm/constants'

function StepTherapyLevels(props) {
  const { DRAFT, PUBLISHED, REJECTED } = ApprovalsConstants
  const {
    state, stepTherapyLevels, handleChange, dropdownOptions,
    activeStepLevel, stepLevelCriteria, levelCriteriaDropdownOptions, showAdditionalCriteria,
    drugList, autoSuggestionValues, stepTherapyQualifierLevels, criteria_sublist, qualifierDropdownOptions, subListDropdownOptions, delQualifierRow, handleSubListChange,
    // nextFormStep, 
    prevFormStep, handleQualifierChange, addQualifierRow, addSubListRow, delSubListRow,
    autoSuggestionResultSelect, autoSuggestionMinChars,
    addStepCondition, delStepCondition, generateStepTherapyLevelDrugs, saveStepTherapy,
    addNewStepLevel, deleteStepLevel, handleStepLevelClick,
    editMode, showCriteria, cellRenderers, criteriaIds, onStepCheckboxChange
  } = props

  const { stepTherapyLevelsDrugsData } = state

  const { multiSourceOptions, brandGenericOptions, otcIndicatorOptions, DESIOptions,
    routeOfAdministrationOptions, drugIndicatorOptions, dosageFormOptions,
    thirdPartyRestrictionOptions, deaClassCodeOptions, storageConditionCodeOptions, 
    marketingCategoryOptions, applicationTypeOptions } = state.conditionsDropDownOptions

  rowCellInfo.brand_generic.options = brandGenericOptions
  rowCellInfo.desi_code.options = DESIOptions
  rowCellInfo.dosage_form_cd.options = dosageFormOptions
  rowCellInfo.maintenance_drug_code.options = drugIndicatorOptions
  rowCellInfo.multi_source.options = multiSourceOptions
  rowCellInfo.otc_indicator.options = otcIndicatorOptions
  rowCellInfo.repackaged_code.options = [{key: "Y", value: "Y", text:"Repackaged"},{key: "N", value: "N", text:"Not Repackaged"}]
  rowCellInfo.route_of_administration.options = routeOfAdministrationOptions
  rowCellInfo.third_party_restriction_code.options = thirdPartyRestrictionOptions
  rowCellInfo.dea_class_code.options = deaClassCodeOptions
  rowCellInfo.clinic_pack_code.options = [{key: "Y", value: "Y", text:"Y"},{key: "N", value: "N", text:"N"}]
  rowCellInfo.innerpack_code.options = [{key: "Y", value: "Y", text:"Y"},{key: "N", value: "N", text:"N"}]
  rowCellInfo.unit_dose_with_non_unit_dose_non_repackager_in_gpi.options = [{key: "Y", value: "Y", text:"Y"},{key: "N", value: "N", text:"N"}]
  rowCellInfo.rx_with_otc_in_gpi.options = [{key: "Y", value: "Y", text:"Y"},{key: "N", value: "N", text:"N"}]
  rowCellInfo.storage_condition_code.options = storageConditionCodeOptions
  rowCellInfo.marketing_category.options = marketingCategoryOptions
  rowCellInfo.application_type_flag.options = applicationTypeOptions

  rowCellInfo.unit_dose.options = [{key: "", value: "", text:""},{key: "U", value: "U", text:"U"},{key: "X", value: "X", text:"X"}]
  rowCellInfo.fda_therapeutic_equivalence_code.options= buildDropdownOptions(["AA",
  "AB",
  "AB1",
  "AB1,AB2",
  "AB1,AB2,AB3",
  "AB1,AB2,AB3,AB4",
  "AB1,AB3",
  "AB2",
  "AB3",
  "AB4",
  "AN",
  "AO",
  "AP",
  "AP1",
  "AP2",
  "AT",
  "AT1",
  "AT2",
  "AT3",
  "BC",
  "BD",
  "BP",
  "BS",
  "BX"])

  // no generate drugs for individual conditions for step therapy levels as
  // we need all drug data for sub list criteria
  delete rowCellInfo.generate_drugs

  if (editMode) {
    qualifierRowCellInfo.action = {
      type: 'deleteRow',
      disabled: false,
    }
    Object.keys(qualifierRowCellInfo).forEach((key) => { qualifierRowCellInfo[key].disabled = false })

    rowCellInfo.action = {
      type: 'deleteRow',
      disabled: false,
    }
    Object.keys(rowCellInfo).forEach((key) => { rowCellInfo[key].disabled = false })
  } else {
    delete qualifierRowCellInfo.action
    Object.keys(qualifierRowCellInfo).forEach((key) => { qualifierRowCellInfo[key].disabled = true })

    delete rowCellInfo.action
    Object.keys(rowCellInfo).forEach((key) => { rowCellInfo[key].disabled = true })
  }

  let qualifierHeaders = Object.keys(qualifierRowCellInfo)
  if (qualifierHeaders.includes('action')) {
    qualifierHeaders.splice(qualifierHeaders.indexOf('action'), 1)
    qualifierHeaders.unshift('action')
  }

  qualifierHeaders = ['action', 'step_therapy_qualifier', 'step_therapy_operator', 'step_therapy_value']


  const headers = Object.keys(rowCellInfo)
  if (headers.includes('action')) {
    headers.splice(headers.indexOf('action'), 1)
    headers.unshift('action')
  }

  let subListHeaders = Object.keys(subListRowCellInfo)
  if (subListHeaders.includes('action')) {
    subListHeaders.splice(subListHeaders.indexOf('action'), 1)
    subListHeaders.unshift('action')
  }

  subListHeaders = ['action', 'lookback_criteria_sublist', 'drug_list', 'set_operator']


  const gridHeaders = [
    'gpi', 'drug_group', 'drug_class', 'drug_subclass', 'manufacturer', 'drug_name', 'multi_source', 'ndc',
    'ddid', 'brand_generic', 'otc_indicator', 'route_of_administration', 'maintenance_drug_code', 'desi_code',
  ]

  const qualifierCellRendererParams = {
    action: {
      cellRenderer: DeleteRowRenderer,
      state: {
        onClick: delQualifierRow,
      },
      width: 95,
    },
    step_therapy_qualifier: {
      overrideHeader: 'Step Therapy Qualifier',
    },
    step_therapy_operator: {
      overrideHeader: 'Step Therapy Operator',
    },
    step_therapy_value: {
      overrideHeader: 'Step Therapy Value',
    },
  }

  const subListCellRendererParams = {
    action: {
      cellRenderer: DeleteRowRenderer,
      state: {
        onClick: delSubListRow,
      },
      width: 95,
    },
    lookback_criteria_sublist: {
      overrideHeader: 'Sub List Lookback Criteria',
    },
    drug_list: {
      overrideHeader: 'Drug List',
    },
    set_operator: {
      overrideHeader: 'Set Operator',
    },
  }

  const subListCellEditorParams = {
    lookback_criteria_sublist: {
      cellEditor: DropdownEditor,
      onChange: handleSubListChange,
    },
    drug_list: {
      cellEditor: DropdownEditor,
      onChange: handleSubListChange,
    },
    set_operator: {
      cellEditor: DropdownEditor,
      onChange: handleSubListChange,
    },
  }

  const qualifierCellEditorParams = {
    step_therapy_qualifier: {
      cellEditor: DropdownEditor,
      onChange: handleQualifierChange,
    },
    step_therapy_operator: {
      cellEditor: DropdownEditor,
      onChange: handleQualifierChange,
    },
    step_therapy_value: {
      cellEditor: InputTextEditor,
      onChange: handleQualifierChange,
    },
  }

  // const minStepTherapyStartDate = addRemoveDaysToDate(1, false)

  let cellRendererParams = {
    action: {
      cellRenderer: DeleteRowRenderer,
      state: {
        onClick: delStepCondition,
      },
      width: 95,
    },
    include: {
      overrideHeader: 'Include',
      cellRenderer: LevelCheckboxRenderer,
      state: {
        onCheckboxChange: onStepCheckboxChange,
      },
    },
  }

  cellRendererParams = { ...cellRendererParams, ...cellRenderers }

  const cellEditorParams = {
    gpi: {
      cellEditor: InputTextEditor,
      onChange: handleChange,
    },
    ndc: {
      cellEditor: InputTextEditor,
      onChange: handleChange,
    },
    desi_code: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    drug_group: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    drug_class: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    drug_subclass: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    drug_name: {
      cellEditor: SearchEditor,
      onChange: handleChange,
      autoSuggestionResultSelect,
    },
    multi_source: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    ddid: {
      cellEditor: InputTextEditor,
      onChange: handleChange,
    },
    brand_generic: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    otc_indicator: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    route_of_administration: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    application_type_flag: {
      cellEditor: InputTextEditor,
      onChange: handleChange,
    },
    mfg_labeler_id: {
      cellEditor: InputTextEditor,
      onChange: handleChange,
    },
    repackaged_code: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    third_party_restriction_code: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    dosage_form_cd: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    clinic_pack_code: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    innerpack_code: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    unit_dose: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    dea_class_code: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    fda_therapeutic_equivalence_code: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    marketing_category: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    storage_condition_code: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    unit_dose_with_non_unit_dose_non_repackager_in_gpi: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    rx_with_otc_in_gpi: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    member_notes: {
      cellEditor: InputTextEditor,
      onChange: handleChange,
    },
    agent_notes: {
      cellEditor: InputTextEditor,
      onChange: handleChange,
    },
    internal_notes: {
      cellEditor: InputTextEditor,
      onChange: handleChange,
    },
    claim_message_code: {
      cellEditor: InputTextEditor,
      onChange: handleChange,
    },
    claim_message_type: {
      cellEditor: InputTextEditor,
      onChange: handleChange,
    },
  }

  const _prevFormStep = () => {
    // e.preventDefault()
    prevFormStep()
  }

  // eslint-disable-next-line react/destructuring-assignment
  // const _constructDrugListDropdownOptions = (values) => values.map((row) => _mapValueToOption(row))

  const _mapValueToOption = (row) => {
    const data = {
      ...row,
    }
    Object.keys(data).forEach((col) => {
      if (Array.isArray(data[col])) {
        data[col] = data[col].map((group, index) => ({
          key: index,
          text: group,
          value: group,
        }))
      }
    })
    return data
  }

  // const _drugModal = (gridData) => {
  //   const message = 'You are about to save the formulary tier. Do you wish to save?'
  //   props.actions.setModalComponent({
  //     modalProperties: {
  //       size: 'large',
  //     },
  //     contents: StepLevelDrugsModal({
  //       gridData, gridHeaders, toggleModal: props.actions.toggleModal, message,
  //     }),
  //   })
  //   props.actions.toggleModal()
  // }
  const stCellRenderer = {
    route_of_administration: {
      hide: true
    },
    maintenance_drug_code: {
      hide: true
    },
  }



  return (
    <div className="section">
      <section className="grid-container-step-therapy-levels spacing border-line shadow">
        {!!editMode && (
          <div className="add-step-therapy-level">
            <Button size="small" onClick={addNewStepLevel} color="youtube">
              <Icon name="add" />
              Add New Step Level
            </Button>
          </div>
        )}
        <div className="step-therapy-levels">
          <Accordion className="step-therapy-level-accordion" styled>
            {
              stepTherapyLevels.map((stepTherapyLevel, idx) => {
                const agGridRef = React.createRef()
                const stepLevelHeading = `Step Therapy Level ${idx + 1}`
                const generateButtonHeading = `Generate Drugs for Step Level ${idx + 1}`
                const gridData = stepTherapyLevelsDrugsData[idx] && stepTherapyLevelsDrugsData[idx].length ? stepTherapyLevelsDrugsData[idx].map((d) => ({
                  ...filterRowData(d, gridHeaders),
                })) : []
                const hiddenColumn = Object.keys(cellRendererParams).filter(key => cellRendererParams[key]?.hide)
                const columnData = {}
                stepTherapyLevel.map(ele => {
                  Object.keys(ele).forEach(key => {
                    columnData[key] = !!columnData[key] || !!ele[key]
                  })
                })
                hiddenColumn.forEach(key => {
                  if (columnData[key]) {
                    cellRendererParams[key].hide = false
                  }
                })
                const levelDrugList = drugList[idx]
                const {
                  drug_list_a, drug_list_b,
                } = levelDrugList
                const drugNames = [...new Set(gridData.map((row) => row.drug_name))]
                const drugListOptions = _mapValueToOption({ drug_name: drugNames.length ? drugNames : drug_list_a }).drug_name
                const subListDD = subListDropdownOptions[idx]
                if (subListDD) {
                  subListDD.map(eachsublist => {
                    eachsublist["drug_list"] = drugListOptions
                  })
                }
                subListDropdownOptions[idx] = subListDD
                // const drugListAOptions = _mapValueToOption({ drug_name: drugNames.length ? drugNames : drug_list_a }).drug_name
                // const drugListBOptions = _mapValueToOption({ drug_name: drugNames.length ? drugNames : drug_list_b }).drug_name
                const levelCriteria = stepLevelCriteria[idx]
                const {
                  lookback_criteria, lookback_period, drug_duration,
                } = levelCriteria
                const levelCriteriaDropdownOption = levelCriteriaDropdownOptions[idx]
                const {
                  lookback_criteria_options, age_limitation_options,
                } = levelCriteriaDropdownOption
                const stepTherapyQualifierLevel = stepTherapyQualifierLevels[idx]
                const stepTherapyConditionLevelQualifier = !editMode ? { data: stepTherapyQualifierLevel, headers: qualifierHeaders } : {
                  data: stepTherapyQualifierLevel,
                  headers: qualifierHeaders,
                  cellRendererParams: qualifierCellRendererParams,
                  cellEditorParams: qualifierCellEditorParams,
                  rowCellInfo: qualifierRowCellInfo,
                  stepLevel: idx,
                  dropdownOptions: qualifierDropdownOptions[idx]
                }
                const subListingLevel = criteria_sublist[idx]
                const subListingCriteria = (lookback_criteria != "Drugs in Sublist") ? { data: [], headers: subListHeaders } : {
                  data: subListingLevel,
                  headers: subListHeaders,
                  cellRendererParams: subListCellRendererParams,
                  cellEditorParams: subListCellEditorParams,
                  rowCellInfo: subListRowCellInfo,
                  stepLevel: idx,
                  dropdownOptions: subListDropdownOptions[idx]
                }
                return (
                  <div className="step-therapy-level">
                    <Accordion.Title
                      active={activeStepLevel === idx}
                      index={idx}
                      onClick={handleStepLevelClick}
                    >
                      <Icon name="dropdown" />
                      {stepLevelHeading}
                      <Icon className="step-therapy-level-delete" name="delete" onClick={(e) => deleteStepLevel(e, idx)} />
                    </Accordion.Title>
                    <Accordion.Content
                      active={activeStepLevel === idx}
                    >
                      <div className="step-therapy-level-conditions">
                        <FliptGrid
                          agGridRef={agGridRef}
                          data={stepTherapyLevel}
                          headers={headers}
                          cellRendererParams={cellRendererParams}
                          cellEditorParams={cellEditorParams}
                          rowCellInfo={rowCellInfo}
                          dropdownOptions={dropdownOptions[idx]}
                          autoSuggestionValues={autoSuggestionValues}
                          autoSuggestionMinChars={autoSuggestionMinChars}
                          stepLevel={idx}
                        />
                      </div>
                      <div className="step-therapy-level-button-container">
                        {!!editMode && (
                          <div className="step-therapy-level-add-cond-button">
                            <AddRow addRow={addStepCondition} level={idx} />
                          </div>
                        )}
                        <FliptButton name={generateButtonHeading} className="primary searchButton" onClick={(e) => generateStepTherapyLevelDrugs(e, idx)} />
                      </div>
                      {!!gridData.length && (
                        // _drugModal(gridData)
                        <div className="step-therapy-level-drugs">
                          <FliptGrid
                            data={gridData}
                            headers={gridHeaders}
                            cellRendererParams={stCellRenderer}
                          />
                        </div>
                      )}
                      {!!showCriteria[idx] && (
                        <section className="step-therapy-level-criteria spacing border-line shadow">
                          <section className="step-therapy-level-lookback-criteria spacing border-line shadow">
                            <div className="step-therapy-level-criteria-inputs">
                              <span>Lookback Criteria<span className='asterik'></span> </span>
                              <FliptDropdown className="step-therapy-level-criteria-inputs-dropdown" placeholder="Lookback Criteria" name="lookback_criteria" value={lookback_criteria} options={lookback_criteria_options} selectOnBlur={false} scrolling clearable selection disabled={!editMode} onChange={(e, dropdown) => handleChange(e, dropdown, null, null, idx)} />
                            </div>
                            <div className="step-therapy-level-criteria-inputs">
                              <span>Lookback Period <span className='asterik'></span></span>
                              <FliptInput placeholder="Lookback Period" name="lookback_period" value={lookback_period} disabled={!editMode} onChange={(e, dropdown) => handleChange(e, dropdown, null, null, idx)} />
                            </div>
                            <div className="step-therapy-level-criteria-inputs">
                              <span>Drug Duration<span className='asterik'></span></span>
                              <FliptInput placeholder="Drug Duration" name="drug_duration" value={drug_duration} disabled={!editMode} onChange={(e, dropdown) => handleChange(e, dropdown, null, null, idx)} />
                            </div>
                            {/* <div className="step-therapy-level-criteria-inputs">
                              <span>Age Limitation</span>
                              <FliptDropdown className="step-therapy-level-criteria-inputs-dropdown" placeholder="Age LImitation" name="age_limitation" value={age_limitation} options={age_limitation_options} selectOnBlur={false} multiple scrolling clearable selection disabled={!editMode} onChange={(e, dropdown) => handleChange(e, dropdown, null, null, idx)} />
                            </div> */}
                          </section>
                          <div className='step-therapy-split'> {(
                            <div className="step-therapy-condition-level-qualifier">
                              <h3><b>Define Sub Level Criteria</b></h3>
                              <div className="step-therapy-sub-level-criteria-container">
                                <FliptGrid {...subListingCriteria} />
                              </div>
                              <div className="step-therapy-qualifier-level-add-cond-button">
                                {!!editMode && (
                                  <AddRow addRow={addSubListRow} addRowLabel="Add Next Sub List" level={idx} />
                                )}
                              </div>

                            </div>
                          )}
                          </div>
                        </section>
                      )}
                      <Qualifiers
                        allCriteriaIDs={criteriaIds}
                        model_ids={["claim", "prescriber", "user", "pharmacy"]}
                        stepLevel={idx}
                        criteria_id={criteriaIds?.[idx] || ""}
                        preventMultipleGetCalls
                      />
                    </Accordion.Content>
                  </div>
                )
              })
            }
          </Accordion>
        </div>
      </section>
      <div className="buttonContainer">
        <FliptButton name="Back" onClick={_prevFormStep} />
        {/* <FliptButton disabled name="Save And Continue" onClick={_nextFormStep} /> */}
        {/* <FliptButton name="Generate Step Therapy" className="primary searchButton" disabled onClick={generateStepTherapy} /> */}
        {!!editMode &&
          <FliptButton
            className="primary searchButton"
            disabled={[DRAFT, PUBLISHED, REJECTED].includes(status)}
            name="Save"
            onClick={saveStepTherapy}
          />}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  state: {
    stepTherapyLevelsDrugsData: state.clinicalCreation.stepTherapyLevelsDrugsData,
    conditionsDropDownOptions: state.rpm.conditionsDropDownOptions,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StepTherapyLevels)
