import React from "react";
import { Icon } from "semantic-ui-react";

function QualifierAction(props) {
    function delitem() {
        props.deleteRow(props.rowIndex);
    }

    return (
        <button className={'ui tiny compact icon button'} onClick={() => delitem()}> <Icon name={"trash"} /></button>
    );
}

export default QualifierAction;
