
import FliptInput from '../../../../components/form/fliptInput'
import FliptButton from '../../../../components/form/fliptButton'
import FliptDatePicker from '../../../../components/form/fliptDatePicker'
import FliptDropdown from '../../../../components/form/fliptDropdown'
import FliptRadio from '../../../../components/form/fliptRadio'
import { Icon } from 'semantic-ui-react'
import usStates from "../../../../redux/reducers/api/utils/states";

const MemberDemographic = (props) => {
  const {
    demographicData,
    _updateDemographicData,
    _checkIfMemberExist,
    _generateMember,
    isPrimaryCardHolder,
    isMemberExist,
    memberChecked,
    updateMemberType,
    relationship,
    addDependentSection,
    updateDependents,
    removeDependent,
    skipRecord,
    ignore_flle_updates
  } = props;
  const gender = ['F', 'M', 'U']
  const type = ["test member", "prod member"];
  const memberType = ["primary", "dependent"];
  const relationshipType = ['spouce', 'child'];
  const skipRecordArr = ["Yes", "No"]

  function generateOptions(value) {
   return value.map((d, i) => ({
     key: i + 1,
     text: d.replace(/_/g, ' '),
     value: d
   }));
  }
  const genderOptions = generateOptions(gender) 
  const typeOptions = generateOptions(type)
  const memberTypeOptions = generateOptions(memberType)
  const relationshipOptions = generateOptions(relationshipType);
  const skipRecordOptions = generateOptions(skipRecordArr);
  const stateOptions = usStates;
  return (
    <div className="content">
      <div className="section">
        <div className="section-header fields-container">
          <h2>Member Demographic Entry</h2>
        </div>
        
        <div className="fields-container">
          <div className="fields-header">
              <FliptDropdown
                value={demographicData?.type}
                options={typeOptions}
                label="Type"
                onChange={_updateDemographicData}             
                stylized
                name="type"
              />
              <FliptDropdown
                value={demographicData?.member_type}
                options={memberTypeOptions}
                label="Member Type"
                onChange={_updateDemographicData}               
                stylized
                name="member_type"
              />
             
           
          </div>
        </div>
        <div className="fields-container">
          <div className="fields-header">
             <FliptInput value={demographicData?.first_name} onChange={_updateDemographicData} stylized name="first_name" label="First Name" />
            <FliptInput value={demographicData?.last_name} onChange={_updateDemographicData} stylized name="last_name" label="Last Name" />
          </div>
        </div>
        <div className="fields-container">
          <div className="fields-header">
            <FliptDatePicker className="margin-right" label="Date Of Birth" placeholder="MM/DD/YYYY" name="dob"
              onChange={_updateDemographicData} value={demographicData.dob} stylized />
            <FliptDropdown
              value={demographicData?.gender}
              options={genderOptions}
              label="Gender"
              onChange={_updateDemographicData}
              stylized
              name="gender"
            />        
           
          </div>
        </div>
        <div className="fields-container">
          <div className="fields-header">
             <FliptDropdown
              value={skipRecord}
              options={skipRecordOptions}
              label="Skip this record from term by absence"
              onChange={_updateDemographicData}
              stylized
              name="skip_this_record_from_term_by_absence"
            />
             <FliptDropdown
              value={ignore_flle_updates}
              options={skipRecordOptions}
              label="Ignore File Updates"
              onChange={_updateDemographicData}
              stylized
              name="ignore_flle_updates"
            />
          </div>
        </div>
      </div>
      <div className="section">
        <div className="fields-container">
          <div className="fields-header">
            <FliptInput value={demographicData?.address1} onChange={_updateDemographicData} stylized name="address1" label="Address 1" />
            <FliptInput value={demographicData?.address2} onChange={_updateDemographicData} stylized name="address2" label="Address 2" />
            <FliptInput value={demographicData?.city} onChange={_updateDemographicData} stylized name="city" label="City" />
            <FliptDropdown
                            value={demographicData?.state}
                            options={stateOptions}
                            label='State'
                            onChange={_updateDemographicData}
                            stylized
                            name='state'
                        />
            <FliptInput value={demographicData?.zipcode} onChange={_updateDemographicData} stylized name="zipcode" label="Zip Code" />
          </div>
        </div>        
      </div>
      <div className="section">
        <div className="fields-container">
          <div className="fields-header">
             {!isPrimaryCardHolder &&
             <>
              <FliptDropdown
              value={demographicData?.relationship}
              options={relationshipOptions}
              label="Relationship"
              onChange={_updateDemographicData}
              stylized
              name="relationship"
            />
             <FliptInput value={demographicData.primary_member_subscriber_id} onChange={_updateDemographicData} stylized name="primary_member_subscriber_id" label="Primary Subscriber Id " />
             </>
            }
           { isPrimaryCardHolder && <FliptInput value={demographicData?.ssn} onChange={_updateDemographicData} stylized name="ssn" label="SSN" />}
            <FliptInput value={demographicData?.medicare_id} onChange={_updateDemographicData} stylized name="medicare_id" label="Medicare Id " />
            <FliptInput value={demographicData?.tpa_id} onChange={_updateDemographicData} stylized name="tpa_id" label="TPA Id" />
           { isPrimaryCardHolder && <FliptInput value={demographicData?.subscriber_id} onChange={_updateDemographicData} stylized name="subscriber_id" label="Subscriber Id" />}
           

          </div>
        </div>
        <div className="fields-container">
          <div className="fields-header">
            <FliptDatePicker className="margin-right" label="Effective Start Date" placeholder="MM/DD/YYYY" name="effective_start_date"
              onChange={_updateDemographicData} value={demographicData.effective_start_date} stylized />
            <FliptDatePicker className="margin-right" label="Effective End Date" placeholder="MM/DD/YYYY" name="effective_end_date"
              onChange={_updateDemographicData} value={demographicData.effective_end_date} stylized />
          </div>
        </div>
      
      </div>
      {demographicData?.dependents?.map((dependent,i) => {
        return (
          <div className="section" key={i}>
            <Icon
              name="close"
              style={{ float: "right" }}
              onClick={() => removeDependent(i)}
            />
            <div className="section-header">Dependent</div>

            <div className="fields-container">
              <div className="fields-header">
                <FliptDropdown
                  value={dependent.relationship}
                  options={relationshipOptions}
                  label="Relationship"
                  onChange={(el, dropdown) => updateDependents(el, dropdown, i)}
                  stylized
                  name="relationship"
                />
              </div>
            </div>
            <div className="fields-container">
              <div className="fields-header">
                <FliptInput
                  value={dependent?.first_name}
                  onChange={(el, dropdown) => updateDependents(el, dropdown, i)}
                  stylized
                  name="first_name"
                  label="First Name"
                />
                <FliptInput
                  value={dependent?.last_name}
                  onChange={(el, dropdown) => updateDependents(el, dropdown, i)}
                  stylized
                  name="last_name"
                  label="Last Name"
                />
                <FliptDatePicker
                  className="margin-right"
                  label="Date Of Birth"
                  placeholder="MM/DD/YYYY"
                  name="date_of_birth"
                  onChange={(el, dropdown) => updateDependents(el, dropdown, i)}
                  value={dependent.dob}
                  stylized
                />
              </div>
            </div>
            <div className="fields-container">
              <div className="fields-header">
                <FliptInput
                  value={dependent?.medicare_id}
                  onChange={(el, dropdown) => updateDependents(el, dropdown, i)}
                  stylized
                  name="medicare_id"
                  label="Medicare Id "
                />
                <FliptInput
                  value={dependent?.tpa_id}
                  onChange={(el, dropdown) => updateDependents(el, dropdown, i)}
                  stylized
                  name="tpa_member_id"
                  label="TPA Id"
                />
                {/* <FliptInput value={dependent?.subscriber_id} onChange={(el,dropdown) => updateDependents(el,dropdown,i)} stylized name="subscriber_id" label="Subscriber Id" /> */}
                <FliptDropdown
                  value={dependent?.gender}
                  options={genderOptions}
                  label="Gender"
                  onChange={(el, dropdown) => updateDependents(el, dropdown, i)}
                  stylized
                  name="gender"
                />
              </div>
            </div>
          </div>
        );
      })}
      <div className="buttons-container">
        <div className="fields">
           <FliptButton name="Add Dependent" 
          className="primary" onClick={addDependentSection} />
        </div>
        <div className="fields">
          <FliptButton name="Add Member" 
          className="primary" onClick={_generateMember} />
        </div>
      </div>
    </div>
  )
}

export default MemberDemographic;
