import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import './style.scss'
import FliptForm from '../../../components/form/fliptForm'
import FliptButton from '../../../components/form/fliptButton'
import FliptInput from '../../../components/form/fliptInput'
import { Creators as UserActions } from '../../../redux/reducers/user'
import { Creators as NavigationActions } from '../../../redux/reducers/navigation'
import { Creators as SSOLoginActions } from '../../../redux/reducers/ssoLogin'
import SSOLogin from './ssoLogin'

class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      form: {
        email_address: null,
        password: null,
      },
    }
  }

  updateFields = (el) => {
    const { name, value } = el.currentTarget

    this.setState(((prevState) => ({
      form: {
        ...prevState.form,
        [name]: value,
      },
    })))
  }

  _onKeyUp = (evt) => {
    if (evt.which === 13) this.userLogin()
  }

  userLogin = () => {
    const { props, state } = this
    props.actions.userLogin(state.form)
  }

  userSSOLogin = () => {
    const { props: { state: { auth0Client }, actions } } = this
    actions.auth0Login(auth0Client)
  }

  render() {
    const { state } = this.props
    const { email_address, password } = this.state
    return (
      <div id="loginPage" className="content-container">
        <div className="left">
          <div className="logo-container">
            <div className="logo-login" />
            <div className="logo-login-default" />
          </div>
          {/* { state?.app?.companyCSSFilename ? <img src={`/i/${state?.app?.companyCSSFilename}/logo-login.png`} alt="Alternate Login Logo" /> : <img hidden alt="Flipt FAST Self Service Application" src="/i/flipt-logo-login.png" /> } */}
        </div>
        <div className="right">
          <div className="header-text">Login to FAST Application</div>
          <FliptForm id="loginForm">
            <FliptInput name="email_address" placeholder="Email Address" onChange={this.updateFields} value={email_address} validation="email_address" required />
            <FliptInput name="password" type="password" placeholder="Password" onChange={this.updateFields} value={password} validation="password" required onKeyPress={this._onKeyUp} />
            <FliptButton className="primary" name="Login" onClick={this.userLogin} />
            <SSOLogin className="primary" name="SSO Login" userSSOLogin={this.userSSOLogin} />
            <Link to="/forgot-password">
              <FliptButton className="tertiary" name="Forgot Password?" />
            </Link>
          </FliptForm>
          <div className="version">
            V
            {state.version}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => (
  {
    state: {
      version: state.app.version,
      auth0Client: state?.ssoLogin?.auth0Client,
      app: state.app,
    },
  }
)
const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...NavigationActions,
    ...UserActions,
    ...SSOLoginActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
