import {
  all,
  call,
  cancel,
  fork,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects'

import {
  getAppState,
  getApiPath,
  getQualifiersState,
  getAllQualifierModels,
} from '../../reducers/selectors'

import _ from 'lodash'

import { getApiUrlHandler } from '../app'
import { Creators as AppActions } from '../../reducers/app'
import { Types, Creators as QualifierActions } from '../../reducers/api/qualifiers'

import { fetchGet, fetchPost, fileUploadPost } from '../../../utils/fetchApi'

export default [
  getMultipleQualifierCriteriaWatcher,
  getQualifierLookupWatcher,
  getQualifierLookupDataWatcher,
  getQualifierModelsWatcher,
  getQualifierCriteriaWatcher,
]

const ERROR = 'error'
const LOADING = 'loading'
const SUCCESS = 'success'

const ALT_NAMES = {
  "chaincode": "chain code",
  "corpname": "corporate name",
  "pharmacystate": "state",
  "pharmacytype": "type",
}

/* WATCHERS */
const ongoingRequests = new Map();
function* getQualifierLookupWatcher() {
  while (true) {
    const action = yield take(Types.GET_QUALIFIER_LOOKUP);
    const { qualifier, lookup_id } = action.payload
    const modelIdsKey = `${qualifier}-${lookup_id}`
    // Check if there is an ongoing request for this model_ids
    if (!ongoingRequests.has(modelIdsKey)) {
      const newTask = yield fork(getQualifierLookupHandler, action);
      ongoingRequests.set(modelIdsKey, newTask);
    }
  }
}

function* getQualifierLookupDataWatcher() {
  yield takeLatest(Types.GET_QUALIFIER_LOOKUP_DATA, getQualifierLookupDataHandler)
}

function* getQualifierModelsWatcher() {
  while (true) {
    const action = yield take(Types.GET_QUALIFIER_MODELS);
    const { model_ids } = action.payload;
    const modelIdsKey = model_ids.sort().join(',');
    // Check if there is an ongoing request for this model_ids
    if (!ongoingRequests.has(modelIdsKey)) {
      const newTask = yield fork(getQualifierModelsHandler, action);
      ongoingRequests.set(modelIdsKey, newTask);
    }
  }
}

function* getMultipleQualifierCriteriaWatcher() {
  yield takeLatest(Types.GET_MULTIPLE_QUALIFIER_CRITERIA, getMultipleQualifierCriteriaHandler)
}

function* getQualifierCriteriaWatcher() {
  yield takeEvery(Types.GET_QUALIFIER_CRITERIA, getQualifierCriteriaHandler)
}

/* HANDLERS */
function* getQualifierLookupHandler({ payload }) {
  try {
    const { qualifier, lookup_id } = payload

    // if lookup model data already exists, skip the api call
    const qualifierState = yield select(getQualifiersState)
    const lookupModelData = qualifierState.qualifierLookupModel || {}
    if (lookupModelData[qualifier]) return

    const { serviceUrl } = yield select(getAppState)
    const { api_path } = yield select(getApiPath, 'qualifier-lookup')
    const url = `${serviceUrl}${api_path}`
    const { data, status, message } = yield call(fetchPost, url, { lookup_id }, true)
    if (data && data.length > 0) {
      yield put(QualifierActions.setQualifierLookupModel({ [qualifier]: data[0] || null }))
    } else {
      const transitionalPortal = {
        header: 'Details not found for this qualifier search',
        copy: 'Details not found for this qualifier search',
      }
      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    }
  } catch (err) {
    console.log('getQualifierLookupDataHandler Error >Data ', err)

    const transitionalPortal = {
      header: 'Qualifier Lookup Failed',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  } finally {
    const { qualifier, lookup_id } = payload || {}
    // Clean up the map when the task is done
    if (qualifier && lookup_id) {
      const modelIdsKey = `${qualifier}-${lookup_id}`
      ongoingRequests.delete(modelIdsKey);
    }
  }
}

function* getQualifierLookupDataHandler({ payload }) {
  try {
    yield put(QualifierActions.clearQualifierLookupData())
    const { serviceUrl } = yield select(getAppState)
    const { api_path } = yield select(getApiPath, 'qualifier-lookup-data')
    const url = `${serviceUrl}${api_path}`
    const { data, status, message } = yield call(fetchPost, url, payload, true)
    if (status === "200" && data) {
      yield put(QualifierActions.setQualifierLookupData(data))
    }
  } catch (err) {
    console.log('getQualifierLookupDataHandler Error >Data ', err)

    const transitionalPortal = {
      header: 'Qualifier Lookup Failed',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* getQualifierModelsHandler({ payload }) {
  try {
    const { model_ids, inclusions } = payload
    const existingModels = yield select(getAllQualifierModels);
    const modelsToFetch = model_ids.filter(modelName => !existingModels.find(model => model.model === modelName));
    if (!modelsToFetch.length) {
      return
    }
    const { serviceUrl } = yield select(getAppState)
    const { api_path: modelsApi } = yield select(getApiPath, 'qualifier-model')
    const getModelsUrl = `${serviceUrl}${modelsApi}`

    const modelsResponse = yield call(fetchPost, getModelsUrl, { model_ids: modelsToFetch }, true)
    if (modelsResponse?.data) {
      const models = [];
      for (let model of modelsResponse.data) {
        if (inclusions && !(model.name in inclusions)) continue;
        const restructuredModel = { model: model.name, title: model.title }
        restructuredModel.properties = Object.keys(model.properties).map(
          (name) => ({ ...model.properties[name], name, altName: getAltName(name) })
        )
        if (inclusions) {
          restructuredModel.properties = restructuredModel.properties.filter((property) => inclusions[model.name][property?.name])
        }
        models.push(restructuredModel);
      }
      yield put(QualifierActions.setQualifierModels(models))
    }
  } catch (err) {
    console.log('getQualifierModelsHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Error occurred while fetching qualifier models',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  } finally {
    const { model_ids } = payload || {}
    // Clean up the map when the task is done
    if (model_ids) {
      const modelIdsKey = model_ids.sort().join(',');
      ongoingRequests.delete(modelIdsKey);
    }
  }
}

function* getMultipleQualifierCriteriaHandler({ payload }) {
  try {
    const { criteria_ids = [] } = payload
    const getCriteriaUrl = yield call(getApiUrlHandler, 'qualifier-criteria')
    const response = yield call(fetchPost, getCriteriaUrl, { criteria_id: criteria_ids }, true)
    if (!response?.data || !response?.data[0]) return;
    const criteriaData = response.data[0];
    const rows = criteria_ids.map((criteria_id) => {
      // criteria_id == '' or with no data will return an empty list
      if (!criteriaData[criteria_id]) return []
      return convertQualifierDatabaseStructToClientSideStruct(criteriaData[criteria_id], criteria_id)
    })
    yield put(QualifierActions.setEntireQualifierRows(rows))
  } catch (err) {
    console.log('getMultipleQualifierCriteria Error ', err)
    const transitionalPortal = {
      header: 'Error occurred while fetching qualifier criteria',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* getQualifierCriteriaHandler({ payload }) {
  try {
    const { criteria_id, stepLevel } = payload
    const { serviceUrl } = yield select(getAppState)
    const { api_path: criteriaApi } = yield select(getApiPath, 'qualifier-criteria')
    const getCriteriaUrl = `${serviceUrl}${criteriaApi}`

    let rows = []
    if (criteria_id) {
      const response = yield call(fetchPost, getCriteriaUrl, { criteria_id }, true)
      if (response?.data?.length) {
        rows = convertQualifierDatabaseStructToClientSideStruct(response.data[0], criteria_id)
      }
    }

    yield put(QualifierActions.setQualifierRows({
      stepLevel,
      rows,
    }))
  } catch (err) {
    console.log('getQualifierCriteriaHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Error occurred while fetching qualifier criteria',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

export function* updateCriteriaIdsHandler({ payload }) {
  try {
    const { conditions: { criteriaIds = [], umConditions = [] } = {}, version = '', status } = payload || {}
    const qualifierState = yield select(getQualifiersState)
    const criteriaRows = qualifierState.qualifierRows

    const isCriteriaRowsDefined = criteriaRows.some((criteriaRows) => criteriaRows?.length > 0)

    if (!isCriteriaRowsDefined) return umConditions.map((cond) => { return '' })

    if (criteriaIds.length || isCriteriaRowsDefined) {
      const { serviceUrl } = yield select(getAppState)
      const { api_path: saveCriteriaApi } = yield select(getApiPath, 'save-qualifier-criteria')
      const saveCriteriaUrl = `${serviceUrl}${saveCriteriaApi}`

      const criteria_list = criteriaRows.map((criteria) => convertClientSideStructToQualifierDatabaseStruct(criteria))

      if (!version || (version === '1.0' && status === 'DRAFT') || !criteriaIds.length) {
        const savedCriteriaIds = yield call(fetchPost, saveCriteriaUrl, { criteria_list }, true)
        return savedCriteriaIds?.data || []
      } else {
        // if not version 1, compare with existing values in database
        const { api_path: criteriaApi } = yield select(getApiPath, 'qualifier-criteria')
        const getCriteriaUrl = `${serviceUrl}${criteriaApi}`
        const { data } = yield call(fetchPost, getCriteriaUrl, { criteria_id: criteriaIds }, true)
        const existingCriteriaList = data.length ? data[0] : []
        const saveCriteriaPayload = []
        for (let i = 0; i < criteria_list.length; i++) {
          const criteriaId = criteria_list[i].id || ''
          const existingCriteriaDoc = existingCriteriaList[criteriaId]
          if (!criteriaId || !existingCriteriaDoc.is_published) {
            saveCriteriaPayload.push({
              ...criteria_list[i],
              is_published: false
            })
            continue
          }
          // if published, compare with existing criteria
          const isCriteriaSame = _.isEqual(existingCriteriaDoc, criteria_list[i])
          if (isCriteriaSame) {
            // if document is same, we send the same doc back
            saveCriteriaPayload.push({ ...existingCriteriaDoc })
          } else {
            // if it is changed, delete id, so api would create a new doc
            saveCriteriaPayload.push({
              ...criteria_list[i],
              id: '',
              is_published: false
            })
          }
        }
        const savedCriteriaIds = yield call(fetchPost, saveCriteriaUrl, { criteria_list: saveCriteriaPayload }, true)
        return savedCriteriaIds?.data || []
      }
    }
  } catch (err) {
    console.log('updateCriteriaIdsHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Error occurred while saving qualifiers',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    throw 'Error occurred while saving qualifiers'
  }
}

/* HELPERS */
function testForOldDatabaseStruct(unTestedDatabaseStructure, criteria_id) {
  // a transitory function that probably shouldn't make it to production if our data is clean
  if (unTestedDatabaseStructure.criteria?.length > 0 && !unTestedDatabaseStructure.criteria[0]?.conditions) {
    return {
      ...unTestedDatabaseStructure,
      "criteria": [
        {
          "conditions": unTestedDatabaseStructure.criteria?.map(condition => ({
            ...condition,
            field: condition.qualifier || condition.field,
          })),
          // create a logic tree for criteria when we want to support something other than AND
        }
      ],
      "id": criteria_id,
      "is_published": !!unTestedDatabaseStructure?.is_published,
    }
  }
  return unTestedDatabaseStructure;
}

function convertQualifierDatabaseStructToClientSideStruct(unTestedDatabaseStructure, criteria_id) {
  const databaseStructure = testForOldDatabaseStruct(unTestedDatabaseStructure, criteria_id)
  let clientStructure = [];
  if (databaseStructure.parent_criteria) {
    databaseStructure.parent_criteria.conditions.forEach(parentCondition => {
      const matchingCriteria = databaseStructure.criteria.find(criterion => criterion.parent_id === parentCondition.id);
      if (matchingCriteria) {
        const combinedObject = {
          ...parentCondition,
          parent_type: databaseStructure.parent_type || '',
          criteria: matchingCriteria.conditions,
          logic_tree: databaseStructure.parent_criteria.logic_tree,
          criteria_id: criteria_id,
          is_published: databaseStructure.is_published,
        };
        clientStructure.push(combinedObject);
      }
    });
  } else {
    // currently not accounting for:
    // 1.multiple criteria when no parent is present
    // 2 logic tree, since it's assumed to be AND for all criteria
    clientStructure = databaseStructure.criteria.map(criterion => ({
      parent_type: databaseStructure.parent_type || '',
      criteria: criterion.conditions,
      criteria_id: criteria_id,
      is_published: databaseStructure.is_published,
    }));
  }

  return clientStructure;
}

/**
 * this function only deal with QL qualifiers. if qualifier doesn't have parent_type defined,
 * it will return the same response.
 * @param {array} clientStructure - list of parent criteria objects
 * 
 */
function convertClientSideStructToQualifierDatabaseStruct(clientStructure) {
  // build criteria list
  const databaseStruct = clientStructure.reduce((databaseCriteriaStruct, parent, index) => {
    // logic tree for criteria are AND
    const newCriteria = { conditions: [], logic_tree: ["AND"] }
    newCriteria.conditions = parent.criteria.map((condition, index) => {
      const conditionWithId = { ...condition }
      if (!condition.id) { conditionWithId.id = "condition" + (index + 1) }
      newCriteria.logic_tree.push(conditionWithId.id)
      return conditionWithId;
    })
    if (parent.id) {
      newCriteria.parent_id = parent.id
      if (!("parent_criteria" in databaseCriteriaStruct)) {
        databaseCriteriaStruct.parent_criteria = { conditions: [], logic_tree: parent.logic_tree }
        databaseCriteriaStruct.parent_type = parent.parent_type;
      }
      const parentCopy = Object.assign({}, parent)
      delete parentCopy.criteria;
      delete parentCopy.parent_type;
      delete parentCopy.criteria_id;
      delete parentCopy.logic_tree;
      databaseCriteriaStruct.parent_criteria.conditions.push(parentCopy)
    }
    databaseCriteriaStruct.criteria.push(newCriteria)
    return databaseCriteriaStruct
  },
    {
      "criteria": [],
    });
  databaseStruct.is_published = clientStructure.every((struct => struct.is_published));
  databaseStruct.id = clientStructure[0]?.criteria_id || '';
  databaseStruct.type = 'criteria_qualifier'
  // todo: build parent_criteria and its logic tree
  return databaseStruct;
}

function getAltName(name) {
  if (name in ALT_NAMES) {
    return ALT_NAMES[name]
  }
  return (name || '').replace(/_/g, ' ');
}
