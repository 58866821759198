import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import './styles.scss'
import BreadCrumb from '../../../../components/breadCrumb'
import { Creators as ApiActions } from '../../../../redux/reducers/api/claimLookupManagement'
import { Creators as AppActions } from '../../../../redux/reducers/app'

import { convertStrToDateObj } from '../../../../utils/utilities'
import FliptGrid from '../../../../components/fliptGrid'

class FinancialInformationReportingDetails extends Component {
    constructor(props) {
        super(props)

        this.state = {
            form: {
                fx_id: '',
                cardholder_id: '',
                start_date: '',
                end_date: '',
                first_name: '',
                last_name: '',
                txnType: '',
            },
            firTxnData: {},
        }
    }

    componentDidMount() {
        const { location, state: { firTxnData } } = this.props
        if (location?.state?.fx_id) {
            const txnData = firTxnData.filter((data) => {
                return data.fx_id === location?.state.fx_id
            })
            if (txnData?.length) this.setState({ firTxnData: txnData[0] })
        }
    }


    handleClick = () => {
        const { form } = this.state
        const { actions } = this.props
        if (form.first_name && !form.last_name) {
            const transitionalPortal = {
                header: 'Fields are missing',
                copy: 'Please add Last Name to search member by name',
            }
            actions.displayTransitionalPortal(transitionalPortal)
            return;
        }
        if (!form.first_name && form.last_name) {
            const transitionalPortal = {
                header: 'Fields are missing',
                copy: 'Please add First Name to search member by name',
            }
            actions.displayTransitionalPortal(transitionalPortal)
            return;
        }
        this.setState({ form })
    }

    handleClear = () => {
        const { form } = this.state
        Object.keys(form).forEach((key) => {
            form[key] = ''
        })
        this.setState({ form })
    }

    updateField = (el, dropdown) => {
        const { form } = this.state
        const { name, value } = dropdown || el.currentTarget
        form[name] = value
        this.setState({ form })
    }

    render() {
        const { state: { memberDetailsData } } = this.props
        const { firTxnData: { request, response }, firTxnData } = this.state
        const selectedMember = memberDetailsData?.find((member) => {
            return member?.flipt_person_id === firTxnData?.flipt_person_id
        })

        const txnType = request?.header?.transaction_code
        const headerObj = {
            request_header: [],
            request_insurance_header: [],
            request_patient_header: [],
            request_reference_header: [],
            request_financial_header: [],
            response_header: [],
            response_status_seg: [],
            response_financial_header: [],
            member_header: []
        }
        if (selectedMember) headerObj.member_header = Object.keys(selectedMember)
        if (request) {
            headerObj.request_header = Object.keys(request?.header)
            headerObj.request_insurance_header = Object.keys(request?.insurance_seg)
            headerObj.request_patient_header = Object.keys(request?.patient_seg)
            headerObj.request_reference_header = Object.keys(request?.reference_seg)
            if (request?.financial_seg?.accumulation_by_month?.length && request?.financial_seg?.accumulation_by_month[0])
                headerObj.request_financial_header = Object.keys(request?.financial_seg?.accumulation_by_month[0])
        }
        if (response) {
            headerObj.response_header = Object.keys(response?.header)
            headerObj.response_status_seg = Object.keys(response?.status_seg)
            if (response?.financial_seg?.accumulation_by_month?.length && response?.financial_seg.accumulation_by_month[0])
                headerObj.response_financial_header = Object.keys(response?.financial_seg.accumulation_by_month[0])
        }
        return (
            <div id="firDetails">
                <BreadCrumb {...this.props} />
                <div className='devider' />
                <div className="main-header">
                    Member Information
                </div>
                <div>
                    <div className='grid-container'>
                        <FliptGrid headers={headerObj.member_header || []}
                            data={[selectedMember] || []} />
                    </div>
                </div>
                <div className='devider' />
                <div className="main-header">
                    Transaction Request
                </div>
                <div>
                    <div className='grid-header'>Header</div>
                    <div className='grid-container'>
                        <FliptGrid headers={headerObj.request_header || []}
                            data={[firTxnData?.request?.header] || []} />
                    </div>
                </div>
                <div>
                    <div className='grid-header'>Insurance Segment</div>
                    <div className='grid-container'>
                        <FliptGrid headers={headerObj.request_insurance_header || []}
                            data={[firTxnData?.request?.insurance_seg] || []} />
                    </div>
                </div>
                <div>
                    <div className='grid-header'>Patient Segment</div>
                    <div className='grid-container'>
                        <FliptGrid headers={headerObj.request_patient_header || []}
                            data={[firTxnData?.request?.patient_seg] || []} />
                    </div>
                </div>
                {(txnType === 'F2' || txnType === 'F3') && <div>
                    <div className='grid-header'>Request Reference Segment</div>
                    <div className='grid-container'>
                        <FliptGrid headers={headerObj.request_reference_header || []}
                            data={[firTxnData?.request?.reference_seg] || []} />
                    </div>
                </div>}
                {(txnType === 'F2' || txnType === 'F3') && <div>
                    <div className='grid-header'>Financial Segment</div>
                    <div className='grid-container'>
                        <FliptGrid headers={headerObj.request_financial_header || []}
                            data={firTxnData?.request?.financial_seg?.accumulation_by_month || []} />
                    </div>
                </div>}
                <div className='devider' />
                <div className="main-header">
                    Transaction Response
                </div>
                <div>
                    <div className='grid-header'>Header</div>
                    <div className='grid-container'>
                        <FliptGrid headers={headerObj.response_header || []}
                            data={[firTxnData?.response?.header] || []} />
                    </div>
                </div>
                <div>
                    <div className='grid-header'>Reporting Response Status Segment</div>
                    <div className='grid-container'>
                        <FliptGrid headers={headerObj.response_status_seg || []}
                            data={[firTxnData?.response?.status_seg] || []} />
                    </div>
                </div>
                <div className='margin-bottom'>
                    <div className='grid-header'>Response Financial Segment</div>
                    <div className='grid-container'>
                        <FliptGrid headers={headerObj.response_financial_header || []}
                            data={firTxnData?.response?.financial_seg?.accumulation_by_month || []} />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    state: {
        firTxnData: state.claimLookup.firTxnData,
        memberDetailsData: state.claimLookup.memberDetailsData,
        user: state.user,
    },
})

const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...ApiActions,
        ...AppActions
    }

    return {
        actions: bindActionCreators(allActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FinancialInformationReportingDetails)
