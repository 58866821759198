import React, { forwardRef } from 'react'
import FliptLabel from './fliptLabel'
import { Input } from 'semantic-ui-react'
import { getVisibilityFunction } from '../../utils/utilities'


import * as fieldValidators from '../../utils/validationHelpers'
import './styles.scss'

const validators = {
  code: fieldValidators.validateCode,
  email_address: fieldValidators.validateEmailAddress,
  numeric: fieldValidators.validateNumericField,
  password: fieldValidators.validatePassword,
  phone: fieldValidators.validatePhoneNumber,
  alphaNumeric: fieldValidators.validateAlphaNumeric,
  name: fieldValidators.validateName,
  zipCode: fieldValidators.validateZipCode
}

const FliptInput = forwardRef((props = {}, ref) => {
  const {
    className, datatype, description, label, required, stylized, validation, customValidation, onChange, onBlur, error, visibilityTable, visibilityGroup,
  } = props

  let { visibility = true, getVisibility = getVisibilityFunction } = props

  if (visibility || visibility === undefined) {
    visibility = getVisibility(visibilityTable, visibilityGroup)
    if (visibility === undefined) visibility = true
  }

  const validateField = (el) => {
    const ct = el.currentTarget

    if (required && !ct.value) {
      ct.classList.add('has-error')
    } else {
      ct.classList.remove('has-error')
    }

    if (validation && validators[validation]) {
      if (!validators[validation](ct.value)) {
        if (validation === 'numeric') ct.value = ct.value.replace(/[^0-9.]/g, '')
        if (validation === 'zipCode') ct.value = ct.value.replace(/[^0-9-]/g, '')
        ct.classList.add('has-error')
      } else {
        ct.classList.remove('has-error')
      }
    } else if (!!customValidation) {
      if (!customValidation(ct.value)) ct.classList.add('has-error')
      else ct.classList.remove('has-error')
    }
  }

  const _onChange = (el) => {
    if (!onChange || typeof onChange !== 'function') return

    const ct = el.currentTarget

    if (validation && validators[validation]) {
      if (validators[validation](ct.value)) {
        onChange(el)
      }
    } else {
      onChange(el)
    }
  }

  const inputProps = { ...props }
  if (inputProps.disabled) {
    inputProps.value = inputProps.value || ''
  }

  // inputProps.default = inputProps.defaultValue || ''
  // const requiredClass = required === true ? 'required' : ''

  const stylizedField = () => {
    const inputType = !!datatype && datatype === 'numeric' ? 'number' : 'text'
    return (visibility ?
      (<div className={`stylized ${className || ''}`}>
        <div className="flipt-label" hidden={inputProps.hidden || false}>
          <FliptLabel
            description={description}
            label={label}
          />
        </div>
        {
          inputProps?.semantic ? (<Input type={inputType} {...inputProps} label={undefined} onKeyUp={validateField} onChange={_onChange} onBlur={onBlur} />) :
            (<>
              <input type={inputType} {...inputProps} onKeyUp={validateField} onChange={_onChange} onBlur={onBlur} />
              {error && <section className="flipt-input-error"><em>{error}</em></section>}
            </>
            )
        }
      </div>
      ) : <div style={{ visibility: 'hidden' }} />
    )
  }
  if (stylized) return stylizedField()


  return inputProps?.semantic ? (visibility ? <Input type={"text"} {...inputProps} label={undefined} onKeyUp={validateField} onChange={_onChange} onBlur={onBlur} /> : <div style={{ visibility: 'hidden' }} />) :
    (visibility ?
      (<>
        <input type="text" {...inputProps} onKeyUp={validateField} ref={ref} />
        {error && <section className="flipt-input-error"><em>{error}</em></section>}
      </>)
      : <div style={{ visibility: 'hidden' }} />
    )

})

export default FliptInput
