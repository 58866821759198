import React from 'react';

function QualifierOperators(props) {
    const { value } = props;

    let operatorOptions = props.operatorOptions;
    return (<select className="criteria-value-dropdown" value={value} defaultValue={value} onChange={(e) => {
        props.updateRow('op', props.rowIndex, { op: e.target.value })
    }}>
        {operatorOptions.map((element, index) => <option key={index}>{element}</option>)}
    </select>);
}

export default QualifierOperators;
