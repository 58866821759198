import React, { useState, useEffect } from 'react'

import './styles.scss'
import FliptButton from '../../../components/form/fliptButton'
import FliptDropdown from '../../../components/form/fliptDropdown'
import FliptInput from '../../../components/form/fliptInput'
import FliptSearch from '../../../components/form/fliptSearch'

export default (props) => {
  const {
    drugDetailOptions,
    drugNameLookup,
    drugNameSearchResults,
    formData,
    formValue,
    getDrugDetails,
    getPrices,
    selectForm,
    updateDrugConfiguration,
  } = props
  const {
    days_of_supply,
    drug_name,
    gpi,
    gppc,
    quantity,
  } = formData
  const {
    forms,
    strengths,
  } = drugDetailOptions
  const [strength, setStrength] = useState(0)

  const updateStrength = (el, dropdown) => {
    const { name, value } = dropdown || el.currentTarget
    const index = strengths.findIndex(e => e.value === value)
    const gpi = index !== -1 && strengths[index]?.gpi || ''
    updateDrugConfiguration(el, { ...dropdown, value: gpi })
    setStrength(index)
  }


  const results = drugNameSearchResults.map((v) => ({ title: v }))
  return (
    <div className="drug-details">
      <span className="subtitle">Drug Details</span>
      <div className="drug-list spacing border-line shadow">
        <div className="drug-detail">
          <div className="drug-input">
            <span>Drug Name</span>
            <FliptSearch
              defaultValue={drug_name || ''}
              name="drug_name"
              onResultSelect={(e, data) => getDrugDetails(e, data)}
              onSearchChange={(e, dropdown) => drugNameLookup(e, dropdown)}
              placeholder="Drug Name"
              results={results}
            />
          </div>
          <div className="drug-input">
            <span>Drug Form</span>
            {forms.length
              ? <FliptDropdown name="drug_form" label="Drug Form" value={formValue} options={forms} onChange={selectForm} />
              : <FliptInput value="" readonly="true" />}
          </div>
          <div className="drug-input">
            <span>Drug Strength</span>
            {strengths.length
              ? <FliptDropdown name="gpi" label="Drug Strength" value={strengths[strength]?.value} options={strengths} onChange={updateStrength} />
              : <FliptInput value="" readonly="true" />}
          </div>
          <div className="drug-input">
            <span>Drug Quantity</span>

            <FliptInput label="Drug Quantity" required name="quantity" value={quantity} validation="numeric" onChange={updateDrugConfiguration} />
          </div>
          <div className="drug-input">
            <span>Days of Supply</span>
            <FliptInput label="Days Of Supply" name="days_of_supply" value={days_of_supply} validation="numeric" onChange={updateDrugConfiguration} />
          </div>
          <FliptButton name="Benefit Price Lookup" className="primary searchButton drugSearchBtn" onClick={getPrices} />
        </div>
      </div>
    </div>
  )
}
