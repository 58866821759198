import React from 'react'
import { Search } from 'semantic-ui-react'

export default function FliptPaSearch(props) {
    const {
        className,
        disabled,
        loading,
        minCharacters,
        name,
        value,
        label,
        containerClassName,
        noResultsMessage = "Searching...",
    } = props

    const inputProps = { ...props }

    return (
        <div className={`${containerClassName || ''} v2-flipt-input-container`}>
            <div className="description-text">
                <div className='flipt-label' hidden={inputProps.hidden || false}>
                    <label htmlFor={label}>{label}</label>
                </div>
            </div>
            <Search {...props} className={className} disabled={disabled} loading={loading} minCharacters={minCharacters || 3} name={name} noResultsMessage={noResultsMessage} value={value} />
        </div>
    )

}
