import React, { useState, useEffect } from 'react'
import { Creators as AppActions } from '../../../../redux/reducers/app'
import { Creators as OpioidManagementActions } from '../../../../redux/reducers/api/opioidManagement'
import './styles.scss'
import FliptHierarchyDropdown from '../../../../components/form/fliptHierarchyDropdown'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import BreadCrumb from '../../../../components/breadCrumb'
import FliptInput from '../../../../components/v2/fliptInput'
import FliptButton from '../../../../components/v2/fliptButton'
import FliptDatePicker from '../../../../components/v2/fliptDatePicker'
import FliptDropdown from '../../../../components/v2/fliptDropdown'
import GeneratedOpioid from './generatedOpioid'
import { convertStrToDateObj } from '../../../../utils/utilities'
import { isEmpty } from 'lodash'


const createOpioidProgram = (props) => {
    const { actions, state: { opioidProgramData, opioidSummaryData }, location } = props
    const [showAcc, setShowAcc] = useState(false)
    const [programForm, setProgramForm] = useState({
        hierarchy: [],
        hierarchyIDs: false,
        hideHierarchy: false,
        doc_name: '',
        program_name: [],
        doc_version: '1.0',
        effective_start_date: '',
        effective_end_date: '',
        hierarchy: [],
        status: 'Draft',
        is_hierarchy_global: true
    })
    const [programOptions, setProgramOptions] = useState([])
    const editMode = location?.state?.editMode
    const viewOnly = location?.state?.viewOnly

    useEffect(() => {
        if (opioidProgramData?.length) {
            const optionData = opioidProgramData.map((data) => {
                return ({ key: data.doc_id, text: data.doc_name, value: data.doc_id })
            })
            setProgramOptions(optionData)
        }
    }, [opioidProgramData])

    useEffect(() => {
        actions.getOpioidProgram()

        if ((editMode || viewOnly) && opioidSummaryData?.length) {
            let index = location.state.data ? opioidSummaryData.findIndex((data) => location.state.data?.program_name === data.doc_name) : location.state?.doc_id ? opioidSummaryData.findIndex((data) => location.state.doc_id === data.doc_id) : -1
            if (index >= 0) {
                const params = {
                    program_name: opioidSummaryData[index].opioids?.length ? opioidSummaryData[index].opioids.map((data) => data.doc_id) : [],
                    status: opioidSummaryData[index].status,
                    doc_name: opioidSummaryData[index].doc_name,
                    doc_version: opioidSummaryData[index].doc_version,
                    effective_start_date: opioidSummaryData[index].effective_start_date,
                    effective_end_date: opioidSummaryData[index].effective_end_date,
                }
                setProgramForm(params)
            }
        }
    }, [])

    const handleChange = (el, dropdown) => {
        const { name, value } = dropdown || el.currentTarget
        const newProgramForm = {
            ...programForm,
            [name]: value,
        }
        setProgramForm(newProgramForm)
    }

    const saveDurProgram = () => {
        const header = 'Fields are missing'
        let copy = 'Please Select Configuration to Proceed'
        if (!programForm.program_name?.length) {
            actions.displayTransitionalPortal({ header, copy })
            return
        }
        if (isEmpty(programForm.doc_name)) {
            copy = 'Please Add Program Name to Proceed'
            actions.displayTransitionalPortal({ header, copy })
            return
        }
        if (!programForm.effective_start_date) {
            copy = 'Please Add Effective Start Date to Proceed'
            actions.displayTransitionalPortal({ header, copy })
            return
        }
        const opioidData = []
        programForm.program_name.forEach((id) => {
            const activeIdIndex = opioidProgramData.findIndex((data) => data.doc_id === id);
            if (activeIdIndex >= 0)
                opioidData.push({
                    doc_id: opioidProgramData[activeIdIndex].doc_id,
                    doc_name: opioidProgramData[activeIdIndex].doc_name,
                    dur_type: opioidProgramData[activeIdIndex].dur_type
                })
        })
        const params = {
            opioids: opioidData,
            status: programForm.status,
            doc_name: programForm.doc_name,
            doc_version: programForm.doc_version,
            effective_start_date: programForm.effective_start_date,
            effective_end_date: programForm.effective_end_date,
            hierarchy: '',
            is_hierarchy_global: programForm.is_hierarchy_global,
        }
        if (editMode) actions.editGlobalOpioid(params)
        else actions.addOpioidProgram(params)
    }

    return (
        <div id='create-opioid'>
            <BreadCrumb {...props} />
            <div className='header'>
                Create Opioid Program
            </div>
            <FliptHierarchyDropdown readOnly setForm={setProgramForm} form={programForm} showHierarchyGlobal={true} />
            <div className='field-container'>
                <FliptInput label='Program Name' disabled={editMode || viewOnly} onChange={handleChange} name='doc_name' value={programForm.doc_name} />
                <FliptInput label='Version' value={programForm.doc_version} disabled />
                <FliptDatePicker label='Start Date' onChange={handleChange} disabled={viewOnly} name='effective_start_date' value={convertStrToDateObj(programForm.effective_start_date)} />
                <FliptDatePicker label='End Date' disabled={viewOnly} onChange={handleChange} name='effective_end_date' value={convertStrToDateObj(programForm.effective_end_date)} />
            </div>
            <div className='field-container'>
                <FliptDropdown multiple={true} disabled={viewOnly} onChange={handleChange} name='program_name' options={programOptions} label='Configuration Name' value={programForm.program_name} />
            </div>
            <FliptButton name='Generate' disabled={!programForm.program_name?.length} onClick={() => setShowAcc(true)} className='primary' />
            {showAcc ? <GeneratedOpioid programName={programForm.program_name} opioidProgramData={opioidProgramData} /> : null}
            <div className='grid-container'>
            </div>
            {viewOnly ? null : <FliptButton name='Save' className='primary' onClick={saveDurProgram} />}
        </div>
    )

}

const mapStateToProps = (state) => ({
    state: {
        opioidProgramData: state.opioidManagement.opioidProgramData,
        opioidSummaryData: state.opioidManagement.opioidSummaryData,
    },
})

const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...AppActions,
        ...OpioidManagementActions,
    }

    return {
        actions: bindActionCreators(allActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(createOpioidProgram)
