import {
    call,
    put,
    select,
    takeLatest,
  } from 'redux-saga/effects'
  
  import { Types, Creators as MedispanLookupActions } from '../../reducers/api/medispanLookup'
  import { Creators as AppActions } from '../../reducers/app'
  import {
    getAppState, getApiPath
  } from '../../reducers/selectors'
  import { fetchPost } from '../../../utils/fetchApi'
import _ from 'lodash'
import moment from 'moment'
  
  export default [
    getMedispanLookupDataWatcher,
  ]
  
  /* WATCHERS */
  function* getMedispanLookupDataWatcher() {
    yield takeLatest(Types.GET_MEDISPAN_LOOKUP_DATA, getMedispanLookupDataHandler)
  }
  
  
  /* HANDLERS */
  // This needs to be again changed for FT-546 so keeping comments
  function* getMedispanLookupDataHandler({ payload }) {
    try {
      const { serviceUrl } = yield select(getAppState)
      const { api_path } = yield select(getApiPath, 'medispan-lookup')
      const url = `${serviceUrl}${api_path}`
      const response = yield call(fetchPost, url, payload)
      const data = response.data[0]
      const medispanSummary = []
      let awp = {}
      let nadac = {}
      if(data["AWP"].length > 0) {
        data["AWP"].forEach( ele => {
          const { ndc } = ele
          let record = ele
          if(ndc in awp)
            record = moment(ele.awp_effective_date)  > moment(awp[ndc].awp_effective_date)? ele:awp[ndc]
          awp[ndc] = record
        })
      }
      if(data["NADAC"].length > 0) {
        data["NADAC"].forEach( ele => {
          const { ndc } = ele
          let record = ele
          if(ndc in nadac)
            record = moment(ele.NADAC_effective_date)  > moment(nadac[ndc].NADAC_effective_date)? ele:nadac[ndc]
          nadac[ndc] = record
        })
      }
      new Set([...Object.keys(awp), ...Object.keys(nadac)]).forEach(ele => {
        let document = {}
        if(ele in awp)
          document = {...awp[ele]}
        if(ele in nadac)
          document = {...document, ...nadac[ele]}
        medispanSummary.push(document)
      })
      yield put(MedispanLookupActions.setMedispanLookupData(medispanSummary))
      yield put(MedispanLookupActions.setMedispanHistoryData(data))
    } catch (err) {
      console.log('getMedispanLookupDataHandler Error > Data ', err)
  
      const transitionalPortal = {
        header: 'Medispan Lookup Failed',
        copy: err.message,
      }
      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    }
  }
  
  export {
    getMedispanLookupDataHandler,
  }
  