import Immutable from 'seamless-immutable'
import { createActions, createReducer } from 'reduxsauce'

export const INITIAL_STATE = Immutable({
  testBatchDetails: {
    batch_name: "",
    batch_type: "",
    batch_id: "",
    benefit_plan: [],
    create_date: "",
    created_by: "",
    formulary_tier: [],
    formulary: [],
    hierarchyKeys: {
      account: [],
      carrier: [],
      client: [],
      group: [],
      organization: [],
    },
    prior_authorization: [],
    programs: [],
    quantity_limit: [],
    status: "",
    step_therapy: [],
    pharmacy_network: [],
    pharmacy_network_tiers: [],
    pharmacy_network_edits: [],
  },
  testBatchClaims: [],
  pharmacyNetworks: {
    pharmacy_networks: [],
    pharmacy_network_edits: [],
    pharmacy_network_tiers: [],
  },
  plans: [],
  testBatches: [],
  umObjects: {
    priorAuths: [],
    programEdits: [],
    quantityLimits: [],
    stepTherapies: [],
    tiers: [],
  },
  uploadedClaimFile: {},
  batchIdClaims: {
    claim_request: [],
    claim_response: [],
  },
  testBeds: [],
  productionClaims: [],
  uploadedBatchCriteria: []
})

export const setUmObjectsReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  umObjects: { ...payload },
})

export const setTestBatchesReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  testBatches: payload,
})

export const clearTestBatchesReducer = (state = INITIAL_STATE) => ({
  ...state,
  testBatches: [],
})

export const setTestBatchDetailsReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  testBatchDetails: payload,
})

export const clearTestBatchDetailsReducer = (state = INITIAL_STATE) => ({
  ...state,
  testBatchDetails: {},
})

export const setTestBatchClaimsReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  testBatchClaims: payload,
})

export const clearTestBatchClaimsReducer = (state = INITIAL_STATE) => ({
  ...state,
  testBatchClaims: [],
})

export const clearUmObjectsReducer = (state = INITIAL_STATE) => ({
  ...state,
  umObjects: {
    programEdits: [],
    tiers: [],
    priorAuths: [],
    quantityLimits: [],
    stepTherapies: [],
  }
})

export const setPlansReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  plans: payload,
})

export const clearPlansReducer = (state = INITIAL_STATE) => ({
  ...state,
  plans: [],
})

export const setPharmacyNetworksReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  pharmacyNetworks: payload,
})

export const clearPharmacyNetworksReducer = (state = INITIAL_STATE) => ({
  ...state,
  pharmacyNetworks: {
    pharmacy_networks: [],
    pharmacy_network_edits: [],
    pharmacy_network_tiers: [],
  }
})

export const setUploadedClaimFileReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  uploadedClaimFile: {
    headers: payload.headers,
    claim_data: payload.claim_data,
  },
})

export const clearUploadedClaimFileReducer = (state = INITIAL_STATE) => ({
  ...state,
  uploadedClaimFile: {},
})

export const setBatchIdClaimsReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  batchIdClaims: payload,
})

export const setTestBedsReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  testBeds: payload,
})

export const clearTestBedsReducer = (state = INITIAL_STATE) => ({
  ...state,
  testBeds: [],
})

export const setProductionClaimsReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  productionClaims: payload,
})

export const clearProductionClaimsReducer = (state = INITIAL_STATE) => ({
  ...state,
  productionClaims: [],
})

export const setUploadedBatchCriteriaReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  uploadedBatchCriteria: payload,
})

export const clearUploadedBatchCriteriaReducer = (state = INITIAL_STATE) => ({
  ...state,
  uploadedBatchCriteria: [],
})

export const { Types, Creators } = createActions({
  addNewAccount: null,
  approveTestBatch: ['payload', 'callback'],
  clearPharmacyNetworks: null,
  clearPlans: null,
  clearTestBatchDetails: null,
  setTestBatchDetails: ['payload'],
  clearTestBatchClaims: null,
  setTestBatchClaims: ['payload'],
  clearTestBatches: null,
  clearUmObjects: null,
  clearUploadedClaimFile: null,
  getPharmacyNetwork: ['payload'],
  getTestBatchConfigPlans: ['payload'],
  getTestBatchDetails: ['payload'],
  getTestBatches: ['payload'],
  getUmObjects: ['payload'],
  rejectTestBatch: ['payload', 'callback'],
  setPharmacyNetworks: ['payload'],
  setPlans: ['payload'],
  setTestBatches: ['payload'],
  setUmObjects: ['payload'],
  setUploadedClaimFile: ['payload'],
  submitTestBatch: ['payload', 'callback'],
  uploadClaimFile: ['payload'],
  setBatchIdClaims: ['payload'],
  getTestClaimsByBatchId: ['payload', 'callback'],
  getTestBatch: ['payload'],
  setTestBeds: ['payload'],
  clearTestBeds: null,
  getTestBeds: ['payload'],
  addTestBed: ['payload'],
  setProductionClaims: ['payload'],
  pullProductionClaims: ['payload'],
  clearProductionClaims: null,
  setUploadedBatchCriteria: ['payload'],
  clearUploadedBatchCriteria: null,
  uploadBatchCriteriaFile: ['payload'],

})

const HANDLERS = {
  [Types.CLEAR_PHARMACY_NETWORKS]: clearPharmacyNetworksReducer,
  [Types.CLEAR_PLANS]: clearPlansReducer,
  [Types.CLEAR_TEST_BATCH_DETAILS]: clearTestBatchDetailsReducer,
  [Types.SET_TEST_BATCH_DETAILS]: setTestBatchDetailsReducer,
  [Types.CLEAR_TEST_BATCH_CLAIMS]: clearTestBatchClaimsReducer,
  [Types.SET_TEST_BATCH_CLAIMS]: setTestBatchClaimsReducer,
  [Types.CLEAR_TEST_BATCHES]: clearTestBatchesReducer,
  [Types.CLEAR_UM_OBJECTS]: clearUmObjectsReducer,
  [Types.CLEAR_UPLOADED_CLAIM_FILE]: clearUploadedClaimFileReducer,
  [Types.SET_PHARMACY_NETWORKS]: setPharmacyNetworksReducer,
  [Types.SET_PLANS]: setPlansReducer,
  [Types.SET_TEST_BATCHES]: setTestBatchesReducer,
  [Types.SET_UM_OBJECTS]: setUmObjectsReducer,
  [Types.SET_UPLOADED_CLAIM_FILE]: setUploadedClaimFileReducer,
  [Types.SET_BATCH_ID_CLAIMS]: setBatchIdClaimsReducer,
  [Types.SET_TEST_BEDS]: setTestBedsReducer,
  [Types.CLEAR_TEST_BEDS]: clearTestBedsReducer,
  [Types.SET_PRODUCTION_CLAIMS]: setProductionClaimsReducer,
  [Types.CLEAR_PRODUCTION_CLAIMS]: clearProductionClaimsReducer,
  [Types.SET_UPLOADED_BATCH_CRITERIA]: setUploadedBatchCriteriaReducer,
  [Types.CLEAR_UPLOADED_BATCH_CRITERIA]: clearUploadedBatchCriteriaReducer,
}

export default createReducer(INITIAL_STATE, HANDLERS)
