import React, { useRef, useState, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import JsonView from "react-json-view";
import { Modal, ModalActions } from 'semantic-ui-react'
// import rawData from './rawData.json'


import BreadCrumb from '../../../components/breadCrumb';
import Spinner from './spinner';

import { Creators as AppActions } from '../../../redux/reducers/app'
import { Creators as UserServiceBrowserActions } from '../../../redux/reducers/api/userServiceBrowser'

import './styles.scss'


const EligibilitySearchAndUpdate = (props) => {
    const loading = props?.state?.userServiceBrowser?.loading;
    const updateSuccess = props?.state?.userServiceBrowser?.update_success
    const [menu, setMenu] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [data, setData] = useState(null);
    const [edits, setEdited] = useState([]);
    const [confirmationModal, triggerConfirmationModal] = useState(false);
    const [fadeIn, setFadeIn] = useState(true);
    const searchInputRef = useRef(null);
    const selectRef = useRef(null);
    const [searchValue, setSearchValue] = useState('username');
    const hasNoEdits = edits && edits.length === 0;

    useEffect(() => {
        props.actions.setSearchResult(null);
        resetState();
    }, []);

    useEffect(() => {
        const searchResult = props?.state?.userServiceBrowser?.search_result || null;
        if (!searchResult) return;
        if (searchResult?.documents) {
            if (searchResult?.documents?.length === 0) {
                setMenu('No results found');
                return;
            }
            setMenu(searchResult.documents);
        } else if (searchResult?.attributes) {
            setMenu([searchResult])
            setSelectedUser(searchResult.attributes.user_id)
            setData(searchResult.attributes)
        } else {
            setMenu('No results found');
        }
    }, [props?.state?.userServiceBrowser?.search_result]);

    useEffect(() => {
        if (!loading) {
            triggerConfirmationModal(false);
            setEdited([]);
        }
    }, [loading])

    useEffect(() => {
        const updateSuccess = props?.state?.userServiceBrowser?.update_success;
        if (updateSuccess) {
            setTimeout(() => {
                setFadeIn(false);
            }, 2100);
            setTimeout(() => {
                props.actions.setUpdateSuccess(false);
            }, 3000)
        } else {
            setFadeIn(true);
        }
    }, [props?.state?.userServiceBrowser?.update_success])

    const resetState = () => {
        setMenu(null)
        setSelectedUser(null)
        setData(null)
        setEdited([])
    }

    const handleEdit = (edit) => {
        if (edit.existing_value === edit.new_value) return;
        setData(edit.updated_src);
        setEdited((prevEdits) => {
            prevEdits.push(edit)
            return prevEdits;
        });
        return true
    };

    const handleOnKeyDown = (e) => {
        if (e.key === 'Enter') {
            fetchData();
        }
    }
    const handleUndo = () => {
        if (hasNoEdits) return;
        setEdited((prevEdits) => {
            const prevData = prevEdits.pop();
            setData(prevData.existing_src);
            return prevEdits;
        });
    }
    const fetchData = () => {
        const inputVal = (searchInputRef.current.value || '').trim();
        const selectVal = selectRef.current.value
        if (!inputVal) return alert('Please enter a value to search');
        resetState();
        props.actions.getUsers({ search_by: selectVal, search_value: inputVal });
    }
    const fieldFilter = (field) => {
        const name = field?.name;
        switch (name) {
            case "attributes":
            case "group_ids":
            case "personal_phones":
            case "locations":
                return true;
            default:
                return false
        }
    }

    const renderMenu = () => {
        if (menu === 'No results found') {
            return (
                <div className="no-results">
                    <h3>No results found</h3>
                </div>
            )
        }
        if (!menu || menu.length === 0) return null;
        return (
            <div className="data-t-container">
                <table className="data-table">
                    <thead>
                        <tr className="data-tr-head">
                            <th className="data-th-username">Username</th>
                            <th className="data-th">First Name</th>
                            <th className="data-th">Last Name</th>
                        </tr>
                    </thead>
                    <tbody>
                        {menu.map((data, i) => (
                            <tr className={data.user_id === selectedUser ? "data-tr-selected" : "data-tr"} key={data.user_id + '-' + i}
                                onClick={() => {
                                    setSelectedUser(data.user_id)
                                    setData(data.attributes || data)
                                    setEdited([])
                                }}>
                                <td className="data-td">{data.username || ''}</td>
                                <td className="data-td">{data.first_name || data.attributes.first_name || ''}</td>
                                <td className="data-td">{data.last_name || data.attributes.last_name || ''}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        )
    }

    const yieldTrueValue = (value, isNew) => {
        if (isNew && value === undefined) {
            return 'deleted'
        }
        if (typeof value === 'boolean') return value ? 'true' : 'false';
        if (value === undefined || value === null) return '';
        return value;
    }

    const renderChanges = () => {
        if (hasNoEdits) return null;
        return edits.map((edit, i) => (
            <div key={i} className="edits-section">
                <section /* old */ className="edits-section-old">
                    <span className="edits-section-old-key">{edit?.name}:</span>{" "}<span className="edits-section-old-value">{yieldTrueValue(edit?.existing_value)}</span>
                </section>
                <section /* new */ className="edits-section-new">
                    <span className="edits-section-new-key">{edit?.name}:</span>{" "}<span className="edits-section-new-value">{yieldTrueValue(edit?.new_value, true)}</span>
                </section>
            </div>
        ));
    }
    const successClasses = fadeIn ? 'page-success-fadein' : 'page-success-fadeout';
    return (
        <main className="page">
            <BreadCrumb {...props} />
            <h2 className="page-header">UserService Browser</h2>
            <div className="page-search-container">
                <label className="page-label" htmlFor="searchby">Search by&nbsp;
                    <select className="page-select" id="searchby" name="searchby" ref={selectRef} onChange={(o) => setSearchValue(o.target.value?.replace(/\_/g, ' ').replace('id', 'ID'))}>
                        <option value="username">username (email)</option>
                        <option value="full_name">Full Name</option>
                        <option value="first_name">First Name</option>
                        <option value="last_name">Last Name</option>
                        <option value="flipt_person_id">flipt_person_id</option>
                        <option value="member_id">member_id</option>
                        <option value="user_id">user_id</option>
                    </select>
                </label>
                <label className="page-label" htmlFor="searchvalue">
                    Search Value&nbsp;
                    <input className="page-input" id="searchvalue" placeholder={`Search ${searchValue}`} ref={searchInputRef} name="searchvalue" onKeyDown={handleOnKeyDown}/>
                </label>
                <button className="page-button" type="button" onClick={fetchData}>Search</button>
            </div>
            <br />
            {(loading && !confirmationModal) && <Spinner />}
            {(updateSuccess) && <div className={successClasses}>Record Updated Successfully</div>}
            <div className="detail-view">
                {menu && (renderMenu())}
                <div id="search_result" className="data-container">
                    {data && (
                        <>
                            <section className="data-bar">
                                <h3 className="data-header">Member Record</h3>
                                <section className="data-bar-section">
                                    <button className={hasNoEdits ? "data-undo__disabled" : "data-undo"}>
                                        <img className={hasNoEdits ? "data-undo-img__disabled" : "data-undo-img"} src="/i/undo-arrow.svg" onClick={handleUndo} />
                                    </button>
                                </section>
                            </section>
                            <JsonView
                                src={data}
                                style={{ minWidth: '645px', maxHeight: '50vh', overflow: 'auto' }}
                                shouldCollapse={fieldFilter}
                                name={false}
                                // theme="grayscale:inverted"
                                onEdit={handleEdit}
                                onAdd={handleEdit}
                                onDelete={handleEdit}
                                displayDataTypes={false}
                            />
                            <section className="data-footer">
                                <button className={hasNoEdits ? "data-undo__disabled" : "data-update"} onClick={() => triggerConfirmationModal(true)}>
                                    Update
                                </button>
                            </section>
                        </>)}
                </div>
                <div className="edits-container">
                    {renderChanges()}
                </div>
            </div>
            <Modal
                closeIcon={true}
                dimmer="inverted"
                onClose={() => triggerConfirmationModal(false)}
                closeOnDimmerClick={true}
                open={confirmationModal}
            >
                {
                    <>
                        <Modal.Content>
                            <div className="confirm-container">
                                <h3 className="confirm-header">Are you sure you want to update this record?</h3>
                                <section className="confirm-body">
                                    <h4 className="confirm-body-header">{loading ? "Updating Record" : "Review Changes"}</h4>
                                    <div className="confirm-body-changes">
                                        {loading ? <Spinner /> : renderChanges()}
                                    </div>
                                </section>
                            </div>
                        </Modal.Content>
                        <ModalActions>
                            <div className="confirm-actions">
                                <button disabled={loading} className="confirm-actions-cancel" onClick={() => triggerConfirmationModal(false)}>
                                    Cancel
                                </button>
                                <button disabled={loading} className="confirm-actions-confirm" onClick={() => props.actions.updateUser(data)}>
                                    Confirm
                                </button>
                            </div>
                        </ModalActions>
                    </>
                }
            </Modal>
        </main>
    )
}

const mapStateToProps = (state) => ({
    state: {
        app: state.app,
        menu: state.app.menu_data,
        userServiceBrowser: state.userServiceBrowser,
    },
})

const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...AppActions,
        ...UserServiceBrowserActions,
    }

    return {
        actions: bindActionCreators(allActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EligibilitySearchAndUpdate)
