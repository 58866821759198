/* eslint-disable */
export default {
  approval_step: {
    type: 'input',
    disabled: false,
    options: [],
  },
  approval_role: {
    type: 'dropdown',
    disabled: false,
    options: [],
  },
}
