import React from 'react'
import './styles.scss'
import { millisToMinutesAndSeconds } from '../../utils/utilities'
import FliptButton from '../../components/form/fliptButton'

export const IdleTimeoutModal = ({ show, remainingTime, resetRemaining: closeIdleTimer }) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none";

  return (
    <div id='idle-timeout' className={showHideClassName}>
      <section className="modal-main">
				<h4>Your session is about to expire</h4>
				<p>
					{millisToMinutesAndSeconds(remainingTime)} remaining
				</p>
        <FliptButton name="Stay Logged In" className="idleTimeoutButton" onClick={() => closeIdleTimer()}/>
      </section>
    </div>
  )
}