import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

// import TierDrugs from './tierDrugs/index'
import "./styles.scss";
import BreadCrumb from "../../../../../components/breadCrumb";
import Config from "../../../../../config";
import SaveTierModal from "./saveTierModal";
import StepTherapyLevels from "./stepTherapyLevels";
import StepTherapyTargetDrugs from "./stepTherapyTargetDrugs";
import _, { cloneDeep } from "lodash";
import drugDemographicData from "../../../../../config/constants/clinical/drugDemographic";
import drugMappingData from "../../../../../config/constants/clinical/drugMapping";
import {
  qualifierType,
  qualifierOperator,
} from "../createStepTherapy/data/qualifier";
import {
  sublist_lookbackCriteria,
  drug_list,
  set_operator,
} from "./data/sublistCriteria";
import { Creators as AppActions } from "../../../../../redux/reducers/app";
import { Creators as AutoSuggestActions } from "../../../../../redux/reducers/api/autoSuggest";
import { Creators as ClinicalCreationActions } from "../../../../../redux/reducers/api/clinicalCreationManagement";
import { Creators as QualifierActions } from "../../../../../redux/reducers/api/qualifiers";
import { Creators as RPMActions } from "../../../../../redux/reducers/rpm";
import { drugGroupValues } from "../../../../../config/constants/clinical/drugGroups";
import { lookbackCriteria } from "./data/stepLevelCriteria";
import {
  parseQueryString,
  incrementVersion,
  convertDateToStartTimeObj,
  convertDateToEndTimeObj,
} from "../../../../../utils/utilities";
import ApprovalOptions from "../../../../../components/approvalOptions";
import { deleteUnnecessaryFields } from "../../../../../utils/utilizationManagement";

class CreateStepTherapy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formStep: 1,
      form: {
        doc_name: "",
        doc_version: "1.0",
        effective_start_date: "",
        doc_id: props.state?.stepTherapyData?.doc_id || "",
        module: "STEP_THERAPY",
        include_inactive_drugs: "",
        version: "1.0",
        status: "DRAFT",
        hierarchy: [],
        is_hierarchy_global: false,
        version_effective_date: "",
        hideHierarchy: false,
      },
      condition: {
        condition_id: "",
        include: true,
        gpi: "",
        drug_group: "",
        drug_class: "",
        drug_subclass: "",
        manufacturer: "",
        drug_name: "",
        multi_source: "",
        ndc: "",
        ddid: "",
        inactive_indicator: "",
        brand_generic: "",
        otc_indicator: "",
        action: "",
        desi_code: "",
        ndc: "",
        route_of_administration: "",
        effective_start_date: "",
        effective_end_date: "",
      },
      targetDrugsConditions: [

      ],
      targetDrugsDropdownValues: [
        {
          drug_group: drugGroupValues,
          drug_class: [],
          drug_subclass: [],
        },
      ],
      targetDrugsAutoSuggestionValues: [
        {
          manufacturer: { values: [], loading: false },
          drug_name: { values: [], loading: false },
        },
      ],
      qualifierDropdownValues: [
        [
          {
            step_therapy_qualifier: qualifierType,
            step_therapy_operator: [],
          },
        ],
      ],
      subListDropdownValues: [
        [
          {
            lookback_criteria_sublist: sublist_lookbackCriteria,
            drug_list: [],
            set_operator: set_operator,
          },
        ],
      ],
      targetDrugsRowUpdated: null,
      targetDrugsColumnUpdated: null,
      activeStepLevel: 0,
      stepTherapyLevels: [
        [
          {
            condition_id: "",
            include: true,
            gpi: "",
            medicare_type: "",
            drug_group: "",
            drug_class: "",
            drug_subclass: "",
            manufacturer: "",
            drug_name: "",
            multi_source: "",
            brand_generic: "",
            inactive_indicator: "",
            otc_indicator: "",
            action: "",
            effective_start_date: "",
            effective_end_date: "",
          },
        ],
      ],
      stepLevelCriteria: [
        {
          lookback_criteria: "",
          lookback_period: "",
          drug_duration: "",
        },
      ],
      stepTherapyQualifierLevels: [
        [
          {
            step_therapy_qualifier: "",
            step_therapy_operator: "",
            step_therapy_value: "",
            action: "",
          },
        ],
      ],
      criteria_sublist: [
        [
          {
            lookback_criteria_sublist: "",
            drug_list: [],
            set_operator: "",
            action: "",
          },
        ],
      ],
      stepLevelCriteriaDropdownValues: [
        {
          lookback_criteria_options: lookbackCriteria,
        },
      ],
      drugList: [
        {
          drug_list_a: [],
          drug_list_b: [],
        },
      ],
      showCriteria: [false],
      dropdownValues: [
        [
          {
            drug_group: drugGroupValues,
            drug_class: [],
            drug_subclass: [],
          },
        ],
      ],
      autoSuggestionValues: [
        [
          {
            manufacturer: { values: [], loading: false },
            drug_name: { values: [], loading: false },
          },
        ],
      ],
      showAdditionalCriteria: [false],
      rowUpdated: null,
      columnUpdated: null,
      levelUpdated: null,
      autoSuggestionMinChars: 3,
      changeInTargetDrugs: false,
      changeInStepLevelDrugs: false,
      changeInTargetManufacturer: false,
      changeInStepLevelManufacturer: false,
      cellRenderers: {

        gpi: {
          overrideHeader: "GPI",
          hide: false,
        },
        medicare_type: {
          hide: true,
          overrideHeader: "Medicare Type Code",
        },
        manufacturer: {
          hide: true,
        },
        multi_source: {
          overrideHeader: "Multi-Source Code",
          hide: false,
        },
        ddid: {
          hide: true,
          overrideHeader: "DDID",
        },
        item_status_flag: {
          hide: true,
          overrideHeader: "Inactive Indicator"
        },
        brand_generic: {
          hide: true,
          overrideHeader: "Brand/Generic",
        },
        otc_indicator: {
          hide: true,
          overrideHeader: "OTC Indicator",
        },
        desi_code: {
          hide: true,
        },
        ndc: {
          hide: true,
        },
        route_of_administration: {
          hide: true,
        },
        maintenance_drug_code: {
          hide: true,
          overrideHeader: "Maintenance Drug Indicator",
        },
        application_type_flag: {
          hide: true,
          overrideHeader: 'App Type',
        },
        mfg_labeler_id: {
          hide: true,
          overrideHeader: 'Labler Code',
        },
        repackaged_code: {
          hide: true,
          overrideHeader: 'Repackaging Indicator',
        },
        third_party_restriction_code: {
          hide: true,
          overrideHeader: 'TPR Code',
        },
        dosage_form_cd: {
          hide: true,
          overrideHeader: 'Dosage Form Code',
        },
        clinic_pack_code: {
          hide: true,
          overrideHeader: 'Clinic Pack',
        },
        innerpack_code: {
          hide: true,
          overrideHeader: 'Inner Pack',
        },
        unit_dose: {
          hide: true,
          overrideHeader: 'Unit Dose',
        },
        dea_class_code: {
          hide: true,
          overrideHeader: 'DEA Class',
        },
        fda_therapeutic_equivalence_code: {
          hide: true,
          overrideHeader: 'FDA Equivalence',
        },
        marketing_category: {
          hide: true,
          overrideHeader: 'Marketing Category',
        },
        storage_condition_code: {
          hide: true,
          overrideHeader: 'Storage Condition',
        },
        unit_dose_with_non_unit_dose_non_repackager_in_gpi: {
          hide: true,
          overrideHeader: 'UD With Non UD RC In GPI',
        },
        rx_with_otc_in_gpi: {
          hide: true,
          overrideHeader: 'Rx With OTC In GPI',
        },
        member_notes: {
          hide: true,
        },
        agent_notes: {
          hide: true,
        },
        internal_notes: {
          hide: true,
        },
        claim_message_code: {
          hide: true,
        },
        claim_message_type: {
          hide: true,
        },
        condition_id: {
          hide: true,
          overrideHeader: "Condition Id"
        },
      },
      stepTherapyDropdownValues: [
        [
          {
            step_therapy_qualifier: qualifierType,
            program_operator: [],
          },
        ],
      ],
    };
  }

  clearFormData() {
    this.setState({
      form: {
        doc_name: "",
        doc_version: "1.0",
        effective_start_date: "",
        effective_start_date: "",
        include_inactive_drugs: "Y",
        doc_id: "",
        module: "STEP_THERAPY",
        version: "",
        status: "DRAFT",
      },
    });
  }

  componentDidMount() {
    const { actions, history } = this.props
    actions.clearStepTherapy()
    actions.clearQualifiers()
    if (!history.location.search) {
      this.clearFormData()
      actions.rpmGetMf2ValData()
      return
    }
    const payload = parseQueryString(history.location.search)
    setTimeout(
      () => {
        actions.getStepTherapyData(payload)
        actions.rpmGetMf2ValData()
      },
      Config.COMPONENT_DATA_LOAD_TIME
    );
  }

  componentDidUpdate(prevProps) {
    const { props, state } = this;
    const { actions } = props;
    const {
      changeInTargetDrugs,
      changeInStepLevelDrugs,
      changeInTargetManufacturer,
      changeInStepLevelManufacturer,
    } = state;
    const prevState = prevProps.state;
    const currState = props.state;
    if (prevState === currState) return;
    if (!_.isEqual(prevState.autoSuggestData, currState.autoSuggestData)) {
      if (changeInTargetDrugs)
        actions.setStepTherapyTargetDrugsDrugNameData(
          currState.autoSuggestData["drug_name"]
        );
      if (changeInStepLevelDrugs)
        actions.setStepTherapyLevelDrugNameData(
          currState.autoSuggestData["drug_name"]
        );
      if (changeInTargetManufacturer)
        actions.setStepTherapyTargetDrugsManufacturerData(
          currState.autoSuggestData["mfg"]
        );
      if (changeInStepLevelManufacturer)
        actions.setStepTherapyLevelManufacturerData(
          currState.autoSuggestData["mfg"]
        );

      actions.clearAutoSuggestData();
      this.setState({
        changeInTargetDrugs: false,
        changeInStepLevelDrugs: false,
        changeInTargetManufacturer: false,
        changeInStepLevelManufacturer: false,
      });
    }
    if (
      !_.isEqual(
        prevState.stepTherapyTargetDrugsDrugNameData,
        currState.stepTherapyTargetDrugsDrugNameData
      ) ||
      !_.isEqual(
        prevState.stepTherapyTargetDrugsManufacturerData,
        currState.stepTherapyTargetDrugsManufacturerData
      )
    )
      this._updateTargetDrugsAutoSuggestions();
    if (
      !_.isEqual(
        prevState.stepTherapyLevelsDrugNameData,
        currState.stepTherapyLevelsDrugNameData
      ) ||
      !_.isEqual(
        prevState.stepTherapyLevelsManufacturerData,
        currState.stepTherapyLevelsManufacturerData
      )
    )
      this._updateAutoSuggestions();
    const hasConditionsChanged =
      prevState.stepTherapyTargetDrugCondData.length !==
      currState.stepTherapyTargetDrugCondData.length &&
      !!currState.stepTherapyTargetDrugCondData.length;
    const hasSTDataChanged =
      prevState.stepTherapyData !== currState.stepTherapyData;
    if (hasConditionsChanged) {
      this._updateState();
    } else if (hasSTDataChanged && currState.stepTherapyData?.doc_id) {
      const { stepTherapyData } = currState;
      // following same practice as _updateState function (not spreading the stepTherapyData)
      const form = {
        ...this.state.form,
        doc_id: stepTherapyData.doc_id,
        doc_name: stepTherapyData.doc_name,
        effective_start_date: stepTherapyData.effective_start_date,
        version_effective_date: stepTherapyData.version_effective_date,
        hierarchy: stepTherapyData.hierarchy,
        is_hierarchy_global: stepTherapyData.is_hierarchy_global,
        hideHierarchy: stepTherapyData.hideHierarchy,
        version: stepTherapyData.version,
        status: stepTherapyData.status,
      };
      this.setState({
        ...this.state,
        form,
      });
    }
  }

  componentWillUnmount() {
    this.props.actions.clearQualifiers();
  }

  _nextFormStep = () => {
    const { props, state } = this;
    const { form, formStep, targetDrugsRowUpdated } = state;
    const hierarchyOrganizationLevel = form?.hierarchy?.[0]?.children || [];
    if (
      !form.doc_name ||
      !form.effective_start_date ||
      targetDrugsRowUpdated === null ||
      hierarchyOrganizationLevel?.length == 0
    ) {
      let msg = "";
      if (!form.doc_name) {
        msg = "Please input a Step Therapy Name before going to next step";
      } else if (!form.effective_start_date) {
        msg = "Please select a start date before going to next step";
      } else if (targetDrugsRowUpdated === null) {
        msg = "Please generate target drugs before going to next step";
      } else {
        msg = "Please assign a Hierarchy before saving";
      }
      const transitionalPortal = {
        header: "Step Therapy Next Step Failed",
        copy: msg,
      };
      props.actions.displayTransitionalPortal(transitionalPortal);
    } else {
      this.setState({
        formStep: formStep + 1,
      });
    }
  };

  _prevFormStep = () => {
    const { formStep } = this.state;
    this.setState({
      formStep: formStep - 1,
    });
  };

  sanitizeString(text) {
    // Keep only alphanumeric characters and spaces, convert to uppercase
    const sanitizedText = text.replace(/[^a-zA-Z0-9 ]/g, '-').toUpperCase();

    // Replace spaces with underscores
    return sanitizedText.replace(/ /g, '-');
  }

  generateConditionId(module, name, version, level, rowNumber) {
    return `${this.sanitizeString(module)}:::${this.sanitizeString(name)}:::${version}:::L${level.toString().padStart(5, '0')}:::${rowNumber.toString().padStart(7, '0')}`
  }

  // eslint-disable-next-line react/destructuring-assignment
  _constructDropdownOptions = () =>
    this.state?.dropdownValues?.map((dropdownValueLevel) =>
      dropdownValueLevel.map((row) => this._mapValueToOption(row))
    );

  // eslint-disable-next-line react/destructuring-assignment
  _constructTargetDrugsDropdownOptions = () =>
    this.state?.targetDrugsDropdownValues?.map((row) =>
      this._mapValueToOption(row)
    );

  // eslint-disable-next-line react/destructuring-assignment
  _constructLevelCriteriaDropdownOptions = () =>
    this.state?.stepLevelCriteriaDropdownValues?.map((row) =>
      this._mapValueToOption(row)
    );

  _constructQualifierDropdownOptions = () =>
    this.state?.qualifierDropdownValues?.map((dropdownValueLevel) =>
      dropdownValueLevel.map((row) => this._mapValueToOption(row))
    );

  _constructSubListDropdownOptions = () =>
    this.state?.subListDropdownValues?.map((dropdownValueLevel) =>
      dropdownValueLevel.map((row) => this._mapValueToOption(row))
    );

  _updateFields = (el, dateData) => {
    const { state } = this;
    const { name, value } = dateData || el.currentTarget;

    // Check if name is 'effective_start_date'
    if (name === "effective_start_date") {
      // Update effective_start_date in targetdrugconditions

      this.setState((prevState) => {
        let updatedTargetDrugsConditionLevels =
          prevState.targetDrugsConditions.map((condition) => {
            return {
              ...condition,
              effective_start_date: value,
            }
          })
        // in current behavior, FliptDatePicker/SemanticDatepicker fires onChange event on first render
        // this trigger this function (_updateFields) and causes effective_start_date for the whole grid to
        // change without any use action. below is the fix for that - we only change grid date if effective_start_date
        // is a date object. please consider this behavior before making any changes.
        if (name === 'effective_start_date' && typeof value !== typeof prevState.form.effective_start_date) {
          updatedTargetDrugsConditionLevels = prevState.targetDrugsConditions
        }
        return {
          ...prevState,
          form: {
            ...prevState.form,
            [name]: value,
          },
          targetDrugsConditions: updatedTargetDrugsConditionLevels,
        }
      })
    } else {
      this.setState((prevState) => {
        let updatedTargetDrugsConditionLevels = prevState.targetDrugsConditions
        if ((name === 'doc_name')) {
          updatedTargetDrugsConditionLevels = updatedTargetDrugsConditionLevels.map(
            (condition, index) => {
              return {
                ...condition,
                condition_id: this.generateConditionId('ST', value, prevState?.form?.doc_version || "1.0", 1, index + 1),
              }
            }
          )
        }
        if ((name === 'doc_version')) {
          updatedTargetDrugsConditionLevels = updatedTargetDrugsConditionLevels.map(
            (condition, index) => {
              return {
                ...condition,
                condition_id: this.generateConditionId('ST', prevState?.form?.doc_name || "", value, 1, index + 1),
              }
            }
          )
        }

        return {
          ...prevState,
          form: {
            ...prevState.form,
            [name]: value,
          },
          targetDrugsConditions: updatedTargetDrugsConditionLevels,
        }
      });
    }
  };

  setForm = (newForm) => {
    this.setState((prevState) => ({
      ...prevState,
      form: newForm,
    }));
  };
  _handleChange = (
    el,
    dropdown,
    rowIndex,
    gridApi,
    stepLevel,
    additionalCriteriaChange
  ) => {
    const { props, state } = this;
    const { actions } = props;
    let allRowConditions;
    let dropdownValues;
    let autoSuggestionValues;
    let rowToUpdate = {};
    let dropDownRowToUpdate;
    let autoSuggestionRowToUpdate;
    const changeInGridEditors = gridApi !== null && gridApi !== undefined;
    const changeInTargetDrugs = stepLevel === null || stepLevel === undefined;

    if (changeInGridEditors && !changeInTargetDrugs) {
      allRowConditions = [...state.stepTherapyLevels];
      dropdownValues = [...state.dropdownValues];
      autoSuggestionValues = [...state.autoSuggestionValues];
      rowToUpdate = allRowConditions[stepLevel][rowIndex];
      dropDownRowToUpdate = dropdownValues[stepLevel][rowIndex];
      autoSuggestionRowToUpdate = autoSuggestionValues[stepLevel][rowIndex];
    } else if (changeInGridEditors && changeInTargetDrugs) {
      allRowConditions = [...state.targetDrugsConditions];
      dropdownValues = [...state.targetDrugsDropdownValues];
      autoSuggestionValues = [...state.targetDrugsAutoSuggestionValues];
      rowToUpdate = allRowConditions[rowIndex];
      dropDownRowToUpdate = dropdownValues[rowIndex];
      autoSuggestionRowToUpdate = autoSuggestionValues[rowIndex];
    }
    const { name, value } = dropdown || el.currentTarget;
    rowToUpdate[name] = value;

    if ((state?.form?.status || "DRAFT") === "PUBLISHED") {
      let condition_grid_version = (parseInt(state.form.doc_version) + 1).toString() + ".0"
      rowToUpdate["condition_id"] = this.generateConditionId("ST", state.form.doc_name, condition_grid_version, 1, rowIndex + 1)
    } else {
      rowToUpdate["condition_id"] = this.generateConditionId("ST", state.form.doc_name, state.form.doc_version, 1, rowIndex + 1)
    }

    if (changeInTargetDrugs && state.targetDrugsRowUpdated != rowIndex) {
      actions.clearStepTherapyTargetDrugsDrugNameData();
      actions.clearStepTherapyTargetDrugsManufacturerData();
    }
    if (
      !changeInTargetDrugs &&
      (state.rowUpdated != rowIndex || state.levelUpdated != stepLevel)
    ) {
      actions.clearStepTherapyLevelDrugNameData();
      actions.clearStepTherapyLevelManufacturerData();
    }

    switch (name) {
      case "gpi": {
        this._onGpiChange(value, rowToUpdate, dropDownRowToUpdate);
        break;
      }
      case "drug_group":
        this._onDrugGroupChange(rowToUpdate, dropDownRowToUpdate);
        break;
      case "drug_class":
        this._onDrugClassChange(rowToUpdate, dropDownRowToUpdate);
        break;
      case "drug_subclass":
        break;
      case "drug_name":
        if (value.length >= state.autoSuggestionMinChars) {
          autoSuggestionRowToUpdate.drug_name.loading = true;
          actions.getAutoSuggestData({
            search_string: value,
            search_in: "fts_ndc_drugs",
            search_for: "drug_name",
          });
          this.setState({
            changeInTargetDrugs,
            changeInStepLevelDrugs: !changeInTargetDrugs,
          });
        }
        break;
      case "manufacturer":
        if (value.length >= state.autoSuggestionMinChars) {
          autoSuggestionRowToUpdate.manufacturer.loading = true;
          actions.getAutoSuggestData({
            search_string: value,
            search_in: "fts_ndc_drugs",
            search_for: "mfg",
          });
          this.setState({
            changeInTargetManufacturer: changeInTargetDrugs,
            changeInStepLevelManufacturer: !changeInTargetDrugs,
          });
        }
        break;
      default:
        break;
    }

    let cellRenderers = { ...state.cellRenderers };

    if (gridApi) {
      const activeColumns = gridApi.columnModel.columnApi
        .getAllGridColumns()
        .filter((col) => {
          if (col.visible) return col.colId;
        });
      const getColumn = Object.keys(cellRenderers);

      activeColumns.forEach((col) => {
        if (getColumn.includes(col.colId) && col.colId != "action") {
          cellRenderers[col.colId]["hide"] = false;
        }
      });
    }

    if (changeInGridEditors && !changeInTargetDrugs) {
      allRowConditions[stepLevel][rowIndex] = rowToUpdate;
      dropdownValues[stepLevel][rowIndex] = dropDownRowToUpdate;
      this.setState({
        stepTherapyLevels: allRowConditions,
        dropdownValues,
        rowUpdated: rowIndex,
        columnUpdated: name,
        cellRenderers: cellRenderers,
      });
      gridApi.refreshCells();
    } else if (changeInGridEditors && changeInTargetDrugs) {
      allRowConditions[rowIndex] = rowToUpdate;
      dropdownValues[rowIndex] = dropDownRowToUpdate;
      this.setState({
        targetDrugsConditions: allRowConditions,
        targetDrugsDropdownValues: dropdownValues,
        targetDrugsRowUpdated: rowIndex,
        targetDrugsColumnUpdated: name,
        levelUpdated: stepLevel,
        cellRenderers: cellRenderers,
      });
      gridApi.refreshCells();
    } else if (!changeInGridEditors && !additionalCriteriaChange) {
      const stepLevelCriteria = [...state.stepLevelCriteria];
      stepLevelCriteria[stepLevel][name] = value;
      const showAdditionalCriteria = [...state.showAdditionalCriteria];
      if (name === "lookback_criteria") {
        if (value.includes("or") || value.includes("not")) {
          showAdditionalCriteria[stepLevel] = true;
        } else {
          showAdditionalCriteria[stepLevel] = false;
        }
      }
      this.setState({
        stepLevelCriteria,
        showAdditionalCriteria,
      });
    } else if (!changeInGridEditors && additionalCriteriaChange) {
      const drugList = [...state.drugList];
      drugList[stepLevel][name] = value;
      this.setState({
        drugList,
      });
    }
  };

  _refreshDrugDemographicDropdowns = (rowToUpdate, dropDownRowToUpdate) => {
    rowToUpdate.drug_group = "";
    rowToUpdate.drug_class = "";
    rowToUpdate.drug_subclass = "";
    dropDownRowToUpdate.drug_group = drugGroupValues;
    dropDownRowToUpdate.drug_class = [];
    dropDownRowToUpdate.drug_subclass = [];
  };

  _onGpiChange = (value, rowToUpdate, dropDownRowToUpdate) => {
    const gpiCode = value;
    const gpiLen = gpiCode.length;
    if (gpiLen === 0) {
      this._refreshDrugDemographicDropdowns(rowToUpdate, dropDownRowToUpdate);
      return;
    }
    const drugGroupCode = gpiCode.slice(0, 2);
    const drugClassCode = gpiCode.slice(2, 4);
    const drugSubClassCode = gpiCode.slice(4, 6);
    this._selectDrugGroup(drugGroupCode, rowToUpdate, dropDownRowToUpdate);
    this._selectDrugClass(
      drugGroupCode,
      drugClassCode,
      rowToUpdate,
      dropDownRowToUpdate
    );
    this._selectDrugSubclass(
      drugGroupCode,
      drugClassCode,
      drugSubClassCode,
      rowToUpdate
    );
  };

  _selectDrugGroup = (gpiCode, rowToUpdate, dropDownRowToUpdate) => {
    if (gpiCode in drugMappingData) {
      rowToUpdate.drug_group = drugMappingData[gpiCode].drug_group;
      this._onDrugGroupChange(rowToUpdate, dropDownRowToUpdate);
    }
  };

  _selectDrugClass = (
    drugGroupCode,
    drugClassCode,
    rowToUpdate,
    dropDownRowToUpdate
  ) => {
    const validDrugClassCode =
      drugGroupCode in drugMappingData &&
      drugClassCode in drugMappingData[drugGroupCode].drug_classes;
    if (validDrugClassCode) {
      rowToUpdate.drug_class =
        drugMappingData[drugGroupCode].drug_classes[drugClassCode].drug_class;
      this._onDrugClassChange(rowToUpdate, dropDownRowToUpdate);
    }
  };

  _selectDrugSubclass = (
    drugGroupCode,
    drugClassCode,
    drugSubClassCode,
    rowToUpdate
  ) => {
    const validDrugClassCode =
      drugGroupCode in drugMappingData &&
      drugClassCode in drugMappingData[drugGroupCode].drug_classes;
    const validDrugSubClassCode =
      validDrugClassCode &&
      drugSubClassCode in
      drugMappingData[drugGroupCode].drug_classes[drugClassCode]
        .drug_subclasses;
    if (validDrugSubClassCode) {
      rowToUpdate.drug_subclass =
        drugMappingData[drugGroupCode].drug_classes[
          drugClassCode
        ].drug_subclasses[drugSubClassCode].drug_subclass;
    }
  };

  _onDrugGroupChange = (rowToUpdate, dropDownRowToUpdate) => {
    rowToUpdate.drug_class = "";
    rowToUpdate.drug_subclass = "";
    dropDownRowToUpdate.drug_class = this._extractDrugDemographics(rowToUpdate);
    dropDownRowToUpdate.drug_subclass = [];
  };

  _onDrugClassChange = (rowToUpdate, dropDownRowToUpdate) => {
    rowToUpdate.drug_subclass = "";
    dropDownRowToUpdate.drug_subclass =
      this._extractDrugDemographics(rowToUpdate);
  };

  _onTargetDrugsAutoSuggestionResultSelect = (data, rowIndex) => {
    const { state } = this;
    const targetDrugsConditions = [...state.targetDrugsConditions];
    const rowToUpdate = targetDrugsConditions[rowIndex];
    const { name, result } = data;
    const value = result.title;
    rowToUpdate[name] = value;
    targetDrugsConditions[rowIndex] = rowToUpdate;
    this.setState({
      targetDrugsConditions,
    });
  };

  _autoSuggestionResultSelect = (data, rowIndex) => {
    const { state } = this;
    const stepTherapyLevels = [...state.stepTherapyLevels];
    const rowToUpdate = stepTherapyLevels[state.activeStepLevel][rowIndex];
    const { name, result } = data;
    const value = result.title;
    rowToUpdate[name] = value;
    stepTherapyLevels[state.activeStepLevel][rowIndex] = rowToUpdate;
    this.setState({
      stepTherapyLevels,
    });
  };

  _delTargetDrugsCondition = (rowIndex) => {
    const {
      targetDrugsConditions,
      targetDrugsDropdownValues,
      targetDrugsAutoSuggestionValues,
    } = this.state;
    //if (targetDrugsConditions.length <= 1) return;
    this.setState({
      targetDrugsConditions: targetDrugsConditions.filter(
        (cond) => targetDrugsConditions.indexOf(cond) !== rowIndex
      ),
      targetDrugsDropdownValues: targetDrugsDropdownValues.filter(
        (value) => targetDrugsDropdownValues.indexOf(value) !== rowIndex
      ),
      targetDrugsAutoSuggestionValues: targetDrugsAutoSuggestionValues.filter(
        (value) => targetDrugsAutoSuggestionValues.indexOf(value) !== rowIndex
      ),
    });
  };

  _addTargetDrugsCondition = () => {
    const {
      targetDrugsConditions,
      targetDrugsDropdownValues,
      targetDrugsAutoSuggestionValues,
      form,
      condition
    } = this.state;
    let no_of_conditions = targetDrugsConditions.length + 1
    let new_id = ""
    if ((form?.status || "DRAFT") === "PUBLISHED") {
      let condition_grid_version = (parseInt(form?.doc_version || "1.0") + 1).toString() + ".0"
      new_id = this.generateConditionId("ST", form?.doc_name || "", condition_grid_version, 1, no_of_conditions)
    } else {
      new_id = this.generateConditionId("ST", form?.doc_name || "", form?.version || "1.0", 1, no_of_conditions)
    }
    this.setState({
      targetDrugsConditions: [
        ...targetDrugsConditions,
        Object.keys(condition).reduce((acc, header) => {
          acc[header] = "";
          if (header === "include") acc[header] = true;
          acc["condition_id"] = new_id
          acc["effective_start_date"] = form.effective_start_date || "";
          return acc;
        }, {})
      ],
      targetDrugsDropdownValues: [
        ...targetDrugsDropdownValues,
        Object.keys(targetDrugsDropdownValues[0]).reduce((acc, header) => {
          if (header === "drug_group") {
            acc[header] = drugGroupValues;
          } else {
            acc[header] = [];
          }
          return acc;
        }, {}),
      ],
      targetDrugsAutoSuggestionValues: [
        ...targetDrugsAutoSuggestionValues,
        Object.keys(targetDrugsAutoSuggestionValues[0]).reduce(
          (acc, header) => {
            acc[header] = { values: [], loading: false };
            return acc;
          },
          {}
        ),
      ],
    });
  };

  _onTargetCheckboxChange = (el, name, rowIndex) => {
    const { targetDrugsConditions } = this.state;
    const {
      target: { checked: value },
    } = el;
    targetDrugsConditions[rowIndex][name] = value
    if ((this.state?.form?.status || "DRAFT") === "PUBLISHED") {
      let condition_grid_version = (parseInt(this.state?.form?.version || "1.0") + 1).toString() + ".0"
      targetDrugsConditions[rowIndex]["condition_id"] = this.generateConditionId("ST", this.state?.form?.doc_name, condition_grid_version, 1, rowIndex + 1)
    } else {
      targetDrugsConditions[rowIndex]["condition_id"] = this.generateConditionId("ST", this.state?.form?.doc_name, this.state?.form?.doc_version || "1.0", 1, rowIndex + 1)
    }
    this.setState({
      targetDrugsConditions,
    })
  }

  _delStepCondition = (rowIndex, stepLevel) => {
    const { stepTherapyLevels, dropdownValues, autoSuggestionValues } =
      this.state;

    const stepLevelToUpdate = [...stepTherapyLevels[stepLevel]];
    const dropdownToUpdate = [...dropdownValues[stepLevel]];
    const autoSuggestionToUpdate = [...autoSuggestionValues[stepLevel]];
    if (stepLevelToUpdate.length <= 1) return;

    const newStepLevel = stepLevelToUpdate.filter(
      (cond) => stepLevelToUpdate.indexOf(cond) !== rowIndex
    );
    stepTherapyLevels[stepLevel] = newStepLevel;

    const newdropdown = dropdownToUpdate.filter(
      (cond) => dropdownToUpdate.indexOf(cond) !== rowIndex
    );
    dropdownValues[stepLevel] = newdropdown;

    const newAutoSuggestion = autoSuggestionToUpdate.filter(
      (cond) => autoSuggestionToUpdate.indexOf(cond) !== rowIndex
    );
    autoSuggestionValues[stepLevel] = newAutoSuggestion;
    this.setState({
      stepTherapyLevels,
      dropdownValues,
      autoSuggestionValues,
    });
  };

  _addStepCondition = (level) => {
    const { state } = this;
    const { form, stepTherapyLevels, dropdownValues, autoSuggestionValues, condition } = state;

    let st_condition_length = stepTherapyLevels?.[0]?.length || 0
    let st_condition_object = condition
    if (st_condition_length > 0) {
      st_condition_object = stepTherapyLevels[0][0]
    }
    // let no_of_conditions = stepTherapyLevels[level].length + 1
    // let new_id = ""
    // if ((form?.status || "DRAFT") === "PUBLISHED"){
    //   let condition_grid_version = (parseInt(form?.doc_version || "1.0") + 1).toString() + ".0"
    //   new_id= this.generateConditionId("ST",  form?.doc_name || "", condition_grid_version,  level + 1, no_of_conditions)
    // }else{
    //   new_id = this.generateConditionId("ST",  form?.doc_name || "", form?.doc_version || "1.0", level + 1, no_of_conditions)
    // }
    const updatedStepTherapyLevels = stepTherapyLevels[level].concat(
      Object.keys(st_condition_object).reduce((acc, header) => {
        acc[header] = "";
        if (header === "include") acc[header] = true;
        acc["effective_start_date"] = form.effective_start_date || "";
        return acc;
      }, {})
    );
    const updatedDropdownValues = dropdownValues[level].concat(
      Object.keys(dropdownValues[0][0]).reduce((acc, header) => {
        if (header === "drug_group") {
          acc[header] = drugGroupValues;
        } else {
          acc[header] = [];
        }
        return acc;
      }, {})
    );
    const updatedAutoSuggestionValues = autoSuggestionValues[level].concat(
      Object.keys(autoSuggestionValues[0][0]).reduce((acc, header) => {
        acc[header] = { values: [], loading: false };
        return acc;
      }, {})
    );
    stepTherapyLevels[level] = updatedStepTherapyLevels;
    dropdownValues[level] = updatedDropdownValues;
    autoSuggestionValues[level] = updatedAutoSuggestionValues;
    this.setState({
      stepTherapyLevels,
      dropdownValues,
      autoSuggestionValues,
    });
  };

  checkDateValidation = (tierConditions) => {
    const { actions } = this.props;
    let invalidDate = false;
    let formattedStConditions = tierConditions.map((tierData) => {
      if (!tierData.effective_start_date) {
        invalidDate = true;
      }
      return {
        ...tierData,
        effective_start_date: convertDateToStartTimeObj(
          tierData.effective_start_date
        ),
        effective_end_date: convertDateToEndTimeObj(
          tierData.effective_end_date
        ),
      };
    });
    if (invalidDate) {
      const transitionalPortal = {
        header: "Missing Effective Start Date in conditions",
        copy: `Please Add Step Therapy Condition Details (Eff Start Date, Drug Details)`,
      };
      actions.displayTransitionalPortal(transitionalPortal);
    }
    return { formattedStConditions, invalidDate };
  };

  _addNewStepLevel = () => {
    const { state } = this;
    const stepTherapyLevels = [...state.stepTherapyLevels];
    const stepLevelCriteria = [...state.stepLevelCriteria];
    const stepTherapyQualifierLevels = [...state.stepTherapyQualifierLevels];
    const criteria_sublist = [...state.criteria_sublist];
    const stepLevelCriteriaDropdownValues = [
      ...state.stepLevelCriteriaDropdownValues,
    ];
    const drugList = [...state.drugList];
    const dropdownValues = [...state.dropdownValues];
    const autoSuggestionValues = [...state.autoSuggestionValues];
    const showCriteria = [...state.showCriteria];
    const showAdditionalCriteria = [...state.showAdditionalCriteria];
    const qualifierDropdownValues = [...state.qualifierDropdownValues];
    const subListDropdownValues = [...state.subListDropdownValues];

    stepTherapyLevels.push([
      {
        gpi: "",
        drug_group: "",
        drug_class: "",
        drug_subclass: "",
        manufacturer: "",
        drug_name: "",
        multi_source: "",
        brand_generic: "",
        otc_indicator: "",
        action: "",
        effective_start_date: state.form.effective_start_date || "",
        effective_end_date: "",
      },
    ]);
    stepLevelCriteria.push({
      lookback_criteria: "",
      lookback_period: "",
      drug_duration: "",
    });
    stepTherapyQualifierLevels.push([
      {
        step_therapy_qualifier: "",
        step_therapy_operator: "",
        step_therapy_value: "",
        action: "",
      },
    ]);
    criteria_sublist.push([
      {
        lookback_criteria_sublist: "",
        drug_list: [],
        set_operator: "",
        action: "",
      },
    ]),
      stepLevelCriteriaDropdownValues.push({
        lookback_criteria_options: lookbackCriteria,
      });
    drugList.push({
      drug_list_a: [],
      drug_list_b: [],
    });
    dropdownValues.push([
      { drug_group: drugGroupValues, drug_class: [], drug_subclass: [] },
    ]);
    autoSuggestionValues.push([
      {
        manufacturer: { values: [], loading: false },
        drug_name: { values: [], loading: false },
      },
    ]);
    qualifierDropdownValues.push([
      { step_therapy_qualifier: qualifierType, step_therapy_operator: [] },
    ]);
    subListDropdownValues.push([
      {
        lookback_criteria_sublist: sublist_lookbackCriteria,
        drug_list: [],
        set_operator: set_operator,
      },
    ]);
    showCriteria.push(true);
    showAdditionalCriteria.push(false);
    this.setState({
      activeStepLevel: stepTherapyLevels.length - 1,
      stepTherapyLevels,
      stepLevelCriteria,
      stepLevelCriteriaDropdownValues,
      stepTherapyQualifierLevels,
      criteria_sublist,
      drugList,
      dropdownValues,
      autoSuggestionValues,
      showCriteria,
      showAdditionalCriteria,
      qualifierDropdownValues,
      subListDropdownValues,
    });
  };

  _handleQualifierChange = (el, dropdown, rowIndex, gridApi, stepLevel) => {
    const { state } = this;
    const { form } = state;
    const stepTherapyQualifierLevels = [...state.stepTherapyQualifierLevels];
    const rowToUpdate = stepTherapyQualifierLevels[stepLevel][rowIndex];
    const { name, value } = dropdown || el.currentTarget;
    rowToUpdate[name] = value;
    stepTherapyQualifierLevels[stepLevel][rowIndex] = rowToUpdate;
    const qualifierDropdownValues = [...state.qualifierDropdownValues];
    // test const subListDropdownValues = [...state.subListDropdownValues]
    const dropDownRowToUpdate = qualifierDropdownValues[stepLevel][rowIndex];
    const options1 = [
      "Gender",
      "Diagnosis",
      "Pharmacy",
      "Prescriber",
      "Taxonomy",
      "Specialty",
      "Medicare Eligible",
    ];
    const option2 = ["Lookback"];
    if (name === "step_therapy_qualifier") {
      if (options1.includes(value)) {
        dropDownRowToUpdate.step_therapy_operator = ["!=", "="];
      } else if (option2.includes(value)) {
        dropDownRowToUpdate.step_therapy_operator = ["<", "<="];
      } else if (
        ["Maximum Days of Supply", "Minimum Days of Supply"].includes(value)
      ) {
        dropDownRowToUpdate.step_therapy_operator = qualifierOperator.filter(
          (e) => e !== "!="
        );
      } else {
        dropDownRowToUpdate.step_therapy_operator = qualifierOperator;
      }
      qualifierDropdownValues[stepLevel][rowIndex] = dropDownRowToUpdate;
    }

    this.setState({
      stepTherapyQualifierLevels,
      dropDownRowToUpdate,
    });
    gridApi.refreshCells();
  };

  _handleSubListChange = (el, dropdown, rowIndex, gridApi, stepLevel) => {
    const { state } = this;
    const { form } = state;
    const criteria_sublist = [...state.criteria_sublist];
    const rowToUpdate = criteria_sublist[stepLevel][rowIndex];
    const { name, value } = dropdown || el.currentTarget;
    rowToUpdate[name] = value;
    criteria_sublist[stepLevel][rowIndex] = rowToUpdate;
    const subListDropdownValues = [...state.subListDropdownValues];
    const dropDownSubListRowToUpdate =
      subListDropdownValues[stepLevel][rowIndex];

    this.setState({
      criteria_sublist,
      dropDownSubListRowToUpdate,
    });
    gridApi.refreshCells();
  };

  _deleteStepLevel = (e, stepLevel) => {
    const { state } = this;
    const stepTherapyLevels = [...state.stepTherapyLevels];
    const stepLevelCriteria = [...state.stepLevelCriteria];
    const stepTherapyQualifierLevels = [...state.stepTherapyQualifierLevels];
    const criteria_sublist = [...state.criteria_sublist];
    const stepLevelCriteriaDropdownValues = [
      ...state.stepLevelCriteriaDropdownValues,
    ];
    const drugList = [...state.drugList];
    const dropdownValues = [...state.dropdownValues];
    const autoSuggestionValues = [...state.autoSuggestionValues];
    const showCriteria = [...state.showCriteria];
    const showAdditionalCriteria = [...state.showAdditionalCriteria];

    if (stepTherapyLevels.length <= 1) return;

    showCriteria.splice(stepLevel, 1);
    showAdditionalCriteria.splice(stepLevel, 1);
    this.setState({
      activeStepLevel: 0,
      stepTherapyLevels: stepTherapyLevels.filter(
        (cond) => stepTherapyLevels.indexOf(cond) !== stepLevel
      ),
      stepLevelCriteria: stepLevelCriteria.filter(
        (cond) => stepLevelCriteria.indexOf(cond) !== stepLevel
      ),
      stepTherapyQualifierLevels: stepTherapyQualifierLevels.filter(
        (cond) => stepTherapyQualifierLevels.indexOf(cond) !== stepLevel
      ),
      criteria_sublist: criteria_sublist.filter(
        (cond) => criteria_sublist.indexOf(cond) !== stepLevel
      ),
      stepLevelCriteriaDropdownValues: stepLevelCriteriaDropdownValues.filter(
        (cond) => stepLevelCriteriaDropdownValues.indexOf(cond) !== stepLevel
      ),
      drugList: drugList.filter((cond) => drugList.indexOf(cond) !== stepLevel),
      dropdownValues: dropdownValues.filter(
        (cond) => dropdownValues.indexOf(cond) !== stepLevel
      ),
      autoSuggestionValues: autoSuggestionValues.filter(
        (cond) => autoSuggestionValues.indexOf(cond) !== stepLevel
      ),
      showCriteria,
      showAdditionalCriteria,
    });
  };

  _onStepCheckboxChange = (el, name, levelIndex, rowIndex) => {
    const { stepTherapyLevels } = this.state
    const {
      target: { checked: value },
    } = el;

    stepTherapyLevels[levelIndex][rowIndex][name] = value
    this.setState({
      stepTherapyLevels,
      rowUpdated: rowIndex,
      columnUpdated: name,
    });
  }

  _handleStepLevelClick = (e, titleProps) => {
    const { state } = this;
    const { activeStepLevel } = state;
    const { index } = titleProps;
    const newIndex = activeStepLevel === index ? -1 : index;
    this.setState({ activeStepLevel: newIndex });
  };

  _generateStepTherapy = (el, stepLevel) => {
    const { props, state } = this;
    // const fetchTargetDrugs = stepLevel === null || stepLevel === undefined;
    // if (fetchTargetDrugs) {

    //   const { targetDrugsConditions } = state;
    //   const { formattedStConditions, invalidDate } = this.checkDateValidation(
    //     targetDrugsConditions
    //   );
    //   if (invalidDate) return;
    //   props.actions.clearStepTherapyTargetDrugs();
    //   setTimeout(
    //     () =>
    //       props.actions.generateStepTherapyDrugs({
    //         drugConditions: formattedStConditions.filter((x) => x.include).map(dict => {
    //           return deleteUnnecessaryFields(dict)
    //         }),
    //         drugExclusions: formattedStConditions.filter((x) => !x.include).map(dict => {
    //           return deleteUnnecessaryFields(dict)
    //         }),
    //         fetchTargetDrugs,
    //       }),
    //     250
    //   );
    // } else {
    const { stepTherapyLevels } = state;
    const stepTherapyLevelsConditions = stepTherapyLevels[stepLevel];
    const showCriteria = [...state.showCriteria];
    showCriteria[stepLevel] = true;
    this.setState({
      showCriteria,
    });
    props.actions.clearStepTherapyLevelDrugs();
    setTimeout(
      () =>
        props.actions.generateStepTherapyLevelDrugs({
          drugConditions: stepTherapyLevelsConditions.filter((x) => x.include).map(dict => {
            return deleteUnnecessaryFields(dict)
          }),
          drugExclusions: stepTherapyLevelsConditions.filter((x) => !x.include).map(dict => {
            return deleteUnnecessaryFields(dict)
          }),
          // fetchTargetDrugs,
          stepLevel,
        }),
      250
    );
    // }
  };

  validateForm = () => {
    const {
      props,
      props: {
        state: { criteriaIds },
      },
      state,
    } = this;
    const {
      form,
      targetDrugsConditions,
      stepTherapyLevels,
      stepLevelCriteria,
      drugList,
      stepTherapyQualifierLevels,
      criteria_sublist,
    } = state;

    messages = []
    if (!form.doc_name) message.push("Please Add Step Therapy Name.")
    if (!form.effective_start_date) message.push("Please Add Step Therapy Effective Start Date.")
    if (!form.effective_start_date) message.push("Please Add Step Therapy Version Effective Date.")

  }

  _saveStepTherapy = () => {
    const {
      props,
      props: {
        state: { criteriaIds },
      },
      state,
    } = this;
    const {
      form,
      targetDrugsConditions,
      stepTherapyLevels,
      stepLevelCriteria,
      drugList,
      stepTherapyQualifierLevels,
      criteria_sublist,
    } = state;
    const { formattedStConditions, invalidDate } = this.checkDateValidation(
      targetDrugsConditions
    );




    if (invalidDate) return;
    const message =
      "You are about to save the step therapy. Do you wish to save?";
    props.actions.setModalComponent({
      modalProperties: {
        size: "tiny",
      },
      contents: SaveTierModal({
        form,
        conditions: { criteriaIds, umConditions: formattedStConditions },
        targetDrugsConditions: formattedStConditions,
        stepTherapyLevels,
        stepLevelCriteria,
        criteria_sublist,
        stepTherapyQualifierLevels,
        drugList,
        onSaveClick: props.actions.saveStepTherapyData,
        toggleModal: props.actions.toggleModal,
        message,
      }),
    });
    props.actions.toggleModal();
  };

  _mapValueToOption = (row) => {
    const data = {
      ...row,
    };
    Object.keys(data).forEach((col) => {
      if (Array.isArray(data[col])) {
        data[col] = data[col].map((group, index) => ({
          key: index,
          text: group,
          value: group,
        }));
      }
    });
    return data;
  };

  _extractDrugDemographics = (selectedValues) => {
    const validKeys = ["drug_group", "drug_class"];
    const extractedKeys = validKeys.filter((key) => !!selectedValues[key]);
    const extractedData = extractedKeys.reduce(
      (obj, key) => ({
        ...obj[selectedValues[key]],
      }),
      drugDemographicData
    );
    return Object.keys(extractedData);
  };

  _updateDropdownFromCond = (initDropdownValues, conditions, nested) => {
    let dropdownValues = [];
    if (!nested) {
      dropdownValues = Array(conditions.length)
        .fill()
        .map(() => ({ ...initDropdownValues[0] }));
    } else {
      conditions.forEach((cond) => {
        const dropdownRowValues = [];
        cond?.forEach(() => {
          dropdownRowValues.push({ ...initDropdownValues[0][0] });
        });
        dropdownValues.push(dropdownRowValues);
      });
    }

    conditions.forEach((cond, index) => {
      const values = {};
      if (cond.drug_group) {
        values.drug_group = cond.drug_group;
        dropdownValues[index].drug_class =
          this._extractDrugDemographics(values);
      }
      if (cond.drug_class) {
        values.drug_class = cond.drug_class;
        dropdownValues[index].drug_subclass =
          this._extractDrugDemographics(values);
      }
      if (cond.drug_subclass) {
        values.drug_subclass = cond.drug_subclass;
      }
    });
    return dropdownValues;
  };

  _updateAutoSuggestionFromCond = (initAutoSuggestionValues, conditions) => {
    const autoSuggestionValues = [];
    conditions.forEach((cond) => {
      const autoSuggestionRowValues = [];
      cond?.forEach(() => {
        autoSuggestionRowValues.push({ ...initAutoSuggestionValues[0][0] });
      });
      autoSuggestionValues.push(autoSuggestionRowValues);
    });
    return autoSuggestionValues;
  };

  _delQualifierRow = (rowIndex, stepLevel) => {
    const { stepTherapyQualifierLevels, qualifierDropdownValues } = this.state;
    const stepTherapyQualifierLevelToUpdate = [
      ...stepTherapyQualifierLevels[stepLevel],
    ];
    const stepTherapyQualifierDropdownToUpdate = [
      ...qualifierDropdownValues[stepLevel],
    ];

    if (stepTherapyQualifierLevelToUpdate.length <= 1) return;
    if (stepTherapyQualifierDropdownToUpdate.length <= 1) return;
    const newStepTherapyQualifierLevel =
      stepTherapyQualifierLevelToUpdate.filter(
        (cond) => stepTherapyQualifierLevelToUpdate.indexOf(cond) !== rowIndex
      );
    const newQualifierDropdownValues =
      stepTherapyQualifierDropdownToUpdate.filter(
        (cond) =>
          stepTherapyQualifierDropdownToUpdate.indexOf(cond) !== rowIndex
      );
    stepTherapyQualifierLevels[stepLevel] = newStepTherapyQualifierLevel;
    qualifierDropdownValues[stepLevel] = newQualifierDropdownValues;
    this.setState({
      stepTherapyQualifierLevels: stepTherapyQualifierLevels,
      qualifierDropdownValues: newQualifierDropdownValues,
    });
  };

  _delSubListRow = (rowIndex, stepLevel) => {
    const { criteria_sublist, subListDropdownValues } = this.state;
    const criteriaSublistLevelToUpdate = [...criteria_sublist[stepLevel]];
    const subListDropdownValuesToUpdate = [...subListDropdownValues[stepLevel]];

    if (criteriaSublistLevelToUpdate.length <= 1) return;
    if (subListDropdownValuesToUpdate.length <= 1) return;

    const newCriteriaSublistLevel = criteriaSublistLevelToUpdate.filter(
      (cond) => criteriaSublistLevelToUpdate.indexOf(cond) !== rowIndex
    );
    const newSublistDropdownValues = subListDropdownValuesToUpdate.filter(
      (cond) => subListDropdownValuesToUpdate.indexOf(cond) !== rowIndex
    );
    criteria_sublist[stepLevel] = newCriteriaSublistLevel;
    subListDropdownValues[stepLevel] = newSublistDropdownValues;

    this.setState({
      criteria_sublist: criteria_sublist,
      subListDropdownValues: subListDropdownValues,
    });
  };

  _addQualifierRow = (level) => {
    const { stepTherapyQualifierLevels, qualifierDropdownValues } = this.state;
    const newstql = cloneDeep(stepTherapyQualifierLevels);
    const updatedStepTherapyQualifierLevels = newstql[level].concat(
      Object.keys(newstql[0][0]).reduce((acc, header) => {
        acc[header] = "";
        return acc;
      }, {})
    );

    const updatedDropdownValues = qualifierDropdownValues[level].concat(
      Object.keys(qualifierDropdownValues[0][0]).reduce((acc, header) => {
        if (header === "step_therapy_qualifier") {
          acc[header] = qualifierType;
        } else {
          acc[header] = [];
        }
        return acc;
      }, {})
    );

    newstql[level] = updatedStepTherapyQualifierLevels;
    qualifierDropdownValues[level] = updatedDropdownValues;
    this.setState({
      stepTherapyQualifierLevels: newstql,
      qualifierDropdownValues,
    });
  };

  _addSubListRow = (level) => {
    const { criteria_sublist, subListDropdownValues } = this.state;
    const newstql = cloneDeep(criteria_sublist);
    const updatedCriteriaSublistLevels = newstql[level].concat(
      Object.keys(newstql[0][0]).reduce((acc, header) => {
        acc[header] = "";
        return acc;
      }, {})
    );

    const updatedDropdownValues = subListDropdownValues[level].concat(
      subListDropdownValues[0][0]
    );
    newstql[level] = updatedCriteriaSublistLevels;
    subListDropdownValues[level] = updatedDropdownValues;
    this.setState({
      criteria_sublist: newstql,
      subListDropdownValues,
    });
  };

  _updateState() {
    const { props, state } = this;
    const {
      targetDrugsDropdownValues,
      targetDrugsAutoSuggestionValues,
      dropdownValues,
      autoSuggestionValues,
      stepLevelCriteriaDropdownValues,
    } = state;
    const {
      stepTherapyData,
      stepTherapyTargetDrugCondData,
      stepTherapyLevelsData,
      stepTherapyLevelsCriteria,
      stepTherapyLevelsDrugList,
      stepTherapyQualifierLevelsData,
      criteria_sublist,
    } = props.state;
    if (props.location.state.editMode) {
      stepTherapyData.doc_version = incrementVersion(
        stepTherapyData.doc_version
      );
    }
    const updatedTargetDrugsDropdownValues = this._updateDropdownFromCond(
      targetDrugsDropdownValues,
      stepTherapyTargetDrugCondData,
      false
    );
    const updatedTargetDrugsAutoSuggestionValues = Array(
      stepTherapyTargetDrugCondData.length
    )
      .fill()
      .map(() => ({ ...targetDrugsAutoSuggestionValues[0] }));
    const updatedLevelsDropdownValues = this._updateDropdownFromCond(
      dropdownValues,
      stepTherapyLevelsData,
      true
    );
    const updatedLevelsAutoSuggestionValues =
      this._updateAutoSuggestionFromCond(
        autoSuggestionValues,
        stepTherapyLevelsData
      );
    const updatedStepLevelCriteriaDropdownValues = Array(
      stepTherapyLevelsData.length
    )
      .fill()
      .map(() => ({ ...stepLevelCriteriaDropdownValues[0] }));
    const updatedShowCriteria = Array(stepTherapyLevelsData.length).fill(true);
    const updatedShowAdditionalCriteria = Array(
      stepTherapyLevelsData.length
    ).fill(true);
    const form = {
      ...state.form,
      doc_id: stepTherapyData.doc_id,
      doc_name: stepTherapyData.doc_name,
      effective_start_date: stepTherapyData.effective_start_date,
      hierarchy: stepTherapyData.hierarchy,
      is_hierarchy_global: stepTherapyData.is_hierarchy_global,
      hideHierarchy: stepTherapyData.hideHierarchy,
      version: stepTherapyData.version,
      status: stepTherapyData.status,
      version_effective_date: stepTherapyData.version_effective_date,
    };
    if (stepTherapyTargetDrugCondData?.length) {
      stepTherapyTargetDrugCondData.map((outerData, index) => {
        if (
          !outerData.effective_start_date &&
          stepTherapyData.effective_start_date
        )
          outerData.effective_start_date = convertDateToStartTimeObj(
            stepTherapyData.effective_start_date
          );

        outerData.condition_id = outerData?.condition_id || this.generateConditionId("ST", form.doc_name, form.doc_version, 1, index + 1)

      });
    }

    this.setState({
      form,
      targetDrugsConditions: stepTherapyTargetDrugCondData,
      stepTherapyLevels: stepTherapyLevelsData,
      stepTherapyQualifierLevels: stepTherapyQualifierLevelsData,
      criteria_sublist: criteria_sublist,
      targetDrugsDropdownValues: updatedTargetDrugsDropdownValues,
      targetDrugsAutoSuggestionValues: updatedTargetDrugsAutoSuggestionValues,
      dropdownValues: updatedLevelsDropdownValues,
      autoSuggestionValues: updatedLevelsAutoSuggestionValues,
      stepLevelCriteria: stepTherapyLevelsCriteria,
      stepLevelCriteriaDropdownValues: updatedStepLevelCriteriaDropdownValues,
      drugList: stepTherapyLevelsDrugList,
      showCriteria: updatedShowCriteria,
      showAdditionalCriteria: updatedShowAdditionalCriteria,
      targetDrugsRowUpdated: 999,
    });
  }

  _updateTargetDrugsAutoSuggestions() {
    const { props, state } = this;
    const {
      stepTherapyTargetDrugsDrugNameData,
      stepTherapyTargetDrugsManufacturerData,
    } = props.state;
    const targetDrugsAutoSuggestionValues = [
      ...state.targetDrugsAutoSuggestionValues,
    ];
    if (state.targetDrugsColumnUpdated === "manufacturer") {
      targetDrugsAutoSuggestionValues[
        state.targetDrugsRowUpdated
      ].manufacturer.values = stepTherapyTargetDrugsManufacturerData.map(
        (val) => ({
          title: val,
        })
      );
      targetDrugsAutoSuggestionValues[
        state.targetDrugsRowUpdated
      ].manufacturer.loading = false;
    } else if (state.targetDrugsColumnUpdated === "drug_name") {
      targetDrugsAutoSuggestionValues[
        state.targetDrugsRowUpdated
      ].drug_name.values = stepTherapyTargetDrugsDrugNameData.map((val) => ({
        title: val,
      }));
      targetDrugsAutoSuggestionValues[
        state.targetDrugsRowUpdated
      ].drug_name.loading = false;
    }
    this.setState(targetDrugsAutoSuggestionValues);
  }

  _updateAutoSuggestions() {
    const { props, state } = this;
    const { stepTherapyLevelsDrugNameData, stepTherapyLevelsManufacturerData } =
      props.state;
    const { activeStepLevel, rowUpdated, columnUpdated } = state;
    const autoSuggestionValues = [...state.autoSuggestionValues];
    if (columnUpdated === "manufacturer") {
      autoSuggestionValues[activeStepLevel][rowUpdated].manufacturer.values =
        stepTherapyLevelsManufacturerData.map((val) => ({
          title: val,
        }));
      autoSuggestionValues[activeStepLevel][
        rowUpdated
      ].manufacturer.loading = false;
    }
    if (columnUpdated === "drug_name") {
      autoSuggestionValues[activeStepLevel][rowUpdated].drug_name.values =
        stepTherapyLevelsDrugNameData.map((val) => ({
          title: val,
        }));
      autoSuggestionValues[activeStepLevel][
        rowUpdated
      ].drug_name.loading = false;
    }
    this.setState(autoSuggestionValues);
  }

  approvalOptionsCallback = () => {
    const { state, props } = this;
    props.actions.getStepTherapyData({ doc_id: state.form.doc_id });
  };

  render() {
    const { props, state } = this;
    const {
      formStep,
      form,
      form: { hideHierarchy },
      targetDrugsConditions,
      targetDrugsAutoSuggestionValues,
      showAdditionalCriteria,
      drugList,
      stepTherapyLevels,
      stepTherapyQualifierLevels,
      activeStepLevel,
      stepLevelCriteria,
      autoSuggestionValues,
      autoSuggestionMinChars,
      showCriteria,
      cellRenderers,
      criteria_sublist,
    } = state;
    let editMode = true;
    if ("state" in props.history.location) {
      editMode =
        props.history.location.state &&
          "editMode" in props.history.location.state
          ? props.history.location.state.editMode
          : true;
    }
    if (hideHierarchy) editMode = false;
    const dropdownOptions = this._constructDropdownOptions();

    const targetDrugsDropdownOptions =
      this._constructTargetDrugsDropdownOptions();

    const levelCriteriaDropdownOptions =
      this._constructLevelCriteriaDropdownOptions();
    const qualifierDropdownOptions = this._constructQualifierDropdownOptions();
    const subListDropdownOptions = this._constructSubListDropdownOptions();

    let formStepToDisplay;

    switch (formStep) {
      case 1: {
        formStepToDisplay = (
          <StepTherapyTargetDrugs
            form={form}
            setForm={this.setForm}
            targetDrugsConditions={targetDrugsConditions}
            targetDrugsDropdownOptions={targetDrugsDropdownOptions}
            targetDrugsAutoSuggestionValues={targetDrugsAutoSuggestionValues}
            updateFields={this._updateFields}
            handleChange={this._handleChange}
            nextFormStep={this._nextFormStep}
            autoSuggestionResultSelect={
              this._onTargetDrugsAutoSuggestionResultSelect
            }
            autoSuggestionMinChars={autoSuggestionMinChars}
            addTargetDrugsCondition={this._addTargetDrugsCondition}
            delTargetDrugsCondition={this._delTargetDrugsCondition}
            saveStepTherapy={this._saveStepTherapy}
            addNewStepLevel={this._addNewStepLevel}
            editMode={editMode}
            cellRenderers={cellRenderers}
            onTargetCheckboxChange={this._onTargetCheckboxChange}
          />
        );
        break;
      }
      case 2:
        formStepToDisplay = (
          <StepTherapyLevels
            stepTherapyLevels={stepTherapyLevels}
            stepTherapyQualifierLevels={stepTherapyQualifierLevels}
            criteria_sublist={criteria_sublist}
            dropdownOptions={dropdownOptions}
            activeStepLevel={activeStepLevel}
            stepLevelCriteria={stepLevelCriteria}
            levelCriteriaDropdownOptions={levelCriteriaDropdownOptions}
            showCriteria={showCriteria}
            showAdditionalCriteria={showAdditionalCriteria}
            drugList={drugList}
            addQualifierRow={this._addQualifierRow}
            addSubListRow={this._addSubListRow}
            delQualifierRow={this._delQualifierRow}
            delSubListRow={this._delSubListRow}
            nextFormStep={this._nextFormStep}
            prevFormStep={this._prevFormStep}
            autoSuggestionValues={autoSuggestionValues}
            handleChange={this._handleChange}
            handleQualifierChange={this._handleQualifierChange}
            handleSubListChange={this._handleSubListChange}
            autoSuggestionResultSelect={this._autoSuggestionResultSelect}
            autoSuggestionMinChars={autoSuggestionMinChars}
            addStepCondition={this._addStepCondition}
            delStepCondition={this._delStepCondition}
            generateStepTherapyLevelDrugs={this._generateStepTherapy}
            saveStepTherapy={this._saveStepTherapy}
            addNewStepLevel={this._addNewStepLevel}
            deleteStepLevel={this._deleteStepLevel}
            handleStepLevelClick={this._handleStepLevelClick}
            editMode={editMode}
            cellRenderers={cellRenderers}
            criteriaIds={this.props.state.criteriaIds}
            qualifierDropdownOptions={qualifierDropdownOptions}
            subListDropdownOptions={subListDropdownOptions}
            onStepCheckboxChange={this._onStepCheckboxChange}
          />
        );
        break;
      default:
        break;
    }
    return (
      <div id="createStepTherapy">
        <div className="breadCrumbContainer">
          <BreadCrumb {...this.props} />{" "}
        </div>
        <div className="header">Create Step Therapy</div>
        {
          <ApprovalOptions
            docData={this.state.form}
            actionCallback={this.approvalOptionsCallback}
          />
        }
        <div className="content">{formStepToDisplay}</div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  state: {
    stepTherapyTargetDrugsDrugNameData:
      state.clinicalCreation.stepTherapyTargetDrugsDrugNameData,
    stepTherapyTargetDrugsManufacturerData:
      state.clinicalCreation.stepTherapyTargetDrugsManufacturerData,
    stepTherapyLevelsDrugNameData:
      state.clinicalCreation.stepTherapyLevelsDrugNameData,
    stepTherapyLevelsManufacturerData:
      state.clinicalCreation.stepTherapyLevelsManufacturerData,
    stepTherapyData: state.clinicalCreation.stepTherapyData,
    stepTherapyTargetDrugCondData:
      state.clinicalCreation.stepTherapyTargetDrugCondData,
    stepTherapyLevelsData: state.clinicalCreation.stepTherapyLevelsData,
    stepTherapyLevelsCriteria: state.clinicalCreation.stepTherapyLevelsCriteria,
    stepTherapyLevelsDrugList: state.clinicalCreation.stepTherapyLevelsDrugList,
    stepTherapyQualifierLevelsData:
      state.clinicalCreation.stepTherapyQualifierLevelsData,
    criteria_sublist: state.clinicalCreation.criteria_sublist,
    criteriaIds: state.clinicalCreation.stepTherapyCriteriaIdData,
    autoSuggestData: state.autoSuggest.autoSuggestData,
  },
});

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
    ...AutoSuggestActions,
    ...ClinicalCreationActions,
    ...QualifierActions,
    ...RPMActions,
  };

  return {
    actions: bindActionCreators(allActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateStepTherapy);
