import React from "react";
import { Link } from "react-router-dom";

export default (props) => {
  const { active } = props;
  const search = "";

  return (
    // <div>
    //   <Link
    //     to={{
    //       pathname: '/prior-authorization-creation-management',
    //       search,
    //     }}
    //     className={active === 'priorAuthorization' ? 'active' : ''}
    //   >
    //     Prior Authorization
    //   </Link>
    //   <Link
    //     to={{
    //       pathname: '/quantity-limit-creation-management',
    //       search,
    //     }}
    //     className={active === 'quantityLimit' ? 'active' : ''}
    //   >
    //     Quantity Limit
    //   </Link>
    //   <Link
    //     to={{
    //       pathname: '/step-therapy-creation-management',
    //       search,
    //     }}
    //     className={active === 'stepTherapy' ? 'active' : ''}
    //   >
    //     Step Therapy
    //   </Link>
    // </div>

    <div className="submenu">
      <div className={active === "priorAuthorization" ? "tab active" : "tab"}>
        <Link
          to={{
            pathname:'/prior-authorization-creation-management',
            search,
          }}
        >
          <div className="tab_inner_container">
            {/* <img src="/i/Drag.svg" /> */}
            <p> Prior Authorization</p>
          </div>
        </Link>
      </div>
      <div className={active === "quantityLimit" ? "tab active" : "tab"}>
        <Link
          to={{
            pathname:'/quantity-limit-creation-management',
            search,
          }}
          
        >
          <div className="tab_inner_container">
          
            {/* <img src="/i/Drag.svg" /> */}
            <p>Quantity Limit</p>
          </div>
        </Link>
      </div>
      <div className={active === "stepTherapy" ? "tab active" : "tab"}>
        <Link
          to={{
            pathname: '/step-therapy-creation-management',
            search,
          }}
        >
          <div className="tab_inner_container">
           
            {/* <img src="/i/Drag.svg" /> */}
            <p>Step Therapy</p>
          </div>
        </Link>
      </div>
    </div>
  );
};
