import {
    call,
    put,
    select,
    takeEvery,
    takeLatest,
} from 'redux-saga/effects'

import { Types, Creators as GlobalEditsActions } from '../../reducers/api/globalEdits'
import { Types as ClientConfigTypes, Creators as ClientConfigurationActions } from '../../reducers/api/clientConfiguration'
import { getAppState, getApiPath, getClientConfiguration, getGlobalEdits } from '../../reducers/selectors'
import { fetchGet, fetchPost, fetchPut } from '../../../utils/fetchApi'
import { getCagHierarchyConfig } from '../../../pages/private/clientConfiguration/utils'
import { Creators as EmergencyOverrideActions } from '../../reducers/api/emergencyOverride'

const TIERS = 'tiers';
const POS_REBATE = 'pos_rebate';
const PROGRAMS = 'programs';
const PRIOR_AUTHORIZATION = 'prior_authorization';
const QUANTITY_LIMIT = 'quantity_limit';
const STEP_THERAPY = 'step_therapy';

export const SECTIONS = {
    DEFAULT_COPAY: "default_copay",
    PRICING_MIXMASTER: "pricing_mixmaster",
}

export default [
    getGlobalEditWatcher,
    getGlobalEditsDashboardWatcher,
    getPublishedUmObjectsWatcher,
    getSectionUIWatcher,
    onClientSetAttributesWatcher,
    saveGlobalEditWatcher,
    validateSaveAndExitWatcher,
    getGlobalEditConfigurationWatcher,
]

/* WATCHERS */
function* getPublishedUmObjectsWatcher() {
    yield takeLatest(Types.GET_PUBLISHED_UM_OBJECTS, getPublishedUmObjectsHandler)
}

function* getGlobalEditConfigurationWatcher() {
    yield takeLatest(Types.GET_GLOBAL_EDIT_CONFIGURATION, getGlobalEditConfigurationHandler)
}

function* getGlobalEditWatcher() {
    yield takeLatest(Types.GET_GLOBAL_EDIT, getGlobalEditHandler)
}
function* getGlobalEditsDashboardWatcher() {
    yield takeLatest(Types.GET_GLOBAL_EDITS_DASHBOARD, getGlobalEditsDashboardHandler)
}

function* getSectionUIWatcher() {
    yield takeEvery(Types.GET_SECTION_UI, getSectionUIHandler)
}

function* onClientSetAttributesWatcher() {
    yield takeLatest(ClientConfigTypes.SET_SINGLE_LEVEL_ATTRIBUTE, getLatestGlobalEditHandler)
}

function* saveGlobalEditWatcher() {
    yield takeLatest(Types.SAVE_GLOBAL_EDIT, saveGlobalEditHandler)
}
function* validateSaveAndExitWatcher() {
    yield takeLatest(Types.VALIDATE_SAVE_AND_EXIT, validateSaveAndExitHandler)
}


/* HANDLERS */
function* getGlobalEditsDashboardHandler() {
    try {
        const url = yield call(getServiceUrl, 'emergency-override-configuration-dashboard');
        const urlWithParams = `${url}?configuration_type=benefit`
        const showLoader = true;
        const response = yield call(fetchGet, urlWithParams, showLoader)
        if (response.status !== "success") {
            throw new Error('Failed to get Benefit Override Dashboard')
        }
        yield put(GlobalEditsActions.setGlobalEditsDashboard(response.dashboard))
    } catch (err) {
        yield put(GlobalEditsActions.resetGlobalEdits())
    }
}

function* getGlobalEditConfigurationHandler({ payload }) {
    try {
        const { configuration_id } = payload
        const url = yield call(getServiceUrl, 'emergency-override-configuration');
        const urlWithParams = `${url}?configuration_id=${configuration_id}`
        const showLoader = true;
        const response = yield call(fetchGet, urlWithParams, showLoader)
        if (response.status !== "success") {
            throw new Error('Failed to get Global Edit Configuration')
        }
        yield put(GlobalEditsActions.setGlobalEdit(response.config))
        yield put(GlobalEditsActions.setPage('create-override'))
    } catch (err) {
        yield put(EmergencyOverrideActions.returnToDashboard())
    }
}

function* getPublishedUmObjectsHandler({ payload }) {
    const defaultModules = [
        TIERS,
        POS_REBATE,
        PROGRAMS,
        PRIOR_AUTHORIZATION,
        QUANTITY_LIMIT,
        STEP_THERAPY,
    ];
    let modulesToSearch = defaultModules;
    if (payload.modules) {
        modulesToSearch = defaultModules.filter(mod => payload.modules?.includes(mod));
    }
    const params = modulesToSearch.map((mod) => `module=${mod}`).join('&');
    const url = yield call(getServiceUrl, 'global-override-data');
    const urlWithParams = `${url}?dataType=modules&${params}`
    const showLoader = false;
    const response = yield call(fetchGet, urlWithParams, showLoader)
    if (response.status !== "success") {
        throw new Error(`Failed to get Published UmObjects: ${(response.message || '')}`)
    }
    try {
        const sortedData = response?.data.sort((a, b) => a.doc_name.localeCompare(b.doc_name));
        const umObjects = sortedData.reduce((acc, doc) => {
            const { module: moduleName } = doc;
            switch (moduleName) {
                case PROGRAMS:
                    acc.programEdits.push(doc)
                    break;
                case TIERS:
                    acc.tiers.push(doc)
                    break;
                case POS_REBATE:
                    acc.posRebates.push(doc)
                    break;
                case PRIOR_AUTHORIZATION:
                    acc.priorAuths.push(doc)
                    break;
                case QUANTITY_LIMIT:
                    acc.quantityLimits.push(doc)
                    break;
                case STEP_THERAPY:
                    acc.stepTherapies.push(doc)
                    break;
                default:
                    break;
            }
            return acc;
        }, { programEdits: [], tiers: [], posRebates: [], priorAuths: [], quantityLimits: [], stepTherapies: [] });
        yield put(GlobalEditsActions.setPublishedUmObjects(umObjects))

    } catch (err) {
        console.log('getPublishedUmObjectsHandler Error: ', err)
        const transitionalPortal = {
            header: 'Get Published UmObject Failed',
            copy: err.message,
        }
        yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    }
}

function* getSectionUIHandler({ payload }) {
    try {
        const { section } = payload;
        const url = yield call(getServiceUrl, 'global-override-data');
        const urlWithParams = `${url}?dataType=ui_fields&section=${section}`
        const showLoader = false;
        const response = yield call(fetchGet, urlWithParams, showLoader)
        if (response.status !== "success") {
            throw new Error(`Failed to get Section UI: ${(response.message || '')}`)
        }
        yield put(GlobalEditsActions.setSectionUI({ [section]: response.data[0] }))
    } catch (error) {
        console.log('getSectionUIHandler Error: ', error)
    }
}

function* getLatestGlobalEditHandler()  {
    try {
        const clientConfiguration = yield select(getClientConfiguration);
        const { singleLevelAttribute } = clientConfiguration;
        const globalEditsAttribute = singleLevelAttribute?.global_edits || {};
        const configuration_id = globalEditsAttribute.value?.value || globalEditsAttribute.value || null;
        yield put(GlobalEditsActions.resetGlobalEdits())
        if (!configuration_id) return;
        yield put(GlobalEditsActions.getGlobalEdit({ configuration_id: configuration_id }))
    } catch (err) {
        console.log('getLatestGlobalEditHandler Error: ', err)
    }
}

function* getGlobalEditHandler({ payload }) {
    try {
        const { configuration_id } = payload
        const url = yield call(getServiceUrl, 'global-override-configuration');
        const urlWithParams = `${url}?configuration_id=${configuration_id}`
        const showLoader = false;
        const response = yield call(fetchGet, urlWithParams, showLoader)
        if (response.status !== "success") {
            throw new Error('Failed to get Global Edits Configuration')
        }
        yield put(GlobalEditsActions.setGlobalEdit(response.config))
    } catch (err) {
        console.log('getGlobalEditHandler Error: ', err)
    }
}

function* validateSaveAndExitHandler() {
    try {
        const hasError = yield call(testGlobalValidationHandler, { payload: { goTo: null } })
        if (hasError) return;
        yield put(GlobalEditsActions.saveGlobalEdit())
    } catch (err) {
        console.log('validateAndExit', err)
    }
}

function* saveGlobalEditHandler() {
    try {
        const rawGlobalEdits = yield select(getGlobalEdits);
        const isolatedGlobalOverride = isolateConfigurationDetails(rawGlobalEdits)
        const url = yield call(getServiceUrl, 'global-override-configuration');
        const showLoader = true;
        const body = { overrideConfiguration: isolatedGlobalOverride };
        const response = yield call(fetchPost, url, body, showLoader)
        if (response.status !== "success") {
            throw new Error('Failed to save Global Edits Configuration')
        }
        const config = response.config;
        const configuration_id = config?.configuration_id;
        yield put(GlobalEditsActions.saveGlobalEditSuccess({ configuration_id: configuration_id }));
    } catch (error) {
        console.log('saveGlobalEditHandler', error);
        yield put(GlobalEditsActions.saveGlobalEditFailure(error));
    } finally {
          yield put(EmergencyOverrideActions.returnToDashboard())
    }
}

function* testGlobalValidationHandler({ payload }) {
    try {
        const globalEdits = yield select(getGlobalEdits);        
        const validations = globalEdits.validations || {};
        const fields = Object.keys(globalEdits);
        let hasErrors = false;
        const validationResults = fields.reduce((acc, field) => {
            const fieldValidation = validations[field];
            const fieldValue = globalEdits[field];
            acc[field] = true;
            if (!fieldValidation) return acc;
            const { type, validation, required } = fieldValidation;
            if (required || fieldValue) {
                acc[field] = validation(fieldValue, globalEdits)
            }
            if (!acc[field]) {
                hasErrors = true;
            }
            return acc;
        }, {})
        validationResults.hasErrors = hasErrors;
        yield put(GlobalEditsActions.updateValidationResult(validationResults))

        return hasErrors;
    } catch (err) {
        console.log(err)
    }
}




/* UTILITY */
function* getServiceUrl(servicePath) {
    const { serviceUrl } = yield select(getAppState)
    const { api_path } = yield select(getApiPath, servicePath)
    const url = `${serviceUrl}${api_path}`
    return url;
}

function isolateConfigurationDetails(emergencyOverride) {
    const fields = [
        'configuration_type',
        'configuration_id',
        'configuration_name',
        'global_edit_type',
        'configuration_notes',
        'effective_begin_date',
        'effective_term_date',
        'status',
        'impacts_all_hierarchies',
        'hierarchy_inclusion_rule',
        'hierarchy',
        'hierarchyIDs',
        'nationwide',
        'geographic_configuration',
        'state',
        'zip_code',
        'clinical_pa',
        'clinical_ql',
        'clinical_step_therapy',
        'programs',
        'formulary_name',
        'formulary_id',
        'tiers',
        'um_ranking',
        'custom_cost_share',
        'copays',
        'price_source',
        'created_by',
        'created_date',
        'updated_by',
        'updated_date',
    ]
    const isolatedEmergencyOverride = {}
    for (let i = 0; i < fields.length; i++) {
        const field = fields[i]
        if (field in emergencyOverride) {
            isolatedEmergencyOverride[field] = emergencyOverride[field]
        }
    }
    return isolatedEmergencyOverride;
}
