import React, { PureComponent } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Creators as IntegrationManagementActions } from '../../../../redux/reducers/api/integrationManagement'

import './styles.scss'
import { Creators as AppActions } from '../../../../redux/reducers/app'
import FliptDatePicker from '../../../../components/v2/fliptDatePicker'
import moment from 'moment'
import BreadCrumb from '../../../../components/breadCrumb'
import TabBar from '../../../../components/tabBar'
import IntegrationMgmtDashboardTab from '../IntegrationMgmtDashboardTab'
import FliptButton from '../../../../components/v2/fliptButton'

import { capitalize } from 'lodash'

class IntegrationManagementDashboard extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            form: {
                start_date: moment().subtract('days', 7).toDate(),
                end_date: moment().toDate()
            },
            IntegrationMgmtTabConfig: [],
            activeTabId: 0,
            queueRef: React.createRef(),
            groupedData: {}
        }
    }

    _updateForm = (el, dropdown) => {
        const { form } = this.state
        const { name, value } = dropdown || el.currentTarget
        form[name] = value
        this.setState({ form })
    }

    componentDidMount() {
        const { actions } = this.props
        actions.clearBatchDetails()
        actions.clearPageNumber()
        this._getRecords(true)
    }

    groupBy = (xs, key) => {
        return xs.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    };

    componentDidUpdate(prevProps) {
        const { state: { integrationMgmtLookupData } } = this.props
        if (prevProps.state.integrationMgmtLookupData !== integrationMgmtLookupData && integrationMgmtLookupData?.length) {
            let groupedData = this.groupBy(integrationMgmtLookupData, 'file_type')
            const IntegrationMgmtTabConfig = Object.keys(groupedData).map((data) => {
                return ({ tabName: capitalize(data), id: capitalize(data) })
            })
            this.setState({ IntegrationMgmtTabConfig, groupedData, activeTabId: IntegrationMgmtTabConfig[0].id })
        }

    }

    _getRecords = () => {
        const { actions } = this.props
        const { form } = this.state
        const params = {}
        if (!form.start_date || !form.end_date) {
            params.to = moment().format('YY-MM-DD')
            params.from = moment().subtract('days', 7).format('YY-MM-DD')
        } else {
            params.from = moment(form.start_date).format('YY-MM-DD')
            params.to = moment(form.end_date).format('YY-MM-DD')
        }
        actions.getIntegrationMgmtLookup(params)
    }

    _showTransitionalPortal = (header, message) => {
        const { props } = this;
        const transitionalPortal = {
            header: header,
            copy: message,
        }
        props.actions.displayTransitionalPortal(transitionalPortal)
    }

    _onComponentStateChanged = (params) => {
        const { batchDetailData } = this.props.state
        params.api.forEachNodeAfterFilter((node) => {
            if (node?.data?.batch_id === batchDetailData?.batch_id)
                node.setSelected(true)
        })
    }

    handleIdClicked = (batchData) => {
        const { data } = batchData
        this.props.history.push('/batch-details', { batch_id: data.batch_id, edit: true })
    }
    setActiveTab = (id, tabs) => {
        this.setState({ activeTabId: id })
    };

    renderIntegrationMgmtTab = () => {
        const { IntegrationMgmtTabConfig, activeTabId, queueRef, form } = this.state
        return IntegrationMgmtTabConfig.map((tabConfig, index) => {
            return (tabConfig.id === activeTabId &&
                <IntegrationMgmtDashboardTab
                    key={index}
                    form={form}
                    tabConfig={tabConfig}
                    queueRef={queueRef}
                    activeTabId={activeTabId}
                />
            )
        })
    }

    _updateForm = (el, dropdown) => {
        const { form } = this.state
        const { name, value } = dropdown || el.currentTarget
        const newForm = { ...form }
        newForm[name] = value
        this.setState({ form: newForm })
    }

    _clearRecords = () => {
        const form = {
            start_date: '',
            end_date: '',
        }
        this.setState({ form })
    }

    render() {
        const { IntegrationMgmtTabConfig, form } = this.state
        const INTEGRATION_MGMT_LOCAL_STORAGE_KEY = "OrganizationTabs";
        return (
            <div id="integration-management-dashboard">
                <div className='breadcrum-container'>
                    <BreadCrumb {...this.props} />
                </div>
                <div className='header'>
                    <span> Integration Job Dashboard</span>
                </div>
                {IntegrationMgmtTabConfig?.length ? <TabBar
                    tabs={IntegrationMgmtTabConfig}
                    setActiveTab={this.setActiveTab}
                    localStorageKey={INTEGRATION_MGMT_LOCAL_STORAGE_KEY}
                    draghandle
                /> : null}
                <div className='date-container'>
                    <FliptDatePicker onChange={this._updateForm} value={form.start_date} name="start_date"
                        label="Start Date" format='YYYY-MM-DD' />
                    <FliptDatePicker onChange={this._updateForm} format='YYYY-MM-DD' value={form.end_date} name="end_date" label="End Date" />
                    <FliptButton className='primary' name='Search' onClick={() => this._getRecords()} />
                    <FliptButton name='Clear' onClick={() => this._clearRecords()} />
                </div>
                {IntegrationMgmtTabConfig?.length ? this.renderIntegrationMgmtTab() : null}
            </div >
        )
    }
}

const mapStateToProps = (state) => ({
    state: {
        app: state.app,
        batchDetailData: state.bulkReprocessingRestacking.batchUpdateDetailData,
        integrationMgmtLookupData: state.integrationMgmt.integrationMgmtLookupData
    },
})

const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...AppActions,
        ...IntegrationManagementActions
    }

    return {
        actions: bindActionCreators(allActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationManagementDashboard)
