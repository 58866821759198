import React, { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Step, Icon } from 'semantic-ui-react'

import { Creators as AppActions } from '../../redux/reducers/app'
import { Creators as RPMActions } from '../../redux/reducers/rpm'
import { Creators as PlanManagementActions } from '../../redux/reducers/api/planManagement'

import ApprovalModal from './approvalModal';

import './styles.scss'

import FliptButton from '../v2/fliptButton'
import FliptDropdown from '../v2/fliptDropdown';

const eligibleRoles = ['approvalApprover', 'superUser']

const DRAFT_STATUS_STR = 'DRAFT'
const FOR_REVIEW_STATUS_STR = 'FOR_REVIEW'
const REJECTED_STATUS_STR = 'REJECTED'
const APPROVED_STATUS_STR = 'APPROVED'
const TEST_APPROVED_STATUS_STR = 'TEST_APPROVED'
const TEST_READY_STATUS_STR = 'TEST_READY'
const TESTING_COMPLETE_STATUS_STR = 'TESTING_COMPLETE'
const PUBLISH_READY_STATUS_STR = 'PUBLISH_READY'
const PUBLISH_FAILED_STR = 'PUBLISH_FAILED'
const PUBLISHED_STATUS_STR = 'PUBLISHED'

// const DRAFT_ACTION_OPTION = { key: '1', value: DRAFT_STATUS_STR, text: 'Draft' }
const SEND_FOR_REVIEW_ACTION_OPTION = { key: '2', value: FOR_REVIEW_STATUS_STR, text: 'Send for Review' }
const REJECT_ACTION_OPTION = { key: '3', value: REJECTED_STATUS_STR, text: 'Reject' }
const APPROVE_FOR_TEST_ACTION_OPTION = { key: '4', value: TEST_APPROVED_STATUS_STR, text: 'Approve for testing' }
const APPROVE_ACTION_OPTION = { key: '5', value: APPROVED_STATUS_STR, text: 'Approve to Publish' }
const PUBLISH_READY_ACTION_OPTION = { key: '6', value: PUBLISH_READY_STATUS_STR, text: 'Publish' }
const PUBLISH_FAILED_ACTION_OPTION = { key: '8', value: PUBLISH_FAILED_STR, text: 'Re-Publish' }
// const PUBLISHED_ACTION_OPTION = { key: '7', value: PUBLISHED_STATUS_STR, text: 'Publish 2' }

const statusToText = {
  [DRAFT_STATUS_STR]: 'Draft',
  [FOR_REVIEW_STATUS_STR]: 'Sent For Review',
  [REJECTED_STATUS_STR]: 'Rejected',
  [APPROVED_STATUS_STR]: 'Approved',
  [TEST_APPROVED_STATUS_STR]: 'Approved For Testing',
  [PUBLISH_READY_STATUS_STR]: 'Publish Ready',
  [PUBLISHED_STATUS_STR]: 'Published',
  [PUBLISH_FAILED_STR]: 'Failed To Publish'
}

const statusDisplayTexts = {
  [DRAFT_STATUS_STR]: {
    completedText: 'Draft',
    pendingText: 'Draft'
  },
  [FOR_REVIEW_STATUS_STR]: {
    completedText: 'Sent For Review',
    pendingText: 'Send For Review'
  },
  [TEST_APPROVED_STATUS_STR]: {
    completedText: 'Approved For Testing',
    pendingText: 'Approve For Testing/Reject'
  },
  [TEST_READY_STATUS_STR]: {
    completedText: 'Ready For Testing',
    pendingText: 'Ready For Testing'
  },
  [REJECTED_STATUS_STR]: {
    completedText: 'Rejected',
    pendingText: 'Approve/Reject'
  },
  [APPROVED_STATUS_STR]: {
    completedText: 'Approved',
    pendingText: 'Approve/Reject'
  },
  [PUBLISHED_STATUS_STR]: {
    completedText: 'Published',
    pendingText: 'Publish'
  },
  [PUBLISH_FAILED_STR]: {
    completedText: 'Failed To Publish',
    pendingText: 'Failed To Publish'
  }
}

const ApprovalOptions = (props) => {
  const { actions } = props

  const [docData, setDocData] = useState({})
  const [statusHistory, setStatusHistory] = useState([])

  // only users with approval access can see this component
  const userRoles = props.state.user.role_key
  const isUserEligible = _.intersection(userRoles, eligibleRoles)

  const [approvalActionOptions, setApprovalActionOptions] = useState([])
  const [approvalAction, setApprovalAction] = useState('')

  const getApprovalActionOptions = (statusStr) => {
    switch (statusStr) {
      case DRAFT_STATUS_STR:
        // should not allow any actions for draft status
        // as we are not certain that document is saved or not
        return []
      case FOR_REVIEW_STATUS_STR:
        return [
          APPROVE_FOR_TEST_ACTION_OPTION,
          APPROVE_ACTION_OPTION,
          REJECT_ACTION_OPTION,
        ]
      // case REJECTED_STATUS_STR:
      //   return [
      //     SEND_FOR_REVIEW_ACTION_OPTION
      //   ]
      case TEST_APPROVED_STATUS_STR:
      case TEST_READY_STATUS_STR:
        return [
          APPROVE_ACTION_OPTION,
          REJECT_ACTION_OPTION,
        ]
      case APPROVED_STATUS_STR:
        return [
          PUBLISH_READY_ACTION_OPTION
        ]
        case PUBLISH_FAILED_STR:
          return [
            PUBLISH_FAILED_ACTION_OPTION
          ]
      default:
        return []
    }
  }

  useEffect(() => {
    const docData = {
      doc_id: props.docData.doc_id,
      module_name: props.docData.module_name || props.docData.doc_name,
      module: props.docData.module,
      version: props.docData?.version || props.docData?.doc_version,
      status: props.docData.status,
    }
    if (docData.doc_id) {
      actions.rpmGetApprovalsStatusHistory(docData)
    }
    const newApprovalActionOptions = getApprovalActionOptions(docData.status)
    setApprovalActionOptions(newApprovalActionOptions)
    setDocData(docData)
  }, [props.docData.doc_id, props.docData.status])

  useEffect(() => {
    return (() => {
      props.actions.clearApprovalsStatusHistory()
    })
  }, [])

  const setStatusHistoryFromProps = () => {
    const statusHistory = [...props.state.statusHistory]
    const status_history = statusHistory.sort((a, b) => a.ts - b.ts)

    let lastDraftIndex = -1
    for (let i = status_history.length - 1; i >= 0; i--) {
      const { status, version } = status_history[i]
      if (status === DRAFT_STATUS_STR && version === docData.version) {
        lastDraftIndex = i
        break
      }
    }
    const history = [...status_history.slice(lastDraftIndex)]

    // if user is creating the document, history will be missing,
    // we want to show that document is being created in DRAFT status.
    // for aesthetics, i was told :)
    if (!history.length && !docData?.doc_id) {
      history.push({
        status: DRAFT_STATUS_STR
      })
    }

    const historyToDisplay = []

    const isDraftComplete = history.some(statusObj => statusObj.status === DRAFT_STATUS_STR)
    const draftStatusTexts = statusDisplayTexts[DRAFT_STATUS_STR]
    historyToDisplay.push({
      status: DRAFT_STATUS_STR,
      text: isDraftComplete ? draftStatusTexts.completedText : draftStatusTexts.pendingText,
      isComplete: isDraftComplete,
      icon: isDraftComplete ? 'check circle' : 'circle outline',
      iconColor: isDraftComplete ? 'green' : 'grey'
    })

    const isReviewComplete = history.some(statusObj => statusObj.status === FOR_REVIEW_STATUS_STR)
    const reviewStatusTexts = statusDisplayTexts[FOR_REVIEW_STATUS_STR]
    historyToDisplay.push({
      status: FOR_REVIEW_STATUS_STR,
      text: isReviewComplete ? reviewStatusTexts.completedText : reviewStatusTexts.pendingText,
      isComplete: isReviewComplete,
      icon: isReviewComplete ? 'check circle' : 'circle outline',
      iconColor: isReviewComplete ? 'green' : 'grey'
    })

    const isTestApprovalComplete = history.some(statusObj => statusObj.status === TEST_APPROVED_STATUS_STR)
    const testApprovalStatusTexts = statusDisplayTexts[TEST_APPROVED_STATUS_STR]
    historyToDisplay.push({
      status: TEST_APPROVED_STATUS_STR,
      text: isTestApprovalComplete ? testApprovalStatusTexts.completedText : testApprovalStatusTexts.pendingText,
      isComplete: isTestApprovalComplete,
      icon: isTestApprovalComplete ? 'check circle' : 'circle outline',
      iconColor: isTestApprovalComplete ? 'green' : 'grey'
    })

    const isReadyForTesting = history.some(statusObj => statusObj.status === TEST_READY_STATUS_STR)
    const testingReadyStatusTexts = statusDisplayTexts[TEST_READY_STATUS_STR]
    historyToDisplay.push({
      status: TEST_READY_STATUS_STR,
      text: isReadyForTesting ? testingReadyStatusTexts.completedText : testingReadyStatusTexts.pendingText,
      isComplete: isReadyForTesting,
      icon: isReadyForTesting ? 'check circle' : 'circle outline',
      iconColor: isReadyForTesting ? 'green' : 'grey'
    })

    const isApproved = history.some(statusObj => statusObj.status === APPROVED_STATUS_STR)
    const isRejected = history.some(statusObj => statusObj.status === REJECTED_STATUS_STR)
    if (isApproved) {
      historyToDisplay.push({
        status: APPROVED_STATUS_STR,
        text: statusDisplayTexts[APPROVED_STATUS_STR].completedText,
        isComplete: isApproved,
        icon: isApproved ? 'check circle' : 'circle outline',
        iconColor: isApproved ? 'green' : 'grey'
      })
    }
    if (isRejected) {
      historyToDisplay.push({
        status: REJECTED_STATUS_STR,
        text: statusDisplayTexts[REJECTED_STATUS_STR].completedText,
        isComplete: isRejected,
        icon: 'warning circle',
        iconColor: 'red'
      })
    }
    if (!isApproved && !isRejected) {
      historyToDisplay.push({
        status: APPROVED_STATUS_STR,
        text: statusDisplayTexts[APPROVED_STATUS_STR].pendingText,
        isComplete: isApproved,
        icon: isApproved ? 'check circle' : 'circle outline',
        iconColor: isApproved ? 'green' : 'grey'
      })
    }

    // testing was skipped
    if ((isApproved || isRejected) && !isTestApprovalComplete) {
      const testStatusIndex = historyToDisplay.findIndex((statusObj) => statusObj.status === TEST_APPROVED_STATUS_STR)
      historyToDisplay[testStatusIndex] = {
        status: TEST_APPROVED_STATUS_STR,
        text: 'Approve For Testing',
        isComplete: false,
        icon: 'circle',
        iconColor: 'grey'
      }
      const testReadyStatusIndex = historyToDisplay.findIndex((statusObj) => statusObj.status === TEST_READY_STATUS_STR)
      historyToDisplay[testReadyStatusIndex] = {
        status: TEST_READY_STATUS_STR,
        text: 'Ready For Testing',
        isComplete: false,
        icon: 'circle',
        iconColor: 'grey'
      }
    }

    if (!isRejected) {
      const isPublished = history.some(statusObj => statusObj.status === PUBLISHED_STATUS_STR)
      const publishedStatusTexts = statusDisplayTexts[PUBLISHED_STATUS_STR]
      historyToDisplay.push({
        status: PUBLISHED_STATUS_STR,
        text: isPublished ? publishedStatusTexts.completedText : publishedStatusTexts.pendingText,
        isComplete: isPublished,
        icon: isPublished ? 'check circle' : 'circle outline',
        iconColor: isPublished ? 'green' : 'grey'
      })
    }

    setStatusHistory(historyToDisplay)
  }

  useEffect(() => {
    setStatusHistoryFromProps(props.state.statusHistory)
  }, [props.state.statusHistory, props.docData.version, props.docData.status])


  const handleModalSubmit = (data) => {
    const { status } = data
    let requestData = { ...data }
    console.log("handleModalSubmit")
    console.log(data)
    if (status === 'PUBLISH_READY') {
      requestData["republish"] = "0"
      actions.rpmSendForPublish(requestData, props.actionCallback)
    }
    if (status === 'PUBLISH_FAILED') {
      requestData["status"] = "PUBLISH_READY"
      requestData["republish"] = "1"
      actions.rpmSendForPublish(requestData, props.actionCallback)
    }

    if ([APPROVED_STATUS_STR, TEST_APPROVED_STATUS_STR, REJECTED_STATUS_STR].includes(status)) {
      actions.rpmApproveRejectDocument(requestData, props.actionCallback)
    }
  }

  const openApprovalModal = () => {
    console.log("openApprovalModal")
    console.log(docData)
    actions.setModalComponent({
      modalProperties: {
        size: 'large',
      },
      contents: <ApprovalModal
        clickHandler={handleModalSubmit}
        data={docData}
        submitType={approvalAction}
        toggleModal={actions.toggleModal}
      />,
    })
    actions.toggleModal()
  }

  const handleActionSelect = (el, dropdown) => {
    const { name, value } = dropdown || el.currentTarget
    setApprovalAction(value)
  }

  return (
    <div id='approvals-approval-options'>
      {
        !!statusHistory.length &&
        <Step.Group>
          {statusHistory.map((statusObj, index) => (
            <Step key={index}>
              <Icon name={statusObj.icon} color={statusObj.iconColor} size='small' />
              <Step.Content>
                <Step.Title>{statusObj.text}</Step.Title>
              </Step.Content>
            </Step>
          ))}
        </Step.Group>
      }
      {!!isUserEligible.length && !!approvalActionOptions.length && <>
        <FliptDropdown
          disabled={!approvalActionOptions.length}
          placeholder='Approval Options'
          name="approvalAction"
          onChange={handleActionSelect}
          options={approvalActionOptions}
          className='approvals-action-dropdown'
          value={approvalAction}
        />
        <FliptButton
          className="primary"
          name="Take Action"
          disabled={!approvalAction}
          onClick={openApprovalModal}
        />
      </>}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    state: {
      user: state.user,
      statusHistory: state.rpm.statusHistory,
      state: state,
    },
  }
}

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
    ...RPMActions,
    ...PlanManagementActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ApprovalOptions)
