import React, { PureComponent, useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Creators as ClientConfigurationActions } from "../../../../redux/reducers/api/clientConfiguration";
import "./styles.scss";
import FliptDatePicker from "../../../../components/form/fliptDatePicker";
import "react-datepicker/dist/react-datepicker.css";
import Info from "../Info";
import { convertStrToDateObj } from "../../../../utils/utilities";
import FliptButton from "../../../../components/form/fliptButton";
import FliptDropdown from "../../../../components/v2/fliptDropdown";
import {
  BillingFieldAttribute,
  BillingAttribute,
  BusinessFieldAttribute,
  ClaimOptions,
} from "../Constant/billingFields";
import { renderDynamicInput, findSelectedFields } from "../utils";
import { COLUMN_DIVIDER } from '../Constant'

const BillingTab = (props) => {
  const { singleLevelAttribute, nodeType } = props.state;
  const { cycleTimeList } = props.state.billingTabOptions;

  const _updateFields = (el, dropdown) => {
    let { name, value } = dropdown || el.currentTarget;
    if (name === 'billingzip') {
      const sliceValue = 5
      if (value?.length > sliceValue && !value?.includes('-')) {
        value = value.slice(0, sliceValue) + '-' + value.slice(sliceValue);
      }
    }
    props.actions.updateAttributeFields({ name, value });
  };

  const renderAdditionalFields = (fieldList) => {
    if (fieldList?.length == 0) return null;
    if (Object.keys(singleLevelAttribute).length == 0) return null;
    const selectedFieldsArray = findSelectedFields(
      fieldList,
      singleLevelAttribute
    );
    const rowDivider = COLUMN_DIVIDER;
    if ((singleLevelAttribute?.pepm_pmpm?.value || singleLevelAttribute?.pepm_pmpm) == "PEPM") {
      const indexToRemove = selectedFieldsArray.findIndex(
        (item) => item.name === "pmpm_rate"
      );
      if (indexToRemove !== -1) {
        selectedFieldsArray.splice(indexToRemove, 1);
      }
    } else {
      const indexToRemove = selectedFieldsArray.findIndex(
        (item) => item.name === "pepm_rate"
      );
      if (indexToRemove !== -1) {
        selectedFieldsArray.splice(indexToRemove, 1);
      }
    }
    const selectedFieldsArrayGroupedBySections = selectedFieldsArray.reduce(function (m, it, i) {
      let subgroup = it["subgroup"] || "";
      m[subgroup] || (m[subgroup] = []);
      m[subgroup].push(it)
      return m
    }, {});
    //convert to a list of tuples sorted by the min order in each group
    const selectedTuples = Object.entries(selectedFieldsArrayGroupedBySections)
      .map(([subgroup, items]) => ({
        subgroup,
        items: items.sort((a, b) => a.order - b.order),
      }))
      .sort((a, b) => a.items[0].order - b.items[0].order)
    const fieldProps = {
      ...singleLevelAttribute,
      onChange: _updateFields,
      className: "fields-client-header",
      nodeType
    };
    return (
      <>
        {/* use array method to Calculate rows dynamically acc to ui layout */}
        {selectedTuples.map(function (tuple) {
          return (
            <fieldset>
              <legend>{tuple.subgroup}</legend>
              {Array.from(
                { length: Math.ceil(tuple.items.length / rowDivider) },
                (_, i) => (
                  <div className="fields-container" key={`row${i}`}>
                    {tuple.items
                      .slice(i * rowDivider, (i + 1) * rowDivider)
                      .map((item) => renderDynamicInput(item, fieldProps))}
                  </div>
                )
              )}
            </fieldset>
          );
        })}
      </>
    );
  };

  const [cliamCycle, setCliamCycle] = useState([
    {
      cycle_claims_type: "",
      cycle_claims_start: "",
      cycle_claims_start_date: "",
      cycle_claims_end_date: "",
    },
  ]);

  const [invoiceCycle, setInvoiceCycle] = useState([
    {
      cycle_invoicing_end_date: "",
      cycle_invoicing_start: "",
      cycle_invoicing_start_date: "",
      cycle_invoicing_type: "",
    },
  ]);
  const [remittanceCycle, setRemittanceCycle] = useState([
    {
      cycle_remittance_end_date: "",
      cycle_remittance_start: "",
      cycle_remittance_start_date: "",
      cycle_remittance_type: "",
    },
  ]);

  const handleAddClick = () => {
    const newCliamCycle = [
      ...cliamCycle,
      {
        cycle_claims_type: "",
        cycle_claims_start: "",
        cycle_claims_start_date: "",
        cycle_claims_end_date: "",
      },
    ];
    setCliamCycle(newCliamCycle);
  };

  const handleRemoveClick = (index) => {
    const newCliamCycle = [...cliamCycle];
    newCliamCycle.splice(index, 1);
    setCliamCycle(newCliamCycle);
    let name = "cycle_claims_type";
    let value = newCliamCycle;
    props.actions.updateAttributeFields({ name, value });
  };

  const handleNameChange = (index, field, val) => {
    const newCliamCycle = [...cliamCycle];
    newCliamCycle[index][field] = val;
    setCliamCycle(newCliamCycle);
    let name = "cycle_claims_type";
    let value = newCliamCycle;
    props.actions.updateAttributeFields({ name, value });
  };

  useEffect(() => {
    if (singleLevelAttribute?.cycle_claims_type) {
      setCliamCycle(singleLevelAttribute?.cycle_claims_type?.value || []);
    }
    if (singleLevelAttribute?.cycle_invoicing_type) {
      setInvoiceCycle(singleLevelAttribute?.cycle_invoicing_type?.value || []);
    }
    if (singleLevelAttribute?.cycle_remittance_type) {
      setRemittanceCycle(singleLevelAttribute?.cycle_remittance_type?.value || []);
    }
  }, [singleLevelAttribute]);

  /// invoice methods
  const handleInvoiceClick = () => {
    const newInvoiceCycle = [
      ...invoiceCycle,
      {
        cycle_invoicing_end_date: "",
        cycle_invoicing_start: "",
        cycle_invoicing_start_date: "",
        cycle_invoicing_type: "",
      },
    ];
    setInvoiceCycle(newInvoiceCycle);
  };

  const handleRemoveInvoice = (index) => {
    const newInvoiceCycle = [...invoiceCycle];
    newInvoiceCycle.splice(index, 1);
    setInvoiceCycle(newInvoiceCycle);
    let name = "cycle_invoicing_type";
    let value = newInvoiceCycle;
    props.actions.updateAttributeFields({ name, value });
  };

  const handleInvoiceName = (index, field, val) => {
    const newInvoiceCycle = [...invoiceCycle];
    newInvoiceCycle[index][field] = val;
    setInvoiceCycle(newInvoiceCycle);
    let name = "cycle_invoicing_type";
    let value = newInvoiceCycle;
    props.actions.updateAttributeFields({ name, value });
  };

  // remittanceCycle methods
  const handleRemittanceClick = () => {
    const newRemittanceCycle = [
      ...remittanceCycle,
      {
        cycle_remittance_end_date: "",
        cycle_remittance_start: "",
        cycle_remittance_start_date: "",
        cycle_remittance_type: "",
      },
    ];
    setRemittanceCycle(newRemittanceCycle);
  };

  const handleRemoveRemittance = (index) => {
    const newRemittanceCycle = [...remittanceCycle];
    newRemittanceCycle.splice(index, 1);
    setRemittanceCycle(newRemittanceCycle);
    let name = "cycle_remittance_type";
    let value = newRemittanceCycle;
    props.actions.updateAttributeFields({ name, value });
  };

  const handleRemittanceName = (index, field, val) => {
    const newRemittanceCycle = [...remittanceCycle];
    newRemittanceCycle[index][field] = val;
    setRemittanceCycle(newRemittanceCycle);
    let name = "cycle_remittance_type";
    let value = newRemittanceCycle;
    props.actions.updateAttributeFields({ name, value });
  };
  return (
    <>
      <div>
        {renderAdditionalFields(BillingAttribute)}
        {/* {renderAdditionalFields(BusinessFieldAttribute)} */}
        {singleLevelAttribute.hasOwnProperty("cycle_claims_type") && (
          <fieldset>
            <legend>{"Claim Cycle"}</legend>
            {cliamCycle.map(function (name, index) {
              return (
                <div className="fields-container" key={index}>
                  <div className="fields-client-header">
                    <div className="field-label">
                      <span>Cycle Type</span>
                      <Info content="Cycle Time" position="top right" />
                    </div>
                    <FliptDropdown
                      name="cycle_claims_type"
                      onChange={(e, dropdown) =>
                        handleNameChange(
                          index,
                          "cycle_claims_type",
                          dropdown.value
                        )
                      }
                      value={name.cycle_claims_type}
                      options={cycleTimeList}
                      className="picker"
                    />
                  </div>
                  <div className="cycle-inputs">
                    <div className="field-label">
                      <span>Effective Date</span>
                      <Info content="Effective Date" position="top right" />
                    </div>
                    <FliptDatePicker
                      className="cycle-start-date"
                      name="cycle_claims_start_date"
                      value={convertStrToDateObj(name.cycle_claims_start_date)}
                      onChange={(e, dropdown) =>
                        handleNameChange(
                          index,
                          "cycle_claims_start_date",
                          dropdown.value
                        )
                      }
                      placeholder="Effective Date"
                    />
                  </div>
                  <div className="cycle-inputs">
                    <div className="field-label">
                      <span>End Date</span>
                      <Info content="End Date" position="top right" />
                    </div>
                    <FliptDatePicker
                      className="cycle-start-date"
                      name="cycle_claims_end_date"
                      value={convertStrToDateObj(name.cycle_claims_end_date)}
                      onChange={(e, dropdown) =>
                        handleNameChange(
                          index,
                          "cycle_claims_end_date",
                          dropdown.value
                        )
                      }
                      placeholder="Effective start date"
                    />
                  </div>
                  <div className="fields-client-header">
                    <div className="field-label">
                      <span>Cycle Start </span>
                      <Info content="Cycle Start" position="top right" />
                    </div>
                    <FliptDropdown
                      name="cycle_claims_start"
                      onChange={(e, dropdown) =>
                        handleNameChange(
                          index,
                          "cycle_claims_start",
                          dropdown.value
                        )
                      }
                      value={name.cycle_claims_start}
                      options={ClaimOptions[name.cycle_claims_type]}
                      className="picker"
                      disabled={
                        name.cycle_claims_type == "Daily" ? true : false
                      }
                    />
                  </div>
                  {cliamCycle.length > 1 && (
                    <div
                      className="fields-client-header"
                      style={{ marginTop: "20px" }}
                    >
                      <FliptButton
                        className="primary"
                        name="Remove"
                        onClick={() => handleRemoveClick(index)}
                      />
                    </div>
                  )}
                </div>
              )
            })}
            <div className="fields-container">
              <div className="fields-client-header">
                <FliptButton
                  className="primary"
                  name="Add Cycle"
                  onClick={handleAddClick}
                />
              </div>
            </div>
          </fieldset>
        )}

        {singleLevelAttribute.hasOwnProperty("cycle_invoicing_type") && (
          <fieldset>
            <legend>{"Invoice Cycle"}</legend>
            {invoiceCycle.map((name, index) => (
              <div className="fields-container" key={index}>
                <div className="fields-client-header">
                  <div className="field-label">
                    <span>Cycle Type</span>
                    <Info content="Cycle Time" position="top right" />
                  </div>
                  <FliptDropdown
                    name="cycle_invoicing_type"
                    onChange={(e, dropdown) =>
                      handleInvoiceName(
                        index,
                        "cycle_invoicing_type",
                        dropdown.value
                      )
                    }
                    value={name.cycle_invoicing_type}
                    options={cycleTimeList}
                    className="picker"
                  />
                </div>
                <div className="cycle-inputs">
                  <div className="field-label">
                    <span>Effective Date</span>
                    <Info content="Effective Date" position="top right" />
                  </div>
                  <FliptDatePicker
                    className="cycle-start-date"
                    name="cycle_invoicing_start_date"
                    value={convertStrToDateObj(name.cycle_invoicing_start_date)}
                    onChange={(e, dropdown) =>
                      handleInvoiceName(
                        index,
                        "cycle_invoicing_start_date",
                        dropdown.value
                      )
                    }
                    placeholder="Effective Date"
                  />
                </div>
                <div className="cycle-inputs">
                  <div className="field-label">
                    <span>End Date</span>
                    <Info content="End Date" position="top right" />
                  </div>
                  <FliptDatePicker
                    className="cycle-start-date"
                    name="cycle_invoicing_end_date"
                    value={convertStrToDateObj(name.cycle_invoicing_end_date)}
                    onChange={(e, dropdown) =>
                      handleInvoiceName(
                        index,
                        "cycle_invoicing_end_date",
                        dropdown.value
                      )
                    }
                    placeholder="End Date"
                  />
                </div>
                <div className="fields-client-header">
                  <div className="field-label">
                    <span>Cycle Start </span>
                    <Info content="Cycle Start" position="top right" />
                  </div>
                  <FliptDropdown
                    name="cycle_invoicing_start"
                    onChange={(e, dropdown) =>
                      handleInvoiceName(
                        index,
                        "cycle_invoicing_start",
                        dropdown.value
                      )
                    }
                    value={name.cycle_invoicing_start}
                    options={ClaimOptions[name.cycle_invoicing_type]}
                    className="picker"
                    disabled={
                      name.cycle_invoicing_type == "Daily" ? true : false
                    }
                  />
                </div>
                {invoiceCycle.length > 1 && (
                  <div
                    className="fields-client-header"
                    style={{ marginTop: "20px" }}
                  >
                    <FliptButton
                      className="primary"
                      name="Remove"
                      onClick={() => handleRemoveInvoice(index)}
                    />
                  </div>
                )}
              </div>
            ))}
            <div className="fields-container">
              <div className="fields-client-header">
                <FliptButton
                  className="primary"
                  name="Add Cycle"
                  onClick={handleInvoiceClick}
                />
              </div>
            </div>
          </fieldset>
        )}

      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    state: {
      nodeType: state.clientConfiguration.nodeType,
      billingTabOptions: state.clientConfiguration.options.billingTab,
      singleLevelAttribute: state.clientConfiguration.singleLevelAttribute,
    },
  };
};

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...ClientConfigurationActions,
  };

  return {
    actions: bindActionCreators(allActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BillingTab);
