import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import './styles.scss'
import BreadCrumb from '../../../components/breadCrumb'
import FliptButton from '../../../components/form/fliptButton'
import FliptInput from '../../../components/form/fliptInput'
import FliptDropdown from '../../../components/form/fliptDropdown'
import FliptGrid from '../../../components/fliptGrid'
import statesJSON from '../../../config/constants/states'
import { Creators as AppActions } from '../../../redux/reducers/app'
import { Creators as PharmacyLookupActions } from '../../../redux/reducers/api/pharmacyLookup'
import { Creators as RxreportLookupActions } from '../../../redux/reducers/api/rxreportLookup'

import { Creators as PaoverridereportLookupAction } from '../../../redux/reducers/api/paoverrideLookup'
import { createQueryString, parseQueryString } from '../../../utils/utilities'
import FliptDatePicker from '../../../components/form/fliptDatePicker'
import { Creators as UserManagement }   from '../../../redux/reducers/userManagement'
import PaModal from './paModal'
import ApprovalModal from './paModal'

class Paoverridereportlookup extends Component {
  constructor(props) {
    super(props)

    this.state = {
      form: {
        flipt_person_id: '',
        drug_name: '',
        pharmacystate: '',
        pharmacyzip1: '',
        pharmacytype: 'RETAIL',
        rx_status: 'PA INITIATED',
        start_date:"",
        end_date:"",
        domain_name:"",
        offset:"0",
        use_date:"",
        note:"",
        pa_override_end_date:"",
        paOverride: false,
        start_date:"",
        end_date:"",
       
      },
      pharmacyTypeValues: ['Retail', 'All'],
      rxStatusValues: ['PA Initiated', 'PA Canceled'],
      agGridRef: React.createRef(),
    }
  }

  componentDidMount() {
    const { history } = this.props
    const { location } = history

    if (!location.search) return
    const { form, agGridRef } = this.state

    this._qsToFormData()
    history.location.search = createQueryString(form)

    agGridRef.current.api.refreshServerSideStore({ route: null, purge: true }) // to refresh the grid server side data
   
  }

  _pharmacySearch = () => {
    const { state, props } = this
    const { form, agGridRef } = state
    const { history } = props
    history.location.search = createQueryString(form)
    agGridRef.current.api.refreshServerSideStore({ route: null, purge: true }) // to refresh the grid server side data
  }

  _updateFields = (el, dropdown) => {
    const { form } = this.state
    const { name, value } = dropdown || el.currentTarget

    form[name] = value
   
    this.setState({ form })
  }

  _qsToFormData = () => {
    const { state, props } = this
    const qs = parseQueryString(props.history.location.search)
    const { form } = state

    Object.keys(qs).forEach((key) => {
      form[key] = qs[key].toUpperCase()
    })
    this.setState({ form })
  }

  _clickHandler=(data)=>{
    const { actions } = this.props
    
    actions.getPaoverridereportSuggestData(data)
    this._pharmacySearch()

  }


  _modelChangevale=(data)=>{
    
    this.setState({form:{
      ...this.state.form,
      ...data
    }})

  

  }

  _cancelOverride=(param)=>{
   
     const { actions } = this.props
     
     this.setState({
       ...this.state.form,
       
     })
     
     
     actions.setModalComponent({
       modalProperties: {
         size: 'large',
       },
       contents: this._generateModal(true,{...param,date:false}),
     })
     actions.toggleModal()
 

  }


  _changeEndDate=(param)=>{
     const { actions } = this.props
     this.setState({
       ...this.state.form,
       
     })
     
     
     actions.setModalComponent({
       modalProperties: {
         size: 'large',
       },
       contents: this._generateModal(true,{...param,date:true}),
     })
     actions.toggleModal()
  }
  
  _generateModal = (submitType, data) => {
    const { actions } = this.props

    return (
      <ApprovalModal
        clickHandler={this._clickHandler}
        data={data}
        action={this._modelChangevale}
        rejectDocument={actions.rejectDocument}
        submitType={submitType}
        toggleModal={actions.toggleModal}
      />
    )
  }

 

  render() {
    
    const { state, props } = this
    const { actions } = props
    const {
      form, pharmacyTypeValues, rxStatusValues, agGridRef,
    } = state
    const {
      flipt_person_id, drug_name, domain_name, pharmacyzip1,
      pharmacytype, rx_status,start_date,end_date
    } = form

   
    const {
      state: {
        paoverridereportPerPage,
      },
    } = props
    const headers = [
      'domain', 'ddid','drug_name', 'brand_generic', "form",'dosage', 'strengths',
      'gpi', 'package_qty', 'rx_flipt_person_id', 'admin_flipt_person_id',
      'create_date', 'pa_flag', 'override_id', 'pa_decision_date',
      'pa_override_start_date', 'pa_override_end_date','change_end_date','cancel_override', 'pa_override_status', 'pa_form', 
    ]
    const cellRendererParams = {
      domain: {
        overrideHeader: 'Domain',
      },
      ddid: {
        overrideHeader: 'DDID',
      },
     
      drug_name: {
        overrideHeader: 'Drug Name',
      },
      brand_generic: {
        overrideHeader: 'Brand/Generic',
      },
      form: {
        overrideHeader: 'Form',
      },
      dosage: {
        overrideHeader: 'Dosage',
      },
      strengths: {
        overrideHeader: 'Strength',
      },
      gpi: {
        overrideHeader: 'GPI',
      },
      package_qty: {
        overrideHeader: 'Package Quantity',
      },
      rx_flipt_person_id: {
        overrideHeader: 'Rx Flipt Person ID',
      },
      admin_flipt_person_id: {
        overrideHeader: 'Admin',
      },
      create_date: {
        overrideHeader: 'Created',
      },
      pa_flag: {
        overrideHeader: 'PA Flag',
      }
      ,
      override_id: {
        overrideHeader: 'Override Id',
      },
      pa_decision_date: {
        overrideHeader: 'PA Approval Date',
      },
      pa_override_start_date: {
        overrideHeader: 'PA Override Start Date',
      },
      pa_override_end_date: {
        overrideHeader: 'PA Override End Date',
      },
      change_end_date:{
        overrideHeader: 'Change end date',
        cellRenderer:(param)=>{
          return <span style={{color:"red",cursor:"pointer"}} onClick={(e)=>{this._changeEndDate(param.data)}}>Change end date</span>
        }
       
      },
      cancel_override:{
        overrideHeader: 'Cancel Override',
        cellRenderer:(param)=>{
          return <span style={{color:"red",cursor:"pointer"}} onClick={(e)=>{this._cancelOverride(param.data)}}>Cancel Override</span>
        }
      },


      pa_override_status: {
        overrideHeader: 'Pa Override Status',
      },
      pa_override_end_date: {
        overrideHeader: 'PA Approved Form',
      },

     
    }

    const statesData = Object.entries(statesJSON).map(([k, v]) => ({
      key: k,
      text: `${k} - ${v}`,
      value: k,
    }))
    const domain = props.state.domain.map((val, idx) => ({
      key: idx,
      text: val.id,
      value: val.id.toUpperCase(),
    }))
    const rxStatusOptions = rxStatusValues.map((val, idx) => ({
      key: idx,
      text: val,
      value: val.toUpperCase(),
    }))

    const serverSideGridConfig = {
      rowModel: 'serverSide',
      serverSideStoreType: 'partial',
      cacheBlockSize: paoverridereportPerPage,
      pagination: true,
      paginationPageSize: paoverridereportPerPage,
    }
    const serverSideGridParams = {
      form,
      apiId: 'paoverride-lookup',
      sagaToCall: actions.getPaoverridereportLookupData,
    
      headers,
    }
   
    return (
      <div id="rxreportlookup">-
        <BreadCrumb {...this.props} />
        <div className="header">Paoverridereport Lookup</div>
        <div className="subhead">
          <FliptDropdown placeholder="Domain Type" name="domain_name" value={domain_name} options={domain} simple item onChange={this._updateFields} />
          <FliptInput placeholder="Flipt Id " name="flipt_person_id" value={flipt_person_id} onChange={this._updateFields} />
          <FliptInput placeholder="Drug Name" name="drug_name" value={drug_name} onChange={this._updateFields} />
          <FliptDatePicker value={start_date} name="start_date" placeholder="Start Date" onChange={this._updateFields}  label="" description="Start Date" />
          <FliptDatePicker value={end_date} name="end_date" placeholder="End Date" onChange={this._updateFields}  label="" description="End date" />
          <FliptButton name="Search" className="primary searchButton" onClick={() => this._pharmacySearch(false)} />
        </div>

        <div className="content">
          <FliptGrid
            headers={headers}
            cellRendererParams={cellRendererParams}
            serverSideGridConfig={serverSideGridConfig}
            serverSideGridParams={serverSideGridParams}
            agGridRef={agGridRef}
            filterOptions={['contains', 'equals']}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
 
 return{ state: {
    app: state.app,
    loading: state.paoverridereportLookup.loading,
    paoverridereportLookupData: state.paoverridereportLookup.paoverridereportLookupData,
    paoverridereportPerPage: state.paoverridereportLookup.paoverridereportPerPage,
    paoverridereportSuggestData: state.paoverridereportLookup.paoverridereportSuggestData,
    totalRows: state.paoverridereportLookup.paoverridereportTotalRows,
    domain:state.user.accounts_access
  },}
}

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,

    ...PaoverridereportLookupAction,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Paoverridereportlookup)
