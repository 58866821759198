import { isEmpty } from "lodash";


export const validate = {
    group_termination_date: ({ group_termination_date }) => isEmpty(group_termination_date) ? "Group Termination Date should not be empty." : "",
    group_effective_date: ({ group_effective_date }) => isEmpty(group_effective_date) ? "Group Effective Date should not be empty." : "",
    client_name: ({ client_name }) => isEmpty(client_name) ? "Client should not be empty." : "",
    carrier_name: ({ carrier_name }) => isEmpty(carrier_name) ? "Carrier should not be empty." : "",
    account_name: ({ account_name }) => isEmpty(account_name) ? "Account should not be empty." : "",
    group_name: ({ group_name }) => isEmpty(group_name) ? "Group should not be empty." : "",
}