import React, { PureComponent, useMemo, useState, useEffect, useRef } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Creators as IntegrationManagementActions } from '../../../redux/reducers/api/integrationManagement'

import './styles.scss'
import { Creators as AppActions } from '../../../redux/reducers/app'
import FliptGrid from '../../../components/fliptGrid';
import FliptButton from '../../../components/v2/fliptButton'
import { LinkRenderer } from '../../../components/fliptGrid/cellRenderers'
import moment from 'moment'
import BreadCrumb from '../../../components/breadCrumb'
import DropdownButtonFloating from '../../../components/v2/dropdownButton'
import { INT_MGMT_TABS_LOCAL_STORAGE_KEY, buttonOptions, tabConfig } from './data/dropdownData'
import { Creators as ClaimsPRPActions } from '../../../redux/reducers/api/claimsProcessingAndReprocessing'
import FliptInput from '../../../components/v2/fliptInput'
import FliptDatePicker from '../../../components/v2/fliptDatePicker'
import TabBar from '../../../components/tabBar'
import { useHistory } from 'react-router-dom'

function ClaimProcessingReprocessingDashboard(props) {
    const { actions } = props
    const history = useHistory()
    const gridRef = useRef()
    const [form, setForm] = useState({
        start_date: moment().subtract(7, 'days').toDate(),
        end_date: moment().toDate(),
        status: '',
        name: ''
    })

    const defaultTabId = history?.location?.state?.tab_id || tabConfig[0].id
    const [activeTabId, setActiveTabId] = useState(defaultTabId)

    useEffect(() => {
        actions.clearTestBatchDetails()
        actions.clearIntegrationMgmtDashboard()
        actions.clearBatchDetails()
        actions.clearPageNumber()
    }, [])



    const _updateForm = (el, dropdown) => {
        const { name, value } = dropdown || el.currentTarget
        const newForm = { ...form }
        newForm[name] = value
        setForm(newForm)
    }

    const _clearRecords = () => {
        const tempForm = {
            start_date: '',
            end_date: '',
            status: '',
            name: ''
        }
        actions.clearIntegrationMgmtDashboard()
        setForm(tempForm)
    }

    const _fetchData = (isBatch, isMount) => {
        const { actions } = props;
        const params = { ...form };

        if (!isMount) {
            if (!params.start_date || !params.end_date) {
                const header = 'Fields Missing';
                const message = 'Please Add Date to Proceed';
                _showTransitionalPortal(header, message);
                return;
            }
            params.start_date = moment(params.start_date).format('YYYY-MM-DD');
            params.end_date = moment(params.end_date).format('YYYY-MM-DD');
        } else {
            params.start_date = moment().subtract('days', 7).format('YYYY-MM-DD');
            params.end_date = moment().format('YYYY-MM-DD');
        }
        if (isBatch) {
            actions.getTestBatches(params);
        } else {
            actions.getIntegrationMgmtDashboard(params);
        }
    }

    const _showTransitionalPortal = (header, message) => {
        const transitionalPortal = {
            header: header,
            copy: message,
        }
        actions.displayTransitionalPortal(transitionalPortal)
    }

    const handleIdClicked = (batchData) => {
        const { data } = batchData

        props.history.push('/batch-details', { batch_id: data.batch_id, edit: true })
    }

    const handleBatchNameClicked = (batchData) => {
        const { data } = batchData
        if(data.status == "DRAFT") {
            props.history.push('/claims-processing-and-reprocessing', { batch_id: data.batch_id })
        } else {
            props.history.push(`/get-test-batch?batch_id=${data.batch_id}`)

        }
    };


    const _navigateToBatchDetail = () => {
        props.history.push('/batch-details', { edit: false })
    }

    const _navigateToClaimProcessing = () => {
        props.history.push('/claims-processing-and-reprocessing', { edit: false })
    }

    const { state: { integrationMgmtDashboardData, testBatches } } = props

    let filteredData = integrationMgmtDashboardData?.queue?.length && integrationMgmtDashboardData?.queue.map((data) => ({
        ...data, create_date: moment(data?.create_date).format('YYYY-MM-DD'),
        update_date: moment(data?.update_date).format('YYYY-MM-DD'), source: data?.auto_queue?.toString().toLowerCase() === 'true' ?
            data?.adjustment_type === 'PDE' ? 'FAST PDE Schedule' : 'FAST Auto-Generated Batch' : 'FAST Manual Batch', name: 'Batch Reprocessing'
    }))
    filteredData = filteredData?.length ? filteredData.sort((a, b) => {
        return new Date(b.create_date) - new Date(a.create_date);
    }) : [];
    const setActiveTab = (tabName) => {
        if (tabName === tabConfig[1].tabName) _fetchData(true, true)
        else _fetchData(false, true)
        setActiveTabId(tabName)
    }

    const defaultColDef = useMemo(() => {
        return {
            resizable: true,
            floatingFilter: true,
            outerHeight: 120,
            menuTabs: ['filterMenuTab'],
        }
    }, [])
    const testBatchHeaders = ['batch_name', 'batch_type', 'create_date', 'created_by', 'organization', 'client', 'carrier', 'account', 'group', 'status']
    const testBatchRendererParams = {
        batch_name: {
            cellRenderer: LinkRenderer,
            onCellClicked: handleBatchNameClicked,
            searchArgs: {},
            width: '250px',
            header: 'Batch Name',
            filter: 'agMultiColumnFilter',
            width: '250px'
        },
        status: {
            header: 'Batch Status',
            filter: 'agMultiColumnFilter',
            overrideHeader: 'Status'
        },
    }
    const buildTestBatches = (testBatches) => {
        return testBatches.map((testBatch) => {
            return ({
                ...testBatch,
                batch_name: testBatch.batch_name || 'Test Batch',
                organization: testBatch?.hierarchyKeys?.organization?.join(', '),
                client: testBatch?.hierarchyKeys?.client?.join(', '),
                carrier: testBatch?.hierarchyKeys?.carrier?.join(', '),
                account: testBatch?.hierarchyKeys?.account?.join(', '),
                group: testBatch?.hierarchyKeys?.group?.join(', '),
                create_date: moment(testBatch?.create_date).format('YYYY-MM-DD'),
            })
        })
    }
    const headers = ['batch_name', 'source', 'reprocessing_type', 'status', 'create_date', 'update_date']
    const cellRendererParams = {
        batch_name: {
            cellRenderer: LinkRenderer,
            onCellClicked: handleIdClicked,
            filter: 'agMultiColumnFilter',
            searchArgs: {},
            width: '250px'
        },
        reprocessing_type: {
            overrideHeader: 'Type'
        },
        source: {
            filter: 'agMultiColumnFilter',
        },
        status: {
            filter: 'agMultiColumnFilter',
        },
        create_date: {
            overrideHeader: 'Receive Date'
        },
        update_date: {
            overrideHeader: 'Processed date'
        },

    }
    buttonOptions[0].onClick = _navigateToBatchDetail
    buttonOptions[1].onClick = _navigateToClaimProcessing
    const serverSideGridConfig = {
        rowModel: "serverSide",
        serverSideStoreType: "partial",
        pagination: true,
        paginationPageSize: 20
    };
    const serverSideGridParams = {
        form: form || {},
        apiId: "integration-management-dashboard",
        sagaToCall: actions.getIntegrationMgmtDashboard,
        headers: headers
    };
    return (
        <div id="claim-processing-reprocessing">
            <div className='breadcrum-container'>
                <BreadCrumb {...props} />
            </div>
            <div className='header'>
                Claim Processing & Reprocessing
                <DropdownButtonFloating buttonName='Create New Reprocessing Batch' options={buttonOptions} />
            </div>
            <div className='devider' />
            <div className='date-container'>
                <FliptInput disabled={activeTabId == tabConfig[0].id} onChange={_updateForm} label='Name' name='name' value={form.name} />
                <FliptInput onChange={_updateForm} label='Status' name='status' value={form.status} />
                <FliptDatePicker onChange={_updateForm} value={form.start_date} name="start_date"
                    label="Start Date" format='YYYY-MM-DD' />
                <FliptDatePicker onChange={_updateForm} format='YYYY-MM-DD' value={form.end_date} name="end_date"
                    label="End Date" />
            </div>
            <div className='button-container'>
                <FliptButton className='primary' name='Search' onClick={() => _fetchData(activeTabId === tabConfig[0].id ? false : true, false)} />
                <FliptButton name='Clear' onClick={() => _clearRecords()} />
            </div>
            <div className='tab-container'>
                <TabBar
                    tabs={tabConfig}
                    setActiveTab={setActiveTab}
                    localStorageKey={INT_MGMT_TABS_LOCAL_STORAGE_KEY}
                    draghandle
                />
            </div>
            <div className='grid-container'>
                 <div className={ activeTabId === tabConfig[0].id ? 'showBlock' : 'hideBlock' }>
                    <FliptGrid
                        agGridRef={gridRef}
                        defaultColDef={defaultColDef}
                        headers={headers}
                        cellRendererParams={cellRendererParams}
                        data={activeTabId === tabConfig[0].id ? filteredData || [] : []}
                        serverSideGridConfig={serverSideGridConfig}
                        serverSideGridParams={serverSideGridParams}
                    />
                </div>
                <div className={ activeTabId !== tabConfig[0].id ? 'showBlock' : 'hideBlock' }>
                   <FliptGrid
                        headers={testBatchHeaders}
                        defaultColDef={defaultColDef}
                        cellRendererParams={testBatchRendererParams}
                        data={buildTestBatches(testBatches) || []}
                    />
                </div>
            </div>
        </div >
    )
}

const mapStateToProps = (state) => ({
    state: {
        app: state.app,
        integrationMgmtDashboardData: state.integrationMgmt.integrationMgmtDashboardData,
        testBatches: state.claimsProcessingAndReprocessing?.testBatches || [],
    },
})

const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...AppActions,
        ...IntegrationManagementActions,
        ...ClaimsPRPActions
    }

    return {
        actions: bindActionCreators(allActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClaimProcessingReprocessingDashboard)
