import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import './styles.scss'
import BreadCrumb from '../../../../components/breadCrumb'
import FliptGrid from '../../../../components/fliptGrid'
import FliptButton from '../../../../components/form/fliptButton'
import FliptDropdown from '../../../../components/form/fliptDropdown'
import FliptInput from '../../../../components/form/fliptInput'
import { DeleteRowRenderer } from '../../../../components/fliptGrid/cellRenderers'
import { Creators as ApiActions } from '../../../../redux/reducers/api/bulkReprocessingRestacking'
import { Creators as FormularyActions } from '../../../../redux/reducers/api/formularyLookup'
import { Creators as IntegrationManagementActions } from '../../../../redux/reducers/api/integrationManagement'
import { Creators as AutoSuggestActions } from '../../../../redux/reducers/api/autoSuggest'
import { Creators as AppActions } from '../../../../redux/reducers/app'
import { getCAGOptions } from '../../../../utils/utilities'
import * as Constants from '.././constants'
import rowCellInfo from '.././rowCellInfo'
import { DatePickerEditor, DropdownEditor, InputTextEditor, SearchEditor } from '../../../../components/fliptGrid/cellEditors'
import FliptMultiMemberSearch from '../../../../components/fliptMultiMemberSearch'
import FliptMultiPrescriberSearch from '../../../../components/fliptMultiPrescriberSearch'
import FliptMultiPharmacySearch from '../../../../components/fliptMultiPharmacySearch'
import BatchClaims from '../batchClaims'
import AlertPopup from '../../../../components/alertPopup'
import FliptCheckbox from '../../../../components/form/fliptCheckbox'
import moment from 'moment'
import BatchInput from '../../bulkReprocessingRestacking/batchInput'
import FliptTextarea from '../../../../components/form/fliptTextarea'
import FliptRadio from '../../../../components/form/fliptRadio'
import { Icon } from 'semantic-ui-react'


class BatchDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            gridApi: null,
            rowIndex: null,
            name: null,
            memberData: [],
            prescriberData: [],
            pharmacyData: [],
            parsedClaimData: [],
            activeIndex: 0,
            selectedAction: '',
            searchData: [{
                carrier: '',
                account: '',
                group: '',
                auth_id: '',
                member_id: '',
                start_date: '',
                end_date: '',
                first_name: '',
                last_name: '',
                npi: '',
                include: true,
                drug_name: '',
                plan_name: '',
                gpi: '',
                ndc: ''
            }],
            batchConditionEditable: true,
            disableAllButtons: false,
            regenerateEnable: false,
            batchCompleted: false,
            reprocessing_type: '',
            batch_name: '',
            adjudication_services: '',
            reprocessing_notes: '',
            drug_cost: '',
            patient_pay: '',
            deductible_state: '',
            adjustment_reason_code: '',
            adjustment_reason_code_qualifier: '',
            reverse: 'N',
            reprocess: 'Y',
            restack: 'N',
            edits_bypassed: 'ALL',
            selectedRows: [],
            selectedClaim: {},
            claimOptions: [],
            memberOptions: [],
            providerOptions: [],
            umOptions: [],
            claimOptionsSelected: ['79', '81', '30'],
            memberOptionsSelected: [],
            providerOptionsSelected: [],
            umOptionsSelected: [],
            columnUpdated: null,
            rowUpdated: null,
            batchSummary: [],
            step: 0,
            isUploadFile: false,
            autoSuggestionValues: [{
                drug_name: { values: [], loading: false }
            }],
            editable: false,
            showDetailAcc: false,
            showCriteriaAcc: false,
            showExtraFields: false,
            adjustmentReasonCodeType: [{ key: 0, text: 'NULL', value: 'NULL' }]
        }
        this.agGridRef = React.createRef()
    }

    componentDidMount() {
        const { actions, location: { state }, state: { batchDetailsData } } = this.props
        actions.getEdits()
        this.setState({ editable: state?.edit })
        if ((state?.batch_id || batchDetailsData?.batch_id) && state.edit)
            actions.getBatchDetails({ batch_id: state?.batch_id || batchDetailsData?.batch_id })
        if (batchDetailsData?.batch_id)
            this.setbatchData()
        if (batchDetailsData?.batch_summary) this.setBatchSummaryData()
    }

    componentDidUpdate(prevProps) {
        const { state: { batchDetailsData, claimSummary, autoSuggestData,
            batchUpdateDetailData, editsData }, history } = this.props
        const { selectedClaim, batchCompleted } = this.state
        if (prevProps?.state?.batchDetailsData !== batchDetailsData && batchDetailsData) {
            this.setbatchData()
            if (batchDetailsData?.batch_summary) this.setBatchSummaryData()
        }
        if (prevProps.state.editsData !== editsData) {
            const claimOptions = []
            const memberOptions = []
            const umOptions = []
            const providerOptions = []
            editsData?.map((editData, index) => {
                if (editData?.default_bypass) {
                    const edit = { key: index, text: editData.description, value: editData.reject_code }
                    if (Constants.CLAIM_CODES.includes(editData.reject_code)) { claimOptions.push(edit) }
                    else if (Constants.MEMBER_CODES.includes(editData.reject_code)) { memberOptions.push(edit) }
                    else if (Constants.UM_CODES.includes(editData.reject_code)) { umOptions.push(edit) }
                    else if (Constants.PROVIDER_CODES.includes(editData.reject_code)) { providerOptions.push(edit) }
                }
            })
            this.setState({ claimOptions, memberOptions, umOptions, providerOptions })
        }
        if (prevProps.state.claimSummary !== claimSummary && claimSummary?.length) {
            history.push('/claim-summary', { auth_id: selectedClaim?.auth_id, batchCompleted: batchCompleted })
        }
        if (prevProps.state.autoSuggestData !== autoSuggestData) this._updateAutoSuggestions()
        // if (prevProps.state.batchUpdateDetailData !== batchUpdateDetailData) this.goToDashboard()
    }

    goToDashboard = () => {
        const pathname = '/integration-management-dashboard'
        this.props.history.push({ pathname })
    }

    _updateAutoSuggestions = () => {
        const { props, state: { columnUpdated, autoSuggestionValues, rowUpdated } } = this
        const { autoSuggestData } = props.state
        const newAutoSuggestionValues = [...autoSuggestionValues]
        newAutoSuggestionValues[rowUpdated][columnUpdated].values = autoSuggestData[columnUpdated].map((val) => ({
            title: val,
        }))
        newAutoSuggestionValues[rowUpdated].drug_name.loading = false
        if (this.setAutoSuggestionValues) this.setAutoSuggestionValues(newAutoSuggestionValues)

        this.setState({ autoSuggestionValues: newAutoSuggestionValues })
    }

    setSelectedClaim = (selectedClaim) => {
        this.setState({ selectedClaim })
    }

    setbatchData = () => {
        const { claimOptionsSelected, editable } = this.state
        const { state: { batchDetailsData: {
            reprocessing_type,
            reprocessing_notes,
            reverse,
            reprocess,
            restack,
            pricing_config,
            status,
            adjustment_reason_code,
            adjustment_reason_code_qualifier,
            rules,
            batch_name,
            edits_bypassed
        } } } = this.props
        const { drug_cost,
            patient_pay,
            deductible_state } = pricing_config
        const claimOptionsSelectedTemp = editable ? [] : [...claimOptionsSelected]
        const providerOptionsSelected = []
        const memberOptionsSelected = []
        const umOptionsSelected = []
        if (edits_bypassed?.length) {
            edits_bypassed.forEach((edit) => {
                if (Constants.CLAIM_CODES.includes(edit)) claimOptionsSelectedTemp.push(edit)
                if (Constants.MEMBER_CODES.includes(edit)) memberOptionsSelected.push(edit)
                if (Constants.PROVIDER_CODES.includes(edit)) providerOptionsSelected.push(edit)
                if (Constants.UM_CODES.includes(edit)) umOptionsSelected.push(edit)
            })
        }

        this.parseRulesData(rules)
        if (status === 'APPROVED' || status === 'REJECTED'
            || status === 'APPROVAL_IN_PROGRESS' || status === 'REPROCESS_COMPLETE'
            || status === 'REPROCESS_IN_PROGRESS' || status === 'CRITERIA_SUBMITTED') {
            this.setState({
                disableAllButtons: true, batchConditionEditable: false, reprocessing_type,
                reprocessing_notes,
                reverse,
                batch_name,
                reprocess,
                restack,
                drug_cost,
                adjustment_reason_code,
                adjustment_reason_code_qualifier,
                patient_pay,
                deductible_state,
                claimOptionsSelected: claimOptionsSelectedTemp,
                umOptionsSelected,
                memberOptionsSelected,
                providerOptionsSelected,
                showExtraFields: reprocessing_type === 'PDE',
                adjustmentReasonCodeType: this.getAdjustmentQualifierType(adjustment_reason_code_qualifier)
            })
        }
        if (status === 'APPROVED' || status === 'REJECTED'
            || status === 'AUTH_ID_GENERATED' || status === 'APPROVAL_IN_PROGRESS'
            || status === 'REPROCESS_IN_PROGRESS' || status === 'CRITERIA_SUBMITTED') {
            this.setState({
                batchCompleted: true, reprocessing_type,
                reprocessing_notes,
                reverse,
                batch_name,
                reprocess,
                restack,
                drug_cost,
                patient_pay,
                adjustment_reason_code,
                adjustment_reason_code_qualifier,
                deductible_state,
                claimOptionsSelected: claimOptionsSelectedTemp,
                umOptionsSelected,
                memberOptionsSelected,
                providerOptionsSelected,
                showExtraFields: reprocessing_type === 'PDE',
                adjustmentReasonCodeType: this.getAdjustmentQualifierType(adjustment_reason_code_qualifier)
            })
        }
        this.setStep(status)
    }

    setStep = (status) => {
        if (status === 'CRITERIA_SUBMITTED' || status === 'REPROCESS_IN_PROGRESS' || status === 'AUTH_ID_GENERATED') {
            this.setState({ step: 1 })
        } else if (status === 'REPROCESS_COMPLETE' | status === 'APPROVAL_IN_PROGRESS') {
            this.setState({ step: 2, showDetailAcc: true, showCriteriaAcc: true })
        } else if (status === 'APPROVED' || status === 'REJECTED') {
            this.setState({ step: 3, showDetailAcc: true, showCriteriaAcc: true })
        }

    }
    updateFrom = ({ form }) => {
        const { actions } = this.props
        this.setState({ form })
        this.agGridRef?.current?.api.refreshServerSideStore({ route: null, purge: true })
        actions.toggleModal()
    }

    handleInputChange = (el, dropdown) => {
        const { name, value } = dropdown || el.currentTarget
        if (name === 'reprocessing_type') {
            if (value === 'PDE') this.setState({ [name]: value, showExtraFields: true })
            else this.setState({
                [name]: value, showExtraFields: false, adjustment_reason_code_qualifier: '',
                adjustment_reason_code: ''
            })
            return
        }
        if (name === 'adjustment_reason_code_qualifier') {
            let adjustmentReasonCodeType = this.getAdjustmentQualifierType(value)
            this.setState({ [name]: value, adjustmentReasonCodeType })
            return
        }
        if (name === 'restack') {
            if (value === 'Y') this.setState({ [name]: value, patient_pay: true, deductible_state: true, drug_cost: true })
            else this.setState({ [name]: value, patient_pay: '', deductible_state: '', drug_cost: '' })
            return
        }
        this.setState({ [name]: value })
    }

    handleActionChange = (el, dropdown) => {
        const { value } = dropdown || el.currentTarget
        this.setState({ selectedAction: value })
    }

    getAdjustmentQualifierType = (value) => {
        let adjustmentReasonCodeType = [{ key: 0, text: 'NULL', value: 'NULL' }]
        if (value === '2 - CMS Audit') adjustmentReasonCodeType = Constants.ADJUSTMENT_REASON_CODE_CMS
        if (value === '3 - CMS Identified Overpayment (CIO)') adjustmentReasonCodeType = Constants.ADJUSTMENT_REASON_CODE_IDENTIFIED
        if (value === '4 - CGDP Dispute or Appeal') adjustmentReasonCodeType = Constants.ADJUSTMENT_REASON_CODE_DISPUTE
        return adjustmentReasonCodeType
    }

    clearScreen = () => {
        this.setState({
            searchData: [{
                carrier: '',
                account: '',
                group: '',
                auth_id: '',
                member_id: '',
                start_date: '',
                end_date: '',
                first_name: '',
                last_name: '',
                npi: '',
                include: true,
                drug_name: '',
                plan_name: '',
                gpi: '',
                ndc: ''
            }],
            reprocessing_type: '',
            batch_name: '',
            adjudication_services: '',
            reprocessing_notes: '',
            drug_cost: '',
            adjustment_reason_code: '',
            adjustment_reason_code_qualifier: '',
            patient_pay: '',
            deductible_state: '',
            reverse: 'N',
            reprocess: 'Y',
            restack: 'N',
            edits_bypassed: 'ALL'
        })
        // this.agGridRef?.current?.api.refreshServerSideStore({ route: null, purge: true })
    }


    handleQueueClaims = (queue_claims) => {
        const { searchData, editable } = this.state
        const { state: { user, batchDetailsData } } = this.props
        const {
            reprocessing_type,
            reprocessing_notes,
            reverse,
            reprocess,
            restack,
            drug_cost,
            patient_pay,
            deductible_state,
            batch_name,
            umOptionsSelected,
            memberOptionsSelected,
            providerOptionsSelected,
            claimOptionsSelected,
            adjustment_reason_code,
            adjustment_reason_code_qualifier
        } = this.state

        if (!batch_name) {
            const header = 'Field Error'
            const message = `Please Add Batch Name to Proceed.`
            this._showTransitionalPortal(header, message)
            return
        }


        let rulesList = []
        searchData.forEach((data) => {
            const innerArray = []
            const keys = Object.keys(data)
            keys.forEach((keyData) => {
                if (data[keyData] && keyData !== 'include') {
                    let condition_type = data.include ? 'IN' : 'NOT_IN'
                    let finalValue = data[keyData]
                    if (keyData === 'start_date' || keyData === 'end_date') {
                        finalValue = moment(finalValue).format('YYYY-MM-DD')
                        condition_type = keyData === 'start_date' ? 'GREATER_THAN_EQUAL_TO' : 'LESS_THAN_EQUAL_TO'
                    } else if (data[keyData]?.length && !Array.isArray(data[keyData])) {
                        finalValue = data[keyData].split(",")
                    }
                    innerArray.push({
                        field: this.getKeyName(keyData, true),
                        condition_type,
                        value: finalValue
                    })
                }
            })
            rulesList.push(innerArray)
        })

        let claimDetails = {
            type: "reprocessing_queue",
            reprocessing_type,
            adjustment_type: '',
            batch_name,
            reprocessing_notes,
            batch_id: batchDetailsData?.batch_id || '',
            reverse,
            reprocess,
            queue_claims: queue_claims,
            restack,
            rules: rulesList,
            edits_bypassed: [...umOptionsSelected,
            ...memberOptionsSelected,
            ...providerOptionsSelected,
            ...claimOptionsSelected],
            created_by: user.uuid,
            updated_by: user.uuid,
            adjustment_reason_code: adjustment_reason_code === 'NULL' ? '' : adjustment_reason_code,
            adjustment_reason_code_qualifier,
            pricing_config: {
                drug_cost,
                patient_pay,
                deductible_state,
            }
        }
        this.props.actions.queueClaims({
            claims: claimDetails, goBack: true,
            openAlert: this.openAlert, editable: editable
        })
    }

    delRow = (rowIndex) => {
        const { searchData, autoSuggestionValues } = this.state
        const newSearchData = [...searchData]
        newSearchData.splice(rowIndex, 1)
        this.setState({
            searchData: newSearchData,
            autoSuggestionValues: autoSuggestionValues.filter((value) => autoSuggestionValues.indexOf(value) !== rowIndex),
        })
    }

    addCondition = () => {
        const { searchData, autoSuggestionValues } = this.state
        const newSearchData = [...searchData]
        newSearchData.push({
            carrier: '',
            account: '',
            group: '',
            auth_id: '',
            member_id: '',
            start_date: '',
            end_date: '',
            first_name: '',
            last_name: '',
            npi: '',
            include: true,
            drug_name: '',
            plan_name: '',
            gpi: '',
            ndc: ''
        })
        this.setState({
            searchData: newSearchData, autoSuggestionValues: [...autoSuggestionValues, {
                drug_name: { values: [], loading: false }
            }]
        })
    }


    _clearExtraValue = (value, selectedData, key) => {
        let arrayData = value?.split(",")
        if (arrayData.length === 1 && arrayData[0].length === 1) {
            return '';
        } else {
            const idArray = selectedData.map((data) => { return data[key] })
            return idArray.join(', ');
        }
    }

    handleChange = (el, dropdown, rowIndex, gridApi, stepLevel, setAutoSuggestionValues) => {
        const { searchData, prescriberData, pharmacyData, memberData, autoSuggestionValues, rowUpdated } = this.state
        let newSearchData = searchData
        const { actions } = this.props
        const { name, value } = dropdown || el.currentTarget
        if (name === 'member_id') {
            newSearchData = _.cloneDeep(searchData)
            newSearchData[rowIndex][name] = this._clearExtraValue(value, memberData, 'member_id')
            this.setState({ rowIndex, name, searchData: newSearchData, regenerateEnable: true })
            this.searchMember();
            return;
        }
        if (name === 'prescriber_npi') {
            newSearchData = _.cloneDeep(searchData)
            newSearchData[rowIndex][name] = this._clearExtraValue(value, prescriberData, 'npi')
            this.setState({ rowIndex, name, searchData: newSearchData, regenerateEnable: true })
            this.searchPrescriber();
            return;
        }
        if (name === 'pharmacy_npi') {
            newSearchData = _.cloneDeep(searchData)
            newSearchData[rowIndex][name] = this._clearExtraValue(value, pharmacyData, 'pharmacynpi')
            this.setState({ rowIndex, name, searchData: newSearchData, regenerateEnable: true })
            this.searchPharmacy();
            return;
        }
        const newAutoSuggestionValues = [...autoSuggestionValues]
        if (name === 'drug_name' && value.length >= 3) {
            newAutoSuggestionValues[rowIndex].drug_name.loading = true
            actions.getAutoSuggestData({ search_string: value, search_in: 'fts_ndc_drugs', search_for: 'drug_name' })
            this.setAutoSuggestionValues = setAutoSuggestionValues
        }
        if (rowUpdated !== rowIndex) {
            actions.clearAutoSuggestSearchTerm()
        }

        newSearchData[rowIndex][name] = value
        this.setState({ searchData: newSearchData, regenerateEnable: true, rowUpdated: rowIndex, columnUpdated: name })
        gridApi.refreshCells(newSearchData)
    }


    updateMemberData = (data) => {
        const { searchData, rowIndex, name } = this.state
        const newSearchData = _.cloneDeep(searchData)
        if (!data.length) {
            newSearchData[rowIndex][name] = ''
            this.setState({ memberData: [], searchData: newSearchData })
            return
        }
        const idArray = data.map((member) => { return member.member_id })
        newSearchData[rowIndex][name] = idArray.join(', ')
        this.setState({ searchData: newSearchData, memberData: data })
    }
    autoSuggestionResultSelect = (data, rowIndex) => {
        const { state } = this
        const searchData = [...state.searchData]
        const rowToUpdate = searchData[rowIndex]
        const { name, result } = data
        const value = result.title

        rowToUpdate[name] = value
        searchData[rowIndex] = rowToUpdate

        this.setState({
            searchData,
        })
    }
    updatePharmacyData = (data) => {
        const { searchData, rowIndex, name } = this.state
        const newSearchData = _.cloneDeep(searchData)
        if (!data.length) {
            newSearchData[rowIndex][name] = ''
            this.setState({ pharmacyData: [], searchData: newSearchData })
            return
        }
        const idArray = data.map((pharmacy) => { return pharmacy.pharmacynpi })
        newSearchData[rowIndex][name] = idArray.join(', ')
        this.setState({ searchData: newSearchData, pharmacyData: data })
    }

    updatePrescriberData = (data) => {
        const { searchData, rowIndex, name } = this.state
        const newSearchData = _.cloneDeep(searchData)
        if (!data.length) {
            newSearchData[rowIndex][name] = ''
            this.setState({ prescriberData: [], searchData: newSearchData })
            return
        }
        const idArray = data.map((prescriber) => { return prescriber.npi })
        newSearchData[rowIndex][name] = idArray.join(', ')
        this.setState({ searchData: newSearchData, prescriberData: data })
    }


    searchMember = () => {
        const { actions } = this.props
        const { memberData } = this.state
        actions.setModalComponent({
            modalProperties: { size: 'small', className: 'bulk-reprocessing-restacking-modal', scrolling: true },
            contents: <FliptMultiMemberSearch updateMemberData={this.updateMemberData} memberData={memberData} />,
        })
        actions.toggleModal()
    }

    searchPrescriber = () => {
        const { actions } = this.props
        const { prescriberData } = this.state
        actions.setModalComponent({
            modalProperties: { size: 'small', className: 'bulk-reprocessing-restacking-modal', scrolling: true },
            contents: <FliptMultiPrescriberSearch updatePrescriberData={this.updatePrescriberData} prescriberData={prescriberData} />,
        })
        actions.toggleModal()
    }

    searchPharmacy = () => {
        const { actions } = this.props
        const { pharmacyData } = this.state
        actions.setModalComponent({
            modalProperties: { size: 'small', className: 'bulk-reprocessing-restacking-modal', scrolling: true },
            contents: <FliptMultiPharmacySearch updatePharmacyData={this.updatePharmacyData} pharmacyData={pharmacyData} />,
        })
        actions.toggleModal()
    }

    parseRulesData = (rulesData) => {
        const parsedSearchData = []
        rulesData && rulesData.forEach((rules) => {
            const obj = {
                carrier: '',
                account: '',
                group: '',
                auth_id: '',
                member_id: '',
                start_date: '',
                end_date: '',
                first_name: '',
                last_name: '',
                npi: '',
                include: '',
                drug_name: '',
                plan_name: '',
                gpi: '',
                ndc: ''
            }

            rules.forEach((rule) => {
                obj.include = rule.condition_type === "IN"
                if (rule.condition_type === 'GREATER_THAN_EQUAL_TO' || rule.condition_type === 'LESS_THAN_EQUAL_TO') obj.include = true
                const keyData = this.getKeyName(rule.field)
                let finalValue = rule.value
                if (rule?.value?.length && Array.isArray(rule.value)) {
                    if (rule?.value?.length > 1) finalValue = rule?.value?.join(',');
                    else finalValue = rule.value[0];
                }
                obj[keyData] = finalValue
            })
            parsedSearchData.push(obj)
        })
        this.setState({ searchData: parsedSearchData })
    }

    getKeyName = (key, isReverse) => {
        const keyObj = {
            'claim_request.cardholder_id': 'member_id',
            'claim_request.prescriber_id': 'prescriber_npi',
            'claim_request.service_provider_id': 'pharmacy_npi',
            'domain': 'account',
            'startDate': 'start_date',
            'endDate': 'end_date',
            'member_group': 'group',
            'carrier': 'carrier',
            'auth_id': 'auth_id',
            'drug_name': 'drug_name',
            'benefit_plan_name': 'plan_name',
            'claim_request.gpi': 'gpi',
            'claim_request.product_id': 'ndc'
        }
        const keyObjReverse = {
            'member_id': 'claim_request.cardholder_id',
            'prescriber_npi': 'claim_request.prescriber_id',
            'pharmacy_npi': 'claim_request.service_provider_id',
            'account': 'domain',
            'start_date': 'startDate',
            'end_date': 'endDate',
            'group': 'member_group',
            'carrier': 'carrier',
            'auth_id': 'auth_id',
            'drug_name': 'drug_name',
            'plan_name': 'benefit_plan_name',
            'gpi': 'claim_request.gpi',
            'ndc': 'claim_request.product_id'
        }
        return isReverse ? keyObjReverse[key] : keyObj[key]
    }

    getApprovedRejectedClaims = () => {
        const { batchClaims } = this.props.state
        const { selectedRows } = this.state
        const selectedAuthIds = selectedRows.map((claimRow) => claimRow.auth_id)
        const APPROVED = []
        const REJECTED = []
        batchClaims.map((batchClaim) => {
            if (selectedAuthIds.includes(batchClaim.auth_id)) {
                REJECTED.push(batchClaim.auth_id)
            } else {
                APPROVED.push(batchClaim.auth_id)
            }
        })
        return ({ APPROVED, REJECTED })
    }

    changeBatchStatus = (status) => {
        const { state: { batchDetailsData: { batch_id } }, actions } = this.props
        actions.toggleModal()
        const params = {
            batch_id,
            reviewed_claims: {},
            approval_status: status
        }
        this.props.actions.queueClaims({ claims: params })
    }

    saveProgress = (status) => {
        const { state: { batchDetailsData: { batch_id } }, actions } = this.props
        actions.toggleModal()

        const { APPROVED, REJECTED } = this.getApprovedRejectedClaims()
        const params = {
            batch_id,
            reviewed_claims: {
                APPROVED: status === 'REJECTED' ? APPROVED : REJECTED,
                REJECTED: status === 'REJECTED' ? REJECTED : APPROVED,
            },
            approval_status: "SAVE_REVIEW"
        }
        this.props.actions.queueClaims({ claims: params })
    }

    setSelectedRows = (selectedRows) => {
        this.setState({ selectedRows })
    }

    onRowSelected = (e) => {
        if (e?.node?.data) e.node.data['include'] = e?.node?.selected
    }

    _onComponentStateChanged = (params) => {
        params.api.forEachNodeAfterFilter(node => node.setSelected(node?.data?.include))
    }

    openAlert = (alertType) => {
        const { actions, state: { batchUpdateDetailData } } = this.props
        const { selectedRows } = this.state
        if (!selectedRows?.length && (alertType === 'CLAIM_APPROVE' || alertType === 'CLAIM_REJECT')) {
            const header = 'Field Error'
            const message = `Please select claims to be Approved/Rejected.`
            this._showTransitionalPortal(header, message)
            return;
        }
        const { APPROVED, REJECTED } = this.getApprovedRejectedClaims()
        actions.setModalComponent({
            modalProperties: { size: 'mini' },
            contents: <AlertPopup headerText={this.getHeaderText()} saveProgress={this.saveProgress}
                changeBatchStatus={this.changeBatchStatus} batch_id={batchUpdateDetailData?.batch_id}
                closeModal={() => actions.toggleModal()} alertType={alertType} approvedClaims={APPROVED?.length} rejectedClaims={REJECTED?.length} />,
        })
        actions.toggleModal()
    }

    getHeaderText = () => {
        const { editable } = this.state
        if (editable) return ('Batch Submitted')
        else return ('')
    }

    batchInput = () => {
        const { actions, history } = this.props
        const { isUploadFile } = this.state
        if (!isUploadFile) return
        actions.setModalComponent({
            modalProperties: { size: 'small', className: 'bulk-reprocessing-restacking-modal' },
            contents: <BatchInput history={history} updateForm={this.updateFrom} form={this.state.searchData} />,
        })
        actions.toggleModal()
    }
    _showTransitionalPortal = (header, message) => {
        const { props } = this;
        const transitionalPortal = {
            header: header,
            copy: message,
        }
        props.actions.displayTransitionalPortal(transitionalPortal)
    }

    _handleAccordianClick = (index) => {
        const { activeIndex } = this.state
        this.setState({ activeIndex: index === activeIndex ? -1 : index })
    }

    setBatchSummaryData = () => {
        const { batchDetailsData: { batch_summary } } = this.props.state
        const finalBatchSummary = [
            {
                description: 'Old', member_cost_share: batch_summary?.total_member_cost_share_before,
                claim_cost: batch_summary?.total_cost_before, paid_claim: batch_summary?.prior_claim_count,
                reject_claim: 0
            },
            {
                description: 'New', member_cost_share: batch_summary?.total_member_cost_share_after,
                claim_cost: batch_summary?.total_cost_after, paid_claim: batch_summary?.paid_claim_count,
                reject_claim: batch_summary?.rejected_claim_count
            },
            {
                description: 'Difference', member_cost_share: batch_summary?.total_member_cost_share_after - batch_summary?.total_member_cost_share_before,
                claim_cost: batch_summary?.total_cost_after - batch_summary?.total_cost_before, paid_claim: batch_summary?.paid_claim_count - batch_summary?.prior_claim_count,
                reject_claim: batch_summary?.rejected_claim_count - batch_summary?.rejected_claim_count
            }
        ]
        this.setState({ batchSummary: finalBatchSummary })
    }

    onRadioChange = () => {
        const { isUploadFile } = this.state
        this.setState({ isUploadFile: !isUploadFile })
    }

    render() {
        const { searchData, batchConditionEditable, regenerateEnable, autoSuggestionValues,
            disableAllButtons, batchCompleted, reprocessing_type, showExtraFields, adjustment_reason_code,
            patient_pay, deductible_state, drug_cost, reprocessing_notes, batchSummary, editable, step, umOptions, providerOptions, batch_name,
            memberOptions, claimOptions, isUploadFile, reverse, reprocess, restack, umOptionsSelected, adjustment_reason_code_qualifier,
            providerOptionsSelected, claimOptionsSelected, adjustmentReasonCodeType, memberOptionsSelected, showCriteriaAcc, showDetailAcc } = this.state
        const { state: { batchDetailsData, carriers }, actions } = this.props
        const batchSummaryHeader = ['description', 'paid_claim', 'reject_claim', 'member_cost_share', 'claim_cost']
        const searchHeaders = ['action', 'include', 'carrier', 'account', 'group', 'plan_name', 'member_id', 'pharmacy_npi', 'prescriber_npi',
            'auth_id', 'start_date', 'end_date', 'drug_name', 'gpi', 'ndc']
        const searchCellRendererParams = {
            action: {
                cellRenderer: DeleteRowRenderer,
                state: {
                    onClick: this.delRow,
                },
                width: 95,
            },
            include: {
                overrideHeader: 'Include',
                cellRenderer: () => {
                    return <p />
                },
                headerCheckboxSelection: true,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true,
            },
            pharmacy_npi: {
                overrideHeader: 'Pharmacy NPI'
            },
            member_id: {
                overrideHeader: 'Member ID'
            },
            prescriber_npi: {
                overrideHeader: 'Prescriber NPI'
            },
            auth_id: {
                overrideHeader: 'Auth ID'
            },
        }
        const searchCellEditorParams = {
            carrier: {
                editable: true,
                cellEditor: DropdownEditor,
                onChange: this.handleChange,
                readOnly: !batchConditionEditable,
            },
            account: {
                editable: true,
                cellEditor: DropdownEditor,
                onChange: this.handleChange,
                readOnly: !batchConditionEditable,
            },
            group: {
                editable: true,
                cellEditor: DropdownEditor,
                onChange: this.handleChange,
                readOnly: !batchConditionEditable,
            },
            member_id: {
                editable: true,
                cellEditor: InputTextEditor,
                disabled: !batchConditionEditable,
                onChange: this.handleChange,
            },
            pharmacy_npi: {
                editable: true,
                cellEditor: InputTextEditor,
                disabled: !batchConditionEditable,
                onChange: this.handleChange,
            },
            prescriber_npi: {
                editable: true,
                cellEditor: InputTextEditor,
                disabled: !batchConditionEditable,
                onChange: this.handleChange,
            },
            auth_id: {
                editable: true,
                cellEditor: InputTextEditor,
                disabled: !batchConditionEditable,
                onChange: this.handleChange,
            },
            start_date: {
                editable: true,
                cellEditor: DatePickerEditor,
                disabled: !batchConditionEditable,
                onChange: this.handleChange,
            },
            drug_name: {
                cellEditor: SearchEditor,
                onChange: this.handleChange,
                disabled: !batchConditionEditable,
                autoSuggestionResultSelect: this.autoSuggestionResultSelect,
            },
            plan_name: {
                editable: true,
                cellEditor: InputTextEditor,
                disabled: !batchConditionEditable,
                onChange: this.handleChange,
            },
            gpi: {
                editable: true,
                cellEditor: InputTextEditor,
                disabled: !batchConditionEditable,
                onChange: this.handleChange,
            },
            ndc: {
                editable: true,
                cellEditor: InputTextEditor,
                disabled: !batchConditionEditable,
                onChange: this.handleChange,
            },
            end_date: {
                editable: true,
                cellEditor: DatePickerEditor,
                onChange: this.handleChange,
            },
        }
        const filterOptions = getCAGOptions(carriers, searchData?.length && searchData[0].carrier, searchData?.length && searchData[0].account, true)
        rowCellInfo.carrier.options = filterOptions.carrierOptions
        rowCellInfo.account.options = filterOptions.accountOptions
        rowCellInfo.group.options = filterOptions.groupOptions
        const onlyReversal = restack === 'N' && reprocess === 'N' && reverse === 'Y'
        return (
            <div id='batch-details'>
                <BreadCrumb {...this.props} />
                <div className='progressbar-container'>
                    <ul className="progressbar">
                        <li className={step >= 0 ? `active ${(step > 0) ? ' completed' : ''}` : ""}>Define Batch</li>
                        <li className={step >= 1 ? `active ${(step > 1) ? ' completed' : ''}` : ""}>Fetch Claim</li>
                        <li className={step >= 2 ? `active ${(step > 2) ? ' completed' : ''}` : ""}>Validate Claims</li>
                        <li className={step >= 3 ? "active" : ""}>Review</li>
                    </ul>
                </div>
                <div className='batch-container'>
                    {showDetailAcc ?
                        batchDetailsData?.batch_id ?
                            <div className='accordian-container'>
                                <div>
                                    <span className="acc-header">Batch Details</span>
                                    <div className='border-right' />
                                    <div className='acc-inner-container'>
                                        <span>Batch ID</span>
                                        <p>{batchDetailsData?.batch_id}</p>
                                    </div>
                                </div>
                                <img src='/i/expand.png' height={20} width={20} onClick={() => { this.setState({ showDetailAcc: !showDetailAcc }) }}></img>
                            </div> : null
                        : <div className='transition-div'>
                            {step >= 2 ?
                                <div className='text-button-container '>
                                    <span className="checkbox-label">Batch Details</span>
                                    <Icon name='angle down' onClick={() => { this.setState({ showDetailAcc: !showDetailAcc }) }} />
                                </div> :
                                <span className="checkbox-label">Batch Details</span>}
                            <section>
                                <FliptInput
                                    stylized
                                    label='Define Batch Name'
                                    name='batch_name'
                                    value={batch_name}
                                    onChange={this.handleInputChange} />
                            </section>
                            {editable ? <section className='reprocessing-details-section'>
                                <FliptInput
                                    stylized
                                    label='Batch ID'
                                    readOnly
                                    value={batchDetailsData?.batch_id}
                                    onChange={this.handleInputChange} />
                                <FliptInput
                                    stylized
                                    label='Status'
                                    readOnly
                                    value={batchDetailsData?.status}
                                    onChange={this.handleInputChange} />
                            </section> : null}
                            <section className='reprocessing-details-section'>
                                <FliptDropdown
                                    stylized
                                    placeholder='Select'
                                    label='Reprocessing Type'
                                    name='reprocessing_type'
                                    readOnly={disableAllButtons}
                                    value={reprocessing_type}
                                    options={Constants.REPROCESSING_TYPES}
                                    onChange={this.handleInputChange} />
                                <FliptDropdown
                                    stylized
                                    placeholder='Select'
                                    label='Historical Pricing'
                                    readOnly={disableAllButtons || restack === 'Y' || onlyReversal}
                                    name='drug_cost'
                                    value={drug_cost}
                                    options={Constants.TRUE_FALSE_TYPES}
                                    onChange={this.handleInputChange} />
                                <FliptDropdown
                                    stylized
                                    placeholder='Select'
                                    label='Historical Patient Pay'
                                    readOnly={disableAllButtons || restack === 'Y' || onlyReversal}
                                    name='patient_pay'
                                    value={patient_pay}
                                    options={Constants.TRUE_FALSE_TYPES}
                                    onChange={this.handleInputChange} />
                                <FliptDropdown
                                    stylized
                                    placeholder='Select'
                                    label='Historical Deductible State'
                                    readOnly={disableAllButtons || restack === 'Y' || onlyReversal}
                                    name='deductible_state'
                                    value={deductible_state}
                                    options={Constants.TRUE_FALSE_TYPES}
                                    onChange={this.handleInputChange} />
                                {showExtraFields ?
                                    <>
                                        <FliptDropdown
                                            stylized
                                            placeholder='Select'
                                            label='Adjustment Reason Code Qualifier'
                                            readOnly={disableAllButtons}
                                            name='adjustment_reason_code_qualifier'
                                            value={adjustment_reason_code_qualifier}
                                            options={Constants.ADJUSTMENT_REASON_CODE_QUALIFIER}
                                            onChange={this.handleInputChange} />
                                        <FliptDropdown
                                            stylized
                                            placeholder='Select'
                                            label='Adjustment Reason Code'
                                            readOnly={disableAllButtons}
                                            name='adjustment_reason_code'
                                            value={adjustment_reason_code}
                                            options={adjustmentReasonCodeType}
                                            onChange={this.handleInputChange} />
                                    </>
                                    : null}
                            </section>
                            <section className='inner-batch-container'>
                                <span className="checkbox-label">Action</span>
                                <div className='radio-container'>
                                    <div className='row-container'>
                                        <FliptCheckbox
                                            className="classname"
                                            disabled={disableAllButtons}
                                            name='reverse'
                                            value={reverse === 'N' ? 'Y' : 'N'}
                                            checked={reverse === 'Y'}
                                            onChange={this.handleInputChange} />
                                        <span className="checkbox-text">Reverse</span>
                                        <div className='restack-icon'>
                                            <Icon color='grey' name='info circle' />
                                            <div className='restack-label'>Claims defined in the batch will be reversed only.</div>
                                        </div>
                                    </div>

                                    <div className='row-container'>
                                        <FliptCheckbox
                                            className="classname"
                                            disabled={disableAllButtons}
                                            name='reprocess'
                                            value={reprocess === 'N' ? 'Y' : 'N'}
                                            checked={reprocess === 'Y'}
                                            onChange={this.handleInputChange} />
                                        <span className="checkbox-text">Reprocess</span>
                                        <div className='restack-icon'>
                                            <Icon color='grey' name='info circle' />
                                            <div className='restack-label'>Claims defined in the batch will be reversed and reprocessed.</div>
                                        </div>
                                    </div>
                                    <div className='row-container'>
                                        <FliptCheckbox
                                            className="classname"
                                            disabled={disableAllButtons}
                                            name='restack'
                                            value={restack === 'N' ? 'Y' : 'N'}
                                            checked={restack === 'Y'}
                                            onChange={this.handleInputChange} />
                                        <span className="checkbox-text">Restack</span>
                                        <div className='restack-icon'>
                                            <Icon color='grey' name='info circle' />
                                            <div className='restack-label'>Claims defined in the batch will be restacked only.</div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className='reprocessing-details-section'>
                                <FliptTextarea
                                    stylized
                                    class='reprocessing_notes'
                                    value={reprocessing_notes}
                                    name='reprocessing_notes'
                                    label='Reprocessing Notes'
                                    disabled={disableAllButtons}
                                    placeholder='Enter'
                                    onChange={this.handleInputChange} />
                            </section>
                            <section>
                                <span className="checkbox-label">Adjudication Services</span>
                                <div className='service-container'>
                                    <FliptDropdown
                                        stylized
                                        multiple
                                        placeholder='Select'
                                        label='Claim'
                                        name='claimOptionsSelected'
                                        value={claimOptionsSelected}
                                        options={claimOptions}
                                        readOnly={disableAllButtons || onlyReversal}
                                        onChange={this.handleInputChange} />
                                    <FliptDropdown
                                        stylized
                                        multiple
                                        placeholder='Select'
                                        label='Utilization Management'
                                        readOnly={disableAllButtons || onlyReversal}
                                        name='umOptionsSelected'
                                        value={umOptionsSelected}
                                        options={umOptions}
                                        onChange={this.handleInputChange} />
                                </div>
                                <div className='service-container'>
                                    <FliptDropdown
                                        stylized
                                        multiple
                                        placeholder='Select'
                                        label='Member'
                                        name='memberOptionsSelected'
                                        value={memberOptionsSelected}
                                        readOnly={disableAllButtons || onlyReversal}
                                        options={memberOptions}
                                        onChange={this.handleInputChange} />
                                    <FliptDropdown
                                        stylized
                                        multiple
                                        placeholder='Select'
                                        label='Provider'
                                        name='providerOptionsSelected'
                                        readOnly={disableAllButtons || onlyReversal}
                                        value={providerOptionsSelected}
                                        options={providerOptions}
                                        onChange={this.handleInputChange} />
                                </div>
                            </section>
                        </div>}
                    {showCriteriaAcc ? batchDetailsData?.batch_id ?
                        <div className='accordian-container'>
                            <div>
                                <span className="acc-header">Build Criteria</span>
                            </div>
                            <img src='/i/expand.png' height={20} width={20} onClick={() => { this.setState({ showCriteriaAcc: !showCriteriaAcc }) }}></img>
                        </div> : null :
                        <div className='transition-div'>
                            <section className='inner-batch-container'>
                                {step >= 2 ?
                                    <div className='text-button-container '>
                                        <span className="checkbox-label">Build Criteria</span>
                                        <Icon name='angle down' onClick={() => { this.setState({ showCriteriaAcc: !showCriteriaAcc }) }} />
                                    </div> :
                                    <span className="checkbox-label">Build Criteria</span>}
                                <div className='radio-text'>Do you want to build criteria via file upload?</div>
                                <div className='radio-container'>
                                    <FliptRadio
                                        radioLabel='Yes'
                                        checked={isUploadFile}
                                        readOnly={disableAllButtons}
                                        value='Yes'
                                        name='is_global'
                                        onChange={this.onRadioChange}
                                    />
                                    <FliptRadio
                                        radioLabel='No'
                                        checked={!isUploadFile}
                                        readOnly={disableAllButtons}
                                        value='No'
                                        name='is_global'
                                        onChange={this.onRadioChange}
                                    />
                                </div>
                                <div className={!isUploadFile ? 'overlay' : 'file-upload-container'} onClick={this.batchInput}>
                                    <img src='/i/upload-icon.png'></img>
                                    <div>
                                        <div>Click to upload</div>
                                        <span>.excel</span>
                                    </div>
                                </div>
                            </section>
                            <section className='grid-section claim-grid-height'>
                                <FliptGrid
                                    headers={searchHeaders}
                                    cellEditorParams={searchCellEditorParams}
                                    rowCellInfo={rowCellInfo}
                                    cellRendererParams={searchCellRendererParams}
                                    data={searchData}
                                    rowSelection='multiple'
                                    onRowSelected={this.onRowSelected}
                                    onComponentStateChanged={this._onComponentStateChanged}
                                    autoSuggestionValues={autoSuggestionValues}
                                    suppressRowClickSelection={true}
                                />
                            </section>
                            {!disableAllButtons &&
                                <div className='button-container'>
                                    <FliptButton
                                        name='Add Condition'
                                        className='primary'
                                        disabled={disableAllButtons}
                                        onClick={() => this.addCondition()} /></div>}
                        </div>}

                    {batchDetailsData?.batch_summary && step >= 2 ? <section className='button-header-section'>
                        <span className="checkbox-label">Batch Summary</span>
                        {batchCompleted ? null : <div>
                            <FliptButton
                                name='Approve'
                                className='green-button'
                                onClick={() => this.openAlert('BATCH_APPROVE')} />
                            <FliptButton
                                name='Reject'
                                className='red-button'
                                onClick={() => this.openAlert('BATCH_REJECT')} /></div>}

                    </section> : null}

                    {batchDetailsData?.batch_summary && step >= 2 ? <section className='grid-section summary-grid-height'>
                        <FliptGrid headers={batchSummaryHeader} data={batchSummary} />
                    </section> : null}
                    {batchDetailsData?.batch_id ? <section className='button-header-section'>
                        <span className="checkbox-label">Batch Claims</span>
                        {batchCompleted ? null : <div>
                            <FliptButton
                                name='Approve'
                                className='green-button'
                                onClick={() => this.openAlert('CLAIM_APPROVE')} />
                            <FliptButton
                                name='Reject'
                                className='red-button'
                                onClick={() => this.openAlert('CLAIM_REJECT')} />
                        </div>}
                    </section> : null}

                    {batchDetailsData?.batch_id ? <section className='grid-section claim-grid-height'>
                        <BatchClaims batch_id={batchDetailsData?.batch_id} batchCompleted={batchCompleted} setSelectedClaim={this.setSelectedClaim} step={step}
                            setSelectedRows={this.setSelectedRows} actions={actions} batchDetailsData={batchDetailsData} />
                    </section> : null}
                </div>
                {!disableAllButtons && <div className='queue-claims-container'>
                    {regenerateEnable || !editable ?
                        <FliptButton
                            name={editable ? 'Re-pull Claims' : 'Pull Claims'}
                            disabled={disableAllButtons}
                            className='primary queue-claim-button'
                            onClick={() => this.handleQueueClaims(false)} /> :
                        batchDetailsData?.status === 'AUTH_ID_GENERATED' ? <FliptButton
                            name='Queue Claims'
                            className='primary'
                            onClick={() => this.handleQueueClaims(true)} /> : <FliptButton
                            name='Finalize Batch'
                            disabled={disableAllButtons}
                            className='primary'
                            onClick={() => this.openAlert('FINALIZE')} />
                    }
                </div>}
            </div>)
    }
}
const mapStateToProps = (state) => ({
    state: {
        user: state.user,
        carriers: state?.user?.carriers && state?.user?.carriers?.length > 0 ? state.user.carriers : [],
        batchDetailsData: state.integrationMgmt.batchDetailsData,
        batchClaims: state.integrationMgmt.batchClaims,
        claimSummary: state.integrationMgmt.claimSummary,
        autoSuggestData: state.autoSuggest.autoSuggestData,
        batchUpdateDetailData: state.bulkReprocessingRestacking.batchUpdateDetailData,
        editsData: state.integrationMgmt.editsData,
    },
})
const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...AppActions,
        ...ApiActions,
        ...IntegrationManagementActions,
        ...AutoSuggestActions,
        ...FormularyActions,
    }

    return {
        actions: bindActionCreators(allActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BatchDetails)