import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import NotificationBar from 'react-notification-bar'

import './styles.scss'
import BreadCrumb from '../../../components/breadCrumb'
import Config from '../../../config'
import DeductibleDetails from './component/deductibleDetails'
import FamilyDetails from './component/familyDetails'
import MemberDetails from './component/memberDetails'
import MemberLookupButtonBar from './component/memberDetails/defaultButtons'
import MultiMemberLookupModal from './component/multiMemberLookupModal'
import PlanDetails from './component/planDetails'
import ProactiveOverride from './component/proactiveOverride'
import ClaimOverride from './component/claimOverride'
import Intent from './component/intentDetails'
import UpdateLookupButtonBar from './component/memberDetails/memberServiceButtons'
import UpdateMemberModal from './component/updateMemberModal'
import UpdatePasswordModal from './component/updatePasswordModal'
import { Creators as AppActions } from '../../../redux/reducers/app'
import { Creators as MemberLookupActions } from '../../../redux/reducers/api/memberLookup'
import { Creators as NavigationActions } from '../../../redux/reducers/navigation'
import { parseQueryString, createQueryString } from '../../../utils/utilities'
import EligibilityModal from './component/eligibilityModel';
import PaReport from './rxHistory'
import ProviderRestrictions from './providerRestrictions'
import moment from 'moment'
import _ from 'lodash'
import  FliptMemberSearch from '../../../components/NewFliptMemberSearch'

class MemberLookup extends Component {
  constructor(props) {
    super(props)
    this.state = {
      memberModal: false,
      memberDetailSearchForm: {
        date_of_birth: '',
        first_name: '',
        last_name: '',
        id: '',
        plan_year: ''
      },
      memberUpdateForm: {
        user_id: '',
        domain: '',
        communication_option_email: '',
        location: {
          street_address: '',
          street_address_2: '',
          city: '',
          state: '',
          zip_code: '',
        },
        phone_number: '',
        is_vip: ''
      },
      showChangePassword: false,
    }
  }

  componentDidMount() {
    const { actions, history, state } = this.props
    const { location } = history

    if (!location) return
    const payload = parseQueryString(location.search)

    // if (state.user.domains_access.length) {
    //   this.setState((prevProps) => ({
    //     memberDetailSearchForm: {
    //       ...prevProps.memberDetailSearchForm,
    //       domain_name: state.user.domains_access[0].id,
    //     },
    //   }))
    // }
    // member_identifier: "member_id"
    // TODO - figure out how to remove settime out from here
    const p = (Object.keys(payload)[0] === 'member_id')
      ? {
        id: payload.member_id,
      } : {}

    this._clearFields()
    if (!_.isEmpty(p)) {
      setTimeout(() => {
        this.setState({ memberDetailSearchForm: { ...this.state.memberDetailSearchForm, ...p } })
        setTimeout(() => this.searchMember())
      }, Config.COMPONENT_DATA_LOAD_TIME)
    } else {
      const { urlHistory } = state?.app || {}
      const { pathname } = window.location
      if (pathname in urlHistory) {
        this.setState({ memberDetailSearchForm: urlHistory[pathname] })
        setTimeout(() => this.searchMember())
      }
    }

  }

  handleChangeEligibilityMode = () => {
    const { history: { location: { search } } } = this.props;

    const { memberDetailSearchForm } = this.state
    const { state: { memberLookup }, actions } = this.props
    const eligibilityParam = search.split('?')[1].split('&');
    const eligibilityData = {};
    eligibilityParam.forEach((eligibility) => {
      const temp = eligibility.split("=");
      eligibilityData[temp[0]] = '';
      eligibilityData[temp[0]] = temp[1];
    })
    const searchData = {
      date_of_birth: '',
      first_name: '',
      last_name: '',
      id: memberLookup?.member_details?.member_id || '',
      plan_year: '',
      eligibility: eligibilityData || {},
    }
    actions.getMemberLookupData(searchData)
    actions.toggleModal();
  }

  eligibilityUpdateMemberMode = (data) => {
    const { state: { memberLookup }, actions } = this.props
    const eligibilityData = {
      plan_name: data.benefit_plan_name,
      effective_date: data.coverage_effective_date,
      termination_date:data.coverage_termination_date
      };
    const searchData = {
      date_of_birth: '',
      first_name: '',
      last_name: '',
      id: memberLookup?.member_details?.member_id || '',
      plan_year: '',
      eligibility: eligibilityData || {},
    }
    this.setState({
      memberModal: false
    })
    actions.getMemberLookupData(searchData)
  }

  componentDidUpdate(prevProps) {
    const { props, state } = this
    const { memberLookup, memberLogs } = props.state
    const { history } = props
    const { location } = history
    const multipleMembersFound = memberLookup.multiMemberLookup.length
      && prevProps.state.memberLookup.multiMemberLookup !== memberLookup.multiMemberLookup
    if (memberLogs !== prevProps.state.memberLogs && memberLogs?.length) {
      history.push('/eligibility-logs')
    }
    if (multipleMembersFound) { 
        this.setState({memberModal :true })
    }

    const sameMember = !memberLookup.member_details.member_id || memberLookup.member_details.member_id === prevProps.state.memberLookup.member_details.member_id
    if (sameMember) return

    if (memberLookup.member_details.eligibility.length > 0) {
      let eligibleCount = 0;
      memberLookup.member_details?.eligibility.forEach((eligibilty) => {
        const today = moment(Date()).format('YYYY-MM-DD HH:MM:SS')
        const effective_date = moment(eligibilty.coverage_effective_date).format('YYYY-MM-DD HH:MM:SS')
        const termination_date = moment(eligibilty.coverage_termination_date).format('YYYY-MM-DD HH:MM:SS')
        if (today >= effective_date && today < termination_date) {
          eligibleCount = eligibleCount + 1;
        }
      });
      if (eligibleCount === 0 && memberLookup.member_details.eligibility.length > 1) {
        const payload = parseQueryString(location?.search || '')
        const { isPopup = false } = payload
        if (isPopup)
          return
          this.setState({memberModal :true })
      }
    }

    props.history.location.search = createQueryString({
      ...state.memberDetailSearchForm,
    })
  }

  setUpdateModal = (toggle = false) => {
    const { actions, state } = this.props
    const { member_details } = state.memberLookup

    if (!Object.keys(member_details).length) return

    actions.setModalComponent({
      modalProperties: { size: 'large' },
      contents: <UpdateMemberModal actions={actions} memberLookup={state.memberLookup} updateMemberDataFields={this.updateMemberDataFields} updateMemberDataForm={this.updateMemberData} />,
    })

    if (toggle) actions.toggleModal()
  }

  updateMemberDetailSearchForm = (el, dropdown) => {
    const { memberDetailSearchForm } = this.state
    const { name, value } = dropdown || el.currentTarget
    memberDetailSearchForm[name] = value
    this.setState({ memberDetailSearchForm })
  }

  searchMember = () => {
    const { history, state: { app } } = this.props;
    const { pathname } = window.location
    history.replace({ search: "" });

    const { memberDetailSearchForm } = this.state
    const { state: { memberLookup }, actions } = this.props
    const searchData = {
      date_of_birth: memberDetailSearchForm?.date_of_birth || '',
      first_name: memberDetailSearchForm.first_name || memberLookup?.member_details?.first_name || '',
      last_name: memberDetailSearchForm.last_name || memberLookup?.member_details?.last_name || '',
      id: memberDetailSearchForm.id || '',
      plan_year: memberDetailSearchForm.plan_year || '',
    }

    if (searchData['id']) {
      searchData['first_name'] = '';
      searchData['last_name'] = '';
      searchData['date_of_birth'] = '';
    }
    if (!searchData['id'] && (!searchData['first_name']) && !searchData['last_name']) {
      searchData['date_of_birth'] = ''
      const transitionalPortal = {
        header: "Fields are missing",
        copy: "Please add member Id/tpa Id, First Name or Last Name to search member",
      };
      actions.displayTransitionalPortal(transitionalPortal);
      return;
    }
 

    setTimeout(() => actions.getMemberLookupData(searchData), 750)
    actions.setUrlHistory({ [pathname]: searchData })
    this.setState({ memberDetailSearchForm: searchData  })
  }

  updateEligibility = () => {
   this.setState({ memberModal:true })
  }

  updateMemberDataFields = (el, dropdown) => {
    const { memberUpdateForm } = this.state
    const { name, value } = dropdown || el.currentTarget
    const locationFields = ['street_address', 'street_address2', 'city', 'state', 'zip_code']

    if (locationFields.indexOf(name) > -1) {
      memberUpdateForm.location = {
        ...memberUpdateForm.location,
        [name]: value,
      }
    } else {
      memberUpdateForm[name] = value
    }
    if (name === 'is_vip') {
      value === 'true' ? memberUpdateForm.is_vip = true : memberUpdateForm.is_vip = false
    }
    this.setState({ memberUpdateForm })
  }

  updateMemberData = () => {
    const { props: { actions, state }, state: { memberUpdateForm } } = this
    const { memberLookup } = state
    const memberForm = {
      ...memberUpdateForm,
      user_id: memberLookup.member_details.user_id,
      domain: memberLookup.member_details.domain_name,
    }

    actions.updateMemberData(memberForm)
  }
  updateMemberDataNew = (data,searchForm) => {
    const {setMemberLookupData } = this.props.actions
    const searchData = {
      date_of_birth: searchForm?.date_of_birth || '',
      first_name: searchForm?.first_name  || '',
      last_name: searchForm?.last_name || '',
      id: searchForm?.memberId || '',
      plan_year: '',
    }
    if(data){
      setMemberLookupData(data)
    }

   this.setState({ memberDetailSearchForm: searchData  })
  
  }

  toggleChangePassword = () => {
    this.setState({ showChangePassword: !this.state.showChangePassword });
  }

  getMemberEligibilityLogs = () => {
    const { props: { actions, state: { memberLookup } } } = this
    const memberForm = {
      subscriber_id: memberLookup?.primary_member_details?.subscriber_id,
      first_name: memberLookup?.primary_member_details?.first_name,
      last_name: memberLookup?.primary_member_details?.last_name,
      date_of_birth: memberLookup?.primary_member_details?.date_of_birth
    }
    actions.getMemberLogs(memberForm)
  }

  _clearFormFilter = () => {
    this.setState({
      memberDetailSearchForm: {
        date_of_birth: '',
        first_name: '',
        last_name: '',
        id: '',
        plan_year: ''
      }
    })
  }

  _clearFields = () => {
    const { props } = this
    props.actions.clearMemberLookupData()

    this._clearFormFilter()
  }
  closeModal =() =>{
    this.setState({
      memberModal :false
    })
  }

  render() {
    const { state, props } = this
    const { history, state: { user, memberLookup, hasPasswordWriteAccess } } = props
    const { pathname } = history.location
    const { memberModal,memberDetailSearchForm } = this.state;
    const isMemberLookup = pathname === '/member-lookup'
    const pageHeader = isMemberLookup ? 'Member Lookup' : 'Member Support'
    const disabledUpdate = !Object.keys(memberLookup.member_details).length
    const primary_member_details = memberLookup?.primary_member_details
    return (
      <div id="memberLookup">
        {!!memberLookup?.member_details?.is_platinum_vip && (
          <NotificationBar>
            <marquee>
              <p>
                Platinum VIP Member
              </p>
            </marquee>
          </NotificationBar>
        )}
        <BreadCrumb {...this.props} />
        <div className="header">{pageHeader}</div>
        <div className="content">
          {!!memberLookup.member_details
            && (
              <div className="section">
                <MemberDetails member_detail_search_form={state.memberDetailSearchForm} data={memberLookup} accounts_access={user.accounts_access} updateMemberSearchForm={this.updateMemberDetailSearchForm} searchMember={this.searchMember} clearFields={this._clearFields} actions={props.actions} updateEligibility={this.updateEligibility} />
                {<MemberLookupButtonBar primary_member_details={primary_member_details} updateMember={this.setUpdateModal} member_details={memberLookup.member_details} plan_data={memberLookup.plan_details} hasPasswordWriteAccess={hasPasswordWriteAccess} toggleChangePassword={this.toggleChangePassword} getMemberEligibilityLogs={this.getMemberEligibilityLogs} />}
              </div>
            )}
            { memberModal &&
              <FliptMemberSearch 
              updateMemberData={(data,search) => this.updateMemberDataNew(data,search)}
              searchForm={memberDetailSearchForm}
              open={memberModal}
              closeModal={this.closeModal}
              eligibilityUpdateMemberMode={(data) => this.eligibilityUpdateMemberMode(data)}
              />
            }
          {!!memberLookup.plan_details && <PlanDetails data={memberLookup} actions={props.actions} memberState={state.memberDetailSearchForm} />}
          {!!memberLookup.deductible_details && <DeductibleDetails data={memberLookup} actions={props.actions} />}
          {!!memberLookup.intent && <Intent data={memberLookup} actions={props.actions} />}
          {!!memberLookup.pa_case_data && <PaReport data={memberLookup} actions={props.actions} memberState={state.memberDetailSearchForm} />}
          {!!memberLookup.pa_details && <ProactiveOverride data={memberLookup} actions={props.actions} memberState={state.memberDetailSearchForm} />}
          {!!memberLookup.claim_override_details && <ClaimOverride data={memberLookup} actions={props.actions} memberState={state.memberDetailSearchForm} />}
          {!!memberLookup?.claim_override_details && <ProviderRestrictions data={memberLookup?.restriction_details?.edits} memberLookup={memberLookup} actions={props.actions} />}
          {/* {!!memberLookup.pa_details.length && <PriorAuthorization data={memberLookup} actions={props.actions} memberState={state.memberDetailSearchForm} />} */}
          {!!memberLookup.family_details.length && <FamilyDetails actions={props.actions} data={memberLookup} clearFields={this._clearFields} />}
          {state.showChangePassword && <UpdatePasswordModal showChangePassword={state.showChangePassword} setShowChangePassword={this.toggleChangePassword} />}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  state: {
    memberLookup: state.memberLookup,
    memberLogs: state.memberLookup.memberLogs,
    user: state.user,
    app: state.app,
    hasPasswordWriteAccess: state.user.permissions.findIndex((record) => {
      if ('password-update' in record) {
        return record['password-update'].includes('w')
      }
      return false;
    }) !== - 1,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
    ...MemberLookupActions,
    ...NavigationActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MemberLookup)
