import { applicableStatusType } from "../data/dataTypes";

export default {
    next_workflow_status: {
        type: 'dropdown',
        disabled: false,
        options: applicableStatusType,
    },
    applicable_workflow_status: {
        type: 'dropdown',
        disabled: false,
        options: applicableStatusType,
    },
}