import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Creators as ClientConfigurationActions } from '../../../../../redux/reducers/api/clientConfiguration';
import { Creators as GlobalEditsActions } from '../../../../../redux/reducers/api/globalEdits'
import '../styles.scss';
import FliptRadio from '../../../../../components/form/fliptRadio';
import Info from '../../Info';
import POSRebateConfig from './POSRebateConfig';


const POSRebateTab = (props) => {
    const { title, state, actions } = props;
    const { singleLevelAttribute, nodeType, addMode } = state;

    const {
        rebate_at_pos_adjustment,
    } = singleLevelAttribute;

    useEffect(() => {
        actions.getSectionUI({ section: 'global_copay' })
        actions.getPublishedUmObjects({ modules: ['programs', 'pos_rebate'] })
    }, []);

    const _updateFields = (el, dropdown) => {
        let { name, value, checked } = dropdown || el.currentTarget;
        if (name === 'rebate_at_pos_adjustment' || name === 'bill_client_rebate_amount_at_adjudication') {
            props.actions.updateAttributeFields({ name, value: (checked ? 'yes' : 'no') });
            return;
        }
        if (dropdown?.type == 'radio') {
            props.actions.updateAttributeFields({ name, value: checked });
            return
        }
        props.actions.updateAttributeFields({ name, value });
    };

    return (
        <div id='hierarchy-pos-rebate-config'>
            <fieldset className='pos-container'>
                <legend>POS Rebate</legend>
                <div className='fields-container'>
                    <div className={'fields-client-header'}>
                        <div className='field-label'>
                            <span />
                            <Info content={''} />
                        </div>
                        <div className='field-stylized radio-outline'>
                            <FliptRadio
                                label={'Rebate at POS Adjustment'}
                                name={'rebate_at_pos_adjustment'}
                                fullLabel={true}
                                toggle
                                className={'field-center'}
                                onChange={_updateFields}
                                checked={rebate_at_pos_adjustment?.value === 'yes'}
                                stylized='true'
                                value={rebate_at_pos_adjustment?.value}
                            />
                        </div>
                    </div>
                </div>
                {rebate_at_pos_adjustment?.value === 'yes' ?
                    <POSRebateConfig
                        updateFields={_updateFields}
                    />
                    : null
                }
            </fieldset>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        state: {
            singleLevelAttribute: state.clientConfiguration.singleLevelAttribute,
            addMode: state.clientConfiguration.addMode,
            nodeType: state.clientConfiguration.nodeType,
            companyTab: state.clientConfiguration.companyTab,
            validations: state.clientConfiguration.validations,
        }
    };
};

const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...ClientConfigurationActions,
        ...GlobalEditsActions,
    };

    return {
        actions: bindActionCreators(allActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(POSRebateTab);
