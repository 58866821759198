import {
  call,
  put,
  select,
  takeLatest,
  take,
} from 'redux-saga/effects'

import { Types, Creators as DrugManagementActions } from '../../reducers/api/drugManagement'
import { Creators as AppActions, Types as AppTypes } from '../../reducers/app'
import { Creators as UserActions, Types as UserTypes } from '../../reducers/user'
import { getAppState, getApiPath, isMenuEmpty } from '../../reducers/selectors'
import { getUserHierarchyAccess } from '../user/user'
import { fetchPost, fileDownload } from '../../../utils/fetchApi'
export default [
  getWeeklyDrugManagementDataWatcher,
  getLegacyReportWatcher,
  getNewDrugsWatcher,
  getDrugTermsWatcher,
  getChangesByTypeWatcher,
  getChangesByDrugWatcher,
  getChangesByObjectWatcher,
  getChangeTypeDetailsWatcher,
  getDrugInformationWatcher,
  getBrandWatcher,
  getImpactedObjectWatcher,
  approveChangesWatcher,
]

/* WATCHERS */
function* getWeeklyDrugManagementDataWatcher() {
  yield takeLatest(Types.GET_DM_WEEKLY_DRUG_MANAGEMENT_DATA, getWeeklyDrugManagementDataHandler)
}
function* getLegacyReportWatcher() {
  yield takeLatest(Types.GET_LEGACY_REPORT, getLegacyReportDataHandler)
}

function* getNewDrugsWatcher() {
  yield takeLatest(Types.GET_DM_NEW_DRUGS_DATA, getNewDrugsDataHandler)
}

function* getDrugTermsWatcher() {
  yield takeLatest(Types.GET_DM_DRUG_TERMS_DATA, getDrugTermsDataHandler)
}

function* getChangesByTypeWatcher() {
  yield takeLatest(Types.GET_DM_CHANGES_BY_TYPE_DATA, getChangeByTypeDataHandler)
}

function* getChangesByDrugWatcher() {
  yield takeLatest(Types.GET_DM_CHANGES_BY_DRUG_DATA, getChangeByDrugDataHandler)
}

function* getChangesByObjectWatcher() {
  yield takeLatest(Types.GET_DM_CHANGES_BY_OBJECT_DATA, getChangeByObjectDataHandler)
}

function* getChangeTypeDetailsWatcher() {
  yield takeLatest(Types.GET_DM_CHANGE_TYPE, getChangeTypeDetailsHandler)
}

function* getDrugInformationWatcher() {
  yield takeLatest(Types.GET_DM_DRUG_INFORMATION_DATA, getDrugInformationHandler)
}

function* getBrandWatcher() {
  yield takeLatest(Types.GET_DM_BRAND_DATA, getBrandDataHandler)
}

function* getImpactedObjectWatcher() {
  yield takeLatest(Types.GET_DM_IMPACTED_OBJECT_DATA, getImpactedObjectDataHandler)
}
function* approveChangesWatcher() {
  yield takeLatest(Types.DM_APPROVE_CHANGES, updateApproveChangesHandler)
}

/* HANDLERS */
function* getWeeklyDrugManagementDataHandler() {
  try {
    const { serviceUrl } = yield select(getAppState)
    const empty = yield select(isMenuEmpty)
    if(empty){
      yield take([AppTypes.SET_APP_SETTINGS])
    }
    const cd = yield select(getApiPath, 'drug-management-weekly-drug-management')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`
    const payload = { }
    payload.cag = yield call(getUserHierarchyAccess)
    const { data } = yield call(fetchPost, url, payload)

    if (data?.length > 0) {
      yield put(DrugManagementActions.setDmWeeklyDrugManagementData({ data }))
    } else {
      const transitionalPortal = {
        header: 'No Data Found',
        copy: '0 Results',
      }
      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    }
  } catch (err) {
    console.log('getWeeklyDrugManagementDataHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'DrugManagement Failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}
function* getLegacyReportDataHandler({ payload: { file_load_id } }){
  try {
    const empty = yield select(isMenuEmpty)
    if(empty){
        yield take([AppTypes.SET_APP_SETTINGS])
    }
    const { serviceUrl } = yield select(getAppState)
    const cd = yield select(getApiPath, 'drug-management-legacy-report')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`
    const payload = { file_load_id }
    const response = yield call(fileDownload, url, payload)
    const blob = new Blob([response], {type:"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
    const burl = window.URL.createObjectURL(blob)
    window.open(burl)
  } catch (err) {
    console.log('getWeeklyDrugManagementDataHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'DrugManagement Failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* getNewDrugsDataHandler({ payload: { file_id } }) {
  try {
    const empty = yield select(isMenuEmpty)
    if(empty){
        yield take([AppTypes.SET_APP_SETTINGS])
    }
    const { serviceUrl } = yield select(getAppState)
    const cd = yield select(getApiPath, 'drug-management-view-new-drugs')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`
    const payload = {
      file_load_id: file_id,
      action: 'add',
      change_type: null,
      ndc: null,
      gpi: null,
      doc: null,
    }
    payload.cag = yield call(getUserHierarchyAccess)
    const { data, freeze } = yield call(fetchPost, url, payload)

    if (data?.length > 0) {
      yield put(DrugManagementActions.setDmNewDrugsData({ data, freeze }))
    } else {
      const transitionalPortal = {
        header: 'No Data Found',
        copy: '0 Results',
      }
      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    }
  } catch (err) {
    console.log('getNewDrugsDataHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'DrugManagement Failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* getDrugTermsDataHandler({ payload: { file_id } }) {
  try {
    const empty = yield select(isMenuEmpty)
    if(empty){
        yield take([AppTypes.SET_APP_SETTINGS])
    }
    const { serviceUrl } = yield select(getAppState)
    const cd = yield select(getApiPath, 'drug-management-view-drug-term')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`
    const payload = {
      file_load_id: file_id,
      action: 'term',
      change_type: null,
      ndc: null,
      gpi: null,
      doc: null,
    }
    payload.cag = yield call(getUserHierarchyAccess)
    const { data } = yield call(fetchPost, url, payload)

    if (data?.length > 0) {
      yield put(DrugManagementActions.setDmDrugTermsData({ data }))
    } else {
      const transitionalPortal = {
        header: 'No Term Drugs',
        copy: '0 Results',
      }
      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    }
  } catch (err) {
    console.log('getDrugTermsDataHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'DrugManagement Failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* getChangeByDrugDataHandler({ payload: { file_id } }) {
  try {
    const empty = yield select(isMenuEmpty)
    if(empty){
        yield take([AppTypes.SET_APP_SETTINGS])
    }
    const { serviceUrl } = yield select(getAppState)
    const cd = yield select(getApiPath, 'drug-management-view-changes-by-drug')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`
    const payload = {
      file_load_id: file_id,
      action: 'change',
      change_type: null,
      ndc: null,
      gpi: null,
      doc: null,
    }
    payload.cag = yield call(getUserHierarchyAccess)
    const { data } = yield call(fetchPost, url, payload)

    if (data?.length > 0) {
      yield put(DrugManagementActions.setDmChangesByDrugData({ data }))
    } else {
      const transitionalPortal = {
        header: 'No Changes By Drug',
        copy: '0 Results',
      }
      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    }
  } catch (err) {
    console.log('getChangeByDrugDataHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'DrugManagement Failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* getChangeByTypeDataHandler({ payload: { file_id } }) {
  try {
    const empty = yield select(isMenuEmpty)
    if(empty){
        yield take([AppTypes.SET_APP_SETTINGS])
    }
    const { serviceUrl } = yield select(getAppState)
    const cd = yield select(getApiPath, 'drug-management-view-changes-by-type')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`
    const payload = {
      file_load_id: file_id,
      action: 'change',
      change_type: null,
      ndc: null,
      gpi: null,
      doc: null,
    }
    payload.cag = yield call(getUserHierarchyAccess)
    const { data } = yield call(fetchPost, url, payload)

    if (data?.length > 0) {
      yield put(DrugManagementActions.setDmChangesByTypeData({ data }))
    } else {
      const transitionalPortal = {
        header: 'No Changes By Type',
        copy: '0 Results',
      }
      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    }
  } catch (err) {
    console.log('getChangeByTypeDataHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'DrugManagement Failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* getChangeByObjectDataHandler({ payload: { file_id } }) {
  try {
    const empty = yield select(isMenuEmpty)
    if(empty){
        yield take([AppTypes.SET_APP_SETTINGS])
    }
    const { serviceUrl } = yield select(getAppState)
    const cd = yield select(getApiPath, 'drug-management-view-changes-by-object')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`
    const payload = {
      file_load_id: file_id,
    }
    payload.cag = yield call(getUserHierarchyAccess)
    const { data } = yield call(fetchPost, url, payload)

    if (data?.length > 0) {
      yield put(DrugManagementActions.setDmChangesByObjectData({ data }))
    } else {
      const transitionalPortal = {
        header: 'No Changes By Object',
        copy: '0 Results',
      }
      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    }
  } catch (err) {
    console.log('getChangeByObjectDataHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'DrugManagement Failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* getChangeTypeDetailsHandler({ payload: { file_id, change_type } }) {
  try {
    const empty = yield select(isMenuEmpty)
    if(empty){
        yield take([AppTypes.SET_APP_SETTINGS])
    }
    const { serviceUrl } = yield select(getAppState)
    const cd = yield select(getApiPath, 'drug-management-change-type-details')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`
    const payload = {
      file_load_id: file_id,
      change_type,
    }
    payload.cag = yield call(getUserHierarchyAccess)
    const { data } = yield call(fetchPost, url, payload)

    if (data?.length > 0) {
      yield put(DrugManagementActions.setDmChangeTypeDetailsData({ data }))
    } else {
      const transitionalPortal = {
        header: 'No Changes By Type',
        copy: '0 Results',
      }
      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    }
  } catch (err) {
    console.log('getChangeTypeDetailsHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'DrugManagement Failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* getDrugInformationHandler({ payload: { file_id, ...other } }) {
  try {
    const empty = yield select(isMenuEmpty)
    if(empty){
        yield take([AppTypes.SET_APP_SETTINGS])
    }
    const { serviceUrl } = yield select(getAppState)
    const cd = yield select(getApiPath, 'drug-management-drug-information')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`
    const payload = {
      file_load_id: file_id,
      ...other,
    }
    payload.cag = yield call(getUserHierarchyAccess)
    const { data, freeze } = yield call(fetchPost, url, payload)

    if (data?.length > 0) {
      yield put(DrugManagementActions.setDmDrugInformationData({ data, freeze }))
    } else {
      const transitionalPortal = {
        header: 'No Drug Found',
        copy: '0 Results',
      }
      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    }
  } catch (err) {
    console.log('getDrugInformationHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'DrugManagement Failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* getBrandDataHandler({ payload: { file_id, module, doc_id } }) {
  try {
    const empty = yield select(isMenuEmpty)
    if(empty){
        yield take([AppTypes.SET_APP_SETTINGS])
    }
    const { serviceUrl } = yield select(getAppState)
    const cd = yield select(getApiPath, 'drug-management-brand-tier')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`
    const payload = {
      file_load_id: file_id,
      doc_id,
      module,
    }
    const { data, freeze } = yield call(fetchPost, url, payload)

    if (data?.drugs.length > 0) {
      yield put(DrugManagementActions.setDmBrandData({ data, freeze }))
    } else {
      const transitionalPortal = {
        header: 'No Data Found',
        copy: '0 Results',
      }
      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    }
  } catch (err) {
    console.log('getBrandDataHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'DrugManagement Failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* getImpactedObjectDataHandler({ payload: { doc_id, doc_name, module } }) {
  try {
    const empty = yield select(isMenuEmpty)
    if(empty){
        yield take([AppTypes.SET_APP_SETTINGS])
    }
    const { serviceUrl } = yield select(getAppState)
    const cd = yield select(getApiPath, 'drug-management-drug-information-get-affected-data')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`
    const payload = {
      data: {
        doc_id,
        doc_name,
        module,
      },
    }
    const { data } = yield call(fetchPost, url, payload)

    if (data?.length > 0) {
      yield put(DrugManagementActions.setDmImpactedObjectData({ doc_id, data }))
    } else {
      const transitionalPortal = {
        header: 'No impacted Object found',
        copy: '0 Results',
      }
      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    }
  } catch (err) {
    console.log('getImpactedObjectDataHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'DrugManagement Failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* updateApproveChangesHandler({ payload: { file_id, ndc: approval_list, notes} }) {
  try {
    const empty = yield select(isMenuEmpty)
    if(empty){
        yield take([AppTypes.SET_APP_SETTINGS])
    }
    const { serviceUrl } = yield select(getAppState)
    const cd = yield select(getApiPath, 'drug-management-approve-changes')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`
    const payload = {
      file_load_id: file_id,
      approval_list,
      approved_by: '',
      status: 'Approved',
      approval_notes: notes,
    }
    const { status } = yield call(fetchPost, url, payload)

    if (status === "200") {
      yield put(DrugManagementActions.setReloadData())
    } else {
      const transitionalPortal = {
        header: 'Something went wrong',
        copy: 'Approve Changes Failed',
      }
      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    }
  } catch (err) {
    console.log('updateApproveChangesHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'DrugManagement Failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}
