import discountType, { rateType } from './discountType'
import drugTypes from './drugTypes'
import feeTypes from './feeTypes'
import priceType from './priceType'

/* eslint-disable */
export default {
  claim_processor: {
    type: 'dropdown',
    disabled: true,
    options: [],
  },
  price_type: {
    type: 'dropdown',
    disabled: true,
    options: priceType
  },
  drug_type: {
    type: 'dropdown',
    disabled: true,
    options: drugTypes,
  },
  specialty_drug_list: {
    type: 'dropdown',
    disabled: true,
    options: [],
  },
  min_days_supply: {
    type: 'input',
    disabled: true,
  },
  max_days_supply: {
    type: 'input',
    disabled: true,
  },
  fixed_variable: {
    type: 'dropdown',
    disabled: true,
    options: discountType,
  },
  rate_type: {
    type: 'dropdown',
    disabled: true,
    options: rateType,
  },
  discount: {
    type: 'input',
    disabled: true,
  },
  dispensing_fee: {
    type: 'input',
    disabled: true,
  },
  fee_type: {
    type: 'dropdown',
    disabled: true,
    options: feeTypes,
    multiple: true,
  },
  fee_value: {
    type: 'input',
    disabled: true,
  },
  mac_list_id: {
    type: 'input',
    disabled: true,
  },
  price_floor: {
    type: 'input',
    disabled: true,
  },
}
