import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import BreadCrumb from '../../../../components/breadCrumb'
import FliptInput from '../../../../components/v2/fliptInput'
import FliptDatePicker from '../../../../components/v2/fliptDatePicker'
import FliptDropdown from '../../../../components/v2/fliptDropdown'
import { Creators as contractManagementActions } from '../../../../redux/reducers/api/contractManagement'
import { Creators as AppActions } from '../../../../redux/reducers/app'

import { editProcessorHeader, processorCellRendererParams, processorType } from '../data/tabData'
import './styles.scss'
import FliptGrid from '../../../../components/fliptGrid'
import { DatePickerEditor, InputTextEditor } from '../../../../components/fliptGrid/cellEditors'
import FliptButton from '../../../../components/v2/fliptButton'
import moment from 'moment-timezone'
import { DeleteRowRenderer } from '../../../../components/fliptGrid/cellRenderers'
import { convertSnakeCaseToString } from '../../../../utils/utilities'

const ProcessorMgmt = (props) => {
    const { actions } = props
    const { location: { state } } = props
    const { editMode } = state
    const [form, setForm] = useState({
        processor_name: '',
        processor_start_date: '',
        processor_end_date: '',
        processor_type: '',
        status: 'Draft',
        transfer_bin: '',
        transfer_pcn: '',
    })
    const [gridData, setGridData] = useState([{ bin: '', pcn: '', effective_start_date: '', effective_end_date: '' }])

    useEffect(() => {
        if (editMode) {
            const {
                processor_name,
                processor_start_date,
                processor_end_date,
                processor_type,
                status,
                transfer_bin,
                transfer_pcn,
                doc_version,
                doc_id,
                bin_pcn_details
            } = state
            const tempGridData = bin_pcn_details?.length ? bin_pcn_details : gridData
            const editForm = {
                processor_name,
                processor_start_date: processor_start_date ? moment(processor_start_date).toDate() : '',
                processor_end_date: processor_end_date ? moment(processor_end_date).toDate() : '',
                doc_version: doc_version,
                processor_type,
                status: status ? status : 'Draft',
                transfer_bin,
                transfer_pcn,
                doc_id,
                id: doc_id
            }
            setForm(editForm)
            setGridData(tempGridData)
        }
    }, [])

    const handleChange = (el, dropdown, rowIndex) => {
        const newGridData = [...gridData]
        const { name, value } = dropdown || el.currentTarget
        newGridData[rowIndex][name] = value
        setGridData(newGridData)
    }

    const handleFieldChange = (el, dropdown) => {
        const tempForm = { ...form }
        const { name, value } = dropdown || el.currentTarget
        if (name === 'processor_type') {
            tempForm.transfer_bin = ''
            tempForm.transfer_pcn = ''
        }
        tempForm[name] = value
        setForm(tempForm)
    }


    const cellEditorParams = {
        effective_start_date: {
            cellEditor: DatePickerEditor,
            onChange: handleChange,
        },
        effective_end_date: {
            cellEditor: DatePickerEditor,
            onChange: handleChange,
        },
        pcn: {
            cellEditor: InputTextEditor,
            onChange: handleChange,
        },
        bin: {
            cellEditor: InputTextEditor,
            onChange: handleChange,
        }
    }

    const delRow = (rowIndex) => {
        const tempGridData = [...gridData]
        tempGridData.splice(rowIndex, 1)
        setGridData(tempGridData)
    }

    const addRow = () => {
        const tempGridData = [...gridData]
        tempGridData.push({ bin: '', pcn: '', effective_start_date: '', effective_end_date: '' })
        setGridData(tempGridData)
    }

    const processorCellRendererParams = {
        action: {
            cellRenderer: DeleteRowRenderer,
            state: {
                onClick: (index) => delRow(index),
            },
            width: 100,
        },
        pcn: {
            overrideHeader: 'PCN',
        },
        bin: {
            overrideHeader: 'BIN',
        },
        effective_start_date: {
            valueFormatter: (params) => {
                if (!params.value) return ''
                return moment(params.value)?.format('YYYY-MM-DD') || params.value
            },
        },
        effective_end_date: {
            valueFormatter: (params) => {
                if (!params.value) return ''
                return moment(params.value)?.format('YYYY-MM-DD') || params.value
            },
        }

    }

    const saveProcessor = () => {
        form.bin_pcn_details = gridData
        let isError = false
        let isEmpty = false
        const header = 'Field Error'
        const filtered = []
        const requiredFields = ['processor_name', 'processor_start_date', 'processor_type']
        if (form.processor_type === 'transfer')
            requiredFields.push('transfer_bin', 'transfer_pcn')
        Object.keys(form).map((x) => {
            if (requiredFields.includes(x) && form[x] === '') {
                const msg = `In order to save, a ${convertSnakeCaseToString(x)} is required to be configured. Please configure to continue.`
                showTransitionPortal(header, msg)
                isEmpty = true
            }
        })
        if (isEmpty) return
        if (!gridData?.length) {
            const msg = `In order to save, a BIN and PCN Record is required to be configured. Please configure to continue.`
            showTransitionPortal(header, msg)
            return
        }

        gridData.forEach(function (entry) {
            if (!entry.bin || !entry.pcn || !entry.effective_start_date) {
                const msg = 'In order to save, BIN, PCN and Effective Date is required to be configured. Please configure to continue.'
                showTransitionPortal(header, msg)
                isError = true
                return
            }
            let key = [entry.bin, entry.pcn].join(':')
            if (filtered.includes(key)) {
                const msg = 'In order to save, BIN and PCN combination should be unique. Please configure to continue. '
                showTransitionPortal(header, msg)
                isError = true
            } else filtered.push(key)
        })
        if (isError) return

        if (editMode) actions.updateProcessor(form)
        else actions.createProcessor(form)
    }

    const showTransitionPortal = (header, copy) => {
        const transitionalPortal = {
            header,
            copy
        }
        actions.displayTransitionalPortal(transitionalPortal)
    }


    return <div id="processorMgmt">
        <div className='header'>
            <BreadCrumb {...props} />
            Processor Management
        </div>
        <div className='field-container'>
            <FliptInput stylized label='Processor Name' name='processor_name' value={form.processor_name} onChange={handleFieldChange} disabled={editMode} />
            <FliptDropdown stylized value={form.processor_type} label='Processor Type' options={processorType} name='processor_type' onChange={handleFieldChange} />
            <FliptDatePicker stylized value={form.processor_start_date} label='Processor Start Date' name='processor_start_date' onChange={handleFieldChange} />
            <FliptDatePicker stylized value={form.processor_end_date} label='Processor End Date' name='processor_end_date' onChange={handleFieldChange} />
        </div>
        <div className='field-container'>
            {form.processor_type === 'transfer' ? <>
                <FliptInput stylized label='Transfer BIN' name='transfer_bin' value={form.transfer_bin} onChange={handleFieldChange} />
                <FliptInput stylized label='Transfer PCN' name='transfer_pcn' value={form.transfer_pcn} onChange={handleFieldChange} />
            </>
                : null}
            {editMode ? <FliptInput disabled stylized value={form.status} label='Status' /> : null}


        </div>
        <div className='grid-container'>
            <FliptGrid headers={editProcessorHeader} data={gridData} cellRendererParams={processorCellRendererParams}
                cellEditorParams={cellEditorParams} />
        </div>
        <div className='button-container'>
            <FliptButton name='Add' className='primary' onClick={() => { addRow(); }} />
            <FliptButton name='SAVE' className='primary' onClick={() => { saveProcessor() }} />
        </div>

    </div>
}

const mapStateToProps = (state) => ({
    state: {
        user: state.user,
    },
})

const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...contractManagementActions,
        ...AppActions
    }

    return {
        actions: bindActionCreators(allActions, dispatch),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ProcessorMgmt)