/* eslint-disable */
import React, { forwardRef } from 'react'
import { Dropdown } from 'semantic-ui-react'
import './styles.scss'

const FliptDropdown = forwardRef((props, ref) => {
    const {
        className,
        defaultValue,
        description,
        disabled,
        hidden,
        icon,
        label,
        options,
        placeholder,
        error
    } = props
    // removing this for now as we should not set default value without communicating it
    // to parent component
    // const defaultvalue = options?.length === 1 ? options[0].value : defaultValue
    const placeHolder = placeholder || 'Select Option'
    const containerClassName = `${className || ''} v2-flipt-dropdown`
    return (
        <div className={containerClassName}>
            {label && <div className="description-text">
                <div className='flipt-label' hidden={props.hidden || false}>
                    <label htmlFor={label}>{label}</label>
                </div>
                {/* {questionText && <Icon className="question circle outline" />} */}
            </div>}
            {icon}
            <Dropdown className="dropdown-component"
                {...props}
                search
                options={options}
                placeholder={placeHolder}
                hidden={hidden || false}
                defaultValue={defaultValue}
                selection
                disabled={disabled} />
            {error && <section className="flipt-input-error"><em>{error}</em></section>}
        </div>
    )
})

export default FliptDropdown
