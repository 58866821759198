import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import './styles.scss'
import BreadCrumb from '../../../components/breadCrumb'
import FliptButton from '../../../components/form/fliptButton'
import FliptInput from '../../../components/form/fliptPaInput'
import FliptDropdown from '../../../components/form/fliptPaDropdown'
import FliptGrid from '../../../components/fliptGrid'
import statesJSON from '../../../config/constants/states'
import { Creators as AppActions } from '../../../redux/reducers/app'
import { Creators as PharmacyLookupActions } from '../../../redux/reducers/api/pharmacyLookup'
import { createQueryString, parseQueryString } from '../../../utils/utilities'
import ServerSideDatasource from '../../../components/fliptGrid/serverSideDataSource'
import { LinkRenderer } from '../../../components/fliptGrid/cellRenderers'

function WarningModal(props) {
  const {
    toggleModal, message, onContinueClick, onCancelClick
  } = props

  const handleClick = () => {
    onContinueClick()
    toggleModal()
  }
  const handleCancel = () => {
    onCancelClick()
    toggleModal()
  }
  return (
    <div className="warningModal">
      <div className="header-text">
        <h3>{message}</h3>
      </div>
      <div className="buttons-container">
        <FliptButton className="primary" name="Continue" onClick={handleClick} />
        <FliptButton className="primary" name="Cancel" onClick={handleCancel} />
      </div>
    </div>
  )

}
class PharmacyLookup extends Component {
  constructor(props) {
    super(props)

    this.state = {
      form: {
        pharmacynpi: '',
        pharmacycity: '',
        pharmacystate: '',
        pharmacyzip1: '',
        pharmacytype: 'ALL',
        networkStatus: 'ALL',
        pharmacyname: ''
      },
      pharmacyTypeValues: ['Retail', 'All'],
      networkStatusValues: ['Active', 'All'],
      agGridRef: React.createRef(),
    }
  }

  componentDidMount() {
    const { history } = this.props
    const { location } = history

    if (!location.search) return
    const { form, agGridRef } = this.state

    this._qsToFormData()
    history.location.search = createQueryString(form)

    agGridRef.current.api.refreshServerSideStore({ route: null, purge: true }) // to refresh the grid server side data
  }

  componentDidUpdate(prevProps) {
    const { props, state } = this
    const { actions } = props
    const { agGridRef } = state
    if (prevProps.state.totalRows !== props.state.totalRows) {
      if (props.state.totalRows > 500) {
        actions.setModalComponent({
          modalProperties: {
            size: 'tiny',
          },
          contents: <WarningModal
            message={`You have selected ${props.state.totalRows} records. Do you want to continue?`}
            onContinueClick={() => {
              agGridRef.current.api.refreshServerSideStore({ route: null, purge: true })
            }}
            toggleModal={actions.toggleModal}
            onCancelClick={() => {
              actions.clearPharmacyLookupReducer()
              this._clearFormFilter()
            }}
          />
        })
        actions.toggleModal()
      } else {
        agGridRef.current.api.refreshServerSideStore({ route: null, purge: true })
      }
    }
  }

  _pharmacySearch() {
    const { state, props } = this
    const { form } = state
    const { history } = props
    props.actions.clearPharmacyLookupReducer();
    history.location.search = createQueryString(form)
    props.actions.getPharmacyLookupData({ form, getTotalRows: true })
  }

  _clearFormFilter = () => {
    const { state, props } = this
    const { agGridRef } = state
    props.actions.clearPharmacyLookupReducer();
    const form = {
      pharmacynpi: '',
      pharmacycity: '',
      pharmacystate: '',
      pharmacyzip1: '',
      pharmacytype: 'ALL',
      networkStatus: 'ALL',
      pharmacyname: ''
    };
    this.setState({ form })
    const headers = [
      'pharmacynpi', 'pharmacyname', 'pharmacytype', 'network_status', 'pharmacyphone', 'pharmacyaddress1', 'pharmacyaddress2',
      'pharmacycity', 'pharmacystate', 'pharmacyzip1', 'chaincode',
    ]
    const datasource = new ServerSideDatasource(form, 'pharmacy-lookup', props.actions.getPharmacyLookupData, headers)
    agGridRef.current.api.setServerSideDatasource(datasource);
  }

  _updateFields = (el, dropdown) => {
    const { form } = this.state
    const { name, value } = dropdown || el.currentTarget

    form[name] = value.toUpperCase()
    this.setState({ form })
  }

  _qsToFormData = () => {
    const { state, props } = this
    const qs = parseQueryString(props.history.location.search)
    const { form } = state

    Object.keys(qs).forEach((key) => {
      form[key] = qs[key].toUpperCase()
    })
    this.setState({ form })
  }

  handleIdClicked = (pharmacyData) => {
    const { data } = pharmacyData
    this.props.history.push('/contract-discount-lookup', { data })
  }

  render() {
    const { state, props } = this
    const { actions } = props
    const {
      form, pharmacyTypeValues, networkStatusValues, agGridRef,
    } = state
    const {
      pharmacynpi, pharmacycity, pharmacystate, pharmacyzip1,
      pharmacytype, networkStatus, pharmacyname
    } = form
    const {
      state: {
        pharmaciesPerPage,
      },
    } = props
    const headers = [
      'pharmacynpi', 'pharmacyname', 'pharmacytype', 'network_status', 'pharmacyphone', 'pharmacyaddress1', 'pharmacyaddress2',
      'pharmacycity', 'pharmacystate', 'pharmacyzip1', 'chaincode',
    ]
    const cellRendererParams = {
      pharmacyname: {
        overrideHeader: 'Name',
      },
      pharmacytype: {
        overrideHeader: 'Type',
      },
      pharmacynpi: {
        cellRenderer: LinkRenderer,
        onCellClicked: this.handleIdClicked,
        searchArgs: {},
        overrideHeader: 'NPI',
      },
      pharmacyaddress1: {
        overrideHeader: 'Address 1',
      },
      pharmacyaddress2: {
        overrideHeader: 'Address 2',
      },
      pharmacycity: {
        overrideHeader: 'City',
      },
      pharmacystate: {
        overrideHeader: 'State',
      },
      pharmacyzip1: {
        overrideHeader: 'Zip',
      },
      pharmacyphone: {
        overrideHeader: 'Phone',
      },
      network_status: {
        filter: false
      }
    }

    const statesData = Object.entries(statesJSON).map(([k, v]) => ({
      key: k,
      text: `${k} - ${v}`,
      value: k,
    }))
    const pharmacyTypeOptions = pharmacyTypeValues.map((val, idx) => ({
      key: idx,
      text: val,
      value: val.toUpperCase(),
    }))
    const networkStatusOptions = networkStatusValues.map((val, idx) => ({
      key: idx,
      text: val,
      value: val.toUpperCase(),
    }))

    const serverSideGridConfig = {
      cacheBlockSize: pharmaciesPerPage,
      pagination: true,
      paginationPageSize: pharmaciesPerPage,
      rowModel: 'serverSide',
      serverSideStoreType: 'partial',
    }
    const serverSideGridParams = {
      apiId: 'pharmacy-lookup',
      form,
      headers,
      sagaToCall: actions.getPharmacyLookupData,
    }

    return (
      <div id="pharmacyLookup">
        <BreadCrumb {...this.props} />
        <div className="header">Pharmacy Lookup</div>
        <div className="horizontal-fields">
          <FliptInput stylized label='Pharmacy NPI' placeholder="NPI" name="pharmacynpi" value={pharmacynpi} onChange={this._updateFields} />
          <FliptInput stylized label="Pharmacy Name" name="pharmacyname" value={pharmacyname} onChange={this._updateFields} />
          <FliptDropdown stylized search label="State" name="pharmacystate" value={pharmacystate} options={statesData} simple item onChange={this._updateFields} />
          <FliptInput stylized label="City" name="pharmacycity" value={pharmacycity} onChange={this._updateFields} />
        </div>
        <div className="horizontal-fields">
          <FliptInput stylized label="Zip Code" name="pharmacyzip1" value={pharmacyzip1} onChange={this._updateFields} />
          <FliptDropdown stylized label="Pharmacy Type" name="pharmacytype" value={pharmacytype} options={pharmacyTypeOptions} simple item onChange={this._updateFields} />
          <FliptDropdown stylized label="Newtork Status" name="networkStatus" value={networkStatus} options={networkStatusOptions} simple item onChange={this._updateFields} />
        </div>
        <div className="button-container">
          <FliptButton name="Search" className="primary searchButton" onClick={() => this._pharmacySearch(false)} />
          <FliptButton name="Clear" className="secondary clearButton" onClick={() => this._clearFormFilter()} />
        </div>

        <div className="content">
          <FliptGrid
            agGridRef={agGridRef}
            cellRendererParams={cellRendererParams}
            filterOptions={['contains', 'equals']}
            headers={headers}
            serverSideGridConfig={serverSideGridConfig}
            serverSideGridParams={serverSideGridParams}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  state: {
    app: state.app,
    loading: state.pharmacyLookup.loading,
    pharmacyLookupData: state.pharmacyLookup.pharmacyLookupData,
    pharmaciesPerPage: state.pharmacyLookup.pharmaciesPerPage,
    pharmacySuggestData: state.pharmacyLookup.pharmacySuggestData,
    totalRows: state.pharmacyLookup.pharmacyTotalRows,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
    ...PharmacyLookupActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PharmacyLookup)
