import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import './styles.scss'
import { Creators as AppActions } from '../../../redux/reducers/app'
import MenuCard from './menuCardHome'
import Banner from '../../../components/headers/banner'
import Masonry from './masonry'

class Home extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activeCard: ''
    }
  }

  _toggleCard = (menuId) => {
    const { activeCard } = this.state

    this.setState({ activeCard: menuId == activeCard ? '' : menuId })
  }

  _renderMenuCards = (activeCard) => {
    const { state: { menu } } = this.props
    const order = [
      "plan-design-configuration",
      'customer-service',
      'network-pricing-and-contract-management',
      'system-admin', 'integration-management',
      'pa-workflow',
      'client-portal',
      'contact-help',
      'reporting',
      'claims-adjudication'
    ]

    // let orderedMenu = this._sortObjectsByOrder(menu, order)
    return menu.map((m) => {
      const aCard = activeCard == m.id
      return !m.hidden && <MenuCard key={m.id} menu={m} toggleCard={this._toggleCard} activeCard={aCard} />
    })

  }

  _sortObjectsByOrder(arrayA, arrayB) {
    // Create a map to store the indices of items in array B
    const indexMap = new Map();
    arrayB.forEach((item, index) => indexMap.set(item, index));

    // Sort arrayA based on the indices in arrayB
    arrayA.sort((a, b) => {
      const indexA = indexMap.has(a.id) ? indexMap.get(a.id) : Infinity;
      const indexB = indexMap.has(b.id) ? indexMap.get(b.id) : Infinity;
      return indexA - indexB;
    });

    // Append any remaining items from arrayA that are not in arrayB
    const remainingItems = arrayA.filter(item => !indexMap.has(item.id));

    return arrayA.filter(item => indexMap.has(item.id)).concat(remainingItems);
  }

  render() {
    const { activeCard } = this.state
    const breakpointColumnsObj = {
      default: 3,
      1100: 3,
      700: 2,
      500: 1
    };

    return (
      <div id="homePage">
        <div id="cardMenuContainer">
          <Banner />
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className="masonry-grid"
            columnClassName="masonry-grid_column"
          >
            {this._renderMenuCards(activeCard)}
          </Masonry>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  state: {
    app: state.app,
    menu: state.app.menu_data,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)
