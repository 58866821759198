/* eslint-disable */
export default {
  action: {
    type: 'input',
    disabled: false,
  },
  claim_processor: {
    type: 'dropdown',
    disabled: false,
    options: [],
  },
  program_drug_list: {
    type: 'dropdown',
    disabled: false,
    options: [],
    multiple: true,
  },
  pharmacy_name: {
    type: 'input',
    disabled: false,
  },
  chain_code: {
    type: 'dropdown',
    disabled: false,
    options: []
  },
  relationship_id: {
    type: 'input',
    disabled: false,
  },
  relationship_name: {
    type: 'input',
    disabled: false,
  },
  relationship_type: {
    type: 'input',
    disabled: false,
  },
  corp_name: {
    type: 'input',
    disabled: false,
  },
  npi: {
    type: 'input',
    disabled: false,
  },
  state: {
    type: 'dropdown',
    disabled: false,
    options: [],
  },
  zip_code: {
    type: 'input',
    disabled: false,
  },
  include_exclude: {
    type: 'dropdown',
    disabled: false,
    options: [{ key: 'Include', text: 'Include', value: 'include' }, { key: 'Exclude', text: 'Exclude', value: 'exclude' }],
  },
  effective_start_date: {
    type: 'date',
    disabled: false,
  },
  effective_end_date: {
    type: 'date',
    disabled: false,
  },
}
