import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import Config from '../../../config'
import './styles.scss'
import BreadCrumb from '../../../components/breadCrumb'
import ClaimTable from '../../../components/fliptGrid'
import { Creators as ApiActions } from '../../../redux/reducers/api/claimHistory'
import { Creators as AppActions } from '../../../redux/reducers/app'

import {
  createQueryString, parseQueryString, filterRowData
} from '../../../utils/utilities'

class ClaimHistory extends Component {
  constructor(props) {
    super(props)

    this.state = {
      form: {
        flipt_person_id: '',
      },
    }
  }
  _dataCleanup = () => {
    const { state: { data,date_of_birth } } = this.props
    if(data){
      data.forEach(claim => {
        claim.created_date_two = claim.created_date
        claim.date_of_birth = date_of_birth
      })
    }
    return data
  }
  componentDidMount() {
    const { history } = this.props
    const { location } = history
    if (!location) return
    const { form } = this.state
    this._qsToFormData()
    setTimeout(() => this.props.actions.getClaimHistoryData(form), Config.COMPONENT_DATA_LOAD_TIME)
  }

  _qsToFormData = () => {
    const { history } = this.props
    const { form } = this.state
    const qs = parseQueryString(history.location.search)

    Object.keys(qs).forEach((key) => {
      form[key] = qs[key]
    })
    this.setState({ form })
  }

  render() {
    const headers = [
      'Auth ID', 'Processed Date', 'Date Of Service',
      'Date Of Birth','Rx Number', 'Drug Name', 'Drug Strength',
      'Drug Dosage Form', 'Submitted Quantity', 'Days Supply', 'Pharmacy ID', 'Brand/Generic', 'Patient Pay Amount',
    ]

    const headerMapping = {
      auth_id: 'Auth ID',
      created_date: 'Processed Date',
      created_date_two: 'Date Of Service',
      date_of_birth: 'Date Of Birth',
      prescription_id: 'Rx Number',
      drug_name: 'Drug Name',
      dosage_strength: 'Drug Strength',
      form: 'Drug Dosage Form',
      package_qty: 'Submitted Quantity',
      daysofsupply: 'Days Supply',
      npi: 'Pharmacy ID',
      brandorgeneric: 'Brand/Generic',
      patient_paid: 'Patient Pay Amount'
    }
    let gridData = this._dataCleanup()
    gridData = gridData && gridData.length ? gridData.map((d) => ({
      ...filterRowData(d, headers, headerMapping),
    })) : []
    return (
      <div id="claimHistory">
        <BreadCrumb {...this.props} />
        <div className="content">
          <ClaimTable
            headers={headers}
            headerMapping={headerMapping}
            data={gridData}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
    return {
      state: {
        data: state.claimHistory.data,
        date_of_birth: state.memberLookup?.member_details?.date_of_birth
      }
    }
}

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
    ...ApiActions
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClaimHistory)
