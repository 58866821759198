import React, { PureComponent, useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Creators as FileConfigurationActions } from "../../../../redux/reducers/api/fileConfiguration";
import { Creators as IntegrationManagementActions } from "../../../../redux/reducers/api/integrationManagement";
import "./styles.scss";
import AddOrganization from "./AddOrganization";
import AddClient from "./AddClient";
import AddCarrier from "./AddCarrier";
import AddAccount from "./AddAccount";
import AddGroup from "./AddGroup";

const AddOption = (props) => {
  const { state, actions } = props;
  const { step } = state;
  return (
    <div id="add-organization">
      <div className="container">
        <div className="subheader">
          <div>
            <ul className="progressbar">
              <li
                className={
                  step >= 0 ? `active ${step > 0 ? " completed" : ""}` : ""
                }
              >
                Organization
              </li>
              <li
                className={
                  step >= 1 ? `active ${step > 1 ? " completed" : ""}` : ""
                }
              >
                Client
              </li>
              <li
                className={
                  step >= 2 ? `active ${step > 2 ? " completed" : ""}` : ""
                }
              >
                Carrier
              </li>
              <li
                className={
                  step >= 3 ? `active ${step > 3 ? " completed" : ""}` : ""
                }
              >
                Account
              </li>
              <li
                className={
                  step >= 3 ? `active ${step > 4 ? " completed" : ""}` : ""
                }
              >
                Group
              </li>
            </ul>
          </div>
        </div>
        <div className="content-progress">
          {step == 0 && <AddOrganization />}
          {step == 1 && <AddClient />}
          {step == 2 && <AddCarrier />}
          {step == 3 && <AddAccount />}
          {step == 4 && <AddGroup />}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    state: {
      step: state.clientConfiguration.step,
    },
  };
};

const mapDispatchToProps = (dispatch) => {
  const allActions = {};

  return {
    actions: bindActionCreators(allActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddOption);
