import React, { useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as _ from 'lodash'
import FliptGrid from '../../../components/fliptGrid'
import { ColorCodedStatusRenderer, LinkContainerRenderer } from '../../../components/fliptGrid/cellRenderers'
import PopupCellRenderer from './popup/popupCellRenderer'
import { Creators as contractManagementActions } from '../../../redux/reducers/api/contractManagement'
import { Creators as NetworkCreationActions } from '../../../redux/reducers/api/networkCreationManagement'
import { Creators as AppActions } from '../../../redux/reducers/app'

import './styles.scss'
import { defaultTabConfigs, processorHeaders } from './data/tabData'

const processorTab = (props) => {
    const { state, actions, checkActionType } = props
    const { selectedTab, processorUpdateData } = state
    const agGridRef = React.createRef()
    const processorForm = {
        processors: [],
        data_only: true,
        offset: 0,
        limit: 20,
        max_version_only: true
    }

    const processorCellRendererParams = {
        action: {
            cellRenderer: PopupCellRenderer,
            searchArgs: {
                onClick: (action, rowdata) => checkActionType(action, rowdata),
            },
            width: 100,
        },
        doc_name: {
            cellRenderer: LinkContainerRenderer,
            searchArgs: [
                {
                    path: '/contract-management',
                    searchArgs: { doc_id: 'doc_id', doc_version: 'doc_version' },
                    state: { editMode: false },
                    key: 'doc_name',
                    staticSearchArgs: {},
                },
            ],
            width: 100,
            overrideHeader: 'Contract Name'
        },
        status: {
            cellRenderer: ColorCodedStatusRenderer,
        },
        pcn: {
            overrideHeader: 'PCNs'
        },
        bin: {
            overrideHeader: 'BINs'
        }
    }

    useEffect(() => {
        if (selectedTab === defaultTabConfigs[1].id) {
            agGridRef.current.api.refreshServerSideStore({ route: null, purge: true })
        }
    }, [])

    useEffect(() => {
        if (selectedTab === defaultTabConfigs[1].id) {
            agGridRef.current.api.refreshServerSideStore({ route: null, purge: true })
        }
    }, [processorUpdateData])





    const serverSideGridConfig = {
        rowModel: 'serverSide',
        serverSideStoreType: 'partial',
        cacheBlockSize: 20,
        pagination: true,
        paginationPageSize: 20,
    }

    const serverSideGridParams = {
        form: processorForm,
        apiId: 'processor-lookup',
        sagaToCall: actions.getProcessorData,
        headers: processorHeaders,
    }

    return (

        <div className="content maxHeight">
            <FliptGrid
                headers={processorHeaders}
                cellRendererParams={processorCellRendererParams}
                serverSideGridConfig={serverSideGridConfig}
                serverSideGridParams={serverSideGridParams}
                agGridRef={agGridRef}
            />
        </div>
    )
}


const mapStateToProps = (state) => ({
    state: {
        contractSummaryData: state.contractManagement.contractSummaryData,
        selectedTab: state.contractManagement.selectedTab,
        contractData: state.contractManagement.contractData,
        allNetworkData: state.networkTierCreation.allNetworkTiersData,
        processorUpdateData: state.contractManagement.processorUpdateData
    },
})

const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...contractManagementActions,
        ...NetworkCreationActions,
        ...AppActions
    }

    return {
        actions: bindActionCreators(allActions, dispatch),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(processorTab)
