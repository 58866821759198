import React, { useEffect, useState } from 'react'
import './styles.scss'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import BreadCrumb from '../../../../components/breadCrumb'
import { Creators as ApiActions } from '../../../../redux/reducers/api/claimLookupManagement'
import { isEmpty } from 'lodash'
import SubmenuNav from '../claimTransaction/shared/navigation'
import JsonView from "react-json-view";
import { capitalizeStr, convertSnakeCaseToString } from '../../../../utils/utilities'

function ClaimTraceability(props) {
    const { actions, state: { traceability_section } } = props
    const [traceabilityData, setTableData] = useState([])




    useEffect(() => {
        if (traceability_section?.length) {
            const formattedData = traceability_section.map((data) => {
                return ({
                    heading: capitalizeStr(convertSnakeCaseToString(data.claim_edit)),
                    pass: data.result === 'PASS',
                    showParams: false,
                    value: '',
                    params: { ...data.edit_params, result: data?.result, overrides_used: data?.overrides_used }
                })
            })
            setTableData(formattedData)
        }
    }, [traceability_section])

    const setopenAcc = (index) => {
        const tempTableData = [...traceabilityData]
        tempTableData[index].showParams = !tempTableData[index].showParams
        setTableData(tempTableData)
    }

    const renderTableRows = traceabilityData?.length ? traceabilityData.map((tableData, index) => {
        return (<>
            <div className='row-container'>
                <div className='tab-heading'>
                    <span>
                        {tableData.heading}
                    </span>

                    <div className='result-container'>
                        <span>{tableData.value}</span>
                    </div>
                </div>
                <div className='img-container' onClick={() => { setopenAcc(index) }}>
                    <img src='/i/expand.png' height={25} width={25} ></img>
                </div>

            </div>
            {tableData?.showParams && !isEmpty(tableData.params) ? <JsonView
                src={tableData.params}
                style={{ minWidth: '645px', maxHeight: '50vh', overflow: 'auto', padding: '10px' }}
                name={false}
                displayDataTypes={false}
            /> : null}
        </>)
    }) : null

    return (
        <div id="claim-traceability">
            <BreadCrumb {...props} />
            <div className="header">
                <h1>Traceability</h1>
                <SubmenuNav history={props.history} active="traceability" />
            </div>
            <div className='table-container'>
                {renderTableRows}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    state: {
        traceability_section: state.claimLookup.claimTransactionData?.traceability_section || [],
    }
})
const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...ApiActions,
    }

    return {
        actions: bindActionCreators(allActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClaimTraceability)
