import React from 'react'
import { Link } from 'react-router-dom'
import { parseQueryString } from '../../../../../utils/utilities'

export default (props) => {
  const { active, history } = props
  const { search, state } = history.location
  const payload = parseQueryString(search)
  const { plan_name } = payload
  const isMeddicare = plan_name ? plan_name.toLowerCase().includes('medicare') : false
  const isNx = state?.hasOwnProperty('showNx') || history?.location?.isNx
  return (
    <div>
      <Link
        to={{
          pathname: '/claim-transaction-overview',
          search,
          isNx
        }}
        className={active === 'overview' ? 'active' : ''}
      >
        Overview

      </Link>
      <Link
        to={{
          pathname: '/claim-transaction-pricing',
          search,
          isNx
        }}
        className={active === 'pricing' ? 'active' : ''}
      >
        Pricing

      </Link>
      {isMeddicare && (<Link
        to={{
          pathname: '/claim-transaction-medicarePartDDetails',
          search,
          isNx
        }}
        className={active === 'medicareDetails' ? 'active' : ''}
      >
        Medicare Part D Details

      </Link>)}
      {(isMeddicare || isNx) && (<Link
        to={{
          pathname: '/nx-claim-transaction-details',
          search,
          isNx
        }}
        className={active === 'nxTransactions' ? 'active' : ''}
      >
        Nx Transactions

      </Link>)}
      <Link
        to={{
          pathname: '/claim-transaction-providers',
          search,
          isNx
        }}
        className={active === 'providers' ? 'active' : ''}
      >
        Providers

      </Link>
      <Link
        to={{
          pathname: '/claim-transaction-rejection',
          search,
          isNx
        }}
        className={active === 'rejection' ? 'active' : ''}
      >
        Rejection

      </Link>
      {isMeddicare && (<Link
        to={{
          pathname: '/claim-transaction-medicareInformationReporting',
          search,
          isNx
        }}
        className={active === 'medicareInformationReporting' ? 'active' : ''}
      >
        Medicare Part D Information Reporting

      </Link>)}
      <Link
        to={{
          pathname: '/claim-transaction-compund-ingredients',
          search,
          isNx
        }}
        className={active === 'ingredients' ? 'active' : ''}
      >
        Compound Ingredients
      </Link>
      <Link
        to={{
          pathname: '/claim-request-response',
          search,
          isNx
        }}
        className={active === 'request' ? 'active' : ''}
      >
        Claim Request and Response
      </Link>
      <Link
        to={{
          pathname: '/claim-traceability',
          search,
          isNx
        }}
        className={active === 'traceability' ? 'active' : ''}
      >
        Claim Traceability
      </Link>
    </div>
  )
}
