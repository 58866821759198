export const overrideTypeValues = [
    {
        text: 'Select',
        value: '',
        key: '',
    },
    {
        text: 'Override All Edits',
        value: 'ALL',
        key: 'ALL',
    },
    {
        text: 'Copay Override',
        value: 'copay',
        key: 'copay',
    },
]

export const claimTypeValues = [
    {
        text: 'Claim Submission & Reversal',
        value: 'B4',
        key: 'B4',
    }
]