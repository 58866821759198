import {
  call,
  put,
  select,
  takeLatest,
  take,
  // all,
} from 'redux-saga/effects'

// import data from '../../../pages/private/medicareSetup/lics/medicarePlanDetails/data'
// import moment from 'moment-timezone'
// import { Creators as MemberActions } from '../../reducers/api/memberLookup'
// import { Creators as NavigationActions } from '../../reducers/navigation'
import { Creators as AppActions, Types as AppTypes } from '../../reducers/app'
import { Types, Creators as formularyExportLookupActions } from '../../reducers/api/formularyExportLookup'
import { createQueryString, filterRowData } from '../../../utils/utilities'
import { fetchGet, fetchPost } from '../../../utils/fetchApi'
import { getApiUrlHandler } from '../app'
import { getAppState, getApiPath, getFormularyExportDrugInfo, getHistory, getUserState, isMenuEmpty } from '../../reducers/selectors'


export default [
  getFormularyExportDrugDataWatcher,
  getFormularyExportDrugOptionsDataWatcher
]

/* WATCHERS */

function* getFormularyExportDrugDataWatcher() {
  yield takeLatest(Types.GET_FORMULARY_EXPORT_DRUG_DATA, getFormularyExportDrugDataHandler)
}

function* getFormularyExportDrugOptionsDataWatcher() {
  yield takeLatest(Types.GET_FORMULARY_EXPORT_DRUG_OPTIONS_DATA, getFormularyExportDrugOptionsDataHandler)
}
/* HANDLERS */

function* getFormularyExportDrugOptionsDataHandler() {
  try {
    const empty = yield select(isMenuEmpty)
    if (empty) {
      yield take([AppTypes.SET_APP_SETTINGS])
    }
    const { serviceUrl } = yield select(getAppState)
    const cd = yield select(getApiPath, 'formulary-export-lookup')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`
    const { data } = yield call(fetchGet, url)
    yield put(formularyExportLookupActions.setFormularyExportDrugOptionsData(data[0]))
  } catch (err) {
    console.log('getFormularyExportDrugOptionsHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Getting Formulary Export LookUp Options Failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* getFormularyExportDrugDataHandler({ payload }) {
  try {
    const {
      form, params, headers, headerMapping, isAdjustment = false
    } = payload
    let tempForm = { ...form }
    const { serviceUrl } = yield select(getAppState)
    const history = yield select(getHistory)
    const { drugsPerPage } = yield select(getFormularyExportDrugInfo)
    const { userCAG = {} } = yield select(getUserState)
    history.location.search = createQueryString({
      ...tempForm,
      offset: params?.request.startRow,
    })
    const qs = history.location.search ? `${history.location.search}&limit=${drugsPerPage}` : ''
    if (!qs && !isAdjustment) {
      params?.success({
        rowData: [],
        rowCount: 0,
      })
    } else {
      const empty = yield select(isMenuEmpty)
      if (empty) {
        yield take([AppTypes.SET_APP_SETTINGS])
      }
      const exportUrl = yield call(getApiUrlHandler, 'formulary-export-lookup')
      //const { api_path } = yield select(getApiUrlHandler, 'formulary-export-lookup')
      //const url = `${serviceUrl}${exportUrl}`
      const reqData = {
        formulary_name: tempForm?.formulary_name, // need it here to make it easy for json deserialization
        gpi: tempForm?.gpi14, // need it here to make it easy for json deserialization
        ndc: tempForm?.ndc, // need it here to make it easy for json deserialization
        drug_name: tempForm?.drug_name, // need it here to make it easy for json deserialization
        doc_name: tempForm?.doc_name, // need it here to make it easy for json deserialization
        ddid: tempForm?.ddid, // need it here to make it easy for json deserialization
        sortModel: params?.request.sortModel,
        filterModel: params?.request.filterModel,
        limit: drugsPerPage,
        offset: params?.request.startRow,
        pagination: true
      }
      let res
      if (!isAdjustment && headers) {
        const { data, last_row } = yield call(fetchPost, exportUrl, reqData)
        let gridData = data
        gridData = data?.map((d) => ({
          ...filterRowData(d, headers, headerMapping)
        }))
        params?.success({
          rowData: gridData,
          rowCount: last_row,
        })
        yield put(formularyExportLookupActions.setFormularyExportDrugData(data))
      }
    }
  } catch (err) {
    console.log('getFormularyExportDrugDataHandler Error > Data: ', err)
    const transitionalPortal = {
      header: 'Formulary Export Lookup Failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

export {
  getFormularyExportDrugDataHandler,
  getFormularyExportDrugOptionsDataHandler
}