import React, { useState, useMemo } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import '../styles.scss'
import _ from "lodash"

import { Creators as ClinicalCreationActions } from '../../../../../../redux/reducers/api/clinicalCreationManagement'
import targetDrugRowCellInfo from '../data/targetDrugRowCellInfo'
import FliptInput from '../../../../../../components/form/fliptInput'
import FliptDatePicker from '../../../../../../components/form/fliptDatePicker'
import FliptButton from '../../../../../../components/form/fliptButton'
import FliptGrid from '../../../../../../components/fliptGrid'
import AddRow from '../addRow'
import { DeleteRowRenderer, CheckboxRenderer, ButtonRenderer } from '../../../../../../components/fliptGrid/cellRenderers'
import { InputTextEditor, DropdownEditor, SearchEditor, DatePickerEditor } from '../../../../../../components/fliptGrid/cellEditors'
import { addRemoveDaysToDate, convertStrToDateObj, filterRowData, buildDropdownOptions } from '../../../../../../utils/utilities'
import FliptHierarchyDropdown from '../../../../../../components/form/fliptHierarchyDropdown'
import moment from 'moment'
import * as ApprovalsConstants from '../../../../../../redux/sagas/rpm/constants'
import { deleteUnnecessaryFields } from '../../../../../../utils/utilizationManagement'

function StepTherapyTargetDrugs(props) {
  const { DRAFT, PUBLISHED, REJECTED } = ApprovalsConstants
  const {
    state, form, setForm, targetDrugsConditions, targetDrugsDropdownOptions, targetDrugsAutoSuggestionValues,
    updateFields, handleChange, nextFormStep, autoSuggestionResultSelect, autoSuggestionMinChars, addTargetDrugsCondition,
    delTargetDrugsCondition, editMode, saveStepTherapy, cellRenderers, onTargetCheckboxChange
  } = props

  const [showGenerateDrugsGrid, setShowGenerateDrugsGrid] = useState(false)
  const [currentGenerateDrugCondition, setCurrentGenerateDrugCondition] = useState({})
  const [gridKey, setGridKey] = useState('')

  const {
    doc_name, doc_version, effective_start_date, version, status, version_effective_date
  } = form
  const { multiSourceOptions, brandGenericOptions, otcIndicatorOptions, DESIOptions,
    routeOfAdministrationOptions, drugIndicatorOptions, dosageFormOptions,
    thirdPartyRestrictionOptions, deaClassCodeOptions, storageConditionCodeOptions,
    marketingCategoryOptions, applicationTypeOptions } = state.conditionsDropDownOptions

  targetDrugRowCellInfo.brand_generic.options = brandGenericOptions
  targetDrugRowCellInfo.desi_code.options = DESIOptions
  targetDrugRowCellInfo.dosage_form_cd.options = dosageFormOptions
  targetDrugRowCellInfo.maintenance_drug_code.options = drugIndicatorOptions
  targetDrugRowCellInfo.multi_source.options = multiSourceOptions
  targetDrugRowCellInfo.otc_indicator.options = otcIndicatorOptions
  targetDrugRowCellInfo.repackaged_code.options = [{ key: "Y", value: "Y", text: "Repackaged" }, { key: "N", value: "N", text: "Not Repackaged" }]
  targetDrugRowCellInfo.route_of_administration.options = routeOfAdministrationOptions
  targetDrugRowCellInfo.third_party_restriction_code.options = thirdPartyRestrictionOptions
  targetDrugRowCellInfo.dea_class_code.options = deaClassCodeOptions
  targetDrugRowCellInfo.clinic_pack_code.options = [{ key: "Y", value: "Y", text: "Y" }, { key: "N", value: "N", text: "N" }]
  targetDrugRowCellInfo.innerpack_code.options = [{ key: "Y", value: "Y", text: "Y" }, { key: "N", value: "N", text: "N" }]
  targetDrugRowCellInfo.unit_dose_with_non_unit_dose_non_repackager_in_gpi.options = [{ key: "Y", value: "Y", text: "Y" }, { key: "N", value: "N", text: "N" }]
  targetDrugRowCellInfo.rx_with_otc_in_gpi.options = [{ key: "Y", value: "Y", text: "Y" }, { key: "N", value: "N", text: "N" }]
  targetDrugRowCellInfo.storage_condition_code.options = storageConditionCodeOptions
  targetDrugRowCellInfo.marketing_category.options = marketingCategoryOptions
  targetDrugRowCellInfo.application_type_flag.options = applicationTypeOptions
  targetDrugRowCellInfo.item_status_flag.options = buildDropdownOptions(["I", "A"])
  targetDrugRowCellInfo.unit_dose.options = [{ key: "", value: "", text: "" }, { key: "U", value: "U", text: "U" }, { key: "X", value: "X", text: "X" }]
  targetDrugRowCellInfo.fda_therapeutic_equivalence_code.options = buildDropdownOptions(["AA",
    "AB",
    "AB1",
    "AB1,AB2",
    "AB1,AB2,AB3",
    "AB1,AB2,AB3,AB4",
    "AB1,AB3",
    "AB2",
    "AB3",
    "AB4",
    "AN",
    "AO",
    "AP",
    "AP1",
    "AP2",
    "AT",
    "AT1",
    "AT2",
    "AT3",
    "BC",
    "BD",
    "BP",
    "BS",
    "BX"])

  if (editMode) {
    targetDrugRowCellInfo.action = {
      type: 'deleteRow',
      disabled: false,
    }
    Object.keys(targetDrugRowCellInfo).forEach((key) => { targetDrugRowCellInfo[key].disabled = false })
  } else {
    delete targetDrugRowCellInfo.action
    Object.keys(targetDrugRowCellInfo).forEach((key) => { targetDrugRowCellInfo[key].disabled = true })
  }

  const headers = Object.keys(targetDrugRowCellInfo)
  if (headers.includes('action')) {
    headers.splice(headers.indexOf('action'), 1)
    headers.unshift('action')
  }

  const gridHeaders = [
    'gpi', 'drug_group', 'drug_class', 'drug_subclass', 'manufacturer', 'drug_name', 'multi_source', 'ndc',
    'ddid', 'brand_generic', 'otc_indicator', 'route_of_administration', 'desi_code', 'maintenance_drug_code',
    'effective_start_date', 'effective_end_date', 'application_type_flag', 'mfg_labeler_id',
    'repackaged_code', 'third_party_restriction_code', 'dosage_form_cd'
  ]

  const minStepTherapyStartDate = addRemoveDaysToDate(1, false)

  const handleGenerateDrugsClick = (data, submitType, rowIndex) => {
    // reusing the same object reference and not using setState for currentGenerateDrugCondition
    // is intentional. it is necessary for FliptGrid to function correctly
    Object.assign(currentGenerateDrugCondition, { ...data, include_inactive_drugs: form.include_inactive_drugs })

    const tempObj = deleteUnnecessaryFields(currentGenerateDrugCondition)
    const randomNumber = Math.floor(Math.random() * 900) + 100
    const key = Object.values(tempObj).reduce((acc, newValue) => acc = `${acc}${newValue || ''}${randomNumber}`, '')
    setGridKey(key)

    if (!showGenerateDrugsGrid) {
      setShowGenerateDrugsGrid(true)
    }
  }

  let cellRendererParams = {
    action: {
      cellRenderer: DeleteRowRenderer,
      state: {
        onClick: delTargetDrugsCondition,
        foo: 'bar',
      },
      width: 95,
    },
    include: {
      overrideHeader: 'Include',
      cellRenderer: CheckboxRenderer,
      state: {
        onCheckboxChange: onTargetCheckboxChange,
      },
    },
    generate_drugs: {
      cellRenderer: ButtonRenderer,
      searchArgs: { onClick: handleGenerateDrugsClick, text: 'Generate Drugs' },
      width: 175,
    },
    effective_start_date: {
      valueFormatter: (params) => {
        if (!params.value) return ''
        return moment(params.value)?.format('YYYY-MM-DD') || params.value
      },
    },
    effective_end_date: {
      valueFormatter: (params) => {
        if (!params.value) return ''
        return moment(params.value)?.format('YYYY-MM-DD') || params.value
      },
    }
  }

  cellRendererParams = { ...cellRendererParams, ...cellRenderers }

  const cellEditorParams = {

    gpi: {
      cellEditor: InputTextEditor,
      onChange: handleChange,
    },
    ndc: {
      cellEditor: InputTextEditor,
      onChange: handleChange,
    },
    drug_group: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    drug_class: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    drug_subclass: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    manufacturer: {
      cellEditor: SearchEditor,
      onChange: handleChange,
      autoSuggestionResultSelect,
    },
    drug_name: {
      cellEditor: SearchEditor,
      onChange: handleChange,
      autoSuggestionResultSelect,
    },
    multi_source: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    ndc: {
      cellEditor: InputTextEditor,
      onChange: handleChange,
    },
    ddid: {
      cellEditor: InputTextEditor,
      onChange: handleChange,
    },
    item_status_flag: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    brand_generic: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    otc_indicator: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    desi_code: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    route_of_administration: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    maintenance_drug_code: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    effective_start_date: {
      cellEditor: DatePickerEditor,
      onChange: handleChange,
    },
    effective_end_date: {
      cellEditor: DatePickerEditor,
      onChange: handleChange,
    },
    application_type_flag: {
      cellEditor: InputTextEditor,
      onChange: handleChange,
    },
    mfg_labeler_id: {
      cellEditor: InputTextEditor,
      onChange: handleChange,
    },
    repackaged_code: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    third_party_restriction_code: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    dosage_form_cd: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    clinic_pack_code: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    innerpack_code: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    unit_dose: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    dea_class_code: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    fda_therapeutic_equivalence_code: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    marketing_category: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    storage_condition_code: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    unit_dose_with_non_unit_dose_non_repackager_in_gpi: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    rx_with_otc_in_gpi: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    member_notes: {
      cellEditor: InputTextEditor,
      onChange: handleChange,
    },
    agent_notes: {
      cellEditor: InputTextEditor,
      onChange: handleChange,
    },
    internal_notes: {
      cellEditor: InputTextEditor,
      onChange: handleChange,
    },
    claim_message_code: {
      cellEditor: InputTextEditor,
      onChange: handleChange,
    },
    claim_message_type: {
      cellEditor: InputTextEditor,
      onChange: handleChange,
    },
  }

  const serverSideGridConfig = {
    rowModel: 'serverSide',
    serverSideStoreType: 'partial',
    cacheBlockSize: 20,
    pagination: true,
    paginationPageSize: 20,
  }

  const serverSideGridParams = {
    form: currentGenerateDrugCondition,
    apiId: 'generate-step-therapy-target-drugs',
    sagaToCall: props.actions.generateStepTherapyTargetDrugs,
    headers,
  }

  const defaultColDef = useMemo(() => ({
    resizable: true,
    floatingFilter: true,
    filter: "agTextColumnFilter",
    filterParams: { buttons: ["reset"], filterOptions: ['contains', 'equals'] },
    outerHeight: 120,
  }), [])

  const saveAndContinue = () => {
    // e.preventDefault()
    nextFormStep()
  }

  const stCellRenderer = {
    route_of_administration: {
      hide: true
    }
  }
  const hiddenColumn = Object.keys(cellRendererParams).filter(key => cellRendererParams[key]?.hide)
  const columnData = {}
  targetDrugsConditions.map(ele => {
    Object.keys(ele).forEach(key => {
      columnData[key] = !!columnData[key] || !!ele[key]
    })
  })
  hiddenColumn.forEach(key => {
    if (columnData[key]) {
      cellRendererParams[key].hide = false
    }
  })

  const generateDrugsCellRendererParams = _.cloneDeep(cellRendererParams)
  generateDrugsCellRendererParams.ndc.hide = false
  delete generateDrugsCellRendererParams.include
  const unsortableKeys = ['effective_start_date', 'effective_end_date']
  for (let key in generateDrugsCellRendererParams) {
    if (generateDrugsCellRendererParams[key]) {
      if (unsortableKeys.includes(key)) {
        generateDrugsCellRendererParams[key].sortable = false
      }
      generateDrugsCellRendererParams[key].filterParams = {
        filterOptions: ['contains', 'equals'],
        defaultJoinOperator: 'OR',
        buttons: ['reset'],
      }
    }
  }

  return (
    <div className="section">
      <section className="step-therapy-inputs-container spacing border-line shadow">
        <div className="step-therapy-inputs">
          <span>Step Therapy Name</span>
          <FliptInput placeholder="Step Therapy Name" name="doc_name" value={doc_name} disabled={!editMode || (!!version && (version !== '1.0')) || (!!status && (status === 'PUBLISHED'))} onChange={updateFields} />
        </div>
        <div className="step-therapy-inputs">
          <span>Step Therapy Version</span>
          <FliptInput name="doc_version" value={doc_version} disabled onChange={updateFields} />
        </div>
        <div className="step-therapy-inputs">
          <span>Effective Start Date</span>
          <FliptDatePicker className="create-step-therapy-start-date" name="effective_start_date" minDate={minStepTherapyStartDate} value={convertStrToDateObj(effective_start_date)} disabled={!editMode} onChange={updateFields} />
        </div>
        <div className="step-therapy-inputs">
          <span>Version Effective Date</span>
          <FliptDatePicker className="create-ql-start-date" name="version_effective_date" value={convertStrToDateObj(version_effective_date)} onChange={updateFields} format="MM-DD-YYYY" readOnly={!editMode} />
        </div>
        <FliptHierarchyDropdown showInactiveDrugs={true} form={form} setForm={setForm} showHierarchyGlobal={true} />
      </section>
      <section className="grid-container spacing border-line shadow">
        <FliptGrid
          data={targetDrugsConditions}
          headers={headers}
          cellRendererParams={cellRendererParams}
          cellEditorParams={cellEditorParams}
          rowCellInfo={targetDrugRowCellInfo}
          dropdownOptions={targetDrugsDropdownOptions}
          autoSuggestionValues={targetDrugsAutoSuggestionValues}
          autoSuggestionMinChars={autoSuggestionMinChars}
        />
        <div className="addRowButtonContainer">
          {!!editMode && (
            <AddRow addRow={addTargetDrugsCondition} />
          )}
        </div>
      </section>
      <div className="buttonContainer">
        {/* <FliptButton name="Generate Target Drugs" onClick={generateStepTherapyTargetDrugs} /> */}
        <FliptButton name="Continue to Next Step" onClick={saveAndContinue} />
        {!!editMode
          && <FliptButton
            className="primary searchButton"
            disabled={![DRAFT, PUBLISHED, REJECTED].includes(status)}
            name="Save"
            onClick={saveStepTherapy}
          />}
      </div>
      {showGenerateDrugsGrid && (
        <section className="grid-container-target-drugs spacing border-line shadow">
          <FliptGrid
            key={gridKey}
            defaultColDef={defaultColDef}
            headers={gridHeaders}
            cellRendererParams={generateDrugsCellRendererParams}
            serverSideGridConfig={serverSideGridConfig}
            serverSideGridParams={serverSideGridParams}
            filterOptions={['contains', 'equals']}
            suppressColumnVirtualisation
          />
        </section>
      )}
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...ClinicalCreationActions
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

const mapStateToProps = (state) => ({
  state: {
    conditionsDropDownOptions: state.rpm.conditionsDropDownOptions,
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(StepTherapyTargetDrugs)
