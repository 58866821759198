import { useState, useEffect,useMemo } from "react";
import FliptDropdown from "../../../../components/form/fliptDropdown";
import PROGRESSION from "../../../../config/constants/progression";

const PlanSearchForm = ({ hierarchy, updateHierarchy }) => {
 
 
  const [dropdownOptions, setDropdownOptions] = useState({});
  const [selectedOptions, setSelectedOptions] = useState({});
  const dummyOptions = [{key: 1, value: "", text: ''}]
  const data = useMemo(()=>{
    return  (hierarchy && Object.values(hierarchy)?.[0])? 
      Object.values(hierarchy).map((it)=>it[0]): 
      [];
    } ,[hierarchy] )
  const _progression = PROGRESSION.filter((item) => item !== "TOP");

  const handleDropdownChange = (el, dropdown,_) => {
    const { name, value } = dropdown || el.currentTarget;
    setSelectedOptions((prevOptions) => ({
      ...prevOptions,
      [name]: value
    }));
    updateHierarchy(name, value);
    handleLevelChange(name, value);
  };

  const buildDropdownOptions = (data, level) => {
    if (!data || !data.length) {
      setDropdownOptions((prevOptions) => ({
        ...prevOptions,
        [level]: []
      }));
      return;
    }

    const options = data.map((item) => ({
      key: item[`${level}_id`],
      text: item[`${level}_name`],
      value: item[`${level}_id`]
    }));    
    setDropdownOptions((prevOptions) => ({
      ...prevOptions,
      [level]: options
    }));
  };

  const handleLevelChange = (level, selectedValue) => {
    const nextLevel = getNextLevel(level);
    const selectedNode = findNode(data, level, selectedValue);
    if (level == _progression[_progression.length-1]) return;
    if ( selectedNode && selectedNode.children?.length > 0) {
      buildDropdownOptions(selectedNode.children, nextLevel);
       
    } else {      
      setDropdownOptions((prevOptions) => ({
        ...prevOptions,
        [nextLevel]: []
      }));
    }
  };

  const getNextLevel = (currentLevel) => { 
    const currentIndex = _progression.indexOf(currentLevel);     
    return currentIndex < PROGRESSION.length - 1
      ? _progression[currentIndex + 1]
      : currentLevel;
  };

  const findNode = (node, level, selectedValue) => {
    if (Array.isArray(node) && node.length){
      for (const childNode of node) {
        if (childNode && childNode[`${level}_id`] === selectedValue) {
          return childNode;
        }
        let resultNode = findNode(childNode.children,level, selectedValue)
        if (resultNode){
          return resultNode
        }
      }
      if (node.length == 1){
        node= node[0]
      }
    }
    if (!node || node[`${level}_id`] === selectedValue) {
      return node;
    }

    if (node.children) {
      for (const childNode of node.children) {
        const foundNode = findNode(childNode, level, selectedValue);
        if (foundNode) {
          return foundNode;
        }
      }
    }

    return null;
  };

  useEffect(() => {
   
    if (data && data.length > 0) {
     
      buildDropdownOptions(data, _progression[0]);
    }
  }, [data]);
 
  return (
    <div className="v2-flipt-hierarchy-dropdown">
      {_progression.map((level, i, array) => (
        <div className="flex-item">
          <FliptDropdown
            key={level}
            options={dropdownOptions[level]?.length > 0 ? dropdownOptions[level] || dummyOptions : dummyOptions}
            label={`${level}`}
            value={selectedOptions[level] || ""}
            onChange={(el, dr) => handleDropdownChange(el,dr,i)}
            stylized
            name={level}
            readOnly={!(i == 0 || selectedOptions[array[i - 1]])}
          />
        </div>
      ))}
    </div>
  );
};

export default PlanSearchForm;
