import React, { PureComponent, useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Creators as FileConfigurationActions } from "../../../../redux/reducers/api/fileConfiguration"
import { Creators as IntegrationManagementActions } from "../../../../redux/reducers/api/integrationManagement";
import "./styles.scss";
import { Creators as AppActions } from "../../../../redux/reducers/app";
import { Link } from "react-router-dom";
import FliptButton from "../../../../components/form/fliptButton";

import BreadCrumb from "../../../../components/breadCrumb";
import { Icon } from "semantic-ui-react";

import FliptGrid from "../../../../components/fliptGrid";
import FliptInput from "../../../../components/v2/fliptInput";
import { filterRowData } from "../../../../utils/utilities";
import {
  LinkRenderer,
} from "../../../../components/fliptGrid/cellRenderers";
//import PopupCellRenderer from './popup/popupCellRenderer'
import PopupCellRenderer from "./popup/popupCellRenderer";
import CreateFileConfiguration from './CreateFileConfiguration'
import { Modal } from 'semantic-ui-react'
import { CONFIG_ACTIONS, CONFIG_STATUS } from '../constants'

const FileConfigurationDashboard = (props) => {
  const { dashboard } = props.state;
  const [showPopupFlag, setShowPopupFlag] = useState(false);
  const [showCopyModal, setShowCopyModal] = useState(false);
  const [showStatusChangeModal, setShowStatusChangeModal] = useState(false);
  const [record, setRecord] = useState({});
  const [copyRecordTitle, setCopyRecordTitle] = useState('');

  useEffect(() => {
    if (props.state.app?.menu_data?.length > 0) {
      props.actions.getFileConfigurationDashboard();
    }
  }, [props.state.app.menu_data])

  const headers = [
    "actions",
    "file_config_name",
    "entity_name",
    "file_type",
    "file_direction",
    "file_status",
    "file_id",
  ];
  const headerMapping = {
    actions: "actions",
  };
  const gridData =
    dashboard && dashboard.length
      ? dashboard.map((d) => ({
        ...filterRowData(d, headers, headerMapping),
      }))
      : [];

  const sortByName = (valueA, valueB) => {
    if (valueA.plan_name === valueB.plan_name) return 0;
    let x = valueA.plan_name.toLowerCase();
    let y = valueB.plan_name.toLowerCase();
    return x > y ? 1 : -1;
  };
  const checkActionType = (action, rowdata) => {
    setRecord(rowdata)
    switch (action) {
      case CONFIG_ACTIONS.EDIT:
        const { file_config_name, file_type, file_id } = rowdata;
        const record = dashboard.find((record) => record.file_id === file_id && record.file_type === file_type)
        props.actions.getFileConfiguration(record)
        break;
      case CONFIG_ACTIONS.COPY:
        const defaultName = rowdata.file_config_name ? `${rowdata.file_config_name} - Copy` : '';
        setCopyRecordTitle(defaultName)
        setShowCopyModal(true)
        break;
      case CONFIG_ACTIONS.CANCEL:
      case CONFIG_ACTIONS.DELETE:
      case CONFIG_ACTIONS.PUBLISH:
      case CONFIG_ACTIONS.REPUBLISH:
      case CONFIG_ACTIONS.SUBMIT:
        setShowStatusChangeModal({ show: true, action })
      default:
        return
    }

  };
  const handleEnterEditMode = (rowdata) => {
    const { file_config_name, file_type, file_id } = rowdata.data;
    const record = dashboard.find((record) => record.file_id === file_id && record.file_type === file_type)
    props.actions.getFileConfiguration(record)
  };

  const handleCreateFileConfiguration = (form) => {
    setShowPopupFlag(false);
    props.actions.createNewFileConfiguration(form);
  };

  const cellRendererParams = {
    actions: {
      cellRenderer: PopupCellRenderer,
      searchArgs: {
        onClick: checkActionType,
      },
    },
    file_config_name: {
      cellRenderer: LinkRenderer,
      file_config_name: 'file_config_name',
      path: '/file-configuration',
      searchArgs: {},
      state: { editMode: true },
      onCellClicked: handleEnterEditMode,

    },
    file_status: {
      overrideHeader: 'Status'
    },

  }

  return (
    <>
      <div id="file-configuration-dashboard">
        <div className="breadcrum-container">
          <BreadCrumb {...props} />
        </div>
        <div className="main-header">
          FILE ADMINISTRATION AND DETAILS
          <div
            className="create-plan"
            aria-hidden="true"
            onClick={() => {
              setRecord({})
              setShowPopupFlag(true)
            }}
          >
            <Icon name="plus circle" size="tiny" />
            Create a file configuration
          </div>
        </div>

        <div className="content">
          <FliptGrid
            data={
              gridData && gridData.length > 0 ? gridData.sort(sortByName) : []
            }
            headers={headers}
            cellRendererParams={cellRendererParams}
            hideSideBar={false}
          />
        </div>
        {showPopupFlag && (
          <CreateFileConfiguration
            data={record}
            showTabFlag={showPopupFlag}
            setShowTabFlag={setShowPopupFlag}
            handleCreateFileConfiguration={handleCreateFileConfiguration}
          />
        )}

        {showCopyModal && (
          <Modal
            onClose={() => setShowStatusChangeModal(!showCopyModal)}
            onOpen={() => setShowStatusChangeModal(!showCopyModal)}
            open={showCopyModal}
            dimmer='blurring'
            size='small'
          >
            <Modal.Header>
              Enter A New File Configuration Name
            </Modal.Header>
            <Modal.Content>
              <FliptInput
                name='file_config_name'
                placeholder='Enter File Name'
                value={copyRecordTitle}
                onChange={(e) => setCopyRecordTitle(e.target.value)}
              />
            </Modal.Content>
            <Modal.Actions>
              <FliptButton
                className="secondary"
                name="Cancel"
                onClick={() => setShowCopyModal(false)}
              />
              <FliptButton
                className="primary"
                name='Confirm'
                disabled={copyRecordTitle === ''}
                onClick={() => props.actions.copyFileConfiguration({ ...record, file_config_name: copyRecordTitle })}
              />
            </Modal.Actions>
          </Modal>
        )}

        {showStatusChangeModal.show && (
          <Modal
            onClose={() => setShowStatusChangeModal(!showStatusChangeModal)}
            onOpen={() => setShowStatusChangeModal(!showStatusChangeModal)}
            open={showStatusChangeModal}
            dimmer='blurring'
            size='small'
          >
            <Modal.Header>
              You are about to {showStatusChangeModal.action}{record?.file_config_name ? ` "${record?.file_config_name}". A` : ", a"}re you sure?
            </Modal.Header>
            <Modal.Actions>
              <FliptButton
                className="secondary"
                name="Cancel"
                onClick={() => setShowStatusChangeModal(!showStatusChangeModal)}
              />
              <FliptButton
                className="primary"
                name='Confirm'
                onClick={() => {
                  props.actions.updateFileConfiguration({ file_id: record.file_id, file_status: showStatusChangeModal.action })
                  setShowStatusChangeModal(false)
                }}
              />
            </Modal.Actions>

          </Modal>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  state: {
    app: state.app,
    dashboard: state.fileConfiguration.dashboard,
  },
});

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
    ...FileConfigurationActions,
    ...IntegrationManagementActions,
  };

  return {
    actions: bindActionCreators(allActions, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FileConfigurationDashboard);
