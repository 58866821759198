export const headers = [
  "drug_name",
  "gpi",
  "ndc",
  "gppc",
  "strengths",
  "multi_source",
  "brand_generic",
  "rebate_factor",
  "rebate_type"
];
