import {
    call,
    put, select, takeLatest, take,
} from 'redux-saga/effects'
import { fetchPost, fetchGet } from '../../../utils/fetchApi'
import { Types, Creators as cmsFormualryBuilderActions } from '../../reducers/api/cmsFormualryBuilder'
import { Creators as AppActions, Types as AppTypes } from '../../reducers/app'
import { getApiPath, getAppState, isMenuEmpty } from '../../reducers/selectors'
import { Creators as NavigationActions } from '../../reducers/navigation'

export default [
    getCmsFormularyDashboard,
    getGroupConfigData,
    saveCmsConfig,
    getDrugList,
    getPrAlternates,
    getPrExclusions
]

/* WATCHERS */
function* getCmsFormularyDashboard() {
    yield takeLatest(Types.GET_CMS_FORMULARY_DASHBOARD, getCmsFormularyDashboardHandler)
}
function* getGroupConfigData() {
    yield takeLatest(Types.GET_GROUP_CONFIG_DATA, getGroupConfigDataHandler)
}
function* saveCmsConfig() {
    yield takeLatest(Types.SAVE_CMS_CONFIG, saveCmsConfigHandler)
}
function* getDrugList() {
    yield takeLatest(Types.GET_DRUG_LIST, getDrugListHandler)
}

function* getPrAlternates() {
    yield takeLatest(Types.GET_DRUG_LIST, getPrAlternatesHandler)
}

function* getPrExclusions() {
    yield takeLatest(Types.GET_DRUG_LIST, getPrExclusionsHandler)
}

/* HANDLERS */
function* getCmsFormularyDashboardHandler({ payload }) {
    try {
        const empty = yield select(isMenuEmpty)
        if (empty) {
            yield take([AppTypes.SET_APP_SETTINGS])
        }

        const { serviceUrl } = yield select(getAppState)
        const cd = yield select(getApiPath, 'get-formulary-export-build')
        const { api_path } = cd
        const url = `${serviceUrl}${api_path}`
        const { data } = yield call(fetchPost, url, payload)

        if (!data?.length) {
            const transitionalPortal = {
                header: 'Data Not Found',
                copy: 'No Groups Found',
            }
            yield put(AppActions.displayTransitionalPortal(transitionalPortal))
            return
        }

        yield put(cmsFormualryBuilderActions.setDashboardData(data))
    } catch (err) {
        console.log('getCmsFormularyDashboardHandler Error >Data ', err)
        const transitionalPortal = {
            header: 'Getting CMS Dashboard Data Failed',
            copy: err,
        }
        yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    }
}

function* getGroupConfigDataHandler({ payload }) {
    try {
        const empty = yield select(isMenuEmpty)
        if (empty) {
            yield take([AppTypes.SET_APP_SETTINGS])
        }
        const { serviceUrl } = yield select(getAppState)
        const cd = yield select(getApiPath, 'get-pbp-contract-id')
        const { api_path } = cd
        const url = `${serviceUrl}${api_path}`
        const { data } = yield call(fetchPost, url, payload)
        if (!data?.length) {
            const transitionalPortal = {
                header: 'Data Not Found',
                copy: 'No Groups Config for Given Details',
            }
            yield put(AppActions.displayTransitionalPortal(transitionalPortal))
            return
        }
        yield put(cmsFormualryBuilderActions.setGroupConfigData(data[0]))
    } catch (err) {
        console.log('getGroupConfigDataHandler Error >Data ', err)
        const transitionalPortal = {
            header: 'Getting Group Config Data Failed',
            copy: err,
        }
        yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    }
}

function* getPrAlternatesHandler() {
    try {
        const empty = yield select(isMenuEmpty)
        if (empty) {
            yield take([AppTypes.SET_APP_SETTINGS])
        }
        const { serviceUrl } = yield select(getAppState)
        const cd = yield select(getApiPath, 'get-program-alternative-list')
        const { api_path } = cd
        const url = `${serviceUrl}${api_path}`
        const { data } = yield call(fetchGet, url)
        yield put(cmsFormualryBuilderActions.setPrAlternatesData(data))
    } catch (err) {
        console.log('getPrAlternatesHandler Error >Data ', err)
        const transitionalPortal = {
            header: 'Getting Program Alternate Options List Failed',
            copy: err,
        }
        yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    }
}

function* getPrExclusionsHandler() {
    try {
        const empty = yield select(isMenuEmpty)
        if (empty) {
            yield take([AppTypes.SET_APP_SETTINGS])
        }
        const { serviceUrl } = yield select(getAppState)
        const cd = yield select(getApiPath, 'get-program-exclusion-list')
        const { api_path } = cd
        const url = `${serviceUrl}${api_path}`
        const { data } = yield call(fetchGet, url)
        yield put(cmsFormualryBuilderActions.setPrExclusionsData(data))
    } catch (err) {
        console.log('getPrExclusionsHandler Error >Data ', err)
        const transitionalPortal = {
            header: 'Getting Program Exclusions Options List Failed',
            copy: err,
        }
        yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    }
}
function* getDrugListHandler({ payload }) {
    try {
        const empty = yield select(isMenuEmpty)
        if (empty) {
            yield take([AppTypes.SET_APP_SETTINGS])
        }
        const { serviceUrl } = yield select(getAppState)
        const cd = yield select(getApiPath, 'get-drug-list')
        const { api_path } = cd
        const url = `${serviceUrl}${api_path}`
        const { data } = yield call(fetchPost, url, payload)
        yield put(cmsFormualryBuilderActions.setDrugListData(data))
    } catch (err) {
        console.log('getDrugListHandler Error >Data ', err)
        const transitionalPortal = {
            header: 'Getting Drug List Failed',
            copy: err,
        }
        yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    }
}

function* saveCmsConfigHandler({ payload }) {
    try {
        const empty = yield select(isMenuEmpty)
        if (empty) {
            yield take([AppTypes.SET_APP_SETTINGS])
        }
        const { serviceUrl } = yield select(getAppState)
        const cd = yield select(getApiPath, 'create-formulary-export-builder')
        const { api_path } = cd
        const url = `${serviceUrl}${api_path}`
        const { data } = yield call(fetchPost, url, payload)
        yield put(cmsFormualryBuilderActions.setSaveConfigData(data))
        yield put(NavigationActions.navigateTo({ pathname: '/get-formulary-export-build' }))

    } catch (err) {
        console.log('saveCmsConfigHandler Error >Data ', err)
        const transitionalPortal = {
            header: 'Data Not Saved',
            copy: err,
        }
        yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    }
}


export {
    getCmsFormularyDashboardHandler,
    getGroupConfigDataHandler,
    getDrugListHandler,
    saveCmsConfigHandler
}