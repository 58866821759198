import React, { useState, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import _ from 'lodash'

import { Creators as AppActions } from '../../../../redux/reducers/app'
import { Creators as PlanManagementActions } from '../../../../redux/reducers/api/planManagement'
import FliptHierarchyDropdown from '../../../../components/form/fliptHierarchyDropdown'
import FliptDropdown from '../../../../components/v2/fliptDropdown'

const Hierarchy = (props) => {
	const { state: { hierarchyData }, copyFormData, fieldDetails: fields = [], editMode } = props
	const [form, setFormData] = useState({})
	const {
		hierarchy = [],
		hierarchyIDs = false,
		hideHierarchy = false,
	} = form
	const { actions } = props

	const updateFormData = (newForm) => {
		const formNew = { ...form, ...newForm }
		setFormData(formNew)
		actions.setHierarchyData({ data: { ...newForm } })
	}
	const updateFormDataField = (el, dropdown) => {
		const formNew = { ...form }
		const { name, value } = dropdown || el.currentTarget
		formNew[name] = value
		setFormData(formNew)
		actions.setHierarchyData({ data: { ...formNew } })

	}
	const yesNoOptions = [{
		key: 0,
		text: 'Yes',
		value: true
	},
	{
		key: 1,
		text: 'No',
		value: false
	}]

	useEffect(() => {
		let data = {}
		if (!_.isEmpty(hierarchyData?.hierarchy) && !_.isEqual(hierarchyData?.hierarchy, hierarchy)) {
			data.hierarchy = hierarchyData?.hierarchy
		}
		if (!_.isEmpty(hierarchyData?.hierarchyIDs) && !_.isEqual(hierarchyData?.hierarchyIDs, hierarchyIDs)) {
			data.hierarchyIDs = hierarchyData?.hierarchyIDs
		}
		if (!_.isEmpty(hierarchyData?.hideHierarchy) && !_.isEqual(hierarchyData?.hideHierarchy, hideHierarchy)) {
			data.hideHierarchy = hierarchyData?.hideHierarchy
		}
		if (!_.isEmpty(data)) {
			updateFormData(data)
		}
	}, [hierarchyData])

	return (
		<div id="Hierarchy">
			<FliptHierarchyDropdown editMode={editMode} setForm={updateFormData} form={form} />
		</div>
	)
}

const mapStateToProps = (state) => ({
	state: {
		app: state.app,
		hierarchyData: state.planManagement.hierarchyData,
		planDesignData: state.planManagement.planDesignData,
	}
})

const mapDispatchToProps = (dispatch) => {
	const allActions = {
		...AppActions,
		...PlanManagementActions,
	}

	return {
		actions: bindActionCreators(allActions, dispatch)
	}
}

const hierarchyContainer = (props) => (
	<Hierarchy
		editMode
		{...props}
	/>
)

export default Hierarchy

export const HierarchyContainer = connect(mapStateToProps, mapDispatchToProps)(hierarchyContainer)