import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Accordion, Icon } from 'semantic-ui-react'

import './styles.scss'
import Config from '../../../../../config'
import BreadCrumb from '../../../../../components/breadCrumb'
import FliptGrid from '../../../../../components/fliptGrid'
import FliptButton from '../../../../../components/form/fliptButton'
import FliptDropdown from '../../../../../components/form/fliptDropdown'
import FliptInput from '../../../../../components/form/fliptInput'
import { InputTextEditor, DropdownEditor } from '../../../../../components/fliptGrid/cellEditors'
import { Creators as MedicareSetupActions } from '../../../../../redux/reducers/api/medicareSetup'
import { filterRowData, convertStrToDateObj } from '../../../../../utils/utilities'
import FliptDatePicker from '../../../../../components/form/fliptDatePicker'
import initialData from '../medicarePlanDetails/data'
import { validateString } from '../../../../../utils/validationHelpers'
import { parseQueryString, convertStringToSnakeCase } from '../../../../../utils/utilities'
import _ from 'lodash'
import rowCellInfo from './rowCellInfo'

class CreateLicsSetp extends Component {
  constructor(props) {
    super(props)
    this.state = {
      form: {
        doc_name: '',
        doc_version: '1.0',
        plan_year: '',
        effective_start_date: '',
      },
      showTabs: false,
      activeIndex: 0,
      medicareData: _.cloneDeep(initialData)
    }
  }

  componentWillUnmount() {
    const { actions } = this.props
    actions.clearMedicareSetupReducer()
  }

  _handleAccordianClick = (e, titleProps) => {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index

    this.setState({ activeIndex: newIndex })
  }

  _handleChange = (from, el, dropdown, rowIndex, gridApi) => {
    const { state: { medicareData } } = this
    const updatedMedicareData = { ...medicareData }
    const phaseToUpdate = updatedMedicareData.phases[from - 1]
    const rowToUpdate = phaseToUpdate.lics_level[rowIndex]
    const { name, value } = dropdown || el.currentTarget
    rowToUpdate[name] = value || null
    gridApi.refreshCells()
  }

  _updateFields = (el, dropdown) => {
    const { form } = this.state
    const { name, value } = dropdown || el.currentTarget

    form[name] = value

    if (name === 'plan_year') form['effective_start_date'] = new Date(value, 0, 1)

    this.setState({ ...this.state, form, })
  }

  _submitParams = () => {
    const { state } = this
    const { props: { state: { medicareSetupData } } } = this
    const { actions } = this.props
    let validateFlag = true;

    for (let i = 0; i < medicareSetupData.length; i++) {
      if (state.form.plan_year.length === 4 && medicareSetupData[i].plan_year === state.form.plan_year) {
        validateFlag = false;
        break;
      }
    }

    if (validateFlag) {
      this.setState({ ...state, showTabs: true });
    } else {
      const msg = 'Medicare LICS already defined for ' + state.form.plan_year + ' Plan Year.';
      actions.getMedicareSetupYears({ "data": msg })
    }
  }

  _saveMedicarePlan = () => {
    const { state: { medicareData, form }, props: { actions } } = this
    const payload = {
      phases: medicareData.phases,
      ...form,
    }

    let validateCount = 0;
    for (let i = 0; i < payload.phases.length; i++) {
      for (let j = 0; j < payload.phases[i].lics_level.length; j++) {
        let temp = payload.phases[i].lics_level[j];
        if (temp['lics_brand_cost_share'] >= 0 && temp['lics_brand_cost_share_type'].length > 1 && temp['lics_generic_cost_share'] >= 0 && temp['lics_generic_cost_share_type'].length > 1) {
          validateCount += 1;
          break;
        }
      }
    }
    if (validateCount === 4) {
      actions.saveMedicareSetupDetailData(payload);
      this.props.history.push({
        pathname: "/medicare-lics-setup",
      });
    } else {
      const msg = 'Brand and Generic cost share and Type must be defined for all benefit phases. Please define for all phases prior to saving';
      actions.getMedicareSetupYears({ "data": msg })
    }
  }

  render() {
    const { props } = this
    const {
      form,
      showTabs,
      activeIndex,
      medicareData,
    } = this.state
    const { location: { state } } = props
    const headers = [
      'lics_level', 'lics_deductible',
    ]
    const costSharePhases = ['Deductible', 'Initial Coverage', 'Coverage Gap', 'Catastrophic']
    const costShareHeaders = [
      'lics_level', 'lics_brand_cost_share', 'lics_brand_cost_share_type', 'lics_minimum_brand_cost_share', 'lics_maximum_brand_cost_share', 'lics_generic_cost_share',
      'lics_generic_cost_share_type', 'lics_minimum_generic_cost_share', 'lics_maximum_generic_cost_share',
    ]

    const cellEditorParamsDeductible = {
      lics_deductible: {
        cellEditor: InputTextEditor,
        onChange: this._handleChange,
      },
    }

    const cellEditorParams = {
      lics_brand_cost_share: {
        cellEditor: InputTextEditor,
        onChange: this._handleChange,
      },
      lics_brand_cost_share_type: {
        cellEditor: DropdownEditor,
        onChange: this._handleChange,
      },
      lics_minimum_brand_cost_share: {
        cellEditor: InputTextEditor,
        onChange: this._handleChange,
      },
      lics_maximum_brand_cost_share: {
        cellEditor: InputTextEditor,
        onChange: this._handleChange,
      },
      lics_generic_cost_share: {
        cellEditor: InputTextEditor,
        onChange: this._handleChange,
      },
      lics_generic_cost_share_type: {
        cellEditor: DropdownEditor,
        onChange: this._handleChange,
      },
      lics_minimum_generic_cost_share: {
        cellEditor: InputTextEditor,
        onChange: this._handleChange,
      },
      lics_maximum_generic_cost_share: {
        cellEditor: InputTextEditor,
        onChange: this._handleChange,
      },
    }

    const cellRendererParams = {
      lics_level: {
        width: 425,
      },
      lics_deductible: {
        width: 425,
      },
    }

    const dropdownOptions = {}
    const rowData = {
      lics_brand_cost_share_type: ['Percentage', 'Fixed'],
      lics_generic_cost_share_type: ['Percentage', 'Fixed'],
    }
    costSharePhases.forEach((phase, idx) => {
      const key = convertStringToSnakeCase(phase.toLowerCase())
      const options = medicareData?.phases[idx]?.lics_level.map(() => {
        const data = {}
        Object.keys(rowData).forEach((col) => {
          data[col] = rowData[col].map((group, index) => ({
            key: index,
            text: group,
            value: group,
          }))
        })
        return data
      })
      dropdownOptions[key] = options
    })

    return (
      <div id="create-medicare-plan" className="create-medicare-plan">
        <BreadCrumb {...this.props} />
        <div className="header">
          <h1> Create New Medicare D LICS Setup </h1>
        </div>
        <div className="subhead">
          <section className="prescriber-section">
            <div className="prescriber-input">
              <span className="input-label">Medicare Plan Name</span>
              <FliptInput placeholder="Name" value={form.doc_name} name="doc_name" onChange={this._updateFields} required />
            </div>
            <div className="prescriber-input">
              <span className="input-label">Version</span>
              <FliptInput placeholder="Vesion" value={form.doc_version} name="doc_version" onChange={this._updateFields} disabled required />
            </div>
            <div className="prescriber-input">
              <span className="input-label">Plan Year</span>
              <FliptInput placeholder="YYYY" value={form.plan_year} name="plan_year" onChange={this._updateFields} validation='numeric' required />
            </div>
            <div className="prescriber-input">
              <span className="input-label">Start Date</span>
              <FliptDatePicker className="create-plan-start-date" name="effective_start_date" value={convertStrToDateObj(form.effective_start_date, { format: 'YYYY-MM-DD', toISO: true })} />
            </div>
          </section>
        </div>
        {showTabs ? (
          <div id="medicareSetup" className="medicare-setup">
            {(medicareData && medicareData?.phases && medicareData?.phases?.length) && (
              <>
                <div className="content">
                  <FliptGrid
                    headers={headers}
                    data={medicareData.phases?.find((it) => it.phase === 'Deductible')?.lics_level?.map(({ lics_deductible, lics_level }) => ({
                      lics_deductible, lics_level,
                    }))?.map((d) => ({
                      ...filterRowData(d, headers),
                    }))}
                    cellEditorParams={cellEditorParamsDeductible}
                    cellRendererParams={cellRendererParams}
                    from={medicareData.phases?.findIndex((it) => it.phase === 'Deductible') + 1}
                  />
                </div>
                <div className="content_acc">
                  {
                    costSharePhases.map((item, idx) => (
                      <div key={idx} className="phaseWrapper">
                        <Accordion fluid styled>
                          <div className="mc_condition_level">
                            <Accordion.Title
                              active={activeIndex === idx}
                              index={idx}
                              onClick={this._handleAccordianClick}
                            >
                              {item}
                              <Icon name="dropdown" className="fl_right__icon" />
                            </Accordion.Title>
                            <Accordion.Content active={activeIndex === idx}>
                              <div className="mc_condition">
                                <FliptGrid
                                  headers={costShareHeaders}
                                  data={medicareData?.phases?.find((it) => it.phase === item)?.lics_level?.map((d) => ({
                                    ...filterRowData(d, costShareHeaders),
                                  }))}
                                  rowCellInfo={rowCellInfo}
                                  dropdownOptions={dropdownOptions[convertStringToSnakeCase(item.toLowerCase())]}
                                  cellEditorParams={cellEditorParams}
                                  from={idx + 1}
                                />
                              </div>
                            </Accordion.Content>
                          </div>
                        </Accordion>
                      </div>
                    ))
                  }
                </div>
                <div className="buttonContainer">
                  <FliptButton name="Save" className="primary searchButton" onClick={this._saveMedicarePlan} />
                </div>
              </>
            )}
          </div>
        ) : (
          <div className="prescriber-input prescriber-btn">
            <FliptButton name="Create Medicare Plan" className="primary searchButton" onClick={this._submitParams} />
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  state: {
    activePlan: state.medicareSetup.activePlan,
    medicareSetupData: state.medicareSetup.medicareSetupData,
    medicareSetupDetailData: state.medicareSetup.medicareSetupDetailData,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...MedicareSetupActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateLicsSetp)