import React, { PureComponent, useEffect, useState } from "react";
import { Accordion } from "semantic-ui-react";
import { NavLink } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Creators as AppActions } from "../../../redux/reducers/app";
import "./styles.scss";
import SidebarMenu from "./sidebarMenu";
import SidebarExtended from "./sidebarExtended";

const SideNavigation = (props) => {
  const [subMenuList, setSubMenuList] = useState({});
  const [activeSubMenuIndex, setActiveSubMenuIndex] = useState("");
  const {
    state: {
      app: { activeIndex, sidebarIsOpen },
    },
  } = props;
  const { state } = props;
  const {app: {menu_data}} = state;
  
  const {
    actions: { toggleSidebar },
  } = props;

  const _handleSubMenuClick = (index) => {
    const newIndex = activeSubMenuIndex === index ? -1 : index;
    setActiveSubMenuIndex(newIndex);
  };

  const goToSubMenu = (item, index) => {
    const {
      actions,
      state: {
        app: { activeIndex },
      },
    } = props;

    const newIndex = activeIndex === index ? -1 : index;

    actions.setActiveIndex(index);
    setSubMenuList(item);
    setActiveSubMenuIndex(0);

  };


  return (
    <div
      className="leftNavigation"
      style={{ width: sidebarIsOpen ? "321px" : "81px" }}
    >
      {!sidebarIsOpen?<div>
        <SidebarMenu
          menuList={menu_data}
          goToSubMenu={goToSubMenu}
          activeIndex={activeIndex}
        />     
      </div>:
      <div>
      <SidebarExtended
          menuList={menu_data}
          goToSubMenu={goToSubMenu}
          activeIndex={activeIndex}
        />  
      </div>}
    </div>
  );
};

const mapStateToProps = (state) => ({
  state: {
    app: state.app,
  },
});

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
  };

  return {
    actions: bindActionCreators(allActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SideNavigation);