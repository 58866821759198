import Immutable from 'seamless-immutable'
import { createActions, createReducer } from 'reduxsauce'

import { convertDateTimeToDate } from '../../../utils/utilities'

export const INITIAL_STATE = Immutable({
  allApprovedTiersData: [],
  allFormulariesData: [],
  allFormularyTiersData: [],
  drugsPerPage: 20,
  formularyData: {},
  formularyDrugClassData: [],
  formularyDrugSubClassData: [],
  formularyTierCondData: [],
  formularyTierData: {},
  formularyTierDrugData: [],
  formularyTierExportDrugData: [],
  formularyTierDrugDemographicData: {},
  formularyTierDrugFiltersData: {},
  formularyTierDrugsView: [],
  formularyTierId: '',
  generatedFormularyData: [],
  saveApprovedTiersData: [],
  importDrugData: {},
  headerFieldMappingData: [],
  summaryImportFileData: [],
  frfSearchData: [],
  uspSearchData: []
})
export const clearFormularyTierReducer = () => INITIAL_STATE

export const clearFormularyInputReducer = (state = INITIAL_STATE) => ({
  ...state,
  importDrugData: {},
  headerFieldMappingData: [],
  summaryImportFileData: [],
})

export const clearFormularyTierDrugsViewReducer = (state = INITIAL_STATE) => ({
  ...state,
  formularyTierDrugsView: [],
})

export const setAllFormularyTiersDataReducer = (state = INITIAL_STATE, { payload }) => {
  const d = payload?.data.map((row) => ({
    ...row,
    effective_end_date: convertDateTimeToDate(row.effective_end_date),
    effective_start_date: convertDateTimeToDate(row.effective_start_date),
  }))
  return {
    ...state,
    allFormularyTiersData: d,
  }
}

export const setFormularyDrugClassDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  formularyDrugClassData: payload.data,
})

export const clearFormularyDrugClassDataReducer = (state = INITIAL_STATE) => ({
  ...state,
  formularyDrugClassData: [],
})

export const setFormularyDrugSubClassDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  formularyDrugSubClassData: payload.data,
})

export const clearFormularyDrugSubClassDataReducer = (state = INITIAL_STATE) => ({
  ...state,
  formularyDrugSubClassData: [],
})


export const setFormularyTierDrugDemographicDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  formularyTierDrugDemographicData: payload,
})

export const setFormularyTierDrugFiltersDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  formularyTierDrugFiltersData: payload,
})

// export const setFormularyTierDrugDataReducer = (state = INITIAL_STATE, { payload }) => ({
//   ...state,
//   formularyTierDrugData: payload.drugs,
//   formularyTierDrugFiltersData: payload.drugFilters,
// })

export const setFormularyTierDrugExportAllDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  formularyTierDrugData: payload.drugs,
  formularyTierDrugFiltersData: payload.drugFilters,
})

export const clearFormularyTierDrugDataReducer = (state = INITIAL_STATE) => ({
  ...state,
  formularyTierDrugData: [],
})

export const setFormularyTierDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  formularyTierCondData: payload.tier_conditions,
  formularyTierData: payload.tier_info,
})

export const clearFormularyTierDataReducer = (state = INITIAL_STATE) => ({
  ...state,
  formularyTierCondData: [],
  formularyTierData: {},
})

export const clearFormularyReducer = () => INITIAL_STATE

export const setAllFormulariesDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  allFormulariesData: payload,
})

export const setAllApprovedTiersDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  allApprovedTiersData: payload,
})

export const saveApprovedTiersDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  saveApprovedTiersData: payload.data,
})

export const setGeneratedFormularyDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  generatedFormularyData: payload,
})

export const setFormularyDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  formularyData: payload,
})

export const setFormularyTierDrugsViewDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  formularyTierDrugsView: payload,
})

export const setFormularyTierIdReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  formularyTierId: payload,
})

export const setImportFileDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  importDrugData: payload,
})

export const setFrfSearchDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  frfSearchData: payload,
})

export const setUspSearchDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  uspSearchData: payload,
})

export const setHeaderFieldMappingDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  headerFieldMappingData: payload.data[0],
})

export const setSummaryImportFieldDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  summaryImportFileData: payload.data,
})

export const { Types, Creators } = createActions({
  approveFormulary: ['payload'],
  clearFormulary: null,
  clearFormularyDrugClassData: null,
  clearFormularyDrugSubClassData: null,
  clearFormularyTier: null,
  clearFormularyTierData: null,
  clearFormularyTierDrugData: null,
  clearFormularyTierDrugsView: null,
  formularySendForReview: ['payload', 'data'],
  formularyTierDocumentLookup: ['payload'],
  generateFormularyData: ['payload'],
  generateFormularyTier: ['payload'],
  getAllApprovedTiersData: ['payload'],
  getAllFormulariesData: null,
  getAllFormularyTiersData: null,
  getDrugDemographicData: null,
  getFormularyData: ['payload'],
  getFormularyTierData: ['payload'],
  getFormularyTierDrugsViewData: ['payload'],
  saveFormularyData: ['payload'],
  saveFormularyTierData: ['payload'],
  setAllApprovedTiersData: ['payload'],
  setAllFormulariesData: ['payload'],
  setAllFormularyTiersData: ['payload'],
  setFormularyData: ['payload'],
  setFormularyDrugClassData: ['payload'],
  setFormularyDrugSubClassData: ['payload'],
  setFormularyTierData: ['payload'],
  // setFormularyTierDrugData: ['payload'],
  setFormularyTierDrugDemographicData: ['payload'],
  setFormularyTierDrugFiltersData: ['payload'],
  setFormularyTierDrugsViewData: ['payload'],
  setFormularyTierId: ['payload'],
  setGeneratedFormularyData: ['payload'],
  setImportFileData: ['payload'],
  setHeaderFieldMappingData: ['payload'],
  setSummaryImportFieldData: ['payload'],
  setFormularyTierDrugExportAllData: ['payload'],
  getDetailsFormularyImportFile: ['payload'],
  generateSmartRulesFormularyImportFile: ['payload'],
  getFormularyTierDrugsExportAll: ['payload', 'callback'],
  summaryFormularyImportFile: ['payload'],
  uploadFormularyImportData: ['payload'],
  saveHeaderMappingImportFile: ['payload', 'callback'],
  navigateToFormularyTier: ['payload'],
  frfSearch: ['payload'],
  setFrfSearchData: ['payload'],
  uspSearch: ['payload'],
  setUspSearchData: ['payload'],
  clearFormularyInput: null,
})

const HANDLERS = {
  [Types.CLEAR_FORMULARY]: clearFormularyReducer,
  [Types.CLEAR_FORMULARY_DRUG_CLASS_DATA]: clearFormularyDrugClassDataReducer,
  [Types.CLEAR_FORMULARY_DRUG_SUB_CLASS_DATA]: clearFormularyDrugSubClassDataReducer,
  [Types.CLEAR_FORMULARY_INPUT]: clearFormularyInputReducer,
  [Types.CLEAR_FORMULARY_TIER]: clearFormularyTierReducer,
  [Types.CLEAR_FORMULARY_TIER_DATA]: clearFormularyTierDataReducer,
  [Types.CLEAR_FORMULARY_TIER_DRUG_DATA]: clearFormularyTierDrugDataReducer,
  [Types.SET_ALL_APPROVED_TIERS_DATA]: setAllApprovedTiersDataReducer,
  [Types.SET_ALL_FORMULARIES_DATA]: setAllFormulariesDataReducer,
  [Types.SET_ALL_FORMULARY_TIERS_DATA]: setAllFormularyTiersDataReducer,
  [Types.SET_FORMULARY_DATA]: setFormularyDataReducer,
  [Types.SET_FORMULARY_DRUG_CLASS_DATA]: setFormularyDrugClassDataReducer,
  [Types.SET_FORMULARY_DRUG_SUB_CLASS_DATA]: setFormularyDrugSubClassDataReducer,
  [Types.SET_FORMULARY_TIER_DATA]: setFormularyTierDataReducer,
  [Types.SET_FORMULARY_TIER_DRUGS_VIEW_DATA]: setFormularyTierDrugsViewDataReducer,
  // [Types.SET_FORMULARY_TIER_DRUG_DATA]: setFormularyTierDrugDataReducer,
  [Types.SET_FORMULARY_TIER_DRUG_EXPORT_ALL_DATA]: setFormularyTierDrugExportAllDataReducer,
  [Types.SET_FORMULARY_TIER_DRUG_DEMOGRAPHIC_DATA]: setFormularyTierDrugDemographicDataReducer,
  [Types.SET_FORMULARY_TIER_DRUG_FILTERS_DATA]: setFormularyTierDrugFiltersDataReducer,
  [Types.SET_FORMULARY_TIER_ID]: setFormularyTierIdReducer,
  [Types.SET_GENERATED_FORMULARY_DATA]: setGeneratedFormularyDataReducer,
  [Types.SET_IMPORT_FILE_DATA]: setImportFileDataReducer,
  [Types.SET_HEADER_FIELD_MAPPING_DATA]: setHeaderFieldMappingDataReducer,
  [Types.SET_SUMMARY_IMPORT_FIELD_DATA]: setSummaryImportFieldDataReducer,
  [Types.SET_FRF_SEARCH_DATA]: setFrfSearchDataReducer,
  [Types.SET_USP_SEARCH_DATA]: setUspSearchDataReducer,
}

export default createReducer(INITIAL_STATE, HANDLERS)
