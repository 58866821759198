import { combineReducers } from 'redux'
import { resettableReducer } from 'reduxsauce'

// import gpiLookupReducer from './api/gpiLookup'
//import sharedCreationReducer from './api/shared'
import SSOLogin from './ssoLogin'
import accountManagementReducer from './api/accountManagement'
import appReducer from './app'
import auditManagement from './api/auditManagement'
import autoSuggest from './api/autoSuggest'
import bulkReprocessingRestackingReducer from './api/bulkReprocessingRestacking'
import claimErrorManagementReducer from './api/claimErrorManagement'
import claimHistoryReducer from './api/claimHistory'
import claimLookupManagementReducer from './api/claimLookupManagement'
import claimsProcessingAndReprocessing from './api/claimsProcessingAndReprocessing'
import clientConfiguration from './api/clientConfiguration'
import clientImplementationReducer from './api/clientImplementation'
import clinicalCreationReducer from './api/clinicalCreationManagement'
import cmsFormualryBuilder from './api/cmsFormualryBuilder'
import companyManagementReducer from './companyManagement'
import contractManagement from './api/contractManagement'
import drugLookupReducer from './api/drugLookup'
import drugManagement from './api/drugManagement'
import drugMappingLookup from './api/drugMappingLookup'
import drugPriceLookup from './api/drugPriceLookup'
import drugUtilizationReview from './api/drugUtilizationReview'
import emergencyOverride from './api/emergencyOverride';
import fileConfiguration from './api/fileConfiguration';
import formularyExportConfigurationReducer from './api/formularyExportConfiguration'
import formularyCreationReducer from './api/formularyCreationManagement'
import formularyExportLookupReducer from './api/formularyExportLookup'
import formularyLookupReducer from './api/formularyLookup'
import getPricing from './api/getPricing'
import globalEdits from './api/globalEdits'
import groupManagement from './api/groupManagement'
import integrationManagementReducer from './api/integrationManagement'
import manualClaimAdjustmentReducer from './api/manualClaimAdjustment'
import manualClaimEntryReducer from './api/manualClaimEntry'
import medicareSetupReducer from './api/medicareSetup'
import medispanLookup from './api/medispanLookup'
import memberLookupReducer from './api/memberLookup'
import navigationReducer from './navigation'
import networkCreationReducer from './api/networkCreationManagement'
import opioidManagement from './api/opioidManagement'
import paoverridereportLookupReducer from './api/paoverrideLookup'
import pharmacyLookupReducer from './api/pharmacyLookup'
import planManagementReducer from './api/planManagement'
import posRebateCreationReducer from './api/posRebate'
import prescriberLookupReducer from './api/prescriberLookup'
import prescriberManagementReducer from './api/prescriberManagement'
import priorAuthorizationReducer from './api/priorAuthorizationManagement'
import programListCreationReducer from './api/programListManagement'
import qualifiersReducer from './api/qualifiers'
import quantityLimitCreationReducer from './api/quantityLimitManagement'
import questionnaireBuilderReducer from './api/questionnaireBuilder'
import queueConfigReducer from './api/queueConfig'
import rebateManagement from './api/rebateManagement'
import roleManagementReducer from './roleManagement'
import rpmReducer from './rpm'
import rxreportLookupReducer from './api/rxreportLookup'
import sureScriptConfigReducer from './api/sureScriptConfig'
import teamManagementReducer from './teamManagement'
import testClaim from './api/testClaim'
import timerConfigReducer from './api/timerConfig'
import userManagementReducer from './userManagement'
import userReducer from './user'
import userServiceBrowserReducer from './api/userServiceBrowser'


// listen for the action type of 'RESET', you can change this.
const resettable = resettableReducer('RESET_ALL_REDUCERS')

export default () => combineReducers({
  // gpiLookup: resettable(gpiLookupReducer),
  accountManagement: resettable(accountManagementReducer),
  app: resettable(appReducer),
  auditManagement: resettable(auditManagement),
  autoSuggest: resettable(autoSuggest),
  bulkReprocessingRestacking: resettable(bulkReprocessingRestackingReducer),
  claimErrorLookup: resettable(claimErrorManagementReducer),
  formularyExportData: resettable(formularyExportLookupReducer),
  formularyExportConfiguration: resettable(formularyExportConfigurationReducer),
  claimHistory: resettable(claimHistoryReducer),
  claimLookup: resettable(claimLookupManagementReducer),
  claimsProcessingAndReprocessing: resettable(claimsProcessingAndReprocessing),
  clientConfiguration: resettable(clientConfiguration),
  clientImplementation: resettable(clientImplementationReducer),
  clinicalCreation: resettable(clinicalCreationReducer),
  cmsFormualry: resettable(cmsFormualryBuilder),
  companyManagement: resettable(companyManagementReducer),
  contractManagement: resettable(contractManagement),
  drugLookup: resettable(drugLookupReducer),
  drugManagement: resettable(drugManagement),
  drugManagement: resettable(drugManagement),
  drugMapLookup: resettable(drugMappingLookup),
  drugPriceLookup: resettable(drugPriceLookup),
  drugUtilizationReview: resettable(drugUtilizationReview),
  emergencyOverride: resettable(emergencyOverride),
  fileConfiguration: resettable(fileConfiguration),
  formularyCreation: resettable(formularyCreationReducer),
  //sharedCreation: resettable(sharedCreationReducer),
  formularyLookup: resettable(formularyLookupReducer),
  getPricing: resettable(getPricing),
  globalEdits: resettable(globalEdits),
  groupManagement: resettable(groupManagement),
  integrationMgmt: resettable(integrationManagementReducer),
  manualClaimAdjustment: resettable(manualClaimAdjustmentReducer),
  manualClaimEntry: resettable(manualClaimEntryReducer),
  medicareSetup: resettable(medicareSetupReducer),
  medispanLookup: resettable(medispanLookup),
  medispanLookup: resettable(medispanLookup),
  memberLookup: resettable(memberLookupReducer),
  navigation: navigationReducer,
  networkTierCreation: resettable(networkCreationReducer),
  opioidManagement: resettable(opioidManagement),
  paoverridereportLookup: resettable(paoverridereportLookupReducer),
  pharmacyLookup: resettable(pharmacyLookupReducer),
  planManagement: resettable(planManagementReducer),
  prescriberLookup: resettable(prescriberLookupReducer),
  prescriberManagement: resettable(prescriberManagementReducer),
  priorAuthorizationCreation: resettable(priorAuthorizationReducer),
  programListCreation: resettable(programListCreationReducer),
  qualifiers: resettable(qualifiersReducer),
  quantityLimitCreation: resettable(quantityLimitCreationReducer),
  questionnaireBuilder: resettable(questionnaireBuilderReducer),
  questionnaireBuilder: resettable(questionnaireBuilderReducer),
  queueConfig: resettable(queueConfigReducer),
  rebateCreation: resettable(posRebateCreationReducer),
  rebateManagement: resettable(rebateManagement),
  roleManagement: resettable(roleManagementReducer),
  rpm: resettable(rpmReducer),
  rxreportLookup: resettable(rxreportLookupReducer),
  ssoLogin: resettable(SSOLogin),
  surescriptconfig: resettable(sureScriptConfigReducer),
  teamManagement: resettable(teamManagementReducer),
  testClaim: resettable(testClaim),
  testClaim: resettable(testClaim),
  timerConfig: resettable(timerConfigReducer),
  user: resettable(userReducer),
  userManagement: resettable(userManagementReducer),
  userServiceBrowser: resettable(userServiceBrowserReducer),
})
