import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Creators as ManualClaimEntryActions } from '../../../../redux/reducers/api/manualClaimEntry';
import { Creators as DrugLookupActions } from '../../../../redux/reducers/api/drugLookup';
import { Creators as AutoSuggestActions } from '../../../../redux/reducers/api/autoSuggest';
import { Creators as QuestionnaireBuilderActions } from '../../../../redux/reducers/api/questionnaireBuilder';
import SubmenuNav from '../shared/navigation';
import './styles.scss';
import BreadCrumb from '../../../../components/breadCrumb';
import { Creators as AppActions } from '../../../../redux/reducers/app';
import BasicInformation from './components/BasicInformation';

const CreateQuestionnaireRequest = (props) => {
  const [state, setState] = useState({
    questionnaireData: {
        questionnaire_type: 'Global',
        questionnaire_name: '',
        questionnaire_policy: '',
        questionnaire_description: '',
        effective_date: '',
        term_date: '',
        question_set_contact_communication_number: '',
    },
    memberList: [],
    loading: false,
  });

  const _updateFields = (el, dropdown) => {
    const { questionnaireData } = state;
    const { name, value } = dropdown || el.currentTarget;
    questionnaireData[name] = value;
    setState((prevState) => ({
      ...prevState,
      questionnaireData,
    }));
  };

  const _createRequest = () => {
    const { questionnaireData, customDrugData } = state;
    if (!customDrugData?.length) {
      const header = 'Fields Missing';
      const message = 'Please Select a Drug to Proceed';
      _showTransitionalPortal(header, message);
      return;
    }
    const params = {
      ...questionnaireData,
      drug_list: customDrugData,
    };
    props.actions.createQuestionnaire(params);
  };

  const _showTransitionalPortal = (header, message) => {
    const { actions } = props;
    const transitionalPortal = {
      header,
      copy: message,
    };
    actions.displayTransitionalPortal(transitionalPortal);
  };
  const { searchForm, drugDataList, minCharacters, resultValues, loading, questionnaireData } = state;

  return (
    <div id="CreateQuestionnaire">
      <BreadCrumb {...props} />
      <div className="submenu">
        <SubmenuNav active="createQuestionnaire" />
      </div>
      <div className="header">Create New Questionnaire</div>
      <BasicInformation
        minCharacters={minCharacters}
        resultValues={resultValues}
        loading={loading}
        questionnaireData={questionnaireData}
        updateFields={_updateFields}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  state: {
    app: state.app,
    drugLookup: state.drugLookup,
    autoSuggestData: state.autoSuggest.autoSuggestData,
    manualClaimEntry: state.manualClaimEntry,
    createQuestionnaireData: state.questionnaireBuilder.createQuestionnaireData,
  },
});

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
    ...AutoSuggestActions,
    ...DrugLookupActions,
    ...ManualClaimEntryActions,
    ...QuestionnaireBuilderActions,
  };

  return {
    actions: bindActionCreators(allActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateQuestionnaireRequest);
