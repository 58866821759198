import React from 'react'

import FliptInput from '../../../../../components/form/fliptInput'

import { removeLeadingZeros } from '../../../../../utils/utilities'

export default ({ data }) => {
  const {
    auth_id,
    bin_number,
    date_of_service,
    erx_rejection_reason,
    fill_number,
    prescription_reference_number,
    processor_control_number,
    product_selection_code,
    sequenceNumber,
    transactionId,
    transaction_response_status,
    date_prescription_written,
    compound_code,
    other_coverage_code,
    submission_clarification_code,
    quantity_dispensed,
    group_id,
    days_supply,
  } = data

  return (
    <div className="section">
      <h2>Claim</h2>
      <div className="fields-container">
        <div className="fields">
          <FliptInput value={auth_id || ''} label="Auth Number" stylized />
          <FliptInput value={bin_number || ''} label="Bin" stylized />
          <FliptInput value={prescription_reference_number || ''} label="Rx No" stylized />
          <FliptInput value={fill_number || ''} label="Fill #" stylized />
          <FliptInput value={product_selection_code || ''} label="DAW" stylized />
          <FliptInput value={compound_code || ''} label="Compound code" stylized />
        </div>
        <div className="fields">
          <FliptInput value={sequenceNumber || ''} label="Sequence Number" stylized />
          <FliptInput value={group_id || ''} label="Group" stylized />
          <FliptInput value={date_prescription_written || ''} label="Date Written" stylized />
          <FliptInput value={quantity_dispensed || ''} label="Submitted Quantity" stylized />
          <FliptInput value={submission_clarification_code || ''} label="Submission Clarification Code" stylized />
          <FliptInput value={transactionId || ''} label="eRx Transaction ID" stylized />
        </div>
        <div className="fields">
          <FliptInput value={transaction_response_status || ''} label="Status" stylized />
          <FliptInput value={processor_control_number || ''} label="PCN" stylized />
          <FliptInput value={date_of_service || ''} label="Date of Service" stylized />
          <FliptInput value={removeLeadingZeros(days_supply) || ''} label="Days Supply" stylized />
          <FliptInput value={other_coverage_code || ''} label="Other Coverage Code" stylized />
          <FliptInput value={erx_rejection_reason || ''} label="eRx Rejection Reason" stylized />
        </div>
      </div>
    </div>
  )
}
