export const IntegrationMgmtStatusTabConfig = [{
    tabName: 'Success',
    id: 'COMPLETED'
},
{
    id: 'STARTED',
    tabName: 'Started'
},
{
    tabName: 'Failed',
    id: 'ERROR'
},
{
    tabName: 'Hold (Manual)',
    id: 'HOLD'
},
{
    id: 'HOLD_THRESHOLD',
    tabName: 'Hold (Threshold)'
},
{
    tabName: 'Queued',
    id: 'IN_STAGE'
},
]

export const INTEGRATION_MGMT_STATUS_LOCAL_STORAGE_KEY = 'INTEGRATION_MGMT_STATUS_LOCAL_STORAGE_KEY'

export const headers = [
    'file_config_name',
    'client',
    'triggered_time',
    'batch_start_time',
    'batch_end_time',
    'total_records_received',
    'total_warning',
    'total_adds',
    'total_changes',
    'total_terms',
    'total_rejects'
]

export const cellRendererParams = {
    file_config_name: {
        overrideHeader: 'File Name'
    },
    batch_start_time: {
        overrideHeader: 'Process Start Date/Time'
    },
    batch_end_time: {
        overrideHeader: 'Process End Date/Time'
    },
}