import {
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects'

import { Types, Creators as PharmacyLookupActions } from '../../reducers/api/pharmacyLookup'
import { Creators as AppActions } from '../../reducers/app'
import {
  getAppState, getApiPath, getPharmacyLookup, getHistory, getUserState,
} from '../../reducers/selectors'
import { fetchGet, fetchPost } from '../../../utils/fetchApi'
import { createQueryString, filterRowData } from '../../../utils/utilities'

export default [
  getPharmacyLookupDataWatcher,
  getPharmacySuggestDataWatcher,
  getPharmacyNpiLookupDataWatcher
]

/* WATCHERS */
function* getPharmacyLookupDataWatcher() {
  yield takeLatest(Types.GET_PHARMACY_LOOKUP_DATA, getPharmacyLookupDataHandler)
}

function* getPharmacyNpiLookupDataWatcher() {
  yield takeLatest(Types.GET_PHARMACY_NPI_LOOKUP_DATA, getPharmacyNpiLookupDataHandler)
}

function* getPharmacySuggestDataWatcher() {
  yield takeLatest(Types.GET_PHARMACY_SUGGEST_DATA, getPharmacySuggestDataHandler)
}

/* HANDLERS */
function* getPharmacyLookupDataHandler({ payload }) {
  try {
    const {
      form, params, headers, headerMapping, isTestClaim, getTotalRows
    } = payload
    const { serviceUrl } = yield select(getAppState)
    const history = yield select(getHistory)
    const { pharmacy_limitation: pharmacyLimitation } = yield select(getUserState)

    const { pharmaciesPerPage } = yield select(getPharmacyLookup)
    history.location.search = createQueryString({
      ...form,
      offset: params?.request.startRow,
    })
    const qs = history.location.search ? `${history.location.search}&limit=${pharmaciesPerPage}` : ''
    const requiredFields = ['pharmacycity', 'pharmacynpi', 'pharmacystate', 'pharmacyzip1', 'pharmacyname']
    if (!requiredFields.some((field) => form[field]) && !isTestClaim) {
      params?.success({
        rowData: [],
        rowCount: 0,
      })
    } else {
      const { api_path } = yield select(getApiPath, 'pharmacy-lookup')
      const url = `${serviceUrl}${api_path}${qs}`
      const reqData = {
        sortModel: params?.request.sortModel,
        filterModel: params?.request.filterModel,
        getTotalRows,
        pharmacyLimitation,
      }
      const { data, total_rows } = yield call(fetchPost, url, reqData)
      const gridData = !isTestClaim && data?.map((d) => ({
        ...filterRowData(d, headers, headerMapping), pharmacyzip1: d?.pharmacyzip1?.substring(0, 5)
      }))

      params?.success({
        rowData: gridData,
        rowCount: parseInt(total_rows, 10),
      })
      if (!data.length && total_rows < 1) {
        const transitionalPortal = {
          header: 'Pharmacy Lookup Error',
          copy: 'Error - We are unable to find a pharmacy that matches the search criteria',
        }
        yield put(AppActions.displayTransitionalPortal(transitionalPortal))
        yield put(PharmacyLookupActions.setPharmacyLookupFailed(true))
        return
      }
      if (isTestClaim || getTotalRows) {
        yield put(PharmacyLookupActions.setPharmacyLookupData({ data, totalRows: total_rows }))
      }
    }
  } catch (err) {
    console.log('getPharmacyLookupDataHandler Error >Data ', err)

    const transitionalPortal = {
      header: 'Pharmacy Lookup Failed',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* getPharmacyNpiLookupDataHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const cd = yield select(getApiPath, 'contract-discount-lookup')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`
    const { data } = yield call(fetchPost, url, payload)
    if (!data?.length) {
      const transitionalPortal = {
        header: 'Data Fetch failed',
        copy: 'No Data found for given NPI',
      }
      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
      return
    }
    yield put(PharmacyLookupActions.setPharmacyNpiLookupData(data[0]))
  } catch (err) {
    console.log('getPharmacyNpiLookupDataHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Pharmacy Npi Lookup Failed',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}



function* getPharmacySuggestDataHandler() {
  try {
    yield put(PharmacyLookupActions.setLoading({ data: true }))
    const { serviceUrl } = yield select(getAppState)
    const history = yield select(getHistory)

    const { pharmaciesPerPage } = yield select(getPharmacyLookup)
    const qs = history.location.search ? `${history.location.search}&limit=${pharmaciesPerPage}` : ''

    if (!qs) return

    const cd = yield select(getApiPath, 'pharmacy-lookup')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}${qs}`
    const { data } = yield call(fetchGet, url, {}, false)
    if (!data.length) throw Error('No Pharmacies match that name')

    yield put(PharmacyLookupActions.setPharmacySuggestData({ data }))
    yield put(PharmacyLookupActions.setLoading({ data: false }))
  } catch (err) {
    console.log('getPharmacySuggestDataHandler Error >Data ', err)

    const transitionalPortal = {
      header: 'Pharmacy Lookup Failed',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    yield put(PharmacyLookupActions.setLoading({ data: false }))
  }
}
