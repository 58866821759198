import {
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects'
import { v4 as uuidv4 } from 'uuid'

import { Creators as AppActions } from '../../reducers/app'
import { Creators as NavigationActions } from '../../reducers/navigation'
import { Creators as RPMActions } from '../../reducers/rpm'
import { Types, Creators as QuantityLimitCreationActions } from '../../reducers/api/quantityLimitManagement'
import { fetchPost } from '../../../utils/fetchApi'
import { getAppState, getApiPath, getHistory, getUserState } from '../../reducers/selectors'
import { updateCriteriaIdsHandler } from './qualifiers'
import { Creators as UserActions } from '../../reducers/user'
import SystemErrorModal from '../../../utils/systemError'
import * as ApprovalsConstants from '../rpm/constants'
import { deleteUnnecessaryFields } from '../../../utils/utilizationManagement'
import { convertDateToStrObj, filterRowData } from '../../../utils/utilities'

export default [
  approveQuantityLimitWatcher,
  generateQuantityLimitWatcher,
  getQuantityLimitDataWatcher,
  getallQuantityLimitDataWatcher,
  saveQuantityLimitDataWatcher,
  generateQuantityLimitLevelExportWatcher
]

/* WATCHERS */
function* getallQuantityLimitDataWatcher() {
  yield takeLatest(Types.GET_ALL_QUANTITY_LIMIT_DATA, getAllQuantityLimitDataHandler)
}

function* generateQuantityLimitWatcher() {
  yield takeLatest(Types.GENERATE_QUANTITY_LIMIT, generateQuantityLimitHandler)
}

function* generateQuantityLimitLevelExportWatcher() {
  yield takeLatest(Types.GENERATE_QUANTITY_LIMIT_LEVEL_EXPORT, generateQuantityLimitLevelExportHandler)
}


function* saveQuantityLimitDataWatcher() {
  yield takeLatest(Types.SAVE_QUANTITY_LIMIT_DATA, saveQuantityLimitDataHandler)
}

function* getQuantityLimitDataWatcher() {
  yield takeLatest(Types.GET_QUANTITY_LIMIT_DATA, getQuantityLimitDataHandler)
}

function* approveQuantityLimitWatcher() {
  yield takeLatest(Types.APPROVE_QUANTITY_LIMIT, approveQuantityLimitHandler)
}

/* HANDLERS */
function* getAllQuantityLimitDataHandler() {
  try {
    const { userCAG = {} } = yield select(getUserState)
    const body = {
      module: 'QUANTITY_LIMIT',
      status: ApprovalsConstants.ALL_RPM_STATUSES,
    }

    yield put(RPMActions.rpmGetApprovalDocuments(body))
  } catch (err) {
    console.log('getAllQuantityLimitDataHandler Error >Data ', err)

    const transitionalPortal = {
      header: 'Quantity Limit Lookup Failed',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* generateQuantityLimitLevelExportHandler({ payload, callback }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const cd = yield select(getApiPath, 'generate-quantity-limit')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`
    const { filename } = payload
    const { data } = yield call(fetchPost, url, payload)
    if (!data.length) throw Error('No Drugs found for the input conditions')
    const { qlConditionLevel } = payload
    if (callback) {
      yield callback(data, filename)
    }
  } catch (err) {
    console.log('getAllQuantityLimitDataHandler Error >Data ', err)

    const transitionalPortal = {
      header: 'Quantity Limit Lookup Failed To Generate QL Drugs',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* generateQuantityLimitHandler({ payload }) {
  try {
    const {
      form, params, headers, headerMapping
    } = payload
    let get_total_rows = true
    if (params?.request.startRow > 0) {
      get_total_rows = false
    }
    const { serviceUrl } = yield select(getAppState)
    const include_inactive_drugs = form.include_inactive_drugs
    const reqData = {
      drugConditions: [deleteUnnecessaryFields(form)],
      limit: 20,
      offset: params?.request.startRow,
      get_total_rows,
      filterModel: params?.request.filterModel,
      sortModel: params?.request.sortModel,
      include_inactive_drugs,
    }
    const { api_path } = yield select(getApiPath, 'generate-quantity-limit')

    const url = `${serviceUrl}${api_path}`
    const { data, last_row } = yield call(fetchPost, url, reqData)
    const gridData = data?.map((d) => ({
      ...filterRowData(d, headers, headerMapping),
      effective_start_date: convertDateToStrObj(form.effective_start_date),
      effective_end_date: convertDateToStrObj(form.effective_end_date)
    }))
    if (get_total_rows) {
      params?.success({
        rowData: gridData,
        rowCount: last_row,
      })
    } else {
      params?.success({
        rowData: gridData
      })
    }

  } catch (err) {
    console.log('getAllQuantityLimitDataHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Error occurred while generating quantity limit drug conditions',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* saveQuantityLimitDataHandler({ payload }) {
  try {
    const approvalDocument = {
      module_name: payload.doc_name,
      data: payload,
      module: 'QUANTITY_LIMIT',
      status: 'DRAFT',
    }
    // eslint-disable-next-line
    const cb = function* ({ success }) {
      if (success) yield put(NavigationActions.navigateBack())
    }
    const criteria_ids = yield call(updateCriteriaIdsHandler, { payload })
    approvalDocument.data.conditions.criteriaIds = criteria_ids
    delete approvalDocument.data.conditions.umConditions
    yield put(RPMActions.rpmUpsertApprovalDocument(approvalDocument, cb))
  } catch (err) {
    console.log('saveQuantityLimitDataHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Saving Quantity Limit Failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* getQuantityLimitDataHandler({ payload }) {
  try {
    const cb = function* cb(data) {
      const qlData = data[0].module_data.data
      const quantity_limit_data = {
        ql_conditions: qlData.conditions.qlConditions,
        ql_info: {
          effective_start_date: qlData.effective_start_date,
          doc_name: qlData.doc_name,
          max_no_of_fills: qlData.max_no_of_fills,
          doc_version: qlData.doc_version,
          hierarchy: data[0].hierarchy,
          doc_id: data[0].id,
          version: data[0].version,
          version_effective_date: qlData.version_effective_date,
          status: data[0].status,
          is_hierarchy_global: data[0].is_hierarchy_global,
          hideHierarchy: data[0].hideHierarchy,
        },
        criteria_conditions: qlData.conditions.criteriaConditions,
        criteria_ids: qlData.conditions?.criteriaIds || [],
      }
      yield put(QuantityLimitCreationActions.setQuantityLimitData(quantity_limit_data))
    }
    yield put(RPMActions.rpmGetApprovalDocuments(payload, cb))
  } catch (err) {
    console.log('getQuantityLimitDataHandler Error >Data ', err)

    const transitionalPortal = {
      header: 'Quantity Limit Lookup Failed',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

// TODO: REMOVE - DEPRECATED

function* approveQuantityLimitHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const history = yield select(getHistory)
    const qs = history.location.search
    const cd = yield select(getApiPath, 'approve-quantity-limit')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}${qs}`

    const { message } = yield call(fetchPost, url, payload)
    yield put(QuantityLimitCreationActions.getAllQuantityLimitData())
    const transitionalPortal = {
      header: 'Quantity Limit',
      copy: `${message}. Please wait while we publish the quantity limit list.`,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    // triggering airlow dag to load the drug list and publish the quantity limit list
    const execution_time = new Date()
    execution_time.setSeconds(execution_time.getSeconds() + 60)
    const payload2 = {
      conf: { section: 'ql', ...payload },
      dag_run_id: uuidv4(),
      execution_date: execution_time.toISOString(),
    }
    yield call(fetchPost, url.replace('/approve-quantity-limit', '/build-drug-list'), payload2)
  } catch (err) {
    console.log('approveQuantityLimitHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Approving Quantity Limit Failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}
