import {
  put,
  select,
  takeLatest,
} from 'redux-saga/effects'

import { Creators as AppActions } from '../../reducers/app'
import { Creators as TeamManagementActions, Types as TeamManagementTypes } from '../../reducers/teamManagement'
import { Creators as NavigationActions } from '../../reducers/navigation'
import { fetchGet, fetchPut, fetchPatch, fetchPost } from '../../../utils/fetchApi'
import { getAppState, getUserState } from '../../reducers/selectors'

export default [
  createTeamManagementDataWatcher,
  getTeamListDataWatcher,
  editTeamManagementDataWatcher,
  getTeamDataWatcher,
]

/* WATCHERS */
function* getTeamListDataWatcher() {
  yield takeLatest(TeamManagementTypes.GET_TEAM_LIST_DATA, getTeamListDataHandler)
}
function* createTeamManagementDataWatcher() {
  yield takeLatest(TeamManagementTypes.CREATE_TEAM_MANAGEMENT_DATA, createTeamManagementDataHandler)
}
function* editTeamManagementDataWatcher() {
  yield takeLatest(TeamManagementTypes.EDIT_TEAM_MANAGEMENT_DATA, editTeamManagementDataHandler)
}
function* getTeamDataWatcher() {
  yield takeLatest(TeamManagementTypes.GET_TEAM_DATA, getTeamDataHandler)
}

/* HANDLERS */
function* getTeamListDataHandler() {
  try {
    const appState = yield select(getAppState)
    const response = yield fetchGet(`${appState.apiUrl}/fast/team-management-get`)

    yield put(TeamManagementActions.setTeamListData(response))
  } catch (err) {
    const transitionalPortal = {
      header: 'Unable to get teams',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* getTeamDataHandler({ payload }) {
  try {
    const appState = yield select(getAppState)
    const { data } = yield fetchGet(`${appState.apiUrl}/fast/team-management-get`, payload)

    if (!data.length) throw Error('No Team Found')

    yield put(TeamManagementActions.clearTeamData())
    yield put(TeamManagementActions.setTeamData(data[0]))
  } catch (err) {
    const transitionalPortal = {
      header: 'getTeamDataHandler Error >Data ',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* createTeamManagementDataHandler({ payload }) {
  try {
    const { apiUrl } = yield select(getAppState)
    yield fetchPut(`${apiUrl}/fast/team-management`, payload)
    const transitionalPortal = {
      header: 'Team Sucessfully Created',
      copy: `${payload.name} created`,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    yield put(NavigationActions.navigateTo({ pathname: '/team-management' }))
  } catch (err) {
    const transitionalPortal = {
      header: 'Team Creation Failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* editTeamManagementDataHandler({ payload }) {
  try {
    const { apiUrl } = yield select(getAppState)
    if (payload.conditions) {
      payload.roles = [...payload.conditions]
    }
    yield fetchPatch(`${apiUrl}/fast/team-management`, payload)
    const transitionalPortal = {
      header: 'Team Sucessfully Updated',
      copy: `${payload.name} Updated`,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    yield put(NavigationActions.navigateTo({ pathname: '/team-management' }))
  } catch (err) {
    const transitionalPortal = {
      header: 'Team Update Failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}
