import React from 'react'
import { Link } from 'react-router-dom'

export default ({ menu }) => {
  return (
    <Link to={menu.path} className="menu-card">
      <div className="menu-item">
        {menu.icon && <img src={menu.icon} alt={menu.name} className="svg" />}
        <div className="title svg">{menu.name}</div>
        {menu.description && (
          <div className="description">
            {menu.description}
            {' '}
          </div>
        )}
      </div>
    </Link>
  )
}
