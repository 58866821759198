import React from 'react'

import './styles.scss'
import FliptInput from '../../../../../../components/form/fliptInput'
import ButtonSection from './buttonSection'

export default function PhoneNumberSection(props) {
  const {
    personal_phones, submitForm, toggleModal, updateFields,
  } = props

  return (
    <div id="phoneNumberSection">
      <div className="header-section">
        <div className="header-label">Phone Numbers</div>
        <div className="sub-header">(Additional phone numbers will be verified via SMS notification to the new number)</div>
      </div>
      <div className="phone-number-list">
        {personal_phones.map((phone, idx) => {
          const { phone_number } = phone

          return <div key={`${idx}${phone_number}`}>{phone_number}</div>
        })}
      </div>
      <div className="phone fields">
        <FliptInput label="Phone Number" name="phone_number" placeholder="Enter phone number" onChange={updateFields} />
        <ButtonSection toggleModal={toggleModal} submitForm={submitForm} />
      </div>
    </div>
  )
}
