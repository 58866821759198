import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { asMutable } from 'seamless-immutable'
import { Creators as AppActions } from '../../../../redux/reducers/app'
import FliptGrid from '../../../../components/fliptGrid'
import { ButtonRenderer, IconClickRenderer } from '../../../../components/fliptGrid/cellRenderers'
import { convertDateTimeToEstStr } from '../../../../utils/utilities'
import './styles.scss'

const PACaseNotes = ({ notes, pa_case_id }) => {

    const notesData = (notes || []).map((note) => {
        return {
            ...note,
            date: convertDateTimeToEstStr(note.note_date_time, { skipTime: true }),
            time: convertDateTimeToEstStr(note.note_date_time, { skipDate: true }),
        }
    })

    const _copyNote = (gridProps) => {
        const { data } = gridProps
        navigator.clipboard.writeText(data.note)
    }

    const headers = ['note', 'date', 'time', 'note_type', 'technician']

    const cellRendererParams = {
        date: { width: '120px' },
        time: { width: '120px' },
        note_type: { width: '120px' },
        technician: { width: '200px' },
        note: {
            tooltipField: 'note',
            cellRenderer: IconClickRenderer,
            searchArgs: {
                iconName: 'copy',
                iconOnClick: _copyNote,
                fieldToRenderInCell: 'note',
            },
            width: '300px'
        }
    }

    return <div id='member-lookup-pa-notes'>
        <div className='header'>
            Notes for PA Case: {pa_case_id}
        </div>
        <div className='notes-grid'>
            <FliptGrid
                data={notesData}
                headers={headers}
                cellRendererParams={cellRendererParams}
                tooltipShowDelay={0}
            />
        </div>
    </div>
}


class PaReport extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    onCaseIdClick = (rowData) => {
        const { actions } = this.props
        actions.setModalComponent({
            modalProperties: {
                size: 'medium',
            },
            contents: <PACaseNotes {...rowData} />,
        })
        actions.toggleModal()
    }

    render() {
        const { data: { pa_case_data = [] } } = this.props
        const mutablePaCaseData = asMutable(pa_case_data, { deep: true })
        mutablePaCaseData.forEach((paCase) => {
            paCase.status_date = convertDateTimeToEstStr(paCase.last_status_update.begin_date_time, { skipTime: true })
        })
        const rxHistoryHeader = ['pa_case_id', 'status_date', 'pa_case_status', 'case_type', 'gpi', 'drug_name', 'dosage', 'dosage_strength', 'notes']
        const cellRendererParams = {
            pa_case_id: {
                cellRenderer: ButtonRenderer,
                path: '/upload-questionnaire',
                searchArgs: { key: 'pa_case_id', className: 'linkButton', onClick: this.onCaseIdClick },
            },
            notes: {
                hide: true
            }
        }
        return (
            <div className="section" id="PaMemberLookup">
                <div className="section-header">
                    <h2>Prior Authorization Case Information</h2>
                </div>
                <div className='content'>
                    <FliptGrid
                        data={pa_case_data || []}
                        headers={rxHistoryHeader}
                        cellRendererParams={cellRendererParams}
                    />
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...AppActions,
    }

    return {
        actions: bindActionCreators(allActions, dispatch),
    }
}

export default connect(null, mapDispatchToProps)(PaReport)
