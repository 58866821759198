import React, { useState, useEffect, useCallback } from "react";

import { Creators as AppActions } from "../../../../redux/reducers/app";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import FliptInput from "../../../../components/form/fliptInput";
import moment from "moment";
import { Button, Icon } from "semantic-ui-react";
import FliptPaDropdown from "../../../../components/form/fliptPaDropdown";
import FliptRadio from "../../../../components/form/fliptRadio";
import FliptLabel from "../../../../components/form/fliptLabel";
import { Creators as FileConfigurationActions } from "../../../../redux/reducers/api/fileConfiguration";
import Info from "./Info";
import { renderDynamicInput, sampleData } from "./utils";
const ThresholdInformation = (props) => {
  const validations = props.state.validations;
  const validationResults = props.state.validationResults;
  const showErrors =
    validationResults && validationResults && !validationResults.isAllValid;
  const [percentage, setPercentage] = useState([
    {
      key: 1,
      text: "percentage",
      value: "percentage",
    },
    {
      key: 2,
      text: "Value",
      value: "Value",
    },
  ]);
  const _updateFields = (el, dropdown) => {
    const { name, value } = dropdown || el.currentTarget;
    props.actions.updateThresholdValidationForm({ name, value });
  };
  const { thresholdInformation } = props.state;
  const {
    hours_to_complete_processing,
    criteria_total_failure,
    criteria_adds_failure,
    criteria_change_failure,
    criteria_term_failure,
    criteria_minimum_failure,
    criteria_total_failure_type,
    criteria_adds_failure_type,
    criteria_change_failure_type,
    criteria_term_failure_type,
    criteria_minimum_failure_type,
  } = thresholdInformation;
  const isPercentage = (value) => {
    if (value === "percentage") return 'Must be a percentage'
    if (value === "Value") return "Must be a value up to 5 bytes"
    else return "Percentage or a value up to 5 bytes"
  }
  const renderAdditionalFields = () => {
    const { additionalFields } = props.state;
    if (additionalFields?.length == 0) return null;
    const rowDivider = 1;
    const fieldProps = {
      ...thresholdInformation,
      onChange: _updateFields,
      className: 'fields-header',
      num: 1
    };
    return (
      <>
        {Array.from(
          { length: Math.ceil(additionalFields.length / rowDivider) },
          (_, i) => (
            <div className="fields-container" key={`row${i}`}>
              {additionalFields
                .slice(i * rowDivider, (i + 1) * rowDivider)
                .map((movie) => renderDynamicInput(movie, fieldProps))}
            </div>
          )
        )}
      </>
    );
  };

  return (
    <div id="file-configuration">
      <div className="section-header">Threshold Parameters</div>
      <div className="section-sub-header">File Processing SLA</div>

      <div className="content shadow margin-bottom ">
        <div className="fields-container">
          <div className="fields-header">
            <div className="field-label">
              <span>
                Number of hours to Complete processing of Client Import File
              </span>
              <Info
                content="Total number of hours allowed for processing to maintain client service level agreement"
                position="top right"
              />
            </div>
          </div>
          <div className="fields-header">
            <FliptInput
              className="field-input"
              placeholder="Number of hours"
              name="hours_to_complete_processing"
              value={hours_to_complete_processing}
              onChange={_updateFields}
              validation="numeric"
              required
              maxLength="5"
              error={
                (showErrors &&
                  !validationResults?.hours_to_complete_processing &&
                  validations.hours_to_complete_processing?.errorMessage) ||
                null
              }
            />
          </div>
        </div>
        <div className="field-label-hint">
          Enter the Threshold Criteria for this File
        </div>
        <div className="fields-container">
          <div className="fields-header">
            <div className="field-label">
              <span>
                Amount of total records that will indicate the file failure
              </span>
              <Info
                content="Number or percentage of total file records which would indicate a file failure"
                position="top right"
              />
            </div>
          </div>
          <div className="fields-header">
            <div className="field-label">
              <div className="divide-third">
                <FliptInput
                  className="field-input"
                  placeholder={isPercentage(criteria_total_failure_type)}
                  name="criteria_total_failure"
                  value={criteria_total_failure}
                  onChange={_updateFields}
                  validation="numeric"
                  required
                  maxLength="5"
                  error={
                    (showErrors &&
                      !validationResults?.criteria_total_failure &&
                      validations.criteria_total_failure?.errorMessage) ||
                    null
                  }
                />
              </div>
              <div className="divide-one">
                <FliptPaDropdown
                  stylized
                  simple
                  className="half-width"
                  search={false}
                  options={percentage}
                  style={{ zIndex: 80 }}
                  name="criteria_total_failure_type"
                  value={criteria_total_failure_type}
                  onChange={_updateFields}
                />
                {showErrors &&
                  !validationResults?.criteria_total_failure_type && (
                    <section className="flipt-input-error">
                      <em>
                        {validations.criteria_total_failure_type
                          ?.errorMessage || null}
                      </em>
                    </section>
                  )}
              </div>
            </div>
          </div>
        </div>

        <div className="fields-container">
          <div className="fields-header">
            <div className="field-label">
              <span>
                Amount of add records that would indicate a file failure
              </span>
              <Info
                content="Number or percentage of add records which would indicate a file failure"
                position="top right"
              />
            </div>
            {/* <div className="field-label-hint">
              <span>Supportive text</span>
            </div> */}
          </div>
          <div className="fields-header">
            <div className="field-label">
              <div className="divide-third">
                <FliptInput
                  className="field-input"
                  placeholder={isPercentage(criteria_adds_failure_type)}
                  name="criteria_adds_failure"
                  value={criteria_adds_failure}
                  onChange={_updateFields}
                  validation="numeric"
                  required
                  maxLength="5"
                  error={
                    (showErrors &&
                      !validationResults?.criteria_adds_failure &&
                      validations.criteria_adds_failure?.errorMessage) ||
                    null
                  }
                />
              </div>
              <div className="divide-one">
                <FliptPaDropdown
                  stylized
                  simple
                  className="half-width"
                  search={false}
                  options={percentage}
                  style={{ zIndex: 70 }}
                  name="criteria_adds_failure_type"
                  value={criteria_adds_failure_type}
                  onChange={_updateFields}
                />
                {showErrors &&
                  !validationResults?.criteria_adds_failure_type && (
                    <section className="flipt-input-error">
                      <em>
                        {validations.criteria_adds_failure_type?.errorMessage ||
                          null}
                      </em>
                    </section>
                  )}
              </div>
            </div>
          </div>
        </div>

        <div className="fields-container">
          <div className="fields-header">
            <div className="field-label">
              <span>
                Amount of change records that would indicate a file failure
              </span>
              <Info
                content="Number or percentage of change records which would indicate a file failure"
                position="top right"
              />
            </div>
          </div>
          <div className="fields-header">
            <div className="field-label">
              <div className="divide-third">
                <FliptInput
                  className="field-input"
                  placeholder={isPercentage(criteria_change_failure_type)}
                  name="criteria_change_failure"
                  value={criteria_change_failure}
                  onChange={_updateFields}
                  validation="numeric"
                  required
                  maxLength="5"
                  error={
                    (showErrors &&
                      !validationResults?.criteria_change_failure &&
                      validations.criteria_change_failure?.errorMessage) ||
                    null
                  }
                />
              </div>
              <div className="divide-one">
                <FliptPaDropdown
                  stylized
                  name="criteria_change_failure_type"
                  value={criteria_change_failure_type}
                  style={{ zIndex: 50 }}
                  onChange={_updateFields}
                  simple
                  className="half-width"
                  search={false}
                  options={percentage}

                />
                {showErrors &&
                  !validationResults?.criteria_change_failure_type && (
                    <section className="flipt-input-error">
                      <em>
                        {validations.criteria_change_failure_type
                          ?.errorMessage || null}
                      </em>
                    </section>
                  )}
              </div>
            </div>
          </div>
        </div>

        <div className="fields-container">
          <div className="fields-header">
            <div className="field-label">
              <span>
                Amount of term records that would indicate a file failure
              </span>
              <Info
                content="Number or percentage of term records which would indicate a file failure"
                position="top right"
              />
            </div>
          </div>
          <div className="fields-header">
            <div className="field-label">
              <div className="divide-third">
                <FliptInput
                  className="field-input"
                  placeholder={isPercentage(criteria_term_failure_type)}
                  name="criteria_term_failure"
                  value={criteria_term_failure}
                  onChange={_updateFields}
                  validation="numeric"
                  required
                  maxLength="5"
                  error={
                    (showErrors &&
                      !validationResults?.criteria_term_failure &&
                      validations.criteria_term_failure?.errorMessage) ||
                    null
                  }
                />
              </div>
              <div className="divide-one">
                <FliptPaDropdown
                  stylized
                  name="criteria_term_failure_type"
                  value={criteria_term_failure_type}
                  onChange={_updateFields}
                  simple
                  style={{ zIndex: 40 }}
                  className="half-width"
                  search={false}
                  options={percentage}

                />
                {showErrors &&
                  !validationResults?.criteria_term_failure_type && (
                    <section className="flipt-input-error">
                      <em>
                        {validations.criteria_term_failure_type?.errorMessage ||
                          null}
                      </em>
                    </section>
                  )}
              </div>
            </div>
          </div>
        </div>

        <div className="fields-container">
          <div className="fields-header">
            <div className="field-label">
              <span>
                Minimum number of records that would indicate a file failure
              </span>
              <Info
                content="Minimum number of records required in the file in order to not indicate a file failure"
                position="top right"
              />
            </div>
          </div>
          <div className="fields-header">
            <div className="field-label">
              <div className="divide-third">
                <FliptInput
                  className="field-input"
                  placeholder={isPercentage(criteria_minimum_failure_type)}
                  name="criteria_minimum_failure"
                  value={criteria_minimum_failure}
                  onChange={_updateFields}
                  validation="numeric"
                  required
                  maxLength="5"
                  error={
                    (showErrors &&
                      !validationResults?.criteria_minimum_failure &&
                      validations.criteria_minimum_failure?.errorMessage) ||
                    null
                  }
                />
              </div>
              <div className="divide-one">
                <FliptPaDropdown
                  stylized
                  name="criteria_minimum_failure_type"
                  value={criteria_minimum_failure_type}
                  onChange={_updateFields}
                  simple
                  search={false}
                  className="half-width"
                  options={percentage}

                />
                {showErrors &&
                  !validationResults?.criteria_minimum_failure_type && (
                    <section className="flipt-input-error">
                      <em>
                        {validations.criteria_minimum_failure_type
                          ?.errorMessage || null}
                      </em>
                    </section>
                  )}
              </div>
            </div>
          </div>
        </div>
        {
          renderAdditionalFields()
        }
      </div>
    </div>
  );
};


const mapStateToProps = (state) => {
  return {
    state: {
      app: state.app,
      thresholdInformation: state.fileConfiguration.thresholdInformation,
      validations: state.fileConfiguration.validations.thresholdInformation,
      validationResults:
        state.fileConfiguration.validationResults.thresholdInformation,
      additionalFields: state.fileConfiguration.additional_fields?.find(row => row.page === 'threshold_information')?.fields || [],
      // test data below, to be removed 
      //  additionalFields: sampleData?.find(row => row.page === 'threshold_information')?.fields || [],
    },
  }
}

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
    ...FileConfigurationActions,
  };
  return {
    actions: bindActionCreators(allActions, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ThresholdInformation);
