import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import './styles.scss'
import Config from '../../../config'
import BreadCrumb from '../../../components/breadCrumb'
import ClaimTable from '../../../components/fliptGrid'
import FliptButton from '../../../components/form/fliptButton'
import { Creators as AutoSuggestActions } from '../../../redux/reducers/api/autoSuggest'
// import FliptDatePicker from '../../../components/form/fliptDatePicker'
import FliptDropdown from '../../../components/form/fliptDropdown'
import FliptInput from '../../../components/form/fliptInput'
// import { ColorCodedStatusRenderer, CheckboxRenderer, PHILoader } from '../../../components/fliptGrid/cellRenderers'
import { Creators as ApiActions } from '../../../redux/reducers/api/formularyExportLookup'
import { Creators as AppActions } from '../../../redux/reducers/app'
import { filterRowData, buildDropdownOptions } from '../../../utils/utilities'
import FliptSearch from '../../../components/form/fliptSearch'

import {
  createQueryString, parseQueryString, convertStrToDateObj,
} from '../../../utils/utilities'
import moment from 'moment'

class FormularyExportLookUp extends Component {
  constructor(props) {
    super(props)
    this.state = {
      form: {
        formulary_name: '',
        gpi14: '',
        ndc: '',
        drug_name: '',
        doc_name: '',
        drugListOption: []
      },
      showGrid: false,
      drugNames: [],
      loading: false,
      minCharacters: 3,
      reRender: 0,
      agGridRef: React.createRef(),
    }
  }

  componentDidMount() {
    const { history, actions, state: { formularyExportData } } = this.props
    const { location } = history
    if (!location) return
    const { form, agGridRef, showGrid } = this.state

    this._qsToFormData()
    history.location.search = createQueryString(form)
    if (showGrid && agGridRef.current) {
      agGridRef.current.api.refreshServerSideStore({ route: null, purge: true });
    }
    setTimeout(() => {
      actions.getFormularyExportDrugOptionsData()
    }, Config.COMPONENT_DATA_LOAD_TIME)
  }

  componentDidUpdate(prevProps) {
    const { props } = this
    const { state: { autoSuggestData } } = props
    const prevState = prevProps.state
    const currState = props.state
    if (prevState === currState) return

    this._updateAutoSuggestionResult(autoSuggestData['drug_name'])
  }

  _qsToFormData = () => {
    const { history } = this.props
    const { form } = this.state
    const qs = parseQueryString(history.location.search)

    Object.keys(qs).forEach((key) => {
      form[key] = qs[key]
    })
    this.setState({ form })
  }

  _onAutoSuggestionResultSelect = (e, data) => {
    const { form } = this.state
    const { name, result } = data
    form[name] = result.title

    this.setState({ form })
  }

  _updateAutoSuggestionResult(result) {
    const drugNames = result ? result.map((d) => ({ title: d })) : []

    this.setState({ loading: false, drugNames })
  }

  handleClick = () => {
    const { form, agGridRef, showGrid } = this.state
    const { history, actions } = this.props

    this.setState({ showGrid: true })
    history.location.search = createQueryString(form)
    actions.getFormularyExportDrugOptionsData()
    if (showGrid && agGridRef.current) {
      agGridRef.current.api.refreshServerSideStore({ route: null, purge: true });
    }
    // agGridRef.current.api.refreshServerSideStore({ route: null, purge: true }) // to refresh the grid server side data
  }

  buildModuleOptions(dropdownValues) {
    if (!dropdownValues) return []
    return dropdownValues.map((dropdownValue, index) => ({
      key: index,
      text: dropdownValue.toUpperCase().replace('_', ' '),
      value: dropdownValue,
    }))
  }

  handleAction = () => {
    // const { form, agGridRef } = this.state
    // const { history, actions } = this.props
    // if (!form.start_date || !form.end_date){
    //   form['start_date'] =  moment().subtract(7, 'days').toDate()
    //   form['end_date'] = moment().add(1, 'day').toDate()
    // }
    // let { auth_id } = form;
    // // 13 is the maximum length of auth_id.
    // const auth_id_length = 13 - auth_id.length;

    // if (auth_id.length > 0 && auth_id.length < 13) {
    //   for (let i = 0; i < auth_id_length; i++) {
    //     auth_id = '0' + auth_id;
    //   }
    // } else if (auth_id_length < 0) {
    //   auth_id = auth_id.substring(auth_id_length * -1);
    // }
    // form['auth_id'] = auth_id;
    // this.setState({ form })
    // history.location.search = createQueryString(form)
    // agGridRef.current.api.refreshServerSideStore({ route: null, purge: true }) // to refresh the grid server side data
  }

  handleClear = () => {
    const { form, reRender = 0, agGridRef, showGrid } = this.state
    Object.keys(form).forEach((key) => {
      if ((key === 'drugListOption')) {
        form[key] = []
      }
      else {
        form[key] = ''
      }
    })
    this.setState({ form, showGrid: false, reRender: reRender + 1 })
    const { history, actions } = this.props
    //this._qsToFormData()

    history.location.search = createQueryString(form)
    actions.getFormularyExportDrugOptionsData()
    // if (showGrid && agGridRef.current) {
    //   agGridRef.current.api.refreshServerSideStore({ route: null, purge: true });
    // }
    //agGridRef.current.api.refreshServerSideStore({ route: null, purge: true })
  }

  updateField = (el, dropdown) => {
    const { form } = this.state
    const { name, value } = dropdown || el.currentTarget
    const { state: { optionsDruglist }, actions } = this.props
    const { minCharacters } = this.state
    form[name] = value
    if (name === 'formulary_name') {
      form['drugListOption'] = optionsDruglist[value]
    }
    this.setState({ form })
    if (name === 'drug_name' && value.length >= minCharacters) {
      actions.getAutoSuggestData({ search_string: value, search_in: 'fts_ndc_drugs', search_for: 'drug_name' })
      this.setState({ loading: true })
    }
  }


  render() {
    const { actions, state: { data } } = this.props
    const headers = [
      'formulary_name', 'doc_name', 'gpi14', 'ndc', 'drug_name', 'drug_group',
      'drug_class', 'drug_subclass', 'multisource', 'otc_indicator', 'brand_generic', 'formulary_tier', 'formulary_status',
      'tier_match_condition_id', 'product_label', 'gpi14_desc', 'pa_required', 'pa_match_condition_id', 'st_required',
      'st_match_condition_id', 'ql_required', 'ql_match_condition_id', 'qty_max_amt', 'qty_max_amt_ql', 'qty_max_amt_time_period', 'ds_max_amt', 'ds_max_amt_ql',
      'ds_max_amt_time_period', 'dd_max_amt', 'dd_max_amt_ql', 'dd_max_amt_time_period_units', 'dd_max_amt_time_period', 'dd_max_applied_ql',
      'ptd_qty_max_amt', 'ptd_qty_max_amt_ql', 'ptd_qty_max_amt_time_period_units', 'ptd_qty_max_amt_time_period', 'ptd_qty_refill_percentage', 'ptd_qty_applied_ql',
      'ptd_ds_max_amt', 'ptd_ds_max_amt_time_period_units', 'ptd_ds_max_amt_time_period', 'ptd_ds_refill_percentage', 'ptd_ds_applied_ql',
      'ptd_mf_max_amt', 'ptd_mf_max_amt_time_period_units', 'ptd_mf_max_amt_time_period', 'ptd_mf_refill_percentage', 'ptd_mf_amt_max_applied_ql'
    ]

    const headerMapping = {
      startDate: 'processed_date',
    }
    const { state: { user, drugsPerPage, formularies, optionsDruglist, formularyExportData } } = this.props
    const { form, agGridRef, drugNames, loading, minCharacters, showGrid } = this.state
    // const domainOptions = user.accounts_access.map((d) => ({
    //   key: d.id,
    //   text: d.id,
    //   value: d.id,
    // }))

    const filterParams = {
      // /* eslint-disable-next-line */
      // comparator: (filterLocalDateAtMidnight, cellValue) => {
      //   const dateAsString = cellValue
      //   if (dateAsString == null) return -1
      //   const dateParts = dateAsString.split(' ')[0].split('/')
      //   const cellDate = new Date(
      //     Number(dateParts[2]),
      //     Number(dateParts[0]) - 1,
      //     Number(dateParts[1]),
      //   )
      //   if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      //     return 0
      //   }

      //   if (cellDate < filterLocalDateAtMidnight) {
      //     return -1
      //   }

      //   if (cellDate > filterLocalDateAtMidnight) {
      //     return 1
      //   }
      // },
      browserDatePicker: true,
      filterOptions: ['inRange', 'equals'],
      defaultJoinOperator: 'OR',
      suppressAndOrCondition: false,
      inRangeInclusive: true,
    }
    const cellRendererParams = {
    }

    const serverSideGridConfig = {
      rowModel: 'serverSide',
      serverSideStoreType: 'partial',
      cacheBlockSize: drugsPerPage,
      pagination: true,
      paginationPageSize: drugsPerPage,
    }
    const serverSideGridParams = {
      form,
      apiId: 'formulary-export-lookup',
      sagaToCall: actions.getFormularyExportDrugData,
      headers,
      headerMapping,
    }
    return (
      <div id="formularyExportLookup">
        <div className='breadCrumbContainer'>
          <BreadCrumb {...this.props} />
        </div>
        <div className='header' float="left">
          <div className="searchDrugLookup">
            <FliptButton name="SEARCH" className="primary searchDrugLookup" onClick={this.handleClick} />

            <FliptButton name="CLEAR" className="primary searchDrugLookup" onClick={this.handleClear} />
          </div>
        </div>


        {/* <div className="stylized">
          <FliptDropdown placeholder="Formulary Name" value={form.formulary_name} onChange={this.updateField} single selection options={this.buildModuleOptions(formularies)} simple item name="formulary_name" />
          <FliptDropdown placeholder="Module Name" value={form.doc_name} onChange={this.updateField} single selection options={buildDropdownOptions(form.drugListOption)} simple item name="doc_name" />
         
          <FliptInput className="formulary-input" value={form.gpi14} placeholder="Gpi" name="gpi14" onChange={this.updateField} />
          <FliptInput value={form.ndc} placeholder="Ndc" name="ndc" onChange={this.updateField} />
          <FliptInput value={form.ddid} placeholder="ddid" name="ddid" onChange={this.updateField} />
          <FliptSearch className="drug-lookup-search" placeholder="Drug Name" name="drug_name" value={form.drug_name} results={drugNames} loading={loading} minCharacters={minCharacters} onResultSelect={(e, data) => this._onAutoSuggestionResultSelect(e, data)} onSearchChange={(e, dropdown) => this.updateField(e, dropdown)} />
          <FliptInput value={form.drug_group} placeholder="drug_group" name="drug_group" onChange={this.updateField} />
          <FliptInput value={form.third_party_restriction_code} placeholder="TPR Code" name="third_party_restriction_code" onChange={this.updateField} />
          <FliptInput value={form.dosage_form_cd} placeholder="Dosage Form Code" name="dosage_form_cd" onChange={this.updateField} />
          <FliptButton name="Search" className="primary searchButton" onClick={this.handleClick} />
          <FliptButton name="Clear Filters" className="primary searchButton" onClick={this.handleClear} />
        </div> */}

        <div className="section row">
          <div className="drug-lookup-inputs-container spacing border-line shadow row">
            <div className="drug-lookup-inputs col-3">
              <span>FORMULARY</span>
              <FliptDropdown className="col-12" placeholder="Formulary Name" default="" value={form.formulary_name} onChange={this.updateField} single selection options={this.buildModuleOptions(formularies)} simple item name="formulary_name" />
            </div>
            {form.formulary_name && (
              <div className="drug-lookup-inputs col-3">
                <span>DRUG LIST</span>
                <FliptDropdown placeholder="Drug List Name" default="" value={form.doc_name} onChange={this.updateField} single selection options={buildDropdownOptions(form?.drugListOption || [])} simple item name="doc_name" />
              </div>
            )}
            {form.formulary_name && (
              <div className="drug-lookup-inputs">
                <span>DRUG NAME</span>

                <FliptSearch className="drug-lookup-search" placeholder="Drug Name" name="drug_name" value={form.drug_name} results={drugNames} loading={loading} minCharacters={minCharacters} onResultSelect={(e, data) => this._onAutoSuggestionResultSelect(e, data)} onSearchChange={(e, dropdown) => this.updateField(e, dropdown)} />
              </div>
            )}
            {form.formulary_name && (
              <div className="drug-lookup-inputs col-1">
                <span>GPI</span>
                <FliptInput placeholder="GPI" name="gpi14" value={form.gpi14} onChange={this.updateField} />
              </div>
            )}

            {form.formulary_name && (
              <div className="drug-lookup-inputs col-1">
                <span>NDC</span>
                <FliptInput placeholder="NDC" name="ndc" value={form.ndc} onChange={this.updateField} />
              </div>
            )}

          </div>


          {showGrid && (
            <div className="content">

              <ClaimTable
                headers={headers}
                cellRendererParams={cellRendererParams}
                serverSideGridConfig={serverSideGridConfig}
                serverSideGridParams={serverSideGridParams}
                agGridRef={agGridRef}
                filterOptions={['contains', 'equals']}
                rowSelection={'multiple'}
                doAutoSize
                suppressColumnVirtualisation
              />
            </div>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  state: {
    //formularyExportData: [],
    formularyExportData: state.formularyExportData.data,
    last_row: state.formularyExportData.last_row,
    drugsPerPage: state.formularyExportData.drugsPerPage,
    user: state.user,
    optionsData: state.formularyExportData.optionsData,
    formularies: state.formularyExportData.optionsData.formularies,
    optionsDruglist: state.formularyExportData.optionsData.druglist,
    autoSuggestData: state.autoSuggest.autoSuggestData,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...ApiActions,
    ...AppActions,
    ...AutoSuggestActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormularyExportLookUp)
