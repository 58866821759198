import React, { PureComponent, useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Creators as FileConfigurationActions } from "../../../../redux/reducers/api/fileConfiguration"
import { Creators as IntegrationManagementActions } from "../../../../redux/reducers/api/integrationManagement";
import "./styles.scss";
import { Creators as AppActions } from "../../../../redux/reducers/app";

import FliptButton from "../../../../components/form/fliptButton";
import moment from "moment";
import BreadCrumb from "../../../../components/breadCrumb";
import { Modal } from "semantic-ui-react";
import ClientInformation from "./ClientInformation";
import FileInformation from "./FileInformation";
import EmailInformation from "./EmailInformation";
import OutboundEmailInformation from "./OutboundEmailInformation";
import ThresholdInformation from "./ThresholdInformation";
import ImportFormat from "./ImportFormat";
import OutboundFormat from "./OutboundFormat";
import FileMapping from "./FileMapping";
import FileConfigurationDashboard from '../FileConfigurationDashboard'
import ResponseParameter from './ResponseParameter'
import { INBOUND_FILE_DIRECTION } from './utils'
import { Link } from "react-router-dom/cjs/react-router-dom.min";
const FileConfiguration = (props) => {
  const [showExitModal, setShowExitModal] = useState(false);
  const [showExitDesktopModal, setShowExitDesktopModal] = useState(false);
  const { step, fileInformation: {outbound_end_date, outbound_start_date} } = props.state.fileConfiguration;
  const { fileDirection, isStandardImportFormat, noUserDefinedFields } = props.state;
  const inboundFile = fileDirection === INBOUND_FILE_DIRECTION;
  useEffect(() => props.actions.returnToDashboard(), [])
  useEffect(() => {
    if (props.state.fileID) {
      const fileConfiguration = props.state.fileConfiguration;
      const { file_direction, file_type } = fileConfiguration;
      props.actions.getFileType({ file_direction, file_type });
    }
  }, [props.state.fileID]);

  if (!props.state.fileID) {
    return <FileConfigurationDashboard {...props} />
  }


  const renderNextAndSubmitButton = () => {
    const isOutboundOnStep5 = step === 5 && !inboundFile;
    const isStandardImportFileOnStep5 = isStandardImportFormat && step === 5;
    if (isStandardImportFileOnStep5 || isOutboundOnStep5 || step === 6) {
      return (
        <div className="fields-header">
          <FliptButton
            className="primary"
            name="Submit"
            onClick={() => {
              props.actions.prepFileConfigurationForSubmit()
            }}
          />
        </div>
      )
    }
    return (
      <div className="fields-header">
        <FliptButton
          className="primary"
          name="Next"
          onClick={() => {
            props.actions.syncWithServer()
            if(!inboundFile && step === 1 && (!outbound_start_date || !outbound_end_date)){
              const transitionalPortal = {
                header: 'Fields are missing',
                copy: 'Coverage Start Date and Coverage End Date are required',
            }
              props.actions.displayTransitionalPortal(transitionalPortal)
              return;
            }
            if(!inboundFile && step === 2){
              props.actions.setStep(5)
              return;
            }
            props.actions.handleNextStep()
          }}
        />
      </div>
    )
  }
  const fileType = props.state.fileConfiguration.file_type
  const fileConfigName = props.state.fileConfiguration.file_config_name

  return (
    <>
      <div id="file-configuration">
        <div className="breadcrum-container">
          <BreadCrumb {...props} />
        </div>

        <div className="header">
          <span>FILE ADMINISTRATION AND DETAILS</span>
          <span>{fileType ? `File Type: ${fileType} / ` : ''}{fileConfigName ? fileConfigName : ''}</span>
        </div>

        <div className="devider" />

        <>
          <div className="container">
            <div className="subheader">
              <div>
                <ul className="progressbar">
                  <li
                    className={
                      step >= 0 ? `active ${step > 0 ? " completed" : ""}` : ""
                    }
                    onClick={() => {
                      props.actions.setStep(0);
                    }}
                  >
                    Client Contact Information
                  </li>
                  <li
                    className={
                      step >= 1 ? `active ${step > 1 ? " completed" : ""}` : ""
                    }
                    onClick={() => {
                      props.actions.setStep(1);
                    }}
                  >
                    File Information
                  </li>
                  <li
                    className={
                      step >= 2 ? `active ${step > 2 ? " completed" : ""}` : ""
                    }
                    onClick={() => {
                      props.actions.setStep(2);
                    }}
                  >
                    Email And SFTP
                  </li>

                  { inboundFile && <li
                    className={
                      step >= 3 ? `active ${step > 3 ? " completed" : ""}` : ""
                    }
                    onClick={() => {
                      props.actions.setStep(3);
                    }}
                  >
                    Threshold Validation
                  </li> }

                  { inboundFile && <li
                    className={
                      step >= 3 ? `active ${step > 4 ? " completed" : ""}` : ""
                    }
                    onClick={() => {
                      props.actions.setStep(4);
                    }}
                  >
                    Response File Parameters
                  </li> }

                  <li
                    className={
                      step >= 5 ? `active ${step > 5 ? " completed" : ""}` : ""
                    }
                    onClick={() => {
                      props.actions.setStep(5);
                    }}
                  >
                    Define  Format
                  </li>
                  {fileDirection == INBOUND_FILE_DIRECTION && !isStandardImportFormat && <li
                    className={
                      (noUserDefinedFields) ? 'disabled'
                        : step >= 6 ? `active ${step > 6 ? " completed" : ""}` : ""
                    }
                    onClick={() => {
                      props.actions.setStep(6);
                    }}
                  >
                    Map to internal Standard
                  </li>}
                </ul>
              </div>
            </div>
            <div className="content-progress">
              {step == 0 && <ClientInformation />}
              {step == 1 && <FileInformation />}
              {step == 2 && (inboundFile ? <EmailInformation />: <OutboundEmailInformation/>)}
              {step == 3 && (inboundFile && <ThresholdInformation />)}
              {step == 4 && (inboundFile && <ResponseParameter />)}
              {step == 5 && (inboundFile ? <ImportFormat /> : <OutboundFormat />)}
              {step == 6 && <FileMapping />}
            </div>
            <div className="fields-btn-container">

              <div className="fields-header">
                <FliptButton
                  className={step === 0 ? 'btn-disabled' : "primary"}
                  name="Previous"
                  disabled={step === 0}
                  onClick={() => {
                    if (step === 0) return;
                    props.actions.syncWithServer()
                    if(!inboundFile && step === 5){
                      props.actions.setStep(2)
                      return;
                    }
                    props.actions.prevPage()
                  }}
                />
              </div>

              <div className="fields-header">
                <FliptButton
                  className="primary"
                  name="Save & Exit"
                  onClick={() => setShowExitModal(!showExitModal)}
                />
              </div>
              {renderNextAndSubmitButton()}
              <div className="fields-header exit-plan-config">
                <div
                  className="exit-plan-button"
                  name="Exit without saving?"
                  onClick={() => setShowExitDesktopModal(!showExitDesktopModal)}
                >
                  <span className="exit-plan">Exit without saving</span>
                </div>
              </div>
            </div>
          </div>
        </>
      </div>
      <Modal
        onClose={() => setShowExitDesktopModal(!showExitDesktopModal)}
        onOpen={() => setShowExitDesktopModal(!showExitDesktopModal)}
        open={showExitDesktopModal}
        size='small'
        dimmer='blurring'
      >
        <Modal.Header>
          Exit without saving?
        </Modal.Header>
        <Modal.Actions>
          <FliptButton
            className="secondary"
            name="Cancel"
            onClick={() => setShowExitDesktopModal(false)}
          />
          {" "}
          <FliptButton
            className="primary"
            name="Confirm"
            onClick={() => {
              setShowExitDesktopModal(false)
              props.actions.syncWithServer()
              props.actions.returnToDashboard()
            }}
          />
        </Modal.Actions>
      </Modal>
      <Modal
        onClose={() => setShowExitModal(!showExitModal)}
        onOpen={() => setShowExitModal(!showExitModal)}
        open={showExitModal}
        size='small'
        dimmer='blurring'
      >
        <Modal.Header>
          Save and Exit?
        </Modal.Header>
        <Modal.Actions>
          <FliptButton
            className="secondary"
            name="Cancel"
            onClick={() => setShowExitModal(false)}
          />
          {" "}
          <FliptButton
            className="primary"
            name="Confirm"
            onClick={() => {
              setShowExitModal(false)
              props.actions.syncWithServer()
              props.actions.returnToDashboard()
            }}
          />
        </Modal.Actions>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  const fileType = state.fileConfiguration.file_type
  const fileStandardKey = fileType + '_standard'
  const standard = state.fileConfiguration[fileStandardKey]
  return {
    state: {
      app: state.app,
      fileConfiguration: state.fileConfiguration,
      fileID: state.fileConfiguration.file_id,
      fileDirection: state.fileConfiguration.file_direction,
      fileStandardFields: standard?.fields,
      noUserDefinedFields: (state.fileConfiguration.importFormat?.defined_configuration_list || []).length === 0,
      isStandardImportFormat: state.fileConfiguration.importFormat?.import_format === 'File Standard',
    }
  }
};

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
    ...FileConfigurationActions,
    ...IntegrationManagementActions,
  };

  return {
    actions: bindActionCreators(allActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FileConfiguration);
