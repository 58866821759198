import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { camelCase } from 'lodash'
import { connect } from 'react-redux'
import './styles.scss'
import BreadCrumb from '../../../../components/breadCrumb'
import Config from '../../../../config'
import FliptButton from '../../../../components/form/fliptButton'
import FliptGrid from '../../../../components/fliptGrid'
import SubmenuNav from '../shared/navigation'
import { LinkRenderer, ColorCodedStatusRenderer } from '../../../../components/fliptGrid/cellRenderers'
import NewDrugPopupCellRenderer from '../popup/networkTierCellRenderer'

import { filterRowData, capitalizeStr } from '../../../../utils/utilities'
import { Creators as AppActions } from '../../../../redux/reducers/app'
import { Creators as NetworkCreationActions } from '../../../../redux/reducers/api/networkCreationManagement'
import { Creators as NavigationActions } from '../../../../redux/reducers/navigation'
import _ from 'lodash'
import ImpactAnalysis from '../../impactAnalysis'
import HeaderLayout from "../../../../components/headers/headerLayout";

class NetworkCreationManagement extends PureComponent {


  constructor(props) {
    super(props);

    this.state = {
      actionMenuStyle: {},
      // rowData: {},
      // rowIndex: null,
      showActionsMenu: false,
      gridApi: null,
    };
  }

  componentDidMount() {
    const { actions, screen } = this.props
    // TODO FIX THIS. SO NO MORE SETTIMEOUT
    if (screen === 'edits')
      setTimeout(() => actions.getAllNetworkEditsData({ doc_type: 'global_network_edit' }), Config.COMPONENT_DATA_LOAD_TIME)
    else
      setTimeout(() => actions.getAllNetworkTiersData({ doc_type: 'global_network_tier' }), Config.COMPONENT_DATA_LOAD_TIME)
  }

  onImpactAnalysisClick = (data) => {
    const { actions, screen } = this.props
    actions.setModalComponent({
      modalProperties: {
        size: 'large',
      },
      contents: <ImpactAnalysis
        data={data}
      />,
    })
    actions.toggleModal()
  }

  _onGridBodyScroll = (params) => {
    const { state } = this;
    const { showActionsMenu } = state;

    if (showActionsMenu) {
      this.setState((prevState) => ({
        showActionsMenu: false,
        actionMenuStyle: {
          ...prevState.actionMenuStyle,
          top: "-9999px",
          left: "9999px",
        },
      }));
      params.api.resetRowHeights();
    }
  };

  render() {
    const { props } = this
    const { state, actions, createLink, screen } = props
    const { allNetworkData } = state
    const headers = [
      'doc_id', 'network_name', 'version', 'start_date', 'end_date', 'status', 'action',
    ]
    const headerMapping = {
      doc_name: 'network_name',
      doc_version: 'version',
      effective_start_date: 'start_date',
      effective_end_date: 'end_date',
    }
    const activeScreen = screen !== 'edits' ? 'networkTier' : 'networkEdits'
    const activeLink = screen !== 'edits' ? 'generate-network-tier' : 'generate-network-edit'
    const cellRendererParams = {
      doc_id: {
        hide: true,
      },
      network_name: {
        cellRenderer: LinkRenderer,
        path: createLink,
        searchArgs: { doc_id: 'doc_id', doc_version: 'version' },
        state: { editMode: false, doc_id: 'doc_id' },
      },
      status: {
        cellRenderer: ColorCodedStatusRenderer,
      },
      action: {
        cellRenderer: NewDrugPopupCellRenderer,
        searchArgs: {
          approvalParamsMapping: { tier_id: 'id', name: 'tier_name', version: 'tier_version' },
          doc_type: screen === 'edits' ? 'global_network_edit' : 'global_network_tier',
          moduleViewDrugs: 'tier',
          onApproveClick: actions.approveTier,
          onNewVersionClick: actions.createNewVersion,
          onPublishClick: actions.publishDocument,
          onImpactAnalysisClick: this.onImpactAnalysisClick,
          path: createLink,
          qsArgs: { network_tier: 'network_tier', doc_id: 'doc_id', doc_version: 'version'},
          state: { doc_id: 'doc_id' },
          screen: activeScreen,
        },
      },
    }
    const gridData = allNetworkData && allNetworkData.length ? allNetworkData.map((d) => ({
      ...filterRowData(d, headers, headerMapping),
    })) : []

    const gridOptions = {
      domLayout: "autoHeight",
      autoSizeStrategy: {
        type: "fitCellContents",
      },
    };

    return (
      <div className="networkTierCreationManagement">
        <BreadCrumb {...this.props} />

        <HeaderLayout
          heading={`Pharmacy Network ${capitalizeStr(screen)} Management`}
          nameofButton={`Create New Network ${capitalizeStr(screen)}`}
          link={activeLink}
        />
        
        <div className="tabsContainer">
          <SubmenuNav active={activeScreen} />
        </div>

        <div className="agGrid content">
          <FliptGrid
            data={gridData}
            headers={headers}
            gridOptions={gridOptions}
            cellRendererParams={cellRendererParams}
            onBodyScroll={this._onGridBodyScroll}
            pagination={true}
            rowBuffer={gridData.length * 10}
            handleGridSize={true}
          />
        </div>
      </div>
    )
  }
}

const NetworkTier = (props) => {
  return <NetworkCreationManagement
    {...props}
    createLink='/generate-network-tier'
    screen='tier'
  />
}

const mapTierStateToProps = (state) => ({
  state: {
    allNetworkData: state.networkTierCreation.allNetworkTiersData,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
    ...NavigationActions,
    ...NetworkCreationActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

const NetworkEdits = (props) => {
  return <NetworkCreationManagement
    {...props}
    createLink='/generate-network-edit'
    screen='edits'
  />
}

const mapEditStateToProps = (state) => ({
  state: {
    allNetworkData: state.networkTierCreation.allNetworkEditsData,
  },
})

export const NetworkTierCreationManagement = connect(mapTierStateToProps, mapDispatchToProps)(NetworkTier)
export const NetworkEditsCreationManagement = connect(mapEditStateToProps, mapDispatchToProps)(NetworkEdits)
