import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import './styles.scss'
import Config from '../../../config'
import BreadCrumb from '../../../components/breadCrumb'
import ClaimTable from '../../../components/fliptGrid'
import FliptButton from '../../../components/form/fliptButton'
import FliptDatePicker from '../../../components/form/fliptDatePicker'
import FliptDropdown from '../../../components/form/fliptDropdown'
import FliptInput from '../../../components/form/fliptInput'
import { ColorCodedStatusRenderer, CheckboxRenderer, PHILoader } from '../../../components/fliptGrid/cellRenderers'
import { Creators as ApiActions } from '../../../redux/reducers/api/claimErrorManagement'
import { Creators as AppActions } from '../../../redux/reducers/app'
import { filterRowData, buildDropdownOptions } from '../../../utils/utilities'

import {
  createQueryString, parseQueryString, convertStrToDateObj,
} from '../../../utils/utilities'
import moment from 'moment'

class ClaimErrorManagement extends Component {
  constructor(props) {
    super(props)
    this.state = {
      form: {
        error_type: '',
        auth_id: '',
        start_date: '',
        end_date: '',
        correction_step: '',
        corrective_actions: ["RESTACK", "REPROCESS"]
      },
      reRender: 0,
      agGridRef: React.createRef(),
    }
  }

  componentDidMount() {
    const { history } = this.props
    const { location } = history

    if (!location) return
    const { form, agGridRef } = this.state

    this._qsToFormData()
    history.location.search = createQueryString(form)

    agGridRef.current.api.refreshServerSideStore({ route: null, purge: true }) // to refresh the grid server side data
  }

  _qsToFormData = () => {
    const { history } = this.props
    const { form } = this.state
    const qs = parseQueryString(history.location.search)

    Object.keys(qs).forEach((key) => {
      form[key] = qs[key]
    })
    this.setState({ form })
  }

  handleClick = () => {
    const { form, agGridRef } = this.state
    const { history, actions } = this.props
    if (!form.start_date || !form.end_date){
      form['start_date'] =  moment().subtract(7, 'days').toDate()
      form['end_date'] = moment().add(1, 'day').toDate()
    }
    let { auth_id } = form;
    // 13 is the maximum length of auth_id.
    const auth_id_length = 13 - auth_id.length;

    if (auth_id.length > 0 && auth_id.length < 13) {
      for (let i = 0; i < auth_id_length; i++) {
        auth_id = '0' + auth_id;
      }
    } else if (auth_id_length < 0) {
      auth_id = auth_id.substring(auth_id_length * -1);
    }
    form['auth_id'] = auth_id;
    this.setState({ form })
    history.location.search = createQueryString(form)
    agGridRef.current.api.refreshServerSideStore({ route: null, purge: true }) // to refresh the grid server side data
  }

  handleAction = () => {
    // const { form, agGridRef } = this.state
    // const { history, actions } = this.props
    // if (!form.start_date || !form.end_date){
    //   form['start_date'] =  moment().subtract(7, 'days').toDate()
    //   form['end_date'] = moment().add(1, 'day').toDate()
    // }
    // let { auth_id } = form;
    // // 13 is the maximum length of auth_id.
    // const auth_id_length = 13 - auth_id.length;

    // if (auth_id.length > 0 && auth_id.length < 13) {
    //   for (let i = 0; i < auth_id_length; i++) {
    //     auth_id = '0' + auth_id;
    //   }
    // } else if (auth_id_length < 0) {
    //   auth_id = auth_id.substring(auth_id_length * -1);
    // }
    // form['auth_id'] = auth_id;
    // this.setState({ form })
    // history.location.search = createQueryString(form)
    // agGridRef.current.api.refreshServerSideStore({ route: null, purge: true }) // to refresh the grid server side data
  }

  handleClear = () => {
    const { form, reRender = 0, agGridRef } = this.state
    Object.keys(form).forEach((key) => {
      form[key] = ''
    })
    this.setState({ form, reRender: reRender + 1 })

    const { history } = this.props
    // this._qsToFormData()
    // history.location.search = createQueryString(form)

    agGridRef.current.api.refreshServerSideStore({ route: null, purge: true })
  }

  updateField = (el, dropdown) => {
    const { form } = this.state
    const { name, value } = dropdown || el.currentTarget

    form[name] = value
    this.setState({ form })
  }


  render() {
    const { actions } = this.props
    const headers = [
      'select', 'auth_id', 'processed_date', 'date_of_service', 'group_id', 'transaction_response_status',
      'claim_status', 'sequenceNumber', 'domain', 'process_error_code'
    ]

    const headerMapping = {
      startDate: 'processed_date',
    }
    const { state: { user, claimsPerPage } } = this.props
    const { form, agGridRef } = this.state

    // const domainOptions = user.accounts_access.map((d) => ({
    //   key: d.id,
    //   text: d.id,
    //   value: d.id,
    // }))

    const filterParams = {
      /* eslint-disable-next-line */
      comparator: (filterLocalDateAtMidnight, cellValue) => {
        const dateAsString = cellValue
        if (dateAsString == null) return -1
        const dateParts = dateAsString.split(' ')[0].split('/')
        const cellDate = new Date(
          Number(dateParts[2]),
          Number(dateParts[0]) - 1,
          Number(dateParts[1]),
        )
        if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
          return 0
        }

        if (cellDate < filterLocalDateAtMidnight) {
          return -1
        }

        if (cellDate > filterLocalDateAtMidnight) {
          return 1
        }
      },
      browserDatePicker: true,
      filterOptions: ['inRange', 'equals'],
      defaultJoinOperator: 'OR',
      suppressAndOrCondition: false,
      inRangeInclusive: true,
    }
    const cellRendererParams = {
      select:{
        checkboxSelection: true,
        headerCheckboxSelection: true,
      },
      transaction_response_status: {
        overrideHeader: 'Response Status',
        cellRenderer: ColorCodedStatusRenderer,
      },
      claim_status: {
        overrideHeader: 'Claim Status',
      },
      processed_date: {
        overrideHeader: 'Processed Date',
      },
      date_of_service: {
        overrideHeader: 'Date Of Service',
      },
      sequenceNumber: {
        overrideHeader: 'Sequence Number',
      },
      auth_id: {
        overrideHeader: 'Auth Id',
      },
      domain: {
        overrideHeader: 'Domain',
      },
      process_error_code: {
        overrideHeader: 'Process Errors',
      },
    }

    const serverSideGridConfig = {
      rowModel: 'serverSide',
      serverSideStoreType: 'partial',
      cacheBlockSize: claimsPerPage,
      pagination: true,
      paginationPageSize: claimsPerPage,
    }
    const serverSideGridParams = {
      form,
      apiId: 'claim-error-management',
      sagaToCall: actions.getClaimErrorManagementData,
      headers,
      headerMapping,
    }
    return (
      <div id="claimErrorManagement">
        <BreadCrumb {...this.props} />
        <div className="header">
          <FliptButton name="Advanced Search" className="invisible" />
        </div>
        <div className="subhead">
          
          <FliptInput value={form.auth_id} placeholder="Auth ID" name="auth_id" onChange={this.updateField} />
          <FliptDatePicker placeholder="Start Date" name="start_date" onChange={this.updateField} value={convertStrToDateObj(form.start_date)} />
          <FliptDatePicker placeholder="End Date" name="end_date" onChange={this.updateField} value={convertStrToDateObj(form.end_date)} />

          <FliptButton name="Search" className="primary searchButton" onClick={this.handleClick} />
          <FliptDropdown placeholder="Select Corrective Actions" value={form.corrective_actions} onChange={this.updateField} single selection options={buildDropdownOptions(form.corrective_actions)} simple item name="correction_step" />
          <FliptButton name="Take Action" className="primary searchButton" onClick={this.handleAction} />
          <FliptButton name="Clear Filters" className="primary searchButton" onClick={this.handleClear} />
        </div>
        <div className="content">
          <ClaimTable
            headers={headers}
            cellRendererParams={cellRendererParams}
            serverSideGridConfig={serverSideGridConfig}
            serverSideGridParams={serverSideGridParams}
            agGridRef={agGridRef}
            filterOptions={['contains', 'equals']}
            rowSelection={'multiple'}
            doAutoSize
            suppressColumnVirtualisation
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  state: {
    data: state.claimErrorLookup.data,
    totalRows: state.claimErrorLookup.totalRows,
    claimsPerPage: state.claimErrorLookup.claimsPerPage,
    user: state.user,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...ApiActions,
    ...AppActions
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClaimErrorManagement)
