import Immutable from 'seamless-immutable'
import { createActions, createReducer } from 'reduxsauce'

export const INITIAL_STATE = Immutable({
    data: []
})

export const updateClaimHistoryDataReducer = (state = INITIAL_STATE, { payload }) => ({
    ...state,
    data: payload.data
})

export const clearReducer = () => INITIAL_STATE

export const { Types, Creators } = createActions({
    updateClaimHistoryData: ['payload'],
    getClaimHistoryData: ['payload'],
    clearReducer: null,
})

const HANDLERS = {
    [Types.UPDATE_CLAIM_HISTORY_DATA]: updateClaimHistoryDataReducer,
    [Types.CLEAR_REDUCER]: clearReducer,
}

export default createReducer(INITIAL_STATE, HANDLERS)