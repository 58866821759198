import React, { useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import './styles.scss'
import FliptDropdown from '../../../../../../components/form/fliptDropdown'
import { Creators as AppActions } from '../../../../../../redux/reducers/app'

import FileImport from '../../../../bulkReprocessingRestacking/batchInput/fileImport'
import FliptFileUpload from '../../../../../../components/v2/fliptFileUpload'

const BatchCriteria = (props) => {
	const { onUploadFile } = props
	const [fieldType, setFieldType] = useState('')
	const [currentFile, setCurrentFile] = useState(null)
	const [isUploaded, setIsUploaded] = useState(false)
	const { actions } = props
	const FIELD_TYPES = [
		{ key: '1', value: 'member_id', text: 'Member ID' },
		{ key: '2', value: 'auth_id', text: 'Auth ID' },
	]
	const handleFileChange = (file) => {
		setCurrentFile(file)
		setIsUploaded(false)
	}
	const uploadFile = () => {
		onUploadFile(currentFile, fieldType)
		setIsUploaded(true)
	}	
	const updateSelection = (el, dropdown) => {
		const { name, value } = dropdown
		setFieldType(value)
	}

	return (
		<div id="batch-input-section">
			<div className="batch-input-header">Batch Input</div>
			<hr />
			<div className="batch-input-container">
				<FliptDropdown
					options={FIELD_TYPES}
					value={fieldType}
					stylized
					name="field_type"
					onChange={(e, dropdown) => updateSelection(e, dropdown)}
					label="Field Type"
				/>
				{fieldType && (
					<FliptFileUpload handleFileChange={handleFileChange} uploadFile={uploadFile} />
				)}
			</div>
		</div>
	)
}

const mapDispatchToProps = (dispatch) => {
	const allActions = {
		...AppActions,
	}

	return {
		actions: bindActionCreators(allActions, dispatch),
	}
}

export default connect(null, mapDispatchToProps)(BatchCriteria)
