import React, { PureComponent } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Creators as groupManagementActions } from '../../../redux/reducers/api/groupManagement'

import './styles.scss'
import { Creators as AppActions } from '../../../redux/reducers/app'
import FliptGrid from '../../../components/fliptGrid';
import { LinkRenderer } from '../../../components/fliptGrid/cellRenderers'
import BreadCrumb from '../../../components/breadCrumb'

class GroupManagement extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            form: {
                start_date: '',
                end_date: ''
            }
        }
    }

    _updateForm = (el, dropdown) => {
        const { form } = this.state
        const { name, value } = dropdown || el.currentTarget
        form[name] = value
        this.setState({ form })
    }

    componentDidMount() {
        const { actions } = this.props
        actions.clearGroupMgmtData()
        actions.clearGroupMaintenanceData()
        actions.clearClientName()
        actions.getGroupMgmtDashboard()
    }


    createNewGroup = () => {
        this.props.history.push('/create-group', { editable: true })
    }
    handleIdClicked = (groupData) => {
        const { data } = groupData
        this.props.history.push('/group-maintenance', { client_name: data.client_name })
    }

    render() {
        const { state: { groupManagement } } = this.props
        const headers = ['client_name', 'client_number', 'cnt', 'update_date']
        const cellRendererParams = {
            client_name: {
                cellRenderer: LinkRenderer,
                onCellClicked: this.handleIdClicked,
                searchArgs: {},
                width: '250px'
            },
            cnt: {
                overrideHeader: 'Group Count'
            },
            update_date: {
                overrideHeader: 'Load Date'
            }
        }

        return (
            <div id="group-management">
                <div className='breadcrum-container'>
                    <BreadCrumb {...this.props} />
                </div>
                <div className='header'>
                    Group Management
                    <span className='link-text' onClick={this.createNewGroup}>
                        + Add New Manual Group
                    </span>
                </div>
                <div className='devider' />
                <div className='grid-container'>
                    <FliptGrid
                        headers={headers}
                        cellRendererParams={cellRendererParams}
                        data={groupManagement || []}
                    />
                </div>
            </div >
        )
    }
}

const mapStateToProps = (state) => ({
    state: {
        app: state.app,
        integrationMgmtDashboardData: state.integrationMgmt.integrationMgmtDashboardData,
        batchDetailData: state.bulkReprocessingRestacking.batchUpdateDetailData,
        groupManagement: state.groupManagement.groupMgmt
    },
})

const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...AppActions,
        ...groupManagementActions
    }

    return {
        actions: bindActionCreators(allActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupManagement)
