import React, { Component, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Icon } from 'semantic-ui-react'

import FliptGrid from '../../../../components/fliptGrid'
import { Creators as TimerConfigActions } from '../../../../redux/reducers/api/timerConfig'

import './styles.scss'
import BreadCrumb from '../../../../components/breadCrumb'
import { LinkRenderer } from '../../../../components/fliptGrid/cellRenderers'

const TimerSummary = (props) => {
    useEffect(() => {
        props.actions.getAllPATimerConfigurations()
    }, [])
    const { state } = props
    const {
        allPATimerConfigurationsData,
    } = state

    const headers = [
        'pa_timer_id',
        'policy_type',
        'priority',
        'hours_to_complete',
        'how_to_calculate_hours',
        "first_alert_hours",
        "second_alert_hours",
        "third_alert_hours",
        "days_to_return_info",
        "first_rmi_alert",
        "second_rmi_alert",
        "third_rmi_alert",
    ]
    const cellRendererParams = {
        pa_timer_id: {
            overrideHeader: "Timer ID",
            cellRenderer: LinkRenderer,
            path: '/timer-configuration',
            searchArgs: { pa_timer_id: 'pa_timer_id' },
            state: { editMode: true },
        },
        policy_type: {
            overrideHeader: "Policy Type",
        },
        hours_to_complete: {
            overrideHeader: "Hours to Complete",
        },
        how_to_calculate_hours: {
            overrideHeader: "Hours Calculation",
        },
        first_alert_hours: {
            overrideHeader: "PA 1st Alert Hours",
        },
        second_alert_hours: {
            overrideHeader: "PA 2nd Alert Hours",
        },
        third_alert_hours: {
            overrideHeader: "PA 3rd Alert Hours",
        },
        days_to_return_info: {
            overrideHeader: "RFI Days",
        },
        first_rmi_alert: {
            overrideHeader: "RFI 1st Alert",
        },
        second_rmi_alert: {
            overrideHeader: "RFI 2nd Alert",
        },
        third_rmi_alert: {
            overrideHeader: "RFI 3rd Alert",
        },
    }


    return (
        <div id="questionnaireBuilder">
            <BreadCrumb {...props} />

            <div className="header">
                Timer Summary
                <Link to={{ pathname: '/timer-configuration' }} >
                    <div className="create-pa-case" aria-hidden="true">
                        <Icon name="plus circle" size="tiny" />
                        Create New Timer
                    </div>
                </Link>
            </div>
            {
                allPATimerConfigurationsData.length > 0 ?
                    <div className="content">
                        <FliptGrid
                            data={allPATimerConfigurationsData}
                            headers={headers}
                            cellRendererParams={cellRendererParams}
                        />
                    </div> : ""
            }
        </div>
    )
}

const mapStateToProps = (state) => ({
    state: {
        app: state.app,
        allPATimerConfigurationsData: state.timerConfig.allPATimerConfigurationsData || []
    },
})

const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...TimerConfigActions,
    }

    return {
        actions: bindActionCreators(allActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TimerSummary)
