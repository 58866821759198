import React from 'react'

import FliptDropdown from '../../../../../components/form/fliptDropdown'
import './styles.scss'

export default function RolesModal({ roles }) {
  const roleOptions = roles.map((role) => ({
    key: role.id,
    text: role.name,
    value: role.id,
  }))

  const selectOption = (e, el) => {
    const selectedRole = roles.find((role) => role.id === el.value)
    const header = document.querySelector('#rolesModal > .contents > h3')
    const contents = document.querySelector('#rolesModal > .contents > p')

    header.innerText = selectedRole.name
    contents.innerText = selectedRole.description
  }

  return (
    <div id="rolesModal">
      <div className="header">
        <h1>
          Role Descriptions
        </h1>
        <FliptDropdown placeholder="Select Role" search selection options={roleOptions} onChange={selectOption} />
      </div>
      <div className="contents">
        <h3 />
        <p />
      </div>
    </div>
  )
}
