import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import './styles.scss'
import BreadCrumb from '../../../components/breadCrumb'
import DrugDetails from './drugDetails'
import FliptDatePicker from '../../../components/form/fliptDatePicker'
import FliptDropdown from '../../../components/form/fliptDropdown'
import FliptInput from '../../../components/form/fliptInput'
import statesJSON from '../../../config/constants/states'
import { Creators as AppActions } from '../../../redux/reducers/app'
import { Creators as GetPricingActions } from '../../../redux/reducers/api/getPricing'
import { Creators as MemberLookupActions } from '../../../redux/reducers/api/memberLookup'
import { convertStrToDateObj } from '../../../utils/utilities'

const statesData = Object.entries(statesJSON).map(([k, v]) => ({
  key: k,
  text: `${k} - ${v}`,
  value: k,
}))

const withinOptions = [
  { key: 1, text: '10 Miles', value: 1 },
  { key: 2, text: '5 Miles', value: 2 },
]

class PriceCheck extends Component {
  constructor(props) {
    super(props)

    const { member_info } = props.location.state

    this.state = {
      member_info,
      form: {
        date_of_service: new Date(Date.now()),
        date_of_birth: member_info?.date_of_birth || '',
        days_of_supply: '',
        domain: {
          domain_name: member_info?.domain_name || '',
        },
        drug_name: null,
        gpi: null,
        gppc: '',
        application: "app_web",
        contract_type: ["client"],
        location: {
          address_1: member_info?.home_address_1 || '',
          address_2: member_info?.home_address_2 || '',
          city: member_info?.city || '',
          state: member_info?.state || '',
          zip: member_info?.zip || '',
          geo: {
            lat: '',
            lng: '',
          },
        },
        plan_name: member_info?.plan_name || '',
        quantity: '',
        rx_flipt_person_id: member_info?.flipt_person_id || '',
        pharmacy_within: member_info?.pharmacy_within || '10 Miles',
        primary_flipt_person_id: member_info?.parent_id || member_info?.flipt_person_id || '',
        intent_created_by: member_info?.parent_id || member_info?.flipt_person_id || ''
      },
    }
  }

  componentDidMount() {
    try {
      const { actions, history, history: { location } } = this.props
      const { member_info: { domain_name, plan_name } } = location.state

      actions.clearDrugDetails()

      if (!plan_name || !domain_name) {
        history.goBack()
        const transitionalPortal = {
          header: 'Price Check Failed',
          copy: 'Member requires a valid domain and/or plan name',
        }
        actions.displayTransitionalPortal(transitionalPortal)
      }
    } catch (err) {
      const { history } = this.props
      history.push({ pathname: '/home' })
    }
  }

  _updateForm = (e, elem) => {
    const { form } = this.state

    if (e?.currentTarget) {
      const { name, value } = e.target
      const locationProps = ['address_1', 'address_2', 'city', 'lat', 'lng', 'state', 'zip']

      if (locationProps.find((el) => el === name)) {
        if (name === 'lng' || name === 'lat') {
          form.location.geo[name] = value
        } else {
          form.location[name] = value
        }
      }
    }

    if (elem?.name === 'date_of_service') {
      form.date_of_service = elem.value
      this.setState(form)
    }
    if (elem?.name === 'pharmacy_within') {
      form.pharmacy_within = withinOptions[elem.value - 1].text
      this.setState(form)
    }
  }

  _resetFormValues = () => {
    const { form } = this.state

    const newForm = {
      ...form,
      quantity: '',
      days_of_supply: '',
    }

    this.setState({ form: newForm })
  }

  _setDrugName = (drug_name) => {
    const { form } = this.state

    form.drug_name = drug_name
    form.quantity = ''
    form.days_of_supply = ''

    this.setState({ form })
  }

  _getPrices = () => {
    const gppc_data = this.props.state.drugDetails.drug_forms?.[0].drugs
    let { form } = this.state

    if (gppc_data.find((f) => (f.gpi === form.gpi) && (f.quantity === parseInt(form.quantity)))) {
      form.gppc = gppc_data.find((f) => (f.gpi === form.gpi) && (f.quantity === parseInt(form.quantity))).gppc
    }

    if (!form.gppc) {
      form.gppc = gppc_data
        .find((f) => (f.gpi === form.gpi) && (f.quantity > parseInt(form.quantity))).gppc
    }
    if (!form.gppc) {
      const filtered_gpi_list = gppc_data.filter((f) => (f.gpi === form.gpi))
      form.gppc = filtered_gpi_list[filtered_gpi_list.length - 1]["gppc"]
    }
    const { actions } = this.props
    const { member_info: { domain_name, plan_name } } = this.state

    // this._resetFormValues()
    actions.getDrugPrices(form)
    setTimeout(() => {
      actions.getDrugDetails({
        domain_name,
        drug_name: form.drug_name,
        plan_name,
      })
    }, 200)
  }

  _updateDrugConfiguration = (el, dropdown) => {
    const { form } = this.state
    const { name, value } = dropdown || el.currentTarget
    if (typeof form[name] === 'undefined') return

    this.setState({
      form: {
        ...form,
        [name]: value,
      },
    })
  }

  render() {
    const {
      form,
      form: {
        date_of_service,
        location,
      },
      member_info,
    } = this.state

    return (
      <div id="priceCheck">
        <BreadCrumb {...this.props} />
        <div className="header">
          <h1>Price Check</h1>
        </div>
        <div className="content">
          <section className="details-section spacing">
            <div className="price-inputs">
              <span>Flipt Person Id</span>
              <FliptInput name="member_id" disabled label="Member Id" value={member_info?.member_id || ''} />
            </div>
            <div className="price-inputs">
              <span>First Name</span>
              <FliptInput disabled label="First Name" value={member_info?.first_name || ''} />
            </div>
            <div className="price-inputs">
              <span>Last Name</span>
              <FliptInput disabled label="Last Name" value={member_info?.last_name || ''} />
            </div>
            <div className="price-inputs dob">
              <span>Date of Birth</span>
              <FliptInput name="Date of birth" disabled value={member_info?.date_of_birth || ''} />
            </div>
          </section>

          <section className="service-details spacing border-line shadow">
            <span className="subtitle">Service Details</span>
            <div className="price-intputs-wrap">
              <div className="price-inputs">
                <span>Pharmacy Within</span>
                <FliptDropdown defaultValue={1} name="pharmacy_within" options={withinOptions} label="Pharmacy Within" onChange={this._updateForm} />
              </div>
              <div className="price-inputs">
                <span>Date of Service</span>
                <FliptDatePicker value={convertStrToDateObj(date_of_service)} name="date_of_service" onChange={this._updateForm} />
              </div>
            </div>
            <div className="price-intputs-wrap">
              <div className="price-inputs">
                <span>Address</span>
                <FliptInput required name="address_1" defaultValue={location.address_1} onChange={this._updateForm} />
              </div>
              <div className="price-inputs">
                <span>City</span>
                <FliptInput required name="city" defaultValue={location.city} onChange={this._updateForm} />
              </div>
              <div className="price-inputs">
                <span>State</span>
                <FliptDropdown required name="state" defaultValue={location.state} options={statesData} />
              </div>
              <div className="price-inputs">
                <span>Zip Code</span>
                <FliptInput required name="zip" defaultValue={location.zip} onChange={this._updateForm} />
              </div>
            </div>
          </section>

          <DrugDetails
            formData={form}
            getPrices={this._getPrices}
            member_info={member_info}
            resetFormValues={this._resetFormValues}
            setDrugName={this._setDrugName}
            updateDrugConfiguration={this._updateDrugConfiguration}
            updateFormData={this._updateForm}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  state: {
    drugDetails: state.getPricing.drugDetails,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
    ...GetPricingActions,
    ...MemberLookupActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PriceCheck)
