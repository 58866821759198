import React, { useState, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as _ from 'lodash'
import FliptGrid from '../../../../../components/fliptGrid'
import BreadCrumb from '../../../../../components/breadCrumb'
import FliptButton from '../../../../../components/form/fliptButton'
import FliptDropdown from '../../../../../components/form/fliptDropdown'
import { Creators as DrugManagementActions } from '../../../../../redux/reducers/api/drugManagement'
import ImpactedObject from './impactedObject'
import ApproveWithNotes from '../popup/approveWithNotes'
import { Creators as AppActions } from '../../../../../redux/reducers/app'
import ApproveCellRenderer from '../popup/approveCellRenderer'
import Config from '../../../../../config'
import { ColorCodedStatusRenderer } from '../../../../../components/fliptGrid/cellRenderers'
import {
  parseQueryString,
} from '../../../../../utils/utilities'
import { Accordion, Icon } from 'semantic-ui-react'
import '../styles.scss'

const DrugInformation = (props) => {
  const { history } = props
  const { file_id, ndc: headerNdc } = parseQueryString(history.location.search)
  const [selections, setSelections] = useState([])
  const onActionClicked = (index, rowData, type) => {
    const {
      change_type,
      drug_name,
      new_value,
      old_value,
    } = rowData
    const ndc = selections.map(x => ({
      change_type: x.change_type,
      drugName: x.drug_name,
      newValue: x.new_value,
      oldValue: x.old_value,
      ndc: x.ndc,
    }) )
    const changeInfo = {
      change: change_type,
      drugName: drug_name,
      newValue: new_value,
      oldValue: old_value,
      ndc,
    }
    actions.setModalComponent({
      modalProperties: {
        size: 'medium',
      },
      contents: <ApproveWithNotes {...changeInfo} onSave={onSave} />,
    })
    if (type === 0) {
      return actions.dmApproveChanges({ file_id, notes: '', ...changeInfo })
    }
    return actions.toggleModal()
  }

  const onSave = ({ ndc, notes, newValue, change }) => {
    actions.dmApproveChanges({ file_id, ndc, notes, newValue, change })
    actions.toggleModal()
  }
  const { state, actions } = props
  const { drugInformation: dat = [], drugApprovalFreeze, reloadPages } = state
  
  const [bulkActions, setBulkActions] = useState(null)
  const bulkSelectOptions = ['approve without notes', 'approve with notes'].map(e => ({ key: e, text: e, value: e, disabled: !!drugApprovalFreeze }))
  const headers = [
    'select',
    'action',
    'clinical_priority',
    'ndc',
    'drug_name',
    'gpi',
    'change_type',
    'old_value',
    'new_value',
    'status',
    'approval_date',
    'approved_by',
  ]
  const cellRendererParams = {
    select: {
      overrideHeader: '',
      width: 5,
      headerCheckboxSelection: true,
      checkboxSelection: (row) => (row.data.status === 'Need Review'),
      showDisabledCheckboxes: true,
      headerCheckboxSelectionFilteredOnly: true,
    },
    action: {
      cellRenderer: ApproveCellRenderer,
      state: {
        onClick: onActionClicked,
        drugApprovalFreeze,
      },
      width: 100,
    },
    clinical_priority: {
      cellRenderer: ColorCodedStatusRenderer,
      width: 200,
      overrideHeader: 'Priority',
    },
    ndc: {
      width: 200,
      overrideHeader: 'NDC',
    },
    drug_name: {
      width: 200,
      overrideHeader: 'Drug Name',
    },
    gpi: {
      width: 200,
      overrideHeader: 'GPI',
    },
    change_type: {
      width: 200,
      overrideHeader: 'Change',
    },
    old_value: {
      width: 200,
      overrideHeader: 'Old Value',
    },
    new_value: {
      width: 200,
      overrideHeader: 'New Value',
    },
    status: {
      cellRenderer: ColorCodedStatusRenderer,
      width: 200,
      overrideHeader: 'Review Status',
    },
    approval_date: {
      width: 200,
      overrideHeader: 'Date/Time Approved',
    },
    approved_by: {
      width: 200,
      overrideHeader: 'User',
    },
  }
  const [data, setData] = useState(dat)
  const [selectedGroup, setSelectedGroup] = useState('')
  const getAccordian = (title, list, index) => {
    const key = `${title}-${index}`
    return (<div className="program-condition-level">
      <Accordion.Title 
      active={selectedGroup === key}
      index={key}
      onClick={(e, titleProps)=>{
        const { index:selectedAccordian } = titleProps
        setSelectedGroup(selectedAccordian === selectedGroup ? '': selectedAccordian)
      }}
      >
        <Icon name="dropdown" />
        {title.toUpperCase()}
      </Accordion.Title>
      <Accordion.Content 
      active={selectedGroup === key}
      >
        <div className="program-conditions">{list.map((ele) => <><ImpactedObject {...ele} /><hr/></>)}</div>
      </Accordion.Content>
    </div>)
  }
  const groupObjects = (data) => {
    const group = data?.objects?.reduce((object, ele) => {
      const { module } = ele
      if(!(module in object)){
        object[module] = []
      }
      object[module].push(ele)
      return object
    }, {})
    return group && (
      <Accordion className="program-condition-levels-accordion" styled>
        {Object.keys(group).map((title, index) => getAccordian(title, group[title], index))}
      </Accordion>
    )
  }
  useEffect(() => {
    if (dat && !_.isEqual(data, dat)) {
      setData(dat)
    }
  }, [dat])
  useEffect(() => {
    setTimeout(() => actions.getDmDrugInformationData({ file_id, ndc: headerNdc }), Config.COMPONENT_DATA_LOAD_TIME)
  }, [reloadPages])

  const onSelectionChanged = (data) => {
    const selectedRows = data.api.getSelectedRows()
    const filterdRow = selectedRows.filter(x=> x.status === 'Need Review')
    setSelections(filterdRow)
  }

  const handleBulkActions = () =>{
    
    const ndc = selections.map(x => ({
      change_type: x.change_type,
      drugName: x.drug_name,
      newValue: x.new_value,
      oldValue: x.old_value,
      ndc: x.ndc,
    }) )
    if(bulkActions === 'approve with notes'){
      actions.setModalComponent({
        modalProperties: {
          size: 'medium',
        },
        contents: <ApproveWithNotes multipleChanges={true} ndc={ndc} onSave={onSave} />,
      })
      return actions.toggleModal()
    }
    setSelections([])
    return actions.dmApproveChanges({ file_id, notes: '', ndc })
  }

  return (
    <div id="planDesignManagement">
      <BreadCrumb {...props} />
      <div className="header">
        Drug Information
      </div>
      <div className="content">
        <FliptGrid rowSelection={'multiple'} onSelectionChanged={onSelectionChanged} data={data?.drugs || []} headers={headers} cellRendererParams={cellRendererParams} />
        <div className='header-actions'>
          {(!_.isEmpty(selections)) && <div className='header-bulkactions'>
            <FliptDropdown placeholder="Bulk Actions" label="" name="bulk_actions" value={bulkActions} options={bulkSelectOptions} onChange={(e, d) => setBulkActions(d.value)} stylized />
            <FliptButton disabled={(bulkActions === null || selections.length === 0)} className="closeButton" compact name="Approve" onClick={handleBulkActions} />
          </div>}
        </div>
      </div>
      <div className="header">
        {(data?.objects?.length > 0) ? 'Impacted Objects' : ''}
      </div>
      <div id="impactedObjects" className="section-content">
        { groupObjects(data) }
      </div>
      <div id="close-button">
        <FliptButton className="closeButton" compact name="Close" onClick={() => history?.goBack()} />
      </div>
    </div>
  )
}
const mapStateToProps = (state) => ({
  state: {
    drugInformation: state.drugManagement.drugInformation,
    drugApprovalFreeze: state.drugManagement.drugApprovalFreeze,
    reloadPages: state.drugManagement.reloadPages,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...DrugManagementActions,
    ...AppActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DrugInformation)
