import './styles.scss'

import * as _ from 'lodash'
import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { changeNodeAtPath } from '@nosferatu500/react-sortable-tree'
import { connect } from 'react-redux'

import BreadCrumb from '../../../components/breadCrumb'
import FliptButton from '../../../components/form/fliptButton'
import FliptCheckbox from '../../../components/form/fliptCheckbox'
import FliptDropdown from '../../../components/form/fliptDropdown'
import FliptInput from '../../../components/form/fliptInput'
import FliptLabel from '../../../components/form/fliptLabel'
import FliptRadio from '../../../components/form/fliptRadio'
import FliptTree from '../../../components/fliptTree'
import PROGRESSION from '../../../config/constants/progression'
import { Creators as AppActions } from '../../../redux/reducers/app'
import { Creators as RPMActions } from '../../../redux/reducers/rpm'

class GlobalObjectConfiguration extends Component {
	constructor(props) {
		super(props)

		this.state = {
			form: {
				object_type: '',
				object_id: [],
				is_hierarchy_global: false,
				hierarchy: [],
				searchString: '',
			},
			objectTypeOptions: props?.state?.approvalDocumentTypes ?? [],
			documentNameOptions: [],
			allDocuments: [],
			allDocumentMapping: {},
			searchFocusIndex: 0,
			searchFoundCount: null,
			organization: [],
			newLeaf: {
				title: '',
				id: '',
				line_of_business: '',
				effective_start_date: '',
				effective_end_date: '',
				subtitle: '',
			},
			focusedLeaf: '',
			isAdd: false,
			selectedCAG: {
				organization: null,
				client: null,
				carrier: null,
				account: null,
				group: null,
			},

		}
	}

	componentDidMount() {
		const { props: { actions, userHierarchy } } = this
		if (userHierarchy) {
			this._updateTree(userHierarchy)
		}
		actions.rpmGetApprovalDocuments()
		this._updateState()
	}

	componentDidUpdate(prevProps) {
		const { props } = this
		const prevState = prevProps.state
		const currState = props.state

		if (_.isEqual(prevState, currState)) return
		if (!_.isEqual(prevState?.allDocumentData, currState?.allDocumentData)
			|| !_.isEqual(prevState?.approvalDocumentTypes, currState?.approvalDocumentTypes)
			|| !_.isEqual(prevState?.userHierarchy, currState?.userHierarchy)) this._updateState()
	}

	_updateState = () => {
		const { state: { approvalDocumentTypes, allDocumentData, userHierarchy } } = this.props
		const allDocumentMapping = {}
		approvalDocumentTypes.forEach(x => allDocumentMapping[x] = [])
		allDocumentData.forEach((doc) => {
			if (approvalDocumentTypes.includes(doc?.module)) {
				allDocumentMapping[doc?.module].push(doc)
			}
		})

		if (userHierarchy) {
			this._updateTree(userHierarchy)
		}

		this.setState((prevState) => ({
			...prevState,
			objectTypeOptions: approvalDocumentTypes,
			allDocuments: allDocumentData,
			allDocumentMapping,
		}))
	}

	_updateTree = (hierarchy) => {
		let newTree = []
		const orgLevel = hierarchy[0]
		newTree.push({
			title: 'Available Organizations',
			id: 'TOP',
			node_key: 'topLevel',
			subtitle: 'TOP',
			active: true,
			children: []
		})
		// newTree.push({
		// 	title: orgLevel?.organization_name ?? '',
		// 	id: orgLevel?.organization_id ?? '',
		// 	node_key: orgLevel?.key,
		// 	subtitle: 'organization',
		// 	active: orgLevel?.active === undefined || orgLevel?.active,
		// 	children: [],
		// })
		// if (orgLevel?.children?.length > 0) {
		const newData = {
			id: 'TOP',
			children: _.cloneDeep(Object.values(hierarchy).map(x => x[0])),
		}
		this._convertToTree(newData, newTree[0])
		// }

		this.setState({ organization: newTree }, () => {
			const { state: { organization } } = this
			this.setState({ organization })
		})
	}

	getNodeKey = ({ treeIndex }) => treeIndex

	_convertToTree = (data, newTree) => {
		if (!data) return
		const keyData = Object.keys(data)
		if ((keyData.indexOf('id') > -1 || keyData.indexOf('name')) > -1 && keyData.length < 2) return
		data?.children?.forEach((element, i) => {
			newTree.children.push({
				title: element[element?.level + '_name'],
				id: element[element?.level + '_id'],
				subtitle: element?.level,
				node_key: element?.key,
				active: element.active === undefined || element.active,
				children: [],
			})
			this._convertToTree(element, newTree.children[i])
		})
	}

	_convertTreeToFlat = (data, newHierarchy) => {
		if (!data) return
		for (let i = 0; i < data.length; i++) {
			if (!data[i]) return
			const idx = PROGRESSION.indexOf(data[i]?.subtitle)
			if (idx !== -1) {
				if (data[i]?.selected) newHierarchy.push(data[i]?.node_key)
				this._convertTreeToFlat(data[i].children, newHierarchy)
			}
		}
	}

	mapListToDropdownOptions = (documentNames) => {
		if (!documentNames || documentNames.length < 1) return [{ key: 'None', text: 'None', value: 'None' }]
		return documentNames.map((x, i) => {
			if (typeof x === 'string') return { key: x, text: x, value: x }
			if (typeof x === 'object') {
				if (x?.name || x?.module_name) {
					const val = x?.name ?? x?.module_name
					return { key: val, text: val, value: x?.id ?? val }
				}
				if (x?.key && x?.text && x?.value) return { key: x?.key, text: x?.text, value: x?.value }
			}
			return { key: 'None', text: 'None', value: 'None' }
		})
	}

	_updateDocumentNameOptions = (module) => {
		const { state: { allDocumentMapping } } = this
		this.setState((prevState) => ({
			...prevState,
			documentNameOptions: this.mapListToDropdownOptions(allDocumentMapping[module])
		}))
	}

	_updateFields = (el, dateData) => {
		const { name, value } = dateData || el.currentTarget

		this.setState((prevState) => ({
			...prevState,
			form: {
				...prevState.form,
				[name]: value,
			},
		}))

		if (name === 'object_type') {
			this._updateDocumentNameOptions(value)
			this.setState((prevState) => ({
				...prevState,
				form: {
					...prevState?.form,
					object_id: []
				}
			}))
		}
	}

	_fetchDocumentNames = () => {
		const { actions } = this.props
		const { form: { object_type } } = this?.state
		if (object_type) actions.rpmGetApprovalDocuments({ module: [object_type] })
	}

	_submitForm = () => {
		const { props: { actions }, state: { form, organization, selectedCAG } } = this
		let newHierarchy = {}
		organization[0].children.forEach(org => {
			let newOrgHierarchy = []
			this._convertTreeToFlat([org], newOrgHierarchy)
			if (newOrgHierarchy?.length > 0) newHierarchy[Object.entries(this.props.state.userHierarchy).find(([key, value]) => value[0].organization_id === org.title)[0]] = newOrgHierarchy
		})
		const { carriers, accounts, groups } = this.getLevelsFromHierarchy(this?.state?.organization)
		actions?.rpmEditDocumentHierarchy({ ...form, hierarchy: newHierarchy, carrier: carriers[0], account: accounts[0], group: groups[0] })
	}

	onChange = (el) => {
		this.setState({ organization: el })
	}

	_changeActiveChildren = (node, active) => {
		if (!node) return
		for (let i = 0; i < node.length; i++) {
			if (!node[i].isAddButton) {
				node[i].active = active
				this._changeActiveChildren(node[i].children, active)
			}
		}
	}

	_changeActiveNode = (leaf) => {
		const { path, node } = leaf
		const active = !node.active
		node.active = active
		this._changeActiveChildren(node.children, active)

		this.setState((state) => ({
			organization: changeNodeAtPath({
				treeData: state.organization,
				path,
				getNodeKey: this.getNodeKey,
				newNode: node,
			}),
		}), () => { this._submitHierarchy() })
	}

	generateNodeProps = (leaf) => {
		const returnedProps = {}
		const { node } = leaf
		returnedProps.buttons = [
			<FliptCheckbox defaultChecked={node?.selected} onClick={() => { this._changeSelectedNode(leaf) }} />,
		]
		return returnedProps
	}

	_changeSelectedChildren = (node, selected) => {
		if (!node) return
		for (let i = 0; i < node.length; i++) {
			node[i].selected = selected
			this._changeSelectedChildren(node[i].children, selected)
		}
	}

	_changeSelectedNode = (leaf) => {
		const { path, node } = leaf
		const { actions } = this?.props
		const isBenefitPlanDoc = this?.state?.form?.object_type === "BENEFIT_PLAN_DOC"
		if (!node.selected && isBenefitPlanDoc) {
			const selectedCAG = this?.state?.selectedCAG[node?.subtitle]
			if (selectedCAG) {
				const transitionalPortal = {
					header: 'Only Select One',
					copy: `Please only select one ${node?.subtitle?.toUpperCase()} for a Benefit Plan`,
				}
				actions.displayTransitionalPortal(transitionalPortal)
				return
			}
		}
		const selected = !node.selected
		node.selected = selected
		if (!isBenefitPlanDoc) this._changeSelectedChildren(node.children, selected)
		this.setState((state) => ({
			organization: changeNodeAtPath({
				treeData: state.organization,
				path,
				getNodeKey: ({ treeIndex }) => treeIndex,
				newNode: node,
			}),
			selectedCAG: {
				...state.selectedCAG,
				[node?.subtitle]: selected ? node?.id : null,
			}
		}))
	}

	_getObjectName = (ids) => {
		if (!ids || ids.length === 0) return []
		const { props, state } = this
		return ids.map(id => state?.allDocumentMapping[state?.form?.object_type].find(x => x.id === id)?.module_name)
	}

	convertToPlusNotation = (val) => {
		if (!val || val?.length === 0) return ''
		if (typeof val === 'string') return val
		if (val?.length === 1) return val[0]
		else return `${val[0]} + ${val?.length - 1}`
	}

	getLevelsFromHierarchy = (organization) => {
		if (!organization || organization?.length === 0) return {}
		const listing = {
			top: [],
			organizations: [],
			clients: [],
			carriers: [],
			accounts: [],
			groups: [],
		}

		organization.forEach(t => {
			t.children.forEach(o => {
				if (o.selected) listing.organizations.push(o.title)
				o.children.forEach(cl => {
					if (cl.selected) listing.clients.push(cl.title)
					cl.children.forEach(ca => {
						if (ca.selected) listing.carriers.push(ca.title)
						ca.children.forEach(a => {
							if (a.selected) listing.accounts.push(a.title)
							a.children.forEach(g => {
								if (g.selected) listing.groups.push(g.title)
							})
						})
					})
				})
			})
		})
		return listing
	}

	setForm = (newForm) => {
		this.setState((prevState) => ({
			...prevState,
			form: newForm
		}))
	}

	render() {
		const {
			form, editMode, documentNameOptions, objectTypeOptions, organization,
			searchFocusIndex, searchFoundCount,
		} = this.state
		const {
			object_id,
			object_type,
			is_hierarchy_global,
			searchString
		} = form

		const customSearchMethod = ({ node, searchQuery }) => (
			searchQuery && node.title.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1
		)

		const searchCallback = (matches) => {
			this.setState({
				searchFoundCount: matches.length,
				searchFocusIndex:
					matches.length > 0 ? searchFocusIndex % matches.length : 0,
			})
		}

		const selectPrevMatch = () => {
			this.setState({
				searchFocusIndex:
					searchFocusIndex !== null
						? (searchFoundCount + searchFocusIndex - 1) % searchFoundCount
						: searchFoundCount - 1,
			})
		}

		const selectNextMatch = () => {
			this.setState({
				searchFocusIndex:
					searchFocusIndex !== null
						? (searchFocusIndex + 1) % searchFoundCount
						: 0,
			})
		}

		const { organizations, clients, carriers, accounts, groups } = this.getLevelsFromHierarchy(organization)

		return (
			<div id="globalObjectConfiguration">
				<section className="global-hierarchy-configuration-container spacing border-line shadow">
					<div className="breadCrumbContainer"><BreadCrumb {...this.props} /> {editMode ? <div className="breadCrumbLogo"></div> : ''}</div>
					<div className="header">Global Object Configuration</div>
					<div>
						<div className="column">
							<section className="global-object-configuration-inputs">
								<div className="labeled-dropdown-input">
									<span>Object Type Listing</span>
									<FliptDropdown className="labeled-dropdown" label="" name="object_type" value={object_type} options={this.mapListToDropdownOptions(objectTypeOptions)} selection onChange={this._updateFields} stylized />
								</div>
								<div className="labeled-dropdown-input">
									<span>Object Name</span>
									<FliptDropdown className="labeled-dropdown" label="" name="object_id" value={object_id || ''} options={documentNameOptions} selection onChange={this._updateFields} multiple stylized />
								</div>
								<div className="labeled-radio-input-container">
									<span>Define as Global?</span>
									<div className="labeled-radio-inputs">
										<FliptRadio className="radio-input" toggle={false} name='is_hierarchy_global' value={true} checked={is_hierarchy_global} onChange={this._updateFields} />
										<FliptLabel className="radio-label" description="" label="Yes" />
										<FliptRadio className="radio-input" toggle={false} name='is_hierarchy_global' value={false} checked={!is_hierarchy_global} onChange={this._updateFields} />
										<FliptLabel className="radio-label" description="" label="No" />
									</div>
								</div>
								<div className="global-object-selected-objects">
									<span className="header">Selected Object</span>
									<div className="row" disabled={true} >
										<div className="column">
											<div>
												<label for="selected_object_type_listing">Object Type Listing</label>
												<input id="selected_object_type_listing" type="text" value={object_type} name="selected_object_type_listing" disabled />
											</div>
											<div>
												<label for="selected_organizations">Organization</label>
												<input id="selected_organizations" type="text" value={this.convertToPlusNotation(organizations)} name="selected_organizations" disabled />
											</div>
											<div>
												<label for="selected_carriers">Carrier</label>
												<input id="selected_carriers" type="text" value={this.convertToPlusNotation(carriers)} name="selected_carriers" disabled />
											</div>
											<div>
												<label for="selected_groups">Group</label>
												<input id="selected_groups" type="text" value={this.convertToPlusNotation(groups)} name="selected_groups" disabled />
											</div>
										</div>
										<div className="column">
											<div>
												<label for="selected_obect_name">Object Name</label>
												<input id="selected_obect_name" type="text" value={this.convertToPlusNotation(this._getObjectName(object_id))} name="selected_obect_name" disabled />
											</div>
											<div>
												<label for="selected_clients">Client</label>
												<input id="selected_clients" type="text" value={this.convertToPlusNotation(clients)} name="selected_clients" disabled />
											</div>
											<div>
												<label for="selected_accounts">Account</label>
												<input id="selected_accounts" type="text" value={this.convertToPlusNotation(accounts)} name="selected_accounts" disabled />
											</div>
										</div>
									</div>
								</div>
								<div className="saveButton">
									<FliptButton name="Save" className="primary" onClick={this._submitForm} />
								</div>
							</section>
						</div>
						<div className="column">
							<div className="header">Flipt Hierarchy</div>
							<div>
								<form onSubmit={(event) => { event.preventDefault() }}>
									<FliptInput
										id="findBox"
										type="text"
										name="searchString"
										style={{ fontSize: '1rem' }}
										value={searchString}
										onChange={this._updateFields}
									/>
									<span className="formElement">
										<button
											type="button"
											disabled={!searchFoundCount}
											onClick={selectPrevMatch}
										>
											&lt;
										</button>
										<button
											type="submit"
											disabled={!searchFoundCount}
											onClick={selectNextMatch}
										>
											&gt;
										</button>
									</span>
									<span className="formElement">
										&nbsp;
										{searchFoundCount > 0 ? searchFocusIndex + 1 : 0}
										&nbsp;/&nbsp;
										{searchFoundCount || 0}
									</span>
								</form>
							</div>
							<div>
								<FliptTree
									treeData={organization}
									onChange={this.onChange}
									canDrag={false}
									generateNodeProps={this.generateNodeProps}
									searchMethod={customSearchMethod}
									searchQuery={searchString}
									searchFocusOffset={searchFocusIndex}
									searchFinishCallback={searchCallback}
									scrolling
									style={{ height: 600, width: 700 }}
								/>
							</div>
						</div>
					</div>
				</section>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	state: {
		allDocumentData: state?.rpm?.documents,
		approvalDocumentTypes: state?.app?.approvalDocumentTypes ?? [],
		userHierarchy: state?.user?.userHierarchy ?? [],
	}
})

const mapDispatchToProps = (dispatch) => {
	const allActions = {
		...AppActions,
		...RPMActions,
	}

	return {
		actions: bindActionCreators(allActions, dispatch),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(GlobalObjectConfiguration)