import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import './styles.scss'
import { Creators as AppActions } from '../../../../../redux/reducers/app'
import FliptTextarea from '../../../../../components/form/fliptTextarea'
import FliptDatePicker from '../../../../../components/form/fliptDatePicker'
import FliptButton from '../../../../../components/form/fliptButton'
import { convertStrToDateObj } from '../../../../../utils/utilities'
import * as Constants from '../../constants'

class ManualList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      input_list: '',
      start_date: '',
      end_date: '',
    }
  }

  handleInputChange = (el, dropdown) => {
    const { name, value } = dropdown || el.currentTarget
    if (name === 'end_date' && value) {
      value.setDate(value.getDate() + 1)
      value.setMilliseconds(value.getMilliseconds() - 1)
    }
    this.setState({ [name]: value })
  }

  handleGetClaims = () => {
    const { form, updateForm, field_type } = this.props
    const { input_list, start_date, end_date } = this.state
    let batch_input = input_list

    batch_input = batch_input.replace(/[\n\r|\n|\s]/g, '')
    batch_input = batch_input.split(',')

    Object.keys(form).forEach((i) => form[i] = '')
    Object.assign(form, {
      batch_input,
      batch_input_type: field_type,
      batch_input_start_date: start_date,
      batch_input_end_date: end_date,
      search_type: Constants.SEARCH_TYPE.BATCH_INPUT
    })
    updateForm({ form })
  }

  render() {
    const { field_type } = this.props
    const { start_date, end_date } = this.state
    return (
      <div id='manual-input-section'>
        <div className='manual-input-header'>
          Manual Input
        </div>
        <hr />

        <div className='manual-input-container'>
          <FliptTextarea
            name='input_list'
            label={'Add IDs'}
            placeholder='Please list notes'
            stylized
            onChange={this.handleInputChange}
          />
        </div>

        <div className='manual-input-dates'>
          {field_type !== 'auth_id' &&
            <FliptDatePicker
              placeholder='MM-DD-YYYY'
              name='start_date'
              label='Start Date'
              onChange={this.handleInputChange}
              value={convertStrToDateObj(start_date)}
              stylized />}
          {field_type !== 'auth_id' &&
            <FliptDatePicker
              placeholder='MM-DD-YYYY'
              name='end_date'
              label='End Date'
              onChange={this.handleInputChange}
              value={convertStrToDateObj(end_date)}
              stylized />}
          <FliptButton
            name='Get Claims'
            data-testid='manual-list-get-claims'
            disabled={(!start_date || !end_date) && field_type !== 'auth_id'}
            className='primary search-button'
            onClick={this.handleGetClaims} />
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

// export default connect(null, mapDispatchToProps)(ManualList)
export default ManualList
