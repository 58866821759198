import React, { Component } from "react";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import "./styles.scss";
import BreadCrumb from "../../../../components/breadCrumb";
import Config from "../../../../config";
import FliptButton from "../../../../components/form/fliptButton";
import FliptGrid from "../../../../components/fliptGrid";
import SubmenuNav from "../shared/navigation";
import {
  ButtonRenderer,
  LinkRenderer,
  DynamicLinkVersionContainerRenderer,
  ActionPopupMenuRenderer,
  ColorCodedStatusRendererUpdated,
  ColorCodedStatusRenderer
} from "../../../../components/fliptGrid/cellRenderers";
import { Creators as AppActions } from "../../../../redux/reducers/app";
import { Creators as FormularyCreationActions } from "../../../../redux/reducers/api/formularyCreationManagement";
import {
  filterRowData,
  calcGridRowHeight,
  sortByName,
} from "../../../../utils/utilities";
import HeaderLayout from "../../../../components/headers/headerLayout";

class Formulary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      actionMenuStyle: {},
      // rowData: {},
      // rowIndex: null,
      showActionsMenu: false,
      gridApi: null,
    };
  }

  componentDidMount() {
    const { actions } = this.props;
    // TODO FIX THIS. SO NO MORE SETTIMEOUT
    setTimeout(
      () => actions.getAllFormulariesData(),
      Config.COMPONENT_DATA_LOAD_TIME
    );
  }

  // componentDidUpdate(prevProps, prevState) {
  //   if (prevState.gridApi !== this.gridApi ) {
  //     this.gridApi.sizeColumnsToFit();
  //   }
  // }

  _getRowHeight = (params) => {
    return calcGridRowHeight(params.data?.tier_configuration, 30, 50);
  };

  // _onActionClick = (e, rowNode, api, rowIndex, data) => {
  //   increaseRowHeight({ node: rowNode, api })
  //   const { state } = this
  //   const { showActionsMenu } = state
  //   const totalRowHeight = calcGridRowHeight(state.rowData?.tier_configuration)
  //   let newShowActionsMenu = false
  //   let newTop = null
  //   let newLeft = null

  //   if ((state.rowIndex !== rowIndex) || !showActionsMenu) {
  //     newShowActionsMenu = true
  //     newTop = `${e.clientY + 10}px`
  //     newLeft = `${e.clientX - 10}px`

  //     if ((state.rowIndex !== rowIndex) && !!showActionsMenu) {
  //       if (rowIndex > state.rowIndex) {
  //         newTop = `${e.clientY - (250 - totalRowHeight)}px`
  //       }
  //       newLeft = `${e.clientX - 10}px`
  //     }
  //   } else {
  //     newTop = '-9999px'
  //     newLeft = '-9999px'
  //   }
  //   this.setState((prevState) => ({
  //     actionMenuStyle: {
  //       ...prevState.actionMenuStyle,
  //       top: newTop,
  //       left: newLeft,
  //     },
  //     rowData: data,
  //     rowIndex,
  //     showActionsMenu: newShowActionsMenu,
  //   }))
  // }

  _onGridBodyScroll = (params) => {
    const { state } = this;
    const { showActionsMenu } = state;

    if (showActionsMenu) {
      this.setState((prevState) => ({
        showActionsMenu: false,
        actionMenuStyle: {
          ...prevState.actionMenuStyle,
          top: "-9999px",
          left: "9999px",
        },
      }));
      params.api.resetRowHeights();
    }
  };

  checkActionType = (rowdata) => {
    if (rowdata && rowdata["qsArgs"]) {
      const doc_id = rowdata["doc_id"];
      const action = rowdata["qsArgs"]["selected_action"];

      //  copy object functionality will be implemented in future

      // if (action === "copy_object") {
      //   setShowPopupFlag(true);
      // } else 
      if (action === "send_for_review") {
        this.sendForReview(rowdata);
      }
    }
  };

  sendForReview = (data) => {
    const { actions } = this.props;
    const payload = {
      doc_id: data.id,
      module: "FORMULARY",
    };
    actions.formularySendForReview(payload, data);
  };

  render() {
    const { props } = this;
    const { state } = props;
    const { allFormulariesData } = state;

    const headers = [
      "name",
      "tier_configuration",
      "status",
      "version",
      "create_date",
      "updated_by",
      "last_updated",
      "actions",
      "id",
    ];
    const headerMapping = {
      create_date: "create_date",
      created_by: "author",
      module_name: "name",
      effective_start_date: "start_date",
      Author: "updated_by",
      tiers: "tier_configuration",
    };
    const cellRendererParams = {
      name: {
        cellRenderer: LinkRenderer,
        path: "/formulary",
        searchArgs: { doc_id: "id", },
        state: { editMode: false },
        minWidth: 330,
      },

      tier_configuration: {
        cellRenderer: DynamicLinkVersionContainerRenderer,
        overrideHeader: "Drug List Configuration",
        minWidth: 320,
        cellClass: "grid-Column",
        searchArgs: {
          doc_name: "doc_name",
          path: "/formulary-tier",
          label: { dynamic: "doc_name" },
          qsMap: { qsKey: "doc_id" },
          editMode: false,
        },
      },
      id: {
        hide: true,
      },
      status: {
        cellRenderer: ColorCodedStatusRendererUpdated,
        minWidth: 144,
        // maxWidth:200
      },
      version: {
        maxWidth: 150
      },
      actions: {
        cellRenderer: ActionPopupMenuRenderer,
        pinned: "right",
        cellClass: "actionsColumns",
        suppressMenu: true,
        minWidth: 100,
        maxWidth: 100,
        searchArgs: {
          onClick: (rowdata) => this.checkActionType(rowdata),
          path: "/formulary-creation-management",
          qsArgs: {
            doc_id: "id",
            action: "COPY_OBJECT",
            module: "FORMULARY",
            status: "status",
          },
        },
      },
    };

    let gridData = allFormulariesData.map((d) => ({
      ...filterRowData(d, headers, headerMapping),
    }));

    const gridOptions = {
      domLayout: "autoHeight",
      autoSizeStrategy: {
        type: "fitCellContents",
      },
    };

    return (
      <div className="formularyCreationManagement">
        <BreadCrumb {...this.props} />

        <HeaderLayout
          heading="Formulary Creation and Management"
          nameofButton="Create New Formulary"
          link="formulary"
        />
        <div className="tabsContainer">
          <SubmenuNav active="formulary" />
        </div>

        <div className="agGrid content">
          <FliptGrid
            cellRendererParams={cellRendererParams}
            data={
              gridData && gridData.length > 0 ? gridData.sort(sortByName) : []
            }
            gridOptions={gridOptions}
            getRowHeight={this._getRowHeight}
            headers={headers}
            onBodyScroll={this._onGridBodyScroll}
            pagination={true}
            rowBuffer={gridData.length * 10}
            suppressColumnVirtualisation
            onGridReadyCustom={this._onGridReady}
            handleGridSize={true}
          />
        </div>
        {/*
          <FliptActionMenu
            actionMenuStyle={actionMenuStyle}
            approvalParamsMapping={{ formulary_id: 'id', name: 'name' }}
            data={rowData}
            onApproveClick={actions.approveFormulary}
            qsArgs={{ doc_id: 'doc_id' }}
            approvalParamsMapping={{ doc_id: 'id', name: 'doc_name', version: 'doc_version' }}
            actionMenuStyle={actionMenuStyle}
          />
        */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  state: {
    allApprovedTiersData: state.formularyCreation.allApprovedTiersData,
    allFormulariesData: state.formularyCreation.allFormulariesData,
  },
});

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
    ...FormularyCreationActions,
  };

  return {
    actions: bindActionCreators(allActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Formulary);
