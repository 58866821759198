import React, { useEffect, Component, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment-timezone'

import { Creators as AppActions } from '../../../../../redux/reducers/app'
import { Creators as MemberLookupActions } from '../../../../../redux/reducers/api/memberLookup'
import { Creators as PriorAuthorizationCreationActions } from '../../../../../redux/reducers/api/priorAuthorizationManagement'

import { Icon } from 'semantic-ui-react'
import FliptButton from '../../../../../components/v2/fliptButton'
import FliptMemberSearch from '../../../../../components/fliptMemberSearch'

import * as Constants from '../../constants'

import { formatPhoneNumber } from '../../../../../utils/utilities'
import './styles.scss'


const MemberInfo = ({ state, actions, lockCase, isCaseInfoEditable }) => {
	const { paCaseDetails, memberLookup, user /*editMode, memberState */ } = state

	// if (paCaseDetails.member_information_validated) {
	// 	patient = paCaseDetails?.patient
	// 	patient_benefit = paCaseDetails?.patient_benefit
	// }

	let { patient, patient_benefit } = paCaseDetails

	const [searchForm, setSearchFrom] = useState({
		date_of_birth: '',
		first_name: '',
		last_name: '',
		date_of_birth: '',
		memberId: '',
	})
	const [memberData, setMemberData] = useState({})
	const [memberSearched, setMemberSearched] = useState(false)

	useEffect(() => {
		const { patient, patient_benefit } = paCaseDetails
		if (paCaseDetails.intake_type === 'INTENT' || paCaseDetails.intake_type === 'CLAIM REJECT') {
			setSearchFrom({
				...searchForm,
				memberId: patient.member_id
			})
		}
		setMemberData({ ...patient, ...patient_benefit })
		setMemberSearched(false)
	}, [paCaseDetails])

	const updateMemberDetails = () => {
		// const { planForm, requestStatus } = this.state
		// const { memberLookup, user } = this.props.state
		let lookupMemberData = memberLookup.member_details
		let benefit_details = memberLookup.benefit_details
		let current_plan_details = memberLookup?.plan_details?.current_plan || {}
		if (memberLookup.multiMemberLookup?.length) {
			const index = memberLookup.multiMemberLookup.findIndex(
				(data) => data.member_details.member_id === memberData.member_id
			)
			lookupMemberData = memberLookup.multiMemberLookup[index].member_details
			benefit_details = memberLookup.multiMemberLookup[index].benefit_details
		}
		if (!lookupMemberData) {
			const header = 'Fields Missing'
			const message = 'Please add a Member to proceed'
			this._showTransitionalPortal(header, message)
			return
		}
		const params = {
			request_type: 'member_details',
			...paCaseDetails,
			pa_case_id: paCaseDetails.pa_case_id,
			pa_case_status: paCaseDetails.pa_case_status,
			verification_section: 'member',
			first_name: user.first_name,
			last_name: user.last_name,
			review_technician: user.uuid,
			patient_benefit: {
				member_id: lookupMemberData.memberId || lookupMemberData.member_id || memberData.memberId || '',
				plan_name: lookupMemberData.plan_name || '',
				client: benefit_details?.client || '',
				carrier: current_plan_details.carrier,
				LOB: benefit_details?.lob || '',
				group: lookupMemberData.group || '',
				effective_date: lookupMemberData.coverage_start_date || '',
				term_date: lookupMemberData.coverage_end_date || '',
				contract_id: benefit_details?.contract_id || '',
				PBP: '',
				formulary_name: benefit_details?.formulary_name || '',
				PCP: benefit_details?.pcp || '',
				flipt_person_id: lookupMemberData.flipt_person_id,
				domain: lookupMemberData.domain_name,
			},
			patient: {
				first_name: lookupMemberData.first_name || memberData.first_name,
				last_name: lookupMemberData.last_name || memberData.last_name,
				dob: lookupMemberData.date_of_birth,
				home_address_2: lookupMemberData.home_address_2,
				city: lookupMemberData.city,
				state: lookupMemberData.state,
				zipcode: lookupMemberData.zip,
				home_address_1: lookupMemberData.home_address_1,
				phone_number: lookupMemberData.phone_number,
				gender: lookupMemberData.gender,
			},
		}
		// if pa_case_id is missing, we are creating a new case
		if (!paCaseDetails.pa_case_id) {
			params.pa_case_status = Constants.IN_PROCESS_STR
			params.intake_type = 'Manual'
			params.case_type = 'pa'
		}
		actions.updatePaCase(params)
		// set drug message to dull using updateDrugMsg? for the case where we change a member using edit
	}

	const addMember = () => {
		// const { searchForm, memberData } = this.state
		actions.setModalComponent({
			modalProperties: { size: 'large' },
			contents: (
				<FliptMemberSearch
					isPa
					updateMemberData={setMemberData}
					setMemberSearched={setMemberSearched}
					searchForm={searchForm}
				/>
			),
		})
		actions.toggleModal()
	}


	// console.log(paCaseDetails)
	const renderMemberEditOptions = () => {
		// const { memberSearched, lockCase, isCaseInfoEditable } = props.memberState
		let isVerifyMemberDisabled = !memberSearched || lockCase
		let memberButtonName = 'Find Member'
		if (paCaseDetails.member_information_validated) memberButtonName = 'Edit Member'
		// console.log(props.memberState)
		return (
			<div className="verify-edit-buttons-container">
				{paCaseDetails?.member_information_validated && !memberSearched && (
					<div className="validated-text">
						<Icon name="check circle" color="green" />
						Validated
					</div>
				)}
				{isCaseInfoEditable &&
					<>
						<FliptButton
							disabled={isVerifyMemberDisabled}
							className="primary"
							name="Verify Member"
							onClick={updateMemberDetails}
						/>
						<FliptButton disabled={lockCase} className="primary" name={memberButtonName} onClick={addMember} />
					</>
				}
			</div>
		)
	}

	const displayMemberErrorMessage = () => {
		const { transition_message, member_information_validated } = paCaseDetails
		let errorMessage = ''
		if (memberData?.effective_date) {
			const date = moment().format('YYYY-MM-DD');
			const start = moment(memberData?.effective_date).format('YYYY-MM-DD');
			const end = moment(memberData?.term_date).format('YYYY-MM-DD');
			if (date < start || date > end) {
				errorMessage = 'Member Not Eligible'
			}
		} else if (((!memberData?.first_name || !memberData?.memberId || !memberData?.member_id) &&
			(memberSearched || member_information_validated))
			|| transition_message?.member_does_not_exist) {
			errorMessage = 'Member Not Found'
		}
		if (errorMessage) {
			return <div className='not-found-container'>
				<Icon name='exclamation triangle' color='red' />
				<span className='not-found-text'>{errorMessage}</span>
			</div>
		}
	}

	const memberInitials = () => {
		const fNameInitial = (memberData.first_name && memberData.first_name[0]) || ''
		const lNameInitial = (memberData.last_name && memberData.last_name[0]) || ''
		return `${fNameInitial}${lNameInitial}`
	}

	const getPreferredPhoneNumber = (phones) => {
		if (phones?.length) {
			let index = phones.findIndex((data) => data.preferred)
			if (index < 0)
				index = 0
			setMemberData(prevState => ({ ...prevState, phone_number: phones[index].phone_number }))
			return formatPhoneNumber(phones[index].phone_number)
		}
	}

	return (
		<div id="member-info-container">
			<div className="section-header-container">
				<span className="section-header">{memberData.member_id ? 'Member Information' : 'Find Member'}</span>
				{renderMemberEditOptions()}
			</div>
			<div className="member-sub-container">
				<div className="member-name-id-container">
					<div className="member-avatar-name-container">
						{/* <div> */}
						<div className="avatar-background" >
							<span className='avatar-initials'>{memberInitials()}</span>
						</div>
						{/* </div> */}
						<div className="name-container">
							{`${memberData.first_name || ''} ${memberData.last_name || ''}`}
						</div>
					</div>
					<div className="id-container">
						<span className="id-text">Member ID</span>
						<span className="id-number"> {memberData.member_id}</span>
						<img
							className='copy-icon'
							src="/i/copy.svg"
							alt='Copy Member ID'
							onClick={() => navigator.clipboard.writeText(memberData.member_id)} />
					</div>
				</div>
				<div className='member-info-column'>
					<div className="field-container">
						<span className="field-header">Contract ID / PBP</span>
						<span className='field-text'>
							{`${memberData.contract_id || '-'} / ${memberData.PBP || '-'}`}
						</span>
					</div>
					<div className="field-container">
						<span className="field-header">Client / LOB</span>
						<span className='field-text'>
							{`${memberData.client || '-'} / ${memberData.LOB || '-'}`}
						</span>
					</div>
					<div className="field-container">
						<span className="field-header">Effective Date</span>
						<span className='field-text'>
							{memberData.effective_date || '-'}
						</span>
					</div>
				</div>
				<div className='member-info-column'>
					<div className="field-container">
						<span className="field-header">Formulary Name</span>
						<span className='field-text'>
							{memberData.formulary_name || '-'}
						</span>
					</div>
					<div className="field-container">
						<span className="field-header">Plan / Group</span>
						<span className='field-text'>
							{`${memberData.plan_name || '-'} / ${memberData.group || '-'}`}
						</span>
					</div>
					<div className="field-container">
						<span className="field-header">Phone Number</span>
						<span className='field-text'>
							{formatPhoneNumber(memberData.phone_number) || getPreferredPhoneNumber(memberData?.personal_phones) || '-'}
						</span>
					</div>
				</div>
				<div className='member-info-column'>
					<div className="field-container">
						<span className="field-header">PCP Name</span>
						<span className='field-text'>
							{memberData.PCP || '-'}
						</span>
					</div>
					<div className="field-container">
						<span className="field-header">Date Of Birth</span>
						<span className='field-text'>{(memberData?.date_of_birth || memberData?.dob || '').split(' ')[0] || '-'}</span>
					</div>
					<div className="field-container">
						<span className="field-header">Term Date</span>
						<span className='field-text'>
							{memberData.term_date || '-'}
						</span>
					</div>
				</div>
				<div className='member-info-column'>
					<div className="field-container">
						<span className="field-header">Zipcode</span>
						<span className='field-text'>{memberData?.zip || memberData?.zipcode || '-'}</span>
					</div>
					<div className="field-container">
						<span className="field-header">Address</span>
						<span className='field-text'>
							{memberData?.city ? memberData?.home_address_1 + ', ' + memberData?.city : '-'}
						</span>
					</div>
					<div className="field-container">
						<span className="field-header">State</span>
						<span className='field-text'>{memberData.state || '-'} </span>
					</div>
				</div>
			</div>
			{/* {this.getNotFoundText(paCaseDetails?.patient_benefit)} */}
			{displayMemberErrorMessage()}
		</div >
	)
}

const mapStateToProps = (state) => ({
	state: {
		// app: state.app,
		user: state.user,
		memberLookup: state.memberLookup,
		paCaseDetails: state.priorAuthorizationCreation.paCaseDetails,
		updatePaCaseData: state.priorAuthorizationCreation.updatePaCaseData,
		// paCaseMessage: state.priorAuthorizationCreation.paCaseMessage,
		// questionnaireData: state.priorAuthorizationCreation.questionnaireData,
		// reassignData: state.priorAuthorizationCreation.reassignData,
		// allUsers: state.userManagement?.data?.map((x) => ({ name: `${x?.first_name} ${x?.last_name}`, user_id: x?.uuid })),
	},
})

const mapDispatchToProps = (dispatch) => {
	const allActions = {
		...AppActions,
		...PriorAuthorizationCreationActions,
		...MemberLookupActions,
	}

	return {
		actions: bindActionCreators(allActions, dispatch),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(MemberInfo)
