import React,{Component} from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Config from '../../../../../config'

import FliptInput from '../../../../../components/form/fliptInput';
import SubmenuNav from '../shared/navigation'
import BreadCrumb from '../../../../../components/breadCrumb'
import { Creators as ApiActions } from '../../../../../redux/reducers/api/claimLookupManagement'

import './styles.scss'
class ClaimTransactionMedicareInformationReportingDetails extends Component{
    componentDidMount() {
        const { actions } = this.props
        // TODO FIX THIS. SO NO MORE SETTIMEOUT
        setTimeout(() => actions.getClaimTransactionData(), Config.COMPONENT_DATA_LOAD_TIME)
      }

      _renderEmptyContent = () => (
        <div>Unavailable Data</div>
      )

      _renderContent = () =>{
        const { state } = this.props

        const {
            claim_response,
        }= state?.claimTransactionData?.claim_request_and_response_section || {}

        const {
            submitted_patient_pay_amount
          } = state.claimTransactionData?.pricing_section?.costs
     
      const {
        transaction_code,
        version,
        response_status,
        cardholder_id,
        group_id,
        reject_code,
        reject_count,
        message,
        additional_message_information,
        additional_message_information_qualifier,
        additional_message_information_count,
        authorization_number,
        help_desk_phone_number,
        help_desk_phone_number_qualifier,
        service_provider_id,
        bin_number,
        transaction_count,
        service_provider_id_qualifier,
        product_id_qualifier,
        prescription_reference_number_qualifier
      } = claim_response
      const { overview_section } = state.claimTransactionData
      const {product_id} = overview_section.product_section
      const { processor_control_number,prescription_reference_number,fill_number } = overview_section.claim_section
        return(
           <>
            <div className='section'> 
                <div className='fields-container'>
                    <div className='fields'>
                        <FliptInput value={bin_number || ''} label="Bin" stylized />
                        <FliptInput value={version || ''} label="Version/Release" stylized />
                        <FliptInput value={transaction_code || ''} label="Transaction Code" stylized />
                    </div>
                    <div className='fields'>
                        <FliptInput value={processor_control_number || ''} label="PCN" stylized />
                        <FliptInput value={transaction_count || ''} label="Transaction Count" stylized />
                        <FliptInput value={service_provider_id_qualifier || ''} label="Service Provider ID Qualifier" stylized />  
                    </div>
                    
                    <div className='fields'>
                    <FliptInput value={service_provider_id || ''} label="Service Provider ID" stylized />    
                    </div>
                </div>
            </div>
            <div className="section">
                <h2>Status</h2>
                <div className="fields-container">
                    <div className="fields">
                        <FliptInput value={response_status || ''} label="Response Status" stylized />
                        <FliptInput value={authorization_number || ''} label="Auth Number" stylized />
                        <FliptInput clearable={false} value={additional_message_information_count} name="Additional Message Count" label="Drug Coverage Status" stylized />    
                    </div>
                    <div className="fields">
                        <FliptInput clearable={false} value={additional_message_information_qualifier} name="Additional Message Qualifier" label="Additional Message Qualifier" stylized />
                        <FliptInput value={additional_message_information || ''} label="Additional Message Information" stylized />
                        <FliptInput value={help_desk_phone_number_qualifier || ''} label="Help Desk Number Qualifier" stylized />
                    </div>
                    <div className='fields'>
                        <FliptInput value={help_desk_phone_number || ''} label="Help Desk Number" stylized />
                    </div>
                </div>
            </div>
            <div className="section">
                <h2>Insurance</h2>
                <div className="fields-container">
                    <div className="fields">
                        <FliptInput value={cardholder_id || ''} label="Card Holder Id" stylized />
                        <FliptInput value={group_id || ''} label="Group ID" stylized />
                        <FliptInput value={''} name="" label="Other Payer Bin" stylized />
                    </div>
                    <div className="fields">
                        <FliptInput clearable={false} value={''} name="Other Payer PCN" label="Other Payer PCN" stylized />
                        <FliptInput value={''} label="Other Cardholder ID" stylized />
                        <FliptInput value={''} label="Other Payer Group ID" stylized />
                    </div>
                </div>
            </div>
            <div className="section">
                <h2>Message</h2>
                <div className="fields-container">
                    <div className="fields">
                        <FliptInput value={message || ''} label="Message Text" stylized />
                    </div>
                </div>
            </div>
            <div className="section">
                <h2>Claim</h2>
                <div className="fields-container">
                    <div className="fields">
                        <FliptInput clearable={false} value={prescription_reference_number_qualifier || ''} label="Rx Qualifier" stylized />
                        <FliptInput clearable={false} value={prescription_reference_number || ''} label="Rx" stylized />
                        <FliptInput clearable={false} value={product_id_qualifier} name="Product Qualifier" label="Product Qualifier" stylized /> 
                    </div>
                    <div className="fields">
                        <FliptInput clearable={false} value={product_id} name="NDC" label="NDC" stylized />
                        <FliptInput clearable={false} value={fill_number || ''} label="Fill Number" stylized />
                    </div>
                </div>
            </div>
            <div className="section">
                <h2>Reject</h2>
                <div className="fields-container">
                    <div className="fields">
                        <FliptInput clearable={false} value={reject_count || ''} label="Reject Count" stylized />
                        <FliptInput clearable={false} value={''} label="Occurance" stylized />
                        <FliptInput clearable={false} value={reject_code} name="Reject Code" label="Reject Code" stylized />
                    </div>
                </div>
            </div>
            <div className="section">
                <h2>Pricing</h2>
                <div className="fields-container">
                    <div className="fields">
                        <FliptInput clearable={false} value={submitted_patient_pay_amount || ''} label="Patient Paid Amount Submitted" stylized />
                    </div>
                </div>
            </div>
            </>
        )
    }

    render(){     
        const { history, state } = this.props
        const { claimTransactionData } = state
        const hasContent = claimTransactionData.claim_request_and_response_section ? Object.keys(claimTransactionData.claim_request_and_response_section) : []
        return(
            <div id="claimTransactionMedicarePartInfomation" className="content-co">
                <BreadCrumb {...this.props} />
                <div className="header">
                <h1>Transaction</h1>
                    <SubmenuNav history={history} active="medicareInformationReporting" />
                </div>
                <div className="subhead" />
                <div className='content' >
                { hasContent.length ? this._renderContent() : this._renderEmptyContent() }
                </div>  
            </div>

        )
    }
}

const mapStateToProps = (state) => ({
    state: {
      claimTransactionData: state.claimLookup.claimTransactionData,
    },
  })
  
  const mapDispatchToProps = (dispatch) => {
    const allActions = {
      ...ApiActions,
    }
  
    return {
      actions: bindActionCreators(allActions, dispatch),
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(ClaimTransactionMedicareInformationReportingDetails);
