import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import './styles.scss'
import BreadCrumb from '../../../components/breadCrumb'
import ClaimTable from '../../../components/fliptGrid'
import FliptButton from '../../../components/form/fliptButton'
import FliptDatePicker from '../../../components/form/fliptDatePicker'
import FliptDropdown from '../../../components/form/fliptDropdown'
import FliptInput from '../../../components/form/fliptInput'
import { LinkContainerRenderer, PHILoader } from '../../../components/fliptGrid/cellRenderers'
import { Creators as ApiActions } from '../../../redux/reducers/api/claimLookupManagement'
import { Creators as AppActions } from '../../../redux/reducers/app'

import { convertStrToDateObj, parseQueryString } from '../../../utils/utilities'

class NxTransaction extends Component {
    constructor(props) {
        super(props)

        this.state = {
            form: {
                auth_id: '',
                nx_transaction_id: '',
                cardholder_id: '',
                start_date: '',
                end_date: '',
                first_name: '',
                last_name: '',
            },
            agGridRef: React.createRef(),
        }
    }

    componentDidMount() {
        const { history } = this.props
        const { form } = this.state
        const qs = parseQueryString(history?.location?.search)
        form.cardholder_id = qs.cardholder_id
        this.setState({ form })
    }

    formatAuthId(authId) {
        // Check if the authId is less than 13 characters
        if (authId.length < 13) {
          // Calculate the number of leading zeroes required
          const leadingZeroes = 13 - authId.length;
          // Add leading zeroes to the authId
          const formattedAuthId = '0'.repeat(leadingZeroes) + authId;
          // Check if all 13 characters are '0s'
          return formattedAuthId;
        } else {
          // If authId is already 13 characters, no changes needed
          // Check if all 13 characters are '0s'
          if (authId == '0000000000000') {
            return '';
          } else {
            return authId;
          }
        }
      }



      handleClick = () => {
        const { form, agGridRef } = this.state
        const { actions } = this.props
        if (form.first_name && !form.last_name) {
            const transitionalPortal = {
                header: 'Fields are missing',
                copy: 'Please add Last Name to search member by name',
            }
            actions.displayTransitionalPortal(transitionalPortal)
            return;
        }
        if (!form.first_name && form.last_name) {
            const transitionalPortal = {
                header: 'Fields are missing',
                copy: 'Please add First Name to search member by name',
            }
            actions.displayTransitionalPortal(transitionalPortal)
            return;
        }
        if(form.auth_id && form.auth_id.length > 0 && form.auth_id.length < 13) {
            this.setState({ form: {
                ...this.state.form,
                auth_id: this.formatAuthId(form.auth_id)
            } }, () => {
                agGridRef?.current?.api?.refreshServerSideStore({ route: null, purge: true })
            });
        }
        if (form.auth_id && form.auth_id.length > 13) {
            const transitionalPortal = {
                header: 'Invalid Auth ID',
                copy: 'Auth ID must be 13 characters',
            }
            actions.displayTransitionalPortal(transitionalPortal)
            return;
        }
        
        // Use this.setState to update the form state and pass a callback function
        this.setState({
            form: {
                ...this.state.form,
                auth_id: form.auth_id
            }
        }, () => {
            agGridRef?.current?.api?.refreshServerSideStore({ route: null, purge: true })
        });
    }

    handleClear = () => {
        const { form } = this.state
        Object.keys(form).forEach((key) => {
            form[key] = ''
        })
        this.setState({ form })
    }

    updateField = (el, dropdown) => {
        const { form } = this.state
        const { name, value } = dropdown || el.currentTarget
        form[name] = value
        this.setState({ form })
    }

    render() {
        const headers = [
            'action', 'auth_id', 'transaction_id', 'start_date', 'type', 'transaction_response_status', 'cardholder_id',
            'first_name', 'last_name', 'plan_name', 'contract_id', 'pbp'
        ]

        const { state: { allNxTxns }, actions } = this.props
        const { form, agGridRef } = this.state
        const cellRendererParams = {
            action: {
                cellRenderer: LinkContainerRenderer,
                searchArgs: [
                    {
                        path: '/nx-claim-transaction-details',
                        searchArgs: { auth_id: 'auth_id' },
                        staticSearchArgs: { fromNx: true },
                        label: 'Nx Transaction',
                    },
                    {
                        path: '/nx-claim-transaction-details',
                        searchArgs: { auth_id: 'auth_id' },
                        state: { showNx: 'true' },
                        label: 'Claim Transaction',
                    }
                ],
                width: 250,
            },
            first_name: {
                cellRenderer: PHILoader,
                filter: false
            },
            last_name: {
                cellRenderer: PHILoader,
                filter: false
            },
            contract_id: {
                cellRenderer: PHILoader,
                filter: false
            },
            pbp: {
                cellRenderer: PHILoader,
                filter: false,
                overrideHeader: 'PBP'
            },
            plan_name: {
                cellRenderer: PHILoader,
                filterParams: {
                    filterOptions: ['contains', 'equals'],
                    defaultJoinOperator: 'OR',
                },
            },
            cardholder_id: {
                overrideHeader: 'Member ID'
            },
            fx_id: {
                overrideHeader: 'Transaction ID'
            },
            create_dt: {
                overrideHeader: 'Transaction Date'
            },
            type: {
                overrideHeader: 'Transaction Type'
            }
        }
        const serverSideGridConfig = {
            rowModel: 'serverSide',
            serverSideStoreType: 'partial',
            cacheBlockSize: 20,
            pagination: true,
            paginationPageSize: 20,
        }
        const serverSideGridParams = {
            form: form,
            apiId: 'nx-transaction',
            sagaToCall: actions.getAllNxTxns,
            headers: headers,
        }
        return (
            <div id="nxTxn">
                <BreadCrumb {...this.props} />
                <div className="header">
                    <FliptButton name="Advanced Search" className="invisible" />
                </div>
                <div className="subhead">
                    <FliptInput value={form.nx_transaction_id} placeholder="Nx Transaction ID" name="nx_transaction_id" onChange={this.updateField} />
                    <FliptInput value={form.auth_id} placeholder="Auth ID" name="auth_id" onChange={this.updateField} />
                    <FliptInput value={form.cardholder_id} placeholder="Cardholder ID" name="cardholder_id" onChange={this.updateField} />
                    <FliptInput value={form.first_name} placeholder="First Name" name="first_name" onChange={this.updateField} />
                    <FliptInput value={form.last_name} placeholder="Last Name" name="last_name" onChange={this.updateField} />
                    <FliptDatePicker placeholder="Start Date" name="start_date" onChange={this.updateField} value={convertStrToDateObj(form.start_date)} className="overlap-index" />
                    <FliptDatePicker placeholder="End Date" name="end_date" onChange={this.updateField} value={convertStrToDateObj(form.end_date)} />
                    <FliptButton name="Search" className="primary searchButton" onClick={this.handleClick} />
                    <FliptButton name="Clear" className="primary searchButton" onClick={this.handleClear} />
                    <Link to={{ pathname: '/adap-spap-information' }}>
                        <div className="adap-spap" aria-hidden="true">
                         Adap Spap Information
                         </div>
                    </Link>
                </div>
                <div className="content">
                    <ClaimTable
                        headers={headers}
                        serverSideGridConfig={serverSideGridConfig}
                        serverSideGridParams={serverSideGridParams}
                        cellRendererParams={cellRendererParams}
                        agGridRef={agGridRef}
                        data={allNxTxns || []}
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    state: {
        allNxTxns: state.claimLookup.allNxTxns,
        user: state.user,
    },
})

const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...ApiActions,
        ...AppActions
    }

    return {
        actions: bindActionCreators(allActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NxTransaction)
