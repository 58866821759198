export default [
  {
    key: 'Processing fee',
    text: 'Processing fee',
    value: 'Processing fee',
  },
  {
    key: 'Admin fee',
    text: 'Admin fee',
    value: 'Admin fee',
  },
  {
    key: 'Stock fee',
    text: 'Stock fee',
    value: 'Stock fee',
  },
]
