import Immutable from 'seamless-immutable'
import { createActions, createReducer } from 'reduxsauce'

export const INITIAL_STATE = Immutable({
    drugListData: [],
    prAlternatesData: [],
    prExclusionsData: [],
    groupConfigData: {},
    dashboardData: [],
    saveConfigData: {}
})


export const setDrugListDataReducer = (state = INITIAL_STATE, { payload }) => ({
    ...state,
    drugListData: payload,
})

export const setPrAlternatesDataReducer = (state = INITIAL_STATE, { payload }) => ({
    ...state,
    prAlternatesData: payload,
})

export const setPrExclusionsDataReducer = (state = INITIAL_STATE, { payload }) => ({
    ...state,
    prExclusionsData: payload,
})

export const setGroupConfigDataReducer = (state = INITIAL_STATE, { payload }) => ({
    ...state,
    groupConfigData: payload,
})

export const setDashboardDataReducer = (state = INITIAL_STATE, { payload }) => ({
    ...state,
    dashboardData: payload,
})


export const setSaveConfigDataReducer = (state = INITIAL_STATE, { payload }) => ({
    ...state,
    saveConfigData: payload,
})

export const clearDashboardDataReducer = (state = INITIAL_STATE, { payload }) => ({
    ...state,
    dashboardData: [],
})


export const { Types, Creators } = createActions({
    getCmsFormularyDashboard: ['payload'],
    setDashboardData: ['payload'],
    getGroupConfigData: ['payload'],
    setGroupConfigData: ['payload'],
    saveCmsConfig: ['payload'],
    setSaveConfigData: ['payload'],
    getDrugList: ['payload'],
    getPrExclusions: null,
    getPrAlternates: null,
    setPrAlternatesData: ['payload'],
    setPrExclusionsData: ['payload'],
    setDrugListData: ['payload'],
    clearDashboardData: null,
})

const HANDLERS = {
    [Types.SET_DASHBOARD_DATA]: setDashboardDataReducer,
    [Types.SET_GROUP_CONFIG_DATA]: setGroupConfigDataReducer,
    [Types.SET_SAVE_CONFIG_DATA]: setSaveConfigDataReducer,
    [Types.SET_DRUG_LIST_DATA]: setDrugListDataReducer,
    [Types.SET_PR_ALTERNATES_DATA]: setPrAlternatesDataReducer,
    [Types.SET_PR_EXCLUSIONS_DATA]: setPrExclusionsDataReducer,
    [Types.CLEAR_DASHBOARD_DATA]: clearDashboardDataReducer,
}

export default createReducer(INITIAL_STATE, HANDLERS)
