/* eslint-disable */
export default {
  pa_qualifier: {
    type: 'dropdown',
    disabled: false,
  },
  pa_operator: {
    type: 'dropdown',
    disabled: false,
  },
  pa_value: {
    type: 'input',
    disabled: false,
  },
}
