import React from 'react'

import FliptInput from '../../../../../components/form/fliptInput'

export default (props) => {
  const { data } = props
  const {
    claim_section,
    product_section,
    member_section,
  } = data
  const { auth_id, date_of_service, quantity_dispensed, days_supply } = claim_section || {}
  const {
    gpi,
    drug_strength,
    drug_name,
    product_id,
  } = product_section || {}
  const { cardholder_id } = member_section || {}

  return (
    <div className="section">
      <h2>Claim Details</h2>
      <div className="fields-container">
        <div className="fields">
          <FliptInput value={auth_id || ''} label="Auth ID" stylized disabled />
          <FliptInput value={gpi || ''} label="GPI" stylized disabled />
          <FliptInput value={cardholder_id || ''} label="Member ID" stylized disabled />
          <FliptInput value={date_of_service || ''} label="Date of Service" stylized disabled />
        </div>
        <div className="fields">
          <FliptInput value={drug_name || ''} label="Drug Name" stylized disabled />
          <FliptInput value={drug_strength || ''} label="Drug Strength" stylized disabled />
          <FliptInput value={product_id || ''} label="NDC" stylized disabled />
          <FliptInput value={quantity_dispensed || ''} label="Quantity" stylized disabled />
          <FliptInput value={days_supply || ''} label="Days of Supply" stylized disabled />
        </div>
      </div>
    </div>
  )
}
