import React, { PureComponent } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import moment from 'moment-timezone'
import './styles.scss'
import BreadCrumb from '../../../../../components/breadCrumb'
import Config from '../../../../../config'
import FliptGrid from '../../../../../components/fliptGrid'
import { LinkRenderer } from '../../../../../components/fliptGrid/cellRenderers'
import FliptInput from '../../../../../components/form/fliptInput'
import SubmenuNav from '../shared/navigation'
import { Creators as ApiActions } from '../../../../../redux/reducers/api/claimLookupManagement'
import { filterRowData } from '../../../../../utils/utilities'

class ClaimTransactions extends PureComponent {
  componentDidMount() {
    const { actions } = this.props
    // TODO FIX THIS. SO NO MORE SETTIMEOUT
    setTimeout(() => actions.getClaimTransactionData(), Config.COMPONENT_DATA_LOAD_TIME)
  }

  _renderContent = () => {
    const { state } = this.props
    const { rejection_section } = state.claimTransactionData
    const {
      flipt_reject_code,
      flipt_reject_message,
      flipt_reject_message2,
      payer_reject_code,
      payer_reject_message,
      payer_reject_message2,
      dur_reject,
    } = rejection_section

    const headers = [
      'auth_id', 'processed_date', 'date_of_service', 'product_id', 'drug_name',
      'strength', 'quantity', 'days_supply',
      'dosage_form', 'service_provider_id', 'pharmacy_name', 'prescriber_id',
      'prescriber_name',
    ]
    const headerMapping = {
      startDate: 'processed_date',
      drug_dosage: 'dosage_form',
      drug_strength: 'strength',
      quantity_dispensed: 'quantity',
    }


    const cellRendererParams = {
      auth_id: {
        cellRenderer: LinkRenderer,
        searchArgs: {},
        onCellClicked: (details) => {
          const { history } = this.props
          history.push(`/claim-transaction-overview?auth_id=${details.value}`)
        },
      },
      product_id: {
        overrideHeader: 'NDC',
      },
      service_provider_id: {
        overrideHeader: 'Pharmacy NPI',
      },
      prescriber_id: {
        overrideHeader: 'Presciber NPI',
      },
    }
    const gridData = dur_reject && dur_reject.length ? dur_reject.map((d) => ({
      ...filterRowData(d, headers, headerMapping),
      processed_date: moment(`${d.startDate}Z`).tz('America/New_York').format('MM/DD/YYYY HH:mm:ss'),
    })) : []

    return (
      <div className="section">
        <div className="fields-container">
          <div className="fields">
            <FliptInput value={flipt_reject_code} label="Flipt Reject Code" stylized />
            <FliptInput value={payer_reject_code} label="Payer Reject Code" stylized />
          </div>
          <div className="fields">
            <FliptInput value={flipt_reject_message} label="Flipt Reject Message 1" stylized />
            <FliptInput value={payer_reject_message} label="Payer Reject Message 1" stylized />
          </div>
          <div className="fields">
            <FliptInput value={flipt_reject_message2} label="Flipt Reject Message 2" stylized />
            <FliptInput value={payer_reject_message2} label="Payer Reject Message 2" stylized />
          </div>
        </div>
        {!!dur_reject && !!dur_reject.length && (
          <div className="dur-grid">
            <FliptGrid
              data={gridData}
              headers={headers}
              cellRendererParams={cellRendererParams}
            />
          </div>
        )}
      </div>
    )
  }

  _renderEmptyContent = () => (
    <div>Unavailable Data</div>
  )

  render() {
    const { history, state } = this.props
    const { claimTransactionData } = state
    const hasContent = claimTransactionData?.rejection_section ? Object.keys(claimTransactionData.rejection_section) : []

    return (
      <div id="claimTransactionRejection">
        <BreadCrumb {...this.props} />
        <div className="header">
          <h1>Transaction</h1>
          <SubmenuNav history={history} active="rejection" />
        </div>
        <div className="subhead" />
        <div className="content">
          {hasContent.length ? this._renderContent() : this._renderEmptyContent()}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  state: {
    claimTransactionData: state.claimLookup.claimTransactionData,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...ApiActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClaimTransactions)
