/* eslint-disable */
export default {
    lookback_criteria_sublist: {
        type: 'dropdown',
        disabled: false,
    },
    drug_list: {
        type: 'dropdown',
        disabled: false,
        multiple: true,
    },
    set_operator: {
        type: 'dropdown',
        disabled: false,
    },
}
