import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import _ from 'lodash'

import { v4 as uuidv4 } from 'uuid'
import { Button } from 'semantic-ui-react'
import { addNodeUnderParent, changeNodeAtPath, getNodeAtPath } from '@nosferatu500/react-sortable-tree'
import Config from '../../../../config'
import './styles.scss'
import LOB_OPTIONS from '../companyManagement/data/lobOptions'
import PROGRESSION from '../../../../config/constants/progression'
import FliptButton from '../../../../components/form/fliptButton'
import FliptTree from '../../../../components/fliptTree'
import { Creators as companyManagementActions } from '../../../../redux/reducers/companyManagement'
import { Creators as NavigationActions } from '../../../../redux/reducers/navigation'
import { Creators as AppActions } from '../../../../redux/reducers/app'
import FliptInput from '../../../../components/form/fliptInput'
import FliptDropdown from '../../../../components/form/fliptDropdown'
import FliptDatePicker from '../../../../components/form/fliptDatePicker'
import {
  addRemoveDaysToDate,
  convertStrToDateObj,
  parseQueryString,
} from '../../../../utils/utilities'

class HierarchyManagement extends Component {
  constructor(props) {
    super(props)
    this.state = {
      searchString: '',
      searchFocusIndex: 0,
      searchFoundCount: null,
      organization: [],
      newLeaf: {
        title: '',
        id: '',
        line_of_business: '',
        effective_start_date: '',
        effective_end_date: '',
        subtitle: '',
      },
      focusedLeaf: '',
      isAdd: false,
    }
  }

  componentDidMount() {
    const { actions, history: { location } } = this.props
    actions.clearCompanyData()

    setTimeout(() => {
      if (location.search) {
        actions.clearCompanyData()
        actions.getCompanyData(parseQueryString(location.search))
      }
    }, Config.COMPONENT_DATA_LOAD_TIME)
  }

  componentDidUpdate(prevProps) {
    const { state } = this.props
    const { companyData } = state
    if (companyData && Object.keys(companyData).length && !_.isEqual(prevProps.state.companyData, companyData)) {
      this._updateTree(companyData)
    }
  }

  componentWillUnmount() {
    const { actions } = this.props
    actions.clearCompanyData()
  }

  _updateTree = (cd) => {
    let newTree = []
    const { id, company_full_name, hierarchy } = cd
    const orgLevel = hierarchy?.keyLevelMapping?.organization?.length > 0 ? hierarchy?.map[hierarchy?.keyLevelMapping?.organization[0]] : null
    if (!orgLevel) {
      newTree.push({
        title: 'Add Organization',
        isAddButton: true,
      })
    } else {
      newTree.push({
        title: orgLevel?.organization_name ?? '',
        id: orgLevel?.organization_id ?? '',
        node_key: orgLevel?.key,
        subtitle: 'organization',
        active: orgLevel?.active === undefined || orgLevel?.active,
        children: [],
      })
    }
    if (hierarchy?.keyLevelMapping?.organization?.length > 0) {
      const newData = { id, client: hierarchy.map[hierarchy?.keyLevelMapping?.organization[0]]?.clients?.map(key => hierarchy.map[key]) }
      this._convertToTree(newData, newTree[0], hierarchy.map)
    }

    this.setState({ organization: newTree }, () => {
      const { organization } = this.state
      this._addButtons(organization)
      this.setState({ organization })
    })
  }

  getNodeKey = ({ treeIndex }) => treeIndex

  _convertToTree = (data, newTree, hierarchyMap) => {
    if (!data) return
    const keyData = Object.keys(data)
    if ((keyData.indexOf('id') > -1 || keyData.indexOf('name')) > -1 && keyData.length < 2) {
      return
    }
    Object.keys(data).forEach((el) => {
      if (Array.isArray(data[el])) {
        data[el].forEach((element, i) => {
          newTree.children.push({
            title: element[element?.level + '_name'], // we could either convert the fields before or just grab the field
            id: element[element?.level + '_id'],
            subtitle: element?.level,
            node_key: element?.key,
            active: element.active === undefined || element.active,
            children: [],
          })
          Object.keys(element).forEach((e, i) => {
            if (Array.isArray(element[e])) {
              element[e] = element[e].map(x => hierarchyMap[x])
            }
          })
          this._convertToTree(element, newTree.children[i], hierarchyMap) //need to figure out how to send the right element params
        })
      }
    })
  }

  _convertTreeToFlat = (data, parentField, hashmap, keys, oldHierarchyMap) => {
    if (!data) return
    for (let i = 0; i < data.length; i++) {
      const node = data[i]
      if (!node) {
        return
      }
      const idx = PROGRESSION.indexOf(node?.subtitle?.toLowerCase())
      if (!node.isAddButton) {
        const parentOf = PROGRESSION[idx + 1]
        let currNode = {
          active: node?.active,
          [node?.subtitle?.toLowerCase() + '_id']: node.id,
          [node?.subtitle?.toLowerCase() + '_name']: node.title,
          config_id: node?.node_key ? oldHierarchyMap[node?.node_key]?.config_id : null,
          key: node?.node_key ?? uuidv4(),
          level: node?.subtitle?.toLowerCase(),
        }
        if (node?.subtitle !== 'group') {
          currNode[parentOf + 's'] = []
        }
        parentField.push(currNode.key)
        hashmap[currNode.key] = currNode
        keys.push(currNode.key)
        this._convertTreeToFlat(node.children, currNode[parentOf + 's'] ?? [], hashmap, keys, oldHierarchyMap)
      }
    }
  }

  _submitHierarchy = () => {
    const { organization } = this.state
    const { actions, history: { location }, state: { companyData: { hierarchy: { map: hierarchyMap, id } = {}, company_code } } } = this.props
    let hashmap = {}
    let keys = []
    this._convertTreeToFlat(organization, [], hashmap, keys, hierarchyMap)
    actions.editCompanyHierarchyData({ company_id: parseQueryString(location.search).id, hierarchy: { hashmap, keys, id, hierarchy_id: company_code } })
  }

  _addButtons = (data) => {
    if (!data) return
    for (let i = 0; i < data.length; i++) {
      if (data[i].isAddButton) {
        return
      }
      const idx = PROGRESSION.indexOf(data[i].subtitle?.toLowerCase())
      const parentOf = PROGRESSION[idx + 1]
      if (parentOf !== undefined) {
        if (!data[i].children) {
          data[i].children = [{
            title: `Add ${parentOf}`,
            isAddButton: true,
          }]
        } else if (!(data[i].children.filter((s) => s.isAddButton).length > 0)) {
          data[i].children.push({
            title: `Add ${parentOf}`,
            isAddButton: true,
          })
        }
      }
      this._addButtons(data[i].children)
    }
  }

  onChange = (el) => {
    this.setState({ organization: el })
  }

  _changeActiveChildren = (node, active) => {
    if (!node) return
    for (let i = 0; i < node.length; i++) {
      if (!node[i].isAddButton) {
        node[i].active = active
        this._changeActiveChildren(node[i].children, active)
      }
    }
  }

  _changeActiveNode = (leaf) => {
    const { path, node } = leaf
    const active = !node.active
    node.active = active
    this._changeActiveChildren(node.children, active)

    this.setState((state) => ({
      organization: changeNodeAtPath({
        treeData: state.organization,
        path,
        getNodeKey: this.getNodeKey,
        newNode: node,
      }),
    }), () => { this._submitHierarchy() })
  }

  editNode = (focusedLeaf) => {
    const { path, node } = focusedLeaf
    const { newLeaf, organization } = this.state
    const { actions } = this.props
    if (!newLeaf?.title || !newLeaf?.id) {
      const field = newLeaf?.id ? 'Name' : 'ID'
      const transitionalPortal = {
        header: `${field} is missing`,
        copy: `Please add ${field} before submitting edit`,
      }
      actions.displayTransitionalPortal(transitionalPortal)
      return null
    }
    const newFocusedLeaf = {
      ...focusedLeaf,
      node: {
        ...node,
        title: newLeaf.title,
        id: newLeaf.id,
        line_of_business: newLeaf.line_of_business,
        effective_start_date: newLeaf.effective_start_date,
        effective_end_date: newLeaf.effective_end_date,
      },
    }
    this.setState({
      organization: changeNodeAtPath({
        treeData: organization,
        path,
        newNode: newFocusedLeaf.node,
        getNodeKey: this.getNodeKey,
        ignoreCollapsed: true,
      }),
      focusedLeaf,
      newFocusedLeaf,
    }, () => { this._submitHierarchy() })
  }

  addNode = (focusedLeaf) => {
    const { path } = focusedLeaf
    const { actions } = this.props
    let isFirstNode = path.length === 1
    const { title } = focusedLeaf.node
    const parent = title.substring(title.indexOf(' ') + 1)
    let { newLeaf } = this.state
    if (!newLeaf?.title || !newLeaf?.id) {
      const field = newLeaf?.id ? 'Name' : 'ID'
      const transitionalPortal = {
        header: `${field} is missing`,
        copy: `Please add ${field} before submitting edit`,
      }
      actions.displayTransitionalPortal(transitionalPortal)
      return null
    }
    newLeaf = {
      ...newLeaf,
      subtitle: parent,
      active: true,
    }
    this.setState((state) => {
      path[path.length - 1] = path[path.length - 2] + 1 || 0
      const newState = {
        organization: !isFirstNode ? addNodeUnderParent({
          treeData: state.organization,
          parentKey: path[path.length - 2] || 0,
          expandParent: true,
          getNodeKey: this.getNodeKey,
          newNode: newLeaf,
          addAsFirstChild: true,
        }).treeData : [newLeaf],
      }
      const { organization } = newState
      this._addButtons(organization)
      return {
        isAdd: false,
        focusedLeaf: getNodeAtPath({
          treeData: organization,
          path,
          getNodeKey: this.getNodeKey,
          ignoreCollapsed: true,
        }),
        organization,
      }
    }, () => { this._submitHierarchy() })
  }

  generateNodeProps = (leaf) => {
    const returnedProps = {}
    const { node, parentNode } = leaf
    returnedProps.className = 'node'
    if (node?.isAddButton) {
      returnedProps.onClick = () => {
        this.setState({
          isAdd: true,
          focusedLeaf: leaf,
          newLeaf: {
            title: '',
            id: '',
            line_of_business: '',
            effective_start_date: '',
            effective_end_date: '',
          },
        })
      }
    } else {
      if (!node?.active) {
        returnedProps.className = 'disabled'
      }
      if (parentNode?.active || parentNode === undefined) {
        returnedProps.buttons = [
          <Button icon={node.active ? 'times circle' : 'check circle'} compact size="tiny" onClick={() => this._changeActiveNode(leaf)} />,
        ]
      }
      returnedProps.onClick = () => {
        this.setState({
          isAdd: false,
          focusedLeaf: leaf,
          newLeaf: {
            title: node.title ? node.title : '',
            id: node.id ? node.id : '',
            line_of_business: node.line_of_business ? node.line_of_business : '',
            effective_start_date: node.effective_start_date ? node.effective_start_date : '',
            effective_end_date: node.effective_end_date ? node.effective_end_date : '',
          },
        })
      }
    }
    return returnedProps
  }

  _updateFields = (el, dateData) => {
    const { newLeaf } = this.state
    const { name, value } = dateData || el.currentTarget
    const newLeafFields = ['title', 'id', 'line_of_business', 'effective_start_date', 'effective_end_date']
    if (newLeafFields.indexOf(name) !== -1) {
      newLeaf[name] = value
      this.setState((prevState) => ({
        ...prevState,
        newLeaf,
      }))
    } else {
      this.setState({
        [name]: value,
      })
    }
  }

  render() {
    const { props, state } = this
    const nl = state.newLeaf
    const minCompanyStartDate = addRemoveDaysToDate(1, false)
    const {
      organization, searchString, searchFocusIndex, searchFoundCount, isAdd, focusedLeaf,
    } = state

    const customSearchMethod = ({ node, searchQuery }) => (
      searchQuery && node.title.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1
    )

    const searchCallback = (matches) => {
      this.setState({
        searchFoundCount: matches.length,
        searchFocusIndex:
          matches.length > 0 ? searchFocusIndex % matches.length : 0,
      })
    }

    const selectPrevMatch = () => {
      this.setState({
        searchFocusIndex:
          searchFocusIndex !== null
            ? (searchFoundCount + searchFocusIndex - 1) % searchFoundCount
            : searchFoundCount - 1,
      })
    }

    const selectNextMatch = () => {
      this.setState({
        searchFocusIndex:
          searchFocusIndex !== null
            ? (searchFocusIndex + 1) % searchFoundCount
            : 0,
      })
    }

    return (
      <div id="hierarchyManagement">
        <div className="header">Hierarchy Management</div>
        <div className="column">
          <form onSubmit={(event) => { event.preventDefault() }}>
            <FliptInput
              id="findBox"
              type="text"
              name="searchString"
              style={{ fontSize: '1rem' }}
              value={searchString}
              onChange={this._updateFields}
            />
            <span className="formElement">
              <button
                type="button"
                disabled={!searchFoundCount}
                onClick={selectPrevMatch}
              >
                &lt;
              </button>

              <button
                type="submit"
                disabled={!searchFoundCount}
                onClick={selectNextMatch}
              >
                &gt;
              </button>
            </span>
            <span className="formElement">
              &nbsp;
              {searchFoundCount > 0 ? searchFocusIndex + 1 : 0}
              &nbsp;/&nbsp;
              {searchFoundCount || 0}
            </span>
          </form>
          <div className="tree">
            <div>
              <FliptTree
                treeData={organization}
                onChange={this.onChange}
                canDrag={false}
                generateNodeProps={this.generateNodeProps}
                searchMethod={customSearchMethod}
                searchQuery={searchString}
                searchFocusOffset={searchFocusIndex}
                searchFinishCallback={searchCallback}
                style={{ height: 600, width: 700 }}
              />
            </div>
          </div>
          <div className="buttonContainer">
            <FliptButton name="Back" className="primary" onClick={props.history.goBack} />
            {/* <FliptButton name="Save" className="primary searchButton" onClick={this._saveCompanyForm} /> */}
            <FliptButton name="Submit" className="primary" onClick={this._submitHierarchy} />
          </div>
        </div>
        <div className="column">
          <div className="details">
            <div>
              <h1>{focusedLeaf.node?.title}</h1>
              <br />
              <form className="details-inner">
                <div className="company-inputs-wrap">
                  <div className="company-inputs">
                    <span>Name:</span>
                    <FliptInput name="title" value={nl.title} onChange={this._updateFields} />
                  </div>
                  <div className="company-inputs">
                    <span>ID:</span>
                    <FliptInput name="id" value={nl.id} onChange={this._updateFields} />
                  </div>
                  <div className="company-inputs">
                    <span>Line Of Business:</span>
                    <FliptDropdown clearable placeholder="Select" name="line_of_business" value={nl.line_of_business} onChange={this._updateFields} options={LOB_OPTIONS} />
                  </div>
                  <div className="company-inputs">
                    <span>Effective Start Date:</span>
                    <FliptDatePicker className="create-company-start-date" placeholder="Effective Start Date" name="effective_start_date" minDate={minCompanyStartDate} defaultValue={convertStrToDateObj(nl.effective_start_date)} onChange={this._updateFields} />
                  </div>
                  <div className="company-inputs">
                    <span>Effective End Date:</span>
                    <FliptDatePicker className="create-company-start-date" placeholder="Effective End Date" name="effective_end_date" minDate={minCompanyStartDate} defaultValue={convertStrToDateObj(nl.effective_end_date)} onChange={this._updateFields} />
                  </div>
                </div>
                <FliptButton name={isAdd ? 'Add' : 'Submit Edit'} className="primary" onClick={isAdd ? () => this.addNode(focusedLeaf) : () => this.editNode(focusedLeaf)} />
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  state: {
    companyData: state.companyManagement.companyData,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...companyManagementActions,
    ...NavigationActions,
    ...AppActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HierarchyManagement)
