export default [
  {
    key: 'Fixed',
    text: 'Fixed',
    value: 'FIXED',
  },
  {
    key: 'Variable',
    text: 'Variable',
    value: 'VARIABLE',
  },
]
