import React, { forwardRef } from 'react'

import './styles.scss'

const FliptCheckbox = forwardRef((props = {}, ref) => {
  const inputProps = { ...props }

  if (inputProps.disabled) {
    inputProps.value = inputProps.value || ''
  }

  return (
    <input type="checkbox" {...inputProps} ref={ref} />
  )
})

export default FliptCheckbox
