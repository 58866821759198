import React from 'react'
import { Link } from 'react-router-dom'
// import { ReactComponent as DragIcon } from "/i/Drag.svg";

export default (props) => {
  const { active } = props
  const search = ''

  return (
    <div className='submenu'>
      <div className={active === 'formulary' ? 'tab active' : 'tab'}>
      <Link
        to={{
          pathname: '/formulary-creation-management',
          search,
        }}
        // className={active === 'formulary' ? 'active' : ''}
      >
        
        <div className='tab_inner_container'>
         {/* <img src='/i/Drag.svg'/> */}
         <p>Formulary</p>
        </div>
      </Link>
      </div>
      <div className={active === 'formularyTier' ? 'tab active' : 'tab'}>
      <Link
        to={{
          pathname: '/formulary-tier-creation-management',
          search,
        }}
        // className={active === 'formularyTier' ? 'active' : ''}
      >
        <div className='tab_inner_container'>
         {/* <DragIcon/> */}
         {/* <img src='/i/Drag.svg'/> */}
         <p>Drug Lists</p>
        </div>
        
      </Link>
      </div>
      <div className={active === 'formularyFileInput' ? 'tab active' : 'tab'}>
      <Link
        to={{
          pathname: '/formulary-file-input',
          search,
        }}
        // className={active === 'formularyFileInput' ? 'active' : ''}
      >
        
        <div className='tab_inner_container'>
         {/* <DragIcon/> */}
         {/* <img src='/i/Drag.svg'/> */}
         <p>Formulary File Input</p>
        </div>
      </Link>
      </div>    
    </div>
  )
}
