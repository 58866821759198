

export const drugToDrugHeader = [
    'action',
    'management_level',
    'severity_level',
    'documentation_level',
    'onset',
    'reject_type',
    'reject_code',
    'message_type',
    'reject_message',
]
export const duplicateRxHeader = [
    'action',
    'gpi_level',
    'fill_threshold',
    'reject_type',
    'reject_code',
    'message_type',
    'reject_message',
]

export const dosageHeader = [
    'action',
    'edit_type',
    'reject_type',
    'reject_code',
    'message_type',
    'reject_message',
]

export const opioidHeaders = [
    'action',
    'member_type',
    'mme_limit',
    'short_acting_mme',
    'long_acting_mme',
    'first_fill_days_supply_limitation',
    'days_supply_limitation',
    'reject_type',
    'reject_code',
    'message_type',
    'reject_message',
]

export const duplicateTherapyHeader = [
    'action',
    'medispan_duplicate_allowance_indicator',
    'duplication_allowance_indicator',
    'significance_code',
    'class_description',
    'reject_type',
    'reject_code',
    'message_type',
    'reject_message',
]

export const regimenComplianceHeader = [
    'action',
    'min_day_supply',
    'percent_day_below_compliance',
    'max_day_below_compliance',
    'maintenance_drug',
    'gpi_level',
    'reject_type',
    'reject_code',
    'message_type',
    'reject_message'
]