import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import './styles.scss'
import FliptDropdown from '../../../../components/form/fliptDropdown'
import { Creators as AppActions } from '../../../../redux/reducers/app'

import ManualList from './manualList'
import * as Constants from '../constants'
import FileImport from './fileImport'

class BatchInput extends Component {
  constructor(props) {
    super(props)

    this.state = {
      field_type: '',
      input_type: '',
    }
  }

  updateSelection = (el, dropdown) => {
    const { actions, history } = this.props
    const { name, value } = dropdown
    this.setState({ [name]: value }, () => {
      const { field_type, input_type } = this.state
      actions.setModalComponent({
        modalProperties: { className: 'bulk-reprocessing-restacking-modal' },
        contents: <FileImport
          history={history}
          field_type={field_type}
          {...this.props}
        />,
      })
    })
  }

  render() {
    const { field_type } = this.state
    return (
      <div id='batch-input-section'>
        <div className='batch-input-header'>
          Batch Input
        </div>
        <hr />
        <div className='batch-input-container'>
          <FliptDropdown options={Constants.FIELD_TYPES} value={field_type} stylized name='field_type' onChange={this.updateSelection} label='Field Type' />
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(null, mapDispatchToProps)(BatchInput)
