import React, { PureComponent, useEffect, useState } from "react";
import { Menu, Segment, Sidebar } from "semantic-ui-react";
import { NavLink, Link, useLocation } from "react-router-dom";
import LogoutButton from "../../headers/logoutButton";
import { Creators as UserActions } from "../../../redux/reducers/user";
import { Creators as AppActions } from "../../../redux/reducers/app";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

function SidebarExtended(props) {
  const { menuList, goToSubMenu, activeIndex,actions,state, auth0Client } = props;
  const { first_name,email_address  } = state.user
  const { sidebarIsOpen } = state.app;
  const {
    actions: { toggleSidebar }
  } = props;

  const [activeSubMenuIndex, setActiveSubMenuIndex] = useState("");

  const [homeActive, setHomeActive] = useState(false);
  const location = useLocation();

  useEffect(() => {
    // Set homeActive to true if the current path is '/home'
    setHomeActive(location.pathname === "/home");
  }, [location.pathname]);

  const handleHomeButtonClick = () => {
    // Toggle sidebar visibility
    toggleSidebar();
    // Deactivate home button if it's already active
    if (homeActive) {
      setHomeActive(false);

    }
  };

  const handleNavButtonClick = () => {
    // Deactivate home button if it's active
    if (homeActive) {
      setHomeActive(false);
    }
  };

  const subMenuBar = (item, index) => {
    return (
      <>
        {item?.children?.length > 0 && (
          <div className="accordion hoverClass">
            <span className="submenu-title">{item?.name}</span>
            {item?.children?.map((item, index) => {
              let iconClass = index == activeSubMenuIndex ? "active" : "";
              return (
                <NavLink
                  className={`${"submenu_active "} ${iconClass}`}
                  to={item.path}
                  onClick={() => {
                    goToSubMenu(item, index);
                  }}
                >
                  {item.name}
                </NavLink>
              );
            })}
          </div>
        )}
      </>
    );
  };

  return (
    <div className="sidebarMenu sidebarExtendedMenu">
      <Sidebar
        as={Menu}
        animation={"push"}
        direction={"left"}
        icon="labeled"
        inverted
        vertical
        visible={true}
        width="thin"
      >
        <div className ="small-bound">
<div className="separate-upper-lower">

        <div className="logoContainer">
          <Link to="/home">
            <img className="logo" src={"/i/flipt-Logo.svg"} />
          </Link>
          <button className="toggleNav-close" onClick={toggleSidebar} type="button">
              <img  src={"/i/sidebar-arrow-left.svg"} alt="close navigation bar"/>
            </button>
          </div>
          <div className="item-list ">
            <Link
              to={"./home"}
            >
              <div className="sidebar__Container">
                <img
                  src="/i/home.svg"
                  className="svg icon-inActive"
                />
                <p>Home</p>
              </div>

            </Link>
          </div>

        {menuList.map((item, index) => {
          let iconClass = index == activeIndex ? "icon-active" : "";
          let textClass = index == activeIndex ? "active-name" : "";
          if (item.hidden) {
            return null;
          } else if (!item.hidden && item.name !== 'Contact and Help' ){
            return (
              <>
                <div className="item-list-container">
                  <NavLink
                    as="a"
                    className="item-list"
                    onClick={() => {
                      goToSubMenu(item, index);
                    }}
                    to={item.path}
                  >
                    <div className="sidebar__Container">
                      <img
                        src={item.icon}
                        className={`${"svg icon-inActive"} ${iconClass}`}
                      />
                      <p className={`${textClass}`}>{item.name}</p>
                    </div>
                  </NavLink>
                </div>
                {subMenuBar(item, index)}
              </>
            ); 
          }
        })}
        </div>
        <div className="preferencesContainer-open">
            <div className="preferencesContainer_topSection">
               <div className="preferencesContainer_topSection__item">
                 <NavLink as= "a" className="preferencesContainer_topSection__navLink" to={"/home"}>
                    <div className="item-container">
                      {/* <Help className="icon"/> */}
                      <Link to = "/help">
                        <img src="/i/help.svg" className="icon" alt="Help Icon" />
                      </Link>
                      <p>Help</p>
                    </div>
                 </NavLink>
                </div>
                <div className="preferencesContainer_topSection__item__second">
                 <NavLink as= "a" className="preferencesContainer_topSection__navLink" to={"/home"}>
                    <div className="item-container">
                    <img src="/i/settings.svg" className="icon" alt="Help Icon" />
                      <p>Settings</p>
                    </div>
                 </NavLink>
                </div>
            </div>
            <div className="preferencesContainer_bottomSection">
             <div className="preferencesContainer_bottomSection__avatar"></div>
             <div className="preferencesContainer_bottomSection__details">
              <p>{first_name}</p>
              <span>{email_address}</span>
             </div>
             <div className="preferencesContainer_bottomSection__logout">
             <LogoutButton classAttributes="smaller-logout" userLogout={() => actions.userLogout()} auth0Client={auth0Client}/>
             </div>
            </div>
        </div>
        </div>

      </Sidebar>
    </div>
  );
}

const mapStateToProps = (state) => ({
  state: {
    app: state.app,
    user: state.user,
    auth0Client: state?.ssoLogin?.auth0Client,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...UserActions,
    ...AppActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SidebarExtended)
