export const DRAFT = 'DRAFT'
export const FOR_REVIEW = 'FOR_REVIEW'
export const TEST_APPROVED = 'TEST_APPROVED'
export const TEST_READY = 'TEST_READY'
export const REJECTED = 'REJECTED'
export const APPROVED = 'APPROVED'
export const PUBLISH_READY = 'PUBLISH_READY'
export const PUBLISHED = 'PUBLISHED'
export const PUBLISH_FAILED = 'PUBLISH_FAILED'
export const PUBLISH_IN_PROGRESS = 'PUBLISH_IN_PROGRESS'
export const PUBLISH_MARKED_FOR_RETRY = 'PUBLISH_MARKED_FOR_RETRY'

export const ALL_RPM_STATUSES = [DRAFT, FOR_REVIEW, TEST_APPROVED, TEST_READY, REJECTED, APPROVED, PUBLISH_READY, PUBLISHED, PUBLISH_FAILED, PUBLISH_MARKED_FOR_RETRY, PUBLISH_IN_PROGRESS]

export const APPROVAL_QUEUE_STATUSES = [FOR_REVIEW, TEST_APPROVED, TEST_READY, APPROVED, PUBLISH_READY]
