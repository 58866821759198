import React from 'react'

import './styles.scss'
import FliptRadio from '../../../../../components/v2/fliptRadio'
export default (props) => {
	const { configValues, currentValue } = props

	return (
		<div id="configuration-component">
			<div className="configuration-picker">
				{configValues.map((configValue, index) => {
					return (
						<FliptRadio
							label={configValue.label}
							value={index}
							checked={currentValue === index}
							onChange={() => props.handleChange(index)}
							disabled={configValue.disabled}
						/>
					)
				})}
			</div>
		</div>
	)
}
