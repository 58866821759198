import React from 'react'

import './styles.scss'
import FliptButton from '../../../../components/form/fliptButton'

export default function SaveTierModal(props) {
  const {
    form, criteria_ids, conditions, onSaveClick, toggleModal, message,
  } = props

  const handleClick = () => {
    onSaveClick({ ...form, criteria_ids, conditions })
    toggleModal()
  }

  return (
    <div className="saveTierModal">
      <div className="header-text">
        <h3>{message}</h3>
      </div>
      <div className="buttons-container">
        <FliptButton className="primary" name="Ok" onClick={handleClick} />
        <FliptButton className="primary" name="Cancel" onClick={toggleModal} />
      </div>
    </div>
  )
}
