import React from 'react'

import './styles.scss'
import { Menu, Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

export default function FliptActionMenu(props) {
  const {
    actionMenuStyle,
    approvalParamsMapping,
    data,
    menuClassName,
    onApproveClick,
    pathDrugs,
    pathHierarchy,
    moduleViewDrugs,
    pathname,
    qsArgs,
    showActionsMenu,
    onClick
  } = props

  const createApprovalData = () => {
    const approveData = {}
    Object.keys(approvalParamsMapping).forEach((param) => {
      approveData[approvalParamsMapping[param]] = data[param]
    })
    return approveData
  }

  function createQS(searchArgs, row) {
    const querystring = Object.keys(searchArgs).reduce((accum, key) => {
      const colName = searchArgs[key]
      const val = row[colName] && typeof row[colName] !== 'string' ? row[colName].toString() : row[colName]
      accum += row[colName] ? `${key}=${val.trim()}&` : ''
      return accum
    }, '?').slice(0, -1)
    return querystring
  }

  const qs = createQS(qsArgs, data)
  const approvalData = approvalParamsMapping ? createApprovalData() : null

  let hasAction = false;
  if(menuClassName === 'ellipsisActionMenu' && qsArgs.hasOwnProperty('action')) {
    if(qsArgs['action'] === 'COPY_OBJECT' ) {
      hasAction = true;
    }
  }

  const _onClick = (item) => {
    qsArgs['selected_action'] = item;
    data['qsArgs'] = qsArgs;
    onClick(data);
  }

  return (
    !!showActionsMenu
    && (
      <div className={menuClassName || 'action-menu'} style={actionMenuStyle}>
        <Menu size="small" fluid vertical>
          {!!approvalData && (
            <Menu.Item
              name="checkLinkedPlans"
            >
              Check Linked Plans
            </Menu.Item>
          )}
          {!!pathHierarchy && (
            <Menu.Item
              name="companyHierarchyManagement"
            >
              <Link
                className="link"
                to={{
                  pathname: pathHierarchy,
                  search: qs,
                }}
              >
                Hierarchy
              </Link>
            </Menu.Item>
          )}
          {
            !!hasAction && (
                <Menu.Item
                  name="sendForReview"
                  >
                  <Link
                    className='link'
                    to={{
                      pathname,
                      search: qs + "&action=send_for_review",
                      state: {
                        data
                      }
                    }}
                    onClick={()=>_onClick('send_for_review')}
                    >
                      Send for Review
                    </Link>

                </Menu.Item>
          )}
          { !!hasAction &&
                <Menu.Item
                  name="copyObject"
                  >
                    <Link
                      className='link'
                      to={{
                        pathname,
                        search: qs + "&action=copy_object",
                        state: {}
                      }}
                      onClick={()=>_onClick('copy_object')}
                      >
                      Copy Object
                    </Link>
                </Menu.Item>
          }
          {!!hasAction && qsArgs['module'] === 'EDIT' && <Menu.Item
            name="edit"
          >
            <Link
              className="link"
              to={{
                search: qs,
                state: {
                  editMode: true,
                },
              }}
              onClick={()=>_onClick('edit')}
            >
              Edit
            </Link>
          </Menu.Item>
          }
          {!hasAction && <Menu.Item
            name="edit"
          >
            <Link
              className="link"
              to={{
                pathname,
                search: qs,
                state: {
                  editMode: true,
                },
              }}
            >
              Edit
            </Link>
          </Menu.Item>
          }
          {!hasAction && data.status.toLowerCase() === 'published'
            && (
              <Menu.Item
                name="viewDrugs"
              >
                <Link
                  className="link"
                  to={{
                    pathname: pathDrugs,
                    state: {
                      data,
                      moduleViewDrugs,
                    },
                  }}
                >
                  View Drugs
                </Link>
              </Menu.Item>
            )}
          {!!approvalData && (
            <Menu.Item
              name="changeStatus"
            >
              <Button
                onClick={() => onApproveClick(approvalData, 250)}
              >
                Approve
              </Button>
            </Menu.Item>
          )}
        </Menu>
      </div>
    )
  )
}
