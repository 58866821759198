import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import BreadCrumb from '../../../components/breadCrumb'
import { Creators as MemberLookupActions } from '../../../redux/reducers/api/memberLookup'
import { Creators as ManualClaimEntryActions } from '../../../redux/reducers/api/manualClaimEntry'
import { Creators as AppActions } from '../../../redux/reducers/app'
import { Creators as PrescriberLookupActions } from '../../../redux/reducers/api/prescriberLookup'
import { Creators as PharmacyLookupActions } from '../../../redux/reducers/api/pharmacyLookup'
import { Creators as DrugLookupActions } from '../../../redux/reducers/api/drugLookup'
import { Creators as AutoSuggestActions } from '../../../redux/reducers/api/autoSuggest'
import { Creators as TestClaimActions } from '../../../redux/reducers/api/testClaim'

import './styles.scss'
import FliptButton from '../../../components/form/fliptButton'
import FliptPaDatePicker from '../../../components/form/fliptPaDatepicker'
import FliptPaDropdown from '../../../components/form/fliptPaDropdown'
import FliptPaInput from '../../../components/form/fliptPaInput'
import { claimTypeValues } from '../../../utils/dropdownValues'
import FliptDrugSearch from '../../../components/fliptDrugSearch'
import FliptPrescriberSearch from '../../../components/fliptPrescriberSearch'
import FliptPharmacySearch from '../../../components/fliptPharmacySearch'
import FliptMemberSearch from '../../../components/fliptMemberSearch'
import { Icon } from 'semantic-ui-react'
import { cloneDeep } from 'lodash'
import moment from 'moment'
import RejectMessageModal from './rejectMessageModal'
import config from '../../../config'
import { createRef } from 'react'

class TestClaims extends Component {
  constructor(props) {
    super(props)
    // if redirected from claim-details, prepopulate the state
    const { location: { state } } = this.props
    const claimDetails = state ? { ...state } : {}
    for (const property in claimDetails) {
      // if values are null, claim details(agiGrid) will set it to '--'
      // so converting it back to empty string
      if (!claimDetails[property] || claimDetails[property] === '--') {
        claimDetails[property] = ''
      }
    }
    let {
      cardholder_id,
      product_id,
      prescriber_id,
      service_provider_id,
      days_supply,
      quantity_dispensed,
      prescription_reference_number,
      ingredient_cost_submitted,
      dispensing_fee_submitted,
      usual_and_customary_charge,
      gross_amount_due,
      basis_of_cost_determination,
      fill_number,
      compound_code,
      date_prescription_written
    } = claimDetails
    let current_date = '', claim_type = '', daw_code = ''
    if (claimDetails && claimDetails.cardholder_id) {
      // this flag is used to indicate that values are autopopulated in searchForm
      // and that we have redirected user from other screen to here to test a specific claim
      this.isRedirect = true
      claim_type = 'B4'
      current_date = new Date()
      date_prescription_written = date_prescription_written && moment(date_prescription_written, 'YYYYMMDD').toDate()
      daw_code = '0'
      quantity_dispensed = parseInt(quantity_dispensed)
    }
    this.state = {
      searchForm: {
        memberId: cardholder_id || '',
        memberDob: '',
        ndc: product_id || '',
        prescriberNpi: prescriber_id || '',
        pharmacyNpi: service_provider_id || '',
      },
      prescriptionForm: {
        referenceNumber: prescription_reference_number || '',
        dateOfService: current_date || '',
        claimType: claim_type || '',
        quantity: quantity_dispensed || '',
        daysOfSupply: days_supply || '',
        ingredient_cost_submitted: ingredient_cost_submitted || '',
        dispensing_fee_submitted: dispensing_fee_submitted || '',
        gross_amount_due: gross_amount_due || '',
        usual_and_customary_charge: usual_and_customary_charge || '',
        fill_number: fill_number || '',
        compound_code: compound_code || '',
        date_prescription_written: date_prescription_written || '',
        daw_code: daw_code || '',
        basis_of_cost_determination: basis_of_cost_determination || '',
      },
      optionalPrescriptionForm: {
        incentiveAmt: '',
        flatSaleTax: '',
        saleTax: '',
        saleTaxPer: '',
        saleTaxBasis: '',
        submission_clarification_code: ''
      },
      memberData: null,
      drugData: null,
      prescriberData: null,
      pharmacyData: null,
      testClaimData: null,
      resultValues: [],
      loading: false,
      minCharacters: 3,
      customDrugData: null,
      memberValidated: false,
      drugValidated: false,
      pharmacyValidated: false,
      prescriberValidated: false,
      memberSearched: false,
      drugSearched: false,
      pharmacySearched: false,
      prescriberSearched: false,
      memberModalOpen: false,
      pharmacyModalOpen: false,
      prescriberModalOpen: false,
      drugModalOpen: false,
      responseData: null,
      isCentrewellUser: false,
      hovering: false,
      hoverText: null,
      hoverType: null,
      ref: createRef(null)
    }
  }

  componentDidMount() {
    this.checkIfCentrewellUser()
    if (this.isRedirect) {
      setTimeout(() => {
        this.validateSearchFromForClaimDetails()
      }, config.COMPONENT_DATA_LOAD_TIME)
    }
  }

  checkIfCentrewellUser = () => {
    const { user } = this.props.state
    if (user.role_key?.includes('centerwell_test_claim_generation')) {
      this.setState({
        prescriptionForm: {
          referenceNumber: '9999999999',
          dateOfService: new Date(),
          claimType: 'B4',
          quantity: '',
          daysOfSupply: '',
          ingredient_cost_submitted: '999999.99',
          dispensing_fee_submitted: '999999.99',
          gross_amount_due: '999999.99',
          usual_and_customary_charge: '999999.99',
          fill_number: '1',
          compound_code: '1',
          date_prescription_written: new Date(),
          daw_code: '0',
          basis_of_cost_determination: '01',
        },
        searchForm: {
          memberId: '',
          memberDob: '',
          ndc: '',
          prescriberNpi: '1111111111',
          pharmacyNpi: '1063675684',
        },
        isCentrewellUser: true
      })
      this._searchOnMountData('PRESCRIBER_SEARCH', '1111111111')
    }
  }

  validateSearchFromForClaimDetails = () => {
    const { actions, history } = this.props
    history.replace()
    const { memberId, ndc, prescriberNpi, pharmacyNpi } = this.state.searchForm
    let [memberSearched, drugSearched, prescriberSearched, pharmacySearched] = Array(4).fill(false)
    // member search
    if (memberId) {
      const searchData = {
        member_identifier: 'member_id',
        id: memberId,
        noDataClearing: true
      }
      actions.setMemberLookupFailure(false)
      actions.getMemberLookupData(searchData)
      memberSearched = true
    }
    // drug search
    if (ndc) {
      actions.setNdcLookupFailed(false)
      actions.clearDrugInfoBySearch()
      actions.clearManualClaimEntryNDC()
      actions.fetchManualClaimEntryNDC({ ndc, noDataClearing: true })
      drugSearched = true
    }

    if (prescriberNpi) {
      // prescriber search
      actions.setPrescriberLookupFailed(false)
      actions.getPrescriberLookupData({ prescriber_npi: prescriberNpi })
      prescriberSearched = true
    }

    // pharmacy search
    if (pharmacyNpi) {
      const form = {
        pharmacytype: 'RETAIL',
        networkStatus: 'ACTIVE',
        pharmacynpi: pharmacyNpi
      }
      actions.setPharmacyLookupFailed(false)
      actions.getPharmacyLookupData({ form, isTestClaim: true })
      pharmacySearched = true
    }
    this.setState({
      memberSearched,
      drugSearched,
      prescriberSearched,
      pharmacySearched,
      searchBy: drugSearched ? 'NDC' : null
    })
  }

  _searchOnMountData(type, data) {
    const { actions } = this.props
    if (type === 'PRESCRIBER_SEARCH') {
      let params = { prescriber_npi: data }
      actions.setPrescriberLookupFailed(false)
      actions.getPrescriberLookupData(params)
    }
    if (type === 'PHARMACY_SEARCH') {
      let form = {
        pharmacytype: 'RETAIL',
        networkStatus: 'ACTIVE',
        pharmacynpi: data
      }
      actions.setPharmacyLookupFailed(false)
      actions.getPharmacyLookupData({ form, isTestClaim: true })
    }
  }

  componentDidUpdate(prevProps) {
    const { props } = this
    const { memberModalOpen, pharmacyModalOpen, prescriberModalOpen,
      drugModalOpen, isCentrewellUser, searchForm, ref } = this.state
    const { memberLookup, manualClaimEntry, drugLookup,
      prescriberLookupData, pharmacyLookupData, memberLookupFailed, ndcLookupFailed,
      pharmacyLookupFailed, prescriberLookupFailed, testClaimData, user } = props.state
    if (prevProps.state.user !== user && user?.role_key) {
      this.checkIfCentrewellUser()
    }

    if (prevProps.state.memberLookup?.member_details !== memberLookup?.member_details &&
      memberLookup?.member_details?.member_id) {
      if (isCentrewellUser) {
        let memberDob = new Date(memberLookup?.member_details.date_of_birth).toLocaleDateString();
        let selectedDob = new Date(searchForm.memberDob).toLocaleDateString();
        if (selectedDob !== memberDob) {
          props.actions.setMemberLookupFailure(true)
          return;
        }
      }
      this.setState({ memberData: memberLookup.member_details, memberValidated: true })
    }
    if (prevProps.state.memberLookupFailed !== memberLookupFailed &&
      memberLookupFailed && !memberModalOpen && !isCentrewellUser) {
      this.setState({ memberModalOpen: true })
      this._findMember()
    }
    if (prevProps.state.ndcLookupFailed !== ndcLookupFailed && ndcLookupFailed
      && !drugModalOpen && !isCentrewellUser) {
      this.setState({ drugModalOpen: true })
      this._findDrug()
    }
    if (prevProps.state.pharmacyLookupFailed !== pharmacyLookupFailed &&
      pharmacyLookupFailed && !pharmacyModalOpen && !isCentrewellUser) {
      this.setState({ pharmacyModalOpen: true })
      this._findPharmacy()
    }
    if (prevProps.state.prescriberLookupFailed !== prescriberLookupFailed &&
      prescriberLookupFailed && !prescriberModalOpen && !isCentrewellUser) {
      this.setState({ prescriberModalOpen: true })
      this._findPrescriber()
    }
    if (prevProps.state.prescriberLookupData !== prescriberLookupData && prescriberLookupData.length) {
      this.setState({ prescriberData: prescriberLookupData[0], prescriberValidated: true })
      if (isCentrewellUser) {
        this.setState({
          prescriberData: prescriberLookupData[0],
          prescriberSearched: true, prescriberValidated: true
        })
        this._searchOnMountData('PHARMACY_SEARCH', '1063675684')
      } else {
        this.setState({ prescriberData: prescriberLookupData[0], prescriberValidated: true })
      }
    }

    if ((prevProps.state?.drugLookup !== drugLookup) ||
      prevProps.state?.manualClaimEntry !== manualClaimEntry) {
      this._updateOnComponent()
    }
    if (prevProps.state.pharmacyLookupData !== pharmacyLookupData && pharmacyLookupData.length) {
      if (isCentrewellUser) {
        this.setState({
          pharmacyData: pharmacyLookupData[0], pharmacyValidated: true,
          pharmacySearched: true
        })
      } else {
        this.setState({ pharmacyData: pharmacyLookupData[0], pharmacyValidated: true })
      }
    }
    if (prevProps.state.testClaimData !== testClaimData && testClaimData?.response?.length) {
      const responseData = testClaimData.response[0]
      this.setState({ responseData })
      ref.current?.scrollIntoView({ behavior: 'smooth' });
    }

  }

  _updateOnComponent() {
    const { state } = this.props
    const { searchBy } = this.state
    switch (searchBy) {
      case 'NDC': {
        if (state.manualClaimEntry?.ndc?.drug_name) {
          this.setState({ drugData: state.manualClaimEntry.ndc, drugValidated: true });
        }
      }
        break;
    }
  }


  _searchData(buttonType) {
    const { actions } = this.props
    const { state: { searchForm, isCentrewellUser, pharmacyValidated, prescriberValidated } } = this
    const header = 'Fields Missing'
    switch (buttonType) {
      case 'MEMBER_SEARCH': {
        this.setState({
          memberData: null, memberSearched: true,
          memberValidated: false, memberModalOpen: false
        })
        if (!searchForm.memberId && !isCentrewellUser) {
          this._findMember()
          return;
        }
        if ((!searchForm.memberId || !searchForm.memberDob) && isCentrewellUser) {
          const message = `Please Add Member Id and DOB to search a Member.`
          this._showTransitionalPortal(header, message);
          return;
        }
        let searchData = {}
        searchData = {
          member_identifier: 'member_id',
          id: searchForm.memberId,
          noDataClearing: true
        }
        if (isCentrewellUser) {
          searchData.date_of_birth = searchForm.memberDob
        }
        actions.setMemberLookupFailure(false)
        actions.getMemberLookupData(searchData)
      }
        break;
      case 'DRUG_SEARCH': {
        this._searchDrug()
      }
        break;
      case 'PRESCRIBER_SEARCH': {
        this.setState({
          prescriberData: null,
          prescriberSearched: true, prescriberModalOpen: false
        })
        if (!searchForm.prescriberNpi && !prescriberValidated) {
          this._findPrescriber()
          return;
        }
        let params = { prescriber_npi: searchForm.prescriberNpi }
        actions.setPrescriberLookupFailed(false)
        actions.getPrescriberLookupData(params)
      }
        break;
      case 'PHARMACY_SEARCH': {
        this.setState({
          pharmacyData: null, pharmacySearched: true,
          pharmacyValidated: false, pharmacyModalOpen: false
        })
        if (!searchForm.pharmacyNpi && !pharmacyValidated) {
          this._findPharmacy()
          return;
        }
        let form = {
          pharmacytype: 'RETAIL',
          networkStatus: 'ACTIVE',
          pharmacynpi: searchForm.pharmacyNpi
        }
        actions.setPharmacyLookupFailed(false)
        actions.getPharmacyLookupData({ form, isTestClaim: true })
      }
        break;
    }
  }

  _searchDrug = () => {
    const { actions } = this.props
    const { searchForm } = this.state
    if (searchForm.ndc.length) {
      this.setState({ searchBy: 'NDC', drugData: null, drugSearched: true, drugModalOpen: false })
      actions.setNdcLookupFailed(false)
      actions.clearDrugInfoBySearch()
      actions.clearManualClaimEntryNDC()
      actions.fetchManualClaimEntryNDC({ ndc: searchForm.ndc, noDataClearing: true })
    } else {
      this.setState({
        drugData: null, drugSearched: true,
        drugValidated: false, drugModalOpen: false
      })
      this._findDrug()
    }
  }

  _updateSearchForm = (el, dropdown) => {
    const { searchForm } = this.state
    const tempSearchForm = cloneDeep(searchForm)
    const { name, value } = dropdown || el.currentTarget
    if (name == 'memberDob') this.setState({ memberData: null, memberSearched: false, memberValidated: false, memberModalOpen: false })
    if (name == 'memberId') this.setState({ memberData: null, memberSearched: false, memberValidated: false, memberModalOpen: false })
    if (name == 'ndc') this.setState({ drugData: null, drugSearched: false, drugValidated: false, drugModalOpen: false })
    if (name == 'prescriberNpi') this.setState({ prescriberData: null, prescriberSearched: false, prescriberValidated: false, prescriberModalOpen: false })
    if (name == 'pharmacyNpi') this.setState({ pharmacyData: null, pharmacySearched: false, pharmacyValidated: false, pharmacyModalOpen: false })
    tempSearchForm[name] = value
    this.setState({ searchForm: tempSearchForm, responseData: null })
  }


  _updatePrescriptionForm = (el, dropdown) => {
    const { prescriptionForm } = this.state
    const { name, value } = dropdown || el.currentTarget
    if (name === 'compound_code' && (value !== "1" && value !== "2" && value !== "")) {
      const header = 'Fields Missing'
      const message = 'Compond Code can be either 1 or 2'
      this._showTransitionalPortal(header, message);
      return;
    }
    prescriptionForm[name] = value
    this.setState({ prescriptionForm, responseData: null })
  }


  _updateOptionalPrescriptionForm = (el, dropdown) => {
    const { optionalPrescriptionForm } = this.state
    const { name, value } = dropdown || el.currentTarget
    optionalPrescriptionForm[name] = value
    this.setState({ optionalPrescriptionForm, responseData: null })
  }


  _showTransitionalPortal = (header, message) => {
    const { props } = this;
    const transitionalPortal = {
      header: header,
      copy: message,
    }
    props.actions.displayTransitionalPortal(transitionalPortal)
  }

  _generateTestClaim = () => {
    const { state: { prescriptionForm, memberValidated, drugValidated, prescriberValidated,
      pharmacyValidated, memberData, customDrugData, prescriberData, drugData,
      pharmacyData, optionalPrescriptionForm } } = this;

    let header = 'Fields are missing';
    let message = 'to submit test claim';

    if (!memberValidated) {
      message = `Please Validate Member details ${message}`
      this._showTransitionalPortal(header, message);
      return;
    }

    if (!drugValidated) {
      message = `Please Validate Drug details ${message}`
      this._showTransitionalPortal(header, message);
      return;
    }

    if (!prescriberValidated) {
      message = `Please Validate Prescriber details ${message}`
      this._showTransitionalPortal(header, message);
      return;
    }

    if (!pharmacyValidated) {
      message = `Please Validate Pharmacy details ${message}`
      this._showTransitionalPortal(header, message);
      return;
    }
    const test = Object.values(prescriptionForm).every((value) => value !== '')
    if (!test) {
      header = 'Fields Missing'
      message = 'Please add All Mandatory Fields to Submit Test Claims'
      this._showTransitionalPortal(header, message);
      return;
    }

    header = 'Invalid Field Type';
    message = 'must be a number';

    const numberRegex = new RegExp('^[0-9]+$');
    const referenceNumberIsNumber = numberRegex.test(prescriptionForm.referenceNumber);
    if (!referenceNumberIsNumber) {
      message = `Rx Number ${message}`
      this._showTransitionalPortal(header, message);
      return;
    }
    const quantityIsNumber = numberRegex.test(prescriptionForm.quantity);
    if (!quantityIsNumber) {
      message = `Quantity ${message}`
      this._showTransitionalPortal(header, message);
      return;
    }
    const daysOfSupplyIsNumber = numberRegex.test(prescriptionForm.daysOfSupply);
    if (!daysOfSupplyIsNumber) {
      message = `Days of supply ${message}`
      this._showTransitionalPortal(header, message);
      return;
    }

    const params = {
      basis_of_cost_determination: prescriptionForm.basis_of_cost_determination,
      cardholder_f_name: memberData?.first_name,
      cardholder_id: memberData?.member_id,
      cardholder_l_name: memberData?.last_name,
      compound_code: prescriptionForm.compound_code || "1",
      date_of_birth: memberData.date_of_birth ? moment(memberData.date_of_birth, 'MM/DD/YYYY').format('YYYYMMDD') : '',
      date_of_service: moment(prescriptionForm.dateOfService).format('YYYYMMDD'),
      date_prescription_written: moment(prescriptionForm.date_prescription_written).format('YYYYMMDD'),
      daw_code: prescriptionForm.daw_code,
      days_supply: prescriptionForm.daysOfSupply,
      dispensing_fee_submitted: prescriptionForm.dispensing_fee_submitted || "0",
      fill_number: prescriptionForm.fill_number || "1",
      flat_sales_tax_amount_submitted: optionalPrescriptionForm.flatSaleTax || "0",
      gross_amount_due: prescriptionForm.gross_amount_due || "0",
      group_id: memberData?.group,
      incentive_amount_submitted: optionalPrescriptionForm.incentiveAmt || "0",
      ingredient_cost_submitted: prescriptionForm.ingredient_cost_submitted || '0',
      patient_f_name: memberData?.first_name,
      patient_l_name: memberData?.last_name,
      patient_pay_amount: "0",
      patient_relationship_code: memberData.relationship_code || "0",
      percentage_sales_tax_amount_submitted: optionalPrescriptionForm.saleTax || "0",
      percentage_sales_tax_basis_submitted: optionalPrescriptionForm.saleTaxBasis || "0",
      percentage_sales_tax_rate_submitted: optionalPrescriptionForm.saleTaxPer || "0",
      person_code: memberData?.person_code,
      prescriber_first_name: prescriberData.first_name || prescriberData.provider_first_name,
      prescriber_id_qualifier: "01",
      prescriber_id: prescriberData.npi,
      prescriber_last_name: prescriberData.last_name || prescriberData.provider_last_name_legal_name,
      prescriber_phone: prescriberData.phone_number || prescriberData.provider_business_practice_location_address_telephone_number,
      prescription_origin_code: "01",
      prescription_reference_number: prescriptionForm?.referenceNumber,
      product_id: customDrugData?.ndc || drugData?.ndc,
      quantity_dispensed: prescriptionForm.quantity,
      scc: optionalPrescriptionForm.submission_clarification_code,
      service_provider_id: pharmacyData?.pharmacynpi,
      usual_and_customary_charge: prescriptionForm.usual_and_customary_charge || "0",
    }
    this.props.actions.submitTestClaimData(params);
  }

  _cancelClaim = () => {
    this.setState({
      searchForm: {
        date_of_birth: '',
        date_of_birth: '',
        drug_name: '',
        drug_strength: '',
        gpi: '',
        memberId: '',
        ndc: '',
        prescriberNpi: '',
        pharmacyNpi: '',
        prescriber_first_name: '',
        prescriber_last_name: '',
        pharmacyState: '',
        pharmacyCity: '',
        pharmacyName: '',
        pharmacyzip1: ''
      },
      prescriptionForm: {
        referenceNumber: '',
        dateOfService: '',
        claimType: '',
        quantity: '',
        daysOfSupply: '',
        ingredient_cost_submitted: '',
        dispensing_fee_submitted: '',
        gross_amount_due: '',
        usual_and_customary_charge: '',
        fill_number: '',
        compound_code: '',
        date_prescription_written: '',
        daw_code: '',
        basis_of_cost_determination: '',
      },
      optionalPrescriptionForm: {
        incentiveAmt: '',
        flatSaleTax: '',
        saleTax: '',
        saleTaxPer: '',
        saleTaxBasis: '',
        submission_clarification_code: ''
      },
      memberData: null,
      drugData: null,
      prescriberData: null,
      pharmacyData: null,
      customDrugData: null,
      memberValidated: false,
      drugValidated: false,
      pharmacyValidated: false,
      prescriberValidated: false,
      memberSearched: false,
      drugSearched: false,
      pharmacySearched: false,
      prescriberSearched: false,
      memberModalOpen: false,
      pharmacyModalOpen: false,
      prescriberModalOpen: false,
      drugModalOpen: false,
      responseData: null,
      isCentrewellUser: false,
      hovering: false,
      hoverText: null,
    })
  }

  updateMemberData = (data) => {
    if (data?.member_id) {
      const { searchForm } = this.state
      const tempSearchForm = { ...searchForm, memberId: data.member_id }
      this.setState({
        memberData: data,
        searchForm: tempSearchForm,
        memberValidated: true,
      })
    }
    else this.setState({ memberData: data, memberValidated: false })
  }

  updateDrugData = (data) => {
    if (data?.ndc) {
      const { searchForm } = this.state
      const tempSearchForm = { ...searchForm, ndc: data.ndc }
      this.setState({
        drugData: data,
        searchForm: tempSearchForm,
        drugValidated: true,
      })
    }
    else this.setState({ drugData: data, drugValidated: false })
  }

  updatePharmacyData = (data) => {
    if (data?.pharmacynpi) {
      const { searchForm } = this.state
      const tempSearchForm = { ...searchForm, pharmacyNpi: data.pharmacynpi }
      this.setState({
        pharmacyData: data,
        searchForm: tempSearchForm,
        pharmacyValidated: true,
      })
    }
    else this.setState({ pharmacyData: data, pharmacyValidated: false })
  }

  updatePrescriberData = (data) => {
    if (data?.npi) {
      const { searchForm } = this.state
      const tempSearchForm = { ...searchForm, prescriberNpi: data.npi }
      this.setState({
        prescriberData: data,
        searchForm: tempSearchForm,
        prescriberValidated: true,
      })
    }
    else this.setState({ prescriberData: data, prescriberValidated: false })
  }

  _findMember = () => {
    const { actions } = this.props
    const { searchForm } = this.state
    actions.setModalComponent({
      modalProperties: { size: 'large' },
      contents: <FliptMemberSearch
        updateMemberData={(data) => this.updateMemberData(data)}
        searchForm={searchForm} />,
    })
    actions.toggleModal()
  }

  _findDrug = () => {
    const { actions } = this.props
    const { searchForm } = this.state
    actions.setModalComponent({
      modalProperties: { size: 'large' },
      contents: <FliptDrugSearch
        editMode={true}
        searchForm={searchForm}
        updateDrugData={(data) => this.updateDrugData(data)}
      />,
    })
    actions.toggleModal()
  }

  _findPharmacy = () => {
    const { actions } = this.props
    const { searchForm } = this.state
    actions.setModalComponent({
      modalProperties: { size: 'large' },
      contents: <FliptPharmacySearch
        updatePharmacyData={(data) => this.updatePharmacyData(data)}
        searchForm={searchForm}
        editMode={true}
      />,
    })
    actions.toggleModal()
  }

  _rejectMessageModal = () => {
    const { actions } = this.props
    const { responseData } = this.state
    actions.setModalComponent({
      modalProperties: { size: 'large' },
      contents: <RejectMessageModal
        testClaimData={responseData} />,
    })
    actions.toggleModal()
  }

  _findPrescriber = () => {
    const { actions } = this.props
    const { searchForm } = this.state
    actions.setModalComponent({
      modalProperties: { size: 'large' },
      contents: <FliptPrescriberSearch
        editMode
        updatePrescriberData={(data) => this.updatePrescriberData(data)}
        searchForm={searchForm} />,
    })
    actions.toggleModal()
  }
  _clearFields = (type) => {
    const { searchForm } = this.state
    const tempForm = { ...searchForm }
    switch (type) {
      case 'MEMBER': {
        tempForm.memberId = ''
        this.setState({ searchForm: tempForm, memberData: null, memberValidated: false })
      }
        break;
      case 'DRUG': {
        tempForm.ndc = ''
        this.setState({ searchForm: tempForm, drugData: null, drugValidated: false })
      }
        break;
      case 'PHARMACY': {
        tempForm.pharmacyNpi = ''
        this.setState({ searchForm: tempForm, pharmacyData: null, pharmacyValidated: false })
      }
        break;
      case 'PRESCRIBER': {
        tempForm.prescriberNpi = ''
        this.setState({ searchForm: tempForm, prescriberData: null, prescriberValidated: false })
      }
        break;
    }
  }
  handleMouseOver = (type) => {
    let hoverText = 'Enter the users Member ID and DOB, then press Search.The information will be validated against current system eligibility and show “validated” if a match occurs.If error occurs, please check the ID and DOB and re - enter.'
    if (type === 'PHARMACY') {
      hoverText = 'The pharmacy is defaulted to CenterWell Mail. If using CenterWell Specialty, you will need to update the NPI to <insert CW SP NPI>.'
    }
    if (type === 'DRUG') {
      hoverText = 'Enter the NDC to be used. If you do not have a specific NDC, you may press Search first and lookup by drug name. Please note, pricing may vary based on the NDC chosen.'
    }
    if (type === 'PRESCRIBER') {
      hoverText = 'The prescriber is defaulted and is not required to be changed. If you prefer, you may enter the specific prescriber for the claim you are testing.'
    }
    this.setState({ hovering: true, hoverText, hoverType: type })
  }

  handleMouseOut = () => {
    this.setState({ hovering: false, hoverText: null, hoverType: null })
  }

  render() {
    const { state: {
      prescriptionForm,
      searchForm,
      optionalPrescriptionForm
    } } = this
    const { state: {
      memberLookupFailed,
      ndcLookupFailed,
      prescriberLookupFailed,
      pharmacyLookupFailed,
      user
    } } = this.props
    const {
      memberSearched,
      memberValidated,
      pharmacySearched,
      pharmacyValidated,
      prescriberSearched,
      prescriberValidated,
      drugSearched,
      drugValidated,
      responseData,
      isCentrewellUser,
      hovering,
      hoverText,
      hoverType,
      ref
    } = this.state
    return (
      <div id="testClaims">
        <BreadCrumb {...this.props} />
        <div className="mainHeader">
          <h2> Test Claim Generation</h2>
          {isCentrewellUser && <span className='imp'>Important: Test claim generation accounts for plan design
            and does consider prescription history.</span>}
        </div>

        {/* section for member search */}
        <div className='test-main-container'>
          <div className='test-claim-header'>Test Claim Build</div>
          <div className="search-container">
            <div>
              <div className='step-header'>Step 1</div>
              <div className='info-icon-container'>
                <div className='mini-header'>Find Member</div>
                {isCentrewellUser && <div onMouseOver={() => this.handleMouseOver('MEMBER')} onMouseOut={() => this.handleMouseOut()}>
                  <Icon name='info circle' color='black' />
                </div>}
              </div>
              {hovering && hoverType === 'MEMBER' && <div className='on-hover-info'>
                {hoverText}
              </div>}

              <div className='icon-container'>
                <FliptPaInput value={searchForm.memberId} stylized name="memberId" onChange={this._updateSearchForm}
                  placeholder="Enter" label="Cardholder ID" />
                <Icon name='cancel circle' onClick={() => { this._clearFields('MEMBER') }} className='margin-left' color='grey' />
              </div>
              {isCentrewellUser ? <div className='icon-container margin-top'>
                <FliptPaDatePicker labelText="Date Of Birth" placeholder="MM/DD/YYYY" name="memberDob"
                  onChange={this._updateSearchForm} value={searchForm.memberDob} stylized />
              </div> : null}
              <FliptButton name="Search" className="primary margin-top" onClick={() => this._searchData('MEMBER_SEARCH')} />
              {memberSearched && memberValidated ?
                <div className='validated-text' >
                  <Icon name='check circle' color='green' />
                  Validated
                </div> : null}
              {memberLookupFailed ? <div className='not-validated-text' >
                <Icon name='cancel circle' color='red' />
                Not Validated
              </div> : null}
            </div>
            <div className='search-separator' />
            <div>
              <div className='step-header'>Step 2</div>
              <div className='info-icon-container'>
                <div className='mini-header'>Find Drug</div>
                {isCentrewellUser && <div onMouseOver={() => this.handleMouseOver('DRUG')} onMouseOut={() => this.handleMouseOut()}>
                  <Icon name='info circle' color='black' />
                </div>}
              </div>
              {hovering && hoverType === 'DRUG' && <div className='on-hover-info'>
                {hoverText}
              </div>}
              <div className='icon-container'>
                <FliptPaInput value={searchForm.ndc} stylized name="ndc" onChange={this._updateSearchForm} placeholder="Enter" label="NDC" />
                <Icon name='cancel circle' onClick={() => { this._clearFields('DRUG') }} className='margin-left' color='grey' />
              </div>
              <FliptButton name="Search" className="primary margin-top" onClick={() => this._searchData('DRUG_SEARCH')} />
              {drugSearched && drugValidated ?
                <div className='validated-text' >
                  <Icon name='check circle' color='green' />
                  Validated
                </div> : null}
              {ndcLookupFailed ? <div className='not-validated-text' >
                <Icon name='cancel circle' color='red' />
                Not Validated
              </div> : null}
            </div>
            <div className='search-separator' />
            <div>
              <div className='step-header'>Step 3</div>
              <div className='info-icon-container'>
                <div className='mini-header'>Find Prescriber</div>
                {isCentrewellUser && <div onMouseOver={() => this.handleMouseOver('PRESCRIBER')} onMouseOut={() => this.handleMouseOut()}>
                  <Icon name='info circle' color='black' />
                </div>}
              </div>
              {hovering && hoverType === 'PRESCRIBER' && <div className='on-hover-info'>
                {hoverText}
              </div>}
              <div className='icon-container'>
                <FliptPaInput value={searchForm.prescriberNpi} stylized name="prescriberNpi"
                  onChange={this._updateSearchForm} placeholder="Enter" label="NPI" />
                <Icon name='cancel circle' onClick={() => { this._clearFields('PRESCRIBER') }} className='margin-left' color='grey' />
              </div>
              <FliptButton name=" Search" className="primary margin-top" onClick={() => this._searchData('PRESCRIBER_SEARCH')} />
              {prescriberSearched && prescriberValidated ?
                <div className='validated-text' >
                  <Icon name='check circle' color='green' />
                  Validated
                </div> : null}
              {prescriberLookupFailed ? <div className='not-validated-text' >
                <Icon name='cancel circle' color='red' />
                Not Validated
              </div> : null}
            </div>
            <div className='search-separator' />
            <div>
              <div className='step-header'>Step 4</div>
              <div className='info-icon-container'>
                <div className='mini-header'>Find Pharmacy</div>
                {isCentrewellUser && <div onMouseOver={() => this.handleMouseOver('PHARMACY')} onMouseOut={() => this.handleMouseOut()}>
                  <Icon name='info circle' color='black' />
                </div>}
              </div>
              {hovering && hoverType === 'PHARMACY' && <div className='on-hover-info on-hover-info-right'>
                {hoverText}
              </div>}
              <div className='icon-container'>
                <FliptPaInput value={searchForm.pharmacyNpi} stylized name="pharmacyNpi"
                  onChange={this._updateSearchForm} placeholder="Enter" label="NPI" />
                <Icon name='cancel circle' onClick={() => { this._clearFields('PHARMACY') }} className='margin-left' color='grey' />
              </div>
              <FliptButton name="Search" className="primary margin-top" onClick={() => this._searchData('PHARMACY_SEARCH')} />
              {pharmacySearched && pharmacyValidated ?
                <div className='validated-text' >
                  <Icon name='check circle' color='green' />
                  Validated
                </div> : null}
              {pharmacyLookupFailed ? <div className='not-validated-text' >
                <Icon name='cancel circle' color='red' />
                Not Validated
              </div> : null}
            </div>
          </div>
          <div className='horizontal-separator' />
          <div className="section-header">
            Enter Required Claim Details
          </div>
          {isCentrewellUser && <div className='imp'>Enter the Day Supply and Quantity prescribed below.
            All other fields have been defaulted but may be changed based on the specifics of
            the claim you are testing.</div>}
          <div className="content shadow margin-bottom">
            <div className="fields-container">
              <div className='fields-header'>
                <FliptPaInput value={prescriptionForm.daysOfSupply} stylized name="daysOfSupply" onChange={this._updatePrescriptionForm} placeholder="Enter" label="Days Supply" />
              </div>
              <div className='fields-header'>
                <FliptPaInput value={prescriptionForm.quantity} stylized name="quantity" onChange={this._updatePrescriptionForm} placeholder="Enter" label="Drug Quantity" />
              </div>
              <div className='fields-header'>
                <FliptPaDropdown value={prescriptionForm.claimType} options={claimTypeValues}
                  name="claimType" label="Claim Type" stylized
                  onChange={this._updatePrescriptionForm} />
              </div>
              <div className='fields-header'>
                <FliptPaDatePicker className="margin-right" labelText="Date Of Service" placeholder="MM/DD/YYYY" name="dateOfService" onChange={this._updatePrescriptionForm} value={prescriptionForm.dateOfService} stylized />
              </div>
            </div>
            <div className="fields-container">
              <div className='fields-header'>
                <FliptPaInput value={prescriptionForm.referenceNumber} stylized name="referenceNumber" onChange={this._updatePrescriptionForm} placeholder="Enter" label="Rx Number" />
              </div>
              <div className='fields-header'>
                <FliptPaInput value={prescriptionForm.ingredient_cost_submitted} stylized name="ingredient_cost_submitted" onChange={this._updatePrescriptionForm} placeholder="Enter" label="Ingredient Cost" />
              </div>
              <div className='fields-header'>
                <FliptPaInput value={prescriptionForm.dispensing_fee_submitted} stylized name="dispensing_fee_submitted" onChange={this._updatePrescriptionForm} placeholder="Enter" label="Dispensing Fee" />
              </div>
              <div className='fields-header'>
                <FliptPaInput value={prescriptionForm.usual_and_customary_charge} stylized name="usual_and_customary_charge" onChange={this._updatePrescriptionForm} placeholder="Enter" label="U&C" />
              </div>
            </div>
            <div className="fields-container">
              <div className='fields-header'>
                <FliptPaInput value={prescriptionForm.gross_amount_due} stylized name="gross_amount_due" onChange={this._updatePrescriptionForm} placeholder="Enter" label="Gross Amount Due" />
              </div>
              <div className='fields-header'>
                <FliptPaInput value={prescriptionForm.basis_of_cost_determination} stylized name="basis_of_cost_determination" onChange={this._updatePrescriptionForm} placeholder="Enter" label="Basis of Cost Determination" />
              </div>
              <div className='fields-header'>
                <FliptPaInput value={prescriptionForm.fill_number} stylized name="fill_number" onChange={this._updatePrescriptionForm} placeholder="Enter" label="Fill Number" />
              </div>
              <div className='fields-header'>
                <FliptPaInput value={prescriptionForm.compound_code} stylized name="compound_code" onChange={this._updatePrescriptionForm} placeholder="Enter" label="Compound Code" />
              </div>
            </div>
            <div className="fields-container">
              <div className='fields-header'>
                <FliptPaInput value={prescriptionForm.daw_code} stylized name="daw_code" onChange={this._updatePrescriptionForm} placeholder="Enter" label="DAW" />
              </div>
              <div className='fields-header'>
                <FliptPaDatePicker className="margin-right" labelText="Date Written" placeholder="MM/DD/YYYY" name="date_prescription_written" onChange={this._updatePrescriptionForm} value={prescriptionForm.date_prescription_written} stylized />
              </div>
            </div>
          </div>
          <div className='horizontal-separator' />
          <div className="section-header">
            Enter Optional Claim Details
          </div>
          <div className="content shadow margin-bottom">
            <div className="fields-container">
              <div className='fields-header'>
                <FliptPaInput value={optionalPrescriptionForm.incentiveAmt} stylized name="incentiveAmt" onChange={this._updateOptionalPrescriptionForm} placeholder="Enter" label="Incentive Amt" />
              </div>
              <div className='fields-header'>
                <FliptPaInput value={optionalPrescriptionForm.flatSaleTax} stylized name="flatSaleTax" onChange={this._updateOptionalPrescriptionForm} placeholder="Enter" label="Flat Sales Tax" />
              </div>
              <div className='fields-header'>
                <FliptPaInput value={optionalPrescriptionForm.saleTax} stylized name="saleTax" onChange={this._updateOptionalPrescriptionForm} placeholder="Enter" label="% Sales Tax" />
              </div>
              <div className='fields-header'>
                <FliptPaInput value={optionalPrescriptionForm.saleTaxPer} stylized name="saleTaxPer" onChange={this._updateOptionalPrescriptionForm} placeholder="Enter" label="% Sales Tax Rate" />
              </div>
            </div>
            <div className="fields-container">
              <div className='fields-header'>
                <FliptPaInput value={optionalPrescriptionForm.saleTaxBasis} stylized name="saleTaxBasis" onChange={this._updateOptionalPrescriptionForm} placeholder="Enter" label="% Sales Tax Rate Basis" />
              </div>
              <div className='fields-header'>
                <FliptPaInput value={prescriptionForm.submission_clarification_code} stylized validation='numeric' maxlength="2"
                  name="submission_clarification_code" onChange={this._updatePrescriptionForm} placeholder="Enter" label="Submission clarification Code" />
              </div>
            </div>
          </div>
          <div ref={ref} className="buttons-container">
            <div className="fields">
              <FliptButton name="SUBMIT TEST CLAIM" className="primary" onClick={this._generateTestClaim} />
            </div>
            <div className="fields">
              <FliptButton name="CLEAR" className="cancelButton" onClick={this._cancelClaim} />
            </div>
          </div>
        </div>

        {responseData ? <div className='test-main-container'>
          <div className='test-claim-header'>Submission Response</div>
          <div className="response-content">
            {responseData?.claim_status === 'R' ? <div className="fields-container">
              <div className='fields-header'>
                <FliptPaInput disabled value={responseData?.claim_status} stylized label="Claim Status" />
              </div>
              <div className='fields-header'>
                <FliptPaInput className="less-margin-right" value={responseData?.reject_code} stylized disabled label="Flipt Reject Code" />
              </div>
              <div>
                <FliptPaInput inputClass='input-class-med' value={responseData?.message}
                  stylized disabled label="Flipt Reject Message 1" />
              </div>
            </div> : <div className="fields-container">  <div className='fields-header'>
              <FliptPaInput disabled value={responseData?.claim_status} stylized label="Claim Status" />
            </div></div>}
          </div>
          <div className='test-claim-header'>Pricing segment</div>
          <div className="response-content">
            <div className="fields-container">
              <div className='fields-header'>
                <FliptPaInput disabled value={responseData?.client_ing_cost} stylized label="Ingredient Cost" />
              </div>
              <div className='fields-header'>
                <FliptPaInput disabled value={responseData?.client_disp_fee_paid} stylized label="Dispensing Fee" />
              </div>
              <div className='fields-header'>
                <FliptPaInput disabled value={responseData?.submitted_flat_sales_tax_amount} stylized label="Sales Tax Flat" />
              </div>
              <div className='fields-header'>
                <FliptPaInput disabled value={responseData?.submitted_percentage_sales_tax_amount} stylized label="Sales Tax %" />
              </div>
            </div>
            <div className="fields-container">
              <div className='fields-header'>
                <FliptPaInput disabled value={responseData?.patient_pay_amount} stylized label="Patient Pay" />
              </div>
              <div className='fields-header'>
                <FliptPaInput disabled value={responseData?.gross_amount_due} stylized label="Amount Due" />
              </div>
              <div className='fields-header'>
                <FliptPaInput className="less-margin-right" value={responseData?.deductible_accumulator_amount} stylized disabled label="Amount Attributed to Deductible" />
              </div>
            </div>
          </div>
          <div className='test-claim-header'>Patient Pay</div>
          <div className="response-content">
            <div className="fields-container">
              <div className='fields-header'>
                <FliptPaInput disabled value={responseData?.copay} stylized label="Copay" />
              </div>
              <div className='fields-header'>
                <FliptPaInput disabled value={responseData?.copay_type} stylized label="Copay Type" />
              </div>
              <div className='fields-header'>
                <FliptPaInput disabled value={responseData?.copay_amt} stylized label="Copay Amount" />
              </div>
              <FliptPaInput disabled value={responseData?.member_choice_penalty_amount} stylized label="Amount Attributed to Product Selection" />
            </div>
          </div>
        </div> : null}
      </div >
    )
  }
}

const mapStateToProps = (state) => ({
  state: {
    memberLookup: state.memberLookup,
    memberLookupFailed: state.memberLookup.memberLookupFailed,
    manualClaimEntry: state.manualClaimEntry,
    ndcLookupFailed: state.manualClaimEntry.ndcLookupFailed,
    prescriberLookupData: state.prescriberLookup.prescriberLookupData,
    prescriberLookupFailed: state.prescriberLookup.prescriberLookupFailed,
    pharmacyLookupData: state.pharmacyLookup.pharmacyLookupData,
    pharmacyLookupFailed: state.pharmacyLookup.pharmacyLookupFailed,
    testClaimData: state.testClaim.testClaimData,
    drugLookup: state.drugLookup,
    user: state.user,
    autoSuggestData: state.autoSuggest.autoSuggestData,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...MemberLookupActions,
    ...AppActions,
    ...ManualClaimEntryActions,
    ...PrescriberLookupActions,
    ...PharmacyLookupActions,
    ...AutoSuggestActions,
    ...DrugLookupActions,
    ...TestClaimActions
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TestClaims)
