import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import './styles.scss'
import BreadCrumb from '../../../../../components/breadCrumb'
import FliptButton from '../../../../../components/form/fliptButton'
import FliptInput from '../../../../../components/form/fliptPaInput'
import FliptGrid from '../../../../../components/fliptGrid'
import { Creators as AppActions } from '../../../../../redux/reducers/app'
import { Creators as NetworkCreationActions } from '../../../../../redux/reducers/api/networkCreationManagement'
import { createQueryString, parseQueryString } from '../../../../../utils/utilities'
import ServerSideDatasource from '../../../../../components/fliptGrid/serverSideDataSource'
import { FeeGridRowTextRenderer } from '../../../../../components/fliptGrid/cellRenderers'

function WarningModal(props) {
    const {
        toggleModal, message, onContinueClick, onCancelClick
    } = props

    const handleClick = () => {
        onContinueClick()
        toggleModal()
    }
    const handleCancel = () => {
        onCancelClick()
        toggleModal()
    }
    return (
        <div className="warningModal">
            <div className="header-text">
                <h3>{message}</h3>
            </div>
            <div className="buttons-container">
                <FliptButton className="primary" name="Continue" onClick={handleClick} />
                <FliptButton className="primary" name="Cancel" onClick={handleCancel} />
            </div>
        </div>
    )

}
class PharmacyNetworkLookup extends Component {
    constructor(props) {
        super(props)

        this.state = {
            form: {
                contract: '',
                pharmacyname: '',
                pharmacynpi: '',
                doc_id: '',
                doc_version: '',
                tier_id: '',
                tier_name: '',
                tier_version: '',
                // limit: 100,
                offset: 0,
                pharmaciesPerPage: 20
            },
            reRender: 0,
            agGridRef: React.createRef(),
        }
    }
    componentDidMount() {
        const { history } = this.props
        const { location } = history

        if (!location) return
        const { form, agGridRef } = this.state

        this._qsToFormData()
        history.location.search = createQueryString(form)

        agGridRef.current.api.refreshServerSideStore({ route: null, purge: true }) // to refresh the grid server side data
    }

    _pharmacySearch() {
        const { state, props } = this
        const { form } = state
        const { history } = props
        // props.actions.clearPharmacyLookupReducer();
        history.location.search = createQueryString(form)
        props.actions.getPharmacyNetworkLookupData({ form, getTotalRows: true })
    }

    _clearFormFilter = () => {
        const { state, props } = this
        const { agGridRef } = state
        // props.actions.clearPharmacyLookupReducer();
        const form = {
            contract: '',
            pharmacyname: '',
            pharmacynpi: '',
            doc_id: '',
            doc_version: '',
            limit: 100,
            offset: 0,
        }
        this.setState({ form })
        const headers = [
            'network_tier', 'claim_processor', 'pharmacynpi', 'pharmacyname', 'discount', 'start_days_supply',
            'end_days_supply', 'price_type', 'maclistid', , 'program_drug_list',
            'fees', 'program_drug_list', 'effective_start_date', 'effective_end_date',
        ]
        const datasource = new ServerSideDatasource(form, 'pharmacy-network-lookup', props.actions.getPharmacyNetworkLookupData, headers)
        agGridRef.current.api.setServerSideDatasource(datasource);
    }

    _updateFields = (el, dropdown) => {
        const { form } = this.state
        const { name, value } = dropdown || el.currentTarget

        form[name] = value.toUpperCase()
        this.setState({ form })
    }

    _qsToFormData = () => {
        const { state, props } = this
        const qs = parseQueryString(props.history.location.search)
        const { form } = state
        const keys = Object.keys(qs)
        if (keys && !keys?.length) return

        keys.forEach((key) => {
            if (key) form[key] = qs[key]
        })
        this.setState({ form })
    }

    handleIdClicked = (pharmacyData) => {
        const { data } = pharmacyData
        this.props.history.push('/contract-discount-lookup', { data })
    }

    cellRendererParams = {
        claim_processor: {
            overrideHeader: 'Contract',
        },
        pharmacynpi: {
            overrideHeader: 'NPI',
        },
        maclistid: {
            overrideHeader: 'MAC List Id',
        },
        pharmacyname: {
            overrideHeader: 'Pharmacy Name',
        },
        program_drug_list: {
            overrideHeader: 'Drug List',
        },
        fees: {
            cellRenderer: FeeGridRowTextRenderer,
            autoHeight: true
        }
    }

    render() {
        const { state, props } = this
        const { actions } = props
        const { form, agGridRef } = state
        const { pharmacynpi, contract, pharmacyname } = form
        const {
            state: {
                pharmaciesPerPage,
            },
        } = props
        const headers = [
            'network_name', 'tier_name', 'network_tier_type', 'claim_processor', 'pharmacynpi', 'pharmacyname', 'discount', 'start_days_supply',
            'end_days_supply', 'price_type', 'maclistid', , 'program_drug_list',
            'fees', 'effective_start_date', 'effective_end_date',
        ]
        let docName = this.props.state.pharmacyNetworkLookup?.data[0].network_details.doc_name || ''

        const serverSideGridConfig = {
            cacheBlockSize: form.pharmaciesPerPage,
            pagination: true,
            paginationPageSize: form.pharmaciesPerPage,
            rowModel: 'serverSide',
            serverSideStoreType: 'partial',
        }
        const serverSideGridParams = {
            apiId: 'pharmacy-network-lookup',
            form,
            headers,
            sagaToCall: actions.getPharmacyNetworkLookupData,
        }


        return (
            <div id="pharmacyLookup">
                <BreadCrumb {...this.props} />
                <div className="header">{docName}</div>
                <div style={{ display: "none" }}>
                    <div className="horizontal-fields">
                        <FliptInput stylized label='Contract' placeholder="Contract Name" name="contract" value={contract} onChange={this._updateFields} />
                        <FliptInput stylized label='Pharmacy NPI' placeholder="NPI" name="pharmacynpi" value={pharmacynpi} onChange={this._updateFields} />
                        <FliptInput stylized label="Pharmacy Name" placeholder="Pharmacy Name" name="pharmacyname" value={pharmacyname} onChange={this._updateFields} />
                    </div>

                    <div className="button-container">
                        <FliptButton name="Search" className="primary searchButton" onClick={() => this._pharmacySearch(false)} />
                        <FliptButton name="Clear" className="secondary clearButton" onClick={() => this._clearFormFilter()} />
                    </div>
                </div>

                <div className="agGrid content">
                    <FliptGrid
                        agGridRef={agGridRef}
                        cellRendererParams={this.cellRendererParams}
                        filterOptions={['contains', 'equals']}
                        headers={headers}
                        serverSideGridConfig={serverSideGridConfig}
                        serverSideGridParams={serverSideGridParams}
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    state: {
        app: state.app,
        //loading: state.networkTierCreation.loading,
        //pharmacyNetworkLookup: state.networkTierCreation.pharmacyNetworkLookup,
        pharmaciesPerPage: state.networkTierCreation.pharmaciesPerPage,
        //totalRows: state.networkTierCreation.pharmacyTotalRows,
    },
})

const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...AppActions,
        ...NetworkCreationActions,
    }

    return {
        actions: bindActionCreators(allActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PharmacyNetworkLookup)
