import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import _ from 'lodash'
import { Creators as PlanManagementActions } from '../../../../redux/reducers/api/planManagement'
import './styles.scss'
import FliptDropdown from '../../../../components/form/fliptDropdown'
import FliptSeparator from '../../../../components/form/fliptSeparator'
import FliptRadio from '../../../../components/form/fliptRadio'

class DawSettings extends Component {
  constructor(props) {
    super(props)

    const { state } = this.props
    const { dawLevels } = state
    const dawLevelsState = this.initializeDAWLevels(dawLevels)

    this.state = {
      psc_settings: dawLevelsState,
      brand_covered_upto_generic_copay_flag: false,
    }
  }

  componentDidMount() {
    const { state } = this.props
    const { dawSettingsData } = state

    if (dawSettingsData) {
      this.setState({
        ...dawSettingsData,
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { copyFormData } = this.props
    if (!_.isEqual(prevState, this.state)) {
      copyFormData(this.state)
    }
  }

  initializeDAWLevels = (dawLevels) => {
    const pscSettings = {
      generic_pricing: '',
      member_choice_penalty: '',
      penalty_ded_accum: '',
      penalty_oop_accum: '',
      reject: '',
    }
    const dawLevelsState = [...Array(dawLevels)].map(() => (_.cloneDeep(pscSettings)))
    return dawLevelsState
  }

  onDropdownChange = (el, dropdown) => {
    const { psc_settings: pscSettings } = this.state
    const { name, value } = dropdown || el.currentTarget
    const fieldNameArr = name?.split('_')
    const fieldNameStrippedLevel = fieldNameArr.slice(0, -1).join('_')
    const dawLevel = Number(fieldNameArr[fieldNameArr.length - 1])
    const pscSettingsCopy = _.cloneDeep(pscSettings)

    pscSettingsCopy[dawLevel] = {
      ...pscSettingsCopy[dawLevel],
      [fieldNameStrippedLevel]: value,
    }

    this.setState({
      psc_settings: pscSettingsCopy,
    })
  }

  updateBrandCovered = () => {
    const { brand_covered_upto_generic_copay_flag } = this.state
    const { state } = this.props
    const { dawLevels } = state
    const pscSettings = {
      generic_pricing: 'N',
      member_choice_penalty: 'N',
      penalty_ded_accum: 'N',
      penalty_oop_accum: 'N',
      reject: 'N',
    }
    if (!brand_covered_upto_generic_copay_flag) {
      pscSettings.member_choice_penalty = 'Y'
    }
    const dawLevelsState = [...Array(dawLevels)].map(() => (_.cloneDeep(pscSettings)))

    this.setState({
      brand_covered_upto_generic_copay_flag: !brand_covered_upto_generic_copay_flag,
      psc_settings: dawLevelsState,
    })
  }

  generateDAWLevel = (level) => {
    const { psc_settings: pscSettings } = this.state
    const dropdownOptions = [{ value: 'Y', text: 'YES' }, { value: 'N', text: 'NO' }]
    return (
      <div className="dawLevel">
        <div className="dawLevelTitle">
          PSC Setting Level&nbsp;
          {level}
        </div>
        <div className="dropdowns">
          <FliptDropdown
            clearable
            placeholder="Select"
            label="Generic Pricing"
            name={`generic_pricing_${level}`}
            value={pscSettings[level]?.generic_pricing}
            options={dropdownOptions}
            onChange={this.onDropdownChange}
            stylized
          />
          <FliptDropdown
            clearable
            placeholder="Select"
            label="Member Choice Penalty"
            name={`member_choice_penalty_${level}`}
            value={pscSettings[level]?.member_choice_penalty}
            options={dropdownOptions}
            onChange={this.onDropdownChange}
            stylized
          />
          <FliptDropdown
            clearable
            placeholder="Select"
            label="Penalty Ded Accum"
            name={`penalty_ded_accum_${level}`}
            value={pscSettings[level]?.penalty_ded_accum}
            options={dropdownOptions}
            onChange={this.onDropdownChange}
            stylized
          />
          <FliptDropdown
            clearable
            placeholder="Select"
            label="Penalty OOP Accum"
            name={`penalty_oop_accum_${level}`}
            value={pscSettings[level]?.penalty_oop_accum}
            options={dropdownOptions}
            onChange={this.onDropdownChange}
            stylized
          />
          <FliptDropdown
            clearable
            placeholder="Select"
            label="Reject"
            name={`reject_${level}`}
            value={pscSettings[level]?.reject}
            options={dropdownOptions}
            onChange={this.onDropdownChange}
            stylized
          />
          <FliptSeparator />
        </div>
      </div>
    )
  }

  render() {
    const { state } = this.props
    const { dawLevels } = state
    const { brand_covered_upto_generic_copay_flag } = this.state

    return (
      <div id="daw_settings">
        <div className="content">
          <div className="dawLevel">
            <FliptRadio
              stylized
              label="Brand Covered up to OOP Max"
              name="brand_covered_upto_generic_copay_flag"
              checked={brand_covered_upto_generic_copay_flag}
              toggle
              onChange={this.updateBrandCovered}
            />
          </div>
          {[...Array(dawLevels)].map((x, i) => this.generateDAWLevel(i))}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  // const { fieldDetails } = props

  // const dawLevelField = fieldDetails.find(({ field }) => field === 'daw_level')
  // const dawLevels = Number(dawLevelField.value) || 9
  const dawLevels = 10

  return ({
    state: {
      app: state.app,
      dawLevels,
      dawSettingsData: state.planManagement.dawSettingsData,
    },
  })
}
const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...PlanManagementActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DawSettings)
