/* eslint-disable */
export default {
  program_qualifier: {
    type: 'dropdown',
    disabled: false,
  },
  program_operator: {
    type: 'dropdown',
    disabled: false,
  },
  program_value: {
    type: 'input',
    disabled: false,
  },
}
