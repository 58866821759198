import Immutable from 'seamless-immutable'
import { createActions, createReducer } from 'reduxsauce'

export const INITIAL_STATE = Immutable({
  loading: false,
  activePlan: [],
  medicareDPlanData: [],
  medicareSetupData: [],
  medicareSetupDetailData: [],
  medicareStandardData: []
})

export const clearMedicareSetupReducer = () => INITIAL_STATE

export const clearReducer = () => INITIAL_STATE

export const setActivePlanDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  activePlan: payload,
})

export const setMedicareDPlanDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  medicareDPlanData: payload,
})

export const setMedicareSetupDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  medicareSetupData: payload,
})

export const setMedicareSetupDetailDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  medicareSetupDetailData: payload,
})

export const setLoadingReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  loading: payload.data,
})

export const setMedicareStandardDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  medicareStandardData: payload,
})

export const { Types, Creators } = createActions({
  clearMedicareSetupReducer: null,
  clearReducer: null,
  createMedicareDPlan: ['payload'],
  getMedicareDPlanData: ['payload'],
  getMedicareSetupData: ['payload'],
  getMedicareSetupDetailData: ['payload'],
  saveMedicareSetupDetailData: ['payload'],
  setLoading: ['payload'],
  setActivePlanData: ['payload'],
  setMedicareDPlanData: ['payload'],
  setMedicareSetupData: ['payload'],
  setMedicareSetupDetailData: ['payload'],
  getMedicareStandardData: ['payload'],
  setMedicareStandardData: ['payload'],
  getMedicareStandardDetail: ['payload'],
  getMedicareSetupYears: ['payload']
})

const HANDLERS = {
  [Types.CLEAR_MEDICARE_SETUP_REDUCER]: clearMedicareSetupReducer,
  [Types.CLEAR_REDUCER]: clearReducer,
  [Types.SET_LOADING]: setLoadingReducer,
  [Types.SET_ACTIVE_PLAN_DATA]: setActivePlanDataReducer,
  [Types.SET_MEDICARE_D_PLAN_DATA]: setMedicareDPlanDataReducer,
  [Types.SET_MEDICARE_SETUP_DATA]: setMedicareSetupDataReducer,
  [Types.SET_MEDICARE_SETUP_DETAIL_DATA]: setMedicareSetupDetailDataReducer,
  [Types.SET_MEDICARE_STANDARD_DATA]: setMedicareStandardDataReducer
}

export default createReducer(INITIAL_STATE, HANDLERS)
