import React from 'react'
import { Popup } from 'semantic-ui-react'

export default (props) => {
  const { description, label, className, labelClass } = props

  if (!description) return label

  return (
    <Popup
      content={description}
      size="tiny"
      trigger={<label className={labelClass} htmlFor={label}>{label}</label>}
      className={className}
    />
  )
}
