import React from 'react'
import { Radio } from 'semantic-ui-react'

import FliptLabel from '../fliptLabel'
import './styles.scss'
export default (props) => {
  const { description, label, toggle } = props
  return (
    <div className="radio-container">
        <Radio className="radio-input" {...props} label="" toggle={toggle} />
        <div className="flipt-label">
            <FliptLabel
                description={description}
                label={label}
            />
        </div>
    </div>
  )
}
