import React from 'react';
import { Icon, Label, Popup } from 'semantic-ui-react';
import FliptDropdown from '../v2/fliptDropdown';
import './styles.scss';

function MultiSelectToolTipDropDown(props) {
    const {
        label,
        tooltip,
        onChange,
        options = [],
        overrideTextKey,
        dropdownPlaceholder,
        removeOptionFromList,
        selectedOptions = [],
    } = props

    function Info(props) {
        const { content, position } = props;
        return (
            <Popup
                trigger={<Icon className='tooltip-icon' name='question circle outline' />}
                content={content}
                position={position}
            />
        );
    };

    return (
        <section id='tooltip-input' className='tooltip-input-wrapper'>
            <label className='tooltip-info-container'>
                {label}
                <span className='input-tooltip'>
                    <Info
                        className='tooltip'
                        content={tooltip}
                    />
                </span>
            </label>
            <section className='multiselect-tt-dropdown-container'>
                <FliptDropdown
                    className='tooltip-list-dropdown'
                    placeholder={dropdownPlaceholder}
                    options={options}
                    fluid
                    search
                    onChange={onChange}
                    value={props.value || ''}
                />
                <section className='multiselect-tt-badge-container'>
                    {selectedOptions.map((option, index) => (
                        <Label key={`${(option.id || option.doc_id)}-dd-${index}`}>
                            {option[overrideTextKey] || option.text}
                            <Icon
                                name='delete'
                                onClick={() => removeOptionFromList(option.id || option.doc_id)}
                            />
                        </Label>
                    ))}
                </section>
            </section>
        </section>

    )
}

export default MultiSelectToolTipDropDown;
