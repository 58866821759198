import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import "./styles.scss"
import BreadCrumb from "../../../components/breadCrumb"
import FliptButton from "../../../components/form/fliptButton"
import FliptInput from "../../../components/form/fliptInput"
import FliptGrid from "../../../components/fliptGrid"
import FliptDatePicker from "../../../components/form/fliptDatePicker"
import FliptDropdown from "../../../components/form/fliptDropdown"
import CAGFilters from "../../../components/CAGFilters"
import { convertStrToDateObj, addRemoveDaysToDate } from '../../../utils/utilities'
import { Creators as AppActions } from "../../../redux/reducers/app"
import { Creators as PlanManagementActions } from "../../../redux/reducers/api/planManagement"
import { Creators as SureScriptConfig } from "../../../redux/reducers/api/sureScriptConfig"
import { createQueryString, parseQueryString } from "../../../utils/utilities"
import ServerSideDatasource from "../../../components/fliptGrid/serverSideDataSource"
import moment from "moment"
import _ from "lodash"
import {
  ButtonRenderer,
  EllipsisActionMenuRenderer,
} from "../../../components/fliptGrid/cellRenderers"
import increaseRowHeight from "../../../components/fliptGrid/cellClickEvents"
import { ServerSideTransactionResultStatus } from "ag-grid-community"
class SureScriptFormularyConfig extends Component {
  constructor(props) {
    super(props)
    const { state } = this.props
    const { plan_name = "" } = location.state || {}
    const {
      user,
      user: { carriers, groups },
    } = state
    this.state = {
      form: {
        carrier: "",
        account: "",
        group: "",
        plan_name: "",
        effective_end_date: "",
        effective_start_date: "",
        formulary_name: "",
        surescript_formulary_id: "",
        surescript_formulary_name: "",
        prior_authorization_name: "",
        surescript_prior_authorization_id: "",
        client: [],
        surescript_doc_id: "",
      },
      isEdit: false,
      showTabFlag: false,
      popupConfiguration: {},
      surescriptFormularyConfiguration: [],
      domainOptions: user.accounts_access.map((d) => ({
        key: d.id,
        text: d.name,
        value: d.id,
      })),
      carrierOptions: carriers.map((d) => ({
        key: d.id,
        text: d.name,
        value: d.id,
      })),
      groupOptions: groups.map((d) => ({
        key: d.id,
        text: d.name,
        value: d.id,
      })),
      planNameOptions: plan_name
        ? [plan_name].map((d) => ({
          key: d,
          text: d,
          value: d,
        }))
        : [
          {
            key: "",
            text: "",
            value: "",
          },
        ],
    }
  }

  componentDidUpdate(prevProps) {
    const { props } = this
    const {
      state: {
        benefitPlanData,
        surescriptFormularyConfiguration,
        currentFormularyConfigurationData,
      },
    } = props
    const prevState = prevProps.state
    const currState = props.state
    if (prevState === currState) return
    if (benefitPlanData?.length) {
      this._updatePlanNameOptions(benefitPlanData)
    }
    if (surescriptFormularyConfiguration?.length) {
      this.setState({
        surescriptFormularyConfiguration,
      })
    }
    if (currentFormularyConfigurationData) {
      this.setState({
        form: currentFormularyConfigurationData,
      })
    }
  }

  componentDidMount() {
    const { history, actions } = this.props
    const { location } = history
    // Get the dropdown values for carrier, account, group, and plan_name
    actions.clearCurrentSurescriptConfigData()
    actions.getSurescriptConfigData()
    this.setState({
      planNameOptions: [],
    })
    if (!location.search) return
    const { form, agGridRef } = this.state
    this._qsToFormData()
    history.location.search = createQueryString(form)
    // agGridRef.current.api.refreshServerSideStore({ route: null, purge: true }) // to refresh the grid server side data
  }

  _pharmacySearch = () => {
    const { state, props } = this
    const { form, agGridRef } = state
    const { history } = props
    props.actions.clearPharmacyLookupReducer()
    history.location.search = createQueryString(form)
    agGridRef.current.api.refreshServerSideStore({ route: null, purge: true }) // to refresh the grid server side data
  }

  // Change plan name here
  _updatePlanNameOptions = (planNameOptions) => {
    this.setState({
      planNameOptions:
        planNameOptions.length > 0
          ? planNameOptions.map((it) => ({
            key: it.plan_name,
            text: it.plan_name,
            value: it.plan_name,
          }))
          : [{ key: "None", text: "None", value: "None" }],
    })
  }

  _clearFormFilter = () => {
    const { state, props } = this
    const { agGridRef } = state
    props.actions.clearPharmacyLookupReducer()
    const form = {
      pharmacynpi: "",
      pharmacycity: "",
      pharmacystate: "",
      pharmacyzip1: "",
      pharmacytype: "ALL",
      networkStatus: "ALL",
      pharmacyname: "",
    }
    this.setState({ form })
    const headers = [
      "pharmacynpi",
      "formulary_name",
      "surescript_formulary_id",
      "surescript_formulary_name",
      "prior_authorization_name",
      "surescript_prior_authorization_id",
      "client",
      "effective_start_date",
      "effective_end_date",
    ]
    const datasource = new ServerSideDatasource(
      form,
      "pharmacy-lookup",
      props.actions.getPharmacyLookupData,
      headers
    )
    agGridRef.current.api.setServerSideDatasource(datasource)
  }

  _updateFields = (el, dropdown) => {
    const { form } = this.state
    const { name, value } = dropdown || el.currentTarget
    form[name] = value
    if (name == "account" || name == "group" || name == "carrier") {
      this._handlePlanNameFetch()
    }
    this.setState({ form })
  }

  _updateScriptConfig = () => {
    const { state, props } = this

    props.actions.updateSurescriptConfigData({
      ...state.form,
    })
  }
  _handlePlanNameFetch = () => {
    const { actions } = this.props
    const {
      form,
      form: { carrier, account, group },
    } = this.state
    if (account || group) {
      form["plan_name"] = ""
      this.setState({
        planNameOptions: [{ key: "None", text: "None", value: "None" }],
        form,
      })
      actions.getBenefitPlanData({ carrier, account, group })
    }
  }

  _qsToFormData = () => {
    const { state, props } = this
    const qs = parseQueryString(props.history.location.search)
    const { form } = state
    Object.keys(qs).forEach((key) => {
      form[key] = qs[key]
    })
    this.setState({ form })
  }

  _searchBenefitPlans = () => {
    const { form } = this.state
    const { actions, history } = this.props
    // if ((form.account.length > 0 || domainOptions.length === 1) && (form.group.length > 0 || groupOptions.length === 1) && form.plan_year.length > 0 && form.plan_name.length > 0) {
    history.location.state = form
    // actions.getBenefitPlanData(form)

    actions.insertSurescriptConfigData(form)
    this.setState({
      form: {
        surescript_formulary_name: "",
        surescript_prior_authorization_id: ""
      }
    })
  }

  onUMFieldsSelect = (e) => {
    const { props } = this
    const { actions } = props
    actions.getSurescriptConfigData({
      surescript_doc_id: e.surescript_doc_id,
    })
    this.setState({ currHistory: e.price_type, showTabFlag: true })
  }
  render() {
    const { state, props } = this
    const { form, planNameOptions, surescriptFormularyConfiguration } = state
    const { currentFormularyConfigurationData } = props.state
    const {
      effective_start_date,
      effective_end_date,
      surescript_formulary_name,
      prior_authorization_name,
      carrier,
      account,
      group,
      plan_name,
    } = form
    // const { surescripts_formulary_id, surescript_prior_authorization_id } =
    //   props.state.surescriptUpdate
    // const {
    //   state: { pharmaciesPerPage },
    // } = props
    const headers = [
      "action",
      "surescript_formulary_id",
      "surescript_formulary_name",
      "surescript_prior_authorization_id",
      "prior_authorization_name",
      "effective_start_date",
      "effective_end_date",
      "plan_name",
      "status",
      "UM Fields",
    ]
    const checkActionType = async (rowData) => {
      const { props } = this
      const { actions } = props
      if (rowData && rowData['qsArgs']) {
        const form = {
          effective_end_date: convertStrToDateObj(rowData["effective_end_date"]),
          effective_start_date: convertStrToDateObj(rowData["effective_start_date"]),
          surescript_formulary_name: rowData["surescript_formulary_name"],
          prior_authorization_name: rowData["prior_authorization_name"],
          surescript_doc_id: rowData["surescript_doc_id"],
          surescript_formulary_id: rowData["surescript_formulary_id"],
          surescript_prior_authorization_id: rowData["surescript_prior_authorization_id"],

        }
        const action = rowData['qsArgs']['selected_action'];
        if (action === 'edit') {
          await actions.setCurrentFormularyConfigData(form)
          this.setState({ isEdit: True, form })
        }
      }
    }

    const cellRendererParams = {
      action: {
        cellRenderer: EllipsisActionMenuRenderer,
        searchArgs: {
          onClick: (rowdata) => checkActionType(rowdata),
          path: '/ss-formulary-config',
          qsArgs: { plan_id: 'plan_id', action: 'COPY_OBJECT', plan_name: 'plan_name', lob: 'lob', module: 'EDIT' },
        },
        onCellClicked: increaseRowHeight,
      },
      surescript_formulary_id: {
        overrideHeader: "Formulary ID",
      },
      surescript_formulary_name: {
        overrideHeader: "Surescript Formulary Name",
      },
      surescript_prior_authorization_id: {
        overrideHeader: "Prior Authorization ID",
      },
      prior_authorization_name: {
        overrideHeader: "Prior Authorization Name",
      },
      effective_start_date: {
        overrideHeader: "Effective Start Date",
      },
      effective_end_date: {
        overrideHeader: "Effective End Date",
      },
      plan_name: {
        overrideHeader: "Plan Name",
      },
      status: {
        overrideHeader: "Status",
      },
      "UM Fields": {
        cellRenderer: ButtonRenderer,
        searchArgs: {
          onClick: this.onUMFieldsSelect,
          text: "More",
          className: "linkButton",
        },
        width: 200,
        overrideHeader: "UM Fields",
      },
    }

    let popupCellRendererParams = {
      ndc: {
        overrideHeader: 'Product ID',
      },
      drug_name: {
        overrideHeader: 'Product Name',
      },
      price_type: {
        overrideHeader: 'Price Type',
      },
    }
    return (
      <div id="sureScriptFormularyConfig">
        <BreadCrumb {...this.props} />
        <div className="header">
          <h1>SureScript Formulary Configuration</h1>
        </div>

        <div className="cag-section">
          <CAGFilters
            selectedCarrier={carrier}
            selectedAccount={account}
            selectedGroup={group}
            onFilterChange={this._updateFields}
          />
          <FliptDropdown
            simple
            item
            name="plan_name"
            placeholder="Plan Name"
            onChange={this._updateFields}
            value={plan_name}
            options={planNameOptions}
            label="Plan Name"
            description="Plan Name"
            stylized="true"
          />
          <FliptButton
            name="Create Formulary Configuration"
            className="primary searchButton"
            onClick={() => this._searchBenefitPlans()}
          />
        </div>
        <div className="content">
          {currentFormularyConfigurationData ? (
            <section className="program-inputs-container grid-container spacing border-line shadow flex-Cflow">
              <div className="displayflex ">
                <div className="sectionComp">
                  <div className="label-T">Formulary ID</div>
                  <FliptInput
                    placeholder="Formulary ID"
                    name="surescriptformularyid"
                    value={
                      currentFormularyConfigurationData.surescript_formulary_id
                    }
                    disabled
                  />
                </div>
                <div className="sectionComp">
                  <div className="label-T">Prior Authorization ID</div>
                  <FliptInput
                    placeholder="Authorization ID"
                    name="surescriptpriorauthorizationid"
                    value={
                      currentFormularyConfigurationData.surescript_prior_authorization_id
                    }
                    disabled
                  />
                </div>
              </div>

              <div className="displayflex">
                <div className="sectionComp">
                  <div className="label-T">Effective Start Date</div>
                  <FliptDatePicker
                    stylized={false}
                    className="create-formulary-start-date"
                    value={effective_start_date}
                    name="effective_start_date"
                    placeholder="Start Date"
                    onChange={this._updateFields}
                    label=""
                    description="Effective Start Date"
                  />
                </div>
                <div className="sectionComp">
                  <div className="label-T">Effective End Date</div>
                  <FliptDatePicker
                    value={effective_end_date}
                    name="effective_end_date"
                    placeholder="End Date"
                    onChange={this._updateFields}
                    label=""
                    description="Effective End Date"
                  />
                </div>
                <div className="sectionComp">
                  <div className="label-T">Formulary Name</div>
                  <FliptInput
                    placeholder="Formulary Name"
                    name="surescript_formulary_name"
                    value={surescript_formulary_name}
                    onChange={this._updateFields}
                  />
                </div>
                <div className="sectionComp">
                  <div className="label-T">Prior Authorization Name</div>
                  <FliptInput
                    placeholder="Prior Authorization Name"
                    name="prior_authorization_name"
                    value={prior_authorization_name}
                    onChange={this._updateFields}
                  />
                </div>
              </div>
              <div
                className="displayflex "
                style={{ "justify-content": "center", padding: "16px" }}
              >
                <div className="sectionComp-button">
                  <FliptButton
                    name="Finalize Formulary Configuration"
                    className="primary searchButton"
                    onClick={() => this._updateScriptConfig()}
                  />
                </div>
              </div>
            </section>
          ) : null}

          <FliptGrid
            headers={headers}
            cellRendererParams={cellRendererParams}
            data={surescriptFormularyConfiguration}
          />
        </div>
        {state.showTabFlag ? (
          <div id="surescriptPopup">
            <div className="content popup">
              <div className="popupTable">
                <FliptGrid
                  headers={[
                    "pa_name",
                    "rank"
                  ]}
                  data={props.state?.popupData?.clinical_pa?.sort((a, b) => { return a.rank - b.rank })}
                />
              </div>
              <div className="popupTable">
                <FliptGrid
                  headers={[
                    "ql_name",
                    "rank"
                  ]}
                  data={props.state?.popupData?.clinical_ql?.sort((a, b) => { return a.rank - b.rank })}
                  cellRendererParams={popupCellRendererParams}
                />
              </div>
              <div className="popupTable">
                <FliptGrid
                  headers={[
                    "step_therapy_name",
                    "rank"
                  ]}
                  data={props.state?.popupData?.clinical_step_therapy?.sort((a, b) => { return a.rank - b.rank })}
                  cellRendererParams={popupCellRendererParams}
                />
              </div>
              <div className="popupTable">
                <FliptGrid
                  headers={[
                    "program_name",
                    "rank"
                  ]}
                  data={props.state?.popupData?.programs?.sort((a, b) => { return a.rank - b.rank })}
                  cellRendererParams={popupCellRendererParams}
                />
              </div>
              <div className="popupTable">
                <FliptGrid
                  headers={[
                    "tier_name",
                    "rank"
                  ]}
                  data={props.state?.popupData?.tiers?.sort((a, b) => { return a.rank - b.rank })}
                  cellRendererParams={popupCellRendererParams}
                />
              </div>
              <div className="popupTable">
                <FliptGrid
                  headers={[
                    "um_type",
                    "rank"
                  ]}
                  data={props.state?.popupData?.um_ranking?.sort((a, b) => { return a.rank - b.rank })}
                  cellRendererParams={popupCellRendererParams}
                />
              </div>
              <div className="buttonContainer">
                <FliptButton
                  name="Quit"
                  className="secondary searchButton"
                  onClick={() => this.setState({ showTabFlag: false })}
                />
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    state: {
      app: state.app,
      loading: state.surescriptconfig.loading,
      surescriptFormularyConfiguration:
        state.surescriptconfig.surescriptFormularyConfigurationData,
      selectedCarrier:
        state.planManagement.planDetailsFilters.carrier ?? "FLIPT",
      selectedAccount: state.planManagement.planDetailsFilters.account ?? null,
      selectedGroup: state.planManagement.planDetailsFilters.group ?? null,
      benefitPlanData: state.planManagement.benefitPlanData,
      planNameLookupData: state.formularyLookup.planNameLookupData,
      currentFormularyConfigurationData:
        state.surescriptconfig.currentFormularyConfigurationData,
      user: state.user,
      popupData: state.surescriptconfig.popupData,
    },
  }
}

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
    ...SureScriptConfig,
    ...PlanManagementActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SureScriptFormularyConfig)
