import React, { PureComponent } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import './style.scss'
import { Creators as UserActions } from '../../../redux/reducers/user'
import EnterEmailAddress from './enterEmailAddress'
import EnterCode from './enterCode'

class ForgotPassword extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      viewName: 'email',
      form: {
        email_address: '',
        code: '',
      },
    }
  }

  _updateFields = (el) => {
    const { name, value } = el.currentTarget

    this.setState(((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        [name]: value,
      },
    })))
  }

  _resetPassword = () => {
    const { actions } = this.props
    const { form } = this.state
    actions.resetPassword(form)
    this.setState({ viewName: 'code' })
  }

  _verifyCode = () => {
    const { actions } = this.props
    const { form } = this.state
    actions.userVerifyResetPasswordCode(form)
  }

  renderViewSwitch() {
    const { form: { email_address }, viewName } = this.state

    switch (viewName) {
      case 'code':
        return <EnterCode email_address={email_address} verifyCode={this._verifyCode} updateField={this._updateFields} />
      default:
        return <EnterEmailAddress email_address={email_address} sendVerificationCode={this._resetPassword} updateField={this._updateFields} />
    }
  }

  render() {
    const { viewName } = this.state

    return (
      <div id="forgotPasswordPage" className="content-container">
        <div className="content-container">
          <div className="left">
            <div>
              {/* { this?.props?.state?.app?.companyCSSFilename ? <img src={`/i/${this?.props?.state?.app?.companyCSSFilename}/logo-login.png`} alt="Alternate Login Logo" /> : <img hidden alt="Flipt FAST Self Service Application" src="/i/flipt-logo-login.png" /> } */}
              <div className="logo-container">
                <div className="logo-login" />
                <div className="logo-login-default" />
              </div>
            </div>
          </div>
          {this.renderViewSwitch(viewName)}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => (
  {
    state: {
      app: state.app,
    }
  }
)

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...UserActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)
