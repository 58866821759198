import {
    call,
    put, select, takeLatest,
} from 'redux-saga/effects'
import { fetchPost } from '../../../utils/fetchApi'
import { Types, Creators as groupManagementActions } from '../../reducers/api/groupManagement'
import { Creators as AppActions } from '../../reducers/app'
import { getApiPath, getAppState, getClientConfiguration } from '../../reducers/selectors'
import { Creators as NavigationActions } from '../../reducers/navigation'

export default [
    getGroupMgmtDashboard,
    getGroupMaintenanceDashboard,
    createGroup,
    getGroupById,
    getGroupByHierarchy
]

/* WATCHERS */
function* getGroupMgmtDashboard() {
    yield takeLatest(Types.GET_GROUP_MGMT_DASHBOARD, getGroupMgmtDashboardHandler)
}
function* getGroupMaintenanceDashboard() {
    yield takeLatest(Types.GET_GROUP_MAINTENANCE_DASHBOARD, getGroupMaintenanceDashboardHandler)
}
function* createGroup() {
    yield takeLatest(Types.CREATE_GROUP, createGroupHandler)
}
function* getGroupById() {
    yield takeLatest(Types.GET_GROUP_BY_ID, getGroupByIdHandler)
}
function* getGroupByHierarchy() {
    yield takeLatest(Types.GET_GROUP_BY_HIERARCHY, getGroupByHierarchyHandler)
}

/* HANDLERS */
function* getGroupMgmtDashboardHandler({ payload }) {
    try {
        const { serviceUrl } = yield select(getAppState)
        const cd = yield select(getApiPath, 'group-management')
        const { api_path } = cd
        const url = `${serviceUrl}${api_path}`
        const { data, message } = yield call(fetchPost, url, payload)
        if (!data?.length) {
            const transitionalPortal = {
                header: 'Data Not Found',
                copy: 'No Groups Found',
            }
            yield put(AppActions.displayTransitionalPortal(transitionalPortal))
            return
        }
        yield put(groupManagementActions.setGroupMgmtDashboard(data))
    } catch (err) {
        console.log('getGroupMgmtDashboardHandler Error >Data ', err)
        const transitionalPortal = {
            header: 'Getting Group Management Data Failed',
            copy: err,
        }
        yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    }
}

function* getGroupMaintenanceDashboardHandler({ payload }) {
    try {
        const { serviceUrl } = yield select(getAppState)
        const cd = yield select(getApiPath, 'group-maintenance')
        const { api_path } = cd
        const url = `${serviceUrl}${api_path}`
        const { data } = yield call(fetchPost, url, payload)
        if (!data?.length) {
            const transitionalPortal = {
                header: 'Data Not Found',
                copy: 'No Groups Found for Given Client Name',
            }
            yield put(AppActions.displayTransitionalPortal(transitionalPortal))
            return
        }
        yield put(groupManagementActions.setGroupMaintenanceDashboard(data))
    } catch (err) {
        console.log('getGroupMaintenanceDashboardHandler Error >Data ', err)
        const transitionalPortal = {
            header: 'Getting Group Maintenance Failed',
            copy: err,
        }
        yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    }
}

function* getGroupByIdHandler({ payload }) {
    try {
        const { serviceUrl } = yield select(getAppState)
        const cd = yield select(getApiPath, 'get-group-by-id')
        const { api_path } = cd
        const url = `${serviceUrl}${api_path}`
        const { data } = yield call(fetchPost, url, payload)
        if (!data?.length) {
            const transitionalPortal = {
                header: 'Data Not Found',
                copy: 'No Details Found for Given Group ID',
            }
            yield put(AppActions.displayTransitionalPortal(transitionalPortal))
            yield put(NavigationActions.navigateBack())
            return
        }
        yield put(groupManagementActions.setGroupData(data[0]))
    } catch (err) {
        console.log('getGroupMaintenanceDashboardHandler Error >Data ', err)
        const transitionalPortal = {
            header: 'Getting Group Maintenance Failed',
            copy: err,
        }
        yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    }
}

function* getGroupByHierarchyHandler({ payload }) {
    try {
        const { serviceUrl } = yield select(getAppState)
        const cd = yield select(getApiPath, 'get-group-by-hierarchy')
        const { api_path } = cd
        const url = `${serviceUrl}${api_path}`
        const { data } = yield call(fetchPost, url, payload)
        data?.group?.sort((a, b) => a?.record_effective_start_date?.localeCompare(b?.record_effective_start_date))

        const { singleLevelAttribute, addMode } = yield select(getClientConfiguration)
        let preFilledInfo = {
            group_name: singleLevelAttribute?.group_custom_name?.value || "",
            group_id: singleLevelAttribute?.group_custom_id?.value || "",
            group_effective_date: new Date(),
            meta_id: '',
            doc_id: '',
        }
        if (addMode) {
            yield put(groupManagementActions.setEffectiveGroupRecord(preFilledInfo))
        } else {
            if (!data.group.length) {
                yield put(groupManagementActions.setEffectiveGroupRecord(preFilledInfo))
            } else {
                yield put(groupManagementActions.setEffectiveGroupRecord({ ...data.group.at(-1), meta_id: '', doc_id: '' }))
            }
        }
        yield put(groupManagementActions.setDefaultGroupData(data))
        // yield put(groupManagementActions.setSelectedGroupId(selectedGroupId))
        yield put(groupManagementActions.setPlanList(data?.plan || []))
    } catch (err) {
        console.log('getGroupByHierarchyHandler Error >Data ', err)
        const transitionalPortal = {
            header: 'Getting Group By Hierarchy Failed',
            copy: err,
        }
        yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    }
}

function* createGroupHandler({ payload }) {
    try {
        const { serviceUrl } = yield select(getAppState)
        const cd = yield select(getApiPath, 'create-group')
        const { api_path } = cd
        const isDashboard = payload?.isDashboard
        const fromHierarchy = payload?.fromHierarchy
        delete payload?.isDashboard
        delete payload?.fromHierarchy
        const url = `${serviceUrl}${api_path}`
        const { data, status } = yield call(fetchPost, url, payload)
        if (fromHierarchy) {
            if (status === '200') {
                const getPayload = {
                    node_id: payload.node_id || '',
                    node_level: 'group'
                }
                yield put(groupManagementActions.getGroupByHierarchy(getPayload))
                const transitionalPortal = {
                    header: 'Data Updated',
                    copy: 'Data Updated Successfully',
                }
                yield put(AppActions.displayTransitionalPortal(transitionalPortal))
                return
            }
        } else {
            yield put(groupManagementActions.setCreateGroupData(data))
            if (isDashboard) yield put(groupManagementActions.getGroupMaintenanceDashboard({ client_name: payload.client_name }))
            yield put(NavigationActions.navigateTo({ pathname: '/group-maintenance', state: { client_name: payload.client_name } }))
        }
    } catch (err) {
        console.log('createGroupHandler Error >Data ', err)
        const transitionalPortal = {
            header: 'Create Group Failed',
            copy: err,
        }
        yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    }
}


export {
    getGroupMgmtDashboardHandler,
    getGroupMaintenanceDashboardHandler,
    createGroup
}
