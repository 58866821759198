import Immutable from 'seamless-immutable'
import { createActions, createReducer } from 'reduxsauce'

export const INITIAL_STATE = Immutable({
  drugManagementWeeklyData: [],
  newDrugsSummary: [],
  drugTermSummary: [],
  changesByTypeSummary: [],
  changesByDrugSummary: [],
  changesByObjectSummary: [],
  changeTypeDetails: [],
  drugInformation: {},
  brandData: [],
  impactedObject: {},
  drugApprovalFreeze: false,
  reloadPages: 0,
})

export const setWeeklyDrugManagementReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  drugManagementWeeklyData: payload.data,
})

export const setNewDrugsDataReducer = (state = INITIAL_STATE, { payload: { data, freeze } }) => ({
  ...state,
  newDrugsSummary: data,
  drugApprovalFreeze: freeze,
})
export const setDrugTermsReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  drugTermSummary: payload.data,
})
export const setChangesByTypeReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  changesByTypeSummary: payload.data,
})
export const setChangesByDrugReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  changesByDrugSummary: payload.data,
})
export const setChangesByObjectReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  changesByObjectSummary: payload.data,
})
export const setChangeTypeDetailsReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  changeTypeDetails: payload.data[0],
})
export const setDrugInformationReducer = (state = INITIAL_STATE, { payload: { data, freeze } }) => ({
  ...state,
  drugInformation: data[0],
  drugApprovalFreeze: freeze,
})
export const setBrandDataReducer = (state = INITIAL_STATE, { payload: { data, freeze } }) => ({
  ...state,
  brandData: data,
  drugApprovalFreeze: freeze,
})

export const setImpactedObjectDataReducer = (state = INITIAL_STATE, { payload: { doc_id, data } }) => ({
  ...state,
  impactedObject: { ...state.impactedObject, [doc_id]: data },
})

export const setReloadReducer = (state = INITIAL_STATE) => ({
  ...state,
  reloadPages: state.reloadPages+1,
})

export const clearDrugManagementReducer = () => INITIAL_STATE

export const { Types, Creators } = createActions({
  clearDrugManagementData: null,
  getDmWeeklyDrugManagementData: null,
  getDmNewDrugsData: ['payload'],
  getLegacyReport: ['payload'],
  getDmDrugTermsData: ['payload'],
  getDmChangesByTypeData: ['payload'],
  getDmChangesByDrugData: ['payload'],
  getDmChangesByObjectData: ['payload'],
  getDmDrugInformationData: ['payload'],
  getDmChangeType: ['payload'],
  getDmBrandData: ['payload'],
  dmApproveChanges: ['payload'],
  getDmImpactedObjectData: ['payload'],
  setDmWeeklyDrugManagementData: ['payload'],
  setDmNewDrugsData: ['payload'],
  setDmDrugTermsData: ['payload'],
  setDmDrugInformationData: ['payload'],
  setDmChangesByTypeData: ['payload'],
  setDmChangesByDrugData: ['payload'],
  setDmChangesByObjectData: ['payload'],
  setDmChangeTypeDetailsData: ['payload'],
  setDmBrandData: ['payload'],
  setDmImpactedObjectData: ['payload'],
  setReloadData: null,
  clearReducer: null,
})
const HANDLERS = {
  [Types.SET_DM_WEEKLY_DRUG_MANAGEMENT_DATA]: setWeeklyDrugManagementReducer,
  [Types.SET_DM_NEW_DRUGS_DATA]: setNewDrugsDataReducer,
  [Types.SET_DM_DRUG_TERMS_DATA]: setDrugTermsReducer,
  [Types.SET_DM_CHANGES_BY_TYPE_DATA]: setChangesByTypeReducer,
  [Types.SET_DM_CHANGES_BY_DRUG_DATA]: setChangesByDrugReducer,
  [Types.SET_DM_CHANGES_BY_OBJECT_DATA]: setChangesByObjectReducer,
  [Types.SET_DM_CHANGE_TYPE_DETAILS_DATA]: setChangeTypeDetailsReducer,
  [Types.SET_DM_DRUG_INFORMATION_DATA]: setDrugInformationReducer,
  [Types.SET_DM_BRAND_DATA]: setBrandDataReducer,
  [Types.SET_DM_IMPACTED_OBJECT_DATA]: setImpactedObjectDataReducer,
  [Types.CLEAR_DRUG_MANAGEMENT_DATA]: clearDrugManagementReducer,
  [Types.SET_RELOAD_DATA]: setReloadReducer,
  [Types.CLEAR_REDUCER]: clearDrugManagementReducer,
}

export default createReducer(INITIAL_STATE, HANDLERS)
