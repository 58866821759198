import React, { Component } from 'react'
import { Tab } from 'semantic-ui-react'
import * as _ from 'lodash'

import FliptDatePicker from '../fliptDatePicker'
import FliptDropdown from '../fliptDropdown'
import FliptInput from '../fliptInput'
import FliptRadio from '../fliptRadio'
import FliptTextarea from '../fliptTextarea'
import FliptSeparator from '../fliptSeparator'
import CustomTab from '../fliptCustomTab'
import FliptCustom from '../fliptCustom'
import '../formRendererStyles.scss'
import { capitalizeStr, convertStrToDateObj } from '../../../utils/utilities'
import { parseStatement, parseValidation, parseValue } from './formParserUtil';
import { isEmpty } from 'lodash'

class FormRenderer extends Component {

  constructor(props) {
    super(props)

    this.state = {
      form: {},
      activeIndex: -1,
    }
  }

  componentDidMount() {
    this._setFormState()
  }

  componentDidUpdate(prevProps) {
    const { props } = this

    if (_.isEqual(prevProps, props)) return
    this._setFormState()
  }


  _setFormState = () => {
    const { formData: { details, model_data }, activeTabIndex } = this.props
    const { activeIndex, form:localChanges } = this.state
    const sectionKeys = Object.keys(details)
    const section = sectionKeys[activeTabIndex]
    let form
    if (!model_data) {
      form = Object.fromEntries(sectionKeys.flatMap((section) => {
        const { fields } = details[section]
        return fields.filter((field) => !field.read_only).map((field) => [field.field, field.value])
      }))
    } else if(section === 'application_only'){
        form = model_data
        if(activeIndex === activeTabIndex)
          form = { ...model_data, ...localChanges } //For retaining local changes.
    }
    else {
        form = model_data
    }
    this.setState({ form, activeIndex: activeTabIndex })
  }

  _updateFields = (el, dateData) => {
    const { form } = this.state
    const { checked, name, value } = dateData || el.currentTarget
    const formNew = { ...form }
    formNew[name] = value || checked

    this.setState({ form: formNew })
  }

  updateGridValues = (name, value, rowIndex) => {
    const { form } = this.state
    if (isEmpty(form['flipt_marketing_configuration']))
      form['flipt_marketing_configuration'] = [{}]
    form.flipt_marketing_configuration[rowIndex][name] = value

    this.setState({ form })

  }

  _updateCheckbox = (el, dateData) => {
    // TODO: update to only change to Y or N if the options contains Y and N, otherwise true/false
    const { form } = this.state
    const { checked, name } = dateData || el.currentTarget

    form[name] = checked ? 'Y' : 'N'

    this.setState({ form })
  }

  render() {
    const {
      formData: { details, model_data },
      activeTabIndex,
      copyFormData,
      renderActionButtons,
      getTabContents,
      sectionMappings,
      context,
    } = this.props
    const { form: f } = this.state
    const renderSection = ([sectionKey, sectionDetails]) => (
      <div className="section" key={sectionKey}>
        <div className="fields-container">
          {sectionDetails.ui_type === 'CUSTOM' && (sectionDetails.fields.length > 0 || sectionKey === 'review' || sectionKey === 'phases') && (
            <CustomTab
              context={context}
              tabName={sectionDetails.display_name.toLowerCase()}
              fieldDetails={sectionDetails.fields}
              copyFormData={copyFormData}
              modelData={model_data}
              getTabContents={getTabContents}
            />
          )}
          {sectionDetails.ui_type !== 'CUSTOM' && sectionDetails.fields.map((fieldDetails) => {
            const statement = fieldDetails?.hidden || false
            let hidden = false
            if (statement && _.isObject(statement)) {
              hidden = parseStatement(Object.keys(statement)[0], statement, this)
            }
            let validation = fieldDetails?.validation
            if (!!validation) fieldDetails.customValidation = parseValidation(validation)
            if ((typeof fieldDetails.dynamic === 'undefined' || !!fieldDetails.dynamic) && !hidden) {
              return renderField(fieldDetails)
            }
            return null
          })}
        </div>
      </div>
    )

    const renderField = (fieldDetails) => {
      const {
        datatype,
        description,
        display_name,
        multiple,
        field,
        options,
        option_save_keys,
        read_only,
        required,
        type,
        customValidation,
        input_props={}
      } = fieldDetails

      const { form } = this.state
      const optionsArr = options?.length > 0 ? options : [{ text: 'None', value: 'None' }]

      let { value } = fieldDetails

      if (_.isObject(value) && type === 'INPUT') {
        value = parseValue(value, this)

        if (read_only) {
          if (!value) value = ''
        } else if (form[field]) {
          value = form[field]
        }
      }

      switch (type.toLowerCase()) {
        case 'input':
          return (
            <FliptInput
              datatype={datatype}
              description={description}
              disabled={read_only}
              key={field}
              label={display_name}
              name={field}
              required={required}
              onChange={this._updateFields}
              value={value || form[field]}
              customValidation={customValidation}
              stylized="true"
              {...input_props}
            />
          )
        case 'dropdown':
          return (
            <FliptDropdown
              clearable={false}
              description={description}
              disabled={read_only}
              key={field}
              label={display_name}
              multiple={multiple}
              name={field}
              onChange={this._updateFields}
              options={optionsArr.map((o) => ({ text: o.display_name, value: o.value, label: o.doc_version || null }))}
              option_save_keys={option_save_keys}
              value={form[field] || value}
              scrolling
              search
              selection
              selectOnBlur={false}
              stylized="true"
            />
          )
        case 'textarea':
          return (
            <FliptTextarea
              description={description}
              disabled={read_only}
              key={field}
              label={display_name}
              name={field}
              onChange={this._updateFields}
              value={(read_only ? value : form[field]) || ''}
              stylized="true"
            />
          )
        case 'date':
          return (
            <FliptDatePicker
              description={description}
              disabled={read_only}
              key={field}
              label={display_name}
              name={field}
              onChange={this._updateFields}
              value={(read_only ? convertStrToDateObj(value) : convertStrToDateObj(form[field])) || ''}
              stylized="true"
            />
          )
        case 'radio':
          return (
            <FliptRadio
              description={description}
              disabled={read_only}
              key={field}
              label={display_name}
              name={field}
              toggle
              // TODO: remove hard coding for deductible_exempt
              onChange={field !== 'deductible_exempt' ? this._updateFields : this._updateCheckbox}
              checked={(read_only ? (value === true || value === 'Y') : (form[field] === true || form[field] === 'Y')) || false}
              stylized="true"
              // TODO: remove hard coding for deducible_exempt
              value={field !== 'deductible_exempt' ? undefined : (form[field] || value)}
            />
          )
        case 'separator':
          return (
            <FliptSeparator />
          )
        case 'custom':
          return (
            <FliptCustom
              context={context}
              form={form}
              updateFields={this._updateFields}
              updateGridValues={this.updateGridValues}
              {...fieldDetails}
            />
          )
        default:
          return null
      }
    }

    const sections = Object.entries(details)


    return (
      <>
        <div className="form-renderer content">
          <Tab
            activeIndex={activeTabIndex}
            onTabChange={(e, data) => {
              const menuTitle = data.panes[data.activeIndex].menuItem
              if (getTabContents) {
                getTabContents(menuTitle)
              }
            }}
            panes={sections.map((sectionEntry) => {
              const sectionNamesToNotCapitalize = ['rra', 'psc (daw)']
              const key = sectionEntry[0]
              const display_name = (sectionMappings && key in sectionMappings) ? sectionMappings[key] : sectionEntry[1].display_name

              const lowercase_display_name = display_name.toLowerCase()
              const section_name = sectionNamesToNotCapitalize.includes(lowercase_display_name)
                ? display_name : lowercase_display_name
              return {
                menuItem: capitalizeStr(section_name),
                render: () => renderSection(sectionEntry),
              }
            })}
          />
        </div>
        {renderActionButtons && renderActionButtons(f)}
      </>
    )
  }
}

export default FormRenderer
