import React, { Component } from 'react'
import { connect } from 'react-redux'
import FliptDropdown from '../../../../components/form/fliptDropdown'
import _ from 'lodash'
import './styles.scss'
import FliptInput from '../../../../components/form/fliptInput'

class Phases extends Component {

  constructor(props) {
    super(props)
    this.state = {
      phasesData: props.state?.phasesData,
    }
  }


  componentDidUpdate(prevProps) {
    const { phasesData } = this.props.state
    if (phasesData !== prevProps.state.phasesData) {
      this.setState({ phasesData: phasesData });
    }
  }

  formatAmount = (amount) => {
    if (amount !== null) {
      return amount
    }
    else {
      return 0
    }
  }

  renderFields = (data) => {
    return (
      <div className="fields-container">
        <FliptInput
          label="Begin Amount"
          name="begin_amount"
          value={'$ ' + data?.begin_amt && this.formatAmount(data?.begin_amt)}
          stylized
        />
        <FliptDropdown
          placeholder={data?.begin_spend_type}
          value={'begin_spend_type'}
          options={[{ 'text': data?.begin_spend_type, 'value': data?.begin_spend_type }]}
          name="begin_spend_type"
          label="Begin Spend Type"
          stylized
        />
        <FliptInput
          label="End Amount"
          name="end_amount"
          value={'$ ' + data?.end_amt && this.formatAmount(data?.end_amt)}
          stylized
        />
        <FliptDropdown
          value={'end_spend_type'}
          placeholder={data?.end_spend_type}
          options={[{ 'text': data?.end_spend_type, 'value': data?.end_spend_type }]}
          name="end_spend_type"
          label="End Spend Type"
          stylized
        />
      </div>
    );
  }

  render() {
    let deductiableData, coverageGapData, initialCoverage, catastrophicData = {}
    if (this.state.phasesData.length > 0) {
      this.state.phasesData?.map((data) => {
        if (data?.phase === "Deductible") {
          deductiableData = data
        }
        else if (data?.phase === 'Initial Coverage') {
          initialCoverage = data
        }
        else if (data?.phase === 'Coverage Gap') {
          coverageGapData = data
        }
        else if (data?.phase === 'Catastrophic') {
          catastrophicData = data
        }
      })
    }

    return (
      <div id="Phases">
        <div>
          <div className="section-header">Deductible</div>
          {this.renderFields(deductiableData)}
        </div>
        <div>
          <div className="section-header">Initial Coverage</div>
          {this.renderFields(initialCoverage)}
        </div>
        <div>
          <div className="section-header">Coverage Gap</div>
          {this.renderFields(coverageGapData)}
        </div>
        <div>
          <div className="section-header">Catastrophic</div>
          {this.renderFields(catastrophicData)}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return ({
    state: {
      app: state.app,
      phasesData: state.planManagement.phasesData,
    },
  })
}

export default connect(mapStateToProps, null)(Phases)
