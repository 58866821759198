import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import _, { set } from 'lodash'
import './styles.scss'
import { Creators as AppActions } from '../../../../../redux/reducers/app'
import { Creators as ClaimsPRPActions } from '../../../../../redux/reducers/api/claimsProcessingAndReprocessing'
import { Creators as AutoSuggestActions } from '../../../../../redux/reducers/api/autoSuggest'
import FliptGrid from '../../../../../components/fliptGrid'
import FliptRadio from '../../../../../components/form/fliptRadio'
import ClaimGrid from '../../components/claimGrid'
import FliptButton from '../../../../../components/v2/fliptButton'
import BatchCriteria from './batchCriteria'
import { DeleteRowRenderer } from '../../../../../components/fliptGrid/cellRenderers'
import { DatePickerEditor, DropdownEditor, InputTextEditor, SearchEditor } from '../../../../../components/fliptGrid/cellEditors'
import { getCAGOptions } from '../../../../../utils/utilities'

import rowCellInfo from '../../../integrationManagement/rowCellInfo'
import FliptMultiMemberSearch from '../../../../../components/fliptMultiMemberSearch'
import FliptMultiPharmacySearch from '../../../../../components/fliptMultiPharmacySearch'
import FliptMultiPrescriberSearch from '../../../../../components/fliptMultiPrescriberSearch'

function ProductionClaimSearch(props) {
	const { state, actions, claimForm, setClaimForm, editable, testBatchConfig } = props
	let _setAutoSuggestionValues = null
	const { productionClaims, autoSuggestData, uploadedBatchCriteria, carriers } = state
	const [productionClaimSelection, setProductionClaimSelection] = useState([])
	const [buildCriteriaData, setBuildCriteriaData] = useState({
		memberData: [],
		prescriberData: [],
		pharmacyData: [],
		searchData: [{
			carrier: '',
			account: '',
			group: '',
			auth_id: '',
			member_id: '',
			start_date: '',
			end_date: '',
			first_name: '',
			last_name: '',
			npi: '',
			include: true,
			drug_name: '',
			plan_name: '',
			gpi: '',
			ndc: ''
		}],
		selectedRows: [],
		autoSuggestionValues: [{
			drug_name: { values: [], loading: false }
		}],
		rowUpdated: null,
		columnUpdated: null,
		editable: false,
	})
	const [isUploadFile, setIsUploadFile] = useState(false)

	useEffect(() => {
		_updateAutoSuggestions()
	}, [autoSuggestData])

	useEffect(() => {
		actions.clearProductionClaims()
		actions.clearUploadedBatchCriteria()
	}, [])

	useEffect(() => {
		const { searchData } = buildCriteriaData
        const filterOptions = getCAGOptions(carriers, searchData?.length && searchData[0].carrier, searchData?.length && searchData[0].account, true)
        rowCellInfo.carrier.options = filterOptions.carrierOptions
        rowCellInfo.account.options = filterOptions.accountOptions
        rowCellInfo.group.options = filterOptions.groupOptions
	}, [buildCriteriaData])

	useEffect(() => {
		if (uploadedBatchCriteria.length) {
			const { searchData } = buildCriteriaData
			const newSearchData = [...searchData]
			uploadedBatchCriteria.forEach((row) => {
				newSearchData.push({
					...row,
					carrier: '',
					account: '',
					group: '',
					start_date: '',
					end_date: '',
					first_name: '',
					last_name: '',
					npi: '',
					include: true,
					drug_name: '',
					plan_name: '',
					gpi: '',
					ndc: ''
				})
			}
			)
			setBuildCriteriaData({ ...buildCriteriaData, searchData: newSearchData })
		}
	}, [uploadedBatchCriteria])

	/* Build Criteria Section */
	const onUploadFile = (file, fieldType) => {
		actions.uploadBatchCriteriaFile({ file, fieldType })
	}

	const batchInput = () => {
        const { actions } = props
        if (!isUploadFile) return
        actions.setModalComponent({
            modalProperties: { size: 'small', className: 'bulk-reprocessing-restacking-modal' },
            contents: <BatchCriteria onUploadFile={onUploadFile}/>,
        })
        actions.toggleModal()
    }

    const _updateAutoSuggestions = () => {
		const { columnUpdated, autoSuggestionValues, rowUpdated } = buildCriteriaData
		if (columnUpdated === null && rowUpdated === null) return
        const { autoSuggestData } = props.state
        const newAutoSuggestionValues = [...autoSuggestionValues]
        newAutoSuggestionValues[rowUpdated][columnUpdated].values = autoSuggestData[columnUpdated].map((val) => ({
            title: val,
        }))
        newAutoSuggestionValues[rowUpdated].drug_name.loading = false
        if (_setAutoSuggestionValues) _setAutoSuggestionValues(newAutoSuggestionValues)
		setBuildCriteriaData({ ...buildCriteriaData, autoSuggestionValues: newAutoSuggestionValues })
    }

	const delRow = (rowIndex) => {
		const { searchData, autoSuggestionValues } = buildCriteriaData
		const newSearchData = [...searchData]
		const newAutoSuggestionValues = autoSuggestionValues.filter((value) => autoSuggestionValues.indexOf(value) !== rowIndex)
		newSearchData.splice(rowIndex, 1)
		setBuildCriteriaData({ ...buildCriteriaData, searchData: newSearchData, autoSuggestionValues: newAutoSuggestionValues})
	}
	const onRowSelected = e => e?.node?.data && (e.node.data['include'] = e?.node?.selected);
	const _onComponentStateChanged = params => params.api.forEachNodeAfterFilter(node => node.setSelected(node?.data?.include));
	
	const searchHeaders = ['action', 'include', 'carrier', 'account', 'group', 'plan_name', 'member_id', 'pharmacy_npi', 'prescriber_npi',
	'auth_id', 'start_date', 'end_date', 'drug_name', 'gpi', 'ndc']
	const searchCellRendererParams = {
		action: {
			cellRenderer: DeleteRowRenderer,
			state: {
				onClick: delRow,
			},
			width: 95,
		},
		include: {
			overrideHeader: 'Include',
			cellRenderer: () => {
				return <p />
			},
			headerCheckboxSelection: true,
			headerCheckboxSelectionFilteredOnly: true,
			checkboxSelection: true,
		},
		pharmacy_npi: {
			overrideHeader: 'Pharmacy NPI'
		},
		member_id: {
			overrideHeader: 'Member ID'
		},
		prescriber_npi: {
			overrideHeader: 'Prescriber NPI'
		},
		auth_id: {
			overrideHeader: 'Auth ID'
		},
	}

    const autoSuggestionResultSelect = (data, rowIndex) => {
        const { state } = this
        const searchData = [...state.searchData]
        const rowToUpdate = searchData[rowIndex]
        const { name, result } = data
        const value = result.title

        rowToUpdate[name] = value
        searchData[rowIndex] = rowToUpdate
		setBuildCriteriaData({ ...buildCriteriaData, searchData })
    }


    const updateData = (type, data, rowIndex) => {
		let newDataArray = data.map(
			(item) => item[type === 'member' ? 'member_id' : type === 'pharmacy' ? 'pharmacynpi' : 'npi'],
		)
		let newData = newDataArray.join(', ')
		setBuildCriteriaData((prevState) => {
			let newState = _.cloneDeep(prevState)
			newState.searchData[rowIndex][
				type === 'member' ? 'member_id' : type === 'pharmacy' ? 'pharmacy_npi' : 'prescriber_npi'
			] = newData
			return newState
		})
	}


    const searchMember = (rowIndex) => {
		const { memberData } = buildCriteriaData
		actions.setModalComponent({
			modalProperties: { size: 'small', className: 'bulk-reprocessing-restacking-modal', scrolling: true },
			contents: (
				<FliptMultiMemberSearch updateMemberData={(data) => updateData('member', data, rowIndex)} memberData={memberData} />
			),
		})
		actions.toggleModal()
	}

	const searchPrescriber = (rowIndex) => {
		const { prescriberData } = buildCriteriaData
		actions.setModalComponent({
			modalProperties: { size: 'small', className: 'bulk-reprocessing-restacking-modal', scrolling: true },
			contents: (
				<FliptMultiPrescriberSearch
					updatePrescriberData={(data) => updateData('prescriber', data, rowIndex)}
					prescriberData={prescriberData}
				/>
			),
		})
		actions.toggleModal()
	}

	const searchPharmacy = (rowIndex) => {
		const { pharmacyData } = buildCriteriaData
		actions.setModalComponent({
			modalProperties: { size: 'small', className: 'bulk-reprocessing-restacking-modal', scrolling: true },
			contents: (
				<FliptMultiPharmacySearch
					updatePharmacyData={(data) => updateData('pharmacy', data, rowIndex)}
					pharmacyData={pharmacyData}
				/>
			),
		})
		actions.toggleModal()
	}

    const _clearExtraValue = (value, selectedData, key) => {
        let arrayData = value?.split(",")
        if (arrayData.length === 1 && arrayData[0].length === 1) {
            return '';
        } else {
            const idArray = selectedData.map((data) => { return data[key] })
            return idArray.join(', ');
        }
    }
    const handleChange = (el, dropdown, rowIndex, gridApi, stepLevel, setAutoSuggestionValues) => {       
		const { searchData, prescriberData, pharmacyData, memberData, autoSuggestionValues, rowUpdated } = buildCriteriaData
		let newSearchData = searchData
        const { actions } = props
        const { name, value } = dropdown || el.currentTarget
        if (name === 'member_id') {
            newSearchData = _.cloneDeep(searchData)
            newSearchData[rowIndex][name] = _clearExtraValue(value, memberData, 'member_id')
			setBuildCriteriaData({ ...buildCriteriaData, searchData: newSearchData, regenerateEnable: true })
            searchMember(rowIndex)
            return;
        }
        if (name === 'prescriber_npi') {
            newSearchData = _.cloneDeep(searchData)
            newSearchData[rowIndex][name] = _clearExtraValue(value, prescriberData, 'npi')
			setBuildCriteriaData({ ...buildCriteriaData, searchData: newSearchData, regenerateEnable: true })
            searchPrescriber(rowIndex)
            return;
        }
        if (name === 'pharmacy_npi') {
            newSearchData = _.cloneDeep(searchData)
            newSearchData[rowIndex][name] = _clearExtraValue(value, pharmacyData, 'pharmacynpi')
			setBuildCriteriaData({ ...buildCriteriaData, searchData: newSearchData, regenerateEnable: true })
            searchPharmacy(rowIndex)
            return;
        }
        const newAutoSuggestionValues = [...autoSuggestionValues]
        if (name === 'drug_name' && value.length >= 3) {
            newAutoSuggestionValues[rowIndex].drug_name.loading = true
            actions.getAutoSuggestData({ search_string: value, search_in: 'fts_ndc_drugs', search_for: 'drug_name' })
			_setAutoSuggestionValues = setAutoSuggestionValues
        }
        if (rowUpdated !== rowIndex) {
            actions.clearAutoSuggestSearchTerm()
        }

        newSearchData[rowIndex][name] = value
		setBuildCriteriaData({ ...buildCriteriaData, newSearchData, rowUpdated: rowIndex, columnUpdated: name})
        gridApi.refreshCells(newSearchData)
    }

	const searchCellEditorParams = {
		carrier: {
			editable: true,
			cellEditor: DropdownEditor,
			onChange: handleChange,
		},
		account: {
			editable: true,
			cellEditor: DropdownEditor,
			onChange: handleChange,
		},
		group: {
			editable: true,
			cellEditor: DropdownEditor,
			onChange: handleChange,
		},
		member_id: {
			editable: true,
			cellEditor: InputTextEditor,
			disabled: !editable,
			onChange: handleChange,
		},
		pharmacy_npi: {
			editable: true,
			cellEditor: InputTextEditor,
			disabled: !editable,
			onChange: handleChange,
		},
		prescriber_npi: {
			editable: true,
			cellEditor: InputTextEditor,
			disabled: !editable,
			onChange: handleChange,
		},
		auth_id: {
			editable: true,
			cellEditor: InputTextEditor,
			disabled: !editable,
			onChange: handleChange,
		},
		start_date: {
			editable: true,
			cellEditor: DatePickerEditor,
			disabled: !editable,
			onChange: handleChange,
		},
		drug_name: {
			cellEditor: SearchEditor,
			onChange: handleChange,
			disabled: !editable,
			autoSuggestionResultSelect: autoSuggestionResultSelect,
		},
		plan_name: {
			editable: true,
			cellEditor: InputTextEditor,
			disabled: !editable,
			onChange: handleChange,
		},
		gpi: {
			editable: true,
			cellEditor: InputTextEditor,
			disabled: !editable,
			onChange: handleChange,
		},
		ndc: {
			editable: true,
			cellEditor: InputTextEditor,
			disabled: !editable,
			onChange: handleChange,
		},
		end_date: {
			editable: true,
			cellEditor: DatePickerEditor,
			onChange: handleChange,
		},
	}
	const addCondition = () => {
		const { searchData, autoSuggestionValues } = buildCriteriaData
        const newSearchData = [...searchData]
		const newAutoSuggestionValues = [...autoSuggestionValues, {
			drug_name: { values: [], loading: false }
		}] 
        newSearchData.push({
            carrier: '',
            account: '',
            group: '',
            auth_id: '',
            member_id: '',
            start_date: '',
            end_date: '',
            first_name: '',
            last_name: '',
            npi: '',
            include: true,
            drug_name: '',
            plan_name: '',
            gpi: '',
            ndc: ''
        })
		setBuildCriteriaData({ ...buildCriteriaData, searchData: newSearchData, autoSuggestionValues: newAutoSuggestionValues})
    }

	/* Production Claim Section */
	const getKeyName = (key) => {
        const keyObjReverse = {
            'member_id': 'claim_request.cardholder_id',
            'prescriber_npi': 'claim_request.prescriber_id',
            'pharmacy_npi': 'claim_request.service_provider_id',
            'account': 'domain',
            'start_date': 'startDate',
            'end_date': 'endDate',
            'group': 'member_group',
            'carrier': 'carrier',
            'auth_id': 'auth_id',
            'drug_name': 'drug_name',
            'plan_name': 'benefit_plan_name',
            'gpi': 'claim_request.gpi',
            'ndc': 'claim_request.product_id'
        }
        return keyObjReverse[key]
    }

	const pullClaims = () => {
		const { searchData } = buildCriteriaData
		let rulesList = []
        searchData.forEach((data) => {
            const innerArray = []
            const keys = Object.keys(data)
            keys.forEach((keyData) => {
                if (data[keyData] && keyData !== 'include') {
                    let condition_type = data.include ? 'IN' : 'NOT_IN'
                    let finalValue = data[keyData]
                    if (keyData === 'start_date' || keyData === 'end_date') {
                        finalValue = moment(finalValue).format('YYYY-MM-DD')
                        condition_type = keyData === 'start_date' ? 'GREATER_THAN_EQUAL_TO' : 'LESS_THAN_EQUAL_TO'
                    } else if (data[keyData]?.length && !Array.isArray(data[keyData])) {
                        finalValue = data[keyData].split(",")
                    }
                    innerArray.push({
                        field: getKeyName(keyData),
                        condition_type,
                        value: finalValue
                    })
                }
            })
            rulesList.push(innerArray)
        })
		actions.pullProductionClaims({
			rulesList,
		})
	}

	const productionClaimHeaders = [
		'Select',
		'auth_id',
		'group_id',
		'cardholder_id',
		'transaction_response_status',
		'message',
		'basis_of_cost_determination',
		'service_provider_id',
		'prescriber_id',
		'prescriber_id_qualifier',
		'prescriber_first_name',
		'prescriber_last_name',
		'drug_name',
		'product_id',
		'quantity_dispensed',
		'days_supply',
		'fill_number',
		'bin_number',
		'compound_code',
		'date_of_service',
		'date_prescription_written',
		'person_code',
	]
   
	const productionClaimChange = (newData) => {
		const selectedRows = newData.api.getSelectedRows().map((row) => ({ ...row, selected: true }))
		setProductionClaimSelection(selectedRows)
	}

	const productionClaimCellRendererParams = {
		Select: {
			checkboxSelection: true,
			width: '120px',
			filter: false,
		}
	}

	/* Generate Test Scenarios Section */
	const generateTestScenarios = () => {
		let tempGridData = []
		productionClaimSelection.forEach((row) => {
			row["claim_type"] = "B1"
			row["daw_code"] = "0"
			row["member_id"] = "MEMBER_ID_1"
			row["ndc"] = row["product_id"]
			row["pharmacy_id"] = row["service_provider_id"]
			row["prescription_reference_number"] = Math.floor(Math.random() * (999 - 1 + 1)) + 1
			row["date_of_service"] = new Date()
			if (!row["basis_of_cost_determination"]) {
				row["basis_of_cost_determination"] = "01"
			}
			row["prescriberData"] = {
				"first_name": row["prescriber_first_name"] || " ",
				"last_name": row["prescriber_last_name"] || " ",
				"phone_number": row["prescriber_phone"] || "9999999999",
				"npi": row["prescriber_id"]
			}
			row["date_prescription_written"] = new Date()
			row["scc"] = row["submission_clarification_code"]
			row["type"] = "test_batch_claim"
			tempGridData.push(row)
		})
		setClaimForm({ ...claimForm, gridData: tempGridData })
	}

	return (
		<div id="ProductionClaimSearch">
			<div className="build-criteria">
				<div className="build-criteria-upload">
					<span className="checkbox-label">Build Criteria</span>
					<div className="radio-text">Do you want to build criteria via file upload?</div>
					<div className="radio-container">
						<FliptRadio
							radioLabel="Yes"
							checked={isUploadFile}
							value="Yes"
							name="is_global"
							onChange={() => setIsUploadFile(true)}
						/>
						<FliptRadio
							radioLabel="No"
							checked={!isUploadFile}
							value="No"
							name="is_global"
							onChange={() => setIsUploadFile(false)}
						/>
					</div>
					<div
						className={`file-upload-container ${!isUploadFile ? 'file-upload-container-overlay' : ''}`}
						onClick={batchInput}
						role="button"
						aria-label="Upload file"
					>
						<img src="/i/upload-icon.png" alt="Upload Icon" />
						<div className="inner-file-upload-container">
							<div className="inner-text">Click to upload</div>
							<span className="inner-sub-text">.excel</span>
						</div>
					</div>
				</div>
				<div className="build-criteria-grid">
					<div className="claim-grid-height">
						<FliptGrid
							headers={searchHeaders}
							cellEditorParams={searchCellEditorParams}
							rowCellInfo={rowCellInfo}
							cellRendererParams={searchCellRendererParams}
							data={buildCriteriaData.searchData}
							rowSelection="multiple"
							onRowSelected={onRowSelected}
							onComponentStateChanged={_onComponentStateChanged}
							autoSuggestionValues={buildCriteriaData.autoSuggestionValues}
							suppressRowClickSelection={true}
						/>
					</div>
					<div className="button-container">
						<FliptButton name="Add Condition" className="primary" disabled={!editable} onClick={() => addCondition()} />
						<FliptButton name="Pull Claims" className="primary" onClick={pullClaims} />
					</div>
				</div>
			</div>
			<div className="production-claims-grid">
				<h2>Production Claims</h2>
				<div className="claim-grid-height">
					<FliptGrid
						headers={productionClaimHeaders}
						data={productionClaims}
						rowSelection="multiple"
						onSelectionChanged={(newData) => productionClaimChange(newData)}
						cellRendererParams={productionClaimCellRendererParams}
					/>
				</div>
				<div className="button-container">
					<FliptButton name="Generate Test Scenarios" className="primary" onClick={generateTestScenarios} />
				</div>
			</div>
			<div className="grid-section claim-grid-height">
				<h2>Test Claim Scenarios</h2>
				<ClaimGrid
					editable={editable}
					claimForm={claimForm}
					testBatchConfig={testBatchConfig}
					setClaimForm={setClaimForm}
				/>
			</div>
		</div>
	)
}

const mapStateToProps = (state) => ({
	state: {
		user: state.user,
		carriers: state?.user?.carriers && state?.user?.carriers?.length > 0 ? state.user.carriers : [],
		productionClaims: state.claimsProcessingAndReprocessing.productionClaims || [],
        autoSuggestData: state.autoSuggest.autoSuggestData,
		uploadedBatchCriteria: state.claimsProcessingAndReprocessing.uploadedBatchCriteria || [],
	},
})

const mapDispatchToProps = (dispatch) => {
	const allActions = {
		...AppActions,
		...ClaimsPRPActions,
		...AutoSuggestActions,
	}

	return {
		actions: bindActionCreators(allActions, dispatch),
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(ProductionClaimSearch)
