export const opioidTabConfigs = [
    {
        tabName: 'Opioid Programs',
        id: 'Opioid Programs',
    },
    {
        tabName: 'Opioid MME',
        id: 'Opioid MME',
    }
]

export const opioidHeaders = [
    'program_action',
    'program_name',
    'program_version',
    'program_status',
    'program_create_date'
]

export const mmeHeaders = [
    'action',
    'doc_name',
    'dur_type',
    'status',
    'doc_version',
    'create_date'
]

export const OPIOID_LOCAL_STORAGE_KEY = 'cmTabs'
