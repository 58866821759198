import Immutable from 'seamless-immutable'
import { createActions, createReducer } from 'reduxsauce'

export const INITIAL_STATE = Immutable({
    data: [],
    optionsData: [],
    form: {
        module: '',
        doc_name: '',
        gpi: '',
        ndc: '',
        ddid: '',
        moduleNames: []
    },
    totalRows: 0,
    claimsPerPage: 20
})

export const setDrugMappingLookupDataReducer = (state = INITIAL_STATE, { payload }) => ({
    ...state,
    data: payload.data,
    totalRows: payload.totalRows,
  })

export const clearReducer = () => INITIAL_STATE

export const setDrugMappingOptionsReducer = (state = INITIAL_STATE, { payload }) => ({
    ...state,
    optionsData: payload,
  })

export const { Types, Creators } = createActions({

    getDrugMappingLookupData: ['payload'],
    setDrugMappingLookupData: ['payload'],
    getDrugMappingOptionsData: null,
    setDrugMappingOptionsData:  ['payload'],
    clearReducer: null
  })

  const HANDLERS = {
    [Types.CLEAR_REDUCER]: clearReducer,
    [Types.SET_DRUG_MAPPING_OPTIONS_DATA]: setDrugMappingOptionsReducer,
    [Types.SET_DRUG_MAPPING_LOOKUP_DATA]: setDrugMappingLookupDataReducer,
  }
  
  export default createReducer(INITIAL_STATE, HANDLERS)