
import React, { PureComponent } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import './styles.scss'
import { ActionPopupMenuRenderer, EllipsisRpmRenderer } from '../../../components/fliptGrid/cellRenderers'
import BreadCrumb from '../../../components/breadCrumb'
import Config from "../../../config";
import FliptGrid from '../../../components/fliptGrid';
import { Creators as AppActions } from '../../../redux/reducers/app'
import { Creators as NavigationActions } from '../../../redux/reducers/navigation'
import { Creators as FormularyExportConfigurationActions } from '../../../redux/reducers/api/formularyExportConfiguration'
import { Creators as RPMActions } from '../../../redux/reducers/rpm'
import { LinkRenderer } from '../../../components/fliptGrid/cellRenderers'


class FormularyExportConfiguration extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {}
  }

  componentDidMount() {
    const { actions } = this.props
    actions.clearApprovalDocuments()

    setTimeout(actions.getFormularyExportConfigurationList(), Config.COMPONENT_DATA_LOAD_TIME)
  }

  componentWillUnmount() {
    const { actions } = this.props
    actions.clearFormularyExportConfigurationList()
  }

  _updateForm = (el, dropdown) => {
    const { form } = this.state
    const { name, value } = dropdown || el.currentTarget

    form[name] = value
    this.setState({ form })
  }

  _sendForReview = (data) => {
    const { actions } = this.props
    const payload = {
      doc_id: data.id,
      module: 'FORMULARY_EXPORT_CONFIGURATION',
    };
    actions.formularyExportConfigurationSendForReview(payload, data);
  };

  _checkActionType = (rowData) => {
    if (rowData && rowData["qsArgs"]) {
      const action = rowData["qsArgs"]["selected_action"];

      if (action === "send_for_review") {
        this._sendForReview(rowData);
      }

    }
  };

  createNewGroup = () => {
    this.props.actions.navigateTo({
      pathname: '/formulary-export-configuration-builder',
      state: {
        editMode: true
      }
    })
  }

  cellRendererParams = {
    module_name: {
      cellRenderer: LinkRenderer,
      minWidth: 300,
      overrideHeader: 'Formulary Name',
      path: '/formulary-export-configuration-builder',
      searchArgs: { doc_id: 'id' },
      state: {
        editMode: false,
        doc_id: 'id',
        status: 'status',
      },
    },
    id: {
      hide: true
    },
    update_date: {
      overrideHeader: 'Load Date'
    },
    create_by: {
      overrideHeader: 'Created By',
    },
    create_date: {
      overrideHeader: 'Create Date'
    },
    last_updated: {
      overrideHeader: 'Last Updated'
    },
    actions: {
      cellRenderer: ActionPopupMenuRenderer,
      // pinned: 'right',
      cellClass: 'actionsColumns',
      suppressMenu: true,
      minWidth: 100,
      maxWidth: 100,
      searchArgs: {
        approvalParamsMapping: { doc_id: 'id', name: 'module_name', version: 'version' },
        onClick: (rowdata) => this._checkActionType(rowdata),
        path: '/formulary-export-configuration',
        qsArgs: {
          action: 'COPY_OBJECT',
          doc_id: 'id',
          module: 'FORMULARY_EXPORT_CONFIGURATION',
          status: 'status',
        },
      },
    },
  }

  render() {
    const { documents } = this.props.state
    const headers = ['module_name', 'version', 'created_by', 'create_date', 'status', 'last_updated', 'assigned_to', 'actions']

    return (
      <div id="FormularyExportConfiguration">
        <BreadCrumb {...this.props} />
        <div className="header">
          Formulary Export Configuration
          <span className='link-text' onClick={this.createNewGroup}>
            + Add New Formulary Export Configuration
          </span>
        </div>
        <div className="content">
          <div className="section">
            <FliptGrid
              headers={headers}
              cellRendererParams={this.cellRendererParams}
              data={documents || []}
            />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  state: {
    app: state.app,
    documents: state.rpm.documents,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
    ...FormularyExportConfigurationActions,
    ...NavigationActions,
    ...RPMActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormularyExportConfiguration)
