import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Icon, Popup } from "semantic-ui-react";

const Info = (props) => {
  const { content, position } = props;

  return (
    <Popup
      trigger={<Icon name="question circle outline" />}
      content={content}
      position={position}
    />
  );
};

export default Info;
