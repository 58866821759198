import {
    call,
    put,
    select,
    takeLatest,
  } from 'redux-saga/effects'
  import { v4 as uuidv4 } from 'uuid'
  
  import { Creators as AppActions } from '../../reducers/app'
  import { Creators as NavigationActions } from '../../reducers/navigation'
  import { Creators as RPMActions } from '../../reducers/rpm'
  import { Types, Creators as RebateActions } from '../../reducers/api/posRebate'
  import { fetchPost } from '../../../utils/fetchApi'
  import { getAppState, getApiPath, getHistory, getUserState } from '../../reducers/selectors'
  import { updateCriteriaIdsHandler } from './qualifiers'
  import { Creators as UserActions } from '../../reducers/user'
  import SystemErrorModal from '../../../utils/systemError'
  
  export default [
    approveRebateWatcher,
    generateRebateWatcher,
    getPOSRebateDataWatcher,
    getAllRebateDataWatcher,
    savePOSRebateDataWatcher,
  ]
  
  /* WATCHERS */
  function* getAllRebateDataWatcher() {
    yield takeLatest(Types.GET_ALL_REBATE_DATA, getAllRebateDataHandler)
  }
  
  function* generateRebateWatcher() {
    yield takeLatest(Types.GENERATE_REBATE, generateRebateHandler)
  }
  
  function* savePOSRebateDataWatcher() {
    yield takeLatest(Types.SAVE_POS_REBATE_DATA, savePOSRebateDataHandler)
  }
  
  function* getPOSRebateDataWatcher() {
    yield takeLatest(Types.GET_POS_REBATE_DATA, getPOSRebateDataHandler)
  }
  
  function* approveRebateWatcher() {
    yield takeLatest(Types.APPROVE_REBATE, approveRebateHandler)
  }
  
  /* HANDLERS */
  function* getAllRebateDataHandler() {
    try {
      const { userCAG = {} } = yield select(getUserState)
      const body = {
        module: 'POS_REBATE',
        status: [
          "DRAFT",
          "FOR_REVIEW",
          "TEST_APPROVED",
          "TEST_READY",
          "REJECTED",
          "APPROVED",
          "PUBLISH_READY",
          "PUBLISHED"
        ],
      }
  
      yield put(RPMActions.rpmGetApprovalDocuments(body))
    } catch (err) {
      console.log('getAllRebateDataHandler Error >Data ', err)
  
      const transitionalPortal = {
        header: 'POS Rebate Lookup Failed',
        copy: err.message,
      }
      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    }
  }
  
  function* generateRebateHandler({ payload }) {
    try {
      const { serviceUrl } = yield select(getAppState)
      const cd = yield select(getApiPath, 'generate-pos-rebate')
      const { api_path } = cd
      const url = `${serviceUrl}${api_path}`
  
      const { data } = yield call(fetchPost, url, payload)
      if (!data.length) throw Error('No Drugs found for the input conditions')
      const { rebateConditionLevel } = payload
      yield put(RebateActions.setRebateLevelDrugData({ data, rebateConditionLevel }))
    } catch (err) {
      const headerText = 'The system is currently experiencing an issue that has been reported; logout and back in and please try again in 5 minutes.'
      yield put(AppActions.setModalComponent({
        modalProperties: {
          size: 'tiny',
        },
        contents: SystemErrorModal({
          headerText,
          onSaveClick: UserActions.userLogout,
          toggleModal: AppActions.toggleModal,
        }),
      }))
      yield put(AppActions.toggleModal())
    }
  }
  
  function* savePOSRebateDataHandler({ payload }) {
    try {
      const approvalDocument = {
        module_name: payload.doc_name,
        data: payload,
        module: 'POS_REBATE',
        status: 'DRAFT',
      }
      // eslint-disable-next-line
      const cb = function* ({ success }) {
        if (success) yield put(NavigationActions.navigateBack())
      }
      const criteria_ids = yield call(updateCriteriaIdsHandler, { criteriaIds: payload.criteria_ids, umConditions: payload.conditions })
      approvalDocument.data.criteria_ids = criteria_ids
      yield put(RPMActions.rpmUpsertApprovalDocument(approvalDocument, cb))
    } catch (err) {
      console.log('savePOSRebateDataHandler Error >Data ', err)
      const transitionalPortal = {
        header: 'Saving POS Rebate Failed',
        copy: err,
      }
      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    }
  }
  
  function* getPOSRebateDataHandler({ payload }) {
    try {
      const cb = function* cb(data) {
        const posRebate = data[0].module_data.data
        const pos_rebate = {
          rebate_conditions: posRebate.conditions,
          rebate_info: {
            effective_start_date: posRebate.effective_start_date,
            doc_name: posRebate.doc_name,
            max_no_of_fills: posRebate.max_no_of_fills,
            doc_version: posRebate.doc_version,
            hierarchy: data[0].hierarchy,
            doc_id: data[0].id,
            version: data[0].version,
            version_effective_date: posRebate.version_effective_date,
            status: data[0].status,
            is_hierarchy_global: data[0].is_hierarchy_global,
            hideHierarchy: data[0].hideHierarchy,
          },
          criteria_ids: posRebate.criteria_ids || [],
        }
        yield put(RebateActions.setPOSRebateData(pos_rebate))
      }
      yield put(RPMActions.rpmGetApprovalDocuments(payload, cb))
    } catch (err) {
      console.log('getPOSRebateDataHandler Error >Data ', err)
  
      const transitionalPortal = {
        header: 'POS Rebate Lookup Failed',
        copy: err.message,
      }
      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    }
  }
  
  // TODO: REMOVE - DEPRECATED
  // REVISE IN SWSS-1387
  function* approveRebateHandler({ payload }) {
    try {
      const { serviceUrl } = yield select(getAppState)
      const history = yield select(getHistory)
      const qs = history.location.search
      const cd = yield select(getApiPath, 'approve-pos-rebate')
      const { api_path } = cd
      const url = `${serviceUrl}${api_path}${qs}`
  
      const { message } = yield call(fetchPost, url, payload)
      yield put(RebateActions.getAllRebateData())
      const transitionalPortal = {
        header: 'POS Rebate',
        copy: `${message}. Please wait while we publish the rebate list.`,
      }
      yield put(AppActions.displayTransitionalPortal(transitionalPortal))

      const execution_time = new Date()
      execution_time.setSeconds(execution_time.getSeconds() + 60)
      const payload2 = {
        conf: { section: 'rebate', ...payload },
        dag_run_id: uuidv4(),
        execution_date: execution_time.toISOString(),
      }
      yield call(fetchPost, url.replace('/approve-pos-rebate', '/build-drug-list'), payload2)
    } catch (err) {
      console.log('approveRebateHandler Error >Data ', err)
      const transitionalPortal = {
        header: 'Approving POS Rebate Failed',
        copy: err,
      }
      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    }
  }
  