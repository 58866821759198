import React from 'react'

import './style.scss'
import FliptForm from '../../../components/form/fliptForm'
import FliptButton from '../../../components/form/fliptButton'
import FliptInput from '../../../components/form/fliptInput'

export default function EnterCode(props) {
  const { email_address, updateField, verifyCode } = props
  return (
    <div className="right">
      <div className="header-text">
        Please enter the 6 digit verification code sent to
        {' '}
        {email_address}
      </div>
      <FliptForm id="forgotPasswordForm">
        <FliptInput placeholder="Enter Code" name="code" onChange={updateField} validation="code" required />
        <FliptButton className="primary" name="Reset Password" onClick={verifyCode} />
      </FliptForm>
    </div>
  )
}
