import { call, put, select, takeLatest, take, takeEvery } from 'redux-saga/effects'
import { Creators as AppActions, Types as AppTypes } from '../../reducers/app'
import { Types, Creators as PrescriberManagementActions } from '../../reducers/api/prescriberManagement'
import { fetchPatch, fetchPost } from '../../../utils/fetchApi'
import { getAppState, getApiPath } from '../../reducers/selectors'

export default [editPrescriberWatcher, searchPrescriberWatcher]
function* searchPrescriberWatcher() {
	yield takeLatest(Types.SEARCH_PRESCRIBER, searchPrescriberHandler)
}
function* editPrescriberWatcher() {
	yield takeLatest(Types.EDIT_PRESCRIBER, editPrescriberHandler)
}

function* searchPrescriberHandler({ payload }) {
	try {
		const { serviceUrl } = yield select(getAppState)
		const cd = yield select(getApiPath, 'prescriber-search')
		const { api_path } = cd
		const url = `${serviceUrl}${api_path}`
		const response = yield call(fetchPost, url, payload, true)
		if (response.status === '200') {
			yield put(PrescriberManagementActions.setPrescriberSearchData(response.data[0]))
		}
	} catch (err) {
		console.log('searchPrescriberHandler Error >Data ', err)
		const transitionalPortal = {
			header: 'Prescriber Not Found',
			copy: err.message || err,
		}
		yield put(AppActions.displayTransitionalPortal(transitionalPortal))
	}
}
function* editPrescriberHandler({ payload }) {
	try {
		const { request_body, search_params, callback } = payload
		const { serviceUrl } = yield select(getAppState)
		const cd = yield select(getApiPath, 'prescriber-edit')
		const { api_path } = cd
		const url = `${serviceUrl}${api_path}`
		const { status, message } = yield call(fetchPatch, url, request_body, true)
		if (status === '200') {
			const transitionalPortal = {
				header: 'Prescriber Updated Successfully',
				copy: message,
			}
			yield put(AppActions.displayTransitionalPortal(transitionalPortal))
			yield put(PrescriberManagementActions.searchPrescriber(search_params))
			if (callback) {
				callback()
			}
		}
	} catch (err) {
		console.log('updatePrescriberHandler Error >Data ', err)
		const transitionalPortal = {
			header: 'Prescriber Not Updated',
			copy: err.message || err,
		}
		yield put(AppActions.displayTransitionalPortal(transitionalPortal))
	}
}
