import {
  call,
  put, select, takeLatest,
} from 'redux-saga/effects'
import { fetchPost } from '../../../utils/fetchApi'
import { Types, Creators as GetPricingActions } from '../../reducers/api/getPricing'
import { Creators as AppActions } from '../../reducers/app'
import { getApiPath, getAppState } from '../../reducers/selectors'

export default [
  getDrugPricesWatcher,
  getDrugDetailsDataWatcher,
]

/* WATCHERS */
function* getDrugPricesWatcher() {
  yield takeLatest(Types.GET_DRUG_PRICES, getDrugPricesHandler)
}

function* getDrugDetailsDataWatcher() {
  yield takeLatest(Types.GET_DRUG_DETAILS, getDrugDetailsDataHandler)
}

/* HANDLERS */
function* getDrugPricesHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const cd = yield select(getApiPath, 'drug-pricing')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`
    let dob_date = ""
    let dob_month = ""
    if (payload?.date_of_birth && payload?.date_of_birth.includes("/")) {
      const dob_array = payload.date_of_birth.split("/")
      if (dob_array[1].length == 1) {
        dob_date = "0" + dob_array[1]
      }
      else {
        dob_date = dob_array[1]
      }
      if (dob_array[0].length == 1) {
        dob_month = "0" + dob_array[0]
      }
      else {
        dob_month = dob_array[0]
      }
      const dob = `${dob_array[2]}-${dob_month}-${dob_date} 00:00:00`
      payload.date_of_birth = dob
    }
    payload.days_of_supply = parseInt(payload.days_of_supply, 10)
    _validateGetPriceArgs(payload)

    const { message, data, total_rows } = yield call(fetchPost, url, payload)

    if (total_rows < 1) {
      throw message
    }

    yield put(GetPricingActions.setDrugPrices({ data, total_rows }))
  } catch (err) {
    console.log('getDrugPricesHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Check Drug Prices Failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    yield put(GetPricingActions.clearDrugDetails())
  }
}

function* getDrugDetailsDataHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const cd = yield select(getApiPath, 'drug-details')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`
    const { data, message } = yield call(fetchPost, url, payload)
    if (data.length === 0) {
      const transitionalPortal = {
        header: 'Drug Data not covered on Formulary',
        copy: message,
      }
      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    } else {
      yield put(GetPricingActions.setDrugDetails(data[0]))
    }
  } catch (err) {
    console.log('getDrugDetailsDataHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Getting Drug Name Data Failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

export {
  getDrugPricesHandler,
  getDrugDetailsDataHandler,
}

function _validateGetPriceArgs(args) {
  const requiredFields = ['date_of_service', 'date_of_birth', 'days_of_supply', 'drug_name', 'gpi', 'plan_name', 'quantity', 'rx_flipt_person_id', 'pharmacy_within', 'primary_flipt_person_id']

  requiredFields.forEach((field) => {
    if (!args[field] || args[field] === 'NaN') {
      throw 'Invalid Parameters'
    }
  })
}
