import React from 'react'
import { Search } from 'semantic-ui-react'

export default function FliptPaSearch(props) {
    const {
        className,
        disabled,
        loading,
        minCharacters,
        name,
        value,
        label,
        stylized,
    } = props

    const inputProps = { ...props }

    const stylizedField = () => (
        <div className={`pa-stylized ${className || ''}`}>
            <div className="flipt-pa-label" hidden={inputProps.hidden || false}>
                <label htmlFor={label}>{label}</label>
            </div>
            <Search {...props} className={className} disabled={disabled} loading={loading} minCharacters={minCharacters || 3} name={name} noResultsMessage="Searching..." value={value} />
        </div>
    )

    if (stylized) return stylizedField()

    return <Search {...props} className={className} disabled={disabled} loading={loading} minCharacters={minCharacters || 3} name={name} noResultsMessage="Searching..." value={value} />
}
