import React, { useState, useEffect, useRef } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import '../styles.scss'
import rowCellInfo from '../data/rowCellInfo'
import { Creators as MedicareSetupActions } from '../../../../../../redux/reducers/api/medicareSetup'
import FliptGrid from '../../../../../../components/fliptGrid'
import FliptInput from '../../../../../../components/form/fliptInput'
import AddRow from '../../../../formularyCreationManagement/formularyTiers/createTier/addRow'
import { DeleteRowRenderer } from '../../../../../../components/fliptGrid/cellRenderers'
import { InputTextEditor, DropdownEditor } from '../../../../../../components/fliptGrid/cellEditors'
import FliptDropdown from '../../../../../../components/form/fliptDropdown'

const phases = ['Deductible', 'Initial Coverage', 'Coverage Gap', 'Catastrophic']
const panes = [...phases, 'LICS', 'Review']
const spendTypeValues = ['Drug Spend', 'TROOP']

function CostPane(props) {
  const {
    editMode,
    paneIdx,
    data,
    setData,
  } = props
  const pane = panes[paneIdx]

  const [toggleReload, setToggleReload] = useState(false)
  const [dropdownValues, setDropdownValues] = useState([{
    brand_cost_share_type: ['Percentage', 'Fixed'],
    generic_cost_share_type: ['Percentage', 'Fixed'],
    party: ['Beneficiary', 'Plan', 'Manufacturer'],
  }])

  const _constructDropdownOptions = () => dropdownValues.map((row) => _mapValueToOption(row))

  const _mapValueToOption = (row) => {
    const rowData = {
      ...row,
    }

    Object.keys(rowData).forEach((col) => {
      rowData[col] = rowData[col].map((group, index) => ({
        key: index,
        text: group,
        value: group,
      }))
    })
    return rowData
  }

  const _addRow = () => {
    const newData = data
    newData.phases.forEach((el) => {
      if (el.phase === pane) {
        el.cost_share = [
          ...el.cost_share,
          Object.keys(el.cost_share[0]).reduce((acc, header) => {
            acc[header] = header.endsWith('cost_share') ? null : ''
            return acc
          }, {}),
        ]

        const rowValues = Object.keys(dropdownValues[0]).reduce((acc, header) => {
          if (header === 'party') acc[header] = ['Beneficiary', 'Plan', 'Manufacturer']
          else acc[header] = ['Percentage', 'Fixed']
          return acc
        }, {})
        const newDropdownValues = el.cost_share.map(() => rowValues)
        if (!!newDropdownValues) setDropdownValues(newDropdownValues)
      }
    })

    setData(newData)
  }

  const _delRow = (rowIndex) => {
    const newData = data
    newData.phases.forEach((el) => {
      if (el.phase === pane && el.cost_share.length > 1) {
        el.cost_share = el.cost_share.filter((_, idx) => idx !== rowIndex)

        const rowValues = Object.keys(dropdownValues[0]).reduce((acc, header) => {
          if (header === 'party') acc[header] = ['Beneficiary', 'Plan', 'Manufacturer']
          else acc[header] = ['Percentage', 'Fixed']
          return acc
        }, {})
        const newDropdownValues = el.cost_share.map(() => rowValues)
        if (!!newDropdownValues) setDropdownValues(newDropdownValues)
      }
    })

    setData(newData)
  }

  const _handleChange = (el, dropdown, rowIndex, gridApi) => {
    const { name, value } = dropdown || el.currentTarget
    const newData = data
    newData.phases.forEach((el) => {
      if (el.phase === pane) {
        el.cost_share[rowIndex][name] = value || name.endsWith('cost_share') ? null : ''
      }
    })
    setData(newData)

    gridApi.refreshCells()
  }

  const _updateFields = (el, dropdown) => {
    const { name, value } = dropdown || el.currentTarget
    const newData = data
    newData.phases.forEach((el) => {
      if (el.phase === pane) {
        el[name] = value

        if (name === 'end_amt' && paneIdx < 3) {
          const prevEndAmt = parseFloat(value) * 100
          if (prevEndAmt > 0) {
            newData.phases[paneIdx + 1].begin_amt = parseInt(prevEndAmt + 1) / 100
          }

        }
      }
    })
    setData(newData)
    setToggleReload(!toggleReload)
  }

  const _generateSpendTypeOptions = () => spendTypeValues.map((type, idx) => ({
    key: idx,
    text: type,
    value: type,
  }))

  if (editMode) {
    rowCellInfo.action = {
      type: 'deleteRow',
      disabled: false,
    }
    Object.keys(rowCellInfo).forEach((key) => { rowCellInfo[key].disabled = false })
  } else {
    delete rowCellInfo.action
    Object.keys(rowCellInfo).forEach((key) => { rowCellInfo[key].disabled = true })
  }
  const headers = Object.keys(rowCellInfo)

  if (headers.includes('action')) {
    headers.splice(headers.indexOf('action'), 1)
    headers.unshift('action')
  }

  const cellRendererParams = {
    action: {
      cellRenderer: DeleteRowRenderer,
      state: {
        onClick: _delRow,
      },
      width: 95,
    },
  }

  const cellEditorParams = {
    party: {
      cellEditor: DropdownEditor,
      onChange: _handleChange,
    },
    brand_cost_share: {
      cellEditor: InputTextEditor,
      onChange: _handleChange,
    },
    brand_cost_share_type: {
      cellEditor: DropdownEditor,
      onChange: _handleChange,
    },
    minimum_brand_cost_share: {
      cellEditor: InputTextEditor,
      onChange: _handleChange,
    },
    maximum_brand_cost_share: {
      cellEditor: InputTextEditor,
      onChange: _handleChange,
    },
    generic_cost_share: {
      cellEditor: InputTextEditor,
      onChange: _handleChange,
    },
    generic_cost_share_type: {
      cellEditor: DropdownEditor,
      onChange: _handleChange,
    },
    minimum_generic_cost_share: {
      cellEditor: InputTextEditor,
      onChange: _handleChange,
    },
    maximum_generic_cost_share: {
      cellEditor: InputTextEditor,
      onChange: _handleChange,
    },
  }

  const [dropdownOptions, setDropdownOptions] = useState([])

  useEffect(() => {
    setDropdownOptions(_constructDropdownOptions())
  }, [dropdownValues])

  const gridProps = editMode ? {
    cellEditorParams,
    cellRendererParams,
    data: data.phases[paneIdx].cost_share,
    dropdownOptions,
    headers,
    rowCellInfo,
  } : {
    data: data.phases[paneIdx].cost_share,
    headers,
  }

  return (
    <div className="wrapper">
      <div className="cost-form">
        <section className="input-container">
          <div className="prescriber-input">
            <span className="input-label">Begin Amount</span>
            <div className='dollar-container'>
              <span className="dollar">$</span>
              <FliptInput placeholder="0.00" value={data.phases[paneIdx].begin_amt === null ? '' : data.phases[paneIdx].begin_amt} name="begin_amt" onChange={_updateFields} validation="numeric" disabled={!editMode} />
            </div>
          </div>
          <div className="prescriber-input">
            <span className="input-label">Begin Spend Type</span>
            <FliptDropdown placeholder="Select" name="begin_spend_type" options={_generateSpendTypeOptions()} value={data.phases[paneIdx].begin_spend_type} onChange={_updateFields} disabled={!editMode} />
          </div>
          {data.phases[paneIdx].phase !== 'Catastrophic' ? (
            <>
              <div className="prescriber-input">
                <span className="input-label">End Amount</span>
                <div className='dollar-container'>
                  <span className="dollar">$</span>
                  <FliptInput placeholder="0.00" value={data.phases[paneIdx].end_amt === null ? '' : data.phases[paneIdx].end_amt} name="end_amt" onChange={_updateFields} disabled={!editMode} />
                </div>
              </div>
              <div className="prescriber-input">
                <span className="input-label">End Spend Type</span>
                <FliptDropdown placeholder="Select" name="end_spend_type" options={_generateSpendTypeOptions()} value={data.phases[paneIdx].end_spend_type} onChange={_updateFields} disabled={!editMode} />
              </div>
            </>) : ('')
          }
        </section>
        {
          data?.medicare_plan_info?.medicare_d_standard === 'Y' ? <>
            <h1>Cost Share</h1>
            <section className="cost-share-grid">
              <FliptGrid {...gridProps} />
            </section>
            {editMode &&
              (<div className="addRowButtonContainer">
                <AddRow addRow={_addRow} />
              </div>)
            }
          </> : ('')
        }
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  state: {
    activePlan: state.medicareSetup.activePlan,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...MedicareSetupActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CostPane)
