import React from 'react'
import { Link } from 'react-router-dom'

export default (props) => {
    const { active, setActive } = props
    const search = ''

    return (
        <div>
            <Link
                // to={{
                //     pathname: '/user-queue',
                //     search,
                //     state: { active: 'myQueue' }
                // }}
                className={active === 'myQueue' ? 'active' : ''}
                onClick={() => setActive('myQueue')}
            >
                My Approval Queue
            </Link>
            <Link
                // to={{
                //     pathname: '/user-queue',
                //     state: { active: 'userQueue' }
                // }}
                className={active === 'userQueue' ? 'active' : ''}
                onClick={() => setActive('userQueue')}
            >
                All Approval Queue
            </Link>
            <Link
                // to={{
                //     pathname: '/user-queue',
                //     search,
                //     state: { active: 'myImplementation' }
                // }}
                className={active === 'myImplementation' ? 'active' : ''}
                onClick={() => setActive('myImplementation')}
            >
                My Implementation Queue
            </Link>
            <Link
                // to={{
                //     pathname: '/user-queue',
                //     search,
                //     state: { active: 'userImplementation' }
                // }}
                className={active === 'userImplementation' ? 'active' : ''}
                onClick={() => setActive('userImplementation')}
            >
                All Implementation Queue
            </Link>
        </div>
    )
}
