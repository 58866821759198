export default function ServerSideDatasource(form, apiId, sagaToCall, headers, headerMapping) {
  return {
    getRows: async function getRows(params) {
      setTimeout(() => callServerApi(params, form, apiId, sagaToCall, headers, headerMapping), 750)
    },
  }
}

async function callServerApi(params, form, apiId, sagaToCall, headers, headerMapping) {
  sagaToCall({
    form, params, headers, headerMapping,
  })
}
