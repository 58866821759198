import React, { useState, useEffect, useCallback } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { cloneDeep } from 'lodash'
import { Divider, Message } from 'semantic-ui-react'

import '../styles.scss'
import FliptButton from '../../../../../../components/form/fliptButton'
import FliptDropdown from '../../../../../../components/form/fliptDropdown'
import FliptInput from '../../../../../../components/form/fliptInput'
import { Creators as AppActions } from '../../../../../../redux/reducers/app'
import { Creators as FormularyCreationActions } from '../../../../../../redux/reducers/api/formularyCreationManagement'
import { buildDropdownOptions } from '../../../../../../utils/utilities'
import FliptGrid from '../../../../../../components/fliptGrid'
import rowCellInfo from '../data/rowCellInfo'
import * as ApprovalsConstants from '../../../../../../redux/sagas/rpm/constants'

function TierDrugs(props) {
  const { DRAFT, PUBLISHED, REJECTED } = ApprovalsConstants
  const [drugFilters, setDrugFilters] = useState({
    drug_class: [],
    drug_group: [],
    drug_name: [],
    drug_subclass: [],
    gpi: '',
    manufacturer: [],
    multi_source: [],
  })
  const [summaryVisible, setSummaryVisible] = useState(true)
  const [filteredSummaryVisible, setFilteredSummaryVisible] = useState(true)
  const [oldRecords, setOldRecords] = useState(0)
  const [newRecords, setNewRecords] = useState(0)

  const {
    sendForReview, doc_id, form, state, saveFormularyTier, editMode, agGridRef, defaultTierCondition,
    filterDrugData, updateFilterConditions, filterConditions, addFilterConditionsToTierConditions,
  } = props
  const { status } = form
  const { formularyTierDrugFiltersData, formularyTierDrugData } = state
  const {
    gpi, drug_group, drug_class, drug_subclass, manufacturer,
    drug_name, multi_source, total_records,
  } = formularyTierDrugFiltersData

  useEffect(() => {
    setDrugFilters({
      drug_class: [],
      drug_group: [],
      drug_name: [],
      drug_subclass: [],
      gpi: '',
      manufacturer: [],
      multi_source: [],
    })
    setSummaryVisible(true)
    setFilteredSummaryVisible(true)
    setOldRecords(total_records || 0)
    setNewRecords(total_records - oldRecords || 0)
  }, [gpi])

  const headers = [
    'include', 'gpi', 'rxcui', 'frf_category', 'medicare_type', 'drug_group', 'drug_class', 'drug_subclass', 'manufacturer', 'drug_name', 'multi_source', 'ndc', 'usp_category', 'usp_class', 'usp_pharmacotherapeutic_group',
    'ddid', 'brand_generic', 'otc_indicator', 'route_of_administration', 'desi_code', 'maintenance_drug_code', 'application_type_flag', 'repackaged_code', 'mfg_labeler_id', 'dosage_form_cd', 'third_party_restriction_code'
  ]

  function onRowSelected(e) {
    if (e?.node?.data) e.node.data['include'] = e?.node?.selected
  }

  const gridData = filterDrugData.map(x => x)
  const cellRendererParams = {
    include: {
      overrideHeader: 'Include',
      cellRenderer: () => {
        return <p />
      },
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
    },
    gpi: {
      filter: true,
    },
    medicare_type: {
      filter: true,
    },
    drug_group: {
      filter: true,
    },
    drug_class: {
      filter: true,
    },
    drug_subclass: {
      filter: true,
    },
    manufacturer: {
      filter: true,
    },
    drug_name: {
      filter: true,
    },
    multi_source: {
      filter: true,
    },
    ndc: {
      filter: true,
    },
    ddid: {
      filter: true,
    },
    brand_generic: {
      filter: true,
    },
    otc_indicator: {
      filter: true,
    },
    application_type_flag: {
      overrideHeader: 'App Type',
    },
    mfg_labeler_id: {

      overrideHeader: 'Labler Code',
    },
    repackaged_code: {

      overrideHeader: 'Repackaging Indicator',
    },
    third_party_restriction_code: {
      overrideHeader: 'TPR Code',
    },
    dosage_form_cd: {
      overrideHeader: 'Dosage Form Code',
    },
    route_of_administration: {
      filter: true,
    },
    desi_code: {
      filter: true,
    },
    maintenance_drug_code: {
      filter: true,
    },
    frf_category: {
      filter: true,
      overrideHeader: 'FRF Category',
    },
    rxcui: {
      filter: true,
      overrideHeader: 'RXCUI',
    },
    usp_category: {
      filter: true,
      overrideHeader: 'USP Category',
    },
    usp_class: {
      filter: true,
      overrideHeader: 'USP Class',
    },
    usp_pharmacotherapeutic_group: {
      filter: true,
      overrideHeader: 'USP Pharmacotherapeutic Group',
    }
  }


  let summaryData = ''
  let filteredSummaryData = ''
  if (gpi) {
    summaryData = `New Records: ${newRecords} :: Total Rows: ${total_records} :: GPI Total: ${gpi.length} :: Drug Group Total: ${drug_group.length} :: Drug Class Total: ${drug_class.length} :: Drug SubClass Total: ${drug_subclass.length} :: Manufacturer Total: ${manufacturer.length} :: Drug Name Total: ${drug_name.length} :: Multi Source Total: ${multi_source.length}`
    filteredSummaryData = `${formularyTierDrugData.length} of ${total_records} records filtered`
  }

  const handleFilterUpdate = (e, dropdown) => {
    const { name, value } = dropdown || e?.currentTarget
    setDrugFilters((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const _filterDrugData = () => {
    agGridRef.current.api.onFilterChanged()
    agGridRef.current.api.refreshCells()
  }

  const convertDrugFiltersToConditions = (agGridRef, formularyTierDrugData) => {
    if (!agGridRef?.current) return null
    let totalData = []
    agGridRef.current.api.forEachNode(node => totalData.push(node.data))
    const totalAmounts = {}
    totalData.map(row => Object.entries(row).forEach(([key, value]) => {
      const obj = totalAmounts[key]
      if (!obj) totalAmounts[key] = {}
      if (value && value.length) {
        let val = totalAmounts[key][value]
        totalAmounts[key][value] = val ? val + 1 : 1
      }
    }))

    let includedData = []
    let excludedData = []
    totalData.forEach(x => {
      if (x?.include)
        includedData.push(x)
      else
        excludedData.push(x)
    })
    const excludedAmounts = cloneDeep(totalAmounts)
    includedData.map(row => Object.entries(row).forEach(([key, value]) => {
      const obj = excludedAmounts[key]
      if (!obj) return
      if (value && value.length || row) {
        if (excludedAmounts[key][value])
          excludedAmounts[key][value] = excludedAmounts[key][value] - 1
      }
    }))

    let fieldsToExclude = {}
    Object.entries(excludedAmounts).forEach(([key1, value1]) => Object.entries(value1).forEach(([key2, value2]) => {
      const totalCount = totalAmounts[key1][key2]
      const excludedCount = value2
      if (excludedCount > 0) {
        if (totalCount === excludedCount) {
          const obj = fieldsToExclude[key1]
          if (!obj) fieldsToExclude[key1] = {}
          fieldsToExclude[key1][key2] = value2
        }
      }
    }))

    let newFilterConditions = []
    while (excludedData.length > 0) {
      let maxValue = 0
      let maxCondition = {}
      Object.entries(fieldsToExclude).forEach(([key1, value1]) => Object.entries(value1).forEach(([key2, value2]) => {
        if (value2 > maxValue) {
          maxCondition = { [key1]: key2 }
          maxValue = value2
        }
      }))
      let maxConditionField = Object.keys(maxCondition)[0]
      let maxConditionVal = Object.values(maxCondition)[0]

      Object.keys(fieldsToExclude).forEach(key1 => {
        if (excludedData.filter(x => x[maxConditionField] === maxConditionVal).every(x => x[key1] === excludedData[0][key1])) maxCondition[key1] = excludedData[0][key1]
      })
      excludedData = excludedData.filter(x => {
        if (!(!Object.entries(x).every(([key, value]) => !maxCondition[key] || maxCondition[key] === value))) {
          Object.entries(x).forEach(([key, value]) => {
            const obj = fieldsToExclude[key]
            if (!obj) return
            if (value && value.length) {
              if (fieldsToExclude[key][value])
                fieldsToExclude[key][value] = fieldsToExclude[key][value] - 1
            }
          })
          return false
        }
        return true
      })

      newFilterConditions.push(maxCondition)
    }
    newFilterConditions = newFilterConditions.map(x => ({
      ...defaultTierCondition,
      ...x,
      include: false,
    }))
    updateFilterConditions(newFilterConditions)
  }

  const isExternalFilterPresent = useCallback(() => {
    return true
  })

  const doesExternalFilterPass = useCallback(
    (node) => {
      return Object.entries(drugFilters).every(([key, value]) => {
        if (value && value.length > 0) {
          if (key === 'gpi') {
            if (value.endsWith('*')) return node.data[key].startsWith(value.replace('*', ''))
            return node.data[key] === value
          }
          return value.some(x => x === node.data[key])
        }
        return true
      })
    }
  )

  const _onComponentStateChanged = useCallback(
    (params) => {
      params.api.forEachNodeAfterFilter(node => node.setSelected(node?.data?.include))
    }
  )

  const filterConditionsHeaders = Object.keys(rowCellInfo).filter(x => x !== 'include')

  const filterConditionsGridProps = {
    data: filterConditions,
    headers: filterConditionsHeaders,
  }

  return (
    <div className="section-drugs">
      <div className="section-contents-drugs">
        {!!gpi && !!summaryVisible && (
          <div className="message">
            <Message
              size="tiny"
              onDismiss={() => setSummaryVisible(false)}
              // header="Summary Data!"
              content={summaryData}
            />
            <Divider />
          </div>
        )}
        {!!gpi && !!filteredSummaryVisible && (
          <div className="message">
            <Message
              size="tiny"
              onDismiss={() => setFilteredSummaryVisible(false)}
              content={filteredSummaryData}
            />
            <Divider />
          </div>
        )}
        {/* <Message
              size="tiny"
              onDismiss={() => setVisible(false)}
            >
              <Statistic.Group
                size="mini"
                items={statsItems}
              />
            </Message> */}
        {!!gpi && (
          <div className="filtersContainer">
            <FliptInput placeholder="GPI" value={drugFilters.gpi} name="gpi" selectOnBlur={false} compact search clearable onChange={handleFilterUpdate} />
            <FliptDropdown placeholder="Drug Group" value={drugFilters.drug_group} options={buildDropdownOptions(drug_group)} name="drug_group" selectOnBlur={false} multiple search scrolling clearable onChange={handleFilterUpdate} />
            <FliptDropdown placeholder="Drug Class" value={drugFilters.drug_class} options={buildDropdownOptions(drug_class)} name="drug_class" selectOnBlur={false} multiple search scrolling clearable onChange={handleFilterUpdate} />
            <FliptDropdown placeholder="Drug SubClass" value={drugFilters.drug_subclass} options={buildDropdownOptions(drug_subclass)} name="drug_subclass" selectOnBlur={false} multiple search scrolling clearable onChange={handleFilterUpdate} />
            <FliptDropdown placeholder="Manufacturer" value={drugFilters.manufacturer} options={buildDropdownOptions(manufacturer)} name="manufacturer" selectOnBlur={false} multiple search scrolling clearable onChange={handleFilterUpdate} />
            <FliptDropdown placeholder="Drug Name" value={drugFilters.drug_name} options={buildDropdownOptions(drug_name)} name="drug_name" selectOnBlur={false} multiple search scrolling clearable onChange={handleFilterUpdate} />
            <FliptDropdown placeholder="Multi Source" value={drugFilters.multi_source} options={buildDropdownOptions(multi_source)} name="multi_source" selectOnBlur={false} multiple search scrolling clearable onChange={handleFilterUpdate} />
            <FliptButton name="Filter" className="primary searchButton" onClick={_filterDrugData} />
          </div>
        )}
        <div className="filtersGrid">
          <FliptGrid
            data={gridData}
            headers={headers}
            cellRendererParams={cellRendererParams}
            isExternalFilterPresent={isExternalFilterPresent}
            doesExternalFilterPass={doesExternalFilterPass}
            agGridRef={agGridRef}
            rowSelection='multiple'
            onRowSelected={onRowSelected}
            onComponentStateChanged={_onComponentStateChanged}
            suppressRowClickSelection={true}
          />
        </div>
        <div className="buttonContainer">
          <FliptButton name={props.changeToDrugList ? "Generate Drug List Exclusions" : "Generate Tier Exclusions"} className="primary" onClick={() => { convertDrugFiltersToConditions(agGridRef, formularyTierDrugData) }} />
        </div>
        {filterConditions?.length > 0 && (
          <div className="filtersSection">
            <div className="filterConditionsGrid">
              <FliptGrid
                {...filterConditionsGridProps}
              />
            </div>
            <div className="buttonContainer">
              <FliptButton name={props.changeToDrugList ? "Add Drug List Exclusion Conditions" : "Add Tier Exclusion Conditions"} className="primary" onClick={addFilterConditionsToTierConditions} />
            </div>
          </div>
        )}
      </div>
      <div className="buttonContainer">
        {editMode &&
          <FliptButton
            className="primary searchButton"
            disabled={![DRAFT, PUBLISHED, REJECTED].includes(status)}
            name="Save"
            onClick={saveFormularyTier}
          />}
        {editMode && !!gridData.length && doc_id &&
          <FliptButton
            className="primary searchButton"
            disabled={status !== DRAFT}
            name="Send to Peers for Review/Approval"
            onClick={sendForReview}
          />}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  state: {
    formularyTierDrugData: state.formularyCreation.formularyTierDrugData,
    formularyTierDrugFiltersData: state.formularyCreation.formularyTierDrugFiltersData,
    history: state.app.history,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
    ...FormularyCreationActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TierDrugs)
