import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import '../styles.scss'
import LicsPane from './LicsPane'
import CostPane from './CostPane'
import FliptButton from '../../../../../../components/form/fliptButton'
import { Creators as MedicareSetupActions } from '../../../../../../redux/reducers/api/medicareSetup'

const phases = ['Deductible', 'Initial Coverage', 'Coverage Gap', 'Catastrophic']

function ReviewPane(props) {
  const {
    data,
    setActiveIdx,
    activateEditMode,
  } = props

  const _onClick = (idx) => {
    setActiveIdx(idx)
    activateEditMode()
  }

  return (
    <div className="wrapper">
      {phases.map((phase, idx) => (
        <div key={phase}>
          <div className="header-container">
            <h1 className="review-heading">{phase}</h1>
            <FliptButton
              className="primary searchButton"
              onClick={() => _onClick(idx)}
              name="EDIT"
            />
          </div>
          <CostPane
            editMode={false}
            paneIdx={idx}
            data={data}
          />
        </div>
      ))}
      <h1 className="lics-header">LICS</h1>
      <LicsPane data={data.lics_plan} />
    </div>
  )
}

const mapStateToProps = (state) => ({
  state: {
    activePlan: state.medicareSetup.activePlan,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...MedicareSetupActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReviewPane)
