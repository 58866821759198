import React from 'react'

import { Button, Icon } from 'semantic-ui-react'

export default function AddRow(props) {
  const {
    addRow,
    addRowLabel = 'Add Condition',
    level = null,
  } = props

  return (
    <Button compact size="small" onClick={() => addRow(level)} color="youtube">
      <Icon name="add" />
      {addRowLabel}
    </Button>
  )
}
