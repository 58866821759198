import React, { useState } from 'react'
import {
  Button,
  Icon,
  Modal,
  Form,
  TextArea,
} from 'semantic-ui-react'
import '../styles.scss'

const ApproveWithNotes = (props) => {
  const {
    change,
    drugName,
    oldValue,
    newValue,
    onSave,
    multipleChanges,
    ndc,
  } = props
  const [notes, setNotes] = useState('')
  const closeHandler = () => {
    onSave({ notes, ndc, newValue, change })
  }
  const header = multipleChanges?`You are Approving ${ndc.length} Changes`:`You are Approving ${change} change for ${drugName}`
  return (
    <div className="popup">
      <Modal.Description>
        <div className="modalHeader">{header}</div>
        <div className="modalDescriptionColumn">
          { !multipleChanges && (<div className="modalDescriptionContainer">
            <div className="modalChange">
              <h2>Old Value</h2>
              <div className="flexCenter bold">
                <h3>{oldValue}</h3>
              </div>
            </div>
            <div className="modalCenterChange">
              <div className="image">
                <Icon name="right arrow" />
              </div>
            </div>
            <div className="modalChange">
              <h2>New Value</h2>
              <div className="flexCenter bold">
                <h3>{newValue}</h3>
              </div>
            </div>
          </div>)}
          <div className="flexCenter">
            <Form>
              <Form.Field required error={!notes}>
                <TextArea placeholder="Notes (required)*" maxLength="250" style={{ minHeight: 100 }} onChange={(e, d) => setNotes(d.value)} value={notes} />
              </Form.Field>
            </Form>
          </div>
        </div>
      </Modal.Description>
      <Modal.Actions>
        <Button onClick={closeHandler} primary disabled={!notes}>
          Save And Close
        </Button>
      </Modal.Actions>
    </div>
  )
}
export default ApproveWithNotes
