import React from 'react'
import { Link } from 'react-router-dom'

export default (props) => {
  const { active } = props
  const search = ''

  return (
    <div>
      <Link
        to={{
          pathname: '/medicare-plan-standard',
          search,
        }}
        className={active === 'medicarePlanStandard' ? 'active' : ''}
      >
        Medicare D Standard Plans
      </Link>
      <Link
        to={{
          pathname: '/medicare-plan-custom',
          search,
        }}
        className={active === 'medicarePlanCustom' ? 'active' : ''}
      >
        Medicare D Custom Plans
      </Link>
    </div>
  )
}
