import React from 'react';
import FliptRadio from '../../../../components/form/fliptRadio';

function RadioSelection(props) {
    return (
        <section className='radio-selection-wrapper'>
            <article className="radio-header-container">
                <span className={'radio-header-label' + (props.largeLabel ? '-large' : '')}>
                    {props.label}
                </span>
            </article>
            <section className={'radio-button-container' + (props.column ? '-column' : '')}>
                {props.options.map((option, index) => {
                    return (
                        <span className='radio-selection' key={index + '-' + option.value}>
                            <FliptRadio
                                className=''
                                name={props.name}
                                value={option.value}
                                checked={props.selectedValue === option.value}
                                onChange={props.onChange}
                            />
                            <span className='radio-selection-label'>
                                {option.label}
                            </span>
                        </span>
                    )
                })}
            </section>
        </section>
    )
}

export default RadioSelection;