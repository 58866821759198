import { v4 as uuidv4 } from "uuid";
import Immutable from "seamless-immutable";
import { createActions, createReducer } from "reduxsauce";
import { validateEmailAddress, validateZipCode } from "../../../utils/validationHelpers";

export const INITIAL_STATE = Immutable({
  billingTab: {
    billingaddress1: "",
    billingaddress2: "",
    billingcity: "",
    billingstate: "",
    billingzip: "",
    invoicing_fields: "",
    billing_type: "",
    pepm_pmpm: "",
    pepm_rate: "",
    pmpm_rate: "",
    sage_line_of_business: "",
    sage_locationid: "",
    sage_payment_instructions: "",
    sage_summary_fields: "",
    sage_termname: "",
    cycle_time: "",
    cycle_start: new Date(),

    cliamCycleType: [],
    invoiceCycleType: [],
    remittanceCycleType: [],
  },
  requiredTab: {},
  companyTab: {
    address1: "",
    address2: "",
    city: "",
    companylegalname: "",
    country: "",
    parentcompany: "",
    state: "",
    zip: "",
    contact_fax: "",
    contact_no_humana_mailorder: "",
    contact_no_humana_speciality: "",
    contact_no_walmart_mail_order: "",
    contact_no_walmart_speciality: "",
    contact_phone1: "",
    contact_phone2: "",
    contactemail: "",
    contactname: "",
    contact_fax: "",
  },
  communicationTab: {},
  appWebTab: {},
  rewardTab: {},
  systemTab: {},
  otherTab: {},

  options: {
    billingTab: {
      cycleTimeList: [
        {
          key: 1,
          text: "Monthly",
          value: "Monthly",
        },
        {
          key: 2,
          text: "Weekly",
          value: "Weekly",
        },
        {
          key: 3,
          text: "Semi-Monthly",
          value: "Semi-Monthly",
        },
        {
          key: 4,
          text: "Bi Weekly",
          value: "Bi Weekly",
        },
        {
          key: 4,
          text: "Daily",
          value: "Daily",
        },
      ],
      billingTypeList: [
        {
          key: 1,
          text: "PEPM",
          value: "PEPM",
        },
        {
          key: 1,
          text: "PMPM",
          value: "PMPM",
        },
      ],
    },
  },

  validations: {
    billingTab: {
      billingaddress1: {
        required: false,
        validation: (val) => (val + "").length >= 2,
        type: "string",
        errorMessage:
          "Billing address 1 is required and must be at least 2 characters long",
      },
      billingaddress2: {
        required: false,
        validation: (val) => (val + "").length >= 2,
        type: "string",
        errorMessage:
          "Billing address 2 is required and must be at least 2 characters long",
      },
      billingcity: {
        required: false,
        validation: (val) => (val + "").length >= 2,
        type: "string",
        errorMessage:
          "Billing City  is required and must be at least 2 characters long",
      },
      billingzip: {
        required: false,
        validation: (val) => validateZipCode(val),
        type: 'string',
        errorMessage: 'Billing Zip code must be 5 digits or 9 digits',
      }
    },
    requiredTab: {
      organization_custom_name: {
        required: true,
        validation: (val) => (val + "").length >= 3,
        type: "string",
        errorMessage:
          "Organization name is required and must be at least 3 characters long",
      },
      organization_custom_id: {
        required: true,
        validation: (val) => (val + "").length >= 2,
        type: "string",
        errorMessage:
          "Organization id  is required and must be at least 2 characters long",
      },
      client_custom_name: {
        required: true,
        validation: (val) => (val + "").length >= 3,
        type: "string",
        errorMessage:
          "Client name is required and must be at least 3 characters long",
      },
      client_custom_id: {
        required: true,
        validation: (val) => (val + "").length >= 2,
        type: "string",
        errorMessage:
          "Client Id is required and must be at least 2 characters long",
      },
      carrier_custom_name: {
        required: true,
        validation: (val) => (val + "").length >= 3,
        type: "string",
        errorMessage:
          "Carrier name is required and must be at least 3 characters long",
      },
      carrier_custom_id: {
        required: true,
        validation: (val) => (val + "").length >= 2,
        type: "string",
        errorMessage:
          "Carrier Id is required and must be at least 2 characters long",
      },
      account_custom_name: {
        required: true,
        validation: (val) => (val + "").length >= 3,
        type: "string",
        errorMessage:
          "Account name is required and must be at least 3 characters long",
      },
      account_custom_id: {
        required: true,
        validation: (val) => (val + "").length >= 2,
        type: "string",
        errorMessage:
          "Account Id is required and must be at least 2 characters long",
      },
      group_custom_name: {
        required: true,
        validation: (val) => (val + "").length >= 3,
        type: "string",
        errorMessage:
          "Group name  is required and must be at least 3 characters long",
      },
      group_custom_id: {
        required: true,
        validation: (val) => (val + "").length >= 2,
        type: "string",
        errorMessage:
          "Group Id is required and must be at least 2 characters long",
      },
      address1: {
        required: true,
        validation: (val) => (val + "").length >= 2,
        type: "string",
        errorMessage:
          "Address 1 is required and must be at least 2 characters long",
      },
      address2: {
        required: false,
        validation: (val) => (val + "").length >= 2,
        type: "string",
        errorMessage:
          "Address 2 is required and must be at least 2 characters long",
      },
      city: {
        required: true,
        validation: (val) => (val + '').length >= 2,
        type: 'string',
        errorMessage: 'City is required and must be at least 2 characters long',
      },
      state: {
        required: true,
        validation: (val) => (val + '').length >= 2,
        type: 'string',
        errorMessage: 'State is required',
      },
      zip: {
        required: true,
        validation: (val) => validateZipCode(val),
        type: 'string',
        errorMessage: 'Zip code is required and must be 5 digits or 9 digits',
      },
      contact_phone1: {
        required: true,
        validation: (val) => (/^[0-9]{10,}$/g).test(val),
        type: 'string',
        errorMessage: 'Phone number is required and must be 10 digits long',
      },
      contact_phone2: {
        required: false,
        validation: (val) => (/^[0-9]{10,}$/g).test(val),
        type: 'string',
        errorMessage: 'Phone number is required and must be 10 digits long',
      },
      contactemail: {
        required: false,
        validation: (val) => validateEmailAddress(val),
        type: 'string',
        errorMessage: 'Email is required and must be a valid email address',
      },
      contactname: {
        required: true,
        validation: (val) => (val + '').length >= 2,
        type: 'string',
        errorMessage: 'Name is required and must be at least 2 characters long',
      },


    }
  },
  validationResults: {},
  defaultTabName: "REQUIRED",
  defaultButtonName: "Organization",
  pageName: 'dashboard',
  AllData: [],
  singleLevelAttribute: {},
  step: 0,
  parentId: "",
  nodeId: "",
  nodeType: 1,
  addMode: false
});
export const updateRequiredTabForm = (state = INITIAL_STATE, { payload }) => { };
export const updateBillingTabForm = (state = INITIAL_STATE, { payload }) => {
  const { billingTab } = state;
  const { name, value } = payload;
  return {
    ...state,
    billingTab: {
      ...billingTab,
      [name]: value,
    },
  };
};
export const updateCompanyTabForm = (state = INITIAL_STATE, { payload }) => {
  const { companyTab } = state;
  const { name, value } = payload;
  return {
    ...state,
    companyTab: {
      ...companyTab,
      [name]: value,
    },
  };
};

export const setTab = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  defaultTabName: payload,
});
export const setButtonName = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  defaultButtonName: payload,
});
export const setStep = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  step: payload,
});
export const setParentId = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  parentId: payload,
});
export const setNodeId = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  nodeId: payload,
});

export const setAddMode = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  addMode: payload,
});

export const updateValidationResult = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  validationResults: {
    ...state.validationResults,
    ...payload,
  },
});
export const resetValidationResult = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  validationResults: {},
});
export const setPageName = (state = INITIAL_STATE, { payload }) => {
  return {
    ...state,
    pageName: payload,
  };
};
export const setNodeType = (state = INITIAL_STATE, { payload }) => {
  return {
    ...state,
    nodeType: payload,
  };
};

export const setAllDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  AllData: payload,
})
export const setSingleLevelAttribute = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  singleLevelAttribute: payload,
})

export const updateAttributeFields = (state = INITIAL_STATE, { payload }) => {
  const { singleLevelAttribute } = state;

  const { name, value } = payload;
  const valueObj = singleLevelAttribute[name] || {}
  return {
    ...state,
    singleLevelAttribute: {
      ...singleLevelAttribute,
      [name]: {
        ...valueObj,
        value,
      },
    },
  };
};


export const { Types, Creators } = createActions({
  updateValidationResult: ["payload"],
  resetValidationResult: ["payload"],
  testValidation: ["payload"],
  updateRequiredTabForm: ["payload"],
  handleSubmit: ["payload"],
  handleSave: ['payload'],
  createSingleLevel: ['payload'],
  setTab: ["payload"],
  setButtonName: ['payload'],
  setStep: ['payload'],
  setParentId: ['payload'],
  setNodeId: ['payload'],
  updateBillingTabForm: ["payload"],
  updateCompanyTabForm: ['payload'],
  setPageName: ['payload'],
  setNodeType: ['payload'],
  getHierarchyDetail: ['payload'],
  getDefaultHierarchyList: ['payload'],
  setAllData: ['payload'],
  setSingleLevelAttribute: ['payload'],
  updateAttributeFields: ['payload'],
  setAddMode : ['payload']
});

const HANDLERS = {
  [Types.UPDATE_VALIDATION_RESULT]: updateValidationResult,
  [Types.RESET_VALIDATION_RESULT]: resetValidationResult,
  [Types.UPDATE_REQUIRED_TAB_FORM]: updateRequiredTabForm,
  [Types.UPDATE_BILLING_TAB_FORM]: updateBillingTabForm,
  [Types.UPDATE_COMPANY_TAB_FORM]: updateCompanyTabForm,
  [Types.SET_PAGE_NAME]: setPageName,
  [Types.SET_NODE_TYPE]: setNodeType,
  [Types.SET_TAB]: setTab,
  [Types.SET_BUTTON_NAME]: setButtonName,
  [Types.SET_STEP]: setStep,
  [Types.SET_PARENT_ID]: setParentId,
  [Types.SET_NODE_ID]: setNodeId,
  [Types.SET_ALL_DATA]: setAllDataReducer,
  [Types.SET_SINGLE_LEVEL_ATTRIBUTE]: setSingleLevelAttribute,
  [Types.UPDATE_ATTRIBUTE_FIELDS]: updateAttributeFields,
  [Types.SET_ADD_MODE]: setAddMode,

};

export default createReducer(INITIAL_STATE, HANDLERS);
