/* eslint-disable */
export default {
  action: {
    disabled: false,
  },
  pos_rebate_type: {
    type: 'dropdown',
    disabled: false,
    options: [
      { key: 'fixed', text: 'Fixed', value: 'fixed' },
      { key: 'wac_percentage', text: 'WAC Percentage', value: 'wac_percentage' },
      { key: 'percentage', text: 'Percentage', value: 'percentage' },
    ],
  },
  pos_rebate_factor: {
    type: 'number',
    disabled: false,
  },
  gpi: {
    type: 'input',
    disabled: false,
  },
  drug_group: {
    type: 'dropdown',
    disabled: false,
  },
  drug_class: {
    type: 'dropdown',
    disabled: false,
  },
  drug_subclass: {
    type: 'dropdown',
    disabled: false,
  },
  manufacturer: {
    type: 'search',
    disabled: false,
  },
  drug_name: {
    type: 'search',
    disabled: false,
  },
  multi_source: {
    type: 'dropdown',
    disabled: false,
    options: [],
  },
  ddid: {
    type: 'input',
    disabled: false,
  },
  brand_generic: {
    type: 'dropdown',
    disabled: false,
    options: [],
  },
  otc_indicator: {
    type: 'dropdown',
    disabled: false,
    options: [],
  },
  route_of_administration: {
    type: 'dropdown',
    disabled: false,
  },
  maintenance_drug_code: {
    type: 'dropdown',
    disabled: false,
    options: [],
  },
  application_type_flag: {
    type: 'input',
    disabled: false,
  },
  mfg_labeler_id: {
    type: 'input',
    disabled: false,
  },
  repackaged_code: {
    type: 'dropdown',
    disabled: false,
  },
  third_party_restriction_code: {
    type: 'dropdown',
    disabled: false,
  },
  dosage_form_cd: {
    type: 'dropdown',
    disabled: false,
  },
  desi_code: {
    type: 'dropdown',
    disabled: false,
  },
  member_notes: {
    type: 'input',
    disabled: false,
  },
  agent_notes: {
    type: 'input',
    disabled: false,
  },
  internal_notes: {
    type: 'input',
    disabled: false,
  },
  claim_message_code: {
    type: 'input',
    disabled: false,
  },
  claim_message_type: {
    type: 'input',
    disabled: false,
  },
  ndc: {
    type: 'input',
    disabled: false,
  },
  effective_start_date: {
    type: 'date',
    disabled: false,
  },
  effective_end_date: {
    type: 'date',
    disabled: false,
  }
}
