import React from 'react'
import { Search } from 'semantic-ui-react'
import FliptLabel from './fliptLabel'

export default function FliptSearch(props) {
  const {
    className,
    disabled,
    loading,
    minCharacters,
    name,
    value,
    label,
    stylized,
    description,
  } = props

  // const debouncedOnChangeHandler = useCallback(
  //   debounce(onSearchChange, 0),
  //   [],
  // )

  // const [searchingText, setSearchingText] = useState('Searching....')

  // const interval = setInterval(() => {
  //   console.log('inside setInterval')
  //   console.log(`${searchingText}`)
  //   if (searchingText && searchingText.includes('....')) {
  //     console.log('inside block')
  //     setSearchingText('Searching.')
  //     return
  //   }
  //   console.log('setting')
  //   setSearchingText(`${searchingText}.`)
  // }, 1000)

  // if (!loading) {
  //   console.log('calling clearinterval')
  //   clearInterval(interval)
  // }
  const inputProps = { ...props }

  const stylizedField = () => (
    <div className={`stylized ${className || ''}`}>
      <div className="flipt-label" hidden={inputProps.hidden || false}>
        <FliptLabel
          description={description}
          label={label}
        />
      </div>
      <Search {...props} className={className} disabled={disabled} loading={loading} minCharacters={minCharacters || 3} name={name} noResultsMessage="Searching..." value={value} />
    </div>
  )

  if (stylized) return stylizedField()

  return <Search {...props} className={className} disabled={disabled} loading={loading} minCharacters={minCharacters || 3} name={name} noResultsMessage="Searching..." value={value} />
}
