import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { createQueryString } from '../../../../../utils/utilities'
import FliptPopup from '../../../../../components/fliptPopup'
import '../styles.scss'

const NewDrugPopupCellRenderer = (props) => {
  const { data: { ndc }, state: { file_id } } = props
  const [visible, setVisible] = useState(false)
  const show = () => setVisible(true)
  const hide = () => setVisible(false)

  const dropDownContent = (
    <div className="menu-container">
      <Link to={{ pathname: '/drug-information', search: createQueryString({ file_id, ndc }) }}>
        <div
          role="link"
          tabIndex={0}
          className="menu-item"
        >
          View
        </div>
      </Link>
      <Link to={{ pathname: '/exclusion', search: createQueryString({ file_id, ndc }) }}>
        <div
          role="link"
          tabIndex={-1}
          className="menu-item"
        >
          Exclusion
        </div>
      </Link>
    </div>
  )
  const popupStyle = {
    boxShadow: 'none !important',
    backgroundColor: 'transparent',
    border: 0,
  }
  return (
    <FliptPopup
      basic
      id='drug-management-popup'
      eventsEnabled
      onClose={hide}
      onOpen={show}
      open={visible}
      style={popupStyle}
      trigger={<button style={{backgroundColor: 'transparent', color: 'red', fontSize: '1.5em'}} type="button" className="btn btn-primary" onClick={visible ? hide : show}> ... </button>}
    >
      {dropDownContent}
    </FliptPopup>
  )
}

export default NewDrugPopupCellRenderer
