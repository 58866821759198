export const qualifierType = [
  'Age',
  'Diagnosis',
  'Gender',
  'Lookback',
  'Maximum Supply',
  'Minimum Supply',
  'Pharmacy',
  'Prescriber',
  'Quantity',
  'Specialty',
  'State',
  'Taxonomy']

export const qualifierOperator = ['=', '!=', '>', '>=', '<', '<=']
