/* eslint-disable */
import {
  call,
  put,
  select,
  takeLatest,
  take,
} from 'redux-saga/effects'

import { Creators as AppActions, Types as AppTypes } from '../../reducers/app'
import { Creators as RPMActions, Types as RPMTypes } from '../../reducers/rpm'
import { fetchPut, fetchPost, fetchGet, fetchPatch, fileUploadPut } from '../../../utils/fetchApi'
import { getAppState, getUserState, isUserEmpty, getApiPath, isMenuEmpty } from '../../reducers/selectors'
import { Creators as UserActions, Types as UserTypes } from '../../reducers/user'
import { Creators as NavigationActions } from '../../reducers/navigation'
import * as ApprovalsConstants from './constants'

import { createQueryString } from '../../../utils/utilities'

export default [
  rpmApproveRejectDocumentWatcher,
  rpmCreateApprovalRulesWatcher,
  rpmEditDocumentHierarchyWatcher,
  rpmGetApprovalDocumentsWatcher,
  rpmGetApprovalRulesDataWatcher,
  rpmGetApprovalsStatusHistoryWatcher,
  rpmGetImpactAnalysisWatcher,
  rpmGetMf2ValDataWatcher,
  rpmGetPublishedDocumentWatcher,
  rpmLoadApprovalsQueueWatcher,
  rpmLoadApprovalsRulesWatcher,
  rpmSendForPublishWatcher,
  rpmSendForReviewWatcher,
  rpmUpdateApprovalRulesWatcher,
  rpmUpsertApprovalDocumentWatcher,
]

/* WATCHERS */
function* rpmApproveRejectDocumentWatcher() {
  yield takeLatest(RPMTypes.RPM_APPROVE_REJECT_DOCUMENT, rpmApproveRejectDocumentHandler)
}

function* rpmCreateApprovalRulesWatcher() {
  yield takeLatest(RPMTypes.RPM_CREATE_APPROVAL_RULES, rpmCreateApprovalRulesHandler)
}

function* rpmGetApprovalDocumentsWatcher() {
  yield takeLatest(RPMTypes.RPM_GET_APPROVAL_DOCUMENTS, rpmGetApprovalDocumentsHandler)
}

function* rpmGetApprovalRulesDataWatcher() {
  yield takeLatest(RPMTypes.RPM_GET_APPROVAL_RULES_DATA, rpmGetApprovalRulesDataHandler)
}

function* rpmGetApprovalsStatusHistoryWatcher() {
  yield takeLatest(RPMTypes.RPM_GET_APPROVALS_STATUS_HISTORY, rpmGetApprovalsStatusHistoryHandler)
}

function* rpmLoadApprovalsQueueWatcher() {
  yield takeLatest(RPMTypes.RPM_LOAD_APPROVALS_QUEUE, rpmLoadApprovalsQueueHandler)
}

function* rpmLoadApprovalsRulesWatcher() {
  yield takeLatest(RPMTypes.RPM_LOAD_APPROVALS_RULES, rpmLoadApprovalsRulesHandler)
}

function* rpmSendForReviewWatcher() {
  yield takeLatest(RPMTypes.RPM_SEND_FOR_REVIEW, rpmSendForReviewHandler)
}

function* rpmUpsertApprovalDocumentWatcher() {
  yield takeLatest(RPMTypes.RPM_UPSERT_APPROVAL_DOCUMENT, rpmUpsertApprovalDocumentHandler)
}

function* rpmSendForPublishWatcher() {
  yield takeLatest(RPMTypes.RPM_SEND_FOR_PUBLISH, rpmSendForPublishHandler)
}

function* rpmGetPublishedDocumentWatcher() {
  yield takeLatest(RPMTypes.RPM_GET_PUBLISHED_DOCUMENT, rpmGetPublishedDocumentHandler)
}

function* rpmUpdateApprovalRulesWatcher() {
  yield takeLatest(RPMTypes.RPM_UPDATE_APPROVAL_RULES, rpmUpdateApprovalRulesHandler)
}

function* rpmEditDocumentHierarchyWatcher() {
  yield takeLatest(RPMTypes.RPM_EDIT_DOCUMENT_HIERARCHY, rpmEditDocumentHierarchyHandler)
}

function* rpmGetImpactAnalysisWatcher() {
  yield takeLatest(RPMTypes.RPM_GET_IMPACT_ANALYSIS, rpmGetImpactAnalysisHandler)
}

function* rpmGetMf2ValDataWatcher() {
  yield takeLatest(RPMTypes.RPM_GET_MF2_VAL_DATA, rpmGetMf2ValDataHandler)
}

/* HANDLERS */
function* rpmGetApprovalRulesDataHandler({ payload }) {
  try {
    const appState = yield select(getAppState)
    let url = `${appState.apiUrl}/fast/approvals-rule-create`
    const isRuleSearch = payload?.id

    if (isRuleSearch) {
      url += `?id=${payload.id}`
    }

    const { data } = yield call(fetchGet, url)
    if (!data.length) throw Error('No Rule Found')
    yield put(RPMActions.rpmSetApprovalRulesData(data[0]))
  } catch (err) {
    const transitionalPortal = {
      header: 'Unable to get Rules',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* rpmApproveRejectDocumentHandler({ payload, callback }) {
  try {
    const { doc_id, status, note } = payload
    const invalidData = !doc_id || !status

    if (invalidData) throw 'Invalid Approval Data'

    const reqBody = {
      doc_id,
      status,
      note
    }
    const appState = yield select(getAppState)
    const response = yield call(fetchPut, `${appState.apiUrl}/fast/approvals/approve-reject-document`, reqBody)
    const transitionalPortal = {
      header: 'Document Approval Successful',
      copy: response.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    yield put(RPMActions.rpmLoadApprovalsQueue())
    if (callback) yield call(callback, { success: true, response })
  } catch (err) {
    const transitionalPortal = {
      header: 'Invalid Document',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))

  }
}

function* rpmCreateApprovalRulesHandler({ payload }) {
  payload.organization = []
  payload.client = []
  payload.carrier = []
  payload.account = []
  payload.group = []

  try {
    if (payload.name === '' || payload.document_governed === '') throw '400: Name and Document is required'

    payload.hierarchy.forEach((hie) => {
      hie.organization.forEach((org) => {
        payload.organization.push(org.name)
        org.client.forEach((cli) => {
          payload.client.push(cli.name)
          cli.carrier.forEach((car) => {
            payload.carrier.push(car.name)
            car.account.forEach((acc) => {
              payload.account.push(acc.name)
              acc.group.forEach((grp) => {
                payload.group.push(grp.name)
              })
            })
          })
        })
      })
    })

    if (!payload?.is_hierarchy_global && (payload.client.length === 0 || payload.carrier.length === 0 || payload.account.length === 0 || payload.group.length === 0)) {
      throw '400: Client and CAG required'
    }
    const appState = yield select(getAppState)
    yield call(fetchPut, `${appState.apiUrl}/fast/approvals-rule-create`, payload)

    const transitionalPortal = {
      header: 'Rules Sucessfully Created',
    }

    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    const pathname = '/approvals-rule'
    yield put(NavigationActions.navigateTo({ pathname }))
  } catch (err) {
    const transitionalPortal = {
      header: 'Rules Creation Failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* rpmUpdateApprovalRulesHandler({ payload }) {
  try {

    const appState = yield select(getAppState)
    yield call(fetchPatch, `${appState.apiUrl}/fast/approvals-rule-create`, payload)

    const transitionalPortal = {
      header: 'Rules Sucessfully Updated',
    }

    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    const pathname = '/approvals-rule'
    yield put(NavigationActions.navigateTo({ pathname }))
  } catch (err) {
    const transitionalPortal = {
      header: 'Rules Update Failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}


function* rpmSendForReviewHandler({ payload, callback }) {
  try {
    const appState = yield select(getAppState)
    const response = yield call(fetchPut, `${appState.apiUrl}/fast/approvals/send-for-review`, payload)
    const transitionalPortal = {
      header: `Document successfully sent for review `,
      copy: response.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    if (callback) yield call(callback, { success: true, response })
  } catch (err) {
    const transitionalPortal = {
      header: 'Invalid Document',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* rpmLoadApprovalsQueueHandler() {
  try {
    const body = {
      status: ApprovalsConstants.APPROVAL_QUEUE_STATUSES,
      getUserData: true,
    }
    yield put(RPMActions.rpmGetApprovalDocuments(body))
  } catch (err) {
    const transitionalPortal = {
      header: 'Unable to fetch Approvals Queue',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* rpmLoadApprovalsRulesHandler() {
  try {
    const appState = yield select(getAppState)
    const { data } = yield call(fetchGet, `${appState.apiUrl}/fast/approvals-rule`)
    yield put(RPMActions.rpmSetApprovalRules(data))
  } catch (err) {
    const transitionalPortal = {
      header: 'Invalid User',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* rpmSendForPublishHandler({ payload, callback }) {
  try {
    const appState = yield select(getAppState)
    const response = yield call(fetchPut, `${appState.apiUrl}/fast/approvals/publish-ready`, payload)
    const transitionalPortal = {
      header: `Document successfully sent for publishing `,
      copy: response.message,
    }

    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    if (callback) {
      callback()
    } else {
      yield put(RPMActions.rpmLoadApprovalsQueue())
    }
  } catch (err) {
    const transitionalPortal = {
      header: 'Invalid Document',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* rpmGetApprovalDocumentsHandler({ payload, callback }) {
  try {
    const appState = yield select(getAppState)
    const userEmpty = yield select(isUserEmpty)
    if (userEmpty) {
      yield take([UserTypes.USER_UPDATE])
    }
    const userState = yield select(getUserState)
    const { hierarchyAccess = [] } = userState
    const { data } = yield call(fetchPost, `${appState.apiUrl}/fast/approvals`, { ...payload, cag: payload?.cag ?? hierarchyAccess })
    if (callback) {
      yield call(callback, data)
    } else {
      yield put(RPMActions.rpmSetApprovalDocuments(data))
    }
  } catch (err) {
    const transitionalPortal = {
      header: 'Invalid User',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* rpmGetMf2ValDataHandler() {
  try {
    const empty = yield select(isMenuEmpty)
    if (empty) {
      yield take([AppTypes.SET_APP_SETTINGS])
    }
    const appState = yield select(getAppState)
    const { api_path } = yield select(getApiPath, 'rpm-get-mf2val')
    const url = `${appState.apiUrl}/fast${api_path}`
    const { data } = yield call(fetchGet, url)
    if (data.length) {
      const mf2Val = data[0]
      const conditionsDropDownOptions = {}
      const mf2ValMapping = {
        'multiSource': 'MF2VAL-H072',
        'brandGenerics': 'MF2VAL-F073',
        'otcIndicator': 'MF2VAL-H022',
        'DESI': 'MF2VAL-H021',
        'routeOfAdministration': 'MF2VAL-F037',
        'drugIndicator': 'MF2VAL-F108',
        'dosageForm': 'MF2VAL-F039',
        'repackagerIndicator': 'MF2VAL-H053',
        'thirdPartyRestriction': 'MF2VAL-H055',
        "deaClassCode": 'MF2VAL-H020',
        "storageConditionCode": "MF2VAL-H084",
        "applicationType": "NABVAL-J041",
        "marketingCategory": "NABVAL-J091",
      }
      for (let [key, value] of Object.entries(mf2ValMapping)) {
        conditionsDropDownOptions[`${key}Options`] = mf2Val[value].map((obj, index) => ({ ...obj, key: index }))
      }
      yield put(RPMActions.rpmSetMf2ValData({
        mf2Val: data,
        conditionsDropDownOptions: conditionsDropDownOptions
      }))
    }
  } catch (err) {
    console.log('getMf2ValDataHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Error occurred while fetching dropdown options',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* rpmUpsertApprovalDocumentHandler({ payload, callback, silentOnSuccess = false, isFormData = false }) {
  try {
    const { status, module, data, module_name } = payload
    const invalidData = !status || !module || !data

    if (invalidData) throw 'Invalid Approval Data'

    const modalMsg = data.doc_id ? 'Document Edit' : 'Document Creation'
    const reqBody = {
      doc_id: data.doc_id,
      module,
      module_data: data,
      module_name,
      status,
    }
    delete data.doc_id
    const appState = yield select(getAppState)
    let response = {}

    if (isFormData) {
      data.doc_name = module_name
      const file = new File([JSON.stringify(data)], "formulary_tier_data.json", { type: 'multipart/form-data' })
      const formData = new FormData()
      formData.append('doc_id', reqBody.doc_id || '')
      formData.append('module', module)
      formData.append('module_data', file, 'formulary_tier_data.json')
      formData.append('module_name', module_name)
      formData.append('status', status)
      response = yield call(fileUploadPut, `${appState.apiUrl}/fast/approvals/upload-approvals`, formData)
    } else {
      response = yield call(fetchPut, `${appState.apiUrl}/fast/approvals`, reqBody)
    }

    const transitionalPortal = {
      header: `${modalMsg} Successful`,
      copy: response.message,
    }

    if (callback) {
      yield call(callback, { success: true, response })
    }
    if (!silentOnSuccess)
      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  } catch (err) {
    const transitionalPortal = {
      header: `Document Update Failure`,
      copy: err,
    }
    if (callback) {
      yield call(callback, { success: false })
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

// function* rpmUpsertFormApprovalDocumentHandler({ payload, callback, silentOnSuccess = false }) {
//   try {
//     const { status, module, data, module_name } = payload
//     const invalidData = !status || !module || !data

//     if (invalidData) throw 'Invalid Approval Data'

//     const modalMsg = data.doc_id ? 'Document Edit' : 'Document Creation'
//     const reqBody = {
//       doc_id: data.doc_id,
//       module,
//       module_data: data,
//       module_name,
//       status,
//     }
//     delete data.doc_id
//     const appState = yield select(getAppState)
//     const formData = new FormData()
//     formData.append('doc_data', btoa(JSON.stringify(reqBody)))
//     const response = yield call(fileUploadPut, `${appState.apiUrl}/fast/approvals`, formData)
//     const transitionalPortal = {
//       header: `${modalMsg} Successful`,
//       copy: response.message,
//     }

//     if (callback) {
//       yield call(callback, { success: true, response })
//     }
//     if (!silentOnSuccess)
//       yield put(AppActions.displayTransitionalPortal(transitionalPortal))
//   } catch (err) {
//     const transitionalPortal = {
//       header: `Document Update Failure`,
//       copy: err,
//     }
//     if (callback) {
//       yield call(callback, { success: false })
//     }
//     yield put(AppActions.displayTransitionalPortal(transitionalPortal))
//   }
// }

function* rpmGetPublishedDocumentHandler({ payload, callback }) {
  try {
    const appState = yield select(getAppState)
    const response = yield call(fetchPost, `${appState.apiUrl}/fast/approvals/get-published-document`, payload)

    if (callback) {
      yield call(callback, response)
    }
  } catch (err) {
    const transitionalPortal = {
      header: `Document Update Failure`,
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* rpmEditDocumentHierarchyHandler({ payload, callback }) {
  try {
    const appState = yield select(getAppState)
    const response = yield call(fetchPatch, `${appState.apiUrl}/fast/approvals/edit-hierarchy`, payload)

    if (payload?.object_type === 'BENEFIT_PLAN_DOC') {
      const { api_path } = yield select(getApiPath, 'update-benefit-plan-by-approval-doc-id-2')
      const { serviceUrl } = appState
      const url = `${serviceUrl}${api_path}`
      const { carrier, account, group, is_hierarchy_global } = payload
      let hierarchy = null
      if (response?.data && response?.data?.length > 0 && response?.data[0]?.length > 0) {
        hierarchy = response?.data[0][0]?.id
      }

      yield call(fetchPatch, url, { fields: { carrier, account, group, is_hierarchy_global, hierarchy }, approval_doc_ids: payload?.object_id })
    }

    const transitionalPortal = {
      header: 'Document Hierarchy Update Successful',
      copy: response.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))

    if (callback) {
      yield call(callback, response)
    }
  } catch (err) {
    const transitionalPortal = {
      header: `Document Hierarchy Update Failure`,
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* rpmGetApprovalsStatusHistoryHandler({ payload, callback }) {
  try {
    const appState = yield select(getAppState)
    const { doc_id, version } = payload
    const qs = createQueryString({ doc_id, version })
    const { data } = yield call(fetchGet, `${appState.apiUrl}/fast/approvals/status-history${qs}`)
    if (data?.length) {
      yield put(RPMActions.rpmSetApprovalsStatusHistory(data))
    }
  } catch (err) {
    const transitionalPortal = {
      header: 'Error ocurred when getting status history',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* rpmGetImpactAnalysisHandler({ payload }) {
  try {
    const appState = yield select(getAppState)
    const { api_path } = yield select(getApiPath, 'rpm-impact-analysis')
    const url = `${appState.apiUrl}/fast${api_path}`
    const { data } = yield call(fetchPost, url, payload, true)
    if (data?.length) {
      yield put(RPMActions.rpmSetImpactAnalysis(data))
    }
  } catch (err) {
    const transitionalPortal = {
      header: 'Error ocurred when getting impact analysis data',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

// FOR TESTING
export {
  rpmApproveRejectDocumentHandler,
}
