export const cycleTypeOptions = [
    {
        key: 0,
        text: 'Monthly',
        value: 'monthly'
    },
    {
        key: 1,
        text: 'Weekly',
        value: 'weekly'
    },
    {
        key: 2,
        text: 'Semi-Monthly',
        value: 'semi_monthly'
    },
    {
        key: 3,
        text: 'Bi-Weekly (14 days)',
        value: 'bi_weekly'
    },
    {
        key: 4,
        text: 'Daily',
        value: 'daily'
    }
]

export const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']