import React from "react";

const QualifierName = (props) => {
	const { value } = props;
	const handleQualifierSelection = (e) => {
		if (!e.target.value) return;
		props.updateRow('field', props.rowIndex, { field: e.target.value });
		props.onChange(e.target.value);
	}

	return (
		<select
			className="criteria-value-dropdown"
			value={value}
			defaultValue={value}
			onChange={handleQualifierSelection}>
			<option selected disabled>Select...</option>
			{
				props.models?.map?.(({ title, model, properties }, key) => (
					<optgroup className="" label={(title || model)} key={key}>
						{
							properties.map(({ name, altName }) => {
								return (
									<option className="" value={model + "." + name} key={model + "." + name + '-' + key}>
										{(title || model) + " " + (altName || name)}
									</option>
								);
							}
							)
						}
					</optgroup>
				))
			}
		</select>);
}

export default QualifierName;
