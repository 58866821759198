import Immutable from 'seamless-immutable'
import { createActions, createReducer } from 'reduxsauce'

export const INITIAL_STATE = Immutable({
    contractSummaryData: [],
    contractData: {},
    selectedTab: '',
    processorData: [],
    processorUpdateData: []
})

export const setSelectedTabReducer = (state = INITIAL_STATE, { payload }) => {
    return ({
        ...state,
        selectedTab: payload
    })
}

export const setProcessorDataReducer = (state = INITIAL_STATE, { payload }) => {
    return ({
        ...state,
        processorData: payload
    })
}

export const saveProcessorUpdateDataReducer = (state = INITIAL_STATE, { payload }) => {
    return ({
        ...state,
        processorUpdateData: payload
    })
}



export const setContractSummaryReducer = (state = INITIAL_STATE, { payload }) => ({
    ...state,
    contractSummaryData: payload.data
})


export const setContractReducer = (state = INITIAL_STATE, { payload }) => ({
    ...state,
    contractData: payload.data
})

export const clearContractData = (state = INITIAL_STATE, { payload }) => ({
    ...state,
    contractData: {}
})

export const clearReducer = () => INITIAL_STATE

export const { Types, Creators } = createActions({
    setContractSummaryData: ['payload'],
    getContractSummaryData: ['payload'],
    getProcessorData: ['payload'],
    setProcessorData: ['payload'],
    setContractData: ['payload'],
    getContractData: ['payload'],
    putContractData: ['payload'],
    createNewContractVersion: ['payload'],
    publishDoc: ['payload'],
    createProcessor: ['payload'],
    updateProcessor: ['payload'],
    clearContractData: null,
    clearReducer: null,
    setSelectedTab: ['payload'],
    saveProcessorUpdateData: ['payload'],
})

const HANDLERS = {
    [Types.SET_CONTRACT_SUMMARY_DATA]: setContractSummaryReducer,
    [Types.SET_CONTRACT_DATA]: setContractReducer,
    [Types.CLEAR_CONTRACT_DATA]: clearContractData,
    [Types.CLEAR_REDUCER]: clearReducer,
    [Types.SET_SELECTED_TAB]: setSelectedTabReducer,
    [Types.SET_PROCESSOR_DATA]: setProcessorDataReducer,
    [Types.SAVE_PROCESSOR_UPDATE_DATA]: saveProcessorUpdateDataReducer
}

export default createReducer(INITIAL_STATE, HANDLERS)