import React, { forwardRef } from 'react'
import { getVisibilityFunction } from '../../utils/utilities'
import './styles.scss'

const FliptDiv = forwardRef((props = {}, ref) => {
  const inputProps = { ...props }

	const { visibilityTable, visibilityGroup } = inputProps

  let { visibility=true, getVisibility=getVisibilityFunction } = props

  if (visibility || visibility === undefined) {
    visibility = getVisibility(visibilityTable, visibilityGroup)
    if (visibility === undefined) visibility = true
  }

  if (inputProps.disabled) {
    inputProps.value = inputProps.value || ''
  }

  return (
    visibility ? <div {...inputProps} ref={ref} >{inputProps?.value}</div> : <div style={{visibility:'hidden'}}/>
  )
})

export default FliptDiv
