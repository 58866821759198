import React, { useEffect } from 'react'
import './styles.scss'
import FliptButton from '../../../../components/form/fliptButton'
import FliptCheckbox from '../../../../components/form/fliptCheckbox'
import { BATCH_APPROVE, BATCH_REJECT } from '../data/constant'
import { convertSnakeCaseToString, capitalizeStr } from '../../../../utils/utilities'

function ApproveRejectModal(props) {
	const { test_batch_details, status, changeBatchStatus } = props
	const getObjectsBasedOnType = () => {
		const {
			batch_type,
			prior_authorization,
			step_therapy,
			programs,
			formulary_tier,
			quantity_limit,
			pharmacy_network,
			pharmacy_network_tiers,
			pharmacy_network_edits,
		} = test_batch_details
		let {
			benefit_plan
		} = test_batch_details
		let objects = {}
		benefit_plan.map((plan) => {
			plan.doc_name = plan.plan_name
			plan.doc_id = plan.plan_id
		})

		switch (batch_type) {
			case 'benefit_plan':
				objects = {
					benefit_plan,
				}
				break
			case 'pharmacy_network':
				objects = {
					pharmacy_network,
					pharmacy_network_tiers,
					pharmacy_network_edits,
				}
				break
			case 'um':
				objects = {
					formulary_tier,
					prior_authorization,
					step_therapy,
					programs,
					quantity_limit,
					benefit_plan
				}
				break
			default:
				break
		}
		Object.keys(objects).forEach((key) => {
			objects[key] = objects[key].map((item) => ({
				...item,
				selected: true,
			}))
		})

		return objects
	}
	const [data, setData] = React.useState(getObjectsBasedOnType())
	const _onSelectionChanged = (docId) => {
		const updatedData = { ...data }

		Object.keys(updatedData).forEach(key => {
			updatedData[key] = updatedData[key].map(item => {
				if (item.doc_id === docId) {
					return { ...item, selected: !item.selected }
				}
				return item
			})
		})
		setData(updatedData)
	}
	const _create_um_checkboxes = () => {
		return Object.keys(data).map((key) => {
			if (data[key].length == 0) return null
			return (
				<div className='checkboxes'>
					<span className="checkbox-label">{capitalizeStr(convertSnakeCaseToString(key))}</span>
					{data[key].length ? (
						<section className="checkbox-section">
							{data[key].map((object) => (
								<div>
									<FliptCheckbox label={object.doc_name} checked={object.selected} onClick={() => _onSelectionChanged(object.doc_id)} />
									<span className="imp">{object.doc_name}</span>
								</div>
							))}
						</section>
					) : null}
				</div>
			)
		})
	}
	const _onSubmit = () => {
		let selectedObjects = {}
		const { batch_id, batch_name, batch_type } = test_batch_details

		Object.keys(data).forEach(key => {
			const selectedInCategory = data[key]
				.filter(item => item.selected)
				.map(item => item)

			selectedObjects[key] = selectedInCategory
		})
		const submitted_test_batch_details = {
			batch_id,
			batch_name,
			batch_type,
			status: status,
			...selectedObjects,
		}
		changeBatchStatus(submitted_test_batch_details, status)
	}
	return (
		<div id="claims-processing-reprocessing-objects-modal">
			{_create_um_checkboxes()}
			<div className="modal-buttons">
				<FliptButton
					name={`${status == BATCH_APPROVE ? 'Approve' : 'Reject'}`}
					className={`${status == BATCH_APPROVE ? 'green-button' : 'red-button'}`}
					onClick={() => _onSubmit()}
				/>
			</div>
		</div>
	)
}

export default ApproveRejectModal
