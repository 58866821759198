export const drugGroupValues = [
  'NASAL AGENTS - SYSTEMIC AND TOPICAL',
  'DERMATOLOGICALS',
  'MEDICAL DEVICES AND SUPPLIES',
  'DIETARY PRODUCTS/DIETARY MANAGEMENT PRODUCTS',
  'OPHTHALMIC AGENTS',
  'ANALGESICS - NonNarcotic',
  'VAGINAL AND RELATED PRODUCTS',
  'PHARMACEUTICAL ADJUVANTS',
  'ANTIHYPERTENSIVES',
  'CHEMICALS',
  'HYPNOTICS/SEDATIVES/SLEEP DISORDER AGENTS',
  'OTIC AGENTS',
  'DIURETICS',
  'COUGH/COLD/ALLERGY',
  'ANTIDIARRHEAL/PROBIOTIC AGENTS',
  'MULTIVITAMINS',
  'GASTROINTESTINAL AGENTS - MISC.',
  'ANTINEOPLASTICS AND ADJUNCTIVE THERAPIES',
  'HEMATOLOGICAL AGENTS - MISC.',
  'ALLERGENIC EXTRACTS/BIOLOGICALS MISC',
  'ADHD/ANTI-NARCOLEPSY/ANTI-OBESITY/ANOREXIANTS',
  'VASOPRESSORS',
  'ANALGESICS - ANTI-INFLAMMATORY',
  'DIAGNOSTIC PRODUCTS',
  'ANTIASTHMATIC AND BRONCHODILATOR AGENTS',
  'ALTERNATIVE MEDICINES',
  'ANTACIDS',
  'ANTIHISTAMINES',
  'GOUT AGENTS',
  'ANTIANXIETY AGENTS',
  'ANTIPARKINSON AND RELATED THERAPY AGENTS',
  'HEMOSTATICS',
  'GENERAL ANESTHETICS',
  'NUTRIENTS',
  'GENITOURINARY AGENTS - MISCELLANEOUS',
  'ANTIDEPRESSANTS',
  'MISCELLANEOUS THERAPEUTIC CLASSES',
  'PENICILLINS',
  'MOUTH/THROAT/DENTAL AGENTS',
  'ANTIFUNGALS',
  'NEUROMUSCULAR AGENTS',
  'PSYCHOTHERAPEUTIC AND NEUROLOGICAL AGENTS - MISC.',
  'ANTICOAGULANTS',
  'ANORECTAL AND RELATED PRODUCTS',
  'THYROID AGENTS',
  'VITAMINS',
  'BETA BLOCKERS',
  'ULCER DRUGS/ANTISPASMODICS/ANTICHOLINERGICS',
  'PROGESTINS',
  'ANTI-INFECTIVE AGENTS - MISC.',
  'MINERALS & ELECTROLYTES',
  'ANTIDIABETICS',
  'VACCINES',
  'MUSCULOSKELETAL THERAPY AGENTS',
  'ANTIDOTES AND SPECIFIC ANTAGONISTS',
  'ANTISEPTICS & DISINFECTANTS',
  'URINARY ANTISPASMODICS',
  'MACROLIDES',
  'ANTHELMINTICS',
  'LAXATIVES',
  'ANTIEMETICS',
  'ANTIARRHYTHMICS',
  'LOCAL ANESTHETICS-Parenteral',
  'ANALGESICS - OPIOID',
  'MIGRAINE PRODUCTS',
  'CALCIUM CHANNEL BLOCKERS',
  'ANTICONVULSANTS',
  'ENDOCRINE AND METABOLIC AGENTS - MISC.',
  'CEPHALOSPORINS',
  'ANTIMALARIALS',
  'ANTIPSYCHOTICS/ANTIMANIC AGENTS',
  'HEMATOPOIETIC AGENTS',
  'FLUOROQUINOLONES',
  'ANTIHYPERLIPIDEMICS',
  'CORTICOSTEROIDS',
  'ANTIVIRALS',
  'ANDROGENS-ANABOLIC',
  'ESTROGENS',
  'CONTRACEPTIVES',
  'DIGESTIVE AIDS',
  'CARDIOTONICS',
  'ANTIANGINAL AGENTS',
  'TOXOIDS',
  'TETRACYCLINES',
  'ANTIMYASTHENIC/CHOLINERGIC AGENTS',
  'AMINOGLYCOSIDES',
  'OXYTOCICS',
  'URINARY ANTI-INFECTIVES',
  'ANTIMYCOBACTERIAL AGENTS',
  'CARDIOVASCULAR AGENTS - MISC.',
  'SULFONAMIDES',
  'AMEBICIDES',
  'RESPIRATORY AGENTS - MISC.',
  'ANORECTAL AGENTS',
  'PASSIVE IMMUNIZING AND TREATMENT AGENTS',
]

export const customProgramTypeCodes = {
  alternatives: {
    text: 'Alternatives',
    value: 'ALTERNATIVES',
  },
  rra_drug_list: {
    text: 'RRA Drug List',
    value: 'RRA DRUG LIST',
  },
  manufacturer_copay_exception: {
    text: 'Manufacturer Copay Exception',
    value: 'MANUFACTURER COPAY EXCEPTION',
  },
  specialty: {
    text: 'Specialty',
    value: 'SPECIALTY',
  },
  reference_based_pricing_list: {
    text: 'Reference Based Pricing List',
    value: 'REFERENCE BASED PRICING LIST',
  },
  opioid_program: {
    text: 'Opioid Program',
    value: 'OPIOID PROGRAM',
  },
  plan_benefit_max: {
    text: 'Plan Benefit Max',
    value: 'plan_benefit_max',
  },
}

export const frf_options = {
  '': {
    text: '',
    value: '',
  },
  'FRF': {
    text: 'FRF',
    value: 'frf',
  },
  'EXLUSION': {
    text: 'EXLUSION',
    value: 'exclusion',
  },
  'OTC_COVERAGE': {
    text: 'OTC COVERAGE',
    value: 'otc_coverage',
  }
}
