import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import _ from 'lodash'
import './styles.scss'
import { Creators as AppActions } from '../../../../../redux/reducers/app'
import { Creators as ClaimsPRPActions } from '../../../../../redux/reducers/api/claimsProcessingAndReprocessing'
import FliptGrid from '../../../../../components/fliptGrid'
import ClaimGrid from '../../components/claimGrid'
import FliptButton from '../../../../../components/v2/fliptButton'
import ClaimForm from '../../components/claimForm'

function BenefitTestingBed(props) {
	const { state, actions, claimForm, setClaimForm, editable, testBatchConfig } = props
	const [testBedSelection, setTestBedSelection] = useState([])
	const [newTestBedData, setNewTestBedData] = useState({})
	const [isModalOpen, setIsModalOpen] = useState(false)

	const testBedGridHeaders = [
		'Select',
		'prescriber_id',
		'pharmacy_id',
		'ndc',
		'drug_name',
		'days_supply',
		'drug_quantity',
		'claim_type',
		'date_of_service',
		'rx_number',
		'ingredient_cost_submitted',
		'quantity_dispensed',
		'usual_and_customary',
		'gross_amount_due',
		'basis_of_cost_determination',
		'fill_number',
		'compound_code',
		'daw_code',
		'date_written',
		'incentive_amount_submitted',
		'flat_sales_tax_amount',
		'percentage_sales_tax_amount',
		'percentage_sales_tax_rate',
		'scc',
		'active',
	]
	const testBedCellRendererParams = {
		Select: {
			checkboxSelection: true,
			width: '120px',
			filter: false,
		}
	}
	const testBedSelectionChange = (newData) => {
		const selectedRows = newData.api.getSelectedRows().map((row) => ({ ...row, selected: true }))
		setTestBedSelection(selectedRows)
	}


	const createClaimsFromTestBed = () => {
		let tempGridData = []
		testBedSelection.forEach((row) => {
			row["member_id"] = "MEMBER_ID_1"
			row["prescription_reference_number"] = Math.floor(Math.random() * (999 - 1 + 1)) + 1
			row["date_of_service"] = new Date()
			row["date_prescription_written"] = new Date()
			row["prescriberData"] = {
				"first_name": row["prescriber_first_name"] || " ",
				"last_name": row["prescriber_last_name"] || " ",
				"phone_number": row["prescriber_phone"] || "9999999999",
				"npi": row["prescriber_id"]
			}
			row["type"] = "test_batch_claim"
			tempGridData.push(row)
		})
		setClaimForm({ ...claimForm, gridData: tempGridData })
	}

	const createNewTestBed = () => {
		setIsModalOpen(true)
	}

	const onFormChange = (e) => {
		setNewTestBedData(e)
	}

	useEffect(() => {
		actions.getTestBeds()
	}, [])

	const ClaimFormModal = ({ isOpen, onClose }) => {
		if (!isOpen) return null;

		return (
			<div className="modal">
				<div className="modal-content">
					<span className="close" onClick={onClose}>&times;</span>
					<ClaimForm
						editable={editable}
						testBatchConfig={testBatchConfig}
						claimData={newTestBedData}
						onFormChange={onFormChange}
					/>
					<FliptButton name="Add Claim" className="primary" onClick={() => addNewTestBed()} />
				</div>
			</div>
		)
	}

	const addNewTestBed = () => {
		let payload = {
			active: true,
		}
		if (newTestBedData["prescriberData"]) {
			payload["prescriber_id"] = newTestBedData["prescriberData"].npi
		}
		if (newTestBedData["pharmacyData"]) {
			payload["pharmacy_id"] = newTestBedData["pharmacyData"].pharmacynpi
		}
		if (newTestBedData["drugData"]) {
			payload["ndc"] = newTestBedData["drugData"].ndc
			payload["drug_name"] = newTestBedData["drugData"].drug_name
		}
		payload = {
			...payload,
			days_supply: newTestBedData.days_supply,
			drug_quantity: newTestBedData.quantity_dispensed,
			claim_type: newTestBedData.claim_type,
			date_of_service: newTestBedData.date_of_service,
			rx_number: newTestBedData.prescription_reference_number,
			ingredient_cost_submitted: newTestBedData.ingredient_cost_submitted,
			quantity_dispensed: newTestBedData.quantity_dispensed,
			usual_and_customary: newTestBedData.usual_and_customary_charge,
			gross_amount_due: newTestBedData.gross_amount_due,
			basis_of_cost_determination: newTestBedData.basis_of_cost_determination,
			fill_number: newTestBedData.fill_number,
			compound_code: newTestBedData.compound_code,
			daw_code: newTestBedData.daw_code,
			date_written: newTestBedData.date_prescription_written,
			incentive_amount_submitted: newTestBedData.incentive_amount_submitted || "",
			flat_sales_tax_amount: newTestBedData.flat_sales_tax_amount || "",
			percentage_sales_tax_amount: newTestBedData.percentage_sales_tax_amount || "",
			percentage_sales_tax_rate: newTestBedData.percentage_sales_tax_rate || "",
			scc: newTestBedData.scc || "",
		}
		actions.addTestBed(payload)
	}


	return (
		<div id="BenefitTestingBed">
			<h2>Create Claim With Testing Bed</h2>
			<div className="test-beds">
				<div className="create-new-test-bed">
					<FliptButton name="Create Net New Claim With Test Bed" disabled={true} className="primary" onClick={createNewTestBed} />
				</div>
				<div className="test-bed-grid">
					<FliptGrid
						headers={testBedGridHeaders}
						data={state.testBeds}
						rowSelection="multiple"
						onSelectionChanged={(newData) => testBedSelectionChange(newData)}
						cellRendererParams={testBedCellRendererParams}
					// suppressRowClickSelection={isDisabled}
					/>
				</div>
			</div>
			<div className="claim-grid">
				<FliptButton name="Create Claims From Test Bed" className="primary" onClick={createClaimsFromTestBed} />
				<ClaimGrid
					editable={editable}
					claimForm={claimForm}
					testBatchConfig={testBatchConfig}
					setClaimForm={setClaimForm}
				/>
			</div>
			<ClaimFormModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
		</div>
	)
}

const mapStateToProps = (state) => ({
	state: {
		user: state.user,
		testBeds: state.claimsProcessingAndReprocessing.testBeds,
	},
})

const mapDispatchToProps = (dispatch) => {
	const allActions = {
		...AppActions,
		...ClaimsPRPActions,
	}

	return {
		actions: bindActionCreators(allActions, dispatch),
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(BenefitTestingBed)
