const usStates = [
  {
    key: 1,
    text: "American Samoa",
    value: "American Samoa"
  },
  {
    key: 2,
    text: "Alabama",
    value: "Alabama"
  },
  {
    key: 3,
    text: "Alaska",
    value: "Alaska"
  },
  {
    key: 4,
    text: "Arizona",
    value: "Arizona"
  },
  {
    key: 5,
    text: "Arkansas",
    value: "Arkansas"
  },
  {
    key: 6,
    text: "California",
    value: "California"
  },
  {
    key: 7,
    text: "Colorado",
    value: "Colorado"
  },
  {
    key: 8,
    text: "Connecticut",
    value: "Connecticut"
  },
  {
    key: 9,
    text: "Delaware",
    value: "Delaware"
  },
  {
    key: 10,
    text: "Florida",
    value: "Florida"
  },
  {
    key: 11,
    text: "Georgia",
    value: "Georgia"
  },
  {
    key: 12,
    text: "Guam",
    value: "Guam"
  },
  {
    key: 13,
    text: "Hawaii",
    value: "Hawaii"
  },
  {
    key: 14,
    text: "Idaho",
    value: "Idaho"
  },
  {
    key: 15,
    text: "Illinois",
    value: "Illinois"
  },
  {
    key: 16,
    text: "Indiana",
    value: "Indiana"
  },
  {
    key: 17,
    text: "Iowa",
    value: "Iowa"
  },
  {
    key: 18,
    text: "Kansas",
    value: "Kansas"
  },
  {
    key: 19,
    text: "Kentucky",
    value: "Kentucky"
  },
  {
    key: 20,
    text: "Louisiana",
    value: "Louisiana"
  },
  {
    key: 21,
    text: "Maine",
    value: "Maine"
  },
  {
    key: 22,
    text: "Maryland",
    value: "Maryland"
  },
  {
    key: 23,
    text: "Massachusetts",
    value: "Massachusetts"
  },
  {
    key: 24,
    text: "Michigan",
    value: "Michigan"
  },
  {
    key: 25,
    text: "Minnesota",
    value: "Minnesota"
  },
  {
    key: 26,
    text: "Mississippi",
    value: "Mississippi"
  },
  {
    key: 27,
    text: "Missouri",
    value: "Missouri"
  },
  {
    key: 28,
    text: "Montana",
    value: "Montana"
  },
  {
    key: 29,
    text: "Nebraska",
    value: "Nebraska"
  },
  {
    key: 30,
    text: "Nevada",
    value: "Nevada"
  },
  {
    key: 31,
    text: "New Hampshire",
    value: "New Hampshire"
  },
  {
    key: 32,
    text: "New Jersey",
    value: "New Jersey"
  },
  {
    key: 33,
    text: "New Mexico",
    value: "New Mexico"
  },
  {
    key: 34,
    text: "New York",
    value: "New York"
  },
  {
    key: 35,
    text: "North Carolina",
    value: "North Carolina"
  },

  {
    key: 36,
    text: "North Dakota",
    value: "North Dakota"
  },
  {
    key: 37,
    text: "Northern Mariana Islands",
    value: "Northern Mariana Islands"
  },
  {
    key: 38,
    text: "Ohio",
    value: "Ohio"
  },
  {
    key: 39,
    text: "Oklahoma",
    value: "Oklahoma"
  },
  {
    key: 40,
    text: "Oregon",
    value: "Oregon"
  },
  {
    key: 41,
    text: "Pennsylvania",
    value: "Pennsylvania"
  },
  {
    key: 42,
    text: "Puerto Rico",
    value: "Puerto Rico"
  },
  {
    key: 43,
    text: "Rhode Island",
    value: "Rhode Island"
  },
  {
    key: 44,
    text: "South Carolina",
    value: "South Carolina"
  },
  {
    key: 45,
    text: "South Dakota",
    value: "South Dakota"
  },
  {
    key: 46,
    text: "Tennessee",
    value: "Tennessee"
  },
  {
    key: 47,
    text: "Texas",
    value: "Texas"
  },
  {
    key: 48,
    text: "United States Minor Outlying Islands",
    value: "United States Minor Outlying Islands"
  },
  {
    key: 49,
    text: "U.S. Virgin Islands",
    value: "U.S. Virgin Islands"
  },

  {
    key: 50,
    text: "Utah",
    value: "Utah"
  },
  {
    key: 51,
    text: "Vermont",
    value: "Vermont"
  },
  {
    key: 52,
    text: "Virginia",
    value: "Virginia"
  },
  {
    key: 53,
    text: "Washington",
    value: "Washington"
  },
  {
    key: 54,
    text: "West Virginia",
    value: "West Virginia"
  },
  {
    key: 55,
    text: "Wisconsin",
    value: "Wisconsin"
  },
  {
    key: 56,
    text: "Wyoming",
    value: "Wyoming"
  }
];


export const usStatesWithAbbreviations = [
  { "key": 1, "text": "American Samoa", "value": "AS" },
  { "key": 2, "text": "Alabama", "value": "AL" },
  { "key": 3, "text": "Alaska", "value": "AK" },
  { "key": 4, "text": "Arizona", "value": "AZ" },
  { "key": 5, "text": "Arkansas", "value": "AR" },
  { "key": 6, "text": "California", "value": "CA" },
  { "key": 7, "text": "Colorado", "value": "CO" },
  { "key": 8, "text": "Connecticut", "value": "CT" },
  { "key": 9, "text": "Delaware", "value": "DE" },
  { "key": 10, "text": "Florida", "value": "FL" },
  { "key": 11, "text": "Georgia", "value": "GA" },
  { "key": 12, "text": "Guam", "value": "GU" },
  { "key": 13, "text": "Hawaii", "value": "HI" },
  { "key": 14, "text": "Idaho", "value": "ID" },
  { "key": 15, "text": "Illinois", "value": "IL" },
  { "key": 16, "text": "Indiana", "value": "IN" },
  { "key": 17, "text": "Iowa", "value": "IA" },
  { "key": 18, "text": "Kansas", "value": "KS" },
  { "key": 19, "text": "Kentucky", "value": "KY" },
  { "key": 20, "text": "Louisiana", "value": "LA" },
  { "key": 21, "text": "Maine", "value": "ME" },
  { "key": 22, "text": "Maryland", "value": "MD" },
  { "key": 23, "text": "Massachusetts", "value": "MA" },
  { "key": 24, "text": "Michigan", "value": "MI" },
  { "key": 25, "text": "Minnesota", "value": "MN" },
  { "key": 26, "text": "Mississippi", "value": "MS" },
  { "key": 27, "text": "Missouri", "value": "MO" },
  { "key": 28, "text": "Montana", "value": "MT" },
  { "key": 29, "text": "Nebraska", "value": "NE" },
  { "key": 30, "text": "Nevada", "value": "NV" },
  { "key": 31, "text": "New Hampshire", "value": "NH" },
  { "key": 32, "text": "New Jersey", "value": "NJ" },
  { "key": 33, "text": "New Mexico", "value": "NM" },
  { "key": 34, "text": "New York", "value": "NY" },
  { "key": 35, "text": "North Carolina", "value": "NC" },
  { "key": 36, "text": "North Dakota", "value": "ND" },
  { "key": 37, "text": "Northern Mariana Islands", "value": "MP" },
  { "key": 38, "text": "Ohio", "value": "OH" },
  { "key": 39, "text": "Oklahoma", "value": "OK" },
  { "key": 40, "text": "Oregon", "value": "OR" },
  { "key": 41, "text": "Pennsylvania", "value": "PA" },
  { "key": 42, "text": "Puerto Rico", "value": "PR" },
  { "key": 43, "text": "Rhode Island", "value": "RI" },
  { "key": 44, "text": "South Carolina", "value": "SC" },
  { "key": 45, "text": "South Dakota", "value": "SD" },
  { "key": 46, "text": "Tennessee", "value": "TN" },
  { "key": 47, "text": "Texas", "value": "TX" },
  { "key": 48, "text": "United States Minor Outlying Islands", "value": "UM" },
  { "key": 49, "text": "U.S. Virgin Islands", "value": "VI" },
  { "key": 50, "text": "Utah", "value": "UT" },
  { "key": 51, "text": "Vermont", "value": "VT" },
  { "key": 52, "text": "Virginia", "value": "VA" },
  { "key": 53, "text": "Washington", "value": "WA" },
  { "key": 54, "text": "West Virginia", "value": "WV" },
  { "key": 55, "text": "Wisconsin", "value": "WI" },
  { "key": 56, "text": "Wyoming", "value": "WY"  }
]

export default usStates;
