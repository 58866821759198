import React, { forwardRef, useRef, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { cloneDeep } from 'lodash'

import { Creators as PriorAuthorizationCreationActions } from '../../../../redux/reducers/api/priorAuthorizationManagement'
import { Creators as AppActions } from '../../../../redux/reducers/app'
import { Creators as UserManagementActions } from '../../../../redux/reducers/userManagement'
import { Creators as TimerConfigActions } from '../../../../redux/reducers/api/timerConfig'

import { LinkRenderer, ActionPopupMenuRendererCustom } from '../../../../components/fliptGrid/cellRenderers'

import PaDataTab from '../../../../components/fliptGrid'
import './styles.scss'

const headers = [
  'pa_case_id',
  'drug_name',
  'pa_case_status',
  'member_id',
  'first_name',
  'last_name',
  'dob',
  'priority_indicator',
  'date_time_received',
  'date_time_due',
  'elapsed_hours',
  'remaining_hours',
  'intake_type',
  'highlight_flag',
  'review_technician',
  'action',
]

const PATab = ({ tabConfig, state, actions, queueRef }) => {

  const { availableUsers, paTimerConfigurations = [], paCasesPerPage } = state

  let assignCaseData = { userId: null, pa_case_id: null, username: null }
  const history = useHistory()

  let availableUsersOptions = [{ text: 'None', value: 'None' }]
  if (availableUsers && availableUsers.length > 0) {
    availableUsersOptions = availableUsers.map(x => ({ text: x.name, value: x.user_id }))
  }

  const getPATechnicianName = (params) => {
    return state.allUsers.find(x => x?.user_id === params?.value)?.name ?? (params?.value || 'None')
  }

  const updateSelectedUser = (e, dropdown) => {
    const { text: username } = dropdown.options.find((user) => user.value === dropdown.value)
    assignCaseData = { userId: dropdown?.value, pa_case_id: dropdown?.context?.pa_case_id, username }
  }

  const handleIdClicked = (data) => {
    history.push('/pa-case-dashboard', { pa_case_id: data.value })
  }

  const reassignCaseCallback = () => {
    queueRef.current.api.refreshServerSideStore({ route: null, purge: true })
    assignCaseData = { userId: null, pa_case_id: null, username: null }
  }

  const reassignCase = () => {
    actions.reassignPACase({ ...assignCaseData, callback: reassignCaseCallback })
  }

  const serverSideGridConfig = {
    rowModel: 'serverSide',
    serverSideStoreType: 'partial',
    cacheBlockSize: paCasesPerPage,
    pagination: true,
    paginationPageSize: paCasesPerPage,
  }

  const serverSideGridParamsTemplate = {
    form: {},
    apiId: 'pa-dashboard',
    sagaToCall: actions.getPaDashboardDetails,
    headers: [],
  }

  const gridParams = {
    ...serverSideGridParamsTemplate,
    form: {
      userId: state.user?.uuid,
      status: tabConfig.id,
    }
  }

  const actionItems = [
    {
      type: 'Text',
      title: 'Case ID: ${pa_case_id}',
      style: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }
    },
    {
      type: 'Text',
      title: 'Current User: $f{${review_technician}}',
      aux_function: (user_id) => getPATechnicianName({ value: user_id }),
      style: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }
    },
    {
      type: 'Dropdown',
      placeholder: 'Available Users',
      label: 'New User: ',
      name: 'availableUsers',
      options: cloneDeep(availableUsersOptions),
      onChange: (e, dropdown) => updateSelectedUser(e, dropdown),
      stylized: true,
      multiple: false,
    },
    {
      type: 'Button',
      name: 'Reassign Case',
      onClick: () => reassignCase(),
      className: 'primary',
      style: {
        "display": "block",
        "margin": "auto",
      }
    }
  ]

  const cellRendererParams = {
    member_id: {
      overrideHeader: 'Patient Id',
    },
    elapsed_hours: {
      valueFormatter: (params) => {
        return (params?.value || '').replace('.', ':')
      },
      overrideHeader: 'Time Elapsed (hr/min)',
      comparator: (valueA, valueB) => valueA - valueB,
      sortable: false,
    },
    remaining_hours: {
      valueFormatter: (params) => {
        return (params?.value || '').replace('.', ':')
      },
      overrideHeader: 'Time Remaining (hr/min)',
      comparator: (valueA, valueB) => valueA - valueB,
      width: 215,
      sortable: false,
    },
    action: {
      cellRenderer: ActionPopupMenuRendererCustom,
      searchArgs: {
        actionItems: actionItems,
        displayValue: 'Reassign Case',
        fontSize: '1em',
      },
      width: 230,
      sortable: false,
    },
    pa_case_id: {
      cellRenderer: LinkRenderer,
      onCellClicked: handleIdClicked,
      searchArgs: {},
      overrideHeader: 'Case ID',
    },
    pa_case_status: {
      overrideHeader: 'Status',
    },
    intake_type: {
      overrideHeader: 'Source',
    },
    drug_name: {
      overrideHeader: 'Drug',
    },
    review_technician: {
      overrideHeader: 'User',
      valueFormatter: (params) => {
        return getPATechnicianName(params)
      },
      sortable: false,
    },
    dob: {
      overrideHeader: 'Patient DOB',
      sortable: false,
    },
    first_name: {
      overrideHeader: 'Patient First Name',
      sortable: false,
    },
    last_name: {
      overrideHeader: 'Patient Last Name',
      sortable: false,
    },
    priority_indicator: {
      overrideHeader: 'Priority',
    },
    highlight_flag: {
      hide: true,
    },
  }



  const rowClassRules = {
    'yellow-highlight': function (params) {
      return params?.data?.highlight_flag === 1
    },
    'orange-highlight': function (params) {
      return params?.data?.highlight_flag === 2
    },
    'red-highlight': function (params) {
      return params?.data?.highlight_flag === 3
    },
  }

  // disable filters for all headers as functionality is not yet implemented
  headers.forEach((header) => {
    if (cellRendererParams[header]) {
      cellRendererParams[header].filter = false
    } else {
      cellRendererParams[header] = { filter: false }
    }
  })

  return (
    <div id='pa-dashboard-tab'>
      <div className='header'>{`${tabConfig.tabName} Queue`}</div>
      <div className="content">
        <PaDataTab
          headers={headers}
          cellRendererParams={cellRendererParams}
          serverSideGridConfig={serverSideGridConfig}
          serverSideGridParams={gridParams}
          agGridRef={queueRef}
          rowClassRules={rowClassRules}
        />
      </div>
    </div>)
}

const mapStateToProps = (state) => {
  const allPATimerConfigurations = state?.timerConfig?.allPATimerConfigurationsData?.length > 0 ? state?.timerConfig?.allPATimerConfigurationsData : []
  const paTimerConfigurations = {
    'standard': [],
    'expedited': [],
  }
  allPATimerConfigurations.forEach(x => {
    if (paTimerConfigurations[x?.priority.toLowerCase()]) paTimerConfigurations[x.priority.toLowerCase()].push(x)
  })
  return {
    state: {
      app: state.app,
      user: state.user,
      // assignToMeMessage: state.priorAuthorizationCreation.assignToMeMessage,
      paCasesPerPage: state.priorAuthorizationCreation.paCasesPerPage,
      availableUsers: state.userManagement.data?.filter(x => x?.roles?.map(y => y.name)?.filter(z => z && z.toLowerCase().startsWith('pa')).length > 0).map(x => ({ name: `${x?.first_name} ${x?.last_name}`, user_id: x?.uuid })),
      allUsers: state.userManagement?.data?.map(x => ({ name: `${x?.first_name} ${x?.last_name}`, user_id: x?.uuid })),
      paTimerConfigurations,
    },
  }
}

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...PriorAuthorizationCreationActions,
    ...AppActions,
    ...UserManagementActions,
    ...TimerConfigActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PATab)
