import React, { Component } from 'react'

import FliptTextarea from '../../form/fliptTextarea'
import FliptButton from '../../v2/fliptButton'
import './styles.scss'

class ApprovalModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      note: '',
    }

    this.statusTypes = {
      APPROVED: 'Approve',
      TEST_APPROVED: 'Approve For Testing',
      REJECTED: 'Reject',
      PUBLISH_READY: 'Publish Ready',
      PUBLISH_FAILED: 'Re-Publish',
    }
  }

  _submitRequest = () => {
    const {
      submitType, toggleModal, clickHandler, data,
    } = this.props
    const { note } = this.state

    clickHandler({
      ...data,
      note,
      status: submitType,
    })

    toggleModal()
  }

  _updateNote = (el) => {
    const val = el.currentTarget.value

    this.setState({
      note: val,
    })
  }

  render() {
    const { data, submitType, toggleModal } = this.props
    const text = this.statusTypes[submitType] || ''
    const header = `Document Name: ${data.module_name}`
    // approvals-approval-modal
    return (
      <div id="approvals-approval-modal">
        <div className="content">
          <h3>{header}</h3>
          <h2>
            Module:
            {' '}
            {data.module}
          </h2>
          <span className="line" />
          {/* <p className="subheading">
            A confirmation email will be sent to&nbsp;
            <span>emailaddress@approveraccount.com</span>
          </p>
          <br />
          <p>A status update for this approval will automatically be sent to:</p>
          <p>
            <span>Peressa Simons</span>
            &nbsp;(Plan Management Submitter)
          </p>
          <p>
            <span>Roy Davies</span>
            &nbsp;(Financial Services Approver)
          </p> */}
          <FliptTextarea
            placeholder="Please list notes"
            className='flipt-text-area'
            onChange={this._updateNote}
          />
          <div className='button-container'>
            <FliptButton
              name={text}
              onClick={() => this._submitRequest()}
            />

            <FliptButton
              name="Cancel"
              onClick={toggleModal}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default ApprovalModal
