import React, { PureComponent } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import "./styles.scss";
import { Creators as AppActions } from "../../../../redux/reducers/app";
import FliptInput from "../../../../components/form/fliptInput";
import FliptRadio from "../../../../components/form/fliptRadio";
import FliptLabel from "../../../../components/form/fliptLabel";
import { Creators as FileConfigurationActions } from "../../../../redux/reducers/api/fileConfiguration";
import Info from "./Info";


const OutboundEmailInformation = (props) => {

  // Now you can use these variables within the component as needed
  const validations = props.state.validations;
  const validationResults = props.state.validationResults;
  const showErrors =
    validationResults && validationResults && !validationResults.isAllValid;
  const _updateFields = (el, dropdown) => {
    const { name, value } = dropdown || el.currentTarget;
    props.actions.updateEmailInformationForm({ name, value });
  };

  const { emailInformation } = props.state;

  const {
    client_email_notification_ind,
    internal_email_notification_ind,
    send_receieve_files_responsibility,
    technical_contact_file_type,
    technical_contact_file_name,
    technical_contact_phone_number,
    technical_contact_email,
    technical_contact_external_sftp_url,
    technical_contact_external_sftp_user_name,
    technical_contact_external_sftp_password,
    technical_contact_external_test_sftp_ind,
    technical_contact_external_test_sftp_url,
    technical_contact_external_test_sftp_user_name,
    technical_contact_external_test_password,
  } = emailInformation;

  return (
    <div id="file-configuration">
      <>
        <div className="section-header">Technical Contact</div>
        <div className="content shadow margin-bottom">
          <div className="fields-container">
            <div className="fields-header">
              <div className="field-label">
                <span>File Type</span>
                <Info
                  content="Technical Contact's First Name"
                  position="top right"
                />
              </div>
              <FliptInput
                className="field-input"
                name="technical_contact_file_type"
                placeholder="File Type"
                value={technical_contact_file_type}
                onChange={_updateFields}
              />
            </div>

            <div className="fields-header">
              <div className="field-label">
                <span>File Name</span>
                <Info
                  content="Technical Contact's Last Name"
                  position="top right"
                />
              </div>
              <FliptInput
                className="field-input"
                name="technical_contact_file_name"
                placeholder="File Name"
                value={technical_contact_file_name}
                onChange={_updateFields}
              />
            </div>
          </div>

          <div className="fields-container">
            <div className="fields-header">
              <div className="field-label">
                <span>Phone Number</span>
                <Info
                  content="Technical Contact's Phone Number"
                  position="top right"
                />
              </div>
              <FliptInput
                className="field-input"
                name="technical_contact_phone_number"
                placeholder="Phone Number"
                value={technical_contact_phone_number}
                onChange={_updateFields}
              />
            </div>

            <div className="fields-header">
              <div className="field-label">
                <span>Email Address </span>
                <Info
                  content="Technical Contact's Email"
                  position="top right"
                />
              </div>
              <FliptInput
                className="field-input"
                name="technical_contact_email"
                placeholder="Email Address"
                value={technical_contact_email}
                onChange={_updateFields}
              />
            </div>
          </div>

          <div className="fields-container">
            <div className="fields-header">
              <div className="field-label">
                <span>Enter sFTP site URL </span>
                <Info
                  content="URL of client's sFTP site"
                  position="top right"
                />
              </div>
              <FliptInput
                className="field-input"
                name="technical_contact_external_sftp_url"
                placeholder="https://www.example.com"
                value={technical_contact_external_sftp_url}
                onChange={_updateFields}
              />
            </div>
            <div className="fields-header">
              <div className="field-label">
                <div className="divide-half">
                  <div className="field-label">
                    <span>Enter sFTP site Username</span>
                    <Info
                      content="User Name for client's sFTP site"
                      position="top right"
                    />
                  </div>
                  <FliptInput
                    className="field-input"
                    name="technical_contact_external_sftp_user_name"
                    placeholder="PlaceHolder "
                    value={technical_contact_external_sftp_user_name}
                    onChange={_updateFields}
                  />
                </div>
                <div className="divide-half">
                  <div className="field-label">
                    <span>Enter sFTP site Password</span>
                    <Info
                      content="Password for client's sFTP site"
                      position="top right"
                    />
                  </div>
                  <FliptInput
                    className="field-input"
                    name="technical_contact_external_sftp_password"
                    placeholder="PlaceHolder "
                    value={technical_contact_external_sftp_password}
                    onChange={_updateFields}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="fields-container">
            <div className="fields-header">
              <div className="field-label">
                <span>
                  Test file sFTP setup (if different from above)
                </span>
                <Info
                  content="Test file sFTP setup (if different from above)"
                  position="top right"
                />
              </div>
            </div>
            <div className="fields-header">
              <div className="field-label-radio">
                <div className="divide-half-radio">
                  <FliptRadio
                    className="radio-input"
                    toggle={false}
                    name="technical_contact_external_test_sftp_ind"
                    value={"Yes"}
                    checked={
                      technical_contact_external_test_sftp_ind === "Yes"
                    }
                    onChange={_updateFields}
                  />
                  <FliptLabel
                    className="radio-label"
                    description=""
                    label="Yes"
                  />
                </div>
                <div className="divide-half-radio">
                  <FliptRadio
                    className="radio-input"
                    toggle={false}
                    name="technical_contact_external_test_sftp_ind"
                    value={"No"}
                    checked={
                      technical_contact_external_test_sftp_ind === "No"
                    }
                    onChange={_updateFields}
                  />
                  <FliptLabel
                    className="radio-label"
                    description=""
                    label="No"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="fields-container">
            <div className="fields-header">
              <div className="field-label">
                <span>Enter Test SFTP Site URL </span>
                <Info
                  content="URL of client's test sFTP site"
                  position="top right"
                />
              </div>
              <FliptInput
                className="field-input"
                name="technical_contact_external_test_sftp_url"
                placeholder="https://www.example.com"
                value={technical_contact_external_test_sftp_url}
                onChange={_updateFields}
              />
            </div>
            <div className="fields-header">
              <div className="field-label">
                <div className="divide-half">
                  <div className="field-label">
                    <span>Enter Test sFTP Site Username</span>
                    <Info
                      content="User Name for client's test sFTP site"
                      position="top right"
                    />
                  </div>
                  <FliptInput
                    className="field-input"
                    name="technical_contact_external_test_sftp_user_name"
                    placeholder="PlaceHolder "
                    value={technical_contact_external_test_sftp_user_name}
                    onChange={_updateFields}
                  />
                </div>
                <div className="divide-half">
                  <div className="field-label">
                    <span>Enter Test sFTP Site Password</span>
                    <Info
                      content="Password for client's test sFTP site"
                      position="top right"
                    />
                  </div>
                  <FliptInput
                    className="field-input"
                    name="technical_contact_external_test_password"
                    placeholder="PlaceHolder "
                    value={technical_contact_external_test_password}
                    onChange={_updateFields}
                  />
                </div>
              </div>
            </div>
          </div>

        </div>
      </>
    </div>
  );
};




const mapStateToProps = (state) => {
  return {
    state: {
      app: state.app,
      emailInformation: state.fileConfiguration.emailInformation,
      validations: state.fileConfiguration.validations.emailInformation,
      validationResults:
        state.fileConfiguration.validationResults.emailInformation,
      additionalFields: state.fileConfiguration.additional_fields?.find(row => row.page === 'email_and_sftp')?.fields || [],
      // test data below, to be removed 
      //  additionalFields: sampleData?.find(row => row.page === 'email_and_sftp')?.fields || [],

    },
  }
}

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
    ...FileConfigurationActions,
  };

  return {
    actions: bindActionCreators(allActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OutboundEmailInformation);
