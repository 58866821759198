import React from 'react'
import moment from 'moment'
import FliptInput from '../../../../../components/form/fliptInput'
import FliptButton from '../../../../../components/form/fliptButton'
import FliptDropdown from '../../../../../components/form/fliptDropdown'
import FliptDatePicker from '../../../../../components/form/fliptDatePicker'
import { addRemoveDaysToDate } from '../../../../../utils/utilities'
import {
  overrideByEditValues, copayOverrideTypeValues, claimOrExtendedValues,
  payerRoutingOverrideBypassValues, payerRoutingOverrideBypassValuesForSingleContractor
} from './overrideDataValues'
import _ from 'lodash'
export default (props) => {
  const {
    data, updateOverrideForm, setOverride, cancelOverride, ql_limit_days_msg, readOnly, editMode, contractedProcessorList,
    claimOverview, pricing, ispaidClaim
  } = props
  const {
    copay_override_type,
    override_amount,
    override_by_edit,
    override_by_edit_other,
    override_from,
    override_percentage,
    override_thru,
    payer_routing_override_bypass,
    claim_or_extended,
    copay_amount_30ds,
    copay_amount_60ds,
    copay_amount_90ds,
    routed_override_processor
  } = data
  const {
    claim_section, override_section
  } = claimOverview

  const {
    copay_amt, copay_type
  } = pricing

  const {
    overridenumber
  } = override_section
  const { date_of_service } = claim_section || {}
  const override_min_date = moment().format('MM-DD-YYYY')
  const override_max_date = addRemoveDaysToDate(365)
  let overrideByEditOptions = Object.keys(overrideByEditValues).map((d, index) => ({
    key: index + 1,
    text: overrideByEditValues[d].text,
    value: overrideByEditValues[d].value,
  }))
  overrideByEditOptions = [
    {
      key: 0,
      text: 'Select Option',
      value: '',
    },
    ..._.sortBy(overrideByEditOptions, 'text')
  ]
  const copayOverrideTypeOptions = Object.keys(copayOverrideTypeValues).map((d, index) => ({
    key: index + 1,
    text: copayOverrideTypeValues[d].text,
    value: copayOverrideTypeValues[d].value,
  }))

  const claimOrExtendedOptions = Object.keys(claimOrExtendedValues).map((d, index) => ({
    key: index + 1,
    text: claimOrExtendedValues[d].text,
    value: claimOrExtendedValues[d].value,
  }))

  const overrideOptions = Object.keys(contractedProcessorList.length > 1 ? payerRoutingOverrideBypassValues : payerRoutingOverrideBypassValuesForSingleContractor).map((d, index) => ({
    key: index + 1,
    text: payerRoutingOverrideBypassValues[d].text,
    value: payerRoutingOverrideBypassValues[d].value,
  }))

  const displayCoPayOverrideType = override_by_edit === overrideByEditValues.copay.value
    || override_by_edit === overrideByEditValues.drug_coverage.value
  // const displayOverrideTypeOther = override_by_edit && override_by_edit === overrideByEditValues.other.value
  const displayOverrideAmount = displayCoPayOverrideType && claim_or_extended === claimOrExtendedValues.claim.value && copay_override_type === copayOverrideTypeValues.fixed.value
  const displayOverridePercentage = displayCoPayOverrideType && (claim_or_extended === claimOrExtendedValues.claim.value || claim_or_extended === claimOrExtendedValues.extended.value) && copay_override_type === copayOverrideTypeValues.percentage.value
  const displayDSCopay = displayCoPayOverrideType && claim_or_extended === claimOrExtendedValues.extended.value && copay_override_type === copayOverrideTypeValues.fixed.value
  const displayQLDaysLimitMSG = override_by_edit === overrideByEditValues.refill_too_soon.value && ql_limit_days_msg
  const displayContractedProcessor = override_by_edit === overrideByEditValues.payer_bypass.value
  let newOverrideOptions
  let newClaimOrExtendedOptions
  if (override_by_edit === overrideByEditValues.drug_coverage.value) {
    newClaimOrExtendedOptions = claimOrExtendedOptions.filter((d) => d.value === claimOrExtendedValues.extended.value)
  }

  if (override_by_edit !== overrideByEditValues.payer_bypass.value) {
    newOverrideOptions = overrideOptions.filter((d) => d.value === payerRoutingOverrideBypassValues.no.value)
  }
  const overridByEditArray = [
    overrideByEditValues.refill_too_soon.value, overrideByEditValues.dur_reject_error.value,
    overrideByEditValues.days_supply_limitation_for_product_service.value, overrideByEditValues.lock_in.value,
    overrideByEditValues.lock_out.value, overrideByEditValues.payer_bypass.value, overrideByEditValues.sanction.value
  ]
  if (overridByEditArray.find((v) => v === override_by_edit)) {
    newClaimOrExtendedOptions = claimOrExtendedOptions.filter((d) => d.value === claimOrExtendedValues.claim.value)
  }
  const calenderMinDate = override_from ? moment(override_from).add(1, 'days').toDate() : override_min_date
  const calenderMaxDate = date_of_service ? moment(date_of_service).toDate() : override_max_date
  return (
    <div className="section">
      <h2>Enter Override Details</h2>
      <div className='error'>{ispaidClaim ? `Claim already exists in a paid status.` : ''}</div>
      <div className="fields-container">
        <div className="fields">
          {displayQLDaysLimitMSG && <h1 name="ql_limit_days_msg">{ql_limit_days_msg}</h1>}
          {editMode || readOnly ? <FliptInput value={override_by_edit} name="override_by_edit" label="Override Type" stylized readOnly={readOnly || editMode} /> :
            <FliptDropdown value={override_by_edit || ''} options={overrideByEditOptions} name="override_by_edit" label="Override Type" stylized onChange={updateOverrideForm} readOnly={readOnly} />}
          <FliptDatePicker value={override_from} name="override_from" label="Override Start Date" minDate={override_min_date} maxDate={calenderMaxDate}
            stylized onChange={updateOverrideForm} readOnly={readOnly} />
          {displayContractedProcessor && <FliptDropdown value={routed_override_processor} options={contractedProcessorList} name="routed_override_processor" label="Contracted Processor" stylized onChange={updateOverrideForm} />}
          {displayCoPayOverrideType && <FliptDropdown value={copay_override_type} options={copayOverrideTypeOptions} name="copay_override_type" label="Copay Override Type" stylized size="4" onChange={updateOverrideForm} readOnly={readOnly} />}
        </div>
        <div className="fields">
          <FliptDropdown value={claim_or_extended} options={newClaimOrExtendedOptions?.length ? newClaimOrExtendedOptions : claimOrExtendedOptions} name="claim_or_extended" label="Claim Only/Extended" stylized onChange={updateOverrideForm} readOnly={readOnly} />
          <FliptDatePicker value={override_thru} name="override_thru" label="Override End Date" minDate={calenderMinDate}
            maxDate={override_max_date} stylized onChange={updateOverrideForm} readOnly={readOnly} />
          {displayOverrideAmount && <FliptInput value={override_amount} name="override_amount" label="Overview Amount" stylized onChange={updateOverrideForm} readOnly={readOnly} />}
          {displayOverridePercentage && <FliptInput value={override_percentage} name="override_percentage" label="Copay Percentage" stylized onChange={updateOverrideForm} readOnly={readOnly} />}
        </div>
        <div className="fields">
          {override_by_edit === 'copay' && <FliptInput value={copay_amt} name="copay_amt" label="Copay Amount" stylized disabled />}
          {override_by_edit === 'copay' && <FliptInput value={copay_type} name="copay_type" label="Copay Type" stylized disabled />}
        </div>
      </div>
      <div className="fields-container">
        <div className="fields">
          {(displayDSCopay || displayOverrideAmount) && <FliptInput value={copay_amount_30ds} name="copay_amount_30ds" label="30 DS Copay" stylized onChange={updateOverrideForm} readOnly={readOnly} />}
        </div>
        <div className="fields">
          {(displayDSCopay || displayOverrideAmount) && <FliptInput value={copay_amount_60ds} name="copay_amount_60ds" label="60 DS Copay" stylized onChange={updateOverrideForm} readOnly={readOnly} />}
        </div>
        <div className="fields">
          {(displayDSCopay || displayOverrideAmount) && <FliptInput value={copay_amount_90ds} name="copay_amount_90ds" label="90 DS Copay" stylized onChange={updateOverrideForm} readOnly={readOnly} />}
        </div>
      </div>
      <div className="fields-container">
        <div className="fields">
          <FliptDropdown value={payer_routing_override_bypass} options={newOverrideOptions?.length ? newOverrideOptions : overrideOptions} name="payer_routing_override_bypass" label="Payer Routing Override Bypass" stylized onChange={updateOverrideForm} readOnly={readOnly} />
        </div>
        <div className="fields">
          <FliptInput value={overridenumber} name="overridenumber" label="Override Number" stylized onChange={updateOverrideForm} readOnly={readOnly} />
        </div>
        <div className="fields" />
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div className="fields-container">
          <div className="fields">
            {(!readOnly && !ispaidClaim) && <FliptButton name={editMode ? "Update Override" : "Set Override"} className="primary" onClick={setOverride} />}
          </div>
          <div className="fields">
            <FliptButton name="Cancel" className="primary" onClick={cancelOverride} />
          </div>
        </div>
      </div>
    </div>
  )
}
