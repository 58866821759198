import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import './style.scss'
import FliptForm from '../../../components/form/fliptForm'
import FliptButton from '../../../components/form/fliptButton'
import FliptInput from '../../../components/form/fliptInput'
import { Creators as UserActions } from '../../../redux/reducers/user'
import { Creators as NavigationActions } from '../../../redux/reducers/navigation'
import { parseQueryString } from '../../../utils/utilities'

class MFALogin extends Component {
  constructor(props) {
    super(props)

    this.state = {
      form: {
        code: null,
      },
    }
  }

  componentDidMount() {
    const { history } = this.props
    const { form } = this.state

    form.email_address = parseQueryString(history.location.search).email_address
    this.setState(form)
  }

  _updateFields = (el) => {
    const { form } = this.state
    const { name, value } = el.currentTarget

    form[name] = value
    this.setState({ form })
  }

  _userMFALogin = () => {
    const { props, state } = this
    props.actions.userMFALogin(state.form)
  }

  render() {
    return (
      <div id="mfaLoginPage" className="content-container">
        <div className="left">
          {/* { state?.app?.companyCSSFilename ? <img src={`/i/${state?.app?.companyCSSFilename}/logo-header.png`} alt="Alternate Logo Header" /> : <img alt="Flipt FAST Self Service Application" src="/i/flipt-logo-login.png" /> } */}
          <div className="logo-container">
            <div className="logo-login" />
            <div className="logo-login-default" />
          </div>
        </div>
        <div className="right">
          <div className="header-text">Please enter the 6 digit verification code</div>
          <FliptForm id="mfaLogin">
            <FliptInput placeholder="Enter Code" name="code" onChange={this._updateFields} validation="code" required />
            <FliptButton className="primary" name="Login" onClick={this._userMFALogin} />
          </FliptForm>
        </div>
      </div>
    )
  }
}

const mapStateToProps = () => ({
  state: {
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...NavigationActions,
    ...UserActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MFALogin)
