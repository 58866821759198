import { operatorType } from "./dataTypes";
export default {
    carrier: {
        type: 'dropdown',
        multiple: true,
        disabled: false,
        options: [],
    },
    account: {
        type: 'dropdown',
        multiple: true,
        disabled: false,
        options: [],
    },
    group: {
        type: 'dropdown',
        disabled: false,
        multiple: true,
        options: [],
    },
    include: {
        type: 'checkbox',
        disabled: false,
    },
    member_id: {
        type: 'input',
        disabled: false,
    },
    first_name: {
        type: 'input',
        disabled: false,
    },
    last_name: {
        type: 'input',
        disabled: false,
    },
    npi: {
        type: 'input',
        disabled: false,
    },
    auth_id: {
        type: 'input',
        disabled: false,
    },
    start_date: {
        type: 'input',
        disabled: false,
    },
    end_date: {
        type: 'input',
        disabled: false,
    },
    drug_name: {
        type: 'search',
        disabled: false,
    },
    plan_name: {
        type: 'input',
        disabled: false,
    },
    gpi: {
        type: 'input',
        disabled: false,
    },
    ndc: {
        type: 'input',
        disabled: false,
    }
}