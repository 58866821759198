import React, { PureComponent } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import "./styles.scss";
import { Creators as AppActions } from "../../../../redux/reducers/app";
import { Button, Icon } from "semantic-ui-react";
import FliptPaDropdown from "../../../../components/form/fliptPaDropdown";
import FliptInput from "../../../../components/form/fliptInput";
import FliptTextarea from "../../../../components/form/fliptTextarea";
import FliptCheckbox from "../../../../components/form/fliptCheckbox";
import FliptRadio from "../../../../components/form/fliptRadio";
import FliptLabel from "../../../../components/form/fliptLabel";
import { Creators as FileConfigurationActions } from "../../../../redux/reducers/api/fileConfiguration";
import Info from "./Info";
import { renderDynamicInput, sampleData } from "./utils";
const EmailInformation = (props) => {
  const validations = props.state.validations;
  const validationResults = props.state.validationResults;
  const showErrors =
    validationResults && validationResults && !validationResults.isAllValid;
  const _updateFields = (el, dropdown) => {
    const { name, value } = dropdown || el.currentTarget;
    props.actions.updateEmailInformationForm({ name, value });
  };

  const { emailInformation } = props.state;

  const {
    client_email_notification_ind,
    client_notification_type,
    client_notification_emails,
    client_error_email_notification_ind,
    internal_email_notification_ind,
    internal_notification_type,
    internal_notification_emails,
    pbm_sftp_used,
    pbm_standard_sftp_structure_used,
    pbm_sftp_url,
    pbm_sftp_user_name,
    pbm_sftp_password,
    pbm_sftp_folder_structure,
    send_receieve_files_responsibility,
    technical_contact_file_type,
    technical_contact_file_name,
    technical_contact_phone_number,
    technical_contact_email,
    technical_contact_external_sftp_url,
    technical_contact_external_sftp_user_name,
    technical_contact_external_sftp_password,
    technical_contact_external_test_sftp_ind,
    technical_contact_external_test_sftp_url,
    technical_contact_external_test_sftp_user_name,
    technical_contact_external_test_password,
  } = emailInformation;

  const renderAdditionalFields = () => {
    const { additionalFields } = props.state;
    if (additionalFields?.length == 0) return null;
    const rowDivider = 2;
    const fieldProps = {
      ...emailInformation,
      onChange: _updateFields,
      className: 'fields-header'
    };
    return (
      <>
        {Array.from(
          { length: Math.ceil(additionalFields.length / rowDivider) },
          (_, i) => (
            <div className="fields-container" key={`row${i}`}>
              {additionalFields
                .slice(i * rowDivider, (i + 1) * rowDivider)
                .map((movie) => renderDynamicInput(movie, fieldProps))}
            </div>
          )
        )}
      </>
    );
  };

  return (
    <div id="file-configuration">
      <div className="section-header">eMail and sFTP Information</div>
      <div className="content shadow margin-bottom ">
        <div className="fields-container">
          <div className="fields-header">
            <div className="field-label">
              <span>
                Does the client wish to receive email notification?{" "}
                <span className="danger">*</span>
              </span>
              <Info
                content="Would the client like to receive notifications on file load status"
                position="top right"
              />
            </div>
            <div className="field-label-radio">
              <div className="divide-half-radio">
                <FliptRadio
                  className="radio-input"
                  toggle={false}
                  name="client_email_notification_ind"
                  value={"Yes"}
                  checked={client_email_notification_ind === "Yes"}
                  onChange={_updateFields}
                />
                <FliptLabel
                  className="radio-label"
                  description=""
                  label="Yes"
                />
                {showErrors &&
                  !validationResults?.client_email_notification_ind && (
                    <section className="flipt-input-error">
                      <em>
                        {validations.client_email_notification_ind
                          ?.errorMessage || null}
                      </em>
                    </section>
                  )}
              </div>
              <div className="divide-half-radio">
                <FliptRadio
                  className="radio-input"
                  toggle={false}
                  name="client_email_notification_ind"
                  value={"No"}
                  checked={client_email_notification_ind === "No"}
                  onChange={_updateFields}
                />
                <FliptLabel className="radio-label" description="" label="No" />
              </div>
            </div>
            <div>
              {client_email_notification_ind == "No" && (
                <>
                  <div className="field-label">
                    <span>
                      Does the client wish to receive eligibility error report on SFTP?
                    </span>
                    <Info
                      content="Does the client wish to receive eligibility error report on SFTP?"
                      position="top right"
                    />
                  </div>
                  <div className="field-label-radio">
                    <div className="divide-half-radio">
                      <FliptRadio
                        className="radio-input"
                        toggle={false}
                        name="client_error_email_notification_ind"
                        value={"Yes"}
                        checked={client_error_email_notification_ind === "Yes"}
                        onChange={_updateFields}
                      />
                      <FliptLabel
                        className="radio-label"
                        description=""
                        label="Yes"
                      />
                      {showErrors &&
                        !validationResults?.client_error_email_notification_ind && (
                          <section className="flipt-input-error">
                            <em>
                              {validations.client_error_email_notification_ind
                                ?.errorMessage || null}
                            </em>
                          </section>
                        )}
                    </div>
                    <div className="divide-half-radio">
                      <FliptRadio
                        className="radio-input"
                        toggle={false}
                        name="client_error_email_notification_ind"
                        value={"No"}
                        checked={client_error_email_notification_ind === "No"}
                        onChange={_updateFields}
                      />
                      <FliptLabel className="radio-label" description="" label="No" />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="fields-header">
            <div className="field-label">
              <span>
                Will additional internal email notifications be required?
                <span className="danger">*</span>
              </span>
              <Info
                content="Should Internal PBM notifications on file load statuses be sent"
                position="top right"
              />
            </div>
            <div className="field-label-radio">
              <div className="divide-half-radio">
                <FliptRadio
                  className="radio-input"
                  toggle={false}
                  name="internal_email_notification_ind"
                  value={"Yes"}
                  checked={internal_email_notification_ind === "Yes"}
                  onChange={_updateFields}
                />
                <FliptLabel
                  className="radio-label"
                  description=""
                  label="Yes"
                />
                {showErrors &&
                  !validationResults?.internal_email_notification_ind && (
                    <section className="flipt-input-error">
                      <em>
                        {validations.internal_email_notification_ind
                          ?.errorMessage || null}
                      </em>
                    </section>
                  )}
              </div>
              <div className="divide-half-radio">
                <FliptRadio
                  className="radio-input"
                  toggle={false}
                  name="internal_email_notification_ind"
                  value={"No"}
                  checked={internal_email_notification_ind === "No"}
                  onChange={_updateFields}
                />
                <FliptLabel className="radio-label" description="" label="No" />
              </div>
            </div>
          </div>
        </div>

        <div className="fields-container">
          <div className="fields-header">
            {client_email_notification_ind == "Yes" && (
              <>
                <div className="field-label">
                  <span>
                    Select the type of notification being requested for import
                    file processing
                  </span>
                  <Info
                    content="Should only failure notifications, or both success and failure notifications be sent"
                    position="top right"
                  />
                </div>
                <div className="field-label-radio">
                  <div className="divide-half-radio">
                    <FliptRadio
                      className="radio-input"
                      toggle={false}
                      name="client_notification_type"
                      value={"successful"}
                      checked={client_notification_type === "successful"}
                      onChange={_updateFields}
                    />
                    <FliptLabel
                      className="radio-label"
                      description=""
                      label="Only successful"
                    />
                  </div>
                  <div className="divide-half-radio">
                    <FliptRadio
                      className="radio-input"
                      toggle={false}
                      name="client_notification_type"
                      value={"failure"}
                      checked={client_notification_type === "failure"}
                      onChange={_updateFields}
                    />
                    <FliptLabel
                      className="radio-label"
                      description=""
                      label="Only failure"
                    />
                  </div>
                </div>
                <div className="field-label-radio">
                  <div className="divide-half-radio">
                    <FliptRadio
                      className="radio-input"
                      toggle={false}
                      name="client_notification_type"
                      value={"successful and failed"}
                      checked={
                        client_notification_type === "successful and failed"
                      }
                      onChange={_updateFields}
                    />
                    <FliptLabel
                      className="radio-label"
                      description=""
                      label="Both successful and failed"
                    />
                  </div>
                </div>
                <div className="field-label">
                  <span>Enter email address(s)</span>
                  <Info
                    content="Email address where notifications should be sent"
                    position="top right"
                  />
                </div>
                <FliptInput
                  className="field-input"
                  name="client_notification_emails"
                  placeholder="Email"
                  value={client_notification_emails}
                  onChange={_updateFields}
                />
                <div className="field-label">
                  <div className="field-label-hint">
                    <span>
                      In case of multiple email addresses, you can separate them
                      by semi-colon
                    </span>
                  </div>
                </div>
              </>
            )}
          </div>

          <div className="fields-header">
            {internal_email_notification_ind == "Yes" && (
              <>
                <div className="field-label">
                  <span>
                    Select the type of notification being requested for import
                    file processing
                  </span>
                  <Info
                    content="Should only failure notifications, or both success and failure notifications be sent"
                    position="top right"
                  />
                </div>
                <div className="field-label-radio">
                  <div className="divide-half-radio">
                    <FliptRadio
                      className="radio-input"
                      toggle={false}
                      name="internal_notification_type"
                      value={"successful"}
                      checked={internal_notification_type === "successful"}
                      onChange={_updateFields}
                    />
                    <FliptLabel
                      className="radio-label"
                      description=""
                      label="Only successful"
                    />
                  </div>
                  <div className="divide-half-radio">
                    <FliptRadio
                      className="radio-input"
                      toggle={false}
                      name="internal_notification_type"
                      value={"failure"}
                      checked={internal_notification_type === "failure"}
                      onChange={_updateFields}
                    />
                    <FliptLabel
                      className="radio-label"
                      description=""
                      label="Only failure"
                    />
                  </div>
                </div>
                <div className="field-label-radio">
                  <div className="divide-half-radio">
                    <FliptRadio
                      className="radio-input"
                      toggle={false}
                      name="internal_notification_type"
                      value={"successful and failed"}
                      checked={
                        internal_notification_type === "successful and failed"
                      }
                      onChange={_updateFields}
                    />
                    <FliptLabel
                      className="radio-label"
                      description=""
                      label="Both successful and failed"
                    />
                  </div>
                </div>
                <div className="field-label">
                  <span>Enter email address(s)</span>
                  <Info
                    content="Email address where notifications should be sent"
                    position="top right"
                  />
                </div>

                <FliptInput
                  className="field-input"
                  name="internal_notification_emails"
                  placeholder="Email"
                  value={internal_notification_emails}
                  onChange={_updateFields}
                />
                <div className="field-label">
                  <div className="field-label-hint">
                    <span>
                      In case of multiple email addresses, you can separate them
                      by semi-colon
                    </span>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>

        {/* <div className="fields-container">
              <div className="fields-header">
                <div className="field-label">
                  <span>Enter email address(s)</span>
                </div>
              </div>
              <div className="fields-header">
                <FliptInput
                  className="field-input"
                  name="client_notification_emails"
                  placeholder="Email"
                  value={client_notification_emails}
                  onChange={_updateFields}
                />
                <div className="field-label">
                  <div className="field-label-hint">
                    <span>
                      In case of multiple email addresses, you can separate them
                      by semi-colon
                    </span>
                  </div>
                </div>
              </div>
            </div> */}

        {/* <div className="ui divider"></div> */}

        {/* <div className="fields-container">
          <div className="fields-header">
            <div className="field-label">
              <span>
                Select the type of notification being requested for import file
                processing
                <span className="danger">*</span>
                </span>
                <Info
                  content="Should only failure notifications, or both success and failure notifications be sent"
                  position="top right"
                />
            </div>
          </div>
          <div className="fields-header">
            <div className="field-label-radio">
              <div className="divide-half-radio">
                <FliptRadio
                  className="radio-input"
                  toggle={false}
                  name="internal_notification_type"
                  value={"successful"}
                  checked={internal_notification_type === "successful"}
                  onChange={_updateFields}
                />
                <FliptLabel
                  className="radio-label"
                  description=""
                  label="Only successful"
                />
              </div>
              <div className="divide-half-radio">
                <FliptRadio
                  className="radio-input"
                  toggle={false}
                  name="internal_notification_type"
                  value={"failure"}
                  checked={internal_notification_type === "failure"}
                  onChange={_updateFields}
                />
                <FliptLabel
                  className="radio-label"
                  description=""
                  label="Only failure"
                />
              </div>
            </div>
            <div className="field-label-radio">
              <div className="divide-half-radio">
                <FliptRadio
                  className="radio-input"
                  toggle={false}
                  name="internal_notification_type"
                  value={"successful and failed"}
                  checked={
                    internal_notification_type === "successful and failed"
                  }
                  onChange={_updateFields}
                />
                <FliptLabel
                  className="radio-label"
                  description=""
                  label="Both successful and failed"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="fields-container">
          <div className="fields-header">
            <div className="field-label">
              <span>Enter email address(s) <span className="danger">*</span>
                </span>
                <Info
                  content="Should only failure notifications, or both success and failure notifications be sent"
                  position="top right"
                />
            </div>
          </div>
          <div className="fields-header">
            <FliptInput
              className="field-input"
              name="internal_notification_emails"
              placeholder="Email"
              value={internal_notification_emails}
              onChange={_updateFields}
            />
            <div className="field-label">
              <div className="field-label-hint">
                <span>
                  In case of multiple email addresses, you can separate them by
                  semi-colon
                </span>
              </div>
            </div>
          </div>
        </div> */}

        <div className="fields-container">
          <div className="fields-header">
            <div className="field-label">
              <span>How does the client want to send/receive File?  <span className="danger">*</span></span>
              <Info
                content="Will the PBM be responsible for pulling files, or will client transmit to PBM sFTP site"
                position="top right"
              />
            </div>
            <div className="field-label-radio">
              <div className="divide-half-radio">
                <FliptRadio
                  className="radio-input"
                  toggle={false}
                  name="send_receieve_files_responsibility"
                  value={"Client to push"}
                  checked={
                    send_receieve_files_responsibility === "Client to push"
                  }
                  onChange={_updateFields}
                />
                <FliptLabel
                  className="radio-label"
                  description=""
                  label="Client to push/pull files"
                />
                {showErrors &&
                  !validationResults?.send_receieve_files_responsibility && (
                    <section className="flipt-input-error">
                      <em>
                        {validations.send_receieve_files_responsibility
                          ?.errorMessage || null}
                      </em>
                    </section>
                  )}
              </div>
              <div className="divide-half-radio">
                <FliptRadio
                  className="radio-input"
                  toggle={false}
                  name="send_receieve_files_responsibility"
                  value={"PBM to push"}
                  checked={send_receieve_files_responsibility === "PBM to push"}
                  onChange={_updateFields}
                />
                <FliptLabel
                  className="radio-label"
                  description=""
                  label="PBM to push/pull files"
                />
              </div>
            </div>
          </div>
          <div className="fields-header">

          </div>
        </div>

        {send_receieve_files_responsibility === "Client to push" && (
          <>
            <div className="fields-container">
              <div className="fields-header">
                <div className="field-label">
                  <span>Will the client be utilizing the PBM SFTP site?</span>
                  <Info
                    content="Will the PBMs sFTP be used, or will a client sFTP be used for file transmission"
                    position="top right"
                  />
                </div>
                <div className="field-label-radio">
                  <div className="divide-half-radio">
                    <FliptRadio
                      className="radio-input"
                      toggle={false}
                      name="pbm_sftp_used"
                      value={"Yes"}
                      checked={pbm_sftp_used === "Yes"}
                      onChange={_updateFields}
                    />
                    <FliptLabel
                      className="radio-label"
                      description=""
                      label="Yes"
                    />
                  </div>
                  <div className="divide-half-radio">
                    <FliptRadio
                      className="radio-input"
                      toggle={false}
                      name="pbm_sftp_used"
                      value={"No"}
                      checked={pbm_sftp_used === "No"}
                      onChange={_updateFields}
                    />
                    <FliptLabel
                      className="radio-label"
                      description=""
                      label="No"
                    />
                  </div>
                </div>
              </div>
              <div className="fields-header">
                <div className="field-label">
                  <span>Will the Standard PBM SFTP structure be ulitized?</span>
                  <Info
                    content="Will a standard sFTP folder be used, or is a custom sFTP folder defined"
                    position="top right"
                  />
                </div>
                <div className="field-label-radio">
                  <div className="divide-half-radio">
                    <FliptRadio
                      className="radio-input"
                      toggle={false}
                      name="pbm_standard_sftp_structure_used"
                      value={"Yes"}
                      checked={pbm_standard_sftp_structure_used === "Yes"}
                      onChange={_updateFields}
                    />
                    <FliptLabel
                      className="radio-label"
                      description=""
                      label="Yes"
                    />
                  </div>
                  <div className="divide-half-radio">
                    <FliptRadio
                      className="radio-input"
                      toggle={false}
                      name="pbm_standard_sftp_structure_used"
                      value={"No"}
                      checked={pbm_standard_sftp_structure_used === "No"}
                      onChange={_updateFields}
                    />
                    <FliptLabel
                      className="radio-label"
                      description=""
                      label="No"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="fields-container">
              <div className="fields-header">
                <div className="field-label">
                  <span>Enter SFTP site URL </span>
                  <Info
                    content="PBM sFTP URL"
                    position="top right"
                  />
                </div>
                <FliptInput
                  className="field-input"
                  name="pbm_sftp_url"
                  placeholder="Enter SFTP site URL"
                  value={pbm_sftp_url}
                  onChange={_updateFields}
                />
              </div>
              <div className="fields-header">
                <div className="field-label">
                  <span>Defiine Client SFTP folder structure</span>
                  <Info
                    content="PBM sFTP Folder location"
                    position="top right"
                  />
                </div>
                <FliptInput
                  className="field-input"
                  name="pbm_sftp_folder_structure"
                  placeholder="Folder Structure"
                  value={pbm_sftp_folder_structure}
                  onChange={_updateFields}
                />
                <div className="field-label">
                  <div className="field-label-hint">
                    <span>Example: /client name, /Manual (test folder)</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="fields-container">
              <div className="fields-header">
                <div className="field-label">
                  <span>Enter SFTP site Username</span>
                  <Info
                    content="PBM sFTP User Name"
                    position="top right"
                  />
                </div>
                <FliptInput
                  className="field-input"
                  name="pbm_sftp_user_name"
                  placeholder="Enter SFTP site Username"
                  value={pbm_sftp_user_name}
                  onChange={_updateFields}
                />
              </div>
              <div className="fields-header">
                <div className="field-label">
                  <span>Enter SFTP site Password</span>
                  <Info
                    content="PBM sFTP Password"
                    position="top right"
                  />
                </div>
                <FliptInput
                  className="field-input"
                  name="pbm_sftp_password"
                  placeholder="Enter SFTP site Password"
                  value={pbm_sftp_password}
                  onChange={_updateFields}
                />
              </div>
            </div>
          </>
        )}
        {renderAdditionalFields()}
      </div>

      {send_receieve_files_responsibility === "PBM to push" && (
        <>
          <div className="section-header">Technical Contact</div>
          <div className="content shadow margin-bottom">
            <div className="fields-container">
              <div className="fields-header">
                <div className="field-label">
                  <span>File Type</span>
                  <Info
                    content="Technical Contact's First Name"
                    position="top right"
                  />
                </div>
                <FliptInput
                  className="field-input"
                  name="technical_contact_file_type"
                  placeholder="File Type"
                  value={technical_contact_file_type}
                  onChange={_updateFields}
                />
              </div>

              <div className="fields-header">
                <div className="field-label">
                  <span>File Name</span>
                  <Info
                    content="Technical Contact's Last Name"
                    position="top right"
                  />
                </div>
                <FliptInput
                  className="field-input"
                  name="technical_contact_file_name"
                  placeholder="File Name"
                  value={technical_contact_file_name}
                  onChange={_updateFields}
                />
              </div>
            </div>

            <div className="fields-container">
              <div className="fields-header">
                <div className="field-label">
                  <span>Phone Number</span>
                  <Info
                    content="Technical Contact's Phone Number"
                    position="top right"
                  />
                </div>
                <FliptInput
                  className="field-input"
                  name="technical_contact_phone_number"
                  placeholder="Phone Number"
                  value={technical_contact_phone_number}
                  onChange={_updateFields}
                />
              </div>

              <div className="fields-header">
                <div className="field-label">
                  <span>Email Address </span>
                  <Info
                    content="Technical Contact's Email"
                    position="top right"
                  />
                </div>
                <FliptInput
                  className="field-input"
                  name="technical_contact_email"
                  placeholder="Email Address"
                  value={technical_contact_email}
                  onChange={_updateFields}
                />
              </div>
            </div>

            <div className="fields-container">
              <div className="fields-header">
                <div className="field-label">
                  <span>Enter sFTP site URL </span>
                  <Info
                    content="URL of client's sFTP site"
                    position="top right"
                  />
                </div>
                <FliptInput
                  className="field-input"
                  name="technical_contact_external_sftp_url"
                  placeholder="https://www.example.com"
                  value={technical_contact_external_sftp_url}
                  onChange={_updateFields}
                />
              </div>
              <div className="fields-header">
                <div className="field-label">
                  <div className="divide-half">
                    <div className="field-label">
                      <span>Enter sFTP site Username</span>
                      <Info
                        content="User Name for client's sFTP site"
                        position="top right"
                      />
                    </div>
                    <FliptInput
                      className="field-input"
                      name="technical_contact_external_sftp_user_name"
                      placeholder="PlaceHolder "
                      value={technical_contact_external_sftp_user_name}
                      onChange={_updateFields}
                    />
                  </div>
                  <div className="divide-half">
                    <div className="field-label">
                      <span>Enter sFTP site Password</span>
                      <Info
                        content="Password for client's sFTP site"
                        position="top right"
                      />
                    </div>
                    <FliptInput
                      className="field-input"
                      name="technical_contact_external_sftp_password"
                      placeholder="PlaceHolder "
                      value={technical_contact_external_sftp_password}
                      onChange={_updateFields}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="fields-container">
              <div className="fields-header">
                <div className="field-label">
                  <span>
                    Does the client have a separate folder or sFTP site for test
                    files
                  </span>
                  <Info
                    content="Does the client use a separate test sFTP"
                    position="top right"
                  />
                </div>
              </div>
              <div className="fields-header">
                <div className="field-label-radio">
                  <div className="divide-half-radio">
                    <FliptRadio
                      className="radio-input"
                      toggle={false}
                      name="technical_contact_external_test_sftp_ind"
                      value={"Yes"}
                      checked={
                        technical_contact_external_test_sftp_ind === "Yes"
                      }
                      onChange={_updateFields}
                    />
                    <FliptLabel
                      className="radio-label"
                      description=""
                      label="Yes"
                    />
                  </div>
                  <div className="divide-half-radio">
                    <FliptRadio
                      className="radio-input"
                      toggle={false}
                      name="technical_contact_external_test_sftp_ind"
                      value={"No"}
                      checked={
                        technical_contact_external_test_sftp_ind === "No"
                      }
                      onChange={_updateFields}
                    />
                    <FliptLabel
                      className="radio-label"
                      description=""
                      label="No"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="fields-container">
              <div className="fields-header">
                <div className="field-label">
                  <span>Enter Test SFTP Site URL </span>
                  <Info
                    content="URL of client's test sFTP site"
                    position="top right"
                  />
                </div>
                <FliptInput
                  className="field-input"
                  name="technical_contact_external_test_sftp_url"
                  placeholder="https://www.example.com"
                  value={technical_contact_external_test_sftp_url}
                  onChange={_updateFields}
                />
              </div>
              <div className="fields-header">
                <div className="field-label">
                  <div className="divide-half">
                    <div className="field-label">
                      <span>Enter Test sFTP Site Username</span>
                      <Info
                        content="User Name for client's test sFTP site"
                        position="top right"
                      />
                    </div>
                    <FliptInput
                      className="field-input"
                      name="technical_contact_external_test_sftp_user_name"
                      placeholder="PlaceHolder "
                      value={technical_contact_external_test_sftp_user_name}
                      onChange={_updateFields}
                    />
                  </div>
                  <div className="divide-half">
                    <div className="field-label">
                      <span>Enter Test sFTP Site Password</span>
                      <Info
                        content="Password for client's test sFTP site"
                        position="top right"
                      />
                    </div>
                    <FliptInput
                      className="field-input"
                      name="technical_contact_external_test_password"
                      placeholder="PlaceHolder "
                      value={technical_contact_external_test_password}
                      onChange={_updateFields}
                    />
                  </div>
                </div>
              </div>
            </div>

          </div>
        </>
      )}

    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    state: {
      app: state.app,
      emailInformation: state.fileConfiguration.emailInformation,
      validations: state.fileConfiguration.validations.emailInformation,
      validationResults:
        state.fileConfiguration.validationResults.emailInformation,
      additionalFields: state.fileConfiguration.additional_fields?.find(row => row.page === 'email_and_sftp')?.fields || [],
      // test data below, to be removed 
      //  additionalFields: sampleData?.find(row => row.page === 'email_and_sftp')?.fields || [],

    },
  }
}

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
    ...FileConfigurationActions,
  };

  return {
    actions: bindActionCreators(allActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailInformation);
