import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import './styles.scss'
import { Creators as ApiActions } from '../../../../../redux/reducers/api/bulkReprocessingRestacking'
import FliptLabel from '../../../../../components/form/fliptLabel'
import FliptDatePicker from '../../../../../components/form/fliptDatePicker'
import FliptButton from '../../../../../components/form/fliptButton'
import FliptGrid from '../../../../../components/fliptGrid'
import FliptTable from '../../../../../components/fliptTable'
import { DropdownRenderer } from '../../../../../components/fliptGrid/cellRenderers'
import { convertStrToDateObj } from '../../../../../utils/utilities'
import * as Constants from '../../constants'

class FileImport extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedFile: '',
      isUploaded: false,
      columns: [],
      start_date: '',
      end_date: '',
    }
  }

  componentDidUpdate(prevProps) {
    const { state: { batchInputFileData } } = this.props
    if (prevProps.state.batchInputFileData !== batchInputFileData) {
      let newCols = []
      if (batchInputFileData.header_field_mapping) {
        const { header_field_mapping } = batchInputFileData
        Object.keys(header_field_mapping).map((key, index) => {
          newCols.push({ "FAST_field": key, "selected_file_field": header_field_mapping[key] })
        })
        this.setState({
          columns: newCols
        })
      }
    }
  }

  handleInputChange = (el, dropdown) => {
    const { name, value } = dropdown || el.currentTarget
    if (name === 'end_date' && value) {
      value.setDate(value.getDate() + 1)
      value.setMilliseconds(value.getMilliseconds() - 1)
    }
    this.setState({ [name]: value })
  }

  setFilePath = (e) => {
    this.setState({
      selectedFile: e.target.files[0]
    })
  }

  handleUpload = () => {
    const { actions, field_type } = this.props
    actions.uploadBatchInputFile({
      selectedFile: this.state.selectedFile,
      field_type,
    })
    this.setState({
      isUploaded: true,
    })
  };


  handleGetClaims = () => {
    const { form, updateForm, field_type, state: { batchInputFileData } } = this.props
    const { batch_input_columns, batch_input_data } = batchInputFileData
    if (!batch_input_columns.length) return
    const { columns } = this.state
    const selected_field = columns[0]['selected_file_field']
    let input_list = []
    batch_input_data.forEach((record) => {
      if (record[selected_field])
        input_list.push(String(record[selected_field]))
    })
    if (field_type === 'auth_id') {
      input_list = input_list.map((authId) => {
        return authId.padStart(13, '0');
      })
    }
    if (input_list?.length) form[0][selected_field] = input_list.join(',')
    updateForm(form)
  }

  render() {
    const { state: { batchInputFileData } } = this.props
    const { isUploaded } = this.state


    let options = []
    if (batchInputFileData.batch_input_columns) {
      options = batchInputFileData.batch_input_columns.map((code, index) => ({
        value: code,
        text: code,
        index: index,
      }))
    }

    return (
      <div id='file-import-section'>
        <div className='file-import-header'>
          File Import
        </div>
        <hr />
        <div className='file-import-container'>
          <div className='flipt-label'>
            <FliptLabel label={'File Input'} />
          </div>
          <span>Select a file in xlsx or csv format: </span>
          <input className='file-input' type='file' name='file' id='file' onChange={this.setFilePath} />
          <FliptButton name="Upload" onClick={this.handleUpload} className="primary search-button" />
        </div>
        <div className='file-import-dates'>
          <FliptButton
            name='Submit'
            disabled={!isUploaded}
            className='primary search-button'
            onClick={this.handleGetClaims} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    state: {
      batchInputFileData: state.bulkReprocessingRestacking.batchInputFileData,
    },
  }
}

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...ApiActions
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FileImport)