import Immutable from 'seamless-immutable'
import { createActions, createReducer } from 'reduxsauce'

export const INITIAL_STATE = Immutable({
  loading: false,
  surescriptFormularyConfigurationData:[],
  currentFormularyConfigurationData: null,
  popupData: {},
  // surescriptPaData:[],
  // surescriptConfigTotalRows: 0,
  // surescriptcongigPerPage: 20,
  // surescriptConfigSuggestData: [],
  // surescriptUpdateData:[{"surescripts_formulary_id":"",surescript_prior_authorization_id:""}],
})

export const clearSurescriptConfigDataReducer = (state = INITIAL_STATE) => ({
  ...state,
  surescriptFormularyConfigurationData: [],
})
export const clearCurrentSurescriptConfigDataReducer = (state = INITIAL_STATE) => ({
  ...state,
  currentFormularyConfigurationData: null,
})

export const clearReducer = () => INITIAL_STATE

export const setSurescriptConfigDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  surescriptFormularyConfigurationData: payload.data,
})

export const setCurrentFormularyConfigDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  currentFormularyConfigurationData: payload,
})

export const setPopupDataReducer = (state = INITIAL_STATE, { payload }) => {
  return {
    ...state,
    popupData: payload,
  }
}

export const setLoadingReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  loading: payload.data,
})

export const { Types, Creators } = createActions({
  clearSurescriptConfigData: null,
  clearReducer: null,
  clearCurrentSurescriptConfigData: null,
  setSurescriptConfigData: ['payload'],
  setLoading: ['payload'],
  getSurescriptConfigData: ['payload'],
  updateSurescriptConfigData: ['payload'],
  insertSurescriptConfigData: ['payload'],
  setCurrentFormularyConfigData: ['payload'],
  setPopupData: ['payload'],
})

const HANDLERS = {
  [Types.CLEAR_REDUCER]: clearReducer,
  [Types.CLEAR_SURESCRIPT_CONFIG_DATA]: clearSurescriptConfigDataReducer,
  [Types.CLEAR_CURRENT_SURESCRIPT_CONFIG_DATA]: clearCurrentSurescriptConfigDataReducer,
  [Types.SET_SURESCRIPT_CONFIG_DATA]: setSurescriptConfigDataReducer,
  [Types.SET_LOADING]: setLoadingReducer,
  [Types.SET_CURRENT_FORMULARY_CONFIG_DATA]: setCurrentFormularyConfigDataReducer,
  [Types.SET_POPUP_DATA]: setPopupDataReducer,
}

export default createReducer(INITIAL_STATE, HANDLERS)
