import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import './styles.scss'
import BreadCrumb from '../../../components/breadCrumb'
import FliptButton from '../../../components/form/fliptButton'
import FliptInput from '../../../components/form/fliptInput'
import FliptGrid from '../../../components/fliptGrid'
import { Creators as AppActions } from '../../../redux/reducers/app'
import { Creators as MedispanLookupActions } from '../../../redux/reducers/api/medispanLookup'
import { ButtonRenderer } from '../../../components/fliptGrid/cellRenderers'

class MedispanLookup extends Component {
  constructor(props) {
    super(props)

    this.state = {
      form: {
        ndc: '',
        drug_name: '',
        gpi: '',
      },
      currHistory: '',
      showTabFlag: false,
    }
  }

  _medispanSearch = () => {
    const { state, props } = this
    const { form } = state
    props.actions.clearMedispanLookupData();
    const params = {}
    if (form.ndc) params['ndc'] = form.ndc
    if (form.drug_name) params['drug_name'] = form.drug_name
    if (form.gpi) params['gpi'] = form.gpi
    props.actions.getMedispanLookupData(params);
  }

  _clearSearch = () => {
    const form = {
      ndc: '',
      drug_name: '',
      gpi: '',
    }
    this.setState({ form })
  }

  _updateFields = (el, dropdown) => {
    const { form } = this.state
    const { name, value } = dropdown || el.currentTarget
    if (name === 'ndc') {
      form.drug_name = ''
      form.gpi = ''
    }
    if (name === 'drug_name') {
      form.ndc = ''
      form.gpi = ''
    }
    if (name === 'gpi') {
      form.drug_name = ''
      form.ndc = ''
    }
    form[name] = value.toUpperCase()
    this.setState({ form })
  }
  onPriceHistorySelect = (e) => {
    const { props } = this
    this.setState({ currHistory: e.price_type, showTabFlag: true })
  }

  render() {
    const { state, props } = this
    const {
      form,
    } = state
    const {
      ndc, drug_name, gpi
    } = form
    const {
      state: {
        medispanLookupData
      },
    } = props
    const headers = [
      'ndc', 'mfg', 'drug_name', 'brand_generic', 'multi_source', 'gpi', 'drug_group', 'drug_class',
      'drug_subclass', 'dosage', 'dosage_strength', 'ddid', 'pkg_uom', 'unit_dose',
      'package_qty', 'awp_unit_price', 'awp_effective_date', 'NADAC_unit_price', 'NADAC_effective_date', 'price_history',
      'otc_indicator', 'maintenance_drug_code'
    ]
    const currentType = state.currHistory == 'AWP' ? state.currHistory.toLowerCase() : state.currHistory
    let popupCellRendererParams = {
      ndc: {
        overrideHeader: 'Product ID',
      },
      drug_name: {
        overrideHeader: 'Product Name',
      },
      price_type: {
        overrideHeader: 'Price Type',
      },
      [`${currentType}_unit_price`]: {
        overrideHeader: 'Price',
      },
      [`${currentType}_effective_date`]: {
        overrideHeader: `${state.currHistory} Effective Date`,
      },
    }

    const cellRendererParams = {
      ndc: {
        overrideHeader: 'Product ID'
      },
      mfg: {
        overrideHeader: 'Manufacturer Name'
      },
      drug_name: {
        overrideHeader: 'Product Name'
      },
      brand_generic: {
        overrideHeader: 'Trademark Code'
      },
      multi_source: {
        overrideHeader: 'Multi-Source Code'
      },
      gpi: {
        overrideHeader: 'GPI ID'
      },
      dosage: {
        overrideHeader: 'Dosage Form'
      },
      dosage_strength: {
        overrideHeader: 'Strength'
      },
      pkg_uom: {
        overrideHeader: 'Unit of Measure'
      },
      maintenance_drug_code: {
        overrideHeader: 'Maintenance Indicator'
      },
      dosage: {
        overrideHeader: 'Dosage Form'
      },
      price_history: {
        cellRenderer: ButtonRenderer,
        searchArgs: { onClick: this.onPriceHistorySelect, text: 'History', className: 'linkButton' },
        width: 200,
        overrideHeader: 'Price History'
      },
    }

    return (
      <div>
      <div id="medispanLookup">
        <BreadCrumb {...this.props} />
        <div className="header">Medispan Lookup</div>
        <div className="subhead">
          <FliptInput placeholder="NDC" name="ndc" value={ndc} onChange={this._updateFields} />
          <FliptInput placeholder="Drug Name" name="drug_name" value={drug_name} onChange={this._updateFields} />
          <FliptInput placeholder="GPI" name="gpi" value={gpi} onChange={this._updateFields} />
          <FliptButton name="Search" className="primary searchButton" onClick={() => this._medispanSearch(false)} />
          <FliptButton name="Clear" className="clearButton" onClick={() => this._clearSearch(false)} />
        </div>

        <div className="content">
          <FliptGrid
            headers={headers}
            cellRendererParams={cellRendererParams}
            data={medispanLookupData}
          />
        </div>

      </div> 
      {state.showTabFlag ? 
      <div id="medispanLookupPopup">
        <div className="content popup">
          <div className="popupTable">
            <FliptGrid
              headers={["ndc", 
                "drug_name",
                "price_type",
                `${currentType}_unit_price`,
                `${currentType}_effective_date`,
                ]}
              data={props.state.medispanHistoryData[state.currHistory]}
              cellRendererParams={popupCellRendererParams}
            />
          </div>
          <div className="buttonContainer">
            <FliptButton name="Quit" className="secondary searchButton" onClick={()=> this.setState({showTabFlag: false})} />
          </div>
        </div>
      </div>
      : ""
      }
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  state: {
    app: state.app,
    loading: state.medispanLookup.loading,
    medispanLookupData: state.medispanLookup.medispanLookupData,
    medispanHistoryData: state.medispanLookup.medispanHistoryData,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
    ...MedispanLookupActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MedispanLookup)
