import React, { PureComponent } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Creators as IntegrationManagementActions } from "../../../../redux/reducers/api/integrationManagement";
import { Creators as FileConfigurationActions } from "../../../../redux/reducers/api/fileConfiguration";
import "./styles.scss";
import { Creators as AppActions } from "../../../../redux/reducers/app";

import BreadCrumb from "../../../../components/breadCrumb";
import FliptDropdown from "../../../../components/form/fliptDropdown";
import FliptPaDropdown from "../../../../components/form/fliptPaDropdown";
import FliptDatePicker from "../../../../components/form/fliptDatePicker";
import FliptPaInput from "../../../../components/form/fliptPaInput";
import FliptInput from "../../../../components/form/fliptInput";
import { Button, Icon, Popup, Form } from "semantic-ui-react";
import { convertStrToDateObj } from "../../../../utils/utilities";
import { lastEventId } from "@sentry/react";
import { renderDynamicInput, sampleData } from "./utils";
import Info from "./Info";

const ClientInformation = (props) => {
  const validations = props.state.validations;
  const { entityList } = props.state.createFileConfigOptions;
  const validationResults = props.state.validationResults;
  const showErrors =
    validationResults && validationResults && !validationResults.isAllValid;

  const _updateFields = (el, dropdown) => {
    const { name, value } = dropdown || el.currentTarget;
    props.actions.updateClientInformationForm({ name, value });
    if (name == "entity_name") {
      props.actions.updateFullFileConfiguration({ name, value });
    }
  };

  const { clientInformation } = props.state;
  const { clientType, stateList, fileType } =
    props.state.clientInformationOptions;
  const {
    client,
    type_of_client,
    client_proposed_go_live,
    client_first_test_file,
    client_first_name,
    client_last_name,
    client_title,
    client_phone_number,
    client_fax_number,
    client_email,
    client_address_1,
    client_address_2,
    client_city,
    client_zip_code,
    client_state,
    entity_name,
    integration_partner_name,
  } = clientInformation;
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };

  const renderAdditionalFields = () => {
    const { fileStandard, additionalFields } = props.state;
    if (additionalFields?.length == 0) return null;
    const rowDivider = 3;
    const fieldProps = {
      ...clientInformation,
      onChange: _updateFields,
      className: 'fields-client-header'
    };
    return (
      <>
        {Array.from(
          { length: Math.ceil(additionalFields.length / rowDivider) },
          (_, i) => (
            <div className="fields-container" key={`row${i}`}>
              {additionalFields
                .slice(i * rowDivider, (i + 1) * rowDivider)
                .map((movie) => renderDynamicInput(movie, fieldProps))}
            </div>
          )
        )}
      </>
    );
  };

  return (
    <>
      <div id="file-configuration">
        <div className="section-header">Client Contact Information</div>
        <div className="content shadow margin-bottom">
          <div className="fields-container">
            <div className="fields-client-header">
              <div className="field-label">
                <span>
                  Type of Integration Partner <span className="danger">*</span>
                </span>
                <Info
                  content="Client Type of the client"
                  position="top right"
                />
              </div>
              <FliptPaDropdown
                stylized
                name="entity_name"
                onChange={_updateFields}
                simple
                className="half-width"
                style={{ zIndex: 99 }}
                value={entity_name}
                options={entityList}
                placeholder="Entity Name"
              />
            </div>
            <div className="fields-client-header">
              <div className="field-label">
                <span>Integration Partner Name <span className="danger">*</span></span>
                <Info content="Name of company or organization" position="top right" />
              </div>
              <FliptInput
                className="field-input"
                name="integration_partner_name"
                placeholder="Partner Name"
                value={integration_partner_name || ''}
                onChange={_updateFields}
                validation="name"
                required
                error={
                  (showErrors &&
                    !validationResults?.integration_partner_name &&
                    validations.integration_partner_name?.errorMessage) ||
                  null
                }
              />
            </div>
          </div>
          <div className="fields-container">
            <div className="fields-client-header">
              <div className="field-label">
                <span>Job Title</span>
                <Info content="Client Contact's Title" position="top right" />
              </div>
              <FliptInput
                className="field-input"
                name="client_title"
                placeholder="Job Title"
                value={client_title}
                onChange={_updateFields}
                validation="name"
                required
                error={
                  (showErrors &&
                    !validationResults?.client_title &&
                    validations.client_title?.errorMessage) ||
                  null
                }
              />
            </div>
            <div className="fields-client-header">
              <div className="field-label">
                <span>
                  First Name <span className="danger">*</span>
                </span>
                {/* <Icon name="question circle outline" /> */}
                <Info
                  content="Client Contact's First Name"
                  position="top right"
                />
              </div>
              <FliptInput
                className="field-input"
                name="client_first_name"
                placeholder="First Name"
                value={client_first_name}
                onChange={_updateFields}
                validation="name"
                required
                error={
                  (showErrors &&
                    !validationResults?.client_first_name &&
                    validations.client_first_name?.errorMessage) ||
                  null
                }
              />
            </div>
            <div className="fields-client-header">
              <div className="field-label">
                <span>
                  Last Name <span className="danger">*</span>
                </span>
                <Info
                  content="Client Contact's Last Name"
                  position="top right"
                />
              </div>
              <FliptInput
                className="field-input"
                name="client_last_name"
                placeholder="Last Name"
                value={client_last_name}
                onChange={_updateFields}
                validation="name"
                required
                error={
                  (showErrors &&
                    !validationResults?.client_last_name &&
                    validations.client_last_name?.errorMessage) ||
                  null
                }
              />
            </div>
          </div>
          <div className="fields-container">
            <div className="fields-client-header">
              <div className="field-label">
                <span>
                  Phone Number <span className="danger">*</span>
                </span>
                <Info
                  content="Client Contact's telephone Number"
                  position="top right"
                />
              </div>
              <FliptInput
                className="field-input"
                name="client_phone_number"
                placeholder="Phone Number"
                value={client_phone_number}
                onChange={_updateFields}
                validation="numeric"
                required
                maxLength="10"
                error={
                  (showErrors &&
                    !validationResults?.client_phone_number &&
                    validations.client_phone_number?.errorMessage) ||
                  null
                }
              />
            </div>
            <div className="fields-client-header">
              <div className="field-label">
                <span>Fax Number</span>
                <Info
                  content="Client Contact's Fax Number"
                  position="top right"
                />
              </div>
              <FliptInput
                className="field-input"
                name="client_fax_number"
                placeholder="Fax Number"
                value={client_fax_number}
                onChange={_updateFields}
                validation="numeric"
                required
                maxLength="10"
                error={
                  (showErrors &&
                    !validationResults?.client_fax_number &&
                    validations.client_fax_number?.errorMessage) ||
                  null
                }
              />
            </div>
            <div className="fields-client-header">
              <div className="field-label">
                <span>
                  Email Address <span className="danger">*</span>
                </span>
                <Info
                  content="Client Contact's Email Address"
                  position="top right"
                />
              </div>
              <FliptInput
                className="field-input"
                name="client_email"
                placeholder="Email Address"
                value={client_email}
                onChange={_updateFields}
                validation="email_address"
                required
                error={
                  (showErrors &&
                    !validationResults?.client_email &&
                    validations.client_email?.errorMessage) ||
                  null
                }
              />
            </div>
          </div>
          <div className="fields-container">
            <div className="fields-client-header">
              <div className="field-label">
                <span>Address 1</span>
                <Info
                  content="Client Contact's Mailing Address"
                  position="top right"
                />
              </div>
              <FliptInput
                className="field-input"
                name="client_address_1"
                placeholder="Address 1"
                value={client_address_1}
                onChange={_updateFields}
                error={
                  (showErrors &&
                    !validationResults?.client_address_1 &&
                    validations.client_address_1?.errorMessage) ||
                  null
                }
              />
            </div>
            <div className="fields-client-header">
              <div className="field-label">
                <span>Address 2</span>
                <Info
                  content="Client Contact's Second Address"
                  position="top right"
                />
              </div>
              <FliptInput
                className="field-input"
                name="client_address_2"
                placeholder="Address 2"
                value={client_address_2}
                onChange={_updateFields}
                error={
                  (showErrors &&
                    !validationResults?.client_address_2 &&
                    validations.client_address_2?.errorMessage) ||
                  null
                }
              />
            </div>
          </div>
          <div className="fields-container" style={{ marginBottom: '40px' }}>
            <div className="fields-client-header">
              <div className="field-label">
                <span>City</span>
                <Info content="Client Contact's City" position="top right" />
              </div>
              <FliptInput
                className="field-input"
                name="client_city"
                placeholder="City"
                value={client_city}
                onChange={_updateFields}
                validation="name"
                required
                error={
                  (showErrors &&
                    !validationResults?.client_city &&
                    validations.client_city?.errorMessage) ||
                  null
                }
              />
            </div>
            <div className="fields-client-header">
              <div className="field-label">
                <span>State</span>
                <Info
                  content="Client Contact's State Abbreviation"
                  position="top right"
                />
              </div>
              <FliptPaDropdown
                stylized
                name="client_state"
                onChange={_updateFields}
                className="half-width"
                value={client_state}
                options={stateList}
                placeholder="State"
              />
            </div>
            <div className="fields-client-header">
              <div className="field-label">
                <span>Zipcode</span>
                <Info
                  content="Client Contact's Zip Code or Zip+4"
                  position="top right"
                />
              </div>
              <FliptInput
                className="field-input"
                name="client_zip_code"
                placeholder="Zipcode "
                value={client_zip_code}
                onChange={_updateFields}
                validation="numeric"
                required
                maxLength="5"
                error={
                  (showErrors &&
                    !validationResults?.client_zip_code &&
                    validations.client_zip_code?.errorMessage) ||
                  null
                }
              />
            </div>
          </div>
          {renderAdditionalFields()}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const fileStandard =
    state.fileConfiguration[state.fileConfiguration.file_type + "_standard"];
  return {
    state: {
      app: state.app,
      integrationMgmtDashboardData:
        state.integrationMgmt.integrationMgmtDashboardData,
      clientInformation: state.fileConfiguration.clientInformation,
      clientInformationOptions:
        state.fileConfiguration.options.clientInformation,
      validations: state.fileConfiguration.validations.clientInformation,
      validationResults:
        state.fileConfiguration.validationResults.clientInformation,
      createFileConfigOptions: state.fileConfiguration.options.createFileConfig,
      fileStandard: fileStandard,
      additionalFields: state.fileConfiguration.additional_fields?.find(row => row.page === 'client_information')?.fields || [],
      // test data below, to be removed
      // additionalFields:
      //   sampleData?.find((row) => row.page === "client_information")?.fields ||
      //   [],
    },
  };
};

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
    ...FileConfigurationActions,
    ...IntegrationManagementActions,
  };

  return {
    actions: bindActionCreators(allActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientInformation);
