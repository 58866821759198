// eslint-disable-next-line react/no-unstable-nested-components
export default {
  lics_plan_info: {
    doc_name: '',
    doc_version: '1.0',
  },
  phases: [
    {
      phase: 'Deductible',
      lics_level: [
        {
          lics_level: '1',
          lics_deductible: 0,
          lics_brand_cost_share: 0,
          lics_brand_cost_share_type: '',
          lics_minimum_brand_cost_share: null,
          lics_maximum_brand_cost_share: null,
          lics_generic_cost_share: 0,
          lics_generic_cost_share_type: '',
          lics_minimum_generic_cost_share: null,
          lics_maximum_generic_cost_share: null,
        },
        {
          lics_level: '2',
          lics_deductible: 0,
          lics_brand_cost_share: 0,
          lics_brand_cost_share_type: '',
          lics_minimum_brand_cost_share: null,
          lics_maximum_brand_cost_share: null,
          lics_generic_cost_share: 0,
          lics_generic_cost_share_type: '',
          lics_minimum_generic_cost_share: null,
          lics_maximum_generic_cost_share: null,
        },
        {
          lics_level: '3',
          lics_deductible: 0,
          lics_brand_cost_share: 0,
          lics_brand_cost_share_type: '',
          lics_minimum_brand_cost_share: null,
          lics_maximum_brand_cost_share: null,
          lics_generic_cost_share: 0,
          lics_generic_cost_share_type: '',
          lics_minimum_generic_cost_share: null,
          lics_maximum_generic_cost_share: null,
        },
        {
          lics_level: '4',
          lics_deductible: 0,
          lics_brand_cost_share: 0,
          lics_brand_cost_share_type: '',
          lics_minimum_brand_cost_share: null,
          lics_maximum_brand_cost_share: null,
          lics_generic_cost_share: 0,
          lics_generic_cost_share_type: '',
          lics_minimum_generic_cost_share: null,
          lics_maximum_generic_cost_share: null,
        },
      ],
    },
    {
      phase: 'Initial Coverage',
      lics_level: [
        {
          lics_level: '1',
          lics_deductible: 0,
          lics_brand_cost_share: 0,
          lics_brand_cost_share_type: '',
          lics_minimum_brand_cost_share: null,
          lics_maximum_brand_cost_share: null,
          lics_generic_cost_share: 0,
          lics_generic_cost_share_type: '',
          lics_minimum_generic_cost_share: null,
          lics_maximum_generic_cost_share: null,
        },
        {
          lics_level: '2',
          lics_deductible: 0,
          lics_brand_cost_share: 0,
          lics_brand_cost_share_type: '',
          lics_minimum_brand_cost_share: null,
          lics_maximum_brand_cost_share: null,
          lics_generic_cost_share: 0,
          lics_generic_cost_share_type: '',
          lics_minimum_generic_cost_share: null,
          lics_maximum_generic_cost_share: null,
        },
        {
          lics_level: '3',
          lics_deductible: 0,
          lics_brand_cost_share: 0,
          lics_brand_cost_share_type: '',
          lics_minimum_brand_cost_share: null,
          lics_maximum_brand_cost_share: null,
          lics_generic_cost_share: 0,
          lics_generic_cost_share_type: '',
          lics_minimum_generic_cost_share: null,
          lics_maximum_generic_cost_share: null,
        },
        {
          lics_level: '4',
          lics_deductible: 0,
          lics_brand_cost_share: 0,
          lics_brand_cost_share_type: '',
          lics_minimum_brand_cost_share: null,
          lics_maximum_brand_cost_share: null,
          lics_generic_cost_share: 0,
          lics_generic_cost_share_type: '',
          lics_minimum_generic_cost_share: null,
          lics_maximum_generic_cost_share: null,
        },
      ],
    },
    {
      phase: 'Coverage Gap',
      lics_level: [
        {
          lics_level: '1',
          lics_deductible: 0,
          lics_brand_cost_share: 0,
          lics_brand_cost_share_type: '',
          lics_minimum_brand_cost_share: null,
          lics_maximum_brand_cost_share: null,
          lics_generic_cost_share: 0,
          lics_generic_cost_share_type: '',
          lics_minimum_generic_cost_share: null,
          lics_maximum_generic_cost_share: null,
        },
        {
          lics_level: '2',
          lics_deductible: 0,
          lics_brand_cost_share: 0,
          lics_brand_cost_share_type: '',
          lics_minimum_brand_cost_share: null,
          lics_maximum_brand_cost_share: null,
          lics_generic_cost_share: 0,
          lics_generic_cost_share_type: '',
          lics_minimum_generic_cost_share: null,
          lics_maximum_generic_cost_share: null,
        },
        {
          lics_level: '3',
          lics_deductible: 0,
          lics_brand_cost_share: 0,
          lics_brand_cost_share_type: '',
          lics_minimum_brand_cost_share: null,
          lics_maximum_brand_cost_share: null,
          lics_generic_cost_share: 0,
          lics_generic_cost_share_type: '',
          lics_minimum_generic_cost_share: null,
          lics_maximum_generic_cost_share: null,
        },
        {
          lics_level: '4',
          lics_deductible: 0,
          lics_brand_cost_share: 0,
          lics_brand_cost_share_type: '',
          lics_minimum_brand_cost_share: null,
          lics_maximum_brand_cost_share: null,
          lics_generic_cost_share: 0,
          lics_generic_cost_share_type: '',
          lics_minimum_generic_cost_share: null,
          lics_maximum_generic_cost_share: null,
        },
      ],
    },
    {
      phase: 'Catastrophic',
      lics_level: [
        {
          lics_level: '1',
          lics_deductible: 0,
          lics_brand_cost_share: 0,
          lics_brand_cost_share_type: '',
          lics_minimum_brand_cost_share: null,
          lics_maximum_brand_cost_share: null,
          lics_generic_cost_share: 0,
          lics_generic_cost_share_type: '',
          lics_minimum_generic_cost_share: null,
          lics_maximum_generic_cost_share: null,
        },
        {
          lics_level: '2',
          lics_deductible: 0,
          lics_brand_cost_share: 0,
          lics_brand_cost_share_type: '',
          lics_minimum_brand_cost_share: null,
          lics_maximum_brand_cost_share: null,
          lics_generic_cost_share: 0,
          lics_generic_cost_share_type: '',
          lics_minimum_generic_cost_share: null,
          lics_maximum_generic_cost_share: null,
        },
        {
          lics_level: '3',
          lics_deductible: 0,
          lics_brand_cost_share: 0,
          lics_brand_cost_share_type: '',
          lics_minimum_brand_cost_share: null,
          lics_maximum_brand_cost_share: null,
          lics_generic_cost_share: 0,
          lics_generic_cost_share_type: '',
          lics_minimum_generic_cost_share: null,
          lics_maximum_generic_cost_share: null,
        },
        {
          lics_level: '4',
          lics_deductible: 0,
          lics_brand_cost_share: 0,
          lics_brand_cost_share_type: '',
          lics_minimum_brand_cost_share: null,
          lics_maximum_brand_cost_share: null,
          lics_generic_cost_share: 0,
          lics_generic_cost_share_type: '',
          lics_minimum_generic_cost_share: null,
          lics_maximum_generic_cost_share: null,
        },
      ],
    },
  ],
}
