import Immutable from 'seamless-immutable'
import { createActions, createReducer } from 'reduxsauce'

export const INITIAL_STATE = Immutable({
  batchInputFileData: {},
  batchUpdateDetailData: {}
})

export const clearBatchInputReducer = (state = INITIAL_STATE) => ({
  ...state,
  batchInputFileData: {},
})

export const setBatchInputFileDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  batchInputFileData: payload,
})

export const setBatchDetailDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  batchUpdateDetailData: payload,
})

export const { Types, Creators } = createActions({
  clearBatchInput: null,
  getBulkReprocessingRestackingData: ['payload'],
  queueClaims: ['payload'],
  setBatchInputFileData: ['payload'],
  setBatchDetailData: ['payload'],
  uploadBatchInputFile: ['payload'],
})

const HANDLERS = {
  [Types.CLEAR_BATCH_INPUT]: clearBatchInputReducer,
  [Types.SET_BATCH_INPUT_FILE_DATA]: setBatchInputFileDataReducer,
  [Types.SET_BATCH_DETAIL_DATA]: setBatchDetailDataReducer,
}

export default createReducer(INITIAL_STATE, HANDLERS)