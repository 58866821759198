import React from 'react'

import './styles.scss'
export default (props) => {
	const {wizardValues, step} = props

	return (
		<div className="flipt-wizard-container">
			<ul className="fliptwizard">
				{wizardValues.map((wizardValue, index) => {
					return (
						<li
							className={step >= index ? `active ${index > 0 ? ' completed' : ''}` : ''}
							onClick={() => {
								props.setStep(index)
							}}
						>
							{wizardValue}
						</li>
					)
				})}
			</ul>
		</div>
	)
}
