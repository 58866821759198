
/* eslint-disable */
export default {
  doc_name: {
    type: 'input',
    disabled: false,
  },
  workstream: {
    type: 'dropdown',
    disabled: false,
    options: []
  },
  module: {
    type: 'dropdown',
    disabled: false,
    options: []
  },
  status: {
    type: 'input',
    disabled: false,
    options: []
  },
  task_object_type: {
    type: 'dropdown',
    options: [],
    disabled: false,
  },
  task_object_state: {
    type: 'dropdown',
    options: [],
    disabled: false,
  },
  task_objects: {
    type: 'dropdown',
    disabled: false,
    options: [],
    multiple: true,
  },
  task_owner: {
    type: 'dropdown',
    options: [],
    disabled: false,
  },
  task_assignee: {
    type: 'dropdown',
    options: [],
    disabled: false,
  },
  task_begin_date: {
    type: 'date',
    disabled: false,
  },
  task_end_date: {
    type: 'date',
    disabled: false,
  },
  actual_task_begin_date: {
    type: 'input',
    disabled: false,
  },
  actual_task_end_date: {
    type: 'input',
    disabled: false,
  },
  task_notes: {
    type: 'input',
    disabled: false,
    
  }
}
