import React, { PureComponent } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Creators as AppActions } from '../../../../redux/reducers/app'
import { Creators as MemberLookupActions } from '../../../../redux/reducers/api/memberLookup'

import './styles.scss'
import BreadCrumb from '../../../../components/breadCrumb'
import FliptGrid from '../../../../components/fliptGrid';

class PADetails extends PureComponent {
  render() {
    const { state: { memberLookup: { pa_details } } } = this.props
    const headers = [
      'drug_name', 'brand_generic', 'dosage_form', 'gpi','package_qty','pa_override_status', 'pa_decision_date','pa_override_start_date','pa_override_end_date'
    ]
    return (
      <div id="PADetails">
        <BreadCrumb {...this.props} />
        <div className="header">Prior Authorization Details</div>
        <div className="content"> 
        <FliptGrid data={pa_details} headers={headers} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  state: {
    app: state.app,
    memberLookup: state.memberLookup,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
    ...MemberLookupActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PADetails)
