import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Creators as AppActions } from '../../../../../redux/reducers/app'
import { Creators as ClaimsPRPActions } from '../../../../../redux/reducers/api/claimsProcessingAndReprocessing'
import './styles.scss'
import FliptCheckbox from '../../../../../components/form/fliptCheckbox'
import FliptDropdown from '../../../../../components/v2/fliptDropdown'
import FliptButton from '../../../../../components/v2/fliptButton'
import PlanPicker from '../../components/planPicker'

function TestUtilizationManagement(props) {
	const { state, actions } = props
	const { claimsProcessingAndReprocessing } = state
	const { programEdits, priorAuths, quantityLimits, stepTherapies, tiers } = claimsProcessingAndReprocessing.umObjects
	const { setTestBatchConfig, testBatchConfig, editable } = props
	const { umObjects, plans } = testBatchConfig
	const isDisabled = !editable
	useEffect(() => {
		if (!(testBatchConfig.plans.length > 0)) {
			actions.clearPlans()
		}
	}, [])
	const isUmObjectsEmpty = Object.keys(umObjects).every((key) => umObjects[key].value.length === 0)
	const onUmObjectChange = (e, dropdown) => {
		const { name, value } = dropdown

		const umObjs = {
			programs: programEdits,
			clinical_pa: priorAuths,
			clinical_ql: quantityLimits,
			clinical_step_therapy: stepTherapies,
			tiers: tiers,
		}

		const ums = umObjs[name] || []

		const finalObjs = value.map((val) => {
			const selectedObject = ums.find((obj) => obj.module_name === val)
			const { module_name, version, global_doc_id = '', id } = selectedObject || {}

			return {
				approval_doc_id: id,
				doc_id: global_doc_id,
				doc_name: module_name,
				doc_version: version,
			}
		})

		setTestBatchConfig((prevForm) => ({
			...prevForm,
			umObjects: {
				...prevForm.umObjects,
				[name]: {
					value,
					obj: finalObjs,
				},
			},
		}))
	}
	const handleSelectionChange = (selectedRows) => {
		setTestBatchConfig((prevForm) => ({
			...prevForm,
			plans: [
				...selectedRows,
			],
		}))

	}
	const setUmObjects = () => {
		actions.getUmObjects({ cag: testBatchConfig.hierarchyIDsWithChildren, status: ['TEST_READY'] })
	}
	return (
		<div id="testUtilizationManagementScreen">
			<div className="section">
				<div className="get-um-button-container">
					<FliptButton
						className="primary"
						name="Pull UM Objects"
						onClick={() => setUmObjects()}
						disabled={isDisabled}
					/>
				</div>
				<h2>Available Test Utilization Management Objects</h2>
				<div className="flex-container">
					<div className="flex-item">
						<FliptDropdown
							label="Program Edits"
							options={programEdits.map((programEdit) => ({
								text: programEdit.module_name,
								value: programEdit.module_name,
							}))}
							multiple
							name="programs"
							value={umObjects.programs.value}
							onChange={onUmObjectChange}
							className="dropdown"
							disabled={isDisabled}
						/>
					</div>
					<div className="flex-item">
						<FliptDropdown
							label="Prior Authorization Drug List"
							options={priorAuths.map((priorAuth) => ({
								value: priorAuth.module_name,
								text: priorAuth.module_name,
							}))}
							value={umObjects.clinical_pa.value}
							multiple
							name="clinical_pa"
							onChange={onUmObjectChange}
							className="dropdown"
							disabled={isDisabled}
						/>
					</div>
					<div className="flex-item">
						<FliptDropdown
							label="Quantity Limit"
							options={quantityLimits.map((quantityLimit) => ({
								text: quantityLimit.module_name,
								value: quantityLimit.module_name,
							}))}
							value={umObjects.clinical_ql.value}
							multiple
							name="clinical_ql"
							onChange={onUmObjectChange}
							className="dropdown"
							disabled={isDisabled}
						/>
					</div>
					<div className="flex-item">
						<FliptDropdown
							label="Step Therapy Edits"
							options={stepTherapies.map((stepTherapy) => ({
								text: stepTherapy.module_name,
								value: stepTherapy.module_name,
							}))}
							value={umObjects.clinical_step_therapy.value}
							multiple
							name="clinical_step_therapy"
							onChange={onUmObjectChange}
							className="dropdown"
							disabled={isDisabled}
						/>
					</div>
					<div className="flex-item">
						<FliptDropdown
							label="Formulary Tiers"
							options={tiers.map((formulary_tier) => ({
								text: formulary_tier.module_name,
								value: formulary_tier.module_name,
							}))}
							value={umObjects.tiers.value}
							multiple
							name="tiers"
							onChange={onUmObjectChange}
							className="dropdown"
							disabled={isDisabled}
						/>
					</div>
				</div>
				<div className="get-plans-button-container">
					<FliptButton
						className="primary"
						onClick={() => {
							const { tiers, programs, clinical_pa, clinical_ql, clinical_step_therapy } = umObjects

							const extractedValues = {
								tiers: tiers.value,
								programs: programs.value,
								clinical_pa: clinical_pa.value,
								clinical_ql: clinical_ql.value,
								clinical_step_therapy: clinical_step_therapy.value,
							}

							actions.getTestBatchConfigPlans({
								type: 'um',
								form: {
									...extractedValues,
									hierarchy: testBatchConfig.hierarchyDropdown,
									status: ["Published", "TEST_READY"]
								}
							})
						}}
						name="Pull Linked Plans"
						disabled={isDisabled || isUmObjectsEmpty}
					/>
				</div>
			</div>
			<div className="section">
				<h2>Linked Plans to UM Object</h2>
				<PlanPicker
					selectedPlans={plans}
					allPlans={claimsProcessingAndReprocessing.plans}
					onSelectionChange={handleSelectionChange}
					isDisabled={isDisabled}
				/>
			</div>
		</div>
	)
}

const mapStateToProps = (state) => ({
	state: {
		user: state.user,
		claimsProcessingAndReprocessing: state.claimsProcessingAndReprocessing,
	},
})

const mapDispatchToProps = (dispatch) => {
	const allActions = {
		...AppActions,
		...ClaimsPRPActions,
	}

	return {
		actions: bindActionCreators(allActions, dispatch),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(TestUtilizationManagement)
