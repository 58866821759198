import React, { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import './styles.scss'
import BreadCrumb from '../../../../../components/breadCrumb'
import Config from '../../../../../config'
import FliptButton from '../../../../../components/form/fliptButton'
import FliptDatePicker from '../../../../../components/form/fliptDatePicker'
import FliptDropdown from '../../../../../components/form/fliptDropdown'
import FliptInput from '../../../../../components/form/fliptInput'
import ApprovalOptions from '../../../../../components/approvalOptions'
import GeneratedFormulary from './generatedFormulary'
import { Creators as AppActions } from '../../../../../redux/reducers/app'
import { Creators as FormularyCreationActions } from '../../../../../redux/reducers/api/formularyCreationManagement'
import { Creators as UserActions } from '../../../../../redux/reducers/userManagement/index'
import {
  parseQueryString, addRemoveDaysToDate, convertStrToDateObj,
} from '../../../../../utils/utilities'
import FliptHierarchyDropdown from '../../../../../components/form/fliptHierarchyDropdown'
import * as ApprovalsConstants from '../../../../../redux/sagas/rpm/constants'

const CreateFormulary = (props) => {
  const { DRAFT, PUBLISHED, REJECTED } = ApprovalsConstants
  const InitialFormularyForm = {
    doc_id: '',
    effective_start_date: '',
    version_effective_date: '',
    version: '',
    doc_name: '',
    tiers: [],
    module: 'FORMULARY',
    hierarchy: [],
    is_hierarchy_global: false,
    hideHierarchy: false,
    status: 'DRAFT',
  }
  const { actions, state, history } = props
  const [showGenerate, setShowGenerate] = useState(false)
  const [formularyForm, setFormularyForm] = useState(InitialFormularyForm)
  const [reRender, setRerender] = useState(0)
  const generateFormulary = () => {
    actions.generateFormularyData({ tiers: formularyForm.tiers })
    setShowGenerate(true)
  }
  const { allApprovedTiersData, generatedFormularyData, formularyData } = state
  const approvedTiersDropdownOptions = allApprovedTiersData.map((d) => ({
    text: d.doc_name,
    value: d.doc_id,
    key: d.doc_id,
  }))
  const min_formulary_start_date = addRemoveDaysToDate(1, false)

  const saveFormulary = () => {
    // add to other modules and we're ready
    const hierarchyOrganizationLevel = formularyForm?.hierarchy?.[0]?.children || []
    if (!formularyForm.doc_name || !formularyForm.effective_start_date || hierarchyOrganizationLevel?.length == 0) {
      let message = ""
      if (!formularyForm.doc_name) message = 'enter a Formulary Name'
      else if (!formularyForm.effective_start_date) message = 'select a Start Date'
      else if (hierarchyOrganizationLevel?.length == 0) message = 'assign a Hierarchy'
      const transitionalPortal = {
        header: 'Formulary Save Failed',
        copy: `Please ${message} before saving`
      }
      actions.displayTransitionalPortal(transitionalPortal)
    } else {
      actions.saveFormularyData(formularyForm)
    }
  }
  const handleInputChange = (e, dropdown) => {
    const { name, value } = dropdown || e.currentTarget
    setFormularyForm({
      ...formularyForm,
      [name]: value,
    })
  }
  const sendForReview = () => {
    actions.formularySendForReview({
      doc_id: state.formularyData.doc_id,
      module: 'FORMULARY',
      status: state.formularyData.status
    })
  }
  useEffect(() => {
    setRerender(reRender + 1)
  }, [allApprovedTiersData])
  useEffect(() => {
    actions.clearFormulary()

    setTimeout(() => actions.getAllApprovedTiersData(), Config.COMPONENT_DATA_LOAD_TIME)

    if (history?.location?.search) {
      const { doc_id } = parseQueryString(history.location.search)
      setFormularyForm({
        ...formularyForm,
        doc_id,
      })
      setTimeout(() => { actions.getFormularyData({ id: doc_id }) }, Config.COMPONENT_DATA_LOAD_TIME)
    }

    return () => actions.clearFormulary()
  }, [])

  useEffect(() => {
    const formData = {
      ...formularyData,
      effective_start_date: formularyData.effective_start_date,
      version_effective_date: formularyData.version_effective_date,
      version: formularyData.doc_version,
      doc_name: formularyData.doc_name,
      hierarchy: formularyData.hierarchy,
    }
    if (Array.isArray(formularyData.tiers)) {
      formData.tiers = formularyData.tiers.map((t) => t.doc_id)
    }
    if (!formData.doc_id && !formData.status) {
      formData.status = 'DRAFT'
    }
    setFormularyForm(formData)
  }, [formularyData])

  const approvalOptionsCallback = () => {
    actions.getFormularyData({ id: formularyForm.doc_id })
  }
  /* eslint-disable-next-line */
  const { doc_id } = props.state.formularyData
  const headerText = doc_id ? 'Edit Formulary' : 'Create Formulary'
  const { hideHierarchy } = formularyData
  const editMode = !hideHierarchy
  return (
    <div id="formularyCreation">
      <BreadCrumb {...props} />
      <div className="header">
        {headerText}
      </div>
      {<ApprovalOptions docData={{ ...formularyForm, module: 'FORMULARY' }} actionCallback={approvalOptionsCallback} />}
      <div className="content">
        <div className="section">
          <section className="formulary-inputs-container spacing border-line shadow">
            <div className="formulary-inputs">
              <span>Formulary Name</span>
              <FliptInput name="doc_name" value={formularyForm.doc_name} onChange={handleInputChange} placeholder="Formulary Name" disabled={(!editMode || !!formularyData?.doc_version && (formularyData?.doc_version !== '1.0') || (!!formularyData?.status && formularyData?.status === 'PUBLISHED'))} />
            </div>
            <div className="formulary-inputs">
              <span>Version Number</span>
              <FliptInput name="version" disabled value={formularyForm.version} onChange={handleInputChange} placeholder="Version Number" />
            </div>
            <div className="formulary-inputs">
              <span>Version Effective Date</span>
              <FliptDatePicker className="create-formulary-start-date" name="version_effective_date" readOnly={!editMode} value={convertStrToDateObj(formularyForm.version_effective_date)} minDate={min_formulary_start_date} onChange={handleInputChange} placeholder="Version Effective Date" />
            </div>
            <div className="formulary-inputs">
              <span>Effective Start Date</span>
              <FliptDatePicker className="create-formulary-start-date" name="effective_start_date" readOnly={!editMode} value={convertStrToDateObj(formularyForm.effective_start_date)} minDate={min_formulary_start_date} onChange={handleInputChange} placeholder="Effective start date" />
            </div>
            <div className="formulary-inputs">
              <span>Drug Lists</span>
              <FliptDropdown className="create-formulary-tier-list" placeholder="Select Drug Lists" readOnly={!editMode} value={formularyForm.tiers} onChange={handleInputChange} multiple selection options={approvedTiersDropdownOptions} simple item name="tiers" />
            </div>
            <FliptHierarchyDropdown form={formularyForm} disabled={!editMode} setForm={setFormularyForm} showHierarchyGlobal={true} />
          </section>
          <div className="generateFormularyButtonContainer">
            <FliptButton name="GENERATE FORMULARY" className="primary searchButton" onClick={generateFormulary} />
          </div>
          <div className="section-contents">
            {
              showGenerate && (
                <GeneratedFormulary data={generatedFormularyData} />
              )
            }
          </div>
          <div className="buttonContainer">
            <FliptButton
              className="primary searchButton"
              disabled={![DRAFT, PUBLISHED, REJECTED].includes(formularyForm.status)}
              name="Save"
              onClick={saveFormulary}
            />
            {!!doc_id &&
              <FliptButton
                className="primary searchButton"
                disabled={formularyForm.status !== DRAFT}
                name="Send to Peers for Review/Approval"
                onClick={sendForReview}
              />}
            {/* <FliptButton name="Download in CSV" className="primary searchButton" /> */}
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  state: {
    allApprovedTiersData: state.formularyCreation.allApprovedTiersData,
    allFormulariesData: state.formularyCreation.allFormulariesData,
    formularyData: state.formularyCreation.formularyData,
    generatedFormularyData: state.formularyCreation.generatedFormularyData,
    user: state.user,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
    ...FormularyCreationActions,
    ...UserActions
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateFormulary)
