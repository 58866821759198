import React from 'react'

import './styles.scss'
import FliptButton from '../../../../../../components/form/fliptButton'

export default function ButtonSection(props) {
  const { submitForm, toggleModal } = props

  return (
    <div className="button-container">
      <FliptButton name="SAVE" className="text" onClick={submitForm} />
      <span className="separator">&#x7c;</span>
      <FliptButton name="CANCEL" className="text" onClick={toggleModal} />
    </div>
  )
}
