import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import FliptButton from "../../../../components/form/fliptButton"
import { Creators as AppActions } from '../../../../redux/reducers/app'
import FliptDatePicker from '../../../../components/form/fliptDatePicker'
import './styles.scss'

class ApprovalModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      note: '',
      start_date:""
    }

    this.statusTypes = {
      APPROVED: 'Approve',
      REJECTED: 'Reject',
      PUBLISH_READY: 'Publish Ready',
    }
    
  }


  _updateFields = (el, dropdown) => {
    const { form } = this.state
    const { name, value } = dropdown || el.currentTarget

   
   
    this.setState({ start_date:value })
   
  }

  _submitRequest = () => {
    const {
      submitType, toggleModal, clickHandler, data,
    } = this.props
   if(this.props.data.date){
      if(!this.state.start_date){
        const transitionalPortal = {
          header: 'Choose date',
         
        }
        actions.displayTransitionalPortal(transitionalPortal) 
        return
      }
      data["pa_override_end_date"]=this.state.start_date;
    data["change"]="pa_override_end_date"
    


   }else{
    data["pa_override_status"]="Cancelled";
    data["change"]="pa_override_status"

   }
   data["override_id"]=this.props.data.override_id
  
    clickHandler({
      
      status: true,
      ...data
    })

    toggleModal()
  }

  _updateNote = (el) => {
   
    const val = el.currentTarget.value.trim()
  
    this.props.action({"note":val})
   
  }

  updatePaOverride=()=>{
    
    this.props.action({"paOverride":!this.props.data.paOverride})
  }

  _handleDateChange=(el)=>{

    const val = el.currentTarget.value
    const now = new Date();
    const selectedDate = new Date(val);
    if(now < selectedDate){
      this.props.action({"pa_override_end_date":selectedDate})
    }else{
      const transitionalPortal = {
        header: 'Invalid date',
       
      }
      actions.displayTransitionalPortal(transitionalPortal) 
      el.currentTarget.value=""
    }

  }

   

  render() {
    const { data, submitType, toggleModal,date } = this.props
    const text = this.statusTypes[submitType] || ''
    
    const header = `Are you sure you want to Cancel`

    return (
      <div id="approvalModal">
        {/* <div className="content"> */}
        <div style={{'width':'300px',"textAlign":"right","fontSize":"1em"}} className="paModal">
        {
            data.date?<FliptDatePicker value={this.state.start_date} name="start_date" placeholder="Select date" onChange={this._updateFields}  label="Select date" description="Start Date" />:  <h3>{header}</h3>
          }

        </div>
         
    
          <div style={{marginTop:"16px","textAlign":"center"}}>
            <FliptButton
              name="Confirm"
              onClick={() => this._submitRequest()}
              style={{ marginRight: '10px' }}
            />

            <FliptButton
              name="Cancel"
              onClick={toggleModal}
            />
          </div>
        {/* </div> */}
      </div>
    )
  }
}


const mapStateToProps = (state) => {
 
  return{ state: {
     app: state.app,
     loading: state.paoverridereportLookup.loading, 
   },}
 }
 
 const mapDispatchToProps = (dispatch) => {
   const allActions = {
     ...AppActions,
   }
 
   return {
     actions: bindActionCreators(allActions, dispatch),
   }
 }
 

export default connect(mapStateToProps, mapDispatchToProps)(ApprovalModal)
