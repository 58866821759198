import React , { useEffect }from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Creators as AppActions } from '../../../redux/reducers/app'
import { Creators as EmergencyOverrideActions } from '../../../redux/reducers/api/emergencyOverride'

import { Icon } from 'semantic-ui-react';
import FliptButton from '../../../components/v2/fliptButton'

import EmergencyOverrideDashboard from './EmergencyOverrideDashboard'
import CreateEmergencyOverride from './CreateEmergencyOverride'
import EmergencyConfiguration from './EmergencyConfiguration';

import BreadCrumb from '../../../components/v2/breadCrumb';
import './styles.scss'

function EmergencyOverrides(props) {
    const { actions, state } = props;
    const { page } = state;

    useEffect(()=>{
        actions.setPage('dashboard');
       },[])

    return (
        <div id="emergency-override-configuration" >
            <BreadCrumb {...props} />
            {page === 'dashboard' ? <EmergencyOverrideDashboard  title={'Emergency Override Configuration'} configuration_type={'Emergency'} /> : null}
            {page === 'create-override' ? <CreateEmergencyOverride /> : null}
            {page === 'emergency-config' ? <EmergencyConfiguration /> : null}
            {page === 'dashboard' ? null : <hr />}
            <footer className='footer-container'>
                {page === 'emergency-config' ? (
                    <FliptButton
                        onClick={() => actions.setPage('create-override')}
                        className='button-primary'
                        name="Previous"
                    >
                        <Icon
                            className='button-icon'
                            name="arrow left"
                        />
                    </FliptButton>
                ) : <section />}
                {page === 'create-override' || page === 'emergency-config' ? (
                    <FliptButton
                        onClick={() => actions.setPage('dashboard')}
                        className='button-primary'
                        name="Exit"
                    />
                ) : null}
                {page === 'create-override' || page === 'emergency-config' ? (
                    <FliptButton
                        onClick={() => actions.validateThenSaveAndExit()}
                        className='button-primary'
                        name="Save & Exit"
                    />
                ) : null}
                {page === 'create-override' ? (
                    <FliptButton
                        onClick={() => actions.testEmergencyValidation({ goTo: 'emergency-config' })}
                        className='button-primary'
                    >
                        <span>Next</span>
                        <Icon
                            className='button-icon'
                            name="arrow right"
                        />
                    </FliptButton>
                ) : null}
                {page === 'emergency-config' ? (
                    <FliptButton
                        name="Save Configuration"
                        onClick={() => {
                            actions.saveEmergencyConfiguration()
                        }}
                        className='button-primary'
                    />
                ) : null}
            </footer>
        </div>
    )
}

const mapStateToProps = (state) => ({
    state: {
        app: state.app,
        user: state.user,
        emergencyOverride: state.emergencyOverride,
        page: state.emergencyOverride?.page,

    },
})

const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...AppActions,
        ...EmergencyOverrideActions,
    }

    return {
        actions: bindActionCreators(allActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EmergencyOverrides);
