import React, { useState, useEffect } from 'react'
import FliptGrid from '../../../../../components/fliptGrid'
import { duplicateRxHeader } from './data'
import { duplicateRxRowCellInfo } from './rowCellInfo'
import { DropdownEditor, InputTextEditor } from '../../../../../components/fliptGrid/cellEditors'
import { DeleteRowRenderer, PreviewCellRenderer } from '../../../../../components/fliptGrid/cellRenderers'
import { gpiLevel, messageType, rejectCode, rejectType, standardMessage } from '../data/options'


export default function duplicateRxGrid(props) {
    const { setData, data, delRow, viewOnly } = props
    const selectedGpi = data?.conditions?.length ? data?.conditions.map((e) => e.gpi_level) : []
    duplicateRxRowCellInfo.reject_type.options = rejectType.map(e => ({ key: e, text: e, value: e }))
    duplicateRxRowCellInfo.reject_code.options = rejectCode.map(e => ({ key: e, text: e, value: e }))
    duplicateRxRowCellInfo.message_type.options = messageType.map(e => ({ key: e, text: e, value: e }))
    duplicateRxRowCellInfo.gpi_level.options = gpiLevel.map(e => ({ key: e, text: e, value: e, disabled: selectedGpi.includes(e) }))
    duplicateRxRowCellInfo.fill_threshold.options = new Array(100).fill().map((_, e) => ({ key: e + 1, text: e + 1, value: e + 1 }))
    const tempHeader = [...duplicateRxHeader]
    if (viewOnly) tempHeader.shift()
    const handleChange = (el, dropdown, rowIndex) => {
        const { name, value } = dropdown || el.currentTarget
        const conditions = data?.conditions ? [...data?.conditions] : []
        let row = { ...(conditions[rowIndex] || {}), [name]: value }
        if (name === "message_type") {
            if (value === "Standard") {
                const { reject_code } = row
                const rejectMessage = (reject_code && standardMessage[reject_code]) || ''
                row = { ...row, reject_message: rejectMessage }
                duplicateRxRowCellInfo.reject_message.disabled = true
            } else {
                row = { ...row, reject_message: '' }
                duplicateRxRowCellInfo.reject_message.disabled = false
            }
        }
        conditions[rowIndex] = row
        if (duplicateRxRowCellInfo[name]?.type !== 'dropdown') {
            _.debounce(() => setData({ ...data, conditions }), 900)
            return
        }
        setData({ ...data, conditions })
    }
    const cellRendererParams = {
        action: {
            cellRenderer: DeleteRowRenderer,
            state: {
                onClick: delRow,
            },
            width: 95,
        },
        reject_type: {
            overrideHeader: 'Action Taken',
        },
        fill_threshold: {
            overrideHeader: 'Fill Threshold %',
        },
        gpi_level: {
            cellRenderer: PreviewCellRenderer,
            searchArgs: {
                key: 'gpi_level',
                parseData: (e, row) => {
                    return 'GPI 14'

                }
            }
        }
    }

    const cellEditorParams = {
        fill_threshold: {
            cellEditor: InputTextEditor,
            validation: 'numeric',
            onChange: handleChange,
            disabled: viewOnly
        },
        message_type: {
            cellEditor: DropdownEditor,
            onChange: handleChange,
            disabled: viewOnly,

        },
        reject_code: {
            cellEditor: DropdownEditor,
            onChange: handleChange,
            isDisabled: function (data) {
                return data?.reject_type === 'Message'
            },
            disabled: viewOnly
        },
        reject_type: {
            cellEditor: DropdownEditor,
            onChange: handleChange,
            disabled: viewOnly
        },
        reject_message: {
            cellEditor: InputTextEditor,
            onChange: handleChange,
            isDisabled: function (data) {
                return data?.message_type === 'Standard'
            },
            disabled: viewOnly,
            value: function(data) {
                if(data.message_type === 'Standard') {
                    return data.message_type = "DUR Reject Error"
                }
            },
        },

    }
    return (
        <div className="grid-container">
            <FliptGrid data={data?.conditions || []} headers={tempHeader} rowCellInfo={duplicateRxRowCellInfo} cellEditorParams={cellEditorParams} cellRendererParams={cellRendererParams} />
        </div>
    )
}