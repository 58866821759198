import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Icon } from 'semantic-ui-react'

import FliptGrid from '../../../components/fliptGrid'
import moment from 'moment'
import { Creators as QuestionnaireBuilderActions } from '../../../redux/reducers/api/questionnaireBuilder'
import { convertStrToDateObj } from '../../../utils/utilities'

import './styles.scss'
import BreadCrumb from '../../../components/breadCrumb'
import { LinkRenderer } from '../../../components/fliptGrid/cellRenderers'

class QuestionnaireBuilder extends Component {
    constructor(props) {
        super(props)

        this.state = {
            showCreateNewPlan: false,
        }
    }

    componentDidMount() {
        this.props.actions.getQuestionnaireData({})
    }

    render() {
        const { state } = this.props
        const {
            questionnaireData,
        } = state

        let finalQuestionnaireData = questionnaireData.length && questionnaireData.map((data) => {
            const obj = {
                ...data,
                effective_date: moment(convertStrToDateObj(data?.effective_date, { toISO: true })).format('YYYYMMDD'),
                term_data: moment(convertStrToDateObj(data?.term_date)).format('YYYYMMDD'),
            }
            return obj
        })

        finalQuestionnaireData = finalQuestionnaireData?.length && finalQuestionnaireData.sort(function (a, b) {
            if (a?.questionnaire_name < b?.questionnaire_name) { return -1; }
            if (a?.questionnaire_name > b.questionnaire_name) { return 1; }
            return 0;
        })

        const headers = [
            'questionnaire_id',
            'questionnaire_name',
            'questionnaire_type',
            'questionnaire_category',
            'effective_date',
            'term_date',
            'status',
            'drug_list',
            'version',

        ]


        const cellRendererParams = {
            questionnaire_id: {
                cellRenderer: LinkRenderer,
                questionnaire_id: 'questionnaire_id',
                path: '/upload-questionnaire',
                searchArgs: { questionnaire_id: 'questionnaire_id', group: 'group' },
                state: { editMode: true },
                // path: '/view-questionnaire',
                // onCellClicked: (details) => {
                //     const { history } = this.props
                //     history.push(`/view-questionnaire?questionnaire_id=${details.value}`)
                // }
            },
            effective_date: {
                valueFormatter: (params) => {
                    return moment(convertStrToDateObj(params?.value, { toISO: true })).format('MM/DD/YYYY')
                }
            },
            term_data: {
                valueFormatter: (params) => {
                    return moment(convertStrToDateObj(params?.value, { toISO: true })).format('MM/DD/YYYY')
                }
            }
        }
        return (
            <div id="questionnaireBuilder">
                <BreadCrumb {...this.props} />

                <div className="header">
                    Questionnaire Builder
                    <Link to={{ pathname: '/upload-questionnaire' }} >
                        <div className="create-pa-case" aria-hidden="true">
                            <Icon name="plus circle" size="tiny" />
                            Create New Questionnaire
                        </div>
                    </Link>
                </div>
                <div className="content">
                    <FliptGrid
                        data={finalQuestionnaireData}
                        cellRendererParams={cellRendererParams}
                        headers={headers} />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    state: {
        app: state.app,
        user: state.user,
        questionnaireData: state.questionnaireBuilder.questionnaireData,
    },
})

const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...QuestionnaireBuilderActions,
    }

    return {
        actions: bindActionCreators(allActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuestionnaireBuilder)
