import {
  call,
  put,
  select,
  takeLatest,
  take,
  // all,
} from 'redux-saga/effects'
// import moment from 'moment-timezone'


// import data from '../../../pages/private/medicareSetup/lics/medicarePlanDetails/data'
// import { Creators as MemberActions } from '../../reducers/api/memberLookup'
import { Creators as AppActions, Types as AppTypes } from '../../reducers/app'
import { Creators as NavigationActions } from '../../reducers/navigation'
import { Creators as RPMActions } from '../../reducers/rpm'
import * as ApprovalsConstants from '../rpm/constants'
import { Types, Creators as formularyExportConfigurationActions } from '../../reducers/api/formularyExportConfiguration'
import { fetchGet, fetchPost } from '../../../utils/fetchApi'
import { getAppState, getApiPath, isMenuEmpty } from '../../reducers/selectors'


export default [
  formularyExportConfigurationSendForReviewWatcher,
  getClinicalProgramsWatcher,
  getDrugListWatcher,
  getFormularyExportConfigurationDocumentWatcher,
  getFormularyExportConfigurationListWatcher,
  getGroupConfigurationDataWatcher,
  getPrAlternatesWatcher,
  getPrExclusionsWatcher,
  getSpecialtyProgramsWatcher,
  saveFormularyExportConfigurationDataWatcher,
  // getFormularyExportDrugDataHandler,
  // getFormularyExportDrugOptionsDataHandler
]

/* WATCHERS */

// function* getFormularyExportDrugDataWatcher() {
//   yield takeLatest(Types.GET_FORMULARY_EXPORT_DRUG_DATA, getFormularyExportDrugDataHandler)
// }

function* formularyExportConfigurationSendForReviewWatcher() {
  yield takeLatest(Types.FORMULARY_EXPORT_CONFIGURATION_SEND_FOR_REVIEW, formularyExportConfigurationSendForReviewHandler)
}

function* saveFormularyExportConfigurationDataWatcher() {
  yield takeLatest(Types.SAVE_FORMULARY_EXPORT_CONFIGURATION_DATA, saveFormularyExportConfigurationDataHandler)
}

function* getDrugListWatcher() {
  yield takeLatest(Types.GET_DRUG_LIST, getDrugListHandler)
}

function* getPrAlternatesWatcher() {
  yield takeLatest(Types.GET_PR_ALTERNATES, getPrAlternatesHandler)
}

function* getPrExclusionsWatcher() {
  yield takeLatest(Types.GET_PR_EXCLUSIONS, getPrExclusionsHandler)
}

function* getFormularyExportConfigurationListWatcher() {
  yield takeLatest(Types.GET_FORMULARY_EXPORT_CONFIGURATION_LIST, getFormularyExportConfigurationListHandler)
}

function* getFormularyExportConfigurationDocumentWatcher() {
  yield takeLatest(Types.GET_FORMULARY_EXPORT_CONFIGURATION_DOCUMENT, getFormularyExportConfigurationDocumentHandler)
}

function* getGroupConfigurationDataWatcher() {
  yield takeLatest(Types.GET_GROUP_CONFIGURATION_DATA, getGroupConfigurationDataHandler)
}

function* getSpecialtyProgramsWatcher() {
  yield takeLatest(Types.GET_SPECIALTY_PROGRAMS, getSpecialtyProgramsHandler)
}

function* getClinicalProgramsWatcher() {
  yield takeLatest(Types.GET_CLINICAL_PROGRAMS, getClinicalProgramsHandler)
}



/* HANDLERS */
function* formularyExportConfigurationSendForReviewHandler({ payload, data }) {
  try {
    const status = data?.status || payload?.status
    if (status !== 'DRAFT') {
      throw Error('Document must be in draft status')
    }
    const { doc_id, module } = payload
    const body = {
      doc_id,
      module,
      status: 'FOR_REVIEW',
    }
    yield put(RPMActions.rpmSendForReview(body))
  } catch (err) {
    console.log('formularyExportConfigurationSendForReviewHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Data for Review Failed',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* getClinicalProgramsHandler() {
  try {
    const empty = yield select(isMenuEmpty)
    if (empty) {
      yield take([AppTypes.SET_APP_SETTINGS])
    }

    const { serviceUrl } = yield select(getAppState)
    const cd = yield select(getApiPath, 'get-clinical-program-list')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`
    const { data } = yield call(fetchGet, url)

    yield put(formularyExportConfigurationActions.setClinicalProgramsData(data))
  } catch (err) {
    console.log('getClinicalProgramsHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Error Getting Clinical Programs',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* getSpecialtyProgramsHandler() {
  try {
    const empty = yield select(isMenuEmpty)
    if (empty) {
      yield take([AppTypes.SET_APP_SETTINGS])
    }

    const { serviceUrl } = yield select(getAppState)
    const cd = yield select(getApiPath, 'get-specialty-program-list')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`
    const { data } = yield call(fetchGet, url)

    yield put(formularyExportConfigurationActions.setSpecialtyProgramsData(data))
  } catch (err) {
    console.log('getSpecialtyProgramsHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Error Getting Specialty Programs',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* getGroupConfigurationDataHandler({ payload }) {
  try {
    const empty = yield select(isMenuEmpty)

    if (empty) {
      yield take([AppTypes.SET_APP_SETTINGS])
    }
    const { serviceUrl } = yield select(getAppState)
    const cd = yield select(getApiPath, 'get-pbp-contract-id')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`
    const { data } = yield call(fetchPost, url, payload)

    if (!data?.length) {
      const transitionalPortal = {
        header: 'Data Not Found',
        copy: 'No Groups Config for Given Details',
      }
      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
      return
    }

    yield put(formularyExportConfigurationActions.setGroupConfigurationData(data[0]))
  } catch (err) {
    console.log('getGroupConfigDataHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Getting Group Config Data Failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* getFormularyExportConfigurationDocumentHandler({ payload }) {
  try {
    const { doc_id } = payload

    if (doc_id) yield put(RPMActions.rpmGetApprovalDocuments({ doc_id }))

  } catch (err) {
    console.log('getFormularyExportConfigurationDocumentHandler Error >Data ', err)
  }
}

function* getFormularyExportConfigurationListHandler() {
  try {
    const params = {
      module: 'FORMULARY_EXPORT_CONFIGURATION',
      status: ApprovalsConstants.ALL_RPM_STATUSES,
    }

    yield put(RPMActions.rpmGetApprovalDocuments(params))
  } catch (err) {
    console.log('getFormularyExportConfigurationListHandler Error >Data ', err)
  }
}

function* getDrugListHandler({ payload }) {
  try {
    const empty = yield select(isMenuEmpty)
    if (empty) {
      yield take([AppTypes.SET_APP_SETTINGS])
    }
    const { serviceUrl } = yield select(getAppState)
    const cd = yield select(getApiPath, 'get-drug-list')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`
    const { data } = yield call(fetchPost, url, payload)
    yield put(formularyExportConfigurationActions.setDrugListData(data))
  } catch (err) {
    console.log('getDrugListHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Getting Drug List Failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* getPrAlternatesHandler() {
  try {
    const empty = yield select(isMenuEmpty)
    if (empty) {
      yield take([AppTypes.SET_APP_SETTINGS])
    }
    const { serviceUrl } = yield select(getAppState)
    const cd = yield select(getApiPath, 'get-program-alternative-list')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`
    const { data } = yield call(fetchGet, url)
    yield put(formularyExportConfigurationActions.setPrAlternatesData(data))
  } catch (err) {
    console.log('getPrAlternatesHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Getting Program Alternate Options List Failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* getPrExclusionsHandler() {
  try {
    const empty = yield select(isMenuEmpty)
    if (empty) {
      yield take([AppTypes.SET_APP_SETTINGS])
    }
    const { serviceUrl } = yield select(getAppState)
    const cd = yield select(getApiPath, 'get-program-exclusion-list')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`
    const { data } = yield call(fetchGet, url)
    yield put(formularyExportConfigurationActions.setPrExclusionsData(data))
  } catch (err) {
    console.log('getPrExclusionsHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Getting Program Exclusions Options List Failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* saveFormularyExportConfigurationDataHandler({ payload }) {
  try {

    const approvalDocument = {
      module_name: payload.formulary_name,
      data: payload,
      module: 'FORMULARY_EXPORT_CONFIGURATION',
      status: 'DRAFT',
    }
    // eslint-disable-next-line
    const cb = function* ({ success }) {
      if (success) yield put(NavigationActions.navigateBack())
    }

    yield put(RPMActions.rpmUpsertApprovalDocument(approvalDocument, cb))
  } catch (err) {
    console.log('saveFormularyExportConfigurationDataHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Saving Formulary Export Configuration Data Failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}


// function* getFormularyExportDrugOptionsDataHandler() {
//   try {
//     const empty = yield select(isMenuEmpty)
//     if (empty) {
//       yield take([AppTypes.SET_APP_SETTINGS])
//     }
//     const { serviceUrl } = yield select(getAppState)
//     const cd = yield select(getApiPath, 'formulary-export-lookup')
//     const { api_path } = cd
//     const url = `${serviceUrl}${api_path}`
//     const { data } = yield call(fetchGet, url)
//     yield put(formularyExportLookupActions.setFormularyExportDrugOptionsData(data[0]))
//   } catch (err) {
//     console.log('getFormularyExportDrugOptionsHandler Error >Data ', err)
//     const transitionalPortal = {
//       header: 'Getting Formulary Export LookUp Options Failed',
//       copy: err,
//     }
//     yield put(AppActions.displayTransitionalPortal(transitionalPortal))
//   }
// }

// function* getFormularyExportDrugDataHandler({ payload }) {
//   try {
//     const {
//       form, params, headers, headerMapping, isAdjustment = false
//     } = payload
//     let tempForm = { ...form }
//     const { serviceUrl } = yield select(getAppState)
//     const history = yield select(getHistory)
//     const { drugsPerPage } = yield select(getFormularyExportDrugInfo)
//     const { userCAG = {} } = yield select(getUserState)
//     history.location.search = createQueryString({
//       ...tempForm,
//       offset: params?.request.startRow,
//     })
//     const qs = history.location.search ? `${history.location.search}&limit=${drugsPerPage}` : ''
//     if (!qs && !isAdjustment) {
//       params?.success({
//         rowData: [],
//         rowCount: 0,
//       })
//     } else {
//       const empty = yield select(isMenuEmpty)
//       if (empty) {
//         yield take([AppTypes.SET_APP_SETTINGS])
//       }
//       const exportUrl = yield call(getApiUrlHandler, 'formulary-export-lookup')
//       //const { api_path } = yield select(getApiUrlHandler, 'formulary-export-lookup')
//       //const url = `${serviceUrl}${exportUrl}`
//       const reqData = {
//         formulary_name: tempForm?.formulary_name, // need it here to make it easy for json deserialization
//         gpi: tempForm?.gpi14, // need it here to make it easy for json deserialization
//         ndc: tempForm?.ndc, // need it here to make it easy for json deserialization
//         drug_name: tempForm?.drug_name, // need it here to make it easy for json deserialization
//         doc_name: tempForm?.doc_name, // need it here to make it easy for json deserialization
//         ddid: tempForm?.ddid, // need it here to make it easy for json deserialization
//         sortModel: params?.request.sortModel,
//         filterModel: params?.request.filterModel,
//         limit: drugsPerPage,
//         offset: params?.request.startRow,
//         pagination: true
//       }
//       let res
//       if (!isAdjustment && headers) {
//         const { data, last_row } = yield call(fetchPost, exportUrl, reqData)
//         let gridData = data
//         gridData = data?.map((d) => ({
//           ...filterRowData(d, headers, headerMapping)
//         }))
//         params?.success({
//           rowData: gridData,
//           rowCount: last_row,
//         })
//         yield put(formularyExportLookupActions.setFormularyExportDrugData(data))
//       }
//     }
//   } catch (err) {
//     console.log('getFormularyExportDrugDataHandler Error > Data: ', err)
//     const transitionalPortal = {
//       header: 'Formulary Export Lookup Failed',
//       copy: err,
//     }
//     yield put(AppActions.displayTransitionalPortal(transitionalPortal))
//   }
// }
