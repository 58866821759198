import React, { PureComponent } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import './App.scss'
import AppModal from './components/modals/appModal'
import FliptLoader from './components/fliptLoader'
import Pages from './pages'
import PrimaryHeader from './components/headers/primaryHeader'
import HomeHeader from './components/headers/homeHeader'
import SideNavigation from './components/menu/sideNavigation'
import Footer from './components/footer'
import { Creators as NavigationActions } from './redux/reducers/navigation'
import { Creators as AppActions } from './redux/reducers/app'
import { Creators as UserActions } from './redux/reducers/user'
import { Creators as SSOLoginActions } from './redux/reducers/ssoLogin'
import FliptIdleTimeout from './components/fliptIdleTimeout'
import { isObject } from 'lodash'
import {
  Header,
  Segment,
  Sidebar,
  TransitionablePortal,
} from 'semantic-ui-react'

class App extends PureComponent {
  componentDidMount() {
    const { history, actions } = this.props
    actions.initializeApplication({ history })
    history.location.search = window.location.search
    history.location.pathname = window.location.pathname
    actions.setNavigationHistory({ history })
    actions.getClientData(window.location.hostname)
    actions.auth0ConfigureClient()
  }

  toggleModal = () => {
    const { actions } = this.props
    actions.toggleSideDrawer()
  }


  renderSideBar = (sideDrawer, bodyComponent) => {
    const { visible = false, component } = sideDrawer
    return (visible) ?
      (<>
        <Sidebar.Pushable as={Segment}>
          <Sidebar
            animation='overlay'
            inverted
            vertical
            visible={sideDrawer.visible}
            direction='right'
            width='very wide'
          >
            {component}
          </Sidebar>
          <Sidebar.Pusher onClick={this.toggleModal} className='pushable' >
            {bodyComponent}
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </>) : (
        <>
          {bodyComponent}
        </>
      )
  }

  render() {
    const { state, actions } = this.props
    const {
      isLoggedIn, modal, transitionalPortal, loader, sidebarIsOpen, sideDrawer
    } = state.app
    const loggedInClass = isLoggedIn ? 'isLoggedIn' : ''
    const openSidebarClass = sidebarIsOpen ? 'openSidebar' : ''
    const visibleModal = modal.visible ? 'visible' : ''
    const { copy = '' } = transitionalPortal
    const transitionMessage = isObject(copy) ? copy?.message || '' : copy
    return (
      <>
        {isLoggedIn && <FliptIdleTimeout userLogout={actions.userLogout} />}
        <div id="appContainer" className={loggedInClass}>
          {this.renderSideBar(sideDrawer, (<BodyComponent
            openSidebarClass={openSidebarClass}
            visibleModal={visibleModal}
            transitionMessage={transitionMessage}
            transitionalPortal={transitionalPortal}
            loader={loader}
            app={state.app}
          />))}
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  state: {
    app: state.app,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...NavigationActions,
    ...AppActions,
    ...UserActions,
    ...SSOLoginActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

const BodyComponent = (props) => {
  const { openSidebarClass, visibleModal, transitionMessage, transitionalPortal, loader, app } = props
  const pathname = window.location.pathname
  return <>
    <body class={app?.companyCSSFilename ?? 'flipt'}>
      {pathname !== '/home' && pathname !== '/frf-search' ?
        <header id="appHeader">
          <PrimaryHeader />
        </header>
        : null}
      <div id="mainContainer">
        {pathname !== '/frf-search' ? <div id="leftColumn">
          <SideNavigation />
        </div> : null}
        <div id="mainColumn" className={openSidebarClass}>
          {pathname == '/home' ?
            <header id="appHeaderHome">
              < HomeHeader />
            </header> :
            null}
          <Pages />
        </div>
      </div>
      <div id="appModal" className={visibleModal}>
        <AppModal />
        <div className="modal-background" />
      </div>
      {pathname !== "/home" ? <div id="footer"><Footer /></div> : null}

      <TransitionablePortal
        open={transitionalPortal.visible}
        transition={{ animation: 'fade', duration: 250 }}
      >
        <Segment id="transitionalPortal">
          <Header className="header">{transitionalPortal.header}</Header>
          <p className="copy">{transitionMessage}</p>
        </Segment>
      </TransitionablePortal>
      <FliptLoader
        active={!!loader.isActive}
        size={loader.size}
        loaderText={loader.loaderText}
      />
    </body>
  </>
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))
