

import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Creators as PriorAuthorizationCreationActions } from '../../../../redux/reducers/api/priorAuthorizationManagement'
import './styles.scss'
import { Creators as AppActions } from '../../../../redux/reducers/app'
import FliptPaDropdown from '../../../../components/form/fliptPaDropdown'
import FliptPaInput from '../../../../components/form/fliptPaInput'
import FliptButton from '../../../../components/form/fliptButton'
import { Icon } from 'semantic-ui-react'
import FliptPaDatepicker from '../../../../components/form/fliptPaDatepicker'
import FliptCheckbox from '../../../../components/form/fliptCheckbox'
import * as Constants from '../constants'
import moment from 'moment'
import FliptTextarea from '../../../../components/form/fliptTextarea'
import { DocCreationQueueMessage, FaxQueueMessage } from '../messageComponents'
import PatientInfo from '../PatientInfo'

class PaApprovalModal extends Component {
    constructor(props) {
        super(props)
        const { FAX_STR, EPA_STR, SURESCRIPTS_STR } = Constants
        const { planForm, selectedStatus } = this.props
        let authorization_effective_date = '', authorization_term_date = '', provider_message = '', communication_method = FAX_STR
        if (planForm.authorization_effective_date) {
            authorization_effective_date = moment(planForm.authorization_effective_date, 'YYYY-MM-DD HH:mm:ss').toDate()
        }
        if (planForm.authorization_term_date) {
            authorization_term_date = moment(planForm.authorization_term_date, 'YYYY-MM-DD HH:mm:ss').toDate()
        }
        if (planForm.audit_trail) {
            const selectedStatusRecord = planForm.audit_trail.findLast((obj) => obj.audit_trail_status === selectedStatus)
            provider_message = selectedStatusRecord?.provider_message || ''
            communication_method = selectedStatusRecord?.communication_method || FAX_STR
        }
        if (planForm.intake_type === SURESCRIPTS_STR) {
            communication_method = EPA_STR
        }

        this.state = {
            form: {
                override_code: '01',
                authorization_effective_date: authorization_effective_date || new Date(),
                authorization_period: '',
                authorization_term_date,
                provider_message,
                communication_method,
            },
            docList: [],
            mergedDocList: [],
            documentRead: false
        }
        this.checkQueueStatus = null
    }

    componentDidMount() {
        const { actions, planForm, selectedStatus, state: { paCaseDetails } } = this.props
        const { pa_case_id, patient_benefit, intake_type, queue_status, queue_action, case_type } = planForm
        if (intake_type !== Constants.SURESCRIPTS_STR)
            actions.getMessageTemplate({
                pa_case_id,
                case_type: paCaseDetails.case_type,
                gpi: paCaseDetails?.medication?.gpi,
                action: selectedStatus,
                domain: patient_benefit?.domain
            })

        if (queue_status === Constants.QUEUE_SUCCESS_STR && queue_action === selectedStatus) {
            actions.getFinalDocuments({
                pa_case_id,
                action: selectedStatus
            })
        }

    }

    _updateDocList = (index, innerIndex) => {
        const { docList } = this.state
        const docIndex = (index * 2) + innerIndex
        docList[docIndex].checked = !docList[docIndex].checked
        this.setState({ docList })
    }

    renderPdf = () => {
        const { docList } = this.state
        const chunkedArray = docList.length > 2 ? _.chunk(docList, 2) : [docList]
        const docListRender = chunkedArray.map((doc, index) => {
            return (
                <div className='doc-outer-container' key={index}>
                    {doc.map((innerData, innerIndex) => {
                        return (
                            <div className='doc-checkbox-container' key={innerIndex}>
                                <FliptCheckbox checked={innerData.checked} name={innerData.document_type} onChange={() => this._updateDocList(index, innerIndex)} />
                                <div className='doc-container'>
                                    <Icon name='file pdf outline' color='grey' />
                                    {innerData.name}
                                </div>
                            </div>
                        )
                    })}
                </div>
            )
        })


        return docListRender;
    }

    renderGeneratedPdf = () => {
        const { mergedDocList } = this.state
        const chunkedArray = mergedDocList.length > 2 ? _.chunk(mergedDocList, 2) : [mergedDocList]
        return chunkedArray.map((doc, index) => {
            return (
                <div className='doc-outer-container' key={index}>
                    {doc.map((innerData, innerIndex) => {
                        return (
                            <div className='doc-checkbox-container' key={innerIndex}>
                                <div className='doc-container'>
                                    <Icon name='file pdf outline' color='grey' />
                                    <a href={innerData.document}>{innerData.name}</a>
                                </div>
                            </div>
                        )
                    })}
                </div>
            )
        })
    }

    componentDidUpdate(prevProps) {
        const { state, actions, selectedStatus } = this.props
        const { finalDocuments, messageTemplateData, paCaseDetails } = state
        const prevPropState = prevProps.state

        if (prevPropState.paCaseDetails?.queue_status !== paCaseDetails.queue_status
            && paCaseDetails?.queue_action === selectedStatus) {
            if ([Constants.QUEUE_SUCCESS_STR, Constants.QUEUE_FAILED_STR].includes(paCaseDetails.queue_status)) {
                clearInterval(this.checkQueueStatus)
                actions.getFinalDocuments({
                    pa_case_id: paCaseDetails.pa_case_id,
                    action: selectedStatus
                })
            }
        }

        if (prevPropState?.finalDocuments !== finalDocuments && finalDocuments) {
            const mergedDocList = finalDocuments.map((doc) => {
                return {
                    ...doc,
                    name: doc["type"]?.split('_').join(' '),
                }
            })
            this.setState({ mergedDocList })
        }
        if (prevPropState?.messageTemplateData !== messageTemplateData && messageTemplateData) {
            const filteredDoc = messageTemplateData.map((doc) => {
                return {
                    ...doc,
                    name: doc.document_type.split('_').join(' '),
                    checked: true
                }
            })
            this.setState({ docList: filteredDoc })
        }
    }

    _updateForm = (el, dropdown) => {
        const { form } = this.state
        const { name, value, checked } = dropdown || el.currentTarget
        if (name === 'resend') {
            form[name] = checked
        } else {
            form[name] = value
            if (name === 'authorization_period') {
                form['authorization_term_date'] = this._calculateAuthorizationTerm(value)
            }
            if (name === 'authorization_effective_date') {
                form['authorization_term_date'] = this._calculateAuthorizationTerm(this.state.form.authorization_period, value)
            }
            if (name === 'authorization_term_date') {
                const calculateNewDate = this._calculateAuthorizationTerm(
                    this.state.form.authorization_period,
                    this.state.form.authorization_effective_date
                )
                if (calculateNewDate && value && calculateNewDate?.getTime() !== value?.getTime())
                    form['authorization_period'] = 'other'
            }
        }
        this.setState({ form })
    }

    _calculateAuthorizationTerm = (value, new_auth_effective_date = null) => {
        const { form } = this.state

        if (!form.authorization_effective_date)
            return ''

        let authorization_term_date = new Date(form.authorization_effective_date)
        if (new_auth_effective_date) {
            authorization_term_date = new Date(new_auth_effective_date)
        }
        switch (value) {
            case '1 month':
                authorization_term_date.setDate(authorization_term_date.getDate() + 30)
                break
            case '3 months':
                authorization_term_date.setDate(authorization_term_date.getDate() + 30 * 3)
                break
            case '6 months':
                authorization_term_date.setDate(authorization_term_date.getDate() + 30 * 6)
                break
            case '9 months':
                authorization_term_date.setDate(authorization_term_date.getDate() + 30 * 9)
                break
            case '12 months':
                authorization_term_date.setDate(authorization_term_date.getDate() + 30 * 12)
                break
            case 'EoY':
                authorization_term_date.setMonth(11)
                authorization_term_date.setDate(31)
                break
            case 'indefinite':
                authorization_term_date.setMonth(11)
                authorization_term_date.setDate(31)
                authorization_term_date.setYear(2099)
                break
            case 'other':
                authorization_term_date = ''
                break
            default:
                break
        }
        return authorization_term_date
    }
    _showTransitionalPortal = (header, message) => {
        const { props } = this;
        const transitionalPortal = {
            header: header,
            copy: message,
        }
        props.actions.displayTransitionalPortal(transitionalPortal)
    }

    closeModal = () => {
        const { actions } = this.props
        actions.toggleSideDrawer()
    }

    _updatePaCaseStatus = () => {
        const { form } = this.state
        const { actions, planForm: { pa_case_id }, selectedStatus, state } = this.props
        // update pa case to status 'APPROVED' or 'APPROVED - APPEAL 1' or 'APPROVED - APPEAL 2'
        // based on selected status
        const updateCaseParams = {
            pa_case_id,
            request_type: 'main_details',
            pa_case_status: selectedStatus,
            provider_message: form.provider_message,
            override_code: form.override_code,
            authorization_effective_date: form.authorization_effective_date,
            authorization_term_date: form.authorization_term_date,
            first_name: state?.user?.first_name || "",
            last_name: state?.user?.last_name || "",
            userId: state?.user?.uuid,
            communication_method: form.communication_method,
        }
        actions.updatePaCase(updateCaseParams)
    }

    _mergeDocuments = () => {
        const { form, docList } = this.state
        const { actions, planForm: { pa_case_id }, selectedStatus, state } = this.props
        if (!form.authorization_term_date) {
            const header = 'Fields Missing'
            const message = 'Please add Authorization Term Date to Generate Communication'
            this._showTransitionalPortal(header, message)
            return
        }
        // mergeDocument (queue/asyncio) will:
        // 1. change the status to 'APPROVED'
        // 2. generate documents
        // 3. set the status 'DOCUMENT CREATION - APPROVED'
        const mergeDocParams = {
            document_id: [],
            pa_case_id: pa_case_id,
            action: selectedStatus,
            override_code: form.override_code,
            message_to_provider: form.provider_message,
            authorization_effective_date: form.authorization_effective_date,
            authorization_term_date: form.authorization_term_date,
            first_name: state?.user?.first_name || "",
            last_name: state?.user?.last_name || "",
            userId: state?.user?.uuid
        }
        docList.map((doc) => {
            if (doc.checked) {
                mergeDocParams.document_id.push(doc)
            }
        })
        actions.mergeDocument(mergeDocParams)
        // after we submit request to merge document
        // we need to check queueStatus every 15 secs
        this.checkQueueStatus = setInterval(this.checkQueueStatusEvent, 15000)
    }

    checkQueueStatusEvent = () => {
        const { actions, planForm: { pa_case_id } } = this.props
        actions.getPaCaseDetails({ pa_case_id })
    }

    componentWillUnmount() {
        clearInterval(this.checkQueueStatus)
    }

    _sendCommunication = () => {
        const { form: { communication_method } } = this.state
        const { actions, planForm: { pa_case_id }, selectedStatus } = this.props
        const payload = {
            pa_case_id,
            action: selectedStatus,
            communication_method,
        }
        actions.sendOutboundCommunication(payload)
    }

    isGenerateCommunicationDisbaled = () => {
        const { docList } = this.state
        let isDisabled = true
        docList.forEach((doc) => {
            if (doc.checked) isDisabled = false
        })
        return isDisabled
    }

    renderDocumentsOptions = () => {
        const { communicationTypeOptions, FAX_STR } = Constants
        const { documentRead, form, mergedDocList } = this.state
        const { state, selectedStatus, lockCase } = this.props
        const { queue_action, queue_status, fax_action, fax_status, fax_error_message = "" } = state.paCaseDetails
        let closeButtonName = 'Finish & Close'
        if (form.communication_method !== FAX_STR) {
            closeButtonName = 'Save & Close'
        }
        return <div>
            <div className='devider' />
            <div className='doc-list-container'>
                {this.renderPdf()}
            </div>
            <div className='devider' />
            <div className='rfi-button-container'>
                <FliptButton className='primary' disabled={this.isGenerateCommunicationDisbaled() || lockCase} name='Generate Communication' onClick={this._mergeDocuments} />
            </div>
            <DocCreationQueueMessage queue_action={queue_action} queue_status={queue_status} selectedStatus={selectedStatus} />
            <div className='doc-list-container'>
                {this.renderGeneratedPdf()}
            </div>
            <div className='doc-read-container'>
                <FliptCheckbox checked={documentRead} onChange={() => this.setState({ documentRead: !documentRead })} />
                <span className='read-text'>I have completed any manual member letter process required.</span>
            </div>
            {mergedDocList?.length !== 0 && <div className='communication-method'>
                <FliptPaDropdown name='communication_method'
                    value={form.communication_method} options={communicationTypeOptions}
                    onChange={this._updateForm} stylized label='Communication Method' />
            </div>}
            <div className='rfi-button-container'>
                <FliptButton className='primary' disabled={!documentRead || lockCase} name={closeButtonName} onClick={this._sendCommunication} />
            </div>
            <FaxQueueMessage fax_action={fax_action} fax_status={fax_status} fax_error_message={fax_error_message} selectedStatus={selectedStatus} />
        </div>

    }

    renderApproveOption = () => {
        const { lockCase } = this.props
        return <>
            <div className='communication-method'>
                <FliptPaInput value={this.state.form.communication_method} label='Communication Method' stylized disabled />
            </div>
            <div className='rfi-button-container'>
                <FliptButton className='primary' disabled={lockCase} name='Approve' onClick={this._updatePaCaseStatus} />
            </div>
        </>
    }

    render() {
        const { planForm, state: { paCaseDetails } } = this.props
        const { form } = this.state

        const authorizationPeriodOptions = [
            { key: '1', value: '1 month', text: '1 Month' },
            { key: '2', value: '3 months', text: '3 Months' },
            { key: '3', value: '6 months', text: '6 Months' },
            { key: '4', value: '9 months', text: '9 Months' },
            { key: '5', value: '12 months', text: '12 Months' },
            { key: '6', value: 'EoY', text: 'End of Calendar Year' },
            { key: '7', value: 'indefinite', text: 'Indefinite' },
            { key: '8', value: 'other', text: 'Other' },
        ]

        return (
            <div id="PaApprovalModal">
                <div className='header-container'>
                    <span className='header-text'>Case Information</span>
                    <Icon onClick={() => this.closeModal()} name='close' color='grey' />
                </div>
                <PatientInfo paCaseDetails={paCaseDetails} />
                <div className='devider' />
                <div className='rfi-container'>
                    <span className='header-text'>Approval</span>
                    <div className='approval-outer-container'>
                        <div className='field-inner-container'>
                            <FliptPaInput name="override_code" value='01' disabled stylized label='Override Code' />
                            <FliptPaDropdown name="authorization_period" value={form.authorization_period} options={authorizationPeriodOptions}
                                onChange={this._updateForm} stylized label='Authorization Period' />
                        </div>
                        <div className='field-inner-container'>
                            <FliptPaDatepicker onChange={this._updateForm} value={form.authorization_effective_date} stylized name="authorization_effective_date"
                                labelText="Authorization Effective" />
                            <FliptPaDatepicker onChange={this._updateForm} value={form.authorization_term_date} stylized name="authorization_term_date"
                                labelText="Authorization Term" />
                        </div>
                    </div>
                    <div className='field-message-container'>
                        <FliptTextarea isPa className='message-area' name="provider_message" maxlength="2500" value={form.provider_message}
                            onChange={this._updateForm} stylized label='Message to provider' />
                    </div>
                </div>

                {planForm?.intake_type === Constants.SURESCRIPTS_STR ? this.renderApproveOption() : this.renderDocumentsOptions()}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    state: {
        app: state.app,
        user: state.user,
        notesData: state.priorAuthorizationCreation.notesData,
        updatePaCaseData: state.priorAuthorizationCreation.updatePaCaseData,
        // mergeDocumentData: state.priorAuthorizationCreation.mergeDocumentData,
        messageTemplateData: state.priorAuthorizationCreation.messageTemplateData,
        // addPaOverrideData: state.priorAuthorizationCreation.addPaOverrideData,
        paCaseDetails: state.priorAuthorizationCreation.paCaseDetails,
        finalDocuments: state.priorAuthorizationCreation.finalDocuments
    },
})

const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...AppActions,
        ...PriorAuthorizationCreationActions,
    }

    return {
        actions: bindActionCreators(allActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaApprovalModal)
