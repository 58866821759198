import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'
import BreadCrumb from '../../../components/breadCrumb'
import { Creators as AppActions } from '../../../redux/reducers/app'
import { Creators as PlanManagementActions } from '../../../redux/reducers/api/planManagement'
import { Creators as MemberLookupActions } from '../../../redux/reducers/api/memberLookup'
import './styles.scss'
import FliptInput from '../../../components/form/fliptInput'
import FliptButton from '../../../components/form/fliptButton'
import FliptDropdown from '../../../components/form/fliptDropdown'
import SubmenuNav from './shared/navigation'
import MemberDemographic from './shared/memberDemographic'
import PlanInfo from './shared/planInfo'

import FliptHierarchyDropdown from '../../../components/form/newFliptHierarchyDropdown'
import PlanSearchForm from './components/PlanSearchForm'
class ManualEligibilityEntry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchForm: {
        plan_name: "",
        plan_year: "",
        status: "Published",
        carrier: "",
        account: ""
      },
      demographicData: {
        first_name: "",
        last_name: "",
        email: "",
        dob: "",
        phone_number: null,
        address1: "",
        address2: "",
        city: "",
        state: "",
        zipcode: "",
        ssn: "",
        medicare_id: "",
        subscriber_id: "",
        tpa_id: "",
        effective_start_date: "",
        effective_end_date: "",
        gender: "",
        type: "",
        member_type: "primary",
        primary_member_subscriber_id: "",
        skip_this_record_from_term_by_absence: "No",
        ignore_flle_updates: "No",
        dependents: []
      },
      planNameOptions: [],
      client: "",
      carrier: "",
      account: "",
      group: "",
      personId: "",
      planData: [],
      selectedPlanData: {},
      isMemberExist: false,
      memberChecked: false,
      savedMemberId: "",
      isPrimaryCardHolder: true,

    };
  }
  componentDidUpdate(prevProps) {
    const { props, state } = this;
    const { benefitPlanData, planLookupData, memberExistData, saveUserData } =
      props.state;

    if (
      prevProps.state.planLookupData !== planLookupData &&
      planLookupData?.length
    ) {
      const planData = [...state.planData, ...planLookupData];
      this.setState({ planData });
      this._setPlanNameOptions(planData);
    }
    if (
      prevProps.state.benefitPlanData !== benefitPlanData &&
      benefitPlanData?.length
    ) {
      const planData = [...state.planData, ...benefitPlanData];
      this.setState({ planData });
      this._setPlanNameOptions(planData);
    }
    if (prevProps.state.memberExistData !== memberExistData) {
      this.setState({
        isMemberExist: !!memberExistData.length,
        memberChecked: true
      });
    }
    if (prevProps.state.saveUserData !== saveUserData) {
      this.setState({
        savedMemberId: saveUserData?.attributes.flipt_person_id
      });
    }
  }

  _setPlanNameOptions = (planData) => {
    const planNameOptions = [];
    planData.map((planData, index) => {
      planNameOptions.push({
        key: index + 1,
        text: planData.plan_name,
        value: planData.plan_name
      });
    });
    this.setState({ planNameOptions });
  };

  _searchData() {
    const {
      actions,
      state: { user }
    } = this.props;
    const {
      state: { searchForm }
    } = this;
    if (!searchForm.account) {
      const transitionalPortal = {
        header: "Fields are missing",
        copy: "Please select account to proceed"
      };
      actions.displayTransitionalPortal(transitionalPortal);
      return;
    }
    searchForm.plan_year = moment().format("YYYY");
    const carrier =
      user.hierarchyAccess.length &&
      user.hierarchyAccess[0]?.client?.length &&
      user.hierarchyAccess[0]?.client.filter(
        (data) =>
          data.carrier.length &&
          data.carrier[0].account.length &&
          data.carrier[0].account[0].name == searchForm.account
      );
    const group =
      (carrier.length &&
        carrier[0]?.carrier?.length &&
        carrier[0].carrier[0]?.account?.length &&
        carrier[0].carrier[0]?.account[0]?.group?.length &&
        carrier[0].carrier[0]?.account[0].group[0].name) ||
      "";
    searchForm.carrier =
      (carrier.length &&
        carrier[0]?.carrier?.length &&
        carrier[0].carrier[0].name) ||
      "";
    this.setState({ group });
    actions.getBenefitPlanData(searchForm);
    searchForm.plan_year = [searchForm.plan_year];
    actions.getPlanDesignLookupData(searchForm);
  }

  _updateHierarchy = (name, value) => {
    const { state } = this;
    state[name] = value;
    this.setState({ state });
  };

  _updateDemographicData = (el, dropdown) => {
    const { isMemberExist, demographicData } = this.state;
    const { name, value } = dropdown || el.currentTarget;
    if (
      isMemberExist ||
      name === "first_name" ||
      name === "last_name" ||
      name === "dob"
    )
      this.setState({ isMemberExist: false, memberChecked: false });
    if (name === "member_type") {
      this.setState((prevState) => ({
        isPrimaryCardHolder: value === "primary"
      }));
      demographicData["relationship"] =
        value === "primary" ? "" : demographicData.relationship;
    }
    demographicData[name] = value;
    this.setState({ demographicData });
  };

  _setPlanData = (value) => {
    const { planData } = this.state;
    const planIndex = planData.findIndex((data) => data.plan_name == value);
    this.setState({ selectedPlanData: planData[planIndex] });
  };

  _showTransitionalPortal = (header, message) => {
    const { props } = this;
    const transitionalPortal = {
      header: header,
      copy: message
    };
    props.actions.displayTransitionalPortal(transitionalPortal);
  };
  buildDependentsParam = () => {
    const {
      state: {
        demographicData: {
          primary_member_subscriber_id,
          address1,
          address2,
          tpa_id,
          dependents,
          ssn,
          city,
          state,
          zipcode,
          subscriber_id,
          type,
          member_type,
          effective_start_date,
          effective_end_date,
        },
        account,
        carrier,
        client,
        group,
        skip_this_record_from_term_by_absence
      }
    } = this;

    let dependentArr = [];

    dependentArr = dependents?.map((dep) => {
      const { effective_start_date, effective_end_date, ...newDep } = dep;
      const modifiedDep = {
        ...newDep,
        effective_start_date: moment(effective_start_date).format(
          "MM/DD/YYYY"
        ),
        effective_end_date: moment(effective_end_date).format("MM/DD/YYYY"),
        date_of_birth: moment(dep.date_of_birth).format("MM/DD/YYYY"),
        skip_this_record_from_term_by_absence,
        subscriber_id: subscriber_id,
        account,
        carrier,
        client,
        group,
        type,
        member_type: "dependent",
        home_address: address1 + " " + address2,
        tpa_member_id: tpa_id,
        ssn,
        city,
        state,
        zip: zipcode
      };

      return modifiedDep;
    });
    return dependentArr;
  };

  _checkIfMemberExist = () => {
    const {
      state: { demographicData, searchForm },
      props
    } = this;

    let header = "Fields are missing";
    let message = "to check if member exist";

    if (!searchForm.plan_name) {
      message = `Please add Plan Name ${message}`;
      this._showTransitionalPortal(header, message);
      return;
    }

    if (
      (demographicData.dob || demographicData.last_name) &&
      !demographicData.first_name
    ) {
      message = `Please add First Name ${message}`;
      this._showTransitionalPortal(header, message);
      return;
    }

    if (
      (demographicData.first_name || demographicData.dob) &&
      !demographicData.last_name
    ) {
      message = `Please add last name ${message}`;
      this._showTransitionalPortal(header, message);
      return;
    }

    if (
      (demographicData.first_name || demographicData.last_name) &&
      !demographicData.dob
    ) {
      message = `Please add Date of birth ${message}`;
      this._showTransitionalPortal(header, message);
      return;
    }

    if (
      !demographicData.first_name &&
      !demographicData.last_name &&
      !demographicData.dob &&
      !demographicData.ssn
    ) {
      message = `Please add SSN ${message}`;
      this._showTransitionalPortal(header, message);
      return;
    }
    const params = {
      plan_name: searchForm.plan_name,
      plan_year: moment().format("YYYY"),
      ssn: demographicData.ssn,
      first_name: demographicData.first_name,
      last_name: demographicData.last_name,
      date_of_birth: demographicData.dob
        ? moment(demographicData.dob).format("YYYY-MM-DD")
        : ""
    };
    props.actions.checkIfMemberExist(params);
  };

  _generateMember = () => {
    const {
      state: {
        demographicData,
        searchForm,
        group,
        client,
        account,
        carrier,
        ignore_file_updates
      },
      props,
      buildDependentsParam
    } = this;
    let header = "Fields are missing";
    let message = "to  generate a member";

    if (!demographicData.first_name) {
      message = `Please add First Name ${message}`;
      this._showTransitionalPortal(header, message);
      return;
    }

    if (!demographicData.last_name) {
      message = `Please add last name ${message}`;
      this._showTransitionalPortal(header, message);
      return;
    }

    if (!demographicData.gender) {
      message = `Please add gender ${message}`;
      this._showTransitionalPortal(header, message);
      return;
    }

    if (!demographicData.dob) {
      message = `Please add date of birth ${message}`;
      this._showTransitionalPortal(header, message);
      return;
    }

    if (demographicData.member_type === "primary") {
      if (!demographicData.address1) {
        message = `Please add address1 ${message}`;
        this._showTransitionalPortal(header, message);
        return;
      }

      if (!demographicData.city) {
        message = `Please add city ${message}`;
        this._showTransitionalPortal(header, message);
        return;
      }

      if (!demographicData.state) {
        message = `Please add state ${message}`;
        this._showTransitionalPortal(header, message);
        return;
      }
      if (!demographicData.zipcode) {
        message = `Please add zipcode ${message}`;
        this._showTransitionalPortal(header, message);
        return;
      }

      if (
        !demographicData.ssn &&
        !demographicData.tpa_id &&
        !demographicData.medicare_id &&
        !demographicData.subscriber_id
      ) {
        message = `A SSN, Medicare ID, TPA ID, or Subscriber ID is required. Please enter at least one of these values before moving forward`;
        this._showTransitionalPortal(header, message);
        return;
      }
      if (!demographicData.effective_start_date) {
        message = `Please add effective start date ${message}`;
        this._showTransitionalPortal(header, message);
        return;
      }

      if (!demographicData.effective_end_date) {
        message = `Please add effective end date ${message}`;
        this._showTransitionalPortal(header, message);
        return;
      }
    }

    if (demographicData.member_type === "dependent" && !demographicData.primary_member_subscriber_id) {
      message = `Please add Primary Member Subscriber Id ${message}`;
      this._showTransitionalPortal(header, message);
      return;
    }

    const dependentsArray = buildDependentsParam();
    const params = {
      type: demographicData.type,
      first_name: demographicData.first_name,
      last_name: demographicData.last_name,
      home_address: demographicData.address1 + " " + demographicData.address2,
      city: demographicData.city,
      state: demographicData.state,
      zip: demographicData.zipcode,
      date_of_birth: moment(demographicData.dob).format("MM/DD/YYYY"),
      ssn: demographicData.ssn,
      gender: demographicData.gender,
      tpa_member_id: demographicData.tpa_id,
      member_type: demographicData.member_type,
      member_id: demographicData.member_id,
      medicare_benefit_id: demographicData.medicare_id,
      subscriber_id: demographicData.subscriber_id,
      effective_start_date: moment(demographicData.effective_start_date).format(
        "MM/DD/YYYY"
      ),
      effective_end_date: moment(demographicData.effective_end_date).format(
        "MM/DD/YYYY"
      ),
      account,
      group,
      carrier,
      client,
      skip_this_record_from_term_by_absence:
        demographicData.skip_this_record_from_term_by_absence,
      ignore_file_updates: demographicData.skip_this_record_from_term_by_absence
    };
    if (dependentsArray.length > 0) {
      params.dependents = dependentsArray;
    }
    props.actions.saveUser(params);
  };

  _addDependentSection = () => {
    if (this.state.isPrimaryCardHolder !== true) return;
    this.setState((prevState) => ({
      ...prevState,
      demographicData: {
        ...prevState.demographicData,
        dependents: [
          ...prevState.demographicData.dependents,
          {
            first_name: "",
            last_name: "",
            relationship: "",
            tpa_member_id: "",
            carrier: "",
            account: "",
            group: "",
            date_of_birth: "",
            effective_start_date: "",
            effective_end_date: "",
            ssn: "",
            home_address: "",
            city: "",
            state: "",
            zip: "",
            gender: "",
            primary_member_subscriber_id: "",
            skip_this_record_from_term_by_absence: ""
          }
        ]
      }
    }));
  };

  _updateDependents = (el, dropdown, index) => {
    const { isMemberExist, demographicData } = this.state;
    const { name, value } = dropdown || el.currentTarget;
    this.setState((prevState) => {
      const updatedDependents = [...prevState.demographicData.dependents];
      updatedDependents[index] = {
        ...updatedDependents[index],
        [name]: value
      };

      return {
        ...prevState,
        demographicData: {
          ...prevState.demographicData,
          dependents: updatedDependents
        }
      };
    });
  };

  _removeDependent = (index) => {
    this.setState((prevState) => {
      const updatedDependents = [...prevState.demographicData.dependents];
      updatedDependents.splice(index, 1);

      return {
        ...prevState,
        demographicData: {
          ...prevState.demographicData,
          dependents: updatedDependents
        }
      };
    });
  };

  updateForm = (value) => {
    console.log(value);
  };
  _clearForm = () => {
    this.setState({
      searchForm: {
        plan_name: "",
        plan_year: "",
        status: "Published",
        carrier: "",
        account: ""
      },
      demographicData: {
        first_name: "",
        last_name: "",
        email: "",
        dob: "",
        phone_number: null,
        address1: "",
        address2: "",
        city: "",
        state: "",
        zipcode: "",
        ssn: "",
        medicare_id: "",
        subscriber_id: "",
        tpa_id: "",
        effective_start_date: "",
        effective_end_date: "",
        gender: "",
        type: "",
        member_type: "primary",
        primary_member_subscriber_id: "",
        dependents: []
      },
      planNameOptions: [],
      client: "",
      carrier: "",
      account: "",
      group: "",
      personId: "",
      planData: [],
      selectedPlanData: {},
      isMemberExist: false,
      memberChecked: false,
      savedMemberId: "",
      isPrimaryCardHolder: true,
      type: "Test member",
      skip_this_record_from_term_by_absence: "No",
      ignore_flle_updates: "No"
    });
  };

  render() {
    const {
      state: {
        searchForm,
        memberChecked,
        savedMemberId,
        planData,
        demographicData,
        planNameOptions,
        selectedPlanData,
        isMemberExist,
        isPrimaryCardHolder,
        skip_this_record_from_term_by_absence,
        ignore_flle_updates
      }
    } = this;
    const {
      state: { user, formularyData },
      priorAuthorizationFlow
    } = this.props;
    const domainOptions = user.accounts_access.map((d) => ({
      key: d.id,
      text: d.name,
      value: d.id
    }));

    // http://localhost:3000/manual-eligibility-entry
    return (
      <div id="manualEligibilityEntry">
        <BreadCrumb {...this.props} />
        {!priorAuthorizationFlow && (
          <div className="mainHeader">
            <h2>Manual Eligibility Entry</h2>
          </div>
        )}
        {/* {!priorAuthorizationFlow && (
          <div className="header">
            <SubmenuNav active="primaryCardholder" />
          </div>
        )} */}
        <div className="content">
          <div className="section">
            <div className="section-header fields-container">
              {/* <h2>Plan Search</h2> */}
            </div>
            <div className="fields-container">
              <div className="fields-header">
                <PlanSearchForm
                  hierarchy={user.userHierarchy}
                  updateHierarchy={this._updateHierarchy}
                />
              </div>
            </div>
          </div>
        </div>

        <MemberDemographic
          _updateDemographicData={this._updateDemographicData}
          isPrimaryCardHolder={isPrimaryCardHolder}
          demographicData={demographicData}
          _checkIfMemberExist={this._checkIfMemberExist}
          _generateMember={this._generateMember}
          isMemberExist={isMemberExist}
          memberChecked={memberChecked}
          updateMemberType={this._updateMemberType}
          addDependentSection={this._addDependentSection}
          updateDependents={this._updateDependents}
          removeDependent={this._removeDependent}
          skipRecord={skip_this_record_from_term_by_absence}
          ignoreFileUpdates={ignore_flle_updates}
        />

        {!priorAuthorizationFlow && (
          <div className="buttons-container">
            <div className="fields">
              <FliptButton
                name="CLEAR"
                className="primary"
                onClick={this._clearForm}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  state: {
    memberLookup: state.memberLookup,
    user: state.user,
    benefitPlanData: state.planManagement.benefitPlanData,
    planLookupData: state.planManagement.planLookupData,
    memberExistData: state.memberLookup.memberExistData,
    saveUserData: state.memberLookup.saveUserData,
    formularyData: state.formularyCreation.formularyData
  }
});

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
    ...PlanManagementActions,
    ...MemberLookupActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManualEligibilityEntry)