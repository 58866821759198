import { isEmpty } from "lodash";

export const validate = {
    // flipt_person_id: ({ flipt_person_id }) => isEmpty(flipt_person_id) ? "flipt Person Id should not be empty." : "",
    edits: ({ edits }) => {
        if (edits?.length) {
            return edits.map((row, index) => {
                let columns = Object.keys(row)
                const empty = columns.filter(c => !row[c].toString())
                if ((empty.includes('ndc') && (empty.includes('gpi')))) {
                    return `Both NDC and GPI in row ${index + 1} should not be empty.`
                }
                if ((empty.includes('edit_type'))) {
                    return `Edit Type in row ${index + 1} should not be empty.`
                }
            }).filter((data) => (data !== undefined))
        }
    }
}