import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import _ from 'lodash'
import { Creators as PlanManagementActions } from '../../../../redux/reducers/api/planManagement'
import './styles.scss'
import FliptDropdown from '../../../../components/form/fliptDropdown'
import FliptInput from '../../../../components/form/fliptInput'
import FliptGrid from '../../../../components/fliptGrid'
import { DatePickerEditor, DropdownEditor, InputTextEditor } from '../../../../components/fliptGrid/cellEditors'
import rowCellInfo from './rowCellInfo'
import { DeleteRowRenderer } from '../../../../components/fliptGrid/cellRenderers'
import AddRow from '../addRow'

class ClaimProcessingConfig extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ClaimProcessingConfigData: {
        carry_over_accumulations_rx_oop: '',
        carry_over_accumulations_rx_ded: '',
        carry_over_accumulations_med_oop: '',
        carry_over_accumulations_med_ded: ''
      },
      gridData: [{ allow_tpa_id_processing: '', end_date: '', message: '' }],
      pa_match_level: '',
      brand_generic: '',
      bmn_override_match_level: '',
      bmn_brand_generic: '',
      drug_coverage_override_match_level: '',
      dc_brand_generic: '',
      prescription_written_fill_days: 0,
      claim_reversal_days: 0,
      future_fill_days: 0,
      drug_not_covered_can_be_dispensed: '',
      manufacturer_based_pricing_flag: '',
      send_cross_accumulation: '',
      rra_daysofsupply_fillcount: null,
      manufacturer_rra: '',
      manufacturer_rra_penalty: null,
      manufacturer_rra_penalty_type: '',
      specialty_rra: '',
      specialty_rra_penalty: null,
      specialty_rra_penalty_type: '',
      standard_rra: '',
      standard_rra_penalty: '',
      standard_rra_penalty_type: '',
    }
    const allowTpaIdOptions = [
      {
        key: '0',
        value: 'Yes',
        text: 'Yes'
      },
      {
        key: '1',
        value: 'No',
        text: 'No'
      }
    ]
    rowCellInfo.allow_tpa_id_processing.options = allowTpaIdOptions
  }
  componentDidMount() {
    const { modelData } = this.props

    if (modelData?.tpa_id_processing_config?.length) {
      this.setState({ gridData: modelData.tpa_id_processing_config })
    }
    if (modelData?.carry_over_accumulation_settings) {
      const {
        pa_match_level,
        brand_generic,
        bmn_override_match_level,
        bmn_brand_generic,
        drug_coverage_override_match_level,
        dc_brand_generic,
        prescription_written_fill_days,
        claim_reversal_days,
        future_fill_days,
        drug_not_covered_can_be_dispensed,
        manufacturer_based_pricing_flag,
        send_cross_accumulation,
        rra_daysofsupply_fillcount,
        manufacturer_rra,
        manufacturer_rra_penalty,
        manufacturer_rra_penalty_type,
        specialty_rra,
        specialty_rra_penalty,
        specialty_rra_penalty_type,
        standard_rra,
        standard_rra_penalty,
        standard_rra_penalty_type,
        carry_over_accumulation_settings: ClaimProcessingConfigData,
      } = modelData
      this.setState({
        ClaimProcessingConfigData,
        pa_match_level,
        brand_generic,
        bmn_override_match_level,
        bmn_brand_generic,
        drug_coverage_override_match_level,
        dc_brand_generic,
        prescription_written_fill_days,
        claim_reversal_days,
        future_fill_days,
        drug_not_covered_can_be_dispensed,
        manufacturer_based_pricing_flag,
        send_cross_accumulation,
        rra_daysofsupply_fillcount,
        manufacturer_rra,
        manufacturer_rra_penalty,
        manufacturer_rra_penalty_type,
        specialty_rra,
        specialty_rra_penalty,
        specialty_rra_penalty_type,
        standard_rra,
        standard_rra_penalty,
        standard_rra_penalty_type,
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { copyFormData, modelData } = this.props
    if (copyFormData && !_.isEqual(prevState, this.state)) {
      const { ClaimProcessingConfigData, gridData, ...other } = this.state
      let newModelData = { ...modelData, ...other }
      newModelData.tpa_id_processing_config = gridData
      newModelData.carry_over_accumulation_settings = ClaimProcessingConfigData
      copyFormData(newModelData)
    }
  }

  _updateFields = (el, dateData) => {
    const { name, value } = dateData || el.currentTarget

    this.setState((prevState) => ({
      ...prevState,
      ClaimProcessingConfigData: {
        ...prevState.ClaimProcessingConfigData,
        [name]: value,
      },
    }))
  }

  _updateFormFields = (el, dateData) => {
    const { name, value } = dateData || el.currentTarget
    this.setState({ [name]: value })
  }

  handleChange = (el, dropdown, rowIndex, gridApi) => {
    const { gridData } = this.state
    let { name, value } = dropdown || el.currentTarget
    if (name === 'allow_tpa_id_processing' && value === 'No') {
      gridData[rowIndex].end_date = ''
    }

    gridData[rowIndex][name] = value
    this.setState({ gridData: gridData })
  }

  delRow = (rowIndex) => {
    const { gridData } = this.state
    const newForm = _.cloneDeep(gridData)
    newForm.splice(rowIndex, 1)
    this.setState({ gridData: newForm })
  }

  addRow = () => {
    const row = { allow_tpa_id_processing: '', end_date: '', message: '' }
    const { gridData } = this.state
    let newForm = [...gridData]
    newForm = newForm?.length ? [...newForm, row] : [row]
    this.setState({ gridData: newForm })
  }

  _updateMatchLevelFields = (el, dateData) => {
    const { name, value } = dateData || el.currentTarget
    this.setState((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  paMatchLevelOptions = ["GPI2", "GPI4", "GPI6", "GPI8", "GPI10", "GPI12", "GPI14"]
  brandOptions = ["B", "T", "G"]
  getDropdownOptions = (options) => options.map(option => ({ text: option, value: option }))
  render() {
    const { gridData, ClaimProcessingConfigData: c, ...f } = this.state

    const options = [{
      key: 1,
      text: 'Yes',
      value: 'Y',
    },
    {
      key: 2,
      text: 'No',
      value: 'N',
    }]

    const penaltyTypeOptions =
      [{
        key: 1, value: "percentage", text: "Percentage"
      }, { key: 2, value: "fixed", text: "Fixed" }]

    const headers = ['allow_tpa_id_processing', 'end_date', 'message', 'action']
    const cellRendererParams = {
      message: {
        width: 300,
      },
      action: {
        cellRenderer: DeleteRowRenderer,
        state: {
          onClick: this.delRow,
        },
        width: 135,
      },
    }
    const cellEditorParams = {
      allow_tpa_id_processing: {
        editable: true,
        cellEditor: DropdownEditor,
        onChange: this.handleChange,
      },
      end_date: {
        editable: true,
        cellEditor: DatePickerEditor,
        onChange: this.handleChange,
        isDisabled: function (data) {
          const { allow_tpa_id_processing } = data
          return allow_tpa_id_processing === 'No'
        },
      },
      message: {
        editable: true,
        cellEditor: InputTextEditor,
        onChange: this.handleChange,
        isDisabled: function (data) {
          const { end_date, allow_tpa_id_processing } = data
          return (allow_tpa_id_processing === 'Yes' && end_date === '')
        },
      }
    }
    return (
      <div className="compounds" >

        <div className="section-compounds">
          <div className="compounds-dropdowns">
            <FliptDropdown
              placeholder="Select"
              label="Carry Over Accumulations Rx OOP"
              name="carry_over_accumulations_rx_oop"
              value={c.carry_over_accumulations_rx_oop}
              options={options}
              onChange={this._updateFields}
              stylized
            />
          </div>
          <div className="compounds-dropdowns">
            <FliptDropdown
              placeholder="Select"
              label="Carry Over Accumulations Rx Deductible"
              name="carry_over_accumulations_rx_ded"
              value={c.carry_over_accumulations_rx_ded}
              options={options}
              onChange={this._updateFields}
              stylized
            />
          </div>
          <div className="compounds-dropdowns">
            <FliptDropdown
              placeholder="Select"
              label="Carry Over Accumulations Medical OOP"
              name="carry_over_accumulations_med_oop"
              value={c.carry_over_accumulations_med_oop}
              options={options}
              onChange={this._updateFields}
              stylized
            />
          </div>
          <div className="compounds-dropdowns">
            <FliptDropdown
              placeholder="Select"
              label="Carry Over Accumulations Medical Deductible"
              name="carry_over_accumulations_med_ded"
              value={c.carry_over_accumulations_med_ded}
              options={options}
              onChange={this._updateFields}
              stylized
            />
          </div>
        </div>
        <div className="section-compounds">
          <div className='compounds-dropdowns'>
            <FliptDropdown
              clearable={false}
              description="..."
              disabled={false}
              key='pa_match_level'
              label='PA Match Level'
              multiple={false}
              name='pa_match_level'
              onChange={this._updateMatchLevelFields}
              options={this.getDropdownOptions(this.paMatchLevelOptions)}
              option_save_keys='pa_match_level'
              value={f?.pa_match_level || ''}
              scrolling
              search
              selection
              selectOnBlur={false}
              stylized='true'
            />
          </div>
          <div className='compounds-dropdowns'>
            <FliptDropdown
              clearable={false}
              description="..."
              disabled={false}
              key='brand_generic'
              label='Brand/Generic'
              multiple={false}
              name='brand_generic'
              onChange={this._updateMatchLevelFields}
              options={this.getDropdownOptions(this.brandOptions)}
              option_save_keys='brand_generic'
              value={f?.brand_generic || ''}
              scrolling
              search
              selection
              selectOnBlur={false}
              stylized='true'
            />
          </div>
          <div className='compounds-dropdowns'>
            <FliptDropdown
              clearable={false}
              description="..."
              disabled={false}
              key='bmn_override_match_level'
              label='BMN Override Match Level'
              multiple={false}
              name='bmn_override_match_level'
              onChange={this._updateMatchLevelFields}
              options={this.getDropdownOptions(this.paMatchLevelOptions)}
              option_save_keys='bmn_override_match'
              value={f?.bmn_override_match_level || ''}
              scrolling
              search
              selection
              selectOnBlur={false}
              stylized='true'
            />
          </div>
          <div className='compounds-dropdowns'>
            <FliptDropdown
              clearable={false}
              description="..."
              disabled={false}
              key='bmn_brand_generic'
              label='BMN Brand/Generic'
              multiple={false}
              name='bmn_brand_generic'
              onChange={this._updateMatchLevelFields}
              options={this.getDropdownOptions(this.brandOptions)}
              option_save_keys='bmn_brand_generic'
              value={f?.bmn_brand_generic || ''}
              scrolling
              search
              selection
              selectOnBlur={false}
              stylized='true'
            />
          </div>
        </div>
        <div className="section-compounds">
          <div className='compounds-dropdowns'>
            <FliptDropdown
              clearable={false}
              description="..."
              disabled={false}
              key='drug_coverage_override_match_level'
              label='Drug Coverage Match Level'
              multiple={false}
              name='drug_coverage_override_match_level'
              onChange={this._updateMatchLevelFields}
              options={this.getDropdownOptions(this.paMatchLevelOptions)}
              option_save_keys='drug_coverage_override_match'
              value={f?.drug_coverage_override_match_level || ''}
              scrolling
              search
              selection
              selectOnBlur={false}
              stylized='true'
            />
          </div>
          <div className='compounds-dropdowns'>
            <FliptDropdown
              clearable={false}
              description="..."
              disabled={false}
              key='dc_brand_generic'
              label='Drug Coverage Brand/Generic'
              multiple={false}
              name='dc_brand_generic'
              onChange={this._updateMatchLevelFields}
              options={this.getDropdownOptions(this.brandOptions)}
              option_save_keys='dc_brand_generic'
              value={f?.dc_brand_generic || ''}
              scrolling
              search
              selection
              selectOnBlur={false}
              stylized='true'
            />
          </div>
        </div>
        <div className="section-compounds">
          <div className='compounds-dropdowns'>
            <FliptInput
              datatype="numeric"
              description="..."
              disabled={false}
              key='prescription_written_fill_days'
              label='Prescription Written Fill Days'
              name='prescription_written_fill_days'
              required={false}
              onChange={this._updateFormFields}
              value={f?.prescription_written_fill_days || 0}
              stylized="true"
            />
          </div>
          <div className='compounds-dropdowns'>
            <FliptInput
              datatype="numeric"
              description="..."
              disabled={false}
              key='claim_reversal_days'
              label='Claim Reversal Days'
              name='claim_reversal_days'
              required={false}
              onChange={this._updateFormFields}
              value={f?.claim_reversal_days || 0}
              stylized="true"
            />
          </div>
          <div className='compounds-dropdowns'>
            <FliptInput
              datatype="numeric"
              description="..."
              disabled={false}
              key='future_fill_days'
              label='Future Fill Days'
              name='future_fill_days'
              required={false}
              onChange={this._updateFormFields}
              value={f?.future_fill_days || 0}
              stylized="true"
            />
          </div>
        </div>
        <div className="section-compounds">
          <div className='compounds-dropdowns'>
            <FliptDropdown
              clearable={false}
              description="..."
              disabled={false}
              key='drug_not_covered_can_be_dispensed'
              label='Drug Not Covered Can be Dispensed'
              multiple={false}
              name='drug_not_covered_can_be_dispensed'
              options={options}
              option_save_keys='drug_not_covered_can_be_dispensed'
              value={f?.drug_not_covered_can_be_dispensed || ''}
              scrolling
              search
              selection
              selectOnBlur={false}
              stylized='true'
            />
          </div>
          <div className='compounds-dropdowns'>
            <FliptDropdown
              clearable={false}
              description="..."
              disabled={false}
              key='manufacturer_based_pricing_flag'
              label='Manufacturer Based Pricing Flag'
              name='manufacturer_based_pricing_flag'
              options={options}
              option_save_keys='manufacturer_based_pricing_flag'
              multiple={false}
              required={false}
              value={f?.manufacturer_based_pricing_flag || ''}
              scrolling
              search
              selection
              selectOnBlur={false}
              stylized="true"
            />
          </div>
          <div className='compounds-dropdowns'>
            <FliptDropdown
              clearable={false}
              description="..."
              disabled={false}
              key='send_cross_accumulation'
              label='Send Cross Accumulation'
              multiple={false}
              name='send_cross_accumulation'
              options={options}
              option_save_keys='send_cross_accumulation'
              value={f?.send_cross_accumulation || ''}
              scrolling
              search
              selection
              selectOnBlur={false}
              stylized='true'
            />
          </div>
        </div>
        <div className="section-compounds">
          <div className='compounds-dropdowns'>
            <FliptInput
              datatype="numeric"
              description="..."
              disabled={false}
              key='rra_daysofsupply_fillcount'
              label='RRA Days of Supply Fill Count'
              name='rra_daysofsupply_fillcount'
              required={false}
              onChange={this._updateFormFields}
              value={f?.rra_daysofsupply_fillcount || null}
              stylized="true"
            />
          </div>
        </div>
        <div className="section-compounds">
          <div className='compounds-dropdowns'>
            <FliptDropdown
              clearable={false}
              description="..."
              disabled={false}
              key='manufacturer_rra'
              label='Manufacturer RRA'
              multiple={false}
              name='manufacturer_rra'
              options={options}
              option_save_keys='manufacturer_rra'
              value={f?.manufacturer_rra || ''}
              scrolling
              search
              selection
              selectOnBlur={false}
              stylized='true'
            />
          </div>
          <div className='compounds-dropdowns'>
            <FliptInput
              datatype="numeric"
              description="..."
              disabled={false}
              key='manufacturer_rra_penalty'
              label='Manufacturer RRA Penalty'
              name='manufacturer_rra_penalty'
              required={false}
              onChange={this._updateFormFields}
              value={f?.manufacturer_rra_penalty || null}
              stylized="true"
            />
          </div>
          <div className='compounds-dropdowns'>
            <FliptDropdown
              clearable={false}
              description="..."
              disabled={false}
              key='manufacturer_rra_penalty_type'
              label='Manufacturer RRA Penalty Type'
              multiple={false}
              name='manufacturer_rra_penalty_type'
              options={penaltyTypeOptions}
              option_save_keys='manufacturer_rra_penalty_type'
              value={f?.manufacturer_rra_penalty_type || ''}
              scrolling
              search
              selection
              selectOnBlur={false}
              stylized='true'
            />
          </div>
        </div>
        <div className="section-compounds">
          <div className='compounds-dropdowns'>
            <FliptDropdown
              clearable={false}
              description="..."
              disabled={false}
              key='specialty_rra'
              label='Speciality RRA'
              multiple={false}
              name='specialty_rra'
              options={options}
              option_save_keys='specialty_rra'
              value={f?.specialty_rra || ''}
              scrolling
              search
              selection
              selectOnBlur={false}
              stylized='true'
            />
          </div>
          <div className='compounds-dropdowns'>
            <FliptInput
              datatype="numeric"
              description="..."
              disabled={false}
              key='specialty_rra_penalty'
              label='Specialty RRA Penalty'
              name='specialty_rra_penalty'
              required={false}
              onChange={this._updateFormFields}
              value={f?.specialty_rra_penalty || null}
              stylized="true"
            />
          </div>
          <div className='compounds-dropdowns'>
            <FliptDropdown
              clearable={false}
              description="..."
              disabled={false}
              key='specialty_rra_penalty_type'
              label='Specialty RRA Penalty Type'
              multiple={false}
              name='specialty_rra_penalty_type'
              options={penaltyTypeOptions}
              option_save_keys='specialty_rra_penalty_type'
              value={f?.specialty_rra_penalty_type || ''}
              scrolling
              search
              selection
              selectOnBlur={false}
              stylized='true'
            />
          </div>
        </div>
        <div className='grid-header'>TPA ID Processing Configuration</div>
        <div className='grid-container'>
          <FliptGrid headers={headers} data={gridData}
            cellEditorParams={cellEditorParams}
            cellRendererParams={cellRendererParams}
            rowCellInfo={rowCellInfo}
          />
          <div className="addRowButtonContainer">
            <AddRow addRow={() => this.addRow()} name='Add Row' />
          </div>
        </div>
      </div >
    )
  }
}

const mapStateToProps = (state, props) => {
  return ({
    state: {
      app: state.app,
      compoundsData: state.planManagement.compoundsData,
    },
  })
}
const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...PlanManagementActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClaimProcessingConfig)
