import React, { useState } from 'react'
import { Accordion, Icon } from 'semantic-ui-react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import './styles.scss'
import FliptGrid from '../../../../components/fliptGrid'
import { Creators as UserActions } from '../../../../redux/reducers/api/formularyCreationManagement'
import { filterRowData } from '../../../../utils/utilities'
import { opioidHeaders } from '../../drugUtilizationReview/create/gridData/data'

const GeneratedOpioid = (props) => {
    const [activeTierLevel, setActiveTierLevel] = useState(0)
    const { opioidProgramData, programName, viewOnly } = props
    const headers = opioidHeaders
    headers.shift()
    const accData = programName.map((data) => {
        const index = opioidProgramData.findIndex((pData) => pData.doc_id === data)
        if (index >= 0) return (opioidProgramData[index])
    })

    const cellRendererParams = {
        reject_type: {
            overrideHeader: 'Action Taken',
        },
        short_acting_mme: {
            overrideHeader: 'Short Acting MME Limit'
        },
        long_acting_mme: {
            overrideHeader: 'Long Acting MME Limit'
        },
        mme_limit: {
            overrideHeader: 'MME Limit'
        },
        first_fill_days_supply_limitation: {
            overrideHeader: 'Days Supply Limitation for First Fill'
        },
        non_naive_lookback: {
            overrideHeader: 'Lookback Period'
        },
        non_naive_threshold: {
            overrideHeader: 'Threshold'
        }
    }
    if (!accData?.length || !opioidProgramData?.length || !programName?.length) return (<div />)

    const _handleTierLevelClick = (e, titleProps) => {
        const { index } = titleProps
        const newIndex = activeTierLevel === index ? -1 : index

        setActiveTierLevel(newIndex)
    }

    return (
        <Accordion className="section-tier-levels-accordion" styled>
            {
                accData.map((tier, idx) => {
                    const tierLevelHeading = `${tier.doc_name} v${tier.doc_version}`
                    const gridData = tier.conditions && tier.conditions.length ? tier.conditions.map((row) => ({
                        ...filterRowData(row, headers),
                    })) : []
                    return (
                        <div className="section-tier-level">
                            <Accordion.Title
                                active={activeTierLevel === idx}
                                index={idx}
                                onClick={_handleTierLevelClick}
                            >
                                <Icon name="dropdown" />
                                {tierLevelHeading}
                            </Accordion.Title>
                            <Accordion.Content
                                active={activeTierLevel === idx}
                            >
                                <div className="section-tier-conditions">
                                    <FliptGrid
                                        cellRendererParams={cellRendererParams}
                                        data={gridData}
                                        headers={headers}
                                    />
                                </div>
                            </Accordion.Content>
                        </div>
                    )
                })
            }
        </Accordion>

    )
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...UserActions,
    }

    return {
        actions: bindActionCreators(allActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GeneratedOpioid)
