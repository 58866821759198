import Immutable from 'seamless-immutable'
import { createActions, createReducer } from 'reduxsauce'

export const INITIAL_STATE = Immutable({
    data: [],
    totalRows: 0,
    claimsPerPage: 20
})

export const setClaimErrorManagementDataReducer = (state = INITIAL_STATE, { payload }) => ({
    ...state,
    data: payload.data,
    totalRows: payload.totalRows,
  })

export const clearReducer = () => INITIAL_STATE

export const { Types, Creators } = createActions({

    getClaimErrorManagementData: ['payload'],
    setClaimErrorManagementData: ['payload'],
    clearReducer: null
  })

  const HANDLERS = {
    [Types.CLEAR_REDUCER]: clearReducer,
    [Types.SET_CLAIM_ERROR_MANAGEMENT_DATA]: setClaimErrorManagementDataReducer,
  }
  
  export default createReducer(INITIAL_STATE, HANDLERS)