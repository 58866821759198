import { call, put, select, takeLatest, take } from 'redux-saga/effects'

import { Creators as AppActions, Types as AppTypes } from '../../reducers/app'
import { Creators as RPMActions } from '../../reducers/rpm'
import { Types, Creators as ClaimsPRPActions } from '../../reducers/api/claimsProcessingAndReprocessing'
import { Creators as IntegrationManagementActions } from '../../reducers/api/integrationManagement'
import { getAppState, getApiPath, isMenuEmpty } from '../../reducers/selectors'
import { fetchPost } from '../../../utils/fetchApi'
import { Creators as NavigationActions } from '../../reducers/navigation'
import { readFileAsDataURL } from '../../../utils/utilities'

export default [
  approveTestBatchWatcher,
  getPharmacyNetworkWatcher,
  getTestBatchConfigPlansWatcher,
  getTestBatchDetailsWatcher,
  getTestBatchesWatcher,
  getUmObjectsWatcher,
  rejectTestBatchWatcher,
  submitTestBatchWatcher,
  uploadClaimFileWatcher,
  getTestClaimsByBatchIdWatcher,
  getTestBatchWatcher,
  getTestBedsWatcher,
  addTestBedWatcher,
  pullProductionClaimsWatcher,
  uploadBatchCriteriaFileWatcher,
]

/* WATCHERS */
function* getTestClaimsByBatchIdWatcher() {
  yield takeLatest(Types.GET_TEST_CLAIMS_BY_BATCH_ID, getTestClaimsByBatchIdHandler)
}

function* getUmObjectsWatcher() {
  yield takeLatest(Types.GET_UM_OBJECTS, getUmObjectsHandler)
}

function* getTestBatchConfigPlansWatcher() {
  yield takeLatest(Types.GET_TEST_BATCH_CONFIG_PLANS, getTestBatchConfigPlansHandler)
}

function* getTestBatchesWatcher() {
  yield takeLatest(Types.GET_TEST_BATCHES, getTestBatchesHandler)
}

function* getTestBatchDetailsWatcher() {
  yield takeLatest(Types.GET_TEST_BATCH_DETAILS, getTestBatchDetailsHandler)
}

function* submitTestBatchWatcher() {
  yield takeLatest(Types.SUBMIT_TEST_BATCH, submitTestBatchHandler)
}

function* approveTestBatchWatcher() {
  yield takeLatest(Types.APPROVE_TEST_BATCH, approveTestBatchHandler)
}

function* uploadClaimFileWatcher() {
  yield takeLatest(Types.UPLOAD_CLAIM_FILE, uploadClaimFileHandler)
}

function* rejectTestBatchWatcher() {
  yield takeLatest(Types.REJECT_TEST_BATCH, rejectTestBatchHandler)
}

function* getPharmacyNetworkWatcher() {
  yield takeLatest(Types.GET_PHARMACY_NETWORK, getPharmacyNetworkHandler)
}

function* getTestBatchWatcher() {
  yield takeLatest(Types.GET_TEST_BATCH, getTestBatchHandler)
}

function* getTestBedsWatcher() {
  yield takeLatest(Types.GET_TEST_BEDS, getTestBedsHandler)
}

function* addTestBedWatcher() {
  yield takeLatest(Types.ADD_TEST_BED, addTestBedHandler)
}

function* pullProductionClaimsWatcher() {
  yield takeLatest(Types.PULL_PRODUCTION_CLAIMS, pullProductionClaimsHandler)
}

function* uploadBatchCriteriaFileWatcher() {
  yield takeLatest(Types.UPLOAD_BATCH_CRITERIA_FILE, uploadBatchCriteriaFileHandler)
}

/* HANDLERS */
function* getUmObjectsHandler({ payload }) {
  try {
    yield put(ClaimsPRPActions.clearUmObjects())
    const cb = function* (data) {
      const umObjects = {
        programEdits: data.filter((doc) => doc.module === 'PROGRAM_LIST'),
        tiers: data.filter((doc) => doc.module === 'FORMULARY_TIER'),
        priorAuths: data.filter((doc) => doc.module === 'PRIOR_AUTHORIZATION'),
        quantityLimits: data.filter((doc) => doc.module === 'QUANTITY_LIMIT'),
        stepTherapies: data.filter((doc) => doc.module === 'STEP_THERAPY')
      }
      yield put(ClaimsPRPActions.setUmObjects(umObjects))
    }
    const body = {
      module: ['FORMULARY_TIER', 'PRIOR_AUTHORIZATION', 'QUANTITY_LIMIT', 'STEP_THERAPY', 'PROGRAM_LIST'],
      status: [...payload.status],
      cag: { ...payload.cag },
    }
    yield put(RPMActions.rpmGetApprovalDocuments(body, cb))

  } catch (err) {
    console.log('getUmObjectsHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Error occurred while fetching UM objects',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* getPharmacyNetworkHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const { api_path } = yield select(getApiPath, 'get-pharmacy-networks')
    const url = `${serviceUrl}${api_path}`
    const response = yield call(fetchPost, url, payload)
    const pharmacyNetworks = response.data[0]
    yield put(ClaimsPRPActions.setPharmacyNetworks(pharmacyNetworks))
  } catch (err) {
    console.log('getPharmacyNetworkHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'getPharmacyNetworkHandler Failed',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* getTestBatchDetailsHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const menuEmpty = yield select(isMenuEmpty)
    if (menuEmpty) {
      yield take([AppTypes.SET_APP_SETTINGS])
    }
    const cd = yield select(getApiPath, 'get-test-batch')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`
    const { data } = yield call(fetchPost, url, payload)
    if (!data?.length) {
      const transitionalPortal = {
        header: 'No Data Found',
        copy: 'No Details Found for Given Batch ID',
      }
      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
      return
    }
    yield put(ClaimsPRPActions.setTestBatchDetails(data[0].test_batch_details))
    yield put(ClaimsPRPActions.setTestBatchClaims(data[0].test_claims))
  } catch (err) {
    console.log('getBatchDetailsHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Getting Batch Details Failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

// function convertDatabaseTestClaimToClient(testClaim) {
//   return {
//     "pharmacy_id": testClaim.service_provider_id,
//     "date_of_service": testClaim.date_of_service,
//     "member_id": testClaim.cardholder_id,
//     "cardholder_first_name": testClaim.cardholder_first_name,
//     "cardholder_last_name": testClaim.cardholder_last_name,
//     "group_id": testClaim.group_id,
//     "person_code": testClaim.person_code,
//     "date_of_birth": testClaim.date_of_birth,
//     "patient_first_name": testClaim.patient_first_name,
//     "patient_last_name": testClaim.patient_last_name,
//     "prescription_reference_number": testClaim.prescription_reference_number,
//     "ndc": testClaim.product_id,
//     "quantity_dispensed": testClaim.quantity_dispensed,
//     "fill_number": testClaim.fill_number,
//     "days_supply": testClaim.days_supply,
//     "daw_code": testClaim.dispense_as_written,
//     "compound_code": testClaim.compound_code,
//     "date_prescription_written": testClaim.date_prescription_written,
//     "prescription_origin_code": testClaim.prescription_origin_code,
//     "prescriber_id_qualifier": testClaim.prescriber_id_qualifier,
//     "prescriber_id": testClaim.prescriber_id,
//     "prescriber_last_name": testClaim.prescriber_last_name,
//     "prescriber_first_name": testClaim.prescriber_first_name,
//     "prescriber_phone": testClaim.prescriber_phone,
//     "ingredient_cost_submitted": testClaim.ingredient_cost_submitted,
//     "flat_sales_tax_amount_submitted": testClaim.flat_sales_tax_amount_submitted,
//     "percentage_sales_tax_amount_submitted": testClaim.percentage_sales_tax_amount_submitted,
//     "percentage_sales_tax_rate_submitted": testClaim.percentage_sales_tax_rate_submitted,
//     "percentage_sales_tax_basis_submitted": testClaim.percentage_sales_tax_basis_submitted,
//     "incentive_amount_submitted": testClaim.incentive_amount_submitted,
//     "dispensing_fee_submitted": testClaim.dispensing_fee_submitted,
//     "patient_paid_amount_submitted": testClaim.patient_paid_amount_submitted,
//     "gross_amount_due": testClaim.gross_amount_due,
//     "usual_and_customary_charge": testClaim.usual_and_customary_charge,
//     "basis_of_cost_determination": testClaim.basis_of_cost_determination,
//     "test_batch_claim_id": testClaim.test_batch_claim_id
//   }

// }
function* getTestBatchHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const menuEmpty = yield select(isMenuEmpty)
    if (menuEmpty) {
      yield take([AppTypes.SET_APP_SETTINGS])
    }
    const cd = yield select(getApiPath, 'get-test-batch-by-id')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`
    const { data } = yield call(fetchPost, url, payload)
    if (!data?.length) {
      const transitionalPortal = {
        header: 'No Data Found',
        copy: 'No Details Found for Given Batch ID',
      }
      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
      return
    }

    yield put(ClaimsPRPActions.setTestBatchDetails(data[0].test_batch_details[0]))
    let testClaims = data[0].test_claims
    if (testClaims.length > 0) {
      yield put(IntegrationManagementActions.setIsMemberGenerated(true))
    } else {
      yield put(IntegrationManagementActions.setIsMemberGenerated(false))
    }
    yield put(ClaimsPRPActions.setTestBatchClaims(testClaims))
  } catch (err) {
    console.log('getBatchDetailsHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Getting Batch Details Failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* getTestBatchesHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const { api_path } = yield select(getApiPath, 'get-test-batches')
    const url = `${serviceUrl}${api_path}`
    const response = yield call(fetchPost, url, payload)
    const testBatches = response.data
    yield put(ClaimsPRPActions.setTestBatches(testBatches))
  } catch (err) {
    console.log('getTestBatchesHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'getTestBatchesHandler Failed',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* getTestBatchConfigPlansHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const { api_path } = yield select(getApiPath, 'get-test-batch-config-plans')
    const url = `${serviceUrl}${api_path}`
    const response = yield call(fetchPost, url, payload)
    const plans = response.data
    yield put(ClaimsPRPActions.setPlans(plans))
  } catch (err) {
    console.log('getTestBatchConfigPlansHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'getTestBatchConfigPlansHandler Failed',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* approveTestBatchHandler({ payload, callback }) {
  try {
    const { apiUrl } = yield select(getAppState)
    const { api_path } = yield select(getApiPath, 'approve-test-batch')
    const url = `${apiUrl}/fast${api_path}`
    const { data } = yield call(fetchPost, url, payload)
    if (data.length) {
      const transitionalPortal = {
        header: 'Batch approved successfully',
        copy: `${data[0].batch_name} Approved`,
      }
      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
      if (callback) callback()
      yield put(NavigationActions.navigateTo({
        pathname: '/claim-processing-reprocessing-dashboard',
      }))
    }
    else {
      const transitionalPortal = {
        header: 'Error occurred while approving the batch',
        copy: `could not approve batch: ${data[0].batch_name}`,
      }
      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    }

  } catch (err) {
    console.log('approveTestBatchHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Error occurred while approving the batch',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}


function* rejectTestBatchHandler({ payload, callback }) {
  try {
    const { apiUrl } = yield select(getAppState)
    const { api_path } = yield select(getApiPath, 'reject-test-batch')
    const url = `${apiUrl}/fast${api_path}`
    const { data } = yield call(fetchPost, url, payload)
    if (data.length) {
      const transitionalPortal = {
        header: 'Batch rejected successfully',
        copy: `${data[0].batch_name} Rejected`,
      }
      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
      if (callback) callback()
      yield put(NavigationActions.navigateTo({
        pathname: '/claim-processing-reprocessing-dashboard',
      }))
    }
    else {
      const transitionalPortal = {
        header: 'Error occurred while rejecting the batch',
        copy: `could not reject batch: ${data[0].batch_name}`,
      }
      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    }

  } catch (err) {
    console.log('rejectTestBatchHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Error occurred while rejecting the batch',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}


function* submitTestBatchHandler({ payload, callback }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const { api_path } = yield select(getApiPath, 'submit-test-batch')
    const url = `${serviceUrl}${api_path}`
    const response = yield call(fetchPost, url, payload)
    if (response.data?.length) {
      const test_batch_details = response.data[0]?.test_batch_details || {}
      let header = 'Test batch saved successfully'
      let message = `Test batch ${test_batch_details?.batch_name || ''} saved successfully`
      if (test_batch_details?.status === 'SUBMITTED') {
        header = 'Test batch submitted successfully'
        message = `Test batch ${test_batch_details?.batch_name || ''} successfully submitted for processing`
      }
      const transitionalPortal = {
        header,
        copy: message
      }
      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
      yield put(ClaimsPRPActions.setTestBatchDetails(response.data[0].test_batch_details))
      yield put(ClaimsPRPActions.setTestBatchClaims(response.data[0].test_claims))
      if (callback) callback()
    } else {
      const transitionalPortal = {
        header: 'Error occurred while submitting the batch',
        copy: response?.message || '',
      }
      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    }

  } catch (err) {
    console.log('submitTestBatchHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Error occurred while submitting the batch',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* uploadClaimFileHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const { api_path } = yield select(getApiPath, 'upload-test-batch-file')
    const url = `${serviceUrl}${api_path}`
    const fileDataURL = yield call(readFileAsDataURL, payload)
    const response = yield call(fetchPost, url, { file: fileDataURL, type: payload.type })
    yield put(ClaimsPRPActions.setUploadedClaimFile(response.data[0]))
  } catch (err) {
    console.log('uploadClaimFile Error >Data ', err)
    const transitionalPortal = {
      header: 'uploadClaimFile Failed',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* uploadBatchCriteriaFileHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const { api_path } = yield select(getApiPath, 'upload-test-batch-file')
    const url = `${serviceUrl}${api_path}`
    const fileDataURL = yield call(readFileAsDataURL, payload.file)
    const response = yield call(fetchPost, url, { file: fileDataURL, type: payload.file.type, fieldType: payload.fieldType})
    yield put(ClaimsPRPActions.setUploadedBatchCriteria(response.data[0].claim_data))
  } catch (err) {
    console.log('uploadBatchCriteriaFileHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'uploadBatchCriteriaFileHandler Failed',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* getTestClaimsByBatchIdHandler({ payload, callback }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const { api_path } = yield select(getApiPath, 'get-test-claims-by-batch-id')
    const url = `${serviceUrl}${api_path}`
    const response = yield call(fetchPost, url, payload)
    yield put(ClaimsPRPActions.setBatchIdClaims(response.data))
    if (callback) {
      yield callback(response.data)
    }
  } catch (err) {
    console.log('getTestClaimsByBatchId Error >Data ', err)
    const transitionalPortal = {
      header: 'getTestClaimsByBatchId Failed',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* getTestBedsHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const { api_path } = yield select(getApiPath, 'get-all-testing-beds')
    const url = `${serviceUrl}${api_path}`
    const response = yield call(fetchPost, url, payload)
    yield put(ClaimsPRPActions.setTestBeds(response.data[0]))
  } catch (err) {
    console.log('getTestBedsHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'getTestBedsHandler Failed',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* addTestBedHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const { api_path } = yield select(getApiPath, 'add-test-bed')
    const url = `${serviceUrl}${api_path}`
    const response = yield call(fetchPost, url, payload)
    const transitionalPortal = {
      header: 'Test Bed Added',
      copy: response.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  } catch (err) {
    console.log('addTestBedHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'addTestBedHandler Failed',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* pullProductionClaimsHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const { api_path } = yield select(getApiPath, 'pull-production-claims')
    const url = `${serviceUrl}${api_path}`
    const response = yield call(fetchPost, url, payload)
    yield put(ClaimsPRPActions.setProductionClaims(response.data[0]))
  } catch (err) {
    console.log('pullProductionClaimsHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'pullProductionClaimsHandler Failed',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}