import React, { Component, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Icon } from 'semantic-ui-react'

import CreatePlan from './createPlan'
import Config from '../../../config'

import FliptButton from '../../../components/form/fliptButton'
import CAGFilters from '../../../components/CAGFilters'
import FliptInput from '../../../components/form/fliptInput'
import FliptGrid from '../../../components/fliptGrid'
import { Creators as RPMActions } from '../../../redux/reducers/rpm'
import { LinkRenderer, EllipsisActionMenuRenderer, ColorCodedStatusRenderer } from '../../../components/fliptGrid/cellRenderers'
import increaseRowHeight from '../../../components/fliptGrid/cellClickEvents'

import { Creators as PlanManagementActions } from '../../../redux/reducers/api/planManagement'

import './styles.scss'
import BreadCrumb from '../../../components/breadCrumb'
import { filterRowData } from '../../../utils/utilities'
import CopyPlanDesignManagementObject from './CopyPlanDesignManagementObject'
import FliptPaInput from '../../../components/form/fliptPaInput'
import PopupCellRenderer from './popup/popupCellRenderer'

const PlanDesignManagement = (props) => {

  const [showCreateNewPlan, setShowCreateNewPlan] = useState(false);
  const [searchFilters, setSearchFilters] = useState({ plan_name: '', plan_year: '', plan_code: '' })
  const { actions } = props

  const [showPopupFlag, setShowPopupFlag] = useState(false);
  const [record, setRecord] = useState({});

  useEffect(() => {
    actions.clearPlanDetailsFilters()
    actions.clearPlanTabData()
  }, [])

  useEffect(() => {
    _getPlanDataOnceMenuDataIsFetched();
  }, [props?.state?.user?.userCAG?.carrier])

  const _getPlanDataOnceMenuDataIsFetched = () => {
    const p = props
    const s = props.state
    if (!s.user.userCAG) return
    actions.getBenefitPlanData({ carrier: s?.user?.userCAG?.carrier })
  }

  const handleOnCreateNewPlan = () => {
    actions.clearPlanTabData()
    actions.clearPlanDetailsFilters()
    actions.setPlanID({ data: null })
    actions.setPlanVersion({ data: null })
    actions.setPlanStatus({ data: null })
    actions.setApprovalDocID({ data: null })
    actions.setPlanName({ data: null })
    actions.setPlanLOB({ data: null })
  }

  const handleEnterEditMode = () => {
    actions.clearPlanTabData()
    actions.clearPlanDetailsFilters()
  }

  const _updateField = (el, dropdown) => {
    let search = { ...searchFilters }
    const { name, value } = dropdown || el.currentTarget
    search[name] = value
    setSearchFilters(search)
  }

  const getPlanFilterParams = () => {
    return {
      ...searchFilters,
    }
  }

  const searchBenefitPlans = () => {
    const planFilterParams = getPlanFilterParams()
    actions.getBenefitPlanData(planFilterParams)
  }

  const clearBenefitPlans = () => {
    const newFilter = { plan_name: '', plan_year: '', plan_code: '' };
    actions.getBenefitPlanData(newFilter);
    setSearchFilters(newFilter)
  }


  const { state } = props
  const {
    benefitPlanData,
  } = state

  const headers = [
    'action',
    'lob',
    'plan_name',
    'version',
    'status',
    'start_date',
    'end_date',
  ]
  const headerMapping = {
    plan_version: 'version',
    effective_start_date: 'start_date',
    effective_end_date: 'end_date',
  }

  const sendForReview = (form) => {
    const payload = {
      doc_id: form?.approval_doc_id,
      module: 'BENEFIT_PLAN_DOC',
    }
    actions.benefitPlanSendForReview(payload, form?.plan_id)
    setTimeout(() => searchBenefitPlans(), Config.COMPONENT_DATA_LOAD_TIME)
  }

  const createNewVersion = (row) => {
    const payload = {
      plan_id: row.plan_id,
      plan_version: row.version,
      approval_doc_id: row.approval_doc_id,
    }
    actions.createNewPlanVersion(payload)
  }

  const checkActionType = (action, rowdata) => {
    const plan_id = rowdata['plan_id']
    let data = benefitPlanData.filter(obj => { return obj.plan_id === plan_id })
    setRecord(data);
    if (action === 'copy_object')
      setShowPopupFlag(true);
    if (action === 'send_for_review')
      sendForReview(rowdata)
    if (action === 'create_new_version')
      createNewVersion(rowdata)
  }

  const cellRendererParams = {
    plan_name: {
      cellRenderer: LinkRenderer,
      path: '/create-benefit-plan',
      searchArgs: { plan_id: 'plan_id', approval_doc_id: 'approval_doc_id', plan_name: 'plan_name', lob: 'lob', plan_version: 'version', status: 'status' },
      state: { editMode: false },
      onCellClicked: handleEnterEditMode,
    },
    status: {
      cellRenderer: ColorCodedStatusRenderer,
    },
    action: {
      cellRenderer: PopupCellRenderer,
      searchArgs: {
        onClick: (action, rowdata) => checkActionType(action, rowdata),
      },
    },
    lob: {
      hide: false,
    },
    plan_id: {
      hide: true,
    },
    approval_doc_id: {
      hide: true,
    }
  }

  const reqFields = [
    'action',
    'plan_name',
    'version',
    'status',
    'start_date',
    'end_date',
    'plan_id',
    'approval_doc_id',
    'lob',
  ]
  const gridData = benefitPlanData && benefitPlanData.length ? benefitPlanData.map((d) => ({
    ...filterRowData(d, reqFields, headerMapping),
  })) : []

  const sortByName = (valueA, valueB) => {
    if (valueA.plan_name === valueB.plan_name) return 0;
    let x = valueA.plan_name.toLowerCase();
    let y = valueB.plan_name.toLowerCase();
    return (x > y) ? 1 : -1;
  }

  return (
    <div id="planDesignManagement">
      <BreadCrumb {...props} />
      <div className="header">
        Plan Design and Management
        <Link to={{ pathname: '/create-benefit-plan', state: { editMode: true } }} onClick={handleOnCreateNewPlan}>
          <div className="create-plan" aria-hidden="true">
            <Icon name="plus circle" size="tiny" />
            Create a new plan
          </div>
        </Link>
      </div>
      {showCreateNewPlan && <CreatePlan backToPlanManagement={() => setShowCreateNewPlan(false)} />}
      <div className="filter-Options">

        <div className="field-container">
          <FliptPaInput
            name="plan_name"
            placeholder="Plan Name"
            value={searchFilters.plan_name}
            onChange={_updateField}
            label="Plan Name"
            description="Plan Name"
            stylized="true"
          />
          <div className='button-container'>
            <FliptButton name="Search" className="primary searchButton" onClick={searchBenefitPlans} />
          </div>
          <div className='button-container'>
            <FliptButton name="Clear" className="primary searchButton" onClick={clearBenefitPlans} />
          </div>
        </div>
      </div>

      <div className="content">
        <FliptGrid data={gridData && gridData.length > 0 ? gridData.sort(sortByName) : []} headers={headers} cellRendererParams={cellRendererParams} />
      </div>
      {showPopupFlag && <CopyPlanDesignManagementObject data={record} showTabFlag={showPopupFlag} setShowTabFlag={setShowPopupFlag} searchBenefitPlans={searchBenefitPlans} />}
    </div>
  )
}


const mapStateToProps = (state) => ({
  state: {
    app: state.app,
    user: state.user,
    benefitPlanData: state.planManagement.benefitPlanData,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...PlanManagementActions,
    ...RPMActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlanDesignManagement)
