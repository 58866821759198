/* eslint-disable func-names */
import {
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects'

import { Creators as AppActions } from '../../reducers/app'
import { Types, Creators as AutoSuggestActions } from '../../reducers/api/autoSuggest'
import { fetchPost } from '../../../utils/fetchApi'
import { getAppState, getApiPath, getHistory } from '../../reducers/selectors'

export default [
	getAutoSuggestDataWatcher,
]

/* WATCHERS */
function* getAutoSuggestDataWatcher() {
	yield takeLatest(Types.GET_AUTO_SUGGEST_DATA, getAutoSuggestDataHandler)
}

/* HANDLERS */
function* getAutoSuggestDataHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const { search_in } = payload
    let cd = yield select(getApiPath, 'auto-suggest')
    if (search_in == 'global_drug_test') {
      cd = yield select(getApiPath, 'auto-suggest-with-um')
    }
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`
    yield put(AutoSuggestActions.setLoading(true))
    const { data } = yield call(fetchPost, url, payload, false)
    yield put(AutoSuggestActions.setLoading(false))
    yield put(AutoSuggestActions.setAutoSuggestData({ data: [...new Set(data.map((d) => d[payload.search_for]))], search_for: payload.search_for, search_string: payload.search_string })) 
  } catch (err) {
    console.log('getAutoSuggestDataHandler Error >Data ', err)

    const transitionalPortal = {
      header: 'Auto Suggest Getting Data Failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}
