import {
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects'

import { Types, Creators as TestClaimActions } from '../../reducers/api/testClaim'
import { Creators as AppActions } from '../../reducers/app'
import { getAppState, getApiPath } from '../../reducers/selectors'
import { fetchPost } from '../../../utils/fetchApi'

export default [
  submitTestClaimDataWatcher,
]

/* WATCHERS */
function* submitTestClaimDataWatcher() {
  yield takeLatest(Types.SUBMIT_TEST_CLAIM_DATA, submitTestClaimDataHandler)
}

/* HANDLERS */
function* submitTestClaimDataHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const cd = yield select(getApiPath, 'test-claim-generation')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`

    const { data } = yield call(fetchPost, url, payload)
    if (!data.length) throw Error('Test claim submission failed')
    yield put(TestClaimActions.setTestClaimData(data[0]))
    const transitionalPortal = {
      header: 'Test Claim Submission Successful',
      copy: data.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  } catch (err) {
    console.log('getDrugModuleDataHandler Error >Data ', err)

    const transitionalPortal = {
      header: 'Test Claim Submission Failed',
      copy: err.message || err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}
