import React, { createRef, useEffect, useRef, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { Creators as PriorAuthorizationCreationActions } from '../../../redux/reducers/api/priorAuthorizationManagement'
import { Creators as AppActions } from '../../../redux/reducers/app'
import { Creators as UserManagementActions } from '../../../redux/reducers/userManagement'
import { Creators as TimerConfigActions } from '../../../redux/reducers/api/timerConfig'
import FliptButton from '../../../components/v2/fliptButton'
import FindCase from './findCase'
import BreadCrumb from '../../../components/v2/breadCrumb'
import PADashboardTab from './paDashboardTab'
import TabBar from "../../../components/tabBar";

import PAReports from './paReports'

import './styles.scss'

const PA_TABS_LOCAL_STORAGE_KEY = "paTabs"
const PERSONAL_TAB_NAME = 'Personal'
const NEW_TAB_NAME = 'New'
const RFI_TAB_NAME = 'RFI'
const ACTIVE_TAB_NAME = 'Active'
const RPH_REVIEW_TAB_NAME = 'Rph Review'
const RPH_POTENTIAL_DENIAL_TAB_NAME = 'Rph Potential Denial'
const FAILED_FAX_TAB_NAME = 'Failed Fax'
const FAILED_CASE_TAB_NAME = 'Failed Case'
const REOPEN_TAB_NAME = 'Reopen'
const OUTREACH_FOLLOW_UP_TAB_NAME = 'Outreach - Follow up'

const PERSONAL_TAB_ID = 'PERSONAL'
const NEW_TAB_ID = 'NEW'
const RFI_TAB_ID = 'RFI'
const ACTIVE_TAB_ID = 'ACTIVE'
const RPH_REVIEW_TAB_ID = 'RPH_REVIEW'
const RPH_POTENTIAL_DENIAL_TAB_ID = 'RPH_POTENTIAL_DENIAL'
const REOPEN_TAB_ID = 'REOPEN'
const FAILED_FAX_TAB_ID = 'FAX'
const FAILED_CASE_TAB_ID = 'ERROR'
const OUTREACH_FOLLOW_UP_TAB_ID = 'OUTREACH_RISK'

const defaultTabConfigs = [
	{
		tabName: PERSONAL_TAB_NAME,
		id: PERSONAL_TAB_ID,
	},
	{
		tabName: `${NEW_TAB_NAME} Case`,
		id: NEW_TAB_ID,
	},
	{
		tabName: `${RFI_TAB_NAME} Waiting For Response`,
		id: RFI_TAB_ID,
	},
	{
		tabName: `${ACTIVE_TAB_NAME} Case`,
		id: ACTIVE_TAB_ID,
	},
	{
		tabName: `${RPH_REVIEW_TAB_NAME}`,
		id: RPH_REVIEW_TAB_ID,
	},
	{
		tabName: `${RPH_POTENTIAL_DENIAL_TAB_NAME}`,
		id: RPH_POTENTIAL_DENIAL_TAB_ID,
	},
	{
		tabName: `${FAILED_FAX_TAB_NAME}`,
		id: FAILED_FAX_TAB_ID,
	},
	{
		tabName: `${FAILED_CASE_TAB_NAME}`,
		id: FAILED_CASE_TAB_ID,
	},
	{
		tabName: `${REOPEN_TAB_NAME}`,
		id: REOPEN_TAB_ID,
	},
	{
		tabName: `${OUTREACH_FOLLOW_UP_TAB_NAME}`,
		id: OUTREACH_FOLLOW_UP_TAB_ID,
	},
]

const PADashboard = (props) => {
	const { state, actions } = props
	const history = useHistory()
	const [activeTabId, setActiveTabId] = useState(PERSONAL_TAB_ID)

	const [displayReports, setDisplayReports] = useState(false)
	const queueRef = useRef(null)

	useEffect(() => {
		actions.clearUserManagementData()
		// this is needed because we cannot clear pa case details
		// on paCaseDashboard unmount because of the side drawner 
		// and because of 'save and close' functionality
		actions.clearUpdatePaCaseData()
		actions.clearPaCaseDetails()
	}, [])

	const assignToMeCallback = (pa_case_id, message) => {
		history.push('/pa-case-dashboard', { pa_case_id, message })
	}

	const assignToMe = () => {
		actions.assignToMe({ userId: state.user.uuid, callback: assignToMeCallback })
	}

	const findCase = () => {
		actions.setModalComponent({
			modalProperties: { size: 'large' },
			contents: <FindCase history={history} />,
		})
		actions.toggleModal()
	}

	const setActiveTab = (id, tabs) => {
		setActiveTabId(id)
		setDisplayReports(false)
	}

	const renderPATab = () => {
		return defaultTabConfigs.map((tabConfig, index) => {
			return (tabConfig.id === activeTabId &&
				<PADashboardTab
					key={index}
					tabConfig={tabConfig}
					queueRef={queueRef}
				/>)
		})
	}

	let reportsClassName = 'secondary reports'
	if (displayReports) {
		reportsClassName = 'secondary reports reports-selected'
	}


	return (
		<div id='pa-dashboard' >
			<BreadCrumb {...props} />
			<div className='header-container'>
				<span className='header'>PA Dashboard</span>
				<div className='pa-buttons-container'>
					<FliptButton
						className='secondary'
						name='Find Case'
						onClick={findCase}
						icon={<img src='/i/search.svg'></img>}
					/>
					<FliptButton className='primary' name='Get Next Case' onClick={assignToMe} />
					<FliptButton className='primary' name='Add Case' onClick={() => history.push('/pa-case-dashboard')} />
				</div>
			</div>
			<div className='tabs-reports-container'>
				<div className='tab-bar'>
					<TabBar
						tabs={defaultTabConfigs}
						setActiveTab={setActiveTab}
						localStorageKey={PA_TABS_LOCAL_STORAGE_KEY}
						draghandle />
				</div>
				<FliptButton
					className={reportsClassName}
					name='Reports'
					onClick={() => setDisplayReports(!displayReports)} />
			</div>
			{displayReports ? <PAReports /> : renderPATab()}

		</div>)
}

const mapStateToProps = (state) => {
	const allPATimerConfigurations = state?.timerConfig?.allPATimerConfigurationsData?.length > 0 ? state?.timerConfig?.allPATimerConfigurationsData : []
	const paTimerConfigurations = {
		'standard': [],
		'expedited': [],
	}
	allPATimerConfigurations.forEach(x => {
		if (paTimerConfigurations[x?.priority.toLowerCase()]) paTimerConfigurations[x.priority.toLowerCase()].push(x)
	})
	return {
		state: {
			app: state.app,
			user: state.user,
			// assignToMeData: state.priorAuthorizationCreation.assignToMeData,
			paCasesPerPage: state.priorAuthorizationCreation.paCasesPerPage,
			availableUsers: state.userManagement.data?.filter(x => x?.roles?.map(y => y.name)?.filter(z => z && z.toLowerCase().startsWith('pa')).length > 0).map(x => ({ name: `${x?.first_name} ${x?.last_name}`, user_tabName: x?.uuid })),
			allUsers: state.userManagement?.data?.map(x => ({ name: `${x?.first_name} ${x?.last_name}`, user_tabName: x?.uuid })),
			paTimerConfigurations,
		},
	}
}

const mapDispatchToProps = (dispatch) => {
	const allActions = {
		...PriorAuthorizationCreationActions,
		...AppActions,
		...UserManagementActions,
		...TimerConfigActions,
	}

	return {
		actions: bindActionCreators(allActions, dispatch),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PADashboard)
