import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Creators as UserActions } from '../../redux/reducers/user'
import LogoutButton from './logoutButton'
import './styles.scss'

class Header extends PureComponent {
  userLogout = () => {
    const { actions } = this.props
    actions.userLogout()
  }

  render() {
    const { state, actions } = this.props
    const { first_name, last_name } = state.user
    const { auth0Client } = state
    return (
      <div id="appHeader">
        <Link to="/home">
          {/* { state?.app?.companyCSSFilename ? <img src={`/i/${state?.app?.companyCSSFilename}/logo-header.png`} alt="Alternate Logo Header" /> : <img src="/i/flipt-header-logo.png" alt="Flipt FAST Self Service Application" /> } */}
          <div className="logo-container">
            <div className="logo-header" />
            <div className="logo-header-default" />
          </div>
        </Link>
        <div className="user-menu">
          <span>
            Hi,
            {' '}
            {first_name}
            {' '}
            {last_name}
          </span>
          <span>|</span>
          <LogoutButton userLogout={() => actions.userLogout()} auth0Client={auth0Client} />
          {/* <span className="logout-text" role="button" onClick={this.userLogout} tabIndex={0}>Logout</span> */}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  state: {
    app: state.app,
    user: state.user,
    auth0Client: state?.ssoLogin?.auth0Client,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...UserActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
