import React from 'react'

import FliptInput from '../../../../../components/form/fliptInput'

import { removeLeadingZeros } from '../../../../../utils/utilities'

export default ({ data }) => {
    const {
        other_coverage_code,
        other_payer_amount_paid,
        other_payer_amount_paid_count,
        other_payer_amount_paid_qualifier,
        other_payer_coverage_type,
        other_payer_date,
        other_payer_id,
        other_payer_id_qualifier,
        other_payer_patient_responsibility_amount,
        other_payer_patient_responsibility_amount_count,
        other_payer_patient_responsibility_amount_qualifier,
        other_payer_reject_code,
        other_payer_reject_count
    } = data

    return (
        <div className="section">
            <h2>COB</h2>
            <div className="fields-container">
                <div className="fields">
                    <FliptInput value={other_coverage_code || ''} label="Other Coverage Code" stylized />
                    <FliptInput value={other_payer_amount_paid_qualifier || ''} label="Other Payer Amount Paid Qualifier" stylized />
                    <FliptInput value={other_payer_id || ''} label="Other Payer ID" stylized />
                    <FliptInput value={other_payer_patient_responsibility_amount_count || ''} label="Patient Responsibility Amount Count" stylized />
                    <FliptInput value={other_payer_reject_count || ''} label="Other Payer Reject Count" stylized />
                </div>
                <div className="fields">
                    <FliptInput value={other_payer_amount_paid || ''} label="Other Payer Amount Paid" stylized />
                    <FliptInput value={other_payer_coverage_type || ''} label="Other Payer Coverage Type" stylized />
                    <FliptInput value={other_payer_id_qualifier || ''} label="Other Payer ID Qualifier" stylized />
                    <FliptInput value={other_payer_patient_responsibility_amount_qualifier || ''} label="Patient Responsibility Amount Qualifier" stylized />
                </div>
                <div className="fields">
                    <FliptInput value={other_payer_amount_paid_count || ''} label="Other Payer Amount Paid Count" stylized />
                    <FliptInput value={other_payer_date || ''} label="Other Payer Date" stylized />
                    <FliptInput value={other_payer_patient_responsibility_amount || ''} label="Patient Responsibility Amount" stylized />
                    <FliptInput value={other_payer_reject_code || ''} label="Other Payer Reject Code" stylized />
                </div>
            </div>
        </div>
    )
}
