import React from 'react'

import moment from 'moment'
import FliptInput from '../../../../../components/form/fliptInput'
import FliptButton from '../../../../../components/form/fliptButton'

export default (props) => {
  const { removeClaimOveride, data } = props
  const {
    over_ride,
    overridenumber,
    override_from,
    override_thru,
    copay_override,
    override_amount,
    override_percentage,
    payer_routing_override_bypass,
    override_type,
    claim_only_extended,
    copay_amount_30ds,
    copay_amount_60ds,
    copay_amount_90ds,
    date_time_stamp,
  } = data

  return (
    <div className="section">
      <h2>Override</h2>
      <div className="fields-container">
        <div className="fields">
          <FliptInput value={over_ride || ''} label="Override" stylized />
          <FliptInput value={override_thru || ''} label="Override To" stylized />
          <FliptInput value={override_percentage || ''} label="Override Percentage" stylized />
          <FliptInput value={overridenumber?.split('-')[2] || ''} label="Username" stylized />
        </div>
        <div className="fields">
          <FliptInput value={overridenumber || ''} label="Override Number" stylized />
          <FliptInput value={copay_override || ''} label="Copay Override" stylized />
          <FliptInput value={payer_routing_override_bypass || ''} label="Payer Override" stylized />
          <FliptInput value={date_time_stamp ?? (overridenumber ? moment(overridenumber?.substring(overridenumber.indexOf(':') + 1).replace('T', ' ')).format('MM/DD/YYYY hh:mm:ss') : '')} label="Date/Time Stamp" stylized />
        </div>
        <div className="fields">
          <FliptInput value={override_type || ''} label="Override Type" stylized />
          <FliptInput value={claim_only_extended || ''} label="Claim Only/Extended" stylized />
          <FliptInput value={copay_amount_30ds || ''} label="30 DS Amount" stylized />
          <FliptInput value={copay_amount_60ds || ''} label="60 DS Amount" stylized />
          <FliptInput value={copay_amount_90ds || ''} label="90 DS Amount" stylized />
        </div>
        <div className="fields">
          <FliptInput value={override_from || ''} label="Override From" stylized />
          <FliptInput value={override_amount || ''} label="Override Amount" stylized />
          <FliptButton name="Remove Override" onClick={removeClaimOveride} className="primary" />
        </div>
      </div>
    </div>
  )
}
