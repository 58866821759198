import React, { PureComponent } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Creators as ClientConfigurationActions } from "../../../../redux/reducers/api/clientConfiguration";
import "./styles.scss";
import {CompanyAttributeFields} from '../Constant/companyFields'
import {renderDynamicInput,findSelectedFields} from '../utils'
import { COLUMN_DIVIDER } from '../Constant'
const CompanyTab = (props) => {
  const { singleLevelAttribute ,nodeType} = props.state;
  const { selectedFields } = props;
  const validations = props.state.validations;
  const validationResults = props.state.validationResults;
  const showErrors =
    validationResults && validationResults && !validationResults.isAllValid;

  const _updateFields = (el, dropdown) => {
    const { name, value } = dropdown || el.currentTarget;
    props.actions.updateAttributeFields({ name, value });
  };

  const renderAdditionalFields = (fieldList) => {
    if (fieldList?.length == 0) return null;
    if(Object.keys(singleLevelAttribute).length == 0) return null;
    const selectedFieldsArray = findSelectedFields(fieldList, singleLevelAttribute);
    const selectedFieldsArrayGroupedBySections = selectedFieldsArray.reduce(function(m,it,i){let subgroup=it["subgroup"]||"";
    m[subgroup] ||(m[subgroup]=[]);
    m[subgroup].push(it)
    return m
  },{});
    //convert to a list of tuples sorted by the min order in each group
   const selectedTuples = Object.entries(selectedFieldsArrayGroupedBySections)
    .map(([subgroup, items]) => ({
        subgroup,
        items: items.sort((a, b) => a.order - b.order),
    }))
    .sort((a, b) => a.items[0].order - b.items[0].order)
    const rowDivider = COLUMN_DIVIDER;
    const fieldProps = {
      ...singleLevelAttribute,
      onChange: _updateFields,
      className:'fields-client-header',
      nodeType
    };
    return (
      <>
      {/* use array method to Calculate rows dynamically acc to ui layout */}
      {selectedTuples.map(function (tuple) {
          return (
            <fieldset>
              <legend>{tuple.subgroup}</legend>
              {Array.from(
                { length: Math.ceil(tuple.items.length / rowDivider) },
                (_, i) => (
                  <div className="fields-container" key={`row${i}`}>
                    {tuple.items
                      .slice(i * rowDivider, (i + 1) * rowDivider)
                      .map((item) => renderDynamicInput(item, fieldProps))}
                  </div>
                )
              )}
            </fieldset>
          );
        })}
      </>
    );
  };



  return (
    <div>
      {renderAdditionalFields(CompanyAttributeFields)}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    state: {
      nodeType:state.clientConfiguration.nodeType,
      companyTab: state.clientConfiguration.companyTab,
      validations: state.clientConfiguration.validations.companyTab,
      validationResults:
        state.clientConfiguration.validationResults.companyTab,
      singleLevelAttribute: state.clientConfiguration.singleLevelAttribute,  
    },
    
  };
};

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...ClientConfigurationActions,
  };

  return {
    actions: bindActionCreators(allActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyTab);
