import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
// import { Creators as AppActions } from '../../../../../redux/reducers/app'
// import { put } from 'redux-saga/effects'

import './styles.scss'
import Config from '../../../../../config'
import Panes from './panes'
import BreadCrumb from '../../../../../components/breadCrumb'
import FliptButton from '../../../../../components/form/fliptButton'
import FliptDropdown from '../../../../../components/form/fliptDropdown'
import FliptInput from '../../../../../components/form/fliptInput'
import { Creators as MedicareSetupActions } from '../../../../../redux/reducers/api/medicareSetup'
import { convertStrToDateObj } from '../../../../../utils/utilities'
import FliptDatePicker from '../../../../../components/form/fliptDatePicker'
import { validateString } from '../../../../../utils/validationHelpers'
import { parseQueryString } from '../../../../../utils/utilities'
import FliptCheckbox from '../../../../../components/form/fliptCheckbox';
import FliptHierarchyDropdown from '../../../../../components/form/fliptHierarchyDropdown'

class CreateMedicarePlan extends Component {
  constructor(props) {
    super(props)
    this.state = {
      form: {
        doc_name: '',
        doc_version: '1.0',
        plan_year: '',
        effective_start_date: '',
        medicare_d_standard: 'N',
        hierarchy: [],
        hierarchyIDs: [],
        is_hierarchy_global: false,
      },
      editMode: false,
      showTabs: false,
      parsedQS: false,
      loading: false,
    }
  }

  componentDidMount() {
    const { actions, history, location, state } = this.props
    const toggleEditMode = !!location.state ? location.state.editMode : true
    const plan_type = location?.state ? location?.state?.plan_type : 'standard'
    this.setState((prevState) => ({
      ...prevState,
      editMode: toggleEditMode,
      plan_type,
      form: {
        ...prevState.form,
        medicare_d_standard: plan_type === 'custom' ? 'N' : 'Y'
      }
    }))

    if (history.location.search) {
      const payload = parseQueryString(history.location.search)
      setTimeout(() => actions.getMedicareDPlanData(payload), Config.COMPONENT_DATA_LOAD_TIME)
    } else {
      setTimeout(() => actions.getMedicareSetupData(), Config.COMPONENT_DATA_LOAD_TIME)
    }
    setTimeout(() => actions.getMedicareStandardData(), Config.COMPONENT_DATA_LOAD_TIME)
  }

  componentDidUpdate() {
    const { state, props } = this
    const { actions, state: { activePlan, medicareSetupData, medicareSetupDetailData } } = props
    const { loading } = state

    if (medicareSetupData.length > 0 && medicareSetupDetailData.length === 0 && !loading) {
      medicareSetupData.forEach((plan) => {
        const currYear = new Date().getFullYear()
        if (plan.plan_year === currYear.toString() && plan.status === 'Published') {
          const { doc_id } = plan
          this.setState((prevState) => ({
            ...prevState,
            loading: true,
          }))
          setTimeout(() => actions.getMedicareSetupDetailData({ doc_id }), Config.COMPONENT_DATA_LOAD_TIME + 1000)
        }
      })
    } else if (medicareSetupDetailData.length > 0 && loading) {
      this.setState((prevState) => ({
        ...prevState,
        loading: false,
      }))
    }


    if (activePlan?.length > 0 && !state.parsedQS) {
      const { medicare_plan_info: {
        doc_name,
        doc_version,
        effective_start_date,
        medicare_d_standard,
        hierarchy,
        is_hierarchy_global,
      } } = activePlan[0]
      const plan_year = activePlan[0].medicare_plan_info.plan_year || activePlan[0].lics_plan.plan_year

      this.setState((prevState) => ({
        ...prevState,
        form: {
          doc_name,
          doc_version,
          plan_year,
          effective_start_date,
          medicare_d_standard,
          hierarchy,
          is_hierarchy_global,
        },
        parsedQS: true
      }))
    } else if (state.parsedQS && !state.showTabs) {
      this.setState((prevState) => ({
        ...prevState,
        showTabs: true
      }))
    }
  }

  setForm = (newForm) => {
    this.setState((prevState) => ({
      ...prevState,
      form: newForm,
    }))
  }

  componentWillUnmount() {
    const { actions } = this.props
    actions.clearMedicareSetupReducer()
  }

  _updateFields = (el, dropdown) => {
    const { form } = this.state
    const { name, value, checked } = dropdown || el.currentTarget
    const pharmacy_name_err = false

    form[name] = value
    if (name === 'medicare_d_standard') {
      form['medicare_d_standard'] = checked ? 'Y' : 'N'
      form['hierarchy'] = []
      form['hierarchyIDs'] = []
    }
    form['is_hierarchy_global'] = true

    this.setState({ ...this.state, form, pharmacy_name_err })
  }

  _submitParams = () => {
    const { state: { medicareStandardData } } = this.props
    const { state, props: { actions } } = this
    const { form: { plan_year, medicare_d_standard } } = state

    let tabFlag = true;
    if (medicare_d_standard === 'Y' && plan_year.length > 0) {
      for (let i = 0; i < medicareStandardData.length; i++) {
        if (medicareStandardData[i]['plan_year'] === plan_year) {
          tabFlag = false;
          break;
        }
      }
    }

    if (tabFlag) {
      this.setState({ ...state, showTabs: true })
    } else {
      actions.getMedicareStandardDetail({ "plan_year": plan_year })
    }
  }

  _activateEditMode = () => this.setState((prevState) => ({ ...prevState, editMode: true }))

  render() {
    const { props, state } = this
    const {
      form, showTabs, editMode, plan_type
    } = state

    const { history } = props
    const { doc_id } = parseQueryString(history.location.search)
    const { medicare_d_standard } = form
    return (
      <div id="create-medicare-plan" className="create-medicare-plan">
        <BreadCrumb {...props} />
        <div className="header">
          <h1> {!!doc_id ? form.doc_name : 'Create New Medicare Plan'}</h1>
        </div>
        <div className="subhead">
          {(editMode && !!doc_id) ?
            (<section className="prescriber-section">
              <div className="prescriber-input">
                <span className="input-label">Plan Name</span>
                <FliptInput placeholder="Name" value={form.doc_name} name="doc_name" onChange={this._updateFields} disabled={!editMode} required />
              </div>
              <div className="prescriber-input">
                <span className="input-label">Benefit Year</span>
                <FliptInput placeholder="YYYY" value={form.plan_year} name="plan_year" onChange={this._updateFields} validation='numeric' disabled={!editMode} required />
              </div>
              <div className="prescriber-input">
                <span className="input-label">Medicare D Standard</span>
                <FliptCheckbox className="create-plan-medicare-d-standard" name="medicare_d_standard" value={form.medicare_d_standard === 'Y'} checked={form.medicare_d_standard === 'Y'} onClick={this._updateFields} disabled={!editMode} />
              </div>
            </section>) : (
              <section className="prescriber-section">
                <div className="prescriber-input">
                  <span className="input-label">Medicare Plan Name</span>
                  <FliptInput placeholder="Name" value={form.doc_name} name="doc_name" onChange={this._updateFields} readOnly={!editMode} required />
                </div>
                <div className="prescriber-input">
                  <span className="input-label">Version</span>
                  <FliptInput placeholder="Vesion" value={form.doc_version} name="doc_version" onChange={this._updateFields} readOnly required />
                </div>
                <div className="prescriber-input">
                  <span className="input-label">Plan Year</span>
                  <FliptInput placeholder="YYYY" value={form.plan_year} name="plan_year" onChange={this._updateFields} validation='numeric' readOnly={!editMode} required />
                </div>
                <div className="prescriber-input">
                  <span className="input-label">Start Date</span>
                  <FliptDatePicker className="create-plan-start-date" name="effective_start_date" value={convertStrToDateObj(form.effective_start_date, { format: 'YYYY-MM-DD', toISO: true })} onChange={this._updateFields} readOnly={!editMode} />
                </div>
                <div className="prescriber-input">
                    <div className="check">
                      <FliptCheckbox checked={form.medicare_d_standard === 'Y' || plan_type !== 'custom'} name='medicare_d_standard' value={form.medicare_d_standard || plan_type !== 'custom'}  onClick={this._updateFields} onChange={e => { }} disabled={!editMode} />
                      <span style={{ margin: 5 }}>{'Medicare D Standard'}</span>
                    </div>
                  </div>
              </section>)}
        </div>
          {false && 
          <FliptHierarchyDropdown setForm={this.setForm} form={_.cloneDeep(form)} showHierarchyGlobal={medicare_d_standard === 'Y'} disabled={medicare_d_standard === 'Y' || !editMode} readOnly={medicare_d_standard === 'Y' || !editMode} />
          }
        {showTabs ? (
          <Panes editMode={editMode} planInfo={form} activateEditMode={this._activateEditMode} />
        ) : (
          <div className="prescriber-input prescriber-btn">
            <FliptButton name="Create Medicare Plan" className="primary searchButton" onClick={this._submitParams} />
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  state: {
    activePlan: state.medicareSetup.activePlan,
    medicareSetupData: state.medicareSetup.medicareSetupData,
    medicareSetupDetailData: state.medicareSetup.medicareSetupDetailData,
    medicareStandardData: state.medicareSetup.medicareStandardData
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...MedicareSetupActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateMedicarePlan)