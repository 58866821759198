import Immutable from 'seamless-immutable'
import { createActions, createReducer } from 'reduxsauce'

export const INITIAL_STATE = Immutable({
    loading: false,
    queueConfigData: [],
    queueConfigDetailData: {},
    saveQueueConfigData: [],
    updateQueueConfigData: []
})


export const setQueueConfigDataReducer = (state = INITIAL_STATE, { payload }) => ({
    ...state,
    queueConfigData: payload,
})

export const setQueueConfigDetailDataReducer = (state = INITIAL_STATE, { payload }) => ({
    ...state,
    queueConfigDetailData: payload,
})

export const setSaveQueueConfigDataReducer = (state = INITIAL_STATE, { payload }) => ({
    ...state,
    saveQueueConfigData: payload,
})

export const setUpdateQueueConfigDataReducer = (state = INITIAL_STATE, { payload }) => ({
    ...state,
    updateQueueConfigData: payload,
})




export const { Types, Creators } = createActions({
    saveQueueConfigData: ['payload'],
    updateQueueConfigData: ['payload'],
    getQueueConfigData: ['payload'],
    setQueueConfigData: ['payload'],
    setQueueConfigDetailData: ['payload'],
    setSaveQueueConfigData: ['payload'],
    setUpdateQueueConfigData: ['payload'],
})

const HANDLERS = {
    [Types.SET_QUEUE_CONFIG_DATA]: setQueueConfigDataReducer,
    [Types.SET_QUEUE_CONFIG_DETAIL_DATA]: setQueueConfigDetailDataReducer,
    [Types.SET_SAVE_QUEUE_CONFIG_DATA]: setSaveQueueConfigDataReducer,
    [Types.SET_UPDATE_QUEUE_CONFIG_DATA]: setUpdateQueueConfigDataReducer,
}
export default createReducer(INITIAL_STATE, HANDLERS)


