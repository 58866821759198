import {
  put,
  select,
  takeLatest,
} from 'redux-saga/effects'

import { fetchGet, fetchPatch, fetchPut } from '../../../utils/fetchApi'
import { getAppState } from '../../reducers/selectors'
import { Creators as RoleManagementActions, Types as RoleManagementTypes } from '../../reducers/roleManagement'
import { Creators as AppActions } from '../../reducers/app'
import { Creators as NavigationActions } from '../../reducers/navigation'

export default [
  createRoleManagementRoleWatcher,
  editRoleManagementRoleWatcher,
  getRoleManagementRolesWatcher,
  getRoleManagementRoleWatcher,
]

/* WATCHERS */
function* getRoleManagementRolesWatcher() {
  yield takeLatest(RoleManagementTypes.GET_ROLE_MANAGEMENT_ROLES, getRoleManagementRolesHandler)
}

function* createRoleManagementRoleWatcher() {
  yield takeLatest(RoleManagementTypes.CREATE_ROLE_MANAGEMENT_ROLE, createRoleManagementRoleHandler)
}

function* editRoleManagementRoleWatcher() {
  yield takeLatest(RoleManagementTypes.EDIT_ROLE_MANAGEMENT_ROLE, editRoleManagementRoleHandler)
}

function* getRoleManagementRoleWatcher() {
  yield takeLatest(RoleManagementTypes.GET_ROLE_MANAGEMENT_ROLE, getRoleManagementRoleHandler)
}

/* HANDLERS */
function* getRoleManagementRolesHandler() {
  try {
    const appState = yield select(getAppState)
    const response = yield fetchGet(`${appState.apiUrl}/fast/role-management-get`)

    yield put(RoleManagementActions.setRoleManagementRolesList(response))
  } catch (err) {
    const transitionalPortal = {
      header: 'Unable to get roles',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* createRoleManagementRoleHandler({ payload }) {
  try {
    const { apiUrl } = yield select(getAppState)
    yield fetchPut(`${apiUrl}/fast/role-management`, payload)
    const transitionalPortal = {
      header: 'Role Successfully Created',
      copy: `${payload.name} created`,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    yield put(NavigationActions.navigateTo({ pathname: '/role-management' }))
  } catch (err) {
    const transitionalPortal = {
      header: 'Role Creation Failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* editRoleManagementRoleHandler({ payload }) {
  try {
    const { apiUrl } = yield select(getAppState)
    if (payload.conditions) {
      payload.functions = [...payload.conditions]
    }
    yield fetchPatch(`${apiUrl}/fast/role-management`, payload)
    const transitionalPortal = {
      header: 'Role Successfully Updated',
      copy: `${payload.name} updated`,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    yield put(NavigationActions.navigateTo({ pathname: '/role-management' }))
  } catch (err) {
    const transitionalPortal = {
      header: 'Role Update Failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* getRoleManagementRoleHandler({ payload }) {
  try {
    const appState = yield select(getAppState)
    const { data } = yield fetchGet(`${appState.apiUrl}/fast/role-management-get`, payload)

    if (!data.length) throw Error('No Role Found')

    yield put(RoleManagementActions.setRoleManagementRoleData(data[0]))
  } catch (err) {
    const transitionalPortal = {
      header: 'getRoleManagementRoleHandler Error >Data ',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

export {
  createRoleManagementRoleHandler,
  getRoleManagementRolesHandler,
  editRoleManagementRoleHandler,
  getRoleManagementRoleHandler,
}
