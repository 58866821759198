const rewardOption = [
  {
  key: 1,
  text: 'Redeem Rewards via Amazon e-gift card',
  value: 'Redeem Rewards via Amazon e-gift card',
},
{
  key: 2,
  text: 'Redeem Rewards via ACH transfer to your Bank',
  value:'Redeem Rewards via ACH transfer to your Bank'
}
]
const rewardRedemption = [
  {
  key: 1,
  text: 'ACH Payment will take 3-5 business days to process',
  value: 'ACH Payment will take 3-5 business days to process',
},
{
  key: 2,
  text: 'Amazon e-gift card will take 3-5 business days to process',
  value:'Amazon e-gift card will take 3-5 business days to process'
}
]

export const rewardsPenaltyAttributes = [
  {
    "header": "",
    "type": "number",
    "label": "Alternate Penalty Max in $",
    "name": "alternate_penalty_max",
    "value": "",
    "info": "maximum penalty for selecting alternate drug",
    "validation": "numeric",
    "placeholder": "Enter",
    "subgroup": "Penalty",
    "order": 25
  },
  {
    "header": "",
    "type": "text",
    "label": "Apply Penalty After OutofPocket ",
    "name": "apply_penalty_after_outofpocketmet",
    "value": "",
    "info": "Apply Penalty After OutofPocket",
    "validation": "",
    "placeholder": "Enter",
    "subgroup": "Penalty",
    "order": 27
  },

  {
    "header": "",
    "type": "number",
    "label": "Alternate Penalty Share %",
    "name": "alt_penalty_share",
    "value": "",
    "info": "Penalty share % by employer to member applicable for Drug Substitution",
    "validation": "numeric",
    "placeholder": "Enter",
    "subgroup": "Penalty",
    "order": 24
  },
  {
    "header": "",
    "type": "number",
    "label": "Alternate Reward Max in $",
    "name": "alternate_reward_max",
    "value": "",
    "info": "maximum reward for selecting alternate drug",
    "validation": "numeric",
    "placeholder": "Enter",
    "subgroup": "Reward",
    "order": 9
  },
  {
    "header": "",
    "type": "number",
    "label": "Alternate Rewards Share %",
    "name": "alt_reward_share",
    "value": "",
    "info": "Reward share % by employer to member based on Drug Substitution",
    "validation": "numeric",
    "placeholder": "Enter",
    "subgroup": "Reward",
    "order": 8
  },
  {
    "header": "",
    "type": "radio",
    "label": "Can Reward Exceed Copay",
    "name": "can_reward_exceed_copay",
    "value": "",
    "info": "Should reward to be granted only to offset copay/co insurance?",
    "validation": "",
    "placeholder": "Enter",
    "subgroup": "Reward",
    "order": 12
  },
  {
    "header": "",
    "type": "number",
    "label": "Comfort Zone Pharmacies",
    "name": "comfort_zone_pharmacies",
    "value": "",
    "info": "Baseline Calculation",
    "validation": "numeric",
    "placeholder": "Enter",
    "subgroup": "Baseline Calculation",
    "order": 21
  },
  {
    "header": "",
    "type": "number",
    "label": "Conceirge Email Frequency for Reward Report",
    "name": "reward_frequency",
    "value": "",
    "info": "Frequency for available rewards report to be sent to the concierge team for member choosing to redeem rewards via Amazon gift card",
    "validation": "numeric",
    "placeholder": "Enter",
    "subgroup": "Reward",
    "order": 16
  },
  {
    "header": "",
    "type": "number",
    "label": "Default Reward Option",
    "name": "default_reward_option",
    "value": "",
    "info": "default reward option set up for company",
    "validation": "numeric",
    "placeholder": "Enter",
    "subgroup": "Reward",
    "order": 13
  },
  {
    "header": "",
    "type": "radio",
    "label": "Display Rewards",
    "name": "display_rewards",
    "value": "",
    "info": "When Y then display the rewards section in the app, when N then rewards is not displayed in the app. This is applicable when the plan allows the rewards to exceed beyond copay",
    "validation": "",
    "placeholder": "Enter",
    "subgroup": "Reward",
    "order": 14
  },
  {
    "header": "",
    "type": "number",
    "label": "Economy Zone Pharmacies",
    "name": "economy_zone_pharmacies",
    "value": "",
    "info": "Baseline calculation",
    "validation": "numeric",
    "placeholder": "Enter",
    "subgroup": "Baseline Calculation",
    "order": 23
  },
  {
    "header": "",
    "type": "number",
    "label": "Employer Penalty Factor %",
    "name": "employer_penalty_factor",
    "value": "",
    "info": "how much penalty is shared between employer and member when not selecting the best value pharmacy",
    "validation": "numeric",
    "placeholder": "Enter",
    "subgroup": "Penalty",
    "order": 23
  },
  {
    "header": "",
    "type": "radio",
    "label": "Enable Alternate Penalty",
    "name": "enable_alternate_penalty",
    "value": "",
    "info": "will penalty be applied for not selecting the cheaper alternate drug.",
    "validation": "",
    "placeholder": "Enter",
    "subgroup": "Penalty",
    "order": 26
  },
  {
    "header": "",
    "type": "radio",
    "label": "Enable Smart Rewards",
    "name": "enable_smart_rewards",
    "value": "",
    "info": "Not in Use",
    "validation": "",
    "placeholder": "Enter",
    "subgroup": "Reward",
    "order": 5
  },
  {
    "header": "",
    "type": "radio",
    "label": "Feedback Rewards",
    "name": "feedback_rewards",
    "value": "",
    "info": "rewards when member gives feedback",
    "validation": "",
    "placeholder": "Enter",
    "subgroup": "Reward",
    "order": 6
  },
  {
    "header": "",
    "type": "number",
    "label": "Max Reward Per Rx in $",
    "name": "max_reward_per_rx",
    "value": "",
    "info": "maximum retail reward cap applied for each script.  Must have a value in this field.  Use 0 if no value. ",
    "validation": "numeric",
    "placeholder": "Enter",
    "subgroup": "Reward",
    "order": 15
  },
  {
    "header": "",
    "type": "number",
    "label": "Max Reward Per Year in $",
    "name": "max_reward_per_year",
    "value": "",
    "info": "maximum retail reward cap applied for each member per plan year",
    "validation": "numeric",
    "placeholder": "Enter",
    "subgroup": "Reward",
    "order": 10
  },
  {
    "header": "",
    "type": "number",
    "label": "Min Reward Amt in $",
    "name": "min_reward_amt",
    "value": "",
    "info": "minimum amount limit for redeeming the rewards",
    "validation": "numeric",
    "placeholder": "Enter",
    "subgroup": "Reward",
    "order": 11
  },
  {
    "header": "",
    "type": "text",
    "label": "Reward Message",
    "name": "reward_message",
    "value": "",
    "info": "Confirmation message for the members if they would like to redeem rewards.",
    "validation": "",
    "placeholder": "Enter",
    "subgroup": "Reward",
    "order": 17
  },
  {
    "header": "",
    "type": "dropdown",
    "label": "Reward Options",
    "name": "reward_options",
    "value": "",
    "info": "options for reward payments",
    "validation": "",
    "placeholder": "Enter",
    "subgroup": "Reward",
    "order": 18,
    "optionList": rewardOption || []

  },
  {
    "header": "",
    "type": "dropdown",
    "label": "Reward Redemption Message",
    "name": "reward_redemption_message",
    "value": "",
    "info": "Message to member when rewards are redeemed by payroll",
    "validation": "",
    "placeholder": "Enter",
    "subgroup": "Reward",
    "order": 19,
    "optionList": rewardRedemption || []

  },
  {
    "header": "",
    "type": "number",
    "label": "Reward Share",
    "name": "reward_share",
    "value": "",
    "info": "%share of retail rewards for the member",
    "validation": "numeric",
    "placeholder": "Enter",
    "subgroup": "Reward",
    "order": 7
  },
  {
    "header": "",
    "type": "number",
    "label": "Rewards Baseline Multiplier in %",
    "name": "rewards_baseline_multiplier",
    "value": "",
    "info": "Baseline Calculation",
    "validation": "numeric",
    "placeholder": "Enter",
    "subgroup": "Baseline Calculation",
    "order": 1
  },
  {
    "header": "",
    "type": "number",
    "label": "Rewards Fixed Amount in $",
    "name": "rewards_fixed_amount",
    "value": "",
    "info": "Baseline Calculation - Calculate Rewards/ Penalty based on zone baseline pricing: \n\nRewards \n\n If calculated by Zone based baseline: if accumulated reward for the member > domain.max_reward_per_year then don't calculate rewards.\n\nReward calculation 1: \n\nif baseline exeeds drug_cost --> reward = ( baseline - drug cost ) * formulary.reward_share \n\nIf formulary.reward_share not there then use domain.reward_share\n\nReward Calculation 2: \n\nmaximum of domain.rewards_opc_multiplier * employee_opc  AND domain.rewards_baseline_multiplier * baseline price\n\nReward Calculation 3: \n\ndomain.rewards_fixed_amount\n\nReward = minimum of the above three reward calculations \n\nIf reward >  domain.max_reward_per_rx then \n\nreward = domain.max_reward_per_rx\n\nIf domain.can_reward_exceed_copay = N then \n\nreward = employee_opc",
    "validation": "numeric",
    "placeholder": "Enter",
    "subgroup": "Reward",
    "order": 2
  },
  {
    "header": "",
    "type": "number",
    "label": "Rewards Opc Multiplier",
    "name": "rewards_opc_multiplier",
    "value": "",
    "info": "Baseline Calculation",
    "validation": "numeric",
    "placeholder": "Enter",
    "subgroup": "Baseline Calculation",
    "order": 3
  },
  {
    "header": "",
    "type": "number",
    "label": "Saver Zone Factor",
    "name": "saver_zone_factor",
    "value": "",
    "info": "Baseline Calculation",
    "validation": "numeric",
    "placeholder": "Enter",
    "subgroup": "Baseline Calculation",
    "order": 4
  }
]