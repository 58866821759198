import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import FliptRadio from '../../../../../components/form/fliptRadio'
import FliptInput from '../../../../../components/v2/fliptInput'
import FliptButton from '../../../../../components/v2/fliptButton'
import FliptDatePicker from '../../../../../components/v2/fliptDatePicker'
import FliptDropdown from '../../../../../components/v2/fliptDropdown'
import {
	drugFields,
	gridHeaders,
	optionalClaimFields,
	pharmacyFields,
	prescriberFields,
	radioFields,
	requiredClaimFields,
} from '../../claimConfig/data/uiFields'
import FliptDrugSearch from '../../../../../components/v2/fliptDrugSearch'
import FliptPrescriberSearch from '../../../../../components/v2/fliptPrescriberSearch'
import FliptPharmacySearch from '../../../../../components/v2/fliptPharmacySearch'
import ClaimGrid from '../../components/claimGrid'
import { Creators as AppActions } from '../../../../../redux/reducers/app'
import { Creators as IntegrationManagementActions } from '../../../../../redux/reducers/api/integrationManagement'
import { Creators as ClaimsPRPActions } from '../../../../../redux/reducers/api/claimsProcessingAndReprocessing'
import './styles.scss'

const ClaimForm = (props) => {
    const { toggle, onFormChange, editable, actions, testBatchConfig, claimData } = props
	const {
		drugData,
		prescriberData,
		pharmacyData,
	} = claimData

	const onChange = (el, dropdown) => {
		const { name, value } = dropdown || el.currentTarget
		const newForm = {
			...claimData,
			[name]: value,
		}
		if(onFormChange) { onFormChange(newForm) }
	}
	const setModalData = (name, value) => {
		const newClaimFormData = {
			...claimData,
			[name]: value,
		}
		if(onFormChange) { onFormChange(newClaimFormData) }
	};
	
	
    const _findDrug = () => {
		const { configType, umObjects } = testBatchConfig
		const testBatchConfigSearch = {
			clinical_pa: umObjects.clinical_pa.value,
			clinical_ql: umObjects.clinical_ql.value,
			clinical_step_therapy: umObjects.clinical_step_therapy.value,
			programs: umObjects.programs.value,
			tiers: umObjects.tiers.value,
		}
		let isUm = false
		if (configType == 0) {
			isUm = true
		}
		actions.setModalComponent({
			modalProperties: { size: 'large' },
			contents: (
				<FliptDrugSearch
					isUm={isUm}
					umObjects={testBatchConfigSearch}
					updateDrugData={(data) => setModalData('drugData', data)}
				/>
			),
		})
		actions.toggleModal()
	}

	const _findpharmacy = () => {
		actions.setModalComponent({
			modalProperties: { size: 'large' },
			contents: <FliptPharmacySearch updatePharmacyData={(data) => setModalData('pharmacyData', data)} />,
		})
		actions.toggleModal()
	}

	const _findPrescriber = () => {
		actions.setModalComponent({
			modalProperties: { size: 'large' },
			contents: <FliptPrescriberSearch updatePrescriberData={(data) => setModalData('prescriberData', data)} />,
		})
		actions.toggleModal()
	}

    const renderFields = (data) => {
        switch (data.type) {
            case 'input': {
                return (
                    <FliptInput
                        label={data.label}
                        name={data.name}
                        value={claimData[data.name]}
                        disabled={data.disabled || !editable}
                        onChange={onChange}
                    />
                )
            }
            case 'dropdown': {
                return (
                    <FliptDropdown
                        label={data.label}
                        options={data.options}
                        name={data.name}
                        value={claimData[data.name]}
                        disabled={data.disabled || !editable}
                        onChange={onChange}
                    />
                )
            }
            case 'date': {
                return (
                    <FliptDatePicker
                        disabled={!editable}
                        className="datepicker"
                        label={data.label}
                        name={data.name}
                        value={claimData[data.name]}
                        onChange={onChange}
                    />
                )
            }
        }
    }

	return (
		<div id="test-batch-claim-form-component">
			<div className="header-container">
				<h2>Enter Required Drug</h2>
				{!toggle ? <FliptButton className="primary" name="Find Drug" onClick={_findDrug} /> : null}
			</div>
			<div className="field-outer-container">
				{drugFields.map((data) => {
					return(
					<div className="section-row">
						<FliptInput
							stylized
							label={data.label}
							name={data.name}
							value={drugData && drugData[data.name]}

							disabled={data.disabled}
							onChange={onChange}
						/>
					</div>
				)})}
			</div>
			<div className="header-container">
				<h2>Enter Required Prescriber</h2>
				{!toggle ? <FliptButton className="primary" name="Find Prescriber" onClick={_findPrescriber} /> : null}
			</div>
			<div className="field-outer-container">
				{prescriberFields.map((data) => (
					<div className="section-row">
						<FliptInput
							stylized
							label={data.label}
							name={data.name}
							value={prescriberData && prescriberData[data.name]}
							disabled={data.disabled}
							onChange={onChange}
						/>
					</div>
				))}
			</div>
			<div className="header-container">
				<h2>Enter Required Pharmacy</h2>
				{!toggle ? <FliptButton className="primary" name="Find Pharmacy" onClick={_findpharmacy} /> : null}
			</div>
			<div className="field-outer-container">
				{pharmacyFields.map((data) => (
					<div className="section-row">
						<FliptInput
							stylized
							label={data.label}
							name={data.name}
							value={pharmacyData && pharmacyData[data.name]}
							disabled={data.disabled}
							onChange={onChange}
						/>
					</div>
				))}
			</div>
			<h2>Enter Required Claim Details</h2>
			<div className="field-outer-container">
				{requiredClaimFields.map((fieldsData) => (
					<div className="section-row">{renderFields(fieldsData)}</div>
				))}
			</div>
			<h2>Enter optional Claim Details</h2>
			<div className="field-outer-container">
				{optionalClaimFields.map((fieldsData) => (
					<div className="section-row">{renderFields(fieldsData)}</div>
				))}
			</div>
		</div>
	)
}

const mapStateToProps = (state) => ({
	state: {
		user: state.user,
	},
})

const mapDispatchToProps = (dispatch) => {
	const allActions = {
		...AppActions,
		...ClaimsPRPActions,
	}
	return {
		actions: bindActionCreators(allActions, dispatch),
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(ClaimForm)
