import {
    call,
    put,
    select,
    takeLatest,
} from 'redux-saga/effects'

import { Types, Creators as QueueConfigActions } from '../../reducers/api/queueConfig'
import { Creators as AppActions } from '../../reducers/app'
import { getAppState, getApiPath } from '../../reducers/selectors'
import { fetchGet, fetchPost, fetchPut } from '../../../utils/fetchApi'

export default [
    saveQueueConfigDataWatcher,
    getQueueConfigDataWatcher,
    updateQueueConfigDataWatcher,
]

/* WATCHERS */
function* saveQueueConfigDataWatcher() {
    yield takeLatest(Types.SAVE_QUEUE_CONFIG_DATA, saveQueueConfigDataHandler)
}
function* getQueueConfigDataWatcher() {
    yield takeLatest(Types.GET_QUEUE_CONFIG_DATA, getQueueConfigDataHandler)
}
function* updateQueueConfigDataWatcher() {
    yield takeLatest(Types.UPDATE_QUEUE_CONFIG_DATA, updateQueueConfigDataHandler)
}

/* HANDLERS */
function* saveQueueConfigDataHandler({ payload }) {
    try {
        const { serviceUrl } = yield select(getAppState)
        const cd = yield select(getApiPath, 'pa-queue-configuration')
        const { api_path } = cd
        const url = `${serviceUrl}${api_path}`

        const data = yield call(fetchPost, url, payload)
        if (!data?.data) throw data;
        yield put(QueueConfigActions.setSaveQueueConfigData(data))
        const transitionalPortal = {
            header: 'Save Queue Configuration Successful',
            copy: data.message,
        }
        yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    } catch (err) {
        console.log('saveQueueConfigDataHandler Error >Data ', err)

        const transitionalPortal = {
            header: 'Save Queue Configuration Failed',
            copy: err.message || err,
        }
        yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    }
}

function* updateQueueConfigDataHandler({ payload }) {
    try {
        const { serviceUrl } = yield select(getAppState)
        const cd = yield select(getApiPath, 'pa-queue-configuration')
        const { api_path } = cd
        const url = `${serviceUrl}${api_path}`

        const { data } = yield call(fetchPut, url, payload)
        if (!data.length) throw Error('Update Queue Configuration Failed')
        yield put(QueueConfigActions.setUpdateQueueConfigData(data))
        const transitionalPortal = {
            header: 'Update Queue Configuration Successful',
            copy: data.message,
        }
        yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    } catch (err) {
        console.log('updateQueueConfigDataHandler Error >Data ', err)

        const transitionalPortal = {
            header: 'Queue Queue Configuration Failed',
            copy: err.message || err,
        }
        yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    }
}

function* getQueueConfigDataHandler({ payload }) {
    try {
        const { serviceUrl } = yield select(getAppState)
        const cd = yield select(getApiPath, 'pa-queue-configuration')
        const { api_path } = cd
        const url = `${serviceUrl}${api_path}`

        const { data } = yield call(fetchGet, url, payload)
        if (!data.length) throw Error('Get Queue Configuration Failed')
        yield put(QueueConfigActions.setQueueConfigData(data))
    } catch (err) {
        console.log('getQueueConfigDataHandler Error >Data ', err)
        const transitionalPortal = {
            header: 'Get Queue Configuration Failed',
            copy: err.message || err,
        }
        yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    }
}