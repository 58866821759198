import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { createQueryString } from '../../../../../utils/utilities'
import FliptPopup from '../../../../../components/fliptPopup'
import '../styles.scss'

const PopupCellRenderer = (props) => {
  const { data: { file_load_id } } = props
  const [visible, setVisible] = useState(false)
  const show = () => setVisible(true)
  const hide = () => setVisible(false)
  const file_id = createQueryString({ file_id: file_load_id })
  const dropDownContent = (
    <div className="menu-container">
      <Link to={{ pathname: '/view-new-drugs', search: file_id }}>
        <div
          role="link"
          tabIndex={0}
          className="menu-item"
        >
          View New Drugs
        </div>
      </Link>
      <Link to={{ pathname: '/view-drug-term', search: file_id }}>
        <div
          role="link"
          tabIndex={-1}
          className="menu-item"
        >
          View Drug Terms
        </div>
      </Link>
      <Link to={{ pathname: '/view-changes-by-type', search: file_id }}>
        <div
          role="link"
          tabIndex={-2}
          className="menu-item"
        >
          View Changes By Type
        </div>
      </Link>
      <Link to={{ pathname: '/view-changes-by-drug', search: file_id }}>
        <div
          role="link"
          tabIndex={-3}
          className="menu-item"
        >
          View Changes By Drug
        </div>
      </Link>
      <Link to={{ pathname: '/view-changes-by-object', search: file_id }}>
        <div
          role="link"
          tabIndex={-4}
          className="menu-item"
        >
          View Changes By Object
        </div>
      </Link>
    </div>
  )
  const popupStyle = {
    boxShadow: 'none !important',
    backgroundColor: 'transparent',
    border: 0,
  }
  return (
    <FliptPopup
      basic
      eventsEnabled
      id='drug-management-popup'
      onClose={hide}
      onOpen={show}
      open={visible}
      style={popupStyle}
      trigger={<button style={{backgroundColor: 'transparent', color: 'red', fontSize: '1.5em'}} type="button" className="btn btn-primary" onClick={visible ? hide : show}> ... </button>}
    >
      {dropDownContent}
    </FliptPopup>
  )
}

export default PopupCellRenderer
