import Immutable from 'seamless-immutable'
import { createActions, createReducer } from 'reduxsauce'

export const INITIAL_STATE = Immutable({
    integrationMgmtDashboardData: [],
    batchDetailsData: {},
    batchClaims: [],
    claimSummary: [],
    pageNumber: 0,
    editsData: [],
    addMemberData: {},
    isMemberGenerated: false,
    integrationMgmtLookupData: [],
    integrationMgmtFilteredLookupData: []
})


export const setIntegrationMgmtDashboardDataReducer = (state = INITIAL_STATE, { payload }) => ({
    ...state,
    integrationMgmtDashboardData: payload,
})

export const setIntegrationMgmtLookupDataReducer = (state = INITIAL_STATE, { payload }) => ({
    ...state,
    integrationMgmtLookupData: payload,
})

export const setIntegrationMgmtFilteredLookupDataReducer = (state = INITIAL_STATE, { payload }) => ({
    ...state,
    integrationMgmtFilteredLookupData: payload,
})

export const clearIntegrationMgmtDashboardDataReducer = (state = INITIAL_STATE) => ({
    ...state,
    integrationMgmtDashboardData: [],
})
export const setBatchDetailsDataReducer = (state = INITIAL_STATE, { payload }) => ({
    ...state,
    batchDetailsData: payload,
})

export const clearBatchDetailsReducer = (state = INITIAL_STATE, { payload }) => ({
    ...state,
    batchDetailsData: payload,
})

export const setBatchClaimsDataDataReducer = (state = INITIAL_STATE, { payload }) => ({
    ...state,
    batchClaims: payload,
})

export const setPageNumberReducer = (state = INITIAL_STATE, { payload }) => ({
    ...state,
    pageNumber: payload,
})

export const setEditsDataReducer = (state = INITIAL_STATE, { payload }) => ({
    ...state,
    editsData: payload,
})

export const setAddMemberDataReducer = (state = INITIAL_STATE, { payload }) => ({
    ...state,
    addMemberData: payload,
})

export const clearPageNumberReducer = (state = INITIAL_STATE, { payload }) => ({
    ...state,
    pageNumber: 0,
})

export const setClaimSummaryDataReducer = (state = INITIAL_STATE, { payload }) => ({
    ...state,
    claimSummary: payload,
})

export const setIsMemberGeneratedReducer = (state = INITIAL_STATE, { payload }) => ({
    ...state,
    isMemberGenerated: payload,
})



export const { Types, Creators } = createActions({
    getIntegrationMgmtDashboard: ['payload'],
    setIntegrationMgmtDashboard: ['payload'],
    getBatchDetails: ['payload'],
    setBatchDetailsData: ['payload'],
    getBatchClaims: ['payload'],
    setBatchClaimsData: ['payload'],
    getClaimSummary: ['payload'],
    setClaimSummaryData: ['payload'],
    clearBatchDetails: null,
    setPageNumber: ['payload'],
    clearPageNumber: null,
    clearIntegrationMgmtDashboard: null,
    getEdits: null,
    setEditsData: ['payload'],
    addMember: ['payload', 'callback'],
    setAddMemberData: ['payload'],
    getIntegrationMgmtLookup: ['payload'],
    setIntegrationMgmtLookupData: ['payload'],
    setIntegrationMgmtFilteredLookupData: ['payload'],
    setIsMemberGenerated: ['payload'],
})

const HANDLERS = {
    [Types.SET_INTEGRATION_MGMT_DASHBOARD]: setIntegrationMgmtDashboardDataReducer,
    [Types.CLEAR_INTEGRATION_MGMT_DASHBOARD]: clearIntegrationMgmtDashboardDataReducer,
    [Types.SET_BATCH_DETAILS_DATA]: setBatchDetailsDataReducer,
    [Types.SET_BATCH_CLAIMS_DATA]: setBatchClaimsDataDataReducer,
    [Types.SET_CLAIM_SUMMARY_DATA]: setClaimSummaryDataReducer,
    [Types.CLEAR_BATCH_DETAILS]: clearBatchDetailsReducer,
    [Types.SET_PAGE_NUMBER]: setPageNumberReducer,
    [Types.CLEAR_PAGE_NUMBER]: clearPageNumberReducer,
    [Types.SET_EDITS_DATA]: setEditsDataReducer,
    [Types.SET_ADD_MEMBER_DATA]: setAddMemberDataReducer,
    [Types.SET_INTEGRATION_MGMT_LOOKUP_DATA]: setIntegrationMgmtLookupDataReducer,
    [Types.SET_INTEGRATION_MGMT_FILTERED_LOOKUP_DATA]: setIntegrationMgmtFilteredLookupDataReducer,
    [Types.SET_IS_MEMBER_GENERATED]: setIsMemberGeneratedReducer,
}

export default createReducer(INITIAL_STATE, HANDLERS)
