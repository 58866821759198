export const cardTypeOptions = [
    {
        key: 0,
        text: 'Custom',
        value: 'Custom'
    },
    {
        key: 1,
        text: 'Generic',
        value: 'Generic'
    },
    {
        key: 2,
        text: 'No Card',
        value: 'No Card'
    }
]
export const idOnCardOptions = [
    {
        key: 0,
        text: 'Member ID',
        value: 'Member ID'
    },
    {
        key: 1,
        text: 'Member ID + Person Code',
        value: 'Member ID + Person Code'
    },
    {
        key: 2,
        text: 'Other ID',
        value: 'Other ID'
    }
]
export const idCardCreatorOptions = [
    {
        key: 0,
        text: 'Client',
        value: 'Client'
    },
    {
        key: 1,
        text: 'External',
        value: 'External'
    },
    {
        key: 2,
        text: 'PBM',
        value: 'PBM'
    }
]

export const deductibleSatisfactionOptions = [
    {
        key: 0,
        text: 'N/A - Not Applicable',
        value: 'N/A - Not Applicable'
    },
    {
        key: 1,
        text: 'Individual Only Applies',
        value: 'Individual Only Applies'
    },
    {
        key: 2,
        text: 'Individual & Family Applies',
        value: 'Individual & Family Applies'
    },
    {
        key: 3,
        text: 'Family Only Apply',
        value: 'Family Only Apply'
    },
     //coment the deductible Satisfaction  FT-4445
    // {
    //     key: 4,
    //     text: 'Family Only Satisfied',
    //     value: 'Family Only Satisfied'
    // },
    // {
    //     key: 5,
    //     text: 'Cov Type determines Ind/Fam Only',
    //     value: 'Cov Type determines Ind/Fam Only'
    // },
    // {
    //     key: 6,
    //     text: 'Individual Dedudctible Satisfied by Two Members',
    //     value: 'Individual Dedudctible Satisfied by Two Members'
    // }
]

export const benefitTypeOptions = [
    {
        key: 0,
        text: 'Calendar Year',
        value: 'Calendar Year'
    },
    {
        key: 1,
        text: 'Benefit Year',
        value: 'Benefit Year'
    }
]

export const typeOfOopOptions = [
    {
        key: 0,
        text: 'N/A - Not Applicable',
        value: 'N/A - Not Applicable'
    },
    {
        key: 1,
        text: 'Benefit Cap (plan paid cap)',
        value: 'Benefit Cap (plan paid cap)'
    },
    {
        key: 2,
        text: 'Out of Pocket Cap Benefit Year (member out-of-pocket cap)',
        value: 'Out of Pocket Cap Benefit Year (member out-of-pocket cap)'
    },
    {
        key: 3,
        text: 'Out of Pocket Cap Calendar Month',
        value: 'Out of Pocket Cap Calendar Month'
    },
    {
        key: 4,
        text: 'Benefit Cap Calendar Month',
        value: 'Benefit Cap Calendar Month'
    }
]

export const deducticleOopOptions = [
    {
        key: 0,
        text: 'Combined Medical & Rx',
        value: 'Combined Medical & Rx'
    },
    {
        key: 1,
        text: 'Rx Only',
        value: 'Rx Only'
    }
]
export const oopSatisfactionOptions = [
    {
        key: 0,
        text: 'N/A - Not Applicable',
        value: 'N/A - Not Applicable'
    },
    {
        key: 1,
        text: 'Individual Only Applies',
        value: 'Individual Only Applies'
    },
    {
        key: 2,
        text: 'Individual & Family Applies',
        value: 'Individual & Family Applies'
    },
    {
        key: 3,
        text: 'Family Only Apply',
        value: 'Family Only Apply'
    },
     //coment the OOP Satisfaction  FT-4445
    // {
    //     key: 2,
    //     text: 'Greater of Individual or Family',
    //     value: 'Greater of Individual or Family'
    // },
    // {
    //     key: 3,
    //     text: 'Individual Only Satisfied',
    //     value: 'Individual Only Satisfied'
    // },
    // {
    //     key: 4,
    //     text: 'Family Only Satisfied',
    //     value: 'Family Only Satisfied'
    // },
    // {
    //     key: 5,
    //     text: 'Cov Type determines Ind/Fam Only',
    //     value: 'Cov Type determines Ind/Fam Only'
    // },
    // {
    //     key: 6,
    //     text: 'Individual Dedudctible Satisfied by Two Members',
    //     value: 'Individual Dedudctible Satisfied by Two Members'
    // }
]