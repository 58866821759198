import React, { PureComponent } from 'react'
import FliptButton from '../../../../components/form/fliptButton'
import { capitalizeStr } from '../../../../utils/utilities'
import './styles.scss'

class ClaimConfirmModal extends PureComponent {
  closeModalBtn = () => {
    const { closeModal } = this.props
    closeModal()
  }

  adjustmentbtnClick = () => {
    const { adjustmentbtn, closeModal } = this.props
    adjustmentbtn()
    closeModal()
  }

  render() {
    const { calculateAdjustmentsData } = this.props
    return (
      <div className="popup-wrap">
        <div className="popup-content">
          <h1>Confirm Data</h1>
          <div className="data-wrap">
            {
              Object.keys(calculateAdjustmentsData).map((it, key) => (
                <div className="data-holder" key={key}>
                  <span className="data-title">{capitalizeStr(it)}</span>
                  <span className="data-value">{calculateAdjustmentsData[it]}</span>
                </div>
              ))
            }
            <div className="data-holder data-btn-wrap">
              <FliptButton name="Cancel" className="secondary cancel" onClick={this.closeModalBtn} />
              <FliptButton name="Adjustment" className="primary adjustment" onClick={this.adjustmentbtnClick} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ClaimConfirmModal
