import React, { Component } from 'react'
import { Button, Icon } from 'semantic-ui-react'
import _, { drop, isEmpty, isEqual } from 'lodash'
import './styles.scss'
import { convertDateToStartTimeObj, convertDateToEndTimeObj, parseQueryString } from '../../../../../utils/utilities'
import BreadCrumb from '../../../../../components/breadCrumb'
import discountType from './data/discountType'
import feeTypes from './data/feeTypes'
import { pharmacy, tier } from './data/conditions'
import FliptButton from '../../../../../components/form/fliptButton'
import priceType from './data/priceType'
import SaveTierModal from './saveTierModal'
import TierConditions from './tierConditions/index'
import AssignPharmacies from '../../assignPharmacies'
import config from '../../../../../config'
import feeNames from './data/feeNames'
const defaultDropDownValues = {
  price_type: priceType, discount_type: discountType, fee_type: feeTypes, fee_name: feeNames, mac_list_id: [],
}

class CreateNetworkComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      step: 1,
      form: {
        tier_name: '',
        tier_version: '1.0',
        effective_start_date: '',
        version_effective_date: '',
        network_tier_type: 'Pricing',
        display_on_top: '',
        preferred_tier: false,
        hierarchy: [],
        is_hierarchy_global: false,
        hierarchyIDs: {},
        hideHierarchy: false,
        no_bill_no_pay: 'Select Yes or No',
      },
      tierConditions: [[{ ...tier }]],
      pharmacyConditions: [[{ ...pharmacy }]],
      activeTierConditionLevel: 0,
      activeConditionLevel: 0,
      dropdownValues: [[{ ...defaultDropDownValues }]],
      autoSuggestionValues: [{
        manufacturer: { values: [], loading: false }, drug_name: { values: [], loading: false },
      }],
      rowUpdated: null,
      columnUpdated: null,
      autoSuggestionMinChars: 3,
      chainCode: [],
      corpName: [],
      specialtyDrug: [],
    }
  }

  componentDidMount() {
    const { actions, history, screen, state } = this.props
    const {
      tierConditions
    } = state
    const editMode = (this.props.history.location.state && 'editMode' in this.props.history.location.state) ? this.props.history.location.state.editMode : true
    if (!_.isEmpty(tierConditions)) {
      actions.clearNetworkTier()
    }
    if (editMode) {
      actions.clearNetworkTier()
      setTimeout(() => actions.getClaimProcessorNetworkData(), config.COMPONENT_DATA_LOAD_TIME)
      this.setState({ editMode: true })
    }
    if (!history?.location?.search) return
    const params = parseQueryString(history.location.search)
    params.doc_type = screen === 'edits' ? 'global_network_edit' : 'global_network_tier'
    setTimeout(() => actions.getNetworkTierData(params), config.COMPONENT_DATA_LOAD_TIME)

  }
  setForm = (form) => {
    this.setState({ form })
  }
  componentDidUpdate(prevProps) {
    const { props, state } = this
    const { specialtyDrug } = state
    const { specialtyDrug: sDrug, networkEditData } = props?.state || {}
    const { pricing_conditions, pharmacy_conditions } = networkEditData || {}
    if (!isEqual(specialtyDrug, sDrug) && !isEmpty(sDrug))
      this.setState({ specialtyDrug: sDrug })
    if (!pricing_conditions || !pharmacy_conditions)
      return
    if (isEqual(props.state.networkEditData, prevProps.state.networkEditData)) return
    this._updateState()
  }

  // eslint-disable-next-line react/destructuring-assignment
  _constructDropdownOptions = () => this.state.dropdownValues.map((grid) => {
    if (!Array.isArray(grid)) {
      grid = new Array(grid)
    }
    const gridOptions = grid.map((row) => this._mapValueToOption(row))
    return gridOptions
  })

  _mapValueToOption = (row) => {
    const data = {
      ...row,
    }
    return data
  }

  _updateFields = (el, dateData) => {
    const { name, value } = dateData || el.currentTarget

    if (name === 'network_tier_type' && value === 'Exclude') {
      this.setState((prevState) => ({
        ...prevState,
        tierConditions: [[{ ...tier }]],
        form: {
          ...prevState.form,
          [name]: value,
        },
      }))
      return;
    }
    if (name === 'version_effective_date') {
      this.setState((prevState) => ({
        ...prevState,
        form: {
          ...prevState.form,
          [name]: convertDateToStartTimeObj(value),
        },
      }))
      return;
    }
    this.setState((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        [name]: value,
      },
    }))
  }

  _handleChange = (el, dropdown, rowIndex, gridApi, level) => {
    const { state } = this
    const tierConditions = [...state.tierConditions]
    const dropdownValues = [...state.dropdownValues]
    const rowToUpdate = tierConditions[level][rowIndex]
    const dropDownRowToUpdate = dropdownValues[level][rowIndex]
    const { name, value, id } = dropdown || el.currentTarget
    if (name === 'days_supply' || name === 'discount') {
      if (Number.isInteger(value)) {
        rowToUpdate[name] = value
      }
    } else if (name === 'fee_name' && (rowToUpdate.fee_value || rowToUpdate.fee_type)) {
      rowToUpdate[name] = value
      let valueObj = {}
      let typeObj = {}
      value.forEach(element => {
        valueObj[element] = rowToUpdate.fee_value[element] || ''
        typeObj[element] = rowToUpdate.fee_type[element] || ''
      })
      rowToUpdate.fee_value = valueObj
      rowToUpdate.fee_type = typeObj

    } else if (name === 'fee_value') {
      rowToUpdate[name] = {
        ...rowToUpdate[name],
        [id]: value,
      }
    } else if (name === 'fee_type') {
      rowToUpdate[name] = {
        ...rowToUpdate[name],
        [id]: value,
      }
    } else {
      rowToUpdate[name] = value
    }
    tierConditions[level][rowIndex] = rowToUpdate
    dropdownValues[level][rowIndex] = dropDownRowToUpdate

    this.setState({
      tierConditions, dropdownValues, rowUpdated: rowIndex, columnUpdated: name,
    })
    gridApi.refreshCells()
    gridApi.resetRowHeights()
  }

  _handleConditionLevelClick = (e, titleProps) => {
    const { state } = this
    const { activeConditionLevel } = state
    const { index } = titleProps
    const newIndex = activeConditionLevel === index ? -1 : index
    this.setState({ activeConditionLevel: newIndex })
  }

  _delRow = (rowIndex, level) => {
    const { tierConditions, dropdownValues } = this.state
    if (tierConditions[level].length <= 1) return
    const newTierConditions = [...tierConditions]
    const newDropDownValues = [...dropdownValues]
    this.setState({
      tierConditions: [newTierConditions[level].filter((_, index) => index !== rowIndex)],
      dropdownValues: [newDropDownValues[level].filter((_, index) => index !== rowIndex)],
    })
  }

  _addRow = (level, gridRef) => {
    const { tierConditions, dropdownValues, form } = this.state
    const newTierConditions = _.cloneDeep(tierConditions);
    const newDropDownValues = _.cloneDeep(dropdownValues);
    newTierConditions[level].push({ ...tier, effective_start_date: form.effective_start_date || "", })
    newDropDownValues[level].push({ ...defaultDropDownValues })
    this.setState({
      tierConditions: newTierConditions,
      dropdownValues: newDropDownValues,
    },
      () => {
        if (gridRef.current && gridRef.current.api) {
          gridRef.current.api.refreshCells()
        }
      })
  }

  _generateEdit = () => {
    const { props, state } = this
    const { history: { location }, screen } = props
    const { tierConditions, pharmacyConditions, form: { tier_name, no_bill_no_pay, effective_start_date, tier_version, network_tier_type, preferred_tier, display_on_top, version_effective_date,
      hierarchy, hierarchyIDs, is_hierarchy_global } } = state
    const formattedTierConditionsLevel = []
    if (network_tier_type === 'Pricing') {
      const { formattedTierConditions, invalidDate } = this.newTierConditionDateValidation(tierConditions)
      if (invalidDate) return
      else formattedTierConditionsLevel.push(...formattedTierConditions)
    }

    const data = {
      doc_name: tier_name,
      doc_version: tier_version,
      no_bill_no_pay: no_bill_no_pay,
      effective_start_date,
      version_effective_date,
      effective_end_date: "",
      hierarchy,
      hierarchyIDs,
      is_hierarchy_global: is_hierarchy_global ? is_hierarchy_global : false,
      network_tier_type,
      preferred_tier,
      display_on_top,
      pricing_conditions: network_tier_type === 'Pricing' ? [...formattedTierConditionsLevel] : [],
      pharmacy_conditions: pharmacyConditions,
      doc_type: screen === 'edits' ? 'global_network_edit' : 'global_network_tier'
    }

    if (data.pricing_conditions?.length) {
      const allParsedPricingData = []
      for (const condition of data.pricing_conditions) {
        const parsedPricingData = []
        for (const pricingData of condition) {
          const parsedFeeData = []
          const parsedDrugList = []
          if (pricingData.fee_name?.length) {
            pricingData.fee_name.map((feeData) => {
              parsedFeeData.push({
                fee_name: feeData,
                fee_type: pricingData.fee_type[feeData],
                fee_value: isNaN(pricingData?.fee_value[feeData]) ? parseFloat(pricingData?.fee_value[feeData]?.replace('%', '')) : pricingData?.fee_value[feeData],
              })
            })
          }
          if (pricingData.specialty_drug_list?.length) {
            pricingData.specialty_drug_list.map((drugList, index) => {
              parsedDrugList.push(drugList)
            })

          }
          pricingData.program_drug_list = parsedDrugList
          pricingData.fees = parsedFeeData
          pricingData.min_days_supply = pricingData.min_days_supply || pricingData.min_days_supply === '0' || pricingData.min_days_supply === 0 ? parseInt(pricingData.min_days_supply) : null
          pricingData.max_days_supply = pricingData.max_days_supply || pricingData.max_days_supply === '0' || pricingData.max_days_supply === 0 ? parseInt(pricingData.max_days_supply) : null
          pricingData.discount = pricingData.discount || pricingData.discount === '0' || pricingData.discount === 0 ? parseFloat(pricingData.discount) : null
          delete pricingData.fee_name
          delete pricingData.fee_type
          delete pricingData.fee_value
          delete pricingData.specialty_drug_list
          parsedPricingData.push(pricingData)
        }
        allParsedPricingData.push(parsedPricingData)
      }
      data.pricing_conditions = allParsedPricingData;
    }
    if (location?.state?.doc_id) {
      data.doc_id = location?.state?.doc_id
    }
    if (screen === 'edits')
      props.actions.generateNetworkEdit({ ...data })
    else
      props.actions.generateNetworkTier({ ...data })
  }

  checkDateValidation = (tierConditions) => {
    const { actions } = this.props
    let invalidDate = false
    let formattedTierConditions = tierConditions.map((tierData) => {
      if (!tierData.effective_start_date) { invalidDate = true }
      return ({
        ...tierData,
        effective_start_date: convertDateToStartTimeObj(tierData.effective_start_date),
        effective_end_date: convertDateToEndTimeObj(tierData.effective_end_date)
      })
    })
    if (invalidDate) {
      const transitionalPortal = {
        header: 'Formulary Tier Save Failed',
        copy: `Please Add effective Start Date before saving`
      }
      actions.displayTransitionalPortal(transitionalPortal)
    }
    return ({ formattedTierConditions, invalidDate })
  }

  newTierConditionDateValidation = (tierConditions) => {
    const { actions } = this.props
    let invalidDate = false
    let formattedTierConditions = tierConditions.map((condition) => condition.map((tierData) => {
      if (!tierData.effective_start_date) { invalidDate = true }
      return ({
        ...tierData,
        effective_start_date: convertDateToStartTimeObj(tierData.effective_start_date),
        effective_end_date: convertDateToEndTimeObj(tierData.effective_end_date)
      })
    }))
    if (invalidDate) {
      const transitionalPortal = {
        header: 'Formulary Tier Save Failed',
        copy: `Please Add effective Start Date before saving`
      }
      actions.displayTransitionalPortal(transitionalPortal)
    }
    return ({ formattedTierConditions, invalidDate })
  }

  _generateAssignedPharmacyNetworkEdits = (level) => {
    const { props, state } = this
    const { pharmacyConditions } = state
    const tierConditions = pharmacyConditions[level]
    const { formattedTierConditions, invalidDate } = this.checkDateValidation(tierConditions)
    if (invalidDate) return
    props.actions.generateAssignedPharmacy({ tierConditions: formattedTierConditions, level })
  }

  _saveNetworkTier = () => {
    const { props, state } = this
    const { screen } = props
    const { tierConditions, form: { network_tier_type } } = state

    if (typeof state.form.no_bill_no_pay != 'boolean') {
      const transitionalPortal = {
        header: 'Fields are missing',
        copy: `No Bill No Pay field is not set, Please select a value.`,
      }
      props.actions.displayTransitionalPortal(transitionalPortal)
      return;

    }
    const type = screen === 'edits' ? 'edits' : 'tier'
    const message = `You are about to save the network ${type}. Do you wish to save?`
    const validation = tierConditions.map((condition) => {
      const mutatedCondition = _.compact(condition.map((it) => {
        const {
          min_days_supply,
          max_days_supply,
          fee_type,
          fee_value,
          fee_name,
          mac_list_id,
          price_floor,
          specialty_drug_list,
          ...other } = it
        let validationObj = other
        if (other.price_type === 'MAC')
          validationObj = { ...other, mac_list_id }
        const emptyKey = Object.keys(validationObj).find(key => !String(validationObj[key]) && key !== "effective_end_date")
        return emptyKey
      }))
      return mutatedCondition;
    })
    if (!_.isEmpty(validation[0]) && network_tier_type === 'Pricing' && ((validation[0] || [])[0]) !== 'program_drug_list') {
      const transitionalPortal = {
        header: 'Fields are missing',
        copy: `Missing ${_.startCase(validation[0])}`,
      }
      props.actions.displayTransitionalPortal(transitionalPortal)
      return;
    }
    props.actions.setModalComponent({
      modalProperties: {
        size: 'tiny',
      },
      contents: SaveTierModal({
        onSaveClick: () => this._generateEdit(), toggleModal: props.actions.toggleModal, message,
      }),
    })
    props.actions.toggleModal()
  }

  _handlePharmacyConditionChange = (el, dropdown, rowIndex, gridApi, level) => {
    const { pharmacyConditions } = this.state
    const updatedPharmacyConditionLevels = { ...pharmacyConditions[level][rowIndex] }
    const { name, value, id } = dropdown || el.currentTarget
    updatedPharmacyConditionLevels[name] = value

    if (name === 'pharmacy_name') {
      updatedPharmacyConditionLevels[name] = value.toUpperCase()
    }

    if (name === 'effective_end_date') {
      updatedPharmacyConditionLevels[name] = convertDateToEndTimeObj(value)
    }

    pharmacyConditions[level][rowIndex] = updatedPharmacyConditionLevels
    this.setState({
      pharmacyConditions: [...pharmacyConditions],
    })
  }

  _addPharmacyCondition = (level) => {
    const { pharmacyConditions, form } = this.state
    const updatedPharmacyConditionLevels = pharmacyConditions[level].concat(
      { ...pharmacy, include_exclude: 'include', effective_start_date: form.effective_start_date || "", }
    )

    pharmacyConditions[level] = updatedPharmacyConditionLevels
    this.setState({
      pharmacyConditions: [...pharmacyConditions],
    })
  }

  _removePharmacyCondition = (index, level) => {
    const { pharmacyConditions } = this.state
    const newPharmacyConditions = [...pharmacyConditions]
    newPharmacyConditions[level] = newPharmacyConditions[level].filter((_, i) => i !== index)
    this.setState({
      pharmacyConditions: newPharmacyConditions,
    })
  }

  _removeConditionLevel = (level) => {
    const { tierConditions, dropdownValues, pharmacyConditions } = this.state
    const newTierConditions = tierConditions.filter((_, index) => index !== level)
    const newDropDownValues = dropdownValues.filter((_, index) => index !== level)
    const newPharmacyConditions = pharmacyConditions.filter((_, index) => index !== level)
    this.setState({
      tierConditions: [...newTierConditions],
      dropdownValues: [...newDropDownValues],
      pharmacyConditions: [...newPharmacyConditions],
    })
  }

  _addNewPharmacyConditionLevel = () => {
    const { state } = this
    const pharmacyConditions = [...state.pharmacyConditions]

    pharmacyConditions.push(
      [{
        pharmacy_name: '',
        chain_code: '',
        relationship_id: '',
        relationship_name: '',
        relationship_type: '',
        corp_name: '',
        npi: '',
        state: '',
        zip_code: '',
        include_exclude: 'include',
        action: '',
      }],
    )
    this.setState({
      activeConditionLevel: pharmacyConditions.length - 1,
      pharmacyConditions,
    })
  }

  _addConditionLevel = () => {
    const { state } = this
    const { tierConditions, dropdownValues, pharmacyConditions } = state
    const newTierConditions = _.cloneDeep(tierConditions)
    const newDropDownValues = _.cloneDeep(dropdownValues)
    const newPharmacyConditions = _.cloneDeep(pharmacyConditions)
    newTierConditions.push([{ ...tier }])
    newDropDownValues.push([{ ...defaultDropDownValues }])
    newPharmacyConditions.push([{ ...pharmacy }])
    this.setState({
      activeConditionLevel: newTierConditions.length - 1,
      tierConditions: newTierConditions,
      dropdownValues: newDropDownValues,
      pharmacyConditions: newPharmacyConditions,
    })
  }

  _updateState() {
    const { props, state } = this
    const { tierConditions, pharmacyConditions } = state
    const { chainCode, corpName, specialtyDrug, networkEditData: { doc_version, doc_name, effective_start_date, version_effective_date, no_bill_no_pay,
      pricing_conditions, pharmacy_conditions, network_tier_type, hierarchy, is_hierarchy_global, hierarchyIDs, preferred_tier, display_on_top } } = props.state
    let editMode = true
    if ('state' in props.history.location) {
      editMode = (props.history.location.state && 'editMode' in props.history.location.state) ? props.history.location.state.editMode : true
    }
    let tempDropdownValues = []
    if (pricing_conditions?.length) {
      for (let i = 0; i < pricing_conditions.length; ++i) {
        const condition = pricing_conditions[i]
        // create dropdowns for each row in each pricing condition
        tempDropdownValues.push(new Array(condition.length).fill(defaultDropDownValues))
      }
    }
    const form = {
      ...state.form,
      tier_version: doc_version || '0.1',
      tier_name: doc_name || '',
      no_bill_no_pay: no_bill_no_pay || false,
      effective_start_date: effective_start_date,
      version_effective_date,
      preferred_tier,
      display_on_top,
      network_tier_type,
      hierarchy,
      is_hierarchy_global,
      hierarchyIDs
    }
    const normalizePricing = pricing_conditions?.length > 0 && pricing_conditions.reduce((allConditions, conditions) => {
      const parsedCondition = conditions.map((cond) => ({
        ...cond,
        effective_start_date: cond.effective_start_date || convertDateToStartTimeObj(effective_start_date)
      }))
      allConditions.push(parsedCondition)
      return allConditions;
    }, [])
    if (pharmacy_conditions?.length > normalizePricing.length) {
      // pharmacy conditions need to map 1:1 with normalize pricing
      const diff = pharmacy_conditions.length - normalizePricing.length
      for (let i = 0; i < diff; ++i) {
        normalizePricing.push([{ ...tier }])
        tempDropdownValues.push([defaultDropDownValues])
      }
    }
    this.setState({
      form,
      dropdownValues: tempDropdownValues,
      tierConditions: normalizePricing || tierConditions,
      pharmacyConditions: pharmacy_conditions || pharmacyConditions,
      chainCode,
      corpName,
      specialtyDrug,
    })
  }


  render() {
    const { props, state } = this
    const {
      form, tierConditions, specialtyDrug, autoSuggestionValues, autoSuggestionMinChars, step, pharmacyConditions, activeConditionLevel,
    } = state
    let editMode = true
    const { screen, state: { claimProcessorNetworkData, chainCode, corpName, networkTierData } } = props
    const type = screen === 'edits' ? 'Edits' : 'Tier'
    if ('state' in props.history.location) {
      editMode = (props.history.location.state && 'editMode' in props.history.location.state) ? props.history.location.state.editMode : true
    }

    const dropdownOptions = this._constructDropdownOptions()
    const claim_processor = claimProcessorNetworkData.map((it) => (
      {
        key: it,
        value: it,
        text: it,
      }
    ))
    return (
      <div id="createEdits">
        <BreadCrumb {...this.props} />
        {
          step === 1 ? (
            <>
              <div className="header">Create Network {type}</div>
              <div className="content">
                <TierConditions
                  _addNewPharmacyConditionLevel={this._addNewPharmacyConditionLevel}
                  activeConditionLevel={activeConditionLevel}
                  addConditionLevel={this._addConditionLevel}
                  addPharmacyCondition={this._addPharmacyCondition}
                  addRow={this._addRow}
                  autoSuggestionMinChars={autoSuggestionMinChars}
                  autoSuggestionValues={autoSuggestionValues}
                  chainCode={chainCode}
                  claim_processor={claim_processor}
                  corpName={corpName}
                  delRow={this._delRow}
                  dropdownOptions={dropdownOptions}
                  editMode={editMode}
                  form={form}
                  generateAssignedPharmacy={this._generateAssignedPharmacyNetworkEdits}
                  generateTier={this._saveNetworkTier}
                  handleChange={this._handleChange}
                  handleConditionLevelClick={this._handleConditionLevelClick}
                  handlePharmacyConditionChange={this._handlePharmacyConditionChange}
                  networkTierData={networkTierData}
                  pharmacyConditions={pharmacyConditions}
                  removeConditionLevel={this._removeConditionLevel}
                  removePharmacyCondition={this._removePharmacyConditionLevel}
                  removePharmacyConditionRow={this._removePharmacyCondition}
                  setForm={this.setForm}
                  specialtyDrug={specialtyDrug}
                  tierConditions={tierConditions}
                  updateFields={this._updateFields}
                />
              </div>
            </>
          ) : (
            <>
              <div className="header">
                <div className="headerContiner">
                  <div className="headerText">Assign Pharmacy to {form.tier_name}</div>
                  <div className="headerAction">
                    <Button size="small" className="addBtn" onClick={this._addNewPharmacyConditionLevel}>
                      <Icon name="add" />
                      Add New Pharmacy Condition Level
                    </Button>
                  </div>
                </div>
              </div>
              <div className="content" id="assignPharmaciesContainer">
                <AssignPharmacies
                  activeConditionLevel={activeConditionLevel}
                  addPharmacyCondition={this._addPharmacyCondition}
                  chainCode={chainCode}
                  conditionLevels={pharmacyConditions}
                  corpName={corpName}
                  editMode={editMode}
                  generateAssignedPharmacy={this._generateAssignedPharmacyNetworkEdits}
                  handleChange={this._handlePharmacyConditionChange}
                  handleConditionLevelClick={this._handleConditionLevelClick}
                  removePharmacyConditionRow={this._removePharmacyCondition}
                />
              </div>
            </>
          )
        }
        <div className="buttonContainer">
          <FliptButton name="Back" className="primary searchButton" onClick={() => props.actions.navigateTo({ pathname: "/network-tier" })} />
          {editMode &&
            <FliptButton name="Save" className="primary searchButton" onClick={this._saveNetworkTier} />
          }
          {/* <FliptButton name="Next" disabled={(step === 2) || !form.tier_name} className="primary searchButton" onClick={() => this.setState({ step: step + 1 })} /> */}
        </div>
      </div>
    )
  }
}

export default CreateNetworkComponent

