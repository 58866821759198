export const pharmacy = {
  pharmacy_name: '',
  chain_code: '',
  relationship_id: '',
  relationship_name: '',
  relationship_type: '',
  corp_name: '',
  npi: '',
  state: '',
  zip_code: '',
  include_exclude: 'include',
  effective_start_date: '',
  effective_end_date: ''
}

export const tier = {
  claim_processor: '',
  price_type: '',
  specialty_drug_list: [],
  min_days_supply: '',
  max_days_supply: '',
  fixed_variable: 'VARIABLE',
  rate_type: '',
  discount: '',
  fee_type: {},
  fee_name: '',
  fee_value: {},
  mac_list_id: '',
  price_floor: 0,
  effective_start_date: '',
  effective_end_date: ''
}