import React from 'react'

import './styles.scss'
import FliptInput from '../../../../../../components/form/fliptInput'
import ButtonSection from './buttonSection'
import { formatAddress } from '../../../../../../utils/utilities'

export default function AddressSection(props) {
  const {
    locations, submitForm, toggleModal, updateFields,
  } = props

  return (
    <div id="addressSection">
      <div className="header-label">Addresses</div>
      <div className="address-list">
        {locations.map((location, idx) => {
          const address = formatAddress(location)

          return <div key={`${idx} ${address}`}>{address}</div>
        })}
      </div>
      <div className="fields">
        <FliptInput name="street_address" placeholder="New Address" onChange={updateFields} />
        <FliptInput name="street_address_2" placeholder="Apt/Ste" onChange={updateFields} />
        <FliptInput name="city" placeholder="City" onChange={updateFields} />
        <FliptInput name="state" placeholder="State" onChange={updateFields} />
        <FliptInput name="zip_code" placeholder="Zip" onChange={updateFields} />
      </div>
      <div className="address-buttons">
        <ButtonSection toggleModal={toggleModal} submitForm={submitForm} />
      </div>
    </div>
  )
}
