/* eslint-disable */
import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import './styles.scss'
import BreadCrumb from '../../../../components/breadCrumb'
import Config from '../../../../config'
import FliptGrid from '../../../../components/fliptGrid'
import FliptButton from '../../../../components/form/fliptButton'
import { Creators as NavigationActions } from '../../../../redux/reducers/navigation'
import { Creators as companyManagementActions } from '../../../../redux/reducers/companyManagement'
import { convertDateTimeToDate, filterRowData } from '../../../../utils/utilities'
import { LinkRenderer, EllipsisActionMenuRenderer, ColorCodedStatusRenderer } from '../../../../components/fliptGrid/cellRenderers'
import increaseRowHeight from '../../../../components/fliptGrid/cellClickEvents'
import FliptTree from '../../../../components/fliptTree'


class CompanyManagement extends Component {
  componentDidMount() {
    const { actions } = this.props
    setTimeout(() => actions.getCompanyListData(), Config.COMPONENT_DATA_LOAD_TIME)
  }

  _dataCleanup = () => {
    const { state: { data } } = this.props
    return data.map((d) => ({
      id: d.id,
      company: d.company_full_name,
      company_code: d.company_code,
      effective_start_date: convertDateTimeToDate(d.effective_start_date),
      effective_end_date: d.effective_end_date === '' ? '---' : convertDateTimeToDate(d.effective_end_date),
      line_of_business: d.line_of_business,
      business_type: d.business_type,
      address: `${d.address1 !== undefined ? d.address1 : ''} ${d.address2 !== undefined ? d.address2 : ''} ${d.city !== undefined ? d.city : ''}, ${d.state !== undefined ? d.state : ''} ${d.zip}`,
      phone: d.company_phone_number,
      full_name: d.admin_contacts && d.admin_contacts[0] ? `${d.admin_contacts[0].first_name} ${d.admin_contacts[0].last_name}` : '',
      status: d.active ? 'Active' : 'Inactive',
    }))
  }

  render() {
    let gridData = this._dataCleanup()
    const headers = [
      'Action',
      'Organization Name',
      'Organization Code',
      'Effective Start Date',
      'Effective End Date',
      'Line of Business (LOB)',
      'Business Type',
      'Address',
      'Phone Number',
      'Full Name',
      'status',
      'id',
    ]
    const headerMapping = {
      company: 'Organization Name',
      company_code: 'Organization Code',
      effective_start_date: 'Effective Start Date',
      effective_end_date: 'Effective End Date',
      line_of_business: 'Line of Business (LOB)',
      business_type: 'Business Type',
      address: 'Address',
      phone: 'Phone Number',
      full_name: 'Full Name',
    }
    gridData = gridData && gridData.length ? gridData.map((d) => ({
      ...filterRowData(d, headers, headerMapping),
    })) : []
    const cellRendererParams = {
      id: {
        hide: true,
      },
      'Organization Name': {
        cellRenderer: LinkRenderer,
        // Paths need to be changed
        path: '/company-management',
        searchArgs: { company_code: 'company_code' },
        state: { editMode: false },
      },
      'Full Name': {
        cellRenderer: LinkRenderer,
        // Paths need to be changed
        path: '/company-management',
        searchArgs: { company_code: 'company_code' },
        state: { editMode: false },
      },
      Action: {
        cellRenderer: EllipsisActionMenuRenderer,
        searchArgs: {
          path: '/company-management-edit',
          pathHierarchy: '/company-management-hierarchy',
          qsArgs: { id: 'id' },
        },
        state: { editMode: true },
        onCellClicked: increaseRowHeight,
      },
      status: {
        cellRenderer: ColorCodedStatusRenderer,
      },
    }
    return (
      <div id="companyManagement">
        <BreadCrumb {...this.props} />
        <div className="buttonContainer" align="right">
          <Link to="/company-management-create">
            <FliptButton
              name="CREATE NEW ORGANIZATION"
              className="primary searchButton"
            />
          </Link>
        </div>
        <div className="content">
          <FliptGrid
            data={gridData}
            headers={headers}
            cellRendererParams={cellRendererParams}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  state: {
    data: state.companyManagement.companyListing,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...companyManagementActions,
    ...NavigationActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyManagement)
