import Immutable from 'seamless-immutable'
import { createActions, createReducer } from 'reduxsauce'
import moment from 'moment'

export const INITIAL_STATE = Immutable({
  implementation: {},
  allImplementationData: [],
  allImplementationTasks: [],
  task: null,
  taskObject: null,
  tasks: null,
  taskObjects: [],
  itemsPerPage: 20,
  totalRows: 0
})

export const setClientImplementationReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  implementation: payload.data[0],
})

export const setAllClientImplementationReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  allImplementationData: payload.data,
})

export const setCreateClientImplementationReducer = (state = INITIAL_STATE) => ({
  ...state,
})

export const setEditClientImplementationReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  implementation: payload.data,
})

export const setImplementationTaskObjectListReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  taskObjects: payload.data,
})

export const clearImplementationReducer = (state = INITIAL_STATE) => ({
  ...state,
  implementation: {},
  allImplementationData: [],
  itemsPerPage: 20,
  totalRows: 0,
})

export const clearImplementationTaskReducer = (state = INITIAL_STATE) => ({
  ...state,
  implementation: {},
  tasks: [],
  taskObjects: []
})

export const setAllImplementationTasksReducer = (state = INITIAL_STATE, { payload }) => {
  return {
    ...state,
    allImplementationTasks: formatQueueData(payload.data)
  }
}

export const clearReducer = () => INITIAL_STATE

export const { Types, Creators } = createActions({
  createClientImplementation: ['payload'],
  clearImplementationReducer: null,
  clearImplementationTaskReducer: null,
  clear: null,
  getAllClientImplementation: ['payload'],
  getAllImplementationTasks: ['payload'],
  getClientImplementation: ['payload'],
  editClientImplementation: ['payload'],
  setClientImplementation: ['payload'],
  setEditClientImplementation: ['payload'],
  setCreateClientImplementation: ['payload'],
  setAllClientImplementation: ['payload'],
  getImplementationTaskObjectList: ['payload'],
  setImplementationTaskObjectList: ['payload'],
  setAllImplementationTasks: ['payload'],
})


const HANDLERS = {
  [Types.SET_IMPLEMENTATION_TASK_OBJECT_LIST]: setImplementationTaskObjectListReducer,
  [Types.CLEAR_IMPLEMENTATION_TASK_REDUCER]: clearImplementationTaskReducer,
  [Types.CLEAR_IMPLEMENTATION_REDUCER]: clearImplementationReducer,
  [Types.CLEAR]: clearReducer,
  [Types.SET_CLIENT_IMPLEMENTATION]: setClientImplementationReducer,
  [Types.SET_ALL_CLIENT_IMPLEMENTATION]: setAllClientImplementationReducer,
  [Types.SET_CREATE_CLIENT_IMPLEMENTATION]: setCreateClientImplementationReducer,
  [Types.SET_EDIT_CLIENT_IMPLEMENTATION]: setEditClientImplementationReducer,
  [Types.SET_ALL_IMPLEMENTATION_TASKS]: setAllImplementationTasksReducer,
}

export default createReducer(INITIAL_STATE, HANDLERS)


function formatQueueData(data) {
  if (!data) return {}
  const moduleName = {
    'BENEFIT_PLAN_DOC': 'Benefit Plan Design',
    'FORMULARY': 'Formulary',
    'FORMULARY_TIER': 'Formulary Drug List',
    'POS_REBATE': 'Program Edits',
    'PRIOR_AUTHORIZATION': 'PA Drug List',
    'PROGRAM_LIST': 'Program Edits',
    'QUANTITY_LIMIT': 'Quantity Limits',
    'STEP_THERAPY': 'Step Therapy',
  }

  const dd = data.map((d) => {
    // d.assigned_to = String(d.assigned_to).split('-')[2]
    // d.last_updated_by = String(d.last_updated_by).split('-')[2]
    d.module = moduleName[d.module] || d.module
    d.task_begin_date = moment(d.task_begin_date).format("MM/DD/YYYY h:MM:SS A")
    d.task_end_date = moment(d.task_end_date).format("MM/DD/YYYY h:MM:SS A")
    d.assignment_type = 'Implementation Task'

    return d
  })
  return dd
}
