import React, { useState, useEffect, useRef } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as _ from 'lodash'
import FliptGrid from '../../../../components/fliptGrid'
import BreadCrumb from '../../../../components/breadCrumb'
import { Creators as DrugManagementActions } from '../../../../redux/reducers/api/drugManagement'
import { Creators as AppActions } from '../../../../redux/reducers/app'
import FliptButton from '../../../../components/form/fliptButton'
import ExportButton from './exportData'
import { LinkContainerRenderer, ColorCodedStatusRenderer } from '../../../../components/fliptGrid/cellRenderers'
import { parseQueryString } from '../../../../utils/utilities'
import Config from '../../../../config'
import './styles.scss'

const ViewChangesByType = (props) => {
  const { history, state, actions } = props
  const { urlHistory } = state?.app || {}
  const { pathname, search } = window.location
  let srch = search || ''
  if(urlHistory[pathname] !== search && search)
    actions.setUrlHistory({ [pathname]: search })
  if(!srch && !_.isEmpty(urlHistory[pathname])){
    srch = urlHistory[pathname]
    //window.location.search =srch
  }
  const { file_id } = parseQueryString(srch)
  const headers = [
    'display_name',
    'change_type',
    'total_changes',
    'object_impacted',
    'review_status',
    'view_changes',
  ]
  const cellRendererParams = {
    display_name: {
      width: 200,
      overrideHeader: 'Name',
    },
    change_type: {
      width: 200,
      overrideHeader: 'Change Type',
    },
    total_changes: {
      width: 200,
      overrideHeader: 'Total Changes',
    },
    object_impacted: {
      width: 200,
      overrideHeader: 'Object Impacted',
    },
    review_status: {
      cellRenderer: ColorCodedStatusRenderer,
      width: 200,
      overrideHeader: 'Review Status',
    },
    view_changes: {
      cellRenderer: LinkContainerRenderer,
      searchArgs: [
        {
          path: '/change-type-details',
          searchArgs: { change_type: 'change_type', change_name: 'display_name' },
          staticSearchArgs: { file_id },
          label: 'View Changes',
        },
      ],
    },
  }
  const { changesByTypeSummary: dat } = state
  const [data, setData] = useState(dat)
  const gridRef = useRef()
  useEffect(() => {
    if (dat && !_.isEqual(data, dat)) {
      setData(dat)
    }
  }, [dat])
  useEffect(() => {
    setTimeout(() => actions.getDmChangesByTypeData({ file_id }), Config.COMPONENT_DATA_LOAD_TIME)
  }, [])
  return (
    <div id="planDesignManagement">
      <BreadCrumb {...props} />
      <div className="header">
        Type Change Processing
        <div>
          <ExportButton grid={gridRef?.current?.gridApi} />
        </div>
      </div>
      <div className="content maxHeight">
        <FliptGrid gridRef={gridRef} data={data} headers={headers} cellRendererParams={cellRendererParams} />
      </div>
      <div id="close-button">
        <FliptButton className="closeButton" compact name="Close" onClick={() => history?.goBack()} />
      </div>
    </div>
  )
}
const mapStateToProps = (state) => ({
  state: {
    changesByTypeSummary: state.drugManagement.changesByTypeSummary,
    app: state.app,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
    ...DrugManagementActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewChangesByType)
