export const headers = [
    'transaction_id',
    'domain_name',
    'line_number',
    'relationship',
    'person_code',
    'employee_id',
    'gender',
    'work_location',
    'city',
    'state',
    'zip',
    'coverage_tier_name',
    'employment_status',
    'benefit_plan_name',
    'group',
    'display_plan_name',
    'employee_dependent_count',
    'errors',
    'hire_date',
    'coverage_termination_date',
    'coverage_effective_date',
    'cobra_effective_date',
    'cobra_termination_date',
    'processed_timestamp',
    'transaction_date',
    'date_of_birth',
    'first_name',
    'last_name',
    'employee_ssn',
    'dependent_ssn',
    'previous_ssn',
    'home_address_1',
    'home_address_2',
    'personal_phones',
    'phone_2',
    'work_email',
    'plan_year',
    'sent_email_count',
    'error_details',
    'flipt_person_id',
    'source_file_name',
    'warnings',
    'action',
    'medicare_eligible',
    'medicare_disenrollment_reason_code',
    'medicare_hic_number',
    'medicare_part_d_begin_date',
    'medicare_part_d_end_date',
    'medicare_part_c_begin_date',
    'medicare_part_c_end_date',
    'group_reporting_field',
    'location',
    'tpa_member_id',
    'tpa_person_code',
    'language',
    'option_id',
    'option_code_description',
    'company_code',
    'business_unit',
    'eligibility_file_received_date',
    'eligibility_file_date',
    'medicare_part_b_begin_date',
    'medicare_part_b_end_date',
    'country',
    'reporting_field_5b',
    'retiree_plan_change_flag',
    'medical_group_number',
    'medicare_contract_id',
    'medicare_benefit_id',
    'plan_benefit_package_id',
    'lics',
    'coverage_begin_date',
    'data_element_251_change_indicator',
    'coverage_change_effective_date',
    'data_element_1501_change_indicator',
    'citizenship_status_code',
    'language_code',
    'middle_name',
    'name_suffix',
    'work_email_1',
    'work_email_2',
    'mailing_address_2',
    'mailing_city',
    'mailing_state',
    'mailing_zip',
    'subscriber_id',
    'ins01_member_indicator',
    'benefit_status_code',
    'medicare_status_code',
    'medicare_plan_code',
    'disabled_dependent_over_26',
    'date_of_death_date_time_format_qualifier',
    'date_of_death',
    'custodial_person',
    'responsible_persons',
    'disability',
    'providers',
    'cob',
    'termination_date',
    'ncpdp_pharmacy_number',
    'medicare_begin_date',
    'medicare_end_date',
    'loop_2300_348_benefit_begin_date',
    'loop_2300_349_benefit_end_date',
    'loop_2300_356_coverage_effective_date',
    'loop_2300_357_coverage_termination_date',
    'credited_service_begin_date',
    'credited_service_end_date',
    'medicaid_begin_date',
    'medicaid_end_date',
    'loop_2000_348_benefit_begin_date',
    'loop_2000_349_benefit_end_date',
    'loop_2000_356_coverage_effective_date',
    'loop_2000_357_coverage_termination_date',
    'esrd',
    'origcvg',
    'client_number'
]