import React, { Component, useEffect, useState, useReducer } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment-timezone'

import BreadCrumb from '../../../components/breadCrumb'
import { Creators as AppActions } from '../../../redux/reducers/app'
import { Creators as ClaimsPRPActions } from '../../../redux/reducers/api/claimsProcessingAndReprocessing'
import { Creators as IntegrationManagementActions } from '../../../redux/reducers/api/integrationManagement';

import './styles.scss'
import FliptWizard from '../../../components/v2/fliptWizard'
import DefineBatchScreen from './defineBatchScreen'
import ClaimConfig from './claimConfig'
import ClaimsProcessingAndReprocessingReview from './review'
import FliptButton from '../../../components/v2/fliptButton'
import { optionalClaimFields, requiredClaimFields } from './claimConfig/data/uiFields'
import { TEST_BATCH_TAB_ID } from '../claimProcessingReprocessing/data/dropdownData'

function ClaimsProcessingAndReprocessing(props) {
	const { state, history } = props
	const { claimsProcessingAndReprocessing, isMemberGenerated } = state
	const { testBatchClaims, testBatchDetails } = claimsProcessingAndReprocessing
	const flatRequiredClaimFields = requiredClaimFields.flat()
	const flatOptionalClaimFields = optionalClaimFields.flat()
	
	useEffect(() => {
		setTestBatchConfig(createTestBatchDetailsForm())
	}, [testBatchDetails])

	useEffect(() => {
		setClaimForm(initiateState())
	}, [testBatchClaims])

	useEffect(() => {
        const { history, actions } = props
        actions.clearTestBatchDetails()
		actions.clearTestBatchClaims()
		actions.setIsMemberGenerated(false)
        const payload = history.location.state;
		if(payload.batch_id) {
        	actions.getTestBatch(payload)
		}
		setTestBatchConfig(createTestBatchDetailsForm())
	}, [])

	const initiateState = () => {
		const fieldObj = {}
		if(testBatchClaims?.length > 0) {
			fieldObj['gridData'] = testBatchClaims
		} else {
			fieldObj['gridData'] = []
		}
		flatRequiredClaimFields.forEach((fieldData) => {
			fieldObj[fieldData.name] = fieldData.value
		})
		flatOptionalClaimFields.map((fieldData) => {
			fieldObj[fieldData.name] = fieldData.value
		})

		fieldObj['drugData'] = null
		fieldObj['prescriberData'] = null
		fieldObj['pharmacyData'] = null
		fieldObj['memberData'] = []
		fieldObj['prevAddMemberData'] = {}
		fieldObj['memberGenerated'] = false
		fieldObj['create_type'] = 'manual'
		return fieldObj
	}

	const testBatchConfigInitialState = {
		batch_name: "",
		batch_id: "",
		umObjects: {
			tiers: { value: [], obj: [] },
			programs: { value: [], obj: [] },
			clinical_pa: { value: [], obj: [] },
			clinical_ql: { value: [], obj: [] },
			clinical_step_therapy: { value: [], obj: [] },
		},
		plans: [],
		hierarchy: [],
		hierarchyIDs: [],
		hierarchyIDsWithChildren: [],
		pharmacyNetworks: {
			pharmacy_networks: { value: [], obj: [] },
			pharmacy_network_tiers: { value: [], obj: [] },
			pharmacy_network_edits: { value: [], obj: [] },
		},
		hierarchyDropdown: {},
		configType: 0,
	}

	const createTestBatchDetailsForm = () => {
		const { actions } = props
		if(testBatchDetails?.batch_id) {
			const configType = parseConfigType(testBatchDetails.batch_type)
			actions.getUmObjects({ cag: testBatchDetails.hierarchyIDsWithChildren, status: ['TEST_READY'] })
			const extractedValues = {
				tiers: testBatchDetails.formulary_tier.map(item => item.doc_name),
				programs: testBatchDetails.programs.map(item => item.doc_name),
				clinical_pa: testBatchDetails.prior_authorization.map(item => item.doc_name),
				clinical_ql: testBatchDetails.quantity_limit.map(item => item.doc_name),
				clinical_step_therapy: testBatchDetails.step_therapy.map(item => item.doc_name),
			}
			actions.getTestBatchConfigPlans({
				type: testBatchDetails.batch_type,
				form: {
					...extractedValues,
					hierarchy: testBatchDetails.hierarchyKeys,
					status: ["Published", "TEST_READY"]
				}
			})
			return {
				batch_name: testBatchDetails.batch_name,
				batch_id: testBatchDetails.batch_id,
				umObjects: {
					tiers: { value: extractedValues.tiers, obj: testBatchDetails.formulary_tier },
					programs: { value: extractedValues.programs, obj: testBatchDetails.programs },
					clinical_pa: { value: extractedValues.clinical_pa, obj: testBatchDetails.prior_authorization },
					clinical_ql: { value: extractedValues.clinical_ql, obj: testBatchDetails.quantity_limit },
					clinical_step_therapy: { value: extractedValues.clinical_step_therapy, obj: testBatchDetails.step_therapy },
				},
				plans: testBatchDetails.benefit_plan,
				hierarchy: testBatchDetails.hierarchyKeys,
				pharmacyNetworks: {
					pharmacy_networks: { value: testBatchDetails.pharmacy_network.map(item => item.doc_name), obj: testBatchDetails.pharmacy_network },
					pharmacy_network_tiers: { value: testBatchDetails.pharmacy_network_tiers.map(item => item.doc_name), obj: testBatchDetails.pharmacy_network_tiers },
					pharmacy_network_edits: { value: testBatchDetails.pharmacy_network_edits.map(item => item.doc_name), obj: testBatchDetails.pharmacy_network_edits },
				},
				hierarchyDropdown: testBatchDetails.hierarchyKeys,
				hierarchyIDs: testBatchDetails.hierarchyIDs,
				hierarchyIDsWithChildren: testBatchDetails.hierarchyIDsWithChildren,
				hierarchy: testBatchDetails.active_hierarchy,
				name: "testBatchConfig",
				configType: configType,
			}
			
		} else {
			return testBatchConfigInitialState
		}
	}

	const [claimForm, setClaimForm] = useState(initiateState())
	const [testBatchConfig, setTestBatchConfig] = useState({})
	const [claimsProcessingStep, setClaimsProcessingStep] = useState(0)
	const _renderClaimProcessingType = (props) => {
		switch (claimsProcessingStep) {
			case 0:
				return <DefineBatchScreen {...props} editable={true} setTestBatchConfig={setTestBatchConfig} testBatchConfig={testBatchConfig} />
			case 1:
				return <ClaimConfig {...props} setStep={setStep} editable={true} setClaimForm={setClaimForm} claimForm={claimForm} testBatchConfig={testBatchConfig} />
			case 2:
				return <ClaimsProcessingAndReprocessingReview gridData={claimForm.gridData} setStep={setStep} setClaimForm={setClaimForm} claimForm={claimForm} setTestBatchConfig={setTestBatchConfig} testBatchConfig={testBatchConfig}  {...props} />
			default:
				return <></>
		}
	}

	const setStep = (step) => {
		setClaimsProcessingStep(step)
	}

	const goBack = () => {
		const { actions } = props
		if (claimsProcessingStep === 0) history.goBack()
		else {
			const finalTestBatch = buildClaim('DRAFT')
			actions.submitTestBatch(finalTestBatch, () => {setStep(claimsProcessingStep - 1)})
		} 

	}

	const submitBatchCallback = (isExit = false) => {
		if (claimsProcessingStep === 2 || isExit)
			history.push({ pathname: '/claim-processing-reprocessing-dashboard', state: { tab_id: TEST_BATCH_TAB_ID } })
		else if (claimsProcessingStep < 2) {
			setStep(claimsProcessingStep + 1)
		}
	}

	const parseConfigType = (configType) => {
		if(typeof configType === 'string') {
			switch (configType) {
				case 'um':
					return 0
				case 'benefit_plan':
					return 1
				case 'pharmacy_network':
					return 2
				default:
					return 0
			}
		} else {
			switch (configType) {
				case 0:
					return 'um'
				case 1:
					return 'benefit_plan'
				case 2:
					return 'pharmacy_network'
				default:
					return 'um'
			}
		}
	}
	const buildClaim = (claimStatus) => {
		const { state: { user } } = props
		const test_batch_claims = claimForm.gridData.map((claim) => {
			return {
				...claim,
				date_of_birth: moment(claim.date_of_birth).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
				date_of_service: moment(claim.date_of_service).format('YYYY-MM-DD HH:mm:ss'),
				date_prescription_written: moment(claim.date_prescription_written).format('YYYY-MM-DD HH:mm:ss'),
				create_date: moment().format('YYYY-MM-DD HH:mm:ss'),
			}
		})
		const finalTestBatch = {
			"test_batch_details": {
				"batch_name": testBatchConfig.batch_name,
				"batch_id": testBatchConfig.batch_id,
				"batch_type": parseConfigType(testBatchConfig.configType),
				"benefit_plan": testBatchConfig.plans,
				"create_date": moment().format('YYYY-MM-DD HH:mm:ss'),
				"created_by": `${user?.first_name} ${user?.last_name}`,
				"formulary_tier": testBatchConfig.umObjects.tiers.obj,
				"formulary": [],
				"hierarchyKeys": testBatchConfig.hierarchyDropdown,
				"prior_authorization": testBatchConfig.umObjects.clinical_pa.obj,
				"programs": testBatchConfig.umObjects.programs.obj,
				"quantity_limit": testBatchConfig.umObjects.clinical_ql.obj,
				"status": claimStatus,
				"step_therapy": testBatchConfig.umObjects.clinical_step_therapy.obj,
				"pharmacy_network": testBatchConfig.pharmacyNetworks.pharmacy_networks.obj,
				"pharmacy_network_tiers": testBatchConfig.pharmacyNetworks.pharmacy_network_tiers.obj,
				"pharmacy_network_edits": testBatchConfig.pharmacyNetworks.pharmacy_network_edits.obj,
				"hierarchyIDs": testBatchConfig.hierarchyIDs,
				"hierarchyIDsWithChildren": testBatchConfig.hierarchyIDsWithChildren,
			},
			test_batch_claims,
		}
		return finalTestBatch
	}

	const createWarningMessage = () => {
		const { actions } = props
		actions.setModalComponent({
			modalProperties: { size: 'large', scrolling: true },
			contents: <WarningModal actions={actions} />
		});
		actions.toggleModal();
	}
	const saveAndExit = () => {
		const { actions } = props
		const finalTestBatch = buildClaim('DRAFT')
		actions.submitTestBatch(finalTestBatch, () => submitBatchCallback(true))
	}

	const createClaim = () => {
		// if any other step submit the test batch with the non final state
		const { actions, state: { user } } = props
		if (claimsProcessingStep === 2) {
			const finalTestBatch = buildClaim('SUBMITTED')
			actions.submitTestBatch(finalTestBatch, submitBatchCallback)
		} else if (claimsProcessingStep === 0) {
			createWarningMessage()	
		} else {
			const finalTestBatch = buildClaim('DRAFT')
			actions.submitTestBatch(finalTestBatch, submitBatchCallback)
		}
	}

	const isButtonDisabled = () => {
		return ((claimsProcessingStep === 1 && !claimForm.gridData?.length) ||
			(claimsProcessingStep === 0 && !testBatchConfig.plans?.length) ||
			(claimsProcessingStep === 1 && !isMemberGenerated))
	}

	const WarningModal = () => {
		const { actions } = props
		return (
			<div className="modal">
				<div className="modal-header">
					<span className="modal-header-text">Warning</span>
				</div>
				<div className="modal-content">
					<span>
						Clicking continue will only generate test cases and members for selected plans. If you would like to add
						more plans in the future, you will have to return to this page and select additional plans to regenerate
						Test Cases and members.
					</span>
					<div className="secondary-row">
						<FliptButton className="primary" name="Continue" onClick={() => {
							const finalTestBatch = buildClaim('DRAFT')
							actions.submitTestBatch(finalTestBatch, submitBatchCallback)
							actions.toggleModal()
						}} />
					</div>
				</div>
			</div>
		)
	}

	return (
		<div id="claimsProcessingAndReprocessing">
			<BreadCrumb {...props} />

			<div className="mainHeader">
				<FliptWizard
					wizardValues={['Define Batch', 'Define Claims', 'Review']}
					setStep={setStep}
					step={claimsProcessingStep}
				/>
			</div>
			<div className='header-container'>
				<span className='header'>Create Claim Testing Batch</span>
			</div>
			{_renderClaimProcessingType({ ...props })}
			<div className='button-container'>
				<FliptButton className='primary' name='Back' onClick={goBack} />
				<div className='sub-container'>
					<FliptButton className='primary' disabled={isButtonDisabled()} onClick={saveAndExit} name="Save and Exit" />
					<FliptButton className='primary' disabled={isButtonDisabled()} onClick={createClaim} name={claimsProcessingStep === 0 ? 'Create Test Claims' : claimsProcessingStep === 1 ? 'Preview' : 'Submit for Processing'} />

				</div>
			</div>
		</div>
	)
}

const mapStateToProps = (state) => ({
	state: {
		user: state.user,
		claimsProcessingAndReprocessing: state.claimsProcessingAndReprocessing,
		isMemberGenerated: state.integrationMgmt.isMemberGenerated,
	},
})

const mapDispatchToProps = (dispatch) => {
	const allActions = {
		...AppActions,
		...ClaimsPRPActions,
		...IntegrationManagementActions,
	}

	return {
		actions: bindActionCreators(allActions, dispatch),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ClaimsProcessingAndReprocessing)
