import React, { useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Creators as AppActions } from '../../../../redux/reducers/app'
import { Creators as ClaimsPRPActions } from '../../../../redux/reducers/api/claimsProcessingAndReprocessing'
import './styles.scss'
import FliptHierarchyDropdown from '../../../../components/v2/fliptHierarchyDropdown'
import ConfigPicker from '../components/configPicker'
import FliptDropdown from '../../../../components/v2/fliptDropdown'
import TestUtilizationManagement from './testUtilizationManagement'
import BenefitPlans from './benefitPlans'
import FliptButton from '../../../../components/v2/fliptButton'
import FliptInput from '../../../../components/v2/fliptInput'
import PharmacyNetworks from './pharmacyNetworks'

function DefineBatchScreen(props) {
	const { actions, setTestBatchConfig, testBatchConfig } = props

	const handleTestBatchConfigurationPicker = (val) => {
		setTestBatchConfig((prevForm) => ({
			...prevForm,
			configType: val,
		}))
		actions.clearPlans()
		actions.clearPharmacyNetworks()
	}

	const renderTestBatchConfiguration = () => {
		switch (testBatchConfig.configType) {
			case 0:
				return (
					<TestUtilizationManagement
						editable={props.editable}
						setTestBatchConfig={setTestBatchConfig}
						testBatchConfig={testBatchConfig}
					/>
				)
			case 1:
				return (
					<BenefitPlans
						editable={props.editable}
						setTestBatchConfig={setTestBatchConfig}
						testBatchConfig={testBatchConfig}
					/>
				)
			case 2:
				return (
					<PharmacyNetworks
						editable={props.editable}
						setTestBatchConfig={setTestBatchConfig}
						testBatchConfig={testBatchConfig}
					/>
				)
			default:
				return <></>
		}
	}
	const configData = [{
		label: 'Formulary/Utilization Management Object',
		disabled: false
	}, {
		label: 'Benefits',
		disabled: false
	}, {
		label: 'Pharmacy Networks',
		disabled: false
	}]

	return (
		<div id="defineBatchScreen">
			<div className="mainSections">
				<div className="section">
					<h2>Define Batch Name</h2>
					<FliptInput
						name="batchName"
						value={testBatchConfig.batch_name}
						onChange={(e) => {
							setTestBatchConfig((prevForm) => ({
								...prevForm,
								batch_name: e.target.value,
							}))
						}}
						placeholder="Enter"
						disabled={!props.editable}
					/>
				</div>
				<div className="section">
					<h2>Define Hierarchies For Testing</h2>
					<FliptHierarchyDropdown disabled={!props.editable} setForm={setTestBatchConfig} form={testBatchConfig} />
				</div>
				<div className="section">
					<h2>Define Test Batch Configuration</h2>
					<span className="imp">What type of testing is being completed?</span>
					<ConfigPicker
						currentValue={testBatchConfig.configType}
						configValues={configData}
						handleChange={handleTestBatchConfigurationPicker}
						disabled={!props.editable}
					/>
				</div>
				{renderTestBatchConfiguration()}
			</div>
		</div>
	)
}

const mapStateToProps = (state) => ({
	state: {
		user: state.user,
	},
})

const mapDispatchToProps = (dispatch) => {
	const allActions = {
		...AppActions,
		...ClaimsPRPActions,
	}

	return {
		actions: bindActionCreators(allActions, dispatch),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(DefineBatchScreen)
