/* group - grid */
export const groupHeader = [
  "group_name",
  "group_id",
  "group_effective_date",
  "group_termination_date",
];

/* plan details - grid */
export const planDetailsHeader = [
  "plan_description",
  "plan_id",
  "plan_effective_date",
  "plan_termination_date",
];

/* accumulation - grid */
export const accumulationHeader = [
  "benefit_effective_start_date",
  "benefit_effective_end_date",
  'benefit_period_type',
  'type_of_oop',
  "deductible_oop_logic",
  "deductible_satisfaction",
  "individual_deductible",
  "family_deductible",
  "oop_satisfaction",
  "individual_oop",
  "family_oop",
];
