export default [
    {
      program_qualifier: 'MME per Unit',
      program_operator: '=',
      program_value: '',
    },
    {
      program_qualifier: 'Long Acting/Immediate Release',
      program_operator: '=',
      program_value: '',
    },
    {
      program_qualifier: 'PA Absolute/Conditional',
      program_operator: '=',
      program_value: '',
    },
  ]