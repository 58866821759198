import React, { forwardRef } from 'react'

import FliptLabel from './fliptLabel'

import * as fieldValidators from '../../utils/validationHelpers'
import './styles.scss'

const validators = {
  code: fieldValidators.validateCode,
  email_address: fieldValidators.validateEmailAddress,
  password: fieldValidators.validatePassword,
  phone: fieldValidators.validatePhoneNumber,
  numeric: fieldValidators.validateNumericField,
}

const FliptTextarea = forwardRef((props = {}, ref) => {
  const {
    className, description, label, required, stylized, validation, onChange, isPa
  } = props

  const validateField = (el) => {
    const ct = el.currentTarget

    if (required && !ct.value) {
      ct.classList.add('has-error')
    } else {
      ct.classList.remove('has-error')
    }

    if (validation && validators[validation]) {
      if (!validators[validation](ct.value)) {
        ct.classList.add('has-error')
      } else {
        ct.classList.remove('has-error')
      }
    }
  }

  const _onChange = (el) => {
    const ct = el.currentTarget
    if (validation && validators[validation]) {
      if (validators[validation](ct.value)) {
        onChange(el)
      }
    } else {
      onChange(el)
    }
  }
  const inputProps = { ...props }

  if (inputProps.disabled) {
    inputProps.value = inputProps.value || ''
  }

  // inputProps.default = inputProps.defaultValue || ''

  const stylizedField = () => (
    <div className={isPa ? 'pa-stylized flipt-textarea' : `stylized flipt-textarea ${className}`}>
      {isPa ? <div className="flipt-pa-label" hidden={inputProps.hidden || false}>
        <label htmlFor={label}>{label}</label>
      </div> : <div className="flipt-label" hidden={inputProps.hidden || false}>
        <FliptLabel
          description={description}
          label={label}
        />
      </div>}

      <textarea {...inputProps} onKeyUp={validateField} onChange={_onChange} />
    </div>
  )

  if (stylized) return stylizedField()

  return <textarea {...inputProps} onKeyUp={validateField} ref={ref} />
})

export default FliptTextarea
