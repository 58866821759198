import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './styles.scss';
import FliptGrid from '../../../../../components/fliptGrid';
import FliptButton from '../../../../../components/v2/fliptButton';
import moment from 'moment-timezone';
import { InputTextEditor } from '../../../../../components/fliptGrid/cellEditors';
import { Icon } from 'semantic-ui-react'
import { DeleteRowRenderer, LinkRenderer } from '../../../../../components/fliptGrid/cellRenderers';
import { Creators as AppActions } from '../../../../../redux/reducers/app';
import { Creators as IntegrationManagementActions } from '../../../../../redux/reducers/api/integrationManagement';
import { drugFields, gridHeaders, optionalClaimFields, pharmacyFields, prescriberFields, radioFields, requiredClaimFields } from '../../claimConfig/data/uiFields';
import EligibilityConfig from '../../claimConfig/eligibilityConfig';

const flatRequiredClaimFields = requiredClaimFields.flat();
const flatOptionalClaimFields = optionalClaimFields.flat();

const ClaimGrid = (props) => {
    const { actions, state: { plansData, isMemberGenerated }, setClaimForm, claimForm, editable, testBatchConfig } = props;
    const form = { ...claimForm };
    const { memberData, gridData } = claimForm;

    const addMemberCallback = (addMemberData, memberData) => {
        const newForm = { ...form }
        const tempGridData = []
        gridData.forEach((claimData) => {
            testBatchConfig.plans.forEach((plan) => {
                // if(memberData.length === 0) {
                //     tempGridData.push(claimData)
                //     return
                // }
                const memberObj = memberData.find((o) => o.member_id === claimData.member_id)
                const obj = { ...claimData }
                obj.member_id = addMemberData[claimData.member_id] && addMemberData[claimData.member_id][plan.plan_name]
                obj.cardholder_id = obj.member_id
                obj.cardholder_first_name = memberObj.first_name
                obj.cardholder_last_name = memberObj.last_name
                obj.patient_first_name = memberObj.first_name
                obj.patient_last_name = memberObj.last_name
                obj.date_of_birth = memberObj.date_of_birth
                obj.group_id = memberObj.group
                obj.person_code = '01'
                obj['plan_name'] = plan.plan_name
                tempGridData.push(obj)
            });
        })
        newForm.gridData = tempGridData
        setClaimForm(newForm)
    }

    const _showTransitionalPortal = (header, message) => {
        const transitionalPortal = {
            header: header,
            copy: message,
        };
        actions.displayTransitionalPortal(transitionalPortal);
    };

    const headers = () => {
        const headerData = [...gridHeaders];
        if (!editable) headerData.shift();
        const claimFields = [...flatRequiredClaimFields, ...flatOptionalClaimFields];
        const renderParams = {};
        claimFields.forEach((data) => {
            headerData.push(data.name);
            renderParams[data.name] = {};
            renderParams[data.name].overrideHeader = data.label;
        });
        cellRendererParams = { ...cellRendererParams, ...renderParams };
        return headerData;
    };

    const setModalData = (name, value) => {
        const newForm = {
            ...form,
            [name]: value,
        };
        setClaimForm(newForm);
    };

    const deleteClaim = (rowIndex) => {
        const tempGridData = [...gridData];
        tempGridData.splice(rowIndex, 1);
        const newForm = {
            ...form,
            gridData: tempGridData,
        };
        if (tempGridData.length === 0) actions.setIsMemberGenerated(false);
        setClaimForm(newForm);
    };

    const handleChange = (el, dropdown, rowIndex) => {
        const { name, value } = dropdown || el.currentTarget;
        const tempGridData = [...gridData];
        tempGridData[rowIndex][name] = value;
        setModalData('gridData', tempGridData);
    };

    let cellEditorParams = {
        member_id: {
            cellEditor: InputTextEditor,
            onChange: handleChange,
        },
    };
    let cellRendererParams = {
        action: {
            cellRenderer: DeleteRowRenderer,
            state: {
                onClick: deleteClaim,
            },
            width: 95,
        },
        test_batch_claim_id: {
            hide: true,
        },
    };

    const handleMemberIdClicked = ({ data }) => {
        const selectedDataIndex = memberData.findIndex((memberData) => memberData.patient_first_name === data?.first_name);
        if (memberData[selectedDataIndex]) {
            actions.setModalComponent({
                modalProperties: { size: 'large', scrolling: true },
                contents: <EligibilityConfig memberInfo={memberData[selectedDataIndex]} />,
            });
            actions.toggleModal();
        }
    };

    if (isMemberGenerated) {
        cellEditorParams = {};
        cellRendererParams.member_id = {
            ...cellRendererParams.member_id,
            cellRenderer: LinkRenderer,
            onCellClicked: handleMemberIdClicked,
            disabled: !editable,
            searchArgs: {},
        };

    }

    const _generateMember = () => {
        if (!gridData?.length) {
            _showTransitionalPortal('Field Error', 'Please add Claims to Generate Members.');
            return;
        }
        if (!testBatchConfig.plans.length) {
            _showTransitionalPortal('Field Error', 'Please Add Plans');
            return;
        }
        const selectedPlanNames = testBatchConfig.plans.map((plan) => plan.plan_name);
        const selectedPlans = [];
        plansData.forEach((planData) => {
            if (selectedPlanNames.includes(planData.plan_name)) {
                selectedPlans.push({
                    ...planData,
                    display_plan_name: planData.plan_name,
                });
            }
        });

        const uniqueMemberIds = [...new Set(gridData.map((item) => item.member_id))];
        let uniqueMemberIdData = [];
        // TODO: users should not be created based on plan CAG. because plan CAG will be removed.
        uniqueMemberIds.forEach((memberId, index) => {
            const membersForPlan = selectedPlans?.map((planData) => {
                const obj = {
                    ...planData,
                    employee_id: 'BATCHEMPLOYEE' + (index + 1),
                    first_name: 'TEST_FIRST_NAME_' + (index + 1),
                    last_name: 'TEST_LAST_NAME_' + (index + 1),
                    type: 'employee',
                    email: 'TEST_EMAIL_' + (index + 1) + '@TEST.COM',
                    phone: '555-555-5555',
                    date_of_birth: moment().subtract(32, 'years').startOf('day').toDate(),
                    gender: 'M',
                    address_1: '123 Main Street',
                    address_2: '',
                    city: '',
                    state: 'CA',
                    zip_code: '90021',
                    ssn: '123456789',
                    medicare_id: 'A1234567890',
                    tpa_member_id: Math.floor(100000000 + Math.random() * 900000000).toString(),
                    member_id: memberId,
                    effective_start_date: moment().subtract(1, 'days').startOf('day').toDate(),
                    effective_end_date: moment().add(365, 'days').endOf('day').toDate(),
                    lics: '',
                    esrd: '',
                    hospice: '',
                    transition_eligible: '',
                    paid_to_date: '',
                };
                return obj;
            });
            uniqueMemberIdData = uniqueMemberIdData.concat(membersForPlan);
        });

        const tempGridData = [...gridData];
        const newForm = {
            ...form,
            memberData: uniqueMemberIdData,
            gridData: tempGridData,
        };
        setClaimForm(newForm);
        const payload = uniqueMemberIdData.map((memberData) => {
            return {
                ...memberData,
                date_of_birth: moment(memberData.date_of_birth).format('YYYY-MM-DD HH:mm:ss'),
                effective_start_date: moment(memberData.effective_start_date).format('YYYY-MM-DD HH:mm:ss'),
                effective_end_date: moment(memberData.effective_end_date).format('YYYY-MM-DD HH:mm:ss'),
            };
        });
        actions.addMember({ data: payload }, addMemberCallback);
    };
    const generateErrorMessage = () => {
        let selectedPlans = testBatchConfig.plans
        let amountOfClaims = gridData.length
        let uniquePlans = new Set()
    
        gridData.forEach((claimData) => {
            uniquePlans.add(claimData.plan_name)
        })
    
        let amountOfUniquePlans = uniquePlans.size
    
        if (amountOfUniquePlans < selectedPlans.length) {
            let neededPlans = selectedPlans.filter(plan => !uniquePlans.has(plan.plan_name))
            let neededPlanNames = neededPlans.map(plan => plan.plan_name).join(', ')
            return 'Please Generate Members for plans: ' + neededPlanNames
        }
        return ''
    }
    let errorMessage = generateErrorMessage()
    return (
        <div id="claim-processing-grid">
            <FliptGrid
                headers={headers()}
                data={gridData}
                cellEditorParams={cellEditorParams}
                cellRendererParams={cellRendererParams}
            />
            {
                errorMessage != "" ? (
                    <div className="not-found-container">
                        <Icon name="exclamation triangle" color="red" />
                        <span className="not-found-text">{errorMessage}</span>
                    </div>
                ) : null
            }
            {!isMemberGenerated && gridData.length ? (
                <FliptButton className="primary generate-member" name="Generate Member" onClick={_generateMember} />
            ) : null}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        state: {
            app: state.app,
            plansData: state.claimsProcessingAndReprocessing?.plans || [],
            addMemberData: state.integrationMgmt.addMemberData,
            isMemberGenerated: state.integrationMgmt.isMemberGenerated,
        },
    };
};

const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...AppActions,
        ...IntegrationManagementActions,
    };

    return {
        actions: bindActionCreators(allActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClaimGrid);
