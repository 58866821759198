import React, { useState, useMemo } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Accordion, Button, Icon } from 'semantic-ui-react'
import _ from "lodash"

import { Creators as AppActions } from '../../../../../redux/reducers/app'
import { Creators as ProgramListCreationActions } from "../../../../../redux/reducers/api/programListManagement"

import '../styles.scss'

import { qualifierType, qualifierOperator } from '../data/qualifier'
import rowCellInfo from '../data/rowCellInfo'
import qualifierRowCellInfo from '../data/qualifierRowCellInfo'
import FliptGrid from '../../../../../components/fliptGrid'
import FliptInput from '../../../../../components/form/fliptInput'
import FliptDatePicker from '../../../../../components/form/fliptDatePicker'
import FliptButton from '../../../../../components/form/fliptButton'
import FliptDropdown from '../../../../../components/form/fliptDropdown'
import FliptHierarchyDropdown from '../../../../../components/form/fliptHierarchyDropdown'
import AddRow from '../addRow'
import { ButtonRenderer, DeleteRowRenderer, LevelCheckboxRenderer } from '../../../../../components/fliptGrid/cellRenderers'
import { InputTextEditor, DropdownEditor, SearchEditor, DatePickerEditor } from '../../../../../components/fliptGrid/cellEditors'
import { filterRowData, addRemoveDaysToDate, convertStrToDateObj, convertDateToStrObj, buildDropdownOptions } from '../../../../../utils/utilities'
import Qualifiers from '../../../../../components/Qualifiers';
import moment from 'moment'
import * as ApprovalsConstants from '../../../../../redux/sagas/rpm/constants'
import { deleteUnnecessaryFields } from '../../../../../utils/utilizationManagement'


function ProgramConditions(props) {
  const { DRAFT, PUBLISHED, REJECTED } = ApprovalsConstants
  const {
    state, form, setForm, programConditionLevels, updateFields, activeProgramConditionLevel, editMode, showQualifier,
    handleQualifierChange, handleChange, dropdownOptions, autoSuggestionValues, programQualifierLevels, rbpProgramQualifierLevels,
    rbpQualifierToUpdate, autoSuggestionResultSelect, autoSuggestionMinChars, listTypeOptions, customProgramTypeOptions,
    addNewProgramConditionLevel, handleProgramConditionLevelClick, deleteProgramConditionLevel, addProgramCondition, delProgramCondition,
    /* addQualifierRow, */ delQualifierRow, saveProgramList, sendForReview, doc_id, cellRenderers,
    qualifierDropdownOptions, criteriaIds, exportConditionDrugs, onCheckboxChange
  } = props

  const [showGenerateDrugsGrid, setShowGenerateDrugsGrid] = useState(Array(programConditionLevels).fill(false))
  const [currentGenerateDrugCondition, setCurrentGenerateDrugCondition] = useState({})
  const [gridKey, setGridKey] = useState('')

  const {
    doc_name, effective_start_date, list_type, custom_program_type, version, status, version_effective_date,
    target_system_formulary_tier, target_system_formulary_status
  } = form

  const { multiSourceOptions, brandGenericOptions, otcIndicatorOptions, DESIOptions,
    routeOfAdministrationOptions, drugIndicatorOptions, dosageFormOptions,
    thirdPartyRestrictionOptions, deaClassCodeOptions, storageConditionCodeOptions,
    marketingCategoryOptions, applicationTypeOptions } = state.conditionsDropDownOptions

  rowCellInfo.brand_generic.options = brandGenericOptions
  rowCellInfo.desi_code.options = DESIOptions
  rowCellInfo.dosage_form_cd.options = dosageFormOptions
  rowCellInfo.maintenance_drug_code.options = drugIndicatorOptions
  rowCellInfo.multi_source.options = multiSourceOptions
  rowCellInfo.otc_indicator.options = otcIndicatorOptions
  rowCellInfo.repackaged_code.options = [{ key: "Y", value: "Y", text: "Repackaged" }, { key: "N", value: "N", text: "Not Repackaged" }]
  rowCellInfo.route_of_administration.options = routeOfAdministrationOptions
  rowCellInfo.third_party_restriction_code.options = thirdPartyRestrictionOptions
  rowCellInfo.dea_class_code.options = deaClassCodeOptions
  rowCellInfo.clinic_pack_code.options = [{ key: "Y", value: "Y", text: "Y" }, { key: "N", value: "N", text: "N" }]
  rowCellInfo.innerpack_code.options = [{ key: "Y", value: "Y", text: "Y" }, { key: "N", value: "N", text: "N" }]
  rowCellInfo.unit_dose_with_non_unit_dose_non_repackager_in_gpi.options = [{ key: "Y", value: "Y", text: "Y" }, { key: "N", value: "N", text: "N" }]
  rowCellInfo.rx_with_otc_in_gpi.options = [{ key: "Y", value: "Y", text: "Y" }, { key: "N", value: "N", text: "N" }]
  rowCellInfo.storage_condition_code.options = storageConditionCodeOptions
  rowCellInfo.marketing_category.options = marketingCategoryOptions
  rowCellInfo.application_type_flag.options = applicationTypeOptions
  rowCellInfo.item_status_flag.options = buildDropdownOptions(["A", "I"])
  rowCellInfo.unit_dose.options = [{ key: "", value: "", text: "" }, { key: "U", value: "U", text: "U" }, { key: "X", value: "X", text: "X" }]
  rowCellInfo.fda_therapeutic_equivalence_code.options = buildDropdownOptions(["AA",
    "AB",
    "AB1",
    "AB1,AB2",
    "AB1,AB2,AB3",
    "AB1,AB2,AB3,AB4",
    "AB1,AB3",
    "AB2",
    "AB3",
    "AB4",
    "AN",
    "AO",
    "AP",
    "AP1",
    "AP2",
    "AT",
    "AT1",
    "AT2",
    "AT3",
    "BC",
    "BD",
    "BP",
    "BS",
    "BX"])

  if (editMode) {
    qualifierRowCellInfo.action = {
      type: 'deleteRow',
      disabled: false,
    }
    Object.keys(qualifierRowCellInfo).forEach((key) => { qualifierRowCellInfo[key].disabled = false })

    rowCellInfo.action = {
      type: 'deleteRow',
      disabled: false,
    }
    Object.keys(rowCellInfo).forEach((key) => { rowCellInfo[key].disabled = false })
  } else {
    delete qualifierRowCellInfo.action
    Object.keys(qualifierRowCellInfo).forEach((key) => { qualifierRowCellInfo[key].disabled = true })

    delete rowCellInfo.action
    Object.keys(rowCellInfo).forEach((key) => { rowCellInfo[key].disabled = true })
  }
  let qualifierHeaders = Object.keys(qualifierRowCellInfo)
  if (qualifierHeaders.includes('action')) {
    qualifierHeaders.splice(qualifierHeaders.indexOf('action'), 1)
    qualifierHeaders.unshift('action')
  }

  const headers = Object.keys(rowCellInfo)
  if (headers.includes('action')) {
    headers.splice(headers.indexOf('action'), 1)
    headers.unshift('action')
  }

  const gridHeaders = [
    'gpi', 'drug_group', 'drug_class', 'drug_subclass', 'manufacturer', 'drug_name', 'multi_source',
    'ndc', 'ddid', 'brand_generic', 'otc_indicator', 'route_of_administration', 'desi_code',
    'maintenance_drug_code', 'effective_start_date', 'effective_end_date', 'alternate_gpi_match',
    'alternate_ndc_match', 'application_type_flag', 'mfg_labeler_id', 'repackaged_code',
    'third_party_restriction_code', 'dosage_form_cd'
  ]

  const headerMapping = {
    Alternate_GPI_Match: "alternate_gpi_match",
    Alternate_NDC_Match: "alternate_ndc_match"
  }

  const minProgramStartDate = addRemoveDaysToDate(1, false)

  const handleGenerateDrugsClick = (data, submitType, rowIndex) => {
    // reusing the same object reference and not using setState for currentGenerateDrugCondition
    // is intentional. it is necessary for FliptGrid to function correctly
    Object.assign(currentGenerateDrugCondition, { ...data, include_inactive_drugs: form.include_inactive_drugs })

    const tempObj = deleteUnnecessaryFields(currentGenerateDrugCondition)
    const randomNumber = Math.floor(Math.random() * 900) + 100
    const key = Object.values(tempObj).reduce((acc, newValue) => acc = `${acc}${newValue || ''}${randomNumber}`, '')
    setGridKey(key)

    if (!showGenerateDrugsGrid[activeProgramConditionLevel]) {
      const newGenerateDrugsGrid = [...showGenerateDrugsGrid]
      newGenerateDrugsGrid[activeProgramConditionLevel] = true
      setShowGenerateDrugsGrid(newGenerateDrugsGrid)
    }
  }

  let cellRendererParams = {
    action: {
      cellRenderer: DeleteRowRenderer,
      state: {
        onClick: delProgramCondition,
      },
      width: 95,
    },
    include: {
      overrideHeader: 'Include',
      cellRenderer: LevelCheckboxRenderer,
      state: {
        onCheckboxChange,
      },
    },
    generate_drugs: {
      cellRenderer: ButtonRenderer,
      searchArgs: { onClick: handleGenerateDrugsClick, text: 'Generate Drugs' },
      width: 175,
    },
    effective_start_date: {
      valueFormatter: (params) => {
        if (!params.value) return ''
        return moment(params.value)?.format('YYYY-MM-DD') || params.value
      },
    },
    effective_end_date: {
      valueFormatter: (params) => {
        if (!params.value) return ''
        return moment(params.value)?.format('YYYY-MM-DD') || params.value
      },
    }
  }

  const serverSideGridConfig = {
    rowModel: 'serverSide',
    serverSideStoreType: 'partial',
    cacheBlockSize: 20,
    pagination: true,
    paginationPageSize: 20,
  }

  const serverSideGridParams = {
    form: currentGenerateDrugCondition,
    apiId: 'generate-program-list',
    sagaToCall: props.actions.generateProgramList,
    headers,
    headerMapping,
  }

  const defaultColDef = useMemo(() => ({
    resizable: true,
    floatingFilter: true,
    filter: "agTextColumnFilter",
    filterParams: { buttons: ["reset"], filterOptions: ['contains', 'equals'] },
    outerHeight: 120,
  }), [])

  const handleAccordionTitleClick = (e, titleProps) => {
    const { index } = titleProps
    if (showGenerateDrugsGrid[index]) {
      const newGenerateDrugsGrid = [...showGenerateDrugsGrid]
      newGenerateDrugsGrid[index] = false
      setShowGenerateDrugsGrid(newGenerateDrugsGrid)
    }
    handleProgramConditionLevelClick(e, titleProps)
  }

  const cellEditorParams = {
    gpi: {
      cellEditor: InputTextEditor,
      onChange: handleChange,
    },
    ndc: {
      cellEditor: InputTextEditor,
      onChange: handleChange,
    },
    drug_group: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    drug_class: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    drug_subclass: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    manufacturer: {
      cellEditor: SearchEditor,
      onChange: handleChange,
      autoSuggestionResultSelect,
    },
    drug_name: {
      cellEditor: SearchEditor,
      onChange: handleChange,
      autoSuggestionResultSelect,
    },
    multi_source: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    ddid: {
      cellEditor: InputTextEditor,
      onChange: handleChange,
    },
    brand_generic: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    otc_indicator: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    item_status_flag: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    desi_code: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    route_of_administration: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    maintenance_drug_code: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    effective_start_date: {
      cellEditor: DatePickerEditor,
      onChange: handleChange,
    },
    effective_end_date: {
      cellEditor: DatePickerEditor,
      onChange: handleChange,
    },
    alternate_gpi_match: {
      cellEditor: InputTextEditor,
      onChange: handleChange,
    },
    alternate_ndc_match: {
      cellEditor: InputTextEditor,
      onChange: handleChange,
    },
    application_type_flag: {
      cellEditor: InputTextEditor,
      onChange: handleChange,
    },
    mfg_labeler_id: {
      cellEditor: InputTextEditor,
      onChange: handleChange,
    },
    repackaged_code: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    third_party_restriction_code: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    dosage_form_cd: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    clinic_pack_code: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    innerpack_code: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    unit_dose: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    dea_class_code: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    fda_therapeutic_equivalence_code: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    marketing_category: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    storage_condition_code: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    unit_dose_with_non_unit_dose_non_repackager_in_gpi: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    rx_with_otc_in_gpi: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    member_notes: {
      cellEditor: InputTextEditor,
      onChange: handleChange,
    },
    agent_notes: {
      cellEditor: InputTextEditor,
      onChange: handleChange,
    },
    internal_notes: {
      cellEditor: InputTextEditor,
      onChange: handleChange,
    },
    claim_message_code: {
      cellEditor: InputTextEditor,
      onChange: handleChange,
    },
    claim_message_type: {
      cellEditor: InputTextEditor,
      onChange: handleChange,
    },
    target_system_formulary_tier: {
      cellEditor: InputTextEditor,
      onChange: handleChange,
    },
    target_system_formulary_status: {
      cellEditor: InputTextEditor,
      onChange: handleChange,
    },
  }
  cellRendererParams = { ...cellRendererParams, ...cellRenderers }

  const generateDrugsCellRendererParams = _.cloneDeep(cellRendererParams)
  generateDrugsCellRendererParams.ndc.hide = false
  delete generateDrugsCellRendererParams.include
  const unsortableKeys = ['effective_start_date', 'effective_end_date']
  for (let key in generateDrugsCellRendererParams) {
    if (generateDrugsCellRendererParams[key]) {
      if (unsortableKeys.includes(key)) {
        generateDrugsCellRendererParams[key].sortable = false
      }
      generateDrugsCellRendererParams[key].filterParams = {
        filterOptions: ['contains', 'equals'],
        defaultJoinOperator: 'OR',
        buttons: ['reset'],
      }
    }
  }

  return (
    <div className="section">
      <section className="program-inputs-container spacing border-line shadow">
        <div className="program-inputs">
          <span>Program Name</span>
          <FliptInput className="createProgramListInputs" placeholder="Program Name" name="doc_name" value={doc_name} disabled={!editMode || (!!version && (version !== '1.0')) || (!!status && status === 'PUBLISHED')} onChange={updateFields} />
        </div>
        <div className="program-inputs">
          <span>Effective Start Date</span>
          <FliptDatePicker className="create-program-start-date" placeholder="Effective Start Date" name="effective_start_date" minDate={minProgramStartDate} value={convertStrToDateObj(effective_start_date)} disabled={!editMode} onChange={updateFields} />
        </div>
        <div className="program-inputs">
          <span>Version</span>
          <FliptInput className="create-program-start-date" disabled name="version" value={version} onChange={updateFields} format="MM-DD-YYYY" readOnly={!editMode} />
        </div>
        <div className="program-inputs">
          <span>Version Effective Date</span>
          <FliptDatePicker className="create-program-start-date" name="version_effective_date" value={convertStrToDateObj(version_effective_date)} onChange={updateFields} format="MM-DD-YYYY" readOnly={!editMode} />
        </div>
        <div className="program-inputs">
          <span>List Type</span>
          <FliptDropdown className="create-program-list-type" placeholder="List Type" name="list_type" value={list_type} options={listTypeOptions} selectOnBlur={false} scrolling clearable selection readOnly={!editMode} onChange={(e, dropdown) => updateFields(e, dropdown)} />
        </div>
        <div className="program-inputs">
          <span>Custom Program Type</span>
          <FliptDropdown className="create-program-list-custom-type" placeholder="Custom Program Type" name="custom_program_type" value={custom_program_type} options={customProgramTypeOptions} selectOnBlur={false} scrolling clearable selection readOnly={!editMode} onChange={(e, dropdown) => updateFields(e, dropdown)} />
        </div>
        <div className="program-inputs">
          <span>Target System Formulary Tier</span>
          <FliptInput className="createProgramListInputs" name="target_system_formulary_tier" value={target_system_formulary_tier} onChange={updateFields} />
        </div>
        <div className="program-inputs">
          <span>Target System Formulary Status</span>
          <FliptInput className="createProgramListInputs" name="target_system_formulary_status" value={target_system_formulary_status} onChange={updateFields} />
        </div>
        <FliptHierarchyDropdown showInactiveDrugs={true} setForm={setForm} form={form} showHierarchyGlobal={true} />
      </section>
      <section className="grid-container spacing border-line shadow">
        {!!editMode
          && (
            <div className="add-program-condition-level">
              <Button size="small" onClick={addNewProgramConditionLevel} color="youtube">
                <Icon name="add" />
                Add New Program Condition Level
              </Button>
            </div>
          )}
        <div className="program-condition-levels">
          <Accordion className="program-condition-levels-accordion" styled>
            {
              programConditionLevels.map((programConditionLevel, idx) => {
                const agGridRef = React.createRef()
                const programConditionLevelHeading = `Program Condition Level ${idx + 1}`
                const hiddenColumn = Object.keys(cellRendererParams).filter(key => cellRendererParams[key]?.hide)
                const columnData = {}
                programConditionLevel.map(ele => {
                  Object.keys(ele).forEach(key => {
                    columnData[key] = !!columnData[key] || !!ele[key]
                  })
                })
                hiddenColumn.forEach(key => {
                  if (columnData[key]) {
                    cellRendererParams[key].hide = false
                  }
                })
                const programConditions = !editMode ? { data: programConditionLevel, headers } : {
                  agGridRef,
                  autoSuggestionMinChars,
                  autoSuggestionValues: autoSuggestionValues,
                  cellEditorParams,
                  cellRendererParams,
                  data: programConditionLevel,
                  dropdownOptions: dropdownOptions[idx],
                  headers,
                  rowCellInfo,
                  stepLevel: idx,
                }

                return (
                  <div className="program-condition-level">
                    <Accordion.Title
                      active={activeProgramConditionLevel === idx}
                      index={idx}
                      onClick={handleAccordionTitleClick}
                    >
                      <Icon name="dropdown" />
                      {programConditionLevelHeading}
                      <Icon className="program-condition-level-delete" name="delete" onClick={(e) => deleteProgramConditionLevel(e, idx)} />
                    </Accordion.Title>
                    <Accordion.Content
                      active={activeProgramConditionLevel === idx}
                    >
                      <section className='button-header-section'>
                        <span className="checkbox-label"></span>
                        <Button size="small" className="export-button" onClick={(e) => exportConditionDrugs(e, idx)}>Export Progam List Level {idx + 1}</Button>
                      </section>
                      <div className="program-conditions">
                        <FliptGrid {...programConditions} />
                      </div>
                      <div className="program-condition-level-button-container">
                        {!!editMode && (
                          <div className="program-condition-level-add-cond-button">
                            <AddRow addRow={addProgramCondition} level={idx} />
                          </div>
                        )}
                      </div>
                      {!!showQualifier[idx] && (
                        <Qualifiers
                          allCriteriaIDs={criteriaIds}
                          model_ids={["claim", "prescriber", "user", "pharmacy"]}
                          stepLevel={idx}
                          criteria_id={criteriaIds?.[idx] || ""}
                          preventMultipleGetCalls
                        />
                      )}
                      {activeProgramConditionLevel === idx && showGenerateDrugsGrid[idx] && (
                        <div className="program-condition-level-drugs">
                          <FliptGrid
                            key={gridKey}
                            defaultColDef={defaultColDef}
                            headers={gridHeaders}
                            cellRendererParams={generateDrugsCellRendererParams}
                            serverSideGridConfig={serverSideGridConfig}
                            serverSideGridParams={serverSideGridParams}
                            filterOptions={['contains', 'equals']}
                            suppressColumnVirtualisation
                          />
                        </div>
                      )}
                    </Accordion.Content>
                  </div>
                )
              })
            }
          </Accordion>
        </div>
      </section>
      <div className="buttonContainer">
        {editMode &&
          <FliptButton
            className="primary searchButton"
            disabled={![DRAFT, PUBLISHED, REJECTED].includes(status)}
            name="Save"
            onClick={saveProgramList}
          />}
        {editMode && doc_id &&
          <FliptButton
            className="primary searchButton"
            disabled={status !== DRAFT}
            name="Send to Peers for Review/Approval"
            onClick={sendForReview}
          />}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  state: {
    conditionsDropDownOptions: state.rpm.conditionsDropDownOptions,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
    ...ProgramListCreationActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProgramConditions)
