import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import FliptButton from "../v2/fliptButton";
// import Card from 'react-bootstrap/Card';
import './styles.scss';

export function SortableItem({ tab, isActive, tabs, onTabClick, draghandle }) {

  const {
    isDragging,
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition
  } = useSortable({ id: tab.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    display: 'inline-flex'
  }

  let buttonClass = 'tab-button'
  if (isActive) {
    buttonClass += ' active-tab'
  }

  return (
    draghandle &&
    <div ref={setNodeRef} style={style} >
      <FliptButton
        name={tab.tabName}
        className={buttonClass}
        onClick={() => onTabClick(tab.id, tabs)}
        style={{ 'cursor': isDragging ? 'grabbing' : 'pointer' }}
        icon={<img {...attributes} {...listeners} src='/i/drag-handle.svg'></img>}
      />
    </div>
  )
}
