export const otherFieldAttribute = [
    {
      header: "",
      type: "text",
      label: "PA Employer Cost Limit",
      name: "pa_employer_cost_limit",
      value: "",
      info: "Max plan sponsor cost covered by the plan that does not require PA",
      validation: "",
      placeholder: "Enter",
    },
  
    {
      header: "",
      type: "text",
      label: "Conceriage PA Approval Within Days",
      name: "pa_override_threshold",
      value: "",
      info: "Maximum number of days that concierge can override the PA drugs for a particular member",
      validation: "numeric",
      placeholder: "Enter",
    },
    {
      header: "",
      type: "radio",
      label: "RRA Penalty for Maintenance Drugs",
      name: "rra_penalty_for_maintenance_drug",
      value: "",
      info: "If Y then RRA penalty logic will Only be applicable to maintenance drugs. If N, then it will be applicable to ALL drugs",
      validation: "",
      placeholder: "Enter",
    },
    {
      header: "",
      type: "text",
      label: "Refill Consumption Pct 30Day",
      name: "claims_refill_consumption_pct_30day",
      value: "",
      info: "for a 30 day of supply refill claim: Member must use this percentage of supply before refill claim can be processed. Earlier claims will rejected with Refill too soon rejection. ",
      validation: "",
      placeholder: "Enter",
    },
    {
      header: "",
      type: "text",
      label: "Refill Consumption Pct 90Day",
      name: "claims_refill_consumption_pct_90day",
      value: "",
      info: "For a 90 day of supply refill claim: Member must use this percentage of supply before refill claim can be processed. Earlier claims will rejected with Refill too soon rejection. ",
      validation: "",
      placeholder: "Enter",
    },

    {
      header: "",
      type: "text",
      label: "Excluded Macids Rra Penalty",
      name: "excluded_macids_rra_penalty",
      value: "",
      info: "Intent expiration days for drugs that require PA",
      validation: "",
      placeholder: "Enter",
      "subgroup": "RRA",
    },

  
    {
      header: "",
      type: "text",
      label: "Client Portal Division Breakdown",
      name: "client_portal_division_breakdown",
      value: "",
      info: "This is used when clients want their members sorted by certain client indicators.  Novartis for example has us sorting by a code they provide.  We put it in the location field.",
      validation: "",
      placeholder: "Enter",
      "subgroup": "Client Portal",
    },
    {
        header: "",
        type: "text",
        label: "Subdivision For Client Portal Invoice Report",
        name: "subdivision",
        value: "",
        info: "Sub division for Client Portal Invoice report ",
        validation: "",
        placeholder: "Enter",
        "subgroup": "Client Portal",
      },
    {
      header: "",
      type: "radio",
      label: "Claims Report Phi Display",
      name: "claims_report_phi_display",
      value: "",
      info: "Client Portal - Determine if we want to display PHI within the report",
      validation: "",
      placeholder: "Enter",
      "subgroup": "Client Portal",
    },

  
    {
      header: "",
      type: "text",
      label: "Subscription Invoice Desc Line 1",
      name: "subscription_invoice_desc_line1",
      value: "",
      info: "True/False",
      validation: "",
      placeholder: "Enter",
      "subgroup": "Client Portal",
    },
    {
      header: "",
      type: "text",
      label: "Subscription Invoice Desc Line 2",
      name: "subscription_invoice_desc_line2",
      value: "",
      info: "Percentage of eligibility changed records in a load ",
      validation: "",
      placeholder: "Enter",
      "subgroup": "Client Portal",
    },
   
  ];
  