import React, { useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import "./styles.scss";
import AppWebTab from "./AppWebTab";
import BillingTab from "./BillingTab";
import CommunicationTab from "./CommunicationTab";
import CompanyTab from "./CompanyTab";
import GlobalEditsTab from "./GlobalEditsTab";
import GroupTab from "./GroupTab";
import OtherTab from "./OtherTab";
import POSRebateTab from "./POSRebateTab/POSRebateTab";
import RequiredTab from "./RequiredTab";
import RewardTab from "./RewardTab";
import SystemTab from "./SystemTab";
import { Creators as ClientConfigurationActions } from "../../../../redux/reducers/api/clientConfiguration";
import { GROUP_TAB_ID } from "../Constant";

const hierarchyDashboardTab = (props) => {
  const { state, actions } = props;
  const defaultTabName = state.defaultTabName;

  useEffect(() => {
    actions.resetValidationResult()
  }, [])

  const renderTab = () => {
    switch (defaultTabName) {
      case "BILLING":
        return <BillingTab />;
      case "REQUIRED":
        return <RequiredTab />;
      case "COMPANY":
        return <CompanyTab />;
      case "COMMUNICATION":
        return <CommunicationTab />;
      case "APP/WEB":
        return <AppWebTab />;
      case "REWARDS":
        return <RewardTab />;
      case "GLOBAL":
        return <GlobalEditsTab />;
      case "POS Rebate":
        return <POSRebateTab />;
      case "SYSTEM":
        return <SystemTab />;
      case "OTHER":
        return <OtherTab />;
      case GROUP_TAB_ID:
        return <GroupTab />;
      default:
        return null;
    }
  };

  return (
    <div id="hierarchy-dashboard-tab">
      <div className="content-tab">
        {renderTab()}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    state: {
      defaultTabName: state.clientConfiguration.defaultTabName,
    },
  };
};

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...ClientConfigurationActions,
  };

  return {
    actions: bindActionCreators(allActions, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(hierarchyDashboardTab);
