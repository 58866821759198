
import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import './styles.scss'
import FliptButton from '../../components/form/fliptButton'
import { Creators as MemberLookupActions } from '../../redux/reducers/api/memberLookup'
import { Creators as AppActions } from '../../redux/reducers/app'
import FliptGrid from '../fliptGrid'
import FliptDatePicker from '../form/fliptPaDatepicker'
import FliptPaInput from '../form/fliptPaInput'
import { isEmpty } from 'lodash'

class FliptMultiMemberSearch extends Component {
    constructor(props) {
        super(props)
        this.state = {
            memberList: [],
            searchForm: {
                first_name: '',
                last_name: '',
                memberId: '',
            },
            memberData: props.memberData,
            memberSearched: false
        }
    }

    _clearMemberFields = () => {
        this.setState({
            searchForm: {
                first_name: '',
                last_name: '',
                memberId: ''
            },
            memberList: [],
            memberSearched: false
        })
    }

    _searchData() {
        const { actions } = this.props
        const { state: { searchForm } } = this
        if (!searchForm.memberId && !searchForm.first_name && !searchForm.last_name) {
            const transitionalPortal = {
                header: 'Fields are missing',
                copy: 'Please add member Id/tpa Id, First Name or Last Name to search member',
            }
            actions.displayTransitionalPortal(transitionalPortal)
            return;
        }
        this.setState({ memberList: [] })
        let searchData = {}
        if (searchForm.memberId) {
            searchData = {
                member_identifier: 'member_id',
                id: searchForm.memberId,
                noDataClearing: true
            }
        }
        if (searchForm.first_name) searchData.first_name = searchForm.first_name
        if (searchForm.last_name) searchData.last_name = searchForm.last_name
        if (searchForm.date_of_birth) searchData.date_of_birth = searchForm.date_of_birth
        actions.setMemberLookupFailure(false)
        actions.getMemberLookupData(searchData)
    }

    componentDidUpdate(prevProps) {
        const { props } = this
        const { memberLookup } = props.state
        const multipleMembersFound = memberLookup.multiMemberLookup.length
            && prevProps.state.memberLookup.multiMemberLookup !== memberLookup.multiMemberLookup
        if (multipleMembersFound) {
            const memberList = memberLookup.multiMemberLookup.map((data) => data.member_details)
            this.setState({ memberList, memberSearched: true })
        } else if (prevProps.state.memberLookup?.member_details !== memberLookup?.member_details && !isEmpty(memberLookup?.member_details)) {
            this.setState({ memberList: [memberLookup.member_details], memberSearched: true })
        } else if (prevProps.state.memberLookup.memberLookupFailed !== memberLookup.memberLookupFailed
            && memberLookup.memberLookupFailed) {
            this.setState({ memberSearched: true })
        }
    }

    _updateSearchForm = (el, dropdown) => {
        const { searchForm } = this.state
        const { name, value } = dropdown || el.currentTarget
        if (name == 'memberId') { searchForm.first_name = ''; searchForm.last_name = ''; searchForm.date_of_birth = '' }
        if (name === 'first_name' || name === 'last_name') { searchForm.memberId = '' }
        searchForm[name] = value
        this.setState({ searchForm, memberSearched: false, memberList: [] })
    }

    onMemberSelectionChanged = (data) => {
        const { updateMemberData } = this.props
        const { memberData } = this.state
        const selectedRows = data.api.getSelectedRows();
        if (selectedRows.length) {
            let filteredMemberData = [...selectedRows, ...memberData]
            filteredMemberData = [...new Map(filteredMemberData.map((m) => [m.member_id, m])).values()];
            updateMemberData(filteredMemberData)
            this.setState({ memberData: filteredMemberData })
        }
    }

    _submitResult = () => {
        const { actions } = this.props
        actions.toggleModal()
    }

    _clearResult = () => {
        const { updateMemberData } = this.props
        updateMemberData([])
        this.setState({ memberData: [] })
    }

    render() {
        const { memberList, searchForm, memberData, memberSearched } = this.state
        const headers = [
            'Select', 'first_name', 'last_name', 'date_of_birth', 'member_id', 'tpa_member_id', 'plan_name',
            'phone_number', 'domain_name', 'group',
        ]
        const selectedHeaders = [
            'first_name', 'last_name', 'date_of_birth', 'member_id', 'tpa_member_id', 'plan_name',
            'phone_number', 'domain_name', 'group',
        ]
        const cellRendererParams = {
            Select: {
                checkboxSelection: true,
                width: '120px'
            },
            domain_name: {
                overrideHeader: 'Account',
            }
        }
        const agGridRefMem = React.createRef()
        const isMemberId = !!searchForm.memberId
        const isName = (searchForm.first_name && searchForm.last_name) && !isMemberId
        return (
            <>
                <div className="multi-member-section">
                    <div className="member-header">
                        Find Member
                    </div>
                    <div className="inner-container">
                        <div className="fields-container">
                            <FliptPaInput value={searchForm.memberId} disabled={isName} stylized name="memberId" onChange={this._updateSearchForm} label="Member Id / Tpa Id" />
                            <FliptPaInput value={searchForm.first_name} disabled={isMemberId} stylized name="first_name" onChange={this._updateSearchForm} label="First Name" />
                            <FliptPaInput value={searchForm.last_name} disabled={isMemberId} stylized name="last_name" onChange={this._updateSearchForm} label="Last Name" />
                        </div>
                        <div className="member-buttons-container">
                            <FliptButton className='primary' disabled={(searchForm?.memberId && searchForm?.memberId.length < 5)} name='SEARCH' onClick={() => this._searchData('MEMBER_SEARCH')} />
                            <FliptButton name="Clear" className="secondary" onClick={this._clearMemberFields} />
                        </div>
                    </div>
                    {memberList?.length ?
                        <>
                            <div className="member-header">
                                Search Results
                            </div>
                            <div className="grid-container">
                                <FliptGrid
                                    agGridRef={agGridRefMem}
                                    headers={headers}
                                    data={memberList}
                                    rowSelection='multiple'
                                    cellRendererParams={cellRendererParams}
                                    onSelectionChanged={(data) => { this.onMemberSelectionChanged(data) }}
                                />
                            </div></> : <></>
                    }
                    {memberData?.length ?
                        <>
                            <div className="member-header">
                                Selected Results
                            </div>
                            <div className="grid-container">
                                <FliptGrid
                                    headers={selectedHeaders}
                                    data={memberData}
                                    cellRendererParams={cellRendererParams}
                                />
                            </div></> : <></>
                    }
                    <div className="member-buttons-container">
                        <FliptButton disabled={!memberList.length && !memberSearched} className='primary' name='SUBMIT' onClick={() => this._submitResult()} />
                        <FliptButton name='CLEAR' onClick={() => this._clearResult()} />

                    </div>
                </div>
            </>
        )
    }

}

const mapStateToProps = (state) => ({
    state: {
        memberLookup: state.memberLookup,
    },
})

const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...MemberLookupActions,
        ...AppActions,
    }
    return {
        actions: bindActionCreators(allActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FliptMultiMemberSearch)