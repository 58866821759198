import { CmsTierLabelTypes, ExportTypes } from "./dropdownData";

export default {
    drug_list_type: {
        type: 'dropdown',
        disabled: false,
        multiple: false,
        options: [{
            key: "DRUG_LIST",
            value: "DRUG_LIST",
            text: "DRUG_LIST"
        },
        {
            key: "PROGRAM",
            value: "PROGRAM",
            text: "PROGRAM"
        }],
    },
    drug_list_name: {
        type: 'dropdown',
        disabled: false,
        multiple: true,
        options: CmsTierLabelTypes,
    },
    tier_name: {
        type: 'dropdown',
        disabled: false,
        options: CmsTierLabelTypes,
    },
    tier_level: {
        type: 'dropdown',
        disabled: false,
        options: [],
    },
    exclude_from_export: {
        type: 'dropdown',
        options: ExportTypes,
    },
}