export default {
    source_contract: {
        type: 'dropdown',
        options: [],
    },
    include_exclude: {
        type: 'dropdown',
        options: [],
    },
    program_drug_list: {
        type: 'dropdown',
        options: [],
        multiple: true
    },
    contract_price_source: {
        type: 'dropdown',
        options: [],
    },
    adjustment_type: {
        type: 'dropdown',
        options: [],
    },
    adjustment_rate: {
        type: 'input',
    },
    target_price_type: {
        type: 'dropdown',
        options: [],
    },
    rate_type: {
        type: 'dropdown',
        options: [],
    },
    mac_list_id: {
        type: 'input',
    },
    target_price: {
        type: 'input',
    }
}