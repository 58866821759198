import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import _ from 'lodash'
import { Accordion, Button, Icon } from 'semantic-ui-react'
import FliptGrid from '../../../../components/fliptGrid'
import FliptDropdown from '../../../../components/form/fliptDropdown'
import AddRow from '../addRow'
import moment from 'moment'
import { Creators as PlanManagementActions } from '../../../../redux/reducers/api/planManagement'
import { Creators as AppActions } from '../../../../redux/reducers/app'
import { DeleteRowRenderer } from '../../../../components/fliptGrid/cellRenderers'
import { InputTextEditor, DropdownEditor, DatePickerEditor } from '../../../../components/fliptGrid/cellEditors'
import rowCellInfo from '../data/rowCellInfo'
import './styles.scss'
import { resolveMessage } from '../../../../utils/validationHelpers'

class FormularyCopay extends Component {
  constructor(props) {
    super(props)
    const { state, editMode } = props
    const { tiers, phases } = editMode ? state : []
    const { planLOB } = state
    const hasPhases = planLOB?.includes('Medicare D')
    const initialTierValues = editMode ? this.initializeTierValues(tiers, hasPhases ? phases : []) : []

    this.state = {
      activeTierLevel: 0,
      activePhaseIndex: 0,
      hasPhases,
      phases: state.phases || [],

      tierStates: initialTierValues ? [...initialTierValues] : [],
    }
  }

  componentDidMount() {
    const { state, editMode } = this.props
    const { tiersData } = state
    const { hasPhases, phases } = this.state
    const defaultTierCondition = {
      action: '',
      member_pay: null,
      copay_type: '',
      day_supply: '',
      effective_end_date: '',
      effective_start_date: '',
      max_copay: null,
      min_copay: null,
      network_tier: 'ALL',
      pharmacy_type: ''
    }

    if (tiersData?.length) {
      this.setState((prevState) => {
        if (!editMode) {
          return {
            tierStates: tiersData,
          }
        }
        return {
          tierStates: prevState.tierStates.map(t => {
            const tierData = tiersData.find(d => d.formulary_tier_version_id === t.formulary_tier_version_id)
            let tierConditions = []
            let phaseData = []
            if (hasPhases) {
              const phaseGrouping = _.groupBy(tierData?.tierConditions, 'phase')
              phaseData = phases.map(p => p.value).map((phaseName) => ({
                phase: phaseName,
                tierConditions: phaseGrouping[phaseName] || [defaultTierCondition],
              }))
            } else {
              tierConditions = tierData?.tierConditions || [defaultTierCondition]
            }

            return {
              ...t,
              form: tierData?.form || t.form,
              tierConditions,
              phaseData,
            }
          })
        }
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { tierStates } = this.state
    const { copyFormData } = this.props
    if (copyFormData && !_.isEqual(prevState.tierStates, tierStates)) {
      copyFormData({ data: tierStates })
    }
  }

  initializeTierValues = (tiers, phases) => {
    const { state } = this.props
    const formVars = {
      accumulate_deductible: '',
      accumulate_out_of_pocket: '',
      deductible_exempt: '',
      out_of_pocket_exempt: '',
      deductible_applies: ''
    }
    let tierConditions = []
    let phaseData = []
    const defaultTierCondition = {
      action: "",
      days_supply: "",
      member_pay: null,
      copay_type: "",
      effective_end_date: state.effective_end_date,
      effective_start_date: state.effective_start_date,
      max_copay: null,
      min_copay: null,
      network_tier: "ALL",
      pharmacy_type: ''
    }
    if (phases?.length > 0) {
      phaseData = phases.map(p => {
        const tierConditions = []
        rowCellInfo.day_supply.options.map(option => {
          if (option.value !== "All") {
            tierConditions.push({
              ...defaultTierCondition,
              day_supply: option.value,
            })
          }
        })
        return {
          phase: p.value,
          tierConditions: tierConditions,
        }
      })
    } else {
      rowCellInfo.day_supply.options.map(option => {
        if (option.value !== "All") {
          tierConditions.push({
            ...defaultTierCondition,
            day_supply: option.value,
          })
        }
      })
    }

    const tierStates = tiers?.map((tier) => ({
      tier_id: tier.value,
      // tier_name: tier?.effective_begin_date ? `${tier.display_name} (${moment(tier.effective_begin_date).format('YYYY-MM-DD')} - ${moment(tier.effective_end_date).format('YYYY-MM-DD')})` : tier.display_name,
      tier_name: tier.display_name,
      effective_begin_date: tier.effective_begin_date,
      effective_end_date: tier.effective_end_date,
      formulary_tier_version_id: tier.formulary_tier_version_id,
      form: _.cloneDeep(formVars),
      tierConditions: _.cloneDeep(tierConditions),
      phaseData: _.cloneDeep(phaseData),
    }))
    return tierStates
  }

  onFilterChange = (e, dropdown) => {
    const { tierStates, activeTierLevel } = this.state
    const { name, value } = dropdown || e.currentTarget

    const tierStatesCopy = _.cloneDeep(tierStates)
    const activeTierState = _.cloneDeep(tierStatesCopy[activeTierLevel])
    const prevState = activeTierState.form
    activeTierState.form = {
      ...prevState,
      [name]: value,
    }
    tierStatesCopy[activeTierLevel] = activeTierState
    this.setState({
      tierStates: tierStatesCopy,
    })
  }

  addRow = () => {
    const { tierStates, activeTierLevel, activePhaseIndex } = this.state
    const hasPhases = tierStates[activeTierLevel].phaseData.length > 0
    const { tierConditions, effective_end_date, effective_start_date } = hasPhases ? tierStates[activeTierLevel].phaseData[activePhaseIndex] : tierStates[activeTierLevel]
    let startDate = effective_start_date || this.props.state?.effective_start_date ? moment(this.props.state?.effective_start_date).toDate() : ''
    let endDate = effective_end_date || this.props.state?.effective_end_date ? moment(this.props.state?.effective_end_date).toDate() : ''
    const tierStatesCopy = _.cloneDeep(tierStates)
    const activeTierState = { ...tierStatesCopy[activeTierLevel] }
    const dataToUpdate = hasPhases ? activeTierState.phaseData[activePhaseIndex] : activeTierState
    dataToUpdate.tierConditions = [
      ...tierConditions,
      {
        action: "",
        days_supply: "",
        member_pay: null,
        copay_type: "",
        effective_end_date: endDate || '',
        effective_start_date: startDate || '',
        max_copay: null,
        min_copay: null,
        network_tier: "ALL",
        pharmacy_type: ''
      },
    ]
    tierStatesCopy[activeTierLevel] = activeTierState

    this.setState({
      tierStates: tierStatesCopy,
    })
  }

  delRow = (rowIndex) => {
    const { tierStates, activeTierLevel, activePhaseIndex } = this.state
    const hasPhases = tierStates[activeTierLevel].phaseData.length > 0
    const { tierConditions } = hasPhases ? tierStates[activeTierLevel].phaseData[activePhaseIndex] : tierStates[activeTierLevel]

    const tierStatesCopy = _.cloneDeep(tierStates)
    const activeTierState = { ...tierStatesCopy[activeTierLevel] }
    const dataToUpdate = hasPhases ? activeTierState.phaseData[activePhaseIndex] : activeTierState

    dataToUpdate.tierConditions = tierConditions.length <= 1 ? [] : tierConditions.filter((cond) => tierConditions.indexOf(cond) !== rowIndex)

    tierStatesCopy[activeTierLevel] = activeTierState

    this.setState({
      tierStates: tierStatesCopy,
    })
  }

  handleChange = (el, dropdown, rowIndex, gridApi) => {
    const { tierStates, activeTierLevel, activePhaseIndex } = this.state

    const tierStatesCopy = { ...tierStates }
    const activeTierState = { ...tierStatesCopy[activeTierLevel] }
    const hasPhases = activeTierState.phaseData.length > 0

    const tierConditionsCopy = hasPhases ? [...activeTierState.phaseData[activePhaseIndex].tierConditions] : [...activeTierState.tierConditions]
    const rowToUpdate = tierConditionsCopy[rowIndex]
    const { name, value } = dropdown || el.currentTarget
    rowToUpdate[name] = value

    if (name === 'copay_type') {
      rowToUpdate['member_pay'] = ''
    }

    tierConditionsCopy[rowIndex] = rowToUpdate
    const dataToUpdate = hasPhases ? activeTierState.phaseData[activePhaseIndex] : activeTierState
    dataToUpdate.tierConditions = tierConditionsCopy

    tierStatesCopy[activeTierLevel] = activeTierState
    this.setState({
      tierStates: Object.values(tierStatesCopy),
    })
    gridApi.refreshCells()
  }

  showGenerateButton = () => {
    const { tierStates, activeTierLevel, activePhaseIndex } = this.state
    const tierStatesCopy = { ...tierStates }
    const activeTierState = { ...tierStatesCopy[activeTierLevel] }
    const hasPhases = (activeTierState?.phaseData?.length > 0) || false
    const tierConditionsCopy = hasPhases ? [...activeTierState.phaseData[activePhaseIndex === -1 ? 0 : activePhaseIndex]?.tierConditions] : [...(activeTierState?.tierConditions || [])]
    const ele = tierConditionsCopy.find(x => x.day_supply === "All" || x.pharmacy_type === "ALL")
    return !_.isEmpty(ele)
  }

  handleTierClick = (idx) => {
    const { state } = this
    const { activeTierLevel } = state
    const newIndex = activeTierLevel === idx ? -1 : idx
    this.setState({ activeTierLevel: newIndex, activePhaseIndex: 0 })
  }

  handlePhaseClick = (idx) => {
    const { state } = this
    const { activePhaseIndex } = state
    const newIndex = activePhaseIndex === idx ? -1 : idx
    this.setState({ activePhaseIndex: newIndex })
  }

  generateGrid = () => {
    const { tierStates } = this.state
    const { actions } = this.props
    const tierStatesCopy = [...tierStates]
    try {
      tierStatesCopy.forEach(formData => {
        if (formData?.phaseData?.length > 0) {
          formData.phaseData.forEach(phase => {
            this.validateCopayData(phase)
          })
        } else {
          this.validateCopayData(formData)
        }
      })
    } catch (err) {
      const transitionalPortal = {
        header: 'Validation Error',
        copy: err,
      }
      actions.displayTransitionalPortal(transitionalPortal)
      return
    }
    this.setState({ tierStates: tierStatesCopy })
  }

  hasDuplicates = (array, pharmacy_type) => {
    const arr = array.filter(x => x === pharmacy_type)
    return (new Set(arr)).size !== arr.length
  }

  validateCopayData = (formData) => {
    const networkTierArray = [...formData.tierConditions.map((data) => data.pharmacy_type)];
    let newTierConditions = []
    formData.tierConditions.forEach(tier => {
      if (tier?.day_supply !== "All") { newTierConditions.push(tier) }
      if (tier?.day_supply === "All") {
        if (this.hasDuplicates(networkTierArray, tier?.pharmacy_type)) {
          throw new Error(resolveMessage("val_remove_duplicate_value_from_network_tier").message)
        } else {
          rowCellInfo.day_supply.options.forEach(option => {
            if (option?.value !== "All") {
              const newTierCondition = { ...tier }
              newTierCondition.day_supply = option.value
              newTierConditions.push(newTierCondition)
            }
          })
        }
      }
    })
    let networkTierCopy = []
    newTierConditions.forEach(tier => {
      if (tier?.pharmacy_type === "ALL") {
        rowCellInfo.pharmacy_type.options.forEach(option => {
          if (option?.value !== "ALL") {
            networkTierCopy = [...networkTierCopy, { ...tier, pharmacy_type: option.value }]
          }
        })
      } else {
        networkTierCopy = [...networkTierCopy, { ...tier }]
      }
    })
    newTierConditions = networkTierCopy
    formData.tierConditions = newTierConditions
    const groupped = _.groupBy(newTierConditions, n => `${n.day_supply}${n.pharmacy_type}`)
    const duplicates = (_.uniq(_.flatten(_.filter(groupped, function (n) { return n.length > 1 }))).length > 1)
    if (duplicates) {
      throw new Error(resolveMessage("val_remove_duplicate_value_from_grid").message)
    }
  }

  renderAccordianContent = (activePhaseIndex, phases) => {
    const { state } = this.props
    const { planLOB } = state
    if (planLOB?.includes('Medicare D')) {
      return this.renderNestedAccordian(phases, activePhaseIndex)
    }
    return this.renderGrid()
  }

  renderNestedAccordian = (phases, activePhaseIndex) => {
    const phasesAccordian = phases.map((phase, phaseIdx) => (
      <div className="section-phase-level">
        <Accordion.Title
          active={activePhaseIndex === phaseIdx}
          index={phaseIdx}
          onClick={() => this.handlePhaseClick(phaseIdx)}
        >
          <Icon name="dropdown" />
          {phase.display_name}
        </Accordion.Title>
        <Accordion.Content
          active={activePhaseIndex === phaseIdx}
        >
          {this.renderGrid()}
        </Accordion.Content>
      </div>
    ))
    return phasesAccordian
  }

  renderGrid = () => {
    const { tierStates, activeTierLevel, hasPhases, activePhaseIndex } = this.state
    const { editMode } = this.props
    const activeTierState = tierStates[activeTierLevel]?.phaseData?.length > 0 ? tierStates[activeTierLevel].phaseData[activePhaseIndex] : tierStates[activeTierLevel] ?? {}
    const { tierConditions } = activeTierState ?? {}
    const headers = [
      'day_supply',
      'network_tier',
      'copay_type',
      'member_pay',
      'min_copay',
      'max_copay',
      'action',
    ]
    const cellRendererParams = {
      day_supply: {
        width: 145,
        overrideHeader: 'Days Supply',
      },
      copay_type: {
        width: 135,
        overrideHeader: 'COPAY Type',
      },
      member_pay: {
        width: 160,
        overrideHeader: 'Member Pay',
        valueFormatter: params => {
          if (params.data.member_pay === undefined || params.data.member_pay === null) return null
          if (params.data.copay_type === 'Fixed') return '$' + `${params.data.member_pay}`
          if (params.data.copay_type === 'Co-Insurance') return `${params.data.member_pay}` + '%'
          return params.data.member_pay
        },
      },
      min_copay: {
        width: 135,
        overrideHeader: 'Min COPAY',
      },
      max_copay: {
        width: 135,
        overrideHeader: 'Max COPAY',
      },
      effective_start_date: {
        width: 180,
      },
      effective_end_date: {
        width: 180,
      },
      action: {
        cellRenderer: DeleteRowRenderer,
        state: {
          onClick: this.delRow,
        },
        width: 135,
      },
    }
    const cellEditorParams = {
      day_supply: {
        editable: true,
        cellEditor: DropdownEditor,
        onChange: this.handleChange,
      },
      network_tier: {
        editable: true,
        cellEditor: DropdownEditor,
        onChange: this.handleChange,
      },
      copay_type: {
        editable: true,
        cellEditor: DropdownEditor,
        onChange: this.handleChange,
      },
      member_pay: {
        editable: true,
        cellEditor: InputTextEditor,
        onChange: this.handleChange,
        validation: 'numeric',
      },
      min_copay: {
        editable: true,
        cellEditor: InputTextEditor,
        onChange: this.handleChange,
        validation: 'numeric',
      },
      max_copay: {
        editable: true,
        cellEditor: InputTextEditor,
        onChange: this.handleChange,
        validation: 'numeric',
      },
      effective_start_date: {
        editable: true,
        cellEditor: DatePickerEditor,
        onChange: this.handleChange,
      },
      effective_end_date: {
        editable: true,
        cellEditor: DatePickerEditor,
        onChange: this.handleChange,
      },
    }

    if (editMode) {
      return (
        <section className="grid-container spacing border-line shadow">
          <div className="content">
            <FliptGrid
              data={tierConditions}
              headers={headers}
              cellRendererParams={cellRendererParams}
              cellEditorParams={cellEditorParams}
              rowCellInfo={rowCellInfo}
            />
          </div>
          <div className="addRowButtonContainer">
            {this.showGenerateButton() && (<Button compact size="small" onClick={() => this.generateGrid()} color="youtube">Generate</Button>)}
            <AddRow addRow={this.addRow} />
          </div>
        </section>
      )
    }
    return (
      <div className="content">
        <FliptGrid
          data={tierConditions}
          headers={headers.slice(0, -1)}
        />
      </div>
    )
  }

  render() {
    const {
      activeTierLevel,
      activePhaseIndex,
      tierStates,
      phases,
      hasPhases
    } = this.state
    const {
      editMode,
    } = this.props
    const activeTierState = tierStates[activeTierLevel] ?? {}
    const { form } = activeTierState ?? {}

    const {
      accumulate_deductible,
      accumulate_out_of_pocket,
      deductible_exempt,
      out_of_pocket_exempt,
      deductible_applies,
    } = form ?? {}

    const dropdownOptions = [{ value: 'Y', text: 'YES' }, { value: 'N', text: 'NO' }]

    return (
      <div className="formulary_copay">
        <div className="section-tiers">
          <Accordion className="section-tiers-accordion" styled>
            {tierStates?.map((tier, idx) => (
              <div className="section-tier-level">
                <Accordion.Title
                  active={activeTierLevel === idx}
                  index={idx}
                  onClick={() => this.handleTierClick(idx)}
                >
                  <Icon name="dropdown" />
                  {`${tier.tier_name} (${moment(tier.effective_begin_date).format('MM/DD/YYYY')} - ${moment(tier.effective_end_date).format('MM/DD/YYYY')})`}
                </Accordion.Title>
                <Accordion.Content
                  active={activeTierLevel === idx}
                >
                  <div className="section">
                    <section className="copay-dropdowns-container spacing border-line shadow">
                      <div className="copay-dropdowns">
                        <FliptDropdown
                          placeholder="Select"
                          label="Accumulate Deductible"
                          name="accumulate_deductible"
                          value={accumulate_deductible}
                          options={dropdownOptions}
                          onChange={this.onFilterChange}
                          stylized
                          disabled={!editMode}
                        />
                      </div>
                      <div className="copay-dropdowns">
                        <FliptDropdown
                          placeholder="Select"
                          label="Accumulate Out of Pocket"
                          name="accumulate_out_of_pocket"
                          value={accumulate_out_of_pocket}
                          options={dropdownOptions}
                          onChange={this.onFilterChange}
                          stylized
                          disabled={!editMode}
                        />
                      </div>
                      <div className="copay-dropdowns">
                        <FliptDropdown
                          placeholder="Select"
                          label="Deductible Exempt"
                          name="deductible_exempt"
                          value={deductible_exempt}
                          options={dropdownOptions}
                          onChange={this.onFilterChange}
                          stylized
                          disabled={!editMode}
                        />
                      </div>
                      <div className="copay-dropdowns">
                        <FliptDropdown
                          placeholder="Select"
                          label="Out of Pocket Exempt"
                          name="out_of_pocket_exempt"
                          value={out_of_pocket_exempt}
                          options={dropdownOptions}
                          onChange={this.onFilterChange}
                          stylized
                          disabled={!editMode}
                        />
                      </div>
                      {hasPhases && <div className="deductible-dropdown">
                        <FliptDropdown
                          placeholder="Select"
                          label="Deductible Applies"
                          name="deductible_applies"
                          value={deductible_applies}
                          options={dropdownOptions}
                          onChange={this.onFilterChange}
                          stylized
                          disabled={!editMode}
                        />
                      </div>
                      }
                    </section>
                    {this.renderAccordianContent(activePhaseIndex, phases)}
                  </div>
                </Accordion.Content>
              </div>
            ))}
          </Accordion>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  const { fieldDetails } = props
  const tiersField = fieldDetails?.find(({ field, type, options }) => type === 'DATA' && field === 'tiers' && options.length > 0)
  const tiers = tiersField?.options || []

  const phasesField = fieldDetails?.find(({ field }) => field === 'phase')
  const phases = phasesField?.options || []

  const daySupplyField = fieldDetails?.find(({ field }) => field === 'day_supply')
  const daySupplyFieldOptions = daySupplyField?.options || []
  const daySupplyOptions = daySupplyFieldOptions?.map((option, index) => {
    const { value } = option
    return {
      key: index,
      text: value,
      value,
    }
  })

  const networkTierOptions = fieldDetails?.find(({ field }) => field === 'network_tier')?.options || []
  rowCellInfo.network_tier.options = networkTierOptions?.map((code, index) => ({
    key: index,
    text: code.display_name,
    value: code.value,
    map_value: code.map_value,
  }))

  rowCellInfo.network_tier.options.unshift({
    key: 0, text: 'ALL', value: 'ALL', map_value: 'ALL',
  })
  const copayTypeField = fieldDetails?.find(({ field }) => field === 'copay_type')
  const copayTypeFieldOptions = copayTypeField?.options || []

  const copayTypeOptions = copayTypeFieldOptions?.map((option, index) => {
    const { value } = option
    return {
      key: index,
      text: value,
      value,
    }
  })

  rowCellInfo.day_supply.options = daySupplyOptions
  rowCellInfo.copay_type.options = copayTypeOptions
  return ({
    state: {
      app: state.app,
      planLOB: state.planManagement.planLOB,
      tiers: tiers ?? [],
      phases: phases ?? [],
      tiersData: state.planManagement.formularyCopayData,
      effective_end_date: state.planManagement.planDetails.model_data.effective_end_date,
      effective_start_date: state.planManagement.planDetails.model_data.effective_start_date
    },
  })
}
const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...PlanManagementActions,
    ...AppActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

const formularyCopayContainer = (props) => (
  <FormularyCopay
    editMode
    {...props}
  />
)

export default FormularyCopay

export const FormularyCopayContainer = connect(mapStateToProps, mapDispatchToProps)(formularyCopayContainer)