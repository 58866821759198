import React, { useState, useEffect, useRef, useCallback } from "react";
import { ArcherContainer, ArcherElement } from 'react-archer';
import _ from "lodash";

import { Creators as AppActions } from "../../../../redux/reducers/app";
import { Creators as FileConfigurationActions } from "../../../../redux/reducers/api/fileConfiguration";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import { Button, Dropdown, Icon, Table } from "semantic-ui-react";
import Transformation from "./components/Transformation";
import EDI834Renderer from "./components/834";
import FliptButton from "../../../../components/v2/fliptButton";

const FileMapping = (props) => {
  const { state } = props;
  const { fileFormat, mapDirection } = state;
  const fileStandardFields = state.fileStandardFields;
  const [standardToRender, setStandardToRender] = useState(fileStandardFields);
  const [standardSortBy, setStandardSortBy] = useState('default');
  const [linkModal, setLinkModal] = useState(false)
  const [from, setFrom] = useState();
  const [predefined, setPredefined] = useState([])
  const [to, setTo] = useState();
  const [selectedTo, setSelectedTo] = useState('');
  const [selectedFrom, setSelectedFrom] = useState('');

  const [form, setFormData] = useState({});
  const [standardField, setStandardField] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [addingCustomField, setAddingCustomField] = useState(false);
  const customFieldsInitState = {
    custom_header: '',
    custom_preview: '',
    standard_mapping: '',
    valid: false,
  }
  const [customFields, setCustomFields] = useState(customFieldsInitState);

  const oncloseModal = () => {
    setLinkModal(false)
  }
  const openTransformationModal = (item) => {
    setPredefined(item)
    setLinkModal(true)

  }

  useEffect(() => {
    if (props.state.fileStandardFields) {
      const standardField = props.state.fileStandardFields.map(({ field, name, required }, i) => {
        return {
          key: i,
          text: name,
          value: field,
        };
      });
      // standardField.push({
      //   key: standardField.length,
      //   text: 'Custom Field',
      //   value: 'custom',
      // })
      setStandardField(standardField);
    }
  }, [props.state.fileStandardFields])

  useEffect(() => {
    const definedConfigurationList = props.state.definedConfigurationList;
    const fileMapping = Array.isArray(props.state.fileMapping) ? props.state.fileMapping : [];
    if (definedConfigurationList) {
      const parsedData = definedConfigurationList.reduce((allFields, header, i) => {
        const fields = header.fields.map((field) => ({ field_name: field?.field_name, id: field.id }))
        allFields.push(...fields)
        return allFields
      }, []);
      const tableData = parsedData.map((field, i) => {
        const mapping = fileMapping.find(({ id }) => id === field.id) || {};
        return {
          ...mapping,
          key: i,
          field: field.field_name,
          id: field.id,
        }
      });
      setTableData(tableData);
    }
  }, [props.state.definedConfigurationList]);

  useEffect(() => {
    handleStandardSort(standardSortBy)
  }, [props.state.fileMapping])


  const handleSelectedFrom = (id) => {
    if (selectedFrom === id) {
      setSelectedFrom('')
      setSelectedTo('')
      return;
    }
    setSelectedFrom(id)
    const fileMapping = props.state.fileMapping;
    const existingMapping = fileMapping.find(({ map_from_id }) => map_from_id === id)
    if (existingMapping) {
      setSelectedTo(existingMapping.field)
    } else {
      setSelectedTo('')
    }
  }

  const handleSelectTo = (id) => {
    if (!selectedFrom) return;
    // setFrom(selectedFrom)
    setSelectedTo(id)
    toggleMap(id);
  }

  const customMapping = (e, data) => {
    const value = data.value
    if (!value) return;
    const option = props.state.fileStandardFields.find(({ field }) => field === value);
    const { data_type, validation } = option;
    let valid = checkType(customFields.custom_preview, data_type);
    if (validation) {
      const reg = new RegExp(validation, 'g');
      valid = reg.test(customFields.custom_preview) && valid;
    }
    setCustomFields({ ...customFields, standard_mapping: value, valid });
  }

  const saveCustomFields = () => {
    const newTableData = [...tableData];
    newTableData.push({
      key: newTableData.length,
      header: customFields.custom_header,
      mapped: customFields.standard_mapping,
      valid: customFields.valid,
      value: customFields.custom_preview,
    });
    setTableData(newTableData);
    setAddingCustomField(false);
    setCustomFields(customFieldsInitState);
  }

  const isStringANumber = (str) => {
    const num = Number(str);
    return !isNaN(num);
  }

  const checkType = (value, type) => {
    switch (type) {
      case 'number':
        return isStringANumber(value);
      case 'date':
        return moment(value).isValid();
      case 'string':
        return typeof value === 'string';
      default:
        return false;
    }
  }

  const setQualifier = (item, qualifier) => {
    const fileMapping = props.state.fileMapping;
    const existingMapping = fileMapping.find(({ std_field }) => std_field === item.std_field)
    if (!existingMapping) return;
    const newMappedFields = fileMapping.filter(({ std_field }) => std_field !== item.std_field);
    newMappedFields.push({
      ...existingMapping,
      qualifier,
    });
    props.actions.syncMappedFields(newMappedFields);
  }

  const toggleMap = (mapToField) => {
    if (!mapToField) return;
    const fieldStandard = props.state.fileStandardFields.find(({ std_field }) => std_field === mapToField);
    const fileMapping = props.state.fileMapping;
    const clientDefinedField = definedConfigurationList.reduce((clientField, header) => {
      const fields = header?.fields || [];
      const clientFieldFound = fields.find(({ id }) => id === selectedFrom);
      if (clientFieldFound) {
        return {
          ...clientFieldFound,
          parent_header: {
            id: header.id,
            multi_record_detail_title: header.multi_record_detail_title || '',
            import_format: header.import_format,
            record_type: header.record_type,
            multi_record_detail: header.multi_record_detail,
          }
        }
      }
      return clientField;
    }, {});
    // do validation
    const mapping = {
      ...fieldStandard,
      ...clientDefinedField,
      map_from_id: selectedFrom,
    }
    const currentMapping = fileMapping.find(({ map_from_id }) => map_from_id === selectedFrom);
    const newMappedFields = fileMapping.filter(({ id, std_field }) => std_field !== mapToField && id !== clientDefinedField.id);
    if (!currentMapping) {
      newMappedFields.push(mapping);
    } else if (currentMapping && currentMapping.std_field !== mapping.std_field) {
      newMappedFields.push(mapping);
    }
    props.actions.syncMappedFields(newMappedFields);
  }

  const removeMapping = (event, stdFieldToRemoveFromMapping) => {
    if (!stdFieldToRemoveFromMapping) return;
    event.stopPropagation();
    const fileMapping = props.state.fileMapping;
    const newMappedFields = fileMapping.filter(({ std_field }) => std_field !== stdFieldToRemoveFromMapping);
    props.actions.syncMappedFields(newMappedFields);
  }

  const renderRequiredForStandardField = (required, std_field_id) => {
    switch (required) {
      case 'YES':
        const fileMapping = props.state.fileMapping;
        const existingMapping = fileMapping.find(({ std_field }) => std_field === std_field_id)
        if (existingMapping) {
          return (
            <Icon size="small" circular name="check" color="green" />
          )
        }
        return (
          <Icon size="small"  circular name="check" color="black" />
        )
      case 'Conditional':
        return (
          <Icon size="small"  circular name="warning sign" color="yellow" />
        )
      case 'NO':
      default:
        return null
    }
  }

  const renderTransformationLink = (item) => {
    if ((item.allowed_values || []).length === 0) {
      return <Table.Cell />
    }
    const fileMapping = props.state.fileMapping;
    const existingMapping = fileMapping.find(({ std_field }) => std_field === item.std_field)
    if (!existingMapping) {
      return <Table.Cell />
    }
    return (
      <Table.Cell
        onClick={() => openTransformationModal(item)}
        style={{ cursor: 'pointer', color: 'red', textDecoration: 'underline', fontWeight: 'bold' }}
      >
        Transform
      </Table.Cell>
    )
  }

  const getColor = (i) => {
    if (i % 3 === 0) {
      return "#FF0000"
    }
    if (i % 2 === 0) {
      return "#F3CA40"
    }
    return "#222E50"
  }

  const definedConfigurationList = props.state.definedConfigurationList;
  if (!fileStandardFields || fileStandardFields && !fileStandardFields.length) {
    return (
      <div>Loading...</div>
    )
  }

  const mapper = props.state.fileMapping.reduce((map, { map_from_id, std_field }) => {
    if (!map_from_id || !std_field) return map;
    map['from'][map_from_id] = std_field;
    map['to'][std_field] = map_from_id;
    return map
  }, { from: {}, to: {} })


  const renderSource = () => {
    return (
      <section className="mapping-custom-table">
        <Table className="remove-top-margin" style={{ zIndex: 3, position: 'relative' }}>
          {definedConfigurationList.map((item, index) => {
            return (
              <>
                <Table.Header>
                  {item.multi_record_detail_title && (
                    <Table.Row>
                      <Table.HeaderCell colSpan={3}>
                        Column Header from File: {item.multi_record_detail_title}
                      </Table.HeaderCell>
                    </Table.Row>
                  )}
                  <Table.Row>
                    <Table.HeaderCell colSpan={1}>
                    </Table.HeaderCell>
                    <Table.HeaderCell colSpan={1}>
                      Field Information
                    </Table.HeaderCell>
                    <Table.HeaderCell colSpan={1}>
                      Preview Information
                    </Table.HeaderCell>
                    <Table.HeaderCell colSpan={1} />
                  </Table.Row>
                </Table.Header>
                <Table.Body className="mapping-custom-fields">
                  {item.fields.map((field, i) => {
                    return (
                      <>
                        <Table.Row
                          active={field.id === selectedFrom}
                          className={field.id === selectedFrom ? ["mapping-custom-field-selected", field.id] : ["mapping-custom-field", field.id]}
                          key={field.id}
                          onClick={() => handleSelectedFrom(field.id)}
                        >

                          {mapDirection ? <div /> : (
                            <ArcherElement
                              id={field.id}
                              relations={[
                                {
                                  targetId: mapper['from'][field.id],
                                  targetAnchor: mapDirection ? 'left' : 'right',
                                  sourceAnchor: mapDirection ? 'right' : 'left',
                                  style: {
                                    strokeColor: getColor(i),
                                    strokeWidth: selectedFrom === field.id ? 2.5 : 1.5,
                                    order: i,
                                  },
                                }
                              ]}
                            >
                              <div />
                            </ArcherElement>
                          )}
                          <Table.Cell singleLine>
                            {field.field_name}
                          </Table.Cell>
                          {field.info ? (
                            <Table.Cell
                              className="mapping-custom-preview"
                            >
                              <span>{field.info}</span>
                            </Table.Cell>
                          ) : <Table.Cell />}
                          <Table.Cell>
                            {mapDirection ? <ArcherElement
                              id={field.id}
                              relations={[
                                {
                                  targetId: mapper['from'][field.id],
                                  targetAnchor: mapDirection ? 'left' : 'right',
                                  sourceAnchor: mapDirection ? 'right' : 'left',
                                  style: {
                                    strokeColor: getColor(i),
                                    strokeWidth: selectedFrom === field.id ? 2.5 : 1.5,
                                    order: i,
                                  },
                                }
                              ]}
                            >
                              <span />
                            </ArcherElement> : (<span />)
                            }
                          </Table.Cell>
                        </Table.Row>
                      </>
                    );
                  })}
                </Table.Body>
              </>
            )
          })}
        </Table>
      </section>
    )
  }

  const handleStandardSort = (action) => {
    let standardToSet = fileStandardFields;
    if (action === 'default') {
      standardToSet = fileStandardFields;
    }
    if (action === 'mapped') {
      standardToSet = _.orderBy(
        fileStandardFields,
        (field) => mapper['to'][field.std_field] ? 1 : 0,
        ['desc']
      );
    }
    if (action === 'unmapped') {
      standardToSet = _.orderBy(
        fileStandardFields,
        (field) => mapper['to'][field.std_field] ? 1 : 0,
        ['asc']
      );
    }
    if (action === 'ordered') {
      const flatConfigList = _.flatMap(definedConfigurationList, ({ fields }) => fields);
      const [mapped, unOrderedMapped] = _.partition(fileStandardFields, (field) => mapper['to'][field.std_field]);
      const unmapped = unOrderedMapped.sort((a, b) => {
        if (a.std_field < b.std_field) { return 1; }
        if (a.std_field > b.std_field) { return -1; }
        return 0;
      })
      const orderedMap = [];
      for (let i = 0; true; i++) {
        const { id } = flatConfigList[i] || {};
        if (!id) {
          orderedMap.push(...unmapped);
          break;
        }
        const toField = mapper['from'][id]
        if (mapper['from'][id]) {
          const field = mapped.find(({ std_field }) => std_field === toField);
          if (field) {
            orderedMap[i] = field;
            continue;
          }
        }
        orderedMap[i] = unmapped.pop();
      }
      standardToSet = orderedMap;
    }
    setStandardToRender(standardToSet)
  }

  const renderSortDropdown = () => {
    return (
      <Dropdown text={`Sort: ${standardSortBy}`}>
        <Dropdown.Menu>
          <Dropdown.Item
            text='Default'
            onClick={() => {
              setStandardSortBy('default')
              handleStandardSort('default')
            }} />
          <Dropdown.Item
            text='Mapped'
            onClick={() => {
              setStandardSortBy('mapped')
              handleStandardSort('mapped')
            }} />
          <Dropdown.Item
            text='Unmapped'
            onClick={() => {
              setStandardSortBy('unmapped')
              handleStandardSort('unmapped')
            }} />
          <Dropdown.Item
            text='Ordered'
            onClick={() => {
              setStandardSortBy('ordered')
              handleStandardSort('ordered')
            }} />
        </Dropdown.Menu>
      </Dropdown>
    )
  }


  const renderStandard = () => {
    return (
      <section className="mapping-custom-container">
        <Table className="remove-top-margin" style={{ zIndex: 3 }}>
          <Table.Header className="standard-table-header">
            <Table.Row>
              <Table.HeaderCell colSpan={1}>
                Field Name
                {renderSortDropdown()}
              </Table.HeaderCell>
              <Table.HeaderCell colSpan={1}>
                Required
              </Table.HeaderCell>
              <Table.HeaderCell colSpan={1}>
                Mapped
              </Table.HeaderCell>
              <Table.HeaderCell colSpan={1} >
                Transformation Builder
              </Table.HeaderCell>
              {mapDirection ? (null) : (
                <Table.HeaderCell colSpan={1} />
              )}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {standardToRender.map((item, index) => {
              return (
                <>
                  <Table.Row
                    key={item.std_field + '-' + index}
                    className={`unmap-container ${item.std_field}`}
                    active={item.std_field === selectedTo}
                  >
                    <Table.Cell
                      className={selectedFrom ? "mapping-standard-field" : ""}
                      onClick={() => handleSelectTo(item.std_field)}
                      singleLine
                    >
                      {mapper['to'][item.std_field] && mapDirection && (
                        <div className="unmap-area fade-in">
                          <FliptButton
                            className="unmap-button"
                            onClick={(e) => removeMapping(e, item.std_field)}
                          >
                            ❌
                          </FliptButton>
                        </div>
                      )}
                      {mapDirection ? (
                        <ArcherElement id={item.std_field}>
                          <span>{item.std_field_name}</span>
                        </ArcherElement>
                      ) : (
                        <span>{item.std_field_name}</span>
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {renderRequiredForStandardField(item.required, item.std_field)}
                    </Table.Cell>
                    <Table.Cell>
                      {mapper['to'][item.std_field] ? (
                        <Icon name="check circle" color="green" />
                      ) : (
                        <Icon name="warning sign" color="yellow" />
                      )}
                    </Table.Cell>
                    {renderTransformationLink(item)}
                    {mapDirection ? (null) : (

                      <Table.Cell>
                        <ArcherElement id={item.std_field}>
                          <span />
                        </ArcherElement>
                      </Table.Cell>
                    )}
                  </Table.Row>
                </>
              )
            })}
          </Table.Body>

        </Table>
      </section>
    )
  }

  const renderDefaultMapping = () => {
    if (mapDirection) {
      return (
        <div className="mapping-container">
          {renderSource()}
          {renderStandard()}
        </div>
      )
    } else {
      return (
        <div className="mapping-container">
          {renderStandard()}
          {renderSource()}
        </div>
      )
    }
  }
  const renderMapping = () => {
    switch (fileFormat) {
      default:
        return (
          <>
            <div className="section-header">File Mapping</div>
            <ArcherContainer
              offset={12}
              endMarker={true}
            >
              {renderDefaultMapping()}
            </ArcherContainer>
            {linkModal && <Transformation open={linkModal} onClose={oncloseModal} predefined={props.state.fileMapping.find(({ std_field }) => std_field === predefined.std_field)} setQualifier={setQualifier} />}
          </>
        )
    }

  }

  return (
    <div id="file-configuration" >
      {renderMapping()}
    </div>
  );
};

const mapStateToProps = (state) => {
  const fileType = state.fileConfiguration.file_type
  const fileStandardKey = fileType + '_standard'
  const standard = state.fileConfiguration[fileStandardKey]
  return {
    state: {
      app: state.app,
      planDetails: state.planManagement?.planDetails,
      fileFormat: state.fileConfiguration?.importFormat?.import_file_type,
      fileMapping: state.fileConfiguration.fileMapping,
      fileStandardFields: standard?.fields,
      uploadedFileData: state.fileConfiguration.uploadedFileData,
      definedConfigurationList: state.fileConfiguration.importFormat?.defined_configuration_list,
      mapDirection: state.fileConfiguration.map_direction || true,
    },
  }
};

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
    ...FileConfigurationActions,
  };
  return {
    actions: bindActionCreators(allActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FileMapping);
