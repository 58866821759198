import Immutable from 'seamless-immutable'
import { createActions, createReducer } from 'reduxsauce'

export const INITIAL_STATE = Immutable({
  accounts: [],
  accountDetails: null,
  accountDetailsFilters: {
    account: null,
    group: null,
    comapny: null,
  },
  newAccountDetails: null,
})

export const setAccountsReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  accounts: payload.data,
})

export const setSelectedAccountReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  accountDetails: payload.data,
})

export const setNewAccountReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  newAccountDetails: payload.data,
})

export const setFiltersReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  accountDetailsFilters: {
    ...state.accountDetailsFilters,
    [payload.data.filterName]: payload.data.filterValue,
  },
})

export const clearReducer = () => INITIAL_STATE

export const { Types, Creators } = createActions({
  addNewAccount: null,
  clearReducer: null,
  getAccounts: null,
  getNewAccount: null,
  saveNewAccount: ['payload'],
  setAccounts: ['payload'],
  setFilters: ['payload'],
  setNewAccount: ['payload'],
  setSelectedAccount: ['payload'],
})

const HANDLERS = {
  [Types.CLEAR_REDUCER]: clearReducer,
  [Types.SET_ACCOUNTS]: setAccountsReducer,
  [Types.SET_FILTERS]: setFiltersReducer,
  [Types.SET_SELECTED_ACCOUNT]: setSelectedAccountReducer,
  [Types.SET_NEW_ACCOUNT]: setNewAccountReducer,
}

export default createReducer(INITIAL_STATE, HANDLERS)
