export const rebateFactorType = [
  {
    key: 0,
    text: "Flat",
    value: "flat"
  },
  {
    key: 1,
    text: "Percentage",
    value: "percentage"
  }
];

export const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']