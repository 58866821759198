export const defaultTabConfigs = [
    {
        tabName: 'DUR Programs',
        id: 'DUR Programs',
    },
    {
        tabName: 'Drug to Drug Interaction',
        id: 'Drug to Drug Interaction',
    },
    {
        tabName: 'Duplicate Rx',
        id: 'Duplicate Rx',
    },
    {
        tabName: 'Duplicate Therapy',
        id: 'Duplicate Therapy',
    },
    {
        tabName: 'Dosage Restriction',
        id: 'dosage_restriction',
    },
    {
        tabName: 'Age/Gender Restriction',
        id: 'Age/Gender Restriction',
    },
    {
        tabName: 'Regimen Compliance',
        id: 'Regimen Compliance',
    },
]

export const programHeaders = [
    'program_action',
    'program_name',
    'program_version',
    'program_status',
    'program_create_date'
]

export const drugHeaders = [
    'action',
    'doc_name',
    'dur_type',
    'status',
    'doc_version',
    'create_date'
]

export const regimenComplianceHeaders = [
    'action',
    'doc_name',
    'dur_type',
    'status',
    'doc_version',
    'create_date'
]

export const DUR_LOCAL_STORAGE_KEY = 'cmTabs'
