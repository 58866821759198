import React from 'react'
import '../styles.scss'
import rowCellInfo from '../data/rowCellInfo'
import FliptGrid from '../../../../../../components/fliptGrid'
import FliptInput from '../../../../../../components/form/fliptInput'
import FliptDatePicker from '../../../../../../components/form/fliptDatePicker'
import AddRow from '../addRow'
import { DeleteRowRenderer, MultiRowRenderer } from '../../../../../../components/fliptGrid/cellRenderers'
import {
  InputTextEditor, DropdownEditor, NestedInputEditor, DatePickerEditor,
} from '../../../../../../components/fliptGrid/cellEditors'
import { convertStrToDateObj } from '../../../../../../utils/utilities'
import {
  NestedRowRenderer
} from '../../../../../../components/fliptGrid/cellRenderers'
import FliptDropdown from '../../../../../../components/form/fliptDropdown'
import tierTypes from '../../../pharmacyNetwork/createNetwork/data/tierTypes'
import { Accordion, Button, Icon } from 'semantic-ui-react'
import AssignPharamcies from '../../../assignPharmacies'
import feeTypes from '../data/feeTypes'
import FliptCheckbox from '../../../../../../components/form/fliptCheckbox'
import moment from 'moment'
import FliptHierarchyDropdown from '../../../../../../components/form/fliptHierarchyDropdown'
import FliptPaDropdown from '../../../../../../components/form/fliptPaDropdown'

export default function TierConditions(props) {
  const {
    form,
    tierConditions,
    updateFields,
    handleChange,
    addRow,
    delRow,
    editMode,
    dropdownOptions,
    specialtyDrug,
    claim_processor,
    chainCode,
    addConditionLevel,
    corpName,
    handlePharmacyConditionChange,
    pharmacyConditions,
    activeConditionLevel,
    handleConditionLevelClick,
    addPharmacyCondition,
    removePharmacyConditionRow,
    removeConditionLevel,
    generateAssignedPharmacy,
    setForm
  } = props
  const {
    tier_name, tier_version, effective_start_date, version_effective_date, network_tier_type, preferred_tier, display_on_top, no_bill_no_pay
  } = form
  const displayonTopOptions = ['Yes', 'No'].map(e => ({
    key: e,
    text: e,
    value: e,
  })) || []
  if (editMode) {
    rowCellInfo.action = {
      type: 'deleteRow',
      disabled: false,
    }
    Object.keys(rowCellInfo).forEach((key) => { rowCellInfo[key].disabled = false })
    rowCellInfo.specialty_drug_list.options = specialtyDrug?.map(e => ({
      key: e.doc_id,
      text: e.doc_name,
      value: e.doc_name,
    })) || []
    rowCellInfo.claim_processor.options = claim_processor
  } else {
    delete rowCellInfo.action
    Object.keys(rowCellInfo).forEach((key) => { rowCellInfo[key].disabled = true })
  }
  const headers = Object.keys(rowCellInfo)
  if (headers.includes('action')) {
    headers.splice(headers.indexOf('action'), 1)
    headers.unshift('action')
  }
  const _getRowHeight = (params) => {
    const unitRowHeight = 42
    const currentRow = _.isEmpty(params?.data?.fee_name) ? ['dummy'] : params?.data?.fee_name
    return unitRowHeight * currentRow.length
  }

  const displayDiscount = (param) => {
    const { data } = param
    const prefix = data?.rate_type === 'POSITIVE' ? '+' : '-'
    const postfix = data?.fixed_variable === 'Variable' ? '%' : ''
    return `${prefix}${param.value}${postfix}`
  }

  const displayFeeValue = (param) => {
    const { data, value } = param
    const keyArr = value && Object.keys(value)
    keyArr?.length && keyArr.forEach((key) => {
      if (data?.fee_type[key] === 'VARIABLE') {
        const newValue = value[key]?.toString().replace('%', '')
        value[key] = `${newValue}%`
      }
    })
    return value
  }

  const onSpecialtyChange = (el, dropdown, rowIndex, gridApi, level) => {
    const { name, value, id } = dropdown
    const list = specialtyDrug.filter(e => value.includes(e.doc_name)).map(e => e.doc_id)
    const version = specialtyDrug.filter(e => value.includes(e.doc_name)).map(e => e.doc_version)
    handleChange(el, { name: 'specialty_drug_list', value }, rowIndex, gridApi, level)
  }

  const onValueChange = (el, dropdown, rowIndex, gridApi, level) => {
    const { name, value } = { ...el, ...dropdown }
    handleChange(el, dropdown, rowIndex, gridApi, level)
    if (name === 'price_type' && value !== 'MAC') {
      setTimeout(() => {
        handleChange(null, { name: 'mac_list_id', value: '' }, rowIndex, gridApi, level)
      }, 100)
    }
    if (name === 'rate_type' || name === 'fixed_variable') {
      _.debounce(() => {
        gridApi.redrawRows()
      }, 1000)()
    }
  }
  const cellRendererParams = {
    action: {
      cellRenderer: DeleteRowRenderer,
      state: {
        onClick: delRow,
      },
      width: 95,
    },
    claim_processor: {
      overrideHeader: 'Contract',
    },
    price_type: {
      overrideHeader: 'Price Type',
    },
    specialty_drug_list: {
      overrideHeader: 'Drug Listing',
    },
    min_days_supply: {
      overrideHeader: 'Min Days Supply',
    },
    max_days_supply: {
      overrideHeader: 'Max Days Supply',
    },
    fixed_variable: {
      overrideHeader: 'Fixed/Variable',
    },
    discount: {
      overrideHeader: 'Discount',
      valueFormatter: (params) => {
        return displayDiscount(params)
      }
    },
    rate_type: {
      overrideHeader: 'Rate Type',
    },
    fee_name: {
      cellRenderer: MultiRowRenderer,
    },
    fee_type: {
      cellRenderer: MultiRowRenderer,
    },
    fee_value: {
      cellRenderer: MultiRowRenderer,
      valueFormatter: (data, item) => {
        return displayFeeValue(data, item)
      }
    },
    mac_list_id: {
      overrideHeader: 'MACLISTID',
    },
    price_floor: {
      overrideHeader: 'Floor',
    },
    effective_start_date: {
      valueFormatter: (params) => {
        if (!params.value) return ''
        return moment(params.value)?.format('YYYY-MM-DD') || params.value
      },
    },
    effective_end_date: {
      valueFormatter: (params) => {
        if (!params.value) return ''
        return moment(params.value)?.format('YYYY-MM-DD') || params.value
      },
    }
  }

  const parseData = ({ data, row }) => {
    const values = Object.values(data)
    const keys = !(_.isEmpty(data)) ? Object.keys(data) : row.fee_name
    return keys?.length == 0 ? [] : keys?.map(key => {
      return {
        onChange: onValueChange,
        validation: 'numeric',
        multiValData: tierConditions[activeConditionLevel],
        isMultiRow: false,
        value: values[key] || 0,
        colDef: { headerName: key, field: 'fee_value', id: key },
        disabled: false,
      }
    })
  }

  const parseDropdownData = ({ data, row }) => {
    const values = Object.values(data)
    const keys = !(_.isEmpty(data)) ? Object.keys(data) : row.fee_name
    return keys?.length == 0 ? [] : keys?.map(key => {
      return {
        onChange: onValueChange,
        options: feeTypes,
        isMultiRow: true,
        value: values[key] || 0,
        colDef: { headerName: key, field: 'fee_type', id: key },
        disabled: false,
      }
    })
  }

  const cellEditorParams = {
    claim_processor: {
      cellEditor: DropdownEditor,
      onChange: onValueChange,
    },
    price_type: {
      cellEditor: DropdownEditor,
      onChange: onValueChange,
    },
    drug_type: {
      cellEditor: DropdownEditor,
      onChange: onValueChange,
    },
    fixed_variable: {
      cellEditor: DropdownEditor,
      onChange: onValueChange,
    },
    discount: {
      cellEditor: InputTextEditor,
      onChange: onValueChange,
      validation: 'numeric',
    },
    fee_type: {
      cellEditor: NestedInputEditor,
      editorParams: {
        EditorComponent: DropdownEditor,
        elementList: parseDropdownData,
        key: 'fee_type',
        forceResetHeight: true,
        validation: false,
        value: 0,
      },
    },
    fee_name: {
      cellEditor: DropdownEditor,
      onChange: onValueChange,
      isMultiRow: true,
      multiple: true,
    },
    fee_value: {
      cellEditor: NestedInputEditor,
      editorParams: {
        EditorComponent: InputTextEditor,
        elementList: parseData,
        key: 'fee_value',
        forceResetHeight: true,
        validation: false,
        value: 0,
      },
    },
    specialty_drug_list: {
      cellEditor: DropdownEditor,
      onChange: onSpecialtyChange,
    },
    min_days_supply: {
      cellEditor: InputTextEditor,
      onChange: onValueChange,
      validation: (value, data, api) => {
        if (parseInt(value) < parseInt(data?.max_days_supply || 0))
          return null
        return 'Should Not be greater than max days supply'
      }
    },
    max_days_supply: {
      cellEditor: InputTextEditor,
      onChange: onValueChange,
      validation: (value, data, api) => {
        if (parseInt(value) > parseInt(data?.min_days_supply || 0))
          return null
        return 'Should Not be less than min days supply'
      }
    },
    rate_type: {
      cellEditor: DropdownEditor,
      onChange: onValueChange,
    },
    mac_list_id: {
      cellEditor: InputTextEditor,
      onChange: onValueChange,
      disabled: true,
      isDisabled: function (data) {
        const { price_type = '' } = data
        return price_type !== 'MAC'
      },
    },
    price_floor: {
      cellEditor: InputTextEditor,
      onChange: onValueChange,
      validation: 'numeric',
    },
    effective_start_date: {
      cellEditor: DatePickerEditor,
      onChange: onValueChange,
    },
    effective_end_date: {
      cellEditor: DatePickerEditor,
      onChange: onValueChange,
    },
  }
  const yesNoOptions = [{
    key: 0,
    text: 'Yes',
    value: true
  },
  {
    key: 1,
    text: 'No',
    value: false
  }]
  const agGridRef = React.createRef();
  return (
    <div className="section">
      <FliptHierarchyDropdown form={form} setForm={setForm} showHierarchyGlobal={true} />

      <section className="tier-inputs-container spacing border-line shadow">
        <div className='tier-row'>
          <div className="tier-inputs">
            <span>Network Tier Name</span>
            <FliptInput placeholder="Tier Name" name="tier_name" value={tier_name} disabled={!editMode} onChange={updateFields} />
          </div>
          <div className="tier-inputs">
            <span>Network Tier Type</span>
            <FliptDropdown options={tierTypes} name="network_tier_type" value={network_tier_type} readOnly={!editMode} onChange={updateFields} />
          </div>
          <div className="tier-inputs">
            <span>Tier Version</span>
            <FliptInput name="tier_version" value={tier_version} disabled onChange={updateFields} />
          </div>
        </div>
        <div className='tier-row'>
          <div className="tier-inputs">
            <span>Start Date</span>
            <FliptDatePicker className="create-tier-start-date" name="effective_start_date" value={convertStrToDateObj(effective_start_date)} disabled={!editMode} onChange={updateFields} />
          </div>
          {/* <div className="tier-inputs">
            <span>Version Effective Date</span>
            <FliptDatePicker className="create-tier-start-date" name="version_effective_date" value={convertStrToDateObj(version_effective_date)} disabled={!editMode} onChange={updateFields} />
          </div> */}
          <FliptPaDropdown stylized label='No Bill No Pay' value={no_bill_no_pay} name='no_bill_no_pay' readOnly={!editMode} options={yesNoOptions} onChange={updateFields} />
          {network_tier_type === 'Pricing' ?
            <FliptPaDropdown stylized label='Preferred Tier' value={preferred_tier} name='preferred_tier' readOnly={!editMode} options={yesNoOptions} onChange={updateFields} />
            : ''}
          {network_tier_type === 'Pricing' ? <div className="tier-inputs no-grow">
            <span>Display on Top</span>
            <FliptDropdown options={displayonTopOptions} name="display_on_top" value={display_on_top} readOnly={!editMode} onChange={updateFields} />
          </div> : ''}
        </div>
      </section>
      <div>
        <div className="header">
          <div className="headerContiner">
            <div className="headerText" />
            <div className="headerAction">
              <Button size="small" className="addBtn" onClick={() => editMode ? addConditionLevel() : null}>
                <Icon name="add" />
                Add New Pharmacy Condition Level
              </Button>
            </div>
          </div>
        </div>
      </div>
      {network_tier_type === 'Pricing' ? (
        <div className="section assignPharmacy">
          <div className="content" id="assignPharmacyContent">
            <div className="pa-condition-levels">
              <Accordion className="pa-condition-levels-accordion" styled>
                {(tierConditions || []).map((condition, level) => {
                  const conditionLevelHeading = `Pharmacy Condition Level ${level + 1}`
                  return (
                    <div className="pa-condition-level">
                      <Accordion.Title
                        active={activeConditionLevel === level}
                        index={level}
                        onClick={handleConditionLevelClick}
                      >
                        <Icon name="dropdown" />
                        {conditionLevelHeading}
                        <Icon
                          onClick={() => editMode && removeConditionLevel(level)}
                          className="pa-condition-level-delete"
                          name="delete"
                        />
                      </Accordion.Title>
                      <Accordion.Content active={activeConditionLevel === level}>
                        <div className="pa-conditions">
                          <FliptGrid
                            data={condition}
                            agGridRef={agGridRef}
                            key={`pricing-setup-${level}-${activeConditionLevel}`}
                            headers={headers}
                            getRowHeight={_getRowHeight}
                            cellRendererParams={cellRendererParams}
                            cellEditorParams={editMode && cellEditorParams}
                            rowCellInfo={editMode && rowCellInfo}
                            dropdownOptions={dropdownOptions[level]}
                            stepLevel={level}
                          />
                        </div>
                        <div className="addRowButtonContainer">
                          {!!editMode && (
                            <AddRow addRow={() => addRow(level, agGridRef)} />
                          )}
                        </div>
                        <br />
                        <AssignPharamcies
                          editMode={editMode}
                          chainCode={chainCode}
                          corpName={corpName}
                          networkTierType={network_tier_type}
                          specialtyDrug={specialtyDrug}
                          claim_processor={claim_processor}
                          conditionLevels={pharmacyConditions}
                          activeConditionLevel={level}
                          handleChange={handlePharmacyConditionChange}
                          addPharmacyCondition={addPharmacyCondition}
                          removePharmacyConditionRow={removePharmacyConditionRow}
                          generateAssignedPharmacy={generateAssignedPharmacy}
                        />
                      </Accordion.Content>
                    </div>
                  )
                })}
              </Accordion>
            </div>
          </div>
        </div>
      ) : (
        <div className="section assignPharmacy">
          <div className="content" id="assignPharmacyContent">
            <div className="pa-condition-levels">
              <Accordion className="pa-condition-levels-accordion" styled>
                {(tierConditions || []).map((condition, level) => {
                  const conditionLevelHeading = `Pharmacy Condition Level ${level + 1}`
                  return (
                    <div className="pa-condition-level">
                      <Accordion.Title
                        active={activeConditionLevel === level}
                        index={level}
                        onClick={handleConditionLevelClick}
                      >
                        <Icon name="dropdown" />
                        {conditionLevelHeading}
                        <Icon
                          onClick={() => editMode && removeConditionLevel(level)}
                          className="pa-condition-level-delete"
                          name="delete"
                        />
                      </Accordion.Title>
                      <Accordion.Content active={activeConditionLevel === level}>
                        {/* <div className="pa-conditions">
                        <FliptGrid
                          data={condition}
                          agGridRef={agGridRef}
                          key={`pricing-setup-${level}-${activeConditionLevel}`}
                          headers={headers}
                          getRowHeight={_getRowHeight}
                          cellRendererParams={cellRendererParams}
                          cellEditorParams={editMode && cellEditorParams}
                          rowCellInfo={editMode && rowCellInfo}
                          dropdownOptions={dropdownOptions[level]}
                          stepLevel={level}
                        />
                      </div>
                      <div className="addRowButtonContainer">
                        {!!editMode && (
                          <AddRow addRow={() => addRow(level, agGridRef)} />
                        )}
                      </div>
                      <br /> */}
                        <AssignPharamcies
                          editMode={editMode}
                          chainCode={chainCode}
                          corpName={corpName}
                          networkTierType={network_tier_type}
                          specialtyDrug={specialtyDrug}
                          claim_processor={claim_processor}
                          conditionLevels={pharmacyConditions}
                          activeConditionLevel={level}
                          handleChange={handlePharmacyConditionChange}
                          addPharmacyCondition={addPharmacyCondition}
                          removePharmacyConditionRow={removePharmacyConditionRow}
                          generateAssignedPharmacy={generateAssignedPharmacy}
                        />
                      </Accordion.Content>
                    </div>
                  )
                })}
              </Accordion>
            </div>
          </div>
        </div>
      )}
      <div>
      </div>
    </div>
  )
}
