import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import './styles.scss'
import FliptPaInput from '../../../../components/form/fliptPaInput'


class RejectMessageModal extends Component {
    constructor(props) {
        super(props)
    }


    render() {
        // changes for custom drug
        const { testClaimData } = this.props
        return (
            <div id="rejectModal">
                <div className="section-header"> Rejection Response</div>
                <div className="content">
                    <div className="fields-container">
                        <FliptPaInput className="less-margin-right" value={testClaimData?.reject_code} stylized disabled label="Flipt Reject Code" />
                        <FliptPaInput className="less-margin-right" value={testClaimData?.message} stylized disabled label="Flipt Reject Message 1" />
                        <FliptPaInput value='' stylized disabled label="Flipt Reject Message 1" />
                    </div>
                    <div className="fields-container">
                        <FliptPaInput className="less-margin-right" value={testClaimData?.other_payer_reject_code} stylized disabled label="Payor Reject Code" />
                        <FliptPaInput className="less-margin-right" value='' stylized disabled label="Payor Reject Message 1" />
                        <FliptPaInput value='' stylized disabled label="Payor Reject Message 2" />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    state: {
    },
})

const mapDispatchToProps = (dispatch) => {
    const allActions = {
    }

    return {
        actions: bindActionCreators(allActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RejectMessageModal)
