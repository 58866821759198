import React from "react"
import NewFliptInput from "../../../../../components/v2/fliptInput"
import "./styles.scss"
import NewFliptUploadInput from "../../../../../components/v2/fliptUploadInput"

const BasicInformation = (props) => {
	const { updateFields, questionnaireData } = props

	return (
		<div id="BasicInformation">
			<div className="fields-main-container">
				<div className="fields-inner-container">
					<NewFliptInput value="Global" label="Type" disabled />
					<NewFliptInput
						value={questionnaireData.questionnaire_name}
						onChange={updateFields}
						name="questionnaire_name"
						label="Questionnaire Name"
					/>
					<NewFliptInput
						value={questionnaireData.questionnaire_policy}
						onChange={updateFields}
						name="policy"
						label="Policy"
					/>
				</div>
				<div className="fields-inner-container">
					<NewFliptInput
						inputClassName="description-input"
						value={questionnaireData.questionnaire_description}
						onChange={updateFields}
						name="description"
						label="Description"
						placeholder="Enter a description..."
					/>
				</div>
				<div className="fields-inner-container">
					<div className="container">
						<div className="sub-container">
							<NewFliptInput
								value={questionnaireData.effective_date}
								onChange={updateFields}
								name="effective_date"
								label="Effective Date"
							/>
							<NewFliptInput
								value={questionnaireData.term_date}
								onChange={updateFields}
								name="term_date"
								label="Term Date"
							/>
						</div>
						<NewFliptInput
							value={
								questionnaireData.question_set_contact_communication_number
							}
							onChange={updateFields}
							name="question_set_contact_communication_number"
							label="Contact Communication Number"
						/>
					</div>
					<div className="fields-inner-container">
						<NewFliptUploadInput label="Upload Questionnaire" />
					</div>
				</div>
				<div className="fields-inner-container">
					<NewFliptInput
						value={questionnaireData.attachment_required}
						onChange={updateFields}
						name="attachment_required"
						label="Attachment Required"
					/>
					<NewFliptInput
						value={questionnaireData.questionnaire_category}
						onChange={updateFields}
						name="questionnaire_category"
						label="Category"
					/>
				</div>
				<div className="fields-inner-container">
					<div className="fields-inner-container">
						<NewFliptUploadInput label="Fax Form" />
					</div>
					<div className="container">
						<div className="sub-container">
							<NewFliptInput
								value={questionnaireData.initial_approval_period}
								onChange={updateFields}
								name="initial_approval_period"
								label="Initial Approval Period"
							/>
							<NewFliptInput
								value={questionnaireData.recertification_approval_period}
								onChange={updateFields}
								name="recertification_approval_period"
								label="Recertification Approval Period"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default BasicInformation
