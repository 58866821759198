import React, { useState } from 'react'
import { Accordion, Icon } from 'semantic-ui-react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import './styles.scss'
import FliptGrid from '../../../../components/fliptGrid'
import { Creators as UserActions } from '../../../../redux/reducers/api/formularyCreationManagement'
import { filterRowData } from '../../../../utils/utilities'
import { opioidHeaders } from '../../drugUtilizationReview/create/gridData/data'
import { defaultTabConfigs } from '../tabData'
import { opioidTabConfigs } from '../../opioidManagement/tabData'
import DrugToDrugInteractionGrid from '../create/gridData/drugToDrugInteractionGrid'
import DuplicateRxGrid from '../create/gridData/duplicateRxGrid'
import DuplicateTherapyGrid from '../create/gridData/duplicateTherapyGrid'
import DosageGrid from '../create/gridData/dosageGrid'
import AgeAndGenderGrid from '../create/gridData/ageAndGenderGrid'
import OpioidGrid from '../create/gridData/opioidGrid'
import RegimenComplianceGrid from '../create/gridData/regimenComplianceGrid'

const GeneratedDurProgram = (props) => {
    const [activeTierLevel, setActiveTierLevel] = useState(0)
    const { programOptionData, programName } = props

    const accData = programName.map((data) => {
        const index = programOptionData.findIndex((pData) => pData.doc_id === data)
        if (index >= 0) return (programOptionData[index])
    })

    if (!accData?.length || !programOptionData?.length || !programName?.length) return (<div />)

    const _handleTierLevelClick = (e, titleProps) => {
        const { index } = titleProps
        const newIndex = activeTierLevel === index ? -1 : index
        setActiveTierLevel(newIndex)
    }

    const renderGrid = (tierData, durType) => {
        if (!programOptionData?.length) return
        if (durType === defaultTabConfigs[1].id) return <DrugToDrugInteractionGrid data={tierData} viewOnly={true} />
        if (durType === defaultTabConfigs[2].id) return <DuplicateRxGrid data={tierData} viewOnly={true} />
        if (durType === defaultTabConfigs[3].id) return <DuplicateTherapyGrid data={tierData} viewOnly={true} />
        if (durType === defaultTabConfigs[4].id) return <DosageGrid data={tierData} viewOnly={true} />
        if (durType === defaultTabConfigs[5].id) return <AgeAndGenderGrid data={tierData} viewOnly={true} />
        if (durType === defaultTabConfigs[6].id) return <RegimenComplianceGrid data={tierData} viewOnly={true} />
        if (durType === opioidTabConfigs[1].id) return <OpioidGrid data={tierData} viewOnly={true} />
    }

    return (
        <Accordion className="section-tier-levels-accordion" styled>
            {
                accData.map((tier, idx) => {
                    const tierLevelHeading = `${tier.doc_name} v${tier.doc_version}`
                    return (
                        <div className="section-tier-level">
                            <Accordion.Title
                                active={activeTierLevel === idx}
                                index={idx}
                                onClick={_handleTierLevelClick}
                            >
                                <Icon name="dropdown" />
                                {tierLevelHeading}
                            </Accordion.Title>
                            <Accordion.Content
                                active={activeTierLevel === idx}
                            >
                                <div className="section-tier-conditions">
                                    {renderGrid(tier, tier.dur_type)}
                                </div>
                            </Accordion.Content>
                        </div>
                    )
                })
            }
        </Accordion>

    )
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...UserActions,
    }

    return {
        actions: bindActionCreators(allActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GeneratedDurProgram)
