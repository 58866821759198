
import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Creators as PriorAuthorizationCreationActions } from '../../../../redux/reducers/api/priorAuthorizationManagement'

import './styles.scss'
import { Creators as AppActions } from '../../../../redux/reducers/app'
import FliptGrid from '../../../../components/fliptGrid'
import { convertDateTimeToDate } from '../../../../utils/utilities'
import { Icon } from 'semantic-ui-react'
class PaReport extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    componentDidMount() {
        const { actions, member_id } = this.props
        actions.getRxTabData({ member_id, optimize: true })
    }
    closeModal = () => {
        const { actions } = this.props
        actions.toggleSideDrawer()
    }
    render() {
        const { state: { rxTabData } } = this.props
        const rxHistoryHeader = ['pa_case_id', 'pa_case_status', 'case_type', 'gpi', 'drug_name', 'dosage', 'dosage_strength']

        const cellRendererParams = {
            pa_case_id: {
                width: '130px'
            },
            override_type: {
                width: '120px'
            },
            status: {
                width: '120px'
            },
            drug_name: {
                width: '120px'
            },
            form: {
                width: '120px'
            },
            strength: {
                width: '120px'
            },
        }

        return (
            <div id="PaReport">
                <div className='header-container'>
                    <span className='header-text'>Member Historical Prior Authorizations</span>
                    <Icon onClick={() => this.closeModal()} name='close' color='grey' />
                </div>
                <div className='grid'>
                    <FliptGrid
                        data={rxTabData?.data?.pa_case_data || []}
                        headers={rxHistoryHeader}
                        cellRendererParams={cellRendererParams}
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    state: {
        rxTabData: state.priorAuthorizationCreation.rxTabData,
    },
})

const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...AppActions,
        ...PriorAuthorizationCreationActions,
    }

    return {
        actions: bindActionCreators(allActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaReport)
