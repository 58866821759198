import React, { useState, useEffect } from "react"
import FliptButton from '../../../../components/form/fliptButton'
// import { useAuth0 } from "@auth0/auth0-react"

export default function SSOLogin (props) {
	const {
		userSSOLogin
	} = props

	return (
		<div className="sso-login">
			<FliptButton
				className="sso-login-button"
				id="ssoLoginButton"
				onClick={() => userSSOLogin()}
				name="Log in with Microsoft"	
			/>
		</div>
	)
}