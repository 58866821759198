import React from 'react'
import FliptButton from '../../../../components/form/fliptButton'
import './styles.scss'

export default (props) => {
  const {
    sectionName,
    getTabContents,
  } = props
  return (
    <div className="header">
      <div className="sectionTitle">
        {sectionName}
      </div>
      <div className="editButton">
        <FliptButton
          className="primary searchButton"
          onClick={() => getTabContents(sectionName)}
          name="EDIT"
        />
      </div>
    </div>
  )
}
