export const qualifierType = [
  'Age',
  'Diagnosis',
  'Gender',
  'Lookback',
  'Maximum Days of Supply',
  'Maximum Supply',
  'Medicare Eligible',
  'Minimum Days of Supply',
  'Minimum Supply',
  'Pharmacy',
  'Prescriber',
  'Quantity',
  'Specialty',
  'State',
  'Taxonomy'
]

export const qualifierOperator = ['=', '!=', '>', '>=', '<', '<=']
