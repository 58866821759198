import React, { useState, useMemo } from 'react'
import { Accordion, Button, Icon } from 'semantic-ui-react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import _ from "lodash"

import '../styles.scss'
import AddRow from '../addRow'
import FliptButton from '../../../../../../components/form/fliptButton'
import FliptDatePicker from '../../../../../../components/form/fliptDatePicker'
import FliptGrid from '../../../../../../components/fliptGrid'
import FliptInput from '../../../../../../components/form/fliptInput'
import qualifierRowCellInfo from '../data/qualifierRowCellInfo'
import rowCellInfo from '../data/rowCellInfo'
import { Creators as AppActions } from '../../../../../../redux/reducers/app'
import { Creators as PriorAuthorizationCreationActions } from '../../../../../../redux/reducers/api/priorAuthorizationManagement'
import { ButtonRenderer, DeleteRowRenderer, LevelCheckboxRenderer } from '../../../../../../components/fliptGrid/cellRenderers'
import { InputTextEditor, DropdownEditor, SearchEditor, DatePickerEditor } from '../../../../../../components/fliptGrid/cellEditors'
import { filterRowData, addRemoveDaysToDate, convertStrToDateObj, buildDropdownOptions } from '../../../../../../utils/utilities'
import { qualifierType, qualifierOperator } from '../data/qualifier'
import { medicareTypeCode } from '../../../../../../config/constants/clinical/drugGroups'
import FliptHierarchyDropdown from '../../../../../../components/form/fliptHierarchyDropdown'
import Qualifiers from "../../../../../../components/Qualifiers";
import moment from 'moment'
import * as ApprovalsConstants from '../../../../../../redux/sagas/rpm/constants'
import { deleteUnnecessaryFields } from '../../../../../../utils/utilizationManagement'

function PAConditions(props) {
  const { DRAFT, PUBLISHED, REJECTED } = ApprovalsConstants
  const {
    state, form, setForm,
    paConditionLevels, updateFields, activePAConditionLevel, editMode, showQualifier,
    handleQualifierChange,
    handleChange, dropdownOptions, autoSuggestionValues,
    paQualifierLevels, onCheckboxChange,
    autoSuggestionResultSelect, autoSuggestionMinChars,
    addNewPAConditionLevel, handlePAConditionLevelClick, deletePAConditionLevel, addPACondition, delPACondition,
    /*addQualifierRow, delQualifierRow,*/ savePriorAuthorization, sendForReview, doc_id, cellRenderers,
    qualifierDropdownOptions, criteriaIds, exportConditionDrugs
  } = props

  const [showGenerateDrugsGrid, setShowGenerateDrugsGrid] = useState(Array(paConditionLevels).fill(false))
  const [currentGenerateDrugCondition, setCurrentGenerateDrugCondition] = useState({})
  const [gridKey, setGridKey] = useState('')

  const {
    doc_name, effective_start_date,
    version, status, version_effective_date
  } = form

  const { multiSourceOptions, brandGenericOptions, otcIndicatorOptions, DESIOptions,
    routeOfAdministrationOptions, drugIndicatorOptions, dosageFormOptions,
    thirdPartyRestrictionOptions, deaClassCodeOptions, storageConditionCodeOptions,
    marketingCategoryOptions, applicationTypeOptions } = state.conditionsDropDownOptions

  rowCellInfo.brand_generic.options = brandGenericOptions
  rowCellInfo.desi_code.options = DESIOptions
  rowCellInfo.dosage_form_cd.options = dosageFormOptions
  rowCellInfo.maintenance_drug_code.options = drugIndicatorOptions
  rowCellInfo.multi_source.options = multiSourceOptions
  rowCellInfo.otc_indicator.options = otcIndicatorOptions
  rowCellInfo.route_of_administration.options = routeOfAdministrationOptions
  rowCellInfo.third_party_restriction_code.options = thirdPartyRestrictionOptions
  rowCellInfo.repackaged_code.options = [{ key: "Y", value: "Y", text: "Repackaged" }, { key: "N", value: "N", text: "Not Repackaged" }]
  rowCellInfo.dea_class_code.options = deaClassCodeOptions
  rowCellInfo.clinic_pack_code.options = [{ key: "Y", value: "Y", text: "Y" }, { key: "N", value: "N", text: "N" }]
  rowCellInfo.innerpack_code.options = [{ key: "Y", value: "Y", text: "Y" }, { key: "N", value: "N", text: "N" }]
  rowCellInfo.unit_dose_with_non_unit_dose_non_repackager_in_gpi.options = [{ key: "Y", value: "Y", text: "Y" }, { key: "N", value: "N", text: "N" }]
  rowCellInfo.rx_with_otc_in_gpi.options = [{ key: "Y", value: "Y", text: "Y" }, { key: "N", value: "N", text: "N" }]
  rowCellInfo.storage_condition_code.options = storageConditionCodeOptions
  rowCellInfo.marketing_category.options = marketingCategoryOptions
  rowCellInfo.application_type_flag.options = applicationTypeOptions
  rowCellInfo.item_status_flag.options = buildDropdownOptions(["A", "I"])
  rowCellInfo.unit_dose.options = [{ key: "", value: "", text: "" }, { key: "U", value: "U", text: "U" }, { key: "X", value: "X", text: "X" }]
  rowCellInfo.fda_therapeutic_equivalence_code.options = buildDropdownOptions(["AA",
    "AB",
    "AB1",
    "AB1,AB2",
    "AB1,AB2,AB3",
    "AB1,AB2,AB3,AB4",
    "AB1,AB3",
    "AB2",
    "AB3",
    "AB4",
    "AN",
    "AO",
    "AP",
    "AP1",
    "AP2",
    "AT",
    "AT1",
    "AT2",
    "AT3",
    "BC",
    "BD",
    "BP",
    "BS",
    "BX"])

  if (editMode) {
    qualifierRowCellInfo.action = {
      type: 'deleteRow',
      disabled: false,
    }
    Object.keys(qualifierRowCellInfo).forEach((key) => { qualifierRowCellInfo[key].disabled = false })

    rowCellInfo.action = {
      type: 'deleteRow',
      disabled: false,
    }
    Object.keys(rowCellInfo).forEach((key) => { rowCellInfo[key].disabled = false })
  } else {
    delete qualifierRowCellInfo.action
    Object.keys(qualifierRowCellInfo).forEach((key) => { qualifierRowCellInfo[key].disabled = true })

    delete rowCellInfo.action
    Object.keys(rowCellInfo).forEach((key) => { rowCellInfo[key].disabled = true })
  }
  const qualifierHeaders = Object.keys(qualifierRowCellInfo)

  if (qualifierHeaders.includes('action')) {
    qualifierHeaders.splice(qualifierHeaders.indexOf('action'), 1)
    qualifierHeaders.unshift('action')
  }

  const headers = Object.keys(rowCellInfo)
  if (headers.includes('action')) {
    headers.splice(headers.indexOf('action'), 1)
    headers.unshift('action')
  }

  const gridHeaders = [
    'gpi', 'drug_group', 'drug_class', 'drug_subclass', 'manufacturer', 'drug_name', 'multi_source', 'ndc',
    'ddid', 'brand_generic', 'otc_indicator', 'route_of_administration', 'desi_code', 'maintenance_drug_code',
    'effective_start_date', 'effective_end_date', 'application_type_flag', 'mfg_labeler_id', 'repackaged_code',
    'third_party_restriction_code', 'dosage_form_cd'
  ]

  const minPAStartDate = addRemoveDaysToDate(1, false)

  const handleGenerateDrugsClick = (data, submitType, rowIndex) => {
    // reusing the same object reference and not using setState for currentGenerateDrugCondition
    // is intentional. it is necessary for FliptGrid to function correctly
    Object.assign(currentGenerateDrugCondition, { ...data, include_inactive_drugs: form.include_inactive_drugs })

    const tempObj = deleteUnnecessaryFields(currentGenerateDrugCondition)
    const randomNumber = Math.floor(Math.random() * 900) + 100
    const key = Object.values(tempObj).reduce((acc, newValue) => acc = `${acc}${newValue || ''}${randomNumber}`, '')
    setGridKey(key)

    if (!showGenerateDrugsGrid[activePAConditionLevel]) {
      const newGenerateDrugsGrid = [...showGenerateDrugsGrid]
      newGenerateDrugsGrid[activePAConditionLevel] = true
      setShowGenerateDrugsGrid(newGenerateDrugsGrid)
    }
  }

  let cellRendererParams = {
    action: {
      cellRenderer: DeleteRowRenderer,
      state: {
        onClick: delPACondition,
      },
      width: 95,
    },
    include: {
      overrideHeader: 'Include',
      cellRenderer: LevelCheckboxRenderer,
      state: {
        onCheckboxChange,
      },
    },
    generate_drugs: {
      cellRenderer: ButtonRenderer,
      searchArgs: { onClick: handleGenerateDrugsClick, text: 'Generate Drugs' },
      width: 175,
    },
    effective_start_date: {
      valueFormatter: (params) => {
        if (!params.value) return ''
        return moment(params.value)?.format('YYYY-MM-DD') || params.value
      },
    },
    effective_end_date: {
      valueFormatter: (params) => {
        if (!params.value) return ''
        return moment(params.value)?.format('YYYY-MM-DD') || params.value
      },
    }
  }

  const serverSideGridConfig = {
    rowModel: 'serverSide',
    serverSideStoreType: 'partial',
    cacheBlockSize: 20,
    pagination: true,
    paginationPageSize: 20,
  }

  const serverSideGridParams = {
    form: currentGenerateDrugCondition,
    apiId: 'generate-prior-authorization',
    sagaToCall: props.actions.generatePriorAuthorization,
    headers,
  }

  const defaultColDef = useMemo(() => ({
    resizable: true,
    floatingFilter: true,
    filter: "agTextColumnFilter",
    filterParams: { buttons: ["reset"], filterOptions: ['contains', 'equals'] },
    outerHeight: 120,
  }), [])


  const handleAccordionTitleClick = (e, titleProps) => {
    const { index } = titleProps
    if (showGenerateDrugsGrid[index]) {
      const newGenerateDrugsGrid = [...showGenerateDrugsGrid]
      newGenerateDrugsGrid[index] = false
      setShowGenerateDrugsGrid(newGenerateDrugsGrid)
    }
    handlePAConditionLevelClick(e, titleProps)
  }

  const cellEditorParams = {
    gpi: {
      cellEditor: InputTextEditor,
      onChange: handleChange,
    },
    ndc: {
      cellEditor: InputTextEditor,
      onChange: handleChange,
    },
    desi_code: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    drug_group: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    drug_class: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    drug_subclass: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    manufacturer: {
      cellEditor: SearchEditor,
      onChange: handleChange,
      autoSuggestionResultSelect,
    },
    drug_name: {
      cellEditor: SearchEditor,
      onChange: handleChange,
      autoSuggestionResultSelect,
    },
    multi_source: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    item_status_flag: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    ddid: {
      cellEditor: InputTextEditor,
      onChange: handleChange,
    },
    brand_generic: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    otc_indicator: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    route_of_administration: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    maintenance_drug_code: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    effective_start_date: {
      cellEditor: DatePickerEditor,
      onChange: handleChange,
    },
    effective_end_date: {
      cellEditor: DatePickerEditor,
      onChange: handleChange,
    },
    application_type_flag: {
      cellEditor: InputTextEditor,
      onChange: handleChange,
    },
    mfg_labeler_id: {
      cellEditor: InputTextEditor,
      onChange: handleChange,
    },
    repackaged_code: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    third_party_restriction_code: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    dosage_form_cd: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    clinic_pack_code: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    innerpack_code: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    unit_dose: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    dea_class_code: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    fda_therapeutic_equivalence_code: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    marketing_category: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    storage_condition_code: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    unit_dose_with_non_unit_dose_non_repackager_in_gpi: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    rx_with_otc_in_gpi: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    member_notes: {
      cellEditor: InputTextEditor,
      onChange: handleChange,
    },
    agent_notes: {
      cellEditor: InputTextEditor,
      onChange: handleChange,
    },
    internal_notes: {
      cellEditor: InputTextEditor,
      onChange: handleChange,
    },
    claim_message_code: {
      cellEditor: InputTextEditor,
      onChange: handleChange,
    },
    claim_message_type: {
      cellEditor: InputTextEditor,
      onChange: handleChange,
    },
  }

  cellRendererParams = { ...cellRendererParams, ...cellRenderers }

  const generateDrugsCellRendererParams = _.cloneDeep(cellRendererParams)
  generateDrugsCellRendererParams.ndc.hide = false
  delete generateDrugsCellRendererParams.include
  const unsortableKeys = ['effective_start_date', 'effective_end_date']
  for (let key in generateDrugsCellRendererParams) {
    if (generateDrugsCellRendererParams[key]) {
      if (unsortableKeys.includes(key)) {
        generateDrugsCellRendererParams[key].sortable = false
      }
      generateDrugsCellRendererParams[key].filterParams = {
        filterOptions: ['contains', 'equals'],
        defaultJoinOperator: 'OR',
        buttons: ['reset'],
      }
    }
  }

  return (
    <div className="section" >
      <section className="pa-inputs-container spacing border-line shadow">
        <div className="pa-intputs-wrap">
          <div className="pa-inputs">
            <span>Prior Authorization Name</span>
            <FliptInput className="createPAInputs" placeholder="Prior Authorization Name" name="doc_name" value={doc_name} disabled={!editMode || (!!version && (version !== '1.0')) || (!!status && (status === 'PUBLISHED'))} onChange={updateFields} />
          </div>
          {/* <div className="pa-inputs">
            <span>Prior Authorization Version</span>
            <FliptInput placeholder="Prior Authorization Version" name="doc_version" value={doc_version} disabled onChange={updateFields} />
          </div> */}
          <div className="pa-inputs">
            <span>Effective Start Date</span>
            <FliptDatePicker className="create-pa-start-date" placeholder="Effective Start Date" name="effective_start_date" minDate={minPAStartDate} value={convertStrToDateObj(effective_start_date)} disabled={!editMode} onChange={updateFields} />
          </div>
          <div className="pa-inputs">
            <span>Version</span>
            <FliptInput className="create-pa-start-date" name="version" value={version} onChange={updateFields} format="MM-DD-YYYY" disabled />
          </div>
          <div className="pa-inputs">
            <span>Version Effective Date</span>
            <FliptDatePicker className="create-pa-start-date" name="version_effective_date" value={convertStrToDateObj(version_effective_date)} onChange={updateFields} format="MM-DD-YYYY" readOnly={!editMode} />
          </div>
        </div>
        <div className="pa-intputs-wrap">
          <FliptHierarchyDropdown showInactiveDrugs={true} setForm={setForm} form={form} showHierarchyGlobal={true} />
        </div>
      </section>
      <section className="grid-container spacing border-line shadow" >
        <div className="add-pa-condition-level">
          <Button size="small" onClick={addNewPAConditionLevel} color="youtube">
            <Icon name="add" />
            Add New PA Condition Level
          </Button>
        </div>
        <div className="pa-condition-levels">
          <Accordion className="pa-condition-levels-accordion" styled>
            {
              paConditionLevels.map((paConditionLevel, idx) => {
                const agGridRef = React.createRef()
                const paConditionLevelHeading = `PA Condition Level ${idx + 1}`
                const hiddenColumn = Object.keys(cellRendererParams).filter(key => cellRendererParams[key]?.hide)
                const columnData = {}
                paConditionLevel.map(ele => {
                  Object.keys(ele).forEach(key => {
                    columnData[key] = !!columnData[key] || !!ele[key]
                  })
                })
                hiddenColumn.forEach(key => {
                  if (columnData[key]) {
                    cellRendererParams[key].hide = false
                  }
                })
                return (
                  <div className="pa-condition-level">
                    <Accordion.Title
                      active={activePAConditionLevel === idx}
                      index={idx}
                      onClick={handleAccordionTitleClick}
                    >
                      <Icon name="dropdown" />
                      {paConditionLevelHeading}
                      <Icon className="pa-condition-level-delete" name="delete" onClick={(e) => deletePAConditionLevel(e, idx)} />
                    </Accordion.Title>
                    <Accordion.Content
                      active={activePAConditionLevel === idx}
                    >
                      <section className='button-header-section'>
                        <span className="checkbox-label"></span>
                        <Button size="small" className="export-button" onClick={(e) => exportConditionDrugs(e, idx)}>Export PA Drug List Level {idx + 1}</Button>
                      </section>
                      <div className="pa-conditions">
                        <FliptGrid
                          agGridRef={agGridRef}
                          data={paConditionLevel}
                          headers={headers}
                          cellRendererParams={cellRendererParams}
                          cellEditorParams={cellEditorParams}
                          rowCellInfo={rowCellInfo}
                          dropdownOptions={dropdownOptions[idx]}
                          autoSuggestionValues={autoSuggestionValues}
                          autoSuggestionMinChars={autoSuggestionMinChars}
                          stepLevel={idx}
                        />
                      </div>
                      <div className="pa-condition-level-button-container">
                        {!!editMode && (
                          <div className="pa-condition-level-add-cond-button">
                            <AddRow addRow={addPACondition} level={idx} />
                          </div>
                        )}
                      </div>
                      {!!showQualifier[idx] && (
                        <Qualifiers
                          allCriteriaIDs={criteriaIds}
                          model_ids={["claim", "prescriber", "user", "pharmacy"]}
                          stepLevel={idx}
                          criteria_id={criteriaIds?.[idx] || ""}
                          preventMultipleGetCalls
                        />
                      )}
                      {activePAConditionLevel === idx && showGenerateDrugsGrid[idx] && (
                        <div className="pa-condition-level-drugs">
                          <FliptGrid
                            key={gridKey}
                            defaultColDef={defaultColDef}
                            headers={gridHeaders}
                            cellRendererParams={generateDrugsCellRendererParams}
                            serverSideGridConfig={serverSideGridConfig}
                            serverSideGridParams={serverSideGridParams}
                            filterOptions={['contains', 'equals']}
                            suppressColumnVirtualisation
                          />
                        </div>
                      )}
                    </Accordion.Content>
                  </div>
                )
              })
            }
          </Accordion>
        </div>
      </section >
      <div className="buttonContainer">
        {editMode &&
          <FliptButton
            className="primary searchButton"
            disabled={![DRAFT, PUBLISHED, REJECTED].includes(status)}
            name="Save"
            onClick={savePriorAuthorization}
          />}
        {editMode && doc_id &&
          <FliptButton
            className="primary searchButton"
            disabled={status !== DRAFT}
            name="Send to Peers for Review/Approval"
            onClick={sendForReview}
          />}
      </div>
    </div >
  )
}

const mapStateToProps = (state) => ({
  state: {
    conditionsDropDownOptions: state.rpm.conditionsDropDownOptions,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
    ...PriorAuthorizationCreationActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PAConditions)
