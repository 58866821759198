import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Icon } from 'semantic-ui-react'

import { Creators as AccountManagementActions } from '../../../redux/reducers/api/accountManagement'
import AccountDetails from './accountDetails'
import AccountFilters from './accountFilters'
import AcccountTable from './accountTable'
import AddNewAccount from './addNewAccount'
import BreadCrumb from '../../../components/breadCrumb'

import './styles.scss'

import { generateFilterOptions, valueTypeHelper } from '../../../utils/utilities'

class AccountDesignSetup extends Component {
  constructor(props) {
    super(props)

    this.state = {
    }
  }

  componentDidMount() {
    this._getAccountsOnceMenuDataIsFetched()
  }

  onFilterChange = (e, dropdown) => {
    const { actions } = this.props
    const { name, value } = dropdown
    actions.setFilters({
      data: {
        filterName: name,
        filterValue: value,
      },
    })
  }

  _getAccountsOnceMenuDataIsFetched = () => {
    const interval = setInterval(() => {
      const { actions, state: { app } } = this.props
      if (app && app.menu_data && app.menu_data.length) {
        actions.getAccounts()
        clearInterval(interval)
      }
    }, 250)
  }

  _filterAccounts = (accounts) => {
    const { state: { accountDetailsFilters } } = this.props

    return accounts
      .filter((a) => (accountDetailsFilters.account
        ? a.domain === accountDetailsFilters.account : true))
      .filter((a) => {
        const { details: { finance } } = a
        return accountDetailsFilters.group
          ? valueTypeHelper(finance.fields.find((field) => field.field === 'flipt_group_id')) === accountDetailsFilters.group
          : true
      })
      .filter((a) => {
        const { details: { finance } } = a
        return accountDetailsFilters.company
          ? valueTypeHelper(finance.fields.find((field) => field.field === 'companylegalname')) === accountDetailsFilters.company
          : true
      })
  }

  rowClick = (el) => {
    const { actions, state } = this.props
    const { accounts } = state
    const { currentTarget } = el
    if (!currentTarget) return

    const companyDomain = currentTarget.querySelector('td:nth-child(2)').innerText
    const companyAccount = accounts.find((account) => account.domain === companyDomain)

    actions.setSelectedAccount({ data: companyAccount })
  }

  render() {
    const { showAddNewAccount } = this.state
    const { state } = this.props
    const { accounts, accountDetails } = state
    const filterOptions = generateFilterOptions(accounts)

    return (
      <div id="accountSetup">
        <BreadCrumb {...this.props} />
        <div className="header">
          Account Details
          <Link to={{ pathname: '/create-account' }}>
            <div className="add-account" aria-hidden="true">
              <Icon name="plus circle" size="tiny" />
              Add Account
            </div>
          </Link>
        </div>
        {!showAddNewAccount && <AccountFilters filterOptions={filterOptions} onFilterChange={this.onFilterChange} />}
        {!showAddNewAccount && accounts && !!accounts.length && <AcccountTable accounts={this._filterAccounts(accounts)} rowClick={this.rowClick} />}
        {!showAddNewAccount && accountDetails && <AccountDetails accountDetails={accountDetails} />}
        {showAddNewAccount && <AddNewAccount backToAccountDetails={() => this.setState({ showAddNewAccount: false })} />}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  state: {
    app: state.app,
    accounts: state.accountManagement.accounts,
    accountDetails: state.accountManagement.accountDetails,
    accountDetailsFilters: state.accountManagement.accountDetailsFilters,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AccountManagementActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountDesignSetup)
