import Immutable from 'seamless-immutable'
import { createActions, createReducer } from 'reduxsauce'

import { convertDateTimeToDate } from '../../../utils/utilities'

export const INITIAL_STATE = Immutable({
  addPaOverrideData: null,
  addPrescriberData: {},
  allPriorAuthorizationData: [],
  communicationData: [],
  createPaCaseData: {},
  diagnosisData: [],
  downloadDocumentData: [],
  duplicateData: null,
  finalDocuments: [],
  formsLettersData: [],
  getAnswerData: [],
  mergeDocumentData: {},
  messageTemplateData: [],
  msgXmlData: {},
  notesData: [],
  paCaseDetails: {},
  paCaseMessage: null,
  paCasesPerPage: 20,
  paDashboardReports: {},
  paIntakeQueue: [],
  paTemplates: [],
  priorAuthorizationCondData: [],
  priorAuthorizationData: {},
  priorAuthorizationDrugFiltersData: {},
  priorAuthorizationQualifierCondData: [],
  priorAuthorizationCriteriaIdData: [],
  questionnaireData: {},
  rxTabData: {},
  saveAnswerData: [],
  saveNotesdata: {},
  searchCaseDetails: [],
  updatePaCaseData: [],
  updatePrescriberData: {},
  uploadDocumentData: [],
  viewAttachmentData: {},
})

export const clearReducer = () => INITIAL_STATE

export const clearPriorAuthorizationReducer = () => INITIAL_STATE

export const clearPaCaseDetailsReducer = (state = INITIAL_STATE) => ({
  ...state,
  paCaseDetails: [],
})

export const setAllPriorAuthorizationDataReducer = (state = INITIAL_STATE, { payload }) => {
  const d = payload?.data.map((row) => ({
    ...row,
    effective_end_date: convertDateTimeToDate(row.effective_end_date),
    effective_start_date: convertDateTimeToDate(row.effective_start_date),
  }))
  return {
    ...state,
    allPriorAuthorizationData: d,
  }
}

export const setPriorAuthorizationDrugFiltersDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  priorAuthorizationDrugFiltersData: payload,
})

export const setPriorAuthorizationTemplatesDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  paTemplates: payload,
})

export const clearCreatePaCaseReducer = (state = INITIAL_STATE) => ({
  ...state,
  createPaCaseData: {},
})

export const setPaIntakeQueueReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  paIntakeQueue: payload,
})

export const setCommunicationDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  communicationData: payload,
})

export const setPaCaseDetailReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  paCaseDetails: payload,
})

export const setUpdatePaCaseDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  updatePaCaseData: payload,
})

export const clearUpdatePaCaseDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  updatePaCaseData: {},
})

export const setSearchCaseDetailsReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  searchCaseDetails: payload,
})

export const clearSearchCaseDetailsReducer = (state = INITIAL_STATE) => ({
  ...state,
  searchCaseDetails: [],
})

export const setCreatePaCaseDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  createPaCaseData: payload,
})

export const setCreateNoteDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  saveNotesdata: payload,
})

export const setGetNoteDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  notesData: payload,
})

export const setRxTabDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  rxTabData: payload,
})

export const setAttachmentDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  uploadDocumentData: payload,
})

export const setDownloadDocumentDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  downloadDocumentData: payload,
})

export const setFormsLettersDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  formsLettersData: payload,
})
export const setQuestionnaireDetailsReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  questionnaireData: payload,
})

export const clearQuestionnaireDetailsReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  questionnaireData: {},
})

export const setSaveAnswerDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  saveAnswerData: payload,
})

export const setMessageXmlReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  msgXmlData: payload,
})

export const setGetAnswerDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  getAnswerData: payload,
})

export const setUpdatePrescriberDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  updatePrescriberData: payload,
})

export const setDiagnosisDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  diagnosisData: payload,
})

export const setMessageTemplateDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  messageTemplateData: payload,
})

export const setAddPrescriberDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  addPrescriberData: payload,
})

export const setUpdatePaCaseMessageReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  paCaseMessage: payload,
})

export const setCheckDuplicateDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  duplicateData: payload,
})

export const setAddPaOverrideDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  addPaOverrideData: payload,
})


export const clearSaveAnswerDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  saveAnswerData: null,
})

export const setViewAttachmentDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  viewAttachmentData: payload,
})

export const setPriorAuthorizationDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  priorAuthorizationQualifierCondData: payload.qualifier_conditions,
  priorAuthorizationCriteriaIdData: payload.criteria_ids,
  priorAuthorizationCondData: payload.pa_conditions,
  priorAuthorizationData: payload.pa_info,
})

export const clearPriorAuthorizationDataReducer = (state = INITIAL_STATE) => ({
  ...state,
  priorAuthorizationQualifierCondData: [],
  priorAuthorizationCriteriaIdData: [],
  priorAuthorizationCondData: [],
  priorAuthorizationData: {},
})

export const setFinalDocumentsDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  finalDocuments: [...payload]
})


export const clearFinalDocumentsDataReducer = (state = INITIAL_STATE) => ({
  ...state,
  finalDocuments: []
})

export const clearPaDashboardReportsReducer = (state = INITIAL_STATE) => ({
  ...state,
  paDashboardReports: {}
})

export const setPaDashboardReportsReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  paDashboardReports: { ...payload }
})

export const { Types, Creators } = createActions({
  addPaOverride: ['payload'],
  addPrescriber: ['payload'],
  approvePriorAuthorization: ['payload'],
  assignToMe: ['payload'],
  checkDuplicate: ['payload'],
  clearCaseSearchDetails: null,
  clearCreatePaCase: null,
  clearFinalDocumentsData: null,
  clearPaCaseDetails: null,
  clearPaDashboardReports: null,
  clearPriorAuthorization: null,
  clearPriorAuthorizationData: null,
  clearQuestionnaireDetails: null,
  clearReducer: null,
  clearSaveAnswerData: null,
  clearUpdatePaCaseData: null,
  createNote: ['payload'],
  createPaIntakeQueue: ['payload'],
  diagnosisLookup: ['payload'],
  generatePriorAuthorization: ['payload'],
  generatePriorAuthorizationLevelExport: ['payload', 'callback'],
  getAllPriorAuthorizationData: null,
  getAnswer: ['payload'],
  getAttachment: ['payload'],
  getCommunication: ['payload'],
  getDownloadDocument: ['payload'],
  getFinalDocuments: ['payload'],
  getMessageTemplate: ['payload'],
  getMessageXml: ['payload'],
  getNotes: ['payload'],
  getPaCaseDetails: ['payload'],
  getPaDashboardDetails: ['payload'],
  getPaDashboardReports: null,
  getPaIntakeQueue: ['payload'],
  getPriorAuthorizationData: ['payload'],
  getPriorAuthorizationTemplates: ['payload'],
  getQuestionnaire: ['payload'],
  getRxTabData: ['payload'],
  getSearchCaseDetails: ['payload'],
  mergeDocument: ['payload'],
  reassignPACase: ['payload'],
  saveAnswer: ['payload'],
  saveAttachment: ['payload'],
  savePriorAuthorizationData: ['payload'],
  sendOutboundCommunication: ['payload'],
  setAddPaOverrideData: ['payload'],
  setAddPrescriberData: ['payload'],
  setAllPriorAuthorizationData: ['payload'],
  setAttachmentData: ['payload'],
  setCheckDuplicateData: ['payload'],
  setCommunicationData: ['payload'],
  setCreateNoteData: ['payload'],
  setCreatePaCaseData: ['payload'],
  setDiagnosisData: ['payload'],
  setDownloadDocumentData: ['payload'],
  setFinalDocumentsData: ['payload'],
  setFormsLettersData: ['payload'],
  setGetAnswerData: ['payload'],
  setGetNoteData: ['payload'],
  setMessageTemplateData: ['payload'],
  setMessageXml: ['payload'],
  setPaCaseDetails: ['payload'],
  setPaDashboardReports: ['payload'],
  setPaIntakeQueue: ['payload'],
  setPriorAuthorizationData: ['payload'],
  setPriorAuthorizationDrugFiltersData: ['payload'],
  setPriorAuthorizationTemplatesData: ['payload'],
  setQuestionnaireDetails: ['payload'],
  setRxTabData: ['payload'],
  setSaveAnswerData: ['payload'],
  setSearchCaseDetails: ['payload'],
  setUpdatePaCaseData: ['payload'],
  setUpdatePaCaseMessage: ['payload'],
  setUpdatePrescriberData: ['payload'],
  setViewAttachmentData: ['payload'],
  updatePaCase: ['payload'],
  updatePrescriber: ['payload'],
  uploadPaDocumentTemplate: ['payload'],
  viewAttachment: ['payload'],
})


const HANDLERS = {
  [Types.CLEAR_CASE_SEARCH_DETAILS]: clearSearchCaseDetailsReducer,
  [Types.CLEAR_CREATE_PA_CASE]: clearCreatePaCaseReducer,
  [Types.CLEAR_FINAL_DOCUMENTS_DATA]: clearFinalDocumentsDataReducer,
  [Types.CLEAR_PA_CASE_DETAILS]: clearPaCaseDetailsReducer,
  [Types.CLEAR_PA_DASHBOARD_REPORTS]: clearPaDashboardReportsReducer,
  [Types.CLEAR_PRIOR_AUTHORIZATION_DATA]: clearPriorAuthorizationDataReducer,
  [Types.CLEAR_PRIOR_AUTHORIZATION]: clearPriorAuthorizationReducer,
  [Types.CLEAR_QUESTIONNAIRE_DETAILS]: clearQuestionnaireDetailsReducer,
  [Types.CLEAR_REDUCER]: clearReducer,
  [Types.CLEAR_SAVE_ANSWER_DATA]: clearSaveAnswerDataReducer,
  [Types.CLEAR_UPDATE_PA_CASE_DATA]: clearUpdatePaCaseDataReducer,
  [Types.SET_ADD_PA_OVERRIDE_DATA]: setAddPaOverrideDataReducer,
  [Types.SET_ADD_PRESCRIBER_DATA]: setAddPrescriberDataReducer,
  [Types.SET_ALL_PRIOR_AUTHORIZATION_DATA]: setAllPriorAuthorizationDataReducer,
  [Types.SET_ATTACHMENT_DATA]: setAttachmentDataReducer,
  [Types.SET_CHECK_DUPLICATE_DATA]: setCheckDuplicateDataReducer,
  [Types.SET_COMMUNICATION_DATA]: setCommunicationDataReducer,
  [Types.SET_CREATE_NOTE_DATA]: setCreateNoteDataReducer,
  [Types.SET_CREATE_PA_CASE_DATA]: setCreatePaCaseDataReducer,
  [Types.SET_DIAGNOSIS_DATA]: setDiagnosisDataReducer,
  [Types.SET_DOWNLOAD_DOCUMENT_DATA]: setDownloadDocumentDataReducer,
  [Types.SET_FINAL_DOCUMENTS_DATA]: setFinalDocumentsDataReducer,
  [Types.SET_FORMS_LETTERS_DATA]: setFormsLettersDataReducer,
  [Types.SET_GET_ANSWER_DATA]: setGetAnswerDataReducer,
  [Types.SET_GET_NOTE_DATA]: setGetNoteDataReducer,
  [Types.SET_MESSAGE_TEMPLATE_DATA]: setMessageTemplateDataReducer,
  [Types.SET_MESSAGE_XML]: setMessageXmlReducer,
  [Types.SET_PA_CASE_DETAILS]: setPaCaseDetailReducer,
  [Types.SET_PA_DASHBOARD_REPORTS]: setPaDashboardReportsReducer,
  [Types.SET_PA_INTAKE_QUEUE]: setPaIntakeQueueReducer,
  [Types.SET_PRIOR_AUTHORIZATION_DATA]: setPriorAuthorizationDataReducer,
  [Types.SET_PRIOR_AUTHORIZATION_DRUG_FILTERS_DATA]: setPriorAuthorizationDrugFiltersDataReducer,
  [Types.SET_PRIOR_AUTHORIZATION_TEMPLATES_DATA]: setPriorAuthorizationTemplatesDataReducer,
  [Types.SET_QUESTIONNAIRE_DETAILS]: setQuestionnaireDetailsReducer,
  [Types.SET_RX_TAB_DATA]: setRxTabDataReducer,
  [Types.SET_SAVE_ANSWER_DATA]: setSaveAnswerDataReducer,
  [Types.SET_SEARCH_CASE_DETAILS]: setSearchCaseDetailsReducer,
  [Types.SET_UPDATE_PA_CASE_DATA]: setUpdatePaCaseDataReducer,
  [Types.SET_UPDATE_PA_CASE_MESSAGE]: setUpdatePaCaseMessageReducer,
  [Types.SET_UPDATE_PRESCRIBER_DATA]: setUpdatePrescriberDataReducer,
  [Types.SET_VIEW_ATTACHMENT_DATA]: setViewAttachmentDataReducer,
}

export default createReducer(INITIAL_STATE, HANDLERS)
