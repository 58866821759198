

export const filterData = (input, filters) => {
  const filteredData = {};
  const result = {}
  for (const orgKey in input) {
    if (input.hasOwnProperty(orgKey)) {
      if (!Array.isArray(input[orgKey])){continue}
      filteredData[orgKey] = input[orgKey]
        .map((org) => {
          const filteredOrg = { ...org };
          if (
            filters.organization_name.length > 0 &&
            !filters.organization_name.includes(filteredOrg.organization_id || filteredOrg.organization_name)
          ) {
            return null;
          }

          filteredOrg.children = org.children
            .map((client) => {
              const filteredClient = { ...client };
              if (
                filters.client_name.length > 0 &&
                !filters.client_name.includes(filteredClient.client_id || filteredClient.client_name)
              ) {
                return null;
              }

              filteredClient.children = client.children
                .map((carrier) => {
                  const filteredCarrier = { ...carrier };
                  if (
                    filters.carrier_name.length > 0 &&
                    !filters.carrier_name.includes(filteredCarrier.carrier_id || filteredCarrier.carrier_name)
                  ) {
                    return null;
                  }

                  filteredCarrier.children = carrier.children
                    .map((account) => {
                      const filteredAccount = { ...account };
                      if (
                        filters.account_name.length > 0 &&
                        !filters.account_name.includes(filteredAccount.account_id || filteredAccount.account_name)
                      ) {
                        return null;
                      }

                      filteredAccount.children = account.children
                        .map((group) => {
                          const filteredGroup = { ...group };
                          if (
                            filters.group_name.length > 0 &&
                            !filters.group_name.includes(filteredGroup.group_id || filteredGroup.group_name)
                          ) {
                            return null;
                          }


                          return filteredGroup;
                        })
                        .filter((group) => group !== null);

                      return filteredAccount;
                    })
                    .filter((account) => account !== null);

                  return filteredCarrier;
                })
                .filter((carrier) => carrier !== null);

              return filteredClient;
            })
            .filter((client) => client !== null);

          return filteredOrg;
        })
        .filter((org) => org !== null);
    }
  }
  if(filters.search){
    for (let inputVal in filteredData) {
      let val = filteredData[inputVal];
     let res = findTermInStructure(val, filters.search);
     
     if(res && res.length > 0){
      result[inputVal] = res;
     }
  }
  }

  return filters.search ? result : filteredData;
};

function findTermInStructure(structure, searchTerm) {
  /**
   * Check if Array.
   */
  if (Array.isArray(structure)) {
      return structure.map((element) => findTermInStructure(element, searchTerm)).filter(Boolean);
  }
  /**
   * Check if Object.
   * */
  else if (typeof structure === 'object') {
    // First: return structure if level name matches
    // Search Matching: return the structure only when the level name matches the searchTerm
    if (structure?.[`${structure?.level}_name`]?.toLowerCase()?.includes(searchTerm)) {
      return structure
    }

    // Second: level name does not match check in children
    /**
    * Match children.
    */
    const filteredChildren = (structure.children || []).map((child) => findTermInStructure(child, searchTerm)).filter(Boolean);

    if (filteredChildren.length > 0) {
        return { ...structure, children: filteredChildren };
    }

  }

  /**
   * If Not array or object then return.
   */
  return null;
}


// Loop through the input
export const checkSelection = (input) => {
  const AllFilters = {
    organizationsData :[],
    clientsData:[],
    carriersData:[],
    accoutsData:[],
    groupsData:[],
  }
  for (const item of input) {
    for (const organization of item.children) {
      const organizationData = organization.name;
      AllFilters.organizationsData.push(organizationData);
      for (const client of organization.children) {
        const clientData = client.name;
        AllFilters.clientsData.push(clientData);
        for (const carrier of client.children) {
          const carrierData = carrier.name;
          AllFilters.carriersData.push(carrierData);
          for (const account of carrier.children) {
            const accountData =account.name;
            AllFilters.accoutsData.push(accountData);
            for (const group of account.children) {
              const groupData = group.name;
              AllFilters.groupsData.push(groupData);
            }
          }
        }
      }
    }
  }
  return AllFilters

};


