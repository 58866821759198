import {
    call,
    put,
    select,
    takeLatest,
  } from 'redux-saga/effects'
  
  import { Types, Creators as PaoverridereportLookupActions } from '../../reducers/api/paoverrideLookup'
  import { Creators as AppActions } from '../../reducers/app'
  import {
    getAppState, getApiPath, getPaoverrideLookup, getHistory,
  } from '../../reducers/selectors'
  import { fetchGet, fetchPost,fetchPut } from '../../../utils/fetchApi'
  import { createQueryString, filterRowData } from '../../../utils/utilities'
  
  export default [
    getPaoverridereportLookupDataWatcher,
    getPaoverridereportSuggestDataWatcher,
  ]
  
  /* WATCHERS */
  function* getPaoverridereportLookupDataWatcher() {
    
    yield takeLatest(Types.GET_PAOVERRIDEREPORT_LOOKUP_DATA, getPaoverridereportLookupDataHandler)
  }
  
  function* getPaoverridereportSuggestDataWatcher() {
    yield takeLatest(Types.GET_PAOVERRIDEREPORT_SUGGEST_DATA, getPaoverridereportSuggestDataHandler)
  }
  
  /* HANDLERS */
  function* getPaoverridereportLookupDataHandler({ payload }) {
    try {
      const {
        form, params, headers, headerMapping,
      } = payload
      const { serviceUrl } = yield select(getAppState)
      const history = yield select(getHistory)
      const { paoverridereportPerPage } = yield select(getPaoverrideLookup)
      history.location.search = createQueryString({
        ...form,
        offset: params?.request.startRow,
      })
      const qs = history.location.search ? `${history.location.search}&limit=${paoverridereportPerPage}` : ''
  
      const requiredFields = ['drug_name', 'end_date', 'flipt_person_id', 'rx_status',"start_date","domain_name","offset","use_date"]
      form.rx_status=form.rx_status=="PA INITIATED"?"PA":form.rx_status=="PA CANCELED"?"Cancelled":"PA"
      if (!requiredFields.some((field) => form[field])) {
        params?.success({
          rowData: [],
          rowCount: 0,
        })
      } else {
        const { api_path } = yield select(getApiPath, 'paoverride-lookup')
        const url = `${serviceUrl}${api_path}`
       
        const reqData = {
          sortModel: params?.request.sortModel,
          filterModel: params?.request.filterModel,
          drug_name:form.drug_name,
          start_date:form.start_date,
          end_date:form.end_date,
          flipt_person_id:form.flipt_person_id,
         
          domain_name:form.domain_name,
          offset:params?.request.startRow,
          use_date:form.use_date
        }
        const { data, total_rows } = yield call(fetchPost, url, reqData)
        const gridData = data?.map((d) => ({
          ...filterRowData(d, headers, headerMapping),
        }))
        params?.success({
          rowData: gridData,
          rowCount: parseInt(total_rows, 10),
        })
      }
    } catch (err) {
      console.log('getPaoverridereportLookupDataHandler Error >Data ', err)
  
      const transitionalPortal = {
        header: 'Paoverridereport Lookup Failed',
        copy: err.message,
      }
      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    }
  }
  
  function* getPaoverridereportSuggestDataHandler({ payload }) {
    try {
     
      yield put(PaoverridereportLookupActions.setLoading({ data: true }))
      const { serviceUrl } = yield select(getAppState)
      const history = yield select(getHistory)
  
      const { paoverridereportPerPage } = yield select(getPaoverrideLookup)
      const qs = history.location.search ? `${history.location.search}&limit=${paoverridereportPerPage}` : ''
  
      if (!qs) return
  
      const cd = yield select(getApiPath, 'paoverride-update')
      const { api_path } = cd
      const url = `${serviceUrl}${api_path}`
      let date = new Date()
      const reqData ={
        "override_id": payload.override_id, 
        "pa_override_end_date":date.toISOString(),
        "pa_override_status":payload.pa_override_status,
        "change":payload.change
      
    }
    
        const { message } = yield call(fetchPut, url, reqData)
  
     
  
      yield put(PaoverridereportLookupActions.setPaoverridereportSuggestData({ message }))
      yield put(PaoverridereportLookupActions.setLoading({ data: false }))
    } catch (err) {
      console.log('getPaoverridereportSuggestDataHandler Error >Data ', err)
  
      const transitionalPortal = {
        header: 'Paoverride Lookup Failed',
        copy: err.message,
      }
      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
      yield put(PaoverridereportLookupActions.setLoading({ data: false }))
    }
  }
  