import React from 'react'
import { Button, Dropdown } from 'semantic-ui-react'
import './styles.scss'

const DropdownButtonFloating = (props) => {
    const { options, buttonName } = props
    return (<Button.Group id='button-group'>
        <Button className='bg-button'>{buttonName}</Button>
        <Dropdown
            className='button icon bg-button'
            floating
            options={options}
            trigger={<></>}
        />
    </Button.Group>)
}

export default DropdownButtonFloating