/* eslint-disable */
import React, { forwardRef } from 'react'
import { Dropdown } from 'semantic-ui-react'

const FliptPaDropdown = forwardRef((props, ref) => {
    if ((!props?.name || !props?.options?.length) && !props?.forceRender) return <div />

    const {
        className,
        defaultValue,
        description,
        hidden,
        icon,
        label,
        options,
        placeholder,
        stylized,
        readOnly,
        multiple = false,
    } = props
    const defaultvalue = options?.length === 1 ? options[0].value : defaultValue
    const placeHolder = placeholder || 'Select Option'
    const stylizedClassName = `pa-stylized ${className || ''}`

    const stylizedField = () => (
        <div className={stylizedClassName}>
            <div className="flipt-pa-label">
                <label htmlFor={label}>{label}</label>
            </div>
            {!!icon && <div>{icon}</div>}
            <Dropdown search {...props} multiple={multiple} options={options} placeholder={placeHolder} hidden={hidden || false} defaultValue={defaultvalue} selection disabled={readOnly} />
        </div>
    )

    if (stylized) return stylizedField()

    return <Dropdown search {...props} multiple={multiple} options={options} placeholder={placeHolder} hidden={hidden || false} defaultValue={defaultvalue} selection ref={ref} disabled={readOnly} />
})

export default FliptPaDropdown
