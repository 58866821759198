/* eslint-disable */
import React, { forwardRef } from 'react'
import { Dropdown } from 'semantic-ui-react'
import './styles.scss'
import SemanticDatepicker from 'react-semantic-ui-datepickers'

const FliptDatePicker = forwardRef((props, ref) => {
    const {
        className,
        icon,
        label,
        format,
        readOnly,
        clearable
    } = props
    const containerClassName = `${className || ''} v2-flipt-datePicker`
    return (
        <div className={containerClassName}>
            <div className="description-text">
                <div className='flipt-label' hidden={props.hidden || false}>
                    <label htmlFor={label}>{label}</label>
                </div>
            </div>
            {icon}
            <SemanticDatepicker className='datepicker-component' {...props} label='' autoComplete='off' clearable={clearable} format={format || 'MM-DD-YYYY'} readOnly={readOnly} />
        </div>
    )
})

export default FliptDatePicker