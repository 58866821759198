import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import './styles.scss'
import Config from '../../../../config'
import _ from 'lodash'
import FliptInput from '../../../../components/form/fliptInput'
import FliptDatePicker from '../../../../components/form/fliptDatePicker'
import { Creators as AppActions } from '../../../../redux/reducers/app'
import { Creators as RebateActions } from '../../../../redux/reducers/api/posRebate'
import FliptButton from '../../../../components/form/fliptButton'
import { drugGroupValues } from '../../../../config/constants/clinical/drugGroups'
import { convertStrToDateObj, addRemoveDaysToDate } from '../../../../utils/utilities'
import FliptHierarchyDropdown from '../../../../components/form/fliptHierarchyDropdown'

class CopyRebateObject extends Component {

    constructor(props) {
        super(props)
        this.state = {
            form: {
                doc_name: props.data[0]?.['module_name'],
                effective_start_date: '',
                questionnaire_name: '',
                questionnaire_link: '',
                ql_reason: '',
                doc_id: props.state?.rebateData?.doc_id || '',
                module: 'POS_REBATE',
                version: props.data[0]?.['version'],
                status: 'DRAFT',
                hierarchy: [],
            },
            activeQLConditionLevel: 0,
            showCriteria: [false],
            dropdownValues: [[{
                drug_group: drugGroupValues, drug_class: [], drug_subclass: [],
            }]],
            autoSuggestionValues: [[{
                manufacturer: { values: [], loading: false }, drug_name: { values: [], loading: false },
            }]],
            rowUpdated: null,
            columnUpdated: null,
            levelUpdated: null,
            autoSuggestionMinChars: 3,
        }

    }

    componentDidMount() {
        const { actions, data } = this.props
        actions.clearPOSRebateData()
        if (data[0]?.id) {
            actions.getPOSRebateData({ doc_id: data[0]?.id })
        }
    }

    componentWillUnmount() {
        this.props.actions.clearPOSRebateData()
    }

    _updateFields = (el, dateData) => {
        const { name, value } = dateData || el.currentTarget
        this.setState((prevState) => ({
            ...prevState,
            form: {
                ...prevState.form,
                [name]: value,
            },
        }))
    }

    setForm = (newForm) => {
        this.setState((prevState) => ({
            ...prevState,
            form: newForm
        }))
    }

    _saveRebate = () => {
        const { props: { state: propState, actions }, state } = this
        const { form } = state
        if (!form.doc_name || !form.effective_start_date) {
            const transitionalPortal = {
                header: 'POS Rebate Save Failed',
                copy: !form.doc_name ? 'Please input a POS Rebate Name before saving' : 'Please select a start date before saving',
            }
            actions.displayTransitionalPortal(transitionalPortal)
        } else {
            const { showTabFlag, setShowTabFlag } = this.props
            actions.savePOSRebateData({ ...form, conditions: propState.rebateCondData, criteria_ids: propState.criteria_ids });
            setShowTabFlag(!showTabFlag);
            setTimeout(() => actions.getAllRebateData(), Config.COMPONENT_DATA_LOAD_TIME)
        }
    }

    render() {
        const { state, props } = this
        const { form } = state
        const { showTabFlag, setShowTabFlag, data } = props

        const { doc_name, effective_start_date } = form
        const minQLStartDate = addRemoveDaysToDate(1, false)

        return (
            <div id="copyQuantityLimit">
                <div className='content copy-object'>
                    <div className="q-inputs">
                        <span>POS Rebate Name</span>
                        <FliptInput className="createQLInputs" placeholder="POS Rebate Name" name="doc_name" value={doc_name} onChange={this._updateFields} />
                    </div>
                    <div className="q-inputs">
                        <span>Effective Start Date</span>
                        <FliptDatePicker className="create-q-start-date" placeholder="Effective Start Date" name="effective_start_date" minDate={minQLStartDate} value={convertStrToDateObj(effective_start_date)} onChange={this._updateFields} />
                    </div>
                    <FliptHierarchyDropdown form={form} setForm={this.setForm} />
                    <div className="buttonContainer">
                        <FliptButton name="Copy Object" className="primary searchButton" onClick={this._saveRebate} />
                    </div>
                    <div className="buttonContainer">
                        <FliptButton name="Cancel" className="secondary searchButton" onClick={() => setShowTabFlag(!showTabFlag)} />
                    </div>
                </div>
            </div>
        )

    }
}


const mapStateToProps = (state) => ({
    state: {
        rebateData: state.rebateCreation?.rebateData,
        rebateCondData: state.rebateCreation?.rebateCondData,
        criteria_ids: state.rebateCreation?.rebateCriteriaIdData || [],
    },
})

const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...AppActions,
        ...RebateActions,
    }

    return {
        actions: bindActionCreators(allActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CopyRebateObject)
