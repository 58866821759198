export default [
  {
    key: 'Fixed',
    text: 'Fixed',
    value: 'FIXED',
  },
  {
    key: 'Variable',
    text: 'Variable',
    value: 'VARIABLE',
  },
]

export const rateType = [
  {
    key: 'Negative',
    text: 'Negative',
    value: 'NEGATIVE',
  },
  {
    key: 'Positive',
    text: 'Positive',
    value: 'POSITIVE',
  },
]
