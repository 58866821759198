import { createActions, createReducer } from 'reduxsauce'

/*
  THIS STATE SHOULD ONLY HAVE 1 propert (history).
  This state should only be used in sagas. DO NOT USE THIS WITHIN COMPONENTS
  INSTEAD USE this.props.history within components
*/
const INITIAL_STATE = {
  history: {},
}

export const setNavigationHistoryReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  history: payload.history,
})

export const { Types, Creators } = createActions({
  navigateBack: ['payload'],
  navigateTo: ['payload'],
  navigateRefresh: null,
  setNavigationHistory: ['payload'],
})

const HANDLERS = {
  [Types.SET_NAVIGATION_HISTORY]: setNavigationHistoryReducer,
}

export default createReducer(INITIAL_STATE, HANDLERS)
