import React from 'react'
import { Dimmer, Loader } from 'semantic-ui-react'

export default function FliptLoader(props) {
  const { active, loaderText, size } = props
  return (
    <div className="Loader">
      <Dimmer active={active} size={size} inverted page>
        <Loader inverted>{loaderText}</Loader>
      </Dimmer>
    </div>
  )
}
