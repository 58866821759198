export const durType = [
    'Drug to Drug Interaction',
    /*'Fill Too Soon',
    'Drug to Geriatric',
    'Drug to Paediatric',
    'Drug to Gender',
    'Minimum Dosage',
    'Maximum Dosage',
    'Duplicate Therapy',
    'Duplicate Ingredient',
    'Fill Too Late',
    'Drug to Known Disease',
    'Controlled Substance Limits',
    'Drug to Inferred Disease',
    'Drug to Lactation',
    'Drug to Allergy'*/
]

export const roaExclusionsOptions = [
    { key: 0, text: 'Vaginal', value: 'VA' },
    { key: 1, text: 'Urethral', value: 'UR' },
    { key: 2, text: 'Intrasinal', value: 'SN' },
    { key: 3, text: 'Otic', value: 'OT' },
    { key: 4, text: 'Ophthalmic', value: 'OP' },
    { key: 5, text: 'Nasal (NS)', value: 'NS' },
    { key: 6, text: 'Nasal (NA)', value: 'NA' },
    { key: 7, text: 'Mouth/Throat', value: 'MT' },
    { key: 8, text: 'Intralesional', value: 'LS' },
    { key: 9, text: 'Dental', value: 'DT' },
    { key: 10, text: 'Intravesical', value: 'IS' },
    { key: 11, text: 'Irrigation', value: 'IR' },
    { key: 12, text: 'Externa', value: 'EX' },
]

export const severity = [
    { key: 3, text: '3-Major', value: 3 },
    { key: 2, text: '2-Moderate', value: 2 },
    { key: 1, text: '1-Minor', value: 1 },
]

export const managementLevel = [
    { key: 3, text: '3-Required', value: 3 },
    { key: 2, text: '2-Suggested', value: 2 },
    { key: 1, text: '1-Potential', value: 1 },
]

export const onsetType = [
    { key: 2, text: '2-Rapid', value: 2 },
    { key: 1, text: '1-Delayed', value: 1 },
]

export const applicationType = [
    'Claims_Processor',
    'Application/Web',
]

export const rejectType = [
    'Hard Reject',
    'Soft Reject',
    'Message',
]

export const regimenComplianceRejectType = [
    'Message',
]

export const standardMessage = {
    '88': 'DUR Reject Error',
    '922': 'Morphine Milligram Equivalency (MME) Exceeds Limits',
    '923': 'Morphine Milligram Equivalency (MME) Exceeds Limits for Patient Age'
}

export const regimenComplianceRejectMessage = {
    '88': 'DUR Reject Error',
}
export const rejectCode = [
    '88',
]

export const opioidRejectCode = [
    '88',
    '922',
    '923'
]

export const messageType = [
    'Custom',
    'Standard',
]

export const regimenCompliancemessageType = [
    'Custom',
]

export const ageGendereditType = [
    'Age Pediatric',
    'Age Geriatric',
    'Gender Male',
    'Gender Female',
]

export const memberType = [
    'Naive',
    'Non-Naive',
]

export const fillsAllowedType = [
    'Yes',
    'No',
]



export const medispan_allowance = [
    'Yes',
    'No',
]

export const maintenance_drug = [
    'Yes',
    'No',
]

export const gpiLevel = [
    'GPI 14',
]

export const editType = [
    'Minimum Dosage',
    'Maximum Dosage',
    'Lifetime Dosage'
]

export const editTypeDosageRestriction = [
    'Minimum Dosage',
    'Maximum Dosage',
]

export const significance_code = [
    "1",
    "2",
]


export const documentationLevel = [
    { key: 5, text: '5-Established', value: 5 },
    { key: 4, text: '4-Probable', value: 4 },
    { key: 3, text: '3-Suspected', value: 3 },
    { key: 2, text: '2-Possible', value: 2 },
    { key: 1, text: '1-Doubtful/Unkown', value: 1 },
]