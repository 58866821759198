import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Creators as FormularyCreationActions } from '../../../../../../redux/reducers/api/formularyCreationManagement'

import './styles.scss'
import { Creators as AppActions } from '../../../../../../redux/reducers/app'
import FliptGrid from '../../../../../../components/fliptGrid';
import FliptPaInput from '../../../../../../components/form/fliptPaInput';
import FliptButton from '../../../../../../components/form/fliptButton';

class USPSearch extends Component {
    constructor(props) {
        super(props)

        this.state = {
            param: {
                rxcui: '',
                ndc: '',
                drug_name: '',
                usp_category: '',
                usp_class: '',
                usp_pharmacotherapeutic_group: '',
            },
            agGridRef: React.createRef(),
        }
    }

    componentDidMount() {
        const { actions: { closeSidebar } } = this.props
        closeSidebar()
    }

    _updateForm = (el, dropdown) => {
        const { param } = this.state
        const { name, value } = dropdown || el.currentTarget
        param[name] = value
        this.setState({ param })
    }

    uspSearch = () => {
        const { agGridRef } = this.state
        agGridRef.current.api.refreshServerSideStore({ route: null, purge: true })
    }

    clearFields = () => {
        const { agGridRef, param } = this.state
        Object.keys(param).forEach((key) => {
            param[key] = ''
        })
        this.setState({ param })
        agGridRef.current.api.refreshServerSideStore({ route: null, purge: true })
    }

    render() {
        const { actions } = this.props
        const { param, agGridRef } = this.state
        const headers = ['rxcui', 'tty', 'name', 'related_bn', 'related_df', 'usp_category', 'usp_class', 'usp_pharmacotherapeutic_group', 'effective_begin_date', 'effective_end_date']

        const cellRendererParams = {
            related_bn: {
                overrideHeader: 'Related Brand Name (BN)'
            },
            rxcui: {
                overrideHeader: 'RXCUI'
            },
            rxnorm_name: {
                overrideHeader: 'RxNorm Description'
            },
            tty: {
                overrideHeader: 'Term Type'
            },
            related_df: {
                overrideHeader: 'Related Dose Form (DF)'
            }
        }

        const serverSideGridConfig = {
            rowModel: 'serverSide',
            serverSideStoreType: 'partial',
            cacheBlockSize: 20,
            pagination: true,
            paginationPageSize: 20,
        }
        const serverSideGridParams = {
            form: param,
            apiId: 'usp',
            sagaToCall: actions.uspSearch,
            headers: headers,
        }

        return (
            <div id="frf-search">
                <div className='header'>
                    USP Search
                </div>
                <div className='devider' />
                <div className='field-outer-container'>
                    <div className='field-inner-container'>
                        <FliptPaInput stylized name='rxcui' value={param.rxcui} label='RXCUI' onChange={this._updateForm} />
                        <FliptPaInput stylized name='ndc' value={param.ndc} label='NDC' onChange={this._updateForm} />
                    </div>
                    <div className='field-inner-container'>
                        <FliptPaInput stylized name='drug_name' value={param.drug_name} label='Drug Name' onChange={this._updateForm} />
                        <FliptPaInput stylized name='usp_category' value={param.usp_category} label='USP Category' onChange={this._updateForm} />
                    </div>
                    <div className='field-inner-container'>
                        <FliptPaInput stylized name='usp_class' value={param.usp_class} label='USP Class' onChange={this._updateForm} />
                        <FliptPaInput stylized name='usp_pharmacotherapeutic_group' value={param.usp_pharmacotherapeutic_group} label='USP Pharmacotherapeutic Group' onChange={this._updateForm} />
                    </div>
                </div>
                <div className='button-container'>
                    <FliptButton name='Search' className='primary' onClick={() => this.uspSearch()} />
                    <FliptButton name='Clear' onClick={() => this.clearFields()} />
                </div>
                <div className='devider' />
                <div className='grid-container'>
                    <FliptGrid
                        headers={headers}
                        cellRendererParams={cellRendererParams}
                        agGridRef={agGridRef}
                        serverSideGridConfig={serverSideGridConfig}
                        serverSideGridParams={serverSideGridParams}
                    />
                </div>
            </div >
        )
    }
}

const mapStateToProps = (state) => ({
    state: {
        app: state.app,
        uspSearchData: state.groupManagement.uspSearchData
    },
})

const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...FormularyCreationActions,
        ...AppActions,
    }

    return {
        actions: bindActionCreators(allActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(USPSearch)
