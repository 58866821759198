import React, { useState, useEffect, useRef } from "react";
import * as XLSX from "xlsx";
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import { Creators as AppActions } from "../../../../redux/reducers/app";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import FliptInput from "../../../../components/form/fliptInput";
import moment from "moment";
import { Button, Icon, Modal, Popup, Tab, Table } from "semantic-ui-react";
import { Creators as FileConfigurationActions } from "../../../../redux/reducers/api/fileConfiguration";
import FliptPaDropdown from "../../../../components/form/fliptPaDropdown";
import FliptRadio from "../../../../components/form/fliptRadio";
import FliptLabel from "../../../../components/form/fliptLabel";
import FliptButton from "../../../../components/form/fliptButton";
import DraggableTableRow from "../../planDesignManagement/listingHierarchy/draggableList"; ///"./draggableList";
import EDI834Renderer from "./components/834"
import Info from "./Info";
import { renderDynamicInput, sampleData } from "./utils";

const flattenDataForGrid = (data = [], parent = [], series = 0) => {
  let position = series;
  const newData = []
  for (let i = 0; i < data.length; i++) {
    const item = data[i]
    const newItem = {
      ...item,
      id: `${item.std_field}-${i}`,
      path: [...parent, item.std_field],
      'Standard Field Name': item.std_field_name,
      'Standard Field': item.std_field,
      position,
    }
    position++;
    let children = []
    if (item.fields && Array.isArray(item.fields)) {
      children = flattenDataForGrid(item.fields, newItem.path, position)
      position += children.length
    }
    newItem.fields = undefined,
      newData.push(newItem, ...children)
  }
  return newData
}

const OutboundFormat = (props) => {
  const { state } = props;
  const {
    definedConfigurationList,
    formatDropDownOptions,
    formatHasCustomStandard,
    fieldValidations,
    fieldsValidationResults,
    supportedFormats,
    validations,
    validationResults,
  } = state;

  const showErrors =
    validationResults && validationResults && !validationResults.isAllValid;
  const showFieldErrors =
    fieldValidations &&
    fieldsValidationResults &&
    !fieldsValidationResults.isAllValid;
  const { dateFormat, fieldTypes, numberFormat } =
    props.state.outboundFormatOptions;

  const [commonData, setCommonData] = useState({
    controlHeader: [
      {
        um_type: "Oraganization Name",
        rank: 1,
      },
      {
        um_type: "Segment Name",
        rank: 2,
      },
      {
        um_type: "Required",
        rank: 3,
      },
    ],
    groupHeader: [
      {
        um_type: "Oraganization Name",
        rank: 1,
      },
      {
        um_type: "Segment Name",
        rank: 2,
      },
      {
        um_type: "Required",
        rank: 3,
      },
    ],
    selectedList: "",
  });
  const [toggleFieldModal, setToggleFieldModal] = useState(false);
  const gridRef = useRef(null)
  const [selectedFields, setSelectedFields] = useState(null)

  const [uploading, setUploading] = useState(false);
  const [uploadLabel, setUploadLabel] = useState("Upload");
  const _updateFields = (el, dropdown) => {
    const { name, value } = dropdown || el.currentTarget;
    if (name === "import_file_path") {
      props.actions.updateOutboundFormatForm({ name, value: el.target.files[0] });
      setUploadLabel("Upload");
      return;
    }
    props.actions.updateOutboundFormatForm({ name, value });
  };

  const _updateEditFields = (el, dropdown) => {
    const { name, value } = dropdown || el.currentTarget;
    props.actions.updateEditFieldsForm({ name, value });
  };

  useEffect(() => {
    if (uploadLabel === "Complete") {
      setUploadLabel("");
      props.actions.setFieldImportConfigListFromSheet();
    }
  }, [uploadLabel]);

  useEffect(() => {
    if (!props.state.outboundFormat?.import_file_type) return;
    props.actions.getFileType({
      file_direction: props.state.fileDirection,
      file_type: props.state.fileType,
      format: props.state.outboundFormat?.import_file_type,
    })
  }, [props.state.outboundFormat?.import_file_type]);

  const onRowSelected = (event) => {
    if (!event.node.isSelected()) return;
    let parentNode = event.node.parent;
    if (parentNode && !parentNode.isSelected()) {
      parentNode.setSelected(true);
    }
  };

  const onSelectionChanged = (data) => {
    const selectedRows = data.api.getSelectedRows();
    if (selectedRows.length) {
      setSelectedFields([...selectedRows])
    } else {
      setSelectedFields(null)
    }
  }
  const handleSelectedFields = () => {
    props.actions.setCustomOutboundFields(selectedFields || [])
  }

  const handleUpload = () => {
    const { outboundFormat } = props.state;
    const { import_file_path } = outboundFormat;
    if (!import_file_path) return;
    setUploading(true);
    try {
      const reader = new FileReader();
      reader.onload = function (evt) {
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: "binary" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
        props.actions.setUploadedFileData(data);
        setUploading(false);
        setUploadLabel("Complete");
      };
      reader.readAsBinaryString(import_file_path);
    } catch (error) {
      console.log(error);
      setUploading(false);
    }
  };

  const { flattenedStandardFields, outboundFormat } = props.state;
  const {
    import_format,
    import_file_type,
    import_delimiter_value,
    import_header_trailer,
    import_end_of_character,
    import_end_of_character_value,
    import_excel_file_ind,
    import_excel_file,
    import_file_path,
    record_type,
    multi_record_detail,
    multi_record_detail_title,
    field_name,
    field_helper_text,
    field_size,
    field_type,
    field_type_format,
    field_starting_position,
    field_ending_position,
    field_transformation_note,
    field_format_instructions,
    custom_group_file_info,
    import_delimiter_other_notes,
  } = outboundFormat;

  const isFixedWidthFile = import_file_type?.toLowerCase() === "fixed width" || import_file_type?.toLowerCase() === "fixed_width";
  const { selectedFileHeader } = props.state.outboundFormatState;
  const { multi_record_detail_title: override_multi_record_detail_title } =
    selectedFileHeader || {};

  const renderFileFormats = () => {
    if (supportedFormats?.length === 0 || !supportedFormats) {
      return (
        <>
          <div className="fields-container">
            <div className="fields-header">
              <div className="field-label">
                <span>Type of file to be processed</span>
                <Info
                  content="Is the file delimited or fixed width"
                  position="top right"
                />
              </div>
            </div>

            <div className="fields-header">
              <div className="field-label-radio">
                <div className="divide-half-radio">
                  <FliptRadio
                    className="radio-input"
                    name="import_file_type"
                    value={"Delimited"}
                    checked={import_file_type?.toLowerCase() === "delimited"}
                    onChange={_updateFields}
                  />
                  <FliptLabel
                    className="radio-label"
                    description=""
                    label="Delimited"
                  />
                  {showErrors && !validationResults?.import_file_type && (
                    <section className="flipt-input-error">
                      <em>
                        {validations.import_file_type?.errorMessage || null}
                      </em>
                    </section>
                  )}
                </div>
                <div className="divide-half-radio">
                  <FliptRadio
                    className="radio-input"
                    toggle={false}
                    name="import_file_type"
                    value={"Fixed Width"}
                    checked={import_file_type?.toLowerCase() === "fixed width"}
                    onChange={_updateFields}
                  />
                  <FliptLabel
                    className="radio-label"
                    description=""
                    label="Fixed Width"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="fields-container">
            <div className="fields-header">
            </div>
            <div className="fields-header">
              <div className="field-label-radio">
                <div className="divide-half-radio">
                  <FliptRadio
                    className="radio-input"
                    name="import_file_type"
                    value={"JSON"}
                    checked={import_file_type?.toLowerCase() === "json"}
                    onChange={_updateFields}
                  />
                  <FliptLabel
                    className="radio-label"
                    description=""
                    label="JSON"
                  />
                  {showErrors && !validationResults?.import_file_type && (
                    <section className="flipt-input-error">
                      <em>
                        {validations.import_file_type?.errorMessage || null}
                      </em>
                    </section>
                  )}
                </div>
                <div className="divide-half-radio">
                  <FliptRadio
                    className="radio-input"
                    name="import_file_type"
                    value={"Excel"}
                    checked={import_file_type?.toLowerCase() === "excel"}
                    onChange={_updateFields}
                  />
                  <FliptLabel
                    className="radio-label"
                    description=""
                    label="Excel"
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }

    return (
      <div className="fields-container">
        <div className="fields-header">
          <div className="field-label">
            <span>Type of file to be processed</span>
            <Info
              content="Is the file delimited or fixed width"
              position="top right"
            />
          </div>
        </div>

        <div className="fields-header">
          <div className="field-label-radio">
            <div className="divide-half-radio">
              <FliptPaDropdown
                stylized
                name="import_file_type"
                onChange={_updateFields}
                simple
                className="half-width"
                value={import_file_type}
                options={import_format?.toLowerCase() === 'file standard' ? formatDropDownOptions?.standard : formatDropDownOptions?.custom}
                placeholder="Field Type"
                log={true}
              />
            </div>
            <div className="divide-half-radio" />
          </div>
        </div>

      </div>
    )
  }

  const renderAdditionalFields = () => {
    const { additionalFields } = props.state;
    if (additionalFields?.length == 0) return null;
    const rowDivider = 1;
    const fieldProps = {
      ...outboundFormat,
      onChange: _updateFields,
      className: "fields-header",
      num: 1,
    };
    return (
      <>
        {Array.from(
          { length: Math.ceil(additionalFields.length / rowDivider) },
          (_, i) => (
            <div className="fields-container" key={`row${i}`}>
              {additionalFields
                .slice(i * rowDivider, (i + 1) * rowDivider)
                .map((movie) => renderDynamicInput(movie, fieldProps))}
            </div>
          )
        )}
      </>
    );
  };
  const swap = (a, b, selectedList) => {
    const customState = commonData;
    customState[selectedList][a] = customState[selectedList].splice(
      b,
      1,
      customState[selectedList][a]
    )[0];
    customState[selectedList].map((item, i) => (item["rank"] = i + 1));

    setCommonData({
      ...customState,
    });

    delete customState.selectedList;
  };

  const _renderDraggableList = (items, key, selectedList) => {
    return (
      <Table className="table-padding">
        <Table.Body>
          {items.map((obj, i) => (
            <DraggableTableRow
              key={i}
              i={i}
              action={(a, b) => swap(a, b, selectedList)}
            >
              {" "}
              <Table.Cell>
                <div className="fields-container">
                  <div className="fields-table-header">
                    <div className="field-label">
                      <Icon name=" sliders horizontal" />
                    </div>
                  </div>
                </div>
              </Table.Cell>
              <Table.Cell>{i + 1}</Table.Cell>
              <Table.Cell>{"Detail"}</Table.Cell>
              <Table.Cell>
                <div className="fields-container">
                  <div className="fields-table-header">
                    <div className="field-label">
                      <span>{obj[key]}</span>
                      <Icon name="question circle outline" />
                    </div>
                    <FliptInput className="field-input" />
                  </div>
                </div>
              </Table.Cell>
              <Table.Cell>{"Field Starting Position"}</Table.Cell>
              <Table.Cell>{"Field Starting Position"}</Table.Cell>
              <Table.Cell>
                <div className="fields-container">
                  <div className="fields-table-header">
                    <div className="field-label">
                      <Icon name="trash" color="red" />
                    </div>
                  </div>
                </div>
              </Table.Cell>
            </DraggableTableRow>
          ))}
        </Table.Body>
      </Table>
    );
  };

  const setInterchangeTableHeader = (title) => {
    const { selectedFileHeader } = props.state.outboundFormatState;
    const { multi_record_detail_title } = selectedFileHeader || {};
    if (title === multi_record_detail_title)
      return "interchange-table-selected";
    return "interchange-table";
  };

  const renderRequiredForStandardField = (required, toolTip) => {

    switch (required?.toLowerCase()) {
      case 'yes':
        return (
          <Popup
            trigger={<Icon circular name="check" color="black" />}
            content={"Required"}
          />
        )
      case 'conditional':
        return (
          <Popup
            trigger={<Icon circular name="warning sign" color="yellow" />}
            content={toolTip || "This field may be required based on configuration"}
          />
        )
      case 'no':
      default:
        return null
    }
  }

  const _renderDraggableFieldList = (items, key = "test", selectedList) => {
    return items.map(
      (
        { multi_record_detail_title, fields, id: parent_id, record_type },
        i
      ) => (
        <div
          className={setInterchangeTableHeader(multi_record_detail_title)}
          onClick={() =>
            definedConfigurationList.length > 1 &&
            multi_record_detail_title &&
            props.actions.setFileImportHeaderSelection({
              multi_record_detail_title,
              parent_id,
            })
          }
        >
          <Table>
            <Table.Header>
              {multi_record_detail_title && (
                <Table.Row>
                  <Table.HeaderCell colSpan={9}>
                    Header: {multi_record_detail_title}
                  </Table.HeaderCell>
                </Table.Row>
              )}
              <Table.Row>
                <Table.HeaderCell colSpan={1}></Table.HeaderCell>
                <Table.HeaderCell colSpan={1}></Table.HeaderCell>
                <Table.HeaderCell colSpan={1}>Record Type</Table.HeaderCell>
                <Table.HeaderCell colSpan={1}>Field Name</Table.HeaderCell>
                <Table.HeaderCell colSpan={1}>Field Type</Table.HeaderCell>
                <Table.HeaderCell colSpan={1}>
                  Field Type Format
                </Table.HeaderCell>
                {isFixedWidthFile ? (
                  <>
                    <Table.HeaderCell colSpan={1}>
                      Starting Position
                    </Table.HeaderCell>
                    <Table.HeaderCell colSpan={1}>
                      Ending Position
                    </Table.HeaderCell>
                  </>
                ) : null}
                <Table.HeaderCell colSpan={1}>Action</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {fields.map((field, i) => {
                const {
                  id,
                  field_name,
                  field_type,
                  field_type_format,
                  field_starting_position,
                  field_ending_position,
                } = field;
                const fieldToModify =
                  props.state.outboundFormatState.fieldToModify || {};
                const { id: modifyId } = fieldToModify;
                const editing = modifyId === id;
                return (
                  <DraggableTableRow
                    key={id}
                    i={i}
                    action={(a, b) => swap(a, b, selectedList)}
                  >
                    {" "}
                    <Table.Cell>
                      <div className="fields-container">
                        <div className="fields-table-header">
                          <div className="field-label">
                            <Icon name=" sliders horizontal" />
                          </div>
                        </div>
                      </div>
                    </Table.Cell>
                    <Table.Cell>{i + 1}</Table.Cell>
                    <Table.Cell>{record_type}</Table.Cell>
                    <Table.Cell>
                      <div className="fields-container">
                        <div className="fields-table-header">
                          <div className="field-label">
                            {editing ? (
                              <FliptInput
                                className="field-input"
                                name="field_name"
                                placeholder="Segment Name"
                                value={fieldToModify.field_name}
                                onChange={_updateEditFields}
                                validation="name"
                              />
                            ) : (
                              <span>{field_name}</span>
                            )}
                          </div>
                        </div>
                      </div>
                    </Table.Cell>
                    <Table.Cell style={{ verticalAlign: "middle" }}>
                      {editing ? (
                        <FliptPaDropdown
                          stylized
                          name="field_type"
                          onChange={_updateEditFields}
                          simple
                          className="half-width"
                          value={fieldToModify.field_type}
                          options={fieldTypes}
                          placeholder="Field Type"
                          log={true}
                        />
                      ) : (
                        field_type
                      )}
                    </Table.Cell>
                    <Table.Cell style={{ verticalAlign: "middle" }}>
                      {editing ? (
                        <>
                          {fieldToModify.field_type === "Date" && (
                            <FliptPaDropdown
                              stylized
                              name="field_type_format"
                              onChange={_updateEditFields}
                              simple
                              className="half-width"
                              value={fieldToModify.field_type_format}
                              options={dateFormat}
                              placeholder="Date Format"
                            />
                          )}
                          {fieldToModify.field_type === "Number" && (
                            <FliptPaDropdown
                              stylized
                              name="field_type_format"
                              onChange={_updateEditFields}
                              simple
                              className="half-width"
                              value={fieldToModify.field_type_format}
                              options={numberFormat}
                              placeholder="Number Format"
                            />
                          )}
                        </>
                      ) : (
                        field_type_format
                      )}
                    </Table.Cell>
                    {isFixedWidthFile ? (
                      <>
                        <Table.Cell>
                          {editing ? (
                            <FliptInput
                              className="field-input"
                              name="field_starting_position"
                              validation="numeric"
                              value={fieldToModify.field_starting_position}
                              onChange={_updateEditFields}
                            />
                          ) : (
                            field_starting_position
                          )}
                        </Table.Cell>
                        <Table.Cell>
                          {editing ? (
                            <FliptInput
                              className="field-input"
                              name="field_ending_position"
                              value={fieldToModify.field_ending_position}
                              validation="numeric"
                              onChange={_updateEditFields}
                            />
                          ) : (
                            field_ending_position
                          )}
                        </Table.Cell>
                      </>) : null}
                    <Table.Cell>
                      <div className="fields-container">
                        <div className="fields-table-header">
                          <div className="field-label-table">
                            {editing ? (
                              <Icon
                                name="checkmark"
                                color="green"
                                onClick={() =>
                                  props.actions.confirmFieldEditChangesAndSync()
                                }
                              />
                            ) : (
                              <Icon
                                name="pencil"
                                color="blue"
                                onClick={() =>
                                  props.actions.setFieldToEdit(field)
                                }
                              />
                            )}
                            <Icon
                              name="trash"
                              color="red"
                              onClick={() =>
                                props.actions.deleteFieldImportConfigAndSync(
                                  field
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </Table.Cell>
                  </DraggableTableRow>
                );
              })}
            </Table.Body>
          </Table>
        </div>
      )
    );
  };

  const renderFileStandard = () => {
    if (import_format !== "File Standard") return null;
    if (!import_file_type) return null;
    const { fileStandardFields = [], formatStandard = {} } = props.state;
    const fieldsToRender = (formatHasCustomStandard ? formatStandard?.fields : fileStandardFields) || [];
    if (fieldsToRender.length === 0) return null;
    if (import_file_type === '834') return <EDI834Renderer />
    return (
      <section>
        <section>
          <h3 className="section-header">File Standard</h3>
        </section>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan={1}>
                Field Name
              </Table.HeaderCell>
              {isFixedWidthFile ? (
                <>
                  <Table.HeaderCell colSpan={1}>
                    Starting Position
                  </Table.HeaderCell>
                  <Table.HeaderCell colSpan={1}>
                    Ending Position
                  </Table.HeaderCell>
                </>
              ) : null}
              <Table.HeaderCell colSpan={1}>
                Required
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {
              fieldsToRender.map((item, index) => {
                return (
                  <Table.Row
                    key={item.std_field + '-' + index}
                  >
                    <Table.Cell>
                      {item.std_field_name}
                    </Table.Cell>
                    {isFixedWidthFile ? (
                      <>
                        <Table.Cell colSpan={1}>
                          {item.std_start_position || ''}
                        </Table.Cell>
                        <Table.Cell colSpan={1}>
                          {item.std_end_position || ''}
                        </Table.Cell>
                      </>
                    ) : null}
                    <Table.Cell>
                      {renderRequiredForStandardField(item.required, item.tool_tip)}
                    </Table.Cell>
                  </Table.Row>
                )
              })
            }
          </Table.Body>
        </Table>
      </section>
    )
  }

  const renderOutboundFields = () => {
    const { customOutboundFields = [] } = props.state;
    if (customOutboundFields.length === 0 || import_format !== "File Custom") return null;
    const displayParentColumn = customOutboundFields.some((item) => item.path.length > 1)
    return (
      <section>
        <section>
          <h3 className="section-header">Custom Fields</h3>
        </section>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan={1} />
              <Table.HeaderCell colSpan={1}>
                Field Name
              </Table.HeaderCell>
              {displayParentColumn && (
                <Table.HeaderCell colSpan={1}>
                  Parent Field Key
                </Table.HeaderCell>
              )}
              <Table.HeaderCell colSpan={1}>
                Field Key
              </Table.HeaderCell>
              <Table.HeaderCell colSpan={1}>
                Field Type
              </Table.HeaderCell>
              <Table.HeaderCell colSpan={1}>
                Target Field
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {
              customOutboundFields.map((item, index) => {
                return (
                  <Table.Row
                    key={item.std_field + '-' + index}
                  >
                    <Table.Cell textAlign="center">
                      <Icon
                        name="trash"
                        color="red"
                        onClick={() =>
                          props.actions.deleteCustomOutboundField(index)
                        }
                      />
                    </Table.Cell>
                    <Table.Cell>
                      {item.std_field_name}
                    </Table.Cell>
                    {displayParentColumn && (
                      <Table.Cell>
                        {item.path.length > 1 ? item.path[item.path.length - 2] : ''}
                      </Table.Cell>
                    )}
                    <Table.Cell>
                      {item.std_field}
                    </Table.Cell>
                    <Table.Cell>
                      {item.std_datatype}
                    </Table.Cell>
                    <Table.Cell>
                      <FliptInput
                        name="target_field"
                        placeholder="Target"
                        value={item.target_field}
                        onChange={(e) => props.actions.updateCustomOutboundFields({ index, item: { ...item, target_field: e.target.value } })}
                      />
                    </Table.Cell>
                  </Table.Row>
                )
              })
            }
          </Table.Body>
        </Table>
      </section>
    )
  }

  return (
    <div id="file-configuration">
      <div className="section-header">File Details</div>

      <div className="content shadow margin-bottom ">
        <div className="fields-container">
          <div className="fields-header">
            <div className="field-label">
              <span>
                Please define which format the Client will be submitted this
                file
              </span>
              <Info
                content="Will the file be submitted in a standard format or custom format"
                position="top right"
              />
            </div>
          </div>
          <div className="fields-header">
            <div className="field-label-radio">
              <div className="divide-half-radio">
                <FliptRadio
                  className="radio-input"
                  name="import_format"
                  value={"File Standard"}
                  checked={import_format === "File Standard"}
                  onChange={_updateFields}
                />
                <FliptLabel
                  className="radio-label"
                  description=""
                  label="File Standard"
                />
                {showErrors && !validationResults?.import_format && (
                  <section className="flipt-input-error">
                    <em>{validations.import_format?.errorMessage || null}</em>
                  </section>
                )}
              </div>
              <div className="divide-half-radio">
                <FliptRadio
                  className="radio-input"
                  toggle={false}
                  name="import_format"
                  value={"File Custom"}
                  checked={import_format === "File Custom"}
                  onChange={_updateFields}
                />
                <FliptLabel
                  className="radio-label"
                  description=""
                  label="File Custom"
                />
              </div>
            </div>
          </div>
        </div>

        {renderFileFormats()}

        {import_file_type?.toLowerCase() === "delimited" && (
          <div className="fields-container">
            <div className="fields-header">
              <div className="field-label">
                <span>Delimiter Value</span>
                <Info
                  content="If delimited, what is the delimiter"
                  position="top right"
                />
              </div>
            </div>
            <div className="fields-header">
              <div className="field-label-radio">
                <div className="divide-half-radio">
                  <FliptRadio
                    className="radio-input"
                    name="import_delimiter_value"
                    value={"Comma"}
                    checked={import_delimiter_value === "Comma"}
                    onChange={_updateFields}
                  />
                  <FliptLabel
                    className="radio-label"
                    description=""
                    label="Comma"
                  />
                </div>
                <div className="divide-half-radio">
                  <FliptRadio
                    className="radio-input"
                    toggle={false}
                    name="import_delimiter_value"
                    value={"Pipe"}
                    checked={import_delimiter_value === "Pipe"}
                    onChange={_updateFields}
                  />
                  <FliptLabel
                    className="radio-label"
                    description=""
                    label="Pipe"
                  />
                </div>
              </div>
              <div className="field-label-radio">
                <div className="divide-half-radio">
                  <FliptRadio
                    className="radio-input"
                    toggle={false}
                    name="import_delimiter_value"
                    value={"Tab"}
                    checked={import_delimiter_value === "Tab"}
                    onChange={_updateFields}
                  />
                  <FliptLabel
                    className="radio-label"
                    description=""
                    label="Tab"
                  />
                </div>
                <div className="divide-half-radio">
                  <FliptRadio
                    className="radio-input"
                    toggle={false}
                    name="import_delimiter_value"
                    value={"Other"}
                    checked={import_delimiter_value === "Other"}
                    onChange={_updateFields}
                  />
                  <FliptLabel
                    className="radio-label"
                    description=""
                    label="Other"
                  />
                </div>
              </div>
              {import_delimiter_value === "Other" && (
                <div className="field-label-radio">
                  <div className="divide-half-radio">
                    <div className="field-label">
                      <span>Other </span>
                    </div>
                    <FliptInput
                      className="field-input"
                      name="import_delimiter_other_notes"
                      placeholder="placeholder "
                      value={import_delimiter_other_notes}
                      onChange={_updateFields}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        {renderAdditionalFields()}
        <div>
          {import_excel_file_ind === "Yes" && (
            <>
              <div className="section">
                <section className="uploadInputContainer">
                  <div className="uploadInput">
                    <span className="element">
                      Select data file in xlsx, format
                    </span>
                    {/* csv, and xml */}
                    <input
                      className="file-input"
                      type="file"
                      name="import_file_path"
                      id="import_file_path"
                      onChange={_updateFields}
                    />
                    <label for="file">{import_file_path?.name}</label>
                  </div>
                  <div className="buttonContainer">
                    <FliptButton
                      className="primary searchButton"
                      name={
                        uploading ? "Loading..." : uploadLabel || "Complete"
                      }
                      onClick={handleUpload}
                      disabled={
                        !import_file_path ||
                        uploading ||
                        uploadLabel === "Complete" ||
                        uploadLabel === ""
                      }
                    />
                  </div>
                </section>
              </div>
            </>
          )}
        </div>
        <div className="ui divider"></div>

        {import_format === "File Custom" && import_file_type && (
          <>
            <div className="fields-equal">
              <section />
              <Button
                content="Select Field From Standard"
                icon="plus"
                size="small"
                onClick={() => {
                  setToggleFieldModal(!toggleFieldModal)
                }
                }
              />
            </div>

            {definedConfigurationList.length > 0 &&
              _renderDraggableFieldList(definedConfigurationList)}
          </>
        )}
        {renderFileStandard()}
        {renderOutboundFields()}
      </div>
      {toggleFieldModal && (
        <Modal
          id="standard-selection-modal"
          onClose={() => setToggleFieldModal(!toggleFieldModal)}
          onOpen={() => setToggleFieldModal(!toggleFieldModal)}
          open={toggleFieldModal}
          dimmer
          size='small'
        >
          <Modal.Header>Select Field From Standard</Modal.Header>
          <Modal.Content>
            <div className="ag-theme-alpine selection-container">
              <AgGridReact
                ref={gridRef}
                rowData={flattenedStandardFields}
                columnDefs={[]}
                treeData={true}
                animateRows={true}
                onRowSelected={onRowSelected}
                onSelectionChanged={onSelectionChanged}
                getDataPath={data => data.path || []}
                rowSelection={'multiple'}
                suppressRowClickSelection={true}
                autoGroupColumnDef={{
                  headerName: 'Standard Field Name',
                  field: 'std_field_name',
                  minWidth: 500,
                  cellRendererParams: {
                    checkbox: true,
                  },
                }}
              />
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button
              content="Cancel"
              onClick={() => setToggleFieldModal(!toggleFieldModal)}
            />
            <Button
              className="primary"
              content="Confirm"
              onClick={() => {
                handleSelectedFields()
                setToggleFieldModal(!toggleFieldModal)
              }}
            />
          </Modal.Actions>
        </Modal>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  const outboundFormat = state.fileConfiguration.outboundFormat;
  const fileType = state.fileConfiguration.file_type;
  const fileDirection = state.fileConfiguration.file_direction?.toLowerCase() || 'inbound';
  const fileStandardKey = fileType + '_standard';
  const standard = state.fileConfiguration[fileStandardKey];
  const supportedFormats = standard?.supported_formats || [];
  const formatDropDownOptions = supportedFormats.reduce((list, formatOption, index) => {
    const directionSupported = formatOption?.supported_file_direction?.[fileDirection];
    if (directionSupported) {
      const selectableOption = {
        key: index + 1,
        text: formatOption?.label,
        value: formatOption?.format,
      };
      if (directionSupported?.standard) {
        list.standard.push(selectableOption)
      }
      if (directionSupported?.custom) {
        list.custom.push(selectableOption)
      }
    }
    return list;
  }, { standard: [], custom: [] })
  let formatStandard = null;
  const selectedFormat = outboundFormat?.import_file_type;
  const formatHasCustomStandard = (supportedFormats.find((format) => format.format === selectedFormat) || { fields: 'default' })?.fields?.toLowerCase() !== 'default';
  if (formatHasCustomStandard) {
    formatStandard = state.fileConfiguration[selectedFormat + "_format_standard"];
  }
  const flattenedStandardFields = flattenDataForGrid(standard?.fields || [])
  return {
    state: {
      app: state.app,
      customOutboundFields: outboundFormat.customOutboundFields || [],
      outboundFormat: outboundFormat,
      outboundFormatOptions: state.fileConfiguration.options.outboundFormat || {},
      outboundFormatState: state.fileConfiguration.outboundFormatState || {},
      definedConfigurationList: state.fileConfiguration.outboundFormat.defined_configuration_list || [],
      formatDropDownOptions: formatDropDownOptions,
      fileDirection: fileDirection,
      fileStandardFields: standard?.fields,
      flattenedStandardFields: flattenedStandardFields,
      fileType: fileType,
      fieldValidations: state.fileConfiguration.validations.outboundFormatFields,
      formatDropDownOptions: formatDropDownOptions,
      formatHasCustomStandard: formatHasCustomStandard,
      formatStandard: formatStandard,
      supportedFormats: supportedFormats,
      validationResults: state.fileConfiguration.validationResults.outboundFormat,
      fieldsValidationResults: state.fileConfiguration.validationResults.outboundFormatFields,
      uploadedFileData: state.fileConfiguration.uploadedFileData,
      additionalFields:
        state.fileConfiguration.additional_fields?.find(
          (row) => row.page === "import_format"
        )?.fields || [],
      validations: state.fileConfiguration.validations.outboundFormat,
    },
  };
};

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
    ...FileConfigurationActions,
  };
  return {
    actions: bindActionCreators(allActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OutboundFormat);
