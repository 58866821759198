// import React, { useState } from 'react';
import React from 'react'

import './styles.scss'

export default function Footer() {
  return (
    <div id="footer">
      &copy; Copyright
      {' '}
      {new Date().getFullYear()}
    </div>
  )
}
