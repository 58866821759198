import React, { useState, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { Tab } from 'semantic-ui-react'
import { connect } from 'react-redux'

import '../styles.scss'
import CostPane from './CostPane'
import LicsPane from './LicsPane'
import ReviewPane from './ReviewPane'
import { Creators as MedicareSetupActions } from '../../../../../../redux/reducers/api/medicareSetup'
// import '../../../../../../components/form/formRendererStyles.scss'
import { Creators as AppActions } from '../../../../../../redux/reducers/app'
import { Creators as NavigationActions } from '../../../../../../redux/reducers/navigation'
import FliptButton from '../../../../../../components/form/fliptButton'

const phases = ['Deductible', 'Initial Coverage', 'Coverage Gap', 'Catastrophic']
const panes = [...phases, 'LICS', 'Review']

function Panes(props) {
  const {
    editMode, state, planInfo, activateEditMode, actions
  } = props

  const [data, setData] = useState({})
  const [activeIdx, setActiveIdx] = useState(0)

  const _goBack = () => {
    if (activeIdx > 0) setActiveIdx(activeIdx - 1)
  }

  useEffect(() => {
    if (state.activePlan.length === 0) setData(_initializeData())
    else setData(state.activePlan[0])
  }, [])

  useEffect(() => {
    if (state.activePlan.length === 0) setData(_initializeData())
    else setData(state.activePlan[0])
  }, [state, planInfo])

  const _initializeData = () => ({
    medicare_plan_info: {
      doc_name: planInfo.doc_name,
      doc_version: planInfo.doc_version,
      effective_start_date: planInfo.effective_start_date,
      plan_year: planInfo.plan_year,
      medicare_d_standard: planInfo.medicare_d_standard,
      hierarchy: planInfo.hierarchyIDs,
      is_hierarchy_global: planInfo.is_hierarchy_global,
    },
    phases: _initializePhases(),
    lics_plan: state.medicareSetupDetailData[0]
  })

  const _initializePhases = () => phases.map((phase) => ({
    phase,
    begin_amt: 0,
    begin_spend_type: '',
    end_amt: 0,
    end_spend_type: '',
    cost_share: [{
      action: '',
      party: '',
      brand_cost_share: null,
      brand_cost_share_type: '',
      minimum_brand_cost_share: null,
      maximum_brand_cost_share: null,
      generic_cost_share: null,
      generic_cost_share_type: '',
      minimum_generic_cost_share: null,
      maximum_generic_cost_share: null,
    }],
  }))

  const _handleTabChange = (_, { activeIndex }) => setActiveIdx(activeIndex)

  const _submitData = () => {
    const filteredData = data.phases.filter((phaseData) => {
      return parseInt(phaseData.begin_amt) > parseInt(phaseData.end_amt) && parseInt(phaseData.end_amt) !== 0
    });
    // if (data.medicare_plan_info.medicare_d_standard == 'Y') {
    //   data.medicare_plan_info.hierarchy = []
    //   data.medicare_plan_info.is_hierarchy_global = true
    // } else {
    //   data.medicare_plan_info.is_hierarchy_global = false
    // }

    data.medicare_plan_info.is_hierarchy_global = true
    data.medicare_plan_info.hierarchy = planInfo.hierarchyIDs
    data.medicare_plan_info.hierarchyIDs = planInfo.hierarchyIDs
    if (filteredData.length) {
      const transitionalPortal = {
        header: 'Begin Amount is greater',
        copy: 'Begin Amount must be smaller then end amount',
      }
      actions.displayTransitionalPortal(transitionalPortal)
    } else {
      actions.createMedicareDPlan(data)
      actions.navigateTo('/medicare-plan')
    }
  }
  const _renderSection = (idx) => (
    <div className="section">
      <div className="fields-container">
        <div className="tabContebt">
          {
            (panes[idx] === 'LICS' || panes[idx] === 'Review') ? (
              (panes[idx] === 'LICS' ? (
                <LicsPane data={data.lics_plan} />
              ) : (
                <ReviewPane data={data} setActiveIdx={setActiveIdx} activateEditMode={activateEditMode} />
              ))
            ) : (
              <CostPane
                editMode={editMode}
                paneIdx={idx}
                data={data}
                setData={setData}
              />
            )
          }
        </div>
      </div>
    </div>
  )

  return (
    <div className="section">
      {Object.keys(data).length > 0 && (
        <>
          <section className="grid-container spacing border-line shadow">
            <Tab
              panes={panes.map((pane, idx) => ({
                menuItem: pane,
                render: () => _renderSection(idx),
              }))}
              activeIndex={activeIdx}
              onTabChange={_handleTabChange}
            />
          </section>
          <section>
            <div className="prescriber-input prescriber-btn button-row">
              <FliptButton name="Back" className="primary backButton searchButton" onClick={_goBack} />
              <FliptButton name="Save" className="primary searchButton" disabled={!editMode} onClick={_submitData} />
              {activeIdx === 5 ? (
                <FliptButton name="Submit for Approval" className="primary searchButton approvalButton" onClick={_submitData} />
              ) : (
                <FliptButton name="Next" className="primary searchButton" onClick={() => setActiveIdx(activeIdx + 1)} />
              )}
            </div>
          </section>
        </>
      )}
    </div>
  )
}

const mapStateToProps = (state) => ({
  state: {
    activePlan: state.medicareSetup.activePlan,
    medicareSetupDetailData: state.medicareSetup.medicareSetupDetailData,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...MedicareSetupActions,
    ...AppActions,
    ...NavigationActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Panes)
