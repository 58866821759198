import React, { PureComponent } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'
import { Creators as AppActions } from '../../../../redux/reducers/app'
import FliptGrid from '../../../../components/fliptGrid'
import { DeleteRowRenderer, CheckboxUIRenderer } from '../../../../components/fliptGrid/cellRenderers'
import { DropdownEditor, InputTextEditor } from '../../../../components/fliptGrid/cellEditors'
import AddRow from '../addRow'
import './styles.scss'
import { transition as rowCellInfo } from '../data/rowCellInfo'
import _ from 'lodash'


class TransitionFill extends PureComponent {
  constructor(props) {
    super(props)
    const { state } = this.props
    const { transitionData = [] } = state
    this.state = {
      transitionData
    }
  }

  getGridHeaders = () => [
    'action',
    'transition_type',
    'ds_covered',
    'exclusion_list',
    'transition_period',
    "rejects",
    "med_d_only_drug",
    'approval_code',
    'approval_message',
  ]

  renderGrid = (data) => {
    const { fieldDetails, editMode } = this.props
    const rowCellInfoCopy = rowCellInfo
    const headers = this.getGridHeaders()
    if (!editMode) {
      headers.splice(0, 1)
    }
    const handleChange = (e, dropdown, rowKey) => {
      this.handleChange(e, dropdown, rowKey)
    }
    fieldDetails.forEach((element) => {
      const { field } = element
      if (!(field in rowCellInfoCopy)) {
        return
      }

      rowCellInfoCopy[field].options = element.options.map((code, index) => ({
        key: index,
        text: code.display_name,
        value: code.value,
        disabled: code.display_name.includes("76") || code.display_name.includes("60") || code.display_name.includes("61") || code.display_name.includes("80")
          || code.display_name.includes("78") || code.display_name.includes("88") || code.display_name.includes("75")
      }))
    })
    const cellRendererParams = {
      action: {
        cellRenderer: DeleteRowRenderer,
        state: {
          onClick: (index) => this.delRow(index),
        },
        width: 100,
      },
      transition_type: {
        width: 200,
        overrideHeader: 'Transition Type',
      },
      ds_covered: {
        width: 200,
        overrideHeader: 'Days of Supply Covered',
      },
      exclusion_list: {
        width: 200,
        overrideHeader: 'Exclusion List',
      },
      transition_period: {
        width: 200,
        overrideHeader: 'Transition Period',
      },
      rejects: {
        width: 200,
        overrideHeader: 'Rejects',
      },
      med_d_only_drug: {
        width: 200,
        overrideHeader: 'Med D only Drug',
      },
      approval_code: {
        width: 250,
        overrideHeader: 'Approval Message Code',
      },
      approval_message: {
        width: 175,
        overrideHeader: 'Approval Message',
      },
    }
    const cellEditorParams = {
      transition_type: {
        editable: true,
        cellEditor: DropdownEditor,
        onChange: handleChange,
      },
      ds_covered: {
        editable: true,
        cellEditor: InputTextEditor,
        onChange: handleChange,
        validation: 'numeric'
      },
      exclusion_list: {
        editable: true,
        cellEditor: DropdownEditor,
        onChange: handleChange,
      },
      transition_period: {
        editable: true,
        cellEditor: InputTextEditor,
        onChange: handleChange,
      },
      rejects: {
        editable: true,
        cellEditor: DropdownEditor,
        onChange: handleChange,
      },
      med_d_only_drug: {
        editable: true,
        cellEditor: InputTextEditor,
        onChange: handleChange,
      },
      approval_code: {
        editable: true,
        cellEditor: InputTextEditor,
        onChange: handleChange,
      },
      approval_message: {
        editable: true,
        cellEditor: InputTextEditor,
        onChange: handleChange,
      },
    }
    if (editMode) {
      return (
        <div className="grid-container">
          <FliptGrid
            data={data}
            headers={this.getGridHeaders()}
            cellRendererParams={cellRendererParams}
            cellEditorParams={cellEditorParams}
            rowCellInfo={rowCellInfoCopy}
          />
        </div>
      )
    }
    return (
      <div className="grid-container" >
        <FliptGrid
          data={data}
          headers={headers}
        />
      </div>
    )
  }

  addRow = () => {
    const headers = this.getGridHeaders()
    headers.splice(0, 1) // remove action from headers
    const row = headers.reduce((obj, v) => {
      obj[v] = rowCellInfo[v]?.default || ''
      return obj
    }, {})
    const { transitionData } = this.state
    const newState = {
      ...this.state,
      transitionData: [...transitionData, row],
    }
    this.setState(newState)
  }

  delRow = (rowIndex) => {
    const { transitionData } = this.state
    const newTransitionData = [...transitionData]
    newTransitionData.splice(rowIndex, 1) // Delete selected row
    const newState = {
      ...this.state,
      transitionData: [...newTransitionData],
    }
    this.setState(newState)
  }

  handleCheckboxChange = (e, rowIndex, api) => {
    const { transitionData } = this.state
    const newTransitionData = [...transitionData]
    const newState = {
      transitionData: newTransitionData,
    }
    this.setState(newState, () => {
      api.refreshCells()
      this.validateAndUpdateData({ ...this.state })
    })
  }

  debouncedStateUpdate = _.debounce(fn => fn(), 1000)

  handleChange = (e, dropdown, rowKey, source) => {
    const { transitionData } = this.state
    const newTransitionData = [...transitionData]
    const { name, value } = dropdown || e.currentTarget
    newTransitionData[rowKey] = { ...newTransitionData[rowKey], [name]: value }
    this.debouncedStateUpdate(e => this.setState({ transitionData: newTransitionData }, () => {
      this.validateAndUpdateData({ ...this.state })
    }))
  }

  validateAndUpdateData = (data) => {
    const { copyFormData } = this.props
    _.debounce(() => copyFormData({ data }), 500)()

  }

  render() {
    const { editMode } = this.props
    const { transitionData } = this.state

    return (
      <div id="transition">
        {this.renderGrid(transitionData)}
        {editMode && (
          <div className="addRowButtonContainer">
            <AddRow addRow={() => this.addRow()} />
          </div>
        )}
        <div className="section-header"> </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  state: {
    app: state.app,
    transitionData: state.planManagement.transitionData,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

const transitionFillContainer = (props) => (
  <TransitionFill
    editMode
    {...props}
  />
)

export default TransitionFill

export const TransitionFillContainer = connect(mapStateToProps, mapDispatchToProps)(transitionFillContainer)