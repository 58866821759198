import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Creators as UserActions } from "../../../redux/reducers/user";
import headerConstants from "../../../config/constants/header";
import "./styles.scss";

class Header extends PureComponent {  
  
  userLogout = () => {
  const { actions } = this.props;
  actions.userLogout();
};

render() {
  const { state, actions } = this.props;
  const { first_name, last_name } = state.user;
  const { auth0Client, app } = state;
  const { isLoggedIn, sideDrawer } = app;
  const {
    state: {
      app: { sidebarIsOpen },
    },
  } = this.props;
  const openSidebarClass = sidebarIsOpen ? "openSidebar" : "";

  return (
    <div id="appHeaderHomeInner" className={openSidebarClass}>
      <Link to="/home">
        <div className="header-text">
          <h2 className="sub-heading-2">{headerConstants.SUB_HEADING_1}</h2>
          <h1 className="main-title">{headerConstants.MAIN_TITLE}</h1>
          <h3 className="sub-heading-3">{headerConstants.SUB_HEADING_2}</h3>
        </div>
      </Link>
      <div className="overlay"></div>
    </div>
  )}
}


const mapStateToProps = (state) => ({
  state: {
    app: state.app,
    user: state.user,
    auth0Client: state?.ssoLogin?.auth0Client,
  },
});

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...UserActions,
  };

  return {
    actions: bindActionCreators(allActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);