import React from 'react'
import { Radio } from 'semantic-ui-react'

import FliptLabel from './fliptLabel'

export default (props) => {
  // radioLabel should be passed when stylized is false and we need to display label and radio in same line
  const { description, label, stylized, toggle, radioLabel, disabled,fullLabel= false } = props
  const stylizedField = () => (
    <div className="stylized">
      <div className=  {(fullLabel ? "flipt-label-full" : "flipt-label" )+ (disabled?" disabled_input ":"")}  >
        <FliptLabel
          description={description}
          label={label}
        />
      </div>
      <Radio {...props} label="" disabled={disabled?"disabled":""} toggle={disabled?null:toggle} />
    </div>
  )

  if (stylized) return stylizedField()

  return <Radio {...props} disabled={disabled?"disabled":""}label={radioLabel} toggle={disabled?null:toggle} />
}
