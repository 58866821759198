/* eslint-disable */
import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import './styles.scss'
import BreadCrumb from '../../../../components/breadCrumb'
import Config from '../../../../config'
import FliptGrid from '../../../../components/fliptGrid'
import FliptButton from '../../../../components/form/fliptButton'
import { Creators as AppActions } from '../../../../redux/reducers/app'
import { Creators as RPMActions } from '../../../../redux/reducers/rpm'
import { Creators as RolesActions } from '../../../../redux/reducers/roleManagement'
import { filterRowData } from '../../../../utils/utilities'
import { LinkContainerRenderer, DynamicLinkContainerRenderer } from '../../../../components/fliptGrid/cellRenderers'
import { calcGridRowHeight } from '../../../../utils/utilities'

class ApprovalRulesCreationManagement extends Component {
    componentDidMount() {
        const { actions } = this.props
        actions.getRoleManagementRoles()
        setTimeout(() => actions.rpmLoadApprovalsRules(), Config.COMPONENT_DATA_LOAD_TIME)
        
    }

    _getRowHeight = (params) => calcGridRowHeight(params.data?.approval_role)

    render() {
        const headers = [
            'action', 'name', 'document_governed', 'client', 'carrier', 'account', 'group', 'approval_step', 'approval_role', 'id']


        const cellRendererParams = {
            id: {
                hide: true,
            },
            rule_conditions: {
                hide: true,
            },
            action: {
                // TODO LinkContainerRenderer does not work with state
                cellRenderer: LinkContainerRenderer,
                searchArgs: [
                    {
                        path: '/approvals-rule-create',
                        searchArgs: { id: 'id' },
                        staticState: { editMode: false },
                        label: 'View',
                    },
                    {
                        path: '/approvals-rule-create',
                        searchArgs: { id: 'id' },
                        staticState: { editMode: true },
                        label: 'Edit',
                    },
                ],
            },
            approval_role: {
                cellRenderer: DynamicLinkContainerRenderer,
                searchArgs: {
                    label: { dynamic: 'approval_role' },
                    editMode: false,
                },
            },
            approval_step: {
                cellRenderer: DynamicLinkContainerRenderer,
                searchArgs: {
                    label: { dynamic: 'approval_step' },
                    editMode: false,
                },
            }
        }

        const { state: { rpm: { rules }, rolesData } } = this.props
        
        if (rules !== undefined) {
            rules.map(rule => {
                if ('conditions' in rule) {
                    rule["approval_role"] = rule.conditions.map(role => {
                        const approvalRole = rolesData.find(x => x.id === role?.approval_role)
                        if (!approvalRole) return {
                            approval_role: 'DOESNOTEXIST',
                            doc_id: 1,
                        }
                        const approvalRoleName = approvalRole?.name
                        return { 
                            approval_role:  approvalRoleName,
                            doc_id: 1,
                        }
                    })
                    rule["approval_step"] = rule.conditions.map(role => ({ approval_step: role.approval_step, doc_id: 2 }))
                }
                return rule
            })
        }
        const gridData = rules.map((d) => ({
            ...filterRowData(d, headers),
        }))

        return (
            <div id="approvalRuleCreationManagement">
                <BreadCrumb {...this.props} />
                <div className="header">
                    <h1>Approval Rules</h1>
                </div>
                <div className="buttonContainer" align="right">
                    <Link to="/approvals-rule-create">
                        <FliptButton
                            name="CREATE NEW APPROVAL RULE"
                            className="primary searchButton"
                        />
                    </Link>
                </div>
                <div className="content">
                    <FliptGrid
                        cellRendererParams={cellRendererParams}
                        data={gridData}
                        headers={headers}
                        getRowHeight={this._getRowHeight}
                        rowBuffer={(gridData.length) * 10}
                        suppressColumnVirtualisation
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    state: {
        rpm: state.rpm,
        rolesData: state.roleManagement.roleListing
    },
})

const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...AppActions,
        ...RPMActions,
        ...RolesActions,
    }

    return {
        actions: bindActionCreators(allActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ApprovalRulesCreationManagement)
