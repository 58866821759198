import React, { useState } from 'react'
import { Accordion, Icon } from 'semantic-ui-react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import './styles.scss'
import FliptGrid from '../../../../../components/fliptGrid'
import { Creators as UserActions } from '../../../../../redux/reducers/api/formularyCreationManagement'
import { filterRowData } from '../../../../../utils/utilities'

const GeneratedFormulary = (props) => {
  const [activeTierLevel, setActiveTierLevel] = useState(0)
  const { data } = props
  const headers = [
    'gpi', 'include', 'medicare_type', 'drug_group', 'drug_class', 'drug_subclass', 'manufacturer', 'drug_name', 'multi_source', 'ndc', 'ddid', 'brand_generic', 'otc_indicator', 'route_of_administration', 'maintenance_drug_code'
    , 'effective_start_date', 'effective_end_date']
  const cellRendererParams = {
    gpi: {
      overrideHeader: 'GPI',
    },
    multi_source: {
      overrideHeader: 'Multi-Source Code',
    },
    ndc: {
      overrideHeader: 'NDC',
    },
    ddid: {
      overrideHeader: 'DDID',
    },
    brand_generic: {
      overrideHeader: 'Brand/Generic',
    },
    otc_indicator: {
      overrideHeader: 'OTC Indicator',
    }
  }

  const _handleTierLevelClick = (e, titleProps) => {
    const { index } = titleProps
    const newIndex = activeTierLevel === index ? -1 : index

    setActiveTierLevel(newIndex)
  }

  return (
    <Accordion className="section-tier-levels-accordion" styled>
      {
        data.map((tier, idx) => {
          const tierLevelHeading = `${tier.doc_name} v${tier.doc_version}`
          const gridData = tier.conditions && tier.conditions.length ? tier.conditions.map((row) => ({
            ...filterRowData(row, headers),
          })) : []
          return (
            <div className="section-tier-level">
              <Accordion.Title
                active={activeTierLevel === idx}
                index={idx}
                onClick={_handleTierLevelClick}
              >
                <Icon name="dropdown" />
                {tierLevelHeading}
              </Accordion.Title>
              <Accordion.Content
                active={activeTierLevel === idx}
              >
                <div className="section-tier-conditions">
                  <FliptGrid
                    cellRendererParams={cellRendererParams}
                    data={gridData}
                    headers={headers}
                  />
                </div>
              </Accordion.Content>
            </div>
          )
        })
      }
    </Accordion>
    // <FliptGrid
    //   data={data}
    //   headers={headers}
    // />
  )
}

const mapStateToProps = () => ({
  //   state: {
  //   },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...UserActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GeneratedFormulary)
