import {
  call, 
  put, 
  select,
  takeLatest,
} from 'redux-saga/effects'

import { Types, Creators as ClaimHistoryActions } from '../../reducers/api/claimHistory'
import { Creators as AppActions } from '../../reducers/app'

import {
  getAppState, getApiPath,
} from '../../reducers/selectors'
import { fetchGet } from '../../../utils/fetchApi'

export default [
  getClaimHistoryDataWatcher,
]

function* getClaimHistoryDataWatcher(){
  yield takeLatest(Types.GET_CLAIM_HISTORY_DATA, getClaimHistoryDataHandler)
}

function* getClaimHistoryDataHandler({payload}){
  try {
    const { serviceUrl } = yield select(getAppState)

    const { api_path } = yield select(getApiPath, 'claim-history')
    const url = `${serviceUrl}${api_path}`
    const response = yield call(fetchGet, url, payload)
    yield put(ClaimHistoryActions.updateClaimHistoryData({data: response?.data}))
  } catch (err) {
    console.log('getClaimHistory Error >Data ', err)
    const transitionalPortal = {
      header: 'Getting Claim History Failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

export {
  getClaimHistoryDataHandler,
}