import {
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects'

import { v4 as uuidv4 } from 'uuid'

import { Types, Creators as StepTherapyCreationActions } from '../../reducers/api/clinicalCreationManagement'
import { Creators as RPMActions } from '../../reducers/rpm'
import { Creators as AppActions } from '../../reducers/app'
import { Creators as NavigationActions } from '../../reducers/navigation'
import { getAppState, getApiPath, getHistory, getUserState } from '../../reducers/selectors'
import { fetchPost } from '../../../utils/fetchApi'
import { updateCriteriaIdsHandler } from './qualifiers'
import * as ApprovalsConstants from '../rpm/constants'
import { deleteUnnecessaryFields } from '../../../utils/utilizationManagement'
import { convertDateToStrObj, filterRowData } from '../../../utils/utilities'

export default [
  getAllStepTherapyDataWatcher,
  generateStepTherapyTargetDrugsWatcher,
  generateStepTherapyLevelDrugsWatcher,
  saveStepTherapyDataWatcher,
  getStepTherapyDataWatcher,
  approveStepTherapyWatcher,
]

/* WATCHERS */
function* getAllStepTherapyDataWatcher() {
  yield takeLatest(Types.GET_ALL_STEP_THERAPY_DATA, getAllStepTherapyDataHandler)
}

function* generateStepTherapyTargetDrugsWatcher() {
  yield takeLatest(Types.GENERATE_STEP_THERAPY_TARGET_DRUGS, generateStepTherapyTargetDrugsHandler)
}

function* generateStepTherapyLevelDrugsWatcher() {
  yield takeLatest(Types.GENERATE_STEP_THERAPY_LEVEL_DRUGS, generateStepTherapyLevelDrugsHandler)
}

function* saveStepTherapyDataWatcher() {
  yield takeLatest(Types.SAVE_STEP_THERAPY_DATA, saveStepTherapyDataHandler)
}

function* getStepTherapyDataWatcher() {
  yield takeLatest(Types.GET_STEP_THERAPY_DATA, getStepTherapyDataHandler)
}

function* approveStepTherapyWatcher() {
  yield takeLatest(Types.APPROVE_STEP_THERAPY, approveStepTherapyHandler)
}

/* HANDLERS */
function* getAllStepTherapyDataHandler() {
  try {
    const { userCAG = {} } = yield select(getUserState)
    const body = {
      module: 'STEP_THERAPY',
      status: ApprovalsConstants.ALL_RPM_STATUSES,
    }

    yield put(RPMActions.rpmGetApprovalDocuments(body))
  } catch (err) {
    console.log('getAllStepTherapyDataHandler Error >Data ', err)

    const transitionalPortal = {
      header: 'Step Therapy Lookup Failed',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* generateStepTherapyTargetDrugsHandler({ payload }) {
  try {
    const {
      form, params, headers, headerMapping
    } = payload
    let get_total_rows = true
    if (params?.request.startRow > 0) {
      get_total_rows = false
    }
    const { serviceUrl } = yield select(getAppState)
    const include_inactive_drugs = form.include_inactive_drugs
    const reqData = {
      drugConditions: [deleteUnnecessaryFields(form)],
      limit: 20,
      offset: params?.request.startRow,
      get_total_rows,
      filterModel: params?.request.filterModel,
      sortModel: params?.request.sortModel,
      include_inactive_drugs,
    }
    const { api_path } = yield select(getApiPath, 'generate-step-therapy-target-drugs')

    const url = `${serviceUrl}${api_path}`
    const { data, last_row } = yield call(fetchPost, url, reqData)
    const gridData = data?.map((d) => ({
      ...filterRowData(d, headers, headerMapping),
      effective_start_date: convertDateToStrObj(form.effective_start_date),
      effective_end_date: convertDateToStrObj(form.effective_end_date)
    }))
    if (get_total_rows) {
      params?.success({
        rowData: gridData,
        rowCount: last_row,
      })
    } else {
      params?.success({
        rowData: gridData
      })
    }
  } catch (err) {
    console.log('generateStepTherapyDrugsHandler Error >Data ', err)

    const transitionalPortal = {
      header: 'Error occurred while generating step therapy target drug conditions',
      copy: err.message || err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* generateStepTherapyLevelDrugsHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const cd = yield select(getApiPath, 'generate-step-therapy-target-drugs')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`

    const { data } = yield call(fetchPost, url, payload)
    if (!data.length) throw Error('No Drugs found for the input conditions')
    const { stepLevel } = payload
    yield put(StepTherapyCreationActions.setStepTherapyLevelsDrugsData({ data, stepLevel }))
  } catch (err) {
    console.log('generateStepTherapyHandler Error >Data ', err)

    const transitionalPortal = {
      header: 'Error occurred while generating step therapy level drug conditions',
      copy: err.message || err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* saveStepTherapyDataHandler({ payload }) {
  try {
    const approvalDocument = {
      module_name: payload.doc_name,
      data: payload,
      module: 'STEP_THERAPY',
      status: 'DRAFT',
    }
    // eslint-disable-next-line
    const cb = function* ({ success }) {
      if (success) yield put(NavigationActions.navigateBack())
    }
    const criteria_ids = yield call(updateCriteriaIdsHandler, { payload })
    approvalDocument.data.criteria_ids = criteria_ids
    delete approvalDocument.data.conditions.umConditions;
    yield put(RPMActions.rpmUpsertApprovalDocument(approvalDocument, cb))
  } catch (err) {
    console.log('saveStepTherapyDataHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Saving Step Therapy Failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

/*
  stepTherapyData: payload.step_therapy_info,
  stepTherapyTargetDrugCondData: payload.target_drugs_conditions,
  stepTherapyLevelsData: payload.step_therapy_levels,
  stepTherapyLevelsCriteria: payload.step_level_criteria,
  stepTherapyLevelsDrugList: payload.drug_list,
*/

function* getStepTherapyDataHandler({ payload }) {
  try {
    const cb = function* cb(data) {
      const stData = data[0].module_data.data
      const step_therapy_data = {
        step_therapy_info: {
          effective_start_date: stData.effective_start_date,
          doc_name: stData.doc_name,
          doc_version: stData.doc_version,
          hierarchy: data[0].hierarchy,
          doc_id: data[0].id,
          version_effective_date: stData?.version_effective_date,
          version: data[0].version,
          status: data[0].status,
          is_hierarchy_global: data[0].is_hierarchy_global,
          hideHierarchy: data[0].hideHierarchy,
        },
        target_drugs_conditions: stData.targetDrugsConditions,
        step_therapy_levels: stData.stepTherapyLevels,
        step_level_criteria: stData.stepLevelCriteria,
        step_therapy_qualifier_levels: stData.stepTherapyQualifierLevels,
        criteria_sublist: stData.criteria_sublist || [[{
          lookback_criteria_sublist: '',
          drug_list: [],
          set_operator: '',
          action: '',
        }]],
        criteria_ids: stData.criteria_ids,
        drug_list: stData.drugList,
      }
      yield put(StepTherapyCreationActions.setStepTherapyData(step_therapy_data))
    }
    yield put(RPMActions.rpmGetApprovalDocuments(payload, cb))
  } catch (err) {
    console.log('getStepTherapyDataHandler Error >Data ', err)

    const transitionalPortal = {
      header: 'Step Therapy Lookup Failed',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* approveStepTherapyHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const history = yield select(getHistory)
    const qs = history.location.search
    const cd = yield select(getApiPath, 'approve-step-therapy')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}${qs}`

    const { message } = yield call(fetchPost, url, payload)
    yield put(StepTherapyCreationActions.getAllStepTherapyData())
    const transitionalPortal = {
      header: 'Step Therapy',
      copy: `${message}. Please wait while we publish the step therapy list.`,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    // triggering airlow dag to load the drug list and publish the step therapy list
    const execution_time = new Date()
    execution_time.setSeconds(execution_time.getSeconds() + 60)
    const payload2 = {
      conf: { section: 'st', ...payload },
      dag_run_id: uuidv4(),
      execution_date: execution_time.toISOString(),
    }
    yield call(fetchPost, url.replace('/approve-step-therapy', '/build-drug-list'), payload2)
  } catch (err) {
    console.log('approveStepTherapyHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Approving Step Therapy Failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}
