import React, { PureComponent } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import './styles.scss'
import BreadCrumb from '../../../../../components/breadCrumb'
import Config from '../../../../../config'
import FliptInput from '../../../../../components/form/fliptInput'
import SubmenuNav from '../shared/navigation'
import { Creators as ApiActions } from '../../../../../redux/reducers/api/claimLookupManagement'

class ClaimTransactions extends PureComponent {
  componentDidMount() {
    const { actions } = this.props
    // TODO FIX THIS. SO NO MORE SETTIMEOUT
    setTimeout(() => actions.getClaimTransactionData(), Config.COMPONENT_DATA_LOAD_TIME)
  }

  _renderContent = () => {
    const { state } = this.props
    const { providers_section } = state.claimTransactionData
    const {
      pharmacy_npi,
      pharmacyaddress1,
      pharmacyaddress2,
      pharmacycity,
      pharmacystate,
      pharmacy_name,
      pharmacyphone,
      prescriber_npi,
      provider_first_line_business_practice_location_address,
      provider_first_name,
      provider_last_name_legal_name,
      provider_business_practice_location_address_telephone_number,
      provider_business_practice_location_address_fax_number,
      pharmacy_ncpdp,
      provider_business_practice_location_address_state_name,
      provider_business_practice_location_address_city_name,
      pharmacy_type,
    } = providers_section

    const pharmacy_address = `${pharmacyaddress1 || ''} ${pharmacyaddress2 || ''}`
    const prescriber_name = `${provider_first_name || ''} ${provider_last_name_legal_name || ''}`

    return (
      <>
        <div className="section">
          <h2>Pharmacy</h2>
          <div className="fields-container">
            <div className="fields">
              <FliptInput value={pharmacy_npi} label="NPI" stylized />
              <FliptInput value={pharmacy_address} label="Address" stylized />
              <FliptInput value={pharmacycity} label="City" stylized />
            </div>
            <div className="fields">
              <FliptInput value={pharmacy_name} label="Pharmacy Name" stylized />
              <FliptInput value={pharmacy_type} label="Pharmacy Type" stylized />
              <FliptInput value={pharmacystate} label="State" stylized />
            </div>
            <div className="fields">
              <FliptInput value={pharmacy_ncpdp} label="NCPDP" stylized />
              <FliptInput value={pharmacyphone} label="Phone" stylized />
            </div>
          </div>
        </div>
        <div className="section">
          <h2>Prescriber</h2>
          <div className="fields-container">
            <div className="fields">
              <FliptInput value={prescriber_name} label="Prescriber Name" stylized />
              <FliptInput value={provider_first_line_business_practice_location_address} label="Address" stylized />
              <FliptInput value={provider_business_practice_location_address_telephone_number} label="Phone" stylized />
            </div>
            <div className="fields">
              <FliptInput value={prescriber_npi} label="Prescriber ID" stylized />
              <FliptInput value={provider_business_practice_location_address_city_name} label="City" stylized />
            </div>
            <div className="fields">
              <FliptInput value={provider_business_practice_location_address_fax_number} label="Fax" stylized />
              <FliptInput value={provider_business_practice_location_address_state_name} label="State" stylized />
            </div>
          </div>
        </div>
      </>
    )
  }

  _renderEmptyContent = () => (
    <div>Unavailable Data</div>
  )

  render() {
    const { state, history } = this.props
    const { claimTransactionData } = state
    const hasContent = claimTransactionData.providers_section ? Object.keys(claimTransactionData.providers_section) : []

    return (
      <div id="claimTransactionProviders">
        <BreadCrumb {...this.props} />
        <div className="header">
          <h1>Transaction</h1>
          <SubmenuNav history={history} active="providers" />
        </div>
        <div className="subhead" />
        <div className="content">
          {hasContent.length ? this._renderContent() : this._renderEmptyContent()}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  state: {
    claimTransactionData: state.claimLookup.claimTransactionData,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...ApiActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClaimTransactions)
