import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import './styles.scss'
import FliptButton from '../../components/form/fliptButton'
import { filterRowData } from '../../utils/utilities'
import FliptGrid from '../fliptGrid'
import FliptPaInput from '../form/fliptPaInput'
import { Creators as PrescriberLookupActions } from '../../redux/reducers/api/prescriberLookup'
import { Creators as PriorAuthorizationCreationActions } from '../../redux/reducers/api/priorAuthorizationManagement'
import { Creators as AppActions } from '../../redux/reducers/app'

class FliptMultiPrescriberSearch extends Component {
    constructor(props) {
        super(props)
        this.state = {
            prescriberData: props.prescriberData,
            searchForm: {
                prescriberNpi: '',
                prescriber_first_name: '',
                prescriber_last_name: '',
            },
            prescriberList: [],
            validationNeeded: false,
            allValues: true,
        }
        this.agGridRefPres = React.createRef()
    }

    componentDidUpdate(prevProps) {
        const { props } = this
        const { prescriberLookupData, } = props.state
        if (prevProps.state.prescriberLookupData !== prescriberLookupData) {
            this.setState({ prescriberList: prescriberLookupData })
        }
    }

    _submitResult = () => {
        const { actions } = this.props
        actions.toggleModal()
    }

    _clearPrescriberFields = () => {
        const { updatePrescriberData } = this.props
        updatePrescriberData([])
        this.setState({
            searchForm: {
                prescriberNpi: '',
                prescriber_first_name: '',
                prescriber_last_name: '',
            },
            prescriberData: [],
            prescriberList: []
        })
    }

    _onPrescriberSelectionChanged = (data) => {
        const { updatePrescriberData } = this.props
        const { prescriberData } = this.state
        const selectedRows = data.api.getSelectedRows();
        if (selectedRows.length) {
            let filteredPrescriberData = [...selectedRows, ...prescriberData]
            filteredPrescriberData = [...new Map(filteredPrescriberData.map((m) => [m.npi, m])).values()];
            updatePrescriberData(filteredPrescriberData)
            this.setState({ prescriberData: filteredPrescriberData })
        }
    }

    _searchData = () => {
        const { actions } = this.props
        const { state: { searchForm } } = this
        if ((!searchForm.prescriberNpi && !searchForm.prescriber_first_name
            && !searchForm.prescriber_last_name)) {
            const transitionalPortal = {
                header: 'Fields are missing',
                copy: 'Please Add Details to search Prescriber detail',
            }
            actions.displayTransitionalPortal(transitionalPortal)
            return;
        }
        if (searchForm.prescriberNpi && searchForm.prescriberNpi.length !== 10) {
            const transitionalPortal = {
                header: 'Field Error',
                copy: 'Prescriber NPI should be of 10 Digits',
            }
            actions.displayTransitionalPortal(transitionalPortal)
            return;
        }
        this.setState({
            prescriberList: [],
            prescriberSearched: true, prescriberModalOpen: false
        })
        let params = {}
        if (searchForm.prescriberNpi) params.prescriber_npi = searchForm.prescriberNpi
        if (searchForm.prescriber_first_name) params.prescriber_first_name = searchForm.prescriber_first_name
        if (searchForm.prescriber_last_name) params.prescriber_last_name = searchForm.prescriber_last_name
        actions.setPrescriberLookupFailed(false)
        actions.getPrescriberLookupData(params)
    }

    _updateSearchForm = (el, dropdown) => {
        const { searchForm } = this.state
        const { name, value } = dropdown || el.currentTarget
        searchForm[name] = value
        this.setState({
            searchForm, prescriberList: [],
            addPrescriber: false
        })
    }

    _showTransitionalPortal = (header, message) => {
        const { props } = this;
        const transitionalPortal = {
            header: header,
            copy: message,
        }
        props.actions.displayTransitionalPortal(transitionalPortal)
    }

    render() {
        const { searchForm, prescriberList, prescriberData } = this.state
        const headers = [
            'Select', 'npi', 'first_name', 'last_name', 'secure_fax_number', 'phone_number', 'address', 'city',
            'state', 'zip_code',
        ]
        const selectedHeaders = [
            'npi', 'first_name', 'last_name', 'secure_fax_number', 'phone_number', 'address', 'city',
            'state', 'zip_code',
        ]
        const headerMapping = {
            npi: 'npi',
            provider_first_name: 'first_name',
            provider_last_name_legal_name: 'last_name',
            provider_first_line_business_practice_location_address: 'address',
            provider_business_practice_location_address_city_name: 'city',
            provider_business_practice_location_address_state_name: 'state',
            provider_business_practice_location_address_postal_code: 'zip_code',
            provider_business_practice_location_address_fax_number: 'fax_number',
            provider_business_practice_location_address_telephone_number: 'phone_number'
        }
        const gridData = prescriberList && prescriberList.length ? prescriberList?.map((d) => {
            const obj = {
                ...filterRowData(d, headers, headerMapping),
            }
            return obj;
        }) : []

        const cellRendererParams = {
            Select: {
                checkboxSelection: true,
                width: '100px'
            },
            npi: {
                width: '120px'
            },
            first_name: {
                width: '120px'
            },
            last_name: {
                width: '120px'
            },
            secure_fax_number: {
                width: '120px'
            },
            address: {
                width: '120px'
            },
            city: {
                width: '120px'
            },
            state: {
                width: '100px'
            },
            zip_code: {
                width: '120px'
            },
            phone_number: {
                width: '120px'
            }
        }

        return (
            <div className="multi-prescriber-section">
                <div className="prescriber-header">
                    Find Prescriber
                </div>
                <div className="inner-container">
                    <div className="fields-container">
                        <FliptPaInput validation='numeric' value={searchForm.prescriberNpi} stylized name="prescriberNpi" onChange={this._updateSearchForm} placeholder="Enter" label="NPI" description="NPI" />
                        <FliptPaInput value={searchForm.prescriber_first_name} stylized name="prescriber_first_name" onChange={this._updateSearchForm} placeholder="Enter" label="Prescriber First Name" />
                        <FliptPaInput value={searchForm.prescriber_last_name} stylized name="prescriber_last_name" onChange={this._updateSearchForm} placeholder="Enter" label="Prescriber Last Name" />
                    </div>
                    <div className="prescriber-buttons-container">
                        <FliptButton className='primary' name='SEARCH' onClick={() => this._searchData()} />
                        <FliptButton name="Clear" className="secondary" onClick={() => this._clearPrescriberFields()} />
                    </div>
                </div>
                {prescriberList?.length ?
                    <>
                        <div className="prescriber-header">
                            Search Results
                        </div>
                        <div className="grid-container">
                            <FliptGrid
                                agGridRef={this.agGridRefPres}
                                headers={headers}
                                data={gridData}
                                rowSelection='multiple'
                                onSelectionChanged={(data) => this._onPrescriberSelectionChanged(data)}
                                cellRendererParams={cellRendererParams}
                            />
                        </div></> : <></>
                }
                {prescriberData?.length ?
                    <>
                        <div className="prescriber-header">
                            Selected Results
                        </div>
                        <div className="grid-container">
                            <FliptGrid
                                headers={selectedHeaders}
                                data={prescriberData}
                            />
                        </div></> : <></>
                }

                <div className="prescriber-buttons-container">
                    <FliptButton disabled={!prescriberData?.length} className='primary' name='SUBMIT'
                        onClick={() => this._submitResult()} />
                </div>
            </div>
        )
    }

}

const mapStateToProps = (state) => ({
    state: {
        prescriberLookupData: state.prescriberLookup.prescriberLookupData,
        user: state.user,
    },
})

const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...PrescriberLookupActions,
        ...PriorAuthorizationCreationActions,
        ...AppActions
    }
    return {
        actions: bindActionCreators(allActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FliptMultiPrescriberSearch)