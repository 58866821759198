import React from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Button, Icon, Modal } from "semantic-ui-react";

import AddRow from "../../addRow";

import FliptGrid from "../../../../../../components/fliptGrid";
import FliptInput from "../../../../../../components/form/fliptInput";
import qualifierRowCellInfo from "../../data/qualifierRowCellInfo";

import FliptButton from "../../../../../../components/form/fliptButton";
import { Creators as AppActions } from "../../../../../../redux/reducers/app";
import { DeleteRowRenderer } from "../../../../../../components/fliptGrid/cellRenderers";
import {
  InputTextEditor,
  DropdownEditor,
  SearchEditor,
} from "../../../../../../components/fliptGrid/cellEditors";

function Conditions(props) {
  const {
    paQualifierLevels,
    qualifierDropdownOptions,
    handleQualifierChange,
    addQualifierRow,
    delQualifierRow,
    onClose,
    open,
    predefined,
  } = props;
  const qualifierHeaders = Object.keys(qualifierRowCellInfo);
  if (qualifierHeaders.includes("action")) {
    qualifierHeaders.splice(qualifierHeaders.indexOf("action"), 1);
    qualifierHeaders.unshift("action");
  }

  const qualifierCellRendererParams = {
    action: {
      cellRenderer: DeleteRowRenderer,
      state: {
        onClick: delQualifierRow,
      },
      width: 95,
    },
    input_value: {
      overrideHeader: "Input Value",
    },
    qualifier: {
      overrideHeader: "Qualifier",
    },
    standard: {
      overrideHeader: "Standard  Value",
    },
  };

  const qualifierCellEditorParams = {
    input_value: {
      cellEditor: InputTextEditor,
      onChange: handleQualifierChange,
    },
    qualifier: {
      cellEditor: DropdownEditor,
      onChange: handleQualifierChange,
    },
    standard: {
      cellEditor: DropdownEditor,
      onChange: handleQualifierChange,
    },
  };

  return (
    <>
      <div className="transform">
        <div className="pa-qualifier-level-add-cond-button"></div>
      </div>

      <Modal size={"medium"} open={open} onClose={onClose}>
        <Modal.Header>Transformation</Modal.Header>
        <Modal.Content>
          <div id="file-configuration">
            <div
              className="content shadow margin-bottom "
              style={{ marginTop: "0px" }}
            >
              <div className="transform">
                <FliptGrid
                  data={paQualifierLevels[0]}
                  headers={qualifierHeaders}
                  cellRendererParams={qualifierCellRendererParams}
                  cellEditorParams={qualifierCellEditorParams}
                  rowCellInfo={qualifierRowCellInfo}
                  // stepLevel={paQualifierLevels.length - 1}
                  // dropdownOptions={qualifierDropdownOptions[qualifierDropdownOptions.length - 1]}

                  stepLevel={0}
                  dropdownOptions={qualifierDropdownOptions[0]}
                />
              </div>
            </div>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <div
            className="fields-container"
            style={{ justifyContent: "center" }}
          >
            <div className="button-space">
              <FliptButton
                onClick={onClose}
                name="Close"
                className="secondary searchButton"
                style={{ padding: "9px 5px", marginRight: "50px" }}
              />
            </div>

            <AddRow
              addRow={addQualifierRow}
              addRowLabel="Add Qualifier"
              level={0}
            />
          </div>
        </Modal.Actions>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => ({
  state: {
  },
});

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
  };

  return {
    actions: bindActionCreators(allActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Conditions);
