/* eslint-disable */

export default {
  "11": {
    "drug_classes": {
      "40": {
        "drug_class": "Imidazole-Related Antifungals",
        "drug_subclasses": {
          "40": {
            "drug_subclass": "Imidazoles"
          },
          "70": {
            "drug_subclass": "Triazoles"
          }
        }
      },
      "50": {
        "drug_class": "Antifungal - Glucan Synthesis Inhibitors (Echinocandins)",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Antifungal - Glucan Synthesis Inhibitors (Echinocandins)"
          }
        }
      },
      "00": {
        "drug_class": "Antifungals",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Antifungals"
          }
        }
      }
    },
    "drug_group": "ANTIFUNGALS"
  },
  "12": {
    "drug_classes": {
      "10": {
        "drug_class": "Antiretrovirals",
        "drug_subclasses": {
          "20": {
            "drug_subclass": "Antiretrovirals - CCR5 Antagonists (Entry Inhibitor)"
          },
          "22": {
            "drug_subclass": "Antiretrovirals - CD4-Directed Post-Attachment Inhibitor"
          },
          "23": {
            "drug_subclass": "Antiretrovirals - gp120-Directed Attachment Inhibitor"
          },
          "25": {
            "drug_subclass": "Antiretrovirals - Fusion Inhibitors"
          },
          "30": {
            "drug_subclass": "Antiretrovirals - Integrase Inhibitors"
          },
          "45": {
            "drug_subclass": "Antiretrovirals - Protease Inhibitors"
          },
          "50": {
            "drug_subclass": "Antiretrovirals - RTI-Nucleoside Analogues-Purines"
          },
          "60": {
            "drug_subclass": "Antiretrovirals - RTI-Nucleoside Analogues-Pyrimidines"
          },
          "80": {
            "drug_subclass": "Antiretrovirals - RTI-Nucleoside Analogues-Thymidines"
          },
          "85": {
            "drug_subclass": "Antiretrovirals - RTI-Nucleotide Analogues"
          },
          "90": {
            "drug_subclass": "Antiretrovirals - RTI-Non-Nucleoside Analogues"
          },
          "95": {
            "drug_subclass": "Antiretrovirals Adjuvants"
          },
          "99": {
            "drug_subclass": "Antiretroviral Combinations"
          }
        }
      },
      "20": {
        "drug_class": "CMV Agents",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "CMV Agents"
          }
        }
      },
      "35": {
        "drug_class": "Hepatitis Agents",
        "drug_subclasses": {
          "20": {
            "drug_subclass": "Hepatitis B Agents"
          },
          "30": {
            "drug_subclass": "Hepatitis C Agents"
          },
          "99": {
            "drug_subclass": "Hepatitis C Agent - Combinations"
          }
        }
      },
      "40": {
        "drug_class": "Herpes Agents",
        "drug_subclasses": {
          "50": {
            "drug_subclass": "Herpes Agents - Purine Analogues"
          },
          "80": {
            "drug_subclass": "Herpes Agents - Thymidine Analogues"
          }
        }
      },
      "50": {
        "drug_class": "Influenza Agents",
        "drug_subclasses": {
          "20": {
            "drug_subclass": "PA Endonuclease Inhibitors"
          },
          "40": {
            "drug_subclass": "Neuraminidase Inhibitors"
          },
          "00": {
            "drug_subclass": "Influenza Agents"
          }
        }
      },
      "60": {
        "drug_class": "Respiratory Syncytial Virus (RSV) Agents",
        "drug_subclasses": {
          "40": {
            "drug_subclass": "RSV Agents - Nucleoside Analogues"
          }
        }
      },
      "70": {
        "drug_class": "Misc. Antivirals",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Misc. Antivirals"
          }
        }
      },
      "99": {
        "drug_class": "Antiviral Combinations",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Antiviral Combinations"
          }
        }
      }
    },
    "drug_group": "ANTIVIRALS"
  },
  "13": {
    "drug_classes": {
      "99": {
        "drug_class": "Antimalarial Combinations",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Antimalarial Combinations"
          }
        }
      },
      "00": {
        "drug_class": "Antimalarials",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Antimalarials"
          }
        }
      }
    },
    "drug_group": "ANTIMALARIALS"
  },
  "14": {
    "drug_classes": {
      "00": {
        "drug_class": "Amebicides",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Amebicides"
          }
        }
      }
    },
    "drug_group": "AMEBICIDES"
  },
  "15": {
    "drug_classes": {
      "00": {
        "drug_class": "Anthelmintics",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Anthelmintics"
          }
        }
      }
    },
    "drug_group": "ANTHELMINTICS"
  },
  "16": {
    "drug_classes": {
      "10": {
        "drug_class": "Polymyxins",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Polymyxins"
          }
        }
      },
      "14": {
        "drug_class": "Monobactams",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Monobactams"
          }
        }
      },
      "15": {
        "drug_class": "Carbapenems",
        "drug_subclasses": {
          "99": {
            "drug_subclass": "Carbapenem Combinations"
          },
          "00": {
            "drug_subclass": "Carbapenems"
          }
        }
      },
      "20": {
        "drug_class": "Chloramphenicols",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Chloramphenicals"
          }
        }
      },
      "21": {
        "drug_class": "Ketolides",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Ketolides"
          }
        }
      },
      "22": {
        "drug_class": "Lincosamides",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Lincosamides"
          }
        }
      },
      "23": {
        "drug_class": "Oxazolidinones",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Oxazolidinones"
          }
        }
      },
      "24": {
        "drug_class": "Pleuromutilins",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Pleuromutilins"
          }
        }
      },
      "25": {
        "drug_class": "Streptogramins",
        "drug_subclasses": {
          "99": {
            "drug_subclass": "Streptogramin Combinations"
          }
        }
      },
      "27": {
        "drug_class": "Cyclic Lipopeptides",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Cyclic Lipopeptides"
          }
        }
      },
      "28": {
        "drug_class": "Glycopeptides",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Glycopeptides"
          }
        }
      },
      "30": {
        "drug_class": "Leprostatics",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Leprostatics"
          }
        }
      },
      "40": {
        "drug_class": "Antiprotozoal Agents",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Antiprotozoal Agents"
          }
        }
      },
      "80": {
        "drug_class": "Urinary Anti-infectives",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Urinary Anti-infectives"
          }
        }
      },
      "99": {
        "drug_class": "Anti-infective Misc. - Combinations",
        "drug_subclasses": {
          "20": {
            "drug_subclass": "Urinary Antiseptic-Antispasmodic &/or Analgesics"
          },
          "00": {
            "drug_subclass": "Anti-infective Misc. - Combinations"
          },
          "05": {
            "drug_subclass": "Methenamine Combos"
          }
        }
      },
      "00": {
        "drug_class": "Anti-infective Agents - Misc.",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Anti-infective Agents - Misc."
          }
        }
      }
    },
    "drug_group": "ANTI-INFECTIVE AGENTS - MISC."
  },
  "17": {
    "drug_classes": {
      "10": {
        "drug_class": "Viral Vaccines",
        "drug_subclasses": {
          "99": {
            "drug_subclass": "Viral Vaccine Combinations"
          },
          "00": {
            "drug_subclass": "Viral Vaccines"
          }
        }
      },
      "20": {
        "drug_class": "Bacterial Vaccines",
        "drug_subclasses": {
          "99": {
            "drug_subclass": "Bacterial Vaccine Combination"
          },
          "00": {
            "drug_subclass": "Bacterial Vaccines"
          }
        }
      },
      "99": {
        "drug_class": "Mixed Vaccine Combinations",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Mixed Vaccine Combinations"
          }
        }
      }
    },
    "drug_group": "VACCINES"
  },
  "18": {
    "drug_classes": {
      "99": {
        "drug_class": "Toxoid Combinations",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Toxoid Combinations"
          }
        }
      }
    },
    "drug_group": "TOXOIDS"
  },
  "19": {
    "drug_classes": {
      "10": {
        "drug_class": "Immune Serums",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Immune Serums"
          }
        }
      },
      "20": {
        "drug_class": "Antitoxins-Antivenins",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Antitoxins-Antivenins"
          }
        }
      },
      "50": {
        "drug_class": "Monoclonal Antibodies",
        "drug_subclasses": {
          "20": {
            "drug_subclass": "Antiviral Monoclonal Antibodies"
          },
          "30": {
            "drug_subclass": "Bacterial Monoclonal Antibodies"
          }
        }
      },
      "99": {
        "drug_class": "Passive Immunizing Agents - Combinations",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Passive Immunizing Agents - Combinations"
          }
        }
      }
    },
    "drug_group": "PASSIVE IMMUNIZING AND TREATMENT AGENTS"
  },
  "20": {
    "drug_classes": {
      "10": {
        "drug_class": "Allergenic Extracts",
        "drug_subclasses": {
          "99": {
            "drug_subclass": "Mixed Allergenic Extracts"
          },
          "00": {
            "drug_subclass": "Allergenic Extracts"
          }
        }
      },
      "00": {
        "drug_class": "Biologicals Misc",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Biologicals Misc"
          }
        }
      }
    },
    "drug_group": "ALLERGENIC EXTRACTS/BIOLOGICALS MISC"
  },
  "21": {
    "drug_classes": {
      "10": {
        "drug_class": "Alkylating Agents",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Nitrogen Mustards"
          },
          "20": {
            "drug_subclass": "Nitrosoureas"
          },
          "40": {
            "drug_subclass": "Imidazotetrazines"
          },
          "70": {
            "drug_subclass": "Tetrahydroisoquinolines"
          },
          "00": {
            "drug_subclass": "Alkylating Agents"
          }
        }
      },
      "20": {
        "drug_class": "Antineoplastic Antibiotics",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Antineoplastic Antibiotics"
          }
        }
      },
      "25": {
        "drug_class": "Antineoplastic Enzymes",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Antineoplastic Enzymes"
          }
        }
      },
      "30": {
        "drug_class": "Antimetabolites",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Antimetabolites"
          }
        }
      },
      "33": {
        "drug_class": "Antineoplastic - Angiogenesis Inhibitors",
        "drug_subclasses": {
          "50": {
            "drug_subclass": "Vascular Endothelial Growth Factor (VEGF) Inhibitors"
          }
        }
      },
      "35": {
        "drug_class": "Antineoplastic - Antibodies",
        "drug_subclasses": {
          "20": {
            "drug_subclass": "Antineoplastic - Bispecific T-cell Engagers"
          },
          "30": {
            "drug_subclass": "Antineoplastic - Monoclonal Antibodies"
          },
          "50": {
            "drug_subclass": "Antineoplastic Antibody-Drug Complexes"
          },
          "80": {
            "drug_subclass": "Antineoplastic -Antibody for Radiopharmaceutical Therapy"
          }
        }
      },
      "37": {
        "drug_class": "Antineoplastic - Hedgehog Pathway Inhibitors",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Antineoplastic - Hedgehog Pathway Inhibitors"
          }
        }
      },
      "40": {
        "drug_class": "Antineoplastic - Hormonal and Related Agents",
        "drug_subclasses": {
          "22": {
            "drug_subclass": "Antiadrenals"
          },
          "24": {
            "drug_subclass": "Antiandrogens"
          },
          "26": {
            "drug_subclass": "Antiestrogens"
          },
          "28": {
            "drug_subclass": "Aromatase Inhibitors"
          },
          "30": {
            "drug_subclass": "Estrogens-Antineoplastic"
          },
          "35": {
            "drug_subclass": "Estrogen Receptor Antagonist"
          },
          "40": {
            "drug_subclass": "Progestins-Antineoplastic"
          },
          "50": {
            "drug_subclass": "LHRH Analogs"
          },
          "55": {
            "drug_subclass": "Gonadotropin Releasing Hormone (GnRH) Antagonists"
          },
          "60": {
            "drug_subclass": "Androgen Biosynthesis Inhibitors"
          },
          "99": {
            "drug_subclass": "Antineoplastic - Hormonal and Related Agent Combinations"
          }
        }
      },
      "45": {
        "drug_class": "Antineoplastic - Immunomodulators",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Antineoplastic - Immunomodulators"
          }
        }
      },
      "47": {
        "drug_class": "Antineoplastic - BCL-2 Inhibitors",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Antineoplastic - BCL-2 Inhibitors"
          }
        }
      },
      "50": {
        "drug_class": "Mitotic Inhibitors",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Mitotic Inhibitors"
          }
        }
      },
      "53": {
        "drug_class": "Antineoplastic Enzyme Inhibitors",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Cyclin-Dependent Kinases (CDK) Inhibitors"
          },
          "15": {
            "drug_subclass": "Antineoplastic - Histone Deacetylase Inhibitors"
          },
          "20": {
            "drug_subclass": "Antineoplastic - BRAF Kinase Inhibitors"
          },
          "22": {
            "drug_subclass": "Antineoplastic - FGFR Kinase Inhibitors"
          },
          "25": {
            "drug_subclass": "Antineoplastic - mTOR Kinase Inhibitors"
          },
          "30": {
            "drug_subclass": "Antineoplastic - Multikinase Inhibitors"
          },
          "35": {
            "drug_subclass": "Antineoplastic - MEK Inhibitors"
          },
          "36": {
            "drug_subclass": "Antineoplastic - Methyltransferase Inhibitors"
          },
          "38": {
            "drug_subclass": "Antineoplastic - Tropomyosin Receptor Kinase Inhibitors"
          },
          "40": {
            "drug_subclass": "Antineoplastic - Tyrosine Kinase Inhibitors"
          },
          "49": {
            "drug_subclass": "Isocitrate Dehydrogenase-1 (IDH1) Inhibitors"
          },
          "50": {
            "drug_subclass": "Isocitrate Dehydrogenase-2 (IDH2) Inhibitors"
          },
          "55": {
            "drug_subclass": "Poly (ADP-ribose) Polymerase (PARP) Inhibitors"
          },
          "60": {
            "drug_subclass": "Antineoplastic - Proteasome Inhibitors"
          },
          "75": {
            "drug_subclass": "Janus Associated Kinase (JAK) Inhibitors"
          },
          "80": {
            "drug_subclass": "Phosphatidylinositol 3-Kinase (PI3K) Inhibitors"
          }
        }
      },
      "55": {
        "drug_class": "Topoisomerase I Inhibitors",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Topoisomerase I Inhibitors"
          }
        }
      },
      "56": {
        "drug_class": "Antineoplastic - XPO1 Inhibitors",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Antineoplastic - XPO1 Inhibitors"
          }
        }
      },
      "57": {
        "drug_class": "Oncolytic Viral Agents",
        "drug_subclasses": {
          "40": {
            "drug_subclass": "Oncolytic Viral Agents - HSV1"
          }
        }
      },
      "60": {
        "drug_class": "Antineoplastic Radiopharmaceuticals",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Antineoplastic Radiopharmaceuticals"
          }
        }
      },
      "65": {
        "drug_class": "Antineoplastic - Cellular Immunotherapy",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Antineoplastic - Autologous Cellular Immunotherapy"
          }
        }
      },
      "70": {
        "drug_class": "Antineoplastics Misc.",
        "drug_subclasses": {
          "30": {
            "drug_subclass": "Antineoplastics - Interleukins"
          },
          "70": {
            "drug_subclass": "Antineoplastics - Photoactivated Agents"
          },
          "80": {
            "drug_subclass": "Retinoids"
          },
          "82": {
            "drug_subclass": "Selective Retinoid X Receptor Agonists"
          },
          "00": {
            "drug_subclass": "Antineoplastics Misc."
          }
        }
      },
      "75": {
        "drug_class": "Chemotherapy Rescue/Antidote Agents",
        "drug_subclasses": {
          "40": {
            "drug_subclass": "Cardiac Protective Agents"
          },
          "50": {
            "drug_subclass": "Folic Acid Antagonists Rescue Agents"
          },
          "60": {
            "drug_subclass": "Carboxypeptidase Enzyme Agents"
          },
          "65": {
            "drug_subclass": "Myeloprotective Agents"
          },
          "80": {
            "drug_subclass": "Urinary Tract Protective Agents"
          }
        }
      },
      "76": {
        "drug_class": "Chemotherapy Adjuncts",
        "drug_subclasses": {
          "40": {
            "drug_subclass": "Chemotherapy Adjuncts - Hyperuricemia Agents"
          },
          "50": {
            "drug_subclass": "Chemotherapy Adjuncts - Keratinocyte Growth Factors"
          }
        }
      },
      "99": {
        "drug_class": "Antineoplastic Combinations",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Antineoplastic Combinations"
          }
        }
      }
    },
    "drug_group": "ANTINEOPLASTICS AND ADJUNCTIVE THERAPIES"
  },
  "22": {
    "drug_classes": {
      "10": {
        "drug_class": "Glucocorticosteroids",
        "drug_subclasses": {
          "99": {
            "drug_subclass": "Steroid Combinations"
          },
          "00": {
            "drug_subclass": "Glucocorticosteroids"
          }
        }
      },
      "20": {
        "drug_class": "Mineralocorticoids",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Mineralocorticoids"
          }
        }
      }
    },
    "drug_group": "CORTICOSTEROIDS"
  },
  "23": {
    "drug_classes": {
      "10": {
        "drug_class": "Androgens",
        "drug_subclasses": {
          "99": {
            "drug_subclass": "Androgen Combinations"
          },
          "00": {
            "drug_subclass": "Androgens"
          }
        }
      },
      "20": {
        "drug_class": "Anabolic Steroids",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Anabolic Steroids"
          }
        }
      }
    },
    "drug_group": "ANDROGENS-ANABOLIC"
  },
  "24": {
    "drug_classes": {
      "99": {
        "drug_class": "Estrogen Combinations",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Estrogen & Androgen"
          },
          "30": {
            "drug_subclass": "Estrogen & Progestin"
          },
          "35": {
            "drug_subclass": "Estrogen-Progestin-GnRH Antagonist"
          },
          "40": {
            "drug_subclass": "Estrogen-Androgen-Progestin"
          },
          "50": {
            "drug_subclass": "Estrogen-Selective Estrogen Receptor Modulator Comb"
          },
          "00": {
            "drug_subclass": "Estrogen Combinations"
          }
        }
      },
      "00": {
        "drug_class": "Estrogens",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Estrogens"
          }
        }
      }
    },
    "drug_group": "ESTROGENS"
  },
  "25": {
    "drug_classes": {
      "10": {
        "drug_class": "Progestin Contraceptives - Oral",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Progestin Contraceptives - Oral"
          }
        }
      },
      "15": {
        "drug_class": "Progestin Contraceptives - Injectable",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Progestin Contraceptives - Injectable"
          }
        }
      },
      "20": {
        "drug_class": "Progestin Contraceptives - IUD",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Progestin Contraceptives - IUD"
          }
        }
      },
      "30": {
        "drug_class": "Progestin Contraceptives - Implants",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Progestin Contraceptives - Implants"
          }
        }
      },
      "40": {
        "drug_class": "Emergency Contraceptives",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Emergency Contraceptives"
          }
        }
      },
      "96": {
        "drug_class": "Combination Contraceptives - Transdermal",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Combination Contraceptives - Transdermal"
          }
        }
      },
      "97": {
        "drug_class": "Combination Contraceptives - Vaginal",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Combination Contraceptives - Vaginal"
          }
        }
      },
      "99": {
        "drug_class": "Combination Contraceptives - Oral",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Biphasic Contraceptives - Oral"
          },
          "20": {
            "drug_subclass": "Triphasic Contraceptives - Oral"
          },
          "24": {
            "drug_subclass": "Four Phase Contraceptives - Oral"
          },
          "30": {
            "drug_subclass": "Extended-Cycle Contraceptives - Oral"
          },
          "40": {
            "drug_subclass": "Continuous Contraceptives - Oral"
          },
          "00": {
            "drug_subclass": "Combination Contraceptives - Oral"
          }
        }
      },
      "05": {
        "drug_class": "Copper Contraceptives - IUD",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Copper Contraceptives - IUD"
          }
        }
      }
    },
    "drug_group": "CONTRACEPTIVES"
  },
  "26": {
    "drug_classes": {
      "00": {
        "drug_class": "Progestins",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Progestins"
          }
        }
      }
    },
    "drug_group": "PROGESTINS"
  },
  "27": {
    "drug_classes": {
      "10": {
        "drug_class": "Insulin",
        "drug_subclasses": {
          "40": {
            "drug_subclass": "Human Insulin"
          }
        }
      },
      "15": {
        "drug_class": "Antidiabetic - Amylin Analogs",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Antidiabetic - Amylin Analogs"
          }
        }
      },
      "17": {
        "drug_class": "Incretin Mimetic Agents (GLP-1 Receptor Agonists)",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Incretin Mimetic Agents (GLP-1 Receptor Agonists)"
          }
        }
      },
      "20": {
        "drug_class": "Sulfonylureas",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Sulfonylureas"
          }
        }
      },
      "25": {
        "drug_class": "Biguanides",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Biguanides"
          }
        }
      },
      "28": {
        "drug_class": "Meglitinide Analogues",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Meglitinide Analogues"
          }
        }
      },
      "30": {
        "drug_class": "Diabetic Other",
        "drug_subclasses": {
          "40": {
            "drug_subclass": "Progesterone Receptor Antagonists"
          },
          "99": {
            "drug_subclass": "Diabetic Other - Combinations"
          },
          "00": {
            "drug_subclass": "Diabetic Other"
          }
        }
      },
      "50": {
        "drug_class": "Alpha-Glucosidase Inhibitors",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Alpha-Glucosidase Inhibitors"
          }
        }
      },
      "55": {
        "drug_class": "Dipeptidyl Peptidase-4 (DPP-4) Inhibitors",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Dipeptidyl Peptidase-4 (DPP-4) Inhibitors"
          }
        }
      },
      "57": {
        "drug_class": "Dopamine Receptor Agonists - Antidiabetic",
        "drug_subclasses": {
          "40": {
            "drug_subclass": "Dopamine Receptor Agonists - Ergot Derivatives"
          }
        }
      },
      "60": {
        "drug_class": "Insulin Sensitizing Agents",
        "drug_subclasses": {
          "70": {
            "drug_subclass": "Thiazolidinediones"
          }
        }
      },
      "70": {
        "drug_class": "Sodium-Glucose Co-Transporter 2 (SGLT2) Inhibitors",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Sodium-Glucose Co-Transporter 2 (SGLT2) Inhibitors"
          }
        }
      },
      "99": {
        "drug_class": "Antidiabetic Combinations",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Insulin-Incretin Mimetic Combinations"
          },
          "25": {
            "drug_subclass": "Dipeptidyl Peptidase-4 Inhibitor-Biguanide Combinations"
          },
          "40": {
            "drug_subclass": "DPP-4 Inhibitor-Thiazolidinedione Combinations"
          },
          "50": {
            "drug_subclass": "Meglitinide-Biguanide Combinations"
          },
          "60": {
            "drug_subclass": "Sodium-Glucose Co-Transporter 2 Inhibitor-Biguanide Comb"
          },
          "65": {
            "drug_subclass": "SGLT2 Inhibitor - DPP-4 Inhibitor Combinations"
          },
          "67": {
            "drug_subclass": "SGLT2 Inhibitor - DPP-4 Inhibitor - Biguanide Comb"
          },
          "70": {
            "drug_subclass": "Sulfonylurea-Biguanide Combinations"
          },
          "78": {
            "drug_subclass": "Sulfonylurea-Thiazolidinedione Combinations"
          },
          "80": {
            "drug_subclass": "Thiazolidinedione-Biguanide Combinations"
          },
          "88": {
            "drug_subclass": "Biguanide-Diabetic Supplies Combinations"
          }
        }
      }
    },
    "drug_group": "ANTIDIABETICS"
  },
  "28": {
    "drug_classes": {
      "10": {
        "drug_class": "Thyroid Hormones",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Thyroid Hormones"
          }
        }
      },
      "30": {
        "drug_class": "Antithyroid Agents",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Antithyroid Agents"
          }
        }
      }
    },
    "drug_group": "THYROID AGENTS"
  },
  "29": {
    "drug_classes": {
      "20": {
        "drug_class": "Abortifacients/Agents for Cervical Ripening",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Abortifacients/Cervical Ripening - Prostaglandins"
          }
        }
      },
      "00": {
        "drug_class": "Oxytocics",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Oxytocics"
          }
        }
      }
    },
    "drug_group": "OXYTOCICS"
  },
  "30": {
    "drug_classes": {
      "10": {
        "drug_class": "Growth Hormones",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Growth Hormones"
          }
        }
      },
      "15": {
        "drug_class": "Growth Hormone Releasing Hormones (GHRH)",
        "drug_subclasses": {
          "99": {
            "drug_subclass": "Growth Hormone Releasing Hormones (GHRH) Combinations"
          },
          "00": {
            "drug_subclass": "Growth Hormone Releasing Hormones (GHRH)"
          }
        }
      },
      "16": {
        "drug_class": "Insulin-Like Growth Factors (Somatomedins)",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Insulin-Like Growth Factors (Somatomedins)"
          }
        }
      },
      "17": {
        "drug_class": "Somatostatic Agents",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Somatostatic Agents"
          }
        }
      },
      "18": {
        "drug_class": "Growth Hormone Receptor Antagonists",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Growth Hormone Receptor Antagonists"
          }
        }
      },
      "19": {
        "drug_class": "Insulin-Like Growth Factor Receptor Inhibitors",
        "drug_subclasses": {
          "20": {
            "drug_subclass": "Insulin-Like Growth Factor-1 Receptor Inhibitors(IGF-1R)"
          }
        }
      },
      "20": {
        "drug_class": "Posterior Pituitary Hormones",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Vasopressin"
          }
        }
      },
      "30": {
        "drug_class": "Corticotropin",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Corticotropin"
          }
        }
      },
      "40": {
        "drug_class": "Prolactin Inhibitors",
        "drug_subclasses": {
          "20": {
            "drug_subclass": "Dopamine Receptor Agonists"
          }
        }
      },
      "45": {
        "drug_class": "Vasopressin Receptor Antagonists",
        "drug_subclasses": {
          "20": {
            "drug_subclass": "V1a/V2-Arginine Vasopressin (AVP) Receptor Antagonists"
          },
          "40": {
            "drug_subclass": "Selective Vasopressin V2-Receptor Antagonists"
          }
        }
      },
      "50": {
        "drug_class": "Progesterone Receptor Antagonists",
        "drug_subclasses": {
          "20": {
            "drug_subclass": "Abortifacient - Progesterone Receptor Antagonists"
          }
        }
      },
      "90": {
        "drug_class": "Metabolic Modifiers",
        "drug_subclasses": {
          "20": {
            "drug_subclass": "Adenosine Deaminase SCID Treatment - Agents"
          },
          "30": {
            "drug_subclass": "Carnitine Replenisher - Agents"
          },
          "36": {
            "drug_subclass": "Fabry Disease - Agents"
          },
          "38": {
            "drug_subclass": "Hereditary Orotic Aciduria Treatment - Agent"
          },
          "40": {
            "drug_subclass": "Hereditary Tyrosinemia Type 1 (HT-1) Treatment - Agents"
          },
          "45": {
            "drug_subclass": "Homocystinuria Treatment - Agents"
          },
          "50": {
            "drug_subclass": "Hyperparathyroid Treatment - Vitamin D Analogs"
          },
          "52": {
            "drug_subclass": "Calcimimetic Agents"
          },
          "56": {
            "drug_subclass": "Hypophosphatasia (HPP) Agents"
          },
          "60": {
            "drug_subclass": "Leptin Analogues"
          },
          "63": {
            "drug_subclass": "Lysosomal Acid Lipase (LAL) Deficiency - Agents"
          },
          "64": {
            "drug_subclass": "Molybdenum Cofactor Deficiency (MoCD) - Agents"
          },
          "65": {
            "drug_subclass": "Mucopolysaccharidosis I (MPS I) - Agents"
          },
          "68": {
            "drug_subclass": "Mucopolysaccharidosis II (MPS II) - Agents"
          },
          "70": {
            "drug_subclass": "Mucopolysaccharidosis IV (MPS IV) - Agents"
          },
          "75": {
            "drug_subclass": "Mucopolysaccharidosis VI (MPS VI) - Agents"
          },
          "76": {
            "drug_subclass": "Mucopolysaccharidosis VII (MPS VII) - Agents"
          },
          "77": {
            "drug_subclass": "GAA Deficiency Treatment - Agents"
          },
          "80": {
            "drug_subclass": "Urea Cycle Disorder - Agents"
          },
          "82": {
            "drug_subclass": "Hyperammonemia Treatment - Agents"
          },
          "85": {
            "drug_subclass": "Phenylketonuria Treatment - Agents"
          },
          "90": {
            "drug_subclass": "Tripeptidyl Peptidase 1 Deficiency Treatment - Agents"
          },
          "95": {
            "drug_subclass": "X-Linked Hypophosphatemia (XLH) Treatment - Agents"
          }
        }
      },
      "02": {
        "drug_class": "Adrenal Steroid Inhibitors",
        "drug_subclasses": {
          "20": {
            "drug_subclass": "Cortisol Synthesis Inhibitors"
          }
        }
      },
      "04": {
        "drug_class": "Bone Density Regulators",
        "drug_subclasses": {
          "20": {
            "drug_subclass": "Bisphosphonates"
          },
          "30": {
            "drug_subclass": "Calcitonins"
          },
          "40": {
            "drug_subclass": "Parathyroid Hormone And Derivatives"
          },
          "45": {
            "drug_subclass": "RANK Ligand (RANKL) Inhibitors"
          },
          "48": {
            "drug_subclass": "Sclerostin Inhibitors"
          }
        }
      },
      "05": {
        "drug_class": "Hormone Receptor Modulators",
        "drug_subclasses": {
          "30": {
            "drug_subclass": "Selective Estrogen Receptor Modulators (SERMs)"
          }
        }
      },
      "06": {
        "drug_class": "Fertility Regulators",
        "drug_subclasses": {
          "20": {
            "drug_subclass": "Ovulation Stimulants-Gonadotropins"
          },
          "60": {
            "drug_subclass": "Ovulation Stimulants-Synthetic"
          }
        }
      },
      "08": {
        "drug_class": "LHRH/GnRH Agonist Analog Pituitary Suppressants",
        "drug_subclasses": {
          "99": {
            "drug_subclass": "LHRH/GnRH Agonist Analog Combinations"
          },
          "00": {
            "drug_subclass": "LHRH/GnRH Agonist Analog Pituitary Suppressants"
          }
        }
      },
      "09": {
        "drug_class": "GnRH/LHRH Antagonists",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "GnRH/LHRH Antagonists"
          }
        }
      }
    },
    "drug_group": "ENDOCRINE AND METABOLIC AGENTS - MISC."
  },
  "31": {
    "drug_classes": {
      "10": {
        "drug_class": "Phosphodiesterase Inhibitors",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Phosphodiesterase Inhibitors"
          }
        }
      },
      "20": {
        "drug_class": "Cardiac Glycosides",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Cardiac Glycosides"
          }
        }
      }
    },
    "drug_group": "CARDIOTONICS"
  },
  "32": {
    "drug_classes": {
      "10": {
        "drug_class": "Nitrates",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Nitrates"
          }
        }
      },
      "20": {
        "drug_class": "Antianginals-Other",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Antianginals-Other"
          }
        }
      }
    },
    "drug_group": "ANTIANGINAL AGENTS"
  },
  "33": {
    "drug_classes": {
      "10": {
        "drug_class": "Beta Blockers Non-Selective",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Beta Blockers Non-Selective"
          }
        }
      },
      "20": {
        "drug_class": "Beta Blockers Cardio-Selective",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Beta Blockers Cardio-Selective"
          }
        }
      },
      "30": {
        "drug_class": "Alpha-Beta Blockers",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Alpha-Beta Blockers"
          }
        }
      }
    },
    "drug_group": "BETA BLOCKERS"
  },
  "34": {
    "drug_classes": {
      "99": {
        "drug_class": "Calcium Channel Blocker Combinations",
        "drug_subclasses": {
          "87": {
            "drug_subclass": "Calcium Channel Blocker-NSAID Combinations"
          }
        }
      },
      "00": {
        "drug_class": "Calcium Channel Blockers",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Calcium Channel Blockers"
          }
        }
      }
    },
    "drug_group": "CALCIUM CHANNEL BLOCKERS"
  },
  "35": {
    "drug_classes": {
      "10": {
        "drug_class": "Antiarrhythmics Type I-A",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Antiarrhythmics Type I-A"
          }
        }
      },
      "20": {
        "drug_class": "Antiarrhythmics Type I-B",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Antiarrhythmics Type I-B"
          }
        }
      },
      "30": {
        "drug_class": "Antiarrhythmics Type I-C",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Antiarrhythmics Type I-C"
          }
        }
      },
      "40": {
        "drug_class": "Antiarrhythmics Type III",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Antiarrhythmics Type III"
          }
        }
      },
      "50": {
        "drug_class": "Antiarrhythmics - Misc.",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Antiarrhythmics - Misc."
          }
        }
      }
    },
    "drug_group": "ANTIARRHYTHMICS"
  },
  "36": {
    "drug_classes": {
      "10": {
        "drug_class": "ACE Inhibitors",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "ACE Inhibitors"
          }
        }
      },
      "15": {
        "drug_class": "Angiotensin II Receptor Antagonists",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Angiotensin II Receptor Antagonists"
          }
        }
      },
      "17": {
        "drug_class": "Direct Renin Inhibitors",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Direct Renin Inhibitors"
          }
        }
      },
      "20": {
        "drug_class": "Antiadrenergic Antihypertensives",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Antiadrenergics - Centrally Acting"
          },
          "20": {
            "drug_subclass": "Antiadrenergics - Peripherally Acting"
          },
          "30": {
            "drug_subclass": "Reserpine"
          }
        }
      },
      "25": {
        "drug_class": "Selective Aldosterone Receptor Antagonists (SARAs)",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Selective Aldosterone Receptor Antagonists (SARAs)"
          }
        }
      },
      "30": {
        "drug_class": "Agents for Pheochromocytoma",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Agents for Pheochromocytoma"
          }
        }
      },
      "40": {
        "drug_class": "Vasodilators",
        "drug_subclasses": {
          "20": {
            "drug_subclass": "Dopamine D1 Receptor Agonists"
          },
          "00": {
            "drug_subclass": "Vasodilators"
          }
        }
      },
      "60": {
        "drug_class": "Antihypertensives - Misc.",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Antihypertensives - Misc."
          }
        }
      },
      "99": {
        "drug_class": "Antihypertensive Combinations",
        "drug_subclasses": {
          "15": {
            "drug_subclass": "ACE Inhibitor & Calcium Channel Blocker Combinations"
          },
          "18": {
            "drug_subclass": "ACE Inhibitors & Thiazide/Thiazide-Like"
          },
          "20": {
            "drug_subclass": "Beta Blocker & Diuretic Combinations"
          },
          "27": {
            "drug_subclass": "Beta Blocker & Angiotensin II Receptor Antagonist Comb"
          },
          "30": {
            "drug_subclass": "Angiotensin II Receptor Antag & Ca Channel Blocker Comb"
          },
          "40": {
            "drug_subclass": "Angiotensin II Receptor Antag & Thiazide/Thiazide-Like"
          },
          "45": {
            "drug_subclass": "Angiotensin II Receptor Ant-Ca Channel Blocker-Thiazides"
          },
          "50": {
            "drug_subclass": "Adrenolytics-Central & Thiazide/Thiazide-Like Comb"
          },
          "60": {
            "drug_subclass": "Direct Renin Inhibitors & Thiazide/Thiazide-Like Comb"
          }
        }
      }
    },
    "drug_group": "ANTIHYPERTENSIVES"
  },
  "37": {
    "drug_classes": {
      "10": {
        "drug_class": "Carbonic Anhydrase Inhibitors",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Carbonic Anhydrase Inhibitors"
          }
        }
      },
      "20": {
        "drug_class": "Loop Diuretics",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Loop Diuretics"
          }
        }
      },
      "40": {
        "drug_class": "Osmotic Diuretics",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Osmotic Diuretics"
          }
        }
      },
      "50": {
        "drug_class": "Potassium Sparing Diuretics",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Potassium Sparing Diuretics"
          }
        }
      },
      "60": {
        "drug_class": "Thiazides and Thiazide-Like Diuretics",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Thiazides and Thiazide-Like Diuretics"
          }
        }
      },
      "90": {
        "drug_class": "Diuretics - Miscellaneous",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Diuretics - Miscellaneous"
          }
        }
      },
      "99": {
        "drug_class": "Diuretic Combinations",
        "drug_subclasses": {
          "20": {
            "drug_subclass": "Non Prescription Diuretics"
          },
          "00": {
            "drug_subclass": "Diuretic Combinations"
          }
        }
      }
    },
    "drug_group": "DIURETICS"
  },
  "38": {
    "drug_classes": {
      "70": {
        "drug_class": "Neurogenic Orthostatic Hypotension (NOH) - Agents",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Neurogenic Orthostatic Hypotension (NOH) - Agents"
          }
        }
      },
      "90": {
        "drug_class": "Anaphylaxis Therapy Agents",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Anaphylaxis Therapy Agents"
          }
        }
      },
      "00": {
        "drug_class": "Vasopressors",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Vasopressors"
          }
        }
      }
    },
    "drug_group": "VASOPRESSORS"
  },
  "39": {
    "drug_classes": {
      "10": {
        "drug_class": "Bile Acid Sequestrants",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Bile Acid Sequestrants"
          }
        }
      },
      "20": {
        "drug_class": "Fibric Acid Derivatives",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Fibric Acid Derivatives"
          }
        }
      },
      "30": {
        "drug_class": "Intestinal Cholesterol Absorption Inhibitors",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Intestinal Cholesterol Absorption Inhibitors"
          }
        }
      },
      "35": {
        "drug_class": "Proprotein Convertase Subtilisin/Kexin Type 9 Inhibitors",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "PCSK9 Inhibitors"
          }
        }
      },
      "38": {
        "drug_class": "Adenosine Triphosphate-Citrate Lyase (ACL) Inhibitors",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Adenosine Triphosphate-Citrate Lyase (ACL) Inhibitors"
          }
        }
      },
      "39": {
        "drug_class": "Angiopoietin-like Protein Inhibitors",
        "drug_subclasses": {
          "20": {
            "drug_subclass": "Angiopoietin-like Protein 3 (ANGPTL3) Inhibitors"
          }
        }
      },
      "40": {
        "drug_class": "HMG CoA Reductase Inhibitors",
        "drug_subclasses": {
          "99": {
            "drug_subclass": "HMG CoA Reductase Inhibitor Combinations"
          },
          "00": {
            "drug_subclass": "HMG CoA Reductase Inhibitors"
          }
        }
      },
      "45": {
        "drug_class": "Nicotinic Acid Derivatives",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Nicotinic Acid Derivatives"
          }
        }
      },
      "48": {
        "drug_class": "Microsomal Triglyceride Transfer Protein (MTP) Inhibitors",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Microsomal Triglyceride Transfer Protein Inhibitors"
          }
        }
      },
      "50": {
        "drug_class": "Antihyperlipidemics - Misc.",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Antihyperlipidemics - Misc."
          }
        }
      },
      "99": {
        "drug_class": "Antihyperlipidemics - Combinations",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "ACL Inhib-Intestinal Cholesterol Absorption Inhib Comb"
          },
          "40": {
            "drug_subclass": "Intest Cholest Absorp Inhib-HMG CoA Reductase Inhib Comb"
          },
          "90": {
            "drug_subclass": "Antihyperlipidemics Misc. Combinations"
          }
        }
      }
    },
    "drug_group": "ANTIHYPERLIPIDEMICS"
  },
  "40": {
    "drug_classes": {
      "10": {
        "drug_class": "Peripheral Vasodilators",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Peripheral Vasodilators"
          }
        }
      },
      "12": {
        "drug_class": "Pulmonary Hypertension - Prostacyclin Receptor Agonist",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Pulmonary Hypertension - Prostacyclin Receptor Agonist"
          }
        }
      },
      "13": {
        "drug_class": "Pulmonary Hypertension - Sol Guanylate Cyclase Stimulator",
        "drug_subclasses": {
          "40": {
            "drug_subclass": "Pulm Hyperten-Soluble Guanylate Cyclase Stimulator (sGC)"
          }
        }
      },
      "14": {
        "drug_class": "Pulmonary Hypertension - Phosphodiesterase Inhibitors",
        "drug_subclasses": {
          "30": {
            "drug_subclass": "Pulmonary Hypertension - Phosphodiesterase Inhibitors"
          }
        }
      },
      "16": {
        "drug_class": "Pulmonary Hypertension - Endothelin Receptor Antagonists",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Pulmonary Hypertension - Endothelin Receptor Antagonists"
          }
        }
      },
      "17": {
        "drug_class": "Prostaglandin Vasodilators",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Prostaglandin Vasodilators"
          }
        }
      },
      "18": {
        "drug_class": "Vasoactive Natriuretic Peptides",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Vasoactive Natriuretic Peptides"
          }
        }
      },
      "20": {
        "drug_class": "Cardioplegic Solutions",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Cardioplegic Solutions"
          }
        }
      },
      "30": {
        "drug_class": "Impotence Agents",
        "drug_subclasses": {
          "30": {
            "drug_subclass": "Prostaglandin - Impotence Agents"
          },
          "40": {
            "drug_subclass": "Selective cGMP Phosphodiesterase Type 5 Inhibitors"
          },
          "90": {
            "drug_subclass": "Impotence Agents - Other"
          },
          "99": {
            "drug_subclass": "Impotence Agent Combinations"
          }
        }
      },
      "50": {
        "drug_class": "Septal Agents",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Septal Agents - Ablatio"
          }
        }
      },
      "55": {
        "drug_class": "Transthyretin Stabilizers",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Transthyretin Stabilizers"
          }
        }
      },
      "70": {
        "drug_class": "Sinus Node Inhibitor",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Sinus Node Inhibitor"
          }
        }
      },
      "90": {
        "drug_class": "Vasoactive Soluble Guanylate Cyclase Stimulator (sGC)",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Vasoactive Soluble Guanylate Cyclase Stimulator (sGC)"
          }
        }
      },
      "99": {
        "drug_class": "Cardiovascular Agents Misc. - Combinations",
        "drug_subclasses": {
          "20": {
            "drug_subclass": "Neprilysin Inhib (ARNI)-Angiotensin II Recept Antag Comb"
          },
          "25": {
            "drug_subclass": "Calcium Channel Blocker & HMG CoA Reductase Inhibit Comb"
          },
          "50": {
            "drug_subclass": "Nitrate & Vasodilator Combinations"
          }
        }
      }
    },
    "drug_group": "CARDIOVASCULAR AGENTS - MISC."
  },
  "41": {
    "drug_classes": {
      "10": {
        "drug_class": "Antihistamines - Alkylamines",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Antihistamines - Alkylamines"
          }
        }
      },
      "20": {
        "drug_class": "Antihistamines - Ethanolamines",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Antihistamines - Ethanolamines"
          }
        }
      },
      "30": {
        "drug_class": "Antihistamines - Ethylenediamines",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Antihistamines - Ethylenediamines"
          }
        }
      },
      "40": {
        "drug_class": "Antihistamines - Phenothiazines",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Antihistamines - Phenothiazines"
          }
        }
      },
      "45": {
        "drug_class": "Antihistamines - Piperazines",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Antihistamines - Piperazines"
          }
        }
      },
      "50": {
        "drug_class": "Antihistamines - Piperidines",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Antihistamines - Piperidines"
          }
        }
      },
      "55": {
        "drug_class": "Antihistamines - Non-Sedating",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Antihistamines - Non-Sedating"
          }
        }
      }
    },
    "drug_group": "ANTIHISTAMINES"
  },
  "42": {
    "drug_classes": {
      "10": {
        "drug_class": "Sympathomimetic Decongestants",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Systemic Decongestants"
          },
          "20": {
            "drug_subclass": "Topical Decongestants"
          },
          "30": {
            "drug_subclass": "Nasal Decongestant Inhalers"
          }
        }
      },
      "20": {
        "drug_class": "Nasal Steroids",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Nasal Steroids"
          }
        }
      },
      "23": {
        "drug_class": "Nasal Anesthetics",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Nasal Anesthetics"
          }
        }
      },
      "25": {
        "drug_class": "Nasal Anti-infectives",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Nasal Antibiotics"
          }
        }
      },
      "30": {
        "drug_class": "Nasal Anticholinergics",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Nasal Anticholinergics"
          }
        }
      },
      "40": {
        "drug_class": "Nasal Antiallergy",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Nasal Antihistamines"
          },
          "50": {
            "drug_subclass": "Nasal Mast Cell Stabilizers"
          }
        }
      },
      "50": {
        "drug_class": "Nasal Agents - Misc.",
        "drug_subclasses": {
          "99": {
            "drug_subclass": "Nasal Agents Misc. - Combinations"
          },
          "00": {
            "drug_subclass": "Nasal Agents - Misc."
          }
        }
      },
      "99": {
        "drug_class": "Nasal Agent Combinations",
        "drug_subclasses": {
          "20": {
            "drug_subclass": "Decongestant Combination Other"
          },
          "30": {
            "drug_subclass": "Decongestant-Analgesic-Expectorant-Non-Narc Antitussive"
          },
          "55": {
            "drug_subclass": "Antihistamine-Steroid"
          }
        }
      }
    },
    "drug_group": "NASAL AGENTS - SYSTEMIC AND TOPICAL"
  },
  "43": {
    "drug_classes": {
      "10": {
        "drug_class": "Antitussives",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Antitussive - Opioid"
          },
          "20": {
            "drug_subclass": "Antitussive - Nonnarcotic"
          }
        }
      },
      "20": {
        "drug_class": "Expectorants",
        "drug_subclasses": {
          "20": {
            "drug_subclass": "Iodine Expectorants"
          },
          "00": {
            "drug_subclass": "Expectorants"
          }
        }
      },
      "30": {
        "drug_class": "Mucolytics",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Mucolytics"
          }
        }
      },
      "40": {
        "drug_class": "Misc. Respiratory Inhalants",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Aromatic Inhalants"
          },
          "00": {
            "drug_subclass": "Misc. Respiratory Inhalants"
          }
        }
      },
      "99": {
        "drug_class": "Cough/Cold/Allergy Combinations",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Decongestant-Analgesic"
          },
          "20": {
            "drug_subclass": "Antihistamine-Analgesics"
          },
          "30": {
            "drug_subclass": "Decongestant & Antihistamine"
          },
          "40": {
            "drug_subclass": "Decongestant-Antihistamine-Analgesic"
          },
          "51": {
            "drug_subclass": "Opioid Antitussive-Decongestant"
          },
          "52": {
            "drug_subclass": "Opioid Antitussive-Antihistamine"
          },
          "53": {
            "drug_subclass": "Opioid Antitussive-Decongestant-Antihistamine"
          },
          "55": {
            "drug_subclass": "Non-Narc Antitussive-Analgesic"
          },
          "56": {
            "drug_subclass": "Non-Narc Antitussive-Decongestant"
          },
          "57": {
            "drug_subclass": "Non-Narc Antitussive-Antihistamine"
          },
          "58": {
            "drug_subclass": "Non-Narc Antitussive-Decongestant-Antihistamine"
          },
          "59": {
            "drug_subclass": "Non-Narc Antitussive-Decongestant-Antihistamine-Analg"
          },
          "61": {
            "drug_subclass": "Analgesic-Expectorant"
          },
          "62": {
            "drug_subclass": "Decongestant w/ Expectorant"
          },
          "66": {
            "drug_subclass": "Decongestant-Antihistamine w/ Expectorant"
          },
          "67": {
            "drug_subclass": "Decongestant-Analgesic-Expectorant"
          },
          "68": {
            "drug_subclass": "Decongestant-Antihistamine-Analgesic w/ Expectorant"
          },
          "69": {
            "drug_subclass": "Antitussive-Decongestant-Analgesic"
          },
          "70": {
            "drug_subclass": "Antitussive-Expectorant"
          },
          "73": {
            "drug_subclass": "Antitussive-Expectorants-Decongestant"
          },
          "75": {
            "drug_subclass": "Antitussive-Expectorants-Antihistamine"
          },
          "80": {
            "drug_subclass": "Antitussive-Expectorant - Decongest-Antihist"
          },
          "83": {
            "drug_subclass": "Antitussive-Expectorant - Decongest-Analgesic"
          },
          "85": {
            "drug_subclass": "Antitussive-Expectorant - Antihist-Analgesic"
          },
          "88": {
            "drug_subclass": "Antitussive-Expectorant - Decongest-Antihist-Analg"
          },
          "89": {
            "drug_subclass": "Antitussive-Antihistamine-Analgesic"
          }
        }
      }
    },
    "drug_group": "COUGH/COLD/ALLERGY"
  },
  "44": {
    "drug_classes": {
      "10": {
        "drug_class": "Bronchodilators - Anticholinergics",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Bronchodilators - Anticholinergics"
          }
        }
      },
      "15": {
        "drug_class": "Anti-Inflammatory Agents",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Anti-Inflammatory Agents"
          }
        }
      },
      "20": {
        "drug_class": "Sympathomimetics",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Beta Adrenergics"
          },
          "20": {
            "drug_subclass": "Mixed Adrenergics"
          },
          "99": {
            "drug_subclass": "Adrenergic Combinations"
          }
        }
      },
      "30": {
        "drug_class": "Xanthines",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Xanthines"
          }
        }
      },
      "40": {
        "drug_class": "Steroid Inhalants",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Steroid Inhalants"
          }
        }
      },
      "45": {
        "drug_class": "Selective Phosphodiesterase 4 (PDE4) Inhibitors",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Selective Phosphodiesterase 4 (PDE4) Inhibitors"
          }
        }
      },
      "50": {
        "drug_class": "Leukotriene Modulators",
        "drug_subclasses": {
          "40": {
            "drug_subclass": "5-Lipoxygenase Inhibitors"
          },
          "50": {
            "drug_subclass": "Leukotriene Receptor Antagonists"
          }
        }
      },
      "60": {
        "drug_class": "Antiasthmatic - Monoclonal Antibodies",
        "drug_subclasses": {
          "30": {
            "drug_subclass": "Anti-IgE Monoclonal Antibodies"
          },
          "40": {
            "drug_subclass": "Interleukin-5 Antagonists (IgG1 kappa)"
          },
          "44": {
            "drug_subclass": "Interleukin-5 Antagonists (IgG4 kappa)"
          }
        }
      },
      "99": {
        "drug_class": "Asthma and Bronchodilator Agent Combinations",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Xanthine-Expectorants"
          }
        }
      }
    },
    "drug_group": "ANTIASTHMATIC AND BRONCHODILATOR AGENTS"
  },
  "45": {
    "drug_classes": {
      "10": {
        "drug_class": "Alpha-Proteinase Inhibitor (Human)",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Alpha-Proteinase Inhibitor (Human)"
          }
        }
      },
      "30": {
        "drug_class": "Cystic Fibrosis Agents",
        "drug_subclasses": {
          "20": {
            "drug_subclass": "CFTR Potentiators"
          },
          "40": {
            "drug_subclass": "Hydrolytic Enzymes"
          },
          "70": {
            "drug_subclass": "Cystic Fibrosis Agents - Miscellaneous"
          },
          "99": {
            "drug_subclass": "Cystic Fibrosis Agent - Combinations"
          }
        }
      },
      "50": {
        "drug_class": "Pleural Sclerosing Agents",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Pleural Sclerosing Agents"
          }
        }
      },
      "55": {
        "drug_class": "Pulmonary Fibrosis Agents",
        "drug_subclasses": {
          "40": {
            "drug_subclass": "Pulmonary Fibrosis Agents - Kinase Inhibitors"
          },
          "00": {
            "drug_subclass": "Pulmonary Fibrosis Agents"
          }
        }
      },
      "00": {
        "drug_class": "Respiratory Agents - Misc.",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Respiratory Agents - Misc."
          }
        }
      }
    },
    "drug_group": "RESPIRATORY AGENTS - MISC."
  },
  "46": {
    "drug_classes": {
      "10": {
        "drug_class": "Saline Laxatives",
        "drug_subclasses": {
          "99": {
            "drug_subclass": "Saline Laxative Mixtures"
          },
          "00": {
            "drug_subclass": "Saline Laxatives"
          }
        }
      },
      "20": {
        "drug_class": "Stimulant Laxatives",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Stimulant Laxatives"
          }
        }
      },
      "30": {
        "drug_class": "Bulk Laxatives",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Bulk Laxatives"
          }
        }
      },
      "40": {
        "drug_class": "Lubricant Laxatives",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Lubricant Laxatives"
          }
        }
      },
      "50": {
        "drug_class": "Surfactant Laxatives",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Surfactant Laxatives"
          }
        }
      },
      "60": {
        "drug_class": "Laxatives - Miscellaneous",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Laxatives - Miscellaneous"
          }
        }
      },
      "99": {
        "drug_class": "Laxative Combinations",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Laxatives & DSS"
          },
          "20": {
            "drug_subclass": "Bowel Evacuant Combinations"
          },
          "00": {
            "drug_subclass": "Laxative Combinations"
          }
        }
      }
    },
    "drug_group": "LAXATIVES"
  },
  "47": {
    "drug_classes": {
      "10": {
        "drug_class": "Antiperistaltic Agents",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Antiperistaltic Agents"
          }
        }
      },
      "20": {
        "drug_class": "Gastrointestinal Adsorbents",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Gastrointestinal Adsorbents"
          }
        }
      },
      "25": {
        "drug_class": "Antidiarrheal - Chloride Channel Antagonists",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Antidiarrheal - Chloride Channel Antagonists"
          }
        }
      },
      "30": {
        "drug_class": "Antidiarrheal/Probiotic Agents - Misc.",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Antidiarrheal/Probiotic Agents - Misc."
          }
        }
      },
      "99": {
        "drug_class": "Antidiarrheal/Probiotic Combinations",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Diarrhea Combinations - Opiates"
          },
          "00": {
            "drug_subclass": "Antidiarrheal/Probiotic Combinations"
          }
        }
      }
    },
    "drug_group": "ANTIDIARRHEAL/PROBIOTIC AGENTS"
  },
  "48": {
    "drug_classes": {
      "10": {
        "drug_class": "Antacids - Aluminum Salts",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Antacids - Aluminum Salts"
          }
        }
      },
      "20": {
        "drug_class": "Antacids - Bicarbonate",
        "drug_subclasses": {
          "99": {
            "drug_subclass": "Antacids - Bicarbonate Combinations"
          },
          "00": {
            "drug_subclass": "Antacids - Bicarbonate"
          }
        }
      },
      "30": {
        "drug_class": "Antacids - Calcium Salts",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Antacids - Calcium Salts"
          }
        }
      },
      "40": {
        "drug_class": "Antacids - Magnesium Salts",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Antacids - Magnesium Salts"
          }
        }
      },
      "50": {
        "drug_class": "Antacids - Sodium Citrate",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Antacids - Sodium Citrate"
          }
        }
      },
      "99": {
        "drug_class": "Antacid Combinations",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Antacid & Simethicone"
          },
          "00": {
            "drug_subclass": "Antacid Combinations"
          }
        }
      }
    },
    "drug_group": "ANTACIDS"
  },
  "49": {
    "drug_classes": {
      "10": {
        "drug_class": "Antispasmodics",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Belladonna Alkaloids"
          },
          "20": {
            "drug_subclass": "Quaternary Anticholinergics"
          },
          "30": {
            "drug_subclass": "Antispasmodics"
          },
          "99": {
            "drug_subclass": "Anticholinergic Combinations"
          }
        }
      },
      "20": {
        "drug_class": "H-2 Antagonists",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "H-2 Antagonists"
          }
        }
      },
      "25": {
        "drug_class": "Ulcer Drugs - Prostaglandins",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Ulcer Drugs - Prostaglandins"
          }
        }
      },
      "27": {
        "drug_class": "Proton Pump Inhibitors",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Proton Pump Inhibitors"
          }
        }
      },
      "30": {
        "drug_class": "Misc. Anti-Ulcer",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Misc. Anti-Ulcer"
          }
        }
      },
      "99": {
        "drug_class": "Ulcer Therapy Combinations",
        "drug_subclasses": {
          "20": {
            "drug_subclass": "Ulcer Anti-Infective w/ Bismuth Combinations"
          },
          "30": {
            "drug_subclass": "Ulcer Anti-Infective w/ Proton Pump Inhibitors"
          },
          "35": {
            "drug_subclass": "H-2 Antagonist-Antacid Combinations"
          },
          "60": {
            "drug_subclass": "Proton Pump Inhibitor-Antacid Combinations"
          }
        }
      }
    },
    "drug_group": "ULCER DRUGS/ANTISPASMODICS/ANTICHOLINERGICS"
  },
  "50": {
    "drug_classes": {
      "10": {
        "drug_class": "Antiemetics - Antidopaminergic",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Antiemetics - Antidopaminergic"
          }
        }
      },
      "20": {
        "drug_class": "Antiemetics - Anticholinergic",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Antiemetics - Anticholinergic"
          }
        }
      },
      "25": {
        "drug_class": "5-HT3 Receptor Antagonists",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "5-HT3 Receptor Antagonists"
          }
        }
      },
      "28": {
        "drug_class": "Substance P/Neurokinin 1 (NK1) Receptor Antagonists",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Substance P/Neurokinin 1 (NK1) Receptor Antagonists"
          }
        }
      },
      "30": {
        "drug_class": "Antiemetics - Miscellaneous",
        "drug_subclasses": {
          "99": {
            "drug_subclass": "Antiemetic Combinations"
          },
          "00": {
            "drug_subclass": "Antiemetics - Miscellaneous"
          }
        }
      }
    },
    "drug_group": "ANTIEMETICS"
  },
  "51": {
    "drug_classes": {
      "20": {
        "drug_class": "Digestive Enzymes",
        "drug_subclasses": {
          "99": {
            "drug_subclass": "Digestive Enzyme Combinations"
          },
          "00": {
            "drug_subclass": "Digestive Enzymes"
          }
        }
      },
      "30": {
        "drug_class": "Gastric Acidifiers",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Gastric Acidifiers"
          }
        }
      },
      "99": {
        "drug_class": "Digestive Aids - Mixtures",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Digestive Aids - Mixtures"
          }
        }
      }
    },
    "drug_group": "DIGESTIVE AIDS"
  },
  "52": {
    "drug_classes": {
      "10": {
        "drug_class": "Gallstone Solubilizing Agents",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Gallstone Solubilizing Agents"
          }
        }
      },
      "16": {
        "drug_class": "Gastrointestinal Antiallergy Agents",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Gastrointestinal Antiallergy Agents"
          }
        }
      },
      "20": {
        "drug_class": "Antiflatulents",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Antiflatulents"
          }
        }
      },
      "30": {
        "drug_class": "Gastrointestinal Stimulants",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Gastrointestinal Stimulants"
          }
        }
      },
      "40": {
        "drug_class": "Intestinal Acidifiers",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Intestinal Acidifiers"
          }
        }
      },
      "45": {
        "drug_class": "Gastrointestinal Chloride Channel Activators",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Gastrointestinal Chloride Channel Activators"
          }
        }
      },
      "50": {
        "drug_class": "Inflammatory Bowel Agents",
        "drug_subclasses": {
          "30": {
            "drug_subclass": "Integrin Receptor Antagonists"
          },
          "40": {
            "drug_subclass": "Interleukin Antagonists"
          },
          "50": {
            "drug_subclass": "Tumor Necrosis Factor Alpha Blockers"
          },
          "00": {
            "drug_subclass": "Inflammatory Bowel Agents"
          }
        }
      },
      "53": {
        "drug_class": "Short Bowel Syndrome (SBS) Agents",
        "drug_subclasses": {
          "30": {
            "drug_subclass": "Glucagon-Like Peptide-2 (GLP-2) Analogs"
          }
        }
      },
      "54": {
        "drug_class": "Agents for Chronic Idiopathic Constipation (CIC)",
        "drug_subclasses": {
          "30": {
            "drug_subclass": "CIC Agents - Guanylate Cyclase-C (GC-C) Agonists"
          }
        }
      },
      "55": {
        "drug_class": "Irritable Bowel Syndrome (IBS) Agents",
        "drug_subclasses": {
          "40": {
            "drug_subclass": "IBS Agent - Selective 5-HT3 Receptor Antagonists"
          },
          "50": {
            "drug_subclass": "IBS Agent - 5-HT4 Receptor Partial Agonists"
          },
          "70": {
            "drug_subclass": "IBS Agent - Guanylate Cyclase-C (GC-C) Agonists"
          },
          "80": {
            "drug_subclass": "IBS Agent - Mu-Opioid Receptor Agonists"
          }
        }
      },
      "56": {
        "drug_class": "5-HT4 Receptor Agonists",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "5-HT4 Receptor Agonists"
          }
        }
      },
      "57": {
        "drug_class": "Tryptophan Hydroxylase Inhibitors",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Tryptophan Hydroxylase Inhibitors"
          }
        }
      },
      "58": {
        "drug_class": "Peripheral Opioid Receptor Antagonists",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Peripheral Opioid Receptor Antagonists"
          }
        }
      },
      "70": {
        "drug_class": "Bile Acid Synthesis Disorder Agents",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Bile Acid Synthesis Disorder Agents"
          }
        }
      },
      "75": {
        "drug_class": "Farnesoid X Receptor (FXR) Agonists",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Farnesoid X Receptor (FXR) Agonists"
          }
        }
      },
      "80": {
        "drug_class": "Phosphate Binder Agents",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Phosphate Binder Agents"
          }
        }
      }
    },
    "drug_group": "GASTROINTESTINAL AGENTS - MISC."
  },
  "53": {
    "drug_classes": {
      "99": {
        "drug_class": "Urinary Anti-infective Combinations",
        "drug_subclasses": {
          "20": {
            "drug_subclass": "Urinary Antiseptic-Antispasmodic &/or Analgesics"
          }
        }
      },
      "00": {
        "drug_class": "Urinary Anti-infectives",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Urinary Anti-infectives"
          }
        }
      }
    },
    "drug_group": "URINARY ANTI-INFECTIVES"
  },
  "54": {
    "drug_classes": {
      "10": {
        "drug_class": "Urinary Antispasmodic - Antimuscarinics (Anticholinergic)",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Urinary Antispasmodic - Antimuscarinic (Anticholinergic)"
          }
        }
      },
      "20": {
        "drug_class": "Urinary Antispasmodics - Beta-3 Adrenergic Agonists",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Urinary Antispasmodics - Beta-3 Adrenergic Agonists"
          }
        }
      },
      "30": {
        "drug_class": "Urinary Antispasmodics - Cholinergic Agonists",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Urinary Antispasmodics - Cholinergic Agonists"
          }
        }
      },
      "40": {
        "drug_class": "Urinary Antispasmodics - Direct Muscle Relaxants",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Urinary Antispasmodics - Direct Muscle Relaxants"
          }
        }
      }
    },
    "drug_group": "URINARY ANTISPASMODICS"
  },
  "55": {
    "drug_classes": {
      "10": {
        "drug_class": "Vaginal Anti-infectives",
        "drug_subclasses": {
          "40": {
            "drug_subclass": "Imidazole-Related Antifungals"
          },
          "00": {
            "drug_subclass": "Vaginal Anti-infectives"
          }
        }
      },
      "20": {
        "drug_class": "Douche Products",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Douche Products"
          }
        }
      },
      "30": {
        "drug_class": "Spermicides",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Spermicides"
          }
        }
      },
      "32": {
        "drug_class": "Vaginal Contraceptive - pH Modulators",
        "drug_subclasses": {
          "99": {
            "drug_subclass": "Vaginal Contraceptive pH Modulator - Combinations"
          }
        }
      },
      "35": {
        "drug_class": "Vaginal Estrogens",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Vaginal Estrogens"
          }
        }
      },
      "37": {
        "drug_class": "Vaginal Progestins",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Vaginal Progestins"
          }
        }
      },
      "40": {
        "drug_class": "Miscellaneous Vaginal Products",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Fertility Enhancers"
          },
          "99": {
            "drug_subclass": "Miscellaneous Vaginal Combinations"
          },
          "00": {
            "drug_subclass": "Miscellaneous Vaginal Products"
          }
        }
      }
    },
    "drug_group": "VAGINAL AND RELATED PRODUCTS"
  },
  "56": {
    "drug_classes": {
      "10": {
        "drug_class": "Acidifiers",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Phosphates"
          }
        }
      },
      "20": {
        "drug_class": "Alkalinizers",
        "drug_subclasses": {
          "20": {
            "drug_subclass": "Citrates"
          }
        }
      },
      "30": {
        "drug_class": "Urinary Analgesics",
        "drug_subclasses": {
          "99": {
            "drug_subclass": "Urinary Analgesic Combinations"
          },
          "00": {
            "drug_subclass": "Urinary Analgesics"
          }
        }
      },
      "40": {
        "drug_class": "Cystinosis Agents",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Cystinosis Agents"
          }
        }
      },
      "50": {
        "drug_class": "Interstitial Cystitis Agents",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Interstitial Cystitis Agents"
          }
        }
      },
      "60": {
        "drug_class": "Urinary Stone Agents",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Urinary Stone Agents"
          }
        }
      },
      "62": {
        "drug_class": "Hyperoxaluria Agents",
        "drug_subclasses": {
          "60": {
            "drug_subclass": "Small Interfering Ribonucleic Acid Agents (siRNA)"
          }
        }
      },
      "70": {
        "drug_class": "Genitourinary Irrigants",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Anti-infective Genitourinary Irrigants"
          },
          "00": {
            "drug_subclass": "Genitourinary Irrigants"
          }
        }
      },
      "85": {
        "drug_class": "Prostatic Hypertrophy Agents",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "5-Alpha Reductase Inhibitors"
          },
          "20": {
            "drug_subclass": "Alpha 1-Adrenoceptor Antagonists"
          },
          "99": {
            "drug_subclass": "Prostatic Hypertrophy Agent Combinations"
          }
        }
      },
      "90": {
        "drug_class": "Vesicoureteral Reflux (VUR) Agents",
        "drug_subclasses": {
          "99": {
            "drug_subclass": "Vesicoureteral Reflux (VUR) Agent Combinations"
          }
        }
      }
    },
    "drug_group": "GENITOURINARY AGENTS - MISCELLANEOUS"
  },
  "57": {
    "drug_classes": {
      "10": {
        "drug_class": "Benzodiazepines",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Benzodiazepines"
          }
        }
      },
      "20": {
        "drug_class": "Antianxiety Agents - Misc.",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Antianxiety Agents - Misc."
          }
        }
      }
    },
    "drug_group": "ANTIANXIETY AGENTS"
  },
  "58": {
    "drug_classes": {
      "10": {
        "drug_class": "Monoamine Oxidase Inhibitors (MAOIs)",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Monoamine Oxidase Inhibitors (MAOIs)"
          }
        }
      },
      "11": {
        "drug_class": "N-Methyl-D-aspartic acid (NMDA) Receptor Antagonists",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "N-Methyl-D-aspartic acid (NMDA) Receptor Antagonists"
          }
        }
      },
      "12": {
        "drug_class": "Serotonin Modulators",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Serotonin Modulators"
          }
        }
      },
      "16": {
        "drug_class": "Selective Serotonin Reuptake Inhibitors (SSRIs)",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Selective Serotonin Reuptake Inhibitors (SSRIs)"
          }
        }
      },
      "18": {
        "drug_class": "Serotonin-Norepinephrine Reuptake Inhibitors (SNRIs)",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Serotonin-Norepinephrine Reuptake Inhibitors (SNRIs)"
          }
        }
      },
      "20": {
        "drug_class": "Tricyclic Agents",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Tricyclic Agents"
          }
        }
      },
      "30": {
        "drug_class": "Antidepressants - Misc.",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Antidepressants - Misc."
          }
        }
      },
      "99": {
        "drug_class": "Antidepressant Combinations",
        "drug_subclasses": {
          "85": {
            "drug_subclass": "SSRIs-Nutritional Supplement Combinations"
          }
        }
      },
      "03": {
        "drug_class": "Alpha-2 Receptor Antagonists (Tetracyclics)",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Alpha-2 Receptor Antagonists (Tetracyclics)"
          }
        }
      },
      "06": {
        "drug_class": "GABA Receptor Modulator - Neuroactive Steroid",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "GABA Receptor Modulator - Neuroactive Steroid"
          }
        }
      }
    },
    "drug_group": "ANTIDEPRESSANTS"
  },
  "59": {
    "drug_classes": {
      "10": {
        "drug_class": "Butyrophenones",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Butyrophenones"
          }
        }
      },
      "15": {
        "drug_class": "Dibenzapines",
        "drug_subclasses": {
          "20": {
            "drug_subclass": "Dibenzodiazepines"
          },
          "30": {
            "drug_subclass": "Dibenzothiazepines"
          },
          "40": {
            "drug_subclass": "Dibenzoxazepines"
          },
          "50": {
            "drug_subclass": "Dibenzo-oxepino Pyrroles"
          },
          "70": {
            "drug_subclass": "Thienbenzodiazepines"
          }
        }
      },
      "16": {
        "drug_class": "Dihydroindolones",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Dihydroindolones"
          }
        }
      },
      "20": {
        "drug_class": "Phenothiazines",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Phenothiazines"
          }
        }
      },
      "25": {
        "drug_class": "Quinolinone Derivatives",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Quinolinone Derivatives"
          }
        }
      },
      "30": {
        "drug_class": "Thioxanthenes",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Thioxanthenes"
          }
        }
      },
      "40": {
        "drug_class": "Antipsychotics - Misc.",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Antipsychotics - Misc."
          }
        }
      },
      "50": {
        "drug_class": "Antimanic Agents",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Antimanic Agents"
          }
        }
      },
      "07": {
        "drug_class": "Benzisoxazoles",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Benzisoxazoles"
          }
        }
      }
    },
    "drug_group": "ANTIPSYCHOTICS/ANTIMANIC AGENTS"
  },
  "60": {
    "drug_classes": {
      "10": {
        "drug_class": "Barbiturate Hypnotics",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Barbiturate Hypnotics"
          }
        }
      },
      "20": {
        "drug_class": "Non-Barbiturate Hypnotics",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Benzodiazepine Hypnotics"
          },
          "40": {
            "drug_subclass": "Non-Benzodiazepine - GABA-Receptor Modulators"
          },
          "60": {
            "drug_subclass": "Selective Alpha2-Adrenoreceptor Agonist Sedatives"
          },
          "00": {
            "drug_subclass": "Non-Barbiturate Hypnotics"
          }
        }
      },
      "25": {
        "drug_class": "Selective Melatonin Receptor Agonists",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Selective Melatonin Receptor Agonists"
          }
        }
      },
      "30": {
        "drug_class": "Antihistamine Hypnotics",
        "drug_subclasses": {
          "99": {
            "drug_subclass": "Antihistamine Hypnotic Combinations"
          },
          "00": {
            "drug_subclass": "Antihistamine Hypnotics"
          }
        }
      },
      "40": {
        "drug_class": "Hypnotics - Tricyclic Agents",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Hypnotics - Tricyclic Agents"
          }
        }
      },
      "50": {
        "drug_class": "Orexin Receptor Antagonists",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Orexin Receptor Antagonists"
          }
        }
      },
      "99": {
        "drug_class": "Hypnotic Combinations",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Hypnotic Combinations"
          }
        }
      }
    },
    "drug_group": "HYPNOTICS/SEDATIVES/SLEEP DISORDER AGENTS"
  },
  "61": {
    "drug_classes": {
      "10": {
        "drug_class": "Amphetamines",
        "drug_subclasses": {
          "99": {
            "drug_subclass": "Amphetamine Mixtures"
          },
          "00": {
            "drug_subclass": "Amphetamines"
          }
        }
      },
      "20": {
        "drug_class": "Anorexiants Non-Amphetamine",
        "drug_subclasses": {
          "99": {
            "drug_subclass": "Anorexiant Combinations"
          },
          "00": {
            "drug_subclass": "Anorexiants Non-Amphetamine"
          }
        }
      },
      "25": {
        "drug_class": "Anti-Obesity Agents",
        "drug_subclasses": {
          "20": {
            "drug_subclass": "Anti-Obesity - GLP-1 Receptor Agonists"
          },
          "35": {
            "drug_subclass": "Lipase Inhibitors"
          },
          "38": {
            "drug_subclass": "Melanocortin 4 (MC4) Receptor Agonists"
          },
          "65": {
            "drug_subclass": "Serotonin 2C Receptor Agonists"
          },
          "99": {
            "drug_subclass": "Anti-Obesity Agent Combination"
          }
        }
      },
      "30": {
        "drug_class": "Analeptics",
        "drug_subclasses": {
          "99": {
            "drug_subclass": "Analeptic Combinations"
          },
          "00": {
            "drug_subclass": "Analeptics"
          }
        }
      },
      "35": {
        "drug_class": "Attention-Deficit/Hyperactivity Disorder (ADHD) Agents",
        "drug_subclasses": {
          "30": {
            "drug_subclass": "ADHD Agent - Selective Alpha Adrenergic Agonists"
          },
          "40": {
            "drug_subclass": "ADHD Agent - Selective Norepinephrine Reuptake Inhibitor"
          }
        }
      },
      "37": {
        "drug_class": "Dopamine and Norepinephrine Reuptake Inhibitors (DNRIs)",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Dopamine and Norepinephrine Reuptake Inhibitors (DNRIs)"
          }
        }
      },
      "40": {
        "drug_class": "Stimulants - Misc.",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Stimulants - Misc."
          }
        }
      },
      "45": {
        "drug_class": "Histamine H3-Receptor Antagonist/Inverse Agonists",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Histamine H3-Receptor Antagonist/Inverse Agonists"
          }
        }
      }
    },
    "drug_group": "ADHD/ANTI-NARCOLEPSY/ANTI-OBESITY/ANOREXIANTS"
  },
  "62": {
    "drug_classes": {
      "10": {
        "drug_class": "Smoking Deterrents",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Smoking Deterrents"
          }
        }
      },
      "17": {
        "drug_class": "Hypoactive Sexual Desire Disorder (HSDD) Agents",
        "drug_subclasses": {
          "35": {
            "drug_subclass": "Melanocortin Receptor Agonists"
          },
          "50": {
            "drug_subclass": "Serotonin 1A Recept Agonist/Serotonin 2A Recept Antag"
          }
        }
      },
      "20": {
        "drug_class": "Premenstrual Dysphoric Disorder (PMDD) Agents",
        "drug_subclasses": {
          "60": {
            "drug_subclass": "Premenstrual Dysphoric Disorder (PMDD) Agents - SSRIs"
          }
        }
      },
      "22": {
        "drug_class": "Vasomotor Symptom Agents",
        "drug_subclasses": {
          "60": {
            "drug_subclass": "Vasomotor Symptom Agents - SSRIs"
          }
        }
      },
      "38": {
        "drug_class": "Movement Disorder Drug Therapy",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Movement Disorder Drug Therapy"
          }
        }
      },
      "40": {
        "drug_class": "Multiple Sclerosis Agents",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Multiple Sclerosis Agents - Antimetabolites"
          },
          "30": {
            "drug_subclass": "Multiple Sclerosis Agents - Interferons"
          },
          "40": {
            "drug_subclass": "MS Agents - Pyrimidine Synthesis Inhibitors"
          },
          "50": {
            "drug_subclass": "Multiple Sclerosis Agents - Monoclonal Antibodies"
          },
          "55": {
            "drug_subclass": "Multiple Sclerosis Agents - Nrf2 Pathway Activators"
          },
          "60": {
            "drug_subclass": "Multiple Sclerosis Agents - Potassium Channel Blockers"
          },
          "70": {
            "drug_subclass": "Sphingosine 1-Phosphate (S1P) Receptor Modulators"
          },
          "00": {
            "drug_subclass": "Multiple Sclerosis Agents"
          }
        }
      },
      "45": {
        "drug_class": "Anti-Cataplectic Agents",
        "drug_subclasses": {
          "99": {
            "drug_subclass": "Anti-Cataplectic Combinations"
          },
          "00": {
            "drug_subclass": "Anti-Cataplectic Agents"
          }
        }
      },
      "50": {
        "drug_class": "Fibromyalgia Agents",
        "drug_subclasses": {
          "40": {
            "drug_subclass": "Fibromyalgia Agent - SNRIs"
          }
        }
      },
      "54": {
        "drug_class": "Postherpetic Neuralgia (PHN)/Neuropathic Pain Agents",
        "drug_subclasses": {
          "99": {
            "drug_subclass": "Postherpetic Neuralgia(PHN)/Neuropathic Pain Comb Agents"
          },
          "00": {
            "drug_subclass": "Postherpetic Neuralgia (PHN)/Neuropathic Pain Agents"
          }
        }
      },
      "56": {
        "drug_class": "Restless Leg Syndrome (RLS) Agents",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Restless Leg Syndrome (RLS) Agents"
          }
        }
      },
      "60": {
        "drug_class": "Pseudobulbar Affect (PBA) Agents",
        "drug_subclasses": {
          "99": {
            "drug_subclass": "Pseudobulbar Affect Agent Combinations"
          }
        }
      },
      "70": {
        "drug_class": "Transthyretin Amyloidosis Agents",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Antisense Oligonucleotide (ASO) Inhibitor Agents"
          },
          "60": {
            "drug_subclass": "Small Interfering Ribonucleic Acid (siRNA) Agents"
          }
        }
      },
      "80": {
        "drug_class": "Agents for Chemical Dependency",
        "drug_subclasses": {
          "20": {
            "drug_subclass": "Alcohol Deterrents"
          },
          "50": {
            "drug_subclass": "Agents for Opioid Withdrawal"
          }
        }
      },
      "99": {
        "drug_class": "Combination Psychotherapeutics",
        "drug_subclasses": {
          "20": {
            "drug_subclass": "Benzodiazepines & Tricyclic Agents"
          },
          "40": {
            "drug_subclass": "Phenothiazines & Tricyclic Agents"
          },
          "45": {
            "drug_subclass": "SNRIs & Anesthetics/Analgesics"
          },
          "50": {
            "drug_subclass": "Thienbenzodiazepines & SSRIs"
          }
        }
      },
      "00": {
        "drug_class": "Psychotherapeutic and Neurological Agents - Misc.",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Psychotherapeutic and Neurological Agents - Misc."
          }
        }
      },
      "05": {
        "drug_class": "Antidementia Agents",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Cholinomimetics - ACHE Inhibitors"
          },
          "35": {
            "drug_subclass": "N-Methyl-D-Aspartate (NMDA) Receptor Antagonists"
          },
          "40": {
            "drug_subclass": "Nootropics"
          },
          "99": {
            "drug_subclass": "Antidementia Agent Combinations"
          }
        }
      }
    },
    "drug_group": "PSYCHOTHERAPEUTIC AND NEUROLOGICAL AGENTS - MISC."
  },
  "64": {
    "drug_classes": {
      "10": {
        "drug_class": "Salicylates",
        "drug_subclasses": {
          "99": {
            "drug_subclass": "Salicylate Combinations"
          },
          "00": {
            "drug_subclass": "Salicylates"
          }
        }
      },
      "15": {
        "drug_class": "Analgesics-Peptide Channel Blockers",
        "drug_subclasses": {
          "40": {
            "drug_subclass": "Selective N-Type Neuronal Calcium Channel Blockers"
          }
        }
      },
      "20": {
        "drug_class": "Analgesics Other",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Analgesics Other"
          }
        }
      },
      "99": {
        "drug_class": "Analgesic Combinations",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Analgesics-Sedatives"
          },
          "00": {
            "drug_subclass": "Analgesic Combinations"
          }
        }
      }
    },
    "drug_group": "ANALGESICS - NonNarcotic"
  },
  "65": {
    "drug_classes": {
      "10": {
        "drug_class": "Opioid Agonists",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Opioid Agonists"
          }
        }
      },
      "20": {
        "drug_class": "Opioid Partial Agonists",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Opioid Partial Agonists"
          }
        }
      },
      "99": {
        "drug_class": "Opioid Combinations",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Codeine Combinations"
          },
          "13": {
            "drug_subclass": "Dihydrocodeine Combinations"
          },
          "15": {
            "drug_subclass": "Fentanyl Combinations"
          },
          "17": {
            "drug_subclass": "Hydrocodone Combinations"
          },
          "18": {
            "drug_subclass": "Hydromorphone Combinations"
          },
          "30": {
            "drug_subclass": "Meperidine Combinations"
          },
          "50": {
            "drug_subclass": "Tramadol Combinations"
          },
          "00": {
            "drug_subclass": "Opioid Combinations"
          }
        }
      }
    },
    "drug_group": "ANALGESICS - OPIOID"
  },
  "66": {
    "drug_classes": {
      "10": {
        "drug_class": "Nonsteroidal Anti-inflammatory Agents (NSAIDs)",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Phenylbutazones"
          },
          "99": {
            "drug_subclass": "Nonsteroidal Anti-inflammatory Agent Combinations"
          },
          "00": {
            "drug_subclass": "Nonsteroidal Anti-inflammatory Agents (NSAIDs)"
          },
          "05": {
            "drug_subclass": "Cyclooxygenase 2 (COX-2) Inhibitors"
          }
        }
      },
      "20": {
        "drug_class": "Gold Compounds",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Gold Compounds"
          }
        }
      },
      "25": {
        "drug_class": "Antirheumatic Antimetabolites",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Antirheumatic Antimetabolites"
          }
        }
      },
      "26": {
        "drug_class": "Interleukin-1 Receptor Antagonist (IL-1Ra)",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Interleukin-1 Receptor Antagonist (IL-1Ra)"
          }
        }
      },
      "27": {
        "drug_class": "Anti-TNF-alpha - Monoclonal Antibodies",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Anti-TNF-alpha - Monoclonal Antibodies"
          }
        }
      },
      "28": {
        "drug_class": "Pyrimidine Synthesis Inhibitors",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Pyrimidine Synthesis Inhibitors"
          }
        }
      },
      "29": {
        "drug_class": "Soluble Tumor Necrosis Factor Receptor Agents",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Soluble Tumor Necrosis Factor Receptor Agents"
          }
        }
      },
      "40": {
        "drug_class": "Selective Costimulation Modulators",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Selective Costimulation Modulators"
          }
        }
      },
      "45": {
        "drug_class": "Interleukin-1 Blockers",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Interleukin-1 Blockers"
          }
        }
      },
      "46": {
        "drug_class": "Interleukin-1beta Blockers",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Interleukin-1beta Blockers"
          }
        }
      },
      "50": {
        "drug_class": "Interleukin-6 Receptor Inhibitors",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Interleukin-6 Receptor Inhibitors"
          }
        }
      },
      "60": {
        "drug_class": "Antirheumatic - Enzyme Inhibitors",
        "drug_subclasses": {
          "30": {
            "drug_subclass": "Antirheumatic - Janus Kinase (JAK) Inhibitors"
          }
        }
      },
      "70": {
        "drug_class": "Phosphodiesterase 4 (PDE4) Inhibitors",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Phosphodiesterase 4 (PDE4) Inhibitors"
          }
        }
      },
      "99": {
        "drug_class": "Analgesics - Anti-inflammatory Combinations",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "NSAID Combinations"
          },
          "80": {
            "drug_subclass": "NSAID-Dietary Management Combinations"
          },
          "85": {
            "drug_subclass": "NSAID-Vitamins and/or Minerals Combinations"
          }
        }
      }
    },
    "drug_group": "ANALGESICS - ANTI-INFLAMMATORY"
  },
  "67": {
    "drug_classes": {
      "40": {
        "drug_class": "Serotonin Agonists",
        "drug_subclasses": {
          "60": {
            "drug_subclass": "Selective Serotonin Agonists 5-HT(1)"
          },
          "65": {
            "drug_subclass": "Selective Serotonin Agonists 5-HT(1F)"
          }
        }
      },
      "60": {
        "drug_class": "Migraine Products - NSAIDs",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Migraine Products - NSAIDs"
          }
        }
      },
      "70": {
        "drug_class": "Calcitonin Gene-Related Peptide (CGRP) Receptor Antag",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Calcitonin Gene-Related Peptide Receptor Antag (CGRP)"
          },
          "20": {
            "drug_subclass": "CGRP Receptor Antagonists - Monocolonal Antibodies"
          }
        }
      },
      "99": {
        "drug_class": "Migraine Combinations",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Ergot Combinations"
          },
          "20": {
            "drug_subclass": "Selective Serotonin Agonist-NSAID Combinations"
          },
          "00": {
            "drug_subclass": "Migraine Combinations"
          }
        }
      },
      "00": {
        "drug_class": "Migraine Products",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Migraine Products"
          }
        }
      }
    },
    "drug_group": "MIGRAINE PRODUCTS"
  },
  "68": {
    "drug_classes": {
      "10": {
        "drug_class": "Uricosurics",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Uricosurics"
          }
        }
      },
      "99": {
        "drug_class": "Gout Agent Combinations",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Gout Agent Combinations"
          }
        }
      },
      "00": {
        "drug_class": "Gout Agents",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Gout Agents"
          }
        }
      }
    },
    "drug_group": "GOUT AGENTS"
  },
  "69": {
    "drug_classes": {
      "10": {
        "drug_class": "Local Anesthetics - Amides",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Local Anesthetics - Amides"
          }
        }
      },
      "20": {
        "drug_class": "Local Anesthetics - Esters",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Local Anesthetics - Esters"
          }
        }
      },
      "99": {
        "drug_class": "Local Anesthetic Combinations",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Local Anesthetic & Sympathomimetic"
          },
          "00": {
            "drug_subclass": "Local Anesthetic Combinations"
          }
        }
      }
    },
    "drug_group": "LOCAL ANESTHETICS-Parenteral"
  },
  "70": {
    "drug_classes": {
      "10": {
        "drug_class": "Barbiturate Anesthetics",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Barbiturate Anesthetics"
          }
        }
      },
      "20": {
        "drug_class": "Volatile Anesthetics",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Volatile Anesthetics"
          }
        }
      },
      "40": {
        "drug_class": "Anesthetics - Misc.",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Anesthetics - Misc."
          }
        }
      }
    },
    "drug_group": "GENERAL ANESTHETICS"
  },
  "72": {
    "drug_classes": {
      "10": {
        "drug_class": "Anticonvulsants - Benzodiazepines",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Anticonvulsants - Benzodiazepines"
          }
        }
      },
      "12": {
        "drug_class": "Carbamates",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Carbamates"
          }
        }
      },
      "17": {
        "drug_class": "GABA Modulators",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "GABA Modulators"
          }
        }
      },
      "20": {
        "drug_class": "Hydantoins",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Hydantoins"
          }
        }
      },
      "40": {
        "drug_class": "Succinimides",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Succinimides"
          }
        }
      },
      "50": {
        "drug_class": "Valproic Acid",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Valproic Acid"
          }
        }
      },
      "55": {
        "drug_class": "AMPA Glutamate Receptor Antagonists",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "AMPA Glutamate Receptor Antagonists"
          }
        }
      },
      "60": {
        "drug_class": "Anticonvulsants - Misc.",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Anticonvulsants - Misc."
          }
        }
      }
    },
    "drug_group": "ANTICONVULSANTS"
  },
  "73": {
    "drug_classes": {
      "10": {
        "drug_class": "Antiparkinson Anticholinergics",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Antiparkinson Anticholinergics"
          }
        }
      },
      "15": {
        "drug_class": "Antiparkinson COMT Inhibitors",
        "drug_subclasses": {
          "20": {
            "drug_subclass": "Central/Peripheral COMT Inhibitors"
          },
          "30": {
            "drug_subclass": "Peripheral COMT Inhibitors"
          }
        }
      },
      "20": {
        "drug_class": "Antiparkinson Dopaminergics",
        "drug_subclasses": {
          "30": {
            "drug_subclass": "Nonergoline Dopamine Receptor Agonists"
          },
          "99": {
            "drug_subclass": "Levodopa Combinations"
          },
          "00": {
            "drug_subclass": "Antiparkinson Dopaminergics"
          }
        }
      },
      "30": {
        "drug_class": "Antiparkinson Monoamine Oxidase Inhibitors",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Antiparkinson Monoamine Oxidase Inhibitors"
          }
        }
      },
      "40": {
        "drug_class": "Antiparkinson Adjunctive Therapy",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Adenosine Receptor Antagonist"
          },
          "30": {
            "drug_subclass": "Decarboxylase Inhibitors"
          }
        }
      }
    },
    "drug_group": "ANTIPARKINSON AND RELATED THERAPY AGENTS"
  },
  "74": {
    "drug_classes": {
      "10": {
        "drug_class": "Depolarizing Muscle Relaxants",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Depolarizing Muscle Relaxants"
          }
        }
      },
      "20": {
        "drug_class": "Nondepolarizing Muscle Relaxants",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Nondepolarizing Muscle Relaxants"
          }
        }
      },
      "40": {
        "drug_class": "Neuromuscular Blocking Agent - Neurotoxins",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Neuromuscular Blocking Agent - Neurotoxins"
          }
        }
      },
      "50": {
        "drug_class": "ALS Agents",
        "drug_subclasses": {
          "30": {
            "drug_subclass": "Benzathiazoles"
          },
          "90": {
            "drug_subclass": "ALS Agents - Miscellaneous"
          }
        }
      },
      "60": {
        "drug_class": "Muscular Dystrophy Agents",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Muscular Dystrophy Agents"
          }
        }
      },
      "70": {
        "drug_class": "Spinal Muscular Atrophy Agents (SMA)",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Spinal Muscular Atrophy-Antisense Oligonucleotides"
          },
          "40": {
            "drug_subclass": "Spinal Muscular Atrophy-Gene Therapy Agents"
          },
          "65": {
            "drug_subclass": "Spinal Muscular Atrophy-SMN2 Splicing Modifiers"
          }
        }
      }
    },
    "drug_group": "NEUROMUSCULAR AGENTS"
  },
  "75": {
    "drug_classes": {
      "10": {
        "drug_class": "Central Muscle Relaxants",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Central Muscle Relaxants"
          }
        }
      },
      "20": {
        "drug_class": "Direct Muscle Relaxants",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Direct Muscle Relaxants"
          }
        }
      },
      "80": {
        "drug_class": "Viscosupplements",
        "drug_subclasses": {
          "99": {
            "drug_subclass": "Viscosupplement Combinations"
          },
          "00": {
            "drug_subclass": "Viscosupplements"
          }
        }
      },
      "84": {
        "drug_class": "Articular Cartilage Repair Therapy",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Articular Cartilage Repair Therapy"
          }
        }
      },
      "99": {
        "drug_class": "Muscle Relaxant Combinations",
        "drug_subclasses": {
          "75": {
            "drug_subclass": "Muscle Relaxant-Liniments Combinations"
          },
          "00": {
            "drug_subclass": "Muscle Relaxant Combinations"
          }
        }
      }
    },
    "drug_group": "MUSCULOSKELETAL THERAPY AGENTS"
  },
  "76": {
    "drug_classes": {
      "00": {
        "drug_class": "Antimyasthenic/Cholinergic Agents",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Antimyasthenic/Cholinergic Agents"
          }
        }
      }
    },
    "drug_group": "ANTIMYASTHENIC/CHOLINERGIC AGENTS"
  },
  "77": {
    "drug_classes": {
      "10": {
        "drug_class": "Water Soluble Vitamins",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Vitamin B-1"
          },
          "20": {
            "drug_subclass": "Vitamin B-2"
          },
          "30": {
            "drug_subclass": "Vitamin B-3"
          },
          "40": {
            "drug_subclass": "Vitamin B-5"
          },
          "50": {
            "drug_subclass": "Vitamin B-6"
          },
          "60": {
            "drug_subclass": "Biotin"
          },
          "70": {
            "drug_subclass": "PABA"
          },
          "80": {
            "drug_subclass": "Vitamin C"
          }
        }
      },
      "20": {
        "drug_class": "Oil Soluble Vitamins",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Vitamin A"
          },
          "20": {
            "drug_subclass": "Vitamin D"
          },
          "30": {
            "drug_subclass": "Vitamin E"
          },
          "40": {
            "drug_subclass": "Vitamin K"
          }
        }
      },
      "30": {
        "drug_class": "Misc. Nutritional Factors",
        "drug_subclasses": {
          "30": {
            "drug_subclass": "Bioflavonoids"
          },
          "99": {
            "drug_subclass": "Bioflavonoid Combinations"
          }
        }
      }
    },
    "drug_group": "VITAMINS"
  },
  "78": {
    "drug_classes": {
      "10": {
        "drug_class": "Vitamin Mixtures",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Vitamins A & D"
          },
          "14": {
            "drug_subclass": "Vitamins A & D w/ K"
          },
          "15": {
            "drug_subclass": "Vitamins A & D w/ C"
          },
          "16": {
            "drug_subclass": "Vitamins A & D w/ Minerals"
          },
          "20": {
            "drug_subclass": "Vitamins ACE & Zn"
          },
          "37": {
            "drug_subclass": "Vitamins A & C"
          },
          "40": {
            "drug_subclass": "Vitamins C & E"
          },
          "44": {
            "drug_subclass": "Niacin w/ Inositol"
          },
          "49": {
            "drug_subclass": "Niacinamide w/ Zinc-Copper & Folic Acid"
          },
          "60": {
            "drug_subclass": "Vitamins B1,B6 & B12"
          },
          "68": {
            "drug_subclass": "Vitamin C & Vitamin D"
          },
          "69": {
            "drug_subclass": "Vitamin C, Vitamin D & Zinc"
          },
          "75": {
            "drug_subclass": "Vitamin D & K"
          },
          "00": {
            "drug_subclass": "Vitamin Mixtures"
          }
        }
      },
      "11": {
        "drug_class": "B-Complex Vitamins",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Brewers Yeast"
          },
          "00": {
            "drug_subclass": "B-Complex Vitamins"
          }
        }
      },
      "12": {
        "drug_class": "B-Complex w/ C",
        "drug_subclasses": {
          "20": {
            "drug_subclass": "B-Complex w/ C & E"
          },
          "25": {
            "drug_subclass": "B-Complex w/ C & E + Zn"
          },
          "30": {
            "drug_subclass": "B-Complex w/ C & Calcium"
          },
          "00": {
            "drug_subclass": "B-Complex w/ C"
          }
        }
      },
      "13": {
        "drug_class": "B-Complex w/ Folic Acid",
        "drug_subclasses": {
          "20": {
            "drug_subclass": "B-Complex w/Biotin & Folic Acid"
          },
          "30": {
            "drug_subclass": "B-Complex w/ C & Folic Acid"
          },
          "34": {
            "drug_subclass": "B-Complex w/ E & Folic Acid"
          },
          "53": {
            "drug_subclass": "B-Complex w/ C-Biotin-D & Folic Acid"
          },
          "55": {
            "drug_subclass": "B-Complex w/ C-Biotin-Fe & Folic Acid"
          },
          "60": {
            "drug_subclass": "B-Complex w/ C-Zn & Folic Acid"
          },
          "65": {
            "drug_subclass": "B-Complex w/ Lysine-Zn & Folic Acid"
          },
          "67": {
            "drug_subclass": "B-Complex w/ Lysine-Min-Fe & Folic Acid"
          },
          "69": {
            "drug_subclass": "B-Complex w/ C-Biotin-E & Folic Acid"
          },
          "70": {
            "drug_subclass": "B-Complex w/ C-Biotin-E-Minerals & Folic Acid"
          },
          "71": {
            "drug_subclass": "B-Complex w/ C-Biotin-E-Folic Acid & Iron"
          },
          "72": {
            "drug_subclass": "B-Complex w/ C-Biotin-D-Zinc & Folic Acid"
          },
          "75": {
            "drug_subclass": "B-Complex w/ C-Biotin-Minerals & Folic Acid"
          },
          "00": {
            "drug_subclass": "B-Complex w/ Folic Acid"
          }
        }
      },
      "14": {
        "drug_class": "B-Complex w/ Iron",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "B-Complex w/ Iron"
          }
        }
      },
      "15": {
        "drug_class": "B-Complex w/ Minerals",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "B-Complex w/ Minerals"
          }
        }
      },
      "16": {
        "drug_class": "Bioflavonoid Products",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Bioflavonoid Products"
          }
        }
      },
      "18": {
        "drug_class": "Biotin w/ Vitamins C & E",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Biotin w/ Vitamins C & E"
          }
        }
      },
      "20": {
        "drug_class": "Multivitamins",
        "drug_subclasses": {
          "40": {
            "drug_subclass": "Multiple Vitamins with Folic Acid"
          },
          "00": {
            "drug_subclass": "Multivitamins"
          }
        }
      },
      "21": {
        "drug_class": "Multiple Vitamins w/ Iron",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Multiple Vitamins w/ Iron"
          }
        }
      },
      "31": {
        "drug_class": "Multiple Vitamins w/ Minerals",
        "drug_subclasses": {
          "30": {
            "drug_subclass": "Multiple Vitamins w/ Minerals & Folic Acid"
          },
          "00": {
            "drug_subclass": "Multiple Vitamins w/ Minerals"
          }
        }
      },
      "32": {
        "drug_class": "Multiple Vitamins & Fluoride-Folic Acid",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Multiple Vitamins & Fluoride-Folic Acid"
          }
        }
      },
      "35": {
        "drug_class": "Multiple Vitamins w/ Calcium",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Multiple Vitamins w/ Calcium"
          }
        }
      },
      "36": {
        "drug_class": "Multiple Vitamins w/ Minerals & Calcium-Folic Acid",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Multiple Vitamins w/ Minerals & Calcium-Folic Acid"
          }
        }
      },
      "38": {
        "drug_class": "Multiple Vitamins w/ Minerals & Fluoride-Iron-Folic Acid",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Multiple Vitamins w/ Minerals & Fluoride-Iron-Folic Acid"
          }
        }
      },
      "40": {
        "drug_class": "Pediatric Vitamins",
        "drug_subclasses": {
          "15": {
            "drug_subclass": "Pediatric Vitamins A & D w/ C"
          },
          "00": {
            "drug_subclass": "Pediatric Vitamins"
          }
        }
      },
      "41": {
        "drug_class": "Pediatric Multiple Vitamins",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Pediatric Multiple Vitamins w/ C"
          },
          "20": {
            "drug_subclass": "Pediatric Multiple Vitamins w/ C & FA"
          },
          "30": {
            "drug_subclass": "Pediatric Multiple Vitamins w/ Extra C & FA"
          },
          "00": {
            "drug_subclass": "Pediatric Multiple Vitamins"
          }
        }
      },
      "42": {
        "drug_class": "Ped Multiple Vitamins w/ Minerals",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Ped Multiple Vitamins w/ Minerals & C"
          },
          "00": {
            "drug_subclass": "Ped Multiple Vitamins w/ Minerals"
          }
        }
      },
      "43": {
        "drug_class": "Ped MV w/ Iron",
        "drug_subclasses": {
          "15": {
            "drug_subclass": "Ped Vitamins ACD w/ Iron"
          },
          "00": {
            "drug_subclass": "Ped MV w/ Iron"
          }
        }
      },
      "44": {
        "drug_class": "Ped MV w/ Fluoride",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Ped MV w/ Fluoride"
          },
          "05": {
            "drug_subclass": "Ped Vitamins ACD w/ Fluoride"
          },
          "07": {
            "drug_subclass": "Ped Vitamins ACD & FA w/ Fluoride"
          }
        }
      },
      "45": {
        "drug_class": "Ped Multi Vitamins w/Fl & FE",
        "drug_subclasses": {
          "20": {
            "drug_subclass": "Ped Vitamins ACD Fluoride & Iron"
          },
          "00": {
            "drug_subclass": "Ped Multi Vitamins w/Fl & FE"
          }
        }
      },
      "46": {
        "drug_class": "Pediatric Multiple Vitamins & Minerals w/ Fluoride",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Pediatric Multiple Vitamins w/Fluoride-Iron-Zinc"
          },
          "00": {
            "drug_subclass": "Pediatric Multiple Vitamins & Minerals w/ Fluoride"
          }
        }
      },
      "50": {
        "drug_class": "Specialty Vitamins Products",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Specialty Vitamins Products"
          }
        }
      },
      "51": {
        "drug_class": "Prenatal Vitamins",
        "drug_subclasses": {
          "20": {
            "drug_subclass": "Prenatal MV & Min w/FE-FA"
          },
          "30": {
            "drug_subclass": "Prenatal MV & Minerals w/ FA without Iron"
          },
          "35": {
            "drug_subclass": "Prenatal MV & Minerals w/ FA-Omega Fatty Acids w/o Iron"
          },
          "50": {
            "drug_subclass": "Prenatal MV & Min w/FE-FA-CA-Omega 3 Fish Oil"
          },
          "60": {
            "drug_subclass": "Prenatal MV & Min w/FE-FA-DHA"
          },
          "70": {
            "drug_subclass": "Prenatal MV & Min w/FE-FA & Coenzyme Q10"
          },
          "00": {
            "drug_subclass": "Prenatal Vitamins"
          },
          "05": {
            "drug_subclass": "Prenatal MV & Minerals w/FA without Iron"
          }
        }
      },
      "52": {
        "drug_class": "Vitamins w/ Lipotropics",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Vitamins w/ Lipotropics"
          }
        }
      },
      "61": {
        "drug_class": "Iron w/ Vitamins",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Iron w/ Vitamins"
          }
        }
      }
    },
    "drug_group": "MULTIVITAMINS"
  },
  "79": {
    "drug_classes": {
      "10": {
        "drug_class": "Calcium",
        "drug_subclasses": {
          "99": {
            "drug_subclass": "Calcium Combinations"
          },
          "00": {
            "drug_subclass": "Calcium"
          }
        }
      },
      "30": {
        "drug_class": "Fluoride",
        "drug_subclasses": {
          "99": {
            "drug_subclass": "Fluoride Combinations"
          },
          "00": {
            "drug_subclass": "Fluoride"
          }
        }
      },
      "35": {
        "drug_class": "Iodine Products",
        "drug_subclasses": {
          "99": {
            "drug_subclass": "Iodine Combinations"
          },
          "00": {
            "drug_subclass": "Iodine Products"
          }
        }
      },
      "40": {
        "drug_class": "Magnesium",
        "drug_subclasses": {
          "99": {
            "drug_subclass": "Magnesium Combinations"
          },
          "00": {
            "drug_subclass": "Magnesium"
          }
        }
      },
      "50": {
        "drug_class": "Manganese",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Manganese"
          }
        }
      },
      "60": {
        "drug_class": "Phosphate",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Phosphate"
          }
        }
      },
      "70": {
        "drug_class": "Potassium",
        "drug_subclasses": {
          "99": {
            "drug_subclass": "Potassium Combinations"
          },
          "00": {
            "drug_subclass": "Potassium"
          }
        }
      },
      "75": {
        "drug_class": "Sodium",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Sodium"
          }
        }
      },
      "80": {
        "drug_class": "Zinc",
        "drug_subclasses": {
          "99": {
            "drug_subclass": "Zinc Combinations"
          },
          "00": {
            "drug_subclass": "Zinc"
          }
        }
      },
      "85": {
        "drug_class": "Mineral Combinations",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Mineral Combinations"
          }
        }
      },
      "90": {
        "drug_class": "Trace Minerals",
        "drug_subclasses": {
          "99": {
            "drug_subclass": "Trace Mineral Combinations"
          },
          "00": {
            "drug_subclass": "Trace Minerals"
          }
        }
      },
      "99": {
        "drug_class": "Electrolyte Mixtures",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Electrolytes Oral"
          },
          "20": {
            "drug_subclass": "Electrolytes Parenteral"
          },
          "30": {
            "drug_subclass": "Electrolytes & Dextrose"
          }
        }
      },
      "05": {
        "drug_class": "Bicarbonates",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Bicarbonates"
          }
        }
      }
    },
    "drug_group": "MINERALS & ELECTROLYTES"
  },
  "80": {
    "drug_classes": {
      "10": {
        "drug_class": "Carbohydrates",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Carbohydrates"
          }
        }
      },
      "20": {
        "drug_class": "Lipids",
        "drug_subclasses": {
          "30": {
            "drug_subclass": "Fatty Acids"
          },
          "00": {
            "drug_subclass": "Lipids"
          }
        }
      },
      "30": {
        "drug_class": "Proteins",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Protein Products"
          },
          "20": {
            "drug_subclass": "Amino Acid Mixtures"
          },
          "30": {
            "drug_subclass": "Amino Acids-Single"
          },
          "99": {
            "drug_subclass": "Protein Combinations"
          }
        }
      },
      "40": {
        "drug_class": "Lipotropics",
        "drug_subclasses": {
          "99": {
            "drug_subclass": "Lipotropic Combinations"
          },
          "00": {
            "drug_subclass": "Lipotropics"
          }
        }
      },
      "45": {
        "drug_class": "Protein-Carbohydrate-Lipid Combinations",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Protein-Carbohydrate-Lipid with Electrolyte Combinations"
          }
        }
      },
      "50": {
        "drug_class": "Misc. Nutritional Substances",
        "drug_subclasses": {
          "99": {
            "drug_subclass": "Misc. Nutritional Substances Combinations"
          },
          "00": {
            "drug_subclass": "Misc. Nutritional Substances"
          }
        }
      }
    },
    "drug_group": "NUTRIENTS"
  },
  "81": {
    "drug_classes": {
      "10": {
        "drug_class": "Infant Foods",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Infant Foods"
          }
        }
      },
      "20": {
        "drug_class": "Nutritional Supplements",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Nutritional Supplements - Diet Aids"
          },
          "00": {
            "drug_subclass": "Nutritional Supplements"
          }
        }
      },
      "25": {
        "drug_class": "Dietary Management Products",
        "drug_subclasses": {
          "99": {
            "drug_subclass": "Dietary Management Product Combinations"
          },
          "00": {
            "drug_subclass": "Dietary Management Products"
          }
        }
      },
      "40": {
        "drug_class": "Nutritional Substitutes",
        "drug_subclasses": {
          "20": {
            "drug_subclass": "Sweeteners"
          }
        }
      }
    },
    "drug_group": "DIETARY PRODUCTS/DIETARY MANAGEMENT PRODUCTS"
  },
  "82": {
    "drug_classes": {
      "10": {
        "drug_class": "Cobalamins",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Liver Preparations"
          },
          "00": {
            "drug_subclass": "Cobalamins"
          }
        }
      },
      "20": {
        "drug_class": "Folic Acid/Folates",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Folic Acid/Folates"
          }
        }
      },
      "30": {
        "drug_class": "Iron",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Iron"
          }
        }
      },
      "40": {
        "drug_class": "Hematopoietic Growth Factors",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Erythropoiesis-Stimulating Agents (ESAs)"
          },
          "15": {
            "drug_subclass": "Granulocyte Colony-Stimulating Factors (G-CSF)"
          },
          "20": {
            "drug_subclass": "Granulocyte/Macrophage Colony-Stimulating Factor(GM-CSF)"
          },
          "50": {
            "drug_subclass": "Thrombopoietin (TPO) Receptor Agonists"
          },
          "05": {
            "drug_subclass": "Erythroid Maturation Agents"
          }
        }
      },
      "50": {
        "drug_class": "Stem Cell Mobilizers",
        "drug_subclasses": {
          "20": {
            "drug_subclass": "CXCR4 Receptor Antagonist"
          }
        }
      },
      "70": {
        "drug_class": "Agents for Gaucher Disease",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Agents for Gaucher Disease"
          }
        }
      },
      "80": {
        "drug_class": "Agents for Sickle Cell Disease",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Amino Acids"
          },
          "30": {
            "drug_subclass": "Cytotoxic Agents"
          },
          "50": {
            "drug_subclass": "Hemoglobin S (HbS) Polymerization Inhibitors"
          },
          "70": {
            "drug_subclass": "Selectin Blockers"
          }
        }
      },
      "99": {
        "drug_class": "Hematopoietic Mixtures",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Cobalamin Combinations"
          },
          "15": {
            "drug_subclass": "Folic Acid/Folate Combinations"
          },
          "20": {
            "drug_subclass": "Iron Combinations"
          },
          "40": {
            "drug_subclass": "Iron w/ Folic Acid"
          },
          "50": {
            "drug_subclass": "Iron-B12-Folate"
          }
        }
      }
    },
    "drug_group": "HEMATOPOIETIC AGENTS"
  },
  "83": {
    "drug_classes": {
      "10": {
        "drug_class": "Heparins And Heparinoid-Like Agents",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Low Molecular Weight Heparins"
          },
          "30": {
            "drug_subclass": "Synthetic Heparinoid-Like Agents"
          },
          "00": {
            "drug_subclass": "Heparins And Heparinoid-Like Agents"
          }
        }
      },
      "20": {
        "drug_class": "Coumarin Anticoagulants",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Coumarin Anticoagulants"
          }
        }
      },
      "33": {
        "drug_class": "Thrombin Inhibitors",
        "drug_subclasses": {
          "40": {
            "drug_subclass": "Thrombin Inhibitors - Hirudin Type"
          },
          "70": {
            "drug_subclass": "Thrombin Inhibitors - Selective Direct & Reversible"
          }
        }
      },
      "35": {
        "drug_class": "Anticoagulants - Misc.",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Anticoagulants - Misc."
          }
        }
      },
      "37": {
        "drug_class": "Direct Factor Xa Inhibitors",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Direct Factor Xa Inhibitors"
          }
        }
      },
      "40": {
        "drug_class": "In Vitro/Lock Anticoagulants",
        "drug_subclasses": {
          "99": {
            "drug_subclass": "In Vitro/Lock Anticoagulant Combinations"
          },
          "00": {
            "drug_subclass": "In Vitro/Lock Anticoagulants"
          }
        }
      }
    },
    "drug_group": "ANTICOAGULANTS"
  },
  "84": {
    "drug_classes": {
      "10": {
        "drug_class": "Hemostatics - Systemic",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Hemostatics - Systemic"
          }
        }
      },
      "20": {
        "drug_class": "Hemostatics - Topical",
        "drug_subclasses": {
          "99": {
            "drug_subclass": "Hemostatic Combinations - Topical"
          },
          "00": {
            "drug_subclass": "Hemostatics - Topical"
          }
        }
      }
    },
    "drug_group": "HEMOSTATICS"
  },
  "85": {
    "drug_classes": {
      "10": {
        "drug_class": "Antihemophilic Products",
        "drug_subclasses": {
          "50": {
            "drug_subclass": "Antihemophilic Products - Monoclonal Antibodies"
          },
          "00": {
            "drug_subclass": "Antihemophilic Products"
          }
        }
      },
      "15": {
        "drug_class": "Platelet Aggregation Inhibitors",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Anti-von Willebrand Factor Agents"
          },
          "30": {
            "drug_subclass": "Glycoprotein IIb/IIIa Receptor Inhibitors"
          },
          "55": {
            "drug_subclass": "Phosphodiesterase III Inhibitors"
          },
          "57": {
            "drug_subclass": "Protease-Activated Receptor-1 (PAR-1) Antagonists"
          },
          "60": {
            "drug_subclass": "Quinazoline Agents"
          },
          "80": {
            "drug_subclass": "Thienopyridine Derivatives"
          },
          "84": {
            "drug_subclass": "Direct-Acting P2Y12 Inhibitors"
          },
          "99": {
            "drug_subclass": "Platelet Aggregation Inhibitor Combinations"
          },
          "00": {
            "drug_subclass": "Platelet Aggregation Inhibitors"
          }
        }
      },
      "20": {
        "drug_class": "Hematorheologic Agents",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Hematorheologic Agents"
          }
        }
      },
      "25": {
        "drug_class": "Hemin",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Hemin"
          }
        }
      },
      "30": {
        "drug_class": "Plasma Expanders",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Plasma Expanders"
          }
        }
      },
      "40": {
        "drug_class": "Plasma Proteins",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Plasma Proteins"
          }
        }
      },
      "50": {
        "drug_class": "Protamine",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Protamine"
          }
        }
      },
      "55": {
        "drug_class": "Human Protein C",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Human Protein C"
          }
        }
      },
      "60": {
        "drug_class": "Thrombolytic Enzymes",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Tissue Plasminogen Activators"
          }
        }
      },
      "65": {
        "drug_class": "Thrombolytic Agent - Misc",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Thrombolytic Agent - Misc"
          }
        }
      },
      "75": {
        "drug_class": "Hemataologic - Tyrosine Kinase Inhibitors",
        "drug_subclasses": {
          "60": {
            "drug_subclass": "Spleen Tyrosine Kinase (SYK) Inhibitors"
          }
        }
      },
      "80": {
        "drug_class": "Complement Inhibitors",
        "drug_subclasses": {
          "20": {
            "drug_subclass": "C1 Inhibitors"
          },
          "00": {
            "drug_subclass": "Complement Inhibitors"
          }
        }
      },
      "82": {
        "drug_class": "Bradykinin B2 Receptor Antagonists",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Bradykinin B2 Receptor Antagonists"
          }
        }
      },
      "84": {
        "drug_class": "Plasma Kallikrein Inhibitors",
        "drug_subclasses": {
          "20": {
            "drug_subclass": "Plasma Kallikrein Inhibitors - Monoclonal Antibodies"
          },
          "00": {
            "drug_subclass": "Plasma Kallikrein Inhibitors"
          }
        }
      },
      "08": {
        "drug_class": "Aminolevulinate Synthase 1-Directed siRNA",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Aminolevulinate Synthase 1-Directed siRNA"
          }
        }
      }
    },
    "drug_group": "HEMATOLOGICAL AGENTS - MISC."
  },
  "86": {
    "drug_classes": {
      "10": {
        "drug_class": "Ophthalmic Anti-infectives",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Ophthalmic Antibiotics"
          },
          "20": {
            "drug_subclass": "Ophthalmic Sulfonamides"
          },
          "30": {
            "drug_subclass": "Ophthalmic Antivirals"
          },
          "40": {
            "drug_subclass": "Ophthalmic Antifungal"
          },
          "50": {
            "drug_subclass": "Ophthalmic Antiseptics"
          },
          "99": {
            "drug_subclass": "Ophthalmic Anti-infective Combinations"
          }
        }
      },
      "20": {
        "drug_class": "Artificial Tears and Lubricants",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Artificial Tear Solutions"
          },
          "30": {
            "drug_subclass": "Artificial Tear Inserts"
          },
          "40": {
            "drug_subclass": "Gonioscopic Solutions"
          },
          "99": {
            "drug_subclass": "Artificial Tear and Lubricant Combinations"
          },
          "00": {
            "drug_subclass": "Artificial Tears and Lubricants"
          }
        }
      },
      "25": {
        "drug_class": "Beta-blockers - Ophthalmic",
        "drug_subclasses": {
          "99": {
            "drug_subclass": "Beta-blockers - Ophthalmic Combinations"
          },
          "00": {
            "drug_subclass": "Beta-blockers - Ophthalmic"
          }
        }
      },
      "30": {
        "drug_class": "Ophthalmic Steroids",
        "drug_subclasses": {
          "99": {
            "drug_subclass": "Ophthalmic Steroid Combinations"
          },
          "00": {
            "drug_subclass": "Ophthalmic Steroids"
          }
        }
      },
      "33": {
        "drug_class": "Prostaglandins - Ophthalmic",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Prostaglandins - Ophthalmic"
          }
        }
      },
      "35": {
        "drug_class": "Cycloplegic Mydriatics",
        "drug_subclasses": {
          "99": {
            "drug_subclass": "Cycloplegic Mydriatic Combinations"
          },
          "00": {
            "drug_subclass": "Cycloplegic Mydriatics"
          }
        }
      },
      "37": {
        "drug_class": "Ophthalmic Gene Therapy",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Ophthalmic Gene Therapy"
          }
        }
      },
      "40": {
        "drug_class": "Ophthalmic Decongestants",
        "drug_subclasses": {
          "99": {
            "drug_subclass": "Ophthalmic Decongestant Combinations"
          },
          "00": {
            "drug_subclass": "Ophthalmic Decongestants"
          }
        }
      },
      "50": {
        "drug_class": "Miotics",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Miotics - Direct Acting"
          },
          "20": {
            "drug_subclass": "Miotics - Cholinesterase Inhibitors"
          }
        }
      },
      "52": {
        "drug_class": "Ophthalmic Kinase Inhibitors",
        "drug_subclasses": {
          "70": {
            "drug_subclass": "Ophthalmic Rho Kinase Inhibitors"
          },
          "99": {
            "drug_subclass": "Ophthalmic Kinase Inhibitors - Combinations"
          }
        }
      },
      "60": {
        "drug_class": "Ophthalmic Adrenergic Agents",
        "drug_subclasses": {
          "20": {
            "drug_subclass": "Ophthalmic Selective Alpha Adrenergic Agonists"
          },
          "99": {
            "drug_subclass": "Alpha Adrenergic Agonist & Carbonic Anhydrase Inhib Comb"
          },
          "00": {
            "drug_subclass": "Ophthalmic Adrenergic Agents"
          }
        }
      },
      "65": {
        "drug_class": "Ophthalmic - Angiogenesis Inhibitors",
        "drug_subclasses": {
          "50": {
            "drug_subclass": "Vascular Endothelial Growth Factor (VEGF) Antagonists"
          }
        }
      },
      "70": {
        "drug_class": "Ophthalmic Photodynamic Therapy Agents",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Ophthalmic Photodynamic Therapy Agents"
          }
        }
      },
      "72": {
        "drug_class": "Ophthalmic Immunomodulators",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Ophthalmic Immunomodulators"
          }
        }
      },
      "73": {
        "drug_class": "Ophthalmic Integrin Antagonists",
        "drug_subclasses": {
          "40": {
            "drug_subclass": "Lymphocyte Function-Associated Antigen-1 (LFA-1) Antag"
          }
        }
      },
      "75": {
        "drug_class": "Ophthalmic Local Anesthetics",
        "drug_subclasses": {
          "99": {
            "drug_subclass": "Ophthalmic Local Anesthetic - Combinations"
          },
          "00": {
            "drug_subclass": "Ophthalmic Local Anesthetics"
          }
        }
      },
      "77": {
        "drug_class": "Ophthalmic Nerve Growth Factors",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Ophthalmic Nerve Growth Factors"
          }
        }
      },
      "78": {
        "drug_class": "Ophthalmic Surgical Aids",
        "drug_subclasses": {
          "99": {
            "drug_subclass": "Ophthalmic Surgical Aids - Combinations"
          },
          "00": {
            "drug_subclass": "Ophthalmic Surgical Aids"
          }
        }
      },
      "79": {
        "drug_class": "Ophthalmic Photoenhancers",
        "drug_subclasses": {
          "99": {
            "drug_subclass": "Ophthalmic Photoenhancer Combinations"
          }
        }
      },
      "80": {
        "drug_class": "Ophthalmics - Misc.",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Ophthalmic Enzymes"
          },
          "20": {
            "drug_subclass": "Ophthalmic Antiallergic"
          },
          "22": {
            "drug_subclass": "Ophthalmics - Blepharoptosis Agent"
          },
          "23": {
            "drug_subclass": "Ophthalmic Carbonic Anhydrase Inhibitors"
          },
          "30": {
            "drug_subclass": "Ophthalmic Irrigation Solutions"
          },
          "40": {
            "drug_subclass": "Ophthalmic Hyperosmolar Products"
          },
          "50": {
            "drug_subclass": "Ophthalmic Nonsteroidal Anti-inflammatory Agents"
          },
          "55": {
            "drug_subclass": "Ophthalmics - Cystinosis Agent"
          },
          "60": {
            "drug_subclass": "Ophthalmic Diagnostic Products"
          },
          "70": {
            "drug_subclass": "Ophthalmics Misc. - Other"
          }
        }
      },
      "90": {
        "drug_class": "Contact Lens Solutions",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Hard Lens Products"
          },
          "20": {
            "drug_subclass": "Soft Lens Products"
          },
          "30": {
            "drug_subclass": "Oxygen Permeable Lens Products"
          },
          "50": {
            "drug_subclass": "Hard/Soft/Gas Permeable Products"
          }
        }
      }
    },
    "drug_group": "OPHTHALMIC AGENTS"
  },
  "87": {
    "drug_classes": {
      "10": {
        "drug_class": "Otic Anti-infectives",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Otic Anti-infectives"
          }
        }
      },
      "30": {
        "drug_class": "Otic Steroids",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Otic Steroids"
          }
        }
      },
      "40": {
        "drug_class": "Otic Agents - Miscellaneous",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Otic Agents - Miscellaneous"
          }
        }
      },
      "99": {
        "drug_class": "Otic Combinations",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Otic Steroid-Anti-infective Combinations"
          },
          "20": {
            "drug_subclass": "Otic Analgesic Combinations"
          },
          "30": {
            "drug_subclass": "Otic Antifungal Combinations"
          }
        }
      }
    },
    "drug_group": "OTIC AGENTS"
  },
  "88": {
    "drug_classes": {
      "10": {
        "drug_class": "Anti-infectives - Throat",
        "drug_subclasses": {
          "99": {
            "drug_subclass": "Anti-infective Combinations - Throat"
          },
          "00": {
            "drug_subclass": "Anti-infectives - Throat"
          }
        }
      },
      "15": {
        "drug_class": "Antiseptics - Mouth/Throat",
        "drug_subclasses": {
          "99": {
            "drug_subclass": "Antiseptic Combinations - Mouth/Throat"
          },
          "00": {
            "drug_subclass": "Antiseptics - Mouth/Throat"
          }
        }
      },
      "20": {
        "drug_class": "Lozenges",
        "drug_subclasses": {
          "99": {
            "drug_subclass": "Lozenge - Combinations"
          },
          "00": {
            "drug_subclass": "Lozenges"
          }
        }
      },
      "25": {
        "drug_class": "Steroids - Mouth/Throat/Dental",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Steroids - Mouth/Throat/Dental"
          }
        }
      },
      "30": {
        "drug_class": "Mouthwashes",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Mouthwashes"
          }
        }
      },
      "35": {
        "drug_class": "Anesthetics Topical Oral",
        "drug_subclasses": {
          "99": {
            "drug_subclass": "Anesthetics Topical Oral - Combinations"
          },
          "00": {
            "drug_subclass": "Anesthetics Topical Oral"
          }
        }
      },
      "40": {
        "drug_class": "Dental Products",
        "drug_subclasses": {
          "20": {
            "drug_subclass": "Fluoride Dental Products"
          },
          "70": {
            "drug_subclass": "Dental Aids"
          },
          "99": {
            "drug_subclass": "Dental Products - Combinations"
          },
          "00": {
            "drug_subclass": "Dental Products"
          },
          "09": {
            "drug_subclass": "Dental Whiteners"
          }
        }
      },
      "45": {
        "drug_class": "Periodontal Products",
        "drug_subclasses": {
          "20": {
            "drug_subclass": "Periodontal Anti-infectives"
          }
        }
      },
      "50": {
        "drug_class": "Throat Products - Misc.",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Dry Mouth Agents and Artificial Saliva"
          },
          "15": {
            "drug_subclass": "Saliva Stimulants"
          },
          "20": {
            "drug_subclass": "Protectants - Mouth/Throat"
          },
          "00": {
            "drug_subclass": "Throat Products - Misc."
          }
        }
      }
    },
    "drug_group": "MOUTH/THROAT/DENTAL AGENTS"
  },
  "89": {
    "drug_classes": {
      "10": {
        "drug_class": "Rectal Steroids",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Rectal Steroids"
          }
        }
      },
      "99": {
        "drug_class": "Rectal Combinations",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Rectal Anesthetic/Steroids"
          },
          "40": {
            "drug_subclass": "Rectal Combinations - Misc."
          }
        }
      }
    },
    "drug_group": "ANORECTAL AGENTS"
  },
  "90": {
    "drug_classes": {
      "10": {
        "drug_class": "Antibiotics - Topical",
        "drug_subclasses": {
          "98": {
            "drug_subclass": "Antibiotic Mixtures Topical"
          },
          "99": {
            "drug_subclass": "Antibiotic Steroid Combinations - Topical"
          },
          "00": {
            "drug_subclass": "Antibiotics - Topical"
          }
        }
      },
      "15": {
        "drug_class": "Antifungals - Topical",
        "drug_subclasses": {
          "40": {
            "drug_subclass": "Imidazole-Related Antifungals - Topical"
          },
          "60": {
            "drug_subclass": "Oxaborole-Related Antifungals - Topical"
          },
          "99": {
            "drug_subclass": "Antifungals - Topical Combinations"
          },
          "00": {
            "drug_subclass": "Antifungals - Topical"
          }
        }
      },
      "20": {
        "drug_class": "Antihistamines-Topical",
        "drug_subclasses": {
          "99": {
            "drug_subclass": "Antihistamine-Topical Combinations"
          },
          "00": {
            "drug_subclass": "Antihistamines - Topical"
          }
        }
      },
      "21": {
        "drug_class": "Anti-inflammatory Agents - Topical",
        "drug_subclasses": {
          "99": {
            "drug_subclass": "Anti-inflammatory Combinations - Topical"
          },
          "00": {
            "drug_subclass": "Anti-inflammatory Agents - Topical"
          }
        }
      },
      "22": {
        "drug_class": "Antipruritics - Topical",
        "drug_subclasses": {
          "99": {
            "drug_subclass": "Antipruritic Combinations - Topical"
          },
          "00": {
            "drug_subclass": "Antipruritics - Topical"
          }
        }
      },
      "23": {
        "drug_class": "Phosphodiesterase 4 (PDE4) Inhibitors - Topical",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Phosphodiesterase 4 (PDE4) Inhibitors - Topical"
          }
        }
      },
      "25": {
        "drug_class": "Antipsoriatics",
        "drug_subclasses": {
          "99": {
            "drug_subclass": "Antipsoriatic Combinations"
          },
          "00": {
            "drug_subclass": "Antipsoriatics"
          },
          "05": {
            "drug_subclass": "Antipsoriatics - Systemic"
          }
        }
      },
      "27": {
        "drug_class": "Eczema Agents",
        "drug_subclasses": {
          "30": {
            "drug_subclass": "Atopic Dermatitis - Monoclonal Antibodies"
          }
        }
      },
      "30": {
        "drug_class": "Antiseborrheic Products",
        "drug_subclasses": {
          "40": {
            "drug_subclass": "Seborrheic Keratosis Product"
          },
          "99": {
            "drug_subclass": "Antiseborrheic Combinations"
          },
          "00": {
            "drug_subclass": "Antiseborrheic Products"
          }
        }
      },
      "35": {
        "drug_class": "Antivirals - Topical",
        "drug_subclasses": {
          "99": {
            "drug_subclass": "Antiviral Topical Combinations"
          },
          "00": {
            "drug_subclass": "Antivirals - Topical"
          }
        }
      },
      "37": {
        "drug_class": "Antineoplastic or Premalignant Lesion Agents - Topical",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Antineoplastic Alkylating Agents - Topical"
          },
          "20": {
            "drug_subclass": "Antineoplastic Antimetabolites - Topical"
          },
          "45": {
            "drug_subclass": "Microtubule Inhibitors - Topical"
          },
          "50": {
            "drug_subclass": "Photodynamic Therapy Agents - Topical"
          },
          "60": {
            "drug_subclass": "Antineoplastic Retinoids - Topical"
          },
          "62": {
            "drug_subclass": "Topical Selective Retinoid X Receptor Agonists"
          },
          "80": {
            "drug_subclass": "Antineoplastic or Premalignant Lesions - Topical Misc."
          },
          "99": {
            "drug_subclass": "Antineoplastic or Premalignant Lesion Agent - Comb"
          }
        }
      },
      "40": {
        "drug_class": "Bath Products",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Bath Products"
          }
        }
      },
      "45": {
        "drug_class": "Burn Products",
        "drug_subclasses": {
          "99": {
            "drug_subclass": "Burn Product Combinations"
          },
          "00": {
            "drug_subclass": "Burn Products"
          }
        }
      },
      "50": {
        "drug_class": "Cauterizing Agents",
        "drug_subclasses": {
          "99": {
            "drug_subclass": "Cauterizing Agent Combinations"
          },
          "00": {
            "drug_subclass": "Cauterizing Agents"
          }
        }
      },
      "52": {
        "drug_class": "Tar Products",
        "drug_subclasses": {
          "99": {
            "drug_subclass": "Tar Combinations"
          },
          "00": {
            "drug_subclass": "Tar Products"
          }
        }
      },
      "55": {
        "drug_class": "Corticosteroids - Topical",
        "drug_subclasses": {
          "98": {
            "drug_subclass": "Steroid-Local Anesthetic Combinations"
          },
          "99": {
            "drug_subclass": "Topical Steroid Combinations"
          },
          "00": {
            "drug_subclass": "Corticosteroids - Topical"
          }
        }
      },
      "60": {
        "drug_class": "Diaper Rash Products",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Diaper Rash Products"
          }
        }
      },
      "65": {
        "drug_class": "Emollients",
        "drug_subclasses": {
          "99": {
            "drug_subclass": "Emollient Combinations"
          },
          "00": {
            "drug_subclass": "Emollients"
          }
        }
      },
      "66": {
        "drug_class": "Emollient/Keratolytic Agents",
        "drug_subclasses": {
          "99": {
            "drug_subclass": "Emollient/Keratolytic Combinations"
          },
          "00": {
            "drug_subclass": "Emollient/Keratolytic Agents"
          }
        }
      },
      "70": {
        "drug_class": "Enzymes - Topical",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Enzymes - Topical"
          }
        }
      },
      "73": {
        "drug_class": "Hair Growth Agents",
        "drug_subclasses": {
          "40": {
            "drug_subclass": "Prostaglandins - Topical"
          },
          "60": {
            "drug_subclass": "Type II 5-Alpha Reductase Inhibitors"
          },
          "80": {
            "drug_subclass": "Vascular Agents"
          },
          "99": {
            "drug_subclass": "Hair Growth Agent - Combinations"
          }
        }
      },
      "74": {
        "drug_class": "Hair Reduction Agents",
        "drug_subclasses": {
          "50": {
            "drug_subclass": "Ornithine Decarboxylase (ODC) Inhibitors - Topical"
          }
        }
      },
      "75": {
        "drug_class": "Keratolytic/Antimitotic Agents",
        "drug_subclasses": {
          "99": {
            "drug_subclass": "Keratolytic and/or Antimitotic Combinations"
          },
          "00": {
            "drug_subclass": "Keratolytic/Antimitotic Agents"
          }
        }
      },
      "76": {
        "drug_class": "Agents for External Genital and Perianal Warts",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Agents for External Genital and Perianal Warts"
          }
        }
      },
      "77": {
        "drug_class": "Immunomodulating Agents - Topical",
        "drug_subclasses": {
          "30": {
            "drug_subclass": "Immunomodulators Imidazoquinolinamines - Topical"
          }
        }
      },
      "78": {
        "drug_class": "Immunosuppressive Agents - Topical",
        "drug_subclasses": {
          "40": {
            "drug_subclass": "Macrolide Immunosuppressants - Topical"
          },
          "99": {
            "drug_subclass": "Immunosuppressive Agents - Topical Combinations"
          }
        }
      },
      "80": {
        "drug_class": "Liniments",
        "drug_subclasses": {
          "99": {
            "drug_subclass": "Liniment Combinations"
          },
          "00": {
            "drug_subclass": "Liniments"
          }
        }
      },
      "85": {
        "drug_class": "Local Anesthetics - Topical",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Topical Anesthetic Gases"
          },
          "99": {
            "drug_subclass": "Topical Anesthetic Combinations"
          },
          "00": {
            "drug_subclass": "Local Anesthetics - Topical"
          }
        }
      },
      "87": {
        "drug_class": "Pigmenting-Depigmenting Agents",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Pigmenting Agents"
          },
          "20": {
            "drug_subclass": "Depigmenting Agents"
          },
          "99": {
            "drug_subclass": "Depigmenting Combinations"
          }
        }
      },
      "88": {
        "drug_class": "Agents for Wrinkles/Lipoatrophy/Other Aesthetic Uses",
        "drug_subclasses": {
          "40": {
            "drug_subclass": "Agents for Facial Lipoatrophy"
          },
          "60": {
            "drug_subclass": "Agents for Facial Wrinkles - Retinoids"
          },
          "80": {
            "drug_subclass": "Agents for Facial Wrinkles - Dermal Fillers"
          },
          "85": {
            "drug_subclass": "Agents for Submental Fat"
          }
        }
      },
      "89": {
        "drug_class": "Glabellar Lines (Frown Lines) Agents",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Glabellar Lines (Frown Lines) Agents"
          }
        }
      },
      "90": {
        "drug_class": "Scabicides & Pediculicides",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Nit Removers"
          },
          "99": {
            "drug_subclass": "Scabicide Combinations"
          },
          "00": {
            "drug_subclass": "Scabicides & Pediculicides"
          }
        }
      },
      "92": {
        "drug_class": "Sunscreens",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Sunscreens"
          }
        }
      },
      "93": {
        "drug_class": "Scar Treatment Products",
        "drug_subclasses": {
          "99": {
            "drug_subclass": "Scar Treatment Products - Combinations"
          },
          "00": {
            "drug_subclass": "Scar Treatment Products"
          }
        }
      },
      "94": {
        "drug_class": "Wound Care Products",
        "drug_subclasses": {
          "30": {
            "drug_subclass": "Wound Cleansers/Decubitus Ulcer Therapy"
          },
          "40": {
            "drug_subclass": "Wound Dressings"
          },
          "50": {
            "drug_subclass": "Wound Care - Growth Factor Agents"
          },
          "70": {
            "drug_subclass": "Wound Care Supplies"
          },
          "80": {
            "drug_subclass": "Tissue Replacements"
          },
          "99": {
            "drug_subclass": "Wound Care Combinations"
          }
        }
      },
      "95": {
        "drug_class": "Poison Ivy Products",
        "drug_subclasses": {
          "99": {
            "drug_subclass": "Poison Ivy Product Combinations"
          },
          "00": {
            "drug_subclass": "Poison Ivy Products"
          }
        }
      },
      "97": {
        "drug_class": "Misc. Topical",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Astringents"
          },
          "15": {
            "drug_subclass": "Skin Cleansers"
          },
          "20": {
            "drug_subclass": "Skin Protectants"
          },
          "25": {
            "drug_subclass": "Insect Repellents"
          },
          "30": {
            "drug_subclass": "Soaps"
          },
          "40": {
            "drug_subclass": "Shampoos"
          },
          "50": {
            "drug_subclass": "Powders"
          },
          "60": {
            "drug_subclass": "Skin Oils"
          },
          "70": {
            "drug_subclass": "Lubricants"
          },
          "72": {
            "drug_subclass": "Intimacy Products"
          },
          "80": {
            "drug_subclass": "Eyelid Cleansers & Lubricants"
          },
          "99": {
            "drug_subclass": "Misc. Topical Combinations"
          },
          "00": {
            "drug_subclass": "Misc. Topical"
          }
        }
      },
      "98": {
        "drug_class": "Podiatric Products",
        "drug_subclasses": {
          "99": {
            "drug_subclass": "Podiatric Product - Combinations"
          },
          "00": {
            "drug_subclass": "Podiatric Products"
          }
        }
      },
      "99": {
        "drug_class": "Misc. Dermatological Products",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Misc. Dermatological Products"
          }
        }
      },
      "05": {
        "drug_class": "Acne Products",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Acne Antibiotics"
          },
          "20": {
            "drug_subclass": "Acne Cleansers"
          },
          "99": {
            "drug_subclass": "Acne Combinations"
          },
          "00": {
            "drug_subclass": "Acne Products"
          }
        }
      },
      "06": {
        "drug_class": "Rosacea Agents",
        "drug_subclasses": {
          "99": {
            "drug_subclass": "Rosacea Combinations"
          },
          "00": {
            "drug_subclass": "Rosacea Agents"
          }
        }
      },
      "07": {
        "drug_class": "Analgesics - Topical",
        "drug_subclasses": {
          "99": {
            "drug_subclass": "Analgesic Combinations - Topical"
          },
          "00": {
            "drug_subclass": "Analgesics - Topical"
          }
        }
      }
    },
    "drug_group": "DERMATOLOGICALS"
  },
  "92": {
    "drug_classes": {
      "10": {
        "drug_class": "Chlorine Antiseptics",
        "drug_subclasses": {
          "99": {
            "drug_subclass": "Chlorine Antiseptic Combinations"
          },
          "00": {
            "drug_subclass": "Chlorine Antiseptics"
          }
        }
      },
      "20": {
        "drug_class": "Iodine Antiseptics",
        "drug_subclasses": {
          "99": {
            "drug_subclass": "Iodine Antiseptic Combinations"
          },
          "00": {
            "drug_subclass": "Iodine Antiseptics"
          }
        }
      },
      "30": {
        "drug_class": "Mercury Antiseptics",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Mercury Antiseptics"
          }
        }
      },
      "40": {
        "drug_class": "Silver Antiseptics",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Silver Antiseptics"
          }
        }
      },
      "80": {
        "drug_class": "Disinfectants",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Disinfectants"
          }
        }
      },
      "99": {
        "drug_class": "Antiseptic Combinations",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Antiseptic Combinations"
          }
        }
      },
      "00": {
        "drug_class": "Antiseptics & Disinfectants",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Antiseptics & Disinfectants"
          }
        }
      }
    },
    "drug_group": "ANTISEPTICS & DISINFECTANTS"
  },
  "93": {
    "drug_classes": {
      "10": {
        "drug_class": "Antidotes - Chelating Agents",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Antidotes - Chelating Agents"
          }
        }
      },
      "20": {
        "drug_class": "Benzodiazepine Antagonists",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Benzodiazepine Antagonists"
          }
        }
      },
      "40": {
        "drug_class": "Opioid Antagonists",
        "drug_subclasses": {
          "99": {
            "drug_subclass": "Opioid Antagonist Combinations"
          },
          "00": {
            "drug_subclass": "Opioid Antagonists"
          }
        }
      },
      "80": {
        "drug_class": "Topical Antidotes",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Topical Antidotes"
          }
        }
      },
      "99": {
        "drug_class": "Antidote Combinations",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Antidote Combinations"
          }
        }
      },
      "00": {
        "drug_class": "Antidotes and Specific Antagonists",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Antidotes and Specific Antagonists"
          }
        }
      }
    },
    "drug_group": "ANTIDOTES AND SPECIFIC ANTAGONISTS"
  },
  "94": {
    "drug_classes": {
      "10": {
        "drug_class": "Diagnostic Tests",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Infection Tests"
          },
          "97": {
            "drug_subclass": "Diagnostic Infection Test Combinations"
          },
          "98": {
            "drug_subclass": "Diagnostic Test Combinations"
          },
          "99": {
            "drug_subclass": "Multiple Urine Tests"
          },
          "00": {
            "drug_subclass": "Diagnostic Tests"
          }
        }
      },
      "20": {
        "drug_class": "Diagnostic Drugs",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Diagnostic Drugs"
          }
        }
      },
      "30": {
        "drug_class": "Diagnostic Biologicals",
        "drug_subclasses": {
          "99": {
            "drug_subclass": "Multiple Skin Tests"
          },
          "00": {
            "drug_subclass": "Diagnostic Biologicals"
          }
        }
      },
      "35": {
        "drug_class": "Diagnostic Radiopharmaceuticals",
        "drug_subclasses": {
          "25": {
            "drug_subclass": "Diagnostic Radiopharmaceuticals - Brain"
          },
          "30": {
            "drug_subclass": "Diagnostic Radiopharmaceuticals - Cardiac"
          },
          "40": {
            "drug_subclass": "Diagnostic Radiopharmaceuticals - Endocrine"
          },
          "46": {
            "drug_subclass": "Diagnostic Radiopharmaceuticals - Hepatobiliary"
          },
          "50": {
            "drug_subclass": "Diagnostic Radiopharmaceuticals-Immune Cell Radiolabelin"
          },
          "65": {
            "drug_subclass": "Diagnostic Radiopharmaceuticals - Prostatic"
          },
          "75": {
            "drug_subclass": "Diagnostic Radiopharmaceuticals - Renal"
          },
          "85": {
            "drug_subclass": "Diagnostic Radiopharmaceuticals - Skeletal"
          },
          "90": {
            "drug_subclass": "Diagnostic Radiopharmaceuticals - Miscellaneous"
          },
          "95": {
            "drug_subclass": "Diagnostic Radiopharmaceuticals - Gases"
          }
        }
      },
      "40": {
        "drug_class": "Radiographic Contrast Media",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Radiographic Contrast Media - Barium"
          },
          "20": {
            "drug_subclass": "Radiographic Contrast Media - Iodinated"
          }
        }
      },
      "50": {
        "drug_class": "Miscellaneous Contrast Media",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Miscellaneous Contrast Media"
          }
        }
      },
      "60": {
        "drug_class": "Diagnostic Products, Misc.",
        "drug_subclasses": {
          "60": {
            "drug_subclass": "Diagnostic Supplies"
          },
          "00": {
            "drug_subclass": "Diagnostic Products, Misc."
          }
        }
      }
    },
    "drug_group": "DIAGNOSTIC PRODUCTS"
  },
  "95": {
    "drug_classes": {
      "63": {
        "drug_class": "Alternative Medicine - U",
        "drug_subclasses": {
          "06": {
            "drug_subclass": "Alternative Medicine - Ub"
          }
        }
      },
      "99": {
        "drug_class": "Alternative Medicine Combinations",
        "drug_subclasses": {
          "02": {
            "drug_subclass": "Alternative Medicine Combinations - Two Ingredients"
          },
          "03": {
            "drug_subclass": "Alternative Medicine Combinations - Three Ingredients"
          },
          "04": {
            "drug_subclass": "Alternative Medicine Combinations - Four Ingredients"
          },
          "05": {
            "drug_subclass": "Alternative Medicine Combinations - Five Ingredients"
          },
          "06": {
            "drug_subclass": "Alternative Medicine Combinations - Six Ingredients"
          },
          "07": {
            "drug_subclass": "Alternative Medicine Combinations - Seven Ingredients"
          }
        }
      },
      "nan": {
        "drug_class": "Alternative Medicine - Y\\'s",
        "drug_subclasses": {}
      }
    },
    "drug_group": "ALTERNATIVE MEDICINES"
  },
  "96": {
    "drug_classes": {
      "10": {
        "drug_class": "Acids, Bases, & Buffers",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Acids"
          },
          "20": {
            "drug_subclass": "Bases"
          },
          "30": {
            "drug_subclass": "Buffers"
          }
        }
      },
      "20": {
        "drug_class": "Liquids",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Solvents"
          },
          "20": {
            "drug_subclass": "Fixed Oils"
          },
          "25": {
            "drug_subclass": "Essential Oils"
          },
          "99": {
            "drug_subclass": "Liquid Combinations"
          },
          "00": {
            "drug_subclass": "Liquids"
          }
        }
      },
      "30": {
        "drug_class": "Solids",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Additional Solids"
          },
          "99": {
            "drug_subclass": "Solid Combinations"
          },
          "00": {
            "drug_subclass": "Solids"
          }
        }
      },
      "40": {
        "drug_class": "Semi-Solids",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Semi-Solids"
          }
        }
      },
      "87": {
        "drug_class": "Bulk Chemicals - X",
        "drug_subclasses": {
          "88": {
            "drug_subclass": "Bulk Chemicals - Xy"
          }
        }
      },
      "90": {
        "drug_class": "Bulk Chemicals",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Bulk Chemicals"
          }
        }
      },
      "95": {
        "drug_class": "Bulk Chemical Compound Kits",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Bulk Chemical Compound Kits"
          }
        }
      },
      "nan": {
        "drug_class": "Bulk Chemicals - Z\\'s",
        "drug_subclasses": {}
      }
    },
    "drug_group": "CHEMICALS"
  },
  "97": {
    "drug_classes": {
      "10": {
        "drug_class": "Respiratory Therapy Supplies",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Nebulizers"
          },
          "12": {
            "drug_subclass": "Nasal Nebulizers"
          },
          "15": {
            "drug_subclass": "Peak Flow Meters"
          },
          "20": {
            "drug_subclass": "Humidifiers"
          },
          "27": {
            "drug_subclass": "Steam Inhalers"
          },
          "30": {
            "drug_subclass": "Vaporizers"
          },
          "50": {
            "drug_subclass": "Tracheostomy Care & Supplies"
          },
          "00": {
            "drug_subclass": "Respiratory Therapy Supplies"
          },
          "05": {
            "drug_subclass": "Spacer/Aerosol-Holding Chambers & Supplies"
          }
        }
      },
      "12": {
        "drug_class": "Respiratory Aids",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Masks"
          },
          "40": {
            "drug_subclass": "Air Cleaners"
          }
        }
      },
      "15": {
        "drug_class": "GI-GU Ostomy & Irrigation Supplies",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Ostomy Supplies"
          },
          "20": {
            "drug_subclass": "Incontinence Supplies"
          },
          "25": {
            "drug_subclass": "Irrigation-Type Syringes"
          },
          "30": {
            "drug_subclass": "Urinary Drainage & Irrigation Supplies"
          },
          "05": {
            "drug_subclass": "Catheters"
          }
        }
      },
      "20": {
        "drug_class": "Diabetic Supplies",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Insulin Administration Supplies"
          },
          "20": {
            "drug_subclass": "Glucose Monitoring Test Supplies"
          },
          "25": {
            "drug_subclass": "Glucose/Ketone Monitoring Test Supplies"
          },
          "30": {
            "drug_subclass": "Glucose Monitor & Blood Pressure Monitor Combinations"
          },
          "40": {
            "drug_subclass": "Glucose Monitor & Cholesterol Monitor Combinations"
          },
          "50": {
            "drug_subclass": "Ketone Monitoring Test Supplies"
          }
        }
      },
      "22": {
        "drug_class": "Blood Monitoring Supplies",
        "drug_subclasses": {
          "30": {
            "drug_subclass": "Blood Cholesterol Monitoring Supplies"
          }
        }
      },
      "25": {
        "drug_class": "Enteral Nutrition Supplies",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Feeding Tubes"
          },
          "20": {
            "drug_subclass": "Digestive Enzyme Cartridge"
          },
          "00": {
            "drug_subclass": "Enteral Nutrition Supplies"
          }
        }
      },
      "30": {
        "drug_class": "Bandages-Dressings-Tape",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Adhesive Bandages"
          },
          "15": {
            "drug_subclass": "Compression Bandages"
          },
          "20": {
            "drug_subclass": "Gauze Bandages"
          },
          "30": {
            "drug_subclass": "Gauze Pads & Dressings"
          },
          "40": {
            "drug_subclass": "Adhesive Tape"
          },
          "50": {
            "drug_subclass": "Nasal Dilators"
          },
          "52": {
            "drug_subclass": "Nasal Filters"
          },
          "70": {
            "drug_subclass": "Scar Treatments"
          }
        }
      },
      "34": {
        "drug_class": "Fixed (Rigid) Bandages/Supports & Accessories",
        "drug_subclasses": {
          "40": {
            "drug_subclass": "Casts, Splints & Accessories"
          }
        }
      },
      "35": {
        "drug_class": "Elastic Bandages & Supports",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Elastic Bandages & Supports"
          }
        }
      },
      "36": {
        "drug_class": "Surgical Supplies",
        "drug_subclasses": {
          "50": {
            "drug_subclass": "Surgical Scalpels & Blades"
          },
          "55": {
            "drug_subclass": "Skin Adhesives"
          },
          "57": {
            "drug_subclass": "Surgical Sealants"
          }
        }
      },
      "37": {
        "drug_class": "Heating/Cooling Aids",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Heating Pads"
          },
          "20": {
            "drug_subclass": "Hot Packs"
          },
          "50": {
            "drug_subclass": "Cold Packs"
          },
          "90": {
            "drug_subclass": "Hot/Cold Combination Therapy Aids"
          }
        }
      },
      "40": {
        "drug_class": "Contraceptives",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Condoms - Male"
          },
          "15": {
            "drug_subclass": "Condoms - Female"
          },
          "18": {
            "drug_subclass": "Cervical Caps"
          },
          "20": {
            "drug_subclass": "Diaphragms"
          }
        }
      },
      "42": {
        "drug_class": "Conception Assistance Supplies",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Conception Assistance Supplies"
          }
        }
      },
      "45": {
        "drug_class": "Female Personal Care Products",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Sanitary Napkins & Tampons"
          }
        }
      },
      "47": {
        "drug_class": "Impotence Aids",
        "drug_subclasses": {
          "50": {
            "drug_subclass": "Impotence Aids - Male"
          },
          "60": {
            "drug_subclass": "Sexual Dysfunction Devices - Female"
          }
        }
      },
      "50": {
        "drug_class": "Oral Hygiene Products",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Denture Care Products"
          },
          "20": {
            "drug_subclass": "Tooth Brushes-Floss"
          },
          "30": {
            "drug_subclass": "Dentifrices"
          },
          "35": {
            "drug_subclass": "Dental Desensitizing Products"
          },
          "40": {
            "drug_subclass": "Dental Whitening Products"
          },
          "00": {
            "drug_subclass": "Oral Hygiene Products"
          },
          "05": {
            "drug_subclass": "Dental Supplies"
          }
        }
      },
      "55": {
        "drug_class": "Infant Care Products",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Feeding Supplies"
          },
          "20": {
            "drug_subclass": "Diapers"
          },
          "30": {
            "drug_subclass": "Nursing Pads"
          },
          "00": {
            "drug_subclass": "Infant Care Products"
          }
        }
      },
      "57": {
        "drug_class": "Auditory Supplies",
        "drug_subclasses": {
          "40": {
            "drug_subclass": "Hearing Aid Supplies-Batteries"
          }
        }
      },
      "60": {
        "drug_class": "Optical and Ophthalmic Supplies",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Contact Lens Care Supplies"
          },
          "20": {
            "drug_subclass": "Eye Glass Care Supplies"
          },
          "30": {
            "drug_subclass": "Eye Patches"
          },
          "50": {
            "drug_subclass": "Ophthalmic Pressure Monitors"
          },
          "00": {
            "drug_subclass": "Optical Supplies"
          }
        }
      },
      "70": {
        "drug_class": "Misc. Devices",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Thermometers"
          },
          "15": {
            "drug_subclass": "Medication Disposal Systems"
          },
          "20": {
            "drug_subclass": "Disposable Gloves"
          },
          "30": {
            "drug_subclass": "Applicators,Cotton Balls,etc"
          },
          "40": {
            "drug_subclass": "Rubber Goods"
          },
          "45": {
            "drug_subclass": "Medical Identification Supplies"
          },
          "47": {
            "drug_subclass": "Transcranial Magnetic Stimulators"
          },
          "49": {
            "drug_subclass": "Tumor Treating Fields Products (TTFields)"
          },
          "50": {
            "drug_subclass": "Nerve Stimulators"
          },
          "53": {
            "drug_subclass": "Blood Coagulation Test Supplies"
          },
          "55": {
            "drug_subclass": "Neurological Diagnostic Supplies"
          },
          "60": {
            "drug_subclass": "Seizure Monitoring Products"
          },
          "70": {
            "drug_subclass": "Razors and Blades"
          },
          "90": {
            "drug_subclass": "Oral Dosing Devices"
          },
          "95": {
            "drug_subclass": "Acupressure Therapy Supplies"
          },
          "00": {
            "drug_subclass": "Misc. Devices"
          }
        }
      },
      "75": {
        "drug_class": "Blood Pressure Devices",
        "drug_subclasses": {
          "99": {
            "drug_subclass": "Blood Pressure Device Combinations"
          },
          "00": {
            "drug_subclass": "Blood Pressure Devices"
          }
        }
      },
      "80": {
        "drug_class": "Foot Care Products",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Foot Care Products"
          }
        }
      },
      "85": {
        "drug_class": "First Aid Kits",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "First Aid Kits"
          }
        }
      },
      "05": {
        "drug_class": "Parenteral Therapy Supplies",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Needles & Syringes"
          },
          "20": {
            "drug_subclass": "IV Sets/Tubing"
          },
          "25": {
            "drug_subclass": "Parenteral Catheters"
          },
          "40": {
            "drug_subclass": "Subcutaneous Administration Supplies"
          },
          "80": {
            "drug_subclass": "Medical Waste Disposal Systems"
          },
          "00": {
            "drug_subclass": "Parenteral Therapy Supplies"
          }
        }
      },
      "08": {
        "drug_class": "Cardiology Supplies",
        "drug_subclasses": {
          "30": {
            "drug_subclass": "Cardiac Defibrillators"
          }
        }
      }
    },
    "drug_group": "MEDICAL DEVICES AND SUPPLIES"
  },
  "98": {
    "drug_classes": {
      "10": {
        "drug_class": "Antimicrobial Agents",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Antimicrobial Agents"
          }
        }
      },
      "30": {
        "drug_class": "Coloring Agents",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Coloring Agents"
          }
        }
      },
      "33": {
        "drug_class": "Flavoring Agents",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Flavoring Agents"
          }
        }
      },
      "35": {
        "drug_class": "Pharmaceutical Excipients",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "External Vehicle Ingredients"
          },
          "00": {
            "drug_subclass": "Pharmaceutical Excipients"
          }
        }
      },
      "36": {
        "drug_class": "Internal Vehicle Ingredients/Agents",
        "drug_subclasses": {
          "50": {
            "drug_subclass": "Thickening Agents"
          }
        }
      },
      "37": {
        "drug_class": "Surfactants",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Surfactants"
          }
        }
      },
      "40": {
        "drug_class": "Liquid Vehicles",
        "drug_subclasses": {
          "10": {
            "drug_subclass": "Parenteral Vehicles"
          },
          "20": {
            "drug_subclass": "Oral Vehicles"
          },
          "30": {
            "drug_subclass": "External Vehicles"
          },
          "90": {
            "drug_subclass": "Misc. Vehicles"
          }
        }
      },
      "60": {
        "drug_class": "Semi Solid Vehicles",
        "drug_subclasses": {
          "99": {
            "drug_subclass": "Semi Solid Vehicle Combination"
          },
          "00": {
            "drug_subclass": "Semi Solid Vehicles"
          }
        }
      },
      "65": {
        "drug_class": "Delivery Devices",
        "drug_subclasses": {
          "20": {
            "drug_subclass": "Transdermal Patches"
          }
        }
      },
      "70": {
        "drug_class": "Gelatin Capsules (Empty)",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Gelatin Capsules (Empty)"
          }
        }
      },
      "71": {
        "drug_class": "Non Gelatin Capsules (Empty)",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Non Gelatin Capsules (Empty)"
          }
        }
      },
      "80": {
        "drug_class": "Placebos",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Placebos"
          }
        }
      },
      "90": {
        "drug_class": "Pharmaceutical Adjuvants Miscellanous",
        "drug_subclasses": {
          "30": {
            "drug_subclass": "Buffer Reference Standards"
          }
        }
      },
      "05": {
        "drug_class": "Alkalizing Agents",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Alkalizing Agents"
          }
        }
      }
    },
    "drug_group": "PHARMACEUTICAL ADJUVANTS"
  },
  "99": {
    "drug_classes": {
      "20": {
        "drug_class": "Chelating Agents",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Chelating Agents"
          }
        }
      },
      "34": {
        "drug_class": "Digital Therapy",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Digital Therapy"
          }
        }
      },
      "35": {
        "drug_class": "Enzymes",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Enzymes"
          }
        }
      },
      "37": {
        "drug_class": "Fecal Incontinence Bulking Agents",
        "drug_subclasses": {
          "99": {
            "drug_subclass": "Fecal Incontinence Bulking Agent - Combinations"
          }
        }
      },
      "39": {
        "drug_class": "Immunomodulators",
        "drug_subclasses": {
          "20": {
            "drug_subclass": "Antileprotics"
          },
          "40": {
            "drug_subclass": "Immunomodulators for Myelodysplastic Syndromes"
          }
        }
      },
      "40": {
        "drug_class": "Immunosuppressive Agents",
        "drug_subclasses": {
          "20": {
            "drug_subclass": "Cyclosporine Analogs"
          },
          "25": {
            "drug_subclass": "Immune Globulin Immunosuppressants"
          },
          "30": {
            "drug_subclass": "Inosine Monophosphate Dehydrogenase Inhibitors"
          },
          "40": {
            "drug_subclass": "Macrolide Immunosuppressants"
          },
          "50": {
            "drug_subclass": "Monoclonal Antibodies"
          },
          "60": {
            "drug_subclass": "Purine Analogs"
          },
          "80": {
            "drug_subclass": "Selective T-Cell Costimulation Blockers"
          }
        }
      },
      "42": {
        "drug_class": "Systemic Lupus Erythematosus Agents",
        "drug_subclasses": {
          "20": {
            "drug_subclass": "B-Lymphocyte Stimulator (BLyS)-Specific Inhibitors"
          }
        }
      },
      "45": {
        "drug_class": "Potassium Removing Agents",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Potassium Removing Agents"
          }
        }
      },
      "46": {
        "drug_class": "Progeria Treatment Agents",
        "drug_subclasses": {
          "30": {
            "drug_subclass": "Farnesyltransferase Inhibitors"
          }
        }
      },
      "47": {
        "drug_class": "Lymphatic Agents",
        "drug_subclasses": {
          "30": {
            "drug_subclass": "Interleukin-6 (IL-6) Antagonists"
          }
        }
      },
      "50": {
        "drug_class": "Prostaglandins",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Prostaglandins"
          }
        }
      },
      "65": {
        "drug_class": "Sclerosing Agents",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Sclerosing Agents"
          }
        }
      },
      "70": {
        "drug_class": "Peritoneal Dialysis Solutions",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Peritoneal Dialysis Solutions"
          }
        }
      },
      "72": {
        "drug_class": "Continuous Renal Replacement Therapy (CRRT) Solutions",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Continuous Renal Replacement Therapy (CRRT) Solutions"
          }
        }
      },
      "75": {
        "drug_class": "Irrigation Solutions",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Irrigation Solutions"
          }
        }
      },
      "80": {
        "drug_class": "Organ Preservation Solutions",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Organ Preservation Solutions"
          }
        }
      },
      "83": {
        "drug_class": "Thickened Products",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Thickened Products"
          }
        }
      },
      "85": {
        "drug_class": "Misc Natural Products",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Misc Natural Products"
          }
        }
      },
      "87": {
        "drug_class": "Homeopathic Products",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Homeopathic Products"
          }
        }
      },
      "00": {
        "drug_class": "Miscellaneous Therapeutic Classes",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Miscellaneous Therapeutic Classes"
          }
        }
      }
    },
    "drug_group": "MISCELLANEOUS THERAPEUTIC CLASSES"
  },
  "01": {
    "drug_classes": {
      "10": {
        "drug_class": "Natural Penicillins",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Natural Penicillins"
          }
        }
      },
      "20": {
        "drug_class": "Aminopenicillins",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Aminopenicillins"
          }
        }
      },
      "30": {
        "drug_class": "Penicillinase-Resistant Penicillins",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Penicillinase-Resistant Penicillins"
          }
        }
      },
      "99": {
        "drug_class": "Penicillin Combinations",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Penicillin Combinations"
          }
        }
      }
    },
    "drug_group": "PENICILLINS"
  },
  "02": {
    "drug_classes": {
      "10": {
        "drug_class": "Cephalosporins - 1st Generation",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Cephalosporins - 1st Generation"
          }
        }
      },
      "20": {
        "drug_class": "Cephalosporins - 2nd Generation",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Cephalosporins - 2nd Generation"
          }
        }
      },
      "30": {
        "drug_class": "Cephalosporins - 3rd Generation",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Cephalosporins - 3rd Generation"
          }
        }
      },
      "40": {
        "drug_class": "Cephalosporins - 4th Generation",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Cephalosporins - 4th Generation"
          }
        }
      },
      "50": {
        "drug_class": "Cephalosporins - 5th Generation",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Cephalosporins - 5th Generation"
          }
        }
      },
      "80": {
        "drug_class": "Cephalosporins - Siderophores",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Cephalosporins - Siderophores"
          }
        }
      },
      "99": {
        "drug_class": "Cephalosporin Combinations",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Cephalosporin Combinations"
          }
        }
      }
    },
    "drug_group": "CEPHALOSPORINS"
  },
  "03": {
    "drug_classes": {
      "10": {
        "drug_class": "Erythromycins",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Erythromycins"
          }
        }
      },
      "40": {
        "drug_class": "Azithromycin",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Azithromycin"
          }
        }
      },
      "50": {
        "drug_class": "Clarithromycin",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Clarithromycin"
          }
        }
      },
      "53": {
        "drug_class": "Fidaxomicin",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Fidaxomicin"
          }
        }
      }
    },
    "drug_group": "MACROLIDES"
  },
  "04": {
    "drug_classes": {
      "20": {
        "drug_class": "Aminomethylcyclines",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Aminomethylcyclines"
          }
        }
      },
      "30": {
        "drug_class": "Fluorocyclines",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Fluorocyclines"
          }
        }
      },
      "35": {
        "drug_class": "Glycylcyclines",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Glycylcyclines"
          }
        }
      },
      "99": {
        "drug_class": "Tetracycline Combinations",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Tetracycline Combinations"
          }
        }
      },
      "00": {
        "drug_class": "Tetracyclines",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Tetracyclines"
          }
        }
      }
    },
    "drug_group": "TETRACYCLINES"
  },
  "05": {
    "drug_classes": {
      "00": {
        "drug_class": "Fluoroquinolones",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Fluoroquinolones"
          }
        }
      }
    },
    "drug_group": "FLUOROQUINOLONES"
  },
  "07": {
    "drug_classes": {
      "00": {
        "drug_class": "Aminoglycosides",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Aminoglycosides"
          }
        }
      }
    },
    "drug_group": "AMINOGLYCOSIDES"
  },
  "08": {
    "drug_classes": {
      "00": {
        "drug_class": "Sulfonamides",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Sulfonamides"
          }
        }
      }
    },
    "drug_group": "SULFONAMIDES"
  },
  "09": {
    "drug_classes": {
      "99": {
        "drug_class": "Anti TB Combinations",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Anti TB Combinations"
          }
        }
      },
      "00": {
        "drug_class": "Antimycobacterial Agents",
        "drug_subclasses": {
          "00": {
            "drug_subclass": "Antimycobacterial Agents"
          }
        }
      }
    },
    "drug_group": "ANTIMYCOBACTERIAL AGENTS"
  }
}