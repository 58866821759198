import React, { useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Creators as FileConfigurationActions } from "../../../../redux/reducers/api/fileConfiguration";
import "./styles.scss";
import { Creators as AppActions } from "../../../../redux/reducers/app";
import { Button, Icon } from "semantic-ui-react";
import FliptPaDropdown from "../../../../components/form/fliptPaDropdown";
import FliptInput from "../../../../components/form/fliptInput";
import FliptTextarea from "../../../../components/form/fliptTextarea";
import FliptRadio from "../../../../components/form/fliptRadio";
import FliptLabel from "../../../../components/form/fliptLabel";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./styles.scss";
import Info from "./Info";
import moment from 'moment'
import { renderDynamicInput, sampleData } from "./utils";

const FileInformation = (props) => {
  const validations = props.state.validations;
  const validationResults = props.state.validationResults;
  const showErrors =
    validationResults && validationResults && !validationResults.isAllValid;
  const [startDate, setStartDate] = useState(new Date());
  const _updateFields = (el, dropdown) => {
    const { name, value } = dropdown || el.currentTarget;
    props.actions.updateFileInformationForm({ name, value });
  };
  const updateTime = (date) => {
    // setStartDate(date);
    let name = "file_expected_load_time";
    let value = date;
    props.actions.updateFileInformationForm({ name, value });
  };

  const { fileInformation, isEligibility, isOutbound } = props.state;
  const { clientType, dayList, TimeZoneList } =
    props.state.fileInformationOptions;
  const {
    file_type,
    file_name,
    file_frequency,
    file_frequency_notes,
    file_load_type,
    term_by_absence,
    period_selection,
    period_selection_other,
    member_matching_criteria,
    time_zone,
    file_description,
    file_expected_load_time,
    file_week_day,
    outbound_start_date,
    outbound_end_date,
    reader_service_validation_for_group,
    retro_dates_for_group_and_coverage
  } = fileInformation;
  const memberMatchingCriteriaStringified = JSON.stringify(member_matching_criteria || []);
  const renderAdditionalFields = () => {
    const { additionalFields } = props.state;
    if (additionalFields?.length == 0) return null;
    const rowDivider = 2;
    const fieldProps = {
      ...fileInformation,
      onChange: _updateFields,
      className: 'fields-header'
    };
    return (
      <>
        {Array.from(
          { length: Math.ceil(additionalFields.length / rowDivider) },
          (_, i) => (
            <div className="fields-container" key={`row${i}`}>
              {additionalFields
                .slice(i * rowDivider, (i + 1) * rowDivider)
                .map((movie) => renderDynamicInput(movie, fieldProps))}
            </div>
          )
        )}
      </>
    );
  };
  return (
    <>
      <div id="file-configuration">
        <div className="section-header">File Information</div>
        <div className="content shadow margin-bottom">
          <div className="fields-container">
            <div className="fields-header">
              <div className="field-label">
                <span>
                  File Name <span className="danger">*</span>
                </span>
                <Info
                  content="Production name of the file which will be provided"
                  position="top right"
                />
              </div>
              <FliptInput
                className="field-input"
                name="file_name"
                placeholder="File Name"
                value={file_name}
                onChange={_updateFields}
                error={
                  (showErrors &&
                    !validationResults?.file_name &&
                    validations.file_name?.errorMessage) ||
                  null
                }
              />
            </div>
            <div className="fields-header">
              <div className="field-label">
                <span>
                  File Load Type <span className="danger">*</span>
                </span>
                <Info
                  content="Defines if the file will be a full refresh file every processing cycle, or a partial delta change file"
                  position="top right"
                />
              </div>
              <div className="field-label-radio">
                <div className="divide-half-radio">
                  <FliptRadio
                    className="radio-input"
                    toggle={false}
                    name="file_load_type"
                    value={"Refresh"}
                    checked={file_load_type === "Refresh"}
                    onChange={_updateFields}
                  />
                  <FliptLabel
                    className="radio-label"
                    description=""
                    label="Full Refresh"
                  />
                  {showErrors && !validationResults?.file_load_type && (
                    <section className="flipt-input-error">
                      <em>
                        {validations.file_load_type?.errorMessage || null}
                      </em>
                    </section>
                  )}
                </div>
                <div className="divide-half-radio">
                  <FliptRadio
                    className="radio-input"
                    toggle={false}
                    name="file_load_type"
                    value={"Delta"}
                    checked={file_load_type === "Delta"}
                    onChange={_updateFields}
                  />
                  <FliptLabel
                    className="radio-label"
                    description=""
                    label="Delta"
                  />
                </div>
              </div>
              <div className="field-sub-hint">
                <div className="divide-half">
                  <span>A full replacement file is provided on every load</span>
                </div>
                <div className="field-sub-hint">
                  <span>The file only contains additions or changes</span>
                </div>
              </div>

            </div>
          </div>
          {isOutbound && (
            <div className="fields-container">
              <div className="fields-header" >
                <div className="field-label">
                  <span>
                    Coverage Period <span className="danger">*</span>
                  </span>
                  <Info
                    content="Coverage Period"
                    position="top right"
                  />
                </div>
                <div className="field-label">
                  <span className="field-label-hint">Start Date</span>
                  <FliptInput
                    type='date'
                    value={outbound_start_date}
                    name="outbound_start_date"
                    onChange={_updateFields}
                    placeholder="MM/DD/YYYY"
                    label="Start Date"
                    format='YYYY-MM-DD'
                    error={
                      (showErrors &&
                        !validationResults?.outbound_start_date &&
                        validations.outbound_start_date?.errorMessage) ||
                      null
                    }
                  />
                  <span className="field-label-hint">End Date</span>
                  <FliptInput
                    type='date'
                    value={outbound_end_date}
                    name="outbound_end_date"
                    onChange={_updateFields}
                    placeholder="MM/DD/YYYY"
                    label="End Date"
                    format='YYYY-MM-DD'
                    error={
                      (showErrors &&
                        !validationResults?.outbound_end_date &&
                        validations.outbound_end_date?.errorMessage) ||
                      null
                    }
                  />
                </div>
                {showErrors && (!validationResults?.outbound_start_date || !validationResults?.outbound_end_date) && (
                  <section className="flipt-input-error">
                    <em>
                      {validations.file_frequency?.errorMessage || null}
                    </em>
                  </section>
                )}
              </div>
            </div>
          )}

          {isEligibility && file_load_type === "Refresh" && (
            <div className="fields-container">
              <div className="fields-header">Other Settings</div>
              <div className="fields-header">
                <div className="field-label">
                  <span>
                    Term by Absence?<span className="danger">*</span>
                  </span>
                  <Info
                    content="How frequently the file will be sent for processing"
                    position="top right"
                  />
                </div>
                <div className="field-label-radio">
                  <div className="divide-half-radio">
                    <FliptRadio
                      className="radio-input"
                      toggle={false}
                      name="term_by_absence"
                      value={"Yes"}
                      checked={term_by_absence === "Yes"}
                      onChange={_updateFields}
                    />
                    <FliptLabel
                      className="radio-label"
                      description=""
                      label="Yes"
                    />
                    {isEligibility && file_load_type === "Refresh" && showErrors && !validationResults?.term_by_absence && (
                      <section className="flipt-input-error">
                        <em>
                          {validations.term_by_absence?.errorMessage || null}
                        </em>
                      </section>
                    )}
                  </div>
                  <div className="divide-half-radio">
                    <FliptRadio
                      className="radio-input"
                      toggle={false}
                      name="term_by_absence"
                      value={"No"}
                      checked={term_by_absence === "No"}
                      onChange={_updateFields}
                    />
                    <FliptLabel
                      className="radio-label"
                      description=""
                      label="No"
                    />
                  </div>

                </div>

                {term_by_absence === "Yes" && (
                  <>
                    <div className="field-label-radio">
                      <div className="divide-half-radio">
                        <FliptRadio
                          className="radio-input"
                          toggle={false}
                          name="period_selection"
                          value={"file_date"}
                          checked={period_selection === "file_date"}
                          onChange={_updateFields}
                        />
                        <FliptLabel
                          className="radio-label"
                          description=""
                          label="File Date"
                        />
                      </div>
                    </div>
                    <div className="field-label-radio">
                      <div className="divide-half-radio">
                        <FliptRadio
                          className="radio-input"
                          toggle={false}
                          name="period_selection"
                          value={"file_date_minus_1_day"}
                          checked={period_selection === "file_date_minus_1_day"}
                          onChange={_updateFields}
                        />
                        <FliptLabel
                          className="radio-label"
                          description=""
                          label="File Date Minus 1 Day"
                        />
                      </div>
                    </div>
                    <div className="field-label-radio">
                      <div className="divide-half-radio">
                        <FliptRadio
                          className="radio-input"
                          toggle={false}
                          name="period_selection"
                          value={"last_day_of_current_month"}
                          checked={period_selection === "last_day_of_current_month"}
                          onChange={_updateFields}
                        />
                        <FliptLabel
                          className="radio-label"
                          description=""
                          label="Last Day of Current Month"
                        />
                      </div>
                    </div>
                    <div className="field-label-radio">
                      <div className="divide-half-radio">
                        <FliptRadio
                          className="radio-input"
                          toggle={false}
                          name="period_selection"
                          value={"last_day_of_previous_month"}
                          checked={period_selection === "last_day_of_previous_month"}
                          onChange={_updateFields}
                        />
                        <FliptLabel
                          className="radio-label"
                          description=""
                          label="Last Day of Previous Month"
                        />
                      </div>
                    </div>
                    <div className="field-label-radio">
                      <div className="divide-half-radio">
                        <FliptRadio
                          className="radio-input"
                          toggle={false}
                          name="period_selection"
                          value={"other"}
                          checked={period_selection === "other"}
                          onChange={_updateFields}
                        />
                        <FliptLabel
                          className="radio-label"
                          description=""
                          label="Other"
                        />

                      </div>
                      {period_selection === "other" && (
                        <FliptInput
                          className="field-input-date"
                          type='date'
                          name="period_selection_other"
                          onChange={_updateFields}
                          value={period_selection_other}
                        />
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
          {isEligibility && (
            <>
              <div className="fields-container">
                <div className="fields-header" />
                <div className="fields-header">
                  <div className="field-label">
                    <span>
                      Member Matching Criteria<span className="danger">*</span>
                    </span>
                    <Info
                      content="How frequently the file will be sent for processing"
                      position="top right"
                    />
                  </div>
                  <div className="field-label-radio">
                    <div className="">
                      <FliptRadio
                        className="radio-input"
                        toggle={false}
                        name="member_matching_criteria"
                        value={["member_id", "person_code"]}
                        checked={memberMatchingCriteriaStringified === '["member_id","person_code"]'}
                        onChange={_updateFields}
                      />
                      <FliptLabel
                        className="radio-label"
                        description=""
                        label="Member ID and Person Code"
                      />
                    </div>
                  </div>
                  <div className="field-label-radio">
                    <div className="">
                      <FliptRadio
                        className="radio-input"
                        toggle={false}
                        name="member_matching_criteria"
                        value={["member_id", "date_of_birth"]}
                        checked={memberMatchingCriteriaStringified === '["member_id","date_of_birth"]'}
                        onChange={_updateFields}
                      />
                      <FliptLabel
                        className="radio-label"
                        description=""
                        label="Member ID and Date of Birth (if multiple use first name as tie breaker)"
                      />
                    </div>
                  </div>
                  <div className="field-label-radio">
                    <div className="">
                      <FliptRadio
                        className="radio-input"
                        toggle={false}
                        name="member_matching_criteria"
                        value={["first_name", "last_name", "date_of_birth"]}
                        checked={memberMatchingCriteriaStringified === '["first_name","last_name","date_of_birth"]'}
                        onChange={_updateFields}
                      />
                      <FliptLabel
                        className="radio-label"
                        description=""
                        label="First Name, Last Name and Date of Birth"
                      />
                    </div>
                  </div>
                  <div className="field-label-radio">
                    <div className="">
                      <FliptRadio
                        className="radio-input"
                        toggle={false}
                        name="member_matching_criteria"
                        value={["ssn"]}
                        checked={memberMatchingCriteriaStringified === '["ssn"]'}
                        onChange={_updateFields}
                      />
                      <FliptLabel
                        className="radio-label"
                        description=""
                        label="SSN"
                      />
                    </div>
                  </div>
                  <div className="field-label-radio">
                    <div className="">
                      <FliptRadio
                        className="radio-input"
                        toggle={false}
                        name="member_matching_criteria"
                        value={["ssn", "date_of_birth"]}
                        checked={memberMatchingCriteriaStringified === '["ssn","date_of_birth"]'}
                        onChange={_updateFields}
                      />
                      <FliptLabel
                        className="radio-label"
                        description=""
                        label="SSN and Date of Birth (if multiple use first name as tie breaker)"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="fields-container bordered-other-setting">
                <div className="fields-header" />
                <div className="fields-header">
                  <div className="field-label">
                    <span>
                      Should Group be validated<span className="danger">*</span>
                    </span>
                    <Info
                      content="Should the group be validated"
                      position="top right"
                    />
                  </div>
                  <div className="field-label-radio">
                    <div className="divide-half-radio">
                      <FliptRadio
                        className="radio-input"
                        toggle={false}
                        name="reader_service_validation_for_group"
                        value={"Yes"}
                        checked={reader_service_validation_for_group === "Yes"}
                        onChange={_updateFields}
                      />
                      <FliptLabel
                        className="radio-label"
                        description=""
                        label="Yes"
                      />
                      {isEligibility && file_load_type === "Refresh" && showErrors && !validationResults?.reader_service_validation_for_group && (
                        <section className="flipt-input-error">
                          <em>
                            {validations.reader_service_validation_for_group?.errorMessage || null}
                          </em>
                        </section>
                      )}
                    </div>
                    <div className="divide-half-radio">
                      <FliptRadio
                        className="radio-input"
                        toggle={false}
                        name="reader_service_validation_for_group"
                        value={"No"}
                        defaultChecked
                        checked={reader_service_validation_for_group === "No"}
                        onChange={_updateFields}
                      />
                      <FliptLabel
                        className="radio-label"
                        description=""
                        label="No"
                      />
                    </div>

                  </div>
                </div>
                <div className="fields-header">
                  <div className="field-label">
                    <span>
                      Accept retro dates for Group and Coverage<span className="danger">*</span>
                    </span>
                    <Info
                      content="Should retroactive dates be accepted for Group and Coverage"
                      position="top right"
                    />
                  </div>
                  <div className="field-label-radio">
                    <div className="divide-half-radio">
                      <FliptRadio
                        className="radio-input"
                        toggle={false}
                        name="retro_dates_for_group_and_coverage"
                        value={"Yes"}
                        checked={retro_dates_for_group_and_coverage === "Yes"}
                        onChange={_updateFields}
                      />
                      <FliptLabel
                        className="radio-label"
                        description=""
                        label="Yes"
                      />
                      {isEligibility && file_load_type === "Refresh" && showErrors && !validationResults?.retro_dates_for_group_and_coverage && (
                        <section className="flipt-input-error">
                          <em>
                            {validations.retro_dates_for_group_and_coverage?.errorMessage || null}
                          </em>
                        </section>
                      )}
                    </div>
                    <div className="divide-half-radio">
                      <FliptRadio
                        className="radio-input"
                        toggle={false}
                        name="retro_dates_for_group_and_coverage"
                        value={"No"}
                        defaultChecked
                        checked={retro_dates_for_group_and_coverage === "No"}
                        onChange={_updateFields}
                      />
                      <FliptLabel
                        className="radio-label"
                        description=""
                        label="No"
                      />
                    </div>

                  </div>
                </div>
              </div>
            </>

          )}
          <div className="fields-container">
            <div className="fields-header">
              <div className="field-label">
                <span>
                  File Frequency<span className="danger">*</span>
                </span>
                <Info
                  content="How frequently the file will be sent for processing"
                  position="top right"
                />
              </div>
              <div className="field-label-radio">
                <div className="divide-half-radio">
                  <FliptRadio
                    className="radio-input"
                    toggle={false}
                    name="file_frequency"
                    value={"Daily"}
                    checked={file_frequency === "Daily"}
                    onChange={_updateFields}
                  />
                  <FliptLabel
                    className="radio-label"
                    description=""
                    label="Daily"
                  />
                </div>
                <div className="divide-half-radio">
                  <FliptRadio
                    className="radio-input"
                    toggle={false}
                    name="file_frequency"
                    value={"Weekly"}
                    checked={file_frequency === "Weekly"}
                    onChange={_updateFields}
                  />
                  <FliptLabel
                    className="radio-label"
                    description=""
                    label="Weekly"
                  />
                </div>
              </div>
              <div className="field-label-radio">
                <div className="divide-half-radio">
                  <FliptRadio
                    className="radio-input"
                    toggle={false}
                    name="file_frequency"
                    value={"Monthly"}
                    checked={file_frequency === "Monthly"}
                    onChange={_updateFields}
                  />
                  <FliptLabel
                    className="radio-label"
                    description=""
                    label="Monthly"
                  />
                </div>
                <div className="divide-half-radio">
                  <FliptRadio
                    className="radio-input"
                    toggle={false}
                    name="file_frequency"
                    value={"RealTime"}
                    checked={file_frequency === "RealTime"}
                    onChange={_updateFields}
                  />
                  <FliptLabel
                    className="radio-label"
                    description=""
                    label="RealTime"
                  />
                </div>
              </div>
              <div className="field-label-radio">
                <div className="divide-half-radio">
                  <FliptRadio
                    className="radio-input"
                    toggle={false}
                    name="file_frequency"
                    value={"Other"}
                    checked={file_frequency === "Other"}
                    onChange={_updateFields}
                  />
                  <FliptLabel
                    className="radio-label"
                    description=""
                    label="Other"
                  />
                  {showErrors && !validationResults?.file_frequency && (
                    <section className="flipt-input-error">
                      <em>
                        {validations.file_frequency?.errorMessage || null}
                      </em>
                    </section>
                  )}
                </div>
                {file_frequency === "Other" && (
                  <div className="divide-half-radio">
                    <div className="field-label">
                      <span>Please describe</span>
                      <Info
                        content="For custom frequencies not listed, provide a brief description or request."
                      />
                    </div>
                    <FliptInput
                      className="field-input"
                      name="file_frequency_notes"
                      placeholder="Custom Frequency Description"
                      value={file_frequency_notes}
                      onChange={_updateFields}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="fields-header"></div>
          </div>
          {file_frequency === "Daily" && (
            <div className="fields-container">
              <div className="fields-header">
                {/* <div className="field-label">
                  <span>Expected Time of File</span>
                </div> */}
                <div className="field-label">
                  <div className="divide-half">
                    <div className="field-label">
                      <span>Expected Time of File</span>
                    </div>

                    <DatePicker
                      value={file_expected_load_time ? moment(file_expected_load_time).format('LT') : moment(new Date()).format('LT')}
                      onChange={updateTime}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                      placeholderText="HH:MM "
                    />
                  </div>
                  <div className="divide-half">
                    <div className="field-label">
                      <span>Time Zone</span>
                      <Info
                        content="Set your preferred time zone to help us know exactly when to expect your daily file(s)."
                      />
                    </div>
                    <FliptPaDropdown
                      stylized
                      name="time_zone"
                      onChange={_updateFields}
                      defaultValue={time_zone}
                      className="half-width bottom-margin"
                      value={time_zone}
                      options={TimeZoneList}
                      placeholder="Time Zone"
                      scrolling
                    />
                  </div>
                </div>
              </div>
              <div className="fields-header"></div>
            </div>
          )}
          {file_frequency === "Weekly" && (
            <div className="fields-container">
              <div className="fields-header">
                <div className="field-label">
                  <span>Week Day</span>
                </div>
                <FliptPaDropdown
                  stylized
                  name="file_week_day"
                  onChange={_updateFields}
                  simple
                  className="half-width"
                  value={file_week_day}
                  options={dayList}
                />
              </div>
              <div className="fields-header"></div>
            </div>
          )}
          {renderAdditionalFields()}
        </div>
      </div >
    </>
  );
};


const mapStateToProps = (state) => {
  const fileType = state.fileConfiguration.file_type;
  const fileDirection = state.fileConfiguration.file_direction;
  const isEligibility = fileType.toLowerCase() === "eligibility" && fileDirection?.toLowerCase() !== "outbound";
  return {
    state: {
      app: state.app,
      integrationMgmtDashboardData:
        state.integrationMgmt.integrationMgmtDashboardData,
      fileInformation: state.fileConfiguration.fileInformation,
      fileInformationOptions: state.fileConfiguration.options.fileInformation,
      isOutbound: fileDirection?.toLowerCase() === "outbound",
      isEligibility: isEligibility,
      validations: state.fileConfiguration.validations.fileInformation,
      validationResults:
        state.fileConfiguration.validationResults.fileInformation,
      additionalFields: state.fileConfiguration.additional_fields?.find(row => row.page === 'file_information')?.fields || [],
      // test data below, to be removed 
      //   additionalFields: sampleData?.find(row => row.page === 'file_information')?.fields || [],
    },
  }
}


const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
    ...FileConfigurationActions,
  };

  return {
    actions: bindActionCreators(allActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FileInformation);

