/* eslint-disable */
import React, { forwardRef } from 'react'
import { Dropdown } from 'semantic-ui-react'

import FliptLabel from './fliptLabel'

const FliptDropdown = forwardRef((props, ref) => {
  if ((!props?.name || !props?.options?.length) && !props?.forceRender) return <div />

  const {
    className,
    defaultValue,
    description,
    hidden,
    icon,
    label,
    options,
    placeholder,
    stylized,
    readOnly
  } = props
  const defaultvalue = options?.length === 1 ? options[0].value : defaultValue
  const placeHolder = placeholder || 'Select Option'
  const stylizedClassName = `stylized ${className || ''}`

  const stylizedField = () => (
    <div className={stylizedClassName}>
      <div className="flipt-label">
        <FliptLabel
          description={description}
          label={label}
        />
      </div>
      {!!icon && <div>{icon}</div>}
      <Dropdown search {...props} options={options} placeholder={placeHolder} hidden={hidden || false} defaultValue={defaultvalue} selection disabled={readOnly} />
    </div>
  )

  if (stylized) return stylizedField()

  return <Dropdown search {...props} options={options} placeholder={placeHolder} hidden={hidden || false} defaultValue={defaultvalue} selection ref={ref} disabled={readOnly} />
})

export default FliptDropdown
