import React from 'react'

import FliptInput from '../../../../../components/form/fliptInput'

export default (props) => {
  const { data } = props
  const {
    // pharmacyaddress1,
    // pharmacyaddress2,
    // pharmacycity,
    pharmacy_name,
    // pharmacyphone,
    service_provider_id,
    pharmacy_ncpdp,
    pharmacy_type
  } = data

  return (
    <div className="section">
      <h2>Pharmacy</h2>
      <div className="fields-container">
        <div className="fields">
          <FliptInput value={service_provider_id || ''} label="NPI" stylized />
          <FliptInput value={pharmacy_type || ''} label="Type" stylized />
        </div>
        <div className="fields">
          <FliptInput value={pharmacy_ncpdp} label="NCPDP" stylized />
        </div>
        <div className="fields">
          <FliptInput value={pharmacy_name || ''} label="Name" stylized />
        </div>
      </div>
    </div>
  )
}
