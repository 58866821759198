import Immutable from 'seamless-immutable'
import { createActions, createReducer } from 'reduxsauce'

export const INITIAL_STATE = Immutable({
  loading: false,
  rxreportLookupData: [],
  rxreportTotalRows: 0,
  rxreportPerPage: 20,
  rxreportSuggestData: [],
  rxreportUploadFileData:[]
})

export const clearRxreportLookupReducer = () => INITIAL_STATE

export const clearReducer = () => INITIAL_STATE

export const setRxreportLookupDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  rxreportLookupData: payload.data,
  rxreportTotalRows: payload.totalRows || 0,
})

export const setRxreportSuggestDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  rxreportSuggestData: payload.data,
})


export const setRxreportUploadFileDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  rxreportUploadFileData: payload.data,
})


export const setLoadingReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  loading: payload.data,
})

export const { Types, Creators } = createActions({
  clearRxreportLookupReducer: null,
  clearReducer: null,
  getRxreportLookupData: ['payload'],
  setRxreportLookupData: ['payload'],
  getRxreportSuggestData: ['payload'],
  setLoading: ['payload'],
  getRxreportUploadFileData: ['payload'],
  setRxreportSuggestData: ['payload'],
  setRxreportUploadFileData: ['payload'],

})

const HANDLERS = {
  [Types.CLEAR_RXREPORT_LOOKUP_REDUCER]: clearRxreportLookupReducer,
  [Types.CLEAR_REDUCER]: clearReducer,
  [Types.SET_LOADING]: setLoadingReducer,
  [Types.SET_RXREPORT_LOOKUP_DATA]: setRxreportLookupDataReducer,
  [Types.SET_RXREPORT_SUGGEST_DATA]: setRxreportSuggestDataReducer,
  [Types.SET_RXREPORT_UPLOAD_FILE_DATA]: setRxreportUploadFileDataReducer,
  
}

export default createReducer(INITIAL_STATE, HANDLERS)
