

import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Creators as PriorAuthorizationCreationActions } from '../../../../redux/reducers/api/priorAuthorizationManagement'
import XMLParser from 'react-xml-parser';
import './styles.scss'
import { Creators as AppActions } from '../../../../redux/reducers/app'
import FliptGrid from '../../../../components/fliptGrid'
import { IconClickRenderer } from '../../../../components/fliptGrid/cellRenderers'
import { LinkRenderer } from '../../../../components/fliptGrid/cellRenderers'
import FliptPaInput from '../../../../components/form/fliptPaInput';
import { convertCamelCaseToTitle, convertSnakeCaseToString } from '../../../../utils/utilities';
import { Icon } from 'semantic-ui-react';
import { convertDateTimeToEstStr } from '../../../../utils/utilities'
class Communications extends Component {
    constructor(props) {
        super(props)

        this.state = {
            xmlData: null,
            finalArray: []
        }
    }

    handleIdClicked = (data) => {
        const { actions } = this.props
        this.setState({ xmlData: null })
        actions.getMessageXml({ message_id: data.value })
    }

    componentDidUpdate(prevProps) {
        const { state } = this.props
        if (prevProps.state.msgXmlData !== state.msgXmlData) {
            this.setState({ xmlData: state.msgXmlData })
        }
    }
    renderXml() {
        const { xmlData } = this.state
        if (xmlData) {
            const xml = new XMLParser().parseFromString(xmlData);
            if (xml?.children?.length) {
                const bodyIndex = xml.children.findIndex((data) => data.name === 'Body')
                if (bodyIndex >= 0) {
                    const { children } = xml.children[bodyIndex]
                    if (children.length) {
                        const flatArray = this.getFlatArray(children, [])
                        const chunkedArray = _.chunk(flatArray, 4)
                        return (<div className='main-container'>

                            {chunkedArray.map((data) => {
                                return (
                                    <div className="fields-container">
                                        {
                                            data?.map((obj) => {
                                                return (<div className='' >
                                                    <FliptPaInput stylized disabled label={convertCamelCaseToTitle(obj.name)} value={obj.value} />
                                                </div>)
                                            })
                                        }
                                    </div>
                                )
                            })}
                        </div>)
                    }
                }
            }
        }
    }

    getFlatArray(xmlArray, finalArray) {
        xmlArray.forEach((xmlData) => {
            const { name, value, children } = xmlData
            finalArray.push({ name, value })
            if (Array.isArray(children)) {
                this.getFlatArray(children, finalArray)
            }
        })
        return finalArray
    }

    _copyNote = (gridProps) => {
        const { data } = gridProps
        navigator.clipboard.writeText(data.note)
    }
    closeModal = () => {
        const { actions } = this.props
        actions.toggleSideDrawer()
    }
    render() {
        const { audit_trail, paCaseDetails } = this.props
        const { xmlData } = this.state

        const claimHistoryHeader = ['message_id', 'user', 'provider_message', 'request_type', 'resend_questionnaire',
            'additional_question', 'audit_trail_status', 'begin_date_time', 'end_date_time', 'deadline_for_reply', 'communication_method',
            'response_status', 'field_changed', 'old_value', 'new_value']

        let cellRendererParams = {
            message_id: {
                width: '120px',
            },
            user: {
                width: '120px',
            },
            request_type: {
                width: '120px',
            },
            resend_questionnaire: {
                width: '120px',
            },
            audit_trail_status: {
                width: '120px',
            },
            begin_date_time: {
                valueFormatter: (params) => {
                    return convertDateTimeToEstStr(params?.value)
                },
                width: '120px',
            },
            end_date_time: {
                valueFormatter: (params) => {
                    return convertDateTimeToEstStr(params?.value)
                },
                width: '120px',
            },
            deadline_for_reply: {
                width: '120px',
            },
            response_status: {
                width: '120px',
            },
            provider_message: {
                tooltipField: 'provider_message',
                cellRenderer: IconClickRenderer,
                searchArgs: {
                    iconName: 'copy',
                    iconOnClick: this._copyNote,
                    fieldToRenderInCell: 'provider_message',
                }
            },
            additional_question: {
                tooltipField: 'additional_question',
                cellRenderer: IconClickRenderer,
                searchArgs: {
                    iconName: 'copy',
                    iconOnClick: this._copyNote,
                    fieldToRenderInCell: 'additional_question',
                }
            },
        }

        if (paCaseDetails.intake_type === 'SURESCRIPTS') {
            cellRendererParams = {
                message_id: {
                    cellRenderer: LinkRenderer,
                    onCellClicked: this.handleIdClicked,
                    searchArgs: {}
                },
            }
        }

        return (
            <div id="Communications">
                <div className='header-container'>
                    <span className='header-text'>Audit Trail</span>
                    <Icon onClick={() => this.closeModal()} name='close' color='grey' />
                </div>
                <div className='content'>
                    <FliptGrid
                        data={audit_trail || []}
                        headers={claimHistoryHeader}
                        cellRendererParams={cellRendererParams}
                    />
                </div>
                {xmlData && <div className='devider' />}
                {xmlData ? <>
                    <span className='msg-header'>Message XML Information</span>
                    <div className='xml-content'>{this.renderXml()}</div>
                </> : null}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    state: {
        msgXmlData: state.priorAuthorizationCreation.msgXmlData
    },
})

const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...PriorAuthorizationCreationActions,
        ...AppActions,
    }

    return {
        actions: bindActionCreators(allActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Communications)
