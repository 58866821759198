import Immutable from 'seamless-immutable'
import { createActions, createReducer } from 'reduxsauce'

export const INITIAL_STATE = Immutable({
  loading: false,
  prescriberSearchData: [],
  prescriberUpdateData: [],
  prescriberSearchFailed: false
})

export const clearPrescriberDataReducer = () => INITIAL_STATE

export const setPrescriberSearchDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  prescriberSearchData: payload,
})

export const setPrescriberSearchfailedReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  prescriberSearchFailed: payload,
})

export const setPrescriberUpdateDataReducer = (state = INITIAL_STATE, { payload }) => ({
    ...state,
    prescriberUpdateData: payload,
})

export const { Types, Creators } = createActions({
  clearPrescriberDataReducer: null,
  searchPrescriber: ['payload'],
  editPrescriber: ['payload'],
  setPrescriberSearchData: ['payload'],
  setPrescriberSearchFailed: ['payload'],
})

const HANDLERS = {
  [Types.CLEAR_PRESCRIBER_DATA_REDUCER]: clearPrescriberDataReducer,
  [Types.SET_PRESCRIBER_SEARCH_DATA]: setPrescriberSearchDataReducer,
  [Types.SET_PRESCRIBER_SEARCH_FAILED]: setPrescriberSearchfailedReducer,
}

export default createReducer(INITIAL_STATE, HANDLERS)
