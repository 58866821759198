import Immutable from 'seamless-immutable'
import { createActions, createReducer } from 'reduxsauce'

export const INITIAL_STATE = Immutable({
  loading: false,
  pharmacyLookupData: [],
  pharmacyTotalRows: 0,
  pharmaciesPerPage: 20,
  pharmacySuggestData: [],
  pharmacyNpiLookupData: { pricing: [], exclusions: [] },
  pharmacyLookupFailed: false
})

export const clearPharmacyLookupReducer = (state = INITIAL_STATE) => ({
  ...state,
  pharmacyLookupData: [],
  pharmacyTotalRows: 0,
  pharmacyLookupFailed: false,
})

export const clearReducer = () => INITIAL_STATE

export const setPharmacyLookupDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  pharmacyLookupData: payload.data,
  pharmacyTotalRows: payload.totalRows || 0,
})

export const setPharmacyNpiLookupDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  pharmacyNpiLookupData: payload,
})



export const setPharmacySuggestDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  pharmacySuggestData: payload.data,
})

export const setPharmacyLookupFailedReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  pharmacyLookupFailed: payload,
})

export const setLoadingReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  loading: payload.data,
})

export const { Types, Creators } = createActions({
  clearPharmacyLookupReducer: null,
  clearReducer: null,
  getPharmacyLookupData: ['payload'],
  getPharmacyNpiLookupData: ['payload'],
  setPharmacyLookupData: ['payload'],
  getPharmacySuggestData: null,
  setLoading: ['payload'],
  setPharmacySuggestData: ['payload'],
  setPharmacyLookupFailed: ['payload'],
  setPharmacyNpiLookupData: ['payload'],
})

const HANDLERS = {
  [Types.CLEAR_PHARMACY_LOOKUP_REDUCER]: clearPharmacyLookupReducer,
  [Types.CLEAR_REDUCER]: clearReducer,
  [Types.SET_LOADING]: setLoadingReducer,
  [Types.SET_PHARMACY_LOOKUP_DATA]: setPharmacyLookupDataReducer,
  [Types.SET_PHARMACY_NPI_LOOKUP_DATA]: setPharmacyNpiLookupDataReducer,
  [Types.SET_PHARMACY_SUGGEST_DATA]: setPharmacySuggestDataReducer,
  [Types.SET_PHARMACY_LOOKUP_FAILED]: setPharmacyLookupFailedReducer,

}

export default createReducer(INITIAL_STATE, HANDLERS)
