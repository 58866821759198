import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Breadcrumb } from 'semantic-ui-react'
import { bindActionCreators } from 'redux'
import { connect, useSelector } from 'react-redux'

import { Creators as AppActions } from '../../redux/reducers/app'

const generateBc = (path, menuData, bcArr) => {
  if (!menuData) return false
  let found = false
  menuData.forEach((menu) => {
    const bcGenerated = menu.path !== '/miscellaneous' && generateBc(path, menu.children, bcArr)
    if (menu.path === path || bcGenerated) {
      bcArr.unshift({ id: menu.name, bcPath: menu.path })
      found = true
    }
  })

  return found
}

function BreadCrumbs({ match: { path }, changeToDrugList, isUpdate, editable, claimSummary }) {
  const history = useHistory()
  const menuData = useSelector((state) => state?.app?.menu_data)
  const [bcData, setBcData] = useState()
  useEffect(() => {
    const arr = []
    const found = generateBc(path, menuData, arr)
    arr.unshift({ id: 'FAST', bcPath: '/home' })
    if (found) setBcData(arr)
  }, [menuData, path])
  return (
    <div>
      {
        !!bcData?.length && (
          <Breadcrumb size="small">
            {
              bcData?.map(({ id, bcPath }, idx) => (
                <React.Fragment key={`${id}-${idx}`}>
                  <Breadcrumb.Section
                    active={bcPath === path}
                    onClick={bcPath === path ? null : claimSummary ? () => history.goBack() : () => history.push(bcPath)}
                    key={`${id}-${idx}`}
                  >
                    {/* As we are using same screens for view/update/add group we need to make the breadcrum look same */}
                    {changeToDrugList && id === 'Formulary Tier' ? 'Drug Lists' :
                      id === 'Add/Edit Group' ? !editable ? 'View Group' : isUpdate ? 'Edit Group' : 'Add Group' : id}
                  </Breadcrumb.Section>
                  {
                    idx !== bcData.length - 1 && (
                      <Breadcrumb.Divider />
                    )
                  }
                </React.Fragment>
              ))
            }
          </Breadcrumb>
        )
      }
    </div>
  )
}

const mapStateToProps = (state) => ({
  state: {
    app: state.app,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BreadCrumbs)
