/* eslint-disable */
export default {
  id: {
    type: 'input',
    disabled: false,
  },
  name: {
    type: 'input',
    disabled: false,
  },
  id_type: {
    type: 'dropdown',
    disabled: false,
    options: [],
  },
  edit_type: {
    type: 'dropdown',
    disabled: false,
    options: [{ key: 'lock-in', text: 'lock-in', value: 'lock_in' }, { key: 'lock-out', text: 'lock-out', value: 'lock_out' }],
  },
  from_date: {
    type: 'input',
    disabled: false,
  },
  to_date: {
    type: 'input',
    disabled: false,
  },
}
