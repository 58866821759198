import React, { useEffect, useMemo, useRef } from 'react'
import moment from 'moment'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Creators as AppActions } from '../../../redux/reducers/app'
import { Creators as EmergencyOverrideActions } from '../../../redux/reducers/api/emergencyOverride'

import { Icon, Table } from "semantic-ui-react";

import FliptButton from '../../../components/v2/fliptButton'
import FliptGrid from '../../../components/fliptGrid'
import { Creators as GlobalEditsActions } from '../../../redux/reducers/api/globalEdits'
import { LinkRenderer } from '../../../components/fliptGrid/cellRenderers'
function EmergencyOverridesDashboard(props) {
    const { actions, state , title, configuration_type} = props;
    const filterData  = configuration_type == 'Benefit' ? state.globalEdits : state.emergencyOverride;
    const { dashboard } = filterData;
    const planGridRef = useRef(null);
    const defaultColDef = useMemo(() => {
        return {
            resizable: true,
            outerHeight: 120,
            menuTabs: ['filterMenuTab'],
        }
    }, [])

    const handleFetchConfig = (row) => {
        const { data } = row || {};
        const { configuration_id } = data || {};
        if(configuration_type == 'Benefit'){
            actions.getGlobalEditConfiguration({ configuration_id })
        }else{
            actions.getEmergencyOverrideConfiguration({ configuration_id })
        }
    }
    const defaultHeaders = ['configuration_name', 'effective_begin_date', 'effective_term_date', 'status', 'carrier', 'account', 'group']
    const defaultCellRendererParams = {

        configuration_name: {
            cellRenderer: LinkRenderer,
            searchArgs: {},
            onCellClicked: handleFetchConfig,
          },
        effective_begin_date: {
            header: 'Effective Date',
        },
        effective_term_date: {
            header: 'Termination Date',
        },
        status: {
            header: 'Status',
        },
        carrier: {
            header: 'Carrier',
        },
        account: {
            header: 'Account',
        },
        group: {
            header: 'Group',
        },
    }
    useEffect(() => {
        if(configuration_type == 'Benefit'){
            actions.getGlobalEditsDashboard()
        }else{
            actions.getEmergencyOverrideDashboard()
        }
    }, [state.app.menu_data])

    const drillHierarchy = (hierarchy) => {
        let carrier = 'All'
        let account = 'All'
        let group = 'All'
        if (hierarchy?.length > 0) {
            const topLevelCarrier = (hierarchy[0]?.organization?.[0]?.client?.[0]?.carrier || hierarchy[0]?.children?.[0]?.children?.[0]?.children)
            if (topLevelCarrier?.length > 0) {
                carrier = topLevelCarrier[0]?.name
                const topLevelAccounts = (topLevelCarrier[0]?.account || topLevelCarrier[0]?.children)
                if (topLevelAccounts.length > 0) {
                    const { accounts, groups } = topLevelAccounts?.reduce((res, account) => {
                        res.accounts?.push(account.name)
                        const groupList = account.group || account.children;
                        groupList?.forEach((group) => {
                            res.groups?.push(group.name)
                        })
                        return res
                    }, { accounts: [], groups: [] })
                    account = accounts.join(', ') || 'All'
                    group = groups.join(', ') || 'All'
                }
            }
        }
        return [carrier, account, group];
    }
    const dashBoardData = dashboard?.map((row) => {
        const [carrier, account, group] = drillHierarchy(row.hierarchy);
        row.carrier = carrier;
        row.account = account;
        row.group = group;
        row.effective_begin_date = row.effective_begin_date ? moment(row.effective_begin_date, "YYYY-MM-DD").format("MMM D, YYYY") : '';
        row.effective_term_date = row.effective_term_date ? moment(row.effective_term_date, "YYYY-MM-DD").format("MMM D, YYYY") : '';
        return row;
    })

    return (
        <div>
            <header className="dashboard-header">
                <h1 className='page-h1'>
                    {title}
                </h1>
                <section className='dashboard-button-container'>
                    <FliptButton
                        className='button-primary-inverted disabled'
                    >
                        <Icon name="search" size="" className="" />
                    </FliptButton>
                    <FliptButton
                        className='button-primary-inverted disabled'
                        name="Filter"
                    >
                        <Icon name="sort amount down" />
                    </FliptButton>
                    <FliptButton
                        className='button-primary-inverted disabled'
                        name="Export"
                    >
                        <Icon name="cloud upload" />
                    </FliptButton>
                    <FliptButton
                        className='button-primary'
                        name="Create New"
                        onClick={() => {
                            actions.returnToDashboard();
                            actions.backToDashboard();
                            actions.setPage('create-override')
                        }}
                    />
                </section>
            </header>
            {/*
            <section id='emergency-override-dashboard'>
                <Table className='eo-table' style={{ marginTop: '0px' }}>
                    <Table.Header className='eo-table-header'>
                        <Table.Row>
                            <Table.HeaderCell className='eo-table-header-cell' />
                            <Table.HeaderCell className='eo-table-header-cell'>
                                <section className='eo-cell-wrapper'>
                                    <span>Name</span>
                                    <Icon name="arrow down" />
                                </section>
                            </Table.HeaderCell>
                            <Table.HeaderCell className='eo-table-header-cell'>
                                <section className='eo-cell-wrapper'>
                                    <span>Effective Date</span>
                                    <Icon name="arrow down" />
                                </section>
                            </Table.HeaderCell>
                            <Table.HeaderCell className='eo-table-header-cell'>
                                <section className='eo-cell-wrapper'>
                                    <span>Termination Date</span>
                                    <Icon name="arrow down" />
                                </section>
                            </Table.HeaderCell>
                            <Table.HeaderCell className='eo-table-header-cell'>
                                <section className='eo-cell-wrapper'>
                                    <span>Status</span>
                                    <Icon name="arrow down" />
                                </section>
                            </Table.HeaderCell>
                            <Table.HeaderCell className='eo-table-header-cell'>
                                <section className='eo-cell-wrapper'>
                                    <span>Carrier</span>
                                    <Icon name="arrow down" />
                                </section>
                            </Table.HeaderCell>
                            <Table.HeaderCell className='eo-table-header-cell'>
                                <section className='eo-cell-wrapper'>
                                    <span>Account</span>
                                    <Icon name="arrow down" />
                                </section>
                            </Table.HeaderCell>
                            <Table.HeaderCell className='eo-table-header-cell'>
                                <section className='eo-cell-wrapper'>
                                    <span>Group</span>
                                    <Icon name="arrow down" />
                                </section>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {dashboard?.map((row) => {
                            const [carrier, account, group] = drillHierarchy(row.hierarchy);
                            return (
                                <Table.Row key={row.configuration_id} className='eo-table-row'>
                                    <Table.Cell className='eo-table-cell' />
                                    <Table.Cell
                                        className='eo-table-cell clickable'
                                        onClick={() => actions.getEmergencyOverrideConfiguration({ configuration_id: row.configuration_id })}
                                    >
                                        {row.configuration_name}
                                    </Table.Cell>
                                    <Table.Cell className='eo-table-cell'>
                                        {row.effective_begin_date
                                            ? moment(row.effective_begin_date, "YYYY-MM-DD").format("MMM D, YYYY")
                                            : "-"}
                                    </Table.Cell>
                                    <Table.Cell className='eo-table-cell'>
                                        {row.effective_term_date
                                            ? moment(row.effective_term_date, "YYYY-MM-DD").format("MMM D, YYYY")
                                            : "-"}
                                    </Table.Cell>
                                    <Table.Cell className='eo-table-cell'>{row.status}</Table.Cell>
                                    <Table.Cell className='eo-table-cell'>{carrier}</Table.Cell>
                                    <Table.Cell className='eo-table-cell wrap'>{account}</Table.Cell>
                                    <Table.Cell className='eo-table-cell wrap'>{group}</Table.Cell>
                                </Table.Row>
                            )
                        })}
                        {dashboard?.length === 0 ? (
                            <Table.Row>
                                <Table.Cell colSpan={8}>No data found</Table.Cell>
                            </Table.Row>) : null}
                    </Table.Body>
                </Table>

            </section>
            */}

            <section className='dashboard-grid'>
                <FliptGrid
                    defaultColDef={defaultColDef}
                    agGridRef={planGridRef}
                    headers={[...defaultHeaders]}
                    data={dashBoardData || []}
                    onSelectionChanged={(data) => _onSelectionChanged(data)}
                    cellRendererParams={{ ...defaultCellRendererParams }}
                />
            </section>
            <footer className='footer-container'>
                <section />
                <section />
            </footer>
        </div>
    )
}

const mapStateToProps = (state) => ({
    state: {
        app: state.app || {},
        user: state.user,
        emergencyOverride: state.emergencyOverride,
        globalEdits: state.globalEdits, 
    },
})


const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...AppActions,
        ...EmergencyOverrideActions,
        ...GlobalEditsActions,
    }

    return {
        actions: bindActionCreators(allActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EmergencyOverridesDashboard);
