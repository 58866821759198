export const lobTypes = [
    {
        key: 0,
        text: 'Commercial',
        value: 'Commercial'
    },
    {
        key: 1,
        text: 'Commercial-Exchange',
        value: 'Commercial-Exchange'
    },
    {
        key: 2,
        text: 'RDS',
        value: 'RDS'
    },
    {
        key: 3,
        text: 'Medicare D',
        value: 'Medicare D'
    },
    {
        key: 4,
        text: 'Medicare - EGWP',
        value: 'Medicare - EGWP'
    },
    {
        key: 5,
        text: 'Medicaid',
        value: 'Medicaid'
    },
    {
        key: 6,
        text: 'Workers Compensation',
        value: 'Workers Compensation'
    },
    {
        key: 7,
        text: 'Other',
        value: 'Other'
    }
]