import moment from "moment"

export const generateClaimDictionary = (memberData, drugData, prescriberData, pharmacyData, prescriptionForm, form) => {
  const prescriber_phone = prescriberData.phone_number || prescriberData.provider_business_practice_location_address_telephone_number
  // this dictionary is created based on ncpdpd0 skeleton
  // claim adjustment service will change the dates to python date objects
  // and directly send it to claim processing 
  const claimDictionary = {
    header: {
      bin_number: '610740',
      version: 'D0',
      transaction_code: 'B1',
      processor_control_number: 'FLIPT',
      transaction_count: '1',
      service_provider_id_qualifier: '01',
      service_provider_id: pharmacyData?.pharmacynpi,
      date_of_service: moment(prescriptionForm.date_of_service).format('YYYYMMDD'),
      software_vendor_certification_id: 'XXX0100AAA'
    },
    insurance_segment: {
      cardholder_id: memberData?.member_id,
      cardholder_first_name: memberData?.first_name,
      cardholder_last_name: memberData?.last_name,
      group_id: memberData?.group,
      person_code: memberData?.person_code,
      patient_relationship_code: memberData.relationship_code || "0",
    },
    patient_segment: {
      date_of_birth: memberData.date_of_birth ? moment(memberData.date_of_birth, 'MM/DD/YYYY').format('YYYYMMDD') : '',
      patient_gender_code: '1',
      patient_first_name: memberData?.first_name,
      patient_last_name: memberData?.last_name,
      place_of_service: '01'
    },
    transactions: [
      {
        claims_segment: {
          prescription_reference_number_qualifier: '1',
          prescription_reference_number: prescriptionForm.prescription_reference_number,
          product_id_qualifier: '03',
          product_id: drugData?.ndc,
          quantity_dispensed: prescriptionForm.quantity,
          fill_number: prescriptionForm.fill_number,
          days_supply: parseInt(prescriptionForm.days_supply),
          compound_code: prescriptionForm.compound_code,
          date_prescription_written: moment(prescriptionForm.date_prescription_written).format('YYYYMMDD'),
          prescription_origin_code: '01',
          submission_clarification_code_count: 1,
          submission_clarification_code_list: [
            {
              submission_clarification_code: '00'
            }
          ],
          other_coverage_code: form.other_coverage_code,
        },
        pricing_segment: {
          ingredient_cost_submitted: parseFloat(form.ingredient_cost_submitted),
          gross_amount_due: parseFloat(form.gross_amount_due),
        },
        prescriber_segment: {
          prescriber_id_qualifier: '01',
          prescriber_id: prescriberData.npi,
          prescriber_last_name: prescriberData.last_name || prescriberData.provider_last_name_legal_name,
          prescriber_first_name: prescriberData.first_name || prescriberData.provider_first_name,
          prescriber_phone: parseInt(prescriber_phone)
        }
      }
    ]
  }

  // add optional pricing fields if entered by user
  const optionalPricingFields = ['flat_sales_tax_amount_submitted', 'percentage_sales_tax_amount_submitted',
    'percentage_sales_tax_rate_submitted', 'incentive_amount_submitted', 'dispensing_fee_submitted',
    'patient_paid_amount_submitted', 'usual_and_customary_charge']

  optionalPricingFields.forEach(key => {
    let str = form[key]?.trim()
    if (str) {
      claimDictionary.transactions[0].pricing_segment[key] = parseFloat(str)
    }
  });

  if (form.percentage_sales_tax_basis_submitted) {
    claimDictionary.transactions[0].pricing_segment['percentage_sales_tax_basis_submitted'] = form.percentage_sales_tax_basis_submitted
  }

  // cob_segment 
  if (form.other_payer_patient_responsibility_amount) {
    claimDictionary["transactions"][0]["cob_segment"] = {
      payment_count: 1,
      payments_list: [
        {
          other_payer_patient_responsibility_amount_count: 1,
          other_payer_patient_responsibility_amount_list: [
            {
              other_payer_patient_responsibility_amount_qualifier: "06",
              other_payer_patient_responsibility_amount: parseFloat(form.other_payer_patient_responsibility_amount),
            }
          ],
        }
      ],
    }
  } else if (form.other_payer_amount_paid) {
    claimDictionary["transactions"][0]["cob_segment"] = {
      payment_count: 1,
      payments_list: [
        {
          other_payer_amount_paid_count: 1,
          other_payer_amount_paid_list: [
            {
              other_payer_amount_paid_qualifier: "07",
              other_payer_amount_paid: parseFloat(form.other_payer_amount_paid),
            }
          ],
        }
      ],
    }
  }

  return claimDictionary
}
