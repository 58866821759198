import React from 'react'
import { Link } from 'react-router-dom'

export default (props) => {
  const { active } = props
  const search = ''

  return (
    <div>
      <Link
        to={{
          pathname: '/create-questionnaire',
          search,
        }}
        className={active === 'createQuestionnaire' ? 'active' : ''}
      >
        Create Questionnaire
      </Link>
      <Link
        to={{
          pathname: '/upload-questionnaire',
          search,
        }}
        className={active === 'questionnaireImport' ? 'active' : ''}
      >
        Import Questionnaire
      </Link>
    </div>
  )
}
