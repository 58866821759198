import React, { PureComponent, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'

import './styles.scss'
import BreadCrumb from '../../../components/breadCrumb'
import Config from '../../../config'
import FliptButton from '../../../components/form/fliptButton'
import FliptGrid from '../../../components/fliptGrid'
import CopyRebateObject from './posRebate/copyRebateObject';
import { Creators as AppActions } from '../../../redux/reducers/app'
import { Creators as NavigationActions } from '../../../redux/reducers/navigation'
import { Creators as RebateActions } from '../../../redux/reducers/api/posRebate'

import { Creators as RPMActions } from '../../../redux/reducers/rpm'
import { LinkRenderer, ActionPopupMenuRenderer, ColorCodedStatusRenderer } from '../../../components/fliptGrid/cellRenderers'
import { convertDateTimeToDate, filterRowData, sortByName } from '../../../utils/utilities'

const POSRebateManagement = (props) => {

  const [showPopupFlag, setShowPopupFlag] = useState(false);
  const [record, setRecord] = useState({});

  const { state, actions } = props
  const { posRebateDrafts } = state;

  useEffect(() => {
    setTimeout(
      () => actions.getAllRebateData(),
      Config.COMPONENT_DATA_LOAD_TIME
    );
  }, []);

  const sendForReview = (form) => {
    const payload = {
      doc_id: form.doc_id,
      module: 'POS_REBATE',
    }

    actions.rpmSendForReview(payload);
    setTimeout(
        () => actions.getAllRebateData(),
        Config.COMPONENT_DATA_LOAD_TIME
      );
  }

  const checkActionType = (rowdata) => {
    if (rowdata && rowdata['qsArgs']) {
      const doc_id = rowdata['doc_id'];
      const action = rowdata['qsArgs']['selected_action'];

      let data = posRebateDrafts.filter(obj => { return obj.id === doc_id })
      setRecord(data);
      if (action === 'copy_object') {
        setShowPopupFlag(true);
      } else if (action === 'send_for_review') {
        sendForReview(rowdata);
      }
    }
  }

  const headers = [
    'actions', 'doc_id', 'name', 'status', 'create_date', 'author', 'last_updated',
  ]
  const headerMapping = {
    module_name: 'name',
    last_updated_by: 'author',
  }

  const cellRendererParams = {
    name: {
      cellRenderer: LinkRenderer,
      path: '/pos-rebate',
      searchArgs: { doc_id: 'doc_id' },
      state: { editMode: true },
    },
    status: {
      cellRenderer: ColorCodedStatusRenderer,
    },
    actions: {
      cellRenderer: ActionPopupMenuRenderer,
      searchArgs: {
        onClick: (rowdata) => checkActionType(rowdata),
        path: '/pos-rebate-creation-management',
        qsArgs: { doc_id: 'doc_id', action: 'COPY_OBJECT', module: 'POS_REBATE' }
      }
    },
    doc_id: {
      hide: true,
    },
  }
  const gridData = posRebateDrafts && posRebateDrafts.length ? posRebateDrafts.filter((d) => d.module === 'POS_REBATE')
    .map((d) => (
      {
        ...filterRowData({
          ...d,
          doc_id: d.id,
          status: d.status,
          create_date: moment(d.create_date).format('MM-DD-YYYY'),
          last_updated: convertDateTimeToDate(d.last_updated),
        }, headers, headerMapping),
      })) : []

  return (
    <div id="posRebateManagement">
      <BreadCrumb {...props} />
      <div className="buttonContainer" align="right">
        <Link to="/pos-rebate">
          <FliptButton
            name="Create New POS Rebate"
            className="primary searchButton"
          />
        </Link>
      </div>
      <div className="content">
        <FliptGrid
          data={gridData && gridData.length > 0 ? gridData.sort(sortByName) : []}
          headers={headers}
          cellRendererParams={cellRendererParams}
        />
      </div>
      {showPopupFlag && <CopyRebateObject data={record} showTabFlag={showPopupFlag} setShowTabFlag={setShowPopupFlag} />}
    </div>
  )
}


const mapStateToProps = (state) => ({
  state: {

    posRebateDrafts: state.rpm.documents,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
    ...NavigationActions,
    ...RebateActions,
    ...RPMActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(POSRebateManagement)
