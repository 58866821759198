import React, { Component, useState } from 'react'
import { Icon, Popup } from 'semantic-ui-react'
import { convertToCurrency } from '../../../utils/utilities';

import './styles.scss'

const ToggleItem = ({ title, priceText, content, children }) => {
  const [toggleThisElement, setToggleThisElement] = useState(false);
  return (
    <div>
      <div className="price-content">
        <span className="price-title">
        <Icon name={toggleThisElement ? "minus" : "plus"} className="toggle-icon" onClick={() => setToggleThisElement((prev) => !prev)}/>
          {title}
          <Popup content={content} trigger={<Icon name="info circle" className="info-icon" />} />
        </span>
        <span className="price-text">{priceText}</span>
      </div>
      <div className="sub-content">
        {toggleThisElement && children}
      </div>
    </div>
  );
};
class PriceModal extends Component {
  handleClick = () => {
    const { hidePriceModal } = this.props

    hidePriceModal()
  }
  render() {
    const { priceData } = this.props
    let {
      address,
      drug_cost,
      employer_cost,
      deductible_accumulator_amount,
      drug_copay,
      drug_coinsurance,

      retail_penalty,
      alternate_penalty,
      member_choice_penalty_amount,
      rbp_penalty,

      retail_reward,
      alternate_reward,
      incentive_credit_applied,
      rbp_reward,

      drug_name,
      employee_opc,
      gpi,
      name,
      total_payment,
      total_reward,
    } = priceData
    if (typeof member_choice_penalty_amount != 'number' && isNaN(member_choice_penalty_amount)) {
      member_choice_penalty_amount = parseFloat(member_choice_penalty_amount.replace('$',''))
    }
    const other_costs = parseFloat(retail_penalty) + member_choice_penalty_amount + parseFloat(alternate_penalty) + parseFloat(rbp_penalty)
    const costs = parseFloat(deductible_accumulator_amount) + parseFloat(drug_copay) + parseFloat(drug_coinsurance) + parseFloat(other_costs)
    const savings_credits = parseFloat(retail_reward) + parseFloat(alternate_reward) + parseFloat(incentive_credit_applied) + parseFloat(rbp_reward)
    const tp = total_payment
    const tr = total_reward
    const savingsCopay = Math.max((tp - tr), 0)

    return (
      <div className="popup-wrap">
        <div className="popup-block price-block">
          <div>
            <h1>{name}</h1>
            <h2>{address}</h2>
          </div>
          <Icon name="close" className="close-icon" onClick={this.handleClick} />

          <div>
            {drug_name}
          </div>
          <div>
            {gpi}
          </div>
          <h2 className="modal_header">Price Breakdown</h2>
          <div className="price-table-wrap">
            <div className="price-content">
              <span className="price-title">
                Drug Cost
                <Popup content="The total amount that a pharmacy charges for a drug. (Includes out of pocket and what the plan pays)" trigger={<Icon name="info circle" className="info-icon" />} />
              </span>
              <span className="price-text">
                {convertToCurrency(drug_cost)}
              </span>
            </div>
            <div className="price-content">
              <span className="price-title">
                Plan Pays
                <Popup content="The total amount of the drug cost that is payed by plan sponsor (your company) " trigger={<Icon name="info circle" className="info-icon" />} />
              </span>
              <span className="price-text">{convertToCurrency(employer_cost)}</span>
            </div>
            <ToggleItem title="Your Cost" priceText={convertToCurrency(costs)} content="The amount (of the Drug Cost) that the member is supposed to pay. This is the sum of the Copay, Co-Insurance and Penalty.">            
              <div className="price-content">
                <span className="price-title">
                  Deductible 
                  <Popup content="Amount that member pays to meet deductible" trigger={<Icon name="info circle" className="info-icon" />} />
                </span>
                <span className="price-text">{convertToCurrency(deductible_accumulator_amount)}</span>
              </div> 
              <div className="price-content">
                <span className="price-title">
                  Copay
                  <Popup content="At the post-deductible stage, copay is your contribution to the drug cost after your plan pays its portion." trigger={<Icon name="info circle" className="info-icon" />} />
                </span>
                <span className="price-text">{convertToCurrency(drug_copay)}</span>
              </div> 
              <div className="price-content">
                <span className="price-title">
                  Co-Insurance
                  <Popup content="At the post-deductible stage, the co-insurance is the percentage of the drug cost that a member is supposed to pay. This can be with or without a copay." trigger={<Icon name="info circle" className="info-icon" />} />
                </span>
                <span className="price-text">{convertToCurrency(drug_coinsurance)}</span>
              </div>
              {other_costs > 0 ?
              <ToggleItem title="Other Costs" priceText={convertToCurrency(other_costs)}>
                {retail_penalty > 0 ?
                <div className="price-content">
                  <span className="price-title">
                    Retail Penalty
                  </span>
                  <span className="price-text">{convertToCurrency(retail_penalty)}</span>
                </div>  
                : "" 
                }
                {alternate_penalty > 0 ? 
                <div className="price-content">
                  <span className="price-title">
                    Alternate Penalty
                  </span>
                  <span className="price-text">{convertToCurrency(alternate_penalty)}</span>
                </div>  
                : "" 
                }
                {member_choice_penalty_amount > 0 ? 
                <div className="price-content">
                  <span className="price-title">
                    Members Choice Copay
                    <Popup content="Amount not covered by your plan for choosing brand drug when generic is available. This amount is not applicable toward your deductible." trigger={<Icon name="info circle" className="info-icon" />} />
                  </span>
                  <span className="price-text">{convertToCurrency(member_choice_penalty_amount)}</span>
                </div>  
                : "" 
                }
                {rbp_penalty > 0 ?
                <div className="price-content">
                  <span className="price-title">
                    Reference-based price penalty
                  </span>
                  <span className="price-text">{convertToCurrency(rbp_penalty)}</span>
                </div>  
                : "" 
                }
              </ToggleItem>
              : ""
              } 
            </ToggleItem>
            {savings_credits > 0 ?
              <ToggleItem title="Savings And Credits" priceText={convertToCurrency(savings_credits)} content="Total of all savings and credit amount">
                {retail_reward > 0 ? 
                <div className="price-content">
                  <span className="price-title">
                    Savings for choosing cheaper pharmacy
                  </span>
                  <span className="price-text">{convertToCurrency(retail_reward)}</span>
                </div>   
                : ""
                }
                {alternate_reward > 0 ?
                <div className="price-content">
                  <span className="price-title">
                    Savings for choosing a cheaper drug
                  </span>
                  <span className="price-text">{convertToCurrency(alternate_reward)}</span>
                </div>  
                : ""
                }
                {incentive_credit_applied > 0 ?
                <div className="price-content">
                  <span className="price-title">
                    Additional Credit applied
                  </span>
                  <span className="price-text">{convertToCurrency(incentive_credit_applied)}</span>
                </div>   
                : ""
                }
                {rbp_reward > 0 ? 
                <div className="price-content">
                  <span className="price-title">
                    Reference-based price savings
                  </span>
                  <span className="price-text">{convertToCurrency(drug_coinsurance)}</span>
                </div>   
                : ""
                }
              </ToggleItem>
              : ""
            
            }
            <div className="price-content bg-color">
              <span className="price-title">
                Your Net Cost
                <Popup content="The amount (of the Drug Cost) that the member is supposed to pay when picking up the drug at the pharmacy." trigger={<Icon name="info circle" className="info-icon" />} />
              </span>
              <span className="price-text">
                {convertToCurrency(costs-savings_credits)}
              </span>
            </div>
          </div>
        </div>
      </div>

    )
  }
}

export default PriceModal
