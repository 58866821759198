import React, { PureComponent } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Creators as AppActions } from '../../../redux/reducers/app'

import './styles.scss'
import BreadCrumb from '../../../components/breadCrumb'

class RebateRemittance extends PureComponent {
  render() {
    return (
      <div id="RebateRemittance">
        <BreadCrumb {...this.props} />
        <div className="header">Rebate Remittance</div>
        <div className="content"> </div>
        <div className="section"> </div>
        <div className="section-header"> </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  state: {
    app: state.app,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RebateRemittance)
