import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import './styles.scss'
import DrugConfiguration from './drugConfiguration'
import PharmacyList from './pharmacyList'
import PriceModal from './priceModal'
import { Creators as AppActions } from '../../../redux/reducers/app'
import { Creators as AutoSuggestActions } from '../../../redux/reducers/api/autoSuggest'
import { Creators as GetPricingActions } from '../../../redux/reducers/api/getPricing'
import { Creators as NavigationActions } from '../../../redux/reducers/navigation'

class DrugList extends Component {
  constructor(props) {
    super(props)

    this.defaultDrugDetailOptions = {
      drug_name: '',
      days_of_supply: '',
      forms: [],
      quantity: '',
      strengths: [],
    }

    this.state = {
      showPrice: false,
      formValue: '',
      drug_detail_options: this.defaultDrugDetailOptions,
      priceData: {},
    }
  }

  _hidePriceModal = () => {
    this.setState({
      priceData: {},
      showPrice: false,
    })
  }

  _showPriceModal = (el) => {
    const { state: { drugPrices } } = this.props
    drugPrices.sort((a, b) => a.npi.localeCompare(b.npi))
    const { currentTarget } = el
    const idx = currentTarget.getAttribute('idx')

    this.setState({
      priceData: drugPrices[idx],
      showPrice: true,
    })
  }

  _getDrugDetails = (e, data) => {
    e.preventDefault()
    const {
      actions, setDrugName, member_info: { domain_name, plan_name }, updateDrugConfiguration,
    } = this.props
    const { result: { title } } = data
    const drug_detail_options = this.defaultDrugDetailOptions

    this._resetFormValue()
    drug_detail_options.drug_name = title
    this.setState({ drug_detail_options })

    actions.clearDrugPriceList()
    actions.getDrugDetails({
      domain_name,
      drug_name: title,
      plan_name,
    })

    updateDrugConfiguration(e)
    setDrugName(title)
  }

  _drugNameLookup = (el) => {
    const { actions } = this.props
    const { name, value } = el.currentTarget

    if (name === 'drug_name' && value.length > 2) {
      actions.getAutoSuggestData({ search_string: value, search_in: 'fts_ndc_drugs', search_for: 'drug_name' })
    }
  }

  _resetFormValue = () => {
    const { resetFormValues } = this.props
    resetFormValues()

    this.setState({
      formValue: '',
      drug_detail_options: this.defaultDrugDetailOptions,
    })
  }

  _selectForm = (e, data) => {
    try {
      const { value } = data
      const { state: { drugDetails } } = this.props

      if (!drugDetails?.drug_forms || !drugDetails?.drug_forms?.length) return

      let strengths_list = drugDetails.drug_forms.find((f) => f.drug_form.toUpperCase() === value.toUpperCase()).drugs.map((d, i) => ({
        text: d.strengths || d.dosage_strength || 'NA',
        value: i,
        gpi: d.gpi,
        key: `${i}-${d.gpi}`,
      })).sort((a, b) => (a.value - b.value))
      const key = 'text';
      const strengths = [...new Map(strengths_list.map(item => [item[key], item])).values()];

      strengths.unshift({
        key: 0, text: 'Select Strength', value: '', selected: 'selected',
      })

      this.setState((prevState) => ({
        formValue: value,
        drug_detail_options: {
          ...prevState.drug_detail_options,
          strengths,
        },
      }))
    } catch (err) {
      this._resetFormValue()
    }
  }

  render() {
    const {
      formData, state, getPrices, updateFormData, updateDrugConfiguration,
    } = this.props
    const {
      drug_detail_options, formValue, showPrice, priceData,
    } = this.state
    const { drugDetails, autoSuggestData } = state

    if (drugDetails.drug_forms?.length) {
      drug_detail_options.forms = drugDetails.drug_forms.map((d) => ({
        key: d.drug_form,
        text: d.drug_form,
        value: d.drug_form,
      }))
      drug_detail_options.forms.unshift({
        key: 0, text: 'Select Form', value: '', selected: 'selected',
      })
    }

    return (
      <section className="drug-list-wrap spacing">
        <DrugConfiguration
          drugDetailOptions={drug_detail_options}
          drugDetails={drugDetails}
          drugNameLookup={this._drugNameLookup}
          drugNameSearchResults={autoSuggestData['drug_name']}
          formData={formData}
          formValue={formValue}
          getDrugDetails={this._getDrugDetails}
          getPrices={getPrices}
          resetFormValue={this._resetFormValue}
          selectForm={this._selectForm}
          updateDrugConfiguration={updateDrugConfiguration}
          updateFormData={updateFormData}
        />
        <PharmacyList
          pharmactListData={state.drugPrices}
          showPriceModal={this._showPriceModal}
        />

        {
          showPrice && (
            <PriceModal
              hidePriceModal={this._hidePriceModal}
              priceData={priceData}
              daysOfSupply={formData.days_of_supply}
              showPriceModal
            />
          )
        }
      </section>
    )
  }
}

const mapStateToProps = (state) => ({
  state: {
    drugDetails: state.getPricing.drugDetails,
    drugPrices: state.getPricing.drugPrices,
    autoSuggestData: state.autoSuggest.autoSuggestData,
    formularyLookup: state.formularyLookup,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
    ...AutoSuggestActions,
    ...GetPricingActions,
    ...NavigationActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DrugList)
