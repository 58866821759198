import {
  call,
  delay,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects'
import { fetchPost } from '../../utils/fetchApi'
import AppLocalStorage from '../../utils/localStorage'
import { Creators as AppActions, Types } from '../reducers/app'
import { Creators as UserActions } from '../reducers/user'
import { Creators as NavigationActions } from '../reducers/navigation'
import { Creators as CompanyManagementActions } from '../reducers/companyManagement'
import { fetchGet } from '../../utils/fetchApi'
import { getApiPath, getAppState, isMenuEmpty } from '../reducers/selectors'

export default [
  displayTransitionalPortalWatcher,
  initializeApplicationWatcher,
  initializeLoggedInUserWatcher,
  getApiUrlWatcher,
  getClientDataWatcher,
  toggleLoaderWatcher,
]

/* WATCHERS */
function* initializeApplicationWatcher() {
  yield takeLatest(Types.INITIALIZE_APPLICATION, initializeApplicationHandler)
}

function* initializeLoggedInUserWatcher() {
  yield takeLatest(Types.INITIALIZE_LOGGED_IN_USER, initializeLoggedInUserHandler)
}

function* getApiUrlWatcher() {
  yield takeLatest(Types.GET_API_URL, getApiUrlHandler)
}

function* getClientDataWatcher() {
  yield takeLatest(Types.GET_CLIENT_DATA, getClientDataHandler)
}

function* displayTransitionalPortalWatcher() {
  yield takeLatest(Types.DISPLAY_TRANSITIONAL_PORTAL, displayTransitionalPortalHandler)
}

function* toggleLoaderWatcher() {
  yield takeLatest(Types.TOGGLE_LOADER, toggleLoaderHandler)
}

/* HANDLERS */
function* initializeApplicationHandler({ payload }) {
  try {
    const jwt = AppLocalStorage.getItem('jwt')
    if (!jwt) return

    yield put(AppActions.setAppSettings({
      history: payload.history,
    }))
    yield put(UserActions.userGet())
    const appState = yield select(getAppState)
    const response = yield fetchGet(`${appState.apiUrl}/fast/app-settings`)
    const appSettings = {
      menu_data: response.menu_data,
      activeIndex: _getActiveIndex(response.menu_data, payload.history.location.pathname),
      // roles: response.roles,
      // features: response.features,
      hierarchy: response.hierarchy,
      roleFunctionHierarchy: response.roleFunctionHierarchy,
      approvalDocumentTypes: response?.approvalDocumentTypes,
    }
    yield put(AppActions.setAppSettings(appSettings))
    yield put(CompanyManagementActions.setCompanyListData(response.company_data))
    yield put(AppActions.toggleDataLoaded())
  } catch (err) {
    console.log('initializeApplicationHandler Error ', err)
    yield put(NavigationActions.navigateTo({ pathname: '/home' }))
  }
}

function* initializeLoggedInUserHandler() {
  try {
    const appState = yield select(getAppState)
    const response = yield fetchGet(`${appState.apiUrl}/fast/app-settings`)
    const appSettings = {
      menu_data: response.menu_data,
      // roles: response.roles,
      roleFunctionHierarchy: response.roleFunctionHierarchy,
      approvalDocumentTypes: response?.approvalDocumentTypes,
    }

    yield put(AppActions.setAppSettings(appSettings))
    yield put(CompanyManagementActions.setCompanyListData(response.company_data))
    yield put(AppActions.toggleDataLoaded())
  } catch (err) {
    yield put(NavigationActions.navigateTo({ pathname: '/home' }))
  }
}

function* getClientDataHandler({ payload }) {
  try {
    const company = payload.split('.')[0]
    const appState = yield select(getAppState)
    const url = `${appState.apiUrl}/fast/get-client-data`
    let companyCSSFilename = null
    if (company) {
      const response = yield call(fetchPost, url, { company_url_hostname: company })
      companyCSSFilename = response.companyCSSFilename
    }

    yield put(AppActions.setCompanyCSSFilename(companyCSSFilename ?? 'flipt'))
  } catch (err) {
    yield put(NavigationActions.navigateTo({ pathname: '/home' }))
  }
}

function* displayTransitionalPortalHandler({ payload }) {
  try {
    yield put(AppActions.setTransitionalPortalContents({ visible: true, ...payload }))
    yield delay(3000)
    yield put(AppActions.setTransitionalPortalContents({ visible: false }))
  } catch (err) {
    console.log('displayTransitionalPortalHandler Error: ', err)
    throw err
  }
}

function* toggleLoaderHandler() {
  try {
    yield put(AppActions.setLoaderContents())
  } catch (err) {
    console.log('toggleLoaderHandler Error: ', err)
    throw err
  }
}

function _getActiveIndex(menuData, pathname) {
  for (let i = 0; i < menuData.length; i++) {
    const data = menuData[i]

    if (data.path === pathname) {
      return i
    } if (data?.children?.length) {
      const childFound = data.children.find((md) => md.path === pathname)
      if (childFound) return i
    }
  }

  return -1
}

function* getApiUrlHandler(path) {
  try {
    const itIsEmtpy = yield select(isMenuEmpty)
    if (itIsEmtpy) yield take([Types.SET_APP_SETTINGS])
    const { serviceUrl } = yield select(getAppState);
    const { api_path } = yield select(getApiPath, path);
    return `${serviceUrl}${api_path}`;
  } catch (error) {
    const transitionalPortalMessage = {
      header: 'Error occurred while determining api path',
      copy: error.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortalMessage))
    // should we throw in a catch lol?
    throw new Error('Error occurred while determining api path')
  }
}

export {
  getApiUrlHandler,
  initializeApplicationHandler,
  initializeLoggedInUserHandler,
  displayTransitionalPortalHandler,
  toggleLoaderHandler,
}
