import React, { useEffect, useMemo, useState } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import _ from 'lodash';
import { Creators as AppActions } from '../../../../../redux/reducers/app'
import { Creators as ClientConfigurationActions } from '../../../../../redux/reducers/api/clientConfiguration'
import { Creators as GlobalEditsActions } from '../../../../../redux/reducers/api/globalEdits'
import { CustomCostShare, MultiSelectToolTipDropDown, StackedItem } from './components';
import { getCagHierarchyConfig } from '../../utils';

const umSectionRefTable = {
    'programs': 'programEdits',
    'clinical_pa': 'priorAuths',
    'clinical_ql': 'quantityLimits',
    'clinical_step_therapy': 'stepTherapies',
}

const sectionToKeyPrefix = {
    'programs': 'program',
    'clinical_pa': 'pa',
    'clinical_ql': 'ql',
    'clinical_step_therapy': 'step_therapy',
}

function PlanOverride(props) {

    useEffect(() => {
        props.actions.getSectionUI({ section: 'global_copay' })
        props.actions.getPublishedUmObjects({ cag: props.state.cag })
    }, [])

    const { state, actions } = props
    const { globalEdits, publishedUmObjects } = state
    const { programEdits = [], priorAuths = [], quantityLimits = [], stepTherapies = [], tiers = [] } = publishedUmObjects
    const nullOption = { text: '', value: '' };
    const programEditsSortedOptions = useMemo(() => {
        return [nullOption, ..._.cloneDeep(programEdits).map((programEdit) => ({
            text: programEdit.doc_name,
            value: programEdit.doc_id,
        }))];
    }, [programEdits]);
    const priorAuthsSortedOptions = useMemo(() => {
        return [nullOption, ..._.cloneDeep(priorAuths).map((priorAuth) => ({
            text: priorAuth.doc_name,
            value: priorAuth.doc_id,
        }))];
    }, [priorAuths]);
    const quantityLimitsSortedOptions = useMemo(() => {
        return [nullOption, ..._.cloneDeep(quantityLimits).map((quantityLimit) => ({
            text: quantityLimit.doc_name,
            value: quantityLimit.doc_id,
        }))];
    }, [quantityLimits]);
    const stepTherapiesSortedOptions = useMemo(() => {
        return [nullOption, ..._.cloneDeep(stepTherapies).map((stepTherapy) => ({
            text: stepTherapy.doc_name,
            value: stepTherapy.doc_id,
        }))];
    }, [stepTherapies]);

    const {
        programs = [],
        clinical_pa = [],
        clinical_ql = [],
        clinical_step_therapy = [],
    } = globalEdits;

    const moveItem = (section, index, direction) => {
        const list = globalEdits[section] || [];
        if (list.length < 2) return;
        const newList = [...list];
        const temp = newList[index];
        newList[index] = newList[index + direction];
        newList[index].rank += -(direction);
        newList[index + direction] = temp;
        newList[index + direction].rank += direction;
        actions.updateGlobalEditsField({ name: section, value: newList });
    };

    const handleBenefitOverrideSelection = (section, dropdown) => {
        const { name, value } = dropdown
        const selectedSection = globalEdits[section] || [];
        if (selectedSection.find((obj) => obj.doc_id === value)) return;
        const newList = [...selectedSection];
        const umObjectList = publishedUmObjects[umSectionRefTable[section]] || [];
        const record = umObjectList.find((obj) => obj.doc_id === value);
        if (!record) return;
        const newProgram = {
            doc_id: record.doc_id,
            [`${sectionToKeyPrefix[section]}_id`]: record.doc_id,
            [`${sectionToKeyPrefix[section]}_name`]: record.doc_name,
            rank: newList.length + 1,
            version: record.version,
        };
        newList.push(newProgram);
        actions.updateGlobalEditsField({ name: section, value: newList });
        if (section === 'programs') {
            const newCopayList = [...(globalEdits.copays || [])];
            newCopayList.push({
                program_id: record.doc_id,
                program_name: record.doc_name,
                program_version_id: record.doc_id,
                cost_share: [{
                    'min_day_supply': '',
                    'max_day_supply': '',
                    'network_tier': '',
                    'copay_type': '',
                    'member_pay': '',
                    'min_copay': '',
                    'max_copay': '',
                }],
            });
            actions.updateGlobalEditsField({ name: 'copays', value: newCopayList });
        }
    }

    const removeOptionFromList = (section, id) => {
        const selectedSection = globalEdits[section] || [];
        const newList = selectedSection.reduce((newList, record) => {
            if (record.doc_id !== id) {
                newList.push(record);
                record.rank = newList.length;
            }
            return newList;
        }, []);
        actions.updateGlobalEditsField({ name: section, value: newList });
        if (section === 'programs') {
            const newCopayList = [...(globalEdits.copays || [])];
            const newCopayListWithoutProgram = newCopayList.filter((copay) => copay.program_id !== id);
            actions.updateGlobalEditsField({ name: 'copays', value: newCopayListWithoutProgram });
        }
    }

    const onDragStart = (section, e, index) => {
        e.dataTransfer.setData(
            'dragData',
            JSON.stringify({ selectedIndex: index, originSection: section })
        );
    };

    const onDragOver = (e) => {
        e.preventDefault();
    };

    const onDrop = (section, e, dropIndex) => {
        const dragData = JSON.parse(e.dataTransfer.getData('dragData'));
        const { selectedIndex, originSection } = dragData;
        if (originSection !== section) return;
        const selectedSection = globalEdits[section] || [];
        const selectedRecord = selectedSection[selectedIndex];
        const newSection = selectedSection.filter((record) => record.doc_id !== selectedRecord.doc_id);
        newSection.splice(dropIndex, 0, selectedRecord);
        newSection.forEach((record, index) => record.rank = index + 1);
        actions.updateGlobalEditsField({ name: section, value: newSection });
    };

    return (
        <div className='page-container'>
            <section>
                <h2 className='page-h2'>
                    Benefit Override Configuration
                </h2>
                <section className='input-container'>
                    <MultiSelectToolTipDropDown
                        label='Program Edit'
                        tooltip=''
                        options={programEditsSortedOptions}
                        name="programs"
                        dropdownPlaceholder='Select Program Edit'
                        onChange={(_, dropdown) => handleBenefitOverrideSelection('programs', dropdown)}
                        removeOptionFromList={(id) => removeOptionFromList('programs', id)}
                        selectedOptions={programs}
                        overrideTextKey='program_name'
                    />
                    <MultiSelectToolTipDropDown
                        label='Prior Authorization Drug List'
                        tooltip=''
                        options={priorAuthsSortedOptions}
                        name="clinical_pa"
                        dropdownPlaceholder='Select PA Drug List'
                        onChange={(_, dropdown) => handleBenefitOverrideSelection('clinical_pa', dropdown)}
                        removeOptionFromList={(id) => removeOptionFromList('clinical_pa', id)}
                        selectedOptions={clinical_pa}
                        overrideTextKey='pa_name'
                    />
                    <MultiSelectToolTipDropDown
                        label='Quantity Limit'
                        tooltip=''
                        options={quantityLimitsSortedOptions}
                        name="clinical_ql"
                        dropdownPlaceholder='Select Quantity Limit'
                        onChange={(_, dropdown) => handleBenefitOverrideSelection('clinical_ql', dropdown)}
                        removeOptionFromList={(id) => removeOptionFromList('clinical_ql', id)}
                        selectedOptions={clinical_ql}
                        overrideTextKey='ql_name'
                    />
                    <MultiSelectToolTipDropDown
                        label='Step Therapy Edits'
                        tooltip=''
                        options={stepTherapiesSortedOptions}
                        name="clinical_step_therapy"
                        dropdownPlaceholder='Select Step Therapy'
                        onChange={(_, dropdown) => handleBenefitOverrideSelection('clinical_step_therapy', dropdown)}
                        removeOptionFromList={(id) => removeOptionFromList('clinical_step_therapy', id)}
                        selectedOptions={clinical_step_therapy}
                        overrideTextKey='step_therapy_name'
                    />
                </section>
            </section>
            <section>
                {programs.length === 0 ? null : (
                    <article>
                        <section className='stacked-label'>
                            <span>
                                Program Edits
                            </span>
                        </section>
                        <ul className='stacked-container'>
                            {programs.map((program, index) => {
                                return (
                                    <StackedItem
                                        text={program[`${sectionToKeyPrefix['programs']}_name`]}
                                        index={index}
                                        position={index + 1}
                                        moveDown={() => moveItem('programs', index, 1)}
                                        moveUp={() => moveItem('programs', index, -1)}
                                        last={programs.length - 1 === index}
                                        onDragStart={(e) => onDragStart('programs', e, index)}
                                        onDragOver={onDragOver}
                                        onDrop={(e) => onDrop('programs', e, index)}
                                    />
                                )
                            })}
                        </ul>
                    </article>
                )}
            </section>
            <section>
                {clinical_pa?.length === 0 ? null : (
                    <article>

                        <section className='stacked-label'>
                            <span>
                                Prior Authorization Drug List
                            </span>
                        </section>
                        <ul className='stacked-container'>
                            {clinical_pa.map((pa, index) => {
                                return (
                                    <StackedItem
                                        text={pa[`${sectionToKeyPrefix['clinical_pa']}_name`]}
                                        index={index}
                                        position={index + 1}
                                        moveDown={() => moveItem('clinical_pa', index, 1)}
                                        moveUp={() => moveItem('clinical_pa', index, -1)}
                                        last={clinical_pa.length - 1 === index}
                                        onDragStart={(e) => onDragStart('clinical_pa', e, index)}
                                        onDragOver={onDragOver}
                                        onDrop={(e) => onDrop('clinical_pa', e, index)}
                                    />
                                )
                            })}
                        </ul>
                    </article>
                )}
            </section>
            <section>
                {clinical_ql?.length === 0 ? null : (
                    <article>
                        <section className='stacked-label'>
                            <span>
                                Quantity Limit
                            </span>
                        </section>
                        <ul className='stacked-container'>
                            {clinical_ql.map((ql, index) => {
                                return (
                                    <StackedItem
                                        text={ql[`${sectionToKeyPrefix['clinical_ql']}_name`]}
                                        index={index}
                                        position={index + 1}
                                        moveDown={() => moveItem('clinical_ql', index, 1)}
                                        moveUp={() => moveItem('clinical_ql', index, -1)}
                                        last={clinical_ql.length - 1 === index}
                                        onDragStart={(e) => onDragStart('clinical_ql', e, index)}
                                        onDragOver={onDragOver}
                                        onDrop={(e) => onDrop('clinical_ql', e, index)}
                                    />
                                )
                            })}
                        </ul>
                    </article>
                )}
            </section>
            <section>
                {clinical_step_therapy?.length === 0 ? null : (
                    <article>
                        <section className='stacked-label'>
                            <span>
                                Step Therapy
                            </span>
                        </section>
                        <ul className='stacked-container'>
                            {clinical_step_therapy.map((stepTherapy, index) => {
                                return (
                                    <StackedItem
                                        text={stepTherapy[`${sectionToKeyPrefix['clinical_step_therapy']}_name`]}
                                        index={index}
                                        position={index + 1}
                                        moveDown={() => moveItem('clinical_step_therapy', index, 1)}
                                        moveUp={() => moveItem('clinical_step_therapy', index, -1)}
                                        last={clinical_step_therapy.length - 1 === index}
                                        onDragStart={(e) => onDragStart('clinical_step_therapy', e, index)}
                                        onDragOver={onDragOver}
                                        onDrop={(e) => onDrop('clinical_step_therapy', e, index)}
                                    />
                                )
                            })}
                        </ul>
                    </article>
                )}
            </section>
            <section>
                {programs.length === 0 ? null : (
                    <CustomCostShare />
                )}
            </section>
        </div>
    )
}

const mapStateToProps = (state) => {
    const singleLevelAttribute = state.clientConfiguration?.singleLevelAttribute || {};
    const globalEditsAttribute = singleLevelAttribute?.global_edits || {};
    const configuration_id = globalEditsAttribute.value?.value || globalEditsAttribute.value || null;
    const cag = state.clientConfiguration.AllData?.map(org => getCagHierarchyConfig({ node: org }))[0];
    return {
        state: {
            app: state.app,
            cag: cag,
            configuration_id: configuration_id,
            clientConfiguration: state.clientConfiguration,
            globalEdits: state.globalEdits,
            publishedUmObjects: state.globalEdits.publishedUmObjects || {},
            singleLevelAttribute: state.clientConfiguration?.singleLevelAttribute || {},
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...AppActions,
        ...ClientConfigurationActions,
        ...GlobalEditsActions,
    }

    return {
        actions: bindActionCreators(allActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlanOverride);