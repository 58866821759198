import React, { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Creators as AppActions } from '../../../../../redux/reducers/app'
import { Creators as MemberLookupActions } from '../../../../../redux/reducers/api/memberLookup'
import BreadCrumb from '../../../../../components/breadCrumb'
import FliptGrid from '../../../../../components/fliptGrid'
import { filterRowData } from '../../../../../utils/utilities'
import { parseQueryString } from '../../../../../utils/utilities'
import './styles.scss'

const CommunicationHistory = (props) => {
    const [counter, setCounter] = useState(0)
    const { state:{ communicationHistory:data }, history } = props
    const { flipt_person_id } = parseQueryString(history.location.search)
    const communication_history = data;
    const communicationHistory = communication_history && communication_history.length ? communication_history : []

    
    const cellRendererParams = {
        time_message_sent: {
            overrideHeader: 'Date',
        },
        type: {
            overrideHeader: 'Type',
        },
        notification_type: {
            overrideHeader: 'Source (Platform)',
        },
        purpose: {
            overrideHeader: 'Category (Content)',
        },
        message: {
            overrideHeader: 'Communication Content',
        },
        rx_number: {
            overrideHeader: 'Rx Number',
        },
        phone_number: {
            overrideHeader: 'Contact Phone Number',
        }
    }
    const headers = Object.keys(cellRendererParams)

    const communicationHistoryData = communicationHistory.map((d) => ({
        ...filterRowData(d, headers)
    }));


    useEffect(()=>{
        const { actions } = props
        actions.getCommunicationHistoryData({ flipt_person_id })
    }, [])

    useEffect(()=>{
        setCounter(counter + 1)
    }, [data])

    return (
        <div id="communication-history">
            <BreadCrumb {...props} />
            <div className="header">
                <h1>Communication History</h1>
            </div>

            <div className="section-contents">
                <div className="fields-container">
                    <div className="history-content">
                        <FliptGrid
                            data={communicationHistoryData}
                            headers={headers}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = (state) => ({
    state: {
      app: state.app,
      communicationHistory: state.memberLookup.communicationHistory,
      user: state.user,
    },
  })
  
  const mapDispatchToProps = (dispatch) => {
    const allActions = {
      ...AppActions,
      ...MemberLookupActions,
    }
  
    return {
      actions: bindActionCreators(allActions, dispatch),
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(CommunicationHistory)