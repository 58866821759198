import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import _, { cloneDeep } from 'lodash'
import { Accordion, Button, Icon } from 'semantic-ui-react'
import FliptGrid from '../../../../components/fliptGrid'
import FliptDropdown from '../../../../components/form/fliptDropdown'
import AddRow from '../addRow'

import { Creators as PlanManagementActions } from '../../../../redux/reducers/api/planManagement'
import { Creators as AppActions } from '../../../../redux/reducers/app'
import { DeleteRowRenderer } from '../../../../components/fliptGrid/cellRenderers'
import { InputTextEditor, DropdownEditor, DatePickerEditor } from '../../../../components/fliptGrid/cellEditors'
import rowCellInfo from '../data/rowCellInfo'
import './styles.scss'
import { resolveMessage } from '../../../../utils/validationHelpers'

class DefaultCopay extends Component {
    constructor(props) {
        super(props)
        this.state = {
            prevCopays: [],
            form: {}
        }
    }

    componentDidUpdate(prevProps) {
        const { state: { defaultCopayData }, editMode, copyFormData, modelData } = this.props

        if (defaultCopayData !== prevProps.state.defaultCopayData) {
            const form = defaultCopayData.model_data
            form.create_date = modelData.create_date
            form.update_date = modelData.update_date
            const prevCopays = form.copays
            const defaultValues = editMode ? this.getDefaultValues(form) : {}
            const { defaultDefaultCopay, defaultForm } = defaultValues
            let filteredData = [];
            if (form.copays.length) {
                filteredData = this.filterDefaultCopays(form.copays)
            }
            form.copays = (filteredData.length && filteredData[0].cost_share) || defaultDefaultCopay;
            if (filteredData.length) {
                defaultForm.accumulate_deductible = filteredData[0].accumulate_deductible
                defaultForm.accumulate_out_of_pocket = filteredData[0].accumulate_out_of_pocket
                defaultForm.out_of_pocket_exempt = filteredData[0].out_of_pocket_exempt
                defaultForm.deductible_exempt = filteredData[0].deductible_exempt
            }
            copyFormData({
                data: { ...form },
                prevCopays: prevCopays
            })
            this.setState({ form: { ...defaultForm, ...form }, prevCopays })
        }
    }

    filterDefaultCopays = (copays) => {
        const filteredData = copays.filter((data) => {
            return !data.program_id && !data.tier_id && !data.tier_name && data.program_name && data.program_name.includes("Default Cost Share")
        })
        return filteredData;
    }


    getDefaultValues = (form) => {
        const { props: { state } } = this
        const defaultForm = {
            accumulate_deductible: form.accumulate_deductible || '',
            accumulate_out_of_pocket: form.accumulate_out_of_pocket || '',
            deductible_exempt: form.deductible_exempt || '',
            out_of_pocket_exempt: form.out_of_pocket_exempt || ''
        }
        const defaultDefaultCopayRow = {
            action: '',
            member_pay: null,
            copay_type: '',
            max_copay: null,
            min_copay: null,
            network_tier: '',
            day_supply: ''
        }
        let defaultDefaultCopay = [defaultDefaultCopayRow]
        return {
            defaultForm,
            defaultDefaultCopay,
        }
    }


    onFilterChange = (e, dropdown) => {
        const { copyFormData } = this.props
        const { form, prevCopays } = this.state
        const { name, value } = dropdown || el.currentTarget
        form[name] = value
        copyFormData({
            data: { ...form },
            prevCopays
        })
        this.setState({
            form
        })
    }

    addRow = () => {
        const row = {
            action: '',
            member_pay: null,
            copay_type: '',
            max_copay: null,
            min_copay: null,
            network_tier: '',
            day_supply: ''
        }
        const { form } = this.state
        const newForm = { ...form }
        // may need to remove delete row here
        newForm.copays = newForm?.copays?.length ? [...newForm.copays, row] : [row]
        this.setState({ form: newForm })
        this.validateAndUpdateData({ ...newForm })
    }

    delRow = (rowIndex) => {
        const { form } = this.state
        const newForm = _.cloneDeep(form)
        newForm.copays.splice(rowIndex, 1)
        this.setState({ form: newForm })
        this.validateAndUpdateData({ ...newForm })
    }

    validateAndUpdateData = (newForm) => {
        const { copyFormData } = this.props
        const { prevCopays } = this.state

        copyFormData({
            data: newForm,
            prevCopays
        })
    }

    handleChange = (el, dropdown, rowIndex, gridApi) => {
        const { copyFormData } = this.props
        const { form, prevCopays } = this.state
        const { name, value } = dropdown || el.currentTarget
        form.copays[rowIndex][name] = value
        copyFormData({
            data: form,
            prevCopays
        })
        this.setState({ form })
        gridApi.refreshCells()
    }
    showGenerateButton = () => {
        const { form } = this.state
        const data = form?.copays || []
        const ele = data.find(x => x.day_supply === "All" || x.pharmacy_type === "ALL")
        return !_.isEmpty(ele)
    }
    generateGrid = () => {
        const { form } = this.state
        const { actions } = this.props
        const newForm = cloneDeep(form)
        if (_.isEmpty(newForm?.copays))
            newForm.copays = []
        try {
            this.validateCopayData(newForm)
        } catch (err) {
            const transitionalPortal = {
                header: 'Validation Error',
                copy: err,
            }
            actions.displayTransitionalPortal(transitionalPortal)
            return
        }

        this.setState({ form: newForm })
    }
    hasDuplicates = (array, pharmacy_type) => {
        const arr = array.filter(x => x === pharmacy_type)
        return (new Set(arr)).size !== arr.length
    }
    validateCopayData = (formData) => {
        const networkTierArray = [...formData.copays.map((data) => data.pharmacy_type)];
        let newTierConditions = []
        formData.copays.forEach(tier => {
            if (tier?.day_supply !== "All") { newTierConditions.push(tier) }
            if (tier?.day_supply === "All") {
                if (this.hasDuplicates(networkTierArray, tier?.pharmacy_type)) {
                    throw new Error(resolveMessage("val_remove_duplicate_value_from_network_tier").message)
                } else {
                    rowCellInfo.day_supply.options.forEach(option => {
                        if (option?.value !== "All") {
                            const newTierCondition = { ...tier }
                            newTierCondition.day_supply = option.value
                            newTierConditions.push(newTierCondition)
                        }
                    })
                }
            }
        })
        let networkTierCopy = []
        newTierConditions.forEach(tier => {
            if (tier?.pharmacy_type === "ALL") {
                rowCellInfo.pharmacy_type.options.forEach(option => {
                    if (option?.value !== "ALL") {
                        networkTierCopy = [...networkTierCopy, { ...tier, pharmacy_type: option.value }]
                    }
                })
            } else {
                networkTierCopy = [...networkTierCopy, { ...tier }]
            }
        })
        newTierConditions = networkTierCopy
        formData.copays = newTierConditions
        const groupped = _.groupBy(newTierConditions, n => `${n.day_supply}${n.pharmacy_type}`)
        const duplicates = (_.uniq(_.flatten(_.filter(groupped, function (n) { return n.length > 1 }))).length > 1)
        if (duplicates) {
            throw new Error(resolveMessage("val_remove_duplicate_value_from_grid").message)
        }
    }

    renderGrid = () => {
        const { editMode } = this.props
        const { form } = this.state
        const headers = [
            'day_supply',
            'network_tier',
            'copay_type',
            'member_pay',
            'min_copay',
            'max_copay',
            'action',
        ]
        const cellRendererParams = {
            day_supply: {
                width: 145,
                overrideHeader: 'Days Supply',
            },
            copay_type: {
                width: 135,
                overrideHeader: 'COPAY Type',
            },
            member_pay: {
                width: 160,
                overrideHeader: 'Member Pay',
                valueFormatter: params => {
                    if (params.data.member_pay === undefined || params.data.member_pay === null) return null
                    if (params.data.copay_type === 'Fixed') return '$' + `${params.data.member_pay}`
                    if (params.data.copay_type === 'Co-Insurance') return `${params.data.member_pay}` + '%'
                    return params.data.member_pay
                },
            },
            min_copay: {
                width: 135,
                overrideHeader: 'Min COPAY',
            },
            max_copay: {
                width: 135,
                overrideHeader: 'Max COPAY',
            },
            action: {
                cellRenderer: DeleteRowRenderer,
                state: {
                    onClick: this.delRow,
                },
                width: 135,
            },
        }
        const cellEditorParams = {
            day_supply: {
                editable: true,
                cellEditor: DropdownEditor,
                onChange: this.handleChange,
            },
            network_tier: {
                editable: true,
                cellEditor: DropdownEditor,
                onChange: this.handleChange,
            },
            copay_type: {
                editable: true,
                cellEditor: DropdownEditor,
                onChange: this.handleChange,
            },
            member_pay: {
                editable: true,
                cellEditor: InputTextEditor,
                onChange: this.handleChange,
                validation: 'numeric',
            },
            min_copay: {
                editable: true,
                cellEditor: InputTextEditor,
                onChange: this.handleChange,
                validation: 'numeric',
            },
            max_copay: {
                editable: true,
                cellEditor: InputTextEditor,
                onChange: this.handleChange,
                validation: 'numeric',
            },
        }
        if (editMode) {
            return (
                <section className="grid-container spacing border-line shadow">
                    <div className="content">
                        <FliptGrid
                            data={form?.copays ? form.copays : []}
                            headers={headers}
                            cellRendererParams={cellRendererParams}
                            cellEditorParams={cellEditorParams}
                            rowCellInfo={rowCellInfo}
                        />
                    </div>
                    <div className="addRowButtonContainer">
                        {this.showGenerateButton() && (<Button compact size="small" onClick={() => this.generateGrid()} color="youtube">Generate</Button>)}
                        <AddRow addRow={() => this.addRow('defaultCopay')} />
                    </div>
                </section>
            )
        }
        return (
            <div className="content">
                <FliptGrid
                    data={form?.copays ? form.copays : []}
                    headers={headers.slice(0, -1)}
                />
            </div>
        )
    }

    render() {
        const {
            form
        } = this.state
        const {
            editMode,
        } = this.props


        const {
            accumulate_deductible,
            accumulate_out_of_pocket,
            deductible_exempt,
            out_of_pocket_exempt
        } = form ?? {}

        const dropdownOptions = [{ value: 'Y', text: 'YES' }, { value: 'N', text: 'NO' }]

        return (
            <div className="default_copay">
                <div className="section-programs">
                    <Accordion className="section-programs-accordion" styled>
                        <div className="section-program-level">
                            <Accordion.Title
                                active={true}
                            >
                                <Icon name="dropdown" />
                                Default Cost Share
                            </Accordion.Title>
                            <Accordion.Content
                                active={true}
                            >
                                <div className="section">
                                    <section className="copay-dropdowns-container spacing border-line shadow">
                                        <div className="copay-dropdowns">
                                            <FliptDropdown
                                                placeholder="Select"
                                                label="Accumulate Deductible"
                                                name="accumulate_deductible"
                                                value={accumulate_deductible}
                                                options={dropdownOptions}
                                                onChange={this.onFilterChange}
                                                stylized
                                                disabled={!editMode}
                                            />
                                        </div>
                                        <div className="copay-dropdowns">
                                            <FliptDropdown
                                                placeholder="Select"
                                                label="Accumulate Out of Pocket"
                                                name="accumulate_out_of_pocket"
                                                value={accumulate_out_of_pocket}
                                                options={dropdownOptions}
                                                onChange={this.onFilterChange}
                                                stylized
                                                disabled={!editMode}
                                            />
                                        </div>
                                        <div className="copay-dropdowns">
                                            <FliptDropdown
                                                placeholder="Select"
                                                label="Out of Pocket Exempt"
                                                name="out_of_pocket_exempt"
                                                value={out_of_pocket_exempt}
                                                options={dropdownOptions}
                                                onChange={this.onFilterChange}
                                                stylized
                                                disabled={!editMode}
                                            />
                                        </div>
                                        <div className="copay-dropdowns">
                                            <FliptDropdown
                                                placeholder="Select"
                                                label="Deductible Exempt"
                                                name="deductible_exempt"
                                                value={deductible_exempt}
                                                options={dropdownOptions}
                                                onChange={this.onFilterChange}
                                                stylized
                                                disabled={!editMode}
                                            />
                                        </div>
                                    </section>
                                    {this.renderGrid()}
                                </div>
                            </Accordion.Content>
                        </div>
                    </Accordion>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, props) => {
    const { fieldDetails } = props

    const copayTypeField = fieldDetails?.find(({ field }) => field === 'copay_type')
    const copayTypeFieldOptions = copayTypeField?.options || []

    const copayTypeOptions = copayTypeFieldOptions?.map((option, index) => {
        const { value } = option
        return {
            key: index,
            text: value,
            value,
        }
    })


    const daySupplyFieldOptions = fieldDetails?.find(({ field }) => field === 'day_supply')?.options || []
    const networkTierOptions = fieldDetails?.find(({ field }) => field === 'network_tier')?.options || []
    rowCellInfo.network_tier.options = networkTierOptions?.map((code, index) => ({
        key: index,
        text: code.display_name,
        value: code.value,
        map_value: code.map_value,
    }))
    rowCellInfo.network_tier.options.unshift({
        key: 0, text: 'ALL', value: 'ALL', map_value: 'ALL',
    })
    const daySupplyOptions = daySupplyFieldOptions?.map((option, index) => ({
        key: index,
        text: option?.value,
        value: option?.value,
    }))

    rowCellInfo.day_supply.options = daySupplyOptions
    rowCellInfo.copay_type.options = copayTypeOptions

    return ({
        state: {
            app: state.app,
            defaultCopayData: state.planManagement.customCopayData,
            effective_end_date: state.planManagement.planDetails.model_data.effective_end_date,
            effective_start_date: state.planManagement.planDetails.model_data.effective_start_date,
        }
    })
}

const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...PlanManagementActions,
        ...AppActions,
    }

    return {
        actions: bindActionCreators(allActions, dispatch),
    }
}

const defaultCopayContainer = (props) => (
    <DefaultCopay
        editMode={true}
        {...props}
    />
)

export default DefaultCopay

export const DefaultCopayContainer = connect(mapStateToProps, mapDispatchToProps)(defaultCopayContainer)
