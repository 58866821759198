import Immutable from 'seamless-immutable'
import { createActions, createReducer } from 'reduxsauce'

export const INITIAL_STATE = Immutable({
  data: [],
  totalRows: 0,
  claimsPerPage: 20,
  claimDetailData: [],
  contractedProcessorList: [],
  claimDetailTotalRows: 0,
  claimDetailsPerPage: 100,
  claimTransactionData: {
    claimOverview: {},
    pricingOverview: {},
    providersOverview: {},
    rejectionOverview: {},
  },
  claimTransactionCostShare: {},
  claimPersonName: {},
  qlDaysLimitMSG: '',
  nxTxnsData: [],
  allNxTxns: [],
  firTxnData: [],
  memberDetailsData: []
})

export const updateClaimLookupDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  data: payload.data,
  totalRows: payload.totalRows,
})

export const setClaimDetailDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  claimDetailData: payload.data,
  claimDetailTotalRows: payload.totalRows,
})

export const setQLDaysLimitMSGReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  qlDaysLimitMSG: payload,
})

export const setMemberDetailsDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  memberDetailsData: payload,
})

export const setContractedProcessorDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  contractedProcessorList: payload,
})

export const setClaimTransactionDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  claimTransactionData: payload,
})

export const setClaimTransactionCostShareDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  claimTransactionCostShareData: payload,
})

export const setNxTxnsDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  nxTxnsData: payload,
})

export const setAllNxTxnsDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  allNxTxns: payload,
})

export const setFirTxnsDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  firTxnData: payload,
})


export const setClaimPersonNameReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  claimPersonName: payload,
})

export const clearClaimDetailDataReducer = (state = INITIAL_STATE) => ({
  ...state,
  claimDetailData: [],
})

export const clearReducer = () => INITIAL_STATE

export const { Types, Creators } = createActions({
  clearClaimDetailData: null,
  clearReducer: null,
  getClaimDetailData: null,
  getClaimLookupManagementData: ['payload'],
  getClaimTransactionData: null,
  getClaimTransactionCostShare: ['payload'],
  getNxTxns: ['payload'],
  getAllNxTxns: ['payload'],
  getAdapSpapTxns: ['payload'],
  getFirTxns: ['payload'],
  getContractedProcessorData: ['payload'],
  setContractedProcessorData: ['payload'],
  removeClaimOverride: ['payload'],
  setClaimDetailData: ['payload'],
  setClaimOverride: ['payload'],
  setClaimPersonName: ['payload'],
  setClaimTransactionData: ['payload'],
  setClaimTransactionCostShare: ['payload'],
  updateClaimLookupManagementData: ['payload'],
  getQLDaysLimitMessage: ['payload'],
  setQLDaysLimitMSG: ['payload'],
  setNxTxnsData: ['payload'],
  setAllNxTxnsData: ['payload'],
  setFirTxnsData: ['payload'],
  setMemberDetailsData: ['payload'],
})

const HANDLERS = {
  [Types.CLEAR_CLAIM_DETAIL_DATA]: clearClaimDetailDataReducer,
  [Types.CLEAR_REDUCER]: clearReducer,
  [Types.SET_CLAIM_DETAIL_DATA]: setClaimDetailDataReducer,
  [Types.SET_CLAIM_PERSON_NAME]: setClaimPersonNameReducer,
  [Types.SET_CLAIM_TRANSACTION_DATA]: setClaimTransactionDataReducer,
  [Types.UPDATE_CLAIM_LOOKUP_MANAGEMENT_DATA]: updateClaimLookupDataReducer,
  [Types.SET_QL_DAYS_LIMIT_MSG]: setQLDaysLimitMSGReducer,
  [Types.SET_CONTRACTED_PROCESSOR_DATA]: setContractedProcessorDataReducer,
  [Types.SET_CLAIM_TRANSACTION_COST_SHARE]: setClaimTransactionCostShareDataReducer,
  [Types.SET_NX_TXNS_DATA]: setNxTxnsDataReducer,
  [Types.SET_FIR_TXNS_DATA]: setFirTxnsDataReducer,
  [Types.SET_MEMBER_DETAILS_DATA]: setMemberDetailsDataReducer,
  [Types.SET_ALL_NX_TXNS_DATA]: setAllNxTxnsDataReducer
}

export default createReducer(INITIAL_STATE, HANDLERS)
