import React, { useEffect, useRef, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import './styles.scss'
import BreadCrumb from '../../../../components/breadCrumb'
import FliptButton from '../../../../components/form/fliptButton'
import FliptDatePicker from '../../../../components/form/fliptDatePicker'
import FliptDropdown from '../../../../components/form/fliptDropdown'
import FliptInput from '../../../../components/form/fliptInput'
import FliptHierarchyDropdown from '../../../../components/form/fliptHierarchyDropdown'
import SubmenuNav from '../shared/navigation'
import { Creators as AppActions } from '../../../../redux/reducers/app'
import { Creators as QuestionnaireBuilderActions } from '../../../../redux/reducers/api/questionnaireBuilder'
import { Creators as UserActions } from '../../../../redux/reducers/userManagement/index'
import { Creators as PriorAuthorizationCreationActions } from "../../../../redux/reducers/api/priorAuthorizationManagement"
import { Creators as NavigationActions } from '../../../../redux/reducers/navigation'
import TableViewer from '../shared/TableViewer'
import {
  convertStrToDateObj, formatPhoneNumber,
} from '../../../../utils/utilities'
import { parseQueryString } from '../../../../utils/utilities'
import CAGFilters from '../../../../components/CAGFilters'
import FliptGrid from '../../../../components/fliptGrid'

// questionnaire file parsed statuses
const NOT_SET = 'not_set'
const SELECTED = 'selected'
const PARSED = 'parsed'
const EXISTING = 'existing'

const QuestionnaireImport = (props) => {
  const initialQuestionnaireObject = {
    questionnaire_type: '',
    questionnaire_name: '',
    questionnaire_policy: '',
    questionnaire_description: '',
    question_set_contact_communication_number: '',
    attachment_required: '',
    effective_date: '',
    term_date: '',
    questionnaire_category: '',
    carrier: '',
    account: '',
    group: '',
    questionnaire_file_name: '',
    initial_approval_period: '',
    recertification_approval_period: '',
  }

  const { actions, state } = props
  const [questionnaireFile, setSelectedQuestionnaire] = useState(null);
  const [faxFormFile, setSelectedFaxForm] = useState(null)
  const [uploadSubmit, setUploadSubmit] = useState(false)
  const [questionnaireObject, setQuestionnaireObject] = useState(initialQuestionnaireObject);
  const questionnaireID = parseQueryString(props?.location?.search)?.questionnaire_id
  const group = parseQueryString(props?.location?.search)?.group
  const [fileElement, setFileElement] = useState(null)
  const [questionnaireFileStatus, setQuestionnaireFileStatus] = useState(NOT_SET)
  const [tabFlag, setTabFlag] = useState(false)

  useEffect(() => {
    if (questionnaireID) actions.getQuestionnaire({ questionnaire_id: questionnaireID, source: 'FAST', group: group })
    if (!questionnaireID) {
      setQuestionnaireObject({
        ...questionnaireObject,
        // auto-populate communication number when creating questionnaire
        question_set_contact_communication_number: '8333547879',
        questionnaire_type: 'Global',
      })
      actions.clearQuestionnaireImportData()
      actions.clearQuestionnaireData()
      actions.clearFinalData()
      actions.clearQuestionnaireDetails()
      actions.clearQuestionnaireErrorData()
    }
    // clear all reducers on unmount
    return () => {
      actions.clearQuestionnaireImportData()
      actions.clearQuestionnaireData()
      actions.clearFinalData()
      actions.clearQuestionnaireDetails()
    }
  }, [])
  useEffect(() => {
    if (Object.keys(props?.state?.questionnaireErrorData).length > 0) {
      setTabFlag(true)
    }
    return;
  }, [props?.state?.questionnaireErrorData])

  let form = {
    ...questionnaireObject,
  }
  if (questionnaireID && props?.state?.questionnaireData) {
    Object.entries(props?.state?.questionnaireData).forEach(([key, value]) => { form[key] = form[key] || value })
  }

  if (form?.effective_date) form.effective_date = new Date(form.effective_date)
  if (form?.term_date) form.term_date = new Date(form.term_date)
  if (props?.state?.questionnaireImportData) {
    let questionnaire_file_name = props.state.questionnaireImportData?.questionnaire_file_name
    if (questionnaire_file_name && questionnaireFileStatus !== PARSED)
      setQuestionnaireFileStatus(PARSED)
    form.questionnaire_file_name = questionnaire_file_name
    form.questionnaire = props.state.questionnaireImportData?.questionnaire
    form.drugs = props.state.questionnaireImportData?.drugs

  }

  const goBack = () => {
    props.history.goBack()
  }

  const saveForm = () => {
    const testQuestionnaireObject = { ...form }
    delete testQuestionnaireObject.term_date
    let requiredFields = ['questionnaire_type',
      'questionnaire_name',
      'questionnaire_policy',
      'questionnaire_description',
      'question_set_contact_communication_number',
      'attachment_required',
      'effective_date',
      'questionnaire_category',
      'questionnaire',
    ]
    if (testQuestionnaireObject.questionnaire_type === 'Custom') {
      requiredFields = [...requiredFields, 'carrier', 'account', 'group']
    }
    // if new questionnaire, faxFormFile should be required
    if (!testQuestionnaireObject.questionnaire_id) {
      requiredFields.push('faxFormFile')
      testQuestionnaireObject.faxFormFile = faxFormFile
    }
    const missingFields = requiredFields.filter((field) => !testQuestionnaireObject[field])
    if (missingFields.length > 0) {
      const transitionalPortal = {
        header: 'Fields Missing',
        copy: `Please add values for these fields: ${missingFields.join(', ')}`
      }
      props.actions.displayTransitionalPortal(transitionalPortal)
      return
    }
    if (testQuestionnaireObject.question_set_contact_communication_number.length !== 10) {
      const transitionalPortal = {
        header: 'Invalid Field',
        copy: 'Please add 10 Digit Contact Number to Upload Questionnaire'
      }
      props.actions.displayTransitionalPortal(transitionalPortal)
      return;
    }

    if (questionnaireFileStatus === SELECTED && questionnaireFile) {
      const transitionalPortal = {
        header: 'Questionnaire file is not parsed',
        copy: 'Click on Upload to parse quesitonnaire file'
      }
      props.actions.displayTransitionalPortal(transitionalPortal)
      return;
    }

    const { user } = props.state
    let payload = {
      ...form,
      questionnaire_id: questionnaireID ?? null,
      last_updated_by: `${user.first_name} ${user.last_name}`
    }
    if (payload.questionnaire_type === 'Global') {
      delete payload.carrier
      delete payload.account
      delete payload.group
    }

    actions.editQuestionnaire({ ...payload, questionnaireFile, faxFormFile })

    setUploadSubmit(true)
  }

  const handleInputChange = (e, dropdown) => {
    const { name, value } = dropdown || e.currentTarget
    if (name === 'questionnaire_type' && value === 'Global') {
      questionnaireObject.account = ''
      questionnaireObject.carrier = ''
      questionnaireObject.group = ''
    }
    if (name === 'effective_date') {
      setQuestionnaireObject({
        ...questionnaireObject,
        [name]: value,
        term_date: ''
      })
      return;
    }

    setQuestionnaireObject({
      ...questionnaireObject,
      [name]: value,
    })
  }

  const readQuestionnaireFile = () => {
    if (!questionnaireFile) {
      const transitionalPortal = {
        header: 'No quesitonnaire file selected',
        copy: 'Please select a file to upload from Choose File'
      }
      props.actions.displayTransitionalPortal(transitionalPortal)
      return;
    }
    actions.parseQuestionnaireFile({ questionnaireFile })
  }

  const clearQuestionnaireFile = () => {
    fileElement.value = null
    setSelectedQuestionnaire(null)
    actions.clearQuestionnaireImportData()
    setQuestionnaireFileStatus(NOT_SET)
  }

  const handleQuestionnaireUpload = (e) => {
    setFileElement(e.target)
    setSelectedQuestionnaire(e.target.files[0])
    setQuestionnaireFileStatus(SELECTED)
  }

  const handleFaxFormFileUpload = (e) => {
    setSelectedFaxForm(e.target.files[0])
  }

  const attachmentOptions = [
    { key: 0, text: 'Yes', value: 'Y' },
    { key: 1, text: 'No', value: 'N' }
  ]

  const quesTypeOptions = [
    { key: 0, text: 'Global', value: 'Global' },
    { key: 1, text: 'Custom', value: 'Custom' }
  ]

  const questionnaireCategoryOptions = [
    { key: 0, text: 'Prior Authorization', value: 'pa' },
    { key: 1, text: 'Prior Authorization Default', value: 'pa_default' },
    { key: 2, text: 'Quantity Limit', value: 'ql' },
    { key: 3, text: 'Brand Medically Necessary', value: 'bmn' },
    { key: 3, text: 'Drug Not Covered', value: 'nc' }
  ]
  const questionCellRendererParams = {
    QuestionID: {
      wrapText: true,
      autoHeight: true,
    },
    "Question Text": {
      wrapText: true,
      autoHeight: true,
      width: 300,
    },
    "Response Type": {
      width: 150,
      autoHeight: true,
    },
  }

  const continueButtonParams = {
    className: "primary",
    id: "saveButton",
    name: "Save",
    onClick: () => { saveForm() }
  }

  return (
    <div id="importFeature">
      <BreadCrumb {...props} />
      {/* <div className="submenu">
        <SubmenuNav active="questionnaireImport" />
      </div> */}
      {questionnaireID ?
        <div className="header">Questionnaire Edit</div> :
        <div className="header">Questionnaire File Import</div>
      }
      <div className="content">
        <div className="section">
          <section className="questionnaire-input-container spacing border-line shadow">
            <div className='input-group'>
              <div className="questionnaire-input">
                <span>Questionnaire Type</span>
                <FliptDropdown value={form?.questionnaire_type} name="questionnaire_type" onChange={handleInputChange} options={quesTypeOptions} label="Questionnaire Type"
                  className="dropdown-width" />
              </div>
              <div className="questionnaire-input">
                <span>Questionnaire Name</span>
                <FliptInput name="questionnaire_name" defaultValue={form.questionnaire_name} onChange={handleInputChange} placeholder="Questionnaire Name" />
              </div>

              <div className="questionnaire-input">
                <span>Questionnaire Description</span>
                <FliptInput name="questionnaire_description" defaultValue={form.questionnaire_description} onChange={handleInputChange} placeholder="Questionnaire Description" />
              </div>
            </div>
            {form?.questionnaire_type === 'Custom' && <div className="input-group">
              <CAGFilters questionnaire className='questionnaire-cag' selectedCarrier={form.carrier} selectedAccount={form.account} selectedGroup={form.group} onFilterChange={handleInputChange} />
            </div>}
            <div className="input-group">
              <div className="questionnaire-input">
                <span>Effective Date</span>
                <FliptDatePicker className="effective-date" name="effective_date" value={convertStrToDateObj(form.effective_date)} onChange={handleInputChange} placeholder="Effective Date" />
              </div>
              <div className="questionnaire-input">
                <span>Term Date</span>
                <FliptDatePicker minDate={form.effective_date} name="term_date" className="term-date" value={convertStrToDateObj(form.term_date)} onChange={handleInputChange} placeholder="Term Date" />
              </div>
              <div className="questionnaire-input">
                <span>Contact Communication Number</span>
                <FliptInput maxlength="10" name="question_set_contact_communication_number" value={formatPhoneNumber(form.question_set_contact_communication_number)} onChange={handleInputChange} placeholder="Contact Communication Number" />
              </div>
            </div>
            <div className="input-group">
              <div className="questionnaire-input">
                <span>Attachment Required</span>
                <FliptDropdown value={form?.attachment_required} name="attachment_required" onChange={handleInputChange} options={attachmentOptions} label="Attachment Required"
                  className="dropdown-width" />
              </div>
              <div className="questionnaire-input">
                <span>Questionnaire Category</span>
                <FliptDropdown value={form?.questionnaire_category} name="questionnaire_category" onChange={handleInputChange} options={questionnaireCategoryOptions} label="Questionnaire Category"
                  className="dropdown-width" />
              </div>
              <div className="questionnaire-input">
                <span>Fax Form</span>
                <FliptInput type="file" name="fax_form" id="fax_form" onChange={handleFaxFormFileUpload} placeholder="Fax Form" /><label for="fax_form">{form?.fax_form_file_name}</label>
              </div>
            </div>
            <div className="input-group">
              <div className="questionnaire-input">
                <span>Questionnaire Policy</span>
                <FliptInput name="questionnaire_policy" defaultValue={form.questionnaire_policy} onChange={handleInputChange} placeholder="Questionnaire Policy" />
              </div>
              {form.questionnaire_policy && <FliptButton onClick={() => { window.open(form.questionnaire_policy, "_blank") }} name='Open Policy' className="primary searchButton open-policy" />}
              <div className="questionnaire-input">
                <span>Initial Approval Period</span>
                <FliptInput name="initial_approval_period" defaultValue={form.initial_approval_period} onChange={handleInputChange} placeholder="Initial Approval Period" />
              </div>
              <div className="questionnaire-input">
                <span>Recertification Approval Period</span>
                <FliptInput name="recertification_approval_period" defaultValue={form.recertification_approval_period} onChange={handleInputChange} placeholder="Recertification Approval Period" />
              </div>
            </div>
          </section>
        </div>
      </div>
      <div className="content">
        <div className="section">
          <section className="uploadInputContainer">
            <div className="uploadInput">
              <span className="element">Select data file in xlsx, csv, and xml format</span>
              <input type="file" name="file" id="file" onChange={handleQuestionnaireUpload} label={form?.questionnaire_file_name} /><label for="file">{form?.questionnaire_file_name}</label>
            </div>
            <div className="buttonContainer">
              <FliptButton name="Upload" onClick={() => readQuestionnaireFile()} className="primary searchButton" />
              <FliptButton name="Cancel" onClick={() => clearQuestionnaireFile()} className="secondary searchButton" />
            </div>
          </section>
        </div>
        {(uploadSubmit && props?.state?.questionFinalData && props?.state?.questionFinalData?.length > 0) ?
          <div className="section">
            <section className="outTableContainer">
              <TableViewer questionFinalData={props.state.questionFinalData} drugsFinalData={props.state.drugsFinalData} />
            </section>
          </div>
          : ""}
        {((form?.questionnaire && !uploadSubmit) || (form?.questionnaire && (!props?.state?.questionFinalData || props?.state?.questionFinalData.length === 0))) ?
          <div className="section">
            <section className="outTableContainer">
              <TableViewer
                questionFinalData={form?.questionnaire}
                drugsFinalData={form?.drugs}
                continueButtonParams={continueButtonParams}
              />
            </section>
          </div>
          : ""}
      </div>
						{tabFlag ? (

							<div id="questionnairePopup">
								<div className="content popup">
                  {
                    Object.keys(props.state.questionnaireErrorData).map((item) => {
                      let header = ["QuestionID", "Question Text", "Response Type"]
                      let data = props.state.questionnaireErrorData[item]
                      if(item === "questions_text_with_invalid_charectars"){
                        header.push("special_charectars")
                      }
                      return (
                        <div className="errorSection">
                          <h1>{item}</h1>
                          <div className="popupTable">
                            <FliptGrid 
                              headers={header}
                              data={data}
                              cellRendererParams={questionCellRendererParams}
                            />
                          </div>
                        </div>
                      )
                    }
                    )
                  }

								<div className="buttonContainer">
									<FliptButton
									name="Quit"
									className="secondary searchButton"
									onClick={() => {setTabFlag(false)}}
									/>
								</div>

								</div>
							</div>
							) : (
							""
							)}
    </div>
  )
}

const mapStateToProps = (state) => ({
  state: {
    user: state.user,
    questionFinalData: state.questionnaireBuilder.questionFinalData,
    drugsFinalData: state.questionnaireBuilder.drugsFinalData,
    questionnaireData: state.priorAuthorizationCreation.questionnaireData,
    questionnaireImportData: state?.questionnaireBuilder?.questionnaireImportData,
    questionnaireErrorData: state?.questionnaireBuilder?.questionnaireErrorData,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
    ...QuestionnaireBuilderActions,
    ...UserActions,
    ...PriorAuthorizationCreationActions,
    ...NavigationActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuestionnaireImport)
