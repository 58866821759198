/* eslint-disable */
import {
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects'

import { Types, Creators as FormularyLookupActions } from '../../reducers/api/formularyLookup'
import { Creators as AppActions } from '../../reducers/app'
import { getAppState, getApiPath, getHistory } from '../../reducers/selectors'
import { fetchPost } from '../../../utils/fetchApi'
import { convertStrToDateObj } from '../../../utils/utilities'
import { remove } from 'lodash'

export default [
  getFormularyLookupDataWatcher,
  getPlanNameLookupWatcher,
  getGpiLookupWatcher,
]

/* WATCHERS */
function* getFormularyLookupDataWatcher() {
  yield takeLatest(Types.GET_FORMULARY_LOOKUP_DATA, getFormularyLookupDataHandler)
}

function* getPlanNameLookupWatcher() {
  yield takeLatest(Types.GET_PLAN_NAME_LOOKUP_DATA, getPlanNameLookupHandler)
}

function* getGpiLookupWatcher() {
  yield takeLatest(Types.GET_GPI_LOOKUP_DATA, getGpiLookupDataHandler)
}

/* HANDLERS */
function* getFormularyLookupDataHandler({ payload }) {
  try {
    const history = yield select(getHistory)
    const { state: locationState } = history.location
    const state = { ...locationState }
    if (!state || (!state.gpi && !state.ndc && !state.drug_name)) {
      return
    }
    // if (!state.carrier) {
    //   delete state.carrier
    // }
    if ("domain_name" in state) {
      state.account = state.domain_name;
      delete state.domain_name
    }
    if (!state.date_of_service) {
      delete state.date_of_service
    }
    // if (!state.date_of_service.includes("/")) {
    //   state.date_of_service = convertStrToDateObj(state.date_of_service, { format: 'YYYYMMDD', toISO: true })
    // }
    const finalResult = []
    const gpiData = yield call(getGpiLookupDataHandler)

    if (gpiData.length == 0) {
      const transitionalPortal = {
        header: 'No Data Found',
        copy: '0 Results',
      }
      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
      return
    }

    yield* gpiData.map(function* (eachGpiData) {
      if (state.gpi) {
        state.gpi = eachGpiData[0].gpi
      }
      else {
        state.gpi = eachGpiData?.length ? eachGpiData[0].gpi : ''
      }

      if (!state.gpi) {
        return
      }
      if (state.drug_name) {
        delete state.drug_name
      }
      if (state.date_of_birth) {
        var dob_date = ""
        var dob_month = ""
        const resultdob = state.date_of_birth.split('/')
        if (resultdob[1].length == 1) {
          dob_date = "0" + resultdob[1]
        }
        else {
          dob_date = resultdob[1]
        }
        if (resultdob[0].length == 1) {
          dob_month = "0" + resultdob[0]
        }
        else {
          dob_month = resultdob[0]
        }
        const dob = `${resultdob[2]}-${dob_month}-${dob_date} 00:00:00`
        state["member_details"] = { date_of_birth: dob }
      }

      const { serviceUrl } = yield select(getAppState)
      const { api_path } = yield select(getApiPath, 'formulary-lookup')
      const url = `${serviceUrl}${api_path}`
      const { data } = yield call(fetchPost, url, state)
      var ndcFormularyCombined = []
      if (data.length != 0 && data[0].ndc) {
        ndcFormularyCombined = eachGpiData.map(x => Object.assign(x, data.find(y => ((y.ndc == x.ndc) && (y["record"]?.length != 0)))))
      }
      else if (data.length != 0) {
        data.map(n => delete n.ndc)
        ndcFormularyCombined = eachGpiData.map(x => Object.assign(x, data.find(y => ((y.drug_name == x.drug_name) && (y.ddid == x.ddid) && (y.gpi == x.gpi) && (y["record"]?.length != 0)))))
      }
      const combinedDataLength = ndcFormularyCombined.length
      if (combinedDataLength) {
        let sortedNdcFormularyCombined = ndcFormularyCombined.sort((a, b) => a.ndc - b.ndc)
        if (!!state.ndc) {
          sortedNdcFormularyCombined = [sortedNdcFormularyCombined.find(d => d.ndc === state.ndc), ...sortedNdcFormularyCombined.filter(d => d.ndc !== state.ndc)]
        }
        if (finalResult.length == 0) {
          finalResult.push(sortedNdcFormularyCombined)
        }
        else {
          sortedNdcFormularyCombined.map(finalObj => {
            finalResult[0].push(finalObj)
          })
        }
      }
      if (finalResult.length == 0) {
        const transitionalPortal = {
          header: 'No Data Found',
          copy: '0 Results',
        }
        yield put(AppActions.displayTransitionalPortal(transitionalPortal))
        return
      }

    })
    yield put(FormularyLookupActions.setFormularyLookupData({ data: finalResult[0], total_rows: finalResult[0]?.length }))



  } catch (err) {
    console.log('getFormularyLookupDataHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Formulary Lookup Failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* getPlanNameLookupHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const { api_path } = yield select(getApiPath, 'plan-design-management')
    const url = `${serviceUrl}${api_path}`
    const { data } = yield call(fetchPost, url, payload)
    yield put(FormularyLookupActions.setPlanNameLookupData({ data }))
  } catch (err) {
    console.log('getPlanNameLookupHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Get Plan Failed',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* getGpiLookupDataHandler() {
  try {
    const history = yield select(getHistory)
    const { state } = history.location
    const { serviceUrl } = yield select(getAppState)
    const { api_path } = yield select(getApiPath, 'gpi-lookup')
    const url = `${serviceUrl}${api_path}`
    const { data } = yield call(fetchPost, url, state)
    return data
  } catch (err) {
    console.log('getGpiLookupDataHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'GPI Lookup Failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

export {
  getFormularyLookupDataHandler,
  getPlanNameLookupHandler,
  getGpiLookupDataHandler,
}
