import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Config from '../../../../config'
import './styles.scss'
import BreadCrumb from '../../../../components/breadCrumb'
import FliptButton from '../../../../components/form/fliptButton'
import FliptGrid from '../../../../components/fliptGrid'
import { Creators as NavigationActions } from '../../../../redux/reducers/navigation'
import { Creators as teamManagementActions } from '../../../../redux/reducers/teamManagement'
import { LinkContainerRenderer } from '../../../../components/fliptGrid/cellRenderers'
import { convertDateTimeToDate, filterRowData } from '../../../../utils/utilities'

class TeamManagement extends Component {
  componentDidMount() {
    const { actions } = this.props
    setTimeout(() => actions.getTeamListData(), Config.COMPONENT_DATA_LOAD_TIME)
  }

  _dataCleanup = () => {
    const { state: { data } } = this.props
    return data.map((d) => ({
      id: d.id,
      name: d.name,
      description: d.description,
      effective_start_date: convertDateTimeToDate(d.effective_start_date),
      effective_end_date: d.effective_end_date === '' ? '---' : convertDateTimeToDate(d.effective_end_date),
      status: d.active ? 'Active' : 'Inactive',
    }))
  }

  render() {
    let gridData = this._dataCleanup()
    const headers = [
      'Action',
      'Team Name',
      'Description',
      'Effective Start Date',
      'Effective End Date',
      'Status',
      'id',
    ]
    const headerMapping = {
      name: 'Team Name',
      description: 'Description',
      effective_start_date: 'Effective Start Date',
      effective_end_date: 'Effective End Date',
      status: 'Status',
    }
    gridData = gridData && gridData.length ? gridData.map((d) => ({
      ...filterRowData(d, headers, headerMapping),
    })) : []

    const cellRendererParams = {
      Action: {
        // TODO LinkContainerRenderer does not work with state
        cellRenderer: LinkContainerRenderer,
        searchArgs: [
          {
            path: '/team-management-edit',
            searchArgs: { id: 'id' },
            staticState: { editMode: false },
            label: 'View',
          },
          {
            path: '/team-management-edit',
            searchArgs: { id: 'id' },
            staticState: { editMode: true },
            label: 'Edit',
          },
        ],
      },
      id: {
        hide: true,
      },
    }
    return (
      <div id="teamManagement">
        <BreadCrumb {...this.props} />
        <div className="header">
          <h1>Team Management</h1>
        </div>
        <div className="buttonContainer" align="right">
          <Link to="/team-management-create">
            <FliptButton
              name="CREATE NEW TEAM"
              className="primary searchButton"
            />
          </Link>
        </div>
        <div className="content">
          <FliptGrid
            data={gridData}
            headers={headers}
            cellRendererParams={cellRendererParams}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  state: {
    data: state.teamManagement.teamListing,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...teamManagementActions,
    ...NavigationActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TeamManagement)
