import React from 'react'

import { Button } from 'semantic-ui-react'

export default function ExportButton(props) {
  const {
    grid,
  } = props

  return (
    <Button compact size="small" onClick={() => grid?.exportDataAsExcel()} color="youtube">
      Export Excel Listing
    </Button>
  )
}
