/* NOT CURRENTLY IN USE */
import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import './styles.scss'
import BreadCrumb from '../../../../components/breadCrumb'
// import FliptGrid from '../../../../components/fliptGrid'
import { Creators as AppActions } from '../../../../redux/reducers/app'
import { Creators as FormularyActions } from '../../../../redux/reducers/api/formularyLookup'
import { Creators as FormularyCreationActions } from '../../../../redux/reducers/api/formularyCreationManagement'
import { Creators as NavigationActions } from '../../../../redux/reducers/navigation'

class FormularyLookup extends Component {
  componentDidMount() {
    // adding back in due to linter error
  }

  render() {
    return (
      <div id="formularyLookup">
        <BreadCrumb {...this.props} />
        <div className="header">Formulary Tier Search</div>
        <div className="subhead" />

        <div className="content">
          {/* <FliptGrid /> */}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  state: {
    formularyLookup: state.formularyLookup,
    formularyDataPerPage: state.formularyLookup.formularyDataPerPage,
    formularyDrugNameData: state.formularyCreation.formularyDrugNameData,
    user: state.user,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
    ...FormularyActions,
    ...FormularyCreationActions,
    ...NavigationActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormularyLookup)
