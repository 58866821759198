import React, { PureComponent } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { isEmpty, debounce } from 'lodash'
import { Icon } from 'semantic-ui-react'
import { Creators as AppActions } from '../../../../redux/reducers/app'
import FliptGrid from '../../../../components/fliptGrid'
import { DeleteRowRenderer } from '../../../../components/fliptGrid/cellRenderers'
import { DropdownEditor, InputTextEditor } from '../../../../components/fliptGrid/cellEditors'
import AddRow from '../addRow'
import FliptLabel from '../../../../components/form/fliptLabel'
import './styles.scss'
import { pricingMixmaster as rowCellInfo } from '../data/rowCellInfo'
import { resolveMessage } from '../../../../utils/validationHelpers'

class PricingMixmaster extends PureComponent {
  constructor(props) {
    super(props)
    const { state } = this.props
    const { price_source = [], otc_inclusion = 'N', otc_price_source = [] } = state
    this.state = {
      price_source,
      otc_inclusion,
      otc_price_source,
    }
  }

  getGridHeaders = () => [
    'action',
    'rank',
    'claim_processor_group',
    'claim_processor',
    'contract_type',
    'direct_contract',
    'mixmaster_price_types',
    'pharmacy_type',
    'network_tier',
    'priority_price_types',
    'exclusive',
    'application',
  ]

  renderGrid = (data, grid) => {
    const { fieldDetails, editMode } = this.props
    const rowCellInfoCopy = rowCellInfo
    rowCellInfoCopy.application.options = [{ key: 'Claims Processor', text: 'Claims Processor', value: 'claims_processor' }, { key: 'Application/Web', text: 'Application/Web', value: 'app_web' }]
    const headers = this.getGridHeaders()
    if (!editMode) {
      headers.splice(0, 1)
    }
    const handleChange = (e, dropdown, rowKey) => {
      this.handleChange(e, dropdown, rowKey, grid)
    }
    const handleGroupRankChange = debounce((e, dropdown, rowKey) => {
      this.handleGroupRankChange(e, dropdown, rowKey, grid)
    }, 1000)
    fieldDetails.forEach((element) => {
      const { field } = element
      let field_name = field
      if (field === grid) {
        field_name = 'claim_processor'
      }
      if (!(field_name in rowCellInfoCopy)) {
        return
      }

      rowCellInfoCopy[field_name].options = element.options.map((code, index) => ({
        key: index,
        text: code.display_name,
        value: code.value,
        map_value: code.map_value,
      }))
    })
    const cellRendererParams = {
      action: {
        cellRenderer: DeleteRowRenderer,
        state: {
          onClick: (index) => this.delRow(index, grid),
        },
        width: 100,
      },
      rank: {
        width: 100,
        overrideHeader: 'Rank',
      },
      claim_processor_group: {
        width: 200,
        overrideHeader: 'Claim Processor Group',
      },
      claim_processor: {
        width: 200,
        overrideHeader: 'Contract',
      },
      contract_type: {
        width: 200,
        overrideHeader: 'Contract Type',
      },
      direct_contract: {
        width: 175,
        overrideHeader: 'Direct Contract',
      },
      mixmaster_price_types: {
        width: 200,
        overrideHeader: 'Price Type',
      },
      pharmacy_type: {
        hide: true,
      },
      network_tier: {
        width: 200,
        overrideHeader: 'Network Tier',
      },
      priority_price_types: {
        width: 200,
        overrideHeader: 'Priority Price Type',
      },
      exclusive: {
        width: 145,
        overrideHeader: 'Exclusive',
      },
    }
    const cellEditorParams = {
      rank: {
        editable: true,
        cellEditor: InputTextEditor,
        onChange: handleGroupRankChange,
        validation: 'numeric',
        inputProps: {
          maxlength: 1,
        }
      },
      claim_processor_group: {
        editable: true,
        cellEditor: InputTextEditor,
        onChange: handleGroupRankChange,
      },
      priority_price_types: {
        editable: true,
        cellEditor: DropdownEditor,
        onChange: handleChange,
      },
      // below code is code is commented because
      // dropdown options coming from the api are incorrect
      // but front end functionality works fine and is implemented
      // claim_processor: {
      //   editable: true,
      //   cellEditor: DropdownEditor,
      //   onChange: handleChange,
      // },
      // contract_type: {
      //   editable: true,
      //   cellEditor: DropdownEditor,
      //   onChange: handleChange,
      // },
      // network_tier: {
      //   editable: true,
      //   cellEditor: DropdownEditor,
      //   onChange: handleChange,
      // },
      // mixmaster_price_types: {
      //   editable: true,
      //   cellEditor: DropdownEditor,
      //   onChange: handleChange,
      // },
      // exclusive: {
      //   editable: true,
      //   cellEditor: DropdownEditor,
      //   onChange: handleChange,
      // },
      // application: {
      //   editable: true,
      //   cellEditor: DropdownEditor,
      //   onChange: handleChange,
      // },
    }
    if (editMode) {
      return (
        <div className="grid-container">
          <FliptGrid
            data={data}
            headers={this.getGridHeaders(grid)}
            cellRendererParams={cellRendererParams}
            cellEditorParams={cellEditorParams}
            rowCellInfo={rowCellInfoCopy}
          />
        </div>
      )
    }
    return (
      <div className="grid-container">
        <FliptGrid
          data={data}
          headers={headers}
        />
      </div>
    )
  }

  addRow = (grid) => {
    const { [grid]: source } = this.state
    let tempSource = [...source]
    const headers = this.getGridHeaders(grid)
    headers.splice(0, 1) // remove action from headers
    const row = headers.reduce((obj, v) => {
      /*if(v === 'rank'){
        obj[v] = (source?.length || 0) + 1
        return obj
      }*/
      obj[v] = rowCellInfo[v]?.default || ''
      return obj
    }, {})
    tempSource.push(row)
    const newState = {
      ...this.state,
      [grid]: tempSource,
    }
    this.setState(newState)
  }

  delRow = (rowIndex, grid) => {
    const { [grid]: source } = this.state
    const newSource = [...source]
    newSource.splice(rowIndex, 1) // Delete selected row
    const newState = {
      ...this.state,
      [grid]: newSource,
    }
    this.setState(newState)
    this.validateAndUpdateData({ ...this.state, [grid]: newSource })
  }

  handleCheckboxChange = () => {
    const { otc_inclusion } = this.state
    const newState = {
      ...this.state,
      otc_inclusion: (otc_inclusion === 'Y') ? 'N' : 'Y',
    }
    this.setState(newState)
  }

  handleChange = (e, dropdown, rowKey, source) => {
    const { [source]: gridSource } = this.state
    const { name, value, options } = dropdown
    const newgridSource = [...gridSource]
    newgridSource[rowKey] = { ...newgridSource[rowKey], [name]: value }
    if (name == "claim_processor") {
      newgridSource[rowKey] = { ...newgridSource[rowKey], "contract_type": options.filter(f => f.value === value)[0].map_value }
    }
    this.setState({ [source]: newgridSource })
    this.validateAndUpdateData({ ...this.state, [source]: newgridSource })
  }

  handleGroupRankChange = (e, dropdown, rowKey, source) => {
    const { [source]: gridSource } = this.state
    const { name, value } = e.target
    const newgridSource = [...gridSource]
    newgridSource[rowKey] = { ...newgridSource[rowKey], group_rank: { ...(newgridSource[rowKey]?.group_rank || {}), [name]: value } }
    this.setState({ [source]: newgridSource })
    this.validateAndUpdateData({ ...this.state, [source]: newgridSource })
  }

  validateAndUpdateData = (data) => {
    const { copyFormData, fieldDetails } = this.props
    let error = ''
    const { price_source, otc_price_source, otc_inclusion } = data
    const price_source_copy = price_source.map((o => {
      o['application'] = o?.application || []
      return o
    }))
    const otc_price_source_copy = otc_price_source.map((o => {
      o['application'] = o?.application || []
      return o
    }))
    const requiredFields = fieldDetails.filter((ele) => ele.required).map((ele) => ele.field)
    const isPriceSourceValid = price_source.every((ele) => requiredFields.every((key) => {
      let rankValidation = true
      if (!isEmpty(ele?.group_rank?.rank)) {
        ele.rank = ele?.group_rank?.rank
        rankValidation = !isEmpty(ele.group_rank.claim_processor_group)
      }
      else {
        ele.rank = null
      }
      if (key in ele) {
        return !isEmpty(ele[key]) && rankValidation
      }
      return true && rankValidation
    }))
    const isOtcPriceSourceValid = otc_price_source.every((ele) => requiredFields.every((key) => {
      let rankValidation = true
      if (!isEmpty(ele?.group_rank?.rank))
        rankValidation = !isEmpty(ele.group_rank.claim_processor_group)
      if (key in ele) {
        return !isEmpty(ele[key]) && rankValidation
      }
      return true && rankValidation
    }))
    if (!isPriceSourceValid || !isOtcPriceSourceValid) {
      error = resolveMessage("val_missing_required_fields").message
    }
    copyFormData({ error, data: { ...data, otc_inclusion, price_source: price_source_copy, otc_price_source: otc_price_source_copy } })
  }

  render() {
    const { editMode, state: { pharmacy_network_tier_rank } } = this.props
    const { price_source, otc_inclusion, otc_price_source } = this.state
    const priceSourceCopy = price_source.map(e => ({ ...e, ...e.group_rank }))
    const otcPriceSourceCopy = otc_price_source.map(e => ({ ...e, ...e.group_rank }))

    return (
      <div id="PricingMixmaster">
        {
          !pharmacy_network_tier_rank.length &&
          <div className='network-warning-message-container'>
            <Icon name='exclamation triangle' color={'red'} />
            <span className='network-warning-message'>Please define Pharmacy Networks prior to defining Pricing Setup</span>
          </div>
        }
        {this.renderGrid(priceSourceCopy, 'price_source')}
        {editMode && (
          <div className="addRowButtonContainer">
            {/* disabling until backend return correct dropdown options */}
            <AddRow disabled addRow={() => this.addRow('price_source')} />
          </div>
        )}
        <div onClick={this.handleCheckboxChange} className={`ui ${editMode ? 'checked' : 'disabled'} checkbox`}>
          <input type="checkbox" onClick={this.handleCheckboxChange} checked={otc_inclusion === 'Y'} disabled={!editMode && 'disabled'} />
          <FliptLabel
            description="OTC Drug Covered"
            label="OTC Drug Covered"
          />
        </div>
        {otc_inclusion === 'Y' && (
          <>
            {this.renderGrid(otcPriceSourceCopy, 'otc_price_source')}
            {editMode && (
              <div className="addRowButtonContainer">
                {/* disabling until backend return correct dropdown options */}
                <AddRow disabled addRow={() => this.addRow('otc_price_source')} />
              </div>
            )}
          </>
        )}
        <div className="section-header"> </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  state: {
    app: state.app,
    price_source: state.planManagement?.pricingMixMaster?.price_source || [],
    otc_inclusion: state.planManagement?.pricingMixMaster?.otc_inclusion || false,
    otc_price_source: state.planManagement?.pricingMixMaster?.otc_price_source || [],
    pharmacy_network_tier_rank: state.planManagement?.planDetails?.model_data?.pharmacy_network_rank || [],
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

const pricingMixMasterContainer = (props) => (
  <PricingMixmaster
    editMode
    {...props}
  />
)

export default PricingMixmaster

export const PricingMixMasterContainer = connect(mapStateToProps, mapDispatchToProps)(pricingMixMasterContainer)
