import React, { PureComponent } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import _ from 'lodash'

import { Creators as AppActions } from '../../../../redux/reducers/app'
import FliptGrid from '../../../../components/fliptGrid'
import { DeleteRowRenderer, DropdownRenderer } from '../../../../components/fliptGrid/cellRenderers'
import { DatePickerEditor, DropdownEditor, InputTextEditor } from '../../../../components/fliptGrid/cellEditors'
import AddRow from '../addRow'
import './styles.scss'
import { grandfathering as rowCellInfo } from '../data/rowCellInfo'
import { convertStrToDateObj } from '../../../../utils/utilities'
import moment from 'moment'

class Grandfathering extends PureComponent {
	constructor(props) {
		super(props)
		const { state: { grandfathering = [] } } = this.props
		this.state = {
			grandfathering,
		}
	}

	renderGrid = (data, grid) => {
		const { fieldDetails, editMode, state } = this.props
		const { headers, cellRendererParams, cellEditorParams, rowCellInfo: rowCellInfoCopy } = this.getGridParams(grid)
		
		// Remove delete row if not in edit mode
		if (!editMode) {
      headers.splice(0, 1)
    }
		fieldDetails?.forEach((element) => {
      const { field } = element
      if (!(field in rowCellInfoCopy)) {
        return
      }

      rowCellInfoCopy[field].options = element.options.map((code, index) => ({
        key: index,
        text: code.display_name,
        value: code.value,
      }))
    })

		const gridProps = editMode ? {
			cellEditorParams,
			cellRendererParams,
			rowCellInfo: rowCellInfoCopy,
		} : {}

		return (
			<div className="grid-container">
				<FliptGrid
					data={data}
					headers={headers}
					{...gridProps}
				/>
			</div>
		)
	}

	addRow = (grid) => {
		const { headers } = this.getGridParams(grid)
		const { grandfathering } = this.state
		// remove action header
		headers.splice(0, 1)
		let dataToUpdate = []
		const row = headers.reduce((obj, v) => {
      obj[v] = ''
			if (['brand_generic', 'multi_source', 'doc_name'].includes(v)) obj[v] = [] 
      return obj
    }, {})
		switch(grid) {
			case 'grandfathering': {
				dataToUpdate = grandfathering
				break
			}
			default:
				break
		}
		const newState = {
			...this.state,
			[grid]: [...dataToUpdate, row],
		}
		this.setState(newState)
		this.validateAndUpdateData({...newState})
	}

	delRow = (rowIndex, grid) => {
		const { grandfathering } = this.state
		let dataToUpdate = []
		switch(grid) {
			case 'grandfathering': {
				dataToUpdate = grandfathering
				break
			}
			default:
				break
		}

		const newSource = [...dataToUpdate]
		newSource.splice(rowIndex, 1)
		const newState = {
			...this.state,
			[grid]: newSource,
		}
		this.setState(newState)
		this.validateAndUpdateData({ ...newState })
	}

	handleChange = (e, dropdown, rowKey, grid) => {
		const { state: { grandfathering } } = this
		const { name, value } = dropdown || e.currentTarget
		let dataToUpdate = []
		switch(grid) {
			case 'grandfathering': {
				dataToUpdate = grandfathering
				break
			}
			default:
				break
		}
		const dataCopy = [...dataToUpdate]
		dataCopy[rowKey] = { ...dataCopy[rowKey], [name]: value }
		_.debounce(() => this.setState({ [grid]: dataCopy }), 100)

		this.validateAndUpdateData({ ...this.state, [grid]: dataCopy })
	}

	validateAndUpdateData = (data) => {
		const { copyFormData } = this.props

		copyFormData({ data })
	}

	getGridParams = (grid) => {
		const handleChange = (e, dropdown, rowKey) => {
			this.handleChange(e, dropdown, rowKey, grid)
		}
		// TODO: eventually make a shared function for all ag grids that just takes one field and sets header, cellRenderer, and cellEditor
		const gridParams = {
			grandfathering: {
				headers: [
					'delete',
					'function',
					'brand_generic',
					'multi_source',
					'gpi_match_level',
					'maintenance_indicator',
					'otc_indicator',
					'doc_name',
					'lookback_period',
					'effective_start_date',
					'effective_end_date',
					'reject_code',
					'penalty',
				],
				cellRendererParams: {
					delete: {
						cellRenderer: DeleteRowRenderer,
						state: {
							onClick: (index) => this.delRow(index, grid),
						},
						width: 100,
					},
					function: {
						width: 110,
						overrideHeader: 'Function',
						cellRenderer: DropdownRenderer,
					},
					brand_generic: {
						width: 145,
						overrideHeader: 'Brand/Generic',
					},
					multi_source: {
						width: 175,
						overrideHeader: 'Multi-Source Code',
					},
					gpi_match_level: {
						width: 155,
						overrideHeader: 'GPI Match Level',
					},
					maintenance_indicator: {
						width: 235,
						overrideHeader: 'Maintenance Drug Indicator',
					},
					otc_indicator: {
						width: 145,
						overrideHeader: 'OTC Indicator',
					},
					doc_name: {
						width: 150,
						overrideHeader: 'Program Name',
					},
					lookback_period: {
						width: 160,
						overrideHeader: 'Lookback Period',
					},
					effective_start_date: {
						width: 175,
						overrideHeader: 'Effective Start Date',
						valueFormatter: (params) => {
              if (!params.value) return ''
              return moment(params.value)?.format('MMM Do YYYY') || params.value
            },
					},
					effective_end_date: {
						width: 175,
						overrideHeader: 'Effective End Date',
						valueFormatter: (params) => {
              if (!params.value) return ''
              return moment(params.value)?.format('MMM Do YYYY') || params.value
            },
					},
					reject_code: {
						width: 130,
						overrideHeader: 'Reject Code',
					},
					penalty: {
						width: 165,
						overrideHeader: 'Penalty Indicator',
					},
				},
				cellEditorParams: {
					function: {
						editable: true,
						cellEditor: DropdownEditor,
						onChange: handleChange,
					},
					brand_generic: {
						editable: true,
						cellEditor: DropdownEditor,
						onChange: handleChange,
					},
					multi_source: {
						editable: true,
						cellEditor: DropdownEditor,
						onChange: handleChange,
					},
					gpi_match_level: {
						editable: true,
						cellEditor: DropdownEditor,
						onChange: handleChange,
					},
					maintenance_indicator: {
						editable: true,
						cellEditor: DropdownEditor,
						onChange: handleChange,
					},
					otc_indicator: {
						editable: true,
						cellEditor: DropdownEditor,
						onChange: handleChange,
					},
					doc_name: {
						editable: true,
						cellEditor: DropdownEditor,
						onChange: handleChange,
					},
					lookback_period: {
						editable: true,
						cellEditor: InputTextEditor,
						onChange: handleChange,
						validation: 'numeric',
					},
					effective_start_date: {
						editable: true,
						cellEditor: DatePickerEditor,
						onChange: handleChange,
					},
					effective_end_date: {
						editable: true,
						cellEditor: DatePickerEditor,
						onChange: handleChange,
					},
					reject_code: {
						editable: true,
						cellEditor: DropdownEditor,
						onChange: handleChange,
					},
					penalty: {
						editable: true,
						cellEditor: DropdownEditor,
						onChange: handleChange,
					},
				},
				rowCellInfo: {
					...rowCellInfo,
				},
			},
		}

		return gridParams[grid]
	}

	render() {
		const { props: { editMode }, state: { grandfathering = [] }} = this

		return (
			<div id="Grandfathering">
				{this.renderGrid(grandfathering, 'grandfathering')}
				{editMode && (
					<div className="addRowButtonContainer">
						<AddRow addRow={() => this.addRow('grandfathering')} />
					</div> 
				)}
			</div>
		)
	}
}

const mapStateToProps = (state, props) => {
	const { fieldDetails } = props
	const now = new Date()
	fieldDetails.find(({field}) => field === 'doc_name').options = fieldDetails.find(({field}) => field === 'doc_name').options.filter(({effective_end_date, status}) => !(effective_end_date && convertStrToDateObj(effective_end_date) < now) && status === 'Published')

	return ({
		state: {
			app: state.app,
			grandfathering: state?.planManagement?.grandfatheringData?.grandfathering,
			fieldDetails,
		}
	})
}

const mapDispatchToProps = (dispatch) => {
	const allActions = {
		...AppActions,
	}

	return {
		actions: bindActionCreators(allActions, dispatch)
	}
}

const grandfatheringContainer = (props) => (
	<Grandfathering
		editMode
		{...props}
	/>
)

export default Grandfathering

export const GrandfatheringContainer = connect(mapStateToProps, mapDispatchToProps)(grandfatheringContainer)