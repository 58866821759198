import React, { useState, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as _ from 'lodash'
import Config from '../../../config'
import FliptGrid from '../../../components/fliptGrid'
import BreadCrumb from '../../../components/breadCrumb'
import { LinkContainerRenderer, ColorCodedStatusRenderer } from '../../../components/fliptGrid/cellRenderers'
import FliptButton from '../../../components/form/fliptButton'
import PopupCellRenderer from './popup/popupCellRenderer'
import { Link } from 'react-router-dom'

import { Creators as DrugUtilizationReviewActions } from '../../../redux/reducers/api/drugUtilizationReview'
import './styles.scss'
import TabBar from '../../../components/tabBar'
import { DUR_LOCAL_STORAGE_KEY, defaultTabConfigs, drugHeaders, programHeaders, regimenComplianceHeaders } from './tabData'

const DurSummary = (props) => {
    // tabName
    const { state, actions, location } = props
    const { durSummaryData: dat, programDashboardData } = state
    const [data, setData] = useState(dat)
    const [programData, setProgramData] = useState([])
    const [selectedTab, setSelectedTab] = useState('')

    const cellRendererParams = {
        action: {
            cellRenderer: PopupCellRenderer,
            searchArgs: {
                onClick: (action, rowdata) => checkActionType(action, rowdata),
                selectedTab: selectedTab
            },
            width: 100,
        },
        doc_name: {
            cellRenderer: LinkContainerRenderer,
            searchArgs: [
                {
                    path: '/drug-utilization-review',
                    searchArgs: { doc_id: 'doc_id', 'doc_name': 'doc_name', doc_version: 'doc_version' },
                    state: { editMode: true },
                    key: 'doc_name',
                    staticState: { selectedTab },
                    staticSearchArgs: {},
                },
            ],
            overrideHeader: 'Configuration Name',
            width: 350,
        },
        dur_type: {
            overrideHeader: 'DUR Edit Type',
            width: 350,
        },
        doc_version: {
            overrideHeader: 'Version',
        },
        status: {
            cellRenderer: ColorCodedStatusRenderer,
        }
    }
    const programCellRendererParams = {
        program_action: {
            cellRenderer: PopupCellRenderer,
            overrideHeader: 'Action',
            searchArgs: {
                onClick: (action, rowdata) => checkActionType(action, rowdata),
                selectedTab: selectedTab
            },
            width: 100,
        },
        program_name: {
            cellRenderer: LinkContainerRenderer,
            searchArgs: [
                {
                    path: '/add-global-dur',
                    searchArgs: { doc_id: 'doc_id', 'doc_name': 'doc_name', doc_version: 'doc_version' },
                    state: { doc_id: 'doc_id' },
                    key: 'doc_name',
                    staticState: { selectedTab, viewOnly: true },
                    staticSearchArgs: {},
                },
            ],
            overrideHeader: 'Configuration Name',
            width: 350,
        },
        program_version: {
            overrideHeader: 'Version',
        },
        program_status: {
            overrideHeader: 'Status',
            cellRenderer: ColorCodedStatusRenderer,
        },
        program_create_date: {
            overrideHeader: 'Create Date',
        }
    }

    const regimenCellRendererParams = {
        action: {
            cellRenderer: PopupCellRenderer,
            searchArgs: {
                onClick: (action, rowdata) => checkActionType(action, rowdata),
                selectedTab: selectedTab
            },
            width: 100,
        },
        doc_name: {
            cellRenderer: LinkContainerRenderer,
            searchArgs: [
                {
                    path: '/drug-utilization-review',
                    searchArgs: { doc_id: 'doc_id', 'doc_name': 'doc_name', doc_version: 'doc_version' },
                    state: { editMode: true },
                    key: 'doc_name',
                    staticState: { selectedTab },
                    staticSearchArgs: {},
                },
            ],
            overrideHeader: 'Configuration Name',
            width: 350,
        },
        dur_type: {
            overrideHeader: 'DUR Edit Type',
            width: 350,
        },
        status: {
            cellRenderer: ColorCodedStatusRenderer,
        },
        doc_version: {
            overrideHeader: 'Version',
        },
        create_date: {
            overrideHeader: 'Create Date',
        }
    }

    useEffect(() => {
        if (location?.state?.tabName) setActiveTab(location.state.tabName)
    }, [])

    useEffect(() => {
        if (dat && !_.isEqual(data, dat)) {
            setData(dat)
        }
    }, [dat])

    useEffect(() => {
        if (programDashboardData?.length) {
            const parsedData = programDashboardData.map((row) => ({
                ...row,
                program_name: row.doc_name,
                program_version: row.doc_version,
                program_status: row.status,
                program_create_date: row.create_date,
                doc_id: row.doc_id
            }))
            setProgramData(parsedData)
        }
    }, [programDashboardData])

    const checkActionType = (action, rowdata) => {
        if (action === 'publish_doc')
            publishDoc(rowdata)
        if (action === 'create_new_version')
            createNewVersion(rowdata)
    }

    const publishDoc = (form) => {
        if (selectedTab === defaultTabConfigs[0].id) {
            actions.publishGlobalDur({
                doc_id: form.doc_id,
                doc_version: form.program_version,
                doc_type: 'global_dur',
                status: 'Published',
            })
            return
        }
        const payload = {
            doc_id: form?.doc_id,
            doc_name: form?.doc_name,
            doc_version: form?.doc_version,
            status: 'Published',
            doc_type: 'drug_interaction_parameter',
        }
        actions.publishDurDoc(payload, form?.plan_id)
    }

    const createNewVersion = (form) => {
        if (selectedTab === defaultTabConfigs[0].id) {
            actions.createNewGlobalDur({
                doc_id: form.doc_id,
                doc_version: form.program_version,
                doc_type: 'global_dur',
            })
            return
        }
        const payload = {
            doc_id: form?.doc_id,
            doc_name: form?.doc_name,
            doc_version: form?.doc_version,
            status: 'Published',
            doc_type: 'drug_interaction_parameter',
        }
        actions.createNewDurVersion(payload)
    }

    const setActiveTab = (id) => {
        if (id !== defaultTabConfigs[0].id) actions.getDurSummaryData()
        else actions.getGlobalDur()
        setSelectedTab(id)
    }

    const getGridData = () => {
        const filteredData = data?.length ? data.filter((data) => {
            return data.dur_type === selectedTab
        }) : []
        return filteredData
    }

    return (
        <div id="durSummary">
            <div className='heading'>
                <BreadCrumb {...props} />
                <div className="header">
                    <div className='tab-bar'>
                        <TabBar
                            tabs={defaultTabConfigs}
                            setActiveTab={(id) => setActiveTab(id)}
                            localStorageKey={DUR_LOCAL_STORAGE_KEY}
                            draghandle />
                    </div>
                    <div className="closeButton">
                        <Link to={{
                            pathname: selectedTab === defaultTabConfigs[0].id ? '/add-global-dur' : '/drug-utilization-review'
                            , state: { create: true, selectedTab: selectedTab }
                        }} className="link-router">
                            <FliptButton className="closeButton" compact name={selectedTab === defaultTabConfigs[0].id ?
                                "Create New DUR Program" : "Create New DUR Configuration"} />
                        </Link>
                    </div>
                </div>
            </div>
            {
                selectedTab === defaultTabConfigs[0].id ?
                    <div className="content maxHeight">
                        <FliptGrid data={programData} headers={programHeaders} cellRendererParams={programCellRendererParams} />
                    </div> : (selectedTab === defaultTabConfigs[6].id ? 
                    <div className="content maxHeight">
                        <FliptGrid data={getGridData()} headers={regimenComplianceHeaders} cellRendererParams={regimenCellRendererParams}/>
                        </div> :
                    <div className="content maxHeight">
                        <FliptGrid data={getGridData()} headers={drugHeaders} cellRendererParams={cellRendererParams} />
                    </div>
                    )
            }
        </div >
    )
}
const mapStateToProps = (state) => ({
    state: {
        durSummaryData: state.drugUtilizationReview.durSummaryData,
        programDashboardData: state.drugUtilizationReview.programDashboardData,
        selectedTab: state.drugUtilizationReview.selectedTab
    },
})

const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...DrugUtilizationReviewActions,
    }

    return {
        actions: bindActionCreators(allActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DurSummary)
