import React, { Component } from 'react';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import BreadCrumb from '../../../../components/breadCrumb'
import Config from "../../../../config";
import { Creators as AppActions } from "../../../../redux/reducers/app";
import { Creators as NavigationActions } from '../../../../redux/reducers/navigation'
import FliptGrid from '../../../../components/fliptGrid'
import { parseQueryString, } from '../../../../utils/utilities'
import { Creators as ClientImplementationActions } from '../../../../redux/reducers/api/clientImplementation'
import { Creators as UserActions } from '../../../../redux/reducers/userManagement/index'
import _ from 'lodash'
import { FliptGenericDynamicLinkContainerRenderer } from '../../../../components/fliptGrid/cellRenderers'
import './styles.scss'
import DonutChart from 'react-donut-chart';
import moment from 'moment';


class ClientImplementationTaskInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      doc_type: "client_implementation",
      form: {
        doc_id: "",
        doc_name: '',
        lob: '',
        estimated_go_live: '',
        estimated_start_date: '',
        estimated_testing_date: '',
        hierarchy: [],
        is_hierarchy_global: false,
        showHierarchyDropdowns: true,
        hierarchyIDs: {},
        hideHierarchy: false,
        status: '',
        type: 'client_implementation'
      },
      tasks: [
        {
          doc_id: "",
          doc_type: "implementation_task",
          doc_name: "",
          workstream: "",
          module: " ",
          task_object_type: " ",
          task_object_state: " ",
          task_objects: [],
          task_object_dependencies: [],
          task_owner: " ",
          task_assignee: " ",
          task_begin_date: "",
          task_end_date: "",
          actual_task_begin_date: "",
          actual_task_end_date: "",
          task_notes: "",
          status: "",
          taskProgramOptions: [],
        }
      ],
      taskObjectsData: {},
      donutData: [],
      hierarchyDisplay: {},
      agGridRef: React.createRef(),
    }
  }


  getObjectKeys() {
    const { state: { taskObjects } } = this.props
    let objSearchArgs = {}

    if (taskObjects.length > 0) {
      let data = taskObjects[0]
      let keys = Object.keys(data)
      for (let index = 0; index < keys.length; index++) {
        const key = keys[index];
        for (let jindex = 0; jindex < data[key].length; jindex++) {
          const element = data[key][jindex];
          objSearchArgs[element["object_name"]] = element
        }
      }
    }
    //console.log(taskObjects)
    //console.log(options)
    return objSearchArgs
  }
  parseHierarchyJson(data) {
    const resultMap = {};

    function traverse(obj) {

      const key = ""
      const val = ""
      resultMap[obj["level"] ? obj["level"] : "group"] = obj["name"]
      if (obj["children"]?.length > 0) {
        traverse(obj["children"][0])
      }
    }
    if (data?.length > 0) {
      traverse(data[0]); // Assuming data is a single object
    }
    return resultMap;
  }
  componentDidMount() {
    const { actions, data, state: { stateuser } } = this.props
    const { agGridRef } = this.state
    actions.clearImplementationTaskReducer()
    const qs = parseQueryString(this.props.history.location.search);
    const { doc_id } = qs
    let payload = {
      doc_id: doc_id,
      doc_type: "client_implementation"
    }
    setTimeout(
      () => actions.getClientImplementation(payload),
      Config.COMPONENT_DATA_LOAD_TIME
    );

    setTimeout(
      () => actions.getImplementationTaskObjectList(),
      Config.COMPONENT_DATA_LOAD_TIME
    );

    //agGridRef.current.api.refreshServerSideStore({ route: null, purge: true })
  }
  componentDidUpdate(prevProps) {
    const { actions, data, state: { stateuser } } = this.props
    const { state: { user, implementationData, tasksData }, form } = this.props;
    // if (prevProps.state.user !== user) {
    //   this.updateFliptHierarchy(user, form);
    // }
    const prevState = prevProps.state;
    const currState = this.props.state;

    if (prevState === currState) return;
    this.setImplementationDataforVieworEdit()


  }



  //   updateFliptHierarchy(user, form) {
  //     // Logic to update hierarchy data based on user
  //     // This would likely involve fetching hierarchy data based on the user
  //     // and updating the form state with the new hierarchy
  //     //console.log('User has changed. Update FliptDropDownHierarchy data based on user:', user);
  //     this.setState({ form: { ...form, hierarchy: [] } }); // Update form state with hierarchy (replace [...] with actual data)
  //   }

  setImplementationDataforVieworEdit = (updateHierarchy) => {
    const { state: { user, implementationData, tasksData }, actions, history, form } = this.props
    let options = this.getObjectKeys()
    if (implementationData) {
      const payload = parseQueryString(this.props.history.location.search);
      const { props, state } = this;
      const form = {
        ...state.form,
        doc_id: implementationData?.doc_id || '',
        doc_name: implementationData?.doc_name || '',
        lob: implementationData?.lob || '',
        estimated_go_live: implementationData?.estimated_go_live || '',
        estimated_start_date: implementationData?.estimated_start_date || '',
        estimated_testing_date: implementationData?.estimated_testing_date || '',
        hierarchy: implementationData?.hierarchy || [],
        is_hierarchy_global: false,
        showHierarchyDropdowns: true,
        hierarchyIDs: implementationData?.hierarchyIDs || {},
        hideHierarchy: false,
        type: "client_implementation"
      };
      let tasks = []
      let argSearch = options
      let donutInfo = {}
      let hierarchyDisplay = this.parseHierarchyJson(form.hierarchy)
      donutInfo["tasks"] = tasksData?.length || 0
      donutInfo["raw_data"] = {}
      for (let index = 0; index < tasksData.length; index++) {
        const element = tasksData[index];
        let objs = element["task_objects"]
        let args = []
        let status = element["status"] ? element["status"] : "NA"

        donutInfo["raw_data"][status] = donutInfo["raw_data"][status] ? donutInfo["raw_data"][status] + 1 : 1
        for (let index = 0; index < objs.length; index++) {
          const objelement = objs[index];
          let arg = {}
          let obj = argSearch[objelement]
          if (obj) {
            let path = obj["object_path"] ? obj["object_path"] : ""
            let name = obj["object_name"] ? obj["object_name"] : ""
            let status = obj["object_status"] ? obj["object_status"] : ""
            let sArgs = obj["args"] ? obj["args"] : {}
            let state = obj["state"] ? obj["state"] : {}
            arg["path"] = `/${path}`
            arg["label"] = `${name.toUpperCase()} :: ${status.toUpperCase()}`
            arg["searchArgs"] = sArgs
            arg["state"] = state
          }
          else {
            arg["path"] = "/"
            arg["label"] = "NA"
            arg["searchArgs"] = {}
            arg["state"] = {}
          }

          args.push(arg)
        }
        element["objectArgs"] = args
        tasks.push(element)

      }
      let chartDataKeys = Object.keys(donutInfo["raw_data"])
      let chartData = []
      for (let index = 0; index < chartDataKeys.length; index++) {
        const key = chartDataKeys[index]
        chartData.push({
          label: key,
          value: donutInfo["raw_data"][key],
        })
      }

      // if (!tasks.length>0){
      //   tasks.push({
      //     doc_id: "",
      //     doc_type: "implementation_task",
      //     doc_name: "",
      //     workstream: "",
      //     module: " ",
      //     task_object_type: " ",
      //     task_object_state: " ",
      //     task_objects: [],
      //     task_object_dependencies: [],
      //     task_owner: " ",
      //     task_assignee: " ",
      //     estimated_task_begin_date: "",
      //     estimated_task_end_date: "",
      //     actual_task_begin_date: "",
      //     actual_task_end_date: "",
      //     task_notes: "",
      //     status: "",
      //     taskProgramOptions: [],
      // })
      //}

      this.setState({
        form,
        tasks,
        taskObjectsData: options,
        donutData: chartData,
        hierarchyDisplay: hierarchyDisplay
      });
    }

  }

  render() {
    const { tasks, form, agGridRef } = this.state;


    const headers = [

      'doc_id',
      'doc_name',
      'workstream',
      'task_object_type',
      'module',
      'objectArgs',
      'task_object_state',
      'status',
      'task_owner',
      'task_assignee',
      'task_begin_date',
      'task_end_date',
      'actual_task_begin_date',
      'actual_task_end_date',
      'task_notes',
      'doc_type'
    ]
    const cellRendererParams = {
      doc_id: {
        hide: true,
      },
      task_assignee: {
        overrideHeader: 'Assignee',
        rowGroup: true,
        enableRowGroup: true,
        showRowGroup: true,
        suppressDragLeaveHidesColumns: true,
        suppressMakeColumnVisibleAfterUnGroup: true,
        suppressRowGroupHidesColumns: true,
        rowGroupIndex: 0
      },
      doc_name: {
        overrideHeader: 'Name',
      },
      workstream: {
        overrideHeader: 'Work Stream',
        enableRowGroup: true,
        suppressDragLeaveHidesColumns: true,
        suppressMakeColumnVisibleAfterUnGroup: true,
        suppressRowGroupHidesColumns: true,
        //rowGroupIndex: 1
      },
      module: {
        overrideHeader: 'Module',
        enableRowGroup: true,
        suppressDragLeaveHidesColumns: true,
        suppressMakeColumnVisibleAfterUnGroup: true,
        suppressRowGroupHidesColumns: true
      },

      status: {
        overrideHeader: 'Status',
        enableRowGroup: true,
        suppressDragLeaveHidesColumns: true,
        suppressMakeColumnVisibleAfterUnGroup: true,
        suppressRowGroupHidesColumns: true

      },
      task_object_type: {
        overrideHeader: 'Int/Ext',
      },

      objectArgs: {
        cellRenderer: FliptGenericDynamicLinkContainerRenderer,
        cellClass: "ag-cell",
        overrideHeader: 'Objects',
        filter: false,
        minWidth: 300,
        autoHeight: true,
        searchArgs: {
          editMode: false,
        },
      },
      task_object_state: {
        overrideHeader: 'Are all objects Assigned?',
      },
      task_owner: {
        overrideHeader: 'Owner',
        enableRowGroup: true,
        suppressDragLeaveHidesColumns: true,
        suppressMakeColumnVisibleAfterUnGroup: true,
        suppressRowGroupHidesColumns: true
      },

      task_begin_date: {
        overrideHeader: 'Est Begin Date',

      },
      task_end_date: {
        overrideHeader: 'Est End Date',
      },
      actual_task_begin_date: {
        overrideHeader: 'Actual Begin Date',
      },
      actual_task_end_date: {
        overrideHeader: 'Actual End Date',
      },
      task_notes: {
        overrideHeader: 'Comments',
      },
      doc_type: {
        hide: true,
        label: 'implementation_task'
      }

    }
    const gridOptions = {
      // columnDefs: [
      //     { field: 'country', rowGroup: true, hide: true },
      //     { field: 'year', rowGroup: true, hide: true },
      //     { field: 'sport' },
      //     { field: 'total' }
      // ],

      // display each row grouping in group rows
      groupDisplayType: 'groupRows',
      rowGroupPanelShow: 'always',

      // other grid options ...
    }
    return (

      <div id="clientImplementationManagement">
        <BreadCrumb {...this.props} />
        <div className='implementation-header'>
          <h1>{form.doc_name}</h1>
        </div>
        <div className='implementation-details'>
          <div className='hierarchy-class'><span>Org: {this.state.hierarchyDisplay.organization}</span></div>
          <div className='hierarchy-class'><span>Client: {this.state.hierarchyDisplay.client}</span></div>
          <div className='hierarchy-class'><span>Carrier: {this.state.hierarchyDisplay.carrier}</span></div>
          <div className='hierarchy-class'><span>Account: {this.state.hierarchyDisplay.account}</span></div>
          <div className='hierarchy-class'><span>Group: {this.state.hierarchyDisplay.group}</span></div>
        </div>
        <div className='implementation-details'>
          <div className='hierarchy-class'><span>Go Live Date: {moment(form.estimated_go_live).format('MM/DD/YYYY')}</span></div>
          <div className='hierarchy-class'><span>Est Start Date: {moment(form.estimated_start_date).format('MM/DD/YYYY')}</span></div>
          <div className='hierarchy-class'><span>Est Testing Date: {moment(form.estimated_testing_date).format('MM/DD/YYYY')}</span></div>

        </div>

        {/* <section className="spacing border-line shadow">
            <div className="donut-chart-container">
          <DonutChart
                data={this.state.donutData}
                width={250}
                height={375}
                legend={true}
                innerRadius= {0.7}
            />
            </div>
          </section> */}
        <div className="content">
          <FliptGrid
            headers={headers}
            cellRendererParams={cellRendererParams}
            data={this.state.tasks}
            doAutoSize={true}
            suppressColumnVirtualisation
            gridPagination={false}
            gridOptions={gridOptions}

            rowGroupPanelShow={"always"}

          />
        </div>

      </div>

    );
  }
}

const mapStateToProps = (state) => ({
  state: {
    user: state.user,
    implementationData: state.clientImplementation.implementation.implementation_details,
    tasksData: state.clientImplementation.implementation.task_details,
    systemUsersData: state.userManagement.data,
    taskObjects: state.clientImplementation.taskObjects
  },
});

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...NavigationActions,
    ...AppActions,
    ...UserActions,
    ...ClientImplementationActions,

  };

  return {
    actions: bindActionCreators(allActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientImplementationTaskInfo);
