import {
  call,
  put,
  select,
  takeLatest,
  take,
  // all,
} from 'redux-saga/effects'

import moment from 'moment-timezone'
import { Types, Creators as ClaimLookupActions } from '../../reducers/api/claimLookupManagement'
import { Creators as AppActions, Types as AppTypes } from '../../reducers/app'
import { Creators as NavigationActions } from '../../reducers/navigation'
// import { Creators as MemberActions } from '../../reducers/api/memberLookup'
import {
  getAppState, getApiPath, getClaimLookup, getHistory, getUserState, isMenuEmpty,
} from '../../reducers/selectors'
import { fetchGet, fetchPut, fetchPost } from '../../../utils/fetchApi'
import { convertStrToDateObj, createQueryString, filterRowData, parseQueryString } from '../../../utils/utilities'
import {
  overrideByEditValues,
  copayOverrideTypeValues,
  claimOrExtendedValues,
  copayOverrideValues,
} from '../../../pages/private/claimLookupManagement/claimOverride/overrideDetails/overrideDataValues';

export default [
  getClaimDetailDataWatcher,
  getClaimLookupManagementDataWatcher,
  getClaimTransactionDataWatcher,
  getClaimTransactionCostShareWatcher,
  setClaimOverrideWatcher,
  removeClaimOverrideWatcher,
  getQLDaysLimitMessageWatcher,
  getContractedProcessorDataWatcher,
  getNxTxnsWatcher,
  getFirTxnsWatcher,
  getAllNxTxnsWatcher,
  getAdapSpapTxnsWatcher
]

/* WATCHERS */


function* getNxTxnsWatcher() {
  yield takeLatest(Types.GET_NX_TXNS, getNxTxnsHandler)
}

function* getAllNxTxnsWatcher() {
  yield takeLatest(Types.GET_ALL_NX_TXNS, getAllNxTxnsHandler)
}
function* getAdapSpapTxnsWatcher() {
  yield takeLatest(Types.GET_ADAP_SPAP_TXNS, getAdapSpapTxnsHandler)
}




function* getFirTxnsWatcher() {
  yield takeLatest(Types.GET_FIR_TXNS, getFirTxnsHandler)
}

function* getClaimLookupManagementDataWatcher() {
  yield takeLatest(Types.GET_CLAIM_LOOKUP_MANAGEMENT_DATA, getClaimLookupManagementDataHandler)
}

function* getClaimDetailDataWatcher() {
  yield takeLatest(Types.GET_CLAIM_DETAIL_DATA, getClaimDetailDataHandler)
}

function* getClaimTransactionDataWatcher() {
  yield takeLatest(Types.GET_CLAIM_TRANSACTION_DATA, getClaimTransactionDataHandler)
}

function* getClaimTransactionCostShareWatcher() {
  yield takeLatest(Types.GET_CLAIM_TRANSACTION_COST_SHARE, getClaimTransactionCostShareDataHandler)
}

function* getContractedProcessorDataWatcher() {
  yield takeLatest(Types.GET_CONTRACTED_PROCESSOR_DATA, getContractedProcessorDataHandler)
}

function* setClaimOverrideWatcher() {
  yield takeLatest(Types.SET_CLAIM_OVERRIDE, setClaimOverrideHandler)
}

function* removeClaimOverrideWatcher() {
  yield takeLatest(Types.REMOVE_CLAIM_OVERRIDE, removeClaimOverrideHandler)
}

function* getQLDaysLimitMessageWatcher() {
  yield takeLatest(Types.GET_QL_DAYS_LIMIT_MESSAGE, getQLDaysLimitMessageHandler)
}

/* HANDLERS */
function* getClaimDetailDataHandler() {
  try {
    const { serviceUrl } = yield select(getAppState)
    const history = yield select(getHistory)
    const { userCAG = {} } = yield select(getUserState)
    const qs = history.location.search
    const args = parseQueryString(qs)
    args.cag = userCAG
    const { api_path } = yield select(getApiPath, 'claim-detail')
    const url = `${serviceUrl}${api_path}`
    const { data, total_rows } = yield call(fetchPost, url, args)

    yield put(ClaimLookupActions.setClaimDetailData({ data, totalRows: total_rows }))
    const res = yield call(fetchPost, url.replace('/claim-detail', '/member-details'), data?.map((d) => d.cardholder_id), false)
    if (res) {
      const dataWithMemberDetails = data.map((d, idx) => {
        const row = { ...d }
        row.first_name = res.data[idx].first_name ? res.data[idx].first_name : 'N/A'
        row.last_name = res.data[idx].last_name ? res.data[idx].last_name : 'N/A'
        row.group = res.data[idx].group ? res.data[idx].group : 'N/A'
        row.person_code = res.data[idx].person_code ? res.data[idx].person_code : 'N/A'
        row.date_of_birth = res.data[idx].date_of_birth ? moment(res.data[idx].date_of_birth).format('MM/DD/YYYY') : 'N/A'
        row.domain_name = res.data[idx].domain_name ? res.data[idx].domain_name : ''
        if (!row.benefit_plan_name) {
          row.benefit_plan_name = res.data[idx].plan_name ? res.data[idx].plan_name : ''
        }
        // row.plan_year = res.data[idx].plan_year ? res.data[idx].plan_year : ''
        data[idx] = row
        return row
      })
      yield put(ClaimLookupActions.setClaimDetailData({ data: dataWithMemberDetails, totalRows: total_rows }))
    }
  } catch (err) {
    console.log('getClaimDetailDataHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Claim Lookup Failed',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* getClaimLookupManagementDataHandler({ payload }) {
  try {
    const {
      form, params, headers, headerMapping, cardholder_id, isAdjustment = false, defaultClaimsAccess = false,
    } = payload
    let tempForm = { ...form }
    
    tempForm.start_date = moment(tempForm.start_date).toISOString()
    tempForm.end_date = moment(tempForm.end_date).toISOString()
    // creates inverted headerMapping if needed
    if (headerMapping && params?.request) {
      const inv_headerMapping = Object.keys(headerMapping).reduce((obj, key) => {
        let newObj = {}
        newObj = { [headerMapping[key]]: key }
        return { ...obj, ...newObj }
      }, {})
      // uses inverted headerMapping to convert header names into db names
      params.request.filterModel = filterRowData(params?.request.filterModel, headers, inv_headerMapping)
    }
    if (tempForm.member_id && params?.request && params.request?.filterModel) {
      if (!params.request.filterModel?.cardholder_id)
        params.request.filterModel['cardholder_id'] = {}
      params.request.filterModel.cardholder_id.filter = tempForm.member_id
      params.request.filterModel.cardholder_id.filterType = "text"
      params.request.filterModel.cardholder_id.type = "equals"
    }
    const displayDates = params?.request && params.request?.filterModel && params.request.filterModel?.date_of_service
    if (displayDates) {
      // eslint-disable-next-line
      const { dateFrom, dateTo } = params.request.filterModel.date_of_service
      params.request.filterModel.date_of_service.dateFrom = dateFrom ? moment(dateFrom).format('YYYYMMDD') : ''
      params.request.filterModel.date_of_service.dateTo = dateTo ? moment(dateTo).format('YYYYMMDD') : ''
      params.request.filterModel.date_of_service.filter = dateFrom ? moment(dateFrom).format('YYYYMMDD') : ''
    }
    const { serviceUrl } = yield select(getAppState)
    const history = yield select(getHistory)
    const { claimsPerPage } = yield select(getClaimLookup)
    const { userCAG = {} } = yield select(getUserState)
    history.location.search = createQueryString({
      ...tempForm,
      offset: params?.request.startRow,
    })
    const qs = history.location.search ? `${history.location.search}&limit=${claimsPerPage}` : ''
    if (!qs && !isAdjustment) {
      params?.success({
        rowData: [],
        rowCount: 0,
      })
    } else {
      const empty = yield select(isMenuEmpty)
      if (empty) {
        yield take([AppTypes.SET_APP_SETTINGS])
      }
      const { api_path } = yield select(getApiPath, 'claim-lookup-management')
      const url = `${serviceUrl}${api_path}${qs}`
      const reqData = {
        start_date: tempForm?.start_date, // need it here to make it easy for json deserialization
        end_date: tempForm?.end_date, // need it here to make it easy for json deserialization
        sortModel: params?.request.sortModel,
        filterModel: params?.request.filterModel,
        prescription_reference_number: tempForm?.prescription_reference_number,
        cag: {
          domain_name: userCAG?.account ?? [],
          group: userCAG?.group ?? [],
          defaultClaimsAccess: userCAG?.defaultClaimsAccess ?? false,
        }
      }
      let res
      if (!isAdjustment && headers) {
        const { data, last_row } = yield call(fetchPost, url, reqData)
        let gridData = data
        gridData = data?.map((d) => ({
          ...filterRowData(d, headers, headerMapping),
          processed_date: moment(`${d.startDate}Z`).tz('America/New_York').format('MM/DD/YYYY HH:mm:ss'),
          date_of_service: moment(d.date_of_service).format('MM/DD/YYYY'),
        }))
        params?.success({
          rowData: gridData,
          rowCount: last_row,
        })
        res = yield call(fetchPost, url.replace('/claim-lookup-management', '/member-details'), data?.map((d) => d.cardholder_id), false)
        if (res) {
          const dataWithMemberDetails = gridData.map((d, idx) => {
            const row = { ...d }
            row.first_name = res.data[idx].first_name ? res.data[idx].first_name : 'N/A'
            row.last_name = res.data[idx].last_name ? res.data[idx].last_name : 'N/A'
            row.date_of_birth = res.data[idx].date_of_birth ? moment(res.data[idx].date_of_birth).format('MM/DD/YYYY') : 'N/A'
            if (!row?.plan_name) {
              row.plan_name = res.data[idx].plan_name ? res.data[idx].plan_name : 'N/A'
            }
            row.is_vip = res?.data[idx]?.is_vip ?? false
            data[idx] = row
            return row
          })
          gridData = dataWithMemberDetails.map((d) => ({
            ...filterRowData(d, headers, headerMapping),
          }))
          params.success({
            rowData: gridData,
            rowCount: last_row,
          })
        }
      } else {
        res = yield call(fetchPost, url.replace('/claim-lookup-management', '/member-details'), [cardholder_id], false)
        yield put(ClaimLookupActions.setClaimPersonName(res.data))
      }
    }
  } catch (err) {
    console.log('getClaimLookupManagementHandler Error > Data: ', err)
    const transitionalPortal = {
      header: 'Claim Lookup Failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

// function* ClaimfetchMemberDetailsDataHandler(d) {
//   try {
//     const { serviceUrl } = yield select(getAppState)
//     const { api_path } = yield select(getApiPath, 'member-details')
//     if (!d) return

//     const url = `${serviceUrl}${api_path}`
//     yield call(fetchPost, url, d, false)
//   } catch (err) {
//     console.log('fetchMemberDetailsDataHandler Error >Data ', err)
//     const transitionalPortal = {
//       header: 'Member details failed',
//       copy: err,
//     }
//     yield put(AppActions.displayTransitionalPortal(transitionalPortal))
//   }
// }

function* getNxTxnsHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const { api_path } = yield select(getApiPath, 'nx-claim-transaction-details')
    const url = `${serviceUrl}${api_path}?auth_id=${payload?.auth_id}`
    const { data } = yield call(fetchGet, url)
    if (!data.length) {
      const transitionalPortal = {
        header: 'Data Not Found',
        copy: 'No Nx Transactions Found for Given Auth ID',
      }
      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
      return
    }
    yield put(ClaimLookupActions.setNxTxnsData(data[0]))
  } catch (err) {
    console.log('getNxTxnsHandler Error > Data: ', err)
    const transitionalPortal = {
      header: 'Nx Transactions Failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    yield put(NavigationActions.navigateTo('/claim-lookup-management'))
  }
}

function* getAllNxTxnsHandler({ payload }) {
  try {
    const {
      form, params
    } = payload
    const { serviceUrl } = yield select(getAppState)
    const { api_path } = yield select(getApiPath, 'nx-transaction')
    const url = `${serviceUrl}${api_path}`
    const apiParam = getParams(form)
    apiParam.offset = params?.request.startRow
    apiParam.start_date = apiParam.start_date ? moment(apiParam.start_date).add(1, 'day').toISOString() : ''
    apiParam.end_date = apiParam.end_date ? moment(apiParam.end_date).add(1, 'day').toISOString() : ''
    const { data, last_row } = yield call(fetchPost, url, apiParam)
    if (!data.length) {
      const transitionalPortal = {
        header: 'Data Not Found',
        copy: 'No Nx Transactions Found',
      }
      params?.success({
        rowData: [],
        rowCount: 0,
      })
      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
      yield put(ClaimLookupActions.setFirTxnsData([]))
      return
    }
    const gridData = data.map((d) => {
      return {
        ...d, cardholder_id: d?.request?.insurance_segment?.cardholder_id,
        transaction_response_status: d?.response?.transactions?.length ? d?.response?.transactions[0]?.response_status?.transaction_response_status : ''
      }
    })
    params?.success({
      rowData: gridData,
      rowCount: last_row,
    })
    const res = yield call(fetchPost, url.replace('/nx-transaction', '/member-details'), gridData?.map((d) => d.cardholder_id), false)
    if (res) {
      const dataWithMemberDetails = gridData.map((d, idx) => {
        const row = { ...d }
        row.first_name = res.data[idx].first_name ? res.data[idx].first_name : 'N/A'
        row.last_name = res.data[idx].last_name ? res.data[idx].last_name : 'N/A'
        row.plan_name = res.data[idx].plan_name ? res.data[idx].plan_name : 'N/A'
        row.contract_id = res.data[idx].contract_id ? res.data[idx].contract_id : 'N/A'
        row.pbp = res.data[idx].pbp ? res.data[idx].pbp : 'N/A'
        data[idx] = row
        return row
      })
      params?.success({
        rowData: dataWithMemberDetails,
        rowCount: last_row,
      })
    }
  } catch (err) {
    console.log('getAllNxTxnsHandler Error > Data: ', err)
    const transitionalPortal = {
      header: 'Nx Transactions Failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}
function* getAdapSpapTxnsHandler({ payload }) {
  try {
    const {
      form, params
    } = payload
    const { serviceUrl } = yield select(getAppState)
    const { api_path } = yield select(getApiPath, 'adap-spap-information')
    const url = `${serviceUrl}${api_path}`
    const apiParam = {
      payload: form,
      pagination: true,
      limit: 20
    }
    apiParam.offset = params?.request.startRow
    const { data, last_row } = yield call(fetchPost, url, apiParam)
    if (!data.length) {
      const transitionalPortal = {
        header: 'Data Not Found',
        copy: 'No Adap Spap Information Found',
      }
      params?.success({
        rowData: [],
        rowCount: 0,
      })

      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
      return
    }
    params?.success({
      rowData: data,
      rowCount: last_row,
    })


  } catch (err) {
    console.log('getAdapSpapTxnsHandler Error > Data: ', err)
    const transitionalPortal = {
      header: 'Adap Spap Information Failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

const getParams = (form) => {
  const params = {
    limit: "20",
    auth_id: form.auth_id,
    cardholder_id: form.cardholder_id,
    offset: "0",
    transaction_id: form.nx_transaction_id,
    sortModel: [],
    start_date: form.start_date,
    end_date: form.end_date,
    first_name: form.first_name,
    last_name: form.last_name,
  }
  if (form.cardholder_id) {
    params.filterModel = {
      cardholder_id: {
        filter: form.cardholder_id,
        filterType: 'text',
        type: 'equals'
      }
    }
  }
  if (form.nx_transaction_id) {
    params.filterModel = {
      transaction_id: {
        filter: form.nx_transaction_id,
        filterType: 'text',
        type: 'equals'
      }
    }
  }
  if (form.auth_id) {
    params.filterModel = {
      auth_id: {
        filter: form.auth_id,
        filterType: 'text',
        type: 'equals'
      }
    }
  }
  return params;
}


function* getFirTxnsHandler({ payload = {} }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const { api_path } = yield select(getApiPath, 'fir-transaction-details')
    const url = `${serviceUrl}${api_path}`
    const { userCAG = {} } = yield select(getUserState)
    payload.cag = userCAG
    const { data } = yield call(fetchPost, url, payload)
    if (!data.length) {
      const transitionalPortal = {
        header: 'Data Not Found',
        copy: 'No FIR Transactions Found',
      }
      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
      yield put(ClaimLookupActions.setFirTxnsData([]))
      return
    }
    const gridData = data.map((d) => {
      return {
        ...d, cardholder_id: d?.request?.insurance_seg?.cardholder_id,
        transaction_type: d?.request?.header?.transaction_code,
        transaction_response_status: d?.response?.status_seg?.transaction_response_status
      }
    })
    yield put(ClaimLookupActions.setFirTxnsData(gridData))
    const res = yield call(fetchPost, url.replace('/fir-transaction-details', '/member-details'), gridData?.map((d) => d.cardholder_id), false)
    yield put(ClaimLookupActions.setMemberDetailsData(res.data))
    if (res) {
      const dataWithMemberDetails = gridData.map((d, idx) => {
        const row = { ...d }
        row.first_name = res.data[idx].first_name ? res.data[idx].first_name : 'N/A'
        row.last_name = res.data[idx].last_name ? res.data[idx].last_name : 'N/A'
        row.plan_name = res.data[idx].plan_name ? res.data[idx].plan_name : 'N/A'
        row.contract_id = res.data[idx].contract_id ? res.data[idx].contract_id : 'N/A'
        row.pbp = res.data[idx].pbp ? res.data[idx].pbp : 'N/A'
        data[idx] = row
        return row
      })
      yield put(ClaimLookupActions.setFirTxnsData(dataWithMemberDetails))
    }
  } catch (err) {
    console.log('getFirTxnsHandler Error > Data: ', err)
    const transitionalPortal = {
      header: 'FIR Transactions Failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}



function* getClaimTransactionDataHandler() {
  try {
    const { serviceUrl } = yield select(getAppState)
    const history = yield select(getHistory)
    const { userCAG = {} } = yield select(getUserState)
    const qs = history.location.search
    const args = parseQueryString(qs)
    args.cag = userCAG
    const { api_path } = yield select(getApiPath, 'claim-transaction-details')
    const url = `${serviceUrl}${api_path}`
    const { data } = yield call(fetchPost, url, args)
    if (!data?.length) {
      const transitionalPortal = {
        header: 'Data Not Found',
        copy: 'No Transaction Data found for given Auth Id',
      }
      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
      yield put(NavigationActions.navigateTo('/nx-transaction'))
      return
    }
    const claimData = data[0]

    yield put(ClaimLookupActions.setQLDaysLimitMSG(''))
    yield put(ClaimLookupActions.setClaimTransactionData(claimData))
  } catch (err) {
    console.log('getClaimLookupManagementHandler Error > Data: ', err)
    const transitionalPortal = {
      header: 'Claim Lookup Failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    yield put(NavigationActions.navigateTo('/claim-lookup-management'))
  }
}

function* getClaimTransactionCostShareDataHandler(data) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const { userCAG = {} } = yield select(getUserState)
    const { api_path } = yield select(getApiPath, 'claim-transaction-cost-share-details')
    const url = `${serviceUrl}${api_path}`
    if (userCAG) {
      data.payload = {
        ...data.payload,
        cag: {
          ...userCAG,
        }
      }
    }
    const response = yield call(fetchPost, url, data.payload)
    const claimCostShareData = response.data[0]

    yield put(ClaimLookupActions.setClaimTransactionCostShare(claimCostShareData))
  } catch (err) {
    console.log('getClaimLookupManagementHandler Error > Data: ', err)
    const transitionalPortal = {
      header: 'Claim Lookup Cost Share details Failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}


function* getContractedProcessorDataHandler(data) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const { api_path } = yield select(getApiPath, 'contracted_processor')
    const url = `${serviceUrl}${api_path}`
    const response = yield call(fetchPost, url, data.payload)
    const contractedProcessorData = response.data[0]
    yield put(ClaimLookupActions.setContractedProcessorData(contractedProcessorData))
  } catch (err) {
    console.log('getContractedProcessorDataHandler Error > Data: ', err)
    const transitionalPortal = {
      header: 'Contracted Processor List Failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}


function* setClaimOverrideHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const cd = yield select(getApiPath, 'claim-override')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`

    payload.override_thru = convertStrToDateObj(payload.override_thru, { toISO: false })
    payload.override_from = convertStrToDateObj(payload.override_from, { toISO: false })

    // payload verification check
    if (!payload.override_from) throw 'Please enter From Date'
    if (!payload.override_thru) throw 'Please enter To Date'

    const isDrugCoverageOverride = payload?.override_by_edit === overrideByEditValues.drug_coverage.value
    const copayOverrideIsFixed = payload?.copay_override_type === copayOverrideTypeValues.fixed.value
    const copayOverrideIsPercentage = payload?.copay_override_type === copayOverrideTypeValues.percentage.value
    const isClaimOnly = payload?.claim_or_extended === claimOrExtendedValues.claim.value
    const isExtendedClaim = payload?.claim_or_extended === claimOrExtendedValues.extended.value

    if (isDrugCoverageOverride && (copayOverrideIsFixed || copayOverrideIsPercentage)) {
      if (copayOverrideIsFixed) {
        if (isClaimOnly && !payload.override_amount) {
          throw 'Please enter Copay Amount first'
        } else if (isExtendedClaim && !(payload.copay_amount_30ds || payload.copay_amount_60ds || payload.copay_amount_90ds)) {
          throw 'Please enter 30 DS Copay, 60 DS Copay, and/or 90 DS Copay first'
        }
      } else if (!payload.override_percentage) {
        throw 'Please enter Copay Percentage first'
      }
    }

    payload.copay_override = payload?.override_by_edit === overrideByEditValues.copay.value ? copayOverrideValues.yes.value : copayOverrideValues.no.value
    if (payload.copay_override_type && payload.copay_override_type === copayOverrideTypeValues.fixed.value) {
      payload.override_percentage = ''
    } else {
      payload.override_amount = ''
    }
    // can move this into another function for cleanliness
    if (payload.claim_or_extended && payload.claim_or_extended === claimOrExtendedValues.extended.value) {
      const paOverrideData = {
        override_type: overrideByEditValues[Object.keys(overrideByEditValues).find((key) => overrideByEditValues[key].value === payload?.override_by_edit)].paOverrideValue,
        pa_override_start_date: payload?.override_from,
        pa_override_end_date: payload?.override_thru,
        copay_type: copayOverrideTypeValues[Object.keys(copayOverrideTypeValues).find((key) => copayOverrideTypeValues[key].value === payload?.copay_override_type)].paOverrideValue,
        copay_amount: payload?.copay_override_type === copayOverrideTypeValues.fixed.value ? payload?.override_amount : payload?.override_percentage,
        copay_amount_30ds: payload?.copay_amount_30ds,
        copay_amount_60ds: payload?.copay_amount_60ds,
        copay_amount_90ds: payload?.copay_amount_90ds,
        domain_name: payload?.domain_name,
        flipt_person_id: payload?.flipt_person_id,
        benefit_plan_name: payload?.benefit_plan_name,
        brand_generic: payload?.brand_generic,
        ddid: payload?.ddid,
        gpi: payload?.gpi,
        drug_name: payload?.drug_name,
        dosage: payload?.dosage,
        strengths: payload?.strengths,
      }
      payload.pa_override = {
        ...paOverrideData,
      }
    }
    if (payload.override_by_edit) {
      payload.override_by_edit = typeof payload.override_by_edit === 'string' ? [payload.override_by_edit] : payload.override_by_edit
      if ((payload.override_by_edit[0] === overrideByEditValues.copay.value)
        && (payload.claim_or_extended === claimOrExtendedValues.claim.value)) {
        payload.override_by_edit = [overrideByEditValues.override_all_edits.value, overrideByEditValues.copay.value]
      }
      // if (payload.override_by_edit[0] !== overrideByEditValues.other.value) {
      //   payload.override_by_edit_other = ''
      // }
    } else {
      payload.override_by_edit = []
    }
    if (payload.isProactive) {
      payload.claim_or_extended = 'proactive'
    }
    const { message } = yield call(fetchPut, url, payload)
    const transitionalPortal = {
      header: 'Claim Override',
      copy: message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  } catch (err) {
    console.log('setClaimOverrideHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Setting Claim Override Failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* removeClaimOverrideHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const history = yield select(getHistory)
    const qs = history.location.search
    const cd = yield select(getApiPath, 'remove-claim-override')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}${qs}`
    const { message } = yield call(fetchPut, url, payload)
    const transitionalPortal = {
      header: 'Remove Claim Override',
      copy: message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    yield put(NavigationActions.navigateBack())
  } catch (err) {
    console.log('removeClaimOverrideHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Removing Claim Override Failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* getQLDaysLimitMessageHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const history = yield select(getHistory)
    const qs = history.location.search

    const { api_path } = yield select(getApiPath, 'ql-days-limit-msg-override')
    const url = `${serviceUrl}${api_path}${qs}`
    const response = yield call(fetchGet, url, payload)

    yield put(ClaimLookupActions.setQLDaysLimitMSG(response?.data[0]?.ql_limit_days_msg))
  } catch (err) {
    console.log('getQLDaysLimitMessage Error >Data ', err)
    const transitionalPortal = {
      header: 'Getting QL Days Limit Message Failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

export {
  getClaimDetailDataHandler,
  getClaimLookupManagementDataHandler,
  getClaimTransactionDataHandler,
  getClaimTransactionCostShareDataHandler,
  setClaimOverrideHandler,
  removeClaimOverrideHandler,
  getQLDaysLimitMessageHandler,
  getAllNxTxnsHandler,
  getAdapSpapTxnsHandler
}
