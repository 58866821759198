import React from 'react'
import SemanticDatepicker from 'react-semantic-ui-datepickers'
import moment from 'moment'

import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css'

export default (props) => {
    const {
        labelText,
        stylized,
    } = props
    const stylizedField = () => (
        <div className="pa-stylized">
            <div className="flipt-pa-label">
                <label htmlFor={labelText}>{labelText}</label>
            </div>
            {displayField(props)}
        </div>
    )

    if (stylized) return stylizedField()

    return displayField(props)
}

function displayField(props) {
    const {
        clearable = true,
        format,
        readOnly,
        value,
    } = props

    return readOnly ? (<input type='text' placeholder='MM-DD-YYYY' autocomplete='off' value={value ? moment(value).format('MM-DD-YYYY') : ''} />) :
        (<SemanticDatepicker {...props} autoComplete='off' clearable={clearable} format={format || 'MM-DD-YYYY'} readOnly={readOnly} />)
}
