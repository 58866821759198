import Immutable from 'seamless-immutable'
import { createActions, createReducer } from 'reduxsauce'

import { convertDateTimeToDate } from '../../../utils/utilities'

export const INITIAL_STATE = Immutable({
  allRebateDataData: [],
  rebateCondData: [],
  rebateCriteriaIdData: [],
  rebateData: {},
  rebateDrugFiltersData: {},
  rebateLevelsDrugData: [],
})

export const clearReducer = () => INITIAL_STATE

export const clearRebateReducer = () => INITIAL_STATE

export const setAllRebateDataReducer = (state = INITIAL_STATE, { payload }) => {
  const d = payload?.data.map((row) => ({
    ...row,
    effective_end_date: convertDateTimeToDate(row.effective_end_date),
    effective_start_date: convertDateTimeToDate(row.effective_start_date),
  }))
  return {
    ...state,
    allRebateDataData: d,
  }
}

export const setRebateDrugFiltersDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  rebateDrugFiltersData: payload,
})

export const setRebateLevelDrugDataReducer = (state = INITIAL_STATE, { payload }) => {
  const drugs = [...state.rebateLevelsDrugData]
  drugs[payload.rebateConditionLevel] = payload.data
  return {
    ...state,
    rebateLevelsDrugData: drugs,
  }
}

export const clearRebateDrugDataReducer = (state = INITIAL_STATE) => ({
  ...state,
  rebateLevelsDrugData: [],
})

export const setPOSRebateDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  rebateCriteriaIdData: payload.criteria_ids,
  rebateCondData: payload.rebate_conditions,
  rebateData: payload.rebate_info,
})

export const clearPOSRebateDataReducer = (state = INITIAL_STATE) => ({
  ...state,
  rebateCriteriaIdData: [],
  rebateCondData: [],
  rebateData: {},
})

export const { Types, Creators } = createActions({
  approveRebate: ['payload'],
  clearRebate: null,
  clearPOSRebateData: null,
  clearRebateDrugData: null,
  clearReducer: null,
  generateRebate: ['payload'],
  getAllRebateData: null,
  getPOSRebateData: ['payload'],
  savePOSRebateData: ['payload'],
  setAllRebateData: ['payload'],
  setPOSRebateData: ['payload'],
  setRebateDrugFiltersData: ['payload'],
  setRebateLevelDrugData: ['payload'],
})

const HANDLERS = {
  [Types.CLEAR_REBATE]: clearRebateReducer,
  [Types.CLEAR_POS_REBATE_DATA]: clearPOSRebateDataReducer,
  [Types.CLEAR_REBATE_DRUG_DATA]: clearRebateDrugDataReducer,
  [Types.CLEAR_REDUCER]: clearReducer,
  [Types.SET_ALL_REBATE_DATA]: setAllRebateDataReducer,
  [Types.SET_POS_REBATE_DATA]: setPOSRebateDataReducer,
  [Types.SET_REBATE_DRUG_FILTERS_DATA]: setRebateDrugFiltersDataReducer,
  [Types.SET_REBATE_LEVEL_DRUG_DATA]: setRebateLevelDrugDataReducer,
}

export default createReducer(INITIAL_STATE, HANDLERS)
