import Immutable from 'seamless-immutable'
import { createActions, createReducer } from 'reduxsauce'

export const INITIAL_STATE = Immutable({
    documentNames: [],
    fieldNames: [],
    queryData: [],
})

export const clearQueryDataReducer = (state = INITIAL_STATE) => ({
    ...state,
    queryData: [],
    documentNames: [],
    fieldNames: [],
})

export const setQueryDataReducer = (state = INITIAL_STATE, { payload }) => ({
    ...state,
    queryData: payload,
})

export const setFieldsReducer = (state = INITIAL_STATE, { payload }) => ({
    ...state,
    fieldNames: payload,
})

export const setDocumentReducer = (state = INITIAL_STATE, { payload }) => ({
    ...state,
    documentNames: payload,
})


export const { Types, Creators } = createActions({
    getDocument: null,
    setDocument: ['payload'],
    getFields: ['payload'],
    setFields: ['payload'],
    getQueryData: ['payload'],
    setQueryData: ['payload'],
    clearQueryData: ['payload'],
})

const HANDLERS = {
    [Types.CLEAR_QUERY_DATA]: clearQueryDataReducer,
    [Types.SET_QUERY_DATA]: setQueryDataReducer,
    [Types.SET_FIELDS]: setFieldsReducer,
    [Types.SET_DOCUMENT]: setDocumentReducer
}

export default createReducer(INITIAL_STATE, HANDLERS)
