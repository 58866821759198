import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import _, { cloneDeep } from 'lodash'
import { Creators as PlanManagementActions } from '../../../../redux/reducers/api/planManagement'
import './styles.scss'
import { Accordion, Button, Icon } from 'semantic-ui-react'
import FliptDropdown from '../../../../components/form/fliptDropdown'
import FliptInput from '../../../../components/form/fliptInput'
import FliptGrid from '../../../../components/fliptGrid'
import AddRow from '../addRow'
import moment from 'moment'
import { Creators as AppActions } from '../../../../redux/reducers/app'
import { DeleteRowRenderer } from '../../../../components/fliptGrid/cellRenderers'
import { InputTextEditor, DropdownEditor, DatePickerEditor } from '../../../../components/fliptGrid/cellEditors'
import rowCellInfo from '../data/rowCellInfo'
import { validateNumericField } from '../../../../utils/validationHelpers'
import './styles.scss'
import { resolveMessage } from '../../../../utils/validationHelpers'

class Compounds extends Component {
  constructor(props) {
    super(props)
    const { state, editMode } = props
    const { phases } = editMode ? state : []
    const { planLOB, planName } = state
    const hasPhases = planLOB?.includes('Medicare D')
    const program_list = [{ "program_id": '', "program_name": props.state?.compoundsData?.copay?.length ? props.state?.compoundsData?.copay[0]?.program_name : "", }]
    const initialTierValues = editMode ? this.initializeTierValues(program_list, hasPhases ? phases : []) : []

    this.state = {
      activeTierLevel: 0,
      activePhaseIndex: 0,
      hasPhases,
      phases: state.phases || [],
      compoundsData: props.state?.compoundsData,
      prevCopays: [],
      tierStates: initialTierValues ? [...initialTierValues] : [],
    }
  }

  componentDidMount() {
    const { state, editMode } = this.props
    const { compoundsData } = state
    const { hasPhases, phases } = this.state
    const defaultTierCondition = {
      action: '',
      member_pay: null,
      copay_type: '',
      day_supply: '',
      effective_end_date: '',
      effective_start_date: '',
      max_copay: null,
      min_copay: null,
      network_tier: 'ALL',
    }

    if (compoundsData?.copay?.length) {
      this.setState((prevState) => {
        if (!editMode) {
          return {
            tierStates: compoundsData?.copays,
          }
        }
        return {
          tierStates: prevState.tierStates.map(t => {
            const tierData = compoundsData?.copay?.find(d => d.program_name === t.program_name)
            let tierConditions = []
            let phaseData = []
            if (hasPhases) {
              const phaseGrouping = _.groupBy(tierData?.tierConditions, 'phase')
              phaseData = phases.map(p => p.value).map((phaseName) => ({
                phase: phaseName,
                tierConditions: phaseGrouping[phaseName] || [defaultTierCondition],
              }))
            } else {
              tierConditions = tierData?.tierConditions || [defaultTierCondition]
            }
            return {
              ...t,
              form: tierData?.form || t.form,
              tierConditions,
              phaseData,
            }
          })
        }
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { copyFormData, state: { compoundsCopayData }, editMode, modelData } = this.props
    const { tierStates } = this.state

    if (copyFormData && !_.isEqual(prevState, this.state)) {
      copyFormData({
        data: { ...this.state.form },
        prevCopays: { ...this.state.prevCopays },
        compoundsData: { ...this.state.compoundsData }
      })
    }
    if (copyFormData && !_.isEqual(prevState.tierStates, tierStates)) {
      copyFormData({
        data: { ...this.state.form },
        prevCopays: { ...this.state.prevCopays },
        compoundsData: { ...tierStates, ...this.state.compoundsData }
      })
    }
  }

  initializeTierValues = (tiers, phases) => {
    const { state } = this.props
    const { state: { planName } } = this.props
    const formVars = {
      accumulate_deductible: '',
      accumulate_out_of_pocket: '',
      deductible_exempt: '',
      out_of_pocket_exempt: '',
      deductible_applies: ''
    }
    let tierConditions = []
    let phaseData = []
    const defaultTierCondition = {
      action: "",
      days_supply: "",
      member_pay: null,
      copay_type: "",
      effective_end_date: state.effective_end_date,
      effective_start_date: state.effective_start_date,
      max_copay: null,
      min_copay: null,
      network_tier: "ALL",
    }
    if (phases?.length > 0) {
      phaseData = phases.map(p => {
        const tierConditions = []
        rowCellInfo.day_supply.options.map(option => {
          if (option.value !== "All") {
            tierConditions.push({
              ...defaultTierCondition,
              day_supply: option.value,
            })
          }
        })
        return {
          phase: p.value,
          tierConditions: tierConditions,
        }
      })
    } else {
      rowCellInfo.day_supply.options.map(option => {
        if (option.value !== "All") {
          tierConditions.push({
            ...defaultTierCondition,
            day_supply: option.value,
          })
        }
      })
    }

    const tierStates = tiers?.map((tier) => ({
      program_id: tier.value,
      program_name: `${planName} || Compounds Cost Share`,
      form: _.cloneDeep(formVars),
      tierConditions: _.cloneDeep(tierConditions),
      phaseData: _.cloneDeep(phaseData),
    }))
    return tierStates
  }

  getDefaultValues = (form) => {
    const { props: { state } } = this
    const defaultForm = {
      accumulate_deductible: form.accumulate_deductible || '',
      accumulate_out_of_pocket: form.accumulate_out_of_pocket || '',
      deductible_exempt: form.deductible_exempt || '',
      out_of_pocket_exempt: form.out_of_pocket_exempt || ''
    }
    const defaultCompoundsCopayRow = {
      action: '',
      member_pay: null,
      copay_type: '',
      max_copay: null,
      min_copay: null,
      network_tier: 'ALL',
      day_supply: '',
    }
    let defaultCompoundsCopay = [defaultCompoundsCopayRow]
    return {
      defaultForm,
      defaultCompoundsCopay,
    }
  }

  onFilterChange = (e, dropdown) => {
    const { copyFormData } = this.props
    const { form, prevCopays } = this.state
    // const { name, value } = dropdown || el.currentTarget
    // form["copays"][0][name] = value

    const { tierStates, activeTierLevel } = this.state
    const { name, value } = dropdown || e.currentTarget

    const tierStatesCopy = _.cloneDeep(tierStates)
    const activeTierState = _.cloneDeep(tierStatesCopy[activeTierLevel])
    const prevState = activeTierState.form
    activeTierState.form = {
      ...prevState,
      [name]: value,
    }
    tierStatesCopy[activeTierLevel] = activeTierState
    this.setState({
      tierStates: tierStatesCopy,
    })
    copyFormData({
      data: { ...form },
      prevCopays,
      tierStates: { ...this.state.tierStates }
    })
    this.setState({ form })
  }

  addRow = () => {
    const { tierStates, activeTierLevel, activePhaseIndex } = this.state
    const hasPhases = tierStates[activeTierLevel].phaseData.length > 0
    const { tierConditions, effective_end_date, effective_start_date } = hasPhases ? tierStates[activeTierLevel].phaseData[activePhaseIndex] : tierStates[activeTierLevel]
    let startDate = effective_start_date || this.props.state?.effective_start_date ? moment(this.props.state?.effective_start_date).toDate() : ''
    let endDate = effective_end_date || this.props.state?.effective_end_date ? moment(this.props.state?.effective_end_date).toDate() : ''
    const tierStatesCopy = _.cloneDeep(tierStates)
    const activeTierState = { ...tierStatesCopy[activeTierLevel] }
    const dataToUpdate = hasPhases ? activeTierState.phaseData[activePhaseIndex] : activeTierState
    dataToUpdate.tierConditions = [
      ...tierConditions,
      {
        action: "",
        days_supply: "",
        member_pay: null,
        copay_type: "",
        effective_end_date: endDate || '',
        effective_start_date: startDate || '',
        max_copay: null,
        min_copay: null,
        network_tier: "ALL",
      },
    ]
    tierStatesCopy[activeTierLevel] = activeTierState

    this.setState({
      tierStates: tierStatesCopy,
    })
  }

  delRow = (rowIndex) => {
    const { tierStates, activeTierLevel, activePhaseIndex } = this.state
    const hasPhases = tierStates[activeTierLevel].phaseData.length > 0
    const { tierConditions } = hasPhases ? tierStates[activeTierLevel].phaseData[activePhaseIndex] : tierStates[activeTierLevel]

    const tierStatesCopy = _.cloneDeep(tierStates)
    const activeTierState = { ...tierStatesCopy[activeTierLevel] }
    const dataToUpdate = hasPhases ? activeTierState.phaseData[activePhaseIndex] : activeTierState

    dataToUpdate.tierConditions = tierConditions.length <= 1 ? [] : tierConditions.filter((cond) => tierConditions.indexOf(cond) !== rowIndex)

    tierStatesCopy[activeTierLevel] = activeTierState

    this.setState({
      tierStates: tierStatesCopy,
    })
  }

  handleChange = (el, dropdown, rowIndex, gridApi) => {
    const { tierStates, activeTierLevel, activePhaseIndex } = this.state

    const tierStatesCopy = { ...tierStates }
    const activeTierState = { ...tierStatesCopy[activeTierLevel] }
    const hasPhases = activeTierState.phaseData.length > 0

    const tierConditionsCopy = hasPhases ? [...activeTierState.phaseData[activePhaseIndex].tierConditions] : [...activeTierState.tierConditions]
    const rowToUpdate = tierConditionsCopy[rowIndex]
    const { name, value } = dropdown || el.currentTarget
    rowToUpdate[name] = value

    if (name === 'copay_type') {
      rowToUpdate['member_pay'] = ''
    }

    tierConditionsCopy[rowIndex] = rowToUpdate
    const dataToUpdate = hasPhases ? activeTierState.phaseData[activePhaseIndex] : activeTierState
    dataToUpdate.tierConditions = tierConditionsCopy

    tierStatesCopy[activeTierLevel] = activeTierState
    this.setState({
      tierStates: Object.values(tierStatesCopy),
    })
    gridApi.refreshCells()
  }

  showGenerateButton = () => {
    const { tierStates, activeTierLevel, activePhaseIndex } = this.state
    const tierStatesCopy = { ...tierStates }
    const activeTierState = { ...tierStatesCopy[activeTierLevel] }
    const hasPhases = (activeTierState?.phaseData?.length > 0) || false
    const tierConditionsCopy = hasPhases ? [...activeTierState.phaseData[activePhaseIndex === -1 ? 0 : activePhaseIndex]?.tierConditions] : [...(activeTierState?.tierConditions || [])]
    const ele = tierConditionsCopy.find(x => x.day_supply === "All" || x.pharmacy_type === "ALL")
    return !_.isEmpty(ele)
  }

  handleTierClick = (idx) => {
    const { state } = this
    const { activeTierLevel } = state
    const newIndex = activeTierLevel === idx ? -1 : idx
    this.setState({ activeTierLevel: newIndex, activePhaseIndex: 0 })
  }

  handlePhaseClick = (idx) => {
    const { state } = this
    const { activePhaseIndex } = state
    const newIndex = activePhaseIndex === idx ? -1 : idx
    this.setState({ activePhaseIndex: newIndex })
  }

  generateGrid = () => {
    const { tierStates } = this.state
    const { actions } = this.props
    const tierStatesCopy = [...tierStates]
    try {
      tierStatesCopy.forEach(formData => {
        if (formData?.phaseData?.length > 0) {
          formData.phaseData.forEach(phase => {
            this.validateCopayData(phase)
          })
        } else {
          this.validateCopayData(formData)
        }
      })
    } catch (err) {
      const transitionalPortal = {
        header: 'Validation Error',
        copy: err,
      }
      actions.displayTransitionalPortal(transitionalPortal)
      return
    }
    this.setState({ tierStates: tierStatesCopy })
  }

  hasDuplicates = (array, pharmacy_type) => {
    const arr = array.filter(x => x === pharmacy_type)
    return (new Set(arr)).size !== arr.length
  }

  validateCopayData = (formData) => {
    const networkTierArray = [...formData.copays.map((data) => data.pharmacy_type)];
    let newTierConditions = []
    formData.copays.forEach(tier => {
      if (tier?.day_supply !== "All") { newTierConditions.push(tier) }
      if (tier?.day_supply === "All") {
        if (this.hasDuplicates(networkTierArray, tier?.pharmacy_type)) {
          throw new Error(resolveMessage("val_remove_duplicate_value_from_network_tier").message)
        } else {
          rowCellInfo.day_supply.options.forEach(option => {
            if (option?.value !== "All") {
              const newTierCondition = { ...tier }
              newTierCondition.day_supply = option.value
              newTierConditions.push(newTierCondition)
            }
          })
        }
      }
    })
    let networkTierCopy = []
    newTierConditions.forEach(tier => {
      if (tier?.pharmacy_type === "ALL") {
        rowCellInfo.pharmacy_type.options.forEach(option => {
          if (option?.value !== "ALL") {
            networkTierCopy = [...networkTierCopy, { ...tier, pharmacy_type: option.value }]
          }
        })
      } else {
        networkTierCopy = [...networkTierCopy, { ...tier }]
      }
    })
    newTierConditions = networkTierCopy
    formData.copays = newTierConditions
    const groupped = _.groupBy(newTierConditions, n => `${n.day_supply}${n.pharmacy_type}`)
    const duplicates = (_.uniq(_.flatten(_.filter(groupped, function (n) { return n.length > 1 }))).length > 1)
    if (duplicates) {
      throw new Error(resolveMessage("val_remove_duplicate_value_from_grid").message)
    }
  }

  renderAccordianContent = (activePhaseIndex, phases) => {
    const { state } = this.props
    const { planLOB } = state
    if (planLOB?.includes('Medicare D')) {
      return this.renderNestedAccordian(phases, activePhaseIndex)
    }
    return this.renderGrid()
  }

  renderNestedAccordian = (phases, activePhaseIndex) => {
    const phasesAccordian = phases.map((phase, phaseIdx) => (
      <div className="section-phase-level">
        <Accordion.Title
          active={activePhaseIndex === phaseIdx}
          index={phaseIdx}
          onClick={() => this.handlePhaseClick(phaseIdx)}
        >
          <Icon name="dropdown" />
          {phase.display_name}
        </Accordion.Title>
        <Accordion.Content
          active={activePhaseIndex === phaseIdx}
        >
          {this.renderGrid()}
        </Accordion.Content>
      </div>
    ))
    return phasesAccordian
  }

  _updateFields = (el, dateData) => {
    const { name, value } = dateData || el.currentTarget
    if (name == 'compound_coverage' && value.toLowerCase() == 'all') {
      this.setState((prevState) => ({
        ...prevState,
        compoundsData: {
          ...prevState.compoundsData,
          [name]: value,
          allow_scc8: 'No'
        },
      }))
    }
    else if (name === 'minimum_compounds_price' || name === 'maximum_compounds_price') {
      this.setState((prevState) => ({
        ...prevState,
        compoundsData: {
          ...prevState.compoundsData,
          [name]: value
        }
      }))
      return !validateNumericField(value) ? 'Only numeric is allowed' : null
    }
    else {
      this.setState((prevState) => ({
        ...prevState,
        compoundsData: {
          ...prevState.compoundsData,
          [name]: value
        }
      }))
    }
  }

  renderGrid = () => {
    const { editMode } = this.props
    const { compoundsData, form } = this.state
    const { tierStates, activeTierLevel, hasPhases, activePhaseIndex } = this.state
    const activeTierState = tierStates[activeTierLevel]?.phaseData?.length > 0 ? tierStates[activeTierLevel].phaseData[activePhaseIndex] : tierStates[activeTierLevel] ?? {}
    const { tierConditions } = activeTierState ?? {}
    const headers = [
      'action',
      'day_supply',
      'network_tier',
      'copay_type',
      'member_pay',
      'min_copay',
      'max_copay',
    ]
    const cellRendererParams = {
      day_supply: {
        width: 145,
        overrideHeader: 'Days Supply',
      },
      copay_type: {
        width: 135,
        overrideHeader: 'COPAY Type',
      },
      member_pay: {
        width: 160,
        overrideHeader: 'Member Pay',
        valueFormatter: params => {
          if (params.data.member_pay === undefined || params.data.member_pay === null) return null
          if (params.data.copay_type === 'Fixed') return '$' + `${params.data.member_pay}`
          if (params.data.copay_type === 'Co-Insurance') return `${params.data.member_pay}` + '%'
          return params.data.member_pay
        },
      },
      min_copay: {
        width: 135,
        overrideHeader: 'Min COPAY',
      },
      max_copay: {
        width: 135,
        overrideHeader: 'Max COPAY',
      },
      action: {
        cellRenderer: DeleteRowRenderer,
        state: {
          onClick: this.delRow,
        },
        width: 135,
      },
    }
    const cellEditorParams = {
      day_supply: {
        editable: true,
        cellEditor: DropdownEditor,
        onChange: this.handleChange,
      },
      network_tier: {
        editable: true,
        cellEditor: DropdownEditor,
        onChange: this.handleChange,
      },
      copay_type: {
        editable: true,
        cellEditor: DropdownEditor,
        onChange: this.handleChange,
      },
      member_pay: {
        editable: true,
        cellEditor: InputTextEditor,
        onChange: this.handleChange,
        validation: 'numeric',
      },
      min_copay: {
        editable: true,
        cellEditor: InputTextEditor,
        onChange: this.handleChange,
        validation: 'numeric',
      },
      max_copay: {
        editable: true,
        cellEditor: InputTextEditor,
        onChange: this.handleChange,
        validation: 'numeric',
      },
    }

    if (editMode) {
      return (
        <section className="grid-container spacing border-line shadow">
          <div className="content">
            <FliptGrid
              data={tierConditions}
              headers={headers}
              cellRendererParams={cellRendererParams}
              cellEditorParams={cellEditorParams}
              rowCellInfo={rowCellInfo}
            />
          </div>
          <div className="addRowButtonContainer">
            <AddRow addRow={() => this.addRow('compoundsCopay')} />
          </div>
        </section>
      )
    }
    return (
      <div className="content" >
        <FliptGrid
          data={tierConditions}
          headers={headers.slice(0, -1)}
        />
      </div>
    )
  }


  render() {
    const { compoundsData: f } = this.state
    const { fieldDetails } = this.props
    const {
      activeTierLevel,
      activePhaseIndex,
      tierStates,
      phases,
      hasPhases
    } = this.state
    const {
      editMode,
    } = this.props
    const activeTierState = tierStates[activeTierLevel] ?? {}
    const { form } = activeTierState ?? {}

    const {
      accumulate_deductible,
      accumulate_out_of_pocket,
      deductible_exempt,
      out_of_pocket_exempt,
      deductible_applies,
    } = form ?? {}

    const dropdownOptions = [{ value: 'Y', text: 'YES' }, { value: 'N', text: 'NO' }]

    const ccOptions = fieldDetails?.find(({ field, options }) => field === 'compound_coverage' && options.length > 0)
    const ccDropdown = ccOptions?.options.map(x => {
      x["text"] = x["display_name"]
      return x
    })

    const scc8Options = fieldDetails?.find(({ field, options }) => field === 'allow_scc8' && options.length > 0)
    const scc8Dropdown = scc8Options?.options.map(x => {
      x["text"] = x["display_name"]
      return x
    })
    const cpoDropdownOptions = fieldDetails?.find(({ field, options }) => field === 'compound_pricing_options' && options.length > 0)
    const cpoDropdown = cpoDropdownOptions?.options.map(x => {
      x["text"] = x["display_name"]
      return x
    })

    const mpaDropdownOptions = fieldDetails?.find(({ field, options }) => field === 'maximum_price_action' && options.length > 0)
    const mpaDropdown = mpaDropdownOptions?.options.map(x => {
      x["text"] = x["display_name"]
      return x
    })

    const cpeDropdownOptions = fieldDetails?.find(({ field, options }) => field === 'compound_pharmacy_exclusions' && options.length > 0)
    const cpeDropdown = cpeDropdownOptions?.options.map(x => {
      x["text"] = x["display_name"]
      return x
    })
    return (
      <div className="compounds">

        <div className="section-compounds">
          <div className="compounds-dropdowns">
            <FliptDropdown
              placeholder="Select"
              label="Compound Coverage"
              name="compound_coverage"
              value={f.compound_coverage}
              options={ccDropdown}
              onChange={this._updateFields}
              // disabled={!f?.compound_coverage?.toLowerCase().includes('compound')}
              stylized
            />
          </div>
          <div className="compounds-dropdowns">
            <FliptDropdown
              placeholder="Select"
              label="SCC Code 8 Allowed?"
              name="allow_scc8"
              value={f.allow_scc8}
              options={scc8Dropdown}
              onChange={this._updateFields}
              readOnly={f?.compound_coverage?.toLowerCase().includes('compound')}
              required={f?.compound_coverage?.toLowerCase().includes('all')}
              stylized
            />
          </div>
          <div className="compounds-dropdowns">
            <FliptDropdown
              placeholder="Select"
              label="Compound Pricing Options"
              name="compound_pricing_options"
              value={f.compound_pricing_options}
              options={cpoDropdown}
              onChange={this._updateFields}
              readOnly={f?.compound_coverage?.toLowerCase().includes('compound')}
              required={f?.compound_coverage?.toLowerCase().includes('atleast_1')}
              stylized
            />
          </div>
          <div className="compounds-dropdowns">
            <FliptDropdown
              placeholder="Select"
              label="Maximum Price Action"
              name="maximum_price_action"
              value={f.maximum_price_action}
              options={mpaDropdown}
              onChange={this._updateFields}
              stylized
            />
          </div>
          <div className="compounds-dropdowns input"> <span>Minimum Compounds Price</span>
            {/* <span className='dollar'>$</span> */}
            <FliptInput
              icon="dollar"
              iconPosition="left"
              semantic
              name="minimum_compounds_price"
              defaultValue={f.minimum_compounds_price ? f.minimum_compounds_price : 0.00}
              onChange={this._updateFields}
              disabled={f?.compound_coverage?.toLowerCase().includes('compound')}
              type="number"
            />
          </div>
          <div className="compounds-dropdowns input"><span>Maximum Compounds Price</span>
            {/* <span className='dollar'>$</span> */}
            <FliptInput name="maximum_compounds_price"
              icon="dollar"
              iconPosition="left"
              semantic
              defaultValue={f.maximum_compounds_price ? f.maximum_compounds_price : 0.00}
              onChange={this._updateFields}
              disabled={f?.compound_coverage?.toLowerCase().includes('compound')}
              type="number"
            />
          </div>
          {/* <div className="compounds-dropdowns">
            <FliptDropdown
              placeholder="Select"
              label="Compound Pharmacy Exclusions"
              name="compound_pharmacy_exclusions"
              value={f.compound_pharmacy_exclusions}
              options={cpeDropdown}
              onChange={this._updateFields}
              readOnly={f?.compound_coverage?.toLowerCase().includes('compound')}
              stylized
            />
          </div> */}
        </div>
        <div className="default_copay">
          <div className="section-programs">
            <Accordion className="section-programs-accordion" styled>
              <div className="section-program-level">
                <Accordion.Title
                  active={true}
                >
                  <Icon name="dropdown" />
                  Compounds Cost Share
                </Accordion.Title>
                <Accordion.Content
                  active={true}
                >
                  <div className="formulary_copay">
                    <div className="section-tiers">
                      <Accordion className="section-tiers-accordion" styled>
                        {tierStates?.map((tier, idx) => (
                          <div className="section-tier-level">
                            <Accordion.Title
                              active={activeTierLevel === idx}
                              index={idx}
                              onClick={() => this.handleTierClick(idx)}
                            >
                              <Icon name="dropdown" />
                              {tier.tier_name}
                            </Accordion.Title>
                            <Accordion.Content
                              active={activeTierLevel === idx}
                            >
                              <div className="section">
                                <section className="copay-dropdowns-container spacing border-line shadow">
                                  <div className="copay-dropdowns">
                                    <FliptDropdown
                                      placeholder="Select"
                                      label="Accumulate Deductible"
                                      name="accumulate_deductible"
                                      value={accumulate_deductible}
                                      options={dropdownOptions}
                                      onChange={this.onFilterChange}
                                      stylized
                                      disabled={!editMode}
                                    />
                                  </div>
                                  <div className="copay-dropdowns">
                                    <FliptDropdown
                                      placeholder="Select"
                                      label="Accumulate Out of Pocket"
                                      name="accumulate_out_of_pocket"
                                      value={accumulate_out_of_pocket}
                                      options={dropdownOptions}
                                      onChange={this.onFilterChange}
                                      stylized
                                      disabled={!editMode}
                                    />
                                  </div>
                                  <div className="copay-dropdowns">
                                    <FliptDropdown
                                      placeholder="Select"
                                      label="Deductible Exempt"
                                      name="deductible_exempt"
                                      value={deductible_exempt}
                                      options={dropdownOptions}
                                      onChange={this.onFilterChange}
                                      stylized
                                      disabled={!editMode}
                                    />
                                  </div>
                                  <div className="copay-dropdowns">
                                    <FliptDropdown
                                      placeholder="Select"
                                      label="Out of Pocket Exempt"
                                      name="out_of_pocket_exempt"
                                      value={out_of_pocket_exempt}
                                      options={dropdownOptions}
                                      onChange={this.onFilterChange}
                                      stylized
                                      disabled={!editMode}
                                    />
                                  </div>
                                  {hasPhases && <div className="deductible-dropdown">
                                    <FliptDropdown
                                      placeholder="Select"
                                      label="Deductible Applies"
                                      name="deductible_applies"
                                      value={deductible_applies}
                                      options={dropdownOptions}
                                      onChange={this.onFilterChange}
                                      stylized
                                      disabled={!editMode}
                                    />
                                  </div>
                                  }
                                </section>
                                {this.renderAccordianContent(activePhaseIndex, phases)}
                              </div>
                            </Accordion.Content>
                          </div>
                        ))}
                      </Accordion>
                    </div>
                  </div>
                </Accordion.Content>
              </div>
            </Accordion>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  const { fieldDetails } = props

  const tiersField = fieldDetails?.find(({ field, type, options }) => type === 'DATA' && field === 'programs' && options.length > 0)
  const tiers = tiersField?.options || []

  const phasesField = fieldDetails?.find(({ field }) => field === 'phase')
  const phases = phasesField?.options || []

  const networkTierOptions = fieldDetails?.find(({ field }) => ((field === 'network_tier')))?.options || []


  const copayTypeField = fieldDetails?.find(({ field }) => field === 'copay_type')
  const copayTypeFieldOptions = copayTypeField?.options || []

  const copayTypeOptions = copayTypeFieldOptions?.map((option, index) => {
    const { value } = option
    return {
      key: index,
      text: value,
      value,
    }
  })


  const daySupplyFieldOptions = fieldDetails?.find(({ field }) => field === 'day_supply')?.options || []
  const daySupplyOptions = daySupplyFieldOptions?.map((option, index) => ({
    key: index,
    text: option?.value,
    value: option?.value,
  }))

  rowCellInfo.day_supply.options = daySupplyOptions
  rowCellInfo.copay_type.options = copayTypeOptions
  rowCellInfo.network_tier.options = networkTierOptions?.map((code, index) => ({
    key: index,
    text: code?.display_name,
    value: code?.display_name,
  }))

  rowCellInfo.network_tier.options.unshift({
    key: 0, text: 'ALL', value: 'ALL', map_value: 'ALL',
  })

  return ({
    state: {
      app: state.app,
      planLOB: state.planManagement.planLOB,
      planName: state.planManagement.planName,
      tiers: tiers ?? [],
      phases: phases ?? [],
      compoundsData: state.planManagement.compoundsData,
      compoundsCopayData: state.planManagement.customCopayData,
      effective_end_date: state.planManagement.planDetails.model_data.effective_end_date,
      effective_start_date: state.planManagement.planDetails.model_data.effective_start_date,
    },
  })
}
const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...PlanManagementActions,
    ...AppActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

const compoundsContainer = (props) => (
  <Compounds
    editMode={true}
    {...props}
  />
)

export default Compounds

export const CompoundsContainer = connect(mapStateToProps, mapDispatchToProps)(compoundsContainer)
