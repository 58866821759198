export default [
  {
    key: 'Variable',
    text: 'Variable',
    value: 'Variable',
  },
]
export const rateType = [
  {
    key: 'Negative',
    text: 'Negative',
    value: 'Negative',
  },
  {
    key: 'Positive',
    text: 'Positive',
    value: 'Positive',
  },
]