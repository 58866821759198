import React from 'react'

import './styles.scss'
import FliptButton from '../components/form/fliptButton'

export default function SystemErrorModal(props) {
    const { onSaveClick, toggleModal, headerText,
    } = props

    const handleClick = () => {
        onSaveClick(),
        toggleModal()
    }

    return (
        <div className="systemErrorModal">
            <div className="header-text">
                <h3>{headerText}</h3>
            </div>
            <div className="buttons-container">
                <FliptButton className="primary" name="Ok" onClick={handleClick} />
                <FliptButton className="primary" name="Cancel" onClick={toggleModal} />
            </div>
        </div>
    )
}
