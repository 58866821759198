import Immutable from 'seamless-immutable'
import { createActions, createReducer } from 'reduxsauce'

export const INITIAL_STATE = Immutable({
    loading: false,
    saveTimerConfigData: {},
    allPATimerConfigurationsData: {},
})


export const setSaveTimerConfigDataReducer = (state = INITIAL_STATE, { payload }) => ({
    ...state,
    saveTimerConfigData: payload,
})

export const clearTimerConfigDataReducer = (state = INITIAL_STATE, { }) => ({
    ...state,
    saveTimerConfigData: {},
})

export const setAllPATimerConfigurationsDataReducer = (state = INITIAL_STATE, { payload }) => ({
    ...state,
    allPATimerConfigurationsData: payload,
})


export const { Types, Creators } = createActions({
    submitTimerConfigData: ['payload'],
    setSaveTimerConfigData: ['payload'],
    getTimerConfiguration: ['payload'],
    getAllPATimerConfigurations: ['payload'],
    setAllPATimerConfigurationsData: ['payload'],
    clearTimerConfigData: [],
})

const HANDLERS = {
    [Types.SET_SAVE_TIMER_CONFIG_DATA]: setSaveTimerConfigDataReducer,
    [Types.SET_ALL_PA_TIMER_CONFIGURATIONS_DATA]: setAllPATimerConfigurationsDataReducer,
    [Types.CLEAR_TIMER_CONFIG_DATA]: clearTimerConfigDataReducer,
}
export default createReducer(INITIAL_STATE, HANDLERS)


