export const SEARCH_TYPE = Object.freeze({
  SINGLE_INPUT: 'single_input',
  BATCH_INPUT: 'batch_input'
})

export const FIELD_TYPES = [
  { key: '1', value: 'member_id', text: 'Member ID' },
  { key: '2', value: 'auth_id', text: 'Auth ID' },
]

export const MANUAL_LIST = 'manual_list'
export const FILE_IMPORT = 'file_import'

export const INPUT_TYPES = [
  { value: MANUAL_LIST, text: 'Manual List' },
  { value: FILE_IMPORT, text: 'File Import' }
]

export const HEADERS = [
  'action', 'auth_id', 'processed_date', 'date_of_service', 'cardholder_id', 'group_id', 'transaction_response_status',
  'claim_status', 'payer_pcn', 'sequenceNumber', 'first_name', 'last_name', 'date_of_birth', 'plan_name', 'rx_number', 'drug_name', 'drug_strength',
  'drug_dosage_form', 'submitted_quantity', 'multi_source', 'drug_mfg', 'pharmacy_name', 'otc', 'reject_message', 'calculated_drug_cost', 'patient_pay_amount',
  'service_provider_id', 'is_vip',
]

export const HEADER_MAPPING = {
  drug_dosage: 'drug_dosage_form',
  drug_manufacturer: 'drug_mfg',
  otc_indicator: 'otc',
  prescription_reference_number: 'rx_number',
  quantity_dispensed: 'submitted_quantity',
  startDate: 'processed_date',
}

export const CLAIM_DETAILS_MAPPING = {
  auth_id: 'auth_id',
  date_of_service: 'date_of_service',
  cardholder_id: 'cardholder_id',
  group_id: 'group_id',
  rx_number: 'prescription_reference_number',
  drug_name: 'drug_name',
  drug_strength: 'drug_strength',
  drug_dosage_form: 'drug_dosage',
  calculated_drug_cost: 'calculated_drug_cost',
  patient_pay_amount: 'patient_pay_amount'
}

export const ADJUSTMENT_TYPES = [
  {
    text: 'Administrative Error',
    value: 'administrative_error'
  },
  {
    text: 'Financial Error',
    value: 'financial_error'
  },
  {
    text: 'Coverage Error',
    value: 'coverage_error'
  },
  {
    text: 'Other Error',
    value: 'other_error'
  },
]

export const BYPASSED_TYPES = [
  {
    text: 'All',
    value: 'ALL',
    key: 0
  }
]

export const TRUE_FALSE_TYPES = [
  {
    text: 'True',
    value: true,
    key: 0
  },
  {
    text: 'False',
    value: false,
    key: 1
  }
]

export const ADJUDICATION_TYPES = [
  {
    text: 'Validation Logic',
    value: 'Validation Logic'
  },
  {
    text: 'Eligibility Logic',
    value: 'Eligibility Logic'
  },
  {
    text: 'Pricing Logic',
    value: 'Pricing Logic'
  },
  {
    text: 'Claim Check Logic',
    value: 'Claim Check Logic'
  },
  {
    text: 'Accumulator Logic',
    value: 'Accumulator Logic'
  },
  {
    text: 'Post Processing Logic',
    value: 'Post Processing Logic'
  },
  {
    text: 'Medicare Logic',
    value: 'Medicare Logic'
  },
  {
    text: 'FAST Reader Service',
    value: 'FAST Reader Service'
  },
  {
    text: 'All Services',
    value: 'All Services'
  }
]

export const REPROCESSING_TYPES = [
  {
    text: 'Claim Processing Logic',
    value: 'Claim Processing Logic'
  },
  {
    text: 'Benefit Changes',
    value: 'Benefit Changes'
  },
  {
    text: 'Formulary Changes',
    value: 'Formulary Changes'
  },
  {
    text: 'Plan Configuration',
    value: 'Plan Configuration'
  },
  {
    text: 'OHI Changes',
    value: 'OHI Changes'
  },
  {
    text: 'LICS Changes',
    value: 'LICS Changes'
  },
  {
    text: 'Pharmacy Audit',
    value: 'Pharmacy Audit'
  },
  {
    text: 'DED/OOP Changes',
    value: 'DED/OOP Changes'
  },
  {
    text: 'TrOOP Changes',
    value: 'TrOOP Changes'
  },
  {
    text: 'Other',
    value: 'Other'
  },
]