import {
    call,
    put,
    select,
    takeLatest,
    take,
} from 'redux-saga/effects'

import { Types, Creators as UserServiceBrowserActions } from '../../reducers/api/userServiceBrowser'
import { getAppState } from '../../reducers/selectors'
import { fetchPost, fetchPut } from '../../../utils/fetchApi'


export default [
    getUsersWatcher,
    updateUserWatcher,
]

/* WATCHERS */
function* getUsersWatcher() {
    yield takeLatest(Types.GET_USERS, getUsersHandler)
}

function* updateUserWatcher() {
    yield takeLatest(Types.UPDATE_USER, updateUserHandler)
}

/* HANDLERS */
function* getUsersHandler({ payload }) {
    try {
        yield put(UserServiceBrowserActions.setLoading(true))
        const appState = yield select(getAppState)
        const response = yield call(fetchPost, `${appState.apiUrl}/service/eligibility-search-and-update`, payload, false);
        let data = {};
        if (response.full && response.full.data) {
            data = response.full.data;
        } else if (response.full && response.full.user) {
            data = response.full.user;
        }
        yield put(UserServiceBrowserActions.setSearchResult(data));
        return data;
    } catch (error) {
        console.log(error)
        return null;
    } finally {
        yield put(UserServiceBrowserActions.setLoading(false))
    }
}

function* updateUserHandler({ payload }) {
    try {
        yield put(UserServiceBrowserActions.setLoading(true))
        const appState = yield select(getAppState)
        yield call(fetchPut, `${appState.apiUrl}/service/eligibility-search-and-update`, { attributes: payload}, false);
        const refreshUserData = {
            "search_by": "user_id",
            "search_value": payload.user_id
          }
        yield call(getUsersHandler, { payload: refreshUserData })
        yield put(UserServiceBrowserActions.setUpdateSuccess(true));
    } catch (error) {
        console.log(error)
        return null;
    } finally {
        yield put(UserServiceBrowserActions.setLoading(false))
    }
}