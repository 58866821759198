import React, { PureComponent } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Creators as ClientConfigurationActions } from "../../../../redux/reducers/api/clientConfiguration";
import "./styles.scss";
import { renderDynamicInput, findSelectedFields } from "../utils";
import {
  rewardsPenaltyAttributes
} from "../Constant/rewardsTabFields";
import { COLUMN_DIVIDER, REWARDS_TAB_HEADERS_BASELINE, REWARDS_TAB_HEADERS_PENALTY, REWARDS_TAB_HEADERS_REWARD } from "../Constant";

const RewardTab = (props) => {
  const {
    rewardTab,
    validations,
    validationResults,
    singleLevelAttribute,
    nodeType,
  } = props.state;
  const showErrors =
    validationResults && validationResults && !validationResults.isAllValid;

  const _updateFields = (el, dropdown) => {
    let { name, value, checked } = dropdown || el.currentTarget;
    if (dropdown?.type == "radio") {
      value = checked;
      props.actions.updateAttributeFields({ name, value });
    } else {
      props.actions.updateAttributeFields({ name, value });
    }
  };

  const renderAdditionalFields = (fieldList, no) => {
    if (fieldList?.length == 0) return null;
    if (Object.keys(singleLevelAttribute).length == 0) return null;
    const selectedFieldsArray = findSelectedFields(
      fieldList,
      singleLevelAttribute
    );
    const selectedFieldsArrayGroupedBySections = selectedFieldsArray.reduce(function(m,it,i){let subgroup=it["subgroup"]||"";
    m[subgroup] ||(m[subgroup]=[]);
    m[subgroup].push(it)
    return m
  },{});
    //convert to a list of tuples sorted by the min order in each group
   const selectedTuples = Object.entries(selectedFieldsArrayGroupedBySections)
    .map(([subgroup, items]) => ({
        subgroup,
        items: items.sort((a, b) => a.order - b.order),
    }))
    .sort((a, b) => a.items[0].order - b.items[0].order)
    const rowDivider = no;
    const fieldProps = {
      ...singleLevelAttribute,
      onChange: _updateFields,
      className: "fields-client-header",
      nodeType,
    };
    return (
      <>
        {/* use array method to Calculate rows dynamically acc to ui layout */}
        {selectedTuples.map(function (tuple) {
          return (
            <fieldset>
              <legend>{tuple.subgroup}</legend>
              {Array.from(
                { length: Math.ceil(tuple.items.length / rowDivider) },
                (_, i) => (
                  <div className="fields-container" key={`row${i}`}>
                    {tuple.items
                      .slice(i * rowDivider, (i + 1) * rowDivider)
                      .map((item) => renderDynamicInput(item, fieldProps))}
                  </div>
                )
              )}
            </fieldset>
          );
        })}
      </>
    );
  };

  return (
    <div className="rewards-sections">
      {renderAdditionalFields(rewardsPenaltyAttributes, COLUMN_DIVIDER)}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    state: {
      nodeType: state.clientConfiguration.nodeType,
      singleLevelAttribute: state.clientConfiguration.singleLevelAttribute,
      rewardTab: state.clientConfiguration.rewardTab,
      validations: state.clientConfiguration.validations.rewardTab,
      validationResults: state.clientConfiguration.validationResults.rewardTab,
    },
  };
};

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...ClientConfigurationActions,
  };

  return {
    actions: bindActionCreators(allActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RewardTab);
