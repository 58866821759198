import React, { useState, useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Creators as AppActions } from '../../../../../redux/reducers/app'
import { Creators as ClaimsPRPActions } from '../../../../../redux/reducers/api/claimsProcessingAndReprocessing'
import './styles.scss'
import FliptDropdown from '../../../../../components/v2/fliptDropdown'
import FliptGrid from '../../../../../components/fliptGrid'
import FliptButton from '../../../../../components/v2/fliptButton'
import PlanPicker from '../../components/planPicker'

function PharmacyNetworks(props) {
	const { state, actions, editable, testBatchConfig, setTestBatchConfig } = props
	const { claimsProcessingAndReprocessing } = state
	const { pharmacy_networks, pharmacy_network_tiers, pharmacy_network_edits } = claimsProcessingAndReprocessing.pharmacyNetworks
	const { pharmacyNetworks, plans } = testBatchConfig
	const isDisabled = !editable
	// useEffect(() => {
	// 	if (!(testBatchConfig.plans.length > 0)) {
	// 		actions.clearPlans()
	// 	}
	// }, [])

	const setPharmacyNetworks = () => {
		actions.getPharmacyNetwork({
			status: ["Published", "TEST_READY"]
		})
	}

	const handleSelectionChange = (selectedRows) => {
		setTestBatchConfig((prevForm) => ({
			...prevForm,
			plans: [
				...selectedRows,
			],
		}))

	}

	const onPharmacyNetworkChange = (e, dropdown) => {
		const { name, value } = dropdown

		const pharmacyNetworkObjs = {
			pharmacy_networks,
			pharmacy_network_tiers,
			pharmacy_network_edits,
		}

		const objs = pharmacyNetworkObjs[name] || []

		const finalObjs = value.map((val) => {
			const selectedObject = objs.find((obj) => obj.doc_name === val)
			const { doc_name, doc_version, doc_id = '' } = selectedObject || {}
			const approval_doc_id = doc_id
			return {
				approval_doc_id,
				doc_id,
				doc_name,
				doc_version,
			}
		})

		setTestBatchConfig((prevForm) => ({
			...prevForm,
			pharmacyNetworks: {
				...prevForm.pharmacyNetworks,
				[name]: {
					value,
					obj: finalObjs,
				},
			},
		}))
	}
	const pullLinkedPlans = () => {
		const { pharmacy_networks, pharmacy_network_tiers, pharmacy_network_edits } = pharmacyNetworks
		const extractedValues = {
			pharmacy_networks: pharmacy_networks.value,
			pharmacy_network_tiers: pharmacy_network_tiers.value,
			pharmacy_network_edits: pharmacy_network_edits.value
		}
		actions.getTestBatchConfigPlans({
			type: 'pharmacy_network',
			form: {
				...extractedValues,
				hierarchy: testBatchConfig.hierarchyDropdown,
				status: ['Published', 'TEST_READY']
			}
		})
	}
	return (
		<div id="testReadyPharmacyNetworks">
			<div className="section">
				<div className="get-networks-button-container">
					<FliptButton
						className="primary"
						name="Pull Test Objects"
						onClick={() => setPharmacyNetworks()}
						disabled={isDisabled}
					/>
				</div>
				<h2>Available Pharmacy Network Objects</h2>
				<div className="flex-container">
					<div className="flex-item">
						<FliptDropdown
							label="Pharmacy Networks"
							options={pharmacy_networks.map((pharmacy_network) => ({
								text: pharmacy_network.doc_name,
								value: pharmacy_network.doc_name,
							}))}
							multiple
							name="pharmacy_networks"
							value={pharmacyNetworks.pharmacy_networks.value}
							onChange={onPharmacyNetworkChange}
							className="dropdown"
							disabled={isDisabled}
						/>
					</div>
					<div className="flex-item">
						<FliptDropdown
							label="Network Tiers"
							options={pharmacy_network_tiers.map((nt) => ({
								value: nt.doc_name,
								text: nt.doc_name,
							}))}
							value={pharmacyNetworks.pharmacy_network_tiers.value}
							multiple
							name="pharmacy_network_tiers"
							onChange={onPharmacyNetworkChange}
							className="dropdown"
							disabled={isDisabled}
						/>
					</div>
					<div className="flex-item">
						<FliptDropdown
							label="Network Edits"
							options={pharmacy_network_edits.map((ne) => ({
								text: ne.doc_name,
								value: ne.doc_name,
							}))}
							value={pharmacyNetworks.pharmacy_network_edits.value}
							multiple
							name="pharmacy_network_edits"
							onChange={onPharmacyNetworkChange}
							className="dropdown"
							disabled={isDisabled}
						/>
					</div>
				</div>
				<div className="get-plans-button-container">
					<FliptButton
						className="primary"
						onClick={pullLinkedPlans}
						name="Pull Linked Plans"
						disabled={isDisabled}
					/>
				</div>
				<h2>Available Benefit Plans</h2>
				<PlanPicker
					selectedPlans={plans}
					allPlans={claimsProcessingAndReprocessing.plans}
					onSelectionChange={handleSelectionChange}
					isDisabled={isDisabled}
				/>
			</div>
		</div>
	)
}

const mapStateToProps = (state) => ({
	state: {
		user: state.user,
		claimsProcessingAndReprocessing: state.claimsProcessingAndReprocessing,
	},
})

const mapDispatchToProps = (dispatch) => {
	const allActions = {
		...AppActions,
		...ClaimsPRPActions,
	}

	return {
		actions: bindActionCreators(allActions, dispatch),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PharmacyNetworks)
