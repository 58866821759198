import React, { useState, useMemo } from 'react'
import moment from 'moment-timezone'
import { connect } from 'react-redux'
import { cloneDeep } from 'lodash'
import FliptButton from '../../../../components/form/fliptButton'
import FliptGrid from '../../../../components/fliptGrid'
import { fetchPost, fetchPut } from '../../../../utils/fetchApi'

import './styles.scss'
import { generateClaimDictionary } from '../generateClaimDictionary'
import { filterRowData } from '../../../../utils/utilities'

// copied from ../../../../redux/reducers/selectors
function _findApiData(data, apiId) {
  return data.map((d) => {
    if (d.id === apiId) {
      return d
    }
    if (d.children && d.children.length) {
      return _findApiData(d.children, apiId)
    }
    return false
  }).filter((d) => !!d).flat()[0]
}

const NDC_LENGTH = 11

const BatchManualClaims = ({ state, actions }) => {

  const headerNames = ["Payer Claim Number", "Claim Status", "Member ID", "Rx Number",
    "Pharmacy NPI", "Prescriber NPI", "Claim Date / Date of Service", "NDC", "Quantity Dispensed",
    "Days-supply", "Client Due Amount", "Patient Paid", "U&C", "Ingredient Cost",
    "Gross Due Amount", "Dispensing Fee", "Tax/Admin", "Check Equation"]

  const headerNamesDictionary = {
    claimNumber: "Payer Claim Number",
    claimStatus: "Claim Status",
    member_id: "Member ID",
    prescription_reference_number: "Rx Number",
    pharmacynpi: "Pharmacy NPI",
    prescriberNpi: "Prescriber NPI",
    date_of_service: "Claim Date / Date of Service",
    ndc: "NDC",
    quantity: "Quantity Dispensed",
    days_supply: "Days-supply",
    clientDueAmount: "Client Due Amount",
    patient_paid_amount_submitted: "Patient Paid",
    usual_and_customary_charge: "U&C",
    ingredient_cost_submitted: "Ingredient Cost",
    gross_amount_due: "Gross Due Amount",
    dispensing_fee_submitted: "Dispensing Fee",
    flat_sales_tax_amount_submitted: "Tax/Admin",
    checkEquation: "Check Equation"
  }
  const [csvFile, setCsvFile] = useState()
  const [csvArray, setCsvArray] = useState([])
  const [headers, setCsvHeaders] = useState([])
  const [processedClaimCount, setProcessedClaimCount] = useState(0)

  const getApiPaths = () => {
    const { api_path: memberLookupApiId } = _findApiData(state.menuData, 'member-lookup')
    const memberLookupUrl = `${state.serviceUrl}${memberLookupApiId}`

    const { api_path: prescriberLookupId } = _findApiData(state.menuData, 'prescriber-lookup')
    const prescriberLookupUrl = `${state.serviceUrl}${prescriberLookupId}`

    const { api_path: manualClaimLookupId } = _findApiData(state.menuData, 'manual-claim-entry')
    const manualClaimUrl = `${state.serviceUrl}${manualClaimLookupId}`

    const userCAG = state.user.userCAG
    return [memberLookupUrl, prescriberLookupUrl, manualClaimUrl, userCAG]
  }

  const [memberLookupUrl, prescriberLookupUrl, manualClaimUrl, userCAG] = useMemo(getApiPaths, [state.user.userCAG, state.serviceUrl, state.menuData])

  const processCSV = (str, delim = ',') => {
    const headers = str.slice(0, str.indexOf('\n')).split(delim)
    const rows = str.slice(str.indexOf('\n') + 1).split('\n')

    const newArray = rows.map(row => {
      const values = row.split(delim)
      const eachObject = headers.reduce((obj, header, i) => {
        obj[header] = values[i]
        return obj
      }, {})
      return eachObject
    })

    setCsvArray(newArray)
    setCsvHeaders(headers)
  }

  const submit = () => {
    const file = csvFile
    const reader = new FileReader()

    reader.onload = function (e) {
      const text = e.target.result
      processCSV(text)
    }

    reader.readAsText(file)
  }

  const memberLookup = async (member_id) => {
    let searchData = {
      cag: {
        group: userCAG.group || [],
        domain_name: userCAG.account || [],
      },
      member_identifier: 'member_id',
      id: member_id,
    }
    return fetchPost(memberLookupUrl, searchData)
  }

  const prescriberLookup = async (prescriberNpi) => {
    let searchData = {
      prescriber_npi: prescriberNpi,
    }
    return fetchPost(prescriberLookupUrl, searchData)
  }

  // maps prescriber data to make it similar to <FliptPrescriberSearch/> component - agGrid mapping
  const mapPrescriberData = (prescriberData) => {
    const prescriberHeaders = [
      'npi', 'first_name', 'last_name', 'secure_fax_number', 'phone_number', 'address', 'city',
      'state', 'zip_code',
    ]
    const headerMapping = {
      npi: 'npi',
      provider_first_name: 'first_name',
      provider_last_name_legal_name: 'last_name',
      provider_first_line_business_practice_location_address: 'address',
      provider_business_practice_location_address_city_name: 'city',
      provider_business_practice_location_address_state_name: 'state',
      provider_business_practice_location_address_postal_code: 'zip_code',
      provider_business_practice_location_address_fax_number: 'fax_number',
      provider_business_practice_location_address_telephone_number: 'phone_number'
    }

    let mappedPrescriberData = {}
    if (prescriberData) {
      mappedPrescriberData = { ...prescriberData, ...filterRowData(prescriberData, prescriberHeaders, headerMapping) }
      return mappedPrescriberData
    }
    return prescriberData

  }

  const putManualClaimEntry = async (data) => {
    return fetchPut(manualClaimUrl, data)
  }

  const submitManualClaim = async () => {

    const AUTH_ID_HEADER = 'Auth ID'
    const MESSAGE_HEADER = 'Response Message'
    const STATUS_HEADER = 'Manual Claim Status'
    const ERROR_HEADER = 'Error'

    const csvArrayCopy = cloneDeep(csvArray)

    for (let i = 0; i < csvArrayCopy.length; i++) {
      let currentRow = csvArrayCopy[i]
      try {

        const memberLookupPromise = memberLookup(currentRow[headerNamesDictionary.member_id])
        const prescriberLookupPromise = prescriberLookup(currentRow[headerNamesDictionary.prescriberNpi])
        let [memberData, prescriberData] = await Promise.all([memberLookupPromise, prescriberLookupPromise])
        if (!memberData.data?.length || memberData?.status !== '200') {
          throw new Error('Member info not found')
        }
        if (!prescriberData?.data?.length || prescriberData.status !== '200') {
          throw new Error('Prescriber info not found')
        }

        memberData = memberData.data[0].member_details
        prescriberData = mapPrescriberData(prescriberData.data[0])

        let ndc = currentRow[headerNamesDictionary.ndc]
        if (ndc.length < NDC_LENGTH) {
          ndc = ndc.padStart(NDC_LENGTH, '0')
        }
        const drugData = { ndc }

        const pharmacyData = { pharmacynpi: currentRow[headerNamesDictionary.pharmacynpi] }

        let date_prescription_written = currentRow[headerNamesDictionary.date_of_service]
        date_prescription_written = moment(date_prescription_written, 'MM/DD/YYYY').format('YYYYMMDD')

        let date_of_service = currentRow[headerNamesDictionary.date_of_service]
        date_of_service = moment(date_of_service, 'MM/DD/YYYY').format('YYYYMMDD')

        const prescriptionForm = {
          prescription_reference_number: currentRow[headerNamesDictionary.prescription_reference_number],
          quantity: currentRow[headerNamesDictionary.quantity],
          fill_number: Math.floor(Math.random() * 6) + 1,
          days_supply: currentRow[headerNamesDictionary.days_supply],
          compound_code: '1',
          date_prescription_written,
          date_of_service,
        }
        const form = {
          other_coverage_code: '00',
          ingredient_cost_submitted: currentRow[headerNamesDictionary.ingredient_cost_submitted],
          gross_amount_due: currentRow[headerNamesDictionary.gross_amount_due],
          dispensing_fee_submitted: currentRow[headerNamesDictionary.dispensing_fee_submitted],
          flat_sales_tax_amount_submitted: currentRow[headerNamesDictionary.flat_sales_tax_amount_submitted],
        }
        if (currentRow[headerNamesDictionary.usual_and_customary_charge]) {
          form.usual_and_customary_charge = currentRow[headerNamesDictionary.usual_and_customary_charge]
        }
        form.patient_paid_amount_submitted = currentRow[headerNamesDictionary.patient_paid_amount_submitted] || '0'

        // temporary measure to prevent claim rejection by code 'M/I Patient Relationship Code'
        form.patient_relationship_code = '0'

        const data = generateClaimDictionary(memberData,
          drugData,
          prescriberData,
          pharmacyData,
          prescriptionForm,
          form)

        const response = await putManualClaimEntry(data)
        if (response?.data?.length) {
          currentRow[AUTH_ID_HEADER] = response.data[0]
        }
        currentRow[MESSAGE_HEADER] = response.message
        currentRow[STATUS_HEADER] = response.status
        setProcessedClaimCount(prevCount => prevCount + 1)
      } catch (err) {
        console.log("error: ", err)
        currentRow[ERROR_HEADER] = err.message || err
        setProcessedClaimCount(prevCount => prevCount + 1)
      }
    }

    // write csv file
    const newHeaders = [...headerNames, AUTH_ID_HEADER, MESSAGE_HEADER, STATUS_HEADER, ERROR_HEADER]
    const newCsvArray = [...csvArrayCopy.map((row) => {
      const rowArray = []
      for (let i = 0; i < newHeaders.length; i++) {
        rowArray.push(row[newHeaders[i]] || '')
      }
      // handle values with commas and double quotes
      return rowArray.map(str => `"${str.replace(/"/g, '\"')}"`)
    })]
    const csvString = [
      newHeaders,
      ...newCsvArray
    ].map(e => e.join(","))
      .join("\n")

    // download results
    let pom = document.createElement('a');
    let blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    let url = URL.createObjectURL(blob);
    pom.href = url;
    pom.setAttribute('download', `batch_manual_claim_result_${Date.now()}.csv`);
    pom.click();
  }

  return (
    <div id='batch-manual-claims'>
      <input type='file' accept='.csv' id='csvFile' onChange={(e) => { setCsvFile(e.target.files[0]) }}>
      </input>
      <br />
      <div className='csv-buttons-container'>
        <FliptButton
          name='Process CSV'
          className='primary buttons'
          onClick={(e) => {
            e.preventDefault()
            if (csvFile) submit()
          }}
        />
      </div>
      <div className="csv-grid">
        <FliptGrid
          data={csvArray}
          headers={headers}
        />
      </div>
      <div className='csv-buttons-container'>
        <FliptButton
          disabled={!csvArray.length}
          name='Submit Manul Claims'
          className='primary buttons'
          onClick={submitManualClaim} />
      </div>
      {processedClaimCount ? <div className='status-string'>
        Processed {processedClaimCount} out of {csvArray.length} claims
      </div> : ''}
    </div>
  )

}

const mapStateToProps = (state) => ({
  state: {
    menuData: state.app.menu_data,
    serviceUrl: state.app.serviceUrl,
    user: state.user,
  },
})

export default connect(mapStateToProps, null)(BatchManualClaims)

