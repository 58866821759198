import React, { PureComponent } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Creators as groupManagementActions } from '../../../../redux/reducers/api/groupManagement'
import statesJSON from '../../../../config/constants/states'

import './styles.scss'
import { Creators as AppActions } from '../../../../redux/reducers/app'
import BreadCrumb from '../../../../components/breadCrumb'
import FliptButton from '../../../../components/form/fliptButton'
import { validate } from './validations'
import FliptInput from '../../../../components/form/fliptPaInput'
import { convertStrToDateObj, getCAGOptions } from '../../../../utils/utilities'
import FliptDropdown from '../../../../components/form/fliptPaDropdown'
import FliptPaDatepicker from '../../../../components/form/fliptPaDatepicker'
import moment from 'moment'
import { benefitTypeOptions, cardTypeOptions, deductibleSatisfactionOptions, deducticleOopOptions, idCardCreatorOptions, idOnCardOptions, oopSatisfactionOptions, typeOfOopOptions } from '../data/dropdownData'


class AddGroup extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            form: {
                carrier_name: '',
                account_name: '',
                group_name: '',
                group_address: '',
                group_state: '',
                group_effective_date: '',
                group_phone: '',
                contact_name: '',
                family_deductible: '',
                family_oop: '',
                plan_id: '',
                plan_termination_date: '',
                cms_contract_id: '',
                cms_pbp: '',
                group_id: '',
                group_city: '',
                group_zip_code: '',
                group_phone_extension: '',
                group_termination_date: '',
                group_fax: '',
                individual_deductible: '',
                individual_oop: '',
                plan_name: '',
                plan_effective_date: '',
                // location_start_date: '',
                organization_name: '',
                client_name: '',
                client_number: '',
                benefit_period_type: '',
                deductible_satisfaction: '',
                oop_satisfaction: '',
                type_of_oop: '',
                deductible_oop_logic: '',
                id_card_creator: '',
                id_card_type: '',
                id_on_card: '',
                client_defined_1: '',
                client_defined_2: '',
                client_defined_3: '',
                client_defined_4: '',
                client_defined_5: '',
                client_defined_6: '',
                client_defined_7: '',
                client_defined_8: '',
                client_defined_9: '',
                client_defined_10: '',
                benefit_effective_start_date: '',
                contact_first_name: '',
                contact_last_name: '',
                contact_email: '',
                meta_id: ''
            },
            editable: false,
            isUpdate: false
        }
    }

    componentDidMount() {
        const { location: { state }, actions } = this.props
        if (state?.meta_id) {
            actions.getGroupById({ meta_id: state?.meta_id })
        }
        if (state?.editable) {
            this.setState({ editable: state.editable })
        }
    }

    componentDidUpdate(prevProps) {
        const { state: { groupData } } = this.props
        if (groupData !== prevProps.state.groupData) {
            this.setState({ form: groupData, isUpdate: true })
        }
    }

    _showTransitionalPortal = (header, message) => {
        const { props } = this;
        const transitionalPortal = {
            header: header,
            copy: message,
        }
        props.actions.displayTransitionalPortal(transitionalPortal)
    }

    updateGroup = () => {
        const { actions } = this.props
        const { form } = this.state
        form.plan_effective_date = form.plan_effective_date ? moment(form.plan_effective_date).format('YYYY-MM-DD') : ''
        form.plan_termination_date = form.plan_termination_date ? moment(form.plan_termination_date).format('YYYY-MM-DD') : ''
        form.benefit_effective_start_date = form.benefit_effective_start_date ? moment(form.benefit_effective_start_date).format('YYYY-MM-DD') : ''
        form.group_effective_date = form.group_effective_date ? moment(form.group_effective_date).format('YYYY-MM-DD') : ''
        form.group_termination_date = form.group_termination_date ? moment(form.group_termination_date).format('YYYY-MM-DD') : ''
        form.group_termination_date = form.group_termination_date ? moment(form.group_termination_date).format('YYYY-MM-DD') : ''

        if (validations(form, actions)) {
            actions?.createGroup(form)
        }
    }


    goBack = () => {
        const { props: { history } } = this
        history.goBack()
    }


    _updateField = (el, dropdown) => {
        const { name, value } = dropdown || el.currentTarget
        const { form } = this.state
        const newForm = { ...form }
        newForm[name] = value
        this.setState({ form: newForm })
    }


    render() {
        const { form, isUpdate, editable } = this.state
        const { state: { carriers, userCAG } } = this.props
        const filterOptions = getCAGOptions(carriers, form.carrier_name, form.account_name)
        const organizationOptions = userCAG?.organization?.map((org, index) => {
            return ({
                key: index,
                value: org,
                text: org
            })
        })
        const clientOptions = userCAG?.client?.map((client, index) => {
            return ({
                key: index,
                value: client,
                text: client
            })
        })
        const stateOptions = Object.entries(statesJSON).map(([k, v]) => ({
            key: k,
            text: `${k} - ${v}`,
            value: k,
        }))

        return (
            <div id="add-group">
                <div className='breadcrum-container'>
                    <BreadCrumb {...this.props} isUpdate={isUpdate} editable={editable} />
                </div>
                <div className='header'>
                    {!editable ? 'View Group Details' : isUpdate ? 'Update Group' : 'Add Group'}
                </div>
                <div className='devider' />
                <span className='inner-header'>Hierarchy</span>
                <div className='field-outer-container'>
                    <div className='field-inner-container'>
                        <FliptDropdown placeholder="Select Organization" readOnly={!editable} options={organizationOptions} label='Organization' name='organization_name' value={form.organization_name} onChange={this._updateField} stylized />
                        <FliptInput label='Group ID' name='group_id' disabled={!editable || isUpdate} value={form.group_id} onChange={this._updateField} stylized />
                    </div>
                    <div className='field-inner-container'>
                        <FliptDropdown placeholder="Select Client" readOnly={!editable} options={clientOptions} label='Client' name='client_name' value={form.client_name} onChange={this._updateField} stylized />
                        <FliptInput label='Group Name' name='group_name' disabled={!editable} value={form.group_name} onChange={this._updateField} stylized />
                    </div>
                    <div className='field-inner-container'>
                        <FliptDropdown placeholder="Select Carrier" readOnly={!editable} label="Carrier" name="carrier_name" value={form.carrier_name} options={filterOptions.carrierOptions} onChange={this._updateField} stylized />
                        <FliptInput label='Contract ID' name='cms_contract_id' disabled={!editable} value={form.cms_contract_id} onChange={this._updateField} stylized />
                    </div>
                    <div className='field-inner-container'>
                        <FliptDropdown placeholder="Select Account" readOnly={!editable} label="Account" name="account_name" value={form.account_name} options={filterOptions.accountOptions} onChange={this._updateField} stylized />
                        <FliptInput label='PBP' name='cms_pbp' disabled={!editable} value={form.cms_pbp} onChange={this._updateField} stylized />
                    </div>
                </div>
                <div className='devider' />
                <span className='inner-header'>Group Demographics</span>
                <div className='field-outer-container'>
                    <div className='field-inner-container'>
                        <FliptInput label='Group Address' disabled={!editable} name='group_address' value={form.group_address} onChange={this._updateField} stylized />
                        <FliptInput label='Group Fax' disabled={!editable} name='group_fax' value={form.group_fax} onChange={this._updateField} stylized />
                    </div>
                    <div className='field-inner-container'>
                        <FliptInput label='Group City' disabled={!editable} name='group_city' value={form.group_city} onChange={this._updateField} stylized />
                        <FliptInput label='Group Phone' validation='numeric' maxLength={10} disabled={!editable} name='group_phone' value={form.group_phone} onChange={this._updateField} stylized />
                    </div>
                    <div className='field-inner-container'>
                        <FliptDropdown options={stateOptions} label='Group State' disabled={!editable} name='group_state' value={form.group_state} onChange={this._updateField} stylized />
                        <FliptInput label='Group Phone Extension' disabled={!editable} name='group_phone_extension' value={form.group_phone_extension} onChange={this._updateField} stylized />
                    </div>
                    <div className='field-inner-container'>
                        <FliptInput label='Group Zipcode' disabled={!editable} name='group_zip_code' value={form.group_zip_code} onChange={this._updateField} stylized />
                    </div>
                </div>
                <div className='devider' />
                <span className='inner-header'>Group Contact Information</span>
                <div className='field-outer-container'>
                    <div className='field-inner-container'>
                        <FliptInput label='Contact First Name' disabled={!editable} name='contact_first_name' value={form.contact_first_name} onChange={this._updateField} stylized />
                    </div>
                    <div className='field-inner-container'>
                        <FliptInput label='Contact Last Name' disabled={!editable} name='contact_last_name' value={form.contact_last_name} onChange={this._updateField} stylized />
                    </div>
                    <div className='field-inner-container'>
                        <FliptInput inputClass='input-class-med' label='Contact Email' disabled={!editable} name='contact_email' value={form.contact_email} onChange={this._updateField} stylized />
                    </div>
                </div>
                <div className='devider' />
                <span className='inner-header'>Group Benefit Information</span>
                <div className='field-outer-container'>
                    <div className='field-inner-container'>
                        <FliptPaDatepicker readOnly={!editable} labelText='Group Effective Date' minDate={moment().subtract(1, 'year').toDate()} name='group_effective_date' value={convertStrToDateObj(form?.group_effective_date)} onChange={this._updateField} stylized />
                        <FliptInput label='Plan Id' disabled={!editable} value={form.plan_id} name='plan_id' onChange={this._updateField} stylized />
                    </div>
                    <div className='field-inner-container'>
                        <FliptPaDatepicker readOnly={!editable} labelText='Group Termination Date' minDate={form.group_effective_date} name='group_termination_date' value={convertStrToDateObj(form?.group_termination_date)} onChange={this._updateField} stylized />
                        <FliptInput label='Plan Name' disabled={!editable} name='plan_name' value={form.plan_name} onChange={this._updateField} stylized />
                    </div>
                    <div className='field-inner-container'>
                        <FliptPaDatepicker labelText='Benefit Effective Start Date' readOnly={!editable} name='benefit_effective_start_date' value={convertStrToDateObj(form?.benefit_effective_start_date)} onChange={this._updateField} stylized />
                        <FliptPaDatepicker labelText='Plan Effective Date' readOnly={!editable} name='plan_effective_date' value={convertStrToDateObj(form?.plan_effective_date)} onChange={this._updateField} stylized />
                    </div>
                    <div className='field-inner-container'>
                        <FliptDropdown label='Benefit Period Type' readOnly={!editable} options={benefitTypeOptions} name='benefit_period_type' value={form.benefit_period_type} onChange={this._updateField} stylized />
                        <FliptPaDatepicker labelText='Plan Termination Date' readOnly={!editable} name='plan_termination_date' value={convertStrToDateObj(form?.plan_termination_date)} onChange={this._updateField} stylized />
                    </div>
                </div>
                <div className='devider' />
                <span className='inner-header'>ID Card Information</span>
                <div className='field-outer-container'>
                    <div className='field-inner-container'>
                        <FliptDropdown options={idCardCreatorOptions} readOnly={!editable} label='ID Card Creator' value={form.id_card_creator} name='id_card_creator' onChange={this._updateField} stylized />
                    </div>
                    <div className='field-inner-container'>
                        <FliptDropdown options={cardTypeOptions} readOnly={!editable} label='ID Card Type' value={form.id_card_type} name='id_card_type' onChange={this._updateField} stylized />
                    </div>
                    <div className='field-inner-container'>
                        <FliptDropdown options={idOnCardOptions} readOnly={!editable} label='ID on the Card' name='id_on_card' value={form.id_on_card} onChange={this._updateField} stylized />
                    </div>
                </div>
                <div className='devider' />
                <span className='inner-header'>Deductible & Out of Pocket Information</span>
                <div className='field-outer-container'>
                    <div className='field-inner-container'>
                        <FliptInput label='Individual Deductible' disabled={!editable} name='individual_deductible' value={form.individual_deductible} onChange={this._updateField} stylized />
                        <FliptDropdown label='Deductible Satisfaction' readOnly={!editable} options={deductibleSatisfactionOptions} name='deductible_satisfaction' value={form.deductible_satisfaction} onChange={this._updateField} stylized />
                    </div>
                    <div className='field-inner-container'>
                        <FliptInput label='Individual OOP' disabled={!editable} name='individual_oop' value={form.individual_oop} onChange={this._updateField} stylized />
                        <FliptDropdown label='OOP Satisfaction' readOnly={!editable} name='oop_satisfaction' options={oopSatisfactionOptions} value={form.oop_satisfaction} onChange={this._updateField} stylized />
                    </div>
                    <div className='field-inner-container'>
                        <FliptInput label='Family Deductible' disabled={!editable} name='family_deductible' value={form.family_deductible} onChange={this._updateField} stylized />
                        <FliptDropdown label='Type Of OOP' readOnly={!editable} name='type_of_oop' options={typeOfOopOptions} value={form.type_of_oop} onChange={this._updateField} stylized />
                    </div>
                    <div className='field-inner-container'>
                        <FliptInput label='Family OOP' disabled={!editable} name='family_oop' value={form.family_oop} onChange={this._updateField} stylized />
                        <FliptDropdown label='Deductible OOP Logic' readOnly={!editable} options={deducticleOopOptions} name='deductible_oop_logic' value={form.deductible_oop_logic} onChange={this._updateField} stylized />
                    </div>
                </div>
                <div className='devider' />
                <span className='inner-header'>Client Defined Fields</span>
                <div className='field-outer-container'>
                    <div className='field-inner-container'>
                        <FliptInput label='Client Defined 1' disabled={!editable} name='client_defined_1' value={form.client_defined_1} onChange={this._updateField} stylized />
                        <FliptInput label='Client Defined 5' disabled={!editable} name='client_defined_5' value={form.client_defined_5} onChange={this._updateField} stylized />
                        <FliptInput label='Client Defined 9' disabled={!editable} name='client_defined_9' value={form.client_defined_9} onChange={this._updateField} stylized />
                    </div>
                    <div className='field-inner-container'>
                        <FliptInput label='Client Defined 2' disabled={!editable} name='client_defined_2' value={form.client_defined_2} onChange={this._updateField} stylized />
                        <FliptInput label='Client Defined 6' disabled={!editable} name='client_defined_6' value={form.client_defined_6} onChange={this._updateField} stylized />
                        <FliptInput label='Client Defined 10' disabled={!editable} name='client_defined_10' value={form.client_defined_10} onChange={this._updateField} stylized />
                    </div>
                    <div className='field-inner-container'>
                        <FliptInput label='Client Defined 3' disabled={!editable} name='client_defined_3' value={form.client_defined_3} onChange={this._updateField} stylized />
                        <FliptInput label='Client Defined 7' disabled={!editable} name='client_defined_7' value={form.client_defined_7} onChange={this._updateField} stylized />
                    </div>
                    <div className='field-inner-container'>
                        <FliptInput label='Client Defined 4' disabled={!editable} name='client_defined_4' value={form.client_defined_4} onChange={this._updateField} stylized />
                        <FliptInput label='Client Defined 8' disabled={!editable} name='client_defined_8' value={form.client_defined_8} onChange={this._updateField} stylized />
                    </div>
                </div>
                {editable ? <div className='button-container'>
                    <FliptButton
                        name={isUpdate ? 'Update Group' : 'Add Group'}
                        className='primary queue-claim-button'
                        onClick={() => this.updateGroup()} />
                    <FliptButton
                        name='Cancel'
                        className='group-button'
                        onClick={() => this.goBack()} />
                </div> : <div className='margin-bottom' />}
            </div >
        )
    }
}

const validations = (payload, actions) => {
    const isValid = Object.keys(validate).every(key => {
        const error = validate[key](payload)
        if (error?.length) {
            const transitionalPortal = {
                header: 'Validation Error',
                copy: (Array.isArray(error) && error?.length && error[0]) || error
            }
            actions.displayTransitionalPortal(transitionalPortal)
            return false
        }
        return true
    })
    return isValid
}

const mapStateToProps = (state) => ({
    state: {
        app: state.app,
        groupData: state.groupManagement.groupData,
        carriers: state?.user?.carriers && state?.user?.carriers?.length > 0 ? state.user.carriers : [],
        userCAG: state?.user?.userCAG
    },
})

const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...AppActions,
        ...groupManagementActions
    }

    return {
        actions: bindActionCreators(allActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddGroup)
