import React from 'react'
import {
  Redirect,
  Route,
} from 'react-router-dom'

export default ({ component, componentProps, ...rest }) => {
  const { app } = componentProps.state
  const isAuthed = app.isLoggedIn
  let redirectPath = '/login'
  const pathname = rest.location.pathname || componentProps.history.location.pathname
  if (isAuthed) {
    const hasPageAccess = _findMenuId(app.menu_data, pathname) || {}

    if (hasPageAccess.path) {
      redirectPath = hasPageAccess.path
    }
  }

  return (
    <Route
      {...rest}
      exact
      render={(props) => (isAuthed ? (
        <div>
          {React.createElement(component, props)}
        </div>
      ) : (
        <Redirect
          to={{
            pathname: redirectPath,
            state: { from: props.location },
            search: props.location.search,
          }}
        />
      ))}
    />
  )
}

function _findMenuId(menuData, pathname, match = []) {
  menuData.filter((m) => {
    if (m.children) {
      _findMenuId(m.children, pathname, match)
    }

    if (m.path === pathname) {
      match.push(m)
      return true
    }

    return false
  })

  return match[0]
}
