import { claimTypeOptions } from "./dropDownData"
import statesJSON from '../../../../../config/constants/states'
export const radioFields = [
    {
        label: 'Upload Claim File',
        name: 'create_type',
        value: 'upload_file',
        readOnly: true
    },
    {
        label: 'Search for Pre-existing claims',
        name: 'create_type',
        value: 'search_file',
        readOnly: true
    },
    {
        label: 'Create net New Claim with test bed',
        name: 'create_type',
        value: 'test_bed',
        readOnly: true
    },
    {
        label: 'Create New Claim manually',
        name: 'create_type',
        value: 'manual',
        readOnly: false
    }
]

const statesData = Object.entries(statesJSON).map(([k, v]) => ({
    key: k,
    text: `${k} - ${v}`,
    value: k,
}))

export const drugFields = [
    {
        label: 'GPI',
        name: 'gpi',
        disabled: true
    },
    {
        label: 'Drug Name',
        name: 'drug_name',
        disabled: true
    }
]

export const prescriberFields = [
    {
        label: 'NPI',
        name: 'npi',
        disabled: true
    },
    {
        label: 'First Name',
        name: 'first_name',
        disabled: true
    },
    {
        label: 'Last Name',
        name: 'last_name',
        disabled: true
    },
    {
        label: 'Secure Fax Number',
        name: 'secure_fax_number',
        disabled: true
    },
    {
        label: 'Phone Number',
        name: 'phone_number',
        disabled: true
    },
    {
        label: 'Address',
        name: 'address',
        disabled: true
    },
    {
        label: 'City',
        name: 'city',
        disabled: true
    },
    {
        label: 'State',
        name: 'state',
        disabled: true
    },
    {
        label: 'Zip Code',
        name: 'zip_code',
        disabled: true
    }
]

export const requiredClaimFields = [
    {
        label: 'Days Supply',
        name: 'days_supply',
        disabled: false,
        type: 'input',
        value: ''
    },
    {
        label: 'Days Drug Quantity',
        name: 'quantity_dispensed',
        disabled: false,
        type: 'input',
        value: ''
    },
    {
        label: 'Claim Type',
        name: 'claim_type',
        disabled: false,
        type: 'dropdown',
        options: claimTypeOptions,
        value: 'B1'
    },
    {
        label: 'Date of Service',
        name: 'date_of_service',
        disabled: false,
        type: 'date',
        value: new Date()
    },
    {
        label: 'Rx Number',
        name: 'prescription_reference_number',
        type: 'input',
        disabled: false,
        value: Math.floor(Math.random() * (999 - 1 + 1)) + 1
    },
    {
        label: 'Ingredient Cost',
        name: 'ingredient_cost_submitted',
        type: 'input',
        disabled: false,
        value: '9999.99'
    },
    {
        label: 'Dispensing Fee',
        name: 'dispensing_fee_submitted',
        type: 'input',
        disabled: false,
        value: '9999.99'
    },
    {
        label: 'U&C',
        name: 'usual_and_customary_charge',
        type: 'input',
        disabled: false,
        value: '9999.99'
    },
    {
        label: 'Gross Amount Due',
        name: 'gross_amount_due',
        type: 'input',
        disabled: false,
        value: '9999.99'
    },
    {
        label: 'Basis of Cost Determination',
        name: 'basis_of_cost_determination',
        type: 'input',
        disabled: false,
        value: ''
    },
    {
        label: 'Fill Number',
        name: 'fill_number',
        type: 'input',
        disabled: false,
        value: '001'
    },
    {
        label: 'Compound Code',
        name: 'compound_code',
        type: 'input',
        disabled: false,
        value: '1'
    },
    {
        label: 'DAW',
        name: 'daw_code',
        type: 'input',
        disabled: false,
        value: '0'
    },
    {
        label: 'Date Written',
        name: 'date_prescription_written',
        type: 'date',
        disabled: false,
        value: new Date()
    }
]

export const pharmacyFields = [
    {
        label: 'NPI',
        name: 'pharmacynpi',
        disabled: true,
    },
    {
        label: 'Name',
        name: 'pharmacyname',
        disabled: true
    },
    {
        label: 'Type',
        name: 'pharmacytype',
        disabled: true
    },
    {
        label: 'Network Status',
        name: 'network_status',
        disabled: true
    },
    {
        label: 'Phone Number',
        name: 'pharmacyphone',
        disabled: true
    },
    {
        label: 'Address 1',
        name: 'pharmacyaddress1',
        disabled: true
    },
    {
        label: 'Address 2',
        name: 'pharmacyaddress2',
        disabled: true
    },
    {
        label: 'City',
        name: 'pharmacycity',
        disabled: true
    },
    {
        label: 'State',
        name: 'pharmacystate',
        disabled: true
    },
    {
        label: 'Zip Code',
        name: 'pharmacyzip1',
        disabled: true
    },
    {
        label: 'Chain Code',
        name: 'chaincode',
        disabled: true
    }
]

export const optionalClaimFields = [
    {
        label: 'Incentive',
        name: 'incentive_amount_submitted',
        disabled: false,
        type: 'input',
        value: ''
    },
    {
        label: 'Flat Sales Tax',
        name: 'flat_sales_tax_amount_submitted',
        disabled: false,
        type: 'input',
        value: ''
    },
    {
        label: '% Sales Tax',
        name: 'percentage_sales_tax_amount_submitted',
        disabled: false,
        type: 'input',
        value: ''
    },
    {
        label: '% Sales Tax Rate',
        name: 'percentage_sales_tax_rate_submitted',
        disabled: false,
        type: 'input',
        value: ''
    },
    {
        label: 'Submission clarification code',
        name: 'scc',
        disabled: false,
        validation: 'numeric',
        type: 'input',
        value: ''
    },
    
]

export const memberFields = [
    {
        label: 'Type',
        name: 'type',
        disabled: false,
        type: 'input',
    },
    {
        label: 'Employee ID',
        name: 'employee_id',
        disabled: false,
        type: 'input',
    },
    {
        label: 'First Name',
        name: 'first_name',
        disabled: false,
        type: 'input',
    },
    {
        label: 'Last Name',
        name: 'last_name',
        disabled: false,
        type: 'input',
    },
    {
        label: 'Email',
        name: 'email',
        disabled: false,
        type: 'input',
    },
    {
        label: 'Phone',
        name: 'phone',
        disabled: false,
        type: 'input',
    },
    {
        label: 'Date Of Birth',
        name: 'dob',
        disabled: false,
        type: 'date',
    },
    {
        label: 'Gender',
        name: 'gender',
        disabled: false,
        type: 'input',
    },{
        label: 'Address 1',
        name: 'address1',
        disabled: false,
        type: 'input',
    }, {
        label: 'Address 2',
        name: 'address2',
        disabled: false,
        type: 'input',
    },
    {
        label: 'State',
        name: 'state',
        disabled: false,
        type: 'dropdown',
        options: statesData,
    },
    {
        label: 'Zip',
        name: 'zip',
        disabled: false,
        type: 'input',
    },{
        label: 'SSN',
        name: 'ssn',
        disabled: false,
        type: 'input',
    },
    {
        label: 'Medicare ID',
        name: 'medicare_id',
        disabled: false,
        type: 'input',
    },
    {
        label: 'TPA Member ID',
        name: 'tpa_member_id',
        disabled: false,
        type: 'input',
    }, {
        label: 'Member ID',
        name: 'member_id',
        disabled: false,
        type: 'input',
    },{
        label: 'Effective Start Date',
        name: 'effective_start_date',
        disabled: false,
        type: 'date',
    },
    {
        label: 'Effective End Date',
        name: 'effective_end_date',
        disabled: false,
        type: 'date',
    },
    {
        label: 'Group',
        name: 'group',
        disabled: false,
        type: 'input',
    },
    {
        label: 'LICS',
        name: 'lics',
        disabled: false,
        type: 'input',
    },{
        label: 'ESRD',
        name: 'esrd',
        disabled: false,
        type: 'input',
    },
    {
        label: 'Hospice',
        name: 'hospice',
        disabled: false,
        type: 'input',
    },
    {
        label: 'Transition Eligible',
        name: 'transition_eligible',
        disabled: false,
        type: 'input',
    },
    {
        label: 'Paid to Date',
        name: 'paid_to_date',
        disabled: false,
        type: 'date',
    }
]

export const gridHeaders = ['action', 'member_id', 'plan_name', 'prescriber_id', 'pharmacy_id', 'ndc', 'drug_name', 'test_batch_claim_id']
