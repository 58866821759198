import React, { PureComponent } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Config from '../../../config'
import { Creators as AppActions } from "../../../redux/reducers/app";

import "./styles.scss";
import BreadCrumb from "../../../components/breadCrumb";

const {FAST_IT_EMAIL, FAST_SELF_SERVICE_URL} = Config;

const open_email_link = `mailto:${FAST_IT_EMAIL}?FAST&nbsp;IT&nbsp;Support`;

const title = `Contact support at ${FAST_IT_EMAIL} with the subject line FAST IT Support`

class Contact extends PureComponent {
  render() {
    return (
      <div id="Contact">
        <BreadCrumb {...this.props} />
        <div className="section">
          <div className="section-header">Contact Information</div>
          <ul>
            <li>Issues with the tool</li>
            <li>Permission and access</li>
            <li>Defects</li>
          </ul>
          <div className="link-description">
            <span>Contact support at </span>
              <a className="email-link"
                href={open_email_link}
                title= {title}
              >
                {FAST_IT_EMAIL}
              </a>
              <span> with the subject line FAST IT Support</span>
          </div>
        </div>
        <div className="section">
          <div className="section-header">Help</div>
            <ul>
              <li>Get onboarding help</li>
              <li>FAQs and training</li>
              <li>Search for help using keywords</li>
            </ul>
          <div className="link-description">
            <a
              href= {FAST_SELF_SERVICE_URL}
              title="Launch the FAST Self Service tool knowledge base and help resources."
              target="_blank"
              rel="noopener noreferrer"
            >
              Launch the FAST Self Service tool knowledge base and help
              resources.
            </a>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  state: {
    app: state.app,
  },
});

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
  };

  return {
    actions: bindActionCreators(allActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
