import React, { Component, useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import './styles.scss'
import BreadCrumb from '../../../../components/breadCrumb'
import FliptGrid from '../../../../components/fliptGrid'
import FliptButton from '../../../../components/form/fliptButton'
import FliptInput from '../../../../components/form/fliptInput'
import ApproveRejectModal from './approveRejectModal'
import Config from '../../../../config'
import { Creators as ClaimsProcessingAndReprocessing } from '../../../../redux/reducers/api/claimsProcessingAndReprocessing'
import { Creators as AppActions } from '../../../../redux/reducers/app'

import FliptDropdown from '../../../../components/v2/fliptDropdown'
import FliptCheckbox from '../../../../components/form/fliptCheckbox'
import { BATCH_APPROVE, BATCH_REJECT, STATUS_COMPLETE, authHeaders, batchSummaryHeader, configValues } from '../data/constant'
import { parseQueryString } from '../../../../utils/utilities'
import { LinkRenderer } from '../../../../components/fliptGrid/cellRenderers';
function TestBatchDetails(props) {
    const { actions, state: { test_batch_details, test_claims }, location: { state } } = props
    const { hierarchyKeys } = test_batch_details

    const _changeBatchStatusCallback = () => {
        const { actions } = props
        actions.toggleModal()
    }
    const changeBatchStatus = (submitted_test_batch_details, status) => {
        if (status === BATCH_APPROVE) {
            actions.approveTestBatch({ ...submitted_test_batch_details }, _changeBatchStatusCallback)
        } else if (status == BATCH_REJECT) {
            actions.rejectTestBatch({ ...submitted_test_batch_details }, _changeBatchStatusCallback)
        }
    }
    useEffect(() => {
        const { history, actions } = props
        actions.clearTestBatchDetails()
        const payload = parseQueryString(history.location.search);
        actions.getTestBatchDetails(payload)
    }, []);

    const _showApproveRejectModal = (status) => {
        const { actions } = props
        actions.setModalComponent({
            modalProperties: { size: 'small', className: 'objects-modal', scrolling: true },
            contents: <ApproveRejectModal changeBatchStatus={changeBatchStatus} status={status} test_batch_details={test_batch_details} {...props} />,
        })
        actions.toggleModal()
    }

    // useEffect(() => {
    //     if ((state?.batch_id)) actions.getTestBatchDetails({ batch_id: state?.batch_id })
    // }, [])

    const getTestClaimsByBatchIdCallback = (claims) => {
        if (!claims) return
        const claimHeaders = Object.keys(claims[0]).sort()
        const csvString = [
            claimHeaders,
            ...claims.map(claim => claimHeaders.map(header => claim[header]))
        ].map(e => e.join(",")).join("\n");
        let pom = document.createElement('a');
        let blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
        let url = URL.createObjectURL(blob);
        pom.href = url;
        pom.setAttribute('download', `test_batch_claims_result_${Date.now()}.csv`);
        pom.click();

    }
    const exportTestClaimDetails = () => {
        const batch_id = test_batch_details.batch_id
        actions.getTestClaimsByBatchId({ batch_id }, getTestClaimsByBatchIdCallback)
    }
    const cellRendererParams = {
        auth_id: {
            cellRenderer: LinkRenderer,
            path: '/claim-txn-overview',
            searchArgs: { auth_id: 'auth_id', test_claim: true, sequence_number: '1' },
        },
    }
    return (
        <div id='test-batch-details'>
            <BreadCrumb {...props} />
            <div className='batch-container'>
                <span className="checkbox-label">Test Batch Details</span>
                <section className='reprocessing-details-section'>
                    <FliptInput
                        stylized
                        label='Batch ID'
                        readOnly
                        value={test_batch_details?.batch_id} />
                    <FliptInput
                        stylized
                        label='Batch Name'
                        readOnly
                        value={test_batch_details?.batch_name} />
                    <FliptInput
                        stylized
                        label='Status'
                        readOnly
                        value={test_batch_details?.status} />
                    <FliptInput
                        stylized
                        label='Batch Type'
                        readOnly
                        value={configValues[test_batch_details?.batch_type]} />
                </section>
                <section className='reprocessing-details-section'>
                    <FliptInput
                        stylized
                        label='Organization'
                        readOnly
                        value={(hierarchyKeys?.organization?.length && hierarchyKeys?.organization[0]) || ''} />
                    <FliptInput
                        stylized
                        label='Client'
                        readOnly
                        value={(hierarchyKeys?.client?.length && hierarchyKeys?.client[0]) || ''} />
                    <FliptInput
                        stylized
                        label='Carrier'
                        readOnly
                        value={(hierarchyKeys?.carrier?.length && hierarchyKeys.carrier[0]) || ''} />
                    <FliptInput
                        stylized
                        label='Account'
                        readOnly
                        value={(hierarchyKeys?.account?.length && hierarchyKeys?.account[0]) || ''} />
                </section>
                <section className='reprocessing-details-section'>
                    <FliptInput
                        stylized
                        label='Group'
                        readOnly
                        value={(hierarchyKeys?.group?.length && hierarchyKeys?.group[0]) || ''} />
                    {test_batch_details?.programs?.length ? <FliptDropdown
                        label="Program Edits"
                        options={(test_batch_details.programs.map((umObj) => ({
                            value: umObj.doc_id,
                            text: umObj.doc_name,
                        }))) || []}
                        value={(test_batch_details.programs.map((umObj) => umObj.doc_id)) || []}
                        multiple
                        className="dropdown"
                        disabled
                    /> : null}
                    {test_batch_details?.prior_authorization?.length ? <FliptDropdown
                        label="Prior Authorization Drug List"
                        options={(test_batch_details.prior_authorization.map((umObj) => ({
                            value: umObj.doc_id,
                            text: umObj.doc_name,
                        }))) || []}
                        value={(test_batch_details.prior_authorization.map((umObj) => umObj.doc_id)) || []}
                        multiple
                        className="dropdown"
                        disabled
                    /> : null}

                </section>
                <section className='reprocessing-details-section'>
                    {test_batch_details?.quantity_limit?.length ? <FliptDropdown
                        label="Quantity Limit"
                        options={(test_batch_details.quantity_limit.map((umObj) => ({
                            value: umObj.doc_id,
                            text: umObj.doc_name,
                        }))) || []}
                        value={(test_batch_details.quantity_limit.map((umObj) => umObj.doc_id)) || []}
                        multiple
                        className="dropdown"
                        disabled
                    /> : null}
                    {test_batch_details?.step_therapy?.length ? <FliptDropdown
                        label="Step Therapy Edits"
                        options={(test_batch_details.step_therapy.map((umObj) => ({
                            value: umObj.doc_id,
                            text: umObj.doc_name,
                        }))) || []}
                        value={(test_batch_details.step_therapy.map((umObj) => umObj.doc_id)) || []}
                        multiple
                        className="dropdown"
                        disabled
                    /> : null}
                    {test_batch_details?.formulary_tier?.length ? <FliptDropdown
                        label="Formulary Tiers"
                        options={(test_batch_details.formulary_tier.map((umObj) => ({
                            value: umObj.doc_id,
                            text: umObj.doc_name,
                        }))) || []}
                        value={(test_batch_details.formulary_tier.map((umObj) => umObj.doc_id)) || []}
                        multiple
                        className="dropdown"
                        isDisabled
                    /> : null}
                </section>
                <span className="checkbox-label">Linked Plans</span>
                {test_batch_details?.benefit_plan?.length ? <section className='checkbox-section'>
                    {test_batch_details.benefit_plan.map((planData) => (<div>
                        <FliptCheckbox
                            label={planData.plan_name}
                            checked
                            disabled
                        />
                        <span className="imp">{planData.plan_name}</span>
                    </div>))}
                </section> : null}
                {test_batch_details?.batch_summary ? <section className='button-header-section'>
                    <span className="checkbox-label">Batch Summary</span>
                    {test_batch_details?.status === STATUS_COMPLETE ? <div>
                        <FliptButton
                            name='Approve'
                            className='green-button'
                            onClick={() => _showApproveRejectModal(BATCH_APPROVE)} />
                        <FliptButton
                            name='Reject'
                            className='red-button'
                            onClick={() => _showApproveRejectModal(BATCH_REJECT)} /></div> : null}

                </section> : null}
                {test_batch_details?.batch_summary ? <section className='summary-grid-height grid-section'>
                    <FliptGrid headers={batchSummaryHeader} data={[test_batch_details.batch_summary] || []} />
                </section> : null}
                <section className='button-header-section'>
                    <span className="checkbox-label">Batch Claims</span>
                    <FliptButton name="Export Test Claim Details" className="export-button" onClick={exportTestClaimDetails} />

                </section>
                <section className='claim-grid-height grid-section'>
                    <FliptGrid
                        headers={authHeaders}
                        cellRendererParams={cellRendererParams}
                        data={test_claims || []}
                    />
                </section>
            </div>
        </div>)

}
const mapStateToProps = (state) => ({
    state: {
        user: state.user,
        test_batch_details: state.claimsProcessingAndReprocessing.testBatchDetails,
        test_claims: state.claimsProcessingAndReprocessing.testBatchClaims,
        batchIdClaims: state.claimsProcessingAndReprocessing.batchIdClaims,
    },
})
const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...ClaimsProcessingAndReprocessing,
        ...AppActions,
    }

    return {
        actions: bindActionCreators(allActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TestBatchDetails)
