import { isEmpty } from "lodash";

export const validate = {
    doc_name: ({ doc_name }) => isEmpty(doc_name) ? "Dur Name should not be empty." : "",
    dur_type: ({ dur_type }) => isEmpty(dur_type) ? "Dur Type should not be empty." : "",
    lookback_period: ({ lookback_period }) => isEmpty(lookback_period) ? "Lookback Period should not be empty." : "",
    effective_date: ({ effective_date }) => !effective_date ? "Effective Date should not be empty." : "",
    is_global: ({ is_global }) => isEmpty(is_global) ? "Global should be either Yes or No." : "",
    hierarchy: ({ hierarchy, is_global }) => !is_global && isEmpty(hierarchy) ? "Hierarchy should not be empty or Select global Object to Yes" : "",
    conditions: ({ conditions }) => {
        if (conditions?.length) {
            let keyArray = []
            return conditions.map((row, index) => {
                let columns = Object.keys(row)
                const empty = columns.filter(c => !row[c].toString())
                if (empty.includes('reject_type')) {
                    return `Action Taken in row ${index + 1} should not be empty.`
                }
                if (empty.includes('reject_code') && empty.includes('reject_message') && (row.reject_type === 'Hard Reject'
                    || row.reject_type === 'Soft Reject')) {
                    return `Reject Code and Reject Message in row ${index + 1} should not be empty.`
                }
                if ((empty.includes('reject_message') && (row.reject_type === 'Message'))) {
                    return `Reject Message in row ${index + 1} should not be empty.`
                }
                const checkValue = `${row.management_level},${row.severity_level},${row.documentation_level},${row.onset}`
                if (keyArray.includes(checkValue)) return `Row Number ${index + 1} is a Duplicate.`
                else {
                    if (!isEmpty(checkValue)) keyArray.push(checkValue)
                }

                const allEmpty = ['management_level', 'severity_level', 'documentation_level', 'onset'].every((val) => empty.includes(val))
                if (allEmpty) {
                    return `Add One of Management Level, Severity Level, Documentation Level or Onset in row ${index + 1}.`
                } else return ''
            }).filter((data) => data !== '')
        } else {
            return `Condition Grid should not be empty.`
        }
    }
}

export const validateDuplicateRx = {
    doc_name: ({ doc_name }) => isEmpty(doc_name) ? "Dur Name should not be empty." : "",
    dur_type: ({ dur_type }) => isEmpty(dur_type) ? "Dur Type should not be empty." : "",
    effective_date: ({ effective_date }) => !effective_date ? "Effective Date should not be empty." : "",
    is_global: ({ is_global }) => isEmpty(is_global) ? "Global should be either Yes or No." : "",
    hierarchy: ({ hierarchy, is_global }) => !is_global && isEmpty(hierarchy) ? "Hierarchy should not be empty or Select global Object to Yes" : "",
    conditions: ({ conditions }) => {
        if (conditions?.length) {
            let keyArray = []
            return conditions.map((row, index) => {
                let columns = Object.keys(row)
                const empty = columns.filter(c => !row[c].toString())

                if (empty.includes('fill_threshold')) {
                    return `Fill Threshold % in row ${index + 1} should not be empty.`
                }
                if (empty.includes('gpi_level')) {
                    return `GPI Level in row ${index + 1} should not be empty.`
                }
                if (empty.includes('reject_type')) {
                    return `Action Taken in row ${index + 1} should not be empty.`
                }
                if (empty.includes('reject_code') && empty.includes('reject_message') && (row.reject_type === 'Hard Reject'
                    || row.reject_type === 'Soft Reject')) {
                    return `Reject Code and Reject Message in row ${index + 1} should not be empty.`
                }
                if ((empty.includes('reject_message') && (row.reject_type === 'Message'))) {
                    return `Reject Message in row ${index + 1} should not be empty.`
                }

            }).filter((data) => data && data !== '')
        } else {
            return `Condition Grid should not be empty.`
        }
    }
}

export const validateRegimenCompliance = {
    doc_name: ({ doc_name }) => isEmpty(doc_name) ? "Dur Name should not be empty." : "",
    dur_type: ({ dur_type }) => isEmpty(dur_type) ? "Dur Type should not be empty." : "",
    effective_date: ({ effective_date }) => !effective_date ? "Effective Date should not be empty." : "",
    is_global: ({ is_global }) => isEmpty(is_global) ? "Global should be either Yes or No." : "",
    hierarchy: ({ hierarchy, is_global }) => !is_global && isEmpty(hierarchy) ? "Hierarchy should not be empty or Select global Object to Yes" : "",
    conditions: ({ conditions }) => {
        if (conditions?.length) {
            return conditions.map((row, index) => {
                let columns = Object.keys(row)
                const empty = columns.filter(c => !row[c].toString())
                if(isNaN(parseFloat(row['min_day_supply'])) == true){
                    return `Min Days Supply in row ${index + 1} should not be empty and should be a valid number.`;
                }
                if (isNaN(parseFloat(row['percent_day_below_compliance'])) == true ) {
                    return `% Days Below Compliance in row ${index + 1} should not be empty and should be a valid number.`;
                }
                if (isNaN(parseFloat(row['max_day_below_compliance'])) == true ) {
                    return `Max Days Below Compliance in row  ${index + 1} should not be empty and should be a valid number.`;
                }
                if (empty.includes('maintenance_drug')) {
                    return `Maintenance Drug in row ${index + 1} should not be empty.`
                }
                if (empty.includes('gpi_level')) {
                    return `GPI Level in row ${index + 1} should not be empty.`
                }
                if ((empty.includes('reject_message') && (row.reject_type === 'Message'))) {
                    return `Display Message in row ${index + 1} should not be empty.`
                }

                return ""; // No errors found for this row
            }).filter((data) => data && data !== '');
        } else {
            return `Condition Grid should not be empty.`;
        }
    }
};



export const validateDosage = {
    doc_name: ({ doc_name }) => isEmpty(doc_name) ? "Dur Name should not be empty." : "",
    dur_type: ({ dur_type }) => isEmpty(dur_type) ? "Dur Type should not be empty." : "",
    effective_date: ({ effective_date }) => !effective_date ? "Effective Date should not be empty." : "",
    is_global: ({ is_global }) => isEmpty(is_global) ? "Global should be either Yes or No." : "",
    hierarchy: ({ hierarchy, is_global }) => !is_global && isEmpty(hierarchy) ? "Hierarchy should not be empty or Select global Object to Yes" : "",
    conditions: ({ conditions }) => {
        if (conditions?.length) {
            return conditions.map((row, index) => {
                let columns = Object.keys(row)
                const empty = columns.filter(c => !row[c].toString())

                if (empty.includes('edit_type')) {
                    return `Edit Type in row ${index + 1} should not be empty.`
                }
                if (empty.includes('reject_type')) {
                    return `Action Taken in row ${index + 1} should not be empty.`
                }
                if (empty.includes('reject_code') && empty.includes('reject_message') && (row.reject_type === 'Hard Reject'
                    || row.reject_type === 'Soft Reject')) {
                    return `Reject Code and Reject Message in row ${index + 1} should not be empty.`
                }
                if ((empty.includes('reject_message') && (row.reject_type === 'Message'))) {
                    return `Reject Message in row ${index + 1} should not be empty.`
                }

            }).filter((data) => data && data !== '')
        } else {
            return `Condition Grid should not be empty.`
        }
    }
}

export const validateRestriction = {
    doc_name: ({ doc_name }) => isEmpty(doc_name) ? "Dur Name should not be empty." : "",
    dur_type: ({ dur_type }) => isEmpty(dur_type) ? "Dur Type should not be empty." : "",
    effective_date: ({ effective_date }) => !effective_date ? "Effective Date should not be empty." : "",
    is_global: ({ is_global }) => isEmpty(is_global) ? "Global should be either Yes or No." : "",
    hierarchy: ({ hierarchy, is_global }) => !is_global && isEmpty(hierarchy) ? "Hierarchy should not be empty or Select global Object to Yes" : "",
    conditions: ({ conditions }) => {
        if (conditions?.length) {
            return conditions.map((row, index) => {
                let columns = Object.keys(row)
                const empty = columns.filter(c => !row[c].toString())

                if (empty.includes('edit_type')) {
                    return `Edit Type in row ${index + 1} should not be empty.`
                }
                if (empty.includes('reject_type')) {
                    return `Action Taken in row ${index + 1} should not be empty.`
                }
                if (empty.includes('reject_code') && empty.includes('reject_message') && (row.reject_type === 'Hard Reject'
                    || row.reject_type === 'Soft Reject')) {
                    return `Reject Code and Reject Message in row ${index + 1} should not be empty.`
                }
                if ((empty.includes('reject_message') && (row.reject_type === 'Message'))) {
                    return `Reject Message in row ${index + 1} should not be empty.`
                }

            }).filter((data) => data && data !== '')
        } else {
            return `Condition Grid should not be empty.`
        }
    }
}

export const validateDuplicateTherapy = {
    doc_name: ({ doc_name }) => isEmpty(doc_name) ? "Dur Name should not be empty." : "",
    dur_type: ({ dur_type }) => isEmpty(dur_type) ? "Dur Type should not be empty." : "",
    effective_date: ({ effective_date }) => !effective_date ? "Effective Date should not be empty." : "",
    is_global: ({ is_global }) => isEmpty(is_global) ? "Global should be either Yes or No." : "",
    hierarchy: ({ hierarchy, is_global }) => !is_global && isEmpty(hierarchy) ? "Hierarchy should not be empty or Select global Object to Yes" : "",
    conditions: ({ conditions }) => {
        if (conditions?.length) {
            return conditions.map((row, index) => {
                let columns = Object.keys(row)
                const empty = columns.filter(c => !row[c].toString())
                if (empty.includes('medispan_duplicate_allowance_indicator')) {
                    return `Medispan Duplicate Allowance Indicator in row ${index + 1} should not be empty.`
                }
                if (empty.includes('significance_code')) {
                    return `Significance Code in row ${index + 1} should not be empty.`
                }
                if (empty.includes('reject_type')) {
                    return `Action Taken in row ${index + 1} should not be empty.`
                }
                if (empty.includes('reject_code') && empty.includes('reject_message') && (row.reject_type === 'Hard Reject'
                    || row.reject_type === 'Soft Reject')) {
                    return `Reject Code and Reject Message in row ${index + 1} should not be empty.`
                }
                if ((empty.includes('reject_message') && (row.reject_type === 'Message'))) {
                    return `Reject Message in row ${index + 1} should not be empty.`
                }

            }).filter((data) => data && data !== '')
        } else {
            return `Condition Grid should not be empty.`
        }
    }
}
export const validateOpioid = {
    doc_name: ({ doc_name }) => isEmpty(doc_name) ? "Dur Name should not be empty." : "",
    dur_type: ({ dur_type }) => isEmpty(dur_type) ? "Dur Type should not be empty." : "",
    effective_date: ({ effective_date }) => !effective_date ? "Effective Date should not be empty." : "",
    is_global: ({ is_global }) => isEmpty(is_global) ? "Global should be either Yes or No." : "",
    hierarchy: ({ hierarchy, is_global }) => !is_global && isEmpty(hierarchy) ? "Hierarchy should not be empty or Select global Object to Yes" : "",
    conditions: ({ conditions }) => {
        if (conditions?.length) {
            return conditions.map((row, index) => {
                let columns = Object.keys(row)
                const empty = columns.filter(c => !row[c].toString())
                if (empty.includes('mme_limit')) {
                    return `MME Limit in row ${index + 1} should not be empty.`
                }
                if (empty.includes('member_type')) {
                    return `Member Type in row ${index + 1} should not be empty.`
                }
                if (empty.includes('reject_type')) {
                    return `Action Taken in row ${index + 1} should not be empty.`
                }
                if (empty.includes('reject_code') && empty.includes('reject_message') && (row.reject_type === 'Hard Reject'
                    || row.reject_type === 'Soft Reject')) {
                    return `Reject Code and Reject Message in row ${index + 1} should not be empty.`
                }
                if ((empty.includes('reject_message') && (row.reject_type === 'Message'))) {
                    return `Reject Message in row ${index + 1} should not be empty.`
                }

            }).filter((data) => data && data !== '')
        } else {
            return `Condition Grid should not be empty.`
        }
    }
}
