import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"

import "./styles.scss"
import BreadCrumb from "../../../../components/breadCrumb"

import { Creators as PriorAuthorizationCreationActions } from "../../../../redux/reducers/api/priorAuthorizationManagement"
import { Creators as QuestionnaireBuilderActions } from '../../../../redux/reducers/api/questionnaireBuilder'
import { Creators as NavigationActions } from "../../../../redux/reducers/navigation"
import TableViewer from "../shared/TableViewer"
import FliptInput from "../../../../components/form/fliptInput"
import FliptDatePicker from "../../../../components/form/fliptDatePicker"
import FliptButton from '../../../../components/form/fliptButton'
import {
	convertDateTimeToDate,
	convertStrToDateObj,
	formatPhoneNumber,
	parseQueryString,
} from "../../../../utils/utilities"
import FliptDropdown from "../../../../components/form/fliptDropdown"
class ViewQuestionnaire extends Component {
	constructor(props) {
		super(props)
		this.state = {
			showQuestionnaireFileImport: false,
			questionnaireFile: null,
			faxFormFile: null,
			showTabFlag: true,
		}
	}
	componentDidMount() {
		const { actions } = this.props
		const params = {
			questionnaire_id: parseQueryString(this.props.location.search)
				.questionnaire_id,
			source: "FAST",
		}
		actions.getQuestionnaire(params)
		actions.clearQuestionnaireImportData()
	}

	_showQuestionnaireFileImport() {
		this.setState({ showQuestionnaireFileImport: true })
	}

	_setQuestionnaireFileOnUpload(e) {
		this.setState({ questionnaireFile: e.target.files[0] })
	}

	_setFaxFormFileOnUpload(e) {
		this.setState({ faxFormFile: e.target.files[0] })
	}
	
	_readQuestionnaireFileOnUpload() {
		const { actions } = this.props
		const { questionnaireFile, faxFormFile } = this.state
		actions.parseQuestionnaireFile({ questionnaireFile, faxFormFile })
	}

	_clearQuestionnaireImportData() {
		const { actions } = this.props
		actions.clearQuestionnaireImportData()
	}

	_submitQuestionnaireChanges() {
		const { actions, state: { questionnaireData: { questionnaire_id }, questionnaireImportData } } = this.props
		if (questionnaireImportData?.questionnaire) actions.editQuestionnaire({ ...questionnaireImportData, questionnaire_id })
		// update PA based on questionnaireImportData
	}

	render() {
		const { history, state, actions } = this.props
		const { questionnaireData, questionnaireImportData } = state
		const { showQuestionnaireFileImport, faxFormFile } = this.state

		const attachmentOptions = [
			{ key: 0, text: 'Yes', value: 'Y' },
			{ key: 1, text: 'No', value: 'N' }
		  ]
		  const questionnaireCategoryOptions = [
			{ key: 0, text: 'Prior Authorization', value: 'pa' },
			{ key: 1, text: 'Prior Authorization Default', value: 'pa_default' },
			{ key: 2, text: 'Quantity Limit', value: 'ql' },
			{ key: 3, text: 'Brand Medically Necessary', value: 'bmn' }
		  ]

		const continueButtonParams = {
			className: "secondary",
			id: "continueButton",
			name: "Continue",
			onClick: () => {
				this._submitQuestionnaireChanges()
				actions.navigateTo({pathname: "/questionnaire-builder" })
			}
		}

		return (
			<div id="questionnaireViewer">
				<BreadCrumb {...this.props} />
				<div className="header">Questionnaire</div>
				<div className="content">
					<div className="section">
						<section className="questionnaire-inputs-container spacing border-line shadow">
							<div className="questionnaire-inputs">
								<span>Questionnaire Type</span>
								<FliptInput
									disabled
									name="questionnaire_type"
									value={
										questionnaireData
											.questionnaire_type
									}
									placeholder="Questionnaire Type"
								/>
							</div>
							<div className="questionnaire-inputs">
								<span>Questionnaire Name</span>
								<FliptInput
									disabled
									name="questionnaire_name"
									value={
										questionnaireData
											.questionnaire_name
									}
									placeholder="Questionnaire Name"
								/>
							</div>
							<div className="questionnaire-inputs">
								<span>Questionnaire Description</span>
								<FliptInput
									disabled
									name="questionnaire_description"
									value={
										questionnaireData
											.questionnaire_description
									}
									placeholder="Questionnaire Description"
								/>
							</div>
							<div className="questionnaire-inputs">
								<span><a href={questionnaireData.questionnaire_policy}>Questionnaire Policy</a></span>
								<FliptInput
									disabled
									name="questionnaire_policy"
									value={questionnaireData.questionnaire_policy}
								/>
							</div>
							{/* {/* <div className="questionnaire-inputs">
								<span>Effective Date</span>
								<FliptDatePicker
									className="create-formulary-start-date"
									name="effective_date"
									value={convertStrToDateObj(
										questionnaireData.effective_date
									)}
									placeholder="Effective Date"
								/>
							</div> */}
							{/* <div className="questionnaire-inputs">
								<span>Term Date</span>
								<FliptDatePicker
									className="create-formulary-start-date"
									name="term_date"
									value={convertStrToDateObj(
										questionnaireData.term_date
									)}
									placeholder="Term Date"
								/>
							</div>  */}
							<div className="questionnaire-inputs">
								<span>Attachment Required</span>
								<FliptInput
									disabled
									name="attachment_required"
									value={questionnaireData.attachment_required}
								/>
							</div>
							<div className="questionnaire-inputs">
								<span>Questionnaire Category</span>
								<FliptInput
									disabled
									name="questionnaire_category"
									value={questionnaireData.questionnaire_category}
								/>
							</div>
							<div className="questionnaire-inputs">
								<span>Contact Communication Number</span>
								<FliptInput
									disabled
									maxlength="10"
									name="question_set_contact_communication_number"
									value={formatPhoneNumber(
										questionnaireData
											.question_set_contact_communication_number
									)}
									placeholder="Contact Communication Number"
								/>
							</div>
						</section>
					</div>
					<div className="section">
						<div className="reimportQuestionnaire">
							<FliptButton
								name="Re-Import Questionnaire"
								className="primary"
								onClick={() => this._showQuestionnaireFileImport()}
							/>
							{showQuestionnaireFileImport && 
								<section className="uploadInputContainer">
									<div className="uploadInput">
										<span className="element">Select data file in xlsx, csv, and xml format</span>
										<input type="file" name="file" id="file" onChange={(e) => this._setQuestionnaireFileOnUpload(e)} />
									</div>
									<div className="questionnaire-input">
                		<span>Fax Form</span>
                		<FliptInput type="file" name="fax_form" onChange={(e) => this._setFaxFormFileOnUpload(e)} placeholder="Fax Form" />
              		</div>
									<br/>
									<br/>
									<div className="buttonContainer">
										<FliptButton name="Upload" onClick={() => this._readQuestionnaireFileOnUpload()} className="primary searchButton" />
										<FliptButton name="Cancel" onClick={() => this._clearQuestionnaireImportData()} className="secondary searchButton" />
									</div>
								</section>
							}
						</div>

						<div className="outTableContainer">
							{questionnaireImportData?.questionnaire && questionnaireImportData?.fax_form &&
								<div>
									Imported Fax Form: {faxFormFile?.name}
								</div>
							}
							<TableViewer
								questionFinalData={
									questionnaireImportData?.questionnaire ?? (questionnaireData.questionnaire ?? [])
								}
								drugsFinalData={
									questionnaireImportData?.drugs ?? (questionnaireData.drugs ?? [])
								}
								continueButtonParams={continueButtonParams}
							/>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	state: {
		questionnaireData: state.priorAuthorizationCreation.questionnaireData,
		questionnaireImportData: state?.questionnaireBuilder?.questionnaireImportData,
	},
})

const mapDispatchToProps = (dispatch) => {
	const allActions = {
		...PriorAuthorizationCreationActions,
		...QuestionnaireBuilderActions,
		...NavigationActions,
	}

	return {
		actions: bindActionCreators(allActions, dispatch),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewQuestionnaire)
