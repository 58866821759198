import React from 'react'
import { connect } from 'react-redux'

import './styles.scss'

import FliptDropdown from '../form/fliptDropdown'
import { getCAGOptions } from '../../utils/utilities'

const CAGFilters = (props) => {
  const {
    className,
    state,
    selectedCarrier,
    selectedAccount,
    selectedGroup,
    onFilterChange,
    setFilterOptions,
    questionnaire,
    isUpdatedHierarchy,
    addAllOptions,
  } = props
  const { carriers } = state
  const filterOptions = getCAGOptions(carriers, selectedCarrier, selectedAccount, null, isUpdatedHierarchy, addAllOptions)
  setFilterOptions && setFilterOptions(filterOptions)
  return (
    <div className={className ?? 'CAGFilters'} id={questionnaire ? "QuestionnaireCAGFilters" : "CAGFilters"}>
      <FliptDropdown placeholder="Select Carrier" label="Carrier" name="carrier" value={isUpdatedHierarchy ? selectedCarrier?.value : selectedCarrier} options={filterOptions.carrierOptions} onChange={onFilterChange} stylized />
      <FliptDropdown placeholder="Select Account" label="Account" name="account" value={isUpdatedHierarchy ? selectedAccount?.value : selectedAccount} options={filterOptions.accountOptions} onChange={onFilterChange} stylized />
      <FliptDropdown placeholder="Select Group" label="Group" name="group" value={isUpdatedHierarchy ? selectedGroup?.value : selectedGroup} options={filterOptions.groupOptions} onChange={onFilterChange} stylized />
    </div>
  )
}

const mapStateToProps = (state) => ({
  state: {
    carriers: state?.user?.carriers && state?.user?.carriers?.length > 0 ? state.user.carriers : [],
  },
})

export default connect(mapStateToProps)(CAGFilters)
