import Immutable from 'seamless-immutable'
import { createActions, createReducer } from 'reduxsauce'

const companyData = {
  id: '',
  address1: '',
  address2: '',
  admin_contacts: [],
  business_type: [],
  city: '',
  company_code: '',
  company_full_name: '',
  company_phone_number: '',
  effective_end_date: '',
  effective_start_date: '',
  line_of_business: [],
  state: '',
  zip: '',
}

export const INITIAL_STATE = Immutable({
  companyData,
  companyListing: [companyData],
})

export const clearReducer = () => INITIAL_STATE

export const clearCompanyDataReducer = () => INITIAL_STATE

export const setCompanyListDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  companyListing: payload,
})

export const setCompanyDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  companyData: { ...payload },
})

export const { Types, Creators } = createActions({
  clearCompanyData: null,
  clearReducer: null,
  createCompanyManagementData: ['payload'],
  editCompanyManagementData: ['payload'],
  getCompanyData: ['payload'],
  getCompanyListData: null,
  setCompanyData: ['payload'],
  setCompanyListData: ['payload'],
  editCompanyHierarchyData: ['payload'],
})

const HANDLERS = {
  [Types.CLEAR_COMPANY_DATA]: clearCompanyDataReducer,
  [Types.CLEAR_REDUCER]: clearReducer,
  [Types.SET_COMPANY_DATA]: setCompanyDataReducer,
  [Types.SET_COMPANY_LIST_DATA]: setCompanyListDataReducer,
}

export default createReducer(INITIAL_STATE, HANDLERS)
