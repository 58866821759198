import "./styles.scss";

import React from "react";
import { COLUMN_DIVIDER } from '../Constant'
import { Creators as ClientConfigurationActions } from "../../../../redux/reducers/api/clientConfiguration";
import { RequiredAttributeFields } from '../Constant/requiredFields'
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { renderDynamicInput, findSelectedFields } from '../utils'

const RequiredTab = (props) => {
  const { title } = props;
  const { singleLevelAttribute, nodeType, addMode } = props.state;
  const validations = props.state.validations;
  const validationResults = props.state.validationResults;
  const showErrors = validationResults && validationResults && !validationResults.isAllValid;
  const _updateFields = (el, dropdown) => {
    let { name, value } = dropdown || el.currentTarget;
    const stripSpace = ['organization_custom_id', 'client_custom_id', 'carrier_custom_id', 'account_custom_id', 'group_custom_id']

    if (stripSpace.includes(name)) value = value.replace(/\s/g, '')

    if (name === 'zip') {
      const sliceValue = 5
      if (value?.length > sliceValue && !value?.includes('-')) {
        value = value.slice(0, sliceValue) + '-' + value.slice(sliceValue);
      }
    }
    props.actions.updateAttributeFields({ name, value });
  };

  const renderAdditionalFields = (fieldList) => {
    if (fieldList?.length == 0) return null;
    if (Object.keys(singleLevelAttribute).length == 0) return null;
    const selectedFieldsArray = findSelectedFields(fieldList, singleLevelAttribute);
    const selectedFieldsArrayGroupedBySections = selectedFieldsArray.reduce(function (m, it, i) {
      let subgroup = it["subgroup"] || "";
      m[subgroup] || (m[subgroup] = []);
      m[subgroup].push(it)
      return m
    }, {});
    //convert to a list of tuples sorted by the min order in each group
    const selectedTuples = Object.entries(selectedFieldsArrayGroupedBySections)
      .map(([subgroup, items]) => ({
        subgroup,
        items: items.sort((a, b) => a.order - b.order),
      }))
      .sort((a, b) => a.items[0].order - b.items[0].order)
    const rowDivider = COLUMN_DIVIDER;
    const fieldProps = {
      ...singleLevelAttribute,
      addMode,
      className: 'fields-client-header',
      nodeType,
      onChange: _updateFields,
      showErrors,
      validationResults,
      validations,
    };

    return (
      <>
        {/* use array method to Calculate rows dynamically acc to ui layout */}
        {selectedTuples.map(function (tuple) {
          return (
            <fieldset>
              <legend>{tuple.subgroup}</legend>
              {Array.from(
                { length: Math.ceil(tuple.items.length / rowDivider) },
                (_, i) => (
                  <div className="fields-container" key={`row${i}`}>
                    {tuple.items
                      .slice(i * rowDivider, (i + 1) * rowDivider)
                      .map((item) => renderDynamicInput(item, fieldProps))}
                  </div>
                )
              )}
            </fieldset>
          );
        })}
      </>
    );
  };

  return (
    <div id="hierarchConfigForm">
      <div id="header">
        <div className="section-header">{title}</div>
      </div>
      <div id="form">
        {renderAdditionalFields(RequiredAttributeFields)}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    state: {
      addMode: state.clientConfiguration.addMode,
      companyTab: state.clientConfiguration.companyTab,
      nodeType: state.clientConfiguration.nodeType,
      singleLevelAttribute: state.clientConfiguration.singleLevelAttribute,
      validationResults: state.clientConfiguration.validationResults.requiredTab,
      validations: state.clientConfiguration.validations.requiredTab,
    },
  };
};

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...ClientConfigurationActions,
  };

  return {
    actions: bindActionCreators(allActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RequiredTab);
