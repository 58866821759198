import React, { useState } from 'react'
import { connect } from 'react-redux'
import FliptDropdown from '../../../../../components/v2/fliptDropdown'
import _ from 'lodash'
import './styles.scss'
import FliptInput from '../../../../../components/v2/fliptInput'
import FliptButton from '../../../../../components/v2/fliptButton'
import FliptDatePicker from '../../../../../components/v2/fliptDatePicker'
import { Creators as AppActions } from '../../../../../redux/reducers/app'
import { memberFields } from '../data/uiFields'
import { bindActionCreators } from 'redux'



function EligibilityConfig(props) {
    const { memberInfo, actions } = props
    const flatMemberFields = memberFields.flat()

    const initiateState = () => {
        const fieldObj = {}
        flatMemberFields.map((fieldData) => {
            fieldObj[fieldData.name] = memberInfo[fieldData.name]
        })
        return fieldObj
    }

    const [form, setForm] = useState(initiateState())
    const [updateButton, enableUpdateButton] = useState(false)

    const onChange = (el, dropdown) => {
        const { name, value } = dropdown || el.currentTarget
        const newForm = {
            ...form,
            [name]: value,
        }
        setForm(newForm)
        enableUpdateButton(true)
    }

    const updateMember = () => {
        actions.toggleModal()
    }

    const renderFields = (data) => {
        switch (data.type) {
            case 'input': {
                return <FliptInput label={data.label} name={data.name}
                    value={form[data.name]} disabled={data.disabled} onChange={onChange} />
            }
            case 'dropdown': {
                return <FliptDropdown label={data.label} options={data.options} name={data.name}
                    value={form[data.name]} className='member-dropdown' disabled={data.disabled} onChange={onChange} />
            }
            case 'date': {
                return <FliptDatePicker className='datepicker' label={data.label} name={data.name} format='MM-DD-YYYY'
                    value={form[data.name]} onChange={onChange} />
            }
        }
    }


    return (
        <div id="eligibilityConfig">
            <h2>Member Builder</h2>
            <div className='field-outer-container'>
                {memberFields.map((fieldsData) => <div>
                    {
                        renderFields(fieldsData)
                    }
                </div>)}
            </div>
            <FliptButton className="primary" disabled={!updateButton} name='Update Member' onClick={updateMember} />
        </div>
    )
}

const mapStateToProps = (state) => {
    return ({
        state: {
            app: state.app,
        },
    })
}
const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...AppActions,
    }

    return {
        actions: bindActionCreators(allActions, dispatch),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EligibilityConfig)