
import FliptInput from '../../../../components/form/fliptInput'

const ParentInfo = (props) => {
  const { memberData, personId } = props

  return (
    <div className="section">
      <div className="section-header fields-container">
        <h3>Parent Information</h3>
      </div>
      <div className="fields-container">
        <div className="fields-header">
          <FliptInput value={memberData && personId} stylized disabled name="flipt_parent_id" label="Flipt Parent Id" />
          <FliptInput value={memberData?.domain_name} stylized disabled name="account" label="Account" />
          <FliptInput value={memberData?.first_name} stylized disabled name="first_name" label="Parent First Name" />
          <FliptInput value={memberData?.last_name} stylized disabled name="last_name" label="Parent Last Name" />
        </div>
      </div>
      <div className="fields-container">
        <div className="fields-header">
          <FliptInput value={memberData?.group} stylized disabled name="group" label="Group" />
        </div>
      </div>
    </div>
  )
}

export default ParentInfo;


