export const discountType = [
  {
    key: 'Fixed',
    text: 'Fixed',
    value: 'FIXED',
  },
  {
    key: 'Percentage',
    text: 'Percentage',
    value: 'PERCENTAGE',
  },
]
export const priceType = [
  {
    key: 'MAC',
    text: 'MAC',
    value: 'MAC',
  },
  {
    key: 'AWP',
    text: 'AWP',
    value: 'AWP',
  },
  {
    key: 'NADAC',
    text: 'NADAC',
    value: 'NADAC',
  },
]

export const feeNames = [
  {
    key: 'Processing fee',
    text: 'Processing fee',
    value: 'PROCESSING',
  },
  {
    key: 'Admin fee',
    text: 'Admin fee',
    value: 'ADMIN',
  },
  {
    key: 'Stock fee',
    text: 'Stock fee',
    value: 'STOCK',
  },
  {
    key: 'Dispensing fee',
    text: 'Dispensing Fee',
    value: 'DISPENSING',
  },
]

export const rateType = [
  {
    key: 'Negative',
    text: 'Negative',
    value: 'NEGATIVE',
  },
  {
    key: 'Positive',
    text: 'Positive',
    value: 'POSITIVE',
  },
]
