import { dayType, weekDaysType } from "./dataTypes";

export default {
    day_type: {
        type: 'dropdown',
        disabled: false,
        options: dayType,
    },
    begin_day: {
        type: 'dropdown',
        disabled: false,
        options: weekDaysType,
    },
    end_day: {
        type: 'dropdown',
        disabled: false,
        options: weekDaysType,
    },
    begin_time: {
        type: 'input',
        disabled: false,
    },
    end_time: {
        type: 'input',
        disabled: false,
    }
}