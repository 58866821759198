import _ from "lodash"
import {
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects'
export const formularyCreation = (state) => (state.formularyCreation)
export const getAllData = (state) => state.clientConfiguration.AllData
export const getAllQualifierModels = (state) => (state.qualifiers.qualifierModels)
export const getApprovalDocID = (state) => (state.planManagement.approvalDocID)
export const getApprovedTiersData = (state) => (state.formularyCreation.allApprovedTiersData)
export const getAppState = (state) => (state.app)
export const getClaimErrorLookup = (state) => (state.claimErrorLookup)
export const getFormularyExportDrugInfo = (state) => (state.formularyExportData)
export const getClaimHistory = (state) => (state.claimHistory)
export const getClaimLookup = (state) => (state.claimLookup)
export const getClientConfiguration = (state) => (state.clientConfiguration)
export const getClientConfigurationTab = (state) => (state.clientConfiguration.defaultTabName)
export const getClientImplementation = (state) => (state.clientImplementation)
export const getCurrentSection = (state) => (state.planManagement.currentSection)
export const getDefaultGroupData = (state) => (state.groupManagement.defaultGroupData)
export const getDrugMapLookUp = (state) => (state.drugMapLookup)
export const getDrugPrices = (state) => (state.drugPrices)
export const getEmergencyOverride = (state) => (state.emergencyOverride)
export const getFileConfiguration = (state) => (state.fileConfiguration)
export const getFileConfigurationStep = (state) => (state.fileConfiguration.step)
export const getFormularyLookup = (state) => (state.formularyLookup)
export const getGlobalEdits = (state) => (state.globalEdits)
export const getGroupManagement = (state) => (state.groupManagement)
export const getHistory = (state) => (state.navigation.history)
export const getIntegrationMgmtState = (state) => (state.integrationMgmt)
export const getMedicareSetupData = (state) => (state.medicareSetup)
export const getMedicareStandardData = (state) => (state.medicareStandard)
export const getMemberDetails = (state) => (state.memberLookup.member_details)
export const getNetworkTierCreation = (state) => (state.networkTierCreation)
export const getNodeType = (state) => (state.clientConfiguration.nodeType)
export const getPaoverrideLookup = (state) => (state.paoverridereportLookup)
export const getPharmacyLookup = (state) => (state.pharmacyLookup)
export const getPlanDetails = (state) => (state.planManagement.planDetails)
export const getPlanDetailsFilters = (state) => (state.planManagement.planDetailsFilters)
export const getPlanHierarchyData = (state) => (state.planManagement.hierarchyData)
export const getPlanID = (state) => (state.planManagement.planID)
export const getPlanLOB = (state) => (state.planManagement.planLOB)
export const getPlanName = (state) => (state.planManagement.planName)
export const getPlanStatus = (state) => (state.planManagement.planStatus)
export const getPlanVersion = (state) => (state.planManagement.planVersion)
export const getPrescriberLookup = (state) => (state.prescriberLookup)
export const getPrimaryMemberDetails = (state) => (state.memberLookup.primary_member_details)
export const getPriorAuthorizationData = (state) => (state.priorAuthorizationCreation)
export const getQualifiersState = (state) => (state.qualifiers)
export const getRxreportLookup = (state) => (state.rxreportLookup)
export const getSurescriptConfigLookup = (state) => (state.surescriptconfig)
export const getUserManagementData = (state) => (state.userManagement.data)
export const getUserPermissions = (state) => (state.user.permissions)
export const getUserState = (state) => (state.user)
export const getApiPath = (state, apiId) => {
  const { menu_data } = state.app
  const apiData = _findApiData(menu_data, apiId)
  if (!apiData?.api_path) {
    throw Error('Permission Denied')
  }
  return apiData
}

export const isMenuEmpty = (state) => {
  const { menu_data } = state.app
  return _.isEmpty(menu_data)
}

export const isUserEmpty = (state) => {
  const { user } = state
  return _.isEmpty(user.permissions)
}

function _findApiData(data, apiId) {
  return data.map((d) => {
    // console.log(`findApiData ${d}, ${apiId}`)
    if (d.id === apiId) {
      return d
    }
    if (d.children && d.children.length) {
      // console.log(`Inside Children ${d}, ${apiId}`)
      return _findApiData(d.children, apiId)
    }

    return false
  }).filter((d) => !!d).flat()[0]
}
