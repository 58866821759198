import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { cloneDeep } from "lodash";

// import ProgramDrugs from './programDrugs/index'
import "./styles.scss";
import BreadCrumb from "../../../../components/breadCrumb";
import FliptButton from '../../../../components/form/fliptButton';
import ApprovalOptions from "../../../../components/approvalOptions";
import Config from "../../../../config";
import ProgramConditions from "./programConditions/index";
import SaveTierModal from "./saveTierModal";
import _ from "lodash";
import drugDemographicData from "../../../../config/constants/clinical/drugDemographic";
import drugMappingData from "../../../../config/constants/clinical/drugMapping";
import rbpQualifier from "./data/rbpQualifier";
import opioidQualifier from "./data/opioidProgramsQualifier";
import { Creators as AppActions } from "../../../../redux/reducers/app";
import { Creators as AutoSuggestActions } from "../../../../redux/reducers/api/autoSuggest";
import { Creators as ProgramListCreationActions } from "../../../../redux/reducers/api/programListManagement";
import { Creators as QualifierActions } from "../../../../redux/reducers/api/qualifiers";
import { Creators as RPMActions } from "../../../../redux/reducers/rpm";
import { drugGroupValues } from "../../../../config/constants/clinical/drugGroups";
import {
  convertDateToStartTimeObj,
  convertDateToEndTimeObj,
  parseQueryString,
  downloadCSV
} from "../../../../utils/utilities";
import { customProgramTypeCodes } from "../../../../config/constants/clinical/drugGroups";
import { qualifierType, qualifierOperator } from "./data/qualifier";
import { deleteUnnecessaryFields } from "../../../../utils/utilizationManagement";

class CreateProgramList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        doc_name: "",
        effective_start_date: "",
        list_type: "",
        custom_program_type: "",
        include_inactive_drugs: "Y",
        doc_id: props.state?.priorAuthorizationData?.doc_id || "",
        module: "PROGRAM_LIST",
        version: "1.0",
        version_effective_date: "",
        status: "",
        hierarchy: [],
        is_hierarchy_global: false,
        hideHierarchy: false,
        target_system_formulary_tier: '',
        target_system_formulary_status: '',
      },
      activeProgramConditionLevel: 0,
      programConditionLevels: [[{
        condition_id: "",
        include: true,
        // include_in_grid: true,
        gpi: '',
        medicare_type: '',
        drug_group: '',
        drug_class: '',
        drug_subclass: '',
        manufacturer: '',
        drug_name: '',
        multi_source: '',
        ddid: '',
        item_status_flag: "",
        brand_generic: '',
        otc_indicator: '',
        desi_code: '',
        ndc: '',
        action: '',
        route_of_administration: '',
        maintenance_drug_code: '',
        effective_start_date: '',
        effective_end_date: '',
        alternate_gpi_match: '',
        alternate_ndc_match: '',
      }]],
      programQualifierLevels: [[{
        program_qualifier: '',
        program_operator: '',
        program_value: '',
        action: '',
      }]],
      rbpProgramQualifierLevels: [cloneDeep(rbpQualifier)],
      opioidProgramQualifierLevels: [cloneDeep(opioidQualifier)],
      showQualifier: [false],
      dropdownValues: [
        [
          {
            drug_group: drugGroupValues,
            drug_class: [],
            drug_subclass: [],
          },
        ],
      ],
      autoSuggestionValues: [
        [
          {
            manufacturer: { values: [], loading: false },
            drug_name: { values: [], loading: false },
          },
        ],
      ],
      listTypeValues: ["Include", "Exclude", 'Clinical Program'],
      rbp_price_list: ["REFERENCE BASED PRICING LIST", "OPIOID PROGRAM"],
      rowUpdated: null,
      columnUpdated: null,
      levelUpdated: null,
      autoSuggestionMinChars: 3,
      cellRenderers: {
        condition_id: {
          hide: true,
          overrideHeader: "Condition Id"
        },
        gpi: {
          overrideHeader: "GPI",
          hide: false,
        },
        medicare_type: {
          hide: true,
          overrideHeader: "Medicare Type Code",
        },
        manufacturer: {
          hide: true,
        },
        multi_source: {
          overrideHeader: "Multi-Source Code",
          hide: false,
        },
        ddid: {
          hide: true,
          overrideHeader: "DDID",
        },
        brand_generic: {
          hide: true,
          overrideHeader: "Brand/Generic",
        },
        otc_indicator: {
          hide: true,
          overrideHeader: "OTC Indicator",
        },
        desi_code: {
          hide: true,
        },
        route_of_administration: {
          hide: true,
        },
        ndc: {
          hide: true,
        },
        item_status_flag: {
          hide: true,
          overrideHeader: "Inactive Indicator"
        },
        maintenance_drug_code: {
          hide: true,
          overrideHeader: "Maintenance Drug Indicator",
        },
        alternate_gpi_match: {
          overrideHeader: 'Alternate GPI Match',
          hide: true
        },
        alternate_ndc_match: {
          overrideHeader: 'Alternate NDC Match',
          hide: true
        },
        application_type_flag: {
          hide: true,
          overrideHeader: 'App Type',
        },
        mfg_labeler_id: {
          hide: true,
          overrideHeader: 'Labler Code',
        },
        repackaged_code: {
          hide: true,
          overrideHeader: 'Repackaging Indicator',
        },
        third_party_restriction_code: {
          hide: true,
          overrideHeader: 'TPR Code',
        },
        dosage_form_cd: {
          hide: true,
          overrideHeader: 'Dosage Form Code',
        },
        clinic_pack_code: {
          hide: true,
          overrideHeader: 'Clinic Pack',
        },
        innerpack_code: {
          hide: true,
          overrideHeader: 'Inner Pack',
        },
        unit_dose: {
          hide: true,
          overrideHeader: 'Unit Dose',
        },
        dea_class_code: {
          hide: true,
          overrideHeader: 'DEA Class',
        },
        fda_therapeutic_equivalence_code: {
          hide: true,
          overrideHeader: 'FDA Equivalence',
        },
        marketing_category: {
          hide: true,
          overrideHeader: 'Marketing Category',
        },
        storage_condition_code: {
          hide: true,
          overrideHeader: 'Storage Condition',
        },
        unit_dose_with_non_unit_dose_non_repackager_in_gpi: {
          hide: true,
          overrideHeader: 'UD With Non UD RC In GPI',
        },
        rx_with_otc_in_gpi: {
          hide: true,
          overrideHeader: 'Rx With OTC In GPI',
        },
        member_notes: {
          hide: true,
        },
        agent_notes: {
          hide: true,
        },
        internal_notes: {
          hide: true,
        },
        claim_message_code: {
          hide: true,
        },
        claim_message_type: {
          hide: true,
        },
        target_system_formulary_tier: {
          hide: true,
        },
        target_system_formulary_status: {
          hide: true
        },
      },
      qualifierDropdownValues: [
        [
          {
            program_qualifier: qualifierType,
            program_operator: [],
          },
        ],
      ],
    };
  }

  sanitizeString(text) {
    // Keep only alphanumeric characters and spaces, convert to uppercase
    const sanitizedText = text.replace(/[^a-zA-Z0-9 ]/g, '-').toUpperCase();

    // Replace spaces with underscores
    return sanitizedText.replace(/ /g, '-');
  }

  generateConditionId(module, name, version, level, rowNumber) {
    return `${this.sanitizeString(module)}:::${this.sanitizeString(name)}:::${version}:::L${level.toString().padStart(5, '0')}:::${rowNumber.toString().padStart(7, '0')}`
  }

  getQualifierLevels(custom_program_type) {
    const {
      rbp_price_list,
      rbpProgramQualifierLevels,
      opioidProgramQualifierLevels,
    } = this.state;
    if (rbp_price_list[0] === custom_program_type) {
      return rbpProgramQualifierLevels;
    }
    return opioidProgramQualifierLevels;
  }

  clearFormData() {
    this.setState({
      form: {
        doc_name: "",
        effective_start_date: "",
        version_effective_date: "",
        list_type: "",
        include_inactive_drugs: "Y",
        custom_program_type: "",
        doc_id: "",
        module: "PROGRAM_LIST",
        version: "1.0",
        status: "DRAFT",
      },
    });
  }

  componentDidMount() {
    const { actions, history } = this.props
    const { search, state } = history.location
    actions.clearProgramList()
    actions.clearQualifiers()

    if (!search) {
      // user is creating a new document
      this.clearFormData()
      actions.rpmGetMf2ValData()
      this.setState({
        showQualifier: [true]
      })
      return
    }

    if (search === "?import") {
      const newProgramConditionLevels = state.programConditionLevels.map((conditionLevel) => {
        const newConditionLevel = conditionLevel.map((row, index) => {
          if ((row?.condition_id || '' === '') || (row?.condition_id === undefined)) {
            let new_index = index + 1
            row.condition_id = this.generateConditionId('PROGRAM', state?.form?.doc_name || "", state?.form?.version || "1.0", 1, new_index)
          }
          if (row?.include_in_grid !== true) {
            row.include_in_grid = true;
          }
          return row
        })
        return newConditionLevel
      })
      this.setState({
        programConditionLevels: newProgramConditionLevels,
        showQualifier: state.showQualifier,
        form: state?.form,
      });
      return;
    }

    const payload = parseQueryString(search)
    setTimeout(
      () => {
        actions.getProgramListData(payload)
        actions.rpmGetMf2ValData()
      },
      Config.COMPONENT_DATA_LOAD_TIME
    );
  }

  componentDidUpdate(prevProps) {
    const { props } = this;
    const prevState = prevProps.state;
    const currState = props.state;

    if (prevState === currState) return;
    if (!_.isEqual(prevState.autoSuggestData, currState.autoSuggestData))
      this._updateAutoSuggestions();
    const hasConditionsChanged =
      prevState.programListCondData.length !==
      currState.programListCondData.length &&
      !!currState.programListCondData.length;
    const hasProgramDataChanged =
      prevState.programListData !== currState.programListData;
    if (hasConditionsChanged) {
      this._updateState();
    } else if (hasProgramDataChanged && currState?.programListData?.doc_id) {
      const { programListData } = currState;
      // following same practice as _updateState function (not spreading the programListData)
      const form = {
        ...this.state.form,
        doc_id: programListData.doc_id,
        doc_name: programListData.doc_name,
        effective_start_date: programListData.effective_start_date,
        version_effective_date: programListData.version_effective_date,
        list_type: programListData.list_type,
        custom_program_type: programListData.custom_program_type,
        hierarchy: programListData.hierarchy,
        is_hierarchy_global: programListData.is_hierarchy_global,
        hideHierarchy: programListData.hideHierarchy,
        version: programListData.version,
        status: programListData.status,
      };
      this.setState({
        ...this.state,
        form,
      });
    }
  }

  componentWillUnmount() {
    const { clearQualifiers } = this.props.actions
    clearQualifiers()
  }

  // eslint-disable-next-line react/destructuring-assignment
  _constructDropdownOptions = () =>
    this.state.dropdownValues.map((dropdownValueLevel) =>
      dropdownValueLevel.map((row) => this._mapValueToOption(row))
    );

  _updateFields = (el, dateData) => {
    const { name, value } = dateData || el.currentTarget;

    if (['effective_start_date', 'target_system_formulary_tier', 'target_system_formulary_status'].includes(name)) {
      this.setState((prevState) => {
        let updatedProgramConditionLevels =
          prevState.programConditionLevels.map((level) => {
            return level.map((condition) => {
              return {
                ...condition,
                [name]: value,
              };
            });
          });

        // in current behavior, FliptDatePicker/SemanticDatepicker fires onChange event on first render
        // this trigger this function (_updateFields) and causes effective_start_date for the whole grid to
        // change without any use action. below is the fix for that - we only change grid date if effective_start_date
        // is a date object. please consider this behavior before making any changes.
        if (name === 'effective_start_date' && typeof value !== typeof prevState.form.effective_start_date) {
          updatedProgramConditionLevels = prevState.programConditionLevels
        }
        return {
          ...prevState,
          form: {
            ...prevState.form,
            [name]: value,
          },
          programConditionLevels: updatedProgramConditionLevels,
        };
      });
    }
    else if (name === "custom_program_type" && value === "ALTERNATIVES") {

      this.setState((prevState) => ({
        ...prevState,
        form: {
          ...prevState.form,
          [name]: value,
        },
        cellRenderers: {
          ...prevState,
          alternate_gpi_match: {
            alternate_gpi_match: prevState.cellRenderers.alternate_gpi_match,
            hide: false,
          },
          alternate_ndc_match: {
            alternate_ndc_match: prevState.cellRenderers.alternate_ndc_match,
            hide: false,
          },
        }
      }));
    }
    else if (name === "custom_program_type") {
      this.setState((prevState) => ({
        ...prevState,
        form: {
          ...prevState.form,
          [name]: value,
        },
        cellRenderers: {
          ...prevState,
          alternate_gpi_match: {
            alternate_gpi_match: prevState.cellRenderers.alternate_gpi_match,
            hide: true,
          },
          alternate_ndc_match: {
            alternate_ndc_match: prevState.cellRenderers.alternate_ndc_match,
            hide: true,
          },
        }
      }));
    }
    else {
      this.setState((prevState) => {

        let updatedProgramConditionLevels = prevState.programConditionLevels
        if ((name === 'doc_name')) {
          updatedProgramConditionLevels = updatedProgramConditionLevels.map(
            (level, level_index) => {
              return level.map((condition, condition_index) => {
                return {
                  ...condition,
                  condition_id: this.generateConditionId('PROGRAM', value, prevState?.form?.version || "1.0", level_index + 1, condition_index + 1),
                }
              })
            }
          )
        }
        if ((name === 'version')) {
          updatedProgramConditionLevels = updatedProgramConditionLevels.map(
            (level, level_index) => {
              return level.map((condition, condition_index) => {
                return {
                  ...condition,
                  condition_id: this.generateConditionId('PROGRAM', prevState?.form?.doc_name || "", value, level_index + 1, condition_index + 1),
                }
              })
            }
          )
        }

        return {
          ...prevState,
          form: {
            ...prevState.form,
            [name]: value,
          },
          programConditionLevels: updatedProgramConditionLevels
        }
      });
    }
  };
  setForm = (newForm) => {
    this.setState((prevState) => ({
      ...prevState,
      form: newForm,
    }));
  };

  _handleChange = (
    el,
    dropdown,
    rowIndex,
    gridApi,
    programConditionLevel,
    setAutoSuggestionValues
  ) => {
    const { props, state } = this;
    const { actions } = props;
    const programConditionLevels = [...state.programConditionLevels];
    const dropdownValues = [...state.dropdownValues];
    const autoSuggestionValues = [...state.autoSuggestionValues];
    const rowToUpdate = programConditionLevels[programConditionLevel][rowIndex];
    const dropDownRowToUpdate = dropdownValues[programConditionLevel][rowIndex];
    const { name, value } = dropdown || el.currentTarget;
    rowToUpdate[name] = value;

    if ((state?.form?.status || "DRAFT") === "PUBLISHED") {
      let condition_grid_version = (parseInt(state.form.version) + 1).toString() + ".0"
      rowToUpdate["condition_id"] = this.generateConditionId("PROGRAM", state.form.doc_name, condition_grid_version, programConditionLevel + 1, rowIndex + 1)
    } else {
      rowToUpdate["condition_id"] = this.generateConditionId("PROGRAM", state.form.doc_name, state.form.version, programConditionLevel + 1, rowIndex + 1)
    }

    if (
      state.rowUpdated != rowIndex ||
      state.levelUpdated != programConditionLevel
    )
      actions.clearAutoSuggestData();

    switch (name) {
      case "gpi": {
        this._onGpiChange(value, rowToUpdate, dropDownRowToUpdate);
        break;
      }
      case "drug_group":
        this._onDrugGroupChange(rowToUpdate, dropDownRowToUpdate);
        break;
      case "drug_class":
        this._onDrugClassChange(rowToUpdate, dropDownRowToUpdate);
        break;
      case "drug_subclass":
        break;
      case "manufacturer":
        if (value.length >= state.autoSuggestionMinChars) {
          autoSuggestionValues[programConditionLevel][
            rowIndex
          ].manufacturer.loading = true;
          actions.getAutoSuggestData({
            search_string: value,
            search_in: "fts_ndc_drugs",
            search_for: "mfg",
          });
          this.setAutoSuggestionValues = setAutoSuggestionValues;
        }
        break;
      case "drug_name":
        if (value.length >= state.autoSuggestionMinChars) {
          autoSuggestionValues[programConditionLevel][
            rowIndex
          ].drug_name.loading = true;
          actions.getAutoSuggestData({
            search_string: value,
            search_in: "fts_ndc_drugs",
            search_for: "drug_name",
          });
          this.setAutoSuggestionValues = setAutoSuggestionValues;
        }
        break;
      default:
        break;
    }
    programConditionLevels[programConditionLevel][rowIndex] = rowToUpdate;
    dropdownValues[programConditionLevel][rowIndex] = dropDownRowToUpdate;

    const activeColumns = gridApi.columnModel.columnApi
      .getAllGridColumns()
      .filter((col) => {
        if (col.visible) return col.colId;
      });
    let cellRenderers = { ...state.cellRenderers };
    const getColumn = Object.keys(cellRenderers);

    activeColumns.forEach((col) => {
      if (
        getColumn.includes(col.colId) &&
        col.colId != "action" &&
        col.colId != "include"
      ) {
        cellRenderers[col.colId]["hide"] = false;
      }
    });

    this.setState({
      programConditionLevels,
      dropdownValues,
      rowUpdated: rowIndex,
      columnUpdated: name,
      levelUpdated: programConditionLevel,
      cellRenderers: cellRenderers,
    });

    gridApi.refreshCells();
  };


  _refreshDrugDemographicDropdowns = (rowToUpdate, dropDownRowToUpdate) => {
    rowToUpdate.drug_group = "";
    rowToUpdate.drug_class = "";
    rowToUpdate.drug_subclass = "";
    dropDownRowToUpdate.drug_group = drugGroupValues;
    dropDownRowToUpdate.drug_class = [];
    dropDownRowToUpdate.drug_subclass = [];
  };

  _onGpiChange = (value, rowToUpdate, dropDownRowToUpdate) => {
    const gpiCode = value;
    const gpiLen = gpiCode.length;

    if (gpiLen === 0) {
      this._refreshDrugDemographicDropdowns(rowToUpdate, dropDownRowToUpdate);
      return;
    }

    const drugGroupCode = gpiCode.slice(0, 2);
    const drugClassCode = gpiCode.slice(2, 4);
    const drugSubClassCode = gpiCode.slice(4, 6);
    this._selectDrugGroup(drugGroupCode, rowToUpdate, dropDownRowToUpdate);
    this._selectDrugClass(
      drugGroupCode,
      drugClassCode,
      rowToUpdate,
      dropDownRowToUpdate
    );
    this._selectDrugSubclass(
      drugGroupCode,
      drugClassCode,
      drugSubClassCode,
      rowToUpdate
    );
  };

  _selectDrugGroup = (gpiCode, rowToUpdate, dropDownRowToUpdate) => {
    if (gpiCode in drugMappingData) {
      rowToUpdate.drug_group = drugMappingData[gpiCode].drug_group;
      this._onDrugGroupChange(rowToUpdate, dropDownRowToUpdate);
    }
  };

  _selectDrugClass = (
    drugGroupCode,
    drugClassCode,
    rowToUpdate,
    dropDownRowToUpdate
  ) => {
    const validDrugClassCode =
      drugGroupCode in drugMappingData &&
      drugClassCode in drugMappingData[drugGroupCode].drug_classes;
    if (validDrugClassCode) {
      rowToUpdate.drug_class =
        drugMappingData[drugGroupCode].drug_classes[drugClassCode].drug_class;
      this._onDrugClassChange(rowToUpdate, dropDownRowToUpdate);
    }
  };

  _selectDrugSubclass = (
    drugGroupCode,
    drugClassCode,
    drugSubClassCode,
    rowToUpdate
  ) => {
    const validDrugClassCode =
      drugGroupCode in drugMappingData &&
      drugClassCode in drugMappingData[drugGroupCode].drug_classes;
    const validDrugSubClassCode =
      validDrugClassCode &&
      drugSubClassCode in
      drugMappingData[drugGroupCode].drug_classes[drugClassCode]
        .drug_subclasses;
    if (validDrugSubClassCode) {
      rowToUpdate.drug_subclass =
        drugMappingData[drugGroupCode].drug_classes[
          drugClassCode
        ].drug_subclasses[drugSubClassCode].drug_subclass;
    }
  };

  _onDrugGroupChange = (rowToUpdate, dropDownRowToUpdate) => {
    rowToUpdate.drug_class = "";
    rowToUpdate.drug_subclass = "";
    dropDownRowToUpdate.drug_class = this._extractDrugDemographics(rowToUpdate);
    dropDownRowToUpdate.drug_subclass = [];
  };

  _onDrugClassChange = (rowToUpdate, dropDownRowToUpdate) => {
    rowToUpdate.drug_subclass = "";
    dropDownRowToUpdate.drug_subclass =
      this._extractDrugDemographics(rowToUpdate);
  };

  _autoSuggestionResultSelect = (data, rowIndex) => {
    const { state } = this;
    const programConditionLevels = [...state.programConditionLevels];
    const rowToUpdate =
      programConditionLevels[state.activeProgramConditionLevel][rowIndex];
    const { name, result } = data;
    const value = result.title;
    rowToUpdate[name] = value;
    programConditionLevels[state.activeProgramConditionLevel][rowIndex] =
      rowToUpdate;

    this.setState({
      programConditionLevels,
    });
  };

  _delProgramCondition = (rowIndex, programConditionLevel, data) => {
    const { programConditionLevels, dropdownValues, autoSuggestionValues } =
      this.state;
    const programConditionLevelToUpdate = [
      ...programConditionLevels[programConditionLevel],
    ];
    const dropdownToUpdate = [...dropdownValues[programConditionLevel]];
    const autoSuggestionToUpdate = [
      ...autoSuggestionValues[programConditionLevel],
    ];

    if (programConditionLevelToUpdate.length <= 1) return;

    const newProgramConditionLevel = programConditionLevelToUpdate.filter(
      (cond) => cond !== data
    );
    programConditionLevels[programConditionLevel] = newProgramConditionLevel;

    const newdropdown = dropdownToUpdate.filter(
      (cond) => dropdownToUpdate.indexOf(cond) !== rowIndex
    );
    dropdownValues[programConditionLevel] = newdropdown;

    const newAutoSuggestion = autoSuggestionToUpdate.filter(
      (cond) => autoSuggestionToUpdate.indexOf(cond) !== rowIndex
    );
    autoSuggestionValues[programConditionLevel] = newAutoSuggestion;

    this.setState({
      programConditionLevels,
      dropdownValues,
      autoSuggestionValues,
    });
  };

  _addProgramCondition = (level) => {
    const {
      programConditionLevels,
      dropdownValues,
      autoSuggestionValues,
      form,
    } = this.state;

    let no_of_conditions = programConditionLevels[level].length + 1
    let new_id = ""
    if ((form?.status || "DRAFT") === "PUBLISHED") {
      let condition_grid_version = (parseInt(form?.version || "1.0") + 1).toString() + ".0"
      new_id = this.generateConditionId("PROGRAM", form?.doc_name || "", condition_grid_version, level + 1, no_of_conditions)
    } else {
      new_id = this.generateConditionId("PROGRAM", form?.doc_name || "", form?.version || "1.0", level + 1, no_of_conditions)
    }
    const updatedProgramConditionLevels = programConditionLevels[level].concat(
      Object.keys(programConditionLevels[0][0]).reduce((acc, header) => {
        acc[header] = "";
        if (header === "include") acc[header] = true;
        acc["condition_id"] = new_id
        acc["effective_start_date"] = form.effective_start_date || "";
        acc["target_system_formulary_tier"] = form.target_system_formulary_tier || "";
        acc["target_system_formulary_status"] = form.target_system_formulary_status || "";
        return acc;
      }, {})
    );
    const updatedDropdownValues = dropdownValues[level].concat(
      Object.keys(dropdownValues[0][0]).reduce((acc, header) => {
        if (header === "drug_group") {
          acc[header] = drugGroupValues;
        } else {
          acc[header] = [];
        }
        return acc;
      }, {})
    );
    const updatedAutoSuggestionValues = autoSuggestionValues[level].concat(
      Object.keys(autoSuggestionValues[0][0]).reduce((acc, header) => {
        acc[header] = { values: [], loading: false };
        return acc;
      }, {})
    );
    programConditionLevels[level] = updatedProgramConditionLevels;
    dropdownValues[level] = updatedDropdownValues;
    autoSuggestionValues[level] = updatedAutoSuggestionValues;

    this.setState({
      programConditionLevels,
      dropdownValues,
      autoSuggestionValues,
    });
  };

  _deleteProgramConditionLevel = (e, programConditionLevel) => {
    const { state } = this;
    const programConditionLevels = [...state.programConditionLevels];
    const programQualifierLevels = [...state.programQualifierLevels];
    const rbpProgramQualifierLevels = [...state.rbpProgramQualifierLevels];
    const dropdownValues = [...state.dropdownValues];
    const autoSuggestionValues = [...state.autoSuggestionValues];
    const showQualifier = [...state.showQualifier];

    if (programConditionLevels.length <= 1) return;

    showQualifier.splice(programConditionLevel, 1);
    this.setState({
      activeProgramConditionLevel: 0,
      autoSuggestionValues: autoSuggestionValues.filter(
        (cond) => autoSuggestionValues.indexOf(cond) !== programConditionLevel
      ),
      dropdownValues: dropdownValues.filter(
        (cond) => dropdownValues.indexOf(cond) !== programConditionLevel
      ),
      programConditionLevels: programConditionLevels.filter(
        (cond) => programConditionLevels.indexOf(cond) !== programConditionLevel
      ),
      programQualifierLevels: programQualifierLevels.filter(
        (cond) => programQualifierLevels.indexOf(cond) !== programConditionLevel
      ),
      rbpProgramQualifierLevels: rbpProgramQualifierLevels.filter(
        (cond) =>
          rbpProgramQualifierLevels.indexOf(cond) !== programConditionLevel
      ),
      showQualifier,
    });
  };

  _addNewProgramConditionLevel = () => {
    const { state } = this;
    const programConditionLevels = [...state.programConditionLevels];
    const programQualifierLevels = [...state.programQualifierLevels];
    const rbpProgramQualifierLevels = [...state.rbpProgramQualifierLevels];
    const dropdownValues = [...state.dropdownValues];
    const qualifierDropdownValues = [...state.qualifierDropdownValues];
    const autoSuggestionValues = [...state.autoSuggestionValues];
    const showQualifier = [...state.showQualifier];
    const opioidProgramQualifierLevels = [
      ...state.opioidProgramQualifierLevels,
    ];
    let condition_levels = programConditionLevels.length
    programConditionLevels.push([
      {
        condition_id: this.generateConditionId("PROGRAM", state.form.doc_name, state.form.version, condition_levels + 1, 1),
        action: "",
        brand_generic: "",
        drug_class: "",
        drug_group: "",
        drug_name: "",
        drug_subclass: "",
        gpi: "",
        manufacturer: "",
        multi_source: "",
        otc_indicator: "",
        effective_start_date: state.form.effective_start_date || "",
        effective_end_date: "",
      },
    ]);
    programQualifierLevels.push([
      {
        action: "",
        program_operator: "",
        program_qualifier: "",
        program_value: "",
      },
    ]);
    rbpProgramQualifierLevels.push(cloneDeep(rbpQualifier));
    opioidProgramQualifierLevels.push(cloneDeep(opioidQualifier));
    dropdownValues.push([
      { drug_group: drugGroupValues, drug_class: [], drug_subclass: [] },
    ]);
    qualifierDropdownValues.push([
      { program_qualifier: qualifierType, program_operator: [] },
    ]);
    autoSuggestionValues.push([
      {
        manufacturer: { values: [], loading: false },
        drug_name: { values: [], loading: false },
      },
    ]);
    showQualifier.push(true);
    this.setState({
      activeProgramConditionLevel: programConditionLevels.length - 1,
      autoSuggestionValues,
      dropdownValues,
      programConditionLevels,
      programQualifierLevels,
      rbpProgramQualifierLevels,
      showQualifier,
      qualifierDropdownValues,
    });
  };

  _handleProgramConditionLevelClick = (e, titleProps) => {
    const { state } = this;
    const { activeProgramConditionLevel } = state;
    const { index } = titleProps;
    const newIndex = activeProgramConditionLevel === index ? -1 : index;
    this.setState({ activeProgramConditionLevel: newIndex });
  };

  _handleQualifierChange = (
    el,
    dropdown,
    rowIndex,
    gridApi,
    programConditionLevel
  ) => {
    const { state } = this;
    const { form, rbp_price_list } = state;
    const { custom_program_type } = form;
    const rbpQualifierToUpdate = rbp_price_list.includes(custom_program_type);
    const programQualifierLevels = rbpQualifierToUpdate
      ? [...this.getQualifierLevels(custom_program_type)]
      : [...state.programQualifierLevels];
    const rowToUpdate = programQualifierLevels[programConditionLevel][rowIndex];
    const { name, value } = dropdown || el.currentTarget;
    rowToUpdate[name] = value;
    programQualifierLevels[programConditionLevel][rowIndex] = rowToUpdate;

    const qualifierDropdownValues = [...state.qualifierDropdownValues];
    const dropDownRowToUpdate =
      qualifierDropdownValues[programConditionLevel][rowIndex];
    const options1 = [
      "Gender",
      "Diagnosis",
      "Pharmacy",
      "Prescriber",
      "Taxonomy",
      "Specialty",
      "Medicare Eligible",
      "State",
    ];
    const option2 = ["Lookback"];
    if (name === "program_qualifier") {
      if (options1.includes(value)) {
        dropDownRowToUpdate.program_operator = ["!=", "="];
      } else if (option2.includes(value)) {
        dropDownRowToUpdate.program_operator = ["<", "<="];
      } else if (
        ["Maximum Days of Supply", "Minimum Days of Supply"].includes(value)
      ) {
        dropDownRowToUpdate.program_operator = qualifierOperator.filter(
          (e) => e !== "!="
        );
      } else {
        dropDownRowToUpdate.program_operator = qualifierOperator;
      }
      qualifierDropdownValues[programConditionLevel][rowIndex] =
        dropDownRowToUpdate;
    }

    if (rbpQualifierToUpdate) {
      this.setState({
        rbpProgramQualifierLevels: programQualifierLevels,
        dropDownRowToUpdate,
      });
    } else {
      this.setState({
        programQualifierLevels,
        dropDownRowToUpdate,
      });
    }
    gridApi.refreshCells();
  };

  _delQualifierRow = (rowIndex, programConditionLevel) => {
    const { programQualifierLevels } = this.state;
    const programQualifierLevelToUpdate = [
      ...programQualifierLevels[programConditionLevel],
    ];
    if (programQualifierLevelToUpdate.length <= 1) return;

    const newProgramQualifierLevel = programQualifierLevelToUpdate.filter(
      (cond) => programQualifierLevelToUpdate.indexOf(cond) !== rowIndex
    );
    programQualifierLevels[programConditionLevel] = newProgramQualifierLevel;

    this.setState({
      programQualifierLevels,
    });
  };

  _addQualifierRow = (level) => {
    const { programQualifierLevels, qualifierDropdownValues } = this.state;
    const updatedProgramQualifierLevels = programQualifierLevels[level].concat(
      Object.keys(programQualifierLevels[0][0]).reduce((acc, header) => {
        acc[header] = "";
        return acc;
      }, {})
    );

    const updatedDropdownValues = qualifierDropdownValues[level].concat(
      Object.keys(qualifierDropdownValues[0][0]).reduce((acc, header) => {
        if (header === "program_qualifier") {
          acc[header] = qualifierType;
        } else {
          acc[header] = [];
        }
        return acc;
      }, {})
    );
    programQualifierLevels[level] = updatedProgramQualifierLevels;
    qualifierDropdownValues[level] = updatedDropdownValues;
    this.setState({
      programQualifierLevels,
      qualifierDropdownValues,
    });
  };

  checkDateValidation = (tierConditions) => {
    const { actions } = this.props;
    let invalidDate = false;
    let formattedProgramConditions = tierConditions.map((tierData) => {
      if (!tierData.effective_start_date) {
        invalidDate = true;
      }
      return {
        ...tierData,
        effective_start_date: convertDateToStartTimeObj(
          tierData.effective_start_date
        ),
        effective_end_date: convertDateToEndTimeObj(
          tierData.effective_end_date
        ),
      };
    });
    if (invalidDate) {
      const transitionalPortal = {
        header: "Missing Effective Start Date in conditions",
        copy: `Please Add effective Start Date before saving`,
      };
      this.props.actions.displayTransitionalPortal(transitionalPortal);
    }
    return { formattedProgramConditions, invalidDate };
  };

  _download_drugs_to_csv = (el, programConditionLevel) => {

    const { props, state } = this;
    const { programConditionLevels, form } = state;
    const { doc_name, version } = form;
    let filename = `program_list_${doc_name}_${version}_level_${programConditionLevel + 1}_${Date.now()}.csv`
    let programConditions = programConditionLevels[programConditionLevel]
    const { formattedProgramConditions, invalidDate } =
      this.checkDateValidation(programConditions);
    if (invalidDate) return;
    props.actions.generateProgramListLevelExport({
      drugConditions: formattedProgramConditions,
      programConditionLevel: programConditionLevel,
      filename: filename
    }, downloadCSV)
  }

  _sendForReview = () => {
    const { props, state } = this;
    const {
      form,
      form: { doc_id },
    } = state;

    if (!doc_id) {
      const transitionalPortal = {
        header: "Program List For Approval",
        copy: "Please save Program List Data first",
      };
      props.actions.displayTransitionalPortal(transitionalPortal);
    } else {
      const message = "Send Program List For Review?";

      props.actions.setModalComponent({
        modalProperties: {
          size: "tiny",
        },
        contents: SaveTierModal({
          form,
          message,
          onSaveClick: props.actions.rpmSendForReview,
          toggleModal: props.actions.toggleModal,
        }),
      });
      props.actions.toggleModal();
    }
  };

  _saveProgramList = () => {
    const {
      props,
      props: {
        state: { criteriaIds },
      },
      state,
    } = this;
    const {
      form,
      programConditionLevels,
      programQualifierLevels,
      rbpProgramQualifierLevels,
      rbp_price_list,
    } = state;
    const hierarchyOrganizationLevel = form?.hierarchy?.[0]?.children || [];
    if (
      !form.doc_name ||
      !form.effective_start_date ||
      hierarchyOrganizationLevel?.length == 0
    ) {
      let message = "";
      if (!form.doc_name) message = "enter a Program List Name";
      else if (!form.effective_start_date) message = "select a Start Date";
      else if (hierarchyOrganizationLevel?.length == 0)
        message = "assign a Hierarchy";
      const transitionalPortal = {
        header: "Program List Save Failed",
        copy: `Please ${message} before saving`,
      };
      props.actions.displayTransitionalPortal(transitionalPortal);
    } else {
      const { custom_program_type } = form;
      const rbpQualifierToUpdate = rbp_price_list.includes(custom_program_type);
      const qualifierConditions = rbpQualifierToUpdate
        ? this.getQualifierLevels(custom_program_type)
        : programQualifierLevels;
      if (rbpQualifierToUpdate) {
        const allQualifiersEntered = qualifierConditions.every((l) =>
          l.every((row) => !!row.program_value)
        );
        if (!allQualifiersEntered) {
          const transitionalPortal = {
            header: "Program List Save Failed",
            copy: "Please enter all qualifiers before saving",
          };
          props.actions.displayTransitionalPortal(transitionalPortal);
          return;
        }
      }
      const formattedProgramConditionLevels = [];
      for (const i in programConditionLevels) {
        const { formattedProgramConditions, invalidDate } =
          this.checkDateValidation(programConditionLevels[i]);
        if (invalidDate) return;
        else formattedProgramConditionLevels.push(formattedProgramConditions);
      }
      if (!custom_program_type) form.custom_program_type = "";
      const message =
        "You are about to save the Program List. Do you wish to save?";
      props.actions.setModalComponent({
        modalProperties: {
          size: "tiny",
        },
        contents: SaveTierModal({
          form,
          conditions: {
            criteriaIds,
            umConditions: formattedProgramConditionLevels,
            programConditions: formattedProgramConditionLevels,
            qualifierConditions,
          },
          onSaveClick: props.actions.saveProgramListData,
          toggleModal: props.actions.toggleModal,
          message,
        }),
      });
      props.actions.toggleModal();
    }
  };

  _mapValueToOption = (row) => {
    const data = {
      ...row,
    };
    Object.keys(data).forEach((col) => {
      data[col] = data[col].map((group, index) => ({
        key: index,
        text: group,
        value: group,
      }));
    });
    return data;
  };

  _extractDrugDemographics = (selectedValues) => {
    const validKeys = ["drug_group", "drug_class"];
    const extractedKeys = validKeys.filter((key) => !!selectedValues[key]);
    const extractedData = extractedKeys.reduce(
      (obj, key) => ({
        ...obj[selectedValues[key]],
      }),
      drugDemographicData
    );
    return Object.keys(extractedData);
  };

  _updateDropdownFromCond(initDropdownValues) {
    const { state } = this.props;
    const { programListCondData } = state;
    const dropdownValues = [];
    programListCondData.forEach((cond) => {
      const dropdownRowValues = [];
      cond?.forEach(() => {
        dropdownRowValues.push({ ...initDropdownValues[0][0] });
      });
      dropdownValues.push(dropdownRowValues);
    });
    // const dropdownValues = Array(programListCondData.length).fill().map(() => ({ ...initDropdownValues[0] }))

    programListCondData.forEach((programListCondLevelData, level) => {
      programListCondLevelData.forEach((cond, index) => {
        const values = {};
        if (cond.drug_group) {
          values.drug_group = cond.drug_group;
          dropdownValues[level][index].drug_class =
            this._extractDrugDemographics(values);
        }
        if (cond.drug_class) {
          values.drug_class = cond.drug_class;
          dropdownValues[level][index].drug_subclass =
            this._extractDrugDemographics(values);
        }
        if (cond.drug_subclass) {
          values.drug_subclass = cond.drug_subclass;
        }
      });
    });
    return dropdownValues;
  }

  _updateAutoSuggestionFromCond(initAutoSuggestionValues) {
    const { state } = this.props;
    const { programListCondData } = state;
    const autoSuggestionValues = [];
    programListCondData.forEach((cond) => {
      const autoSuggestionRowValues = [];
      cond?.forEach(() => {
        autoSuggestionRowValues.push({ ...initAutoSuggestionValues[0][0] });
      });
      autoSuggestionValues.push(autoSuggestionRowValues);
    });
    return autoSuggestionValues;
  }

  _updateState() {
    const { props, state } = this;
    const { dropdownValues, autoSuggestionValues } = state;
    const { rbp_price_list } = state;
    const {
      programListData,
      programListQualifierCondData,
      programListCondData,
    } = props.state;
    const updatedDropdownValues = this._updateDropdownFromCond(dropdownValues);
    const updatedAutoSuggestionValues =
      this._updateAutoSuggestionFromCond(autoSuggestionValues);
    // const updatedAutoSuggestionValues = Array(programListCondData.length).fill().map(() => ({ ...autoSuggestionValues[0] }))
    const updatedShowQualifier = Array(programListCondData.length).fill(true);
    const rbpQualifierToUpdate = rbp_price_list.includes(
      programListData.custom_program_type
    );
    const form = {
      ...state.form,
      doc_id: programListData.doc_id,
      doc_name: programListData.doc_name,
      effective_start_date: programListData.effective_start_date,
      version_effective_date: programListData.version_effective_date,
      list_type: programListData.list_type,
      custom_program_type: programListData.custom_program_type,
      hierarchy: programListData.hierarchy,
      is_hierarchy_global: programListData.is_hierarchy_global,
      hideHierarchy: programListData.hideHierarchy,
      version: programListData.version,
      status: programListData.status,
    };
    if (programListCondData?.length) {
      programListCondData.map((outerData, level_index) => {
        if (outerData?.length) {
          outerData.map((innerData, condition_index) => {
            if (
              !innerData.effective_start_date &&
              programListData.effective_start_date
            )
              innerData.effective_start_date = convertDateToStartTimeObj(
                programListData.effective_start_date
              );
            innerData.condition_id = innerData?.condition_id || this.generateConditionId("PROGRAM", form?.doc_name || "", form?.version || '1.0', level_index + 1, condition_index + 1)
          });
        }
      });
    }

    this.setState({
      form,
      programConditionLevels: programListCondData,
      // programConditionLevels: programListCondData.map((level) =>
      //   level.map((row) => {
      //     if (row?.include_in_grid !== true) {
      //       row.include_in_grid = true;
      //     }
      //     return row;
      //   })
      // ),
      dropdownValues: updatedDropdownValues,
      autoSuggestionValues: updatedAutoSuggestionValues,
      showQualifier: updatedShowQualifier,
    });

    if (rbpQualifierToUpdate) {
      let key = "rbpProgramQualifierLevels";
      if (
        programListQualifierCondData[0][0].program_qualifier === "MME per Unit"
      )
        key = "opioidProgramQualifierLevels";
      this.setState({
        [key]: programListQualifierCondData,
      });
    } else {
      this.setState({
        programQualifierLevels: programListQualifierCondData,
      });
    }
  }

  _updateAutoSuggestions() {
    const { props, state } = this;
    const { autoSuggestData } = props.state;
    const { activeProgramConditionLevel, rowUpdated, columnUpdated } = state;
    const autoSuggestionValues = [...state.autoSuggestionValues];

    if (columnUpdated === "manufacturer") {
      autoSuggestionValues[activeProgramConditionLevel][
        rowUpdated
      ].manufacturer.values = autoSuggestData["mfg"].map((val) => ({
        title: val,
      }));
      autoSuggestionValues[activeProgramConditionLevel][
        rowUpdated
      ].manufacturer.loading = false;
      if (this.setAutoSuggestionValues)
        this.setAutoSuggestionValues(autoSuggestionValues);
    } else if (columnUpdated === "drug_name") {
      autoSuggestionValues[activeProgramConditionLevel][
        rowUpdated
      ].drug_name.values = autoSuggestData["drug_name"].map((val) => ({
        title: val,
      }));
      autoSuggestionValues[activeProgramConditionLevel][
        rowUpdated
      ].drug_name.loading = false;
      if (this.setAutoSuggestionValues)
        this.setAutoSuggestionValues(autoSuggestionValues);
    }

    this.setState(autoSuggestionValues);
  }
  _onCheckboxChange = (el, name, levelIndex, rowIndex) => {
    const { state } = this;
    let ConditionLevels = state.programConditionLevels;
    const rowToUpdate = ConditionLevels[levelIndex][rowIndex];
    const {
      target: { checked: value },
    } = el;

    rowToUpdate[name] = value;
    if ((state?.form?.status || "DRAFT") === "PUBLISHED") {
      let condition_grid_version = (parseInt(state.form.version) + 1).toString() + ".0"
      rowToUpdate["condition_id"] = this.generateConditionId("PROGRAM", state.form.doc_name, condition_grid_version, levelIndex + 1, rowIndex + 1)
    } else {
      rowToUpdate["condition_id"] = this.generateConditionId("PROGRAM", state.form.doc_name, state.form.version, levelIndex + 1, rowIndex + 1)
    }
    ConditionLevels[levelIndex][rowIndex][name] = value
    this.setState({
      programConditionLevels: ConditionLevels,
      rowUpdated: rowIndex,
      columnUpdated: name,
      columnValue: value,
    });
  };

  _constructQualifierDropdownOptions = () => {
    const { programQualifierLevels, qualifierDropdownValues } = this.state;
    const newQualifierDropdownValues = [...qualifierDropdownValues];
    programQualifierLevels.map((x, level) => {
      for (
        let i = 0;
        i < (programQualifierLevels[level]?.length || 1) - 1;
        i++
      ) {
        newQualifierDropdownValues[level].push(
          Object.keys(qualifierDropdownValues[0][0]).reduce((acc, header) => {
            if (header === "program_qualifier") {
              acc[header] = qualifierType;
            } else {
              acc[header] = [];
            }
            return acc;
          }, {})
        );
      }
    });

    return newQualifierDropdownValues.map((dropdownValueLevel) =>
      dropdownValueLevel.map((row) => this._mapValueToOption(row))
    );
  };

  approvalOptionsCallback = () => {
    const { state, props } = this;
    props.actions.getProgramListData({ doc_id: state.form.doc_id });
  };

  render() {
    const { props, state } = this;
    const {
      form,
      form: { hideHierarchy },
      activeProgramConditionLevel,
      programConditionLevels,
      programQualifierLevels,
      rbpProgramQualifierLevels,
      autoSuggestionValues,
      autoSuggestionMinChars,
      showQualifier,
      listTypeValues,
      rbp_price_list,
      cellRenderers,
    } = state;
    // let editMode = true

    // if ('state' in props.history.location) {
    //   editMode = (props.history.location.state && 'editMode' in props.history.location.state) ? props.history.location.state.editMode : true
    //   if (parseQueryString(props.history.location.search).editMode === 'false') editMode = false
    // }

    let editMode = true;

    if ("state" in props.history.location) {
      editMode =
        props.history.location.state &&
          "editMode" in props.history.location.state
          ? props.history.location.state.editMode
          : true;

      if (parseQueryString(props.history.location.search).editMode === "false")
        editMode = false;
    }
    if (hideHierarchy) editMode = false;
    const dropdownOptions = this._constructDropdownOptions();
    const listTypeOptions = listTypeValues.map((val, idx) => ({
      key: idx,
      text: val,
      value: val.toUpperCase(),
    }));
    const customProgramTypeOptions = Object.keys(customProgramTypeCodes).map(
      (d, idx) => ({
        key: idx,
        text: customProgramTypeCodes[d].text,
        value: customProgramTypeCodes[d].value,
      })
    );
    let headerText = editMode ? "Create Program List" : "View Program List";

    if (form.doc_id && editMode) headerText = "Edit Program List";

    const { custom_program_type } = form;
    const rbpQualifierToUpdate = rbp_price_list.includes(custom_program_type);
    const qualifierLevels = this.getQualifierLevels(custom_program_type);
    const qualifierDropdownOptions = this._constructQualifierDropdownOptions();

    return (
      <div id="createProgramList">
        <div className="breadCrumbContainer">
          <BreadCrumb {...this.props} />
        </div>
        <div className="header">{headerText}</div>
        {
          <ApprovalOptions
            docData={this.state.form}
            actionCallback={this.approvalOptionsCallback}
          />
        }
        <div className="content">
          <ProgramConditions
            activeProgramConditionLevel={activeProgramConditionLevel}
            addNewProgramConditionLevel={this._addNewProgramConditionLevel}
            addProgramCondition={this._addProgramCondition}
            addQualifierRow={this._addQualifierRow}
            autoSuggestionMinChars={autoSuggestionMinChars}
            autoSuggestionResultSelect={this._autoSuggestionResultSelect}
            autoSuggestionValues={autoSuggestionValues}
            customProgramTypeOptions={customProgramTypeOptions}
            criteriaIds={this.props.state.criteriaIds}
            delProgramCondition={this._delProgramCondition}
            delQualifierRow={this._delQualifierRow}
            deleteProgramConditionLevel={this._deleteProgramConditionLevel}
            doc_id={form.doc_id}
            dropdownOptions={dropdownOptions}
            exportConditionDrugs={this._download_drugs_to_csv}
            editMode={editMode}
            form={form}
            onCheckboxChange={this._onCheckboxChange}
            setForm={this.setForm}
            handleChange={this._handleChange}
            handleProgramConditionLevelClick={
              this._handleProgramConditionLevelClick
            }
            handleQualifierChange={this._handleQualifierChange}
            listTypeOptions={listTypeOptions}
            programConditionLevels={programConditionLevels}
            programQualifierLevels={programQualifierLevels}
            rbpProgramQualifierLevels={qualifierLevels}
            rbpQualifierToUpdate={rbpQualifierToUpdate}
            saveProgramList={this._saveProgramList}
            sendForReview={this._sendForReview}
            showQualifier={showQualifier}
            updateFields={this._updateFields}
            cellRenderers={cellRenderers}
            qualifierDropdownOptions={qualifierDropdownOptions}
          />
          {/* <ProgramDrugs
            programConditionLevels={programConditionLevels}
            editMode={editMode}
            saveProgramList={this._saveProgramList}
          /> */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  state: {
    allProgramListData: state.programListCreation.allProgramListData,
    autoSuggestData: state.autoSuggest.autoSuggestData,
    autoSuggestSearchTerm: state.autoSuggest.autoSuggestSearchTerm,
    programListData: state.programListCreation.programListData,
    programListQualifierCondData:
      state.programListCreation.programListQualifierCondData,
    programListCondData: state.programListCreation.programListCondData,
    criteriaIds: state.programListCreation.programListCriteriaIdData,
  },
});

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
    ...AutoSuggestActions,
    ...ProgramListCreationActions,
    ...QualifierActions,
    ...RPMActions,
  };

  return {
    actions: bindActionCreators(allActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateProgramList);
