import React, { useEffect, useMemo } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Creators as AppActions } from '../../../../../redux/reducers/app'
import { Creators as GlobalEditsActions } from '../../../../../redux/reducers/api/globalEdits'
import { Creators as NetworkCreationActions } from '../../../../../redux/reducers/api/networkCreationManagement'
import { MultiSelectToolTipDropDown, NetworkPricingConfiguration, ToolTipDropDown } from '../../../emergencyOverrides/components';
import { StackedItem }  from './components';

const networkSectionRefTable = {
    'networks': 'allNetworksData',
    'network_tiers': 'allNetworkEditsData',
}

function NetworkPricing(props) {
    const { actions, state } = props
    const { allNetworksData = [], allNetworkEditsData = [], globalEdits } = state;
    const {
        networks,
        network_tiers,
    } = globalEdits;
    const nullOption = { text: '', value: '' };
    // const allNetworksSortedOptions = useMemo(() => {
    //     return [nullOption, ..._.cloneDeep(allNetworksData).sort((a, b) => a.doc_name.localeCompare(b.doc_name)).map((network) => ({
    //         text: network.doc_name,
    //         value: network.doc_id,
    //     }))];
    // }, [allNetworksData]);
    const allNetworkEditsSortedOptions = useMemo(() => {
        return [nullOption, ..._.cloneDeep(allNetworkEditsData).sort((a, b) => a.doc_name.localeCompare(b.doc_name)).map((edit) => ({
            text: edit.doc_name,
            value: edit.doc_id,
        }))];
    }, [allNetworkEditsData]);

    useEffect(() => {
        // actions.getAllNetworksData()
        props.actions.getSectionUI({ section: 'global_pricing' })
        actions.getAllNetworkEditsData({ doc_type: 'global_network_edit' })
    }, []);

    const removeOptionFromList = (section, id) => {
        const selectedSection = globalEdits[section] || [];
        const newList = selectedSection.filter((obj) => obj.doc_id !== id);
        actions.updateGlobalEditsField({ name: section, value: newList });
    }

    const handlePharmacySelection = (section, dropdown) => {
        const { value } = dropdown
        const selectedSection = globalEdits[section] || [];
        if (selectedSection.find((obj) => obj.doc_id === value)) return;
        const newList = [...selectedSection];
        const networkList = state[networkSectionRefTable[section]] || [];
        const record = networkList.find((obj) => obj.doc_id === value);
        if (!record) return;
        const newNetwork = {
            ...record,
            doc_id: record.doc_id,
            formulary_version_id: record.doc_id,
            rank: newList.length + 1,
            version: record.doc_version,
        };
        newList.push(newNetwork);
        actions.updateGlobalEditsField({ name: section, value: newList });
    }

    const moveItem = (section, index, direction) => {
        const list = globalEdits[section] || [];
        if (list.length < 2) return;
        const newList = [...list];
        const temp = newList[index];
        newList[index] = newList[index + direction];
        newList[index].rank += -(direction);
        newList[index + direction] = temp;
        newList[index + direction].rank += direction;
        actions.updateGlobalEditsField({ name: section, value: newList });
    };

    const onDragStart = (section, e, index) => {
        e.dataTransfer.setData(
            'dragData',
            JSON.stringify({ selectedIndex: index, originSection: section })
        );
    };

    const onDrop = (section, e, dropIndex) => {
        const dragData = JSON.parse(e.dataTransfer.getData('dragData'));
        const { selectedIndex, originSection } = dragData;
        if (originSection !== section) return;
        const selectedSection = globalEdits[section] || [];
        const selectedRecord = selectedSection[selectedIndex];
        const newSection = selectedSection.filter((record) => record.doc_id !== selectedRecord.doc_id);
        newSection.splice(dropIndex, 0, selectedRecord);
        newSection.forEach((record, index) => record.rank = index + 1);
        actions.updateGlobalEditsField({ name: section, value: newSection });
    };

    return (
        <div className='page-container'>
            <section>
                <h2 className='page-h2'>
                    Pharmacy Network Definition
                </h2>
                <section className='input-container'>
                    {/* hiding the Pharmacy Networkfir time being */}
                    {/* <MultiSelectToolTipDropDown
                        label='Pharmacy Network'
                        tooltip=''
                        options={allNetworksSortedOptions}
                        name="networks"
                        dropdownPlaceholder='Select Pharmacy Network'
                        onChange={(_, dropdown) => handlePharmacySelection('networks', dropdown)}
                        removeOptionFromList={(id) => removeOptionFromList('networks', id)}
                        selectedOptions={networks}
                    /> */}
                    <MultiSelectToolTipDropDown
                        label='Pharmacy Edit'
                        tooltip=''
                        options={allNetworkEditsSortedOptions}
                        name="network_tiers"
                        dropdownPlaceholder='Select Pharmacy Edit'
                        onChange={(_, dropdown) => handlePharmacySelection('network_tiers', dropdown)}
                        removeOptionFromList={(id) => removeOptionFromList('network_tiers', id)}
                        selectedOptions={network_tiers}
                    />
                </section>
            </section>
            <section>
                {network_tiers?.length === 0 ? null : (
                    <article>
                        <section className='stacked-label'>
                            <span>
                                Pharmacy Edits
                            </span>
                        </section>
                        <ul className='stacked-container'>
                            {network_tiers.map((nt, index) => {
                                return (
                                    <StackedItem
                                        text={nt?.doc_name || ''}
                                        index={index}
                                        position={index + 1}
                                        moveDown={() => moveItem('network_tiers', index, 1)}
                                        moveUp={() => moveItem('network_tiers', index, -1)}
                                        last={network_tiers.length - 1 === index}
                                        onDragStart={(e) => onDragStart('network_tiers', e, index)}
                                        onDragOver={(e) => e.preventDefault()}
                                        onDrop={(e) => onDrop('network_tiers', e, index)}
                                    />
                                )
                            })}
                        </ul>
                    </article>
                )}
            </section>
            <section>
                <h2 className='page-h2'>
                    Network Pricing Configuration
                </h2>
                <section>
                    <NetworkPricingConfiguration />
                </section>
            </section>
        </div>
    )
}


const mapStateToProps = (state) => ({
    state: {
        app: state.app,
        globalEdits: state.globalEdits,
        allNetworksData: state.networkTierCreation.allNetworksData,
        allNetworkEditsData: state.networkTierCreation.allNetworkEditsData,
        networkTierCreation: state.networkTierCreation
    },
})

const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...AppActions,
        ...GlobalEditsActions,
        ...NetworkCreationActions,
    }

    return {
        actions: bindActionCreators(allActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NetworkPricing);
