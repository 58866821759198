import React from 'react'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Creators as AppActions } from '../../../../../redux/reducers/app';
import { Creators as MemberLookupActions } from '../../../../../redux/reducers/api/memberLookup';

import FliptButton from '../../../../../components/form/fliptButton'

const MemberLookupButtonBar = (props) => {
  const { hasPasswordWriteAccess, member_details, plan_data, primary_member_details, toggleChangePassword, updateMember, getMemberEligibilityLogs } = props
  const { domain_name, member_id, flipt_person_id = '', plan_name } = member_details
  const member_info = {
    ...member_details,
    plan_name: plan_data?.current_plan?.plan_name || '',
  }
  const { family_id = '', subscriber_id } = primary_member_details

  const sendCardId = () => {
    const { actions } = props
    const sendCardData = {
      benefit_plan_name: plan_name,
      family_id: family_id,
      flipt_person_id: flipt_person_id,
      is_deleted: false,
      status: "pending"
    }
    actions.saveCard(sendCardData);
  }

  const getIdButton = () => {
    if (domain_name === 'NOVARTIS001' && (plan_name.length === 7 || plan_name.length === 6)) {
      const pn = plan_name.split(' ');
      return pn[0].toLowerCase() === 'plan' && pn[1] <= 15 ? <FliptButton onClick={sendCardId} name="Send ID Card" className="primary" /> : "";
    }
  }

  return (
    <>
      <div className="button-container">
        <FliptButton name="Update" className="primary" disabled />
        <Link to={`/member-lookup-claim-history?flipt_person_id=${flipt_person_id}`}>
          <FliptButton name="Claim History" className="primary" />
        </Link>
        <Link to={`/claim-lookup-management?member_id=${member_id}`}>
          <FliptButton name="Claims" className="primary" />
        </Link>
        <Link to={`/financial-information-reporting?cardholder_id=${member_id}`}>
          <FliptButton name="FIR Transactions" className="primary" />
        </Link>
        <Link to={`/nx-transaction?cardholder_id=${member_id}`}>
          <FliptButton name="Nx Transactions" className="primary" />
        </Link>
      </div>
      <div className="button-container">
        <FliptButton name="Update Password" className="primary" disabled={!member_id || !hasPasswordWriteAccess} onClick={toggleChangePassword} />
        <FliptButton name="Edit Member" className="primary" onClick={() => updateMember(true)} disabled />
        <Link
          to={{
            pathname: '/price-check-management',
            state: {
              member_info,
            },
          }}
        >
          <FliptButton name="Price Check" className="primary" disabled={!member_id} />
        </Link>
        <Link
          to={{
            pathname: '/eligibility',
            state: {
              member_info,
              primary_member_details
            },
          }}
        >
          <FliptButton name="Eligibility" className="primary" disabled={!domain_name} />
        </Link>
        {domain_name && plan_name && getIdButton()}
        <Link
          to={{
            pathname: `/communication-history`,
            search: `?flipt_person_id=${flipt_person_id}`,
            state: {
              flipt_person_id,
            },
          }}
        >
          <FliptButton name="Communication History" className="primary" disabled={!domain_name} />
        </Link>
        <FliptButton name="Eligibility Logs" className="primary" onClick={getMemberEligibilityLogs} disabled={!subscriber_id} />
      </div>
    </>

  )
}

const mapStateToProps = (state) => ({
  state: { memberLookup: state.memberLookup }
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
    ...MemberLookupActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MemberLookupButtonBar)