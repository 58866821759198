import React, { PureComponent } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Creators as AppActions } from '../../../redux/reducers/app'
import MenuCard from '../../../components/menu/card'

class OperationsReporting extends PureComponent {
  _renderMenuCards = () => {
    const { state } = this.props
    const data = state.menu.find((d) => d.id === 'operations-reporting').children
    return data.map((m) => <MenuCard key={m.id} menu={m} />)
  }

  render() {
    return (
      <div id="homePage">
        <div id="cardMenuContainer">
          { this._renderMenuCards() }
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  state: {
    app: state.app,
    menu: state.app.menu_data,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OperationsReporting)
