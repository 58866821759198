
import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Creators as PriorAuthorizationCreationActions } from '../../../../redux/reducers/api/priorAuthorizationManagement'
import { Creators as MemberLookupActions } from '../../../../redux/reducers/api/memberLookup'

import './styles.scss'
import FliptButton from '../../../../components/form/fliptButton'
import FliptTextarea from '../../../../components/form/fliptTextarea'
import FliptGrid from '../../../../components/fliptGrid'
import { IconClickRenderer } from '../../../../components/fliptGrid/cellRenderers'
import { Creators as AppActions } from '../../../../redux/reducers/app'
import { Icon } from 'semantic-ui-react'
import { convertDateTimeToEstStr } from '../../../../utils/utilities'
class Notes extends Component {
  constructor(props) {
    super(props)

    this.state = {
      notesData: [],
      displayAddNote: false,
      noteData: {
        noteDescription: ''
      },
      noteRendererIcon: 'copy'
    }
  }

  componentDidMount() {
    const { pa_case_id, actions } = this.props
    actions.getNotes({ pa_case_id })
  }

  componentDidUpdate(prevProps) {
    const { props } = this
    const { notesData } = props.state
    if (prevProps.state.notesData !== notesData && notesData.length) {
      const customNotesData = notesData.map((data) => {
        const estDateTime = convertDateTimeToEstStr(data?.note_date_time)
        const [date, time] = estDateTime.split(' ')
        const obj = {
          note: data.note,
          note_type: data.note_type,
          date,
          time,
          technician: data.technician,
        }
        return obj;
      })
      this.setState({ notesData: customNotesData })
    }
  }

  _displayAddNote = () => {
    this.setState({ displayAddNote: true })
  }

  _changeNoteData = (el, dropdown) => {
    const { noteData } = this.state
    const { name, value } = dropdown || el.currentTarget
    noteData[name] = value
    this.setState({ noteData })
  }

  _showTransitionalPortal = (header, message) => {
    const { props } = this;
    const transitionalPortal = {
      header: header,
      copy: message,
    }
    props.actions.displayTransitionalPortal(transitionalPortal)
  }

  _saveNote = () => {
    const { pa_case_id, state: { user } } = this.props
    const { noteData } = this.state
    const header = 'Fields Missing'
    let message = 'Please add a Note Type to proceed'


    if (!noteData.noteDescription) {
      message = 'Please add a Note Description to proceed'
      this._showTransitionalPortal(header, message)
      return;
    }
    const params = {
      pa_case_id,
      note: {
        note: noteData.noteDescription,
        technician: `${user.first_name} ${user.last_name}`,
        note_type: 'Internal'
      }
    }
    this.props.actions.createNote(params)
    this.setState({
      noteData: {
        noteDescription: ''
      }
    })
  }

  _copyNote = (gridProps) => {
    const { data } = gridProps
    navigator.clipboard.writeText(data.note)
  }

  renderNotes() {
    const headers = ['note', 'date', 'time', 'note_type', 'technician']
    const cellRendererParams = {
      date: {
        width: '120px'
      },
      time: {
        width: '120px'
      },
      note_type: {
        width: '120px'
      },
      technician: {
        width: '120px'
      },
      note: {
        tooltipField: 'note',
        cellRenderer: IconClickRenderer,
        searchArgs: {
          iconName: this.state.noteRendererIcon,
          iconOnClick: this._copyNote,
          fieldToRenderInCell: 'note',
        }
      }
    }

    const { notesData, displayAddNote, noteData } = this.state
    return (
      <div className='content'>
        <div className="pa-notes-grid">
          <FliptGrid
            data={notesData || []}
            headers={headers}
            cellRendererParams={cellRendererParams}
            tooltipShowDelay={0}
          />
        </div>
        {!displayAddNote &&
          <div className='pa-button-container'>
            <FliptButton className='primary' name='Add Notes' onClick={this._displayAddNote} />
          </div>}
        {displayAddNote &&
          <div className='note-container'>
            <FliptTextarea name='noteDescription' value={noteData.noteDescription} stylized
              label='Note Description' onChange={this._changeNoteData} isPa />
            <div className="pa-button-container">
              <FliptButton className='primary' name='SAVE NOTE' onClick={this._saveNote} />
            </div>
          </div>}
      </div>
    )
    // const notes = notesData.map((data) => {
    //     return (<div className='notes-container'>
    //         <div className='notes-icon-container'>
    //             <Icon color='grey' name='file alternate outline' size='large' />
    //         </div>
    //         <div className='notes-devider-container'>
    //             <div className='notes-inner-container'>
    //                 <div className='header-container'>
    //                     Internal Note
    //                 </div>
    //                 <div className='date-time-container'>
    //                     {data?.date}  |  {data?.time}
    //                 </div>
    //                 <div className='text-container'>
    //                     {data?.note}
    //                 </div>
    //             </div>
    //             <div className='notes-devider' />
    //         </div>
    //     </div>)
    // })
    // return notes;
  }

  closeModal = () => {
    const { actions } = this.props
    actions.toggleSideDrawer()
  }

  render() {
    const { state } = this.props
    const {
    } = state

    return (
      <div id="Notes">
        <div className='header-container'>
          <span className='header-text'>Notes</span>
          <Icon onClick={() => this.closeModal()} name='close' color='grey' />
        </div>
        {this.renderNotes()}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  state: {
    app: state.app,
    user: state.user,
    memberLookup: state.memberLookup,
    notesData: state.priorAuthorizationCreation.notesData,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...MemberLookupActions,
    ...AppActions,
    ...PriorAuthorizationCreationActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Notes)
