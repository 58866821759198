
import React, { PureComponent } from 'react'
import moment from 'moment'
import { Creators as formularyBuilderActions } from '../../../../redux/reducers/api/cmsFormualryBuilder'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import './styles.scss'
import BreadCrumb from '../../../../components/breadCrumb'
import Config from "../../../../config";
import FliptButton from '../../../../components/form/fliptButton'
import FliptCheckbox from '../../../../components/form/fliptCheckbox'
import FliptDatepicker from '../../../../components/form/fliptPaDatepicker'
import FliptDropdown from '../../../../components/form/fliptPaDropdown'
import FliptGrid from '../../../../components/fliptGrid';
import FliptHierarchyDropdown from '../../../../components/form/fliptHierarchyDropdown'
import FliptInput from '../../../../components/form/fliptPaInput'
import FliptLabel from '../../../../components/form/fliptLabel'
import rowCellInfo from '../data/rowCellInfo'
import { AlternativeModelsData, CmsTierLabelTypes, lobTypes, submissionFilesData } from '../data/dropdownData'
import { Creators as AppActions } from '../../../../redux/reducers/app'
import { DeleteRowRenderer, MultiRowRenderer } from '../../../../components/fliptGrid/cellRenderers'
import { DropdownEditor, InputTextEditor } from '../../../../components/fliptGrid/cellEditors'
import { calcGridRowHeight, convertStrToDateObj, getCAGOptions } from '../../../../utils/utilities'
import { parseQueryString } from "../../../../utils/utilities"

class FormularyBuilder extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            form: {
                contract_id: '',
                effective_end: '',
                effective_start: '',
                formulary_name: '',
                group: '',
                hierarchy: [],
                lob: '',
                pa_drug_list_id: [],
                pbp: '',
                pr_alt_drug_list_id: [],
                pr_ex_drug_list_id: [],
                ql_drug_list_id: [],
                st_drug_list_id: [],
            },
            tiers: [],
            druglistTypes: ["DRUG_LIST", "PROGRAM"],
            dropdownValues:
                [
                    {
                        drug_list_type: ["DRUG_LIST", "PROGRAM"],
                        drug_list_name: [],
                    },
                ],
            file_generation_criterias: ['Medicare D', 'Medicare - EGWP'],
            supplemental_benefits: AlternativeModelsData,
            submission_files: submissionFilesData
        }
    }

    _mapValueToOption = (row) => {
        const data = {
            ...row,
        };
        Object.keys(data).forEach((col) => {
            if (Array.isArray(data[col])) {
                data[col] = data[col].map((group, index) => ({
                    key: index,
                    text: group,
                    value: group,
                }));
            }
        });
        return data;
    };


    _constructDropdownOptions = () =>
        this.state.dropdownValues.map((row) => this._mapValueToOption(row));


    _updateField = (el, dropdown) => {
        const { name, value } = dropdown || el.currentTarget
        const { form } = this.state
        const { actions } = this.props
        const newForm = { ...form }
        if (name === 'group') {
            newForm.contract_id = ''
            newForm.pbp = ''
            actions.getGroupConfigData({ group_name: value })
        }
        newForm[name] = value
        this.setState({ form: newForm })
    }

    componentDidUpdate(prevProps) {
        const { state: { groupConfigData } } = this.props
        const { form } = this.state
        if (prevProps.state.groupConfigData !== groupConfigData && groupConfigData) {
            const newForm = { ...form }
            newForm.contract_id = groupConfigData.cms_contract_id
            newForm.pbp = groupConfigData.cms_pbp
            this.setState({ form: newForm })
        }
    }

    clearFormData() {
        this.setState({
            form: {
                formulary_name: '',
                lob: '',
                effective_start: '',
                effective_end: '',
                pa_drug_list_id: [],
                ql_drug_list_id: [],
                st_drug_list_id: [],
                pr_ex_drug_list_id: [],
                pr_alt_drug_list_id: [],
                pbp: '',
                contract_id: '',
            },
        });
    }

    addGridRow = () => {
        const { tiers, druglistTypes, dropdownValues } = this.state
        // if (tiers.length === 8) {
        //     const header = 'Field Error';
        //     const message = 'You Can Add Maximum of 8 Tiers'
        //     this._showTransitionalPortal(header, message)
        //     return
        // }
        const newTiers = [...tiers]
        const updatedDropdownValues = dropdownValues.concat(
            Object.keys(dropdownValues[0]).reduce((acc, header) => {
                if (header === "drug_list_type") {
                    acc[header] = druglistTypes;
                } else {
                    acc[header] = [];
                }
                return acc;
            }, {})
        );

        const obj = {
            drug_list_type: "",
            tier_name: '',
            drug_list_name: [],
            tier_level: '',
            exclude_from_export: ''
        }
        newTiers.push(obj)

        this.setState({
            tiers: newTiers,
            dropdownValues: updatedDropdownValues
        })
    }

    _showTransitionalPortal = (header, message) => {
        const { props } = this;
        const transitionalPortal = {
            header: header,
            copy: message,
        }
        props.actions.displayTransitionalPortal(transitionalPortal)
    }

    delRow = (rowIndex) => {
        const { tiers } = this.state
        const newTiers = [...tiers]
        newTiers.splice(rowIndex, 1)
        newTiers.forEach((data) => data.tier_level = '')
        this.setState({
            tiers: newTiers,
        })
    }

    componentDidMount() {
        const { actions } = this.props
        setTimeout(() => {
            actions.getDrugList({
                tier: [],
                prior_authorization: [],
                step_therapy: [],
                quantity_limit: []
            })
            actions.getPrAlternates()
            actions.getPrExclusions()
        },
            Config.COMPONENT_DATA_LOAD_TIME
        );

        if (!this.props.history.location.search) {
            this.clearFormData();
            return;
        } else {
            const payload = parseQueryString(this.props.history.location.search);
            setTimeout(
                () => actions.getCmsFormularyDashboard(payload),
                Config.COMPONENT_DATA_LOAD_TIME
            );
            this.setFormularyDataforVieworEdit()
        }

    }

    getTierLevelOptions = (selectedTierLevel) => {
        const { tiers } = this.state
        const numberOfTiersOptions = []
        for (let i = 1; i <= tiers.length; i++) {
            numberOfTiersOptions.push({
                key: i,
                value: i,
                text: i,
                disabled: selectedTierLevel.includes(i)
            })
        }
        return numberOfTiersOptions;
    }

    setFormularyDataforVieworEdit = () => {
        const { state: { dashboardData }, history } = this.props
        if (dashboardData.length > 0) {
            const payload = parseQueryString(this.props.history.location.search);
            let formulary = dashboardData.filter((record) => record.formulary_name === payload?.doc_name)[0]
            const { props, state } = this;
            const form = {
                ...state.form,
                contract_id: formulary.contract_id,
                doc_id: formulary.doc_id,
                effective_end: moment(formulary.effective_end).format('YYYY-MM-DD') || '',
                effective_start: moment(formulary.effective_start).format('YYYY-MM-DD') || '',
                formulary_name: formulary.formulary_name,
                hierarchy: formulary.hierarchy,
                lob: formulary.lob,
                pa_drug_list_id: formulary?.pa?.map((p) => p.pa_drug_list_id),
                pbp: formulary.pbp,
                pr_alt_drug_list_id: formulary.program_alternatives?.map((p) => p.pr_alt_drug_list_id),
                pr_ex_drug_list_id: formulary.program_exclusions?.map((p) => p.pr_ex_drug_list_id),
                ql_drug_list_id: formulary.ql?.map((p) => p.ql_drug_list_id),
                st_drug_list_id: formulary.st?.map((p) => p.st_drug_list_id),
            };
            let tiers = []
            for (let index = 0; index < formulary.tiers.length; index++) {
                const element = formulary.tiers[index];
                let tier = {}
                tier = { drug_list_name: element.tier_data.map((t) => t.drug_list_name), drug_list_type: element.drug_list_type, tier_name: element.tier_name, tier_level: element.tier_level, exclude_from_export: element.exclude_from_export }
                tiers.push(tier)
            }
            // const tiers = formulary?.tiers?.map((tier) => tier.doc_name)
            this.setState({
                form,
                tiers
            });
        }
    }

    // getTierLevelOptions = (selectedTierLevel) => {
    //     const { tiers } = this.state
    //     const numberOfTiersOptions = []
    //     for (let i = 1; i <= tiers.length; i++) {
    //         numberOfTiersOptions.push({
    //             key: i,
    //             value: i,
    //             text: i,
    //             disabled: selectedTierLevel.includes(i)
    //         })
    //     }
    //     return numberOfTiersOptions;
    // }

    _updateTierData = (el, dropdown, rowIndex, gridApi) => {
        const { tiers, dropdownValues } = this.state
        const { name, value } = dropdown || el.currentTarget

        const newTiers = [...tiers]
        newTiers[rowIndex][name] = value
        let updatedDropdownValues = dropdownValues
        if (name === "drug_list_type") {
            let condition_name = ""
            if (value === "DRUG_LIST") {
                condition_name = "GLOBAL_TIER"
            } else if (value === "PROGRAM") {
                condition_name = "GLOBAL_PROGRAM"
            }
            updatedDropdownValues[rowIndex]["drug_list_name"] = this.props.state.drugListData?.tier?.filter((cond) => cond.drug_list_type.toUpperCase() === condition_name).map(item => item.doc_name);
        }
        this.setState({
            tiers: newTiers,
            dropdownValues: updatedDropdownValues
        })
        gridApi.resetRowHeights()
        gridApi.refreshCells(newTiers)
    }

    handleCheckboxChange = (index, change) => {
        const { supplemental_benefits, submission_files } = this.state
        if (change === 'Supplemental') {
            const new_supplemental_benefits = [...supplemental_benefits]
            new_supplemental_benefits[index].applied = !new_supplemental_benefits[index].applied
            this.setState({ supplemental_benefits: new_supplemental_benefits })
        } else {
            const new_submission_files = [...submission_files]
            new_submission_files[index].extract = !new_submission_files[index].extract
            this.setState({ submission_files: new_submission_files })
        }

    }
    _getRowHeight = (params) => calcGridRowHeight(params.data?.drug_list_name)

    setHierarchy = ({ hierarchy }) => {
        const { form } = this.state

        this.setState({
            form: {
                ...form,
                hierarchy,
            }
        })

    }

    saveConfig = () => {
        const { form, tiers, supplemental_benefits, submission_files } = this.state
        const { actions, state: { drugListData: { tier, quantity_limit, step_therapy, prior_authorization }, prAlternatesData, prExclusionsData } } = this.props
        const params = { ...form, supplemental_benefits, submission_files, tiers }

        params.effective_start = form.effective_start ? moment(form.effective_start).format('YYYY-MM-DD') : ''
        params.effective_end = form.effective_end ? moment(form.effective_end).format('YYYY-MM-DD') : ''
        params.st = params?.st_drug_list_id?.map((drug) => {
            return ({
                st_drug_list_id: drug,
                st_drug_list_name: step_therapy.filter((tierData) => drug === tierData?.doc_id)[0]?.doc_name
            })
        })
        params.ql = params?.ql_drug_list_id?.map((drug) => {
            return ({
                ql_drug_list_id: drug,
                ql_drug_list_name: quantity_limit.filter((tierData) => drug === tierData?.doc_id)[0]?.doc_name
            })
        })
        params.pa = params?.pa_drug_list_id?.map((drug) => {
            return ({
                pa_drug_list_id: drug,
                pa_drug_list_name: prior_authorization.filter((tierData) => drug === tierData?.doc_id)[0]?.doc_name
            })
        })
        params.pr_exlusions = params?.pr_ex_drug_list_id?.map((drug) => {
            return ({
                pr_ex_drug_list_id: drug,
                pr_ex_drug_list_name: prExclusionsData.filter((tierData) => drug === tierData?.doc_id)[0]?.doc_name
            })
        })
        params.pr_alternates = params?.pr_alt_drug_list_id?.map((drug) => {
            return ({
                pr_alt_drug_list_id: drug,
                pr_alt_drug_list_name: prAlternatesData.filter((tierData) => drug === tierData?.doc_id)[0]?.doc_name

            })
        })
        const finalTiers = params?.tiers?.map((data) => {
            const obj = { ...data }
            obj.tier_data = []
            obj.exclude_from_export = obj.exclude_from_export === 'Yes'
            let lookupObj = {
                "DRUG_LIST": "global_tier",
                "PROGRAM": "global_program"
            }
            obj?.drug_list_name?.forEach((drug) => {
                let record_data = tier.filter((tierData) => (drug === tierData?.doc_name) && (lookupObj[obj.drug_list_type] === tierData?.drug_list_type))
                obj.tier_data.push({
                    drug_list_id: record_data[0]?.doc_id,
                    drug_list_type: record_data[0]?.drug_list_type,
                    doc_version: record_data[0]?.doc_version,
                    drug_list_name: drug,
                })
            })
            console.log('finalTiersobj')
            console.log(obj)
            delete obj.drug_list_name
            return (obj)
        })
        params.tiers = finalTiers
        delete params.pa_drug_list_id
        delete params.ql_drug_list_id
        delete params.st_drug_list_id
        actions.saveCmsConfig(params)
    }

    render() {
        const { form, tiers, supplemental_benefits, submission_files } = this.state

        const { state: { carriers, userCAG, drugListData, groupConfigData, prAlternatesData, prExclusionsData, dashboardData } } = this.props
        const dropdownOptions = this._constructDropdownOptions();
        //this.setFormularyDataforVieworEdit(dashboardData)
        const headers = ['drug_list_type', 'drug_list_name', 'tier_name', 'tier_level', 'exclude_from_export', 'action']
        const selectedTierLevel = tiers?.map((data) => data.tier_level)
        const CellRendererParams = {
            action: {
                cellRenderer: DeleteRowRenderer,
                state: {
                    onClick: this.delRow,
                },
                width: 95,
            },
            drug_list_name: {
                cellRenderer: MultiRowRenderer,
                width: 800,
            }
        }

        const supplementalBenefits = supplemental_benefits.map((benefit, index) => {
            return (<div className='checkbox-container' >
                <FliptCheckbox
                    value={benefit.applied}
                    checked={benefit.applied}
                    onChange={() => this.handleCheckboxChange(index, 'Supplemental')}
                />
                <FliptLabel
                    description={benefit.benefit_name}
                    label={benefit.benefit_name}
                    labelClass='checkbox-label'
                />
            </div>)
        })
        const submissionFiles = submission_files.map((submission, index) => {
            return (<div className='checkbox-container' >
                <FliptCheckbox
                    value={submission.extract}
                    checked={submission.extract}
                    onChange={() => this.handleCheckboxChange(index)}
                />
                <FliptLabel
                    description={submission.file_name}
                    label={submission.file_name}
                    labelClass='checkbox-label'
                />
            </div>)
        })
        const CellEditorParams = {
            drug_list_type: {
                editable: true,
                cellEditor: DropdownEditor,
                onChange: this._updateTierData,
                width: "20%"
            },
            drug_list_name: {
                editable: true,
                cellEditor: DropdownEditor,
                onChange: this._updateTierData,
                width: "35%"
            },
            tier_name: {
                editable: true,
                cellEditor: InputTextEditor,
                onChange: this._updateTierData,
                width: "35%"
            },
            tier_level: {
                editable: true,
                cellEditor: DropdownEditor,
                onChange: this._updateTierData,
                width: "5%"
            },
            exclude_from_export: {
                editable: true,
                cellEditor: DropdownEditor,
                onChange: this._updateTierData,
                width: "5%"
            },
        }
        const filterOptions = getCAGOptions(carriers, form.carrier, form.account)
        const clientOptions = userCAG?.client?.map((client, index) => {
            return ({
                key: index,
                value: client,
                text: client
            })
        })
        rowCellInfo.tier_level.options = this.getTierLevelOptions(selectedTierLevel)
        // rowCellInfo.drug_list_name.options = drugListData?.tier?.map((drug, index) => {
        //     return ({
        //         key: index,
        //         value: drug.doc_name,
        //         text: `${drug.doc_name}||${drug.drug_list_type.toUpperCase()}`
        //     })
        // })
        // rowCellInfo.drug_list_type.options = [
        //     {
        //         key: "DRUG_LIST",
        //         value: "DRUG_LIST",
        //         text: "DRUG_LIST"
        //     },
        //     {
        //         key: "PROGRAM",
        //         value: "PROGRAM",
        //         text: "PROGRAM"
        //     },
        // ]
        const slDrugOptions = drugListData?.step_therapy?.map((drug, index) => {
            return ({
                key: index,
                value: drug.doc_id,
                text: drug.doc_name
            })
        })

        const qlDrugOptions = drugListData?.quantity_limit?.map((drug, index) => {
            return ({
                key: index,
                value: drug.doc_id,
                text: drug.doc_name
            })
        })
        const paDrugOptions = drugListData?.prior_authorization?.map((drug, index) => {
            return ({
                key: index,
                value: drug.doc_id,
                text: drug.doc_name
            })
        })
        const prAlts = prAlternatesData?.map((drug, index) => {
            return ({
                key: index,
                value: drug.doc_id,
                text: drug.doc_name.toUpperCase()
            })
        })
        const prExclusions = prExclusionsData?.map((drug, index) => {
            return ({
                key: index,
                value: drug.doc_id,
                text: drug.doc_name.toUpperCase()
            })
        })

        const showPrExclusions = () => {
            if (prExclusions.length > 0) {
                return false
            }
            return true
        }

        const showPrAlternates = () => {
            if (prAlts.length > 0) {
                return false
            }
            return true
        }

        let editMode = true;

        if ("state" in this.props.history.location) {
            editMode =
                this.props.history.location.state &&
                    "editMode" in this.props.history.location.state
                    ? this.props.history.location.state.editMode
                    : true;

            if (parseQueryString(this.props.history.location.search).editMode === "false")
                editMode = false;
        }

        return (
            <div id="medD-formulary-builder">
                <BreadCrumb {...this.props} />
                <div className="header">Formulary Export Builder</div>
                <div className='content'>
                    <div className="section">
                        <div>
                            <FliptInput label='Formulary Name' value={form.formulary_name} name='formulary_name' onChange={this._updateField} stylized />
                        </div>
                        <div>
                            <FliptDatepicker placeholder='MM-DD-YYYY' labelText='Start Date' name='effective_start' value={convertStrToDateObj(form?.effective_start)} onChange={this._updateField} stylized />
                        </div>
                        <div>
                            <FliptDropdown placeholder="Select LOB" options={lobTypes} label='LOB' value={form.lob} name='lob' onChange={this._updateField} stylized />
                        </div>
                        <div>
                            <FliptDatepicker placeholder='MM-DD-YYYY' labelText='End Date' name='effective_end' value={convertStrToDateObj(form?.effective_end)} onChange={this._updateField} stylized />
                        </div>
                        {/* <FliptDropdown placeholder="Select Group" label="Group" name="group" value={form.group} options={filterOptions.groupOptions} onChange={this._updateField} stylized /> */}
                        {/* 
                            <FliptDropdown placeholder="Select Client" options={clientOptions} label='Client' name='client' value={form.client} onChange={this._updateField} stylized />
                            <FliptInput label='Contract ID' value={form.contract_id} name='contract_id' disabled stylized /> 
                            <FliptDropdown placeholder="Select Carrier" label="Carrier" name="carrier" value={form.carrier} options={filterOptions.carrierOptions} onChange={this._updateField} stylized />
                            <FliptInput label='PBP' value={form.pbp} name='pbp' disabled stylized />
                        */}
                    </div>
                    <div className='section'>
                        <div className='inner-header'>Basic Formulary Information</div>
                        <FliptHierarchyDropdown showEnhancedTier={false} showRxcui={false} setForm={this.setHierarchy} form={form} showHierarchyGlobal={true} />
                    </div>
                    <div className="section">
                        <div className="row">
                            <div className="inner-header">Define Drug List</div>
                        </div>
                        <div className="row grid-container">
                            <FliptGrid
                                cellEditorParams={CellEditorParams}
                                cellRendererParams={CellRendererParams}
                                data={tiers}
                                dropdownOptions={dropdownOptions}
                                getRowHeight={this._getRowHeight}
                                headers={headers}
                                rowCellInfo={rowCellInfo}
                            />

                            <div>
                                <FliptButton name='Add Condition' className='primary' onClick={() => this.addGridRow()} />
                            </div>
                        </div>
                    </div>

                    <div className="section">
                        <div className="row">
                            <div>
                                <div className='inner-header'>Define Prior Authorization Drug List</div>
                                <div className='field-container'>
                                    <FliptDropdown className="create-formulary-tier-list" multiple={true} selection options={paDrugOptions}
                                        simple item label='Prior Authorization Drug List' value={form.pa_drug_list_id}
                                        name='pa_drug_list_id' onChange={this._updateField} stylized
                                    />
                                </div>
                            </div>
                            <div>
                                <div className='inner-header'>Define Quantity Limit Drug List</div>
                                <div className='field-container'>
                                    <FliptDropdown
                                        className="create-formulary-tier-list"
                                        multiple={true} selection options={qlDrugOptions}
                                        name='ql_drug_list_id'
                                        onChange={this._updateField} stylized
                                        simple item label='Quantity Limit Drug List'
                                        value={form.ql_drug_list_id}
                                    />
                                </div>
                            </div>
                            <div>
                                <div className='inner-header'>Define Step Therapy Drug List</div>
                                <div className='field-container'>
                                    <FliptDropdown
                                        className="create-formulary-tier-list"
                                        item
                                        label='Step Therapy Drug List'
                                        multiple={true}
                                        onChange={this._updateField} stylized
                                        options={slDrugOptions}
                                        selection
                                        simple
                                        value={form.st_drug_list_id} name='st_drug_list_id'
                                    />
                                </div>
                            </div>
                            <div>
                                <div hidden={showPrExclusions()}>
                                    <div className='inner-header' hidden={showPrExclusions()}>Define Program Exclusions</div>
                                    <div className='field-container' hidden={showPrExclusions()}>
                                        <FliptDropdown
                                            className="create-formulary-tier-list"
                                            multiple={true}
                                            selection
                                            options={prExclusions}
                                            item
                                            label='Program Exclusions'
                                            name='pr_ex_drug_list_id'
                                            onChange={this._updateField} stylized
                                            simple
                                            value={form.pr_ex_drug_list_id}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div hidden={showPrAlternates()}>
                                    <div className='inner-header' hidden={showPrAlternates()}>Define Program Alternatives</div>
                                    <div className='field-container' hidden={showPrAlternates()}>
                                        <FliptDropdown
                                            className="create-formulary-tier-list"
                                            item
                                            label='Program Alternatives'
                                            multiple={true}
                                            name='pr_alt_drug_list_id'
                                            onChange={this._updateField}
                                            options={prAlts}
                                            selection
                                            simple
                                            stylized
                                            value={form.pr_alt_drug_list_id}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{ display: "none" }}>
                            <div>
                                <div className='inner-header'>Medicare File Generation Criteria</div>
                                <fieldset disabled={!this.state.file_generation_criterias.includes(form.lob)}>
                                    <div className='checkbox-outer-container'>
                                        <div className='checkbox-inner-container'>
                                            <div className='checkbox-header'>
                                                Define Submission Files {submissionFiles}
                                            </div>
                                        </div>
                                        <div className='checkbox-inner-container'>
                                            <div className='checkbox-header'>
                                                Supplemental Benefits or Alternative Models
                                                {supplementalBenefits}
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </div>

                    <div className='section'>
                        <FliptButton name='Save Details' className='primary' onClick={() => this.saveConfig()} />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    state: {
        app: state.app,
        carriers: state?.user?.carriers && state?.user?.carriers?.length > 0 ? state.user.carriers : [],
        dashboardData: state.cmsFormualry.dashboardData,
        drugListData: state.cmsFormualry.drugListData,
        groupConfigData: state.cmsFormualry.groupConfigData,
        prAlternatesData: state.cmsFormualry.prAlternatesData,
        prExclusionsData: state.cmsFormualry.prExclusionsData,
        userCAG: state?.user?.userCAG
    },
})

const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...AppActions,
        ...formularyBuilderActions
    }

    return {
        actions: bindActionCreators(allActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormularyBuilder)
