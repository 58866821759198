import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import './styles.scss'
import FliptButton from '../../components/form/fliptButton'
import statesJSON from '../../config/constants/states'
import FliptGrid from '../fliptGrid'
import FliptPaInput from '../form/fliptPaInput'
import FliptPaDropdown from '../form/fliptPaDropdown'
import { Creators as PharmacyLookupActions } from '../../redux/reducers/api/pharmacyLookup'
import { Creators as AppActions } from '../../redux/reducers/app'

class FliptMultiPharmacySearch extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pharmacyData: props.pharmacyData,
            pharmacyList: [],
            searchForm: {
                date_of_birth: '',
                first_name: '',
                last_name: '',
                memberId: '',
            },
        }
        this.agGridRefPh = React.createRef()
    }

    _clearPharmacyFields = () => {
        this.setState({
            searchForm: {
                pharmacyNpi: '',
                pharmacyState: '',
                pharmacyCity: '',
                pharmacyName: '',
                pharmacyzip1: ''
            },
            pharmacyList: []
        })
    }

    componentDidUpdate(prevProps) {
        const { props } = this
        const { state: { pharmacyLookupData } } = props
        if (prevProps.state.pharmacyLookupData !== pharmacyLookupData && pharmacyLookupData.length) {
            this.setState({ pharmacyList: pharmacyLookupData })
        }
    }

    onSelectionChanged = (data) => {
        const { updatePharmacyData } = this.props
        const { pharmacyData } = this.state
        const selectedRows = data.api.getSelectedRows();
        if (selectedRows.length) {
            let filteredPharmacyData = [...selectedRows, ...pharmacyData]
            filteredPharmacyData = [...new Map(filteredPharmacyData.map((m) => [m.pharmacynpi, m])).values()];
            updatePharmacyData(filteredPharmacyData)
            this.setState({ pharmacyData: filteredPharmacyData })
        }
    }

    _updateSearchForm = (el, dropdown) => {
        const { searchForm } = this.state
        const { name, value } = dropdown || el.currentTarget
        searchForm[name] = value
        this.setState({ searchForm, pharmacyList: null })
    }

    _searchData() {
        const { searchForm } = this.state
        const { actions } = this.props
        if (!searchForm.pharmacyNpi && !searchForm.pharmacyName && !searchForm.pharmacyCity
            && !searchForm.pharmacyState) {
            const transitionalPortal = {
                header: 'Fields are missing',
                copy: 'Please add NPI, Name, City or State to search Pharmacy detail',
            }
            actions.displayTransitionalPortal(transitionalPortal)
            return;
        }
        this.setState({ pharmacyList: [] })
        let form = {
            pharmacytype: 'RETAIL',
            networkStatus: 'ACTIVE'
        }
        if (searchForm.pharmacyNpi) form.pharmacynpi = searchForm.pharmacyNpi;
        if (searchForm.pharmacyState) form.pharmacystate = searchForm.pharmacyState;
        if (searchForm.pharmacyName) form.pharmacyname = searchForm.pharmacyName.toUpperCase();
        if (searchForm.pharmacyCity) form.pharmacycity = searchForm.pharmacyCity.toUpperCase();
        if (searchForm.pharmacyzip1) form.pharmacyzip1 = searchForm.pharmacyzip1;
        actions.setPharmacyLookupFailed(false)
        actions.getPharmacyLookupData({ form, isTestClaim: true })
    }

    _submitResult = () => {
        const { actions } = this.props
        actions.toggleModal()
    }

    render() {
        const { editMode, isPa } = this.props
        const { searchForm, pharmacyList, pharmacyData } = this.state
        const statesData = Object.entries(statesJSON).map(([k, v]) => ({
            key: k,
            text: `${k} - ${v}`,
            value: k,
        }))
        const headers = [
            'Select', 'pharmacynpi', 'pharmacyname', 'pharmacytype', 'network_status', 'pharmacyphone', 'pharmacyaddress1', 'pharmacyaddress2',
            'pharmacycity', 'pharmacystate', 'pharmacyzip1', 'chaincode'
        ]

        const SelectedHeaders = ['pharmacynpi', 'pharmacyname', 'pharmacytype', 'network_status', 'pharmacyphone', 'pharmacyaddress1', 'pharmacyaddress2',
            'pharmacycity', 'pharmacystate', 'pharmacyzip1', 'chaincode'
        ]

        const cellRendererParams = {
            Select: {
                checkboxSelection: true,
                width: '120px'
            },
            pharmacyname: {
                overrideHeader: 'Name',
            },
            pharmacytype: {
                overrideHeader: 'Type',
            },
            pharmacynpi: {
                overrideHeader: 'NPI',
            },
            pharmacyaddress1: {
                overrideHeader: 'Address 1',
            },
            pharmacyaddress2: {
                overrideHeader: 'Address 2',
            },
            pharmacycity: {
                overrideHeader: 'City',
            },
            pharmacystate: {
                overrideHeader: 'State',
            },
            pharmacyzip1: {
                overrideHeader: 'Zip',
            },
            pharmacyphone: {
                overrideHeader: 'Phone',
            },
        }

        return (
            <div className="multi-pharmacy-section">
                <div className="pharmacy-header">
                    Find Pharmacy
                </div>
                <div className="inner-container">
                    <div className="fields-container">
                        <div className='fields-inner-container'>
                            <FliptPaInput disabled={!editMode} value={searchForm.pharmacyNpi} stylized name="pharmacyNpi"
                                onChange={this._updateSearchForm} placeholder="Enter" label="NPI" description="NPI" />
                            <div className='pa-field'>
                                <FliptPaInput value={searchForm.pharmacyCity} stylized name="pharmacyCity"
                                    onChange={this._updateSearchForm} placeholder="Enter" label="Pharmacy City" />
                            </div>
                        </div>
                        <div className='fields-inner-container'>
                            <FliptPaInput disabled={!editMode} value={searchForm.pharmacyName} stylized name="pharmacyName"
                                onChange={this._updateSearchForm} placeholder="Enter" label="Pharmacy Name" />
                        </div>
                        <div className='fields-inner-container'>
                            <FliptPaDropdown search label="State" name="pharmacyState" value={searchForm.pharmacyState}
                                options={statesData} stylized onChange={this._updateSearchForm} />
                        </div>
                    </div>
                    <div className="pharmacy-buttons-container">
                        <FliptButton className='primary' name='SEARCH PHARMACY' onClick={() => this._searchData()} />
                        <FliptButton name="Clear" className="secondary" onClick={this._clearPharmacyFields} />
                    </div>
                </div>
                {pharmacyList?.length ?
                    <>
                        <div className="pharmacy-header">
                            Search Results
                        </div>
                        <div className="grid-container">
                            <FliptGrid
                                headers={headers}
                                cellRendererParams={cellRendererParams}
                                data={pharmacyList}
                                rowSelection='multiple'
                                onSelectionChanged={(data) => { this.onSelectionChanged(data) }}
                            />
                        </div></> : <></>}

                {pharmacyData?.length ?
                    <>
                        <div className="pharmacy-header">
                            Selected Results
                        </div>
                        <div className="grid-container">
                            <FliptGrid
                                headers={SelectedHeaders}
                                data={pharmacyData}
                            />
                        </div></> : <></>}
                <div className="pharmacy-buttons-container">
                    <FliptButton className='primary' name='SUBMIT' onClick={() => this._submitResult()} />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    state: {
        pharmacyLookupData: state.pharmacyLookup.pharmacyLookupData,
    },
})

const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...PharmacyLookupActions,
        ...AppActions
    }
    return {
        actions: bindActionCreators(allActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FliptMultiPharmacySearch)
