import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import './styles.scss'
import FliptGrid from "../../../../components/fliptGrid"
import BreadCrumb from "../../../../components/breadCrumb"
import { headers } from "./data"



const EligibilityLogs = (props) => {
    const { memberLogs } = props.state
    return (
        <div id="eligibilityLogs">
            <BreadCrumb {...props} />
            <div className="grid-container">
                <FliptGrid
                    headers={headers}
                    data={memberLogs}
                />
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    state: {
        memberLogs: state.memberLookup.memberLogs,
    },
})

const mapDispatchToProps = (dispatch) => {
    const allActions = {
    }

    return {
        actions: bindActionCreators(allActions, dispatch),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EligibilityLogs)