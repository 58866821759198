import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import './style.scss'
import FliptButton from '../../../components/form/fliptButton'
import FliptForm from '../../../components/form/fliptForm'
import FliptInput from '../../../components/form/fliptInput'
import { Creators as UserActions } from '../../../redux/reducers/user'

class ChangePassword extends Component {
  constructor(props) {
    super(props)

    this.state = {
      form: {
        new_password: '',
        confirm_password: '',
      },
    }
  }

  _changePassword = () => {
    const { form } = this.state
    const { actions } = this.props

    actions.changePassword(form)
  }

  _updateFields = (el) => {
    const { name, value } = el.currentTarget

    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [name]: value,
      },
    }))
  }

  render() {
    return (
      <div id="changePassword" className="content-container">
        <div className="left">
          {/* { state?.app?.companyCSSFilename ? <img src={`/i/${state?.app?.companyCSSFilename}/logo-login.png`} alt="Alternate Image" /> :<img alt="Flipt FAST Self Service Application" src="/i/flipt-logo-login.png" /> } */}
          <div className="logo-container">
            <div className="logo-login" />
            <div className="logo-login-default" />
          </div>
        </div>
        <div className="right">
          <div className="header-text">Create a new Password</div>
          <div className="content-text">Please enter a new password.</div>
          <FliptForm id="changePasswordForm">
            <FliptInput
              name="new_password"
              onChange={this._updateFields}
              placeholder="New Password"
              required
              type="password"
              validation="password"
            />
            <FliptInput
              name="confirm_password"
              onChange={this._updateFields}
              placeholder="Re-Enter Password"
              required
              type="password"
              validation="password"
            />
            <FliptButton
              className="primary"
              name="Change Password"
              onClick={this._changePassword}
            />
          </FliptForm>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => (
  {
    state: {
      version: state.app.version,
    },
  }
)
const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...UserActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword)
