import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import './styles.scss'
import Config from '../../../config'
import BreadCrumb from '../../../components/breadCrumb'
import ClaimTable from '../../../components/fliptGrid'
import FliptButton from '../../../components/form/fliptButton'
import FliptDatePicker from '../../../components/form/fliptDatePicker'
import FliptDropdown from '../../../components/form/fliptDropdown'
import FliptInput from '../../../components/form/fliptInput'
import { ColorCodedStatusRenderer, LinkContainerRenderer, PHILoader } from '../../../components/fliptGrid/cellRenderers'
import { Creators as ApiActions } from '../../../redux/reducers/api/claimLookupManagement'
import { Creators as AppActions } from '../../../redux/reducers/app'

import {
  createQueryString, parseQueryString, convertStrToDateObj,
} from '../../../utils/utilities'
import moment from 'moment'

class ClaimLookupManagement extends Component {
  constructor(props) {
    super(props)

    this.state = {
      form: {
        domain_name: '',
        auth_id: '',
        member_id: '',
        member_name: '',
        start_date: '',
        end_date: '',
        first_name: '',
        last_name: '',
        npi: '',
        prescription_reference_number: '',
        claim_status_filter: '',
      },
      reRender: 0,
      agGridRef: React.createRef(),
    }
  }

  componentDidMount() {
    const { history } = this.props
    const { location } = history

    if (!location) return
    const { form, agGridRef } = this.state

    this._qsToFormData()
    history.location.search = createQueryString(form)

    agGridRef.current.api.refreshServerSideStore({ route: null, purge: true }) // to refresh the grid server side data
  }

  _qsToFormData = () => {
    const { history } = this.props
    const { form } = this.state
    const qs = parseQueryString(history.location.search)

    Object.keys(qs).forEach((key) => {
      form[key] = qs[key]
    })
    this.setState({ form })
  }

  handleClick = () => {
    const { form, agGridRef } = this.state
    const { history, actions } = this.props
    if (form.first_name && !form.last_name) {
      const transitionalPortal = {
        header: 'Fields are missing',
        copy: 'Please add Last Name to search member by name',
      }
      actions.displayTransitionalPortal(transitionalPortal)
      return;
    }
    if (!form.first_name && form.last_name) {
      const transitionalPortal = {
        header: 'Fields are missing',
        copy: 'Please add First Name to search member by name',
      }
      actions.displayTransitionalPortal(transitionalPortal)
      return;
    }
    let { auth_id } = form;
    // 13 is the maximum length of auth_id.
    const auth_id_length = 13 - auth_id.length;

    if (auth_id.length > 0 && auth_id.length < 13) {
      for (let i = 0; i < auth_id_length; i++) {
        auth_id = '0' + auth_id;
      }
    } else if (auth_id_length < 0) {
      auth_id = auth_id.substring(auth_id_length * -1);
    }
    form['auth_id'] = auth_id;
    this.setState({ form })
    history.location.search = createQueryString(form)
    agGridRef.current.api.refreshServerSideStore({ route: null, purge: true }) // to refresh the grid server side data
  }

  handleClear = () => {
    const { form, reRender = 0, agGridRef } = this.state
    Object.keys(form).forEach((key) => {
      form[key] = ''
    })
    this.setState({ form, reRender: reRender + 1 })

    const { history } = this.props
    // this._qsToFormData()
    // history.location.search = createQueryString(form)

    agGridRef.current.api.refreshServerSideStore({ route: null, purge: true })
  }

  updateField = (el, dropdown) => {
    const { form } = this.state
    const { name, value } = dropdown || el.currentTarget

    form[name] = value
    this.setState({ form })
  }

  render() {
    const { actions } = this.props
    const headers = [
      'action', 'auth_id', 'processed_date', 'date_of_service', 'cardholder_id', 'group_id', 'transaction_response_status',
      'claim_status', 'payer_pcn', 'sequenceNumber', 'first_name', 'last_name', 'date_of_birth', 'plan_name', 'rx_number', 'drug_name', 'product_id', 'drug_strength',
      'drug_dosage_form', 'submitted_quantity', 'days_supply', 'multi_source', 'drug_mfg', 'pharmacy_name', 'otc', 'reject_message', 'calculated_drug_cost', 'patient_pay_amount',
      'service_provider_id', 'is_vip',
    ]

    const headerMapping = {
      drug_dosage: 'drug_dosage_form',
      drug_manufacturer: 'drug_mfg',
      otc_indicator: 'otc',
      prescription_reference_number: 'rx_number',
      quantity_dispensed: 'submitted_quantity',
      startDate: 'processed_date',
    }
    const { state: { user, claimsPerPage } } = this.props
    const { form, agGridRef } = this.state
    const domainOptions = user.accounts_access.map((d) => ({
      key: d.id,
      text: d.id,
      value: d.id,
    }))

    const filterParams = {
      /* eslint-disable-next-line */
      comparator: (filterLocalDateAtMidnight, cellValue) => {
        const dateAsString = cellValue
        if (dateAsString == null) return -1
        const dateParts = dateAsString.split(' ')[0].split('/')
        const cellDate = new Date(
          Number(dateParts[2]),
          Number(dateParts[0]) - 1,
          Number(dateParts[1]),
        )
        if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
          return 0
        }

        if (cellDate < filterLocalDateAtMidnight) {
          return -1
        }

        if (cellDate > filterLocalDateAtMidnight) {
          return 1
        }
      },
      browserDatePicker: true,
      filterOptions: ['inRange', 'equals'],
      defaultJoinOperator: 'OR',
      suppressAndOrCondition: false,
      inRangeInclusive: true,
    }

    const cellRendererParams = {
      action: {
        cellRenderer: LinkContainerRenderer,
        searchArgs: [
          {
            path: '/claim-detail',
            searchArgs: { auth_id: 'auth_id' },
            label: 'Claim Details',
          },
          {
            path: '/member-lookup',
            searchArgs: { member_id: 'cardholder_id' },
            label: 'Member',
          },
        ],
        width: 200,
        minWidth: 200,
        maxWidth: 200,
        filter: false
      },
      first_name: {
        cellRenderer: PHILoader,
        filter: false
      },
      last_name: {
        cellRenderer: PHILoader,
        filter: false
      },
      date_of_birth: {
        cellRenderer: PHILoader,
        filter: false
      },
      plan_name: {
        cellRenderer: PHILoader,
        filterParams: {
          filterOptions: ['contains', 'equals'],
          defaultJoinOperator: 'OR',
        },
      },
      transaction_response_status: {
        cellRenderer: ColorCodedStatusRenderer,
        maxWidth: 100,
        filterParams: {
          filterOptions: ['equals'],
          buttons: ['reset'],
          defaultJoinOperator: 'OR',
        },
      },
      claim_status: {
        filterParams: {
          filterOptions: ['equals'],
          buttons: ['reset'],
          defaultJoinOperator: 'OR',
        },
      },
      processed_date: {
        menuTabs: ['generalMenuTab', 'columnsMenuTab'],
        filter: 'agDateColumnFilter',
        filterParams: filterParams,
      },
      date_of_service: {
        filter: 'agDateColumnFilter',
        filterParams: {
          filterOptions: ['inRange', 'equals'],
          suppressAndOrCondition: true,
        },
      },
      cardholder_id: {
        cellClassRules: {
          'vip-highlight': (params) => {
            return params?.data?.is_vip ?? false
          },
        },
        filterParams: {
          buttons: ['reset'],
          filterOptions: ['contains', 'equals'],
          defaultJoinOperator: 'OR',
        },
      },
      service_provider_id: {
        overrideHeader: 'NPI',
        filterParams: {
          buttons: ['reset'],
          defaultJoinOperator: 'OR',
        },
      },
      sequenceNumber: {
        overrideHeader: 'Sequence Number',
        maxWidth: 100,
        filterParams: {
          buttons: ['reset'],
          filterOptions: ['equals'],
          defaultJoinOperator: 'OR',
        },
      },
      product_id: {
        overrideHeader: 'NDC',
      },
      drug_strength: {
        maxWidth: 150,
        filterParams: {
          buttons: ['reset'],
          defaultJoinOperator: 'OR',
        },
      },
      submitted_quantity: {
        maxWidth: 150,
        filter: 'agNumberColumnFilter',
        filterParams: {
          buttons: ['reset'],
          filterOptions: ['equals', 'inRange'],
          defaultJoinOperator: 'OR',
        },
      },
      reject_message: {
        maxWidth: 200,
        filterParams: {
          buttons: ['reset'],
          defaultJoinOperator: 'OR',
        },
      },
      multi_source: {
        maxWidth: 125,
        filterParams: {
          buttons: ['reset'],
          defaultJoinOperator: 'OR',
        },
      },
      otc: {
        filterParams: {
          buttons: ['reset'],
          filterOptions: ['equals'],
          defaultJoinOperator: 'OR',
        },
      },
      calculated_drug_cost: {
        filterParams: {
          buttons: ['reset'],
          filterOptions: ['equals'],
          defaultJoinOperator: 'OR',
        },
      },
      patient_pay_amount: {
        maxWidth: 150,
        filterParams: {
          buttons: ['reset'],
          filterOptions: ['equals'],
          defaultJoinOperator: 'OR',
        },
      },
      is_vip: {
        hide: true,
        filter: false,
      }
    }

    const serverSideGridConfig = {
      rowModel: 'serverSide',
      serverSideStoreType: 'partial',
      cacheBlockSize: claimsPerPage,
      pagination: true,
      paginationPageSize: claimsPerPage,
    }
    const serverSideGridParams = {
      form,
      apiId: 'claim-lookup-management',
      sagaToCall: actions.getClaimLookupManagementData,
      headers,
      headerMapping,
    }
    const listTypeOptions = ['--', 'X', 'R', 'P', 'All'].reverse().map((val, idx) => ({
      key: idx,
      text: val,
      value: val.toUpperCase(),
    }))
    return (
      <div id="claimLookupManagement">
        <BreadCrumb {...this.props} />
        <div className="header">
          <FliptButton name="Advanced Search" className="invisible" />
        </div>
        <div className="subhead">
          <FliptDropdown value={form.domain_name} options={domainOptions} simple item name="domain_name" onChange={this.updateField} className="overlap-value" />
          <FliptInput value={form.auth_id} placeholder="Auth ID" name="auth_id" onChange={this.updateField} />
          <FliptInput value={form.member_id} placeholder="Member ID" name="member_id" onChange={this.updateField} />
          <FliptInput value={form.first_name} placeholder="First Name" name="first_name" onChange={this.updateField} />
          <FliptInput value={form.last_name} placeholder="Last Name" name="last_name" onChange={this.updateField} />
          <FliptInput value={form.npi} placeholder="NPI" name="npi" onChange={this.updateField} />
          <FliptInput placeholder="Rx Number" name="prescription_reference_number" value={form.prescription_reference_number} onChange={this.updateField} />
          <FliptDatePicker placeholder="Start Date" name="start_date" onChange={this.updateField} value={convertStrToDateObj(form.start_date)} className="overlap-index" />
          <FliptDatePicker placeholder="End Date" name="end_date" onChange={this.updateField} value={convertStrToDateObj(form.end_date)} />
          <FliptDropdown
            className="create-formulary-tier-list"
            placeholder="Claims Status Filter"
            value={form.claim_status_filter}
            selection
            options={listTypeOptions}
            simple
            item
            name="claim_status_filter"
            onChange={this.updateField}
          />

          <FliptButton name="Search" className="primary searchButton" onClick={this.handleClick} />
          <FliptButton name="Clear" className="primary searchButton" onClick={this.handleClear} />
        </div>
        <div className="content">
          <ClaimTable
            headers={headers}
            cellRendererParams={cellRendererParams}
            serverSideGridConfig={serverSideGridConfig}
            serverSideGridParams={serverSideGridParams}
            agGridRef={agGridRef}
            filterOptions={['contains', 'equals']}
            doAutoSize
            suppressColumnVirtualisation
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  state: {
    data: state.claimLookup.data,
    totalRows: state.claimLookup.totalRows,
    claimsPerPage: state.claimLookup.claimsPerPage,
    user: state.user,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...ApiActions,
    ...AppActions
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClaimLookupManagement)
