import {
  call,
  put,
  select,
  takeLatest,
  take,
  takeEvery,
} from 'redux-saga/effects'
import { v4 as uuidv4 } from 'uuid'
import { Creators as AppActions, Types as AppTypes } from '../../reducers/app'
import { Creators as NavigationActions } from '../../reducers/navigation'
import { Creators as RPMActions } from '../../reducers/rpm'
import { Creators as UserActions, Types as UserTypes } from '../../reducers/user'
import { Types, Creators as PriorAuthorizationCreationActions } from '../../reducers/api/priorAuthorizationManagement'
import { Creators as UserManagementActions } from '../../reducers/userManagement'
import { fetchGet, fetchPost, fileUploadPost } from '../../../utils/fetchApi'
import SystemErrorModal from '../../../utils/systemError'
import {
  getAppState,
  getApiPath,
  getHistory,
  getUserManagementData,
  getUserState,
  isMenuEmpty,
  isUserEmpty,
  getPriorAuthorizationData
} from '../../reducers/selectors'
import * as Constants from '../../../pages/private/intakeQueue/constants'
import { isEmpty } from 'lodash'
import { updateCriteriaIdsHandler } from './qualifiers'
import * as ApprovalsConstants from '../rpm/constants'
import { convertDateToStrObj, filterRowData } from '../../../utils/utilities'
import { deleteUnnecessaryFields } from '../../../utils/utilizationManagement'

export default [
  addPaOverrideWatcher,
  addPrescriberWatcher,
  approvePriorAuthorizationWatcher,
  assignToMeWatcher,
  checkDuplicateWatcher,
  createNoteWatcher,
  createPaIntakeQueueWatcher,
  diagnosisLookupWatcher,
  generatePriorAuthorizationWatcher,
  getallPriorAuthorizationDataWatcher,
  getAnswerWatcher,
  getAttachmentWatcher,
  getCommunicationWatcher,
  getDownloadDocumentWatcher,
  getFinalDocumentsWatcher,
  getMessageTemplateWatcher,
  getMsgXmlWatcher,
  getNotesWatcher,
  getPaCaseDetailsWatcher,
  getPaDashboardDetailsWatcher,
  getPaDashboardReportsWatcher,
  getPaIntakeQueueWatcher,
  getPriorAuthorizationDataWatcher,
  getPriorAuthorizationTemplatesWatcher,
  getQuestionnaireWatcher,
  getRxTabDataWatcher,
  mergeDocumentWatcher,
  reassignPACaseWatcher,
  saveAnswerWatcher,
  saveAttachmentWatcher,
  savePriorAuthorizationDataWatcher,
  searchCaseWatcher,
  sendOutboundCommunicationWatcher,
  updatePaCaseWatcher,
  updatePrescriberWatcher,
  uploadPaDocumentTemplateWatcher,
  generatePriorAuthorizationLevelExportWatcher,
  viewAttachmentWatcher,
]

/* WATCHERS */
function* getPriorAuthorizationTemplatesWatcher() {
  yield takeLatest(Types.GET_PRIOR_AUTHORIZATION_TEMPLATES, getPriorAuthorizationTemplatesHandler)
}

function* assignToMeWatcher() {
  yield takeLatest(Types.ASSIGN_TO_ME, assignToMeHandler)
}

function* reassignPACaseWatcher() {
  yield takeLatest(Types.REASSIGN_PA_CASE, reassignPACaseHandler)
}

function* getAnswerWatcher() {
  yield takeLatest(Types.GET_ANSWER, getAnswerHandler)
}

function* diagnosisLookupWatcher() {
  yield takeLatest(Types.DIAGNOSIS_LOOKUP, diagnosisLookupHandler)
}

function* getMessageTemplateWatcher() {
  yield takeLatest(Types.GET_MESSAGE_TEMPLATE, getMessageTemplateHandler)
}

function* getFinalDocumentsWatcher() {
  yield takeLatest(Types.GET_FINAL_DOCUMENTS, getFinalDocumentsHandler)
}

function* saveAnswerWatcher() {
  yield takeLatest(Types.SAVE_ANSWER, saveAnswerHandler)
}

function* addPrescriberWatcher() {
  yield takeLatest(Types.ADD_PRESCRIBER, addPrescriberHandler)
}

function* getQuestionnaireWatcher() {
  yield takeLatest(Types.GET_QUESTIONNAIRE, getQuestionnaireHandler)
}

function* getMsgXmlWatcher() {
  yield takeLatest(Types.GET_MESSAGE_XML, getMsgXmlHandler)
}
function* updatePrescriberWatcher() {
  yield takeLatest(Types.UPDATE_PRESCRIBER, updatePrescriberHandler)
}


function* getRxTabDataWatcher() {
  yield takeLatest(Types.GET_RX_TAB_DATA, getRxTabDataHandler)
}

function* updatePaCaseWatcher() {
  yield takeLatest(Types.UPDATE_PA_CASE, updatePaCaseHandler)
}

function* getPaCaseDetailsWatcher() {
  yield takeLatest(Types.GET_PA_CASE_DETAILS, getPaCaseDetailsHandler)
}

function* getPaDashboardDetailsWatcher() {
  yield takeEvery(Types.GET_PA_DASHBOARD_DETAILS, getPaDashboardDetailsHandler)
}

function* getPaDashboardReportsWatcher() {
  yield takeLatest(Types.GET_PA_DASHBOARD_REPORTS, getPaDashboardReportsHandler)
}

function* searchCaseWatcher() {
  yield takeLatest(Types.GET_SEARCH_CASE_DETAILS, searchCaseHandler)
}

function* getCommunicationWatcher() {
  yield takeLatest(Types.GET_COMMUNICATION, getCommunicationHandler)
}

function* saveAttachmentWatcher() {
  yield takeLatest(Types.SAVE_ATTACHMENT, saveAttachmentHandler)
}

function* getDownloadDocumentWatcher() {
  yield takeLatest(Types.GET_DOWNLOAD_DOCUMENT, getDownloadDocumentHandler)
}

// function* saveFormsLettersWatcher() {
//   yield takeLatest(Types.SAVE_ATTACHMENT, saveFormsLettersHandler)
// }

function* getAttachmentWatcher() {
  yield takeLatest(Types.GET_ATTACHMENT, getAttachmentHandler)
}
function* viewAttachmentWatcher() {
  yield takeLatest(Types.VIEW_ATTACHMENT, viewAttachmentHandler)
}

function* addPaOverrideWatcher() {
  yield takeLatest(Types.ADD_PA_OVERRIDE, addPaOverrideHandler)
}


function* getallPriorAuthorizationDataWatcher() {
  yield takeLatest(Types.GET_ALL_PRIOR_AUTHORIZATION_DATA, getAllPriorAuthorizationDataHandler)
}

function* generatePriorAuthorizationWatcher() {
  yield takeLatest(Types.GENERATE_PRIOR_AUTHORIZATION, generatePriorAuthorizationHandler)
}

function* generatePriorAuthorizationLevelExportWatcher() {
  yield takeLatest(Types.GENERATE_PRIOR_AUTHORIZATION_LEVEL_EXPORT, generatePriorAuthorizationLevelExportHandler)
}

function* savePriorAuthorizationDataWatcher() {
  yield takeLatest(Types.SAVE_PRIOR_AUTHORIZATION_DATA, savePriorAuthorizationDataHandler)
}

function* getPriorAuthorizationDataWatcher() {
  yield takeLatest(Types.GET_PRIOR_AUTHORIZATION_DATA, getPriorAuthorizationDataHandler)
}

function* approvePriorAuthorizationWatcher() {
  yield takeLatest(Types.APPROVE_PRIOR_AUTHORIZATION, approvePriorAuthorizationHandler)
}

function* getPaIntakeQueueWatcher() {
  yield takeLatest(Types.GET_PA_INTAKE_QUEUE, getPaIntakeQueueHandler)
}

function* createPaIntakeQueueWatcher() {
  yield takeLatest(Types.CREATE_PA_INTAKE_QUEUE, createPaCaseHandler)
}

function* createNoteWatcher() {
  yield takeLatest(Types.CREATE_NOTE, createNoteHandler)
}

function* getNotesWatcher() {
  yield takeLatest(Types.GET_NOTES, getNotesHandler)
}

function* uploadPaDocumentTemplateWatcher() {
  yield takeLatest(Types.UPLOAD_PA_DOCUMENT_TEMPLATE, uploadPaDocumentTemplateHandler)
}

function* mergeDocumentWatcher() {
  yield takeLatest(Types.MERGE_DOCUMENT, mergeDocumentHandler)
}

function* checkDuplicateWatcher() {
  yield takeLatest(Types.CHECK_DUPLICATE, checkDuplicateHandler)
}

function* sendOutboundCommunicationWatcher() {
  yield takeLatest(Types.SEND_OUTBOUND_COMMUNICATION, sendOutboundCommunicationHandler)
}

function* saveAttachmentHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const cd = yield select(getApiPath, 'pacase-file-upload')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`
    const { data } = yield call(fileUploadPost, url, payload)
    if (!data.length) {
      const transitionalPortal = {
        header: 'Save attachement Failed',
        copy: 'Save attachement Failed',
      }
      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
      return
    }
    const transitionalPortal = {
      header: 'Attachement Saved',
      copy: 'Saves Attachement Successfully',
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    yield put(PriorAuthorizationCreationActions.setAttachmentData(data))
  } catch (err) {
    console.log('saveAttachmentHandler Error >Data ', err)

    const transitionalPortal = {
      header: 'Save attachement Failed',
      copy: err.message || err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* getDownloadDocumentHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const cd = yield select(getApiPath, 'pacase-file-download')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`
    const { data } = yield call(fetchPost, url, payload, true)
    // if (!data.length) {
    //   // const transitionalPortal = {
    //   //   header: 'No Documents Found',
    //   //   copy: 'No Documents Found',
    //   // }
    //   // yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    //   return
    // }
    // const transitionalPortal = {
    //   header: 'File Downloaded',
    //   copy: 'Download file Successfully',
    // }
    // yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    yield put(PriorAuthorizationCreationActions.setDownloadDocumentData(data))
  } catch (err) {
    console.log('downloadDataHandler Error >Data ', err)

    const transitionalPortal = {
      header: 'Download document Failed',
      copy: err.message || err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* getAttachmentHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const cd = yield select(getApiPath, 'pacase-get-files')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}?pa_case_id=${payload.pa_case_id}`
    const { data } = yield call(fetchGet, url, {}, true)
    if (!data.length) {
      const transitionalPortal = {
        header: 'Data Not found',
        copy: 'No forms or letters Found for given Id',
      }
      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
      return
    }
    yield put(PriorAuthorizationCreationActions.setFormsLettersData(data))
  } catch (err) {
    console.log('saveAttachmentHandler Error >Data ', err)

    const transitionalPortal = {
      header: 'Get attachement Failed',
      copy: err.message || err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* checkDuplicateHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const cd = yield select(getApiPath, 'validate-duplicate-pacase')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`
    const duplicateData = yield call(fetchPost, url, payload, true)
    if (duplicateData?.data?.length) {
      yield put(PriorAuthorizationCreationActions.setCheckDuplicateData(duplicateData))
    }
  } catch (err) {
    console.log('checkDuplicate Error >Data ', err)
    const transitionalPortal = {
      header: 'Check Duplicate Failed',
      copy: err.message || err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* addPaOverrideHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const cd = yield select(getApiPath, 'add-to-legacy-pa-override')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`
    const { data, message } = yield call(fetchPost, url, payload, true)
    if (!data.length) {
      const transitionalPortal = {
        header: 'Add PA override Failed',
        copy: message,
      }
      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
      return
    }
    yield put(PriorAuthorizationCreationActions.setAddPaOverrideData(data))
  } catch (err) {
    console.log('addPaOverride Error >Data ', err)

    const transitionalPortal = {
      header: 'Add PA override Failed',
      copy: err.message || err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* viewAttachmentHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const cd = yield select(getApiPath, 'pacase-get-file')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`
    const { data } = yield call(fetchPost, url, payload, true)
    if (!data.length) {
      const transitionalPortal = {
        header: 'View attachement Failed',
        copy: 'View attachement Failed',
      }
      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
      return
    }
    yield put(PriorAuthorizationCreationActions.setViewAttachmentData(data))
  } catch (err) {
    console.log('viewAttachmentHandler Error >Data ', err)

    const transitionalPortal = {
      header: 'View attachement Failed',
      copy: err.message || err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* getCommunicationHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const cd = yield select(getApiPath, 'get-communications')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`
    const { data } = yield call(fetchPost, url, payload, true)
    if (!data.length) {
      const transitionalPortal = {
        header: 'Get Communication Data Failed',
        copy: 'No Data found for given request Id',
      }
      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
      return
    }
    yield put(PriorAuthorizationCreationActions.setCommunicationData(data))
  } catch (err) {
    console.log('getRxTabDataHandler Error >Data ', err)

    const transitionalPortal = {
      header: 'Get Communication Data Failed',
      copy: err.message || err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* getMsgXmlHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const cd = yield select(getApiPath, 'pa-transaction-message-xml')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`
    yield put(PriorAuthorizationCreationActions.setMessageXml(""))
    const { data } = yield call(fetchPost, url, payload, true)
    if (!data.length) {
      const transitionalPortal = {
        header: 'XML Not Found',
        copy: 'No XML found for Given Message ID',
      }
      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
      return
    }
    yield put(PriorAuthorizationCreationActions.setMessageXml(data[0]))
  } catch (err) {
    console.log('getMsgXmlHandler Error >Data ', err)

    const transitionalPortal = {
      header: 'XML Not Found',
      copy: err.message || err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* getQuestionnaireHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const cd = yield select(getApiPath, 'get-questionnaire')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`
    yield put(PriorAuthorizationCreationActions.clearQuestionnaireDetails())
    const { data, message } = yield call(fetchPost, url, payload, true)
    if (!data.length || isEmpty(data[0])) {
      const transitionalPortal = {
        header: 'Questionnaire Not Found',
        copy: message ? message : 'No Questionnaire found for Given NdC and Group',
      }
      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
      return
    }
    yield put(PriorAuthorizationCreationActions.setQuestionnaireDetails(data[0]))
  } catch (err) {
    console.log('getQuestionnaireHandler Error >Data ', err)

    const transitionalPortal = {
      header: 'Questionnaire Not Found',
      copy: err.message || err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* getPriorAuthorizationTemplatesHandler() {
  try {
    const { serviceUrl } = yield select(getAppState)
    const cd = yield select(getApiPath, 'padocument-view-templates')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`

    const { data, message } = yield call(fetchGet, url, {}, true)
    yield put(PriorAuthorizationCreationActions.setPriorAuthorizationTemplatesData(data))
  } catch (err) {
    console.log('getPriorAuthorizationTemplatesHandler Error >Data ', err)

    const transitionalPortal = {
      header: 'Template Not Found',
      copy: err.message || err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* diagnosisLookupHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const cd = yield select(getApiPath, 'icd-codes-lookup')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`
    const { data } = yield call(fetchPost, url, payload, true)
    if (!data?.length) {
      const transitionalPortal = {
        header: 'Diagnosis Not Found',
        copy: 'No Diagnosis Found For Given Details',
      }
      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
      return
    }
    yield put(PriorAuthorizationCreationActions.setDiagnosisData(data))
  } catch (err) {
    console.log('diagnosisLookupHandler Error >Data ', err)

    const transitionalPortal = {
      header: 'Diagnosis Not Found',
      copy: err.message || err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* getMessageTemplateHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const cd = yield select(getApiPath, 'get-padocument-template')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`
    const { data } = yield call(fetchPost, url, payload, true)
    if (!data?.length) {
      const transitionalPortal = {
        header: 'Template Not Found',
        copy: 'No Template found for Given PA Case Id.',
      }
      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
      return
    }
    yield put(PriorAuthorizationCreationActions.setMessageTemplateData(data))
  } catch (err) {
    console.log('getMessageTemplateHandler Error >Data ', err)

    const transitionalPortal = {
      header: 'Template Not Found',
      copy: err.message || err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* getFinalDocumentsHandler({ payload }) {
  try {
    PriorAuthorizationCreationActions.clearFinalDocumentsData()
    const { serviceUrl } = yield select(getAppState)
    const cd = yield select(getApiPath, 'get-final-documents')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`
    const { data } = yield call(fetchPost, url, payload, true)
    if (!data?.length) {
      const transitionalPortal = {
        header: 'Documents not found',
        copy: 'Server returned empty response',
      }
      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
      return
    }
    yield put(PriorAuthorizationCreationActions.setFinalDocumentsData(data))
  } catch (err) {
    console.log('getFinalDocumentsHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Error getting generated documents',
      copy: err.message || err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* getAnswerHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const cd = yield select(getApiPath, 'get-questionnaire-answers')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`
    const { data } = yield call(fetchPost, url, payload, true)
    if (!data?.length) {
      const transitionalPortal = {
        header: 'Answer Not Found',
        copy: 'Error: This case does not have a completed questionnaire response from the provider yet.',
      }
      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
      return
    }
    yield put(PriorAuthorizationCreationActions.setGetAnswerData(data[0]))
  } catch (err) {
    console.log('getAnswerHandler Error >Data ', err)

    const transitionalPortal = {
      header: 'Answer Not Found',
      copy: err.message || err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* updatePrescriberHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const cd = yield select(getApiPath, 'update-prescriber-details')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`
    const { status, message } = yield call(fetchPost, url, payload, true)
    if (status === "200") {
      const transitionalPortal = {
        header: 'Prescriber Updated Successfully',
        copy: message,
      }
      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
      yield put(PriorAuthorizationCreationActions.setUpdatePrescriberData({ status }))
    }
  } catch (err) {
    console.log('updatePrescriberHandler Error >Data ', err)

    const transitionalPortal = {
      header: 'Prescriber Not Updated',
      copy: err.message || err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}



function* saveAnswerHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const cd = yield select(getApiPath, 'save-answers')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`
    const { data } = yield call(fetchPost, url, payload, true)
    if (!data?.length) {
      const transitionalPortal = {
        header: 'Answer Not Saved',
        copy: 'Error in saving the Answers',
      }
      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
      return
    }
    yield put(PriorAuthorizationCreationActions.setSaveAnswerData(data[0]))
  } catch (err) {
    console.log('saveAnswerHandler Error >Data ', err)

    const transitionalPortal = {
      header: 'Answer Not Saved',
      copy: err.message || err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* reassignPACaseHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const cd = yield select(getApiPath, 'reassign-pa-case')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`
    const { username, callback, ...params } = payload
    const { status, message } = yield call(fetchPost, url, params, true)
    if (status === "200") {
      let newMessage = message.replace(params.userId, username)
      const transitionalPortal = {
        header: 'Re-assign Successful',
        copy: newMessage,
      }
      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
      if (callback) {
        callback()
      }
      // yield put(PriorAuthorizationCreationActions.setReassignData({ status }))
    } else {
      const transitionalPortal = {
        header: 'Assign Failed',
        copy: 'Assign Next Case Failed',
      }
      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    }
  } catch (err) {
    console.log('assignToMeHandler Error >Data ', err)

    const transitionalPortal = {
      header: 'Assign Next Case Failed',
      copy: err.message || err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* assignToMeHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const cd = yield select(getApiPath, 'assign-to-me')
    const { callback, ...restOfPayload } = payload
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`
    const { data, status, message } = yield call(fetchPost, url, restOfPayload, true)
    if (status === "200" && data && data.length > 0) {
      const { pa_case_id } = data[0]
      if (pa_case_id && callback) {
        callback(pa_case_id, message)
      } else {
        const transitionalPortal = {
          header: message,
          copy: message,
        }
        yield put(AppActions.displayTransitionalPortal(transitionalPortal))
      }
    } else {
      const transitionalPortal = {
        header: 'Assign Failed',
        copy: 'Assign Next Case Failed',
      }
      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    }
  } catch (err) {
    console.log('assignToMeHandler Error >Data ', err)

    const transitionalPortal = {
      header: 'Assign Next Case Failed',
      copy: err.message || err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* getRxTabDataHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const { userCAG = {} } = yield select(getUserState)
    const cd = yield select(getApiPath, 'rxhistory')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`
    payload.cag = userCAG
    const { data } = yield call(fetchPost, url, payload, true)
    if (!data.length) {
      const transitionalPortal = {
        header: 'Get Rx Tab Data Failed',
        copy: 'No Data found for given member Id',
      }
      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
      return
    }
    yield put(PriorAuthorizationCreationActions.setRxTabData(data[0]))
  } catch (err) {
    console.log('getRxTabDataHandler Error >Data ', err)

    const transitionalPortal = {
      header: 'Get Rx Tab Data Failed',
      copy: err.message || err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}


function* updatePaCaseHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const cd = yield select(getApiPath, 'update-pa-case')
    const { callback, ...params } = payload
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`
    const { data, message } = yield call(fetchPost, url, params, true)
    if (!data.length) {
      const transitionalPortal = {
        header: 'Update Failed',
        copy: 'Update PA Case Details Failed',
      }
      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
      return;
    }
    if (data.length && Array.isArray(data[0])) {
      yield put(PriorAuthorizationCreationActions.setUpdatePaCaseMessage(message))
      yield put(PriorAuthorizationCreationActions.setUpdatePaCaseData(data[0]))
      return;
    }
    const transitionalPortal = {
      header: 'Update Successful',
      copy: 'Update PA Case Details Successful',
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    yield put(PriorAuthorizationCreationActions.setUpdatePaCaseData(data[0]))
    yield put(PriorAuthorizationCreationActions.setUpdatePaCaseMessage(message))
    yield put(PriorAuthorizationCreationActions.setPaCaseDetails(data[0]))
    if (callback) {
      callback()
    }
  } catch (err) {
    console.log('updatePaCaseHandler Error >Data ', err)

    const transitionalPortal = {
      header: 'Update PA Case Details Failed',
      copy: err.message || err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* getPaCaseDetailsHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const cd = yield select(getApiPath, 'get-pa-case')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`
    const { data } = yield call(fetchPost, url, payload, true)
    if (!data.length) {
      const transitionalPortal = {
        header: 'Get PA Case Details Failed',
        copy: 'No Data found for given Request Id',
      }
      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
      return
    }
    yield put(PriorAuthorizationCreationActions.setPaCaseDetails(data[0]))
  } catch (err) {
    console.log('getPaCaseDetailsHandler Error >Data ', err)

    const transitionalPortal = {
      header: 'Get PA Case Details Failed',
      copy: err.message || err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* addPrescriberHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const cd = yield select(getApiPath, 'add-prescriber-details')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`
    const { data } = yield call(fetchPost, url, payload, true)
    const { message } = data[0]
    if (!data.length || (message && message.startsWith('400'))) {
      const transitionalPortal = {
        header: 'Add Prescriber Failed',
        copy: message.substring(3) || 'Prescriber Not Added',
      }
      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
      return
    }
    const transitionalPortal = {
      header: 'Add Prescriber Sucessful',
      copy: 'Prescriber Created',
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    yield put(PriorAuthorizationCreationActions.setAddPrescriberData(data[0]))
  } catch (err) {
    console.log('addPrescriberHandler Error >Data ', err)

    const transitionalPortal = {
      header: 'Add Prescriber Failed',
      copy: err.message || err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* getPaDashboardDetailsHandler({ payload }) {
  try {
    const { form, params, headerMapping, headers } = payload
    const { paCasesPerPage } = yield select(getPriorAuthorizationData)
    form["offset"] = params?.request.startRow
    form["sortModel"] = params?.request.sortModel
    form["limit"] = paCasesPerPage
    const { serviceUrl } = yield select(getAppState)
    const menuEmpty = yield select(isMenuEmpty)
    if (menuEmpty) {
      yield take([AppTypes.SET_APP_SETTINGS])
    }
    const userEmpty = yield select(isUserEmpty)
    if (userEmpty) {
      yield take([UserTypes.USER_UPDATE])
    }
    const userEmptyAfterUpdate = yield select(isUserEmpty)
    if (userEmptyAfterUpdate) throw ('User Data not found. Please login again.')
    const users = yield select(getUserManagementData)
    if (!users?.length) {
      yield put(UserManagementActions.getUserManagementUsers())
    }
    if (!form.userId) {
      const user = yield select(getUserState)
      form["userId"] = user?.uuid
    }
    const cd = yield select(getApiPath, 'pa-dashboard')

    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`

    const { data, last_row } = yield call(fetchPost, url, form, true)
    const gridData = data.map((d) => ({
      ...filterRowData(d, headers),
    }))
    params.success({
      rowData: gridData,
      rowCount: last_row,
    })
  } catch (err) {
    console.log('getPaDashboardDetailsHandler Error >Data ', err)

    const transitionalPortal = {
      header: 'Get PA Case Details Failed',
      copy: err.message || err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* getPaDashboardReportsHandler() {
  try {
    const { serviceUrl } = yield select(getAppState)
    const cd = yield select(getApiPath, 'pa-dashboard-reports')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`
    const { data, message } = yield call(fetchPost, url, {}, true)
    if (!data?.length) {
      const transitionalPortal = {
        header: 'Error occured while getting reports data',
        copy: 'Error occured while getting reports data',
      }
      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
      return
    }
    yield put(PriorAuthorizationCreationActions.setPaDashboardReports(data[0]))

  } catch (err) {
    console.log('getPaDashboardReportsHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Error occured while getting reports data',
      copy: err.message || err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}


function* searchCaseHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const cd = yield select(getApiPath, 'case-search')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`
    const { data } = yield call(fetchPost, url, payload, true)
    if (data?.data && !data?.data?.length) {
      const transitionalPortal = {
        header: 'Search Case Failed',
        copy: 'No PA found for given data',
      }
      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
      return
    }
    yield put(PriorAuthorizationCreationActions.setSearchCaseDetails(data[0]))
  } catch (err) {
    console.log('searchCaseHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Search Case Failed',
      copy: err.message || err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* mergeDocumentHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const cd = yield select(getApiPath, 'merge-padocument-template')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`
    let requestPayload = { ...payload }
    // this flag will generate documents asyncronously
    // response is different if it's not an asynchronous call
    requestPayload.api_type = 'async'

    const { data } = yield call(fetchPost, url, requestPayload, true)
    if (!data.length || !data[0]) {
      const transitionalPortal = {
        header: 'Merge Document Failed',
        copy: 'Documents Not Merged',
      }
      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
      return
    }
    yield put(PriorAuthorizationCreationActions.setPaCaseDetails(data[0]))
    const transitionalPortal = {
      header: 'Document Creation in progress',
      copy: 'Document Creation request submitted successfully',
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))

    // yield put(PriorAuthorizationCreationActions.setMergeDocumentData(data))

  } catch (err) {
    console.log('mergeDocumentHandler Error >Data ', err)

    const transitionalPortal = {
      header: 'Merge Document Failed',
      copy: err.message || err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* getNotesHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const cd = yield select(getApiPath, 'get-notes')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`

    const { data } = yield call(fetchPost, url, payload, true)
    if (!data.length) {
      const transitionalPortal = {
        header: 'No Notes Found',
        copy: 'No Notes Found For Given PA Case',
      }
      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
      return
    }

    yield put(PriorAuthorizationCreationActions.setGetNoteData(data))
  } catch (err) {
    console.log('getNotesHandler Error >Data ', err)

    const transitionalPortal = {
      header: 'Get Notes Failed',
      copy: err.message || err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* createNoteHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const cd = yield select(getApiPath, 'create-note')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`

    const { pa_case_id } = payload
    const { data } = yield call(fetchPost, url, payload, true)

    yield put(PriorAuthorizationCreationActions.setCreateNoteData(data))
    const transitionalPortal = {
      header: 'Successfully Created Note',
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    yield put(PriorAuthorizationCreationActions.getNotes({ pa_case_id }))
    yield put(PriorAuthorizationCreationActions.getPaCaseDetails({ pa_case_id }))
  } catch (err) {
    console.log('createNoteHandler Error >Data ', err)

    const transitionalPortal = {
      header: 'Create Notes Failed',
      copy: err.message || err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* getPaIntakeQueueHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const cd = yield select(getApiPath, 'intake-queue')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`

    const { data } = yield call(fetchPost, url, payload, true)

    yield put(PriorAuthorizationCreationActions.setPaIntakeQueue(data))
  } catch (err) {
    console.log('getPaIntakeQueueHandler Error >Data ', err)

    const transitionalPortal = {
      header: 'Pa Intake Queue Generate Failed',
      copy: err.message || err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* createPaCaseHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const cd = yield select(getApiPath, 'create-pa-case')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`

    const { data } = yield call(fetchPost, url, payload, true)
    if (!data.length) {
      const transitionalPortal = {
        header: 'Pa Intake Queue Generate Failed',
        copy: ''
      }
      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
      return
    }
    yield put(PriorAuthorizationCreationActions.setCreatePaCaseData(data[0]))

  } catch (err) {
    console.log('getPaIntakeQueueHandler Error >Data ', err)

    const transitionalPortal = {
      header: 'Pa Intake Queue Generate Failed',
      copy: err.message || err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}



/* HANDLERS */
function* getAllPriorAuthorizationDataHandler() {
  try {
    const { userCAG = {} } = yield select(getUserState)
    const body = {
      module: 'PRIOR_AUTHORIZATION',
      status: ApprovalsConstants.ALL_RPM_STATUSES,
    }

    yield put(RPMActions.rpmGetApprovalDocuments(body))
  } catch (err) {
    console.log('getAllPriorAuthorizationDataHandler Error >Data ', err)

    const transitionalPortal = {
      header: 'Prior Authorization Lookup Failed',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* generatePriorAuthorizationLevelExportHandler({ payload, callback }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const cd = yield select(getApiPath, 'generate-prior-authorization')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`
    const { filename } = payload
    const { data } = yield call(fetchPost, url, payload)
    if (!data.length) throw Error('No Drugs found for the input conditions')
    const { paConditionLevel } = payload
    if (callback) {
      yield callback(data, filename)
    }
  } catch (err) {
    console.log('generatePriorAuthorizationLevelExportHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Generate Prior Auth List Failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* generatePriorAuthorizationHandler({ payload }) {
  try {
    const {
      form, params, headers, headerMapping
    } = payload
    let get_total_rows = true
    if (params?.request.startRow > 0) {
      get_total_rows = false
    }
    const { serviceUrl } = yield select(getAppState)
    const include_inactive_drugs = form.include_inactive_drugs
    const reqData = {
      drugConditions: [deleteUnnecessaryFields(form)],
      limit: 20,
      offset: params?.request.startRow,
      get_total_rows,
      filterModel: params?.request.filterModel,
      sortModel: params?.request.sortModel,
      include_inactive_drugs,
    }
    const { api_path } = yield select(getApiPath, 'generate-prior-authorization')

    const url = `${serviceUrl}${api_path}`
    const { data, last_row } = yield call(fetchPost, url, reqData)
    const gridData = data?.map((d) => ({
      ...filterRowData(d, headers, headerMapping),
      effective_start_date: convertDateToStrObj(form.effective_start_date),
      effective_end_date: convertDateToStrObj(form.effective_end_date)
    }))
    if (get_total_rows) {
      params?.success({
        rowData: gridData,
        rowCount: last_row,
      })
    } else {
      params?.success({
        rowData: gridData
      })
    }
  } catch (err) {
    console.log('generatePriorAuthorizationHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Error occurred while generating prior authorization drug conditions',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* savePriorAuthorizationDataHandler({ payload }) {
  try {
    const approvalDocument = {
      module_name: payload.doc_name,
      data: payload,
      module: 'PRIOR_AUTHORIZATION',
      status: 'DRAFT',
    }
    // eslint-disable-next-line
    const cb = function* ({ success }) {
      if (success) yield put(NavigationActions.navigateBack())
    }

    const criteria_ids = yield call(updateCriteriaIdsHandler, { payload })
    approvalDocument.data.conditions.criteriaIds = criteria_ids
    delete approvalDocument.data.conditions.umConditions
    yield put(RPMActions.rpmUpsertApprovalDocument(approvalDocument, cb))
  } catch (err) {
    console.log('savePriorAuthorizationDataHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Saving Prior Authorization Failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* getPriorAuthorizationDataHandler({ payload }) {
  try {
    const cb = function* cb(data) {
      const paData = data[0].module_data.data
      const prior_authorization_data = {
        pa_conditions: paData.conditions.paConditions,
        pa_info: {
          effective_start_date: paData.effective_start_date,
          doc_name: paData.doc_name,
          pa_reason: paData.pa_reason,
          doc_version: paData.doc_version,
          questionnaire_link: paData.questionnaire_link,
          questionnaire_name: paData.questionnaire_name,
          hierarchy: data[0].hierarchy,
          doc_id: data[0].id,
          version_effective_date: paData.version_effective_date,
          version: data[0].version,
          status: data[0].status,
          is_hierarchy_global: data[0].is_hierarchy_global,
          hideHierarchy: data[0].hideHierarchy,
        },
        qualifier_conditions: paData.conditions.qualifierConditions,
        criteria_ids: paData.conditions?.criteriaIds || [],
      }
      yield put(PriorAuthorizationCreationActions.setPriorAuthorizationData(prior_authorization_data))
    }
    yield put(RPMActions.rpmGetApprovalDocuments(payload, cb))
  } catch (err) {
    console.log('getPriorAuthorizationDataHandler Error >Data ', err)

    const transitionalPortal = {
      header: 'Prior Authorization Lookup Failed',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

// TODO: REMOVE - DEPRECATED
function* approvePriorAuthorizationHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const history = yield select(getHistory)
    const qs = history.location.search
    const cd = yield select(getApiPath, 'approve-prior-authorization')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}${qs}`

    const { message } = yield call(fetchPost, url, payload)
    yield put(PriorAuthorizationCreationActions.getAllPriorAuthorizationData())
    const transitionalPortal = {
      header: 'Prior Authorization',
      copy: `${message}. Please wait while we publish the prior authorization list.`,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    // triggering airlow dag to load the drug list and publish the prior authorization list
    const execution_time = new Date()
    execution_time.setSeconds(execution_time.getSeconds() + 60)
    const payload2 = {
      conf: { section: 'pa', ...payload },
      dag_run_id: uuidv4(),
      execution_date: execution_time.toISOString(),
    }
    yield call(fetchPost, url.replace('/approve-prior-authorization', '/build-drug-list'), payload2)
  } catch (err) {
    console.log('approvePriorAuthorizationHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Approving Prior Authorization Failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* uploadPaDocumentTemplateHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const cd = yield select(getApiPath, 'padocument-template-upload')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`
    const formData = new FormData()
    const { file } = payload
    for (const f in file) {
      formData.append(`${f}_file`, file[f])
      formData.append(`${f}_type`, file[f]["Document Type"])
    }
    const response = yield call(fileUploadPost, url, formData)
    const { data } = response
    if (!data.length) throw Error('Upload PA Template Document failed')
    const transitionalPortal = {
      header: 'PA Template Document Upload',
      copy: 'PA Template Document Upload Successfully',
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  } catch (err) {
    console.log('paTemplateDocumentUpload Error >Data ', err)

    const transitionalPortal = {
      header: 'PA Template Upload Failed',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}


function* sendOutboundCommunicationHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const cd = yield select(getApiPath, 'send-outbound-communication')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`
    const { data, message = '' } = yield call(fetchPost, url, payload)
    if (!data.length) {
      let header = 'Error occurred while updating the case'
      if (payload.communication_method === Constants.FAX_STR) {
        header = 'Error while sending outbound communication'
      }
      const transitionalPortal = {
        header,
        copy: message || 'Error',
      }
      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    }
    yield put(PriorAuthorizationCreationActions.setPaCaseDetails(data[0]))
    yield put(AppActions.toggleSideDrawer())
    let header = 'Case update successful'
    if (payload.communication_method === Constants.FAX_STR) {
      header = 'Send fax request was submitted'
    }
    const transitionalPortal = {
      header,
      copy: message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  } catch (err) {
    console.log('sendOutboundCommunicationHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Error occurred while updating the case',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}
