export const timerType = [
    {
        key: 'Yes',
        text: 'Yes',
        value: 'Yes',
    },
    {
        key: 'No',
        text: 'No',
        value: 'No',
    }
]
export const statusType = [
    {
        key: 'active',
        text: 'Active',
        value: 'active',
    },
    {
        key: 'inactive',
        text: 'Inactive',
        value: 'inactive',
    }
]

export const applicableStatusType = [
    { key: '1', value: 'Intake', text: 'Intake' },
    { key: '2', value: 'Clinical Review', text: 'Clinical Review' },
    { key: '3', value: 'RFI', text: 'RFI' },
    { key: '4', value: 'Outbound Communication', text: 'Outbound Communication' },
    { key: '5', value: 'Closed', text: 'Closed' },
]