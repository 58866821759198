import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import _ from 'lodash';

import { Creators as AppActions } from '../../../../../../redux/reducers/app'
import { Creators as GlobalEditsActions } from '../../../../../../redux/reducers/api/globalEdits'

import FliptGrid from '../../../../../../components/fliptGrid';
import { DeleteRowRenderer } from '../../../../../../components/fliptGrid/cellRenderers';
import { InputTextEditor, DropdownEditor, DatePickerEditor } from '../../../../../../components/fliptGrid/cellEditors';
import { Icon } from 'semantic-ui-react';
import FliptButton from '../../../../../../components/v2/fliptButton';

import './flipt_accordion.scss';

const rowCellInfo = {
    min_day_supply: {
        type: 'dropdown',
        disabled: false,
        options: [],
    },
    max_day_supply: {
        type: 'dropdown',
        disabled: false,
        options: [],
    },
    network_tier: {
        type: 'dropdown',
        disabled: false,
        options: [],
    },
    copay_type: {
        type: 'dropdown',
        disabled: false,
        options: [],
    },
    member_pay: {
        type: 'input',
        disabled: false,
    },
    min_copay: {
        type: 'input',
        disabled: false,
    },
    max_copay: {
        type: 'input',
        disabled: false,
    }
}

const defaultCopay = {
    'min_day_supply': '',
    'max_day_supply': '',
    'network_tier': '',
    'copay_type': '',
    'member_pay': '',
    'min_copay': '',
    'max_copay': '',
}


const maximize = () => (
    <svg className='maximize-icon' fill='none' height='512' viewBox='0 0 24 24' width='512' xmlns='http://www.w3.org/2000/svg'>
        <g fill='rgb(0,0,0)'>
            <path
                d='m14 3c0 .69036.5597 1.25 1.25 1.25h2.7322l-3.9822 3.98223c-.4881.48816-.4881 1.27962 0 1.76777.4882.4882 1.2796.4882 1.7678 0l3.9822-3.98223v2.73223c0 .69035.5596 1.25 1.25 1.25s1.25-.55964 1.25-1.25v-5.75c0-.69036-.5596-1.25-1.25-1.25h-5.75c-.6903 0-1.25.55964-1.25 1.25z' />
            <path
                d='m9.99999 21c0-.6904-.55965-1.25-1.25-1.25h-2.73223l3.98223-3.9822c.48811-.4882.48811-1.2796 0-1.7678-.48816-.4882-1.27962-.4882-1.76777 0l-3.98222 3.9822v-2.7322c0-.6904-.55964-1.25-1.25-1.25s-1.25.5596-1.25 1.25v5.75c0 .6904.55964 1.25 1.25 1.25h5.74999c.69035 0 1.25-.5596 1.25-1.25z' />
        </g>
    </svg>
)

const trash = () => (
    <svg id='Layer_1' enable-background='new 0 0 24 24' height='512' viewBox='0 0 24 24' width='512' xmlns='http://www.w3.org/2000/svg'><g><path d='m21 7h-18c-.6 0-1-.4-1-1s.4-1 1-1h18c.6 0 1 .4 1 1s-.4 1-1 1z' /></g><g><path d='m17 23h-10c-1.7 0-3-1.3-3-3v-14c0-.6.4-1 1-1s1 .4 1 1v14c0 .6.4 1 1 1h10c.6 0 1-.4 1-1v-14c0-.6.4-1 1-1s1 .4 1 1v14c0 1.7-1.3 3-3 3zm-1-16c-.6 0-1-.4-1-1v-2c0-.6-.4-1-1-1h-4c-.6 0-1 .4-1 1v2c0 .6-.4 1-1 1s-1-.4-1-1v-2c0-1.7 1.3-3 3-3h4c1.7 0 3 1.3 3 3v2c0 .6-.4 1-1 1z' /></g></svg>
)

// spin off 
function FliptAccordion(props) {
    const {
        title,
        removeItem,
    } = props;

    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpansion = () => {
        setIsExpanded(!isExpanded);
    };
    return (
        <section id='flipt-accordion' className='accordion-container'>
            <section className='accordion-header'>
                <h2 className='accordion-header-title'>{title}</h2>
                <div className='accordion-actions'>
                    <div className='accordion-button-container'>
                        <button class='accordion-button maximize'
                            onClick={toggleExpansion}
                        >
                            <svg className='accordion-icon maximize' fill='#667085' height='512' viewBox='0 0 24 24' width='512' xmlns='http://www.w3.org/2000/svg'>
                                <g>
                                    <path
                                        d='m14 3c0 .69036.5597 1.25 1.25 1.25h2.7322l-3.9822 3.98223c-.4881.48816-.4881 1.27962 0 1.76777.4882.4882 1.2796.4882 1.7678 0l3.9822-3.98223v2.73223c0 .69035.5596 1.25 1.25 1.25s1.25-.55964 1.25-1.25v-5.75c0-.69036-.5596-1.25-1.25-1.25h-5.75c-.6903 0-1.25.55964-1.25 1.25z' />
                                    <path
                                        d='m9.99999 21c0-.6904-.55965-1.25-1.25-1.25h-2.73223l3.98223-3.9822c.48811-.4882.48811-1.2796 0-1.7678-.48816-.4882-1.27962-.4882-1.76777 0l-3.98222 3.9822v-2.7322c0-.6904-.55964-1.25-1.25-1.25s-1.25.5596-1.25 1.25v5.75c0 .6904.55964 1.25 1.25 1.25h5.74999c.69035 0 1.25-.5596 1.25-1.25z' />
                                </g>
                            </svg>
                        </button>
                    </div>
                    <div className='accordion-button-container'>
                        <button class='accordion-button trash'
                            onClick={removeItem}
                        >
                            <svg className='accordion-icon trash' fill='#FC0303' id='Layer_1' enable-background='new 0 0 24 24' height='512' viewBox='0 0 24 24' width='512' xmlns='http://www.w3.org/2000/svg'><g><path d='m21 7h-18c-.6 0-1-.4-1-1s.4-1 1-1h18c.6 0 1 .4 1 1s-.4 1-1 1z' /></g><g><path d='m17 23h-10c-1.7 0-3-1.3-3-3v-14c0-.6.4-1 1-1s1 .4 1 1v14c0 .6.4 1 1 1h10c.6 0 1-.4 1-1v-14c0-.6.4-1 1-1s1 .4 1 1v14c0 1.7-1.3 3-3 3zm-1-16c-.6 0-1-.4-1-1v-2c0-.6-.4-1-1-1h-4c-.6 0-1 .4-1 1v2c0 .6-.4 1-1 1s-1-.4-1-1v-2c0-1.7 1.3-3 3-3h4c1.7 0 3 1.3 3 3v2c0 .6-.4 1-1 1z' /></g></svg>
                        </button>
                    </div>
                </div>
            </section>
            <section className={`accordion-content ${isExpanded ? 'expanded' : 'collapsed'}`}>
                {props.children}
                <div>
                </div>
            </section>
        </section>
    )
}



function CustomCostShare(props) {


    const { state, actions } = props
    const { copays, globalEdits } = state

    const handleChange = (parentIndex) => (el, dropdown, rowIndex, gridApi) => {
        const { name, value } = dropdown || el.currentTarget
        const newCopayList = _.cloneDeep(copays);
        newCopayList[parentIndex].cost_share[rowIndex][name] = value
        actions.updateGlobalEditsField({ name: 'copays', value: newCopayList })
        gridApi.refreshCells()
    }
    const delRow = (parentIndex) => (rowIndex) => {
        const copaysCopay = _.cloneDeep(copays)
        const program = copaysCopay[parentIndex]
        program.cost_share = program.cost_share?.filter((_, i) => i !== rowIndex) || []
        if (program.cost_share === 0) {
            program.cost_share = [defaultCopay]
        }
        actions.updateGlobalEditsField({ name: 'copays', value: copaysCopay })
    }

    const renderGrid = (programCopay, parentIndex) => {
        const headers = [
            'min_day_supply',
            'max_day_supply',
            'network_tier',
            'copay_type',
            'member_pay',
            'min_copay',
            'max_copay',
            'action'
        ];
        const cellRendererParams = {
            min_day_supply: {
                width: 160,
                overrideHeader: 'Min Day Supply',
            },
            max_day_supply: {
                width: 160,
                overrideHeader: 'Max Day Supply',
            },
            network_tier: {
                width: 160,
            },
            copay_type: {
                width: 145,
                overrideHeader: 'Cost Share Type',
            },
            member_pay: {
                width: 170,
                overrideHeader: 'Member Pay',
                valueFormatter: params => {
                    if (params.data.member_pay === undefined || params.data.member_pay === null) return null
                    if (params.data.copay_type === 'Fixed') return '$' + `${params.data.member_pay}`
                    if (params.data.copay_type === 'Co-Insurance') return `${params.data.member_pay}` + '%'
                    return params.data.member_pay
                },
            },
            min_copay: {
                width: 155,
                overrideHeader: 'Min Cost Share',
            },
            max_copay: {
                width: 155,
                overrideHeader: 'Max Cost Share',
            },
            action: {
                cellRenderer: DeleteRowRenderer,
                state: {
                    onClick: delRow(parentIndex),
                },
                width: 135,
            },
        }
        const cellEditorParams = {
            min_day_supply: {
                editable: true,
                cellEditor: DropdownEditor,
                onChange: handleChange(parentIndex),
            },
            max_day_supply: {
                editable: true,
                cellEditor: DropdownEditor,
                onChange: handleChange(parentIndex),
            },
            network_tier: {
                editable: true,
                cellEditor: DropdownEditor,
                onChange: handleChange(parentIndex),
            },
            copay_type: {
                editable: true,
                cellEditor: DropdownEditor,
                onChange: handleChange(parentIndex),
            },
            member_pay: {
                editable: true,
                cellEditor: InputTextEditor,
                onChange: handleChange(parentIndex),
                validation: 'numeric',
            },
            min_copay: {
                editable: true,
                cellEditor: InputTextEditor,
                onChange: handleChange(parentIndex),
                validation: 'numeric',
            },
            max_copay: {
                editable: true,
                cellEditor: InputTextEditor,
                onChange: handleChange(parentIndex),
                validation: 'numeric',
            }
        }

        return (
            <FliptGrid
                data={programCopay}
                headers={headers}
                cellRendererParams={cellRendererParams}
                cellEditorParams={cellEditorParams}
                rowCellInfo={rowCellInfo}
            />
        )
    }

    const addConditionRow = (copayIndex) => {
        const newCopayList = _.cloneDeep(copays);
        if (newCopayList[copayIndex] && Array.isArray(newCopayList[copayIndex].cost_share)) {
            newCopayList[copayIndex].cost_share.push(defaultCopay)
        }
        actions.updateGlobalEditsField({ name: 'copays', value: newCopayList })
    }

    const removeOptionFromList = (section, id) => {
        const selectedSection = globalEdits[section] || [];
        const newList = selectedSection.reduce((newList, record) => {
            if (record.doc_id !== id) {
                newList.push(record);
                record.rank = newList.length;
            }
            return newList;
        }, []);
        actions.updateGlobalEditsField({ name: section, value: newList });
        if (section === 'programs') {
            const newCopayList = [...(globalEdits.copays || [])];
            const newCopayListWithoutProgram = newCopayList.filter((copay) => copay.program_id !== id);
            actions.updateGlobalEditsField({ name: 'copays', value: newCopayListWithoutProgram });
        }
    }

    return (
        <div id='CustomCostShare'>
            <section>
                <h2 className='page-h2'>Custom Cost Share</h2>
            </section>
            <section>
                {copays.map((copay, index) => {
                    return <FliptAccordion
                        key={'accordion-' + index}
                        title={copay.program_name}
                        removeItem={() => removeOptionFromList('programs', copay.program_id)}
                    >
                        <div className='grid-container'>
                            {renderGrid(copay.cost_share, index)}
                            <section className="grid-actions">
                                <FliptButton
                                    className="button-primary"
                                    name="Add Condition"
                                    icon={<Icon name="add" />}
                                    onClick={() => addConditionRow(index)}
                                />
                            </section>
                        </div>
                    </FliptAccordion>
                })}
            </section>
        </div>
    )
}

const mapStateToProps = (state) => {
    const section = state.globalEdits?.section || {};
    const fieldDetails = section?.global_copay?.fields || [];
    const daySupplyField = fieldDetails?.find(({ field }) => field === 'day_supply')
    const daySupplyFieldOptions = daySupplyField?.options || []
    const daySupplyOptions = daySupplyFieldOptions?.map((option, index) => {
      const { value } = option
      return {
        key: index,
        text: value,
        value,
      }
    })
    const networkTierOptions = fieldDetails?.find(({ field }) => field === 'network_tier')?.options || []
    rowCellInfo.network_tier.options = networkTierOptions?.map((code, index) => ({
      key: index,
      text: code.display_name,
      value: code.value,
      map_value: code.map_value,
    }))
    rowCellInfo.network_tier.options.unshift({
      key: 0, text: 'ALL', value: 'ALL', map_value: 'ALL',
    })
    const copayTypeField = fieldDetails?.find(({ field }) => field === 'copay_type')
    const copayTypeFieldOptions = copayTypeField?.options || []
    const copayTypeOptions = copayTypeFieldOptions?.map((option, index) => {
      const { value } = option
      return {
        key: index,
        text: value,
        value,
      }
    })
    rowCellInfo.min_day_supply.options = daySupplyOptions
    rowCellInfo.max_day_supply.options = daySupplyOptions
    rowCellInfo.copay_type.options = copayTypeOptions
    return {
        state: {
            app: state.app,
            claimsProcessingAndReprocessing: state.claimsProcessingAndReprocessing,
            copays: state.globalEdits?.copays || [],
            globalEdits: state.globalEdits,
            singleLevelAttribute: state.clientConfiguration.singleLevelAttribute,
            clientConfiguration: state.clientConfiguration
        },
    }
}

const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...AppActions,
        ...GlobalEditsActions,
    }

    return {
        actions: bindActionCreators(allActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomCostShare);