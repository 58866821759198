import React, { useState } from 'react'


import '../styles.scss'

import rowCellInfo from './data/rowCellInfo'
import FliptGrid from '../../../../components/fliptGrid'

import FliptButton from '../../../../components/form/fliptButton'
import AddRow from './addRow'
import { DeleteRowRenderer } from '../../../../components/fliptGrid/cellRenderers'
import { InputTextEditor, DropdownEditor, SearchEditor, StaticSearchEditor, DatePickerEditor } from '../../../../components/fliptGrid/cellEditors'
import PharmacyList from './pharmacyList'
import moment from 'moment-timezone'


function AssignPharamcies(props) {
  const {
    conditionLevels,
    activeConditionLevel,
    addPharmacyCondition,
    handleChange,
    generateAssignedPharmacy,
    showPharmacyList,
    editMode,
    chainCode,
    corpName,
    removePharmacyConditionRow,
    specialtyDrug,
    claim_processor,
    networkTierType,
  } = props
  const [showgeneratedPharmacy, setGeneratedPharmacy] = useState(showPharmacyList)
  const headers = (networkTierType !== 'Pricing') ? Object.keys(rowCellInfo) : Object.keys(rowCellInfo).filter(e => !['claim_processor', 'program_drug_list'].includes(e))
  rowCellInfo.claim_processor.options = claim_processor
  rowCellInfo.program_drug_list.options = specialtyDrug?.map(e => ({
    key: e.doc_id,
    text: e.doc_name,
    value: e.doc_name,
  })) || []
  if (!editMode) {
    headers.splice(headers.indexOf('action'), 1)
    //headers.unshift('action')
  }
  const chain_code = chainCode?.map((ele) => {
    return { title: ele, text: ele, value: ele }
  }) || []
  const corp_name = corpName?.map((ele) => {
    return { title: ele, text: ele, value: ele }
  }) || []
  rowCellInfo.chain_code.options = chain_code
  rowCellInfo.corp_name.options = corp_name
  const cellRendererParams = {
    action: {
      cellRenderer: DeleteRowRenderer,
      state: {
        onClick: removePharmacyConditionRow,
      },
      program_drug_list: {
        overrideHeader: 'Drug Listing',
      },
      width: 95,
    },
    effective_start_date: {
      valueFormatter: (params) => {
        if (!params.value) return ''
        return moment(params.value)?.format('YYYY-MM-DD') || params.value
      },
    },
    effective_end_date: {
      valueFormatter: (params) => {
        if (!params.value) return ''
        return moment(params.value)?.format('YYYY-MM-DD') || params.value
      },
    }
  }

  const autoSuggestionResultSelect = (data, rowIndex, api) => {
    handleChange(undefined, { ...data, value: data.result.title }, rowIndex, api, activeConditionLevel)
  }

  const cellEditorParams = {
    pharmacy_name: {
      cellEditor: InputTextEditor,
      onChange: handleChange,
    },
    claim_processor: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    program_drug_list: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    chain_code: {
      cellEditor: StaticSearchEditor,
      autoSuggestionResultSelect,
    },
    relationship_id: {
      cellEditor: InputTextEditor,
      onChange: handleChange,
    },
    relationship_name: {
      cellEditor: InputTextEditor,
      onChange: handleChange,
    },
    relationship_type: {
      cellEditor: InputTextEditor,
      onChange: handleChange,
    },
    effective_start_date: {
      cellEditor: DatePickerEditor,
      onChange: handleChange,
    },
    effective_end_date: {
      cellEditor: DatePickerEditor,
      onChange: handleChange,
    },
    corp_name: {
      cellEditor: StaticSearchEditor,
      autoSuggestionResultSelect,
    },
    npi: {
      cellEditor: InputTextEditor,
      onChange: handleChange,
    },
    state: {
      cellEditor: InputTextEditor,
      onChange: handleChange,
    },
    zip_code: {
      cellEditor: InputTextEditor,
      onChange: handleChange,
    },
    include_exclude: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
  }
  const agGridRef = React.createRef();
  const generateButtonHeading = `Generate Pharmacy For Pharmacy Condition Level ${activeConditionLevel + 1}`;
  return (
    <>
      <div className="pa-conditions">
        <FliptGrid
          agGridRef={agGridRef}
          key={`${networkTierType}-${activeConditionLevel}`}
          data={conditionLevels[activeConditionLevel]}
          headers={headers}
          cellRendererParams={cellRendererParams}
          cellEditorParams={editMode && cellEditorParams}
          rowCellInfo={editMode && rowCellInfo}
          stepLevel={activeConditionLevel}
        />
      </div>
      <div className="pa-condition-level-button-container">
        {editMode && (
          <>
            <div className="pa-condition-level-add-cond-button addRowButtonContainer">
              <AddRow addRow={addPharmacyCondition} level={activeConditionLevel} />
            </div>
          </>
        )}
        {editMode && (
          <div className="generateButtonContainer">
            <FliptButton onClick={() => {
              setGeneratedPharmacy(true)
              generateAssignedPharmacy(activeConditionLevel)
            }} name={generateButtonHeading} className="primary searchButton" />
          </div>
        )}
        {showgeneratedPharmacy && (
          <div>
            <PharmacyList
              conditionLevels={conditionLevels[activeConditionLevel]}
            />
          </div>
        )}
      </div>
    </>
  )
}
export default AssignPharamcies

