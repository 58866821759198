export const sublist_lookbackCriteria = [
    'Any 1 Drug',
    'Any 2 Drugs',
    'Any 3 Drugs',
    'Any 4 Drugs',
    'Any 5 Drugs',
    'Exclude',
    'All Drugs',
]
export const drug_list = []
export const set_operator = ['', 'AND', 'OR']
