import React, { useState, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as _ from 'lodash'
import FliptGrid from '../../../../components/fliptGrid'
import BreadCrumb from '../../../../components/breadCrumb'
import FliptButton from '../../../../components/form/fliptButton'
import { Creators as DrugManagementActions } from '../../../../redux/reducers/api/drugManagement'
import { parseQueryString } from '../../../../utils/utilities'
import Config from '../../../../config'
import { ColorCodedStatusRenderer } from '../../../../components/fliptGrid/cellRenderers'
import './styles.scss'

const ViewDrugTerm = (props) => {
  const { history } = props
  const { file_id } = parseQueryString(history.location.search)
  const headers = [
    'drug_name',
    'clinical_priority',
    'ndc',
    'gpi',
    'change_type',
    'added_to_exclusion',
    'old_value',
    'new_value',
    'object_impacted',
  ]
  const cellRendererParams = {
    drug_name: {
      width: 200,
      overrideHeader: 'Drug Name',
    },
    clinical_priority: {
      cellRenderer: ColorCodedStatusRenderer,
      width: 200,
      overrideHeader: 'Priority',
    },
    ndc: {
      width: 200,
      overrideHeader: 'NDC',
    },
    gpi: {
      width: 200,
      overrideHeader: 'GPI',
    },
    added_to_exclusion: {
      width: 200,
      overrideHeader: 'Added to Exclusion ?',
    },
    change_type: {
      width: 200,
      overrideHeader: 'Change Type',
    },
    old_value: {
      width: 200,
      overrideHeader: 'Old Value',
    },
    new_value: {
      width: 200,
      overrideHeader: 'New Value',
    },
    object_impacted: {
      width: 200,
      overrideHeader: 'Object Impacted',
    },
  }
  const { state, actions } = props
  const { drugTermSummary: dat } = state
  const [data, setData] = useState(dat)
  useEffect(() => {
    if (dat && !_.isEqual(data, dat)) {
      setData(dat)
    }
  }, [dat])
  useEffect(() => {
    setTimeout(() => actions.getDmDrugTermsData({ file_id }), Config.COMPONENT_DATA_LOAD_TIME)
  }, [])
  return (
    <div id="planDesignManagement">
      <BreadCrumb {...props} />
      <div className="header">
        Drug Change Processing
      </div>
      <div className="content maxHeight">
        <FliptGrid data={data} headers={headers} cellRendererParams={cellRendererParams} />
      </div>
      <div id="close-button">
        <FliptButton className="closeButton" compact name="Close" onClick={() => history?.goBack()} />
      </div>
    </div>
  )
}
const mapStateToProps = (state) => ({
  state: {
    drugTermSummary: state.drugManagement.drugTermSummary,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...DrugManagementActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewDrugTerm)
