import React from 'react'

import './styles.scss'
import ButtonSection from './buttonSection'
import FliptDropdown from '../../../../../../components/form/fliptDropdown'

export default function EmailSection(props) {
    const {
        submitForm, toggleModal, updateFields, is_vip
    } = props
    const vipOptions = [
        { key: 0, text: 'Yes', value: 'true' },
        { key: 1, text: 'No', value: 'false' }]
    return (
        <div id="vipSection">
            <div className="header-label">VIP Indicator</div>
            <div className="email fields">
                <FliptDropdown defaultValue={is_vip} name="is_vip" onChange={updateFields}
                    options={vipOptions} label="VIP" className="dropdown-width" stylized />
                <ButtonSection toggleModal={toggleModal} submitForm={submitForm} />
            </div>
        </div>
    )
}
