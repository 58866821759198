import React from 'react'

import './styles.scss'
import FliptInput from '../../../../../../components/form/fliptInput'
import ButtonSection from './buttonSection'

export default function EmailSection(props) {
  const {
    email_addresses, submitForm, toggleModal, updateFields,
  } = props

  return (
    <div id="emailSection">
      <div className="header-label">Communication Email</div>
      <div className="email-list">
        {email_addresses.map((email, idx) => {
          const { email_address } = email

          return <div key={`${idx}${email}`}>{email_address}</div>
        })}
      </div>
      <div className="email fields">
        <FliptInput label="New Communication Email" name="communication_option_email" placeholder="Enter email" onChange={updateFields} />
        <ButtonSection toggleModal={toggleModal} submitForm={submitForm} />
      </div>
    </div>
  )
}
