export const policyType = [
    {
        key: 'Global',
        text: 'Global',
        value: 'Global',
    },
    {
        key: 'Custom',
        text: 'Custom',
        value: 'Custom',
    }
]
export const requestType = [
    {
        key: 'All Exceptions',
        text: 'All Exceptions',
        value: 'All Exceptions',
    },
    {
        key: 'Prior Authorization',
        text: 'Prior Authorization',
        value: 'Prior Authorization',
    },
    {
        key: 'Step Therapy',
        text: 'Step Therapy',
        value: 'Step Therapy',
        disabled: true,
    },
    {
        key: 'Quantity Limits',
        text: 'Quantity Limits',
        value: 'Quantity Limits',
        disabled: true,
    },
    {
        key: 'Vacation Supply',
        text: 'Vacation Supply',
        value: 'Vacation Supply',
        disabled: true,
    },
    {
        key: 'Transitional',
        text: 'Transitional',
        value: 'Transitional',
        disabled: true,
    },
    {
        key: 'Non-Covered',
        text: 'Non-Covered',
        value: 'Non-Covered',
    },
    {
        key: 'Brand Medically Necessary',
        text: 'Brand Medically Necessary',
        value: 'Brand Medically Necessary',
    }

]

export const priorityType = [
    {
        key: 'Expedited',
        text: 'Expedited',
        value: 'Expedited',
    },
    {
        key: 'Standard',
        text: 'Standard',
        value: 'Standard',
    }
]

export const calculateType = [
    {
        key: 'Business Hours',
        text: 'Business Hours',
        value: 'Business Hours',
    },
    {
        key: 'Continuous',
        text: 'Continuous',
        value: 'Continuous',
    }
]

export const daysToReturnInfoType = [
    {
        key: 3,
        text: 3,
        value: 3,
    },
    {
        key: 7,
        text: 7,
        value: 7,
    },
    {
        key: 10,
        text: 10,
        value: 10,
    },
    {
        key: 14,
        text: 14,
        value: 14,
    },
    {
        key: 21,
        text: 21,
        value: 21,
    },
    {
        key: 30,
        text: 30,
        value: 30,
    },
    {
        key: 'other',
        text: 'other',
        value: 'other',
    }
]

export const emptyAlertType = [
    {
        key: '',
        text: '',
        value: '',
    },
]
export const alertType = [
    {
        key: 4,
        text: 4,
        value: 4,
    },
    {
        key: 8,
        text: 8,
        value: 8,
    },
    {
        key: 13,
        text: 13,
        value: 13,
    },
    {
        key: 16,
        text: 16,
        value: 16,
    },
    {
        key: 20,
        text: 20,
        value: 20,
    },
    {
        key: 24,
        text: 24,
        value: 24,
    },
    {
        key: 48,
        text: 48,
        value: 48,
    },
]
export const rmiAlertType = [
    {
        key: 3,
        text: 3,
        value: 3,
    },
    {
        key: 7,
        text: 7,
        value: 7,
    },
    {
        key: 10,
        text: 10,
        value: 10,
    },
    {
        key: 14,
        text: 14,
        value: 14,
    },
    {
        key: 18,
        text: 18,
        value: 18,
    },
    {
        key: 22,
        text: 22,
        value: 22,
    }
]

export const dayType = [
    {
        key: 'work_day',
        text: 'Work Day',
        value: 'work_day',
    },
    {
        key: 'holiday',
        text: 'Holiday',
        value: 'holiday',
    }
]

export const weekDaysType = [
    {
        key: 'M',
        text: 'Monday',
        value: 'M',
    },
    {
        key: 'T',
        text: 'Tuesday',
        value: 'T',
    },
    {
        key: 'W',
        text: 'Wednesday',
        value: 'W',
    },
    {
        key: 'Th',
        text: 'Thursday',
        value: 'Th',
    },
    {
        key: 'F',
        text: 'Friday',
        value: 'F',
    },
    {
        key: 'S',
        text: 'Saturday',
        value: 'S',
    },
    {
        key: 'Su',
        text: 'Sunday',
        value: 'Su',
    },
]

