import {
  call,
  put,
  select,
  takeLatest,
  take,
} from 'redux-saga/effects'
import moment from 'moment'

import { Types, Creators as MemberLookupActions } from '../../reducers/api/memberLookup'
import { Creators as NavigationActions } from '../../reducers/navigation'
import { Creators as AppActions, Types as AppTypes } from '../../reducers/app'
import { Creators as UserActions, Types as UserTypes } from '../../reducers/user'
import {
  getAppState, getApiPath, getPrimaryMemberDetails, getMemberDetails, getHistory, getUserState, isMenuEmpty, isUserEmpty,
} from '../../reducers/selectors'
import { fetchPost, fetchPatch, fetchGet, fetchPut } from '../../../utils/fetchApi'
import { convertStrToDateObj } from '../../../utils/utilities'

export default [
  fetchAccumulatorDetailsDataWatcher,
  fetchMemberLookupDataWatcher,
  getMemberDeductibleDetailsDataWatcher,
  getMemberLookupDataWatcher,
  updateMemberDataWatcher,
  fetchMemberDetailsDataWatcher,
  checkIfMemberExistWatcher,
  saveUserWatcher,
  getCommunicationHistoryWatcher,
  saveCardWatcher,
  getMemberMedicareDetailsDataWatcher,
  saveMemberRestrictionsWatcher,
  updateMemberPasswordWatcher,
  getMemberLogsWatcher
]

/* WATCHERS */
function* getMemberLookupDataWatcher() {
  yield takeLatest(Types.GET_MEMBER_LOOKUP_DATA, getMemberLookupDataHandler)
}

function* getMemberLogsWatcher() {
  yield takeLatest(Types.GET_MEMBER_LOGS, getMemberLogsHandler)
}
function* fetchMemberLookupDataWatcher() {
  yield takeLatest(Types.FETCH_MEMBER_LOOKUP_DATA, fetchMemberLookupDataHandler)
}

function* getMemberDeductibleDetailsDataWatcher() {
  yield takeLatest(Types.GET_MEMBER_DEDUCTIBLE_DETAILS_DATA, getMemberDeductibleDetailsDataHandler)
}

function* getMemberMedicareDetailsDataWatcher() {
  yield takeLatest(Types.GET_MEMBER_MEDICARE_DETAILS_DATA, getMemberMedicareDetailsDataHandler)
}

function* fetchAccumulatorDetailsDataWatcher() {
  yield takeLatest(Types.FETCH_ACCUMULATOR_DETAILS_DATA, fetchAccumulatorDetailsDataHandler)
}

function* updateMemberDataWatcher() {
  yield takeLatest(Types.UPDATE_MEMBER_DATA, updateMemberDataHandler)
}

function* fetchMemberDetailsDataWatcher() {
  yield takeLatest(Types.FETCH_MEMBER_DETAILS_DATA, fetchMemberDetailsDataHandler)
}

function* checkIfMemberExistWatcher() {
  yield takeLatest(Types.CHECK_IF_MEMBER_EXIST, checkIfMemberExistHandler)
}

function* saveUserWatcher() {
  yield takeLatest(Types.SAVE_USER, saveUserHandler)
}

function* getCommunicationHistoryWatcher() {
  yield takeLatest(Types.GET_COMMUNICATION_HISTORY_DATA, getCommunicationHistoryHandler)
}

function* saveMemberRestrictionsWatcher() {
  yield takeLatest(Types.SAVE_MEMBER_RESTRICTIONS_DATA, saveMemberRestrictionsHandler)
}

function* saveCardWatcher() {
  yield takeLatest(Types.SAVE_CARD, saveCardHandler)
}

function* updateMemberPasswordWatcher() {
  yield takeLatest(Types.UPDATE_MEMBER_PASSWORD, updateMemberPasswordHandler)
}

/* HANDLERS */
function* getMemberLookupDataHandler({ payload }) {
  try {

    const hasPayload = Object.values(payload).find((v) => !!v)
    if (!hasPayload) return

    if (!payload.noDataClearing) {
      yield put(MemberLookupActions.clearMemberLookupData())
      delete payload.noDataClearing
    }
    const menuEmpty = yield select(isMenuEmpty)
    if (menuEmpty) {
      yield take([AppTypes.SET_APP_SETTINGS])
    }
    const userEmpty = yield select(isUserEmpty)
    if (userEmpty) {
      yield take([UserTypes.USER_UPDATE])
    }

    const { userCAG = {} } = yield select(getUserState)
    payload = {
      ...payload,
      cag: {
        group: userCAG?.group ?? [],
        domain_name: userCAG?.account ?? [],
        ...userCAG,
      }
    }

    yield put(MemberLookupActions.fetchMemberLookupData(payload))
  } catch (err) {
    console.log('getMemberLookupDataHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Member Lookup Failed',
      copy: 'Unable to find user',
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    yield put(NavigationActions.navigateBack())
  }
}


function* getMemberLogsHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const { api_path } = yield select(getApiPath, 'eligibility-logs')
    const url = `${serviceUrl}${api_path}`
    const { data } = yield call(fetchPost, url, payload)
    yield put(MemberLookupActions.setMemberLogs(data))
  } catch (err) {
    console.log('getMemberLogsHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Fetch Member Logs Failed',
      copy: 'Unable to find Logs',
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    yield put(NavigationActions.navigateBack())
  }
}

function* getCommunicationHistoryHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const { api_path } = yield select(getApiPath, 'communication-history')
    if (!payload)
      return
    const url = `${serviceUrl}${api_path}`
    const { data } = yield call(fetchGet, url, payload)
    yield put(MemberLookupActions.setCommunicationHistoryData(data))
  } catch (err) {
    console.log('getCommunicationHistoryHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Communication History Failed',
      copy: 'Unable to find History',
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    yield put(NavigationActions.navigateBack())
  }
}

function* fetchMemberLookupDataHandler({ payload }) {
  try {
    yield put(MemberLookupActions.clearMemberLookupData())

    const { serviceUrl } = yield select(getAppState)
    const { api_path } = yield select(getApiPath, 'member-lookup')
    if (!payload) return
    if (payload.date_of_birth) {
      payload.date_of_birth = convertStrToDateObj(payload.date_of_birth, { toISO: false })
    }
    const url = `${serviceUrl}${api_path}`
    const response = yield call(fetchPost, url, payload)
    const memberData = response.data

    if (memberData.length === 1) {
      if (memberData[0]?.error) throw `${memberData[0]?.message}`
      yield put(MemberLookupActions.setMemberLookupData(memberData[0]))
    } else if (memberData.length > 1) {
      yield put(MemberLookupActions.setMultiMemberLookupData(memberData))
    } else {
      // eslint-disable-next-line no-throw-literal
      throw 'There was a problem searching for a member'
    }
  } catch (err) {
    console.log('fetchMemberLookupDataHandler Error >Data ', err)
    yield put(MemberLookupActions.clearMemberLookupData())
    yield put(MemberLookupActions.setMemberLookupFailure(true))
    const transitionalPortal = {
      header: 'Unable to find Member.',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* getMemberDeductibleDetailsDataHandler() {
  try {
    const memberDetails = yield select(getMemberDetails)
    const history = yield select(getHistory)
    const qs = history.location.search
    if (!qs) return

    if (!memberDetails.flipt_person_id) {
      yield put(NavigationActions.navigateTo({
        pathname: '/member-lookup',
        search: qs,
      }))
    } else {
      yield put(MemberLookupActions.fetchAccumulatorDetailsData())
    }
  } catch (err) {
    console.log('getMemberDeductibleDetailsDataHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Member Deductible Details Lookup failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* getMemberMedicareDetailsDataHandler() {
  try {
    const memberDetails = yield select(getMemberDetails)
    const history = yield select(getHistory)
    const qs = history.location.search
    if (!qs) return

    const { serviceUrl } = yield select(getAppState)
    const { api_path } = yield select(getApiPath, 'medicare-pde-details')
    const url = `${serviceUrl}${api_path}`
    const body = { 'rx_member_id': memberDetails.member_id }
    const { data } = yield call(fetchPost, url, body)

    yield put(MemberLookupActions.setMedicareDetailsData(data))
  } catch (err) {
    console.log('getMemberMedicareDetailsDataHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Member Medicare Details Lookup failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* fetchAccumulatorDetailsDataHandler() {
  try {
    const pmd = yield select(getPrimaryMemberDetails)
    const memberDetails = yield select(getMemberDetails)
    const { serviceUrl } = yield select(getAppState)
    const { userCAG = {} } = yield select(getUserState)
    const { api_path } = yield select(getApiPath, 'accumulation-details')
    const url = `${serviceUrl}${api_path}`
    const body = _formatDeductiblesDetailsData(pmd, memberDetails)
    const { data } = yield call(fetchPost, url, { ...body, cag: { group: userCAG?.group ?? [], domain_name: userCAG?.account ?? [], ...userCAG }, })

    yield put(MemberLookupActions.setAccumulatorDetailsData(data))
  } catch (err) {
    console.log('fetchAccumulatorDetailsDataHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Member Accumulator Details lookup failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* updateMemberDataHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const { api_path } = yield select(getApiPath, 'member-lookup')
    const url = `${serviceUrl}${api_path}`
    const { data } = yield call(fetchPatch, url, payload)

    yield put(MemberLookupActions.setMemberLookupData(data[0]))
  } catch (err) {
    console.log('updateMemberDataHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Member Update Failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
  // yield put(AppActions.toggleModal())
}

function* fetchMemberDetailsDataHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const { api_path } = yield select(getApiPath, 'member-details')
    if (!payload) return

    const url = `${serviceUrl}${api_path}`
    const data = yield call(fetchPost, url, payload)
    yield put(MemberLookupActions.setMemberDetailsData((data.data.length && data.data[0]) || {}))
  } catch (err) {
    console.log('fetchMemberDetailsDataHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Member details failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* checkIfMemberExistHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const { api_path } = yield select(getApiPath, 'check-member-exists')
    if (!payload) return

    const url = `${serviceUrl}${api_path}`
    yield call(fetchPost, url, payload)
    const { data } = yield call(fetchPost, url, payload)
    yield put(MemberLookupActions.setMemberExistData(data))
  } catch (err) {
    console.log('checkIfMemberExistHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'check If Member Exist Handler failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* saveUserHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const { api_path } = yield select(getApiPath, 'save-member')
    if (!payload) return

    const url = `${serviceUrl}${api_path}`
    const { data, message } = yield call(fetchPut, url, payload)
    if (data?.length) {
      const transitionalPortal = {
        header: 'User Saved',
        copy: 'User Saved with id ' + data[0].user_id,
      }
      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
      yield put(MemberLookupActions.setSaveUserData(data[0]))
    }
    else {
      let message1 = "Error Saving Data"
      const errorMessage = message ? message : "Error saving data";
      throw new Error(errorMessage);
    }

  } catch (err) {
    console.log('saveUserHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Save User Handler failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* saveCardHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const { api_path } = yield select(getApiPath, 'save-card')
    if (!payload) return

    const url = `${serviceUrl}${api_path}`
    const { data } = yield call(fetchPost, url, payload)
    if (data.length) {
      const transitionalPortal = {
        header: 'Card Details Saved Successfully.'
      }
      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    }

  } catch (err) {
    console.log('saveCardIdDataHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Save Card Handler failed',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* saveMemberRestrictionsHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const { api_path } = yield select(getApiPath, 'save-restrictions')
    if (!payload) return
    const url = `${serviceUrl}${api_path}`
    const { status } = yield call(fetchPost, url, payload)
    if (status === "200") {
      const transitionalPortal = {
        header: 'Restrictions Saved Successfully.'
      }
      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    }
  } catch (err) {
    console.log('saveMemberRestrictionsHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Restrictions Save Handler Failed',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* updateMemberPasswordHandler({ payload }) {
  try {
    yield put(MemberLookupActions.setLoading(true))
    const { serviceUrl } = yield select(getAppState)
    const { api_path } = yield select(getApiPath, 'password-update')
    if (!payload) return
    const url = `${serviceUrl}${api_path}`
    const { status } = yield call(fetchPut, url, payload)
    if (status !== "success") {
      throw new Error('Password update failed')
    }
    yield put(MemberLookupActions.setPasswordUpdateStatus('success'))
  } catch (err) {
    yield put(MemberLookupActions.setPasswordUpdateStatus('error'))
  } finally {
    yield put(MemberLookupActions.setLoading(false))
  }
}


/* UTILS */
function _formatDeductiblesDetailsData(primaryMemberDetails, memberDetails) {
  // const currentEligibility = memberDetails.eligibility.sort((a, b) => new Date(b.coverage_effective_date) - new Date(a.coverage_effective_date))[0]
  // const plan_start_date = currentEligibility.coverage_effective_date
  const { coverage_start_date } = memberDetails
  const plan_start_date = moment(coverage_start_date, 'MM/DD/YYYY').format('YYYY-MM-DD')

  if (!primaryMemberDetails.dependents) throw 'Invalid dependent data'

  const members = primaryMemberDetails.dependents.map((dep) => ({
    flipt_person_id: dep.flipt_person_id,
    first_name: dep.first_name,
    last_name: dep.last_name,
    date_of_birth: dep.date_of_birth,
    cardholder_id: `${dep.member_id}`,
  }))

  members.push({
    flipt_person_id: primaryMemberDetails.flipt_person_id,
    first_name: primaryMemberDetails.first_name,
    last_name: primaryMemberDetails.last_name,
    date_of_birth: primaryMemberDetails.date_of_birth,
    cardholder_id: `${primaryMemberDetails.member_id}`,
  })

  return {
    employee_flipt_person_id: primaryMemberDetails.flipt_person_id,
    plan_start_date,
    members,
  }
}

export {
  getMemberLookupDataHandler,
  fetchMemberLookupDataHandler,
  getMemberDeductibleDetailsDataHandler,
  fetchAccumulatorDetailsDataHandler,
  getCommunicationHistoryHandler,
  getMemberMedicareDetailsDataHandler
}
