import React, { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Creators as IntegrationManagementActions } from '../../../../redux/reducers/api/integrationManagement'

import { Creators as AppActions } from '../../../../redux/reducers/app'
import { Creators as UserManagementActions } from '../../../../redux/reducers/userManagement'
import { Creators as FileConfigurationActions } from "../../../../redux/reducers/api/fileConfiguration"

import { Creators as TimerConfigActions } from '../../../../redux/reducers/api/timerConfig'

import PaDataTab from '../../../../components/fliptGrid'
import './styles.scss'
import TabBar from '../../../../components/tabBar'
import { INTEGRATION_MGMT_STATUS_LOCAL_STORAGE_KEY, IntegrationMgmtStatusTabConfig, cellRendererParams, headers } from './data'
import { isEmpty } from 'lodash'
import moment from 'moment-timezone'



const IntegrationMgmtDashboardTab = ({ state, actions, activeTabId, form }) => {

    const { integrationMgmtFilteredLookupData } = state
    const [statusActiveTabId, setStatusActiveTabId] = useState('COMPLETED')
    const [gridData, setGridData] = useState({})

    useEffect(() => {
        const params = { file_type: activeTabId.toLowerCase() }
        if (!form.start_date || !form.end_date) {
            params.to = moment().format('YY-MM-DD')
            params.from = moment().subtract('days', 7).format('YY-MM-DD')
        } else {
            params.from = moment(form.start_date).format('YY-MM-DD')
            params.to = moment(form.end_date).format('YY-MM-DD')
        }
        actions.getIntegrationMgmtLookup(params)
    }, [])

    const groupBy = (xs, key) => {
        return xs.reduce((rv, x) => {
            const obj = {
                file_config_name: x?.file_config_name,
                client: `${x?.clientInformation?.client_first_name} ${x?.clientInformation?.client_last_name} `,
                triggered_time: x?.triggered_time,
                batch_start_time: x?.batch_start_time,
                batch_end_time: x?.batch_end_time,
                total_records_received: x?.error_track ? (x.error_track?.error + x.error_track?.good + x.error_track?.warning) : 0,
                total_warning: x.error_track?.warning || 0,
                total_adds: x?.change_track?.eligibility?.add || 0,
                total_changes: x?.change_track?.eligibility?.update || 0,
                total_terms: x?.change_track?.eligibility?.term || 0,
                total_rejects: x.error_track?.error || 0,
            }
            rv[x[key]] = rv[x[key]] || []
            rv[x[key]].push(obj);
            return rv;
        }, {});
    };

    useEffect(() => {
        if (integrationMgmtFilteredLookupData?.length) {
            const formattedData = integrationMgmtFilteredLookupData.map((data) => {
                return ({
                    ...data, status: data?.status === 'ERROR' && data?.error_message?.error_type === '%_RECORD_ISSUE' ? 'HOLD_THRESHOLD' : data.status
                })
            })
            const gridData = groupBy(formattedData, 'status')
            setGridData(gridData)
        }
    }, [integrationMgmtFilteredLookupData])


    const setStatusActiveTab = (id) => {
        setStatusActiveTabId(id)
    }

    // const handleEnterEditMode = (rowdata) => {
    //     const { file_type, file_id } = rowdata.data;
    //     const record = dashboard.find((record) => record.file_id === file_id && record.file_type === file_type)
    //     props.actions.getFileConfiguration(record)
    // };

    // cellRendererParams.file_config_name = {
    //     overrideHeader: 'File Name',
    //     cellRenderer: LinkRenderer,
    //     file_config_name: 'file_config_name',
    //     path: '/file-configuration',
    //     searchArgs: {},
    //     state: { editMode: true },
    //     onCellClicked: handleEnterEditMode,
    // }

    const renderGrid = () => {
        return IntegrationMgmtStatusTabConfig.map((tabConfig, index) => {
            return (tabConfig.id === statusActiveTabId &&
                <div className="content">
                    <PaDataTab
                        headers={headers}
                        data={gridData[statusActiveTabId] || []}
                        cellRendererParams={cellRendererParams}
                    />
                </div>
            )
        })
    }
    return (
        <div id='int-dashboard-tab'>
            <TabBar
                tabs={IntegrationMgmtStatusTabConfig}
                setActiveTab={setStatusActiveTab}
                localStorageKey={INTEGRATION_MGMT_STATUS_LOCAL_STORAGE_KEY}
                draghandle
            />
            {!isEmpty(gridData) ? renderGrid() : null}
        </div>)
}

const mapStateToProps = (state) => {
    return {
        state: {
            app: state.app,
            user: state.user,
            integrationMgmtFilteredLookupData: state.integrationMgmt.integrationMgmtFilteredLookupData
        },
    }
}

const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...IntegrationManagementActions,
        ...AppActions,
        ...FileConfigurationActions,
        ...UserManagementActions,
        ...TimerConfigActions,
    }

    return {
        actions: bindActionCreators(allActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationMgmtDashboardTab)
