import React, { PureComponent } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Modal } from 'semantic-ui-react'
import { Creators as AppActions } from '../../redux/reducers/app'
import { Creators as PriorAuthorizationCreationActions } from '../../redux/reducers/api/priorAuthorizationManagement'

class AppModal extends PureComponent {
  toggleModal = () => {
    const { actions } = this.props
    actions.toggleModal()
    actions.clearCaseSearchDetails()
  }

  render() {
    const { state } = this.props
    const { modalProperties, component } = state.app.modal

    return (
      <Modal
        closeIcon={modalProperties?.noShow ? false : true}
        dimmer="inverted"
        onClose={this.toggleModal}
        closeOnDimmerClick={false}
        open={state.app.modal.visible}
        {...modalProperties}
      >
        <Modal.Content scrolling={modalProperties?.scrolling}>
          {component}
        </Modal.Content>
      </Modal>
    )
  }
}

const mapStateToProps = (state) => ({
  state: {
    app: state.app,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
    ...PriorAuthorizationCreationActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppModal)
