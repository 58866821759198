import React, {
  useState, useEffect, useRef, forwardRef, useImperativeHandle,
} from 'react'

import FliptInput from '../../form/fliptInput'
import FliptDropdown from '../../form/fliptDropdown'
import FliptSearch from '../../form/fliptSearch'
import FliptDatePicker, { FliptCustomDatePicker } from '../../form/fliptDatePicker'
import FliptCheckbox from '../../form/fliptCheckbox'
import * as fieldValidators from '../../../utils/validationHelpers'

import { convertStrToDateObj } from '../../../utils/utilities'
import { validateAlphaNumeric, validateNumericField, validateString } from '../../../utils/validationHelpers'

export const _handleValidation = (validation, value, data, api) => {
  if (!validation) return null

  switch (validation) {
    case 'numeric':
      return !validateNumericField(value) ? 'Only numeric is allowed' : null
    case 'required':
      return value ? null : 'Value is required'
    case 'alphaNumeric':
      return !validateAlphaNumeric(value) ? 'Only alpha numeric is allowed' : null
    case 'string':
      return !validateString(value) ? 'Only string is allowed' : null
    default:
      if (validation instanceof Function) {
        const response = validation(value, data, api)
        return response ? response : null
      }

      return null
  }
}

export const CheckboxEditor = forwardRef((props, ref) => {
  const {
    colDef, onChange, disabled, context, rowIndex, api, data,
  } = props
  const { stepLevel } = context
  const { headerName, field } = colDef
  const [value, setValue] = useState(props.value)
  const refInput = useRef()

  const _onChange = (e, dropdown) => {
    setValue(e.target.checked)
    onChange(e, dropdown, rowIndex, api, stepLevel, data)
  }
  useImperativeHandle(ref, () => ({
    getValue() {
      return value
    },
    afterGuiAttached: () => {
      setValue(props.value)
    },
  }))
  return (
    <FliptCheckbox ref={refInput} placeholder={headerName} name={field} disabled={disabled} checked={value} onChange={(e, dropdown) => _onChange(e, dropdown)} />
  )
})

export const InputTextEditor = forwardRef((props, ref) => {
  const {
    colDef, onChange, context, rowIndex, api, data, validation, multiValData, isMultiRow, isDisabled,
    inputProps
  } = props
  let { disabled } = props
  if (isDisabled) disabled = isDisabled(data)
  const { stepLevel } = context
  const { headerName, field, id } = colDef
  const [value, setValue] = useState(props.value)
  const refInput = useRef()
  const multiInput = isMultiRow ? multiValData[rowIndex]?.fee_type : ''
  const multiInputVal = isMultiRow ? multiValData[rowIndex] : ''

  const validators = {
    code: fieldValidators.validateCode,
    email_address: fieldValidators.validateEmailAddress,
    numeric: fieldValidators.validateNumericField,
    password: fieldValidators.validatePassword,
    phone: fieldValidators.validatePhoneNumber,
    alphaNumeric: fieldValidators.validateAlphaNumeric,
    name: fieldValidators.validateName,
    zipCode: fieldValidators.validateZipCode
  }

  const _onChange = (e, dropdown) => {
    if (e.target.value && validation && validators[validation] && !validators[validation](e.target.value)) return
    if (!isMultiRow) {
      setValue(e.target.value)
    }
    onChange(e, dropdown, rowIndex, api, stepLevel, data)
  }

  const _convertMultiValInStr = () => {
    if (multiInputVal[field] && typeof multiInputVal[field] === 'object') {
      if (Object.keys(multiInputVal[field])?.length > 0) {
        let val = Object.keys(multiInputVal[field])?.map((it) => multiInputVal[field][it])
        val = val ? val.join(', ') : ''
        return val
      } return ''
    } return ''
  }

  useImperativeHandle(ref, () => ({
    getValue() {
      // if (isMultiRow) {
      //   _convertMultiValInStr()
      // } return value
      return isMultiRow ? multiInputVal[field] : value
    },
    isPopup() {
      return true
    },
    afterGuiAttached: () => {
      if (!isMultiRow) {
        setValue(props.value)
      } else {
        setValue(_convertMultiValInStr())
      }
      refInput.current.focus()
    },
  }))
  const validateField = _handleValidation(validation, value, data, api)
  // let val = ''
  // if (isMultiRow) {
  //   val = _convertMultiValInStr()
  // }
  return (
    <div>
      <FliptInput ref={refInput} id={id} placeholder={headerName} name={field} value={value} disabled={disabled} onChange={(e, dropdown) => _onChange(e, dropdown)} validation={validation} {...inputProps} />
      <br />
      {isMultiRow
        && multiInput.length > 0
        && multiInput.map((col) => {
          let fieldVal = ''
          if (multiInputVal) {
            if (Object.keys(multiInputVal)?.length > 0) {
              fieldVal = multiInputVal[col]
            }
          }
          return (
            <>
              <FliptInput placeholder={`${col} value`} name={field} id={col} value={fieldVal} onChange={(e, dropdown) => _onChange(e, dropdown)} validation={validation} {...inputProps} />
              <br />
            </>
          )
        })}
      {
        validation && validateField ? (
          <>
            <br />
            <span className="err">{validateField}</span>
          </>
        ) : ''
      }
    </div>
  )
})

export const DropdownEditor = forwardRef((props, ref) => {
  const {
    colDef, onChange, context, rowIndex, data, api, isMulti, readOnly, isDisabled
  } = props
  let { disabled } = props
  if (isDisabled) disabled = isDisabled(data)
  const { rowCellInfo, dropdownOptions, stepLevel } = context
  const { headerName, field, id } = colDef
  const [value, setValue] = useState(props.value)
  const refInput = useRef()
  const _onChange = (e, dropdown) => {
    setValue(dropdown.value)
    onChange(e, dropdown, rowIndex, api, stepLevel, data)
  }
  useImperativeHandle(ref, () => ({
    getValue() {
      return value
    },
    isPopup() {
      return true
    },
    afterGuiAttached: () => {
      setValue(props.value)
    },
  }))
  return (
    <>
      <FliptDropdown
        ref={refInput}
        name={field}
        value={value}
        options={!!dropdownOptions && dropdownOptions[rowIndex] && dropdownOptions[rowIndex][field] ? dropdownOptions[rowIndex][field] : rowCellInfo[field].options}
        selectOnBlur={false}
        scrolling
        search
        clearable
        selection
        id={id}
        placeholder={headerName}
        readOnly={readOnly || disabled || rowCellInfo[field]?.disabled}
        disabled={disabled || rowCellInfo[field]?.disabled}
        onChange={(e, dropdown) => _onChange(e, dropdown)}
        multiple={!!rowCellInfo && rowCellInfo[field]?.multiple}
      />
      <br />
      {isMulti
        && value.length > 0
        && value.map((col) => (
          <>
            <p>{col}</p>
            <br />
          </>
        ))}
    </>
  )
})

export const SearchEditor = forwardRef((props, ref) => {
  const {
    colDef, onChange, context,
    autoSuggestionResultSelect, rowIndex, api,
  } = props
  const {
    rowCellInfo = [], autoSuggestionValues: suggestions = [], autoSuggestionMinChars = 0, stepLevel,
  } = context
  const [autoSuggestionValues, setAutoSuggestionValues] = useState(suggestions)
  const { field } = colDef
  const [value, setValue] = useState(props.value)
  const refInput = useRef()

  const _onChange = (e, dropdown) => {
    setValue(dropdown.value)
    onChange(e, dropdown, rowIndex, api, stepLevel, setAutoSuggestionValues)
  }
  useEffect(() => {
    setAutoSuggestionValues(suggestions)
  }, [suggestions])
  const _onAutoSuggestionResultSelect = (data) => {
    setValue(data.result.title)
    autoSuggestionResultSelect(data, rowIndex, api)
  }
  const { [field]: { values = [], loading = false } = {} } = autoSuggestionValues[rowIndex] || {}
  useImperativeHandle(ref, () => ({
    getValue() {
      return value
    },
    isPopup() {
      return true
    },
    afterGuiAttached: () => {
      setValue(props.value)
    },
  }))
  const data = stepLevel !== undefined ? autoSuggestionValues[stepLevel][rowIndex][field] : autoSuggestionValues[rowIndex][field]
  return (
    <FliptSearch ref={refInput} name={field} value={value} results={data?.values || values} loading={data.loading || loading} minCharacters={autoSuggestionMinChars} onResultSelect={(e, d) => _onAutoSuggestionResultSelect(d)} disabled={rowCellInfo[field].disabled} onSearchChange={(e, dropdown) => _onChange(e, dropdown)} />
  )
})

export const StaticSearchEditor = forwardRef((props, ref) => {
  const {
    colDef, onChange, context,
    autoSuggestionResultSelect,
    rowIndex, api,
  } = props
  const {
    rowCellInfo = {}
  } = context
  const { field } = colDef
  const suggestions = rowCellInfo[field]?.options || []
  const [autoSuggestionValues, setAutoSuggestionValues] = useState(suggestions)
  const [value, setValue] = useState(props.value)
  const refInput = useRef()
  const filter = (value) => {
    return suggestions.filter(ele => {
      return ele.value ? ele.value.toLowerCase().startsWith(value) : false
    })
  }
  const _onChange = (e, dropdown) => {
    setValue(dropdown.value)
    onChange && onChange(e, dropdown, api)
    setAutoSuggestionValues(filter(dropdown.value))
  }
  const _onAutoSuggestionResultSelect = (data) => {
    setValue(data.result.title)
    autoSuggestionResultSelect(data, rowIndex, api)
  }
  useImperativeHandle(ref, () => ({
    getValue() {
      return value
    },
    isPopup() {
      return true
    },
    afterGuiAttached: () => {
      setValue(props.value)
    },
  }))

  return (
    <FliptSearch ref={refInput} name={field} resultRenderer={({ title }) => <div> {title} </div>} value={value} results={autoSuggestionValues} loading={false} minCharacters={1} onResultSelect={(e, d) => _onAutoSuggestionResultSelect(d)} disabled={false} onSearchChange={(e, dropdown) => _onChange(e, dropdown)} />
  )
})

export const DatePickerEditor = forwardRef((props, ref) => {
  const {
    colDef, onChange, context, rowIndex, api, data, isDisabled, format, minDate, maxDate
  } = props
  const { stepLevel, dropdownOptions } = context
  const { headerName, field } = colDef
  const [value, setValue] = useState(props.value)
  const refInput = useRef()
  let { disabled } = props
  if (isDisabled) disabled = isDisabled(data, rowIndex)
  const _onChange = (e, dropdown) => {
    setValue(dropdown.value)
    onChange(e, dropdown, rowIndex, api, stepLevel, data)
  }
  useImperativeHandle(ref, () => ({
    getValue() {
      return value
    },
    isPopup() {
      return true
    },
    afterGuiAttached: () => {
      setValue(props.value)
    },
  }))
  const customMinDate = dropdownOptions?.length && dropdownOptions[rowIndex]
    && dropdownOptions[rowIndex][field]?.minDate || ''
  const customMaxDate = dropdownOptions?.length && dropdownOptions[rowIndex]
    && dropdownOptions[rowIndex][field]?.maxDate || ''
  return (
    <FliptDatePicker ref={refInput} placeholder={headerName} minDate={customMinDate || minDate || ''}
      maxDate={customMaxDate || maxDate || ''} name={field} value={convertStrToDateObj(value)} disabled={disabled}
      onChange={(e, dropdown) => _onChange(e, dropdown)} format={format || 'MM-DD-YYYY'} />
  )
})

export const DateCustomPickerEditor = forwardRef((props, ref) => {
  const {
    colDef, onChange, disabled, context, rowIndex, api, data, inputProps,
  } = props
  const { stepLevel } = context
  const { headerName, field } = colDef
  const [value, setValue] = useState(props.value)
  const refInput = useRef()

  const _onChange = (value, e) => {
    setValue(value)
    onChange(value, e, rowIndex, api, stepLevel, data)
  }
  useImperativeHandle(ref, () => ({
    getValue() {
      return value
    },
    isPopup() {
      return true
    },
    afterGuiAttached: () => {
      setValue(props.value)
    },
  }))
  return (
    <FliptCustomDatePicker ref={refInput} placeholderText={headerName} name={field} selected={convertStrToDateObj(value)} disabled={disabled} onChange={(e, dropdown) => _onChange(e, dropdown)} stylized={true} {...inputProps} />
  )
})

export const NestedInputEditor = forwardRef((props, ref) => {
  const {
    data, editorParams, ...other
  } = props
  const { api } = other
  const { EditorComponent, elementList, key, forceResetHeight, ...otherParams } = editorParams
  const list = (elementList instanceof Function) ? elementList({ data: data[key], row: data, key }) : elementList
  const [value, setValue] = useState(data[key])
  useImperativeHandle(ref, () => ({
    getValue() {
      return data[key]
    },
    isPopup() {
      return true
    },
    afterGuiAttached: () => {
      setValue(data[key])
    },
  }))

  useEffect(() => {
    if (!forceResetHeight)
      return
    const timer = setTimeout(() => {
      if (list.length > 0)
        api.resetRowHeights()
    }, 2000);
    return () => clearTimeout(timer)
  }, [])
  return (<div className='nestedEditor'>{list?.map(e => {
    return <div className='editorContainer'><EditorComponent {...other} {...e} {...otherParams} value={value[e?.colDef?.id]} /></div>
  })}</div>)
})