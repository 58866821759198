import React, { Component } from 'react'
import _ from 'lodash'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import './styles.scss'
import BreadCrumb from '../../../../../components/breadCrumb'
import Config from '../../../../../config'
import TeamRoles from './teamRoles'
import { Creators as AppActions } from '../../../../../redux/reducers/app'
import { Creators as NavigationActions } from '../../../../../redux/reducers/navigation'
import { Creators as RolesActions } from '../../../../../redux/reducers/roleManagement'
import { Creators as teamManagementActions } from '../../../../../redux/reducers/teamManagement'
import { parseQueryString } from '../../../../../utils/utilities'
import SaveTierModal from '../../../formularyCreationManagement/formularyTiers/createTier/saveTierModal'

class CreateTeam extends Component {
  constructor(props) {
    super(props)
    this.state = {
      form: {
        id: '',
        name: '',
        description: '',
        effective_start_date: '',
        effective_end_date: '',
        active: '',
        organization: '',
      },
      roles: props?.state?.rolesData,
      editMode: true,
      rowUpdated: null,
    }
  }

  componentDidMount() {
    const { actions, history: { location }, state: { rolesData } } = this.props

    actions.clearTeamData()
    actions.getRoleManagementRoles()

    if (!location?.search) {
      this.state.roles = [...rolesData]
      return
    }

    const { id } = parseQueryString(location.search)
    const s = {
      editMode: id && !!location.state?.editMode,
    }

    this.setState(s)

    const payload = parseQueryString(location.search)
    setTimeout(() => {
      actions.getTeamData(payload)
    }, Config.COMPONENT_DATA_LOAD_TIME)
  }

  componentDidUpdate(prevProps) {
    const { props } = this
    const prevState = prevProps.state
    const currState = props.state

    if (_.isEqual(prevState, currState)) return
    this._updateState(currState)
  }

  _updateFields = (el, dateData) => {
    const { name, value } = dateData || el.currentTarget

    this.setState((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        [name]: value,
      },
    }))
  }

  _updateIsActive = () => {
    const { form } = this.state

    form.active = !form.active
    this.setState({ form })
  }

  _handleChange = (el) => {
    const { state } = this
    const roles = [...state.roles]

    const { name, checked, value } = el.currentTarget
    const rowToUpdate = { ...roles[value] }

    rowToUpdate.enable = checked
    roles[value] = rowToUpdate

    this.setState({ roles, rowUpdated: value, columnUpdate: name })

    // gridApi.refreshCells()
  }

  _goBack = () => {
    const { props: { history } } = this
    history.goBack()
  }

  _submitForm = () => {
    const { props, state } = this
    const { form, roles } = state
    const payload = {
      ...form,
      roles,
    }
    if (state.editMode && form.id) {
      props.actions.editTeamManagementData(payload)
    } else {
      props.actions.createTeamManagementData(payload)
    }
  }

  _saveRoleForm = () => {
    const { props, state } = this
    const { form, roles } = state
    const headerText = 'You are about to save the Team. Do you wish to save?'

    props.actions.setModalComponent({
      modalProperies: {
        size: 'tiny',
      },
      contents: SaveTierModal({
        conditions: roles,
        form,
        headerText,
        onSaveClick: props.actions.editTeamManagementData,
        toggleModal: props.actions.toggleModal,
      }),
    })
    props.actions.toggleModal()
  }

  _updateState(currState) {
    const { form } = this.state
    const { state: { rolesData } } = this.props
    const formData = Object.keys(form).reduce((acc, curr) => {
      acc[curr] = currState.teamData[curr]

      return acc
    }, {})
    const { roles } = currState.teamData
    this.setState((prevState) => ({
      ...prevState,
      form: formData,
      roles: roles.length ? [...roles] : [...rolesData],
    }))
  }

  render() {
    const { props, state } = this
    const {
      form, editMode, roles,
    } = state
    const rolesOptions = roles.map((role) => ({ role_name: role.name, functions: role.functions, enable: role.enable }))
    let headerText = editMode ? 'Create Team' : 'View Team'
    const { location: { search } } = props.history

    if (search && editMode) headerText = 'Edit Team'

    return (
      <div id="createTeam">
        <BreadCrumb {...this.props} />
        <div className="header">
          <h1>{headerText}</h1>
        </div>
        <div className="content">
          <TeamRoles
            editMode={editMode}
            form={form}
            goBack={this._goBack}
            handleChange={this._handleChange}
            roles={rolesOptions}
            saveForm={this._saveRoleForm}
            submitForm={this._submitForm}
            updateFields={this._updateFields}
            updateIsActive={this._updateIsActive}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  state: {
    teamData: state.teamManagement.teamData,
    rolesData: state.roleManagement.roleListing,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
    ...NavigationActions,
    ...teamManagementActions,
    ...RolesActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateTeam)
