import React, { PureComponent, useEffect, useState, useRef } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Creators as ClientConfigurationActions } from "../../../../../redux/reducers/api/clientConfiguration";
import HierarchyDashboardTab from "../../hierarchyDashboardTab";
import TabBar from "../../../../../components/tabBar";
import FliptButton from "../../../../../components/form/fliptButton";
import { Creators as UserActions } from '../../../../../redux/reducers/userManagement/index'
import {
  organizationTabConfigs,
  ORGANIZATION_TABS_LOCAL_STORAGE_KEY,
  REQUIRED_TAB_ID,
} from "../Constant";
import CloseModal from "../../CloseModal";
const AddAccount = (props) => {
  const [activeTabId, setActiveTabId] = useState(REQUIRED_TAB_ID);
  const queueRef = useRef(null);
  const [modalPopup, setModalPopup] = useState(false);
  const { state, actions } = props;
  const {  user } = state;
  const { singleLevelAttribute, defaultButtonName,parentId } = state;
  const defaultTabName = state.defaultTabName;
  const setActiveTab = (id, tabs) => {
    setActiveTabId(id);
    actions.setTab(id);
  };

  const renderHierarchyTab = () => {
    return organizationTabConfigs.map((tabConfig, index) => {
      return (
        tabConfig.id === defaultTabName && (
          <HierarchyDashboardTab
            key={index}
            tabConfig={tabConfig}
            queueRef={queueRef}
          />
        )
      );
    });
  };
  return (
    <>
      <div id="add-organization">
        <div>
          <TabBar
            tabs={organizationTabConfigs}
            setActiveTab={setActiveTab}
            localStorageKey={ORGANIZATION_TABS_LOCAL_STORAGE_KEY}
            draghandle
          />

          {renderHierarchyTab()}
        </div>
      </div>
      <div id="hierarchy-dashboard-tab">
        <div
          className="view-more"
          onClick={() => {
            let payload = {
              parent_id: parentId,
              addAnother: true,
              data: {
                node_key: singleLevelAttribute?.account_custom_id,
                node_name:  singleLevelAttribute?.account_custom_name,
                created_by:`${user.first_name} ${user.last_name}`,
                node_type: "4",
                owner_id: "fast-fixture",
                attributes: singleLevelAttribute,
              },
            };
            actions.createSingleLevel(payload);
          }}
        >
          {"Save and Add another Account"}
        </div>
        <div className="fields-btn-container">
          <div className="fields-header">
            <FliptButton
              className="secondary draft"
              name="Cancel and Discard"
              onClick={() => {
                setModalPopup(true);
              }}
            />
          </div>
          {modalPopup && (
            <CloseModal
              open={modalPopup}
              discard={() => {
                let data = {
                  node_type: defaultButtonName.toLowerCase(),
                };
                actions.setSingleLevelAttribute({})
                actions.getDefaultHierarchyList(data);
                setModalPopup(false);
              }}
              onClose={() => {
                setModalPopup(false);
              }}
            />
          )}
          <div className="fields-header">
            <FliptButton
              className="primary draft gap"
              name="Save"
              onClick={() => {
                let payload = {
                  parent_id: parentId,
                  addAnother: false,
                  data: {
                    node_key: singleLevelAttribute?.account_custom_id,
                    node_name:  singleLevelAttribute?.account_custom_name,
                    created_by:`${user.first_name} ${user.last_name}`,
                    node_type: "4",
                    owner_id: "fast-fixture",
                    attributes: singleLevelAttribute,
                  },
                };
                actions.createSingleLevel(payload);
              }}
            />
            <FliptButton
              className="primary draft"
              name="Save and Add Group"
              onClick={() => {
                let payload = {
                  parent_id: parentId,
                  addAnother: false,
                  pageToMove: 4,
                  data: {
                    node_key: singleLevelAttribute?.account_custom_id,
                    node_name:  singleLevelAttribute?.account_custom_name,
                    created_by:`${user.first_name} ${user.last_name}`,
                    node_type: "4",
                    owner_id: "fast-fixture",
                    attributes: singleLevelAttribute,
                  },
                };
                actions.createSingleLevel(payload);
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    state: {
      user: state?.user,
      parentId: state.clientConfiguration.parentId,
      defaultButtonName: state.clientConfiguration.defaultButtonName,
      defaultTabName: state.clientConfiguration.defaultTabName,
      singleLevelAttribute: state.clientConfiguration.singleLevelAttribute,
    },
  };
};

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...ClientConfigurationActions,
  };

  return {
    actions: bindActionCreators(allActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddAccount);
