/* eslint-disable */
import '../styles.scss'
import React, { useRef, useState, useMemo } from 'react'
import moment from 'moment'
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import _ from "lodash"

import { Creators as FormularyCreationActions } from "../../../../../../redux/reducers/api/formularyCreationManagement"

import AddRow from '../addRow'
import FliptButton from '../../../../../../components/form/fliptButton'
import FliptDatePicker from '../../../../../../components/form/fliptDatePicker'
import FliptGrid from '../../../../../../components/fliptGrid'
import FliptInput from '../../../../../../components/form/fliptInput'
import rowCellInfo from '../data/rowCellInfo'
import { ButtonRenderer, CheckboxRenderer, DeleteRowRenderer } from '../../../../../../components/fliptGrid/cellRenderers'
import { InputTextEditor, DropdownEditor, SearchEditor, DatePickerEditor } from '../../../../../../components/fliptGrid/cellEditors'
import { addRemoveDaysToDate, convertStrToDateObj, buildDropdownOptions } from '../../../../../../utils/utilities'
import { frf_options } from '../../../../../../config/constants/clinical/drugGroups'
import FliptHierarchyDropdown from '../../../../../../components/form/fliptHierarchyDropdown'
import { deleteUnnecessaryFields } from '../../../../../../utils/utilizationManagement'
import * as ApprovalsConstants from '../../../../../../redux/sagas/rpm/constants'

function TierConditions(props) {
  const { DRAFT, PUBLISHED, REJECTED } = ApprovalsConstants
  const {
    form, setForm, tierConditions, updateFields,
    handleChange, dropdownOptions, autoSuggestionValues, autoSuggestionResultSelect,
    autoSuggestionMinChars, exportAll, addRow, delRow, onCheckboxChange, sendForReview,
    editMode, cellRenderers, conditionsDropDownOptions, saveFormularyTier,
    doc_id
  } = props
  const {
    name, effective_start_date, version, status, version_effective_date,
    target_system_formulary_tier, target_system_formulary_status
  } = form

  const { multiSourceOptions, brandGenericOptions, otcIndicatorOptions, DESIOptions,
    routeOfAdministrationOptions, drugIndicatorOptions, dosageFormOptions,
    thirdPartyRestrictionOptions, deaClassCodeOptions, storageConditionCodeOptions,
    marketingCategoryOptions, applicationTypeOptions } = conditionsDropDownOptions

  const FRFCategoryOptions = Object.keys(frf_options).map((code, index) => ({
    key: index,
    text: frf_options[code].text,
    value: frf_options[code].value,
  }))

  const effectiveDateRef = useRef(true);
  rowCellInfo.brand_generic.options = brandGenericOptions
  rowCellInfo.desi_code.options = DESIOptions
  rowCellInfo.dosage_form_cd.options = dosageFormOptions
  rowCellInfo.maintenance_drug_code.options = drugIndicatorOptions
  rowCellInfo.multi_source.options = multiSourceOptions
  rowCellInfo.otc_indicator.options = otcIndicatorOptions
  rowCellInfo.frf_category.options = FRFCategoryOptions

  rowCellInfo.route_of_administration.options = routeOfAdministrationOptions
  rowCellInfo.third_party_restriction_code.options = thirdPartyRestrictionOptions
  rowCellInfo.repackaged_code.options = [{ key: "Y", value: "Y", text: "Repackaged" }, { key: "N", value: "N", text: "Not Repackaged" }]
  rowCellInfo.dea_class_code.options = deaClassCodeOptions
  rowCellInfo.clinic_pack_code.options = [{ key: "Y", value: "Y", text: "Y" }, { key: "N", value: "N", text: "N" }]
  rowCellInfo.innerpack_code.options = [{ key: "Y", value: "Y", text: "Y" }, { key: "N", value: "N", text: "N" }]
  rowCellInfo.unit_dose_with_non_unit_dose_non_repackager_in_gpi.options = [{ key: "Y", value: "Y", text: "Y" }, { key: "N", value: "N", text: "N" }]
  rowCellInfo.rx_with_otc_in_gpi.options = [{ key: "Y", value: "Y", text: "Y" }, { key: "N", value: "N", text: "N" }]
  rowCellInfo.storage_condition_code.options = storageConditionCodeOptions
  rowCellInfo.marketing_category.options = marketingCategoryOptions
  rowCellInfo.application_type_flag.options = applicationTypeOptions
  rowCellInfo.unit_dose.options = [{ key: "U", value: "U", text: "U" }, { key: "X", value: "X", text: "X" }]
  rowCellInfo.item_status_flag.options = buildDropdownOptions(["A", "I"])
  rowCellInfo.fda_therapeutic_equivalence_code.options = buildDropdownOptions(["AA",
    "AB",
    "AB1",
    "AB1,AB2",
    "AB1,AB2,AB3",
    "AB1,AB2,AB3,AB4",
    "AB1,AB3",
    "AB2",
    "AB3",
    "AB4",
    "AN",
    "AO",
    "AP",
    "AP1",
    "AP2",
    "AT",
    "AT1",
    "AT2",
    "AT3",
    "BC",
    "BD",
    "BP",
    "BS",
    "BX"])

  const [showGenerateDrugsGrid, setShowGenerateDrugsGrid] = useState(false)
  const [currentGenerateDrugCondition, setCurrentGenerateDrugCondition] = useState({})
  const [gridKey, setGridKey] = useState('')

  if (editMode) {
    rowCellInfo.action = {
      type: 'deleteRow',
      disabled: false,
    }
    Object.keys(rowCellInfo).forEach((key) => { rowCellInfo[key].disabled = false })
  } else {
    delete rowCellInfo.action
    Object.keys(rowCellInfo).forEach((key) => { rowCellInfo[key].disabled = true })
  }
  let headers = Object.keys(rowCellInfo)

  if (headers.includes('action')) {
    headers.splice(headers.indexOf('action'), 1)
    headers.unshift('action')
  }

  const gridHeaders = [
    'gpi', 'rxcui', 'frf_category', 'medicare_type', 'drug_group', 'drug_class', 'drug_subclass', 'manufacturer', 'drug_name',
    'multi_source', 'ndc', 'usp_category', 'usp_class', 'usp_pharmacotherapeutic_group', 'ddid', 'brand_generic', 'otc_indicator',
    'route_of_administration', 'desi_code', 'maintenance_drug_code', 'application_type_flag',
    'repackaged_code', 'mfg_labeler_id', 'dosage_form_cd', 'third_party_restriction_code'
  ]

  const minTierStartDate = moment(addRemoveDaysToDate(1, false)).format('MM-DD-YYYY')

  const handleGenerateDrugsClick = (data, submitType, rowIndex) => {
    // reusing the same object reference and not using setState for currentGenerateDrugCondition
    // is intentional. it is necessary for FliptGrid to function correctly
    Object.assign(currentGenerateDrugCondition, { ...data, include_inactive_drugs: form.include_inactive_drugs })

    const tempObj = deleteUnnecessaryFields(currentGenerateDrugCondition)
    const randomNumber = Math.floor(Math.random() * 900) + 100;
    const key = Object.values(tempObj).reduce((acc, newValue) => acc = `${acc}${newValue || ''}${randomNumber}`, '')
    setGridKey(key)

    if (!showGenerateDrugsGrid) {
      setShowGenerateDrugsGrid(true)
    }
  }

  let cellRendererParams = {
    action: {
      cellRenderer: DeleteRowRenderer,
      state: {
        onClick: delRow,
      },
      width: 95,
    },
    include: {
      overrideHeader: 'Include',
      cellRenderer: CheckboxRenderer,
      state: {
        onCheckboxChange,
      },
      width: 100,
    },
    generate_drugs: {
      cellRenderer: ButtonRenderer,
      searchArgs: { onClick: handleGenerateDrugsClick, text: 'Generate Drugs' },
      width: 175,
    },
    frf_category: {
      filter: false,
      overrideHeader: 'FRF Category',
      options: form.frfOptions,
      hide: true,
    },
    rxcui: {
      filter: false,
      overrideHeader: 'RXCUI',
      hide: true,
    },
    usp_category: {
      hide: true,
      overrideHeader: 'USP Category',
      filter: false,
    },
    usp_class: {
      hide: true,
      filter: false,
      overrideHeader: 'USP Class',
    },
    usp_pharmacotherapeutic_group: {
      hide: true,
      filter: false,
      overrideHeader: 'USP Pharmacotherapeutic Group',
    },
    effective_start_date: {
      valueFormatter: (params) => {
        if (!params.value) return ''
        return moment(params.value)?.format('YYYY-MM-DD') || params.value
      },
    },
    effective_end_date: {
      valueFormatter: (params) => {
        if (!params.value) return ''
        return moment(params.value)?.format('YYYY-MM-DD') || params.value
      },
    }
  }

  const defaultColDef = useMemo(() => ({
    resizable: true,
    floatingFilter: true,
    filter: "agTextColumnFilter",
    filterParams: { buttons: ["reset"], filterOptions: ['contains', 'equals'] },
    outerHeight: 120,
  }), [])

  const cellEditorParams = {
    gpi: {
      cellEditor: InputTextEditor,
      onChange: handleChange,
    },
    rxcui: {
      cellEditor: InputTextEditor,
      onChange: handleChange,
    },
    frf_category: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    drug_group: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    drug_class: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    drug_subclass: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    manufacturer: {
      cellEditor: SearchEditor,
      onChange: handleChange,
      autoSuggestionResultSelect,
    },
    drug_name: {
      cellEditor: SearchEditor,
      onChange: handleChange,
      autoSuggestionResultSelect,
    },
    usp_category: {
      cellEditor: InputTextEditor,
      onChange: handleChange,
    },
    usp_class: {
      cellEditor: InputTextEditor,
      onChange: handleChange,
    },
    usp_pharmacotherapeutic_group: {
      cellEditor: InputTextEditor,
      onChange: handleChange,
    },
    multi_source: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    ndc: {
      cellEditor: InputTextEditor,
      onChange: handleChange,
    },
    ddid: {
      cellEditor: InputTextEditor,
      onChange: handleChange,
    },
    item_status_flag: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    brand_generic: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    otc_indicator: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    desi_code: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    route_of_administration: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    maintenance_drug_code: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    application_type_flag: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    mfg_labeler_id: {
      cellEditor: InputTextEditor,
      onChange: handleChange,
    },
    repackaged_code: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    third_party_restriction_code: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    dosage_form_cd: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    clinic_pack_code: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    innerpack_code: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    unit_dose: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    dea_class_code: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    fda_therapeutic_equivalence_code: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    marketing_category: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    storage_condition_code: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    unit_dose_with_non_unit_dose_non_repackager_in_gpi: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    rx_with_otc_in_gpi: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    member_notes: {
      cellEditor: InputTextEditor,
      onChange: handleChange,
    },
    agent_notes: {
      cellEditor: InputTextEditor,
      onChange: handleChange,
    },
    internal_notes: {
      cellEditor: InputTextEditor,
      onChange: handleChange,
    },
    claim_message_code: {
      cellEditor: InputTextEditor,
      onChange: handleChange,
    },
    claim_message_type: {
      cellEditor: InputTextEditor,
      onChange: handleChange,
    },
    target_system_formulary_tier: {
      cellEditor: InputTextEditor,
      onChange: handleChange,
    },
    target_system_formulary_status: {
      cellEditor: InputTextEditor,
      onChange: handleChange,
    },
    effective_start_date: {
      cellEditor: DatePickerEditor,
      onChange: handleChange,
    },
    effective_end_date: {
      cellEditor: DatePickerEditor,
      onChange: handleChange,
    }
  }

  cellRendererParams = { ...cellRendererParams, ...cellRenderers }

  const serverSideGridConfig = {
    rowModel: 'serverSide',
    serverSideStoreType: 'partial',
    cacheBlockSize: 20,
    pagination: true,
    paginationPageSize: 20,
  }

  const serverSideGridParams = {
    form: currentGenerateDrugCondition,
    apiId: 'generate-program-list',
    sagaToCall: props.actions.generateFormularyTier,
    headers,
  }

  const hiddenColumn = Object.keys(cellRendererParams).filter(key => cellRendererParams[key]?.hide)
  const columnData = {}
  tierConditions.map(ele => {
    Object.keys(ele).forEach(key => {
      columnData[key] = !!columnData[key] || !!ele[key]
    })
  })
  hiddenColumn.forEach(key => {
    if (columnData[key])
      cellRendererParams[key].hide = false
  })
  const dateValue = effective_start_date ? convertStrToDateObj(new Date(effective_start_date)) : ""

  if (form?.medicare_drug_list === 'N' || !form?.medicare_drug_list) {
    let medicare_columns = ['rxcui', 'frf_category', 'usp_category', 'usp_class', 'usp_pharmacotherapeutic_group']
    headers = headers.filter(item => !medicare_columns.includes(item))
  }
  const gridProps = editMode ? {
    autoSuggestionMinChars,
    autoSuggestionValues,
    cellEditorParams,
    cellRendererParams,
    data: tierConditions,
    dropdownOptions,
    headers,
    rowCellInfo,
  } : {
    data: tierConditions,
    headers,
  }

  const generateDrugsCellRendererParams = _.cloneDeep(cellRendererParams)
  generateDrugsCellRendererParams.ndc.hide = false
  delete generateDrugsCellRendererParams.include
  const unsortableKeys = ['rxcui', 'frf_category', 'usp_category', 'usp_class', 'usp_pharmacotherapeutic_group',
    'effective_start_date', 'effective_end_date'
  ]
  for (let key in generateDrugsCellRendererParams) {
    if (generateDrugsCellRendererParams[key]) {
      if (unsortableKeys.includes(key)) {
        generateDrugsCellRendererParams[key].sortable = false
      }
      generateDrugsCellRendererParams[key].filterParams = {
        filterOptions: ['contains', 'equals'],
        defaultJoinOperator: 'OR',
        buttons: ['reset'],
      }
    }
  }

  return (
    <div className="section">
      <section className="tier-inputs-container spacing border-line shadow">
        <div className="tier-inputs">
          <span>{props.changeToDrugList ? 'Drug List Name' : 'Tier Name'}</span>
          <FliptInput className="createTierInputs" name="name" value={name} onChange={updateFields} readOnly={!editMode} disabled={(!!version && (version !== '1.0') || (!!status && (status === 'PUBLISHED')))} />
        </div>
        <div className="tier-inputs">
          <span>Effective Start Date</span>
          <FliptDatePicker className="create-tier-start-date" name="effective_start_date" value={dateValue} minDate={minTierStartDate} onChange={(e, dropdown) => updateFields(e, dropdown, effectiveDateRef)} format="MM-DD-YYYY" readOnly={!editMode} />
        </div>
        <div className="tier-inputs">
          <span>Version</span>
          <FliptInput disabled className="create-tier-start-date" name="version" value={version} onChange={updateFields} format="MM-DD-YYYY" readOnly={!editMode} />
        </div>
        <div className="tier-inputs">
          <span>Version Effective Date</span>
          <FliptDatePicker className="create-tier-start-date" name="version_effective_date" value={convertStrToDateObj(version_effective_date)} onChange={updateFields} format="MM-DD-YYYY" readOnly={!editMode} />
        </div>
        <div className="tier-inputs">
          <span>Target System Formulary Tier</span>
          <FliptInput className="createProgramListInputs" name="target_system_formulary_tier" value={target_system_formulary_tier} onChange={updateFields} />
        </div>
        <div className="tier-inputs">
          <span>Target System Formulary Status</span>
          <FliptInput className="createProgramListInputs" name="target_system_formulary_status" value={target_system_formulary_status} onChange={updateFields} />
        </div>
        <FliptHierarchyDropdown
          showEnhancedTier={true}
          showMedicareDrugList={true}
          showSupplimentalDrugList={true}
          showExchangeDrugList={true}
          showInactiveDrugs={true}
          changeToDrugList={props.changeToDrugList}
          setForm={setForm}
          form={form}
          showHierarchyGlobal={true} />
      </section>
      <section className='button-header-section'>
        <span className="checkbox-label"></span>
        <FliptButton name="Export Drug List" className="export-button" onClick={exportAll} />
      </section>
      <section className="grid-container spacing border-line shadow">
        <FliptGrid
          {...gridProps}
        />
      </section>
      {editMode &&
        (<div className="addRowButtonContainer">
          <AddRow addRow={addRow} />
        </div>)
      }
      {showGenerateDrugsGrid && <div className="filtersSection">
        <div className="filterConditionsGrid">
          <FliptGrid
            key={gridKey}
            defaultColDef={defaultColDef}
            headers={gridHeaders}
            cellRendererParams={generateDrugsCellRendererParams}
            serverSideGridConfig={serverSideGridConfig}
            serverSideGridParams={serverSideGridParams}
            suppressColumnVirtualisation
          />
        </div>
      </div>}
      <div className="buttonContainer">
        {editMode &&
          <FliptButton
            className="primary searchButton"
            disabled={![DRAFT, PUBLISHED, REJECTED].includes(status)}
            name="Save"
            onClick={saveFormularyTier}
          />}
        {editMode && doc_id &&
          <FliptButton
            className="primary searchButton"
            disabled={status !== DRAFT}
            name="Send to Peers for Review/Approval"
            onClick={sendForReview}
          />}
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...FormularyCreationActions,
  };

  return {
    actions: bindActionCreators(allActions, dispatch),
  };
};

export default connect(
  null,
  mapDispatchToProps
)(React.memo(TierConditions));
