import { call, put, select, takeLatest } from "redux-saga/effects"
import {
  Types,
  Creators as SurescriptConfigLookupActions,
} from "../../reducers/api/sureScriptConfig"
import { Creators as AppActions } from "../../reducers/app"
import {
  getAppState,
  getApiPath,
  getSurescriptConfigLookup,
  getHistory,
} from "../../reducers/selectors"
import { fetchGet, fetchPost } from "../../../utils/fetchApi"
import { createQueryString, filterRowData } from "../../../utils/utilities"

export default [getSurescriptConfigDataWatcher, insertSurescriptConfigDataWatcher, updateSurescriptConfigDataWatcher]

/* WATCHERS */

function* getSurescriptConfigDataWatcher() {
  yield takeLatest(
    Types.GET_SURESCRIPT_CONFIG_DATA,
    getSurescriptConfigDataHandler
  )
}

function* insertSurescriptConfigDataWatcher() {
  yield takeLatest(
    Types.INSERT_SURESCRIPT_CONFIG_DATA,
    insertSurescriptConfigDataHandler
  )
}

function* updateSurescriptConfigDataWatcher() {
  yield takeLatest(
    Types.UPDATE_SURESCRIPT_CONFIG_DATA,
    updateSurescriptConfigDataHandler
  )
}

/* HANDLERS */
function* getSurescriptConfigDataHandler({ payload }) {
  try {
    if (payload) {
      yield put(SurescriptConfigLookupActions.setLoading({ data: true }))
      const { serviceUrl } = yield select(getAppState)
      const cd = yield select(getApiPath, "ss-formulary-config-get")
      const { api_path } = cd
      const url = `${serviceUrl}${api_path}?surescripts_doc_id=${payload.surescripts_doc_id}`
      const { data } = yield call(fetchGet, url)
      yield put(SurescriptConfigLookupActions.setPopupData(data[0]))
      yield put(SurescriptConfigLookupActions.setLoading({ data: false }))

    } else {
      yield put(SurescriptConfigLookupActions.setLoading({ data: true }))
      const { serviceUrl } = yield select(getAppState)

      const cd = yield select(getApiPath, "ss-formulary-config-get")
      const { api_path } = cd
      const url = `${serviceUrl}${api_path}`
      const { data } = yield call(fetchGet, url, {}, false)
      yield put(SurescriptConfigLookupActions.setSurescriptConfigData({ data }))
      yield put(SurescriptConfigLookupActions.setLoading({ data: false }))

    }
  } catch (err) {
    console.log("getSurescriptConfigDataHandler Error >Data ", err)
    const transitionalPortal = {
      header: "SurescriptConfig Lookup Failed",
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    yield put(SurescriptConfigLookupActions.setLoading({ data: false }))
  }
}

function* insertSurescriptConfigDataHandler({ payload }) {
  try {
    yield put(SurescriptConfigLookupActions.setLoading({ data: true }))
    const { serviceUrl } = yield select(getAppState)

    const cd = yield select(getApiPath, "ss-formulary-config-insert")
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`
    const { data } = yield call(fetchPost, url, payload)
    yield put(SurescriptConfigLookupActions.setCurrentFormularyConfigData(data[0]))
    yield put(SurescriptConfigLookupActions.setLoading({ data: false }))
  } catch (err) {
    console.log("getSurescriptConfigSuggestDataHandler Error >Data ", err)
    const transitionalPortal = {
      header: "SurescriptConfig Lookup Failed",
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    yield put(SurescriptConfigLookupActions.setLoading({ data: false }))
  }
}

function* updateSurescriptConfigDataHandler({ payload }) {
  try {
    yield put(SurescriptConfigLookupActions.setLoading({ data: true }))
    const { serviceUrl } = yield select(getAppState)
    const cd = yield select(getApiPath, "ss-formulary-config-update")
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`
    const { data } = yield call(fetchPost, url, payload)
    yield put(
      SurescriptConfigLookupActions.clearCurrentSurescriptConfigData()
    )
    yield put(SurescriptConfigLookupActions.setLoading({ data: false }))
    yield call(getSurescriptConfigDataHandler, payload)
  } catch (err) {
    console.log("updateSurescriptConfigDataHandler Error >Data ", err)
    const transitionalPortal = {
      header: "updateSurescriptConfigDataHandler Failed",
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    yield put(SurescriptConfigLookupActions.setLoading({ data: false }))
  }
}