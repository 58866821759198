import {
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects'

import { Types, Creators as AccountManagementActions } from '../../reducers/api/accountManagement'
import { Creators as AppActions } from '../../reducers/app'
import { Creators as NavigationActions } from '../../reducers/navigation'
import { getAppState, getApiPath } from '../../reducers/selectors'
import { fetchGet, fetchPost, fetchPut } from '../../../utils/fetchApi'

export default [
  getAccountsWatcher,
  getNewAccountWatcher,
  saveNewAccountWatcher,
]

/* WATCHERS */
function* getAccountsWatcher() {
  yield takeLatest(Types.GET_ACCOUNTS, getAccountsHandler)
}

function* getNewAccountWatcher() {
  yield takeLatest(Types.GET_NEW_ACCOUNT, getNewAccountHandler)
}

function* saveNewAccountWatcher() {
  yield takeLatest(Types.SAVE_NEW_ACCOUNT, saveNewAccountHandler)
}

/* HANDLERS */
function* getAccountsHandler() {
  try {
    const { serviceUrl } = yield select(getAppState)
    const { api_path } = yield select(getApiPath, 'account-design-setup')
    const url = `${serviceUrl}${api_path}`
    const { data } = yield call(fetchPost, url)

    yield put(AccountManagementActions.setAccounts({ data }))
  } catch (err) {
    console.log('getAccountsHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Retrieving Accounts Failed',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* getNewAccountHandler() {
  try {
    const { serviceUrl } = yield select(getAppState)
    const { api_path } = yield select(getApiPath, 'create-account')
    const url = `${serviceUrl}${api_path}`
    const { data } = yield call(fetchGet, url)

    yield put(AccountManagementActions.setNewAccount({ data: data[0] }))
  } catch (err) {
    console.log('getNewAccountsHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Get New Account Failed',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* saveNewAccountHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const { api_path } = yield select(getApiPath, 'create-account')
    const url = `${serviceUrl}${api_path}`
    yield call(fetchPut, url, payload)

    yield put(NavigationActions.navigateBack())
  } catch (err) {
    console.log('saveNewAccountsHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Saving New Account Failed',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}
