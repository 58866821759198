import React, { useEffect, memo } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts';

import { Creators as PriorAuthorizationCreationActions } from '../../../../redux/reducers/api/priorAuthorizationManagement'
import { Creators as AppActions } from '../../../../redux/reducers/app'

import './styles.scss'

const MonthlyChart = ({ cases_per_month }) => {
  if (!cases_per_month) {
    return <></>
  }
  return (
    <div className='monthly-cases-container'>
      <div className='monthly-cases-title-container'>
        <span className='monthly-cases-title'>Case trend by month</span>
      </div>
      <div className='monthly-cases-chart'>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={500}
            height={300}
            data={cases_per_month}
            margin={{
              top: 20,
              right: 20,
              left: 20,
              bottom: 20,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" label={{ value: 'Month', offset: -15, position: 'insideBottom' }} />
            <YAxis label={{ value: 'No of Cases', angle: -90, position: 'insideLeft' }} />
            <Tooltip />
            <Bar dataKey="cases" stackId="a" fill="#8884d8" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>)
}

const PAReports = (props) => {
  const { state, actions } = props
  const { cases_per_month,
    todays_total_cases,
    todays_closed_cases
  } = state.reports
  useEffect(() => {
    actions.getPaDashboardReports()
    return actions.clearPaDashboardReports()
  }, [])

  return <div id='pa-charts'>
    <div className='stats-row'>
      <div className='stats-container'>
        <span className='stats-title'>Today's Total Cases</span>
        <span className='stats'>{todays_total_cases}</span>
      </div>
      <div className='stats-container'>
        <span className='stats-title'>Today's Case Closure</span>
        <span className='stats'>{todays_closed_cases}</span>
      </div>
    </div>
    <div className='charts-container-1'>
      <MonthlyChart cases_per_month={cases_per_month} />
    </div>
  </div>

}

const mapStateToProps = (state) => {
  return {
    state: {
      app: state.app,
      reports: state.priorAuthorizationCreation.paDashboardReports,
    },
  }
}

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...PriorAuthorizationCreationActions,
    ...AppActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(memo(PAReports))
