import React from 'react'

import AddressSection from './component/addressSection'
import EmailSection from './component/emailSection'
import PhoneNumberSection from './component/phoneNumberSection'
import VipSection from './component/vipSection'

export default ({
  memberLookup, actions, updateMemberDataForm, updateMemberDataFields,
}) => {
  const { locations, personal_phones, email_addresses, is_vip } = memberLookup.member_details

  return (
    <div id="updateMemberModal">
      <AddressSection locations={locations} updateFields={updateMemberDataFields} submitForm={updateMemberDataForm} toggleModal={actions.toggleModal} />
      <PhoneNumberSection personal_phones={personal_phones} updateFields={updateMemberDataFields} submitForm={updateMemberDataForm} toggleModal={actions.toggleModal} />
      <EmailSection email_addresses={email_addresses} updateFields={updateMemberDataFields} submitForm={updateMemberDataForm} toggleModal={actions.toggleModal} />
      <VipSection is_vip={is_vip} updateFields={updateMemberDataFields} submitForm={updateMemberDataForm} toggleModal={actions.toggleModal} />
    </div>
  )
}
