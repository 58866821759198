import React, { useState, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import FliptDropdown from '../../../../components/form/fliptPaDropdown'
import { DropdownEditor, InputTextEditor, NestedInputEditor } from '../../../../components/fliptGrid/cellEditors'
import { calcGridRowHeight, createQueryString, filterRowData } from '../../../../utils/utilities'
import rowCellInfo from '../data/rowCellInfo'
import Config from '../../../../config'
import { discountType } from '../data/dropdownData'
import { Creators as contractManagementActions } from '../../../../redux/reducers/api/contractManagement'
import { Creators as NetworkCreationActions } from '../../../../redux/reducers/api/networkCreationManagement'
import FliptButton from '../../../../components/form/fliptButton'
import FliptGrid from '../../../../components/fliptGrid'
import { Creators as AppActions } from '../../../../redux/reducers/app'
import { DeleteRowRenderer, MultiRowRenderer, ColorCodedStatusRenderer } from '../../../../components/fliptGrid/cellRenderers'
import * as _ from 'lodash'

function ContractPublishAlertPopup(props) {
    const { publishDoc, rowdata, state: { contractData, allNetworkData }, actions } = props
    const [options, setOptions] = useState({})
    const [auto_publish_pharmacy, setPublishDoc] = useState('')
    const [networkData, setNetworkData] = useState([])
    const [selectedNetwork, setselectedNetwork] = useState([])
    const [gridData, setGridData] = useState([{
        source_contract: '',
        include_exclude: '',
        program_drug_list: [],
        source_price_type: '',
        rate_type: '',
        fee_type: {},
        fee_name: '',
        fee_value: {},
        adjustment_type: '',
        adjustment_rate: '',
        target_price_type: '',
        target_price: '',
        mac_list_id: ''
    }])
    const publishOptions = [
        {
            key: 0,
            text: 'Yes',
            value: 'Y',
        },
        {
            key: 1,
            text: 'No',
            value: 'N',
        }
    ]
    const addCondition = () => {
        const tempGridData = [...gridData]
        const obj = {
            claim_processor: '',
            price_type: '',
            specialty_drug_list: [],
            min_days_supply: '',
            max_days_supply: '',
            fixed_variable: '',
            rate_type: '',
            discount: '',
            fee_name: '',
            fee_type: '',
            fee_value: '',
            mac_list_id: '',
            price_floor: ''
        }
        tempGridData.push(obj)
        setGridData(tempGridData)
    }

    const deleteCondition = (rowIndex) => {
        const tempGridData = [...gridData]
        tempGridData.splice(rowIndex, 1)
        setGridData(tempGridData)
    }

    useEffect(() => {
        const { source_contracts = {}, program, model_data } = contractData || {}
        const tempGridData = model_data?.contact_criteria?.length ? model_data.contact_criteria.map((data) => {
            return {
                claim_processor: data.source_contract,
                price_type: data.target_price_type,
                specialty_drug_list: data.program_drug_list,
                min_days_supply: '',
                max_days_supply: '',
                fixed_variable: data.adjustment_type,
                rate_type: data.rate_type,
                discount: data.adjustment_rate,
                fee_name: '',
                fee_type: '',
                fee_value: '',
                mac_list_id: data.mac_list_id,
                price_floor: ''
            }
        }) : []
        setGridData(tempGridData)
        if (_.isEmpty(options) && Object.keys(source_contracts).length || !_.isEmpty(program)) {
            const source_contracts_names = Object.keys(source_contracts)
            setOptions({ contractPriceSource: source_contracts_names, program })
        }
    }, [contractData])

    useEffect(() => {
        const headerMapping = {
            doc_id: 'doc_id',
            doc_name: 'network_name',
            doc_version: 'version',
            status: 'status',
            effective_start_date: 'start_date',
            effective_end_date: 'end_date',
        }
        const tempNetworkData = []
        allNetworkData.forEach((d) => {
            if (d.status === 'Published') tempNetworkData.push({ ...filterRowData(d, headers, headerMapping) })
        })


        setNetworkData(tempNetworkData)
    }, [allNetworkData])

    useEffect(() => {
        const params = {
            doc_id: rowdata.doc_id,
            doc_name: rowdata.doc_name,
            doc_version: rowdata.doc_version
        }
        const qs = createQueryString(params)
        actions.getContractData(qs)
        setTimeout(() => actions.getAllNetworkTiersData({ doc_type: 'global_network_tier' }), Config.COMPONENT_DATA_LOAD_TIME)
    }, [])

    const checkActionValue = () => {
        if (auto_publish_pharmacy === 'N') publishDoc(rowdata, auto_publish_pharmacy)
        else {
            publishDoc(rowdata, auto_publish_pharmacy, selectedNetwork, gridData)
        }
    }

    const displayFeeValue = (param) => {
        const { data, value } = param
        const keyArr = value && Object.keys(value)
        keyArr?.length && keyArr.forEach((key) => {
            if (data?.fee_type[key] === 'VARIABLE') {
                const newValue = value[key].replace('%', '')
                value[key] = `${newValue}%`
            }
        })
        return value
    }

    const parseData = ({ data, row }) => {
        const values = Object.values(data)
        const keys = !(_.isEmpty(data)) ? Object.keys(data) : row.fee_name
        return keys?.length == 0 ? [] : keys?.map(key => {
            return {
                onChange: handleChange,
                validation: 'numeric',
                multiValData: gridData,
                isMultiRow: false,
                value: values[key] || 0,
                colDef: { headerName: key, field: 'fee_value', id: key },
                disabled: false,
            }
        })
    }

    const handleChange = (el, dropdown, rowIndex, gridApi) => {
        const { name, value, id } = dropdown || el.currentTarget
        const rowToUpdate = gridData[rowIndex]
        if (name === 'fee_name' && (rowToUpdate.fee_value || rowToUpdate.fee_type)) {
            rowToUpdate[name] = value
            let valueObj = {}
            let typeObj = {}
            value.forEach(element => {
                valueObj[element] = rowToUpdate.fee_value[element] || ''
                typeObj[element] = rowToUpdate.fee_type[element] || ''
            })
            rowToUpdate.fee_value = valueObj
            rowToUpdate.fee_type = typeObj

        } else if (name === 'fee_value') {
            rowToUpdate[name] = {
                ...rowToUpdate[name],
                [id]: value,
            }
        } else if (name === 'fee_type') {
            rowToUpdate[name] = {
                ...rowToUpdate[name],
                [id]: value,
            }
        } else {
            rowToUpdate[name] = value
        }
        setGridData(gridData)
        gridApi.refreshCells()
    }

    const parseDropdownData = ({ data, row }) => {
        const values = Object.values(data)
        const keys = !(_.isEmpty(data)) ? Object.keys(data) : row.fee_name
        return keys?.length == 0 ? [] : keys?.map(key => {
            return {
                onChange: handleChange,
                options: discountType,
                isMultiRow: true,
                value: values[key] || 0,
                colDef: { headerName: key, field: 'fee_type', id: key },
                disabled: false,
            }
        })
    }


    const displayDiscount = (param) => {
        const { data } = param
        const prefix = data?.rate_type === 'POSITIVE' ? '+' : '-'
        const postfix = data?.adjustment_type === 'PERCENTAGE' ? '%' : ''
        return `${prefix}${param.value}${postfix}`
    }

    const _getRowHeight = (params) => {
        const drugRowHeight = calcGridRowHeight(params.data?.specialty_drug_list)
        const unitRowHeight = 42
        const currentRow = _.isEmpty(gridData[0]?.fee_name) ? ['dummy'] : gridData[0]?.fee_name
        const feeRowHeight = unitRowHeight * currentRow.length
        return feeRowHeight > drugRowHeight ? feeRowHeight : drugRowHeight
    }

    const headers = ['action', ...Object.keys(rowCellInfo)]
    const networkHeaders = ['select', 'doc_id', 'network_name', 'version', 'start_date', 'end_date', 'status']

    const cellEditorParams = {
        claim_processor: {
            editable: true,
            cellEditor: DropdownEditor,
            onChange: handleChange,
        },
        price_type: {
            editable: true,
            cellEditor: DropdownEditor,
            onChange: handleChange,
        },
        specialty_drug_list: {
            editable: true,
            cellEditor: DropdownEditor,
            onChange: handleChange,
        },
        fixed_variable: {
            editable: true,
            cellEditor: DropdownEditor,
            onChange: handleChange,
        },
        discount: {
            editable: true,
            cellEditor: InputTextEditor,
            onChange: handleChange,
        },
        rate_type: {
            editable: true,
            cellEditor: DropdownEditor,
            onChange: handleChange,
        },
        fee_type: {
            cellEditor: NestedInputEditor,
            editorParams: {
                EditorComponent: DropdownEditor,
                elementList: parseDropdownData,
                key: 'fee_type',
                forceResetHeight: true,
                validation: false,
                value: 0,
            },
        },
        fee_name: {
            cellEditor: DropdownEditor,
            onChange: handleChange,
            isMultiRow: true,
            multiple: true,
        },
        fee_value: {
            cellEditor: NestedInputEditor,
            editorParams: {
                EditorComponent: InputTextEditor,
                elementList: parseData,
                key: 'fee_value',
                forceResetHeight: true,
                validation: false,
                value: 0,
            },
        },
        max_days_supply: {
            editable: true,
            cellEditor: InputTextEditor,
            onChange: handleChange,
        },
        min_days_supply: {
            editable: true,
            cellEditor: InputTextEditor,
            onChange: handleChange,
        },
        price_floor: {
            editable: true,
            cellEditor: InputTextEditor,
            onChange: handleChange,
        },
        mac_list_id: {
            editable: true,
            cellEditor: InputTextEditor,
            onChange: handleChange,
        },
    }

    const cellRendererParams = {
        action: {
            cellRenderer: DeleteRowRenderer,
            state: {
                onClick: deleteCondition,
            },
            width: 95,
        },
        specialty_drug_list: {
            cellRenderer: MultiRowRenderer,
            overrideHeader: 'Drug List'
        },
        fixed_variable: {
            overrideHeader: 'Fixed/Variable',
        },
        discount: {
            overrideHeader: 'Discount',
            valueFormatter: (params) => {
                return displayDiscount(params)
            }
        },
        mac_list_id: {
            overrideHeader: 'MAC List ID'
        },
        fee_name: {
            cellRenderer: MultiRowRenderer,
        },
        fee_type: {
            cellRenderer: MultiRowRenderer,
        },
        fee_value: {
            cellRenderer: MultiRowRenderer,
            valueFormatter: (data, item) => {
                return displayFeeValue(data, item)
            }
        },
    }

    const networkCellRendererParams = {
        select: {
            checkboxSelection: true,
            width: '120px'
        },
        doc_id: {
            hide: true
        },
        status: {
            cellRenderer: ColorCodedStatusRenderer,
        }
    }

    const onSelectionChanged = (data) => {
        const selectedRows = data.api.getSelectedRows();
        setselectedNetwork(selectedRows)
    }
    const contractPriceOptions = (options?.contractPriceSource || []).map(e => ({ key: e, text: e, value: e }))
    const drugListOptions = (options?.program || []).map(e => ({ key: e, text: e, value: e }))
    rowCellInfo.claim_processor.options = [{ key: 'New', text: 'New', value: 'New' }, ...contractPriceOptions]
    rowCellInfo.specialty_drug_list.options = drugListOptions
    return (<div id='pharmacy-alert-popup'>
        <img src='/i/featured_icon_green.png' width={40} height={40}></img>
        <span>Would you like to auto-publish new contract pricing to any pharmacies with the existing source contract already defined within their pricing networks.</span>
        <div>
            <FliptDropdown stylized value={auto_publish_pharmacy} name='auto_publish_pharmacy'
                options={publishOptions} onChange={(el, d) => setPublishDoc(d?.value)} />
        </div>

        {auto_publish_pharmacy === 'Y' ?
            <>
                <div className='info-text'>Define new Pricing Records to be added to applicable network tiers</div>
                <div className="grid-container">
                    <FliptGrid
                        headers={headers}
                        data={gridData}
                        getRowHeight={_getRowHeight}
                        cellEditorParams={cellEditorParams}
                        rowCellInfo={rowCellInfo}
                        cellRendererParams={cellRendererParams}
                    />
                </div>
                <FliptButton className="primary" compact name='Add +' onClick={addCondition} />

                <div className='info-text'>Define Network Tiers to add new pricing records to:</div>
                <div className="grid-container">
                    <FliptGrid
                        data={networkData}
                        headers={networkHeaders}
                        cellRendererParams={networkCellRendererParams}
                        rowSelection='multiple'
                        onSelectionChanged={(data) => { onSelectionChanged(data) }}
                    />
                </div>
                <div className='info-text'>This change will update pricing for {selectedNetwork.length} network tiers</div>
            </>
            : null}
        <FliptButton disabled={!auto_publish_pharmacy} name='Publish' className='primary' onClick={() => checkActionValue()} />
    </div>)
}

const mapStateToProps = (state) => ({
    state: {
        contractSummaryData: state.contractManagement.contractSummaryData,
        contractData: state.contractManagement.contractData,
        allNetworkData: state.networkTierCreation.allNetworkTiersData,
    },
})

const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...contractManagementActions,
        ...NetworkCreationActions,
        ...AppActions
    }

    return {
        actions: bindActionCreators(allActions, dispatch),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ContractPublishAlertPopup)
