import Immutable from 'seamless-immutable'
import { createActions, createReducer } from 'reduxsauce'

export const INITIAL_STATE = Immutable({
  loading: false,
  prescriberLookupData: [],
  prescriberTotalRows: 0,
  prescribersPerPage: 20,
  prescriberSuggestData: [],
  prescriberLookupFailed: false
})

export const clearPrescriberLookupReducer = () => INITIAL_STATE

export const clearReducer = () => INITIAL_STATE

export const setPrescriberLookupDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  prescriberLookupData: payload,
})

export const setPrescriberSuggestDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  prescriberSuggestData: payload.data,
})

export const setPrescriberLookupfailedReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  prescriberLookupFailed: payload,
})

export const setLoadingReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  loading: payload.data,
})

export const { Types, Creators } = createActions({
  clearPrescriberLookupReducer: null,
  clearReducer: null,
  getPrescriberLookupData: ['payload'],
  setPrescriberLookupData: ['payload'],
  getPrescriberSuggestData: null,
  setLoading: ['payload'],
  setPrescriberSuggestData: ['payload'],
  setPrescriberLookupFailed: ['payload'],
})

const HANDLERS = {
  [Types.CLEAR_PRESCRIBER_LOOKUP_REDUCER]: clearPrescriberLookupReducer,
  [Types.CLEAR_REDUCER]: clearReducer,
  [Types.SET_LOADING]: setLoadingReducer,
  [Types.SET_PRESCRIBER_LOOKUP_DATA]: setPrescriberLookupDataReducer,
  [Types.SET_PRESCRIBER_SUGGEST_DATA]: setPrescriberSuggestDataReducer,
  [Types.SET_PRESCRIBER_LOOKUP_FAILED]: setPrescriberLookupfailedReducer,
}

export default createReducer(INITIAL_STATE, HANDLERS)
