import React, { useEffect, useRef, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import './styles.scss'
import BreadCrumb from '../../../../components/breadCrumb'
import FliptButton from '../../../../components/form/fliptButton'
import FliptDatePicker from '../../../../components/form/fliptDatePicker'
import FliptDropdown from '../../../../components/form/fliptDropdown'
import FliptInput from '../../../../components/form/fliptInput'
import SubmenuNav from '../shared/navigation'
import { Creators as AppActions } from '../../../../redux/reducers/app'
import { Creators as FormularyCreationActions } from '../../../../redux/reducers/api/formularyCreationManagement'
import { Creators as UserActions } from '../../../../redux/reducers/userManagement/index'
import {
  parseQueryString, addRemoveDaysToDate, convertStrToDateObj,
} from '../../../../utils/utilities'
import FliptHierarchyDropdown from '../../../../components/form/fliptHierarchyDropdown'
import TableViewer from './TableViewer'

const FORMULARY_TIER = 'FORMULARY_TIER'
const PRIOR_AUTHORIZATION = 'PRIOR_AUTHORIZATION'
const QUANTITY_LIMIT = 'QUANTITY_LIMIT'
const PROGRAM_LIST = 'PROGRAM_LIST'
const STEP_THERAPY = 'STEP_THERAPY'

const objectTypeOptions = [
  { value: FORMULARY_TIER, text: "Drug List" },
  { value: PROGRAM_LIST, text: "Program List" },
  { value: PRIOR_AUTHORIZATION, text: "Prior Authorization" },
]

const FormularyFileInput = (props) => {

  const initialFastTargetObject = {
    object_type: '',
    object_name: '',
    effective_start_date: '',
    effective_end_date: '',
    hierarchy: [],
    status: 'DRAFT',
    version: '1.0'
  }
  const { actions, state, history } = props
  const { headerFieldMappingData } = state
  const [fastTargetObject, setFastTargetObject] = useState(initialFastTargetObject)
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadSubmit, setUploadSubmit] = useState(false);
  const [generation, setGeneration] = useState(false);

  useEffect(() => {
    actions.clearFormularyInput()
  }, [])

  const handleInputChange = (e, dropdown) => {
    const { name, value } = dropdown || e.currentTarget
    setFastTargetObject({
      ...fastTargetObject,
      [name]: value,
    })
  }

  const submitUpload = () => {
    actions.uploadFormularyImportData(selectedFile)
    setUploadSubmit(true)
  };

  const handleFileUpload = (e) => {
    setSelectedFile(e.target.files[0])
  }

  const generateFastObject = () => {
    if (!fastTargetObject?.hierarchy || (Array.isArray(fastTargetObject?.hierarchy) && fastTargetObject?.hierarchy?.length < 1) || (Array.isArray(fastTargetObject?.hierarchy) && fastTargetObject?.hierarchy[0]?.organization?.length < 1)) {
      const transitionalPortal = {
        header: 'Cannot generate FAST Object without hierarchy',
        copy: 'Please assign a Hierarchy before saving'
      }
      actions.displayTransitionalPortal(transitionalPortal)
      return
    }
    if (fastTargetObject.object_type === FORMULARY_TIER) {
      actions.navigateToFormularyTier({
        formularyTierCondData: state.headerFieldMappingData,
        form: fastTargetObject
      })
    } else {
      const map = new Map()
      const key = 'condition_level'
      headerFieldMappingData.forEach(item => {
        const keyValue = item[key]
        if (!map.has(keyValue)) {
          map.set(keyValue, [])
        }
        map.get(keyValue).push(item)
      })
      const conditionsDataWithLevels = Array.from(map.values())
      const showQualifier = []
      conditionsDataWithLevels.forEach((x) => showQualifier.push(true))
      if (fastTargetObject.object_type === PROGRAM_LIST) {
        history.push({
          pathname: 'program-list',
          search: '?import',
          state: {
            form: { ...fastTargetObject, doc_name: fastTargetObject.name },
            programConditionLevels: conditionsDataWithLevels,
            showQualifier,
          }
        })
      } else if (fastTargetObject.object_type === PRIOR_AUTHORIZATION) {
        history.push({
          pathname: 'prior-authorization',
          search: '?import',
          state: {
            form: { ...fastTargetObject, doc_name: fastTargetObject.name },
            paConditionLevels: conditionsDataWithLevels,
            showQualifier
          }
        })
      }

    }
  }

  return (
    <div id="um-file-import">
      <BreadCrumb {...props} />
      <div className="header">Formulary/Utilization Management File Import</div>
      <div className="tabsContainer">
        <SubmenuNav active="formularyFileInput" />
      </div>
      <div className="content">
        <div className="section">
          <section className="formulary-inputs-container spacing border-line shadow">
            <div className="formulary-inputs">
              <span>Object Type</span>
              <FliptDropdown
                className="create-formulary-tier-list"
                placeholder="Select Object Type"
                value={fastTargetObject.object_type}
                onChange={handleInputChange}
                options={objectTypeOptions}
                name="object_type"
              />
            </div>
            <div className="formulary-inputs">
              <span>Object Name</span>
              <FliptInput
                name="name"
                value={fastTargetObject.name}
                onChange={handleInputChange}
                placeholder="Object Name"
              />
            </div>
            <div className="formulary-inputs">
              <span>Effective Start Date</span>
              <FliptDatePicker
                className="create-formulary-start-date"
                name="effective_start_date"
                value={convertStrToDateObj(fastTargetObject.effective_start_date)}
                onChange={handleInputChange}
                placeholder="Effective Start Date"
              />
            </div>
            <div className="formulary-inputs">
              <span>Effective End Date</span>
              <FliptDatePicker
                className="create-formulary-start-date"
                name="effective_end_date"
                value={convertStrToDateObj(fastTargetObject.effective_end_date)}
                onChange={handleInputChange}
                placeholder="Effective End Date"
              />
            </div>
            <FliptHierarchyDropdown
              form={fastTargetObject}
              setForm={setFastTargetObject}
            />
            <div class="break"></div>
            <div className="buttonContainer">
            </div>
          </section>
        </div>
        <div className="section">
          <section className="uploadInputContainer">
            <div className="uploadInput">
              <span className="element">Select data file in xlsx format</span>
              <input type="file" name="file" id="file" onChange={handleFileUpload} />
            </div>
            <div className="buttonContainer">
              <FliptButton name="Upload" onClick={submitUpload} className="primary searchButton" />
              <FliptButton name="Cancel" className="secondary searchButton" />
            </div>
          </section>
        </div>
        {uploadSubmit ?
          <div className="section">
            <section className="outTableContainer">
              <TableViewer object_type={fastTargetObject.object_type} />
            </section>
          </div>
          : ""}
        {headerFieldMappingData.length > 0 ?
          <div className="section">
            <section className="formulary-inputs-container spacing border-line shadow">
              <div className="formulary-inputs">
                <span>Object Type</span>
                <FliptDropdown
                  className="create-formulary-tier-list"
                  placeholder="Select Formulary Tiers"
                  value={fastTargetObject.object_type}
                  options={objectTypeOptions}
                  name="type"
                  readOnly
                />
              </div>
              <div className="formulary-inputs">
                <span>Object Name</span>
                <FliptInput name="name" value={fastTargetObject.name} onChange={handleInputChange} placeholder="Object Name" disabled />
              </div>
              <div className="formulary-inputs">
                <span>Effective Start Date</span>
                <FliptDatePicker
                  className="create-formulary-start-date"
                  name="effective_start_date"
                  value={convertStrToDateObj(fastTargetObject.effective_start_date)}
                  onChange={handleInputChange}
                  placeholder="Effective Start Date"
                  disabled
                />
              </div>
              <div className="formulary-inputs">
                <span>Effective End Date</span>
                <FliptDatePicker
                  className="create-formulary-start-date"
                  name="effective_end_date"
                  value={convertStrToDateObj(fastTargetObject.effective_end_date)}
                  onChange={handleInputChange}
                  placeholder="Effective End Date"
                  disabled
                />
              </div>
              <div className="formulary-inputs">
                <span>Status</span>
                <FliptInput name="name" value={fastTargetObject.status} disabled />
              </div>
              <div className="buttonContainer">
                <FliptButton name="Generate Fast Object" onClick={generateFastObject} className="primary searchButton" />
              </div>
            </section>
          </div> : ""
        }
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  state: {
    user: state.user,
    importDrugData: state.formularyCreation.importDrugData,
    headerFieldMappingData: state.formularyCreation.headerFieldMappingData,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
    ...FormularyCreationActions,
    ...UserActions
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormularyFileInput)
