/* eslint-disable */
export default {
  action:{
    type: 'delete',
    disabled: false,
  },
  input_value: {
    type: 'input',
    disabled: false,
  },
   qualifier: {
    type: 'dropdown',
    disabled: false,
  },
  standard:{
    type: 'dropdown',
    disabled: false,
  },
}
