import {
    call,
    put,
    select,
    takeLatest,
} from 'redux-saga/effects'
import { Types, Creators as opioidManagementActions } from '../../reducers/api/opioidManagement'
import { Creators as NavigationActions } from '../../reducers/navigation'
import { Creators as AppActions } from '../../reducers/app'

import {
    getAppState, getApiPath,
} from '../../reducers/selectors'
import { fetchGet, fetchPost } from '../../../utils/fetchApi'

export default [
    getOpioidSummaryWatcher,
    getOpioidProgramWatcher,
    addOpioidProgramWatcher,
    createNewGlobalOpioidWatcher,
    editGlobalOpioidWatcher,
    publishGlobalOpioidWatcher
]

function* getOpioidSummaryWatcher() {
    yield takeLatest(Types.GET_OPIOID_SUMMARY, getOpioidSummaryHandler)
}

function* getOpioidProgramWatcher() {
    yield takeLatest(Types.GET_OPIOID_PROGRAM, getOpioidProgramHandler)
}

function* addOpioidProgramWatcher() {
    yield takeLatest(Types.ADD_OPIOID_PROGRAM, addOpioidProgramHandler)
}

function* createNewGlobalOpioidWatcher() {
    yield takeLatest(Types.CREATE_NEW_GLOBAL_OPIOID, createNewGlobalOpioidHandler)
}

function* editGlobalOpioidWatcher() {
    yield takeLatest(Types.EDIT_GLOBAL_OPIOID, editGlobalOpioidHandler)
}

function* publishGlobalOpioidWatcher() {
    yield takeLatest(Types.PUBLISH_GLOBAL_OPIOID, publishGlobalOpioidHandler)
}

function* publishGlobalOpioidHandler({ payload }) {
    try {
        const { serviceUrl } = yield select(getAppState)

        const { api_path } = yield select(getApiPath, 'publish-global-opioid')
        const url = `${serviceUrl}${api_path}`
        yield call(fetchPost, url, payload)
        const transitionalPortal = {
            header: 'DUR Status Updated',
            copy: 'New DUR Program Published Successfully',
        }
        yield put(AppActions.displayTransitionalPortal(transitionalPortal))
        yield put(opioidManagementActions.getOpioidSummary())
    } catch (err) {
        console.log('publishGlobalOpioidHandler Error >Data ', err)
        const transitionalPortal = {
            header: 'Publish Global opioid Data Failed',
            copy: err,
        }
        yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    }
}

function* editGlobalOpioidHandler({ payload }) {
    try {
        const { serviceUrl } = yield select(getAppState)

        const { api_path } = yield select(getApiPath, 'edit-global-opioid')
        const url = `${serviceUrl}${api_path}`
        yield call(fetchPost, url, payload)
        const transitionalPortal = {
            header: 'DUR Updated',
            copy: 'DUR Program updated Successfully',
        }
        yield put(AppActions.displayTransitionalPortal(transitionalPortal))
        yield put(NavigationActions.navigateTo({ pathname: '/opioid-management', state: { tabName: 'Opioid Programs' } }))
    } catch (err) {
        console.log('editGlobalOpioidHandler Error >Data ', err)
        const transitionalPortal = {
            header: 'Edit Global Opioid Data Failed',
            copy: err,
        }
        yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    }
}

function* createNewGlobalOpioidHandler({ payload }) {
    try {
        const { serviceUrl } = yield select(getAppState)

        const { api_path } = yield select(getApiPath, 'create-opioid-version')
        const url = `${serviceUrl}${api_path}`
        yield call(fetchPost, url, payload)
        const transitionalPortal = {
            header: 'DUR Added',
            copy: 'New DUR Program Version Added Successfully',
        }
        yield put(AppActions.displayTransitionalPortal(transitionalPortal))
        yield put(opioidManagementActions.getOpioidSummary())
    } catch (err) {
        console.log('createNewGlobalOpioidHandler Error >Data ', err)
        const transitionalPortal = {
            header: 'create New Global Opioid Data Failed',
            copy: err,
        }
        yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    }
}

function* getOpioidSummaryHandler({ payload }) {
    try {
        const { serviceUrl } = yield select(getAppState)
        const { api_path } = yield select(getApiPath, 'opioid-management')
        const url = `${serviceUrl}${api_path}`
        const { data } = yield call(fetchGet, url, payload)
        yield put(opioidManagementActions.setOpioidSummaryData(data))
    } catch (err) {
        console.log('getOpioidSummaryHandler Error >Data ', err)
        const transitionalPortal = {
            header: 'Getting Opioid Summary Data Failed',
            copy: err,
        }
        yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    }
}

function* getOpioidProgramHandler({ payload }) {
    try {
        const { serviceUrl } = yield select(getAppState)
        const { api_path } = yield select(getApiPath, 'get-opioid-program')
        const url = `${serviceUrl}${api_path}`
        const { data } = yield call(fetchGet, url, payload)
        yield put(opioidManagementActions.setOpioidProgramData(data))
    } catch (err) {
        console.log('getOpioidProgramHandler Error >Data ', err)
        const transitionalPortal = {
            header: 'Getting Opioid Program Data Failed',
            copy: err,
        }
        yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    }
}

function* addOpioidProgramHandler({ payload }) {
    try {
        const { serviceUrl } = yield select(getAppState)
        const { api_path } = yield select(getApiPath, 'create-opioid-management')
        const url = `${serviceUrl}${api_path}`
        yield call(fetchPost, url, payload)
        yield put(NavigationActions.navigateTo({ pathname: '/opioid-management' }))
    } catch (err) {
        console.log('addOpioidProgramHandler Error >Data ', err)
        const transitionalPortal = {
            header: 'Add Program Info Failed',
            copy: err,
        }
        yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    }
}


export {
    getOpioidSummaryHandler,
    getOpioidProgramHandler,
    addOpioidProgramHandler,
    publishGlobalOpioidHandler,
    createNewGlobalOpioidHandler,
    editGlobalOpioidHandler
}
