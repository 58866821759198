export const CONFIG_ACTIONS = {
    CANCEL: 'CANCEL',
    COPY: 'COPY',
    DELETE: 'DELETE',
    EDIT: 'EDIT',
    PUBLISH: 'PUBLISH',
    REPUBLISH: 'REPUBLISH',
    SUBMIT: 'SUBMIT',
}

export const CONFIG_STATUS = {
    DRAFT: 'draft',
    READY: 'Ready for Review',
    CANCEL: 'Cancel',
    PUBLUSHED: 'Published',
    DELETED: 'deleted',
}

export const SEARCH_TYPE = Object.freeze({
    SINGLE_INPUT: 'single_input',
    BATCH_INPUT: 'batch_input'
})

export const FIELD_TYPES = [
    { key: '1', value: 'cardholder_id', text: 'Cardholder ID' },
    { key: '2', value: 'auth_id', text: 'Auth ID' },
    { key: '3', value: 'carrier', text: 'Carrier' },
    { key: '4', value: 'group', text: 'Group' },
    { key: '5', value: 'plan', text: 'Plan' },
]

export const MANUAL_LIST = 'manual_list'
export const FILE_IMPORT = 'file_import'

export const INPUT_TYPES = [
    { value: MANUAL_LIST, text: 'Manual List' },
    { value: FILE_IMPORT, text: 'File Import' }
]

export const HEADERS = [
    'action', 'auth_id', 'processed_date', 'date_of_service', 'cardholder_id', 'group_id', 'transaction_response_status',
    'claim_status', 'payer_pcn', 'sequenceNumber', 'first_name', 'last_name', 'date_of_birth', 'plan_name', 'rx_number', 'drug_name', 'drug_strength',
    'drug_dosage_form', 'submitted_quantity', 'multi_source', 'drug_mfg', 'pharmacy_name', 'otc', 'reject_message', 'calculated_drug_cost', 'patient_pay_amount',
    'service_provider_id', 'is_vip',
]

export const HEADER_MAPPING = {
    drug_dosage: 'drug_dosage_form',
    drug_manufacturer: 'drug_mfg',
    otc_indicator: 'otc',
    prescription_reference_number: 'rx_number',
    quantity_dispensed: 'submitted_quantity',
    startDate: 'processed_date',
}

export const CLAIM_DETAILS_MAPPING = {
    auth_id: 'auth_id',
    date_of_service: 'date_of_service',
    cardholder_id: 'cardholder_id',
    group_id: 'group_id',
    rx_number: 'prescription_reference_number',
    drug_name: 'drug_name',
    drug_strength: 'drug_strength',
    drug_dosage_form: 'drug_dosage',
    calculated_drug_cost: 'calculated_drug_cost',
    patient_pay_amount: 'patient_pay_amount'
}

export const ADJUDICATION_TYPES = [
    {
        text: 'Validation Logic',
        value: 'Validation Logic'
    },
    {
        text: 'Eligibility Logic',
        value: 'Eligibility Logic'
    },
    {
        text: 'Pricing Logic',
        value: 'Pricing Logic'
    },
    {
        text: 'Claim Check Logic',
        value: 'Claim Check Logic'
    },
    {
        text: 'Accumulator Logic',
        value: 'Accumulator Logic'
    },
    {
        text: 'Post Processing Logic',
        value: 'Post Processing Logic'
    },
    {
        text: 'Medicare Logic',
        value: 'Medicare Logic'
    },
    {
        text: 'FAST Reader Service',
        value: 'FAST Reader Service'
    },
    {
        text: 'All Services',
        value: 'All Services'
    }
]
export const BYPASSED_TYPES = [
    {
        text: 'All',
        value: 'ALL',
        key: 0
    }
]

export const ADJUSTMENT_REASON_CODE_CMS = [
    {
        text: 'OFM',
        value: 'OFM',
        key: 0
    },
    {
        text: 'RAC',
        value: 'RAC',
        key: 1
    },
    {
        text: 'MEDIC',
        value: 'MEDIC',
        key: 2
    }
]

export const ADJUSTMENT_REASON_CODE_IDENTIFIED = [
    {
        text: 'CIO',
        value: 'CIO',
        key: 0
    }
]

export const ADJUSTMENT_REASON_CODE_DISPUTE = [
    {
        text: 'DISPUTE',
        value: 'DISPUTE',
        key: 0
    },
    {
        text: 'APPEAL',
        value: 'APPEAL',
        key: 1
    }
]

export const ADJUSTMENT_REASON_CODE_QUALIFIER = [
    {
        text: '2 - CMS Audit',
        value: '2 - CMS Audit',
        key: 0
    },
    {
        text: '3 - CMS Identified Overpayment (CIO)',
        value: '3 - CMS Identified Overpayment (CIO)',
        key: 1
    },
    {
        text: '4 - CGDP Dispute or Appeal',
        value: '4 - CGDP Dispute or Appeal',
        key: 2
    },
    {
        text: '9 - Other',
        value: '9 - Other',
        key: 3
    },
    {
        text: 'BLANK - Not Applicable',
        value: 'BLANK - Not Applicable',
        key: 4
    }
]

export const TRUE_FALSE_TYPES = [
    {
        text: 'True',
        value: true,
        key: 0
    },
    {
        text: 'False',
        value: false,
        key: 1
    }
]

export const CLAIM_CODES = ['30', '79', '81', '82', '83', '84', '88', '95', '606']
export const MEMBER_CODES = ['9', '52', '65', 'CA', 'CB']
export const PROVIDER_CODES = ['40', '6Z']
export const UM_CODES = ['70', '76', '3W', '3Y', 'AG']

export const REPROCESSING_TYPES = [
    {
        text: 'Claim Processing Logic',
        value: 'Claim Processing Logic'
    },
    {
        text: 'Benefit Changes',
        value: 'Benefit Changes'
    },
    {
        text: 'Formulary Changes',
        value: 'Formulary Changes'
    },
    {
        text: 'Plan Configuration',
        value: 'Plan Configuration'
    },
    {
        text: 'OHI Changes',
        value: 'OHI Changes'
    },
    {
        text: 'LICS Changes',
        value: 'LICS Changes'
    },
    {
        text: 'Pharmacy Audit',
        value: 'Pharmacy Audit'
    },
    {
        text: 'DED/OOP Changes',
        value: 'DED/OOP Changes'
    },
    {
        text: 'TrOOP Changes',
        value: 'TrOOP Changes'
    },
    {
        text: 'PDE',
        value: 'PDE'
    },
    {
        text: 'Other',
        value: 'Other'
    },
]