import React from 'react'
import { Tab } from 'semantic-ui-react'

import BreadCrumb from '../../../components/breadCrumb'
import UMDrugLookup from './umDrugLookup'
import UMWhereUsedLookup from './umWhereUsedLookup'

import './styles.scss'

const panes = [
  { menuItem: 'UM Drug Lookup', render: () => <Tab.Pane><UMDrugLookup /></Tab.Pane> },
  { menuItem: 'UM Where Used Lookup', render: () => <Tab.Pane><UMWhereUsedLookup /></Tab.Pane> }
]

const UMDrugLookupTabs = (props) => {
  return <div id="um-drug-lookup-tabs">
    <BreadCrumb {...props} />
    <Tab panes={panes} />
  </div>
}

export default UMDrugLookupTabs
