import React, { Component } from 'react';
import { bindActionCreators } from "redux";
import SaveTierModal from "./saveTierModal";
import { connect } from "react-redux";
import BreadCrumb from '../../../../components/breadCrumb'
import Config from "../../../../config";
import { Creators as AppActions } from "../../../../redux/reducers/app";
import { Creators as NavigationActions } from '../../../../redux/reducers/navigation'
import rowCellInfo from './rowCellInfo'
import FliptHierarchyDropdown from '../../../../components/form/fliptHierarchyDropdown'
import FliptGrid from '../../../../components/fliptGrid'
import FliptInput from '../../../../components/form/fliptInput'
import FliptDatePicker from '../../../../components/form/fliptDatePicker'
import FliptButton from '../../../../components/form/fliptButton'
import { capitalizeStr, parseQueryString, convertStrToDateObj, buildDropdownOptions } from '../../../../utils/utilities'
import { Creators as UserManagementActions } from '../../../../redux/reducers/userManagement'
import { DropdownEditor, InputTextEditor, DatePickerEditor } from '../../../../components/fliptGrid/cellEditors'
import { Creators as ClientImplementationActions } from '../../../../redux/reducers/api/clientImplementation'
import FliptDropdown from '../../../../components/form/fliptDropdown'
import { Creators as UserActions } from '../../../../redux/reducers/userManagement/index'
import _ from 'lodash'
import './styles.scss'
import { lobTypes } from './data';
import { DeleteRowRenderer } from '../../../../components/fliptGrid/cellRenderers'
import { from } from 'seamless-immutable';
import { queryAllByAltText } from '@testing-library/react';
import moment from 'moment'



class NewImplmentation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      doc_type: "client_implementation",
      form: {
        doc_id: "",
        doc_name: '',
        lob: '',
        estimated_go_live: '',
        estimated_start_date: '',
        estimated_testing_date: '',
        hierarchy: [],
        is_hierarchy_global: false,
        showHierarchyDropdowns: true,
        hierarchyIDs: {},
        hideHierarchy: false,
        status: '',
        type: 'client_implementation'
      },
      tasks: [
        {
          doc_id: "",
          doc_type: "implementation_task",
          doc_name: "",
          workstream: "",
          module: " ",
          task_object_type: " ",
          task_object_state: " ",
          task_objects: [],
          task_object_dependencies: [],
          task_owner: " ",
          task_assignee: " ",
          task_begin_date: "",
          task_end_date: "",
          actual_task_begin_date: "",
          actual_task_end_date: "",
          task_notes: "",
          status: "",
          taskProgramOptions: [],
        }
      ],
      lobOptions: lobTypes,
      taskObjectsData: [],
      taskWorkStreamOptions: ["HIERARCHY", "CLINICAL", "BENEFITS", "NETWORK", "ELIGIBILITY", "PRICING", "FINANCE", "REPORTING"],
      taskModuleOptions: [
        "HIERARCHY || GROUP FILE LOAD",
        "CLINICAL || FORUMULARY", "CLINICAL || FORMULARY DRUG LIST", "CLINICAL || PROGRAM EDITS", "CLINICAL || STEP THERAPY", "CLINICAL || QUANTITY LIMITS", "CLINICAL || PA DRUG LIST", "CLINICAL || INBOUND FORMULARY LOAD",
        "BENEFITS || PLAN DESIGN", "BENEFITS || INBOUND BENEFIT LOAD",
        "NETWORK || NETWORK", "NETWORK || NETWORK TIER", "NETWORK || NETWORK EDIT", "NETWORK || INBOUND NETWORK LOAD",
        "ELIGIBILITY || INBOUND ELIGIBILITY LOAD",
        "PRICING || CONTRACT DEFINITION", "PRICING || INBOUND PRICING LOAD",
        "FINANCE || INVOICE", "FINANCE || BILL", "FINANCE || DMR",
        "REPORTING || OUTBOUND ACCUM", "REPORTING || OUTBOUND CLAIM", "REPORTING || OUTBOUND ELIGIBILITY"
      ],

      taskObjTypeOptions: ['INTERNAL', 'EXTERNAL'],
      taskObjStateOptions: ['Y', 'N'],
      statusOptions: ['Not Started', 'In Progress', "Ready for Testing", "In Testing ", 'Completed']
    }
  }


  getObjectKeys() {
    const { state: { taskObjects } } = this.props
    let options = []

    if (taskObjects.length > 0) {
      let data = taskObjects[0]
      let keys = Object.keys(data)
      for (let index = 0; index < keys.length; index++) {
        const key = keys[index];
        for (let jindex = 0; jindex < data[key].length; jindex++) {
          const element = data[key][jindex];
          options.push(element)
        }
      }
    }
    //console.log(taskObjects)
    //console.log(options)
    return options
  }
  componentDidMount() {
    const { actions, data, state: { stateuser } } = this.props
    this.clearFormData()
    if (!this.props.history.location.search) {
      actions.clearImplementationReducer();

      return;
    }
    else {
      const qs = parseQueryString(this.props.history.location.search);
      const { doc_id } = qs
      let payload = {
        doc_id: doc_id,
        doc_type: "client_implementation"
      }
      setTimeout(
        () => actions.getClientImplementation(payload),
        Config.COMPONENT_DATA_LOAD_TIME
      );

    }
    setTimeout(
      () => actions.getUserManagementUsers(),
      Config.COMPONENT_DATA_LOAD_TIME
    );
    setTimeout(
      () => actions.getImplementationTaskObjectList(),
      Config.COMPONENT_DATA_LOAD_TIME
    );


  }
  componentDidUpdate(prevProps) {
    const { actions, data, state: { stateuser } } = this.props
    const { state: { user, implementationData, tasksData }, form } = this.props;
    // if (prevProps.state.user !== user) {
    //   this.updateFliptHierarchy(user, form);
    // }
    const prevState = prevProps.state;
    const currState = this.props.state;

    if (prevState === currState) return;
    if (this.props.history.location.search) {
      this.setImplementationDataforVieworEdit()
    } else {
      let options = this.getObjectKeys()
      this.setState({ taskObjectsData: options })
    }

  }
  clearFormData = () => {
    this.setState({
      form: {
        doc_id: "",
        doc_name: '',
        lob: '',
        estimated_go_live: '',
        estimated_start_date: '',
        estimated_testing_date: '',
        hierarchy: [],
        is_hierarchy_global: false,
        showHierarchyDropdowns: true,
        hierarchyIDs: {},
        hideHierarchy: false,
        status: '',
        type: "client_implementation"
      },
      tasks: [
        {
          doc_id: "",
          doc_name: "",
          workstream: "",
          module: "",
          task_object_type: "",
          task_object_state: "",
          task_objects: [],
          task_object_dependencies: [],
          task_owner: "",
          task_assignee: "",
          estimated_task_begin_date: "",
          estimated_task_end_date: "",
          actual_task_begin_date: "",
          actual_task_end_date: "",
          task_notes: "",
          status: ""
        }
      ]
    });
  }

  setForm = (newForm) => {
    this.setState((prevState) => ({
      ...prevState,
      form: newForm
    }))
  }

  updateFields = (el, dateData) => {
    const { name, value } = dateData || el.currentTarget

    this.setState((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        [name]: value,
      },
    }))
  }

  handleChange = (el, dropdown, rowIndex, gridApi) => {
    const { state } = this
    const tasks = [...state.tasks]
    const rowToUpdate = tasks[rowIndex]
    const { name, value } = dropdown || el.currentTarget
    let new_value = value
    console.log(`Checking task Date format for ${name}`)
    // if (name.toLowerCase().endsWith('date')){
    //   new_value = moment(value).format('YYYY-MM-DD')
    //   console.log(`Checking task Date format for ${name} :: ${new_value}`)
    // }
    rowToUpdate[name] = new_value
    tasks[rowIndex] = rowToUpdate

    this.setState({
      tasks, rowUpdated: rowIndex, columnUpdated: name, columnValue: value,
    })
    gridApi.refreshCells()
  }


  addRow = () => {
    const { tasks } = this.state
    const newTasks = [...tasks]
    newTasks.push({
      doc_id: "",
      doc_name: "",
      workstream: "",
      module: "",
      task_object_type: "",
      task_object_state: "",
      task_objects: [],
      task_object_dependencies: [],
      task_owner: "",
      task_assignee: "",
      estimated_task_begin_date: "",
      estimated_task_end_date: "",
      actual_task_begin_date: "",
      actual_task_end_date: "",
      task_notes: "",
      status: ""
    })
    this.setState({
      tasks: newTasks
    })
  }

  delRow = (rowIndex) => {
    const { tasks } = this.state

    if (tasks.length <= 1) return

    this.setState({
      tasks: tasks.filter((cond) => tasks.indexOf(cond) !== rowIndex),
    })
  }

  updateFliptHierarchy(user, form) {
    // Logic to update hierarchy data based on user
    // This would likely involve fetching hierarchy data based on the user
    // and updating the form state with the new hierarchy
    //console.log('User has changed. Update FliptDropDownHierarchy data based on user:', user);
    this.setState({ form: { ...form, hierarchy: [] } }); // Update form state with hierarchy (replace [...] with actual data)
  }

  setImplementationDataforVieworEdit = (updateHierarchy) => {
    const { state: { user, implementationData, tasksData }, actions, history, form } = this.props
    this.clearFormData()
    let options = this.getObjectKeys()
    if (implementationData) {
      const payload = parseQueryString(this.props.history.location.search);
      const { props, state } = this;
      const form = {
        ...state.form,
        doc_id: implementationData?.doc_id || '',
        doc_name: implementationData?.doc_name || '',
        lob: implementationData?.lob || '',
        estimated_go_live: implementationData?.estimated_go_live || '',
        estimated_start_date: implementationData?.estimated_start_date || '',
        estimated_testing_date: implementationData?.estimated_testing_date || '',
        hierarchy: implementationData?.hierarchy || [],
        is_hierarchy_global: false,
        showHierarchyDropdowns: true,
        hierarchyIDs: implementationData?.hierarchyIDs || {},
        hideHierarchy: false,
        type: "client_implementation"
      };
      let tasks = []
      for (let index = 0; index < tasksData.length; index++) {
        const element = tasksData[index];
        tasks.push(element)
      }
      if (!tasks.length > 0) {
        tasks.push({
          doc_id: "",
          doc_type: "implementation_task",
          doc_name: "",
          workstream: "",
          module: " ",
          task_object_type: " ",
          task_object_state: " ",
          task_objects: [],
          task_object_dependencies: [],
          task_owner: " ",
          task_assignee: " ",
          estimated_task_begin_date: "",
          estimated_task_end_date: "",
          actual_task_begin_date: "",
          actual_task_end_date: "",
          task_notes: "",
          status: "",
          taskProgramOptions: [],
        })
      }

      this.setState({
        form,
        tasks,
        taskObjectsData: options
      });
    }

  }


  handleSaveImplementation = () => {
    const { state: { systemUsersData } } = this.props
    const {
      props: { actions },
      state,
    } = this;
    const {
      form,
      form: { doc_id },
    } = state;
    const hierarchyOrganizationLevel = form?.hierarchy?.[0]?.children || [];

    if (
      !form.doc_name ||
      !form.lob ||
      !form.estimated_go_live ||
      !form.estimated_start_date ||
      !form.estimated_testing_date ||
      hierarchyOrganizationLevel?.length == 0
    ) {
      let message = "";
      if (!form.doc_name) message = "Enter a Client Implementation Name";
      else if (!form.lob) message = "select a Line of business";
      else if (!form.estimated_go_live) message = "select a effective go live date";
      else if (!form.estimated_start_date) message = "select a effective start date";
      else if (!form.estimated_testing_date) message = "select a effective testing date";
      else if (hierarchyOrganizationLevel?.length == 0) message = "Assign a Hierarchy";
      const transitionalPortal = {
        header: "Client Implementation Save Failed",
        copy: `Please ${message} before saving`,
      };
      actions.displayTransitionalPortal(transitionalPortal);
      return;
    }
    let postTasks = []
    const tasks = [...state.tasks]
    console.log(tasks)
    if (this.state.tasks.length > 0) {
      if (this.state.tasks.length == 1) {
        if (!this.state.tasks[0]['doc_name']) {
          let message = 'Add Missing Task Name';
          const transitionalPortal = {
            header: "Client Implementation Save Failed",
            copy: `Please ${message} before saving`,
          };
          actions.displayTransitionalPortal(transitionalPortal);
          return;
        }

      }
    }
    postTasks = tasks
    for (let index = 0; index < postTasks.length; index++) {
      let element = postTasks[index];
      element["task_owner_id"] = systemUsersData.filter((usr) => element["task_owner"] == `${usr.first_name} ${usr.last_name}`)[0]?.uuid || ''
      element["task_assignee_id"] = systemUsersData.filter((usr) => element["task_assignee"] == `${usr.first_name} ${usr.last_name}`)[0]?.uuid || ''
      element["doc_type"] = "implementation_task"
      console.log(`task element: ${element.doc_id} ${element.doc_name}`)
      console.log(element.doc_id)
    }
    let payload = {
      implementation_details: {
        doc_id: form.doc_id,
        doc_name: form.doc_name,
        lob: form.lob,
        hierarchy: form.hierarchy,
        hierarchyIDs: form.hierarchyIDs,
        estimated_go_live: form.estimated_go_live,
        estimated_start_date: form.estimated_start_date,
        estimated_testing_date: form.estimated_testing_date,
        status: "PENDING",
        doc_type: "client_implementation"
      },
      task_details: postTasks
    }
    if (!this.props.history.location.search) {
      const message = "Create New Client Implementation?";
      actions.setModalComponent({
        modalProperties: {
          size: "tiny",
        },
        contents: SaveTierModal({
          form,
          message,
          onSaveClick: () => actions.createClientImplementation(payload),
          toggleModal: actions.toggleModal,
        }),
      });
      const transitionalPortal = {
        header: "Client Implementation",
        copy: `Successfully Created.!!!`,
      };
      actions.displayTransitionalPortal(transitionalPortal);
      // this.setState({
      //   form,
      //   tasks: postTasks
      // });
      actions.toggleModal();
      actions.toggleModal();

    } else {
      const message = "Save Client Implementation?";
      actions.setModalComponent({
        modalProperties: {
          size: "tiny",
        },
        contents: SaveTierModal({
          form,
          message,
          onSaveClick: () => actions.editClientImplementation(payload),
          toggleModal: actions.toggleModal,
        }),
      });
      const transitionalPortal = {
        header: "Client Implementation",
        copy: `Successfully Updated.!!!`,
      };
      actions.displayTransitionalPortal(transitionalPortal);
      // this.setState({
      //   form,
      //   tasks: postTasks
      // });
      actions.toggleModal();
    }

    this.props.actions.navigateTo({
      pathname: "/client-implementation",
    })


  }

  render() {
    const { tasks, form, lobOptions } = this.state;


    const headers = [
      'remove',
      'doc_id',
      'doc_name',
      'workstream',
      'task_object_type',
      'module',
      'task_objects',
      'task_object_state',
      'status',
      'task_owner',
      'task_assignee',
      'task_begin_date',
      'task_end_date',
      'actual_task_begin_date',
      'actual_task_end_date',
      'task_notes',
      'doc_type'
    ]


    const cellEditorParams = {
      doc_name: {
        cellEditor: InputTextEditor,
        onChange: this.handleChange,
      },
      workstream: {
        cellEditor: DropdownEditor,
        options: [],
        onChange: this.handleChange,
      },

      task_object_type: {
        cellEditor: DropdownEditor,
        onChange: this.handleChange,
      },
      task_object_state: {
        cellEditor: DropdownEditor,
        onChange: this.handleChange,
      },
      module: {
        cellEditor: DropdownEditor,
        options: [],
        onChange: this.handleChange,
      },


      task_objects: {
        cellEditor: DropdownEditor,
        onChange: this.handleChange,
        isMulti: true
      },
      status: {
        cellEditor: DropdownEditor,
        onChange: this.handleChange,
      },
      task_owner: {
        cellEditor: DropdownEditor,
        options: [],
        onChange: this.handleChange,
      },
      task_assignee: {
        cellEditor: DropdownEditor,
        options: [],
        onChange: this.handleChange,
      },
      task_begin_date: {
        cellEditor: DatePickerEditor,
        onChange: this.handleChange,
      },
      task_end_date: {
        cellEditor: DatePickerEditor,
        onChange: this.handleChange,
      },
      actual_task_begin_date: {
        cellEditor: DatePickerEditor,
        onChange: this.handleChange,
      },
      actual_task_end_date: {
        cellEditor: DatePickerEditor,
        onChange: this.handleChange,
      },
      task_notes: {
        cellEditor: InputTextEditor,
        onChange: this.handleChange,
      }

    }
    const cellRendererParams = {
      remove: {
        cellRenderer: DeleteRowRenderer,
        state: {
          onClick: (rowIndex) => { this.delRow(rowIndex) },
        },
        width: 95,
      },
      doc_id: {
        hide: true,
      },
      doc_name: {

        overrideHeader: 'Name',
      },
      workstream: {
        overrideHeader: 'Work Stream',
      },
      module: {
        overrideHeader: 'Module',
      },
      status: {
        overrideHeader: 'Status',
      },
      task_object_type: {
        overrideHeader: 'Int/Ext',
      },

      task_objects: {
        overrideHeader: 'Objects',
      },
      task_object_state: {
        overrideHeader: 'Are all objects Assigned?',
      },
      task_owner: {
        overrideHeader: 'Owner',
      },
      task_assignee: {
        overrideHeader: 'assignee',
      },
      task_begin_date: {
        overrideHeader: 'Est Begin Date',

      },
      task_end_date: {
        overrideHeader: 'Est End Date',
      },
      actual_task_begin_date: {
        overrideHeader: 'Actual Begin Date',
      },
      actual_task_end_date: {
        overrideHeader: 'Actual End Date',
      },
      task_notes: {
        overrideHeader: 'Comments',
      },
      doc_type: {
        hide: true,
        label: 'implementation_task'
      }

    }

    const { state: { systemUsersData } } = this.props;
    let userOptions = systemUsersData.map((usr) => ({
      key: usr.uuid,
      text: `${usr.first_name} ${usr.last_name}`,
      value: `${usr.first_name} ${usr.last_name}`,
    }))

    let objectOptions = this.state.taskObjectsData.map((obj) => ({
      key: obj.object_id,
      text: obj.object_name,
      value: obj.object_name,
    }))
    // console.log('userOptions')
    // console.log(userOptions)
    // console.dir(userOptions)
    rowCellInfo.task_object_state.options = buildDropdownOptions(this.state.taskObjStateOptions)
    rowCellInfo.task_object_type.options = buildDropdownOptions(this.state.taskObjTypeOptions)
    rowCellInfo.status.options = buildDropdownOptions(this.state.statusOptions)
    rowCellInfo.workstream.options = buildDropdownOptions(this.state.taskWorkStreamOptions)
    rowCellInfo.module.options = buildDropdownOptions(this.state.taskModuleOptions)
    rowCellInfo.task_owner.options = userOptions
    rowCellInfo.task_assignee.options = userOptions
    rowCellInfo.task_objects.options = objectOptions
    return (
      <div id="createTier">
        <BreadCrumb {...this.props} />
        <div className="header">Create Implementation
          <button>
            <FliptButton margin-FliptButton name="Save Implementation" className="primary searchButton" onClick={this.handleSaveImplementation} />
          </button>
        </div>
        <div className="content">
          <div className="section">
            <div id="Hierarchy">
              <FliptHierarchyDropdown editMode={true} form={form} setForm={this.setForm} />
            </div>
            <section className="tier-inputs-container spacing border-line shadow">
              <div className="tier-inputs">
                <span>Implementation Name</span>
                <FliptInput placeholder="Implementation Name" name="doc_name" value={form.doc_name} onChange={this.updateFields} />
              </div>
              <div className="tier-inputs">
                <span>Implementation Line Of Business</span>
                <FliptDropdown name="lob" options={lobOptions} value={form.lob} onChange={this.updateFields} single />
              </div>
              <div className="tier-inputs">
                <span>Estimated Go Live Date</span>
                <FliptDatePicker className="create-tier-start-date" name="estimated_go_live" value={convertStrToDateObj(form.estimated_go_live)} onChange={this.updateFields} />
              </div>
              <div className="tier-inputs">
                <span>Estimated Start Date</span>
                <FliptDatePicker className="create-tier-start-date" name="estimated_start_date" value={convertStrToDateObj(form.estimated_start_date)} onChange={this.updateFields} />
              </div>
              <div className="tier-inputs">
                <span>Estimated Testing Date</span>
                <FliptDatePicker className="create-tier-start-date" name="estimated_testing_date" value={convertStrToDateObj(form.estimated_testing_date)} onChange={this.updateFields} />
              </div>
            </section>

          </div>
        </div>
        <div className="header">Assign Tasks
          <button>
            <FliptButton margin-FliptButton name="Add Task" className="primary searchButton" onClick={this.addRow} />
          </button>
        </div>
        <div className="content">
          <div className="section">
            <div className='grid-container'>
              <FliptGrid
                data={tasks}
                headers={headers}
                cellEditorParams={cellEditorParams}
                rowCellInfo={rowCellInfo}
                cellRendererParams={cellRendererParams} />
            </div>
          </div>
        </div>
      </div>

    );
  }
}

const mapStateToProps = (state) => ({
  state: {
    user: state.user,
    implementationData: state.clientImplementation.implementation.implementation_details,
    tasksData: state.clientImplementation.implementation.task_details,
    systemUsersData: state.userManagement.data,
    taskObjects: state.clientImplementation.taskObjects
  },
});

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...NavigationActions,
    ...AppActions,
    ...UserActions,
    ...ClientImplementationActions,
    ...UserManagementActions
  };

  return {
    actions: bindActionCreators(allActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewImplmentation);
