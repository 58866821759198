import React, { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Creators as UserActions } from '../../../redux/reducers/userManagement/index'

import PROGRESSION from '../../../config/constants/progression'
import FliptDropdown from '../fliptDropdown'
import './styles.scss'

const FliptHierarchyDropdown = (props) => {
  const [activeHierarchy, setActiveHierarchy] = useState([]);
  let [dropdownValues, setDropdownValues] = useState({})
  const [rerender, setRerender] = useState(0)
  const [parentChildMap, setParentChildMap] = useState({})
  useEffect(() => {
    let hierarchyAccessArray = []
    let activeHierarchyAccessArray = []
    const { state } = props
    if (props.form.hierarchy && props.form?.hierarchy.length !== 0) {
      getActiveHierarchy(Object.values(props.form.hierarchy).map(x => x)[0], activeHierarchyAccessArray)
    }
    if (state.user.userHierarchy) {
      getActiveHierarchy({ name: 'TOP', children: Object.values(state.user.userHierarchy).map(x => x[0]) }, hierarchyAccessArray)


      let hierarchyDropdown = {}
      if (activeHierarchyAccessArray.length !== 0) {
        activeHierarchyAccessArray.forEach((v) => {
          hierarchyDropdown[v.typeOfNode] = v.keys.map(v => v.value)
        })
      } else {
        hierarchyAccessArray.forEach((v) => {
          hierarchyDropdown[v.typeOfNode] = []
        })
      }
      setDropdownValues(hierarchyDropdown)
    }
    setActiveHierarchy(hierarchyAccessArray)
    setRerender(rerender + 1)
  }, [props.form.doc_name, props.form.name, props.form.enhanced_tier])

  useEffect(() => {
    const { setForm, form } = props
    function buildParentChildMap(node, map) {
      if (!node.children || node.children.length === 0) return
      node.children.forEach(child => {
        map[node.key] = map[node.key] || []
        map[node.key].push(child.key)
        buildParentChildMap(child, map)
      })
    }
    if(!form.hierarchyIDs || !form.hierarchyIDsWithChildren) {
      const parentChildMap = {}
      if (!props.state.user.userHierarchy) return
      Object.values(props.state.user.userHierarchy).forEach(root => {
        if (Array.isArray(root)) {
          root.forEach(subRoot => buildParentChildMap(subRoot, parentChildMap))
        } else {
          buildParentChildMap(root, parentChildMap)
        }
      }) 
      let allHierarchyIds = []
      for (let key in parentChildMap) {
        allHierarchyIds.push(key)
        parentChildMap[key].forEach(value => allHierarchyIds.push(value))
      }
      setParentChildMap(parentChildMap)
      setForm({
        ...form,
        hierarchyIDs: allHierarchyIds,
        hierarchyIDsWithChildren: allHierarchyIds
      })

    }
  }, [])


  const handleInputChange = (e, dropdown) => {
    const { setForm, form } = props
    const { name, value } = dropdown || e.currentTarget
    if (activeHierarchy.filter(v => v.typeOfNode == name).length !== 0) {
      if (dropdownValues[name]) {
        dropdownValues[name] = value
      } else {
        dropdownValues = { ...dropdownValues, [name]: value }
      }
      const dropdownIds = {}
      const hierarchyMap = activeHierarchy.reduce((acc, node) => {
        acc[node.typeOfNode] = node
        return acc
      }, {})

      Object.keys(dropdownValues).forEach(key => {
        if (hierarchyMap[key]) {
          dropdownIds[key] = dropdownValues[key].map(value => {
            const matchedKey = hierarchyMap[key].keys.find(x => x.value === value)
            return matchedKey ? matchedKey.key : null
          });
        } else {
          dropdownIds[key] = []
        }
      })
      _validateHierarchyDropdown(dropdownValues, dropdownIds, hierarchyMap)
      setDropdownValues(dropdownValues)
      const { hierarchy, hierarchyIDs, hierarchyIDsWithChildren } = _convert(dropdownValues, props.state.user.userHierarchy)
      setForm({
        ...form,
        hierarchy: hierarchy,
        hierarchyIDs: hierarchyIDs,
        hierarchyIDsWithChildren: hierarchyIDsWithChildren,
        hierarchyDropdown: dropdownValues,
      })
    }
  }

  const _convert = (hierarchyDropdown, userCompanyHierarchyData) => {
    function selectAllChildren(parentId, map, selectedIds) {
      if (!map[parentId]) return
      map[parentId].forEach(childId => {
        selectedIds.add(childId)
        selectAllChildren(childId, map, selectedIds)
      })
    }
    let hierarchy = [{ name: activeHierarchy.filter(v => v.typeOfNode == "organization")[0].keys[0].parent, organization: [] }]
    let queue = []
    queue.push(hierarchy)
    Object.keys(hierarchyDropdown).forEach(key => {
      const currentDropdownValues = activeHierarchy.filter(v => v.typeOfNode == key)[0].keys
      queue.forEach(queueValue => {
        if (!queueValue) return
        queueValue.forEach(parentNode => {
          if (!Array.isArray(parentNode[key])) {
            parentNode[key] = [];
          }
          let childNode = parentNode[key]
          let parentOf = PROGRESSION[PROGRESSION.indexOf(key) + 1]
          let valueToParent = {}
          currentDropdownValues.forEach(dropdownElement => {
            valueToParent[dropdownElement.value] = dropdownElement.parent
          })
          hierarchyDropdown[key].forEach((node) => {
            if (valueToParent[node] == parentNode.name || key == "organization") {
              if (parentOf) {
                childNode.push({ name: node, [parentOf]: [] })
              } else {
                childNode.push({ name: node })
              }
            }
          })
          queue.push(childNode)
        })
      })
      queue.shift()
    })
    // this needs to be explored to make sure that the org.name is actually tied to the organization instead of just the top of the hierarchy
    const hierarchyOrgToCompanyMapping = {}
    Object.entries(userCompanyHierarchyData).forEach(([company, value]) => {
      hierarchyOrgToCompanyMapping[value[0].organization_id] = company
    })
    const hierarchyNodeToOrgMapping = {}
    hierarchy[0].organization.map((org) => {
      hierarchyNodeToOrgMapping[org.name] = org.name
      org.client.map((client) => {
        hierarchyNodeToOrgMapping[client.name] = org.name
        client.carrier.map((carrier) => {
          hierarchyNodeToOrgMapping[carrier.name] = org.name
          carrier.account.map((account) => {
            hierarchyNodeToOrgMapping[account.name] = org.name
            account.group.map((group) => {
              hierarchyNodeToOrgMapping[group.name] = org.name
            })
          })
        })
      })
    })
    let hierarchyIDs = []

    let hierarchyIDsWithChildren = []
    PROGRESSION.forEach((level, idx) => {
      if (idx === PROGRESSION.length-1) return
      if (idx === 0) return
      if (hierarchyDropdown[PROGRESSION[idx + 1]] === undefined) hierarchyDropdown[PROGRESSION[idx + 1]] = []
      if (hierarchyDropdown[level] && hierarchyDropdown[PROGRESSION[idx + 1]].length === 0) {
        hierarchyDropdown[level].forEach(id => {
          const parentId = activeHierarchy.find(x => x?.typeOfNode === level)?.keys?.find(x => x?.value === id)?.key
          hierarchyIDsWithChildren.push(parentId)
          hierarchyIDs.push(parentId)
          let childIds = new Set()
          selectAllChildren(parentId, parentChildMap, childIds)
          childIds.forEach(childId => hierarchyIDsWithChildren.push(childId))
        })
      } else {
        hierarchyDropdown[level].forEach(id => {
          hierarchyIDs.push(activeHierarchy.find(x => x?.typeOfNode === level)?.keys?.find(x => x?.value === id)?.key)
          hierarchyIDsWithChildren.push(activeHierarchy.find(x => x?.typeOfNode === level)?.keys?.find(x => x?.value === id)?.key)
        })
      }
    })

    return { hierarchy, hierarchyIDsWithChildren, hierarchyIDs }
  }
  
  const _validateHierarchyDropdown = (dropdownValues, dropdownIds, hierarchyMap) => {
    Object.keys(dropdownValues).forEach(currentProgression => {
      const parentProgression = PROGRESSION[PROGRESSION.indexOf(currentProgression) - 1]

      const parentValues = dropdownValues[parentProgression]
      const currentValues = dropdownValues[currentProgression]

      const currentIds = dropdownIds[currentProgression]
      const parentIds = dropdownIds[parentProgression]

      const currentProgressionObjects = hierarchyMap[currentProgression].keys

      if (parentValues) {
        currentProgressionObjects.forEach((hierarchyNode) => {
          const currentIndex = currentIds.indexOf(hierarchyNode.key)
          if (hierarchyNode.key != currentIds[currentIndex]) {
            return
          }
          if (parentIds.indexOf(hierarchyNode.parent_id) > -1) {
            return
          }          
          currentValues.splice(currentIndex, 1)
          currentIds.splice(currentIndex, 1)
        })
      }
    })
  }

  const getActiveHierarchy = (data, finalHierarchy) => {
    if (!data) return finalHierarchy
    const keyData = Object.keys(data)
    if ((keyData.indexOf('id') > -1 || keyData.indexOf('name')) > -1 && keyData.length < 2) {
      return finalHierarchy
    }
    keyData.forEach((el) => {
      if (Array.isArray(data[el]) && el === 'children') {
        const childLevel = PROGRESSION[PROGRESSION.indexOf(data[el]) + 1]
        data[el].forEach((element) => {
          //if (!element){return}

          if (element?.access || element?.access === undefined) {
            var exists = false
            finalHierarchy.forEach(v => {
              if (v.typeOfNode == element.level) {
                v.keys.push({ parent_id: data.key, value: element[element.level + '_id'], text: (data?.level ? (data[data?.level + '_name'] + " - ") : "") + element[element.level + '_name'], parent: (data?.level ? (data[data?.level + '_id']) : data?.name), key: element?.key })
                exists = true
              }
            })
            if (!exists) {
              finalHierarchy.push({
                typeOfNode: element.level,
                keys: [{ parent_id: data.key, value: element[element.level + '_id'], text: (data?.level ? (data[data?.level + '_name'] + " - ") : "") + element[element.level + '_name'], parent: (data?.level ? (data[data?.level + '_id']) : data?.name), key: element?.key }],
              })
            }
          }
          return getActiveHierarchy(element, finalHierarchy)
        })
      } else if (Array.isArray(data[el]) && PROGRESSION.includes(el)) {
        data[el].forEach((element) => {
          if (element.access || element.access === undefined) {
            var exists = false
            finalHierarchy.forEach(v => {
              if (v.typeOfNode == el) {
                v.keys.push({ parent_id: data.key, value: element.name, text: (data.name ? (data.name + " - ") : "") + element.name, parent: data.name, key: element?.key })
                exists = true
              }
            })
            if (!exists) {
              finalHierarchy.push({
                typeOfNode: el,
                keys: [{ parent_id: data.key, value: element.name, text: (data.name ? (data.name + " - ") : "") + element.name, parent: data.name, key: element?.key }],
              })
            }
          }
          return getActiveHierarchy(element, finalHierarchy)
        })
      }
    })
  }

  const rebuildKeys = (node) => {
    const parent = PROGRESSION[PROGRESSION.indexOf(node.typeOfNode) - 1]
    let keys = []
    var hierarchyDropdownIdx = -1
    var formParent = ""
    node.keys.forEach((nodeKey) => {
      var parentInDropdown = dropdownValues[parent]
      if (parentInDropdown) {
        hierarchyDropdownIdx = parentInDropdown.indexOf(nodeKey.parent)
        formParent = parentInDropdown[hierarchyDropdownIdx]
      }
      if (formParent == nodeKey.parent || node.typeOfNode == "organization") {
        keys = keys.concat(nodeKey)
      }
    })
    return keys
  }

  const { disabled, readOnly } = props

  return (
    <div className="v2-flipt-hierarchy-dropdown">
      {!props?.form?.hideHierarchy && activeHierarchy.map((node) => {
        var keys = rebuildKeys(node)
        var isDisabled = false
        if (keys.length === 0) {
          keys = [{}]
          isDisabled = true
        }
        return (
          <div className="flex-item">
            <FliptDropdown label={node.typeOfNode.charAt(0).toUpperCase() + node.typeOfNode.substring(1)} disabled={disabled || isDisabled} readOnly={readOnly} className="dropdown" placeholder={`Select ${node.typeOfNode}`} multiple selection simple fluid item value={dropdownValues[node.typeOfNode]} onChange={handleInputChange} options={keys} name={node.typeOfNode} />
          </div>
        )
      })}
    </div>

  )
}


const mapStateToProps = (state) => ({
  state: {
    user: state.user,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...UserActions
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(FliptHierarchyDropdown)