export default {
    lics_brand_cost_share: {
        type: 'input',
        disabled: false
    },
    lics_brand_cost_share_type: {
        type: 'dropdown',
        options: [],
        disabled: false
    },
    lics_minimum_brand_cost_share: {
        type: 'input',
        disabled: false
    },
    lics_maximum_brand_cost_share: {
        type: 'input',
        disabled: false
    },
    lics_generic_cost_share_type: {
        type: 'dropdown',
        options: [],
        disabled: false
    },
    lics_minimum_generic_cost_share: {
        type: 'input',
        disabled: false
    },
    lics_maximum_generic_cost_share: {
        type: 'input',
        disabled: false
    }
}