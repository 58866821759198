export const overrideByEditValues = {
  override_all_edits: {
    text: 'Override All Edits',
    value: 'ALL',
    paOverrideValue: 'ALL',
  },
  drug_coverage: {
    text: 'Drug Coverage Override',
    value: 'drug_coverage',
    paOverrideValue: 'drug_coverage',
  },
  copay: {
    text: 'Copay Override',
    value: 'copay',
    paOverrideValue: 'copay',
  },
  refill_too_soon: {
    text: 'Refill Too Soon',
    value: 'REFILL TOO SOON',
    paOverrideValue: 'refill_too_soon',
  },
  dur_reject_error: {
    text: 'DUR Reject Error',
    value: 'DUR REJECT ERROR',
    paOverrideValue: 'DUR_reject',
  },
  days_supply_limitation_for_product_service: {
    text: 'Days Supply Limitation For Product/Service',
    value: 'DAYS SUPPLY LIMITATION FOR PRODUCT/SERVICE',
    paOverrideValue: 'days_supply_limitation',
  },
  brand_medically_necessary: {
    text: 'Brand Medically Necessary',
    value: 'bmn',
    paOverrideValue: 'bmn',
  },
  payer_bypass: {
    text: 'Payer Bypass',
    value: 'payer_bypass',
    paOverrideValue: 'payer_bypass',
  },
  pa: {
    text: 'Prior Authorization',
    value: 'pa',
    paOverrideValue: 'pa',
  },
  lock_in: {
    text: 'Lock-in',
    value: 'lock_in',
    paOverrideValue: 'lock_in',
  },
  lock_out: {
    text: 'Lock-out',
    value: 'lock_out',
    paOverrideValue: 'lock_in',
  },
  sanction: {
    text: 'Sanction',
    value: 'sanction',
    paOverrideValue: 'sanction',
  }
  // other: {
  //   text: 'Other',
  //   value: 'OTHER',
  //   paOverrideValue: 'other',
  // },
}

export const copayOverrideTypeValues = {
  na: {
    text: 'N/A',
    value: 'NA',
    paOverrideValue: 'NA',
  },
  fixed: {
    text: '$',
    value: '$',
    paOverrideValue: 'fixed',
  },
  percentage: {
    text: '%',
    value: '%',
    paOverrideValue: 'percentage',
  },
}

export const claimOrExtendedValues = {
  claim: {
    text: 'Claim Only',
    value: 'claim',
    paOverrideValue: 'claim',
  },
  extended: {
    text: 'Extended',
    value: 'extended',
    paOverrideValue: 'extended',
  },
}

export const copayOverrideValues = {
  yes: {
    text: 'Y',
    value: 'Y',
  },
  no: {
    text: 'N',
    value: 'N',
  },
}

export const refillTooSoonGroups = [
  'JNJRX001ALL',
  'JNJRX',
]

export const payerRoutingOverrideBypassValues = {
  no: {
    text: 'No',
    value: 'N',
  },
  yes: {
    text: 'Yes',
    value: 'Y',
  },
}

export const payerRoutingOverrideBypassValuesForSingleContractor = {
  no: {
    text: 'No',
    value: 'N',
  },
}