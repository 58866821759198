import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Creators as ClaimsActions } from '../../../../redux/reducers/api/claimLookupManagement'
import { Creators as AppActions } from '../../../../redux/reducers/app'

import { overrideByEditValues, payerRoutingOverrideBypassValues, refillTooSoonGroups } from './overrideDetails/overrideDataValues'

import './styles.scss'
import BreadCrumb from '../../../../components/breadCrumb'
import ClaimDetails from './claimDetails'
import Config from '../../../../config'
import OverrideDetails from './overrideDetails'
import { convertSnakeCaseToString, capitalizeStr, convertStrToDateObj, filterRowData } from '../../../../utils/utilities'
import { LinkRenderer } from '../../../../components/fliptGrid/cellRenderers'
import FliptInput from '../../../../components/form/fliptInput'
import FliptGrid from '../../../../components/fliptGrid'
import moment from 'moment'
import { isEmpty } from 'lodash'

class ClaimOverride extends Component {
  constructor(props) {
    super(props)

    const { auth_id, sequence_number } = props.history.location.state
    const { tracking_id } = props.state.user
    this.state = {
      readOnly: false,
      editMode: false,
      contractedProcessorList: [],
      form: {
        auth_id,
        copay_override: 'N',
        copay_override_type: 'NA',
        override_amount: '',
        override_by_edit: 'ALL',
        override_from: '',
        override_percentage: '',
        override_by_edit_other: '',
        override_thru: '',
        overridenumber: '',
        payer_routing_override_bypass: 'N',
        claim_or_extended: '',
        copay_amount_30ds: '',
        copay_amount_60ds: '',
        copay_amount_90ds: '',
        routed_override_processor: '',
        pa_override: {},
        sequence_number,
        copay_amt: '0.0',
        copay_type: '',
      },
    }
  }

  componentDidMount() {
    const { props, props: { history } } = this
    const { readOnly, editMode, sequence_number, auth_id } = history.location.state
    if (readOnly) {
      this.setState({ readOnly: true })
    }

    if (editMode) {
      this.setState({ editMode: true })
    }

    if (readOnly || editMode) {
      const { form } = this.state;
      const { override_from, override_thru, routed_override_processor, claim_only_flag,
        payer_routing_override_bypass, override_number, override_percentage,
        copay_override, override_amount, override_by_edit } = history.location.state;
      form.override_from = convertStrToDateObj(override_from)
      form.override_thru = convertStrToDateObj(override_thru)
      form.override_amount = override_amount
      form.override_percentage = override_percentage
      form.payer_routing_override_bypass = payer_routing_override_bypass
      form.copay_override = copay_override
      form.routed_override_processor = routed_override_processor
      if (copay_override) {
        if (override_amount) form.copay_override_type = '$'
        else if (override_percentage) form.copay_override_type = '%'
        else form.copay_override_type = 'NA'
      }
      const overrideByEditArray = override_by_edit.split(',')
      form.override_by_edit = overrideByEditArray?.length > 1 ? overrideByEditArray[overrideByEditArray.length - 1] : overrideByEditArray[0]
      if (claim_only_flag === 'Y') form.claim_or_extended = 'claim'
      else form.claim_or_extended = 'extended'
      this.setState({ form })
    }

    if (!auth_id || !sequence_number) {
      history.push({ pathname: '/client-setup-management' })
      return
    }

    props.actions.setClaimTransactionData([]);
    props.actions.setContractedProcessorData([]);

    setTimeout(() => {
      const { props } = this;
      props.actions.getClaimTransactionData();
    }, Config.COMPONENT_DATA_LOAD_TIME)
  }

  componentDidUpdate(prevProps) {
    const { props, props: { actions } } = this
    const { providersData, contractedProcessorList } = props.state
    const prevState = prevProps.state
    if (prevState === props.state) return
    const pharmacy_npi = providersData?.pharmacy_npi
    if ((!_.isEqual(prevState.providersData, providersData)) && pharmacy_npi) {
      actions.getContractedProcessorData({ pharmacy_npi })
    }
    if ((!_.isEqual(prevState.contractedProcessorList, contractedProcessorList))) {
      this.createProcessorDropdown(contractedProcessorList)
    }
  }

  createProcessorDropdown = (contractedProcessorListData) => {
    const contractedProcessorList = contractedProcessorListData.map((d, index) => ({
      key: index + 1,
      text: capitalizeStr(convertSnakeCaseToString(d)),
      value: d,
    }))
    this.setState({ contractedProcessorList })
  }

  updateFields = (el, dropdown) => {
    const { contractedProcessorList } = this.state
    const { name, value } = dropdown || el.currentTarget
    const { props } = this
    if (name === 'override_by_edit' && value === overrideByEditValues.refill_too_soon.value) {
      const group_id = props.state?.claimOverview?.member_section?.group_id
      const member_id = props.state?.claimOverview?.member_section?.cardholder_id
      if (refillTooSoonGroups.includes(group_id)) {
        setTimeout(() => { props.actions.getQLDaysLimitMessage({ group_id, member_id }) }, Config.COMPONENT_DATA_LOAD_TIME)
      }
    }
    let payer_routing_override_bypass = name !== 'payer_routing_override_bypass' ? this.state.form.payer_routing_override_bypass : value
    if (name === 'override_by_edit') {
      if (value === 'payer_bypass' && contractedProcessorList.length === 1) {
        const transitionalPortal = {
          header: 'Field Error',
          copy: 'This option is not available',
        }
        props.actions.displayTransitionalPortal(transitionalPortal)
        return;
      }
      this.clearConditionalForm()
      payer_routing_override_bypass = payerRoutingOverrideBypassValues.no.value
    }
    if (name === 'override_by_edit' && value === overrideByEditValues.payer_bypass.value) payer_routing_override_bypass = payerRoutingOverrideBypassValues.yes.value

    this.setState((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        [name]: value,
        payer_routing_override_bypass: payer_routing_override_bypass,
      },
    }))
  }

  _setOverride = () => {
    const { props, state } = this
    const { flipt_person_id, domain_name, benefit_plan_name } = props.state.claimOverview.member_section
    props.actions.setClaimOverride({
      ...state.form,
      flipt_person_id,
      domain_name,
      benefit_plan_name,
    })
  }

  _cancelOverride = () => {
    const { props: { history }, state: { editMode, readOnly } } = this
    if (editMode || readOnly) {
      history.push({ pathname: '/member-lookup' })
    } else {
      history.push({ pathname: '/client-setup-management' })
    }
  }

  clearConditionalForm() {
    this.setState((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        claim_or_extended: '',
        copay_amount_30ds: '',
        copay_amount_60ds: '',
        copay_amount_90ds: '',
        override_percentage: '',
        override_by_edit_other: '',
        override_amount: '',
        copay_override_type: 'NA',
        routed_override_processor: '',
        payer_routing_override_bypass: '',
        copay_amt: '0.0',
        copay_type: ''
      },
    }))
  }
  _renderRejectContent = () => {
    const { state: { rejection_section } } = this.props
    const {
      flipt_reject_code,
      flipt_reject_message,
      flipt_reject_message2,
      payer_reject_code,
      payer_reject_message,
      payer_reject_message2,
      dur_reject,
    } = rejection_section

    const headers = [
      'auth_id', 'processed_date', 'date_of_service', 'product_id', 'drug_name',
      'strength', 'quantity', 'days_supply',
      'dosage_form', 'service_provider_id', 'pharmacy_name', 'prescriber_id',
      'prescriber_name',
    ]
    const headerMapping = {
      startDate: 'processed_date',
      drug_dosage: 'dosage_form',
      drug_strength: 'strength',
      quantity_dispensed: 'quantity',
    }


    const cellRendererParams = {
      auth_id: {
        cellRenderer: LinkRenderer,
        searchArgs: {},
        onCellClicked: (details) => {
          const { history } = this.props
          history.push(`/claim-transaction-overview?auth_id=${details.value}`)
        },
      },
      product_id: {
        overrideHeader: 'NDC',
      },
      service_provider_id: {
        overrideHeader: 'Pharmacy NPI',
      },
      prescriber_id: {
        overrideHeader: 'Presciber NPI',
      },
    }
    const gridData = dur_reject && dur_reject.length ? dur_reject.map((d) => ({
      ...filterRowData(d, headers, headerMapping),
      processed_date: moment(`${d.startDate}Z`).tz('America/New_York').format('MM/DD/YYYY HH:mm:ss'),
    })) : []

    return (
      <div className="section">
        <h2>Rejection Detail</h2>
        <div className="fields-container">
          <div className="fields">
            <FliptInput value={flipt_reject_code} label="Flipt Reject Code" stylized />
            <FliptInput value={payer_reject_code} label="Payer Reject Code" stylized />
          </div>
          <div className="fields">
            <FliptInput value={flipt_reject_message} label="Flipt Reject Message 1" stylized />
            <FliptInput value={payer_reject_message} label="Payer Reject Message 1" stylized />
          </div>
          <div className="fields">
            <FliptInput value={flipt_reject_message2} label="Flipt Reject Message 2" stylized />
            <FliptInput value={payer_reject_message2} label="Payer Reject Message 2" stylized />
          </div>
        </div>
        {!!dur_reject && !!dur_reject.length && (
          <div className="dur-grid">
            <FliptGrid
              data={gridData}
              headers={headers}
              cellRendererParams={cellRendererParams}
            />
          </div>
        )}
      </div>
    )
  }

  render() {
    const { props, state } = this
    const { form, readOnly, editMode, contractedProcessorList } = state
    const { sequence_number } = props.history.location.state
    const { claimOverview, qlDaysLimitMSG, rejection_section, pricing, claimDetailData } = props.state
    const claimTransactionsData = {
      claimTransactionData: {
        rejection_section,
      }
    }
    const claimDetailDataCopy = [...claimDetailData]
    const claim = (isEmpty(claimDetailDataCopy)?[]:claimDetailDataCopy).sort((a,b)=> b.sequenceNumber - a.sequenceNumber)
    const latestClaim = claim.shift()
    const ispaidClaim = sequence_number !== latestClaim?.sequenceNumber && latestClaim?.claim_status === 'P'
    return (
      <div id="ClaimOverride" className="content-co">
        <BreadCrumb {...this.props} />
        <div className="header">
          <h1>Claim Override</h1>
        </div>
        <div className="subhead" />
        <div className="content">
          {!!claimOverview && <ClaimDetails data={claimOverview} />}
          {!!claimOverview && <OverrideDetails ispaidClaim={ispaidClaim} data={form} claimOverview={claimOverview} updateOverrideForm={this.updateFields} setOverride={this._setOverride} cancelOverride={this._cancelOverride} actions={props.actions}
            ql_limit_days_msg={qlDaysLimitMSG} readOnly={readOnly} editMode={editMode} contractedProcessorList={contractedProcessorList} pricing={pricing} />}
          {!!claimOverview && this._renderRejectContent()}
        </div>
        <div className="section-header"> </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  state: {
    claimOverview: state.claimLookup.claimTransactionData.overview_section,
    rejection_section: state.claimLookup.claimTransactionData.rejection_section,
    providersData: state.claimLookup.claimTransactionData.providers_section,
    qlDaysLimitMSG: state.claimLookup.qlDaysLimitMSG,
    contractedProcessorList: state.claimLookup.contractedProcessorList?.claim_processor || [],
    user: state.user,
    pricing: state.claimLookup.claimTransactionData?.pricing_section?.patient_pay || {},
    claimDetailData: state.claimLookup.claimDetailData,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...ClaimsActions,
    ...AppActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClaimOverride)
