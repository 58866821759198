import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Creators as AppActions } from '../../../../redux/reducers/app'
import { Creators as NetworkTierCreationActions } from '../../../../redux/reducers/api/networkCreationManagement'
import { Creators as NavigationActions } from '../../../../redux/reducers/navigation'
import CreateNetworkComponent from './create'

const CreateNetworkTier = (props) => {
  return <CreateNetworkComponent
    {...props}
    screen='tiers'
  />
}

const mapStateToProps = (state) => ({
  state: {
    claimProcessorNetworkData: state.networkTierCreation.claimProcessorNetworkData,
    networkEditData: state.networkTierCreation.networkTierData,
    networkEditCondData: state.networkTierCreation.networkTierCondData,
    chainCode: state.networkTierCreation.chainCode,
    specialtyDrug: state.networkTierCreation.specialtyDrug,
    corpName: state.networkTierCreation.corpName
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
    ...NavigationActions,
    ...NetworkTierCreationActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateNetworkTier)