import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import _ from 'lodash'
import { Creators as PlanManagementActions } from '../../../../redux/reducers/api/planManagement'
import './styles.scss'
import FliptDropdown from '../../../../components/form/fliptDropdown'
import FliptInput from '../../../../components/form/fliptInput'

class RewardPenalties extends Component {
  constructor(props) {
    super(props)
    this.state = {
      rewardsPenaltiesData: props.state?.rewardsPenaltiesData,
    }

  }

  componentDidUpdate(prevProps, prevState) {
    const { copyFormData } = this.props
    if (copyFormData && !_.isEqual(prevState, this.state)) {
      copyFormData(this.state.rewardsPenaltiesData)
    }
  }

  _updateFields = (el, dateData) => {
    const { name, value } = dateData || el.currentTarget

    this.setState((prevState) => ({
      ...prevState,
      rewardsPenaltiesData: {
        ...prevState.rewardsPenaltiesData,
        [name]: value,
      },
    }))
  }
  finalDropdowns = (fieldDetails) => {
    const { rewardsPenaltiesData: f } = this.state
    return fieldDetails.map(fie => {
      const ccOptions = fieldDetails?.find(({ field, options }) => field === fie.field && options.length > 0)
      const ccDropdown = ccOptions?.options.map(x => {
        x["text"] = x["display_name"]
        return x
      })
      return (
          <div className="rewards-dropdowns">
            <FliptDropdown
                placeholder="Select"
                label={fie.display_name}
                name={fie.field}
                value={f[fie.field]}
                options={ccDropdown}
                onChange={this._updateFields}
                stylized
            />
          </div>

      )
    })

  }

  render(){
    const { fieldDetails } = this.props
    return(
      <div className="rewards">
        {this.finalDropdowns(fieldDetails)}
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  return ({
    state: {
      app: state.app,
      rewardsPenaltiesData: state.planManagement.rewardsPenaltiesData,
    },
  })
}
const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...PlanManagementActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RewardPenalties)
