import {
    call,
    put,
    select,
    takeLatest,
} from 'redux-saga/effects'

import { Types, Creators as QuestionnaireBuilderActions } from '../../reducers/api/questionnaireBuilder'
import { Creators as AppActions } from '../../reducers/app'
import {
    getAppState, getApiPath
} from '../../reducers/selectors'
import { fetchPost, fetchPatch, fileUploadPost } from '../../../utils/fetchApi'
import { readFileAsDataURL } from '../../../utils/utilities'

export default [
    getQuestionnaireDataWatcher,
    parseQuestionnaireFileWatcher,
    editQuestionnaireWatcher,
]

/* WATCHERS */
function* getQuestionnaireDataWatcher() {
    yield takeLatest(Types.GET_QUESTIONNAIRE_DATA, getQuestionnaireDataHandler)
    yield takeLatest(Types.CREATE_QUESTIONNAIRE, createQuestionnaireHandler)
    yield takeLatest(Types.UPLOAD_QUESTIONNAIRE, uploadQuestionnaireHandler)
}

function* parseQuestionnaireFileWatcher() {
    yield takeLatest(Types.PARSE_QUESTIONNAIRE_FILE, parseQuestionnaireFileHandler)
}

function* editQuestionnaireWatcher() {
    yield takeLatest(Types.EDIT_QUESTIONNAIRE, editQuestionnaireHandler)
}

/* HANDLERS */

function* getQuestionnaireDataHandler({ payload }) {
    try {
        const { serviceUrl } = yield select(getAppState)
        const cd = yield select(getApiPath, 'questionnaire-summary')
        const { api_path } = cd
        const url = `${serviceUrl}${api_path}`

        const { data } = yield call(fetchPost, url, payload)
        if (!data.length) {
            yield put(QuestionnaireBuilderActions.clearQuestionnaireData())
            throw Error('No Questionnaire found ')
        }
        yield put(QuestionnaireBuilderActions.setQuestionnaireData(data))
    } catch (err) {
        console.log('getQuestionnaireDataHandler Error >Data ', err)

        const transitionalPortal = {
            header: 'Get Questionnaire Lookup Failed',
            copy: err.message,
        }
        yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    }
}

function* createQuestionnaireHandler({ payload }) {
    try {
        const { serviceUrl } = yield select(getAppState)
        const cd = yield select(getApiPath, 'create-questionnaire')
        const { api_path } = cd
        const url = `${serviceUrl}${api_path}`

        const { data } = yield call(fetchPost, url, payload)
        if (!data.length) throw Error('Create Questionnaire failed')
        yield put(QuestionnaireBuilderActions.setCreateQuestionnaireData(data[0]))
        const transitionalPortal = {
            header: 'Questionnaire Created',
            copy: 'Questionnaire Created Successfully',
        }
        yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    } catch (err) {
        console.log('createQuestionnaireHandler Error >Data ', err)

        const transitionalPortal = {
            header: 'Create Questionnaire Failed',
            copy: err.message,
        }
        yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    }
}

function* uploadQuestionnaireHandler({ payload }) {
    try {
        const { serviceUrl } = yield select(getAppState)
        const cd = yield select(getApiPath, 'upload-questionnaire')
        const { api_path } = cd
        const url = `${serviceUrl}${api_path}`
        const formData = new FormData()
        const { questionnaireFile, faxFormFile, questionnaireObject } = payload
        formData.append("files", questionnaireFile);
        formData.append("files", faxFormFile);
        Object.keys(questionnaireObject).forEach(key => {
            formData.append(key, questionnaireObject[key])
        })
        const response = yield call(fileUploadPost, url, formData)
        const { data } = response
        if (!data.length) throw Error('Upload Questionnaire failed')
        if (!data[0].questionnaire) throw Error('No Data Found')
        yield put(QuestionnaireBuilderActions.setQuestionFinalData(data[0].questionnaire))
        yield put(QuestionnaireBuilderActions.setDrugFinalData(data[0].drugs))
        const transitionalPortal = {
            header: 'Questionnaire Upload',
            copy: 'Questionnaire Upload Successfully',
        }
        yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    } catch (err) {
        console.log('uploadQuestionnaireHandler Error >Data ', err)

        const transitionalPortal = {
            header: 'Upload Questionnaire Failed',
            copy: err.message,
        }
        yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    }
}

function* parseQuestionnaireFileHandler({ payload }) {
    try {
        const { serviceUrl } = yield select(getAppState)
        yield put(QuestionnaireBuilderActions.clearQuestionnaireErrorData())
        const cd = yield select(getApiPath, 'parse-questionnaire-file')
        const { api_path } = cd
        const url = `${serviceUrl}${api_path}`
        const { questionnaireFile } = payload
        const fileDataURL = yield call(readFileAsDataURL, questionnaireFile)
        const response = yield call(fetchPost, url, { fileDataURL, filename: questionnaireFile.name })
        const { data } = response
        if (!data.length) throw Error('Upload Questionnaire failed')
        let questionnaireErrorData = {}

        if (!data[0].questionnaire) {
            Object.keys(data[0]).forEach(key => {
                if (data[0][key].length > 0) {
                    questionnaireErrorData[key] = []
                    for (let i = 0; i < data[0][key].length; i++) {
                        if (data[0][key][i]) {
                            questionnaireErrorData[key].push(data[0][key][i])
                        }
                    }
                }
            })
        }
        if (Object.keys(questionnaireErrorData).length) {
            const transitionalPortal = {
                header: 'Issues Parsing Questionnaire File',
                copy: 'Please check the file issues',
            }
            yield put(QuestionnaireBuilderActions.setQuestionnaireErrorData(questionnaireErrorData))
            yield put(AppActions.displayTransitionalPortal(transitionalPortal))
        } else {
            yield put(QuestionnaireBuilderActions.setQuestionnaireImportData(data[0]))
            const transitionalPortal = {
                header: 'Questionnaire Data Parsed Successfully',
                copy: 'Questionnaire Data Preview Below. Please press Save below grid to save Questionnaire.',
            }
            yield put(AppActions.displayTransitionalPortal(transitionalPortal))
        }
    } catch (err) {
        console.log('parseQuestionnaireFileHandler Error >Data ', err)

        let message = err?.message || err || 'Unkown error occured while parsing the file.'
        if (err === 'Network Error')
            message = 'Server refused to parse the file. Please check the file format.'

        const transitionalPortal = {
            header: 'Issues Parsing Questionnaire File',
            copy: message,
        }
        yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    }
}

function* editQuestionnaireHandler({ payload }) {
    try {
        const { serviceUrl } = yield select(getAppState)
        const cd = yield select(getApiPath, 'questionnaire-edit')
        const { api_path } = cd
        const url = `${serviceUrl}${api_path}`
        const requestData = { ...payload }
        const { questionnaireFile, faxFormFile } = payload
        try {
            if (questionnaireFile) {
                requestData.questionnaireDataURL = yield call(readFileAsDataURL, questionnaireFile)
                requestData.questionnaire_file_name = questionnaireFile.name
                delete requestData.questionnaireFile
            }
            if (faxFormFile) {
                requestData.faxFormDataURL = yield call(readFileAsDataURL, faxFormFile)
                requestData.fax_form_file_name = faxFormFile.name
                delete requestData.faxFormFile
            }
        } catch (err) {
            throw Error('Error occured while reading file from the disk.')
        }
        const response = yield call(fetchPatch, url, requestData)
        const { data } = response
        if (!data.length) throw Error('Saving Questionnaire failed')
        const transitionalPortal = {
            header: 'Questionnaire Saved',
            copy: 'Questionnaire Saved Successfully',
        }
        yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    } catch (err) {
        console.log('editQuestionnaireHandler Error >Data ', err)
        let message = err?.message || 'Unkown error occured while parsing the file.'
        if (err === 'Network Error')
            message = 'Server refused to accept the request. Please check the file format.'
        const transitionalPortal = {
            header: 'Create/Edit Questionnaire Handler  Failed',
            copy: message,
        }
        yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    }
}
