import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import './styles.scss'
import FliptSearch from '../../v2/fliptSearch'
import FliptInput from '../../v2/fliptInput'
import FliptGrid from '../../fliptGrid'
import FliptButton from '../../v2/fliptButton'
import { Creators as AutoSuggestActions } from '../../../redux/reducers/api/autoSuggest'
import { Creators as ManualClaimEntryActions } from '../../../redux/reducers/api/manualClaimEntry'
import { Creators as DrugLookupActions } from '../../../redux/reducers/api/drugLookup'
import { Creators as AppActions } from '../../../redux/reducers/app'
import { bindActionCreators } from 'redux'
import { useState } from 'react'

function FliptDrugSearch(props) {
    const { updateDrugData, actions, isUm, umObjects, state: { manualClaimEntry, drugLookup,
        autoSuggestData, isLoading } } = props


    const [searchForm, setSearchForm] = useState({
        drug_name: '',
        gpi: '',
        ndc: '',
    })

    const [resultValues, setResultValues] = useState([])
    const [drugDataList, setDrugDataList] = useState([])
    const [drugData, setDrugData] = useState(null)
    const [searchBy, setSearchBy] = useState(false)
    const minCharacters = 3


    const _clearDrugFields = () => {
        setSearchForm({
            drug_name: '',
            gpi: '',
            ndc: '',
        })
        setResultValues([])
        setDrugDataList([])
        setDrugData(null)
        setSearchBy(false)
        updateDrugData(null)
    }

    const _searchDrug = () => {
        if (searchForm.drug_name.length) {
            return
        }
        actions.setNdcLookupFailed(false)
        if (isUm) {
            setSearchBy('UM')
            setDrugData(null)
            setDrugDataList([])
            actions.clearDrugInfoWithUm();
            const key = searchForm.ndc.length ? 'ndc' : searchForm.gpi.length ? 'gpi' : 'drug_name';
            const value = searchForm[key];
            if (value) {
              actions.getDrugInfoWithUm({ [key]: value, form: umObjects });
            }
        }
        else if (searchForm.ndc.length) {
            setSearchBy('NDC')
            setDrugData(null)
            setDrugDataList([])
            actions.clearManualClaimEntryNDC()
            actions.fetchManualClaimEntryNDC({ ndc: searchForm.ndc, noDataClearing: true })
        }
        else if (searchForm.gpi.length) {
            setSearchBy('GPI')
            setDrugData(null)
            setDrugDataList([])
            actions.clearDrugInfoBySearch()
            actions.getDrugInfoBySearch({ gpi: searchForm.gpi })
        }
        else {
            const transitionalPortal = {
                header: 'Fields are missing',
                copy: 'Please add ndc, gpi or drug name to search the drug',
            }
            actions.displayTransitionalPortal(transitionalPortal)
        }
    }

    useEffect(() => {
        _updateResultSearchValues(autoSuggestData['drug_name'])
    }, [autoSuggestData])

    useEffect(() => {
        _updateOnComponent()
    }, [drugLookup, manualClaimEntry])
    
    const _updateOnComponent = () => {
        switch (searchBy) {
            case 'NDC': {
                if (manualClaimEntry?.ndc?.drug_name) setDrugDataList([manualClaimEntry.ndc])
            }
                break;
            case 'DRUG': {
                if (drugLookup?.drugInfo?.length) setDrugDataList(drugLookup?.drugInfo)
            }
                break;
            case 'GPI': {
                if (drugLookup?.drugInfo?.length) setDrugDataList(drugLookup?.drugInfo)
            }
                break;
            case 'UM': {
                if (drugLookup?.drugInfoWithUm?.length) setDrugDataList(drugLookup?.drugInfoWithUm)
            }
                break;
        }
    }

    const _updateResultSearchValues = (drugsArray) => {
        const resultValues = drugsArray ? drugsArray.map((d) => ({ title: d })) : []
        setResultValues(resultValues)
    }

    const _onDrugSelectionChanged = (data) => {
        const selectedRows = data.api.getSelectedRows();
        if (selectedRows.length) {
            updateDrugData(selectedRows[0])
            setDrugData(selectedRows[0])
        }
        else {
            updateDrugData(null)
            setDrugData(null)
        }
    }

    const _onResultSelect = (e, data) => {
        const { name, result } = data
        searchForm[name] = result.title
        actions.setNdcLookupFailed(false)
        actions.clearDrugInfoBySearch()
        actions.clearDrugInfoWithUm()
        if(isUm) {
            actions.getDrugInfoWithUm({ drug_name: searchForm.drug_name, form: umObjects })
            setSearchBy('UM')
        } else {
            actions.getDrugInfoBySearch({ drug_name: searchForm.drug_name })
            setSearchBy('DRUG')
        }
        setSearchForm(searchForm)
    }

    const _updateFields = (el, dropdown) => {
        const { name, value } = dropdown || el.currentTarget
        const tempSearchForm = { ...searchForm }
        if (name === 'drug_name') { tempSearchForm.gpi = ''; tempSearchForm.ndc = '' }
        if (name === 'gpi') { tempSearchForm.drug_name = ''; searchForm.ndc = '' }
        if (name === 'ndc') { tempSearchForm.gpi = ''; tempSearchForm.drug_name = '' }
        tempSearchForm[name] = value
        setSearchForm(tempSearchForm)
        if (name === 'drug_name' && value.length > 2) {
            if (isUm) {
                actions.getAutoSuggestData({ search_string: value, search_in: 'global_drug_test', form: umObjects, search_for: 'drug_name'})

            } else {
                actions.getAutoSuggestData({ search_string: value, search_in: 'fts_ndc_drugs', search_for: 'drug_name' })
            }
        } else if(name==='drug_name' && value.length <= 2) {
            actions.clearAutoSuggestData()
        }
    }


    const _submitResult = () => {
        actions.toggleModal()
    }

    const headers = ['Select', 'drug_name', 'gpi', 'ndc', 'multi_source', 'dosage_strength', 'dosage']
    const umHeaders = ['Select', 'drug_name', 'gpi', 'ndc', 'multi_source']

    const cellRendererParams = {
        Select: {
            checkboxSelection: true,
            width: '120px'
        }
    }
    return (
        <div className="drug-section-v2">
            <div className="drug-header">
                Find Drug
            </div>
            <div className="field-container">
                <FliptSearch name="drug_name"
                    value={searchForm.drug_name} results={resultValues} loading={isLoading} minCharacters={minCharacters}
                    onResultSelect={(e, data) => _onResultSelect(e, data)}
                    onSearchChange={(e, dropdown) => _updateFields(e, dropdown)}
                    noResultsMessage={isLoading ? "Loading..." : "No Results Found"}
                    label="Drug Name" />
                <FliptInput name="gpi" value={searchForm.gpi} onChange={_updateFields} label="GPI" />
                <FliptInput name="ndc" value={searchForm.ndc} onChange={_updateFields} label="NDC" />
                <div />
            </div>
            <div className="drug-buttons-container">
                <FliptButton name="SEARCH" className="primary" onClick={() => _searchDrug()} />
                <FliptButton name="Clear" className="secondary" onClick={() => _clearDrugFields()} />
            </div>
            {drugDataList?.length ?
                <>
                    <div className="drug-header">
                        Search Results
                    </div>
                    <div className="grid-container">
                        <FliptGrid
                            headers={isUm ? umHeaders : headers }
                            data={drugDataList}
                            rowSelection='single'
                            cellRendererParams={cellRendererParams}
                            onSelectionChanged={(data) => { _onDrugSelectionChanged(data) }} />
                    </div>
                </> : <></>
            }
            {drugData ? <div className="drug-buttons-container">
                <FliptButton disabled={!drugData} name="SUBMIT" className="primary" onClick={() => _submitResult()} />
            </div> : null}
        </div>
    )

}

const mapStateToProps = (state) => {
    return ({
        state: {
            manualClaimEntry: state.manualClaimEntry,
            drugLookup: state.drugLookup,
            autoSuggestData: state.autoSuggest.autoSuggestData,
            isLoading: state.autoSuggest.isLoading,
        },
    })
}

const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...AutoSuggestActions,
        ...DrugLookupActions,
        ...AppActions,
        ...ManualClaimEntryActions,
    }

    return {
        actions: bindActionCreators(allActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FliptDrugSearch)
