import React, { useState } from 'react'
import './styles.scss'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import FliptFileUpload from '../../../../../components/v2/fliptFileUpload'
import { Creators as AppActions } from '../../../../../redux/reducers/app'
import { Creators as ClaimsPRPActions } from '../../../../../redux/reducers/api/claimsProcessingAndReprocessing'
import FliptGrid from '../../../../../components/fliptGrid'
import FliptTable from '../../../../../components/fliptTable'
import FliptButton from '../../../../../components/v2/fliptButton'
import { DropdownEditor } from '../../../../../components/fliptGrid/cellEditors'
import ClaimGrid from '../../components/claimGrid'

const UploadFile = (props) => {
	const { actions, state, claimForm, setClaimForm, editable, testBatchConfig } = props
	const { headers } = state
	const [isUploaded, setIsUploaded] = useState(false)
	const [currentFile, setCurrentFile] = useState(null)
	const [columns, setColumns] = useState([])
	const items = {
		'Prescriber NPI': 'prescriber_id',
		'Pharmacy NPI': 'pharmacy_id',
		'Product ID (NDC)': 'ndc',
		'Days Supply': 'days_supply',
		'Quantity': 'quantity_dispensed',
		'Claim Type': 'claim_type',
		'Date of Service': 'date_of_service',
		'Rx Number': 'prescription_reference_number',
		'Ingredient Cost': 'ingredient_cost_submitted',
		'Dispensing Fee': 'dispensing_fee_submitted',
		'U&C': 'usual_and_customary_charge',
		'Gross Amount Due': 'gross_amount_due',
		'Basis of Cost Determination': 'basis_of_cost_determination',
		'Fill Number': 'fill_number',
		'Compound Code': 'compound_code',
		'DAW': 'daw_code',
		'Date Written': 'date_prescription_written',
		'Incentive (Optional)': 'incentive_amount_submitted',
		'Flat Sales Tax (Optional)': 'flat_sales_tax_amount_submitted',
		'% Sales Tax (Optional)': 'percentage_sales_tax_amount_submitted',
		'% Sales Tax Rate (Optional)': 'percentage_sales_tax_rate_submitted',
		'Submission Clarification Code (Optional)': 'scc',
	}

	const handleChange = (e, dropdown, rowKey, api, stepLevel, data) => {
		const { name, value } = dropdown || e.currentTarget
		const { field } = data
		const newState = {
			...columns,
			[field]: value,
		}
		setColumns(newState)
	}
	const cellRendererParams = {
		field: {
			overridHeader: 'Field',
			type: 'text',
			name: 'field',
			flex: 1,
		},
		mapped_field: {
			overridHeader: 'Mapped Field',
			type: 'dropdown',
			options: headers,
			disabled: false,
			flex: 1,
		},
	}
	const claimRowCellInfo = {
		field: {
			type: 'text',
		},
		mapped_field: {
			type: 'dropdown',
			options: headers?.map((code, index) => ({
				value: code,
				text: code,
				index: index,
			})),
			disabled: false,
		},
	}

	const cellEditorParams = {
		mapped_field: {
			cellEditor: DropdownEditor,
			onChange: handleChange,
		},
	}

	const mapFields = () => {
		const { uploadedClaimFile } = state
		const mappedData = uploadedClaimFile.map((claim) => {
			const mappedClaim = {}
			Object.keys(items).forEach((key) => {
				let claimKey = columns[key]
				mappedClaim[`${items[key]}`] = claim.hasOwnProperty(claimKey) ? claim[claimKey] : ''
			})
			mappedClaim['member_id'] = 'MEMBER_ID_1'
			return mappedClaim
		})
		setClaimForm({ ...claimForm, gridData: mappedData })
	}
	const handleFileChange = (file) => {
		setCurrentFile(file)
		setIsUploaded(false)
	}
	const uploadFile = () => {
		actions.uploadClaimFile(currentFile)
		setIsUploaded(true)
	}
	return (
		<div id="claim-config-upload-file">
			<FliptFileUpload handleFileChange={handleFileChange} uploadFile={uploadFile} />
			{isUploaded ? (
				<div className='mapper-container'>
					<div style={{ height: '1000px' }}>
						<FliptGrid
							headers={['field', 'mapped_field']}
							data={Object.keys(items).map((key) => ({
								field: key,
							}))}
							cellRendererParams={cellRendererParams}
							cellEditorParams={cellEditorParams}
							rowCellInfo={claimRowCellInfo}
						/>
					</div>
					<FliptButton
						className='primary map-fields'
						name='Map Fields'
						onClick={mapFields} />
				</div>
			) : null}
			<ClaimGrid
				editable={editable}
				claimForm={claimForm}
				testBatchConfig={testBatchConfig}
				setClaimForm={setClaimForm}
			/>
		</div>
	)
}

const mapStateToProps = (state) => ({
	state: {
		user: state.user,
		headers: state.claimsProcessingAndReprocessing?.uploadedClaimFile?.headers,
		uploadedClaimFile: state.claimsProcessingAndReprocessing?.uploadedClaimFile?.claim_data,
	},
})

const mapDispatchToProps = (dispatch) => {
	const allActions = {
		...AppActions,
		...ClaimsPRPActions,
	}

	return {
		actions: bindActionCreators(allActions, dispatch),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(UploadFile)
