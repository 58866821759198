
import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import FliptButton from '../../../components/form/fliptButton'
import FliptCheckbox from '../../../components/form/fliptCheckbox'
import BreadCrumb from '../../../components/breadCrumb'
import './styles.scss'
import { Creators as AppActions } from '../../../redux/reducers/app'
import { Creators as QueueConfigActions } from '../../../redux/reducers/api/queueConfig'
import AddQueueConfig from './AddQueueConfig'

class QueueConfiguration extends Component {
    constructor(props) {
        super(props)
        this.state = {
            queueConfigData: []
        }
    }

    componentDidMount() {
        this.props.actions.getQueueConfigData()
    }

    componentDidUpdate(prevProps) {
        const { state: { queueConfigData } } = this.props
        if (queueConfigData !== prevProps.state.queueConfigData && queueConfigData.length) {
            const customQueueConfig = queueConfigData.map(v => ({ ...v, selected: false })) || []
            const chunkedArray = customQueueConfig.length && _.chunk(customQueueConfig, 4)
            this.setState({ queueConfigData: chunkedArray })
        }
    }

    _onChange = (index, innerIndex) => {
        const { queueConfigData } = this.state
        queueConfigData[index][innerIndex].selected = !queueConfigData[index][innerIndex].selected
        this.setState({ queueConfigData })
    }

    _showTransitionalPortal = (header, message) => {
        const { props } = this;
        const transitionalPortal = {
            header: header,
            copy: message,
        }
        props.actions.displayTransitionalPortal(transitionalPortal)
    }

    editConfig = () => {
        const { actions, history } = this.props
        const { queueConfigData } = this.state
        const selectedDataArray = queueConfigData.flat(1)
        const selectedData = selectedDataArray.filter((queue) => !!queue.selected)
        if (selectedData.length !== 1) {
            const header = 'Field Error'
            const message = 'Please Select one Queue to edit'
            this._showTransitionalPortal(header, message)
            return;
        }
        actions.setModalComponent({
            modalProperties: { size: 'large' },
            contents: <AddQueueConfig history={history} selectedData={selectedData[0]}
                buttonName='Edit' />,
        })
        actions.toggleModal()
    }

    addConfig = () => {
        const { actions, history } = this.props
        actions.setModalComponent({
            modalProperties: { size: 'large' },
            contents: <AddQueueConfig history={history} buttonName='Add' />,
        })
        actions.toggleModal()
    }

    render() {
        const { queueConfigData } = this.state
        const checkboxDiv = queueConfigData.length ? queueConfigData.map((data, index) => {
            return (
                <div className="fields-container">
                    {
                        data.map((innerData, innerIndex) => {
                            return (
                                <div className='checkbox-container'>
                                    <FliptCheckbox checked={innerData.selected} onChange={() => this._onChange(index, innerIndex)} />
                                    <span className='checkbox-text'>{innerData.queue_name}</span>
                                </div>
                            )
                        })
                    }
                </div>
            )
        }) : null;

        return (
            <div id="queueConfig" >
                <BreadCrumb {...this.props} />
                <div className="header">
                    Add/Edit Queues information
                </div>
                <div className="inner-container">
                    <span className='queue-header'>Queue</span>
                    {checkboxDiv}
                </div>
                <div className="buttons-container">
                    <FliptButton className='primary' name='ADD' onClick={() => this.addConfig()} />
                    <FliptButton className='primary queue-edit-button' name='EDIT' onClick={() => this.editConfig()} />
                </div>
            </div >

        )
    }
}

const mapStateToProps = (state) => ({
    state: {
        user: state.user,
        queueConfigData: state.queueConfig.queueConfigData,
    }
})

const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...AppActions,
        ...QueueConfigActions
    }

    return {
        actions: bindActionCreators(allActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(QueueConfiguration)