export const lobTypes = [
    {
        key: 0,
        text: 'Commercial',
        value: 'Commercial'
    },
    {
        key: 1,
        text: 'Commercial-Exchange',
        value: 'Commercial-Exchange'
    },
    {
        key: 2,
        text: 'RDS',
        value: 'RDS'
    },
    {
        key: 3,
        text: 'Medicare D',
        value: 'Medicare D'
    },
    {
        key: 4,
        text: 'Medicare - EGWP',
        value: 'Medicare - EGWP'
    },
    {
        key: 5,
        text: 'Medicaid',
        value: 'Medicaid'
    },
    {
        key: 6,
        text: 'Workers Compensation',
        value: 'Workers Compensation'
    },
    {
        key: 7,
        text: 'Other',
        value: 'Other'
    }
]

export const ExportTypes = [
    {
        key: 0,
        text: 'Yes',
        value: 'Yes'
    },
    {
        key: 1,
        text: 'No',
        value: 'No'
    }
]

export const CmsTierLabelTypes = [
    {
        key: 0,
        text: 'Generic, Brand',
        value: 'Generic, Brand'
    },
    {
        key: 1,
        text: 'Generic',
        value: 'Generic'
    },
    {
        key: 2,
        text: 'Brand',
        value: 'Brand'
    },
    {
        key: 3,
        text: 'Preferred Generic',
        value: 'Preferred Generic'
    },
    {
        key: 4,
        text: 'Preferred Brand',
        value: 'Preferred Brand'
    },
    {
        key: 5,
        text: 'Non-Preferred Brand',
        value: 'Non-Preferred Brand'
    },
    {
        key: 6,
        text: 'Non-Preferred Drug',
        value: 'Non-Preferred Drug'
    },
    {
        key: 7,
        text: 'Vaccine',
        value: 'Vaccine'
    },
    {
        key: 8,
        text: 'Injectable Drug',
        value: 'Injectable Drug'
    },
    {
        key: 9,
        text: 'Specialty Tier',
        value: 'Specialty Tier'
    },
    {
        key: 10,
        text: 'Preferred Specialty Tier',
        value: 'Preferred Specialty Tier'
    },
    {
        key: 11,
        text: 'Excluded Drug Only Tier',
        value: 'Excluded Drug Only Tier'
    },
    {
        key: 12,
        text: 'Select Diabetic Drugs',
        value: 'Select Diabetic Drugs'
    },
    {
        key: 13,
        text: 'Select Care Drugs',
        value: 'Select Care Drugs'
    },
    {
        key: 14,
        text: '$0 Drugs',
        value: '$0 Drugs'
    },
    {
        key: 15,
        text: 'Non-Medicare Rx Drugs',
        value: 'Non-Medicare Rx Drugs'
    },
    {
        key: 16,
        text: 'Non-Medicare OTC Drugs',
        value: 'Non-Medicare OTC Drugs'
    },
    {
        key: 17,
        text: 'Non-Medicare Rx/OTC Drugs',
        value: 'Non-Medicare Rx/OTC Drugs'
    }
]

export const submissionFilesData = [
    {
        file_name: 'Formulary File',
        extract: false
    },
    {
        file_name: 'Prior Authorization File',
        extract: false
    },
    {
        file_name: 'Step Therapy File',
        extract: false
    },
    {
        file_name: 'Indication Based Coverage (IBC) File',
        extract: false
    },
    {
        file_name: 'Value Based Insurance Design (VBID) File',
        extract: false
    }

]
export const AlternativeModelsData = [
    {
        benefit_name: 'Free First Fill',
        applied: false
    },
    {
        benefit_name: 'Partial Gap Coverage',
        applied: false
    },
    {
        benefit_name: 'Home Infusion',
        applied: false
    },
    {
        benefit_name: 'Value Based Insurance Design (VBID)',
        applied: false
    },
    {
        benefit_name: 'Excluded Drugs',
        applied: false
    },
    {
        benefit_name: 'Over the Counter Coverage',
        applied: false
    }
]