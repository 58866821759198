import React from 'react'

import './styles.scss'
import { FormularyCopayContainer } from '../../pages/private/planDesignManagement/formularyCopay'
import { PricingMixMasterContainer } from '../../pages/private/planDesignManagement/pricingMixmaster'
import { TransitionFillContainer } from '../../pages/private/planDesignManagement/transitionfill'
import { CustomCopayContainer } from '../../pages/private/planDesignManagement/customCopay'
import { CustomMessagingContainer } from '../../pages/private/planDesignManagement/customMessaging'
import { BenefitLimitationContainer } from '../../pages/private/planDesignManagement/benefitLimitation'
import PlanDesignDetail from '../../pages/private/planDesignManagement/planDesignDetail'
// import Compounds from '../../pages/private/planDesignManagement/compounds'
import { CompoundsContainer } from '../../pages/private/planDesignManagement/compounds'
import RewardsPenalties from '../../pages/private/planDesignManagement/rewardsPenalties'
import { GrandfatheringContainer } from '../../pages/private/planDesignManagement/grandfathering'
import { HierarchyContainer } from '../../pages/private/planDesignManagement/hierarchy'
import ListingHierarchy from '../../pages/private/planDesignManagement/listingHierarchy'
import { PharmacyNetworksContainer } from '../../pages/private/planDesignManagement/pharmacyNetwork'
import DawSettings from '../../pages/private/planDesignManagement/dawSettings'
import Review from '../../pages/private/planDesignManagement/review'
import ClaimProcessingConfig from '../../pages/private/planDesignManagement/claimProcessingConfig'
import { convertStringToSnakeCase } from '../../utils/utilities'
import Phases from '../../pages/private/planDesignManagement/phases'
import { DefaultCopayContainer } from '../../pages/private/planDesignManagement/defaultCopay'
import Deductibles from '../../pages/private/planDesignManagement/deductibles'
import DUR from '../../pages/private/planDesignManagement/dur'
import UtilizationMgmt from '../../pages/private/planDesignManagement/utilizationMgmtCustom'

export default function FliptCustomTab(props) {
  const {
    tabName,
    fieldDetails,
    copyFormData,
    modelData,
    getTabContents,
    context,
  } = props
  const tabNameSnakeCase = convertStringToSnakeCase(tabName)
  return (
    <div className="tabContent">
      {(getCustomComponent(tabNameSnakeCase, fieldDetails, copyFormData, modelData, getTabContents, context))}
    </div>
  )
}

const getCustomComponent = (tabName, fieldDetails, copyFormData, modelData, getTabContents, context) => {
  const customComponents = {
    plan_design_details: () => <PlanDesignDetail tabName={tabName} fieldDetails={fieldDetails} context={context} copyFormData={copyFormData} />,
    utilization_management: () => <UtilizationMgmt modelData={modelData} tabName={tabName} fieldDetails={fieldDetails} context={context} copyFormData={copyFormData} />,
    formulary_copay: () => <FormularyCopayContainer tabName={tabName} fieldDetails={fieldDetails} copyFormData={copyFormData} />,
    custom_copay: () => <CustomCopayContainer tabName={tabName} fieldDetails={fieldDetails} copyFormData={copyFormData} />,
    custom_messaging: () => <CustomMessagingContainer tabName={tabName} modelData={modelData} fieldDetails={fieldDetails} copyFormData={copyFormData} />,
    limitations: () => <BenefitLimitationContainer tabName={tabName} modelData={modelData} fieldDetails={fieldDetails} copyFormData={copyFormData} />,
    grandfathering: () => <GrandfatheringContainer tabName={tabName} fieldDetails={fieldDetails} copyFormData={copyFormData} />,
    hierarchy: () => <HierarchyContainer tabName={tabName} fieldDetails={fieldDetails} copyFormData={copyFormData} />,
    pricing_mixmaster: () => <PricingMixMasterContainer tabName={tabName} fieldDetails={fieldDetails} copyFormData={copyFormData} />,
    deductibles: () => <Deductibles tabName={tabName} fieldDetails={fieldDetails} copyFormData={copyFormData} />,
    transition: () => <TransitionFillContainer tabName={tabName} fieldDetails={fieldDetails} copyFormData={copyFormData} />,
    listing_hierarchy: () => <ListingHierarchy tabName={tabName} modelData={modelData} fieldDetails={fieldDetails} copyFormData={copyFormData} />,
    pharmacy_networks: () => <PharmacyNetworksContainer tabName={tabName} modelData={modelData} fieldDetails={fieldDetails} copyFormData={copyFormData} />,
    daw_settings: () => <DawSettings tabName={tabName} fieldDetails={fieldDetails} copyFormData={copyFormData} />,
    review: () => <Review tabName={tabName} fieldDetails={fieldDetails} copyFormData={copyFormData} modelData={modelData} getTabContents={getTabContents} />,
    compounds: () => <CompoundsContainer tabName={tabName} fieldDetails={fieldDetails} copyFormData={copyFormData} />,
    // rewards_penalties: () => <RewardsPenalties tabName={tabName} fieldDetails={fieldDetails} copyFormData={copyFormData} />,
    phases: () => <Phases tabName={tabName} fieldDetails={fieldDetails} getTabContents={getTabContents} />,
    claim_processing_config: () => <ClaimProcessingConfig tabName={tabName} fieldDetails={fieldDetails} copyFormData={copyFormData} modelData={modelData} />,
    default_copay: () => <DefaultCopayContainer tabName={tabName} fieldDetails={fieldDetails} copyFormData={copyFormData} modelData={modelData} />,
    drug_utilization_review: () => <DUR tabName={tabName} fieldDetails={fieldDetails} copyFormData={copyFormData} modelData={modelData} />,
    // ADD NEW CUSTOM COMPONENTS HERE
  }
  return customComponents[tabName]()
}
