import {
  call, put, select, takeLatest,
} from 'redux-saga/effects'

import {
  Types,
  Creators as ClaimLookupActions,
} from '../../reducers/api/manualClaimAdjustment'
import { Creators as AppActions } from '../../reducers/app'
import {
  getAppState,
  getApiPath,
  getHistory,
} from '../../reducers/selectors'
import { fetchPost, fetchPut, fetchPatch } from '../../../utils/fetchApi'
import { parseQueryString } from '../../../utils/utilities'

export default [
  reprocessClaimDataWatcher,
  adjustedClaimProcessDataWatcher,
  findDuplicateClaimsWatcher,
  getAdjustmentCalculationWatcher,
  getDuplicateClaimReversalWatcher,
  postPaidClaimReversalWatcher,
]

/* WATCHERS */
function* getAdjustmentCalculationWatcher() {
  yield takeLatest(Types.GET_ADJUSTMENT_CALCULATION, getAdjustmentCalculationHandler)
}

function* postPaidClaimReversalWatcher() {
  yield takeLatest(Types.POST_PAID_CLAIM_REVERSAL, postPaidClaimReversalHandler)
}

function* getDuplicateClaimReversalWatcher() {
  yield takeLatest(Types.GET_DUPLICATE_CLAIM_REVERSAL, getDuplicateClaimReversalHandler)
}

function* findDuplicateClaimsWatcher() {
  yield takeLatest(Types.FIND_DUPLICATE_CLAIMS, findDuplicateClaimsHandler)
}

function* adjustedClaimProcessDataWatcher() {
  yield takeLatest(Types.ADJUST_CLAIM_PROCESS_DATA, adjustClaimProcessDataHandler)
}

function* reprocessClaimDataWatcher() {
  yield takeLatest(Types.GET_REPROCESS_CLAIM, reprocessClaimDataHandler)
}

/* HANDLERS */
function* getAdjustmentCalculationHandler(data) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const { api_path } = yield select(getApiPath, 'claim-adjustment-calculation')
    const url = `${serviceUrl}${api_path}`
    const response = yield call(fetchPost, url, data.payload)
    if (!response.data.length) throw Error()

    yield put(ClaimLookupActions.setAdjustmentCalculationData(response.data[0]))
  } catch (err) {
    console.log('getAdjustmentCalculationHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Adjustment Calculations Failed',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* postPaidClaimReversalHandler(data) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const { api_path } = yield select(getApiPath, 'paid-claim-reversal')
    const url = `${serviceUrl}${api_path}`
    const response = yield call(fetchPut, url, data.payload)
    if (!response.data.length) throw Error()
    const transitionalPortal = {
      header: response.data[0]?.general_message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  } catch (err) {
    const transitionalPortal = {
      header: 'Paid Claim Reversal Failed',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* getDuplicateClaimReversalHandler() {
  try {
    // const { serviceUrl } = yield select(getAppState)
    // const { api_path } = yield select(getApiPath, 'duplicate-claim-reversal')
    // const url = `${serviceUrl}${api_path}`
    // const response = yield call(
    //   fetchPost,
    //   url,
    //   data.payload,
    // )
    const transitionalPortal = {
      header: 'Duplicate Claim reversal',
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    yield put(ClaimLookupActions.clearDataReducer())
    const status = 'success'
    yield put(ClaimLookupActions.setReverseClaim(status))
  } catch (err) {
    console.log('getDuplicateClaimReversalHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Duplicate Reversal Failed',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* findDuplicateClaimsHandler() {
  try {
    const { serviceUrl } = yield select(getAppState)
    const history = yield select(getHistory)
    const { auth_id, transaction_id } = parseQueryString(history.location.search)
    const { api_path } = yield select(getApiPath, 'find-duplicate-claims')
    const url = `${serviceUrl}${api_path}`
    const { message } = yield call(fetchPost, url, { auth_id, transaction_id })
    const isDuplicate = message.toLowerCase() === 'true'

    yield put(ClaimLookupActions.setDuplicateClaims(isDuplicate))
  } catch (err) {
    console.log('findDuplicateClaimsHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Duplicate Claims Lookup Failed',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* adjustClaimProcessDataHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const { api_path } = yield select(getApiPath, 'adjust-claim-process')
    const url = `${serviceUrl}${api_path}`
    const response = yield call(fetchPatch, url, payload, true)
    if (!response.data.length) throw Error()
    const transitionalPortal = {
      header: response.data[0]?.general_message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    const status = 'success'
    yield put(ClaimLookupActions.setAdjustClaim(status))
  } catch (err) {
    console.log('adjustClaimProcessDataHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Claim Adjustment Failed',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* reprocessClaimDataHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const { api_path } = yield select(getApiPath, 'reprocess-claim')
    const url = `${serviceUrl}${api_path}`
    const response = yield call(fetchPost, url, payload)
    if (!response.data.length) throw Error()
    const transitionalPortal = {
      header: response.data[0]?.general_message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    yield put(ClaimLookupActions.setReprocessClaim(response))
  } catch (err) {
    console.log('reprocessClaimDataHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Claim Reprocess Failed',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

export {
  adjustClaimProcessDataHandler,
  findDuplicateClaimsHandler,
  getAdjustmentCalculationHandler,
  getDuplicateClaimReversalHandler,
  postPaidClaimReversalHandler,
}
