export default [
  {
    key: 'MAC',
    text: 'MAC',
    value: 'MAC',
  },
  {
    key: 'AWP',
    text: 'AWP',
    value: 'AWP',
  },
  {
    key: 'NADAC',
    text: 'NADAC',
    value: 'NADAC',
  },
]
