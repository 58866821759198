import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Icon } from 'semantic-ui-react'

import FliptGrid from '../../../components/fliptGrid'
import moment from 'moment'
import { Creators as PriorAuthorizationCreationActions } from '../../../redux/reducers/api/priorAuthorizationManagement'
import { convertStrToDateObj } from '../../../utils/utilities'

import './styles.scss'
import BreadCrumb from '../../../components/breadCrumb'
import { LinkRenderer } from '../../../components/fliptGrid/cellRenderers'

class PADocumentList extends Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        this.props.actions.getPriorAuthorizationTemplates({})
    }

    render() {
        const { state } = this.props
        const {
          paTemplates,
        } = state

        const headers = [
            'document_id',
            'document_key',
            'document_name',
            'document_type',
        ]


        // const cellRendererParams = {
        //     questionnaire_id: {
        //         cellRenderer: LinkRenderer,
        //         questionnaire_id: 'questionnaire_id',
        //         path: '/upload-questionnaire',
        //         searchArgs: { questionnaire_id: 'questionnaire_id', group: 'group'},
        //         state: { editMode: true },
        //     },
        // }
        return (
            <div id="questionnaireBuilder">
                <BreadCrumb {...this.props} />

                <div className="header">
                    PA Templates
                    <Link to={{ pathname: '/padocument-template-upload' }} >
                        <div className="create-pa-case" aria-hidden="true">
                            <Icon name="plus circle" size="tiny" />
                            Upload PA Template
                        </div>
                    </Link>
                </div>
                <div className="content">
                    <FliptGrid
                        data={paTemplates}
                        // cellRendererParams={cellRendererParams}
                        headers={headers} />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    state: {
        app: state.app,
        user: state.user,
        paTemplates: state.priorAuthorizationCreation.paTemplates,
    },
})

const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...PriorAuthorizationCreationActions,
    }

    return {
        actions: bindActionCreators(allActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PADocumentList)
