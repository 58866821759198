import { useState } from 'react';
import { Icon } from 'semantic-ui-react';
import FliptButton from '../../../../../../components/v2/fliptButton';

function StackedItem(props) {
    const [animateDirection, setAnimateDirection] = useState('');

    const handleMoveUp = () => {
        setAnimateDirection('animate-up');
        setTimeout(() => {
            setAnimateDirection('')
            props.moveUp();
        }, 250);
    };

    const handleMoveDown = () => {
        setAnimateDirection('animate-down');
        setTimeout(() => {
            setAnimateDirection('')
            props.moveDown();
        }, 250);
    };

    const buildStackRowClassName = () => {
        let className = 'stacked-row'
        if (props.index === 0) {
            className += ' top-border-radius'
        }
        if (props.last) {
            className += ' bottom-border-radius'
        }
        if (animateDirection) {
            className += ' ' + animateDirection
        }
        return className;
    };

    return (
        <li
            className={buildStackRowClassName()}
            draggable
            onDragStart={props.onDragStart}
            onDragOver={props.onDragOver}
            onDrop={props.onDrop}
        >
            <section>
                <span className="stacked-draggable-icon">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="20"
                        fill="none"
                        viewBox="0 0 24 24"
                    >
                        <clipPath id="clip0_11_4288">
                            <path d="M0 0h24v24H0z"></path>
                        </clipPath>
                        <g clipPath="url(#clip0_11_4288)">
                            <path
                                fill="#323232"
                                d="M11 18c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zm-2-8c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
                            ></path>
                        </g>
                    </svg>
                </span>
            </section>
            <section>
                {props.index === 0 ? null : (
                    <FliptButton
                        className='stacked-button'
                        onClick={handleMoveUp}
                        disabled={props.index === 0}
                    >
                        <Icon name='chevron up' />
                    </FliptButton>
                )}
            </section>
            <section>
                {props.last ? null : (
                    <FliptButton
                        className='stacked-button'
                        onClick={handleMoveDown}
                    >
                        <Icon name='chevron down' />
                    </FliptButton>
                )}
            </section>
            <section>
                {props.text}
            </section>
            <section>
                <input
                    className='stacked-input'
                    type="number"
                    value={props.position}
                    onChange={() => { }}
                />
            </section>
        </li>
    )
}

export default StackedItem;
