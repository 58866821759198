import React from 'react'

import FliptInput from '../../../../../components/form/fliptInput'

export default ({ data }) => {
  const {
    drug_manufacturer,
    drug_name,
    drug_strength,
    gpi,
    multi_source,
    otc_indicator,
    product_id,
    drug_dosage,
  } = data

  return (
    <div className="section">
      <h2>Product</h2>
      <div className="fields-container">
        <div className="fields">
          <FliptInput value={product_id || ''} label="Product ID/NDC" stylized />
          <FliptInput value={drug_dosage || ''} label="Dosage Form" stylized />
          <FliptInput value={drug_manufacturer || ''} label="Manufacturer" stylized />
        </div>
        <div className="fields">
          <FliptInput value={gpi || ''} label="GPI Code" stylized />
          <FliptInput value={drug_strength || ''} label="Strength" stylized />
          <FliptInput value={otc_indicator || ''} label="OTC Indicator" stylized />
        </div>
        <div className="fields">
          <FliptInput value={drug_name || ''} label="Product Name" stylized />
          <FliptInput value={multi_source || ''} label="Multi Source Code" stylized />
        </div>
      </div>
    </div>
  )
}
