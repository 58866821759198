

import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import FliptGrid from '../../../../components/fliptGrid'
import { Creators as PlanManagementActions } from '../../../../redux/reducers/api/planManagement'
import { Creators as AppActions } from '../../../../redux/reducers/app'
import { connect } from 'react-redux'
import './styles.scss'
import FliptInput from '../../../../components/form/fliptInput'
import FliptDropdown from '../../../../components/form/fliptDropdown'
import FliptDatePicker from '../../../../components/form/fliptDatePicker'
import { convertStrToDateObj, convertDateToStartTimeObj, convertDateToEndTimeObj } from '../../../../utils/utilities'
import FliptButton from '../../../../components/form/fliptButton'

import * as _ from 'lodash'
import { Icon } from 'semantic-ui-react'
import { DatePickerEditor, DropdownEditor } from '../../../../components/fliptGrid/cellEditors'
import rowCellInfo from './rowCellInfo'
import { DeleteRowRenderer } from '../../../../components/fliptGrid/cellRenderers'
import moment from 'moment-timezone';
import { resolveMessage } from '../../../../utils/validationHelpers';

class PlanDesignDetail extends Component {
    constructor(props) {

        super(props)
        this.state = {
            form: {
                plan_name: '',
                planInfo: [{
                    plan_code: '',
                    display_plan_name: '',
                    group_type: ''
                }],
                grace_period: '',
            },
            formularyData: [{ formulary_id: '', formulary_name: '', effective_begin_date: '', effective_end_date: '', formulary_version_id: '' }],
            minMaxOptions: [{
                effective_begin_date: { minDate: '', maxDate: '' },
                effective_end_date: { minDate: '', maxDate: '' }
            }],
            planYearOptions: [],
            lobOptions: [],
            medicareDType: [],
            medicarePlanType: [],
            medicare_plan_name: [],
            formularyOptions: [],
            medicareFormularyOptions: [],
            specialProgramType: [],
            medContractIdError: null,
            medPbpError: null
        }
    }


    componentDidMount() {
        const { props: { fieldDetails, state } } = this;
        const { form, formularyData } = this.state
        let planYearOptions = []
        let lobOptions = []
        const lobAdditions = ["Worker's Compensation", "Other"]
        let medicareDType = []
        let medicarePlanType = []
        let formularyOptions = []
        let medicareFormularyOptions = []
        let specialProgramType = []
        let obj = fieldDetails.find(o => o.field === "plan_year");
        planYearOptions = obj.options;
        planYearOptions = [...new Set(planYearOptions.map((data, index) => ({
            key: index + 1,
            text: data.display_name,
            value: data.value,
        })))];

        obj = fieldDetails.find(o => o.field === "lob");
        lobOptions = obj.options;
        lobOptions = [...new Set(lobOptions.map((data, index) => ({
            key: index + 1,
            text: data.display_name,
            value: data.value,
        })))];

        lobAdditions.forEach((el) => {
            lobOptions.push({
                key: lobOptions.length,
                text: el,
                value: el,
            })
        })

        obj = fieldDetails.find(o => o.field === "medicare_plan_name");
        medicareDType = obj.options;
        medicareDType = [...new Set(medicareDType.map((data, index) => ({
            key: index + 1,
            text: data.display_name,
            value: data.value,
        })))];

        obj = fieldDetails.find(o => o.field === "medicare_plan_type");
        medicarePlanType = obj.options;
        medicarePlanType = [...new Set(medicarePlanType.map((data, index) => ({
            key: index + 1,
            text: data.display_name,
            value: data.value,
        })))];

        obj = fieldDetails.find(o => o.field === "special_program_type");
        specialProgramType = obj.options;
        specialProgramType = [...new Set(specialProgramType.map((data, index) => ({
            key: index + 1,
            text: data.display_name,
            value: data.value,
        })))]
        obj = fieldDetails.find(o => o.field === "formulary_name");
        formularyOptions = obj.options;
        formularyOptions = [...new Set(formularyOptions.map((data, index) => ({
            key: index + 1,
            text: data.display_name,
            value: data.value,
            formulary_id: data.doc_id
        })))]

        //whenever you un-comment this logic for medicare formulary options make sure you add formulary id as line 112
        // obj = fieldDetails.find(o => o.field === "medicare_d_formulary_name");
        // medicareFormularyOptions = obj.options;
        // medicareFormularyOptions = [...new Set(medicareFormularyOptions.map((data, index) => ({
        //     key: index + 1,
        //     text: data.display_name,
        //     value: data.value,
        //     pbp: data.pbp,
        //     contract_id: data.contract_id
        // })))]
        const planDesignData = {
            ...state.planDesignData,
        }
        const updatedFormularyId = obj?.options?.find(x => x.display_name === planDesignData?.formulary_name)?.doc_id
        const planInfo = []
        if (state?.planDesignData?.plan_code && planDesignData.plan_code?.length &&
            planDesignData?.plan_code.length === planDesignData.display_plan_name.length) {
            planDesignData.plan_code.map((data, index) => {
                planInfo.push({
                    plan_code: data,
                    display_plan_name: planDesignData.display_plan_name[index],
                    group_type: (planDesignData?.group_type && planDesignData?.group_type[index]) || ''
                })
            })
        } else {
            planInfo.push({
                plan_code: '',
                display_plan_name: '',
                group_type: ''
            })
        }
        form.planInfo = planInfo
        const formData = { ...form, ...planDesignData }

        if (updatedFormularyId && formData?.formulary_id) formData.formulary_id = updatedFormularyId
        this.setState({ form, planYearOptions, lobOptions, medicarePlanType, medicareDType, specialProgramType, formularyOptions, medicareFormularyOptions, form: formData, formularyData: (planDesignData?.formulary?.length && planDesignData?.formulary) || formularyData })

    }

    componentDidUpdate(prevProps) {
        const { form, formularyOptions, formularyData } = this.state
        const { state } = this.props
        if (prevProps.state.planDesignData !== state.planDesignData) {
            const planDesignData = {
                ...state.planDesignData,
                ...state.planDesignDetailData,
            }
            const newForm = { ...form, ...planDesignData, ...state.planDetailsFilters };
            const obj = formularyOptions.find(o => o.value === newForm.formulary_name)
            // const medicareFormularyObj = medicareFormularyOptions.find(o => o.value === newForm.medicare_d_formulary_name)
            // const medicareobj = medicareDType.find(o => o.value === newForm.medicare_plan_id);
            const planInfo = []
            if (planDesignData.plan_code.length &&
                planDesignData.plan_code.length === planDesignData.display_plan_name.length) {
                planDesignData.plan_code.map((data, index) => {
                    planInfo.push({
                        plan_code: data,
                        display_plan_name: planDesignData.display_plan_name[index],
                        group_type: (planDesignData?.group_type && planDesignData?.group_type[index]) || ''
                    })
                })
            } else {
                planInfo.push({
                    plan_code: '',
                    display_plan_name: '',
                    group_type: ''
                })
            }
            newForm.planInfo = planInfo
            newForm.formulary_name = obj?.text
            // newForm.medicare_d_formulary_name = medicareFormularyObj?.text
            newForm.medicare_plan_name = ''
            newForm.effective_start_date = convertStrToDateObj(newForm.effective_start_date)
            newForm.effective_end_date = convertStrToDateObj(newForm.effective_end_date)
            newForm.carrier = newForm.carrier || ''
            newForm.account = newForm.account || ''
            newForm.group = newForm.group || ''
            this.setState({ form: { ...newForm }, formularyData: (formularyData || planDesignData?.formulary?.length && planDesignData?.formulary) })
        }
    }
    _updateformFields = (el, dropdown) => {
        const { copyFormData, fieldDetails, actions } = this.props
        const { form } = this.state
        const { name, value } = dropdown || el.currentTarget
        const newForm = { ...form }
        newForm[name] = value
        if (name === 'lob') {
            if (value.includes("Medicare")) {
                newForm["isMedicare"] = true
            }
            else {
                newForm["isMedicare"] = false
            }
            actions.setPlanLOB({ data: value })
        }
        if (name === 'formulary_name') {
            const formularyFieldOptions = fieldDetails?.find(x => x.field === 'formulary_name')?.options
            const formulary_id = formularyFieldOptions.find(x => x.display_name === el.nativeEvent.target.textContent)?.doc_id
            newForm['formulary_id'] = formulary_id
        }
        // if (name === 'medicare_d_formulary_name') {
        //     const medicareFormularyFieldOptions = fieldDetails?.find(x => x.field === 'medicare_d_formulary_name')?.options
        //     const formulary_id = medicareFormularyFieldOptions.find(x => x.display_name === el.nativeEvent.target.textContent)?.doc_id
        //     newForm['formulary_id'] = formulary_id
        // }
        if (name === 'medicare_plan_id') {
            newForm['medicare_plan_name'] = el.nativeEvent.target.textContent
        }
        if (newForm.effective_start_date) {
            newForm.effective_start_date = convertDateToStartTimeObj(newForm.effective_start_date)
        }
        if (newForm.effective_end_date) {
            newForm.effective_end_date = convertDateToEndTimeObj(newForm.effective_end_date)
        }
        actions.setPlanDesignDetailData({ data: { ...newForm } })
        if (name === 'plan_name') {
            actions.setPlanName({ data: value })
        }
        copyFormData({ ...newForm })
        this.setState({ form: newForm })
    }

    _addMoreDisplayName = () => {
        const { form } = this.state
        form.planInfo = [...form.planInfo, {
            plan_code: '',
            display_plan_name: '',
            group_type: ''
        }]
        this.setState({ form })
    }

    _handleDisplayInfoChange = (e, index) => {
        const { form } = this.state
        const { copyFormData, actions } = this.props
        const { name, value } = e.target;
        const newForm = { ...form }
        newForm.planInfo[index][name] = value
        if (newForm.effective_start_date) {
            newForm.effective_start_date = convertDateToStartTimeObj(newForm.effective_start_date)
        }
        if (newForm.effective_end_date) {
            newForm.effective_end_date = convertDateToEndTimeObj(newForm.effective_end_date)
        }
        actions.setPlanDesignDetailData({ data: { ...newForm } })
        copyFormData({ ...newForm })
        this.setState({ form: newForm })
    };

    _deleteRow = (index) => {
        const { form } = this.state
        const newForm = { ...form }
        newForm.planInfo.splice(index, 1)
        this.setState({ form: newForm })
    }

    // renderPlanInfo = () => {
    //     const { form } = this.state
    //     return form.planInfo.map((data, index) => {
    //         return (
    //             <div className="fields-container">
    //                 <FliptInput
    //                     placeholder="Plan Code"
    //                     label="Plan Code"
    //                     name='plan_code'
    //                     value={data.plan_code}
    //                     onChange={(e) => this._handleDisplayInfoChange(e, index)}
    //                     stylized
    //                 />
    //                 <FliptInput
    //                     placeholder="Plan Display Name"
    //                     label="Plan Display Name"
    //                     name='display_plan_name'
    //                     value={data.display_plan_name}
    //                     onChange={(e) => this._handleDisplayInfoChange(e, index)}
    //                     stylized
    //                 />
    //                 <FliptInput
    //                     placeholder="Group Type"
    //                     label="Group Type"
    //                     name='group_type'
    //                     value={data.group_type}
    //                     onChange={(e) => this._handleDisplayInfoChange(e, index)}
    //                     stylized
    //                 />
    //                 <Icon name='delete' size='small' onClick={() => this._deleteRow(index)} />
    //             </div>

    //         )
    //     });
    // }
    medContractIdValidation = (value) => {
        const regex = /^[HRSEX][0-9]{4}$/
        const isValid = regex.test(value)
        if (!isValid) this.setState({ medContractIdError: resolveMessage("val_limited_medicare_contract_id").message })
        else this.setState({ medContractIdError: null })
        return isValid
    }
    medPbpValidation = (value) => {
        const isValid = value.length === 3
        if (!isValid) this.setState({ medPbpError: resolveMessage("val_limited_medicare_pbp_value").message })
        else this.setState({ medPbpError: null })
        return isValid
    }
    _showTransitionalPortal = (header, message) => {
        const { props: { actions } } = this;
        const transitionalPortal = {
            header: header,
            copy: message,
        }
        actions.displayTransitionalPortal(transitionalPortal)
    }

    handleChange = (e, dropdown, rowIndex) => {
        const { copyFormData, actions } = this.props
        const { formularyData, minMaxOptions, form, formularyOptions, medicareFormularyOptions } = this.state
        const newForm = { ...form }
        const newGridData = [...formularyData]
        const { name, value } = dropdown || e.currentTarget
        if (name === 'formulary_name') {
            if (formularyOptions?.length) {
                newGridData[rowIndex].formulary_id = formularyOptions.filter(function (el) {
                    return el.value == value
                })[0]?.formulary_id;
            }
            if (medicareFormularyOptions?.length) {
                newGridData[rowIndex].formulary_id = medicareFormularyOptions.filter(function (el) {
                    return el.value == value
                })[0]?.formulary_id;
            }
        }
        if (name === 'effective_begin_date') minMaxOptions[rowIndex]['effective_end_date']['minDate'] = moment(value).add(1, 'day').toDate()
        newGridData[rowIndex][name] = value
        newForm.formulary = newGridData
        copyFormData({ ...newForm })
        actions.setPlanDesignDetailData({ data: { ...newForm, formulary: newGridData } })
        this.setState({ formularyData: newGridData, minMaxOptions })
    }

    addGridRow = (e) => {
        const { formularyData, minMaxOptions } = this.state
        const newGridData = [...formularyData]
        const index = newGridData?.length ? newGridData.length - 1 : 0
        if (newGridData?.length && (!newGridData[index]?.formulary_name || !newGridData[index]?.effective_begin_date || !newGridData[index]?.effective_end_date)) {
            this._showTransitionalPortal(resolveMessage("val_missing_formulary_dates"))
            return
        }
        newGridData.push({
            formulary_id: '', formulary_name: '',
            effective_begin_date: newGridData?.length ? moment(newGridData[index].effective_end_date).add(1, 'day').toDate() : '',
            effective_end_date: ''
        })
        minMaxOptions.push({
            effective_begin_date: { minDate: moment(newGridData[index].effective_end_date).add(1, 'day').toDate(), maxDate: '' },
            effective_end_date: { minDate: moment(newGridData[index].effective_end_date).add(2, 'day').toDate(), maxDate: '' }
        })
        this.setState({ formularyData: newGridData, minMaxOptions })
    }

    delGridRow = (rowIndex) => {
        const { copyFormData } = this.props
        const { formularyData, form } = this.state
        const newForm = { ...form }
        const newGridData = [...formularyData]
        newGridData.splice(rowIndex, 1)
        newForm.formulary = newGridData
        copyFormData({ ...newForm })
        this.setState({ formularyData: newGridData })
    }

    isPlanNameEditable = () => {
        const { plan_version = '', status = '' } = this.state.form

        if (!plan_version || !status) return true

        if (parseInt(plan_version) == 1 && status.toUpperCase() === 'DRAFT') return true

        return false
    }

    render() {
        const { form, planYearOptions, lobOptions, medicareDType, formularyData, minMaxOptions,
            medicarePlanType, formularyOptions, medicareFormularyOptions, specialProgramType, medContractIdError, medPbpError } = this.state
        const medicareFormularyOptionsConst = medicareFormularyOptions.filter(x => x.contract_id === form.medicare_contract_id && x.pbp === form.medicare_pbp)
        const { state, context } = this.props
        const { groupOptions } = context
        const formularyHeader = ['action', 'formulary_name', 'effective_begin_date', 'effective_end_date']
        const cellRendererParams = {
            action: {
                cellRenderer: DeleteRowRenderer,
                state: {
                    onClick: this.delGridRow,
                },
                width: 95,
            },
            effective_begin_date: {
                valueFormatter: (params) => {
                    if (!params.value) return ''
                    return moment(params.value)?.format('YYYY-MM-DD') || params.value
                },
            },
            effective_end_date: {
                valueFormatter: (params) => {
                    if (!params.value) return ''
                    return moment(params.value)?.format('YYYY-MM-DD') || params.value
                },
            },
        }

        const cellEditorParams = {
            formulary_name: {
                cellEditor: DropdownEditor,
                onChange: this.handleChange,
            },
            effective_begin_date: {
                cellEditor: DatePickerEditor,
                onChange: this.handleChange,
            },
            effective_end_date: {
                cellEditor: DatePickerEditor,
                onChange: this.handleChange,
            }
        }
        rowCellInfo.formulary_name.options = ((form?.isMedicare || this.props.state?.planDesignData?.lob?.includes("Medicare"))
            && medicareFormularyOptionsConst.length > 0) ? medicareFormularyOptionsConst : formularyOptions
        return (
            <div id="PlanDesignDetails">
                <div className="section-contents">
                    <div className="fields-container">
                        <FliptInput
                            placeholder="Plan Name"
                            label="Plan Name"
                            name="plan_name"
                            disabled={!this.isPlanNameEditable()}
                            value={form.plan_name}
                            onChange={this._updateformFields}
                            stylized
                        />
                        <FliptInput
                            label="Version"
                            value={form?.plan_version ?? 1.0}
                            stylized
                        />
                        <FliptDatePicker label="Effective Start Date" placeholder="Effective Start Date"
                            name="effective_start_date" onChange={this._updateformFields}
                            value={convertStrToDateObj(form.effective_start_date)} stylized />

                        <FliptDatePicker label="Effective End Date" placeholder="Effective End Date"
                            name="effective_end_date" onChange={this._updateformFields}
                            value={convertStrToDateObj(form.effective_end_date)} stylized />
                    </div>
                    <div className="fields-container">
                        <FliptDropdown value={form.lob} options={lobOptions}
                            name="lob" label="Line of Business" stylized
                            onChange={this._updateformFields} />
                        {form.lob === 'Commercial-Exchange' || form.lob === 'Commercial' ?
                            <FliptInput value={form.grace_period}
                                name="grace_period" label="Grace Period" stylized
                                onChange={this._updateformFields} /> : null}
                        <FliptDropdown value={form.secondary_processing_group} options={groupOptions}
                            name="secondary_processing_group" label="Secondary Group" stylized
                            onChange={this._updateformFields} />
                    </div>
                </div>

                <div className="section-contents"
                    style={{ 'flex-direction': 'row', 'display': 'flex' }}>
                    {/* <div className="display-info-container">
                        {this.renderPlanInfo()}
                    </div> */}
                    {/* <div className="fields">
                        <FliptButton name="ADD PLAN NAME" className="primary" onClick={this._addMoreDisplayName} />
                    </div> */}
                </div>
                {form.lob && form.lob.indexOf('Medicare') >= 0 ? <div className='row-container'>
                    <FliptDropdown value={form.medicare_plan_id} options={medicareDType}
                        name="medicare_plan_id" label="Medicare D Plan" stylized
                        onChange={this._updateformFields} clearable />
                    <FliptDropdown value={form.medicare_plan_type} options={medicarePlanType}
                        name="medicare_plan_type" label="Type of Medicare Plan" stylized
                        onChange={this._updateformFields} clearable />
                    <FliptDropdown value={form.special_program_type} options={specialProgramType}
                        name="special_program_type" label="Special Program Type" stylized
                        onChange={this._updateformFields} clearable />
                    <FliptInput
                        placeholder="Medicare Contract ID"
                        label="Medicare Contract ID"
                        customValidation={this.medContractIdValidation}
                        name="medicare_contract_id"
                        error={medContractIdError}
                        value={form.medicare_contract_id}
                        onChange={this._updateformFields}
                        stylized
                    />
                </div> : null}

                {form.lob && form.lob.indexOf('Medicare') >= 0
                    && <div className="section-medicare-contents" >
                        <div className="fields-container">
                            <FliptInput
                                placeholder="Medicare PBP"
                                label="Medicare PBP"
                                name="medicare_pbp"
                                error={medPbpError}
                                customValidation={this.medPbpValidation}
                                value={form.medicare_pbp}
                                onChange={this._updateformFields}
                                stylized
                            />
                            <Link to={{ pathname: '/create-medicare-plan' }}>
                                <div className="create-medicare-plan" aria-hidden="true">
                                    Create New Medicare Plan
                                </div>
                            </Link>
                        </div>
                    </div>}

                {form.lob && form.lob.indexOf('Medicaid') >= 0
                    && <div className="section-contents" >
                        <div className="fields-container">
                            <FliptInput
                                placeholder="Medicaid Plan"
                                label="Medicaid Plan"
                                name="medicaid_plan"
                                value={form.medicaid_plan}
                                onChange={this._updateformFields}
                                stylized
                            />
                        </div>
                    </div>}

                <div className="grid-contents">
                    <FliptGrid
                        data={formularyData || []}
                        headers={formularyHeader}
                        cellEditorParams={cellEditorParams}
                        rowCellInfo={rowCellInfo}
                        cellRendererParams={cellRendererParams}
                        dropdownOptions={minMaxOptions}
                    />
                </div>
                <div className="fields">
                    <FliptButton name="ADD +" className="primary" onClick={this.addGridRow} />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    state: {
        planDesignData: state.planManagement.planDesignData,
        planDetailsFilters: state.planManagement.planDetailsFilters,
        planDesignDetailData: state.planManagement.planDesignDetailData,
    },
})

const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...PlanManagementActions,
        ...AppActions,
    }

    return {
        actions: bindActionCreators(allActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlanDesignDetail)
