import { all, fork } from 'redux-saga/effects'

//import sharedCreationSagas from './api/shared'
import accountManagementSagas from './api/accountManagement'
import appSagas from './app'
import auditManagementSagas from './api/auditManagement'
import autoSuggest from './api/autoSuggest'
import bulkReprocessingRestackingSagas from './api/bulkReprocessingRestacking'
import claimErrorLookupSagas from './api/claimErrorManagement'
import claimHistorySagas from './api/claimHistory'
import claimLookupSagas from './api/claimLookupManagement'
import claimsProcessingAndReprocessing from './api/claimsProcessingAndReprocessing'
import clientConfigurationSagas from './api/clientConfiguration'
import clientImplementationSagas from './api/clientImplementation'
import clinicalCreationSagas from './api/clinicalCreationManagement'
import cmsFormularyBuilderSagas from './api/cmsFormularyBuilder'
import companyManagementSagas from './companyManagement'
import contractManagementSagas from './api/contractManagement'
import drugLookupSagas from './api/drugLookup'
import drugManagement from './api/drugManagement'
import drugMappingLookupSagas from './api/drugMappingLookup'
import drugPriceLookupSagas from './api/drugPriceLookup'
import durSagas from './api/drugUtilizationReview'
import emergencyOverride from './api/emergencyOverride'
import fileConfigurationSagas from './api/fileConfiguration'
import formularyCreationSagas from './api/formularyCreationManagement'
import formularyExportConfiguration from './api/formularyExportConfiguration'
import formularyExportLookupSagas from './api/formularyExportLookup'
import formularyLookupSagas from './api/formularyLookup'
import getPricing from './api/getPricing'
import globalEdits from './api/globalEdits'
import groupManagementSagas from './api/groupManagement'
import integrationManagementSagas from './api/integrationManagement'
import manualClaimAdjustmentSagas from './api/manualClaimAdjustment'
import manualClaimEntrySagas from './api/manualClaimEntry'
import medicareSetupSagas from './api/medicareSetup'
import medispanLookup from './api/medispanLookup'
import memberLookupSagas from './api/memberLookup'
import navigationSagas from './navigation'
import networkCreationSagas from './api/networkCreationManagement'
import opioidManagementSagas from './api/opioidManagement'
import paoverridereportLookupSagas from './api/paoverridereportLookup'
import pharmacyLookupSagas from './api/pharmacyLookup'
import planManagementSagas from './api/planManagement'
import posRebate from './api/posRebate'
import prescriberLookupSagas from './api/prescriberLookup'
import prescriberManagementSagas from './api/prescriberManagement'
import priorAuthorizationSagas from './api/priorAuthorizationManagement'
import programListLookupSagas from './api/programListManagement'
import qualifiersSagas from './api/qualifiers'
import quantityLimitLookupSagas from './api/quantityLimitManagement'
import questionnaireBuilderSagas from './api/questionnaireBuilder'
import queueConfigSagas from './api/queueConfig'
import rebateManagementSagas from './api/rebateManagement'
import rolesSagas from './roleManagement'
import rpmSagas from './rpm'
import rxreportLookupSagas from './api/rxreportLookup'
import ssoLoginSagas from './ssoLogin'
import sureScriptConfigSagas from './api/sureScriptConfig'
import teamManagementSagas from './teamManagement'
import testClaim from './api/testClaim'
import timerConfigSagas from './api/timerConfig'
import userManagementSagas from './userManagement'
import userSagas from './user'
import userServiceBrowserSagas from './api/userServiceBrowser'

const forkList = (sagasList) => sagasList.map((saga) => fork(saga))

export default function* root() {
  yield all([
    ...forkList(accountManagementSagas),
    ...forkList(appSagas),
    ...forkList(auditManagementSagas),
    ...forkList(autoSuggest),
    ...forkList(bulkReprocessingRestackingSagas),
    ...forkList(claimErrorLookupSagas),
    ...forkList(claimHistorySagas),
    ...forkList(claimLookupSagas),
    ...forkList(claimsProcessingAndReprocessing),
    ...forkList(clientConfigurationSagas),
    ...forkList(clientImplementationSagas),
    ...forkList(clinicalCreationSagas),
    ...forkList(cmsFormularyBuilderSagas),
    ...forkList(companyManagementSagas),
    ...forkList(contractManagementSagas),
    ...forkList(drugLookupSagas),
    ...forkList(drugManagement),
    ...forkList(drugMappingLookupSagas),
    ...forkList(drugPriceLookupSagas),
    ...forkList(durSagas),
    ...forkList(emergencyOverride),
    ...forkList(fileConfigurationSagas),
    ...forkList(formularyCreationSagas),
    ...forkList(formularyExportConfiguration),
    ...forkList(formularyExportLookupSagas),
    ...forkList(formularyLookupSagas),
    ...forkList(getPricing),
    ...forkList(globalEdits),
    ...forkList(groupManagementSagas),
    ...forkList(integrationManagementSagas),
    ...forkList(manualClaimAdjustmentSagas),
    ...forkList(manualClaimEntrySagas),
    ...forkList(medicareSetupSagas),
    ...forkList(medispanLookup),
    ...forkList(memberLookupSagas),
    ...forkList(navigationSagas),
    ...forkList(networkCreationSagas),
    ...forkList(opioidManagementSagas),
    ...forkList(paoverridereportLookupSagas),
    ...forkList(pharmacyLookupSagas),
    ...forkList(planManagementSagas),
    ...forkList(posRebate),
    ...forkList(prescriberLookupSagas),
    ...forkList(prescriberManagementSagas),
    ...forkList(priorAuthorizationSagas),
    ...forkList(programListLookupSagas),
    ...forkList(qualifiersSagas),
    ...forkList(quantityLimitLookupSagas),
    ...forkList(questionnaireBuilderSagas),
    ...forkList(queueConfigSagas),
    ...forkList(rebateManagementSagas),
    ...forkList(rolesSagas),
    ...forkList(rpmSagas),
    ...forkList(rxreportLookupSagas),
    ...forkList(ssoLoginSagas),
    ...forkList(sureScriptConfigSagas),
    ...forkList(teamManagementSagas),
    ...forkList(testClaim),
    ...forkList(timerConfigSagas),
    ...forkList(userManagementSagas),
    ...forkList(userSagas),
    ...forkList(userServiceBrowserSagas),
    //...forkList(sharedCreationSagas),
  ])
}
