import React, { useEffect, useState } from "react";
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Icon } from "semantic-ui-react";
import { Creators as AppActions } from '../../../redux/reducers/app'
import { Creators as QualifierActions } from '../../../redux/reducers/api/qualifiers'
import FliptDropdown from "../../v2/fliptDropdown";
import FliptButton from "../../v2/fliptButton";
import QualifierLookup from "../QualifierLookup";
import '../styles.scss';

const QualifierValue = (props) => {
	const { getFieldRecord, value, actions } = props;

	let initialRecord = getFieldRecord(props.models, props.field)
	const [record, setRecord] = useState(initialRecord)

	useEffect(() => {
		let newRecord = getFieldRecord(props.models, props.field)
		if (newRecord && newRecord.lookup) {
			actions.getQualifierLookup({ lookup_id: newRecord.lookup, qualifier: props.field })
		}
		setRecord(newRecord)
	}, [props.models, props.field])

	const isDateRecord = (record?.format === "date" || record?.format === "datetime")

	const handleLookupSubmit = (paramValue) => {
		if (record.lookup && record?.type === "string" && Array.isArray(paramValue)) {
			props.updateRow('value', props.rowIndex, { value: paramValue })
			return;
		}
		const newValue = [...value, ...paramValue]
		props.updateRow('value', props.rowIndex, { value: newValue })
	}

	const openQualifierLookup = () => {
		actions.setModalComponent({
			modalProperties: {
				size: 'large',
			},
			contents: <QualifierLookup
				lookup_id={record.lookup}
				handleLookupSubmit={handleLookupSubmit}
				selectedQualifier={props.field} />,
		})
		actions.toggleModal()
	}

	if (record?.type == "boolean") {
		return (<input className="criteria-value criteria_check" type="checkbox" value={value}
			onChange={(e) => {
				props.updateRow('value', props.rowIndex, { value: e.target?.value });
			}} />);
	}

	if (record?.type === "string") {
		const isLookup = !!record.lookup
		const isList = !!record.list
		const modelData = props.state.qualifierLookupModel[props.field] || {}
		let optionsList = []
		if (isList) {
			optionsList = record.list
		} else if (isLookup && modelData.list) {
			optionsList = modelData.list
		}

		if (optionsList.length) {
			const options = optionsList.map((option, index) => ({ key: index, value: option.value, text: option.label }))
			const isMultiSelect = ["in", "not in"].includes(props.operator)
			return <FliptDropdown
				defaultValue={props.value}
				multiple={isMultiSelect}
				clearable={true}
				search
				onChange={(el, dropdown) => {
					const { value } = dropdown
					props.updateRow('value', props.rowIndex, { value });
				}}
				options={options}
			/>
		}

		if (isLookup && !modelData.list) {
			let options = []
			if (props.value && Array.isArray(props.value)) {
				options = props.value.map((val, index) => ({ key: index, value: val, text: val }))
			}
			return (
				<section className="criteria-value-section">
					<FliptDropdown
						className="criteria-value-search-dropdown"
						defaultValue={props.value}
						multiple={!!props.value}
						clearable={true}
						onChange={(el, data) => {
							const { value } = data
							props.updateRow('value', props.rowIndex, { value });
						}}
						options={options}
						value={props.value}
					/>
					{isLookup &&
						<FliptButton
							name="Search"
							className="criteria-value-search-button"
							onClick={openQualifierLookup}
						>
							<Icon name="search" size="" className="" />
						</FliptButton>}
				</section>
			)
		}
	}
	return (
		<input title={`Enter ${record?.type}`} value={value} className="criteria-value-input"
			onChange={(e) => {
				let valueToSet = e.target?.value;
				if (record?.type === "number") {
					valueToSet = Number(valueToSet);
				}
				props.updateRow('value', props.rowIndex, { value: valueToSet });
			}}
			type={isDateRecord ? "date" : (record?.type == "number" || record?.type == "integer") ? "number" : "text"}
		/>

	);

}

const mapStateToProps = (state) => {
	return {
		state: {
			qualifierLookupModel: state.qualifiers.qualifierLookupModel,
		},
	}
}


const mapDispatchToProps = (dispatch) => {
	const allActions = {
		...AppActions,
		...QualifierActions
	}

	return {
		actions: bindActionCreators(allActions, dispatch),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(QualifierValue);
