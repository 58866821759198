import Immutable from 'seamless-immutable'
import { createActions, createReducer } from 'reduxsauce'

export const INITIAL_STATE = Immutable({
  auth0Client: null,
	auth0User: null,
})

export const setAuth0ClientReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  auth0Client: payload,
})

export const { Types, Creators } = createActions({
	setAuth0Client: ['payload'],
	auth0Login: ['payload'],
	auth0ConfigureClient: ['payload'],
})

const HANDLERS = {
	[Types.SET_AUTH0_CLIENT]: setAuth0ClientReducer,
}

export default createReducer(INITIAL_STATE, HANDLERS)
