import React, { PureComponent } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { Creators as AppActions } from '../../../redux/reducers/app'

class Rebates extends PureComponent {
  render() {
    return (
      <div id="homePage">
        <div id="cardMenuContainer">
          <Link to="/rebate-contract-management">
            <div className="menu-item">
              <img src="/i/rebate-contract.svg" alt="Rebate Contract Management" className="svg" />
              <div className="title svg">Rebate Contract Management</div>
              <div className="description" />
            </div>
          </Link>
          <Link to="/rebate-remittance">
            <div className="menu-item">
              <img src="/i/rebate-remittance.svg" alt="Rebate Remittance" className="svg" />
              <div className="title svg">Rebate Remittance</div>
              <div className="description" />
            </div>
          </Link>
          <Link to="/rebate-invoicing-billing">
            <div className="menu-item">
              <img src="/i/rebate-invoicing-billing.svg" alt="Rebate Invoicing and Billing" className="svg" />
              <div className="title svg">Rebate Invoicing and Billing</div>
              <div className="description" />
            </div>
          </Link>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  state: {
    app: state.app,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Rebates)
