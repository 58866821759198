import FliptButton from "../../../../../components/form/fliptButton";
import FliptLabel from "../../../../../components/form/fliptLabel";
import FliptInput from "../../../../../components/form/fliptInput";
import FliptRadio from "../../../../../components/form/fliptRadio";
import Info from "../Info";

export const INBOUND_FILE_DIRECTION = "inbound";

function renderLabel(component, props) {
    if (!component.label) return null;
    return (
        <div className="field-label">
            <span>{component.label}</span>
            {component.info ? (
                <Info
                    content={component.info}
                    position="top right"
                />
            ) : null}
        </div>
    )
}

export function renderDynamicInput(component, props) {
    switch (component.type) {
        case "text":
            return (   
              <>
               { props?.num == 1  ? 
              <> 
               <div className={props.className} key={component.name}>
                {renderLabel(component, props)}
              </div> 
              <div className={props.className} key={component.name}>
                <FliptInput
                    label={component.label}
                    name={component.name}
                    className={'field-input'}
                    value={props[component.name] || component.value}
                    onChange={props.onChange}
                    placeholder={component.placeholder}
                    required={component.required}
                    validation={component.validation}
                    disabled={component.disabled}
                    stylized={false}
                />
              </div>  
              </> : 
                <div className={props.className} key={component.name}>
                {renderLabel(component, props)}
                <FliptInput
                    label={component.label}
                    name={component.name}
                    className={'field-input'}
                    value={props[component.name] || component.value}
                    onChange={props.onChange}
                    placeholder={component.placeholder}
                    required={component.required}
                    validation={component.validation}
                    disabled={component.disabled}
                    stylized={false}
                />
            </div>  
              }
              
              </>
            );
        case "radio":
            return (
              <>
                { props?.num == 1  ? 
                 <>  <div className={props.className} key={component.name}>
                 {renderLabel(component, props)}

               </div>
               <div className={props.className} key={component.name}>
                    <div className="field-label-radio" >
                        {component.options.map((option, postion) => {
                            return (
                                <div className="divide-half-radio" key={component.name + option.value + postion}>
                                    <FliptRadio
                                        className="radio-input"
                                        toggle={false}
                                        name={component.name}
                                        value={option.value}
                                        onChange={props.onChange}
                                        checked={props[component.name] === option.value}
                                        disabled={component.disabled}
                                        stylized={false}
                                    />
                                    <FliptLabel
                                        className="radio-label"
                                        description={option.label}
                                        label={option.value}
                                    />
                                </div>
                            )
                        })}
                    </div>
                </div>
                </> :
                <div className={props.className} key={component.name}>
                    {renderLabel(component, props)}
                    <div className="field-label-radio" >
                        {component.options.map((option, postion) => {
                            return (
                                <div className="divide-half-radio" key={component.name + option.value + postion}>
                                    <FliptRadio
                                        className="radio-input"
                                        toggle={false}
                                        name={component.name}
                                        value={option.value}
                                        onChange={props.onChange}
                                        checked={props[component.name] === option.value}
                                        disabled={component.disabled}
                                        stylized={false}
                                    />
                                    <FliptLabel
                                        className="radio-label"
                                        description={option.label}
                                        label={option.value}
                                    />
                                </div>
                            )
                        })}
                    </div>
                </div>
            }
              </>
            );
    
        default:
            return null
    }
}


 export  const sampleData = [
    {
      page: "client_information",
      fields: [
        {
          "section": "",
          "type": "text",
          "label": "Test Input",
          "name": "test_field",
          "value": "",
          "info": "info test field",
          "validation": "text"
        },
        {
          "section": "",
          "type": "text",
          "label": "Test Input 1",
          "name": "test_field",
          "value": "",
          "info": "info test field",
          "validation": "text"
        },
        {
          "type": "radio",
          "label": "Is Dependent File Seprate",
          "name": "is_dep_file_seprate",
          "options": [
            {
              "value": "Yes",
              "text": "Yes",
            },
            {
              "value": "No",
              "text": "No",
            }
          ],
          "value": "",
          "info": "info is_dep_file_seprate",
          "validation": "text"
        },
        {
          "type": "radio",
          "label": "Is Medicare Part D",
          "name": "is_medicare_part_d",
          "options": [
            {
              "value": "Yes",
              "label": "Yes",
            },
            {
              "value": "No",
              "label": "No",
            }
          ],
          "value": "",
          "info": "info is_medicare_part_d",
          "validation": "text"
        }
      ]
    },
    {
        page: "file_information",
        fields: [
          {
            "section": "",
            "type": "text",
            "label": "Test Input",
            "name": "test_field",
            "value": "",
            "info": "info test field",
            "validation": "text"
          },

          {
            "type": "radio",
            "label": "Is Dependent File Seprate",
            "name": "is_dep_file_seprate",
            "options": [
              {
                "value": "Yes",
                "text": "Yes",
              },
              {
                "value": "No",
                "text": "No",
              }
            ],
            "value": "",
            "info": "info is_dep_file_seprate",
            "validation": "text"
          },
          
 
        ]
      },
      {
        page: "email_and_sftp",
        fields: [
          {
            "section": "",
            "type": "text",
            "label": "Test Input",
            "name": "test_field",
            "value": "",
            "info": "info test field",
            "validation": "text"
          },

          {
            "type": "radio",
            "label": "Is Dependent File Seprate",
            "name": "is_dep_file_seprate",
            "options": [
              {
                "value": "Yes",
                "text": "Yes",
              },
              {
                "value": "No",
                "text": "No",
              }
            ],
            "value": "",
            "info": "info is_dep_file_seprate",
            "validation": "text"
          },
          {
            "type": "radio",
            "label": "Is Medicare Part D",
            "name": "is_medicare_part_d",
            "options": [
              {
                "value": "Yes",
                "label": "Yes",
              },
              {
                "value": "No",
                "label": "No",
              }
            ],
            "value": "",
            "info": "info is_medicare_part_d",
            "validation": "text"
          }
          
 
        ]
      },
      {
        page: "threshold_information",
        fields: [
          {
            "section": "",
            "type": "text",
            "label": "Test Input",
            "name": "test_field",
            "value": "",
            "info": "info test field",
            "validation": "text"
          },

          {
            "type": "radio",
            "label": "Is Dependent File Seprate",
            "name": "is_dep_file_seprate",
            "options": [
              {
                "value": "Yes",
                "text": "Yes",
              },
              {
                "value": "No",
                "text": "No",
              }
            ],
            "value": "",
            "info": "info is_dep_file_seprate",
            "validation": "text"
          },
          
 
        ]
      },
      {
        page: "response_parameter",
        fields: [
          {
            "section": "",
            "type": "text",
            "label": "Test Input",
            "name": "test_field",
            "value": "",
            "info": "info test field",
            "validation": "text"
          },

          {
            "type": "radio",
            "label": "Is Dependent File Seprate",
            "name": "is_dep_file_seprate",
            "options": [
              {
                "value": "Yes",
                "text": "Yes",
              },
              {
                "value": "No",
                "text": "No",
              }
            ],
            "value": "",
            "info": "info is_dep_file_seprate",
            "validation": "text"
          },
          
 
        ]
      },
      {
        page: "import_format",
        fields: [
          {
            "section": "",
            "type": "text",
            "label": "Test Input",
            "name": "test_field",
            "value": "",
            "info": "info test field",
            "validation": "text"
          },

          {
            "type": "radio",
            "label": "Is Dependent File Seprate",
            "name": "is_dep_file_seprate",
            "options": [
              {
                "value": "Yes",
                "text": "Yes",
              },
              {
                "value": "No",
                "text": "No",
              }
            ],
            "value": "",
            "info": "info is_dep_file_seprate",
            "validation": "text"
          },
          
 
        ]
      }

  ]