import React from 'react'
import SemanticDatepicker from 'react-semantic-ui-datepickers'
import moment from 'moment'

import FliptLabel from './fliptLabel'
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";

export default (props) => {
  const {
    description,
    label,
    stylized,
  } = props
  const stylizedField = () => (
    <div className="stylized">
      <div className="flipt-label">
        <FliptLabel
          description={description}
          label={label}
        />
      </div>
      {displayField(props)}
    </div>
  )

  if (stylized) return stylizedField()

  return displayField(props)
}

function displayField(props) {
  const {
    clearable = true,
    format,
    readOnly,
    value,
  } = props

  return readOnly ? (<input type='text' autocomplete='off'  value={moment(value).format('MM-DD-YYYY')}/>) : (<SemanticDatepicker {...props} autoComplete='off' clearable={clearable} format={format || 'MM-DD-YYYY'} readOnly={readOnly} />)
}


export const FliptCustomDatePicker = (props) => {
  const {
    description,
    label,
    stylized,
    ...otherProps
  } = props
  const stylizedField = () => (
    <div className="stylized">
      { label && (<div className="flipt-label">
        <FliptLabel
          description={description}
          label={label}
        />
      </div>)}
      {displayField(otherProps)}
    </div>
  )

  const displayField = (props) => {
    const { readOnly } = props
    return readOnly ? (<input type='text' autocomplete='off'  value={moment(value).format('MM-DD-YYYY')}/>) : (<DatePicker 
      {...props}
      autoComplete='off' readOnly={readOnly} />)
  }

  if (stylized) return stylizedField()

  return displayField(otherProps)
}
