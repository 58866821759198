import {
  select,
  takeLatest,
} from 'redux-saga/effects'

import { Types as NavigationTypes } from '../../reducers/navigation'
import { getHistory } from '../../reducers/selectors'

export default [
  navigatBackWatcher,
  navigateToWatcher,
  navigateRefreshWatcher,
]

/* WATCHERS */
function* navigateToWatcher() {
  yield takeLatest(NavigationTypes.NAVIGATE_TO, navigateToHandler)
}

function* navigatBackWatcher() {
  yield takeLatest(NavigationTypes.NAVIGATE_BACK, navigateBackHandler)
}

function* navigateRefreshWatcher() {
  yield takeLatest(NavigationTypes.NAVIGATE_REFRESH, navigateRefreshHandler)
}

/* HANDLERS */
function* navigateToHandler({ payload }) {
  try {
    if (!payload.pathname && typeof payload !== 'string') return

    const history = yield select(getHistory)
    history.push(payload)
    yield
  } catch (err) {
    console.log('navigateToHandler Error: ', err)
  }
}

function* navigateBackHandler() {
  try {
    const history = yield select(getHistory)
    history.goBack()
    yield
  } catch (err) {
    console.log('navigateBackHandler Error: ', err)
  }
}

function* navigateRefreshHandler() {
  try {
    const history = yield select(getHistory)
    history.go(0)
    yield
  } catch (err) {
    console.log('navigateBackHandler Error: ', err)
  }
}

export {
  navigateToHandler,
  navigateBackHandler,
  navigateRefreshHandler,
}
