import React, { PureComponent } from 'react'
import FliptGrid from '../../../../components/fliptGrid'
import { DropdownEditor, InputTextEditor, DatePickerEditor } from '../../../../components/fliptGrid/cellEditors'
import { Accumulators as rowCellInfo } from '../data/rowCellInfo'
import { DeleteRowRenderer } from '../../../../components/fliptGrid/cellRenderers'
import { Creators as AppActions } from '../../../../redux/reducers/app'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import AddRow from '../addRow'
import moment from 'moment'

import _ from 'lodash'
import './styles.scss'

class deductibles extends PureComponent {
  constructor(props) {
    super(props)
    const { state } = this.props
    const { accumulator_period = [{
      benefit_effective_start_date: '',
      benefit_effective_end_date: '',
      benefit_period_type: '',
      deductible_satisfaction: '',
      individual_deductible: '',
      family_deductible: '',
      oop_satisfaction: '',
      individual_oop: '',
      family_oop: '',
      deductible_oop_logic: '',
    }] } = state
    this.state = {
      accumulator_period
    }
  }

  getGridHeaders = () => [
    'benefit_effective_start_date',
    'benefit_effective_end_date',
    'benefit_period_type',
    'deductible_satisfaction',
    'individual_deductible',
    'family_deductible',
    'oop_satisfaction',
    'individual_oop',
    'family_oop',
    'deductible_oop_logic',
  ]

  renderGrid = (data) => {
    const { fieldDetails } = this.props
    const rowCellInfoCopy = rowCellInfo

    const headers = this.getGridHeaders()
    const handleChange = (e, dropdown, rowKey) => {
      this.handleChange(e, dropdown, rowKey)
    }
    fieldDetails.forEach((element) => {
      const { field } = element
      if (!(field in rowCellInfoCopy)) {
        return
      }

      rowCellInfoCopy[field].options = element.options.map((code, index) => ({
        key: index,
        text: code.display_name,
        value: code.value,
      }))
    })

    const cellRendererParams = {
      action: {
        cellRenderer: DeleteRowRenderer,
        state: {
          onClick: (index) => this.delRow(index),
        },
        width: 95,
      },
      benefit_effective_start_date: {
        valueFormatter: (params) => {
          if (!params.value) return ''
          return moment(params.value)?.format('YYYY-MM-DD') || params.value
        },
        width: '300px',
        overrideHeader: 'Period Begin'
      },
      benefit_effective_end_date: {
        valueFormatter: (params) => {
          if (!params.value) return ''
          return moment(params.value)?.format('YYYY-MM-DD') || params.value
        },
        width: '300px',
        overrideHeader: 'Period End'
      },
      benefit_period_type: {
        width: '300px',
        overrideHeader: 'Accumulation Type'
      },
      deductible_satisfaction: {
        width: '300px',
      },
      individual_deductible: {
        width: '300px',
      },
      family_deductible: {
        width: '300px',
      },
      oop_satisfaction: {
        width: '300px',
        overrideHeader: 'OOP Satisfaction',
      },
      individual_oop: {
        width: '300px',
        overrideHeader: 'Individual OOP',
      },
      family_oop: {
        width: '300px',
        overrideHeader: 'Family OOP',
      },
      deductible_oop_logic: {
        width: '300px',
        overrideHeader: 'Accumulation Logic',

      }
    }

    const cellEditorParams = {
      benefit_effective_start_date: {
        editable: true,
        cellEditor: DatePickerEditor,
        onChange: handleChange,
      },
      benefit_effective_end_date: {
        editable: true,
        cellEditor: DatePickerEditor,
        onChange: handleChange,
      },
      benefit_period_type: {
        editable: true,
        cellEditor: DropdownEditor,
        onChange: handleChange,
      },
      deductible_satisfaction: {
        editable: true,
        cellEditor: DropdownEditor,
        onChange: handleChange,
      },
      individual_deductible: {
        editable: true,
        cellEditor: InputTextEditor,
        onChange: handleChange,
        validation: 'numeric',
      },
      family_deductible: {
        editable: true,
        cellEditor: InputTextEditor,
        onChange: handleChange,
        validation: 'numeric',
      },
      oop_satisfaction: {
        editable: true,
        cellEditor: DropdownEditor,
        onChange: handleChange,
      },
      individual_oop: {
        cellEditor: InputTextEditor,
        onChange: handleChange,
        validation: 'numeric',
      },
      family_oop: {
        editable: true,
        cellEditor: InputTextEditor,
        onChange: handleChange,
        validation: 'numeric',
      },
      deductible_oop_logic: {
        editable: true,
        cellEditor: DropdownEditor,
        onChange: handleChange,
      }
    }

    return (
      <div className="grid-container">
        <FliptGrid
          data={data}
          headers={this.getGridHeaders()}
          cellRendererParams={cellRendererParams}
          cellEditorParams={cellEditorParams}
          rowCellInfo={rowCellInfoCopy}
        />
      </div>
    )
  }

  addRow = () => {
    const headers = this.getGridHeaders()
    headers.splice(0, 1) // remove action from headers
    const row = headers.reduce((obj, v) => {
      obj[v] = rowCellInfo[v]?.default || ''
      return obj
    }, {})
    const { accumulator_period } = this.state
    const newState = {
      ...this.state,
      accumulator_period: [...accumulator_period, row],
    }
    this.setState(newState)
  }

  delRow = (rowIndex) => {
    const { accumulator_period } = this.state
    const new_accumulator_period = [...accumulator_period]
    new_accumulator_period.splice(rowIndex, 1) // Delete selected row
    const newState = {
      ...this.state,
      accumulator_period: [...new_accumulator_period],
    }
    this.setState(newState)
  }

  debouncedStateUpdate = _.debounce(fn => fn(), 1000)

  handleChange = (e, dropdown, rowKey, source) => {
    const { accumulator_period } = this.state
    const new_accumulator_period = [...accumulator_period]
    const { name, value } = dropdown || e.currentTarget
    new_accumulator_period[rowKey] = { ...new_accumulator_period[rowKey], [name]: value }
    this.debouncedStateUpdate(e => this.setState({ accumulator_period: new_accumulator_period }, () => {
      this.validateAndUpdateData({ ...this.state })
    }))
  }

  validateAndUpdateData = (data) => {
    const { copyFormData } = this.props
    _.debounce(() => copyFormData({ data }), 500)()

  }

  render() {
    const { accumulator_period } = this.state

    return (
      <div id="transition">
        {this.renderGrid(accumulator_period)}
        {/* <div className="addRowButtonContainer">
          <AddRow addRow={() => this.addRow()} />
        </div> */}
        <div className="section-header"> </div>
      </div>
    )
  }

}



const mapStateToProps = (state) => ({
  state: {
    app: state.app,
    accumulator_period: state.planManagement?.accumulator_period,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
  }
  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(deductibles)