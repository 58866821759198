import React, { useState, useEffect, useCallback } from 'react'
import { Creators as AppActions } from '../../../../redux/reducers/app'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import _ from 'lodash'
import './styles.scss'
import FliptDropdown from '../../../../components/v2/fliptDropdown'

const DUR = (props) => {
    const { state: { planDetails }, copyFormData } = props
    const [form, setFormData] = useState({ dur_configuration: { dur_type: '', doc_id: '', conditions: '' }, opioid_program_configuration: [], dur_program_configuration: [] })
    const [configOptions, setConfigOption] = useState([])
    const [durConfigOptions, setDurConfigOption] = useState([])
    const [opioidConfigOption, setOpioidConfigOption] = useState([])


    const updateFormData = (el, dropdown) => {
        const { name, value } = dropdown || el.currentTarget
        const formNew = { ...form }
        if (name === 'dur_type' || name === 'doc_id' || name === 'conditions') formNew['dur_configuration'][name] = value
        else if (name === 'dur_program_configuration') {
            formNew[name] = []
            if (value.length) value.forEach((dData) => {
                let index = durConfigOptions.findIndex((data) => data?.value === dData)
                if (index >= 0) {
                    formNew[name].push({
                        doc_name: durConfigOptions[index].text,
                        doc_id: durConfigOptions[index].value,
                        effective_begin_date: '',
                        effective_end_date: ''
                    })
                }
            })
        }
        else if (name === 'opioid_program_configuration') {
            formNew[name] = []
            if (value.length) value.forEach((oData) => {
                let index = opioidConfigOption.findIndex((data) => data?.value === oData)
                if (index >= 0) {
                    formNew[name].push({
                        doc_name: opioidConfigOption[index].text,
                        doc_id: opioidConfigOption[index].value,
                        effective_begin_date: '',
                        effective_end_date: ''
                    })
                }
            })
        } else formNew[name] = value
        copyFormData(formNew)
        setFormData(formNew)
    }

    useEffect(() => {
        const { model_data, details } = planDetails
        if (details['drug_utilization_review']?.fields?.length) {
            let index = details['drug_utilization_review'].fields.findIndex((data) => data?.field === 'doc_id')
            if (index >= 0) {
                let tempConfigOptions = []
                const optionArray = details['drug_utilization_review']?.fields[index]?.options
                optionArray.forEach((element, arrIndex) => {
                    configOptions.push({
                        key: arrIndex,
                        text: element?.display_name,
                        value: element?.value
                    })
                });
                setConfigOption(tempConfigOptions)
            }
            index = details['drug_utilization_review'].fields.findIndex((data) => data?.field === "dur_program_configuration")
            if (index >= 0) {
                let tempDurConfigOptions = []
                const optionArray = details['drug_utilization_review']?.fields[index]?.options
                optionArray.forEach((element, arrIndex) => {
                    tempDurConfigOptions.push({
                        key: arrIndex,
                        text: element?.display_name,
                        value: element?.value
                    })
                });
                setDurConfigOption(tempDurConfigOptions)
            }
            index = details['drug_utilization_review'].fields.findIndex((data) => data?.field === "opioid_program_configuration")
            if (index >= 0) {
                let tempOpioidConfigOptions = []
                const optionArray = details['drug_utilization_review']?.fields[index]?.options
                optionArray.forEach((element, arrIndex) => {
                    tempOpioidConfigOptions.push({
                        key: arrIndex,
                        text: element?.display_name,
                        value: element?.value
                    })
                });
                setOpioidConfigOption(tempOpioidConfigOptions)
            }

        }
        if (!_.isEmpty(model_data?.dur_configuration))
            setFormData({ ...model_data, dur_configuration: model_data?.dur_configuration || form.dur_configuration, opioid_program_configuration: model_data?.opioid_program_configuration || [], dur_program_configuration: model_data?.dur_program_configuration || [] })
    }, [planDetails])

    const DurType = [
        {
            key: 0,
            text: 'Drug to Drug Interaction',
            value: 'Drug to Drug Interaction'
        }
    ]

    const ConditionType = [
        {
            key: 0,
            text: 'Yes',
            value: 'Yes'
        },
        {
            key: 1,
            text: 'No',
            value: 'No'
        }
    ]
    const { dur_configuration: { dur_type, doc_id, conditions }, opioid_program_configuration, dur_program_configuration } = form

    const getValueFromArray = (data) => {
        if (!data.length) return
        return data.map((innerData) => innerData?.doc_id)

    }
    return <div id="benefit-dur">
        <div >
            <FliptDropdown options={DurType} label="DUR Edit Type" value={dur_type} name="dur_type" onChange={updateFormData} stylized />
            <FliptDropdown options={configOptions} label="Configuration Name" value={doc_id} name="doc_id" onChange={updateFormData} stylized />
            <FliptDropdown options={ConditionType} label="Apply" value={conditions} name="conditions" onChange={updateFormData} stylized />
        </div>
        <div >
            <FliptDropdown multiple options={durConfigOptions} label="DUR Program Configuration" value={getValueFromArray(dur_program_configuration)} name="dur_program_configuration" onChange={updateFormData} stylized />
            <FliptDropdown multiple options={opioidConfigOption} label="Opioid Program Configuration" value={getValueFromArray(opioid_program_configuration)} name="opioid_program_configuration" onChange={updateFormData} stylized />
        </div>
    </div>
}

const mapStateToProps = (state) => ({
    state: {
        app: state.app,
        planDetails: state.planManagement?.planDetails,
    },
})

const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...AppActions,
    }
    return {
        actions: bindActionCreators(allActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DUR)