import React, { PureComponent } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import moment from 'moment-timezone'
import './styles.scss'
import BreadCrumb from '../../../../../components/breadCrumb'
import Config from '../../../../../config'
import FliptGrid from '../../../../../components/fliptGrid'
import { LinkRenderer } from '../../../../../components/fliptGrid/cellRenderers'
import FliptInput from '../../../../../components/form/fliptInput'
import SubmenuNav from '../shared/navigation'
import { Creators as ApiActions } from '../../../../../redux/reducers/api/claimLookupManagement'
import { convertStrToDate, filterRowData } from '../../../../../utils/utilities'

class ClaimRequestResponse extends PureComponent {
    componentDidMount() {
        const { actions } = this.props
        // TODO FIX THIS. SO NO MORE SETTIMEOUT
        setTimeout(() => actions.getClaimTransactionData(), Config.COMPONENT_DATA_LOAD_TIME)
    }

    _renderContent = () => {
        const { state } = this.props
        const { claim_request_and_response_section } = state.claimTransactionData
        const {
            claim_request,
            claim_response,
            insurance_segment = [],
            patient_segment = [],
        } = claim_request_and_response_section

        const requestHeaders = [
            'DUR_PPS_code_counter',
            'associated_prescription_date',
            'associated_prescription_reference_number',
            'basis_of_cost_determination',
            'benefit_stage_amount',
            'benefit_stage_count',
            'benefit_stage_qualifier',
            'bin_number',
            'cardholder_id',
            'cms_part_d_defined_qualified_facility',
            'compound_code',
            'compound_ingredients',
            'compound_type',
            'daw_code',
            'days_supply_intended_dispensed',
            'delay_reason_code',
            'dispensing_fee_submitted',
            'dispensing_status',
            'dispensing_unit_form_indicator',
            'dosgae_form_desc_code',
            'eligibility_clarification_code',
            'employer_id',
            'fill_number',
            'flat_sales_tax_amount_submitted',
            'gross_amount_due',
            'group_id',
            'home_plan',
            'incentive_amount_submitted',
            'ingredient_component_count',
            'ingredient_cost_submitted',
            'intermediary_authorization_id',
            'intermediary_authorization_type_id',
            'internal_control_number',
            'level_of_effort',
            'level_of_service',
            'medicaid_id_number',
            'medicaid_indicator',
            'medigap_id',
            'number_of_refills_authorized',
            'original_cardholder_id',
            'originally_prescribed_product_code',
            'originally_prescribed_product_id_qualifier',
            'originally_prescribed_quantity',
            'other_amount_claimed_submitted',
            'other_amount_claimed_submitted_count',
            'other_amount_claimed_submitted_qualifier',
            'other_coverage_code',
            'other_payer_amount_paid',
            'other_payer_amount_paid_count',
            'other_payer_amount_paid_qualifier',
            'other_payer_coverage_type',
            'other_payer_date',
            'other_payer_id',
            'other_payer_id_qualifier',
            'other_payer_patient_responsibility_amount',
            'other_payer_patient_responsibility_amount_count',
            'other_payer_patient_responsibility_amount_qualifier',
            'other_payer_reject_code',
            'other_payer_reject_count',
            'patient_assignment_indicator',
            'patient_id',
            'patient_id_qualifier',
            'patient_paid_amount_submitted',
            'patient_relationship_code',
            'payment_count',
            'percentage_sales_tax_amount_submitted',
            'percentage_sales_tax_basis_submitted',
            'percentage_sales_tax_rate_submitted',
            'person_code',
            'pharmacy_service_type',
            'place_of_service',
            'plan_id',
            'pregnancy_indicator',
            'prescription_origin_code',
            'prescription_reference_number',
            'prescription_reference_number_qualifier',
            'primary_care_provider_id',
            'primary_care_provider_id_qualifier',
            'primary_care_provider_last_name',
            'prior_authorization_number_submitted',
            'prior_authorization_type_code',
            'procedure_modifier_code',
            'procedure_modifier_code_count',
            'processor_control_number',
            'product_id',
            'product_id_qualifier',
            'product_selection_code',
            'professional_service_code',
            'provider_accept_assignment_indicator',
            'quantity_dispensed',
            'quantity_intended_dispensed',
            'quantity_prescribed',
            'reason_for_service_code',
            'result_of_service_code',
            'route_of_administration',
            'scheduled_prescription_id_number',
            'service_provider_id',
            'service_provider_id_qualifier',
            'software_vendor',
            'special_packaging_indicator',
            'submission_clarification_code',
            'submission_clarification_code_count',
            'tpa_member_id',
            'transaction_code',
            'transaction_count',
            'unit_of_measure',
            'usual_and_customary_charge',
            'version',
            'diagnosis_code',
            'prescriber_id',
            'prescriber_first_name',
            'prescriber_last_name',
            'prescriber_city_address',
            'prescriber_phone',
            'prescriber_state_address',
            'prescriber_street_address',
            'prescriber_zip_code',
            'prescriber_id_qualifier',
            'gpi',
            'days_supply',
            'date_of_service',
            'date_prescription_written',
        ]

        const responseHeaders = [
            'response_status',
            'product_count',
            'product_description',
            'patient_pay_amount',
            'incentive_amount_paid',
            'ingredient_cost_paid',
            'date_of_service',
            'transaction_response_status',
            'transaction_count',
            'reject_code',
            'reject_count',
            'additional_message_information',
            'additional_message_information_continuity',
            'additional_message_information_count',
            'additional_message_information_qualifier',
            'amount_attributed_to_sales_tax',
            'amount_of_copay',
            'authorization_number',
            'basis_of_reimbursement_determination',
            'cardholder_id',
            'dispensing_fee_paid',
            'flat_sales_tax_amount_paid',
            'group_id',
            'help_desk_phone_number',
            'help_desk_phone_number_qualifier',
            'incentive_amount_paid',
            'ingredient_cost_paid',
            'message',
            'network_reimbursement_id',
            'other_amount_paid',
            'other_amount_paid_count',
            'other_amount_paid_qualifier',
            'patient_sales_tax_amount',
            'payer_id',
            'payer_id_qualifier',
            'percentage_sales_tax_amount_paid',
            'plan_id',
            'plan_sales_tax_amount',
            'prescription_reference_number',
            'prescription_reference_number_qualifier',
            'product_cost_share_incentive',
            'product_id',
            'product_id_qualifier',
            'product_incentive',
            'reject_field_occurrence_indicator',
            'service_provider_id',
            'service_provider_id_qualifier',
            'tax_exempt_indicator',
            'total_amount_paid',
            'transaction_code',
            'url',
            'version'
        ]
        const patientHeaders = ['date_of_birth', 'patient_gender_code', 'patient_first_name', 'patient_last_name', 'patient_street_address', 'patient_city_address', 'patient_state_address', 'patient_zip_code', 'place_of_service', 'pregnancy_indicator', 'patient_residence']
        const insuranceHeaders = ['cardholder_id', 'cardholder_first_name', 'cardholder_last_name', 'eligibility_clarification_code', 'group_id', 'person_code', 'patient_relationship_code']
        const patientSegmentArray = [patient_segment]
        const insuranceSegmentArray = [insurance_segment]
        const claimRequestArray = [claim_request]
        const claimResponseArray = [claim_response]
        const patientData = patientSegmentArray && patientSegmentArray.length ? patientSegmentArray.filter(d => !!d).map((d) => ({
            ...filterRowData((d || {}), patientHeaders),
            date_of_birth: convertStrToDate(d.date_of_birth),
        })) : []
        const insuranceData = insuranceSegmentArray && insuranceSegmentArray.length ? insuranceSegmentArray.filter(d => !!d).map((d) => ({
            ...filterRowData((d || {}), insuranceHeaders),
        })) : []
        const requestData = claimRequestArray && claimRequestArray.length ? claimRequestArray.filter(d => !!d).map((d) => ({
            ...filterRowData((d || {}), requestHeaders),
            date_of_service: convertStrToDate(d.date_of_service),
            date_prescription_written: convertStrToDate(d.date_prescription_written),
            compound_ingredients: d?.compound_ingredients?.join() || ''
        })) : []

        const responseData = claimResponseArray && claimResponseArray.length ? claimResponseArray.filter(d => !!d).map((d) => ({
            ...filterRowData((d || {}), responseHeaders),
            date_of_service: convertStrToDate(d.date_of_service),
            date_prescription_written: convertStrToDate(d.date_prescription_written),
        })) : []


        return (
            <>
                <div className="section">
                    <h2>Patient Segment</h2>
                    <div className="dur-grid">
                        <FliptGrid
                            data={patientData}
                            headers={patientHeaders}
                        />
                    </div>
                </div><div className="section">
                    <h2>Insurance Segment</h2>
                    <div className="dur-grid">
                        <FliptGrid
                            data={insuranceData}
                            headers={insuranceHeaders}
                        />
                    </div>
                </div>
                <div className="section">
                    <h2>Claim Request</h2>
                    <div className="dur-grid">
                        <FliptGrid
                            data={requestData}
                            headers={requestHeaders}
                        />
                    </div>
                </div>
                <div className="section">
                    <h2>Claim Response</h2>
                    <div className="dur-grid">
                        <FliptGrid
                            data={responseData}
                            headers={responseHeaders}
                        />
                    </div>
                </div></>

        )
    }

    _renderEmptyContent = () => (
        <div>Unavailable Data</div>
    )

    render() {
        const { history, state } = this.props
        const { claimTransactionData } = state
        const hasContent = claimTransactionData?.rejection_section ? Object.keys(claimTransactionData.rejection_section) : []

        return (
            <div id="claimRequestResponse">
                <BreadCrumb {...this.props} />
                <div className="header">
                    <h1>Transaction</h1>
                    <SubmenuNav history={history} active="request" />
                </div>
                <div className="subhead" />
                <div className="content">
                    {hasContent.length ? this._renderContent() : this._renderEmptyContent()}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    state: {
        claimTransactionData: state.claimLookup.claimTransactionData,
    },
})

const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...ApiActions,
    }

    return {
        actions: bindActionCreators(allActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClaimRequestResponse)
