import {
  call,
  put,
  select,
  takeLatest,
  take,
} from 'redux-saga/effects'

import { Types, Creators as DrugPriceLookupActions } from '../../reducers/api/drugPriceLookup'

import { Creators as AppActions, Types as AppTypes } from '../../reducers/app'
import { getAppState, getApiPath, isMenuEmpty } from '../../reducers/selectors'
import { fetchPost } from '../../../utils/fetchApi'

export default [
  fetchDrugPriceLookupWatcher,
  fetchContractNamesWatcher,
]

/* WATCHERS */
function* fetchDrugPriceLookupWatcher() {
  yield takeLatest(Types.FETCH_DRUG_PRICE_LOOKUP, fetchDrugPriceLookupHandler)
}

function* fetchContractNamesWatcher() {
  yield takeLatest(Types.FETCH_CONTRACT_NAMES, fetchContractNamesHandler)
}

/* HANDLERS */
function* fetchContractNamesHandler({ payload }) {
  try {
    const empty = yield select(isMenuEmpty)
    if (empty) {
      yield take([AppTypes.SET_APP_SETTINGS])
    }
    const { serviceUrl } = yield select(getAppState)
    const cd = yield select(getApiPath, 'fetch-contract-names')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`
    const { data } = yield call(fetchPost, url, payload)

    yield put(DrugPriceLookupActions.setDrugPriceContracts(data))
  } catch (err) {
    console.log('contractNamesHandler Error >Data ', err)

    const transitionalPortal = {
      header: 'Unable to fetch Contract Names',
      copy: err.message || err,
    }

    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* fetchDrugPriceLookupHandler({ payload }) {
  try {
    const k = Object.keys(payload)
    if (!k.length) return false

    const formData = {}
    for (const k in payload) {
      if (!!payload[k]) formData[k] = payload[k]
    }
    const { serviceUrl } = yield select(getAppState)
    const cd = yield select(getApiPath, 'drug-price-lookup')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`
    const { data } = yield call(fetchPost, url, formData)

    yield put(DrugPriceLookupActions.setDrugPriceResults(data))
  } catch (err) {
    console.log('drugPriceLookupHandler Error >Data ', err)

    const transitionalPortal = {
      header: 'Drug Price Lookup Failed',
      copy: err.message || err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}
