import React from 'react'

import './style.scss'
import FliptButton from '../../../components/form/fliptButton'
import FliptForm from '../../../components/form/fliptForm'
import FliptInput from '../../../components/form/fliptInput'

export default function enterEmailAddress(props) {
  const { updateField, sendVerificationCode } = props
  return (
    <div className="right">
      <div className="header-text">Change or Reset Password</div>
      <FliptForm id="forgotPasswordForm">
        <FliptInput placeholder="Enter Your Email Address" name="email_address" onChange={updateField} required validation="email_address" />
        <FliptButton className="primary" name="Reset Password" onClick={sendVerificationCode} />
      </FliptForm>
    </div>
  )
}
