import React, { useState, useEffect } from 'react'
import _ from 'lodash'

import FliptRadio from '../../../../../components/form/fliptRadio'
import FliptInput from '../../../../../components/v2/fliptInput'
import FliptButton from '../../../../../components/v2/fliptButton'
import FliptDatePicker from '../../../../../components/v2/fliptDatePicker'
import FliptDropdown from '../../../../../components/v2/fliptDropdown'
import {
	drugFields,
	gridHeaders,
	optionalClaimFields,
	pharmacyFields,
	prescriberFields,
	radioFields,
	requiredClaimFields,
} from '../data/uiFields'
import FliptDrugSearch from '../../../../../components/v2/fliptDrugSearch'
import FliptPrescriberSearch from '../../../../../components/v2/fliptPrescriberSearch'
import FliptPharmacySearch from '../../../../../components/v2/fliptPharmacySearch'
import ClaimGrid from '../../components/claimGrid'
import './styles.scss'
import ClaimForm from '../../components/claimForm'

function ManualClaim(props) {
	const {
		actions,
		setClaimForm,
		claimForm,
		editable,
		setStep,
		testBatchConfig,
		state: { plansData, addMemberData },
	} = props
	const flatRequiredClaimFields = requiredClaimFields.flat()
	const flatOptionalClaimFields = optionalClaimFields.flat()
	const form = { ...claimForm }
	const {
		drugData,
		prescriberData,
		pharmacyData,
		memberData,
		memberGenerated,
		create_type,
		gridData,
		prevAddMemberData,
	} = claimForm
	const _showTransitionalPortal = (header, message) => {
		const transitionalPortal = {
			header: header,
			copy: message,
		}
		actions.displayTransitionalPortal(transitionalPortal)
	}
	const validateClaimData = () => {
		const requiredFieldNames = flatRequiredClaimFields.map((data) => data.name)
		let isEmpty = false
		const fields = []
		Object.keys(form).map((x) => {
			if (requiredFieldNames.includes(x) && form[x] === '') {
				isEmpty = true
				fields.push(x)
			}
		})
		const header = 'Fields Missing'
		let message = `Please add ${fields.join(', ')} to proceed.`

		if (isEmpty) {
			_showTransitionalPortal(header, message)
			return
		}
		if (!prescriberData || !pharmacyData || !drugData) {
			message = 'Add Prescriber, Pharmacy and Drug to Proceed.'
			_showTransitionalPortal(header, message)
			return
		}
		return !isEmpty && prescriberData && pharmacyData && drugData
	}

	const addClaim = () => {
		const isValid = validateClaimData()
		if (!isValid) return
		const tempGridData = [...gridData]
		const tempForm = { ...form }
		tempForm['member_id'] = 'MEMBER_ID_1'
		tempForm['prescriber_id'] = prescriberData?.npi
		tempForm['pharmacy_id'] = pharmacyData?.pharmacynpi
		tempForm['ndc'] = drugData?.ndc
		tempForm['drug_name'] = drugData?.drug_name
		tempGridData.push(tempForm)
		setClaimForm({ ...claimForm, gridData: tempGridData })
	}

	return (
		<div id="manualClaim">
			<ClaimForm
				editable={editable}
				testBatchConfig={testBatchConfig}
				claimData={claimForm}
				onFormChange={(e) => setClaimForm(e)}
			/>
			<FliptButton className="primary" disabled={memberGenerated} name="Add Claim" onClick={addClaim} />
			{gridData.length ? (
				<ClaimGrid
					editable={editable}
					claimForm={claimForm}
					testBatchConfig={testBatchConfig}
					setClaimForm={setClaimForm}
				/>
			) : null}
		</div>
)}
export default ManualClaim
