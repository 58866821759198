import React from 'react'

import FliptInput from '../../../../../components/form/fliptInput'
import FliptButton from '../../../../../components/form/fliptButton'
import './styles.scss'

export default ({ passwordReset, updatePasswordForm }) => (
  <div id="resetPasswordModal">
    <div className="header">
      <h1>Change Password</h1>
    </div>
    <div className="contents">
      <FliptInput label="Password" name="new_password" required type="text" validation="password" stylized onChange={(e) => updatePasswordForm(e)} />
      <FliptButton name="Reset Password" className="primary" onClick={passwordReset} />
    </div>
  </div>
)
