import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import './styles.scss'
import BreadCrumb from '../../../../../components/breadCrumb'
import FliptGrid from '../../../../../components/fliptGrid'
import { Creators as AppActions } from '../../../../../redux/reducers/app'
import { Creators as FormularyCreationActions } from '../../../../../redux/reducers/api/formularyCreationManagement'
import { parseQueryString, createQueryString } from '../../../../../utils/utilities'

class ViewFormularyDrugs extends Component {
  constructor(props) {
    super(props)

    this.state = {
      form: {
        tier_id: '',
      },
      agGridRef: React.createRef(),
    }
  }

  componentDidMount() {
    const { props, state } = this
    const { actions, history: { location } } = props
    const { form, agGridRef } = state
    actions.clearFormularyTierDrugsView()

    this._qsToFormData()
    location.search = createQueryString(form)

    if (!location?.state?.tier_configuration?.length < 1) return
    agGridRef.current.api.refreshServerSideStore({ route: null, purge: true }) // to refresh the grid server side data
  }

  _qsToFormData = () => {
    const { state, props } = this
    const qs = parseQueryString(props.history.location.search)
    const { form } = state

    Object.keys(qs).forEach((key) => {
      form[key] = qs[key]
    })
    this.setState({ form })
  }

  render() {
    const { state, props } = this
    const { actions } = props
    const { form, agGridRef } = state
    const {
      state: {
        pharmaciesPerPage,
      },
      location,
    } = props
    const { moduleViewDrugs } = location.state
    const headers = [
      'drug_name', 'ddid', 'gpi', 'brand_generic', 'multi_source', 'otc_indicator',
    ]
    const serverSideGridConfig = {
      rowModel: 'serverSide',
      serverSideStoreType: 'partial',
      cacheBlockSize: pharmaciesPerPage,
      pagination: true,
      paginationPageSize: pharmaciesPerPage,
    }
    const updatedForm = { ...form, moduleViewDrugs }
    const serverSideGridParams = {
      form: updatedForm,
      apiId: 'view-tier-drugs',
      sagaToCall: actions.getFormularyTierDrugsViewData,
      headers,
    }

    return (
      <div id="viewFormularyDrugs">
        <BreadCrumb {...this.props} />
        <div className="header">View Drugs</div>
        <div className="content">
          <FliptGrid
            headers={headers}
            serverSideGridConfig={serverSideGridConfig}
            serverSideGridParams={serverSideGridParams}
            agGridRef={agGridRef}
            filterOptions={['contains', 'equals']}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  state: {
    app: state.app,
    loading: state.pharmacyLookup.loading,
    pharmacyLookupData: state.pharmacyLookup.pharmacyLookupData,
    pharmaciesPerPage: state.pharmacyLookup.pharmaciesPerPage,
    pharmacySuggestData: state.pharmacyLookup.pharmacySuggestData,
    totalRows: state.pharmacyLookup.pharmacyTotalRows,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
    ...FormularyCreationActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewFormularyDrugs)
