
import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Creators as PriorAuthorizationCreationActions } from '../../../../redux/reducers/api/priorAuthorizationManagement'
import './styles.scss'
import { Creators as AppActions } from '../../../../redux/reducers/app'
import FliptPaInput from '../../../../components/form/fliptPaInput'
import FliptButton from '../../../../components/form/fliptButton'
import FliptPaDropdown from '../../../../components/form/fliptPaDropdown'
import FliptCheckbox from '../../../../components/form/fliptCheckbox'
import { Icon } from 'semantic-ui-react'
import FliptTextarea from '../../../../components/form/fliptTextarea'
import * as Constants from '../constants'
import { DocCreationQueueMessage, FaxQueueMessage } from '../messageComponents'
import PatientInfo from '../PatientInfo'

class RfiModal extends Component {
    constructor(props) {
        super(props)
        const { FAX_STR, EPA_STR, SURESCRIPTS_STR } = Constants
        let provider_message = '', additional_question = '', communication_method = FAX_STR
        const { planForm, selectedStatus } = this.props
        if (planForm.audit_trail) {
            const selectedStatusRecord = planForm.audit_trail.findLast((obj) => obj.audit_trail_status === selectedStatus)
            provider_message = selectedStatusRecord?.provider_message || ''
            additional_question = selectedStatusRecord?.additional_question || ''
            communication_method = selectedStatusRecord?.communication_method || FAX_STR
        }
        if (planForm.intake_type === SURESCRIPTS_STR) {
            communication_method = EPA_STR
        }

        this.state = {
            form: {
                rfi_communication_method: '',
                provider_message: provider_message,
                additional_question: '',
                communication_method,
                resend: false
            },
            docList: [],
            mergedDocList: [],
            documentRead: false
        }
        this.checkQueueStatus = null
    }

    componentDidMount() {
        const { actions, planForm, selectedStatus, state: { paCaseDetails } } = this.props
        const { pa_case_id, patient_benefit, medication, intake_type, queue_status, queue_action } = planForm
        if (intake_type !== Constants.SURESCRIPTS_STR) {
            actions.getMessageTemplate({
                pa_case_id,
                case_type: paCaseDetails.case_type,
                gpi: paCaseDetails?.medication?.gpi,
                action: selectedStatus,
                domain: patient_benefit?.domain,
                ndc: medication?.ndc
            })
        }

        if (queue_status === Constants.QUEUE_SUCCESS_STR && queue_action === selectedStatus) {
            actions.getFinalDocuments({
                pa_case_id,
                action: selectedStatus
            })
        }

    }

    componentDidUpdate(prevProps) {
        const { state, actions, selectedStatus } = this.props
        const { finalDocuments, messageTemplateData, paCaseDetails } = state
        const prevPropState = prevProps.state

        if (prevPropState.paCaseDetails?.queue_status !== paCaseDetails.queue_status &&
            paCaseDetails?.queue_action == selectedStatus) {
            if ([Constants.QUEUE_SUCCESS_STR, Constants.QUEUE_FAILED_STR].includes(paCaseDetails.queue_status)) {
                clearInterval(this.checkQueueStatus)
                actions.getFinalDocuments({
                    pa_case_id: paCaseDetails.pa_case_id,
                    action: selectedStatus
                })
            }
        }

        if (prevPropState?.finalDocuments !== finalDocuments && finalDocuments) {
            const mergedDocList = finalDocuments.map((doc) => {
                return {
                    ...doc,
                    name: doc.type?.split('_').join(' '),
                }
            })
            this.setState({ mergedDocList })
        }
        if (prevPropState?.messageTemplateData !== messageTemplateData && messageTemplateData) {
            const filteredDoc = messageTemplateData.map((doc) => {
                return {
                    ...doc,
                    name: doc.document_type?.split('_').join(' '),
                    checked: true
                }
            })
            this.setState({ docList: filteredDoc })
        }
    }


    _updateForm = (el, dropdown) => {
        const { form } = this.state
        const { name, value, checked } = dropdown || el.currentTarget
        if (name === 'resend') {
            form[name] = checked
        } else {
            form[name] = value
        }
        this.setState({ form })
    }

    closeModal = () => {
        const { actions } = this.props
        actions.toggleSideDrawer()
    }

    _mergeDocuments = () => {
        const { form, docList } = this.state
        const { actions, planForm: { pa_case_id }, selectedStatus, state } = this.props
        const mergeDocParams = {
            document_id: [],
            pa_case_id: pa_case_id,
            action: selectedStatus,
            message_to_provider: form.provider_message,
            first_name: state?.user?.first_name || "",
            last_name: state?.user?.last_name || "",
            userId: state?.user?.uuid
        }
        docList.map((doc) => {
            if (doc.checked) {
                mergeDocParams.document_id.push(doc)
            }
        })
        actions.mergeDocument(mergeDocParams)
        // after we submit request to merge document
        // we need to check queueStatus every 15 secs
        this.checkQueueStatus = setInterval(this.checkQueueStatusEvent, 15000)
    }

    checkQueueStatusEvent = () => {
        const { actions, planForm: { pa_case_id } } = this.props
        actions.getPaCaseDetails({ pa_case_id })
    }

    componentWillUnmount() {
        clearInterval(this.checkQueueStatus)
    }

    _sendCommunication = () => {
        const { form: { communication_method } } = this.state
        const { actions, planForm: { pa_case_id }, selectedStatus } = this.props
        const payload = {
            pa_case_id,
            action: selectedStatus,
            communication_method,
        }
        actions.sendOutboundCommunication(payload)
    }

    _showTransitionalPortal = (header, message) => {
        const { props } = this;
        const transitionalPortal = {
            header: header,
            copy: message,
        }
        props.actions.displayTransitionalPortal(transitionalPortal)
    }

    _updateDocList = (index, innerIndex) => {
        const { docList } = this.state
        const docIndex = (index * 2) + innerIndex
        docList[docIndex].checked = !docList[docIndex].checked
        this.setState({ docList })
    }

    renderPdf = () => {
        const { docList } = this.state
        const chunkedArray = docList.length > 2 ? _.chunk(docList, 2) : [docList]
        const docListRender = chunkedArray.map((doc, index) => {
            return (
                <div className='doc-outer-container' key={index}>
                    {doc.map((innerData, innerIndex) => {
                        return (
                            <div className='doc-checkbox-container' key={innerIndex}>
                                <FliptCheckbox checked={innerData.checked} name={innerData.document_type} onChange={() => this._updateDocList(index, innerIndex)} />
                                <div className='doc-container'>
                                    <Icon name='file pdf outline' color='grey' />
                                    {innerData.name}
                                </div>
                            </div>
                        )
                    })}
                </div>
            )
        })
        return docListRender;
    }

    renderGeneratedPdf = () => {
        const { mergedDocList } = this.state
        const chunkedArray = mergedDocList.length > 2 ? _.chunk(mergedDocList, 2) : [mergedDocList]
        return chunkedArray.map((doc, index) => {
            return (
                <div className='merged-doc-outer-container' key={index}>
                    {doc.map((innerData, innerIndex) => {
                        return (
                            <div className='doc-checkbox-container' key={innerIndex}>
                                <div className='doc-container'>
                                    <Icon name='file pdf outline' color='grey' />
                                    <a href={innerData.document}>{innerData.name}</a>
                                </div>
                            </div>
                        )
                    })}
                </div>
            )
        })
    }

    isGenerateCommunicationDisbaled = () => {
        const { docList } = this.state
        let isDisabled = true
        docList.forEach((doc) => {
            if (doc.checked) isDisabled = false
        })
        return isDisabled
    }

    _updatePaCaseStatus = () => {
        const { form } = this.state
        const { selectedStatus, planForm: { pa_case_id }, state, actions } = this.props
        const params = {
            pa_case_id,
            request_type: 'main_details',
            pa_case_status: selectedStatus,
            provider_message: form.provider_message,
            resend_questionnaire: form.resend ? 'Y' : 'N',
            additional_question: form.additional_question,
            first_name: state?.user?.first_name || "",
            last_name: state?.user?.last_name || "",
            userId: state?.user?.uuid,
            communication_method: form.communication_method,
        }
        actions.updatePaCase(params)
    }

    renderDocumentsOptions = () => {
        const { mergedDocList, form } = this.state
        const { state, selectedStatus, lockCase, planForm } = this.props
        const { queue_action, queue_status, fax_action, fax_status, fax_error_message = "" } = state.paCaseDetails
        const { communicationTypeOptions, FAX_STR } = Constants
        let closeButtonName = 'Finish & Close'
        if (form.communication_method !== FAX_STR) {
            closeButtonName = 'Save & Close'
        }
        return <div>
            <div className='devider' />
            <div className='doc-list-container'>
                {this.renderPdf()}
            </div>
            <div className='devider' />
            <div className='rfi-button-container'>
                <FliptButton className='primary' disabled={this.isGenerateCommunicationDisbaled() || lockCase} name='Generate Communication' onClick={this._mergeDocuments} />
            </div>
            <DocCreationQueueMessage queue_action={queue_action} queue_status={queue_status} selectedStatus={selectedStatus} />
            <div className='doc-list-container'>
                {this.renderGeneratedPdf()}
            </div>
            {mergedDocList?.length !== 0 && <div className='communication-method'>
                <FliptPaDropdown name='communication_method'
                    value={form.communication_method} options={communicationTypeOptions}
                    onChange={this._updateForm} stylized label='Communication Method' />
            </div>}
            <div className='rfi-button-container margin-bottom'>
                <FliptButton className='primary' disabled={!mergedDocList?.length || lockCase} name={closeButtonName} onClick={this._sendCommunication} />
            </div>
            <FaxQueueMessage fax_action={fax_action} fax_status={fax_status} fax_error_message={fax_error_message} selectedStatus={selectedStatus} />
        </div>
    }

    renderApproveOption = () => {
        const { lockCase } = this.props
        return <>
            <div className='communication-method'>
                <FliptPaInput value={this.state.form.communication_method} label='Communication Method' stylized disabled />
            </div>
            <div className='rfi-button-container'>
                <FliptButton className='primary' disabled={lockCase} name='Send RFI' onClick={this._updatePaCaseStatus} />
            </div>
        </>
    }

    render() {
        const { planForm, state: { paCaseDetails } } = this.props
        const { form } = this.state
        return (
            <div id="rfiModal">
                <div className='header-container'>
                    <span className='header-text'>Case Information</span>
                    <Icon onClick={() => this.closeModal()} name='close' color='grey' />
                </div>
                <PatientInfo paCaseDetails={paCaseDetails} />
                <div className='devider' />
                <div className='rfi-container'>
                    <span className='header-text'>Request for Information</span>
                    <div className={planForm?.intake_type === Constants.SURESCRIPTS_STR ? 'rfi-field-container-long' : 'rfi-field-container'}>
                        <FliptTextarea isPa className='message-area' name="provider_message" maxlength="2500" value={form.provider_message}
                            onChange={this._updateForm} stylized label='Message to provider' />
                        {planForm?.intake_type === Constants.SURESCRIPTS_STR && <FliptTextarea className='message-area' isPa name="additional_question" maxlength="2500"
                            value={form.additional_question} onChange={this._updateForm} stylized label='Additional Question' />}
                        {planForm?.intake_type === Constants.SURESCRIPTS_STR && <div className='checkbox-fields'>
                            <FliptCheckbox checked={form.resend} name='resend' onChange={this._updateForm} />
                            <span className='checkbox-text'>Re-Send Questionnaire</span>
                        </div>}
                    </div>
                </div>
                {planForm?.intake_type === Constants.SURESCRIPTS_STR ? this.renderApproveOption() : this.renderDocumentsOptions()}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    state: {
        app: state.app,
        user: state.user,
        notesData: state.priorAuthorizationCreation.notesData,
        updatePaCaseData: state.priorAuthorizationCreation.updatePaCaseData,
        // mergeDocumentData: state.priorAuthorizationCreation.mergeDocumentData,
        messageTemplateData: state.priorAuthorizationCreation.messageTemplateData,
        paCaseDetails: state.priorAuthorizationCreation.paCaseDetails,
        finalDocuments: state.priorAuthorizationCreation.finalDocuments
    },
})

const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...AppActions,
        ...PriorAuthorizationCreationActions,
    }

    return {
        actions: bindActionCreators(allActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RfiModal)
