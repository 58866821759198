import React, { useState, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as _ from 'lodash'
import Config from '../../../../config'
import FliptGrid from '../../../../components/fliptGrid'
import BreadCrumb from '../../../../components/breadCrumb'
import PopupCellRenderer from './popup/popupCellRenderer'
import { Icon } from 'semantic-ui-react'
import { LinkContainerRenderer } from '../../../../components/fliptGrid/cellRenderers'
import { Creators as DrugManagementActions } from '../../../../redux/reducers/api/drugManagement'

const WeeklyDrugManagement = (props) => {
  const headers = [
    'action',
    'processed_file_name',
    'file_load_date',
    'target_date',
    'legacy_reports',
    'total_add_drugs',
    'total_change_drugs',
    'total_term_drugs',
    'overall_status',
  ]
  const handleDownload = (file_load_id) => {
    const { actions } = props
    actions.getLegacyReport({ file_load_id })
  }
  const iconContainer = (props) => {
    const { data: { file_load_id } } = props
    return <button style={{backgroundColor: 'transparent'}} type="button" className="btn btn-primary" onClick={() => handleDownload(file_load_id)}><Icon color='green' size='large' name='file excel outline icon' /></button>
  }
  const cellRendererParams = {
    action: {
      cellRenderer: PopupCellRenderer,
      width: 100,
    },
    processed_file_name: {
      width: 200,
      overrideHeader: 'Processed File Name',
    },
    file_load_date: {
      width: 200,
      overrideHeader: 'Load Date',
    },
    target_date: {
      width: 200,
      overrideHeader: 'Deadline',
    },
    legacy_reports: {
      cellRenderer: iconContainer,
      width: 100,
    },
    total_add_drugs: {
      cellRenderer: LinkContainerRenderer,
      searchArgs: [
        {
          path: '/view-new-drugs',
          searchArgs: { file_id: 'file_load_id' },
          key: 'total_add_drugs',
          staticSearchArgs: {},
        },
      ],
      width: 200,
      overrideHeader: 'New Drugs',
    },
    total_change_drugs: {
      cellRenderer: LinkContainerRenderer,
      searchArgs: [
        {
          path: '/view-changes-by-drug',
          searchArgs: { file_id: 'file_load_id' },
          key: 'total_change_drugs',
          staticSearchArgs: {},
        },
      ],
      width: 145,
      overrideHeader: 'Drug Change',
    },
    total_term_drugs: {
      cellRenderer: LinkContainerRenderer,
      searchArgs: [
        {
          path: '/view-drug-term',
          searchArgs: { file_id: 'file_load_id' },
          key: 'total_term_drugs',
          staticSearchArgs: {},
        },
      ],
      width: 145,
      overrideHeader: 'Drug Term',
    },
    overall_status: {
      width: 145,
      overrideHeader: 'Processing Status',
    },
  }
  const { state, actions } = props
  const { drugManagementWeeklyData: dat } = state
  const [data, setData] = useState(dat)
  useEffect(() => {
    if (dat && !_.isEqual(data, dat)) {
      setData(dat)
    }
  }, [dat])
  useEffect(() => {
    setTimeout(() => actions.getDmWeeklyDrugManagementData(), Config.COMPONENT_DATA_LOAD_TIME)
  }, [])
  return (
    <div id="planDesignManagement">
      <BreadCrumb {...props} />
      <div className="header">
        Weekly Drug Management Summary
      </div>
      <div className="content maxHeight">
        <FliptGrid data={data} headers={headers} cellRendererParams={cellRendererParams} />
      </div>
    </div>
  )
}
const mapStateToProps = (state) => ({
  state: {
    drugManagementWeeklyData: state.drugManagement.drugManagementWeeklyData,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...DrugManagementActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WeeklyDrugManagement)
