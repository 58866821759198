import React from 'react'

import './styles.scss'
import FliptButton from '../../../../../components/form/fliptButton'
import FliptInput from '../../../../../components/form/fliptInput'

export default ({ actions, data, clearFields }) => {
  const { family_details } = data
  const familyDetails = family_details.length ? family_details : []

  const getFamilyMemberData = (domain_name, cardholder_id) => {
    clearFields()
    actions.getMemberLookupData({
      domain_name,
      id: cardholder_id,
      member_identifier: 'member_id',
    })
  }

  return (
    <div className="section family-details">
      <div className="section-header">
        <h2>Family Members On Plan</h2>
      </div>
      {familyDetails.map((d) => (
        <div className="fields-container">
          <div className="fields">
            <FliptInput value={d.name} label="Name" disabled stylized />
            <FliptInput value={d.relationship} label="Relationship" disabled stylized />
            <FliptButton
              name="Detail"
              className="primary"
              onClick={() => getFamilyMemberData(d.domain_name, d.cardholder_id)}
            />
          </div>
        </div>
      ))}
    </div>
  )
}
