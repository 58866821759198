import React from 'react'
import { Popup } from 'semantic-ui-react'

export default function FliptPopup(props) {
  const {
    id, onOpen, onClose, basic, style, trigger, children, open,
  } = props
  return (
    <Popup
      id={id}
      basic={basic}
      on="click"
      onClose={onClose}
      onOpen={onOpen}
      open={open}
      style={style}
      trigger={trigger}
    >
      {children}
    </Popup>
  )
}
