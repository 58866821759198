import Immutable from 'seamless-immutable'
import { createActions, createReducer } from 'reduxsauce'

export const INITIAL_STATE = Immutable({
  create_date: null,
  email_address: null,
  email_verified: null,
  enable_mfa: null,
  // domains_access: [],
  accounts_access: [],
  first_name: null,
  id: null,
  is_active: null,
  last_ip: null,
  last_name: null,
  phone_number: null,
  permissions: [],
  sms_verified: null,
  carriers: [
    {
      name: 'FLIPT',
      id: 'FLIPT',
    },
  ],
  groups: [
    {
      name: 'FliptRx',
      id: 'FLIPT001',
    },
  ],
  userCAG: null,
  userOrgKey: null,
})

export const clearReducer = () => INITIAL_STATE

export const clearUserDataReducer = (state = INITIAL_STATE) => ({
  ...state,
  userData: {},
})

export const userUpdateReducer = (state = INITIAL_STATE, { payload }) => {
  const accounts_access = Object.values(payload.userHierarchy).map(([h]) => h?.children?.map(c => c?.children?.map(ca => ca?.children?.map(a => ({ id: a?.account_id, name: a?.account_name, access: a?.access }))).flat()).flat()).flat().sort((a,b) => (a.id > b.id) ? 1 : -1).filter(d => d.access).map(d => ({ id: d.id, name: d.name })) || []
  const userCAG = {
    organization: [],
    client: [],
    carrier: [],
    account: [],
    group: [],
  }
  let carriers = []
  if (payload?.userHierarchy) {
    Object.entries(payload?.userHierarchy).forEach(([company_code, [org]]) => {
      if (org?.access) userCAG['organization'].push(org?.organization_id)
      org?.children?.forEach(client => {
        if (client?.access) userCAG['client'].push(client?.client_id)
        client?.children?.forEach(carrier => {
          if (carrier?.access) {
            userCAG['carrier'].push(carrier?.carrier_id)
            carriers.push({
              access: carrier?.access,
              name: carrier?.carrier_id,
              key: carrier?.key,
              path: {[company_code]: [org?.key, client?.key, carrier?.key]},
              display_name: carrier.carrier_name,
              account: carrier?.children.filter(a => a?.access).map(a => ({
                access: a?.access,
                name: a?.account_id,
                key: a?.key,
                path: {[company_code]: [org?.key, client?.key, carrier?.key, a?.key]},
                display_name: a.account_name,
                group: a?.children.filter(g => g?.access).map(g => ({
                  access: g?.access,
                  name: g?.group_id,
                  key: g?.key,
                  path: {[company_code]: [org?.key, client?.key, carrier?.key, a?.key, g?.key]},
                  display_name: g.group_name,
                }))
              }))
            })
            carrier?.children?.forEach(account => {
              if (account?.access) userCAG['account'].push(account?.account_id)
              account?.children?.forEach(group => {
                if (group?.access) userCAG['group'].push(group?.group_id)
              })
            })
          }
        })
      })
    })
  }

  return {
    ...state,
    ...payload,
    accounts_access,
    userCAG: {
      ...userCAG,
      defaultClaimsAccess: payload?.role_key?.includes('defaultClaimsViewer'),
    },
    carriers,
  }
}

export const setUserDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  userData: payload,
})

export const userSetCAGReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  userCAG: payload,
})

export const userSetOrgKeyReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  userOrgKey: payload,
})

export const { Types, Creators } = createActions({
  activateUser: ['payload'],
  clearReducer: null,
  clearUserData: null,
  changePassword: ['payload'],
  resetPassword: ['payload'],
  sendVerificationCode: ['payload'],
  userGet: ['payload'],
  userLogin: ['payload'],
  userSSOLogin: ['payload'],
  userLoginSuccessful: ['payload'],
  userLogout: null,
  userMFALogin: ['payload'],
  userUpdate: ['payload'],
  userVerifyCode: ['payload'],
  userVerifyResetPasswordCode: ['payload'],
  setUserData: ['payload'],
  userGetCAG: ['callback'],
  userSetCAG: ['payload'],
  userSetOrgKey: ['payload'],
})

const HANDLERS = {
  [Types.CLEAR_REDUCER]: clearReducer,
  [Types.USER_UPDATE]: userUpdateReducer,
  [Types.SET_USER_DATA]: setUserDataReducer,
  [Types.CLEAR_USER_DATA]: clearUserDataReducer,
  [Types.USER_SET_CAG]: userSetCAGReducer,
  [Types.USER_SET_ORG_KEY]: userSetOrgKeyReducer,
}

export default createReducer(INITIAL_STATE, HANDLERS)
