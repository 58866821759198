import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Icon } from 'semantic-ui-react'

import FliptGrid from '../../../components/fliptGrid'

import { Creators as PriorAuthorizationCreationActions } from '../../../redux/reducers/api/priorAuthorizationManagement'

import './styles.scss'
import BreadCrumb from '../../../components/breadCrumb'
import { LinkRenderer } from '../../../components/fliptGrid/cellRenderers'

class IntakeQueue extends Component {
    constructor(props) {
        super(props)

        this.state = {
            showCreateNewPlan: false,
        }
    }

    componentDidMount() {
        this.props.actions.getPaIntakeQueue({})
    }

    handleIdClicked = (data) => {
        this.props.history.push('/pa-case-dashboard', { pa_case_id: data.value })
    }

    render() {
        const { state } = this.props
        const {
            paIntakeQueue,
        } = state

        const headers = [
            'pa_case_id',
            'intake_type',
            'priority',
            'status',
            'date_time_received',
            'date_time_due',
            'technician'
        ]
        const cellRendererParams = {
            pa_case_id: {
                cellRenderer: LinkRenderer,
                onCellClicked: this.handleIdClicked,
                searchArgs: {}
            },
        }
        return (
            <div id="intakeQueue">
                <BreadCrumb {...this.props} />
                <div className="header">
                    Intake Queue
                    <Link to={{ pathname: '/pa-case-dashboard' }} >
                        <div className="create-pa-case" aria-hidden="true">
                            <Icon name="plus circle" size="tiny" />
                            Create New Manual Intake Request
                        </div>
                    </Link>
                </div>
                <div className="content">
                    {paIntakeQueue?.length ?
                        <FliptGrid
                            data={paIntakeQueue}
                            cellRendererParams={cellRendererParams}
                            headers={headers} /> : <></>}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    state: {
        app: state.app,
        user: state.user,
        paIntakeQueue: state.priorAuthorizationCreation.paIntakeQueue,
    },
})

const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...PriorAuthorizationCreationActions,
    }

    return {
        actions: bindActionCreators(allActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(IntakeQueue)
