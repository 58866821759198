import Immutable from 'seamless-immutable'
import { createActions, createReducer } from 'reduxsauce'

import { convertDateTimeToDate } from '../../../utils/utilities'

export const INITIAL_STATE = Immutable({
  // stepTherapyDrugData: [],
  allStepTherapyData: [],
  stepTherapyCriteriaIdData: [],
  stepTherapyData: {},
  stepTherapyLevelsCriteria: [],
  stepTherapyLevelsData: [],
  stepTherapyLevelsDrugList: [],
  stepTherapyLevelsDrugNameData: [],
  stepTherapyLevelsDrugsData: [],
  stepTherapyLevelsManufacturerData: [],
  stepTherapyTargetDrugCondData: [],
  stepTherapyTargetDrugsDrugNameData: [],
  stepTherapyTargetDrugsManufacturerData: [],
  stepTherapyQualifierLevelsData: [],
  criteria_sublist: []
})

export const clearReducer = () => INITIAL_STATE
export const clearStepTherapyLevelsDrugsReducer = (state = INITIAL_STATE) => ({
  ...state,
  stepTherapyLevelsDrugsData: [],
})

export const clearStepTherapyTargetDrugsDrugNameDataReducer = (state = INITIAL_STATE) => ({
  ...state,
  stepTherapyTargetDrugsDrugNameData: [],
})

export const clearStepTherapyLevelDrugNameDataReducer = (state = INITIAL_STATE) => ({
  ...state,
  stepTherapyLevelsDrugNameData: [],
})

export const clearStepTherapyTargetDrugsManufacturerDataReducer = (state = INITIAL_STATE) => ({
  ...state,
  stepTherapyTargetDrugsManufacturerData: [],
})

export const clearStepTherapyLevelManufacturerDataReducer = (state = INITIAL_STATE) => ({
  ...state,
  stepTherapyLevelsManufacturerData: [],
})

export const clearstepTherapyReducer = () => INITIAL_STATE

export const setAllStepTherapyDataReducer = (state = INITIAL_STATE, { payload }) => {
  const d = payload?.data.map((row) => ({
    ...row,
    effective_end_date: convertDateTimeToDate(row.effective_end_date),
    effective_start_date: convertDateTimeToDate(row.effective_start_date),
  }))
  return {
    ...state,
    allStepTherapyData: d,
  }
}

export const setStepTherapyTargetDrugsManufacturerDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  stepTherapyTargetDrugsManufacturerData: payload,
})

export const setStepTherapyTargetDrugsDrugNameDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  stepTherapyTargetDrugsDrugNameData: payload,
})

export const setStepTherapyLevelManufacturerDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  stepTherapyLevelsManufacturerData: payload,
})

export const setStepTherapyLevelDrugNameDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  stepTherapyLevelsDrugNameData: payload,
})

export const setStepTherapyLevelsDrugsDataReducer = (state = INITIAL_STATE, { payload }) => {
  const drugs = [...state.stepTherapyLevelsDrugsData]
  drugs[payload.stepLevel] = payload.data
  return {
    ...state,
    stepTherapyLevelsDrugsData: drugs,
  }
}

export const setStepTherapyQualifierLevelsDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  stepTherapyQualifierLevelsData: payload,
})

export const clearStepTherapyQualifierLevelsDataReducer = (state = INITIAL_STATE) => ({
  ...state,
  stepTherapyQualifierLevelsData: [],
})

export const setCriteriaSublistLevelsDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  criteria_sublist: payload,
})

export const clearCriteriaSublistLevelsDataReducer = (state = INITIAL_STATE) => ({
  ...state,
  criteria_sublist: [],
})

export const setStepTherapyDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  stepTherapyCriteriaIdData: payload.criteria_ids || [],
  stepTherapyData: payload.step_therapy_info,
  stepTherapyTargetDrugCondData: payload.target_drugs_conditions,
  stepTherapyLevelsData: payload.step_therapy_levels,
  stepTherapyLevelsCriteria: payload.step_level_criteria,
  stepTherapyLevelsDrugList: payload.drug_list,
  stepTherapyQualifierLevelsData: payload.step_therapy_qualifier_levels,
  criteria_sublist: payload.criteria_sublist
})

export const clearStepTherapyDataReducer = (state = INITIAL_STATE) => ({
  ...state,
  stepTherapyCriteriaIdData: [],
  stepTherapyData: {},
  stepTherapyTargetDrugCondData: [],
  stepTherapyLevelsData: [],
  stepTherapyLevelsCriteria: [],
  stepTherapyLevelsDrugList: [],
  stepTherapyQualifierLevelsData: [],
  criteria_sublist: []
})

export const { Types, Creators } = createActions({
  clearCriteriaSublistLevelsData: null,
  clearStepTherapyQualifierLevelsData: null,
  // generateStepTherapy: ['payload'],
  setCriteriaSublistLevelsData: ['payload'],
  setStepTherapyQualifierLevelsData: ['payload'],
  approveStepTherapy: ['payload'],
  clearReducer: null,
  clearStepTherapy: null,
  clearStepTherapyData: null,
  clearStepTherapyLevelDrugs: null,
  generateStepTherapyTargetDrugs: ['payload'],
  generateStepTherapyLevelDrugs: ['payload'],
  getAllStepTherapyData: null,
  getStepTherapyData: ['payload'],
  saveStepTherapyData: ['payload'],
  setAllStepTherapyData: ['payload'],
  setStepTherapyData: ['payload'],
  setStepTherapyDrugData: ['payload'],
  setStepTherapyLevelDrugNameData: ['payload'],
  setStepTherapyLevelManufacturerData: ['payload'],
  setStepTherapyLevelsDrugsData: ['payload'],
  setStepTherapyTargetDrugsDrugNameData: ['payload'],
  setStepTherapyTargetDrugsManufacturerData: ['payload'],
  clearStepTherapyLevelManufacturerData: null,
  clearStepTherapyTargetDrugsManufacturerData: null,
  clearStepTherapyTargetDrugsDrugNameData: null,
  clearStepTherapyLevelDrugNameData: null,
})

const HANDLERS = {
  [Types.CLEAR_CRITERIA_SUBLIST_LEVELS_DATA]: clearCriteriaSublistLevelsDataReducer,
  [Types.SET_CRITERIA_SUBLIST_LEVELS_DATA]: setCriteriaSublistLevelsDataReducer,
  [Types.CLEAR_STEP_THERAPY_QUALIFIER_LEVELS_DATA]: clearStepTherapyQualifierLevelsDataReducer,
  [Types.SET_STEP_THERAPY_QUALIFIER_LEVELS_DATA]: setStepTherapyQualifierLevelsDataReducer,
  [Types.CLEAR_REDUCER]: clearReducer,
  [Types.CLEAR_STEP_THERAPY]: clearstepTherapyReducer,
  [Types.CLEAR_STEP_THERAPY_DATA]: clearStepTherapyDataReducer,
  [Types.CLEAR_STEP_THERAPY_LEVEL_DRUGS]: clearStepTherapyLevelsDrugsReducer,
  [Types.SET_ALL_STEP_THERAPY_DATA]: setAllStepTherapyDataReducer,
  [Types.SET_STEP_THERAPY_DATA]: setStepTherapyDataReducer,
  [Types.SET_STEP_THERAPY_LEVELS_DRUGS_DATA]: setStepTherapyLevelsDrugsDataReducer,
  [Types.SET_STEP_THERAPY_LEVEL_DRUG_NAME_DATA]: setStepTherapyLevelDrugNameDataReducer,
  [Types.SET_STEP_THERAPY_LEVEL_MANUFACTURER_DATA]: setStepTherapyLevelManufacturerDataReducer,
  [Types.SET_STEP_THERAPY_TARGET_DRUGS_DRUG_NAME_DATA]: setStepTherapyTargetDrugsDrugNameDataReducer,
  [Types.SET_STEP_THERAPY_TARGET_DRUGS_MANUFACTURER_DATA]: setStepTherapyTargetDrugsManufacturerDataReducer,
  [Types.CLEAR_STEP_THERAPY_TARGET_DRUGS_MANUFACTURER_DATA]: clearStepTherapyTargetDrugsManufacturerDataReducer,
  [Types.CLEAR_STEP_THERAPY_LEVEL_MANUFACTURER_DATA]: clearStepTherapyLevelManufacturerDataReducer,
  [Types.CLEAR_STEP_THERAPY_TARGET_DRUGS_DRUG_NAME_DATA]: clearStepTherapyTargetDrugsDrugNameDataReducer,
  [Types.CLEAR_STEP_THERAPY_LEVEL_DRUG_NAME_DATA]: clearStepTherapyLevelDrugNameDataReducer,
}

export default createReducer(INITIAL_STATE, HANDLERS)
