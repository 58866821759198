import React, { useState, useEffect, useCallback } from "react";
import * as XLSX from "xlsx";

import { Creators as AppActions } from "../../../../redux/reducers/app";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import FliptInput from "../../../../components/form/fliptInput";
import moment from "moment";
import { Button, Icon, Popup, Table } from "semantic-ui-react";
import { Creators as FileConfigurationActions } from "../../../../redux/reducers/api/fileConfiguration";
import FliptPaDropdown from "../../../../components/form/fliptPaDropdown";
import FliptRadio from "../../../../components/form/fliptRadio";
import FliptLabel from "../../../../components/form/fliptLabel";
import FliptButton from "../../../../components/form/fliptButton";
import DraggableTableRow from "../../planDesignManagement/listingHierarchy/draggableList"; ///"./draggableList";
import EDI834Renderer from "./components/834"
import Info from "./Info";
import { renderDynamicInput, sampleData } from "./utils";
const ImportFormat = (props) => {
  const { state } = props;
  const {
    definedConfigurationList,
    formatDropDownOptions,
    formatHasCustomStandard,
    fieldValidations,
    fieldsValidationResults,
    supportedFormats,
    validations,
    validationResults,
  } = state;

  const showErrors =
    validationResults && validationResults && !validationResults.isAllValid;
  const showFieldErrors =
    fieldValidations &&
    fieldsValidationResults &&
    !fieldsValidationResults.isAllValid;

  const [commonData, setCommonData] = useState({
    controlHeader: [
      {
        um_type: "Oraganization Name",
        rank: 1,
      },
      {
        um_type: "Segment Name",
        rank: 2,
      },
      {
        um_type: "Required",
        rank: 3,
      },
    ],
    groupHeader: [
      {
        um_type: "Oraganization Name",
        rank: 1,
      },
      {
        um_type: "Segment Name",
        rank: 2,
      },
      {
        um_type: "Required",
        rank: 3,
      },
    ],
    selectedList: "",
  });
  const { dateFormat, fieldTypes, numberFormat } =
    props.state.importFormatOptions;
  const [uploading, setUploading] = useState(false);
  const [uploadLabel, setUploadLabel] = useState("Upload");
  const _updateFields = (el, dropdown) => {
    const { name, value } = dropdown || el.currentTarget;
    if (name === "import_file_path") {
      props.actions.updateImportFormatForm({ name, value: el.target.files[0] });
      setUploadLabel("Upload");
      return;
    }
    if (name === "import_format") {
      props.actions.updateImportFormatForm({ name: 'import_file_type', value: '' });
    }
    props.actions.updateImportFormatForm({ name, value });
  };

  const updateDyanmicStandards = (el, dropdown) => {
    const { name, value } = dropdown || el.currentTarget;
    props.actions.updateImportFormatForm({ name: 'import_format', value: 'File Standard' });
    props.actions.updateImportFormatForm({ name, value });
  };

  const _updateEditFields = (el, dropdown) => {
    const { name, value } = dropdown || el.currentTarget;
    props.actions.updateEditFieldsForm({ name, value });
  };

  useEffect(() => {
    if (uploadLabel === "Complete") {
      setUploadLabel("");
      props.actions.setFieldImportConfigListFromSheet();
    }
  }, [uploadLabel]);

  useEffect(() => {
    if (!props.state.importFormat?.import_file_type) return;
    props.actions.getFileType({
      file_direction: props.state.fileDirection,
      file_type: props.state.fileType,
      format: props.state.importFormat?.import_file_type,
    })
  }, [props.state.importFormat?.import_file_type]);

  const handleUpload = () => {
    const { importFormat } = props.state;
    const { import_file_path } = importFormat;
    if (!import_file_path) return;
    setUploading(true);
    try {
      const reader = new FileReader();
      reader.onload = function (evt) {
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: "binary" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        let data = XLSX.utils.sheet_to_json(ws, { header: 1 });
        if (data.length > 0 && !Array.isArray(data[0])) {
          data = [data];
        }
        props.actions.setUploadedFileData(data);
        setUploading(false);
        setUploadLabel("Complete");
      };
      reader.readAsBinaryString(import_file_path);
    } catch (error) {
      console.log(error);
      setUploading(false);
    }
  };

  const { importFormat } = props.state;
  const {
    import_format,
    import_file_type,
    import_delimiter_value,
    import_header_trailer,
    import_end_of_character,
    import_end_of_character_value,
    import_excel_file_ind,
    import_excel_file,
    import_file_path,
    record_type,
    multi_record_detail,
    multi_record_detail_title,
    field_name,
    field_helper_text,
    field_size,
    field_type,
    field_type_format,
    field_starting_position,
    field_ending_position,
    field_transformation_note,
    field_format_instructions,
    custom_group_file_info,
    import_delimiter_other_notes,
  } = importFormat;

  const isFixedWidthFile = import_file_type?.toLowerCase() === "fixed width" || import_file_type?.toLowerCase() === "fixed_width";
  const { selectedFileHeader } = props.state.importFormatState;
  const { multi_record_detail_title: override_multi_record_detail_title } =
    selectedFileHeader || {};

  const renderDynamicStandards = () => {
    const dynamicStandards = props.state.dynamicStandards || [];
    if (dynamicStandards.length === 0) return null;
    return (
      <div className="fields-container">
        <div className="fields-header" />
        <div className="fields-header">
          <div className="field-label-radio">
            {dynamicStandards.map((item, index) => (
              <div className="divide-half-radio" key={`dynamic-standard-${index}`}>
                <FliptRadio
                  className="radio-input"
                  name="import_file_type"
                  value={item.value}
                  checked={import_file_type?.toLowerCase() === item.value}
                  onChange={updateDyanmicStandards}
                />
                <FliptLabel
                  className="radio-label"
                  description=""
                  label={item.text}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }

  const renderFileFormats = () => {
    if (supportedFormats?.length === 0 || !supportedFormats) {
      return (
        <>
          <div className="fields-container">
            <div className="fields-header">
              <div className="field-label">
                <span>Type of file to be processed</span>
                <Info
                  content="Is the file delimited or fixed width"
                  position="top right"
                />
              </div>
            </div>

            <div className="fields-header">
              <div className="field-label-radio">
                <div className="divide-half-radio">
                  <FliptRadio
                    className="radio-input"
                    name="import_file_type"
                    value={"Delimited"}
                    checked={import_file_type?.toLowerCase() === "delimited"}
                    onChange={_updateFields}
                  />
                  <FliptLabel
                    className="radio-label"
                    description=""
                    label="Delimited"
                  />
                  {showErrors && !validationResults?.import_file_type && (
                    <section className="flipt-input-error">
                      <em>
                        {validations.import_file_type?.errorMessage || null}
                      </em>
                    </section>
                  )}
                </div>
                <div className="divide-half-radio">
                  <FliptRadio
                    className="radio-input"
                    toggle={false}
                    name="import_file_type"
                    value={"Fixed Width"}
                    checked={import_file_type?.toLowerCase() === "fixed width"}
                    onChange={_updateFields}
                  />
                  <FliptLabel
                    className="radio-label"
                    description=""
                    label="Fixed Width"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="fields-container">
            <div className="fields-header">
            </div>
            <div className="fields-header">
              <div className="field-label-radio">
                <div className="divide-half-radio">
                  <FliptRadio
                    className="radio-input"
                    name="import_file_type"
                    value={"JSON"}
                    checked={import_file_type?.toLowerCase() === "json"}
                    onChange={_updateFields}
                  />
                  <FliptLabel
                    className="radio-label"
                    description=""
                    label="JSON"
                  />
                  {showErrors && !validationResults?.import_file_type && (
                    <section className="flipt-input-error">
                      <em>
                        {validations.import_file_type?.errorMessage || null}
                      </em>
                    </section>
                  )}
                </div>
                <div className="divide-half-radio">
                  <FliptRadio
                    className="radio-input"
                    name="import_file_type"
                    value={"Excel"}
                    checked={import_file_type?.toLowerCase() === "excel"}
                    onChange={_updateFields}
                  />
                  <FliptLabel
                    className="radio-label"
                    description=""
                    label="Excel"
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }

    return (
      <div className="fields-container">
        <div className="fields-header">
          <div className="field-label">
            <span>Type of file to be processed</span>
            <Info
              content="Is the file delimited or fixed width"
              position="top right"
            />
          </div>
        </div>

        <div className="fields-header">
          <div className="field-label-radio">
            <div className="divide-half-radio">
              <FliptPaDropdown
                stylized
                name="import_file_type"
                onChange={_updateFields}
                simple
                className="half-width"
                value={import_file_type}
                options={import_format?.toLowerCase() === 'file standard' ? formatDropDownOptions?.standard : formatDropDownOptions?.custom}
                placeholder="Field Type"
                log={true}
              />
            </div>
            <div className="divide-half-radio" />
          </div>
        </div>

      </div>
    )
  }

  const renderAdditionalFields = () => {
    const { additionalFields } = props.state;
    if (additionalFields?.length == 0) return null;
    const rowDivider = 1;
    const fieldProps = {
      ...importFormat,
      onChange: _updateFields,
      className: "fields-header",
      num: 1,
    };
    return (
      <>
        {Array.from(
          { length: Math.ceil(additionalFields.length / rowDivider) },
          (_, i) => (
            <div className="fields-container" key={`row${i}`}>
              {additionalFields
                .slice(i * rowDivider, (i + 1) * rowDivider)
                .map((movie) => renderDynamicInput(movie, fieldProps))}
            </div>
          )
        )}
      </>
    );
  };
  const swap = (a, b, selectedList) => {
    const customState = commonData;
    customState[selectedList][a] = customState[selectedList].splice(
      b,
      1,
      customState[selectedList][a]
    )[0];
    customState[selectedList].map((item, i) => (item["rank"] = i + 1));

    setCommonData({
      ...customState,
    });

    delete customState.selectedList;
  };

  const _renderDraggableList = (items, key, selectedList) => {
    return (
      <Table className="table-padding">
        <Table.Body>
          {items.map((obj, i) => (
            <DraggableTableRow
              key={i}
              i={i}
              action={(a, b) => swap(a, b, selectedList)}
            >
              {" "}
              <Table.Cell>
                <div className="fields-container">
                  <div className="fields-table-header">
                    <div className="field-label">
                      <Icon name=" sliders horizontal" />
                    </div>
                  </div>
                </div>
              </Table.Cell>
              <Table.Cell>{i + 1}</Table.Cell>
              <Table.Cell>{"Detail"}</Table.Cell>
              <Table.Cell>
                <div className="fields-container">
                  <div className="fields-table-header">
                    <div className="field-label">
                      <span>{obj[key]}</span>
                      <Icon name="question circle outline" />
                    </div>
                    <FliptInput className="field-input" />
                  </div>
                </div>
              </Table.Cell>
              <Table.Cell>{"Field Starting Position"}</Table.Cell>
              <Table.Cell>{"Field Starting Position"}</Table.Cell>
              <Table.Cell>
                <div className="fields-container">
                  <div className="fields-table-header">
                    <div className="field-label">
                      <Icon name="trash" color="red" />
                    </div>
                  </div>
                </div>
              </Table.Cell>
            </DraggableTableRow>
          ))}
        </Table.Body>
      </Table>
    );
  };

  const setInterchangeTableHeader = (title) => {
    const { selectedFileHeader } = props.state.importFormatState;
    const { multi_record_detail_title } = selectedFileHeader || {};
    if (title === multi_record_detail_title)
      return "interchange-table-selected";
    return "interchange-table";
  };

  const renderRequiredForStandardField = (required, toolTip) => {

    switch (required?.toLowerCase()) {
      case 'yes':
        return (
          <Popup
            trigger={<Icon circular name="check" color="black" />}
            content={"Required"}
          />
        )
      case 'conditional':
        return (
          <Popup
            trigger={<Icon circular name="warning sign" color="yellow" />}
            content={toolTip || "This field may be required based on configuration"}
          />
        )
      case 'no':
      default:
        return null
    }
  }

  const _renderDraggableFieldList = (items, key = "test", selectedList) => {
    return items.map(
      (
        { multi_record_detail_title, fields, id: parent_id, record_type },
        i
      ) => (
        <div
          className={setInterchangeTableHeader(multi_record_detail_title)}
          onClick={() =>
            definedConfigurationList.length > 1 &&
            multi_record_detail_title &&
            props.actions.setFileImportHeaderSelection({
              multi_record_detail_title,
              parent_id,
            })
          }
        >
          <Table>
            <Table.Header>
              {multi_record_detail_title && (
                <Table.Row>
                  <Table.HeaderCell colSpan={9}>
                    Header: {multi_record_detail_title}
                  </Table.HeaderCell>
                </Table.Row>
              )}
              <Table.Row>
                <Table.HeaderCell colSpan={1}></Table.HeaderCell>
                <Table.HeaderCell colSpan={1}></Table.HeaderCell>
                <Table.HeaderCell colSpan={1}>Record Type</Table.HeaderCell>
                <Table.HeaderCell colSpan={1}>Field Name</Table.HeaderCell>
                <Table.HeaderCell colSpan={1}>Field Type</Table.HeaderCell>
                <Table.HeaderCell colSpan={1}>
                  Field Type Format
                </Table.HeaderCell>
                {isFixedWidthFile ? (
                  <>
                    <Table.HeaderCell colSpan={1}>
                      Starting Position
                    </Table.HeaderCell>
                    <Table.HeaderCell colSpan={1}>
                      Ending Position
                    </Table.HeaderCell>
                  </>
                ) : null}
                <Table.HeaderCell colSpan={1}>Action</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {fields.map((field, i) => {
                const {
                  id,
                  field_name,
                  field_type,
                  field_type_format,
                  field_starting_position,
                  field_ending_position,
                } = field;
                const fieldToModify =
                  props.state.importFormatState.fieldToModify || {};
                const { id: modifyId } = fieldToModify;
                const editing = modifyId === id;
                return (
                  <DraggableTableRow
                    key={id}
                    i={i}
                    action={(a, b) => swap(a, b, selectedList)}
                  >
                    {" "}
                    <Table.Cell>
                      <div className="fields-container">
                        <div className="fields-table-header">
                          <div className="field-label">
                            <Icon name=" sliders horizontal" />
                          </div>
                        </div>
                      </div>
                    </Table.Cell>
                    <Table.Cell>{i + 1}</Table.Cell>
                    <Table.Cell>{record_type}</Table.Cell>
                    <Table.Cell>
                      <div className="fields-container">
                        <div className="fields-table-header">
                          <div className="field-label">
                            {editing ? (
                              <FliptInput
                                className="field-input"
                                name="field_name"
                                placeholder="Segment Name"
                                value={fieldToModify.field_name}
                                onChange={_updateEditFields}
                                validation="name"
                              />
                            ) : (
                              <span>{field_name}</span>
                            )}
                          </div>
                        </div>
                      </div>
                    </Table.Cell>
                    <Table.Cell style={{ verticalAlign: "middle" }}>
                      {editing ? (
                        <FliptPaDropdown
                          stylized
                          name="field_type"
                          onChange={_updateEditFields}
                          simple
                          className="half-width"
                          value={fieldToModify.field_type}
                          options={fieldTypes}
                          placeholder="Field Type"
                          log={true}
                        />
                      ) : (
                        field_type
                      )}
                    </Table.Cell>
                    <Table.Cell style={{ verticalAlign: "middle" }}>
                      {editing ? (
                        <>
                          {fieldToModify.field_type === "Date" && (
                            <FliptPaDropdown
                              stylized
                              name="field_type_format"
                              onChange={_updateEditFields}
                              simple
                              className="half-width"
                              value={fieldToModify.field_type_format}
                              options={dateFormat}
                              placeholder="Date Format"
                            />
                          )}
                          {fieldToModify.field_type === "Number" && (
                            <FliptPaDropdown
                              stylized
                              name="field_type_format"
                              onChange={_updateEditFields}
                              simple
                              className="half-width"
                              value={fieldToModify.field_type_format}
                              options={numberFormat}
                              placeholder="Number Format"
                            />
                          )}
                        </>
                      ) : (
                        field_type_format
                      )}
                    </Table.Cell>
                    {isFixedWidthFile ? (
                      <>
                        <Table.Cell>
                          {editing ? (
                            <FliptInput
                              className="field-input"
                              name="field_starting_position"
                              validation="numeric"
                              value={fieldToModify.field_starting_position}
                              onChange={_updateEditFields}
                            />
                          ) : (
                            field_starting_position
                          )}
                        </Table.Cell>
                        <Table.Cell>
                          {editing ? (
                            <FliptInput
                              className="field-input"
                              name="field_ending_position"
                              value={fieldToModify.field_ending_position}
                              validation="numeric"
                              onChange={_updateEditFields}
                            />
                          ) : (
                            field_ending_position
                          )}
                        </Table.Cell>
                      </>) : null}
                    <Table.Cell>
                      <div className="fields-container">
                        <div className="fields-table-header">
                          <div className="field-label-table">
                            {editing ? (
                              <Icon
                                name="checkmark"
                                color="green"
                                onClick={() =>
                                  props.actions.confirmFieldEditChangesAndSync()
                                }
                              />
                            ) : (
                              <Icon
                                name="pencil"
                                color="blue"
                                onClick={() =>
                                  props.actions.setFieldToEdit(field)
                                }
                              />
                            )}
                            <Icon
                              name="trash"
                              color="red"
                              onClick={() =>
                                props.actions.deleteFieldImportConfigAndSync(
                                  field
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </Table.Cell>
                  </DraggableTableRow>
                );
              })}
            </Table.Body>
          </Table>
        </div>
      )
    );
  };

  const renderFileStandard = () => {
    if (import_format !== "File Standard") return null;
    if (!import_file_type) return null;
    const { fileStandardFields = [], formatStandard = {} } = props.state;
    const fieldsToRender = (formatHasCustomStandard ? formatStandard?.fields : fileStandardFields) || [];
    if (fieldsToRender.length === 0) return null;
    if (import_file_type === '834') return <EDI834Renderer />
    return (
      <section>
        <section>
          <h3 className="section-header">File Standard</h3>
        </section>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan={1}>
                Field Name
              </Table.HeaderCell>
              {isFixedWidthFile ? (
                <>
                  <Table.HeaderCell colSpan={1}>
                    Starting Position
                  </Table.HeaderCell>
                  <Table.HeaderCell colSpan={1}>
                    Ending Position
                  </Table.HeaderCell>
                </>
              ) : null}
              <Table.HeaderCell colSpan={1}>
                Required
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {
              fieldsToRender.map((item, index) => {
                return (
                  <Table.Row
                    key={item.std_field + '-' + index}
                  >
                    <Table.Cell>
                      {item.std_field_name}
                    </Table.Cell>
                    {isFixedWidthFile ? (
                      <>
                        <Table.Cell colSpan={1}>
                          {item.std_start_position || ''}
                        </Table.Cell>
                        <Table.Cell colSpan={1}>
                          {item.std_end_position || ''}
                        </Table.Cell>
                      </>
                    ) : null}
                    <Table.Cell>
                      {renderRequiredForStandardField(item.required, item.tool_tip)}
                    </Table.Cell>
                  </Table.Row>
                )
              })
            }
          </Table.Body>
        </Table>
      </section>
    )
  }

  return (
    <div id="file-configuration">
      <div className="section-header">File Details</div>

      <div className="content shadow margin-bottom ">
        <div className="fields-container">
          <div className="fields-header">
            <div className="field-label">
              <span>
                Please define which format the Client will be submitted this
                file
              </span>
              <Info
                content="Will the file be submitted in a standard format or custom format"
                position="top right"
              />
            </div>
          </div>
          <div className="fields-header">
            <div className="field-label-radio">
              <div className="divide-half-radio">
                <FliptRadio
                  className="radio-input"
                  name="import_format"
                  value={"File Standard"}
                  checked={import_format === "File Standard"}
                  onChange={_updateFields}
                />
                <FliptLabel
                  className="radio-label"
                  description=""
                  label="File Standard"
                />
                {showErrors && !validationResults?.import_format && (
                  <section className="flipt-input-error">
                    <em>{validations.import_format?.errorMessage || null}</em>
                  </section>
                )}
              </div>
              <div className="divide-half-radio">
                <FliptRadio
                  className="radio-input"
                  toggle={false}
                  name="import_format"
                  value={"File Custom"}
                  checked={import_format === "File Custom"}
                  onChange={_updateFields}
                />
                <FliptLabel
                  className="radio-label"
                  description=""
                  label="File Custom"
                />
              </div>
            </div>
          </div>
        </div>
        {renderDynamicStandards()}

        {renderFileFormats()}

        {import_file_type?.toLowerCase() === "delimited" && (
          <div className="fields-container">
            <div className="fields-header">
              <div className="field-label">
                <span>Delimiter Value</span>
                <Info
                  content="If delimited, what is the delimiter"
                  position="top right"
                />
              </div>
            </div>
            <div className="fields-header">
              <div className="field-label-radio">
                <div className="divide-half-radio">
                  <FliptRadio
                    className="radio-input"
                    name="import_delimiter_value"
                    value={"Comma"}
                    checked={import_delimiter_value === "Comma"}
                    onChange={_updateFields}
                  />
                  <FliptLabel
                    className="radio-label"
                    description=""
                    label="Comma"
                  />
                </div>
                <div className="divide-half-radio">
                  <FliptRadio
                    className="radio-input"
                    toggle={false}
                    name="import_delimiter_value"
                    value={"Pipe"}
                    checked={import_delimiter_value === "Pipe"}
                    onChange={_updateFields}
                  />
                  <FliptLabel
                    className="radio-label"
                    description=""
                    label="Pipe"
                  />
                </div>
              </div>
              <div className="field-label-radio">
                <div className="divide-half-radio">
                  <FliptRadio
                    className="radio-input"
                    toggle={false}
                    name="import_delimiter_value"
                    value={"Tab"}
                    checked={import_delimiter_value === "Tab"}
                    onChange={_updateFields}
                  />
                  <FliptLabel
                    className="radio-label"
                    description=""
                    label="Tab"
                  />
                </div>
                <div className="divide-half-radio">
                  <FliptRadio
                    className="radio-input"
                    toggle={false}
                    name="import_delimiter_value"
                    value={"Other"}
                    checked={import_delimiter_value === "Other"}
                    onChange={_updateFields}
                  />
                  <FliptLabel
                    className="radio-label"
                    description=""
                    label="Other"
                  />
                </div>
              </div>
              {import_delimiter_value === "Other" && (
                <div className="field-label-radio">
                  <div className="divide-half-radio">
                    <div className="field-label">
                      <span>Other </span>
                    </div>
                    <FliptInput
                      className="field-input"
                      name="import_delimiter_other_notes"
                      placeholder="placeholder "
                      value={import_delimiter_other_notes}
                      onChange={_updateFields}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        <div className="fields-container">
          <div className="fields-header">
            <div className="field-label">
              <span>
                Will the Client Import File contain a Header and Trailer?
              </span>
              <Info
                content="Does the file contain a header and/or trailer"
                position="top right"
              />
            </div>
          </div>
          <div className="fields-header">
            <div className="field-label-radio">
              <div className="divide-half-radio">
                <FliptRadio
                  className="radio-input"
                  toggle={false}
                  name="import_header_trailer"
                  value={"Yes"}
                  checked={import_header_trailer === "Yes"}
                  onChange={_updateFields}
                />
                <FliptLabel
                  className="radio-label"
                  description=""
                  label="Yes"
                />
                {showErrors && !validationResults?.import_header_trailer && (
                  <section className="flipt-input-error">
                    <em>
                      {validations.import_header_trailer?.errorMessage || null}
                    </em>
                  </section>
                )}
              </div>
              <div className="divide-half-radio">
                <FliptRadio
                  className="radio-input"
                  toggle={false}
                  name="import_header_trailer"
                  value={"No"}
                  checked={import_header_trailer === "No"}
                  onChange={_updateFields}
                />
                <FliptLabel className="radio-label" description="" label="No" />
              </div>
            </div>
          </div>
        </div>
        {/* <div className="fields-container">
          <div className="fields-header">
            <div className="field-label">
              <span>
                Does the Client have a document that defines the Custom Group
                File Information?
              </span>
            </div>
          </div>
          <div className="fields-header">
            <div className="field-label-radio">
              <div className="divide-half-radio">
                <FliptRadio
                  className="radio-input"
                  name="custom_group_file_info"
                  value={"Yes"}
                  checked={custom_group_file_info === "Yes"}
                  onChange={_updateFields}
                />
                <FliptLabel
                  className="radio-label"
                  description=""
                  label="Yes"
                />
              </div>
              <div className="divide-half-radio">
                <FliptRadio
                  className="radio-input"
                  toggle={false}
                  name="custom_group_file_info"
                  value={"No"}
                  checked={custom_group_file_info === "No"}
                  onChange={_updateFields}
                />
                <FliptLabel className="radio-label" description="" label="No" />
              </div>
            </div>
          </div>
        </div> */}

        <div className="fields-container">
          <div className="fields-header">
            <div className="field-label">
              <span>Will the client file contain the end of character?</span>
              <Info
                content="Is there an end of character for each record"
                position="top right"
              />
            </div>
          </div>
          <div className="fields-header">
            <div className="field-label-radio">
              <div className="divide-half-radio">
                <FliptRadio
                  className="radio-input"
                  toggle={false}
                  name="import_end_of_character"
                  value={"Yes"}
                  checked={import_end_of_character === "Yes"}
                  onChange={_updateFields}
                />
                <FliptLabel
                  className="radio-label"
                  description=""
                  label="Yes"
                />
                {showErrors && !validationResults?.import_end_of_character && (
                  <section className="flipt-input-error">
                    <em>
                      {validations.import_end_of_character?.errorMessage ||
                        null}
                    </em>
                  </section>
                )}
              </div>
              <div className="divide-half-radio">
                <FliptRadio
                  className="radio-input"
                  toggle={false}
                  name="import_end_of_character"
                  value={"No"}
                  checked={import_end_of_character === "No"}
                  onChange={_updateFields}
                />
                <FliptLabel className="radio-label" description="" label="No" />
              </div>
            </div>
          </div>
        </div>

        {import_end_of_character === "Yes" && (
          <div className="fields-container">
            <div className="fields-header">
              <div className="field-label">
                <span>Enter the end of Character</span>
                <Info
                  content="What is the end of character value"
                  position="top right"
                />
              </div>
            </div>
            <div className="fields-header">
              <FliptInput
                className="field-input"
                placeholder="Enter"
                name="import_end_of_character_value"
                value={import_end_of_character_value}
                onChange={_updateFields}
              />
            </div>
          </div>
        )}
        {renderAdditionalFields()}
        <div className="fields-container">
          <div className="fields-header">
            <div className="field-label">
              <span>
                Does the Client have a file that defines the Custom File Information, available in either a vertical or horizontal layout, regardless of the file format?
              </span>
              <Info
                content="Does the client have a readable file (in Excel) which can be imported to map headers and define file layout dynamically"
                position="top right"
              />
            </div>
          </div>
          <div className="fields-header">
            <div className="field-label-radio">
              <div className="divide-half-radio">
                <FliptRadio
                  className="radio-input"
                  toggle={false}
                  name="import_excel_file_ind"
                  value={"Yes"}
                  checked={import_excel_file_ind === "Yes"}
                  onChange={_updateFields}
                />
                <FliptLabel
                  className="radio-label"
                  description=""
                  label="Yes"
                />
                <div className="field-label">
                  <div className="field-label-hint">
                    <span>excel mappable file with a single record layout</span>
                  </div>
                </div>
                {showErrors && !validationResults?.import_excel_file_ind && (
                  <section className="flipt-input-error">
                    <em>
                      {validations.import_excel_file_ind?.errorMessage || null}
                    </em>
                  </section>
                )}
              </div>
              <div className="divide-half-radio">
                <FliptRadio
                  className="radio-input"
                  toggle={false}
                  name="import_excel_file_ind"
                  value={"No"}
                  checked={import_excel_file_ind === "No"}
                  onChange={_updateFields}
                />
                <FliptLabel className="radio-label" description="" label="No" />
                <div className="field-label">
                  <div className="field-label-hint">
                    <span>No file or file not able to be auto mapped</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          {import_excel_file_ind === "Yes" && (
            <>
              <div className="section">
                <section className="uploadInputContainer">
                  <div className="uploadInput">
                    <span className="element">
                      Select data file in xlsx, format
                    </span>
                    {/* csv, and xml */}
                    <input
                      className="file-input"
                      type="file"
                      name="import_file_path"
                      id="import_file_path"
                      onChange={_updateFields}
                    />
                    <label for="file">{import_file_path?.name}</label>
                  </div>
                  <div className="buttonContainer">
                    <FliptButton
                      className="primary searchButton"
                      name={
                        uploading ? "Loading..." : uploadLabel || "Complete"
                      }
                      onClick={handleUpload}
                      disabled={
                        !import_file_path ||
                        uploading ||
                        uploadLabel === "Complete" ||
                        uploadLabel === ""
                      }
                    />
                  </div>
                </section>
              </div>
            </>
          )}
        </div>
        <div className="ui divider"></div>
        {/* 
        <div className="fields-container">
          <div className="fields-header">
            <div className="field-label">
              <span>Import the File Description Document</span>
            </div>
          </div>
          <div className="fields-header">
            <FliptInput
              className="field-input"
              name="client_notification_emails"
              placeholder="Click to upload"
            />
          </div>
        </div> */}

        {import_format === "File Custom" && import_file_type && (
          <>
            <div className="fields-container">
              <FliptButton className="secondary" name={import_file_type} />
            </div>
            <div className="section-middle-header">
              Define the configuration
            </div>
            <div className="fields-container">
              <div className="fields-header">
                <div className="field-label">
                  <span>What is the record type</span>
                  <Info
                    content="Is the layout being defined, for a header, detail, or trailer record"
                    position="top right"
                  />
                </div>
              </div>
              <div className="fields-header">
                <div className="field-label-radio">
                  <div className="divide-half-radio">
                    <FliptRadio
                      className="radio-input"
                      toggle={false}
                      name="record_type"
                      value={"Detail"}
                      checked={record_type === "Detail"}
                      onChange={_updateFields}
                    />
                    <FliptLabel
                      className="radio-label"
                      description=""
                      label="Detail"
                    />
                  </div>
                  <div className="divide-half-radio">
                    <FliptRadio
                      className="radio-input"
                      toggle={false}
                      name="record_type"
                      value={"Header"}
                      checked={record_type === "Header"}
                      onChange={_updateFields}
                      disabled={import_header_trailer === "No" ? true : false}
                    />
                    <FliptLabel
                      className="radio-label"
                      description=""
                      label="Header"
                    />
                  </div>
                </div>
                <div className="field-label-radio">
                  <div className="divide-half-radio">
                    <FliptRadio
                      className="radio-input"
                      toggle={false}
                      name="record_type"
                      value={"Trailer"}
                      checked={record_type === "Trailer"}
                      onChange={_updateFields}
                      disabled={import_header_trailer === "No" ? true : false}
                    />
                    <FliptLabel
                      className="radio-label"
                      description=""
                      label="Trailer"
                    />
                    <div className="field-label">
                      <div className="field-label-hint">
                        <span>Some supportive text</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="fields-container">
              <div className="fields-header">
                <div className="field-label">
                  <span>
                    Are there multiple detail record types within the file
                  </span>
                  <Info
                    content="Are there multiple detail record types within the file with different layouts"
                    position="top right"
                  />
                </div>
              </div>
              <div className="fields-header">
                <div className="field-label-radio">
                  <div className="divide-half-radio">
                    <FliptRadio
                      className="radio-input"
                      toggle={false}
                      name="multi_record_detail"
                      value={"Yes"}
                      checked={multi_record_detail === "Yes"}
                      onChange={_updateFields}
                    />
                    <FliptLabel
                      className="radio-label"
                      description=""
                      label="Yes"
                    />
                  </div>
                  <div className="divide-half-radio">
                    <FliptRadio
                      className="radio-input"
                      toggle={false}
                      name="multi_record_detail"
                      value={"No"}
                      disabled={props.state.definedConfigurationList.length > 1}
                      checked={multi_record_detail === "No"}
                      onChange={_updateFields}
                    />
                    <FliptLabel
                      className="radio-label"
                      description=""
                      label="No"
                    />
                  </div>
                </div>
              </div>
            </div>
            {multi_record_detail == "Yes" && (
              <div className="fields-container">
                <div className="fields-header">
                  <div className="field-label">
                    <span>Title for the Detail </span>
                    <Info
                      content="If there are multiple detail records, define a name for this detail record"
                      position="top right"
                    />
                  </div>
                </div>
                <div className="fields-header">
                  <FliptInput
                    className="field-input"
                    name="multi_record_detail_title"
                    placeholder="Interchange Control Header"
                    value={
                      override_multi_record_detail_title ||
                      multi_record_detail_title
                    }
                    onChange={_updateFields}
                  />
                </div>
              </div>
            )}
            {/* starting the darag and drop */}
            <div className="fields-container">
              <div className="fields-header">
                <div className="field-label">
                  <span>Field Name</span>
                  <Info content="Field Name" position="top right" />
                </div>
              </div>
              <div className="fields-header">
                <FliptInput
                  className="field-input"
                  name="field_name"
                  placeholder="Segment Name"
                  value={field_name}
                  onChange={_updateFields}
                  error={
                    showFieldErrors &&
                    !fieldsValidationResults?.field_name &&
                    fieldValidations.field_name?.errorMessage
                  }
                />
              </div>
            </div>

            <div className="fields-container">
              <div className="fields-header">
                <div className="field-label">
                  <span>Field Size</span>
                  <Info content="Field size" position="top right" />
                </div>
              </div>
              <div className="fields-header">
                <FliptInput
                  className="field-input"
                  name="field_size"
                  placeholder="15"
                  value={field_size}
                  onChange={_updateFields}
                  validation="numeric"
                  required
                  error={
                    showFieldErrors &&
                    !fieldsValidationResults?.field_size &&
                    fieldValidations.field_size?.errorMessage
                  }
                />
              </div>
            </div>

            <div className="fields-container">
              <div className="fields-header">
                <div className="field-label">
                  <span>Helper Text</span>
                  <Info
                    content="A comment on what is contained within the field"
                    position="top right"
                  />
                </div>
              </div>
              <div className="fields-header">
                <FliptInput
                  className="field-input"
                  name="field_helper_text"
                  placeholder="This field is required in order to enter the name"
                  value={field_helper_text}
                  onChange={_updateFields}
                  error={
                    showFieldErrors &&
                    !fieldsValidationResults?.field_helper_text &&
                    fieldValidations.field_helper_text?.errorMessage
                  }
                />
              </div>
            </div>

            <div className="fields-container">
              <div className="fields-header">
                <div className="field-label">
                  <span>What is the field type</span>
                  <Info
                    content="Field type - Numeric, Date, or a Text value"
                    position="top right"
                  />
                </div>
              </div>
              <div className="fields-header">
                <div className="field-label-radio">
                  <div className="divide-half-radio">
                    <FliptRadio
                      className="radio-input"
                      toggle={false}
                      name="field_type"
                      value={"Number"}
                      checked={field_type === "Number"}
                      onChange={_updateFields}
                      error={
                        showFieldErrors &&
                        !fieldsValidationResults?.field_type &&
                        fieldValidations.field_type?.errorMessage
                      }
                    />
                    <FliptLabel
                      className="radio-label"
                      description=""
                      label="Number"
                    />
                  </div>
                  <div className="divide-half-radio">
                    <FliptRadio
                      className="radio-input"
                      toggle={false}
                      name="field_type"
                      value={"Date"}
                      checked={field_type === "Date"}
                      onChange={_updateFields}
                    />
                    <FliptLabel
                      className="radio-label"
                      description=""
                      label="Date"
                    />
                  </div>
                </div>
                <div className="field-label-radio">
                  <div className="divide-half-radio">
                    <FliptRadio
                      className="radio-input"
                      toggle={false}
                      name="field_type"
                      value={"Text"}
                      checked={field_type === "Text"}
                      onChange={_updateFields}
                    />
                    <FliptLabel
                      className="radio-label"
                      description=""
                      label="Text"
                    />
                  </div>
                  <div className="divide-half-radio">
                    {field_type === "Date" && (
                      <FliptPaDropdown
                        stylized
                        name="field_type_format"
                        onChange={_updateFields}
                        simple
                        className="half-width"
                        value={field_type_format}
                        options={dateFormat}
                        placeholder="Date Format"
                      />
                    )}
                    {field_type === "Number" && (
                      <FliptPaDropdown
                        stylized
                        name="field_type_format"
                        onChange={_updateFields}
                        simple
                        className="half-width"
                        value={field_type_format}
                        options={numberFormat}
                        placeholder="Number Format"
                      />
                    )}
                    {/* {field_type === "Text" && (
                  <FliptInput
                    className="field-input"
                    name="field_type_format"
                    placeholder="1"
                    value={field_type_format}
                    onChange={_updateFields}
                  />
                )} */}
                  </div>
                </div>
                {showFieldErrors && !fieldsValidationResults?.field_type && (
                  <section className="flipt-input-error">
                    <em>{fieldValidations.field_type?.errorMessage || null}</em>
                  </section>
                )}
              </div>
            </div>
            {isFixedWidthFile && (
              <>
                <div className="fields-container">
                  <div className="fields-header">
                    <div className="field-label">
                      <span> Field Starting Position </span>
                      <Info
                        content="Starting position for the field - if the file is a fixed width"
                        position="top right"
                      />
                    </div>
                  </div>
                  <div className="fields-header">
                    <FliptInput
                      className="field-input"
                      name="field_starting_position"
                      placeholder="1"
                      value={field_starting_position}
                      onChange={_updateFields}
                      validation="numeric"
                      required
                    // error={
                    //   showFieldErrors &&
                    //   !fieldsValidationResults?.field_starting_position &&
                    //   fieldValidations.field_starting_position?.errorMessage
                    // }
                    />
                    <div className="field-sub-label">
                      <div className="field-label-hint">
                        <span>
                          Need to calculate Starting Position for the User
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="fields-container">
                  <div className="fields-header">
                    <div className="field-label">
                      <span>Field Ending Position</span>
                      <Info
                        content="Ending position for the field - if the file is fixed width"
                        position="top right"
                      />
                    </div>
                  </div>
                  <div className="fields-header">
                    <FliptInput
                      className="field-input"
                      name="field_ending_position"
                      placeholder="1"
                      value={field_ending_position}
                      onChange={_updateFields}
                      validation="numeric"
                      required
                    // error={
                    //   showFieldErrors &&
                    //   !fieldsValidationResults?.field_ending_position &&
                    //   fieldValidations.field_ending_position?.errorMessage
                    // }
                    />
                    <div className="field-sub-label">
                      <div className="field-label-hint">
                        <span>
                          Need to calculate Starting Position for the User
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}

            <div className="fields-container">
              <div className="fields-header">
                <div className="field-label">
                  <span>
                    Field Import Values and Transformation Instructions
                  </span>
                  <Info
                    content="If any basic transformations need to occur they can be listed here"
                    position="top right"
                  />
                </div>
              </div>
              <div className="fields-header">
                <FliptInput
                  className="field-input"
                  name="field_transformation_note"
                  placeholder="1"
                  value={field_transformation_note}
                  onChange={_updateFields}
                  error={
                    showFieldErrors &&
                    !fieldsValidationResults?.field_transformation_note &&
                    fieldValidations.field_transformation_note?.errorMessage
                  }
                />
                <div className="field-sub-label">
                  <div className="field-label-hint">
                    <span>Example: Gender: 20= Female, 10 = Male</span>
                    <div>
                      Relationship Code: 33 = Spouse, 23 = Self, 49 = Child
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="fields-container">
              <div className="fields-header">
                <div className="field-label">
                  <span>Field Format Instructions</span>
                  <Info
                    content="Does the field have a certain format, for example Date is MM/DD/YYYY"
                    position="top right"
                  />
                </div>
              </div>
              <div className="fields-header">
                <FliptInput
                  className="field-input"
                  name="field_format_instructions"
                  placeholder="1"
                  value={field_format_instructions}
                  onChange={_updateFields}
                  error={
                    showFieldErrors &&
                    !fieldsValidationResults?.field_format_instructions &&
                    fieldValidations.field_format_instructions?.errorMessage
                  }
                />
                <div className="field-sub-label">
                  <div className="field-label-hint">
                    <span>Example: Gender: 20= Female, 10 = Male</span>
                    <div>
                      Relationship Code: 33 = Spouse, 23 = Self, 49 = Child
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="fields-equal">
              <Button
                content="Add More Record Type"
                icon="plus"
                size="small"
                style={{ background: "#1D2939", color: "white" }}
                disabled={multi_record_detail === "No" || (!override_multi_record_detail_title && !multi_record_detail_title)}
                onClick={props.actions.createNewRecordType}
              />
              <Button
                content="Add Field"
                icon="plus"
                size="small"
                onClick={props.actions.validateAndAppendFields}
              />
            </div>
            {definedConfigurationList.length > 0 &&
              _renderDraggableFieldList(definedConfigurationList)}
          </>
        )}
        {renderFileStandard()}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const importFormat = state.fileConfiguration.importFormat;
  const fileType = state.fileConfiguration.file_type;
  const fileDirection = state.fileConfiguration.file_direction?.toLowerCase() || 'inbound';
  const fileStandardKey = fileType + '_standard';
  const standard = state.fileConfiguration[fileStandardKey];
  const supportedFormats = standard.supported_formats || [];
  const dynamicStandards = [];
  const formatDropDownOptions = { standard: [], custom: [] };
  for (let index = 0; index < supportedFormats.length; ++index) {
    const formatOption = supportedFormats[index];
    const directionSupported = formatOption?.supported_file_direction?.[fileDirection];
    if (directionSupported) {
      const selectableOption = {
        // key: formatOption?.format,
        key: index + 1,
        text: formatOption?.label,
        value: formatOption?.format,
      };
      const requiresCustomFields = formatOption.fields === 'custom';
      if (requiresCustomFields) {
        dynamicStandards.push(selectableOption);
        continue;
      }
      if (directionSupported?.standard) {
        formatDropDownOptions.standard.push(selectableOption)
      }
      if (directionSupported?.custom) {
        formatDropDownOptions.custom.push(selectableOption)
      }
    }
  }
  let formatStandard = null;
  const selectedFormat = importFormat?.import_file_type;
  const formatHasCustomStandard = (supportedFormats.find((format) => format.format === selectedFormat) || { fields: 'default' })?.fields?.toLowerCase() !== 'default';
  if (formatHasCustomStandard) {
    formatStandard = state.fileConfiguration[selectedFormat + "_format_standard"];
  }
  return {
    state: {
      app: state.app,
      importFormat: importFormat,
      importFormatOptions: state.fileConfiguration.options.importFormat,
      importFormatState: state.fileConfiguration.importFormatState,
      definedConfigurationList: state.fileConfiguration.importFormat.defined_configuration_list || [],
      dynamicStandards: dynamicStandards,
      formatDropDownOptions: formatDropDownOptions,
      fileDirection: fileDirection,
      fileStandardFields: standard?.fields,
      fileType: fileType,
      fieldValidations: state.fileConfiguration.validations.importFormatFields,
      formatDropDownOptions: formatDropDownOptions,
      formatHasCustomStandard: formatHasCustomStandard,
      formatStandard: formatStandard,
      supportedFormats: supportedFormats,
      validationResults: state.fileConfiguration.validationResults.importFormat,
      fieldsValidationResults: state.fileConfiguration.validationResults.importFormatFields,
      uploadedFileData: state.fileConfiguration.uploadedFileData,
      additionalFields:
        state.fileConfiguration.additional_fields?.find(
          (row) => row.page === "import_format"
        )?.fields || [],
      validations: state.fileConfiguration.validations.importFormat,
    },
  };
};

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
    ...FileConfigurationActions,
  };
  return {
    actions: bindActionCreators(allActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ImportFormat);
