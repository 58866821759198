import React, { Component } from 'react'
import _ from 'lodash'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'

import { Creators as AppActions } from '../../../../../redux/reducers/app'
import { Creators as FormularyCreationActions } from '../../../../../redux/reducers/api/formularyCreationManagement'
import FliptInput from '../../../../../components/form/fliptInput'
import './styles.scss'
import FliptDatePicker from '../../../../../components/form/fliptDatePicker'
import FliptButton from '../../../../../components/form/fliptButton'
import { drugGroupValues } from '../../../../../config/constants/clinical/drugGroups'
import { convertStrToDateObj, addRemoveDaysToDate } from '../../../../../utils/utilities'
import FliptHierarchyDropdown from '../../../../../components/form/fliptHierarchyDropdown'

class CopyFormularyTierObject extends Component {
  constructor(props) {
    super(props)

    this.state = {
      form: {
        doc_id: props.state?.formularyTierData?.doc_id || '',
        effective_start_date: '',
        module: 'FORMULARY_TIER',
        name: props.data[0]?.['module_name'],
        status: 'DRAFT',
        version: '1.0',
        hierarchy: [],
      },
      conditions: [],
      dropdownValues: [{
        drug_group: drugGroupValues, drug_class: [], drug_subclass: [],
      }],
      autoSuggestionValues: [{
        manufacturer: { values: [], loading: false }, drug_name: { values: [], loading: false },
      }],
      autoSuggestionMinChars: 3,
      columnUpdated: null,
      editMode: true,
      columnValue: '',
      rowUpdated: null,
    }
  }

  componentDidMount() {
    const { actions, data } = this.props
    actions.clearFormularyTierData()
    if (data[0]?.id) {
      actions.getFormularyTierData({ doc_id: data[0]?.id })
    }
  }

  componentDidUpdate(prevProps) {
    const { formularyTierCondData } = this.props.state
    if (prevProps.state.formularyTierCondData !== formularyTierCondData) {
      this.setState({
        conditions: [...formularyTierCondData]
      })
    }
  }

  componentWillUnmount() {
    this.props.actions.clearFormularyTierData()
  }

  _updateFields = (el, dateData) => {
    const { name, value } = dateData || el.currentTarget

    this.setState((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        [name]: value,
      },
    }))
  }

  setForm = (newForm) => {
    this.setState((prevState) => ({
      ...prevState,
      form: newForm
    }))
  }

  _saveFormularyTier = () => {
    const { props, state } = this
    const { form, conditions } = state
    const { showTabFlag, setShowTabFlag } = this.props

    if (!form.name || !form.effective_start_date) {
      const transitionalPortal = {
        header: 'Formulary Tier Save Failed',
        copy: !form.name ? 'Please input a Tier Name before saving' : 'Please select a start date before saving',
      }
      props.actions.displayTransitionalPortal(transitionalPortal)
    } else {
      setTimeout(() => props.actions.saveFormularyTierData({ ...form, conditions }), 250)
      setShowTabFlag(!showTabFlag);
      setTimeout(() => props.actions.getAllFormularyTiersData(), 10000);
    }
  }

  render() {
    const {
      form
    } = this.state

    const { showTabFlag, setShowTabFlag } = this.props

    const {
      name, effective_start_date, version, status,
    } = form
    const dateValue = effective_start_date ? convertStrToDateObj(new Date(effective_start_date)) : ""
    const minTierStartDate = moment(addRemoveDaysToDate(1, false)).format('MM-DD-YYYY')

    return (
      <div id="create-drug-list">
        <div className="content copy-object">
          <div className="tier-inputs">
            <span>Tier Name</span>
            <FliptInput className="createTierInputs" name="name" value={name} onChange={this._updateFields} disabled={!!version && (version !== '1.0')} />
          </div>
          <div className="tier-inputs">
            <span>Effective Start Date</span>
            <FliptDatePicker className="create-tier-start-date" name="effective_start_date" value={dateValue} minDate={minTierStartDate} onChange={this._updateFields} format="MM-DD-YYYY" />
          </div>
          <FliptHierarchyDropdown showEnhancedTier={true} showRxcui={true} setForm={this.setForm} form={form} />
          <div className="buttonContainer">
            <FliptButton name="Copy Object" className="primary searchButton" onClick={this._saveFormularyTier} />
          </div>
          <div className="buttonContainer">
            <FliptButton name="Cancel" className="secondary searchButton" onClick={() => setShowTabFlag(!showTabFlag)} />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  state: {
    formularyTierData: state.formularyCreation.formularyTierData,
    formularyTierCondData: state.formularyCreation.formularyTierCondData,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
    ...FormularyCreationActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CopyFormularyTierObject)
