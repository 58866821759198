
const dateHeaders = ['effective_begin_date', 'effective_end_date']
export const programHeader = ['action', 'program_name', ...dateHeaders]
export const qlHeader = ['action', 'ql_name', ...dateHeaders]
export const paHeader = ['action', 'pa_name', ...dateHeaders]
export const stHeader = ['action', 'step_therapy_name', ...dateHeaders]

export const rejectCodeOptions = ["75", "3W"]
export const rejectMessage = {
    '75': 'Prior Authorization Required',
    '3W': 'Prior Authorization in Process'
}