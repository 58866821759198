import { isEmpty } from "lodash";
const validateKeys = {
    'doc_name': 'Contract Name',
    'contract_start_date': 'Contract Start Date',
    'contract_end_date': 'Contract End Date',
}

const validateSystemKeys = {
    'flipt_bpg_match': 'Flipt Payer in System settings'
}

export const validate = (data) => {
    const validateKeysArray = Object.keys(validateKeys)
    const error = validateKeysArray.find(ele => !(ele in data && data[ele]))
    return error ? `${validateKeys[error]} should not be empty.` : ''
}
export const validateSystem = (data) => {
    const validateKeysArray = Object.keys(validateSystemKeys)
    const error = validateKeysArray.find(ele => !(ele in data && data[ele]))
    return error ? `${validateSystemKeys[error]} should not be empty.` : ''
}
