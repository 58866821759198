import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import _ from 'lodash'

import './styles.scss'
import BUSINESS_OPTIONS from '../data/businessOptions'
import Config from '../../../../../config'
import FliptButton from '../../../../../components/form/fliptButton'
import FliptDatePicker from '../../../../../components/form/fliptDatePicker'
import FliptDropdown from '../../../../../components/form/fliptDropdown'
import FliptInput from '../../../../../components/form/fliptInput'
import FliptSeparator from '../../../../../components/form/fliptSeparator'
import LOB_OPTIONS from '../data/lobOptions'
import statesJSON from '../../../../../config/constants/states'
import { Creators as AppActions } from '../../../../../redux/reducers/app'
import { Creators as NavigationActions } from '../../../../../redux/reducers/navigation'
import { Creators as companyManagementActions } from '../../../../../redux/reducers/companyManagement'
import { addRemoveDaysToDate, convertStrToDateObj, parseQueryString } from '../../../../../utils/utilities'

class CreateCompany extends Component {
  constructor(props) {
    super(props)
    this.state = {
      form: {
        id: '',
        address1: '',
        address2: '',
        admin_contacts: [{}],
        business_type: [],
        city: '',
        company_code: '',
        company_full_name: '',
        company_phone_number: '',
        effective_end_date: '',
        effective_start_date: '',
        line_of_business: [],
        state: '',
        zip: '',
      },
      editMode: true,
    }
  }

  componentDidMount() {
    const { actions, history: { location } } = this.props

    actions.clearCompanyData()

    if (!location.state) {
      this.setState({ editMode: false })
      return
    }

    const { editMode } = location.state

    if (editMode) this.setState({ editMode })

    setTimeout(() => {
      if (location.search) {
        actions.clearCompanyData()
        actions.getCompanyData(parseQueryString(location.search))
      }
    }, Config.COMPONENT_DATA_LOAD_TIME)
  }

  componentDidUpdate(prevProps) {
    const { state } = this.props
    const { companyData } = state
    if (companyData && Object.keys(companyData).length && !_.isEqual(prevProps.state.companyData, companyData)) {
      this.updateForm(companyData)
    }
  }

  updateForm = (companyData) => {
    const { form } = this.state
    const formData = Object.keys(form).reduce((acc, curr) => {
      acc[curr] = companyData[curr]

      return acc
    }, {})
    this.setState((prevState) => ({
      ...prevState,
      form: formData,
    }))
  }

  _submitForm = () => {
    const { props, state } = this
    if (state.editMode) {
      props.actions.editCompanyManagementData(state.form)
    } else {
      props.actions.createCompanyManagementData(state.form)
    }
  }

  _updateFields = (el, dateData) => {
    const { form } = this.state
    const { name, value } = dateData || el.currentTarget
    const adminContactFields = ['first_name', 'last_name', 'email_address']
    if (adminContactFields.indexOf(name) !== -1) {
      if (form.admin_contacts[0]) {
        form.admin_contacts[0][name] = value
      } else {
        form.admin_contacts[0] = { [name]: value }
      }
    } else {
      form[name] = value
    }
    this.setState((prevState) => ({
      ...prevState,
      form,
    }))
  }

  render() {
    const { props, state } = this
    const cd = state.form
    const minCompanyStartDate = addRemoveDaysToDate(1, false)
    const statesData = Object.entries(statesJSON).map(([k, v]) => ({
      key: k,
      text: `${k} - ${v}`,
      value: k,
    }))
    const { location: { search } } = props.history
    const contact = cd?.admin_contacts[0]

    return (
      <div id="createCompany">
        <div className="header">
          {search ? 'Edit ' : 'Create New '}
          Organization
        </div>
        <div className="content">
          <div className="section">
            <section className="company-inputs-container spacing border-line shadow">
              <div className="company-inputs-wrap">
                <div className="company-inputs">
                  <span>Organization Name</span>
                  <FliptInput placeholder="Organization Name" name="company_full_name" defaultValue={cd.company_full_name} onChange={this._updateFields} />
                </div>
                <div className="company-inputs">
                  <span>Organization Code</span>
                  <FliptInput placeholder="Organization Code" name="company_code" defaultValue={cd.company_code} onChange={this._updateFields} />
                </div>
                <div className="company-inputs">
                  <span>Effective Start Date</span>
                  <FliptDatePicker className="create-company-start-date" placeholder="Effective Start Date" name="effective_start_date" minDate={minCompanyStartDate} defaultValue={convertStrToDateObj(cd.effective_start_date)} onChange={this._updateFields} />
                </div>
                <div className="company-inputs">
                  <span>Effective End Date</span>
                  <FliptDatePicker className="create-company-start-date" placeholder="Effective End Date" name="effective_end_date" minDate={minCompanyStartDate} defaultValue={convertStrToDateObj(cd.effective_end_date)} onChange={this._updateFields} />
                </div>
              </div>
              <div className="company-inputs-wrap">
                <div className="company-inputs">
                  <span>Line of Business (LOB)</span>
                  <FliptDropdown clearable placeholder="Select" name="line_of_business" value={cd.line_of_business} onChange={this._updateFields} options={LOB_OPTIONS} multiple />
                </div>
                <div className="company-inputs">
                  <span>Business Type</span>
                  <FliptDropdown clearable placeholder="Select" name="business_type" value={cd.business_type} onChange={this._updateFields} options={BUSINESS_OPTIONS} multiple />
                </div>
                <div className="company-inputs">
                  <span>Phone Number</span>
                  <FliptInput placeholder="Phone Number" name="company_phone_number" defaultValue={cd.company_phone_number} onChange={this._updateFields} />
                </div>
              </div>
              <FliptSeparator />

              <h2>Address</h2>
              <div className="company-inputs-wrap">
                <div className="company-inputs">
                  <span>Address 1</span>
                  <FliptInput placeholder="Address 1" name="address1" defaultValue={cd.address1} onChange={this._updateFields} />
                </div>
                <div className="company-inputs">
                  <span>Address 2</span>
                  <FliptInput placeholder="Address 2" name="address2" defaultValue={cd.address2} onChange={this._updateFields} />
                </div>
                <div className="company-inputs">
                  <span>City</span>
                  <FliptInput placeholder="City" name="city" defaultValue={cd.city} onChange={this._updateFields} />
                </div>
                <div className="company-inputs">
                  <span>State</span>
                  <FliptDropdown clearable placeholder="Select" name="state" defaultValue={cd.state} onChange={this._updateFields} options={statesData} />
                </div>
                <div className="company-inputs">
                  <span>Zipcode</span>
                  <FliptInput placeholder="Zipcode" name="zip" defaultValue={cd.zip} onChange={this._updateFields} />
                </div>
              </div>
              <FliptSeparator />

              <h2>Primary Contact</h2>
              <div className="company-inputs-wrap">
                <div className="company-inputs">
                  <span>First Name</span>
                  <FliptInput placeholder="First Name" name="first_name" defaultValue={contact?.first_name} onChange={this._updateFields} />
                </div>
                <div className="company-inputs">
                  <span>Last Name</span>
                  <FliptInput placeholder="Last Name" name="last_name" defaultValue={contact?.last_name} onChange={this._updateFields} />
                </div>
                <div className="company-inputs">
                  <span>Email</span>
                  <FliptInput placeholder="Email" name="email_address" defaultValue={contact?.email_address} onChange={this._updateFields} />
                </div>
              </div>
            </section>
            <div className="buttonContainer">
              <FliptButton name="Back" className="primary searchButton" onClick={props.history.goBack} />
              <FliptButton name="Submit" className="primary searchButton" onClick={this._submitForm} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  state: {
    companyData: state.companyManagement.companyData,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
    ...NavigationActions,
    ...companyManagementActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateCompany)
