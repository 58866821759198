import Immutable from 'seamless-immutable'
import { createActions, createReducer } from 'reduxsauce'
import moment from 'moment'

export const INITIAL_STATE = Immutable({
  approvalRuleData: {
    id: '',
    account: [],
    group: [],
    carrier: [],
    client: [],
    name: '',
    document_governed: '',
    hierarchy: [],
    conditions: [],
  },
  documents: [],
  rules: [],
  statusHistory: [],
  impactAnalysis: [],
  mf2Val: [],
  conditionsDropDownOptions: [],
})

export const clearApprovalDocumentsReducer = (state = INITIAL_STATE) => {
  return {
    ...state,
    documents: [],
  }
}

export const rpmSetApprovalDocumentsReducer = (state = INITIAL_STATE, { payload }) => {
  return {
    ...state,
    documents: payload,
  }
}

export const rpmSetApprovalRulesReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  rules: payload,
})

export const setApprovalRulesDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  approvalRuleData: payload,
})

export const rpmSetApprovalsStatusHistoryReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  statusHistory: [...payload],
})

export const rpmSetImpactAnalysisReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  impactAnalysis: [...payload]
})

export const rpmSetMf2ValDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  mf2val: [...payload.mf2Val],
  conditionsDropDownOptions: { ...payload.conditionsDropDownOptions }
})

export const clearApprovalRulesReducer = (state = INITIAL_STATE) => ({
  ...state,
  approvalRuleData: {
    ...INITIAL_STATE.approvalRuleData,
  }
})

export const clearApprovalsStatusHistoryReducer = (state = INITIAL_STATE) => ({
  ...state,
  statusHistory: [],
})

export const rpmClearImpactAnalysisReducer = (state = INITIAL_STATE) => ({
  ...state,
  impactAnalysis: []
})

export const clearReducer = () => INITIAL_STATE

export const { Types, Creators } = createActions({
  clearApprovalRules: null,
  clearApprovalDocuments: null,
  clearApprovalsStatusHistory: null,
  clearReducer: null,
  rpmApprovalRulesLookup: ['payload'],
  rpmApproveDocument: ['payload'],
  rpmApproveRejectDocument: ['payload', 'callback'],
  rpmClearImpactAnalysis: null,
  rpmCreateApprovalRules: ['payload'],
  rpmEditDocumentHierarchy: ['payload'],
  rpmGetApprovalDocuments: ['payload', 'callback'],
  rpmGetApprovalRulesData: ['payload'],
  rpmGetApprovalsStatusHistory: ['payload', 'callback'],
  rpmGetImpactAnalysis: ['payload'],
  rpmGetMf2ValData: ['payload'],
  rpmGetPublishedDocument: ['payload', 'callback'],
  rpmLoadApprovalsQueue: null,
  rpmLoadApprovalsRules: null,
  rpmSendForPublish: ['payload', 'callback'],
  rpmSendForReview: ['payload', 'callback'],
  rpmSetApprovalDocuments: ['payload'],
  rpmSetApprovalRules: ['payload'],
  rpmSetApprovalRulesData: ['payload'],
  rpmSetApprovalsStatusHistory: ['payload'],
  rpmSetImpactAnalysis: ['payload'],
  rpmSetMf2ValData: ['payload'],
  rpmUpdateApprovalRules: ['payload'],
  rpmUpsertApprovalDocument: ['payload', 'callback', 'silentOnSuccess', 'isFormData'],
})

const HANDLERS = {
  [Types.CLEAR_APPROVAL_RULES]: clearApprovalRulesReducer,
  [Types.CLEAR_APPROVAL_DOCUMENTS]: clearApprovalDocumentsReducer,
  [Types.CLEAR_APPROVALS_STATUS_HISTORY]: clearApprovalsStatusHistoryReducer,
  [Types.CLEAR_REDUCER]: clearReducer,
  [Types.RPM_CLEAR_IMPACT_ANALYSIS]: rpmClearImpactAnalysisReducer,
  [Types.RPM_SET_APPROVAL_DOCUMENTS]: rpmSetApprovalDocumentsReducer,
  [Types.RPM_SET_APPROVAL_RULES_DATA]: setApprovalRulesDataReducer,
  [Types.RPM_SET_APPROVAL_RULES]: rpmSetApprovalRulesReducer,
  [Types.RPM_SET_APPROVALS_STATUS_HISTORY]: rpmSetApprovalsStatusHistoryReducer,
  [Types.RPM_SET_IMPACT_ANALYSIS]: rpmSetImpactAnalysisReducer,
  [Types.RPM_SET_MF2_VAL_DATA]: rpmSetMf2ValDataReducer,
}

export default createReducer(INITIAL_STATE, HANDLERS)
