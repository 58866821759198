import React, { forwardRef } from 'react'

import * as fieldValidators from '../../../utils/validationHelpers'
import './styles.scss'
import { Icon } from 'semantic-ui-react'

const validators = {
	code: fieldValidators.validateCode,
	email_address: fieldValidators.validateEmailAddress,
	numeric: fieldValidators.validateNumericAndEmptyField,
	password: fieldValidators.validatePassword,
	phone: fieldValidators.validatePhoneNumber,
	alphaNumeric: fieldValidators.validateAlphaNumeric,
}

const FliptInput = forwardRef((props = {}, ref) => {
	const {
		className,
		datatype,
		label,
		required,
		validation,
		customValidation,
		onChange,
		onBlur,
		icon,
	} = props

	const validateField = (el) => {
		const ct = el.currentTarget

		if (required && !ct.value) {
			ct.classList.add('has-error')
		} else {
			ct.classList.remove('has-error')
		}

		if (validation && validators[validation]) {
			if (!validators[validation](ct.value)) {
				if (validation === 'numeric')
					ct.value = ct.value.replace(/[^0-9.]/g, '')
				ct.classList.add('has-error')
			} else {
				ct.classList.remove('has-error')
			}
		} else if (!!customValidation) {
			if (!customValidation(ct.value)) ct.classList.add('has-error')
			else ct.classList.remove('has-error')
		}
	}

	const _onChange = (el) => {
		if (!onChange || typeof onChange !== 'function') return

		const ct = el.currentTarget

		if (validation && validators[validation]) {
			if (validators[validation](ct.value)) {
				onChange(el)
			}
		} else {
			onChange(el)
		}
	}

	// TO-DO: remove warning: React does not recognize the `inputClassName` prop on a DOM element.
	const { className: containerClassName, ...inputProps } = { ...props }

	if (inputProps.disabled) {
		inputProps.value = inputProps.value || ''
	}

	// TO-DO: make it similar to orignial input form/fliptInput.js. we should only be changing styles
	// and how classes are applied.
	// similar to how we have option to use input.semantic in form/fliptInput.js
	const inputType = !!datatype && datatype === 'numeric' ? 'number' : 'text'

	return (
		<div className={`${containerClassName || ''} v2-flipt-input-container`}>
			{/* use v2/FlipLabel instead?? */}
			<div className="description-text">
				<div className='flipt-label' hidden={inputProps.hidden || false}>
					<label htmlFor={label}>{label}</label>
				</div>
				{icon}
			</div>
			<input
				type={inputType}
				className='v2-flipt-input'
				{...inputProps}
				onKeyUp={validateField}
				onChange={_onChange}
				onBlur={onBlur}
			/>
		</div>
	)
})

export default FliptInput
