import React, { PureComponent } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Creators as AppActions } from '../../../redux/reducers/app'
import MenuCard from '../../../components/menu/card'

class PriorAuthorizationAdministration extends PureComponent {
    _renderMenuCards = () => {
        const { state: { menu } } = this.props
        const data = menu.find((d) => d.id === 'prior-auth-administration').children
        return data.map((m) => <MenuCard key={m.id} menu={m} />)
    }

    render() {
        return (
            <div id="homePage">
                <div id="cardMenuContainer">
                    {this._renderMenuCards()}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    state: {
        app: state.app,
        menu: state.app.menu_data,
    },
})

const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...AppActions,
    }

    return {
        actions: bindActionCreators(allActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PriorAuthorizationAdministration)
