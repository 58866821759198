import React, { useEffect, useRef, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import './styles.scss'
import BreadCrumb from '../../../../components/breadCrumb'
import FliptButton from '../../../../components/form/fliptButton'
import { Creators as AppActions } from '../../../../redux/reducers/app'
import { Creators as PriorAuthorizationCreationActions } from '../../../../redux/reducers/api/priorAuthorizationManagement'
import { Creators as UserActions } from '../../../../redux/reducers/userManagement/index'
import { DropdownRenderer } from '../../../../components/fliptGrid/cellRenderers'
import FliptTable from '../../../../components/fliptTable'

const PADocumentTemplateUpload = (props) => {
  const initialPADocument = {
    document_name: "",
    document_type: "",
    questionnaire_type: "",
    questionnaire_id: "",
  }
  const { actions, state } = props
  const [files, setFiles] = useState([]);
  const [paDocumentObject, setPADocument] = useState(initialPADocument);
  const handleInputChange = (e, dropdown) => {
    const { name, value } = dropdown || e.currentTarget
    setPADocument({
      ...paDocumentObject,
      [name]: value,
    })
  }

  const handleChange = (e, dropdown, rowKey) => { 
    const { name, value } = dropdown || e.currentTarget
    let fileList = [...files]
    for (let i = 0; i < fileList.length; i++) {
      if (i == rowKey) {
        fileList[i][name] = value
      }
    }
    setFiles(fileList)
  }
  const submitUpload = () => {
    actions.uploadPaDocumentTemplate({ file: files })
  };

  const handleFileUpload = (e) => {
    setFiles(Array.from(e.target.files))
  }
  const documentTypeOptions = [
    { key: 0, text: 'Approval Form', value: 'approval_form' },
    { key: 1, text: 'Denial Form', value: 'denial_form' },
    { key: 2, text: 'Void Form', value: 'void_form' },
    { key: 3, text: 'Denial Letter', value: 'denial_letter' },
    { key: 4, text: 'Spanish Denial Letter', value: 'spanish_denial_letter' },
    { key: 5, text: 'Approval Letter', value: 'approval_letter' },
    { key: 6, text: 'Spanish Approval Letter', value: 'spanish_approval_letter' },
    { key: 7, text: 'Fax Cover Sheet', value: 'fax_cover_sheet' },
    { key: 8, text: 'RFI Appeal One Form', value: 'rfi_appeal_one_form' },
    { key: 9, text: 'RFI Appeal Two Form', value: 'rfi_appeal_two_form' },
    { key: 10, text: 'RFI Appeal One Letter', value: 'rfi_appeal_one_letter' },
    { key: 11, text: 'RFI Appeal Two Letter', value: 'rfi_appeal_two_letter' },
    { key: 12, text: 'Cover Letter', value: 'cover_letter' },
    { key: 13, text: 'Approved Appeal Form', value: 'approved_appeal_form' },
    { key: 14, text: 'Denied Appeal One Form', value: 'denied_appeal_one_form' },
    { key: 15, text: 'Denied Appeal Two Form', value: 'denied_appeal_two_form' },
    { key: 16, text: 'Approved Appeal Letter', value: 'approved_appeal_letter' },
    { key: 17, text: 'Denied Appeal One Letter', value: 'denied_appeal_one_letter' },
    { key: 18, text: 'Denied Appeal Two Letter', value: 'denied_appeal_two_letter' },
    { key: 19, text: 'Spanish Appeal Approval Letter', value: 'spanish_appeal_approval_letter' },
    { key: 20, text: 'Spanish RFI Appeal One Letter', value: 'spanish_rfi_appeal_one_letter' },
    { key: 21, text: 'Spanish RFI Appeal Two Letter', value: 'spanish_rfi_appeal_two_letter' },
    { key: 22, text: 'RFI Cover Sheet', value: 'rfi_cover_sheet' },
  ]
  const cellRendererParams = {
    "Document Type": {
      cellRender: DropdownRenderer
    }
  }
  const rowCellInfo = {
    "Document Type": {
      type: "dropdown",
      disabled: false,
      options: documentTypeOptions
    },
    "Document Name": {
      type: "text",
    }
  }
  const uploadedFiles = files.map((file) => {
    return {
      "Document Name": file.name,
      "Document Type": file?.document_type,
      "file": file
    }
  })
  return (
    <div id="importFeature">
      <BreadCrumb {...props} />
      {/* <div className="submenu">
        <SubmenuNav active="questionnaireImport" />
      </div> */}
      <div className="header">PA Template File Upload</div>
      <div className="content">
        <div className="section">
          <section className="uploadInputContainer">
            <div className="uploadInput">
              <span className="element">Upload a Docx</span>
              <input multiple="multiple" type="file" name="file" id="file" accept=".docx, .doc" onChange={handleFileUpload} />
            </div>
            {
              uploadedFiles.length > 0 ? 
              <div id="accountTable">
                <FliptTable
                  data={uploadedFiles}
                  cellRendererParams={cellRendererParams}
                  headers={["Document Name","Document Type"]}
                  rowCellInfo={rowCellInfo}
                  handleChange={handleChange}
                />
              </div>  : ""
            }
            <div className="buttonContainer">
               
              <FliptButton name="Upload" onClick={submitUpload} className="primary searchButton" />
              <FliptButton name="Cancel" className="secondary searchButton" />
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  state: {
    user: state.user,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
    ...PriorAuthorizationCreationActions,
    ...UserActions
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PADocumentTemplateUpload)
