import Immutable from 'seamless-immutable'
import { createActions, createReducer } from 'reduxsauce'

const teamData = {
  id: '',
  description: '',
  name: '',
  active: '',
  effective_end_date: '',
  effective_start_date: '',
  roles: [],
}

export const INITIAL_STATE = Immutable({
  teamData,
  teamListing: [],
  rolesData: [],
})

export const clearReducer = () => INITIAL_STATE

export const clearTeamDataReducer = () => INITIAL_STATE

export const setTeamListDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  teamListing: payload.data,
})

export const setTeamDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  teamData: payload,
})

export const { Types, Creators } = createActions({
  clearTeamData: null,
  clearReducer: null,
  createTeamManagementData: ['payload'],
  editTeamManagementData: ['payload'],
  getTeamData: ['payload'],
  getTeamListData: null,
  setTeamData: ['payload'],
  setTeamListData: ['payload'],
})

const HANDLERS = {
  [Types.CLEAR_TEAM_DATA]: clearTeamDataReducer,
  [Types.CLEAR_REDUCER]: clearReducer,
  [Types.SET_TEAM_DATA]: setTeamDataReducer,
  [Types.SET_TEAM_LIST_DATA]: setTeamListDataReducer,
}

export default createReducer(INITIAL_STATE, HANDLERS)
