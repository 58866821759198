
import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import FliptButton from '../../../components/form/fliptButton'
import FliptDropdown from '../../../components/v2/fliptDropdown'
import FliptInput from '../../../components/v2/fliptInput'
import BreadCrumb from '../../../components/v2/breadCrumb'
import './styles.scss'
import { Creators as AppActions } from '../../../redux/reducers/app'
import { Creators as TimerConfigActions } from '../../../redux/reducers/api/timerConfig'
import FliptGrid from '../../../components/fliptGrid'
import { Icon } from 'semantic-ui-react'
import { alertType, calculateType, daysToReturnInfoType, emptyAlertType, policyType, priorityType, requestType, rmiAlertType } from './data/dataTypes'
import moment from 'moment'
import { DatePickerEditor, DropdownEditor, InputTextEditor } from '../../../components/fliptGrid/cellEditors'
import { parseQueryString } from "../../../utils/utilities"
import rowCellInfo from './data/rowCellInfo'
import { DeleteRowRenderer } from '../../../components/fliptGrid/cellRenderers'
import CAGFilters from '../../../components/CAGFilters'
import { mapValues } from 'lodash'
class TimerConfiguration extends Component {
    constructor(props) {
        super(props)
        this.state = {
            gridData: [],
            searchForm: {
                client: '',
                policy_type: '',
                request_type: '',
                priority: '',
                hours_to_complete: '',
                how_to_calculate_hours: '',
                days_to_return_info: '',
                other_number_of_days: '',
                carrier: '',
                account: '',
                group: '',
            },
            alertData: {
                firstAlert: '',
                secondAlert: '',
                thirdAlert: '',
                firstRmiAlert: '',
                secondRmiAlert: '',
                thirdRmiAlert: ''
            },
            businessCalendar: [],
            clientOptions: [],
            pa_timer_id: '',
            isEdit: false
        }
    }

    componentDidMount() {
        this.setClientOptions()
        const { actions } = this.props
        const pa_timer_id = parseQueryString(this.props.location.search).pa_timer_id
        if (pa_timer_id) {
            const params = {
                pa_timer_id: pa_timer_id
            }
            this.setState({ pa_timer_id: pa_timer_id, isEdit: true })
            actions.getTimerConfiguration(params)
        } else {
            actions.clearTimerConfigData()
        }
    }
    _formatData = (data) => {
        const searchForm = {
            client: data?.client,
            policy_type: data?.policy_type,
            request_type: data?.request_type,
            priority: data?.priority,
            hours_to_complete: data?.hours_to_complete,
            how_to_calculate_hours: data?.how_to_calculate_hours,
            days_to_return_info: data?.days_to_return_info,
            other_number_of_days: data?.other_number_of_days,
            carrier: data?.carrier,
            account: data?.account,
            group: data?.group
        }
        const newState = {
            searchForm: searchForm,
            businessCalendar: data?.business_calendar,
            clientOptions: data?.clientOptions,
            alertData: {
                firstAlert: data?.compliance_warnings?.first_alert_hours,
                secondAlert: data?.compliance_warnings?.second_alert_hours,
                thirdAlert: data?.compliance_warnings?.third_alert_hours,
                firstRmiAlert: data?.rmi_warnings?.first_rmi_alert,
                secondRmiAlert: data?.rmi_warnings?.second_rmi_alert,
                thirdRmiAlert: data?.rmi_warnings?.third_rmi_alert
            }
        }
        return newState

    }
    componentDidUpdate(prevProps) {
        const { state: { saveTimerConfigData }, history } = this.props
        if (prevProps.state.saveTimerConfigData !== saveTimerConfigData) {
            this.setState(this._formatData(saveTimerConfigData))
        }
        // if (prevProps.state.saveTimerConfigData !== saveTimerConfigData && saveTimerConfigData?.pa_timer_id) {
        //     history.goBack()
        // }
    }

    setClientOptions = () => {
        const { state: { user: { userHierarchy } } } = this.props
        let clientOptions = []
        if (userHierarchy && Object.keys(userHierarchy)?.length > 0) {
            clientOptions = Object.values(userHierarchy).map(([companyHierarchy]) => companyHierarchy?.children?.map(client => client.client_name)).flat()
        }
        this.setState({ clientOptions })
    }

    _addData = () => {
        const { searchForm, gridData } = this.state
        const obj = { ...searchForm };
        const newGridData = [...gridData]
        if (obj.days_to_return_info === 'other') obj.days_to_return_info = obj.other_number_of_days
        delete obj.other_number_of_days
        const test = Object.values(obj).every((value) => value !== '')
        if (!test) {
            const header = 'Fields Missing'
            let message = 'Please add All Fields to Add'
            this._showTransitionalPortal(header, message);
            return;
        }
        newGridData.push(obj);
        this.setState({ gridData: newGridData })
    }

    _addRowData = () => {
        let { businessCalendar } = this.state
        if (!businessCalendar) {
            businessCalendar = []
        }
        const obj = {
            day_type: '',
            holiday_description: '',
            holiday_date: '',
            begin_day: '',
            end_day: '',
            begin_time: '',
            end_time: ''
        }
        const newBusinessCalendar = [...businessCalendar, obj]
        this.setState({ businessCalendar: newBusinessCalendar })
    }


    _saveData = () => {
        const { businessCalendar, searchForm } = this.state
        const alertData = mapValues(this.state.alertData, Number)
        const { actions } = this.props
        const header = 'Fields Missing'
        let message = 'Please add SLA information to Proceed'
        if (searchForm.questionnaire_type === 'Global') {
            delete searchForm.carrier
            delete searchForm.account
            delete searchForm.group
        }
        if (!alertData.firstAlert || !alertData.firstRmiAlert) {
            let message = 'Please add Alert Data to Proceed'
            this._showTransitionalPortal(header, message);
            return;
        }
        if (!alertData.firstAlert || !alertData.secondAlert || !alertData.thirdAlert) {
            let message = 'Please add Alert Data to Proceed'
            this._showTransitionalPortal(header, message);
            return;
        }
        if (!alertData.firstRmiAlert || !alertData.secondRmiAlert || !alertData.thirdRmiAlert) {
            let message = 'Please add Alert Data to Proceed'
            this._showTransitionalPortal(header, message);
            return;
        }
        if (alertData.secondAlert && Number(alertData.secondAlert) < Number(alertData.firstAlert)) {
            message = 'Second Alert Time should be larger then First Alert Time'
            this._showTransitionalPortal(header, message)
            return;
        }
        if (alertData.thirdAlert && Number(alertData.thirdAlert) < Number(alertData.secondAlert)) {
            message = 'Third Alert Time should be larger then Second Alert Time'
            this._showTransitionalPortal(header, message)
            return;
        }
        if (alertData.secondRmiAlert && Number(alertData.secondRmiAlert) < Number(alertData.firstRmiAlert)) {
            message = 'Second RFI Alert Time should be larger then First RFI Alert Time'
            this._showTransitionalPortal(header, message)
            return;
        }
        if (alertData.thirdRmiAlert && Number(alertData.thirdRmiAlert) < Number(alertData.secondRmiAlert)) {
            message = 'Third RFI Alert Time should be larger then Second RFI Alert Time'
            this._showTransitionalPortal(header, message)
            return;
        }
        if (searchForm.how_to_calculate_hours === 'Business Hours' && (!businessCalendar || businessCalendar.length == 0)) {
            message = 'Please add Business calendar to save For Business hours'
            this._showTransitionalPortal(header, message);
            return;
        }
        let formattedBusinessCalendar = []
        if (businessCalendar?.length) {
            formattedBusinessCalendar = this.formatBusinessCalendar(businessCalendar)
        }
        if (businessCalendar?.length && !formattedBusinessCalendar?.length) return;

        const params = {
            ...searchForm,
            days_to_return_info: Number(searchForm.days_to_return_info),
            compliance_warnings: {
                first_alert_hours: alertData.firstAlert,
                second_alert_hours: alertData.secondAlert,
                third_alert_hours: alertData.thirdAlert
            },
            rmi_warnings: {
                first_rmi_alert: alertData.firstRmiAlert,
                second_rmi_alert: alertData.secondRmiAlert,
                third_rmi_alert: alertData.thirdRmiAlert
            },
            business_calendar: this.formatBusinessCalendar(businessCalendar || [])
        }
        if (this.props.location?.state?.editMode) {
            params.pa_timer_id = this.state.pa_timer_id
        }
        actions.submitTimerConfigData(params)
    }

    _goBack = () => {
        const { props: { history } } = this
        history.goBack()
    }

    formatBusinessCalendar = (businessCalendar) => {
        const finalArray = []
        const header = 'Fields Missing'
        let message = ''
        businessCalendar.map((data) => {
            if (!data.day_type) {
                message = 'Please Add Day Type to Proceed'
                this._showTransitionalPortal(header, message);
                return;
            }
            if (data.day_type === 'holiday') {
                if (!data.holiday_description
                    || !data.holiday_date) {
                    message = 'Please Add Holiday Description and Holiday Date to Add Holiday'
                    this._showTransitionalPortal(header, message);
                    return;
                } else {
                    finalArray.push({
                        day_type: data.day_type, holiday_date: data.holiday_date,
                        holiday_description: data.holiday_description
                    })
                }
            } else if (data.day_type === 'work_day') {
                if (!data.begin_day || !data.end_day
                    || !data.begin_time || !data.end_time) {
                    message = 'Please Add Begin & End Date and Time to Add Work Day'
                    this._showTransitionalPortal(header, message);
                    return;
                } else {
                    finalArray.push({
                        day_type: data.day_type, begin_time: data.begin_time,
                        end_time: data.end_time, begin_day: data.begin_day,
                        end_day: data.end_day
                    })
                }
            }
        })
        return finalArray;
    }

    _showTransitionalPortal = (header, message) => {
        const { props } = this;
        const transitionalPortal = {
            header: header,
            copy: message,
        }
        props.actions.displayTransitionalPortal(transitionalPortal)
    }


    clear() {
    }

    _updateSearchForm = (el, dropdown) => {
        const { searchForm } = this.state
        const { name, value } = dropdown || el.currentTarget
        if (name === 'policy_type' && value === 'Global') {
            searchForm.account = ''
            searchForm.carrier = ''
            searchForm.group = ''
        }
        if (name === 'hours_to_complete') this._clearAlert()
        if (name === 'days_to_return_info' || name === 'other_number_of_days') this._clearRmiAlert()
        searchForm[name] = value
        this.setState({ searchForm })
    }

    _updateAlertData = (el, dropdown) => {
        const { alertData, searchForm } = this.state
        const { name, value } = dropdown || el.currentTarget
        const header = 'Field Error'
        let message = ''
        if (name === 'firstAlert') this._clearAlert()
        if (name === 'firstRmiAlert') this._clearRmiAlert()
        if (value && (name === 'firstAlert' || name === 'secondAlert' || name === 'thirdAlert') && value > parseInt(searchForm.hours_to_complete)) {
            message = 'Alert Time should be less then Hours To Complete'
            this._showTransitionalPortal(header, message)
            return;
        }
        if (value && (name === 'firstRmiAlert' || name === 'secondRmiAlert' || name === 'thirdRmiAlert') && value > parseInt(searchForm.days_to_return_info)) {
            message = 'RFI Alert Time should be less then RFI Days to Return Information'
            this._showTransitionalPortal(header, message)
            return;
        }

        alertData[name] = value
        this.setState({ alertData })
    }

    _clearAlert = () => {
        const { alertData } = this.state
        alertData.secondAlert = ''
        alertData.thirdAlert = ''
        this.setState({ alertData })

    }
    _clearRmiAlert = () => {
        const { alertData } = this.state
        alertData.secondRmiAlert = ''
        alertData.thirdRmiAlert = ''
        this.setState({ alertData })
    }

    handleChange = (el, dropdown, rowIndex, gridApi) => {
        const { businessCalendar } = this.state
        const { name, value } = dropdown || el.currentTarget

        if (name === 'day_type' && value === 'work_day') {
            businessCalendar[rowIndex].holiday_date = ''
            businessCalendar[rowIndex].holiday_description = ''
        }
        if (name === 'day_type' && value === 'holiday') {
            businessCalendar[rowIndex].begin_day = ''
            businessCalendar[rowIndex].end_day = ''
            businessCalendar[rowIndex].begin_time = ''
            businessCalendar[rowIndex].end_time = ''
        }
        businessCalendar[rowIndex][name] = value
        this.setState({ businessCalendar })
        gridApi.refreshCells()
    }

    delGridRow = (rowIndex) => {
        const { gridData } = this.state
        const newGridData = [...gridData]
        newGridData.splice(rowIndex, 1)
        this.setState({ gridData: newGridData })
    }

    delCalendarRow = (rowIndex) => {
        const { businessCalendar } = this.state
        const newBusinessCalendar = [...businessCalendar]
        newBusinessCalendar.splice(rowIndex, 1)
        this.setState({ businessCalendar: newBusinessCalendar })
    }


    render() {
        const headers = ['policy_type', 'client', 'request_type', 'priority', 'hours_to_complete', 'how_to_calculate_hours',
            'days_to_return_info', 'action'];
        const calendarHeader = ['day_type', 'holiday_description', 'holiday_date', 'begin_day', 'end_day',
            'begin_time', 'end_time', 'action']
        const cellEditorParams = {
            day_type: {
                editable: true,
                cellEditor: DropdownEditor,
                onChange: this.handleChange,
            },
            begin_day: {
                editable: (params) => params.data.day_type == 'work_day',
                cellEditor: DropdownEditor,
                onChange: this.handleChange,
            },
            end_day: {
                editable: (params) => params.data.day_type == 'work_day',
                cellEditor: DropdownEditor,
                onChange: this.handleChange,
            },
            holiday_description: {
                editable: (params) => params.data.day_type == 'holiday',
                cellEditor: InputTextEditor,
                onChange: this.handleChange,
            },
            holiday_date: {
                editable: (params) => params.data.day_type == 'holiday',
                cellEditor: DatePickerEditor,
                onChange: this.handleChange,
            },
            begin_time: {
                editable: (params) => params.data.day_type == 'work_day',
                cellEditor: InputTextEditor,
                onChange: this.handleChange,
            },
            end_time: {
                editable: (params) => params.data.day_type == 'work_day',
                cellEditor: InputTextEditor,
                onChange: this.handleChange,
            }
        }
        const cellRendererParams = {
            action: {
                cellRenderer: DeleteRowRenderer,
                state: {
                    onClick: this.delGridRow,
                },
                width: 95,
            },
            days_to_return_info: {
                overrideHeader: 'RFI Days To Return Info'
            },
            begin_day: {
                overrideHeader: 'Begin Day'
            }
        }
        const calendarCellRendererParams = {
            action: {
                cellRenderer: DeleteRowRenderer,
                state: {
                    onClick: this.delCalendarRow,
                },
                width: 95,
            },
        }
        const { gridData, searchForm, businessCalendar, alertData, clientOptions } = this.state


        const currentYear = moment().year();


        // rowCellInfo.begin_day.disabled = rowCellInfo.day_type === 'work_day' ? false : true
        let customBreadcrumbId = this.state.isEdit ? "Timer Configuration" : "Create New Timer Configuration"
        return (
            <div id="timerConfig" >
                <BreadCrumb {...this.props} customId={customBreadcrumbId} customBcPath="/timer-configuration" />
                <div className="header">
                    Timer Configuration Details
                </div>
                <div className="border-box">
                    <div className="inner-fields-container">
                        {/* <FliptDropdown value={searchForm.client} stylized name="client" onChange={this._updateSearchForm} options={clientOptions} label="Client" /> */}
                        <FliptDropdown value={searchForm.policy_type} stylized name="policy_type" onChange={this._updateSearchForm} options={policyType} label="Timer/SLA Policy" />
                        <FliptDropdown value={searchForm.request_type} stylized name="request_type" onChange={this._updateSearchForm} options={requestType} label="Type Of Request" />
                        <FliptDropdown value={searchForm.priority} stylized name="priority" onChange={this._updateSearchForm} options={priorityType} label="Priority of request" />
                        {searchForm.days_to_return_info === 'other' ? <FliptInput value={searchForm.other_number_of_days} stylized
                            validation='numeric' name="other_number_of_days" onChange={this._updateSearchForm} placeholder="Enter" label="Other Number of Days" /> : null}
                    </div>
                    {
                        searchForm.policy_type == "Custom" ?
                            <CAGFilters questionnaire className='questionnaire-cag' selectedCarrier={searchForm.carrier} selectedAccount={searchForm.account} selectedGroup={searchForm.group} onFilterChange={this._updateSearchForm} /> : ""
                    }
                    <div className="inner-fields-container">
                        <FliptInput validation='numeric' value={searchForm.hours_to_complete} stylized name="hours_to_complete" onChange={this._updateSearchForm} placeholder="Enter" label="Hours to Complete" />
                        <FliptDropdown value={searchForm.how_to_calculate_hours} stylized name="how_to_calculate_hours" onChange={this._updateSearchForm} options={calculateType} label="How to Calculate Hours" />
                        <FliptInput validation='numeric' value={searchForm.days_to_return_info} stylized name="days_to_return_info" onChange={this._updateSearchForm} label="RFI Days to Return Information" />
                    </div>
                </div>
                <div className="header">
                    Compliance Warning Information
                </div>
                <div className="border-box">
                    <div className="inner-fields-container">
                        <FliptInput validation='numeric' value={alertData.firstAlert} stylized name="firstAlert" onChange={this._updateAlertData} label="First Alert Hours" />
                        <FliptInput validation='numeric' value={alertData.secondAlert} stylized name="secondAlert" onChange={this._updateAlertData} label="Second Alert Hours" />
                        <FliptInput validation='numeric' value={alertData.thirdAlert} stylized name="thirdAlert" onChange={this._updateAlertData} label="Third Alert Hours" />
                    </div>
                </div>
                <div className="header">
                    RFI Warning Information
                </div>
                <div className="border-box">
                    <div className="inner-fields-container">
                        <FliptInput validation='numeric' value={alertData.firstRmiAlert} stylized name="firstRmiAlert" onChange={this._updateAlertData} label="RFI First Alert" />
                        <FliptInput validation='numeric' value={alertData.secondRmiAlert} stylized name="secondRmiAlert" onChange={this._updateAlertData} label="RFI Second Alert" />
                        <FliptInput validation='numeric' value={alertData.thirdRmiAlert} stylized name="thirdRmiAlert" onChange={this._updateAlertData} label="RFI Third Alert" />
                    </div>
                </div>
                <div className="header">
                    Business Calendar
                    <div className='icon-header'>
                        <Icon name='calendar alternate' size="small" color='grey' />
                        <div className='calendar-header'> {currentYear} Calendar</div>
                    </div>
                </div>
                <div className="outer-grid-container">
                    <div className="grid-container">
                        <FliptGrid
                            headers={calendarHeader}
                            data={businessCalendar}
                            cellEditorParams={cellEditorParams}
                            rowCellInfo={rowCellInfo}
                            cellRendererParams={calendarCellRendererParams}
                        />
                    </div>
                    <div className="buttons-container">
                        <FliptButton className='primary' name='ADD ROW' onClick={() => this._addRowData()} />
                    </div>

                </div>

                <div className="buttons-container margin-bottom">
                    <FliptButton className='primary' name='SAVE' onClick={() => this._saveData()} />
                    <FliptButton className='primary' name='BACK' onClick={() => this._goBack()} />
                </div>
            </div >

        )
    }
}

const mapStateToProps = (state) => ({
    state: {
        user: state.user,
        saveTimerConfigData: state.timerConfig.saveTimerConfigData
    }
})

const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...AppActions,
        ...TimerConfigActions
    }

    return {
        actions: bindActionCreators(allActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TimerConfiguration)
