import Immutable from 'seamless-immutable'
import { createActions, createReducer } from 'reduxsauce'

export const INITIAL_STATE = Immutable({
    durSummaryData: [],
    durData: {},
    programDashboardData: [],
    classOptionData: [],
    programOptionData: [],
    selectedTab: ''
})

export const setDurSummaryReducer = (state = INITIAL_STATE, { payload }) => ({
    ...state,
    durSummaryData: payload.data
})

export const setSelectedTabReducer = (state = INITIAL_STATE, { payload }) => ({
    ...state,
    selectedTab: payload
})



export const setClassOptionDataReducer = (state = INITIAL_STATE, { payload }) => ({
    ...state,
    classOptionData: payload
})
export const setGlobalDurDataReducer = (state = INITIAL_STATE, { payload }) => ({
    ...state,
    programDashboardData: payload
})
export const setProgramDataReducer = (state = INITIAL_STATE, { payload }) => ({
    ...state,
    programOptionData: payload
})


export const setDurReducer = (state = INITIAL_STATE, { payload }) => ({
    ...state,
    durData: payload.data
})

export const clearDurData = (state = INITIAL_STATE, { payload }) => ({
    ...state,
    durData: {}
})

export const clearReducer = () => INITIAL_STATE

export const { Types, Creators } = createActions({
    setDurSummaryData: ['payload'],
    getDurSummaryData: ['payload'],
    setDurData: ['payload'],
    getDurData: ['payload'],
    putDurData: ['payload'],
    createNewDurVersion: ['payload'],
    getClassOption: ['payload'],
    getProgramInfo: ['payload'],
    getGlobalDur: ['payload'],
    editGlobalDur: ['payload'],
    publishGlobalDur: ['payload'],
    createNewGlobalDur: ['payload'],
    addGlobalDur: ['payload'],
    publishDurDoc: ['payload'],
    setClassOptionData: ['payload'],
    setGlobalDurData: ['payload'],
    setProgramData: ['payload'],
    setSelectedTab: ['payload'],
    clearDurData: null,
    clearReducer: null,
})

const HANDLERS = {
    [Types.SET_DUR_SUMMARY_DATA]: setDurSummaryReducer,
    [Types.SET_SELECTED_TAB]: setSelectedTabReducer,
    [Types.SET_CLASS_OPTION_DATA]: setClassOptionDataReducer,
    [Types.SET_GLOBAL_DUR_DATA]: setGlobalDurDataReducer,
    [Types.SET_PROGRAM_DATA]: setProgramDataReducer,
    [Types.SET_DUR_DATA]: setDurReducer,
    [Types.CLEAR_DUR_DATA]: clearDurData,
    [Types.CLEAR_REDUCER]: clearReducer,
}

export default createReducer(INITIAL_STATE, HANDLERS)