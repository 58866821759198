import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import Conditions from "./Conditions";
import _ from "lodash";

import { Creators as AppActions } from "../../../../../../redux/reducers/app";

import { qualifierType, qualifierOperator } from "../../data/qualifier";

class Transformation extends Component {
  constructor(props) {
    // console.log("props.predefined", props.predefined);
    super(props);
    this.state = {
      paQualifierLevels: [
        [
          {
            action: "",
            standard: "",
            qualifier: "",
            input_value: "",
          },
        ],
      ],
      qualifierDropdownValues: [
        [
          {
            standard: props.predefined.allowed_values,
            qualifier: qualifierOperator,
          },
        ],

      ],
    };
  }

  componentDidMount() {
    const qualifier = this.props.predefined.qualifier || [];
    if (!qualifier.length) return;
    const qualifierOption = {
      standard: this.props.predefined.allowed_values,
      qualifier: qualifierOperator,
    };
    const qualifierLevels = [];
    qualifierLevels[0] = qualifier.map((q) => ({ action: "", ...q }))
    qualifierLevels[0].push({
      action: "",
      standard: "",
      qualifier: "",
      input_value: "",
    })
    const qualifierDropdownValues = [];
    qualifierDropdownValues[0] = qualifierLevels[0].map(() => qualifierOption);

    this.setState({ paQualifierLevels: qualifierLevels, qualifierDropdownValues });
  }

  _handleQualifierChange = (
    el,
    dropdown,
    rowIndex,
    gridApi,
    paConditionLevel
  ) => {
    const { state } = this;
    const paQualifierLevels = [...state.paQualifierLevels];
    const rowToUpdate = paQualifierLevels[paConditionLevel][rowIndex];
    const { name, value } = dropdown || el.currentTarget;
    rowToUpdate[name] = value;
    paQualifierLevels[paConditionLevel][rowIndex] = rowToUpdate;

    const qualifierDropdownValues = [...state.qualifierDropdownValues];
    const dropDownRowToUpdate =
      qualifierDropdownValues[paConditionLevel][rowIndex];

    // if (name === "pa_qualifier") {
    //   dropDownRowToUpdate.pa_operator = qualifierOperator;
    //   qualifierDropdownValues[paConditionLevel][rowIndex] = dropDownRowToUpdate;
    // }
    this.setState({
      paQualifierLevels,
      dropDownRowToUpdate,
    });
    gridApi.refreshCells();
  };

  _delQualifierRow = (rowIndex, paConditionLevel) => {
    const { paQualifierLevels } = this.state;
    const paQualifierLevelToUpdate = [...paQualifierLevels[paConditionLevel]];
    if (paQualifierLevelToUpdate.length <= 1) return;

    const newPAQualifierLevel = paQualifierLevelToUpdate.filter(
      (cond) => paQualifierLevelToUpdate.indexOf(cond) !== rowIndex
    );
    paQualifierLevels[paConditionLevel] = newPAQualifierLevel;

    this.setState({
      paQualifierLevels,
    });
  };

  _addQualifierRow = (level) => {
    const { paQualifierLevels, qualifierDropdownValues } = this.state;
    const updatedPAQualifierLevels = paQualifierLevels[level].concat(
      Object.keys(paQualifierLevels[0][0]).reduce((acc, header) => {
        acc[header] = "";
        return acc;
      }, {})
    );

    const updatedDropdownValues = qualifierDropdownValues[level].concat(
      Object.keys(qualifierDropdownValues[0][0]).reduce((acc, header) => {
        if (header === "qualifier") {
          acc[header] = qualifierOperator;
        } else if (header === "standard") {
          acc[header] = this.props.predefined.allowed_values;
        } else {
          acc[header] = [];
        }
        return acc;
      }, {})
    );

    const paQualifierLevelsToBeSet = paQualifierLevels[0].reduce((list, item, index) => {
      if (item.standard && item.qualifier && item.input_value) {
        list.push({
          standard: item.standard,
          qualifier: item.qualifier,
          input_value: item.input_value,
        })
      }
      return list;
    }, [])

    this.props.setQualifier(this.props.predefined, paQualifierLevelsToBeSet);
    paQualifierLevels[level] = updatedPAQualifierLevels;
    qualifierDropdownValues[level] = updatedDropdownValues;
    this.setState({
      paQualifierLevels,
      qualifierDropdownValues,
    });
  };

  _mapValueToOption = (row) => {
    const data = {
      ...row,
    };
    Object.keys(data).forEach((col) => {
      if (Array.isArray(data[col])) {
        data[col] = data[col].map((group, index) => ({
          key: index,
          text: group,
          value: group,
        }));
      }
    });
    return data;
  };

  _constructQualifierDropdownOptions = () =>
    this.state.qualifierDropdownValues.map((dropdownValueLevel) =>
      dropdownValueLevel.map((row) => this._mapValueToOption(row))
    );

  render() {
    const { state } = this;
    const { paQualifierLevels } = state;
    const { open, onClose, predefined } = this.props;

    const qualifierDropdownOptions = this._constructQualifierDropdownOptions();
    return (
      <div>
        <Conditions
          delQualifierRow={this._delQualifierRow}
          addQualifierRow={this._addQualifierRow}
          handleQualifierChange={this._handleQualifierChange}
          qualifierDropdownOptions={qualifierDropdownOptions}
          paQualifierLevels={paQualifierLevels}
          open={open}
          onClose={onClose}
          predefined={predefined.allowed_values}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  state: {},
});

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
  };

  return {
    actions: bindActionCreators(allActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Transformation);