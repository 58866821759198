import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import './styles.scss'
import BreadCrumb from '../../../../components/breadCrumb'
import ClaimConfirmModal from './claimConfirmModal'
import Config from '../../../../config'
import FliptButton from '../../../../components/form/fliptButton'
import FliptInput from '../../../../components/form/fliptInput'
import { Creators as ApiActions } from '../../../../redux/reducers/api/claimLookupManagement'
import { Creators as AppActions } from '../../../../redux/reducers/app'
import { Creators as ManualClaimAdjustmentActions } from '../../../../redux/reducers/api/manualClaimAdjustment'
import { Creators as MemberLookupActions } from '../../../../redux/reducers/api/memberLookup'

class ClaimDetails extends Component {
  constructor(props) {
    super(props)
    const {
      location:
      {
        state:
        {
          auth_id, transfer_status, client_drug_cost_paid, client_employer_cost, client_employee_paid,
          deductible_accumulator_amount, deductible_remaining, oop_accumulator_amount, pharmacy_name,
          sequenceNumber, transactionId, out_of_pocket_remaining, group_id, member_id, domain_name
        },
      },
    } = this.props
    this.state = {
      claimDetailData: {
        adj_ded_accum_amt: deductible_accumulator_amount || '',
        adj_oop_accum_amt: oop_accumulator_amount || '',
        adj_patient_pay: client_employee_paid || '',
        adjustment_reason: '0',
        auth_id: auth_id || '',
        claim_status: transfer_status || '',
        client_drug_cost_paid: client_drug_cost_paid || '',
        client_employee_paid: client_employee_paid || '',
        client_employer_cost: client_employer_cost || '',
        deductible_accumulator_amount: deductible_accumulator_amount || '',
        deductible_remaining: deductible_remaining || '',
        oop_accumulator_amount: oop_accumulator_amount || '',
        out_of_pocket_remaining: out_of_pocket_remaining || '',
        pharmacy_name: pharmacy_name || '',
        reimbursement_amount: '',
        sequenceNumber: sequenceNumber || '',
        transactionId: transactionId || '',
        domain_name: domain_name
      },
      group_id: group_id || '',
      cardholder_id: member_id || '',
      form: {
        attribute_to_deductible: '',
        attribute_to_oop: '',
        patient_pay_amount: '',
      },
      selected: true,
      showModal: false,
      params: null,
      headers: null,
      headerMapping: null,
    }
  }

  componentDidMount() {
    // TODO FIX THIS. SO NO MORE SETTIMEOUT
    const { actions: { getClaimDetailData, fetchMemberLookupData, getClaimLookupManagementData } } = this.props
    const {
      form, params, headers, headerMapping, cardholder_id,
    } = this.state
    setTimeout(() => {
      getClaimDetailData()
      fetchMemberLookupData()
      getClaimLookupManagementData({
        form, params, headers, headerMapping, cardholder_id, isAdjustment: true,
      })
    }, Config.COMPONENT_DATA_LOAD_TIME)
  }

  componentDidUpdate(prevProps) {
    const { state } = this.props
    const updatedCalculationData = prevProps.state.adjustmentCalculationData !== state.adjustmentCalculationData
    const adjustClaim = prevProps.state.adjustClaim !== state.adjustClaim
    const reverseClaim = prevProps.state.reverseClaim !== state.reverseClaim
    const reprocessClaimData = prevProps.state.reprocessClaimData !== state.reprocessClaimData
    if (updatedCalculationData) this._updateCalculationData()
    if (adjustClaim || reverseClaim || reprocessClaimData) this._updateClamData()
  }

  _updateClamData = () => {
    const { claimDetailData: { auth_id } } = this.state
    const { history } = this.props
    const path = `claim-detail?auth_id=${auth_id}`
    const { actions: { clearDataReducer } } = this.props

    history.push(path)
    clearDataReducer()
  }

  _updateCalculationData = () => {
    const { claimDetailData, form } = this.state
    const { state: { adjustmentCalculationData } } = this.props
    const {
      attribute_to_deductible,
      attribute_to_oop,
      patient_pay_amount,
      reimbursement_amount,
    } = adjustmentCalculationData

    this.setState({
      claimDetailData: {
        ...claimDetailData,
        ...adjustmentCalculationData,
        reimbursement_amount: Math.abs(reimbursement_amount),
      },
      form: {
        ...form,
        attribute_to_deductible,
        attribute_to_oop,
        patient_pay_amount,
      },
      showModal: true,
    })
  }

  _closeModal = () => {
    this.setState({ showModal: false })
  }

  _adjustClaim = () => {
    const { actions: { adjustClaimProcessData }, location: { state } } = this.props
    const {
      claimDetailData: {
        adj_ded_accum_amt,
        adj_oop_accum_amt,
        adj_patient_pay,
        auth_id,
        claim_status,
        client_drug_cost_paid,
        client_employee_paid,
        client_employer_cost,
        deductible_accumulator_amount,
        deductible_remaining,
        oop_accumulator_amount,
        out_of_pocket_remaining,
        sequenceNumber,
      },
    } = this.state
    const data = {
      adj_ded_accum_amt: adj_ded_accum_amt || deductible_accumulator_amount || '',
      adj_oop_accum_amt: adj_oop_accum_amt || oop_accumulator_amount || '',
      adj_patient_pay: adj_patient_pay || client_employee_paid || '',
      auth_id: auth_id || '',
      claim_status: claim_status || '',
      ded_accum_amt: deductible_accumulator_amount || '',
      ded_remaining: deductible_remaining || '',
      drug_cost: client_drug_cost_paid || '',
      flipt_person_id: state?.cardholder_id?.substring(0, 7) || '',
      oop_accum_amt: oop_accumulator_amount || '',
      oop_remaining: out_of_pocket_remaining || '',
      patient_paid: client_drug_cost_paid || '',
      plan_paid: client_employer_cost || '',
      sequence_number: sequenceNumber || '',
    }

    adjustClaimProcessData(data)
  }

  _calculateAdjustment = () => {
    const { actions: { getAdjustmentCalculation }, location: { state } } = this.props
    const {
      claimDetailData: {
        adj_ded_accum_amt,
        adj_oop_accum_amt,
        adj_patient_pay,
        auth_id,
        claim_status,
        client_drug_cost_paid,
        client_employee_paid,
        client_employer_cost,
        deductible_accumulator_amount,
        deductible_remaining,
        oop_accumulator_amount,
        out_of_pocket_remaining,
        sequenceNumber,
      },
    } = this.state
    const data = {
      adj_ded_accum_amt: adj_ded_accum_amt || deductible_accumulator_amount || '',
      adj_oop_accum_amt: adj_oop_accum_amt || oop_accumulator_amount || '',
      adj_patient_pay: adj_patient_pay || client_employee_paid || '',
      auth_id: auth_id || '',
      claim_status: claim_status || '',
      ded_accum_amt: deductible_accumulator_amount || '',
      ded_remaining: deductible_remaining || '',
      drug_cost: client_drug_cost_paid || '',
      flipt_person_id: state?.cardholder_id?.substring(0, 7) || '',
      oop_accum_amt: oop_accumulator_amount || '',
      oop_remaining: out_of_pocket_remaining || '',
      patient_paid: client_employee_paid || '',
      plan_paid: client_employer_cost || '',
      sequence_number: sequenceNumber || '',
    }

    getAdjustmentCalculation(data)
  }

  _updateForm = (e) => {
    const { claimDetailData } = this.state
    const { name, value } = e.target

    this.setState({
      claimDetailData: {
        ...claimDetailData,
        [name]: value,
      },
    })
  }

  _reverseClaim = () => {
    const { props, state } = this
    const {
      auth_id,
      transactionId,
    } = state.claimDetailData

    props.actions.postPaidClaimReversal({
      auth_id,
      transaction_id: transactionId,
    })
    props.history.push({
      pathname: 'claim-detail',
      search: `?auth_id=${auth_id}`,
    })
  }

  _reprocessClaim = () => {
    const { actions, state: { claimPersonName } } = this.props
    const {
      auth_id,
    } = this.state.claimDetailData
    const params = {
      auth_id,
      patient_f_name: claimPersonName[0]?.first_name,
      patient_l_name: claimPersonName[0]?.last_name,
      date_of_birth: claimPersonName[0]?.date_of_birth,
    }
    actions.getReprocessClaim(params)
  }


  _renderMemberDetails = () => {
    const { state } = this.props
    const {
      flipt_person_id,
      person_code,

    } = state.memberLookup.member_details

    const {
      group_id, cardholder_id, domain_name
    } = this.state
    const {
      claimDetailData: {
        auth_id },
    } = this.state
    return (
      <div className="section">
        <h2>Member Details</h2>
        <div className="fields-container">
          <div className="fields">
            <FliptInput value={domain_name} label="Account" stylized disabled />
            <FliptInput value={`${state?.claimPersonName[0]?.first_name || ''} ${state?.claimPersonName[0]?.last_name || ''}`} label="Name" stylized disabled />
          </div>
          <div className="fields">
            <FliptInput value={group_id} label="Group" stylized disabled />
            <FliptInput value={state?.claimPersonName[0]?.date_of_birth} label="DOB" stylized disabled />
          </div>
          <div className="fields">
            <FliptInput value={`${flipt_person_id || ''}${person_code || ''}${cardholder_id || ''}`} label="Member ID" stylized disabled />
          </div>
        </div>
      </div>
    )
  }

  _renderClaimDetail = () => {
    const {
      claimDetailData,
      selected,
    } = this.state
    const {
      adj_ded_accum_amt,
      adj_oop_accum_amt,
      adj_patient_pay,
      adjustment_reason,
      auth_id,
      claim_status,
      client_drug_cost_paid,
      client_employer_cost,
      deductible_remaining,
      out_of_pocket_remaining,
      pharmacy_name,
      reimbursement_amount,
      sequenceNumber,
    } = claimDetailData
    const isReversal = !selected

    return (
      <div className="section">
        <h2>Claim Details</h2>
        <div className="fields-container">
          <div className="fields">
            <FliptInput
              value={auth_id}
              label="Auth ID"
              stylized
              disabled
            />
            <FliptInput
              value={sequenceNumber}
              label="Sequence Number"
              stylized
              disabled
            />
            <FliptInput
              value={reimbursement_amount}
              label="Reimbursement"
              stylized
              className="currency"
              name="reimbursement_amount"
              disabled
            />
            <FliptInput
              value={out_of_pocket_remaining}
              label="Out of Pocket Remaining"
              stylized
              className="currency"
              name="out_of_pocket_remaining"
              disabled
            />
            <FliptInput
              value={adj_ded_accum_amt}
              label="Attribute to Deductible"
              name="adj_ded_accum_amt"
              onChange={this._updateForm}
              stylized
              disabled={isReversal}
            />
          </div>
          <div className="fields">
            <FliptInput
              value={claim_status}
              label="Claim Status"
              stylized
              disabled
            />
            <FliptInput
              value={Math.abs(client_drug_cost_paid)}
              label="Drug Cost"
              stylized
              className="currency"
              disabled
            />
            <FliptInput
              value={Math.abs(client_employer_cost)}
              label="Plan Pays"
              stylized
              className="currency"
              disabled
            />
            <FliptInput
              value={adj_oop_accum_amt}
              label="Attribute to OOP"
              onChange={this._updateForm}
              name="adj_oop_accum_amt"
              stylized
              disabled={isReversal}
            />
          </div>
          <div className="fields">
            <FliptInput
              value={pharmacy_name}
              label="Pharmacy"
              stylized
              disabled
            />
            <FliptInput
              value={adj_patient_pay}
              onChange={this._updateForm}
              label="Members Paid"
              stylized
              className="currency"
              name="adj_patient_pay"
              disabled={isReversal}
            />
            <FliptInput
              value={adjustment_reason}
              onChange={this._updateForm}
              label="Reason for Adjustment"
              stylized
              name="adjustment_reason"
            />
            <FliptInput
              value={Math.abs(deductible_remaining)}
              label="Deductible Remaining"
              stylized
              className="currency"
              disabled
            />
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { state: { memberLookup } } = this.props
    const { selected } = this.state
    const { showModal } = this.state
    const isReversal = !selected
    let calcButton = () => this._calculateAdjustment()
    let calcButtonName = 'Calculate Adjustment Amounts'
    if (isReversal) {
      calcButton = this._reverseClaim
      calcButtonName = 'Reverse Claim'
    }

    const { state: { manualClaimAdjustment: { adjustmentCalculationData } } } = this.props
    return (
      <div id="claimDetails">
        <BreadCrumb {...this.props} />
        <div className="header">Adjustment</div>
        <div>
          <FliptButton
            onClick={() => this.setState({
              selected: true,
            })}
            className={!isReversal ? 'primary' : ''}
            name="Adjust Claim"
          />
          <FliptButton
            onClick={() => this.setState({
              selected: false,
            })}
            className={isReversal ? 'primary' : ''}
            name="Reverse Claim"
          />
          <FliptButton
            onClick={() => this._reprocessClaim()}
            stylized
            className='primary reprocessbtn'
            name="Reprocess Claim"
          />
        </div>
        <div className="content">
          {!!memberLookup.member_details && this._renderMemberDetails()}
          {this._renderClaimDetail()}
          <div className="buttons-container">
            <FliptButton
              onClick={calcButton}
              name={calcButtonName}
              className="primary calcbtn"
            />
          </div>
        </div>
        {
          showModal && (
            <ClaimConfirmModal
              closeModal={this._closeModal}
              calculateAdjustmentsData={adjustmentCalculationData}
              adjustmentbtn={this._adjustClaim}
              openSuccessModal={this._openSuccessModal}
            />
          )
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  state: {
    adjustClaim: state.manualClaimAdjustment.adjustClaim,
    adjustmentCalculationData: state.manualClaimAdjustment.adjustmentCalculationData,
    admin_email: state.user.email_address,
    admin_id: state.user.id,
    claimLookup: state.claimLookup,
    claimPersonName: state.claimLookup.claimPersonName,
    manualClaimAdjustment: state.manualClaimAdjustment,
    memberLookup: state.memberLookup,
    reverseClaim: state.manualClaimAdjustment.reverseClaim,
    reprocessClaimData: state.manualClaimAdjustment.reprocessClaimData,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
    ...ApiActions,
    ...MemberLookupActions,
    ...ManualClaimAdjustmentActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClaimDetails)
