import React, { useState, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Creators as ClaimsPRPActions } from '../../../../redux/reducers/api/claimsProcessingAndReprocessing'
import { Creators as AppActions } from '../../../../redux/reducers/app'

import FliptGrid from '../../../../components/fliptGrid'
import FliptHierarchyDropdown from '../../../../components/v2/fliptHierarchyDropdown'
import FliptDropdown from '../../../../components/v2/fliptDropdown'
import './styles.scss'
import FliptButton from '../../../../components/v2/fliptButton'

const UM_OBJECTS = [
  {
    label: "Program Edits",
    stateKey: "programEdits",
    name: "programs",
  },
  {
    label: "Prior Authorization Drug List",
    stateKey: "priorAuths",
    name: "clinical_pa",
  },
  {
    label: "Quantity Limit",
    stateKey: "quantityLimits",
    name: "clinical_ql",
  },
  {
    label: "Step Therapy Edits",
    stateKey: "stepTherapies",
    name: "clinical_step_therapy",
  },
  {
    label: "Formulary Tiers",
    stateKey: "tiers",
    name: "tiers",
  },
]

const defaultHeaders = ['plan_name', 'plan_version', 'status', 'account', 'group']

const EMPTY_UM_OBJS = {
  programs: { value: [] },
  clinical_pa: { value: [] },
  clinical_ql: { value: [] },
  clinical_step_therapy: { value: [] },
  tiers: { value: [] },
}

const UMWhereUsedLookup = (props) => {
  const { actions, state } = props
  const { umObjects, plans } = state.claimsProcessingAndReprocessing

  const [hierarchyForm, setHierarchyForm] = useState({})
  const [selectedUMs, setSelectedUMs] = useState({ ...EMPTY_UM_OBJS })

  useEffect(() => {
    return () => {
      actions.clearUmObjects()
      actions.clearPlans()
    }
  }, [])

  const handleHierarchyChange = (newHierarchyForm) => {
    actions.clearUmObjects()
    setHierarchyForm(newHierarchyForm)
    setSelectedUMs({ ...EMPTY_UM_OBJS })
  }

  const getUmObjects = () => {
    const cag = { ...hierarchyForm.hierarchyIDs }
    actions.getUmObjects({ cag, status: ['PUBLISHED'] })
  }

  const onUmObjectChange = (e, dropdown) => {
    actions.clearPlans()
    const { name, value } = dropdown
    setSelectedUMs({
      ...selectedUMs,
      [name]: {
        value
      }
    })
  }

  const pullLinkedPlans = () => {
    const { tiers, programs, clinical_pa, clinical_ql, clinical_step_therapy } = selectedUMs

    const extractedValues = {
      tiers: tiers.value,
      programs: programs.value,
      clinical_pa: clinical_pa.value,
      clinical_ql: clinical_ql.value,
      clinical_step_therapy: clinical_step_therapy.value,
    }

    actions.getTestBatchConfigPlans({
      type: 'um',
      form: {
        ...extractedValues,
        hierarchy: hierarchyForm.hierarchyDropdown,
        status: ["Published"]
      },
    })
  }

  return <div id='um-where-used-lookup'>
    <div className='hierarchy-container'>
      <span className='hierarchy-header'>Define Hierarchy</span>
      <FliptHierarchyDropdown
        setForm={handleHierarchyChange}
        form={hierarchyForm}
      />
      <FliptButton
        className="primary self-align-center"
        name="Pull UM Objects"
        onClick={getUmObjects}
      />
    </div>
    <div className='um-dropdowns-container'>
      {UM_OBJECTS.map((umObject) => {
        return <div className="flex-item">
          <FliptDropdown
            label={umObject.label}
            options={umObjects[umObject.stateKey].map((umDetails) => ({
              text: umDetails.module_name,
              value: umDetails.module_name,
            }))}
            multiple
            name={umObject.name}
            value={selectedUMs[umObject.name]?.value}
            onChange={onUmObjectChange}
            className="dropdown"
          />
        </div>
      })}
    </div>
    <div className='pull-plans-container'>
      <FliptButton
        className="primary"
        name="Pull Linked Plans"
        onClick={pullLinkedPlans}
      />
    </div>
    {!!plans.length && <div className='plans-grid-container'>
      <FliptGrid
        headers={[...defaultHeaders]}
        data={plans}
      />
    </div>}
  </div>
}

const mapStateToProps = (state) => ({
  state: {
    user: state.user,
    claimsProcessingAndReprocessing: state.claimsProcessingAndReprocessing,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
    ...ClaimsPRPActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UMWhereUsedLookup)
