import { priceType, discountType, rateType, feeNames } from './dropdownData'

/* eslint-disable */
export default {
  claim_processor: {
    type: 'dropdown',
    disabled: false,
    options: [],
  },
  price_type: {
    type: 'dropdown',
    disabled: false,
    options: priceType
  },
  specialty_drug_list: {
    type: 'dropdown',
    disabled: false,
    options: [],
    multiple: true,
  },
  min_days_supply: {
    type: 'input',
    disabled: false,
  },
  max_days_supply: {
    type: 'input',
    disabled: false,
  },
  fixed_variable: {
    type: 'dropdown',
    disabled: false,
    options: discountType,
  },
  rate_type: {
    type: 'dropdown',
    disabled: false,
    options: rateType,
  },
  discount: {
    type: 'input',
    disabled: false,
  },
  fee_name: {
    type: 'dropdown',
    disabled: false,
    options: feeNames,
    multiple: true,
  },
  fee_type: {
    type: 'dropdown',
    disabled: false,
    options: discountType,
  },
  fee_value: {
    type: 'input',
    disabled: false,
  },
  mac_list_id: {
    type: 'input',
    disabled: false,
  },
  price_floor: {
    type: 'input',
    disabled: false,
  },
}
