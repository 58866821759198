export const HIERARCHY_TABS_LOCAL_STORAGE_KEY = "hierarchyTabs";
const REQUIRED_TAB = "Required";
const COMPANY_TAB = "Company";
const BILLING_FINANCE_TAB = "Billing & Finance";
const COMMUNICATTION_TAB = "Communication";
const APP_WEB_TAB = "App/Web Settings";
// const REWARDS_PENALTY_TAB = "Rewards & Penalty";
const GLOBAL_EDIT_TAB = "Global Edit";
const SYSTEM_TAB = "System Settings";
const POS_REBATE_TAB = "POS Rebate";
const OTHER_SETTING_TAB = "Other Settings";
export const GROUP_TAB = 'Coverage Details';

export const COLUMN_DIVIDER = 3;
export const COLUMN_TWO_DIVIDER = 2;
export const REQUIRED_TAB_ID = "REQUIRED";
export const COMPANY_TAB_ID = "COMPANY";
const BILLING_FINANCE_TAB_ID = "BILLING";
const COMMUNICATTION_TAB_ID = "COMMUNICATION";
const APP_WEB_TAB_ID = "APP/WEB";
// const REWARDS_PENALTY_TAB_ID = "REWARDS";
const GLOBAL_EDIT_TAB_ID = "GLOBAL";
const SYSTEM_TAB_ID = "SYSTEM";
const OTHER_SETTING_TAB_ID = "OTHER";
export const GROUP_TAB_ID = 'GROUP';

export const REWARDS_TAB_HEADERS_REWARD = 'Reward';
export const REWARDS_TAB_HEADERS_PENALTY = 'Penalty';
export const REWARDS_TAB_HEADERS_BASELINE = 'Baseline Calculation';

export const defaultTabConfigs = [
  // {
  //   tabName: REQUIRED_TAB,
  //   id: REQUIRED_TAB_ID,
  // },
  {
    tabName: COMPANY_TAB,
    id: COMPANY_TAB_ID,
  },
  {
    tabName: BILLING_FINANCE_TAB,
    id: BILLING_FINANCE_TAB_ID,
  },
  {
    tabName: COMMUNICATTION_TAB,
    id: COMMUNICATTION_TAB_ID,
  },
  {
    tabName: APP_WEB_TAB,
    id: APP_WEB_TAB_ID,
  },
  // {
  //   tabName: REWARDS_PENALTY_TAB,
  //   id: REWARDS_PENALTY_TAB_ID,
  // },
  {
    tabName: SYSTEM_TAB,
    id: SYSTEM_TAB_ID,
  },
  {
    tabName: POS_REBATE_TAB,
    id: POS_REBATE_TAB,
  },
  {
    tabName: OTHER_SETTING_TAB,
    id: OTHER_SETTING_TAB_ID,
  },
];

export const groupDefaultTabConfigs = [
  {
    tabName: GROUP_TAB,
    id: GROUP_TAB_ID,
  },
  ...defaultTabConfigs,
]

// get the tabs based on the node type
export const getDefaultTabConfigs = (nodeType) => {
  if (nodeType === 5) return groupDefaultTabConfigs
  else return defaultTabConfigs
}
