import React from 'react'
import { Link } from 'react-router-dom'

export default (props) => {
  const { active, data } = props

  return (
    <div>
      <Link
        to={{
          pathname: '/manual-eligibility-entry',
        }}
        className={active === 'primaryCardholder' ? 'active' : ''}
      >
        Primary Cardholder
      </Link>
      <Link
        to={{
          pathname: '/create-dependent-eligibility',
        }}
        className={active === 'dependent' ? 'active' : ''}
      >
        Dependent
      </Link>
    </div>
  )
}
