import React, { Component } from 'react'
import _ from 'lodash'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'
import FliptInput from '../../../components/form/fliptInput'
import './styles.scss'
import FliptDatePicker from '../../../components/form/fliptDatePicker'
import FliptButton from '../../../components/form/fliptButton'
import { convertStrToDateObj, addRemoveDaysToDate, convertDateToStartTimeObj, convertDateToEndTimeObj } from '../../../utils/utilities'
import CAGFilters from '../../../components/CAGFilters'
import { Creators as PlanManagementActions } from '../../../redux/reducers/api/planManagement'
import { Creators as AppActions } from '../../../redux/reducers/app'
import FliptHierarchyDropdown from '../../../components/form/fliptHierarchyDropdown'


class CopyPlanDesignManagementObject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        plan_id: props.data[0]?.['plan_id'],
        effective_start_date: '',
        effective_end_date: '',
        plan_name: props.data[0]?.['plan_name'],
        status: 'Draft',
        plan_version: props.data[0]?.['plan_version'],
        account: '',
        carrier: props.data[0]?.['carrier'] || 'FLIPT',
        group: props.data[0]?.['group'] || '',
        plan_year: props.data[0]?.['plan_year'] || '',
        hierarchy: [],
        hierarchyIDs: false,
        hideHierarchy: false,
      }
    }
  }
  convertDate = (name, value) => {
    return name === 'effective_start_date' ? convertDateToStartTimeObj(value) : convertDateToEndTimeObj(value)
  }
  _updateFields = (el, dateData) => {
    const { name, value } = dateData || el.currentTarget

    this.setState((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        [name]: ['effective_start_date', 'effective_end_date'].includes(name) ? this.convertDate(name, value) : value,
      },
    }))
  }

  setForm = (newForm) => {
    this.setState((prevState) => ({
      ...prevState,
      form: newForm
    }))
  }

  onFilterChange = (e, dropdown) => {
    const { name, value } = dropdown
    this.props.actions.setFilters({
      data: {
        filterName: name,
        filterValue: value,
      },
    })

    this.setState((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        [name]: value,
      },
    }))
  }

  _copyBenefitPlan = () => {
    const { props, state } = this
    const { actions } = props
    const { form } = state
    const { hierarchy, hierarchyIDs, hideHierarchy } = form 
    const { showTabFlag, setShowTabFlag } = this.props
    if (!hierarchy || !hierarchy?.length > 0) {
      const transitionalPortal = {
        header: 'Hierarchy Missing or Incomplete',
        copy: 'Please select exactly 1 carrier, 1 account, and 1 group',
      }
      actions.displayTransitionalPortal(transitionalPortal)
      return
    }
    const topLevel = hierarchy[0]
    const orgLevel = topLevel?.children
    const clientLevel = orgLevel[0]?.children
    const carrierLevel = clientLevel[0]?.children
    const accountLevel = carrierLevel[0]?.children
    const groupLevel = accountLevel[0]?.children
    if (carrierLevel?.length != 1 || accountLevel?.length != 1 || groupLevel?.length != 1) {
      const transitionalPortal = {
        header: 'Hierarchy Missing or Incomplete',
        copy: 'Please select exactly 1 carrier, 1 account, and 1 group',
      }
      actions.displayTransitionalPortal(transitionalPortal)
      return
    }
    const carrier = carrierLevel[0]?.carrier_id ?? carrierLevel[0]?.name
    const account = accountLevel[0]?.account_id ?? accountLevel[0]?.name
    const group = groupLevel[0]?.group_id ?? groupLevel[0]?.name

    if (!( hierarchyIDs && carrier && account && group)) {
      const transitionalPortal = {
        header: 'Hierarchy Missing or Incomplete',
        copy: 'Please select Hierarchy from the dropdown',
      }
      actions.displayTransitionalPortal(transitionalPortal)
      return
    }
    if (!form.plan_name || !form.effective_start_date || !form.effective_end_date) {
      const transitionalPortal = {
        header: 'Plan Save Failed',
        copy: !form.plan_name ? 'Please input a Plan Name before saving' : 'Please select start and end dates before saving',
      }
      props.actions.displayTransitionalPortal(transitionalPortal)
    } else {
      setTimeout(() => props.actions.copyPlan({ ...form, account, group, carrier, hierarchyIDs }), 250);
      setShowTabFlag(!showTabFlag);
      setTimeout(() => props.searchBenefitPlans(), 4000);
    }
  }

  render() {
    const {
      form
    } = this.state

    const { showTabFlag, setShowTabFlag, state } = this.props

    const {
      selectedCarrier,
      selectedAccount,
      selectedGroup,
    } = state

    const {
      plan_name, effective_start_date, effective_end_date
    } = form
    const dateValue1 = effective_start_date ? convertStrToDateObj(new Date(effective_start_date)) : ""
    const dateValue2 = effective_end_date ? convertStrToDateObj(new Date(effective_end_date)) : ""
    const minPlanStartDate = moment(addRemoveDaysToDate(1, false)).format('MM-DD-YYYY')
    const minPlanEndDate = moment(addRemoveDaysToDate(1, false)).format('MM-DD-YYYY')

    return (
      <div id="createPlan">
        <div className="content copy-object">
          <div className='copy-header'>
            Copy Plan
          </div>
          <div id="Hierarchy">
            <FliptHierarchyDropdown editMode={true} setForm={this.setForm} form={form} />
          </div>
          <div className='input-container'>
            <div className="plan-inputs">
              <span>Plan Name</span>
              <FliptInput className="createPlanInputs" name="plan_name" value={plan_name} onChange={this._updateFields} />
            </div>
            <div className="plan-inputs">
              <span>Effective Start Date</span>
              <FliptDatePicker className="create-plan-start-date" name="effective_start_date" value={dateValue1} minDate={minPlanStartDate} onChange={this._updateFields} format="MM-DD-YYYY" />
            </div>
            <div className="plan-inputs">
              <span>Effective End Date</span>
              <FliptDatePicker className="create-plan-end-date" name="effective_end_date" value={dateValue2} minDate={minPlanEndDate} onChange={this._updateFields} format="MM-DD-YYYY" />
            </div>
          </div>
          <div className='action-container'>
            <div className="button-container">
              <FliptButton name="Copy Object" className="primary searchButton" onClick={this._copyBenefitPlan} />
            </div>
            <div className="button-container">
              <FliptButton name="Cancel" className="secondary searchButton" onClick={() => setShowTabFlag(!showTabFlag)} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  state: {
    app: state.app,
    user: state.user,
    selectedCarrier: state.planManagement.planDetailsFilters.carrier ?? 'FLIPT',
    selectedAccount: state.planManagement.planDetailsFilters.account ?? null,
    selectedGroup: state.planManagement.planDetailsFilters.group ?? null,
    benefitPlanData: state.planManagement.benefitPlanData,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
    ...PlanManagementActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CopyPlanDesignManagementObject)
