
import React from 'react';
import { connect } from 'react-redux';

import FliptGrid from '../../../../../../components/fliptGrid';
import { DeleteRowRenderer } from '../../../../../../components/fliptGrid/cellRenderers';
import { InputTextEditor, DropdownEditor } from '../../../../../../components/fliptGrid/cellEditors';

const rowCellInfo = {
    day_supply: {
        type: 'dropdown',
        disabled: false,
        options: [],
    },
    network_tier: {
        type: 'dropdown',
        disabled: false,
        options: [],
    },
    member_pos_rebate_type: {
        type: 'dropdown',
        disabled: false,
        options: [],
    },
    member_pos_rebate_factor: {
        type: 'input',
        disabled: false,
    },
}

const POSRebateGrid = (props) => {
    const {
        data,
        delRow,
        handleChange,
    } = props;

    const headers = [
        'day_supply',,
        'network_tier',
        'member_pos_rebate_type',
        'member_pos_rebate_factor',
        'action'
    ];
    const cellRendererParams = {
        day_supply: {
            width: 160,
            overrideHeader: 'Day Supply',
        },
        network_tier: {
            width: 160,
        },
        member_pos_rebate_type: {
            width: 175,
            overrideHeader: 'POS Rebate Type',
        },
        member_pos_rebate_factor: {
            width: 185,
            overrideHeader: 'POS Rebate Factor',
        },
        action: {
            cellRenderer: DeleteRowRenderer,
            state: {
                onClick: delRow,
            },
            width: 135,
        },
    }
    const cellEditorParams = {
        day_supply: {
            editable: true,
            cellEditor: DropdownEditor,
            onChange: handleChange,
        },
        network_tier: {
            editable: true,
            cellEditor: DropdownEditor,
            onChange: handleChange,
        },
        member_pos_rebate_type: {
            editable: true,
            cellEditor: DropdownEditor,
            onChange: handleChange,
        },
        member_pos_rebate_factor: {
            editable: true,
            cellEditor: InputTextEditor,
            onChange: handleChange,
            validation: 'numeric',
        },
    }

    return (
        <FliptGrid
            data={data}
            headers={headers}
            cellRendererParams={cellRendererParams}
            cellEditorParams={cellEditorParams}
            rowCellInfo={rowCellInfo}
        />
    )
}

const mapStateToProps = (state) => {
    const section = state.globalEdits?.section || {};
    const fieldDetails = section?.global_copay?.fields || [];
    const daySupplyField = fieldDetails?.find(({ field }) => field === 'day_supply')
    const daySupplyFieldOptions = daySupplyField?.options || []
    const daySupplyOptions = daySupplyFieldOptions?.map((option, index) => {
      const { value } = option
      return {
        key: index,
        text: value,
        value,
      }
    })
    const networkTierOptions = fieldDetails?.find(({ field }) => field === 'network_tier')?.options || []
    rowCellInfo.network_tier.options = networkTierOptions?.map((code, index) => ({
      key: index,
      text: code.display_name,
      value: code.value,
      map_value: code.map_value,
    }))
    rowCellInfo.network_tier.options.unshift({
      key: 0, text: 'ALL', value: 'ALL', map_value: 'ALL',
    })

    rowCellInfo.day_supply.options = daySupplyOptions
    rowCellInfo.member_pos_rebate_type.options = [
        { key: 1, text: 'Percentage', value: 'percentage' },
        { key: 0, text: 'Fixed', value: 'fixed' },
    ]
    return {
        state: {
            fieldDetails: fieldDetails,
        }
    }
}

export default connect(mapStateToProps, null)(POSRebateGrid);