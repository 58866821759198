import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Config from '../../../../config'
import './styles.scss'
import BreadCrumb from '../../../../components/breadCrumb'
import FliptButton from '../../../../components/form/fliptButton'
import FliptGrid from '../../../../components/fliptGrid'
import { Creators as NavigationActions } from '../../../../redux/reducers/navigation'
import { Creators as RoleManagementActions } from '../../../../redux/reducers/roleManagement'
import { LinkContainerRenderer } from '../../../../components/fliptGrid/cellRenderers'
import { convertDateTimeToDate, filterRowData } from '../../../../utils/utilities'

class RoleManagement extends PureComponent {
  componentDidMount() {
    const { actions } = this.props
    setTimeout(() => actions.getRoleManagementRoles(), Config.COMPONENT_DATA_LOAD_TIME)
  }

  _dataCleanup = () => {
    const { state: { data } } = this.props
    return data.map((d) => ({
      id: d.id,
      name: d.name,
      status: d.active ? 'Active' : 'Inactive',
      effective_start_date: convertDateTimeToDate(d.effective_start_date),
      effective_end_date: d.effective_end_date === '' ? '---' : convertDateTimeToDate(d.effective_end_date),
      description: d.description,
      organization: d.organization,
      created_by: d.created_by,
    }))
  }

  render() {
    let gridData = this._dataCleanup()
    const headers = [
      'Action',
      'Role Name',
      'Description',
      'Created By',
      'Effective Start Date',
      'Effective End Date',
      'Status',
      'id',
    ]
    const headerMapping = {
      name: 'Role Name',
      description: 'Description',
      created_by: 'Created By',
      effective_start_date: 'Effective Start Date',
      effective_end_date: 'Effective End Date',
      status: 'Status',
    }
    gridData = gridData && gridData.length ? gridData.map((d) => ({
      ...filterRowData(d, headers, headerMapping),
    })) : []
    const cellRendererParams = {
      Action: {
        // TODO LinkContainerRenderer does not work with state
        cellRenderer: LinkContainerRenderer,
        searchArgs: [
          {
            path: '/role-management-edit',
            searchArgs: { id: 'id' },
            staticState: { editMode: false },
            label: 'View',
          },
          {
            path: '/role-management-edit',
            searchArgs: { id: 'id' },
            staticState: { editMode: true },
            label: 'Edit',
          },
        ],
      },
      id: {
        hide: true,
      },
    }
    return (
      <div id="roleManagement">
        <BreadCrumb {...this.props} />
        <div className="header">
          <h1>Roles</h1>
        </div>
        <div className="subhead">
          <Link to={{
            pathname: '/role-management-create',
            state: { editMode: true },
          }}
          >
            <FliptButton className="primary" name="Create Role" />
          </Link>
          <div className="searchForm" />
        </div>
        <div className="content">
          <FliptGrid
            headers={headers}
            data={gridData}
            cellRendererParams={cellRendererParams}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  state: {
    data: state.roleManagement.roleListing,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...RoleManagementActions,
    ...NavigationActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RoleManagement)
