import React from "react";
import { Button, Icon, Checkbox, Modal } from "semantic-ui-react";
import "./styles.scss";
import FliptButton from "../../../../components/form/fliptButton";

const CloseModal = (props) => {
  const { open, onClose, discard } = props;
  return (
    <div>
      <Modal
        size={"mini"}
        id="closeModalInfo"
        closeIcon
        open={open}
        onClose={() => onClose()}
      >
        <Modal.Header>
          <div className="heading">
           <Icon name="exclamation circle" />
           <p> Changes not saved</p>
          </div>
        </Modal.Header>
        <Modal.Content className="info-content">
          <p className="info-text">
            You are about to discard any modifications made this far. Feel free to return, save your information, and edit it at any time.
          </p>
          <div className="gapView">
            <FliptButton
              className="primary discard"
              name="Go Back"
              onClick={() => onClose()}
            />
            <FliptButton
              className="primary discard"
              name="Discard Changes"
              onClick={discard}
            />
          </div>
        </Modal.Content>
      </Modal>
    </div>
  );
};

export default CloseModal;
