import React from 'react'

import './styles.scss'

export default function FliptForm({ id, children }) {
  return (
    <form id={id}>
      {children}
    </form>
  )
}
