import React, { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import './styles.scss'
import FliptButton from '../../v2/fliptButton'
import FliptGrid from '../../fliptGrid'
import FliptPaInput from '../../v2/fliptInput'
import { Creators as PrescriberLookupActions } from '../../../redux/reducers/api/prescriberLookup'
import { Creators as AppActions } from '../../../redux/reducers/app'
import { filterRowData } from '../../../utils/utilities'

function FliptPrescriberSearch(props) {
    const { actions, updatePrescriberData, state: { prescriberLookupData } } = props

    const [searchForm, setSearchForm] = useState({
        prescriberNpi: '',
        prescriber_first_name: '',
        prescriber_last_name: '',
    })

    const [prescriberList, setPrescriberList] = useState([])
    const [prescriberData, setPrescriberData] = useState(null)

    useEffect(() => {
        setPrescriberList(prescriberLookupData)
    }, [prescriberLookupData])


    const _onPrescriberSelectionChanged = (data) => {
        const selectedRows = data.api.getSelectedRows();
        if (selectedRows.length) {
            setPrescriberData(selectedRows[0])
            updatePrescriberData(selectedRows[0])
        }
        else {
            setPrescriberData(null)
            updatePrescriberData(null)
        }
    }

    useEffect(() => {
        actions.clearPrescriberLookupReducer()
    }, []);

    const _clearPrescriberFields = () => {
        setSearchForm({
            prescriberNpi: '',
            prescriber_first_name: '',
            prescriber_last_name: '',

        })
        setPrescriberData(null)
        updatePrescriberData(null)
        setPrescriberList([])
        actions.clearPrescriberLookupReducer()
    }

    const _submitResult = () => {
        actions.toggleModal()
    }

    const _searchData = () => {
        if ((!searchForm.prescriberNpi && !searchForm.prescriber_first_name
            && !searchForm.prescriber_last_name)) {
            const transitionalPortal = {
                header: 'Fields are missing',
                copy: 'Please Add Details to search Prescriber detail',
            }
            actions.displayTransitionalPortal(transitionalPortal)
            return;
        }
        if (searchForm.prescriberNpi && searchForm.prescriberNpi.length !== 10) {
            const transitionalPortal = {
                header: 'Field Error',
                copy: 'Prescriber NPI should be of 10 Digits',
            }
            actions.displayTransitionalPortal(transitionalPortal)
            return;
        }
        if (searchForm.prescriber_first_name && !searchForm.prescriber_last_name || !searchForm.prescriber_first_name && searchForm.prescriber_last_name) {
            const transitionalPortal = {
                header: 'Field Error',
                copy: 'Please Add Both First Name and Last Name',
            }
            actions.displayTransitionalPortal(transitionalPortal)
            return;
        }
        let params = {}
        if (searchForm.prescriberNpi) params.prescriber_npi = searchForm.prescriberNpi
        if (searchForm.prescriber_first_name) params.prescriber_first_name = searchForm.prescriber_first_name
        if (searchForm.prescriber_last_name) params.prescriber_last_name = searchForm.prescriber_last_name
        actions.clearPrescriberLookupReducer()
        actions.getPrescriberLookupData(params)
    }

    const _updateSearchForm = (el, dropdown) => {
        const { name, value } = dropdown || el.currentTarget
        const tempSearchForm = { ...searchForm }
        tempSearchForm[name] = value
        setSearchForm(tempSearchForm)
    }

    const headers = [
        'Select', 'npi', 'first_name', 'last_name', 'secure_fax_number', 'phone_number', 'address', 'city',
        'state', 'zip_code',
    ]
    const headerMapping = {
        npi: 'npi',
        provider_first_name: 'first_name',
        provider_last_name_legal_name: 'last_name',
        provider_first_line_business_practice_location_address: 'address',
        provider_business_practice_location_address_city_name: 'city',
        provider_business_practice_location_address_state_name: 'state',
        provider_business_practice_location_address_postal_code: 'zip_code',
        provider_business_practice_location_address_fax_number: 'fax_number',
        provider_business_practice_location_address_telephone_number: 'phone_number'
    }
    const gridData = prescriberList?.map((d) => ({ ...filterRowData(d, headers, headerMapping) }))
    const cellRendererParams = {
        Select: {
            checkboxSelection: true,
            width: '100px'
        },
        npi: {
            width: '120px'
        },
        first_name: {
            width: '120px'
        },
        last_name: {
            width: '120px'
        },
        secure_fax_number: {
            width: '120px'
        },
        address: {
            width: '120px'
        },
        city: {
            width: '120px'
        },
        state: {
            width: '100px'
        },
        zip_code: {
            width: '120px'
        },
        phone_number: {
            width: '120px'
        }
    }
    return (
        <div className="prescriber-search-v2">
            <div className="prescriber-header">
                Find Prescriber
            </div>
            <div className="fields-container">
                <FliptPaInput validation='numeric' value={searchForm.prescriberNpi} name="prescriberNpi" onChange={_updateSearchForm} placeholder="Enter" label="NPI" description="NPI" />
                <FliptPaInput value={searchForm.prescriber_first_name} name="prescriber_first_name" onChange={_updateSearchForm} placeholder="Enter" label="Prescriber First Name" />
                <FliptPaInput value={searchForm.prescriber_last_name} name="prescriber_last_name" onChange={_updateSearchForm} placeholder="Enter" label="Prescriber Last Name" />
            </div>
            <div className="prescriber-buttons-container">
                <FliptButton name="SEARCH" className="primary" onClick={() => _searchData()} />
                <FliptButton name="Clear" className="secondary" onClick={() => _clearPrescriberFields()} />
            </div>
            {prescriberList?.length ?
                <>
                    <div className="prescriber-header">
                        Search Results
                    </div>
                    <div className="grid-container">
                        <FliptGrid
                            headers={headers}
                            data={gridData}
                            rowSelection='single'
                            onSelectionChanged={(data) => _onPrescriberSelectionChanged(data)}
                            cellRendererParams={cellRendererParams}
                        />
                    </div></> : <></>
            }
            {prescriberData ? <div className="prescriber-buttons-container">
                <FliptButton className='primary' name='SUBMIT'
                    onClick={() => _submitResult()} />
            </div> : null}
        </div>
    )

}

const mapStateToProps = (state) => ({
    state: {
        prescriberLookupData: state.prescriberLookup.prescriberLookupData,
        user: state.user,
    },
})

const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...PrescriberLookupActions,
        ...AppActions
    }
    return {
        actions: bindActionCreators(allActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FliptPrescriberSearch)