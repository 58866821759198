import React, { PureComponent } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import './styles.scss'
import BreadCrumb from '../../../../components/breadCrumb'
import Config from '../../../../config'
import { Creators as AppActions } from '../../../../redux/reducers/app'
import { Creators as MemberLookupActions } from '../../../../redux/reducers/api/memberLookup'
import Deductibles from './deductibles';
import Medicares from './medicares';
import config from '../../../../config'

class DeductibleDetails extends PureComponent {

  constructor(props) {
    super(props);
    const para = props.location.search ? props.location.search.split('?')[1] : '';
    const med = para.length > 25 ? para.split('medicare=')[1] : false;
    this.state = { medicare: med };
  }
  componentDidMount() {
    const { actions: { getMemberDeductibleDetailsData, getMemberMedicareDetailsData } } = this.props
    // TODO - figure out how to remove settime out from here
    setTimeout(() => getMemberDeductibleDetailsData(), Config.COMPONENT_DATA_LOAD_TIME)

    //setTimeout(()=> getMemberMedicareDetailsData(), Config.COMPONENT_DATA_LOAD_TIME)
  }

  update_medicare = () => {
    const { medicare } = this.state;
    this.setState({ medicare: !medicare });
  }

  render() {
    const { state, props } = this
    const { state: { accumulator_details, medicare_details } } = props;
    const { medicare } = state;
    return (
      <div id="DeductibleDetails">
        <BreadCrumb {...this.props} />
        <div className='header'>
          <span className={medicare ? "item" : "active-item item"} onClick={() => this.update_medicare()}>Accumulation Details</span>
          { /*<span className={!medicare ? "item" : "active-item item"} onClick={()=> this.update_medicare()}>Medicare Part D PDE Details</span> */}
        </div>
        <div id='deductible_medicare' >
          {!medicare && <Deductibles accumulator_details={accumulator_details} />}
          {/*medicare && <Medicares medicare_details={medicare_details} /> */}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  state: {
    app: state.app,
    accumulator_details: state.memberLookup.accumulator_details,
    medicare_details: state.memberLookup.medicare_details
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
    ...MemberLookupActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeductibleDetails)
