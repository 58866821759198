import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import './styles.scss'
import BreadCrumb from '../../../components/breadCrumb'
import FliptButton from '../../../components/form/fliptButton'
import FliptInput from '../../../components/form/fliptInput'
import FliptDropdown from '../../../components/form/fliptDropdown'
import FliptGrid from '../../../components/fliptGrid'
import statesJSON from '../../../config/constants/states'
import { Creators as AppActions } from '../../../redux/reducers/app'
import { Creators as PharmacyLookupActions } from '../../../redux/reducers/api/pharmacyLookup'
import { Creators as RxreportLookupActions } from '../../../redux/reducers/api/rxreportLookup'
import { createQueryString, parseQueryString } from '../../../utils/utilities'
import FliptDatePicker from '../../../components/form/fliptDatePicker'
import { Creators as UserManagement } from '../../../redux/reducers/userManagement'
import PaModal from './paModal'
import ApprovalModal from './paModal'
import UserModal from './userModal'

class RxreportLookup extends Component {
  constructor(props) {
    super(props)
    this.headers = [
      'domain', 'patient_flipt_person_id', 'member_id', 'create_date', 'application', "prescription_id",'rx_status', 'pa_reason',
      'pa_form', 'pharmacyname', 'pharmacytype', 'filled_date',
      'patient_paid', 'pharmacy_npi', 'prescriber_npi', 
      'daysofsupply', 'erx_created_by', 'drug_name', 'form',
      'dosage', 'selected_quantity', 'dispensed_quantity', 'preselected_pharmacy',
      'pharmacy', 'location', 'rx_number', 'prescriber_npi',
       'prescriber_first_name', 'prescriber_last_name', 'prescriber_phone',
      'rewards', 'payment_option', 'deductible_remaining', 'out_of_pocket_remaining',
      'pharmacy_transfer_status', 'oldNpi', 'oldPharmacy',
      'switchToPharmacy', 'switch_pharmacy_response_phone', 'switch_pharmacy_text_response', 'Abc',"pa_override"
    ]

    this.state = {
      form: {
        flipt_person_id: '',
        drug_name: '',
        pharmacystate: '',
        pharmacyzip1: '',
        pharmacytype: 'RETAIL',
        rx_status: 'PA INITIATED',
        start_date: "",
        end_date: "",
        domain_name: "",
        offset: "0",
        use_date: "",
        note: "",
        user: "",
        pa_override_end_date: "",
        paOverride: false,
       
        file:[],

        pa: {
          status: null,
          prescription_id: null,
          flipt_person_id: null,
          drug_name: null,
          pharmacy: null,
          domain: null,
          pharmacy_npi: null
        },
        callback:""
      },
      pharmacyTypeValues: ['Retail', 'All'],
      rxStatusValues: ['PA Initiated', 'PA Canceled', 'PA Pending WIP', 'PA Pending RFI'],
      agGridRef: React.createRef(),
    }
  }


  componentDidMount() {
    const { history } = this.props
    const { location } = history

    if (!location.search) return
    const { form, agGridRef } = this.state

    this._qsToFormData()
    history.location.search = createQueryString(form)

    agGridRef.current.api.refreshServerSideStore({ route: null, purge: true }) // to refresh the grid server side data

  }

  _pharmacySearch = () => {
    const { state, props } = this
    const { form, agGridRef } = state
    const { history } = props
    history.location.search = createQueryString(form)
    agGridRef.current.api.refreshServerSideStore({ route: null, purge: true }) // to refresh the grid server side data
  }

  _updateFields = (el, dropdown) => {
    const { form } = this.state
    const { name, value } = dropdown || el.currentTarget

    form[name] = value

    this.setState({ form })
  }

  _qsToFormData = () => {
    const { state, props } = this
    const qs = parseQueryString(props.history.location.search)
    const { form } = state

    Object.keys(qs).forEach((key) => {
      form[key] = qs[key].toUpperCase()
    })
    this.setState({ form })
  }


  _upDatePA = (e, data) => {

    let status = e.target.value.toLowerCase()
    if (status != "action") {
      const { actions } = this.props

      const { prescription_id, patient_flipt_person_id, drug_name, pharmacy, domain, pharmacy_npi, pa_override } = data
      let form = this.state.form
      
      form.pa = {

        status: status,
        prescription_id: prescription_id,
        flipt_person_id: patient_flipt_person_id,
        drug_name: drug_name,
        pharmacy: pharmacy,
        domain: domain,
        pharmacy_npi: pharmacy_npi,
        pa_override: this.state.form.paOverride ? "Y" : "N"

      }

      this.setState({
        ...this.state.form,
        ...form

      })

      if (status != "assign") {

        actions.setModalComponent({
          modalProperties: {
            size: 'large',
          },
          contents: this._generateModal("abc", { module_name: e.target.value, status: status, pa_override: pa_override, paOverride: this.state.form.paOverride, prescription_id: prescription_id }),
        })
        actions.toggleModal()
      }
      else {
        actions.setModalComponent({
          modalProperties: {
            size: 'large',
          },
          contents: this._generateUserModal("abc", { module_name: e.target.value, status: status, pa_override: pa_override, paOverride: this.state.form.paOverride, prescription_id: prescription_id }),
        })
        actions.toggleModal()
      }
    }


  }

  _clickHandler = (data) => {
    const { actions } = this.props
    let form=this.state.form
    if(this.state.form.file.length>0){
     
      form.callback=()=>{
      
        actions.getRxreportSuggestData(form)
      }
      actions.getRxreportUploadFileData(form)
    }
    else{
     
      actions.getRxreportSuggestData(form)

    }
    
   
    this._pharmacySearch()

  }



  _clickUserHandler=(data)=>{
    const { actions } = this.props
    actions.getRxreportSuggestData(this.state.form)
   
  }


  _modelChangevale = (data) => {
    this.setState({
      form: {
        ...this.state.form,
        ...data
      }
    })


  }



  _generateModal = (submitType, data) => {
    const { actions } = this.props

    return (
      <ApprovalModal
        clickHandler={this._clickHandler}
        data={data}
        action={this._modelChangevale}
        rejectDocument={actions.rejectDocument}
        submitType={submitType}
        toggleModal={actions.toggleModal}
      />
    )
  }


  _generateUserModal = (submitType, data) => {
    const { actions } = this.props

    return (
      <UserModal
        clickHandler={this._clickUserHandler}
        data={data}
        action={this._modelChangevale}
        rejectDocument={actions.rejectDocument}
        submitType={submitType}
        toggleModal={actions.toggleModal}
      />
    )
  }



  render() {

    const { state, props } = this
    const { actions } = props
    const {
      form, pharmacyTypeValues, rxStatusValues, agGridRef,
    } = state
    const {
      flipt_person_id, drug_name, domain_name, pharmacyzip1,
      pharmacytype, rx_status, start_date, end_date, user_id
    } = form


    const {
      state: {
        rxreportPerPage,
      },
    } = props
    
    const headers=this.headers
    const cellRendererParams = {
      domain: {
        overrideHeader: 'Domain',
      },
      flipt_person_id: {
        overrideHeader: 'Patient Flipt ID',
      },
      member_id: {
        overrideHeader: 'Member ID',
      },
      create_date: {
        overrideHeader: 'Created Date',
      },
      application: {
        overrideHeader: 'Application',
      },
      prescription_id: {
        overrideHeader: 'Prescription Id',
      },
      rx_status: {
        overrideHeader: 'Rx Status',
      },
      pa_reason: {
        overrideHeader: 'PA Reason',
      },
      pa_form: {
        overrideHeader: 'PA Form',
      },


      pharmacyname: {
        overrideHeader: 'Action',
        cellRenderer: (param) => {

          const paActions = param.data.rx_status !== 'CANCELED' ? [
            { key: 0, value: 'action', text: 'Action' },
            { key: 1, value: 'approve', text: 'Approve' },
            { key: 2, value: 'reject', text: 'Reject' },
            { key: 3, value: 'cancel', text: 'Cancel' },
            { key: 4, value: 'delete', text: 'Delete' },
            { key: 6, value: 'assign', text: 'Assign' },
            { key: 7, value: 'rfi', text: 'PA Pending RFI' },
          ] : record.pa_status == 'CANCELED' ? [
            { key: 0, value: 'action', text: 'Action' },
            { key: 4, value: 'regenerate', text: 'Regenerate ' },
            { key: 5, value: 'delete', text: 'Delete' },
          ] : [];

          return <select onChange={(e) => this._upDatePA(e, param.data)}>{paActions.map(obj => { return <option value={obj.text} key={obj.key} >{obj.text}</option> })}</select>
        }
      },
      pharmacytype: {
        overrideHeader: 'PA Template',
      },
      filled_date: {
        overrideHeader: 'Filled Date',
      },
      patient_paid: {
        overrideHeader: 'Patient Paid',
      },
      pharmacy_npi: {
        overrideHeader: 'Pharmacy NPI',
      },
      prescriber_npi: {
        overrideHeader: 'Prescriber NPI',
      },
      daysofsupply: {
        overrideHeader: 'Days of Supply',
      },
      erx_created_by: {
        overrideHeader: 'eRx Created By',
      },
      drug_name: {
        overrideHeader: 'Drug',
      },

      user_name: {
        overrideHeader: 'User Name',
      },
      form: {
        overrideHeader: 'Form',
      },
      dosage: {
        overrideHeader: 'Dosage',
      },
      selected_quantity: {
        overrideHeader: 'Selected Quantity',
      },
      dispensed_quantity: {
        overrideHeader: 'Dispensed Quantity',
      },
      preselected_pharmacy: {
        overrideHeader: 'Selected Pharmacy',
      },
      pharmacy: {
        overrideHeader: 'Filled Pharmacy',
      },
      location: {
        overrideHeader: 'Location',
      },
      rx_number: {
        overrideHeader: 'Rx Number',
      },
      prescriber_npi: {
        overrideHeader: 'Prescriber NPI',
      },


      prescriber_first_name: {
        overrideHeader: 'Prescriber First Name',
      },
      prescriber_last_name: {
        overrideHeader: 'Prescriber Last Name',
      },
      prescriber_phone: {
        overrideHeader: 'Prescriber Phone Number',
      },
      rewards: {
        overrideHeader: 'Rewards',
      },
      payment_option: {
        overrideHeader: 'Payment Option',
      },
      deductible_remaining: {
        overrideHeader: 'Deductible Remaining',
      },
      out_of_pocket_remaining: {
        overrideHeader: 'Out of Pocket Remaining',
      },
      pharmacy_transfer_status: {
        overrideHeader: 'Pharmacy Transfer Status',
      },
      oldNpi: {
        overrideHeader: 'Old Npi',
      },


      oldPharmacy: {
        overrideHeader: 'Old Pharmacy',
      },
      switchToPharmacy: {
        overrideHeader: 'Switch to NPI',
      },
      switch_pharmacy_response_phone: {
        overrideHeader: 'Switch Pharmacy Response Phone',
      },
      switch_pharmacy_text_response: {
        overrideHeader: 'Switch Pharmacy Text Response',
      },
      pharmacyaddress2: {
        overrideHeader: 'Set Transfer Status',
      },
      pa_override: {
        overrideHeader: 'PA Override',
      }

    }

    const statesData = Object.entries(statesJSON).map(([k, v]) => ({
      key: k,
      text: `${k} - ${v}`,
      value: k,
    }))
    const domain = props.state.domain.map((val, idx) => ({
      key: idx,
      text: val.id,
      value: val.id.toUpperCase(),
    }))
    const rxStatusOptions = rxStatusValues.map((val, idx) => ({
      key: idx,
      text: val,
      value: val.toUpperCase(),
    }))

    const serverSideGridConfig = {
      rowModel: 'serverSide',
      serverSideStoreType: 'partial',
      cacheBlockSize: rxreportPerPage,
      pagination: true,
      paginationPageSize: rxreportPerPage,
    }
    const serverSideGridParams = {
      form,
      apiId: 'rxreport-lookup',
      sagaToCall: actions.getRxreportLookupData,
      headers,
    }

    return (
      <div id="rxreportlookup">-
        <BreadCrumb {...this.props} />
        <div className="header">Rxreport Lookup</div>
        <div className="subhead">
          <FliptDropdown placeholder="Domain Type" name="domain_name" value={domain_name} options={domain} simple item onChange={this._updateFields} />
          <FliptInput placeholder="Flipt Id " name="flipt_person_id" value={flipt_person_id} onChange={this._updateFields} />

          <FliptInput placeholder="Drug Name" name="drug_name" value={drug_name} onChange={this._updateFields} />


          <FliptDropdown placeholder="Rx Status" name="rx_status" value={rx_status} options={rxStatusOptions} simple item onChange={this._updateFields} />
          <FliptInput placeholder="User Id " name="user_id" value={user_id} onChange={this._updateFields} />
          <FliptDatePicker value={start_date} name="start_date" placeholder="Start Date" onChange={this._updateFields} label="" description="Start Date" />

          <FliptDatePicker value={end_date} name="end_date" placeholder="End Date" onChange={this._updateFields} label="" description="End date" />
          <FliptButton name="Search" className="primary searchButton" onClick={() => this._pharmacySearch(false)} />
        </div>

        <div className="content">
          <FliptGrid
            headers={headers}
            cellRendererParams={cellRendererParams}
            serverSideGridConfig={serverSideGridConfig}
            serverSideGridParams={serverSideGridParams}
            agGridRef={agGridRef}
            filterOptions={['contains', 'equals']}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    state: {
      app: state.app,
      loading: state.rxreportLookup.loading,
      rxreportLookupData: state.rxreportLookup.rxreportLookupData,
      rxreportPerPage: state.rxreportLookup.rxreportPerPage,
      rxreportSuggestData: state.rxreportLookup.rxreportSuggestData,
      rxreportUploadFileData: state.rxreportLookup.rxreportUploadFileData,
      totalRows: state.rxreportLookup.rxreportTotalRows,
      domain: state.user.accounts_access
    },
  }
}

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
    ...RxreportLookupActions,
  }


  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RxreportLookup)
