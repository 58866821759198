import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import './styles.scss'
import BreadCrumb from '../../../../components/breadCrumb'
import Config from '../../../../config'
import FliptButton from '../../../../components/form/fliptButton'
import FliptGrid from '../../../../components/fliptGrid'
import { Creators as NavigationActions } from '../../../../redux/reducers/navigation'
import { Creators as UserManagementActions } from '../../../../redux/reducers/userManagement'
import { LinkContainerRenderer } from '../../../../components/fliptGrid/cellRenderers'
import { convertDateTimeToDate, filterRowData } from '../../../../utils/utilities'

class UserManagement extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      agGridRef: React.createRef()
    }
  }

  componentDidMount() {
    const { actions } = this.props

    setTimeout(() => actions.getUserManagementUsers(), Config.COMPONENT_DATA_LOAD_TIME)
  }

  _dataCleanup() {
    const { state } = this.props
    return state.data.map((user) => (
      {
        full_name: `${user.first_name} ${user.last_name}`,
        email_address: user.email_address,
        status: user.is_active ? 'Active' : 'Inactive',
        uuid: user.uuid,
        phone_number: user.phone_number,
        effective_start_date: user?.effective_start_date === '' ? '---' : convertDateTimeToDate(user.effective_start_date),
        effective_end_date: user?.effective_end_date === '' ? '---' : convertDateTimeToDate(user.effective_end_date),
        company: user.company,
        roles: user.roles.filter((d) => d.enable).map((d) => d.name),
        enable_mfa: user.enable_mfa,
      }
    ))
  }

  _setUsersActive = (activeState) => {
    const { actions } = this.props
    const { agGridRef } = this.state
    const selectedUsers = agGridRef.current.api.getSelectedRows()
    const users = selectedUsers.reduce((acc, curr) => {
      const { uuid } = curr
      acc.push(uuid)
      return acc
    }, [])
    actions.updateUserManagementUsers({ activeState, users })
  }

  render() {
    const { agGridRef } = this.state
    let gridData = this._dataCleanup()
    const headers = [
      'Action',
      'Full Name',
      'Email Address',
      'Status',
      'Phone Number',
      'Roles',
      'Company',
      // 'Effective Start Date',
      // 'Effective End Date',
      'Enable MFA',
      'uuid',
    ]
    const headerMapping = {
      full_name: 'Full Name',
      // effective_start_date: 'Effective Start Date',
      // effective_end_date: 'Effective End Date',
      email_address: 'Email Address',
      phone_number: 'Phone Number',
      company: 'Company',
      roles: 'Roles',
      enable_mfa: 'Enable MFA',
      status: 'Status',
    }

    gridData = gridData?.length ? gridData.map((d) => ({
      ...filterRowData(d, headers, headerMapping),
    })) : []

    const cellRendererParams = {
      Action: {
        // TODO LinkContainerRenderer does not work with state
        cellRenderer: LinkContainerRenderer,
        searchArgs: [
          {
            path: '/user-management-view',
            searchArgs: { id: 'uuid' },
            staticState: { editMode: false },
            label: 'View',
          },
          {
            path: '/user-management-edit',
            searchArgs: { id: 'uuid' },
            staticState: { editMode: true },
            label: 'Edit',
          },
        ],
      },
      'Full Name': {
        checkboxSelection: true,
        headerCheckboxSelection: true,
      },
      uuid: {
        hide: true,
      },
    }

    return (
      <div id="userManagement">
        <BreadCrumb {...this.props} />
        <div className="header">
          <h1>User Management</h1>
        </div>
        <div className="subhead">
          <Link to={{
            pathname: '/user-management-create',
          }}
          >
            <FliptButton className="primary" name="Create User" />
          </Link>
          <div className="searchForm" />
        </div>
        <div className="buttons">
          <FliptButton className="secondary" name="Activate Selected Users" onClick={() => this._setUsersActive(true)} />
        </div>
        <div className="buttons">
          <FliptButton className="secondary" name="Inactivate Selected Users" onClick={() => this._setUsersActive(false)} />
        </div>
        <div className="contents">
          <FliptGrid
            data={gridData}
            headers={headers}
            cellRendererParams={cellRendererParams}
            rowSelection={'multiple'}
            suppressRowClickSelection={true}
            agGridRef={agGridRef}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  state: {
    data: state.userManagement.data,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...UserManagementActions,
    ...NavigationActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserManagement)
