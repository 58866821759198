import React, { useState, useEffect } from "react";
import { Menu, Sidebar } from "semantic-ui-react";
import { NavLink, Link, useLocation } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Creators as UserActions } from "../../../redux/reducers/user";
import { Creators as AppActions } from "../../../redux/reducers/app";
import LogoutButton from "../../headers/logoutButton";
//import './styles.scss';

function SidebarMenu(props) {
  const { menuList, goToSubMenu, state, actions, auth0Client } = props;
  const { toggleSidebar } = actions;
  const { sidebarIsOpen } = state.app;
  const [homeActive, setHomeActive] = useState(false);
  const location = useLocation();

  useEffect(() => {
    // Set homeActive to true if the current path is '/home'
    setHomeActive(location.pathname === "/home");
  }, [location.pathname]);

  const handleHomeButtonClick = () => {
    // Deactivate home button if it's already active
    if (homeActive) {
      setHomeActive(true);

    }
  };

  const handleNavButtonClick = () => {
    // Deactivate home button if it's active
    if (homeActive) {
      setHomeActive(false);
    }
  };


  userLogout = () => {
    if (!this || !this.props) {
      window.location.href = '/'
    }
    const { actions } = this.props;
    actions.userLogout();
  };

  return (
    <div className="sidebarMenu">
      <Sidebar
        as={Menu}
        animation="push"
        direction="left"
        icon="labeled"
        inverted
        vertical
        visible={true}
        width="thin"
      >
        <div className="small-bound">
          <div className="logoContainer">
            <Link to="/home">
              <img className="logo" src={"/i/flipt-Logo.svg"} alt="Flipt Logo" />
            </Link>
            <button className="toggleNav" onClick={toggleSidebar} type="button">
              <img src={"/i/sidebar-arrow-right.svg"} alt="open navigation bar" />
            </button>
          </div>

          {/* Home button */}
          <div className="item-list-container">
            <NavLink
              as="a"
              className="item-list"
              onClick={handleNavButtonClick}
              to={"/home"}
            >
              <img src="/i/home.svg" className="svg icon-inActive" alt="Home Icon" />
            </NavLink>
          </div>

          {/* Render menu items */}
          {menuList.map((item, index) => (
            <React.Fragment key={index}>
              {/* Check if the item is hidden */}
              {!item.hidden && item.name !== 'Contact and Help' && (
                <>
                  <div className="item-list-container">
                    <NavLink
                      as="a"
                      className="item-list"
                      onClick={handleNavButtonClick}
                      to={item.path}
                    >
                      <img src={item.icon} className="svg icon-inActive" alt={item.name} />
                    </NavLink>
                  </div>
                  {/* Render sub menu if children exist */}
                  {item.children && (
                    <div className="accordion hoverClass">
                      <span className="submenu-title">{item.name}</span>
                      {item.children.map((subItem, subIndex) => (
                        <NavLink
                          key={subIndex}
                          className="submenu_active"
                          to={subItem.path}
                          onClick={() => goToSubMenu(subItem, subIndex)}
                        >
                          {subItem.name}
                        </NavLink>
                      ))}
                    </div>
                  )}
                </>
              )}
            </React.Fragment>
          ))}
        </div>
        <div>
          <div className="preferencesContainer">
            <div className="preferencesContainer_topSection__item">
              <NavLink
                as="a"
                className="preferencesContainer_topSection__navLink"
                to="/contact-help"
              >
                <img src="/i/help.svg" />
              </NavLink>
            </div>

            <NavLink
              as="a"
              className="preferencesContainer_topSection__navLink"
              to={"/settings"}
            >
              <img src="/i/settings.svg" />
            </NavLink>

            <div className="preferencesContainer_bottomSection">
              <LogoutButton userLogout={() => actions.userLogout()} auth0Client={auth0Client} />
            </div>
          </div>
        </div>
      </Sidebar>

      {/* <Sidebar.Pusher>
          <Segment basic></Segment>
        </Sidebar.Pusher> */}
      {/* </Sidebar.Pushable> */}
    </div>
  );
}

const mapStateToProps = (state) => ({
  state: {
    app: state.app,
    user: state.user,
    auth0Client: state?.ssoLogin?.auth0Client,
  },
});

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...UserActions,
    ...AppActions,
  };
  return {
    actions: bindActionCreators(allActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SidebarMenu);
