import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import './styles.scss'
import BreadCrumb from '../../../components/breadCrumb'
import ClaimTable from '../../../components/fliptGrid'
import FliptButton from '../../../components/form/fliptButton'
import FliptDatePicker from '../../../components/form/fliptDatePicker'
import FliptDropdown from '../../../components/form/fliptDropdown'
import FliptInput from '../../../components/form/fliptInput'
import { LinkContainerRenderer, PHILoader } from '../../../components/fliptGrid/cellRenderers'
import { Creators as ApiActions } from '../../../redux/reducers/api/claimLookupManagement'
import { Creators as AppActions } from '../../../redux/reducers/app'

import { convertStrToDateObj, parseQueryString } from '../../../utils/utilities'

class FinancialInformationReporting extends Component {
    constructor(props) {
        super(props)

        this.state = {
            form: {
                fx_id: '',
                cardholder_id: '',
                start_date: '',
                end_date: '',
                first_name: '',
                last_name: '',
                txnType: '',
            },
            firTxnData: [],
        }
    }

    componentDidMount() {
        const { actions, history } = this.props
        const { form } = this.state
        const qs = parseQueryString(history?.location?.search)
        if (qs?.cardholder_id) {
            actions.getFirTxns(qs)
            form.cardholder_id = qs.cardholder_id
            this.setState({ form })
        }
        else actions.getFirTxns()
    }

    componentDidUpdate(prevProps) {
        const { firTxnData } = this.props.state
        if (firTxnData !== prevProps.state.firTxnData) {
            this.setState({ firTxnData })
        }
    }


    handleClick = () => {
        const { form: { first_name, last_name, txnType, cardholder_id, fx_id, start_date } } = this.state
        const { actions } = this.props
        if (first_name && !last_name) {
            const transitionalPortal = {
                header: 'Fields are missing',
                copy: 'Please add Last Name to search member by name',
            }
            actions.displayTransitionalPortal(transitionalPortal)
            return;
        }
        if (!first_name && last_name) {
            const transitionalPortal = {
                header: 'Fields are missing',
                copy: 'Please add First Name to search member by name',
            }
            actions.displayTransitionalPortal(transitionalPortal)
            return;
        }
        const params = {}
        if (txnType) params.transaction_type = txnType
        if (cardholder_id) params.cardholder_id = cardholder_id
        if (fx_id) params.transaction_id = fx_id
        if (start_date) params.start_date = start_date
        if (first_name) params.first_name = first_name
        if (last_name) params.last_name = last_name
        actions.getFirTxns(params)
    }

    handleClear = () => {
        const { form } = this.state
        Object.keys(form).forEach((key) => {
            form[key] = ''
        })
        this.setState({ form })
    }

    updateField = (el, dropdown) => {
        const { form } = this.state
        const { name, value } = dropdown || el.currentTarget
        form[name] = value
        this.setState({ form })
    }

    render() {
        const headers = [
            'action', 'fx_id', 'create_dt', 'transaction_type', 'transaction_response_status', 'cardholder_id',
            'first_name', 'last_name', 'plan_name', 'contract_id', 'pbp'
        ]

        const { state: { user } } = this.props
        const { form, firTxnData } = this.state
        const cellRendererParams = {
            action: {
                cellRenderer: LinkContainerRenderer,
                searchArgs: [
                    {
                        path: '/fir-transaction-details',
                        searchArgs: { fx_id: 'fx_id' },
                        state: { fx_id: 'fx_id' },
                        label: 'Transaction Details',
                    }
                ],
                width: 200,
            },
            first_name: {
                cellRenderer: PHILoader,
                filter: false
            },
            last_name: {
                cellRenderer: PHILoader,
                filter: false
            },
            contract_id: {
                cellRenderer: PHILoader,
                filter: false
            },
            pbp: {
                cellRenderer: PHILoader,
                filter: false,
                overrideHeader: 'PBP'
            },
            plan_name: {
                cellRenderer: PHILoader,
                filterParams: {
                    filterOptions: ['contains', 'equals'],
                    defaultJoinOperator: 'OR',
                },
            },
            cardholder_id: {
                overrideHeader: 'Member ID'
            },
            fx_id: {
                overrideHeader: 'Transaction ID'
            },
            create_dt: {
                overrideHeader: 'Transaction Date'
            }
        }
        const txnTypeOptions = ['F1', 'F2', 'F3'].reverse().map((val, idx) => ({
            key: idx,
            text: val,
            value: val.toUpperCase(),
        }))
        return (
            <div id="fir">
                <BreadCrumb {...this.props} />
                <div className="header">
                    <FliptButton name="Advanced Search" className="invisible" />
                </div>
                <div className="subhead">
                    <FliptInput value={form.fx_id} placeholder="FIR Transaction ID" name="fx_id" onChange={this.updateField} />
                    <FliptInput value={form.cardholder_id} placeholder="Cardholder ID" name="cardholder_id" onChange={this.updateField} />
                    <FliptInput value={form.first_name} placeholder="First Name" name="first_name" onChange={this.updateField} />
                    <FliptInput value={form.last_name} placeholder="Last Name" name="last_name" onChange={this.updateField} />
                    <FliptDatePicker placeholder="Start Date" name="start_date" onChange={this.updateField} value={convertStrToDateObj(form.start_date)} className="overlap-index" />
                    <FliptDatePicker placeholder="End Date" name="end_date" onChange={this.updateField} value={convertStrToDateObj(form.end_date)} />
                    <FliptDropdown
                        className="create-formulary-tier-list"
                        placeholder="Transacation Type"
                        value={form.txnType}
                        selection
                        options={txnTypeOptions}
                        simple
                        item
                        name="txnType"
                        onChange={this.updateField}
                    />

                    <FliptButton name="Search" className="primary searchButton" onClick={this.handleClick} />
                    <FliptButton name="Clear" className="primary searchButton" onClick={this.handleClear} />
                </div>
                <div className="content">
                    <ClaimTable
                        headers={headers}
                        cellRendererParams={cellRendererParams}
                        data={firTxnData || []}
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    state: {
        firTxnData: state.claimLookup.firTxnData,
        user: state.user,
    },
})

const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...ApiActions,
        ...AppActions
    }

    return {
        actions: bindActionCreators(allActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FinancialInformationReporting)
