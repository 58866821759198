import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { createQueryString } from '../../../../utils/utilities'
import FliptPopup from '../../../../components/fliptPopup'
import { defaultTabConfigs } from '../data/tabData'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
//import './styles.scss'

const PopupCellRenderer = (props) => {
  const { data, searchArgs, state: { selectedTab } } = props
  const { onClick } = searchArgs
  const { status } = data
  const [visible, setVisible] = useState(false)
  const show = () => setVisible(true)
  const hide = () => setVisible(false)
  const params = { doc_id: 'doc_id', doc_name: 'doc_name', doc_version: 'doc_version' }
  Object.keys(params).forEach((key) => params[key] = (params[key] in data) ? data[params[key]] : '')
  const editQs = createQueryString(params)
  const dropDownContent = (
    <div className="menu-container">
      {(status === 'Draft') ? (<Link to={selectedTab === defaultTabConfigs[0].id ? { pathname: '/contract-management', search: editQs, state: { editMode: true } } :
        { pathname: '/create-processor', search: editQs, state: { editMode: true, ...data } }}>
        <div
          role="link"
          tabIndex={-1}
          disabled={status !== 'Draft'}
          className="menu-item"
        >
          Edit
        </div>
      </Link>) : (
        <div
          role="link"
          tabIndex={-1}
          disabled={status !== 'Draft'}
          className="menu-item"
        >
          Edit
        </div>
      )}
      <div
        role="button"
        tabIndex={-2}
        disabled={status !== 'Draft'}
        onClick={() => hide() || onClick && onClick('publish_doc', data)}
        className={`menu-item`}
      >
        Publish
      </div>
      <div
        role="button"
        tabIndex={-3}
        disabled={status !== 'Published'}
        onClick={() => hide() || onClick && onClick('create_new_version', data)}
        className={`menu-item`}
      >
        Create New Version
      </div>
    </div>
  )
  const popupStyle = {
    boxShadow: 'none !important',
    backgroundColor: 'transparent',
    border: 0,
  }
  return (
    <FliptPopup
      basic
      eventsEnabled
      id='drug-management-popup'
      onClose={hide}
      onOpen={show}
      open={visible}
      style={popupStyle}
      trigger={<button style={{ backgroundColor: 'transparent', color: 'red', fontSize: '1.5em' }} type="button" className="btn btn-primary" onClick={visible ? hide : show}> ... </button>}
    >
      {dropDownContent}
    </FliptPopup>
  )
}

const mapStateToProps = (state) => ({
  state: {
    selectedTab: state.contractManagement.selectedTab
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {}

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(PopupCellRenderer)

