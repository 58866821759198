import React, { useEffect, Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import './styles.scss'
import Config from '../../../config'
import BreadCrumb from '../../../components/breadCrumb'
import ClaimTable from '../../../components/fliptGrid'
import FliptButton from '../../../components/form/fliptButton'
import FliptDatePicker from '../../../components/form/fliptDatePicker'
import FliptDropdown from '../../../components/form/fliptDropdown'
import FliptInput from '../../../components/form/fliptInput'
import { ColorCodedStatusRenderer, CheckboxRenderer, PHILoader } from '../../../components/fliptGrid/cellRenderers'
import { Creators as ApiActions } from '../../../redux/reducers/api/drugMappingLookup'
import { Creators as AppActions } from '../../../redux/reducers/app'
import { filterRowData, buildDropdownOptions } from '../../../utils/utilities'

import {
  createQueryString, parseQueryString, convertStrToDateObj,
} from '../../../utils/utilities'
import moment from 'moment'
import { dropWhile } from 'lodash'

class DrugMappingLookup extends Component {
  constructor(props) {
    super(props)
    this.state = {
      form: {
        module: '',
        doc_name: '',
        gpi: '',
        ndc: '',
        ddid: '',
        moduleNames: [],
      },
      reRender: 0,
      agGridRef: React.createRef(),
    }
  }

  componentDidMount() {
    const { history, state, actions } = this.props
    const { location } = history
    if (!location) return
    const { form, agGridRef } = this.state
    this._qsToFormData()
    actions.getDrugMappingOptionsData()
    history.location.search = createQueryString(form)
    agGridRef.current.api.refreshServerSideStore({ route: null, purge: true }) //to refresh the grid server side data
  }

  buildModuleOptions(dropdownValues) {
    if (!dropdownValues) return []
    return dropdownValues.map((dropdownValue, index) => ({
      key: index,
      text:  ((dropdownValue.toUpperCase().replace('_', ' ') === "TIER") ? "DRUG LIST" : dropdownValue.toUpperCase().replace('_', ' ')),
      value: dropdownValue,
    }))
  }
  _qsToFormData = () => {
    const { history } = this.props
    const { form } = this.state
    const qs = parseQueryString(history.location.search)

    Object.keys(qs).forEach((key) => {
      form[key] = qs[key]
    })
    this.setState({ form })
  }

  handleClick = () => {
    const { form, agGridRef } = this.state
    const { history, actions } = this.props
    this.setState({ form })
    history.location.search = createQueryString(form)
    agGridRef.current.api.refreshServerSideStore({ route: null, purge: true }) //to refresh the grid server side data
  }

  handleAction = () => {
    const { form, agGridRef } = this.state
    const { history, actions } = this.props
    this.setState({ form })
    history.location.search = createQueryString(form)
    agGridRef.current.api.refreshServerSideStore({ route: null, purge: true }) //to refresh the grid server side data
  }

  handleClear = () => {
    const { form, reRender = 0, agGridRef } = this.state
    Object.keys(form).forEach((key) => {
      if (key === 'moduleNames') {
        form[key] = []
      }
      else {
        form[key] = ''
      }

    })
    this.setState({ form, reRender: reRender + 1 })
    const { history, actions } = this.props
    this._qsToFormData()
    actions.getDrugMappingOptionsData()
    history.location.search = createQueryString(form)

    agGridRef.current.api.refreshServerSideStore({ route: null, purge: true })
  }

  updateField = (el, dropdown) => {
    const { form } = this.state
    const { name, value } = dropdown || el.currentTarget
    form[name] = value
    if (name === 'module') {
      const { state: { moduleNamesOptions } } = this.props
      form['moduleNames'] = moduleNamesOptions[value]
    }
    this.setState({ form })
  }

  render() {

    const { actions } = this.props

    const headers = [
      'module', 'module_name', 'module_version', 'formulary_tier_name', 'gpi', 'ndc', 'ddid', 'brand_generic', 'drug_name', 'rxcui',
      'multi_source', 'otc_indicator', 'alternate_gpi_match', 'alternate_ndc_match',
      'module_start_date', 'module_end_date', 'formulary_start_date', 'formulary_end_date'
    ]

    const headerMapping = {
    }

    const { state: { user, claimsPerPage, moduleOptions } } = this.props
    const { form, agGridRef } = this.state
    const domainOptions = user.accounts_access.map((d) => ({
      key: d.id,
      text: d.id,
      value: d.id,
    }))

    const filterParams = {
      /* eslint-disable-next-line */
      comparator: (filterLocalDateAtMidnight, cellValue) => {
        const dateAsString = cellValue
        if (dateAsString == null) return -1
        const dateParts = dateAsString.split(' ')[0].split('/')
        const cellDate = new Date(
          Number(dateParts[2]),
          Number(dateParts[0]) - 1,
          Number(dateParts[1]),
        )
        if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
          return 0
        }

        if (cellDate < filterLocalDateAtMidnight) {
          return -1
        }

        if (cellDate > filterLocalDateAtMidnight) {
          return 1
        }
      },
      browserDatePicker: true,
      filterOptions: ['inRange', 'equals'],
      defaultJoinOperator: 'OR',
      suppressAndOrCondition: false,
      inRangeInclusive: true,
    }
    const cellRendererParams = {
      module: {
        overrideHeader: 'MODULE',
      },
      module_name: {
        overrideHeader: 'MODULE NAME',
      },
      module_version: {
        overrideHeader: 'VERSION',
      },
      formulary_tier_name: {
        overrideHeader: 'DRUG LIST',
      },
      gpi: {
        overrideHeader: 'GPI',
      },
      ndc: {
        overrideHeader: 'NDC',
      },
      ddid: {
        overrideHeader: 'DDID',
      },
      brand_generic: {
        overrideHeader: 'B/G',
      },
      drug_name: {
        overrideHeader: 'DRUG NAME',
      },
      rxcui: {
        overrideHeader: 'RXCUI',
      },
      multi_source: {
        overrideHeader: 'MULTI SOURCE',
      },
      otc_indicator: {
        overrideHeader: 'OTC INDICATOR',
      },
      alternate_gpi_match: {
        overrideHeader: 'ALTERNATE GPI MATCH',
        hide: true
      },
      alternate_ndc_match: {
        overrideHeader: 'ALTERNATE NDC MATCH',
        hide: true
      },
      module_start_date: {
        overrideHeader: 'MODULE START DATE',
      },
      module_end_date: {
        overrideHeader: 'MODULE END DATE',
      },
      formulary_start_date: {
        overrideHeader: 'FORMULARY START DATE',
        hide: true
      },
      formulary_end_date: {
        overrideHeader: 'FORMULARY END DATE',
        hide: true
      },
    }

    const serverSideGridConfig = {
      rowModel: 'serverSide',
      serverSideStoreType: 'partial',
      cacheBlockSize: claimsPerPage,
      pagination: true,
      paginationPageSize: claimsPerPage,
    }
    const serverSideGridParams = {
      form,
      apiId: 'drug-map-lookup',
      sagaToCall: actions.getDrugMappingLookupData,
      headers,
      headerMapping,
    }
    return (
      <div id="drugMapLookup">
        <BreadCrumb {...this.props} />
        <div className="header">
          <FliptButton name="Advanced Search" className="invisible" />
        </div>
        <div className="subhead">
          <FliptDropdown placeholder="Modules" value={form.module} onChange={this.updateField} single selection options={this.buildModuleOptions(moduleOptions)} simple item name="module" />
          <FliptDropdown placeholder="Module Name" value={form.doc_name} onChange={this.updateField} single selection options={buildDropdownOptions(form.moduleNames)} simple item name="doc_name" />
          <FliptInput value={form.gpi} placeholder="GPI" name="gpi" onChange={this.updateField} />
          <FliptInput value={form.ndc} placeholder="NDC" name="ndc" onChange={this.updateField} />
          <FliptInput value={form.ddid} placeholder="DDID" name="ddid" onChange={this.updateField} />
          <FliptButton name="Search" className="primary searchButton" onClick={this.handleClick} />
          {/* <FliptButton name="Clear Filters" className="primary searchButton" onClick={this.handleClear} /> */}
        </div>
        <div className="content">
          <ClaimTable
            headers={headers}
            cellRendererParams={cellRendererParams}
            serverSideGridConfig={serverSideGridConfig}
            serverSideGridParams={serverSideGridParams}
            agGridRef={agGridRef}
            filterOptions={['contains', 'equals']}
            rowSelection={'multiple'}
            doAutoSize
            suppressColumnVirtualisation
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => (
  {
    state: {
      data: state.drugMapLookup.data,
      totalRows: state.drugMapLookup.totalRows,
      claimsPerPage: state.drugMapLookup.claimsPerPage,
      moduleOptions: state.drugMapLookup?.optionsData?.moduleOptions || [],
      moduleNamesOptions: state.drugMapLookup?.optionsData?.moduleNamesOptions || [],
      user: state.user,
    },
  })

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...ApiActions,
    ...AppActions
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DrugMappingLookup)
