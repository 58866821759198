import React from 'react'
import {
  Redirect,
  Route,
  useLocation,
} from 'react-router-dom'

export default ({ component, componentProps, rest }) => {
  const isAuthed = componentProps.state.app.isLoggedIn
  const { state } = useLocation()
  return (
    <Route
      {...rest}
      exact
      render={(props) => (
        !isAuthed ? (
          <div>
            { React.createElement(component, props) }
          </div>
        ) : (
          <Redirect
            to={{
              pathname: state && state.from ? state.from.pathname : '/home',
              state: { from: props.location },
              search: props.location.search,
            }}
          />
        )
      )}
    />
  )
}
