import React, { Component } from 'react';
import FliptGrid from '../../../../../components/fliptGrid';
import FliptInput from '../../../../../components/form/fliptInput';
import SubmenuNav from '../shared/navigation'
import BreadCrumb from '../../../../../components/breadCrumb'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Config from '../../../../../config'
import * as _ from 'lodash';
import { Creators as ApiActions } from '../../../../../redux/reducers/api/claimLookupManagement'
import { ButtonRenderer } from '../../../../../components/fliptGrid/cellRenderers'
import { formatAmt, parseQueryString } from '../../../../../utils/utilities'

import './styles.scss'

const CURRENCY_SYMBOL = '$';
class ClaimTransactionMedicareDPartDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {
      deductible: {},
      initialCoverage: {},
      catastrophic: {},
      coverageGap: {},
      activeContent: {},
      pde: {},
      preAdjAcc: {},
      postAdjAcc: {}
    }
  }

  componentDidMount() {
    const { actions, history } = this.props
    const { search } = history.location
    const payload = parseQueryString(search)
    const { transaction_id } = payload
    // TODO FIX THIS. SO NO MORE SETTIMEOUT
    setTimeout(() => actions.getClaimTransactionCostShare({ transactionId: transaction_id }), Config.COMPONENT_DATA_LOAD_TIME)
  }

  componentDidUpdate(prevProps) {
    const { state } = this.props
    const { claimTransactionCostShareInfo } = state
    if (claimTransactionCostShareInfo !== prevProps.state.claimTransactionCostShareInfo) {
      const { medicare_phase_cost_share = [], medicare_net_cost_share = {},
        pre_adjudication_accumulation = {}, post_adjudication_accumulation = {} } = claimTransactionCostShareInfo || {}
      this.setState({
        pde: medicare_net_cost_share, preAdjAcc: pre_adjudication_accumulation,
        postAdjAcc: post_adjudication_accumulation
      })
      if (!medicare_phase_cost_share.length) {
        return
      }
      medicare_phase_cost_share.map((data) => {
        switch (data.phase) {
          case 'Deductible': {
            this.setState({ deductible: data })
          }
            break;
          case 'Initial Coverage': {
            this.setState({ initialCoverage: data })
          }
            break;
          case 'Catastrophic': {
            this.setState({ catastrophic: data })
          }
            break;
          case 'Coverage Gap': {
            this.setState({ coverageGap: data })
          }
            break;
        }
      })
    }
  }


  subContent = (rowIndex) => {
    const { state } = this.props
    const { claimTransactionCostShareInfo } = state
    const { medicare_phase_cost_share = [] } = claimTransactionCostShareInfo || {}
    if (medicare_phase_cost_share.length <= 0) {
      return
    }
    this.setState({ activeContent: medicare_phase_cost_share[rowIndex] })
  }

  renderAccFields = (data) => {
    // if (!data?.phase) {
    //   return (
    //     <>
    //       <FliptInput value='$0.00' disabled />
    //       <FliptInput value='$0.00' disabled />
    //       <FliptInput value='$0.00' disabled />
    //       <FliptInput value='$0.00' disabled />
    //       <FliptInput value='' disabled />
    //       <FliptInput value='' disabled />
    //     </>
    //   )
    // }
    const { preAdjAcc, postAdjAcc } = this.state
    const { state } = this.props
    const { claimTransactionCostShareInfo } = state
    const { beginning_phase, ending_phase } = claimTransactionCostShareInfo || {}
    return (
      <>
        <FliptInput value={formatAmt(preAdjAcc.gcdc_accumulator_amount, CURRENCY_SYMBOL)} disabled />
        <FliptInput value={formatAmt(preAdjAcc.troop_accumulator_amount, CURRENCY_SYMBOL)} disabled />
        <FliptInput value={formatAmt(postAdjAcc.gcdc_accumulator_amount, CURRENCY_SYMBOL)} disabled />
        <FliptInput value={formatAmt(postAdjAcc.troop_accumulator_amount, CURRENCY_SYMBOL)} disabled />
        <FliptInput value={beginning_phase} disabled />
        <FliptInput value={ending_phase} disabled />
      </>
    )
  }

  renderFields = (data, isPde = false) => {
    const { preAdjAcc } = this.state
    return (
      <>
        <FliptInput value={formatAmt(data?.ingredient_cost, CURRENCY_SYMBOL)} disabled />
        <FliptInput value={formatAmt(data?.dispensing_fee, CURRENCY_SYMBOL)} disabled />
        <FliptInput value={formatAmt(data.gdcb, CURRENCY_SYMBOL)} disabled />
        <FliptInput value={formatAmt(data.gdca, CURRENCY_SYMBOL)} disabled />
        <FliptInput value={formatAmt(data?.patient_pay, CURRENCY_SYMBOL)} disabled />
        <FliptInput value={formatAmt(data.plro, CURRENCY_SYMBOL)} disabled />
        <FliptInput value={formatAmt(data.cpp, CURRENCY_SYMBOL)} disabled />
        <FliptInput value={formatAmt(data.npp, CURRENCY_SYMBOL)} disabled />
        <FliptInput value='' disabled />
        <FliptInput value={formatAmt(data.lics, CURRENCY_SYMBOL)} disabled />
        <FliptInput value='' disabled />
        <FliptInput value='' disabled />
        <FliptInput value={formatAmt(data?.accumulator_amounts?.gcdc_accumulator_amount, CURRENCY_SYMBOL)} disabled />
        <FliptInput value={formatAmt(data?.accumulator_amounts?.troop_accumulator_amount, CURRENCY_SYMBOL)} disabled />
        <FliptInput value={data.gap_discount ? formatAmt(data.gap_discount, CURRENCY_SYMBOL) : isPde ? '$0.00' : 0} disabled />
      </>
    )
  }

  _renderContent = () => {
    const { state } = this.props
    const { claimTransactionCostShareInfo } = state
    const { medicare_phase_cost_share = [] } = claimTransactionCostShareInfo || {}

    if (_.isEmpty(this.state.activeContent) && medicare_phase_cost_share.length > 0) {
      this.setState({
        activeContent: medicare_phase_cost_share[0]
      })
    }

    const {
      deductible,
      coverageGap,
      catastrophic,
      initialCoverage,
      pde
    } = this.state
    const { drug_coverage_status_code, drug_name, drug_dosage,
      drug_strength, multi_source, enhanced_tier } = claimTransactionCostShareInfo || {}
    const drugKeyValue = `${drug_name} /${drug_strength}/${drug_dosage}`
    return (
      <>
        <div className="subhead" />
        <div className='content'>
          <div className="section">
            <h2>Drug Information</h2>
            <div className="outer-container">
              <div className="fields-container">
                <FliptInput value={drug_name} disabled label="Drug Name" stylized />
                <FliptInput value={multi_source} disabled label="Medispan MONY" stylized />
                <FliptInput value={drug_coverage_status_code} disabled label="Flipt covered Status" stylized />
                <FliptInput value='' disabled label="FDA Application Type" stylized />
              </div>
              <div className="fields-container">
                <FliptInput value={drug_strength} disabled label="Drug Strength" stylized />
                <FliptInput value='' disabled label="CMS BRAND/Generic" stylized />
                <FliptInput value='' disabled label="Tier" stylized />
                <FliptInput value={enhanced_tier} disabled label="Enhanced Tier" stylized />
              </div>
              <div className="fields-container">
                <FliptInput value={drug_dosage} disabled label="Drug Dosage" stylized />
                <FliptInput value='' disabled label="CMS covered Status" stylized />
                <FliptInput value='' disabled label="Medicare Model" stylized />
              </div>
            </div>
          </div>

          <div className="section">
            <h2>Accumulation</h2>
            <div className="fields-container-grid grid">
              <div className="fields">
                <div className="spacer field-label">&nbsp;</div>
                <div className="field-label">Pre-Claim GCDC Accum</div>
                <div className="field-label">Pre-Claim TROOP Accum</div>
                <div className="field-label">Post-Claim GCDC Accum</div>
                <div className="field-label">Post-Claim TROOP Accum</div>
                <div className="field-label">Beginning Phase</div>
                <div className="field-label">Ending Phase</div>
              </div>
              <div className="fields">
                <div className="title">Summary</div>
                {this.renderAccFields(deductible)}
              </div>
            </div>
          </div>


          <div className="section">
            <h2>Claim Information</h2>
            <div className="fields-container-grid grid">
              <div className="fields">
                <div className="spacer field-label">&nbsp;</div>
                <div className="field-label">Ingredient Cost</div>
                <div className="field-label">Dispensing Fee</div>
                <div className="field-label"> GDCB</div>
                <div className="field-label"> GDCA</div>
                <div className="field-label">Patient Pay</div>
                <div className="field-label"> PLRO</div>
                <div className="field-label">CPP</div>
                <div className="field-label">NPP</div>
                <div className="field-label"> Other TROOP</div>
                <div className="field-label"> LICS</div>
                <div className="field-label">Vaccine Admin fee</div>
                <div className="field-label">POS Rebate</div>
                <div className="field-label">GCDC Acc</div>
                <div className="field-label">TROOP Acc</div>
                <div className="field-label">GAP Discount</div>
              </div>
              <div className="fields">
                <div className="title">Deductible</div>
                {this.renderFields(deductible)}
              </div>
              <div className="fields">
                <div className="title">Initial Coverage</div>
                {this.renderFields(initialCoverage)}
              </div>
              <div className="fields">
                <div className="title">Coverage Gap</div>
                {this.renderFields(coverageGap)}
              </div>
              <div className="fields">
                <div className="title">Catastrophic</div>
                {this.renderFields(catastrophic)}
              </div>
              <div className="fields">
                <div className="title">Claim Summary</div>
                {this.renderFields(pde, true)}
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  _renderEmptyContent = () => (
    <div>Unavailable Data</div>
  )

  render() {
    const { history, state } = this.props
    const { claimTransactionCostShareInfo } = state
    const hasContent = claimTransactionCostShareInfo?.medicare_phase_cost_share ? Object.keys(claimTransactionCostShareInfo.medicare_phase_cost_share) : []
    return (
      <div id="claimTransactionMedicarePartDDetails" className="content-co">
        <BreadCrumb {...this.props} />
        <div className="header">
          <h1>Transaction</h1>
          <SubmenuNav history={history} active="medicareDetails" />
        </div>
        <div className="subhead" />
        {this._renderContent()}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  state: {
    claimTransactionCostShareInfo: state.claimLookup.claimTransactionCostShareData
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...ApiActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(ClaimTransactionMedicareDPartDetails);
