import React from 'react'

import './styles.scss'

export default function FliptInput(props) {
  const {
    disabled, className, onClick, name, style,
  } = props
  return (
    <input type="button" disabled={disabled} className={className} onClick={onClick} value={name} style={style || {}} />
  )
}
