import {
  put,
  select,
  takeLatest,
} from 'redux-saga/effects'

import { Creators as AppActions } from '../../reducers/app'
import { Creators as CompanyManagementActions, Types as CompanyManagementTypes } from '../../reducers/companyManagement'
import { Creators as NavigationActions } from '../../reducers/navigation'
import { fetchGet, fetchPut, fetchPatch } from '../../../utils/fetchApi'
import { getAppState } from '../../reducers/selectors'

export default [
  createCompanyManagementDataWatcher,
  editCompanyManagementDataWatcher,
  getCompanyDataWatcher,
  getCompanyListDataWatcher,
  editCompanyHierarchyDataWatcher,
]

/* WATCHERS */
function* getCompanyListDataWatcher() {
  yield takeLatest(CompanyManagementTypes.GET_COMPANY_LIST_DATA, getCompanyListDataHandler)
}
function* createCompanyManagementDataWatcher() {
  yield takeLatest(CompanyManagementTypes.CREATE_COMPANY_MANAGEMENT_DATA, createCompanyManagementDataHandler)
}
function* editCompanyHierarchyDataWatcher() {
  yield takeLatest(CompanyManagementTypes.EDIT_COMPANY_HIERARCHY_DATA, editCompanyHierarchyDataHandler)
}
function* editCompanyManagementDataWatcher() {
  yield takeLatest(CompanyManagementTypes.EDIT_COMPANY_MANAGEMENT_DATA, editCompanyManagementDataHandler)
}
function* getCompanyDataWatcher() {
  yield takeLatest(CompanyManagementTypes.GET_COMPANY_DATA, getCompanyDataHandler)
}

/* HANDLERS */
function* getCompanyListDataHandler() {
  try {
    const appState = yield select(getAppState)
    const { data } = yield fetchGet(`${appState.apiUrl}/fast/company-management-get`)
    yield put(CompanyManagementActions.setCompanyListData(data))
  } catch (err) {
    const transitionalPortal = {
      header: 'Unable to get companies',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* getCompanyDataHandler({ payload }) {
  try {
    const appState = yield select(getAppState)
    const { data } = yield fetchGet(`${appState.apiUrl}/fast/company-management-get`, payload)
    yield put(CompanyManagementActions.clearCompanyData())
    yield put(CompanyManagementActions.setCompanyData(data[0]))
  } catch (err) {
    const transitionalPortal = {
      header: 'Unable to get companies',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* createCompanyManagementDataHandler({ payload }) {
  try {
    const { apiUrl } = yield select(getAppState)
    yield fetchPut(`${apiUrl}/fast/company-management`, payload)
    const transitionalPortal = {
      header: 'Organization Sucessfully Create',
      copy: `${payload.company_full_name} created`,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    yield put(NavigationActions.navigateTo({ pathname: '/company-management' }))
  } catch (err) {
    const transitionalPortal = {
      header: 'Organization Creation Failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* editCompanyManagementDataHandler({ payload }) {
  try {
    const { apiUrl } = yield select(getAppState)
    yield fetchPatch(`${apiUrl}/fast/company-management`, payload)
    const transitionalPortal = {
      header: 'Organization Sucessfully Updated',
      copy: `${payload.company_full_name} Updated`,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    yield put(NavigationActions.navigateTo({ pathname: '/company-management' }))
  } catch (err) {
    const transitionalPortal = {
      header: 'Organization Update Failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* editCompanyHierarchyDataHandler({ payload }) {
  try {
    const appState = yield select(getAppState)
    if (payload?.hierarchy?.id) {
      yield fetchPatch(`${appState.apiUrl}/fast/company-management-hierarchy`, payload)
    } else {
      yield fetchPut(`${appState.apiUrl}/fast/company-management-hierarchy`, payload)
    }
    // yield put(CompanyManagementActions.clearCompanyData())
    // yield put(CompanyManagementActions.setCompanyData(data))
  } catch (err) {
    const transitionalPortal = {
      header: 'Unable to set hierarchy',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}
