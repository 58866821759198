import React, { useState, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Creators as ApiActions } from '../../../../../redux/reducers/api/claimLookupManagement'
import FliptInput from '../../../../../components/form/fliptInput'
import SubmenuNav from '../shared/navigation'
import BreadCrumb from '../../../../../components/breadCrumb'
import Config from '../../../../../config'
import FliptGrid from '../../../../../components/fliptGrid';
import './styles.scss'
import _ from 'lodash'

const CompoundIngredients = (props) => {
  const { history, state } = props
  const { claimOverview } = state
  const { claim_section='' } = claimOverview || {}
  const { compound_code='', compound_ingredients=[], compound_type='' } = claim_section || {}
  useEffect(()=>{
    const { actions } = props
    setTimeout(() => actions.getClaimTransactionData(), Config.COMPONENT_DATA_LOAD_TIME)
  }, [])

  const headers = [
    'basis_of_cost_determination',
    'ingredient_drug_cost',
    'ingredient_modifier_code',
    'ingredient_modifier_code_count',
    'ingredient_quantity',
    'product_id',
    'product_id_qualifier'
]
  return (
    <div id="claimTransactionIngredients">
      <BreadCrumb {...props} />
      <div className="header">
        <h1>Compound Ingredients</h1>
        <SubmenuNav history={history} active="ingredients" />
      </div>
      <div className="subhead" />
      <div className="content">
        <div className="section">
          <div className="fields-container">
            <div className="fields">
              <FliptInput value={compound_code} label="Compound Code" stylized disabled />
              <FliptInput value={compound_type} label="Compound Type" stylized disabled />
            </div>
            <div className="gridcontent">
                <FliptGrid  
                    data={compound_ingredients}
                    headers={headers} />        
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
const mapStateToProps = (state) => ({
  state: {
    claimOverview: state.claimLookup.claimTransactionData.overview_section,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...ApiActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompoundIngredients)