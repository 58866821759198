import {
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects'
import { v4 as uuidv4 } from 'uuid'

import { Types, Creators as ProgramListCreationActions } from '../../reducers/api/programListManagement'
import { Creators as RPMActions } from '../../reducers/rpm'
import { Creators as AppActions } from '../../reducers/app'
import { Creators as NavigationActions } from '../../reducers/navigation'
import { Creators as UserActions } from '../../reducers/user'
import { getAppState, getApiPath, getHistory, getUserState } from '../../reducers/selectors'
import { fetchPost } from '../../../utils/fetchApi'
import { updateCriteriaIdsHandler } from './qualifiers'
import SystemErrorModal from '../../../utils/systemError'
import * as ApprovalsConstants from '../rpm/constants'
import { convertDateToStrObj, createQueryString, filterEmptyfromObj, filterRowData } from '../../../utils/utilities'
import { deleteUnnecessaryFields } from '../../../utils/utilizationManagement'

export default [
  approveProgramListWatcher,
  generateProgramListWatcher,
  getAllProgramListDataWatcher,
  getProgramListDataWatcher,
  saveProgramListDataWatcher,
  generateProgramListLevelExportWatcher
]

/* WATCHERS */
function* getAllProgramListDataWatcher() {
  yield takeLatest(Types.GET_ALL_PROGRAM_LIST_DATA, getAllProgramListDataHandler)
}

function* generateProgramListWatcher() {
  yield takeLatest(Types.GENERATE_PROGRAM_LIST, generateProgramListHandler)
}

function* generateProgramListLevelExportWatcher() {
  yield takeLatest(Types.GENERATE_PROGRAM_LIST_LEVEL_EXPORT, generateProgramListLevelExportHandler)
}

function* saveProgramListDataWatcher() {
  yield takeLatest(Types.SAVE_PROGRAM_LIST_DATA, saveProgramListDataHandler)
}

function* getProgramListDataWatcher() {
  yield takeLatest(Types.GET_PROGRAM_LIST_DATA, getProgramListDataHandler)
}

function* approveProgramListWatcher() {
  yield takeLatest(Types.APPROVE_PROGRAM_LIST, approveProgramListHandler)
}

/* HANDLERS */
function* getAllProgramListDataHandler() {
  try {
    const { userCAG = {} } = yield select(getUserState)
    const body = {
      module: 'PROGRAM_LIST',
      status: ApprovalsConstants.ALL_RPM_STATUSES,
    }

    yield put(RPMActions.rpmGetApprovalDocuments(body))
  } catch (err) {
    console.log('getAllProgramListDataHandler Error >Data ', err)

    const transitionalPortal = {
      header: 'Program List Lookup Failed',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* generateProgramListLevelExportHandler({ payload, callback }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const cd = yield select(getApiPath, 'generate-program-list')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`
    const { filename } = payload
    const { data } = yield call(fetchPost, url, payload)
    if (!data.length) throw Error('No Drugs found for the input conditions')
    const { programConditionLevel } = payload
    if (callback) {
      yield callback(data, filename)
    }
    //yield put(ProgramListCreationActions.setProgramListLevelDrugData({ data, programConditionLevel }))
  } catch (err) {
    console.log('generateProgramListLevelExportHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Generate Program List Failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* generateProgramListHandler({ payload }) {
  try {
    const {
      form, params, headers, headerMapping
    } = payload
    let get_total_rows = true
    if (params?.request.startRow > 0) {
      get_total_rows = false
    }
    const { serviceUrl } = yield select(getAppState)
    const include_inactive_drugs = form.include_inactive_drugs
    const reqData = {
      drugConditions: [deleteUnnecessaryFields(form)],
      limit: 20,
      offset: params?.request.startRow,
      get_total_rows,
      filterModel: params?.request.filterModel,
      sortModel: params?.request.sortModel,
      include_inactive_drugs,
    }
    const { api_path } = yield select(getApiPath, 'generate-program-list')
    const url = `${serviceUrl}${api_path}`
    const { data, last_row } = yield call(fetchPost, url, reqData)
    const gridData = data?.map((d) => ({
      ...filterRowData(d, headers, headerMapping),
      alternate_gpi_match: form.alternate_gpi_match,
      alternate_ndc_match: form.alternate_ndc_match,
      effective_start_date: convertDateToStrObj(form.effective_start_date),
      effective_end_date: convertDateToStrObj(form.effective_end_date)
    }))
    if (get_total_rows) {
      params?.success({
        rowData: gridData,
        rowCount: last_row,
      })
    } else {
      params?.success({
        rowData: gridData
      })
    }
  } catch (err) {
    console.log('generateProgramListHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Error occurred while generating program list drug conditions',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* saveProgramListDataHandler({ payload }) {
  try {
    const approvalDocument = {
      module_name: payload.doc_name,
      data: payload,
      module: 'PROGRAM_LIST',
      status: 'DRAFT',
    }
    // eslint-disable-next-line
    const cb = function* ({ success }) {
      if (success) yield put(NavigationActions.navigateBack())
    }
    const criteria_ids = yield call(updateCriteriaIdsHandler, { payload })
    approvalDocument.data.conditions.criteriaIds = criteria_ids
    delete approvalDocument.data.conditions.umConditions
    yield put(RPMActions.rpmUpsertApprovalDocument(approvalDocument, cb))
  } catch (err) {
    console.log('saveProgramListDataHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Saving Program List Failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* getProgramListDataHandler({ payload }) {
  try {
    const cb = function* cb(data) {
      const programData = data[0].module_data.data
      const program_list_data = {
        program_info: {
          custom_program_type: programData.custom_program_type,
          effective_start_date: programData.effective_start_date,
          version_effective_date: programData.version_effective_date,
          list_type: programData.list_type,
          doc_name: programData.doc_name,
          doc_version: programData.doc_version,
          doc_id: data[0].id,
          hierarchy: data[0].hierarchy,
          version: data[0].version,
          status: data[0].status,
          is_hierarchy_global: data[0].is_hierarchy_global,
          hideHierarchy: data[0].hideHierarchy,
        },
        qualifier_conditions: programData.conditions.qualifierConditions,
        program_conditions: programData.conditions.programConditions,
        criteria_ids: programData.conditions?.criteriaIds || [],
      }
      yield put(ProgramListCreationActions.setProgramListData(program_list_data))
    }
    yield put(RPMActions.rpmGetApprovalDocuments(payload, cb))
  } catch (err) {
    console.log('getProgramListDataHandler Error >Data ', err)

    const transitionalPortal = {
      header: 'Program List Lookup Failed',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* approveProgramListHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const history = yield select(getHistory)
    const qs = history.location.search
    const cd = yield select(getApiPath, 'approve-program-list')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}${qs}`

    const { message } = yield call(fetchPost, url, payload)
    yield put(ProgramListCreationActions.getAllProgramListData())
    const transitionalPortal = {
      header: 'Program List',
      copy: `${message}. Please wait while we publish the program list.`,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    // triggering airlow dag to load the drug list and publish the program list
    const execution_time = new Date()
    execution_time.setSeconds(execution_time.getSeconds() + 60)
    const payload2 = {
      conf: { section: 'program', ...payload },
      dag_run_id: uuidv4(),
      execution_date: execution_time.toISOString(),
    }
    yield call(fetchPost, url.replace('/approve-program-list', '/build-drug-list'), payload2)
  } catch (err) {
    console.log('approveProgramListHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Approving Program List Failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}
