import React, { useState, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Modal } from 'semantic-ui-react'
import FliptButton from '../../../../../components/form/fliptButton'
import { Creators as MemberLookupActions } from '../../../../../redux/reducers/api/memberLookup';
import './styles.scss'
import useDebounce from '../../../../../utils/hooks/useDebounce';

function UpdatePasswordModal({ actions, loading, passwordUpdateStatus, showChangePassword, setShowChangePassword, state }) {
    const [newPassword, setNewPassword] = useState({ value: '', error: '' });
    const [confirmPassword, setConfirmPassword] = useState({ value: '', error: '' });
    const [visiblePassword, setVisiblePassword] = useState(false);
    const passwordErrorMessage = 'Password must be at least 11 characters long and contain at least one uppercase letter, one lowercase letter, and one special character.';
    const confirmPasswordErrorMessage = 'Passwords do not match.';

    const debouncedNewPassword = useDebounce(newPassword.value, 1000);
    const debouncedConfirmPassword = useDebounce(confirmPassword.value, 1000);

    useEffect(() => {
        const hasError = debouncedNewPassword && passwordHasError(debouncedNewPassword);
        setNewPassword((prevState) => ({ ...prevState, error: hasError ? passwordErrorMessage : '' }));
    }, [debouncedNewPassword]);

    useEffect(() => {
        const hasError = debouncedConfirmPassword && passwordHasError(debouncedConfirmPassword);
        setConfirmPassword((prevState) => ({ ...prevState, error: hasError ? passwordErrorMessage : '' }));
    }, [debouncedConfirmPassword]);


    useEffect(() => {
        if (passwordUpdateStatus) {
            this.setTimeout(() => {
                actions.setPasswordUpdateStatus(null)
                setNewPassword({ value: '', error: '' });
                setConfirmPassword({ value: '', error: '' });
            }, 2500);
        }
    }, [loading])

    const passwordHasError = (password) => {
        const minLength = 11;
        const hasLowerCase = /[a-z]/;
        const hasUpperCase = /[A-Z]/;
        const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/;

        if (password.length < minLength) {
            return true;
        }

        if (!hasLowerCase.test(password)) {
            return true;
        }

        if (!hasUpperCase.test(password)) {
            return true;
        }

        if (!hasSpecialChar.test(password)) {
            return true;
        }

        return false;
    }

    const handlePasswordInputChange = (e) => {
        e.preventDefault();
        const name = e.target.name;
        const value = (e.target.value || '').trim();
        if (name === 'new-password') {
            setNewPassword((prevState) => ({ ...prevState, value }));
        } else {
            setConfirmPassword((prevState) => ({ ...prevState, value }));
        }
    }

    const isPasswordFormInvalid = () => {
        const passwordsDoNotMatch = newPassword.value !== confirmPassword.value
        const hasInvalidPassword = passwordHasError(newPassword.value) || passwordHasError(confirmPassword.value);
        return (passwordsDoNotMatch || hasInvalidPassword || !newPassword.value || !confirmPassword.value);
    }

    const handlePasswordUpdate = (e) => {
        e.preventDefault();
        if (isPasswordFormInvalid()) {
            return;
        }

        const payload = {
            new_value: confirmPassword.value,
            user_id: state.member_details.user_id,
        }

        actions.updateMemberPassword(payload);
    }
    const cannotSubmit = isPasswordFormInvalid();
    return (
        <Modal
            closeIcon={true}
            dimmer="inverted"
            onClose={() => setShowChangePassword(false)}
            closeOnDimmerClick={true}
            open={showChangePassword}
        >
            <Modal.Content>
                <form className='modal-pw-content'>
                    <h3 className='modal-pw-header'>Change Password</h3>
                    <br />
                    {passwordUpdateStatus && <p className="modal-pw-update">Password Update: {passwordUpdateStatus}</p>}
                    <lable htmlFor="new-password">
                        New Password
                    </lable>
                    <input
                        type={visiblePassword ? "text" : "password"}
                        name="new-password"
                        className={newPassword.error ? "modal-pw-input-error" : newPassword.value ? "modal-pw-input-success" : "modal-pw-input"}
                        value={newPassword.value}
                        onChange={(e) => handlePasswordInputChange(e)}
                    />
                    {newPassword.error && newPassword.value && <p className="modal-pw-error-message">{newPassword.error}</p>}
                    <lable htmlFor="confirm-password">
                        Confirm Password
                    </lable>
                    <input
                        type={visiblePassword ? "text" : "password"}
                        name="confirm-password"
                        className={confirmPassword.error ? "modal-pw-input-error" : confirmPassword.value ? "modal-pw-input-success" : "modal-pw-input"}
                        value={confirmPassword.value}
                        onChange={(e) => handlePasswordInputChange(e)}
                    />
                    {confirmPassword.error && confirmPassword.value && <p className="modal-pw-error-message">{confirmPassword.error}</p>}
                    {newPassword.value && confirmPassword.value && newPassword.value !== confirmPassword.value && <p className="modal-pw-error-message">{confirmPasswordErrorMessage}</p>}
                </form>

            </Modal.Content>
            <Modal.Actions>
                <div className="modal-pw-actions">
                    <section className='modal-pw-actions-selector'>
                        <label>Show Password&nbsp;
                            <input
                                type="checkbox"
                                className="modal-pw-actions-selector-checkbox"
                                checked={visiblePassword}
                                label='Show Password'
                                onChange={() => setVisiblePassword(!visiblePassword)}
                            />
                        </label>
                    </section>
                    <section className="modal-pw-actions-section">
                        <FliptButton
                            onClick={() => setShowChangePassword(false)}
                            className="modal-pw-actions-section-buttons-cancel"
                            name="Cancel"
                        />
                        <FliptButton
                            name="Confirm"
                            className={cannotSubmit ? "modal-pw-actions-section-buttons-confirm-disabled" : "modal-pw-actions-section-buttons-confirm"}
                            onClick={(e) => cannotSubmit ? {} : handlePasswordUpdate(e)}
                        />
                    </section>
                </div>
            </Modal.Actions>
        </Modal >
    )
}

const mapStateToProps = (state) => ({
    state: {
        memberLookup: state.memberLookup,
        member_details: state.memberLookup?.member_details,
        user: state.user,
        app: state.app,
        loading: state.memberLookup?.loading,
        passwordUpdateStatus: state.memberLookup?.passwordUpdateStatus,
        //   hasPasswordWriteAccess: state.user.permissions.findIndex((record) => {
        //     if ('password-update' in record) {
        //       return record['password-update'].includes('w')
        //     }
        //     return false;
        //   }) !== - 1,
        // },
    }
})

const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...MemberLookupActions,
    }

    return {
        actions: bindActionCreators(allActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePasswordModal);