import moment from 'moment'
import {stringsMessage} from "../../src/config/Validation/strings"

export function validatePhoneNumber(phone) {
  return /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(phone)
}

export function validateEmailAddress(email) {
  return /^(([^<>()[\]\\.,;:\s@!$&#~"]+(\.[^<>()[\]\\.,;:\s@!$&#~"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9._-]+\.)+[a-zA-Z0-9]{2,}))$/.test(email)
}

export function validatePassword(pw, pw2) {
  const pwRegex = /^(?=.*).{6,77}$/

  return pw.length && pw && pw2 ? pw === pw2 && pwRegex.test(pw) : pwRegex.test(pw)
}

export function validateAlphaNumeric(str) {
  const strRegex = /^[a-z0-9]+$/

  return strRegex.test(str)
}

export function validateLatitude(lat) {
  return Number.isFinite(lat) && Math.abs(lat) <= 90
}

export function validateLongitude(lng) {
  return Number.isFinite(lng) && Math.abs(lng) <= 180
}

export function validateString(str, minlength = 0, maxLength) {
  let valid = str && str.length >= minlength

  if (maxLength) {
    valid = valid && str.length <= maxLength
  }

  return valid
}

export function validateDate(d) {
  return moment(d).isValid()
}

export function validateCode(code) {
  const regex = /^\d{6}$/
  return regex.test(code)
}

export function validateNumericField(code) {
  const regex = /^[0-9.\b]+$/
  return regex.test(code)
}

export function validateNumericAndEmptyField(code) {
  const regex = /^[0-9]+$|^$/
  return regex.test(code)
}

export function  validateName(name){
  const regex=  /^[0-9a-zA-Z][a-zA-Z0-9 _ .,'-]*$/
  return regex.test(name)
}

export function validateZipCode(code){
  const regex = /^\d{5}(?:-\d{4})?$/
  return regex.test(code)
}


// export const resolveMessage = (key, parameters) => {
//   const resolveJson = stringsMessage()||{};
//   const resolveMessageObject = Object.assign({},resolveJson[key]||{})
//   if (key && resolveMessageObject &&  resolveMessageObject.message) {
//     let message = resolveMessageObject.message;
//       let error = message;
//       const regex = /\$\{([\w]+)\}/g;
//       console.log(regex.test(message))
//       // if (regex.test(message) ) {
//         console.log("hi");
//         if(parameters){
//           console.log("hi");
//           error = message.replace(regex, (match, k) => {
//             console.log(k);
//             return parameters[k] || k;
//           });
  
//         // }
       
//       }
//       resolveMessageObject.message=error;
//       return resolveMessageObject;
//   } 
//   return  {message:key||"",header:"Message" }
  
// };

const REGEX_placeholders = /\$\{([\w]+)\}/g;
/**
 * Resolves the message-key and returns an object with the resolved message and title.
 * If the key is not defined then returns a default object ( with key as the message)
 * 
 * @param {*} key 
 * @param {*} parameters 
 * @returns a object with resolved message and title
 */
export const resolveMessage = (key, parameters) => {
  if (!key){
    return {message:"", header:""}
  }
  const messages = stringsMessage()||{}
  let message = messages[key]||{}

  //if not found and key is a path
  if (!message.message && key.indexOf(".") > 0){
    message = key.split(".").reduce((c,k)=>c?.[k], messages) ||{}
  }
  if ( message && message.message){
      let resolved_message = message.message;
      if ( parameters) {
        resolved_message = resolved_message.replace(REGEX_placeholders, (match, k) => {
          return parameters[k] || k;
        });
      }
      //make a copy
      message = Object.assign({},message)
      message.message = resolved_message 
      return message;
      
  } 
  return {
    message:key, header:"Message"
  }
};