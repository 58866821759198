import React, { PureComponent } from 'react'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Switch } from 'react-router-dom'

import AccountDesignSetup from './private/accountDesignSetup'
import ActivateUser from './public/activateUser'
import AdapSpapDashboard from './private/adapSpapDashboard'
import AddGroup from './private/groupManagement/AddGroup'
import AddNewAccount from './private/accountDesignSetup/addNewAccount'
import ApprovalQueue from './private/approvals/queue'
import ApprovalRulesCreationManagement from './private/approvals/rule'
import AssignPharmacies from './private/pharmacyNetwork/assignPharmacies'
import AuditManagement from './private/systemSetupControl/auditManagement'
import BatchDetails from './private/integrationManagement/batchDetails'
import BrandTier from './private/drugManagement/weeklyDrugManagement/brandTier'
import BulkReprocessingRestacking from './private/bulkReprocessingRestacking'
import ChangePassword from './public/changePassword'
import ClaimAdjustment from './private/claimLookupManagement/claimAdjustment'
import ClaimDetails from './private/claimLookupManagement/claimDetails'
import ClaimErrorManagement from './private/claimErrorManagement'
import FormularyExportLookUp from './private/formularyExportLookup'
import FormularyExportConfiguration from './private/formularyExportConfiguration'
import FormularyExportConfigurationBuilder from './private/formularyExportConfiguration/builder'
import ClaimHistory from './private/claimHistory/'
import ClaimLookupManagement from './private/claimLookupManagement'
import ClaimOverride from './private/claimLookupManagement/claimOverride'
import ClaimProcessingReprocessingDashboard from './private/claimProcessingReprocessing'
import ClaimRequestResponse from './private/claimLookupManagement/claimTransaction/requestResponse'
import ClaimSummary from './private/integrationManagement/claimSummary'
import ClaimTraceability from './private/claimLookupManagement/claimTraceability/index.js'
import ClaimTransactionMedicareDPartDetails from './private/claimLookupManagement/claimTransaction/medicarePartDDetails'
import ClaimTransactionMedicareInformationReportingDetails from './private/claimLookupManagement/claimTransaction/medicarePartDInformationReporting'
import ClaimTransactionOverview from './private/claimLookupManagement/claimTransaction/overview'
import ClaimTransactionPricing from './private/claimLookupManagement/claimTransaction/pricing'
import ClaimTransactionProviders from './private/claimLookupManagement/claimTransaction/providers'
import ClaimTransactionRejection from './private/claimLookupManagement/claimTransaction/rejection'
import ClaimsProcessingAndReprocessing from './private/claimsProcessingAndReprocessing'
import ClientConfiguration from './private/clientConfiguration'
import ClientPortalReporting from './private/clientPortalReporting'
import ClientSetupManagement from './private/clientSetupManagement'
import ClinicalOperations from './private/clinicalOperations'
import CommunicationHistory from './private/memberLookup/component/communicationHistory'
import CompanyManagement from './private/systemSetupControl/companyManagement'
import CompoundIngredients from './private/claimLookupManagement/claimTransaction/ingredients'
import Contact from './private/contact'
import ContractManagement from './private/contractManagement/create'
import ContractManagementSummary from './private/contractManagement'
import ContractPriceLookup from './private/pharmacyLookup/contractPricelookup'
import CreateApprovalRules from './private/approvals/rule/createRules'
import CreateCompany from './private/systemSetupControl/companyManagement/createCompany'
import CreateDurProgram from './private/drugUtilizationReview/createDurProgram'
import CreateFormulary from './private/formularyCreationManagement/formulary/createFormulary'
import CreateFormularyTier from './private/formularyCreationManagement/formularyTiers/createTier'
import CreateLicsSetup from './private/medicareSetup/lics/createLicsSetup'
import CreateMedicareDPlan from './private/medicareSetup/dPlan/createPlan'
import CreateNetwork from './private/pharmacyNetwork/pharmacyNetwork/createNetwork'
import CreateNetworkEdits from './private/pharmacyNetwork/networkComponents/edits'
//import CreateNetworkEdits from './private/pharmacyNetwork/networkEdits/createEdits'
import CreateNetworkTier from './private/pharmacyNetwork/networkComponents/tier'
//import CreateNetworkTier from './private/pharmacyNetwork/networkTier/createTier'
import CreatePACase from './private/intakeQueue/createPACase'
import CreatePlan from './private/planDesignManagement/createPlan'
import CreatePriorAuthorization from './private/clinical/priorAuthorization/createPriorAuthorization'
import CreateProgramList from './private/programList/createProgramList'
import CreateQuantityLimit from './private/clinical/quantityLimit/createQuantityLimit'
import CreateQuestionnaire from './private/questionnaireBuilder/createQuestionnaire'
import CreateRole from './private/systemSetupControl/roleManagement/createRole'
import CreateStepTherapy from './private/clinical/stepTherapy/createStepTherapy'
import CreateTeam from './private/systemSetupControl/teamManagement/createTeam'
import CustomerServicePortal from './private/customerServicePortal'
// import CreateUser from './private/systemSetupControl/userManagement/user/createUser'
import DeductibleDetails from './private/memberLookup/deductibleDetails'
import DependentEligibility from './private/manualEligibilityEntry/components/dependent'
import DrugInformation from './private/drugManagement/weeklyDrugManagement/drugInformation'
import DrugListView from './private/rebateManagement/DrugListView/index.js'
import DrugMappingLookup from './private/drugMappingLookup'
import DrugPriceLookup from './private/drugPriceLookup'
import DrugUtilizationReview from './private/drugUtilizationReview/create'
import DrugUtilizationReviewSummary from './private/drugUtilizationReview'
// import EditUser from './private/systemSetupControl/userManagement/user/editUser'
import EligibilityLogs from './private/memberLookup/eligibilityLogs/index.js'
import EligibilitySearchAndUpdate from './private/eligibilitySearchAndUpdate'
import EmergencyOverrides from './private/emergencyOverrides'
import FRFSearch from './private/formularyCreationManagement/formularyTiers/createTier/frfSearch'
import FileConfiguration from './private/integrationManagement/FileConfiguration'
import FileConfigurationDashboard from './private/integrationManagement/FileConfigurationDashboard'
import FinancialInformationReporting from './private/financialInformationReporting'
import FinancialInformationReportingDetails from './private/financialInformationReporting/txnDetail'
import FinancialManagement from './private/financialManagement'
import ForgotPassword from './public/forgotPassword'
import FormularyBuilder from './private/medDFormulary/formularyBuilder'
import FormularyCreationManagement from './private/formularyCreationManagement/formulary'
import FormularyFileInput from './private/formularyCreationManagement/formularyFileInput'
import FormularyLookup from './private/formularyLookup/v1'
import FormularyLookupV2 from './private/formularyLookup/v2'
import FormularyTierCreationManagement from './private/formularyCreationManagement/formularyTiers'
import GetEligibility from './private/getEligibility'
import GlobalEditDashboard from './private/globalEditDashboard'
import GlobalObjectConfiguration from './private/globalObjectConfiguration'
import GlobalSettings from './private/globalSettings'
import GroupMaintenance from './private/groupManagement/groupMaintenance'
import GroupManagement from './private/groupManagement'
import HierarchyManagement from './private/systemSetupControl/hierarchyManagement'
import Home from './private/home'
import IntakeQueue from './private/intakeQueue'
import IntegrationManagement from './private/integrationManagement'
import IntegrationManagementDashboard from './private/integrationManagement/Dashboard'
import Login from './public/login'
import MFALogin from './public/login/mfaLogin'
import ManualClaimAdjustment from './private/manualClaimAdjustment'
import ManualClaimEntry from './private/manualClaimEntry'
import ManualEligibilityEntry from './private/manualEligibilityEntry/index'
import MedDFormulary from './private/medDFormulary'
import MedicareDPlanSetup from './private/medicareSetup/dPlan'
import MedicarePlanDetails from './private/medicareSetup/lics/medicarePlanDetails'
import MedicareSetup from './private/medicareSetup/lics'
import MedispanLookup from './private/medispanLookup'
import MemberLookup from './private/memberLookup'
import NewRestrictions from './private/memberLookup/providerRestrictions/restrictions'
import NxTransaction from './private/NxTransaction'
import NxTransactions from './private/claimLookupManagement/claimTransaction/nxTransactions'
import OperationsReporting from './private/operationsReporting'
import OpioidManagementSummary from './private/opioidManagement'
import PACaseDashboard from './private/intakeQueue/PACaseDashboard'
import PADashboard from './private/paDashboard'
import PADocumentList from './private/paTemplateDocumentUpload'
import PADocumentTemplateUpload from './private/paTemplateDocumentUpload/uploadTemplate'
import POSRebate from './private/posRebateManagement/posRebate'
import POSRebateManagement from './private/posRebateManagement'
import Paoverridereportlookup from './private/paoverride'
import PharmacyLookup from './private/pharmacyLookup'
import PharmacyNetwork from './private/pharmacyNetwork/pharmacyNetwork'
import PharmacyNetworkLookup from './private/pharmacyNetwork/pharmacyNetwork/networkLookup'
import PharmacyNetworkTierLookup from './private/pharmacyNetwork/pharmacyNetwork/networkTierLookup'
import PlanDesignLookup from './private/planDesignLookup'
import PlanDesignManagement from './private/planDesignManagement'
import PlanDetails from './private/memberLookup/planDetails'
import PrescriberLookup from './private/prescriberLookup'
import PriceCheck from './private/getPricing'
import PriorAuthorization from './private/clinical/priorAuthorization'
import PriorAuthorizationAdministration from './private/priorAuthorizationRequest'
import PriorAuthorizationDetails from './private/memberLookup/priorAuthorizationDetails'
import PriorAuthorizationOperations from './private/priorAuthorizationOperations'
import PrivateRoute from './private'
import ProactiveOverrideDetails from './private/memberLookup/proactiveOverrideDetails'
import ProcessorMgmt from './private/contractManagement/processorMgmt'
import ProgramList from './private/programList'
import PublicRoute from './public'
import QuantityLimit from './private/clinical/quantityLimit'
import QuestionnaireBuilder from './private/questionnaireBuilder'
import QuestionnaireImport from './private/questionnaireBuilder/importQuestionnaire'
import QueueConfiguration from './private/queueConfiguration'
import RealTimeClaimErrorManagement from './private/realtimeClaimErrorManagement'
import RealTimeClaimManagement from './private/realTimeClaimManagement'
import RebateContract from './private/rebateContract'
import RebateInvoicing from './private/rebateInvoicing'
import RebateManagement from './private/rebateManagement/create'
import RebateManagementSummary from './private/rebateManagement'
import RebateRemittance from './private/rebateRemittance'
import Rebates from './private/rebates'
import RoleManagement from './private/systemSetupControl/roleManagement'
import Rxreportlookup from './private/rxreportlookup'
import StepTherapyCreationManagement from './private/clinical/stepTherapy'
import SureScriptFormularyConfig from './private/sureScriptFormularyConfig'
import SystemSetupControl from './private/systemSetupControl'
import TeamManagement from './private/systemSetupControl/teamManagement'
import TestBatchDetails from './private/claimProcessingReprocessing/testBatchDetails/index.js'
import TestClaims from './private/testClaims'
import TimerConfiguration from './private/timerConfiguration'
import TimerSummary from './private/timerConfiguration/timerSummary/index.js'
import UMDrugLookupTabs from './private/umDrugLookupTabs'
import USPSearch from './private/formularyCreationManagement/formularyTiers/createTier/uspSearch'
import AssignmentQueue from './private/assignmentQueue'
import UserManagement from './private/systemSetupControl/userManagement'
import UserManagementCreate from './private/systemSetupControl/userManagement/user/createUser'
import ViewChangesByDrug from './private/drugManagement/weeklyDrugManagement/viewChangesByDrug'
import ViewChangesByObject from './private/drugManagement/weeklyDrugManagement/viewChangesByObject'
import ViewChangesByType from './private/drugManagement/weeklyDrugManagement/viewChangesByType'
import ViewDrugTerms from './private/drugManagement/weeklyDrugManagement/viewDrugTerms'
import ViewFormularyDrugs from './private/formularyCreationManagement/shared/viewFormularyDrugs'
import ViewNewDrugs from './private/drugManagement/weeklyDrugManagement/viewNewDrugs'
import ViewQuestionnaire from './private/questionnaireBuilder/viewQuestionnaire'
import WeeklyDrugManagement from './private/drugManagement/weeklyDrugManagement'
import changeTypeSummary from './private/drugManagement/weeklyDrugManagement/changeTypeSummary'
import createOpioid from './private/opioidManagement/createOpioid'
import { NetworkTierCreationManagement, NetworkEditsCreationManagement } from './private/pharmacyNetwork/networkComponents'
import NewImplmentation from './private/clientImplementation/createImplementation'
import ClientImplementations from './private/clientImplementation'
import ClientImplementationTaskInfo from './private/clientImplementation/taskInfo'


class RootNavigator extends PureComponent {
  render() {
    return (
      <Switch>
        <PrivateRoute componentProps={this.props} component={AccountDesignSetup} path="/account-design-setup" />
        <PrivateRoute componentProps={this.props} component={AddNewAccount} path="/create-account" />
        <PrivateRoute componentProps={this.props} component={GlobalEditDashboard} path="/global-edit-dashboard" />
        <PrivateRoute componentProps={this.props} component={ApprovalQueue} path="/approvals-queue" />
        <PrivateRoute componentProps={this.props} component={ApprovalRulesCreationManagement} path="/approvals-rule" />
        <PrivateRoute componentProps={this.props} component={AssignPharmacies} path="/assign-pharmacies" />
        <PrivateRoute componentProps={this.props} component={OpioidManagementSummary} path="/opioid-management" />
        <PrivateRoute componentProps={this.props} component={createOpioid} path="/create-opioid-management" />
        <PrivateRoute componentProps={this.props} component={DrugUtilizationReview} path="/add-opioid-dur" />
        <PrivateRoute componentProps={this.props} component={CreateDurProgram} path="/add-global-dur" />
        <PrivateRoute componentProps={this.props} component={AdapSpapDashboard} path="/adap-spap-information" />
        <PrivateRoute componentProps={this.props} component={ClientConfiguration} path="/hierarchy-configuration" />
        <PrivateRoute componentProps={this.props} component={ClaimAdjustment} path="/claim-adjustment" />
        <PrivateRoute componentProps={this.props} component={ClaimDetails} path="/claim-detail" />
        <PrivateRoute componentProps={this.props} component={ClaimHistory} path="/claim-history" />
        <PrivateRoute componentProps={this.props} component={ClaimHistory} path="/member-lookup-claim-history" />
        <PrivateRoute componentProps={this.props} component={DrugUtilizationReviewSummary} path="/drug-utilization-review-summary" />
        <PrivateRoute componentProps={this.props} component={DrugUtilizationReview} path="/drug-utilization-review" />
        <PrivateRoute componentProps={this.props} component={BulkReprocessingRestacking} path="/bulk-reprocessing-restacking" />
        <PrivateRoute componentProps={this.props} component={ContractManagementSummary} path="/contract-management-summary" />
        <PrivateRoute componentProps={this.props} component={ContractManagement} path="/contract-management" />
        <PrivateRoute componentProps={this.props} component={ProcessorMgmt} path="/create-processor" />
        <PrivateRoute componentProps={this.props} component={ClaimLookupManagement} path="/claim-lookup-management" />
        <PrivateRoute componentProps={this.props} component={ClaimErrorManagement} path="/claim-error-management" />
        <PrivateRoute componentProps={this.props} component={FormularyExportLookUp} path="/formulary-export-lookup" />
        <PrivateRoute componentProps={this.props} component={TimerConfiguration} path="/timer-configuration" />
        <PrivateRoute componentProps={this.props} component={QueueConfiguration} path="/pa-queue-configuration" />
        <PrivateRoute componentProps={this.props} component={ClaimOverride} path="/claim-override" />
        <PrivateRoute componentProps={this.props} component={ClaimTransactionOverview} path="/claim-transaction-overview" />
        <PrivateRoute componentProps={this.props} component={ClaimTransactionOverview} path="/claim-txn-overview" />
        <PrivateRoute componentProps={this.props} component={ClaimTransactionPricing} path="/claim-transaction-pricing" />
        <PrivateRoute componentProps={this.props} component={ClaimTransactionProviders} path="/claim-transaction-providers" />
        <PrivateRoute componentProps={this.props} component={ClaimTransactionRejection} path="/claim-transaction-rejection" />
        <PrivateRoute componentProps={this.props} component={ClaimRequestResponse} path="/claim-request-response" />
        <PrivateRoute componentProps={this.props} component={CompoundIngredients} path="/claim-transaction-compund-ingredients" />
        <PrivateRoute componentProps={this.props} component={ClaimTransactionMedicareDPartDetails} path="/claim-transaction-medicarePartDDetails" />
        <PrivateRoute componentProps={this.props} component={ClaimTransactionMedicareInformationReportingDetails} path="/claim-transaction-medicareInformationReporting" />
        <PrivateRoute componentProps={this.props} component={ClientPortalReporting} path="/client-portal-reporting" />
        <PrivateRoute componentProps={this.props} component={CreatePACase} path='/create-pa-case' />
        <PrivateRoute componentProps={this.props} component={ClientSetupManagement} path="/client-setup-management" />
        <PrivateRoute componentProps={this.props} component={ClinicalOperations} path="/clinical-operations" />
        <PrivateRoute componentProps={this.props} component={CompanyManagement} path="/company-management" />
        <PrivateRoute componentProps={this.props} component={Contact} path="/contact-help" />
        <PrivateRoute componentProps={this.props} component={CreateApprovalRules} path="/approvals-rule-create" />
        <PrivateRoute componentProps={this.props} component={CreateCompany} path="/company-management-create" />
        <PrivateRoute componentProps={this.props} component={CreateCompany} path="/company-management-edit" />
        <PrivateRoute componentProps={this.props} component={CreateFormulary} path="/formulary" />
        <PrivateRoute componentProps={this.props} component={CreateFormularyTier} path="/formulary-tier" />
        <PrivateRoute componentProps={this.props} component={CreateMedicareDPlan} path="/create-medicare-plan" />
        <PrivateRoute componentProps={this.props} component={DrugPriceLookup} path="/drug-price-lookup" />
        <PrivateRoute componentProps={this.props} component={MedDFormulary} path="/get-formulary-export-build" />
        <PrivateRoute componentProps={this.props} component={FormularyExportConfiguration} path="/formulary-export-configuration" />
        <PrivateRoute componentProps={this.props} component={FormularyExportConfigurationBuilder} path="/formulary-export-configuration-builder" />
        <PrivateRoute componentProps={this.props} component={EmergencyOverrides} path="/emergency-override-configuration-dashboard" />
        <PrivateRoute componentProps={this.props} component={FormularyBuilder} path="/create-formulary-export-builder" />
        <PrivateRoute componentProps={this.props} component={WeeklyDrugManagement} path="/weekly-drug-management" />
        <PrivateRoute componentProps={this.props} component={ViewNewDrugs} path="/view-new-drugs" />
        <PrivateRoute componentProps={this.props} component={ViewDrugTerms} path="/view-drug-term" />
        <PrivateRoute componentProps={this.props} component={ViewChangesByDrug} path="/view-changes-by-drug" />
        <PrivateRoute componentProps={this.props} component={ViewChangesByType} path="/view-changes-by-type" />
        <PrivateRoute componentProps={this.props} component={ViewChangesByObject} path="/view-changes-by-object" />
        <PrivateRoute componentProps={this.props} component={changeTypeSummary} path="/change-type-details" />
        <PrivateRoute componentProps={this.props} component={DrugInformation} path="/drug-information" />
        <PrivateRoute componentProps={this.props} component={BrandTier} path="/drug-brand" />
        <PrivateRoute componentProps={this.props} component={CreateNetwork} path="/generate-network" />
        <PrivateRoute componentProps={this.props} component={CreateNetworkEdits} path="/generate-network-edit" />
        <PrivateRoute componentProps={this.props} component={CreateNetworkTier} path="/generate-network-tier" />
        <PrivateRoute componentProps={this.props} component={FRFSearch} path="/frf-search" />
        <PrivateRoute componentProps={this.props} component={USPSearch} path="/usp-search" />
        <PrivateRoute componentProps={this.props} component={GroupManagement} path="/group-management" />
        <PrivateRoute componentProps={this.props} component={GroupMaintenance} path="/group-maintenance" />
        <PrivateRoute componentProps={this.props} component={AddGroup} path="/create-group" />
        <PrivateRoute componentProps={this.props} component={CreateLicsSetup} path="/create-lics-setup" />
        <PrivateRoute componentProps={this.props} component={CreatePlan} path="/create-benefit-plan" />
        <PrivateRoute componentProps={this.props} component={EligibilityLogs} path="/eligibility-logs" />
        <PrivateRoute componentProps={this.props} component={CreatePriorAuthorization} path="/prior-authorization" />
        <PrivateRoute componentProps={this.props} component={CreateProgramList} path="/program-list" />
        <PrivateRoute componentProps={this.props} component={CreateQuantityLimit} path="/quantity-limit" />
        <PrivateRoute componentProps={this.props} component={CreateRole} path="/role-management-create" />
        <PrivateRoute componentProps={this.props} component={CreateRole} path="/role-management-edit" />
        <PrivateRoute componentProps={this.props} component={CreateStepTherapy} path="/step-therapy" />
        <PrivateRoute componentProps={this.props} component={CreateTeam} path="/team-management-create" />
        <PrivateRoute componentProps={this.props} component={CreateTeam} path="/team-management-edit" />
        <PrivateRoute componentProps={this.props} component={NewImplmentation} path="/create-implementation" />
        <PrivateRoute componentProps={this.props} component={ClientImplementations} path="/client-implementation" />
        <PrivateRoute componentProps={this.props} component={ClientImplementationTaskInfo} path="/tasks-info" />
        {/* <PrivateRoute componentProps={this.props} component={CreateUser} path="/create-user" /> */}
        <PrivateRoute componentProps={this.props} component={CustomerServicePortal} path="/customer-service-portal" />
        <PrivateRoute componentProps={this.props} component={DeductibleDetails} path="/accumulation-details" />
        <PrivateRoute componentProps={this.props} component={NewRestrictions} path="/new-restrictions" />
        <PrivateRoute componentProps={this.props} component={UMDrugLookupTabs} path="/drug-lookup" />
        <PrivateRoute componentProps={this.props} component={DrugMappingLookup} path="/drug-map-lookup" />
        {/* <PrivateRoute componentProps={this.props} component={EditUser} path="/edit-user" /> */}
        <PrivateRoute componentProps={this.props} component={EligibilitySearchAndUpdate} path="/eligibility-search-and-update" />
        <PrivateRoute componentProps={this.props} component={FinancialManagement} path="/financial-management" />
        <PrivateRoute componentProps={this.props} component={FormularyCreationManagement} path="/formulary-creation-management" />
        <PrivateRoute componentProps={this.props} component={FormularyFileInput} path="/formulary-file-input" />
        <PrivateRoute componentProps={this.props} component={FormularyLookup} path="/formulary-lookup" />
        <PrivateRoute componentProps={this.props} component={FormularyLookupV2} path="/formulary-lookup-v2" />
        <PrivateRoute componentProps={this.props} component={FormularyTierCreationManagement} path="/formulary-tier-creation-management" />
        <PrivateRoute componentProps={this.props} component={GlobalSettings} path="/global-settings" />
        <PrivateRoute componentProps={this.props} component={HierarchyManagement} path="/company-management-hierarchy" />
        <PrivateRoute componentProps={this.props} component={Home} path="/home" />
        <PrivateRoute componentProps={this.props} component={ClaimProcessingReprocessingDashboard} path="/claim-processing-reprocessing-dashboard" />
        <PrivateRoute componentProps={this.props} component={ClaimProcessingReprocessingDashboard} path="/claim-processing-reprocessing" />
        <PrivateRoute componentProps={this.props} component={ManualClaimAdjustment} path="/manual-claim-adjustment" />
        <PrivateRoute componentProps={this.props} component={ManualClaimEntry} path="/manual-claim-entry" />
        <PrivateRoute componentProps={this.props} component={MedicareSetup} path="/medicare-lics-setup" />
        <PrivateRoute componentProps={this.props} component={MedicareDPlanSetup} path="/medicare-plan" />
        <PrivateRoute componentProps={this.props} component={MedicareDPlanSetup} path="/medicare-plan-standard" />
        <PrivateRoute componentProps={this.props} component={MedicareDPlanSetup} path="/medicare-plan-custom" />
        <PrivateRoute componentProps={this.props} component={MedicarePlanDetails} path="/medicare-lics-setup-details" />
        <PrivateRoute componentProps={this.props} component={MemberLookup} path="/member-lookup" />
        {/*<PrivateRoute componentProps={this.props} component={MemberLookup} path="/member-support" />*/}
        <PrivateRoute componentProps={this.props} component={POSRebate} path="/pos-rebate" />
        <PrivateRoute componentProps={this.props} component={POSRebateManagement} path="/pos-rebate-creation-management" />
        <PrivateRoute componentProps={this.props} component={CommunicationHistory} path="/communication-history" />
        <PrivateRoute componentProps={this.props} component={TestClaims} path="/test-claim-generation" />
        <PrivateRoute componentProps={this.props} component={NetworkEditsCreationManagement} path="/network-edit" />
        <PrivateRoute componentProps={this.props} component={NetworkTierCreationManagement} path="/network-tier" />
        <PrivateRoute componentProps={this.props} component={UserManagement} path="/user-management" />
        <PrivateRoute componentProps={this.props} component={UserManagementCreate} path="/user-management-create" />
        <PrivateRoute componentProps={this.props} component={UserManagementCreate} path="/user-management-edit" />
        <PrivateRoute componentProps={this.props} component={UserManagementCreate} path="/user-management-view" />
        <PrivateRoute componentProps={this.props} component={OperationsReporting} path="/operations-reporting" />
        <PrivateRoute componentProps={this.props} component={PharmacyLookup} path="/pharmacy-lookup" />
        <PrivateRoute componentProps={this.props} component={CreateQuestionnaire} path="/create-questionnaire" />
        <PrivateRoute componentProps={this.props} component={MedispanLookup} path="/medispan-lookup" />
        <PrivateRoute componentProps={this.props} component={PharmacyNetwork} path="/pharmacy-network" />
        <PrivateRoute componentProps={this.props} component={PharmacyNetworkLookup} path="/pharmacy-network-lookup" />
        <PrivateRoute componentProps={this.props} component={PharmacyNetworkTierLookup} path="/pharmacy-network-tier-lookup" />
        <PrivateRoute componentProps={this.props} component={PlanDesignLookup} path="/plan-design-lookup" />
        <PrivateRoute componentProps={this.props} component={PlanDesignManagement} path="/plan-design-management" />
        <PrivateRoute componentProps={this.props} component={PADocumentTemplateUpload} path="/padocument-template-upload" />
        <PrivateRoute componentProps={this.props} component={PADocumentList} path="/view-templates" />
        <PrivateRoute componentProps={this.props} component={IntakeQueue} path="/intake-queue" />
        <PrivateRoute componentProps={this.props} component={PriorAuthorizationAdministration} path="/prior-auth-administration" />
        <PrivateRoute componentProps={this.props} component={QuestionnaireBuilder} path="/questionnaire-builder" />
        <PrivateRoute componentProps={this.props} component={ViewQuestionnaire} path="/view-questionnaire" />
        <PrivateRoute componentProps={this.props} component={PriorAuthorizationOperations} path="/operations-prior-auth" />
        <PrivateRoute componentProps={this.props} component={IntegrationManagement} path="/integration-management" />
        <PrivateRoute componentProps={this.props} component={PlanDetails} path="/plan-details" />
        <PrivateRoute componentProps={this.props} component={PrescriberLookup} path="/prescriber-lookup" />
        <PrivateRoute componentProps={this.props} component={PriceCheck} path="/price-check-management" />
        <PrivateRoute componentProps={this.props} component={GetEligibility} path="/eligibility" />
        <PrivateRoute componentProps={this.props} component={PriorAuthorization} path="/prior-authorization-creation-management" />
        <PrivateRoute componentProps={this.props} component={DependentEligibility} path="/create-dependent-eligibility" />
        <PrivateRoute componentProps={this.props} component={PriorAuthorizationDetails} path="/pa-details" />
        <PrivateRoute componentProps={this.props} component={ProgramList} path="/program-list-creation-management" />
        <PrivateRoute componentProps={this.props} component={QuantityLimit} path="/quantity-limit-creation-management" />
        <PrivateRoute componentProps={this.props} component={RealTimeClaimManagement} path="/real-time-claim-management" />
        <PrivateRoute componentProps={this.props} component={RealTimeClaimErrorManagement} path="/real-time-claim-error-management" />
        <PrivateRoute componentProps={this.props} component={FinancialInformationReporting} path="/financial-information-reporting" />
        <PrivateRoute componentProps={this.props} component={FinancialInformationReportingDetails} path="/fir-transaction-details" />
        <PrivateRoute componentProps={this.props} component={NxTransaction} path="/nx-transaction" />
        <PrivateRoute componentProps={this.props} component={RebateContract} path="/rebate-contract-management" />
        <PrivateRoute componentProps={this.props} component={RebateInvoicing} path="/rebate-invoicing-billing" />
        <PrivateRoute componentProps={this.props} component={RebateRemittance} path="/rebate-remittance" />
        <PrivateRoute componentProps={this.props} component={Rebates} path="/rebates" />
        <PrivateRoute componentProps={this.props} component={RoleManagement} path="/role-management" />
        <PrivateRoute componentProps={this.props} component={Rxreportlookup} path="/rxreport-lookup" />
        <PrivateRoute componentProps={this.props} component={Paoverridereportlookup} path="/paoverride-lookup" />
        <PrivateRoute componentProps={this.props} component={StepTherapyCreationManagement} path="/step-therapy-creation-management" />
        <PrivateRoute componentProps={this.props} component={SystemSetupControl} path="/system-setup-control" />
        <PrivateRoute componentProps={this.props} component={SureScriptFormularyConfig} path="/ss-formulary-config" />
        <PrivateRoute componentProps={this.props} component={TeamManagement} path="/team-management" />
        <PrivateRoute componentProps={this.props} component={TimerSummary} path="/timer-summary" />
        <PrivateRoute componentProps={this.props} component={AssignmentQueue} path="/assignment-queue" />
        <PrivateRoute componentProps={this.props} component={PACaseDashboard} path="/pa-case-dashboard" />
        <PrivateRoute componentProps={this.props} component={PADashboard} path="/pa-dashboard" />
        <PrivateRoute componentProps={this.props} component={ManualEligibilityEntry} path="/manual-eligibility-entry" />
        <PrivateRoute componentProps={this.props} component={QuestionnaireImport} path="/upload-questionnaire" />
        <PrivateRoute componentProps={this.props} component={IntegrationManagementDashboard} path="/integration-management-dashboard" />
        <PrivateRoute componentProps={this.props} component={ClaimsProcessingAndReprocessing} path="/claims-processing-and-reprocessing" />
        <PrivateRoute componentProps={this.props} component={FileConfiguration} path="/file-configuration" />
        <PrivateRoute componentProps={this.props} component={AuditManagement} path="/audit-management" />
        <PrivateRoute componentProps={this.props} component={ContractPriceLookup} path="/contract-discount-lookup" />
        <PrivateRoute componentProps={this.props} component={TestBatchDetails} path="/get-test-batch" />
        <PrivateRoute componentProps={this.props} component={ClaimTraceability} path="/claim-traceability" />
        <PrivateRoute componentProps={this.props} component={RebateManagementSummary} path="/rebate-management-summary" />
        <PrivateRoute componentProps={this.props} component={RebateManagement} path="/rebate-management" />
        <PrivateRoute componentProps={this.props} component={DrugListView} path="/view-druglist" />



        {/* <PrivateRoute componentProps={this.props} component={FileConfigurationDashboard} path="/file-configuration-dashboard" /> */}

        <PrivateRoute componentProps={this.props} component={BatchDetails} path="/batch-details" />
        <PrivateRoute componentProps={this.props} component={ClaimSummary} path="/claim-summary" />
        <PrivateRoute componentProps={this.props} component={NxTransactions} path="/nx-claim-transaction-details" />

        {/* <PrivateRoute componentProps={this.props} component={UserManagement} path="/user-management" /> */}
        <PrivateRoute componentProps={this.props} component={ViewFormularyDrugs} path="/view-drugs" />
        <PrivateRoute componentProps={this.props} component={ProactiveOverrideDetails} path="/po-details" />
        <PrivateRoute componentProps={this.props} component={GlobalObjectConfiguration} path="/global-object-configuration" />
        <PublicRoute componentProps={this.props} component={ActivateUser} path="/activate" />
        <PublicRoute componentProps={this.props} component={ChangePassword} path="/change-password" />
        <PublicRoute componentProps={this.props} component={ForgotPassword} path="/forgot-password" />
        <PublicRoute componentProps={this.props} component={MFALogin} path="/mfa-login" />
        <PublicRoute componentProps={this.props} component={Login} path="/" />
        <PublicRoute componentProps={this.props} component={Login} path="/login" />
      </Switch>
    )
  }
}

const mapStateToProps = (state) => ({
  state: {
    app: state.app,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    // ...appActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RootNavigator)
