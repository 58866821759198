import React, { useCallback } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import FliptInput from '../../../../components/form/fliptInput'
import FliptDropdown from '../../../../components/form/fliptDropdown'
import FliptGrid from '../../../../components/fliptGrid'
import { DropdownEditor, InputTextEditor } from '../../../../components/fliptGrid/cellEditors'
import { ApplicationOnly as rowCellInfo, Banner } from '../data/rowCellInfo'
import { DeleteRowRenderer } from '../../../../components/fliptGrid/cellRenderers'
import AddRow from '../addRow'
import FliptPharmacySearch from '../../../../components/fliptPharmacySearch'
import { Creators as AppActions } from '../../../../redux/reducers/app'
import _, { isEmpty } from 'lodash'
import './styles.scss'
const applicationOnly = (props) => {
  const { form, updateFields, context: { cagValues }, updateGridValues } = props
  const { account } = cagValues || {}
  const { app_pharmacy_popup_message = [] } = form
  const appPharmacyPopup = ['ALL', 'NJ00']
  const crossAccumulationOptions = [{ text: 'Y', value: 'Y' }, { text: 'N', value: 'N' }]
  const data = app_pharmacy_popup_message || []
  const isNovartis = (account || '').toString().toLowerCase().startsWith('novartis') || false
  const defaultMarketingData = [{
    message_on_off: '',
    image_location: '',
    link: ''
  }]
  const marketingData = isEmpty(form?.flipt_marketing_configuration) ? defaultMarketingData : form?.flipt_marketing_configuration
  const debouncedUpdate = useCallback(
    _.debounce((e, param) => updateFields(e, param), 1000),
    [], // will be created only once initially
  )
  const updateNestedFields = (e, dropdown, rowIndex) => {
    const { name, value } = dropdown || e.currentTarget
    const copy = { ...data[rowIndex] }

    const param = {
      name: 'app_pharmacy_popup_message', value: Object.values({
        ...data, [rowIndex]: {
          ...copy,
          [name]: value,
        }
      })
    }
    debouncedUpdate(e, param)

  }

  const updateGridFields = (e, dropdown, rowIndex) => {
    const { name, value } = dropdown || e.currentTarget

    updateGridValues(name, value, rowIndex)

  }


  const headers = [
    'app_pharmacy_popup_message_type',
    'message',
    'no_access_message',
  ]

  const bannerHeaders = [
    'message_on_off',
    'image_location',
    'link',
  ]
  const mailOrderHeaders = [
    'action',
    'pharmacy_name',
    'npi',
  ]
  const cellRendererParams = {
    app_pharmacy_popup_message_type: {
      width: '300px',
      overrideHeader: 'App Pharmacy Popup Message Type',
    },
    message: {
      width: '450px',
      overrideHeader: 'App Pharmacy Popup Message',
    },
    no_access_message: {
      width: '450px',
      overrideHeader: 'App Pharmacy Popup No Access Message',
    }
  }

  const delRow = (rowIndex) => {
    const mail_order_credit_card_flow = form?.mail_order_credit_card_flow || []
    const mailOrderCopy = [...mail_order_credit_card_flow]
    mailOrderCopy.splice(rowIndex, 1)
    const param = {
      name: 'mail_order_credit_card_flow', value: [...mailOrderCopy]
    }
    updateFields({}, param)
  }

  const bannerCellRendererParams = {
    message_on_off: {
      width: '300px',
    },
    image_location: {
      width: '350px',
    },
    link: {
      width: '350px',
    }
  }

  const mailOrderCellRendererParams = {
    action: {
      cellRenderer: DeleteRowRenderer,
      state: {
        onClick: delRow,
      },
      width: 300,
    },
    pharmacy_name: {
      width: '350px',
    },
    npi: {
      width: '350px',
    }
  }


  const bannerCellEditorParams = {
    message_on_off: {
      cellEditor: DropdownEditor,
      onChange: updateGridFields,
    },
    image_location: {
      cellEditor: InputTextEditor,
      onChange: updateGridFields,
    },
    link: {
      cellEditor: InputTextEditor,
      onChange: updateGridFields,
    },
  }
  const cellEditorParams = {
    message: {
      cellEditor: InputTextEditor,
      onChange: updateNestedFields,
    },
    no_access_message: {
      cellEditor: InputTextEditor,
      onChange: updateNestedFields,
    },
  }

  if (_.isEmpty(data) && isNovartis) {
    appPharmacyPopup.forEach(ele => {
      data.push({
        app_pharmacy_popup_message_type: ele,
        message: '',
        no_access_message: ''
      })
    })
  }

  const updatePharmacyData = (data) => {
    const mail_order_credit_card_flow = form?.mail_order_credit_card_flow || []
    const param = {
      name: 'mail_order_credit_card_flow', value: [...mail_order_credit_card_flow, ...data.map(row => ({ pharmacy_name: row.pharmacyname, npi: row.pharmacynpi }))]
    }
    updateFields({}, param)
  }

  const _findPharmacy = () => {
    const { actions } = props
    actions.setModalComponent({
      modalProperties: { size: 'large' },
      contents: <FliptPharmacySearch
        submitPharmacyData={(data) => updatePharmacyData(data)}
        searchForm={{}}
        editMode
      />,
    })
    actions.toggleModal()
  }

  return <div id="applicationOnly">
    <div className='applicationContainer'>
      <FliptInput
        datatype="str"
        description="..."
        disabled={false}
        key='app_max_incentive_credit'
        label='App Max Incentive Credit'
        name='app_max_incentive_credit'
        required={false}
        onChange={updateFields}
        value={form?.app_max_incentive_credit || ''}
        stylized="true"
      />
      <FliptInput
        datatype="str"
        description="..."
        disabled={false}
        key='max_incentive_credit_per_intent'
        label='Max Incentive Credit Per Intent'
        name='max_incentive_credit_per_intent'
        required={false}
        onChange={updateFields}
        value={form?.max_incentive_credit_per_intent || ''}
        stylized="true"
      />
      <FliptDropdown
        clearable={false}
        description="..."
        disabled={false}
        key='send_cross_accumulation'
        label='Send Cross Accumulation'
        multiple={false}
        name='send_cross_accumulation'
        onChange={updateFields}
        options={crossAccumulationOptions}
        option_save_keys='send_cross_accumulation'
        value={form?.send_cross_accumulation || ''}
        scrolling
        search
        selection
        selectOnBlur={false}
        stylized='true'
      />
      <FliptInput
        datatype="str"
        description="..."
        disabled={false}
        key='spd'
        label='SPD'
        name='spd'
        required={false}
        onChange={updateFields}
        value={form?.spd || ''}
        stylized="true"
      />
    </div>
    {isNovartis && (<div className='applicationContainer'>
      <div className="app-grid-container">
        <FliptGrid
          data={data}
          headers={headers}
          cellRendererParams={cellRendererParams}
          cellEditorParams={cellEditorParams}
          rowCellInfo={rowCellInfo}
        />
      </div>
    </div>)}
    <div className="app-grid-container">
      <FliptGrid
        data={marketingData}
        headers={bannerHeaders}
        cellEditorParams={bannerCellEditorParams}
        rowCellInfo={Banner}
        cellRendererParams={bannerCellRendererParams}
      />
    </div>
    <div className='mailOrderContainer'>
      <div className='headingContainer'>
        <h3>Mail Order Credit Card Pharmacies</h3>
      </div>
      <div className="app-grid-container">
        <FliptGrid
          data={form?.mail_order_credit_card_flow || []}
          headers={mailOrderHeaders}
          cellRendererParams={mailOrderCellRendererParams}
        />
      </div>
      <div className="addRowButtonContainer">
        <AddRow name="Add Pharmacy" addRow={_findPharmacy} />
      </div>
    </div>
  </div>
}
const mapStateToProps = (state) => ({
  state: {},
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions
  }
  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(applicationOnly)
