import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { cloneDeep } from "lodash";

import "./styles.scss";
import BreadCrumb from "../../../../components/breadCrumb";
import ApprovalOptions from "../../../../components/approvalOptions";
import Config from "../../../../config";
import RebateConditions from "./posRebateConditions";
import SaveTierModal from "./saveTierModal";
import _ from "lodash";
import drugDemographicData from "../../../../config/constants/clinical/drugDemographic";
import drugMappingData from "../../../../config/constants/clinical/drugMapping";
import { Creators as AppActions } from "../../../../redux/reducers/app";
import { Creators as AutoSuggestActions } from "../../../../redux/reducers/api/autoSuggest";
import { Creators as RebateActions } from "../../../../redux/reducers/api/posRebate";
import { Creators as QualifierActions } from "../../../../redux/reducers/api/qualifiers";
import { Creators as RPMActions } from "../../../../redux/reducers/rpm";
import { drugGroupValues } from "../../../../config/constants/clinical/drugGroups";
import {
  convertDateToStartTimeObj,
  parseQueryString,
  convertDateToEndTimeObj,
} from "../../../../utils/utilities";

class POSRebate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        doc_name: "",
        effective_start_date: "",
        questionnaire_name: "",
        questionnaire_link: "",
        pos_rebate_reason: "",
        doc_id: props.state?.rebateData?.doc_id || "",
        module: "POS_REBATE",
        version: "",
        status: "",
        version_effective_date: "",
        hierarchy: [],
        is_hierarchy_global: false,
        hideHierarchy: false,
      },
      activeRebateConditionLevel: 0,
      rebateLevels: [
        [
          {
            gpi: "",
            medicare_type: "",
            drug_group: "",
            drug_class: "",
            drug_subclass: "",
            manufacturer: "",
            drug_name: "",
            multi_source: "",
            ddid: "",
            brand_generic: "",
            otc_indicator: "",
            action: "",
            desi_code: "",
            route_of_administration: "",
            effective_start_date: "",
            effective_end_date: "",
          },
        ],
      ],
      showCriteria: [false],
      dropdownValues: [
        [
          {
            drug_group: drugGroupValues,
            drug_class: [],
            drug_subclass: [],
          },
        ],
      ],
      autoSuggestionValues: [
        [
          {
            manufacturer: { values: [], loading: false },
            drug_name: { values: [], loading: false },
          },
        ],
      ],
      rowUpdated: null,
      columnUpdated: null,
      levelUpdated: null,
      autoSuggestionMinChars: 3,
      cellRenderers: {
        gpi: {
          overrideHeader: "GPI",
          hide: false,
        },
        desi_code: {
          hide: true,
        },
        medicare_type: {
          hide: true,
          overrideHeader: "Medicare Type Code",
        },
        manufacturer: {
          hide: true,
        },
        multi_source: {
          overrideHeader: "Multi-Source Code",
          hide: false,
        },
        ddid: {
          hide: false,
          overrideHeader: "DDID",
        },
        brand_generic: {
          hide: true,
          overrideHeader: "Brand/Generic",
        },
        otc_indicator: {
          hide: true,
          overrideHeader: "OTC Indicator",
        },
        route_of_administration: {
          hide: true,
        },
        ndc: {
          hide: true,
        },
        maintenance_drug_code: {
          hide: true,
          overrideHeader: "Maintenance Drug Indicator",
        },
        application_type_flag: {
          hide: true,
          overrideHeader: 'App Type',
        },
        mfg_labeler_id: {
          hide: true,
          overrideHeader: 'Labler Code',
        },
        repackaged_code: {
          hide: true,
          overrideHeader: 'Repackaging Indicator',
        },
        third_party_restriction_code: {
          hide: true,
          overrideHeader: 'TPR Code',
        },
        dosage_form_cd: {
          hide: true,
          overrideHeader: 'Dosage Form Code',
        },
        member_notes: {
          hide: true,
        },
        agent_notes: {
          hide: true,
        },
        internal_notes: {
          hide: true,
        },
        claim_message_code: {
          hide: true,
        },
        claim_message_type: {
          hide: true,
        },
      },
    };
  }

  clearFormData() {
    this.setState({
      form: {
        doc_name: "",
        effective_start_date: "",
        questionnaire_name: "",
        version_effective_date: "",
        questionnaire_link: "",
        pos_rebate_reason: "",
        doc_id: "",
        module: "POS_REBATE",
        version: "",
        status: "DRAFT",
      },
    });
  }

  componentDidMount() {
    const { actions, history } = this.props
    actions.clearRebate()
    actions.clearQualifiers()
    if (!history.location.search) {
      this.clearFormData()
      actions.rpmGetMf2ValData()
      return;
    }
    const payload = parseQueryString(history.location.search);
    setTimeout(
      () => {
        actions.getPOSRebateData(payload)
        actions.rpmGetMf2ValData()
      },
      Config.COMPONENT_DATA_LOAD_TIME
    );
  }

  componentDidUpdate(prevProps) {
    const { props } = this;
    const prevState = prevProps.state;
    const currState = props.state;
    if (prevState === currState) return;
    const hasConditionsChanged =
      prevState.rebateCondData?.length !== currState.rebateCondData?.length &&
      !!currState.rebateCondData?.length;
    const hasRebateDataChanged = prevState.rebateData !== currState.rebateData;
    if (hasConditionsChanged) {
      this._updateState();
    } else if (hasRebateDataChanged) {
      const { rebateData } = currState;
      // following same practice as _updateState function (not spreading the rebateData)
      const form = {
        ...this.state.form,
        doc_id: rebateData.doc_id,
        doc_name: rebateData.doc_name,
        effective_start_date: rebateData.effective_start_date,
        version_effective_date: rebateData.version_effective_date,
        hierarchy: rebateData.hierarchy,
        is_hierarchy_global: rebateData.is_hierarchy_global,
        hideHierarchy: rebateData.hideHierarchy,
        max_no_of_fills: rebateData.max_no_of_fills,
        version: rebateData.version,
        status: rebateData.status,
      };
      this.setState({
        ...this.state,
        form,
      });
    }
    if (!_.isEqual(prevState.autoSuggestData, currState.autoSuggestData))
      this._updateAutoSuggestions();
  }

  // eslint-disable-next-line react/destructuring-assignment
  _constructDropdownOptions = () =>
    this.state.dropdownValues.map((dropdownValueLevel) =>
      dropdownValueLevel.map((row) => this._mapValueToOption(row))
    );

  _updateFields = (el, dateData) => {
    const { name, value } = dateData || el.currentTarget;
    // Check if name is 'effective_start_date'
    if (name === "effective_start_date") {
      // Update effective_start_date in rebateLevels
      this.setState((prevState) => {
        let updatedRebateLevels = prevState.rebateLevels.map((level) => {
          return level.map((condition) => {
            return {
              ...condition,
              effective_start_date: value,
            }
          })
        })
        // in current behavior, FliptDatePicker/SemanticDatepicker fires onChange event on first render
        // this trigger this function (_updateFields) and causes effective_start_date for the whole grid to
        // change without any use action. below is the fix for that - we only change grid date if effective_start_date
        // is a date object. please consider this behavior before making any changes.
        if (name === 'effective_start_date' && typeof value !== typeof prevState.form.effective_start_date) {
          updatedRebateLevels = prevState.rebateLevels
        }
        return {
          ...prevState,
          form: {
            ...prevState.form,
            [name]: value,
          },
          rebateLevels: updatedRebateLevels,
        }
      })
    } else {
      this.setState((prevState) => ({
        ...prevState,
        form: {
          ...prevState.form,
          [name]: value,
        },
      }));
    }
  };

  setForm = (newForm) => {
    this.setState((prevState) => ({
      ...prevState,
      form: newForm,
    }));
  };

  _handleChange = (
    el,
    dropdown,
    rowIndex,
    gridApi,
    rebateConditionLevel,
    setAutoSuggestionValues
  ) => {
    const { props, state } = this;
    const { actions } = props;
    const rebateLevels = [...state.rebateLevels];
    const dropdownValues = [...state.dropdownValues];
    const autoSuggestionValues = [...state.autoSuggestionValues];
    const rowToUpdate = rebateLevels[rebateConditionLevel][rowIndex];
    const dropDownRowToUpdate = dropdownValues[rebateConditionLevel][rowIndex];
    const { name, value } = dropdown || el.currentTarget;
    rowToUpdate[name] = value;

    if (
      state.rowUpdated != rowIndex ||
      state.levelUpdated != rebateConditionLevel
    )
      actions.clearAutoSuggestData();

    switch (name) {
      case "gpi": {
        this._onGpiChange(value, rowToUpdate, dropDownRowToUpdate);
        break;
      }
      case "drug_group":
        this._onDrugGroupChange(rowToUpdate, dropDownRowToUpdate);
        break;
      case "drug_class":
        this._onDrugClassChange(rowToUpdate, dropDownRowToUpdate);
        break;
      case "drug_subclass":
        break;
      case "manufacturer":
        if (value.length >= state.autoSuggestionMinChars) {
          autoSuggestionValues[rebateConditionLevel][
            rowIndex
          ].manufacturer.loading = true;
          actions.getAutoSuggestData({
            search_string: value,
            search_in: "fts_ndc_drugs",
            search_for: "mfg",
          });
          this.setAutoSuggestionValues = setAutoSuggestionValues;
        }
        break;
      case "drug_name":
        if (value.length >= state.autoSuggestionMinChars) {
          autoSuggestionValues[rebateConditionLevel][
            rowIndex
          ].drug_name.loading = true;
          actions.getAutoSuggestData({
            search_string: value,
            search_in: "fts_ndc_drugs",
            search_for: "drug_name",
          });
          this.setAutoSuggestionValues = setAutoSuggestionValues;
        }
        break;
      default:
        break;
    }
    rebateLevels[rebateConditionLevel][rowIndex] = rowToUpdate;
    dropdownValues[rebateConditionLevel][rowIndex] = dropDownRowToUpdate;

    const activeColumns = gridApi.columnModel?.columnApi
      .getAllGridColumns()
      .filter((col) => {
        if (col.visible) return col.colId;
      });
    let cellRenderers = { ...state.cellRenderers };
    const getColumn = Object.keys(cellRenderers);

    activeColumns?.forEach((col) => {
      if (getColumn.includes(col.colId) && col.colId != "action") {
        cellRenderers[col.colId]["hide"] = false;
      }
    });

    this.setState({
      rebateLevels,
      dropdownValues,
      rowUpdated: rowIndex,
      columnUpdated: name,
      levelUpdated: rebateConditionLevel,
      cellRenderers: cellRenderers,
    });
    gridApi.refreshCells();
  };

  _refreshDrugDemographicDropdowns = (rowToUpdate, dropDownRowToUpdate) => {
    rowToUpdate.drug_group = "";
    rowToUpdate.drug_class = "";
    rowToUpdate.drug_subclass = "";
    dropDownRowToUpdate.drug_group = drugGroupValues;
    dropDownRowToUpdate.drug_class = [];
    dropDownRowToUpdate.drug_subclass = [];
  };

  _onGpiChange = (value, rowToUpdate, dropDownRowToUpdate) => {
    const gpiCode = value;
    const gpiLen = gpiCode.length;
    if (gpiLen === 0) {
      this._refreshDrugDemographicDropdowns(rowToUpdate, dropDownRowToUpdate);
      return;
    }
    const drugGroupCode = gpiCode.slice(0, 2);
    const drugClassCode = gpiCode.slice(2, 4);
    const drugSubClassCode = gpiCode.slice(4, 6);
    this._selectDrugGroup(drugGroupCode, rowToUpdate, dropDownRowToUpdate);
    this._selectDrugClass(
      drugGroupCode,
      drugClassCode,
      rowToUpdate,
      dropDownRowToUpdate
    );
    this._selectDrugSubclass(
      drugGroupCode,
      drugClassCode,
      drugSubClassCode,
      rowToUpdate
    );
  };

  _selectDrugGroup = (gpiCode, rowToUpdate, dropDownRowToUpdate) => {
    if (gpiCode in drugMappingData) {
      rowToUpdate.drug_group = drugMappingData[gpiCode].drug_group;
      this._onDrugGroupChange(rowToUpdate, dropDownRowToUpdate);
    }
  };

  _selectDrugClass = (
    drugGroupCode,
    drugClassCode,
    rowToUpdate,
    dropDownRowToUpdate
  ) => {
    const validDrugClassCode =
      drugGroupCode in drugMappingData &&
      drugClassCode in drugMappingData[drugGroupCode].drug_classes;
    if (validDrugClassCode) {
      rowToUpdate.drug_class =
        drugMappingData[drugGroupCode].drug_classes[drugClassCode].drug_class;
      this._onDrugClassChange(rowToUpdate, dropDownRowToUpdate);
    }
  };

  _selectDrugSubclass = (
    drugGroupCode,
    drugClassCode,
    drugSubClassCode,
    rowToUpdate
  ) => {
    const validDrugClassCode =
      drugGroupCode in drugMappingData &&
      drugClassCode in drugMappingData[drugGroupCode].drug_classes;
    const validDrugSubClassCode =
      validDrugClassCode &&
      drugSubClassCode in
      drugMappingData[drugGroupCode].drug_classes[drugClassCode]
        .drug_subclasses;
    if (validDrugSubClassCode) {
      rowToUpdate.drug_subclass =
        drugMappingData[drugGroupCode].drug_classes[
          drugClassCode
        ].drug_subclasses[drugSubClassCode].drug_subclass;
    }
  };

  _onDrugGroupChange = (rowToUpdate, dropDownRowToUpdate) => {
    rowToUpdate.drug_class = "";
    rowToUpdate.drug_subclass = "";
    dropDownRowToUpdate.drug_class = this._extractDrugDemographics(rowToUpdate);
    dropDownRowToUpdate.drug_subclass = [];
  };

  _onDrugClassChange = (rowToUpdate, dropDownRowToUpdate) => {
    rowToUpdate.drug_subclass = "";
    dropDownRowToUpdate.drug_subclass =
      this._extractDrugDemographics(rowToUpdate);
  };

  _autoSuggestionResultSelect = (data, rowIndex) => {
    const { state } = this;
    const rebateLevels = [...state.rebateLevels];
    const rowToUpdate =
      rebateLevels[state.activeRebateConditionLevel][rowIndex];
    const { name, result } = data;
    const value = result.title;
    rowToUpdate[name] = value;
    rebateLevels[state.activeRebateConditionLevel][rowIndex] = rowToUpdate;
    this.setState({
      rebateLevels,
    });
  };

  _delRebateCondition = (rowIndex, rebateConditionLevel) => {
    const { rebateLevels, dropdownValues, autoSuggestionValues } = this.state;
    const poseRebateConditionLevelToUpdate = [
      ...rebateLevels[rebateConditionLevel],
    ];
    const dropdownToUpdate = [...dropdownValues[rebateConditionLevel]];
    const autoSuggestionToUpdate = [
      ...autoSuggestionValues[rebateConditionLevel],
    ];

    if (poseRebateConditionLevelToUpdate.length <= 1) return;

    const newRebateConditionLevel = poseRebateConditionLevelToUpdate.filter(
      (cond) => poseRebateConditionLevelToUpdate.indexOf(cond) !== rowIndex
    );
    rebateLevels[rebateConditionLevel] = newRebateConditionLevel;

    const newdropdown = dropdownToUpdate.filter(
      (cond) => dropdownToUpdate.indexOf(cond) !== rowIndex
    );
    dropdownValues[rebateConditionLevel] = newdropdown;

    const newAutoSuggestion = autoSuggestionToUpdate.filter(
      (cond) => autoSuggestionToUpdate.indexOf(cond) !== rowIndex
    );
    autoSuggestionValues[rebateConditionLevel] = newAutoSuggestion;

    this.setState({
      rebateLevels,
      dropdownValues,
      autoSuggestionValues,
    });
  };

  _addRebateCondition = (level) => {
    const { rebateLevels, dropdownValues, autoSuggestionValues, form } =
      this.state;
    const updatedRebateConditionLevels = rebateLevels[level]?.concat(
      Object.keys(rebateLevels[0][0]).reduce((acc, header) => {
        acc[header] = "";
        acc["effective_start_date"] = form.effective_start_date || "";
        return acc;
      }, {})
    );
    const updatedDropdownValues = dropdownValues[level]?.concat(
      Object.keys(dropdownValues[0][0]).reduce((acc, header) => {
        if (header === "drug_group") {
          acc[header] = drugGroupValues;
        } else {
          acc[header] = [];
        }
        return acc;
      }, {})
    );
    const updatedAutoSuggestionValues = autoSuggestionValues[level].concat(
      Object.keys(autoSuggestionValues[0][0]).reduce((acc, header) => {
        acc[header] = { values: [], loading: false };
        return acc;
      }, {})
    );
    rebateLevels[level] = updatedRebateConditionLevels;
    dropdownValues[level] = updatedDropdownValues;
    autoSuggestionValues[level] = updatedAutoSuggestionValues;

    this.setState({
      rebateLevels,
      dropdownValues,
      autoSuggestionValues,
    });
  };

  _deleteRebateConditionLevel = (e, rebateConditionLevel) => {
    const { state } = this;
    const rebateLevels = [...state.rebateLevels];
    const dropdownValues = [...state.dropdownValues];
    const autoSuggestionValues = [...state.autoSuggestionValues];
    const showCriteria = [...state.showCriteria];

    if (rebateLevels.length <= 1) return;
    showCriteria.splice(rebateConditionLevel, 1);
    this.setState({
      activeRebateConditionLevel: 0,
      rebateLevels: rebateLevels.filter(
        (cond) => rebateLevels.indexOf(cond) !== rebateConditionLevel
      ),
      dropdownValues: dropdownValues.filter(
        (cond) => dropdownValues.indexOf(cond) !== rebateConditionLevel
      ),
      autoSuggestionValues: autoSuggestionValues.filter(
        (cond) => autoSuggestionValues.indexOf(cond) !== rebateConditionLevel
      ),
      showCriteria,
    });
  };

  checkDateValidation = (tierConditions) => {
    const { actions } = this.props;
    let invalidDate = false;
    let formattedRebateConditions = tierConditions.map((tierData) => {
      if (!tierData.effective_start_date) {
        invalidDate = true;
      }
      return {
        ...tierData,
        effective_start_date: convertDateToStartTimeObj(
          tierData.effective_start_date
        ),
        effective_end_date: convertDateToEndTimeObj(
          tierData.effective_end_date
        ),
      };
    });
    if (invalidDate) {
      const transitionalPortal = {
        header: "Formulary Tier Save Failed",
        copy: `Please Add effective Start Date before saving`,
      };
      actions.displayTransitionalPortal(transitionalPortal);
    }
    return { formattedRebateConditions, invalidDate };
  };

  checkDateValidationForRebateDrugGeneration = (tierConditions) => {
    const { actions } = this.props;
    let invalidDate = false;
    const excludeKeys = { pos_rebate_factor: true, pos_rebate_type: true }
    let formattedRebateConditions = tierConditions.map((tierData) => {
      if (!tierData.effective_start_date) {
        invalidDate = true;
      }
      const condition = Object.keys(tierData).reduce((acc, key) => {
        if (key in excludeKeys) return acc;
        acc[key] = tierData[key];
        return acc;
      }, {});
      condition.effective_start_date = convertDateToStartTimeObj(condition.effective_start_date)
      condition.effective_end_date = convertDateToEndTimeObj(condition.effective_end_date)
      return condition;
    });
    if (invalidDate) {
      const transitionalPortal = {
        header: "Formulary Tier Save Failed",
        copy: `Please Add effective Start Date before saving`,
      };
      actions.displayTransitionalPortal(transitionalPortal);
    }
    return { formattedRebateConditions, invalidDate };
  }

  _addNewRebateConditionLevel = () => {
    const { state } = this;
    const rebateLevels = [...state.rebateLevels];
    const dropdownValues = [...state.dropdownValues];
    const autoSuggestionValues = [...state.autoSuggestionValues];
    const showCriteria = [...state.showCriteria];

    rebateLevels.push([
      {
        gpi: "",
        drug_group: "",
        drug_class: "",
        drug_subclass: "",
        manufacturer: "",
        drug_name: "",
        multi_source: "",
        brand_generic: "",
        otc_indicator: "",
        action: "",
        effective_start_date: state.form.effective_start_date || "",
        effective_end_date: "",
      },
    ]);
    dropdownValues.push([
      { drug_group: drugGroupValues, drug_class: [], drug_subclass: [] },
    ]);
    autoSuggestionValues.push([
      {
        manufacturer: { values: [], loading: false },
        drug_name: { values: [], loading: false },
      },
    ]);
    showCriteria.push(false);
    this.setState({
      activeRebateConditionLevel: rebateLevels.length - 1,
      rebateLevels,
      dropdownValues,
      autoSuggestionValues,
      showCriteria,
    });
  };

  _handleRebateConditionLevelClick = (e, titleProps) => {
    const { state } = this;
    const { activeRebateConditionLevel } = state;
    const { index } = titleProps;
    const newIndex = activeRebateConditionLevel === index ? -1 : index;
    this.setState({ activeRebateConditionLevel: newIndex });
  };

  _generateRebate = (el, rebateConditionLevel) => {
    const {
      props: { actions },
      state,
    } = this;
    const { rebateLevels } = state;
    const rebateConditions = rebateLevels[rebateConditionLevel];
    const showCriteria = [...state.showCriteria];
    showCriteria[rebateConditionLevel] = true;
    this.setState({
      showCriteria,
    });
    const { formattedRebateConditions, invalidDate } =
      this.checkDateValidationForRebateDrugGeneration(rebateConditions);
    if (invalidDate) return;
    actions.clearRebateDrugData();
    // TODO: Not sure if this is needed(settimeout)
    setTimeout(
      () =>
        actions.generateRebate({
          drugConditions: formattedRebateConditions,
          rebateConditionLevel,
        }),
      250
    );
  };

  _sendForReview = () => {
    const {
      props: { actions },
      state,
    } = this;
    const {
      form,
      form: { doc_id },
    } = state;

    if (!doc_id) {
      const transitionalPortal = {
        header: "POS Rebate For Approval",
        copy: "Please save POS Rebate Data first",
      };
      actions.displayTransitionalPortal(transitionalPortal);
    } else {
      const message = "Send POS Rebate For Review?";

      actions.setModalComponent({
        modalProperties: {
          size: "tiny",
        },
        contents: SaveTierModal({
          form,
          message,
          onSaveClick: actions.rpmSendForReview,
          toggleModal: actions.toggleModal,
        }),
      });
      actions.toggleModal();
    }
  };

  _saveRebate = () => {
    const {
      props: {
        actions,
        state: { criteriaIds },
      },
      state,
    } = this;
    const { form, rebateLevels } = state;
    const hierarchyOrganizationLevel = form?.hierarchy?.[0]?.children || [];
    if (
      !form.doc_name ||
      !form.effective_start_date ||
      hierarchyOrganizationLevel?.length == 0
    ) {
      let message = "";
      if (!form.doc_name) message = "enter a POS Rebate Name";
      else if (!form.effective_start_date) message = "select a Start Date";
      else if (hierarchyOrganizationLevel?.length == 0)
        message = "assign a Hierarchy";
      const transitionalPortal = {
        header: "POS Rebate Save Failed",
        copy: `Please ${message} before saving`,
      };
      actions.displayTransitionalPortal(transitionalPortal);
      return;
    }

    const formattedRebateConditionLevels = [];
    for (const i in rebateLevels) {
      const { formattedRebateConditions, invalidDate } =
        this.checkDateValidation(rebateLevels[i]);
      if (invalidDate) return;
      else formattedRebateConditionLevels.push(formattedRebateConditions);
    }

    const message =
      "You are about to save the POS Rebate. Do you wish to save?";
    actions.setModalComponent({
      modalProperties: {
        size: "tiny",
      },
      contents: SaveTierModal({
        form,
        criteria_ids: criteriaIds,
        conditions: formattedRebateConditionLevels,
        onSaveClick: actions.savePOSRebateData,
        toggleModal: actions.toggleModal,
        message,
      }),
    });
    actions.toggleModal();
  };

  _mapValueToOption = (row) => {
    const data = {
      ...row,
    };
    Object.keys(data).forEach((col) => {
      if (Array.isArray(data[col])) {
        data[col] = data[col].map((group, index) => ({
          key: index,
          text: group,
          value: group,
        }));
      }
    });
    return data;
  };

  _extractDrugDemographics = (selectedValues) => {
    const validKeys = ["drug_group", "drug_class"];
    const extractedKeys = validKeys.filter((key) => !!selectedValues[key]);
    const extractedData = extractedKeys.reduce(
      (obj, key) => ({
        ...obj[selectedValues[key]],
      }),
      drugDemographicData
    );
    return Object.keys(extractedData);
  };

  _updateDropdownFromCond(initDropdownValues) {
    const { state } = this.props;
    const { rebateCondData } = state;
    const dropdownValues = [];
    rebateCondData.forEach((cond) => {
      const dropdownRowValues = [];
      cond?.forEach(() => {
        dropdownRowValues.push({ ...initDropdownValues[0][0] });
      });
      dropdownValues.push(dropdownRowValues);
    });

    rebateCondData.forEach((rebateCondLevelData, level) => {
      rebateCondLevelData?.forEach((cond, index) => {
        const values = {};
        if (cond.drug_group) {
          values.drug_group = cond.drug_group;
          dropdownValues[level][index].drug_class =
            this._extractDrugDemographics(values);
        }
        if (cond.drug_class) {
          values.drug_class = cond.drug_class;
          dropdownValues[level][index].drug_subclass =
            this._extractDrugDemographics(values);
        }
        if (cond.drug_subclass) {
          values.drug_subclass = cond.drug_subclass;
        }
      });
    });
    return dropdownValues;
  }

  _updateAutoSuggestionFromCond(initAutoSuggestionValues) {
    const { state } = this.props;
    const { rebateCondData } = state;
    const autoSuggestionValues = [];
    rebateCondData.forEach((cond) => {
      const autoSuggestionRowValues = [];
      cond?.forEach(() => {
        autoSuggestionRowValues.push({ ...initAutoSuggestionValues[0][0] });
      });
      autoSuggestionValues.push(autoSuggestionRowValues);
    });
    return autoSuggestionValues;
  }

  _updateState() {
    const { props, state } = this;
    const { dropdownValues, autoSuggestionValues } = state;
    const { rebateData, rebateCondData } = props.state;
    const updatedDropdownValues = this._updateDropdownFromCond(dropdownValues);
    const updatedAutoSuggestionValues =
      this._updateAutoSuggestionFromCond(autoSuggestionValues);
    const updatedShowCriteria = Array(rebateCondData.length).fill(true);

    const form = {
      ...state.form,
      doc_id: rebateData.doc_id,
      doc_name: rebateData.doc_name,
      effective_start_date: rebateData.effective_start_date,
      hierarchy: rebateData.hierarchy,
      is_hierarchy_global: rebateData.is_hierarchy_global,
      hideHierarchy: rebateData.hideHierarchy,
      max_no_of_fills: rebateData.max_no_of_fills,
      version: rebateData.version,
      status: rebateData.status,
      version_effective_date: rebateData.version_effective_date,
    };
    if (rebateCondData?.length) {
      rebateCondData.map((outerData) => {
        if (outerData?.length) {
          outerData.map((innerData) => {
            if (
              !innerData.effective_start_date &&
              rebateData.effective_start_date
            )
              innerData.effective_start_date = convertDateToStartTimeObj(
                rebateData.effective_start_date
              );
          });
        }
      });
    }
    this.setState({
      form,
      rebateLevels: rebateCondData,
      dropdownValues: updatedDropdownValues,
      autoSuggestionValues: updatedAutoSuggestionValues,
      showCriteria: updatedShowCriteria,
    });
  }

  _updateAutoSuggestions() {
    const { props, state } = this;
    const { autoSuggestData } = props.state;
    const { activeRebateConditionLevel, rowUpdated, columnUpdated } = state;
    const autoSuggestionValues = [...state.autoSuggestionValues];
    if (columnUpdated === "manufacturer") {
      autoSuggestionValues[activeRebateConditionLevel][
        rowUpdated
      ].manufacturer.values = autoSuggestData["mfg"].map((val) => ({
        title: val,
      }));
      autoSuggestionValues[activeRebateConditionLevel][
        rowUpdated
      ].manufacturer.loading = false;
      if (this.setAutoSuggestionValues)
        this.setAutoSuggestionValues(autoSuggestionValues);
    } else if (columnUpdated === "drug_name") {
      autoSuggestionValues[activeRebateConditionLevel][
        rowUpdated
      ].drug_name.values = autoSuggestData["drug_name"].map((val) => ({
        title: val,
      }));
      autoSuggestionValues[activeRebateConditionLevel][
        rowUpdated
      ].drug_name.loading = false;
      if (this.setAutoSuggestionValues)
        this.setAutoSuggestionValues(autoSuggestionValues);
    }
    this.setState(autoSuggestionValues);
  }

  approvalOptionsCallback = () => {
    const { state, props } = this;
    props.actions.getPOSRebateData({ doc_id: state.form.doc_id });
  };

  render() {
    const { props, state } = this;
    const {
      form,
      form: { hideHierarchy },
      activeRebateConditionLevel,
      rebateLevels,
      autoSuggestionValues,
      autoSuggestionMinChars,
      showCriteria,
      cellRenderers,
    } = state;
    let editMode = true;

    if ("state" in props.history.location) {
      editMode =
        props.history.location.state &&
          "editMode" in props.history.location.state
          ? props.history.location.state.editMode
          : true;

      if (parseQueryString(props.history.location.search).editMode === "false")
        editMode = false;
    }
    const dropdownOptions = this._constructDropdownOptions();

    if (hideHierarchy) editMode = false;
    let headerText = editMode ? "Create POS Rebate" : "View POS Rebate";
    if (form.doc_id && editMode) headerText = "Edit POS Rebate";

    return (
      <div id="createPOSRebate">
        <div className="breadCrumbContainer">
          <BreadCrumb {...this.props} />{" "}
        </div>
        <div className="header">{headerText}</div>
        {
          <ApprovalOptions
            docData={this.state.form}
            actionCallback={this.approvalOptionsCallback}
          />
        }
        <div className="content">
          <RebateConditions
            activeRebateConditionLevel={activeRebateConditionLevel}
            addNewRebateConditionLevel={this._addNewRebateConditionLevel}
            addRebateCondition={this._addRebateCondition}
            autoSuggestionMinChars={autoSuggestionMinChars}
            autoSuggestionResultSelect={this._autoSuggestionResultSelect}
            autoSuggestionValues={autoSuggestionValues}
            allCriteriaIDs={this.props.state.criteriaIds}
            delRebateCondition={this._delRebateCondition}
            deleteRebateConditionLevel={this._deleteRebateConditionLevel}
            doc_id={form.doc_id}
            dropdownOptions={dropdownOptions}
            editMode={editMode}
            form={form}
            setForm={this.setForm}
            generateRebate={this._generateRebate}
            handleChange={this._handleChange}
            handleCriteriaChange={this._handleCriteriaChange}
            handleRebateConditionLevelClick={
              this._handleRebateConditionLevelClick
            }
            rebateLevels={rebateLevels}
            saveRebate={this._saveRebate}
            sendForReview={this._sendForReview}
            showCriteria={showCriteria}
            updateFields={this._updateFields}
            cellRenderers={cellRenderers}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  state: {
    autoSuggestData: state.autoSuggest.autoSuggestData,
    autoSuggestSearchTerm: state.autoSuggest.autoSuggestSearchTerm,
    rebateData: state.rebateCreation?.rebateData,
    rebateCondData: state.rebateCreation?.rebateCondData,
    criteriaIds: state.rebateCreation?.rebateCriteriaIdData || [],
  },
});

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
    ...AutoSuggestActions,
    ...RebateActions,
    ...RPMActions,
    ...QualifierActions,
  };

  return {
    actions: bindActionCreators(allActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(POSRebate);
