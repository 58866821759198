import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import FliptButton from "../../../../components/form/fliptButton"
import { Creators as AppActions } from '../../../../redux/reducers/app'
import { Creators as UserManagementActions } from '../../../../redux/reducers/userManagement'
import Config from '../../../../config'
import './styles.scss'
import { Creators as NavigationActions } from '../../../../redux/reducers/navigation'
import { convertDateTimeToDate, filterRowData } from '../../../../utils/utilities'

class UserModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      disableButton: false,
      paOverride:false,
      file:""
    }

    this.statusTypes = {
      APPROVED: 'Approve',
      REJECTED: 'Reject',
      PUBLISH_READY: 'Publish Ready',
    }
    
  }

  componentDidMount() {
    const { actions } = this.props

    setTimeout(() => actions.getUserManagementUsers(), Config.COMPONENT_DATA_LOAD_TIME)
  }



  _submitRequest = (value) => {
    const {
      submitType, toggleModal, clickHandler, data,
    } = this.props
    let user={
      "user_name":value.full_name,
      "user_id":value.uuid
    }
    this.props.action({"user":user})


    setTimeout(()=>{
      clickHandler({
        ...data,
        ...value,
        status: submitType,
      })
  
      toggleModal()
    },1000)
  }





  _dataCleanup() {
    const { state } = this.props
    return state.data.map((user) => (
      {
        full_name: `${user.first_name} ${user.last_name}`,
        email_address: user.email_address,
        status: user.is_active ? 'Active' : 'Inactive',
        uuid: user.uuid,
        phone_number: user.phone_number,
        effective_start_date: user?.effective_start_date === '' ? '---' : convertDateTimeToDate(user.effective_start_date),
        effective_end_date: user?.effective_end_date === '' ? '---' : convertDateTimeToDate(user.effective_end_date),
        company: user.company,
        roles: user.roles.filter((d) => d.enable).map((d) => d.name),
        enable_mfa: user.enable_mfa,
      }
    ))
  }


  render() {
    let gridData = this._dataCleanup()
    
    const { data, submitType, toggleModal } = this.props
   
    const text = this.statusTypes[submitType] || ''
    const header = `Click whom you want to ${data.module_name}?`
    return (
      <div id="approvalModal">
        <div className="content">
          <h1>{header}</h1>
        
        
      <div className='bodyContain'>
        <div>
          <ul>
           {
             gridData.map(arr=>{
               return <li className='userName' onClick={()=>{this._submitRequest(arr)}}>{arr['full_name']}</li>
             })
           }
          </ul>
        </div>
        </div> 
          <div>
          

            <FliptButton
              name="Cancel"
              onClick={toggleModal}
            />
          </div>
        </div>
      </div>
    )
  }
}


const mapStateToProps = (state) => {
  return{ state: {
     app: state.app,
     data: state.userManagement.data,
   },}
 }

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
    ...UserManagementActions,
    ...NavigationActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(UserModal)
