/* eslint-disable */
export default {
    day_supply: {
      type: 'dropdown',
      disabled: false,
      options: [],
    },
    network_tier: {
      type: 'dropdown',
      disabled: false,
      options: [],
    },
    copay_type: {
      type: 'dropdown',
      disabled: false,
      options: [],
    },
    member_pay: {
      type: 'input',
      disabled: false,
    },
    min_copay: {
      type: 'input',
      disabled: false,
    },
    max_copay: {
      type: 'input',
      disabled: false,
    },
    effective_start_date: {
      type: 'input',
      disabled: false,
    },
    effective_end_date: {
      type: 'input',
      disabled: false,
    },
    action: {
      type: 'input',
      disabled: false,
    },
  }
  
  export const ApplicationOnly = {
    app_pharmacy_popup_message_type: {
      type: 'input',
      disabled: false,
    },
    message: {
      type: 'input',
      disabled: false,
    },
    no_access_message: {
      type: 'input',
      disabled: false,
    }
  }
  
  export const Accumulators = {
    accumulator_type: {
      type: 'dropdown',
      disabled: false,
      options: [],
    },
    accumulator_begin_date: {
      type: 'input',
      disabled: false,
    },
    accumulator_end_date: {
      type: 'input',
      disabled: false,
    },
    plan_year: {
      type: 'input',
      disabled: false,
    }
  }
  
  export const Banner = {
    message_on_off: {
      type: 'dropdown',
      disabled: false,
      options: [
        {
          key: 'On',
          value: 'On',
          text: 'On'
        },
        {
          key: 'Off',
          value: 'Off',
          text: 'Off'
        }
      ],
    },
    image_location: {
      type: 'input',
      disabled: false,
    },
    link: {
      type: 'input',
      disabled: false,
    }
  }
  export const pricingMixmaster = {
    rank: {
      type: 'input',
      disabled: false,
      options: [],
      multiple: false,
    },
    claim_processor: {
      type: 'dropdown',
      disabled: false,
      options: [],
      multiple: false,
    },
    contract_type: {
      type: 'dropdown',
      disabled: false,
      options: [],
      multiple: true,
    },
    mixmaster_price_types: {
      type: 'dropdown',
      disabled: false,
      options: [],
      multiple: true,
    },
    pharmacy_type: {
      type: 'dropdown',
      disabled: false,
      options: [],
    },
    network_tier: {
      type: 'dropdown',
      disabled: false,
      options: [],
    },
    priority_price_types: {
      type: 'dropdown',
      options: [],
      multiple: true,
      default: [],
    },
    exclusive: {
      type: 'dropdown',
      options: [],
    },
    application: {
      type: 'dropdown',
      multiple: true,
      options: [],
      default: [],
    }
  }
  
  export const transition = {
    transition_type: {
      type: 'dropdown',
      disabled: false,
      options: [],
    },
    setting: {
      type: 'dropdown',
      disabled: false,
      options: [],
    },
    rejects: {
      type: 'dropdown',
      disabled: false,
      options: [],
    },
    reject_type: {
      type: 'dropdown',
      disabled: false,
      options: [],
    },
    ds_covered: {
      type: 'input',
      disabled: false,
    },
    exclusion_list: {
      type: 'dropdown',
      disabled: false,
    },
    emergency_ds_covered: {
      type: 'input',
      disabled: false,
    },
    lookback: {
      type: 'input',
      disabled: false,
    },
    drug_duration: {
      type: 'input',
      disabled: false,
    },
    action: {
      type: 'input',
      disabled: false,
    },
    suggestive_analytics: {
      type: 'dropdown',
      disabled: false,
    },
    approval_code: {
      type: 'input',
      disabled: false,
    },
    approval_message: {
      type: 'input',
      disabled: false,
    }
  }
  
  export const benefitLimitation = {
    action: {
      type: 'dropdown',
      disabled: false,
      options: [],
    },
    drug_type: {
      type: 'dropdown',
      disabled: false,
      options: [],
    },
    pharmacy_type: {
      type: 'dropdown',
      disabled: false,
      options: [],
    },
    min_day_supply: {
      type: 'dropdown',
      disabled: false,
      options: [],
    },
    max_day_supply: {
      type: 'dropdown',
      disabled: false,
      options: [],
    },
    days_to_search_back: {
      type: 'input',
      disabled: false
    },
    max_no_of_fills: {
      type: 'input',
      disabled: false
    },
    amount: {
      type: 'input',
      disabled: false
    },
    type: {
      type: 'dropdown',
      options: [],
    },
    rank: {
      type: 'input',
      disabled: false
    },
    cp_reject_code: {
      type: 'input',
      disabled: false
    },
    cp_reject_message: {
      type: 'input',
      disabled: false
    },
    cp_additional_reject_message: {
      type: 'input',
      disabled: false
    },
    app_message: {
      type: 'input',
      disabled: false
    },
    medicare_eligible: {
      type: 'dropdown',
      options: [],
    },
    occ: {
      type: 'dropdown',
      multiple: true,
      options: [],
    },
    medicare_type: {
      type: 'dropdown',
      options: [],
    },
    multi_source: {
      type: 'dropdown',
      options: [],
      multiple: true,
    },
    doc_name: {
      type: 'dropdown',
      options: [],
    },
    app_medicare_message_indicator: {
      type: 'dropdown',
      options: [],
    },
    minimum_age: {
      type: 'input',
      options: [],
    },
    maximum_age: {
      type: 'input',
      options: [],
    },
  }
  
  export const grandfathering = {
    function: {
      type: 'dropdown',
      options: [],
    },
    brand_generic: {
      type: 'dropdown',
      options: [],
      multiple: true,
    },
    multi_source: {
      type: 'dropdown',
      options: [],
      multiple: true,
    },
    gpi_match_level: {
      type: 'dropdown',
      options: [],
    },
    maintenance_indicator: {
      type: 'dropdown',
      options: [],
    },
    otc_indicator: {
      type: 'dropdown',
      options: [],
    },
    doc_name: {
      type: 'dropdown',
      options: [],
      multiple: true,
    },
    lookback_period: {
      type: 'input',
    },
    reject_code: {
      type: 'dropdown',
      options: [],
    },
    penalty: {
      type: 'dropdown',
      options: [],
    },
    effective_end_date: {
      type: 'input',
      options: [],
    },
  }
  