export default [
  {
    key: 'Brand',
    text: 'Brand',
    value: 'Brand',
  },
  {
    key: 'Generic',
    text: 'Generic',
    value: 'Generic',
  },
  {
    key: 'Speciality',
    text: 'Speciality',
    value: 'Speciality',
  },
]
