import {
    call,
    put,
    select,
    takeLatest,
} from 'redux-saga/effects'

import { Types, Creators as AuditManagementActions } from '../../reducers/api/auditManagement'
import { Creators as AppActions } from '../../reducers/app'
import { getAppState, getApiPath } from '../../reducers/selectors'
import { fetchGet, fetchPost, fetchPut } from '../../../utils/fetchApi'

export default [
    getDocumentWatcher,
    getFieldsWatcher,
    getQueryDataWatcher,
]

/* WATCHERS */
function* getDocumentWatcher() {
    yield takeLatest(Types.GET_DOCUMENT, getDocumentHandler)
}

function* getFieldsWatcher() {
    yield takeLatest(Types.GET_FIELDS, getFieldsHandler)
}

function* getQueryDataWatcher() {
    yield takeLatest(Types.GET_QUERY_DATA, getQueryDataHandler)
}

/* HANDLERS */
function* getDocumentHandler() {
    try {
        const { serviceUrl } = yield select(getAppState)
        const { api_path } = yield select(getApiPath, 'audit-management-indices')
        const url = `${serviceUrl}${api_path}`
        const { data } = yield call(fetchGet, url)
        yield put(AuditManagementActions.setDocument(data))
    } catch (err) {
        console.log('getDocumentHandler Error >Data ', err)
        const transitionalPortal = {
            header: 'Retrieving Document Name Failed',
            copy: err.message,
        }
        yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    }
}

function* getFieldsHandler({ payload }) {
    try {
        const { serviceUrl } = yield select(getAppState)
        const { api_path } = yield select(getApiPath, 'audit-management-fields')
        const url = `${serviceUrl}${api_path}`
        const { data } = yield call(fetchPost, url, payload)

        yield put(AuditManagementActions.setFields(data))
    } catch (err) {
        console.log('getFieldsHandler Error >Data ', err)
        const transitionalPortal = {
            header: 'Retrieving Field Name Failed',
            copy: err.message,
        }
        yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    }
}

function* getQueryDataHandler({ payload }) {
    try {
        const { serviceUrl } = yield select(getAppState)
        const { api_path } = yield select(getApiPath, 'audit-management-runQuery')
        const url = `${serviceUrl}${api_path}`
        const { data } = yield call(fetchPost, url, payload)
        if (!data.length) return

        yield put(AuditManagementActions.setQueryData(data[0]?.output))
    } catch (err) {
        console.log('saveNewAccountsHandler Error >Data ', err)
        const transitionalPortal = {
            header: 'Retrieving Snapshot Data Failed',
            copy: err.message,
        }
        yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    }
}
