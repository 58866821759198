import { useEffect, useState } from "react"
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Icon } from "semantic-ui-react"
import moment from 'moment-timezone'

import { Creators as AppActions } from '../../../../../redux/reducers/app'
import { Creators as PriorAuthorizationCreationActions } from '../../../../../redux/reducers/api/priorAuthorizationManagement'

import FliptInput from "../../../../../components/v2/fliptInput"
import FliptDropdown from "../../../../../components/v2/fliptDropdown"
import FliptButton from "../../../../../components/v2/fliptButton"
import { convertDateTimeToEstStr } from '../../../../../utils/utilities'
import * as Constants from '../../constants'

import ApprovePaModal from '../../ApprovePaModal'
import RfiModal from '../../RfiModal'
import PharmacistDenialModal from '../../PharmacistDenialModal'
import CloseModal from '../../CloseModal'
import QuestionnaireModal from '../../QuestionnaireModal'

import './styles.scss'
import { DocCreationQueueMessage, FaxQueueMessage } from "../../messageComponents"

const priorityOptions = [
  { key: '1', text: 'Standard', value: 'STANDARD' },
  { key: '2', text: 'Expedited', value: 'EXPEDITED' },
]

const caseTypeOptions = [
  { key: '1', value: 'pa', text: 'Prior Authorization' },
  { key: '2', value: 'ql', text: 'Quantity Limit' },
  { key: '3', value: 'bmn', text: 'Brand Medically Necessary' },
  { key: '4', value: 'nc', text: 'Drug Not Covered' },
]

const checkMemberEligibility = (patientBenefitData) => {
  if (patientBenefitData?.effective_date) {
    const date = moment().format('YYYY-MM-DD')
    const start = moment(patientBenefitData?.effective_date).format('YYYY-MM-DD')
    const end = moment(patientBenefitData?.term_date).format('YYYY-MM-DD')
    if (date < start || date > end) {
      return false
    }
    return true
  } else return true
}

const getActionOptions = (paCaseDetails) => {
  // const { memberSearched, memberData } = this.state
  const {
    pa_case_id,
    pa_case_status,
    member_information_validated,
    patient_benefit
  } = paCaseDetails

  if (!pa_case_id) {
    return []
  }

  // if (memberSearched && !memberData?.coverage_start_date && !memberData?.effective_date) {
  //   return [Constants.VOID_CLOSE_OPTION, Constants.CLOSED_VOID_CLOSE_OPTION]
  // }

  if (member_information_validated && !checkMemberEligibility(patient_benefit)) {
    return [Constants.VOID_CLOSE_OPTION, Constants.CLOSED_VOID_CLOSE_OPTION]
  }

  switch (pa_case_status) {
    case Constants.NEW_STR:
      return [
        Constants.VIEW_QUESTIONNAIRE_OPTION,
        Constants.HOLD_PENDING_OPTION,
        Constants.IN_PROCESS_OPTION,
        Constants.VOID_CLOSE_OPTION,
      ]
    case Constants.IN_PROCESS_STR:
      return [
        Constants.VIEW_QUESTIONNAIRE_OPTION,
        Constants.VIEW_COMPLETED_QUESTIONNAIRE_OPTION,
        Constants.HOLD_PENDING_OPTION,
        Constants.RFI_OPTION,
        Constants.DENIED_OPTION,
        Constants.APPROVED_OPTION,
        Constants.VOID_CLOSE_OPTION,
      ]
    case Constants.HOLD_PENDING_STR:
      return [
        Constants.VIEW_QUESTIONNAIRE_OPTION,
        Constants.VIEW_COMPLETED_QUESTIONNAIRE_OPTION,
        Constants.IN_PROCESS_OPTION,
      ]
    case Constants.VOID_CLOSE_STR:
    case Constants.DOCUMENT_CREATION_VOID_CLOSE_STR:
    case Constants.OUTBOUND_COMMUNICATION_VOID_CLOSE_STR:
      return [Constants.VOID_CLOSE_OPTION, Constants.CLOSED_VOID_CLOSE_OPTION]
    case Constants.RFI_STR:
      return [
        Constants.VIEW_QUESTIONNAIRE_OPTION,
        Constants.VIEW_COMPLETED_QUESTIONNAIRE_OPTION,
        Constants.IN_PROCESS_OPTION,
        Constants.RFI_OPTION,
        Constants.DENIED_OPTION,
        Constants.APPROVED_OPTION,
      ]
    case Constants.APPROVED_STR:
    case Constants.DENIED_STR:
      return [
        Constants.VIEW_QUESTIONNAIRE_OPTION,
        Constants.VIEW_COMPLETED_QUESTIONNAIRE_OPTION,
        Constants.RFI_OPTION,
        Constants.DENIED_OPTION,
        Constants.APPROVED_OPTION,
      ]
    case Constants.DOCUMENT_CREATION_APPROVED_STR:
    case Constants.OUTBOUND_COMMUNICATION_APPROVED_STR:
      return [
        Constants.CLOSED_APPROVED_OPTION,
        Constants.VIEW_QUESTIONNAIRE_OPTION,
        Constants.VIEW_COMPLETED_QUESTIONNAIRE_OPTION,
        Constants.RFI_OPTION,
        Constants.DENIED_OPTION,
        Constants.APPROVED_OPTION,
      ]
    case Constants.DOCUMENT_CREATION_DENIED_STR:
    case Constants.OUTBOUND_COMMUNICATION_DENIED_STR:
      return [
        Constants.CLOSED_DENIED_OPTION,
        Constants.VIEW_QUESTIONNAIRE_OPTION,
        Constants.VIEW_COMPLETED_QUESTIONNAIRE_OPTION,
        Constants.RFI_OPTION,
        Constants.DENIED_OPTION,
        Constants.APPROVED_OPTION,
      ]
    case Constants.CLOSED_DENIED_STR:
      return [Constants.VIEW_COMPLETED_QUESTIONNAIRE_OPTION, Constants.REOPEN_APPEAL_1_OPTION]
    case Constants.REOPEN_APPEAL_1_STR:
      return [
        Constants.VIEW_QUESTIONNAIRE_OPTION,
        Constants.VIEW_COMPLETED_QUESTIONNAIRE_OPTION,
        Constants.IN_PROCESS_APPEAL_1_OPTION,
        Constants.RFI_APPEAL_1_OPTION,
        Constants.DENIED_APPEAL_1_OPTION,
        Constants.APPROVED_APPEAL_1_OPTION,
      ]
    case Constants.IN_PROCESS_APPEAL_1_STR:
    case Constants.APPROVED_APPEAL_1_STR:
    case Constants.DENIED_APPEAL_1_STR:
      return [
        Constants.VIEW_QUESTIONNAIRE_OPTION,
        Constants.VIEW_COMPLETED_QUESTIONNAIRE_OPTION,
        Constants.RFI_APPEAL_1_OPTION,
        Constants.DENIED_APPEAL_1_OPTION,
        Constants.APPROVED_APPEAL_1_OPTION,
      ]
    case Constants.RFI_APPEAL_1_STR:
      return [
        Constants.VIEW_QUESTIONNAIRE_OPTION,
        Constants.VIEW_COMPLETED_QUESTIONNAIRE_OPTION,
        Constants.IN_PROCESS_APPEAL_1_OPTION,
        Constants.RFI_APPEAL_1_OPTION,
        Constants.DENIED_APPEAL_1_OPTION,
        Constants.APPROVED_APPEAL_1_OPTION,
      ]
    case Constants.DOCUMENT_CREATION_DENIED_APPEAL_1_STR:
    case Constants.OUTBOUND_COMMUNICATION_DENIED_APPEAL_1_STR:
      return [
        Constants.CLOSED_DENIED_UPHELD_1_OPTION,
        Constants.VIEW_QUESTIONNAIRE_OPTION,
        Constants.VIEW_COMPLETED_QUESTIONNAIRE_OPTION,
        Constants.RFI_APPEAL_1_OPTION,
        Constants.DENIED_APPEAL_1_OPTION,
        Constants.APPROVED_APPEAL_1_OPTION,
      ]
    case Constants.DOCUMENT_CREATION_APPROVED_APPEAL_1_STR:
    case Constants.OUTBOUND_COMMUNICATION_APPROVED_APPEAL_1_STR:
      return [
        Constants.CLOSED_APPEAL_APPROVED_1_OPTION,
        Constants.VIEW_QUESTIONNAIRE_OPTION,
        Constants.VIEW_COMPLETED_QUESTIONNAIRE_OPTION,
        Constants.RFI_APPEAL_1_OPTION,
        Constants.DENIED_APPEAL_1_OPTION,
        Constants.APPROVED_APPEAL_1_OPTION,
      ]
    case Constants.CLOSED_DENIED_UPHELD_1_STR:
      return [
        Constants.VIEW_QUESTIONNAIRE_OPTION,
        Constants.VIEW_COMPLETED_QUESTIONNAIRE_OPTION,
        Constants.REOPEN_APPEAL_2_OPTION,
      ]
    case Constants.REOPEN_APPEAL_2_STR:
      return [
        Constants.VIEW_QUESTIONNAIRE_OPTION,
        Constants.VIEW_COMPLETED_QUESTIONNAIRE_OPTION,
        Constants.IN_PROCESS_APPEAL_2_OPTION,
        Constants.RFI_APPEAL_2_OPTION,
        Constants.DENIED_APPEAL_2_OPTION,
        Constants.APPROVED_APPEAL_2_OPTION,
      ]
    case Constants.IN_PROCESS_APPEAL_2_STR:
    case Constants.APPROVED_APPEAL_2_STR:
    case Constants.DENIED_APPEAL_2_STR:
      return [
        Constants.VIEW_QUESTIONNAIRE_OPTION,
        Constants.VIEW_COMPLETED_QUESTIONNAIRE_OPTION,
        Constants.RFI_APPEAL_2_OPTION,
        Constants.DENIED_APPEAL_2_OPTION,
        Constants.APPROVED_APPEAL_2_OPTION,
      ]
    case Constants.RFI_APPEAL_2_STR:
      return [
        Constants.VIEW_QUESTIONNAIRE_OPTION,
        Constants.VIEW_COMPLETED_QUESTIONNAIRE_OPTION,
        Constants.IN_PROCESS_APPEAL_2_OPTION,
        Constants.RFI_APPEAL_2_OPTION,
        Constants.DENIED_APPEAL_2_OPTION,
        Constants.APPROVED_APPEAL_2_OPTION,
      ]
    case Constants.DOCUMENT_CREATION_DENIED_APPEAL_2_STR:
    case Constants.OUTBOUND_COMMUNICATION_DENIED_APPEAL_2_STR:
      return [
        Constants.CLOSED_DENIED_UPHELD_2_OPTION,
        Constants.VIEW_QUESTIONNAIRE_OPTION,
        Constants.VIEW_COMPLETED_QUESTIONNAIRE_OPTION,
        Constants.RFI_APPEAL_2_OPTION,
        Constants.DENIED_APPEAL_2_OPTION,
        Constants.APPROVED_APPEAL_2_OPTION,
      ]
    case Constants.DOCUMENT_CREATION_APPROVED_APPEAL_2_STR:
    case Constants.OUTBOUND_COMMUNICATION_APPROVED_APPEAL_2_STR:
      return [
        Constants.CLOSED_APPEAL_APPROVED_2_OPTION,
        Constants.VIEW_QUESTIONNAIRE_OPTION,
        Constants.VIEW_COMPLETED_QUESTIONNAIRE_OPTION,
        Constants.RFI_APPEAL_2_OPTION,
        Constants.DENIED_APPEAL_2_OPTION,
        Constants.APPROVED_APPEAL_2_OPTION,
      ]
    case Constants.CLOSED_APPROVED_STR:
    case Constants.CLOSED_APPEAL_APPROVED_1_STR:
    case Constants.CLOSED_APPEAL_APPROVED_2_STR:
    case Constants.CLOSED_DENIED_UPHELD_2_STR:
    case Constants.CLOSED_VOID_CLOSE_STR:
      return [Constants.VIEW_COMPLETED_QUESTIONNAIRE_OPTION]
    case Constants.OPEN_OUTBOUND_ERROR_STR:
    case Constants.IN_PROCESS_OUTBOUND_ERROR_STR:
    case Constants.RFI_OUTBOUND_ERROR_STR:
    case Constants.CLOSED_OUTBOUND_ERROR_STR:
    case Constants.APPROVED_OUTBOUND_ERROR_STR:
    case Constants.DENIED_OUTBOUND_ERROR_STR:
    case Constants.VOID_OUTBOUND_ERROR_STR:
    case Constants.CANCELLED_OUTBOUND_ERROR_STR:
    case Constants.ERROR_STR:
    case Constants.DATA_ERROR_STR:
      return [Constants.CLOSED_ERROR_RESOLVED_OPTION, Constants.CLOSED_ERROR_UNRESOLVED_OPTION]
    default:
      return []
  }
}


const CaseInformation = ({ lockCase, actions, state: reduxState }) => {
  const { paCaseDetails, getAnswerData, questionnaireData, user, users } = reduxState

  const {
    case_type,
    date_time_due,
    date_time_received,
    intake_type,
    pa_case_id,
    pa_case_status,
    priority_indicator,
    queue_action,
    queue_status,
    fax_action,
    fax_status,
    fax_error_message = ""
  } = paCaseDetails || {}


  const history = useHistory()
  const [state, setState] = useState({
    caseType: '',
    priorityIndicator: '',
    paCaseStatus: '',
    viewCompletedQuestionnaire: false,
    viewQuestionnaire: false,
  })


  useEffect(() => {
    setState(prevState => ({
      ...prevState,
      caseType: case_type,
      priorityIndicator: priority_indicator,
    }))
  }, [paCaseDetails])

  useEffect(() => {
    const { patient_benefit, patient } = paCaseDetails
    const memberDetails = { ...patient_benefit, ...patient }
    let questData = null
    if (state.viewQuestionnaire && questionnaireData && questionnaireData?.questionnaire?.length) {
      setState(prevState => ({ ...prevState, viewQuestionnaire: false }))
      questData = questionnaireData
    }
    if (state.viewCompletedQuestionnaire && getAnswerData && getAnswerData?.questionnaire_ans?.questionnaire?.length) {
      if (paCaseDetails.intake_type === Constants.SURESCRIPTS_STR)
        actions.getPaCaseDetails({ pa_case_id: paCaseDetails.pa_case_id })
      setState(prevState => ({ ...prevState, viewCompletedQuestionnaire: false }))
      questData = getAnswerData.questionnaire_ans
    }
    if (questData) {
      actions.setModalComponent({
        modalProperties: { size: 'large' },
        contents: <QuestionnaireModal planForm={paCaseDetails} lockCase={lockCase} quesData={questionnaireData}
          memberDetails={memberDetails} pa_case_id={paCaseDetails.pa_case_id} />,
      })
      actions.toggleModal()
    }
  }, [getAnswerData, questionnaireData])

  const showTransitionalPortal = (header, message) => {
    const transitionalPortal = {
      header: header,
      copy: message,
    }
    actions.displayTransitionalPortal(transitionalPortal)
  }


  const openSideDrawer = (status) => {
    const { patient_benefit } = paCaseDetails
    let SideDrawerComponent = null

    switch (status) {
      case Constants.APPROVED_STR:
      case Constants.APPROVED_APPEAL_1_STR:
      case Constants.APPROVED_APPEAL_2_STR:
        SideDrawerComponent = ApprovePaModal
        break
      case Constants.RFI_STR:
      case Constants.RFI_APPEAL_1_STR:
      case Constants.RFI_APPEAL_2_STR:
        SideDrawerComponent = RfiModal
        break
      case Constants.DENIED_STR:
      case Constants.DENIED_APPEAL_1_STR:
      case Constants.DENIED_APPEAL_2_STR:
        SideDrawerComponent = PharmacistDenialModal
        break
      case Constants.VOID_CLOSE_STR:
        SideDrawerComponent = CloseModal
        break
    }

    if (SideDrawerComponent) {
      actions.setSideDrawerComponent({
        modalProperties: { size: 'large' },
        contents: <SideDrawerComponent planForm={paCaseDetails} selectedStatus={status} lockCase={lockCase} />,
      })
      actions.toggleSideDrawer()
      return true
    }

    if (status === Constants.VIEW_QUESTIONNAIRE_STR) {
      if (paCaseDetails?.medication?.gpi || paCaseDetails?.medication?.ndc) {
        setState({ viewQuestionnaire: true })
        const params = {
          ndc: paCaseDetails?.medication?.ndc,
          gpi: paCaseDetails?.medication?.gpi,
          group: patient_benefit?.group,
          case_type: paCaseDetails.case_type,
          source: 'FAST',
        }
        actions.getQuestionnaire(params)
      }
      return true
    }

    if (status === Constants.VIEW_COMPLETED_QUESTIONNAIRE_STR) {
      actions.getAnswer({ pa_case_id: paCaseDetails.pa_case_id })
      return true
    }
    return false
  }

  const handleInputChange = (el, dropdown) => {
    const { name, value } = dropdown || el.currentTarget

    const allowedStatusUpdateWithoutValidation = [
      Constants.VOID_CLOSE_STR,
      Constants.CLOSED_VOID_CLOSE_STR,
      Constants.CLOSED_ERROR_RESOLVED_STR,
      Constants.CLOSED_ERROR_UNRESOLVED_STR
    ]
    if (name === 'paCaseStatus') {
      if ((!allowedStatusUpdateWithoutValidation.includes(value)) && (!paCaseDetails?.prescriber_information_validated
        || !paCaseDetails?.drug_information_validated || !paCaseDetails?.member_information_validated)) {
        const field = 'Fields Missing'
        const message = 'Please Add Drug, Prescriber and Member to change the Status'
        showTransitionalPortal(field, message)
        return;
      }
      const didSideDrawerOpen = openSideDrawer(value)
      if (didSideDrawerOpen) {
        setState(prevState => ({
          ...prevState,
          [name]: ''
        }))
        return
      }
    }

    setState(prevState => ({
      ...prevState,
      [name]: value
    }))
  }

  const getLockedCaseString = (params) => {
    const name = users.find(x => x?.user_id === params)?.name
    if (name)
      return `This case is locked by ${name}`
    else
      return `This case is locked by another user`
  }

  const goToPADashboard = () => {
    history.push('/pa-dashboard')
  }

  // only updates case_type, pa_case_status and priority (CaseInformation section)
  // do not use this to update member, prescriber or 
  // other info that require verification_section arg
  const saveCaseInformaiton = ({ close = false } = {}) => {
    let { paCaseStatus } = state
    // check Constants.STATUSES_UPDATABLE_FROM_DROPDOWN if we need to filter more
    if (!paCaseStatus || Constants.NOT_STATUS_STRS.includes(paCaseStatus)) {
      paCaseStatus = paCaseDetails.pa_case_status
    }

    const params = {
      pa_case_id: paCaseDetails.pa_case_id,
      case_type: state.caseType,
      priority_indicator: state.priorityIndicator,
      request_type: 'main_details',
      pa_case_status: paCaseStatus,
      first_name: user?.first_name || "",
      last_name: user?.last_name || "",
      userId: user?.uuid
    }

    if (close) {
      params.callback = goToPADashboard
    }
    actions.updatePaCase(params)
  }

  const reassignCaseCallback = () => {
    actions.getPaCaseDetails({ pa_case_id })
  }

  const reassignCase = () => {
    const params = {
      pa_case_id: paCaseDetails.pa_case_id,
      userId: user.uuid,
      username: `${user.first_name} ${user.last_name}`
    }

    actions.reassignPACase({ ...params, callback: reassignCaseCallback })
  }

  const isTestClaimVisible = () => {
    if (Constants.TEST_CLAIM_VISIBLE_STATUSES.includes(paCaseDetails?.pa_case_status)) return true
    return false
  }

  const navigateToTestClaim = () => {
    const { patient_benefit, medication, prescriber, pharmacy } = paCaseDetails
    const claimDetails = {
      cardholder_id: patient_benefit?.member_id,
      product_id: medication?.ndc,
      prescriber_id: prescriber?.npi,
      service_provider_id: pharmacy?.npi,
      days_supply: medication?.days_supply,
      quantity_dispensed: medication?.quantity,
      prescription_reference_number: "9999999999",
      ingredient_cost_submitted: "9999999999",
      dispensing_fee_submitted: "9999999999",
      usual_and_customary_charge: "9999999999",
      gross_amount_due: "9999999999",
      basis_of_cost_determination: '01',
      fill_number: '1',
      compound_code: '1',
      date_prescription_written: moment(new Date()).format('YYYYMMDD')
    }
    history.push({
      pathname: "/test-claim-generation",
      state: { ...claimDetails }
    })
  }

  return (
    <div id='pa-case-information' className="section-container">
      <div className="section-header-container">
        <span className="section-header">Case Information</span>
      </div>
      <div className="section-row">
        <FliptInput
          disabled
          label="Request"
          name="request"
          value={pa_case_id}
        />
        <FliptDropdown
          disabled={!pa_case_id || lockCase}
          label="Case Type"
          name="caseType"
          onChange={handleInputChange}
          options={caseTypeOptions}
          value={state.caseType}
        />
        <FliptInput
          disabled
          // icon={<Icon className="question circle outline" />}
          label="Case Status"
          name="case_status"
          value={pa_case_status}
        />
        <FliptInput
          disabled
          // icon={<Icon className="question circle outline" />}
          label="Source"
          name="intake_type"
          value={intake_type}
        />
      </div>
      <div className="section-row">
        <FliptInput
          disabled
          label="Date Time Recieved"
          name="date_time_recieved"
          value={convertDateTimeToEstStr(date_time_received)}
        />
        <FliptInput
          disabled
          label="Date Time Due"
          value={convertDateTimeToEstStr(date_time_due)}
        />
        <FliptDropdown
          disabled={!pa_case_id || lockCase}
          label="Action"
          name="paCaseStatus"
          onChange={handleInputChange}
          options={getActionOptions(paCaseDetails)}
          value={state.paCaseStatus}
        />
        <FliptDropdown
          disabled={!pa_case_id || lockCase}
          label="Priority"
          name="priorityIndicator"
          onChange={handleInputChange}
          options={priorityOptions}
          value={state.priorityIndicator}
        />
      </div>
      <div className="case-information-status">
        <DocCreationQueueMessage queue_action={queue_action} queue_status={queue_status} />
        <FaxQueueMessage fax_action={fax_action} fax_status={fax_status} fax_error_message={fax_error_message} />
        {lockCase && <div className='lock-msg'>{getLockedCaseString(paCaseDetails.review_technician)}</div>}
      </div>
      <div className="case-action-buttons">
        <FliptButton
          className="primary"
          name="Add Note"
          disabled icon={<Icon name="file alternate outline" />}
        />
        <FliptButton
          className="primary"
          name="Save"
          disabled={!pa_case_id || lockCase}
          icon={<Icon name="save outline" />}
          onClick={() => saveCaseInformaiton()}
        />
        <FliptButton
          className="primary"
          name="Save & Close"
          disabled={!pa_case_id || lockCase}
          icon={<Icon name="save outline" />}
          onClick={() => saveCaseInformaiton({ close: true })}
        />
        <FliptButton
          className="primary"
          name="Close"
          icon={<Icon name="close" />}
          onClick={goToPADashboard}
        />
        {lockCase && <FliptButton className='primary' name='Assign To Me' onClick={() => reassignCase()} />}
        {isTestClaimVisible() && <FliptButton className='primary' disabled={!pa_case_id || lockCase} name='Test Claim' onClick={() => navigateToTestClaim()} />}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  state: {
    getAnswerData: state.priorAuthorizationCreation.getAnswerData,
    paCaseDetails: state.priorAuthorizationCreation.paCaseDetails,
    questionnaireData: state.priorAuthorizationCreation.questionnaireData,
    user: state.user,
    users: state.userManagement?.data?.map(x => ({ name: `${x?.first_name} ${x?.last_name}`, user_id: x?.uuid })),
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...PriorAuthorizationCreationActions,
    ...AppActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CaseInformation)
