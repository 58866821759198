import {
    call,
    put,
    select,
    takeLatest,
  } from 'redux-saga/effects'
  
  import { Types, Creators as RxreportLookupActions } from '../../reducers/api/rxreportLookup'
  import { Creators as AppActions } from '../../reducers/app'
  import {
    getAppState, getApiPath, getRxreportLookup, getHistory,
  } from '../../reducers/selectors'
  import { fetchGet, fetchPost,fetchPut,fileUploadPost } from '../../../utils/fetchApi'
  import { createQueryString, filterRowData } from '../../../utils/utilities'
  
  export default [
    getRxreportLookupDataWatcher,
    getRxreportSuggestDataWatcher,
    getRxreportUploadFileDataWatcher
  ]
  
  /* WATCHERS */
  function* getRxreportLookupDataWatcher() {
    yield takeLatest(Types.GET_RXREPORT_LOOKUP_DATA, getRxreportLookupDataHandler)
  }
  
  function* getRxreportSuggestDataWatcher() {
    yield takeLatest(Types.GET_RXREPORT_SUGGEST_DATA, getRxreportSuggestDataHandler)
  }


  function* getRxreportUploadFileDataWatcher() {
   
    yield takeLatest(Types.GET_RXREPORT_UPLOAD_FILE_DATA, getRxreportUploadFileDataHandler)
  }
  
  /* HANDLERS */
  function* getRxreportLookupDataHandler({ payload }) {
    try {
      const {
        form, params, headers, headerMapping,
      } = payload
     
      const { serviceUrl } = yield select(getAppState)
      const history = yield select(getHistory)
     
      const { rxreportPerPage } = yield select(getRxreportLookup)
      history.location.search = createQueryString({
        ...form,
        offset: params?.request.startRow,
      })
      const qs = history.location.search ? `${history.location.search}&limit=${rxreportPerPage}` : ''
     
      const requiredFields = ['drug_name', 'end_date', 'flipt_person_id', 'rx_status',"start_date","domain_name","offset","use_date"]
      form.rx_status=form.rx_status=="PA INITIATED"?"PA":form.rx_status=="PA CANCELED"?"Cancelled":form.rx_status
      if (!requiredFields.some((field) => form[field])) {
        params?.success({
          rowData: [],
          rowCount: 0,
        })
      } else {
        const { api_path } = yield select(getApiPath, 'rxreport-lookup')
        const url = `${serviceUrl}${api_path}`
       
        const reqData = {
          sortModel: params?.request.sortModel,
          filterModel: params?.request.filterModel,
          drug_name:form.drug_name,
          flipt_person_id:form.flipt_person_id,
          rx_status:form.rx_status,
          start_date:form.start_date,
          end_date:form.end_date,
          domain_name:form.domain_name,
          offset:params?.request.startRow,
          use_date:form.use_date
        }
        const { data, total_rows } = yield call(fetchGet, url, reqData)
        const gridData = data?.map((d) => ({
          ...filterRowData(d, headers, headerMapping),
        }))
        params?.success({
          rowData: gridData,
          rowCount: parseInt(total_rows, 10),
        })
      }
    } catch (err) {
      console.log('getRxreportLookupDataHandler Error >Data ', err)
  
      const transitionalPortal = {
        header: 'Rxreport Lookup Failed',
        copy: err.message,
      }
      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    }
  }
  
  function* getRxreportSuggestDataHandler({ payload }) {
    try {
     
      yield put(RxreportLookupActions.setLoading({ data: true }))
      const { serviceUrl } = yield select(getAppState)
      const history = yield select(getHistory)
  
      const { rxreportPerPage } = yield select(getRxreportLookup)
      const qs = history.location.search ? `${history.location.search}&limit=${rxreportPerPage}` : ''
  
      if (!qs) return
  
      const cd = yield select(getApiPath, 'rxreport-update')
      const { api_path } = cd
      const url = `${serviceUrl}${api_path}`
      const reqData ={
        "prescription_id": payload.pa.prescription_id, 
        "status": payload.pa.status,
         "pa_decision_date": new Date().toISOString() ,
          "flipt_person_id":  payload.pa.flipt_person_id,
           "drug_name": payload.pa.drug_name,
            "pharmacy": payload.pa.pharmacy,
             "pa_override":  payload.pa.pa_override,
              "pa_override_end_date":payload.paOverride? payload.pa_override_end_date.toISOString():"", 
             "note": payload.note, 
             "domain": payload.pa.domain, 
             "pharmacy_npi":payload.pa.pharmacy_npi==undefined?"":payload.pa.pharmacy_npi,
             "user_email":"ada@text.com",
             "user_id":payload.user.user_id,
             "user_name":payload.user.user_name
    }
    
        const { message } = yield call(fetchPut, url, reqData)
     
      yield put(RxreportLookupActions.setRxreportSuggestData({ message }))
      yield put(RxreportLookupActions.setLoading({ data: false }))
      payload.callback()
    } catch (err) {
      console.log('getRxreportSuggestDataHandler Error >Data ', err)
  
      const transitionalPortal = {
        header: 'Rxreport Lookup Failed',
        copy: err.message,
      }
      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
      yield put(RxreportLookupActions.setLoading({ data: false }))
    }
  }
  


  function* getRxreportUploadFileDataHandler({ payload },a) {
    try {
      
     
      yield put(RxreportLookupActions.setLoading({ data: true }))
      const { serviceUrl } = yield select(getAppState)
      const history = yield select(getHistory)
  
      const { rxreportPerPage } = yield select(getRxreportLookup)
      const qs = history.location.search ? `${history.location.search}&limit=${rxreportPerPage}` : ''
  
      if (!qs) return
  
      const cd = yield select(getApiPath, 'rxreport-fileupload')
      const { api_path } = cd
      const url = `${serviceUrl}${api_path}`
      const formData = new FormData();
    
      // Update the formData object
      formData.append(
        "files",payload.file[0]);
        formData.append("prescription_id",payload.pa.prescription_id)
   
   
      const { message } = yield call(fileUploadPost, url, formData)
      yield put(RxreportLookupActions.setRxreportSuggestData({ message }))    
      yield put(RxreportLookupActions.setLoading({ data: false }))
      payload.callback()
    } catch (err) {
      console.log('getRxreportUploadFileDataHandler Error >Data ', err)
  
      const transitionalPortal = {
        header: 'Rxreport Lookup Failed',
        copy: err.message,
      }
      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
      yield put(RxreportLookupActions.setLoading({ data: false }))
    }
  }
  