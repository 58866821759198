import axios from 'axios'

import AppLocalStorage from './localStorage'
import APP_CONFIG from '../config'

export const fetchPost = async (url, payload, showLoader = true) => fliptFetch(url, 'POST', payload, showLoader)
export const fetchGet = async (url, payload, showLoader = true) => fliptFetch(url, 'GET', payload, showLoader)
export const fetchPut = async (url, payload, showLoader = true) => fliptFetch(url, 'PUT', payload, showLoader)
export const fetchPatch = async (url, payload, showLoader = true) => fliptFetch(url, 'PATCH', payload, showLoader)
export const fileUploadPost = async (url, payload, showLoader = true) => fliptFileUpload(url, 'POST', payload, showLoader)
export const fileUploadPut = async (url, payload, showLoader = true) => fliptFileUpload(url, 'PUT', payload, showLoader)
export const fileUploadPatch = async (url, payload, showLoader = true) => fliptFileUpload(url, 'PATCH', payload, showLoader)
export const fileDownload = async (url, payload, showLoader = true) => fliptDownload(url, 'POST', payload, showLoader)

async function fliptFetch(url, method = 'GET', payload = {}, showLoader = true) {
  try {
    if (showLoader) {
      global.toggleLoader()
    }
    const headers = {
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Allow-Origin': '*',
      'Application-ID': APP_CONFIG.applicationId,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    }
    const jwt = AppLocalStorage.getItem('jwt')

    if (jwt) {
      headers.Authorization = `Bearer ${jwt}`
    }

    const options = {
      headers,
      method,
      url,
    }
    // eslint-disable-next-line no-unused-vars
    // const payloadData = Object.entries(payload).filter(([key, value]) => value).reduce((acc, curr) => {
    //   const [key, val] = curr
    //   if (!val) return acc

    //   acc[key] = val
    //   return acc
    // }, {})

    if (method === 'GET') {
      options.params = payload
    } else {
      options.data = payload
    }

    const response = await axios(options)
    if (showLoader) {
      global.toggleLoader()
    }
    return response.data
  } catch (err) {
    if (showLoader) {
      global.toggleLoader()
    }
    if (err.response.status == 401) {
      window.localStorage.clear()
      window.userLogout()
      window.location.href = "/"
    }
    throw err.response.data.message
  }
}


async function fliptFileUpload(url, method = 'GET', payload = {}, showLoader = true) {
  try {
    if (showLoader) {
      global.toggleLoader()
    }
    const headers = {
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Allow-Origin': '*',
      'Application-ID': APP_CONFIG.applicationId,
      "Content-Type": "multipart/form-data",
      Accept: 'application/json',
    }

    const jwt = AppLocalStorage.getItem('jwt')

    if (jwt) {
      headers.Authorization = `Bearer ${jwt}`
    }

    const options = {
      headers,
      method,
      url,
    }

    if (method === 'GET') {
      options.params = payload
    } else {
      options.data = payload
    }

    const response = await axios(options)
    if (showLoader) {
      global.toggleLoader()
    }
    return response.data
  } catch (err) {
    if (showLoader) {
      global.toggleLoader()
    }
    if (!err.response) {
      throw err.message || 'Error during upload'
    }
    if (err.response.status == 401) {
      window.location.href = "/"
      window.userLogout()
    }
    throw err.response.data.message
  }
}
async function fliptDownload(url, method = 'GET', payload = {}, showLoader = true) {
  try {
    if (showLoader) {
      global.toggleLoader()
    }
    const headers = {
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Allow-Origin': '*',
      'Application-ID': APP_CONFIG.applicationId,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    }
    const jwt = AppLocalStorage.getItem('jwt')

    if (jwt) {
      headers.Authorization = `Bearer ${jwt}`
    }

    const options = {
      headers,
      method,
      url,
      responseType: 'blob'
    }
    // eslint-disable-next-line no-unused-vars
    // const payloadData = Object.entries(payload).filter(([key, value]) => value).reduce((acc, curr) => {
    //   const [key, val] = curr
    //   if (!val) return acc

    //   acc[key] = val
    //   return acc
    // }, {})

    if (method === 'GET') {
      options.params = payload
    } else {
      options.data = payload
    }

    const response = await axios(options)
    if (showLoader) {
      global.toggleLoader()
    }
    return response?.data || response
  } catch (err) {
    if (showLoader) {
      global.toggleLoader()
    }
    if (err.response.status == 401) {
      window.userLogout()
      window.location.href = "/"
    }
    throw err.response.data.message
  }
}
