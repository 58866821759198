import {
  all,
  call,
  put,
  select,
  takeLatest,
  take
} from 'redux-saga/effects'

import {
  getAppState, getApiPath, getHistory,
  getNetworkTierCreation, isMenuEmpty
} from '../../reducers/selectors'
import { Creators as AppActions, Types as AppTypes } from '../../reducers/app'
import { Creators as NavigationActions } from '../../reducers/navigation'
import { filterEmptyfromObj, createQueryString, filterRowData } from '../../../utils/utilities'
import { fetchGet, fetchPost, fetchPut } from '../../../utils/fetchApi'
import { Types, Creators as NetworkCreationActions } from '../../reducers/api/networkCreationManagement'

export default [
  createNewVersionWatcher,
  generateAssignedPharmacyWatcher,
  generateNetworkEditWatcher,
  generateNetworkTierWatcher,
  generateNetworkWatcher,
  getAllNetworkDataWatcher,
  getAllNetworkEditsDataWatcher,
  getAllNetworkTiersDataWatcher,
  getClaimProcessorNetworkDataWatcher,
  getNetworkDataWatcher,
  getNetworkTierDataWatcher,
  getPharmacyNetworkLookupDataWatcher,
  getPharmacyNetworkTierLookupWatcher,
  publishDocumentWatcher,
]

// /* WATCHERS */
function* getAllNetworkTiersDataWatcher() {
  yield takeLatest(Types.GET_ALL_NETWORK_TIERS_DATA, getAllNetworkTiersDataHandler)
}

function* generateNetworkTierWatcher() {
  yield takeLatest(Types.GENERATE_NETWORK_TIER, generateNetworkTierHandler)
}

function* getAllNetworkDataWatcher() {
  yield takeLatest(Types.GET_ALL_NETWORKS_DATA, getAllNetworksDataHandler)
}

function* generateNetworkWatcher() {
  yield takeLatest(Types.GENERATE_NETWORK, generateNetworkHandler)
}

function* getAllNetworkEditsDataWatcher() {
  yield takeLatest(Types.GET_ALL_NETWORK_EDITS_DATA, getAllNetworkEditsDataHandler)
}

function* generateNetworkEditWatcher() {
  yield takeLatest(Types.GENERATE_NETWORK_EDIT, generateNetworkEditHandler)
}

function* generateAssignedPharmacyWatcher() {
  yield takeLatest(Types.GENERATE_ASSIGNED_PHARMACY, generateAssignedPharmacyHandler)
}

function* publishDocumentWatcher() {
  yield takeLatest(Types.PUBLISH_DOCUMENT, publishDocumentHandler)
}

function* createNewVersionWatcher() {
  yield takeLatest(Types.CREATE_NEW_VERSION, createNewVersionHandler)
}

function* getClaimProcessorNetworkDataWatcher() {
  yield* [all([
    takeLatest(Types.GET_CLAIM_PROCESSOR_NETWORK_DATA, getChainCodeDataHandler),
    takeLatest(Types.GET_CLAIM_PROCESSOR_NETWORK_DATA, getSpecialtyDrugDataHandler)
  ])]
}

function* getNetworkTierDataWatcher() {
  yield takeLatest(Types.GET_NETWORK_TIER_DATA, getNetworkTierDataHandler)
}

function* getNetworkDataWatcher() {
  yield takeLatest(Types.GET_NETWORK_DATA, getNetworkDataHandler)
}

function* getPharmacyNetworkLookupDataWatcher() {
  yield takeLatest(Types.GET_PHARMACY_NETWORK_LOOKUP_DATA, getPharmacyNetworkLookupDataHandler)
}

function* getPharmacyNetworkTierLookupWatcher() {
  yield takeLatest(Types.GET_PHARMACY_NETWORK_TIER_LOOKUP, getPharmacyNetworkTierLookupHandler)
}

function* getPharmacyNetworkLookupDataHandler({ payload }) {
  try {
    const { form, params, headers, headerMapping } = payload
    const { serviceUrl } = yield select(getAppState)
    const history = yield select(getHistory)
    const { pharmaciesPerPage } = yield select(getNetworkTierCreation)
    const f = filterEmptyfromObj(form)
    let tempForm = { ...form }
    if (headerMapping && params?.request) {
      const inv_headerMapping = Object.keys(headerMapping).reduce((obj, key) => {
        let newObj = {}
        newObj = { [headerMapping[key]]: key }
        return { ...obj, ...newObj }
      }, {})
      // uses inverted headerMapping to convert header names into db names
      params.request.filterModel = filterRowData(params?.request.filterModel, headers, inv_headerMapping)
    }
    history.location.search = createQueryString({
      ...tempForm,
      offset: params?.request.startRow,
    })
    const qs = history.location.search ? `${history.location.search}&limit=${pharmaciesPerPage}` : ''
    if (!qs) {
      params?.success({
        rowData: [],
        rowCount: 0,
      })
    } else {
      const empty = yield select(isMenuEmpty)
      if (empty) {
        yield take([AppTypes.SET_APP_SETTINGS])
      }

      const reqData = {
        doc_id: tempForm?.doc_id, // need it here to make it easy for json deserialization
        doc_name: tempForm?.doc_name, // need it here to make it easy for json deserialization
        doc_version: tempForm?.doc_version,
        tier_id: tempForm?.tier_id,
        tier_name: tempForm?.tier_name,
        tier_version: tempForm?.tier_version,
        sortModel: params?.request.sortModel,
        filterModel: params?.request.filterModel,
        limit: pharmaciesPerPage,
        offset: params?.request.startRow,
        // cag: {
        //   domain_name: userCAG?.account ?? [],
        //   group: userCAG?.group ?? [],
        //   defaultClaimsAccess: userCAG?.defaultClaimsAccess ?? false,
        // }
      }
      const { api_path } = yield select(getApiPath, 'pharmacy-network-lookup')
      const url = `${serviceUrl}${api_path}`
      let res
      if (headers) {
        const { data, last_row } = yield call(fetchGet, url, reqData)
        let gridData = data
        gridData = data?.map((d) => ({
          ...filterRowData(d, headers, headerMapping)
        }))
        params?.success({
          rowData: gridData,
          rowCount: last_row,
        })
      }
    }
    // const qs = history.location.search ? `${history.location.search}&limit=${pharmaciesPerPage}` : ''
    // const requiredFields = ['doc_id', 'doc_version', 'limit', 'offset']
    // if (!requiredFields.some((field) => form[field])) {
    //   params?.success({
    //     rowData: [],
    //     rowCount: 0,
    //   })
    // } else {
    //   payload.form.offset = parseInt(payload.form.offset) + pharmaciesPerPage
    //   const { api_path } = yield select(getApiPath, 'pharmacy-network-lookup')
    //   const url = `${serviceUrl}${api_path}`
    //   const { data, total_rows } = yield call(fetchGet, url, f)
    //   const gridData = data[0].network_pricing

    //   yield put(NetworkCreationActions.setPharmacyNetworkLookupData({ data, totalRows: total_rows }))
    //   params?.success({
    //     rowData: gridData,
    //     rowCount: parseInt(total_rows, 10),
    //   })
    //   if (!data.length && total_rows < 1) {
    //     const transitionalPortal = {
    //       header: 'Pharmacy Network Tier Lookup Error',
    //       copy: 'Error - We are unable to find a pharmacy that matches the search criteria',
    //     }
    //     yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    //     // yield put(NetworkCreationActions.setPharmacyLookupFailed(true))
    //     return
    //   }
    //   if (getTotalRows) {
    //     yield put(NetworkCreationActions.setPharmacyNetworkLookupData({ data, totalRows: total_rows }))
    //   }
    // }
  } catch (err) {

    console.log('getPharmacyNetworkLookupDataHandler Error >Data ', err)

    const transitionalPortal = {
      header: 'Pharmacy Network Lookup Failed',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* getPharmacyNetworkTierLookupHandler({ payload }) {
  try {
    const {
      form, params, headers, headerMapping, getTotalRows
    } = payload

    let tempForm = { ...form }
    const { serviceUrl } = yield select(getAppState)
    const history = yield select(getHistory)
    const pharmaciesPerPage = 100
    const f = filterEmptyfromObj(tempForm)
    f.doc_name = encodeURIComponent(f.doc_name)
    history.location.search = createQueryString({
      ...f,
      offset: params?.request.startRow,
    })
    // const qs = history.location.search ? `${history.location.search}&limit=${pharmaciesPerPage}` : ''
    const requiredFields = ['network_tier']
    if (!requiredFields.some((field) => form[field])) {
      params?.success({
        rowData: [],
        rowCount: 0,
      })
    } else {
      const empty = yield select(isMenuEmpty)
      if (empty) {
        yield take([AppTypes.SET_APP_SETTINGS])
      }
      const { api_path } = yield select(getApiPath, 'pharmacy-network-tier-lookup')
      const url = `${serviceUrl}${api_path}`
      const reqData = {
        network_tier: f?.network_tier,
        sortModel: params?.request.sortModel,
        filterModel: params?.request.filterModel,
        limit: pharmaciesPerPage,
        offset: params?.request.startRow,
        pagination: true,
      }
      
      if (headers) {
        const { data, last_row } = yield call(fetchGet, url, reqData)
        let gridData = data
        gridData = data?.map((d) => ({
          ...filterRowData(d, headers, headerMapping)
        }))
        params?.success({
          rowData: gridData,
          rowCount: last_row,
        })
      } else{
        const response = yield call(fetchGet, url, reqData)
        const { data, last_row } = response
        let res
      }
    }
  } catch (err) {
    console.log('getPharmacyNetworkTierLookupDataHandler Error >Data ', err)

    const transitionalPortal = {
      header: 'Pharmacy Network Tier Lookup Failed',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* getAllNetworksDataHandler() {
  try {
    const empty = yield select(isMenuEmpty)
    if (empty) {
      yield take([AppTypes.SET_APP_SETTINGS])
    }
    const { serviceUrl } = yield select(getAppState)
    const cd = yield select(getApiPath, 'pharmacy-network')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`

    const { data } = yield call(fetchGet, url)
    if (!data.length) throw Error('No Networks found')
    yield put(NetworkCreationActions.setAllNetworksData({ data }))
  } catch (err) {
    console.log('getAllNetworkssDataHandler Error >Data ', err)

    const transitionalPortal = {
      header: 'Networks Lookup Failed',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* publishDocumentHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const cd = yield select(getApiPath, 'publish-document')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`

    const { data, message } = yield call(fetchPost, url, payload)
    if (!data.length) {
      const transitionalPortal = {
        header: 'Publish Document Failed',
        copy: message,
      }
      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
      return
    }
    const transitionalPortal = {
      header: 'Document Published',
      copy: 'Document Published Successfully',
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    yield put(NetworkCreationActions.setPublishDocumentData({ data }))
    if (payload.doc_type === 'global_pharmacy_network') yield put(NetworkCreationActions.getAllNetworksData())
    if (payload.doc_type === 'global_network_edit') yield put(NetworkCreationActions.getAllNetworkEditsData({ doc_type: 'global_network_edit' }))
    if (payload.doc_type === 'global_network_tier') yield put(NetworkCreationActions.getAllNetworkTiersData({ doc_type: 'global_network_tier' }))
  } catch (err) {
    console.log('publishDocumentHandler Error >Data ', err)

    const transitionalPortal = {
      header: 'Publish Document Failed',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* createNewVersionHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const cd = yield select(getApiPath, 'create-new-version')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`

    const { data, message } = yield call(fetchPost, url, payload)
    if (!data.length) {
      const transitionalPortal = {
        header: 'Create New Version Failed',
        copy: message,
      }
      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
      return
    }
    const transitionalPortal = {
      header: 'New Version Created',
      copy: 'New Version Created Successfully',
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    yield put(NetworkCreationActions.setNewVersionData({ data }))
    if (payload.doc_type === 'global_pharmacy_network') yield put(NetworkCreationActions.getAllNetworksData())
    if (payload.doc_type === 'global_network_edit') yield put(NetworkCreationActions.getAllNetworkEditsData({ doc_type: 'global_network_edit' }))
    if (payload.doc_type === 'global_network_tier') yield put(NetworkCreationActions.getAllNetworkTiersData({ doc_type: 'global_network_tier' }))
  } catch (err) {
    console.log('createNewVersionaHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Create New Version Failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* getAllNetworkTiersDataHandler({ payload }) {
  try {
    const empty = yield select(isMenuEmpty)
    if (empty) {
      yield take([AppTypes.SET_APP_SETTINGS])
    }
    const { serviceUrl } = yield select(getAppState)
    const cd = yield select(getApiPath, 'network-tier')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`

    const { data } = yield call(fetchGet, url, payload)
    if (!data.length) throw Error('No Network Tiers found')
    yield put(NetworkCreationActions.setAllNetworkTiersData({ data }))
  } catch (err) {
    console.log('getAllNetworkTiersDataHandler Error >Data ', err)

    const transitionalPortal = {
      header: 'Network Tier Lookup Failed',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* getNetworkTierDataHandler({ payload }) {
  try {
    const empty = yield select(isMenuEmpty)
    if (empty) {
      yield take([AppTypes.SET_APP_SETTINGS])
    }
    const { serviceUrl } = yield select(getAppState)
    const cd = yield select(getApiPath, 'network-tier')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`
    const { data } = yield call(fetchGet, url, payload)
    if (!data.length) throw Error('No Network Tier found')
    let parsedData = []
    const resData = { ...data[0]?.network_tier_info }

    if (resData.pricing_conditions?.length) {
      if (Array.isArray(resData.pricing_conditions[0])) {
        parsedData = resData.pricing_conditions.reduce((allConditions, condition) => {
          const parsedCondition = condition.map(parsePricingData)
          allConditions.push(parsedCondition)
          return allConditions;
        }, [])
      } else {
        parsedData = [resData.pricing_conditions.map(parsePricingData)]
      }
      resData.pricing_conditions = parsedData
    }
    yield put(NetworkCreationActions.setNetworkTierData(resData))
    yield put(NetworkCreationActions.setNetworkEditData(resData))
  } catch (err) {
    console.log('getNetworkTierDataHandler Error >Data ', err)

    const transitionalPortal = {
      header: 'Network Tier Lookup Failed',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* getAllNetworkEditsDataHandler({ payload }) {
  try {
    const empty = yield select(isMenuEmpty)
    if (empty) {
      yield take([AppTypes.SET_APP_SETTINGS])
    }
    const { serviceUrl } = yield select(getAppState)
    const cd = yield select(getApiPath, 'network-tier')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`
    const { data } = yield call(fetchGet, url, payload)
    if (!data.length) throw Error('No Network Edit found')
    yield put(NetworkCreationActions.setAllNetworkEditsData(data))
  } catch (err) {
    console.log('getAllNetworkEditsDataHandler Error >Data ', err)

    const transitionalPortal = {
      header: 'Network Tier Lookup Failed',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* getNetworkDataHandler({ payload }) {
  try {
    const empty = yield select(isMenuEmpty)
    if (empty) {
      yield take([AppTypes.SET_APP_SETTINGS])
    }
    const { serviceUrl } = yield select(getAppState)
    const cd = yield select(getApiPath, 'pharmacy-network')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`
    const { data } = yield call(fetchGet, url, payload)
    if (!data.length) throw Error('No Network  found')
    yield put(NetworkCreationActions.setNetworkData(data[0]))
  } catch (err) {
    console.log('getNetworkDataHandler Error >Data ', err)

    const transitionalPortal = {
      header: 'Network Lookup Failed',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* getChainCodeDataHandler() {
  try {
    const empty = yield select(isMenuEmpty)
    if (empty) {
      yield take([AppTypes.SET_APP_SETTINGS])
    }
    const { serviceUrl } = yield select(getAppState)
    const cd = yield select(getApiPath, 'get-chain-codes')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`
    const { data } = yield call(fetchGet, url)
    if (!data.length) throw Error('No Chain code')
    yield put(NetworkCreationActions.setChainCodeData(data[0]))
  } catch (err) {
    console.log('getChainCodeDataHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Network Lookup Failed',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}
function* getSpecialtyDrugDataHandler() {
  try {
    const empty = yield select(isMenuEmpty)
    if (empty) {
      yield take([AppTypes.SET_APP_SETTINGS])
    }
    const { serviceUrl } = yield select(getAppState)
    const cd = yield select(getApiPath, 'get-specialty-drug-list')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`
    const { data } = yield call(fetchGet, url, null, false)
    if (!data.length) throw Error('No Specialty Drug')
    const { data: drug } = data[0]
    yield put(NetworkCreationActions.setSpecialtyDrugData(drug))
  } catch (err) {
    console.log('getSpecialtyDrugDataHandler Error >Data ', err)

    const transitionalPortal = {
      header: 'Specialty Drug List Failed',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* generateNetworkTierHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const cd = yield select(getApiPath, 'network-tier')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`
    if (!payload.doc_name || !payload.effective_start_date) throw Error(!payload.doc_name ? 'Please input a Network Tier Name before saving' : 'Please select a start date before saving')
    yield call(fetchPut, url, payload)
    yield put(NavigationActions.navigateTo({ pathname: '/network-tier' }))
  } catch (err) {
    console.log('generateNetworkTierHandler Error >Data ', err)

    const transitionalPortal = {
      header: 'Network Tier Generate Failed',
      copy: err.message || err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* generateNetworkEditHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const cd = yield select(getApiPath, 'network-tier')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`
    if (!payload.doc_name || !payload.effective_start_date) throw Error(!payload.doc_name ? 'Please input a Network Edit Name before saving' : 'Please select a start date before saving')

    yield call(fetchPut, url, payload)
    yield put(NavigationActions.navigateTo({ pathname: '/network-edit' }))
  } catch (err) {
    console.log('generateNetworkEditHandler Error >Data ', err)

    const transitionalPortal = {
      header: 'Network Edit Generate Failed',
      copy: err.message || err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* generateNetworkHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const cd = yield select(getApiPath, 'pharmacy-network')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`
    if (!payload.doc_name || !payload.effective_start_date) throw Error(!payload.doc_name ? 'Please input a Network Name before saving' : 'Please select a start date before saving')

    yield call(fetchPut, url, payload)
    // if (data) throw Error('No Network for the input conditions')
    yield put(NavigationActions.navigateTo({ pathname: '/pharmacy-network' }))
  } catch (err) {
    console.log('generateNetworkHandler Error >Data ', err)

    const transitionalPortal = {
      header: 'Network Generate Failed',
      copy: err.message || err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* generateAssignedPharmacyHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const cd = yield select(getApiPath, 'generate-assign-pharmacy-network-edits')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`
    const newPayload = {
      tierConditions: payload.tierConditions.map((ele) => {
        return {
          pharmacyname: ele.pharmacy_name,
          chaincode: ele.chain_code,
          relationship_id: ele.relationship_id,
          relationship_name: ele.relationship_name,
          relationship_type: ele.relationship_type,
          corpname: ele.corp_name,
          pharmacynpi: ele.npi,
          pharmacystate: ele.state,
          pharmacyzip1: ele.zip_code,
          include_exclude: ele.include_exclude,
          effective_start_date: ele.effective_start_date,
          effective_end_date: ele.effective_end_date
        }
      })
    }
    const { data } = yield call(fetchPut, url, newPayload)
    if (!data.length) throw Error('No Network Tier found')
    yield put(NetworkCreationActions.setAssignedPharmacyData(data))
  } catch (err) {
    console.log('generateNetworkHandler Error >Data ', err)

    const transitionalPortal = {
      header: 'Network Generate Failed',
      copy: err.message || err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* getClaimProcessorNetworkDataHandler() {
  try {
    const { serviceUrl } = yield select(getAppState)
    const cd = yield select(getApiPath, 'claim-processor-network')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`

    const { data } = yield call(fetchGet, url, null, false)
    if (!data.length) throw Error('No Claim peocessor Network found')
    yield put(NetworkCreationActions.setClaimProcessorNetworkData(data))
  } catch (err) {
    console.error('Error in getClaimProcessorDataHandler', err)
    const transitionalPortal = {
      header: 'Saving Formulary Tier Failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}


function parsePricingData(pricingData) {
  const parsedPricingData = {
    ...pricingData, fee_name: [], fee_type: {},
    fee_value: {}, specialty_drug_list: []
  }
  if (parsedPricingData?.fees?.length) {
    parsedPricingData?.fees.map((feeData) => {
      parsedPricingData.fee_name.push(feeData.fee_name)
      parsedPricingData.fee_type = { ...parsedPricingData.fee_type, [feeData.fee_name]: feeData.fee_type }
      parsedPricingData.fee_value = { ...parsedPricingData.fee_value, [feeData.fee_name]: feeData.fee_value }
    })
  }
  if (parsedPricingData?.program_drug_list?.length) {
    parsedPricingData.specialty_drug_list = parsedPricingData?.program_drug_list
  }
  return parsedPricingData
}