//drug-utilization-review
import React, { useState, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import BreadCrumb from '../../../../components/breadCrumb'
import FliptButton from '../../../../components/form/fliptButton'
import FliptDatePicker from '../../../../components/form/fliptPaDatepicker'
import FliptDropdown from '../../../../components/form/fliptPaDropdown'
import { validate } from './data/validations'
import FliptInput from '../../../../components/form/fliptPaInput'
import { Creators as ContractActions } from '../../../../redux/reducers/api/contractManagement'
import { Creators as AppActions } from '../../../../redux/reducers/app'
import { Creators as GlobalEditsActions } from '../../../../redux/reducers/api/globalEdits'
import { parseQueryString, convertStrToDateObj, calcGridRowHeight, convertDateToStartTimeObj, convertDateToEndTimeObj } from '../../../../utils/utilities'
import Config from '../../../../config'
import _, { isEmpty, isEqual } from 'lodash'
import './styles.scss'
import moment from 'moment'
import FliptGrid from '../../../../components/fliptGrid'
import rowCellInfo from './data/rowCellInfo'
import { DropdownEditor, InputTextEditor } from '../../../../components/fliptGrid/cellEditors'
import { DeleteRowRenderer, MultiRowRenderer } from '../../../../components/fliptGrid/cellRenderers'
import states from '../../../../config/constants/states'
import FliptCheckbox from '../../../../components/form/fliptCheckbox'
import { cycleTypeOptions, daysOfWeek } from './data/dropdownData'
import { Icon } from 'semantic-ui-react'
import FliptHierarchyDropdown from '../../../../components/form/fliptHierarchyDropdown'

const customSourcePriceOptions = []

const newContract = (props) => {
    const InitialFormularyForm = {
        doc_id: '',
        effective_start_date: '',
        doc_name: '',
        tiers: [],
        module: 'FORMULARY',
        hierarchy: [],
        is_hierarchy_global: false,
        hierarchyIDs: {},
        hideHierarchy: false,
    }
    const initiateCycleState = () => {
        return ({
            type: '',
            start: '',
            start_date: '',
            end_date: '',
            startOptions: [{ key: '', text: '', value: '' }]
        })
    }
    const [data, setData] = useState({})
    const [formularyForm, setFormularyForm] = useState(InitialFormularyForm)
    const [cycleData, setCycleData] = useState([initiateCycleState()])
    const [mac_publishing_configuration, setMacConfig] = useState({ exclude_repackers: 'Y', exclude_clinic_packs: 'Y' })
    const [gridData, setGridData] = useState([{
        source_contract: '',
        include_exclude: '',
        program_drug_list: [],
        source_price_type: '',
        rate_type: '',
        adjustment_type: '',
        adjustment_rate: '',
        target_price_type: '',
        target_price: '',
        mac_list_id: ''
    }])

    const gridRef = React.createRef()
    const [options, setOptions] = useState({})
    const [step, setStep] = useState(0)
    const { history = {}, state = {} } = props
    const { location = {} } = history || {}
    const { editMode: edit = false, create = false } = location?.state || {}
    let editMode = edit && step !== 3

    const contactTypeOptions = ['CLIENT', 'PHARMACY', 'CALCULATED'].map(e => ({ key: e, text: e, value: e.toLowerCase() }))
    const bpgOptions = ['Y', 'N'].map(e => ({ key: e, text: e, value: e }))
    const incExcOptions = ['Include', 'Exclude'].map(e => ({ key: e, text: e, value: e }))
    const directOptions = ['Direct', 'Transfer'].map(e => ({ key: e, text: e, value: e }))

    const fliptContractOptions = bpgOptions
    const contractPriceOptions = (options?.contractPriceSource || []).map(e => ({ key: e, text: e, value: e }))
    const targetPriceOptions = ['MAC', 'New'].map(e => ({ key: e, text: e, value: e }))
    const sourcePriceOptions = ['AWP', 'NADAC'].map(e => ({ key: e, text: e, value: e }))
    const rateTypeOptions = ['POSITIVE', 'NEGATIVE'].map(e => ({ key: e, text: e, value: e }))

    const clawbackOptions = fliptContractOptions
    const adjustmentTypeOptions = ['FIXED', 'PERCENTAGE'].map(e => ({ key: e, text: e, value: e }))
    const adjustmentRateOptions = (options?.program || []).map(e => ({ key: e, text: e, value: e }))
    const onChange = (el, dropdown) => {
        const { name, value, checked } = dropdown || el.currentTarget
        if (name.includes('_date')) {
            setData({ ...data, [name]: moment(value).toISOString() })
            return
        }
        setData({ ...data, [name]: value })
    }

    const onContactChange = (el, dropdown) => {
        const { name, value, checked } = dropdown || el.currentTarget
        setData({
            ...data, contact: {
                ...data.contact,
                [name]: value
            }
        }
        )
    }

    const processorOptions = (state.contractData?.claim_processors || []).map(e => ({ key: e, text: e, value: e }))

    const getCycleStartOptions = (cycleType) => {
        let cycleOptions = []
        switch (cycleType) {
            case cycleTypeOptions[0].value: {
                for (let i = 1; i <= 30; i++) {
                    cycleOptions.push({
                        key: i,
                        text: i,
                        value: i
                    })
                }
            }
                break;
            case cycleTypeOptions[1].value: {
                cycleOptions = daysOfWeek.map((days, index) =>
                ({
                    key: index,
                    text: days,
                    value: days
                })
                )
            }
                break;
            case cycleTypeOptions[2].value: {
                for (let i = 1; i <= 16; i++) {
                    cycleOptions.push({
                        key: i,
                        text: i,
                        value: i
                    })
                }
            }
                break;
            case cycleTypeOptions[3].value: {
                cycleOptions = daysOfWeek.map((days, index) =>
                ({
                    key: index,
                    text: days,
                    value: days
                })
                )
            }
                break;
            case cycleTypeOptions[4].value: {
                cycleOptions = [{ key: '', text: '', value: '' }]
            }
                break;
        }
        return cycleOptions
    }

    const next = (customStep) => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        setStep(customStep >= 0 ? customStep : step + 1)
    }

    const addCondition = () => {
        const tempGridData = [...gridData]
        const obj = {
            source_contract: '',
            include_exclude: '',
            program_drug_list: [],
            source_price_type: '',
            adjustment_type: '',
            adjustment_rate: '',
            target_price_type: '',
            mac_list_id: '',
            target_price: '',
            rate_type: ''
        }
        tempGridData.push(obj)
        customSourcePriceOptions.push({ source_price_type: [] })
        setGridData(tempGridData)
        gridRef.current.api.refreshCells()
    }

    const deleteCondition = (rowIndex) => {
        const tempGridData = [...gridData]
        tempGridData.splice(rowIndex, 1)
        customSourcePriceOptions.splice(rowIndex, 1)
        setGridData(tempGridData)
        gridRef.current.api.refreshCells()
    }

    const back = (el) => {
        setStep(step - 1)
    }

    const validations = (payload) => {
        const { actions } = props
        const error = validate(payload)
        if (error) {
            const transitionalPortal = {
                header: 'Validation Error',
                copy: error
            }
            actions.displayTransitionalPortal(transitionalPortal)
            return false
        }
        return true
    }

    const submitForm = () => {
        const { actions, state: { user } } = props
        const newGridData = gridData.map((data) => ({
            ...data, target_price_type: data?.target_price_type === 'New' ?
                data?.target_price : data?.target_price_type
        }))
        const remittance_cycle_data = cycleData.map((cyc) => {
            const obj = { ...cyc }
            delete obj.startOptions
            return obj
        })
        const payload = {
            ...data,
            mac_publishing_configuration: mac_publishing_configuration,
            contract_start_date: data.contract_start_date ? moment(data.contract_start_date).startOf('day') : '',
            contract_end_date: data.contract_end_date ? moment(data.contract_end_date).startOf('day') : '',
            type: 'global_claim_processor',
            updated_by: user?.uuid,
            contact_criteria: newGridData,
            hierarchy: formularyForm.hierarchy,
            is_hierarchy_global: formularyForm.is_hierarchy_global,
            hierarchyIDs: formularyForm.hierarchyIDs,
            remittance_cycle_data
        }
        payload.direct_contract = payload.direct_contract === 'Direct' ? 'Y' : 'N'
        if (validations(payload)) {
            actions?.putContractData({ ...payload })
        }

    }
    const onCycleChange = (el, dropdown, index) => {
        const { name, value } = dropdown || el.currentTarget
        const tempCycleData = [...cycleData]
        if (name === 'type') {
            tempCycleData[index].startOptions = getCycleStartOptions(value)
        }
        if (name === 'start_date') tempCycleData[index][name] = convertDateToStartTimeObj(value)
        else if (name === 'end_date') tempCycleData[index][name] = convertDateToEndTimeObj(value)
        else tempCycleData[index][name] = value
        setCycleData(tempCycleData)
    }

    const deleteRow = (rowIndex) => {
        const tempCycleData = [...cycleData]
        tempCycleData.splice(rowIndex, 1)
        setCycleData(tempCycleData)
    }

    const handleChange = (el, dropdown, rowIndex, gridApi) => {
        const { name, value } = dropdown || el.currentTarget
        gridData[rowIndex][name] = value
        if (name === 'target_price_type') gridData[rowIndex].target_price = ''
        if (name === 'source_contract') {
            gridData[rowIndex].source_price_type = ''
            if (!value) {
                customSourcePriceOptions[rowIndex].source_price_type = []
            } else if (value === 'New') {
                customSourcePriceOptions[rowIndex].source_price_type = sourcePriceOptions
            } else {
                const { source_contracts = {} } = props.state.contractData
                const selected_source_contract = gridData[rowIndex][name]
                const priceOptionsList = source_contracts?.[selected_source_contract]?.['source_price_types'] || []
                const priceOptions = priceOptionsList.map(e => ({ key: e, text: e, value: e }))
                customSourcePriceOptions[rowIndex].source_price_type = priceOptions
            }
        }
        setGridData(gridData)
        gridApi.refreshCells()
    }
    const displayDiscount = (param) => {
        const { data } = param
        const prefix = data?.rate_type === 'POSITIVE' ? '+' : '-'
        const postfix = data?.adjustment_type === 'PERCENTAGE' ? '%' : ''
        return `${prefix}${param.value}${postfix}`
    }
    useEffect(() => {
        const { model_data, source_contracts = {}, program } = props.state.contractData
        model_data?.mac_publishing_configuration && setMacConfig(model_data?.mac_publishing_configuration)
        if (model_data?.hierarchy?.length) {
            const tempFormularyForm = { ...formularyForm }
            tempFormularyForm.hierarchy = model_data?.hierarchy
            tempFormularyForm.hierarchyIDs = model_data?.hierarchyIDs
            tempFormularyForm.is_hierarchy_global = model_data?.is_hierarchy_global
            setFormularyForm(tempFormularyForm)
        }
        const newGridData = model_data?.contact_criteria?.map((data) => ({
            ...data, target_price_type: data?.target_price_type === 'MAC' ?
                'MAC' : 'New'
        }))
        // set source price options
        customSourcePriceOptions.splice(0, customSourcePriceOptions.length)
        newGridData && newGridData.forEach((contractRow) => {
            const currentContract = contractRow['source_contract']
            const priceOptionsList = source_contracts?.[currentContract]?.['source_price_types'] || []
            const priceOptions = priceOptionsList.map(e => ({ key: e, text: e, value: e }))
            customSourcePriceOptions.push({ source_price_type: priceOptions })
        })

        if (model_data?.remittance_cycle_data?.length) {
            const tempCycleData = model_data?.remittance_cycle_data?.map((cyc) => {
                return ({ ...cyc, startOptions: getCycleStartOptions(cyc.type) })
            })
            setCycleData(tempCycleData)
        }
        setGridData(newGridData || [])
        const dat = { ...model_data }
        if (!isEqual(dat, data)) {
            setData({ ...data, ...dat, direct_contract: model_data?.direct_contract === 'Y' ? 'Direct' : 'Transfer' })
        }
        if (isEmpty(options) && Object.keys(source_contracts).length || !isEmpty(program)) {
            const source_contracts_names = Object.keys(source_contracts)
            setOptions({ contractPriceSource: source_contracts_names, program })
        }
    }, [props?.state?.contractData])

    useEffect(() => {
        const { actions } = props
        const { search } = location
        let qs = { 'doc_id': 'create' }
        if (search)
            qs = parseQueryString(search)
        setTimeout(() => actions.getContractData(qs), Config.COMPONENT_DATA_LOAD_TIME)
        return (() => actions.clearContractData())
    }, [])

    useEffect(() => {
        const { actions } = props
        actions.getSectionUI({ section: 'global_pricing' })
    }, [])

    const addCycle = () => {
        const tempCycleData = [...cycleData, initiateCycleState()]
        setCycleData(tempCycleData)
    }
    const _getRowHeight = (params) => calcGridRowHeight(params.data?.program_drug_list)

    const headers = ['source_contract', 'include_exclude', 'program_drug_list', 'source_price_type', 'adjustment_type', 'rate_type',
        'adjustment_rate', 'target_price_type', 'target_price', 'mac_list_id', 'action']
    const cellEditorParams = {
        source_contract: {
            editable: true,
            cellEditor: DropdownEditor,
            onChange: handleChange,
            readOnly: !editMode,
        },
        include_exclude: {
            editable: true,
            cellEditor: DropdownEditor,
            onChange: handleChange,
            readOnly: !editMode,
        },
        program_drug_list: {
            editable: true,
            cellEditor: DropdownEditor,
            onChange: handleChange,
            readOnly: !editMode,
        },
        source_price_type: {
            editable: true,
            cellEditor: DropdownEditor,
            onChange: handleChange,
            readOnly: !editMode,
        },
        adjustment_type: {
            editable: true,
            cellEditor: DropdownEditor,
            onChange: handleChange,
            readOnly: !editMode,
        },
        rate_type: {
            editable: true,
            cellEditor: DropdownEditor,
            onChange: handleChange,
            readOnly: !editMode,
        },
        adjustment_rate: {
            editable: true,
            validation: 'numeric',
            cellEditor: InputTextEditor,
            disabled: !editMode,
            onChange: handleChange,
        },
        target_price_type: {
            editable: true,
            cellEditor: DropdownEditor,
            onChange: handleChange,
            readOnly: !editMode,
        },
        mac_list_id: {
            editable: true,
            cellEditor: InputTextEditor,
            disabled: !editMode,
            onChange: handleChange,
        },
        target_price: {
            editable: true,
            cellEditor: InputTextEditor,
            disabled: !editMode,
            onChange: handleChange,
            isDisabled: function (data) {
                const { target_price_type } = data
                return target_price_type !== 'New'
            },
        },
    }
    const cellRendererParams = {
        action: {
            cellRenderer: DeleteRowRenderer,
            state: {
                onClick: deleteCondition,
            },
            width: 95,
        },
        source_contract: {
            overrideHeader: 'Contract List'
        },
        include_exclude: {
            overrideHeader: 'Include/Exclude'
        },
        program_drug_list: {
            cellRenderer: MultiRowRenderer,
            overrideHeader: 'Drug List'
        },
        source_price_type: {
            overrideHeader: 'Source Price Type'
        },
        adjustment_type: {
            overrideHeader: 'Discount Type'
        },
        adjustment_rate: {
            overrideHeader: 'Discount',
            valueFormatter: (params) => {
                return displayDiscount(params)
            }
        },
        mac_list_id: {
            overrideHeader: 'MAC List ID'
        },
    }

    rowCellInfo.source_contract.options = [{ key: 'New', text: 'New', value: 'New' }, ...contractPriceOptions]
    rowCellInfo.include_exclude.options = incExcOptions
    rowCellInfo.program_drug_list.options = adjustmentRateOptions
    rowCellInfo.adjustment_type.options = adjustmentTypeOptions
    rowCellInfo.target_price_type.options = targetPriceOptions
    rowCellInfo.rate_type.options = rateTypeOptions
    const statesOptions = Object.entries(states).map(([k, v]) => ({
        key: k,
        text: `${k} - ${v}`,
        value: k,
    }))
    return <div id="contractManagement">
        <BreadCrumb {...props} />
        <div className="header">
            <div className='container'>
                <ul className="progressbar">
                    <li onClick={() => next(0)} className={step >= 0 ? `active ${(step > 0) ? ' completed' : ''}` : ""}>Contract Details</li>
                    <li onClick={() => next(1)} className={step >= 1 ? `active ${(step > 1) ? ' completed' : ''}` : ""}>Remittance Settings</li>
                    <li onClick={() => next(2)} className={step >= 2 ? `active ${(step > 2) ? ' completed' : ''}` : ""}>Contact</li>
                    <li onClick={() => next(3)} className={step >= 3 ? "active" : ""}>Review</li>
                </ul>
            </div>
        </div>
        <div className="content maxHeight">
            {step === 0 || step === 3 ?
                <div className='card' >
                    <h3 className="header1">
                        Contract Details
                        {step === 3 && editMode ? <FliptButton className='primary' name='Edit' onClick={() => next(0)} /> : null}
                    </h3>
                    <FliptHierarchyDropdown form={formularyForm} setForm={setFormularyForm} showHierarchyGlobal={true} />
                    <div className='outer-container'>
                        <div className='field-inner-container'>
                            <FliptInput value={data?.doc_name || ''} label='Contract Name' name='doc_name' disabled={!editMode} onChange={onChange} placeholder='' stylized />
                            <FliptDropdown readOnly={!editMode} placeholder="" label="Clawback" name="clawback" value={data?.clawback || ''} options={clawbackOptions} onChange={onChange} stylized />
                        </div>
                        <div className='field-inner-container'>
                            <FliptDatePicker value={data?.contract_start_date ? moment(data?.contract_start_date).toDate() : ''} labelText='Contract Start Date' name='contract_start_date' onChange={onChange} disabled={!editMode} placeholder='' stylized />
                            <FliptDropdown readOnly={!editMode} placeholder="" label="Direct Transfer" name="direct_contract" value={data?.direct_contract || ''} options={directOptions} onChange={onChange} stylized />
                        </div>
                        <div className='field-inner-container'>
                            <FliptDatePicker value={data?.contract_end_date ? moment(data?.contract_end_date).toDate() : ''} labelText='Contract End Date' name='contract_end_date' onChange={onChange} disabled={!editMode} placeholder='' stylized />
                            <FliptDropdown readOnly={!editMode} placeholder="" label="Claim Processors" name="processor_name" value={data.processor_name} options={processorOptions} onChange={onChange} stylized />
                        </div>
                        <div className='field-inner-container'>
                            <FliptDropdown multiple={true} readOnly={!editMode} placeholder="" label="Contract Type" name="contract_type" value={data?.contract_type || ''} options={contactTypeOptions} onChange={onChange} stylized />
                        </div>
                    </div>
                    <div className='checkbox-container'>
                        <FliptCheckbox checked={mac_publishing_configuration?.exclude_repackers === 'Y'}
                            onChange={() => setMacConfig({
                                exclude_repackers: mac_publishing_configuration.exclude_repackers === 'Y' ? 'N' : 'Y',
                                exclude_clinic_packs: mac_publishing_configuration.exclude_clinic_packs
                            })} />
                        <span>Exclude Repackaged Products from MAC Publishing</span>
                    </div>
                    <div className='checkbox-container'>
                        <FliptCheckbox checked={mac_publishing_configuration?.exclude_clinic_packs === 'Y'}
                            onChange={() => setMacConfig({
                                exclude_clinic_packs: mac_publishing_configuration.exclude_clinic_packs === 'Y' ? 'N' : 'Y',
                                exclude_repackers: mac_publishing_configuration?.exclude_repackers
                            })} />
                        <span>Exclude Clinic Packaged Products from MAC Publishing</span>
                    </div>
                    <div className='devider' />
                    <h3 className="header1">
                        Contract Criteria
                    </h3>
                    <div className='grid-container'>
                        <FliptGrid
                            headers={headers}
                            data={gridData}
                            getRowHeight={_getRowHeight}
                            cellEditorParams={cellEditorParams}
                            rowCellInfo={rowCellInfo}
                            cellRendererParams={cellRendererParams}
                            agGridRef={gridRef}
                            dropdownOptions={customSourcePriceOptions}
                        />
                    </div>
                    <div className='button-container'>
                        <FliptButton className="primary" disabled={(step >= 3) || !editMode} compact name='Add Condition' onClick={addCondition} />
                    </div>
                </div> : ''}
            {step === 1 || step === 3 ?
                <div className='card' >
                    <h3 className="header1">
                        Remittance Settings
                        {step === 3 && editMode ? <FliptButton className='primary' name='Edit' onClick={() => next(1)} /> : null}
                    </h3>
                    <div className='devider' />
                    {data?.contract_type?.includes('pharmacy') ? <>
                        <div className='outer-cycle-container'>
                            {
                                cycleData?.map((cycle, index) => {
                                    return (
                                        <div className='inner-cycle-container'>
                                            <div className='field-inner-container'>
                                                <FliptDropdown options={cycleTypeOptions} value={cycle?.type || ''} label='Type' name='type' readOnly={!editMode} placeholder='' onChange={(el, dropdown) => onCycleChange(el, dropdown, index)} stylized />
                                            </div>
                                            <div className='field-inner-container'>
                                                <FliptDropdown readOnly={cycle?.type === cycleTypeOptions[4].value || !editMode} options={cycle.startOptions} value={cycle?.start || ''} label='Start' name='start' placeholder='' onChange={(el, dropdown) => onCycleChange(el, dropdown, index)} stylized />
                                            </div>
                                            <div className='field-inner-container'>
                                                <FliptDatePicker minDate={cycleData[index - 1]?.end_date || ''} value={cycle?.start_date ? moment(cycle?.start_date).toDate() : ''} labelText='Start Date' name='start_date'
                                                    disabled={!editMode} placeholder='' onChange={(el, dropdown) => onCycleChange(el, dropdown, index)} stylized />
                                            </div>
                                            <div className='field-inner-container'>
                                                <FliptDatePicker minDate={cycle?.start_date || ''} value={cycle?.end_date ? moment(cycle?.end_date).toDate() : ''} labelText='End Date' name='end_date'
                                                    disabled={!editMode} placeholder='' onChange={(el, dropdown) => onCycleChange(el, dropdown, index)} stylized />
                                            </div>
                                            <Icon name='delete' onClick={() => deleteRow(index)}></Icon>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className='button-container'>
                            <FliptButton className='primary' disabled={!editMode} name='Add Cycle' onClick={() => addCycle()} />
                        </div>
                    </> : null}

                </div> : ''}
            {step === 2 || step === 3 ?
                <div className='card' >
                    <h3 className="header1">
                        Contact
                        {step === 3 && editMode ? <FliptButton className='primary' name='Edit' onClick={() => next(2)} /> : null}
                    </h3>
                    <div className='devider' />
                    <div className='outer-container'>
                        <div className='field-inner-container'>
                            <FliptInput value={data?.contact?.contact_person || ''} label='Contact' name='contact_person' disabled={!editMode} placeholder='' onChange={onContactChange} stylized />
                            <FliptInput inputClass='input-class-med' value={data?.contact?.address1 || ''} label='Address 1' name='address1' disabled={!editMode} placeholder='' onChange={onContactChange} stylized />
                            <FliptInput value={data?.contact?.zip || ''} label='Zip Code' name='zip' disabled={!editMode} placeholder='' onChange={onContactChange} stylized />
                        </div>
                        <div className='field-inner-container'>
                            <FliptInput label="Email" name="email" value={data?.contact?.email || ''} disabled={!editMode} placeholder='' onChange={onContactChange} stylized />
                            <FliptInput inputClass='input-class-med' value={data?.contact?.address2 || ''} label='Address 2' name='address2' disabled={!editMode} placeholder='' onChange={onContactChange} stylized />
                        </div>
                        <div className='field-inner-container'>
                            <FliptInput label="Phone" name="phone" value={data?.contact?.phone || ''} disabled={!editMode} placeholder='' onChange={onContactChange} stylized />
                            <FliptInput value={data?.contact?.city || ''} label='City' name='city' disabled={!editMode} placeholder='' onChange={onContactChange} stylized />
                        </div>
                        <div className='field-inner-container'>
                            <FliptInput label="Fax" name="fax" value={data?.contact?.fax || ''} disabled={!editMode} placeholder='' onChange={onContactChange} stylized />
                            <FliptDropdown options={statesOptions} value={data?.contact?.state || ''} label='State' name='state' disabled={!editMode} placeholder='' onChange={onContactChange} stylized />
                        </div>
                    </div>
                </div> : ''}
            <div id="navButton">
                <div className='back'>
                    <FliptButton className="closeButton" disabled={(step === 0)} compact name="Back" onClick={back} />
                </div>
                <div className='next'>
                    <FliptButton className="closeButton" disabled={(step >= 3)} compact name={step >= 2 ? "Preview" : "Next"} onClick={next} />
                </div>

            </div>
            {
                (edit || create) ? (<div className="close-button">
                    <FliptButton className="primary" compact name="Save" onClick={submitForm} />
                </div>) : ''
            }

        </div>

    </div >
}

const mapStateToProps = (state) => {

    return {
        state: {
            // app: state.app,
            globalEdits: state.globalEdits.section,
            contractData: state.contractManagement.contractData,
            user: state.user,
        },
    }
}

const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...AppActions,
        ...ContractActions,
        ...GlobalEditsActions,
    }

    return {
        actions: bindActionCreators(allActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(newContract)
