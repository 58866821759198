import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment-timezone'

import './styles.scss'
import { Creators as AppActions } from '../../../../redux/reducers/app'
import { Creators as PriorAuthorizationCreationActions } from '../../../../redux/reducers/api/priorAuthorizationManagement'

import FliptButton from '../../../../components/v2/fliptButton'
import FliptDrugSearch from '../../../../components/fliptDrugSearch'
// import FliptPharmacySearch from '../../../../components/fliptPharmacySearch'
import FliptPrescriberSearch from '../../../../components/v2/fliptPrescriberSearchv2'
import FliptInput from '../../../../components/v2/fliptInput'
import QuestionnaireModal from '../QuestionnaireModal'
import { formatPhoneNumber } from '../../../../utils/utilities'
import { Icon } from 'semantic-ui-react'
import CloseModal from '../CloseModal'
import DiagnosisSearch from '../DiagnosisSearch'
import * as Constants from '../constants'
import DuplicateModal from '../DuplicateModal'

class PACaseTab extends Component {
	constructor(props) {
		super(props)
		this.state = {
			searchForm: {
				date_of_birth: '',
				first_name: '',
				last_name: '',
				drug_name: '',
				dosage_strength: '',
				gpi: '',
				memberId: '',
				ndc: '',
				pharmacyNpi: '',
				pharmacyState: '',
				pharmacyCity: '',
				pharmacyName: '',
				days_supply: '',
				quantity: '',
			},
			editMode: false,
			planForm: {},
			drugData: null,
			prescriberData: null,
			isPrescriberDataDirty: false,
			pharmacyData: null,
			diagnosisData: null,
			requestStatus: '',
			displayAddNote: false,
			diagnosticSaved: false,
			drugSaved: false,
			noteData: {
				noteDescription: '',
			},
			voidModalOpen: false,
			lockCase: false,
			isDrugDataDirty: false,
			isDiagnosisDataDirty: false,
			isCaseInfoEditable: true,
		}
	}

	componentDidMount() {
		const {
			state: { paCaseDetails },
		} = this.props
		if (paCaseDetails?.pa_case_id) {
			this._setPaData(false)
		}
	}

	componentDidUpdate(prevProps) {
		const { props } = this
		const { planForm, diagnosticSaved, drugSaved } = this.state
		const { saveNotesdata, paCaseDetails, saveAnswerData, createPaCaseData, getAnswerData, updatePaCaseData } =
			props.state
		const { patient_benefit, patient } = paCaseDetails || {}
		const memberDetails = { ...patient_benefit, ...patient }

		if (prevProps.state?.createPaCaseData !== createPaCaseData && createPaCaseData) {
			props.actions.getPaCaseDetails({
				pa_case_id: createPaCaseData.pa_case_id,
			})
		}

		if (prevProps.state?.paCaseDetails !== paCaseDetails && paCaseDetails) {
			this._setPaData(true)
		}

		if (prevProps.state?.updatePaCaseData !== updatePaCaseData && updatePaCaseData?.pa_case_id && diagnosticSaved) {
			this.setState({ diagnosticSaved: false })
			window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
		}
		if (prevProps.state?.updatePaCaseData !== updatePaCaseData && drugSaved) {
			if (updatePaCaseData?.duplicate === 'Y') {
				this._openDuplicateModal()
			}
			this.setState({ drugSaved: false })
		}

		if (prevProps.state.saveNotesdata !== saveNotesdata && saveNotesdata) {
			this.setState({
				noteData: {
					noteDescription: '',
				},
				displayAddNote: false,
			})
		}
		if (prevProps.state.saveAnswerData !== saveAnswerData && saveAnswerData) {
			props.actions.getPaCaseDetails({
				pa_case_id: paCaseDetails.pa_case_id,
			})
		}

		if (prevProps.state.getAnswerData !== getAnswerData && getAnswerData) {
			if (getAnswerData?.questionnaire_ans?.questionnaire?.length) {
				if (paCaseDetails.intake_type === Constants.SURESCRIPTS_STR)
					props.actions.getPaCaseDetails({
						pa_case_id: paCaseDetails.pa_case_id,
					})
				props.actions.setModalComponent({
					modalProperties: { size: 'large' },
					contents: (
						<QuestionnaireModal
							planForm={planForm}
							quesData={getAnswerData.questionnaire_ans}
							memberDetails={memberDetails}
							preview
							pa_case_id={paCaseDetails.pa_case_id}
						/>
					),
				})
				props.actions.toggleModal()
			}
		}
	}

	formatDate = (dateStr) => {
		if (!dateStr) return ''
		return moment(dateStr).format('MM/DD/YYYY')
	}

	_openDuplicateModal = () => {
		const { actions } = this.props
		const { planForm, lockCase } = this.state
		actions.setModalComponent({
			modalProperties: { size: 'large', noShow: true },
			contents: (
				<DuplicateModal _openStatusModal={() => this._openStatusModal(Constants.VOID_CLOSE_STR, planForm, lockCase)} />
			),
		})
		actions.toggleModal()
	}

	checkEditable = (paCaseDetails) => {
		const { pa_case_status, intake_type, audit_trail } = paCaseDetails
		if (intake_type === Constants.SURESCRIPTS_STR) return false
		if ([Constants.NEW_STR, Constants.IN_PROCESS_STR, Constants.HOLD_PENDING_STR].includes(pa_case_status)) {
			return audit_trail.every((statusLog) => statusLog.audit_trail_status !== Constants.RFI_STR)
		}
		return false
	}

	_setPaData = (openModal) => {
		const {
			props,
			state: { voidModalOpen, searchForm },
		} = this
		const { paCaseDetails, user } = props.state
		let planForm = { ...paCaseDetails }

		const requestStatus = planForm.pa_case_status
		planForm.pa_case_status = null
		const memberData = {
			...paCaseDetails?.patient_benefit,
			...paCaseDetails?.patient,
		}

		let lockCase = false
		if (paCaseDetails?.review_technician && paCaseDetails.review_technician !== user.uuid) lockCase = true

		let isCaseInfoEditable = this.checkEditable(paCaseDetails)

		if (
			paCaseDetails?.member_information_validated &&
			!paCaseDetails?.patient_benefit?.plan_name &&
			paCaseDetails.pa_case_status !== Constants.VOID_CLOSE_STR &&
			!voidModalOpen
		) {
			planForm.pa_case_status = Constants.VOID_CLOSE_STR
			this.setState({ voidModalOpen: true })
			if (openModal) this._openStatusModal(Constants.VOID_CLOSE_STR, planForm, lockCase)
		} else if (
			paCaseDetails?.member_information_validated &&
			paCaseDetails.pa_case_status !== Constants.VOID_CLOSE_STR &&
			!voidModalOpen
		) {
			planForm.pa_case_status = Constants.VOID_CLOSE_STR
			this.setState({ voidModalOpen: true })
			if (
				openModal &&
				!this.checkIfMemberValidated({
					...paCaseDetails?.patient_benefit,
					...paCaseDetails?.patient,
				})
			) {
				this._openStatusModal(Constants.VOID_CLOSE_STR, planForm, lockCase)
			}
		}
		searchForm.days_supply = paCaseDetails?.medication?.days_supply || ''
		searchForm.quantity = paCaseDetails?.medication?.quantity || ''
		this.setState({
			planForm,
			requestStatus,
			drugData: paCaseDetails?.medication,
			memberData,
			pharmacyData: paCaseDetails?.pharmacy,
			prescriberData: paCaseDetails?.prescriber,
			lockCase,
			diagnosisData: paCaseDetails?.diagnosis?.length ? paCaseDetails.diagnosis[0] : {},
			searchForm,
			isPrescriberDataDirty: false,
			memberSearched: false,
			isDrugDataDirty: false,
			isDiagnosisDataDirty: false,
			isCaseInfoEditable,
		})
	}

	_openStatusModal = (status, planForm, lockCase) => {
		const {
			actions,
			state: { app },
		} = this.props
		if (status === Constants.VOID_CLOSE_STR) {
			actions.setSideDrawerComponent({
				modalProperties: { size: 'large' },
				contents: (
					<CloseModal
						planForm={planForm}
						lockCase={lockCase}
						selectedStatus={status}
						pa_case_id={planForm.pa_case_id}
					/>
				),
			})
			actions.toggleSideDrawer()
		}
	}

	_showTransitionalPortal = (header, message) => {
		const { props } = this
		const transitionalPortal = {
			header: header,
			copy: message,
		}
		props.actions.displayTransitionalPortal(transitionalPortal)
	}

	_updatePaCase = () => {
		const { drugData, pharmacyData, searchForm, planForm, requestStatus } = this.state
		const {
			actions,
			state: { user },
		} = this.props
		if (drugData && !searchForm.days_supply && !searchForm.quantity) {
			const header = 'Fields Missing'
			const message = 'Please Add Quantity And Days Supply to Save Drug'
			this._showTransitionalPortal(header, message)
			return
		}
		const params = {
			...planForm,
			request_type: 'main_details',
			pa_case_id: planForm.pa_case_id,
			pa_case_status: requestStatus,
			verification_section: 'drug',
			first_name: user.first_name,
			last_name: user.last_name,
			medication: drugData?.drug_name
				? {
					drug_name: drugData.drug_name,
					dosage: drugData.dosage,
					dosage_strength: drugData.strengths || drugData.dosage_strength,
					gpi: drugData.gpi,
					quantity: searchForm.quantity,
					days_supply: searchForm.days_supply,
					ndc: drugData.ndc,
					dosing_instructions: '',
				}
				: planForm?.medication
					? planForm?.medication
					: {},
			pharmacy: planForm?.pharmacy
				? planForm.pharmacy
				: pharmacyData?.npi
					? {
						NCPDP: '',
						npi: pharmacyData?.npi,
						pharmacy_name: pharmacyData?.pharmacy_name,
						phone_number: pharmacyData?.phone_number,
						fax_number: '',
					}
					: null,
		}
		actions.updatePaCase(params)
		this.setState({ drugSaved: true })
	}
	_updateDiagnosis = () => {
		const { diagnosisData, planForm, requestStatus } = this.state
		const { state } = this.props
		const params = {
			diagnosis: [diagnosisData],
			pa_case_id: planForm.pa_case_id,
			pa_case_status: requestStatus,
			first_name: state?.user?.first_name || '',
			last_name: state?.user?.last_name || '',
			review_technician: state.user.uuid,
			verification_section: 'diagnosis',
		}
		this.props.actions.updatePaCase(params)
		this.setState({ diagnosticSaved: true })
	}

	updateDrugData = (data) => {
		const { updateDrugMsg } = this.props
		const { searchForm } = this.state
		updateDrugMsg()
		searchForm.days_supply = ''
		searchForm.quantity = ''
		this.setState({ drugData: data, searchForm, isDrugDataDirty: true })
	}

	updateDiagnosisData = (data) => {
		this.setState({ diagnosisData: data, isDiagnosisDataDirty: true })
	}


	updateDaySupply = (el) => {
		const { value } = el.currentTarget
		const { searchForm } = this.state
		searchForm.days_supply = value
		this.setState({ searchForm })
	}

	updateQuantity = (el) => {
		const { searchForm } = this.state
		const { value } = el.currentTarget
		searchForm.quantity = value
		this.setState({ searchForm })
	}

	// updatePharmacyData = (pharmacyData) => {
	// 	this.setState({
	// 		pharmacyData: {
	// 			npi: pharmacyData?.pharmacynpi,
	// 			pharmacy_name: pharmacyData?.pharmacyname,
	// 			phone_number: pharmacyData?.pharmacyphone,
	// 		},
	// 	})
	// }

	updatePrescriberData = (data) => {
		let prescriberData = data
		if (data?.npi) {
			prescriberData = { ...data, npi: data.npi }
		}
		this.setState({
			prescriberData: prescriberData,
			isPrescriberDataDirty: true,
		})
	}
	_updatePrescriberFields = (e) => {
		this.setState({
			prescriberData: {
				...this.state.prescriberData,
				[e.target.name]: e.target.value,
			},
		})
	}

	_findDrug = () => {
		const { actions } = this.props
		const { searchForm } = this.state
		actions.setModalComponent({
			modalProperties: { size: 'large' },
			contents: (
				<FliptDrugSearch editMode searchForm={searchForm} updateDrugData={(data) => this.updateDrugData(data)} />
			),
		})
		actions.toggleModal()
	}

	_findDiagnosis = () => {
		const { actions } = this.props
		actions.setModalComponent({
			modalProperties: { size: 'large' },
			contents: <DiagnosisSearch updateDiagnosisData={(data) => this.updateDiagnosisData(data)} />,
		})
		actions.toggleModal()
	}

	// _findPharmacy = () => {
	// 	const { actions } = this.props
	// 	const { searchForm } = this.state
	// 	actions.setModalComponent({
	// 		modalProperties: { size: 'large' },
	// 		contents: (
	// 			<FliptPharmacySearch
	// 				updatePharmacyData={(data) => this.updatePharmacyData(data)}
	// 				searchForm={searchForm}
	// 				editMode
	// 			/>
	// 		),
	// 	})
	// 	actions.toggleModal()
	// }

	_findPrescriber = () => {
		const {
			actions,
			state: { paCaseDetails },
		} = this.props
		const { searchForm, prescriberData } = this.state
		const isIntent = paCaseDetails.intake_type === 'INTENT' || paCaseDetails.intake_type === 'CLAIM REJECT'
		if (isIntent) {
			searchForm.prescriberNpi = prescriberData.npi
		}
		actions.setModalComponent({
			modalProperties: { size: 'large' },
			contents: (
				<FliptPrescriberSearch
					submitPrescriberData={(data) => this.updatePrescriberData(data)}
					searchForm={searchForm}
				/>
			),
		})
		actions.toggleModal()
	}

	_createRequest = () => {
		const { prescriberData, planForm } = this.state
		const {
			state: { user, paCaseDetails },
		} = this.props
		if (!prescriberData) {
			const header = 'Fields Missing'
			const message = 'Please Select a Prescriber to Proceed'
			this._showTransitionalPortal(header, message)
			return
		}

		let params = {
			...planForm,
			request_type: 'main_details',
			pa_case_id: planForm.pa_case_id,
			pa_case_status: Constants.IN_PROCESS_STR,
			verification_section: 'prescriber',
			prescriber: {
				npi: prescriberData.npi,
				first_name: prescriberData.first_name,
				last_name: prescriberData.last_name,
				address1: prescriberData.address1,
				address2: prescriberData.address2,
				city: prescriberData.city,
				state: prescriberData.state,
				zip_code: prescriberData.zip_code,
				country_code: '',
				phone_number: prescriberData.phone_number,
				fax_number: prescriberData.fax_number,
				secure_fax_number: prescriberData.secure_fax_number,
			},
			review_technician: user.uuid,
			first_name: user.first_name,
			last_name: user.last_name,
		}
		// if pa_case_id is missing, we are creating a new case
		if (!planForm.pa_case_id) {
			params.pa_case_status = Constants.IN_PROCESS_STR
			params.intake_type = 'Manual'
			params.case_type = 'pa'
		}
		this.props.actions.updatePaCase(params)
	}


	checkIfMemberValidated = (memberData) => {
		if (memberData?.effective_date) {
			const date = moment().format('YYYY-MM-DD')
			const start = moment(memberData?.effective_date).format('YYYY-MM-DD')
			const end = moment(memberData?.term_date).format('YYYY-MM-DD')
			if (date < start || date > end) {
				return false
			}
			return true
		} else return true
	}

	getNotFoundText = (memberData) => {
		const { memberSearched } = this.state
		const {
			paCaseDetails: { transition_message, member_information_validated },
		} = this.props.state
		if (memberData?.effective_date) {
			const date = moment().format('YYYY-MM-DD')
			const start = moment(memberData?.effective_date).format('YYYY-MM-DD')
			const end = moment(memberData?.term_date).format('YYYY-MM-DD')
			if (date < start || date > end) {
				return (
					<div className="not-found-container">
						<Icon name="exclamation triangle" color="red" />
						<span className="not-found-text">Member Not Eligible</span>
					</div>
				)
			}
		} else if (
			((!memberData?.first_name || !memberData?.memberId || !memberData?.member_id) &&
				(memberSearched || member_information_validated)) ||
			transition_message?.member_does_not_exist
		) {
			return (
				<div className="not-found-container">
					<Icon name="exclamation triangle" color="red" />
					<span className="not-found-text">Member Not Found</span>
				</div>
			)
		}
	}



	renderPrescriberEditOptions = () => {
		const { paCaseDetails } = this.props.state
		const { prescriberData, isPrescriberDataDirty, lockCase, isCaseInfoEditable } = this.state
		if (prescriberData) {
			delete prescriberData?.fax_number
			delete prescriberData?.country_code
		}
		let prescriberButtonName = 'Find Prescriber'
		if (paCaseDetails?.prescriber_information_validated) {
			prescriberButtonName = 'Edit Prescriber'
		}
		let isVerifyPrescriberDisabled = !prescriberData || lockCase || !isPrescriberDataDirty

		return (
			<div className="verify-edit-buttons-container">
				{paCaseDetails?.prescriber_information_validated && !isPrescriberDataDirty && (
					<div className="validated-text">
						<Icon name="check circle" color="green" />
						Validated
					</div>
				)}
				{isCaseInfoEditable && (
					<>
						<FliptButton
							disabled={isVerifyPrescriberDisabled}
							className="primary"
							name="Verify Prescriber"
							onClick={this._createRequest}
						/>
						<FliptButton
							className="primary"
							disabled={lockCase}
							name={prescriberButtonName}
							onClick={this._findPrescriber}
						/>
					</>
				)}
			</div>
		)
	}

	renderMemberEditOptions = () => {
		const { paCaseDetails } = this.props.state
		const { memberSearched, lockCase, isCaseInfoEditable } = this.state

		let isVerifyMemberDisabled = !memberSearched || lockCase
		let memberButtonName = 'Find Member'
		if (paCaseDetails.member_information_validated) memberButtonName = 'Edit Member'

		return (
			<div className="verify-buttons-container">
				{paCaseDetails?.member_information_validated && !memberSearched && (
					<div className="validated-text">
						<Icon name="check circle" color="green" />
						Validated
					</div>
				)}
				{isCaseInfoEditable && (
					<>
						<FliptButton
							disabled={isVerifyMemberDisabled}
							className="primary"
							name="Verify Member"
							onClick={this._updateMemberDetails}
						/>
						<FliptButton disabled={lockCase} className="primary" name={memberButtonName} onClick={this.addMember} />
					</>
				)}
			</div>
		)
	}

	renderDrugEditOptions = () => {
		const { drugData, lockCase, isDrugDataDirty, isCaseInfoEditable } = this.state
		const { paCaseDetails } = this.props.state
		const isIntent = paCaseDetails.intake_type === 'INTENT' || paCaseDetails.intake_type === 'CLAIM REJECT'
		// const isIntentDrugValidated = isIntent && paCaseDetails.drug_information_validated
		// const isVerifyDrugDisabled = !drugData?.drug_name || isIntentDrugValidated || lockCase || (!isIntent && !isDrugDataDirty)
		const isVerifyDrugDisabled =
			!drugData?.drug_name ||
			lockCase ||
			(isIntent && paCaseDetails.drug_information_validated) ||
			(!isIntent && !isDrugDataDirty)
		let drugButtonName = 'Find Drug'
		if (paCaseDetails.drug_information_validated) drugButtonName = 'Edit Drug'
		return (
			<div className="verify-edit-buttons-container">
				{paCaseDetails?.drug_information_validated && !isDrugDataDirty && (
					<div className="validated-text">
						<Icon name="check circle" color="green" />
						Validated
					</div>
				)}
				{isCaseInfoEditable && (
					<>
						<FliptButton
							disabled={isVerifyDrugDisabled}
							className="primary"
							name="Verify Drug"
							onClick={this._updatePaCase}
						/>
						<FliptButton
							disabled={isIntent || !paCaseDetails.patient_benefit || lockCase}
							className="primary"
							name={drugButtonName}
							onClick={this._findDrug}
						/>
					</>
				)}
			</div>
		)
	}

	renderDiagnosisEditOptions = () => {
		const { lockCase, isDiagnosisDataDirty, isCaseInfoEditable } = this.state
		const { paCaseDetails } = this.props.state
		let diagnosisButtonName = 'Find Diagnosis'
		if (paCaseDetails.diagnosis_information_validated) diagnosisButtonName = 'Edit Diagnosis'

		return (
			<div className="verify-edit-buttons-container">
				{paCaseDetails?.diagnosis_information_validated && (
					<div className="validated-text">
						<Icon name="check circle" color="green" />
						Validated
					</div>
				)}
				{isCaseInfoEditable && (
					<>
						<FliptButton
							disabled={lockCase || !isDiagnosisDataDirty}
							className="primary"
							name="Verify Diagnosis"
							onClick={this._updateDiagnosis}
						/>
						<FliptButton
							disabled={lockCase || (!paCaseDetails.patient_benefit && !paCaseDetails.prescriber)}
							className="primary"
							name={diagnosisButtonName}
							onClick={this._findDiagnosis}
						/>
					</>
				)}
			</div>
		)
	}

	getPrescriberAddressString = () => {
		const { address1 = '', address2 = '', city = '' } = this.state.prescriberData || {}
		let address = address1
		if (address2) {
			address = address + `, ${address2}`
		}
		if (city) {
			address = address + `, ${city}`
		}
		return address
	}


	render() {
		const { drugData, prescriberData, diagnosisData, memberData, searchForm, isDrugDataDirty } = this.state
		const {
			state: { paCaseDetails, updatePaCaseData },
			drugMsg,
		} = this.props
		const isIntent = paCaseDetails.intake_type === 'INTENT' || paCaseDetails.intake_type === 'CLAIM REJECT'

		return (
			<div id="PACaseTab">
				<div className="section-container">
					<div className="section-header-container">
						<span className="section-header">{paCaseDetails?.prescriber ? 'Prescriber Information' : 'Find Prescriber'}</span>
						{this.renderPrescriberEditOptions()}
					</div>
					<div className="section-row">
						<FliptInput value={prescriberData?.npi} stylized label="NPI" name="npi" disabled />
						<FliptInput
							value={prescriberData?.first_name}
							stylized
							label="Prescriber First Name"
							name="first_name"
							disabled
						/>
						<FliptInput
							value={prescriberData?.last_name}
							stylized
							label="Prescriber last Name"
							name="last_name"
							disabled
						/>
						<FliptInput
							value={this.getPrescriberAddressString()}
							name="address1"
							stylized
							label="Address"
							disabled
						/>
					</div>
					<div className="section-row">
						<FliptInput
							value={prescriberData?.phone_number}
							stylized
							label="Phone Number"
							name="phone_number"
							disabled
						/>
						<FliptInput value={prescriberData?.zip_code} stylized label="Zip Code" name="zip_code" disabled />
						<FliptInput value={prescriberData?.state} stylized label="State" disabled />
						<FliptInput
							value={prescriberData?.secure_fax_number}
							stylized
							name="secure_fax_number"
							label="Secure Fax Number"
							disabled
						/>
					</div>
				</div>
				<div className="divider-tab" />
				<div className="section-container">
					<div className="section-header-container">
						<span className="section-header">{paCaseDetails?.medication?.ndc ? 'Drug Information' : 'Find Drug'}</span>
						{this.renderDrugEditOptions()}
					</div>
					<div className="section-row">
						<FliptInput value={drugData?.drug_name} stylized label="Drug Name" disabled />
						<FliptInput value={drugData?.gpi} stylized label="GPI" disabled />
						<FliptInput
							onChange={this.updateQuantity}
							name="quantity"
							disabled={!isDrugDataDirty || isIntent}
							value={searchForm?.quantity}
							stylized
							label="Quantity"
						/>
						<FliptInput
							onChange={this.updateDaySupply}
							name="days_supply"
							disabled={!isDrugDataDirty || isIntent}
							value={searchForm?.days_supply}
							stylized
							label="Days Supply"
						/>

					</div>
					<div className="section-row">
						<FliptInput disabled value={drugData?.dosage_strength} stylized label="Drug Strength" />
						<FliptInput value={drugData?.ndc} stylized label="NDC" disabled />
						<FliptInput disabled value={drugData?.dosage} stylized label="Drug Form" />
						<FliptInput
							value={memberData?.formulary || memberData?.formulary_name}
							stylized
							label="Formulary Name"
							disabled
						/>
					</div>
					{drugMsg || (updatePaCaseData?.length && updatePaCaseData[0].pa_flag) ? (
						<div className="not-found-container">
							<Icon name="exclamation triangle" color="red" />
							<span className="not-found-text">{drugMsg || 'PA Not Required'}</span>
						</div>
					) : null}
				</div>
				<div className="divider-tab" />
				<div className="section-container">
					<div className="section-header-container">
						<span className="section-header">
							{paCaseDetails?.diagnosis?.icd_10_cm ? 'Diagnosis Search' : 'Find Diagnosis'}
						</span>
						{this.renderDiagnosisEditOptions()}
					</div>
					<div className="section-row">
						{/* <div className="inner-field-container"> */}
						<FliptInput value={diagnosisData?.icd_10_cm} stylized label="ICD-10 Code" disabled />
						{/* </div> */}
						{/* <div className="inner-field-container margin-on-left"> */}
						<FliptInput
							className='diagnosis-description'
							disabled
							value={diagnosisData?.long_description}
							stylized
							label="Diagnosis Description"
						/>
						{/* </div> */}
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	state: {
		app: state.app,
		user: state.user,
		saveNotesdata: state.priorAuthorizationCreation.saveNotesdata,
		paCaseDetails: state.priorAuthorizationCreation.paCaseDetails,
		questionnaireData: state.priorAuthorizationCreation.questionnaireData,
		memberLookup: state.memberLookup,
		createPaCaseData: state.priorAuthorizationCreation.createPaCaseData,
		updatePaCaseData: state.priorAuthorizationCreation.updatePaCaseData,
		getAnswerData: state.priorAuthorizationCreation.getAnswerData,
		duplicateData: state.priorAuthorizationCreation.duplicateData,
		saveAnswerData: state.priorAuthorizationCreation.saveAnswerData,
	},
})

const mapDispatchToProps = (dispatch) => {
	const allActions = {
		...AppActions,
		...PriorAuthorizationCreationActions,
	}

	return {
		actions: bindActionCreators(allActions, dispatch),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PACaseTab)
