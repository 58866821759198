import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment-timezone'

import { Creators as AppActions } from '../../../../redux/reducers/app'
import { Creators as MemberLookupActions } from '../../../../redux/reducers/api/memberLookup'
import { Creators as PriorAuthorizationCreationActions } from '../../../../redux/reducers/api/priorAuthorizationManagement'

import './styles.scss'
import BreadCrumb from '../../../../components/v2/breadCrumb'
import PACaseTab from '../PACaseTab'
import RxReport from '../RxReport'
import PaReport from '../PaReport'
import Notes from '../Notes'
import Attachments from '../Attachments'
import Communications from '../Communications'
import { Icon } from 'semantic-ui-react'
import FliptButton from '../../../../components/v2/fliptButton'
import ApprovePaModal from '../ApprovePaModal'
import RfiModal from '../RfiModal'
import PharmacistDenialModal from '../PharmacistDenialModal'
import CloseModal from '../CloseModal'
import * as Constants from '../constants'
import MemberInfo from './MemberInfo'
import CaseInformation from './CaseInformation'

class PACaseDashboard extends Component {
	constructor(props) {
		super(props)

		this.state = {
			selectedTab: 'PA_CASE',
			findMember: true,
			// paCaseDetails: null,
			pa_case_id: '',
			planForm: {},
			memberSearched: false,
			requestStatus: '',
			memberData: null,
			policyClicked: false,
			questionnaireClicked: false,
			drugMsg: null,
			lockCase: false,
			memberData: null,
			isCaseInfoEditable: true,
		}
	}

	checkEditable = (paCaseDetails) => {
		const { pa_case_status, intake_type, audit_trail } = paCaseDetails
		if (intake_type === Constants.SURESCRIPTS_STR) return false
		if ([Constants.NEW_STR, Constants.IN_PROCESS_STR, Constants.HOLD_PENDING_STR].includes(pa_case_status)) {
			return audit_trail.every((statusLog) => statusLog.audit_trail_status !== Constants.RFI_STR)
		}
		return false
	}
	componentDidMount() {
		const {
			location,
			actions,
			state: { menu_data, paCaseDetails },
		} = this.props
		actions.clearMemberLookupData()
		if (location?.state?.pa_case_id && menu_data?.length) {
			// if ids are different, clear old pa case details
			if (location.state.pa_case_id !== paCaseDetails?.pa_case_id) {
				actions.clearPaCaseDetails()
			}
			if (!paCaseDetails?.pa_case_id) {
				this.getPACase(location.state.pa_case_id)
				if (location?.state?.message) {
					const transitionalPortal = {
						header: 'Assign Successful',
						copy: location.state.message,
					}
					actions.displayTransitionalPortal(transitionalPortal)
				}
			}
		} else if (paCaseDetails?.pa_case_id) {
			this._setPaData()
		}
	}

	getPACase(pa_case_id) {
		const {
			actions,
			state: { paCaseDetails },
		} = this.props
		if (paCaseDetails?.pa_case_id || pa_case_id) {
			actions.getPaCaseDetails({ pa_case_id: pa_case_id ? pa_case_id : paCaseDetails.pa_case_id })
		}
	}

	componentDidUpdate(prevProps) {
		const {
			props,
			state: { policyClicked, questionnaireClicked, planForm, lockCase },
		} = this
		const {
			state: { paCaseDetails, updatePaCaseData, menu_data, paCaseMessage, questionnaireData, reassignData },
			location,
		} = props
		// const { patient_benefit, patient } = paCaseDetails || {}
		// const memberDetails = { ...patient_benefit, ...patient }
		if (
			prevProps.state.questionnaireData !== questionnaireData &&
			questionnaireData?.questionnaire_policy &&
			policyClicked
		) {
			this.setState({ policyClicked: false })
			window.open(questionnaireData.questionnaire_policy)
		}
		// get pa case when page is refreshed
		if (prevProps.state?.menu_data !== menu_data && menu_data?.length && location?.state?.pa_case_id) {
			this.getPACase(location?.state?.pa_case_id)
		}

		if (prevProps.state?.paCaseDetails !== paCaseDetails && paCaseDetails) {
			this._setPaData()
		}
		if (prevProps.state?.updatePaCaseData !== updatePaCaseData) {
			let showSecondPortal = true
			if (
				updatePaCaseData?.case_type_change_message ||
				(updatePaCaseData?.length && updatePaCaseData[0].case_type_change_message)
			) {
				const field = 'Case Type Changed'
				this._showTransitionalPortal(
					field,
					updatePaCaseData?.case_type_change_message || updatePaCaseData[0].case_type_change_message
				)
				showSecondPortal = false
			}
			this.getPACase()
			if (updatePaCaseData?.pa_case_id) {
				this.setState({ findMember: false })
			} else if (updatePaCaseData?.duplicate !== 'Y') {
				const case_type = updatePaCaseData[0]?.case_type
				const drug_information_validated = updatePaCaseData[0]?.drug_information_validated
				let messageHeader = 'PA Case Not Updated'
				if (case_type === Constants.BMN_STATUS_STR && drug_information_validated) {
					messageHeader = "PA Case Updated"
				}
				if (showSecondPortal) this._showTransitionalPortal(messageHeader, paCaseMessage)
				if (updatePaCaseData?.length && updatePaCaseData[0].pa_flag) {
					this.setState({ drugMsg: 'No PA Required' })
					const statusArray = [Constants.PA_STATUS_STR, Constants.QL_STATUS_STR, Constants.NC_STATUS_STR]
					if (
						!updatePaCaseData[0].ql_flag &&
						updatePaCaseData[0].drug_covered !== "N" &&
						statusArray.includes(case_type)
					) {
						this._openStatusModal(Constants.VOID_CLOSE_OPTION.value)
					}
				}

				if (updatePaCaseData?.length && updatePaCaseData[0].ql_flag) {
					this.setState({
						drugMsg: `QL exceeds plan QL of ${updatePaCaseData[0].ql_ratio}`,
						planForm: { ...planForm },
					})
				}
			}

			const prevStatus = prevProps.state.updatePaCaseData.pa_case_status
			const currStatus = updatePaCaseData.pa_case_status
			if (
				prevStatus !== currStatus &&
				[Constants.REOPEN_APPEAL_1_STR, Constants.REOPEN_APPEAL_2_STR].includes(currStatus)
			) {
				this.changeReopenToInProcess(currStatus)
			}
		}
	}

	componentWillUnmount() {
		const { actions } = this.props
		// actions.clearUpdatePaCaseData()
		// actions.clearPaCaseDetails()
	}

	changeReopenToInProcess = (currStatus) => {
		const {
			actions,
			state: { paCaseDetails, user },
		} = this.props
		let newStatus = ''
		if (currStatus === Constants.REOPEN_APPEAL_1_STR) {
			newStatus = Constants.IN_PROCESS_APPEAL_1_STR
		} else if (currStatus === Constants.REOPEN_APPEAL_2_STR) {
			newStatus = Constants.IN_PROCESS_APPEAL_2_STR
		}
		if (newStatus) {
			actions.updatePaCase({
				pa_case_id: paCaseDetails.pa_case_id,
				request_type: 'main_details',
				pa_case_status: newStatus,
				first_name: user?.first_name || '',
				last_name: user?.last_name || '',
				userId: user?.uuid,
			})
		}
	}

	_setPaData = () => {
		const { props, state } = this
		const {
			state: { paCaseDetails, user },
		} = props
		let planForm = state.planForm
		planForm = { ...paCaseDetails }
		const requestStatus = planForm.pa_case_status
		planForm.pa_case_status = null
		let memberSearched = false
		if (paCaseDetails?.member_information_validated) memberSearched = true
		if (
			paCaseDetails?.member_information_validated &&
			!paCaseDetails?.patient_benefit?.plan_name &&
			paCaseDetails.pa_case_status !== 'VOID - CLOSE'
		) {
			planForm.pa_case_status = 'VOID - CLOSE'
		}
		let lockCase = false
		if (paCaseDetails?.review_technician && paCaseDetails.review_technician !== user.uuid) lockCase = true
		let drugMsg = null
		if (paCaseDetails?.transition_message?.drug_not_covered) drugMsg = 'Drug Not covered'
		if (paCaseDetails?.transition_message?.drug_is_not_pa_bound) drugMsg = `No PA Required`
		if (paCaseDetails?.transition_message?.drug_exceeds_ql_for_plan)
			drugMsg = `QL exceeds plan QL of ${paCaseDetails?.transition_message?.drug_exceeds_ql_ratio}`
		let isCaseInfoEditable = this.checkEditable(paCaseDetails)
		this.setState({
			paCaseDetails: paCaseDetails,
			pa_case_id: paCaseDetails.pa_case_id,
			drugMsg,
			planForm,
			requestStatus,
			memberSearched,
			memberData: paCaseDetails?.patient_benefit,
			lockCase,
			isCaseInfoEditable,
		})
	}

	_selectTab = (selectedTab) => {
		const { actions, state: { paCaseDetails } } = this.props
		const { pa_case_id } = paCaseDetails
		const { planForm, lockCase } = this.state
		if (!pa_case_id) return
		const { patient_benefit, audit_trail, pa_case_logs = [] } = paCaseDetails || {}
		// merge audit_trail and pa_case_logs while keeping order of audit_trail
		const at = [...audit_trail],
			pcl = [...pa_case_logs]
		const customAuditTrail = []
		let i = 0
		while (pcl.length > 0) {
			if (i < at.length && at[i].begin_date_time <= pcl[0].begin_date_time) {
				customAuditTrail.push(at[i])
				i++
			} else {
				customAuditTrail.push(pcl.pop())
			}
		}
		customAuditTrail.push(...at.slice(i, at.length))

		switch (selectedTab) {
			case 'RX_HISTORY':
				{
					actions.setSideDrawerComponent({
						modalProperties: { size: 'large' },
						contents: <RxReport member_id={patient_benefit?.member_id} />,
					})
					actions.toggleSideDrawer()
				}
				break
			case 'PA_HISTORY':
				{
					actions.setSideDrawerComponent({
						modalProperties: { size: 'large' },
						contents: <PaReport member_id={patient_benefit?.member_id} pa_case_id={pa_case_id} />,
					})
					actions.toggleSideDrawer()
				}
				break
			case 'NOTES':
				{
					actions.setSideDrawerComponent({
						modalProperties: { size: 'large' },
						contents: <Notes pa_case_id={pa_case_id} />,
					})
					actions.toggleSideDrawer()
				}
				break
			case 'ATTACHMENTS':
				{
					actions.setSideDrawerComponent({
						modalProperties: { size: 'large' },
						contents: <Attachments pa_case_id={pa_case_id} lockCase={lockCase} />,
					})
					actions.toggleSideDrawer()
				}
				break
			case 'COMMUNICATIONS':
				{
					actions.setSideDrawerComponent({
						modalProperties: { size: 'large' },
						contents: <Communications audit_trail={customAuditTrail} paCaseDetails={paCaseDetails} />,
					})
					actions.toggleSideDrawer()
				}
				break
			case 'POLICY':
				{
					if (planForm?.medication?.gpi || planForm?.medication?.ndc) {
						this.setState({ policyClicked: true })
						const params = {
							ndc: planForm?.medication?.ndc,
							gpi: planForm?.medication?.gpi,
							group: patient_benefit?.group,
							case_type: planForm.case_type,
							source: 'FAST',
						}
						actions.getQuestionnaire(params)
					} else {
						const field = 'Fields Missing'
						const message = 'Please Add Drug To View Policy'
						this._showTransitionalPortal(field, message)
					}
				}
				break
		}
	}

	// only updates case_type, pa_case_status and priority (section above 'Save & Close' button)
	// do not use this to update member, prescriber or
	// other info that require verification_section arg
	_updatePaCase = () => {
		const { planForm } = this.state
		const {
			actions,
			state: { paCaseDetails, user },
		} = this.props
		let { pa_case_status } = planForm
		// check Constants.STATUSES_UPDATABLE_FROM_DROPDOWN if we need to filter more
		if (!pa_case_status || Constants.NOT_STATUS_STRS.includes(pa_case_status)) {
			pa_case_status = paCaseDetails.pa_case_status
		}

		const params = {
			pa_case_id: planForm.pa_case_id,
			case_type: planForm.case_type,
			priority_indicator: planForm.priority_indicator,
			request_type: 'main_details',
			pa_case_status: pa_case_status,
			first_name: user?.first_name || '',
			last_name: user?.last_name || '',
			userId: user?.uuid,
		}
		actions.updatePaCase(params)
	}
	_updateMemberDetails = () => {
		const { planForm, requestStatus } = this.state
		const { memberLookup, user } = this.props.state
		let memberData = memberLookup.member_details
		let benefit_details = memberLookup.benefit_details
		if (memberLookup.multiMemberLookup?.length) {
			const index = memberLookup.multiMemberLookup.findIndex(
				(data) => data.member_details.member_id === this.state.memberData.member_id
			)
			memberData = memberLookup.multiMemberLookup[index].member_details
			benefit_details = memberLookup.multiMemberLookup[index].benefit_details
		}
		if (!memberData) {
			const header = 'Fields Missing'
			const message = 'Please add a Member to proceed'
			this._showTransitionalPortal(header, message)
			return
		}
		const params = {
			request_type: 'member_details',
			...planForm,
			pa_case_id: planForm.pa_case_id,
			pa_case_status: requestStatus,
			verification_section: 'member',
			first_name: user.first_name,
			last_name: user.last_name,
			review_technician: user.uuid,
			patient_benefit: {
				member_id: memberData.memberId || memberData.member_id || this.state.memberData.memberId || '',
				plan_name: memberData.plan_name || '',
				client: benefit_details?.client || '',
				LOB: benefit_details?.lob || '',
				group: memberData.group || '',
				effective_date: memberData.coverage_start_date || '',
				term_date: memberData.coverage_end_date || '',
				contract_id: benefit_details?.contract_id || '',
				PBP: '',
				formulary_name: benefit_details?.formulary || '',
				PCP: benefit_details?.pcp || '',
				flipt_person_id: memberData.flipt_person_id,
				domain: memberData.domain_name,
			},
			patient: {
				first_name: memberData.first_name || this.state.memberData.first_name,
				last_name: memberData.last_name || this.state.memberData.last_name,
				dob: memberData.date_of_birth,
				home_address_2: memberData.home_address_2,
				city: memberData.city,
				state: memberData.state,
				zipcode: memberData.zip,
				home_address_1: memberData.home_address_1,
				phone_number: memberData.phone_number,
				gender: memberData.gender,
			},
		}
		// if pa_case_id is missing, we are creating a new case
		if (!planForm.pa_case_id) {
			params.pa_case_status = Constants.IN_PROCESS_STR
			params.intake_type = 'Manual'
			params.case_type = 'pa'
		}
		this.props.actions.updatePaCase(params)
		this.setState({ memberData: null })
	}

	_exitCase = () => {
		this.props.history.push('/pa-dashboard')
	}
	_updatePaCaseAndClose = () => {
		this._updatePaCase()
		this._exitCase()
	}

	copyId = (memberId) => {
		navigator.clipboard.writeText(memberId)
	}

	getNotFoundText = (memberData) => {
		if (memberData.effective_date) {
			const date = new Date()
			const start = new Date(memberData?.effective_date)
			const end = new Date(memberData?.term_date)
			if (date < start && date > end) {
				return (
					<div className="not-found-container">
						<Icon name="exclamation triangle" color="red" />
						<span className="not-found-text">Member Not Eligible</span>
					</div>
				)
			}
		} else {
			return (
				<div className="not-found-container">
					<Icon name="exclamation triangle" color="red" />
					<span className="not-found-text">Member Not Found</span>
				</div>
			)
		}
	}

	_openStatusModal = (status) => {
		const { planForm, lockCase } = this.state
		const { actions, state } = this.props
		const {
			paCaseDetails: { patient_benefit },
		} = state || {}

		if ([Constants.APPROVED_STR, Constants.APPROVED_APPEAL_1_STR, Constants.APPROVED_APPEAL_2_STR].includes(status)) {
			actions.setSideDrawerComponent({
				modalProperties: { size: 'large' },
				contents: <ApprovePaModal planForm={planForm} selectedStatus={status} lockCase={lockCase} />,
			})
			actions.toggleSideDrawer()
		}
		if ([Constants.RFI_STR, Constants.RFI_APPEAL_1_STR, Constants.RFI_APPEAL_2_STR].includes(status)) {
			actions.setSideDrawerComponent({
				modalProperties: { size: 'large' },
				contents: <RfiModal planForm={planForm} selectedStatus={status} lockCase={lockCase} />,
			})
			actions.toggleSideDrawer()
		}
		if ([Constants.DENIED_STR, Constants.DENIED_APPEAL_1_STR, Constants.DENIED_APPEAL_2_STR].includes(status)) {
			actions.setSideDrawerComponent({
				modalProperties: { size: 'large' },
				contents: <PharmacistDenialModal planForm={planForm} selectedStatus={status} lockCase={lockCase} />,
			})
			actions.toggleSideDrawer()
		}

		if (status === Constants.VIEW_QUESTIONNAIRE_STR) {
			if (planForm?.medication?.gpi || planForm?.medication?.ndc) {
				this.setState({ questionnaireClicked: true })
				const params = {
					ndc: planForm?.medication?.ndc,
					gpi: planForm?.medication?.gpi,
					group: patient_benefit?.group,
					case_type: planForm.case_type,
					source: 'FAST',
				}
				actions.getQuestionnaire(params)
			} else {
				const field = 'Fields Missing'
				const message = 'Please Add Drug To View Questionnaire'
				this._showTransitionalPortal(field, message)
			}
		}

		if (status === Constants.VOID_CLOSE_STR) {
			actions.setSideDrawerComponent({
				modalProperties: { size: 'large' },
				contents: (
					<CloseModal
						planForm={planForm}
						selectedStatus={status}
						pa_case_id={planForm.pa_case_id}
						lockCase={lockCase}
					/>
				),
			})
			actions.toggleSideDrawer()
		}

		if (status === Constants.VIEW_COMPLETED_QUESTIONNAIRE_STR) {
			actions.getAnswer({ pa_case_id: planForm.pa_case_id })
		}
	}

	_updatePlanForm = (el, dropdown) => {
		const { paCaseDetails } = this.props.state
		const { planForm } = this.state
		const { name, value } = dropdown || el.currentTarget
		const allowedStatusUpdateWithoutValidation = [
			Constants.VOID_CLOSE_STR,
			Constants.CLOSED_VOID_CLOSE_STR,
			Constants.CLOSED_ERROR_RESOLVED_STR,
			Constants.CLOSED_ERROR_UNRESOLVED_STR,
		]
		if (name === 'pa_case_status') {
			if (
				!allowedStatusUpdateWithoutValidation.includes(value) &&
				(!paCaseDetails?.prescriber_information_validated ||
					!paCaseDetails?.drug_information_validated ||
					!paCaseDetails?.member_information_validated)
			) {
				const field = 'Fields Missing'
				const message = 'Please Add Drug, Prescriber and Member to change the Status'
				this._showTransitionalPortal(field, message)
				return
			}
			this._openStatusModal(value)
		}
		planForm[name] = value
		this.setState({ planForm })
	}

	checkIfMemberValidated = (memberData) => {
		if (memberData?.effective_date) {
			const date = moment().format('YYYY-MM-DD')
			const start = moment(memberData?.effective_date).format('YYYY-MM-DD')
			const end = moment(memberData?.term_date).format('YYYY-MM-DD')
			if (date < start || date > end) {
				return false
			}
			return true
		} else return true
	}

	_showTransitionalPortal = (header, message) => {
		const { props } = this
		const transitionalPortal = {
			header: header,
			copy: message,
		}
		props.actions.displayTransitionalPortal(transitionalPortal)
	}

	setMemberSearched = () => {
		this.setState({ memberSearched: true })
	}

	updateDrugMsg = () => {
		this.setState({ drugMsg: null })
	}


	navigateToTestClaim = () => {
		const { state, history } = this.props
		const { paCaseDetails } = state
		const { patient_benefit, medication, prescriber, pharmacy } = paCaseDetails
		const claimDetails = {
			cardholder_id: patient_benefit?.member_id,
			product_id: medication?.ndc,
			prescriber_id: prescriber?.npi,
			service_provider_id: pharmacy?.npi,
			days_supply: medication?.days_supply,
			quantity_dispensed: medication?.quantity,
			prescription_reference_number: '9999999999',
			ingredient_cost_submitted: '9999999999',
			dispensing_fee_submitted: '9999999999',
			usual_and_customary_charge: '9999999999',
			gross_amount_due: '9999999999',
			basis_of_cost_determination: '01',
			fill_number: '1',
			compound_code: '1',
			date_prescription_written: moment(new Date()).format('YYYYMMDD'),
		}
		history.push({
			pathname: '/test-claim-generation',
			state: { ...claimDetails },
		})
	}

	getPATechnicianName = (params) => {
		const {
			state: { allUsers },
		} = this.props
		return allUsers.find((x) => x?.user_id === params)?.name ?? (params?.value || 'None')
	}



	render() {
		const { paCaseDetails } = this.props.state
		const { drugMsg, lockCase, isCaseInfoEditable } = this.state

		return (
			<div id="PACaseDasboard">
				<BreadCrumb {...this.props} />
				<div>
					<MemberInfo
						lockCase={lockCase}
						isCaseInfoEditable={isCaseInfoEditable}
						updateDrugMsg={this.updateDrugMsg}
					// drugMsg={drugMsg}
					/>
					<div className="tab-container">
						<div className="first-tab selected-tab" onClick={() => this._selectTab('PA_CASE')}>
							PA Case
						</div>
						<div className="unselected-tab" onClick={() => this._selectTab('RX_HISTORY')}>
							Rx History
						</div>
						<div className="unselected-tab" onClick={() => this._selectTab('PA_HISTORY')}>
							PA History
						</div>
						<div className="unselected-tab" onClick={() => this._selectTab('ATTACHMENTS')}>
							Attachments
						</div>
						<div className="unselected-tab" onClick={() => this._selectTab('COMMUNICATIONS')}>
							Audit Trail
						</div>
						<div className="unselected-tab" onClick={() => this._selectTab('NOTES')}>
							Notes
						</div>
						<div className="last-tab unselected-tab" onClick={() => this._selectTab('POLICY')}>
							Policy
						</div>
						{!paCaseDetails.pa_case_id ? (
							<div className="cancel-button">
								<FliptButton
									className="primary"
									name="Cancel"
									onClick={() => {
										this.props.history.push('/pa-dashboard')
									}}
								/>
							</div>
						) : null}
					</div>
				</div>
				<div className='pa-case-dashboard-section-2'>
					<CaseInformation lockCase={lockCase} />
					<div className="divider-tab" />
					<PACaseTab
						paCaseDetails={paCaseDetails}
						updateDrugMsg={this.updateDrugMsg}
						updatePaCase={this._updatePaCase}
						updatePaCaseAndClose={this._updatePaCaseAndClose}
						navigateToTestClaim={this.navigateToTestClaim}
						drugMsg={drugMsg}
						setMemberSearched={(data) => this.setMemberSearched(data)}
						getActionOptions={this.getActionOptions}
					/>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	state: {
		app: state.app,
		menu_data: state.app.menu_data,
		user: state.user,
		memberLookup: state.memberLookup,
		paCaseDetails: state.priorAuthorizationCreation.paCaseDetails,
		updatePaCaseData: state.priorAuthorizationCreation.updatePaCaseData,
		paCaseMessage: state.priorAuthorizationCreation.paCaseMessage,
		questionnaireData: state.priorAuthorizationCreation.questionnaireData,
		// reassignData: state.priorAuthorizationCreation.reassignData,
		allUsers: state.userManagement?.data?.map((x) => ({ name: `${x?.first_name} ${x?.last_name}`, user_id: x?.uuid })),
	},
})

const mapDispatchToProps = (dispatch) => {
	const allActions = {
		...PriorAuthorizationCreationActions,
		...AppActions,
		...MemberLookupActions,
	}

	return {
		actions: bindActionCreators(allActions, dispatch),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PACaseDashboard)
