/* eslint-disable func-names */
import moment from 'moment'
import {
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects'

import { Creators as AppActions } from '../../reducers/app'
import { Creators as NavigationActions } from '../../reducers/navigation'
import { Creators as RPMActions } from '../../reducers/rpm'
import { Creators as UserActions } from '../../reducers/user'
import { Types, Creators as FormularyCreationActions } from '../../reducers/api/formularyCreationManagement'
import { fetchGet, fetchPost, fileUploadPost } from '../../../utils/fetchApi'
import { filterRowData, readFileAsDataURL, convertDateToStrObj } from '../../../utils/utilities'
import SystemErrorModal from '../../../utils/systemError'
import * as ApprovalsConstants from '../rpm/constants'
import { deleteUnnecessaryFields } from '../../../utils/utilizationManagement'

import {
  getAppState, getApiPath, getHistory, getApprovedTiersData, getUserState
} from '../../reducers/selectors'
export default [
  approveFormularyWatcher,
  formularySendForReviewWatcher,
  formularyTierDocumentLookupWatcher,
  generateFormularyDataWatcher,
  generateFormularyTierWatcher,
  getAllApprovedTiersDataWatcher,
  getAllFormulariesDataWatcher,
  getAllFormularyTiersDataWatcher,
  getDrugDemographicDataWatcher,
  getFormularyDataWatcher,
  getFormularyTierDataWatcher,
  getFormularyTierDrugsViewDataWatcher,
  saveFormularyDataWatcher,
  saveFormularyTierDataWatcher,
  uploadFormularyImportDataWatcher,
  summaryFormularyImportFileWatcher,
  getDetailsFormularyImportFileWatcher,
  generateSmartRulesFormularyImportFileWatcher,
  saveHeaderMappingImportFileWatcher,
  navigateToFormularyTierWatcher,
  getFormularyTierDrugsExportAllWatcher,
  frfSearchWatcher,
  uspSearchWatcher,
]

/* WATCHERS */
function* getAllFormularyTiersDataWatcher() {
  yield takeLatest(Types.GET_ALL_FORMULARY_TIERS_DATA, getAllFormularyTiersDataHandler)
}

function* frfSearchWatcher() {
  yield takeLatest(Types.FRF_SEARCH, frfSearchHandler)
}

function* uspSearchWatcher() {
  yield takeLatest(Types.USP_SEARCH, uspSearchHandler)
}

function* generateFormularyTierWatcher() {
  yield takeLatest(Types.GENERATE_FORMULARY_TIER, generateFormularyTierHandler)
}

function* getDrugDemographicDataWatcher() {
  yield takeLatest(Types.GET_DRUG_DEMOGRAPHIC_DATA, getDrugDemographicDataHandler)
}

function* saveFormularyTierDataWatcher() {
  yield takeLatest(Types.SAVE_FORMULARY_TIER_DATA, saveFormularyTierDataHandler)
}

function* getFormularyTierDataWatcher() {
  yield takeLatest(Types.GET_FORMULARY_TIER_DATA, getFormularyTierDataHandler)
}

function* getFormularyTierDrugsExportAllWatcher() {
  yield takeLatest(Types.GET_FORMULARY_TIER_DRUGS_EXPORT_ALL, getFormularyTierDrugsExportAllHandler)
}

function* getAllFormulariesDataWatcher() {
  yield takeLatest(Types.GET_ALL_FORMULARIES_DATA, getAllFormulariesDataHandler)
}

function* getAllApprovedTiersDataWatcher() {
  yield takeLatest(Types.GET_ALL_APPROVED_TIERS_DATA, getAllApprovedTiersDataHandler)
}

function* saveFormularyDataWatcher() {
  yield takeLatest(Types.SAVE_FORMULARY_DATA, saveFormularyDataHandler)
}

function* generateFormularyDataWatcher() {
  yield takeLatest(Types.GENERATE_FORMULARY_DATA, generateFormularyDataHandler)
}

function* getFormularyDataWatcher() {
  yield takeLatest(Types.GET_FORMULARY_DATA, getFormularyDataHandler)
}

function* getFormularyTierDrugsViewDataWatcher() {
  yield takeLatest(Types.GET_FORMULARY_TIER_DRUGS_VIEW_DATA, getFormularyTierDrugsViewDataHandler)
}

function* formularySendForReviewWatcher() {
  yield takeLatest(Types.FORMULARY_SEND_FOR_REVIEW, formularySendForReviewHandler)
}

function* approveFormularyWatcher() {
  yield takeLatest(Types.APPROVE_FORMULARY, approveFormularyHandler)
}

function* formularyTierDocumentLookupWatcher() {
  yield takeLatest(Types.FORMULARY_TIER_DOCUMENT_LOOKUP, formularyTierDocumentLookupHandler)
}

function* uploadFormularyImportDataWatcher() {
  yield takeLatest(Types.UPLOAD_FORMULARY_IMPORT_DATA, uploadFormularyImportDataHandler)
}
function* summaryFormularyImportFileWatcher() {
  yield takeLatest(Types.SUMMARY_FORMULARY_IMPORT_FILE, summaryFormularyImportFileHandler)
}
function* getDetailsFormularyImportFileWatcher() {
  yield takeLatest(Types.GET_DETAILS_FORMULARY_IMPORT_FILE, getDetailsFormularyImportFileHandler)
}
function* generateSmartRulesFormularyImportFileWatcher() {
  yield takeLatest(Types.GENERATE_SMART_RULES_FORMULARY_IMPORT_FILE, generateSmartRulesFormularyImportFileHandler)
}
function* saveHeaderMappingImportFileWatcher() {
  yield takeLatest(Types.SAVE_HEADER_MAPPING_IMPORT_FILE, saveHeaderMappingImportFileHandler)
}
function* navigateToFormularyTierWatcher() {
  yield takeLatest(Types.NAVIGATE_TO_FORMULARY_TIER, navigateToFormularyTierHandler)
}

/* HANDLERS */
function* saveHeaderMappingImportFileHandler({ payload, callback }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const { api_path } = yield select(getApiPath, 'save-header-mapping-import-file')
    const url = `${serviceUrl}${api_path}`
    yield call(fetchPost, url, payload)
    if (callback) callback()
  } catch (err) {
    console.log('saveHeaderMappingImportFileHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Saving Header Mapping Import File Handler Failed',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* uploadFormularyImportDataHandler({ payload }) {
  try {
    yield put(FormularyCreationActions.clearFormularyInput())
    const { serviceUrl } = yield select(getAppState)
    const { api_path } = yield select(getApiPath, 'upload-import-file')
    const url = `${serviceUrl}${api_path}`
    const fileDataURL = yield call(readFileAsDataURL, payload)
    const response = yield call(fetchPost, url, { fileDataURL, filename: payload.name })
    yield put(FormularyCreationActions.setImportFileData(response.data[0]))
  } catch (err) {
    console.log('uploadFormularyImportDataHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Uploading Formulary Import File Handler Failed',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}
function* summaryFormularyImportFileHandler() {
  try {
    const { serviceUrl } = yield select(getAppState)
    const { api_path } = yield select(getApiPath, 'summary-import-file')
    const url = `${serviceUrl}${api_path}`
    const response = yield call(fetchGet, url)
    yield put(FormularyCreationActions.setImportFileData(response))
  } catch (err) {
    console.log('summaryFormularyImportFileHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Summary Formulary Import File Handler Failed',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}
function* getDetailsFormularyImportFileHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const { api_path } = yield select(getApiPath, 'get-details-import-file')
    const url = `${serviceUrl}${api_path}`
    const response = yield call(fetchGet, url, payload)
    yield put(FormularyCreationActions.setImportFileData(response))
  } catch (err) {
    console.log('getDetailsFormularyImportFileWatcher Error >Data ', err)
    const transitionalPortal = {
      header: 'Get Details From Import File ID Handler Failed',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}
function* generateSmartRulesFormularyImportFileHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const { api_path } = yield select(getApiPath, 'generate-smart-rules-import-file')
    const url = `${serviceUrl}${api_path}`
    const response = yield call(fetchPost, url, payload)
    yield put(FormularyCreationActions.setHeaderFieldMappingData(response))
  } catch (err) {
    console.log('generateSmartRulesFormularyImportFileHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Generate Smart Rules Formulary Import File Handler Failed',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}
function* navigateToFormularyTierHandler({ payload }) {
  try {
    yield put(NavigationActions.navigateTo({ pathname: '/formulary-tier', search: '?import', state: payload }))
  } catch (err) {
    console.log('navigateToFormularyTierHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Navigate To Formulary Tier Handler Failed',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* getAllApprovedTiersDataHandler() {
  try {
    const { serviceUrl } = yield select(getAppState)
    const cd = yield select(getApiPath, 'approved-tiers')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`
    const { data } = yield call(fetchGet, url)
    if (!data.length) throw Error('No Formulary Tiers found')

    yield put(FormularyCreationActions.setAllApprovedTiersData(data))
  } catch (err) {
    console.log('getAllApprovedTiersDataHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Getting Approved Formulary Tiers Failed',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function formatParam(form) {
  const keyArray = Object.keys(form)
  const obj = {}
  if (keyArray.length)
    keyArray.forEach((key) => {
      if (form[key]) {
        obj[key] = {
          filter: key === 'rxcui' ? parseInt(form[key]) : key === 'frf_category' ? form[key] : form[key].toUpperCase(),
          filterType: key === 'rxcui' ? 'number' : 'text',
          type: "equals"
        }
      }

    })
  return obj;
}

function formatUspParam(form) {
  const keyArray = Object.keys(form)
  const obj = {}
  if (keyArray.length)
    keyArray.forEach((key) => {
      if (form[key]) {
        obj[key] = {
          filter: form[key],
          filterType: 'text',
          type: "equals"
        }
      }

    })
  return obj;
}

function* frfSearchHandler({ payload }) {
  try {
    const {
      form, params
    } = payload
    let tempForm = { ...form }
    const filterModel = formatParam(tempForm)
    const keyArray = Object.keys(filterModel)
    if (!keyArray.length) {
      params?.success({
        rowData: [],
        rowCount: 0,
      })
      return
    }
    const { serviceUrl } = yield select(getAppState)
    const cd = yield select(getApiPath, 'frf')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`
    const formattedParam = {
      filterModel,
      limit: 20,
      offset: params?.request.startRow
    }
    const { data, last_row } = yield call(fetchPost, url, formattedParam)
    if (!data.length) {
      const transitionalPortal = {
        header: 'Data Not Found',
        copy: 'No FRF Data Found for Given Parameters',
      }
      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
      params?.success({
        rowData: [],
        rowCount: 0,
      })
    }
    params?.success({
      rowData: data,
      rowCount: last_row,
    })
    yield put(FormularyCreationActions.setFrfSearchData(data))
  } catch (err) {
    console.log('frfSearchHandler Error >Data ', err)

    const transitionalPortal = {
      header: 'FRF Search Failed',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* uspSearchHandler({ payload }) {
  try {
    const {
      form, params
    } = payload
    let tempForm = { ...form }
    const filterModel = formatUspParam(tempForm)
    const keyArray = Object.keys(filterModel)
    if (!keyArray.length) {
      params?.success({
        rowData: [],
        rowCount: 0,
      })
      return
    }
    const { serviceUrl } = yield select(getAppState)
    const cd = yield select(getApiPath, 'usp')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`
    const formattedParam = {
      filterModel,
      limit: 20,
      offset: params?.request.startRow
    }
    const { data, last_row } = yield call(fetchPost, url, formattedParam)
    if (!data.length) {
      const transitionalPortal = {
        header: 'Data Not Found',
        copy: 'No USP Data Found for Given Parameters',
      }
      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
      params?.success({
        rowData: [],
        rowCount: 0,
      })
    }
    params?.success({
      rowData: data,
      rowCount: last_row,
    })
    yield put(FormularyCreationActions.setUspSearchData(data))
  } catch (err) {
    console.log('uspSearchHandler Error >Data ', err)

    const transitionalPortal = {
      header: 'USP Search Failed',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* getAllFormularyTiersDataHandler() {
  try {
    const { userCAG = {} } = yield select(getUserState)
    const body = {
      module: 'FORMULARY_TIER',
      status: ApprovalsConstants.ALL_RPM_STATUSES,
    }

    yield put(RPMActions.rpmGetApprovalDocuments(body))
  } catch (err) {
    console.log('getAllFormularyTiersDataHandler Error >Data ', err)

    const transitionalPortal = {
      header: 'Formulary Tier Lookup Failed',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* getFormularyTierDrugsExportAllHandler({ payload, callback }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const { api_path } = yield select(getApiPath, 'generate-formulary-tier')
    const url = `${serviceUrl}${api_path}`
    const { data, message, status } = yield call(fetchPost, url, payload)
    if (!data.length) throw Error('No Drugs found for the input conditions')
    //yield put(FormularyCreationActions.setFormularyTierDrugExportAllData(data[0]['drugs']))
    if (callback) {
      yield callback(data, payload['filename'])
    }
  } catch (err) {
    console.log('getFormularyTierDrugsExportAll Error >Data ', err)
    const transitionalPortal = {
      header: 'getFormularyTierDrugsExportAll Failed',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* generateFormularyTierHandler({ payload }) {
  try {
    const {
      form, params, headers, headerMapping
    } = payload
    let get_total_rows = true
    if (params?.request.startRow > 0) {
      get_total_rows = false
    }
    const { serviceUrl } = yield select(getAppState)
    const include_inactive_drugs = form.include_inactive_drugs
    const reqData = {
      tierConditions: [deleteUnnecessaryFields(form)],
      limit: 20,
      offset: params?.request.startRow,
      get_total_rows,
      filterModel: params?.request.filterModel,
      sortModel: params?.request.sortModel,
      include_inactive_drugs,
    }
    const { api_path } = yield select(getApiPath, 'generate-formulary-tier')
    const url = `${serviceUrl}${api_path}`
    const { data, last_row } = yield call(fetchPost, url, reqData)
    const gridData = data?.map((d) => ({
      ...filterRowData(d, headers, headerMapping),
      effective_start_date: convertDateToStrObj(form.effective_start_date),
      effective_end_date: convertDateToStrObj(form.effective_end_date)
    }))
    if (get_total_rows) {
      params?.success({
        rowData: gridData,
        rowCount: last_row,
      })
    } else {
      params?.success({
        rowData: gridData
      })
    }
  } catch (err) {
    console.log(err)
    console.log('generateFormularyTier Error >Data ', err)
    const transitionalPortal = {
      header: 'generateFormularyTier Failed',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    // const headerText = 'The system is currently experiencing an issue that has been reported; logout and back in and please try again in 5 minutes.'
    // yield put(AppActions.setModalComponent({
    //   modalProperties: {
    //     size: 'tiny',
    //   },
    //   contents: SystemErrorModal({
    //     headerText,
    //     onSaveClick: UserActions.userLogout,
    //     toggleModal: AppActions.toggleModal,
    //   }),
    // }))
    // yield put(AppActions.toggleModal())
  }
}

function* getDrugDemographicDataHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const cd = yield select(getApiPath, 'drug-demographic')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`

    const { data } = yield call(fetchPost, url, payload)
    if (!data) throw Error('No Drugs demographic found for the input conditions')

    // const drugReducerToCall = drugDemographicToUpdate(payload)
    // yield put(drugReducerToCall({ data }))
    yield put(FormularyCreationActions.setFormularyTierDrugDemographicData(data[0]))
  } catch (err) {
    console.log('getDrugDemographicDataHandler Error >Data ', err)

    const transitionalPortal = {
      header: 'Getting Drug Demographic Failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}




function* getFormularyTierDataHandler({ payload }) {
  try {
    const cb = function* (data) {
      const tierData = data[0].module_data.data
      const reducerData = {
        tier_info: {
          doc_id: data[0].id,
          name: tierData.name,
          effective_start_date: moment(tierData.effective_start_date).format('MM-DD-YYYY'),
          version_effective_date: tierData?.version_effective_date,
          hierarchy: data[0].hierarchy,
          version: data[0].version,
          supplemental_tier: tierData.supplemental_tier,
          enhanced_tier: tierData.enhanced_tier,
          exchange_tier: tierData.exchange_tier,
          medicare_drug_list: tierData.medicare_drug_list,
          status: data[0].status,
          is_hierarchy_global: data[0].is_hierarchy_global,
          hideHierarchy: data[0].hideHierarchy,
        },
        tier_conditions: tierData.conditions,
      }
      if (data[0]?.module_name === 'PROGRAM_LIST') {
        const transitionalPortal = {
          header: 'Data Error',
          copy: 'There is Duplicate Data for this Formulary.',
        }
        yield put(AppActions.displayTransitionalPortal(transitionalPortal))
        yield put(NavigationActions.navigateBack())
        return
      }
      yield put(FormularyCreationActions.setFormularyTierData(reducerData))
    }

    yield put(RPMActions.rpmGetApprovalDocuments(payload, cb))
  } catch (err) {
    console.log('getFormularyTierDataHandler Error >Data ', err)

    const transitionalPortal = {
      header: 'Formulary Tier Lookup Failed',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* getAllFormulariesDataHandler() {
  try {
    const { userCAG = {} } = yield select(getUserState)
    const body = {
      module: 'FORMULARY',
      status: ApprovalsConstants.ALL_RPM_STATUSES,
    }

    const cb = function* (data) {
      const reduxData = data.map((d) => ({
        ...d,
        create_date: moment(d.create_date).format('MM-DD-YYYY'),
        created_by: d.created_by,
        effective_start_date: moment(d.effective_start_date).format('MM-DD-YYYY'),
        id: d.id,
        doc_id: d.id,
        last_updated: moment(d.last_updated).format('MM-DD-YYYY'),
        tiers: d.tiers || [],
        status: d.status,
        updated_by: d.updated_by,
      }))

      yield put(FormularyCreationActions.setAllFormulariesData(reduxData))
    }

    yield put(RPMActions.rpmGetApprovalDocuments(body, cb))
  } catch (err) {
    console.log('getAllFormulariesDataHandler Error >Data ', err)

    const transitionalPortal = {
      header: 'Formularies Lookup Failed',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* saveFormularyDataHandler({ payload }) {
  try {
    const approvedTierData = yield select(getApprovedTiersData)

    payload.tiers = payload.tiers.map((tier) => (approvedTierData.find((data) => data.doc_id === tier))).filter((d) => !!d)
    const approvalDocument = {
      module_name: payload.doc_name,
      data: payload,
      module: 'FORMULARY',
      status: 'DRAFT',
    }

    const cb = function* ({ success }) {
      if (success) yield put(NavigationActions.navigateTo({ pathname: '/formulary-creation-management' }))
    }

    yield put(RPMActions.rpmUpsertApprovalDocument(approvalDocument, cb))
  } catch (err) {
    console.log('saveFormularyDataHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Saving Formulary Failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* saveFormularyTierDataHandler({ payload }) {
  try {
    const approvalDocument = {
      module_name: payload.name,
      data: {
        ...payload,
        enhanced_tier: payload?.enhanced_tier ? payload?.enhanced_tier : 'N',
        exchange_tier: payload?.exchange_tier ? payload?.exchange_tier : 'N',
        medicare_drug_list: payload?.medicare_drug_list ? payload?.medicare_drug_list : 'N',
        supplemental_tier: payload?.supplemental_tier ? payload?.supplemental_tier : 'N',
        include_inactive_drugs: payload?.include_inactive_drugs ? payload?.include_inactive_drugs : 'N',
      },
      module: 'FORMULARY_TIER',
      status: 'DRAFT',
    }

    const cb = function* ({ success }) {
      if (success) yield put(NavigationActions.navigateTo({ pathname: '/formulary-tier-creation-management' }))
    }

    yield put(RPMActions.rpmUpsertApprovalDocument(approvalDocument, cb, false, true))
  } catch (err) {
    console.log('saveFormularyTierDataHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Saving Formulary Tier Failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* generateFormularyDataHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const cd = yield select(getApiPath, 'generate-formulary')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`

    const { data } = yield call(fetchPost, url, payload)
    if (!data.length) throw Error('No Tiers found ')
    yield put(FormularyCreationActions.setGeneratedFormularyData(data))
  } catch (err) {
    console.log('generateFormularyHandler Error >Data ', err)

    const transitionalPortal = {
      header: 'Formulary Generate Failed',
      copy: err.message || err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* getFormularyDataHandler({ payload }) {
  try {
    const body = {
      doc_id: payload.id,
    }

    const cb = function* (data) {
      const { module_data } = data[0]
      const formulary_info = {
        doc_id: data[0].id,
        doc_name: module_data.data.doc_name,
        doc_version: module_data.version,
        effective_start_date: module_data.data.effective_start_date,
        version_effective_date: module_data?.data?.version_effective_date,
        hierarchy: data[0].hierarchy,
        tiers: module_data.data.tiers,
        status: module_data.status,
        is_hierarchy_global: data[0].is_hierarchy_global,
        hideHierarchy: data[0].hideHierarchy,
      }

      yield put(FormularyCreationActions.setFormularyData(formulary_info))
      yield put(FormularyCreationActions.setAllApprovedTiersData(formulary_info.tiers))
    }

    yield put(RPMActions.rpmGetApprovalDocuments(body, cb))
  } catch (err) {
    console.log('getFormularyDataHandler Error >Data ', err)

    const transitionalPortal = {
      header: 'Formulary Lookup Failed',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* getFormularyTierDrugsViewDataHandler({ payload }) {
  try {
    const { location: { state } } = yield select(getHistory)
    const {
      form,
      params,
      headers,
    } = payload
    const { serviceUrl } = yield select(getAppState)

    const { moduleViewDrugs } = form
    const ids = _getModuleId(moduleViewDrugs, state.data)
    // const tierIds = state.data.tier_id ? [state.data.tier_id] : state.data.tier_configuration.map(({ tier_id }) => tier_id)

    if (!ids?.length) return

    const cd = yield select(getApiPath, 'view-tier-drugs')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}`
    const reqData = {
      moduleViewDrugs,
      filterModel: params?.request.filterModel,
      offset: params?.request.startRow,
      sortModel: params?.request.sortModel,
      ids,
    }

    const { data, total_rows } = yield call(fetchPost, url, reqData)

    if (!data.length) throw Error('No Drugs found')

    const gridData = data?.map((d) => ({
      ...filterRowData(d, headers),
    }))
    params?.success({
      rowData: gridData,
      rowCount: parseInt(total_rows, 10),
    })
  } catch (err) {
    console.log('getFormularyTierDrugsViewDataHandler Error >Data ', err)
    const {
      params,
    } = payload
    params?.success({
      rowData: [],
      rowCount: 0,
    })

    const transitionalPortal = {
      header: 'Formulary Tier View Drugs Lookup Failed',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* formularySendForReviewHandler({ payload, data }) {
  try {
    const status = data?.status || payload?.status
    if (status !== 'DRAFT') {
      throw Error('Document must be in draft status')
    }
    const { doc_id, module } = payload
    const body = {
      doc_id,
      module,
    }
    const pathname = module === 'FORMULARY' ? '/formulary-creation-management' : '/formulary-tier-creation-management'

    yield put(RPMActions.rpmSendForReview(body))
    yield put(FormularyCreationActions.getAllFormularyTiersData())

    if (module === 'FORMULARY') {
      yield put(FormularyCreationActions.getAllFormulariesData())
    }

    yield put(NavigationActions.navigateTo({ pathname }))
  } catch (err) {
    console.log('formularySendForReviewHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Data for Review Failed',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* formularyTierDocumentLookupHandler({ payload }) {
  try {
    const body = {
      doc_id: payload,
    }

    const cb = function* ({ data }) {
      const d = data[0]
      const reducerData = {
        tier_info: {
          doc_id: d.doc_id,
          name: d.doc_name,
          effective_start_date: moment(d.effective_start_date).format('MM-DD-YYYY'),
        },
        tier_conditions: d.conditions,
      }
      yield put(FormularyCreationActions.setFormularyTierData(reducerData))
    }

    yield put(RPMActions.rpmGetPublishedDocument(body, cb))
  } catch (err) {
    console.log('getFormularyLookupDateHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Unable to Lookup Document',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* approveFormularyHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const history = yield select(getHistory)
    const qs = history.location.search
    const cd = yield select(getApiPath, 'approve-formulary')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}${qs}`

    const { message } = yield call(fetchPost, url, payload)
    const transitionalPortal = {
      header: 'Formulary',
      copy: message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    yield put(FormularyCreationActions.getAllFormulariesData())
  } catch (err) {
    console.log('approveFormularyHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Approving Formulary Failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function _getModuleId(moduleViewDrugs, data) {
  switch (moduleViewDrugs) {
    case 'tier':
      return [data.doc_id]
    case 'formulary':
      return data.tier_configuration.map(({ tier_id }) => tier_id)
    case 'program':
      return [data.doc_id]
    case 'prior_authorization':
      return [data.doc_id]
    case 'quantity_limit':
      return [data.doc_id]
    default:
      return ''
  }
}

/* UTILS */
// function drugDemographicToUpdate(data) {
//   let drugReducerToCall = null
//   if ('manufacturer' in data) {
//     drugReducerToCall = FormularyCreationActions.setFormularyDrugNameData
//   } else if ('drug_subclass' in data) {
//     drugReducerToCall = FormularyCreationActions.setFormularyManufacturerNameData
//   } else if ('drug_class' in data) {
//     drugReducerToCall = FormularyCreationActions.setFormularyDrugSubClassData
//   } else if ('drug_group' in data) {
//     drugReducerToCall = FormularyCreationActions.setFormularyDrugClassData
//   }
//   return drugReducerToCall
// }
