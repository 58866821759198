import Immutable from 'seamless-immutable'
import { createActions, createReducer } from 'reduxsauce'

export const INITIAL_STATE = Immutable({
  loading: false,
  medispanLookupData: [],
  medispanHistoryData: [],
})



export const setMedispanLookupDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  medispanLookupData: payload,
})
export const setMedispanHistoryDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  medispanHistoryData: payload,
})

export const clearMedispanLookupData = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  medispanLookupData: [],
})


export const setLoadingReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  loading: payload.data,
})

export const { Types, Creators } = createActions({
  clearMedispanLookupData:null,
  getMedispanLookupData: ['payload'],
  setMedispanLookupData: ['payload'],
  setMedispanHistoryData: ['payload'],
  setLoading: ['payload'],
})

const HANDLERS = {
  [Types.SET_LOADING]: setLoadingReducer,
  [Types.SET_MEDISPAN_LOOKUP_DATA]: setMedispanLookupDataReducer,
  [Types.SET_MEDISPAN_HISTORY_DATA]: setMedispanHistoryDataReducer,
}

export default createReducer(INITIAL_STATE, HANDLERS)
