import {
    call,
    put,
    select,
    takeLatest,
} from 'redux-saga/effects'
import { v4 as uuidv4 } from 'uuid'

import { Types, Creators as FileConfigurationActions } from '../../reducers/api/fileConfiguration'
import { getAppState, getApiPath, getFileConfiguration, getFileConfigurationStep } from '../../reducers/selectors'
import { fetchGet, fetchPost, fetchPut } from '../../../utils/fetchApi'
import { CONFIG_ACTIONS, CONFIG_STATUS } from '../../../pages/private/integrationManagement/constants'

export default [
    confirmFieldEditChangesAndSyncWatcher,
    copyFileConfigurationWatcher,
    createNewFileConfigurationWatcher,
    deleteFieldImportConfigAndSyncWatcher,
    getFileTypeWatcher,
    getFileConfigurationWatcher,
    getFileConfigurationDashboardWatcher,
    handleNextStepWatcher,
    prepFileConfigurationForSubmitWatcher,
    setFieldImportConfigListFromSheetWatcher,
    syncMappedFieldsWatcher,
    syncWithServerWatcher,
    testValidationWatcher,
    validateAndAppendFieldsWatcher,
    updateFileConfigurationWatcher
]

/* WATCHERS */
function* confirmFieldEditChangesAndSyncWatcher() {
    yield takeLatest(Types.CONFIRM_FIELD_EDIT_CHANGES_AND_SYNC, confirmFieldEditChangesAndSyncHandler)
}

function* copyFileConfigurationWatcher() {
  yield takeLatest(Types.COPY_FILE_CONFIGURATION, copyFileConfigurationHandler)
}

function* createNewFileConfigurationWatcher() {
    yield takeLatest(Types.CREATE_NEW_FILE_CONFIGURATION, createNewFileConfigurationHandler)
}

function* updateFileConfigurationWatcher() { 
    yield takeLatest(Types.UPDATE_FILE_CONFIGURATION, updateFileConfigurationHandler) 
}

function* deleteFieldImportConfigAndSyncWatcher() {
    yield takeLatest(Types.DELETE_FIELD_IMPORT_CONFIG_AND_SYNC, deleteFieldImportConfigAndSyncHandler)
}

function* getFileTypeWatcher() {
    yield takeLatest(Types.GET_FILE_TYPE, getFileTypeHandler)
}

function* getFileConfigurationWatcher() {
    yield takeLatest(Types.GET_FILE_CONFIGURATION, getFileConfigurationHandler)
}

function* getFileConfigurationDashboardWatcher() {
    yield takeLatest(Types.GET_FILE_CONFIGURATION_DASHBOARD, getFileConfigurationDashboardHandler)
}

function* handleNextStepWatcher() {
    yield takeLatest(Types.HANDLE_NEXT_STEP, handleNextStepHandler)
}

function* prepFileConfigurationForSubmitWatcher() {
    yield takeLatest(Types.PREP_FILE_CONFIGURATION_FOR_SUBMIT, prepFileConfigurationForSubmitHandler)
}

function* setFieldImportConfigListFromSheetWatcher() {
    yield takeLatest(Types.SET_FIELD_IMPORT_CONFIG_LIST_FROM_SHEET, setFieldImportConfigListFromSheetHandler)
}

function* syncMappedFieldsWatcher() {
    yield takeLatest(Types.SYNC_MAPPED_FIELDS, syncMappedFieldsHandler)
}

function* syncWithServerWatcher() {
    yield takeLatest(Types.SYNC_WITH_SERVER, syncWithServerHandler)
}

function* testValidationWatcher() {
    yield takeLatest(Types.TEST_VALIDATION, testValidationHandler)
}

function* validateAndAppendFieldsWatcher() {
  yield takeLatest(Types.VALIDATE_AND_APPEND_FIELDS, validateAndAppendFieldsHandler)
}


/* HANDLERS */
function* confirmFieldEditChangesAndSyncHandler({ payload }) {
  try {
    yield put(FileConfigurationActions.confirmFieldEditChanges())
    yield call(syncWithServerHandler, { payload })
  } catch (err) {
    console.log(err)
  }
}

function* copyFileConfigurationHandler({ payload }) {
  try {
    const url = yield call(getServiceUrl, 'file-configuration');
    if (!payload) return
    const { file_id } = payload;
    if (!file_id) return;
    const fullFileConfigResponse = yield call(fetchPost, url, { action: CONFIG_ACTIONS.COPY, ...payload })
    if (fullFileConfigResponse.status !== "success") {
      throw new Error('Deleting file configuration failed')
    }
    delete fullFileConfigResponse.status;
    delete fullFileConfigResponse.message;
    yield put(FileConfigurationActions.updateFullFileConfiguration(fullFileConfigResponse))
  } catch (err) {
    console.log(err)
  }
}

function* createNewFileConfigurationHandler({ payload }) {
    try {
        const url = yield call(getServiceUrl, 'file-configuration-dashboard');
        const response = yield call(fetchPost, url, payload)
        if (response.status !== "success") {
          throw new Error('Creating a new file configuration failed')
        }
        delete response.status;
        delete response.message;
        const dashboardRecord = {
          file_name: response.file_name,
          file_type: response.file_type,
          client_type: response.client_type,
          status: response.status,
          entity_name: response.entity_name,
          file_config_name:response.file_config_name,
          client_hierarchy:response.client_hierarchy
        }
        yield put(FileConfigurationActions.updateDashboard(dashboardRecord))
        yield put(FileConfigurationActions.updateFullFileConfiguration(response))
      } catch (err) {
        console.log(err)
      } finally {
      }
}

function* updateFileConfigurationHandler({ payload }) {
  try {
    const rawFileConfigration = yield select(getFileConfiguration);
    const url = yield call(getServiceUrl, 'file-configuration-dashboard');
    if (!payload) return
    const { file_id } = payload;
    if (!file_id) return;
    const fullFileConfigResponse = yield call(fetchPut, url, payload)
    if (fullFileConfigResponse.status !== "success") {
      throw new Error('Deleting file configuration failed')
    }
    delete fullFileConfigResponse.status;
    delete fullFileConfigResponse.message;
    let file_status =  payload.file_status;

    if (payload.file_status === CONFIG_ACTIONS.SUBMIT) {
      yield put(FileConfigurationActions.updateFullFileConfiguration(fullFileConfigResponse))
      yield call(getFileTypeHandler, { payload: { file_direction: fullFileConfigResponse.file_direction, file_type: fullFileConfigResponse.file_type } });
      yield call(prepFileConfigurationForSubmitHandler);
      return;
    }
    let newDashboard = rawFileConfigration.dashboard;
    if (payload.file_status === CONFIG_ACTIONS.DELETE) {
      file_status =  CONFIG_STATUS.DELETED;
      newDashboard = rawFileConfigration.dashboard.filter((record) => record.file_id !== payload.file_id)
    }
    if (payload.file_status === CONFIG_ACTIONS.CANCEL) {
      file_status =  CONFIG_STATUS.CANCEL;
      newDashboard = rawFileConfigration.dashboard.map((record) => {
        if (record.file_id === payload.file_id) {
          return {
            ...record,
            file_status: CONFIG_STATUS.CANCEL,
          }
        }
        return record
      })
    }
    if (payload.file_status === CONFIG_ACTIONS.PUBLISH || payload.file_status === CONFIG_ACTIONS.REPUBLISH) {
      file_status =  CONFIG_STATUS.PUBLUSHED;
      newDashboard = rawFileConfigration.dashboard.map((record) => {
        if (record.file_id === payload.file_id) {
          return {
            ...record,
            file_status: CONFIG_STATUS.PUBLUSHED,
          }
        }
        return record
      })
    }
    fullFileConfigResponse.file_status = file_status;
    const body = { file_id, fileConfiguration: fullFileConfigResponse };
    const fileConfigURL = yield call(getServiceUrl, 'file-configuration');
    const showLoader = false;
    const response = yield call(fetchPut, fileConfigURL, body, showLoader)
    if (response.status !== "success") {
      throw new Error('Deleting file configuration failed')
    }
    yield put(FileConfigurationActions.updateDashboard(newDashboard))
  } catch (err) {
    console.log(err)
  }
}

function* deleteFieldImportConfigAndSyncHandler({ payload }) {
  try {
    yield put(FileConfigurationActions.deleteFieldImportConfig(payload))
    yield put(FileConfigurationActions.syncWithServer())
  } catch (err) {
    console.log(err)
  }
}

function* getFileTypeHandler({ payload }) {
    try {
        // background fetch
        if (!payload) return
        const fileConfigration = yield select(getFileConfiguration);
        const { file_direction, file_type, format = null } = payload;
        const key = payload.file_type + "_standard";
        if (format) {
          const fileTypeStandard = fileConfigration[key]
          const formatKey = payload.format + "_format_standard";
          if (fileConfigration[formatKey]) return;
          const supportedFormats = fileTypeStandard.supported_formats || [];
          const formatUsesDefaultFields = (supportedFormats.find((format) => format.format === payload.format)  || { fields: 'default' })?.fields?.toLowerCase() === 'default';
          if (formatUsesDefaultFields) return;
          const url = yield call(getServiceUrl, 'file-configuration');
          const showLoader = false;
          const response = yield call(fetchGet, url, { file_direction, file_type, format }, showLoader)
          if (response.status !== "success") {
            throw new Error('Request for file format standard failed')
          }
          const standard = response;
          const { fields, additional_fields } = standard
          if (fields.length === 0) {
            throw new Error('File format "' + payload.format + '" appears to not have standard fields defined')
          }
          yield put(FileConfigurationActions.setFileTypeStandard({ file_type, format, standard, fields, additional_fields }))
          return;
        }
        if (fileConfigration[key]) return;
        const url = yield call(getServiceUrl, 'file-configuration');
        const showLoader = false;
        const response = yield call(fetchGet, url, payload, showLoader)
        if (response.status !== "success") {
          throw new Error('Request for file type standard failed')
        }
        const standard = response;
        const { fields, additional_fields } = standard
        if (fields.length === 0) {
          throw new Error('File type "' + payload.file_type + '" appears to not have standard fields defined')
        }
        yield put(FileConfigurationActions.setFileTypeStandard({ file_type, standard, fields, additional_fields }))
      } catch (err) {
        console.log(err)
      } finally {
      }
}

function* getFileConfigurationHandler({ payload }) {
    try {
        // background fetch
        if (!payload) return
        const { file_id } = payload;
        if (!file_id) return;
        const url = yield call(getServiceUrl, 'file-configuration-dashboard');
        const response = yield call(fetchPut, url, payload)
        if (response.status !== "success") {
          throw new Error('Request to get file configuration failed')
        }
        delete response.status;
        delete response.message;
        yield put(FileConfigurationActions.updateFullFileConfiguration(response))
      } catch (err) {
        console.log(err)
      } finally {
      }
}

function* getFileConfigurationDashboardHandler() {
    try {
        const url = yield call(getServiceUrl, 'file-configuration-dashboard');
        const response = yield call(fetchGet, url)
        if (response.status !== "success") {
          throw new Error('Request for file configuration dashboard failed')
        }
        yield put(FileConfigurationActions.updateDashboard(response.files))
        yield put(FileConfigurationActions.setFileTypeListOptions(response.file_types))
      } catch (err) {
        console.log(err)
      } finally {
      }
}

function* prepFileConfigurationForSubmitHandler() {
  try {
    const rawFileConfigration = yield select(getFileConfiguration);
    const isInboundFile = rawFileConfigration.file_direction === "inbound";
    const importOrOutboundFormat = isInboundFile ? 'importFormat' : 'outboundFormat';
    const isFollowingFileStandard = rawFileConfigration[importOrOutboundFormat].import_format === "File Standard";
    const fileType = rawFileConfigration.file_type;
    const fileConfiguration = yield call(isolateFileConfigurationFields, rawFileConfigration)
    const format = rawFileConfigration[importOrOutboundFormat].import_file_type;
    const standard = rawFileConfigration[format + "_format_standard"] || rawFileConfigration[fileType + "_standard"];
    if (isFollowingFileStandard) {
      const fileStandardFields = standard.fields;
      const targetField = isInboundFile ? 'field_name' : 'target_field'
      fileConfiguration.fileMapping = fileStandardFields.map((field) => {
        const mappedField = {
          ...field,
          [targetField]: isInboundFile ? field.std_field_name : field.std_field,
        }
        if (field.fields && Array.isArray(field.fields)) {
          mappedField.fields = field.fields.map((subField) => {
            const mappedSubField = {
              ...subField,
              [targetField]: isInboundFile ? subField.std_field_name : subField.std_field,
            }
            return mappedSubField
          })
        }
        if (format === "834" && field in rawFileConfigration.ediMapping) {
          mappedField.loop_id = rawFileConfigration.ediMapping[field]
        }
        return mappedField
      })
    }
    const isOutboundFile = rawFileConfigration.file_direction === "outbound";
    if (isOutboundFile && !isFollowingFileStandard) {
      fileConfiguration.fileMapping = revertDataFromGrid(rawFileConfigration.outboundFormat.customOutboundFields || [], standard.fields)
    }
    fileConfiguration.file_status = "Ready for Review";
    const body = { file_id: fileConfiguration.file_id, fileConfiguration };
    const url = yield call(getServiceUrl, 'file-configuration');
    const showLoader = true;
    const response = yield call(fetchPut, url, body, showLoader)
    if (response.status !== "success") {
      throw new Error('File Configuration Sync Request Failed')
    }
    yield put(FileConfigurationActions.returnToDashboard())
  } catch (err) {
    console.log(err)
  }
}

function* syncMappedFieldsHandler({ payload }) {
  try {
    yield put(FileConfigurationActions.setFileMapping(payload))
    yield put(FileConfigurationActions.syncWithServer())
  } catch (err) {
    console.log(err)
  }
}

function* syncWithServerHandler({ payload }) {
  try {
    const rawFileConfigration = yield select(getFileConfiguration);
    const fileConfiguration = yield call(isolateFileConfigurationFields, rawFileConfigration)
    if (payload && payload.status) {
      fileConfiguration.file_status = payload.status
    }
    const body = { file_id: fileConfiguration.file_id, fileConfiguration };
    const url = yield call(getServiceUrl, 'file-configuration');
    const showLoader = false;
    const response = yield call(fetchPut, url, body, showLoader)
    if (response.status !== "success") {
      throw new Error('File Configuration Sync Request Failed')
    }
    if (payload && payload.status) {
      yield put(FileConfigurationActions.returnToDashboard())
    }
  } catch (err) {
    console.log(err)
  }
}


function* handleNextStepHandler() {
    try {
      const screen = yield call(getScreenName)
      if(!screen){
        yield put(FileConfigurationActions.nextPage())
      }     
      yield call(testValidationHandler, { payload: { screen } })
      const rawFileConfigration = yield select(getFileConfiguration);
      const screenvalidationResults = rawFileConfigration.validationResults[screen]
      if (screenvalidationResults.isAllValid) {
        yield put(FileConfigurationActions.nextPage())
      }
    } catch (err) {
    }
}

function* setFieldImportConfigListFromSheetHandler({ }) {
  const rawFileConfigration = yield select(getFileConfiguration);
  const uploadedFileData = rawFileConfigration['uploadedFileData'];
  const importFormat = rawFileConfigration['importFormat'];
  if (uploadedFileData) {
    const rawFields = uploadedFileData[0];
    const rawFirstRow = uploadedFileData[1] || [];
    const parentRecordID = uuidv4();
    const fields = [];
    for (let i = 0; i < rawFields.length; i++) {
      const field = rawFields[i] || '';
      // if (!field) break;
      let value = rawFirstRow[i];
      if (value === undefined && uploadedFileData.length > 2) {
        value = findNextValue(uploadedFileData, 2, i);
      }
      const id = uuidv4();
      const fieldType = value ? inferType(value) || inferTypeByHeaderName(field) : '';
      let fieldTypeFormat = '';
      if (fieldType === 'Number') {
        fieldTypeFormat = 'Integer';
      }
      if (fieldType=== 'Date') {
        fieldTypeFormat = 'YYYY-MM-DD';
      }
      const newField = {
        id,
        field_name: field,
        field_helper_text: '',
        field_size: field?.length,
        info: value,
        field_type: fieldType,
        field_type_format: fieldTypeFormat,
        field_starting_position: i+1,
        field_ending_position: i+1,
        field_transformation_note: '',
        field_format_instructions: '',
        parent_record_id: parentRecordID,
      };
      fields.push(newField);
    }
    const parentRecord = {
      id: parentRecordID,
      multi_record_detail_title: '',
      import_format: importFormat.import_format,
      record_type: importFormat.record_type,
      multi_record_detail: importFormat.multi_record_detail,
      fields: fields,
    }
    const defined_configuration_list = importFormat.defined_configuration_list;
    console.log({fields})
    defined_configuration_list.unshift(parentRecord);
    yield put(FileConfigurationActions.setDefinedConfigurationList(defined_configuration_list))
  }

}

function* validateAndAppendFieldsHandler({ payload }) {
  try {
    const screen = 'importFormatFields';
    const rawFileConfigration = yield select(getFileConfiguration);
    const importFormat = rawFileConfigration['importFormat'];
    const sectionToTest = {
      field_name: importFormat.field_name,
      field_helper_text: importFormat.field_helper_text,
      field_size: importFormat.field_size,
      field_type: importFormat.field_type,
      field_type_format: importFormat.field_type_format,
      field_starting_position: importFormat.field_starting_position,
      field_ending_position: importFormat.field_ending_position,
      field_transformation_note: importFormat.field_transformation_note,
      field_format_instructions: importFormat.field_format_instructions,
    }
    const validations = rawFileConfigration.validations[screen] || {};
    const fields = Object.keys(sectionToTest);
    let isAllValid = true;
    const validationResults = {}
    validationResults[screen] = fields.reduce((acc, field) => {
      const fieldValidation = validations[field];
      const fieldValue = sectionToTest[field];
      // field by default is valid, set to true if not required and no value
      acc[field] = true;
      if (!fieldValidation) return acc;
      const { type, validation, required } = fieldValidation;
      if (required || fieldValue) {
        acc[field] = validation(fieldValue) && typeof fieldValue === type;
      }
      if (!acc[field]) {
        isAllValid = false;
      }
      return acc;
    }, {})
    validationResults[screen].isAllValid = isAllValid;
    yield put(FileConfigurationActions.updateValidationResult(validationResults))
    const updatedRawFileConfigration = yield select(getFileConfiguration);
    const screenvalidationResults = updatedRawFileConfigration.validationResults['importFormatFields'] || {};
    if (screenvalidationResults.isAllValid) {
      yield put(FileConfigurationActions.appendFieldImportConfig())
      yield put(FileConfigurationActions.syncWithServer())
    }
  } catch (err) {
    console.log(err)
  }
}

function* testValidationHandler({ payload }) {
  const { screen } = payload;
  try {
    const rawFileConfigration = yield select(getFileConfiguration);
    const sectionToTest = rawFileConfigration[screen];
    const validations = rawFileConfigration.validations[screen] || {};
    const fields = Object.keys(sectionToTest);
    const fileDirection = rawFileConfigration.file_direction;
    let isAllValid = true;
    const validationResults = {}
    validationResults[screen] = fields.reduce((acc, field) => {
      const fieldValidation = validations[field];
      const fieldValue = sectionToTest[field];
      // field by default is valid, set to true if not required and no value
      acc[field] = true;
      if (!fieldValidation) return acc;
      const { direction, type, validation, required } = fieldValidation;
      const fieldNotInFileDirection = !(direction && direction[fileDirection]);
      if (fieldNotInFileDirection) return acc;
      if (required || fieldValue) {
        acc[field] = validation(fieldValue) && typeof fieldValue === type;
      }
      if (!acc[field]) {
        isAllValid = false;
      }
      return acc;
    }, {})
    validationResults[screen].isAllValid = isAllValid;
    yield put(FileConfigurationActions.updateValidationResult(validationResults))
  } catch (err) {
    console.log(err)
  }
}

/* UTILS */

function inferType(cell) {
  if (!isNaN(cell)) {
    return 'Number';
  } else if (Date.parse(cell)) {
    return 'Date';
  } else {
    return 'Text';
  }
};

function inferTypeByHeaderName(header) {
  if (header.toLowerCase().includes('date')) {
    return 'Date';
  }
  return 'Text';
}

function findNextValue(data = [], row, position) {
  if (data.length === 0) return '';
  for (let i = row; i < data.length; i++) {
    const nextRow = data[row];
    if (!nextRow) return '';
    const nextValue = nextRow[position];
    if (nextValue) return nextValue;
  }
  return '';
}

function createNewFieldCopy(field, addSubFields = true) {
  const { id, path = [], std_field, std_field_name, target_field, ...rest } = field;
  const copy = {
    ...rest,
    id,
    std_field_name,
    std_field,
    target_field: target_field || std_field,
    path: path.length < 1 ? std_field : path.join('.'),
  };
  if (addSubFields) {
    copy.fields = [];
  }
  delete copy["Standard Field"]
  delete copy["Standard Field Name"]
  return copy;
}

function getParentRecord(standardField, standardFields = []) {
  for (let i = 0; i < standardFields.length; ++i) {
    const field = standardFields[i];
    if (field.std_field === standardField) {
      return field;
    }
  }
}

function getParent(parentMap, path, fileMapping, standardFields) {
  let parent = parentMap.get(path[0])
  if (!parent) {
    const parentRecord = getParentRecord(path[0], standardFields);
    if (parentRecord) {
      parent = createNewFieldCopy(parentRecord);
      fileMapping.push(parent);
      parentMap.set(path[0], parent);
    }
  }
  return parent;
}

function revertDataFromGrid(flattenedData, standardFields) {
  const fileMapping = [];
  const parentMap = new Map();

  for (let i = 0; i < flattenedData.length; i++) {
    const item = flattenedData[i];
    const { path = [] } = item;
    if (path.length > 1) continue;
    const newItem = createNewFieldCopy(item);
    parentMap.set(path[0], newItem);
    fileMapping.push(newItem);
  }

  for (const item of flattenedData) {
    const { path = [] } = item;
    if (path.length < 2) continue;
    const parent = getParent(parentMap, path, fileMapping, standardFields);
    const addSubFields = false;
    const newItem = createNewFieldCopy(item, addSubFields);
    if (parent) {
      parent.fields = parent.fields || [];
      parent.fields.push(newItem);
    } else {
      fileMapping.push(newItem);
    }
  }

  for (const item of fileMapping) {
    // clean up childless fields
    if (item.fields && item.fields.length === 0) {
      delete item.fields;
    }
  }

  return fileMapping;
}

function* getServiceUrl(servicePath) {
    const { serviceUrl } = yield select(getAppState)
    const { api_path } = yield select(getApiPath, servicePath)
    const url = `${serviceUrl}${api_path}`
    return url;
}

function* getScreenName(override) {
  const rawFileConfigration = yield select(getFileConfiguration);
  const inboundFile = rawFileConfigration.file_direction === "inbound"  //bool
  const outboundFile = rawFileConfigration.file_direction === "outbound"
  const step = yield select(getFileConfigurationStep);
  if (inboundFile) {
    switch (override || (step + '')) {
      case '0':
        return 'clientInformation'
      case '1':
        return 'fileInformation'
      case '2':
        return 'emailInformation'
      case '3':
        return 'thresholdInformation'
      case '4':
        return 'responseParameter'
      case '5':
        return 'importFormat'
      case '6':
        return 'fileMapping'
      default:
        return ''
    }
  }

  if (outboundFile) {
    switch (override || (step + '')) {
      case '0':
        return 'clientInformation'
      case '1':
        return 'fileInformation'
      case '2':
        return 'emailInformation'
      case '5':
        return 'outboundFormat'
      default:
        return ''
    }
  }
  return "";
}

function* isolateFileConfigurationFields(rawFileConfigration) {
    const fileConfiguration = {
      client_type: rawFileConfigration.client_type,
      file_id: rawFileConfigration.file_id,
      entity_name: rawFileConfigration?.entity_name,
      file_direction: rawFileConfigration.file_direction,
      file_name: rawFileConfigration.file_name,
      file_status: rawFileConfigration.file_status,
      file_type: rawFileConfigration.file_type,
      clientInformation: rawFileConfigration.clientInformation,
      fileInformation: rawFileConfigration.fileInformation,
      emailInformation: rawFileConfigration.emailInformation,
      responseParameter: rawFileConfigration.responseParameter,
      thresholdInformation: rawFileConfigration.thresholdInformation,
      importFormat: rawFileConfigration.importFormat,
      outboundFormat: rawFileConfigration.outboundFormat,
      fileMapping: rawFileConfigration.fileMapping,
    };
    return fileConfiguration;
}
