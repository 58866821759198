import React from 'react';
import { Link } from "react-router-dom";
import FliptButton from "../form/fliptButton";

export default function HeaderLayout(props) {
    const {heading,nameofButton,link,showSearchBar=false,showfilter=false}=props;
  return (
    <div className="header">
    <p className="heading">{heading}</p>

    <div className="header_menu">
      {showSearchBar && <div className="searchContainer">
      <img src='/i/search.svg'/>
      </div>}
     { showfilter && <div className="filterContainer">
      <img src='/i/filters_lines.svg'/>
        <p>Filter</p>
      </div>}
      <div className="buttonContainer" align="right">
      <Link to={`/${link}`}>
        <FliptButton
          className="button searchButton"
          name={nameofButton}
        />
      </Link>
    </div>
    </div>          
  </div>
  )
}
