import React, { PureComponent,Component, useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import "./styles.scss";
import { Creators as AppActions } from "../../../redux/reducers/app";
import BreadCrumb from "../../../components/breadCrumb";
import FliptGrid from "../../../components/fliptGrid";
import FliptPaDropdown from "../../../components/form/fliptPaDropdown";
import FliptInput from "../../../components/form/fliptInput";
import Info from "./Info";
import FliptButton from "../../../components/form/fliptButton";
import { Creators as ApiActions } from '../../../redux/reducers/api/claimLookupManagement'

  class AdapSpapDashboard extends Component {
    constructor(props) {
      super(props)
      this.state = {
          form: {
            bin: '',
            pcn: '',
            group: '',
          },
          agGridRef: React.createRef(),
      }
  }

   _updateFields = (event) => {
    const { form } = this.state
    const { name, value } = event.currentTarget
    form[name] = value
    this.setState({ form })
  };

 handleClick = () => {
 const { form, agGridRef } = this.state
  agGridRef.current.api.refreshServerSideStore({ route: null, purge: true })
}

render() {
  const { form, agGridRef } = this.state
    const headers = [
    "prog_type",
    "group_id",
    "a_bin",
    "a_pcn",
    "a_bin_effective_start_date",
    "a_bin_effective_end_date",
    "b_bin",
    "b_pcn",
    "b_bin_effective_start_date",
    "b_bin_effective_end_date",
    "c_bin",
    "c_pcn",
    "c_bin_effective_start_date",
    "c_bin_effective_end_date",
    "prog_name",
    "program_website",
    "status_information",
    "create_date",
    "last_mod_date",
  ];
  const { actions } = this.props
  const cellRendererParams = {
    prog_type: {
      overrideHeader: "Program Type",
    },
    prog_name: {
      overrideHeader: "Program Name",
    },
  };
  const serverSideGridConfig = {
    rowModel: 'serverSide',
    serverSideStoreType: 'partial',
    cacheBlockSize: 20,
    pagination: true,
    paginationPageSize: 20,
}
const serverSideGridParams = {
    form: form,
    apiId: 'adap-spap-information',
    sagaToCall: actions.getAdapSpapTxns,
    headers: headers,
}


  return (
    <>
      <div id="adap-spap-dashboard">
        <div className="breadcrum-container">
          <BreadCrumb {...this.props} />
        </div>
        <div className="main-header">Batch DETAILS</div>
        <div className="fields-container">
          <div className="fields-client-header">
            <div className="field-label">
              <span>Bin</span>
              <Info content="It identifies the insurance provider who covers your prescription." position="top right" />
            </div>
            <FliptInput
              className="field-input"
              name="bin"
              placeholder="Bin"
              value={form.bin}
              onChange={this._updateFields}
            />
          </div>
          <div className="fields-client-header">
            <div className="field-label">
              <span>PCN</span>
              <Info content="It is a secondary identification number that health care providers and insurance providers use to route claims" position="top right" />
            </div>
            <FliptInput
              className="field-input"
              name="pcn"
              placeholder="PCN"
              value={form.pcn}
              onChange={this._updateFields}
            />
          </div>
          <div className="fields-client-header">
            <div className="field-label">
              <span>Group</span>
              <Info content="It specifies the specific group or employer-sponsored plan that the cardholder belongs to within a larger insurance or prescription benefit program" position="top right" />
            </div>
            <FliptInput
              className="field-input"
              name="group"
              placeholder="Group"
              value={form.group}
              onChange={this._updateFields}
            />
          </div>
          <div className="fields-client-header">
            <FliptButton
              name="Search"
              className="primary search-button"
              onClick={() => {
                this.handleClick()
              }}
            />
          </div>
        </div>
        <div className="content">
          <FliptGrid
            headers={headers}
            hideSideBar={false}
            serverSideGridConfig={serverSideGridConfig}
            serverSideGridParams={serverSideGridParams}
            cellRendererParams={cellRendererParams}
            agGridRef={agGridRef}
          />
        </div>
      </div>
    </>
  );
}
};

const mapStateToProps = (state) => ({
  state: {
  },
});

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
    ...ApiActions,
  };

  return {
    actions: bindActionCreators(allActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdapSpapDashboard);
