import React from 'react';
import FliptGrid from '../../../../components/fliptGrid'
import moment from 'moment';
import { LinkRenderer } from '../../../../components/fliptGrid/cellRenderers'
import { filterRowData } from '../../../../utils/utilities'

const Deductibles = ({ accumulator_details }) => {
    const headers = [
        'claim_type', 'flipt_member_id', 'claim_number', 'date_of_service', 'processed_date', 'claim_status', 'reason_code', 'pharmacy_name',
        'drug_name', 'drug_strength', 'quantity_dispensed', 'deductible_amount', 'oop_amount', 'patient_pay_amount',
        'client_employer_cost', 'deductible_remaining', 'out_of_pocket_remaining',
    ];

    const headerMapping = {
        startDate: 'processed_date',
    }

    const accumulatorData = accumulator_details.map((d) => ({
        ...filterRowData(d, headers, headerMapping),
        processed_date: d.startDate ? moment(d.startDate).format('MM/DD/YYYY HH:MM:SS') : '',
        flipt_member_id: d.cardholder_id || d.flipt_person_id,
        oop_amount: parseFloat(d?.oop_amount).toFixed(2),
        deductible_amount: parseFloat(d?.deductible_amount).toFixed(2),
        reason_code: d.claim_status === 'X' ? 'REVERSAL' : d.reason_code,
        patient_pay_amount: parseFloat(d?.patient_pay_amount).toFixed(2),
        deductible_remaining: parseFloat(d?.deductible_remaining).toFixed(2),
        out_of_pocket_remaining: parseFloat(d?.out_of_pocket_remaining).toFixed(2),
        client_employer_cost: parseFloat(d?.client_employer_cost).toFixed(2),
    }))

    const cellRendererParams = {
        claim_number: {
            cellRenderer: LinkRenderer,
            path: '/claim-lookup-management',
            searchArgs: { auth_id: 'claim_number' }
        },
        processed_date: {
            suppressFiltersToolPanel: true,
            menuTabs: ['generalMenuTab', 'columnsMenuTab'],
            sort: 'asc',
        },
        patient_pay_amount: {
            overrideHeader: 'Patient Pay'
        },
        out_of_pocket_remaining: {
            overrideHeader: 'OOP Remaining'
        },
        oop_amount: {
            overrideHeader: 'Amount Attributed to OOP'
        },
        deductible_amount: {
            overrideHeader: 'Amount Attributed to Deductible'
        },
        client_employer_cost: {
            overrideHeader: 'Plan Pay'
        },
    }

    return (
        <div className="content">
            <FliptGrid
                className="deductible-data"
                data={accumulatorData}
                headers={headers}
                cellRendererParams={cellRendererParams}
            />
        </div>
    )
}

export default React.memo(Deductibles);