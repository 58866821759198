import { isEmpty } from "lodash";
const validateKeys = {
  rebate_name: "Rebate Name",
  claim_processor: "Contract",
  effective_start_date: "Effective Start Date",
  effective_end_date: "Effective End Date"
};

const validateSystemKeys = {
  flipt_bpg_match: "Flipt Payer in System settings"
};

export const validate = (data) => {
  const validateKeysArray = Object.keys(validateKeys);
  const error = validateKeysArray.find((ele) => !(ele in data && data[ele]));
  return error ? `${validateKeys[error]} should not be empty.` : "";
};
export const validateSystem = (data) => {
  const validateKeysArray = Object.keys(validateSystemKeys);
  const error = validateKeysArray.find((ele) => !(ele in data && data[ele]));
  return error ? `${validateSystemKeys[error]} should not be empty.` : "";
};
