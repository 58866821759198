import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Creators as AppActions } from '../../../../redux/reducers/app'
import { Creators as AutoSuggestActions } from '../../../../redux/reducers/api/autoSuggest'
import { Creators as DrugLookupActions } from '../../../../redux/reducers/api/drugLookup'

import './styles.scss'
import BreadCrumb from '../../../../components/breadCrumb'
import FliptGrid from '../../../../components/fliptGrid'
import FliptInput from '../../../../components/form/fliptInput'
import FliptSearch from '../../../../components/form/fliptSearch'
import FliptButton from '../../../../components/form/fliptButton'

import { LinkRenderer } from '../../../../components/fliptGrid/cellRenderers'
import { filterRowData } from '../../../../utils/utilities'

class UMDrugLookup extends Component {
  constructor(props) {
    super(props)
    this.state = {
      form: {
        drug_name: '',
        gpi: '',
        ddid: '',
        ndc: '',
      },
      loading: false,
      minCharacters: 3,
      drugNames: [],
    }
  }

  componentDidMount() {
    const { actions } = this.props
    actions.clearDrugModuleData()
  }

  componentDidUpdate(prevProps) {
    const { props } = this
    const { state: { autoSuggestData } } = props
    const prevState = prevProps.state
    const currState = props.state
    if (prevState === currState) return

    this._updateAutoSuggestionResult(autoSuggestData['drug_name'])
  }

  _updateFields = (el, dateData) => {
    const { props, state } = this
    const { actions } = props
    const { minCharacters } = state
    const { name, value } = dateData || el.currentTarget
    this.setState((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        [name]: value,
      },
    }))

    if (name === 'drug_name' && value.length >= minCharacters) {
      actions.getAutoSuggestData({ search_string: value, search_in: 'fts_ndc_drugs', search_for: 'drug_name' })
      this.setState({ loading: true })
    }
  }

  _searchDrugModule = () => {
    const { props, state } = this
    const { actions } = props
    const { form } = state
    actions.clearDrugModuleData()
    actions.getDrugModuleData(form)
  }

  _onAutoSuggestionResultSelect = (e, data) => {
    const { form } = this.state
    const { name, result } = data
    form[name] = result.title

    this.setState({ form })
  }

  _updateAutoSuggestionResult(result) {
    const drugNames = result ? result.map((d) => ({ title: d })) : []

    this.setState({ loading: false, drugNames })
  }

  render() {
    const { state, props } = this
    const {
      form, loading, minCharacters, drugNames,
    } = state
    const {
      drug_name, gpi, ddid, ndc,
    } = form
    const { drugModuleData } = props.state
    const headers = [
      'tier', 'program', 'prior_authorization', 'quantity_limit', 'step_therapy', 'tier_id', 'program_id', 'pa_id', 'ql_id', 'st_id', 'tier_version', 'program_version', 'pa_version', 'ql_version', 'st_version',
    ]
    const headerMapping = {
      tier_doc_name: 'tier_doc_name',
      program_doc_name: 'program_doc_name',
      pa_doc_name: 'pa_doc_name',
      ql_doc_name: 'ql_doc_name',
      st_doc_name: 'st_doc_name',
      tier_name: 'tier',
      program_name: 'program',
      pa_name: 'prior_authorization',
      ql_name: 'quantity_limit',
      st_name: 'step_therapy',
    }
    const gridData = drugModuleData && drugModuleData.length ? drugModuleData.map(row => ({
      ...row,
      tier_doc_name: row.tier_name,
      program_doc_name: row.program_name,
      pa_doc_name: row.pa_name,
      ql_doc_name: row.ql_name,
      st_doc_name: row.st_name,
      tier: row.tier_name ? row.tier_name + ' || ' + row.tier_version : '',
      program: row.program_name ? row.program_name + ' || ' + row.program_version : '',
      prior_authorization: row.pa_name ? row.pa_name + ' || ' + row.pa_version : '',
      quantity_limit: row.ql_name ? row.ql_name + ' || ' + row.ql_version : '',
      step_therapy: row.st_name ? row.st_name + ' || ' + row.st_version : '',
    })).map((d) => ({
      ...filterRowData(d, headers, headerMapping),
    })) : []

    const cellRendererParams = {
      tier: {
        cellRenderer: LinkRenderer,
        path: '/formulary-tier',
        searchArgs: { doc_name: 'tier_doc_name' },
        state: { editMode: false, remote: true },
      },
      program: {
        cellRenderer: LinkRenderer,
        path: '/program-list',
        searchArgs: { doc_name: 'program_doc_name' },
        state: { editMode: false, remote: true },
      },
      prior_authorization: {
        cellRenderer: LinkRenderer,
        path: '/prior-authorization',
        searchArgs: { doc_name: 'pa_doc_name' },
        state: { editMode: false, remote: true },
      },
      quantity_limit: {
        cellRenderer: LinkRenderer,
        path: '/quantity-limit',
        searchArgs: { doc_name: 'ql_doc_name' },
        state: { editMode: false, remote: true },
      },
      step_therapy: {
        cellRenderer: LinkRenderer,
        path: '/step-therapy',
        searchArgs: { doc_name: 'st_doc_name' },
        state: { editMode: false, remote: true },
      },
      tier_id: {
        hide: true,
      },
      program_id: {
        hide: true,
      },
      pa_id: {
        hide: true,
      },
      ql_id: {
        hide: true,
      },
      st_id: {
        hide: true,
      },
      tier_version: {
        hide: true,
      },
      program_version: {
        hide: true,
      },
      pa_version: {
        hide: true,
      },
      ql_version: {
        hide: true,
      },
      st_version: {
        hide: true,
      },
    }

    return (
      <div id="drugLookup">
        <div className="section">
          <section className="drug-lookup-inputs-container spacing border-line shadow">
            <div className="drug-lookup-inputs">
              <span>Drug Name</span>
              {/* <FliptInput placeholder="Drug Name" name="drug_name" value={drug_name} onChange={this._updateFields} /> */}
              <FliptSearch className="drug-lookup-search" placeholder="Drug Name" name="drug_name" value={drug_name} results={drugNames} loading={loading} minCharacters={minCharacters} onResultSelect={(e, data) => this._onAutoSuggestionResultSelect(e, data)} onSearchChange={(e, dropdown) => this._updateFields(e, dropdown)} />
            </div>
            <div className="drug-lookup-inputs">
              <span>GPI</span>
              <FliptInput placeholder="GPI" name="gpi" value={gpi} onChange={this._updateFields} />
            </div>
            <div className="drug-lookup-inputs">
              <span>DDID</span>
              <FliptInput placeholder="DDID" name="ddid" value={ddid} onChange={this._updateFields} />
            </div>
            <div className="drug-lookup-inputs">
              <span>NDC</span>
              <FliptInput placeholder="NDC" name="ndc" value={ndc} onChange={this._updateFields} />
            </div>
          </section>
          <div className="searchDrugLookup">
            <FliptButton name="SEARCH DRUGS" className="primary searchButton" onClick={this._searchDrugModule} />
          </div>
          {!!gridData && !!gridData.length && (
            <section className="grid-container spacing border-line shadow">
              <FliptGrid
                data={gridData}
                headers={headers}
                cellRendererParams={cellRendererParams}
              />
            </section>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  state: {
    autoSuggestData: state.autoSuggest.autoSuggestData,
    drugModuleData: state.drugLookup.drugModuleData,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
    ...AutoSuggestActions,
    ...DrugLookupActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UMDrugLookup)
