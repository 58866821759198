import React, { PureComponent } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment-timezone'

import './styles.scss'
import BreadCrumb from '../../../../components/breadCrumb'
import Config from '../../../../config'
import FliptGrid from '../../../../components/fliptGrid'
import { LinkContainerRenderer, PHILoader } from '../../../../components/fliptGrid/cellRenderers'
import { Creators as ApiActions } from '../../../../redux/reducers/api/claimLookupManagement'
import {
  createQueryString, parseQueryString, filterRowData, convertDateTimeToDate,
} from '../../../../utils/utilities'

class ClaimDetails extends PureComponent {
  componentDidMount() {
    const { actions, state, history } = this.props
    const { auth_id } = parseQueryString(history.location.search)

    history.location.search = createQueryString({
      auth_id,
      limit: String(state.claimDetailsPerPage),
    })
    actions.clearClaimDetailData()
    // TODO FIX THIS. SO NO MORE SETTIMEOUT
    setTimeout(() => actions.getClaimDetailData(), Config.COMPONENT_DATA_LOAD_TIME)
  }

  // onPageChange = (e, data) => {
  //   const active_page = data.activePage
  //   const { actions, history, state } = this.props
  //   const { auth_id } = parseQueryString(history.location.search)

  //   history.location.search = createQueryString({
  //     auth_id,
  //     offset: String((active_page - 1) * state.claimDetailsPerPage),
  //     limit: String(state.claimDetailsPerPage),

  //   })
  //   // TODO - figure out how to remove settime out from here
  //   setTimeout(() => actions.getClaimDetailData(), Config.COMPONENT_DATA_LOAD_TIME)
  // }

  render() {
    // added the columns 'first_name', 'last_name', 'group', 'person_code', 'date_of_birth'
    const headers = [

      'action', 'sequenceNumber', 'processed_date', 'date_of_service', 'transaction_code', 'response_status', 'claim_status', 'flipt_reject_code', 'flipt_reject_message', 'payer_PCN', 'days_supply', 'submitted_quantity', 'drug_name', 'drug_strength',
      'first_name', 'last_name', 'group', 'person_code', 'date_of_birth', 'member_id',
      'transfer_PCN', 'claim_date', 'processor_control_number', 'group_id',
      'drug_dosage_form', 'multi_source', 'drug_mfg', 'otc',
      'erx_reject_message', 'client_drug_cost_paid', 'client_employer_cost', 'client_employee_paid',
      'deductible_accumulator_amount', 'deductible_remaining', 'oop_accumulator_amount', 'pharmacy_name', 'transactionId',
      'out_of_pocket_remaining', 'domain_name', 'gpi', 'product_id', 'plan_name', 'plan_year',
      'prescriber_id', 'service_provider_id', 'prescription_reference_number', 'ingredient_cost_submitted',
      'dispensing_fee_submitted', 'usual_and_customary_charge', 'gross_amount_due', 'basis_of_cost_determination',
      'fill_number', 'compound_code', 'date_prescription_written', 'carrier', 'group', 'days_of_supply'
    ]
    const { history, state } = this.props
    const { claimDetailData } = state
    const { auth_id } = parseQueryString(history.location.search)
    const headerMapping = {
      cardholder_id: 'member_id',
      drug_dosage: 'drug_dosage_form',
      drug_manufacturer: 'drug_mfg',
      otc_indicator: 'otc',
      processor_control_number: 'payer_PCN',
      quantity_dispensed: 'submitted_quantity',
      startDate: 'processed_date',
      transaction_response_status: 'response_status',
      transfer_pcn: 'transfer_PCN',
      benefit_plan_name: 'plan_name',
    }
    const cellRendererParams = {
      action: {
        cellRenderer: LinkContainerRenderer,
        searchArgs: [
          {
            path: '/claim-transaction-overview',
            searchArgs: { sequence_number: 'sequenceNumber' }, searchArgs: { sequence_number: 'sequenceNumber', plan_name: 'plan_name', transaction_id: 'transactionId' },
            staticSearchArgs: { auth_id },
            label: 'Transaction',
          },
          {
            path: '/claim-adjustment',
            searchArgs: {
              member_id: 'cardholder_id', domain: 'group_id', transaction_id: 'transactionId', sequence_number: 'sequenceNumber',
            },
            staticSearchArgs: { auth_id },
            state: {
              transfer_status: 'transfer_status',
              client_drug_cost_paid: 'client_drug_cost_paid',
              client_employer_cost: 'client_employer_cost',
              client_employee_paid: 'client_employee_paid',
              deductible_accumulator_amount: 'deductible_accumulator_amount',
              deductible_remaining: 'deductible_remaining',
              oop_accumulator_amount: 'oop_accumulator_amount',
              pharmacy_name: 'pharmacy_name',
              sequenceNumber: 'sequenceNumber',
              transactionId: 'transactionId',
              out_of_pocket_remaining: 'out_of_pocket_remaining',
              group_id: 'group_id',
              member_id: 'member_id',
              domain_name: 'domain_name'
            },
            staticState: {
              auth_id,
            },
            label: 'Adjustment',
          },
          {
            path: '/formulary-lookup',
            searchArgs: {},
            state: {
              account: 'domain_name',
              gpi: 'gpi',
              carrier: 'carrier',
              group: 'group',
              ndc: 'product_id',
              date_of_service: 'date_of_service',
              plan_name: 'plan_name',
              plan_year: 'plan_year',
              date_of_birth: "date_of_birth",
              days_of_supply: "days_of_supply"
            },
            label: 'Formulary Lookup',
            disable: claimDetailData[0]?.benefit_plan_name ? false : true,
          },
          {
            path: '/claim-override',
            searchArgs: { sequence_number: 'sequenceNumber' },
            staticSearchArgs: { auth_id },
            state: {
              sequence_number: 'sequenceNumber',
            },
            staticState: {
              auth_id,
            },
            label: 'Override',
          },
          {
            path: '/test-claim-generation',
            searchArgs: {},
            state: {
              cardholder_id: 'member_id',
              product_id: 'product_id',
              prescriber_id: 'prescriber_id',
              service_provider_id: 'service_provider_id',
              days_supply: 'days_supply',
              quantity_dispensed: 'submitted_quantity',
              prescription_reference_number: 'prescription_reference_number',
              ingredient_cost_submitted: 'ingredient_cost_submitted',
              dispensing_fee_submitted: 'dispensing_fee_submitted',
              usual_and_customary_charge: 'usual_and_customary_charge',
              gross_amount_due: 'gross_amount_due',
              basis_of_cost_determination: 'basis_of_cost_determination',
              fill_number: 'fill_number',
              compound_code: 'compound_code',
              date_prescription_written: 'date_prescription_written',
            },
            label: 'Test Claim',
          },
        ],
        width: 450,
        minWidth: 450,
      },
      first_name: {
        cellRenderer: PHILoader,
      },
      last_name: {
        cellRenderer: PHILoader,
      },
      group: {
        cellRenderer: PHILoader,
      },
      person_code: {
        cellRenderer: PHILoader,
      },
      date_of_birth: {
        cellRenderer: PHILoader,
      },
      client_drug_cost_paid: {
        hide: true,
      },
      client_employer_cost: {
        hide: true,
      },
      client_employee_paid: {
        hide: true,
      },
      deductible_accumulator_amount: {
        hide: true,
      },
      deductible_remaining: {
        hide: true,
      },
      oop_accumulator_amount: {
        hide: true,
      },
      pharmacy_name: {
        hide: true,
      },
      transactionId: {
        hide: true,
      },
      out_of_pocket_remaining: {
        hide: true,
      },
      processed_date: {
        filter: 'agDateColumnFilter',
        filterParams,
      },
      transaction_code: {
        overrideHeader: 'Claim Type'
      },
      prescriber_id: {
        hide: true,
      },
      service_provider_id: {
        hide: true,
      },
      prescription_reference_number: {
        hide: true,
      },
      ingredient_cost_submitted: {
        hide: true,
      },
      dispensing_fee_submitted: {
        hide: true,
      },
      usual_and_customary_charge: {
        hide: true,
      },
      gross_amount_due: {
        hide: true,
      },
      basis_of_cost_determination: {
        hide: true,
      },
      fill_number: {
        hide: true,
      },
      compound_code: {
        hide: true,
      },
      date_prescription_written: {
        hide: true,
      },
    }
    const claimData = claimDetailData && claimDetailData.length ? claimDetailData.map((d) => ({
      ...filterRowData(d, headers, headerMapping),
      processed_date: moment(`${d.startDate}Z`).tz('America/New_York').format('MM/DD/YYYY HH:mm:ss'),
    })) : []

    return (
      <div id="claimDetails">
        <BreadCrumb {...this.props} />
        <div className="header">Claim Details</div>
        <div className="content">
          <FliptGrid
            data={claimData}
            headers={headers}
            cellRendererParams={cellRendererParams}
            doAutoSize
          />
        </div>
      </div>
    )
  }
}

const filterParams = {
  /* eslint-disable-next-line */
  comparator: (filterLocalDateAtMidnight, cellValue) => {
    const dateAsString = cellValue
    if (dateAsString == null) return -1
    const dateParts = dateAsString.split(' ')[0].split('/')
    const cellDate = new Date(
      Number(dateParts[2]),
      Number(dateParts[0]) - 1,
      Number(dateParts[1]),
    )
    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0
    }

    if (cellDate < filterLocalDateAtMidnight) {
      return -1
    }

    if (cellDate > filterLocalDateAtMidnight) {
      return 1
    }
  },
  browserDatePicker: true,
  filterOptions: ['inRange', 'equals'],
  suppressAndOrCondition: true,
  inRangeInclusive: true,
}

const mapStateToProps = (state) => ({
  state: {
    claimDetailData: state.claimLookup.claimDetailData,
    totalRows: state.claimLookup.claimDetailTotalRows,
    claimDetailsPerPage: state.claimLookup.claimDetailsPerPage,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...ApiActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClaimDetails)
