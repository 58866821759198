import { v4 as uuidv4 } from 'uuid'
import Immutable from "seamless-immutable";
import { createActions, createReducer } from "reduxsauce";
import usStates from "./utils/states";

const INITIAL_DEFINE_IMPORT_CONFIGURATION_STATE = Immutable({
  multi_record_detail_title: "",
})

const INITIAL_DEFINE_IMPORT_CONFIGURATION_FIELDS = Immutable({
  field_name: "",
  field_helper_text: "",
  field_size: "",
  field_type: "",
  field_type_format: "",
  field_starting_position: "",
  field_ending_position: "",
  field_transformation_note: "",
  field_format_instructions: "",
})

export const INITIAL_STATE = Immutable({
  client_type: "",
  file_id: "",
  file_name: "",
  file_status: "",
  file_type: "",
  clientInformation: {
    client: "",
    type_of_client: "",
    client_proposed_go_live: "",
    client_first_test_file: "",
    client_first_name: "",
    client_last_name: "",
    client_title: "",
    client_phone_number: "",
    client_fax_number: "",
    client_email: "",
    client_address_1: "",
    client_address_2: "",
    client_city: "",
    client_state: "",
    client_zip_code: "",
    entity_name:""
  },
  fileInformation: {
    file_type: "",
    file_name: "",
    file_description: "",
    file_frequency: "",
    file_frequency_notes: "",
    file_load_type: "",
    file_expected_load_time: new Date(),
    time_zone: "",
    file_week_day:""
  },
  emailInformation: {
    client_email_notification_ind: "",
    client_notification_type: "",
    client_notification_emails: "",
    internal_email_notification_ind: "",
    internal_notification_type: "",
    internal_notification_emails: "",
    pbm_sftp_used: "",
    pbm_standard_sftp_structure_used: "",
    pbm_sftp_url: "",
    pbm_sftp_user_name: "",
    pbm_sftp_password: "",
    pbm_sftp_folder_structure: "",
    send_receieve_files_responsibility: "",
    technical_contact_file_type: "",
    technical_contact_file_name: "",
    technical_contact_phone_number: "",
    technical_contact_email: "",
    technical_contact_external_sftp_url: "",
    technical_contact_external_sftp_user_name: "",
    technical_contact_external_sftp_password: "",
    technical_contact_external_test_sftp_ind: "",
    technical_contact_external_test_sftp_url: "",
    technical_contact_external_test_sftp_user_name: "",
    technical_contact_external_test_password: "",
  },
  thresholdInformation: {
    hours_to_complete_processing: "",
    criteria_total_failure: "",
    criteria_adds_failure: "",
    criteria_change_failure: "",
    criteria_term_failure: "",
    criteria_minimum_failure: "",
    criteria_total_failure_type: "",
    criteria_adds_failure_type: "",
    criteria_change_failure_type: "",
    criteria_term_failure_type: "",
    criteria_minimum_failure_type: "",

  },
  responseParameter: {
    criteria_notification_type: "",
    criteria_response_format: "",
    criteria_response_file_type: "",
    hold_file_processing: "",
    hold_file_processing_reason: "",
    criteria_other_delimiter: "",
    criteria_other_format: "",
    criteria_delimiter_value:"",
    criteria_delimiter_other_notes:""
  },
  importFormat: {
    import_format: "",
    import_file_type: "",
    import_delimiter_value: "",
    import_header_trailer: "",
    import_end_of_character: "",
    import_end_of_character_value: "",
    import_excel_file_ind: "",
    import_excel_file: "",
    import_file_path: "",
    record_type: "",
    multi_record_detail: "",
    multi_record_detail_title: "",
    field_name: "",
    field_helper_text: "",
    field_size: "",
    field_type: "",
    field_starting_position: "",
    field_ending_position: "",
    field_transformation_note: "",
    field_format_instructions: "",
    custom_group_file_info: "",
    field_type_format: "",
    defined_configuration_list: [],
    import_delimiter_other_notes:""
  },
  ediMapping: {},
  fileMapping: [],
  options: {
    createFileConfig: {
      entityList: [
        {
          key: 1,
          text: "TPA exchange",
          value: "TPA exchange",
        },
        {
          key: 2,
          text: "Client",
          value: "Client",
        },
        {
          key: 3,
          text: "Broker",
          value: "Broker",
        },
        {
          key: 4,
          text: "Provider",
          value: "Provider",
        },
        {
          key: 5,
          text: "Vendor",
          value: "Vendor",
        },
      ],
      fileTypeList: [
        {
          key: 1,
          text: "Group File",
          value: "group",
        },
        {
          key: 2,
          text: "Eligibility File",
          value: "eligibility",
        },
        {
          key: 3,
          text: "834 File",
          value: "834",
        },
      ],
    },
    clientInformation: {
      clientType: [
        {
          key: 1,
          text: "Health Plan",
          value: "Health Plan",
        },
        {
          key: 2,
          text: "Client",
          value: "Client",
        },
        {
          key: 3,
          text: "TPA",
          value: "TPA",
        },
      ],
      stateList: usStates,
      fileType: [
        {
          key: 1,
          text: "834",
          value: "834",
        },
        {
          key: 2,
          text: "Delimited",
          value: "Delimited",
        },
        {
          key: 3,
          text: "JSON",
          value: "JSON",
        },
        {
          key: 4,
          text: "XML",
          value: "XML",
        },
        {
          key: 5,
          text: "Custom (other)",
          value: "Custom (other)",
        },
      ],
    },
    fileInformation: {
      clientType: [
        {
          key: 1,
          text: "Group File",
          value: "group",
        },
        {
          key: 2,
          text: "Eligibility File",
          value: "eligibility",
        },
      ],
      TimeZoneList: [
        {
          key: 1,
          text: "Pacific Daylight Time",
          value: "Pacific",
        },
        {
          key: 2,
          text: "Central Daylight Time",
          value: "Central",
        },
        {
          key: 2,
          text: "Eastern Daylight Time",
          value: "Eastern",
        },
        {
          key: 2,
          text: "Mountain Daylight Time",
          value: "Mountain",
        },
      ],
      dayList: [
        {
          key: 1,
          text: "Monday",
          value: "monday",
        },
        {
          key: 2,
          text: "Tuesday",
          value: "tuesday",
        },
        {
          key: 3,
          text: "Wednesday",
          value: "wednesday",
        },
        {
          key: 4,
          text: "Thrusday",
          value: "thrrusday",
        },
        {
          key: 5,
          text: "Friday",
          value: "friday",
        },
        {
          key: 6,
          text: "Saturday",
          value: "saturday",
        },
        {
          key: 7,
          text: "Sunday",
          value: "sunday",
        },
     
      ],
    },
    importFormat: {
      dateFormat: [
        {
          key: 1,
          text: "MM/DD/YYYY",
          value: "MM/DD/YYYY",
        },
        {
          key: 2,
          text: "YYYY-MM-DD",
          value: "YYYY-MM-DD",
        },
        {
          key: 3,
          text: "DD-MM-YYYY",
          value: "DD-MM-YYYY",
        },
        {
          key: 4,
          text: "YYYY/MM/DD",
          value: "YYYY/MM/DD",
        },
        {
          key: 5,
          ttext: "DD/MM/YYYY",
          value: "DD/MM/YYYY",
        },
        {
          key: 6,
          text: "YYYYMMDD",
          value: "YYYYMMDD",
        },
        {
          key: 7,
          text: "MMDDYYYY",
          value: "MMDDYYYY",
        },
        {
          key: 8,
          text: "DDMMYYYY",
          value: "DDMMYYYY",
        },
        {
          key: 9,
          text: "MMM DD, YYYY",
          value: "MMM DD, YYYY",
        },
        {
          key: 10,
          text: "DD MMM YYYY",
          value: "DD MMM YYYY",
        },
        {
          key: 11,
          text: "YYYY, MMM DD",
          value: "YYYY, MMM DD",
        },
        {
          key: 12,
          text: "YYYY-DD-MM",
          value: "YYYY-DD-MM",
        },
        {
          key: 13,
          text: "YYYY/DD/MM",
          value: "YYYY/DD/MM",
        },
        {
          key: 14,
          text: "MM-DD-YYYY",
          value: "MM-DD-YYYY",
        },
        {
          key: 15,
          text: "DD/MM/YY",
          value: "DD/MM/YY",
        },
        {
          key: 16,
          text: "MM/DD/YY",
          value: "MM/DD/YY",
        },
        {
          key: 17,
          text: "YY/MM/DD",
          value: "YY/MM/DD",
        },
        {
          key: 18,
          text: "YY-MM-DD",
          value: "YY-MM-DD",
        },
        {
          key: 19,
          text: "DD-MM-YY",
          value: "DD-MM-YY",
        },
        {
          key: 20,
          text: "YYDDMM",
          value: "YYDDMM",
        },
        {
          key: 21,
          text: "MMYYDD",
          value: "MMYYDD",
        },
        {
          key: 22,
          text: "DDMMYY",
          value: "DDMMYY",
        },
        {
          key: 23,
          text: "CCYY",
          value: "CCYY",
        },
        {
          key: 24,
          text: "CCYYMM",
          value: "CCYYMM",
        },
        {
          key: 25,
          text: "CCYYMMDD",
          value: "CCYYMMDD",
        },
        {
          key: 26,
          text: "CCYY-MM",
          value: "CCYY-MM",
        },
        {
          key: 27,
          text: "CCYY-MM-DD",
          value: "CCYY-MM-DD",
        },
        {
          key: 28,
          text: "CCYY/MM",
          value: "CCYY/MM",
        },
        {
          key: 29,
          text: "CCYY/MM/DD",
          value: "CCYY/MM/DD",
        },
      ],
      fieldTypes: [
        {
          key: 1,
          text: "Text",
          value: "Text",
        },
        {
          key: 2,
          text: "Number",
          value: "Number",
        },
        {
          key: 3,
          text: "Date",
          value: "Date",
        },
      ],
      numberFormat: [
        {
          key: 1,
          text: "Decimal",
          value: "Decimal",
        },
        {
          key: 2,
          text: "Integer",
          value: "Integer",
        },
        {
          key: 3,
          text: "Scientific Notation",
          value: "Scientific Notation",
        },
        {
          key: 4,
          text: "Percentage",
          value: "Percentage",
        },
        {
          key: 5,
          text: "Currency",
          value: "Currency",
        },
      ],
    },
  },
  importFormatState: {
    selectedFileHeader: {},
    fieldToModify: {}
  },
  outboundFormatState: {
    selectedFileHeader: {},
    fieldToModify: {}
  },
  dashboard: [],
  validations: {
    clientInformation: {
      integration_partner_name: {
        required: true,
        direction: { inbound: true, outbound: true },
        validation: (val) => (val + '').length >= 1,
        type: 'string',
        errorMessage: 'Integration partner name is required and must be at least 1 characters long',
      },
      client_first_name: {
        required: true,
        direction: { inbound: true, outbound: true },
        validation: (val) => (val + '').length >= 2,
        type: 'string',
        errorMessage: 'First name is required and must be at least 2 characters long',
      },
      client_last_name: {
        required: true,
        direction: { inbound: true, outbound: true },
        validation: (val) => (val + '').length >= 2,
        type: 'string',
        errorMessage: 'Last name is required and must be at least 2 characters long',
      },
      entity_name: {
        required: true,
        validation: (val) => (val + '').length >= 1,
        type: 'string',
        errorMessage: 'Integration Partner is required',
      },
      client_title: {
        required: false,
        direction: { inbound: true, outbound: true },
        validation: (val) => (val + '').length >= 2,
        type: 'string',
        errorMessage: 'Title is required and must be at least 2 characters long',
      },
      client_phone_number: {
        required: true,
        direction: { inbound: true, outbound: true },
        validation: (val) => (/^[0-9]{10,}$/g).test(val),
        type: 'string',
        errorMessage: 'Phone number is required and must be 10 digits long',
      },
      client_fax_number: {
        required: false,
        direction: { inbound: true },
        validation: (val) => (/^[0-9]{10,}$/g).test(val),
        type: 'string',
        errorMessage: 'Fax number must be 10 digits long',
      },
      client_email: {
        required: true,
        direction: { inbound: true, outbound: true },
        validation: (val) => (/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/g).test(val),
        type: 'string',
        errorMessage: 'Email is required and must be a valid email address',
      },
      client_address_1: {
        required: false,
        direction: { inbound: true, outbound: true },
        validation: (val) => (/^[a-zA-Z0-9\s\.,#-]+$/g).test(val),
        type: 'string',
        errorMessage: 'Address is required and must be a valid address',
      },
      client_address_2: {
        required: false,
        direction: { inbound: true, outbound: true },
        validation: (val) => (/^[a-zA-Z0-9\s\.,#-]+$/g).test(val),
        type: 'string',
        errorMessage: 'Address must be a valid address',
      },
      client_city: {
        required: false,
        direction: { inbound: true, outbound: true },
        validation: (val) => (val + '').length >= 2,
        type: 'string',
        errorMessage: 'City is required and must be at least 2 characters long',
      },
      client_state: {
        required: false,
        direction: { inbound: true, outbound: true },
        validation: (val) => (val + '').length >= 2,
        type: 'string',
        errorMessage: 'State is required and must be at least 2 characters long',
      },
      client_zip_code: {
        required: false,
        direction: { inbound: true, outbound: true },
        validation: (val) => (val + '').length === 5,
        type: 'string',
        errorMessage: 'Zip code is required and must be 5 digits long',
      },
    },
    fileInformation: {
      file_name: {
        required: true,
        direction: { inbound: true },
        validation: (val) => (val + '').length >= 2,
        type: 'string',
        errorMessage: 'File name is required and must be at least 2 characters long',
      },
      file_load_type: {
        required: true,
        direction: { inbound: true },
        validation: (val) => (val + '').length >= 1,
        type: 'string',
        errorMessage: 'File Load Type is required',
      },
      file_frequency: {
        required: true,
        direction: { inbound: true },
        validation: (val) => (val + '').length >= 1,
        type: 'string',
        errorMessage: 'File Frequency is required',
      },
      file_frequency_notes: {
        required: false,
        direction: { inbound: true },
        validation: (val) => (val + '').length >= 1,
        type: 'string',
        errorMessage: 'File Frequency is required',
      },
      outbound_start_date: {
        required: true,
        validation: (val) => (val + '').length >= 1,
        type: 'string',
        errorMessage: 'Coverage Start Date is required',
      },
      outbound_end_date: {
        required: true,
        validation: (val) => (val + '').length >= 1,
        type: 'string',
        errorMessage: 'Coverage End Date is required',
      },
      reader_service_validation_for_group: {
        required: true,
        validation: (val) => (val + '').length >= 1,
        type: 'string',
        errorMessage: 'Required: Should retroactive coverage dates be accepted for group and coverage?',
      },
      retro_dates_for_group_and_coverage: {
        required: true,
        validation: (val) => (val + '').length >= 1,
        type: 'string',
        errorMessage: 'Required: Should the groupd be validated',
      },
    },
    emailInformation: {
      client_email_notification_ind: {
        required: true,
        direction: { inbound: true },
        validation: (val) => (val + '').length >= 1,
        type: 'string',
        errorMessage: 'Client Email Notification is required',
      },
      client_error_email_notification_ind: {
        required: true,
        validation: (val) => (val + '').length >= 1,
        type: 'string',
        errorMessage: 'Client Email Notification is required',
      },
      internal_email_notification_ind: {
        required: true,
        direction: { inbound: true },
        validation: (val) => (val + '').length >= 1,
        type: 'string',
        errorMessage: 'Internal Email Notification is required',
      },
      send_receieve_files_responsibility: {
        required: true,
        direction: { inbound: true },
        validation: (val) => (val + '').length >= 1,
        type: 'string',
        errorMessage: 'How to Send/Receive is required',
      },
    },
    thresholdInformation: {
      hours_to_complete_processing: {
        required: true,
        direction: { inbound: true },
        validation: (val) => (val + '').length < 6 && (val + '').length > 0,
        type: 'string',
        errorMessage: 'Complete Processing is required and must be numeric value -01 thru 99',
      },
      criteria_total_failure: {
        required: true,
        direction: { inbound: true },
        validation: (val) => (val + '').length < 6 && (val + '').length > 0,
        type: 'string',
        errorMessage: 'Total Failure is required and a value up to 5 bytes',
      },
      criteria_adds_failure: {
        required: true,
        direction: { inbound: true },
        validation: (val) => (val + '').length < 6 && (val + '').length > 0,
        type: 'string',
        errorMessage: 'Adds Failure and must be numeric value -01 thru 99',
      },
      criteria_change_failure: {
        required: true,
        direction: { inbound: true },
        validation: (val) => (val + '').length < 6 && (val + '').length > 0,
        type: 'string',
        errorMessage: 'Change Failure is required and must be numeric value -01 thru 99',
      },
      criteria_term_failure: {
        required: true,
        direction: { inbound: true },
        validation: (val) => (val + '').length < 6 && (val + '').length > 0,
        type: 'string',
        errorMessage: 'Term Failure is required and must be numeric value -01 thru 99',
      },
      criteria_minimum_failure: {
        required: true,
        direction: { inbound: true },
        validation: (val) => (val + '').length < 6 && (val + '').length > 0,
        type: 'string',
        errorMessage: 'Minimum Failure is required and must be numeric value -01 thru 99',
      },
      criteria_notification_type: {
        required: true,
        direction: { inbound: true },
        validation: (val) => (val + '').length >= 1,
        type: 'string',
        errorMessage: 'Notification Type is required',
      },
      criteria_response_format: {
        required: true,
        direction: { inbound: true },
        validation: (val) => (val + '').length >= 1,
        type: 'string',
        errorMessage: 'Response Format is required',
      },
      criteria_response_file_type: {
        required: true,
        direction: { inbound: true },
        validation: (val) => (val + '').length >= 1,
        type: 'string',
        errorMessage: 'Response File Type is required',
      },
      criteria_total_failure_type: {
        required: true,
        direction: { inbound: true },
        validation: (val) => (val + '').length >= 1,
        type: 'string',
        errorMessage: 'Required',
      },
      criteria_adds_failure_type: {
        required: true,
        direction: { inbound: true },
        validation: (val) => (val + '').length >= 1,
        type: 'string',
        errorMessage: 'Required',
      },
      criteria_change_failure_type: {
        required: true,
        direction: { inbound: true },
        validation: (val) => (val + '').length >= 1,
        type: 'string',
        errorMessage: 'Required',
      },
      criteria_term_failure_type: {
        required: true,
        direction: { inbound: true },
        validation: (val) => (val + '').length >= 1,
        type: 'string',
        errorMessage: 'Required',
      },
      criteria_minimum_failure_type: {
        required: true,
        direction: { inbound: true },
        validation: (val) => (val + '').length >= 1,
        type: 'string',
        errorMessage: 'Required',
      },
    },
    responseParameter: {
      criteria_notification_type: {
        required: true,
        direction: { inbound: true },
        validation: (val) => (val + '').length >= 1,
        type: 'string',
        errorMessage: 'Notification Type is required',
      },
      criteria_response_format: {
        required: true,
        direction: { inbound: true },
        validation: (val) => (val + '').length >= 1,
        type: 'string',
        errorMessage: 'Response Format is required',
      },
      criteria_response_file_type: {
        required: true,
        direction: { inbound: true },
        validation: (val) => (val + '').length >= 1,
        type: 'string',
        errorMessage: 'Response File Type is required',
      },
    },
    importFormat: {
      import_format: {
        required: true,
        direction: { inbound: true },
        validation: (val) => (val + '').length >= 1,
        type: 'string',
        errorMessage: 'File format is required',
      },
      import_file_type: {
        required: true,
        direction: { inbound: true },
        validation: (val) => (val + '').length >= 1,
        type: 'string',
        errorMessage: 'Type of file is required',
      },
      import_header_trailer: {
        required: true,
        direction: { inbound: true },
        validation: (val) => (val + '').length >= 1,
        type: 'string',
        errorMessage: 'Header and Trailer is required',
      },
      import_end_of_character: {
        required: true,
        direction: { inbound: true },
        validation: (val) => (val + '').length >= 1,
        type: 'string',
        errorMessage: 'End of character is required',
      },
      import_excel_file_ind: {
        required: true,
        direction: { inbound: true },
        validation: (val) => (val + '').length >= 1,
        type: 'string',
        errorMessage: 'Client excel file  is required',
      },
    },
    importFormatFields: {
      field_name: {
        required: true,
        direction: { inbound: true },
        type: 'string',
        validation: (val) => (val + '').length >= 1,
        errorMessage: 'Field name is required',
      },
      field_helper_text: {
        required: false,
        direction: { inbound: true },
        type: 'string',
        errorMessage: '',
        validation: (val) => (val + '').length >= 1,
        errorMessage: 'Field helper text must be valid',
      },
      field_size: {
        required: true,
        direction: { inbound: true },
        type: 'string',
        errorMessage: '',
        validation: (val) => (val + '').length >= 1,
        errorMessage: 'Field size cannot be empty',
      },
      field_type: {
        required: true,
        direction: { inbound: true },
        type: 'string',
        errorMessage: '',
        validation: (val) => (val + '').length >= 1,
        errorMessage: 'Must select a field type',
      },
      field_type_format: {
        required: false,
        direction: { inbound: true },
        type: 'string',
        errorMessage: '',
        validation: (val) => (val + '').length >= 1,
        errorMessage: 'Must select a format field type',
      },
      field_starting_position: {
        required: false,
        direction: { inbound: true },
        type: 'string',
        errorMessage: '',
        validation: (val) => (val + '').length >= 1,
        errorMessage: 'Field starting position cannot be empty',
      },
      field_ending_position: {
        required: false,
        direction: { inbound: true },
        type: 'string',
        errorMessage: '',
        validation: (val) => (val + '').length >= 1,
        errorMessage: 'Field ending position cannot be empty',
      },
      field_transformation_note: {
        required: false,
        direction: { inbound: true },
        type: 'string',
        errorMessage: '',
        validation: (val) => (val + '').length >= 1,
        errorMessage: 'Field transformation note must be valid',
      },
      field_format_instructions: {
        required: false,
        direction: { inbound: true },
        type: 'string',
        errorMessage: '',
        validation: (val) => (val + '').length >= 1,
        errorMessage: 'Field format instructions must be valid',
      }
    }
  },
  validationResults: {},
  step: 0,
});

export const appendFieldImportConfig = (state = INITIAL_STATE, { payload }) => {
  const { importFormat } = state;
  const { importFormatState } = state;
  const { selectedFileHeader } = importFormatState;
  const { multi_record_detail_title: override_multi_record_detail_title, id: override_parent_id } = selectedFileHeader || {};
  const defined_configuration_list = importFormat.defined_configuration_list || [];
  const id = uuidv4();
  const parentRecordID = uuidv4();
  const searchByDetailTitle = importFormat.multi_record_detail === 'Yes';
  let fieldTypeFormat = importFormat.field_type_format;
  if (importFormat.field_type === 'Number' && !fieldTypeFormat) {
    fieldTypeFormat = 'Integer';
  }
  if (importFormat.field_type === 'Date' && !fieldTypeFormat) {
    fieldTypeFormat = 'MM/DD/YYYY';
  }
  const newField = {
    id,
    field_name: importFormat.field_name,
    field_helper_text: importFormat.field_helper_text,
    field_size: importFormat.field_size,
    field_type: importFormat.field_type,
    field_type_format: fieldTypeFormat,
    field_starting_position: importFormat.field_starting_position,
    field_ending_position: importFormat.field_ending_position,
    field_transformation_note: importFormat.field_transformation_note,
    field_format_instructions: importFormat.field_format_instructions,
    parent_record_id: parentRecordID,
  };

  if (defined_configuration_list.length === 0) {
    const parentRecord = {
      id: parentRecordID,
      multi_record_detail_title: importFormat.multi_record_detail_title || '',
      import_format: importFormat.import_format,
      record_type: importFormat.record_type,
      multi_record_detail: importFormat.multi_record_detail,
      fields: [newField],
    }
    const newImportFormat = {
      ...importFormat,
      ...INITIAL_DEFINE_IMPORT_CONFIGURATION_FIELDS,
      defined_configuration_list: [parentRecord],
    };
    return {
      ...state,
      importFormat: newImportFormat,
    };
  }
  let parentRecordPosition = 0;
  if (searchByDetailTitle) {
    parentRecordPosition = defined_configuration_list.findIndex(parentRecord => parentRecord.multi_record_detail_title === importFormat.multi_record_detail_title)
  }

  if (override_parent_id) {
    parentRecordPosition = defined_configuration_list.findIndex(parentRecord => parentRecord.id === override_parent_id)
  }
  let parentRecord = null;
  if (parentRecordPosition === -1) {
    parentRecord = {
      id: parentRecordID,
      multi_record_detail_title: importFormat.multi_record_detail_title || '',
      import_format: importFormat.import_format,
      record_type: importFormat.record_type,
      multi_record_detail: importFormat.multi_record_detail,
      fields: [newField],
    }
    defined_configuration_list.push(parentRecord);
  } else {
    parentRecord = defined_configuration_list[parentRecordPosition]
    newField.parent_record_id = parentRecord.id;
    parentRecord.fields.push(newField)
  }

  const newImportFormat = {
    ...importFormat,
    ...INITIAL_DEFINE_IMPORT_CONFIGURATION_FIELDS,
    defined_configuration_list,
  };
  return {
    ...state,
    importFormat: newImportFormat,
  };
}

export const deleteFieldImportConfig = (state = INITIAL_STATE, { payload }) => {
  const { importFormat } = state;
  const { defined_configuration_list } = importFormat;
  const { parent_record_id, id } = payload;
  const parentRecordPosition = defined_configuration_list.findIndex(parentRecord => parentRecord.id === parent_record_id)
  const parentRecord = defined_configuration_list[parentRecordPosition]
  parentRecord.fields = parentRecord.fields.filter(field => field.id !== id)
  const newImportFormat = {
    ...importFormat,
    defined_configuration_list,
  };
  return {
    ...state,
    importFormat: newImportFormat,
  }
}

export const deleteCustomOutboundField = (state = INITIAL_STATE, { payload }) => {
  const { outboundFormat } = state;
  const { customOutboundFields = [] } = outboundFormat;
  const newCustomOutboundFields = customOutboundFields.filter((field, position) => position !== payload)
  return {
    ...state,
    outboundFormat: {
      ...state.outboundFormat,
      customOutboundFields: newCustomOutboundFields,
    }
  }
}

export const setDefinedConfigurationList = (state = INITIAL_STATE, { payload }) => {
  return {
    ...state,
    importFormat: {
      ...state.importFormat,
      defined_configuration_list: payload,
    }
  }
}

export const setFieldToEdit = (state = INITIAL_STATE, { payload }) => {
  return {
    ...state,
    importFormatState: {
      ...state.importFormatState,
      fieldToModify: payload,
    }
  }
}

export const setCustomOutboundFields = (state = INITIAL_STATE, { payload = [] }) => {
  const { outboundFormat = {} } = state;
  const { customOutboundFields = [] } = outboundFormat;
  const newCustomOutboundFields = [];
  const customOutboundFieldsSet = new Set();
  customOutboundFields.forEach((field) => {
    if (!customOutboundFieldsSet.has(field.std_field)) {
      customOutboundFieldsSet.add(field.std_field);
      newCustomOutboundFields.push(field);
    }
  });
  payload.forEach((field) => {
    if (!customOutboundFieldsSet.has(field.std_field)) {
      customOutboundFieldsSet.add(field.std_field);
      newCustomOutboundFields.push(field);
    }
  });
  newCustomOutboundFields.sort((a, b) => a.position - b.position)
  return {
    ...state,
    outboundFormat: {
      ...state.outboundFormat,
      customOutboundFields: newCustomOutboundFields,
    }
  }
}

export const updateCustomOutboundFields = (state = INITIAL_STATE, { payload = {} }) => {
  const {index, item} = payload;
  const { outboundFormat = {} } = state;
  const { customOutboundFields = [] } = outboundFormat;
  const newCustomOutboundFields = [...customOutboundFields];
  newCustomOutboundFields[index] = item;
  return {
    ...state,
    outboundFormat: {
      ...state.outboundFormat,
      customOutboundFields: newCustomOutboundFields,
    }
  }
}

export const updateEditFieldsForm = (state = INITIAL_STATE, { payload }) => {
  const { importFormatState } = state;
  const { name, value } = payload;
  return {
    ...state,
    importFormatState: {
      ...importFormatState,
      fieldToModify: {
        ...importFormatState.fieldToModify,
        [name]: value,
      }
    },
  };
}

export const confirmFieldEditChanges = (state = INITIAL_STATE, { payload }) => {
  const { importFormatState } = state;
  const { fieldToModify } = importFormatState;
  const { defined_configuration_list } = state.importFormat;
  const { parent_record_id } = fieldToModify;
  const parentRecordPosition = defined_configuration_list.findIndex(parentRecord => parentRecord.id === parent_record_id)
  const parentRecord = defined_configuration_list[parentRecordPosition]
  const fieldPosition = parentRecord.fields.findIndex(field => field.id === fieldToModify.id)
  parentRecord.fields[fieldPosition] = fieldToModify;
  const newImportFormat = {
    ...state.importFormat,
    defined_configuration_list,
  };
  return {
    ...state,
    importFormat: newImportFormat,
    importFormatState: {
      ...state.importFormatState,
      fieldToModify: {},
    }
  }
}

export const setFileImportHeaderSelection = (state = INITIAL_STATE, { payload }) => {
  return {
    ...state,
    importFormat: {
      ...state.importFormat,
      multi_record_detail_title: payload.multi_record_detail_title,
    },
    importFormatState: {
      ...state.importFormatState,
      selectedFileHeader: payload,
    }
  }
}

export const createNewRecordType = (state = INITIAL_STATE, { payload }) => {
  const { importFormat } = state;
  const newImportFormat = {
    ...importFormat,
    ...INITIAL_DEFINE_IMPORT_CONFIGURATION_STATE,
    ...INITIAL_DEFINE_IMPORT_CONFIGURATION_FIELDS,
  };
  return {
    ...state,
    importFormatState: {
      ...state.importFormatState,
      selectedFileHeader: {},
    },
    importFormat: newImportFormat,
  };
}

export const removeImportConfig = (state = INITIAL_STATE, { payload }) => {
  return {
    ...state,
    importFormat: {
      ...state.importFormat,
      defined_configuration_list: state.importFormat.defined_configuration_list.filter((item) => item.id !== payload),
    },
  }
}

export const nextPage = (state = INITIAL_STATE) => (
  {
    ...state,
    step: state.step + 1,
  }
)

export const prevPage = (state = INITIAL_STATE) => (
  {
    ...state,
    step: state.step - 1,
  }
)

export const updateClientInformationForm = (
  state = INITIAL_STATE,
  { payload }
) => {
  const { clientInformation } = state;
  const { name, value } = payload;
  return {
    ...state,
    clientInformation: {
      ...clientInformation,
      [name]: value,
    },
  };
};

export const updateFileInformationForm = (
  state = INITIAL_STATE,
  { payload }
) => {
  const { fileInformation } = state;
  const { name, value } = payload;
  return {
    ...state,
    fileInformation: {
      ...fileInformation,
      [name]: value,
    },
  };
};

export const updateEmailInformationForm = (
  state = INITIAL_STATE,
  { payload }
) => {
  const { emailInformation } = state;
  const { name, value } = payload;
  return {
    ...state,
    emailInformation: {
      ...emailInformation,
      [name]: value,
    },
  };
};
export const updateThresholdValidationForm = (
  state = INITIAL_STATE,
  { payload }
) => {
  const { thresholdInformation } = state;
  const { name, value } = payload;
  return {
    ...state,
    thresholdInformation: {
      ...thresholdInformation,
      [name]: value,
    },
  };
};
export const updateResponseFileParameterForm = (
  state = INITIAL_STATE,
  { payload }
) => {
  const { responseParameter } = state;
  const { name, value } = payload;
  return {
    ...state,
    responseParameter: {
      ...responseParameter,
      [name]: value,
    },
  };
};

//
export const updateImportFormatForm = (state = INITIAL_STATE, { payload }) => {
  const { importFormat } = state;
  const { name, value } = payload;
  return {
    ...state,
    importFormat: {
      ...importFormat,
      [name]: value,
    },
  };
};

export const updateOutboundFormatForm = (state = INITIAL_STATE, { payload }) => {
  const { outboundFormat } = state;
  const { name, value } = payload;
  return {
    ...state,
    outboundFormat: {
      ...outboundFormat,
      [name]: value,
    },
  };
};

export const setClientInformationClientType = (
  state = INITIAL_STATE,
  { payload }
) => ({
  ...state,
  clientInformation: {
    ...state.clientInformation,
    clientType: payload,
  },
});

export const setClientInformationFileType = (
  state = INITIAL_STATE,
  { payload }
) => ({
  ...state,
  clientInformation: {
    ...state.clientInformation,
    fileType: payload,
  },
});

export const setClientInformationStateList = (
  state = INITIAL_STATE,
  { payload }
) => ({
  ...state,
  clientInformation: {
    ...state.clientInformation,
    stateList: payload,
  },
});

export const setFileInformationClientType = (
  state = INITIAL_STATE,
  { payload }
) => ({
  ...state,
  fileInformation: {
    ...state.fileInformation,
    clientType: payload,
  },
});

export const setFileInformationTimeZoneList = (
  state = INITIAL_STATE,
  { payload }
) => ({
  ...state,
  fileInformation: {
    ...state.fileInformation,
    TimeZoneList: payload,
  },
});

export const setFileMapping = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  fileMapping: payload,
})

export const setStep = (state = INITIAL_STATE, { payload }) => (
  {
    ...state,
    step: payload,
  }
)

export const setUploadedFileData = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  uploadedFileData: payload,
});

export const setFileTypeStandard = (state = INITIAL_STATE, { payload }) => {
  const { file_type, format, standard, additional_fields } = payload;
  const key = format ? format + "_format_standard" : file_type + "_standard";
  return {
    ...state,
    [key]: standard,
    additional_fields: additional_fields
  };
};

export const returnToDashboard = (state = INITIAL_STATE, { payload }) => {
  return {
    ...INITIAL_STATE,
    dashboard: state.dashboard,
  };
};

export const updateDashboard = (state = INITIAL_STATE, { payload }) => {
  if (!payload) return;
  let newDashboard = state.dashboard;
  if (Array.isArray(payload)) {
    newDashboard = payload;
  } else {
    const filteredDashboard = state.dashboard.filter(record => record.file_id !== payload?.file_id);
    return {
      ...state,
      dashboard: [...filteredDashboard, payload]
    }
  }
  return {
    ...state,
    dashboard: newDashboard,
  };
};

export const setEdiMapping = (state = INITIAL_STATE, { payload }) => {
  const { name, value } = payload;
  return {
    ...state,
    ediMapping: {
      ...state.ediMapping,
      [name]: value,
    },
  }
}

export const setFileTypeListOptions = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  options: {
    ...state.options,
    createFileConfig: {
      ...state.options.createFileConfig,
      fileTypeList: payload,
    },
  }
})

export const updateFullFileConfiguration = (
  state = INITIAL_STATE,
  { payload }
) => ({
  ...state,
  ...payload,
  entity_name: payload? payload.value : '',
});

export const updateValidationResult = (
  state = INITIAL_STATE,
  { payload }
) => ({
  ...state,
  validationResults: {
    ...state.validationResults,
    ...payload,
  },
});

export const { Types, Creators } = createActions({
  appendFieldImportConfig: ["payload"],
  confirmFieldEditChanges: ["payload"],
  confirmFieldEditChangesAndSync: ["payload"],
  copyFileConfiguration: ["payload"],
  createNewFileConfiguration: ["payload"],
  createNewRecordType: ['payload'],
  updateFileConfiguration: ['payload'],
  deleteFileConfiguration: ["payload"],
  deleteFieldImportConfig: ["payload"],
  deleteFieldImportConfigAndSync: ["payload"],
  deleteCustomOutboundField: ["payload"],
  getFileConfiguration: ["payload"],
  getFileConfigurationDashboard: ["payload"],
  getFileType: ["payload"],
  handleNextStep: ["payload"],
  prepFileConfigurationForSubmit: ["payload"],
  syncMappedFields: ["payload"],
  nextPage: ["payload"],
  prevPage: ["payload"],
  removeImportConfig: ["payload"],
  returnToDashboard: ["payload"],
  setClientInformationClientType: ["payload"],
  setClientInformationFileType: ["payload"],
  setClientInformationStateList: ["payload"],
  setDefinedConfigurationList: ["payload"],
  setEdiMapping: ["payload"],
  setFieldImportConfigListFromSheet: ["payload"],
  setFieldToEdit: ["payload"],
  setFileImportHeaderSelection: ["payload"],
  setFileInformationClientType: ["payload"],
  setFileInformationTimeZoneList: ["payload"],
  setFileMapping: ["payload"],
  setFileTypeListOptions: ["payload"],
  setFileTypeStandard: ["payload"],
  setCustomOutboundFields: ["payload"],
  setStep: ["payload"],
  setUploadedFileData: ["payload"],
  syncWithServer: ["payload"],
  testValidation: ["payload"],
  updateClientInformationForm: ["payload"],
  updateCustomOutboundFields: ["payload"],
  updateDashboard: ["payload"],
  updateEditFieldsForm: ["payload"],
  updateFileInformationForm: ["payload"],
  updateEmailInformationForm: ["payload"],
  updateThresholdValidationForm: ["payload"],
  updateResponseFileParameterForm: ["payload"],
  updateImportFormatForm: ["payload"],
  updateOutboundFormatForm: ["payload"],
  updateFullFileConfiguration: ["payload"],
  updateValidationResult: ["payload"],
  validateAndAppendFields: ["payload"],
});

const HANDLERS = {
  [Types.APPEND_FIELD_IMPORT_CONFIG]: appendFieldImportConfig,
  [Types.CONFIRM_FIELD_EDIT_CHANGES]: confirmFieldEditChanges,
  [Types.CREATE_NEW_RECORD_TYPE]: createNewRecordType,
  [Types.DELETE_FIELD_IMPORT_CONFIG]: deleteFieldImportConfig,
  [Types.DELETE_CUSTOM_OUTBOUND_FIELD]: deleteCustomOutboundField,
  [Types.SET_FIELD_TO_EDIT]: setFieldToEdit,
  [Types.NEXT_PAGE]: nextPage,
  [Types.PREV_PAGE]: prevPage,
  [Types.REMOVE_IMPORT_CONFIG]: removeImportConfig,
  [Types.RETURN_TO_DASHBOARD]: returnToDashboard,
  [Types.SET_CLIENT_INFORMATION_CLIENT_TYPE]: setClientInformationClientType,
  [Types.SET_CLIENT_INFORMATION_FILE_TYPE]: setClientInformationFileType,
  [Types.SET_CLIENT_INFORMATION_STATE_LIST]: setClientInformationStateList,
  [Types.SET_DEFINED_CONFIGURATION_LIST]: setDefinedConfigurationList,
  [Types.SET_EDI_MAPPING]: setEdiMapping,
  [Types.SET_FILE_IMPORT_HEADER_SELECTION]: setFileImportHeaderSelection,
  [Types.SET_FILE_INFORMATION_CLIENT_TYPE]: setFileInformationClientType,
  [Types.SET_FILE_INFORMATION_TIME_ZONE_LIST]: setFileInformationTimeZoneList,
  [Types.SET_FILE_MAPPING]: setFileMapping,
  [Types.SET_FILE_TYPE_LIST_OPTIONS]: setFileTypeListOptions,
  [Types.SET_FILE_TYPE_STANDARD]: setFileTypeStandard,
  [Types.SET_CUSTOM_OUTBOUND_FIELDS]: setCustomOutboundFields,
  [Types.SET_STEP]: setStep,
  [Types.SET_UPLOADED_FILE_DATA]: setUploadedFileData,
  [Types.UPDATE_CLIENT_INFORMATION_FORM]: updateClientInformationForm,
  [Types.UPDATE_CUSTOM_OUTBOUND_FIELDS]: updateCustomOutboundFields,
  [Types.UPDATE_DASHBOARD]: updateDashboard,
  [Types.UPDATE_EDIT_FIELDS_FORM]: updateEditFieldsForm,
  [Types.UPDATE_FILE_INFORMATION_FORM]: updateFileInformationForm,
  [Types.UPDATE_EMAIL_INFORMATION_FORM]: updateEmailInformationForm,
  [Types.UPDATE_THRESHOLD_VALIDATION_FORM]: updateThresholdValidationForm,
  [Types.UPDATE_RESPONSE_FILE_PARAMETER_FORM]: updateResponseFileParameterForm,
  [Types.UPDATE_IMPORT_FORMAT_FORM]: updateImportFormatForm,
  [Types.UPDATE_OUTBOUND_FORMAT_FORM]: updateOutboundFormatForm,
  [Types.UPDATE_FULL_FILE_CONFIGURATION]: updateFullFileConfiguration,
  [Types.UPDATE_VALIDATION_RESULT]: updateValidationResult,
};

export default createReducer(INITIAL_STATE, HANDLERS);

