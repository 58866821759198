import Immutable from 'seamless-immutable'
import { createActions, createReducer } from 'reduxsauce'

import { convertDateTimeToDate } from '../../../utils/utilities'

export const INITIAL_STATE = Immutable({
  allQuantityLimitData: [],
  quantityLimitCondData: [],
  quantityLimitCriteriaCondData: [],
  quantityLimitCriteriaIdData: [],
  quantityLimitData: {},
  quantityLimitDrugFiltersData: {},
})

export const clearReducer = () => INITIAL_STATE

export const clearQuantityLimitReducer = () => INITIAL_STATE

export const setAllQuantityLimitDataReducer = (state = INITIAL_STATE, { payload }) => {
  const d = payload?.data.map((row) => ({
    ...row,
    effective_end_date: convertDateTimeToDate(row.effective_end_date),
    effective_start_date: convertDateTimeToDate(row.effective_start_date),
  }))
  return {
    ...state,
    allQuantityLimitData: d,
  }
}

export const setQuantityLimitDrugFiltersDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  quantityLimitDrugFiltersData: payload,
})

export const setQuantityLimitDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  quantityLimitCriteriaCondData: payload.criteria_conditions,
  quantityLimitCriteriaIdData: payload.criteria_ids,
  quantityLimitCondData: payload.ql_conditions,
  quantityLimitData: payload.ql_info,
})

export const clearQuantityLimitDataReducer = (state = INITIAL_STATE) => ({
  ...state,
  quantityLimitCriteriaCondData: [],
  quantityLimitCriteriaIdData: [],
  quantityLimitCondData: [],
  quantityLimitData: {},
})

export const { Types, Creators } = createActions({
  approveQuantityLimit: ['payload'],
  clearQuantityLimit: null,
  clearQuantityLimitData: null,
  clearReducer: null,
  generateQuantityLimit: ['payload'],
  generateQuantityLimitLevelExport: ['payload', 'callback'],
  getAllQuantityLimitData: null,
  getQuantityLimitData: ['payload'],
  saveQuantityLimitData: ['payload'],
  setAllQuantityLimitData: ['payload'],
  setQuantityLimitData: ['payload'],
  setQuantityLimitDrugFiltersData: ['payload'],
})

const HANDLERS = {
  [Types.CLEAR_QUANTITY_LIMIT]: clearQuantityLimitReducer,
  [Types.CLEAR_QUANTITY_LIMIT_DATA]: clearQuantityLimitDataReducer,
  [Types.CLEAR_REDUCER]: clearReducer,
  [Types.SET_ALL_QUANTITY_LIMIT_DATA]: setAllQuantityLimitDataReducer,
  [Types.SET_QUANTITY_LIMIT_DATA]: setQuantityLimitDataReducer,
  [Types.SET_QUANTITY_LIMIT_DRUG_FILTERS_DATA]: setQuantityLimitDrugFiltersDataReducer,
}

export default createReducer(INITIAL_STATE, HANDLERS)
