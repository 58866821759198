import Immutable from 'seamless-immutable'
import { createActions, createReducer } from 'reduxsauce'

import { convertDateTimeToDate } from '../../../utils/utilities'

export const INITIAL_STATE = Immutable({
  allProgramListData: [],
  programListCondData: [],
  programListData: {},
  programListDrugFiltersData: {},
  programListCriteriaIdData: [],
  programListQualifierCondData: [],
})

export const clearReducer = () => INITIAL_STATE

export const clearProgramListReducer = () => INITIAL_STATE

export const setAllProgramListDataReducer = (state = INITIAL_STATE, { payload }) => {
  const d = payload?.data.map((row) => ({
    ...row,
    effective_end_date: convertDateTimeToDate(row.effective_end_date),
    effective_start_date: convertDateTimeToDate(row.effective_start_date),
  }))
  return {
    ...state,
    allProgramListData: d,
  }
}

export const setProgramListDrugFiltersDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  programListDrugFiltersData: payload,
})

export const setProgramListDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  programListCriteriaIdData: payload.criteria_ids || [],
  programListQualifierCondData: payload.qualifier_conditions,
  programListCondData: payload.program_conditions,
  programListData: payload.program_info,
})

export const clearProgramListDataReducer = (state = INITIAL_STATE) => ({
  ...state,
  programListCriteriaIdData: [],
  programListQualifierCondData: [],
  programListCondData: [],
  programListData: {},
})

export const { Types, Creators } = createActions({
  approveProgramList: ['payload'],
  clearProgramList: null,
  clearReducer: null,
  clearProgramListData: null,
  generateProgramList: ['payload'],
  generateProgramListLevelExport: ['payload', 'callback'],
  getAllProgramListData: null,
  getProgramListData: ['payload'],
  saveProgramListData: ['payload'],
  setAllProgramListData: ['payload'],
  setProgramListData: ['payload'],
  setProgramListDrugFiltersData: ['payload'],
})

const HANDLERS = {
  [Types.CLEAR_PROGRAM_LIST]: clearProgramListReducer,
  [Types.CLEAR_REDUCER]: clearReducer,
  [Types.CLEAR_PROGRAM_LIST_DATA]: clearProgramListDataReducer,
  [Types.SET_ALL_PROGRAM_LIST_DATA]: setAllProgramListDataReducer,
  [Types.SET_PROGRAM_LIST_DATA]: setProgramListDataReducer
}

export default createReducer(INITIAL_STATE, HANDLERS)
