
import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Creators as PriorAuthorizationCreationActions } from '../../../../redux/reducers/api/priorAuthorizationManagement'

import './styles.scss'
import { Creators as AppActions } from '../../../../redux/reducers/app'
import FliptPaDropdown from '../../../../components/form/fliptPaDropdown'
import FliptButton from '../../../../components/form/fliptButton'
import FliptPaInput from '../../../../components/form/fliptPaInput'
import FliptGrid from '../../../../components/fliptGrid'

class DiagnosisSearch extends Component {
    constructor(props) {
        super(props)

        this.state = {
            form: {
                icd_10_cm: '',
                long_description: '',
            },
            selectedDiagnosis: null,
            diagnosisList: []
        }
    }

    componentDidUpdate(prevProps) {
        const { props: { state, actions } } = this
        const { diagnosisData } = state
        if (prevProps.state?.diagnosisData !== diagnosisData && diagnosisData) {
            const formattedDiagnosisData = diagnosisData.map((data) => {
                const icd = data['ICD-10-CM']
                return {
                    icd_10_cm: icd,
                    long_description: data.long_description
                }
            })
            this.setState({ diagnosisList: formattedDiagnosisData })
        }
    }

    _updateForm = (el, dropdown) => {
        const { form } = this.state
        const { name, value } = dropdown || el.currentTarget
        form[name] = value
        this.setState({ form, selectedDiagnosis: null, diagnosisList: [] })
    }

    _searchDiagnosis = () => {
        const { form } = this.state
        const { actions } = this.props
        if (!form.icd_10_cm && !form.long_description) {
            const transitionalPortal = {
                header: 'Fields are missing',
                copy: 'Please add ICD 10 Code or Diagnosis Description to search the Diagnosis',
            }
            actions.displayTransitionalPortal(transitionalPortal)
            return
        }
        actions.diagnosisLookup(form)
    }

    _submitDiagnosis = () => {
        const { selectedDiagnosis } = this.state
        const { updateDiagnosisData, actions } = this.props
        updateDiagnosisData(selectedDiagnosis)
        actions.toggleModal()
    }

    _onDiagnosisSelectionChanged = (data) => {
        const selectedRows = data.api.getSelectedRows();
        if (selectedRows.length) {
            this.setState({ selectedDiagnosis: selectedRows[0] })
        }
        else {
            this.setState({ selectedDiagnosis: null })
        }
    }


    render() {
        const { updateDiagnosisData } = this.props
        const { form, selectedDiagnosis, diagnosisList } = this.state
        const agGridRef = React.createRef()

        const onGridReady = () => {
            if (diagnosisList.length == 1) {
                updateDiagnosisData(diagnosisList[0])
                this.setState({ selectedDiagnosis: diagnosisList[0] })
                agGridRef.current.api.forEachNode(function (node) {
                    node.setSelected(true);
                });
            }
        }
        const headers = ['Select', 'icd_10_cm', 'long_description']
        const cellRendererParams = {
            Select: {
                checkboxSelection: true,
                width: '120px'
            },
            long_description: {
                width: '420px'
            },
        }


        return (
            <div id="searchDiagnosis">
                <div className='content'>
                    <span className='void-header'>Find and Fetch Diagnosis Information</span>
                    <div className='outer-content'>
                        <div className='fields'>
                            <FliptPaInput value={form?.icd_10_cm} onChange={this._updateForm}
                                name='icd_10_cm' stylized label="ICD-10 Code" />
                        </div>
                        <span className='or-text'>OR</span>
                        <div className='fields'>
                            <FliptPaInput inputClass='input-class-med' onChange={this._updateForm}
                                value={form?.long_description} name='long_description' stylized label='Diagnosis Description' />
                        </div>
                    </div>
                    <div className='button-container'>
                        <FliptButton className='primary' name='SEARCH' onClick={this._searchDiagnosis} />
                    </div>
                    {diagnosisList?.length ?
                        <>
                            <div className="diagnosis-header">
                                Search Results
                            </div>
                            <div className="grid-container">
                                <FliptGrid
                                    agGridRef={agGridRef}
                                    headers={headers}
                                    onGridReadyCustom={onGridReady}
                                    data={diagnosisList}
                                    rowSelection={'single'}
                                    cellRendererParams={cellRendererParams}
                                    onSelectionChanged={(data) => { this._onDiagnosisSelectionChanged(data) }} />
                            </div>
                        </> : <></>
                    }
                </div>
                <div className="button-container">
                    <FliptButton disabled={!selectedDiagnosis} name="SUBMIT" className="primary" onClick={() => this._submitDiagnosis()} />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    state: {
        app: state.app,
        diagnosisData: state.priorAuthorizationCreation.diagnosisData
    },
})

const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...AppActions,
        ...PriorAuthorizationCreationActions,
    }

    return {
        actions: bindActionCreators(allActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DiagnosisSearch)
