import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
// import { Icon } from 'semantic-ui-react'

import './styles.scss'
import BreadCrumb from '../../../../components/breadCrumb'
// import ComparisonModal from './comparisonModal'
import Config from '../../../../config'
import FliptButton from '../../../../components/form/fliptButton'
import FliptDropdown from '../../../../components/form/fliptDropdown'
import FliptSearch from '../../../../components/form/fliptSearch'
import FliptDatePicker from '../../../../components/form/fliptDatePicker'
import { Creators as AppActions } from '../../../../redux/reducers/app'
import { Creators as AutoSuggestActions } from '../../../../redux/reducers/api/autoSuggest'
import { Creators as FormularyActions } from '../../../../redux/reducers/api/formularyLookup'
import { Creators as FormularyCreationActions } from '../../../../redux/reducers/api/formularyCreationManagement'
import { Creators as NavigationActions } from '../../../../redux/reducers/navigation'
import { Creators as GetPricingActions } from '../../../../redux/reducers/api/getPricing'
import { createQueryString, parseQueryString, convertStrToDateObj } from '../../../../utils/utilities'
import FliptGrid from '../../../../components/fliptGrid'
import moment from 'moment'
import _ from 'lodash'
import CAGFilters from '../../../../components/CAGFilters'
import FliptInput from '../../../../components/form/fliptInput'

// const MAX_COMPARISON_DATA = 4

class FormularyLookup extends Component {
  constructor(props) {
    super(props)
    const {
      history,
      state,
      location,
    } = this.props

    const {
      account = '',
      date_of_service = '',
      gpi = '',
      ndc = '',
      plan_name = '',
      plan_year = '',
      date_of_birth = ''
    } = location.state || {}
    const daysSupply = ['30', '60', '90', '90+']
    const { user: { userCAG } } = state
    const { carrier: carriers = [], account: accounts = [], group: groups = [] } = userCAG ?? {}
    const qs = history?.location?.search ? parseQueryString(history.location.search) : (history?.location?.state || {})
    this.state = {
      // comparisonData: [],
      form: {
        // account: qs.account || '',
        carrier: qs.carrier || '',
        drug_details: qs.drug_details || '',
        drug_name: qs.drug_name ? decodeURIComponent(qs.drug_name) : '',
        // drug_name: qs.drug_name ? decodeURIComponent(qs.drug_name) : '',
        ndc: ndc || '',
        end_date: qs.end_date || '',
        group: qs.group || '',
        limit: qs.limit || '',
        offset: qs.offset || '',
        // plan_name: qs.plan_name ? decodeURIComponent(qs.plan_name) : '',
        plan_name: plan_name || '',
        plan_year: plan_year || '',
        start_date: qs.start_date || '',
        account: qs.account ? qs.account : account || '',
        gpi: gpi || '',
        date_of_birth: date_of_birth || '',
        date_of_service: convertStrToDateObj(date_of_service, { format: 'YYYY-MM-DD', toISO: true }) || moment().format('YYYY-MM-DD'),
        drug_form: qs.drug_form || '',
        strengths: qs.strengths || '',
        days_of_supply: qs.days_of_supply || '30'
      },
      domainOptions: accounts.map((d) => ({
        key: d,
        text: d,
        value: d,
      })),
      carrierOptions: carriers.map((d) => ({
        key: d,
        text: d,
        value: d,
      })),
      groupOptions: groups.map((d) => ({
        key: d,
        text: d,
        value: d,
      })),
      planNameOptions: plan_name ? [plan_name].map((d) => ({
        key: d,
        text: d,
        value: d,
      })) : [{
        key: '',
        text: '',
        value: '',
      }],
      loading: false,
      // maxComparisonErrorMsg: 'Select up to 4 drugs from the list below and then click/tap to compare.',
      minCharacters: 3,
      resultValues: [],
      drug_detail_options: {
        forms: [{
          key: '',
          text: '',
          value: '',
        }], strengths: [{
          key: '',
          text: '',
          value: '',
        }]
      },
    }
  }

  componentDidMount() {
    const { form, carrierOptions, domainOptions, groupOptions } = this.state
    const { actions, history } = this.props

    if (!history.location) return

    actions.clearFormularyLookupData()
    this._qsToFormData()
    if (form?.plan_year && form?.plan_name && (form?.drug_name || form?.ndc)) {
      history.location.search = createQueryString({ ...form, })
      setTimeout(() => {
        actions.getFormularyLookupData(parseQueryString(history.location.search))
      }, Config.COMPONENT_DATA_LOAD_TIME)
    }
  }

  componentDidUpdate(prevProps) {
    const { props } = this
    const { state: { autoSuggestData, user, planNameLookupData } } = props
    const prevState = prevProps.state
    const currState = props.state
    if (prevState === currState) return
    this._updateDropdownFields(user)
    this._updateResultSearchValues(autoSuggestData)
    this._updateDrugDetailsDropdown()
    if (planNameLookupData?.length) {
      this._updatePlanNameOptions(planNameLookupData)
    }
  }

  _updateDrugDetailsDropdown() {
    const {
      state,
    } = this.props
    const {
      drug_detail_options,
    } = this.state
    const { drugDetails } = state
    const drug_detail_options_copy = { ...drug_detail_options }
    if (drugDetails.drug_forms?.length) {
      drug_detail_options_copy.forms = drugDetails.drug_forms.map((d) => ({
        key: d.drug_form,
        text: d.drug_form,
        value: d.drug_form,
      }))
      drug_detail_options_copy.forms.unshift({
        key: 0, text: 'Select Form', value: '', selected: 'selected',
      })
    }
    this.setState({ drug_detail_options: drug_detail_options_copy })
  }

  _updatePlanNameOptions = (planNameOptions) => {
    let planNameOptionsArray = planNameOptions.length > 0 ? [...new Set(planNameOptions.map((pn) => pn.plan_name))] : []
    this.setState({ planNameOptions: planNameOptionsArray.length > 0 ? planNameOptionsArray.map((plan_name) => ({ key: plan_name, text: plan_name, value: plan_name })) : [{ key: 'None', text: 'None', value: 'None' }] })
  }

  _qsToFormData = () => {
    const { state, props } = this
    const qs = parseQueryString(props.history.location.search)
    const { form, rerender = 0 } = state

    Object.keys(qs).forEach((key) => {
      const isNDC = /^\d+$/.test(qs[key])
      if (key === 'drug_name' && isNDC) {
        form.ndc = decodeURIComponent(qs[key])
      } else {
        form[key] = decodeURIComponent(qs[key])
      }
    })
    this.setState({ form, rerender: rerender + 1 })
  }

  // _updateTableData = (formularyLookupData) => {
  //   const tableData = formularyLookupData.map((data) => {
  //     const {
  //       gpi,
  //       ndc,
  //       drug_covered,
  //       drug_name,
  //       stregths,
  //       form,
  //       // aca,
  //       // hdhp,
  //       multi_source,
  //       past,
  //       mfg,
  //       copay_type,
  //       copay_30daysupply,
  //       mo_copay_30daysupply,
  //       preferred_copay_30daysupply,
  //       copay_60daysupply,
  //       mo_copay_60daysupply,
  //       preferred_copay_60daysupply,
  //       copay_90daysupply,
  //       mo_copay_90daysupply,
  //       preferred_copay_90daysupply,
  //       copayabove90daysupply,
  //       mo_copayabove90daysupply,
  //       preferred_copay_above90daysupply,
  //       pa_override,
  //       pa_quantity,
  //       pa_reason,
  //       ql_ratio,
  //       maintenance_drug_code,
  //       otc_indicator,
  //       compareNdc,
  //     } = data

  //     return {
  //       // compare: (<div><input id={`checkBox-${id}`} type="checkbox" value={id} onClick={this._compareDrug} disabled={isDisabled} /></div>),
  //       gpi,
  //       ndc,
  //       drug_covered,
  //       drug_name,
  //       stregths,
  //       form,
  //       // aca,
  //       // hdhp,
  //       multi_source,
  //       past,
  //       mfg,
  //       copay_type,
  //       copay_30daysupply,
  //       mo_copay_30daysupply,
  //       preferred_copay_30daysupply,
  //       copay_60daysupply,
  //       mo_copay_60daysupply,
  //       preferred_copay_60daysupply,
  //       copay_90daysupply,
  //       mo_copay_90daysupply,
  //       preferred_copay_90daysupply,
  //       copayabove90daysupply,
  //       mo_copayabove90daysupply,
  //       preferred_copay_above90daysupply,
  //       pa_override,
  //       pa_quantity,
  //       pa_reason,
  //       ql_ratio,
  //       maintenance_drug_code,
  //       otc_indicator,
  //       compareNdc,
  //     }
  //   })

  //   return tableData
  // }

  // _rowClick = (el) => {
  //   const { props } = this
  //   const { state: { formularyLookupData } } = this.props
  //   const target = el.currentTarget.querySelector('input[type="checkbox"]')
  //   const formularyData = formularyLookupData.find((d) => d.id === target.value)

  //   props.actions.setModalComponent({
  //     modalProperties: { size: 'fullscreen' },
  //     contents: ComparisonModal({ comparisonData: [formularyData] }),
  //   })
  //   props.actions.toggleModal()
  // }

  // _deleteFromComparison = (el) => {
  //   const { comparisonData } = this.state
  //   const itemId = el.currentTarget.getAttribute('data-id')
  //   const chkBox = document.querySelector(`#checkBox-${itemId}`)
  //   const idx = comparisonData.findIndex((d) => d.id === itemId)

  //   comparisonData.splice(idx, 1)
  //   this.setState({ comparisonData })
  //   if (chkBox) chkBox.checked = !chkBox.checked
  // }

  // _compareFormularyData = () => {
  //   const { state, props } = this
  //   if (!state.comparisonData.length) return

  //   props.actions.setModalComponent({
  //     modalProperties: { size: 'fullscreen' },
  //     contents: ComparisonModal({ comparisonData: state.comparisonData }),
  //   })
  //   props.actions.toggleModal()
  // }

  // _onPageChange = (e, { activePage }) => {
  //   const { form } = this.state
  //   const { actions, history, state } = this.props

  //   form.offset = String((activePage - 1) * state.formularyDataPerPage)
  //   form.limit = state.formularyDataPerPage

  //   history.location.search = createQueryString({
  //     ...form,
  //   })

  //   actions.getFormularyLookupData()
  //   // actions.getGpiLookupData()
  // }

  _handleDosPicker = (eve, e) => {
    const { form, rerender = 0 } = this.state
    const { value } = e
    form['date_of_service'] = value ? moment(value).format('YYYY-MM-DD') : ''
    this.setState({ form: { ...form }, rerender: rerender + 1 }
      // , () => {
      // this._handlePlanNameFetch()
      // }
    )
  }

  _updateField = (el, dropdown) => {
    const { actions, state } = this.props
    const {
      drug_detail_options,
      form: oldForm
    } = this.state
    const form = { ...oldForm }
    let drug_detail_options_copy = { ...drug_detail_options }
    const { drugDetails } = state
    const { name, value } = dropdown || el.currentTarget

    const isDigits = /^\d+$/.test(value)
    if (name !== 'drug_name') {
      form[name] = value
    } else if (name === 'drug_name' && !!isDigits) {
      form.ndc = value
      form.drug_name = ''
      form.gpi = ''
      form['drug_strength'] = ''
      form['drug_form'] = ''
    } else if (name === 'drug_name' && !isDigits) {
      form.drug_name = value
      form.ndc = ''
      form.gpi = form?.drug_strength || ''
    }
    if (name === 'drug_form') {
      if (!drugDetails?.drug_forms || !drugDetails?.drug_forms?.length) return
      drug_detail_options_copy.strengths = drugDetails.drug_forms.find((f) => f.drug_form.toUpperCase() === value.toUpperCase()).drugs.map((d) => ({
        text: d.strengths,
        value: d.gpi,
        key: d.gpi,
      })).sort((a, b) => (a.value - b.value))
      drug_detail_options_copy.strengths.unshift({
        key: 0, text: 'Select Strength', value: '', selected: 'selected',
      })
    }
    if (name === 'drug_strength') {
      form.gpi = value
    }


    this.setState({ form, drug_detail_options: drug_detail_options_copy }, () => {
      if (name === 'drug_name' && value.length > 2) {
        actions.clearAutoSuggestData()
        actions.getAutoSuggestData({ search_string: value, search_in: 'fts_ndc_drugs', search_for: !!isDigits ? 'ndc' : 'drug_name' })
        this.setState({ loading: true })
      }
      // if (name === 'account') {
      //   this._handlePlanNameFetch()
      // }
    })
  }

  _clearSearchData = () => {
    const { actions, history, state } = this.props
    const form = {
      carrier: '',
      account: '',
      group: '',
      ndc: '',
      drug_name: '',
      plan_name: '',
      date_of_service: '',
      drug_form: '',
      drug_strength: '',
      plan_year: '',
      date_of_birth: '',
    }

    const drug_detail_options = {
      forms: [{
        key: '',
        text: '',
        value: '',
      }],
      strengths: [{
        key: '',
        text: '',
        value: '',
      }]
    }

    const planNameOptions =
      [{
        key: '',
        text: '',
        value: '',
      }]

    history.location.state = form
    actions.clearFormularyLookupData()
    actions.clearDrugDetails()
    this.setState({ form })
    this.setState({ drug_detail_options, planNameOptions })
  }

  _fetchFormularyData = () => {
    const { form } = this.state
    const { actions, history } = this.props

    if (form?.date_of_service && form?.carrier && (form?.account || form?.domain_name) && form?.group && (form?.drug_name || form?.ndc)) {
      history.location.state = { ...form }
      actions.clearFormularyLookupData()
      actions.getFormularyLookupData({ ...form })
    }
  }

  _onResultSelect = (e, data) => {
    const { actions } = this.props
    const { form, rerender = 0 } = this.state
    const { name, result } = data
    const value = result.title
    const isNDC = /^\d+$/.test(value)
    form[name] = value
    if (isNDC) {
      form[name] = ''
      form['ndc'] = value
    }

    this.setState({ form: { ...form }, rerender: rerender + 1 }, () => {
      if (!_.isEmpty(form.drug_name) || !_.isEmpty(form.ndc) && !_.isEmpty(form.plan_name) && !_.isEmpty(form.account)) {
        actions.clearFormularyLookupData()
        actions.getDrugDetails({
          ndc: form.ndc,
          domain_name: form.account,
          drug_name: form.drug_name,
          carrier: form.carrier,
          group: form.group
        })
      }
    })
  }

  _handlePlanNameFetch = () => {
    const { actions } = this.props
    const { form: { date_of_service, account } } = this.state
    if (account) {
      actions.getPlanNameLookupData({ date_of_service, domain_name: account })
    }
  }

  _updateDropdownFields(user) {
    this.setState({
      domainOptions: user?.userCAG?.account.map((d) => ({
        key: d,
        text: d,
        value: d,
      })),
      carrierOptions: user?.userCAG?.carrier.map((d) => ({
        key: d,
        text: d,
        value: d,
      })),
      groupOptions: user?.userCAG?.group.map((d) => ({
        key: d,
        text: d,
        value: d,
      })),
    })
  }

  _updateResultSearchValues(drugsArray) {
    let suggestList = drugsArray['drug_name']
    if ('ndc' in drugsArray)
      suggestList = drugsArray['ndc']
    const resultValues = suggestList ? suggestList.map((d) => ({ title: d })) : []

    this.setState({ loading: false, resultValues })
  }

  render() {
    const { state } = this.props
    const { formularyLookupData } = state
    const {
      carrierOptions,
      domainOptions,
      groupOptions,
      planNameOptions,
      form,
      loading,
      minCharacters,
      resultValues,
    } = this.state
    const {
      form: {
        ndc,
      },
      drug_detail_options,
    } = this.state;
    const others = [];
    const actualData = [];
    (formularyLookupData || []).filter(data => data).map((data, index) => {
      data.compareNdc = ndc
      const { record } = data
      const formularyRecord = record?.length ? record[0] : {}
      if (!Object.keys(formularyRecord).length) {
        data.drug_covered = 'N'
      }
      if (formularyRecord?.pa_flag === 'Y') {
        data.past = 'Y'
      }
      else {
        data.past = 'N'
      }
      // eslint-disable-next-line prefer-destructuring
      const unnestedRecord = data.record?.length ? data.record[0] : {}
      const updatedData = {
        ...data,
        ...unnestedRecord,
      }

      const unnestedCopay = updatedData.copay?.length ? updatedData.copay[0] : {}

      const updatedFinalData = {
        ...updatedData,
        ...unnestedCopay,
      }
      formularyLookupData[index] = updatedFinalData
      if (formularyLookupData[index].drug_name === form.drug_name || formularyLookupData[index].ndc === form.ndc)
        actualData.push(updatedFinalData)
      else
        others.push(updatedFinalData)
      return updatedFinalData
    })
    const filteredData = [...actualData, ...others]
    const headers = [
      'gpi',
      'ndc',
      'drug_covered',
      'manufacturer_only_coverage',
      'drug_name',
      'dosage_strength',
      'dosage',
      'past',
      'multi_source',
      'medicare_type',
      'specialty_flag',
      'aca',
      'hdhp',
      'ql_ratio',
      'ql_qty_per_period',
      'ql_ds_per_period',
      'ql_lookback_period',
      'ql_gpi_grouping',
      'mfg',
      'network_tiers',
      'phase',
      'copay_type',
      'member_pay',
      'min_copay',
      'max_copay',
      'maintenance_drug_code',
      'drug_subclass'
    ]

    const cellRendererParams = {
      gpi: {
        overrideHeader: 'GPI',
      },
      ndc: {
        overrideHeader: 'NDC',
      },
      dosage_strength: {
        overrideHeader: 'Strength',
      },
      dosage: {
        overrideHeader: 'Form',
      },
      past: {
        overrideHeader: 'PA',
      },
      ql_ratio: {
        overrideHeader: 'QL Ratio',
      },
      ql_qty_per_period: {
        overrideHeader: 'QL Per Period',
      },
      ql_ds_per_period: {
        overrideHeader: 'QL DS Per Period',
      },
      ql_lookback_period: {
        overrideHeader: 'QL Lookback',
      },
      ql_gpi_grouping: {
        overrideHeader: 'QL GPI Grouping',
      },
      // copay_30daysupply: {
      //   overrideHeader: 'Retail Copay 30 Day',
      // },
      // copay_60daysupply: {
      //   overrideHeader: 'Retail Copay 60 Day',
      // },
      // copay_90daysupply: {
      //   overrideHeader: 'Retail Copay 90 Day',
      // },
      // copayabove90daysupply: {
      //   overrideHeader: 'Retail Copay above 90 Day',
      // },
      member_pay: {
        overrideHeader: 'Copay',
      },
      min_copay: {
        overrideHeader: 'Min Copay',
      },
      max_copay: {
        overrideHeader: 'Max Copay',
      },
      mfg: {
        overrideHeader: 'Manufacturer',
      },
      otc_indicator: {
        overrideHeader: 'Indication',
      },
      maintenance_drug_code: {
        overrideHeader: 'Maintenance Drug',
      },
      drug_subclass: {
        overrideHeader: 'Indication',
      },
      specialty_flag: {
        overrideHeader: 'Specialty'
      },
      manufacturer_only_coverage: {
        overrideHeader: 'Manufacturer Only Coverage'
      }
    }

    const serverSideGridConfig = {
      pagination: true,
      paginationPageSize: 20,
    }

    const rowClassRules = {
      'ndc-highlight': 'data.ndc === data.compareNdc',
    }
    const plan_year = form.plan_year ? moment(form.plan_year, 'YYYY').toDate() : ''
    return (
      <div id="formularyLookup">
        <BreadCrumb {...this.props} />
        <div className="header">Formulary Lookup</div>
        <div className="subhead">
          <div className='flex-left row'>
            <CAGFilters className='formulary-lookup-cag-filters' selectedCarrier={form?.carrier} selectedAccount={form?.account} selectedGroup={form?.group} onFilterChange={this._updateField} />
            {/* <FliptInput name="plan_year" onBlur={this._handlePlanNameFetch} placeholder="Plan Year" value={form.plan_year} onChange={this._updateField} label="Plan Year" description="Plan Year" stylized="true" /> */}
            {/* <FliptDropdown simple item name="plan_name" placeholder="Plan Name" onChange={this._updateField} value={form.plan_name} options={planNameOptions} label="Plan Name" description="Plan Name" stylized="true" /> */}
            {/* <FliptCustomDatePicker name="plan_year" dateFormat='YYYY' showYearPicker={true} yearItemNumber={9} placeholderText="Plan Year" selected={plan_year} onChange={this._handleYearPicker} label="Plan Year" stylized={true} /> */}
            <FliptDatePicker name='date_of_service' showYearPicker={true} label='Date Of Service' onChange={this._handleDosPicker} value={convertStrToDateObj(form.date_of_service)} stylized='true' />
            <FliptSearch name="drug_name" placeholder="Drug Name/NDC" value={form.drug_name || form.ndc} results={resultValues} loading={loading} minCharacters={minCharacters} onResultSelect={(e, data) => this._onResultSelect(e, data)} onSearchChange={(e, dropdown) => this._updateField(e, dropdown)} label="Drug Name/NDC" description="Drug Name/NDC" stylized="true" />
            {/* <FliptDatePicker name="start_date" placeholder="Start Date" value="07-01-2020" onChange={this._updateField} /> */}
            {/* <FliptDatePicker name="end_date" placeholder="End Date" value={form.end_date} onChange={this._updateField} /> */}
          </div>

          <div className='flex-left row'>
            <FliptDropdown simple item name="drug_form" placeholder='Form' onChange={this._updateField} value={form?.drug_form} options={drug_detail_options.forms} description="Drug Form" label="Drug Form" stylized />
            <FliptDropdown simple item name="drug_strength" placeholder='Strength' onChange={this._updateField} value={form.drug_strength} options={drug_detail_options.strengths.filter((o, i) =>
              i === drug_detail_options.strengths.findIndex(oo => o.key === oo.key && o.text === oo.text && o.value === oo.value)
            )} label="Drug Strength" stylized />
            <FliptInput name="days_of_supply" placeholder='030' onChange={this._updateField} value={form?.days_of_supply} label="Days Supply" stylized />
          </div>
          <div className='row'>
            <FliptButton name="Search" className="primary searchButton" onClick={this._fetchFormularyData} />
            <FliptButton name="Clear" className="primary searchButton" onClick={this._clearSearchData} />
          </div>
        </div>

        <div className="content">
          <FliptGrid
            // data={this._updateTableData(formularyLookupData)}
            data={filteredData}
            headers={headers}
            serverSideGridConfig={serverSideGridConfig}
            cellRendererParams={cellRendererParams}
            rowClassRules={rowClassRules}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  state: {
    drugDetails: state.getPricing.drugDetails,
    formularyLookupData: state.formularyLookup.formularyLookupData,
    formularyDataPerPage: state.formularyLookup.formularyDataPerPage,
    autoSuggestData: state.autoSuggest.autoSuggestData,
    planNameLookupData: state.formularyLookup.planNameLookupData,
    user: state.user,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
    ...AutoSuggestActions,
    ...FormularyActions,
    ...FormularyCreationActions,
    ...NavigationActions,
    ...GetPricingActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormularyLookup)
