import React, { useCallback, useEffect, useMemo, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as _ from 'lodash'
import { Creators as groupManagementActions } from "../../../../../redux/reducers/api/groupManagement";
import { Creators as AppActions } from '../../../../../redux/reducers/app'
import FliptInput from "../../../../../components/form/fliptPaInput";
import FliptDropdown from "../../../../../components/form/fliptPaDropdown";
import FliptButton from '../../../../../components/v2/fliptButton'
import {
  cardTypeOptions,
  deductibleSatisfactionOptions,
  idCardCreatorOptions,
  idOnCardOptions,
  oopSatisfactionOptions,
  deducticleOopOptions,
  benefitTypeOptions,
  typeOfOopOptions
} from "../../../groupManagement/data/dropdownData";
import {
  DatePickerEditor,
  DropdownEditor,
  InputTextEditor,
} from "../../../../../components/fliptGrid/cellEditors";
import FliptGrid from "../../../../../components/fliptGrid";
import "./styles.scss";
import moment from "moment";
import {
  accumulationHeader,
  groupHeader,
  planDetailsHeader,
} from "./constants";
import { Creators as ClientConfigurationActions } from "../../../../../redux/reducers/api/clientConfiguration";
import { checkKeys, getHierarchy, mergeIdenticalRecords } from "../../utils";

const GroupTab = (props) => {
  const {
    state: { effectiveGroupRecord, planList, AllData, nodeId, parentId, addMode, defaultGroupData },
    actions,
  } = props;

  // const groupData = [...defaultGroupData.group, effectiveGroupRecord]

  const [groupData, setGroupData] = useState([...defaultGroupData.group, effectiveGroupRecord])
  const [canAddNewPlan, setCanAddNewPlan] = useState(true)
  const [isAddingNewPlan, setIsAddingNewPlan] = useState(false)
  const [canAddNewAccums, setCanAddNewAccums] = useState(true)
  const [isAddingNewAccums, setIsAddingNewAccums] = useState(false)

  useEffect(() => {
    const existingGroupData = defaultGroupData.group
    const effectiveReadOnlyRecord = existingGroupData.at(-1) || {}
    setCanAddNewPlan(!!effectiveReadOnlyRecord.plan_termination_date)
    setCanAddNewAccums(!!effectiveReadOnlyRecord.benefit_effective_end_date)
    setGroupData([...defaultGroupData.group, effectiveGroupRecord])
  }, [defaultGroupData, effectiveGroupRecord, planList])


  // handle changes to the values in the grid
  const handleChange = (e, dropdown, rowIndex, gridApi) => {
    let { name, value } = dropdown || e?.currentTarget
    let formattedValue = name && cellRendererParams[name]?.type === 'date' ? valueFormatter(value) : value
    if (name == "group_id") {
      formattedValue = formattedValue.replace(/\s/g, '')
    }
    const fieldsToUpdate = {
      [name]: formattedValue
    }
    if (name === "plan_description") {
      fieldsToUpdate['plan_id'] = planList?.find((each) => each?.plan_name === value)?.plan_id
    }
    const updatedEffectiveGroupRecord = { ...effectiveGroupRecord, ...fieldsToUpdate }
    actions.setEffectiveGroupRecord(updatedEffectiveGroupRecord)

    if (name == "group_name") {
      name = "group_custom_name"
      actions.updateAttributeFields({ name, value });
    } else if (name == "group_id") {
      name = "group_custom_id"
      value = value.replace(/\s/g, '')
      actions.updateAttributeFields({ name, value });
    }
  };

  const addNewPlan = () => {
    const existingGroupData = defaultGroupData.group
    if (existingGroupData.length) {
      const effectiveReadOnlyRecord = existingGroupData.at(-1)
      if (effectiveReadOnlyRecord.plan_termination_date !== effectiveGroupRecord.plan_termination_date) {
        const transitionalPortal = {
          header: 'Save current plan changes before adding a new plan.',
          copy: 'Save current plan changes before adding a new plan.',
        }
        actions.displayTransitionalPortal(transitionalPortal)
        return
      }
    }
    actions.setEffectiveGroupRecord({
      ...effectiveGroupRecord,
      plan_description: '',
      plan_effective_date: '',
      plan_id: '',
      plan_name: '',
      plan_termination_date: '',
    })
    setGroupData([...defaultGroupData.group, effectiveGroupRecord])
    setIsAddingNewPlan(true)
  }

  const addNewAccums = () => {
    const existingGroupData = defaultGroupData.group
    if (existingGroupData.length) {
      const effectiveReadOnlyRecord = existingGroupData.at(-1)
      if (effectiveReadOnlyRecord.benefit_effective_end_date !== effectiveGroupRecord.benefit_effective_end_date) {
        const transitionalPortal = {
          header: 'Save current accumulation period changes before adding a new plan.',
          copy: 'Save current accumulation period changes before adding a new plan.',
        }
        actions.displayTransitionalPortal(transitionalPortal)
        return
      }
    }
    actions.setEffectiveGroupRecord({
      ...effectiveGroupRecord,
      benefit_effective_start_date: '',
      benefit_effective_end_date: '',
      benefit_period_type: '',
      type_of_oop: '',
      deductible_oop_logic: '',
      deductible_satisfaction: '',
      individual_deductible: '',
      family_deductible: '',
      oop_satisfaction: '',
      individual_oop: '',
      family_oop: '',
    })
    setGroupData([...defaultGroupData.group, effectiveGroupRecord])
    setIsAddingNewAccums(true)
  }


  // handle field changes for non grid inputs
  const _updateField = (el, dropdown) => {
    const { name, value } = dropdown || el?.currentTarget;
    actions.setEffectiveGroupRecord({ ...effectiveGroupRecord, [name]: value })
  };

  // checks if the fields are editable
  const checkEditable = (params) => {
    // new group creation is in progress
    if (!defaultGroupData.group.length) return true

    if (params.data.doc_id === '') {
      if (isAddingNewPlan || isAddingNewAccums) {
        // new plan or accum record is being added
        return true
      } else if (['plan_termination_date', 'benefit_effective_end_date'].includes(params.column.colId)) {
        return true
      }
    }
    return false
  }

  const valueFormatter = (value) => {
    if (!value) return "";
    return moment(value)?.format("YYYY-MM-DD") || value;
  };

  const cellRendererParams = {
    deductible_oop_logic: {
      overrideHeader: 'Accumulation Type'
    },
    group_effective_date: {
      type: 'date',
      valueFormatter: (params) => valueFormatter(params?.value),
    },
    group_termination_date: {
      type: 'date',
      valueFormatter: (params) => valueFormatter(params?.value),
    },
    plan_description: {
      overrideHeader: 'Plan Name',
    },
    plan_id: {
      hide: true,
    },
    plan_effective_date: {
      overrideHeader: 'Begin Date',
      type: 'date',
      valueFormatter: (params) => valueFormatter(params?.value),
    },
    plan_termination_date: {
      overrideHeader: 'End Date',
      type: 'date',
      valueFormatter: (params) => valueFormatter(params?.value),
    },
    benefit_effective_start_date: {
      overrideHeader: 'Period Begin',
      type: 'date',
      valueFormatter: (params) => valueFormatter(params?.value)
    },
    benefit_effective_end_date: {
      overrideHeader: 'Period End',
      type: 'date',
      valueFormatter: (params) => valueFormatter(params?.value)
    },
    type_of_oop: {
      hide: true,
    }
  };

  const rowCellInfo = useMemo(
    () => ({
      plan_description: {
        type: "dropdown",
        options: planList?.map((each) => ({
          value: each?.plan_name,
          text: each?.plan_name,
        })),
      },
      deductible_satisfaction: {
        type: "dropdown",
        options: deductibleSatisfactionOptions,
      },
      oop_satisfaction: {
        type: "dropdown",
        options: oopSatisfactionOptions,
      },
      deductible_oop_logic: {
        type: "dropdown",
        options: deducticleOopOptions,
      },
      benefit_period_type: {
        type: "dropdown",
        options: benefitTypeOptions,
      },
      type_of_oop: {
        type: "dropdown",
        options: typeOfOopOptions,
      }

    }),
    [JSON.stringify(planList)]
  );

  const cellEditorParams = {
    // group grid
    group_name: {
      cellEditor: InputTextEditor,
      onChange: handleChange,
      editable: checkEditable,
    },
    group_id: {
      cellEditor: InputTextEditor,
      onChange: handleChange,
      editable: checkEditable,
    },
    group_effective_date: {
      cellEditor: DatePickerEditor,
      onChange: handleChange,
      editable: checkEditable,
    },
    group_termination_date: {
      cellEditor: DatePickerEditor,
      onChange: handleChange,
      editable: checkEditable,
    },

    // plan grid
    plan_description: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
      editable: checkEditable,
      // validation: 'numeric',
    },
    plan_effective_date: {
      cellEditor: DatePickerEditor,
      onChange: handleChange,
      editable: checkEditable,
    },
    plan_termination_date: {
      cellEditor: DatePickerEditor,
      onChange: handleChange,
      editable: checkEditable,
    },

    // accumulation_type grid
    benefit_effective_start_date: {
      cellEditor: DatePickerEditor,
      onChange: handleChange,
      editable: checkEditable,
    },
    benefit_effective_end_date: {
      cellEditor: DatePickerEditor,
      onChange: handleChange,
      editable: checkEditable,
    },
    deductible_oop_logic: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
      editable: checkEditable,
    },
    benefit_period_type: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
      editable: checkEditable,
    },
    type_of_oop: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
      editable: checkEditable,
    },
    deductible_satisfaction: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
      editable: checkEditable,
    },
    individual_deductible: {
      cellEditor: InputTextEditor,
      onChange: handleChange,
      editable: checkEditable,
    },
    family_deductible: {
      cellEditor: InputTextEditor,
      onChange: handleChange,
      editable: checkEditable,
    },
    oop_satisfaction: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
      editable: checkEditable,
    },
    individual_oop: {
      cellEditor: InputTextEditor,
      onChange: handleChange,
      editable: checkEditable,
    },
    family_oop: {
      cellEditor: InputTextEditor,
      onChange: handleChange,
      editable: checkEditable,
    },
  };

  const modifyGridData = (headers) => {
    const keys_to_filter = ["plan_termination_date", "benefit_effective_end_date"]
    const properties = headers.filter(item => !keys_to_filter.includes(item))
    if (groupData?.length > 0) {
      return mergeIdenticalRecords(groupData, properties) || [];
    } else {
      return [headers.reduce((acc, cur) => ({ ...acc, [cur]: "" }), {})]
    }
  };

  useEffect(() => {
    const data = {
      node_id: addMode ? parentId : nodeId,
      node_level: addMode ? "account" : "group"
    }
    actions.getGroupByHierarchy(data)
  }, [JSON.stringify(AllData)])

  return (
    <div id="add-group-from-hierarchy">
      <div className="devider" />
      <span className="inner-header">Group Coverage Details</span>
      <div className="grid-contents group-coverage">
        <FliptGrid
          data={modifyGridData(groupHeader) || []}
          headers={groupHeader}
          cellEditorParams={cellEditorParams}
          cellRendererParams={cellRendererParams}
        />
      </div>

      <div className="devider" />
      <span className="inner-header">Medicare Part D</span>
      <div className="field-outer-container">
        <div className="field-inner-container">
          <FliptInput
            label="Contract ID"
            name="cms_contract_id"
            value={effectiveGroupRecord?.cms_contract_id || ''}
            onChange={_updateField}
            stylized
          />
        </div>
        <div className="field-inner-container">
          <FliptInput
            label="Plan Benefit Package (PBP)"
            name="cms_pbp"
            value={effectiveGroupRecord?.cms_pbp || ''}
            onChange={_updateField}
            stylized
          />
        </div>
      </div>

      <div className="devider" />
      <div className='header-container'>
        <span>Plan Details</span>
        <FliptButton name='Add Plan' disabled={!canAddNewPlan || isAddingNewPlan} className='primary' onClick={addNewPlan} />
      </div>
      <div className="grid-contents plan-details">
        <FliptGrid
          data={modifyGridData(planDetailsHeader) || []}
          headers={planDetailsHeader}
          cellEditorParams={cellEditorParams}
          cellRendererParams={cellRendererParams}
          rowCellInfo={rowCellInfo}
        />
      </div>

      <div className="devider" />
      <div className='header-container'>
        <span>Accumulation Periods</span>
        <FliptButton name='Add Accum Period' disabled={!canAddNewAccums || isAddingNewAccums} className='primary' onClick={addNewAccums} />
      </div>
      <div className="grid-contents accumulation-periods">
        <FliptGrid
          data={modifyGridData(accumulationHeader) || []}
          headers={accumulationHeader}
          cellEditorParams={cellEditorParams}
          cellRendererParams={cellRendererParams}
          rowCellInfo={rowCellInfo}
        />
      </div>

      <div className="devider" />
      <span className="inner-header">ID Card Information</span>
      <div className="field-outer-container">
        <div className="field-inner-container">
          <FliptDropdown
            options={idCardCreatorOptions}
            label="ID Card Creator"
            value={effectiveGroupRecord?.id_card_creator || ''}
            name="id_card_creator"
            onChange={_updateField}
            stylized
          />
        </div>
        <div className="field-inner-container">
          <FliptDropdown
            options={cardTypeOptions}
            label="ID Card Type"
            value={effectiveGroupRecord?.id_card_type || ''}
            name="id_card_type"
            onChange={_updateField}
            stylized
          />
        </div>
        <div className="field-inner-container">
          <FliptDropdown
            options={idOnCardOptions}
            label="ID on the Card"
            name="id_on_the_card"
            value={effectiveGroupRecord?.id_on_the_card || ''}
            onChange={_updateField}
            stylized
          />
        </div>
      </div>

      <div className="devider" />
      <span className="inner-header">Client Defined Fields</span>
      <div className="field-outer-container">
        <div className="field-inner-container">
          <FliptInput
            label="Client Defined 1"
            name="client_defined_1"
            value={effectiveGroupRecord?.client_defined_1 || ''}
            onChange={_updateField}
            stylized
          />
          <FliptInput
            label="Client Defined 5"
            name="client_defined_5"
            value={effectiveGroupRecord?.client_defined_5 || ''}
            onChange={_updateField}
            stylized
          />
          <FliptInput
            label="Client Defined 9"
            name="client_defined_9"
            value={effectiveGroupRecord?.client_defined_9 || ''}
            onChange={_updateField}
            stylized
          />
        </div>
        <div className="field-inner-container">
          <FliptInput
            label="Client Defined 2"
            name="client_defined_2"
            value={effectiveGroupRecord?.client_defined_2 || ''}
            onChange={_updateField}
            stylized
          />
          <FliptInput
            label="Client Defined 6"
            name="client_defined_6"
            value={effectiveGroupRecord?.client_defined_6 || ''}
            onChange={_updateField}
            stylized
          />
          <FliptInput
            label="Client Defined 10"
            name="client_defined_10"
            value={effectiveGroupRecord?.client_defined_10 || ''}
            onChange={_updateField}
            stylized
          />
        </div>
        <div className="field-inner-container">
          <FliptInput
            label="Client Defined 3"
            name="client_defined_3"
            value={effectiveGroupRecord?.client_defined_3 || ''}
            onChange={_updateField}
            stylized
          />
          <FliptInput
            label="Client Defined 7"
            name="client_defined_7"
            value={effectiveGroupRecord?.client_defined_7 || ''}
            onChange={_updateField}
            stylized
          />
        </div>
        <div className="field-inner-container">
          <FliptInput
            label="Client Defined 4"
            name="client_defined_4"
            value={effectiveGroupRecord?.client_defined_4 || ''}
            onChange={_updateField}
            stylized
          />
          <FliptInput
            label="Client Defined 8"
            name="client_defined_8"
            value={effectiveGroupRecord?.client_defined_8 || ''}
            onChange={_updateField}
            stylized
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  state: {
    effectiveGroupRecord: state.groupManagement.effectiveGroupRecord,
    planList: state.groupManagement.planList,
    AllData: state.clientConfiguration.AllData,
    singleLevelAttribute: state.clientConfiguration.singleLevelAttribute,
    nodeId: state.clientConfiguration.nodeId,
    addMode: state.clientConfiguration.addMode,
    parentId: state.clientConfiguration.parentId,
    defaultGroupData: state.groupManagement.defaultGroupData,
  },
});

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...groupManagementActions,
    ...ClientConfigurationActions,
    ...AppActions,
  };

  return {
    actions: bindActionCreators(allActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupTab);
