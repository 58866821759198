const getFormFromState = (index,state) => {
  const key = index.replace('$$_STATE_', '')
  const { form } = state
  return form[key]
}

const getFormFromProps = (index,props) => {
  const key = index.replace('$$_PROPS_', '')
  const { formData: { model_data } } = props
  return model_data[key]
}

const conditionalOperators = {
  $eq: (a, b) => a === b,
  $lt: (a, b) => a < b,
  $gt: (a, b) => a > b,
  $lte: (a, b) => $lt(a, b) || $eq(a, b),
  $gte: (a, b) => $gt(a, b) || $eq(a, b),
  $and: (a, b) => a && b,
  $or: (a, b) => a || b,
  $not: (a) => !a,
}

export const parseStatement = (key, statement,form) => {
  const {state, props} = form;
    const value = statement[key]
    if (!_.isArray(value)) {
      throw 'Wrong Syntax'
    }
    const [arg1, arg2] = value
    let a = arg1
    let b = arg2
    if (_.isObject(arg1) && !_.isEmpty(arg1)) {
      a = parseStatement(Object.keys(a)[0], a,form)
    } else if (a?.startsWith('$$_')) {
      if (a.startsWith('$$_STATE_')) {
        a = getFormFromState(a,state)
        if (a instanceof Array) a = a.length
      } else {
        a = getFormFromProps(a,props)
      }
    }
    if (_.isObject(arg2) && !_.isEmpty(arg2)) {
      b = parseStatement(Object.keys(b)[0], b,form)
    } 
    else if (typeof b === 'string' && b?.startsWith('$$_')) {
      if (b.startsWith('$$_STATE_')) {
        b = getFormFromState(b,state)
      } else {
        b = getFormFromProps(b,props)
      }
    }
    return conditionalOperators[key](a, b)
  }

export const parseValue = (value, form) => {
  const {state, props} = form;
  if (!_.isObject(value) || Object.keys(value).length !== 1) {
    throw 'Wrong Syntax'
  }

  const key = Object.keys(value)[0]
  let target
  if (key?.startsWith('$$_')) {
    if (key.startsWith('$$_STATE_')) {
      target = getFormFromState(key,state)
    } else {
      target = getFormFromProps(key,props)
    }
  }
  
  return value[key][target]
}

export const parseValidation = (val) => {
  if (!val.startsWith('$$_')) return val

  if (val.startsWith('$$_LEN_')) {
    const v = val.replace('$$_LEN_', '')
    const length = parseInt(v)
    return (str) => str.length <= length
  }

  return val
}