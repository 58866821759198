import React, { Component } from 'react';
import FliptGrid from '../../../../../components/fliptGrid';
import SubmenuNav from '../shared/navigation'
import BreadCrumb from '../../../../../components/breadCrumb'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as _ from 'lodash';
import { Creators as ApiActions } from '../../../../../redux/reducers/api/claimLookupManagement'
import { parseQueryString } from '../../../../../utils/utilities'

import './styles.scss'
class NxTransactions extends Component {
    constructor(props) {
        super(props)
        this.state = {
            fromNx: false
        }
    }

    componentDidMount() {
        const { actions, history } = this.props
        const { search } = history.location
        const payload = parseQueryString(search)
        const { auth_id } = payload
        this.setState({ fromNx: !!payload?.fromNx })
        actions.getNxTxns({ auth_id: auth_id })
    }

    componentDidUpdate(prevProps) {
    }


    render() {
        const { history, state } = this.props
        const { nxTxnsData: { request, response } } = state
        const { fromNx } = this.state
        const headerObj = {
            request_header: [],
            request_insurance_header: [],
            request_claim_header: [],
            request_pricing_header: [],
            response_header: [],
            response_insurance_header: [],
            response_claim_header: [],
        }
        if (request) {
            headerObj.request_header = Object.keys(request?.header)
            headerObj.request_claim_header = Object.keys(request?.claims_segment)
            headerObj.request_insurance_header = Object.keys(request?.insurance_segment)
            headerObj.request_pricing_header = Object.keys(request?.pricing_segment)
        }
        if (response) {
            headerObj.response_header = Object.keys(response?.header)
            headerObj.response_insurance_header = Object.keys(response?.response_insurance)
            if (response?.transactions?.length && response?.transactions[0].claims_segment)
                headerObj.response_claim_header = Object.keys(response?.transactions[0].claims_segment)
        }

        return (
            <div id="nxTransactions">
                <BreadCrumb {...this.props} />
                {!fromNx && <div className="header">
                    <h1>Transactions</h1>
                    <SubmenuNav history={history} active="nxTransactions" />
                </div>}

                <div className='inner-header'>Nx Transaction Request</div>
                <div>
                    <div className='grid-header'>Request Header</div>
                    <div className='grid-container'>
                        <FliptGrid
                            headers={headerObj.request_header || []}
                            data={[request?.header] || []} />
                    </div>
                </div>
                <div>
                    <div className='grid-header'>Insurance Segment</div>
                    <div className='grid-container'>
                        <FliptGrid
                            headers={headerObj.request_insurance_header || []}
                            data={[request?.insurance_segment] || []} />
                    </div>
                </div>
                <div>
                    <div className='grid-header'>Claim Segment</div>
                    <div className='grid-container'>
                        <FliptGrid headers={headerObj.request_claim_header || []}
                            data={[request?.claims_segment] || []} />
                    </div>
                </div>
                <div>
                    <div className='grid-header'>Pricing Segment</div>
                    <div className='grid-container'>
                        <FliptGrid headers={headerObj.request_pricing_header || []}
                            data={[request?.pricing_segment] || []} />
                    </div>
                </div>
                <div className='inner-header'>Nx Transaction Response</div>
                <div>
                    <div className='grid-header'>Response Header</div>
                    <div className='grid-container'>
                        <FliptGrid headers={headerObj.response_header || []}
                            data={[response?.header] || []} />
                    </div>
                </div>
                <div>
                    <div className='grid-header'>Insurance Segment</div>
                    <div className='grid-container'>
                        <FliptGrid headers={headerObj.response_insurance_header || []}
                            data={[response?.response_insurance] || []} />
                    </div>
                </div>
                <div>
                    <div className='grid-header'>Claim Segment</div>
                    <div className='grid-container last'>
                        <FliptGrid headers={headerObj.response_claim_header || []}
                            data={(headerObj.response_claim_header?.length && [response?.transactions[0].claims_segment]) || []} />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    state: {
        nxTxnsData: state.claimLookup.nxTxnsData
    },
})

const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...ApiActions,
    }

    return {
        actions: bindActionCreators(allActions, dispatch),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(NxTransactions);
