import Immutable from 'seamless-immutable'
import { createActions, createReducer } from 'reduxsauce'

export const INITIAL_STATE = Immutable({
    data: [],
    totalRows: 0,
    drugsPerPage: 20,
    optionsData: {
        formularies: [],
        druglist: {}
    },
    last_row: 0
})

export const setFormularyExportDrugDataReducer = (state = INITIAL_STATE, { payload }) => ({
    ...state,
    data: payload
  })

export const setFormularyExportDrugOptionsDataReducer = (state = INITIAL_STATE, { payload }) => {
   return  {
       ...state,
       optionsData: payload
   }
}



export const clearReducer = () => INITIAL_STATE

export const { Types, Creators } = createActions({

    getFormularyExportDrugData: ['payload'],
    getFormularyExportDrugOptionsData: null,
    setFormularyExportDrugData: ['payload'],
    setFormularyExportDrugOptionsData: ['payload'],
    clearReducer: null
  })

  const HANDLERS = {
    [Types.CLEAR_REDUCER]: clearReducer,
    [Types.SET_FORMULARY_EXPORT_DRUG_DATA]: setFormularyExportDrugDataReducer,
    [Types.SET_FORMULARY_EXPORT_DRUG_OPTIONS_DATA]: setFormularyExportDrugOptionsDataReducer,
  }
  
  export default createReducer(INITIAL_STATE, HANDLERS)