import '../styles.scss'
import React, { useState } from 'react'
import { Accordion, Icon } from 'semantic-ui-react'
import FliptInput from '../../../../../../components/form/fliptInput'
import FliptDatePicker from '../../../../../../components/form/fliptDatePicker'
import FliptButton from '../../../../../../components/form/fliptButton'
import FliptTable from '../../../../../../components/fliptTable'
import FliptRadio from '../../../../../../components/form/fliptRadio'
import { convertCamelCaseToTitle, convertStrToDateObj } from '../../../../../../utils/utilities'

export default function TeamRoles(props) {
  const {
    form, roles, updateFields, updateIsActive, handleChange, editMode, submitForm, saveForm, goBack,
  } = props
  const {
    name, description, effective_start_date, effective_end_date, id, active: is_active,
  } = form
  const [activeIndex, setActiveIndex] = useState()
  const handleClick = (e, titleProps) => {
    if (e.target.type === 'checkbox') return
    const { index } = titleProps
    const newIndex = activeIndex === index ? -1 : index

    setActiveIndex(newIndex)
  }

  return (
    <div className="section">
      <section className="team-inputs-container spacing border-line shadow">
        <div className="team-inputs-wrap">
          <div className="team-inputs">
            <span>Team Name</span>
            <FliptInput placeholder="Team Name" className="createTeamInputs" name="name" defaultValue={name} onChange={updateFields} readOnly={!editMode} />
          </div>
          <div className="team-inputs">
            <span>Description</span>
            <FliptInput placeholder="Description" className="create-team-description" name="description" defaultValue={description} onChange={updateFields} readOnly={!editMode} />
          </div>
          <div className="team-inputs">
            <span>Effective Start Date</span>
            <FliptDatePicker className="create-team-start-date" name="effective_start_date" value={convertStrToDateObj(effective_start_date)} onChange={updateFields} readOnly={!editMode} />
          </div>
          <div className="team-inputs">
            <span>Effective End Date</span>
            <FliptDatePicker className="create-team-end-date" name="effective_end_date" value={convertStrToDateObj(effective_end_date)} onChange={updateFields} readOnly={!editMode} />
          </div>
          <div className="fields">
            <div className="fields-label">Is Active</div>
            <FliptRadio toggle label="Active" name="is_active" checked={is_active} onChange={updateIsActive} readOnly={!(editMode)} />
          </div>
        </div>
      </section>
      <section className="grid-container spacing border-line shadow">
        <div className="team-roles">
          <Accordion className="team-roles-accordion" styled>
            {
              roles.map((role, idx) => {
                const teamRoleHeading = `${role.role_name}`
                const gridData = roles.functions?.length ? role.functions
                  .map((t) => ({
                    modules: t.id.concat(": [", t.permissions.join(','), "]"), //convertCamelCaseToTitle(t),
                  })) : []
                return (
                  <div className="team-role">
                    <Accordion.Title active={activeIndex === idx} index={idx} onClick={handleClick}>
                      <Icon name="dropdown" />
                      <input type="checkbox" name={role.role_name} value={idx} checked={role.enable} onClick={handleChange} disabled={!editMode} />
                      {teamRoleHeading}
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === idx}>
                      <div className="role-features">
                        <FliptTable data={gridData} />
                      </div>
                    </Accordion.Content>
                  </div>
                )
              })
            }
          </Accordion>
        </div>
      </section>
      <div className="buttonContainer">
        <FliptButton name="Back" className="secondary" onClick={goBack} />
        {editMode && id && <FliptButton name="Save" className="primary" onClick={saveForm} />}
        {editMode && <FliptButton name="Submit" className="primary" onClick={submitForm} />}
      </div>
    </div>
  )
}
