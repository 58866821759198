import React, { PureComponent } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import './styles.scss'
import FliptButton from '../../../components/form/fliptButton'
import FliptDropdown from '../../../components/form/fliptDropdown'
import FliptInput from '../../../components/form/fliptInput'
import { Creators as AppActions } from '../../../redux/reducers/app'

class ManualClaimEntry extends PureComponent {
  // constructor(props) {
  //   super(props)

  //   this.state = {
  //     form: {
  //       patient: {
  //         account: '',
  //         dob: '',
  //         groupNumber: '',
  //         legalRepresentatives: '',
  //         memberLookup: '',
  //         patientAddress: '',
  //         patientName: '',
  //         patientPhone: '',
  //       },
  //       pharmacy: {
  //         npi: '',
  //         name: '',
  //         rxNumber: '',
  //         dispensedDate: '',
  //         quantity: '',
  //         daysOfSupply: '',
  //         drugNameStrength: '',
  //         drugNDC: '',
  //         physicianId: '',
  //         physicianName: '',
  //         totalPaid: '',
  //         deductible: '',
  //         outOfPocket: '',
  //         reimbursement: '',
  //         responseMessage: '',
  //       },
  //     },
  //   }
  // }

  render() {
    return (
      <div className="manual-claim-adjustment-container">
        <div>
          <h1>Manual Claim Adjustment</h1>
          <div>
            <FliptButton name="Adjust Claim" />
            {' '}
            |
            {' '}
            <FliptButton name="Reverse Claim" />
          </div>
        </div>
        <div className="section">
          <div className="section-header">Member Details</div>
          <div className="fields-container">
            <div className="fields">
              <div>
                <span>ACCOUNT</span>
                <FliptDropdown className="field" />
              </div>
              <div><FliptInput label="Name" stylized /></div>
            </div>
            <div className="fields">
              <div>
                <span>GROUP</span>
                <FliptDropdown className="field" />
              </div>
              <div><FliptInput label="Date Of Birth" stylized /></div>
            </div>
            <div className="fields">
              <div><FliptInput label="Member ID" stylized /></div>
            </div>
          </div>
        </div>
        <hr />
        <div className="section">
          <div className="section-header">Claim Details</div>
          <div className="fields-container">
            <div className="fields">
              <div>
                {' '}
                <FliptInput label="Auth ID" stylized />
              </div>
              <div>
                {' '}
                <FliptInput label="Sequence Number" stylized />
              </div>
              <div>
                {' '}
                <FliptInput label="Attribute to Deductible" stylized />
              </div>
              <div>
                {' '}
                <FliptInput label="Reimbursement" stylized />
              </div>
              <div>
                {' '}
                <FliptInput label="Out of Pocket Remaining" stylized />
              </div>
            </div>
            <div className="fields">
              <div>
                {' '}
                <FliptInput label="Claim Status" stylized />
              </div>
              <div>
                {' '}
                <FliptInput label="Drug Cost" stylized />
              </div>
              <div>
                {' '}
                <FliptInput label="Attribute to OOP" stylized />
              </div>
              <div>
                {' '}
                <FliptInput label="Plan Pays" stylized />
              </div>
            </div>
            <div className="fields">
              <div>
                {' '}
                <FliptInput label="Pharmacy" stylized />
              </div>
              <div>
                {' '}
                <FliptInput label="Members Paid" stylized />
              </div>
              <div>
                {' '}
                <FliptInput label="Reason for Adjustment" stylized />
              </div>
              <div>
                {' '}
                <FliptInput label="Deductible Remainging" stylized />
              </div>
            </div>
          </div>
        </div>
        <div>
          <FliptButton className="primary" name="Calculate Adjustment Amounts" />
          <FliptButton className="primary" name="Adjust Claim" />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  state: {
    app: state.app,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManualClaimEntry)
