import React, { PureComponent, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import moment from "moment";

import "./styles.scss";
import BreadCrumb from "../../../../components/breadCrumb";
import Config from "../../../../config";
import FliptButton from "../../../../components/form/fliptButton";
import FliptGrid from "../../../../components/fliptGrid";
import SubmenuNav from "../shared/navigation";
import { Creators as AppActions } from "../../../../redux/reducers/app";
import { Creators as NavigationActions } from "../../../../redux/reducers/navigation";
import { Creators as PriorAuthorizationCreationActions } from "../../../../redux/reducers/api/priorAuthorizationManagement";
import { Creators as RPMActions } from "../../../../redux/reducers/rpm";
import {
  LinkRenderer,
  ActionPopupMenuRenderer,
  ColorCodedStatusRenderer,
  ColorCodedStatusRendererUpdated,
} from "../../../../components/fliptGrid/cellRenderers";
import {
  convertDateTimeToDate,
  filterRowData,
  sortByName,
} from "../../../../utils/utilities";
import CopyPriorAuthorizationObject from "./createPriorAuthorization/CopyPriorAuthorizationObject";
import { calcGridRowHeight } from "../../../../utils/utilities";
import HeaderLayout from "../../../../components/headers/headerLayout";
import ImpactAnalysis from "../../impactAnalysis";

const PriorAuthorization = (props) => {
  const [showPopupFlag, setShowPopupFlag] = useState(false);
  const [record, setRecord] = useState({});

  const { state, actions } = props;
  const { priorAuthorizationDrafts } = state;

  useEffect(() => {
    setTimeout(
      () => actions.getAllPriorAuthorizationData(),
      Config.COMPONENT_DATA_LOAD_TIME
    );
  }, []);

  const sendForReview = (form) => {
    const payload = {
      doc_id: form.doc_id,
      module: "PRIOR_AUTHORIZATION",
    };

    actions.rpmSendForReview(payload);
    setTimeout(
      () => actions.getAllPriorAuthorizationData(),
      Config.COMPONENT_DATA_LOAD_TIME
    );
  };

  const _getRowHeight = (params) => calcGridRowHeight(undefined, 30);

  const checkActionType = (rowdata) => {
    if (rowdata && rowdata["qsArgs"]) {
      const doc_id = rowdata["doc_id"];
      const action = rowdata["qsArgs"]["selected_action"];

      let data = priorAuthorizationDrafts.filter((obj) => {
        return obj.id === doc_id;
      });
      setRecord(data);
      if (action === "copy_object") {
        setShowPopupFlag(true);
      } else if (action === "send_for_review") {
        sendForReview(rowdata);
      } else if (action === "impact_analysis") {
        actions.setModalComponent({
          modalProperties: {
            size: 'large',
          },
          contents: <ImpactAnalysis
            data={data}
          />,
        })
        actions.toggleModal()
      }
    }
  };

  const headers = [
    "actions",
    "doc_id",
    "name",
    "status",
    "version",
    "create_date",
    "author",
    "last_updated",
  ];
  const headerMapping = {
    module_name: "name",
    last_updated_by: "author",
    doc_version: "version",
  };

  const cellRendererParams = {
    name: {
      cellRenderer: LinkRenderer,
      path: "/prior-authorization",
      searchArgs: { doc_id: "doc_id" },
      minWidth: 300,
      state: { editMode: true },
    },
    status: {
      cellRenderer: ColorCodedStatusRendererUpdated,
      minWidth: 200,
    },
    doc_version: {
      minWidth: 50,
      // autoHeight: true,
      wrapText: true,
    },
    create_date: {
      minWidth: 150,
    },
    author: {
      minWidth: 350,
      wrapText: true,
    },
    actions: {
      cellRenderer: ActionPopupMenuRenderer,
      pinned: "right",
      maxWidth: 100,
      cellClass: "actionsColumns",
      suppressMenu: true,
      searchArgs: {
        onClick: (rowdata) => checkActionType(rowdata),
        path: "/prior-authorization-creation-management",
        qsArgs: {
          doc_id: "doc_id",
          action: "COPY_OBJECT",
          module: "PRIOR_AUTHORIZATION",
          status: "status",
        },
      },
    },
    doc_id: {
      hide: true,
    },
  };

  const gridData =
    priorAuthorizationDrafts && priorAuthorizationDrafts.length
      ? priorAuthorizationDrafts
        .filter((d) => d.module === "PRIOR_AUTHORIZATION")
        .map((d) => ({
          ...filterRowData(
            {
              ...d,
              doc_id: d.id,
              status: d.status,
              create_date: moment(d.create_date).format("MM-DD-YYYY"),
              last_updated: convertDateTimeToDate(d.last_updated),
            },
            headers,
            headerMapping
          ),
        }))
      : [];

  const gridOptions = {
    domLayout: "autoHeight",
    rowHeight: "auto",
    autoSizeStrategy: {
      type: "fitCellContents",
    },
  };

  return (
    <div className="priorAuthorizationManagement">
      <BreadCrumb {...props} />
      <HeaderLayout
        heading="Utilization Management"
        nameofButton="Create New Prior Authorization"
        link="prior-authorization"
      />
      <div className="tabsContainer">
        <SubmenuNav active="priorAuthorization" />
      </div>

      <div className="agGrid content">
        <FliptGrid
          data={
            gridData && gridData.length > 0 ? gridData.sort(sortByName) : []
          }
          headers={headers}
          cellRendererParams={cellRendererParams}
          gridOptions={gridOptions}
          getRowHeight={_getRowHeight}
          handleGridSize={true}
        />
      </div>
      {showPopupFlag && (
        <CopyPriorAuthorizationObject
          data={record}
          showTabFlag={showPopupFlag}
          setShowTabFlag={setShowPopupFlag}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  state: {
    allPriorAuthorizationData:
      state.priorAuthorizationCreation.allPriorAuthorizationData,
    priorAuthorizationDrafts: state.rpm.documents,
  },
});

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
    ...NavigationActions,
    ...PriorAuthorizationCreationActions,
    ...RPMActions,
  };

  return {
    actions: bindActionCreators(allActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PriorAuthorization);
