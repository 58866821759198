import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { Creators as UserActions } from "../../../redux/reducers/user";
import headerConstants from "../../../config/constants/header";
import "./styles.scss";

class Banner extends PureComponent {
  render() {
    return (
      <div id="banner">
        <div className="row">
          <div className="col-md-10">
            <h4 className="tool-menu-tip">{headerConstants.TOOL_MENU_TIP}</h4>
            <h2 className="tool-menu-title">{headerConstants.TOOL_MENU}</h2>
          </div>
          <div className="col-md-2">
            <div className="help-tool-tip-container">
              <Link to="/contact-help" className="help-tool-tip">
                <span>{headerConstants.HELP}</span> 
                <span className="help-tool-tip-link">{headerConstants.CLICK_HERE}</span>
            </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  state: {
    app: state.app,
    user: state.user,
    auth0Client: state?.ssoLogin?.auth0Client,
  },
});

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...UserActions,
  };

  return {
    actions: bindActionCreators(allActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Banner);
