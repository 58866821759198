import React from 'react'

import FliptGrid from '../../../../../components/fliptGrid'
import { filterRowData } from '../../../../../utils/utilities'
import { Link } from 'react-router-dom'
import FliptButton from '../../../../../components/form/fliptButton'
import './styles.scss'
import { isEmpty } from 'lodash'

export default ({ data, member_details }) => {

    const headers = ['phase', 'gcdc_accumulator_amount', 'troop_accumulator_amount'];

    const medicare_details = data;
    const medicareDetails = medicare_details && medicare_details.length ? medicare_details : []
    const order = ['Deductible', 'Initial Coverage', 'Coverage Gap', 'Catastrophic']
    const duplicate = medicareDetails.map(e => e.phase).filter((e, i, a) => a.indexOf(e) !== i)
    const medicareDetailsData = medicareDetails
    let filteredValue = []
    if (!isEmpty(duplicate)) {
        filteredValue = medicareDetailsData.filter((d) => duplicate.includes(d.phase) && (parseInt(d.gcdc_accumulator_amount) + parseInt(d.troop_accumulator_amount)) > 0)
        if (isEmpty(filteredValue))
            filteredValue = [medicareDetailsData.find((d) => duplicate.includes(d.phase))]
    }
    const gridData = [...medicareDetailsData.filter((d) => !duplicate.includes(d.phase)), ...filteredValue]
        .map((d) => {
            ['gcdc_accumulator_amount', 'troop_accumulator_amount'].map(key => {

                if (!d[key] || d[key] === '0')
                    d[key] = '$0.00'
                else if (typeof (d[key]) === "string") {
                    return d
                }
                else
                    d[key] = '$' + String((Math.round(parseFloat(d[key]) * 100) / 100).toFixed(2));
                return ''
            })
            return d
        })
        .sort((a, b) => order.indexOf(a.phase) - order.indexOf(b.phase))


    const cellRendererParams = {
        phase: {
            overrideHeader: 'Phase',
        },
        gcdc_accumulator_amount: {
            overrideHeader: 'Drug Spend',
            type: 'numericColumn'
        },
        troop_accumulator_amount: {
            overrideHeader: 'Troop',
            type: 'numericColumn'
        }
    }

    return (
        <div className="section-contents">
            <div className="fields-container">
                <div className="medicare-content">
                    <FliptGrid
                        data={gridData}
                        headers={headers}
                        cellRendererParams={cellRendererParams}
                    />
                </div>
            </div>
            {/* <div className="button-container">
                <Link to={`/deductible-details/?rx_member_id=${member_details.member_id}&medicare=true`}>
                    <FliptButton name="Detail" className="primary" />
                </Link>
            </div> */}
        </div>
    )
}
