import React, { useState, useRef } from 'react'
import './styles.scss'
import FliptButton from '../fliptButton'

const FliptFileUpload = (props) => {
	const { uploadFile, handleFileChange } = props
	const [selectedFile, setSelectedFile] = useState(null);
	const fileInput = useRef(null)

	const _handleFileChange = (e) => {
		setSelectedFile(e.target.files[0])
		handleFileChange(e.target.files[0])
	}
	const clearFileUpload = () => {
		setSelectedFile(null)
		fileInput.current.value = null
		handleFileChange(null)
	}

	return (
		<div id="flipt-file-upload">
			<div className="uploadInput">
				<span className="element">Select data file in xlsx, csv, and xml format</span>
				<input type="file" name="file" id="file" onChange={_handleFileChange} ref={fileInput}/>
			</div>
			<div className="file-upload-button-container">
				<FliptButton name="Upload" onClick={() => uploadFile()} className="primary" />
				<FliptButton name="Cancel" className="secondary" onClick={clearFileUpload} />
			</div>
		</div>
	)
}

export default FliptFileUpload
