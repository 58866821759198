import React from 'react'

import './styles.scss'
import FliptTable from '../../../components/fliptTable'
import { asMutable } from 'seamless-immutable'
export default (props) => {
  let { pharmactListData, showPriceModal } = props
  // var pharmacytListData = pharmactListData.filter(pharm =>  pharm.cp_pharmacy_info.cp_status == 'active')
  let pharmData = asMutable(pharmactListData).sort(function (a, b) { return a.total_payment_int - b.total_payment_int });
  pharmData.forEach(pharm => {
    pharm["pharmacycity"] = pharm?.address.split(",")[1]
    pharm["out_of_pocket"] = pharm?.employee_opc
  })
  pharmData = pharmData.sort((a, b) => a.npi.localeCompare(b.npi))
  const headers = ['name', 'pharmacycity', 'out_of_pocket', 'rewards']
  const agGridRef = {}
  return (
    <div className="pharmacy-list">
      <div className="title-wrap">
        <span className="subtitle">Pharmacy List</span>
        {/* <div className="sort-wrap">
          <span>Sort by</span>
          <FliptDropdown defaultValue={1} name="sort_by" options={[]} label="sort by" className="sort-list" />
        </div> */}
      </div>
      <div className="pharmacy-table">
        {/* <FliptGrid
          data={pharmactListData}
          headers={headers}
        /> */}
        <FliptTable
          agGridRef={agGridRef}
          className="drug-table"
          data={pharmData}
          headers={headers}
          rowClick={showPriceModal}
        />
      </div>
    </div>
  )
}
