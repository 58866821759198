import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Creators as FormularyCreationActions } from '../../../../../../redux/reducers/api/formularyCreationManagement'

import './styles.scss'
import { Creators as AppActions } from '../../../../../../redux/reducers/app'
import FliptGrid from '../../../../../../components/fliptGrid';
import FliptPaInput from '../../../../../../components/form/fliptPaInput';
import FliptPaDropdown from '../../../../../../components/form/fliptPaDropdown';
import FliptButton from '../../../../../../components/form/fliptButton';

class FRFSearch extends Component {
    constructor(props) {
        super(props)

        this.state = {
            param: {
                rxcui: '',
                TTY: '',
                rxnorm_name: '',
                related_bn: '',
                related_scdc: '',
                related_df: '',
                frf_category: ''
            },
            agGridRef: React.createRef(),
        }
    }

    componentDidMount() {
        const { actions: { closeSidebar } } = this.props
        closeSidebar()
    }

    _updateForm = (el, dropdown) => {
        const { param } = this.state
        const { name, value } = dropdown || el.currentTarget
        param[name] = value
        this.setState({ param })
    }

    frfSearch = () => {
        const { agGridRef } = this.state
        agGridRef.current.api.refreshServerSideStore({ route: null, purge: true })
    }

    clearFields = () => {
        const { agGridRef, param } = this.state
        Object.keys(param).forEach((key) => {
            param[key] = ''
        })
        this.setState({ param })
        agGridRef.current.api.refreshServerSideStore({ route: null, purge: true })
    }

    render() {
        const { actions } = this.props
        const { param, agGridRef } = this.state
        const headers = ['rxcui', 'frf_category', 'related_bn', 'related_scdc', 'related_df', 'rxnorm_name', 'TTY']
        const cellRendererParams = {
            related_bn: {
                overrideHeader: 'Related Brand Name (BN)'
            },
            related_scdc: {
                overrideHeader: 'Related Semantic Clinical Drug Component (SCDC)'
            },
            rxcui: {
                overrideHeader: 'RXCUI'
            },
            rxnorm_name: {
                overrideHeader: 'RxNorm Description'
            },
            frf_category: {
                overrideHeader: 'FRF Category'
            },
            TTY: {
                overrideHeader: 'Term Type'
            },
            related_df: {
                overrideHeader: 'Related Dose Form (DF)'
            }
        }
        const serverSideGridConfig = {
            rowModel: 'serverSide',
            serverSideStoreType: 'partial',
            cacheBlockSize: 20,
            pagination: true,
            paginationPageSize: 20,
        }
        const serverSideGridParams = {
            form: param,
            apiId: 'frf',
            sagaToCall: actions.frfSearch,
            headers: headers,
        }
        const frfOptions = [
            {
                key: 0,
                text: 'FRF',
                value: 'frf'
            },
            {
                key: 1,
                text: 'Exclusion',
                value: 'exclusion'
            },
            {
                key: 2,
                text: 'OTC Coverage',
                value: 'otc_coverage'
            }
        ]
        return (
            <div id="frf-search">
                <div className='header'>
                    FRF Search
                </div>
                <div className='devider' />
                <div className='field-outer-container'>
                    <div className='field-inner-container'>
                        <FliptPaInput stylized name='rxcui' value={param.rxcui} label='RXCUI' onChange={this._updateForm} />
                        <FliptPaDropdown options={frfOptions} stylized name='frf_category' value={param.frf_category} label='FRF Category' onChange={this._updateForm} />
                    </div>
                    <div className='field-inner-container'>
                        <FliptPaInput stylized name='TTY' value={param.TTY} label='Term Type' onChange={this._updateForm} />
                        <FliptPaInput stylized name='related_df' value={param.related_df} label='Related Dose Form (DF)' onChange={this._updateForm} />
                    </div>
                    <div className='field-inner-container'>
                        <FliptPaInput stylized name='rxnorm_name' value={param.rxnorm_name} label='RxNorm Description' onChange={this._updateForm} />
                        <FliptPaInput stylized name='related_bn' value={param.related_bn} label='Related Brand Name (BN)' onChange={this._updateForm} />
                    </div>
                    <div className='field-inner-container'>
                        <FliptPaInput stylized name='related_scdc' value={param.related_scdc} label='Related Semantic Clinical Drug Component (SCDC)' onChange={this._updateForm} />
                    </div>
                </div>
                <div className='button-container'>
                    <FliptButton name='Search' className='primary' onClick={() => this.frfSearch()} />
                    <FliptButton name='Clear' onClick={() => this.clearFields()} />
                </div>
                <div className='devider' />
                <div className='grid-container'>
                    <FliptGrid
                        headers={headers}
                        cellRendererParams={cellRendererParams}
                        agGridRef={agGridRef}
                        serverSideGridConfig={serverSideGridConfig}
                        serverSideGridParams={serverSideGridParams}
                    />
                </div>
            </div >
        )
    }
}

const mapStateToProps = (state) => ({
    state: {
        app: state.app,
        frfSearchData: state.groupManagement.frfSearchData
    },
})

const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...FormularyCreationActions,
        ...AppActions,
    }

    return {
        actions: bindActionCreators(allActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FRFSearch)
