import { createActions, createReducer } from 'reduxsauce'
import Config from '../../../config'
import AppLocalStorage from '../../../utils/localStorage'

export const INITIAL_STATE = {
  apiUrl: Config.apiUrl, // fast related functionality
  activeIndex: -1,
  isLoggedIn: !!AppLocalStorage.getItem('jwt'),
  menu_data: [],
  modal: {
    component: null,
    visible: false,
  },
  roles: [],
  roleFunctionHierarchy: [],
  serviceUrl: `${Config.apiUrl}/service`, // microservices related functionality
  transitionalPortal: {
    copy: null,
    header: null,
    visible: false,
  },
  sidebarIsOpen: false,
  loader: {
    isActive: false,
    size: 'massive',
    loaderText: 'Loading',
  },
  dataLoaded: false,
  version: Config.appVersion,
  urlHistory: {},
  sideDrawer: {
    component: null,
    visible: false,
  },
  companyCSSFilename: null,
}

export const clearReducer = (state = INITIAL_STATE) => {
  const transitionalPortal = { ...state.transitionalPortal }
  return {
    ...INITIAL_STATE,
    transitionalPortal,
  }
}

export const setAsLoggedInUserReducer = (state = INITIAL_STATE) => ({
  ...state,
  isLoggedIn: true,
})

export const initializeLoggedInUserReducer = (state = INITIAL_STATE) => ({
  ...state,
  isLoggedIn: true,
})

export const setCompanyCSSFilenameReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  companyCSSFilename: payload,
})

export const setModalComponentReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  modal: {
    ...state.modal,
    modalProperties: payload.modalProperties,
    component: payload.contents,
  },
})

export const setSideDrawerComponentReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  sideDrawer: {
    ...state.sideDrawer,
    modalProperties: payload.modalProperties,
    component: payload.contents,
  },
})

export const toggleModalReducer = (state = INITIAL_STATE) => {
  return ({
    ...state,
    modal: {
      ...state.modal,
      visible: !state.modal.visible,
    },
  })
}

export const toggleSideDrawerReducer = (state = INITIAL_STATE) => ({
  ...state,
  sideDrawer: {
    ...state.sideDrawer,
    visible: !state.sideDrawer.visible,
  },
})

export const initializeLoggedOutUserReducer = (state = INITIAL_STATE) => ({
  ...state,
  isLoggedIn: false,
})

export const toggleDataLoadedReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  dataLoaded: typeof payload !== 'undefined' ? payload : !state.dataLoaded,
})

export const toggleSidebarReducer = (state = INITIAL_STATE) => ({
  ...state,
  sidebarIsOpen: !state.sidebarIsOpen,
})

export const closeSidebarReducer = (state = INITIAL_STATE) => ({
  ...state,
  sidebarIsOpen: false,
})

export const setAppSettingsReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  ...payload,
})

export const setTransitionalPortalContentsReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  transitionalPortal: {
    visible: payload.visible,
    copy: payload.copy,
    header: payload.header,
  },
})

export const setActiveIndexReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  activeIndex: payload,
})

export const setLoaderContentsReducer = (state = INITIAL_STATE) => ({
  ...state,
  loader: {
    ...state.loader,
    isActive: !state.loader.isActive,
  },
})

export const setUrlHistoryReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  urlHistory: {
    ...state.urlHistory,
    ...payload,
  },
})

export const { Types, Creators } = createActions({
  clearReducer: null,
  displayTransitionalPortal: ['payload'],
  initializeApplication: ['payload'],
  initializeLoggedInUser: null,
  initializeLoggedOutUser: null,
  getApiUrl: ['payload'],
  getClientData: ['payload'],
  setActiveIndex: ['payload'],
  setAppSettings: ['payload'],
  setAsLoggedInUser: null,
  setLoaderContents: ['payload'],
  setModalComponent: ['payload'],
  setTransitionalPortalContents: ['payload'],
  setUrlHistory: ['payload'],
  toggleDataLoaded: ['payload'],
  toggleLoader: ['payload'],
  toggleModal: null,
  toggleSidebar: null,
  closeSidebar: null,
  toggleSideDrawer: null,
  setSideDrawerComponent: ['payload'],
  setCompanyCSSFilename: ['payload'],
})

const HANDLERS = {
  [Types.CLEAR_REDUCER]: clearReducer,
  [Types.INITIALIZE_LOGGED_IN_USER]: initializeLoggedInUserReducer,
  [Types.INITIALIZE_LOGGED_OUT_USER]: initializeLoggedOutUserReducer,
  [Types.SET_ACTIVE_INDEX]: setActiveIndexReducer,
  [Types.SET_APP_SETTINGS]: setAppSettingsReducer,
  [Types.SET_AS_LOGGED_IN_USER]: setAsLoggedInUserReducer,
  [Types.SET_LOADER_CONTENTS]: setLoaderContentsReducer,
  [Types.SET_MODAL_COMPONENT]: setModalComponentReducer,
  [Types.SET_TRANSITIONAL_PORTAL_CONTENTS]: setTransitionalPortalContentsReducer,
  [Types.SET_URL_HISTORY]: setUrlHistoryReducer,
  [Types.TOGGLE_DATA_LOADED]: toggleDataLoadedReducer,
  [Types.TOGGLE_MODAL]: toggleModalReducer,
  [Types.TOGGLE_SIDEBAR]: toggleSidebarReducer,
  [Types.CLOSE_SIDEBAR]: closeSidebarReducer,
  [Types.TOGGLE_SIDE_DRAWER]: toggleSideDrawerReducer,
  [Types.SET_SIDE_DRAWER_COMPONENT]: setSideDrawerComponentReducer,
  [Types.SET_COMPANY_CSS_FILENAME]: setCompanyCSSFilenameReducer,
}

export default createReducer(INITIAL_STATE, HANDLERS)
