import Immutable from 'seamless-immutable'
import { createActions, createReducer } from 'reduxsauce'

import { convertToCurrency } from '../../../utils/utilities'

export const INITIAL_STATE = Immutable({
  loading: false,
  drugPrices: [],
  drugDetails: [],
})

export const setDrugPricesReducer = (state = INITIAL_STATE, { payload }) => {
  try {
    const d = payload?.data.map((data) => ({
      ...data,
      alternate_penalty: data.alternate_penalty,
      alternate_reward: data.alternate_reward,
      baseline_price: data.baseline_price,
      deductible_accumulator_amount: data.deductible_accumulator_amount,
      drug_coinsurance: data.drug_coinsurance,
      drug_copay: data.drug_copay,
      drug_cost: data.drug_cost,
      employee_opc: data.employee_opc,
      employer_cost: data.employer_cost,
      ingredient_cost: data.ingredient_cost,
      member_choice_penalty_amount: data.member_choice_penalty_amount,
      oop_accumulator_amount: data.oop_accumulator_amount,
      pharmacy_discount: data.pharmacy_discount,
      pharmacy_dispensing_fee: data.pharmacy_dispensing_fee,
      rbp: data.rbp,
      rbp_penalty: data.rbp_penalty,
      rbp_reward: data.rbp_reward,
      retail_penalty: data.retail_penalty,
      retail_reward: data.retail_reward,
      rewards: data.rewards,
      rra_penalty_amount: data.rra_penalty_amount,
      total_payment: data.total_payment,
      total_payment_int: data.total_payment,
      total_penalty: data.total_penalty,
      total_reward: data.total_reward,
      unit_price: data.unit_price,
    }))

    return {
      ...state,
      drugPrices: d,
    }
  } catch (err) {
    console.log(err)

    return {
      ...state,
      drugPrices: payload?.data,
    }
  }
}

export const setLoadingReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  loading: payload.data,
})

export const clearReducer = () => INITIAL_STATE

export const setDrugDetailsReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  drugDetails: payload,
})

export const getDrugDetailsReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  drugDetails: payload,
})

export const clearDrugDetailsReducer = (state = INITIAL_STATE) => ({
  ...state,
  drugDetails: [],
  drugPrices: [],
})

export const clearDrugPriceListReducer = (state) => ({
  ...state,
  drugPrices: [],
})

export const { Types, Creators } = createActions({
  clearDrugDetails: null,
  clearDrugPriceList: null,
  clearReducer: null,
  getDrugDetails: ['payload'],
  getDrugPrices: ['payload'],
  setDrugDetails: ['payload'],
  setDrugPrices: ['payload'],
  setLoading: ['payload'],
})

const HANDLERS = {
  [Types.CLEAR_DRUG_DETAILS]: clearDrugDetailsReducer,
  [Types.CLEAR_DRUG_PRICE_LIST]: clearDrugPriceListReducer,
  [Types.CLEAR_REDUCER]: clearReducer,
  [Types.GET_DRUG_DETAILS]: getDrugDetailsReducer,
  [Types.SET_DRUG_DETAILS]: setDrugDetailsReducer,
  [Types.SET_DRUG_PRICES]: setDrugPricesReducer,
  [Types.SET_LOADING]: setLoadingReducer,

}

export default createReducer(INITIAL_STATE, HANDLERS)
