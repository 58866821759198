import {
    call,
    put,
    select,
    takeLatest,
    take,
    // all,
  } from 'redux-saga/effects'
  
  import moment from 'moment-timezone'
  import { Types, Creators as ClaimErrorLookupActions } from '../../reducers/api/claimErrorManagement'
  import { Creators as AppActions, Types as AppTypes } from '../../reducers/app'
  import { Creators as NavigationActions } from '../../reducers/navigation'
  // import { Creators as MemberActions } from '../../reducers/api/memberLookup'
  import {
    getAppState, getApiPath, getClaimErrorLookup, getHistory, getUserState, isMenuEmpty,
  } from '../../reducers/selectors'
  import { fetchPost } from '../../../utils/fetchApi'
  import { createQueryString, filterRowData } from '../../../utils/utilities'

  export default [
    getClaimErrorManagementDataWatcher
  ]

  /* WATCHERS */

  function* getClaimErrorManagementDataWatcher() {
    yield takeLatest(Types.GET_CLAIM_ERROR_MANAGEMENT_DATA, getClaimErrorManagementDataHandler)
  }

  /* HANDLERS */

  function* getClaimErrorManagementDataHandler({ payload }) {
    try {
      const {
        form, params, headers, headerMapping, isAdjustment = false, defaultClaimsAccess = false,
      } = payload
      let tempForm = { ...form }
      if (!tempForm.start_date || !tempForm.end_date){
        tempForm.start_date =  moment().subtract(450, 'days').toDate()
        tempForm.end_date = moment().add(1, 'day').toDate()
      }
      else{
        tempForm.start_date = moment(tempForm.start_date).add(1, 'day').toISOString()
        tempForm.end_date = moment(tempForm.end_date).add(1, 'day').toISOString()
      }
      
      const { serviceUrl } = yield select(getAppState)
      const history = yield select(getHistory)
      const { claimsPerPage } = yield select(getClaimErrorLookup)
      const { userCAG = {} } = yield select(getUserState)
      history.location.search = createQueryString({
        ...tempForm,
        offset: params?.request.startRow,
      })
      const qs = history.location.search ? `${history.location.search}&limit=${claimsPerPage}` : ''
      if (!qs && !isAdjustment) {
        params?.success({
          rowData: [],
          rowCount: 0,
        })
      } else {
        const empty = yield select(isMenuEmpty)
        if (empty) {
          yield take([AppTypes.SET_APP_SETTINGS])
        }
        const { api_path } = yield select(getApiPath, 'claim-error-management')
        const url = `${serviceUrl}${api_path}`
        const reqData = {
          start_date: tempForm?.start_date, // need it here to make it easy for json deserialization
          end_date: tempForm?.end_date, // need it here to make it easy for json deserialization
          sortModel: params?.request.sortModel,
          cag: {
            domain_name: userCAG?.account ?? [],
            group: userCAG?.group ?? [],
            defaultClaimsAccess: userCAG?.defaultClaimsAccess ?? false,
          }
        }
        console.log(reqData)
        let res
        if (!isAdjustment && headers) {
          const { data, last_row } = yield call(fetchPost, url, reqData)
          let gridData = data
          gridData = data?.map((d) => ({
            ...filterRowData(d, headers, headerMapping),
            processed_date: moment(`${d.startDate}Z`).tz('America/New_York').format('MM/DD/YYYY HH:mm:ss'),
            date_of_service: moment(d.date_of_service).format('MM/DD/YYYY'),
          }))
          params?.success({
            rowData: gridData,
            rowCount: last_row,
          })
        //   res = yield call(fetchPost, url.replace('/claim-error-management', '/member-details'), data?.map((d) => d.cardholder_id), false)
        //   if (res) {
        //     const dataWithMemberDetails = gridData.map((d, idx) => {
        //       const row = { ...d }
        //       row.first_name = res.data[idx].first_name ? res.data[idx].first_name : 'N/A'
        //       row.last_name = res.data[idx].last_name ? res.data[idx].last_name : 'N/A'
        //       row.date_of_birth = res.data[idx].date_of_birth ? moment(res.data[idx].date_of_birth).format('MM/DD/YYYY') : 'N/A'
        //       if (!row?.plan_name) {
        //         row.plan_name = res.data[idx].plan_name ? res.data[idx].plan_name : 'N/A'
        //       }
        //       row.is_vip = res?.data[idx]?.is_vip ?? false
        //       data[idx] = row
        //       return row
        //     })
        //     gridData = dataWithMemberDetails.map((d) => ({
        //       ...filterRowData(d, headers, headerMapping),
        //     }))
        //     params.success({
        //       rowData: gridData,
        //       rowCount: last_row,
        //     })
        //   }
        }
      }
    } catch (err) {
      console.log('getClaimErroRLookupManagementHandler Error > Data: ', err)
      const transitionalPortal = {
        header: 'Claim Error Lookup Failed',
        copy: err,
      }
      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    }
  }
  
  export {getClaimErrorManagementDataHandler}