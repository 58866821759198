import React, { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Creators as UserActions } from '../../redux/reducers/userManagement/index'

import PROGRESSION from '../../config/constants/progression'
import FliptDropdown from '../form/fliptDropdown'
import FliptCheckbox from '../form/fliptCheckbox'
import FliptInput from '../form/fliptInput'
import FliptRadio from '../form/fliptRadio'

const FliptHierarchyDropdown = (props) => {
  const [activeHierarchy, setActiveHierarchy] = useState([]);
  let [dropdownValues, setDropdownValues] = useState({})
  const [rerender, setRerender] = useState(0)
  const { state } = props;
  useEffect(() => {
    let hierarchyAccessArray = []
    let activeHierarchyAccessArray = []
    if (props.form.hierarchy && props.form?.hierarchy.length !== 0) {
      getActiveHierarchy({ ...Object.values(props.form.hierarchy).map(x => x)[0], level: 'TOP' }, activeHierarchyAccessArray)
    }
    if (state?.user?.newUserHierarchyData) {
      getActiveHierarchy({ name: 'TOP', children: Object.values(state.user.newUserHierarchyData).map(x => x[0]), level: 'TOP', TOP_id: 'TOP' }, hierarchyAccessArray)
      let hierarchyDropdown = {}
      if (activeHierarchyAccessArray.length !== 0) {
        activeHierarchyAccessArray.forEach((v) => {
          hierarchyDropdown[v.typeOfNode] = v.keys.map(v => v.value)
        })
      } else {
        hierarchyAccessArray.forEach((v) => {
          hierarchyDropdown[v.typeOfNode] = []
        })
      }
      setDropdownValues(hierarchyDropdown)
    }
    setActiveHierarchy(hierarchyAccessArray)
    setRerender(rerender + 1)
  }, [props.form.doc_name, props.form.name, props.form.enhanced_tier, props.form.hierarchy, state?.user?.newUserHierarchyData])

  const handleInputChange = (e, dropdown) => {
    const { setForm, form } = props
    const { name, value, checked } = dropdown || e.currentTarget
    if (name === 'is_hierarchy_global') {
      setForm({ ...form, [name]: checked ? true : false })
    }
    if (name === 'enhanced_tier' || name === 'exchange_tier' || name === 'medicare_drug_list' || name === 'supplemental_tier') {
      setForm({ ...form, [name]: checked ? 'Y' : 'N' })
    }
    if (activeHierarchy.filter(v => v.typeOfNode == name).length !== 0) {
      if (dropdownValues[name]) {
        dropdownValues[name] = value
      } else {
        dropdownValues = { ...dropdownValues, [name]: value }
      }
      _validateHierarchyDropdown(dropdownValues)
      setDropdownValues(dropdownValues)
      const { hierarchy, hierarchyIDs } = _convert(dropdownValues, props.state.user.newUserHierarchyData)
      setForm({
        ...form,
        hierarchy: hierarchy,
        hierarchyIDs: hierarchyIDs,
      })
    }
  }

  const _convert = (hierarchyDropdown, userCompanyHierarchyData) => {
    let hierarchy = [{ name: activeHierarchy.filter(v => v.typeOfNode == "organization")[0].keys[0].parent, children: [], level: 'TOP' }]
    let queue = []
    queue.push(hierarchy)
    Object.keys(hierarchyDropdown).forEach(key => {
      const currentDropdownValues = activeHierarchy.filter(v => v.typeOfNode == key)[0].keys
      queue.forEach(queueValue => {
        if (!queueValue) return
        queueValue.forEach(parentNode => {
          let childNode = parentNode['children']
          let parentOf = PROGRESSION[PROGRESSION.indexOf(key) + 1]
          let valueToParent = {}
          currentDropdownValues.forEach(dropdownElement => {
            valueToParent[dropdownElement.value] = { name: dropdownElement.parent, key: dropdownElement.key }
          })
          hierarchyDropdown[key].forEach((node) => {
            if (valueToParent[node]?.name == parentNode.name || key == "organization") {
              if (parentOf) {
                childNode.push({
                  name: node,
                  children: [],
                  level: PROGRESSION[PROGRESSION.indexOf(parentNode.level) + 1],
                  key: valueToParent[node]?.key,
                })
              } else {
                childNode.push({ name: node })
              }
            }
          })
          queue.push(childNode)
        })
      })
      queue.shift()
    })
    // this needs to be explored to make sure that the org.name is actually tied to the organization instead of just the top of the hierarchy
    const hierarchyOrgToCompanyMapping = {}

    Object.entries(userCompanyHierarchyData || {}).forEach(([company, value]) => {
      if (!company || !(value && Array.isArray(value) && value.length)) {
        if (!value || value.error) {
          console.log(`---- invalid org ${company} - ${value?.message}-----------`)
          return
        }
      }
      hierarchyOrgToCompanyMapping[value[0].organization_id] = company
    })
    const hierarchyNodeToOrgMapping = {}
    hierarchy[0].children.map((org) => {
      hierarchyNodeToOrgMapping[org.name] = org.name
      org.children.map((client) => {
        hierarchyNodeToOrgMapping[client.name] = org.name
        client.children.map((carrier) => {
          hierarchyNodeToOrgMapping[carrier.name] = org.name
          carrier.children.map((account) => {
            hierarchyNodeToOrgMapping[account.name] = org.name
            account.children.map((group) => {
              hierarchyNodeToOrgMapping[group.name] = org.name
            })
          })
        })
      })
    })
    const hierarchyIDs = {}
    Object.keys(hierarchyDropdown).forEach(key => {
      hierarchyDropdown[key].forEach(id => {
        if (!hierarchyIDs[hierarchyOrgToCompanyMapping[hierarchyNodeToOrgMapping[id]]]) hierarchyIDs[hierarchyOrgToCompanyMapping[hierarchyNodeToOrgMapping[id]]] = []
        hierarchyIDs[hierarchyOrgToCompanyMapping[hierarchyNodeToOrgMapping[id]]].push(activeHierarchy.find(x => x?.typeOfNode === key)?.keys?.find(x => x?.value === id)?.key)
      })
    })

    return { hierarchy, hierarchyIDs }
  }

  const _validateHierarchyDropdown = (hierarchyDropdown) => {
    Object.keys(hierarchyDropdown).forEach(key => {
      const parentKey = PROGRESSION[PROGRESSION.indexOf(key) - 1]
      const parentValues = hierarchyDropdown[parentKey]
      const currentValues = hierarchyDropdown[key]
      const valueParent = activeHierarchy.filter(v => v.typeOfNode == key)[0].keys
      if (parentValues) {
        valueParent.forEach((hierarchyNode) => {
          const currentIndex = currentValues.indexOf(hierarchyNode.value)
          if (hierarchyNode.value == currentValues[currentIndex]) {
            if (parentValues.indexOf(hierarchyNode.parent) == -1) {
              currentValues.splice(currentIndex, 1)
            }
          }
        })
      }
    })
  }

  const getActiveHierarchy = (data, finalHierarchy) => {
    if (!data) return finalHierarchy
    const keyData = Object.keys(data)
    if ((keyData.indexOf('id') > -1 || keyData.indexOf('name')) > -1 && keyData.length < 2) {
      return finalHierarchy
    }
    keyData.forEach((el) => {
      if (Array.isArray(data[el]) && el === 'children') {
        data[el].forEach((element) => {
          if (!element) { return }
          const hierarchyFromAPI = element?.active
          const elementID = hierarchyFromAPI ? element[element?.level + '_id'] : element.name || ""
          const elementName = hierarchyFromAPI ? element[element.level + '_name'] : element.name
          const parentName = hierarchyFromAPI ? data[data?.level + '_name'] : data.name
          const parentID = hierarchyFromAPI ? data[data?.level + '_id'] : data.name
          const elementKey = element?.key || ""
          const elementLevel = PROGRESSION[PROGRESSION.indexOf(data.level) + 1]
          const dropdownData = { value: elementID, text: (parentName ? (parentName + " - ") : "") + elementName, parent: parentID, key: elementKey, level: elementLevel }
          if (element?.access || element?.access === undefined) {
            var exists = false
            finalHierarchy.forEach(v => {
              if (v.typeOfNode == elementLevel) {
                v.keys.push(dropdownData)
                exists = true
              }
            })
            if (!exists) {
              finalHierarchy.push({
                typeOfNode: elementLevel,
                keys: [dropdownData],
              })
            }
          }
          return getActiveHierarchy(element, finalHierarchy)
        })
      }
    })
  }

  const rebuildKeys = (node) => {
    const parent = PROGRESSION[PROGRESSION.indexOf(node.typeOfNode) - 1]
    let keys = []
    var hierarchyDropdownIdx = -1
    var formParent = ""
    node.keys.forEach((nodeKey) => {
      var parentInDropdown = dropdownValues[parent]
      if (parentInDropdown) {
        hierarchyDropdownIdx = parentInDropdown.indexOf(nodeKey.parent)
        formParent = parentInDropdown[hierarchyDropdownIdx]
      }
      if (formParent == nodeKey.parent || node.typeOfNode == "organization") {
        keys = keys.concat(nodeKey)
      }
    })
    return keys
  }

  const { disabled, readOnly, multiple = true } = props

  return (
    <>
      <div className="dropdown-wrap">
        {!props?.form?.hideHierarchy && activeHierarchy.map((node) => {
          var keys = rebuildKeys(node)
          var isDisabled = false
          if (keys.length === 0) {
            keys = [{}]
            isDisabled = true
          }
          return (
            <div className="inputs">
              <span>{node.typeOfNode.charAt(0).toUpperCase() + node.typeOfNode.substring(1)}</span>
              <FliptDropdown disabled={disabled || isDisabled} readOnly={readOnly} className="dropdown" placeholder={`Select ${node.typeOfNode}`} multiple={multiple} selection item value={dropdownValues[node.typeOfNode]} onChange={handleInputChange} options={keys} name={node.typeOfNode} />
            </div>
          )
        })}
        {props?.showHierarchyGlobal && (<div>
          <div className="inputs">
            <span>Global?</span>
            <FliptRadio disabled={props?.form?.hideHierarchy} toggle label="" name="is_hierarchy_global" checked={props?.form?.is_hierarchy_global} onChange={handleInputChange} />
            {/* <FliptInput name="is_hierarchy_global" value={props?.form?.is_hierarchy_global ? 'Y' : 'N'} placeholder="Global?" disabled={true} /> */}
          </div>
        </div>)}
      </div>
      <div className='enhanced-checkbox-container'>
        {props.showEnhancedTier && (<div className="check">
          <FliptCheckbox checked={(props.form?.enhanced_tier === 'Y')} name='enhanced_tier' onClick={(el) => handleInputChange(el)} onChange={e => { }} disabled={disabled ?? false} readOnly={readOnly} />
          <span style={{ margin: 5 }}>{props.changeToDrugList ? 'Enhanced Drug List' : 'Enhanced Tier'}</span>
        </div>)}

        {
          props.showRxcui && (<div className="check">
            <FliptCheckbox checked={(props.form?.medicare_drug_list === 'Y')} name='medicare_drug_list' onClick={(el) => handleInputChange(el)} onChange={e => { }} disabled={(props.form?.exchange_tier === 'Y') ?? false} readOnly={readOnly} />
            <span style={{ margin: 5 }}>Medicare Drug List</span>
          </div>)
        }
        {
          props.showRxcui && (<div className="check">
            <FliptCheckbox checked={(props.form?.supplemental_tier === 'Y')} name='supplemental_tier' onClick={(el) => handleInputChange(el)} onChange={e => { }} disabled={disabled ?? false} readOnly={readOnly} />
            <span style={{ margin: 5 }}>Supplemental Drug List</span>
          </div>)
        }
        {
          props.showRxcui && (<div className="check">
            <FliptCheckbox checked={(props.form?.exchange_tier === 'Y')} name='exchange_tier' onClick={(el) => handleInputChange(el)} onChange={e => { }} disabled={(props.form?.medicare_drug_list === 'Y') ?? false} readOnly={readOnly} />
            <span style={{ margin: 5 }}>Exchange Drug List</span>
          </div>)
        }
      </div>
    </>

  )
}

const mapStateToProps = (state) => {
  return {
    state: {
      user: state?.user,
    },
  }
}

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...UserActions
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(FliptHierarchyDropdown)
