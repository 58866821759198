import Immutable from 'seamless-immutable'
import { createActions, createReducer } from 'reduxsauce'

export const INITIAL_STATE = Immutable({
  adjustmentCalculationData: {},
  paidClaimReversalData: {},
  duplicateClaimReversal: {},
  hasDuplicateClaims: false,
  adjustedClaimProcessData: {},
  calculateAdjustments: {},
  calculateAdjustmentsData: {},
  adjustClaim: {},
  adjustClaimData: {},
  reverseClaim: {},
  reverseClaimData: {},
  reprocessClaimData:{}
})

export const clearReducer = () => INITIAL_STATE

export const clearDataReducer = (state = INITIAL_STATE) => ({
  ...state,
  adjustClaim: null,
  reverseClaim: null,
})

export const setAdjustmentCalculationDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  adjustmentCalculationData: payload,
})

export const setPaidClaimReversalDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  paidClaimReversalData: payload,
})

export const setDuplicateClaimReversalReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  pendingClaimApprovals: payload,
})

export const setDuplicateClaimsReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  hasDuplicateClaims: payload,
})

export const setAdjustedClaimProcessDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  adjustedClaimProcessData: payload,
})

export const setCalculateAdjustmentsReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  calculateAdjustmentsData: payload,
})

export const getCalculateAdjustmentsReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  calculateAdjustments: payload,
})

export const setAdjustClaimReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  adjustClaim: payload,
})

export const getAdjustClaimReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  adjustClaimData: payload,
})

export const setReverseClaimReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  reverseClaim: payload,
})

export const getReverseClaimReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  reverseClaimData: payload,
})

export const setReprocessClaimReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  reprocessClaimData: payload,
})

export const { Types, Creators } = createActions({
  adjustClaimProcessData: ['payload'],
  clearDataReducer: null,
  clearReducer: null,
  findDuplicateClaims: null,
  getAdjustClaim: ['payload'],
  getAdjustmentCalculation: ['payload'],
  getCalculateAdjustments: ['payload'],
  getDuplicateClaimReversal: ['payload'],
  getReverseClaim: ['payload'],
  getReprocessClaim: ['payload'],
  postPaidClaimReversal: ['payload'],
  setAdjustClaim: ['payload'],
  setAdjustedClaimProcessData: ['payload'],
  setAdjustmentCalculationData: ['payload'],
  setCalculateAdjustments: ['payload'],
  setDuplicateClaimReversal: ['payload'],
  setDuplicateClaims: ['payload'],
  setPaidClaimReversalData: ['payload'],
  setReverseClaim: ['payload'],
  setReprocessClaim: ['payload'],
})

const HANDLERS = {
  [Types.CLEAR_REDUCER]: clearReducer,
  [Types.CLEAR_DATA_REDUCER]: clearDataReducer,
  [Types.SET_ADJUSTED_CLAIM_PROCESS_DATA]: setAdjustedClaimProcessDataReducer,
  [Types.SET_ADJUSTMENT_CALCULATION_DATA]: setAdjustmentCalculationDataReducer,
  [Types.SET_DUPLICATE_CLAIMS]: setDuplicateClaimsReducer,
  [Types.SET_DUPLICATE_CLAIM_REVERSAL]: setDuplicateClaimReversalReducer,
  [Types.SET_PAID_CLAIM_REVERSAL_DATA]: setPaidClaimReversalDataReducer,
  [Types.SET_CALCULATE_ADJUSTMENTS]: setCalculateAdjustmentsReducer,
  [Types.GET_CALCULATE_ADJUSTMENTS]: getCalculateAdjustmentsReducer,
  [Types.SET_ADJUST_CLAIM]: setAdjustClaimReducer,
  [Types.GET_ADJUST_CLAIM]: getAdjustClaimReducer,
  [Types.SET_REVERSE_CLAIM]: setReverseClaimReducer,
  [Types.GET_REVERSE_CLAIM]: getReverseClaimReducer,
  [Types.SET_REPROCESS_CLAIM]: setReprocessClaimReducer,
}

export default createReducer(INITIAL_STATE, HANDLERS)
