import Immutable from 'seamless-immutable'
import { createActions, createReducer } from 'reduxsauce'

export const INITIAL_STATE = Immutable({
  ndc: {},
  npi: {},
  manualClaimEntryData: {},
  ndcLookupFailed: false
})

export const clearReducer = () => INITIAL_STATE

export const setManualClaimEntryNDCReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  ndc: payload,
})

export const clearManualClaimEntryNDCReducer = (state) => ({
  ...state,
  ndc: {},
  ndcLookupFailed: false,
})
export const setManualClaimEntryNPIReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  npi: payload,
})

export const clearManualClaimEntryNPIReducer = (state) => ({
  ...state,
  npi: {},
})

export const setManualClaimEntryDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  manualClaimEntryData: payload,
})

export const setNdcLookupFailedReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  ndcLookupFailed: payload,
})



export const { Types, Creators } = createActions({
  clearReducer: null,
  clearManualClaimEntryNDC: null,
  getManualClaimEntryData: ['payload'],
  fetchManualClaimEntryNDC: ['payload'],
  setManualClaimEntryNDC: ['payload'],
  setManualClaimEntryData: ['payload'],
  putManualClaimEntry: ['payload'],
  setManualClaimEntryNPI: ['payload'],
  clearManualClaimEntryNPI: null,
  fetchManualClaimEntryNPI: ['payload'],
  setNdcLookupFailed: ['payload'],
})

const HANDLERS = {
  [Types.CLEAR_REDUCER]: clearReducer,
  [Types.SET_MANUAL_CLAIM_ENTRY_NDC]: setManualClaimEntryNDCReducer,
  [Types.CLEAR_MANUAL_CLAIM_ENTRY_NDC]: clearManualClaimEntryNDCReducer,
  [Types.SET_MANUAL_CLAIM_ENTRY_NPI]: setManualClaimEntryNPIReducer,
  [Types.CLEAR_MANUAL_CLAIM_ENTRY_NPI]: clearManualClaimEntryNPIReducer,
  [Types.SET_MANUAL_CLAIM_ENTRY_DATA]: setManualClaimEntryDataReducer,
  [Types.SET_NDC_LOOKUP_FAILED]: setNdcLookupFailedReducer,
}

export default createReducer(INITIAL_STATE, HANDLERS)
