import React from 'react'
import { Link } from 'react-router-dom'

import FliptButton from '../../../../../components/form/fliptButton'
import FliptInput from '../../../../../components/form/fliptInput'
import { createQueryString } from '../../../../../utils/utilities'

export default ({ data, memberState }) => {
  const { member_details, plan_details, benefit_details } = data
  let carrier = ""
  const isMedicare = benefit_details?.lob ? benefit_details?.lob.toLowerCase().includes('medicare') : false

  const {
    annual_deductible_family,
    annual_deductible_individual,
    annual_out_of_pocket_family,
    annual_out_of_pocket_individual,
    coverage_tier_name,
    plan_name,
    plan_year,
    display_plan_name,
    cms_contract_id,
    cms_pbp
  } = plan_details.current_plan || {}
  const isIndividualPlan = coverage_tier_name === 'Individual' || coverage_tier_name === 'Employee' || coverage_tier_name === 'Employee only';
  const isFamilyPlan = coverage_tier_name === 'Family' || coverage_tier_name === 'Children';
  const {
    domain_name,
    state,
    city,
    group,
    medicare_eligible,
    date_of_birth,
    eligibility
  } = member_details || {}
  if (eligibility && eligibility?.length > 0) {
    carrier = eligibility[0].carrier
  }
  const formularyQS = {
    account: domain_name,
    carrier: carrier,
    group,
    date_of_birth: date_of_birth,
    days_of_supply: "030"
  }
  return (
    <div className="section">
      <div className="section-header">
        <h2>Plan Details</h2>
      </div>
      <div className="fields-container">
        <div className="fields">
          <FliptInput value={plan_name} label="Plan Name" disabled stylized />
          {isMedicare ? <FliptInput value={benefit_details?.medicare_plan_type} label="Medicare Plan Type" disabled stylized /> :
            <FliptInput value={!isFamilyPlan && annual_deductible_individual && `$${parseFloat(annual_deductible_individual).toFixed(2)}`} label="Individual Deductible Limit" disabled stylized />
          }
          {isMedicare ? <FliptInput value={cms_contract_id} label="Contract ID" disabled stylized /> : <FliptInput value={!isIndividualPlan && annual_deductible_family && `$${parseFloat(annual_deductible_family).toFixed(2)}`} label="Family Deductible Limit" disabled stylized />}
        </div>
        <div className="fields">
          <FliptInput value={display_plan_name} label="Display Plan Name" disabled stylized />
          {isMedicare ? <FliptInput value={benefit_details?.special_program_type} label="Medicare Program" disabled stylized /> :
            <FliptInput value={!isFamilyPlan && annual_out_of_pocket_individual && `$${parseFloat(annual_out_of_pocket_individual).toFixed(2)}`}
              label="Individual OOP Limit" disabled stylized />}
          {isMedicare ? null : <FliptInput value={!isIndividualPlan && annual_out_of_pocket_family && `$${parseFloat(annual_out_of_pocket_family).toFixed(2)}`} label="Family OOP Limit" disabled stylized />}
        </div>
        <div className="fields">
          {isMedicare ? <FliptInput value={benefit_details?.lob} label="LOB" disabled stylized /> : <FliptInput value={coverage_tier_name} label="Coverage Tier" disabled stylized />}
          {isMedicare ? <FliptInput label="PBP" value={cms_pbp} disabled stylized /> : <FliptInput label="" disabled stylized />}
          {isMedicare ? null : <FliptInput value={medicare_eligible} label="Medicare Eligible" disabled stylized />}
        </div>
      </div>
      <div className="no-bg button-container">
        <Link to={`/plan-details${createQueryString(memberState)}`}>
          <FliptButton name="Detail" className="primary" />
        </Link>
        <Link to={{ pathname: '/formulary-lookup', state: formularyQS }}>
          <FliptButton name="Formulary Lookup" className="primary" />
        </Link>
        <Link to={`/pharmacy-lookup?pharmacystate=${state}&pharmacycity=${city}`}>
          <FliptButton name="Pharmacy Lookup" className="primary" />
        </Link>
      </div>
    </div>
  )
}
