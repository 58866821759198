import React, { useRef, useState, useEffect } from "react";
import QualifierName from "../QualifierName";
import QualifierOperators from "../QualifierOperators";
import QualifierValue from "../QualifierValue";
import QualifierAction from "../QualifierAction";
import '../styles.scss';


function QualifierRow(props) {
    const { getFieldRecord, opsByType } = props;
    const [operatorOptions, setOperatorOptions] = useState(opsByType["def"]);
    const [field, setField] = useState("");

    function setOperatorList(k) {
        setField(k);
        let fieldRecord = getFieldRecord(props.models, k);
        setOperatorOptions(opsByType[fieldRecord?.type] || opsByType["def"]);
    }

    useEffect(() => {
        setOperatorList(props.rowData?.field)
    }, [props.rowData?.field])

    return (
        <tr className="criteria-row">
            <td className="qualifier">
                <QualifierName
                    models={props.models}
                    onChange={setOperatorList}
                    updateRow={props.updateRow}
                    rowIndex={props.rowIndex}
                    value={props.rowData?.field}
                />
            </td>
            <td className="operator">
                <QualifierOperators
                    updateRow={props.updateRow}
                    operatorOptions={operatorOptions}
                    rowIndex={props.rowIndex}
                    value={props.rowData?.op}
                />
            </td>
            <td className="qualifier_value">
                <QualifierValue
                    field={field}
                    getFieldRecord={getFieldRecord}
                    models={props.models}
                    updateRow={props.updateRow}
                    operator={props.rowData?.op}
                    rowIndex={props.rowIndex}
                    value={props.rowData?.value}
                />
            </td>
            <td className="action">
                <QualifierAction
                    deleteRow={props.deleteRow}
                    rowIndex={props.rowIndex}
                />
            </td>
        </tr>
    );
}

export default QualifierRow;
