import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Accordion, Button, Icon } from 'semantic-ui-react'
import moment from 'moment'

import { Creators as AppActions } from '../../../../redux/reducers/app'

import './styles.scss'

import rowCellInfo from './data/rowCellInfo'
import FliptGrid from '../../../../components/fliptGrid'
import FliptInput from '../../../../components/form/fliptInput'
import FliptDatePicker from '../../../../components/form/fliptDatePicker'
import FliptButton from '../../../../components/form/fliptButton'
import AddRow from './addRow'
import { DeleteRowRenderer } from '../../../../components/fliptGrid/cellRenderers'
import { InputTextEditor, DropdownEditor, SearchEditor, DatePickerEditor } from '../../../../components/fliptGrid/cellEditors'
import { filterRowData, addRemoveDaysToDate, convertStrToDateObj } from '../../../../utils/utilities'
import FliptHierarchyDropdown from '../../../../components/form/fliptHierarchyDropdown'
import Qualifiers from '../../../../components/Qualifiers'

function RebateConditions(props) {
  const {
    state, form, setForm,
    rebateLevels, updateFields, activeRebateConditionLevel, editMode, showCriteria,
    handleChange, dropdownOptions, autoSuggestionValues,
    autoSuggestionResultSelect, autoSuggestionMinChars,
    addNewRebateConditionLevel, handleRebateConditionLevelClick, deleteRebateConditionLevel, addRebateCondition, delRebateCondition,
    generateRebate,
    saveRebate,
    sendForReview,
    doc_id,
    cellRenderers,
    allCriteriaIDs,
  } = props

  const {
    doc_name, effective_start_date, version, status, version_effective_date,
  } = form

  const { rebateLevelsDrugData } = state
  const { multiSourceOptions, brandGenericOptions, otcIndicatorOptions, DESIOptions,
    routeOfAdministrationOptions, drugIndicatorOptions, dosageFormOptions,
    thirdPartyRestrictionOptions, repackagerIndicatorOptions } = state.conditionsDropDownOptions

  rowCellInfo.brand_generic.options = brandGenericOptions
  rowCellInfo.desi_code.options = DESIOptions
  rowCellInfo.dosage_form_cd.options = dosageFormOptions
  rowCellInfo.maintenance_drug_code.options = drugIndicatorOptions
  rowCellInfo.multi_source.options = multiSourceOptions
  rowCellInfo.otc_indicator.options = otcIndicatorOptions
  rowCellInfo.repackaged_code.options = repackagerIndicatorOptions
  rowCellInfo.route_of_administration.options = routeOfAdministrationOptions
  rowCellInfo.third_party_restriction_code.options = thirdPartyRestrictionOptions

  if (editMode) {
    rowCellInfo.action = {
      type: 'deleteRow',
      disabled: false,
    }
    Object.keys(rowCellInfo).forEach((key) => { rowCellInfo[key].disabled = false })
  } else {
    delete rowCellInfo.action
    Object.keys(rowCellInfo).forEach((key) => { rowCellInfo[key].disabled = true })
  }

  const headers = Object.keys(rowCellInfo)

  const gridHeaders = [
    'gpi', 'drug_group', 'drug_class', 'drug_subclass', 'manufacturer', 'drug_name', 'multi_source', 'ndc',
    'ddid', 'brand_generic', 'otc_indicator', 'maintenance_drug_code', 'route_of_administration', 'desi_code',
  ]

  const minRebateStartDate = addRemoveDaysToDate(1, false)

  let cellRendererParams = {
    action: {
      cellRenderer: DeleteRowRenderer,
      state: {
        onClick: delRebateCondition,
      },
      width: 95,
    },
    pos_rebate_type: {
      overrideHeader: 'POS Rebate Type',
    },
    pos_rebate_factor: {
      overrideHeader: 'POS Rebate Factor',
    },
    effective_start_date: {
      valueFormatter: (params) => {
        if (!params.value) return ''
        return moment(params.value)?.format('YYYY-MM-DD') || params.value
      },
    },
    effective_end_date: {
      valueFormatter: (params) => {
        if (!params.value) return ''
        return moment(params.value)?.format('YYYY-MM-DD') || params.value
      },
    }
  }

  const cellEditorParams = {
    pos_rebate_type: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    pos_rebate_factor: {
      cellEditor: InputTextEditor,
      onChange: handleChange,
    },
    collection_factor: {
      cellEditor: InputTextEditor,
      onChange: handleChange,
    },
    gpi: {
      cellEditor: InputTextEditor,
      onChange: handleChange,
    },
    ndc: {
      cellEditor: InputTextEditor,
      onChange: handleChange,
    },
    drug_group: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    drug_class: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    drug_subclass: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    manufacturer: {
      cellEditor: SearchEditor,
      onChange: handleChange,
      autoSuggestionResultSelect,
    },
    drug_name: {
      cellEditor: SearchEditor,
      onChange: handleChange,
      autoSuggestionResultSelect,
    },
    multi_source: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    ddid: {
      cellEditor: InputTextEditor,
      onChange: handleChange,
    },
    brand_generic: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    otc_indicator: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    desi_code: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    route_of_administration: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    maintenance_drug_code: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    effective_start_date: {
      cellEditor: DatePickerEditor,
      onChange: handleChange,
    },
    effective_end_date: {
      cellEditor: DatePickerEditor,
      onChange: handleChange,
    },
    application_type_flag: {
      cellEditor: InputTextEditor,
      onChange: handleChange,
    },
    mfg_labeler_id: {
      cellEditor: InputTextEditor,
      onChange: handleChange,
    },
    repackaged_code: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    third_party_restriction_code: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    dosage_form_cd: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    member_notes: {
      cellEditor: InputTextEditor,
      onChange: handleChange,
    },
    agent_notes: {
      cellEditor: InputTextEditor,
      onChange: handleChange,
    },
    internal_notes: {
      cellEditor: InputTextEditor,
      onChange: handleChange,
    },
    claim_message_code: {
      cellEditor: InputTextEditor,
      onChange: handleChange,
    },
    claim_message_type: {
      cellEditor: InputTextEditor,
      onChange: handleChange,
    },
  }

  const qaCellRenderer = {
    route_of_administration: {
      hide: true
    },
    maintenance_drug_code: {
      hide: true
    },
    ndc: {
      hide: true
    },
  }

  cellRendererParams = { ...cellRendererParams, ...cellRenderers }
  return (
    <div className="section">
      <section className="pos-inputs-container spacing border-line shadow">
        <div className="pos-intputs-wrap">
          <div className="pos-inputs">
            <span>POS Rebate Name</span>
            <FliptInput placeholder="POS Rebate Name" name="doc_name" value={doc_name} disabled={!editMode || (!!version && (version !== '1.0')) || (!!status & (status === 'PUBLISHED'))} onChange={updateFields} />
          </div>
          <div className="pos-inputs">
            <span>Effective Start Date</span>
            <FliptDatePicker className="create-pos-start-date" placeholder="Effective Start Date" name="effective_start_date" minDate={minRebateStartDate} value={convertStrToDateObj(effective_start_date)} disabled={!editMode} onChange={updateFields} />
          </div>
          <div className="pos-inputs">
            <span>Version</span>
            <FliptInput className="create-pos-start-date" name="version" value={version} onChange={updateFields} format="MM-DD-YYYY" disabled />
          </div>
          <div className="pos-inputs">
            <span>Version Effective Date</span>
            <FliptDatePicker className="create-pos-start-date" name="version_effective_date" value={convertStrToDateObj(version_effective_date)} onChange={updateFields} format="MM-DD-YYYY" readOnly={!editMode} />
          </div>
        </div>
        <FliptHierarchyDropdown form={form} setForm={setForm} showHierarchyGlobal={true} />
      </section>
      <section className="grid-container spacing border-line shadow">
        {!!editMode
          && (
            <div className="add-pos-condition-level">
              <Button size="small" onClick={addNewRebateConditionLevel} color="youtube">
                <Icon name="add" />
                Add New POS Rebate Condition Level
              </Button>
            </div>
          )}
        <div className="pos-condition-levels">
          <Accordion className="pos-condition-levels-accordion" styled>
            {
              rebateLevels.map((rebateConditionLevel, idx) => {
                const rebateConditionLevelHeading = `POS Rebate Condition Level ${idx + 1}`
                const generateButtonHeading = `Generate Drugs for POS Rebate Condition Level ${idx + 1}`
                const agGridRef = React.createRef()
                const gridData = rebateLevelsDrugData[idx] && rebateLevelsDrugData[idx].length ? rebateLevelsDrugData[idx].map((d) => ({
                  ...filterRowData(d, gridHeaders),
                })) : []
                const hiddenColumn = Object.keys(cellRendererParams).filter(key => cellRendererParams[key]?.hide)
                const columnData = {}
                rebateConditionLevel?.map(ele => {
                  Object.keys(ele).forEach(key => {
                    columnData[key] = !!columnData[key] || !!ele[key]
                  })
                })
                hiddenColumn.forEach(key => {
                  if (columnData[key]) {
                    cellRendererParams[key].hide = false
                  }
                })

                const rebateConditions = !editMode ? { data: rebateConditionLevel, headers } : {
                  agGridRef,
                  autoSuggestionMinChars,
                  autoSuggestionValues: autoSuggestionValues,
                  cellEditorParams,
                  cellRendererParams,
                  data: rebateConditionLevel,
                  dropdownOptions: dropdownOptions[idx],
                  headers,
                  rowCellInfo,
                  stepLevel: idx,
                }

                return (
                  <div className="pos-condition-level">
                    <Accordion.Title
                      active={activeRebateConditionLevel === idx}
                      index={idx}
                      onClick={handleRebateConditionLevelClick}
                    >
                      <Icon name="dropdown" />
                      {rebateConditionLevelHeading}
                      <Icon className="pos-condition-level-delete" name="delete" onClick={(e) => deleteRebateConditionLevel(e, idx)} />
                    </Accordion.Title>
                    <Accordion.Content
                      active={activeRebateConditionLevel === idx}
                    >
                      <div className="pos-conditions">
                        <FliptGrid {...rebateConditions} />
                      </div>
                      <div className="pos-condition-level-button-container">
                        {!!editMode && (
                          <div className="pos-condition-level-add-cond-button">
                            <AddRow addRow={addRebateCondition} level={idx} />
                          </div>
                        )}
                        <FliptButton name={generateButtonHeading} className="primary searchButton" onClick={(e) => generateRebate(e, idx)} />
                      </div>
                      {!!showCriteria[idx] && (
                        <Qualifiers
                          allCriteriaIDs={allCriteriaIDs}
                          model_ids={["claim", "pharmacy"]}
                          stepLevel={idx}
                          parentType="rebate"
                          criteria_id={allCriteriaIDs?.[idx] || ""}
                          preventMultipleGetCalls
                        />
                      )}
                      {!!gridData.length && (
                        <div className="pos-condition-level-drugs">
                          <FliptGrid
                            data={gridData}
                            headers={gridHeaders}
                            cellRendererParams={qaCellRenderer}
                          />
                        </div>
                      )}
                    </Accordion.Content>
                  </div>
                )
              })
            }
          </Accordion>
        </div>
      </section>
      <div className="buttonContainer">
        {editMode && <FliptButton name="Save" className="primary searchButton" onClick={saveRebate} />}
        {editMode && doc_id && <FliptButton name="Send to Peers for Review/Approval" className="primary searchButton" onClick={sendForReview} />}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  state: {
    rebateLevelsDrugData: state.rebateCreation?.rebateLevelsDrugData,
    conditionsDropDownOptions: state.rpm.conditionsDropDownOptions,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RebateConditions)
