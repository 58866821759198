import React, { Component } from 'react'
import _ from 'lodash'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Creators as AppActions } from '../../../../redux/reducers/app'
import { Creators as AutoSuggestActions } from '../../../../redux/reducers/api/autoSuggest'
import { Creators as ProgramListCreationActions } from '../../../../redux/reducers/api/programListManagement'
import { Creators as RPMActions } from '../../../../redux/reducers/rpm'
import Config from '../../../../config'
import FliptInput from '../../../../components/form/fliptInput'
import FliptDropdown from '../../../../components/form/fliptDropdown'
import './styles.scss'
import FliptDatePicker from '../../../../components/form/fliptDatePicker'
import FliptButton from '../../../../components/form/fliptButton'
import { drugGroupValues } from '../../../../config/constants/clinical/drugGroups'
import { convertStrToDateObj, addRemoveDaysToDate } from '../../../../utils/utilities'
import FliptHierarchyDropdown from '../../../../components/form/fliptHierarchyDropdown'
import { customProgramTypeCodes } from '../../../../config/constants/clinical/drugGroups'

class CopyProgramListObject extends Component {
  constructor(props) {
    super(props)

    this.state = {
      form: {
        doc_name: props.data[0]?.['module_name'],
        effective_start_date: '',
        list_type: '',
        custom_program_type: '',
        doc_id: props.state?.priorAuthorizationData?.doc_id || '',
        module: 'PROGRAM_LIST',
        version: props.data[0]?.['version'],
        status: 'DRAFT',
        hierarchy: [],
      },
      activeProgramConditionLevel: 0,
      showQualifier: [false],
      dropdownValues: [[{
        drug_group: drugGroupValues, drug_class: [], drug_subclass: [],
      }]],
      autoSuggestionValues: [[{
        manufacturer: { values: [], loading: false }, drug_name: { values: [], loading: false },
      }]],
      listTypeValues: ['Include', 'Exclude', 'Clinical Program'],
      rbp_price_list: ['REFERENCE BASED PRICING LIST', 'OPIOID PROGRAM'],
      rowUpdated: null,
      columnUpdated: null,
      levelUpdated: null,
      autoSuggestionMinChars: 3,
    }
    this.conditions = {}
  }

  componentDidMount() {
    const { actions, data } = this.props
    actions.clearProgramListData()
    if (data[0]?.id) {
      actions.getProgramListData({ doc_id: data[0]?.id })
    }
  }

  componentDidUpdate(prevProps) {
    const { programListCondData, programListQualifierCondData } = this.props.state
    if (prevProps.state.programListCondData !== programListCondData
      || prevProps.state.programListQualifierCondData != programListQualifierCondData) {
      this.conditions = {
        programConditions: [...programListCondData],
        qualifierConditions: [...programListQualifierCondData]
      }
    }
  }

  componentWillUnmount() {
    this.props.actions.clearProgramListData()
  }

  _updateFields = (el, dateData) => {
    const { name, value } = dateData || el.currentTarget
    this.setState((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        [name]: value,
      },
    }))
  }

  setForm = (newForm) => {
    this.setState((prevState) => ({
      ...prevState,
      form: newForm
    }))
  }

  _saveProgramList = () => {
    const { props, state } = this
    const { form } = state
    if (!form.doc_name || !form.effective_start_date) {
      const transitionalPortal = {
        header: 'Program List Save Failed',
        copy: !form.doc_name ? 'Please input a Program Name before saving' : 'Please select a start date before saving',
      }
      props.actions.displayTransitionalPortal(transitionalPortal)
    } else {
      const { showTabFlag, setShowTabFlag } = this.props
      props.actions.saveProgramListData({ ...form, conditions: this.conditions });
      setShowTabFlag(!showTabFlag);
      setTimeout(() => props.actions.getAllProgramListData(), Config.COMPONENT_DATA_LOAD_TIME)
    }
  }


  render() {
    const { form, listTypeValues } = this.state
    const { showTabFlag, setShowTabFlag } = this.props
    const { doc_name, effective_start_date, list_type, custom_program_type } = form
    const customProgramTypeOptions = Object.keys(customProgramTypeCodes).map((d, idx) => ({
      key: idx,
      text: customProgramTypeCodes[d].text,
      value: customProgramTypeCodes[d].value,
    }))

    const listTypeOptions = listTypeValues.map((val, idx) => ({
      key: idx,
      text: val,
      value: val.toUpperCase(),
    }))

    const minProgramStartDate = addRemoveDaysToDate(1, false)

    return (
      <div id="createProgramList copy-object">
        <div className="content copy-object">
          <div className="program-inputs">
            <span>Program Name</span>
            <FliptInput className="createProgramListInputs" placeholder="Program Name" name="doc_name" value={doc_name} onChange={this._updateFields} />
          </div>
          <div className="program-inputs">
            <span>Effective Start Date</span>
            <FliptDatePicker className="create-program-start-date" placeholder="Effective Start Date" name="effective_start_date" minDate={minProgramStartDate} value={convertStrToDateObj(effective_start_date)} onChange={this._updateFields} />
          </div>
          <div className="program-inputs">
            <span>List Type</span>
            <FliptDropdown className="create-program-list-type" placeholder="List Type" name="list_type" value={list_type} options={listTypeOptions} selectOnBlur={false} scrolling clearable selection onChange={(e, dropdown) => this._updateFields(e, dropdown)} />
          </div>
          <div className="program-inputs">
            <span>Custom Program Type</span>
            <FliptDropdown className="create-program-list-custom-type" placeholder="Custom Program Type" name="custom_program_type" value={custom_program_type} options={customProgramTypeOptions} selectOnBlur={false} scrolling clearable selection onChange={(e, dropdown) => this._updateFields(e, dropdown)} />
          </div>
          <FliptHierarchyDropdown setForm={this.setForm} form={form} />
          <div className="buttonContainer">
            <FliptButton name="Copy Object" className="primary searchButton" onClick={this._saveProgramList} />
          </div>
          <div className="buttonContainer">
            <FliptButton name="Cancel" className="secondary searchButton" onClick={() => setShowTabFlag(!showTabFlag)} />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  state: {
    programListData: state.programListCreation.programListData,
    programListCondData: state.programListCreation.programListCondData,
    programListQualifierCondData: state.programListCreation.programListQualifierCondData
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
    ...ProgramListCreationActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CopyProgramListObject)
