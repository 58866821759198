import React, { useEffect, useState, bind } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import "./styles.scss";
import FliptButton from "../../../../components/form/fliptButton";
import { Creators as AppActions } from "../../../../redux/reducers/app";
import { Creators as UserActions } from "../../../../redux/reducers/userManagement/index";
import { Creators as NavigationActions } from "../../../../redux/reducers/navigation";
import FliptGrid from "../../../../components/fliptGrid";
import {
  ButtonRenderer,
  LinkRenderer,
} from "../../../../components/fliptGrid/cellRenderers";

class TableViewer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      questionFinalData: [],
      selectedTab: "QUESTIONNAIRE",
      current_question_id: "",
      showTabFlag: false,
    };
  }

  componentDidMount() {
    this.setState({
      questionFinalData: this.props.questionFinalData,
      drugsFinalData: this.props.drugsFinalData,
    });
  }

  componentDidUpdate(prevProps) {
    const { props } = this
    const prevState = prevProps.state
    const currState = props.state
    if (_.isEqual(prevState, currState)) return
    this.setState({
      questionFinalData: props.questionFinalData,
      drugsFinalData: props.drugsFinalData,
    });
  }

  _selectTab = (selectedTab) => {
    this.setState({ selectedTab });
  };
  onCurrentQuestionSelect = (e) => {
    const current_question_id = e.question_id || e.sequence || e.default_next_question_id || "";
    if (current_question_id != "END") {
      this.setState({ current_question_id, showTabFlag: true });
    }
  };
  questionCellRendererParams = {
    question_id: {
      overrideHeader: "Additional Responses",
      wrapText: true,
      autoHeight: true,
    },
    question_text: {
      overrideHeader: "Next Question",
      cellRenderer: ButtonRenderer,
      searchArgs: {
        onClick: this.onCurrentQuestionSelect,
        key: "sequence",
        className: "linkButton",
      },
      width: 150,
    },
    responseType: {
      overrideHeader: "Additional Actions",
      width: 150,
    },
  };
  questionCellRendererParams = {
    question_id: {
      cellRenderer: ButtonRenderer,
      searchArgs: {
        onClick: this.onCurrentQuestionSelect,
        key: "question_id",
        className: "linkButton",
      },
    },
    question_text: {
      width: 300,
      wrapText: true,
      autoHeight: true,
    },
  };
  choicesCellRendererParams = {
    choice_text: {
      overrideHeader: "Additional Responses",
      wrapText: true,
      autoHeight: true,
      width: 300
    },
    sequence: {
      overrideHeader: "Next Question",
      cellRenderer: ButtonRenderer,
      searchArgs: {
        onClick: this.onCurrentQuestionSelect,
        key: "sequence",
        className: "linkButton",
      },
      width: 200,
    },
    additional_actions: {
      overrideHeader: "Additional Actions",
      wrapText: true,
      autoHeight: true,
      width: 200,
    },
  };

  nextQuestionCellRendererParams = {
    question_number: {
      overrideHeader: "Question Number",
    },
    default_next_question_id: {
      overrideHeader: "Default Next Question",
      cellRenderer: ButtonRenderer,
      searchArgs: {
        onClick: this.onCurrentQuestionSelect,
        key: "default_next_question_id",
        className: "linkButton",
      },
      width: 150,
    },
  };
  questionnaireHeaders = ["question_id", "question_text", "question_type"];
  medicationHeaders = [
    "drug_name",
    "drug_strength",
    "ndc",
    "gpi",
    "other_drug_qualification",
  ];
  render() {
    const { selectedTab, showTabFlag } = this.state;
    const { questionFinalData, drugsFinalData, continueButtonParams } = this.props;
    return (
      <div className="tableContainer">
        <div className="tabContainer">
          <div
            className={
              selectedTab === "QUESTIONNAIRE" ? "selectedTab" : "unselectedTab"
            }
            onClick={() => this._selectTab("QUESTIONNAIRE")}
          >
            Questionnaire
          </div>
          <div
            className={
              selectedTab === "DRUGS"
                ? "selectedTab margin-left"
                : "unselectedTab margin-left"
            }
            onClick={() => this._selectTab("DRUGS")}
          >
            Medications
          </div>
        </div>
        <div className="gridOfContainer">
          {selectedTab === "QUESTIONNAIRE" && (
            <FliptGrid
              autoHeight={true}
              wrapText={true}
              cellRendererParams={this.questionCellRendererParams}
              data={questionFinalData}
              headers={this.questionnaireHeaders}
              className="fliptTable"
            />
          )}
          {selectedTab === "DRUGS" && (
            <FliptGrid
              data={drugsFinalData}
              headers={this.medicationHeaders}
              className="fliptTable"
            />
          )}
        </div>
        {continueButtonParams ? 
            (<div id="buttonContainer">
              <FliptButton 
                {...continueButtonParams} 
              />
              <FliptButton
                className="secondary"
                id="continueButton"
                name="Cancel"
                onClick={() =>
                  this.props.actions.navigateTo({
                    pathname: "/questionnaire-builder",
                  })
                }
              />
            </div>
            ) :
            (<FliptButton
              className="secondary"
              id="continueButton"
              name="Continue"
              onClick={() =>
                this.props.actions.navigateTo({
                  pathname: "/questionnaire-builder",
                })
              }
            />
            )
        }
        {showTabFlag ? (
          <div id="questionnairePopup">
            <div className="content popup">
              <div className="popupTable">
                <FliptGrid
                  headers={["question_id", "question_text", "question_type"]}
                  data={[
                    questionFinalData.filter(
                      (v) => v.question_id == this.state.current_question_id
                    )[0],
                  ]}
                  cellRendererParams={this.questionCellRendererParams}
                />
              </div>
              <div className="popupTable">
                <FliptGrid
                  headers={["choice_text", "sequence", "additional_actions"]}
                  data={
                    questionFinalData.filter(
                      (v) => v.question_id == this.state.current_question_id
                    )[0].choices
                  }
                  cellRendererParams={this.choicesCellRendererParams}
                />
              </div>
              <div className="buttonContainer">
                <FliptButton
                  name="Quit"
                  className="secondary searchButton"
                  onClick={() => this.setState({ showTabFlag: false })}
                />
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    state: {
      user: state.user,
    },
  };
};
const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
    ...UserActions,
    ...NavigationActions,
  };

  return {
    actions: bindActionCreators(allActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TableViewer);