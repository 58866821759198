export const qlCriteria = [
  {
    ql_criteria: 'Per Day',
    ql_qualifier: 'QL Ratio',
    ql_value: '',
  },
  {
    ql_criteria: 'Per Prescription',
    ql_qualifier: 'Max Quantity',
    ql_value: '',
  },
  {
    ql_criteria: 'Per Prescription',
    ql_qualifier: 'Max Days of Supply',
    ql_value: '',
  },
  {
    ql_criteria: 'Per Period',
    ql_qualifier: 'Lookback Period',
    ql_value: '',
  },
  {
    ql_criteria: 'Per Period',
    ql_qualifier: 'Max Quantity',
    ql_value: '',
  },
  {
    ql_criteria: 'Per Period',
    ql_qualifier: 'Max Days of Supply',
    ql_value: '',
  },
  {
    ql_criteria: 'Per Period',
    ql_qualifier: 'Max Number of Fills',
    ql_value: '',
  },
]

export const qualifierOperator = ['=', '!=', '>', '>=', '<', '<=']
