import React, { useState, useEffect, useRef } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import '../styles.scss'
import { Creators as MedicareSetupActions } from '../../../../../../redux/reducers/api/medicareSetup'
import FliptGrid from '../../../../../../components/fliptGrid'
import { filterRowData } from '../../../../../../utils/utilities'
import { DeleteRowRenderer } from '../../../../../../components/fliptGrid/cellRenderers'
import { InputTextEditor, DropdownEditor} from '../../../../../../components/fliptGrid/cellEditors'

function LicsPane(props) {
  const {
    data,
  } = props

  const licsDeductibleHeaders = ['LICS_level', 'LICS_deductible']
  const licsDeductibleHeaderMappings = {
    lics_level: 'LICS_level',
    lics_deductible: 'LICS_deductible',
  }

  const licsDeductibleData = data?.phases.length > 0 ? data.phases[0].lics_level
    .map((d) => Object.keys(d).reduce((obj, key) => {
      const value = key === 'lics_deductible' ? `$${d[key]}` : d[key]
      const newObj = { [licsDeductibleHeaderMappings[key]]: value }
      return { ...obj, ...newObj }
  }, {})) : []

  const headers = ['LICS_level', 'brand_cost_share', 'brand_cost_share_type',
    'minimum_brand_cost_share', 'lics_maximum_brand_cost_share', 'lics_generic_cost_share',
    'generic_cost_share_type', 'minimum_generic_cost_share', 'maximum_generic_cost_share']

  const headerMappings = {
    lics_level: 'LICS_level',
    lics_brand_cost_share: 'brand_cost_share',
    lics_brand_cost_share_type: 'brand_cost_share_type',
    lics_minimum_brand_cost_share: 'minimum_brand_cost_share',
    lics_maximum_brand_cost_share: 'maximum_brand_cost_share',
    lics_generic_cost_share: 'generic_cost_share',
    lics_generic_cost_share_type: 'generic_cost_share_type',
    lics_minimum_generic_cost_share: 'minimum_generic_cost_share',
    lics_maximum_generic_cost_share: 'maximum_generic_cost_share',
  }

  const allGridData = data?.phases.map((p) => p.lics_level.map((level) => ({
      ...filterRowData(level, headers, headerMappings),
    }))
  )

  return (
    <div className="wrapper">
      {data && Object.keys(data).length > 0 && (
        <>
        <section className="cost-share-grid lics-grid">
          <FliptGrid data={licsDeductibleData} headers={licsDeductibleHeaders} />
        </section>
        {data.phases.map((p, idx) => (
          <div key={p.phase}>
          <h1>{p.phase}</h1>
          <section className="cost-share-grid lics-grid">
            <FliptGrid data={allGridData[idx]} headers={headers} />
          </section>
          </div>
        ))}
        </>
      )}
      
    </div>
  )
}

const mapStateToProps = (state) => ({
  state: {
    activePlan: state.medicareSetup.activePlan,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...MedicareSetupActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LicsPane)
