import React, { useState, useRef, useEffect, useMemo } from "react";
// import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { Icon, Input } from "semantic-ui-react";
import { connect } from "react-redux";
import { Creators as ClientConfigurationActions, setNodeType } from "../../../redux/reducers/api/clientConfiguration";
import { Creators as AppActions } from '../../../redux/reducers/app'
import { Creators as UserActions } from '../../../redux/reducers/user'
// import BreadCrumb from "../../../components/breadCrumb";
import BreadCrumb from '../../../components/v2/breadCrumb';
import FliptButton from "../../../components/form/fliptButton";
import TabBar from "../../../components/tabBar";
import HierarchyDashboardTab from "./hierarchyDashboardTab";
import "./styles.scss";
import FliptHierarchyDropdown from "../../../components/form/newFliptHierarchyDropdown";
import {
  // REQUIRED_TAB_ID,
  COMPANY_TAB_ID,
  getDefaultTabConfigs,
  HIERARCHY_TABS_LOCAL_STORAGE_KEY,
} from "./Constant";
import { Table } from "semantic-ui-react";
import {
  filterData, checkSelection
} from "./Constant/mockHierarchyMini";
import RequiredTab from "./hierarchyDashboardTab/RequiredTab";
import AddOption from './AddOption'
import CloseModal from "./CloseModal";
import { Creators as groupManagementActions } from "../../../redux/reducers/api/groupManagement";

const ClientConfiguration = (props) => {
  const { state, actions } = props;
  const { user } = state;
  const { pageName, AllData, singleLevelAttribute, defaultButtonName, nodeType, nodeId, defaultGroupData } = state;
  const [headingName, setHeadingName] = useState("")
  const [modalPopup, setModalPopup] = useState(false);
  const [viewMore, setViewMore] = useState(false);
  const [search, setSearch] = useState("");
  const [tableList, setTableList] = useState({});
  const [form, setForm] = useState({});

  const defaultTabName = state.defaultTabName;
  const [activeTabId, setActiveTabId] = useState(COMPANY_TAB_ID);
  const queueRef = useRef(null);
  const [highlightedCell, setHighlightedCell] = useState(null);

  // checks if there are multiple children in the hierarchy
  const checkMultipleChildren = (hierarchy) => {
    const hasMultiple = hierarchy?.children?.length > 1;
    if (hasMultiple) return true
    else return hierarchy?.children?.length > 0 ? checkMultipleChildren(hierarchy?.children?.[0]) : false
  }

  const disableAddButton = useMemo(() => checkMultipleChildren(form?.hierarchy?.[0]), [JSON.stringify(form?.hierarchy)])

  useEffect(() => {
    actions.setPageName('dashboard');
    actions.setButtonName("Organization");
    actions.userGet();
  }, [])

  useEffect(() => {
    if (AllData.length > 0) {
      if (nodeType == 1) {
        actions.setSingleLevelAttribute(AllData[0]?.attributes)
      } else if (nodeType == 2) {
        actions.setSingleLevelAttribute(AllData[0]?.children[0]?.attributes)
      } else if (nodeType == 3) {
        actions.setSingleLevelAttribute(AllData[0]?.children[0]?.children[0]?.attributes)
      } else if (nodeType == 4) {
        actions.setSingleLevelAttribute(AllData[0]?.children[0]?.children[0]?.children[0]?.attributes)
      } else if (nodeType == 5) {
        actions.setSingleLevelAttribute(AllData[0]?.children[0]?.children[0]?.children[0]?.children[0]?.attributes)
      }
    }
  }, [AllData])

  const getParentId = (hierarchy, parentId) => {
    if (hierarchy?.key) {
      parentId = hierarchy?.key
      if (hierarchy?.children?.length > 0) {
        return getParentId(hierarchy?.children?.[0], parentId)
      } else return parentId
    } else {
      return parentId
    }
  }

  const setActiveTab = (id, tabs) => {
    setActiveTabId(id);
    actions.setTab(id);
  };
  const updateForm = (newForm) => {
    setForm(newForm);
    if (newForm?.hierarchy[0]?.children[0]?.level) {
      actions.setNodeType(1)
      actions.setButtonName("Client");
    } else {
      actions.setNodeType(1)
      actions.setButtonName("Organization");
    }
    if (newForm?.hierarchy[0]?.children[0]?.children[0]?.level) {
      actions.setNodeType(2)
      actions.setButtonName("Carrier");
    }
    if (newForm?.hierarchy[0]?.children[0]?.children[0]?.children[0]?.level) {
      actions.setNodeType(3)
      actions.setButtonName("Account");
    }
    if (newForm?.hierarchy[0]?.children[0]?.children[0]?.children[0]?.children[0]?.level) {
      actions.setNodeType(4)
      actions.setButtonName("Group");
    }

    // set parentId
    if (checkMultipleChildren(newForm?.hierarchy?.[0])) {
      actions.setParentId('')
    } else {
      const parentId = getParentId(newForm?.hierarchy?.[0]?.children?.[0], '')
      actions.setParentId(parentId)
    }
  };

  const _onChange = (el) => {
    const ct = el.currentTarget;
    setSearch(ct.value);
  };

  const searchData = () => {

    if (search.length > 2 || (Object?.keys(form)?.length > 0 && Object?.keys(form?.hierarchyIDs)?.length > 0)) {
      const result = form?.hierarchy ? checkSelection(form?.hierarchy) : null
      const filters = {
        organization_name: result?.organizationsData?.length > 0 ? result?.organizationsData : [],
        client_name: result?.clientsData?.length > 0 ? result?.clientsData : [],
        carrier_name: result?.carriersData?.length > 0 ? result?.carriersData : [],
        account_name: result?.accoutsData?.length > 0 ? result?.accoutsData : [],
        group_name: result?.groupsData?.length > 0 ? result?.groupsData : [],
        search: search.toLowerCase()
      };
      const filteredData = filterData(user?.newUserHierarchyData, filters);   //hirerachyStructure
      // actions.setButtonName("Organization");
      setHeadingName("");
      setHighlightedCell(null);
      setTableList(filteredData);
      actions.setSingleLevelAttribute({})
    } else {

      const transitionalPortal = {
        header: 'Missing fields',
        copy: 'To continue, choose Organization or input a search term.',
      }
      actions.displayTransitionalPortal(transitionalPortal)
      return;
    }


  };

  const addSingleData = () => {
    let data = {
      node_type: defaultButtonName.toLowerCase()
    }
    actions.setNodeId('');
    actions.setSingleLevelAttribute({})
    actions.setPageName('add');
    actions.getDefaultHierarchyList(data);
    actions.setAddMode(true);
    checkLevelName()
  }

  function checkLevelName() {
    const d = {
      Organization: 0,
      Client: 1,
      Carrier: 2,
      Account: 3,
      Group: 4
    }

    actions.setStep(d[defaultButtonName]);
  }

  // trigger any default actions when the table cells are clicked
  const handleCellClick = () => {
    actions.resetValidationResult()
  }

  // get the attributes based on node id
  const getAttributesByNodeId = (data, nodeId) => {
    if (data?.node_id === nodeId) {
      return data?.attributes
    } else {
      if (data?.children?.length > 0) {
        // Todo: current taking only the 1st child
        return getAttributesByNodeId(data?.children?.[0], nodeId)
      } else return {}
    }
  }

  // reset required features for cancel and discard 
  const handleDiscard = () => {
    // reset values
    actions.setSingleLevelAttribute(getAttributesByNodeId(AllData?.[0], nodeId));
    actions.resetValidationResult()
    actions.setEffectiveGroupRecord({})
  }

  const renderHierarchyTab = () => {
    return getDefaultTabConfigs(nodeType).map((tabConfig, index) => {
      return (
        tabConfig.id === defaultTabName && (
          <HierarchyDashboardTab
            key={index}
            tabConfig={tabConfig}
            queueRef={queueRef}
          />
        )
      );
    });
  };

  return (
    <div id="clientConfiguration">
      <BreadCrumb {...props} />
      <div className="horizontalLine" />

      {pageName === 'add' ?
        <div className="goBack"
          onClick={() => {
            actions.setSingleLevelAttribute({})
            actions.setPageName('dashboard');
            actions.setButtonName("Organization");
            setHeadingName("");
            setHighlightedCell(null);
            setTableList({});
            setSearch('')
            setForm({})
            actions.resetValidationResult()
            actions.userGet();
            actions.setAddMode(false)
            actions.setParentId('')
            actions.setNodeId('')
          }}
        >
          <Icon
            className='button-icon-secondary'
            name="chevron left"
          />
          Cancel, Go Back
        </div> :
        <div className="header">
          <FliptButton name={`Add ${defaultButtonName}`} onClick={addSingleData}
            className="secondary draft" disabled={disableAddButton} />
        </div>
      }
      {
        pageName == 'add' && <AddOption />
      }

      {pageName == 'dashboard' &&
        <div>
          <div className="horizontalLine" />
          {user?.newUserHierarchyData &&
            <div >
              <FliptHierarchyDropdown setForm={updateForm} form={form} />
            </div>
          }
          <div className="search-row ">
            <Input icon placeholder="Search..." className="search-input gap">
              <Icon name="search" />
              <input onChange={_onChange} value={search} />
            </Input>
            <FliptButton
              name="Search"
              className="secondary draft gap"
              searchData
              onClick={() => {
                searchData();
              }}
            />
            <FliptButton
              name="Clear"
              className="secondary draft"
              searchData
              onClick={() => {
                actions.setSingleLevelAttribute({})
                actions.setPageName('dashboard');
                actions.setButtonName("Organization");
                setHeadingName("");
                setForm({})
                setHighlightedCell(null);
                setTableList({});
                setSearch('');
                actions.setAllData({})
              }}
            />
          </div>
          {Object.keys(tableList).length > 0 && (
            <div id="TableList">
              <Table id="TableData" sortable celled fixed className="table-cover">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>
                      Organization
                    </Table.HeaderCell>
                    <Table.HeaderCell>Client</Table.HeaderCell>
                    <Table.HeaderCell>Carrier</Table.HeaderCell>
                    <Table.HeaderCell>Account</Table.HeaderCell>
                    <Table.HeaderCell>Group</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {Object.keys(tableList).map((orgId) =>
                    tableList[orgId].map((organization, orgIndex) =>
                      organization?.children.length > 0 ? organization?.children?.map((client) =>
                        client?.children.length > 0 ? client?.children?.map((carrier) =>
                          carrier?.children.length > 0 ? carrier?.children?.map((account) =>
                            account?.children?.length > 0 ? account?.children?.map((group, groupIndex) => (
                              <Table.Row key={group.key}>
                                <Table.Cell
                                  className={
                                    highlightedCell ==
                                      `${organization.key}_${client.key}_${carrier.key}_${account.key}_${group.key}`
                                      ? "selected"
                                      : ""
                                  }
                                  onClick={() => {
                                    let filter = {
                                      get_all_children: false,
                                      node_id: [organization.key],
                                      get_attributes: true,
                                      return_nested: true,
                                      get_cascaded_attributes: true,
                                      get_attribute_source: true
                                    }
                                    setHighlightedCell(
                                      `${organization.key}_${client.key}_${carrier.key}_${account.key}_${group.key}`
                                    );
                                    actions.setNodeType(1)
                                    actions.setParentId(organization.key)
                                    actions.getHierarchyDetail(filter)
                                    actions.setNodeId(organization.key)
                                    actions.setButtonName("Client");
                                    setHeadingName(`${organization.level} : ${organization.organization_name}`)
                                    handleCellClick()
                                  }}
                                >
                                  {organization?.organization_name}
                                </Table.Cell>
                                <Table.Cell
                                  className={
                                    highlightedCell ==
                                      `${client.key}_${carrier.key}_${account.key}_${group.key}`
                                      ? "selected"
                                      : ""
                                  }
                                  onClick={() => {
                                    let payload = {
                                      get_all_children: false,
                                      node_id: [client.key],
                                      get_attributes: true,
                                      return_nested: true,
                                      get_cascaded_attributes: true,
                                      get_attribute_source: true
                                    }
                                    setHighlightedCell(
                                      `${client.key}_${carrier.key}_${account.key}_${group.key}`
                                    );
                                    actions.setNodeType(2)
                                    actions.setParentId(client.key)
                                    actions.setNodeId(client.key)
                                    actions.getHierarchyDetail(payload)
                                    setHeadingName(`${client.level} : ${client.client_name}`)
                                    actions.setButtonName("Carrier");
                                    handleCellClick()
                                  }}
                                >
                                  {client?.client_name}
                                </Table.Cell>
                                <Table.Cell
                                  className={
                                    highlightedCell ==
                                      `${carrier.key}_${account.key}_${group.key}`
                                      ? "selected"
                                      : ""
                                  }
                                  onClick={() => {
                                    let payload = {
                                      get_all_children: false,
                                      node_id: [carrier.key],
                                      get_attributes: true,
                                      return_nested: true,
                                      get_cascaded_attributes: true,
                                      get_attribute_source: true
                                    }
                                    setHighlightedCell(
                                      `${carrier.key}_${account.key}_${group.key}`
                                    );
                                    actions.setNodeType(3)
                                    actions.setParentId(carrier.key)
                                    actions.setNodeId(carrier.key)
                                    actions.getHierarchyDetail(payload)
                                    setHeadingName(`${carrier.level} : ${carrier.carrier_name}`)
                                    actions.setButtonName("Account");
                                    handleCellClick()
                                  }}
                                >
                                  {carrier?.carrier_name}
                                </Table.Cell>
                                <Table.Cell
                                  className={
                                    highlightedCell == `${account.key}_${group.key}`
                                      ? "selected"
                                      : ""
                                  }
                                  onClick={() => {
                                    let payload = {
                                      get_all_children: false,
                                      node_id: [account.key],
                                      get_attributes: true,
                                      return_nested: true,
                                      get_cascaded_attributes: true,
                                      get_attribute_source: true
                                    }
                                    setHighlightedCell(
                                      `${account.key}_${group.key}`
                                    );
                                    actions.setNodeType(4)
                                    actions.setParentId(account.key)
                                    actions.setNodeId(account.key)
                                    actions.getHierarchyDetail(payload)
                                    setHeadingName(`${account.level} : ${account.account_name}`)
                                    actions.setButtonName("Group");
                                    handleCellClick()
                                  }}
                                >
                                  {account?.account_name}
                                </Table.Cell>
                                <Table.Cell
                                  className={
                                    highlightedCell === group.key ? "selected" : ""
                                  }
                                  onClick={() => {
                                    let payload = {
                                      get_all_children: false,
                                      node_id: [group.key],
                                      get_attributes: true,
                                      return_nested: true,
                                      get_cascaded_attributes: true,
                                      get_attribute_source: true
                                    }
                                    actions.setNodeType(5)
                                    setHighlightedCell(group.key);
                                    actions.setNodeId(group.key)
                                    actions.setParentId(account.key)
                                    actions.getHierarchyDetail(payload)
                                    actions.setButtonName("Group");
                                    setHeadingName(`${group.level} : ${group.group_name}`)
                                    handleCellClick()
                                  }}
                                >
                                  {group?.group_name}
                                </Table.Cell>
                              </Table.Row>
                            )) :
                              (
                                <Table.Row key={account.key}>
                                  <Table.Cell
                                    className={
                                      highlightedCell ==
                                        `${organization.key}_${client.key}_${carrier.key}_${account.key}`
                                        ? "selected"
                                        : ""
                                    }
                                    onClick={() => {
                                      let filter = {
                                        get_all_children: false,
                                        node_id: [organization.key],
                                        get_attributes: true,
                                        return_nested: true,
                                        get_cascaded_attributes: true,
                                        get_attribute_source: true
                                      }
                                      setHighlightedCell(
                                        `${organization.key}_${client.key}_${carrier.key}_${account.key}`
                                      );
                                      actions.setNodeType(1)
                                      actions.setParentId(organization.key)
                                      actions.setNodeId(organization.key)
                                      actions.getHierarchyDetail(filter)
                                      actions.setButtonName("Client");
                                      setHeadingName(`${organization.level} : ${organization.organization_name}`)
                                      handleCellClick()
                                    }}
                                  >
                                    {organization?.organization_name}
                                  </Table.Cell>
                                  <Table.Cell
                                    className={
                                      highlightedCell ==
                                        `${client.key}_${carrier.key}_${account.key}`
                                        ? "selected"
                                        : ""
                                    }
                                    onClick={() => {
                                      let payload = {
                                        get_all_children: false,
                                        node_id: [client.key],
                                        get_attributes: true,
                                        return_nested: true,
                                        get_cascaded_attributes: true,
                                        get_attribute_source: true
                                      }
                                      setHighlightedCell(
                                        `${client.key}_${carrier.key}_${account.key}`
                                      );
                                      actions.setNodeType(2)
                                      actions.setParentId(client.key)
                                      actions.setNodeId(client.key)
                                      actions.getHierarchyDetail(payload)
                                      setHeadingName(`${client.level} : ${client.client_name}`)
                                      actions.setButtonName("Carrier");
                                      handleCellClick()
                                    }}
                                  >
                                    {client?.client_name}
                                  </Table.Cell>
                                  <Table.Cell
                                    className={
                                      highlightedCell ==
                                        `${carrier.key}_${account.key}`
                                        ? "selected"
                                        : ""
                                    }
                                    onClick={() => {
                                      let payload = {
                                        get_all_children: false,
                                        node_id: [carrier.key],
                                        get_attributes: true,
                                        return_nested: true,
                                        get_cascaded_attributes: true,
                                        get_attribute_source: true
                                      }
                                      setHighlightedCell(
                                        `${carrier.key}_${account.key}`
                                      );
                                      actions.setNodeType(3)
                                      actions.setParentId(carrier.key)
                                      actions.setNodeId(carrier.key)
                                      actions.getHierarchyDetail(payload)
                                      setHeadingName(`${carrier.level} : ${carrier.carrier_name}`)
                                      actions.setButtonName("Account");
                                      handleCellClick()
                                    }}
                                  >
                                    {carrier?.carrier_name}
                                  </Table.Cell>
                                  <Table.Cell
                                    className={
                                      highlightedCell == `${account.key}`
                                        ? "selected"
                                        : ""
                                    }
                                    onClick={() => {
                                      let payload = {
                                        get_all_children: false,
                                        node_id: [account.key],
                                        get_attributes: true,
                                        return_nested: true,
                                        get_cascaded_attributes: true,
                                        get_attribute_source: true
                                      }
                                      setHighlightedCell(
                                        `${account.key}`
                                      );
                                      actions.setNodeType(4)
                                      actions.setParentId(account.key)
                                      actions.setNodeId(account.key)
                                      actions.getHierarchyDetail(payload)
                                      setHeadingName(`${account.level} : ${account.account_name}`)
                                      actions.setButtonName("Group");
                                      handleCellClick()
                                    }}
                                  >
                                    {account?.account_name}
                                  </Table.Cell>
                                  <Table.Cell></Table.Cell>
                                </Table.Row>
                              )
                          ) :
                            (
                              <Table.Row key={carrier.key}>
                                <Table.Cell
                                  className={
                                    highlightedCell ==
                                      `${organization.key}_${client.key}_${carrier.key}`
                                      ? "selected"
                                      : ""
                                  }
                                  onClick={() => {
                                    let filter = {
                                      get_all_children: false,
                                      node_id: [organization.key],
                                      get_attributes: true,
                                      return_nested: true,
                                      get_cascaded_attributes: true,
                                      get_attribute_source: true
                                    }
                                    setHighlightedCell(
                                      `${organization.key}_${client.key}_${carrier.key}`
                                    );
                                    actions.setNodeType(1)
                                    actions.setParentId(organization.key)
                                    actions.setNodeId(organization.key)
                                    actions.getHierarchyDetail(filter)
                                    actions.setButtonName("Client");
                                    setHeadingName(`${organization.level} : ${organization.organization_name}`)
                                    handleCellClick()
                                  }} >
                                  {organization?.organization_name}
                                </Table.Cell>
                                <Table.Cell
                                  className={
                                    highlightedCell ==
                                      `${client.key}_${carrier.key}`
                                      ? "selected"
                                      : ""
                                  }
                                  onClick={() => {
                                    let payload = {
                                      get_all_children: false,
                                      node_id: [client.key],
                                      get_attributes: true,
                                      return_nested: true,
                                      get_cascaded_attributes: true,
                                      get_attribute_source: true
                                    }
                                    setHighlightedCell(
                                      `${client.key}_${carrier.key}`
                                    );
                                    actions.setNodeType(2)
                                    actions.setParentId(client.key)
                                    actions.setNodeId(client.key)
                                    actions.getHierarchyDetail(payload)
                                    setHeadingName(`${client.level} : ${client.client_name}`)
                                    actions.setButtonName("Carrier");
                                    handleCellClick()
                                  }}
                                >
                                  {client?.client_name}
                                </Table.Cell>
                                <Table.Cell
                                  className={
                                    highlightedCell ==
                                      `${carrier.key}`
                                      ? "selected"
                                      : ""
                                  }
                                  onClick={() => {
                                    let payload = {
                                      get_all_children: false,
                                      node_id: [carrier.key],
                                      get_attributes: true,
                                      return_nested: true,
                                      get_cascaded_attributes: true,
                                      get_attribute_source: true
                                    }
                                    setHighlightedCell(
                                      `${carrier.key}`
                                    );
                                    actions.setNodeType(3)
                                    actions.setParentId(carrier.key)
                                    actions.setNodeId(carrier.key)
                                    actions.getHierarchyDetail(payload)
                                    setHeadingName(`${carrier.level} : ${carrier.carrier_name}`)
                                    actions.setButtonName("Account");
                                    handleCellClick()
                                  }}
                                >
                                  {carrier?.carrier_name}
                                </Table.Cell>
                                <Table.Cell></Table.Cell>
                                <Table.Cell></Table.Cell>
                              </Table.Row>
                            )
                        ) : (
                          <Table.Row key={client.key}>
                            <Table.Cell
                              className={
                                highlightedCell ==
                                  `${organization.key}_${client.key}`
                                  ? "selected"
                                  : ""
                              }
                              onClick={() => {
                                let filter = {
                                  get_all_children: false,
                                  node_id: [organization.key],
                                  get_attributes: true,
                                  return_nested: true,
                                  get_cascaded_attributes: true,
                                  get_attribute_source: true
                                }
                                setHighlightedCell(
                                  `${organization.key}_${client.key}`
                                );
                                actions.setNodeType(1)
                                actions.setParentId(organization.key)
                                actions.setNodeId(organization.key)
                                actions.getHierarchyDetail(filter)
                                actions.setButtonName("Client");
                                setHeadingName(`${organization.level} : ${organization.organization_name}`)
                                handleCellClick()
                              }} >
                              {organization?.organization_name}
                            </Table.Cell>
                            <Table.Cell
                              className={
                                highlightedCell ==
                                  `${client.key}`
                                  ? "selected"
                                  : ""
                              }
                              onClick={() => {
                                let payload = {
                                  get_all_children: false,
                                  node_id: [client.key],
                                  get_attributes: true,
                                  return_nested: true,
                                  get_cascaded_attributes: true,
                                  get_attribute_source: true
                                }
                                setHighlightedCell(
                                  `${client.key}`
                                );
                                actions.setNodeType(2)
                                actions.setParentId(client.key)
                                actions.setNodeId(client.key)
                                actions.getHierarchyDetail(payload)
                                setHeadingName(`${client.level} : ${client.client_name}`)
                                actions.setButtonName("Carrier");
                                handleCellClick()
                              }}
                            >
                              {client?.client_name}
                            </Table.Cell>
                            <Table.Cell></Table.Cell>
                            <Table.Cell></Table.Cell>
                            <Table.Cell></Table.Cell>
                          </Table.Row>
                        )
                      )
                        : (
                          <Table.Row key={organization.key}>
                            <Table.Cell
                              className={
                                highlightedCell ==
                                  `${organization.key}`
                                  ? "selected"
                                  : ""
                              }
                              onClick={() => {
                                let filter = {
                                  get_all_children: false,
                                  node_id: [organization.key],
                                  get_attributes: true,
                                  return_nested: true,
                                  get_cascaded_attributes: true,
                                  get_attribute_source: true
                                }
                                setHighlightedCell(
                                  `${organization.key}`
                                );
                                actions.setNodeType(1)
                                actions.setParentId(organization.key)
                                actions.setNodeId(organization.key)
                                actions.getHierarchyDetail(filter)
                                actions.setButtonName("Client");
                                setHeadingName(`${organization.level} : ${organization.organization_name}`)
                                handleCellClick()
                              }} >
                              {organization?.organization_name}
                            </Table.Cell>
                            <Table.Cell></Table.Cell>
                            <Table.Cell></Table.Cell>
                            <Table.Cell></Table.Cell>
                            <Table.Cell></Table.Cell>
                          </Table.Row>
                        )
                    )
                  )}
                </Table.Body>
              </Table >
            </div >
          )}

          {
            Object.keys(singleLevelAttribute || {}).length > 0 && Object.keys(tableList).length > 0 && (
              <div>
                <div id="hierarchy-dashboard-tab">
                  <div className="content-tab">
                    <RequiredTab title={headingName} />
                  </div>
                </div>
                <div
                  className="view-more"
                  onClick={() => {
                    setViewMore(!viewMore);
                  }}
                >
                  {viewMore ? "View Less Details" : "View More Details"}
                </div>

                {viewMore && (
                  <div>
                    <TabBar
                      tabs={getDefaultTabConfigs(nodeType)}
                      setActiveTab={setActiveTab}
                      localStorageKey={HIERARCHY_TABS_LOCAL_STORAGE_KEY}
                      draghandle
                    />

                    {renderHierarchyTab()}
                  </div>
                )}
              </div>
            )
          }

          {
            modalPopup && (
              <CloseModal
                open={modalPopup}
                discard={() => {
                  handleDiscard();
                  setModalPopup(false);
                }}
                onClose={() => {
                  setModalPopup(false);
                }}
              />
            )
          }

          {
            Object.keys(singleLevelAttribute || {}).length > 0 && Object.keys(tableList).length > 0 && (
              <div id="hierarchy-dashboard-tab">
                <div className="fields-btn-container">
                  <div className="fields-header">
                    <FliptButton
                      className="secondary draft"
                      name="Cancel and Discard"
                      onClick={() => {
                        setModalPopup(true);
                      }
                      }
                    />
                  </div>

                  <div className="fields-header">
                    <FliptButton
                      className="primary draft"
                      name="Save"
                      onClick={() => {
                        let payload = {
                          node_id: nodeId,
                          data: {
                            attributes: singleLevelAttribute
                          }
                        }
                        actions.handleSave(payload);
                      }}
                    />
                  </div>
                </div>
              </div>
            )
          }
        </div >
      }
    </div >
  );
};

const mapStateToProps = (state) => ({
  state: {
    AllData: state.clientConfiguration.AllData,
    defaultButtonName: state.clientConfiguration.defaultButtonName,
    defaultGroupData: state.groupManagement.defaultGroupData,
    defaultTabName: state.clientConfiguration.defaultTabName,
    nodeId: state.clientConfiguration.nodeId,
    nodeType: state.clientConfiguration.nodeType,
    pageName: state.clientConfiguration?.pageName,
    parentId: state.clientConfiguration.parentId,
    singleLevelAttribute: state.clientConfiguration.singleLevelAttribute,
    user: state?.user,
  },
});

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
    ...ClientConfigurationActions,
    ...UserActions,
    ...groupManagementActions,
  };

  return {
    actions: bindActionCreators(allActions, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientConfiguration);
