import Immutable from 'seamless-immutable'
import { createActions, createReducer } from 'reduxsauce'

import { convertDateTimeToDate } from '../../../utils/utilities'

export const INITIAL_STATE = Immutable({
  allNetworkEditsData: [],
  allNetworkTiersData: [],
  allNetworksData: [],
  assignedPharmacyData: {},
  chainCode: [],
  claimProcessorNetworkData: [],
  corpName: [],
  networkData: {},
  networkEditData: {},
  networkTierCondData: [],
  networkTierData: {},
  networkTierName: '',
  newVersionData: {},
  pharmacyNetworkTierLookup: [],
  publishDocumentData: {},
  specialtyDrug: [],
})

export const clearNetworkTierReducer = () => INITIAL_STATE

export const clearReducer = () => INITIAL_STATE

export const setAllNetworksDataReducer = (state = INITIAL_STATE, { payload }) => {
  const d = payload?.data.map((row) => ({
    ...row,
    effective_end_date: convertDateTimeToDate(row.effective_end_date),
    effective_start_date: convertDateTimeToDate(row.effective_start_date),
  }))
  return {
    ...state,
    allNetworksData: d,
  }
}

export const setAllNetworkTiersDataReducer = (state = INITIAL_STATE, { payload }) => {
  const d = payload?.data.map((row) => ({
    ...row,
    effective_end_date: convertDateTimeToDate(row.effective_end_date),
    effective_start_date: convertDateTimeToDate(row.effective_start_date),
  }))
  return {
    ...state,
    allNetworkTiersData: d,
  }
}

export const setAllNetworkEditsDataReducer = (state = INITIAL_STATE, { payload }) => {
  const d = payload?.map((row) => ({
    ...row,
    effective_end_date: convertDateTimeToDate(row.effective_end_date),
    effective_start_date: convertDateTimeToDate(row.effective_start_date),
  }))
  return {
    ...state,
    allNetworkEditsData: d,
  }
}

export const setNetworkTierDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  networkTierData: payload,
})

export const setNetworkEditDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  networkEditData: payload,
})

export const setNetworkDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  networkData: payload,
})

export const setClaimProcessorNetworkDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  claimProcessorNetworkData: payload,
})

export const setAssignedPharmacyDataReducer = (state = INITIAL_STATE, { payload }) => {
  return {
    ...state,
    assignedPharmacyData: payload,
  }
}

export const setChainCodeDataReducer = (state = INITIAL_STATE, { payload }) => {
  return {
    ...state,
    chainCode: payload.chaincode,
    corpName: payload.corpname,
    claimProcessorNetworkData: payload.claim_processor
  }
}
export const setSpecialtyDrugDataReducer = (state = INITIAL_STATE, { payload }) => {
  return {
    ...state,
    specialtyDrug: payload,
  }
}

export const setNewVersionDataReducer = (state = INITIAL_STATE, { payload }) => {
  return {
    ...state,
    newVersionData: payload,
  }
}

export const setPublishDocumentDataReducer = (state = INITIAL_STATE, { payload }) => {
  return {
    ...state,
    publishDocumentData: payload,
  }
}

export const setPharmacyNetworkLookupDataReducer = (state = INITIAL_STATE, { payload }) => {
  return {
    ...state,
    pharmacyNetworkLookup: payload.data,
  }
}

export const setPharmacyNetworkTierLookupReducer = (state = INITIAL_STATE, { payload }) => {
  return {
    ...state,
    tierName: payload.tier_name || '',
    networkDetails: payload.network_details,
    networkExclusion: payload.network_exclusion,
    networkPricing: payload.network_pricing,
    total_rows: payload.total_rows,
    pharmaciesPerPage: 50
  }
}

export const { Types, Creators } = createActions({
  clearNetworkTier: null,
  clearReducer: null,
  createNewVersion: ['payload'],
  generateAssignedPharmacy: ['payload'],
  generateNetwork: ['payload'],
  generateNetworkEdit: ['payload'],
  generateNetworkTier: ['payload'],
  getAllNetworkEditsData: ['payload'],
  getAllNetworkTiersData: ['payload'],
  getAllNetworksData: null,
  getChainCodeData: null,
  getClaimProcessorNetworkData: null,
  getNetworkData: ['payload'],
  getNetworkTierData: ['payload'],
  getPharmacyNetworkLookupData: ['payload'],
  getPharmacyNetworkTierLookup: ['payload'],
  getSpecialtyDrugData: null,
  publishDocument: ['payload'],
  setAllNetworkEditsData: ['payload'],
  setAllNetworkTiersData: ['payload'],
  setAllNetworksData: ['payload'],
  setAssignedPharmacyData: ['payload'],
  setChainCodeData: ['payload'],
  setClaimProcessorNetworkData: ['payload'],
  setNetworkData: ['payload'],
  setNetworkEditData: ['payload'],
  setNetworkTierData: ['payload'],
  setNewVersionData: ['payload'],
  setPharmacyNetworkLookupData: ['payload'],
  setPharmacyNetworkTierLookup: ['payload'],
  setPublishDocumentData: ['payload'],
  setSpecialtyDrugData: ['payload'],
})

const HANDLERS = {
  [Types.CLEAR_NETWORK_TIER]: clearNetworkTierReducer,
  [Types.CLEAR_REDUCER]: clearReducer,
  [Types.SET_ALL_NETWORKS_DATA]: setAllNetworksDataReducer,
  [Types.SET_ALL_NETWORK_EDITS_DATA]: setAllNetworkEditsDataReducer,
  [Types.SET_ALL_NETWORK_TIERS_DATA]: setAllNetworkTiersDataReducer,
  [Types.SET_ASSIGNED_PHARMACY_DATA]: setAssignedPharmacyDataReducer,
  [Types.SET_CHAIN_CODE_DATA]: setChainCodeDataReducer,
  [Types.SET_CLAIM_PROCESSOR_NETWORK_DATA]: setClaimProcessorNetworkDataReducer,
  [Types.SET_NETWORK_DATA]: setNetworkDataReducer,
  [Types.SET_NETWORK_EDIT_DATA]: setNetworkEditDataReducer,
  [Types.SET_NETWORK_TIER_DATA]: setNetworkTierDataReducer,
  [Types.SET_NEW_VERSION_DATA]: setNewVersionDataReducer,
  [Types.SET_PHARMACY_NETWORK_LOOKUP_DATA]: setPharmacyNetworkLookupDataReducer,
  [Types.SET_PHARMACY_NETWORK_TIER_LOOKUP]: setPharmacyNetworkTierLookupReducer,
  [Types.SET_PUBLISH_DOCUMENT_DATA]: setPublishDocumentDataReducer,
  [Types.SET_SPECIALTY_DRUG_DATA]: setSpecialtyDrugDataReducer,
}

export default createReducer(INITIAL_STATE, HANDLERS)
