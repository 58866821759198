import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import './styles.scss'
import FliptPaSearch from '../../components/form/fliptPasearch'
import FliptPaInput from '../form/fliptPaInput'
import FliptGrid from '../fliptGrid'
import FliptButton from '../../components/form/fliptButton'
import { Creators as AutoSuggestActions } from '../../redux/reducers/api/autoSuggest'
import { Creators as ManualClaimEntryActions } from '../../redux/reducers/api/manualClaimEntry'
import { Creators as DrugLookupActions } from '../../redux/reducers/api/drugLookup'
import { Creators as AppActions } from '../../redux/reducers/app'

class FliptDrugSearch extends Component {
    constructor(props) {
        super(props)
        this.state = {
            memberList: [],
            searchForm: {
                drug_name: '',
                gpi: '',
                ndc: '',
            },
            drugDataList: [],
            resultValues: [],
            searchBy: '',
            loading: false,
            minCharacters: 3,
        }
    }

    _searchDrug = () => {
        const { actions } = this.props
        const { searchForm } = this.state
        if (searchForm.drug_name.length) {
            return
        }
        actions.setNdcLookupFailed(false)
        if (searchForm.ndc.length) {
            this.setState({ searchBy: 'NDC', drugData: null, drugDataList: [] })
            actions.clearManualClaimEntryNDC()
            actions.fetchManualClaimEntryNDC({ ndc: searchForm.ndc, noDataClearing: true })
        }
        else if (searchForm.gpi.length) {
            this.setState({ searchBy: 'GPI', drugData: null, drugDataList: [] });
            actions.clearDrugInfoBySearch()
            actions.getDrugInfoBySearch({ gpi: searchForm.gpi })
        }
        else {
            const transitionalPortal = {
                header: 'Fields are missing',
                copy: 'Please add ndc, gpi or drug name to search the drug',
            }
            actions.displayTransitionalPortal(transitionalPortal)
        }
    }

    componentDidMount() {
        const { searchForm, editMode } = this.props
        if (searchForm?.ndc) {
            this.setState({
                searchForm: {
                    drug_name: '',
                    gpi: '',
                    ndc: searchForm.ndc,
                },
                drugData: null
            })
        }
    }

    componentDidUpdate(prevProps) {
        const { props } = this
        const { state: { manualClaimEntry, drugLookup,
            autoSuggestData }, searchForm, editMode, isPa } = props
        if (prevProps.searchForm !== searchForm
            && !editMode && searchForm?.drug_name && isPa) {
            this.setState({
                searchForm: {
                    drug_name: searchForm.drug_name,
                    drug_strength: searchForm.dosage_strength,
                    gpi: searchForm.gpi,
                    drug_form: searchForm.drug_form,
                    days_supply: searchForm.days_supply,
                    quantity: searchForm.quantity,
                }
            })
        }
        if (prevProps.state?.autoSuggestData !== autoSuggestData) {
            this._updateResultSearchValues(autoSuggestData['drug_name'])
        }
        if ((prevProps.state?.drugLookup !== drugLookup) ||
            prevProps.state?.manualClaimEntry !== manualClaimEntry) {
            this._updateOnComponent()
        }
    }

    _updateOnComponent() {
        const { state } = this.props
        const { searchBy } = this.state
        switch (searchBy) {
            case 'NDC': {
                if (state.manualClaimEntry?.ndc?.drug_name) {
                    this.setState({ drugDataList: [state.manualClaimEntry.ndc] });
                }
            }
                break;
            case 'DRUG': {
                if (state.drugLookup?.drugInfo?.length) this.setState({ drugDataList: state.drugLookup?.drugInfo });
            }
                break;
            case 'GPI': {
                if (state.drugLookup?.drugInfo?.length) this.setState({ drugDataList: state.drugLookup?.drugInfo });
            }
        }
    }

    _updateResultSearchValues(drugsArray) {
        const resultValues = drugsArray ? drugsArray.map((d) => ({ title: d })) : []
        this.setState({ loading: false, resultValues })
    }

    _onDrugSelectionChanged = (data) => {
        const { updateDrugData } = this.props
        const selectedRows = data.api.getSelectedRows();
        if (selectedRows.length) {
            updateDrugData(selectedRows[0])
            this.setState({ drugData: selectedRows[0] })
        }
        else {
            updateDrugData(null)
            this.setState({ drugData: null })
        }
    }

    _onResultSelect = (e, data) => {
        const { actions } = this.props
        const { searchForm } = this.state
        const { name, result } = data
        searchForm[name] = result.title
        actions.setNdcLookupFailed(false)
        actions.clearDrugInfoBySearch()
        actions.getDrugInfoBySearch({ drug_name: searchForm.drug_name })
        this.setState({ searchForm, searchBy: 'DRUG' })
    }

    _updateFields = (el, dropdown) => {
        const { actions } = this.props
        const { searchForm } = this.state
        const { name, value } = dropdown || el.currentTarget

        if (name === 'drug_name') { searchForm.gpi = ''; searchForm.ndc = '' }
        if (name === 'gpi') { searchForm.drug_name = ''; searchForm.ndc = '' }
        if (name === 'ndc') { searchForm.gpi = ''; searchForm.drug_name = '' }
        searchForm[name] = value
        this.setState({ searchForm })
        if (name === 'drug_name' && value.length > 2) {
            actions.getAutoSuggestData({ search_string: value, search_in: 'fts_ndc_drugs', search_for: 'drug_name' })
            this.setState({ loading: true })
        }
    }

    _clearDrugFields = () => {
        const { updateDrugData } = this.props
        const searchForm = {
            drug_name: '',
            gpi: '',
            ndc: '',
            drug_strength: ''
        }
        this.setState({
            searchForm,
            drugDataList: [],
            drugData: null,
        })
        updateDrugData()
    }

    _submitResult = () => {
        const { actions } = this.props
        actions.toggleModal()
    }


    render() {
        const { isPa,
            rowSelection, editMode, updateDrugData, isPod } = this.props
        const { drugDataList, resultValues, searchForm, minCharacters, loading, drugData } = this.state
        const headers = ['Select', 'gpi', 'drug_name', 'strengths', 'dosage']
        const cellRendererParams = {
            Select: {
                checkboxSelection: true,
                width: '120px'
            }
        }
        const agGridRef = React.createRef()
        const onGridReady = () => {
            if (drugDataList.length == 1) {
                updateDrugData(drugDataList[0])
                this.setState({ drugData: drugDataList[0] })
                agGridRef.current.api.forEachNode(function (node) {
                    node.setSelected(true);
                });
            }
        }
        return (
            <div className="drug-section">
                <div className="drug-header">
                    Find Drug
                </div>
                <div className="inner-container">
                    <div className="fields-container">
                        <div className="pa-fields-container">
                            {editMode ? <FliptPaSearch name="drug_name"
                                value={searchForm.drug_name} results={resultValues} loading={loading} minCharacters={minCharacters}
                                onResultSelect={(e, data) => this._onResultSelect(e, data)}
                                onSearchChange={(e, dropdown) => this._updateFields(e, dropdown)}
                                label="Drug Name" stylized /> :
                                <FliptPaInput disabled={!editMode} name="drug_name" value={searchForm.drug_name} onChange={this._updateFields}
                                    label="Drug name" stylized />}
                            {isPa && <div className='pa-field' >
                                <FliptPaInput disabled={!editMode} name="days_supply" value={searchForm.days_supply} onChange={this._updateFields}
                                    label="Days Supply" stylized />
                            </div>}
                            {!editMode && <div className='pa-field' > <FliptPaInput disabled={!editMode} name="drug_strength" value={searchForm.drug_strength} onChange={this._updateFields}
                                label="Drug Strength"
                                stylized /></div>}
                            <div />
                        </div>
                        <div className="pa-fields-container">
                            <FliptPaInput disabled={!editMode} name="gpi" value={searchForm.gpi} onChange={this._updateFields}
                                label="GPI"
                                stylized />
                            {isPa && <div className='pa-field' >
                                <FliptPaInput disabled={!editMode} name="quantity" value={searchForm.quantity} onChange={this._updateFields}
                                    label="Quantity"
                                    stylized /> </div>}
                        </div>
                        <div className="pa-fields-container">
                            {!editMode && <FliptPaInput disabled={!editMode} name="ndc" value={searchForm.ndc} onChange={this._updateFields}
                                label="NDC" stylized />}
                            {editMode ? <FliptPaInput disabled={!editMode} name="ndc" value={searchForm.ndc} onChange={this._updateFields}
                                label="NDC"
                                stylized /> :
                                <div className='pa-field' ><FliptPaInput disabled={!editMode} name="drug_form" value={searchForm.drug_form} onChange={this._updateFields}
                                    label="Drug Form"
                                    stylized /></div>}

                        </div>

                    </div>

                    {editMode ? <div className="drug-buttons-container">
                        <FliptButton name="SEARCH" className="primary" onClick={() => this._searchDrug()} />
                        <FliptButton name="Clear" className="secondary" onClick={() => this._clearDrugFields()} />
                    </div> : null}
                </div>
                {drugDataList?.length ?
                    <>
                        <div className="drug-header">
                            Search Results
                        </div>
                        <div className="grid-container">
                            <FliptGrid
                                agGridRef={agGridRef}
                                headers={headers}
                                onGridReadyCustom={onGridReady}
                                data={drugDataList}
                                rowSelection={rowSelection ? rowSelection : 'single'}
                                cellRendererParams={cellRendererParams}
                                onSelectionChanged={(data) => { this._onDrugSelectionChanged(data) }} />
                        </div>
                    </> : <></>
                }
                {!isPod ? <div className="drug-buttons-container">
                    <FliptButton disabled={!drugData} name="SUBMIT" className="primary" onClick={() => this._submitResult()} />
                </div> : null}
            </div>
        )
    }

}

const mapStateToProps = (state) => ({
    state: {
        manualClaimEntry: state.manualClaimEntry,
        drugLookup: state.drugLookup,
        autoSuggestData: state.autoSuggest.autoSuggestData,
    },
})

const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...AutoSuggestActions,
        ...DrugLookupActions,
        ...AppActions,
        ...ManualClaimEntryActions,
    }
    return {
        actions: bindActionCreators(allActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FliptDrugSearch)