import React from 'react';
import FliptPaInput from '../../../../components/form/fliptPaInput';
import { convertDateTimeToEstStr } from '../../../../utils/utilities';
import FliptButton from '../../../../components/form/fliptButton'
import FliptTextarea from '../../../../components/form/fliptTextarea'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Creators as PriorAuthorizationCreationActions } from '../../../../redux/reducers/api/priorAuthorizationManagement'
import { Creators as AppActions } from '../../../../redux/reducers/app'
import './styles.scss'

const PatientInfo = (props) => {
    const { paCaseDetails } = props
    const [displayAddNote, setDisplayAddNote] = React.useState(false)
    const [noteData, setNoteData] = React.useState("")
    const _displayAddNote = () => {
        setDisplayAddNote(true)
    }

    const _changeNoteData = (el, dropdown) => {
        const { name, value } = dropdown || el.currentTarget
        setNoteData(value)
    }

    const _showTransitionalPortal = (header, message) => {
        const transitionalPortal = {
            header: header,
            copy: message,
        }
        props.actions.displayTransitionalPortal(transitionalPortal)
    }

    const _saveNote = () => {
        const { paCaseDetails: { pa_case_id }, state } = props
        const { user } = state
        const header = 'Fields Missing'
        let message = 'Please add a Note Type to proceed'
        if (!noteData) {
            message = 'Please add a Note Description to proceed'
            _showTransitionalPortal(header, message)
            return;
        }
        const params = {
            pa_case_id,
            note: {
                note: noteData,
                technician: `${user.first_name} ${user.last_name}`,
                note_type: 'Internal'
            }
        }
        props.actions.createNote(params)
        setNoteData("")
    }
    return (
        <div id="PatientInfo">
            <div className='field-outer-container'>
                <div className='field-inner-container'>
                    <FliptPaInput value={paCaseDetails?.pa_case_id} label='Case ID' stylized disabled />
                    <FliptPaInput value={paCaseDetails?.patient?.first_name} label='Patient First Name' stylized disabled />
                    <FliptPaInput value={paCaseDetails?.prescriber?.first_name} label='Provider First Name' stylized disabled />
                    <FliptPaInput value={paCaseDetails?.medication?.drug_name} label='Drug Name' stylized disabled />
                </div>
                <div className='field-inner-container'>
                    <FliptPaInput value={convertDateTimeToEstStr(paCaseDetails?.date_time_received)} label='Receive Date' stylized disabled />
                    <FliptPaInput value={paCaseDetails?.patient?.last_name} label='Patient Last Name' stylized disabled />
                    <FliptPaInput value={paCaseDetails?.prescriber?.last_name} label='Provider Last Name' stylized disabled />
                    <FliptPaInput value={paCaseDetails?.case_type} label='Case Type' stylized disabled />
                </div>
            </div>
            <div className='field-notes-container'>
                {
                    !displayAddNote &&
                    <div className='pa-button-container'>
                        <FliptButton className='primary' name='Add Notes' onClick={() => _displayAddNote()} />
                    </div>
                }
                {
                    displayAddNote &&
                    <div className='note-container'>
                            <FliptTextarea style={{ width: "100%"}} name='noteDescription' value={noteData} stylized id="note-textarea"
                            label='Note Description' onChange={(el,val) => _changeNoteData(el, val)} isPa />
                            <div className="pa-button-container">
                                <FliptButton className='primary' name='SAVE NOTE' onClick={() => _saveNote()} />
                            </div>
                    </div>
                }
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    state: {
        app: state.app,
        user: state.user,
    },
})

const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...AppActions,
        ...PriorAuthorizationCreationActions,
    }

    return {
        actions: bindActionCreators(allActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientInfo)