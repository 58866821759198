import React from 'react'
import { Link } from 'react-router-dom'
import { Icon } from 'semantic-ui-react'
import FliptGrid from '../../../../../components/fliptGrid'
import FliptInput from '../../../../../components/form/fliptInput'
import { convertStrUi } from '../../../../../utils/utilities'
import '../styles.scss'

const ImpactedObject = (props) => {
  const headers = [
    'gpi',
    'drug_group',
    'drug_class',
    'drug_subclass',
    'Manufacturer',
    'drug_name',
    'multi_source',
    'ddid',
    'brand_generic',
    'otc_indicator',
  ]
  const cellRendererParams = {
    gpi: {
      width: 200,
      overrideHeader: 'GPI',
    },
    drug_group: {
      width: 200,
      overrideHeader: 'Drug Group',
    },
    drug_class: {
      width: 200,
      overrideHeader: 'Drug Class',
    },
    drug_subclass: {
      width: 200,
      overrideHeader: 'Drug Subclass',
    },
    manufacturer: {
      width: 200,
      overrideHeader: 'Manufacturer',
    },
    drug_name: {
      width: 200,
      overrideHeader: 'Drug Name',
    },
    multi_source: {
      width: 200,
      overrideHeader: 'Multisource Code',
    },
    ddid: {
      width: 200,
      overrideHeader: 'DDID',
    },
    brand_generic: {
      width: 200,
      overrideHeader: 'Brand Generic',
    },
    otc_indicator: {
      width: 200,
      overrideHeader: 'Otc Indicator',
    },
  }
  const {
    doc_id,
    doc_name,
    module,
    doc_version: version,
    status,
    effective_start_date,
    mapped_conditions = [],
  } = props
  const generateEditLink = () => {
    const toObj = { pathname: '/step-therapy', search: `?doc_name=${doc_name}`, state: { editMode: true, remote: true, }, }
    switch (module) {
      case 'tiers': toObj.pathname = '/formulary-tier'
        break
      case 'programs': toObj.pathname = '/program-list'
        break
      case 'prior_authorization': toObj.pathname = '/prior-authorization'
        break
      case 'quantity_limit': toObj.pathname = '/quantity-limit'
        break
      default: toObj.pathname = '/step-therapy'
    }
    return toObj
  }
  return (
    <>
      <div className="section-content">
        <div className="section">
          <div className="header red">{ doc_name }</div>
          <section id="impactedObjectInput" className="drug-lookup-inputs-container spacing border-line shadow">
            <div className="drug-lookup-inputs">
              <span>Version</span>
              <FliptInput  className="ui drug-lookup-search" disabled="" value={version} />
            </div>
            <div className="drug-lookup-inputs">
              <span>Effective Start Date</span>
              <FliptInput icon='search' disabled="" value={convertStrUi(effective_start_date)} />
            </div>
            <div className="drug-lookup-inputs">
              <span>Status</span>
              <FliptInput disabled="" value={status} />
            </div>
          </section>
        </div>
        <div className="header-container">
          <div className="header">Conditions</div>
          <div className="edit-button">
            <Link to={{
              ...generateEditLink(),
            }}
            >
              <Icon name="pencil" size="small" />
              EDIT CONDITIONS
            </Link>
          </div>
        </div>
        <div className="content">
          <FliptGrid data={mapped_conditions} headers={headers} cellRendererParams={cellRendererParams} />
        </div>
      </div>
    </>
  )
}

export default ImpactedObject
