import React from 'react'
import {
  Button,
  Modal,
  Grid,
} from 'semantic-ui-react'
import FliptInput from '../../../../../components/form/fliptInput'
import FliptLabel from '../../../../../components/form/fliptLabel'
import '../styles.scss'

const ChangeSummary = (props) => {
  const {
    gpi,
    drug_name: drugName,
    ddid,
    drug_class: drugClass,
    drug_group: drugGroup,
    multi_source: multisouceCode,
    drug_subclass: drugSubclass,
    brand_generic: brandGeneric,
    otc_indicator: otcIndicator,
    manufacturer,
    strength,
    uom,
    onClose,
  } = props
  return (
    <div className="popup">
      <Modal.Description>
        <div className="modalHeader">Change Summary</div>
        <div className="modalDescriptionColumn">
          <Grid columns={3} divided>
            <Grid.Row stretched>
              <Grid.Column>
                <div className="labelCenter"><FliptLabel label="Drug Name" /></div>
                <FliptInput disabled value={drugName} />
                <div className="labelCenter"><FliptLabel label="Drug Class" /></div>
                <FliptInput disabled value={drugClass} />
                <div className="labelCenter"><FliptLabel label="Multi-Source Code" /></div>
                <FliptInput disabled value={multisouceCode} />
                <div className="labelCenter"><FliptLabel label="Strength" /></div>
                <FliptInput disabled value={strength} />
              </Grid.Column>
              <Grid.Column>
                <div className="labelCenter"><FliptLabel label="GPI" /></div>
                <FliptInput disabled value={gpi} />
                <div className="labelCenter"><FliptLabel label="Drug Group" /></div>
                <FliptInput disabled value={drugGroup} />
                <div className="labelCenter"><FliptLabel label="Brand/Generic" /></div>
                <FliptInput disabled value={brandGeneric} />
                <div className="labelCenter"><FliptLabel label="Manufacturer" /></div>
                <FliptInput disabled value={manufacturer} />
              </Grid.Column>
              <Grid.Column>
                <div className="labelCenter"><FliptLabel label="DDID" /></div>
                <FliptInput disabled value={ddid} />
                <div className="labelCenter"><FliptLabel label="Drug Subclass" /></div>
                <FliptInput disabled value={drugSubclass} />
                <div className="labelCenter"><FliptLabel label="OTC Indicator" /></div>
                <FliptInput disabled value={otcIndicator} />
                <div className="labelCenter"><FliptLabel label="Unit of Measure (UOM)" /></div>
                <FliptInput disabled value={uom} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </Modal.Description>
      <Modal.Actions>
        <Button onClick={onClose}>
          Close
        </Button>
      </Modal.Actions>
    </div>
  )
}
export default ChangeSummary
