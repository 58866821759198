
import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import './styles.scss'
import FliptButton from '../../../../components/form/fliptButton'
import * as Constants from '../constants'
import { Creators as AppActions } from '../../../../redux/reducers/app'
class DuplicateModal extends Component {
    constructor(props) {
        super(props)

        this.state = {
        }
    }


    _openModal = () => {
        const { _openStatusModal, actions } = this.props
        actions.toggleModal()
        _openStatusModal(Constants.VOID_CLOSE_STR)
    }

    render() {
        const { state: { paCaseMessage } } = this.props


        return (
            <div id="DuplicateModal">
                {paCaseMessage}
                <div className='button-container'>
                    <FliptButton className='primary' name='OK' onClick={() => this._openModal()} />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    state: {
        app: state.app,
        paCaseMessage: state.priorAuthorizationCreation.paCaseMessage,
    },
})

const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...AppActions,
    }

    return {
        actions: bindActionCreators(allActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DuplicateModal)
