import React, { Component, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import BreadCrumb from '../../../../components/breadCrumb'
import { Creators as AppActions } from '../../../../redux/reducers/app'

import './styles.scss'
import DefineBatchScreen from '../defineBatchScreen'
import ClaimConfig from '../claimConfig'
import FliptGrid from '../../../../components/fliptGrid'
import { gridHeaders, memberFields, optionalClaimFields, requiredClaimFields } from '../claimConfig/data/uiFields'
import moment from 'moment-timezone'

function ClaimsProcessingAndReprocessingReview(props) {
	const { setClaimForm, claimForm, gridData, setTestBatchConfig, testBatchConfig, setStep } = props
	const flatRequiredClaimFields = requiredClaimFields.flat()
	const flatOptionalClaimFields = optionalClaimFields.flat()

	const getHeaders = () => {
		const headerData = [...gridHeaders]
		headerData.shift()
		const claimFields = [...flatRequiredClaimFields, ...flatOptionalClaimFields]
		claimFields.forEach((data) => {
			headerData.push(data.name)
		})
		return headerData
	}
	const flatMemberFields = memberFields.flat()

	const headers = flatMemberFields.map((data) => data.name)
	const [openAcc, setopenAcc] = useState(1)
	console.log(claimForm)
	const cellRendererParams = {
		effective_start_date: {
			overrideHeader: 'Eligibility Begin Date',
			valueFormatter: (params) => {
				if (!params.value) return ''
				return moment(params.value)?.format('YYYY-MM-DD') || params.value
			},
		},
		effective_end_date: {
			overrideHeader: 'Eligibility End Date',
			valueFormatter: (params) => {
				if (!params.value) return ''
				return moment(params.value)?.format('YYYY-MM-DD') || params.value
			},
		},
		zip: {
			overrideHeader: 'Zip Code',
		},
		lics: {
			overrideHeader: 'LICS',
		},
		esrd: {
			overrideHeader: 'ESRD',
		},
		ssn: {
			overrideHeader: 'SSN',
		},
		dob: {
			overrideHeader: 'Date Of Birth',
		},
		medicare_id: {
			overrideHeader: 'MBID',
		},
	}

	return (
		<div id="claimsProcessingAndReprocessingReview">
			<BreadCrumb {...props} />
			{openAcc === 1 ? (
				<div className="transition-div">
					<span className="header">Batch Details</span>

					<DefineBatchScreen
						{...props}
						testBatchConfig={testBatchConfig}
						setTestBatchConfig={setTestBatchConfig}
						setopenAcc={setopenAcc}
						editable={false}
					/>
				</div>
			) : (
				<div className="accordian-container">
					<div>
						<span className="acc-header">Batch Details</span>
					</div>
					<img
						src="/i/expand.png"
						height={20}
						width={20}
						onClick={() => {
							setopenAcc(1)
						}}
					></img>
				</div>
			)}
			{openAcc === 2 ? (
				<div className="grid-container transition-div">
					<span className="header">Test Member</span>
					<FliptGrid headers={headers} cellRendererParams={cellRendererParams} data={claimForm.gridData} />
				</div>
			) : (
				<div className="accordian-container">
					<div>
						<span className="acc-header">Test Member</span>
					</div>
					<img
						src="/i/expand.png"
						height={20}
						width={20}
						onClick={() => {
							setopenAcc(2)
						}}
					></img>
				</div>
			)}
			{openAcc === 3 ? (
				<div className="transition-div">
					<span className="header">Test Claims</span>
					<div className="grid-container">
						<FliptGrid headers={getHeaders()} data={gridData} />
					</div>
				</div>
			) : (
				<div className="accordian-container">
					<div>
						<span className="acc-header">Test Claims</span>
					</div>
					<img
						src="/i/expand.png"
						height={20}
						width={20}
						onClick={() => {
							setopenAcc(3)
						}}
					></img>
				</div>
			)}
		</div>
	)
}

const mapStateToProps = (state) => ({
	state: {
		user: state.user,
	},
})

const mapDispatchToProps = (dispatch) => {
	const allActions = {
		...AppActions,
	}

	return {
		actions: bindActionCreators(allActions, dispatch),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ClaimsProcessingAndReprocessingReview)
