import Immutable from 'seamless-immutable'
import { createActions, createReducer } from 'reduxsauce'

export const INITIAL_STATE = Immutable({
  data: {
    id: '',
    description: '',
    effective_start_date: '',
    effective_end_date: '',
    active: '',
    name: '',
    organization: '',
  },
  roleListing: [],
  roleData: {
    features: [],
  },
})

export const clearReducer = () => INITIAL_STATE

export const clearRoleManagementRoleDataReducer = () => INITIAL_STATE

export const setRoleManagementRolesReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  roleListing: payload.data,
})

export const setRoleManagementRoleDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  roleData: payload,
})

export const { Types, Creators } = createActions({
  clearRoleManagementRoleData: null,
  clearReducer: null,
  createRoleManagementRole: ['payload'],
  editRoleManagementRole: ['payload'],
  getRoleManagementRoles: null,
  getRoleManagementRole: ['payload'],
  setRoleManagementRolesList: ['payload'],
  setRoleManagementRoleData: ['payload'],
})

const HANDLERS = {
  [Types.CLEAR_ROLE_MANAGEMENT_ROLE_DATA]: clearRoleManagementRoleDataReducer,
  [Types.CLEAR_REDUCER]: clearReducer,
  [Types.SET_ROLE_MANAGEMENT_ROLES_LIST]: setRoleManagementRolesReducer,
  [Types.SET_ROLE_MANAGEMENT_ROLE_DATA]: setRoleManagementRoleDataReducer,
}

export default createReducer(INITIAL_STATE, HANDLERS)
