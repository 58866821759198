import Immutable from 'seamless-immutable'
import { createActions, createReducer } from 'reduxsauce'

export const INITIAL_STATE = Immutable({
  loading: false,
  paoverridereportLookupData: [],
  paoverridereportTotalRows: 0,
  paoverridereportPerPage: 20,
  paoverridereportSuggestData: [],
})

export const clearPaoverridereportLookupReducer = () => INITIAL_STATE

export const clearReducer = () => INITIAL_STATE

export const setPaoverridereportLookupDataReducer = (state = INITIAL_STATE, { payload }) => {
 
  return ({
    ...state,
    paoverridereportLookupData: payload.data,
    paoverridereportTotalRows: payload.totalRows || 0,
  })
}

export const setPaoverridereportSuggestDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  paoverridereportSuggestData: payload.data,
})

export const setLoadingReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  loading: payload.data,
})

export const { Types, Creators } = createActions({
  clearPaoverridereportLookupReducer: null,
  clearReducer: null,
  getPaoverridereportLookupData: ['payload'],
  setPaoverridereportLookupData: ['payload'],
  getPaoverridereportSuggestData: ['payload'],
  setLoading: ['payload'],
  setPaoverridereportSuggestData: ['payload'],
})

const HANDLERS = {
  [Types.CLEAR_PAOVERRIDEREPORT_LOOKUP_REDUCER]: clearPaoverridereportLookupReducer,
  [Types.CLEAR_REDUCER]: clearReducer,
  [Types.SET_LOADING]: setLoadingReducer,
  [Types.SET_PAOVERRIDEREPORT_LOOKUP_DATA]: setPaoverridereportLookupDataReducer,
  [Types.SET_PAOVERRIDEREPORT_SUGGEST_DATA]: setPaoverridereportSuggestDataReducer,
}

export default createReducer(INITIAL_STATE, HANDLERS)
