export const commFieldAttribute = [
  {
    header: "",
    type: "radio",
    label: "Send welcome primary",
    name: "send_welcome_primary",
    value: "",
    info: "Turn on to send welcome email to the Primary member",
    validation: "",
    placeholder: "Enter",
  },
  {
    header: "",
    type: "text",
    label: "Primary welcome email template ID",
    name: "primary_welcome_email_template_id",
    value: "",
    info: "Email Template ID to send Welcome email to Primary member",
    validation: "",
    placeholder: "Enter",
  },
  {
    header: "",
    type: "radio",
    label: "Send welcome dependent",
    name: "send_welcome_dependent",
    value: "",
    info: "Turn on to send welcome email to the dependent member",
    validation: "",
    placeholder: "Enter",
  },
  {
    header: "",
    type: "text",
    label: "Dependent welcome email template ID",
    name: "dependent_welcome_email_template_id",
    value: "",
    info: "Email template id to send welcome email to the dependent member",
    validation: "",
    placeholder: "Enter",
  },

  {
    header: "",
    type: "radio",
    label: "Send benefit plan change",
    name: "send_benefit_plan_change",
    value: "",
    info: "Turn on to email that member's benefit plan has changed ",
    validation: "",
    placeholder: "Enter",
  },
  {
    header: "",
    type: "text",
    label: "Benefit plan change template ID",
    name: "benefit_plan_change_template_id",
    value: "",
    info: "Sendgrid Email Template ID to send  email that member's benefit plan has changedt",
    validation: "",
    placeholder: "Enter",
  },

  {
    header: "",
    type: "radio",
    label: "Send cobra plan change ",
    name: "send_cobra_plan_change",
    value: "",
    info: "Turn on to send Cobra plan change email to the member",
    validation: "",
    placeholder: "Enter",
  },
  {
    header: "",
    type: "text",
    label: "Cobra plan change template ID",
    name: "cobra_plan_change_template_id",
    value: "",
    info: "Email Template ID to send Cobra plan change email to the member",
    validation: "",
    placeholder: "Enter",
  },
  {
    header: "",
    type: "radio",
    label: "Send acivation email",
    name: "send_activation_email",
    value: "",
    info: "Turn on to email member that the app has been activated",
    validation: "",
    placeholder: "Enter",
  },
  {
    header: "",
    type: "text",
    label: "Activation Email Template ID",
    name: "activation_email_template_id",
    value: "",
    info: "An email template ID will be triggered at the time when the member activates the app/web",
    validation: "",
    placeholder: "Enter",
  },
  {
    header: "",
    type: "radio",
    label: "Send termination primary",
    name: "send_termination_primary",
    value: "",
    info: "Turn on to send termination email to the Primary member",
    validation: "",
    placeholder: "Enter",
  },
  {
    "header": "",
    "type": "text",
    "label": "Primary Termination Template ID",
    "name": "termination_primary_template_id",
    "value": "",
    "info": "Apply Penalty After OutofPocket",
    "validation": "",
    "placeholder": "Enter",
   
  },
  {
    header: "",
    type: "text",
    label: "Termination Email for primary Template ID",
    name: "termination_primary_template",
    value: "",
    info: "Email Template ID to send member termination email ",
    validation: "",
    placeholder: "Enter",
  },
  {
    header: "",
    type: "radio",
    label: "Send dependent termination",
    name: "send_dependent_termination",
    value: "",
    info: "Turn on to send termination email to the dependent member",
    validation: "",
    placeholder: "Enter",
  },
  {
    header: "",
    type: "text",
    label: "Dependent termination template ID",
    name: "dependent_termination_template_id",
    value: "",
    info: "Email template id to send termination email to the dependent member",
    validation: "",
    placeholder: "Enter",
  },
  {
    header: "",
    type: "radio",
    label: "Send new email change",
    name: "send_new_email_change",
    value: "",
    info: "Turn on to send and email to members new email address after member has changed email address from the app/web",
    validation: "",
    placeholder: "Enter",
  },
  {
    header: "",
    type: "text",
    label: "New email change template ID",
    name: "new_email_change_template_id",
    value: "",
    info: "Email Template ID to send email to members new email address after member has changed email address from the app/web.",
    validation: "",
    placeholder: "Enter",
  },
  {
    header: "",
    type: "radio",
    label: "Send old email change",
    name: "send_old_email_change",
    value: "",
    info: "Turn on to send and email to members old email address after member has changed email address from the app/web",
    validation: "",
    placeholder: "Enter",
  },
  {
    header: "",
    type: "text",
    label: "Old email change template ID",
    name: "old_email_change_template_id",
    value: "",
    info: "Email Template ID to send Welcome email to Primary member",
    validation: "",
    placeholder: "Enter",
  },
  {
    header: "",
    type: "radio",
    label: "Send primary address change ",
    name: "send_primary_address_change",
    value: "",
    info: "Turn on to send an email to the member when primary member's address has changed",
    validation: "",
    placeholder: "Enter",
  },
  {
    header: "",
    type: "text",
    label: "Primary address change template ID",
    name: "primary_address_change_template_id",
    value: "",
    info: "Email Template ID to send email to member for address change for primary member",
    validation: "",
    placeholder: "Enter",
  },
];

export const changeDepFieldAttribute = [
  {
    header: "",
    type: "text",
    label: "Change dependent email address template ID",
    name: "change_dependent_email_address_template_id",
    value: "",
    info: "Email Template ID to send email if member's dependent's email address has changed",
    validation: "",
    placeholder: "Enter",
  },
  {
    header: "",
    type: "text",
    label: "Billing plan change template ID",
    name: "billing_plan_change_template_id",
    value: "",
    info: "Email Template ID to send email if member's billing plan has changed",
    validation: "",
    placeholder: "Enter",
  },
  {
    header: "",
    type: "text",
    label: "Change password email template ID",
    name: "change_password_email_template_id",
    value: "",
    info: "Email Template ID to send email if member's Password has changed",
    validation: "",
    placeholder: "Enter",
  },
  {
    header: "",
    type: "text",
    label: "Redeem rewards email template ID",
    name: "redeem_rewards_email_template_id",
    value: "",
    info: "Email Template ID to send Redeem rewards email to the member",
    validation: "",
    placeholder: "Enter",
  },
  {
    header: "",
    type: "radio",
    label: "Send digitalcard link",
    name: "send_digitalcard_link",
    value: "",
    info: "If the client wants us to send link to the member's digital card in the welcome email",
    validation: "",
    placeholder: "Enter",
  },

  {
    header: "",
    type: "radio",
    label: "Enable refill text notification",
    name: "enable_refill_text_notification",
    value: "",
    info: "if set to Y, members will receive a refill reminder text notification",
    validation: "",
    placeholder: "Enter",
  },
  {
    header: "",
    type: "radio",
    label: "Enable maintenance drug notification",
    name: "enable_maintenance_drug_notification",
    value: "",
    info: "If the notification should be sent as reminder for refill",
    validation: "",
    placeholder: "Enter",
  },
  {
    header: "",
    type: "text",
    label: "Maintenance drug notification reminder",
    name: "maintenance_drug_notification_time",
    value: "",
    info: "Notification should be sent for refill before this number of days",
    validation: "",
    placeholder: "Enter",
  },
  {
    header: "",
    type: "text",
    label: "Maintenance drug refill %",
    name: "maintenance_drug_refill_pct",
    value: "",
    info: "Notification should be sent for refill when this much percentage of drugs is consumed",
    validation: "",
    placeholder: "Enter",
  },

  {
    header: "",
    type: "text",
    label: "Default Communication",
    name: "default_communication_option",
    value: "",
    info: "Option specified here is used a default mode for communication ",
    validation: "",
    placeholder: "Enter",
  },
  {
    header: "",
    type: "text",
    label: "Refill text notification threshold for Mailorder pharmacy",
    name: "refill_text_notification_threshold_mail",
    value: "",
    info: "For mail order details, how many days prior to the refill date should the text notification reminder should be sent to the member",
    validation: "",
    placeholder: "Enter",
  },
  {
    header: "",
    type: "text",
    label: "Refill text notification threshold for Retail pharmacy",
    name: "refill_text_notification_threshold_retail",
    value: "",
    info: "For Retail Refills, how many days prior to the refill date should the text notification reminder should be sent to the member",
    validation: "",
    placeholder: "Enter",
  },
];

export const depFieldAttribute = [
  {
    header: "",
    type: "radio",
    label: "Send employee reminder email final",
    name: "send_employee_reminder_email_final",
    value: "",
    info: "Turn on to send final reminder email to the Primary member to register app",
    validation: "",
    placeholder: "Enter",
  },
  {
    header: "",
    type: "text",
    label: "Employee registration email final reminder Template ID",
    name: "employee_reminder_email_final_template_id",
    value: "",
    info: "Email template id to send final reminder email to the Primary member to register app",
    validation: "",
    placeholder: "Enter",
  },
  {
    header: "",
    type: "text",
    label: "Number of days for Employee registration email final reminder",
    name: "emp_registration_reminder_final",
    value: "",
    info: "Number of days when the Final reminder is to be  sent to primary account holder to register",
    validation: "",
    placeholder: "Enter",
  },

  {
    header: "",
    type: "radio",
    label: "Send employee reminder email 1",
    name: "send_employee_reminder_email_1",
    value: "",
    info: "Turn on to send first reminder email to the Primary member to register app",
    validation: "",
    placeholder: "Enter",
  },
  {
    header: "",
    type: "text",
    label: "Employee registration email 1st reminder Template ID",
    name: "employee_reminder_email_1_template_id",
    value: "",
    info: "Email template id to send firtst reminder email to the Primary member to register app",
    validation: "",
    placeholder: "Enter",
  },
  {
    header: "",
    type: "text",
    label: "Number of Days for Dependent registration email 1st Reminder",
    name: "emp_registration_reminder3",
    value: "",
    info: "Number of days when the second reminder is to be  sent to primary account holder to register",
    validation: "",
    placeholder: "Enter",
  },
  {
    header: "",
    type: "radio",
    label: "Send employee reminder email 2",
    name: "send_employee_reminder_email_2",
    value: "",
    info: "Turn on to send second reminder email to the Primary member to register app",
    validation: "numeric",
    placeholder: "Enter",
  },
  {
    header: "",
    type: "text",
    label: "Employee registration email 2nd reminder Template ID",
    name: "employee_reminder_email_2_template_id",
    value: "",
    info: "Email template id to send second reminder email to the Primary member to register app",
    validation: "",
    placeholder: "Enter",
  },

  {
    header: "",
    type: "text",
    label: "Number of days for Employee registration email 2nd reminder ",
    name: "emp_registration_reminder2",
    value: "",
    info: "number of days when the first reminder is to be  sent to primary account holder to register",
    validation: "",
    placeholder: "Enter",
  },

  {
    header: "",
    type: "radio",
    label: "Send dependent Registration Reminder",
    name: "send_dependent_registration_reminder",
    value: "",
    info: "Turn on to send reminder email to primary member to remind to have dependent member register the app ",
    validation: "",
    placeholder: "Enter",
  },
  {
    header: "",
    type: "text",
    label: "Dependent registration reminder template ID",
    name: "dependent_registration_reminder_template_id",
    value: "",
    info: "Email template id to send reminder email to primary member to remind to have dependent member register the app ",
    validation: "numeric",
    placeholder: "Enter",
  },
  {
    header: "",
    type: "text",
    label: "Dependent registration email reminder",
    name: "dependent_registration_email_reminder",
    value: "",
    info: "Number of days when reminder is to be sent to primary member to hace dependent member register the app. ",
    validation: "",
    placeholder: "Enter",
  },
];
