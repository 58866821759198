import React, { useState, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as _ from 'lodash'
import FliptGrid from '../../../../components/fliptGrid'
import FliptButton from '../../../../components/form/fliptButton'
import FliptDropdown from '../../../../components/form/fliptDropdown'
import BreadCrumb from '../../../../components/breadCrumb'
import ImpactedObject from './drugInformation/impactedObject'
import { Creators as DrugManagementActions } from '../../../../redux/reducers/api/drugManagement'
import { Creators as AppActions } from '../../../../redux/reducers/app'
import {
  parseQueryString,
} from '../../../../utils/utilities'
import ApproveWithNotes from './popup/approveWithNotes'
import ApproveCellRenderer from './popup/approveCellRenderer'
import ChangeSummary from './popup/changeSummary'
import { ButtonRenderer, ColorCodedStatusRenderer } from '../../../../components/fliptGrid/cellRenderers'
import Config from '../../../../config'

const BrandTier = (props) => {
  const { state, actions } = props
  const { brandData: dat, drugApprovalFreeze, reloadPages } = state
  const [data, setData] = useState(dat)
  const { drugs, object_details, ...impactedObjects } = data
  const { name, history } = props
  const { file_id, module, name: doc_id } = parseQueryString(history.location.search)
  const [selections, setSelections] = useState([])
  const [bulkActions, setBulkActions] = useState(null)
  const bulkSelectOptions = ['approve without notes', 'approve with notes'].map(e => ({ key: e, text: e, value: e, disabled: !!drugApprovalFreeze }))

  const onGPISelect = (rowData) => {
    actions.setModalComponent({
      modalProperties: {
        size: 'medium',
      },
      contents: <ChangeSummary {...rowData} onClose={onClose} />,
    })
    actions.toggleModal()
  }

  const onActionClicked = (index, rowData, type) => {
    const {
      change_type,
      drug_name,
      new_value,
      old_value,
    } = rowData
    const ndc = selections.map(x => ({
      change_type: x.change_type,
      drugName: x.drug_name,
      newValue: x.new_value,
      oldValue: x.old_value,
      ndc: x.ndc,
    }) )
    const changeInfo = {
      change: change_type,
      drugName: drug_name,
      newValue: new_value,
      oldValue: old_value,
      ndc,
    }
    actions.setModalComponent({
      modalProperties: {
        size: 'medium',
      },
      contents: <ApproveWithNotes {...changeInfo} onSave={onSave} />,
    })
    if (type === 0) {
      return actions.dmApproveChanges({ file_id, notes: '', ...changeInfo })
    }
    return actions.toggleModal()
  }

  const onSave = ({ notes, ndc, ...others }) => {
    actions.dmApproveChanges({ file_id, ndc, ...others })
    actions.toggleModal()
  }
  const onClose = () => {
    actions.toggleModal()
  }
  const impactedObjectHeaders = [
    'select',
    'event',
    'action',
    'clinical_priority',
    'drug_name',
    'ndc',
    'gpi',
    'change_type',
    'old_value',
    'new_value',
    'drug_impact',
    'status',
    'approval_date',
    'approved_by',
  ]

  const impactedObjectRendererParams = {
    select: {
      overrideHeader: '',
      width: 5,
      headerCheckboxSelection: true,
      checkboxSelection: (row) => (row.data.status === 'Need Review'),
      showDisabledCheckboxes: true,
      headerCheckboxSelectionFilteredOnly: true,
    },
    event: {
      cellRenderer: ApproveCellRenderer,
      state: {
        onClick: onActionClicked,
        drugApprovalFreeze,
      },
      width: 200,
      overrideHeader: 'Action',
    },
    action: {
      width: 200,
      overrideHeader: 'Type',
    },
    clinical_priority: {
      cellRenderer: ColorCodedStatusRenderer,
      width: 200,
      overrideHeader: 'Priority',
    },
    drug_name: {
      width: 200,
      overrideHeader: 'Drug Name',
    },
    ndc: {
      width: 200,
      overrideHeader: 'NDC',
    },
    gpi: {
      cellRenderer: ButtonRenderer,
      searchArgs: { onClick: onGPISelect, key: 'gpi', className: 'linkButton' },
      width: 200,
      overrideHeader: 'GPI',
    },
    change_type: {
      width: 200,
      overrideHeader: 'Change',
    },
    old_value: {
      width: 200,
      overrideHeader: 'Old Value',
    },
    new_value: {
      width: 200,
      overrideHeader: 'New Value',
    },
    drug_impact: {
      width: 200,
      overrideHeader: 'Drug Impact',
    },
    status: {
      cellRenderer: ColorCodedStatusRenderer,
      width: 200,
      overrideHeader: 'Review Status',
    },
    approval_date: {
      width: 200,
      overrideHeader: 'Date/Time Approved',
    },
    approved_by: {
      width: 200,
      overrideHeader: 'User',
    },
  }

  useEffect(() => {
    if (dat && !_.isEqual(data, dat)) {
      setData(dat)
    }
  }, [dat])
  useEffect(() => {
    setTimeout(() => actions.getDmBrandData({ file_id, module, doc_id }), Config.COMPONENT_DATA_LOAD_TIME)
  }, [reloadPages])

  const onSelectionChanged = (data) => {
    const selectedRows = data.api.getSelectedRows()
    const filterdRow = selectedRows.filter(x=> x.status === 'Need Review')
    setSelections(filterdRow)
  }

  const handleBulkActions = () => {
    const ndc = selections.map(x => ({
      change_type: x.change_type,
      drugName: x.drug_name,
      newValue: x.new_value,
      oldValue: x.old_value,
      ndc: x.ndc,
    }))
    if (bulkActions === 'approve with notes') {
      actions.setModalComponent({
        modalProperties: {
          size: 'medium',
        },
        contents: <ApproveWithNotes multipleChanges={true} ndc={ndc} onSave={onSave} />,
      })
      return actions.toggleModal()
    }
    setSelections([])
    return actions.dmApproveChanges({ file_id, notes: '', ndc })
  }


  return (
    <div id="planDesignManagement">
      <BreadCrumb {...props} />
      <div className="header">
        {name}
      </div>
      <div className="impactedObjectContainer">
        <ImpactedObject {...impactedObjects} {...object_details} module={module} doc_id={doc_id} />
      </div>
      <div className="header">
        Total Changes (
        {drugs?.length || 0}
        )
      </div>
      <div className="content">
        <FliptGrid rowSelection={'multiple'} onSelectionChanged={onSelectionChanged} data={drugs} headers={impactedObjectHeaders} cellRendererParams={impactedObjectRendererParams} />
      </div>
      <div className='header-actions'>
          {(!_.isEmpty(selections)) && <div className='header-bulkactions'>
            <FliptDropdown placeholder="Bulk Actions" label="" name="bulk_actions" value={bulkActions} options={bulkSelectOptions} onChange={(e, d) => setBulkActions(d.value)} stylized />
            <FliptButton disabled={(bulkActions === null || selections.length === 0)} className="closeButton" compact name="Approve" onClick={handleBulkActions} />
          </div>}
        </div>
      <div id="close-button">
        <FliptButton className="closeButton" compact name="Close" onClick={() => history?.goBack()} />
      </div>
    </div>
  )
}
const mapStateToProps = (state) => ({
  state: {
    brandData: state.drugManagement.brandData,
    drugApprovalFreeze: state.drugManagement.drugApprovalFreeze,
    reloadPages: state.drugManagement.reloadPages,
    app: state.app,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...DrugManagementActions,
    ...AppActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BrandTier)
