import Immutable from 'seamless-immutable'
import { createActions, createReducer } from 'reduxsauce'

export const INITIAL_STATE = Immutable({
  formularyDataPerPage: 20,
  formularyLookupData: [],
  total_rows: null,
  planNameLookupData: [],
})

export const setFormularyLookupDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  formularyLookupData: payload.data,
  total_rows: payload.total_rows,
})

export const setPlanNameLookupDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  planNameLookupData: payload.data,
})

export const clearFormularyLookupDataReducer = () => INITIAL_STATE

export const { Types, Creators } = createActions({
  clearFormularyLookupData: null,
  clearReducer: null,
  getFormularyLookupData: null,
  getGpiLookupData: null,
  getPlanNameLookupData: ['payload'],
  setFormularyLookupData: ['payload'],
  setPlanNameLookupData: ['payload'],
})

const HANDLERS = {
  [Types.CLEAR_REDUCER]: clearFormularyLookupDataReducer,
  [Types.SET_FORMULARY_LOOKUP_DATA]: setFormularyLookupDataReducer,
  [Types.CLEAR_FORMULARY_LOOKUP_DATA]: clearFormularyLookupDataReducer,
  [Types.SET_PLAN_NAME_LOOKUP_DATA]: setPlanNameLookupDataReducer,
}

export default createReducer(INITIAL_STATE, HANDLERS)
