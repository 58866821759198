import React, { PureComponent, useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import moment from "moment";

import "./styles.scss";
import BreadCrumb from "../../../components/breadCrumb";
import Config from "../../../config";
import FliptGrid from "../../../components/fliptGrid";
import { Creators as AppActions } from "../../../redux/reducers/app";
import { Creators as NavigationActions } from "../../../redux/reducers/navigation";
import { Creators as ProgramListCreationActions } from "../../../redux/reducers/api/programListManagement";
import { Creators as RPMActions } from "../../../redux/reducers/rpm";
import {
  LinkRenderer,
  ColorCodedStatusRendererUpdated,
  ActionPopupMenuRenderer,
} from "../../../components/fliptGrid/cellRenderers";
import {
  convertDateTimeToDate,
  filterRowData,
  sortByName,
} from "../../../utils/utilities";
import CopyProgramListObject from "./createProgramList/CopyProgramListObject";
import HeaderLayout from "../../../components/headers/headerLayout";
import { calcGridRowHeight } from "../../../utils/utilities";
import ImpactAnalysis from "../impactAnalysis";

const ProgramList = (props) => {
  const [showPopupFlag, setShowPopupFlag] = useState(false);
  const [record, setRecord] = useState({});

  const { state, actions } = props;
  const { programListDrafts } = state;

  useEffect(() => {
    setTimeout(
      () => actions.getAllProgramListData(),
      Config.COMPONENT_DATA_LOAD_TIME
    );
  }, []);

  const sendForReview = (form) => {
    const payload = {
      doc_id: form.doc_id,
      module: "PROGRAM_LIST",
    };

    actions.rpmSendForReview(payload);
    setTimeout(
      () => actions.getAllProgramListData(),
      Config.COMPONENT_DATA_LOAD_TIME
    );
  };

  const checkActionType = (rowdata) => {
    if (rowdata && rowdata["qsArgs"]) {
      const doc_id = rowdata["doc_id"];
      const action = rowdata["qsArgs"]["selected_action"];

      let data = programListDrafts.filter((obj) => {
        return obj.id === doc_id;
      });
      setRecord(data);
      if (action === "copy_object") {
        setShowPopupFlag(true);
      } else if (action === "send_for_review") {
        sendForReview(rowdata);
      } else if (action === "impact_analysis") {
        actions.setModalComponent({
          modalProperties: {
            size: 'large',
          },
          contents: <ImpactAnalysis
            data={data}
          />,
        })
        actions.toggleModal()
      }
    }
  };

  const headers = [
    "actions",
    "doc_id",
    "name",
    "status",
    "version",
    "create_date",
    "author",
    "last_updated",
  ];
  const headerMapping = {
    module_name: "name",
    last_updated_by: "author",
    doc_version: "version"
  };
  const cellRendererParams = {
    name: {
      cellRenderer: LinkRenderer,
      path: "/program-list",
      searchArgs: { doc_id: "doc_id" },
      state: { editMode: true },
      minWidth: 150,
      wrapText: true,

    },
    status: {
      cellRenderer: ColorCodedStatusRendererUpdated,
      minWidth: 100,
    },
    version: {
      wrapText: true,
    },
    author: {
      minWidth: 350,
      wrapText: true,
    },
    actions: {
      cellRenderer: ActionPopupMenuRenderer,
      pinned: "right",
      maxWidth: 100,
      cellClass: "actionsColumns",
      suppressMenu: true,
      searchArgs: {
        onClick: (rowdata) => checkActionType(rowdata),
        path: "/program-list-creation-management",
        qsArgs: {
          doc_id: "doc_id",
          action: "COPY_OBJECT",
          module: "PROGRAM_LIST",
          status: "status",
        },
      },
    },
    doc_id: {
      hide: true,
    },
  };

  const _getRowHeight = () => calcGridRowHeight(undefined, 30);

  const gridData =
    programListDrafts && programListDrafts.length
      ? programListDrafts
        .filter((d) => d.module === "PROGRAM_LIST")
        .map((d) => ({
          ...filterRowData(
            {
              ...d,
              doc_id: d.id,
              status: d.status,
              create_date: moment(d.create_date).format("MM-DD-YYYY"),
              last_updated: convertDateTimeToDate(d.last_updated),
            },
            headers,
            headerMapping
          ),
        }))
      : [];

  const gridOptions = {
    domLayout: "autoHeight",
    rowHeight: "auto",
    autoSizeStrategy: {
      type: "fitCellContents",
    },
  };

  return (
    <div className="programListManagement">
      <BreadCrumb {...props} />
      <HeaderLayout
        heading="Program List Creation and Management"
        nameofButton="Create New Program List"
        link="program-list"
      />
      <div className="agGrid content">
        <FliptGrid
          data={
            gridData && gridData.length > 0 ? gridData.sort(sortByName) : []
          }
          headers={headers}
          gridOptions={gridOptions}
          cellRendererParams={cellRendererParams}
          // doAutoSize={true}
          getRowHeight={_getRowHeight}
          handleGridSize={true}
        />
      </div>
      {showPopupFlag && (
        <CopyProgramListObject
          data={record}
          showTabFlag={showPopupFlag}
          setShowTabFlag={setShowPopupFlag}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  state: {
    allProgramListData: state.programListCreation.allProgramListData,
    programListDrafts: state.rpm.documents,
  },
});

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
    ...NavigationActions,
    ...ProgramListCreationActions,
    ...RPMActions,
  };

  return {
    actions: bindActionCreators(allActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProgramList);
