import React from 'react'

import { Button, Icon } from 'semantic-ui-react'

export default function AddRow(props) {
  const {
    addRow,
  } = props

  return (
    <Button compact size="small" onClick={addRow} color="youtube">
      <Icon name="add" />
      Add Condition
    </Button>
  )
}
