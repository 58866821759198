import {
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects'

import { Types, Creators as MedicareSetupActions } from '../../reducers/api/medicareSetup'
import { Creators as AppActions } from '../../reducers/app'
import {
  getAppState, getApiPath, getMedicareSetupData
} from '../../reducers/selectors'
import { getUserHierarchyAccess } from '../user/user'
import { fetchPost, fetchPut } from '../../../utils/fetchApi'

export default [
  createMedicareDPlanWatcher,
  getMedicareDPlanDataWatcher,
  getMedicareSetupDataWatcher,
  getMedicareSetupDetailDataWatcher,
  saveMedicareSetupDetailDataWatcher,
  getMedicareStandardDataWatcher,
  getMedicareStandardDetailWatcher,
  getMedicareSetupYearsWatcher,
]

/* WATCHERS */
function* createMedicareDPlanWatcher() {
  yield takeLatest(Types.CREATE_MEDICARE_D_PLAN, createMedicareDPlanHandler)
}

function* getMedicareDPlanDataWatcher() {
  yield takeLatest(Types.GET_MEDICARE_D_PLAN_DATA, getMedicareDPlanDataHandler)
}

function* getMedicareSetupDataWatcher() {
  yield takeLatest(Types.GET_MEDICARE_SETUP_DATA, getMedicareSetupDataHandler)
}

function* getMedicareSetupDetailDataWatcher() {
  yield takeLatest(Types.GET_MEDICARE_SETUP_DETAIL_DATA, getMedicareSetupDetailDataHandler)
}

function* saveMedicareSetupDetailDataWatcher() {
  yield takeLatest(Types.SAVE_MEDICARE_SETUP_DETAIL_DATA, saveMedicareSetupDetailDataHandler)
}

function* getMedicareStandardDataWatcher() {
  yield takeLatest(Types.GET_MEDICARE_STANDARD_DATA, getMedicareStandardDataHandler)
}

function* getMedicareStandardDetailWatcher() {
  yield takeLatest(Types.GET_MEDICARE_STANDARD_DETAIL, getMedicareStandardDetailHandler)
}

function* getMedicareSetupYearsWatcher() {
  yield takeLatest(Types.GET_MEDICARE_SETUP_YEARS, getMedicareSetupYearsHandler)
}

/* HANDLERS */
function* createMedicareDPlanHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const { api_path } = yield select(getApiPath, 'medicare-plan')
    const url = `${serviceUrl}${api_path}`

    let newPlan = false;
    let plan_year = "";
    
    if('plan_year' in payload['medicare_plan_info']) {
        plan_year = payload.medicare_plan_info?.plan_year;
    } else {
        plan_year = payload.lics_plan?.plan_year;
    }

    if ('medicare_plan_info' in payload) {
      newPlan = true
      payload = {
        ...payload.medicare_plan_info,
        phases: payload.phases,
        plan_year: plan_year
      }
    }

    yield call(fetchPut, url, payload)

    yield put(MedicareSetupActions.getMedicareDPlanData())

    const transitionalPortal = {
      header: `Medicare D Plan successfully ${newPlan ? 'created' : 'updated'}`,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  } catch (err) {
    console.log('createMedicareDPlanHandler Error >Data ', err)

    const transitionalPortal = {
      header: 'Medicare Plan Submission Failed',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* getMedicareDPlanDataHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const { api_path } = yield select(getApiPath, 'medicare-plan')
    const url = `${serviceUrl}${api_path}`
    let response
    payload.cag = yield call(getUserHierarchyAccess)
    if (!!payload) {
      response = yield call(fetchPost, url, payload)
      let plan = response?.data
      if ('doc_id' in payload && plan?.length > 0) {
        plan = [plan.find(x => x?.medicare_plan_info?.doc_id === payload?.doc_id)]
        yield put(MedicareSetupActions.setActivePlanData(plan))
      }
      yield put(MedicareSetupActions.setMedicareDPlanData(plan))
    } else {
      response = yield call(fetchPost, url)
      yield put(MedicareSetupActions.setMedicareDPlanData(response.data))
    }
    
  } catch (err) {
    console.log('getMedicareDPlanHandler Error >Data ', err)

    const transitionalPortal = {
      header: 'Medicare Plan retrieval get Failed',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* getMedicareSetupDataHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const { api_path } = yield select(getApiPath, 'medicare-lics-setup')
    const url = `${serviceUrl}${api_path}`
    let response

    if (!!payload) {
      response = yield call(fetchPost, url, payload)
    } else {
      response = yield call(fetchPost, url, {})
    }

    yield put(MedicareSetupActions.setMedicareSetupData(response.data))
  } catch (err) {
    console.log('getMedicareSetupDataHandler Error >Data ', err)

    const transitionalPortal = {
      header: 'Medicare Setup get Failed',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* getMedicareSetupDetailDataHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const { api_path } = yield select(getApiPath, 'medicare-lics-setup')
    const url = `${serviceUrl}${api_path}`
    const response = yield call(fetchPost, url, payload)

    yield put(MedicareSetupActions.setMedicareSetupDetailData(response.data))
    yield put(MedicareSetupActions.setLoading({ data: false }))
  } catch (err) {
    console.log('getMedicareSetupDetailDataHandler Error >Data ', err)

    const transitionalPortal = {
      header: 'Medicare Setup detail Failed',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    yield put(MedicareSetupActions.setLoading({ data: false }))
  }
}

function* saveMedicareSetupDetailDataHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const { api_path } = yield select(getApiPath, 'medicare-lics-setup')
    const url = `${serviceUrl}${api_path}`
    yield call(fetchPut, url, payload)

    yield put(MedicareSetupActions.setLoading({ data: false }))
    yield put(MedicareSetupActions.getMedicareSetupData())

    const transitionalPortal = {
      header: `Medicare LICS Plan successfully saved`,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  } catch (err) {
    console.log('saveMedicareSetupDetailDataHandler Error >Data ', err)

    const transitionalPortal = {
      header: 'Medicare LICS Plan Could Not Be Saved',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    yield put(MedicareSetupActions.setLoading({ data: false }))
  }
}

function* getMedicareStandardDataHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const { api_path } = yield select(getApiPath, 'medicare-standard-plan')
    const url = `${serviceUrl}${api_path}`
    let response

    if (!!payload) {
      response = yield call(fetchPost, url, payload)
    } else {
      response = yield call(fetchPost, url, {})
    }

    yield put(MedicareSetupActions.setMedicareStandardData(response.data))
    yield put(MedicareSetupActions.setLoading({ data: false }))
  } catch (err) {
    console.log('getMedicareStandardDataHandler Error >Data ', err)

    const transitionalPortal = {
      header: 'Medicare Setup get Failed',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    yield put(MedicareSetupActions.setLoading({ data: false }))
  }
}

function* getMedicareStandardDetailHandler({ payload }) {
  try {
    const transitionalPortal = {
      header: 'Medicare Standard Plan already exist for Plan Year ' + payload['plan_year'],
      copy: "",
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  } catch (err) {
    console.log('getMedicareStandardDetailHandler Error >Data ', err)

    const transitionalPortal = {
      header: 'Medicare Setup get Failed',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* getMedicareSetupYearsHandler({ payload }) {
  try {
    const transitionalPortal = {
      header: payload['data'],
      copy: "",
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  } catch (err) {
    console.log('getMedicareSetupYearsHandler Error >Data ', err)

    const transitionalPortal = {
      header: 'Medicare Setup get Failed',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

export {
  getMedicareDPlanDataHandler,
  getMedicareSetupDataHandler,
  getMedicareSetupDetailDataHandler,
  saveMedicareSetupDetailDataHandler,
  getMedicareStandardDataHandler,
  getMedicareStandardDetailHandler,
  getMedicareSetupYearsHandler
}
