import React, { forwardRef, useImperativeHandle, useState } from 'react';

export default forwardRef((props, ref) => {
  const [data, setData] = useState(
    props.api.getDisplayedRowAtIndex(props.rowIndex)?.data || ''
  );

  useImperativeHandle(ref, () => {
    return {
      getReactContainerClasses() {
        return ['custom-tooltip'];
      },
    };
  });

	let name = ''
	switch (props?.column?.colId) {
		case 'application':
			name = data.application
			break
		case 'screen':
			name = data.screen
			break
		case 'feature':
			name = data.feature
			break
		case 'function':
			name = data.function
			break
		case 'ag-Grid-AutoColumn':
			name = props.value.split('_')[0]
			break
		default:
			break
	}
	
  return (
    <div
      className="custom-tooltip"
      style={{ backgroundColor: props.color || 'white' }}
    >
      <p>
        <span>{name || ''}</span>
      </p>
			<p>
				<span>{data?.description || props.value.split(/_(.+)/)[1]}</span>
			</p>
    </div>
  );
});