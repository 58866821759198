import React from 'react'
import FliptGrid from '../../../../../components/fliptGrid'
import FliptButton from '../../../../../components/form/fliptButton'

import { LinkContainerRenderer } from '../../../../../components/fliptGrid/cellRenderers'
import './styles.scss'
import { filterRowData, createQueryString } from '../../../../../utils/utilities'
import { Link } from 'react-router-dom'


export default ({ data, memberState }) => {
  const { pa_details } = data
  const paDetails = pa_details.length ? pa_details : []
  const headers = [
    'gpi', 'drug_name', 'brand_generic', 'strengths', 'dosage_form','package_qty', 'override_type',
    'pa_decision_date', 'pa_override_start_date', 'pa_override_end_date', 'pa_override_status',
  ]
  const paDetailsData = paDetails.map((d) => ({
    ...filterRowData(d, headers)
  }))

  const cellRendererParams = {
    pa_override_start_date: {
      overrideHeader: 'Claim Start Date',
    },
    pa_override_end_date: {
      overrideHeader: 'Claim End Date',
    },
    pa_override_status: {
      overrideHeader: 'Override Status',
    },
    strengths: {
      overrideHeader: 'Drug Strength',
    },
    dosage_form: {
      overrideHeader: 'Drug Form',
    },
    pa_decision_date: {
      overrideHeader: 'Decision Date'
    },
    package_qty: {
      overrideHeader: 'Package Quantity'
    }
  }

  return (
    <div className="section" id="claim-override-section">
      <div className="section-header">
        <h2>Medication Overrides</h2>
        {/* <Link to={`/pa-details${createQueryString(memberState)}`} className="btn-title">
          <FliptButton name="Detail" className="primary" />
        </Link> */}

        <Link to={{
          pathname: '/po-details',
          state: {
            data,
          },
        }}>
          <FliptButton name="Proactive Override" className="primary" />
        </Link>
      </div>

      <div className="content">
        <FliptGrid
          data={paDetailsData}
          headers={headers}
          cellRendererParams={cellRendererParams}
        />
      </div>
      {/* <div className="button-container">
        
      </div> */}
    </div>
  )
}
