import {
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects'
import moment from 'moment-timezone'
import { Types, Creators as BulkReprocessingRestackingActions } from '../../reducers/api/bulkReprocessingRestacking'
import { Creators as AppActions } from '../../reducers/app'
import {
  getAppState, getApiPath, getClaimLookup, getHistory, isMenuEmpty,
} from '../../reducers/selectors'
import { fetchPost, fetchPut, fileUploadPost } from '../../../utils/fetchApi'
import { Creators as NavigationActions } from '../../reducers/navigation'

import { createQueryString, filterRowData } from '../../../utils/utilities'

export default [
  getBulkReprocessingRestackingDataWatcher,
  queueClaimsWatcher,
  uploadBatchInputFileWatcher,
]

/* WATCHERS */
function* getBulkReprocessingRestackingDataWatcher() {
  yield takeLatest(Types.GET_BULK_REPROCESSING_RESTACKING_DATA, getBulkReprocessingRestackingDataHandler)
}

function* queueClaimsWatcher() {
  yield takeLatest(Types.QUEUE_CLAIMS, queueClaimsHandler)
}

function* uploadBatchInputFileWatcher() {
  yield takeLatest(Types.UPLOAD_BATCH_INPUT_FILE, uploadBatchInputFileHandler)
}

/* HANDLERS */
function* getBulkReprocessingRestackingDataHandler({ payload }) {
  try {
    const {
      form, params, headers, headerMapping } = payload
    if (!form.search_type) {
      params?.success({
        rowData: [],
        rowCount: 0,
      })
      return
    }
    // creates inverted headerMapping if needed
    if (headerMapping && params?.request) {
      const inv_headerMapping = Object.keys(headerMapping).reduce((obj, key) => {
        let newObj = {}
        newObj = { [headerMapping[key]]: key }
        return { ...obj, ...newObj }
      }, {})
      // uses inverted headerMapping to convert header names into db names
      params.request.filterModel = filterRowData(params?.request.filterModel, headers, inv_headerMapping)
    }
    const displayDates = params?.request && params.request?.filterModel && params.request.filterModel?.date_of_service
    if (displayDates) {
      // eslint-disable-next-line
      const { dateFrom, dateTo } = params.request.filterModel.date_of_service
      params.request.filterModel.date_of_service.dateFrom = dateFrom ? moment(dateFrom).format('YYYYMMDD') : ''
      params.request.filterModel.date_of_service.dateTo = dateTo ? moment(dateTo).format('YYYYMMDD') : ''
      params.request.filterModel.date_of_service.filter = dateFrom ? moment(dateFrom).format('YYYYMMDD') : ''
    }
    const { serviceUrl } = yield select(getAppState)
    const history = yield select(getHistory)
    const { claimsPerPage } = yield select(getClaimLookup)

    history.location.search = createQueryString({
      ...form,
      offset: params?.request.startRow,
    })
    const qs = history.location.search ? `${history.location.search}&limit=${claimsPerPage}` : ''
    if (!qs) {
      params?.success({
        rowData: [],
        rowCount: 0,
      })
      return
    }

    const reqData = {
      start_date: form?.start_date || form?.batch_input_start_date, // need it here to make it easy for json deserialization
      end_date: form?.end_date || form?.batch_input_end_date, // need it here to make it easy for json deserialization
      sortModel: params?.request.sortModel,
      filterModel: params?.request.filterModel,
      claim_status_filter: 'P', // bulk processing and restacking only returns processed claims
    }

    const empty = yield select(isMenuEmpty)
    if (empty) {
      yield take([AppTypes.SET_APP_SETTINGS])
    }
    let data = [], last_row = -1, url
    reqData.cag = {
      carrier: [form?.carrier] ?? [],
      account: [form?.account] ?? [],
      group: [form?.group] ?? [],
    }
    const { api_path: bulkProcessingAPI } = yield select(getApiPath, 'bulk-reprocessing-restacking')
    url = `${serviceUrl}${bulkProcessingAPI}${qs}`
    let response = yield call(fetchPost, url, reqData)
    data = response.data
    last_row = response.last_row

    let gridData = data
    gridData = data?.map((d) => ({
      ...filterRowData(d, headers, headerMapping),
      processed_date: moment(`${d.startDate}Z`).tz('America/New_York').format('MM/DD/YYYY HH:mm:ss'),
      date_of_service: moment(d.date_of_service).format('MM/DD/YYYY'),
    }))
    params?.success({
      rowData: gridData,
      rowCount: last_row,
    })
    let res
    const { api_path: memeberDetailsAPI } = yield select(getApiPath, 'member-details')
    url = `${serviceUrl}${memeberDetailsAPI}${qs}`
    res = yield call(fetchPost, url, data?.map((d) => d.cardholder_id), false)
    if (res) {
      const dataWithMemberDetails = gridData.map((d, idx) => {
        const row = { ...d }
        row.first_name = res.data[idx].first_name ? res.data[idx].first_name : 'N/A'
        row.last_name = res.data[idx].last_name ? res.data[idx].last_name : 'N/A'
        row.date_of_birth = res.data[idx].date_of_birth ? moment(res.data[idx].date_of_birth).format('MM/DD/YYYY') : 'N/A'
        row.plan_name = res.data[idx].plan_name ? res.data[idx].plan_name : 'N/A'
        row.is_vip = res?.data[idx]?.is_vip ?? false
        data[idx] = row
        return row
      })
      gridData = dataWithMemberDetails.map((d) => ({
        ...filterRowData(d, headers, headerMapping),
      }))
      params.success({
        rowData: gridData,
        rowCount: last_row,
      })
    }
  } catch (err) {
    const transitionalPortal = {
      header: 'Claim Lookup Failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* queueClaimsHandler({ payload }) {
  try {
    const { claims, editable, openAlert, goBack } = payload
    const { serviceUrl } = yield select(getAppState)
    const { api_path } = yield select(getApiPath, 'bulk-reprocessing-restacking')
    const url = `${serviceUrl}${api_path}`
    const { data, message } = yield call(fetchPut, url, claims)

    if (!data?.length) {
      const transitionalPortal = {
        header: 'Batch Not Created',
        copy: message
      }
      yield put(NavigationActions.navigateBack())
      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
      return
    }
    yield put(BulkReprocessingRestackingActions.clearBatchInput())
    yield put(BulkReprocessingRestackingActions.setBatchDetailData(JSON.parse(data[0])))
    if (!editable) {
      yield put(NavigationActions.navigateBack())
      if (openAlert) yield call(openAlert, 'CREATED')
      else {
        const transitionalPortal = {
          header: 'Batch Updated',
          copy: message
        }
        yield put(AppActions.displayTransitionalPortal(transitionalPortal))
      }
      return
    }
    const transitionalPortal = {
      header: 'Batch Updated',
      copy: message
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))

    if (goBack) {
      yield put(NavigationActions.navigateBack())
      return
    }
  } catch (err) {
    console.log('queueClaimsHandler failed: ', err)
    const transitionalPortal = {
      header: 'Error occured while submitting claims for reprocessing/restacking',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* uploadBatchInputFileHandler({ payload }) {
  try {
    const { field_type, selectedFile } = payload
    yield put(BulkReprocessingRestackingActions.clearBatchInput())
    const { serviceUrl } = yield select(getAppState)
    const { api_path } = yield select(getApiPath, 'upload-batch-input')
    const url = `${serviceUrl}${api_path}`
    const formData = new FormData()
    formData.set("field_type", field_type)
    formData.append("files", selectedFile);
    const response = yield call(fileUploadPost, url, formData)
    yield put(BulkReprocessingRestackingActions.setBatchInputFileData(response.data[0]))
  } catch (err) {
    console.log('uploadBatchInputFileHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Uploading Batch Input File Handler Failed',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

export {
  getBulkReprocessingRestackingDataHandler,
  queueClaimsHandler,
  uploadBatchInputFileHandler,
}