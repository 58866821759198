import {
    call,
    put, select, takeLatest, take
} from 'redux-saga/effects'
import { fetchGet, fetchPost } from '../../../utils/fetchApi'
import { Types, Creators as IntegrationManagementActions } from '../../reducers/api/integrationManagement'
import { Creators as AppActions, Types as AppTypes } from '../../reducers/app'
import { getApiPath, getAppState, getIntegrationMgmtState, getUserState, isMenuEmpty } from '../../reducers/selectors'
import moment from 'moment'

export default [
    getIntegrationMgmtLookup,
    getIntegrationMgmtDashboard,
    getBatchDetails,
    getBatchClaims,
    getClaimSummary,
    getEdits,
    addMember
]

/* WATCHERS */
function* getIntegrationMgmtDashboard() {
    yield takeLatest(Types.GET_INTEGRATION_MGMT_DASHBOARD, getIntegrationMgmtDashboardHandler)
}

function* getIntegrationMgmtLookup() {
    yield takeLatest(Types.GET_INTEGRATION_MGMT_LOOKUP, getIntegrationMgmtLookupHandler)
}

function* getBatchDetails() {
    yield takeLatest(Types.GET_BATCH_DETAILS, getBatchDetailsHandler)
}

function* getEdits() {
    yield takeLatest(Types.GET_EDITS, getEditsHandler)
}

function* addMember() {
    yield takeLatest(Types.ADD_MEMBER, addMemberHandler)
}


function* getBatchClaims() {
    yield takeLatest(Types.GET_BATCH_CLAIMS, getBatchClaimshandler)
}
function* getClaimSummary() {
    yield takeLatest(Types.GET_CLAIM_SUMMARY, getClaimSummaryHandler)
}



/* HANDLERS */
function* getIntegrationMgmtDashboardHandler({ payload }) {
    try {
        const {
            form, params
        } = payload
        const { serviceUrl } = yield select(getAppState)
        const menuEmpty = yield select(isMenuEmpty)
        if (menuEmpty) {
          yield take([AppTypes.SET_APP_SETTINGS])
        }
        const { userCAG = {} } = yield select(getUserState)
        const endDate = moment(form?.end_date).format("YYYY-MM-DD");
        const startDate = moment(form?.start_date).format("YYYY-MM-DD");
        const newForm = { ...form, end_date: endDate, start_date: startDate };
        if (userCAG) {
            newForm.cag = userCAG;
         }
        const cd = yield select(getApiPath, 'integration-management-dashboard')
        const { api_path } = cd
        const url = `${serviceUrl}${api_path}`
        const { data } = yield call(fetchPost, url, newForm)
         if (!data?.queue?.length) {
            const transitionalPortal = {
                header: 'No Data Found',
                copy: 'No Claims Found for Given Batch ID',
            }
            params?.success({
                rowData: [],
                rowCount: 0,
            })
            yield put(AppActions.displayTransitionalPortal(transitionalPortal))
            return
        }
         params?.success({
           rowData: data.queue,
           rowCount: data.queue.length
         });
        yield put(IntegrationManagementActions.setIntegrationMgmtDashboard(data.queue))
    } catch (err) {
        console.log('getIntegrationMgmtDashboardHandler Error >Data ', err)
        const transitionalPortal = {
            header: 'Getting Dashboard Data Failed',
            copy: err,
        }
        yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    }
}

function* getIntegrationMgmtLookupHandler({ payload }) {
    try {
        const { serviceUrl } = yield select(getAppState)
        const cd = yield select(getApiPath, 'im_files_lookup')
        const { api_path } = cd
        const url = `${serviceUrl}${api_path}`
        const { data } = yield call(fetchPost, url, payload)
        if (payload?.file_type) {
            yield put(IntegrationManagementActions.setIntegrationMgmtFilteredLookupData(data))
            return
        }
        yield put(IntegrationManagementActions.setIntegrationMgmtLookupData(data))
    } catch (err) {
        console.log('getIntegrationMgmtDashboardHandler Error >Data ', err)
        const transitionalPortal = {
            header: 'Getting Dashboard Data Failed',
            copy: err,
        }
        yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    }
}




function* addMemberHandler({ payload, callback }) {
    try {
        const { serviceUrl } = yield select(getAppState)
        const cd = yield select(getApiPath, 'add-test-member')
        const { api_path } = cd
        const url = `${serviceUrl}${api_path}`
        const { data, message } = yield call(fetchPost, url, payload)
        if (!data?.length) {
            const transitionalPortal = {
                header: 'Member Not Added',
                copy: message
            }
            yield put(IntegrationManagementActions.setIsMemberGenerated(false))
            yield put(AppActions.displayTransitionalPortal(transitionalPortal))
            return
        }
        yield put(IntegrationManagementActions.setIsMemberGenerated(true))
        yield put(IntegrationManagementActions.setAddMemberData(data[0]))
        if (callback) callback(data[0], payload["data"])
    } catch (err) {
        console.log('addMemberHandler Error >Data ', err)
        const transitionalPortal = {
            header: 'Adding Member Failed',
            copy: err,
        }
        yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    }
}

function* getEditsHandler() {
    try {
        const { serviceUrl } = yield select(getAppState)
        const cd = yield select(getApiPath, 'edits')
        const { api_path } = cd
        const url = `${serviceUrl}${api_path}`
        const { data } = yield call(fetchGet, url, {})
        if (!data?.length) {
            const transitionalPortal = {
                header: 'No Data Found',
                copy: 'No Edits Found',
            }
            yield put(AppActions.displayTransitionalPortal(transitionalPortal))
            return
        }
        yield put(IntegrationManagementActions.setEditsData(data))
    } catch (err) {
        console.log('getEditsHandler Error >Data ', err)
        const transitionalPortal = {
            header: 'Getting Edits Failed',
            copy: err,
        }
        yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    }
}

function* getBatchDetailsHandler({ payload }) {
    try {
        const { serviceUrl } = yield select(getAppState)
        const cd = yield select(getApiPath, 'get-restacking-by-batch-id')
        const { api_path } = cd
        const url = `${serviceUrl}${api_path}`
        const { data } = yield call(fetchPost, url, payload)
        if (!data?.length) {
            const transitionalPortal = {
                header: 'No Data Found',
                copy: 'No Details Found for Given Batch ID',
            }
            yield put(AppActions.displayTransitionalPortal(transitionalPortal))
            return
        }
        yield put(IntegrationManagementActions.setBatchDetailsData(data[0]))
    } catch (err) {
        console.log('getBatchDetailsHandler Error >Data ', err)
        const transitionalPortal = {
            header: 'Getting Batch Details Failed',
            copy: err,
        }
        yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    }
}

function* getBatchClaimshandler({ payload }) {
    try {
        const {
            form, params
        } = payload
        const { serviceUrl } = yield select(getAppState)
        const { pageNumber } = yield select(getIntegrationMgmtState)
        const { userCAG = {} } = yield select(getUserState)
        const cd = yield select(getApiPath, 'get-claims-by-batch-id')
        const { api_path } = cd
        const url = `${serviceUrl}${api_path}`
        const apiParams = { ...form }
        delete apiParams.batchDetailsData
        apiParams.cag = userCAG
        if (params?.request.startRow) yield put(IntegrationManagementActions.setPageNumber(params?.request.startRow))
        apiParams.offset = pageNumber ? pageNumber : params?.request.startRow
        const { data, last_row } = yield call(fetchPost, url, apiParams)
        if (!data?.length) {
            const transitionalPortal = {
                header: 'No Data Found',
                copy: 'No Claims Found for Given Batch ID',
            }
            params?.success({
                rowData: [],
                rowCount: 0,
            })
            yield put(AppActions.displayTransitionalPortal(transitionalPortal))
            return
        }
        const gridData = data.map((claim) => {
            return {
                ...claim, calculated_drug_cost_difference: claim.calculated_drug_cost_new - claim.calculated_drug_cost,
                patient_pay_amount_difference: claim.patient_pay_amount_new - claim.patient_pay_amount
            }
        })
        gridData.sort((a, b) => parseFloat(b.patient_pay_amount_difference) - parseFloat(a.patient_pay_amount_difference));
        params?.success({
            rowData: gridData,
            rowCount: last_row,
        })
        yield put(IntegrationManagementActions.setBatchClaimsData(data))
    } catch (err) {
        console.log('getClaimsHandler Error >Data ', err)
        const transitionalPortal = {
            header: 'Getting Claims Handler Failed',
            copy: err,
        }
        yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    }
}

function* getClaimSummaryHandler({ payload }) {
    try {
        const { serviceUrl } = yield select(getAppState)
        const cd = yield select(getApiPath, 'claim-summary')
        const { userCAG = {} } = yield select(getUserState)
        payload.cag = userCAG
        const { api_path } = cd
        const url = `${serviceUrl}${api_path}`
        const { data } = yield call(fetchPost, url, payload)
        if (!data?.length) {
            const transitionalPortal = {
                header: 'No Data Found',
                copy: 'No Claim Summary Found for Given Auth ID',
            }
            yield put(AppActions.displayTransitionalPortal(transitionalPortal))
            return
        }
        yield put(IntegrationManagementActions.setClaimSummaryData(data))
    } catch (err) {
        console.log('getClaimSummaryHandler Error >Data ', err)
        const transitionalPortal = {
            header: 'Getting Claim Summary Failed',
            copy: err,
        }
        yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    }
}

export {
    getIntegrationMgmtDashboardHandler,
    getBatchDetailsHandler,
    getBatchClaimshandler,
    getClaimSummaryHandler,
    getEditsHandler,
    addMemberHandler,
    getIntegrationMgmtLookupHandler
}