import React, { forwardRef } from 'react'

import * as fieldValidators from '../../utils/validationHelpers'
import './styles.scss'

const validators = {
    code: fieldValidators.validateCode,
    email_address: fieldValidators.validateEmailAddress,
    numeric: fieldValidators.validateNumericAndEmptyField,
    password: fieldValidators.validatePassword,
    phone: fieldValidators.validatePhoneNumber,
    alphaNumeric: fieldValidators.validateAlphaNumeric,
}

const FliptPaInput = forwardRef((props = {}, ref) => {
    const {
        className, datatype, inputClass, label, required, stylized, validation, customValidation, onChange, onBlur,
    } = props

    const validateField = (el) => {
        const ct = el.currentTarget

        if (required && !ct.value) {
            ct.classList.add('has-error')
        } else {
            ct.classList.remove('has-error')
        }

        if (validation && validators[validation]) {
            if (!validators[validation](ct.value)) {
                if (validation === 'numeric') ct.value = ct.value.replace(/[^0-9.]/g, '')
                ct.classList.add('has-error')
            } else {
                ct.classList.remove('has-error')
            }
        } else if (!!customValidation) {
            if (!customValidation(ct.value)) ct.classList.add('has-error')
            else ct.classList.remove('has-error')
        }
    }

    const _onChange = (el) => {
        if (!onChange || typeof onChange !== 'function') return

        const ct = el.currentTarget

        if (validation && validators[validation]) {
            if (validators[validation](ct.value)) {
                onChange(el)
            }
        } else {
            onChange(el)
        }
    }

    const inputProps = { ...props }

    if (inputProps.disabled) {
        inputProps.value = inputProps.value || ''
    }

    const stylizedField = () => {
        const inputType = !!datatype && datatype === 'numeric' ? 'number' : 'text'
        return (
            <div className={`pa-stylized ${className || ''}`}>
                <div className="flipt-pa-label" hidden={inputProps.hidden || false}>
                    <label htmlFor={label}>{label}</label>
                </div>
                {
                    inputClass ? <input type={inputType} className={inputClass} {...inputProps} onKeyUp={validateField} onChange={_onChange} onBlur={onBlur} />
                        : <input type={inputType}  {...inputProps} onKeyUp={validateField} onChange={_onChange} onBlur={onBlur} />

                }
            </div>
        )
    }

    if (stylized) return stylizedField()

    return <input {...inputProps} onKeyUp={validateField} ref={ref} />
})

export default FliptPaInput