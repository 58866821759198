import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Creators as PrescriberLookupActions } from '../../../../redux/reducers/api/prescriberLookup'

import { Creators as MemberLookupActions } from '../../../../redux/reducers/api/memberLookup'
import { Creators as PriorAuthorizationCreationActions } from '../../../../redux/reducers/api/priorAuthorizationManagement'

import './styles.scss'
import BreadCrumb from '../../../../components/breadCrumb'
import FliptMemberSearch from '../../../../components/fliptMemberSearch'
import { Creators as AppActions } from '../../../../redux/reducers/app'
import FliptButton from '../../../../components/form/fliptButton'
import FliptPrescriberSearch from '../../../../components/fliptPrescriberSearch'
class CreatePACase extends Component {
    constructor(props) {
        super(props)

        this.state = {
            showCreateNewPlan: false,
            memberData: null,
            memberList: [],
            searchForm: {
                date_of_birth: '',
                first_name: '',
                last_name: '',
                date_of_birth: '',
                memberId: '',
                prescriberNpi: '',
                prescriber_first_name: '',
                prescriber_last_name: '',
            },
            prescriberData: null,
            agGridRef: React.createRef(),
        }
    }


    componentDidUpdate(prevProps) {
        const { props } = this
        const { updatePaCaseData } = props.state

        if (updatePaCaseData !== prevProps.state.updatePaCaseData && updatePaCaseData?.pa_case_id) {
            if (!props.isModal) {
                this.props.history.push('/pa-case-dashboard', { pa_case_id: updatePaCaseData.pa_case_id, editMode: true })
            } else if (props.isModal) {
                props.actions.toggleModal()
            }
        }
    }


    _createRequest = () => {
        const { prescriberData } = this.state
        if (!prescriberData) {
            const header = 'Fields Missing'
            const message = 'Please Select a Prescriber to Proceed'
            this._showTransitionalPortal(header, message)
            return;
        }
        const params = {
            intake_type: 'Manual',
            prescriber: {
                NPI: prescriberData.npi,
                first_name: prescriberData.first_name,
                last_name: prescriberData.last_name,
                address1: prescriberData.address,
                city: prescriberData.city,
                state: prescriberData.state,
                zip_code: prescriberData.zip_code,
                country_code: '',
                phone_number: prescriberData.phone_number,
                fax_number: prescriberData.fax_number
            }
        }
        this.props.actions.createPaIntakeQueue(params)
    }

    _showTransitionalPortal = (header, message) => {
        const { props } = this;
        const transitionalPortal = {
            header: header,
            copy: message,
        }
        props.actions.displayTransitionalPortal(transitionalPortal)
    }

    _onPrescriberSelectionChanged = (data) => {
        const selectedRows = data.api.getSelectedRows();
        if (selectedRows.length) this.setState({ prescriberData: selectedRows[0] })
        else this.setState({ prescriberData: null })
    }

    _cancelRequest = () => {
        this.props.history.goBack()
    }

    _goToNextScreen = () => {
        const { memberData } = this.state
        if (!memberData) {
            const header = 'Fields Missing'
            const message = 'Please add a Member to proceed'
            this._showTransitionalPortal(header, message)
            return;
        }
        this.props.actions.toggleModal()
    }

    updatePrescriberData = (data) => {
        if (data?.npi) {
            this.setState({
                prescriberData: data,
            })
        }
        else this.setState({ prescriberData: data })
    }

    updateMemberData = (data) => {
        const { updateMemberData } = this.props
        updateMemberData(data)
        if (data?.member_id) {
            this.setState({
                memberData: data,
            })
        }
        else this.setState({ memberData: data })
    }

    render() {
        const {
            createPaCaseData
        } = this.props.state
        const { searchForm, memberData } = this.state
        const { isModal } = this.props

        return (
            <div id="createPACase">
                {isModal ? null : <BreadCrumb {...this.props} />}
                {!createPaCaseData?.pa_case_id && !isModal && <div className="content">
                    <FliptPrescriberSearch
                        isPa
                        editMode
                        updatePrescriberData={(data) => this.updatePrescriberData(data)}
                        searchForm={searchForm} />
                </div>}

                {!createPaCaseData?.pa_case_id && !isModal && <div className="buttons-container">
                    <FliptButton className='primary' name='CREATE REQUEST' onClick={() => this._createRequest('MEMBER_SEARCH')} />
                    <FliptButton className='cancelButton' name='CANCEL' onClick={() => this._cancelRequest()} />
                </div>}

                {(createPaCaseData?.pa_case_id || isModal) && <div className="content">
                    <FliptMemberSearch
                        isPa
                        updateMemberData={(data) => this.updateMemberData(data)}
                        searchForm={searchForm} />
                </div>
                }
                {isModal ?
                    <div className="buttons-container">
                        <FliptButton disabled={!memberData} className='primary' name='SUBMIT' onClick={() => this._goToNextScreen()} />
                    </div> : null}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    state: {
        app: state.app,
        user: state.user,
        memberLookup: state.memberLookup,
        paIntakeQueue: state.priorAuthorizationCreation.paIntakeQueue,
        createPaCaseData: state.priorAuthorizationCreation.createPaCaseData,
        prescriberLookupData: state.prescriberLookup.prescriberLookupData,
        updatePaCaseData: state.priorAuthorizationCreation.updatePaCaseData,
    },
})

const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...MemberLookupActions,
        ...AppActions,
        ...PriorAuthorizationCreationActions,
        ...PrescriberLookupActions
    }

    return {
        actions: bindActionCreators(allActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreatePACase)
