import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import _ from 'lodash'
import { Creators as PlanManagementActions } from '../../../../redux/reducers/api/planManagement'
import './styles.scss'
import FliptSeparator from '../../../../components/form/fliptSeparator'
import { Table } from "semantic-ui-react/";
import DraggableTableRow from "./../listingHierarchy/draggableList";
import { Creators as AppActions } from '../../../../redux/reducers/app'
import FliptDropdown from '../../../../components/form/fliptDropdown'


class PharmacyNetworks extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            networks: [],
            network_edits: [],
            pharmacy_network_rank: [],
            pharmacy_network_tier_rank: [],
            pharmacy_network_edit_rank: [],
            selectedList: ''
        }
    }

    componentDidMount() {
        const { state, copyFormData } = this.props
        const { pharmacy_network_rank, networks, network_edits, pharmacy_network_tier_rank, pharmacy_network_edit_rank } = state
        const data = {
            networks: networks,
            network_edits: network_edits,
            pharmacy_network_rank: pharmacy_network_rank,
            pharmacy_network_tier_rank: pharmacy_network_tier_rank,
            pharmacy_network_edit_rank: pharmacy_network_edit_rank,
        }
        copyFormData(data)
        this.setState({ ...data, selectedList: '' })
    }

    componentDidUpdate(prevProps, prevState) {
        const { copyFormData } = this.props
        if (copyFormData && !_.isEqual(prevState, this.state)) {
            copyFormData(this.state)
        }
    }

    _updateFields = (el, data) => {
        const { fieldDetails } = this.props
        const network_edit_options = fieldDetails?.find(({ field, options }) => field === 'network_edits' && options.length > 0)
        const network_edit_keys = network_edit_options?.options.map(x => {
            x["text"] = x["display_name"]
            return x
        })
        const network_tier_options = fieldDetails?.find(({ field, options }) => field === 'network_tiers' && options.length > 0)
        const { name, value } = data || el.currentTarget
        this.setState((prevState) => ({
            ...prevState,
            [name]: value
        }))
        if (name === "network_edits") {
            this.setState((prevState) => ({
                ...prevState,
                pharmacy_network_edit_rank: network_edit_keys?.filter(item => prevState.network_edits?.includes(item.value))
                    .map((item, index) => {
                        const { value, text, ...rest } = item; // Destructure and exclude "value" and "text"
                        return { ...rest, network_edit_id: value, rank: index + 1 };
                    })
            }))
        }
        if (name === "networks") {
            this.setState((prevState) => ({
                ...prevState,
                pharmacy_network_tier_rank: this.get_matching_network_tiers(network_tier_options?.options, prevState.networks)
            }))
        }

    }


    get_matching_network_tiers(network_tier_options, networks) {
        let matchingTiers = [];
        let counter = 0
        for (const network of networks) {
            for (const each_tier_option of network_tier_options) {
                if (each_tier_option.hasOwnProperty(network) && each_tier_option[network]) {
                    let updated_rank_tier = each_tier_option[network].map(item => ({ ...item, rank: counter + 1 }))
                    if (updated_rank_tier) {
                        matchingTiers = matchingTiers.concat(updated_rank_tier);
                    }
                }
            }
        }
        return matchingTiers;
    }

    sortedAsc = (arr) => arr.sort((a, b) => {
        if (a.rank === null || a.rank === undefined) {
            return 1;
        }

        if (b.rank === null || b.rank === undefined) {
            return -1;
        }

        return a.rank < b.rank ? -1 : 1;
    });

    formatedNetworkValues = (arr) => {
        const formatedNetworkConfiguration = this.sortedAsc(arr.map((networkData) => ({
            network_type: networkData.value,
            rank: networkData.rank || null
        })))
        return formatedNetworkConfiguration;
    }

    swap = (a, b, selectedList) => {
        const { copyFormData } = this.props
        const customState = this.state
        customState[selectedList][a] = customState[selectedList].splice(b, 1, customState[selectedList][a])[0]
        customState[selectedList].map((item, i) => item['rank'] = i + 1)
        this.setState({
            ...customState,
        })
        delete customState.selectedList
        copyFormData(customState)
    }

    _renderDraggableList = (items, key, selectedList) => {
        return (
            <Table className="table-padding">
                <Table.Body>
                    {items.map((obj, i) => (
                        <DraggableTableRow key={i} i={i} action={(a, b) => this.swap(a, b, selectedList)}>
                            <Table.Cell active textAlign='center'>{i + 1} : {obj[key] || obj['display_name'] || obj['doc_name']}</Table.Cell>            </DraggableTableRow>
                    ))}
                </Table.Body>
            </Table>
        )
    }
    render() {
        const { pharmacy_network_rank, pharmacy_network_edit_rank, pharmacy_network_tier_rank, networks, network_edits } = this.state
        const { fieldDetails } = this.props
        const pnOptions = fieldDetails?.find(({ field, options }) => field === 'networks' && options.length > 0)
        const pnDropdown = pnOptions?.options.map(x => {
            x["text"] = x["display_name"]
            return x
        })

        const neOptions = fieldDetails?.find(({ field, options }) => field === 'network_edits' && options.length > 0)
        const neDropdown = neOptions?.options.map(x => {
            x["text"] = x["display_name"]
            return x
        })

        return (
            <div className="compounds">

                <div className="section-compounds">
                    <div className="compounds-dropdowns">
                        <FliptDropdown
                            placeholder="Select"
                            label="Pharmacy Networks"
                            name="networks"
                            value={networks}
                            options={pnDropdown}
                            onChange={this._updateFields}
                            multiple={true}
                            stylized
                        />
                    </div>
                    <div className="compounds-dropdowns">
                        <FliptDropdown
                            placeholder="Select"
                            label="Network Edits"
                            name="network_edits"
                            value={network_edits}
                            options={neDropdown}
                            onChange={this._updateFields}
                            multiple={true}
                            stylized
                        />
                    </div>
                </div>
                <div id="listing_hierachy">
                    <style>{`
                    .draggable {
                    cursor: move; /* fallback if grab cursor is unsupported */
                    cursor: grab;
                    cursor: -moz - grab;
                    cursor: -webkit - grab;
                    }
                    `}</style>
                    <FliptSeparator />
                    <div className="content">
                        <div className="umType">Network Hierarchy Configuration</div>
                        <div className="hierarchyLevel">
                            {this._renderDraggableList(pharmacy_network_rank ? pharmacy_network_rank : [], 'network_type', 'pharmacy_network_rank')}
                        </div>
                        <FliptSeparator />
                        <div className="umType">Network Tier Hierarchy Configuration</div>
                        <div className="hierarchyLevel">
                            {this._renderDraggableList(pharmacy_network_tier_rank ? pharmacy_network_tier_rank : [], 'doc_name', 'pharmacy_network_tier_rank')}
                        </div>
                        <FliptSeparator />
                        <div className="umType">Network Edit Hierarchy Configuration</div>
                        <div className="hierarchyLevel">
                            {this._renderDraggableList(pharmacy_network_edit_rank ? pharmacy_network_edit_rank : [], 'display_name', 'pharmacy_network_edit_rank')}
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}


const mapStateToProps = (state, props) => {
    const { fieldDetails, modelData } = props
    const { networks, network_edits, pharmacy_network_tier_rank, pharmacy_network_edit_rank } = modelData
    const networkTypesField = fieldDetails?.find(({ field, options }) => field === 'network_type' && options.length > 0)
    const pharmacy_network_rank = networkTypesField.options.map((field) => ({
        ...field,
        rank: modelData?.pharmacy_network_rank?.find((x) => x?.value === field.display_name || x?.network_type === field.display_name)?.rank,
    }))

    const sortedAsc = (arr) => arr.sort((a, b) => {
        if (a.rank === null || a.rank === undefined) {
            return 1;
        }

        if (b.rank === null || b.rank === undefined) {
            return -1;
        }

        return a.rank < b.rank ? -1 : 1;
    });

    return ({
        state: {
            app: state.app,
            pharmacy_network_rank: pharmacy_network_rank?.length && pharmacy_network_rank[0].hasOwnProperty('rank') ? sortedAsc(pharmacy_network_rank) : pharmacy_network_rank,
            networks: networks ? networks : [],
            network_edits: network_edits ? network_edits : [],
            pharmacy_network_tier_rank: pharmacy_network_tier_rank?.length && pharmacy_network_tier_rank[0].hasOwnProperty('rank') ? sortedAsc(pharmacy_network_tier_rank) : pharmacy_network_tier_rank,
            pharmacy_network_edit_rank: pharmacy_network_edit_rank?.length && pharmacy_network_edit_rank[0].hasOwnProperty('rank') ? sortedAsc(pharmacy_network_edit_rank) : pharmacy_network_edit_rank,
            pharmacyNetworkData: state.planManagement.pharmacy_network,
        },
    })
}



const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...PlanManagementActions,
        ...AppActions,
    }

    return {
        actions: bindActionCreators(allActions, dispatch),
    }
}

const pharmacyNetworksContainer = (props) => (
    <PharmacyNetworks
        editMode={true}
        {...props}
    />
)
export default PharmacyNetworks

export const PharmacyNetworksContainer = connect(mapStateToProps, mapDispatchToProps)(pharmacyNetworksContainer)

