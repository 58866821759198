export const documentGoverned = ['FORMULARY', 'FORMULARY_TIER', 'POS_REBATE', 'PRIOR_AUTHORIZATION', 'PROGRAM_LIST', 'QUANTITY_LIMIT', 'STEP_THERAPY', 'BENEFIT_PLAN_DOC']

export const documentGovernedValues =  {
  formulary: {
    text: 'Formulary',
    value: 'FORMULARY',
  },
  formulary_tier: {
    text: 'Formulary Tier',
    value: 'FORMULARY_TIER',
  },
  pos_rebate: {
    text: 'POS Rebate',
    value: 'POS_REBATE',
  },
	prior_authorization: {
		text: 'Prior Authorization',
		value: 'PRIOR_AUTHORIZATION',
	},
	program_list: {
    text: 'Program List',
    value: 'PROGRAM_LIST',
  },
	quantity_limit: {
    text: 'Quantity Limit',
    value: 'QUANTITY_LIMIT',
  },
	step_therapy: {
    text: 'Step Therapy',
    value: 'STEP_THERAPY',
  },
	benefit_plan: {
    text: 'Benefit Plan',
    value: 'BENEFIT_PLAN_DOC',
  },
}

export const companyData = ['FLIPTRX', 'FLIPT']

export const approvalStep = ['1', '2', '3', '4', '5', '6']

export const carrier = ['ALL', 'FLIPT', 'FLIPT']

export const account = ['ALL', 'ACS001', 'CARYN001', 'CASH001', 'FLIPT001']

export const group = ['ALL', 'ACSALL', 'CARYN', 'CASH', 'FLIPTALL']