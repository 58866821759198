import {
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects'

import { fetchGet, fetchPatch, fetchPut } from '../../../utils/fetchApi'
import { getAppState, getUserState } from '../../reducers/selectors'
import { Creators as UserManagementActions, Types as UserManagementTypes } from '../../reducers/userManagement'
import { Creators as AppActions } from '../../reducers/app'
import { Creators as NavigationActions } from '../../reducers/navigation'

export default [
  createUserManagementWatcher,
  editUserManagementUserWatcher,
  getUserManagementUsersWatcher,
  changeUserManagementPasswordWatcher,
  getUserManagementSingleUserWatcher,
  updateUserManagementUsersWatcher,
]

/* WATCHERS */
function* getUserManagementUsersWatcher() {
  yield takeLatest(UserManagementTypes.GET_USER_MANAGEMENT_USERS, getUserManagementUsersHandler)
}

function* getUserManagementSingleUserWatcher() {
  yield takeLatest(UserManagementTypes.GET_USER_MANAGEMENT_SINGLE_USER, getUserManagementSingleUserHandler)
}

function* createUserManagementWatcher() {
  yield takeLatest(UserManagementTypes.CREATE_USER_MANAGEMENT_USER, createUserManagementUserHandler)
}

function* editUserManagementUserWatcher() {
  yield takeLatest(UserManagementTypes.EDIT_USER_MANAGEMENT_USER, editUserManagementUserHandler)
}

function* changeUserManagementPasswordWatcher() {
  yield takeLatest(UserManagementTypes.CHANGE_USER_MANAGEMENT_PASSWORD, changeUserManageMentPasswordHandler)
}

function* updateUserManagementUsersWatcher() {
  yield takeLatest(UserManagementTypes.UPDATE_USER_MANAGEMENT_USERS, updateUserManagementUsersHandler)
}

/* HANDLERS */
function* getUserManagementUsersHandler() {
  try {
    const appState = yield select(getAppState)
    const url = `${appState.apiUrl}/fast/user-management`
    const response = yield call(fetchGet, url, {}, true)
    yield put(UserManagementActions.setUserManagementUsersList(response))
  } catch (err) {
    const transitionalPortal = {
      header: 'Unable to get users',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* getUserManagementSingleUserHandler({ payload }) {
  try {
    const appState = yield select(getAppState)
    const { data } = yield fetchGet(`${appState.apiUrl}/fast/user`, payload)
    if (!data.length) throw Error('No User Found')
    yield put(UserManagementActions.setUserManagementSingleUser(data[0]))
  } catch (err) {
    const transitionalPortal = {
      header: 'Unable to get the user ',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* createUserManagementUserHandler({ payload }) {
  try {
    const { apiUrl } = yield select(getAppState)

    yield fetchPut(`${apiUrl}/fast/user-management`, payload)

    const transitionalPortal = {
      header: 'User Sucessfully Created',
      copy: `${payload.first_name} ${payload.last_name} (${payload.email_address}) created`,
    }

    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    const pathname = '/user-management'
    yield put(NavigationActions.navigateTo({ pathname }))
  } catch (err) {
    const transitionalPortal = {
      header: 'User Creation Failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* editUserManagementUserHandler({ payload }) {
  try {
    const { apiUrl } = yield select(getAppState)

    yield fetchPatch(`${apiUrl}/fast/user-management`, payload)

    const transitionalPortal = {
      header: 'User Sucessfully Updated',
      copy: `${payload.first_name} ${payload.last_name} (${payload.email_address}) updated`,
    }

    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    const pathname = '/user-management'
    yield put(NavigationActions.navigateTo({ pathname }))
  } catch (err) {
    const transitionalPortal = {
      header: 'User Update Failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* changeUserManageMentPasswordHandler({ payload }) {
  try {
    const { apiUrl } = yield select(getAppState)
    const user = yield fetchPut(`${apiUrl}/fast/user/change-password`, payload)

    const transitionalPortal = {
      header: 'User Password Changed',
      copy: `${user.email_address}'s password updated.`,
    }

    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  } catch (err) {
    const transitionalPortal = {
      header: 'Change Password Error',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* updateUserManagementUsersHandler({ payload }) {
  try {
    const { users, activeState } = payload
    const { apiUrl } = yield select(getAppState)

    const response = yield fetchPatch(`${apiUrl}/fast/user-management-update-users`, { users, activeState })

    const transitionalPortal = {
      header: `${response?.message}`,
      copy: `${response?.message}`,
    }

    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    yield call(getUserManagementUsersHandler)
  } catch (err) {
    const transitionalPortal = {
      header: 'updateUserManagementUsers Failed',
      copy: err.message || err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

export {
  changeUserManageMentPasswordHandler,
  createUserManagementUserHandler,
  editUserManagementUserHandler,
  getUserManagementUsersHandler,
  getUserManagementSingleUserHandler,
  updateUserManagementUsersHandler,
}
