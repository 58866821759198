import { statesData } from "./fieldHelpers";

export const RequiredAttributeFields = [
  {
    header: "",
    type: "text",
    label: "Organization Name",
    name: "organization_custom_name",
    value: "",
    info: "organization name",
    validation: "name",
    placeholder: "Enter",
  },
  {
    header: "",
    type: "text",
    label: "Organization Id",
    name: "organization_custom_id",
    value: "",
    info: "Organization id",
    validation: "name",
    placeholder: "Enter",
    disabled: true
  },

  {
    header: "",
    type: "text",
    label: "Client Name",
    name: "client_custom_name",
    value: "",
    info: "Client Name",
    validation: "name",
    placeholder: "Enter",
  },
  {
    header: "",
    type: "text",
    label: "Client Id",
    name: "client_custom_id",
    value: "",
    info: "Client id",
    validation: "name",
    placeholder: "Enter",
    disabled: true
  },

  {
    header: "",
    type: "text",
    label: "Carrier Name",
    name: "carrier_custom_name",
    value: "",
    info: "Carrier name",
    validation: "name",
    placeholder: "Enter",
  },
  {
    header: "",
    type: "text",
    label: "Carrier Id",
    name: "carrier_custom_id",
    value: "",
    info: "Carrier id",
    validation: "name",
    placeholder: "Enter",
    disabled: true
  },
  {
    header: "",
    type: "text",
    label: "Account Name",
    name: "account_custom_name",
    value: "",
    info: "Account name",
    validation: "name",
    placeholder: "Enter",
  },
  {
    header: "",
    type: "text",
    label: "Account Id",
    name: "account_custom_id",
    value: "",
    info: "Account id",
    validation: "name",
    placeholder: "Enter",
    disabled: true
  },
  {
    header: "",
    type: "text",
    label: "Group Name",
    name: "group_custom_name",
    value: "",
    info: "Group name",
    validation: "name",
    placeholder: "Enter",
  },
  {
    header: "",
    type: "text",
    label: "Group Id",
    name: "group_custom_id",
    value: "",
    info: "Group id",
    validation: "name",
    placeholder: "Enter",
    disabled: true
  },
  {
    header: "",
    type: "text",
    label: "Address Line 1",
    name: "address1",
    value: "",
    info: "Address Line 1",
    validation: "name",
    placeholder: "Enter",
  },
  {
    header: "",
    type: "text",
    label: "Address Line 2",
    name: "address2",
    value: "",
    info: "Address Line 2",
    validation: "name",
    placeholder: "Enter",
  },
  {
    header: "",
    type: "text",
    label: "City",
    name: "city",
    value: "",
    info: "City",
    validation: "",
    placeholder: "Enter",
  },
  {
    header: "",
    type: "dropdown",
    label: "State",
    name: "state",
    value: "",
    info: "State",
    validation: "",
    placeholder: "Enter",
    optionList: statesData || []
  },
  {
    header: "",
    type: "text",
    label: "Zip Code",
    name: "zip",
    value: "",
    info: "Zipcode",
    validation: "zipCode",
    placeholder: "Enter",
    maxLength: 10
  },
  {
    header: "",
    type: "text",
    label: "Contact Name",
    name: "contactname",
    value: "",
    info: "main contact for the account",
    validation: "",
    placeholder: "Enter",
  },
  {
    header: "",
    type: "text",
    label: "Contact Phone 1",
    name: "contact_phone1",
    value: "",
    info: "The primary phone number for the main contact for the account",
    validation: "numeric",
    placeholder: "Enter",
    maxLength: 10
  },
];
