import React, { useEffect, useMemo, useState } from "react";
import { DndContext, closestCenter } from "@dnd-kit/core";
import {
  arrayMove,
  horizontalListSortingStrategy,
  SortableContext,
} from "@dnd-kit/sortable";
import { cloneDeep } from "lodash";

import { SortableItem } from "./SortableItem.jsx";
import AppLocalStorage from "../../utils/localStorage.js";
import "./styles.scss";
import FliptButton from "../v2/fliptButton/index.js";
// import { Icon } from "semantic-ui-react";

const TabBar = (props) => {
  const {
    tabs: defaultTabs,
    setActiveTab,
    localStorageKey,
    draghandle,
  } = props;

  // changing saved tabs based on the change in tabs
  const savedTabs = useMemo(() => {
    let savedTabs = [...defaultTabs];
    if (localStorageKey) {
      let storageTabs = AppLocalStorage.getItem(localStorageKey);
      try {
        storageTabs = JSON.parse(storageTabs);
      } catch (err) {
        console.log(`Error in parsing PA tabs: ${err}`);
        storageTabs = null;
      }
      if (
        !storageTabs ||
        !Array.isArray(storageTabs) ||
        storageTabs.length != defaultTabs.length ||
        !storageTabs[0]?.id
      ) {
        AppLocalStorage.setItem(localStorageKey, JSON.stringify(defaultTabs));
      } else {
        savedTabs = [...storageTabs];
      }
    }
    return savedTabs
  }, [JSON.stringify(defaultTabs)])

  const [activeId, setActiveId] = useState(savedTabs[0]?.id);
  const [tabs, setTabs] = useState(cloneDeep(savedTabs));

  // set first saved tab as active tab in parent component
  useEffect(() => {
    setActiveTab(savedTabs[0].id, tabs);
    setTabs(cloneDeep(savedTabs)) // when the saved tabs are changed the tabs need to be reset
  }, [JSON.stringify(savedTabs)]);

  // only display 5 tabs
  const [visibleTabIndexStart, setVisibleTabIndexStart] = useState(0);
  const [visibleTabIndexEnd, setVisibleTabIndexEnd] = useState(6);

  const handleTabClick = (id) => {
    setActiveId(id);
    setActiveTab(id, tabs);
  };

  // if active id is moving out (left or right) of visible tabs,
  // set adjecent tab as active
  useEffect(() => {
    const activeIdIndex = tabs.findIndex((tab) => tab.id === activeId);
    if (activeIdIndex < visibleTabIndexStart) {
      const nextActiveId = tabs[visibleTabIndexStart]?.id;
      handleTabClick(nextActiveId);
    }
    if (activeIdIndex > visibleTabIndexEnd) {
      const nextActiveId = tabs[visibleTabIndexEnd].id;
      handleTabClick(nextActiveId);
    }
  }, [visibleTabIndexStart, visibleTabIndexEnd, JSON.stringify(savedTabs)]);

  const handlePreviousTabClick = () => {
    setVisibleTabIndexStart(visibleTabIndexStart - 1);
    setVisibleTabIndexEnd(visibleTabIndexEnd - 1);
  };

  const handleNextTabClick = () => {
    setVisibleTabIndexStart(visibleTabIndexStart + 1);
    setVisibleTabIndexEnd(visibleTabIndexEnd + 1);
  };

  return (
    <div id="flipt-tab-bar">
      <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <div className="container">
          <FliptButton
            disabled={visibleTabIndexStart === 0}
            onClick={handlePreviousTabClick}
            className='left-control'
            icon={<img src='/i/chevron-left.svg'></img>}
          />
          <SortableContext
            items={tabs}
            strategy={horizontalListSortingStrategy}
          >
            {/* We need components that use the useSortable hook */}
            {tabs.map((tab, index) => {
              return (
                index >= visibleTabIndexStart &&
                index <= visibleTabIndexEnd && (
                  <SortableItem
                    key={index}
                    id={tab.id}
                    tab={tab}
                    tabs={tabs}
                    isActive={activeId === tab.id}
                    onTabClick={handleTabClick}
                    draghandle={draghandle}
                  />
                )
              );
            })}
          </SortableContext>
          <FliptButton
            disabled={visibleTabIndexEnd === (savedTabs.length - 1)}
            onClick={handleNextTabClick}
            className='right-control'
            icon={<img src='/i/chevron-right.svg'></img>}
          />
        </div>
      </DndContext>
    </div>
  );

  function handleDragEnd(event) {
    const { active, over } = event;

    if (active.id !== over.id) {
      setTabs((tabs) => {
        const activeIndex = tabs.findIndex((tab) => tab.id === active.id);
        const overIndex = tabs.findIndex((tab) => tab.id === over.id);
        const rearrangedTabs = arrayMove(tabs, activeIndex, overIndex);
        if (localStorageKey) {
          AppLocalStorage.setItem(
            localStorageKey,
            JSON.stringify(rearrangedTabs)
          );
        }
        return rearrangedTabs;
      });
    } else {
      if (!draghandle) {
        handleTabClick(active.id, tabs);
      }
    }
  }
};

export default TabBar;
