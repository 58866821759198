const otcDevice = [
  {
  key: 1,
  text: 'DIABETIC SUPPLIES',
  value: 'DIABETIC SUPPLIES',
},
{
  key: 2,
  text: 'DIAGNOSTIC TESTS',
  value: 'DIAGNOSTIC TESTS',
},
{
  key: 3,
  text: 'MISC. DEVICES',
  value: 'MISC. DEVICES',
}
]
export const AppWebFields =[
  {
    "header": "",
    "type": "radio",
    "label": "Allow All Form",
    "name": "allow_all_form",
    "value": "",
    "info": "When switching from Brand to Generic, restrict the generic form based on the Brand or allow all forms",
    "validation": "",
    "placeholder": "Enter",
    "subgroup": "Display Settings",
    "order": 1
  },
  {
    "header": "",
    "type": "radio",
    "label": "Allow Feedback",
    "name": "feedback_rx",
    "value": "",
    "info": "If the application should allow feedback",
    "validation": "",
    "placeholder": "Enter",
    "subgroup": "Misc Settings",
    "order": 50
  },
  {
    "header": "",
    "type": "number",
    "label": "Alternate Drug Eligibility Threshold",
    "name": "alt_drug_eligibility_threshold",
    "value": "",
    "info": "A threshold for the amount that the app will display for savings",
    "validation": "",
    "placeholder": "Enter",
    "subgroup": "Misc Settings",
    "order": 4
  },
  {
    "header": "",
    "type": "date",
    "label": "Alternate Effective Date",
    "name": "alternate_effective_date",
    "value": "",
    "info": "For alternate reward eligibility, the date since that patient has dispensed that drug",
    "validation": "",
    "placeholder": "Enter",
    "subgroup": "Misc Settings",
    "order": 3
  },
  {
    "header": "",
    "type": "text",
    "label": "App Wide Message",
    "name": "app_wide_message",
    "value": "",
    "info": "banner message verbiage",
    "validation": "",
    "placeholder": "Enter",
    "subgroup": "Display Settings",
    "order": 19
  },
  {
    "header": "",
    "type": "radio",
    "label": "App Wide Message Display ",
    "name": "app_wide_message_display",
    "value": "",
    "info": "turn on or off banner message",
    "validation": "",
    "placeholder": "Enter",
    "subgroup": "Display Settings",
    "order": 18
  },
  {
    "header": "",
    "type": "radio",
    "label": "Conceirge Phone Number",
    "name": "contact_no_flipt_concierge",
    "value": "",
    "info": "o\tY or N which indicates if the custom walkthrough screens should be pulled or default",
    "validation": "",
    "placeholder": "Enter",
    "subgroup": "Display Settings",
    "order": 30
  },
  {
    "header": "",
    "type": "radio",
    "label": "Custom Walkthrough Screens ",
    "name": "custom_walkthrough",
    "value": "",
    "info": "o\tY or N which indicates if the custom walkthrough screens should be pulled or default",
    "validation": "",
    "placeholder": "Enter",
    "subgroup": "Display Settings",
    "order": 20
  },
  {
    "header": "",
    "type": "text",
    "label": "Customer Service App Email Address",
    "name": "concierage_app_notification_email",
    "value": "",
    "info": "the email address used from the app to contact Customer Service",
    "validation": "",
    "placeholder": "Enter",
    "subgroup": "Display Settings",
    "order": 32
  },
  {
    "header": "",
    "type": "text",
    "label": "Customer Service Email Address",
    "name": "email",
    "value": "",
    "info": "Customer Service email address in Application and Web ",
    "validation": "email_address",
    "placeholder": "Enter",
    "subgroup": "Display Settings",
    "order": 32
  },
  {
    "header": "",
    "type": "text",
    "label": "Customer Service Phone Number",
    "name": "phone",
    "value": "",
    "info": "Customer Service phone number to be displayed in the app for the members",
    "validation": "numeric",
    "placeholder": "Enter",
    "subgroup": "Display Settings",
    "order": 31,
    maxLength:10
  },
  {
    "header": "",
    "type": "text",
    "label": "Deductible Receipt Message",
    "name": "deductible_receipt_message",
    "value": "",
    "info": "Default message when prescription amount can be applied towards deductible.",
    "validation": "",
    "placeholder": "Enter",
    "subgroup": "Communication",
    "order": 25
  },
  {
    "header": "",
    "type": "radio",
    "label": "Device Activation Flag",
    "name": "device_activation_flag",
    "value": "",
    "info": "Two factor authentication for activating the device. This is currently not in use",
    "validation": "",
    "placeholder": "Enter",
    "subgroup": "Communication",
    "order": 40
  },
  {
    "header": "",
    "type": "text",
    "label": "Display BIN No",
    "name": "flipt_bin",
    "value": "",
    "info": "Bin number for Flipt for Digital card",
    "validation": "",
    "placeholder": "Enter",
    "subgroup": "Display Settings",
    "order": 44
  },
  {
    "header": "",
    "type": "radio",
    "label": "Display BIN/PCN Flag",
    "name": "display_flipt_bin_pcn_flag",
    "value": "",
    "info": "If Flipt Bin/PCN should be displayed ",
    "validation": "",
    "placeholder": "Enter",
    "subgroup": "Display Settings",
    "order": 43
  },
  {
    "header": "",
    "type": "radio",
    "label": "Display Deductible Receipt Message",
    "name": "display_deductible_receipt_message",
    "value": "",
    "info": "If the deductible receipt message should be displayed",
    "validation": "",
    "placeholder": "Enter",
    "subgroup": "Display Settings",
    "order": 21
  },
  {
    "header": "",
    "type": "radio",
    "label": "Display Dependent Activate Account",
    "name": "display_dependent_activate_account",
    "value": "",
    "info": "Allow the primary member to invite dependents for activation",
    "validation": "",
    "placeholder": "Enter",
    "subgroup": "Display Settings",
    "order": 27
  },
  {
    "header": "",
    "type": "radio",
    "label": "Display Family Deductible",
    "name": "display_family_deductible",
    "value": "",
    "info": "Applicable only when plan does not have deductible limit and does not require to display the family deductible in the app",
    "validation": "",
    "placeholder": "Enter",
    "subgroup": "Display Settings",
    "order": 24
  },
  {
    "header": "",
    "type": "text",
    "label": "Display Group Id",
    "name": "flipt_group_id",
    "value": "",
    "info": "Group name for the client  for Digital card",
    "validation": "",
    "placeholder": "Enter",
    "subgroup": "Display Settings",
    "order": 42
  },
  {
    "header": "",
    "type": "radio",
    "label": "Display Logo",
    "name": "display_logo",
    "value": "",
    "info": "co-branding for company logo to be displayed in the app",
    "validation": "",
    "placeholder": "Enter",
    "subgroup": "Display Settings",
    "order": 22
  },
  {
    "header": "",
    "type": "text",
    "label": "Display Mail Order Intent Days",
    "name": "mo_dayssupply",
    "value": "",
    "info": "for how many days , mail order intent option should be displayed: in days",
    "validation": "numeric",
    "placeholder": "Enter",
    "subgroup": "Prescription Related Settings",
    "order": 11
  },
  {
    "header": "",
    "type": "text",
    "label": "Display PCB No",
    "name": "flipt_pcn",
    "value": "",
    "info": "PCN for Flipt for Digital card",
    "validation": "",
    "placeholder": "Enter",
    "subgroup": "Display Settings",
    "order": 45
  },
  {
    "header": "",
    "type": "radio",
    "label": "Display Uid",
    "name": "display_uid",
    "value": "",
    "info": "Unique ID from TPA to be displayed in the app if required by the pln sponsor",
    "validation": "",
    "placeholder": "Enter",
    "subgroup": "Display Settings",
    "order": 23
  },
  {
    "header": "",
    "type": "radio",
    "label": "Enable Compulsory Phone Verification",
    "name": "enable_compulsory_phone_verification",
    "value": "",
    "info": "If the phone verification for the members is mandatory when the application is used for the first time. ",
    "validation": "",
    "placeholder": "Enter",
    "subgroup": "Communication",
    "order": 41
  },
  {
    "header": "",
    "type": "radio",
    "label": "Enable Drug Interaction",
    "name": "enable_drug_interaction",
    "value": "",
    "info": "Drug Interaction to be displayed in the app",
    "validation": "",
    "placeholder": "Enter",
    "subgroup": "Misc Settings",
    "order": 5
  },
  {
    "header": "",
    "type": "radio",
    "label": "Enable Expiry Notification",
    "name": "enable_expiry_notification",
    "value": "",
    "info": "If the prescription expiry notification should be sent to the members",
    "validation": "",
    "placeholder": "Enter",
    "subgroup": "Communication",
    "order": 8
  },
  {
    "header": "",
    "type": "radio",
    "label": "Enable Quantity Resctriction",
    "name": "enable_quantity_resctriction",
    "value": "",
    "info": "If there should be quantity restrictions",
    "validation": "",
    "placeholder": "Enter",
    "subgroup": "Misc Settings",
    "order": 13
  },
  {
    "header": "",
    "type": "radio",
    "label": "Enable Smart Search",
    "name": "enable_smart_search",
    "value": "",
    "info": "If the smart search should be enabled",
    "validation": "",
    "placeholder": "Enter",
    "subgroup": "Misc Settings",
    "order": 16
  },
  {
    "header": "",
    "type": "text",
    "label": "Seach Spell %",
    "name": "search_spell_pct",
    "value": "",
    "info": "Search functionality in App",
    "validation": "",
    "placeholder": "Enter",
    "subgroup": "Misc Settings",
    "order": 15
  },
  {
    "header": "",
    "type": "text",
    "label": "Explain Flipt Pharmacy",
    "name": "explain_flipt_pharmacy",
    "value": "",
    "info": "If the \"Explain Flipt\" should be visible in the application.",
    "validation": "",
    "placeholder": "Enter",
    "subgroup": "Display Settings",
    "order": 48
  },
  {
    "header": "",
    "type": "text",
    "label": "Link to 'How to' Videos",
    "name": "how_to_video_link",
    "value": "",
    "info": "link to display 'How to Video' ",
    "validation": "",
    "placeholder": "Enter",
    "subgroup": "Display Settings",
    "order": 34
  },
  {
    "header": "",
    "type": "text",
    "label": "Link to FAQ",
    "name": "faq_link",
    "value": "",
    "info": "Link for 'Frequenty Asked Questions'",
    "validation": "",
    "placeholder": "Enter",
    "subgroup": "Display Settings",
    "order": 35
  },
  {
    "header": "",
    "type": "text",
    "label": "Mail Order Expiration Days",
    "name": "rx_mail_expiry_days",
    "value": "",
    "info": "#of day in which mail order prescriptions will expire. ",
    "validation": "numeric",
    "placeholder": "Enter",
    "subgroup": "Prescription Related Settings",
    "order": 9
  },
  {
    "header": "",
    "type": "text",
    "label": "Min Savings to Invoke Split Pharmacy ",
    "name": "min_split_pharmacy_savings",
    "value": "",
    "info": "Minimum savings amount required for the system to split multiple intent to multiple pharmacies",
    "validation": "",
    "placeholder": "Enter",
    "subgroup": "Misc Settings",
    "order": 47
  },
  {
    "header": "",
    "type": "multi-dropdown",
    "label": "OTC Device Drugclass Search",
    "name": "otc_device_drugclass_search",
    "value": "",
    "info": "OTC device drugclass search",
    "validation": "",
    "placeholder": "Enter",
    "subgroup": "Misc Settings",
    "order": 17,
    "optionList": otcDevice || [],
  },
  {
    "header": "",
    "type": "text",
    "label": "PA Expiry Days",
    "name": "pa_expiry_limit",
    "value": "",
    "info": "Intent expiration days for drugs that require PA",
    "validation": "",
    "placeholder": "Enter",
    "subgroup": "Prescription Related Settings",
    "order": 16
  },
  {
    "header": "",
    "type": "number",
    "label": "Prescription Expiration Days",
    "name": "rx_expiry_days",
    "value": "",
    "info": "#of day in which unpicked prescriptions will expire in the app.",
    "validation": "numeric",
    "placeholder": "Enter",
    "subgroup": "Prescription Related Settings",
    "order": 6
  },
  {
    "header": "",
    "type": "number",
    "label": "Prescription Expiration Notification Days",
    "name": "rx_expiry_notification_days",
    "value": "",
    "info": "Number of days before the notification will be sent before the prescription expires.",
    "validation": "",
    "placeholder": "Enter",
    "subgroup": "Prescription Related Settings",
    "order": 7
  },
  {
    "header": "",
    "type": "number",
    "label": "Quantity Restriction Buffer",
    "name": "quantity_restriction_buffer",
    "value": "",
    "info": "No of Days buffer that is provided before the intent can be created for the same member and medication combination in the app",
    "validation": "numeric",
    "placeholder": "Enter",
    "subgroup": "Prescription Related Settings",
    "order": 12
  },
  {
    "header": "",
    "type": "text",
    "label": "Search Match %",
    "name": "search_match_pct",
    "value": "",
    "info": "Search functionality in App",
    "validation": "",
    "placeholder": "Enter",
    "subgroup": "Misc Settings",
    "order": 14
  },
  {
    "header": "",
    "type": "radio",
    "label": "Show 'How to' Video",
    "name": "show_howto_video",
    "value": "",
    "info": "If 'How to Video' should be displayed in the app/web",
    "validation": "",
    "placeholder": "Enter",
    "subgroup": "Display Settings",
    "order": 33
  },
  {
    "header": "",
    "type": "radio",
    "label": "Show Deductible Breakdown Web",
    "name": "show_deductible_breakdown_web",
    "value": "",
    "info": "Display deductible breakdown on the web",
    "validation": "",
    "placeholder": "Enter",
    "subgroup": "Display Settings",
    "order": 26
  },
  {
    "header": "",
    "type": "radio",
    "label": "Show Digital Card",
    "name": "show_digital_card",
    "value": "",
    "info": "If the digital card should be displayed in the app/web",
    "validation": "",
    "placeholder": "Enter",
    "subgroup": "Display Settings",
    "order": 28
  },
  {
    "header": "",
    "type": "radio",
    "label": "Show Explain Flipt Pharmacy",
    "name": "show_explain_flipt_pharmacy",
    "value": "",
    "info": "If 'Explain Flipt' should be displayed in the app/web",
    "validation": "",
    "placeholder": "Enter",
    "subgroup": "Display Settings",
    "order": 46
  },
  {
    "header": "",
    "type": "radio",
    "label": "Show User Guide",
    "name": "show_user_guide",
    "value": "",
    "info": "If 'User guide ' should be displayed in the app/web",
    "validation": "",
    "placeholder": "Enter",
    "subgroup": "Display Settings",
    "order": 36
  },
  {
    "header": "",
    "type": "number",
    "label": "Switch Pharmacy Radius",
    "name": "switch_pharmacy_radius",
    "value": "",
    "info": "Radius in miles for the system to check the available pharmacies for savings",
    "validation": "",
    "placeholder": "Enter",
    "subgroup": "Misc Settings",
    "order": 49
  },
  {
    "header": "",
    "type": "number",
    "label": "Track Mail Order Days",
    "name": "track_mail_order_days",
    "value": "",
    "info": "No of days the Humana tracking number should be displayed after the intent has been filled",
    "validation": "",
    "placeholder": "Enter",
    "subgroup": "Prescription Related Settings",
    "order": 10
  }
]