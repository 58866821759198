import React from 'react'
import { Link } from 'react-router-dom'

export default (props) => {
  const { active } = props
  const search = ''

  return (
    <div className='submenu'>
      <div className={active === 'pharmacyNetwork' ? 'tab active' : 'tab'}>
      <Link
        to={{
          pathname: '/pharmacy-network',
          search,
        }}
        //className={active === 'pharmacyNetwork' ? 'tab active' : 'tab'}
      >
        <div className='tab_inner_container'>
         {/* <img src='/i/Drag.svg'/> */}
         <p>Pharmacy Network</p>
        </div>
      </Link>
      </div>
      <div className={active === 'networkTier' ? 'tab active' : 'tab'}>
      <Link
        to={{
          pathname: '/network-tier',
          search,
        }}
       // className={active === 'networkTier' ? 'tab active' : 'tab'}
      >
        <div className='tab_inner_container'>
         {/* <DragIcon/> */}
         {/* <img src='/i/Drag.svg'/> */}
         <p>Network Tier</p>
        </div>
      </Link>
      </div>
      <div className={active === 'networkEdits' ? 'tab active' : 'tab'}>
      <Link
        to={{
          pathname: '/network-edit',
          search,
        }}
        //className={active === 'networkEdits' ? 'active' : ''}
      >
        <div className='tab_inner_container'>
         {/* <DragIcon/> */}
         {/* <img src='/i/Drag.svg'/> */}
         <p>Network Edits</p>
        </div>
      </Link>
    </div>
  </div>
  )
}
