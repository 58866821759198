import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import './styles.scss'

import { filterRowData } from '../../../utils/utilities'
import { Creators as PrescriberManagementActions } from '../../../redux/reducers/api/prescriberManagement'
import { Creators as AppActions } from '../../../redux/reducers/app'

import FliptGrid from '../../fliptGrid'
import FliptButton from '../fliptButton'
import FliptInput from '../fliptInput'

// this component is integrated with new prescriber service.
// if you need to use old prescriber lookup use src/components/v2/fliptPrescriberSearch 
class FliptPrescriberSearch extends Component {
	constructor(props) {
		super(props)
		this.state = {
			searchForm: {
				npi: '',
				first_name: '',
				last_name: '',
			},
			editForm: {
				secure_fax_number: '',
				npi: '',
				first_name: '',
				last_name: '',
				address1: '',
				address2: '',
				phone_number: '',
				city: '',
				state: '',
				zip: '',
			},
			prescriberList: [],
			prescriberData: {},
			prescriberSearched: false,
			editPrescriber: false,
		}
	}

	componentDidMount() {
		const { searchForm } = this.props
		if (searchForm.npi) {
			this.setState({
				searchForm: {
					first_name: '',
					last_name: '',
					npi: searchForm.npi,
				},
			})
		}
	}

	componentWillUnmount() {
		const { actions } = this.props
		this._clearPrescriberFields()
		actions.clearPrescriberDataReducer()
	}

	componentDidUpdate(prevProps) {
		const { props } = this
		const { prescriberSearchData } = props.state
		if (prevProps.state.prescriberSearchData !== prescriberSearchData) {
			if (prescriberSearchData) {
				this.setState({
					prescriberList: prescriberSearchData,
				})
				return
			}
			this.setState({ prescriberList: prescriberSearchData })
		}
	}

	_onPrescriberSelectionChanged = (data) => {
		const selectedRows = data.api.getSelectedRows()
		this.setState({ prescriberData: selectedRows[0] })
	}

	_clearPrescriberFields = () => {
		this.setState({
			searchForm: {
				npi: '',
				first_name: '',
				last_name: '',
			},
			prescriberList: [],
		})
	}

	_submitResult = () => {
		let { prescriberData } = this.state
		prescriberData = {
			...prescriberData,
			zip_code: prescriberData.zip,
		}
		const { actions, submitPrescriberData } = this.props
		if (!prescriberData) {
			return
		}
		actions.toggleModal()
		submitPrescriberData(prescriberData)
	}

	_editPrescriber = () => {
		const { prescriberData } = this.state
		const { actions } = this.props
		if (!prescriberData) {
			return
		}
		this.setState({
			editPrescriber: true,
			editForm: {
				secure_fax_number: prescriberData.secure_fax_number,
				npi: prescriberData.npi,
				first_name: prescriberData.first_name,
				last_name: prescriberData.last_name,
				address1: prescriberData.address1,
				address2: prescriberData.address2,
				city: prescriberData.city,
				state: prescriberData.state,
				zip: prescriberData.zip,
				phone_number: prescriberData.phone_number
			},
		})
	}

	closePrescriberEdit = () => {
		this.setState({
			editPrescriber: false,
		})
	}

	saveEditPrescriber = () => {
		const { editForm, searchForm } = this.state
		const { actions } = this.props
		const request_body = {
			npi: editForm.npi,
			address: {
				address1: editForm.address1,
				address2: editForm.address2,
				city: editForm.city,
				secure_fax_number: editForm.secure_fax_number,
				state: editForm.state,
				zip: editForm.zip,
				phone_number: editForm.phone_number
			},
		}
		let search_params = {}
		if (searchForm.npi) {
			search_params.npi = searchForm.npi
			search_params.search_by = 'npi'
		}
		if (searchForm.first_name && searchForm.last_name) {
			search_params.first_name = searchForm.first_name
			search_params.last_name = searchForm.last_name
			search_params.search_by = 'name'
		}
		actions.editPrescriber({ request_body, search_params, callback: this.closePrescriberEdit })
	}

	_searchData = () => {
		const { actions } = this.props
		const {
			state: { searchForm },
		} = this
		if (!searchForm.npi && !searchForm.first_name && !searchForm.last_name) {
			const transitionalPortal = {
				header: 'Fields are missing',
				copy: 'Please Add Details to search Prescriber detail',
			}
			actions.displayTransitionalPortal(transitionalPortal)
			return
		}
		if (searchForm.npi && searchForm.npi.length !== 10) {
			const transitionalPortal = {
				header: 'Field Error',
				copy: 'Prescriber NPI should be of 10 Digits',
			}
			actions.displayTransitionalPortal(transitionalPortal)
			return
		}
		this.setState({
			prescriberList: [],
			prescriberData: {},
		})

		let params = {}
		if (searchForm.npi) {
			params.npi = searchForm.npi
			params.search_by = 'npi'
		}
		if (searchForm.first_name && searchForm.last_name) {
			params.first_name = searchForm.first_name
			params.last_name = searchForm.last_name
			params.search_by = 'name'
		}

		actions.setPrescriberSearchFailed(false)
		actions.searchPrescriber(params)
		this.setState({
			prescriberSearched: true,
		})
	}

	_updateSearchForm = (el, dropdown) => {
		const { searchForm } = this.state
		const { name, value } = dropdown || el.currentTarget
		searchForm[name] = value
		this.setState({
			searchForm,
			prescriberList: [],
			prescriberData: {},
			editPrescriber: false,
		})
	}

	_updateEditForm = (el, dropdown) => {
		const { editForm } = this.state
		const { name, value } = dropdown || el.currentTarget
		editForm[name] = value
		this.setState({
			editForm,
		})
	}

	_showTransitionalPortal = (header, message) => {
		const { props } = this
		const transitionalPortal = {
			header: header,
			copy: message,
		}
		props.actions.displayTransitionalPortal(transitionalPortal)
	}

	render() {
		const { editMode } = this.props
		const { searchForm, prescriberList, prescriberData, editPrescriber, editForm, prescriberSearched } = this.state
		const headers = [
			'Select',
			'npi',
			'first_name',
			'last_name',
			'secure_fax_number',
			'phone_number',
			'address1',
			'address2',
			'city',
			'state',
			'zip',
		]
		const gridData =
			prescriberList && prescriberList.length
				? prescriberList?.map((d) => {
					const obj = {
						...filterRowData(d, headers),
						address1: d.address.address1,
						address2: d.address.address2,
						state: d.address.state,
						city: d.address.city,
						zip: d.address.zip,
						secure_fax_number: d.address.secure_fax_number,
						phone_number: d.address.phone_number
					}
					return obj
				})
				: []
		const cellRendererParams = {
			Select: {
				checkboxSelection: true,
				width: '100px',
			},
		}
		return (
			<div className="prescriber-section">
				{!editPrescriber && (
					<div className="prescriber-inputs">
						<div className="fields-container">
							<FliptInput
								validation="numeric"
								value={searchForm.npi}
								stylized
								name="npi"
								onChange={this._updateSearchForm}
								placeholder="Enter"
								label="NPI"
								description="NPI"
							/>
							<FliptInput
								value={searchForm.first_name}
								stylized
								name="first_name"
								onChange={this._updateSearchForm}
								placeholder="Enter"
								label="Prescriber First Name"
							/>
							<FliptInput
								value={searchForm.last_name}
								stylized
								name="last_name"
								onChange={this._updateSearchForm}
								placeholder="Enter"
								label="Prescriber Last Name"
							/>
						</div>
						<div className="prescriber-buttons-container">
							<FliptButton className="primary" name="Search" onClick={() => this._searchData()} />
							<FliptButton name="Clear" className="secondary" onClick={() => this._clearPrescriberFields()} />
						</div>
					</div>
				)}
				{editPrescriber && (
					<div className="edit-prescriber">
						<span className="edit-prescriber-header">Edit Prescriber</span>
						<div className="edit-outer-container">
							<div className="edit-inner-container">
								<FliptInput
									disabled={true}
									value={editForm.npi}
									stylized
									name="npi"
									validation="numeric"
									onChange={this._updateEditForm}
									label="NPI"
								/>
								<FliptInput
									disabled={true}
									value={editForm.first_name}
									stylized
									name="first_name"
									onChange={this._updateEditForm}
									label="Prescriber First Name"
								/>
								<FliptInput
									disabled={true}
									value={editForm.last_name}
									stylized
									name="last_name"
									onChange={this._updateEditForm}
									label="Prescriber Last Name"
								/>
							</div>
							<div className="edit-inner-container">
								<FliptInput
									disabled={true}
									value={editForm.address1}
									name="address"
									onChange={this._updateEditForm}
									stylized
									label="Address"
								/>
								<FliptInput
									disabled={true}
									value={editForm.city}
									stylized
									name="city"
									onChange={this._updateEditForm}
									label="City"
								/>
								<FliptInput
									search
									label="State"
									name="state"
									value={editForm.state}
									disabled={true}
									stylized
									onChange={this._updateEditForm}
								/>
							</div>
							<div className="edit-inner-container">
								<FliptInput
									disabled={true}
									value={editForm.zip}
									onChange={this._updateEditForm}
									name="zip"
									stylized
									label="Zip Code"
								/>
								<FliptInput
									value={editForm.secure_fax_number}
									validation="numeric"
									name="secure_fax_number"
									onChange={this._updateEditForm}
									stylized
									label="Secure Fax Number"
								/>
								<FliptInput
									disabled={true}
									value={editForm.phone_number}
									validation="numeric"
									stylized
									name="phone_number"
									onChange={this._updateEditForm}
									label="Phone Number"
								/>
							</div>
						</div>
						{
							<div className="prescriber-buttons-container">
								<FliptButton className="primary" name="SAVE" onClick={() => this.saveEditPrescriber()} />
								<FliptButton
									name="Cancel"
									className="secondary"
									onClick={this.closePrescriberEdit}
								/>
							</div>
						}
					</div>
				)}
				{prescriberList.length ? (
					<>
						<div className="prescriber-header">Search Results</div>
						<div className="grid-container">
							<FliptGrid
								headers={headers}
								data={gridData}
								rowSelection="single"
								onSelectionChanged={(data) => this._onPrescriberSelectionChanged(data)}
								cellRendererParams={cellRendererParams}
							/>
						</div>
					</>
				) : (
					prescriberSearched && (
						<div className="prescriber-not-found-container">
							<span className="prescriber-not-found-header">Prescriber Not Found</span>
							<span className="prescriber-not-found-text">
								There is no prescriber with the search query. Please search again or add prescriber.
							</span>
						</div>
					)
				)}
				{prescriberList.length ? (
					<div className="prescriber-buttons-container">
						<FliptButton
							disabled={!prescriberData}
							className="primary"
							name="Edit"
							onClick={() => this._editPrescriber()}
						/>
						<FliptButton
							disabled={!prescriberData}
							className="primary"
							name="Submit"
							onClick={() => this._submitResult()}
						/>
					</div>
				) : (
					''
				)}
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	state: {
		prescriberSearchData: state.prescriberManagement.prescriberSearchData,
		user: state.user,
	},
})

const mapDispatchToProps = (dispatch) => {
	const allActions = {
		...PrescriberManagementActions,
		...AppActions,
	}
	return {
		actions: bindActionCreators(allActions, dispatch),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(FliptPrescriberSearch)
