import React from 'react'
import { Link } from 'react-router-dom'
import './styles.scss'

export default ({ menu, toggleCard, activeCard }) => {
  const menuId = menu.id
  const menuIdContainer = `${menuId}-container`
  const imageSrc = "/i/arrow-up-right.svg";
  const menuClass = !!activeCard ? 'menu-card-item active' : 'menu-card-item';
  const MenuItem = ({ name, imgSrc, id, path }) => (
    <div>
      <div>
        <Link to={path} className="menu-item-row">
          <div className="sub-menu-item-name">{name}</div>
          <div className="arrow-container">
            <img
              loading="lazy"
              src={imgSrc}
              className="arrow-icon"
              id={id}
            />
          </div>
        </Link>
      </div>
    </div>
  );
  return (
    <div className="menu-card">
      <div className={menuClass} id={menuIdContainer} onClick={() => toggleCard(menuId)}>
        <div className='icon-container-outer'>
          <div className='icon-container-inner'>
            {/* <Link to={menu.path} > */}
            {menu.icon && <img src={menu.icon} alt={menu.name} className="menu-icon" />}
            {/* </Link> */}
          </div>
        </div>
        {/* {/* <Link to={menu.path} > */}
        <div className="title svg">{menu.name}</div>
        <div className="description">{menu.description}</div>
        {/* </Link> */}

        {menu?.children && (

          <div className="menu-item-wrapper" id={menuId}>
            {menu?.children?.map((m, index) => (
              <MenuItem
                key={m.id}
                name={m.name}
                imgSrc={imageSrc}
                id={`img-${index + 1}`}
                path={m.path}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  )
}
