import {
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects'

import { Creators as AppActions } from '../../reducers/app'
import { Creators as SSOLoginActions, Types as SSOLoginTypes } from '../../reducers/ssoLogin'
import { Creators as UserActions, Types as UserTypes } from '../../reducers/user'
import { Creators as NavigationActions } from '../../reducers/navigation'
import { fetchGet, fetchPut, fetchPatch, fetchPost } from '../../../utils/fetchApi'
import { getAppState } from '../../reducers/selectors'
import { createAuth0Client } from '@auth0/auth0-spa-js'

export default [
  auth0LoginWatcher,
  auth0ConfigureClientWatcher,
]

/* WATCHERS */
function* auth0LoginWatcher() {
  yield takeLatest(SSOLoginTypes.AUTH0_LOGIN, auth0LoginHandler)
}
function* auth0ConfigureClientWatcher() {
  yield takeLatest(SSOLoginTypes.AUTH0_CONFIGURE_CLIENT, auth0ConfigureClientHandler)
}

/* HANDLERS */
function* auth0LoginHandler({ payload: auth0 }) {
  try {
    const options = {
      authorizationParams: {
        redirect_uri: window.location.origin
      }
    }
    console.log('Activating Auth0 Login with Popup')
    yield auth0.loginWithPopup(options)
    const user = yield auth0.getUser()
    console.log('Auth0 Login Success, Logging into FAST')
    yield put(UserActions.userSSOLogin(user))
  } catch (err) {
    console.log("Log in failed", err);
  }
}

function* auth0ConfigureClientHandler() {
	const appState = yield select(getAppState)
	const response = yield fetchPost(`${appState.apiUrl}/fast/welcome`)

	const config = response

  try {
    const auth0Client = yield createAuth0Client({
      domain: config.domain,
      clientId: config.clientId,
    })
    yield put(SSOLoginActions.setAuth0Client(auth0Client))
  } catch (err) {
    console.log(err)
  }
}
