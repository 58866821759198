import {
    call,
    put,
    select,
    takeLatest,
} from 'redux-saga/effects'

import { Types, Creators as EmergencyOverrideActions } from '../../reducers/api/emergencyOverride'
import { getAppState, getApiPath, getEmergencyOverride } from '../../reducers/selectors'
import { fetchGet, fetchPost, fetchPut } from '../../../utils/fetchApi'

export default [
    getEmergencyOverrideDashboardWatcher,
    getEmergencyOverrideConfigurationWatcher,
    saveEmergencyConfigurationWatcher,
    testEmergencyValidationWatcher,
    validateThenSaveAndExitWatcher,
]

/* WATCHERS */
function* getEmergencyOverrideDashboardWatcher() {
    yield takeLatest(Types.GET_EMERGENCY_OVERRIDE_DASHBOARD, getEmergencyOverrideDashboardHandler)
}
function* getEmergencyOverrideConfigurationWatcher() {
    yield takeLatest(Types.GET_EMERGENCY_OVERRIDE_CONFIGURATION, getEmergencyOverrideConfigurationHandler)
}
function* saveEmergencyConfigurationWatcher() {
    yield takeLatest(Types.SAVE_EMERGENCY_CONFIGURATION, saveEmergencyConfigurationHandler)
}
function* testEmergencyValidationWatcher() {
    yield takeLatest(Types.TEST_EMERGENCY_VALIDATION, testEmergencyValidationHandler)
}

function* validateThenSaveAndExitWatcher() {
    yield takeLatest(Types.VALIDATE_THEN_SAVE_AND_EXIT, validateThenSaveAndExitHandler)
}

/* HANDLERS */
function* getEmergencyOverrideDashboardHandler() {
    try {
        const url = yield call(getServiceUrl, 'emergency-override-configuration-dashboard');
        const urlWithParams = `${url}?configuration_type=emergency`
        const showLoader = true;
        const response = yield call(fetchGet, urlWithParams, showLoader)
        if (response.status !== "success") {
            throw new Error('Failed to get Emergency Override Dashboard')
        }
        yield put(EmergencyOverrideActions.setEmergencyOverrideDashboard(response.dashboard))
    } catch (err) {
        yield put(EmergencyOverrideActions.returnToDashboard())
    }
}

function* getEmergencyOverrideConfigurationHandler({ payload }) {
    try {
        const { configuration_id } = payload
        const url = yield call(getServiceUrl, 'emergency-override-configuration');
        const urlWithParams = `${url}?configuration_id=${configuration_id}`
        const showLoader = true;
        const response = yield call(fetchGet, urlWithParams, showLoader)
        if (response.status !== "success") {
            throw new Error('Failed to get Emergency Configuration')
        }
        yield put(EmergencyOverrideActions.setEmergencyOverride(response.config))
    } catch (err) {
        yield put(EmergencyOverrideActions.returnToDashboard())
    }
}

function* validateThenSaveAndExitHandler() {
    try {
        const hasError = yield call(testEmergencyValidationHandler, { payload: { goTo: null } })
        if (hasError) return;
        yield put(EmergencyOverrideActions.saveEmergencyConfiguration())
    } catch (err) {
        console.log('validateAndExit', err)
    }
}

function* saveEmergencyConfigurationHandler() {
    try {
        const emergencyOverride = yield select(getEmergencyOverride)
        const updatedEmergencyOverride = configAdditionFields(emergencyOverride)
        const isolatedEmergencyOverride = isolateConfigurationDetails(updatedEmergencyOverride)
        const url = yield call(getServiceUrl, 'emergency-override-configuration');
        const showLoader = true;
        const body = { overrideConfiguration: isolatedEmergencyOverride };
        const response = yield call(fetchPost, url, body, showLoader)
        if (response.status !== "success") {
            throw new Error('Failed to save Emergency Configuration')
        }
    } catch (err) {
        console.log('saveEmergencyConfigurationHandler', err)
    } finally {
        yield put(EmergencyOverrideActions.returnToDashboard())
    }
}

function* testEmergencyValidationHandler({ payload }) {
    try {
        const emergencyOverride = yield select(getEmergencyOverride);
        const validations = emergencyOverride.validations || {};
        const fields = Object.keys(emergencyOverride);
        let hasErrors = false;
        const validationResults = fields.reduce((acc, field) => {
            const fieldValidation = validations[field];
            const fieldValue = emergencyOverride[field];
            acc[field] = true;
            if (!fieldValidation) return acc;
            const { type, validation, required } = fieldValidation;
            if (required || fieldValue) {
                acc[field] = validation(fieldValue, emergencyOverride)// && typeof fieldValue === type;
            }
            if (!acc[field]) {
                hasErrors = true;
            }
            return acc;
        }, {})
        validationResults.hasErrors = hasErrors;
        yield put(EmergencyOverrideActions.updateValidationResult(validationResults))
        if (!hasErrors && payload.goTo) {
            yield put(EmergencyOverrideActions.setPage(payload.goTo))
        }
        return hasErrors;
    } catch (err) {
        console.log(err)
    }
}

/* UTILS */
function* getServiceUrl(servicePath) {
    const { serviceUrl } = yield select(getAppState)
    const { api_path } = yield select(getApiPath, servicePath)
    const url = `${serviceUrl}${api_path}`
    return url;
}

function configAdditionFields(emergencyOverride) {
    // geographic_configuration
    const { state, zip_code } = emergencyOverride;
    const updatedEmergencyOverride = Object.assign({}, emergencyOverride);
    updatedEmergencyOverride.geographic_configuration = {
        state: state || [],
        zip_code,
    };

    // claims_submission_configuration
    const { origin_5_required, origin_5_missing_response, scc_13_required, scc_13_missing_response } = emergencyOverride;
    updatedEmergencyOverride.claim_submission_configuration = {
        origin_5_required,
        origin_5_missing_response,
        scc_13_required,
        scc_13_missing_response,
    };
    return updatedEmergencyOverride;
}

function isolateConfigurationDetails(emergencyOverride) {
    const fields = [
        'configuration_id',
        'configuration_type',
        'configuration_name',
        'configuration_notes',
        'effective_begin_date',
        'effective_term_date',
        'status',
        'impacts_all_hierarchies',
        'hierarchy_inclusion_rule',
        'hierarchy',
        'hierarchyIDs',
        'nationwide',
        'geographic_configuration',
        'state',
        'zip_code',
        'emergency_location_configuration',
        'patient_location_configuration',
        'claim_submission_configuration',
        'origin_5_required',
        'origin_5_missing_response',
        'scc_13_required',
        'scc_13_missing_response',
        'pharmacy_exclusions',
        'zero_dollar_copay_drugs',
        'bypass_edit_configuration',
        'clinical_pa',
        'clinical_ql',
        'clinical_step_therapy',
        'programs',
        'formulary_name',
        'formulary_id',
        'tiers',
        'um_ranking',
        'custom_cost_share',
        'copays',
        'price_source',
        'created_by',
        'created_date',
        'updated_by',
        'updated_date',
    ]
    const isolatedEmergencyOverride = {}
    for (let i = 0; i < fields.length; i++) {
        const field = fields[i]
        if (field in emergencyOverride) {
            isolatedEmergencyOverride[field] = emergencyOverride[field]
        }
    }
    return isolatedEmergencyOverride;
}
