import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Creators as AppActions } from '../../../redux/reducers/app'
import { Creators as EmergencyOverrideActions } from '../../../redux/reducers/api/emergencyOverride'

import FliptHierarchyDropdown from '../../../components/form/fliptHierarchyDropdown';
import { usStatesWithAbbreviations } from '../../../redux/reducers/api/utils/states'
import { RadioSelection, ToolTipDropDown, ToolTipInput } from '../emergencyOverrides/components';
import { Creators as GlobalEditsActions } from '../../../redux/reducers/api/globalEdits'
import FliptDropdown from '../../../components/form/fliptDropdown'
function CreateNewGlobalEdit(props) {
    const { actions, state } = props
    const { globalEdits, showErrors, validations, validationResults } = state;
    const listOption = [
    {
        key: 1,
        text: 'Default',
        value: 'Default'
    },
    {
        key: 2,
        text: 'Override',
        value: 'Override'
    }
    ]

    const { hierarchy, hierarchyIDs } = globalEdits;
    const form = { hierarchy, hierarchyIDs };
    const {
        configuration_name,
        configuration_notes,
        effective_begin_date,
        effective_term_date,
        impacts_all_hierarchies,
        hierarchy_inclusion_rule,
        nationwide,
        state: configStates,
        zip_code,  
        global_edit_type 
    } = globalEdits;

    const _updateEditFields = (el, dropdown) => {
        const { name, value } = dropdown || el.currentTarget;
        actions.updateGlobalEditsField({ name, value });
    };

    const updateZipCode = (el) => {
        const { name, value } = el.currentTarget;
        const invalidValue = /[^0-9,]/;
        if (invalidValue.test(value)) return;
        actions.updateGlobalEditsField({ name, value: value });
    }

    const setForm = (newForm) => {
        Object.entries(newForm).forEach(([key, value]) => {
            actions.updateGlobalEditsField({ name: key, value });
        })
    }

    return (
        <div className='page-container'>
            <header>
                <h1 className='page-h1'>
                   Create New Global Edit
                </h1>
            </header>
            <section className='input-container'>
                <ToolTipInput
                    label='Configuration Name'
                    tooltip='Enter a unique name for this override configuration. This will help identify and differentiate it from other override settings.'
                    placeholder='Enter a unique name'
                    name={'configuration_name'}
                    onChange={_updateEditFields}
                    type='text'
                    value={configuration_name}
                    error={showErrors &&
                        !validationResults?.configuration_name &&
                        validations.configuration_name.errorMessage}
                />
                <ToolTipInput
                    label='Configuration Notes'
                    tooltip='Add any relevant notes or details about this override. This can include reasons for the override, reference numbers, or other specifics that might aid in understanding or tracking.'
                    placeholder='Relevant override notes or details'
                    name={'configuration_notes'}
                    onChange={_updateEditFields}
                    type='text'
                    value={configuration_notes}
                />
                <ToolTipInput
                    label='Effective Date'
                    tooltip='Select the date when this override configuration will start to take effect. Ensure that this date aligns with the intended plan or coverage changes.'
                    min={new Date()}
                    name={'effective_begin_date'}
                    onChange={_updateEditFields}
                    type='date'
                    value={effective_begin_date}
                    error={showErrors &&
                        !validationResults?.effective_begin_date &&
                        validations.effective_begin_date.errorMessage}
                />
                <ToolTipInput
                    label='Term Date'
                    tooltip='Choose the date when this override configuration will no longer be valid. After this date, the settings revert to the default or previous configuration.'
                    min={effective_begin_date || new Date()}
                    name={'effective_term_date'}
                    onChange={_updateEditFields}
                    type='date'
                    value={effective_term_date}
                    error={showErrors &&
                        !validationResults?.effective_term_date &&
                        validations.effective_term_date.errorMessage}
                />
                <div className='tooltip-input-wrapper'>
                  <article className='tooltip-info-container'>
                  <span>Global Edit Type</span>
                </article>
                <FliptDropdown 
                     style={{width:'100%'}}
                     placeholder="Global Edit Type" 
                     name="global_edit_type" 
                     value={global_edit_type} 
                     options={listOption} 
                     selectOnBlur={false} 
                     scrolling clearable selection 
                     onChange={(e, dropdown) => _updateEditFields(e, dropdown)}
                  />
                </div>

            </section>
            <section>
                <h2 className='page-h2'>
                    Impacted Hierarchy Configuration
                </h2>
                <section>
                    <RadioSelection
                        label='Impacts All Hierarchies'
                        name={'impacts_all_hierarchies'}
                        options={[
                            {
                                label: 'Yes',
                                value: true,
                            },
                            {
                                label: 'No',
                                value: false,
                            },
                        ]}
                        selectedValue={impacts_all_hierarchies}
                        onChange={_updateEditFields}
                    />
                </section>
            </section>
            <section>
                {impacts_all_hierarchies
                    ? <section />
                    : (

                        <RadioSelection
                            label='Hierarchy Inclusion Rule'
                            name={'hierarchy_inclusion_rule'}
                            options={[
                                {
                                    label: 'Include',
                                    value: 'include',
                                },
                                {
                                    label: 'Exclude',
                                    value: 'exclude',
                                },
                            ]}
                            selectedValue={hierarchy_inclusion_rule}
                            onChange={_updateEditFields}
                        />


                    )}
            </section>
            <section className='hierarchy-container'>
                {impacts_all_hierarchies ? <section /> : (<FliptHierarchyDropdown setForm={setForm} form={form} />)}
            </section>
            <section>
                <h2 className='page-h2'>
                    Geographic Configuration
                </h2>
                <section>
                    <RadioSelection
                        label='Nationwide'
                        name={'nationwide'}
                        options={[
                            {
                                label: 'Yes',
                                value: true,
                            },
                            {
                                label: 'No',
                                value: false,
                            },
                        ]}
                        selectedValue={nationwide}
                        onChange={_updateEditFields}
                    />
                </section>
            </section>
            <section className='input-container'>
                {nationwide ? <section /> : (
                    <>
                        <ToolTipDropDown
                            className='tooltip-input chuncky'
                            label='State'
                            multiple={true}
                            name={'state'}
                            onChange={_updateEditFields}
                            options={usStatesWithAbbreviations}
                            tooltip='State'
                            defaultValue={configStates}
                            value={configStates}
                        />
                        <ToolTipInput
                            label='Zip Code'
                            name={'zip_code'}
                            tooltip='Zip Code'
                            className='tooltip-input chuncky'
                            type='text'
                            onChange={updateZipCode}
                            value={zip_code}
                        />
                    </>
                )}
            </section>
        </div>
    )
}

const mapStateToProps = (state) => {
 const validationResults = state.globalEdits.validationResults;
    return {
        state: {
            app: state.app,
            user: state.user,
            globalEdits: state.globalEdits,
            validations: state.globalEdits.validations,
            validationResults: state.globalEdits.validationResults,
           showErrors: validationResults.hasErrors,
        },
    }
}

const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...AppActions,
        ...EmergencyOverrideActions,
        ...GlobalEditsActions,
    }

    return {
        actions: bindActionCreators(allActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateNewGlobalEdit);