import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import './styles.scss'

import { Creators as PlanManagementActions } from '../../../../redux/reducers/api/planManagement'
import CAGFilters from '../../../../components/CAGFilters'

const AccountFilters = (props) => {
  const { onFilterChange, state } = props
  const {
    selectedCarrier,
    selectedAccount,
  } = state
  return (
    <div id="accountFilters">
      <CAGFilters selectedCarrier={selectedCarrier} selectedAccount={selectedAccount} onFilterChange={onFilterChange} />
    </div>
  )
}

const mapStateToProps = (state) => ({
  state: {
    selectedCarrier: state.planManagement.planDetailsFilters.carrier ?? null,
    selectedAccount: state.planManagement.planDetailsFilters.account ?? null,
  },
})
const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...PlanManagementActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountFilters)
