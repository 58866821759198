import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import './styles.scss'
import Config from '../../../../../config'
import _ from 'lodash'
import FliptInput from '../../../../../components/form/fliptInput'
import FliptDatePicker from '../../../../../components/form/fliptDatePicker'
import { Creators as AppActions } from '../../../../../redux/reducers/app'
import { Creators as ClinicalCreationManagementActions } from '../../../../../redux/reducers/api/clinicalCreationManagement'
import FliptButton from '../../../../../components/form/fliptButton'
import { drugGroupValues } from '../../../../../config/constants/clinical/drugGroups'
import { convertStrToDateObj, addRemoveDaysToDate } from '../../../../../utils/utilities'
import FliptHierarchyDropdown from '../../../../../components/form/fliptHierarchyDropdown'

class CopyStepTherapyObject extends Component {

  constructor(props) {
    super(props)
    this.state = {
      formStep: 1,
      form: {
        doc_name: props.data[0]?.['module_name'],
        doc_version: '1.0',
        effective_start_date: '',
        doc_id: '',
        module: 'STEP_THERAPY',
        version: props.data[0]?.['version'],
        status: 'DRAFT',
        hierarchy: [],
      },
      targetDrugsConditions: [{}],
      targetDrugsDropdownValues: [{
        drug_group: drugGroupValues, drug_class: [], drug_subclass: [],
      }],
      targetDrugsAutoSuggestionValues: [{
        manufacturer: { values: [], loading: false }, drug_name: { values: [], loading: false },
      }],
      targetDrugsRowUpdated: null,
      targetDrugsColumnUpdated: null,
      activeStepLevel: 0,
      drugList: [{
        drug_list_a: [],
        drug_list_b: [],
      }],
      showCriteria: [false],
      dropdownValues: [[{
        drug_group: drugGroupValues, drug_class: [], drug_subclass: [],
      }]],
      autoSuggestionValues: [[{
        manufacturer: { values: [], loading: false }, drug_name: { values: [], loading: false },
      }]],
      showAdditionalCriteria: [false],
      rowUpdated: null,
      columnUpdated: null,
      levelUpdated: null,
      autoSuggestionMinChars: 3,
      changeInTargetDrugs: false,
      changeInStepLevelDrugs: false,
      changeInTargetManufacturer: false,
      changeInStepLevelManufacturer: false,
    }
    this.drugList = []
    this.stepLevelCriteria = []
    this.stepTherapyLevels = []
    this.targetDrugsConditions = []
    this.stepTherapyQualifierLevels = []
  }

  componentDidMount() {
    const { actions, data } = this.props
    actions.clearStepTherapyData()
    if (data[0]?.id) {
      actions.getStepTherapyData({ doc_id: data[0]?.id })
    }
  }

  componentDidUpdate(prevProps) {
    const { stepTherapyLevelsDrugList,
      stepTherapyLevelsCriteria,
      stepTherapyLevelsData,
      stepTherapyTargetDrugCondData,
      stepTherapyQualifierLevelsData
    } = this.props.state

    if (prevProps.state.stepTherapyLevelsDrugList !== stepTherapyLevelsDrugList ||
      prevProps.state.stepTherapyLevelsCriteria !== stepTherapyLevelsCriteria ||
      prevProps.state.stepTherapyLevelsData !== stepTherapyLevelsData ||
      prevProps.state.stepTherapyTargetDrugCondData !== stepTherapyTargetDrugCondData ||
      prevProps.state.stepTherapyQualifierLevelsData !== stepTherapyQualifierLevelsData) {
      this.drugList = [...stepTherapyLevelsDrugList]
      this.stepLevelCriteria = [...stepTherapyLevelsCriteria]
      this.stepTherapyLevels = [...stepTherapyLevelsData]
      this.targetDrugsConditions = [...stepTherapyTargetDrugCondData]
      this.stepTherapyQualifierLevels = [...stepTherapyQualifierLevelsData]
    }
  }

  componentWillUnmount() {
    this.props.actions.clearStepTherapyData()
  }

  _updateFields = (el, dateData) => {
    const { name, value } = dateData || el.currentTarget
    this.setState((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        [name]: value,
      },
    }))
  }

  setForm = (newForm) => {
    this.setState((prevState) => ({
      ...prevState,
      form: newForm
    }))
  }

  _saveStepTherapy = () => {
    const { props: { actions }, state } = this
    const { form } = state
    const { showTabFlag, setShowTabFlag } = this.props

    actions.saveStepTherapyData({
      ...form,
      drugList: this.drugList,
      stepLevelCriteria: this.stepLevelCriteria,
      stepTherapyLevels: this.stepTherapyLevels,
      targetDrugsConditions: this.targetDrugsConditions,
      stepTherapyQualifierLevels: this.stepTherapyQualifierLevels
    });

    setShowTabFlag(!showTabFlag);
    setTimeout(() => actions.getAllStepTherapyData(), Config.COMPONENT_DATA_LOAD_TIME)
  }

  render() {
    const { state, props } = this
    const { form } = state
    const { showTabFlag, setShowTabFlag } = props

    const { doc_name, effective_start_date } = form
    const minStepTherapyStartDate = addRemoveDaysToDate(1, false)


    return (
      <div id="copyStepTherapy">
        <div className='content copy-object'>
          <div className="step-therapy-inputs">
            <span>Step Therapy Name</span>
            <FliptInput placeholder="Step Therapy Name" name="doc_name" value={doc_name} onChange={this._updateFields} />
          </div>
          <div className="step-therapy-inputs">
            <span>Effective Start Date</span>
            <FliptDatePicker className="create-step-therapy-start-date" name="effective_start_date" minDate={minStepTherapyStartDate} value={convertStrToDateObj(effective_start_date)} onChange={this._updateFields} />
          </div>
          <FliptHierarchyDropdown form={form} setForm={this.setForm} />
          <div className="buttonContainer">
            <FliptButton name="Copy Object" className="primary searchButton" onClick={this._saveStepTherapy} />
          </div>
          <div className="buttonContainer">
            <FliptButton name="Cancel" className="secondary searchButton" onClick={() => setShowTabFlag(!showTabFlag)} />
          </div>
        </div>
      </div>
    )
  }

}

const mapStateToProps = (state) => ({
  state: {
    stepTherapyData: state.clinicalCreation.stepTherapyData,
    stepTherapyTargetDrugCondData: state.clinicalCreation.stepTherapyTargetDrugCondData,
    stepTherapyLevelsData: state.clinicalCreation.stepTherapyLevelsData,
    stepTherapyLevelsCriteria: state.clinicalCreation.stepTherapyLevelsCriteria,
    stepTherapyLevelsDrugList: state.clinicalCreation.stepTherapyLevelsDrugList,
    stepTherapyQualifierLevelsData: state.clinicalCreation.stepTherapyQualifierLevelsData,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
    ...ClinicalCreationManagementActions
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CopyStepTherapyObject)