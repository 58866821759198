import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as _ from "lodash";
import { Link } from "react-router-dom";
import Config from "../../../config";
import FliptGrid from "../../../components/fliptGrid";
import BreadCrumb from "../../../components/breadCrumb";
import {
  LinkContainerRenderer,
  ColorCodedStatusRenderer,
  MultiRowLinkRenderer,
} from "../../../components/fliptGrid/cellRenderers";
import FliptButton from "../../../components/form/fliptButton";
import PopupCellRenderer from "./popup/popupCellRenderer";
import { Creators as rebateManagementActions } from "../../../redux/reducers/api/rebateManagement";
import { Creators as NetworkCreationActions } from "../../../redux/reducers/api/networkCreationManagement";
import { Creators as AppActions } from "../../../redux/reducers/app";
import { calcGridRowHeight } from "../../../utils/utilities";
import { headers } from "./data/header";

import "./styles.scss";


const rebateManagement = (props) => {
  const { state, actions } = props;
  const { rebateSummaryData: dat, reloadPages } = state;
  const [data, setData] = useState(groupData(dat));
  
  const cellRendererParams = {
    action: {
      cellRenderer: PopupCellRenderer,
      searchArgs: {
        onClick: (action, rowdata) => publishDoc(action, rowdata)
      },
      width: 100
    },
    rebate_name: {
      cellRenderer: LinkContainerRenderer,
      searchArgs: [
        {
          path: "/rebate-management",
          searchArgs: { x_id: "id" },
          state: { editMode: false, create: false },
          key: "rebate_name",
          staticSearchArgs: {}
        }
      ],
      width: 100,
      overrideHeader: "Rebate Name"
    },
    claim_processor: {
      overrideHeader: "Contract"
    },
    drug_list: {
      cellRenderer: MultiRowLinkRenderer,
      path: "/view-druglist",
      searchArgs: { x_id: "id", drug_list: 'rowData' },
      //rowData uses the value of the row in cell
      overrideHeader: "Drug List"
    },
    status: {
      cellRenderer: ColorCodedStatusRenderer
    },
    version: {
      overrideHeader: "Version"
    }
  };
  const publishDoc = (action, rowdata) => {
    const x_id = rowdata["id"];
    const { id, ...data } = rowdata;
    const payload = {
      x_id,
      publish: true,
      payload: { ...data, status: "published" }
    };
    actions?.putRebateData({ ...payload });
  };

  useEffect(() => {
    if (dat && !_.isEqual(data, dat)) {
      setData(groupData(dat));
    }
  }, [dat]);

  useEffect(() => {
    setTimeout(
      () => actions.getRebateSummaryData(),
      Config.COMPONENT_DATA_LOAD_TIME
    );
  }, [reloadPages]);


  function groupData(data) {
     const groupedData = Object.values(
       dat.reduce((result, item) => {
         const existingItem = result.find((entry) => entry.id === item.id);

         if (existingItem) {
           existingItem.drug_list.push(item.drug_list);
         } else {
           result.push({ ...item, drug_list: [item.drug_list] });
         }

         return result;
       }, [])
     );
     return groupedData;
  }
  const _getRowHeight = (params) => calcGridRowHeight(params.data?.drug_list)

  return (
    <div id="durSummary">
      <div className="heading">
        <BreadCrumb {...props} />
        <div className="header">
          Rebate Management
          <div className="closeButton">
            <Link
              to={{
                pathname: "/rebate-management",
                state: { editMode: false, create: true }
              }}
              className="link-router"
            >
              <FliptButton
                className="closeButton"
                compact
                name="Add New Rebate"
              />
            </Link>
          </div>
        </div>
      </div>
      <div className="content maxHeight">
        <FliptGrid
          data={data}
          headers={headers}
          cellRendererParams={cellRendererParams}
          getRowHeight={_getRowHeight}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  state: {
    rebateSummaryData: state.rebateManagement.rebateSummaryData,
    rebateData: state.rebateManagement.rebateData,
    reloadPages: state.rebateManagement.reloadPages
    // allNetworkData: state.networkTierCreation.allNetworkTiersData
  }
});

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...rebateManagementActions,
    ...NetworkCreationActions,
    ...AppActions
  };

  return {
    actions: bindActionCreators(allActions, dispatch)
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(rebateManagement);
