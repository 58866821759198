import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _, { isEmpty, debounce } from 'lodash'
import { Button, Icon } from 'semantic-ui-react'

import { Creators as AppActions } from '../../../../redux/reducers/app'
import { Creators as GlobalEditsActions } from '../../../../redux/reducers/api/globalEdits'

import FliptGrid from '../../../../components/fliptGrid'
import { pricingMixmaster as rowCellInfo } from './data/rowCellInfo'
import { DeleteRowRenderer } from '../../../../components/fliptGrid/cellRenderers';
import { DropdownEditor, InputTextEditor } from '../../../../components/fliptGrid/cellEditors';
import FliptLabel from '../../../../components/form/fliptLabel'

const defaultPriceSource = {
    application: [],
    claim_processor: '',
    claim_processor_group: '',
    contract_type: [],
    exclusive: '',
    mixmaster_price_types: [],
    network_tier: '',
    pharmacy_type: '',
    priority_price_types: [],
    rank: ''
}

function AddRow(props) {
    const {
        addRow,
        name = 'Add Condition',
    } = props

    return (
        <Button compact size="small" onClick={addRow} color="youtube">
            <Icon name="add" />
            {name}
        </Button>
    )
}

const NetworkPricingConfiguration = (props) => {
    const { actions, state, editMode } = props;
    const { globalEdits } = state;
    const { price_source, otc_inclusion, otc_price_source } = globalEdits;
    const [localState, setLocalState] = useState({
        otcInclusion: state.otc_inclusion || 'N',
        otcPriceSource: state.otc_price_source || [],
    });
    const setState = (newState) => setLocalState({ ...localState, ...newState });
    const { otcInclusion, otcPriceSource } = localState;

    const headers = [
        'action',
        'claim_processor',
        'contract_type',
        'mixmaster_price_types',
        'pharmacy_type',
        'network_tier',
        'priority_price_types',
        'exclusive',
        'application',
    ]

    const renderGrid = (data, grid) => {
        const { gridFieldDetails } = state
        const rowCellInfoCopy = rowCellInfo
        rowCellInfoCopy.application.options = [{ key: 'Claims Processor', text: 'Claims Processor', value: 'claims_processor' }, { key: 'Application/Web', text: 'Application/Web', value: 'app_web' }]
        const gridHandleChange = (e, dropdown, rowKey) => handleChange(e, dropdown, rowKey, grid)
        gridFieldDetails?.forEach((element) => {
            const { field } = element
            let field_name = field
            if (field === grid) {
                field_name = 'claim_processor'
            }
            if (!(field_name in rowCellInfoCopy)) {
                return
            }

            rowCellInfoCopy[field_name].options = element.options.map((code, index) => ({
                key: index,
                text: code.display_name,
                value: code.value,
                map_value: code.map_value,
            }))
        })
        const cellRendererParams = {
            action: {
                cellRenderer: DeleteRowRenderer,
                state: {
                    onClick: (index) => delRow(index, grid),
                },
                width: 100,
            },
            claim_processor: {
                width: 200,
                overrideHeader: 'Contract',
            },
            contract_type: {
                width: 200,
                overrideHeader: 'Contract Type',
            },
            mixmaster_price_types: {
                width: 200,
                overrideHeader: 'Price Type',
            },
            pharmacy_type: {
                width: 200,
                overrideHeader: 'Pharmacy Type',
            },
            network_tier: {
                width: 200,
                overrideHeader: 'Network Tier',
            },
            priority_price_types: {
                width: 200,
                overrideHeader: 'Priority Price Type',
            },
            exclusive: {
                width: 145,
                overrideHeader: 'Exclusive',
            }, NetworkPricingConfiguration
        }
        const cellEditorParams = {
            claim_processor: {
                editable: true,
                cellEditor: DropdownEditor,
                onChange: gridHandleChange,
            },
            contract_type: {
                editable: true,
                cellEditor: DropdownEditor,
                onChange: gridHandleChange,
            },
            pharmacy_type: {
                editable: true,
                cellEditor: DropdownEditor,
                onChange: gridHandleChange,
            },
            network_tier: {
                editable: true,
                cellEditor: DropdownEditor,
                onChange: gridHandleChange,
            },
            mixmaster_price_types: {
                editable: true,
                cellEditor: DropdownEditor,
                onChange: gridHandleChange,
            },
            priority_price_types: {
                editable: true,
                cellEditor: DropdownEditor,
                onChange: gridHandleChange,
            },
            exclusive: {
                editable: true,
                cellEditor: DropdownEditor,
                onChange: gridHandleChange,
            },
            application: {
                editable: true,
                cellEditor: DropdownEditor,
                onChange: gridHandleChange,
            },
        }
        if (editMode) {
            return (
                <div className="grid-container">
                    <FliptGrid
                        data={data}
                        headers={headers}
                        cellRendererParams={cellRendererParams}
                        cellEditorParams={cellEditorParams}
                        rowCellInfo={rowCellInfoCopy}
                    />
                </div>
            )
        }
        return (
            <div className="grid-container">
                <FliptGrid
                    data={data}
                    headers={headers}
                />
            </div>
        )
    }

    const addRow = (gridSection) => {
        const sectionCopy = _.cloneDeep(globalEdits[gridSection])
        sectionCopy.push(defaultPriceSource)
        actions.updateGlobalEditsField({ name: gridSection, value: sectionCopy })
    }

    const delRow = (rowIndex, grid) => {
        const gridSource = globalEdits[grid] || []
        const newgridSource = gridSource.filter((_, index) => index !== rowIndex)
        actions.updateGlobalEditsField({ name: grid, value: newgridSource })
    }

    const handleChange = (e, dropdown, rowKey, source) => {
        const gridSource = globalEdits[source] || []
        const { name, value, options } = dropdown
        const newgridSource = _.cloneDeep(gridSource)
        newgridSource[rowKey] = { ...newgridSource[rowKey], [name]: value }
        if (name == "claim_processor") {
            newgridSource[rowKey] = { ...newgridSource[rowKey], "contract_type": options.filter(f => f.value === value)[0]?.map_value }
        }
        actions.updateGlobalEditsField({ name: source, value: newgridSource })
    }

    return (
        <div className="network-pricing-configuration-container">
            {renderGrid(price_source, 'price_source')}
            {editMode && (
                <div className="addRowButtonContainer">
                    <AddRow addRow={() => addRow('price_source')} />
                </div>
            )}
            {/* <div onClick={() => handleCheckboxChange()} className={`ui ${editMode ? 'checked' : 'disabled'} checkbox`}>
                <input type="checkbox" onClick={() => handleCheckboxChange()} checked={otcInclusion === 'Y'} disabled={!editMode && 'disabled'} />
                <FliptLabel
                    description="OTC Drug Covered"
                    label="OTC Drug Covered"
                />
            </div> */}
            {otcInclusion === 'Y' && (
                <>
                    {renderGrid(otcPriceSource, 'otc_price_source')}
                    {editMode && (
                        <div className="addRowButtonContainer">
                            <AddRow addRow={() => addRow('otc_price_source')} />
                        </div>
                    )}
                </>
            )}
            <div className="section-header"> </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    const section = state.globalEdits?.section || {};
    const fieldDetails = section?.global_pricing?.fields || [];
    return {
        state: {
            app: state.app,
            globalEdits: state.globalEdits,
            gridFieldDetails: fieldDetails,
            price_source: state.globalEdits?.price_source || [],
            otc_inclusion: state.globalEdits?.otc_inclusion || false,
            otc_price_source: state.globalEdits?.otc_price_source || [],
        },
    }
};

const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...AppActions,
        ...GlobalEditsActions,
    };

    return {
        actions: bindActionCreators(allActions, dispatch),
    };
};

const NetworkPricingConfigurationContainer = (props) => (
    <NetworkPricingConfiguration
        editMode
        {...props}
    />
);

export default connect(mapStateToProps, mapDispatchToProps)(NetworkPricingConfigurationContainer);
