import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import BreadCrumb from '../../../../../components/breadCrumb'
import { Creators as AppActions } from '../../../../../redux/reducers/app'
import { Creators as PlanManagementActions } from '../../../../../redux/reducers/api/planManagement'
import { Creators as MemberLookupActions } from '../../../../../redux/reducers/api/memberLookup'
import './styles.scss'
import FliptInput from '../../../../../components/form/fliptInput'
import FliptButton from '../../../../../components/form/fliptButton'
import SubmenuNav from '../../shared/navigation'
import MemberDemographic from '../../shared/memberDemographic'
import ParentInfo from '../../shared/parentInfo'
import moment from 'moment'

class DependentEligibility extends Component {
  constructor(props) {
    super(props)
    this.state = {
      demographicData: {
        first_name: '',
        last_name: '',
        email: '',
        dob: '',
        phone_number: null,
        address1: '',
        address2: '',
        city: '',
        state: '',
        zipcode: '',
        ssn: '',
        medicare_id: '',
        tpa_id: '',
        member_id: '',
        effective_start_date: '',
        effective_end_date: '',
        gender:'',
        type:''
      },
      personId: '',
      planData: [],
      memberDetailsData:null,
      isMemberExist: false,
      memberChecked: false,
      savedMemberId:''
    }
  }

  componentDidUpdate(prevProps) {
    const { props, state } = this
    const { memberDetailsData, memberExistData, saveUserData } = props.state
    if (prevProps.state.memberDetailsData !== memberDetailsData) {
      this.setState({ memberDetailsData })
    }
    if (prevProps.state.memberExistData !== memberExistData) {
      this.setState({ isMemberExist: !!memberExistData.length, memberChecked:true })
    }
    if (prevProps.state.saveUserData !== saveUserData) {
      const index = saveUserData?.attributes?.dependents?.length && 
      saveUserData?.attributes?.dependents.findIndex((data) => data.email === state.demographicData.email)
      if(index>=0) this.setState({ savedMemberId: saveUserData.attributes.dependents[index].flipt_person_id })
    }
  }

  _updatePersonId = (el) => {
    this.setState({ personId: el.currentTarget.value, memberDetailsData:null })
  }

  _searchPersonData() {
    const { actions } = this.props
    const { state: { personId } } = this
    if (!personId) {
      const transitionalPortal = {
        header: 'Fields are missing',
        copy: 'Please add Person Id to proceed',
      }
      actions.displayTransitionalPortal(transitionalPortal)
      return;
    }
    actions.fetchMemberDetailsData([personId])
  }

  _updateDemographicData = (el, dropdown) => {
    const { isMemberExist, demographicData } = this.state
    const { name, value } = dropdown || el.currentTarget
    if (isMemberExist) this.setState({ isMemberExist: false, memberChecked:false })
    demographicData[name] = value
    this.setState({ demographicData })
  }

  _showTransitionalPortal = (header, message) => {
    const { props } = this;
    const transitionalPortal = {
      header: header,
      copy: message,
    }
    props.actions.displayTransitionalPortal(transitionalPortal)
  }
  _generateMember = () => {
    const { state: { demographicData, memberDetailsData }, props } = this;
    let header = 'Fields are missing';
    let message = 'to  generate a member';

    if (!demographicData.type) {
      message = `Please select type ${message}`
      this._showTransitionalPortal(header, message);
      return;
    }

    if (!demographicData.first_name) {
      message = `Please add First Name ${message}`
      this._showTransitionalPortal(header, message);
      return;
    }

    if (!demographicData.last_name) {
      message = `Please add last name ${message}`
      this._showTransitionalPortal(header, message);
      return;
    }

    if (!demographicData.email) {
      message = `Please add email ${message}`
      this._showTransitionalPortal(header, message);
      return;
    }

    if (!demographicData.gender) {
      message = `Please add gender ${message}`
      this._showTransitionalPortal(header, message);
      return;
    }

    if (!demographicData.dob) {
      message = `Please add date of birth ${message}`
      this._showTransitionalPortal(header, message);
      return;
    }

    if (!demographicData.phone_number) {
      message = `Please add Phone Number ${message}`
      this._showTransitionalPortal(header, message);
      return;
    }
    if ( demographicData.phone_number && demographicData?.phone_number.replaceAll('-','').length !== 10) {
      message = `Phone number should be of 10 digits`
      this._showTransitionalPortal(header, message);
      return;
    }

    if (!demographicData.address1) {
      message = `Please add address1 ${message}`
      this._showTransitionalPortal(header, message);
      return;
    }


    if (!demographicData.city) {
      message = `Please add city ${message}`
      this._showTransitionalPortal(header, message);
      return;
    }

    if (!demographicData.state) {
      message = `Please add state ${message}`
      this._showTransitionalPortal(header, message);
      return;
    }

    if (!demographicData.zipcode) {
      message = `Please add zipcode ${message}`
      this._showTransitionalPortal(header, message);
      return;
    }
    if (!demographicData.effective_start_date) {
      message = `Please add effective start date ${message}`
      this._showTransitionalPortal(header, message);
      return;
    }

    if (!demographicData.effective_end_date) {
      message = `Please add effective end date ${message}`
      this._showTransitionalPortal(header, message);
      return;
    }

    const params = {
      type: demographicData.type,
      first_name: demographicData.first_name,
      last_name: demographicData.last_name,
      email: demographicData.email,
      phone_number: demographicData.phone_number.replaceAll('-', ''),
      address_1: demographicData.address1,
      address_2: demographicData.address2,
      city: demographicData.city,
      state: demographicData.state,
      zip_code: demographicData.zipcode,
      date_of_birth: demographicData.dob,
      gender: demographicData.gender,
      tpa_member_id: demographicData.tpa_id,
      member_id: demographicData.member_id,
      medicare_benefit_id: demographicData.medicare_id,
      effective_start_date: demographicData.effective_start_date,
      effective_end_date: demographicData.effective_end_date,
      account: '',
      group: '',
      plan_name: '',
      plan_year: moment().format('YYYY'),
      parent_id: memberDetailsData?.flipt_person_id
    }
    props.actions.saveUser(params)
  }

  _checkIfMemberExist = () => {
    const { state: { demographicData, personId, memberDetailsData }, props } = this;

    let header = 'Fields are missing';
    let message = 'to check if member exist';

    if (!personId || !memberDetailsData) {
      message = `Please add Person Id ${message}`
      this._showTransitionalPortal(header, message);
      return;
    }

    if (!demographicData.first_name) {
      message = `Please add First Name ${message}`
      this._showTransitionalPortal(header, message);
      return;
    }

    if (!demographicData.last_name) {
      message = `Please add last name ${message}`
      this._showTransitionalPortal(header, message);
      return;
    }

    if (!demographicData.dob) {
      message = `Please add Date of birth ${message}`
      this._showTransitionalPortal(header, message);
      return;
    }

    const params = {
      first_name: demographicData.first_name,
      last_name: demographicData.last_name,
      date_of_birth: demographicData.dob ? moment(demographicData.dob).format('YYYY-MM-DD') : '',
    }
    props.actions.checkIfMemberExist(params)
  }

  _cancelClaim = () => {
    this.setState({
      demographicData: {
        first_name: '',
        last_name: '',
        email: '',
        dob: '',
        phone_number: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zipcode: '',
        medicare_id: '',
        tpa_id: '',
        member_id: '',
        effective_start_date: '',
        effective_end_date: '',
        gender: '',
        type: ''
      },
      planNameOptions: [],
      personId: '',
      planData: [],
      selectedPlanData: {},
      isMemberExist: false,
      memberChecked:false,
      savedMemberId:'',
      memberDetailsData:null
    })
  }


  render() {
    const { state: { personId, demographicData, memberDetailsData,
       isMemberExist, memberChecked , savedMemberId} } = this
    return (
      <div id="manualEligibilityEntry">
        <BreadCrumb {...this.props} />
        <div className="mainHeader">
          <h2>Dependent Eligibility Entry </h2>
        </div>
        <div className="header">
          <SubmenuNav active="dependent" />
        </div>
        <div className="content">
          <div className="section">
            <div className="section-header fields-container">
              <h2>Parent Search</h2>
            </div>
            <div className="fields-container">
              <div className="fields-header">
                <FliptInput value={personId} onChange={this._updatePersonId} stylized name="personId" label="Flipt Member Id" />
                <FliptButton disabled={!!memberDetailsData} className='primary' name='SEARCH PARENT' onClick={() => this._searchPersonData()} />
              </div>
            </div>
          </div>
          <ParentInfo memberData={memberDetailsData} personId={personId} />
        </div>

        <MemberDemographic _updateDemographicData={this._updateDemographicData} memberChecked={memberChecked}
          demographicData={demographicData} _checkIfMemberExist={this._checkIfMemberExist}
           _generateMember={this._generateMember} isMemberExist={isMemberExist}/>

        <div className="content margin-bottom">
          <div className="section">
            <div className="section-header fields-container">
              <h2>Member TrueVault Record Creation</h2>
            </div>
            <div className="fields-container">
              <div className="fields-header">
                <FliptInput value={savedMemberId} stylized name="flipt_person_id" label="Flipt Person Id" />
                <FliptInput value={savedMemberId && demographicData.effective_start_date} stylized name="effective_date" label="Effective Date" />
              </div>
            </div>
          </div>
        </div>

        <div className="buttons-container">
          <div className="fields">
            <FliptButton name="CLEAR" disabled className="cancelButton" onClick={this._cancelClaim} />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  state: {
    memberLookup: state.memberLookup,
    user: state.user,
    benefitPlanData: state.planManagement.benefitPlanData,
    planLookupData: state.planManagement.planLookupData,
    memberDetailsData: state.memberLookup.memberDetailsData,
    memberExistData: state.memberLookup.memberExistData,
    saveUserData: state.memberLookup.saveUserData,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
    ...PlanManagementActions,
    ...MemberLookupActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DependentEligibility)