import React from 'react'
import { Table, Button, Icon } from 'semantic-ui-react'

import FliptInput from '../form/fliptInput'
import FliptDropdown from '../form/fliptDropdown'
import FliptSearch from '../form/fliptSearch'
import './styles.scss'
import { convertSnakeCaseToString } from '../../utils/utilities'

// data = array of list
export default function FliptTable(props) {
  const {
    data,
    headers,
    rowCellInfo,
    dropdownOptions,
    autoSuggestionValues,
    handleChange,
    autoSuggestionResultSelect,
    autoSuggestionMinChars,
    addRow,
    delRow,
    rowClick,
    infoClick,
  } = props
  // if (!data || !data.length) return (<div className="loading-container"><img className="loading-icon" src="/i/loading-icon.svg" /></div>)
  if (!data || !data.length) return (<div />)
  const headerTitles = headers || Object.keys(data[0])
  const renderCell = (headerName, value, dropdownOption, autoSuggestionValue, rowKey) => {
    if (rowCellInfo[headerName].type === 'deleteRow') {
      return <Button onClick={() => delRow(rowKey)} compact size="tiny" icon="trash" />
    }
    if (rowCellInfo[headerName].type === 'addRow') {
      return <Button compact size="tiny" icon="add" onClick={addRow} />
    }
    if (rowCellInfo[headerName].type === 'editRow') {
      return <Button compact size="tiny" icon="edit" onClick={addRow} />
    }
    if (rowCellInfo[headerName].type === 'input') {
      return <FliptInput placeholder={headerName} name={headerName} value={value} disabled={rowCellInfo[headerName].disabled} onChange={(e, dropdown) => handleChange(e, dropdown, rowKey)} />
    }
    if (rowCellInfo[headerName].type === 'dropdown') {
      return <FliptDropdown name={headerName} value={value} options={ rowCellInfo[headerName].options || dropdownOption[headerName]} selectOnBlur={false} scrolling search clearable selection disabled={rowCellInfo[headerName].disabled}  onChange={(e, dropdown) => handleChange(e, dropdown, rowKey)} />
    }
    if (rowCellInfo[headerName].type === 'search') {
      return <FliptSearch name={headerName} value={value} results={autoSuggestionValue[headerName].values} loading={autoSuggestionValue[headerName].loading} minCharacters={autoSuggestionMinChars} onResultSelect={(e, d) => autoSuggestionResultSelect(d, rowKey)} disabled={rowCellInfo[headerName].disabled} onSearchChange={(e, dropdown) => handleChange(e, dropdown, rowKey)} />
    }
    return <FliptInput placeholder={headerName} name={headerName} value={value} disabled={rowCellInfo[headerName].disabled} onChange={(e, drop) => handleChange(e, drop, rowKey)} />
  }
  const renderHeader = () => (
    <Table.Header>
      <Table.Row>
        { headerTitles.map((headerCopy) => (
          <Table.HeaderCell key={headerCopy}>
            { convertSnakeCaseToString(headerCopy) }
            {' '}
          </Table.HeaderCell>
        ))}
      </Table.Row>
    </Table.Header>
  )

  const renderBody = () => (
    <Table.Body>
      {
          data.map((d, idx) => (
            <Table.Row key={idx} idx={idx} onClick={(el) => (rowClick ? rowClick(el) : false)} className={rowClick ? 'clickable' : ''}>
              {
                headerTitles.map((headerName, idx2) => {
                  const cellData = !rowCellInfo ? d[headerName] : renderCell(headerName, d[headerName], dropdownOptions && dropdownOptions[idx],
                    autoSuggestionValues &&  autoSuggestionValues[idx], idx) 
                  const showIcon = d.icon || ''
                  return (
                    <Table.Cell key={idx2}>
                      {cellData}
                      {
                        showIcon && idx2 === headerTitles.length - 1 && (
                          <span>
                            <Icon onClick={() => infoClick(d)} name={showIcon} />
                          </span>
                        )
                      }
                    </Table.Cell>
                  )
                })
              }
            </Table.Row>
          ))
        }
    </Table.Body>
  )

  if (!data) {
    return (<div>No Data</div>)
  }

  return (
    <Table celled>
      { renderHeader() }
      { renderBody() }
    </Table>
  )
}
