import React, { useEffect, useState } from 'react'
import * as _ from 'lodash'
import FliptGrid from '../../../../components/fliptGrid'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Creators as NetworkTierCreationActions } from '../../../../redux/reducers/api/networkCreationManagement'
import '../styles.scss'

const PharmacyList = (props) => {
  const { level, conditionLevels, state } = props
  const { assignedPharmacyData } = state
  const [conData, setConData] = useState(conditionLevels)
  const [data, setData] = useState([])
  const headers = [
    'pharmacynpi',
    'pharmacyname',
    'pharmacyaddress1',
    'pharmacycity',
    'pharmacystate',
    'pharmacyzip1',
    'chaincode',
    'corpname',
    'relationship_id',
    'relationship_name',
    'relationship_type'
  ]
  const cellRendererParams = {
    pharmacynpi: {
      width: 200,
      overrideHeader: 'NPI',
    },
    pharmacyname: {
      width: 200,
      overrideHeader: 'Pharmacy Name',
    },
    pharmacyaddress1: {
      width: 200,
      overrideHeader: 'Address',
    },
    pharmacycity: {
      width: 200,
      overrideHeader: 'City',
    },
    pharmacystate: {
      width: 200,
      overrideHeader: 'State',
    },
    pharmacyzip1: {
      width: 200,
      overrideHeader: 'Zip Code',
    },
    chaincode: {
      width: 200,
      overrideHeader: 'Chain Code',
    },
    corpname: {
      width: 200,
      overrideHeader: 'Corp Name',
    },
  }

  useEffect(() => {
    if (data && !_.isEqual(data, assignedPharmacyData)) {
      setData(assignedPharmacyData)
    }
  }, [conditionLevels, assignedPharmacyData])

  return (
    <div id="pharmacy-list">
      <div className="header">
        Pharmacy List for Pharmacy Condition Level {level}
      </div>
      <div className="content maxHeight">
        <FliptGrid data={data} headers={headers} cellRendererParams={cellRendererParams} />
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  state: {
    assignedPharmacyData: state.networkTierCreation.assignedPharmacyData,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...NetworkTierCreationActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PharmacyList)