import Immutable from 'seamless-immutable'
import { createActions, createReducer } from 'reduxsauce'

export const INITIAL_STATE = Immutable({
	autoSuggestData: {
		mfg: [],
		drug_name: [],
	},
	autoSuggestSearchTerm: '',
	isLoading: false,
})

export const clearReducer = () => INITIAL_STATE

export const clearAutoSuggestDataReducer = (state = INITIAL_STATE) => ({
	...state,
	autoSuggestData: {
		mfg: [],
		drug_name: [],
	}
})

export const clearAutoSuggestSearchTermReducer = (state = INITIAL_STATE) => ({
	...state,
	autoSuggestSearchTerm: '',
})

export const setLoadingReducer = (state = INITIAL_STATE, { payload }) => ({
	...state,
	isLoading: payload,
})

export const setAutoSuggestDataReducer = (state = INITIAL_STATE, { payload }) => {
	const data = {...state.autoSuggestData}
	data[payload.search_for] = payload.data
	return {
		...state,
		autoSuggestData: data,
		autoSuggestSearchTerm: payload.search_term,
	}
}

export const { Types, Creators } = createActions({
	clearReducer: null,
	clearAutoSuggestData: null,
	clearAutoSuggestSearchTerm: null,
	setAutoSuggestData: ['payload'],
	getAutoSuggestData: ['payload'],
	setLoading: ['payload'],
})

const HANDLERS = {
	[Types.CLEAR_REDUCER]: clearReducer,
	[Types.CLEAR_AUTO_SUGGEST_DATA]: clearAutoSuggestDataReducer,
	[Types.CLEAR_AUTO_SUGGEST_SEARCH_TERM]: clearAutoSuggestSearchTermReducer,
	[Types.SET_AUTO_SUGGEST_DATA]: setAutoSuggestDataReducer,
	[Types.SET_LOADING]: setLoadingReducer,
}

export default createReducer(INITIAL_STATE, HANDLERS)