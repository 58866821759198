import React from 'react'

import FliptDatePicker from '../../../../../components/form/fliptDatePicker'
import FliptButton from '../../../../../components/form/fliptButton'
import FliptDropdown from '../../../../../components/form/fliptDropdown'
import FliptInput from '../../../../../components/form/fliptInput'
import { filterDistinct, formatAddress } from '../../../../../utils/utilities'
import './styles.scss'
import { Button } from 'semantic-ui-react'
import moment from 'moment'
import { validateEmailAddress } from '../../../../../utils/validationHelpers'
import Info from '../../../../../components/info'
export default (props) => {
  const {
    clearFields, accounts_access, data, updateMemberSearchForm, searchMember, member_detail_search_form, updateEligibility
  } = props
  const {
    city,
    cobra_effective_date,
    cobra_termination_date,
    coverage_end_date,
    coverage_start_date,
    date_of_birth,
    domain_name,
    email_addresses,
    first_name,
    gender,
    group,
    home_address_1,
    home_address_2,
    last_name,
    locations,
    member_id,
    personal_phones,
    private_account,
    relationship,
    ssn,
    state,
    tpa_member_id,
    flipt_person_id,
    zip,
    registration_status,
    medicare_eligible,
    all_plan_years = [],
    claimed_date,
    medicaid_end_date,
    medicare_begin_date,
    medicare_benefit_id,
    medicare_end_date,
    medicare_plan_code,
    name_suffix,
    mailing_city,
    medicare_status_code,
    mailing_state,
    mailing_address,
    mailing_address_2,
    mailing_zip,
    communication_option_phone,
    language_code
    // registration_date = '',
  } = data.member_details
  let is_vip = false
  if (data?.member_details?.is_vip) is_vip = data.member_details.is_vip
  let customVip = 'false'
  if (is_vip) customVip = 'true'

  const addressOptions = []
  if (locations && locations.length) {
    locations.forEach((l) => {
      addressOptions.push({
        key: l.place_id,
        text: formatAddress(l),
        value: l.number,
      })
    })
  }
  const defaultAddress = addressOptions.length ? addressOptions[0].value : 1
  const domainOptions = accounts_access.map((d) => ({
    key: d.id,
    text: d.name,
    value: d.id,
  }))

  const allPlanYearOptions = all_plan_years.map((d) => ({
    key: d,
    text: d,
    value: d,
  }))

  const personalPhones = personal_phones && personal_phones.length ? personal_phones : []
  const phoneOptions = filterDistinct(personalPhones, 'phone_number').map((vPhone, i) => ({
    key: i,
    text: vPhone.phone_number,
    value: i,
    icon: (!!vPhone.verified && !!vPhone.preferred) ? 'check' : '',
  }))

  const vipOptions = [
    { key: 0, text: 'Yes', value: 'true' },
    { key: 1, text: 'No', value: 'false' }]

  const emailAddresses = email_addresses && email_addresses.length ? [...new Set(email_addresses)] : []
  // checking if email_address is a valid email
  const emailAddressOptions = emailAddresses?.filter(each => validateEmailAddress(each?.email_address))?.map((addy, i) => ({
    key: i,
    text: addy.email_address,
    value: i,
    icon: (addy.primary) ? 'check' : '',
  }))
  const memberIdentifier_opt = [
    { key: 'tpa_id', text: 'TPA ID', value: 'tpa_id' },
    { key: 'member_id', text: 'Member ID', value: 'member_id' },
  ]
  let privateAcct = null

  if (typeof private_account !== 'undefined') {
    privateAcct = private_account ? 'Yes' : 'No'
  }

  const getRegisteredDate = () => {
    return registration_status === 'Registered' ? moment(claimed_date).format('MM-DD-YYYY HH:MM:SS') : registration_status;
  }
  const isMemberId = !!member_detail_search_form.id
  const isName = (member_detail_search_form.first_name && member_detail_search_form.last_name) && !isMemberId

  return (
    <>
      <div className="search-container">
      <div className="horizontalLine" />
          <div className="search-container">
            <div className="fields-container ">
              <div className="fields-client-header">
                <div className="field-label">
                  <span>{"Member ID"}</span>
                </div>
                <FliptInput
                  value={member_detail_search_form.id}
                  disabled={!!member_detail_search_form.first_name}
                  name="id"
                  placeholder="Member Id or TPA Id"
                  onChange={updateMemberSearchForm}
                  label="Member ID or TPA ID"
                  description="Member ID or TPA ID"
                  className="field-input"
                />
              </div>
              <div className="gap">
                <Button className="option" disabled={true}>
                  OR
                </Button>
              </div>

              <div className="fields-client-header">
                <div className="field-label">
                  <span>{"Member First Name"}</span>
                </div>
                <FliptInput
                  value={
                    !isMemberId ? member_detail_search_form.first_name : ""
                  }
                  disabled={isMemberId}
                  name="first_name"
                  placeholder="First Name"
                  onChange={updateMemberSearchForm}
                  label="Name"
                  description="Full Name"
                  className="field-input"
                />
              </div>
              <div className="gap"></div>
              <div className="fields-client-header">
                <div className="field-label">
                  <span>{"Member Last Name"}</span>
                </div>
                <FliptInput
                  value={!isMemberId ? member_detail_search_form.last_name : ""}
                  disabled={isMemberId}
                  name="last_name"
                  placeholder="Last Name"
                  onChange={updateMemberSearchForm}
                  label=""
                  description="Last Name"
                  className="field-input"
                />
              </div>
              <div className="gap"></div>
              <div className="fields-client-header">
                <div className="field-label">
                  <span>{"DOB"}</span>
                </div>
                <FliptDatePicker
                  value={isName ? member_detail_search_form.date_of_birth : ""}
                  disabled={!isName}
                  name="date_of_birth"
                  placeholder="DOB"
                  onChange={updateMemberSearchForm}
                  label=""
                  description="Date of birth"
                 className="field-date-picker"
                />
              </div>

              <div className='searchBtn'>
                <Button
                  icon="search"
                  className="clearBtn"
                  color="white"
                  size="mini"
                  disabled={
                    isMemberId && member_detail_search_form.id.length < 5
                  }
                  onClick={searchMember}
                />
                <Button
                  icon="remove"
                  className="clearBtn"
                  color="white"
                  size="mini"
                  onClick={clearFields}
                />
              </div>
            </div>
          </div>

        <div className="button-container column">
          {flipt_person_id && <FliptButton name="Choose Eligibility" className="secondary" onClick={updateEligibility} style={{ marginRight: 5 }} />}
        </div>
      </div>

      <div className="section-header" style={{ 'display': 'flex', 'flex-direction': 'row' }}>
        <h2>Member Details</h2>
        {is_vip && <div className='vip-header'> VIP </div>}
      </div>

      <div className="section-contents">
        <div className="fields-container">
          <div className="horizontal-fields">
            <FliptInput value={first_name} label="First Name" disabled stylized />
            <FliptInput value={last_name} label="Last Name" disabled stylized />
            <FliptInput value={date_of_birth} label="DOB" disabled stylized />
            <FliptInput value={ssn} label="SSN" disabled stylized />
          </div>
        </div>
        <div className="fields-container">
          <div className="horizontal-fields">
            <FliptInput value={gender} label="Gender" disabled stylized />
            <FliptInput className="address1" value={`${home_address_1 || ''} ${home_address_2 || ''}`} label="Primary Address" disabled stylized />
            <FliptInput value={city} label="City" disabled stylized />
            <FliptInput value={state} label="State" disabled stylized className="state" />
          </div>
        </div>
        <div className="fields-container">
          <div className="horizontal-fields">
            <FliptInput value={zip} label="Zip Code" disabled stylized />
            <FliptDropdown defaultValue={0} name="email_addresses" options={emailAddressOptions} label="Email Addresses" className="dropdown-width" stylized />
            <FliptDropdown defaultValue={0} name="phone" options={phoneOptions} label="Phone(s)" className="dropdown-width" stylized />
            <FliptDropdown className="dropdown-width" defaultValue={defaultAddress} options={addressOptions} simple item name="locations" stylized label="Alternate Address" />
          </div>
        </div>

        <div className="fields-container">
          <div className="horizontal-fields">
            <FliptInput value={relationship} label="Relationship" disabled stylized />
            <FliptInput value={privateAcct} label="Private Account" disabled stylized />
            <FliptInput value={getRegisteredDate()} label="Registration Status" disabled stylized />
          </div>
        </div>
      </div>

      <div className="section-contents">
        <div className="fields-container">
          <div className="horizontal-fields">
            <FliptInput value={first_name && "FLIPT"} label="Carrier Name" disabled stylized />
            <FliptInput value={domain_name} label="Account Name" disabled stylized />
            <FliptInput value={group} label="Group Name" disabled stylized />
            <FliptInput value={is_vip ? 'Yes' : first_name ? 'No' : null} label="VIP" disabled stylized />
          </div>
        </div>

        <div className="fields-container">
          <div className="horizontal-fields">
            <FliptInput value={member_id} label="Member ID" disabled stylized />
            <FliptInput value={flipt_person_id} label="Flipt Person Id" disabled stylized />
            <FliptInput value={tpa_member_id} label="TPA Member ID" disabled stylized />
          </div>
        </div>

        <div className="fields-container">
          <div className="horizontal-fields">
            <FliptInput value={coverage_start_date} label="Coverage Start Date" disabled stylized />
            <FliptInput value={coverage_end_date} label="Coverage End Date" disabled stylized />
            <FliptInput value={cobra_effective_date} label="COBRA Effective Date" disabled stylized />
            <FliptInput value={cobra_termination_date} label="COBRA Termination Date" disabled stylized />
          </div>
        </div>

        <div className="fields-container">
          <div className="horizontal-fields">
            <FliptInput value={medicare_status_code} label="Medicare Status" disabled stylized />
            <FliptInput value={medicare_plan_code} label="Medicare Plan Code" disabled stylized />
            <FliptInput value={name_suffix} label="Suffix" disabled stylized />
            <FliptInput value={language_code} label="Language" disabled stylized />
          </div>
        </div>

        <div className="fields-container">
          <div className="horizontal-fields">
            <FliptInput value={communication_option_phone} label="Communication Phone Number" disabled stylized />
            <FliptInput value={mailing_address} label="Mailing Address" disabled stylized />
            <FliptInput value={mailing_address_2} label="Mailing Address 2" disabled stylized />
            <FliptInput value={mailing_city} label="Mailing City" disabled stylized />
          </div>
        </div>

        <div className="fields-container">
          <div className="horizontal-fields">
            <FliptInput value={mailing_state} label="Mailing State" disabled stylized />
            <FliptInput value={mailing_zip} label="Mailing Zip" disabled stylized />
          </div>
        </div>
      </div>
    </>
  )
}
