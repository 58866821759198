export default [
    {
        key: '0',
        text: 'Pricing',
        value: 'Pricing',
    },
    {
        key: '1',
        text: 'Exclude',
        value: 'Exclude',
    }
]
