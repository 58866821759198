import Immutable from "seamless-immutable";
import moment from "moment";
import { createActions, createReducer } from "reduxsauce";

const INITIAL_BYPASS_EDITS = [
    {
        'selected': false,
        'edit': '79',
        'text': 'Refill Too Soon',
        'value': '79',
        'key': '79',
        'max_ds': '',
        'max_quantity': '',
        'max_fills': '',
    },
    {
        'selected': false,
        'edit': '88',
        'text': 'DUR Reject Error',
        'value': '88',
        'key': '88',
        'max_ds': '',
        'max_quantity': '',
        'max_fills': '',
    },
    {
        'selected': false,
        'edit': '76',
        'text': 'Plan Limitations Exceeded',
        'value': '76',
        'key': '76',
        'max_ds': '',
        'max_quantity': '',
        'max_fills': '',
    },
    {
        'selected': false,
        'edit': '70',
        'text': 'Service Not Covered - Plan/Benefit Exclusion',
        'value': '70',
        'key': '70',
        'max_ds': '',
        'max_quantity': '',
        'max_fills': '',
    },
    {
        'selected': false,
        'edit': 'A6',
        'text': 'Service May Be Covered Under Medicare Part B',
        'value': 'A6',
        'key': 'A6',
        'max_ds': '',
        'max_quantity': '',
        'max_fills': '',
    },
    {
        'selected': false,
        'edit': '3W',
        'text': 'Prior Authorization In Process',
        'value': '3W',
        'key': '3W',
        'max_ds': '',
        'max_quantity': '',
        'max_fills': '',
    },
    {
        'selected': false,
        'edit': '6Z',
        'text': 'Provider Not Eligible To Perform Service',
        'value': '6Z',
        'key': '6Z',
        'max_ds': '',
        'max_quantity': '',
        'max_fills': '',
    },
    {
        'selected': false,
        'edit': '75',
        'text': 'Prior Authorization Required',
        'value': '75',
        'key': '75',
        'max_ds': '',
        'max_quantity': '',
        'max_fills': '',
    },
    {
        'selected': false,
        'edit': 'AG',
        'text': 'Days Supply Limitation',
        'value': 'AG',
        'key': 'AG',
        'max_ds': '',
        'max_quantity': '',
        'max_fills': '',
    }
];

export const INITIAL_STATE = Immutable({
    'configuration_type': 'Emergency',
    'configuration_name': '',
    'configuration_notes': '',
    'effective_begin_date': '',
    'effective_term_date': '',
    'status': '',
    'impacts_all_hierarchies': true,
    'hierarchy': [],
    'nationwide': true,
    'geographic_configuration': {},
    'state': [],
    'zip_code': '',
    'emergency_location_configuration': [],
    'patient_location_configuration': [],
    'claim_submission_configuration': {},
    'origin_5_required': false,
    'origin_5_missing_response': '',
    'scc_13_required': false,
    'scc_13_missing_response': '',
    'pharmacy_exclusions': [],
    'zero_dollar_copay_drugs': [],
    'bypass_edit_configuration': [],
    'bypass_options': INITIAL_BYPASS_EDITS,
    'clinical_pa': [],
    'clinical_ql': [],
    'clinical_step_therapy': [],
    'programs': [],
    'formulary_name': '',
    'formulary_id': '',
    'tiers': [],
    'um_ranking': [],
    'custom_cost_share': '',
    'copays': [],
    'price_source': [],
    'dashboard': [],
    'page': 'dashboard',
    'validations': {
        'configuration_name': {
            required: true,
            validation: (val) => (val + '').length >= 2,
            errorMessage: 'Must enter a configuration name that is at least 2 characters long',
        },
        'configuration_notes': {
            required: false,
            validation: (val) => (val + '').length >= 2,
            errorMessage: 'Must enter a configuration note that is at least 2 characters long',
        },
        'effective_begin_date': {
            required: true,
            validation: (val) => (val + '').length >= 2,
            errorMessage: 'Must enter a begin date',
        },
        'effective_term_date': {
            required: true,
            validation: (val, state) => {
                const beginDate = moment(state.effective_begin_date);
                const endDate = moment(val);
                return endDate.isAfter(beginDate);
            },
            errorMessage: 'Must enter an end date that is after the begin date',
        },
    },
    'validationResults': {}
})

export const updateEmergencyOverrideField = (state = INITIAL_STATE, { payload }) => {
    const { name, value } = payload;
    return {
        ...state,
        [name]: value,
    };
};

export const setEmergencyOverride = (state = INITIAL_STATE, { payload }) => {
    return {
        ...state,
        ...payload,
        page: 'create-override'
    };
};

export const setEmergencyOverrideDashboard = (state = INITIAL_STATE, { payload }) => {
    return {
        ...state,
        dashboard: payload,
    };
}

export const setPage = (state = INITIAL_STATE, { payload }) => {
    return {
        ...state,
        page: payload,
    };
};

export const returnToDashboard = (state = INITIAL_STATE, { payload }) => {
    return INITIAL_STATE;
}

export const updateValidationResult = (state = INITIAL_STATE, { payload }) => ({
    ...state,
    validationResults: {
        ...state.validationResults,
        ...payload,
    },
})


export const { Types, Creators } = createActions({
    getEmergencyOverrideConfiguration: ['payload'],
    getEmergencyOverrideDashboard: null,
    returnToDashboard: null,
    saveEmergencyConfiguration: ['payload'],
    setEmergencyOverride: ['payload'],
    setEmergencyOverrideDashboard: ['payload'],
    setPage: ['payload'],
    testEmergencyValidation: ['payload'],
    updateEmergencyOverrideField: ['payload'],
    updateValidationResult: ['payload'],
    validateThenSaveAndExit: null,
});

const HANDLERS = {
    [Types.RETURN_TO_DASHBOARD]: returnToDashboard,
    [Types.SET_EMERGENCY_OVERRIDE]: setEmergencyOverride,
    [Types.SET_EMERGENCY_OVERRIDE_DASHBOARD]: setEmergencyOverrideDashboard,
    [Types.SET_PAGE]: setPage,
    [Types.UPDATE_EMERGENCY_OVERRIDE_FIELD]: updateEmergencyOverrideField,
    [Types.UPDATE_VALIDATION_RESULT]: updateValidationResult,
};

export default createReducer(INITIAL_STATE, HANDLERS);
