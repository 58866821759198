import React from 'react'
import FliptGrid from '../../../../components/fliptGrid'
import FliptButton from '../../../../components/form/fliptButton'
import { Link } from 'react-router-dom'
import './styles.scss'

const providerRestrictions = ({ memberLookup }) => {
    const { member_details, restriction_details: { edits = [] } = {} } = memberLookup
    const headers = ['id', 'name', 'id_type', 'edit_type', 'ndc', 'gpi', 'effective_start_date', 'effective_end_date', 'chaincode', 'res_id']
    const cellRendererParams = {
        id: {
            overrideHeader: 'Provider Id',
        },
        name: {
            overrideHeader: 'Provider Name',
        },
        id_type: {
            overrideHeader: 'Provider Type',
        },
        edit_type: {
            overrideHeader: 'Restriction Type',
        },
        ndc: {
            overrideHeader: 'NDC'
        },
        gpi: {
            overrideHeader: 'GPI'
        },
        chaincode: {
            overrideHeader: 'Chain Code'
        },
        res_id: {
            overrideHeader: 'Restriction ID'
        }
    }
    return (
        <div className="section" id="providerRestrictions">
            <div className="section-header">
                <h2>Provider Restrictions</h2>
            </div>
            <div className='content'>
                <FliptGrid
                    cellRendererParams={cellRendererParams}
                    data={edits}
                    headers={headers}
                />
            </div>
            <div className='buttonContainer'>
                <Link to={`/new-restrictions?flipt_person_id=${member_details?.flipt_person_id || ''}`}>
                    <FliptButton
                        name="Add New Restriction"
                        className="primary"
                    />
                </Link>
            </div>
        </div>
    )
}

export default providerRestrictions