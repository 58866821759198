import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import FliptButton from '../../../../../components/form/fliptButton'
import FliptInput from '../../../../../components/form/fliptInput'

import MedicareDetails from '../medicareDetails';

export default ({ data }) => {
  const [activeDeductable, setActiveDeductable] = useState(true);

  const { member_details, deductible_details, plan_details, benefit_details } = data
  const {
    family_deductible,
    family_out_of_pocket,
    individual_deductible,
    individual_out_of_pocket,
    family_deductible_met,
    individual_deductible_met,
    accumulation_total = [],
  } = deductible_details
  const {
    coverage_tier_name,
  } = plan_details.current_plan || {}
  const isIndividualPlan = coverage_tier_name === 'Individual';
  const [accumulation = {}, ...other] = accumulation_total
  const { medicare_accumulation = [] } = accumulation
  const isMedicare = benefit_details?.lob?.toLowerCase().includes('medicare')

  const checkColor = () => {
    return '#FB0505';
  }

  const filter_member_accum_based_on_current_plan = (medicare_accumulation, plan_name) => {
    return medicare_accumulation.filter(obj => obj?.plan_name?.toUpperCase() === plan_name?.toUpperCase());
  }
  return (

    <div className="section">
      <div className="section-header" style={{ position: 'relative' }}>
        {isMedicare ? <h2 style={{ backgroundColor: checkColor() }} >Medicare Part D Accums</h2>
          : <h2 style={{ backgroundColor: checkColor() }} >Accumulation Details</h2>}
      </div>
      {/* <div className="section-header fields-container" onClick={() => setActiveDeductable(true)} style={{ marginLeft: 10, paddingTop: 10 }}>
        <h2>Deductible Details</h2>
      </div> */}
      {
        !isMedicare ?
          <>
            <div className="section-contents">
              <div className="fields-container">
                <div className="horizontal-fields">
                  <FliptInput value={individual_deductible && `$${parseFloat(individual_deductible).toFixed(2)}`}
                    label="Individual Deductible Accrued" disabled stylized />
                  <FliptInput value={individual_out_of_pocket && `$${parseFloat(individual_out_of_pocket).toFixed(2)}`} label="Individual Out of Pocket Accrued" disabled stylized />
                  <FliptInput value={individual_deductible_met} label="Individual Deductable Met" disabled stylized />
                </div>
              </div>
              {!isIndividualPlan && (
                <div className="fields-container">
                  <div className="horizontal-fields">
                    <FliptInput value={family_deductible && `$${parseFloat(family_deductible).toFixed(2)}`} label="Family Deductible Accrued" disabled stylized />
                    <FliptInput value={family_out_of_pocket && `$${parseFloat(family_out_of_pocket).toFixed(2)}`} label="Family Out of Pocket Accrued" disabled stylized />
                    <FliptInput value={family_deductible_met} label="Family Deductable Met" disabled stylized />
                  </div>
                </div>
              )}
            </div>
            <div className="button-container">
              <Link to={`/accumulation-details/?flipt_person_id=${member_details.flipt_person_id}`}>
                <FliptButton name="Deductible Details" className="primary" />
              </Link>
            </div>
          </> : <MedicareDetails data={filter_member_accum_based_on_current_plan(medicare_accumulation, plan_details.current_plan.plan_name) ? filter_member_accum_based_on_current_plan(medicare_accumulation, plan_details.current_plan.plan_name) : []} member_details={member_details} />
      }
    </div >

  )
}
