import React, {useState} from 'react'
import FliptInput from '../../../../components/form/fliptInput'
import FliptDropdown from '../../../../components/form/fliptDropdown'

import _ from 'lodash'
import './styles.scss'
const utilizationManagement = (props) => {
  const { form, updateFields } = props
  const { clinical_pa, pa_reject_message } = form
  const rejectCodeOptions = ["75", "3W"]
  const rejectMessage = {
    '75': 'Prior Authorization Required',
    '3W': 'Prior Authorization in Process'
  }

  const getDropdownOptions = (options) => options.map(option => ({ text: option, value: option }))
  const onRejectCodeChange = (e, dropdown, rowIndex) => {
    const { name, value } = dropdown || e.currentTarget
    updateFields(e, { name, value })
    updateFields(e, { name: 'pa_reject_message', value:rejectMessage[value] })
}


  return <div id="applicationOnly">
            <div className='applicationContainer'>
            {!_.isEmpty(clinical_pa) && (
              <>
            <FliptDropdown
              clearable={false}
              description="..."
              disabled={false}
              key='pa_reject_code'
              label='PA Reject Code'
              multiple={false}
              name='pa_reject_code'
              onChange={onRejectCodeChange}
              options={getDropdownOptions(rejectCodeOptions)}
              option_save_keys='pa_reject_code'
              value={form?.pa_reject_code || ''}
              scrolling
              search
              selection
              selectOnBlur={false}
              stylized='true'
            />
            <FliptInput
                description="..."
                disabled={false}
                key='pa_reject_message'
                label='PA Reject Message'
                name='pa_reject_message'
                required={false}
                value={pa_reject_message || ''}
                stylized="true"
              />
            </>)}
            </div>
  </div>
}

export default utilizationManagement