import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Icon } from 'semantic-ui-react'

import { Creators as PlanManagementActions } from '../../../../redux/reducers/api/planManagement'
import { Creators as AppActions } from '../../../../redux/reducers/app'

import './styles.scss'

import BreadCrumb from '../../../../components/breadCrumb'
import CAGFilters from '../../../../components/CAGFilters'

import PlanDetails from '../planDetails'
import sectionMappings from '../planDetails/sectionMappings'

import { convertStringToSnakeCase, parseQueryString, createQueryString } from '../../../../utils/utilities'
import * as _ from 'lodash'
import { medicareDTabExclusion, medicareDTabInclusion } from './medicareDTabs'
import ApprovalOptions from '../../../../components/approvalOptions'

class CreatePlan extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTabIndex: 0,
      customFormData: [],
      filterOptions: {},
      customPlanDetails: null,
      hierarchyIDs: null,
    }
  }

  componentDidMount() {
    const { actions, history } = this.props
    actions.clearPlanDetailsFilters()
    if (history.location.search) {
      const { plan_id, plan_name, plan_version, lob, approval_doc_id, status } = parseQueryString(history.location.search)
      actions.setPlanID({ data: plan_id })
      actions.setPlanVersion({ data: plan_version })
      actions.setPlanStatus({ data: status })
      actions.setApprovalDocID({ data: approval_doc_id })
      actions.setPlanName({ data: plan_name })
      actions.setPlanLOB({ data: lob })
    }
    this._getPlanOnceMenuDataIsFetched()
  }

  _getPlanOnceMenuDataIsFetched = () => {
    const interval = setInterval(() => {
      const { actions, state: { app } } = this.props
      if (app && app.menu_data && app.menu_data.length) {
        // call add new plan action here
        actions.getPlan()
        clearInterval(interval)
      }
    }, 250)
  }

  onFilterChange = (e, dropdown) => {
    const { actions } = this.props
    const { name, value } = dropdown
    const data = dropdown?.options?.find(x => x.value === value)
    actions.setFilters({
      data: {
        filterName: name,
        filterValue: data ?? value,
      },
    })
    this.setState({ hierarchyIDs: data.path })
  }

  _addUpdatedByAndSave = (formData) => {
    const { actions, history, state: { currentSection } } = this.props
    const { customFormData } = this.state

    actions.savePlan({ formData: { ...formData }, customFormData: { ...customFormData } })

    if (currentSection === 'plan_design_details')
      history.location.search = createQueryString(customFormData)
  }

  _validatePlanHierarchy = (hierarchy, hierarchyIDs) => {
    if (!hierarchy) return false
    const topLevel = hierarchy[0]
    const orgLevel = topLevel?.children
    const clientLevel = orgLevel?.[0]?.children
    const carrierLevel = clientLevel?.[0]?.children
    const accountLevel = carrierLevel?.[0]?.children
    const groupLevel = accountLevel?.[0]?.children
    if (carrierLevel?.length != 1 || accountLevel?.length != 1 || groupLevel?.length != 1) return false
    const carrier = carrierLevel?.[0]?.carrier_id ?? carrierLevel?.[0]?.name
    const account = accountLevel?.[0]?.account_id ?? accountLevel?.[0]?.name
    const group = groupLevel?.[0]?.group_id ?? groupLevel?.[0]?.name

    if (!(hierarchyIDs && carrier && account && group)) return false
    return { carrier, account, group }
  }

  getSections = () => {
    const { state, history } = this.props
    const { sections, planDetails, planLOBState } = state
    const allSections = (sections && (sections?.length || 0) > 0) ? sections : Object.keys(planDetails?.details || {})
    let planLob = planDetails?.model_data?.lob || ''
    if (history.location.search) {
      const { lob } = parseQueryString(history.location.search)
      planLob = lob
    } else if (planLOBState) planLob = planLOBState
    const filterSections = allSections.filter((section) => {
      /*if (planDetails?.model_data?.lob?.startsWith('Medicare D') && medicareDTabInclusion.includes(section)) {
        return false
      }*/
      if (!planLob?.startsWith('Medicare D') && medicareDTabExclusion.includes(section)) {
        return false
      }
      return true;
    })
    return filterSections;
  }


  getPlanDetails = () => {
    const { state } = this.props
    const { planDetails } = state
    const sections = this.getSections();
    if (!planDetails?.details) {
      return planDetails
    }

    const filteredPlanDetails = Object.entries(planDetails.details).filter(key => sections.includes(key[0]));
    return { ...planDetails, details: Object.fromEntries(filteredPlanDetails) }
  }


  copyFormData = (customData) => {
    this.setState({
      customFormData: customData,
    })
  }

  handleBackClick = () => {
    const { actions } = this.props
    actions.clearPlanDetailsFilters()
    actions.clearPlanDesignData()
  }

  onNext = () => {
    const { state, actions } = this.props
    const {
      currentSection,
    } = state
    const sections = this.getSections()
    const currIndex = sections.findIndex((section) => section === currentSection)
    if (currIndex < sections.length - 1) {
      const nextIndex = currIndex + 1
      actions.getPlan(sections[nextIndex])
      this.setState({
        activeTabIndex: nextIndex,
      })
    }
  }

  onPrev = () => {
    const { state, actions } = this.props
    const { currentSection } = state
    const sections = this.getSections()
    const currIndex = sections.findIndex((section) => section === currentSection)
    if (currIndex > 0) {
      const prevIndex = currIndex - 1
      actions.getPlan(sections[prevIndex])
      this.setState({
        activeTabIndex: prevIndex,
      })
    }
  }

  getTabContents = (tabTitle) => {
    const { state, actions } = this.props
    const sections = this.getSections()
    let payload = tabTitle
    const currIndex = sections.findIndex((section) => {
      if (section in sectionMappings && sectionMappings[section] === tabTitle.toUpperCase()) {
        payload = section
        return sectionMappings[section] === tabTitle.toUpperCase()
      }
      return section === convertStringToSnakeCase(tabTitle.toLowerCase())
    })
    this.setState({
      activeTabIndex: currIndex,
    })
    actions.getPlan(payload)
  }

  setFilterOptions = (ele) => {
    const { filterOptions } = this.state
    if (ele && !_.isEqual(ele, filterOptions))
      this.setState({ filterOptions: { ...ele } })
  }

  componentDidUpdate(prevProps) {
    const { state: { planDetails, currentSection } } = this.props
    if (planDetails !== prevProps.state.planDetails) {
      const customPlanDetails = this.getPlanDetails();
      if (currentSection === 'deductibles' && planDetails?.model_data?.lob?.startsWith('Medicare D')) { // Medicare Changes
        const details = customPlanDetails.details.deductibles.fields.filter(x => x.type === "SAVE_FIELDS" || x.type === "CUSTOM")
        customPlanDetails.details.deductibles.fields = details
      }
      this.setState({ customPlanDetails })
    }

  }

  approvalOptionsCallback = () => {
    const { actions, state: { app } } = this.props
    actions.getPlan()
  }

  render() {
    const { state, state: { planDetails = {}, hierarchyData = {}, approvalDocID } } = this.props
    let { history: { location: { state: { editMode = false } } } } = this.props
    const {
      selectedCarrier,
      selectedAccount,
      selectedGroup,
    } = state

    if (planDetails?.model_data?.hideHierarchy) editMode = false
    const { hierarchy, hierarchyIDs } = hierarchyData
    const { activeTabIndex, filterOptions, customPlanDetails } = this.state
    let saveButtonDisabled = false
    let saveButtonDisabledMessage = ''
    // if (!this._validatePlanHierarchy(hierarchy, hierarchyIDs)) {
    //   saveButtonDisabled = true
    //   saveButtonDisabledMessage = 'Plan Design Details and Hierarchy must be defined in order to save a draft plan.'
    // }
    const docData = {
      doc_id: approvalDocID,
      doc_name: planDetails?.model_data?.plan_name,
      doc_version: planDetails?.model_data?.plan_version,
      status: planDetails?.model_data?.status,
      module: 'BENEFIT_PLAN_DOC',
    }
    return (
      <div id="createPlan">
        <div className="breadCrumbContainer"><BreadCrumb {...this.props} /></div>
        <div className="header">
          Create New Plan
          <Link to={{ pathname: '/plan-design-management' }} onClick={this.handleBackClick}>
            <div className="new-plan" aria-hidden="true">
              <Icon name="chevron circle left" size="tiny" />
              Back
            </div>
          </Link>
          {<ApprovalOptions docData={docData} actionCallback={this.approvalOptionsCallback} />}
        </div>
        {/* <CAGFilters selectedCarrier={selectedCarrier} setFilterOptions={this.setFilterOptions} selectedAccount={selectedAccount} selectedGroup={selectedGroup} onFilterChange={this.onFilterChange} isUpdatedHierarchy={typeof selectedCarrier === 'object'} /> */}
        {customPlanDetails && (
          <PlanDetails
            planDetails={customPlanDetails}
            context={{
              groupOptions: filterOptions?.groupOptions?.map(e => {
                e.display_name = e.value
                return e
              }) || {}, cagValues: { carrier: selectedAccount, account: selectedAccount, group: selectedGroup }
            }}
            cancelPath="/plan-design-management"
            editMode={editMode}
            activeTabIndex={activeTabIndex}
            getTabContents={this.getTabContents}
            onSave={(formData) => this._addUpdatedByAndSave(formData)}
            copyFormData={(customData) => this.copyFormData(customData)}
            customData={this.customFormData}
            onNext={this.onNext}
            onPrev={this.onPrev}
            saveButtonParams={{
              disabled: saveButtonDisabled,
              disabledMessage: saveButtonDisabledMessage,
            }}

          />
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  state: {
    app: state.app,
    approvalDocID: state.planManagement.approvalDocID,
    planLOBState: state.planManagement.planLOB,
    planDesignData: state.planManagement.planDesignData,
    hierarchyData: state.planManagement.hierarchyData,
    selectedCarrier: state.planManagement.planDetailsFilters.carrier ?? 'ALL',
    selectedAccount: state.planManagement.planDetailsFilters.account ?? null,
    selectedGroup: state.planManagement.planDetailsFilters.group ?? null,
    planDetails: state.planManagement.planDetails,
    sections: state.planManagement.sections,
    currentSection: state.planManagement.currentSection,
  },
})
const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
    ...PlanManagementActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreatePlan)