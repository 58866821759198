import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as _ from "lodash";
import Config from "../../../../config";
import FliptGrid from "../../../../components/fliptGrid";
import BreadCrumb from "../../../../components/breadCrumb";
import { Creators as rebateManagementActions } from "../../../../redux/reducers/api/rebateManagement";
import { Creators as NetworkCreationActions } from "../../../../redux/reducers/api/networkCreationManagement";
import { Creators as AppActions } from "../../../../redux/reducers/app";
import { parseQueryString } from "../../../../utils/utilities";
import { headers } from "./header";
import "../styles.scss";

const DrugListView = (props) => {
  const { state, actions, history } = props;
  const { location = {} } = history || {};
  const { drugListData: dat } = state;
  const [data, setData] = useState([]);
  const cellRendererParams = {
 
    drug_name: {
      overrideHeader: "Drug Name"
    },
    gpi: {
      overrideHeader: "GPI"
    },
    gppc: {
      overrideHeader: "GPPC"
    },
    ndc: {
      overrideHeader: "NDC"
    },
    strengths: {
      overrideHeader: "Strength"
    },
    multi_source: {
      overrideHeader: "MultiSource"
    },
    brand_generic: {
      overrideHeader: "Brand / Generic Indicator"
    },
    rebate_factor: {
      overrideHeader: "Rebate Factor"
    },
    rebate_type: {
      overrideHeader: "Rebate Factor(Flat or %)"
    }
  };
  
   

 

  useEffect(() => {
    if (dat && !_.isEqual(data, dat)) {
      setData(dat);
    }
      return () => setData([])
  }, [dat]);

  useEffect(() => {
    const { actions } = props;
    const { search } = location; 
    
    let qs = { x_id: "create" };
    if (search) qs = parseQueryString(search);
    setTimeout(() => actions.getRebateData(qs), Config.COMPONENT_DATA_LOAD_TIME)
    return(() => setData([]))
  }, []);
  return (
    <div id="durSummary">
      <div className="heading">
        <BreadCrumb {...props} />
        <div className="header">
          Drug List
          
        </div>
      </div>
      <div className="content maxHeight">
        <FliptGrid
          data={data}
          headers={headers}
          cellRendererParams={cellRendererParams}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  state: {
    drugListData: state.rebateManagement.drugListData,
    allNetworkData: state.networkTierCreation.allNetworkTiersData
  }
});

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...rebateManagementActions,
    ...NetworkCreationActions,
    ...AppActions
  };

  return {
    actions: bindActionCreators(allActions, dispatch)
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DrugListView);
