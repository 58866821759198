export const NEW_STR = 'NEW'
export const IN_PROCESS_STR = 'IN PROCESS'
export const RFI_STR = 'RFI'
export const VOID_CLOSE_STR = 'VOID - CLOSE'
export const HOLD_PENDING_STR = 'HOLD - PENDING'
export const ERROR_STR = 'ERROR'

export const DOCUMENT_CREATION_VOID_CLOSE_STR = 'DOCUMENT CREATION - VOID - CLOSE'
export const OUTBOUND_COMMUNICATION_VOID_CLOSE_STR = 'OUTBOUND COMMUNICATION - VOID - CLOSE'
export const CLOSED_VOID_CLOSE_STR = 'CLOSED - VOID - CLOSE'

export const APPROVED_STR = 'APPROVED'
export const DOCUMENT_CREATION_APPROVED_STR = 'DOCUMENT CREATION - APPROVED'
export const OUTBOUND_COMMUNICATION_APPROVED_STR = 'OUTBOUND COMMUNICATION - APPROVED'
export const CLOSED_APPROVED_STR = 'CLOSED - APPROVED'

export const DENIED_STR = 'DENIED'
export const DOCUMENT_CREATION_DENIED_STR = 'DOCUMENT CREATION - DENIED'
export const OUTBOUND_COMMUNICATION_DENIED_STR = 'OUTBOUND COMMUNICATION - DENIED'
export const CLOSED_DENIED_STR = 'CLOSED - DENIED'

export const REOPEN_APPEAL_1_STR = 'REOPEN - APPEAL 1'
export const IN_PROCESS_APPEAL_1_STR = 'IN PROCESS - APPEAL 1'
export const RFI_APPEAL_1_STR = 'RFI - APPEAL 1'
export const APPROVED_APPEAL_1_STR = 'APPROVED - APPEAL 1'
export const DOCUMENT_CREATION_APPROVED_APPEAL_1_STR = 'DOCUMENT CREATION - APPROVED - APPEAL 1'
export const OUTBOUND_COMMUNICATION_APPROVED_APPEAL_1_STR = 'OUTBOUND COMMUNICATION - APPROVED - APPEAL 1'
export const DENIED_APPEAL_1_STR = 'DENIED - APPEAL 1'
export const DOCUMENT_CREATION_DENIED_APPEAL_1_STR = 'DOCUMENT CREATION - DENIED - APPEAL 1'
export const OUTBOUND_COMMUNICATION_DENIED_APPEAL_1_STR = 'OUTBOUND COMMUNICATION - DENIED - APPEAL 1'
export const CLOSED_APPEAL_APPROVED_1_STR = 'CLOSED - APPEAL APPROVED'
export const CLOSED_DENIED_UPHELD_1_STR = 'CLOSED - DENIED UPHELD'

export const REOPEN_APPEAL_2_STR = 'REOPEN - APPEAL 2'
export const IN_PROCESS_APPEAL_2_STR = 'IN PROCESS - APPEAL 2'
export const RFI_APPEAL_2_STR = 'RFI - APPEAL 2'
export const APPROVED_APPEAL_2_STR = 'APPROVED - APPEAL 2'
export const DOCUMENT_CREATION_APPROVED_APPEAL_2_STR = 'DOCUMENT CREATION - APPROVED - APPEAL 2'
export const OUTBOUND_COMMUNICATION_APPROVED_APPEAL_2_STR = 'OUTBOUND COMMUNICATION - APPROVED - APPEAL 2'
export const DENIED_APPEAL_2_STR = 'DENIED - APPEAL 2'
export const DOCUMENT_CREATION_DENIED_APPEAL_2_STR = 'DOCUMENT CREATION - DENIED - APPEAL 2'
export const OUTBOUND_COMMUNICATION_DENIED_APPEAL_2_STR = 'OUTBOUND COMMUNICATION - DENIED - APPEAL 2'
export const CLOSED_APPEAL_APPROVED_2_STR = 'CLOSED - APPEAL APPROVED 2'
export const CLOSED_DENIED_UPHELD_2_STR = 'CLOSED - DENIED UPHELD 2'

// ePA errors
export const OPEN_OUTBOUND_ERROR_STR = 'OPEN - OUTBOUND ERROR'
export const IN_PROCESS_OUTBOUND_ERROR_STR = 'IN PROCESS - OUTBOUND ERROR'
export const RFI_OUTBOUND_ERROR_STR = 'RFI - OUTBOUND ERROR'
export const CLOSED_OUTBOUND_ERROR_STR = 'CLOSED - OUTBOUND ERROR'
export const APPROVED_OUTBOUND_ERROR_STR = 'APPROVED - OUTBOUND ERROR'
export const DENIED_OUTBOUND_ERROR_STR = 'DENIED - OUTBOUND ERROR'
export const VOID_OUTBOUND_ERROR_STR = 'VOID - OUTBOUND ERROR'
export const CANCELLED_OUTBOUND_ERROR_STR = 'CANCELLED - OUTBOUND ERROR'
export const CLOSED_ERROR_RESOLVED_STR = 'CLOSED - ERROR RESOLVED'
export const CLOSED_ERROR_UNRESOLVED_STR = 'CLOSED - ERROR UNRESOLVED'
export const DATA_ERROR_STR = 'DATA_ERROR'

export const VIEW_QUESTIONNAIRE_STR = 'View Questionnaire'
export const VIEW_COMPLETED_QUESTIONNAIRE_STR = 'View Completed Questionnaire'

export const VIEW_QUESTIONNAIRE_OPTION = { key: '1', value: VIEW_QUESTIONNAIRE_STR, text: VIEW_QUESTIONNAIRE_STR }
export const VIEW_COMPLETED_QUESTIONNAIRE_OPTION = { key: '2', value: VIEW_COMPLETED_QUESTIONNAIRE_STR, text: VIEW_COMPLETED_QUESTIONNAIRE_STR }
export const RFI_OPTION = { key: '3', value: RFI_STR, text: 'Request For Information' }
export const HOLD_PENDING_OPTION = { key: '4', value: HOLD_PENDING_STR, text: 'Hold/Pending' }
export const DENIED_OPTION = { key: '5', value: DENIED_STR, text: 'Pharmacist Denial' }
export const APPROVED_OPTION = { key: '6', value: APPROVED_STR, text: 'Approve' }
export const VOID_CLOSE_OPTION = { key: '7', value: VOID_CLOSE_STR, text: 'Void - Close' }
export const REOPEN_APPEAL_1_OPTION = { key: '8', value: REOPEN_APPEAL_1_STR, text: 'Reopen' }
export const RFI_APPEAL_1_OPTION = { key: '9', value: RFI_APPEAL_1_STR, text: 'Request For Information' }
export const APPROVED_APPEAL_1_OPTION = { key: '10', value: APPROVED_APPEAL_1_STR, text: 'Approve' }
export const DENIED_APPEAL_1_OPTION = { key: '11', value: DENIED_APPEAL_1_STR, text: 'Deny' }
export const CLOSED_APPEAL_APPROVED_1_OPTION = { key: '12', value: CLOSED_APPEAL_APPROVED_1_STR, text: 'Close - Approved' }
export const CLOSED_DENIED_UPHELD_1_OPTION = { key: '13', value: CLOSED_DENIED_UPHELD_1_STR, text: 'Close - Denied' }
export const REOPEN_APPEAL_2_OPTION = { key: '14', value: REOPEN_APPEAL_2_STR, text: 'Reopen' }
export const RFI_APPEAL_2_OPTION = { key: '15', value: RFI_APPEAL_2_STR, text: 'Request For Information' }
export const APPROVED_APPEAL_2_OPTION = { key: '16', value: APPROVED_APPEAL_2_STR, text: 'Approve' }
export const DENIED_APPEAL_2_OPTION = { key: '17', value: DENIED_APPEAL_2_STR, text: 'Deny' }
export const CLOSED_APPEAL_APPROVED_2_OPTION = { key: '18', value: CLOSED_APPEAL_APPROVED_2_STR, text: 'Close - Approved' }
export const CLOSED_DENIED_UPHELD_2_OPTION = { key: '19', value: CLOSED_DENIED_UPHELD_2_STR, text: 'Close - Denied' }
export const CLOSED_APPROVED_OPTION = { key: '20', value: CLOSED_APPROVED_STR, text: 'Close - Approved' }
export const CLOSED_DENIED_OPTION = { key: '21', value: CLOSED_DENIED_STR, text: 'Close - Denied' }
export const CLOSED_VOID_CLOSE_OPTION = { key: '22', value: CLOSED_VOID_CLOSE_STR, text: 'Close - Void/Close' }
export const IN_PROCESS_OPTION = { key: '23', value: IN_PROCESS_STR, text: 'In Process' }
export const IN_PROCESS_APPEAL_1_OPTION = { key: '24', value: IN_PROCESS_APPEAL_1_STR, text: 'In Process' }
export const IN_PROCESS_APPEAL_2_OPTION = { key: '25', value: IN_PROCESS_APPEAL_2_STR, text: 'In Process' }
export const NEW_OPTION = { key: '26', value: NEW_STR, text: 'New' }
export const CLOSED_ERROR_RESOLVED_OPTION = { key: '27', value: CLOSED_ERROR_RESOLVED_STR, text: 'Close - Error Resolved' }
export const CLOSED_ERROR_UNRESOLVED_OPTION = { key: '28', value: CLOSED_ERROR_UNRESOLVED_STR, text: 'Close - Error Unresolved' }


export const TEST_CLAIM_VISIBLE_STATUSES = [
  APPROVED_STR,
  APPROVED_APPEAL_1_STR,
  APPROVED_APPEAL_2_STR,
  OUTBOUND_COMMUNICATION_APPROVED_STR,
  OUTBOUND_COMMUNICATION_APPROVED_APPEAL_1_STR,
  OUTBOUND_COMMUNICATION_APPROVED_APPEAL_2_STR,
  DOCUMENT_CREATION_APPROVED_STR,
  DOCUMENT_CREATION_APPROVED_APPEAL_1_STR,
  DOCUMENT_CREATION_APPROVED_APPEAL_2_STR,
  CLOSED_APPROVED_STR,
  CLOSED_APPEAL_APPROVED_1_STR,
  CLOSED_APPEAL_APPROVED_2_STR
]

export const NOT_STATUS_STRS = [
  VIEW_COMPLETED_QUESTIONNAIRE_STR,
  VIEW_QUESTIONNAIRE_STR
]

// DO NOT USE without updating - not up to date
// not being used right now
// in case we need it to filter 'Action' dropdown
// in PACaseDashboard
export const STATUSES_UPDATABLE_FROM_DROPDOWN = [
  IN_PROCESS_STR,
  IN_PROCESS_APPEAL_1_STR,
  IN_PROCESS_APPEAL_2_STR,
  HOLD_PENDING_STR,
  REOPEN_APPEAL_1_STR,
  REOPEN_APPEAL_2_STR,
  CLOSED_APPROVED_STR,
  CLOSED_APPEAL_APPROVED_1_STR,
  CLOSED_APPEAL_APPROVED_2_STR,
  CLOSED_DENIED_STR,
  CLOSED_DENIED_UPHELD_1_STR,
  CLOSED_DENIED_UPHELD_2_STR
]

export const SURESCRIPTS_STR = 'SURESCRIPTS'

// document generation statuses
export const QUEUE_SUCCESS_STR = 'SUCCESS'
export const QUEUE_IN_PROGRESS_STR = 'IN PROGRESS'
export const QUEUE_FAILED_STR = 'FAILED'

// fax statuses
export const FAX_SUCCESS_STR = 'SUCCESS'
export const FAX_IN_PROGRESS_STR = 'IN PROGRESS'
export const FAX_FAILED_STR = 'FAILED'

// pa case statuses
export const PA_STATUS_STR = 'pa'
export const QL_STATUS_STR = 'ql'
export const NC_STATUS_STR = 'nc'
export const BMN_STATUS_STR = 'bmn'

// communication type constants
export const FAX_STR = 'Fax'
export const NO_COMMUNICATION_STR = 'No Communication'
export const EPA_STR = 'ePA'

export const communicationTypeOptions = [
  { key: '1', value: FAX_STR, text: FAX_STR },
  { key: '2', value: NO_COMMUNICATION_STR, text: NO_COMMUNICATION_STR },
]
