export const defaultTabConfigs = [
    {
        tabName: 'Contract Management',
        id: 'contract_management',
    },
    {
        tabName: 'Processors',
        id: 'processors',
    }
]

export const cmHeaders = [
    'action',
    'doc_name',
    'contract_type',
    'status',
    'doc_version',
    'effective_start_date',
    'effective_end_date',
]
export const processorHeaders = ['action', 'processor_name', 'processor_type', 'doc_version', 'bin', 'pcn', 'status', 'processor_start_date', 'processor_end_date']

export const CM_LOCAL_STORAGE_KEY = 'cmTabs'

export const editProcessorHeader = ['action', 'bin', 'pcn', 'effective_start_date', 'effective_end_date']
export const processorType = [
    {
        key: 0,
        text: 'Direct',
        value: 'direct'
    },
    {
        key: 0,
        text: 'Transfer',
        value: 'transfer'
    }]

