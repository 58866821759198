import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import FliptButton from '../../../../components/v2/fliptButton'
import { Creators as AppActions } from '../../../../redux/reducers/app'
import { Creators as IntegrationManagementActions } from '../../../../redux/reducers/api/integrationManagement'

import { bindActionCreators } from 'redux'

import ManualClaim from './manualClaim'
import UploadFile from './uploadFile'
import ConfigPicker from '../components/configPicker'
import BenefitTestingBed from './fastBenefitTestingBed'
import ProductionClaimSearch from './productionClaimSearch'

import './styles.scss'



function ClaimConfig(props) {
    const [claimGenerationType, setClaimGenerationType] = useState(0);
    const { claimForm, setClaimForm, editable, testBatchConfig } = props;
    const handleClaimGenerationChange = (val) => {
        setClaimGenerationType(val);
    };

    const renderClaimGenerationType = () => {
        switch (claimGenerationType) {
            case 0:
                return <UploadFile {...props}/>
            case 1:
                return <ProductionClaimSearch claimForm={claimForm} setClaimForm={setClaimForm} editable={editable} testBatchConfig={testBatchConfig} />
            case 2:
                return <BenefitTestingBed {...props} />
            case 3:
                return <ManualClaim {...props} />
            default:
                return <></>;
        }
    };

    const configData = [
        { label: 'Upload Claim File', disabled: false },
        { label: 'Search for pre-existing claims', disabled: false },
        { label: 'Create net new claims with test bed', disabled: false },
        { label: 'Create net new claims manually', disabled: false }
    ];
    return (
        <div id="claimConfig">
            {!props.editable ? <FliptButton className="primary" name='Edit' onClick={editDetails} />
                : null}
            <span>How should a Claim be generated for this batch</span>
            <ConfigPicker currentValue={claimGenerationType} 
            configValues={configData}
            handleChange={handleClaimGenerationChange} />

            {renderClaimGenerationType()}
        </div>
    )
}

const mapStateToProps = (state) => {
    return ({
        state: {
            app: state.app,
            plansData: state.claimsProcessingAndReprocessing?.plans || [],
            addMemberData: state.integrationMgmt.addMemberData
        },
    })
}

const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...AppActions,
        ...IntegrationManagementActions
    }

    return {
        actions: bindActionCreators(allActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClaimConfig)
