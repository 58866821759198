export const ORGANIZATION_TABS_LOCAL_STORAGE_KEY = "OrganizationTabs";
export const GROUP_TABS_LOCAL_STORAGE_KEY = "GroupsTabs";
const REQUIRED_TAB = "Required";
const COMPANY_TAB = "Company";
const BILLING_FINANCE_TAB = "Billing & Finance";
const COMMUNICATTION_TAB = "Communication";
const APP_WEB_TAB = "App/Web Settings";
// const REWARDS_PENALTY_TAB = "Rewards & Penalty";
const GLOBAL_EDIT_TAB = "Global Edit";
const SYSTEM_TAB = "System Settings";
const OTHER_SETTING_TAB = "Other Settings";
const POS_REBATE_TAB = "POS Rebate";
const GROUP_TAB = 'Coverage Details';

export const REQUIRED_TAB_ID = "REQUIRED";
export const COMPANY_TAB_ID = "COMPANY";
const BILLING_FINANCE_TAB_ID = "BILLING";
const COMMUNICATTION_TAB_ID = "COMMUNICATION";
const APP_WEB_TAB_ID = "APP/WEB";
// const REWARDS_PENALTY_TAB_ID = "REWARDS";
const GLOBAL_EDIT_TAB_ID = "GLOBAL";
const SYSTEM_TAB_ID = "SYSTEM";
const OTHER_SETTING_TAB_ID = "OTHER";
const GROUP_TAB_ID = 'GROUP';

export const organizationTabConfigs = [
  {
    tabName: REQUIRED_TAB,
    id: REQUIRED_TAB_ID,
  },
  {
    tabName: COMPANY_TAB,
    id: COMPANY_TAB_ID,
  },
  {
    tabName: BILLING_FINANCE_TAB,
    id: BILLING_FINANCE_TAB_ID,
  },
  {
    tabName: COMMUNICATTION_TAB,
    id: COMMUNICATTION_TAB_ID,
  },
  {
    tabName: APP_WEB_TAB,
    id: APP_WEB_TAB_ID,
  },
  // {
  //   tabName: REWARDS_PENALTY_TAB,
  //   id: REWARDS_PENALTY_TAB_ID,
  // },

  {
    tabName: SYSTEM_TAB,
    id: SYSTEM_TAB_ID,
  },
  {
    tabName: POS_REBATE_TAB,
    id: POS_REBATE_TAB,
  },
  {
    tabName: OTHER_SETTING_TAB,
    id: OTHER_SETTING_TAB_ID,
  },
];


const groupDefaultTabConfigs = [
  {
    tabName: GROUP_TAB,
    id: GROUP_TAB_ID,
  },
  ...organizationTabConfigs,
]

export const groupLevelTab =() =>{

  groupDefaultTabConfigs.sort((a, b) => {
    if (a.tabName === "Required") return -1;
    return 0;
});
return groupDefaultTabConfigs
}