import React from 'react';
import { Icon, Popup } from 'semantic-ui-react';
import FliptInput from '../form/fliptInput';
import './styles.scss';

function Info(props) {
    const { content, position } = props;
    return (
        <Popup
            trigger={<Icon className='tooltip-icon' name='question circle outline' />}
            content={content}
            position={position}
        />
    );
};

function ToolTipInput(props) {
    return (
        <section id="flipt-tooltip-input" className='tooltip-input-wrapper'>
            <article className='tooltip-info-container'>
                <span>
                    {props.label}
                </span>
                <Info
                    className='tooltip'
                    content={props.tooltip}
                />
            </article>
            <FliptInput
                className='tooltip-input'
                {...props}
            />
        </section >
    )
}

export default ToolTipInput;