import Immutable from 'seamless-immutable'
import { createActions, createReducer } from 'reduxsauce'

export const INITIAL_STATE = Immutable({
    loading: false,
    questionnaireData: [],
    createQuestionnaireData: {},
    questionFinalData: [],
    drugsFinalData: [],
    questionnaireErrorData: {}
})

export const setQuestionnaireDataReducer = (state = INITIAL_STATE, { payload }) => ({
    ...state,
    questionnaireData: payload,
})

export const clearQuestionnaireDataReducer = (state = INITIAL_STATE, { payload }) => ({
    ...state,
    questionnaireData: []
})

export const setCreateQuestionnaireDataReducer = (state = INITIAL_STATE, { payload }) => ({
    ...state,
    createQuestionnaireData: payload,
})

export const setQuestionFinalDataReducer = (state = INITIAL_STATE, { payload }) => ({
    ...state,
    questionFinalData: payload,
})
export const setDrugFinalDataReducer = (state = INITIAL_STATE, { payload }) => ({
    ...state,
    drugsFinalData: payload,
})

export const clearFinalDataReducer = (state = INITIAL_STATE) => ({
    ...state,
    questionFinalData: [],
    drugsFinalData: [],
})

export const setQuestionnaireImportDataReducer = (state = INITIAL_STATE, { payload }) => ({
    ...state,
    questionnaireImportData: payload,
})

export const setQuestionnaireErrorDataReducer = (state = INITIAL_STATE, { payload }) => ({
    ...state,
    questionnaireErrorData: payload,
})

export const clearQuestionnaireErrorDataReducer = (state = INITIAL_STATE, { payload }) => ({
    ...state,
    questionnaireErrorData: {},
})

export const clearQuestionnaireImportDataReducer = (state = INITIAL_STATE, { payload }) => ({
    ...state,
    questionnaireImportData: null,
})

export const { Types, Creators } = createActions({
    createQuestionnaire: ['payload'],
    getQuestionnaireData: ['payload'],
    setQuestionnaireData: ['payload'],
    setCreateQuestionnaireData: ['payload'],
    uploadQuestionnaire: ['payload'],
    setQuestionFinalData: ['payload'],
    setDrugFinalData: ['payload'],
    parseQuestionnaireFile: ['payload'],
    editQuestionnaire: ['payload'],
    clearFinalData: [],
    clearQuestionnaireData: [],
    setQuestionnaireImportData: ['payload'],
    setQuestionnaireErrorData: ['payload'],
    clearQuestionnaireErrorData: [],
    clearQuestionnaireImportData: [],
})

const HANDLERS = {
    [Types.SET_QUESTIONNAIRE_DATA]: setQuestionnaireDataReducer,
    [Types.SET_CREATE_QUESTIONNAIRE_DATA]: setCreateQuestionnaireDataReducer,
    [Types.SET_QUESTION_FINAL_DATA]: setQuestionFinalDataReducer,
    [Types.SET_DRUG_FINAL_DATA]: setDrugFinalDataReducer,
    [Types.CLEAR_FINAL_DATA]: clearFinalDataReducer,
    [Types.CLEAR_QUESTIONNAIRE_DATA]: clearQuestionnaireDataReducer,
    [Types.SET_QUESTIONNAIRE_IMPORT_DATA]: setQuestionnaireImportDataReducer,
    [Types.SET_QUESTIONNAIRE_ERROR_DATA]: setQuestionnaireErrorDataReducer,
    [Types.CLEAR_QUESTIONNAIRE_IMPORT_DATA]: clearQuestionnaireImportDataReducer,
    [Types.CLEAR_QUESTIONNAIRE_ERROR_DATA]: clearQuestionnaireErrorDataReducer,
}

export default createReducer(INITIAL_STATE, HANDLERS)
