import {
    call,
    put,
    select,
    takeLatest,
    take,
} from 'redux-saga/effects'

import { Types, Creators as TimerConfigActions } from '../../reducers/api/timerConfig'
import { Creators as AppActions, Types as AppTypes } from '../../reducers/app'
import { Creators as UserActions, Types as UserTypes } from '../../reducers/user'
import { getAppState, getApiPath, isMenuEmpty, isUserEmpty } from '../../reducers/selectors'
import { fetchPost, fetchGet } from '../../../utils/fetchApi'

export default [
    submitTimerConfigDataWatcher,
    getAllPATimerConfigurationsWatcher,
    getTimerConfigurationWatcher
]

/* WATCHERS */
function* submitTimerConfigDataWatcher() {
    yield takeLatest(Types.SUBMIT_TIMER_CONFIG_DATA, submitTimerConfigDataHandler)
}

function* getTimerConfigurationWatcher() {
    yield takeLatest(Types.GET_TIMER_CONFIGURATION, getTimerConfigurationHandler)
}

function* getAllPATimerConfigurationsWatcher() {
    yield takeLatest(Types.GET_ALL_PA_TIMER_CONFIGURATIONS, getAllPATimerConfigurationsHandler)
}

/* HANDLERS */
function* submitTimerConfigDataHandler({ payload }) {
    try {
        const { serviceUrl } = yield select(getAppState)
        const cd = yield select(getApiPath, 'create-tat-configuration')
        const { api_path } = cd
        const url = `${serviceUrl}${api_path}`

        const { data } = yield call(fetchPost, url, payload)
        if (!data.length) throw Error('Save Timer Configuration Failed')
        yield put(TimerConfigActions.setSaveTimerConfigData(data[0]))
        const transitionalPortal = {
            header: 'Save Timer Configuration Successful',
            copy: data.message,
        }
        yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    } catch (err) {
        console.log('getDrugModuleDataHandler Error >Data ', err)

        const transitionalPortal = {
            header: 'Save Timer Configuration Failed',
            copy: err.message || err,
        }
        yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    }
}

function* getTimerConfigurationHandler({ payload }) {
    try {
        const { serviceUrl } = yield select(getAppState)
        const cd = yield select(getApiPath, 'get-timer-configuration')
        const { api_path } = cd
        const url = `${serviceUrl}${api_path}`

        const { data } = yield call(fetchGet, url, payload)
        if (!data.length) throw Error('GET Timer Configuration Failed')
        yield put(TimerConfigActions.setSaveTimerConfigData(data[0][0]))
        const transitionalPortal = {
            header: 'Get Timer Configuration Successful',
            copy: data.message,
        }
        yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    } catch (err) {
        console.log('getTimerConfiguration Error >Data ', err)

        const transitionalPortal = {
            header: 'Get Timer Configuration Failed',
            copy: err.message || err,
        }
        yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    }
}

function* getAllPATimerConfigurationsHandler({ payload }) {
    try {
        const { serviceUrl } = yield select(getAppState)
        const menuEmpty = yield select(isMenuEmpty)
        if (menuEmpty) {
          yield take([AppTypes.SET_APP_SETTINGS])
        }
        const userEmpty = yield select(isUserEmpty)
        if (userEmpty) {
          yield take([UserTypes.USER_UPDATE])
        }
        const cd = yield select(getApiPath, 'get-all-pa-timer-configurations')
        const { api_path } = cd
        const url = `${serviceUrl}${api_path}`

        const { data } = yield call(fetchGet, url, payload, false)
        if (!data.length) throw Error('Get All PA Timer Configurations Failed')
        yield put(TimerConfigActions.setAllPATimerConfigurationsData(data[0]))
    } catch (err) {
        console.log('getAllPATimerConfigurationsHandler Error >Data ', err)

        const transitionalPortal = {
            header: 'Get All PA Timer Configurations Failed',
            copy: err.message || err,
        }
        yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    }
}