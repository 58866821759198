
import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Creators as PriorAuthorizationCreationActions } from '../../../../redux/reducers/api/priorAuthorizationManagement'

import './styles.scss'
import { Creators as AppActions } from '../../../../redux/reducers/app'
import FliptPaInput from '../../../../components/form/fliptPaInput'
import FliptButton from '../../../../components/form/fliptButton'
import { getAge } from '../../../../utils/utilities'
import FliptRadio from '../../../../components/form/fliptRadio'
import { Form, Icon } from 'semantic-ui-react'
import FliptCheckbox from '../../../../components/form/fliptCheckbox'
import FliptDatePicker from '../../../../components/form/fliptDatePicker'
import FliptPaDropdown from '../../../../components/form/fliptPaDropdown'
import FliptTextarea from '../../../../components/form/fliptTextarea'
import { cloneDeep } from 'lodash'

class QuestionnaireModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            formattedQuesData: [],
            quesCounter: 0,
            previewQuestionnaire: false,
            choiceOuterIndex: null,
            choiceInnerIndex: null,
            errorMessage: null
        }
    }

    componentDidMount() {
        const { quesData, actions, preview } = this.props
        actions.clearSaveAnswerData()
        const formattedQuesData = [];
        quesData?.questionnaire.map((data) => {
            const obj = { ...data }
            if (!preview) obj.answer = ''
            if (obj?.choices?.length) {
                obj.choices = _.chunk(obj.choices, 3)
            }
            if (obj.question_type === 'MULTIPLE CHOICE' && !preview) {
                obj.answer = []
            }
            formattedQuesData.push(obj)
        })
        if (preview) {
            this.setState({ formattedQuesData, previewQuestionnaire: true })
            return
        }
        this.setState({ formattedQuesData })
    }

    componentDidUpdate(prevProps) {
        const { state: { saveAnswerData }, actions } = this.props
        if (prevProps.state.saveAnswerData !== saveAnswerData && saveAnswerData) {
            actions.toggleModal()
        }
    }

    _renderQuestion = () => {
        const {
            formattedQuesData,
            quesCounter,
            errorMessage
        } = this.state
        const { lockCase } = this.props
        return (<div>
            <div className='header'>
                {formattedQuesData[quesCounter].question_text}
            </div>
            {this._renderAnswers(formattedQuesData[quesCounter])}
            <div className='button-container'>
                <FliptButton className='primary' disabled={lockCase} name='OK' onClick={() => this._goToNextQues()} />
            </div>
            {errorMessage ? <div className='error-message'>
                {errorMessage}
            </div> : null}
        </div>)
    }

    _renderQuestionnaire = () => {
        const {
            formattedQuesData,
            previewQuestionnaire
        } = this.state
        const previewData = formattedQuesData?.length && formattedQuesData.map((data, index) => {
            if ((previewQuestionnaire && data?.answer?.length) || (!previewQuestionnaire)) {
                return (<React.Fragment key={index}>
                    <div className='header'>
                        {data.question_text}
                    </div>
                    {this._renderAnswers(data)}
                </React.Fragment>)
            }
        })
        return previewData;
    }

    _previewQuestionnaire = () => {
        const { preview } = this.props
        return (<>
            <div className='header'>
                Preview
            </div>
            <div className='devider' />
            {this._renderQuestionnaire()}
            {!preview && <FliptButton className='primary' name='SAVE' onClick={() => this._saveQuestionnaire()} />}
        </>)
    }

    _saveQuestionnaire = () => {
        const { actions, quesData, pa_case_id } = this.props;
        const { formattedQuesData } = this.state
        const formattedAnswer = []
        formattedQuesData.map((data) => {
            const obj = { ...data }
            obj.choice = cloneDeep(obj.choices.flat())
            delete obj.choices
            formattedAnswer.push(obj);
        })
        const params = {
            pa_case_id: pa_case_id,
            header: {
                questionnaire_id: quesData.questionnaire_id,
                questionnaire_name: quesData.questionnaire_name,
                questionnaire_desc: quesData.questionnaire_description,
                question_set_contact_communication_number: quesData.question_set_contact_communication_number,
            },
            answers: formattedAnswer
        }
        actions.saveAnswer(params)
    }

    _goToNextQues = () => {
        let { quesCounter, formattedQuesData, choiceInnerIndex, choiceOuterIndex } = this.state
        const { quesData } = this.props
        if (!formattedQuesData[quesCounter].answer || !formattedQuesData[quesCounter].answer.length) {
            const header = 'Fields Missing.'
            const message = 'Please fill the Answer to Proceed.'
            this._showTransitionalPortal(header, message)
            return;
        }
        const errorMessage = `Error: The question as defined does not have a valid next question within the ${quesData?.questionnaire_name} questionnaire. Please resolve issue before moving forward.`
        if (quesCounter < formattedQuesData.length - 1) {
            if (formattedQuesData[quesCounter].question_type === 'SELECT'
                && formattedQuesData[quesCounter].choices[choiceOuterIndex][choiceInnerIndex].sequence) {
                const quesIndex = formattedQuesData.findIndex((data) =>
                    data.question_id === formattedQuesData[quesCounter].choices[choiceOuterIndex][choiceInnerIndex].sequence)
                if (quesIndex >= 0) this.setState({ quesCounter: quesIndex, errorMessage: null })
                else {
                    this.setState({ errorMessage })
                }
            } else if (formattedQuesData[quesCounter].question_type === 'MULTIPLE CHOICE'
                && formattedQuesData[quesCounter].choices.length &&
                formattedQuesData[quesCounter].choices[0][0].sequence) {
                const quesIndex = formattedQuesData.findIndex((data) =>
                    data.question_id === formattedQuesData[quesCounter].choices[0][0].sequence)
                if (quesIndex >= 0) this.setState({ quesCounter: quesIndex, errorMessage: null })
                else this.setState({ errorMessage })
            } else {
                quesCounter++
                if (quesCounter >= 0) this.setState({ quesCounter, errorMessage: null })
                else this.setState({ errorMessage })
            }
        } else {
            this.setState({ previewQuestionnaire: true })
        }
    }

    _showTransitionalPortal = (header, message) => {
        const { props } = this;
        const transitionalPortal = {
            header: header,
            copy: message,
        }
        props.actions.displayTransitionalPortal(transitionalPortal)
    }

    _updateSelectAnswer = (value, index, innerIndex) => {
        const {
            formattedQuesData,
            quesCounter
        } = this.state
        formattedQuesData[quesCounter].answer = value
        this.setState({ formattedQuesData, choiceInnerIndex: innerIndex, choiceOuterIndex: index })
    }

    _updateAnswer = (el, dropdown) => {
        const { value } = dropdown || el.currentTarget
        const {
            formattedQuesData,
            quesCounter
        } = this.state
        formattedQuesData[quesCounter].answer = value
        this.setState({ formattedQuesData })
    }

    _updateMultiSelectAnswer = (el, dropdown) => {
        const {
            formattedQuesData,
            quesCounter
        } = this.state
        const { checked, value } = dropdown || el.currentTarget
        if (checked) {
            formattedQuesData[quesCounter].answer.push(value)
        } else {
            const currIndex = formattedQuesData[quesCounter].answer.findIndex((ans) => ans === value)
            if (currIndex >= 0) {
                formattedQuesData[quesCounter].answer.splice(currIndex, 1)
            }
        }
        this.setState({ formattedQuesData })
    }

    _renderAnswers = (currentQues) => {
        const { preview } = this.props
        const { previewQuestionnaire } = this.state

        const dropdownOptions = [{
            key: 0,
            value: 'True',
            text: 'True'

        },
        {
            key: 1,
            value: 'False',
            text: 'False'

        }]
        switch (currentQues.question_type) {
            case 'SELECT': {
                return (<Form>
                    {currentQues?.choices?.map((data, index) => {
                        return (
                            <div className="fields-container" key={index}>
                                {
                                    data?.map((innerData, innerIndex) => {
                                        return (<Form.Field className={innerIndex === 0 ? 'radio-container' : 'radio-container-left'} key={innerIndex}>
                                            <FliptRadio
                                                disabled={previewQuestionnaire}
                                                checked={currentQues.answer === innerData.choice_text}
                                                value={innerData.choice_text}
                                                name='radioGroup'
                                                onChange={(el, { value }) => this._updateSelectAnswer(value, index, innerIndex)}
                                            />
                                            <span className="checkbox-label">{innerData.choice_text}</span>
                                            {innerData.additional_actions === 'Possible Denial' && (previewQuestionnaire || preview) && currentQues.answer === innerData.choice_text
                                                ? <div className='denial-icon-container'><Icon name='exclamation circle' color='red' /></div> : null}
                                        </Form.Field>)
                                    })
                                } </div>)
                    })}
                </Form>)
            }
                break;
            case 'TEXT': {
                return (<div className='answer-container'>
                    <FliptTextarea isPA className='message-area' disabled={previewQuestionnaire} value={currentQues.answer} onChange={this._updateAnswer} />
                </div>)
            }
            case 'MULTIPLE CHOICE': {
                return (<div className='margin-bottom-container'>
                    {currentQues.choices.map((data, index) => {
                        return (
                            <div className="fields-container" key={index}>
                                {
                                    data?.map((obj, innerIndex) => {
                                        return (<div className={innerIndex === 0 ? 'checkbox-container' : 'checkbox-container-left'} key={innerIndex}>
                                            <FliptCheckbox
                                                disabled={previewQuestionnaire}
                                                value={obj.choice_text}
                                                checked={currentQues.answer.includes(obj.choice_text)}
                                                onChange={this._updateMultiSelectAnswer}
                                            />
                                            <span className="checkbox-label">{obj.choice_text}</span>
                                        </div>)
                                    })
                                }
                            </div>
                        )
                    })}
                </div>)
            }
            case 'NUMERIC': {
                return (<div className='answer-container'>
                    <FliptPaInput disabled={previewQuestionnaire} validation='numeric' onChange={this._updateAnswer} value={currentQues.answer} />
                </div>)
            }
            case 'DATE': {
                return (<div className='answer-container'>
                    <FliptDatePicker disabled={previewQuestionnaire} value={currentQues.answer} onChange={this._updateAnswer} />
                </div>)
            }
            case 'TRUE/FALSE': {
                return (<div className='answer-container'>
                    <FliptPaDropdown readOnly={previewQuestionnaire} value={currentQues.answer} stylized name="test"
                        onChange={this._updateAnswer} options={dropdownOptions} label="" />
                </div>)
            }
            default: {
                return (<div className='answer-container'>
                    <FliptPaInput disabled={previewQuestionnaire} value={currentQues.answer} onChange={this._updateAnswer} />
                </div>)
            }
        }

    }

    render() {
        const { planForm, memberDetails } = this.props
        const {
            formattedQuesData,
            previewQuestionnaire
        } = this.state
        return (
            <div id="questionnaireModal">
                {previewQuestionnaire ?
                    <div className='questionnaire-container'>
                        {this._previewQuestionnaire()}
                    </div> : <>
                        <div className='devider' />
                        <div className='header'>
                            Case Information
                        </div>
                        <div className='field-container'>
                            <FliptPaInput label='Case ID' stylized value={planForm.pa_case_id} disabled />
                            <FliptPaInput label="Patient First Name" inputClass='input-class-med' value={memberDetails.first_name} stylized disabled />
                            <FliptPaInput label="Patient Last Name" inputClass='input-class-med' value={memberDetails.last_name} disabled stylized />
                            <FliptPaInput label='Drug Name' disabled inputClass='input-class-med' value={planForm?.medication?.drug_name} stylized />
                            <FliptPaInput label='Patient Age' inputClass='input-class-min' disabled value={getAge(memberDetails.dob)} stylized />
                            <FliptPaInput label='Patient Gender' inputClass='input-class-min' disabled value={memberDetails.gender} stylized />
                        </div>
                        <div className='devider' />
                        {formattedQuesData?.length ? this._renderQuestion() : null}</>}

            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    state: {
        app: state.app,
        saveAnswerData: state.priorAuthorizationCreation.saveAnswerData,
    },
})

const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...AppActions,
        ...PriorAuthorizationCreationActions,
    }

    return {
        actions: bindActionCreators(allActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuestionnaireModal)
