import React, { Component, useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment-timezone'

import './styles.scss'
import BreadCrumb from '../../../../components/breadCrumb'
import FliptInput from '../../../../components/form/fliptPaInput'
import FliptGrid from '../../../../components/fliptGrid'
import { Creators as AppActions } from '../../../../redux/reducers/app'
import { Creators as PharmacyLookupActions } from '../../../../redux/reducers/api/pharmacyLookup'
import { MultiRowRenderer, FeeGridRowTextRenderer } from '../../../../components/fliptGrid/cellRenderers'
import { capitalizeStr } from '../../../../utils/utilities'


function ContractPriceLookup(props) {
    const { location, actions, state: { pharmacyNpiLookupData: { pricing, exclusions }, pharmacyNpiLookupData } } = props
    const exclusionHeader = ['claim_processor', 'network_tier', 'effective_start_date', 'effective_end_date']
    const pricingHeader = ['claim_processor', 'contract_type', 'network_tier', 'price_type', 'discount_type', 'rate_type',
        'discount', 'start_days_supply', 'end_days_supply', 'maclistid', 'floor', 'fees', 'effective_start_date', 'effective_end_date']
    const [pharmacyDetails, setPharmacyDetails] = useState({})
    const [pricingData, setPricingData] = useState([])
    const [exclusionsData, setExclusionsData] = useState([])

    useEffect(() => {
        let tempPricingData = []
        if (pricing?.length) {
            pricing.forEach(element => {
                if (element?.pricing?.length > 1) {
                    element?.pricing.forEach((pricingInnerData) => {
                        tempPricingData.push({
                            ...element,
                            ...pricingInnerData,
                            contract_type: (element?.contract_type || []).map((x) => capitalizeStr(x)),
                            effective_start_date: moment(element.effective_start_date).format('YYYY-MM-DD'),
                            effective_end_date: element.effective_end_date ? moment(element.effective_end_date).format('YYYY-MM-DD') : ''
                        })
                    })
                } else if (pricing?.pricing?.length === 1) tempPricingData.push({
                    ...element,
                    ...element?.pricing[0],
                    contract_type: (element?.contract_type || []).map((x) => capitalizeStr(x)),
                    effective_start_date: moment(element.effective_start_date).format('YYYY-MM-DD'),
                    effective_end_date: element.effective_end_date ? moment(element.effective_end_date).format('YYYY-MM-DD') : ''
                })
            });
        }

        let tempExclusionData = []
        if (exclusions.length) {
            tempExclusionData = exclusions.map((exclusion) => ({
                ...exclusion,
                effective_start_date: moment(exclusion.effective_start_date).format('YYYY-MM-DD'),
                effective_end_date: exclusion.effective_end_date ? moment(exclusion.effective_end_date).format('YYYY-MM-DD') : ''
            }))
        }
        setPricingData(tempPricingData)
        setExclusionsData(tempExclusionData)
    }, [pharmacyNpiLookupData])

    useEffect(() => {
        if (location?.state?.data) {
            setPharmacyDetails(location?.state?.data)
            actions.getPharmacyNpiLookupData({ pharmacynpi: location?.state?.data?.pharmacynpi })
        }
    }, [])

    const cellRendererParams = {
        claim_processor: {
            overrideHeader: 'Contract Name'
        },
        contract_type: {
            cellRenderer: MultiRowRenderer,
            compact: true,
            autoHeight: true,
        },
        start_days_supply: {
            overrideHeader: 'Begin Day Supply'
        },
        fees: {
            cellRenderer: FeeGridRowTextRenderer,
            autoHeight: true,
        },
        maclistid: {
            overrideHeader: 'MAC List ID'
        },
        effective_start_date: {
            overrideHeader: 'Begin Date'
        },
        effective_end_date: {
            overrideHeader: 'End Date'
        },
    }
    return (
        <div id="contractPrice" >
            <BreadCrumb {...props} />
            <div className="header">Pharmacy Details</div>
            <div className="horizontal-fields">
                <FliptInput stylized label="NPI" name="pharmacynpi" value={pharmacyDetails?.pharmacynpi} disabled />
                <FliptInput stylized label="Pharmacy Name" name="pharmacyname" value={pharmacyDetails?.pharmacyname} disabled />
                <FliptInput stylized label="Pharmacy Type" name="pharmacytype" value={pharmacyDetails?.pharmacytype} disabled />
                <FliptInput stylized label="Address 1" name="pharmacyaddress1" value={pharmacyDetails?.pharmacyaddress1} disabled />
            </div>
            <div className="horizontal-fields">
                <FliptInput stylized label="Address 2" name="pharmacyaddress2" value={pharmacyDetails?.pharmacyaddress2} disabled />
                <FliptInput stylized label="City" name="pharmacycity" value={pharmacyDetails?.pharmacycity} disabled />
                <FliptInput stylized label="State" name="pharmacystate" value={pharmacyDetails?.pharmacystate} disabled />
                <FliptInput stylized label="Zip Code" name="pharmacyzip1" value={pharmacyDetails?.pharmacyzip1} disabled />
            </div>
            <div className="horizontal-fields">
                <FliptInput stylized label="Phone Number" name="pharmacyphone" value={pharmacyDetails?.pharmacyphone} disabled />
            </div>
            <div className="header">Network Pricing</div>
            <div className="content">
                <FliptGrid
                    headers={pricingHeader}
                    data={pricingData}
                    cellRendererParams={cellRendererParams}
                />
            </div>
            <div className="header">Exclusion</div>
            <div className="content">
                <FliptGrid
                    headers={exclusionHeader}
                    data={exclusionsData}
                    cellRendererParams={cellRendererParams}
                />
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    state: {
        app: state.app,
        pharmacyNpiLookupData: state.pharmacyLookup.pharmacyNpiLookupData
    },
})

const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...AppActions,
        ...PharmacyLookupActions,
    }

    return {
        actions: bindActionCreators(allActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContractPriceLookup)
