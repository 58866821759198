import React, { PureComponent } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import './style.scss'
import FliptForm from '../../../components/form/fliptForm'
import FliptButton from '../../../components/form/fliptButton'
import FliptInput from '../../../components/form/fliptInput'
import { Creators as UserActions } from '../../../redux/reducers/user'
import { parseQueryString } from '../../../utils/utilities'

class ActivateAccount extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      code_sent: false,
      form: {
        code: '',
        email_address: '',
        phone_number: '',
      },
    }
  }

  componentDidMount() {
    const { history } = this.props
    if (!history.location) return
    const { email } = parseQueryString(history.location.search)

    this.setState((prevState) => ({
      code_sent: false,
      form: {
        ...prevState.form,
        email_address: email,
      },
    }))
  }

  _updateFields = (el) => {
    const { form } = this.state
    const { name, value } = el.currentTarget

    this.setState((() => ({
      form: {
        ...form,
        [name]: value,
      },
    })))
  }

  _resendCode = () => {
    const { code_sent } = this.state
    this.setState({ code_sent: !code_sent })
  }

  _submitForm = () => {
    const { actions } = this.props
    const { form, code_sent } = this.state

    if (!code_sent) {
      actions.activateUser(form)
    } else {
      actions.userVerifyCode(form)
      this.setState({
        code_sent: false,
      })
    }
    this.setState({ code_sent: !code_sent })
  }

  render() {
    const { code_sent, form } = this.state
    const { email_address, phone_number } = form
    const buttonText = code_sent ? 'Verify Phone Number' : 'Send Code'

    return (
      <div id="activationPage" className="content-container">
        <div className="left">
          {/* { this.props?.state?.app?.companyCSSFilename ? <img src={`/i/${state?.app?.companyCSSFilename}/logo-login.png`} alt="Alternate Login Logo" /> : <img alt="Flipt FAST Self Service Application" src="/i/flipt-logo-login.png" /> } */}
          <div className="logo-container">
            <div className="logo-login" />
            <div className="logo-login-default" />
          </div>
        </div>
        <div className="right">
          <div className="header-text">Activate FAST Account</div>
          <FliptForm id="activationForm">
            <FliptInput name="email_address" defaultValue={email_address} placeholder="Email Address" onChange={this._updateFields} />
            {!code_sent && <FliptInput name="phone_number" defaultValue={phone_number} type="text" placeholder="Phone Number" onChange={this._updateFields} />}
            {!!code_sent && <FliptInput name="code" type="text" placeholder="Verification Code" onChange={this._updateFields} />}
            {!!code_sent && (
              <div>
                [
                {' '}
                <span onClick={this._resendCode} role="button" tabIndex={0}>Resend Code</span>
                {' '}
                ]
              </div>
            )}
            <FliptButton className="primary" name={buttonText} onClick={this._submitForm} />
          </FliptForm>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => (
  {
    state: {
      app: state.app,
    },
  }
)

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...UserActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivateAccount)
