import React, { Component } from "react";
import _ from "lodash";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import moment from "moment";
import FliptInput from "../../../../components/form/fliptInput";
import "./styles.scss";
import FliptButton from "../../../../components/form/fliptButton";
import { Creators as AppActions } from "../../../../redux/reducers/app";
import FliptPaDropdown from "../../../../components/form/fliptPaDropdown";
import { Creators as FileConfigurationActions } from "../../../../redux/reducers/api/fileConfiguration";
import FliptHierarchyDropdown from "../../../../components/form/fliptHierarchyDropdown";
import FliptRadio from "../../../../components/form/fliptRadio";
import FliptLabel from "../../../../components/form/fliptLabel";
class CreateFileConfiguration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        file_type: props.data?.file_type ||  "",
        file_config_name: "",
        // entity_name: "",
        module: "FILE_CONFIG",
        hierarchy: [],
        configuration_level: "",
        file_direction: props.data?.file_direction || ""
      },
      disableConfigurationLevel: false,
    };
  }

  _updateFields = (el, dateData) => {
    const { name, value } = dateData || el.currentTarget;

    this.setState((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        [name]: value,
      },
    }));
    if (name === "file_type") {
      this.props.actions.getFileType({ [name]: value, file_direction: this.state.form.file_direction });
      if ((value || '').toLowerCase().includes('usp')) {
        this.setState((prevState) => ({
          ...prevState,
          disableConfigurationLevel: true,
          form: {
            ...prevState.form,
            configuration_level: "Global",
          },
        }));
      } else if (this.state.disableConfigurationLevel) {
        this.setState((prevState) => ({
          ...prevState,
          disableConfigurationLevel: false,
        }));
      }
    }
  };

  _copyBenefitPlan = () => {
    const { showTabFlag, setShowTabFlag } = this.props;
    setShowTabFlag(!showTabFlag);
  };

  setForm = (newForm) => {
    this.setState((prevState) => ({
      ...prevState,
      form: newForm,
    }));
  };
 
  checkButtonValidation = () => {
    const { form } = this.state;
    const { file_config_name, configuration_level,file_direction, file_type } = form;
     if(file_config_name && configuration_level && file_direction && file_type) {
       return false 
     }else{ 
      return true 
     }
  }

  render() {
    const { form } = this.state;
    const { file_config_name, configuration_level,file_direction, file_type } = form;
    const { showTabFlag, setShowTabFlag } = this.props;
    const { entityList, fileTypeList } =
      this.props.state.createFileConfigOptions;
    const availableFileTypesBasedOnDirection = fileTypeList.filter((fileType) => fileType.file_direction?.includes(file_direction));
    return (
      <div id="createPlan">
        <div className="content copy-object" style={{display: 'block'}}>
          <div className="copy-header" >Create File Configuration</div>

          <div className="main-content">
            <div className="fields-container">
              <div className="fields-header">
                <div className="field-label">
                  <span>
                    Configuration Name <span className="danger">*</span>
                  </span>
                </div>
                <FliptInput
                  className="field-input"
                  name="file_config_name"
                  value={file_config_name}
                  onChange={this._updateFields}
                  placeholder="Configuration Name"
                  validation="name"
                  required
                />
              </div>

              <div className="fields-header">
                <div className="field-label">
                  <span>
                    File Direction
                    <span className="danger">*</span>
                  </span>
                </div>
                <div className="field-label-radio">
                  <div className="divide-half-radio">
                    <FliptRadio
                      className="radio-input"
                      toggle={false}
                      name="file_direction"
                      value={"inbound"}
                      checked={file_direction === "inbound"}
                      onChange={this._updateFields}
                    />
                    <FliptLabel
                      className="radio-label"
                      description=""
                      label="Inbound"
                    />
                  </div>
                  <div className="divide-half-radio">
                    <FliptRadio
                      className="radio-input"
                      toggle={false}
                      name="file_direction"
                      value={"outbound"}
                      checked={file_direction === "outbound"}
                      onChange={this._updateFields}
                    />
                    <FliptLabel
                      className="radio-label"
                      description=""
                      label="Outbound"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="fields-container">
              <div className="fields-header">
                <div className="field-label">
                  <span>
                    At what level will this file be configured ?
                    <span className="danger">*</span>
                  </span>
                </div>
                <div className="field-label-radio">
                  <div className="divide-half-radio">
                    <FliptRadio
                      className="radio-input"
                      toggle={false}
                      name="configuration_level"
                      value={"Organization"}
                      checked={configuration_level === "Organization"}
                      onChange={this._updateFields}
                      disabled={this.state.disableConfigurationLevel}
                    />
                    <FliptLabel
                      className="radio-label"
                      description=""
                      label="Organization level"
                    />
                  </div>
                  <div className="divide-half-radio">
                    <FliptRadio
                      className="radio-input"
                      toggle={false}
                      name="configuration_level"
                      value={"Global"}
                      checked={configuration_level === "Global"}
                      onChange={this._updateFields}
                      disabled={this.state.disableConfigurationLevel}
                    />
                    <FliptLabel
                      className="radio-label"
                      description=""
                      label="Global"
                    />
                  </div>
                </div>
              </div>
              <div className="fields-header">
                <div className="field-label">
                  <span>
                    File Type <span className="danger">*</span>
                  </span>
                </div>
                <FliptPaDropdown
                  stylized
                  name="file_type"
                  style={{ zIndex: 99 }}
                  onChange={this._updateFields}
                  simple
                  className="half-width"
                  value={file_type}
                  options={availableFileTypesBasedOnDirection}
                  readOnly={!file_direction}
                  placeholder="File Type"
                />
              </div>
            </div>


            {configuration_level === "Organization" && (
              <FliptHierarchyDropdown setForm={this.setForm} form={form} />
            )}
            <div className="fields-container" style={{ justifyContent: "end" }}>
              <div className="button-space">
                <FliptButton
                  name="Cancel"
                  className="secondary searchButton"
                  onClick={() => setShowTabFlag(false)}
                />
              </div>
              <div className="button-space">
                <FliptButton
                  name="Create"
                  className="primary searchButton"
                  disabled ={this.checkButtonValidation()}
                  onClick={() =>
                    this.props.handleCreateFileConfiguration(this.state.form)
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  state: {
    app: state.app,
    createFileConfigOptions: state.fileConfiguration.options.createFileConfig,
  },
});

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
    ...FileConfigurationActions,
  };

  return {
    actions: bindActionCreators(allActions, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateFileConfiguration);
