import React,{useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import './styles.scss';

import FliptButton from '../../../../../components/form/fliptButton'
import FliptGrid from '../../../../../components/fliptGrid'
import { filterRowData, createQueryString } from '../../../../../utils/utilities'

export default ({ data, memberState }) => {
  const headers = [
    'prescription_id',
    'rx_status',
    'auth_id',
    'create_date',
    'cancelled_date',
    'drug',
    'dosage',
    'dosage_strength',
    'gpi',
    'package_qty',
    'days_of_supply',
    'pharmacy_type',
    'pharmacy',
    'npi',
    'copay_type',
    'drug_copay',
    'total_payment',
    'total_reward',
    'username',
    'application'
  ]

  const cellRendererParams = {
    username: {
        overrideHeader: 'Concierge Flipt ID',
    },
    package_qty: {
      overrideHeader: 'Quantity'
    }
  }
  return (
    <div className="section" id='intents-section'>
      <div className="section-header">
        <h2>Intent</h2>
      </div>
      <div className="content">
        <FliptGrid
          data={data.intent}
          headers={headers}
          cellRendererParams={cellRendererParams}
        />
      </div>

    </div>
  )
}
