import Immutable from "seamless-immutable";
import { createActions, createReducer } from "reduxsauce";

export const INITIAL_STATE = Immutable({
  rebateSummaryData: [],
  rebateData: {},
  drugListSummaryData: [],
  drugListData:[],
  reloadPages: [],
});

export const setRebateSummaryReducer = (state = INITIAL_STATE, { payload }) => ({
    ...state,
    rebateSummaryData: payload.data
})

export const setRebateReducer = (state = INITIAL_STATE, { payload }) => ({
    ...state,
    rebateData: payload.data
})
export const setDrugListSummaryReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  drugListSummaryData: payload.data
});
export const setDrugListReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  drugListData: payload.data
});

export const clearRebateData = (state = INITIAL_STATE, { payload }) => ({
    ...state,
    rebateData: {}
})
export const setReloadReducer = (state = INITIAL_STATE) => ({
  ...state,
  reloadPages: state.reloadPages + 1
});

export const clearReducer = () => INITIAL_STATE

export const { Types, Creators } = createActions({
    setRebateSummaryData: ['payload'],
    getRebateSummaryData: ['payload'],
    setRebateData: ['payload'],
    getRebateData: ['payload'],
    saveRebateData:['payload'],
    setDrugListSummaryData: ['payload'],
    getDrugListSummaryData: ['payload'],
    setDrugListData: ['payload'],
    putRebateData: ['payload'],
    clearRebateData: null,
    clearReducer: null,
    setReloadData: null
})

const HANDLERS = {
  [Types.SET_REBATE_SUMMARY_DATA]: setRebateSummaryReducer,
  [Types.SET_REBATE_DATA]: setRebateReducer,
  [Types.CLEAR_REBATE_DATA]: clearRebateData,
  [Types.SET_DRUG_LIST_SUMMARY_DATA]: setDrugListSummaryReducer,
  [Types.SET_DRUG_LIST_DATA]: setDrugListReducer,
  [Types.SET_RELOAD_DATA]: setReloadReducer,
  [Types.CLEAR_REDUCER]: clearReducer
};

export default createReducer(INITIAL_STATE, HANDLERS);
