/* eslint-disable */
export default {
      "ADHD/ANTI-NARCOLEPSY/ANTI-OBESITY/ANOREXIANTS": {
        "Amphetamines": {
          "Amphetamine Mixtures": {
            "ACETRIS HEALTH": {
              "AMPHETAMINE-DEXTROAMPHETAMINE": "AMPHETAMINE-DEXTROAMPHETAMINE"
            },
            "ACTAVIS": {
              "AMPHETAMINE-DEXTROAMPHETAMINE": "AMPHETAMINE-DEXTROAMPHETAMINE"
            },
            "ACTAVIS ELIZABETH": {
              "AMPHETAMINE-DEXTROAMPHET ER": "AMPHETAMINE-DEXTROAMPHET ER",
              "AMPHETAMINE-DEXTROAMPHETAMINE": "AMPHETAMINE-DEXTROAMPHETAMINE"
            },
            "ALVOGEN": {
              "AMPHETAMINE-DEXTROAMPHETAMINE": "AMPHETAMINE-DEXTROAMPHETAMINE"
            },
            "AMERICAN HEALTH PACKAGING": {
              "AMPHETAMINE-DEXTROAMPHET ER": "AMPHETAMINE-DEXTROAMPHET ER",
              "AMPHETAMINE-DEXTROAMPHETAMINE": "AMPHETAMINE-DEXTROAMPHETAMINE"
            },
            "AMERIGEN PHARMACEUTICALS": {
              "AMPHETAMINE-DEXTROAMPHET ER": "AMPHETAMINE-DEXTROAMPHET ER"
            },
            "AMNEAL PHARMACEUTICALS": {
              "AMPHETAMINE-DEXTROAMPHET ER": "AMPHETAMINE-DEXTROAMPHET ER",
              "AMPHETAMINE-DEXTROAMPHETAMINE": "AMPHETAMINE-DEXTROAMPHETAMINE"
            },
            "ANI  PHARMACEUTICALS": {
              "AMPHETAMINE-DEXTROAMPHET ER": "AMPHETAMINE-DEXTROAMPHET ER"
            },
            "AUROBINDO PHARMA USA": {
              "AMPHETAMINE-DEXTROAMPHETAMINE": "AMPHETAMINE-DEXTROAMPHETAMINE"
            },
            "BRYANT RANCH PREPACK": {
              "ADDERALL": "ADDERALL",
              "AMPHETAMINE-DEXTROAMPHETAMINE": "AMPHETAMINE-DEXTROAMPHETAMINE"
            },
            "EPIC PHARMA": {
              "AMPHETAMINE-DEXTROAMPHETAMINE": "AMPHETAMINE-DEXTROAMPHETAMINE"
            },
            "IMPAX GENERICS": {
              "AMPHETAMINE-DEXTROAMPHETAMINE": "AMPHETAMINE-DEXTROAMPHETAMINE"
            },
            "LANNETT": {
              "AMPHETAMINE-DEXTROAMPHET ER": "AMPHETAMINE-DEXTROAMPHET ER",
              "AMPHETAMINE-DEXTROAMPHETAMINE": "AMPHETAMINE-DEXTROAMPHETAMINE"
            },
            "MALLINCKRODT PHARM": {
              "AMPHETAMINE-DEXTROAMPHET ER": "AMPHETAMINE-DEXTROAMPHET ER",
              "AMPHETAMINE-DEXTROAMPHETAMINE": "AMPHETAMINE-DEXTROAMPHETAMINE"
            },
            "MYLAN": {
              "AMPHETAMINE-DEXTROAMPHETAMINE": "AMPHETAMINE-DEXTROAMPHETAMINE"
            },
            "NORTHSTAR RX": {
              "AMPHETAMINE-DEXTROAMPHETAMINE": "AMPHETAMINE-DEXTROAMPHETAMINE"
            },
            "PAR PHARMACEUTICAL": {
              "AMPHETAMINE-DEXTROAMPHET ER": "AMPHETAMINE-DEXTROAMPHET ER"
            },
            "PDRX PHARMACEUTICAL": {
              "AMPHETAMINE-DEXTROAMPHETAMINE": "AMPHETAMINE-DEXTROAMPHETAMINE"
            },
            "PRASCO LABORATORIES": {
              "AMPHETAMINE-DEXTROAMPHET ER": "AMPHETAMINE-DEXTROAMPHET ER"
            },
            "QUALITY CARE": {
              "ADDERALL": "ADDERALL",
              "ADDERALL XR": "ADDERALL XR"
            },
            "RHODES PHARMACEUTICAL": {
              "AMPHETAMINE-DEXTROAMPHET ER": "AMPHETAMINE-DEXTROAMPHET ER"
            },
            "SANDOZ": {
              "AMPHETAMINE-DEXTROAMPHET ER": "AMPHETAMINE-DEXTROAMPHET ER",
              "AMPHETAMINE-DEXTROAMPHETAMINE": "AMPHETAMINE-DEXTROAMPHETAMINE"
            },
            "SHIRE US INC.": {
              "ADDERALL XR": "ADDERALL XR",
              "MYDAYIS": "MYDAYIS"
            },
            "SPECGX": {
              "AMPHETAMINE-DEXTROAMPHETAMINE": "AMPHETAMINE-DEXTROAMPHETAMINE"
            },
            "SUN PHARMACEUTICALS": {
              "AMPHETAMINE-DEXTROAMPHET ER": "AMPHETAMINE-DEXTROAMPHET ER",
              "AMPHETAMINE-DEXTROAMPHETAMINE": "AMPHETAMINE-DEXTROAMPHETAMINE"
            },
            "SUNRISE PHARMACEUTICAL": {
              "AMPHETAMINE-DEXTROAMPHETAMINE": "AMPHETAMINE-DEXTROAMPHETAMINE"
            },
            "TEVA PHARMACEUTICALS USA": {
              "AMPHETAMINE-DEXTROAMPHETAMINE": "AMPHETAMINE-DEXTROAMPHETAMINE"
            },
            "TEVA SELECT BRANDS": {
              "ADDERALL": "ADDERALL"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "AMPHETAMINE-DEXTROAMPHETAMINE": "AMPHETAMINE-DEXTROAMPHETAMINE"
            }
          },
          "Amphetamines": {
            "ACTAVIS": {
              "DEXTROAMPHETAMINE SULFATE ER": "DEXTROAMPHETAMINE SULFATE ER"
            },
            "AMERICAN HEALTH PACKAGING": {
              "DEXTROAMPHETAMINE SULFATE": "DEXTROAMPHETAMINE SULFATE",
              "DEXTROAMPHETAMINE SULFATE ER": "DEXTROAMPHETAMINE SULFATE ER"
            },
            "AMNEAL PHARMACEUTICALS": {
              "AMPHETAMINE SULFATE": "AMPHETAMINE SULFATE",
              "DEXTROAMPHETAMINE SULFATE ER": "DEXTROAMPHETAMINE SULFATE ER"
            },
            "AMNEAL SPECIALTY": {
              "DEXEDRINE": "DEXEDRINE"
            },
            "ARBOR PHARMACEUTICALS": {
              "EVEKEO": "EVEKEO",
              "EVEKEO ODT": "EVEKEO ODT",
              "ZENZEDI": "ZENZEDI"
            },
            "AUROBINDO PHARMA USA": {
              "DEXTROAMPHETAMINE SULFATE": "DEXTROAMPHETAMINE SULFATE"
            },
            "BIONPHARMA": {
              "AMPHETAMINE SULFATE": "AMPHETAMINE SULFATE"
            },
            "BRYANT RANCH PREPACK": {
              "AMPHETAMINE SULFATE": "AMPHETAMINE SULFATE"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "AMPHETAMINE SULFATE": "AMPHETAMINE SULFATE"
            },
            "GRANULES PHARMACEUTICALS": {
              "AMPHETAMINE SULFATE": "AMPHETAMINE SULFATE"
            },
            "HIKMA": {
              "METHAMPHETAMINE HCL": "METHAMPHETAMINE HCL"
            },
            "IMPAX GENERICS": {
              "DEXTROAMPHETAMINE SULFATE": "DEXTROAMPHETAMINE SULFATE",
              "DEXTROAMPHETAMINE SULFATE ER": "DEXTROAMPHETAMINE SULFATE ER"
            },
            "INDEPENDENCE PHARMACEUTICALS": {
              "PROCENTRA": "PROCENTRA"
            },
            "KVK TECH": {
              "DEXTROAMPHETAMINE SULFATE": "DEXTROAMPHETAMINE SULFATE"
            },
            "LANNETT": {
              "AMPHETAMINE SULFATE": "AMPHETAMINE SULFATE"
            },
            "LUPIN PHARMACEUTICALS": {
              "DEXTROAMPHETAMINE SULFATE": "DEXTROAMPHETAMINE SULFATE"
            },
            "MALLINCKRODT PHARM": {
              "DEXTROAMPHETAMINE SULFATE": "DEXTROAMPHETAMINE SULFATE",
              "DEXTROAMPHETAMINE SULFATE ER": "DEXTROAMPHETAMINE SULFATE ER"
            },
            "MAYNE PHARMA": {
              "DEXTROAMPHETAMINE SULFATE ER": "DEXTROAMPHETAMINE SULFATE ER",
              "METHAMPHETAMINE HCL": "METHAMPHETAMINE HCL"
            },
            "MYLAN": {
              "DEXTROAMPHETAMINE SULFATE ER": "DEXTROAMPHETAMINE SULFATE ER",
              "METHAMPHETAMINE HCL": "METHAMPHETAMINE HCL"
            },
            "NEOS THERAPEUTICS BRANDS": {
              "ADZENYS ER": "ADZENYS ER",
              "ADZENYS XR-ODT": "ADZENYS XR-ODT"
            },
            "PRASCO LABORATORIES": {
              "AMPHETAMINE ER": "AMPHETAMINE ER",
              "DEXTROAMPHETAMINE SULFATE": "DEXTROAMPHETAMINE SULFATE"
            },
            "QUALITY CARE": {
              "DEXTROAMPHETAMINE SULFATE": "DEXTROAMPHETAMINE SULFATE",
              "VYVANSE": "VYVANSE"
            },
            "RECORDATI RARE DISEASES": {
              "DESOXYN": "DESOXYN"
            },
            "SHIRE US INC.": {
              "VYVANSE": "VYVANSE"
            },
            "SOLCO HEALTHCARE": {
              "AMPHETAMINE SULFATE": "AMPHETAMINE SULFATE"
            },
            "SUNRISE PHARMACEUTICAL": {
              "DEXTROAMPHETAMINE SULFATE": "DEXTROAMPHETAMINE SULFATE"
            },
            "TALEC PHARMA": {
              "DEXTROAMPHETAMINE SULFATE": "DEXTROAMPHETAMINE SULFATE"
            },
            "TEVA PHARMACEUTICALS USA": {
              "DEXTROAMPHETAMINE SULFATE": "DEXTROAMPHETAMINE SULFATE",
              "DEXTROAMPHETAMINE SULFATE ER": "DEXTROAMPHETAMINE SULFATE ER"
            },
            "TRIS PHARMA": {
              "DEXTROAMPHETAMINE SULFATE": "DEXTROAMPHETAMINE SULFATE",
              "DYANAVEL XR": "DYANAVEL XR"
            },
            "WILSHIRE PHARMACEUTICALS": {
              "AMPHETAMINE SULFATE": "AMPHETAMINE SULFATE",
              "DEXTROAMPHETAMINE SULFATE": "DEXTROAMPHETAMINE SULFATE"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "DEXTROAMPHETAMINE SULFATE": "DEXTROAMPHETAMINE SULFATE"
            }
          }
        },
        "Analeptics": {
          "Analeptic Combinations": {
            "PHARMAVITE": {
              "ENERGY CHEWS": "ENERGY CHEWS"
            }
          },
          "Analeptics": {
            "AMERICAN REGENT": {
              "CAFFEINE CITRATE": "CAFFEINE CITRATE",
              "CAFFEINE-SODIUM BENZOATE": "CAFFEINE-SODIUM BENZOATE"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP ALERT AID": "GNP ALERT AID"
            },
            "APOTHECA SUPPLY": {
              "CAFFEINE CITRATED": "CAFFEINE CITRATED"
            },
            "ARMAS PHARMACEUTICALS": {
              "CAFFEINE CITRATE": "CAFFEINE CITRATE"
            },
            "AUROMEDICS PHARMA": {
              "CAFFEINE CITRATE": "CAFFEINE CITRATE"
            },
            "BERGEN BRUNSWIG": {
              "GNP STAY AWAKE": "GNP STAY AWAKE"
            },
            "CHAIN DRUG CONSORTIUM": {
              "ALERTNESS AID": "ALERTNESS AID",
              "ALERTNESS AID MAXIMUM STRENGTH": "ALERTNESS AID MAXIMUM STRENGTH"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "STAY AWAKE MAXIMUM STRENGTH": "STAY AWAKE MAXIMUM STRENGTH"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS CAFFEINE": "CVS CAFFEINE"
            },
            "ENCORE SCIENTIFIC": {
              "CAFFEINE": "CAFFEINE"
            },
            "EQUALINE": {
              "EQL STAY AWAKE": "EQL STAY AWAKE",
              "EQL STAY AWAKE MAX ST": "EQL STAY AWAKE MAX ST"
            },
            "EXELA PHARMA SCIENCES": {
              "CAFFEINE CITRATE": "CAFFEINE CITRATE"
            },
            "FAGRON": {
              "CAFFEINE ANHYDROUS": "CAFFEINE ANHYDROUS"
            },
            "FREEDOM PHARMACEUTICALS": {
              "CAFFEINE ANHYDROUS": "CAFFEINE ANHYDROUS"
            },
            "FRESENIUS KABI USA": {
              "CAFFEINE CITRATE": "CAFFEINE CITRATE"
            },
            "GEISS DESTIN & DUNN": {
              "STAY AWAKE MAXIMUM STRENGTH": "STAY AWAKE MAXIMUM STRENGTH"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "NO DOZ MAXIMUM STRENGTH": "NO DOZ MAXIMUM STRENGTH"
            },
            "HIKMA": {
              "CAFCIT": "CAFCIT",
              "DOPRAM": "DOPRAM"
            },
            "LEADER BRAND PRODUCTS": {
              "ALERTNESS AID": "ALERTNESS AID",
              "CAFFEINE": "CAFFEINE"
            },
            "LETCO MEDICAL": {
              "CAFFEINE ANHYDROUS": "CAFFEINE ANHYDROUS"
            },
            "MAGNO-HUMPHRIES LABORATORIES": {
              "KEEP ALERT": "KEEP ALERT"
            },
            "MAJOR PHARMACEUTICALS": {
              "STAY AWAKE": "STAY AWAKE"
            },
            "MARLEX PHARMACEUTICALS": {
              "CAFFEINE": "CAFFEINE"
            },
            "MCKESSON": {
              "HM STAY AWAKE": "HM STAY AWAKE"
            },
            "MCKESSON SUNMARK": {
              "SM STAY AWAKE": "SM STAY AWAKE",
              "SM STAY AWAKE MAXIMUM STRENGTH": "SM STAY AWAKE MAXIMUM STRENGTH"
            },
            "MEDA CONSUMER HEALTHCARE": {
              "VIVARIN": "VIVARIN"
            },
            "MEDISCA": {
              "CAFFEINE ANHYDROUS": "CAFFEINE ANHYDROUS"
            },
            "MICRO LABORATORIES": {
              "CAFFEINE CITRATE": "CAFFEINE CITRATE"
            },
            "NOVAPLUS/EXELA": {
              "CAFFEINE CITRATE": "CAFFEINE CITRATE"
            },
            "NOVAPLUS/SUN PHARMA": {
              "CAFFEINE CITRATE": "CAFFEINE CITRATE"
            },
            "PCCA": {
              "CAFFEINE ANHYDROUS": "CAFFEINE ANHYDROUS",
              "CAFFEINE CITRATED": "CAFFEINE CITRATED"
            },
            "REESE PHARMACEUTICAL": {
              "KEEP ALERT": "KEEP ALERT"
            },
            "RITE AID CORPORATION": {
              "RA STAY AWAKE": "RA STAY AWAKE",
              "RA STAY AWAKE EXTRA STRENGTH": "RA STAY AWAKE EXTRA STRENGTH",
              "RA STAY AWAKE MAXIMUM STRENGTH": "RA STAY AWAKE MAXIMUM STRENGTH"
            },
            "SAGENT PHARMACEUTICAL": {
              "CAFFEINE CITRATE": "CAFFEINE CITRATE"
            },
            "SPECTRUM": {
              "CAFFEINE ANHYDROUS": "CAFFEINE ANHYDROUS"
            },
            "SUN PHARMACEUTICALS": {
              "CAFFEINE CITRATE": "CAFFEINE CITRATE"
            },
            "TOPCO": {
              "STAY AWAKE MAXIMUM STRENGTH": "STAY AWAKE MAXIMUM STRENGTH"
            },
            "TOTAL PHARMACY SUPPLY": {
              "CAFFEINE ANHYDROUS": "CAFFEINE ANHYDROUS"
            },
            "WAL-MART": {
              "EQ STAY AWAKE": "EQ STAY AWAKE"
            },
            "WALGREENS": {
              "AWAKE MAXIMUM STRENGTH": "AWAKE MAXIMUM STRENGTH",
              "STAY AWAKE": "STAY AWAKE"
            }
          }
        },
        "Anorexiants Non-Amphetamine": {
          "Anorexiant Combinations": {
            "VIVUS": {
              "QSYMIA": "QSYMIA"
            }
          },
          "Anorexiants Non-Amphetamine": {
            "A-S MEDICATION SOLUTIONS": {
              "ADIPEX-P": "ADIPEX-P",
              "BENZPHETAMINE HCL": "BENZPHETAMINE HCL",
              "DIDREX": "DIDREX",
              "DIETHYLPROPION HCL": "DIETHYLPROPION HCL",
              "DIETHYLPROPION HCL ER": "DIETHYLPROPION HCL ER",
              "PHENDIMETRAZINE TARTRATE": "PHENDIMETRAZINE TARTRATE",
              "PHENDIMETRAZINE TARTRATE ER": "PHENDIMETRAZINE TARTRATE ER",
              "PHENTERMINE HCL": "PHENTERMINE HCL"
            },
            "ACTAVIS PHARMA": {
              "DIETHYLPROPION HCL": "DIETHYLPROPION HCL",
              "DIETHYLPROPION HCL ER": "DIETHYLPROPION HCL ER"
            },
            "AIDAREX PHARMACEUTICALS": {
              "ADIPEX-P": "ADIPEX-P",
              "BENZPHETAMINE HCL": "BENZPHETAMINE HCL",
              "DIETHYLPROPION HCL": "DIETHYLPROPION HCL",
              "DIETHYLPROPION HCL ER": "DIETHYLPROPION HCL ER",
              "PHENDIMETRAZINE TARTRATE": "PHENDIMETRAZINE TARTRATE",
              "PHENDIMETRAZINE TARTRATE ER": "PHENDIMETRAZINE TARTRATE ER",
              "PHENTERMINE HCL": "PHENTERMINE HCL"
            },
            "AKRIMAX PHARMACEUTICALS": {
              "SUPRENZA": "SUPRENZA"
            },
            "ALTURA PHARMACEUTICALS": {
              "PHENDIMETRAZINE TARTRATE": "PHENDIMETRAZINE TARTRATE",
              "PHENTERMINE HCL": "PHENTERMINE HCL"
            },
            "AMERICAN HEALTH PACKAGING": {
              "PHENTERMINE HCL": "PHENTERMINE HCL"
            },
            "AMNEAL PHARMACEUTICALS": {
              "BENZPHETAMINE HCL": "BENZPHETAMINE HCL"
            },
            "AUROBINDO PHARMA USA": {
              "PHENTERMINE HCL": "PHENTERMINE HCL"
            },
            "AVET PHARMACEUTICALS": {
              "BENZPHETAMINE HCL": "BENZPHETAMINE HCL"
            },
            "BAUSCH HEALTH": {
              "BONTRIL PDM": "BONTRIL PDM"
            },
            "BLENHEIM PHARMACAL": {
              "DIETHYLPROPION HCL ER": "DIETHYLPROPION HCL ER",
              "PHENDIMETRAZINE TARTRATE": "PHENDIMETRAZINE TARTRATE",
              "PHENTERMINE HCL": "PHENTERMINE HCL"
            },
            "BRYANT RANCH PREPACK": {
              "BENZPHETAMINE HCL": "BENZPHETAMINE HCL",
              "DIETHYLPROPION HCL": "DIETHYLPROPION HCL",
              "DIETHYLPROPION HCL ER": "DIETHYLPROPION HCL ER",
              "PHENDIMETRAZINE TARTRATE": "PHENDIMETRAZINE TARTRATE",
              "PHENDIMETRAZINE TARTRATE ER": "PHENDIMETRAZINE TARTRATE ER",
              "PHENTERMINE HCL": "PHENTERMINE HCL"
            },
            "CIPLA USA": {
              "PHENTERMINE HCL": "PHENTERMINE HCL"
            },
            "DIRECT RX": {
              "PHENTERMINE HCL": "PHENTERMINE HCL"
            },
            "ELITE LABORATORIES": {
              "PHENDIMETRAZINE TARTRATE": "PHENDIMETRAZINE TARTRATE"
            },
            "EPIC PHARMA": {
              "BENZPHETAMINE HCL": "BENZPHETAMINE HCL",
              "PHENTERMINE HCL": "PHENTERMINE HCL"
            },
            "GLENMARK PHARMACEUTICALS": {
              "PHENDIMETRAZINE TARTRATE": "PHENDIMETRAZINE TARTRATE"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "DIETHYLPROPION HCL": "DIETHYLPROPION HCL",
              "DIETHYLPROPION HCL ER": "DIETHYLPROPION HCL ER",
              "PHENDIMETRAZINE TARTRATE": "PHENDIMETRAZINE TARTRATE",
              "PHENDIMETRAZINE TARTRATE ER": "PHENDIMETRAZINE TARTRATE ER",
              "PHENTERMINE HCL": "PHENTERMINE HCL"
            },
            "IMPAX GENERICS": {
              "DIETHYLPROPION HCL": "DIETHYLPROPION HCL"
            },
            "KVK TECH": {
              "BENZPHETAMINE HCL": "BENZPHETAMINE HCL",
              "DIETHYLPROPION HCL": "DIETHYLPROPION HCL",
              "LOMAIRA": "LOMAIRA",
              "PHENDIMETRAZINE TARTRATE": "PHENDIMETRAZINE TARTRATE",
              "PHENTERMINE HCL": "PHENTERMINE HCL"
            },
            "LANNETT": {
              "DIETHYLPROPION HCL": "DIETHYLPROPION HCL",
              "DIETHYLPROPION HCL ER": "DIETHYLPROPION HCL ER",
              "PHENTERMINE HCL": "PHENTERMINE HCL"
            },
            "LEADING PHARMA": {
              "PHENTERMINE HCL": "PHENTERMINE HCL"
            },
            "LEGACY PHARMACEUTICAL PKG": {
              "PHENTERMINE HCL": "PHENTERMINE HCL"
            },
            "MIKAH PHARMA": {
              "PHENTERMINE HCL": "PHENTERMINE HCL"
            },
            "NIVAGEN PHARMACEUTICALS": {
              "BENZPHETAMINE HCL": "BENZPHETAMINE HCL"
            },
            "NUCARE PHARMACEUTICALS": {
              "PHENTERMINE HCL": "PHENTERMINE HCL"
            },
            "PAR PHARMACEUTICAL": {
              "BENZPHETAMINE HCL": "BENZPHETAMINE HCL"
            },
            "PAR PHARMACEUTICALS": {
              "PHENTERMINE HCL": "PHENTERMINE HCL"
            },
            "PDRX PHARMACEUTICAL": {
              "ADIPEX-P": "ADIPEX-P",
              "BENZPHETAMINE HCL": "BENZPHETAMINE HCL",
              "DIDREX": "DIDREX",
              "DIETHYLPROPION HCL": "DIETHYLPROPION HCL",
              "DIETHYLPROPION HCL ER": "DIETHYLPROPION HCL ER",
              "PHENDIMETRAZINE TARTRATE": "PHENDIMETRAZINE TARTRATE",
              "PHENDIMETRAZINE TARTRATE ER": "PHENDIMETRAZINE TARTRATE ER",
              "PHENTERMINE HCL": "PHENTERMINE HCL"
            },
            "PDRX PHARMACEUTICALS": {
              "DIETHYLPROPION HCL": "DIETHYLPROPION HCL"
            },
            "PHARMEDIX": {
              "PHENTERMINE HCL": "PHENTERMINE HCL"
            },
            "PREFERRED PHARMACEUTICALS": {
              "PHENDIMETRAZINE TARTRATE": "PHENDIMETRAZINE TARTRATE",
              "PHENTERMINE HCL": "PHENTERMINE HCL"
            },
            "PROFICIENT RX": {
              "DIETHYLPROPION HCL": "DIETHYLPROPION HCL",
              "PHENDIMETRAZINE TARTRATE": "PHENDIMETRAZINE TARTRATE",
              "PHENTERMINE HCL": "PHENTERMINE HCL"
            },
            "QUALITY CARE": {
              "DIETHYLPROPION HCL": "DIETHYLPROPION HCL",
              "DIETHYLPROPION HCL ER": "DIETHYLPROPION HCL ER",
              "PHENDIMETRAZINE TARTRATE": "PHENDIMETRAZINE TARTRATE",
              "PHENDIMETRAZINE TARTRATE ER": "PHENDIMETRAZINE TARTRATE ER",
              "PHENTERMINE HCL": "PHENTERMINE HCL"
            },
            "QUALITY CARE PRODUCTS": {
              "PHENTERMINE HCL": "PHENTERMINE HCL"
            },
            "RISING PHARMACEUTICALS": {
              "BENZPHETAMINE HCL": "BENZPHETAMINE HCL",
              "PHENTERMINE HCL": "PHENTERMINE HCL"
            },
            "RXCHANGE CO": {
              "PHENDIMETRAZINE TARTRATE": "PHENDIMETRAZINE TARTRATE"
            },
            "SANDOZ": {
              "PHENDIMETRAZINE TARTRATE": "PHENDIMETRAZINE TARTRATE",
              "PHENDIMETRAZINE TARTRATE ER": "PHENDIMETRAZINE TARTRATE ER",
              "PHENTERMINE HCL": "PHENTERMINE HCL"
            },
            "SOLCO HEALTHCARE": {
              "BENZPHETAMINE HCL": "BENZPHETAMINE HCL",
              "PHENDIMETRAZINE TARTRATE": "PHENDIMETRAZINE TARTRATE",
              "PHENTERMINE HCL": "PHENTERMINE HCL"
            },
            "SUN PHARMACEUTICALS": {
              "PHENTERMINE HCL": "PHENTERMINE HCL"
            },
            "SUNRISE PHARMACEUTICAL": {
              "PHENTERMINE HCL": "PHENTERMINE HCL"
            },
            "TAGI PHARMA": {
              "PHENTERMINE HCL": "PHENTERMINE HCL"
            },
            "TEVA PHARMACEUTICALS USA": {
              "PHENTERMINE HCL": "PHENTERMINE HCL"
            },
            "TEVA SELECT BRANDS": {
              "ADIPEX-P": "ADIPEX-P"
            },
            "UNIT DOSE SERVICES": {
              "DIETHYLPROPION HCL": "DIETHYLPROPION HCL",
              "PHENTERMINE HCL": "PHENTERMINE HCL"
            },
            "VIRTUS PHARMACEUTICALS": {
              "PHENDIMETRAZINE TARTRATE": "PHENDIMETRAZINE TARTRATE",
              "PHENDIMETRAZINE TARTRATE ER": "PHENDIMETRAZINE TARTRATE ER"
            },
            "WRASER PHARMACEUTICALS": {
              "REGIMEX": "REGIMEX"
            }
          }
        },
        "Anti-Obesity Agents": {
          "Anti-Obesity - GLP-1 Receptor Agonists": {
            "NOVO NORDISK": {
              "SAXENDA": "SAXENDA"
            }
          },
          "Anti-Obesity Agent Combination": {
            "A-S MEDICATION SOLUTIONS": {
              "CONTRAVE": "CONTRAVE"
            },
            "CURRAX PHARMACEUTICALS": {
              "CONTRAVE": "CONTRAVE"
            },
            "NALPROPION PHARMACEUTICALS": {
              "CONTRAVE": "CONTRAVE"
            },
            "PDRX PHARMACEUTICAL": {
              "CONTRAVE": "CONTRAVE"
            }
          },
          "Lipase Inhibitors": {
            "A-S MEDICATION SOLUTIONS": {
              "XENICAL": "XENICAL"
            },
            "AQ PHARMACEUTICALS": {
              "XENICAL": "XENICAL"
            },
            "GENENTECH": {
              "XENICAL": "XENICAL"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "ALLI": "ALLI"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "XENICAL": "XENICAL"
            },
            "H2 PHARMA": {
              "XENICAL": "XENICAL"
            },
            "PDRX PHARMACEUTICAL": {
              "XENICAL": "XENICAL"
            },
            "QUALITY CARE": {
              "XENICAL": "XENICAL"
            }
          },
          "Serotonin 2C Receptor Agonists": {
            "EISAI": {
              "BELVIQ": "BELVIQ",
              "BELVIQ XR": "BELVIQ XR"
            }
          }
        },
        "Attention-Deficit/Hyperactivity Disorder (ADHD) Agents": {
          "ADHD Agent - Selective Alpha Adrenergic Agonists": {
            "ACTAVIS ELIZABETH": {
              "CLONIDINE HCL ER": "CLONIDINE HCL ER",
              "GUANFACINE HCL ER": "GUANFACINE HCL ER"
            },
            "AJANTA PHARMA LIMITED": {
              "CLONIDINE HCL ER": "CLONIDINE HCL ER"
            },
            "AMERICAN HEALTH PACKAGING": {
              "CLONIDINE HCL ER": "CLONIDINE HCL ER"
            },
            "AMNEAL PHARMACEUTICALS": {
              "CLONIDINE HCL ER": "CLONIDINE HCL ER"
            },
            "APOTEX": {
              "GUANFACINE HCL ER": "GUANFACINE HCL ER"
            },
            "AVKARE": {
              "CLONIDINE HCL ER": "CLONIDINE HCL ER",
              "GUANFACINE HCL ER": "GUANFACINE HCL ER"
            },
            "BRYANT RANCH PREPACK": {
              "GUANFACINE HCL ER": "GUANFACINE HCL ER"
            },
            "CADISTA": {
              "CLONIDINE HCL ER": "CLONIDINE HCL ER"
            },
            "CONCORDIA PHARMACEUTICALS": {
              "CLONIDINE HCL ER": "CLONIDINE HCL ER",
              "KAPVAY": "KAPVAY"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "CLONIDINE HCL ER": "CLONIDINE HCL ER",
              "GUANFACINE HCL ER": "GUANFACINE HCL ER"
            },
            "INGENUS PHARMACEUTICALS": {
              "CLONIDINE HCL ER": "CLONIDINE HCL ER"
            },
            "LUPIN PHARMACEUTICALS": {
              "CLONIDINE HCL ER": "CLONIDINE HCL ER"
            },
            "MAYNE PHARMA": {
              "CLONIDINE HCL ER": "CLONIDINE HCL ER"
            },
            "MYLAN": {
              "GUANFACINE HCL ER": "GUANFACINE HCL ER"
            },
            "NORTHSTAR RX": {
              "CLONIDINE HCL ER": "CLONIDINE HCL ER"
            },
            "PAR PHARMACEUTICAL": {
              "CLONIDINE HCL ER": "CLONIDINE HCL ER",
              "GUANFACINE HCL ER": "GUANFACINE HCL ER"
            },
            "PRASCO LABORATORIES": {
              "CLONIDINE HCL ER": "CLONIDINE HCL ER"
            },
            "QUALITY CARE PRODUCTS": {
              "CLONIDINE HCL ER": "CLONIDINE HCL ER"
            },
            "SANDOZ": {
              "GUANFACINE HCL ER": "GUANFACINE HCL ER"
            },
            "SHIRE US INC.": {
              "INTUNIV": "INTUNIV"
            },
            "SOLCO HEALTHCARE": {
              "CLONIDINE HCL ER": "CLONIDINE HCL ER"
            },
            "SUN PHARMACEUTICALS": {
              "GUANFACINE HCL ER": "GUANFACINE HCL ER"
            },
            "TEVA PHARMACEUTICALS USA": {
              "GUANFACINE HCL ER": "GUANFACINE HCL ER"
            },
            "TWI PHARMACEUTICALS": {
              "GUANFACINE HCL ER": "GUANFACINE HCL ER"
            },
            "UPSHER-SMITH": {
              "CLONIDINE HCL ER": "CLONIDINE HCL ER"
            }
          },
          "ADHD Agent - Selective Norepinephrine Reuptake Inhibitor": {
            "AMERICAN HEALTH PACKAGING": {
              "ATOMOXETINE HCL": "ATOMOXETINE HCL"
            },
            "APOTEX": {
              "ATOMOXETINE HCL": "ATOMOXETINE HCL"
            },
            "AVPAK": {
              "ATOMOXETINE HCL": "ATOMOXETINE HCL"
            },
            "BUREL PHARMACEUTICALS": {
              "ATOMOXETINE HCL": "ATOMOXETINE HCL"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "ATOMOXETINE HCL": "ATOMOXETINE HCL"
            },
            "GLENMARK PHARMACEUTICALS": {
              "ATOMOXETINE HCL": "ATOMOXETINE HCL"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "ATOMOXETINE HCL": "ATOMOXETINE HCL"
            },
            "LILLY": {
              "STRATTERA": "STRATTERA"
            },
            "MAJOR PHARMACEUTICALS": {
              "ATOMOXETINE HCL": "ATOMOXETINE HCL"
            },
            "NORTHSTAR RX": {
              "ATOMOXETINE HCL": "ATOMOXETINE HCL"
            },
            "PRASCO LABORATORIES": {
              "ATOMOXETINE HCL": "ATOMOXETINE HCL"
            },
            "QUALITY CARE": {
              "STRATTERA": "STRATTERA"
            },
            "RISING PHARMACEUTICALS": {
              "ATOMOXETINE HCL": "ATOMOXETINE HCL"
            },
            "TEVA PHARMACEUTICALS USA": {
              "ATOMOXETINE HCL": "ATOMOXETINE HCL"
            }
          }
        },
        "Dopamine and Norepinephrine Reuptake Inhibitors (DNRIs)": {
          "Dopamine and Norepinephrine Reuptake Inhibitors (DNRIs)": {
            "JAZZ PHARMACEUTICALS": {
              "SUNOSI": "SUNOSI"
            }
          }
        },
        "Histamine H3-Receptor Antagonist/Inverse Agonists": {
          "Histamine H3-Receptor Antagonist/Inverse Agonists": {
            "HARMONY BIOSCIENCES": {
              "WAKIX": "WAKIX"
            }
          }
        },
        "Stimulants - Misc.": {
          "Stimulants - Misc.": {
            "A-S MEDICATION SOLUTIONS": {
              "MODAFINIL": "MODAFINIL",
              "NUVIGIL": "NUVIGIL"
            },
            "ACTAVIS PHARMA": {
              "ARMODAFINIL": "ARMODAFINIL",
              "METHYLPHENIDATE HCL": "METHYLPHENIDATE HCL",
              "METHYLPHENIDATE HCL ER": "METHYLPHENIDATE HCL ER",
              "METHYLPHENIDATE HCL ER (XR)": "METHYLPHENIDATE HCL ER (XR)",
              "MODAFINIL": "MODAFINIL"
            },
            "ACTAVIS SOUTHATLANTIC": {
              "METHYLPHENIDATE HCL ER (LA)": "METHYLPHENIDATE HCL ER (LA)"
            },
            "ADLON THERAPEUTICS": {
              "ADHANSIA XR": "ADHANSIA XR"
            },
            "ALEMBIC PHARMACEUTICALS": {
              "MODAFINIL": "MODAFINIL"
            },
            "ALTURA PHARMACEUTICALS": {
              "PROVIGIL": "PROVIGIL"
            },
            "ALVOGEN": {
              "METHYLPHENIDATE HCL": "METHYLPHENIDATE HCL",
              "METHYLPHENIDATE HCL ER": "METHYLPHENIDATE HCL ER"
            },
            "AMERICAN HEALTH PACKAGING": {
              "DEXMETHYLPHENIDATE HCL ER": "DEXMETHYLPHENIDATE HCL ER",
              "METHYLPHENIDATE HCL": "METHYLPHENIDATE HCL",
              "METHYLPHENIDATE HCL ER": "METHYLPHENIDATE HCL ER",
              "METHYLPHENIDATE HCL ER (CD)": "METHYLPHENIDATE HCL ER (CD)",
              "METHYLPHENIDATE HCL ER (LA)": "METHYLPHENIDATE HCL ER (LA)",
              "MODAFINIL": "MODAFINIL"
            },
            "AMNEAL PHARMACEUTICALS": {
              "DEXMETHYLPHENIDATE HCL ER": "DEXMETHYLPHENIDATE HCL ER",
              "METHYLPHENIDATE HCL": "METHYLPHENIDATE HCL",
              "METHYLPHENIDATE HCL ER": "METHYLPHENIDATE HCL ER",
              "METHYLPHENIDATE HCL ER (CD)": "METHYLPHENIDATE HCL ER (CD)"
            },
            "ANI  PHARMACEUTICALS": {
              "METHYLPHENIDATE HCL ER": "METHYLPHENIDATE HCL ER"
            },
            "APHENA PHARMA SOLUTIONS": {
              "MODAFINIL": "MODAFINIL",
              "PROVIGIL": "PROVIGIL"
            },
            "APOTEX": {
              "MODAFINIL": "MODAFINIL"
            },
            "AUROBINDO PHARMA": {
              "ARMODAFINIL": "ARMODAFINIL",
              "MODAFINIL": "MODAFINIL"
            },
            "AVET PHARMACEUTICALS": {
              "MODAFINIL": "MODAFINIL"
            },
            "AVKARE": {
              "METHYLPHENIDATE HCL ER": "METHYLPHENIDATE HCL ER",
              "MODAFINIL": "MODAFINIL"
            },
            "AVPAK": {
              "METHYLPHENIDATE HCL": "METHYLPHENIDATE HCL",
              "MODAFINIL": "MODAFINIL"
            },
            "BIONPHARMA": {
              "METHYLPHENIDATE HCL": "METHYLPHENIDATE HCL"
            },
            "BRECKENRIDGE": {
              "ARMODAFINIL": "ARMODAFINIL",
              "METHYLPHENIDATE HCL": "METHYLPHENIDATE HCL"
            },
            "BRYANT RANCH PREPACK": {
              "ARMODAFINIL": "ARMODAFINIL",
              "DEXMETHYLPHENIDATE HCL": "DEXMETHYLPHENIDATE HCL",
              "METHYLPHENIDATE HCL": "METHYLPHENIDATE HCL",
              "METHYLPHENIDATE HCL ER": "METHYLPHENIDATE HCL ER",
              "MODAFINIL": "MODAFINIL",
              "PROVIGIL": "PROVIGIL"
            },
            "CAMBER PHARMACEUTICALS": {
              "METHYLPHENIDATE HCL": "METHYLPHENIDATE HCL",
              "METHYLPHENIDATE HCL ER": "METHYLPHENIDATE HCL ER"
            },
            "DIRECT RX": {
              "ARMODAFINIL": "ARMODAFINIL"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "METHYLPHENIDATE HCL ER": "METHYLPHENIDATE HCL ER"
            },
            "EYWA PHARMA": {
              "METHYLPHENIDATE HCL": "METHYLPHENIDATE HCL"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "METHYLPHENIDATE HCL ER": "METHYLPHENIDATE HCL ER",
              "MODAFINIL": "MODAFINIL"
            },
            "GRANULES PHARMACEUTICALS": {
              "METHYLPHENIDATE HCL ER": "METHYLPHENIDATE HCL ER",
              "METHYLPHENIDATE HCL ER (LA)": "METHYLPHENIDATE HCL ER (LA)"
            },
            "HIKMA": {
              "MODAFINIL": "MODAFINIL"
            },
            "IMPAX GENERICS": {
              "DEXMETHYLPHENIDATE HCL ER": "DEXMETHYLPHENIDATE HCL ER",
              "METHYLPHENIDATE HCL": "METHYLPHENIDATE HCL"
            },
            "INGENUS PHARMACEUTICALS": {
              "MODAFINIL": "MODAFINIL"
            },
            "IRONSHORE PHARMACEUTICALS": {
              "JORNAY PM": "JORNAY PM"
            },
            "JANSSEN": {
              "CONCERTA": "CONCERTA"
            },
            "KREMERS URBAN": {
              "METHYLPHENIDATE HCL ER": "METHYLPHENIDATE HCL ER",
              "METHYLPHENIDATE HCL ER (CD)": "METHYLPHENIDATE HCL ER (CD)"
            },
            "KVK TECH": {
              "DEXMETHYLPHENIDATE HCL": "DEXMETHYLPHENIDATE HCL",
              "METHYLPHENIDATE HCL": "METHYLPHENIDATE HCL",
              "METHYLPHENIDATE HCL ER": "METHYLPHENIDATE HCL ER"
            },
            "LANNETT": {
              "DEXMETHYLPHENIDATE HCL ER": "DEXMETHYLPHENIDATE HCL ER",
              "METHYLPHENIDATE HCL ER": "METHYLPHENIDATE HCL ER",
              "METHYLPHENIDATE HCL ER (CD)": "METHYLPHENIDATE HCL ER (CD)"
            },
            "LUPIN PHARMACEUTICALS": {
              "ARMODAFINIL": "ARMODAFINIL",
              "DEXMETHYLPHENIDATE HCL": "DEXMETHYLPHENIDATE HCL",
              "METHYLPHENIDATE HCL": "METHYLPHENIDATE HCL"
            },
            "MAJOR PHARMACEUTICALS": {
              "MODAFINIL": "MODAFINIL"
            },
            "MALLINCKRODT PHARM": {
              "METHYLPHENIDATE HCL": "METHYLPHENIDATE HCL",
              "METHYLPHENIDATE HCL ER": "METHYLPHENIDATE HCL ER"
            },
            "MAYNE PHARMA": {
              "METHYLPHENIDATE HCL ER (LA)": "METHYLPHENIDATE HCL ER (LA)"
            },
            "MYLAN": {
              "ARMODAFINIL": "ARMODAFINIL",
              "DEXMETHYLPHENIDATE HCL ER": "DEXMETHYLPHENIDATE HCL ER",
              "METHYLPHENIDATE HCL ER": "METHYLPHENIDATE HCL ER",
              "MODAFINIL": "MODAFINIL"
            },
            "MYLAN INSTITUTIONAL": {
              "MODAFINIL": "MODAFINIL"
            },
            "NEOS THERAPEUTICS BRANDS": {
              "COTEMPLA XR-ODT": "COTEMPLA XR-ODT"
            },
            "NORTHSTAR RX": {
              "METHYLPHENIDATE HCL": "METHYLPHENIDATE HCL",
              "METHYLPHENIDATE HCL ER": "METHYLPHENIDATE HCL ER"
            },
            "NOSTRUM LABORATORIES": {
              "METHYLPHENIDATE HCL": "METHYLPHENIDATE HCL"
            },
            "NOVARTIS": {
              "FOCALIN": "FOCALIN",
              "FOCALIN XR": "FOCALIN XR",
              "RITALIN": "RITALIN",
              "RITALIN LA": "RITALIN LA"
            },
            "NOVEN THERAPEUTICS": {
              "DAYTRANA": "DAYTRANA"
            },
            "NUCARE PHARMACEUTICALS": {
              "PROVIGIL": "PROVIGIL"
            },
            "ORCHIDPHARMA": {
              "MODAFINIL": "MODAFINIL"
            },
            "PAR PHARMACEUTICAL": {
              "DEXMETHYLPHENIDATE HCL ER": "DEXMETHYLPHENIDATE HCL ER",
              "MODAFINIL": "MODAFINIL"
            },
            "PAR PHARMACEUTICALS": {
              "METHYLPHENIDATE HCL": "METHYLPHENIDATE HCL",
              "MODAFINIL": "MODAFINIL"
            },
            "PATRIOT PHARMACEUTICALS LLC": {
              "METHYLPHENIDATE HCL ER": "METHYLPHENIDATE HCL ER"
            },
            "PDRX PHARMACEUTICAL": {
              "CONCERTA": "CONCERTA",
              "METHYLPHENIDATE HCL": "METHYLPHENIDATE HCL",
              "PROVIGIL": "PROVIGIL"
            },
            "PDRX PHARMACEUTICALS": {
              "ARMODAFINIL": "ARMODAFINIL"
            },
            "PHARMACEUTICAL ASSOCIATES": {
              "METHYLPHENIDATE HCL": "METHYLPHENIDATE HCL"
            },
            "PREFERRED PHARMACEUTICALS": {
              "ARMODAFINIL": "ARMODAFINIL",
              "MODAFINIL": "MODAFINIL"
            },
            "QUAGEN PHARMACEUTICALS": {
              "METHYLPHENIDATE HCL": "METHYLPHENIDATE HCL"
            },
            "QUALITY CARE": {
              "FOCALIN XR": "FOCALIN XR",
              "METHYLPHENIDATE HCL": "METHYLPHENIDATE HCL",
              "NUVIGIL": "NUVIGIL",
              "PROVIGIL": "PROVIGIL",
              "RITALIN LA": "RITALIN LA"
            },
            "QUALITY CARE PRODUCTS": {
              "ARMODAFINIL": "ARMODAFINIL",
              "MODAFINIL": "MODAFINIL"
            },
            "RHODES PHARMACEUTICAL": {
              "APTENSIO XR": "APTENSIO XR",
              "DEXMETHYLPHENIDATE HCL": "DEXMETHYLPHENIDATE HCL",
              "METHYLPHENIDATE HCL ER (XR)": "METHYLPHENIDATE HCL ER (XR)"
            },
            "RISING PHARMACEUTICALS": {
              "METHYLPHENIDATE HCL": "METHYLPHENIDATE HCL",
              "MODAFINIL": "MODAFINIL"
            },
            "SANDOZ": {
              "ARMODAFINIL": "ARMODAFINIL",
              "DEXMETHYLPHENIDATE HCL": "DEXMETHYLPHENIDATE HCL",
              "DEXMETHYLPHENIDATE HCL ER": "DEXMETHYLPHENIDATE HCL ER",
              "METHYLPHENIDATE HCL": "METHYLPHENIDATE HCL",
              "METHYLPHENIDATE HCL ER (LA)": "METHYLPHENIDATE HCL ER (LA)"
            },
            "SHIONOGI PHARMA": {
              "METHYLIN": "METHYLIN"
            },
            "SPECGX": {
              "METHYLPHENIDATE HCL ER (CD)": "METHYLPHENIDATE HCL ER (CD)"
            },
            "SUN PHARMACEUTICALS": {
              "DEXMETHYLPHENIDATE HCL": "DEXMETHYLPHENIDATE HCL",
              "METHYLPHENIDATE HCL": "METHYLPHENIDATE HCL"
            },
            "TEVA PHARMACEUTICALS USA": {
              "ARMODAFINIL": "ARMODAFINIL",
              "DEXMETHYLPHENIDATE HCL": "DEXMETHYLPHENIDATE HCL",
              "DEXMETHYLPHENIDATE HCL ER": "DEXMETHYLPHENIDATE HCL ER",
              "METHYLPHENIDATE HCL ER (CD)": "METHYLPHENIDATE HCL ER (CD)",
              "METHYLPHENIDATE HCL ER (LA)": "METHYLPHENIDATE HCL ER (LA)",
              "MODAFINIL": "MODAFINIL",
              "NUVIGIL": "NUVIGIL",
              "PROVIGIL": "PROVIGIL"
            },
            "TRIGEN LABORATORIES": {
              "METHYLPHENIDATE HCL ER": "METHYLPHENIDATE HCL ER"
            },
            "TRIS PHARMA": {
              "DEXMETHYLPHENIDATE HCL": "DEXMETHYLPHENIDATE HCL",
              "METHYLPHENIDATE HCL": "METHYLPHENIDATE HCL",
              "QUILLICHEW ER": "QUILLICHEW ER",
              "QUILLIVANT XR": "QUILLIVANT XR"
            },
            "UCB PHARMA": {
              "METADATE CD": "METADATE CD"
            },
            "UNIT DOSE SERVICES": {
              "PROVIGIL": "PROVIGIL"
            },
            "UPSTATE PHARMA, LLC": {
              "METADATE ER": "METADATE ER",
              "METHYLPHENIDATE HCL": "METHYLPHENIDATE HCL"
            },
            "VERTICAL PHARMACEUTICAL": {
              "RELEXXII": "RELEXXII"
            },
            "XLCARE PHARMACEUTICALS": {
              "METHYLPHENIDATE HCL ER": "METHYLPHENIDATE HCL ER"
            }
          }
        }
      },
      "ALLERGENIC EXTRACTS/BIOLOGICALS MISC": {
        "Allergenic Extracts": {
          "Allergenic Extracts": {
            "AIMMUNE THERAPEUTICS": {
              "PALFORZIA (12 MG DAILY DOSE)": "PALFORZIA (12 MG DAILY DOSE)",
              "PALFORZIA (120 MG DAILY DOSE)": "PALFORZIA (120 MG DAILY DOSE)",
              "PALFORZIA (160 MG DAILY DOSE)": "PALFORZIA (160 MG DAILY DOSE)",
              "PALFORZIA (20 MG DAILY DOSE)": "PALFORZIA (20 MG DAILY DOSE)",
              "PALFORZIA (200 MG DAILY DOSE)": "PALFORZIA (200 MG DAILY DOSE)",
              "PALFORZIA (240 MG DAILY DOSE)": "PALFORZIA (240 MG DAILY DOSE)",
              "PALFORZIA (3 MG DAILY DOSE)": "PALFORZIA (3 MG DAILY DOSE)",
              "PALFORZIA (300 MG MAINTENANCE)": "PALFORZIA (300 MG MAINTENANCE)",
              "PALFORZIA (300 MG TITRATION)": "PALFORZIA (300 MG TITRATION)",
              "PALFORZIA (40 MG DAILY DOSE)": "PALFORZIA (40 MG DAILY DOSE)",
              "PALFORZIA (6 MG DAILY DOSE)": "PALFORZIA (6 MG DAILY DOSE)",
              "PALFORZIA (80 MG DAILY DOSE)": "PALFORZIA (80 MG DAILY DOSE)",
              "PALFORZIA INITIAL ESCALATION": "PALFORZIA INITIAL ESCALATION"
            },
            "ALK LABS": {
              "ALK SQ CAT HAIR": "ALK SQ CAT HAIR",
              "HONEY BEE TREATMENT": "HONEY BEE TREATMENT",
              "HONEY BEE VENOM": "HONEY BEE VENOM",
              "MIXED VESPID VENOM PROTEIN": "MIXED VESPID VENOM PROTEIN",
              "WASP VENOM PROTEIN": "WASP VENOM PROTEIN",
              "WHITE FACED HORNET VENOM PROT": "WHITE FACED HORNET VENOM PROT",
              "YELLOW HORNET VENOM PROTEIN": "YELLOW HORNET VENOM PROTEIN",
              "YELLOW JACKET VENOM PROTEIN": "YELLOW JACKET VENOM PROTEIN"
            },
            "ALK-ABELLO": {
              "ASPERGILLUS FUMIGATUS": "ASPERGILLUS FUMIGATUS",
              "AUREOBASIDIUM PULLULANS": "AUREOBASIDIUM PULLULANS",
              "BOTRYTIS": "BOTRYTIS",
              "CLADOSPORIUM CLADOSPORIOIDES": "CLADOSPORIUM CLADOSPORIOIDES",
              "DANDELION": "DANDELION",
              "EPICOCCUM NIGRUM": "EPICOCCUM NIGRUM",
              "GRASTEK": "GRASTEK",
              "MUCOR": "MUCOR",
              "PENICILLIUM NOTATUM": "PENICILLIUM NOTATUM",
              "RAGWITEK": "RAGWITEK",
              "SACCHAROMYCES CEREVISIAE": "SACCHAROMYCES CEREVISIAE",
              "TRICHOPHYTON MENTAGROPHYTES": "TRICHOPHYTON MENTAGROPHYTES"
            },
            "GREER LABORATORIES": {
              "BERMUDA GRASS": "BERMUDA GRASS",
              "CAT HAIR EXTRACT": "CAT HAIR EXTRACT",
              "GRASS POLLEN(K-O-R-T-SWT VERN)": "GRASS POLLEN(K-O-R-T-SWT VERN)",
              "MITE (D. FARINAE)": "MITE (D. FARINAE)",
              "MITE (D. PTERONYSSINUS)": "MITE (D. PTERONYSSINUS)",
              "PERENNIAL RYE GRASS POLLEN": "PERENNIAL RYE GRASS POLLEN",
              "TIMOTHY GRASS POLLEN ALLERGEN": "TIMOTHY GRASS POLLEN ALLERGEN"
            },
            "JUBILANT HOLLISTERSTIER": {
              "CANDIDA ALBICANS EXTRACT": "CANDIDA ALBICANS EXTRACT",
              "HONEY BEE VENOM PROTEIN": "HONEY BEE VENOM PROTEIN",
              "MIXED VESPID VENOM PROTEIN": "MIXED VESPID VENOM PROTEIN",
              "VENOMIL HONEY BEE VENOM": "VENOMIL HONEY BEE VENOM",
              "VENOMIL MIXED VESPID VENOM": "VENOMIL MIXED VESPID VENOM",
              "VENOMIL WASP VENOM": "VENOMIL WASP VENOM",
              "VENOMIL WHITE FACED HORNET": "VENOMIL WHITE FACED HORNET",
              "VENOMIL YELLOW HORNET VENOM": "VENOMIL YELLOW HORNET VENOM",
              "VENOMIL YELLOW JACKET VENOM": "VENOMIL YELLOW JACKET VENOM",
              "WASP VENOM PROTEIN": "WASP VENOM PROTEIN",
              "WHITE-FACED HORNET VENOM": "WHITE-FACED HORNET VENOM",
              "YELLOW HORNET VENOM PROTEIN": "YELLOW HORNET VENOM PROTEIN",
              "YELLOW JACKET VENOM PROTEIN": "YELLOW JACKET VENOM PROTEIN"
            },
            "MERCK SHARP & DOHME": {
              "GRASTEK": "GRASTEK",
              "RAGWITEK": "RAGWITEK"
            },
            "PHARMAGENETICO": {
              "ACACIA": "ACACIA",
              "ACREMONIUM": "ACREMONIUM",
              "ALDER": "ALDER",
              "ALTERNARIA": "ALTERNARIA",
              "AMERICAN BEECH": "AMERICAN BEECH",
              "AMERICAN COCKROACH": "AMERICAN COCKROACH",
              "AMERICAN ELM": "AMERICAN ELM",
              "AMERICAN SYCAMORE": "AMERICAN SYCAMORE",
              "ARIZONA CYPRESS": "ARIZONA CYPRESS",
              "ASPERGILLUS FUMIGATUS": "ASPERGILLUS FUMIGATUS",
              "AUREOBASIDIUM": "AUREOBASIDIUM",
              "AUSTRALIAN PINE": "AUSTRALIAN PINE",
              "BAHIA": "BAHIA",
              "BALD CYPRESS": "BALD CYPRESS",
              "BAYBERRY (WAX MYRTLE)": "BAYBERRY (WAX MYRTLE)",
              "BERMUDA GRASS": "BERMUDA GRASS",
              "BLACK WALNUT POLLEN": "BLACK WALNUT POLLEN",
              "BLACK WILLOW": "BLACK WILLOW",
              "BOTRYTIS": "BOTRYTIS",
              "BOX ELDER": "BOX ELDER",
              "BROME": "BROME",
              "CALIFORNIA PEPPER TREE": "CALIFORNIA PEPPER TREE",
              "CANDIDA ALBICANS EXTRACT": "CANDIDA ALBICANS EXTRACT",
              "CAT HAIR EXTRACT": "CAT HAIR EXTRACT",
              "CATTLE EPITHELIUM": "CATTLE EPITHELIUM",
              "CEDAR ELM": "CEDAR ELM",
              "CLADOSPORIUM CLADOSPORIOIDES": "CLADOSPORIUM CLADOSPORIOIDES",
              "CLADOSPORIUM SPHAEROSPERMUM": "CLADOSPORIUM SPHAEROSPERMUM",
              "COCKLEBUR": "COCKLEBUR",
              "CORN POLLEN": "CORN POLLEN",
              "CURVULARIA": "CURVULARIA",
              "DOG EPITHELIUM": "DOG EPITHELIUM",
              "DOG FENNEL": "DOG FENNEL",
              "DRECHSLERA": "DRECHSLERA",
              "EASTERN COTTONWOOD": "EASTERN COTTONWOOD",
              "ENGLISH PLANTAIN": "ENGLISH PLANTAIN",
              "EPICOCCUM": "EPICOCCUM",
              "FIRE ANT": "FIRE ANT",
              "FUSARIUM": "FUSARIUM",
              "GERMAN COCKROACH": "GERMAN COCKROACH",
              "GOLDENROD": "GOLDENROD",
              "HACKBERRY": "HACKBERRY",
              "HONEY BEE VENOM": "HONEY BEE VENOM",
              "HORSE EPITHELIUM": "HORSE EPITHELIUM",
              "JOHNSON GRASS": "JOHNSON GRASS",
              "JUNE GRASS POLLEN STANDARDIZED": "JUNE GRASS POLLEN STANDARDIZED",
              "KAPOK": "KAPOK",
              "KOCHIA": "KOCHIA",
              "LAMBS QUARTERS": "LAMBS QUARTERS",
              "LENSCALE": "LENSCALE",
              "MEADOW FESCUE GRASS POLLEN": "MEADOW FESCUE GRASS POLLEN",
              "MELALEUCA": "MELALEUCA",
              "MESQUITE": "MESQUITE",
              "MITE (D. FARINAE)": "MITE (D. FARINAE)",
              "MITE (D. PTERONYSSINUS)": "MITE (D. PTERONYSSINUS)",
              "MIXED RAGWEED": "MIXED RAGWEED",
              "MIXED VESPID VENOM PROTEIN": "MIXED VESPID VENOM PROTEIN",
              "MOUNTAIN CEDAR": "MOUNTAIN CEDAR",
              "MOUSE EPITHELIUM": "MOUSE EPITHELIUM",
              "MUCOR": "MUCOR",
              "MUGWORT": "MUGWORT",
              "OLIVE TREE": "OLIVE TREE",
              "ORCHARD GRASS POLLEN": "ORCHARD GRASS POLLEN",
              "PECAN POLLEN": "PECAN POLLEN",
              "PENICILLIUM NOTATUM": "PENICILLIUM NOTATUM",
              "PERENNIAL RYE GRASS POLLEN": "PERENNIAL RYE GRASS POLLEN",
              "PHOMA EXIGUA": "PHOMA EXIGUA",
              "PRIVET": "PRIVET",
              "QUEEN PALM": "QUEEN PALM",
              "RABBIT EPITHELIUM": "RABBIT EPITHELIUM",
              "RED BIRCH": "RED BIRCH",
              "RED CEDAR": "RED CEDAR",
              "RED MAPLE": "RED MAPLE",
              "RED MULBERRY": "RED MULBERRY",
              "RED TOP GRASS POLLEN": "RED TOP GRASS POLLEN",
              "RHIZOPUS": "RHIZOPUS",
              "ROUGH MARSH ELDER": "ROUGH MARSH ELDER",
              "ROUGH PIGWEED": "ROUGH PIGWEED",
              "RUSSIAN THISTLE": "RUSSIAN THISTLE",
              "SACCHAROMYCES CEREVISIAE": "SACCHAROMYCES CEREVISIAE",
              "SAGEBRUSH": "SAGEBRUSH",
              "SHAGBARK HICKORY": "SHAGBARK HICKORY",
              "SHEEP SORREL": "SHEEP SORREL",
              "SHORT RAGWEED POLLEN EXT": "SHORT RAGWEED POLLEN EXT",
              "SPINY PIGWEED": "SPINY PIGWEED",
              "STEMPHYLIUM": "STEMPHYLIUM",
              "SWEET GUM": "SWEET GUM",
              "SWEET VERNAL GRASS POLLEN": "SWEET VERNAL GRASS POLLEN",
              "TALL RAGWEED": "TALL RAGWEED",
              "TIMOTHY GRASS POLLEN ALLERGEN": "TIMOTHY GRASS POLLEN ALLERGEN",
              "TRICHOPHYTON": "TRICHOPHYTON",
              "VIRGINIA LIVE OAK": "VIRGINIA LIVE OAK",
              "WASP VENOM PROTEIN": "WASP VENOM PROTEIN",
              "WESTERN JUNIPER": "WESTERN JUNIPER",
              "WHITE ASH": "WHITE ASH",
              "WHITE BIRCH": "WHITE BIRCH",
              "WHITE FACED HORNET VENOM": "WHITE FACED HORNET VENOM",
              "WHITE MULBERRY": "WHITE MULBERRY",
              "WHITE OAK": "WHITE OAK",
              "WHITE PINE": "WHITE PINE",
              "YELLOW DOCK": "YELLOW DOCK",
              "YELLOW HORNET VENOM PROTEIN": "YELLOW HORNET VENOM PROTEIN",
              "YELLOW JACKET VENOM PROTEIN": "YELLOW JACKET VENOM PROTEIN"
            }
          },
          "Mixed Allergenic Extracts": {
            "ALK-ABELLO": {
              "ODACTRA": "ODACTRA"
            },
            "GREER LABORATORIES": {
              "DUST MITE MIXED ALLERGEN EXT": "DUST MITE MIXED ALLERGEN EXT",
              "ORALAIR": "ORALAIR",
              "ORALAIR ADULT SAMPLE KIT": "ORALAIR ADULT SAMPLE KIT",
              "ORALAIR ADULT STARTER PACK": "ORALAIR ADULT STARTER PACK",
              "ORALAIR CHILDRENS SAMPLE KIT": "ORALAIR CHILDRENS SAMPLE KIT",
              "ORALAIR CHILDRENS STARTER PACK": "ORALAIR CHILDRENS STARTER PACK"
            },
            "PHARMAGENETICO": {
              "DUST MITE MIXED ALLERGEN EXT": "DUST MITE MIXED ALLERGEN EXT",
              "MIXED ASPERGILLUS": "MIXED ASPERGILLUS",
              "MIXED FEATHERS": "MIXED FEATHERS",
              "SORREL/DOCK MIX": "SORREL/DOCK MIX"
            }
          }
        },
        "Biologicals Misc": {
          "Biologicals Misc": {
            "LEADIANT BIOSCIENCES": {
              "ADAGEN": "ADAGEN"
            }
          }
        }
      },
      "ALTERNATIVE MEDICINES": {
        "Alternative Medicine - A\\'s": {},
        "Alternative Medicine - B\\'s": {},
        "Alternative Medicine - C\\'s": {},
        "Alternative Medicine - D\\'s": {},
        "Alternative Medicine - E\\'s": {},
        "Alternative Medicine - F\\'s": {},
        "Alternative Medicine - G\\'s": {},
        "Alternative Medicine - H\\'s": {},
        "Alternative Medicine - K\\'s": {},
        "Alternative Medicine - L\\'s": {},
        "Alternative Medicine - M\\'s": {},
        "Alternative Medicine - N\\'s": {},
        "Alternative Medicine - O\\'s": {},
        "Alternative Medicine - P\\'s": {},
        "Alternative Medicine - R\\'s": {},
        "Alternative Medicine - S\\'s": {},
        "Alternative Medicine - T\\'s": {},
        "Alternative Medicine - U": {
          "Alternative Medicine - Ub": {
            "EMPOWER PHARMACY": {
              "COENZYME Q-10": "COENZYME Q-10"
            },
            "GOOD THINGS HEALTH": {
              "ULTRA COQ10": "ULTRA COQ10"
            },
            "INTEGRATIVE THERAPEUTICS": {
              "UBQH": "UBQH"
            },
            "MASON VITAMINS": {
              "UBIQUINOL": "UBIQUINOL"
            },
            "NATURES BOUNTY": {
              "UBIQUINOL": "UBIQUINOL"
            },
            "PHARMAVITE": {
              "UBIQUINOL": "UBIQUINOL"
            },
            "QUTEN RESEARCH INSTITUTE": {
              "QUNOL COQ10/UBIQUINOL/MEGA": "QUNOL COQ10/UBIQUINOL/MEGA"
            },
            "REXALL SUNDOWN": {
              "UBIQUINOL": "UBIQUINOL"
            },
            "SOLACE NUTRITION": {
              "CYTO-Q": "CYTO-Q",
              "CYTO-Q MAX": "CYTO-Q MAX",
              "CYTO-Q T/F": "CYTO-Q T/F"
            },
            "TISHCON CORP": {
              "ACTIVE Q": "ACTIVE Q",
              "ACTIVE Q MAXIMUM STRENGTH": "ACTIVE Q MAXIMUM STRENGTH",
              "ACTIVE-Q EXTRA STRENGTH": "ACTIVE-Q EXTRA STRENGTH",
              "QH": "QH"
            },
            "WAL-MART": {
              "UBIQUINOL": "UBIQUINOL"
            },
            "WALGREENS": {
              "UBIQUINOL": "UBIQUINOL"
            }
          }
        },
        "Alternative Medicine - V\\'s": {},
        "Alternative Medicine - W\\'s": {},
        "Alternative Medicine - Y\\'s": {},
        "Alternative Medicine Combinations": {
          "Alternative Medicine Combinations - Five Ingredients": {
            "ALEXSO": {
              "SOMNICIN": "SOMNICIN"
            },
            "BIONEURIX": {
              "MELLODYN": "MELLODYN"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS GLUCOS-CHONDROIT TRIPLE ST": "CVS GLUCOS-CHONDROIT TRIPLE ST"
            },
            "GENERAL NUTRITION CORP": {
              "MODIFIED CITRUS PECTIN": "MODIFIED CITRUS PECTIN"
            },
            "JARROW FORMULAS": {
              "GLUCOSAMINE-CHONDROIT-MSM-C-MN": "GLUCOSAMINE-CHONDROIT-MSM-C-MN"
            },
            "LEADER BRAND PRODUCTS": {
              "GLUCOSAMINE CHONDROITIN + D3": "GLUCOSAMINE CHONDROITIN + D3",
              "GLUCOSAMINE CHONDROITIN COMPLX": "GLUCOSAMINE CHONDROITIN COMPLX"
            },
            "LIVING WELL PHARMACY": {
              "MYOFIBEX": "MYOFIBEX"
            },
            "MAJOR PHARMACEUTICALS": {
              "GLUCOSAMINE-MSM COMPLEX": "GLUCOSAMINE-MSM COMPLEX"
            },
            "MASON VITAMINS": {
              "FLEXI JOINT": "FLEXI JOINT"
            },
            "MEDA CONSUMER HEALTHCARE": {
              "MIDNITE SLEEP AID": "MIDNITE SLEEP AID"
            },
            "MOM ENTERPRISES": {
              "MOMMY\\'S BLISS GRIPE WATER NGHT": "MOMMY\\'S BLISS GRIPE WATER NGHT"
            },
            "PHARMAVITE": {
              "MELATONIN + L-THEANINE": "MELATONIN + L-THEANINE",
              "SLEEP": "SLEEP"
            },
            "QUALITY CARE PRODUCTS": {
              "SOMNICIN": "SOMNICIN"
            },
            "REXALL SUNDOWN": {
              "COMPLETE OMEGA": "COMPLETE OMEGA"
            },
            "WALGREENS": {
              "5-HTP": "5-HTP",
              "GLUCOSAMINE CHOND TRIPLE/VIT D": "GLUCOSAMINE CHOND TRIPLE/VIT D"
            }
          },
          "Alternative Medicine Combinations - Four Ingredients": {
            "21ST CENTURY HEALTHCARE": {
              "CRANBERRY PLUS PROBIOTIC": "CRANBERRY PLUS PROBIOTIC"
            },
            "AVPAK": {
              "GLUCOSAMINE-CHONDROITIN MAX ST": "GLUCOSAMINE-CHONDROITIN MAX ST"
            },
            "BERGEN BRUNSWIG": {
              "GNP CRANBERRY": "GNP CRANBERRY"
            },
            "BONGEO PHARMACEUTICALS": {
              "NATUSSA COUGH": "NATUSSA COUGH"
            },
            "CHAIN DRUG CONSORTIUM": {
              "JOINT SUPPORT FORMULA": "JOINT SUPPORT FORMULA"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC GLUCOSAMINE-CHONDROITIN DS": "QC GLUCOSAMINE-CHONDROITIN DS"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS GLUCOSAMINE-CHONDROITIN": "CVS GLUCOSAMINE-CHONDROITIN",
              "CVS GRIPE WATER FOR COLIC": "CVS GRIPE WATER FOR COLIC"
            },
            "ENOVACHEM MANUFACTURING": {
              "VASOHA": "VASOHA"
            },
            "GENERAL NUTRITION CORP": {
              "GINKGO BILOBA PLUS": "GINKGO BILOBA PLUS",
              "WOMENS WATER BALANCE": "WOMENS WATER BALANCE"
            },
            "HOME AIDE DIAGNOSTICS": {
              "CO-BALAMIN": "CO-BALAMIN",
              "CO-VERATROL": "CO-VERATROL"
            },
            "I-HEALTH": {
              "ESTROVEN MOOD & MEMORY": "ESTROVEN MOOD & MEMORY",
              "ESTROVEN NIGHTTIME": "ESTROVEN NIGHTTIME"
            },
            "INTEGRATIVE THERAPEUTICS": {
              "SUPER MILK THISTLE X": "SUPER MILK THISTLE X",
              "VITAMIN C-QUERCETIN-CITRUS BIO": "VITAMIN C-QUERCETIN-CITRUS BIO",
              "YEAST FORMULA": "YEAST FORMULA"
            },
            "MASON VITAMINS": {
              "APPLE CIDER VINEGAR PLUS": "APPLE CIDER VINEGAR PLUS",
              "GLUCOSAMINE & FISH OIL": "GLUCOSAMINE & FISH OIL",
              "GLUCOSAMINE 1500 COMPLEX": "GLUCOSAMINE 1500 COMPLEX",
              "GLUCOSAMINE CHONDR 1500 COMPLX": "GLUCOSAMINE CHONDR 1500 COMPLX",
              "GLUCOSAMINE CHONDR 500 COMPLEX": "GLUCOSAMINE CHONDR 500 COMPLEX",
              "GLUCOSAMINE CHONDROIT-COLLAGEN": "GLUCOSAMINE CHONDROIT-COLLAGEN",
              "GLUCOSAMINE CHONDROITIN COMPLX": "GLUCOSAMINE CHONDROITIN COMPLX",
              "GLUCOSAMINE-CHONDROITIN": "GLUCOSAMINE-CHONDROITIN",
              "POMEGRANATE/EGCG & GRAPE SEED": "POMEGRANATE/EGCG & GRAPE SEED"
            },
            "MCKESSON SUNMARK": {
              "SM GLUCOSAMINE/CHONDROITIN": "SM GLUCOSAMINE/CHONDROITIN",
              "SM NATURAL OMEGA-3 FISH OIL": "SM NATURAL OMEGA-3 FISH OIL"
            },
            "METAGENICS": {
              "OSTERA": "OSTERA"
            },
            "NAT-RUL HEALTH PRODUCTS": {
              "EZ FLEX GC": "EZ FLEX GC",
              "GLUCOSAMINE CHONDROITIN COMPLX": "GLUCOSAMINE CHONDROITIN COMPLX"
            },
            "NATIONAL VITAMIN": {
              "BILBERRY EXTRACT": "BILBERRY EXTRACT",
              "GLUCOSAMINE CHONDROITIN COMPLX": "GLUCOSAMINE CHONDROITIN COMPLX",
              "GLUCOSAMINE-CHONDROITIN": "GLUCOSAMINE-CHONDROITIN",
              "GLUCOSAMINE-CHONDROITIN MAX ST": "GLUCOSAMINE-CHONDROITIN MAX ST"
            },
            "NATROL": {
              "GLUCOSAMINE-CHONDROITIN-MSM": "GLUCOSAMINE-CHONDROITIN-MSM"
            },
            "NATURES BOUNTY": {
              "GLUCOSAMINE CHONDROITIN COMPLX": "GLUCOSAMINE CHONDROITIN COMPLX"
            },
            "NOW HEALTH GROUP": {
              "NOW MELATONIN": "NOW MELATONIN"
            },
            "PHARMAVITE": {
              "TRIPLE FLEX 50+": "TRIPLE FLEX 50+",
              "TRIPLE FLEX/VITAMIN D3": "TRIPLE FLEX/VITAMIN D3"
            },
            "PHYSICIAN RECOMMENDED NUTR": {
              "EYE OMEGA ADVANTAGE/VIT D-3": "EYE OMEGA ADVANTAGE/VIT D-3"
            },
            "RECKITT BENCKISER": {
              "MOVE FREE JOINT HEALTH ADVANCE": "MOVE FREE JOINT HEALTH ADVANCE"
            },
            "REXALL SUNDOWN": {
              "MILK THISTLE XTRA": "MILK THISTLE XTRA",
              "STRESS SAVER": "STRESS SAVER"
            },
            "RITE AID CORPORATION": {
              "RA GLUCOSAMINE-CHONDROITIN-MSM": "RA GLUCOSAMINE-CHONDROITIN-MSM"
            },
            "TWIN LABS": {
              "TRIGOSAMINE MAX ST": "TRIGOSAMINE MAX ST"
            },
            "WAL-MART": {
              "GLUCOSAMINE CHONDROITIN JOINT": "GLUCOSAMINE CHONDROITIN JOINT",
              "GLUCOSAMINE-CHONDROITIN-MSM-D3": "GLUCOSAMINE-CHONDROITIN-MSM-D3"
            },
            "WALGREENS": {
              "GLUCOSAMINE CHONDROITIN PLUS": "GLUCOSAMINE CHONDROITIN PLUS"
            },
            "WELLEMENTS": {
              "GRIPE WATER": "GRIPE WATER"
            },
            "WINDMILL CONSUMER PRODUCTS": {
              "CRANBERRY/CHOKEBERRY": "CRANBERRY/CHOKEBERRY"
            }
          },
          "Alternative Medicine Combinations - Seven Ingredients": {
            "ENOVACHEM MANUFACTURING": {
              "SOPORDREN": "SOPORDREN"
            },
            "NATIONAL VITAMIN": {
              "GLUCOSAMINE-MSM COMPLEX-COLLGN": "GLUCOSAMINE-MSM COMPLEX-COLLGN"
            },
            "PDRX PHARMACEUTICAL": {
              "DURAFLEX": "DURAFLEX"
            },
            "TRIMARC LABORATORIES": {
              "DURAFLEX": "DURAFLEX"
            }
          },
          "Alternative Medicine Combinations - Six Ingredients": {
            "ALTURA PHARMACEUTICALS": {
              "GLUCOSAMINE MSM COMPLEX": "GLUCOSAMINE MSM COMPLEX"
            },
            "BERGEN BRUNSWIG": {
              "GNP MSM GLUCOSAMINE COMPLEX": "GNP MSM GLUCOSAMINE COMPLEX"
            },
            "DOMEL LABORATORIES": {
              "STONEX": "STONEX"
            },
            "EQUALINE": {
              "EQL GLUCOSAMINE CHONDROITIN/D": "EQL GLUCOSAMINE CHONDROITIN/D"
            },
            "MAJOR PHARMACEUTICALS": {
              "GLUCOSAMINE MSM COMPLEX": "GLUCOSAMINE MSM COMPLEX"
            },
            "NATURES BOUNTY": {
              "GLUCOSAMINE MSM COMPLEX": "GLUCOSAMINE MSM COMPLEX"
            },
            "NUTRACEUTICS": {
              "AVORIA GC+": "AVORIA GC+"
            },
            "QUALITY CARE": {
              "GLUCOSAMINE MSM COMPLEX": "GLUCOSAMINE MSM COMPLEX"
            },
            "WALGREENS": {
              "GLUCOS-CHOND-MSM-BOR-D3-HYALUR": "GLUCOS-CHOND-MSM-BOR-D3-HYALUR"
            }
          },
          "Alternative Medicine Combinations - Three Ingredients": {
            "21ST CENTURY HEALTHCARE": {
              "TRIPLE OMEGA COMPLEX": "TRIPLE OMEGA COMPLEX"
            },
            "AIDAREX PHARMACEUTICALS": {
              "MEDI-FLEXX": "MEDI-FLEXX"
            },
            "AKESO HEALTH SCIENCES": {
              "MIGRELIEF": "MIGRELIEF",
              "MIGRELIEF CHILDRENS": "MIGRELIEF CHILDRENS"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP ADVANCED EYE HEALTH": "GNP ADVANCED EYE HEALTH"
            },
            "BASIC DRUGS": {
              "GLUCOSAMINE-CHONDROITIN-MSM": "GLUCOSAMINE-CHONDROITIN-MSM"
            },
            "BASIC ORGANICS": {
              "GLUCOSAMINE-CHONDROITIN-MSM": "GLUCOSAMINE-CHONDROITIN-MSM"
            },
            "BERGEN BRUNSWIG": {
              "GNP TRIPLE OMEGA COMPLEX": "GNP TRIPLE OMEGA COMPLEX"
            },
            "CHAIN DRUG CONSORTIUM": {
              "CO Q-10": "CO Q-10",
              "CRANBERRY SUPER STRENGTH": "CRANBERRY SUPER STRENGTH",
              "CRANBERRY/PROBIOTIC/VIT C": "CRANBERRY/PROBIOTIC/VIT C",
              "JOINT HEALTH": "JOINT HEALTH"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS GLUCO-CHONDROIT PLUS UC-II": "CVS GLUCO-CHONDROIT PLUS UC-II",
              "CVS GLUCOSAMINE HCL": "CVS GLUCOSAMINE HCL",
              "CVS GLUCOSAMINE-CHONDROIT-MSM": "CVS GLUCOSAMINE-CHONDROIT-MSM",
              "CVS JOINT HEALTH TRIPLE ACTION": "CVS JOINT HEALTH TRIPLE ACTION",
              "CVS URINARY HEALTH/CRANBERRY": "CVS URINARY HEALTH/CRANBERRY"
            },
            "ENOVACHEM MANUFACTURING": {
              "CONDROLITE": "CONDROLITE",
              "TRUTICAL": "TRUTICAL"
            },
            "EQUALINE": {
              "EQL CRANBERRY": "EQL CRANBERRY",
              "EQL GLUCOSAMINE-CHONDROIT-MSM": "EQL GLUCOSAMINE-CHONDROIT-MSM"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "EMERGEN-ZZZ": "EMERGEN-ZZZ",
              "EMERGEN-ZZZZ": "EMERGEN-ZZZZ",
              "FORTIFENSE": "FORTIFENSE"
            },
            "GOOD THINGS HEALTH": {
              "CARDIOTEA": "CARDIOTEA"
            },
            "I-HEALTH": {
              "AZO CRANBERRY": "AZO CRANBERRY",
              "ESTROVEN MENOPAUSE RELIEF": "ESTROVEN MENOPAUSE RELIEF",
              "ESTROVEN NIGHTTIME": "ESTROVEN NIGHTTIME"
            },
            "INTEGRATIVE THERAPEUTICS": {
              "BERBERINE COMPLEX": "BERBERINE COMPLEX",
              "ESKIMO KIDS FISH OIL/VIT D": "ESKIMO KIDS FISH OIL/VIT D"
            },
            "LEADER BRAND PRODUCTS": {
              "ADULT OMEGA PLUS DHA": "ADULT OMEGA PLUS DHA",
              "GLUCOSAMINE COMPLEX/VITAMIN D3": "GLUCOSAMINE COMPLEX/VITAMIN D3",
              "SUPER OMEGA-3": "SUPER OMEGA-3"
            },
            "LIVING WELL PHARMACY": {
              "CARTIVISC": "CARTIVISC"
            },
            "MAGNO-HUMPHRIES LABORATORIES": {
              "GLUCOSAMINE-CHONDROITIN-MSM": "GLUCOSAMINE-CHONDROITIN-MSM"
            },
            "MASON VITAMINS": {
              "CINNAMON ALPHA LIPOIC AC CMPLX": "CINNAMON ALPHA LIPOIC AC CMPLX",
              "CRANBERRY CONCENTRATE": "CRANBERRY CONCENTRATE",
              "CRANBERRY SUPER STRENGTH": "CRANBERRY SUPER STRENGTH",
              "DIABETES TRIO": "DIABETES TRIO",
              "GARLIC-PARSLEY": "GARLIC-PARSLEY",
              "KOREAN GINSENG COMPLEX": "KOREAN GINSENG COMPLEX",
              "MAGNESIUM-VITAMIN D3-TURMERIC": "MAGNESIUM-VITAMIN D3-TURMERIC",
              "MENOPAUSE TRIO": "MENOPAUSE TRIO",
              "OMEGA 3-6-9": "OMEGA 3-6-9",
              "OMEGA-3-6-9": "OMEGA-3-6-9",
              "TRIPLE OMEGA-3-6-9": "TRIPLE OMEGA-3-6-9",
              "VEINERECT": "VEINERECT"
            },
            "MCKESSON": {
              "HM ADVANCED EYE HEALTH": "HM ADVANCED EYE HEALTH",
              "HM GLUCOSAMINE & VITAMIN D3": "HM GLUCOSAMINE & VITAMIN D3",
              "HM OMEGA-3-6-9 FATTY ACIDS": "HM OMEGA-3-6-9 FATTY ACIDS"
            },
            "MCKESSON SUNMARK": {
              "SM ADVANCED EYE HEALTH": "SM ADVANCED EYE HEALTH",
              "SM GLUCOSAMINE-VITAMIN D3": "SM GLUCOSAMINE-VITAMIN D3",
              "SM GLUCOSAMINE/CALCIUM + D": "SM GLUCOSAMINE/CALCIUM + D",
              "SM OMEGA-3": "SM OMEGA-3",
              "SM OMEGA-3-6-9 FATTY ACIDS": "SM OMEGA-3-6-9 FATTY ACIDS"
            },
            "MEDICINE SHOPPE": {
              "SUPER OMEGA-3": "SUPER OMEGA-3"
            },
            "MEDTECH": {
              "LITTLE TUMMYS GRIPE WATER": "LITTLE TUMMYS GRIPE WATER"
            },
            "MOM ENTERPRISES": {
              "MOMMY\\'S BLISS CONSTIP+PREBIOT": "MOMMY\\'S BLISS CONSTIP+PREBIOT"
            },
            "NAT-RUL HEALTH PRODUCTS": {
              "NAT-RUL COENZYME Q-10 PLUS": "NAT-RUL COENZYME Q-10 PLUS"
            },
            "NATIONAL VITAMIN": {
              "CHONDROITIN SULFATE COMPLEX": "CHONDROITIN SULFATE COMPLEX",
              "CO-Q 10 OMEGA-3 FISH OIL": "CO-Q 10 OMEGA-3 FISH OIL",
              "FLAX OIL-FISH OIL-BORAGE OIL": "FLAX OIL-FISH OIL-BORAGE OIL",
              "GLUCOSAMINE-CHONDROITIN-MSM": "GLUCOSAMINE-CHONDROITIN-MSM",
              "GLUCOSAMINE-CHONDROITIN-VIT D3": "GLUCOSAMINE-CHONDROITIN-VIT D3",
              "OMEGA DHA": "OMEGA DHA"
            },
            "NATROL": {
              "HYALURONIC ACID": "HYALURONIC ACID",
              "OMEGA 3-6-9 COMPLEX": "OMEGA 3-6-9 COMPLEX"
            },
            "NATURES BOUNTY": {
              "CRANBERRY PLUS VITAMIN C": "CRANBERRY PLUS VITAMIN C",
              "CRANBERRY/VITAMIN C TRIPLE ST": "CRANBERRY/VITAMIN C TRIPLE ST",
              "FISH-FLAX-BORAGE": "FISH-FLAX-BORAGE",
              "GINSENG ROYAL JELLY PLUS": "GINSENG ROYAL JELLY PLUS"
            },
            "NUTRICIA NA": {
              "UTI-STAT": "UTI-STAT"
            },
            "OCUSOFT": {
              "RETAINE FLAX": "RETAINE FLAX",
              "RETAINE OM3": "RETAINE OM3"
            },
            "PHARMA NATURAL": {
              "GLUCOSAMINE-CHONDROIT-COLLAGEN": "GLUCOSAMINE-CHONDROIT-COLLAGEN"
            },
            "PHARMASSURE, INC.": {
              "VALERIAN COMPLEX": "VALERIAN COMPLEX",
              "VITAMIN C/ECHINACEA": "VITAMIN C/ECHINACEA"
            },
            "PHARMAVITE": {
              "TRIPLE FLEX": "TRIPLE FLEX",
              "TRIPLE OMEGA-3-6-9": "TRIPLE OMEGA-3-6-9"
            },
            "PRINCETON RESEARCH": {
              "LUTEIN-ZEAXANTHIN-BILBERRY": "LUTEIN-ZEAXANTHIN-BILBERRY"
            },
            "PROFICIENT RX": {
              "MEDI-DOZE": "MEDI-DOZE",
              "REPOZEN SLEEP AID": "REPOZEN SLEEP AID"
            },
            "REXALL SUNDOWN": {
              "FLAX OIL XTRA": "FLAX OIL XTRA",
              "OSTEO BI-FLEX ONE PER DAY": "OSTEO BI-FLEX ONE PER DAY",
              "TRIPLE OMEGA-3-6-9": "TRIPLE OMEGA-3-6-9",
              "ZINC PLUS VITAMIN C/ECHINACEA": "ZINC PLUS VITAMIN C/ECHINACEA"
            },
            "RITE AID CORPORATION": {
              "PA GLUCOSAMINE-CHONDROITIN-D3": "PA GLUCOSAMINE-CHONDROITIN-D3",
              "RA ADVANCED EYE HEALTH": "RA ADVANCED EYE HEALTH",
              "RA CRANBERRY SUPPLEMENTS": "RA CRANBERRY SUPPLEMENTS",
              "RA GLUCOSAMINE & VITAMIN D3": "RA GLUCOSAMINE & VITAMIN D3",
              "RA GLUCOSAMINE-CHONDROIT-MSM": "RA GLUCOSAMINE-CHONDROIT-MSM",
              "RA GLUCOSAMINE-CHONDROITIN-D3": "RA GLUCOSAMINE-CHONDROITIN-D3",
              "RA OMEGA 3-6-9": "RA OMEGA 3-6-9",
              "RA SAW PALMETTO COMPLEX": "RA SAW PALMETTO COMPLEX"
            },
            "RUGBY LABORATORIES": {
              "GLUCOSAMINE-CHONDROITIN-MSM": "GLUCOSAMINE-CHONDROITIN-MSM"
            },
            "THE KEY COMPANY": {
              "SAMOLINIC": "SAMOLINIC"
            },
            "TWO HIP": {
              "MEDI-DOZE": "MEDI-DOZE",
              "MEDI-FLEXX": "MEDI-FLEXX"
            },
            "VILLAGE PHARMA": {
              "REPOZEN SLEEP AID": "REPOZEN SLEEP AID"
            },
            "VITAMIN HEALTH": {
              "NEOFLEX": "NEOFLEX",
              "VITEYES ESSENTIALS": "VITEYES ESSENTIALS"
            },
            "WAL-MART": {
              "CO Q-10 PLUS L-CARNITINE": "CO Q-10 PLUS L-CARNITINE",
              "CRANBERRY PLUS VITAMIN C": "CRANBERRY PLUS VITAMIN C",
              "OMEGA 3-6-9": "OMEGA 3-6-9"
            },
            "WALGREENS": {
              "ADVANCED EYE HEALTH": "ADVANCED EYE HEALTH",
              "CRANBERRY": "CRANBERRY",
              "CRANBERRY PLUS VITAMIN C": "CRANBERRY PLUS VITAMIN C",
              "FISH OIL-FLAX OIL-BORAGE OIL": "FISH OIL-FLAX OIL-BORAGE OIL",
              "FLAX + DHA": "FLAX + DHA",
              "FLAX OIL-FISH OIL-BORAGE OIL": "FLAX OIL-FISH OIL-BORAGE OIL",
              "GLUCOSAMINE COMPLEX": "GLUCOSAMINE COMPLEX",
              "GLUCOSAMINE-CHONDROITIN-MSM": "GLUCOSAMINE-CHONDROITIN-MSM",
              "GLUCOSAMINE-CHONDROITIN-VIT C": "GLUCOSAMINE-CHONDROITIN-VIT C",
              "GLUCOSAMINE-VITAMIN D3": "GLUCOSAMINE-VITAMIN D3",
              "OMEGA-3 FUSION": "OMEGA-3 FUSION",
              "TRIPLE OMEGA-3-6-9": "TRIPLE OMEGA-3-6-9"
            },
            "WINDMILL CONSUMER PRODUCTS": {
              "BEE POLLEN-PROPOLIS-ROYALJELLY": "BEE POLLEN-PROPOLIS-ROYALJELLY",
              "COQ10-ACETYLCARN-CARNOSINE": "COQ10-ACETYLCARN-CARNOSINE",
              "ECHINACEA-ZINC-VITAMIN C": "ECHINACEA-ZINC-VITAMIN C",
              "PLANT STEROL CHOLESTEROL CONT": "PLANT STEROL CHOLESTEROL CONT"
            }
          },
          "Alternative Medicine Combinations - Two Ingredients": {
            "21ST CENTURY HEALTHCARE": {
              "CINNAMON PLUS CHROMIUM": "CINNAMON PLUS CHROMIUM",
              "GLUCOSAMINE-CHONDROITIN": "GLUCOSAMINE-CHONDROITIN",
              "GLUCOSAMINE-CHONDROITIN DS": "GLUCOSAMINE-CHONDROITIN DS",
              "SAW PALMETTO": "SAW PALMETTO"
            },
            "A-S MEDICATION SOLUTIONS": {
              "GLUCOSAMINE-CHONDROITIN": "GLUCOSAMINE-CHONDROITIN"
            },
            "ADVANCED MEDICAL ENTERPRISES": {
              "VICECTIN GB": "VICECTIN GB"
            },
            "AIDAREX PHARMACEUTICALS": {
              "GLUCOSAMINE-CHONDROITIN": "GLUCOSAMINE-CHONDROITIN"
            },
            "ALTURA PHARMACEUTICALS": {
              "GLUCOSAMINE-CHONDROITIN": "GLUCOSAMINE-CHONDROITIN"
            },
            "AMERICAN HEALTH PACKAGING": {
              "GLUCOSAMINE-CHONDROITIN": "GLUCOSAMINE-CHONDROITIN"
            },
            "AVPAK": {
              "GLUCOSAMINE-CHONDROITIN DS": "GLUCOSAMINE-CHONDROITIN DS"
            },
            "BASIC DRUGS": {
              "GLUCOSAMINE CHONDROITIN COMPLX": "GLUCOSAMINE CHONDROITIN COMPLX",
              "MELATONIN-PYRIDOXINE": "MELATONIN-PYRIDOXINE"
            },
            "BASIC ORGANICS": {
              "ECHINACEA-GOLDEN SEAL": "ECHINACEA-GOLDEN SEAL",
              "GLUCOSAMINE-CHONDROITIN": "GLUCOSAMINE-CHONDROITIN",
              "GLUCOSAMINE-MSM": "GLUCOSAMINE-MSM",
              "LUTEIN-ZEAXANTHIN": "LUTEIN-ZEAXANTHIN"
            },
            "BAUSCH HEALTH": {
              "OCUVITE BLUE LIGHT": "OCUVITE BLUE LIGHT",
              "OCUVITE LUTEIN 25": "OCUVITE LUTEIN 25"
            },
            "BERGEN BRUNSWIG": {
              "GNP FISH OIL +D3": "GNP FISH OIL +D3",
              "GNP GLUCOSAMINE-CHONDROITIN": "GNP GLUCOSAMINE-CHONDROITIN",
              "GNP GLUCOSAMINE/CHONDROITIN": "GNP GLUCOSAMINE/CHONDROITIN",
              "GNP SAW PALMETTO EXTRACT": "GNP SAW PALMETTO EXTRACT"
            },
            "BRYANT RANCH PREPACK": {
              "GLUCOSAMINE-CHONDROITIN": "GLUCOSAMINE-CHONDROITIN"
            },
            "CHAIN DRUG CONSORTIUM": {
              "GLUCOSAMINE-CHONDROITIN": "GLUCOSAMINE-CHONDROITIN",
              "MELATONIN-VITAMIN B-6": "MELATONIN-VITAMIN B-6",
              "MSM GLUCOSAMINE": "MSM GLUCOSAMINE",
              "URINARY TRACT HEALTH": "URINARY TRACT HEALTH"
            },
            "CHATTEM INC": {
              "MELATONEX": "MELATONEX"
            },
            "CHEMI-SOURCE": {
              "GLUCOSAMINE-CHONDROITIN": "GLUCOSAMINE-CHONDROITIN"
            },
            "CHRONOHEALTH": {
              "SILYMARIN": "SILYMARIN"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CRANBERRY-VITAMIN C": "CRANBERRY-VITAMIN C",
              "CVS CRANBERRY": "CVS CRANBERRY",
              "CVS GLUCOSAMINE-CHONDROITIN": "CVS GLUCOSAMINE-CHONDROITIN",
              "CVS LUTEIN": "CVS LUTEIN",
              "CVS MELATONIN": "CVS MELATONIN",
              "CVS SUPER CRANBERRY URINARY": "CVS SUPER CRANBERRY URINARY",
              "TURMERIC COMPLEX/BLACK PEPPER": "TURMERIC COMPLEX/BLACK PEPPER",
              "TURMERIC CURCUMIN": "TURMERIC CURCUMIN"
            },
            "ENDURANCE PRODUCTS": {
              "ENDUR-FLEX": "ENDUR-FLEX",
              "ENDUR-THINE": "ENDUR-THINE",
              "RESVERATROL-GRAPE": "RESVERATROL-GRAPE"
            },
            "ENOVACHEM MANUFACTURING": {
              "WRESTONE": "WRESTONE"
            },
            "ENZYMATIC THERAPY": {
              "SMART Q10 COQ10": "SMART Q10 COQ10"
            },
            "EQUALINE": {
              "EQL GLUCOSAMINE CHONDROITIN": "EQL GLUCOSAMINE CHONDROITIN",
              "EQL MELATONIN/VITAMIN B-6": "EQL MELATONIN/VITAMIN B-6"
            },
            "GENERAL NUTRITION CORP": {
              "DONG QUAI": "DONG QUAI",
              "GINKGO VIN": "GINKGO VIN",
              "GINSENG EDGE": "GINSENG EDGE",
              "MULTI GINSENG & SAW PALMETTO": "MULTI GINSENG & SAW PALMETTO",
              "PASSION FLOWER-VALERIAN": "PASSION FLOWER-VALERIAN"
            },
            "GERI-CARE": {
              "GLUCOSAMINE-CHONDROITIN": "GLUCOSAMINE-CHONDROITIN"
            },
            "GOOD THINGS HEALTH": {
              "CARDIOSTEROL": "CARDIOSTEROL",
              "OMEGA-3 + VITAMIN D3": "OMEGA-3 + VITAMIN D3"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "CIDAFLEX": "CIDAFLEX",
              "GLUCOSAMINE-CHONDROITIN": "GLUCOSAMINE-CHONDROITIN",
              "GLUCOSAMINE-CHONDROITIN DS": "GLUCOSAMINE-CHONDROITIN DS"
            },
            "I-HEALTH": {
              "AZO BLADDER CONTROL/GO-LESS": "AZO BLADDER CONTROL/GO-LESS",
              "AZO CRANBERRY URINARY TRACT": "AZO CRANBERRY URINARY TRACT"
            },
            "INTEGRATIVE THERAPEUTICS": {
              "GLUCOSAMINE-CHONDROITIN": "GLUCOSAMINE-CHONDROITIN",
              "MELATONIN FORTE/L-THEANINE": "MELATONIN FORTE/L-THEANINE",
              "RHIZINATE 3X": "RHIZINATE 3X",
              "VITALINE COQ10/VITAMIN E": "VITALINE COQ10/VITAMIN E"
            },
            "KAISER FOUNDATION HOSPITAL": {
              "KP GLUCOSAMINE CHONDROITIN": "KP GLUCOSAMINE CHONDROITIN",
              "KP SAW PALMETTO": "KP SAW PALMETTO"
            },
            "LEADER BRAND PRODUCTS": {
              "CRANBERRY CONCENTRATE/VITAMINC": "CRANBERRY CONCENTRATE/VITAMINC",
              "FISH OIL + D3": "FISH OIL + D3",
              "GLUCOSAMINE-CHONDROITIN": "GLUCOSAMINE-CHONDROITIN",
              "GLUCOSAMINE-CHONDROITIN DS": "GLUCOSAMINE-CHONDROITIN DS",
              "SAW PALMETTO EXTRACT": "SAW PALMETTO EXTRACT"
            },
            "LIVING WELL PHARMACY": {
              "RESTONE": "RESTONE"
            },
            "MAGNA PHARMACEUTICALS, INC": {
              "Z-SLIM CARB CUTTER": "Z-SLIM CARB CUTTER"
            },
            "MAGNO-HUMPHRIES LABORATORIES": {
              "BEE POLLEN PLUS GINSENG": "BEE POLLEN PLUS GINSENG",
              "CAYENNE PLUS GARLIC": "CAYENNE PLUS GARLIC",
              "GLUCOSAMINE-CHONDROITIN": "GLUCOSAMINE-CHONDROITIN",
              "GLUCOSAMINE-MSM": "GLUCOSAMINE-MSM",
              "LUTEIN-ZEAXANTHIN": "LUTEIN-ZEAXANTHIN"
            },
            "MAJOR PHARMACEUTICALS": {
              "GLUCOSAMINE-CHONDROITIN": "GLUCOSAMINE-CHONDROITIN",
              "GLUCOSAMINE-CHONDROITIN DS": "GLUCOSAMINE-CHONDROITIN DS"
            },
            "MASON VITAMINS": {
              "APPETITE CONTROL": "APPETITE CONTROL",
              "CO Q-10 PLUS RED YEAST RICE": "CO Q-10 PLUS RED YEAST RICE",
              "COCONUT OIL-FLAXSEED OIL": "COCONUT OIL-FLAXSEED OIL",
              "COENZYME Q10-LEVOCARNITINE": "COENZYME Q10-LEVOCARNITINE",
              "COLLAGEN PLUS VITAMIN C": "COLLAGEN PLUS VITAMIN C",
              "ECHINACEA-GOLDENSEAL": "ECHINACEA-GOLDENSEAL",
              "EVENING PRIMROSE OIL-CRANBERRY": "EVENING PRIMROSE OIL-CRANBERRY",
              "GARCINIA CAMBOGIA-CHROMIUM": "GARCINIA CAMBOGIA-CHROMIUM",
              "LIVER & KIDNEY CLEANSER": "LIVER & KIDNEY CLEANSER",
              "MELATIN": "MELATIN",
              "MELATONIN": "MELATONIN",
              "MELATONIN/VITAMIN B-6 EX ST": "MELATONIN/VITAMIN B-6 EX ST",
              "OMEGA-3 FISH OIL/VITAMIN D3": "OMEGA-3 FISH OIL/VITAMIN D3",
              "TOXIN CONTROL": "TOXIN CONTROL"
            },
            "MAYNE PHARMA": {
              "GLUCOSAMINE CHONDR COMPLEX": "GLUCOSAMINE CHONDR COMPLEX"
            },
            "MCKESSON": {
              "HM MELATONIN": "HM MELATONIN",
              "HM MELATONIN-LEMON BALM": "HM MELATONIN-LEMON BALM"
            },
            "MCKESSON SUNMARK": {
              "SM GLUCOSAMINE HCL-MSM": "SM GLUCOSAMINE HCL-MSM",
              "SM MELATONIN": "SM MELATONIN",
              "SM MELATONIN-LEMON BALM": "SM MELATONIN-LEMON BALM"
            },
            "MEDTECH": {
              "LITTLE REMEDIES FOR TUMMYS": "LITTLE REMEDIES FOR TUMMYS"
            },
            "METAGENICS": {
              "COQ10 ST-100": "COQ10 ST-100"
            },
            "MOM ENTERPRISES": {
              "MOMMY\\'S BLISS GRIPE WATER": "MOMMY\\'S BLISS GRIPE WATER"
            },
            "NAT-RUL HEALTH PRODUCTS": {
              "ECHINACEA COMB/GOLDEN SEAL": "ECHINACEA COMB/GOLDEN SEAL",
              "KRILL OIL PLUS": "KRILL OIL PLUS"
            },
            "NATIONAL VITAMIN": {
              "ECHINACEA-GOLDEN SEAL": "ECHINACEA-GOLDEN SEAL",
              "GLUCOSAMINE-CHONDROITIN": "GLUCOSAMINE-CHONDROITIN",
              "GLUCOSAMINE-MSM": "GLUCOSAMINE-MSM",
              "LUTEIN": "LUTEIN",
              "OMEGA-3 FISH OIL-VITAMIN D3": "OMEGA-3 FISH OIL-VITAMIN D3"
            },
            "NATROL": {
              "MELATONIN": "MELATONIN",
              "MELATONIN TR": "MELATONIN TR",
              "MELATONIN TR WITH VITAMIN B6": "MELATONIN TR WITH VITAMIN B6",
              "MELATONIN-PYRIDOXINE": "MELATONIN-PYRIDOXINE",
              "MSM-GLUCOSAMINE": "MSM-GLUCOSAMINE",
              "RESVERATROL PLUS": "RESVERATROL PLUS"
            },
            "NATURES BOUNTY": {
              "ACETYLCARN-ALPHA LIPOIC ACID": "ACETYLCARN-ALPHA LIPOIC ACID",
              "CINNAMON PLUS CHROMIUM": "CINNAMON PLUS CHROMIUM",
              "CO Q-10 PLUS": "CO Q-10 PLUS",
              "ECHINACEA-GOLDENSEAL": "ECHINACEA-GOLDENSEAL",
              "FISH OIL + D3": "FISH OIL + D3",
              "FISH OIL PLUS CO Q-10": "FISH OIL PLUS CO Q-10",
              "FISH OIL/D3 ADULT GUMMIES": "FISH OIL/D3 ADULT GUMMIES",
              "GINSENG COMPLEX/ROYAL JELLY": "GINSENG COMPLEX/ROYAL JELLY",
              "HORNY GOAT WEED-MACA": "HORNY GOAT WEED-MACA",
              "HYALURONIC ACID": "HYALURONIC ACID",
              "MELATONIN TR": "MELATONIN TR",
              "MSM-GLUCOSAMINE": "MSM-GLUCOSAMINE",
              "OMEGA-3 DUAL SPECTRUM": "OMEGA-3 DUAL SPECTRUM",
              "SUPER GOAT WEED/MACA": "SUPER GOAT WEED/MACA",
              "VITAMIN C PLUS ECHINACEA": "VITAMIN C PLUS ECHINACEA"
            },
            "NOW HEALTH GROUP": {
              "NOW MELATONIN": "NOW MELATONIN"
            },
            "NUCARE PHARMACEUTICALS": {
              "COSAMIN DS": "COSAMIN DS",
              "GLUCOSAMINE-CHONDROITIN": "GLUCOSAMINE-CHONDROITIN"
            },
            "NUTRAMAX LABORATORIES": {
              "COSAMIN DS": "COSAMIN DS",
              "SENIOR MOMENT": "SENIOR MOMENT"
            },
            "NUTRITIONAL ALLIANCE": {
              "GLUCOSAMINE-CHONDROITIN": "GLUCOSAMINE-CHONDROITIN"
            },
            "OPTIMAL NUTRIENTS": {
              "GLUCOSAMINE-CHONDROITIN": "GLUCOSAMINE-CHONDROITIN"
            },
            "PCCA": {
              "GOWEY": "GOWEY"
            },
            "PHARMACEUTICA NORTH AMERICA": {
              "CIDAFLEX": "CIDAFLEX"
            },
            "PHARMASSURE, INC.": {
              "ECHINACEA-GOLDEN SEAL": "ECHINACEA-GOLDEN SEAL",
              "GLUCOSAMINE-CHONDROITIN": "GLUCOSAMINE-CHONDROITIN",
              "MELATONIN": "MELATONIN"
            },
            "PHARMATON": {
              "PROSTATONIN": "PROSTATONIN"
            },
            "PHARMAVITE": {
              "CHOLESTOFF COMPLETE": "CHOLESTOFF COMPLETE",
              "CRANBERRY-VITAMIN C": "CRANBERRY-VITAMIN C",
              "FISH OIL-VITAMIN D": "FISH OIL-VITAMIN D",
              "GLUCOSAMINE-VITAMIN D": "GLUCOSAMINE-VITAMIN D"
            },
            "PHYSICIAN RECOMMENDED NUTR": {
              "LDL BENEFIT": "LDL BENEFIT"
            },
            "PRINCETON RESEARCH": {
              "ALPHA LIPOIC ACID-BIOTIN": "ALPHA LIPOIC ACID-BIOTIN",
              "BLACK PEPPER-TURMERIC": "BLACK PEPPER-TURMERIC",
              "CRANBERRY CONCENTRATE/VITAMINC": "CRANBERRY CONCENTRATE/VITAMINC",
              "MELATONIN PLUS L-THEANINE": "MELATONIN PLUS L-THEANINE",
              "MELATONIN-THEANINE": "MELATONIN-THEANINE",
              "TURMERIC COMPLEX/BLACK PEPPER": "TURMERIC COMPLEX/BLACK PEPPER"
            },
            "PROFICIENT RX": {
              "CIDAFLEX": "CIDAFLEX"
            },
            "PUBLIX SUPER MARKETS INC.": {
              "PX GLUCOSAMINE-CHONDROITIN": "PX GLUCOSAMINE-CHONDROITIN",
              "PX GLUCOSAMINE-CHONDROITIN DS": "PX GLUCOSAMINE-CHONDROITIN DS"
            },
            "QUALITY CARE": {
              "GLUCOSAMINE-CHONDROITIN": "GLUCOSAMINE-CHONDROITIN"
            },
            "QUEST PRODUCTS": {
              "PROVENT HEMP PAIN RELIEF": "PROVENT HEMP PAIN RELIEF"
            },
            "QUTEN RESEARCH INSTITUTE": {
              "QUNOL ULTRA COQ10": "QUNOL ULTRA COQ10"
            },
            "REESE PHARMACEUTICAL": {
              "GLUCOSAMINE-CHONDROITIN": "GLUCOSAMINE-CHONDROITIN"
            },
            "RELIABLE 1 LABS": {
              "COQ10-VITAMIN E": "COQ10-VITAMIN E"
            },
            "REXALL SUNDOWN": {
              "AFRICAN MANGO-GREEN TEA": "AFRICAN MANGO-GREEN TEA",
              "CO Q-10 PLUS RED YEAST RICE": "CO Q-10 PLUS RED YEAST RICE",
              "FISH OIL-VITAMIN D": "FISH OIL-VITAMIN D",
              "GLUCOSAMINE PLUS VITAMIN D": "GLUCOSAMINE PLUS VITAMIN D",
              "GLUCOSAMINE-MSM": "GLUCOSAMINE-MSM",
              "HORNY GOAT WEED-MACA": "HORNY GOAT WEED-MACA",
              "OMEGA-3 & VITAMIN D3 GUMMIES": "OMEGA-3 & VITAMIN D3 GUMMIES",
              "OMEGA-3 GUMMIES": "OMEGA-3 GUMMIES",
              "OSTEO BI-FLEX REGULAR STRENGTH": "OSTEO BI-FLEX REGULAR STRENGTH",
              "SAFFLOWER OIL-VITAMIN B6": "SAFFLOWER OIL-VITAMIN B6",
              "SUPER CRANBERRY/VITAMIN D3": "SUPER CRANBERRY/VITAMIN D3"
            },
            "RITE AID CORPORATION": {
              "PA FISH OIL/KRILL": "PA FISH OIL/KRILL",
              "PA FISH OIL/PHYTOSTEROLS": "PA FISH OIL/PHYTOSTEROLS",
              "PA GLUCOSAMINE-CHONDROITIN": "PA GLUCOSAMINE-CHONDROITIN",
              "RA GLUCOSAMINE-CHONDROITIN": "RA GLUCOSAMINE-CHONDROITIN",
              "RA GLUCOSAMINE-CHONDROITIN DS": "RA GLUCOSAMINE-CHONDROITIN DS",
              "RA LUTEIN": "RA LUTEIN",
              "RA MELATONIN": "RA MELATONIN"
            },
            "RUGBY LABORATORIES": {
              "GLUCOSAMINE-CHONDROITIN": "GLUCOSAMINE-CHONDROITIN",
              "GLUCOSAMINE-CHONDROITIN DS": "GLUCOSAMINE-CHONDROITIN DS"
            },
            "RXCHANGE CO": {
              "COSAMIN DS": "COSAMIN DS"
            },
            "SCHWABE NORTH AMERICA": {
              "BLACK ELDERBERRY(BERRY-FLOWER)": "BLACK ELDERBERRY(BERRY-FLOWER)"
            },
            "SWANSON HEALTH PRODUCTS": {
              "GUGLIPID/BIOPERINE": "GUGLIPID/BIOPERINE"
            },
            "THE COROMEGA COMPANY": {
              "COROMEGA OMEGA 3+D SQUEEZE": "COROMEGA OMEGA 3+D SQUEEZE",
              "HEALTHY HEART": "HEALTHY HEART"
            },
            "THERALOGIX": {
              "OPTIFLEX COMPLETE": "OPTIFLEX COMPLETE",
              "OPTIFLEX SPORT": "OPTIFLEX SPORT",
              "PROSTATE PQ": "PROSTATE PQ",
              "PROSTATE SR": "PROSTATE SR"
            },
            "TISHCON CORP": {
              "ACTIVE-Q": "ACTIVE-Q",
              "CARNI Q-GEL FORTE": "CARNI Q-GEL FORTE",
              "LIPO-GEL": "LIPO-GEL",
              "LIQ-10": "LIQ-10"
            },
            "TWIN LABS": {
              "MAX GLUCOSAMINE CHONDROITIN": "MAX GLUCOSAMINE CHONDROITIN"
            },
            "UNIT DOSE SERVICES": {
              "GLUCOSAMINE-CHONDROITIN DS": "GLUCOSAMINE-CHONDROITIN DS"
            },
            "VITALINE": {
              "CO-ENZYME Q10-VITAMIN E": "CO-ENZYME Q10-VITAMIN E"
            },
            "WAL-MART": {
              "ACETYLCARN-ALPHA LIPOIC ACID": "ACETYLCARN-ALPHA LIPOIC ACID",
              "BILBERRY PLUS LUTEIN": "BILBERRY PLUS LUTEIN",
              "CINNAMON PLUS CHROMIUM": "CINNAMON PLUS CHROMIUM",
              "ECHINACEA-GOLDEN SEAL COMPLEX": "ECHINACEA-GOLDEN SEAL COMPLEX",
              "GLUCOSAMINE-CHONDROITIN": "GLUCOSAMINE-CHONDROITIN",
              "GREEN COFFEE BEAN EXTRACT": "GREEN COFFEE BEAN EXTRACT",
              "GREEN TEA-HOODIA": "GREEN TEA-HOODIA",
              "HYALURONIC ACID-VITAMIN C": "HYALURONIC ACID-VITAMIN C",
              "LUTEIN-ZEAXANTHIN": "LUTEIN-ZEAXANTHIN",
              "MELATONIN": "MELATONIN",
              "SV MELATONIN-LEMON BALM": "SV MELATONIN-LEMON BALM"
            },
            "WALGREENS": {
              "CRANBERRY ULTRA STRENGTH": "CRANBERRY ULTRA STRENGTH",
              "CRANBERRY-VITAMIN C": "CRANBERRY-VITAMIN C",
              "CRANBERRY/VITAMIN C TRIPLE ST": "CRANBERRY/VITAMIN C TRIPLE ST",
              "ECHINACEA-VITAMIN C": "ECHINACEA-VITAMIN C",
              "GLUCOSAMINE SULFATE-MSM": "GLUCOSAMINE SULFATE-MSM",
              "GLUCOSAMINE-CHONDROITIN": "GLUCOSAMINE-CHONDROITIN",
              "GLUCOSAMINE-CHONDROITIN PM": "GLUCOSAMINE-CHONDROITIN PM",
              "GLUCOSAMINE-MSM": "GLUCOSAMINE-MSM",
              "GLUCOSAMINE-MSM DS": "GLUCOSAMINE-MSM DS",
              "LUTEIN-ZEAXANTHIN": "LUTEIN-ZEAXANTHIN",
              "MELATONIN TR": "MELATONIN TR",
              "MELATONIN TR WITH VITAMIN B6": "MELATONIN TR WITH VITAMIN B6",
              "MELATONIN-LEMON BALM": "MELATONIN-LEMON BALM",
              "MELATONIN-PYRIDOXINE": "MELATONIN-PYRIDOXINE",
              "MELATONIN-PYRIDOXINE ER": "MELATONIN-PYRIDOXINE ER",
              "OMEGA-3 + D": "OMEGA-3 + D",
              "OMEGA-3 GUMMIES": "OMEGA-3 GUMMIES",
              "VITAMIN C PLUS ECHINACEA": "VITAMIN C PLUS ECHINACEA"
            },
            "WINDMILL CONSUMER PRODUCTS": {
              "GLUCOSAMINE-CHONDROITIN DS": "GLUCOSAMINE-CHONDROITIN DS"
            }
          }
        }
      },
      "AMEBICIDES": {
        "Amebicides": {
          "Amebicides": {
            "APOTHECA SUPPLY": {
              "IODOQUINOL": "IODOQUINOL"
            },
            "LUPIN PHARMACEUTICALS": {
              "SOLOSEC": "SOLOSEC"
            },
            "PCCA": {
              "IODOQUINOL": "IODOQUINOL"
            },
            "SPECTRUM": {
              "IODOQUINOL": "IODOQUINOL"
            }
          }
        }
      },
      "AMINOGLYCOSIDES": {
        "Aminoglycosides": {
          "Aminoglycosides": {
            "A-S MEDICATION SOLUTIONS": {
              "PAROMOMYCIN SULFATE": "PAROMOMYCIN SULFATE"
            },
            "AKORN": {
              "TOBRAMYCIN": "TOBRAMYCIN",
              "TOBRAMYCIN SULFATE": "TOBRAMYCIN SULFATE"
            },
            "AMERICAN PHARMA INGREDIENTS": {
              "AMIKACIN SULFATE": "AMIKACIN SULFATE"
            },
            "AMNEAL PHARMACEUTICALS": {
              "TOBRAMYCIN": "TOBRAMYCIN"
            },
            "API SOLUTIONS": {
              "TOBRAMYCIN SULFATE": "TOBRAMYCIN SULFATE"
            },
            "AVET PHARMACEUTICALS": {
              "AMIKACIN SULFATE": "AMIKACIN SULFATE",
              "PAROMOMYCIN SULFATE": "PAROMOMYCIN SULFATE"
            },
            "BAXTER HEALTHCARE CORP": {
              "GENTAMICIN IN SALINE": "GENTAMICIN IN SALINE",
              "TOBRAMYCIN SULFATE": "TOBRAMYCIN SULFATE"
            },
            "BRECKENRIDGE": {
              "NEOMYCIN SULFATE": "NEOMYCIN SULFATE"
            },
            "CHIESI USA": {
              "BETHKIS": "BETHKIS"
            },
            "CIPLA USA": {
              "ZEMDRI": "ZEMDRI"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "TOBRAMYCIN": "TOBRAMYCIN"
            },
            "FAGRON": {
              "TOBRAMYCIN SULFATE": "TOBRAMYCIN SULFATE"
            },
            "FRESENIUS KABI USA": {
              "AMIKACIN SULFATE": "AMIKACIN SULFATE",
              "GENTAMICIN SULFATE": "GENTAMICIN SULFATE",
              "TOBRAMYCIN SULFATE": "TOBRAMYCIN SULFATE"
            },
            "GENERICUS": {
              "TOBRAMYCIN": "TOBRAMYCIN"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "NEOMYCIN SULFATE": "NEOMYCIN SULFATE"
            },
            "HI-TECH": {
              "NEOMYCIN SULFATE": "NEOMYCIN SULFATE"
            },
            "HIKMA": {
              "AMIKACIN SULFATE": "AMIKACIN SULFATE"
            },
            "HOSPIRA": {
              "GENTAMICIN IN SALINE": "GENTAMICIN IN SALINE",
              "GENTAMICIN SULFATE": "GENTAMICIN SULFATE",
              "TOBRAMYCIN SULFATE": "TOBRAMYCIN SULFATE"
            },
            "INSMED": {
              "ARIKAYCE": "ARIKAYCE"
            },
            "KALCHEM INTERNATIONAL": {
              "AMIKACIN SULFATE": "AMIKACIN SULFATE"
            },
            "LETCO MEDICAL": {
              "AMIKACIN SULFATE": "AMIKACIN SULFATE",
              "TOBRAMYCIN SULFATE": "TOBRAMYCIN SULFATE"
            },
            "LUPIN PHARMACEUTICALS": {
              "TOBRAMYCIN": "TOBRAMYCIN"
            },
            "MEDISCA": {
              "AMIKACIN SULFATE": "AMIKACIN SULFATE",
              "TOBRAMYCIN SULFATE": "TOBRAMYCIN SULFATE"
            },
            "MYLAN INSTITUTIONAL": {
              "NEOMYCIN SULFATE": "NEOMYCIN SULFATE",
              "TOBRAMYCIN SULFATE": "TOBRAMYCIN SULFATE"
            },
            "MYLAN SPECIALTY L.P.": {
              "TOBI": "TOBI",
              "TOBI PODHALER": "TOBI PODHALER"
            },
            "NORTHSTAR RX": {
              "TOBRAMYCIN": "TOBRAMYCIN"
            },
            "NOVAPLUS/FRESENIUS KABI": {
              "GENTAMICIN SULFATE": "GENTAMICIN SULFATE",
              "TOBRAMYCIN SULFATE": "TOBRAMYCIN SULFATE"
            },
            "NOVAPLUS/HOSPIRA": {
              "GENTAMICIN SULFATE": "GENTAMICIN SULFATE"
            },
            "NOVAPLUS/MYLAN INSTITUTIONAL": {
              "TOBRAMYCIN SULFATE": "TOBRAMYCIN SULFATE"
            },
            "NOVAPLUS/TEVA PARENTAL MEDICIN": {
              "AMIKACIN SULFATE": "AMIKACIN SULFATE"
            },
            "PARI RESPIRATORY EQUIPMENT": {
              "KITABIS PAK": "KITABIS PAK"
            },
            "PCCA": {
              "AMIKACIN SULFATE": "AMIKACIN SULFATE",
              "STREPTOMYCIN SULFATE": "STREPTOMYCIN SULFATE"
            },
            "PHARMPAK": {
              "PAROMOMYCIN SULFATE": "PAROMOMYCIN SULFATE"
            },
            "PRASCO LABORATORIES": {
              "TOBRAMYCIN": "TOBRAMYCIN"
            },
            "SAGENT PHARMACEUTICAL": {
              "AMIKACIN SULFATE": "AMIKACIN SULFATE"
            },
            "SANDOZ": {
              "TOBRAMYCIN": "TOBRAMYCIN"
            },
            "SPECTRUM": {
              "AMIKACIN SULFATE": "AMIKACIN SULFATE",
              "TOBRAMYCIN SULFATE": "TOBRAMYCIN SULFATE"
            },
            "SUN PHARMACEUTICALS": {
              "PAROMOMYCIN SULFATE": "PAROMOMYCIN SULFATE",
              "TOBRAMYCIN": "TOBRAMYCIN"
            },
            "TEVA PARENTERAL MEDICINES": {
              "AMIKACIN SULFATE": "AMIKACIN SULFATE",
              "TOBRAMYCIN SULFATE": "TOBRAMYCIN SULFATE"
            },
            "TEVA PHARMACEUTICALS USA": {
              "NEOMYCIN SULFATE": "NEOMYCIN SULFATE",
              "TOBRAMYCIN": "TOBRAMYCIN"
            },
            "WILLOW BIRCH PHARMA": {
              "TOBRAMYCIN SULFATE": "TOBRAMYCIN SULFATE"
            },
            "X-GEN PHARMACEUTICALS, INC": {
              "NEOMYCIN SULFATE": "NEOMYCIN SULFATE",
              "STREPTOMYCIN SULFATE": "STREPTOMYCIN SULFATE",
              "TOBRAMYCIN SULFATE": "TOBRAMYCIN SULFATE"
            }
          }
        }
      },
      "ANALGESICS - ANTI-INFLAMMATORY": {
        "Analgesics - Anti-inflammatory Combinations": {
          "NSAID Combinations": {
            "FORTUS PHARMA": {
              "ANODYNE ILE": "ANODYNE ILE"
            }
          },
          "NSAID-Dietary Management Combinations": {
            "HEALTH SCIENCE FUNDING": {
              "PRASTERA": "PRASTERA"
            }
          },
          "NSAID-Vitamins and/or Minerals Combinations": {
            "PHARMAGENETICO": {
              "EQUAPAX/IBUPROFEN/MINREX": "EQUAPAX/IBUPROFEN/MINREX"
            }
          }
        },
        "Anti-TNF-alpha - Monoclonal Antibodies": {
          "Anti-TNF-alpha - Monoclonal Antibodies": {
            "ABBVIE": {
              "HUMIRA": "HUMIRA",
              "HUMIRA PEDIATRIC CROHNS START": "HUMIRA PEDIATRIC CROHNS START",
              "HUMIRA PEN": "HUMIRA PEN",
              "HUMIRA PEN-CD/UC/HS STARTER": "HUMIRA PEN-CD/UC/HS STARTER",
              "HUMIRA PEN-PS/UV/ADOL HS START": "HUMIRA PEN-PS/UV/ADOL HS START"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "HUMIRA PEN": "HUMIRA PEN"
            },
            "JANSSEN BIOTECH": {
              "SIMPONI": "SIMPONI",
              "SIMPONI ARIA": "SIMPONI ARIA"
            }
          }
        },
        "Antirheumatic - Enzyme Inhibitors": {
          "Antirheumatic - Janus Kinase (JAK) Inhibitors": {
            "ABBVIE": {
              "RINVOQ": "RINVOQ"
            },
            "LILLY": {
              "OLUMIANT": "OLUMIANT"
            },
            "PFIZER U.S.": {
              "XELJANZ": "XELJANZ",
              "XELJANZ XR": "XELJANZ XR"
            }
          }
        },
        "Antirheumatic Antimetabolites": {
          "Antirheumatic Antimetabolites": {
            "ANTARES PHARMA": {
              "OTREXUP": "OTREXUP"
            },
            "CUMBERLAND PHARMACEUTICALS": {
              "REDITREX": "REDITREX"
            },
            "MEDEXUS PHARMA": {
              "RASUVO": "RASUVO"
            },
            "PAR PHARMACEUTICAL": {
              "RHEUMATREX": "RHEUMATREX"
            }
          }
        },
        "Gold Compounds": {
          "Gold Compounds": {
            "SEBELA PHARMACEUTICALS": {
              "RIDAURA": "RIDAURA"
            }
          }
        },
        "Interleukin-1 Blockers": {
          "Interleukin-1 Blockers": {
            "REGENERON PHARMACEUTICALS": {
              "ARCALYST": "ARCALYST"
            }
          }
        },
        "Interleukin-1 Receptor Antagonist (IL-1Ra)": {
          "Interleukin-1 Receptor Antagonist (IL-1Ra)": {
            "SOBI": {
              "KINERET": "KINERET"
            }
          }
        },
        "Interleukin-1beta Blockers": {
          "Interleukin-1beta Blockers": {
            "NOVARTIS": {
              "ILARIS": "ILARIS"
            }
          }
        },
        "Interleukin-6 Receptor Inhibitors": {
          "Interleukin-6 Receptor Inhibitors": {
            "GENENTECH": {
              "ACTEMRA": "ACTEMRA",
              "ACTEMRA ACTPEN": "ACTEMRA ACTPEN"
            },
            "SANOFI PHARMACEUTICALS": {
              "KEVZARA": "KEVZARA"
            }
          }
        },
        "Nonsteroidal Anti-inflammatory Agents (NSAIDs)": {
          "Cyclooxygenase 2 (COX-2) Inhibitors": {
            "A-S MEDICATION SOLUTIONS": {
              "CELEBREX": "CELEBREX",
              "CELECOXIB": "CELECOXIB"
            },
            "ACTAVIS PHARMA": {
              "CELECOXIB": "CELECOXIB"
            },
            "AIDAREX PHARMACEUTICALS": {
              "CELEBREX": "CELEBREX",
              "CELECOXIB": "CELECOXIB"
            },
            "ALEMBIC PHARMACEUTICALS": {
              "CELECOXIB": "CELECOXIB"
            },
            "ALTURA PHARMACEUTICALS": {
              "CELEBREX": "CELEBREX"
            },
            "AMERICAN HEALTH PACKAGING": {
              "CELECOXIB": "CELECOXIB"
            },
            "APHENA PHARMA SOLUTIONS": {
              "CELEBREX": "CELEBREX",
              "CELECOXIB": "CELECOXIB"
            },
            "APOTEX": {
              "CELECOXIB": "CELECOXIB"
            },
            "APOTHECA": {
              "CELEBREX": "CELEBREX"
            },
            "AQ PHARMACEUTICALS": {
              "CELEBREX": "CELEBREX"
            },
            "AUROBINDO PHARMA": {
              "CELECOXIB": "CELECOXIB"
            },
            "AVKARE": {
              "CELECOXIB": "CELECOXIB"
            },
            "AVPAK": {
              "CELECOXIB": "CELECOXIB"
            },
            "BIOGEN PHARMACEUTICALS": {
              "CELEBREX": "CELEBREX"
            },
            "BLENHEIM PHARMACAL": {
              "CELECOXIB": "CELECOXIB"
            },
            "BRYANT RANCH PREPACK": {
              "CELEBREX": "CELEBREX",
              "CELECOXIB": "CELECOXIB"
            },
            "CAMBRIDGE THERAPEUTICS TECHN": {
              "CELECOXIB": "CELECOXIB"
            },
            "CIPLA USA": {
              "CELECOXIB": "CELECOXIB"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "CELEBREX": "CELEBREX"
            },
            "DENTON PHARMA": {
              "CELECOXIB": "CELECOXIB"
            },
            "DIRECT RX": {
              "CELEBREX": "CELEBREX",
              "CELECOXIB": "CELECOXIB"
            },
            "ENOVACHEM MANUFACTURING": {
              "CELECOXIB": "CELECOXIB"
            },
            "EPIC PHARMA": {
              "CELECOXIB": "CELECOXIB"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "CELECOXIB": "CELECOXIB"
            },
            "GREENSTONE": {
              "CELECOXIB": "CELECOXIB"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "CELEBREX": "CELEBREX"
            },
            "LIBERTY PHARMACEUTICAL": {
              "CELEBREX": "CELEBREX"
            },
            "LUPIN PHARMACEUTICALS": {
              "CELECOXIB": "CELECOXIB"
            },
            "MACLEODS PHARMACEUTICALS": {
              "CELECOXIB": "CELECOXIB"
            },
            "MAJOR PHARMACEUTICALS": {
              "CELECOXIB": "CELECOXIB"
            },
            "MAS MANAGEMENT GROUP": {
              "CELECOXIB": "CELECOXIB"
            },
            "MEDSTONE PHARMA": {
              "CELECOXIB": "CELECOXIB"
            },
            "MEDVANTX": {
              "CELEBREX": "CELEBREX"
            },
            "MICRO LABORATORIES": {
              "CELECOXIB": "CELECOXIB"
            },
            "MYLAN": {
              "CELECOXIB": "CELECOXIB"
            },
            "MYLAN INSTITUTIONAL": {
              "CELECOXIB": "CELECOXIB"
            },
            "NIVAGEN PHARMACEUTICALS": {
              "CELECOXIB": "CELECOXIB"
            },
            "NORTHSTAR RX": {
              "CELECOXIB": "CELECOXIB"
            },
            "NUCARE PHARMACEUTICALS": {
              "CELEBREX": "CELEBREX"
            },
            "PDRX PHARMACEUTICAL": {
              "CELEBREX": "CELEBREX",
              "CELECOXIB": "CELECOXIB"
            },
            "PFIZER U.S.": {
              "CELEBREX": "CELEBREX"
            },
            "PREFERRED PHARMACEUTICALS": {
              "CELECOXIB": "CELECOXIB"
            },
            "PROFICIENT RX": {
              "CELECOXIB": "CELECOXIB"
            },
            "QUALITY CARE": {
              "CELEBREX": "CELEBREX"
            },
            "QUALITY CARE PRODUCTS": {
              "CELECOXIB": "CELECOXIB"
            },
            "RXCHANGE CO": {
              "CELEBREX": "CELEBREX",
              "CELECOXIB": "CELECOXIB"
            },
            "ST MARY'S MPP": {
              "CELEBREX": "CELEBREX",
              "CELECOXIB": "CELECOXIB"
            },
            "SYDON LABS": {
              "CELECOXIB": "CELECOXIB"
            },
            "TEVA PHARMACEUTICALS USA": {
              "CELECOXIB": "CELECOXIB"
            },
            "TORRENT PHARMACEUTICALS": {
              "CELECOXIB": "CELECOXIB"
            },
            "TRIGEN LABORATORIES": {
              "CELECOXIB": "CELECOXIB"
            },
            "UNIT DOSE SERVICES": {
              "CELEBREX": "CELEBREX",
              "CELECOXIB": "CELECOXIB"
            }
          },
          "Nonsteroidal Anti-inflammatory Agent Combinations": {
            "A-S MEDICATION SOLUTIONS": {
              "ARTHROTEC": "ARTHROTEC"
            },
            "ACTAVIS PHARMA": {
              "DICLOFENAC-MISOPROSTOL": "DICLOFENAC-MISOPROSTOL"
            },
            "ALTURA PHARMACEUTICALS": {
              "ARTHROTEC": "ARTHROTEC"
            },
            "AMERICAN HEALTH PACKAGING": {
              "DICLOFENAC-MISOPROSTOL": "DICLOFENAC-MISOPROSTOL"
            },
            "AMNEAL PHARMACEUTICALS": {
              "DICLOFENAC-MISOPROSTOL": "DICLOFENAC-MISOPROSTOL"
            },
            "AVKARE": {
              "DICLOFENAC-MISOPROSTOL": "DICLOFENAC-MISOPROSTOL"
            },
            "BLENHEIM PHARMACAL": {
              "DICLOFENAC-MISOPROSTOL": "DICLOFENAC-MISOPROSTOL"
            },
            "BLUE POINT LABORATORIES": {
              "DICLOFENAC-MISOPROSTOL": "DICLOFENAC-MISOPROSTOL"
            },
            "BRYANT RANCH PREPACK": {
              "ARTHROTEC": "ARTHROTEC"
            },
            "DASH PHARMACEUTICALS": {
              "DICLOFENAC-MISOPROSTOL": "DICLOFENAC-MISOPROSTOL"
            },
            "DIRECT RX": {
              "DICLOFENAC-MISOPROSTOL": "DICLOFENAC-MISOPROSTOL"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "NAPROXEN-ESOMEPRAZOLE": "NAPROXEN-ESOMEPRAZOLE"
            },
            "EAGLE PHARMACEUTICALS": {
              "DICLOFENAC-MISOPROSTOL": "DICLOFENAC-MISOPROSTOL"
            },
            "ENOVACHEM MANUFACTURING": {
              "KETOROCAINE-L": "KETOROCAINE-L",
              "KETOROCAINE-LM": "KETOROCAINE-LM",
              "TORONOVA II SUIK": "TORONOVA II SUIK",
              "TORONOVA SUIK": "TORONOVA SUIK",
              "YBUPHEN": "YBUPHEN"
            },
            "GENPAK SOLUTIONS": {
              "PREVIDOLRX ANALGESIC": "PREVIDOLRX ANALGESIC",
              "PREVIDOLRX PLUS ANALGESIC": "PREVIDOLRX PLUS ANALGESIC"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "ADVIL DUAL ACTION": "ADVIL DUAL ACTION"
            },
            "GM PHARMACEUTICALS": {
              "IC 400": "IC 400",
              "IC 800": "IC 800"
            },
            "GREENSTONE": {
              "DICLOFENAC-MISOPROSTOL": "DICLOFENAC-MISOPROSTOL"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "ARTHROTEC": "ARTHROTEC"
            },
            "HORIZON THERAPEUTICS USA": {
              "DUEXIS": "DUEXIS",
              "VIMOVO": "VIMOVO"
            },
            "LUPIN PHARMACEUTICALS": {
              "NAPROXEN-ESOMEPRAZOLE": "NAPROXEN-ESOMEPRAZOLE"
            },
            "MAS MANAGEMENT GROUP": {
              "CAPXIB": "CAPXIB",
              "LIDOXIB": "LIDOXIB"
            },
            "NEPHRON STERILE COMPOUNDING": {
              "KETOROLAC-BUPIV-KETAMINE": "KETOROLAC-BUPIV-KETAMINE",
              "KETOROLAC-ROPIV-KETAMINE": "KETOROLAC-ROPIV-KETAMINE"
            },
            "NUCARE PHARMACEUTICALS": {
              "ARTHROTEC": "ARTHROTEC",
              "NUDICLO TABPAK": "NUDICLO TABPAK",
              "NUDROXIPAK": "NUDROXIPAK"
            },
            "PATCHWERX LABS": {
              "DERMA SILKRX ANODYNEXA PAK": "DERMA SILKRX ANODYNEXA PAK",
              "DERMA SILKRX DICLOPAK": "DERMA SILKRX DICLOPAK"
            },
            "PDRX PHARMACEUTICAL": {
              "ARTHROTEC": "ARTHROTEC"
            },
            "PFIZER U.S.": {
              "ARTHROTEC": "ARTHROTEC"
            },
            "PHARMACEUTICA NORTH AMERICA": {
              "ACTIVE INJECTION KET-L": "ACTIVE INJECTION KET-L",
              "ACTIVE INJECTION KETMARC-L": "ACTIVE INJECTION KETMARC-L"
            },
            "PREFERRED PHARMACEUTICALS": {
              "DICLOFENAC-MISOPROSTOL": "DICLOFENAC-MISOPROSTOL"
            },
            "PRIMARY PHARMACEUTICALS": {
              "INAVIX": "INAVIX",
              "LIDOVIX": "LIDOVIX"
            },
            "PURE TEK": {
              "DERMACINRX ANALGESIC COMBOPAK": "DERMACINRX ANALGESIC COMBOPAK",
              "DERMACINRX INFLAMMATRAL PAK": "DERMACINRX INFLAMMATRAL PAK",
              "INFLAMMACIN": "INFLAMMACIN"
            },
            "QUALITY CARE": {
              "ARTHROTEC": "ARTHROTEC"
            },
            "SHORELINE PHARMACEUTICALS": {
              "XENAFLAMM": "XENAFLAMM"
            },
            "STERLING KNIGHT PHARMACEUTICAL": {
              "FLEXIPAK": "FLEXIPAK",
              "FLEXIZOL COMBIPAK": "FLEXIZOL COMBIPAK"
            },
            "TERRAIN PHARMACEUTICALS": {
              "READYSHARP ANESTH + KETOROLAC": "READYSHARP ANESTH + KETOROLAC"
            },
            "TMIG": {
              "INFLAMMATION REDUCTION PACK": "INFLAMMATION REDUCTION PACK"
            },
            "TOTAL PHARMACY SUPPLY": {
              "NAPROPAK": "NAPROPAK",
              "NAPROPAK COOL": "NAPROPAK COOL"
            },
            "V2 PHARMA": {
              "INFLATHERM": "INFLATHERM"
            }
          },
          "Nonsteroidal Anti-inflammatory Agents (NSAIDs)": {
            "A-S MEDICATION SOLUTIONS": {
              "ALEVE": "ALEVE",
              "CHILDRENS IBUPROFEN": "CHILDRENS IBUPROFEN",
              "DAYPRO": "DAYPRO",
              "DICLOFENAC POTASSIUM": "DICLOFENAC POTASSIUM",
              "DICLOFENAC SODIUM": "DICLOFENAC SODIUM",
              "DICLOFENAC SODIUM ER": "DICLOFENAC SODIUM ER",
              "ETODOLAC": "ETODOLAC",
              "ETODOLAC ER": "ETODOLAC ER",
              "FLURBIPROFEN": "FLURBIPROFEN",
              "IBUPROFEN": "IBUPROFEN",
              "IBUPROFEN CHILDRENS": "IBUPROFEN CHILDRENS",
              "INDOMETHACIN": "INDOMETHACIN",
              "INDOMETHACIN ER": "INDOMETHACIN ER",
              "KETOPROFEN": "KETOPROFEN",
              "KETOROLAC TROMETHAMINE": "KETOROLAC TROMETHAMINE",
              "MELOXICAM": "MELOXICAM",
              "MOBIC": "MOBIC",
              "NABUMETONE": "NABUMETONE",
              "NAPROSYN": "NAPROSYN",
              "NAPROXEN": "NAPROXEN",
              "NAPROXEN DR": "NAPROXEN DR",
              "NAPROXEN SODIUM": "NAPROXEN SODIUM",
              "OXAPROZIN": "OXAPROZIN",
              "PIROXICAM": "PIROXICAM",
              "SPRIX": "SPRIX",
              "SULINDAC": "SULINDAC"
            },
            "ACELLA PHARMACEUTICALS": {
              "NAPROXEN": "NAPROXEN"
            },
            "ACTAVIS": {
              "INFANTS IBUPROFEN": "INFANTS IBUPROFEN"
            },
            "ACTAVIS ELIZABETH": {
              "DICLOFENAC SODIUM": "DICLOFENAC SODIUM",
              "DICLOFENAC SODIUM ER": "DICLOFENAC SODIUM ER"
            },
            "ACTAVIS MID ATLANTIC": {
              "CHILDRENS IBUPROFEN": "CHILDRENS IBUPROFEN",
              "IBUPROFEN": "IBUPROFEN",
              "IBUPROFEN CHILDRENS": "IBUPROFEN CHILDRENS"
            },
            "ACTAVIS PHARMA": {
              "DICLOFENAC SODIUM ER": "DICLOFENAC SODIUM ER",
              "NABUMETONE": "NABUMETONE",
              "NAPROXEN SODIUM ER": "NAPROXEN SODIUM ER",
              "SULINDAC": "SULINDAC"
            },
            "AIDAREX PHARMACEUTICALS": {
              "DICLOFENAC POTASSIUM": "DICLOFENAC POTASSIUM",
              "DICLOFENAC SODIUM": "DICLOFENAC SODIUM",
              "DICLOFENAC SODIUM ER": "DICLOFENAC SODIUM ER",
              "ETODOLAC": "ETODOLAC",
              "ETODOLAC ER": "ETODOLAC ER",
              "FENOPROFEN CALCIUM": "FENOPROFEN CALCIUM",
              "FLURBIPROFEN": "FLURBIPROFEN",
              "IBUPROFEN": "IBUPROFEN",
              "INDOMETHACIN": "INDOMETHACIN",
              "INDOMETHACIN ER": "INDOMETHACIN ER",
              "KETOPROFEN": "KETOPROFEN",
              "KETOROLAC TROMETHAMINE": "KETOROLAC TROMETHAMINE",
              "MELOXICAM": "MELOXICAM",
              "NABUMETONE": "NABUMETONE",
              "NAPROXEN": "NAPROXEN",
              "NAPROXEN DR": "NAPROXEN DR",
              "NAPROXEN SODIUM": "NAPROXEN SODIUM",
              "OXAPROZIN": "OXAPROZIN",
              "PIROXICAM": "PIROXICAM",
              "SULINDAC": "SULINDAC"
            },
            "AIPING PHARMACEUTICAL": {
              "IBUPROFEN": "IBUPROFEN"
            },
            "ALEXSO": {
              "FLURBIPROFEN": "FLURBIPROFEN"
            },
            "ALIVIO MEDICAL PRODUCTS": {
              "IBUPROFEN": "IBUPROFEN",
              "NAPROXEN": "NAPROXEN"
            },
            "ALLEGIS HOLDINGS": {
              "NAPROXEN": "NAPROXEN"
            },
            "ALMAJECT": {
              "KETOROLAC TROMETHAMINE": "KETOROLAC TROMETHAMINE"
            },
            "ALMATICA": {
              "NAPRELAN": "NAPRELAN"
            },
            "ALTURA PHARMACEUTICALS": {
              "CHILDRENS IBUPROFEN": "CHILDRENS IBUPROFEN",
              "DICLOFENAC SODIUM": "DICLOFENAC SODIUM",
              "DICLOFENAC SODIUM ER": "DICLOFENAC SODIUM ER",
              "ETODOLAC": "ETODOLAC",
              "ETODOLAC ER": "ETODOLAC ER",
              "IBUPROFEN": "IBUPROFEN",
              "INDOMETHACIN": "INDOMETHACIN",
              "INDOMETHACIN ER": "INDOMETHACIN ER",
              "KETOPROFEN": "KETOPROFEN",
              "KETOROLAC TROMETHAMINE": "KETOROLAC TROMETHAMINE",
              "MOBIC": "MOBIC",
              "NABUMETONE": "NABUMETONE",
              "NAPROXEN": "NAPROXEN",
              "NAPROXEN DR": "NAPROXEN DR",
              "NAPROXEN SODIUM": "NAPROXEN SODIUM",
              "OXAPROZIN": "OXAPROZIN",
              "PIROXICAM": "PIROXICAM",
              "SULINDAC": "SULINDAC"
            },
            "ALTUS PHARMA": {
              "NAPROXEN SODIUM": "NAPROXEN SODIUM"
            },
            "ALVIX LABORATORIES": {
              "FLURBIPROFEN": "FLURBIPROFEN",
              "IBUPROFEN": "IBUPROFEN",
              "NAPROXEN": "NAPROXEN"
            },
            "ALVOGEN": {
              "KETOROLAC TROMETHAMINE": "KETOROLAC TROMETHAMINE",
              "NAPROXEN SODIUM ER": "NAPROXEN SODIUM ER"
            },
            "AMERICAN HEALTH PACKAGING": {
              "DICLOFENAC SODIUM": "DICLOFENAC SODIUM",
              "ETODOLAC": "ETODOLAC",
              "IBUPROFEN": "IBUPROFEN",
              "INDOMETHACIN ER": "INDOMETHACIN ER",
              "MELOXICAM": "MELOXICAM",
              "NABUMETONE": "NABUMETONE",
              "NAPROXEN": "NAPROXEN",
              "NAPROXEN SODIUM": "NAPROXEN SODIUM",
              "OXAPROZIN": "OXAPROZIN"
            },
            "AMERICAN PHARMA INGREDIENTS": {
              "FLURBIPROFEN": "FLURBIPROFEN",
              "INDOMETHACIN": "INDOMETHACIN",
              "PIROXICAM": "PIROXICAM"
            },
            "AMERICAN SALES COMPANY": {
              "CHILDRENS IBUPROFEN": "CHILDRENS IBUPROFEN",
              "IBUPROFEN": "IBUPROFEN",
              "IBUPROFEN CHILDRENS": "IBUPROFEN CHILDRENS"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP CHILDRENS IBUPROFEN": "GNP CHILDRENS IBUPROFEN",
              "GNP IBUPROFEN": "GNP IBUPROFEN",
              "GNP IBUPROFEN CHILDRENS": "GNP IBUPROFEN CHILDRENS",
              "GNP IBUPROFEN JUNIOR STRENGTH": "GNP IBUPROFEN JUNIOR STRENGTH",
              "GNP NAPROXEN SODIUM": "GNP NAPROXEN SODIUM"
            },
            "AMNEAL PHARMACEUTICALS": {
              "ETODOLAC": "ETODOLAC",
              "IBUPROFEN": "IBUPROFEN",
              "INDOMETHACIN ER": "INDOMETHACIN ER",
              "NABUMETONE": "NABUMETONE",
              "NAPROXEN": "NAPROXEN",
              "NAPROXEN SODIUM": "NAPROXEN SODIUM",
              "OXAPROZIN": "OXAPROZIN"
            },
            "AMPHASTAR-IMS": {
              "KETOROLAC TROMETHAMINE": "KETOROLAC TROMETHAMINE"
            },
            "ANI  PHARMACEUTICALS": {
              "ETODOLAC": "ETODOLAC"
            },
            "APHENA PHARMA SOLUTIONS": {
              "DICLOFENAC SODIUM": "DICLOFENAC SODIUM",
              "ETODOLAC": "ETODOLAC",
              "IBUPROFEN": "IBUPROFEN",
              "MELOXICAM": "MELOXICAM",
              "NAPROXEN": "NAPROXEN",
              "PIROXICAM": "PIROXICAM",
              "SULINDAC": "SULINDAC"
            },
            "API SOLUTIONS": {
              "FLURBIPROFEN": "FLURBIPROFEN",
              "PIROXICAM": "PIROXICAM"
            },
            "APOTEX": {
              "ETODOLAC": "ETODOLAC",
              "MELOXICAM": "MELOXICAM"
            },
            "APOTHECA": {
              "DICLOFENAC SODIUM": "DICLOFENAC SODIUM",
              "IBUPROFEN": "IBUPROFEN",
              "NABUMETONE": "NABUMETONE",
              "NAPROXEN": "NAPROXEN",
              "OXAPROZIN": "OXAPROZIN",
              "PIROXICAM": "PIROXICAM"
            },
            "APOTHECA SUPPLY": {
              "FLURBIPROFEN": "FLURBIPROFEN",
              "IBUPROFEN": "IBUPROFEN",
              "PIROXICAM": "PIROXICAM"
            },
            "ARCHIS PHARMA": {
              "DICLOFENAC POTASSIUM": "DICLOFENAC POTASSIUM"
            },
            "ASAFI PHARMACEUTICAL": {
              "IBUPROFEN": "IBUPROFEN"
            },
            "ASCEND LABORATORIES": {
              "IBUPROFEN": "IBUPROFEN"
            },
            "ASSERTIO THERAPEUTICS": {
              "ZIPSOR": "ZIPSOR"
            },
            "ATHENA BIOSCIENCE": {
              "NAPROSYN": "NAPROSYN"
            },
            "ATHENEX PHARMACEUTICAL": {
              "KETOROLAC TROMETHAMINE": "KETOROLAC TROMETHAMINE"
            },
            "ATLANTIC BIOLOGICALS": {
              "CHILDRENS IBUPROFEN": "CHILDRENS IBUPROFEN",
              "NAPROXEN SODIUM": "NAPROXEN SODIUM"
            },
            "ATTIX PHARMACEUTICALS": {
              "FLURBIPROFEN": "FLURBIPROFEN"
            },
            "AUBURN PHARMACEUTICAL": {
              "NAPROXEN SODIUM": "NAPROXEN SODIUM",
              "PROVIL": "PROVIL"
            },
            "AUROBINDO PHARMA": {
              "IBUPROFEN": "IBUPROFEN",
              "INDOMETHACIN ER": "INDOMETHACIN ER",
              "MELOXICAM": "MELOXICAM",
              "NAPROXEN": "NAPROXEN",
              "NAPROXEN SODIUM": "NAPROXEN SODIUM"
            },
            "AURORA HEALTHCARE": {
              "IBUPROFEN": "IBUPROFEN",
              "NAPROXEN SODIUM": "NAPROXEN SODIUM"
            },
            "AVET PHARMACEUTICALS": {
              "INDOMETHACIN": "INDOMETHACIN",
              "KETOPROFEN": "KETOPROFEN",
              "SULINDAC": "SULINDAC"
            },
            "AVKARE": {
              "DICLOFENAC SODIUM": "DICLOFENAC SODIUM",
              "ETODOLAC": "ETODOLAC",
              "IBUPROFEN": "IBUPROFEN",
              "KETOPROFEN": "KETOPROFEN",
              "KETOROLAC TROMETHAMINE": "KETOROLAC TROMETHAMINE",
              "NABUMETONE": "NABUMETONE",
              "NAPROXEN": "NAPROXEN",
              "NAPROXEN DR": "NAPROXEN DR",
              "NAPROXEN SODIUM": "NAPROXEN SODIUM",
              "OXAPROZIN": "OXAPROZIN",
              "PIROXICAM": "PIROXICAM"
            },
            "AVPAK": {
              "INDOMETHACIN": "INDOMETHACIN",
              "INDOMETHACIN ER": "INDOMETHACIN ER",
              "MELOXICAM": "MELOXICAM",
              "NABUMETONE": "NABUMETONE",
              "NAPROXEN": "NAPROXEN",
              "NAPROXEN SODIUM": "NAPROXEN SODIUM"
            },
            "AYURAX": {
              "INDOMETHACIN": "INDOMETHACIN"
            },
            "B & B PHARMACEUTICALS": {
              "FLURBIPROFEN": "FLURBIPROFEN",
              "IBUPROFEN": "IBUPROFEN"
            },
            "BASIC DRUGS": {
              "NAPROXEN SODIUM": "NAPROXEN SODIUM"
            },
            "BASIEM": {
              "TIVORBEX": "TIVORBEX"
            },
            "BAUDAX BIO": {
              "ANJESO": "ANJESO"
            },
            "BAXTER HEALTHCARE CORP": {
              "KETOROLAC TROMETHAMINE": "KETOROLAC TROMETHAMINE"
            },
            "BAYER CONSUMER": {
              "ALEVE": "ALEVE"
            },
            "BELCHER PHARMACEUTICALS": {
              "FENOPROFEN CALCIUM": "FENOPROFEN CALCIUM",
              "MEFENAMIC ACID": "MEFENAMIC ACID",
              "MELOXICAM": "MELOXICAM"
            },
            "BELMORA": {
              "FLANAX PAIN RELIEF": "FLANAX PAIN RELIEF"
            },
            "BERGEN BRUNSWIG": {
              "GNP ALL DAY PAIN RELIEF": "GNP ALL DAY PAIN RELIEF",
              "GNP CHILDRENS IBUPROFEN": "GNP CHILDRENS IBUPROFEN",
              "GNP IBUPROFEN": "GNP IBUPROFEN",
              "GNP IBUPROFEN INFANTS": "GNP IBUPROFEN INFANTS",
              "GNP IBUPROFEN JUNIOR STRENGTH": "GNP IBUPROFEN JUNIOR STRENGTH"
            },
            "BI COASTAL PHARMACEUTICAL": {
              "IBUPROFEN": "IBUPROFEN"
            },
            "BIOMES PHARMACEUTICALS": {
              "IBUPROFEN": "IBUPROFEN",
              "NAPROXEN": "NAPROXEN"
            },
            "BIOPHYSICS PHARMA": {
              "BASE FIVE NAPROXEN COMPONENT": "BASE FIVE NAPROXEN COMPONENT"
            },
            "BLENHEIM PHARMACAL": {
              "DICLOFENAC SODIUM": "DICLOFENAC SODIUM",
              "ETODOLAC": "ETODOLAC",
              "IBUPROFEN": "IBUPROFEN",
              "INDOMETHACIN": "INDOMETHACIN",
              "INDOMETHACIN ER": "INDOMETHACIN ER",
              "KETOROLAC TROMETHAMINE": "KETOROLAC TROMETHAMINE",
              "MELOXICAM": "MELOXICAM",
              "NABUMETONE": "NABUMETONE",
              "NAPROXEN": "NAPROXEN",
              "NAPROXEN SODIUM": "NAPROXEN SODIUM"
            },
            "BLU PHARMACEUTICALS": {
              "SULINDAC": "SULINDAC"
            },
            "BLUCREST PHARMACEUTICALS": {
              "RELAFEN": "RELAFEN"
            },
            "BLUE POINT LABORATORIES": {
              "DICLOFENAC SODIUM": "DICLOFENAC SODIUM",
              "IBUPROFEN CHILDRENS": "IBUPROFEN CHILDRENS"
            },
            "BOEHRINGER INGELHEIM": {
              "MOBIC": "MOBIC"
            },
            "BPI LABS LLC": {
              "FENOPROFEN CALCIUM": "FENOPROFEN CALCIUM",
              "FENORTHO": "FENORTHO",
              "FLURBIPROFEN": "FLURBIPROFEN"
            },
            "BRECKENRIDGE": {
              "MEFENAMIC ACID": "MEFENAMIC ACID"
            },
            "BRYANT RANCH PREPACK": {
              "DICLOFENAC POTASSIUM": "DICLOFENAC POTASSIUM",
              "DICLOFENAC SODIUM": "DICLOFENAC SODIUM",
              "DICLOFENAC SODIUM ER": "DICLOFENAC SODIUM ER",
              "ETODOLAC": "ETODOLAC",
              "ETODOLAC ER": "ETODOLAC ER",
              "FENOPROFEN CALCIUM": "FENOPROFEN CALCIUM",
              "FLURBIPROFEN": "FLURBIPROFEN",
              "IBUPROFEN": "IBUPROFEN",
              "INDOMETHACIN": "INDOMETHACIN",
              "INDOMETHACIN ER": "INDOMETHACIN ER",
              "KETOPROFEN": "KETOPROFEN",
              "KETOROLAC TROMETHAMINE": "KETOROLAC TROMETHAMINE",
              "MELOXICAM": "MELOXICAM",
              "NABUMETONE": "NABUMETONE",
              "NALFON": "NALFON",
              "NAPROXEN": "NAPROXEN",
              "NAPROXEN DR": "NAPROXEN DR",
              "NAPROXEN SODIUM": "NAPROXEN SODIUM",
              "OXAPROZIN": "OXAPROZIN",
              "PIROXICAM": "PIROXICAM",
              "SULINDAC": "SULINDAC"
            },
            "BURKE THERAPEUTICS": {
              "FENOPROFEN CALCIUM": "FENOPROFEN CALCIUM"
            },
            "CADISTA": {
              "INDOMETHACIN ER": "INDOMETHACIN ER"
            },
            "CALIFORNIA PHARMACEUTICALS": {
              "ETODOLAC ER": "ETODOLAC ER",
              "OXAPROZIN": "OXAPROZIN"
            },
            "CAMBER CONSUMER CARE": {
              "ALL DAY PAIN RELIEF": "ALL DAY PAIN RELIEF",
              "IBUPROFEN": "IBUPROFEN",
              "IBUPROFEN CHILDRENS": "IBUPROFEN CHILDRENS",
              "NAPROXEN SODIUM": "NAPROXEN SODIUM"
            },
            "CAMBER PHARMACEUTICALS": {
              "INDOMETHACIN": "INDOMETHACIN",
              "INDOMETHACIN ER": "INDOMETHACIN ER"
            },
            "CAMBRIDGE THERAPEUTICS TECHN": {
              "DICLOFENAC SODIUM": "DICLOFENAC SODIUM"
            },
            "CAMERON PHARMACEUTICALS": {
              "NAPROXEN SODIUM": "NAPROXEN SODIUM"
            },
            "CANTON LABORATORIES": {
              "ANAPROX DS": "ANAPROX DS",
              "EC-NAPROSYN": "EC-NAPROSYN",
              "NAPROSYN": "NAPROSYN"
            },
            "CARDINAL HEALTH REPACKAGING SO": {
              "IBUPROFEN": "IBUPROFEN",
              "KETOROLAC TROMETHAMINE": "KETOROLAC TROMETHAMINE"
            },
            "CARLSBAD TECHNOLOGIES": {
              "DICLOFENAC SODIUM": "DICLOFENAC SODIUM",
              "MELOXICAM": "MELOXICAM"
            },
            "CARWIN ASSOCIATES": {
              "RELAFEN DS": "RELAFEN DS"
            },
            "CHAIN DRUG CONSORTIUM": {
              "CHILDRENS IBUPROFEN": "CHILDRENS IBUPROFEN",
              "IBUPROFEN": "IBUPROFEN",
              "IBUPROFEN CHILDRENS": "IBUPROFEN CHILDRENS",
              "NAPROXEN SODIUM": "NAPROXEN SODIUM"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "IBUPROFEN": "IBUPROFEN",
              "QC CHILDRENS IBUPROFEN": "QC CHILDRENS IBUPROFEN",
              "QC IBUPROFEN": "QC IBUPROFEN",
              "QC IBUPROFEN IB": "QC IBUPROFEN IB",
              "QC IBUPROFEN INFANTS": "QC IBUPROFEN INFANTS",
              "QC NAPROXEN SODIUM": "QC NAPROXEN SODIUM"
            },
            "CHATTEM INC": {
              "PAMPRIN ALL DAY RELIEF MAX ST": "PAMPRIN ALL DAY RELIEF MAX ST"
            },
            "CIPLA USA": {
              "MELOXICAM": "MELOXICAM",
              "NABUMETONE": "NABUMETONE",
              "NAPROXEN": "NAPROXEN",
              "NAPROXEN DR": "NAPROXEN DR"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "DICLOFENAC POTASSIUM": "DICLOFENAC POTASSIUM",
              "DICLOFENAC SODIUM": "DICLOFENAC SODIUM",
              "ETODOLAC": "ETODOLAC",
              "IBUPROFEN": "IBUPROFEN",
              "KETOROLAC TROMETHAMINE": "KETOROLAC TROMETHAMINE",
              "MELOXICAM": "MELOXICAM",
              "NABUMETONE": "NABUMETONE",
              "NAPROXEN": "NAPROXEN",
              "NAPROXEN SODIUM": "NAPROXEN SODIUM",
              "OXAPROZIN": "OXAPROZIN"
            },
            "COSTCO WHOLESALE": {
              "KLS IBUPROFEN": "KLS IBUPROFEN",
              "KLS IBUPROFEN IB": "KLS IBUPROFEN IB",
              "KLS NAPROXEN SODIUM": "KLS NAPROXEN SODIUM"
            },
            "CUMBERLAND PHARMACEUTICALS": {
              "CALDOLOR": "CALDOLOR"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS ALL DAY PAIN RELIEF": "CVS ALL DAY PAIN RELIEF",
              "CVS CHILDRENS IBUPROFEN": "CVS CHILDRENS IBUPROFEN",
              "CVS IBUPROFEN": "CVS IBUPROFEN",
              "CVS IBUPROFEN CHILDRENS": "CVS IBUPROFEN CHILDRENS",
              "CVS IBUPROFEN IB": "CVS IBUPROFEN IB",
              "CVS IBUPROFEN INFANTS": "CVS IBUPROFEN INFANTS",
              "CVS IBUPROFEN JUNIOR STRENGTH": "CVS IBUPROFEN JUNIOR STRENGTH",
              "CVS NAPROXEN SODIUM": "CVS NAPROXEN SODIUM"
            },
            "DARMERICA": {
              "INDOMETHACIN": "INDOMETHACIN"
            },
            "DENTON PHARMA": {
              "DICLOFENAC SODIUM": "DICLOFENAC SODIUM",
              "DICLOFENAC SODIUM ER": "DICLOFENAC SODIUM ER",
              "IBUPROFEN": "IBUPROFEN",
              "INDOMETHACIN ER": "INDOMETHACIN ER",
              "KETOROLAC TROMETHAMINE": "KETOROLAC TROMETHAMINE",
              "NAPROXEN": "NAPROXEN"
            },
            "DIRECT RX": {
              "DICLOFENAC POTASSIUM": "DICLOFENAC POTASSIUM",
              "DICLOFENAC SODIUM": "DICLOFENAC SODIUM",
              "DICLOFENAC SODIUM ER": "DICLOFENAC SODIUM ER",
              "ETODOLAC": "ETODOLAC",
              "IBUPROFEN": "IBUPROFEN",
              "INDOMETHACIN": "INDOMETHACIN",
              "INDOMETHACIN ER": "INDOMETHACIN ER",
              "KETOROLAC TROMETHAMINE": "KETOROLAC TROMETHAMINE",
              "MELOXICAM": "MELOXICAM",
              "NABUMETONE": "NABUMETONE",
              "NAPROXEN": "NAPROXEN",
              "NAPROXEN SODIUM": "NAPROXEN SODIUM",
              "OXAPROZIN": "OXAPROZIN",
              "PIROXICAM": "PIROXICAM",
              "SULINDAC": "SULINDAC"
            },
            "DOVER PHARMACEUTICALS": {
              "ADDAPRIN": "ADDAPRIN",
              "DYSPEL": "DYSPEL"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "IBU": "IBU",
              "MELOXICAM": "MELOXICAM",
              "NABUMETONE": "NABUMETONE",
              "NAPROXEN SODIUM": "NAPROXEN SODIUM",
              "OXAPROZIN": "OXAPROZIN"
            },
            "EDENBRIDGE PHARMACEUTICALS": {
              "ETODOLAC": "ETODOLAC"
            },
            "ENCORE SCIENTIFIC": {
              "FLURBIPROFEN": "FLURBIPROFEN",
              "IBUPROFEN": "IBUPROFEN"
            },
            "ENOVACHEM MANUFACTURING": {
              "IBUPROFEN": "IBUPROFEN",
              "MELOXICAM": "MELOXICAM",
              "NAPROXEN": "NAPROXEN"
            },
            "EPIC PHARMA": {
              "SULINDAC": "SULINDAC"
            },
            "EQUALINE": {
              "EQL ALL DAY PAIN RELIEF": "EQL ALL DAY PAIN RELIEF",
              "EQL ALL DAY RELIEF": "EQL ALL DAY RELIEF",
              "EQL CHILDRENS IBUPROFEN": "EQL CHILDRENS IBUPROFEN",
              "EQL IBUPROFEN": "EQL IBUPROFEN",
              "EQL IBUPROFEN INFANTS": "EQL IBUPROFEN INFANTS",
              "EQL IBUPROFEN JUNIOR STRENGTH": "EQL IBUPROFEN JUNIOR STRENGTH",
              "EQL NAPROXEN SODIUM": "EQL NAPROXEN SODIUM"
            },
            "EXELAN PHARMACEUTICALS": {
              "MELOXICAM": "MELOXICAM",
              "NABUMETONE": "NABUMETONE",
              "NAPROXEN": "NAPROXEN"
            },
            "FAGRON": {
              "FLURBIPROFEN": "FLURBIPROFEN",
              "IBUPROFEN": "IBUPROFEN",
              "INDOMETHACIN": "INDOMETHACIN",
              "MEFENAMIC ACID": "MEFENAMIC ACID",
              "NAPROXEN": "NAPROXEN",
              "PIROXICAM": "PIROXICAM"
            },
            "FOSUN PHARMA USA": {
              "KETOROLAC TROMETHAMINE": "KETOROLAC TROMETHAMINE"
            },
            "FREEDOM PHARMACEUTICALS": {
              "FLURBIPROFEN": "FLURBIPROFEN",
              "IBUPROFEN": "IBUPROFEN",
              "INDOMETHACIN": "INDOMETHACIN",
              "MEFENAMIC ACID": "MEFENAMIC ACID",
              "NAPROXEN": "NAPROXEN",
              "PIROXICAM": "PIROXICAM"
            },
            "FRESENIUS KABI USA": {
              "INDOMETHACIN SODIUM": "INDOMETHACIN SODIUM",
              "KETOROLAC TROMETHAMINE": "KETOROLAC TROMETHAMINE"
            },
            "GEISS DESTIN & DUNN": {
              "GOODSENSE IBUPROFEN": "GOODSENSE IBUPROFEN",
              "GOODSENSE IBUPROFEN CHILDRENS": "GOODSENSE IBUPROFEN CHILDRENS",
              "GOODSENSE IBUPROFEN INFANTS": "GOODSENSE IBUPROFEN INFANTS",
              "GOODSENSE IBUPROFEN JUNIOR ST": "GOODSENSE IBUPROFEN JUNIOR ST",
              "GOODSENSE NAPROXEN SODIUM": "GOODSENSE NAPROXEN SODIUM"
            },
            "GENENTECH": {
              "ANAPROX DS": "ANAPROX DS",
              "EC-NAPROSYN": "EC-NAPROSYN",
              "NAPROSYN": "NAPROSYN"
            },
            "GENPAK SOLUTIONS": {
              "FLURBIPROFEN": "FLURBIPROFEN"
            },
            "GENSCO LABORATORIES": {
              "NAPROXEN SODIUM ER": "NAPROXEN SODIUM ER"
            },
            "GENTEX PHARMA LLC": {
              "FENOPROFEN CALCIUM": "FENOPROFEN CALCIUM"
            },
            "GERI-CARE": {
              "IBUPROFEN": "IBUPROFEN",
              "NAPROXEN SODIUM": "NAPROXEN SODIUM"
            },
            "GERITREX CORPORATION": {
              "IBUPROFEN": "IBUPROFEN"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "ADVIL": "ADVIL",
              "ADVIL JUNIOR STRENGTH": "ADVIL JUNIOR STRENGTH",
              "ADVIL LIQUI-GELS MINIS": "ADVIL LIQUI-GELS MINIS",
              "ADVIL MIGRAINE": "ADVIL MIGRAINE",
              "CHILDRENS ADVIL": "CHILDRENS ADVIL",
              "INFANTS ADVIL": "INFANTS ADVIL"
            },
            "GLENMARK PHARMACEUTICALS": {
              "INDOMETHACIN": "INDOMETHACIN",
              "INDOMETHACIN ER": "INDOMETHACIN ER",
              "MELOXICAM": "MELOXICAM",
              "NABUMETONE": "NABUMETONE",
              "NAPROXEN": "NAPROXEN",
              "NAPROXEN SODIUM": "NAPROXEN SODIUM"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "DICLOFENAC SODIUM": "DICLOFENAC SODIUM",
              "ETODOLAC": "ETODOLAC",
              "ETODOLAC ER": "ETODOLAC ER",
              "IBUPROFEN": "IBUPROFEN",
              "NAPROXEN": "NAPROXEN",
              "PIROXICAM": "PIROXICAM",
              "SULINDAC": "SULINDAC"
            },
            "GREENSTONE": {
              "OXAPROZIN": "OXAPROZIN",
              "PIROXICAM": "PIROXICAM"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "ANAPROX DS": "ANAPROX DS",
              "DAYPRO": "DAYPRO",
              "DICLOFENAC POTASSIUM": "DICLOFENAC POTASSIUM",
              "DICLOFENAC SODIUM": "DICLOFENAC SODIUM",
              "DICLOFENAC SODIUM ER": "DICLOFENAC SODIUM ER",
              "EC-NAPROSYN": "EC-NAPROSYN",
              "ETODOLAC": "ETODOLAC",
              "ETODOLAC ER": "ETODOLAC ER",
              "FELDENE": "FELDENE",
              "FENOPROFEN CALCIUM": "FENOPROFEN CALCIUM",
              "FLURBIPROFEN": "FLURBIPROFEN",
              "IBUPROFEN": "IBUPROFEN",
              "IBUPROFEN CHILDRENS": "IBUPROFEN CHILDRENS",
              "INDOMETHACIN": "INDOMETHACIN",
              "INDOMETHACIN ER": "INDOMETHACIN ER",
              "KETOPROFEN": "KETOPROFEN",
              "KETOPROFEN ER": "KETOPROFEN ER",
              "KETOROLAC TROMETHAMINE": "KETOROLAC TROMETHAMINE",
              "MELOXICAM": "MELOXICAM",
              "MOBIC": "MOBIC",
              "NABUMETONE": "NABUMETONE",
              "NAPRELAN": "NAPRELAN",
              "NAPROSYN": "NAPROSYN",
              "NAPROXEN": "NAPROXEN",
              "NAPROXEN DR": "NAPROXEN DR",
              "NAPROXEN SODIUM": "NAPROXEN SODIUM",
              "OXAPROZIN": "OXAPROZIN",
              "PIROXICAM": "PIROXICAM",
              "SULINDAC": "SULINDAC",
              "TOLMETIN SODIUM": "TOLMETIN SODIUM"
            },
            "HANNAFORD BROTHERS": {
              "IBUPROFEN": "IBUPROFEN",
              "IBUPROFEN CHILDRENS": "IBUPROFEN CHILDRENS"
            },
            "HENRY SCHEIN": {
              "IBUPROFEN": "IBUPROFEN"
            },
            "HI-TECH": {
              "IBUPROFEN": "IBUPROFEN"
            },
            "HIKMA": {
              "KETOROLAC TROMETHAMINE": "KETOROLAC TROMETHAMINE",
              "MELOXICAM": "MELOXICAM",
              "NAPROXEN": "NAPROXEN",
              "PIROXICAM": "PIROXICAM"
            },
            "HOSPIRA": {
              "DYLOJECT": "DYLOJECT",
              "INDOMETHACIN SODIUM": "INDOMETHACIN SODIUM",
              "KETOROLAC TROMETHAMINE": "KETOROLAC TROMETHAMINE"
            },
            "HUMCO": {
              "FLURBIPROFEN": "FLURBIPROFEN",
              "IBUPROFEN": "IBUPROFEN",
              "INDOMETHACIN": "INDOMETHACIN",
              "PIROXICAM": "PIROXICAM"
            },
            "HURON PHARMACEUTICALS": {
              "FLURBIPROFEN": "FLURBIPROFEN",
              "PIROXICAM": "PIROXICAM"
            },
            "HY-VEE": {
              "HY-VEE ALL DAY RELIEF": "HY-VEE ALL DAY RELIEF",
              "HYVEE IBUPROFEN CHILDRENS": "HYVEE IBUPROFEN CHILDRENS"
            },
            "IMPAX GENERICS": {
              "NABUMETONE": "NABUMETONE"
            },
            "INTERNATIONAL LABS, LLC": {
              "MELOXICAM": "MELOXICAM"
            },
            "KALCHEM INTERNATIONAL": {
              "IBUPROFEN": "IBUPROFEN",
              "PIROXICAM": "PIROXICAM"
            },
            "KEY THERAPEUTICS": {
              "NAPROSYN": "NAPROSYN",
              "NAPROXEN": "NAPROXEN"
            },
            "KLE 2": {
              "FENOPROFEN CALCIUM": "FENOPROFEN CALCIUM"
            },
            "KROGER COMPANY": {
              "ALL DAY RELIEF": "ALL DAY RELIEF",
              "CHILDRENS IBUPROFEN": "CHILDRENS IBUPROFEN",
              "IBUPROFEN": "IBUPROFEN",
              "IBUPROFEN JUNIOR STRENGTH": "IBUPROFEN JUNIOR STRENGTH",
              "INFANTS IBUPROFEN": "INFANTS IBUPROFEN"
            },
            "KVK TECH": {
              "INDOMETHACIN ER": "INDOMETHACIN ER"
            },
            "LANDMARK SUPPLY": {
              "IBUPROFEN": "IBUPROFEN"
            },
            "LANNETT": {
              "DICLOFENAC SODIUM ER": "DICLOFENAC SODIUM ER",
              "MELOXICAM": "MELOXICAM"
            },
            "LEADER BRAND PRODUCTS": {
              "ALL DAY PAIN RELIEF": "ALL DAY PAIN RELIEF",
              "CHILDRENS IBUPROFEN": "CHILDRENS IBUPROFEN",
              "CHILDS IBUPROFEN": "CHILDS IBUPROFEN",
              "IBU-DROPS": "IBU-DROPS",
              "IBUPROFEN": "IBUPROFEN",
              "IBUPROFEN CHILDRENS": "IBUPROFEN CHILDRENS",
              "IBUPROFEN INFANTS DROPS": "IBUPROFEN INFANTS DROPS",
              "IBUPROFEN JUNIOR STRENGTH": "IBUPROFEN JUNIOR STRENGTH",
              "INFANTS IBUPROFEN": "INFANTS IBUPROFEN",
              "NAPROXEN SODIUM": "NAPROXEN SODIUM"
            },
            "LEGACY PHARMACEUTICAL PKG": {
              "IBUPROFEN": "IBUPROFEN"
            },
            "LETCO MEDICAL": {
              "FLURBIPROFEN": "FLURBIPROFEN",
              "IBUPROFEN": "IBUPROFEN",
              "INDOMETHACIN": "INDOMETHACIN",
              "NAPROXEN SODIUM": "NAPROXEN SODIUM",
              "PIROXICAM": "PIROXICAM"
            },
            "LIBERTY PHARMACEUTICAL": {
              "DICLOFENAC SODIUM": "DICLOFENAC SODIUM",
              "DICLOFENAC SODIUM ER": "DICLOFENAC SODIUM ER",
              "ETODOLAC": "ETODOLAC",
              "ETODOLAC ER": "ETODOLAC ER",
              "FLURBIPROFEN": "FLURBIPROFEN",
              "IBUPROFEN": "IBUPROFEN",
              "INDOMETHACIN": "INDOMETHACIN",
              "INDOMETHACIN ER": "INDOMETHACIN ER",
              "KETOPROFEN": "KETOPROFEN",
              "KETOROLAC TROMETHAMINE": "KETOROLAC TROMETHAMINE",
              "MELOXICAM": "MELOXICAM",
              "NABUMETONE": "NABUMETONE",
              "NAPROXEN": "NAPROXEN",
              "NAPROXEN DR": "NAPROXEN DR",
              "NAPROXEN SODIUM": "NAPROXEN SODIUM",
              "OXAPROZIN": "OXAPROZIN",
              "PIROXICAM": "PIROXICAM",
              "SULINDAC": "SULINDAC",
              "TOLMETIN SODIUM": "TOLMETIN SODIUM"
            },
            "LUPIN PHARMACEUTICALS": {
              "MEFENAMIC ACID": "MEFENAMIC ACID",
              "MELOXICAM": "MELOXICAM",
              "NABUMETONE": "NABUMETONE"
            },
            "MAGNO-HUMPHRIES LABORATORIES": {
              "IBUPROFEN": "IBUPROFEN",
              "NAPROXEN SODIUM": "NAPROXEN SODIUM"
            },
            "MAJOR PHARMACEUTICALS": {
              "CHILDRENS IBUPROFEN": "CHILDRENS IBUPROFEN",
              "IBU-200": "IBU-200",
              "IBUPROFEN": "IBUPROFEN",
              "IBUPROFEN INFANTS": "IBUPROFEN INFANTS",
              "SULINDAC": "SULINDAC"
            },
            "MARKSANS PHARMA": {
              "IBUPROFEN": "IBUPROFEN"
            },
            "MARLEX PHARMACEUTICALS": {
              "IBUPROFEN": "IBUPROFEN"
            },
            "MAS MANAGEMENT GROUP": {
              "IBUPROFEN": "IBUPROFEN"
            },
            "MCKESSON": {
              "HM IBUPROFEN": "HM IBUPROFEN",
              "HM IBUPROFEN CHILDRENS": "HM IBUPROFEN CHILDRENS",
              "HM IBUPROFEN IB": "HM IBUPROFEN IB",
              "HM IBUPROFEN INFANTS": "HM IBUPROFEN INFANTS",
              "HM NAPROXEN SODIUM": "HM NAPROXEN SODIUM"
            },
            "MCKESSON SUNMARK": {
              "SM ALL DAY PAIN RELIEF": "SM ALL DAY PAIN RELIEF",
              "SM CHILDRENS IBUPROFEN": "SM CHILDRENS IBUPROFEN",
              "SM IBUPROFEN": "SM IBUPROFEN",
              "SM IBUPROFEN IB": "SM IBUPROFEN IB",
              "SM IBUPROFEN JR": "SM IBUPROFEN JR",
              "SM INFANTS IBUPROFEN": "SM INFANTS IBUPROFEN",
              "SM NAPROXEN SODIUM": "SM NAPROXEN SODIUM"
            },
            "MCNEIL CONSUMER": {
              "CHILDRENS MOTRIN": "CHILDRENS MOTRIN",
              "MOTRIN CHILDRENS": "MOTRIN CHILDRENS",
              "MOTRIN IB": "MOTRIN IB",
              "MOTRIN INFANTS DROPS": "MOTRIN INFANTS DROPS"
            },
            "MEDICINE SHOPPE": {
              "CHILDRENS MEDI-PROFEN": "CHILDRENS MEDI-PROFEN",
              "MEDI-PROFEN": "MEDI-PROFEN",
              "NAPROXEN SODIUM": "NAPROXEN SODIUM"
            },
            "MEDIQUE PRODUCTS": {
              "ADDAPRIN": "ADDAPRIN",
              "I-PRIN": "I-PRIN",
              "IBUPROFEN": "IBUPROFEN",
              "MEDIPROXEN": "MEDIPROXEN"
            },
            "MEDISCA": {
              "FLURBIPROFEN": "FLURBIPROFEN",
              "IBUPROFEN": "IBUPROFEN",
              "INDOMETHACIN": "INDOMETHACIN",
              "MECLOFENAMATE SODIUM": "MECLOFENAMATE SODIUM",
              "MEFENAMIC ACID": "MEFENAMIC ACID",
              "NAPROXEN": "NAPROXEN",
              "PIROXICAM": "PIROXICAM"
            },
            "MEDLINE/DERMAL MANAGEMENT": {
              "IBUPROFEN": "IBUPROFEN"
            },
            "MEDVANTX": {
              "DICLOFENAC POTASSIUM": "DICLOFENAC POTASSIUM",
              "ETODOLAC": "ETODOLAC",
              "IBUPROFEN": "IBUPROFEN",
              "INDOMETHACIN": "INDOMETHACIN",
              "KETOPROFEN": "KETOPROFEN",
              "KETOROLAC TROMETHAMINE": "KETOROLAC TROMETHAMINE",
              "MELOXICAM": "MELOXICAM",
              "NAPROXEN": "NAPROXEN",
              "NAPROXEN SODIUM": "NAPROXEN SODIUM",
              "OXAPROZIN": "OXAPROZIN"
            },
            "MEIJER": {
              "MEIJER IBUPROFEN": "MEIJER IBUPROFEN",
              "NAPROXEN SODIUM": "NAPROXEN SODIUM"
            },
            "METHOD PHARMACEUTICALS": {
              "IBUPROFEN": "IBUPROFEN"
            },
            "MICRO LABORATORIES": {
              "MEFENAMIC ACID": "MEFENAMIC ACID",
              "PIROXICAM": "PIROXICAM"
            },
            "MURFREESBORO PHARM NURSING SUP": {
              "IBUPROFEN": "IBUPROFEN"
            },
            "MYLAN": {
              "DICLOFENAC SODIUM": "DICLOFENAC SODIUM",
              "DICLOFENAC SODIUM ER": "DICLOFENAC SODIUM ER",
              "FLURBIPROFEN": "FLURBIPROFEN",
              "INDOMETHACIN": "INDOMETHACIN",
              "KETOPROFEN": "KETOPROFEN",
              "KETOPROFEN ER": "KETOPROFEN ER",
              "KETOROLAC TROMETHAMINE": "KETOROLAC TROMETHAMINE",
              "MECLOFENAMATE SODIUM": "MECLOFENAMATE SODIUM",
              "MELOXICAM": "MELOXICAM",
              "NABUMETONE": "NABUMETONE",
              "PIROXICAM": "PIROXICAM",
              "SULINDAC": "SULINDAC",
              "TOLMETIN SODIUM": "TOLMETIN SODIUM"
            },
            "MYLAN INSTITUTIONAL": {
              "DICLOFENAC SODIUM": "DICLOFENAC SODIUM",
              "INDOMETHACIN": "INDOMETHACIN",
              "MELOXICAM": "MELOXICAM",
              "NAPROXEN": "NAPROXEN",
              "SULINDAC": "SULINDAC"
            },
            "NEIL LABS": {
              "IBUPROFEN": "IBUPROFEN"
            },
            "NEPHRON STERILE COMPOUNDING": {
              "KETOROLAC TROMETHAMINE": "KETOROLAC TROMETHAMINE"
            },
            "NEW HORIZON RX GROUP": {
              "MELOXICAM": "MELOXICAM",
              "NAPROXEN SODIUM": "NAPROXEN SODIUM"
            },
            "NEW WORLD IMPORTS": {
              "IBUPROFEN": "IBUPROFEN",
              "NAPROXEN SODIUM": "NAPROXEN SODIUM"
            },
            "NIVAGEN PHARMACEUTICALS": {
              "PIROXICAM": "PIROXICAM"
            },
            "NORTHSTAR RX": {
              "ETODOLAC ER": "ETODOLAC ER"
            },
            "NOSTRUM LABORATORIES": {
              "PIROXICAM": "PIROXICAM"
            },
            "NOVA PLUS/FRESENIUS KABI": {
              "KETOROLAC TROMETHAMINE": "KETOROLAC TROMETHAMINE"
            },
            "NOVAPLUS/FRESENIUS KABI": {
              "INDOMETHACIN SODIUM": "INDOMETHACIN SODIUM"
            },
            "NOVAPLUS/HOSPIRA": {
              "KETOROLAC TROMETHAMINE": "KETOROLAC TROMETHAMINE"
            },
            "NUCARE PHARMACEUTICALS": {
              "ALEVE": "ALEVE",
              "DAYPRO": "DAYPRO",
              "DFS DR/MS/MENTH/CAP PAK": "DFS DR/MS/MENTH/CAP PAK",
              "DICLOFENAC POTASSIUM": "DICLOFENAC POTASSIUM",
              "DICLOFENAC SODIUM": "DICLOFENAC SODIUM",
              "DICLOFENAC SODIUM ER": "DICLOFENAC SODIUM ER",
              "ETODOLAC": "ETODOLAC",
              "ETODOLAC ER": "ETODOLAC ER",
              "FENOPROFEN CALCIUM": "FENOPROFEN CALCIUM",
              "FLURBIPROFEN": "FLURBIPROFEN",
              "IBUPROFEN": "IBUPROFEN",
              "INDOMETHACIN": "INDOMETHACIN",
              "KETOPROFEN": "KETOPROFEN",
              "KETOROLAC TROMETHAMINE": "KETOROLAC TROMETHAMINE",
              "MELOXICAM": "MELOXICAM",
              "MOBIC": "MOBIC",
              "NABUMETONE": "NABUMETONE",
              "NAPRELAN": "NAPRELAN",
              "NAPROXEN": "NAPROXEN",
              "NAPROXEN DR": "NAPROXEN DR",
              "NAPROXEN SODIUM": "NAPROXEN SODIUM",
              "NUDROXIPAK DSDR-50": "NUDROXIPAK DSDR-50",
              "NUDROXIPAK DSDR-75": "NUDROXIPAK DSDR-75",
              "NUDROXIPAK E-400": "NUDROXIPAK E-400",
              "NUDROXIPAK I-800": "NUDROXIPAK I-800",
              "NUDROXIPAK M-15": "NUDROXIPAK M-15",
              "NUDROXIPAK N-500": "NUDROXIPAK N-500",
              "OXAPROZIN": "OXAPROZIN",
              "PIROXICAM": "PIROXICAM",
              "SULINDAC": "SULINDAC"
            },
            "OCEANSIDE PHARMACEUTICALS": {
              "DICLOFENAC SODIUM ER": "DICLOFENAC SODIUM ER"
            },
            "PACK PHARMACEUTICALS, LLC": {
              "DICLOFENAC SODIUM": "DICLOFENAC SODIUM"
            },
            "PALMETTO PHARMACEUTICALS, INC.": {
              "NAPROXEN": "NAPROXEN"
            },
            "PAR PHARMACEUTICALS": {
              "MEFENAMIC ACID": "MEFENAMIC ACID"
            },
            "PCCA": {
              "FLURBIPROFEN": "FLURBIPROFEN",
              "IBUPROFEN": "IBUPROFEN",
              "INDOMETHACIN": "INDOMETHACIN",
              "MEFENAMIC ACID": "MEFENAMIC ACID",
              "NAPROXEN": "NAPROXEN",
              "NAPROXEN SODIUM": "NAPROXEN SODIUM",
              "PIROXICAM": "PIROXICAM",
              "SULINDAC": "SULINDAC"
            },
            "PDRX PHARMACEUTICAL": {
              "ANAPROX DS": "ANAPROX DS",
              "DAYPRO": "DAYPRO",
              "DICLOFENAC POTASSIUM": "DICLOFENAC POTASSIUM",
              "DICLOFENAC SODIUM": "DICLOFENAC SODIUM",
              "DICLOFENAC SODIUM ER": "DICLOFENAC SODIUM ER",
              "EC-NAPROSYN": "EC-NAPROSYN",
              "ETODOLAC": "ETODOLAC",
              "FELDENE": "FELDENE",
              "FENOPROFEN CALCIUM": "FENOPROFEN CALCIUM",
              "FLURBIPROFEN": "FLURBIPROFEN",
              "IBUPROFEN": "IBUPROFEN",
              "IBUPROFEN COMFORT PAC": "IBUPROFEN COMFORT PAC",
              "INDOMETHACIN": "INDOMETHACIN",
              "INDOMETHACIN ER": "INDOMETHACIN ER",
              "KETOPROFEN": "KETOPROFEN",
              "KETOROLAC TROMETHAMINE": "KETOROLAC TROMETHAMINE",
              "MELOXICAM": "MELOXICAM",
              "MELOXICAM COMFORT PAC": "MELOXICAM COMFORT PAC",
              "MOBIC": "MOBIC",
              "NABUMETONE": "NABUMETONE",
              "NAPRELAN": "NAPRELAN",
              "NAPROSYN": "NAPROSYN",
              "NAPROXEN": "NAPROXEN",
              "NAPROXEN COMFORT PAC": "NAPROXEN COMFORT PAC",
              "NAPROXEN DR": "NAPROXEN DR",
              "NAPROXEN SODIUM": "NAPROXEN SODIUM",
              "OXAPROZIN": "OXAPROZIN",
              "PIROXICAM": "PIROXICAM",
              "PONSTEL": "PONSTEL",
              "SULINDAC": "SULINDAC",
              "ZIPSOR": "ZIPSOR"
            },
            "PDRX PHARMACEUTICALS": {
              "DICLOFENAC POTASSIUM": "DICLOFENAC POTASSIUM",
              "NABUMETONE": "NABUMETONE",
              "NAPROXEN DR": "NAPROXEN DR"
            },
            "PERRIGO": {
              "GOODSENSE IBUPROFEN": "GOODSENSE IBUPROFEN",
              "INDOMETHACIN ER": "INDOMETHACIN ER",
              "MEFENAMIC ACID": "MEFENAMIC ACID"
            },
            "PERRIGO PHARMACEUTICALS": {
              "CHILDRENS IBUPROFEN": "CHILDRENS IBUPROFEN",
              "IBUPROFEN": "IBUPROFEN",
              "IBUPROFEN CHILDRENS": "IBUPROFEN CHILDRENS",
              "INFANTS IBUPROFEN": "INFANTS IBUPROFEN",
              "NAPROXEN SODIUM": "NAPROXEN SODIUM"
            },
            "PFIZER U.S.": {
              "DAYPRO": "DAYPRO",
              "FELDENE": "FELDENE"
            },
            "PHARBEST PHARMACEUTICALS": {
              "IBUPROFEN": "IBUPROFEN"
            },
            "PHARMACEUTICA NORTH AMERICA": {
              "FLURBIPROFEN": "FLURBIPROFEN",
              "NAPROXEN KIT": "NAPROXEN KIT",
              "PIROXICAM": "PIROXICAM"
            },
            "PHARMACEUTICAL ASSOCIATES": {
              "CHILDRENS IBUPROFEN": "CHILDRENS IBUPROFEN",
              "IBUPROFEN": "IBUPROFEN",
              "IBUPROFEN CHILDRENS": "IBUPROFEN CHILDRENS"
            },
            "PHARMEDIX": {
              "DICLOFENAC SODIUM": "DICLOFENAC SODIUM",
              "ETODOLAC": "ETODOLAC",
              "IBUPROFEN": "IBUPROFEN",
              "KETOROLAC TROMETHAMINE": "KETOROLAC TROMETHAMINE",
              "NABUMETONE": "NABUMETONE",
              "NAPROXEN": "NAPROXEN",
              "NAPROXEN SODIUM": "NAPROXEN SODIUM",
              "PIROXICAM": "PIROXICAM"
            },
            "PHARMPAK": {
              "IBUPROFEN": "IBUPROFEN",
              "NAPROXEN SODIUM": "NAPROXEN SODIUM"
            },
            "PL DEVELOPMENTS": {
              "KS IBUPROFEN": "KS IBUPROFEN"
            },
            "POLYGEN PHARMACEUTICALS": {
              "IBUPROFEN": "IBUPROFEN",
              "NAPROXEN": "NAPROXEN"
            },
            "PRASCO LABORATORIES": {
              "IBUPROFEN LYSINE": "IBUPROFEN LYSINE",
              "KETOROLAC TROMETHAMINE": "KETOROLAC TROMETHAMINE",
              "MEFENAMIC ACID": "MEFENAMIC ACID"
            },
            "PRECISION DOSE, INC": {
              "CHILDRENS IBUPROFEN": "CHILDRENS IBUPROFEN",
              "IBUPROFEN CHILDRENS": "IBUPROFEN CHILDRENS"
            },
            "PREFERRED PHARMACEUTICALS": {
              "CHILDRENS IBUPROFEN": "CHILDRENS IBUPROFEN",
              "DICLOFENAC SODIUM": "DICLOFENAC SODIUM",
              "DICLOFENAC SODIUM ER": "DICLOFENAC SODIUM ER",
              "ETODOLAC": "ETODOLAC",
              "FLURBIPROFEN": "FLURBIPROFEN",
              "IBUPROFEN": "IBUPROFEN",
              "INDOMETHACIN": "INDOMETHACIN",
              "KETOPROFEN": "KETOPROFEN",
              "MELOXICAM": "MELOXICAM",
              "NABUMETONE": "NABUMETONE",
              "NAPROXEN": "NAPROXEN",
              "NAPROXEN DR": "NAPROXEN DR",
              "NAPROXEN SODIUM": "NAPROXEN SODIUM",
              "OXAPROZIN": "OXAPROZIN",
              "READYSHARP KETOROLAC": "READYSHARP KETOROLAC"
            },
            "PROFICIENT RX": {
              "DICLOFENAC SODIUM": "DICLOFENAC SODIUM",
              "DICLOFENAC SODIUM ER": "DICLOFENAC SODIUM ER",
              "ETODOLAC": "ETODOLAC",
              "ETODOLAC ER": "ETODOLAC ER",
              "IBUPROFEN": "IBUPROFEN",
              "IBUPROFEN CHILDRENS": "IBUPROFEN CHILDRENS",
              "INDOMETHACIN": "INDOMETHACIN",
              "INFANTS IBUPROFEN": "INFANTS IBUPROFEN",
              "KETOROLAC TROMETHAMINE": "KETOROLAC TROMETHAMINE",
              "MELOXICAM": "MELOXICAM",
              "NABUMETONE": "NABUMETONE",
              "NALFON": "NALFON",
              "NAPROXEN": "NAPROXEN",
              "NAPROXEN DR": "NAPROXEN DR",
              "NAPROXEN SODIUM": "NAPROXEN SODIUM",
              "PIROXICAM": "PIROXICAM"
            },
            "PUBLIX SUPER MARKETS INC.": {
              "PX ALL DAY RELIEF": "PX ALL DAY RELIEF",
              "PX CHILDRENS PROFEN IB": "PX CHILDRENS PROFEN IB",
              "PX IBUPROFEN": "PX IBUPROFEN",
              "PX IBUPROFEN JUNIOR STRENGTH": "PX IBUPROFEN JUNIOR STRENGTH",
              "PX INFANTS PROFEN IB": "PX INFANTS PROFEN IB"
            },
            "PURE TEK": {
              "IBU 600-EZS": "IBU 600-EZS",
              "IBUPAK": "IBUPAK"
            },
            "QUALITY CARE": {
              "DAYPRO": "DAYPRO",
              "DICLOFENAC POTASSIUM": "DICLOFENAC POTASSIUM",
              "DICLOFENAC SODIUM": "DICLOFENAC SODIUM",
              "DICLOFENAC SODIUM ER": "DICLOFENAC SODIUM ER",
              "ETODOLAC": "ETODOLAC",
              "ETODOLAC ER": "ETODOLAC ER",
              "FENOPROFEN CALCIUM": "FENOPROFEN CALCIUM",
              "FLURBIPROFEN": "FLURBIPROFEN",
              "IBU": "IBU",
              "IBUPROFEN": "IBUPROFEN",
              "INDOMETHACIN": "INDOMETHACIN",
              "INDOMETHACIN ER": "INDOMETHACIN ER",
              "KETOPROFEN": "KETOPROFEN",
              "KETOROLAC TROMETHAMINE": "KETOROLAC TROMETHAMINE",
              "MELOXICAM": "MELOXICAM",
              "MOBIC": "MOBIC",
              "NABUMETONE": "NABUMETONE",
              "NAPRELAN": "NAPRELAN",
              "NAPROXEN": "NAPROXEN",
              "NAPROXEN DR": "NAPROXEN DR",
              "NAPROXEN SODIUM": "NAPROXEN SODIUM",
              "OXAPROZIN": "OXAPROZIN",
              "PIROXICAM": "PIROXICAM",
              "SULINDAC": "SULINDAC",
              "TOLMETIN SODIUM": "TOLMETIN SODIUM"
            },
            "QUALITY CARE PRODUCTS": {
              "ALL DAY RELIEF": "ALL DAY RELIEF",
              "DICLOFENAC SODIUM": "DICLOFENAC SODIUM",
              "DICLOFENAC SODIUM ER": "DICLOFENAC SODIUM ER",
              "ETODOLAC": "ETODOLAC",
              "ETODOLAC ER": "ETODOLAC ER",
              "GOODSENSE NAPROXEN SODIUM": "GOODSENSE NAPROXEN SODIUM",
              "IBUPROFEN": "IBUPROFEN",
              "INDOMETHACIN": "INDOMETHACIN",
              "NABUMETONE": "NABUMETONE",
              "NALFON": "NALFON",
              "NAPROXEN": "NAPROXEN",
              "NAPROXEN DR": "NAPROXEN DR",
              "NAPROXEN SODIUM": "NAPROXEN SODIUM",
              "PIROXICAM": "PIROXICAM",
              "SULINDAC": "SULINDAC"
            },
            "READYMEDS": {
              "KETOROLAC TROMETHAMINE": "KETOROLAC TROMETHAMINE"
            },
            "RECORDATI RARE DISEASES": {
              "NEOPROFEN": "NEOPROFEN"
            },
            "REESE PHARMACEUTICAL": {
              "IBUPROFEN": "IBUPROFEN"
            },
            "REPHARM": {
              "DICLOFENAC SODIUM": "DICLOFENAC SODIUM"
            },
            "RICHMOND PHARMACEUTICALS": {
              "IBUPROFEN": "IBUPROFEN",
              "SULINDAC": "SULINDAC"
            },
            "RITE AID CORPORATION": {
              "RA IBUPROFEN": "RA IBUPROFEN",
              "RA IBUPROFEN CHILDRENS": "RA IBUPROFEN CHILDRENS",
              "RA IBUPROFEN INFANTS": "RA IBUPROFEN INFANTS",
              "RA IBUPROFEN JUNIOR STRENGTH": "RA IBUPROFEN JUNIOR STRENGTH",
              "RA NAPROXEN SODIUM": "RA NAPROXEN SODIUM",
              "RA PAIN RELIEF IBUPROFEN": "RA PAIN RELIEF IBUPROFEN"
            },
            "RUGBY LABORATORIES": {
              "ALL DAY RELIEF": "ALL DAY RELIEF",
              "IBUPROFEN": "IBUPROFEN",
              "IBUPROFEN CHILDRENS": "IBUPROFEN CHILDRENS"
            },
            "RXCHANGE CO": {
              "DICLOFENAC SODIUM": "DICLOFENAC SODIUM",
              "DICLOFENAC SODIUM ER": "DICLOFENAC SODIUM ER",
              "ETODOLAC": "ETODOLAC",
              "FLURBIPROFEN": "FLURBIPROFEN",
              "IBUPROFEN": "IBUPROFEN",
              "INDOMETHACIN": "INDOMETHACIN",
              "INDOMETHACIN ER": "INDOMETHACIN ER",
              "KETOPROFEN": "KETOPROFEN",
              "KETOROLAC TROMETHAMINE": "KETOROLAC TROMETHAMINE",
              "MELOXICAM": "MELOXICAM",
              "NABUMETONE": "NABUMETONE",
              "NAPROXEN": "NAPROXEN",
              "NAPROXEN DR": "NAPROXEN DR",
              "NAPROXEN SODIUM": "NAPROXEN SODIUM",
              "OXAPROZIN": "OXAPROZIN",
              "PIROXICAM": "PIROXICAM",
              "SULINDAC": "SULINDAC"
            },
            "RXPAK MCKESSON": {
              "DICLOFENAC SODIUM": "DICLOFENAC SODIUM"
            },
            "SA3": {
              "NAPROXEN SODIUM ER": "NAPROXEN SODIUM ER"
            },
            "SAGENT PHARMACEUTICAL": {
              "KETOROLAC TROMETHAMINE": "KETOROLAC TROMETHAMINE"
            },
            "SALLUS LABORATORIES": {
              "LODINE": "LODINE"
            },
            "SANDOZ": {
              "DICLOFENAC POTASSIUM": "DICLOFENAC POTASSIUM",
              "DICLOFENAC SODIUM": "DICLOFENAC SODIUM",
              "DICLOFENAC SODIUM ER": "DICLOFENAC SODIUM ER",
              "ETODOLAC": "ETODOLAC",
              "INDOMETHACIN ER": "INDOMETHACIN ER",
              "NABUMETONE": "NABUMETONE",
              "NAPROXEN SODIUM": "NAPROXEN SODIUM",
              "OXAPROZIN": "OXAPROZIN"
            },
            "SCIEGEN PHARMACEUTICALS": {
              "NABUMETONE": "NABUMETONE",
              "NAPROXEN": "NAPROXEN",
              "NAPROXEN SODIUM": "NAPROXEN SODIUM"
            },
            "SDA LABORATORIES INC": {
              "IBUPROFEN": "IBUPROFEN"
            },
            "SELECT BRAND": {
              "SB CHILDRENS IBUPROFEN": "SB CHILDRENS IBUPROFEN",
              "SB IBUPROFEN": "SB IBUPROFEN",
              "SB INFANTS IBUPROFEN": "SB INFANTS IBUPROFEN",
              "SB NAPROXEN SODIUM": "SB NAPROXEN SODIUM"
            },
            "SHIONOGI PHARMA": {
              "PONSTEL": "PONSTEL"
            },
            "SKY PACKAGING": {
              "IBUPROFEN": "IBUPROFEN",
              "MELOXICAM": "MELOXICAM",
              "NAPROXEN": "NAPROXEN"
            },
            "SPECTRUM": {
              "FLURBIPROFEN": "FLURBIPROFEN",
              "IBUPROFEN": "IBUPROFEN",
              "INDOMETHACIN": "INDOMETHACIN",
              "MECLOFENAMATE SODIUM": "MECLOFENAMATE SODIUM",
              "NAPROXEN": "NAPROXEN",
              "NAPROXEN SODIUM": "NAPROXEN SODIUM",
              "PIROXICAM": "PIROXICAM",
              "SULINDAC": "SULINDAC"
            },
            "ST MARY'S MPP": {
              "DICLOFENAC SODIUM": "DICLOFENAC SODIUM",
              "DICLOFENAC SODIUM ER": "DICLOFENAC SODIUM ER",
              "ETODOLAC": "ETODOLAC",
              "ETODOLAC ER": "ETODOLAC ER",
              "FENOPROFEN CALCIUM": "FENOPROFEN CALCIUM",
              "FLURBIPROFEN": "FLURBIPROFEN",
              "IBUPROFEN": "IBUPROFEN",
              "INDOMETHACIN": "INDOMETHACIN",
              "INDOMETHACIN ER": "INDOMETHACIN ER",
              "KETOROLAC TROMETHAMINE": "KETOROLAC TROMETHAMINE",
              "MELOXICAM": "MELOXICAM",
              "MOBIC": "MOBIC",
              "NABUMETONE": "NABUMETONE",
              "NAPROXEN": "NAPROXEN",
              "NAPROXEN DR": "NAPROXEN DR",
              "NAPROXEN SODIUM": "NAPROXEN SODIUM",
              "OXAPROZIN": "OXAPROZIN",
              "PIROXICAM": "PIROXICAM",
              "SULINDAC": "SULINDAC"
            },
            "STERLING KNIGHT PHARMACEUTICAL": {
              "DICLOFENAC SODIUM": "DICLOFENAC SODIUM",
              "FENOPROFEN CALCIUM": "FENOPROFEN CALCIUM",
              "FENORTHO": "FENORTHO",
              "KETOPROFEN": "KETOPROFEN",
              "MEFENAMIC ACID": "MEFENAMIC ACID",
              "MELOXICAM": "MELOXICAM"
            },
            "STRIDES PHARMA": {
              "IBUPROFEN": "IBUPROFEN",
              "PIROXICAM": "PIROXICAM"
            },
            "SUN PHARMACEUTICALS": {
              "FLURBIPROFEN": "FLURBIPROFEN",
              "SULINDAC": "SULINDAC",
              "TOLMETIN SODIUM": "TOLMETIN SODIUM"
            },
            "TARGET": {
              "TGT CHILDRENS IBUPROFEN": "TGT CHILDRENS IBUPROFEN",
              "TGT IBUPROFEN": "TGT IBUPROFEN",
              "TGT IBUPROFEN CHILDRENS": "TGT IBUPROFEN CHILDRENS",
              "TGT IBUPROFEN JUNIOR STRENGTH": "TGT IBUPROFEN JUNIOR STRENGTH",
              "TGT INFANTS IBUPROFEN": "TGT INFANTS IBUPROFEN",
              "TGT NAPROXEN SODIUM": "TGT NAPROXEN SODIUM"
            },
            "TARO": {
              "ETODOLAC": "ETODOLAC",
              "ETODOLAC ER": "ETODOLAC ER",
              "IBUPROFEN": "IBUPROFEN",
              "IBUPROFEN CHILDRENS": "IBUPROFEN CHILDRENS"
            },
            "TERRAIN PHARMACEUTICALS": {
              "READYSHARP KETOROLAC": "READYSHARP KETOROLAC"
            },
            "TERSERA THERAPEUTICS": {
              "QMIIZ ODT": "QMIIZ ODT"
            },
            "TEVA PHARMACEUTICALS USA": {
              "DICLOFENAC POTASSIUM": "DICLOFENAC POTASSIUM",
              "DICLOFENAC SODIUM ER": "DICLOFENAC SODIUM ER",
              "ETODOLAC": "ETODOLAC",
              "ETODOLAC ER": "ETODOLAC ER",
              "FLURBIPROFEN": "FLURBIPROFEN",
              "GENPRIL": "GENPRIL",
              "INDOMETHACIN": "INDOMETHACIN",
              "KETOPROFEN": "KETOPROFEN",
              "KETOROLAC TROMETHAMINE": "KETOROLAC TROMETHAMINE",
              "NAPROXEN": "NAPROXEN",
              "NAPROXEN DR": "NAPROXEN DR",
              "NAPROXEN SODIUM": "NAPROXEN SODIUM",
              "OXAPROZIN": "OXAPROZIN",
              "PIROXICAM": "PIROXICAM",
              "TOLMETIN SODIUM": "TOLMETIN SODIUM"
            },
            "TIME-CAP LABS": {
              "IBUPROFEN": "IBUPROFEN",
              "NAPROXEN": "NAPROXEN",
              "NAPROXEN SODIUM": "NAPROXEN SODIUM"
            },
            "TOPCO": {
              "ALL DAY PAIN RELIEF": "ALL DAY PAIN RELIEF",
              "CHILDRENS IBUPROFEN": "CHILDRENS IBUPROFEN",
              "IBUPROFEN": "IBUPROFEN",
              "IBUPROFEN CHILDRENS": "IBUPROFEN CHILDRENS",
              "IBUPROFEN JUNIOR STRENGTH": "IBUPROFEN JUNIOR STRENGTH",
              "INFANTS IBUPROFEN": "INFANTS IBUPROFEN",
              "NAPROXEN SODIUM": "NAPROXEN SODIUM"
            },
            "TOTAL PHARMACY SUPPLY": {
              "FLURBIPROFEN": "FLURBIPROFEN",
              "PIROXICAM": "PIROXICAM"
            },
            "TRIOVA PHARMACEUTICALS": {
              "FLURBIPROFEN": "FLURBIPROFEN"
            },
            "TRIVUE PHARMACEUTICAL": {
              "DICLOFENAC": "DICLOFENAC"
            },
            "UNICHEM PHARMACEUTICALS": {
              "MELOXICAM": "MELOXICAM",
              "PIROXICAM": "PIROXICAM"
            },
            "UNIT DOSE SERVICES": {
              "ALL DAY PAIN RELIEF": "ALL DAY PAIN RELIEF",
              "DICLOFENAC SODIUM": "DICLOFENAC SODIUM",
              "DICLOFENAC SODIUM ER": "DICLOFENAC SODIUM ER",
              "ETODOLAC": "ETODOLAC",
              "ETODOLAC ER": "ETODOLAC ER",
              "IBUPROFEN": "IBUPROFEN",
              "INDOMETHACIN": "INDOMETHACIN",
              "KETOROLAC TROMETHAMINE": "KETOROLAC TROMETHAMINE",
              "MELOXICAM": "MELOXICAM",
              "NABUMETONE": "NABUMETONE",
              "NAPROXEN": "NAPROXEN",
              "NAPROXEN SODIUM": "NAPROXEN SODIUM",
              "OXAPROZIN": "OXAPROZIN",
              "PIROXICAM": "PIROXICAM"
            },
            "VA CMOP DALLAS": {
              "ETODOLAC": "ETODOLAC",
              "IBUPROFEN": "IBUPROFEN",
              "NAPROXEN": "NAPROXEN"
            },
            "VANGARD": {
              "IBUPROFEN": "IBUPROFEN"
            },
            "VENSUN PHARMACEUTICALS": {
              "PIROXICAM": "PIROXICAM"
            },
            "VILLAGE PHARMA": {
              "FLURBIPROFEN": "FLURBIPROFEN"
            },
            "VIRTUE RX": {
              "IBUPROFEN": "IBUPROFEN"
            },
            "VIRTUS PHARMACEUTICALS": {
              "KETOROLAC TROMETHAMINE": "KETOROLAC TROMETHAMINE",
              "NAPROXEN DR": "NAPROXEN DR"
            },
            "VISTAPHARM INC.": {
              "IBUPROFEN": "IBUPROFEN"
            },
            "WAL-MART": {
              "EQ ALL DAY PAIN RELIEF": "EQ ALL DAY PAIN RELIEF",
              "EQ IBUPROFEN": "EQ IBUPROFEN",
              "EQ IBUPROFEN CHILDRENS": "EQ IBUPROFEN CHILDRENS",
              "EQ IBUPROFEN INFANTS": "EQ IBUPROFEN INFANTS",
              "EQ IBUPROFEN JUNIOR": "EQ IBUPROFEN JUNIOR",
              "EQ NAPROXEN SODIUM": "EQ NAPROXEN SODIUM"
            },
            "WALGREENS": {
              "ALL DAY PAIN RELIEF": "ALL DAY PAIN RELIEF",
              "CHILDRENS IBUPROFEN": "CHILDRENS IBUPROFEN",
              "CHILDRENS IBUPROFEN 100": "CHILDRENS IBUPROFEN 100",
              "IBUPROFEN": "IBUPROFEN",
              "IBUPROFEN 100 JUNIOR STRENGTH": "IBUPROFEN 100 JUNIOR STRENGTH",
              "IBUPROFEN CHILDRENS": "IBUPROFEN CHILDRENS",
              "IBUPROFEN JUNIOR STRENGTH": "IBUPROFEN JUNIOR STRENGTH",
              "INFANTS IBUPROFEN": "INFANTS IBUPROFEN",
              "NAPROXEN SODIUM": "NAPROXEN SODIUM",
              "WAL-PROFEN": "WAL-PROFEN"
            },
            "WESTMINSTER PHARMACEUTICALS": {
              "NABUMETONE": "NABUMETONE"
            },
            "WILLOW BIRCH PHARMA": {
              "FLURBIPROFEN": "FLURBIPROFEN"
            },
            "WOODWARD PHARMA SERVICES": {
              "EC-NAPROXEN": "EC-NAPROXEN",
              "NAPROXEN SODIUM": "NAPROXEN SODIUM"
            },
            "WRASER PHARMACEUTICALS": {
              "PROFENO": "PROFENO"
            },
            "X-GEN PHARMACEUTICALS, INC": {
              "IBUPROFEN LYSINE": "IBUPROFEN LYSINE"
            },
            "XLCARE PHARMACEUTICALS": {
              "MELOXICAM": "MELOXICAM"
            },
            "XSPIRE PHARMA": {
              "FENOPROFEN CALCIUM": "FENOPROFEN CALCIUM",
              "NALFON": "NALFON"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "ETODOLAC ER": "ETODOLAC ER",
              "INDOMETHACIN": "INDOMETHACIN",
              "INDOMETHACIN SODIUM": "INDOMETHACIN SODIUM",
              "MELOXICAM": "MELOXICAM"
            },
            "ZYLA LIFE SCIENCES": {
              "INDOCIN": "INDOCIN",
              "SPRIX": "SPRIX",
              "TIVORBEX": "TIVORBEX",
              "VIVLODEX": "VIVLODEX",
              "ZORVOLEX": "ZORVOLEX"
            }
          },
          "Phenylbutazones": {
            "APOTHECA SUPPLY": {
              "PHENYLBUTAZONE": "PHENYLBUTAZONE"
            },
            "ENCORE SCIENTIFIC": {
              "PHENYLBUTAZONE": "PHENYLBUTAZONE"
            },
            "FAGRON": {
              "PHENYLBUTAZONE": "PHENYLBUTAZONE"
            },
            "LETCO MEDICAL": {
              "PHENYLBUTAZONE": "PHENYLBUTAZONE"
            },
            "MEDISCA": {
              "PHENYLBUTAZONE": "PHENYLBUTAZONE"
            },
            "PCCA": {
              "PHENYLBUTAZONE": "PHENYLBUTAZONE"
            },
            "SPECTRUM": {
              "PHENYLBUTAZONE": "PHENYLBUTAZONE"
            }
          }
        },
        "Phosphodiesterase 4 (PDE4) Inhibitors": {
          "Phosphodiesterase 4 (PDE4) Inhibitors": {
            "AMGEN": {
              "OTEZLA": "OTEZLA"
            }
          }
        },
        "Pyrimidine Synthesis Inhibitors": {
          "Pyrimidine Synthesis Inhibitors": {
            "ALEMBIC PHARMACEUTICALS": {
              "LEFLUNOMIDE": "LEFLUNOMIDE"
            },
            "APOTEX": {
              "LEFLUNOMIDE": "LEFLUNOMIDE"
            },
            "AVET PHARMACEUTICALS": {
              "LEFLUNOMIDE": "LEFLUNOMIDE"
            },
            "AVKARE": {
              "LEFLUNOMIDE": "LEFLUNOMIDE"
            },
            "AVPAK": {
              "LEFLUNOMIDE": "LEFLUNOMIDE"
            },
            "BRYANT RANCH PREPACK": {
              "LEFLUNOMIDE": "LEFLUNOMIDE"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "LEFLUNOMIDE": "LEFLUNOMIDE"
            },
            "KVK TECH": {
              "LEFLUNOMIDE": "LEFLUNOMIDE"
            },
            "LUPIN PHARMACEUTICALS": {
              "LEFLUNOMIDE": "LEFLUNOMIDE"
            },
            "NORTHSTAR RX": {
              "LEFLUNOMIDE": "LEFLUNOMIDE"
            },
            "PRASCO LABORATORIES": {
              "LEFLUNOMIDE": "LEFLUNOMIDE"
            },
            "SANOFI-AVENTIS U.S.": {
              "ARAVA": "ARAVA"
            },
            "TRIGEN LABORATORIES": {
              "LEFLUNOMIDE": "LEFLUNOMIDE"
            },
            "WINTHROP, US": {
              "LEFLUNOMIDE": "LEFLUNOMIDE"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "LEFLUNOMIDE": "LEFLUNOMIDE"
            }
          }
        },
        "Selective Costimulation Modulators": {
          "Selective Costimulation Modulators": {
            "B-M SQUIBB U.S. (PRIMARY CARE)": {
              "ORENCIA": "ORENCIA",
              "ORENCIA CLICKJECT": "ORENCIA CLICKJECT"
            }
          }
        },
        "Soluble Tumor Necrosis Factor Receptor Agents": {
          "Soluble Tumor Necrosis Factor Receptor Agents": {
            "AMGEN": {
              "ENBREL": "ENBREL",
              "ENBREL MINI": "ENBREL MINI",
              "ENBREL SURECLICK": "ENBREL SURECLICK"
            }
          }
        }
      },
      "ANALGESICS - NonNarcotic": {
        "Analgesic Combinations": {
          "Analgesic Combinations": {
            "ALVA-AMCO": {
              "BACKAID MAX": "BACKAID MAX"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP HEADACHE RELIEF": "GNP HEADACHE RELIEF",
              "GNP HEADACHE RELIEF EXTRA STR": "GNP HEADACHE RELIEF EXTRA STR"
            },
            "AVPAK": {
              "ASPIRIN-ACETAMINOPHEN-CAFFEINE": "ASPIRIN-ACETAMINOPHEN-CAFFEINE"
            },
            "BAYER CONSUMER": {
              "BAYER BACK & BODY": "BAYER BACK & BODY",
              "BAYER BACK & BODY PAIN EX ST": "BAYER BACK & BODY PAIN EX ST",
              "BAYER MIGRAINE": "BAYER MIGRAINE",
              "MIDOL CAFFEINE FREE": "MIDOL CAFFEINE FREE",
              "MIDOL COMPLETE": "MIDOL COMPLETE",
              "MIDOL MAX ST MENSTRUAL": "MIDOL MAX ST MENSTRUAL",
              "MIDOL TEEN": "MIDOL TEEN",
              "VANQUISH": "VANQUISH"
            },
            "BERGEN BRUNSWIG": {
              "GNP HEADACHE RELIEF": "GNP HEADACHE RELIEF",
              "GNP HEADACHE RELIEF ADDED ST": "GNP HEADACHE RELIEF ADDED ST",
              "GNP MIGRAINE RELIEF": "GNP MIGRAINE RELIEF"
            },
            "CHAIN DRUG CONSORTIUM": {
              "HEADACHE PAIN-RELIEVER": "HEADACHE PAIN-RELIEVER",
              "MENSTRUAL RELIEF MAX STRENGTH": "MENSTRUAL RELIEF MAX STRENGTH",
              "PAIN RELIEVER EXTRA STRENGTH": "PAIN RELIEVER EXTRA STRENGTH",
              "TENSION HEADACHE RELIEF": "TENSION HEADACHE RELIEF"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC HEADACHE RELIEF": "QC HEADACHE RELIEF",
              "QC MENSTRUAL COMPLETE MAX ST": "QC MENSTRUAL COMPLETE MAX ST"
            },
            "CHATTEM INC": {
              "PAMPRIN MAX": "PAMPRIN MAX"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS BACK & BODY": "CVS BACK & BODY",
              "CVS BACK & BODY EXTRA STRENGTH": "CVS BACK & BODY EXTRA STRENGTH",
              "CVS HEADACHE RELIEF": "CVS HEADACHE RELIEF",
              "CVS HEADACHE RELIEF ADDED ST": "CVS HEADACHE RELIEF ADDED ST",
              "CVS MENSTRUAL RELIEF": "CVS MENSTRUAL RELIEF",
              "CVS MIGRAINE RELIEF": "CVS MIGRAINE RELIEF",
              "CVS TENSION HEADACHE": "CVS TENSION HEADACHE"
            },
            "DSE HEALTHCARE SOLUTIONS": {
              "VANQUISH": "VANQUISH"
            },
            "EQUALINE": {
              "EQL HEADACHE FORMULA": "EQL HEADACHE FORMULA",
              "EQL HEADACHE RELIEF EX STR": "EQL HEADACHE RELIEF EX STR",
              "EQL MENSTRUAL RELIEF MAX ST": "EQL MENSTRUAL RELIEF MAX ST",
              "EQL MIGRAINE FORMULA": "EQL MIGRAINE FORMULA"
            },
            "GEISS DESTIN & DUNN": {
              "GOODSENSE HEADACHE RELIEF": "GOODSENSE HEADACHE RELIEF",
              "GOODSENSE MENSTRUAL RELIEF": "GOODSENSE MENSTRUAL RELIEF",
              "GOODSENSE MIGRAINE FORMULA": "GOODSENSE MIGRAINE FORMULA"
            },
            "GENTEX PHARMA LLC": {
              "LEVACET": "LEVACET"
            },
            "GERI-CARE": {
              "PAIN RELIEVER EXTRA STRENGTH": "PAIN RELIEVER EXTRA STRENGTH"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "DOANS PM EXTRA STRENGTH": "DOANS PM EXTRA STRENGTH",
              "EXCEDRIN BACK & BODY": "EXCEDRIN BACK & BODY",
              "EXCEDRIN EXTRA STRENGTH": "EXCEDRIN EXTRA STRENGTH",
              "EXCEDRIN MENSTRUAL COMPLETE": "EXCEDRIN MENSTRUAL COMPLETE",
              "EXCEDRIN MIGRAINE": "EXCEDRIN MIGRAINE",
              "EXCEDRIN TENSION HEADACHE": "EXCEDRIN TENSION HEADACHE",
              "PANADOL EXTRA": "PANADOL EXTRA"
            },
            "INSIGHT PHARMACEUTICALS": {
              "ANACIN": "ANACIN"
            },
            "KROGER COMPANY": {
              "HEADACHE FORMULA ADDED ST": "HEADACHE FORMULA ADDED ST",
              "MIGRAINE FORMULA": "MIGRAINE FORMULA"
            },
            "LEADER BRAND PRODUCTS": {
              "BACK & BODY EXTRA STRENGTH": "BACK & BODY EXTRA STRENGTH",
              "HEADACHE RELIEF": "HEADACHE RELIEF",
              "MENSTRUAL COMPLETE": "MENSTRUAL COMPLETE",
              "MIGRAINE FORMULA": "MIGRAINE FORMULA",
              "MIGRAINE RELIEF": "MIGRAINE RELIEF",
              "TENSION HEADACHE": "TENSION HEADACHE"
            },
            "MAGNO-HUMPHRIES LABORATORIES": {
              "EXTRAPRIN": "EXTRAPRIN"
            },
            "MAJOR PHARMACEUTICALS": {
              "MAPAP HEADACHE PLUS": "MAPAP HEADACHE PLUS",
              "PAIN RELIEVER PLUS": "PAIN RELIEVER PLUS"
            },
            "MCKESSON": {
              "HM MIGRAINE FORMULA": "HM MIGRAINE FORMULA",
              "HM MIGRAINE RELIEF": "HM MIGRAINE RELIEF"
            },
            "MCKESSON SUNMARK": {
              "SM MIGRAINE RELIEF": "SM MIGRAINE RELIEF",
              "SM TENSION HEADACHE": "SM TENSION HEADACHE"
            },
            "MEDICINE SHOPPE": {
              "ADDED STRENGTH HEADACHE RELIEF": "ADDED STRENGTH HEADACHE RELIEF",
              "MIGRAINE FORMULA": "MIGRAINE FORMULA"
            },
            "MEDIQUE PRODUCTS": {
              "BACK PAIN-OFF": "BACK PAIN-OFF",
              "CRAMP TABS": "CRAMP TABS",
              "MS-AID": "MS-AID",
              "PAIN RELIEF": "PAIN RELIEF",
              "PAIN-OFF": "PAIN-OFF"
            },
            "MEDTECH": {
              "BC FAST PAIN RELIEF": "BC FAST PAIN RELIEF",
              "BC FAST PAIN RELIEF ARTHRITIS": "BC FAST PAIN RELIEF ARTHRITIS",
              "BC HEADACHE": "BC HEADACHE",
              "GOODYS EXTRA STRENGTH": "GOODYS EXTRA STRENGTH",
              "STANBACK HEADACHE POWDERS": "STANBACK HEADACHE POWDERS"
            },
            "MEIJER": {
              "MEIJER MIGRAINE FORMULA": "MEIJER MIGRAINE FORMULA"
            },
            "PERRIGO PHARMACEUTICALS": {
              "HEADACHE FORMULA": "HEADACHE FORMULA"
            },
            "PORTAL PHARMACEUTICALS": {
              "DURAXIN": "DURAXIN"
            },
            "PUBLIX SUPER MARKETS INC.": {
              "PX HEADACHE RELIEF ADDED ST": "PX HEADACHE RELIEF ADDED ST",
              "PX MIGRAINE RELIEF": "PX MIGRAINE RELIEF"
            },
            "REESE PHARMACEUTICAL": {
              "HEADRIN EX STRENGTH PAIN REL": "HEADRIN EX STRENGTH PAIN REL"
            },
            "RITE AID CORPORATION": {
              "RA BACK & BODY PAIN RELIEF": "RA BACK & BODY PAIN RELIEF",
              "RA HEADACHE FORMULA": "RA HEADACHE FORMULA",
              "RA HEADACHE FORMULA EX ST": "RA HEADACHE FORMULA EX ST",
              "RA MENSTRUAL COMPLETE": "RA MENSTRUAL COMPLETE",
              "RA MENSTRUAL RELIEF": "RA MENSTRUAL RELIEF",
              "RA MIGRAINE RELIEF": "RA MIGRAINE RELIEF",
              "RA PAIN RELIEVER EX ST": "RA PAIN RELIEVER EX ST",
              "RA TENSION HEADACHE": "RA TENSION HEADACHE"
            },
            "SELECT BRAND": {
              "SB PAIN RELIEF X-STR": "SB PAIN RELIEF X-STR"
            },
            "SHEFFIELD PHARMACEUTICALS": {
              "COPE": "COPE",
              "P-A-C": "P-A-C"
            },
            "TARGET": {
              "TGT MENSTRUAL RELIEF": "TGT MENSTRUAL RELIEF",
              "TGT MIGRAINE RELIEF": "TGT MIGRAINE RELIEF",
              "TGT PAIN RELIEVER ADDED ST": "TGT PAIN RELIEVER ADDED ST"
            },
            "TOPCO": {
              "HEADACHE FORMULA ADDED ST": "HEADACHE FORMULA ADDED ST",
              "MIGRAINE FORMULA": "MIGRAINE FORMULA"
            },
            "WAL-MART": {
              "EQ HEADACHE RELIEF": "EQ HEADACHE RELIEF",
              "EQ MENSTRUAL COMPLETE": "EQ MENSTRUAL COMPLETE",
              "EQ MIGRAINE RELIEF": "EQ MIGRAINE RELIEF"
            },
            "WALGREENS": {
              "BACK & BODY EXTRA STRENGTH": "BACK & BODY EXTRA STRENGTH",
              "HEADACHE RELIEF": "HEADACHE RELIEF",
              "MENSTRUAL RELIEF MAX STRENGTH": "MENSTRUAL RELIEF MAX STRENGTH",
              "MIGRAINE RELIEF": "MIGRAINE RELIEF",
              "TENSION HEADACHE": "TENSION HEADACHE",
              "WOMENS MENSTRUAL RELIEF": "WOMENS MENSTRUAL RELIEF"
            }
          },
          "Analgesics-Sedatives": {
            "A-S MEDICATION SOLUTIONS": {
              "BUTALBITAL-APAP-CAFFEINE": "BUTALBITAL-APAP-CAFFEINE",
              "BUTALBITAL-ASA-CAFFEINE": "BUTALBITAL-ASA-CAFFEINE",
              "BUTALBITAL-ASPIRIN-CAFFEINE": "BUTALBITAL-ASPIRIN-CAFFEINE"
            },
            "ACTAVIS PHARMA": {
              "BUTALBITAL-APAP-CAFFEINE": "BUTALBITAL-APAP-CAFFEINE",
              "BUTALBITAL-ASPIRIN-CAFFEINE": "BUTALBITAL-ASPIRIN-CAFFEINE"
            },
            "AG MARIN PHARMACEUTICALS": {
              "DOLOREX": "DOLOREX"
            },
            "AIDAREX PHARMACEUTICALS": {
              "BUTALBITAL-APAP-CAFFEINE": "BUTALBITAL-APAP-CAFFEINE",
              "BUTALBITAL-ASA-CAFFEINE": "BUTALBITAL-ASA-CAFFEINE"
            },
            "ALLEGIS PHARMACEUTICALS": {
              "HISTAFLEX": "HISTAFLEX"
            },
            "ALLERGAN": {
              "FIORINAL": "FIORINAL"
            },
            "ALTURA PHARMACEUTICALS": {
              "BUTALBITAL-APAP-CAFFEINE": "BUTALBITAL-APAP-CAFFEINE",
              "BUTALBITAL-ASA-CAFFEINE": "BUTALBITAL-ASA-CAFFEINE"
            },
            "ALVOGEN": {
              "BUTALBITAL-ACETAMINOPHEN": "BUTALBITAL-ACETAMINOPHEN",
              "BUTALBITAL-APAP-CAFFEINE": "BUTALBITAL-APAP-CAFFEINE"
            },
            "AMERICAN HEALTH PACKAGING": {
              "BUTALBITAL-APAP-CAFFEINE": "BUTALBITAL-APAP-CAFFEINE",
              "BUTALBITAL-ASA-CAFFEINE": "BUTALBITAL-ASA-CAFFEINE"
            },
            "AMERICAN SALES COMPANY": {
              "MENSTRUAL PAIN RELIEF": "MENSTRUAL PAIN RELIEF"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP PRE-MENSTRUAL RELF MAX ST": "GNP PRE-MENSTRUAL RELF MAX ST"
            },
            "APHENA PHARMA SOLUTIONS": {
              "BUTALBITAL-APAP-CAFFEINE": "BUTALBITAL-APAP-CAFFEINE"
            },
            "ATLAND PHARMACEUTICALS": {
              "BUTALBITAL-ACETAMINOPHEN": "BUTALBITAL-ACETAMINOPHEN"
            },
            "AUROBINDO PHARMA USA": {
              "BUTALBITAL-APAP-CAFFEINE": "BUTALBITAL-APAP-CAFFEINE"
            },
            "AVKARE": {
              "BUTALBITAL-APAP-CAFFEINE": "BUTALBITAL-APAP-CAFFEINE"
            },
            "AVPAK": {
              "BUTALBITAL-APAP-CAFFEINE": "BUTALBITAL-APAP-CAFFEINE"
            },
            "BAUSCH HEALTH": {
              "BUPAP": "BUPAP"
            },
            "BRYANT RANCH PREPACK": {
              "BUTALBITAL-APAP-CAFFEINE": "BUTALBITAL-APAP-CAFFEINE"
            },
            "CHAIN DRUG CONSORTIUM": {
              "MENSTRUAL PMS": "MENSTRUAL PMS"
            },
            "CHATTEM INC": {
              "PAMPRIN MAX PAIN FORMULA": "PAMPRIN MAX PAIN FORMULA",
              "PAMPRIN MULTI-SYMPTOM": "PAMPRIN MULTI-SYMPTOM",
              "PREMSYN PMS": "PREMSYN PMS"
            },
            "COUNTY LINE PHARMACEUTICALS": {
              "BUTALBITAL-ACETAMINOPHEN": "BUTALBITAL-ACETAMINOPHEN"
            },
            "DIRECT RX": {
              "BUTALBITAL-APAP-CAFFEINE": "BUTALBITAL-APAP-CAFFEINE"
            },
            "GM PHARMACEUTICALS": {
              "VANATOL LQ": "VANATOL LQ",
              "VANATOL S": "VANATOL S"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "BUTALBITAL-APAP-CAFFEINE": "BUTALBITAL-APAP-CAFFEINE"
            },
            "GRANULES PHARMACEUTICALS": {
              "BUTALBITAL-ACETAMINOPHEN": "BUTALBITAL-ACETAMINOPHEN",
              "BUTALBITAL-APAP-CAFFEINE": "BUTALBITAL-APAP-CAFFEINE"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "BUTALBITAL-ASA-CAFFEINE": "BUTALBITAL-ASA-CAFFEINE"
            },
            "HIKMA": {
              "BUTALBITAL-APAP-CAFFEINE": "BUTALBITAL-APAP-CAFFEINE"
            },
            "INTERNATIONAL ETHICAL LABS": {
              "RELAGESIC": "RELAGESIC",
              "TENCON": "TENCON"
            },
            "KVK TECH": {
              "BUTALBITAL-APAP-CAFFEINE": "BUTALBITAL-APAP-CAFFEINE"
            },
            "LANNETT": {
              "BUTALBITAL-APAP-CAFFEINE": "BUTALBITAL-APAP-CAFFEINE",
              "BUTALBITAL-ASPIRIN-CAFFEINE": "BUTALBITAL-ASPIRIN-CAFFEINE"
            },
            "LARKEN LABORATORIES, INC.": {
              "ALLZITAL": "ALLZITAL",
              "BUTALBITAL-ACETAMINOPHEN": "BUTALBITAL-ACETAMINOPHEN"
            },
            "LEADER BRAND PRODUCTS": {
              "MENSTRUAL PAIN RELIEF": "MENSTRUAL PAIN RELIEF"
            },
            "LIVING WELL PHARMACY": {
              "BIPHENOX": "BIPHENOX"
            },
            "LLORENS PHARMACEUTICAL": {
              "DOLOGESIC": "DOLOGESIC"
            },
            "MAGNA PHARMACEUTICALS, INC": {
              "CAPACET": "CAPACET"
            },
            "MAJOR PHARMACEUTICALS": {
              "BUTALBITAL-APAP-CAFFEINE": "BUTALBITAL-APAP-CAFFEINE"
            },
            "MARLEX PHARMACEUTICALS": {
              "BUTALBITAL-APAP-CAFFEINE": "BUTALBITAL-APAP-CAFFEINE"
            },
            "MARNEL PHARMACEUTICAL": {
              "MARGESIC": "MARGESIC",
              "MARTEN-TAB": "MARTEN-TAB"
            },
            "MAYNE PHARMA": {
              "BUTALBITAL-ACETAMINOPHEN": "BUTALBITAL-ACETAMINOPHEN",
              "BUTALBITAL-APAP-CAFFEINE": "BUTALBITAL-APAP-CAFFEINE",
              "ESGIC": "ESGIC",
              "ZEBUTAL": "ZEBUTAL"
            },
            "MEDVANTX": {
              "BUTALBITAL-APAP-CAFFEINE": "BUTALBITAL-APAP-CAFFEINE"
            },
            "MIKART": {
              "BUTALBITAL-APAP-CAFFEINE": "BUTALBITAL-APAP-CAFFEINE"
            },
            "MONARCH PCM": {
              "VTOL LQ": "VTOL LQ"
            },
            "NUCARE PHARMACEUTICALS": {
              "BUTALBITAL-ACETAMINOPHEN": "BUTALBITAL-ACETAMINOPHEN",
              "BUTALBITAL-APAP-CAFFEINE": "BUTALBITAL-APAP-CAFFEINE"
            },
            "OCEANSIDE PHARMACEUTICALS": {
              "BUTALBITAL-ACETAMINOPHEN": "BUTALBITAL-ACETAMINOPHEN"
            },
            "PAR PHARMACEUTICALS": {
              "BUTALBITAL-ACETAMINOPHEN": "BUTALBITAL-ACETAMINOPHEN",
              "BUTALBITAL-APAP-CAFFEINE": "BUTALBITAL-APAP-CAFFEINE"
            },
            "PDRX PHARMACEUTICAL": {
              "BUTALBITAL-APAP-CAFFEINE": "BUTALBITAL-APAP-CAFFEINE"
            },
            "PHLIGHT PHARMA": {
              "ALLZITAL": "ALLZITAL"
            },
            "PREFERRED PHARMACEUTICALS": {
              "BUTALBITAL-APAP-CAFFEINE": "BUTALBITAL-APAP-CAFFEINE"
            },
            "PROFICIENT RX": {
              "BUTALBITAL-APAP-CAFFEINE": "BUTALBITAL-APAP-CAFFEINE"
            },
            "QUALITY CARE": {
              "BUTALBITAL-APAP-CAFFEINE": "BUTALBITAL-APAP-CAFFEINE"
            },
            "QUALITY CARE PRODUCTS": {
              "BUTALBITAL-APAP-CAFFEINE": "BUTALBITAL-APAP-CAFFEINE",
              "BUTALBITAL-ASA-CAFFEINE": "BUTALBITAL-ASA-CAFFEINE"
            },
            "RITE AID CORPORATION": {
              "RA MENSTRUAL PAIN RELIEF": "RA MENSTRUAL PAIN RELIEF"
            },
            "RXCHANGE CO": {
              "BUTALBITAL-ACETAMINOPHEN": "BUTALBITAL-ACETAMINOPHEN",
              "BUTALBITAL-APAP-CAFFEINE": "BUTALBITAL-APAP-CAFFEINE"
            },
            "SKYLAR LABORATORIES": {
              "ALLZITAL": "ALLZITAL",
              "BUTALBITAL-ACETAMINOPHEN": "BUTALBITAL-ACETAMINOPHEN"
            },
            "SOLUBIOMIX LLC": {
              "BUTALBITAL-ACETAMINOPHEN": "BUTALBITAL-ACETAMINOPHEN"
            },
            "STI PHARMA": {
              "BUTALBITAL-APAP": "BUTALBITAL-APAP"
            },
            "SUNRISE PHARMACEUTICAL": {
              "BUTALBITAL-APAP-CAFFEINE": "BUTALBITAL-APAP-CAFFEINE"
            },
            "TARO": {
              "BUTALBITAL-APAP-CAFFEINE": "BUTALBITAL-APAP-CAFFEINE"
            },
            "TEVA SELECT BRANDS": {
              "FIORICET": "FIORICET"
            },
            "UNIT DOSE SERVICES": {
              "BUTALBITAL-APAP-CAFFEINE": "BUTALBITAL-APAP-CAFFEINE"
            },
            "VENSUN PHARMACEUTICALS": {
              "BUTALBITAL-ASPIRIN-CAFFEINE": "BUTALBITAL-ASPIRIN-CAFFEINE"
            },
            "WESTMINSTER PHARMACEUTICALS": {
              "BUTALBITAL-APAP-CAFFEINE": "BUTALBITAL-APAP-CAFFEINE"
            },
            "WRASER PHARMACEUTICALS": {
              "PHRENILIN FORTE": "PHRENILIN FORTE"
            },
            "XSPIRE PHARMA": {
              "BUTALBITAL-APAP-CAFFEINE": "BUTALBITAL-APAP-CAFFEINE"
            }
          }
        },
        "Analgesics Other": {
          "Analgesics Other": {
            "7T PHARMA": {
              "7T GUMMY ES": "7T GUMMY ES"
            },
            "A-S MEDICATION SOLUTIONS": {
              "ACEPHEN": "ACEPHEN",
              "ACETAMINOPHEN": "ACETAMINOPHEN",
              "ACETAMINOPHEN EXTRA STRENGTH": "ACETAMINOPHEN EXTRA STRENGTH",
              "MAPAP": "MAPAP",
              "PHARBETOL": "PHARBETOL",
              "PHARBETOL EXTRA STRENGTH": "PHARBETOL EXTRA STRENGTH",
              "TYLENOL EXTRA STRENGTH": "TYLENOL EXTRA STRENGTH"
            },
            "ADVANTICE HEALTH": {
              "PEDIACARE CHILDREN": "PEDIACARE CHILDREN",
              "PEDIACARE INFANTS": "PEDIACARE INFANTS"
            },
            "AIDAREX PHARMACEUTICALS": {
              "ACETAMINOPHEN": "ACETAMINOPHEN",
              "ACETAMINOPHEN ER": "ACETAMINOPHEN ER",
              "MAPAP": "MAPAP"
            },
            "AKRON PHARMA": {
              "PAIN RELIEF CHILDRENS": "PAIN RELIEF CHILDRENS"
            },
            "ALEXSO": {
              "ACETAMINOPHEN": "ACETAMINOPHEN"
            },
            "ALTAIRE": {
              "APRA": "APRA"
            },
            "ALTURA PHARMACEUTICALS": {
              "ACETAMINOPHEN": "ACETAMINOPHEN"
            },
            "AMERICAN HEALTH PACKAGING": {
              "ACETAMINOPHEN ER": "ACETAMINOPHEN ER"
            },
            "AMERICAN REGENT": {
              "CLONIDINE HCL (ANALGESIA)": "CLONIDINE HCL (ANALGESIA)"
            },
            "AMERICAN SALES COMPANY": {
              "PAIN RELIEF CHILDRENS": "PAIN RELIEF CHILDRENS",
              "PAIN RELIEF EXTRA STRENGTH": "PAIN RELIEF EXTRA STRENGTH"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP 8 HOUR ARTHRITIS RELIEF": "GNP 8 HOUR ARTHRITIS RELIEF",
              "GNP 8 HOUR PAIN RELIEVER": "GNP 8 HOUR PAIN RELIEVER",
              "GNP ACETAMINOPHEN": "GNP ACETAMINOPHEN",
              "GNP ACETAMINOPHEN EX ST": "GNP ACETAMINOPHEN EX ST",
              "GNP ARTHRITIS PAIN RELIEF": "GNP ARTHRITIS PAIN RELIEF",
              "GNP CHILDRENS EASY-MELTS": "GNP CHILDRENS EASY-MELTS",
              "GNP CHILDRENS PAIN RELIEF": "GNP CHILDRENS PAIN RELIEF",
              "GNP INFANTS PAIN RELIEF": "GNP INFANTS PAIN RELIEF",
              "GNP INFANTS PAIN/FEVER": "GNP INFANTS PAIN/FEVER",
              "GNP PAIN & FEVER CHILDRENS": "GNP PAIN & FEVER CHILDRENS",
              "GNP PAIN & FEVER INFANTS": "GNP PAIN & FEVER INFANTS",
              "GNP PAIN RELIEF": "GNP PAIN RELIEF",
              "GNP PAIN RELIEF EXTRA STRENGTH": "GNP PAIN RELIEF EXTRA STRENGTH",
              "GNP PAIN RELIEVER EX ST": "GNP PAIN RELIEVER EX ST"
            },
            "APHENA PHARMA SOLUTIONS": {
              "ACETAMINOPHEN": "ACETAMINOPHEN"
            },
            "ATLANTIC BIOLOGICALS": {
              "MAPAP": "MAPAP"
            },
            "AUBURN PHARMACEUTICAL": {
              "CHILDRENS TACTINAL": "CHILDRENS TACTINAL",
              "TACTINAL": "TACTINAL",
              "TACTINAL EXTRA STRENGTH": "TACTINAL EXTRA STRENGTH"
            },
            "AUROHEALTH": {
              "ACETAMINOPHEN 8 HOUR": "ACETAMINOPHEN 8 HOUR",
              "AUROPHEN CHILDRENS": "AUROPHEN CHILDRENS",
              "PAIN & FEVER CHILDRENS": "PAIN & FEVER CHILDRENS",
              "PAIN RELIEF EXTRA STRENGTH": "PAIN RELIEF EXTRA STRENGTH"
            },
            "AURORA HEALTHCARE": {
              "ACETAMINOPHEN": "ACETAMINOPHEN",
              "CHILDRENS NON-ASPIRIN": "CHILDRENS NON-ASPIRIN",
              "NON-ASPIRIN": "NON-ASPIRIN",
              "PAIN RELIEF CHILDRENS": "PAIN RELIEF CHILDRENS"
            },
            "AVPAK": {
              "ACETAMINOPHEN ER": "ACETAMINOPHEN ER"
            },
            "BALLAY PHARMACEUTICALS": {
              "NORTEMP": "NORTEMP",
              "NORTEMP INFANTS": "NORTEMP INFANTS"
            },
            "BAYER CONSUMER": {
              "MIDOL": "MIDOL"
            },
            "BENE HEALTH OTC": {
              "ACETAMINOPHEN": "ACETAMINOPHEN"
            },
            "BERGEN BRUNSWIG": {
              "GNP 8 HOUR PAIN RELIEF": "GNP 8 HOUR PAIN RELIEF",
              "GNP ARTHRITIS PAIN RELIEF": "GNP ARTHRITIS PAIN RELIEF",
              "GNP CHILDRENS PAIN RELIEF": "GNP CHILDRENS PAIN RELIEF",
              "GNP PAIN RELIEF": "GNP PAIN RELIEF",
              "GNP PAIN RELIEF EXTRA STRENGTH": "GNP PAIN RELIEF EXTRA STRENGTH",
              "GNP PAIN RELIEVER EX ST": "GNP PAIN RELIEVER EX ST"
            },
            "BETA DERMS": {
              "BETATEMP CHILDRENS": "BETATEMP CHILDRENS"
            },
            "BONITA PHARMACEUTICALS": {
              "ACETAMINOPHEN EXTRA STRENGTH": "ACETAMINOPHEN EXTRA STRENGTH"
            },
            "BRYANT RANCH PREPACK": {
              "ACETAMINOPHEN": "ACETAMINOPHEN",
              "ACETAMINOPHEN ER": "ACETAMINOPHEN ER",
              "ACETAMINOPHEN EXTRA STRENGTH": "ACETAMINOPHEN EXTRA STRENGTH",
              "MAPAP ARTHRITIS PAIN": "MAPAP ARTHRITIS PAIN"
            },
            "CHAIN DRUG CONSORTIUM": {
              "ACETAMINOPHEN 8 HOUR": "ACETAMINOPHEN 8 HOUR",
              "ARTHRITIS PAIN RELIEF": "ARTHRITIS PAIN RELIEF",
              "CHILDRENS NON-ASPIRIN": "CHILDRENS NON-ASPIRIN",
              "INFANTS PAIN RELIEF": "INFANTS PAIN RELIEF",
              "NON-ASPIRIN": "NON-ASPIRIN",
              "NON-ASPIRIN 8 HOUR": "NON-ASPIRIN 8 HOUR",
              "NON-ASPIRIN CHILDRENS": "NON-ASPIRIN CHILDRENS",
              "NON-ASPIRIN EXTRA STRENGTH": "NON-ASPIRIN EXTRA STRENGTH",
              "NON-ASPIRIN JR STRENGTH": "NON-ASPIRIN JR STRENGTH",
              "NON-ASPIRIN PAIN RELIEF CHILD": "NON-ASPIRIN PAIN RELIEF CHILD",
              "PAIN RELIEF CHILDRENS": "PAIN RELIEF CHILDRENS",
              "PAIN RELIEF EXTRA STRENGTH": "PAIN RELIEF EXTRA STRENGTH",
              "PAIN RELIEVER": "PAIN RELIEVER",
              "PAIN RELIEVER EXTRA STRENGTH": "PAIN RELIEVER EXTRA STRENGTH",
              "RAPID BURST ADULT": "RAPID BURST ADULT"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "NON-ASPIRIN CHILDRENS": "NON-ASPIRIN CHILDRENS",
              "NON-ASPIRIN EXTRA STRENGTH": "NON-ASPIRIN EXTRA STRENGTH",
              "NON-ASPIRIN PAIN RELIEF": "NON-ASPIRIN PAIN RELIEF",
              "QC ACETAMINOPHEN 8 HOURS": "QC ACETAMINOPHEN 8 HOURS",
              "QC ACETAMINOPHEN 8HR ARTH PAIN": "QC ACETAMINOPHEN 8HR ARTH PAIN",
              "QC ACETAMINOPHEN 8HR MUSC ACHE": "QC ACETAMINOPHEN 8HR MUSC ACHE",
              "QC ARTHRITIS PAIN RELIEF": "QC ARTHRITIS PAIN RELIEF",
              "QC NON-ASPIRIN 8 HOUR": "QC NON-ASPIRIN 8 HOUR",
              "QC NON-ASPIRIN CHILDRENS": "QC NON-ASPIRIN CHILDRENS",
              "QC NON-ASPIRIN EXTRA STRENGTH": "QC NON-ASPIRIN EXTRA STRENGTH",
              "QC NON-ASPIRIN JR STRENGTH": "QC NON-ASPIRIN JR STRENGTH",
              "QC PAIN RELIEF": "QC PAIN RELIEF",
              "QC PAIN RELIEF CHILDRENS": "QC PAIN RELIEF CHILDRENS",
              "QC PAIN RELIEF EXTRA STRENGTH": "QC PAIN RELIEF EXTRA STRENGTH",
              "QC PAIN RELIEF INFANTS": "QC PAIN RELIEF INFANTS"
            },
            "CONTRACT PHARMACAL CORPORATION": {
              "APAP": "APAP",
              "APAP EXTRA STRENGTH": "APAP EXTRA STRENGTH"
            },
            "COSETTE PHARMACEUTICALS": {
              "ACEPHEN": "ACEPHEN"
            },
            "COSTCO WHOLESALE": {
              "KLS ACETAMINOPHEN EX ST": "KLS ACETAMINOPHEN EX ST",
              "KLS ARTHRITIS PAIN": "KLS ARTHRITIS PAIN",
              "KLS NON ASPIRIN": "KLS NON ASPIRIN",
              "KLS PAIN & FEVER INFANTS": "KLS PAIN & FEVER INFANTS",
              "KLS PAIN RELIEF CHILDRENS": "KLS PAIN RELIEF CHILDRENS",
              "KLS RAPID RELEASE PAIN": "KLS RAPID RELEASE PAIN"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS 8 HOUR PAIN RELIEF": "CVS 8 HOUR PAIN RELIEF",
              "CVS 8HR ARTHRITIS PAIN RELIEF": "CVS 8HR ARTHRITIS PAIN RELIEF",
              "CVS 8HR MUSCLE ACHES & PAIN": "CVS 8HR MUSCLE ACHES & PAIN",
              "CVS ACETAMINOPHEN": "CVS ACETAMINOPHEN",
              "CVS ACETAMINOPHEN EX ST": "CVS ACETAMINOPHEN EX ST",
              "CVS ARTHRITIS PAIN RELIEF": "CVS ARTHRITIS PAIN RELIEF",
              "CVS CHILDS NON-ASPIRIN": "CVS CHILDS NON-ASPIRIN",
              "CVS FEVER REDUCING CHILDRENS": "CVS FEVER REDUCING CHILDRENS",
              "CVS INFANTS PAIN RELIEF DROPS": "CVS INFANTS PAIN RELIEF DROPS",
              "CVS NON-ASPIRIN CHILDRENS": "CVS NON-ASPIRIN CHILDRENS",
              "CVS NON-ASPIRIN EXTRA STRENGTH": "CVS NON-ASPIRIN EXTRA STRENGTH",
              "CVS NON-ASPIRIN JR": "CVS NON-ASPIRIN JR",
              "CVS PAIN & FEVER CHILDRENS": "CVS PAIN & FEVER CHILDRENS",
              "CVS PAIN & FEVER INFANTS": "CVS PAIN & FEVER INFANTS",
              "CVS PAIN RELIEF": "CVS PAIN RELIEF",
              "CVS PAIN RELIEF ADULT": "CVS PAIN RELIEF ADULT",
              "CVS PAIN RELIEF CHILDRENS": "CVS PAIN RELIEF CHILDRENS",
              "CVS PAIN RELIEF EXTRA STRENGTH": "CVS PAIN RELIEF EXTRA STRENGTH",
              "CVS PAIN RELIEF REGULAR ST": "CVS PAIN RELIEF REGULAR ST"
            },
            "DIRECT RX": {
              "ACETAMINOPHEN": "ACETAMINOPHEN",
              "ACETAMINOPHEN ER": "ACETAMINOPHEN ER",
              "MAPAP": "MAPAP",
              "PAIN RELIEF EXTRA STRENGTH": "PAIN RELIEF EXTRA STRENGTH",
              "PHARBETOL": "PHARBETOL"
            },
            "DOVER PHARMACEUTICALS": {
              "AMINOFEN": "AMINOFEN"
            },
            "ENOVACHEM MANUFACTURING": {
              "APHEN": "APHEN"
            },
            "EQUALINE": {
              "EQL ACETAMINOPHEN": "EQL ACETAMINOPHEN",
              "EQL ACETAMINOPHEN 8 HOUR": "EQL ACETAMINOPHEN 8 HOUR",
              "EQL ACETAMINOPHEN CHILDRENS": "EQL ACETAMINOPHEN CHILDRENS",
              "EQL ACETAMINOPHEN EX ST": "EQL ACETAMINOPHEN EX ST",
              "EQL ACETAMINOPHEN INFANTS": "EQL ACETAMINOPHEN INFANTS",
              "EQL ACETAMINOPHEN RAPID TABS": "EQL ACETAMINOPHEN RAPID TABS",
              "EQL ARTHRITIS PAIN RELIEF": "EQL ARTHRITIS PAIN RELIEF",
              "EQL CHILDRENS PAIN RELIEF": "EQL CHILDRENS PAIN RELIEF",
              "EQL PAIN & FEVER CHILDRENS": "EQL PAIN & FEVER CHILDRENS",
              "EQL PAIN & FEVER INFANTS": "EQL PAIN & FEVER INFANTS",
              "EQL PAIN RELIEF": "EQL PAIN RELIEF",
              "EQL PAIN RELIEF EXTRA STRENGTH": "EQL PAIN RELIEF EXTRA STRENGTH"
            },
            "FRESENIUS KABI USA": {
              "CLONIDINE HCL (ANALGESIA)": "CLONIDINE HCL (ANALGESIA)"
            },
            "GEISS DESTIN & DUNN": {
              "GOODSENSE PAIN & FEVER CHILD": "GOODSENSE PAIN & FEVER CHILD",
              "GOODSENSE PAIN & FEVER INFANTS": "GOODSENSE PAIN & FEVER INFANTS",
              "GOODSENSE PAIN RELIEF": "GOODSENSE PAIN RELIEF",
              "GOODSENSE PAIN RELIEF EXTRA ST": "GOODSENSE PAIN RELIEF EXTRA ST"
            },
            "GERI-CARE": {
              "ACETAMINOPHEN": "ACETAMINOPHEN",
              "ACETAMINOPHEN ER": "ACETAMINOPHEN ER",
              "ACETAMINOPHEN EXTRA STRENGTH": "ACETAMINOPHEN EXTRA STRENGTH",
              "CHILDRENS NON-ASA PAIN RELIEF": "CHILDRENS NON-ASA PAIN RELIEF",
              "LIQUID PAIN RELIEF": "LIQUID PAIN RELIEF",
              "NON-ASPIRIN PAIN RELIEF": "NON-ASPIRIN PAIN RELIEF",
              "PAIN RELIEF": "PAIN RELIEF",
              "PAIN RELIEF EXTRA STRENGTH": "PAIN RELIEF EXTRA STRENGTH"
            },
            "GERITREX CORPORATION": {
              "APAP": "APAP"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "PANADOL CHILDRENS": "PANADOL CHILDRENS",
              "PANADOL EXTRA STRENGTH": "PANADOL EXTRA STRENGTH",
              "PANADOL INFANTS": "PANADOL INFANTS",
              "TRIAMINIC FEVER REDUCER": "TRIAMINIC FEVER REDUCER"
            },
            "GRANULES USA": {
              "ACETAMINOPHEN ER": "ACETAMINOPHEN ER"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "ACETAMINOPHEN": "ACETAMINOPHEN"
            },
            "HANNAFORD BROTHERS": {
              "CHILDRENS PAIN RELIEVER": "CHILDRENS PAIN RELIEVER",
              "INFANTS PAIN RELIEVER": "INFANTS PAIN RELIEVER",
              "PAIN RELIEVER EXTRA STRENGTH": "PAIN RELIEVER EXTRA STRENGTH"
            },
            "HENRY SCHEIN": {
              "ACETAMINOPHEN": "ACETAMINOPHEN"
            },
            "HIKMA": {
              "CLONIDINE HCL (ANALGESIA)": "CLONIDINE HCL (ANALGESIA)"
            },
            "HY-VEE": {
              "ARTHRITIS PAIN RELIEF": "ARTHRITIS PAIN RELIEF",
              "PAIN RELIEF REGULAR STRENGTH": "PAIN RELIEF REGULAR STRENGTH"
            },
            "J.B. LABS": {
              "ACETAMINOPHEN": "ACETAMINOPHEN"
            },
            "LEADER BRAND PRODUCTS": {
              "8 HOUR ARTHRITIS PAIN RELIEVER": "8 HOUR ARTHRITIS PAIN RELIEVER",
              "8 HOUR PAIN RELIEVER": "8 HOUR PAIN RELIEVER",
              "8HR MUSCLE ACHES & PAIN": "8HR MUSCLE ACHES & PAIN",
              "ACETAMINOPHEN": "ACETAMINOPHEN",
              "ACETAMINOPHEN CHILDRENS": "ACETAMINOPHEN CHILDRENS",
              "ACETAMINOPHEN EXTRA STRENGTH": "ACETAMINOPHEN EXTRA STRENGTH",
              "ARTHRITIS PAIN": "ARTHRITIS PAIN",
              "ARTHRITIS PAIN RELIEVER": "ARTHRITIS PAIN RELIEVER",
              "CHILDRENS PAIN RELIEVER": "CHILDRENS PAIN RELIEVER",
              "PAIN & FEVER CHILDRENS": "PAIN & FEVER CHILDRENS",
              "PAIN & FEVER INFANTS": "PAIN & FEVER INFANTS",
              "PAIN RELIEF 8 HOUR": "PAIN RELIEF 8 HOUR",
              "PAIN RELIEVER": "PAIN RELIEVER",
              "PAIN RELIEVER EXTRA STRENGTH": "PAIN RELIEVER EXTRA STRENGTH"
            },
            "LIBERTY PHARMACEUTICAL": {
              "ACETAMINOPHEN": "ACETAMINOPHEN"
            },
            "LLORENS PHARMACEUTICAL": {
              "LIQUID ACETAMINOPHEN": "LIQUID ACETAMINOPHEN"
            },
            "MAGNO-HUMPHRIES LABORATORIES": {
              "ACETAMINOPHEN": "ACETAMINOPHEN",
              "CHILDRENS PAIN RELIEVER": "CHILDRENS PAIN RELIEVER"
            },
            "MAJOR PHARMACEUTICALS": {
              "ACETAMINOPHEN": "ACETAMINOPHEN",
              "ACETAMINOPHEN CHILDRENS": "ACETAMINOPHEN CHILDRENS",
              "ACETAMINOPHEN ER": "ACETAMINOPHEN ER",
              "ACETAMINOPHEN EXTRA STRENGTH": "ACETAMINOPHEN EXTRA STRENGTH",
              "CHILDRENS MAPAP RAPID TABS": "CHILDRENS MAPAP RAPID TABS",
              "JUNIOR MAPAP": "JUNIOR MAPAP",
              "MAPAP": "MAPAP",
              "MAPAP ACETAMINOPHEN EXTRA STR": "MAPAP ACETAMINOPHEN EXTRA STR",
              "MAPAP ARTHRITIS PAIN": "MAPAP ARTHRITIS PAIN",
              "MAPAP CHILDRENS": "MAPAP CHILDRENS",
              "MAPAP EXTRA STRENGTH": "MAPAP EXTRA STRENGTH"
            },
            "MALLINCKRODT HOSPITAL PRODUCTS": {
              "OFIRMEV": "OFIRMEV"
            },
            "MARLEX PHARMACEUTICALS": {
              "ACETAMINOPHEN": "ACETAMINOPHEN"
            },
            "MATERNAL SCIENCE": {
              "HEALTHY MAMA SHAKE THAT ACHE": "HEALTHY MAMA SHAKE THAT ACHE"
            },
            "MCKESSON": {
              "HM ACETAMINOPHEN CHILDRENS": "HM ACETAMINOPHEN CHILDRENS",
              "HM ARTHRITIS PAIN RELIEF": "HM ARTHRITIS PAIN RELIEF",
              "HM PAIN & FEVER CHILDRENS": "HM PAIN & FEVER CHILDRENS",
              "HM PAIN & FEVER INFANTS": "HM PAIN & FEVER INFANTS",
              "HM PAIN RELIEF": "HM PAIN RELIEF",
              "HM PAIN RELIEF CHILDRENS": "HM PAIN RELIEF CHILDRENS",
              "HM PAIN RELIEF EXTRA STRENGTH": "HM PAIN RELIEF EXTRA STRENGTH",
              "HM PAIN RELIEVER": "HM PAIN RELIEVER",
              "HM RAPID MELTS JUNIOR": "HM RAPID MELTS JUNIOR"
            },
            "MCKESSON SUNMARK": {
              "SM 8 HOUR PAIN RELIEF": "SM 8 HOUR PAIN RELIEF",
              "SM ARTHRITIS PAIN RELIEF": "SM ARTHRITIS PAIN RELIEF",
              "SM ARTHRITIS PAIN RELIEVER": "SM ARTHRITIS PAIN RELIEVER",
              "SM INFANTS PAIN RELIEVER": "SM INFANTS PAIN RELIEVER",
              "SM PAIN & FEVER CHILDRENS": "SM PAIN & FEVER CHILDRENS",
              "SM PAIN & FEVER INFANTS": "SM PAIN & FEVER INFANTS",
              "SM PAIN RELIEF EXTRA STRENGTH": "SM PAIN RELIEF EXTRA STRENGTH",
              "SM PAIN RELIEVER": "SM PAIN RELIEVER",
              "SM PAIN RELIEVER 8 HOUR EX ST": "SM PAIN RELIEVER 8 HOUR EX ST",
              "SM PAIN RELIEVER CHILDRENS": "SM PAIN RELIEVER CHILDRENS",
              "SM PAIN RELIEVER EX ST": "SM PAIN RELIEVER EX ST",
              "SM RAPID MELTS JUNIOR": "SM RAPID MELTS JUNIOR"
            },
            "MCNEIL CONSUMER": {
              "TYLENOL": "TYLENOL",
              "TYLENOL 8 HOUR": "TYLENOL 8 HOUR",
              "TYLENOL 8 HOUR ARTHRITIS PAIN": "TYLENOL 8 HOUR ARTHRITIS PAIN",
              "TYLENOL CHILDRENS": "TYLENOL CHILDRENS",
              "TYLENOL CHILDRENS CHEWABLES": "TYLENOL CHILDRENS CHEWABLES",
              "TYLENOL CHILDRENS PAIN + FEVER": "TYLENOL CHILDRENS PAIN + FEVER",
              "TYLENOL DISSOLVE PACKS": "TYLENOL DISSOLVE PACKS",
              "TYLENOL EXTRA STRENGTH": "TYLENOL EXTRA STRENGTH",
              "TYLENOL INFANTS": "TYLENOL INFANTS",
              "TYLENOL INFANTS PAIN+FEVER": "TYLENOL INFANTS PAIN+FEVER",
              "TYLENOL SORE THROAT DAYTIME": "TYLENOL SORE THROAT DAYTIME"
            },
            "MEDICINE SHOPPE": {
              "ARTHRITIS PAIN RELIEVER": "ARTHRITIS PAIN RELIEVER",
              "CHILDRENS MEDI-TABS": "CHILDRENS MEDI-TABS",
              "MEDI-TABS CHILDRENS": "MEDI-TABS CHILDRENS",
              "MEDI-TABS EXTRA STRENGTH": "MEDI-TABS EXTRA STRENGTH",
              "MEDI-TABS JUNIOR STRENGTH": "MEDI-TABS JUNIOR STRENGTH",
              "PAIN RELIEF CHILDRENS": "PAIN RELIEF CHILDRENS",
              "PAIN RELIEVER EXTRA STRENGTH": "PAIN RELIEVER EXTRA STRENGTH"
            },
            "MEDIQUE PRODUCTS": {
              "ACETAMINOPHEN": "ACETAMINOPHEN",
              "ACETAMINOPHEN EXTRA STRENGTH": "ACETAMINOPHEN EXTRA STRENGTH",
              "NON-ASPIRIN": "NON-ASPIRIN",
              "NON-ASPIRIN EXTRA STRENGTH": "NON-ASPIRIN EXTRA STRENGTH"
            },
            "MEDLINE/DERMAL MANAGEMENT": {
              "ACETAMINOPHEN": "ACETAMINOPHEN",
              "ACETAMINOPHEN EXTRA STRENGTH": "ACETAMINOPHEN EXTRA STRENGTH"
            },
            "MEDTECH": {
              "CHLORASEPTIC SORE THROAT": "CHLORASEPTIC SORE THROAT",
              "LITTLE REMEDIES FOR FEVER": "LITTLE REMEDIES FOR FEVER",
              "STANBACK ASPIRIN FREE": "STANBACK ASPIRIN FREE"
            },
            "MEDVANTX": {
              "MAPAP": "MAPAP"
            },
            "MEIJER": {
              "ARTHRITIS PAIN RELIEF": "ARTHRITIS PAIN RELIEF",
              "CHILDRENS ASPIRIN FREE": "CHILDRENS ASPIRIN FREE",
              "MEIJER ASPIRIN FREE": "MEIJER ASPIRIN FREE",
              "MEIJER JR ST ASPIRIN FREE": "MEIJER JR ST ASPIRIN FREE",
              "PAIN & FEVER CHILDRENS": "PAIN & FEVER CHILDRENS",
              "PAIN & FEVER INFANTS": "PAIN & FEVER INFANTS",
              "PAIN RELIEF EXTRA STRENGTH": "PAIN RELIEF EXTRA STRENGTH"
            },
            "METHOD PHARMACEUTICALS": {
              "ACETAMINOPHEN": "ACETAMINOPHEN",
              "M-PAP": "M-PAP"
            },
            "MYLAN INSTITUTIONAL": {
              "DURACLON": "DURACLON"
            },
            "NEIL LABS": {
              "MAXAPAP MAXIMUM STRENGTH": "MAXAPAP MAXIMUM STRENGTH",
              "MAXAPAP REGULAR STRENGTH": "MAXAPAP REGULAR STRENGTH"
            },
            "NEW WORLD IMPORTS": {
              "NON-ASPIRIN": "NON-ASPIRIN"
            },
            "NUCARE PHARMACEUTICALS": {
              "ACETAMINOPHEN": "ACETAMINOPHEN"
            },
            "OHM LABS": {
              "8 HOUR PAIN RELIEVER": "8 HOUR PAIN RELIEVER",
              "ARTHRITIS PAIN RELIEF": "ARTHRITIS PAIN RELIEF"
            },
            "PAR PHARMACEUTICAL": {
              "Q-PAP": "Q-PAP",
              "Q-PAP EXTRA STRENGTH": "Q-PAP EXTRA STRENGTH"
            },
            "PAR PHARMACEUTICALS": {
              "Q-PAP CHILDRENS": "Q-PAP CHILDRENS"
            },
            "PDRX PHARMACEUTICAL": {
              "ACETAMINOPHEN": "ACETAMINOPHEN"
            },
            "PERRIGO": {
              "ACETAMINOPHEN": "ACETAMINOPHEN",
              "GOODSENSE ARTHRITIS PAIN": "GOODSENSE ARTHRITIS PAIN",
              "GOODSENSE PAIN & FEVER INFANTS": "GOODSENSE PAIN & FEVER INFANTS",
              "GOODSENSE PAIN RELIEF EXTRA ST": "GOODSENSE PAIN RELIEF EXTRA ST"
            },
            "PERRIGO PHARMACEUTICALS": {
              "ACETAMINOPHEN CHILDRENS": "ACETAMINOPHEN CHILDRENS",
              "GOODSENSE PAIN RELIEF": "GOODSENSE PAIN RELIEF",
              "PAIN RELIEF CHILDRENS": "PAIN RELIEF CHILDRENS"
            },
            "PHARBEST PHARMACEUTICALS": {
              "PHARBETOL": "PHARBETOL",
              "PHARBETOL EXTRA STRENGTH": "PHARBETOL EXTRA STRENGTH"
            },
            "PHARMACEUTICAL ASSOCIATES": {
              "ACETAMINOPHEN": "ACETAMINOPHEN",
              "CHILDRENS ACETAMINOPHEN": "CHILDRENS ACETAMINOPHEN"
            },
            "PHARMEDIX": {
              "ACETAMINOPHEN": "ACETAMINOPHEN",
              "ACETAMINOPHEN EXTRA STRENGTH": "ACETAMINOPHEN EXTRA STRENGTH"
            },
            "PHARMPAK": {
              "ACETAMINOPHEN": "ACETAMINOPHEN"
            },
            "PLUS PHARMA": {
              "ACETAMINOPHEN": "ACETAMINOPHEN",
              "ACETAMINOPHEN EXTRA STRENGTH": "ACETAMINOPHEN EXTRA STRENGTH"
            },
            "PRECISION DOSE, INC": {
              "ACETAMINOPHEN": "ACETAMINOPHEN",
              "CHILDRENS ACETAMINOPHEN": "CHILDRENS ACETAMINOPHEN"
            },
            "PREFERRED PHARMACEUTICALS": {
              "ACETAMINOPHEN": "ACETAMINOPHEN",
              "ACETAMINOPHEN EXTRA STRENGTH": "ACETAMINOPHEN EXTRA STRENGTH",
              "PAIN RELIEF EXTRA STRENGTH": "PAIN RELIEF EXTRA STRENGTH",
              "Q-PAP": "Q-PAP"
            },
            "PROFICIENT RX": {
              "ACETAMINOPHEN": "ACETAMINOPHEN",
              "ACETAMINOPHEN CHILDRENS": "ACETAMINOPHEN CHILDRENS",
              "ACETAMINOPHEN EXTRA STRENGTH": "ACETAMINOPHEN EXTRA STRENGTH"
            },
            "PUBLIX SUPER MARKETS INC.": {
              "PX ARTHRITIS PAIN RELIEF": "PX ARTHRITIS PAIN RELIEF",
              "PX CHILDRENS PAIN RELIEF": "PX CHILDRENS PAIN RELIEF",
              "PX PAIN RELIEF EXTRA STRENGTH": "PX PAIN RELIEF EXTRA STRENGTH"
            },
            "QUALITY CARE": {
              "ACETAMINOPHEN": "ACETAMINOPHEN",
              "MAPAP": "MAPAP"
            },
            "QUALITY CARE PRODUCTS": {
              "ARTHRITIS PAIN RELIEF": "ARTHRITIS PAIN RELIEF",
              "MAPAP": "MAPAP"
            },
            "R I J PHARMACEUTICALS": {
              "CHILDRENS ACETAMINOPHEN": "CHILDRENS ACETAMINOPHEN"
            },
            "RELIABLE 1 LABS": {
              "ACETAMINOPHEN": "ACETAMINOPHEN",
              "ACETAMINOPHEN EXTRA STRENGTH": "ACETAMINOPHEN EXTRA STRENGTH"
            },
            "RICHMOND PHARMACEUTICALS": {
              "ACETAMINOPHEN": "ACETAMINOPHEN",
              "CHILDRENS APAP": "CHILDRENS APAP"
            },
            "RITE AID CORPORATION": {
              "RA 8 HOUR PAIN RELIEF": "RA 8 HOUR PAIN RELIEF",
              "RA ACETAMINOPHEN": "RA ACETAMINOPHEN",
              "RA ACETAMINOPHEN CHILDRENS": "RA ACETAMINOPHEN CHILDRENS",
              "RA ACETAMINOPHEN EX ST": "RA ACETAMINOPHEN EX ST",
              "RA ACETAMINOPHEN RAPID MELTS": "RA ACETAMINOPHEN RAPID MELTS",
              "RA ARTHRITIS PAIN RELIEF": "RA ARTHRITIS PAIN RELIEF",
              "RA CHILDRENS FEVER/PAIN": "RA CHILDRENS FEVER/PAIN",
              "RA CHILDRENS NON-ASPIRIN": "RA CHILDRENS NON-ASPIRIN",
              "RA FEVER REDUCER/PAIN RELIEVER": "RA FEVER REDUCER/PAIN RELIEVER",
              "RA PAIN RELIEF ACETAMINOPHEN": "RA PAIN RELIEF ACETAMINOPHEN",
              "RA PAIN RELIEVER EX ST": "RA PAIN RELIEVER EX ST"
            },
            "RUGBY LABORATORIES": {
              "ACETAMINOPHEN": "ACETAMINOPHEN",
              "ACETAMINOPHEN EXTRA STRENGTH": "ACETAMINOPHEN EXTRA STRENGTH",
              "ACETAMINOPHEN INFANTS": "ACETAMINOPHEN INFANTS",
              "PAIN & FEVER": "PAIN & FEVER",
              "PAIN & FEVER CHILDRENS": "PAIN & FEVER CHILDRENS",
              "PAIN & FEVER EXTRA STRENGTH": "PAIN & FEVER EXTRA STRENGTH",
              "PAIN & FEVER INFANTS": "PAIN & FEVER INFANTS"
            },
            "RXCHANGE CO": {
              "ACETAMINOPHEN": "ACETAMINOPHEN"
            },
            "SAM'S WEST": {
              "MM ARTHRITIS PAIN": "MM ARTHRITIS PAIN"
            },
            "SCOT-TUSSIN": {
              "FEBROL": "FEBROL"
            },
            "SELECT BRAND": {
              "SB ARTHRITIS PAIN RELIEF": "SB ARTHRITIS PAIN RELIEF",
              "SB CHILDRENS NON-ASPIRIN": "SB CHILDRENS NON-ASPIRIN",
              "SB NON-ASPIRIN": "SB NON-ASPIRIN",
              "SB NON-ASPIRIN EXTRA STRENGTH": "SB NON-ASPIRIN EXTRA STRENGTH",
              "SB NON-ASPIRIN JR STRENGTH": "SB NON-ASPIRIN JR STRENGTH",
              "SB PAIN RELIEVER CHILDRENS": "SB PAIN RELIEVER CHILDRENS",
              "SB PAIN RELIEVER EX ST": "SB PAIN RELIEVER EX ST"
            },
            "SILARX": {
              "CHILDRENS SILAPAP": "CHILDRENS SILAPAP"
            },
            "SKY PACKAGING": {
              "ACETAMINOPHEN": "ACETAMINOPHEN"
            },
            "ST MARY'S MPP": {
              "ACETAMINOPHEN": "ACETAMINOPHEN",
              "MAPAP": "MAPAP",
              "NON-ASPIRIN PAIN RELIEF": "NON-ASPIRIN PAIN RELIEF",
              "TACTINAL": "TACTINAL"
            },
            "STRIDES USA CONSUMER": {
              "PEDIACARE INFANT FEVER/PAIN": "PEDIACARE INFANT FEVER/PAIN"
            },
            "TARGET": {
              "TGT ACETAMINOPHEN CHILDRENS": "TGT ACETAMINOPHEN CHILDRENS",
              "TGT ACETAMINOPHEN EX ST": "TGT ACETAMINOPHEN EX ST",
              "TGT ACETAMINOPHEN INFANTS": "TGT ACETAMINOPHEN INFANTS",
              "TGT ACETAMINOPHEN MELTS CHILD": "TGT ACETAMINOPHEN MELTS CHILD",
              "TGT ARTHRITIS PAIN RELIEF": "TGT ARTHRITIS PAIN RELIEF",
              "TGT CHILDRENS ACETAMINOPHEN": "TGT CHILDRENS ACETAMINOPHEN",
              "TGT PAIN RELIEVER JR ST": "TGT PAIN RELIEVER JR ST",
              "TGT PAIN/FEVER ACETAMINOPHEN": "TGT PAIN/FEVER ACETAMINOPHEN"
            },
            "TARO": {
              "ELIXSURE FEVER/PAIN": "ELIXSURE FEVER/PAIN",
              "FEVERALL ADULTS": "FEVERALL ADULTS",
              "FEVERALL CHILDRENS": "FEVERALL CHILDRENS",
              "FEVERALL INFANTS": "FEVERALL INFANTS",
              "FEVERALL JUNIOR STRENGTH": "FEVERALL JUNIOR STRENGTH"
            },
            "TIME-CAP LABS": {
              "ACETAMINOPHEN": "ACETAMINOPHEN",
              "ACETAMINOPHEN EXTRA STRENGTH": "ACETAMINOPHEN EXTRA STRENGTH"
            },
            "TOPCO": {
              "8 HOUR PAIN RELIEF": "8 HOUR PAIN RELIEF",
              "8 HR ARTHRITIS PAIN RELIEF": "8 HR ARTHRITIS PAIN RELIEF",
              "ACETAMINOPHEN JUNIOR STRENGTH": "ACETAMINOPHEN JUNIOR STRENGTH",
              "ACETAMINOPHEN RAPID TABS CHILD": "ACETAMINOPHEN RAPID TABS CHILD",
              "ARTHRITIS PAIN RELIEF": "ARTHRITIS PAIN RELIEF",
              "EXTRA STRENGTH PAIN RELIEF": "EXTRA STRENGTH PAIN RELIEF",
              "FEVER REDUCER CHILDRENS": "FEVER REDUCER CHILDRENS",
              "PAIN & FEVER CHILDRENS": "PAIN & FEVER CHILDRENS",
              "PAIN & FEVER INFANTS": "PAIN & FEVER INFANTS",
              "PAIN RELIEF": "PAIN RELIEF",
              "PAIN RELIEF CHILDRENS": "PAIN RELIEF CHILDRENS",
              "PAIN RELIEF EXTRA STRENGTH": "PAIN RELIEF EXTRA STRENGTH",
              "PAIN RELIEF REGULAR STRENGTH": "PAIN RELIEF REGULAR STRENGTH"
            },
            "VANGARD": {
              "ACETAMINOPHEN": "ACETAMINOPHEN"
            },
            "VISTAPHARM INC.": {
              "ACETAMINOPHEN": "ACETAMINOPHEN"
            },
            "WAL-MART": {
              "EQ 8HR ARTHRITIS PAIN RELIEF": "EQ 8HR ARTHRITIS PAIN RELIEF",
              "EQ ACETAMINOPHEN": "EQ ACETAMINOPHEN",
              "EQ ACETAMINOPHEN CHILDRENS": "EQ ACETAMINOPHEN CHILDRENS",
              "EQ ACETAMINOPHEN JUNIOR": "EQ ACETAMINOPHEN JUNIOR",
              "EQ ARTHRITIS PAIN": "EQ ARTHRITIS PAIN",
              "EQ CHILDRENS PAIN RELIEVER": "EQ CHILDRENS PAIN RELIEVER",
              "EQ PAIN & FEVER CHILDRENS": "EQ PAIN & FEVER CHILDRENS",
              "EQ PAIN & FEVER INFANTS": "EQ PAIN & FEVER INFANTS",
              "EQ PAIN RELIEF/RAPID BURST": "EQ PAIN RELIEF/RAPID BURST",
              "EQ PAIN RELIEVER": "EQ PAIN RELIEVER",
              "EQ PAIN RELIEVER JUNIOR": "EQ PAIN RELIEVER JUNIOR",
              "PAIN RELIEF REGULAR STRENGTH": "PAIN RELIEF REGULAR STRENGTH"
            },
            "WALGREENS": {
              "ACETAMINOPHEN ER": "ACETAMINOPHEN ER",
              "ACETAMINOPHEN EXTRA STRENGTH": "ACETAMINOPHEN EXTRA STRENGTH",
              "ACETAMINOPHEN JUNIOR STRENGTH": "ACETAMINOPHEN JUNIOR STRENGTH",
              "ARTHRITIS PAIN RELIEF": "ARTHRITIS PAIN RELIEF",
              "ARTHRITIS PAIN RELIEVER": "ARTHRITIS PAIN RELIEVER",
              "CHILDRENS ACETAMINOPHEN": "CHILDRENS ACETAMINOPHEN",
              "CHILDRENS NON-ASPIRIN": "CHILDRENS NON-ASPIRIN",
              "FEVER REDUCER CHILDRENS": "FEVER REDUCER CHILDRENS",
              "INFANTS PAIN & FEVER": "INFANTS PAIN & FEVER",
              "NON-ASPIRIN JR STRENGTH": "NON-ASPIRIN JR STRENGTH",
              "PAIN & FEVER CHILDRENS": "PAIN & FEVER CHILDRENS",
              "PAIN & FEVER INFANTS": "PAIN & FEVER INFANTS",
              "PAIN & FEVER JUNIOR": "PAIN & FEVER JUNIOR",
              "PAIN RELIEF CHILDRENS": "PAIN RELIEF CHILDRENS",
              "PAIN RELIEVER": "PAIN RELIEVER",
              "PAIN RELIEVER EXTRA STRENGTH": "PAIN RELIEVER EXTRA STRENGTH",
              "PAIN RELIEVER/FEVER REDUCER": "PAIN RELIEVER/FEVER REDUCER"
            },
            "WOMEN'S CHOICE PHARMACEUTICALS": {
              "ED-APAP": "ED-APAP"
            },
            "X-GEN PHARMACEUTICALS, INC": {
              "CLONIDINE HCL (ANALGESIA)": "CLONIDINE HCL (ANALGESIA)"
            }
          }
        },
        "Analgesics-Peptide Channel Blockers": {
          "Selective N-Type Neuronal Calcium Channel Blockers": {
            "TERSERA THERAPEUTICS": {
              "PRIALT": "PRIALT"
            }
          }
        },
        "Salicylates": {
          "Salicylate Combinations": {
            "AMERICAN SALES COMPANY": {
              "EFFERVESCENT PAIN RELIEF": "EFFERVESCENT PAIN RELIEF"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP EFFERVESCENT ANTACID/PAIN": "GNP EFFERVESCENT ANTACID/PAIN"
            },
            "BAYER CONSUMER": {
              "ALKA-SELTZER": "ALKA-SELTZER",
              "ALKA-SELTZER EXTRA STRENGTH": "ALKA-SELTZER EXTRA STRENGTH",
              "ALKA-SELTZER ORIGINAL": "ALKA-SELTZER ORIGINAL",
              "BAYER PLUS": "BAYER PLUS",
              "BAYER WOMENS": "BAYER WOMENS"
            },
            "CHAIN DRUG CONSORTIUM": {
              "EFFERVESCENT ANTACID/PAIN REL": "EFFERVESCENT ANTACID/PAIN REL"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC EFFERVESCENT ANTACID/PAIN": "QC EFFERVESCENT ANTACID/PAIN"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS ANTACID & PAIN RELIEVER": "CVS ANTACID & PAIN RELIEVER",
              "CVS BUFFERED ASPIRIN": "CVS BUFFERED ASPIRIN",
              "CVS EFFERVESCENT ANTACID": "CVS EFFERVESCENT ANTACID"
            },
            "CYPRESS PHARMACEUTICAL": {
              "CHOLINE-MAG TRISALICYLATE": "CHOLINE-MAG TRISALICYLATE"
            },
            "DOVER PHARMACEUTICALS": {
              "NEUTRALIN": "NEUTRALIN"
            },
            "DUCERE PHARMA": {
              "BUFFERIN": "BUFFERIN"
            },
            "EQUALINE": {
              "EQL ANTACID/PAIN RELIEF": "EQL ANTACID/PAIN RELIEF",
              "EQL BUFFERED ASPIRIN": "EQL BUFFERED ASPIRIN"
            },
            "GEISS DESTIN & DUNN": {
              "GOODSENSE ANTACID/PAIN RELIEF": "GOODSENSE ANTACID/PAIN RELIEF"
            },
            "GERI-CARE": {
              "BUFFERED ASPIRIN": "BUFFERED ASPIRIN"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "ASCRIPTIN": "ASCRIPTIN",
              "ASCRIPTIN MS": "ASCRIPTIN MS",
              "BUFFERIN": "BUFFERIN",
              "BUFFERIN EXTRA STRENGTH": "BUFFERIN EXTRA STRENGTH",
              "BUFFERIN LOW DOSE": "BUFFERIN LOW DOSE"
            },
            "LEADER BRAND PRODUCTS": {
              "EFFERVESCENT ANTACID/PAIN": "EFFERVESCENT ANTACID/PAIN",
              "TRI-BUFFERED ASPIRIN": "TRI-BUFFERED ASPIRIN"
            },
            "MAGNO-HUMPHRIES LABORATORIES": {
              "TRI-BUFFERED ASPIRIN": "TRI-BUFFERED ASPIRIN"
            },
            "MAJOR PHARMACEUTICALS": {
              "EFFERVESCENT PAIN RELIEF": "EFFERVESCENT PAIN RELIEF",
              "TRI-BUFFERED ASPIRIN": "TRI-BUFFERED ASPIRIN"
            },
            "MCKESSON SUNMARK": {
              "SM ASPIRIN TRI-BUFFERED": "SM ASPIRIN TRI-BUFFERED",
              "SM EFFERVESCENT PAIN RELIEF": "SM EFFERVESCENT PAIN RELIEF"
            },
            "MEDIQUE PRODUCTS": {
              "MEDI-SELTZER": "MEDI-SELTZER"
            },
            "PUBLIX SUPER MARKETS INC.": {
              "PX EFFERVESCENT": "PX EFFERVESCENT"
            },
            "RITE AID CORPORATION": {
              "RA ANTACID PAIN RELIEF": "RA ANTACID PAIN RELIEF",
              "RA TRI-BUFFERED ASPIRIN": "RA TRI-BUFFERED ASPIRIN"
            },
            "SELECT BRAND": {
              "SB EFFERVESCENT PAIN RELIEF": "SB EFFERVESCENT PAIN RELIEF"
            },
            "SILARX": {
              "CHOLINE-MAG TRISALICYLATE": "CHOLINE-MAG TRISALICYLATE"
            },
            "WAL-MART": {
              "EQ ANTACID & PAIN RELIEF": "EQ ANTACID & PAIN RELIEF",
              "EQ BUFFERED ASPIRIN": "EQ BUFFERED ASPIRIN",
              "EQ EFFERVESCENT PAIN RELIEF": "EQ EFFERVESCENT PAIN RELIEF"
            },
            "WALGREENS": {
              "EFFERVESCENT PAIN RELIEF": "EFFERVESCENT PAIN RELIEF",
              "TRI-BUFFERED ASPIRIN": "TRI-BUFFERED ASPIRIN"
            }
          },
          "Salicylates": {
            "A-S MEDICATION SOLUTIONS": {
              "ASPIRIN": "ASPIRIN",
              "ASPIRIN CHILDRENS": "ASPIRIN CHILDRENS",
              "ASPIRIN EC": "ASPIRIN EC",
              "DIFLUNISAL": "DIFLUNISAL",
              "SALSALATE": "SALSALATE"
            },
            "ACELLA PHARMACEUTICALS": {
              "SALSALATE": "SALSALATE"
            },
            "AIDAREX PHARMACEUTICALS": {
              "ASPIRIN": "ASPIRIN",
              "ASPIRIN EC": "ASPIRIN EC",
              "DIFLUNISAL": "DIFLUNISAL"
            },
            "AMERICAN HEALTH PACKAGING": {
              "ASPIRIN EC": "ASPIRIN EC"
            },
            "AMERICAN SALES COMPANY": {
              "ASPIRIN": "ASPIRIN",
              "ASPIRIN EC": "ASPIRIN EC",
              "ASPIRIN LOW DOSE": "ASPIRIN LOW DOSE"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP ASPIRIN": "GNP ASPIRIN",
              "GNP ASPIRIN LOW DOSE": "GNP ASPIRIN LOW DOSE"
            },
            "AMNEAL PHARMACEUTICALS": {
              "SALSALATE": "SALSALATE"
            },
            "ANDAPHARM": {
              "SALSALATE": "SALSALATE"
            },
            "APHENA PHARMA SOLUTIONS": {
              "ASPIR-LOW": "ASPIR-LOW",
              "ASPIRIN EC": "ASPIRIN EC",
              "SALSALATE": "SALSALATE"
            },
            "ATLANTIC BIOLOGICALS": {
              "ASPIRIN": "ASPIRIN"
            },
            "AUBURN PHARMACEUTICAL": {
              "ASPIRIN": "ASPIRIN",
              "ASPIRIN 81": "ASPIRIN 81",
              "CHILDRENS ASPIRIN LOW STRENGTH": "CHILDRENS ASPIRIN LOW STRENGTH",
              "ECPIRIN": "ECPIRIN"
            },
            "AURORA HEALTHCARE": {
              "ASPIRIN": "ASPIRIN",
              "ASPIRIN CHILDRENS": "ASPIRIN CHILDRENS",
              "ASPIRIN EC": "ASPIRIN EC",
              "ASPIRIN LOW DOSE": "ASPIRIN LOW DOSE"
            },
            "AVET PHARMACEUTICALS": {
              "DIFLUNISAL": "DIFLUNISAL"
            },
            "AVION PHARMACEUTICALS": {
              "DISALCID": "DISALCID"
            },
            "AVKARE": {
              "SALSALATE": "SALSALATE"
            },
            "AVPAK": {
              "SALSALATE": "SALSALATE"
            },
            "BAYER CONSUMER": {
              "BAYER ADVANCED ASPIRIN EX ST": "BAYER ADVANCED ASPIRIN EX ST",
              "BAYER ADVANCED ASPIRIN REG ST": "BAYER ADVANCED ASPIRIN REG ST",
              "BAYER ASPIRIN": "BAYER ASPIRIN",
              "BAYER ASPIRIN EC LOW DOSE": "BAYER ASPIRIN EC LOW DOSE",
              "BAYER ASPIRIN EXTRA STRENGTH": "BAYER ASPIRIN EXTRA STRENGTH",
              "BAYER LOW DOSE": "BAYER LOW DOSE"
            },
            "BENE HEALTH OTC": {
              "ASPIRIN": "ASPIRIN"
            },
            "BERGEN BRUNSWIG": {
              "GNP ADULT ASPIRIN LOW STRENGTH": "GNP ADULT ASPIRIN LOW STRENGTH",
              "GNP ASPIRIN": "GNP ASPIRIN",
              "GNP ASPIRIN LOW DOSE": "GNP ASPIRIN LOW DOSE"
            },
            "BRYANT RANCH PREPACK": {
              "ASPIRIN": "ASPIRIN",
              "ASPIRIN EC": "ASPIRIN EC",
              "ASPIRIN EC LOW DOSE": "ASPIRIN EC LOW DOSE",
              "SALSALATE": "SALSALATE"
            },
            "CAMBRIDGE THERAPEUTICS TECHN": {
              "ASPIRIN": "ASPIRIN"
            },
            "CHAIN DRUG CONSORTIUM": {
              "ASPIRIN": "ASPIRIN",
              "ASPIRIN EC": "ASPIRIN EC",
              "ASPIRIN EC LOW DOSE": "ASPIRIN EC LOW DOSE",
              "BACKACHE RELIEF EXTRA STRENGTH": "BACKACHE RELIEF EXTRA STRENGTH",
              "CHILDRENS ASPIRIN": "CHILDRENS ASPIRIN"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC ASPIRIN": "QC ASPIRIN",
              "QC ASPIRIN LOW DOSE": "QC ASPIRIN LOW DOSE",
              "QC CHILDRENS ASPIRIN": "QC CHILDRENS ASPIRIN",
              "QC ENTERIC ASPIRIN": "QC ENTERIC ASPIRIN"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "ASPIRIN": "ASPIRIN"
            },
            "CONTRACT PHARMACAL CORPORATION": {
              "ASPIRIN EC LOW DOSE": "ASPIRIN EC LOW DOSE"
            },
            "COSTCO WHOLESALE": {
              "KLS ASPIRIN EC": "KLS ASPIRIN EC",
              "KLS ASPIRIN LOW DOSE": "KLS ASPIRIN LOW DOSE"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS ASPIRIN": "CVS ASPIRIN",
              "CVS ASPIRIN ADULT LOW DOSE": "CVS ASPIRIN ADULT LOW DOSE",
              "CVS ASPIRIN ADULT LOW STRENGTH": "CVS ASPIRIN ADULT LOW STRENGTH",
              "CVS ASPIRIN CHILD": "CVS ASPIRIN CHILD",
              "CVS ASPIRIN EC": "CVS ASPIRIN EC",
              "CVS ASPIRIN LOW DOSE": "CVS ASPIRIN LOW DOSE",
              "CVS ASPIRIN LOW STRENGTH": "CVS ASPIRIN LOW STRENGTH",
              "CVS BACKACHE RELIEF": "CVS BACKACHE RELIEF",
              "CVS CHILDRENS ASPIRIN": "CVS CHILDRENS ASPIRIN"
            },
            "DIRECT RX": {
              "ASPIRIN EC LOW DOSE": "ASPIRIN EC LOW DOSE"
            },
            "DOVER PHARMACEUTICALS": {
              "ASPIRTAB": "ASPIRTAB",
              "ASPIRTAB MAXIMUM STRENGTH": "ASPIRTAB MAXIMUM STRENGTH"
            },
            "ECI PHARMACEUTICALS": {
              "SALSALATE": "SALSALATE"
            },
            "EQUALINE": {
              "EQL ADULT ASPIRIN LOW STRENGTH": "EQL ADULT ASPIRIN LOW STRENGTH",
              "EQL ASPIRIN": "EQL ASPIRIN",
              "EQL ASPIRIN EC": "EQL ASPIRIN EC",
              "EQL ASPIRIN LOW DOSE": "EQL ASPIRIN LOW DOSE",
              "EQL CHILDRENS ASPIRIN": "EQL CHILDRENS ASPIRIN"
            },
            "FAGRON": {
              "ASPIRIN": "ASPIRIN"
            },
            "FOUNDATION CONSUMER HEALTHCARE": {
              "ST JOSEPH LOW DOSE": "ST JOSEPH LOW DOSE"
            },
            "FREEDOM PHARMACEUTICALS": {
              "ASPIRIN": "ASPIRIN"
            },
            "GEISS DESTIN & DUNN": {
              "GOODSENSE ASPIRIN": "GOODSENSE ASPIRIN",
              "GOODSENSE ASPIRIN ADULT LOW ST": "GOODSENSE ASPIRIN ADULT LOW ST",
              "GOODSENSE ASPIRIN LOW DOSE": "GOODSENSE ASPIRIN LOW DOSE"
            },
            "GERI-CARE": {
              "ASPIRIN": "ASPIRIN",
              "ASPIRIN ADULT LOW DOSE": "ASPIRIN ADULT LOW DOSE",
              "ASPIRIN ADULT LOW STRENGTH": "ASPIRIN ADULT LOW STRENGTH",
              "ASPIRIN EC": "ASPIRIN EC"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "DOANS EXTRA STRENGTH": "DOANS EXTRA STRENGTH",
              "DOANS PILLS": "DOANS PILLS"
            },
            "H E B": {
              "H-E-B ASPIRIN": "H-E-B ASPIRIN"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "ASPIRIN": "ASPIRIN",
              "ASPIRIN EC": "ASPIRIN EC",
              "DIFLUNISAL": "DIFLUNISAL",
              "SALSALATE": "SALSALATE"
            },
            "HANNAFORD BROTHERS": {
              "ASPIRIN": "ASPIRIN",
              "ASPIRIN EC": "ASPIRIN EC"
            },
            "HOSPAK UNIT DOSE PRODUCTS": {
              "ASPIRIN": "ASPIRIN",
              "CHILDRENS ASPIRIN": "CHILDRENS ASPIRIN"
            },
            "ILEX": {
              "ST JOSEPH ASPIRIN": "ST JOSEPH ASPIRIN"
            },
            "KAISER FOUNDATION HOSPITAL": {
              "KP ASPIRIN": "KP ASPIRIN"
            },
            "KROGER COMPANY": {
              "ASPIRIN": "ASPIRIN",
              "ASPIRIN LOW DOSE": "ASPIRIN LOW DOSE"
            },
            "LEADER BRAND PRODUCTS": {
              "ADULT ASPIRIN REGIMEN": "ADULT ASPIRIN REGIMEN",
              "ASPIRIN": "ASPIRIN",
              "ASPIRIN EC": "ASPIRIN EC",
              "ASPIRIN LOW DOSE": "ASPIRIN LOW DOSE",
              "ASPIRIN LOW DOSE ADULT": "ASPIRIN LOW DOSE ADULT",
              "ASPIRIN LOW STRENGTH": "ASPIRIN LOW STRENGTH",
              "ASPIRIN REGIMEN LOW DOSE ADULT": "ASPIRIN REGIMEN LOW DOSE ADULT",
              "CHILDRENS ASPIRIN": "CHILDRENS ASPIRIN"
            },
            "LETCO MEDICAL": {
              "ASPIRIN": "ASPIRIN",
              "SODIUM SALICYLATE": "SODIUM SALICYLATE"
            },
            "LIBERTY PHARMACEUTICAL": {
              "ASPIRIN EC": "ASPIRIN EC",
              "SALSALATE": "SALSALATE"
            },
            "MAGNO-HUMPHRIES LABORATORIES": {
              "ASPIRIN EC": "ASPIRIN EC",
              "ASPIRIN EC ADULT LOW STRENGTH": "ASPIRIN EC ADULT LOW STRENGTH",
              "ASPIRIN EC LOW DOSE": "ASPIRIN EC LOW DOSE"
            },
            "MAJOR PHARMACEUTICALS": {
              "ASPIR-LOW": "ASPIR-LOW",
              "ASPIRIN": "ASPIRIN",
              "ASPIRIN ADULT": "ASPIRIN ADULT",
              "ASPIRIN EC": "ASPIRIN EC",
              "ASPIRIN LOW DOSE": "ASPIRIN LOW DOSE"
            },
            "MARLEX PHARMACEUTICALS": {
              "ASPIRIN": "ASPIRIN",
              "ASPIRIN CHILDRENS": "ASPIRIN CHILDRENS",
              "ASPIRIN EC": "ASPIRIN EC",
              "SALSALATE": "SALSALATE"
            },
            "MCKESSON": {
              "HM ASPIRIN": "HM ASPIRIN",
              "HM ASPIRIN EC": "HM ASPIRIN EC",
              "HM ASPIRIN EC LOW DOSE": "HM ASPIRIN EC LOW DOSE"
            },
            "MCKESSON SUNMARK": {
              "SM ASPIRIN": "SM ASPIRIN",
              "SM ASPIRIN ADULT LOW STRENGTH": "SM ASPIRIN ADULT LOW STRENGTH",
              "SM ASPIRIN EC": "SM ASPIRIN EC",
              "SM ASPIRIN EC LOW STRENGTH": "SM ASPIRIN EC LOW STRENGTH",
              "SM ASPIRIN LOW DOSE": "SM ASPIRIN LOW DOSE",
              "SM CHILDRENS ASPIRIN": "SM CHILDRENS ASPIRIN"
            },
            "MEDICINE SHOPPE": {
              "ADULT ASPIRIN EC LOW STRENGTH": "ADULT ASPIRIN EC LOW STRENGTH",
              "ASPIRIN": "ASPIRIN",
              "ASPIRIN EC": "ASPIRIN EC",
              "CHILDRENS ASPIRIN": "CHILDRENS ASPIRIN"
            },
            "MEDIQUE PRODUCTS": {
              "MEDIQUE ASPIRIN": "MEDIQUE ASPIRIN"
            },
            "MEDISCA": {
              "ACETYL SALICYLIC ACID": "ACETYL SALICYLIC ACID"
            },
            "MEDTECH": {
              "ECOTRIN": "ECOTRIN",
              "ECOTRIN LOW STRENGTH": "ECOTRIN LOW STRENGTH",
              "ECOTRIN MAXIMUM STRENGTH": "ECOTRIN MAXIMUM STRENGTH",
              "MOMENTUM MUSCULAR BACKACHE": "MOMENTUM MUSCULAR BACKACHE",
              "PERCOGESIC BACKACHE RELIEF": "PERCOGESIC BACKACHE RELIEF"
            },
            "MEIJER": {
              "ASPIRIN": "ASPIRIN",
              "ASPIRIN LOW DOSE": "ASPIRIN LOW DOSE",
              "CHILDRENS ASPIRIN": "CHILDRENS ASPIRIN",
              "MEIJER ASPIRIN EC": "MEIJER ASPIRIN EC"
            },
            "METHOD PHARMACEUTICALS": {
              "SALSALATE": "SALSALATE"
            },
            "NATIONWIDE LABORATORIES": {
              "SALSALATE": "SALSALATE"
            },
            "NEIL LABS": {
              "ASPIRIN EC": "ASPIRIN EC",
              "ASPIRIN EC LOW DOSE": "ASPIRIN EC LOW DOSE"
            },
            "NEW WORLD IMPORTS": {
              "ASPIRIN": "ASPIRIN"
            },
            "NUCARE PHARMACEUTICALS": {
              "ASPIRIN": "ASPIRIN",
              "ASPIRIN EC": "ASPIRIN EC",
              "SALSALATE": "SALSALATE"
            },
            "PAR PHARMACEUTICAL": {
              "ASPIRIN": "ASPIRIN",
              "ASPIRIN EC": "ASPIRIN EC",
              "ASPIRIN EC LOW DOSE": "ASPIRIN EC LOW DOSE"
            },
            "PCCA": {
              "ASPIRIN": "ASPIRIN",
              "SODIUM SALICYLATE": "SODIUM SALICYLATE"
            },
            "PDRX PHARMACEUTICAL": {
              "ASPIRIN": "ASPIRIN",
              "ASPIRIN EC": "ASPIRIN EC",
              "DIFLUNISAL": "DIFLUNISAL",
              "GOODSENSE ASPIRIN LOW DOSE": "GOODSENSE ASPIRIN LOW DOSE",
              "SALSALATE": "SALSALATE"
            },
            "PDRX PHARMACEUTICALS": {
              "ASPIRIN": "ASPIRIN"
            },
            "PERRIGO": {
              "ASPIRIN": "ASPIRIN"
            },
            "PHARBEST PHARMACEUTICALS": {
              "ASPIRIN": "ASPIRIN",
              "ASPIRIN EC": "ASPIRIN EC"
            },
            "PHARMPAK": {
              "ASPIRIN": "ASPIRIN"
            },
            "PLUS PHARMA": {
              "ASPIRIN": "ASPIRIN"
            },
            "PREFERRED PHARMACEUTICALS": {
              "ASPIRIN ADULT LOW STRENGTH": "ASPIRIN ADULT LOW STRENGTH",
              "ASPIRIN EC": "ASPIRIN EC",
              "SALSALATE": "SALSALATE"
            },
            "PROFICIENT RX": {
              "ASPIR-LOW": "ASPIR-LOW",
              "ASPIRIN EC": "ASPIRIN EC",
              "ASPIRIN LOW DOSE": "ASPIRIN LOW DOSE",
              "GOODSENSE ASPIRIN LOW DOSE": "GOODSENSE ASPIRIN LOW DOSE"
            },
            "PUBLIX SUPER MARKETS INC.": {
              "PX ASPIRIN": "PX ASPIRIN",
              "PX ENTERIC ASPIRIN": "PX ENTERIC ASPIRIN"
            },
            "QUALITY CARE": {
              "ASPIRIN": "ASPIRIN",
              "DIFLUNISAL": "DIFLUNISAL",
              "SALSALATE": "SALSALATE"
            },
            "QUALITY CARE PRODUCTS": {
              "ASPIRIN ADULT LOW STRENGTH": "ASPIRIN ADULT LOW STRENGTH",
              "ASPIRIN EC": "ASPIRIN EC",
              "ASPIRIN LOW DOSE": "ASPIRIN LOW DOSE",
              "DIFLUNISAL": "DIFLUNISAL"
            },
            "RELIABLE 1 LABS": {
              "ASPIRIN": "ASPIRIN",
              "ASPIRIN ADULT LOW STRENGTH": "ASPIRIN ADULT LOW STRENGTH",
              "ASPIRIN EC": "ASPIRIN EC"
            },
            "RICHMOND PHARMACEUTICALS": {
              "ASPIRIN": "ASPIRIN",
              "ASPIRIN EC": "ASPIRIN EC",
              "ASPIRIN EC LOW STRENGTH": "ASPIRIN EC LOW STRENGTH",
              "ASPIRIN LOW DOSE": "ASPIRIN LOW DOSE",
              "SALSALATE": "SALSALATE"
            },
            "RISING PHARMACEUTICALS": {
              "DIFLUNISAL": "DIFLUNISAL"
            },
            "RITE AID CORPORATION": {
              "RA ASPIRIN": "RA ASPIRIN",
              "RA ASPIRIN ADULT LOW DOSE": "RA ASPIRIN ADULT LOW DOSE",
              "RA ASPIRIN ADULT LOW STRENGTH": "RA ASPIRIN ADULT LOW STRENGTH",
              "RA ASPIRIN CHILDRENS": "RA ASPIRIN CHILDRENS",
              "RA ASPIRIN EC": "RA ASPIRIN EC",
              "RA ASPIRIN EC ADULT LOW ST": "RA ASPIRIN EC ADULT LOW ST",
              "RA BACKACHE RELIEF": "RA BACKACHE RELIEF",
              "RA CHILDRENS ASPIRIN": "RA CHILDRENS ASPIRIN",
              "RA PAIN RELIEF ASPIRIN": "RA PAIN RELIEF ASPIRIN"
            },
            "RUGBY LABORATORIES": {
              "ASPIRIN": "ASPIRIN",
              "ASPIRIN ADULT": "ASPIRIN ADULT",
              "ASPIRIN EC": "ASPIRIN EC",
              "ASPIRIN LOW DOSE": "ASPIRIN LOW DOSE"
            },
            "RXCHANGE CO": {
              "ASPIRIN EC": "ASPIRIN EC",
              "SALSALATE": "SALSALATE"
            },
            "SAM'S WEST": {
              "ASPIRIN ADULT LOW STRENGTH": "ASPIRIN ADULT LOW STRENGTH"
            },
            "SELECT BRAND": {
              "SB ASPIRIN": "SB ASPIRIN",
              "SB ASPIRIN ADULT LOW STRENGTH": "SB ASPIRIN ADULT LOW STRENGTH",
              "SB ASPIRIN EC": "SB ASPIRIN EC",
              "SB BACKACHE EXTRA STRENGTH": "SB BACKACHE EXTRA STRENGTH",
              "SB CHILDRENS ASPIRIN": "SB CHILDRENS ASPIRIN",
              "SB LOW DOSE ASA EC": "SB LOW DOSE ASA EC"
            },
            "SHEFFIELD PHARMACEUTICALS": {
              "DEWITTS PAIN RELIEVER": "DEWITTS PAIN RELIEVER",
              "NORWICH ASPIRIN": "NORWICH ASPIRIN"
            },
            "SKY PACKAGING": {
              "ASPIRIN EC": "ASPIRIN EC",
              "ASPIRIN LOW STRENGTH": "ASPIRIN LOW STRENGTH"
            },
            "SPECTRUM": {
              "ASPIRIN": "ASPIRIN",
              "DIFLUNISAL": "DIFLUNISAL",
              "SODIUM SALICYLATE": "SODIUM SALICYLATE"
            },
            "ST MARY'S MPP": {
              "ASPIRIN": "ASPIRIN",
              "ASPIRIN EC": "ASPIRIN EC",
              "ASPIRIN EC LOW DOSE": "ASPIRIN EC LOW DOSE",
              "DIFLUNISAL": "DIFLUNISAL",
              "ECPIRIN": "ECPIRIN"
            },
            "SUN PHARMACEUTICALS": {
              "SALSALATE": "SALSALATE"
            },
            "SUPPOSITORIA": {
              "ASPIRIN": "ASPIRIN"
            },
            "TARGET": {
              "TGT ASPIRIN": "TGT ASPIRIN",
              "TGT ASPIRIN EC": "TGT ASPIRIN EC",
              "TGT ASPIRIN LOW DOSE": "TGT ASPIRIN LOW DOSE",
              "TGT CHILDRENS ASPIRIN": "TGT CHILDRENS ASPIRIN"
            },
            "TEVA PHARMACEUTICALS USA": {
              "DIFLUNISAL": "DIFLUNISAL"
            },
            "TIME-CAP LABS": {
              "ASPIRIN": "ASPIRIN",
              "ASPIRIN EC": "ASPIRIN EC",
              "ASPIRIN LOW DOSE": "ASPIRIN LOW DOSE",
              "MINIPRIN LOW DOSE": "MINIPRIN LOW DOSE"
            },
            "TOPCO": {
              "ASPIRIN": "ASPIRIN",
              "ASPIRIN ADULT LOW DOSE": "ASPIRIN ADULT LOW DOSE",
              "ASPIRIN ADULT LOW STRENGTH": "ASPIRIN ADULT LOW STRENGTH",
              "ASPIRIN EC": "ASPIRIN EC",
              "ASPIRIN EC LOW STRENGTH": "ASPIRIN EC LOW STRENGTH",
              "ASPIRIN LOW DOSE": "ASPIRIN LOW DOSE"
            },
            "TRIMARC LABORATORIES": {
              "ASPIRIN LOW DOSE": "ASPIRIN LOW DOSE",
              "EC-81 ASPIRIN": "EC-81 ASPIRIN"
            },
            "VA CMOP DALLAS": {
              "SALSALATE": "SALSALATE"
            },
            "VANGARD": {
              "ASPIRIN": "ASPIRIN",
              "ASPIRIN EC": "ASPIRIN EC"
            },
            "WAL-MART": {
              "EQ ADULT ASPIRIN LOW STRENGTH": "EQ ADULT ASPIRIN LOW STRENGTH",
              "EQ ASPIRIN": "EQ ASPIRIN",
              "EQ ASPIRIN ADULT LOW DOSE": "EQ ASPIRIN ADULT LOW DOSE",
              "EQ ASPIRIN LOW DOSE": "EQ ASPIRIN LOW DOSE",
              "EQ CHILDRENS ASPIRIN": "EQ CHILDRENS ASPIRIN",
              "MM ASPIRIN": "MM ASPIRIN"
            },
            "WALGREENS": {
              "ASPIRIN": "ASPIRIN",
              "ASPIRIN 81": "ASPIRIN 81",
              "ASPIRIN CHILDRENS": "ASPIRIN CHILDRENS",
              "ASPIRIN EC": "ASPIRIN EC",
              "ASPIRIN EC LOW DOSE": "ASPIRIN EC LOW DOSE",
              "ASPIRIN LOW DOSE": "ASPIRIN LOW DOSE",
              "BACKACHE RELIEF EXTRA STRENGTH": "BACKACHE RELIEF EXTRA STRENGTH"
            },
            "WESTMINSTER PHARMACEUTICALS": {
              "SALSALATE": "SALSALATE"
            }
          }
        }
      },
      "ANALGESICS - OPIOID": {
        "Opioid Agonists": {
          "Opioid Agonists": {
            "A-S MEDICATION SOLUTIONS": {
              "CONZIP": "CONZIP",
              "TRAMADOL HCL": "TRAMADOL HCL",
              "TRAMADOL HCL ER": "TRAMADOL HCL ER",
              "ULTRAM": "ULTRAM"
            },
            "ACELRX PHARMACEUTICALS": {
              "DSUVIA": "DSUVIA"
            },
            "ACTAVIS ELIZABETH": {
              "MORPHINE SULFATE ER": "MORPHINE SULFATE ER",
              "MORPHINE SULFATE ER BEADS": "MORPHINE SULFATE ER BEADS",
              "OXYCODONE HCL": "OXYCODONE HCL",
              "OXYMORPHONE HCL ER": "OXYMORPHONE HCL ER"
            },
            "ACTAVIS PHARMA": {
              "FENTANYL": "FENTANYL",
              "HYDROMORPHONE HCL ER": "HYDROMORPHONE HCL ER",
              "OXYCODONE HCL ER": "OXYCODONE HCL ER"
            },
            "AIDAREX PHARMACEUTICALS": {
              "TRAMADOL HCL": "TRAMADOL HCL",
              "TRAMADOL HCL ER": "TRAMADOL HCL ER"
            },
            "AKORN": {
              "ALFENTANIL HCL": "ALFENTANIL HCL",
              "FENTANYL CITRATE (PF)": "FENTANYL CITRATE (PF)",
              "HYDROMORPHONE HCL PF": "HYDROMORPHONE HCL PF",
              "METHADONE HCL": "METHADONE HCL",
              "OXYCODONE HCL": "OXYCODONE HCL",
              "SUBLIMAZE": "SUBLIMAZE",
              "SUFENTANIL CITRATE": "SUFENTANIL CITRATE"
            },
            "ALIVIO MEDICAL PRODUCTS": {
              "TRAMADOL HCL": "TRAMADOL HCL"
            },
            "ALLERGAN": {
              "KADIAN": "KADIAN"
            },
            "ALTURA PHARMACEUTICALS": {
              "FENTANYL": "FENTANYL",
              "METHADONE HCL": "METHADONE HCL",
              "MORPHINE SULFATE ER": "MORPHINE SULFATE ER",
              "OXYCODONE HCL": "OXYCODONE HCL",
              "TRAMADOL HCL": "TRAMADOL HCL"
            },
            "ALVOGEN": {
              "FENTANYL": "FENTANYL",
              "HYDROCODONE BITARTRATE ER": "HYDROCODONE BITARTRATE ER",
              "OXYCODONE HCL": "OXYCODONE HCL"
            },
            "AMERICAN HEALTH PACKAGING": {
              "HYDROMORPHONE HCL": "HYDROMORPHONE HCL",
              "METHADONE HCL": "METHADONE HCL",
              "METHADOSE": "METHADOSE",
              "MORPHINE SULFATE ER": "MORPHINE SULFATE ER",
              "OXYCODONE HCL": "OXYCODONE HCL",
              "OXYMORPHONE HCL": "OXYMORPHONE HCL",
              "TRAMADOL HCL": "TRAMADOL HCL",
              "TRAMADOL HCL ER": "TRAMADOL HCL ER"
            },
            "AMNEAL PHARMACEUTICALS": {
              "MORPHINE SULFATE ER": "MORPHINE SULFATE ER",
              "OXYCODONE HCL": "OXYCODONE HCL",
              "OXYCODONE HCL ER": "OXYCODONE HCL ER",
              "TRAMADOL HCL": "TRAMADOL HCL"
            },
            "AMNEAL SPECIALTY": {
              "OXYMORPHONE HCL ER": "OXYMORPHONE HCL ER"
            },
            "ANI  PHARMACEUTICALS": {
              "MORPHINE SULFATE": "MORPHINE SULFATE",
              "OXYCODONE HCL": "OXYCODONE HCL"
            },
            "APHENA PHARMA SOLUTIONS": {
              "TRAMADOL HCL": "TRAMADOL HCL"
            },
            "APOTEX": {
              "FENTANYL": "FENTANYL"
            },
            "APOTHECA": {
              "TRAMADOL HCL": "TRAMADOL HCL"
            },
            "ASCEND LABORATORIES": {
              "METHADONE HCL": "METHADONE HCL"
            },
            "ASSERTIO THERAPEUTICS": {
              "NUCYNTA ER": "NUCYNTA ER"
            },
            "ATHENA BIOSCIENCE": {
              "QDOLO": "QDOLO"
            },
            "ATLANTIC BIOLOGICALS": {
              "METHADOSE": "METHADOSE"
            },
            "AUROBINDO PHARMA USA": {
              "HYDROMORPHONE HCL": "HYDROMORPHONE HCL",
              "METHADONE HCL": "METHADONE HCL",
              "OXYCODONE HCL": "OXYCODONE HCL",
              "OXYMORPHONE HCL": "OXYMORPHONE HCL"
            },
            "B & B PHARMACEUTICALS": {
              "CODEINE PHOSPHATE": "CODEINE PHOSPHATE",
              "FENTANYL CITRATE": "FENTANYL CITRATE",
              "HYDROMORPHONE HCL": "HYDROMORPHONE HCL",
              "MEPERIDINE HCL": "MEPERIDINE HCL",
              "METHADONE HCL": "METHADONE HCL",
              "MORPHINE SULFATE": "MORPHINE SULFATE",
              "OXYCODONE HCL": "OXYCODONE HCL"
            },
            "BIOMES PHARMACEUTICALS": {
              "TRAMADOL HCL": "TRAMADOL HCL"
            },
            "BLENHEIM PHARMACAL": {
              "OXYCODONE HCL": "OXYCODONE HCL",
              "TRAMADOL HCL": "TRAMADOL HCL"
            },
            "BRYANT RANCH PREPACK": {
              "FENTANYL": "FENTANYL",
              "HYDROMORPHONE HCL": "HYDROMORPHONE HCL",
              "MEPERIDINE HCL": "MEPERIDINE HCL",
              "METHADONE HCL": "METHADONE HCL",
              "MORPHINE SULFATE": "MORPHINE SULFATE",
              "MORPHINE SULFATE ER": "MORPHINE SULFATE ER",
              "NUCYNTA": "NUCYNTA",
              "OXYCODONE HCL": "OXYCODONE HCL",
              "OXYCONTIN": "OXYCONTIN",
              "TRAMADOL HCL": "TRAMADOL HCL",
              "TRAMADOL HCL ER": "TRAMADOL HCL ER"
            },
            "CAMBER PHARMACEUTICALS": {
              "HYDROMORPHONE HCL ER": "HYDROMORPHONE HCL ER",
              "METHADONE HCL": "METHADONE HCL",
              "OXYCODONE HCL": "OXYCODONE HCL",
              "OXYMORPHONE HCL": "OXYMORPHONE HCL"
            },
            "CARDINAL HEALTH REPACKAGING SO": {
              "TRAMADOL HCL": "TRAMADOL HCL"
            },
            "CENTRAL ADMIXTURE PHARMACY SER": {
              "FENTANYL CITRATE-NACL": "FENTANYL CITRATE-NACL",
              "HYDROMORPHONE HCL-NACL": "HYDROMORPHONE HCL-NACL",
              "MORPHINE SULFATE-NACL": "MORPHINE SULFATE-NACL"
            },
            "CIVICA": {
              "FENTANYL CITRATE (PF)": "FENTANYL CITRATE (PF)",
              "MORPHINE SULFATE (PF)": "MORPHINE SULFATE (PF)"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "TRAMADOL HCL": "TRAMADOL HCL"
            },
            "COLLEGIUM PHARMACEUTICALS": {
              "NUCYNTA": "NUCYNTA",
              "NUCYNTA ER": "NUCYNTA ER",
              "XTAMPZA ER": "XTAMPZA ER"
            },
            "CURRAX PHARMACEUTICALS": {
              "ZOHYDRO ER": "ZOHYDRO ER"
            },
            "DIRECT RX": {
              "FENTANYL": "FENTANYL",
              "METHADONE HCL": "METHADONE HCL",
              "MORPHINE SULFATE": "MORPHINE SULFATE",
              "MORPHINE SULFATE ER": "MORPHINE SULFATE ER",
              "OXYCODONE HCL": "OXYCODONE HCL",
              "TRAMADOL HCL": "TRAMADOL HCL",
              "TRAMADOL HCL ER": "TRAMADOL HCL ER"
            },
            "ENDO GENERIC PRODUCTS": {
              "MORPHINE SULFATE ER": "MORPHINE SULFATE ER",
              "OXYMORPHONE HCL": "OXYMORPHONE HCL"
            },
            "ENDO PHARMACEUTICALS": {
              "OPANA": "OPANA",
              "OPANA ER": "OPANA ER"
            },
            "ENOVACHEM MANUFACTURING": {
              "TRAMADOL HCL": "TRAMADOL HCL"
            },
            "EPIC PHARMA": {
              "MEPERIDINE HCL": "MEPERIDINE HCL",
              "METHADONE HCL": "METHADONE HCL",
              "OXYCODONE HCL": "OXYCODONE HCL",
              "OXYMORPHONE HCL": "OXYMORPHONE HCL"
            },
            "EYWA PHARMA": {
              "OXYCODONE HCL": "OXYCODONE HCL"
            },
            "FAGRON": {
              "CODEINE PHOSPHATE": "CODEINE PHOSPHATE",
              "FENTANYL CITRATE": "FENTANYL CITRATE",
              "HYDROMORPHONE HCL": "HYDROMORPHONE HCL",
              "MEPERIDINE HCL": "MEPERIDINE HCL",
              "METHADONE HCL": "METHADONE HCL",
              "MORPHINE SULFATE": "MORPHINE SULFATE",
              "OXYCODONE HCL": "OXYCODONE HCL"
            },
            "FAGRON COMPOUNDING SERVICES": {
              "FENTANYL CITRATE": "FENTANYL CITRATE",
              "HYDROMORPHONE HCL": "HYDROMORPHONE HCL",
              "MORPHINE SULFATE": "MORPHINE SULFATE"
            },
            "FREEDOM PHARMACEUTICALS": {
              "FENTANYL CITRATE": "FENTANYL CITRATE",
              "HYDROMORPHONE HCL": "HYDROMORPHONE HCL",
              "METHADONE HCL": "METHADONE HCL",
              "MORPHINE SULFATE": "MORPHINE SULFATE",
              "OXYCODONE HCL": "OXYCODONE HCL"
            },
            "FRESENIUS KABI USA": {
              "DILAUDID": "DILAUDID",
              "FENTANYL CITRATE (PF)": "FENTANYL CITRATE (PF)",
              "HYDROMORPHONE HCL PF": "HYDROMORPHONE HCL PF",
              "MORPHINE SULFATE": "MORPHINE SULFATE",
              "MORPHINE SULFATE (PF)": "MORPHINE SULFATE (PF)",
              "REMIFENTANIL HCL": "REMIFENTANIL HCL"
            },
            "FUSION PHARMACEUTICALS": {
              "SYNAPRYN FUSEPAQ": "SYNAPRYN FUSEPAQ"
            },
            "GENUS LIFESCIENCES": {
              "OXYCODONE HCL": "OXYCODONE HCL"
            },
            "GLENMARK PHARMACEUTICALS": {
              "METHADONE HCL": "METHADONE HCL",
              "OXYCODONE HCL": "OXYCODONE HCL"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "MEPERIDINE HCL": "MEPERIDINE HCL",
              "OXYCODONE HCL": "OXYCODONE HCL",
              "TRAMADOL HCL": "TRAMADOL HCL",
              "TRAMADOL HCL ER (BIPHASIC)": "TRAMADOL HCL ER (BIPHASIC)"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "FENTANYL": "FENTANYL",
              "FENTANYL CITRATE": "FENTANYL CITRATE",
              "HYDROMORPHONE HCL": "HYDROMORPHONE HCL",
              "METHADONE HCL": "METHADONE HCL",
              "MORPHINE SULFATE": "MORPHINE SULFATE",
              "MORPHINE SULFATE ER": "MORPHINE SULFATE ER",
              "OXYCODONE HCL": "OXYCODONE HCL",
              "OXYCONTIN": "OXYCONTIN",
              "TRAMADOL HCL": "TRAMADOL HCL",
              "ULTRAM": "ULTRAM"
            },
            "HI-TECH": {
              "MORPHINE SULFATE (CONCENTRATE)": "MORPHINE SULFATE (CONCENTRATE)",
              "OXYCODONE HCL": "OXYCODONE HCL"
            },
            "HIKMA": {
              "CODEINE SULFATE": "CODEINE SULFATE",
              "DOLOPHINE": "DOLOPHINE",
              "DURAMORPH": "DURAMORPH",
              "FENTANYL CITRATE (PF)": "FENTANYL CITRATE (PF)",
              "HYDROMORPHONE HCL": "HYDROMORPHONE HCL",
              "INFUMORPH 200": "INFUMORPH 200",
              "INFUMORPH 500": "INFUMORPH 500",
              "MEPERIDINE HCL": "MEPERIDINE HCL",
              "METHADONE HCL": "METHADONE HCL",
              "METHADONE HCL INTENSOL": "METHADONE HCL INTENSOL",
              "MORPHINE SULFATE": "MORPHINE SULFATE",
              "MORPHINE SULFATE (CONCENTRATE)": "MORPHINE SULFATE (CONCENTRATE)",
              "MORPHINE SULFATE (PF)": "MORPHINE SULFATE (PF)",
              "OXYCODONE HCL": "OXYCODONE HCL",
              "OXYMORPHONE HCL": "OXYMORPHONE HCL",
              "SUFENTANIL CITRATE": "SUFENTANIL CITRATE"
            },
            "HOSPIRA": {
              "ALFENTANIL HCL": "ALFENTANIL HCL",
              "DEMEROL": "DEMEROL",
              "FENTANYL CITRATE (PF)": "FENTANYL CITRATE (PF)",
              "HYDROMORPHONE HCL": "HYDROMORPHONE HCL",
              "HYDROMORPHONE HCL PF": "HYDROMORPHONE HCL PF",
              "MEPERIDINE HCL": "MEPERIDINE HCL",
              "MORPHINE SULFATE": "MORPHINE SULFATE",
              "MORPHINE SULFATE (PF)": "MORPHINE SULFATE (PF)",
              "SUFENTANIL CITRATE": "SUFENTANIL CITRATE"
            },
            "IMPAX GENERICS": {
              "OXYCODONE HCL": "OXYCODONE HCL",
              "OXYMORPHONE HCL": "OXYMORPHONE HCL",
              "OXYMORPHONE HCL ER": "OXYMORPHONE HCL ER"
            },
            "INTEGRADOSE COMPOUNDING SERVIC": {
              "FENTANYL CITRATE": "FENTANYL CITRATE",
              "HYDROMORPHONE HCL-NACL": "HYDROMORPHONE HCL-NACL"
            },
            "INTRNTL MEDICATION SYSTEM": {
              "MORPHINE SULFATE": "MORPHINE SULFATE"
            },
            "JANSSEN": {
              "DURAGESIC-100": "DURAGESIC-100",
              "DURAGESIC-12": "DURAGESIC-12",
              "DURAGESIC-25": "DURAGESIC-25",
              "DURAGESIC-50": "DURAGESIC-50",
              "DURAGESIC-75": "DURAGESIC-75",
              "ULTRAM": "ULTRAM",
              "ULTRAM ER": "ULTRAM ER"
            },
            "KLE 2": {
              "TRAMADOL HCL ER": "TRAMADOL HCL ER"
            },
            "KVK TECH": {
              "OXYCODONE HCL": "OXYCODONE HCL",
              "OXYCODONE HCL ER": "OXYCODONE HCL ER",
              "OXYMORPHONE HCL": "OXYMORPHONE HCL"
            },
            "LANNETT": {
              "CODEINE SULFATE": "CODEINE SULFATE",
              "HYDROMORPHONE HCL": "HYDROMORPHONE HCL",
              "LEVORPHANOL TARTRATE": "LEVORPHANOL TARTRATE",
              "MORPHINE SULFATE (CONCENTRATE)": "MORPHINE SULFATE (CONCENTRATE)",
              "OXYCODONE HCL": "OXYCODONE HCL"
            },
            "LEITERS HEALTH": {
              "FENTANYL CITRATE": "FENTANYL CITRATE",
              "HYDROMORPHONE HCL-NACL": "HYDROMORPHONE HCL-NACL",
              "MORPHINE SULFATE-NACL": "MORPHINE SULFATE-NACL"
            },
            "LETCO MEDICAL": {
              "FENTANYL CITRATE": "FENTANYL CITRATE",
              "HYDROMORPHONE HCL": "HYDROMORPHONE HCL",
              "MEPERIDINE HCL": "MEPERIDINE HCL",
              "METHADONE HCL": "METHADONE HCL",
              "MORPHINE SULFATE": "MORPHINE SULFATE"
            },
            "LIBERTY PHARMACEUTICAL": {
              "TRAMADOL HCL": "TRAMADOL HCL"
            },
            "LOHXA": {
              "HYDROMORPHONE HCL": "HYDROMORPHONE HCL",
              "METHADONE HCL": "METHADONE HCL",
              "MORPHINE SULFATE (CONCENTRATE)": "MORPHINE SULFATE (CONCENTRATE)"
            },
            "LUPIN PHARMACEUTICALS": {
              "MORPHINE SULFATE ER": "MORPHINE SULFATE ER",
              "OXYCODONE HCL": "OXYCODONE HCL",
              "TRAMADOL HCL ER": "TRAMADOL HCL ER"
            },
            "MACLEODS PHARMACEUTICALS": {
              "TRAMADOL HCL": "TRAMADOL HCL"
            },
            "MACOVEN PHARMACEUTICALS": {
              "HYDROCODONE BITARTRATE ER": "HYDROCODONE BITARTRATE ER"
            },
            "MAJOR PHARMACEUTICALS": {
              "METHADONE HCL": "METHADONE HCL",
              "MORPHINE SULFATE ER": "MORPHINE SULFATE ER",
              "OXYCODONE HCL": "OXYCODONE HCL",
              "TRAMADOL HCL": "TRAMADOL HCL"
            },
            "MALLINCKRODT PHARM": {
              "CODEINE PHOSPHATE": "CODEINE PHOSPHATE",
              "FENTANYL": "FENTANYL",
              "FENTANYL CITRATE": "FENTANYL CITRATE",
              "HYDROMORPHONE HCL": "HYDROMORPHONE HCL",
              "HYDROMORPHONE HCL ER": "HYDROMORPHONE HCL ER",
              "MEPERIDINE HCL": "MEPERIDINE HCL",
              "METHADONE HCL": "METHADONE HCL",
              "METHADOSE": "METHADOSE",
              "METHADOSE SUGAR-FREE": "METHADOSE SUGAR-FREE",
              "MORPHINE SULFATE": "MORPHINE SULFATE",
              "MORPHINE SULFATE (CONCENTRATE)": "MORPHINE SULFATE (CONCENTRATE)",
              "MORPHINE SULFATE ER": "MORPHINE SULFATE ER",
              "OXYCODONE HCL": "OXYCODONE HCL",
              "OXYMORPHONE HCL": "OXYMORPHONE HCL"
            },
            "MAYNE PHARMA": {
              "FENTANYL": "FENTANYL",
              "FENTANYL CITRATE": "FENTANYL CITRATE",
              "MORPHINE SULFATE ER": "MORPHINE SULFATE ER",
              "OXYCODONE HCL": "OXYCODONE HCL"
            },
            "MEDISCA": {
              "CODEINE PHOSPHATE": "CODEINE PHOSPHATE",
              "FENTANYL CITRATE": "FENTANYL CITRATE",
              "HYDROMORPHONE HCL": "HYDROMORPHONE HCL",
              "MEPERIDINE HCL": "MEPERIDINE HCL",
              "METHADONE HCL": "METHADONE HCL",
              "MORPHINE SULFATE": "MORPHINE SULFATE",
              "OXYCODONE HCL": "OXYCODONE HCL"
            },
            "MEDVANTX": {
              "ULTRAM": "ULTRAM"
            },
            "MERIDIAN MEDICAL TECHNOLOGIES": {
              "MORPHINE SULFATE": "MORPHINE SULFATE"
            },
            "MORTON GROVE PHARMACEUTICALS": {
              "OXYCODONE HCL": "OXYCODONE HCL"
            },
            "MYLAN": {
              "FENTANYL": "FENTANYL",
              "MORPHINE SULFATE ER": "MORPHINE SULFATE ER",
              "OXYCODONE HCL": "OXYCODONE HCL",
              "TRAMADOL HCL": "TRAMADOL HCL",
              "TRAMADOL HCL ER": "TRAMADOL HCL ER"
            },
            "MYLAN INSTITUTIONAL": {
              "METHADONE HCL": "METHADONE HCL",
              "TRAMADOL HCL": "TRAMADOL HCL",
              "ULTIVA": "ULTIVA"
            },
            "NEPHRON STERILE COMPOUNDING": {
              "FENTANYL CITRATE": "FENTANYL CITRATE",
              "FENTANYL CITRATE-NACL": "FENTANYL CITRATE-NACL",
              "HYDROMORPHONE HCL": "HYDROMORPHONE HCL",
              "MORPHINE SULFATE": "MORPHINE SULFATE"
            },
            "NORTHSTAR RX": {
              "TRAMADOL HCL": "TRAMADOL HCL"
            },
            "NOSTRUM LABORATORIES": {
              "MORPHINE SULFATE": "MORPHINE SULFATE"
            },
            "NOVAPLUS/APOTEX USA": {
              "FENTANYL": "FENTANYL"
            },
            "NOVAPLUS/MYLAN INSTITUTIONAL": {
              "ULTIVA": "ULTIVA"
            },
            "NUCARE PHARMACEUTICALS": {
              "FENTANYL": "FENTANYL",
              "KADIAN": "KADIAN",
              "MEPERIDINE HCL": "MEPERIDINE HCL",
              "MORPHINE SULFATE": "MORPHINE SULFATE",
              "MORPHINE SULFATE ER": "MORPHINE SULFATE ER",
              "NUCYNTA": "NUCYNTA",
              "OXYCODONE HCL": "OXYCODONE HCL",
              "OXYCONTIN": "OXYCONTIN",
              "TRAMADOL HCL": "TRAMADOL HCL",
              "TRAMADOL HCL ER": "TRAMADOL HCL ER",
              "ULTRAM": "ULTRAM",
              "ULTRAM ER": "ULTRAM ER"
            },
            "PAR PHARMACEUTICAL": {
              "FENTANYL CITRATE": "FENTANYL CITRATE",
              "MORPHINE SULFATE ER": "MORPHINE SULFATE ER",
              "OXYCODONE HCL ER": "OXYCODONE HCL ER",
              "TRAMADOL HCL ER": "TRAMADOL HCL ER",
              "TRAMADOL HCL ER (BIPHASIC)": "TRAMADOL HCL ER (BIPHASIC)"
            },
            "PAR PHARMACEUTICALS": {
              "MEPERIDINE HCL": "MEPERIDINE HCL",
              "OXYCODONE HCL": "OXYCODONE HCL"
            },
            "PATRIOT PHARMACEUTICALS LLC": {
              "TRAMADOL HCL ER": "TRAMADOL HCL ER"
            },
            "PCCA": {
              "CODEINE PHOSPHATE": "CODEINE PHOSPHATE",
              "FENTANYL CITRATE": "FENTANYL CITRATE",
              "HYDROMORPHONE HCL": "HYDROMORPHONE HCL",
              "MEPERIDINE HCL": "MEPERIDINE HCL",
              "METHADONE HCL": "METHADONE HCL",
              "MORPHINE SULFATE": "MORPHINE SULFATE",
              "OXYCODONE HCL": "OXYCODONE HCL"
            },
            "PDRX PHARMACEUTICAL": {
              "HYDROMORPHONE HCL": "HYDROMORPHONE HCL",
              "METHADONE HCL": "METHADONE HCL",
              "MORPHINE SULFATE": "MORPHINE SULFATE",
              "MORPHINE SULFATE ER": "MORPHINE SULFATE ER",
              "OXYCODONE HCL": "OXYCODONE HCL",
              "OXYCONTIN": "OXYCONTIN",
              "TRAMADOL HCL": "TRAMADOL HCL",
              "ULTRAM": "ULTRAM"
            },
            "PENTEC HEALTH": {
              "HYDROMORPHONE HCL": "HYDROMORPHONE HCL"
            },
            "PERRIGO": {
              "HYDROMORPHONE HCL": "HYDROMORPHONE HCL",
              "HYDROMORPHONE HCL ER": "HYDROMORPHONE HCL ER",
              "MORPHINE SULFATE": "MORPHINE SULFATE",
              "MORPHINE SULFATE (CONCENTRATE)": "MORPHINE SULFATE (CONCENTRATE)"
            },
            "PFIZER U.S.": {
              "EMBEDA": "EMBEDA"
            },
            "PHARMACEUTICAL ASSOCIATES": {
              "MORPHINE SULFATE (CONCENTRATE)": "MORPHINE SULFATE (CONCENTRATE)",
              "OXYCODONE HCL": "OXYCODONE HCL"
            },
            "PHARMEDIUM SERVICES": {
              "FENTANYL CITRATE": "FENTANYL CITRATE",
              "FENTANYL CITRATE-NACL": "FENTANYL CITRATE-NACL",
              "HYDROMORPHONE HCL": "HYDROMORPHONE HCL",
              "HYDROMORPHONE HCL-NACL": "HYDROMORPHONE HCL-NACL",
              "MEPERIDINE HCL-SODIUM CHLORIDE": "MEPERIDINE HCL-SODIUM CHLORIDE",
              "MORPHINE SULFATE IN DEXTROSE": "MORPHINE SULFATE IN DEXTROSE",
              "MORPHINE SULFATE-NACL": "MORPHINE SULFATE-NACL",
              "REMIFENTANIL HCL-NACL": "REMIFENTANIL HCL-NACL"
            },
            "PHARMEDIX": {
              "TRAMADOL HCL": "TRAMADOL HCL"
            },
            "PIRAMAL CRITICAL CARE": {
              "MITIGO": "MITIGO"
            },
            "PRECISION DOSE, INC": {
              "HYDROMORPHONE HCL": "HYDROMORPHONE HCL",
              "MORPHINE SULFATE": "MORPHINE SULFATE",
              "MORPHINE SULFATE (CONCENTRATE)": "MORPHINE SULFATE (CONCENTRATE)",
              "OXYCODONE HCL": "OXYCODONE HCL",
              "TRAMADOL HCL": "TRAMADOL HCL"
            },
            "PREFERRED PHARMACEUTICALS": {
              "TRAMADOL HCL": "TRAMADOL HCL",
              "TRAMADOL HCL ER": "TRAMADOL HCL ER"
            },
            "PROFICIENT RX": {
              "TRAMADOL HCL": "TRAMADOL HCL",
              "TRAMADOL HCL ER": "TRAMADOL HCL ER"
            },
            "PURDUE PHARMA L.P.": {
              "DILAUDID": "DILAUDID",
              "DILAUDID-HP": "DILAUDID-HP",
              "HYSINGLA ER": "HYSINGLA ER",
              "MS CONTIN": "MS CONTIN",
              "OXYCONTIN": "OXYCONTIN"
            },
            "QUALITY CARE": {
              "ACTIQ": "ACTIQ",
              "DEMEROL": "DEMEROL",
              "DILAUDID": "DILAUDID",
              "EXALGO": "EXALGO",
              "FENTANYL": "FENTANYL",
              "FENTANYL CITRATE": "FENTANYL CITRATE",
              "FENTORA": "FENTORA",
              "HYDROMORPHONE HCL": "HYDROMORPHONE HCL",
              "KADIAN": "KADIAN",
              "MEPERIDINE HCL": "MEPERIDINE HCL",
              "METHADONE HCL": "METHADONE HCL",
              "METHADOSE": "METHADOSE",
              "MORPHINE SULFATE": "MORPHINE SULFATE",
              "MORPHINE SULFATE ER": "MORPHINE SULFATE ER",
              "MS CONTIN": "MS CONTIN",
              "NUCYNTA": "NUCYNTA",
              "NUCYNTA ER": "NUCYNTA ER",
              "OPANA": "OPANA",
              "OPANA ER": "OPANA ER",
              "OXYCODONE HCL": "OXYCODONE HCL",
              "OXYCONTIN": "OXYCONTIN",
              "OXYMORPHONE HCL": "OXYMORPHONE HCL",
              "OXYMORPHONE HCL ER": "OXYMORPHONE HCL ER",
              "ROXICODONE": "ROXICODONE",
              "TRAMADOL HCL": "TRAMADOL HCL",
              "TRAMADOL HCL ER": "TRAMADOL HCL ER",
              "ULTRAM": "ULTRAM",
              "ULTRAM ER": "ULTRAM ER"
            },
            "QUALITY CARE PRODUCTS": {
              "FENTANYL": "FENTANYL",
              "HYDROMORPHONE HCL ER": "HYDROMORPHONE HCL ER",
              "KADIAN": "KADIAN",
              "METHADONE HCL": "METHADONE HCL",
              "MORPHINE SULFATE ER": "MORPHINE SULFATE ER",
              "NUCYNTA": "NUCYNTA",
              "NUCYNTA ER": "NUCYNTA ER",
              "OXAYDO": "OXAYDO",
              "OXYCODONE HCL": "OXYCODONE HCL",
              "OXYCODONE HCL ER": "OXYCODONE HCL ER",
              "OXYMORPHONE HCL ER": "OXYMORPHONE HCL ER",
              "TRAMADOL HCL": "TRAMADOL HCL",
              "TRAMADOL HCL ER": "TRAMADOL HCL ER",
              "XTAMPZA ER": "XTAMPZA ER"
            },
            "QUVA PHARMA": {
              "FENTANYL CITRATE": "FENTANYL CITRATE",
              "FENTANYL CITRATE-NACL": "FENTANYL CITRATE-NACL",
              "HYDROMORPHONE HCL-NACL": "HYDROMORPHONE HCL-NACL",
              "METHADONE HCL-NACL": "METHADONE HCL-NACL",
              "MORPHINE SULFATE": "MORPHINE SULFATE",
              "MORPHINE SULFATE-NACL": "MORPHINE SULFATE-NACL"
            },
            "READYMEDS": {
              "TRAMADOL HCL": "TRAMADOL HCL"
            },
            "RHODES PHARMACEUTICAL": {
              "DILAUDID": "DILAUDID",
              "HYDROMORPHONE HCL": "HYDROMORPHONE HCL",
              "MORPHINE SULFATE ER": "MORPHINE SULFATE ER",
              "MS CONTIN": "MS CONTIN",
              "OXYCODONE HCL": "OXYCODONE HCL"
            },
            "RXCHANGE CO": {
              "HYDROMORPHONE HCL": "HYDROMORPHONE HCL",
              "OXYCODONE HCL": "OXYCODONE HCL",
              "TRAMADOL HCL": "TRAMADOL HCL",
              "TRAMADOL HCL ER": "TRAMADOL HCL ER"
            },
            "SA3": {
              "TRAMADOL HCL ER": "TRAMADOL HCL ER"
            },
            "SANDOZ": {
              "FENTANYL": "FENTANYL",
              "OXYCODONE HCL ER": "OXYCODONE HCL ER"
            },
            "SANKYO": {
              "MORPHABOND ER": "MORPHABOND ER"
            },
            "SANOFI-AVENTIS U.S.": {
              "DEMEROL": "DEMEROL"
            },
            "SCA PHARMACEUTICALS": {
              "FENTANYL CITRATE": "FENTANYL CITRATE",
              "FENTANYL CITRATE-NACL": "FENTANYL CITRATE-NACL",
              "HYDROMORPHONE HCL-NACL": "HYDROMORPHONE HCL-NACL",
              "MORPHINE SULFATE-NACL": "MORPHINE SULFATE-NACL"
            },
            "SENTYNL THERAPEUTICS": {
              "ABSTRAL": "ABSTRAL",
              "LEVORPHANOL TARTRATE": "LEVORPHANOL TARTRATE"
            },
            "SKY PACKAGING": {
              "HYDROMORPHONE HCL": "HYDROMORPHONE HCL",
              "METHADONE HCL": "METHADONE HCL",
              "MORPHINE SULFATE ER": "MORPHINE SULFATE ER",
              "OXYCODONE HCL": "OXYCODONE HCL",
              "TRAMADOL HCL": "TRAMADOL HCL"
            },
            "SPECGX": {
              "EXALGO": "EXALGO",
              "FENTANYL": "FENTANYL",
              "LEVORPHANOL TARTRATE": "LEVORPHANOL TARTRATE",
              "ROXICODONE": "ROXICODONE"
            },
            "SPECTRUM": {
              "CODEINE PHOSPHATE": "CODEINE PHOSPHATE",
              "FENTANYL CITRATE": "FENTANYL CITRATE",
              "HYDROMORPHONE HCL": "HYDROMORPHONE HCL",
              "MEPERIDINE HCL": "MEPERIDINE HCL",
              "METHADONE HCL": "METHADONE HCL",
              "MORPHINE SULFATE": "MORPHINE SULFATE",
              "OXYCODONE HCL": "OXYCODONE HCL"
            },
            "ST MARY'S MPP": {
              "FENTANYL": "FENTANYL",
              "KADIAN": "KADIAN",
              "MORPHINE SULFATE ER": "MORPHINE SULFATE ER",
              "OXYCODONE HCL": "OXYCODONE HCL",
              "OXYCONTIN": "OXYCONTIN",
              "TRAMADOL HCL": "TRAMADOL HCL",
              "ULTRAM ER": "ULTRAM ER"
            },
            "STA3": {
              "TRAMADOL HCL ER": "TRAMADOL HCL ER"
            },
            "SUN PHARMACEUTICALS": {
              "MORPHINE SULFATE": "MORPHINE SULFATE",
              "MORPHINE SULFATE ER": "MORPHINE SULFATE ER",
              "OXYCODONE HCL": "OXYCODONE HCL",
              "OXYCODONE HCL ER": "OXYCODONE HCL ER",
              "TRAMADOL HCL": "TRAMADOL HCL",
              "TRAMADOL HCL ER": "TRAMADOL HCL ER",
              "TRAMADOL HCL ER (BIPHASIC)": "TRAMADOL HCL ER (BIPHASIC)"
            },
            "TAGI PHARMA": {
              "HYDROMORPHONE HCL": "HYDROMORPHONE HCL"
            },
            "TEVA PARENTERAL MEDICINES": {
              "HYDROMORPHONE HCL PF": "HYDROMORPHONE HCL PF"
            },
            "TEVA PHARMACEUTICALS USA": {
              "ACTIQ": "ACTIQ",
              "FENTANYL CITRATE": "FENTANYL CITRATE",
              "FENTORA": "FENTORA",
              "MEPERIDINE HCL": "MEPERIDINE HCL",
              "OXYCODONE HCL ER": "OXYCODONE HCL ER",
              "OXYMORPHONE HCL": "OXYMORPHONE HCL",
              "TRAMADOL HCL": "TRAMADOL HCL"
            },
            "THE MEDICINES COMPANY": {
              "IONSYS": "IONSYS"
            },
            "TRIGEN LABORATORIES": {
              "HYDROMORPHONE HCL ER": "HYDROMORPHONE HCL ER",
              "TRAMADOL HCL ER": "TRAMADOL HCL ER"
            },
            "TRIS PHARMA": {
              "MORPHINE SULFATE (CONCENTRATE)": "MORPHINE SULFATE (CONCENTRATE)"
            },
            "TRUPHARMA": {
              "TRAMADOL HCL": "TRAMADOL HCL"
            },
            "UNICHEM PHARMACEUTICALS": {
              "TRAMADOL HCL": "TRAMADOL HCL"
            },
            "UNIT DOSE SERVICES": {
              "OXYCODONE HCL": "OXYCODONE HCL",
              "TRAMADOL HCL": "TRAMADOL HCL",
              "TRAMADOL HCL ER": "TRAMADOL HCL ER",
              "TRAMADOL HCL ER (BIPHASIC)": "TRAMADOL HCL ER (BIPHASIC)"
            },
            "UPSHER-SMITH": {
              "MORPHINE SULFATE": "MORPHINE SULFATE",
              "MORPHINE SULFATE ER": "MORPHINE SULFATE ER"
            },
            "VA CMOP DALLAS": {
              "TRAMADOL HCL": "TRAMADOL HCL"
            },
            "VALIDUS PHARMACEUTICALS": {
              "DEMEROL": "DEMEROL"
            },
            "VERTICAL PHARMACEUTICAL": {
              "CONZIP": "CONZIP"
            },
            "VIRTUS PHARMACEUTICALS": {
              "LEVORPHANOL TARTRATE": "LEVORPHANOL TARTRATE",
              "TRAMADOL HCL": "TRAMADOL HCL"
            },
            "VISTAPHARM INC.": {
              "METHADONE HCL": "METHADONE HCL",
              "MORPHINE SULFATE": "MORPHINE SULFATE",
              "OXYCODONE HCL": "OXYCODONE HCL"
            },
            "WEST THERAPEUTICS DEVELOPMENT": {
              "LAZANDA": "LAZANDA",
              "SUBSYS": "SUBSYS"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "MORPHINE SULFATE ER": "MORPHINE SULFATE ER",
              "OXYCODONE HCL": "OXYCODONE HCL",
              "TRAMADOL HCL": "TRAMADOL HCL"
            },
            "ZYLA LIFE SCIENCES": {
              "ARYMO ER": "ARYMO ER",
              "OXAYDO": "OXAYDO"
            }
          }
        },
        "Opioid Combinations": {
          "Codeine Combinations": {
            "A-S MEDICATION SOLUTIONS": {
              "ACETAMINOPHEN-CODEINE": "ACETAMINOPHEN-CODEINE",
              "ACETAMINOPHEN-CODEINE #2": "ACETAMINOPHEN-CODEINE #2",
              "ACETAMINOPHEN-CODEINE #3": "ACETAMINOPHEN-CODEINE #3",
              "ACETAMINOPHEN-CODEINE #4": "ACETAMINOPHEN-CODEINE #4",
              "TYLENOL WITH CODEINE #3": "TYLENOL WITH CODEINE #3"
            },
            "ACTAVIS PHARMA": {
              "BUTALBITAL-APAP-CAFF-COD": "BUTALBITAL-APAP-CAFF-COD",
              "BUTALBITAL-ASA-CAFF-CODEINE": "BUTALBITAL-ASA-CAFF-CODEINE"
            },
            "AIDAREX PHARMACEUTICALS": {
              "ACETAMINOPHEN-CODEINE": "ACETAMINOPHEN-CODEINE",
              "ACETAMINOPHEN-CODEINE #3": "ACETAMINOPHEN-CODEINE #3",
              "ACETAMINOPHEN-CODEINE #4": "ACETAMINOPHEN-CODEINE #4",
              "ASCOMP-CODEINE": "ASCOMP-CODEINE"
            },
            "ALLERGAN": {
              "FIORINAL/CODEINE #3": "FIORINAL/CODEINE #3"
            },
            "ALTURA PHARMACEUTICALS": {
              "ACETAMINOPHEN-CODEINE": "ACETAMINOPHEN-CODEINE",
              "ACETAMINOPHEN-CODEINE #2": "ACETAMINOPHEN-CODEINE #2",
              "ACETAMINOPHEN-CODEINE #3": "ACETAMINOPHEN-CODEINE #3",
              "ACETAMINOPHEN-CODEINE #4": "ACETAMINOPHEN-CODEINE #4",
              "BUTALBITAL-ASA-CAFF-CODEINE": "BUTALBITAL-ASA-CAFF-CODEINE"
            },
            "AMERICAN HEALTH PACKAGING": {
              "ACETAMINOPHEN-CODEINE #3": "ACETAMINOPHEN-CODEINE #3",
              "ACETAMINOPHEN-CODEINE #4": "ACETAMINOPHEN-CODEINE #4"
            },
            "AMNEAL PHARMACEUTICALS": {
              "ACETAMINOPHEN-CODEINE #3": "ACETAMINOPHEN-CODEINE #3",
              "BUTALBITAL-ASA-CAFF-CODEINE": "BUTALBITAL-ASA-CAFF-CODEINE"
            },
            "APHENA PHARMA SOLUTIONS": {
              "ACETAMINOPHEN-CODEINE #3": "ACETAMINOPHEN-CODEINE #3",
              "ACETAMINOPHEN-CODEINE #4": "ACETAMINOPHEN-CODEINE #4"
            },
            "APOTHECA": {
              "ACETAMINOPHEN-CODEINE #3": "ACETAMINOPHEN-CODEINE #3"
            },
            "ATLANTIC BIOLOGICALS": {
              "ACETAMINOPHEN-CODEINE": "ACETAMINOPHEN-CODEINE"
            },
            "AUROBINDO PHARMA USA": {
              "ACETAMINOPHEN-CODEINE": "ACETAMINOPHEN-CODEINE",
              "ACETAMINOPHEN-CODEINE #3": "ACETAMINOPHEN-CODEINE #3"
            },
            "BAUSCH HEALTH": {
              "CAPITAL/CODEINE": "CAPITAL/CODEINE"
            },
            "BLENHEIM PHARMACAL": {
              "ACETAMINOPHEN-CODEINE #3": "ACETAMINOPHEN-CODEINE #3"
            },
            "BRECKENRIDGE": {
              "ASCOMP-CODEINE": "ASCOMP-CODEINE",
              "BUTALBITAL-APAP-CAFF-COD": "BUTALBITAL-APAP-CAFF-COD"
            },
            "BRYANT RANCH PREPACK": {
              "ACETAMINOPHEN-CODEINE": "ACETAMINOPHEN-CODEINE",
              "ACETAMINOPHEN-CODEINE #2": "ACETAMINOPHEN-CODEINE #2",
              "ACETAMINOPHEN-CODEINE #3": "ACETAMINOPHEN-CODEINE #3",
              "ACETAMINOPHEN-CODEINE #4": "ACETAMINOPHEN-CODEINE #4",
              "ASCOMP-CODEINE": "ASCOMP-CODEINE",
              "BUTALBITAL-APAP-CAFF-COD": "BUTALBITAL-APAP-CAFF-COD"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "ACETAMINOPHEN-CODEINE #3": "ACETAMINOPHEN-CODEINE #3"
            },
            "DIRECT RX": {
              "ACETAMINOPHEN-CODEINE": "ACETAMINOPHEN-CODEINE",
              "ACETAMINOPHEN-CODEINE #3": "ACETAMINOPHEN-CODEINE #3",
              "ACETAMINOPHEN-CODEINE #4": "ACETAMINOPHEN-CODEINE #4"
            },
            "EYWA PHARMA": {
              "ACETAMINOPHEN-CODEINE": "ACETAMINOPHEN-CODEINE"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "ACETAMINOPHEN-CODEINE": "ACETAMINOPHEN-CODEINE",
              "ACETAMINOPHEN-CODEINE #2": "ACETAMINOPHEN-CODEINE #2",
              "ACETAMINOPHEN-CODEINE #3": "ACETAMINOPHEN-CODEINE #3",
              "ACETAMINOPHEN-CODEINE #4": "ACETAMINOPHEN-CODEINE #4",
              "BUTALBITAL-APAP-CAFF-COD": "BUTALBITAL-APAP-CAFF-COD",
              "BUTALBITAL-ASA-CAFF-CODEINE": "BUTALBITAL-ASA-CAFF-CODEINE"
            },
            "HI-TECH": {
              "ACETAMINOPHEN-CODEINE": "ACETAMINOPHEN-CODEINE"
            },
            "IMPAX GENERICS": {
              "ACETAMINOPHEN-CODEINE #3": "ACETAMINOPHEN-CODEINE #3",
              "ACETAMINOPHEN-CODEINE #4": "ACETAMINOPHEN-CODEINE #4"
            },
            "JANSSEN": {
              "TYLENOL WITH CODEINE #3": "TYLENOL WITH CODEINE #3",
              "TYLENOL WITH CODEINE #4": "TYLENOL WITH CODEINE #4"
            },
            "LANNETT": {
              "BUTALBITAL-ASA-CAFF-CODEINE": "BUTALBITAL-ASA-CAFF-CODEINE"
            },
            "LIBERTY PHARMACEUTICAL": {
              "ACETAMINOPHEN-CODEINE #3": "ACETAMINOPHEN-CODEINE #3",
              "BUTALBITAL-APAP-CAFF-COD": "BUTALBITAL-APAP-CAFF-COD"
            },
            "MALLINCKRODT PHARM": {
              "ACETAMINOPHEN-CODEINE #2": "ACETAMINOPHEN-CODEINE #2",
              "ACETAMINOPHEN-CODEINE #3": "ACETAMINOPHEN-CODEINE #3",
              "ACETAMINOPHEN-CODEINE #4": "ACETAMINOPHEN-CODEINE #4"
            },
            "MAYNE PHARMA": {
              "BUTALBITAL-ASA-CAFF-CODEINE": "BUTALBITAL-ASA-CAFF-CODEINE"
            },
            "MEDVANTX": {
              "ACETAMINOPHEN-CODEINE #3": "ACETAMINOPHEN-CODEINE #3"
            },
            "MORTON GROVE PHARMACEUTICALS": {
              "ACETAMINOPHEN-CODEINE": "ACETAMINOPHEN-CODEINE"
            },
            "MYLAN INSTITUTIONAL": {
              "ACETAMINOPHEN-CODEINE #3": "ACETAMINOPHEN-CODEINE #3"
            },
            "NUCARE PHARMACEUTICALS": {
              "ACETAMINOPHEN-CODEINE": "ACETAMINOPHEN-CODEINE",
              "ACETAMINOPHEN-CODEINE #2": "ACETAMINOPHEN-CODEINE #2",
              "ACETAMINOPHEN-CODEINE #3": "ACETAMINOPHEN-CODEINE #3",
              "ACETAMINOPHEN-CODEINE #4": "ACETAMINOPHEN-CODEINE #4"
            },
            "PAR PHARMACEUTICALS": {
              "ACETAMINOPHEN-CODEINE #2": "ACETAMINOPHEN-CODEINE #2",
              "ACETAMINOPHEN-CODEINE #3": "ACETAMINOPHEN-CODEINE #3",
              "ACETAMINOPHEN-CODEINE #4": "ACETAMINOPHEN-CODEINE #4",
              "BUTALBITAL-APAP-CAFF-COD": "BUTALBITAL-APAP-CAFF-COD"
            },
            "PDRX PHARMACEUTICAL": {
              "ACETAMINOPHEN-CODEINE #2": "ACETAMINOPHEN-CODEINE #2",
              "ACETAMINOPHEN-CODEINE #3": "ACETAMINOPHEN-CODEINE #3",
              "ACETAMINOPHEN-CODEINE #4": "ACETAMINOPHEN-CODEINE #4",
              "FIORINAL/CODEINE #3": "FIORINAL/CODEINE #3",
              "TYLENOL WITH CODEINE #3": "TYLENOL WITH CODEINE #3"
            },
            "PHARMACEUTICAL ASSOCIATES": {
              "ACETAMINOPHEN-CODEINE": "ACETAMINOPHEN-CODEINE"
            },
            "PHARMEDIX": {
              "ACETAMINOPHEN-CODEINE #3": "ACETAMINOPHEN-CODEINE #3"
            },
            "PHARMPAK": {
              "ACETAMINOPHEN-CODEINE #3": "ACETAMINOPHEN-CODEINE #3"
            },
            "PREFERRED PHARMACEUTICALS": {
              "ACETAMINOPHEN-CODEINE": "ACETAMINOPHEN-CODEINE",
              "ACETAMINOPHEN-CODEINE #3": "ACETAMINOPHEN-CODEINE #3",
              "ACETAMINOPHEN-CODEINE #4": "ACETAMINOPHEN-CODEINE #4"
            },
            "PROFICIENT RX": {
              "ACETAMINOPHEN-CODEINE #3": "ACETAMINOPHEN-CODEINE #3"
            },
            "QUALITY CARE": {
              "ACETAMINOPHEN-CODEINE": "ACETAMINOPHEN-CODEINE",
              "ACETAMINOPHEN-CODEINE #3": "ACETAMINOPHEN-CODEINE #3",
              "ACETAMINOPHEN-CODEINE #4": "ACETAMINOPHEN-CODEINE #4"
            },
            "QUALITY CARE PRODUCTS": {
              "ACETAMINOPHEN-CODEINE #3": "ACETAMINOPHEN-CODEINE #3"
            },
            "RXCHANGE CO": {
              "ACETAMINOPHEN-CODEINE #3": "ACETAMINOPHEN-CODEINE #3",
              "ACETAMINOPHEN-CODEINE #4": "ACETAMINOPHEN-CODEINE #4",
              "BUTALBITAL-APAP-CAFF-COD": "BUTALBITAL-APAP-CAFF-COD"
            },
            "SKY PACKAGING": {
              "ACETAMINOPHEN-CODEINE #3": "ACETAMINOPHEN-CODEINE #3"
            },
            "ST MARY'S MPP": {
              "ACETAMINOPHEN-CODEINE #3": "ACETAMINOPHEN-CODEINE #3"
            },
            "SUN PHARMACEUTICALS": {
              "ACETAMINOPHEN-CODEINE #4": "ACETAMINOPHEN-CODEINE #4"
            },
            "TEVA PHARMACEUTICALS USA": {
              "ACETAMINOPHEN-CODEINE #2": "ACETAMINOPHEN-CODEINE #2",
              "ACETAMINOPHEN-CODEINE #3": "ACETAMINOPHEN-CODEINE #3",
              "ACETAMINOPHEN-CODEINE #4": "ACETAMINOPHEN-CODEINE #4"
            },
            "TEVA SELECT BRANDS": {
              "FIORICET/CODEINE": "FIORICET/CODEINE"
            },
            "UNIT DOSE SERVICES": {
              "ACETAMINOPHEN-CODEINE #3": "ACETAMINOPHEN-CODEINE #3"
            }
          },
          "Dihydrocodeine Combinations": {
            "AIDAREX PHARMACEUTICALS": {
              "APAP-CAFF-DIHYDROCODEINE": "APAP-CAFF-DIHYDROCODEINE"
            },
            "ATLAND PHARMACEUTICALS": {
              "APAP-CAFF-DIHYDROCODEINE": "APAP-CAFF-DIHYDROCODEINE"
            },
            "BRYANT RANCH PREPACK": {
              "APAP-CAFF-DIHYDROCODEINE": "APAP-CAFF-DIHYDROCODEINE",
              "TREZIX": "TREZIX"
            },
            "LARKEN LABORATORIES, INC.": {
              "APAP-CAFF-DIHYDROCODEINE": "APAP-CAFF-DIHYDROCODEINE"
            },
            "PDRX PHARMACEUTICAL": {
              "SYNALGOS-DC": "SYNALGOS-DC"
            },
            "PHLIGHT PHARMA": {
              "DVORAH": "DVORAH"
            },
            "PREFERRED PHARMACEUTICALS": {
              "APAP-CAFF-DIHYDROCODEINE": "APAP-CAFF-DIHYDROCODEINE"
            },
            "PROFICIENT RX": {
              "TREZIX": "TREZIX"
            },
            "SKYLAR LABORATORIES": {
              "DVORAH": "DVORAH",
              "PANLOR": "PANLOR"
            },
            "SUN PHARMACEUTICALS": {
              "ASPIRIN-CAFF-DIHYDROCODEINE": "ASPIRIN-CAFF-DIHYDROCODEINE",
              "SYNALGOS-DC": "SYNALGOS-DC"
            },
            "WRASER PHARMACEUTICALS": {
              "TREZIX": "TREZIX"
            },
            "XSPIRE PHARMA": {
              "APAP-CAFF-DIHYDROCODEINE": "APAP-CAFF-DIHYDROCODEINE"
            }
          },
          "Fentanyl Combinations": {
            "CENTRAL ADMIXTURE PHARMACY SER": {
              "FENTANYL-BUPIVACAINE-NACL": "FENTANYL-BUPIVACAINE-NACL"
            },
            "NEPHRON STERILE COMPOUNDING": {
              "FENTANYL CIT-ROPIVACAINE-NACL": "FENTANYL CIT-ROPIVACAINE-NACL",
              "FENTANYL-BUPIVACAINE-NACL": "FENTANYL-BUPIVACAINE-NACL"
            },
            "PHARMEDIUM SERVICES": {
              "FENTANYL CIT-ROPIVACAINE-NACL": "FENTANYL CIT-ROPIVACAINE-NACL",
              "FENTANYL-BUPIVACAINE-NACL": "FENTANYL-BUPIVACAINE-NACL"
            },
            "QUVA PHARMA": {
              "FENTANYL CIT-ROPIVACAINE-NACL": "FENTANYL CIT-ROPIVACAINE-NACL",
              "FENTANYL-BUPIVACAINE-NACL": "FENTANYL-BUPIVACAINE-NACL"
            },
            "SCA PHARMACEUTICALS": {
              "FENTANYL-BUPIVACAINE-NACL": "FENTANYL-BUPIVACAINE-NACL"
            }
          },
          "Hydrocodone Combinations": {
            "A-S MEDICATION SOLUTIONS": {
              "HYDROCODONE-ACETAMINOPHEN": "HYDROCODONE-ACETAMINOPHEN"
            },
            "ABBVIE": {
              "VICODIN": "VICODIN",
              "VICODIN ES": "VICODIN ES",
              "VICODIN HP": "VICODIN HP",
              "VICOPROFEN": "VICOPROFEN"
            },
            "ACTAVIS PHARMA": {
              "HYDROCODONE-ACETAMINOPHEN": "HYDROCODONE-ACETAMINOPHEN",
              "HYDROCODONE-IBUPROFEN": "HYDROCODONE-IBUPROFEN",
              "NORCO": "NORCO"
            },
            "AIDAREX PHARMACEUTICALS": {
              "HYDROCODONE-ACETAMINOPHEN": "HYDROCODONE-ACETAMINOPHEN"
            },
            "AKORN": {
              "LORTAB": "LORTAB"
            },
            "ALLERGAN": {
              "NORCO": "NORCO"
            },
            "ALTURA PHARMACEUTICALS": {
              "HYDROCODONE-ACETAMINOPHEN": "HYDROCODONE-ACETAMINOPHEN",
              "HYDROCODONE-IBUPROFEN": "HYDROCODONE-IBUPROFEN"
            },
            "AMERICAN HEALTH PACKAGING": {
              "HYDROCODONE-ACETAMINOPHEN": "HYDROCODONE-ACETAMINOPHEN",
              "HYDROCODONE-IBUPROFEN": "HYDROCODONE-IBUPROFEN"
            },
            "AMNEAL PHARMACEUTICALS": {
              "HYDROCODONE-ACETAMINOPHEN": "HYDROCODONE-ACETAMINOPHEN",
              "HYDROCODONE-IBUPROFEN": "HYDROCODONE-IBUPROFEN"
            },
            "AMNEAL SPECIALTY": {
              "REPREXAIN": "REPREXAIN"
            },
            "APHENA PHARMA SOLUTIONS": {
              "HYDROCODONE-ACETAMINOPHEN": "HYDROCODONE-ACETAMINOPHEN"
            },
            "APOTHECA": {
              "HYDROCODONE-ACETAMINOPHEN": "HYDROCODONE-ACETAMINOPHEN"
            },
            "AUROBINDO PHARMA USA": {
              "HYDROCODONE-ACETAMINOPHEN": "HYDROCODONE-ACETAMINOPHEN",
              "HYDROCODONE-IBUPROFEN": "HYDROCODONE-IBUPROFEN"
            },
            "AVKARE": {
              "HYDROCODONE-ACETAMINOPHEN": "HYDROCODONE-ACETAMINOPHEN"
            },
            "AVPAK": {
              "HYDROCODONE-ACETAMINOPHEN": "HYDROCODONE-ACETAMINOPHEN"
            },
            "BEACH": {
              "ZAMICET": "ZAMICET"
            },
            "BRYANT RANCH PREPACK": {
              "HYDROCODONE-ACETAMINOPHEN": "HYDROCODONE-ACETAMINOPHEN",
              "HYDROCODONE-IBUPROFEN": "HYDROCODONE-IBUPROFEN"
            },
            "BURKE THERAPEUTICS": {
              "HYDROCODONE-ACETAMINOPHEN": "HYDROCODONE-ACETAMINOPHEN"
            },
            "CAMBER PHARMACEUTICALS": {
              "HYDROCODONE-ACETAMINOPHEN": "HYDROCODONE-ACETAMINOPHEN"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "HYDROCODONE-ACETAMINOPHEN": "HYDROCODONE-ACETAMINOPHEN"
            },
            "COSETTE PHARMACEUTICALS": {
              "HYCET": "HYCET",
              "HYDROCODONE-ACETAMINOPHEN": "HYDROCODONE-ACETAMINOPHEN"
            },
            "DIRECT RX": {
              "HYDROCODONE-ACETAMINOPHEN": "HYDROCODONE-ACETAMINOPHEN",
              "HYDROCODONE-IBUPROFEN": "HYDROCODONE-IBUPROFEN"
            },
            "EPIC PHARMA": {
              "HYDROCODONE-ACETAMINOPHEN": "HYDROCODONE-ACETAMINOPHEN"
            },
            "EYWA PHARMA": {
              "HYDROCODONE-ACETAMINOPHEN": "HYDROCODONE-ACETAMINOPHEN"
            },
            "GENUS LIFESCIENCES": {
              "HYDROCODONE-ACETAMINOPHEN": "HYDROCODONE-ACETAMINOPHEN"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "HYDROCODONE-ACETAMINOPHEN": "HYDROCODONE-ACETAMINOPHEN"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "HYDROCODONE-ACETAMINOPHEN": "HYDROCODONE-ACETAMINOPHEN",
              "HYDROCODONE-IBUPROFEN": "HYDROCODONE-IBUPROFEN",
              "NORCO": "NORCO",
              "VICOPROFEN": "VICOPROFEN"
            },
            "KAISER FOUNDATION HOSPITAL": {
              "HYDROCODONE-ACETAMINOPHEN": "HYDROCODONE-ACETAMINOPHEN"
            },
            "KLE 2": {
              "HYDROCODONE-ACETAMINOPHEN": "HYDROCODONE-ACETAMINOPHEN"
            },
            "KVK TECH": {
              "HYDROCODONE-ACETAMINOPHEN": "HYDROCODONE-ACETAMINOPHEN"
            },
            "LANNETT": {
              "HYDROCODONE-ACETAMINOPHEN": "HYDROCODONE-ACETAMINOPHEN"
            },
            "LIBERTY PHARMACEUTICAL": {
              "HYDROCODONE-ACETAMINOPHEN": "HYDROCODONE-ACETAMINOPHEN"
            },
            "LUPIN PHARMACEUTICALS": {
              "HYDROCODONE-ACETAMINOPHEN": "HYDROCODONE-ACETAMINOPHEN"
            },
            "MAJOR PHARMACEUTICALS": {
              "HYDROCODONE-ACETAMINOPHEN": "HYDROCODONE-ACETAMINOPHEN"
            },
            "MALLINCKRODT PHARM": {
              "HYDROCODONE-ACETAMINOPHEN": "HYDROCODONE-ACETAMINOPHEN"
            },
            "MAYNE PHARMA": {
              "HYDROCODONE-ACETAMINOPHEN": "HYDROCODONE-ACETAMINOPHEN",
              "LORCET": "LORCET",
              "LORCET HD": "LORCET HD",
              "LORCET PLUS": "LORCET PLUS"
            },
            "MYLAN INSTITUTIONAL": {
              "HYDROCODONE-ACETAMINOPHEN": "HYDROCODONE-ACETAMINOPHEN"
            },
            "NUCARE PHARMACEUTICALS": {
              "HYDROCODONE-ACETAMINOPHEN": "HYDROCODONE-ACETAMINOPHEN",
              "HYDROCODONE-IBUPROFEN": "HYDROCODONE-IBUPROFEN"
            },
            "PAR PHARMACEUTICAL": {
              "HYDROCODONE-ACETAMINOPHEN": "HYDROCODONE-ACETAMINOPHEN"
            },
            "PAR PHARMACEUTICALS": {
              "HYDROCODONE-ACETAMINOPHEN": "HYDROCODONE-ACETAMINOPHEN",
              "HYDROCODONE-IBUPROFEN": "HYDROCODONE-IBUPROFEN"
            },
            "PDRX PHARMACEUTICAL": {
              "HYDROCODONE-ACETAMINOPHEN": "HYDROCODONE-ACETAMINOPHEN",
              "HYDROCODONE-IBUPROFEN": "HYDROCODONE-IBUPROFEN",
              "NORCO": "NORCO",
              "VICOPROFEN": "VICOPROFEN"
            },
            "PHARMACEUTICAL ASSOCIATES": {
              "HYDROCODONE-ACETAMINOPHEN": "HYDROCODONE-ACETAMINOPHEN"
            },
            "POLY PHARMACEUTICALS": {
              "IBUDONE": "IBUDONE"
            },
            "PRECISION DOSE, INC": {
              "HYDROCODONE-ACETAMINOPHEN": "HYDROCODONE-ACETAMINOPHEN"
            },
            "PREFERRED PHARMACEUTICALS": {
              "HYDROCODONE-ACETAMINOPHEN": "HYDROCODONE-ACETAMINOPHEN"
            },
            "PROFICIENT RX": {
              "HYDROCODONE-ACETAMINOPHEN": "HYDROCODONE-ACETAMINOPHEN"
            },
            "QUALITY CARE": {
              "HYDROCODONE-ACETAMINOPHEN": "HYDROCODONE-ACETAMINOPHEN",
              "HYDROCODONE-IBUPROFEN": "HYDROCODONE-IBUPROFEN",
              "NORCO": "NORCO",
              "XODOL": "XODOL"
            },
            "QUALITY CARE PRODUCTS": {
              "HYDROCODONE-ACETAMINOPHEN": "HYDROCODONE-ACETAMINOPHEN",
              "HYDROCODONE-IBUPROFEN": "HYDROCODONE-IBUPROFEN"
            },
            "RHODES PHARMACEUTICAL": {
              "HYDROCODONE-ACETAMINOPHEN": "HYDROCODONE-ACETAMINOPHEN"
            },
            "RXCHANGE CO": {
              "HYDROCODONE-ACETAMINOPHEN": "HYDROCODONE-ACETAMINOPHEN",
              "HYDROCODONE-IBUPROFEN": "HYDROCODONE-IBUPROFEN"
            },
            "SHIONOGI PHARMA": {
              "XODOL": "XODOL"
            },
            "SIRCLE LABORATORIES": {
              "XYLON": "XYLON"
            },
            "SKY PACKAGING": {
              "HYDROCODONE-ACETAMINOPHEN": "HYDROCODONE-ACETAMINOPHEN",
              "HYDROCODONE-IBUPROFEN": "HYDROCODONE-IBUPROFEN"
            },
            "SOLCO HEALTHCARE": {
              "HYDROCODONE-ACETAMINOPHEN": "HYDROCODONE-ACETAMINOPHEN"
            },
            "ST MARY'S MPP": {
              "HYDROCODONE-ACETAMINOPHEN": "HYDROCODONE-ACETAMINOPHEN",
              "HYDROCODONE-IBUPROFEN": "HYDROCODONE-IBUPROFEN"
            },
            "SUN PHARMACEUTICALS": {
              "HYDROCODONE-ACETAMINOPHEN": "HYDROCODONE-ACETAMINOPHEN"
            },
            "TRIGEN LABORATORIES": {
              "HYDROCODONE-ACETAMINOPHEN": "HYDROCODONE-ACETAMINOPHEN"
            },
            "TRIS PHARMA": {
              "HYDROCODONE-ACETAMINOPHEN": "HYDROCODONE-ACETAMINOPHEN"
            },
            "UCB PHARMA": {
              "LORTAB": "LORTAB"
            },
            "UNIT DOSE SERVICES": {
              "HYDROCODONE-ACETAMINOPHEN": "HYDROCODONE-ACETAMINOPHEN"
            },
            "VERTICAL PHARMACEUTICAL": {
              "VERDROCET": "VERDROCET"
            },
            "VISTAPHARM INC.": {
              "HYDROCODONE-ACETAMINOPHEN": "HYDROCODONE-ACETAMINOPHEN"
            }
          },
          "Hydromorphone Combinations": {
            "PHARMEDIUM SERVICES": {
              "HYDROMORPHONE-BUPIVACAINE-NACL": "HYDROMORPHONE-BUPIVACAINE-NACL",
              "HYDROMORPHONE-ROPIVACAINE-NACL": "HYDROMORPHONE-ROPIVACAINE-NACL"
            }
          },
          "Meperidine Combinations": {
            "ECI PHARMACEUTICALS": {
              "MEPERIDINE-PROMETHAZINE": "MEPERIDINE-PROMETHAZINE"
            }
          },
          "Opioid Combinations": {
            "ACTAVIS ELIZABETH": {
              "OXYCODONE-ACETAMINOPHEN": "OXYCODONE-ACETAMINOPHEN",
              "OXYCODONE-IBUPROFEN": "OXYCODONE-IBUPROFEN"
            },
            "ACTAVIS PHARMA": {
              "OXYCODONE-ASPIRIN": "OXYCODONE-ASPIRIN"
            },
            "AKRIMAX PHARMACEUTICALS": {
              "PRIMLEV": "PRIMLEV"
            },
            "ALTURA PHARMACEUTICALS": {
              "OXYCODONE-ACETAMINOPHEN": "OXYCODONE-ACETAMINOPHEN"
            },
            "ALVOGEN": {
              "OXYCODONE-ACETAMINOPHEN": "OXYCODONE-ACETAMINOPHEN"
            },
            "AMERICAN HEALTH PACKAGING": {
              "OXYCODONE-ACETAMINOPHEN": "OXYCODONE-ACETAMINOPHEN"
            },
            "AMNEAL PHARMACEUTICALS": {
              "OXYCODONE-ACETAMINOPHEN": "OXYCODONE-ACETAMINOPHEN"
            },
            "AUROBINDO PHARMA USA": {
              "OXYCODONE-ACETAMINOPHEN": "OXYCODONE-ACETAMINOPHEN"
            },
            "AVKARE": {
              "OXYCODONE-ACETAMINOPHEN": "OXYCODONE-ACETAMINOPHEN"
            },
            "AVPAK": {
              "OXYCODONE-ACETAMINOPHEN": "OXYCODONE-ACETAMINOPHEN"
            },
            "BLENHEIM PHARMACAL": {
              "OXYCODONE-ACETAMINOPHEN": "OXYCODONE-ACETAMINOPHEN"
            },
            "BRYANT RANCH PREPACK": {
              "OXYCODONE-ACETAMINOPHEN": "OXYCODONE-ACETAMINOPHEN"
            },
            "CAMBER PHARMACEUTICALS": {
              "OXYCODONE-ACETAMINOPHEN": "OXYCODONE-ACETAMINOPHEN"
            },
            "DIRECT RX": {
              "OXYCODONE-ACETAMINOPHEN": "OXYCODONE-ACETAMINOPHEN"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "OXYCODONE-ACETAMINOPHEN": "OXYCODONE-ACETAMINOPHEN"
            },
            "ENDO GENERIC PRODUCTS": {
              "ENDOCET": "ENDOCET"
            },
            "ENDO PHARMACEUTICALS": {
              "PERCOCET": "PERCOCET"
            },
            "EPIC PHARMA": {
              "OXYCODONE-ACETAMINOPHEN": "OXYCODONE-ACETAMINOPHEN"
            },
            "EYWA PHARMA": {
              "OXYCODONE-ACETAMINOPHEN": "OXYCODONE-ACETAMINOPHEN"
            },
            "FH2 PHARMA": {
              "OXYCODONE-ACETAMINOPHEN": "OXYCODONE-ACETAMINOPHEN"
            },
            "FORTE BIO-PHARMA": {
              "NALOCET": "NALOCET",
              "PROLATE": "PROLATE"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "OXYCODONE-ACETAMINOPHEN": "OXYCODONE-ACETAMINOPHEN"
            },
            "HIKMA": {
              "OXYCODONE-ACETAMINOPHEN": "OXYCODONE-ACETAMINOPHEN"
            },
            "KVK TECH": {
              "APADAZ": "APADAZ",
              "BENZHYDROCODONE-ACETAMINOPHEN": "BENZHYDROCODONE-ACETAMINOPHEN",
              "OXYCODONE-ACETAMINOPHEN": "OXYCODONE-ACETAMINOPHEN"
            },
            "LANNETT": {
              "OXYCODONE-ACETAMINOPHEN": "OXYCODONE-ACETAMINOPHEN"
            },
            "LIBERTY PHARMACEUTICAL": {
              "OXYCODONE-ACETAMINOPHEN": "OXYCODONE-ACETAMINOPHEN"
            },
            "LUPIN PHARMACEUTICALS": {
              "OXYCODONE-ACETAMINOPHEN": "OXYCODONE-ACETAMINOPHEN"
            },
            "MAJOR PHARMACEUTICALS": {
              "OXYCODONE-ACETAMINOPHEN": "OXYCODONE-ACETAMINOPHEN"
            },
            "MALLINCKRODT PHARM": {
              "OXYCODONE-ACETAMINOPHEN": "OXYCODONE-ACETAMINOPHEN"
            },
            "MAYNE PHARMA": {
              "OXYCODONE-ACETAMINOPHEN": "OXYCODONE-ACETAMINOPHEN",
              "OXYCODONE-ASPIRIN": "OXYCODONE-ASPIRIN"
            },
            "MYLAN": {
              "OXYCODONE-ACETAMINOPHEN": "OXYCODONE-ACETAMINOPHEN",
              "OXYCODONE-ASPIRIN": "OXYCODONE-ASPIRIN"
            },
            "NUCARE PHARMACEUTICALS": {
              "ENDOCET": "ENDOCET",
              "OXYCODONE-ACETAMINOPHEN": "OXYCODONE-ACETAMINOPHEN"
            },
            "PAR PHARMACEUTICALS": {
              "OXYCODONE-ACETAMINOPHEN": "OXYCODONE-ACETAMINOPHEN"
            },
            "PDRX PHARMACEUTICAL": {
              "OXYCODONE-ACETAMINOPHEN": "OXYCODONE-ACETAMINOPHEN"
            },
            "PRECISION DOSE, INC": {
              "OXYCODONE-ACETAMINOPHEN": "OXYCODONE-ACETAMINOPHEN"
            },
            "PROFICIENT RX": {
              "OXYCODONE-ACETAMINOPHEN": "OXYCODONE-ACETAMINOPHEN"
            },
            "QUALITY CARE": {
              "ENDOCET": "ENDOCET",
              "OXYCODONE-ACETAMINOPHEN": "OXYCODONE-ACETAMINOPHEN",
              "OXYCODONE-IBUPROFEN": "OXYCODONE-IBUPROFEN",
              "PERCOCET": "PERCOCET"
            },
            "QUALITY CARE PRODUCTS": {
              "OXYCODONE-ACETAMINOPHEN": "OXYCODONE-ACETAMINOPHEN",
              "XARTEMIS XR": "XARTEMIS XR"
            },
            "RHODES PHARMACEUTICAL": {
              "OXYCODONE-ACETAMINOPHEN": "OXYCODONE-ACETAMINOPHEN"
            },
            "RXCHANGE CO": {
              "ENDOCET": "ENDOCET",
              "OXYCODONE-ACETAMINOPHEN": "OXYCODONE-ACETAMINOPHEN"
            },
            "SKY PACKAGING": {
              "OXYCODONE-ACETAMINOPHEN": "OXYCODONE-ACETAMINOPHEN"
            },
            "SPECGX": {
              "OXYCODONE-ACETAMINOPHEN": "OXYCODONE-ACETAMINOPHEN",
              "XARTEMIS XR": "XARTEMIS XR"
            },
            "ST MARY'S MPP": {
              "OXYCODONE-ACETAMINOPHEN": "OXYCODONE-ACETAMINOPHEN"
            },
            "SUN PHARMACEUTICALS": {
              "OXYCODONE-ACETAMINOPHEN": "OXYCODONE-ACETAMINOPHEN"
            },
            "UNIT DOSE SERVICES": {
              "OXYCODONE-ACETAMINOPHEN": "OXYCODONE-ACETAMINOPHEN"
            }
          },
          "Tramadol Combinations": {
            "A-S MEDICATION SOLUTIONS": {
              "TRAMADOL-ACETAMINOPHEN": "TRAMADOL-ACETAMINOPHEN",
              "ULTRACET": "ULTRACET"
            },
            "AIDAREX PHARMACEUTICALS": {
              "TRAMADOL-ACETAMINOPHEN": "TRAMADOL-ACETAMINOPHEN"
            },
            "ALTURA PHARMACEUTICALS": {
              "TRAMADOL-ACETAMINOPHEN": "TRAMADOL-ACETAMINOPHEN"
            },
            "AMERICAN HEALTH PACKAGING": {
              "TRAMADOL-ACETAMINOPHEN": "TRAMADOL-ACETAMINOPHEN"
            },
            "AMNEAL PHARMACEUTICALS": {
              "TRAMADOL-ACETAMINOPHEN": "TRAMADOL-ACETAMINOPHEN"
            },
            "APOTEX": {
              "TRAMADOL-ACETAMINOPHEN": "TRAMADOL-ACETAMINOPHEN"
            },
            "APOTHECA": {
              "TRAMADOL-ACETAMINOPHEN": "TRAMADOL-ACETAMINOPHEN"
            },
            "ASCEND LABORATORIES": {
              "TRAMADOL-ACETAMINOPHEN": "TRAMADOL-ACETAMINOPHEN"
            },
            "AUROBINDO PHARMA": {
              "TRAMADOL-ACETAMINOPHEN": "TRAMADOL-ACETAMINOPHEN"
            },
            "AVKARE": {
              "TRAMADOL-ACETAMINOPHEN": "TRAMADOL-ACETAMINOPHEN"
            },
            "AVPAK": {
              "TRAMADOL-ACETAMINOPHEN": "TRAMADOL-ACETAMINOPHEN"
            },
            "BRYANT RANCH PREPACK": {
              "TRAMADOL-ACETAMINOPHEN": "TRAMADOL-ACETAMINOPHEN"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "TRAMADOL-ACETAMINOPHEN": "TRAMADOL-ACETAMINOPHEN"
            },
            "DIRECT RX": {
              "TRAMADOL-ACETAMINOPHEN": "TRAMADOL-ACETAMINOPHEN"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "TRAMADOL-ACETAMINOPHEN": "TRAMADOL-ACETAMINOPHEN"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "TRAMADOL-ACETAMINOPHEN": "TRAMADOL-ACETAMINOPHEN",
              "ULTRACET": "ULTRACET"
            },
            "JANSSEN": {
              "ULTRACET": "ULTRACET"
            },
            "LIBERTY PHARMACEUTICAL": {
              "TRAMADOL-ACETAMINOPHEN": "TRAMADOL-ACETAMINOPHEN"
            },
            "MICRO LABORATORIES": {
              "TRAMADOL-ACETAMINOPHEN": "TRAMADOL-ACETAMINOPHEN"
            },
            "MYLAN": {
              "TRAMADOL-ACETAMINOPHEN": "TRAMADOL-ACETAMINOPHEN"
            },
            "NUCARE PHARMACEUTICALS": {
              "TRAMADOL-ACETAMINOPHEN": "TRAMADOL-ACETAMINOPHEN",
              "ULTRACET": "ULTRACET"
            },
            "PAR PHARMACEUTICAL": {
              "TRAMADOL-ACETAMINOPHEN": "TRAMADOL-ACETAMINOPHEN"
            },
            "PDRX PHARMACEUTICAL": {
              "TRAMADOL-ACETAMINOPHEN": "TRAMADOL-ACETAMINOPHEN",
              "ULTRACET": "ULTRACET"
            },
            "PREFERRED PHARMACEUTICALS": {
              "TRAMADOL-ACETAMINOPHEN": "TRAMADOL-ACETAMINOPHEN"
            },
            "QUALITY CARE": {
              "TRAMADOL-ACETAMINOPHEN": "TRAMADOL-ACETAMINOPHEN",
              "ULTRACET": "ULTRACET"
            },
            "QUALITY CARE PRODUCTS": {
              "TRAMADOL-ACETAMINOPHEN": "TRAMADOL-ACETAMINOPHEN"
            },
            "RXCHANGE CO": {
              "TRAMADOL-ACETAMINOPHEN": "TRAMADOL-ACETAMINOPHEN"
            },
            "ST MARY'S MPP": {
              "TRAMADOL-ACETAMINOPHEN": "TRAMADOL-ACETAMINOPHEN"
            },
            "SUN PHARMACEUTICALS": {
              "TRAMADOL-ACETAMINOPHEN": "TRAMADOL-ACETAMINOPHEN"
            },
            "UNIT DOSE SERVICES": {
              "TRAMADOL-ACETAMINOPHEN": "TRAMADOL-ACETAMINOPHEN"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "TRAMADOL-ACETAMINOPHEN": "TRAMADOL-ACETAMINOPHEN"
            }
          }
        },
        "Opioid Partial Agonists": {
          "Opioid Partial Agonists": {
            "A-S MEDICATION SOLUTIONS": {
              "BUPRENORPHINE HCL": "BUPRENORPHINE HCL",
              "BUPRENORPHINE HCL-NALOXONE HCL": "BUPRENORPHINE HCL-NALOXONE HCL",
              "BUTORPHANOL TARTRATE": "BUTORPHANOL TARTRATE",
              "BUTRANS": "BUTRANS",
              "NALBUPHINE HCL": "NALBUPHINE HCL",
              "PENTAZOCINE-NALOXONE HCL": "PENTAZOCINE-NALOXONE HCL",
              "SUBOXONE": "SUBOXONE"
            },
            "ACTAVIS ELIZABETH": {
              "BUPRENORPHINE HCL": "BUPRENORPHINE HCL",
              "BUPRENORPHINE HCL-NALOXONE HCL": "BUPRENORPHINE HCL-NALOXONE HCL"
            },
            "ACTAVIS PHARMA": {
              "PENTAZOCINE-NALOXONE HCL": "PENTAZOCINE-NALOXONE HCL"
            },
            "AIDAREX PHARMACEUTICALS": {
              "BUPRENORPHINE HCL": "BUPRENORPHINE HCL",
              "BUPRENORPHINE HCL-NALOXONE HCL": "BUPRENORPHINE HCL-NALOXONE HCL"
            },
            "ALMATICA": {
              "BUPRENORPHINE HCL-NALOXONE HCL": "BUPRENORPHINE HCL-NALOXONE HCL"
            },
            "ALTURA PHARMACEUTICALS": {
              "BUTORPHANOL TARTRATE": "BUTORPHANOL TARTRATE",
              "PENTAZOCINE-NALOXONE HCL": "PENTAZOCINE-NALOXONE HCL"
            },
            "ALVOGEN": {
              "BUPRENORPHINE HCL-NALOXONE HCL": "BUPRENORPHINE HCL-NALOXONE HCL"
            },
            "AMERICAN HEALTH PACKAGING": {
              "BUPRENORPHINE HCL": "BUPRENORPHINE HCL"
            },
            "AMNEAL PHARMACEUTICALS": {
              "BUPRENORPHINE": "BUPRENORPHINE",
              "BUPRENORPHINE HCL-NALOXONE HCL": "BUPRENORPHINE HCL-NALOXONE HCL"
            },
            "APOTEX": {
              "BUTORPHANOL TARTRATE": "BUTORPHANOL TARTRATE"
            },
            "AVKARE": {
              "BUPRENORPHINE HCL-NALOXONE HCL": "BUPRENORPHINE HCL-NALOXONE HCL"
            },
            "AVPAK": {
              "BUPRENORPHINE HCL-NALOXONE HCL": "BUPRENORPHINE HCL-NALOXONE HCL"
            },
            "BIODELIVERY SCIENCES": {
              "BELBUCA": "BELBUCA",
              "BUNAVAIL": "BUNAVAIL"
            },
            "BRAEBURN PHARMACEUTICAL": {
              "PROBUPHINE IMPLANT KIT": "PROBUPHINE IMPLANT KIT"
            },
            "BRYANT RANCH PREPACK": {
              "BUPRENORPHINE HCL": "BUPRENORPHINE HCL",
              "PENTAZOCINE-NALOXONE HCL": "PENTAZOCINE-NALOXONE HCL"
            },
            "DIRECT RX": {
              "PENTAZOCINE-NALOXONE HCL": "PENTAZOCINE-NALOXONE HCL"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "BUPRENORPHINE HCL-NALOXONE HCL": "BUPRENORPHINE HCL-NALOXONE HCL"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "BUPRENORPHINE HCL-NALOXONE HCL": "BUPRENORPHINE HCL-NALOXONE HCL",
              "PENTAZOCINE-NALOXONE HCL": "PENTAZOCINE-NALOXONE HCL"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "PENTAZOCINE-NALOXONE HCL": "PENTAZOCINE-NALOXONE HCL"
            },
            "HI-TECH": {
              "BUPRENORPHINE HCL": "BUPRENORPHINE HCL",
              "BUPRENORPHINE HCL-NALOXONE HCL": "BUPRENORPHINE HCL-NALOXONE HCL"
            },
            "HIKMA": {
              "BUPRENORPHINE HCL": "BUPRENORPHINE HCL",
              "BUPRENORPHINE HCL-NALOXONE HCL": "BUPRENORPHINE HCL-NALOXONE HCL",
              "BUTORPHANOL TARTRATE": "BUTORPHANOL TARTRATE"
            },
            "HOSPIRA": {
              "BUPRENORPHINE HCL": "BUPRENORPHINE HCL",
              "BUTORPHANOL TARTRATE": "BUTORPHANOL TARTRATE",
              "NALBUPHINE HCL": "NALBUPHINE HCL",
              "TALWIN": "TALWIN"
            },
            "INDIVIOR INC": {
              "BUPRENEX": "BUPRENEX",
              "SUBLOCADE": "SUBLOCADE",
              "SUBOXONE": "SUBOXONE"
            },
            "KREMERS URBAN": {
              "BUPRENORPHINE HCL-NALOXONE HCL": "BUPRENORPHINE HCL-NALOXONE HCL"
            },
            "LUPIN PHARMACEUTICALS": {
              "PENTAZOCINE-NALOXONE HCL": "PENTAZOCINE-NALOXONE HCL"
            },
            "MALLINCKRODT PHARM": {
              "BUPRENORPHINE HCL-NALOXONE HCL": "BUPRENORPHINE HCL-NALOXONE HCL"
            },
            "MYLAN": {
              "BUPRENORPHINE HCL": "BUPRENORPHINE HCL",
              "BUPRENORPHINE HCL-NALOXONE HCL": "BUPRENORPHINE HCL-NALOXONE HCL",
              "BUTORPHANOL TARTRATE": "BUTORPHANOL TARTRATE"
            },
            "NOVAPLUS/HOSPIRA": {
              "BUTORPHANOL TARTRATE": "BUTORPHANOL TARTRATE",
              "NALBUPHINE HCL": "NALBUPHINE HCL"
            },
            "NUCARE PHARMACEUTICALS": {
              "PENTAZOCINE-NALOXONE HCL": "PENTAZOCINE-NALOXONE HCL"
            },
            "OREXO US": {
              "ZUBSOLV": "ZUBSOLV"
            },
            "PAR STERILE PRODUCTS": {
              "BUPRENORPHINE HCL": "BUPRENORPHINE HCL"
            },
            "PDRX PHARMACEUTICAL": {
              "BUPRENORPHINE HCL": "BUPRENORPHINE HCL",
              "PENTAZOCINE-NALOXONE HCL": "PENTAZOCINE-NALOXONE HCL"
            },
            "PURDUE PHARMA L.P.": {
              "BUTRANS": "BUTRANS"
            },
            "QUALITY CARE": {
              "BUPRENORPHINE HCL": "BUPRENORPHINE HCL",
              "BUTRANS": "BUTRANS"
            },
            "QUALITY CARE PRODUCTS": {
              "BELBUCA": "BELBUCA",
              "BUPRENORPHINE": "BUPRENORPHINE",
              "SUBOXONE": "SUBOXONE"
            },
            "RHODES PHARMACEUTICAL": {
              "BUPRENORPHINE": "BUPRENORPHINE",
              "BUPRENORPHINE HCL": "BUPRENORPHINE HCL"
            },
            "SANDOZ": {
              "BUPRENORPHINE HCL-NALOXONE HCL": "BUPRENORPHINE HCL-NALOXONE HCL"
            },
            "SKY PACKAGING": {
              "NALBUPHINE HCL": "NALBUPHINE HCL"
            },
            "SPECGX": {
              "BUPRENORPHINE HCL-NALOXONE HCL": "BUPRENORPHINE HCL-NALOXONE HCL"
            },
            "SUN PHARMACEUTICALS": {
              "BUPRENORPHINE HCL": "BUPRENORPHINE HCL",
              "BUPRENORPHINE HCL-NALOXONE HCL": "BUPRENORPHINE HCL-NALOXONE HCL"
            },
            "TEVA PHARMACEUTICALS USA": {
              "BUPRENORPHINE": "BUPRENORPHINE",
              "BUPRENORPHINE HCL": "BUPRENORPHINE HCL",
              "BUPRENORPHINE HCL-NALOXONE HCL": "BUPRENORPHINE HCL-NALOXONE HCL"
            },
            "TITAN PHARMACEUTICALS": {
              "PROBUPHINE IMPLANT KIT": "PROBUPHINE IMPLANT KIT"
            }
          }
        }
      },
      "ANDROGENS-ANABOLIC": {
        "Anabolic Steroids": {
          "Anabolic Steroids": {
            "A-S MEDICATION SOLUTIONS": {
              "OXANDRIN": "OXANDRIN",
              "OXANDROLONE": "OXANDROLONE"
            },
            "AMERICAN HEALTH PACKAGING": {
              "OXANDROLONE": "OXANDROLONE"
            },
            "EMPOWER PHARMACY": {
              "NANDROLONE DECANOATE": "NANDROLONE DECANOATE"
            },
            "MYLAN SPECIALTY L.P.": {
              "ANADROL-50": "ANADROL-50"
            },
            "PAR PHARMACEUTICAL": {
              "OXANDROLONE": "OXANDROLONE"
            },
            "SAVIENT PHARMACEUTICALS INC.": {
              "OXANDRIN": "OXANDRIN"
            },
            "UPSHER-SMITH": {
              "OXANDROLONE": "OXANDROLONE"
            }
          }
        },
        "Androgens": {
          "Androgen Combinations": {
            "EMPOWER PHARMACY": {
              "NANDROLONE-TESTOSTERONE CYP&EN": "NANDROLONE-TESTOSTERONE CYP&EN",
              "TESTOSTERONE CYPIONATE & PROP": "TESTOSTERONE CYPIONATE & PROP"
            }
          },
          "Androgens": {
            "A-S MEDICATION SOLUTIONS": {
              "ANDROGEL": "ANDROGEL",
              "ANDROGEL PUMP": "ANDROGEL PUMP",
              "DEPO-TESTOSTERONE": "DEPO-TESTOSTERONE",
              "TESTIM": "TESTIM",
              "TESTOSTERONE CYPIONATE": "TESTOSTERONE CYPIONATE"
            },
            "ABBVIE": {
              "ANDROGEL": "ANDROGEL",
              "ANDROGEL PUMP": "ANDROGEL PUMP"
            },
            "ACERUS PHARMACEUTICALS": {
              "NATESTO": "NATESTO"
            },
            "ACTAVIS PHARMA": {
              "TESTOSTERONE": "TESTOSTERONE",
              "TESTOSTERONE CYPIONATE": "TESTOSTERONE CYPIONATE",
              "TESTOSTERONE ENANTHATE": "TESTOSTERONE ENANTHATE"
            },
            "ALLERGAN": {
              "ANDRODERM": "ANDRODERM"
            },
            "ALTURA PHARMACEUTICALS": {
              "DEPO-TESTOSTERONE": "DEPO-TESTOSTERONE"
            },
            "AMERICAN REGENT": {
              "TESTOSTERONE CYPIONATE": "TESTOSTERONE CYPIONATE"
            },
            "AMNEAL PHARMACEUTICALS": {
              "METHITEST": "METHITEST",
              "METHYLTESTOSTERONE": "METHYLTESTOSTERONE",
              "TESTOSTERONE": "TESTOSTERONE"
            },
            "ANTARES PHARMA": {
              "XYOSTED": "XYOSTED"
            },
            "ASP CARES": {
              "TESTOSTERONE CYPIONATE": "TESTOSTERONE CYPIONATE"
            },
            "AVKARE": {
              "DANAZOL": "DANAZOL"
            },
            "AYTU BIOSCIENCE": {
              "NATESTO": "NATESTO"
            },
            "AZURITY PHARMACEUTICALS": {
              "FIRST-TESTOSTERONE": "FIRST-TESTOSTERONE",
              "FIRST-TESTOSTERONE MC": "FIRST-TESTOSTERONE MC"
            },
            "BAUSCH HEALTH": {
              "ANDROID": "ANDROID",
              "TESTRED": "TESTRED"
            },
            "CIPLA USA": {
              "TESTOSTERONE": "TESTOSTERONE",
              "TESTOSTERONE CYPIONATE": "TESTOSTERONE CYPIONATE"
            },
            "CLARUS THERAPEUTICS": {
              "JATENZO": "JATENZO"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "TESTOSTERONE": "TESTOSTERONE"
            },
            "EMPOWER PHARMACY": {
              "TESTOSTERONE": "TESTOSTERONE",
              "TESTOSTERONE CYPIONATE": "TESTOSTERONE CYPIONATE",
              "TESTOSTERONE ENANTHATE": "TESTOSTERONE ENANTHATE",
              "TESTOSTERONE PROPIONATE": "TESTOSTERONE PROPIONATE"
            },
            "ENDO PHARMACEUTICALS": {
              "AVEED": "AVEED",
              "FORTESTA": "FORTESTA",
              "STRIANT": "STRIANT",
              "TESTIM": "TESTIM",
              "TESTOPEL": "TESTOPEL"
            },
            "ENOVACHEM MANUFACTURING": {
              "EC-RX TESTOSTERONE": "EC-RX TESTOSTERONE",
              "TESTONE CIK": "TESTONE CIK"
            },
            "HIKMA": {
              "TESTOSTERONE CYPIONATE": "TESTOSTERONE CYPIONATE",
              "TESTOSTERONE ENANTHATE": "TESTOSTERONE ENANTHATE"
            },
            "HOSPIRA": {
              "TESTOSTERONE CYPIONATE": "TESTOSTERONE CYPIONATE"
            },
            "HUMCO": {
              "TESTOSTERONE COMPOUNDING KIT": "TESTOSTERONE COMPOUNDING KIT"
            },
            "LANNETT": {
              "DANAZOL": "DANAZOL"
            },
            "LETCO MEDICAL": {
              "DANAZOL": "DANAZOL"
            },
            "LILLY": {
              "AXIRON": "AXIRON"
            },
            "LUPIN PHARMACEUTICALS": {
              "TESTOSTERONE": "TESTOSTERONE"
            },
            "MEDISCA": {
              "DANAZOL": "DANAZOL",
              "METHYLTESTOSTERONE": "METHYLTESTOSTERONE"
            },
            "NORTHSTAR RX": {
              "TESTOSTERONE": "TESTOSTERONE"
            },
            "NOVAPLUS/PFIZER U.S.": {
              "DEPO-TESTOSTERONE": "DEPO-TESTOSTERONE"
            },
            "PAR PHARMACEUTICAL": {
              "TESTOSTERONE": "TESTOSTERONE"
            },
            "PAR PHARMACEUTICALS": {
              "TESTOSTERONE": "TESTOSTERONE"
            },
            "PCCA": {
              "DANAZOL": "DANAZOL",
              "METHYLTESTOSTERONE": "METHYLTESTOSTERONE"
            },
            "PERRIGO": {
              "TESTOSTERONE CYPIONATE": "TESTOSTERONE CYPIONATE"
            },
            "PERRIGO PHARMACEUTICALS": {
              "TESTOSTERONE": "TESTOSTERONE"
            },
            "PFIZER U.S.": {
              "DEPO-TESTOSTERONE": "DEPO-TESTOSTERONE"
            },
            "PRASCO LABORATORIES": {
              "TESTOSTERONE": "TESTOSTERONE"
            },
            "QUALITY CARE": {
              "ANDROGEL": "ANDROGEL",
              "DEPO-TESTOSTERONE": "DEPO-TESTOSTERONE",
              "TESTIM": "TESTIM"
            },
            "RISING PHARMACEUTICALS": {
              "TESTOSTERONE CYPIONATE": "TESTOSTERONE CYPIONATE"
            },
            "SPECTRUM": {
              "DANAZOL": "DANAZOL"
            },
            "SUN PHARMACEUTICALS": {
              "TESTOSTERONE CYPIONATE": "TESTOSTERONE CYPIONATE"
            },
            "TEVA PHARMACEUTICALS USA": {
              "DANAZOL": "DANAZOL"
            },
            "TWI PHARMACEUTICALS": {
              "TESTOSTERONE": "TESTOSTERONE"
            },
            "UPSHER-SMITH": {
              "ANDROXY": "ANDROXY",
              "TESTOSTERONE": "TESTOSTERONE",
              "VOGELXO": "VOGELXO",
              "VOGELXO PUMP": "VOGELXO PUMP"
            },
            "US COMPOUNDING": {
              "TESTOSTERONE": "TESTOSTERONE"
            },
            "WILSHIRE PHARMACEUTICALS": {
              "TESTOSTERONE CYPIONATE": "TESTOSTERONE CYPIONATE"
            },
            "XIROMED": {
              "TESTOSTERONE": "TESTOSTERONE"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "TESTOSTERONE": "TESTOSTERONE"
            }
          }
        }
      },
      "ANORECTAL AGENTS": {
        "Rectal Combinations": {
          "Rectal Anesthetic/Steroids": {
            "SEBELA PHARMACEUTICALS": {
              "HYDROCORTISONE ACE-PRAMOXINE": "HYDROCORTISONE ACE-PRAMOXINE"
            }
          },
          "Rectal Combinations - Misc.": {
            "GERITREX CORPORATION": {
              "HEMORRHOIDAL": "HEMORRHOIDAL"
            },
            "TOPCO": {
              "HEMORRHOIDAL": "HEMORRHOIDAL"
            },
            "WALGREENS": {
              "HEMORRHOIDAL": "HEMORRHOIDAL"
            }
          }
        },
        "Rectal Steroids": {
          "Rectal Steroids": {
            "H2 PHARMA": {
              "HYDROCORTISONE": "HYDROCORTISONE"
            }
          }
        }
      },
      "ANORECTAL AND RELATED PRODUCTS": {
        "Intrarectal Steroids": {
          "Intrarectal Steroids": {
            "ANI  PHARMACEUTICALS": {
              "CORTENEMA": "CORTENEMA",
              "HYDROCORTISONE": "HYDROCORTISONE"
            },
            "BAUSCH HEALTH": {
              "UCERIS": "UCERIS"
            },
            "MYLAN SPECIALTY L.P.": {
              "CORTIFOAM": "CORTIFOAM"
            },
            "PERRIGO": {
              "COLOCORT": "COLOCORT"
            }
          }
        },
        "Rectal Combinations": {
          "Rectal Anesthetic Combinations": {
            "AMERICAN SALES COMPANY": {
              "HEMORRHOIDAL": "HEMORRHOIDAL"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC HEMORRHOIDAL MAX": "QC HEMORRHOIDAL MAX"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS HEMORRHOIDAL": "CVS HEMORRHOIDAL"
            },
            "EQUALINE": {
              "EQL HEMORRHOIDAL": "EQL HEMORRHOIDAL"
            },
            "FERNDALE LAB": {
              "RECTICARE": "RECTICARE"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "PREPARATION H": "PREPARATION H",
              "PREPARATION H RAPID RELIEF": "PREPARATION H RAPID RELIEF",
              "PREPARATION H TOTABLES": "PREPARATION H TOTABLES"
            },
            "LEADER BRAND PRODUCTS": {
              "HEMORRHOIDAL": "HEMORRHOIDAL",
              "HEMORRHOIDAL MAX STR": "HEMORRHOIDAL MAX STR"
            },
            "MCKESSON SUNMARK": {
              "SM HEMORRHOIDAL": "SM HEMORRHOIDAL"
            },
            "PERRIGO PHARMACEUTICALS": {
              "HEMORRHOIDAL": "HEMORRHOIDAL"
            },
            "PUBLIX SUPER MARKETS INC.": {
              "PX HEMORRHOIDAL": "PX HEMORRHOIDAL"
            },
            "RITE AID CORPORATION": {
              "RA HEMORRHOIDAL": "RA HEMORRHOIDAL"
            },
            "SHEFFIELD PHARMACEUTICALS": {
              "TRONOLANE": "TRONOLANE"
            },
            "TARGET": {
              "TGT HEMORRHOIDAL": "TGT HEMORRHOIDAL"
            },
            "TOPCO": {
              "HEMORRHOIDAL MAX ST/ALOE": "HEMORRHOIDAL MAX ST/ALOE"
            },
            "WAL-MART": {
              "EQ HEMORRHOIDAL MAX ST": "EQ HEMORRHOIDAL MAX ST"
            },
            "WALGREENS": {
              "HEMORRHOIDAL": "HEMORRHOIDAL",
              "HEMORRHOIDAL MAX ST/ALOE": "HEMORRHOIDAL MAX ST/ALOE"
            }
          },
          "Rectal Anesthetic/Steroids": {
            "A-S MEDICATION SOLUTIONS": {
              "HYDROCORT-PRAMOXINE (PERIANAL)": "HYDROCORT-PRAMOXINE (PERIANAL)"
            },
            "ACELLA PHARMACEUTICALS": {
              "ANA-LEX": "ANA-LEX",
              "LIDOCAINE-HYDROCORT (PERIANAL)": "LIDOCAINE-HYDROCORT (PERIANAL)",
              "LIDOCAINE-HYDROCORTISONE ACE": "LIDOCAINE-HYDROCORTISONE ACE"
            },
            "AVION PHARMACEUTICALS": {
              "ANA-LEX": "ANA-LEX"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "ANALPRAM-HC": "ANALPRAM-HC"
            },
            "KMM PHARMACEUTICALS": {
              "HYDROCORT-PRAMOXINE (PERIANAL)": "HYDROCORT-PRAMOXINE (PERIANAL)"
            },
            "MYLAN SPECIALTY L.P.": {
              "PROCTOFOAM HC": "PROCTOFOAM HC"
            },
            "NIVAGEN PHARMACEUTICALS": {
              "HYDROCORT-PRAMOXINE (PERIANAL)": "HYDROCORT-PRAMOXINE (PERIANAL)"
            },
            "PERRIGO PHARMACEUTICALS": {
              "HYDROCORT-PRAMOXINE (PERIANAL)": "HYDROCORT-PRAMOXINE (PERIANAL)",
              "HYDROCORTISONE ACE-PRAMOXINE": "HYDROCORTISONE ACE-PRAMOXINE"
            },
            "PURE TEK": {
              "LIDOCAINE-HYDROCORT (PERIANAL)": "LIDOCAINE-HYDROCORT (PERIANAL)",
              "LIDOCAINE-HYDROCORTISONE ACE": "LIDOCAINE-HYDROCORTISONE ACE",
              "LIDOCORT": "LIDOCORT"
            },
            "ROCHESTER PHARMACEUTICALS": {
              "PRAMCORT": "PRAMCORT"
            },
            "SEBELA PHARMACEUTICALS": {
              "ANALPRAM ADVANCED": "ANALPRAM ADVANCED",
              "ANALPRAM E": "ANALPRAM E",
              "ANALPRAM HC": "ANALPRAM HC",
              "ANALPRAM HC SINGLES": "ANALPRAM HC SINGLES",
              "ANALPRAM-HC": "ANALPRAM-HC"
            },
            "SETON PHARMACEUTICALS": {
              "LIDOCAINE-HYDROCORT (PERIANAL)": "LIDOCAINE-HYDROCORT (PERIANAL)",
              "LIDOCAINE-HYDROCORTISONE ACE": "LIDOCAINE-HYDROCORTISONE ACE"
            },
            "TRUPHARMA": {
              "HYDROCORT-PRAMOXINE (PERIANAL)": "HYDROCORT-PRAMOXINE (PERIANAL)"
            },
            "WOMEN'S CHOICE PHARMACEUTICALS": {
              "PROCORT": "PROCORT"
            }
          },
          "Rectal Combinations - Misc.": {
            "ACINO PRODUCTS": {
              "HEMORRHOIDAL": "HEMORRHOIDAL"
            },
            "AMERICAN SALES COMPANY": {
              "HEMORRHOIDAL": "HEMORRHOIDAL",
              "HEMORRHOIDAL COOLING": "HEMORRHOIDAL COOLING"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP HEMORRHOIDAL": "GNP HEMORRHOIDAL"
            },
            "BERGEN BRUNSWIG": {
              "GNP HEMORRHOIDAL COOLING": "GNP HEMORRHOIDAL COOLING"
            },
            "CHAIN DRUG CONSORTIUM": {
              "HEMORRHOIDAL": "HEMORRHOIDAL",
              "HEMORRHOIDAL COOLING": "HEMORRHOIDAL COOLING",
              "HEMORRHOIDAL RELIEF": "HEMORRHOIDAL RELIEF"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC HEMORRHOIDAL": "QC HEMORRHOIDAL"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS HEMORRHOIDAL": "CVS HEMORRHOIDAL"
            },
            "EQUALINE": {
              "EQL HEMORRHOIDAL": "EQL HEMORRHOIDAL",
              "EQL HEMORRHOIDAL COOLING": "EQL HEMORRHOIDAL COOLING"
            },
            "GEISS DESTIN & DUNN": {
              "GOODSENSE HEMORRHOIDAL": "GOODSENSE HEMORRHOIDAL"
            },
            "GERITREX CORPORATION": {
              "GRX HEMORRHOIDAL": "GRX HEMORRHOIDAL"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "PREPARATION H": "PREPARATION H",
              "PREPARATION H TOTABLES": "PREPARATION H TOTABLES"
            },
            "GRAXCELL PHARMACEUTICAL": {
              "HEMORRHOIDAL": "HEMORRHOIDAL"
            },
            "HANNAFORD BROTHERS": {
              "HEMORRHOIDAL": "HEMORRHOIDAL"
            },
            "HY-VEE": {
              "HEMORRHOIDAL": "HEMORRHOIDAL"
            },
            "LEADER BRAND PRODUCTS": {
              "HEMORRHOIDAL": "HEMORRHOIDAL",
              "HEMORRHOIDAL COOLING": "HEMORRHOIDAL COOLING"
            },
            "MAJOR PHARMACEUTICALS": {
              "ANU-MED": "ANU-MED",
              "HEMORRHOIDAL": "HEMORRHOIDAL",
              "MAJOR-PREP HEMORRHOIDAL": "MAJOR-PREP HEMORRHOIDAL"
            },
            "MCKESSON": {
              "HM HEMORRHOIDAL": "HM HEMORRHOIDAL"
            },
            "MCKESSON SUNMARK": {
              "SM HEMORRHOIDAL": "SM HEMORRHOIDAL",
              "SM HEMORRHOIDAL COOLING": "SM HEMORRHOIDAL COOLING"
            },
            "MEDLINE/DERMAL MANAGEMENT": {
              "CURAD ZINC OXIDE": "CURAD ZINC OXIDE"
            },
            "PERRIGO": {
              "GOODSENSE HEMORRHOIDAL": "GOODSENSE HEMORRHOIDAL"
            },
            "PERRIGO PHARMACEUTICALS": {
              "HEMORRHOIDAL": "HEMORRHOIDAL"
            },
            "PUBLIX SUPER MARKETS INC.": {
              "PX HEMORRHOIDAL": "PX HEMORRHOIDAL"
            },
            "REESE PHARMACEUTICAL": {
              "RECTACAINE": "RECTACAINE"
            },
            "RITE AID CORPORATION": {
              "RA HEMORRHOID RELIEF": "RA HEMORRHOID RELIEF",
              "RA HEMORRHOIDAL": "RA HEMORRHOIDAL",
              "RA HEMORRHOIDAL COOLING": "RA HEMORRHOIDAL COOLING"
            },
            "RUGBY LABORATORIES": {
              "HEMORRHOIDAL": "HEMORRHOIDAL"
            },
            "SELECT BRAND": {
              "SB HEMORRHOID": "SB HEMORRHOID"
            },
            "SHEFFIELD PHARMACEUTICALS": {
              "MEDICONE": "MEDICONE",
              "TRONOLANE": "TRONOLANE"
            },
            "TARGET": {
              "TGT HEMORRHOIDAL": "TGT HEMORRHOIDAL"
            },
            "TOPCO": {
              "HEMORRHOIDAL": "HEMORRHOIDAL"
            },
            "TRIFECTA PHARMACEUTICALS": {
              "HEMORRHOIDAL": "HEMORRHOIDAL",
              "HEMORRHOIDAL COOLING": "HEMORRHOIDAL COOLING"
            },
            "WAL-MART": {
              "EQ HEMORRHOIDAL": "EQ HEMORRHOIDAL"
            },
            "WALGREENS": {
              "HEMORRHOIDAL": "HEMORRHOIDAL",
              "HEMORRHOIDAL COOLING": "HEMORRHOIDAL COOLING"
            }
          }
        },
        "Rectal Local Anesthetics": {
          "Rectal Local Anesthetics": {
            "ALVOGEN": {
              "LIDOCAINE (ANORECTAL)": "LIDOCAINE (ANORECTAL)"
            },
            "COUNTY LINE PHARMACEUTICALS": {
              "LIDOCAINE (ANORECTAL)": "LIDOCAINE (ANORECTAL)"
            },
            "ESBA LABORATORIES": {
              "LUBRICAINE": "LUBRICAINE",
              "TOPICAINE 5": "TOPICAINE 5"
            },
            "FERNDALE LAB": {
              "LMX 5": "LMX 5",
              "RECTICARE": "RECTICARE"
            },
            "FOCUS HEALTH GROUP": {
              "ANECREAM5": "ANECREAM5"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "NUPERCAINAL": "NUPERCAINAL"
            },
            "INSIGHT PHARMACEUTICALS": {
              "AMERICAINE": "AMERICAINE"
            },
            "LEADER BRAND PRODUCTS": {
              "HEMORRHOIDAL RELIEF": "HEMORRHOIDAL RELIEF"
            },
            "LEADING PHARMA": {
              "RECTASMOOTHE": "RECTASMOOTHE"
            },
            "MAYNE PHARMA": {
              "PRAMOXINE HCL (PERIANAL)": "PRAMOXINE HCL (PERIANAL)"
            },
            "MYLAN SPECIALTY L.P.": {
              "PROCTOFOAM": "PROCTOFOAM"
            },
            "NIVAGEN PHARMACEUTICALS": {
              "LIDOCAINE (ANORECTAL)": "LIDOCAINE (ANORECTAL)"
            },
            "PATRIN PHARMA": {
              "LIDOCAINE (ANORECTAL)": "LIDOCAINE (ANORECTAL)"
            },
            "PERRIGO": {
              "DIBUCAINE (PERIANAL)": "DIBUCAINE (PERIANAL)"
            },
            "RITE AID CORPORATION": {
              "RA ANORECTAL": "RA ANORECTAL"
            },
            "STRATUS PHARMACEUTICALS": {
              "LIDOCAINE (ANORECTAL)": "LIDOCAINE (ANORECTAL)"
            },
            "TRIMARC LABORATORIES": {
              "LC-5 LIDOCAINE": "LC-5 LIDOCAINE"
            }
          }
        },
        "Rectal Products - Misc.": {
          "Rectal Cleanser": {
            "FLEET PHARMACEUTICALS": {
              "FLEET NATURALS CLEANSING ENEMA": "FLEET NATURALS CLEANSING ENEMA"
            }
          },
          "Rectal Products - Misc.": {
            "CHAIN DRUG CONSORTIUM": {
              "HEMORRHOIDAL": "HEMORRHOIDAL"
            }
          }
        },
        "Rectal Protectants-Emollients": {
          "Rectal Protectants-Emollients": {
            "RESICAL INC": {
              "CALMOL-4": "CALMOL-4"
            }
          }
        },
        "Rectal Steroids": {
          "Rectal Steroids": {
            "A-S MEDICATION SOLUTIONS": {
              "HEMORRHOIDAL-HC": "HEMORRHOIDAL-HC",
              "HYDROCORTISONE (PERIANAL)": "HYDROCORTISONE (PERIANAL)",
              "HYDROCORTISONE ACETATE": "HYDROCORTISONE ACETATE",
              "PROCTO-MED HC": "PROCTO-MED HC"
            },
            "AIDAREX PHARMACEUTICALS": {
              "HYDROCORTISONE ACETATE": "HYDROCORTISONE ACETATE",
              "PROCTOZONE-HC": "PROCTOZONE-HC"
            },
            "ANI  PHARMACEUTICALS": {
              "HYDROCORTISONE (PERIANAL)": "HYDROCORTISONE (PERIANAL)"
            },
            "AVPAK": {
              "HYDROCORTISONE ACETATE": "HYDROCORTISONE ACETATE"
            },
            "BAUSCH HEALTH": {
              "ANUSOL-HC": "ANUSOL-HC",
              "PROCTOCORT": "PROCTOCORT"
            },
            "CAMERON PHARMACEUTICALS": {
              "HYDROCORTISONE ACETATE": "HYDROCORTISONE ACETATE"
            },
            "COSETTE PHARMACEUTICALS": {
              "ANUCORT-HC": "ANUCORT-HC"
            },
            "GRAXCELL PHARMACEUTICAL": {
              "HYDROCORTISONE ACETATE": "HYDROCORTISONE ACETATE"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "HYDROCORTISONE ACETATE": "HYDROCORTISONE ACETATE"
            },
            "LASER PHARMACEUTICALS": {
              "HEMMOREX-HC": "HEMMOREX-HC"
            },
            "LEADING PHARMA": {
              "PROCTO-MED HC": "PROCTO-MED HC"
            },
            "LIBERTY PHARMACEUTICAL": {
              "HYDROCORTISONE ACETATE": "HYDROCORTISONE ACETATE"
            },
            "NEOMED PHARMACEUTICAL": {
              "HYDROCORTISONE ACETATE": "HYDROCORTISONE ACETATE"
            },
            "NIVAGEN PHARMACEUTICALS": {
              "HYDROCORTISONE ACETATE": "HYDROCORTISONE ACETATE"
            },
            "NUCARE PHARMACEUTICALS": {
              "PROCTOCARE-HC": "PROCTOCARE-HC"
            },
            "PERRIGO": {
              "HYDROCORTISONE ACETATE": "HYDROCORTISONE ACETATE"
            },
            "PHARMEDIX": {
              "HYDROCORTISONE (PERIANAL)": "HYDROCORTISONE (PERIANAL)"
            },
            "PREFERRED PHARMACEUTICALS": {
              "HEMMOREX-HC": "HEMMOREX-HC"
            },
            "QUALITY CARE": {
              "ANUCORT-HC": "ANUCORT-HC"
            },
            "RANBAXY LABORATORIES": {
              "PROCTOSOL HC": "PROCTOSOL HC"
            },
            "RISING PHARMACEUTICALS": {
              "PROCTO-PAK": "PROCTO-PAK",
              "PROCTOZONE-HC": "PROCTOZONE-HC"
            },
            "SYNTENZA PHARMACEUTICALS": {
              "HYDROCORTISONE ACETATE": "HYDROCORTISONE ACETATE"
            },
            "TORRENT PHARMACEUTICALS": {
              "HYDROCORTISONE ACETATE": "HYDROCORTISONE ACETATE"
            },
            "WESTMINSTER PHARMACEUTICALS": {
              "HYDROCORTISONE ACETATE": "HYDROCORTISONE ACETATE"
            }
          }
        },
        "Vasodilating Agents": {
          "Nitrate Vasodilating Agents": {
            "ALLERGAN": {
              "RECTIV": "RECTIV"
            }
          }
        }
      },
      "ANTACIDS": {
        "Antacid Combinations": {
          "Antacid & Simethicone": {
            "A-S MEDICATION SOLUTIONS": {
              "ALUMINUM-MAGNESIUM-SIMETHICONE": "ALUMINUM-MAGNESIUM-SIMETHICONE",
              "MI-ACID MAXIMUM STRENGTH": "MI-ACID MAXIMUM STRENGTH",
              "MINTOX": "MINTOX",
              "MYLANTA": "MYLANTA"
            },
            "AIDAREX PHARMACEUTICALS": {
              "MINTOX": "MINTOX"
            },
            "ALTAIRE": {
              "ANTACID I": "ANTACID I",
              "ANTACID III": "ANTACID III"
            },
            "AMERICAN SALES COMPANY": {
              "ANTACID": "ANTACID",
              "ANTACID FAST ACTING": "ANTACID FAST ACTING"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP ANTACID & ANTI-GAS": "GNP ANTACID & ANTI-GAS",
              "GNP ANTACID REGULAR STRENGTH": "GNP ANTACID REGULAR STRENGTH"
            },
            "ATLANTIC BIOLOGICALS": {
              "MINTOX": "MINTOX",
              "MINTOX MAXIMUM STRENGTH": "MINTOX MAXIMUM STRENGTH"
            },
            "AUBURN PHARMACEUTICAL": {
              "ANTACID": "ANTACID",
              "MILANTEX": "MILANTEX",
              "MILANTEX EXTRA STRENGTH": "MILANTEX EXTRA STRENGTH"
            },
            "AURORA HEALTHCARE": {
              "ANTACID ANTI-GAS": "ANTACID ANTI-GAS",
              "ANTACID EXTRA STRENGTH": "ANTACID EXTRA STRENGTH",
              "ANTACID FAST RELIEF": "ANTACID FAST RELIEF"
            },
            "BAYER CONSUMER": {
              "ALKA-SELTZER HEARTBURN + GAS": "ALKA-SELTZER HEARTBURN + GAS",
              "DI-GEL": "DI-GEL"
            },
            "BELMORA": {
              "FLANAX HEARTBURN RELIEF": "FLANAX HEARTBURN RELIEF"
            },
            "BERGEN BRUNSWIG": {
              "GNP ANTACID ANTI-GAS": "GNP ANTACID ANTI-GAS",
              "GNP ANTACID MAXIMUM STRENGTH": "GNP ANTACID MAXIMUM STRENGTH",
              "GNP MASANTI MAXIMUM STRENGTH": "GNP MASANTI MAXIMUM STRENGTH",
              "GNP MASANTI REGULAR STRENGTH": "GNP MASANTI REGULAR STRENGTH"
            },
            "BRACCO DIAGNOSTICS": {
              "E-Z-GAS II": "E-Z-GAS II"
            },
            "CHAIN DRUG CONSORTIUM": {
              "ANTACID": "ANTACID",
              "ANTACID ANTI-GAS FAST ACTING": "ANTACID ANTI-GAS FAST ACTING",
              "ANTACID MAXIMUM STRENGTH": "ANTACID MAXIMUM STRENGTH",
              "FAST ACTING ANTACID/ANTI-GAS": "FAST ACTING ANTACID/ANTI-GAS"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC ANTACID": "QC ANTACID",
              "QC ANTACID MULTI-SYMPTOM": "QC ANTACID MULTI-SYMPTOM",
              "QC ANTACID/ANTI-GAS": "QC ANTACID/ANTI-GAS"
            },
            "CHATTEM INC": {
              "ROLAIDS ADVANCED": "ROLAIDS ADVANCED"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS ANTACID": "CVS ANTACID",
              "CVS ANTACID & ANTI-GAS": "CVS ANTACID & ANTI-GAS",
              "CVS ANTACID FAST RELIEF": "CVS ANTACID FAST RELIEF",
              "CVS ANTACID PLUS ANTIGAS": "CVS ANTACID PLUS ANTIGAS",
              "CVS ANTACID/ANTI-GAS": "CVS ANTACID/ANTI-GAS",
              "CVS ANTACID/SIMETHICONE": "CVS ANTACID/SIMETHICONE"
            },
            "EQUALINE": {
              "EQL ANTACID ADVANCED MAX ST": "EQL ANTACID ADVANCED MAX ST",
              "EQL ANTACID MAXIMUM STRENGTH": "EQL ANTACID MAXIMUM STRENGTH",
              "EQL ANTACID/ANTI-GAS": "EQL ANTACID/ANTI-GAS"
            },
            "GEISS DESTIN & DUNN": {
              "ANTACID PLUS ANTI-GAS RELIEF": "ANTACID PLUS ANTI-GAS RELIEF"
            },
            "GERI-CARE": {
              "GERI-LANTA": "GERI-LANTA"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "GAS-X WITH MAALOX EX ST": "GAS-X WITH MAALOX EX ST",
              "MAALOX ADVANCED MAX ST": "MAALOX ADVANCED MAX ST",
              "MAALOX JUNIOR PLUS ANTIGAS": "MAALOX JUNIOR PLUS ANTIGAS",
              "MAALOX MAX": "MAALOX MAX",
              "MAALOX MULTI SYMPTOM MAX ST": "MAALOX MULTI SYMPTOM MAX ST",
              "TUMS GAS RELIEF CHEWY BITES": "TUMS GAS RELIEF CHEWY BITES"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "ALUMINUM-MAGNESIUM-SIMETHICONE": "ALUMINUM-MAGNESIUM-SIMETHICONE"
            },
            "HY-VEE": {
              "ANTACID ANTI-GAS MAX STRENGTH": "ANTACID ANTI-GAS MAX STRENGTH",
              "HYVEE ADVANCED ANTACID": "HYVEE ADVANCED ANTACID"
            },
            "INFIRST HEALTHCARE": {
              "MYLANTA MAXIMUM STRENGTH": "MYLANTA MAXIMUM STRENGTH",
              "MYLANTA TONIGHT": "MYLANTA TONIGHT"
            },
            "KROGER COMPANY": {
              "ANTACID": "ANTACID",
              "ANTACID ANTI-GAS REG STRENGTH": "ANTACID ANTI-GAS REG STRENGTH"
            },
            "LEADER BRAND PRODUCTS": {
              "ANTACID": "ANTACID",
              "ANTACID FAST ACTING": "ANTACID FAST ACTING",
              "ANTACID MAXIMUM STRENGTH": "ANTACID MAXIMUM STRENGTH",
              "ANTACID REGULAR STRENGTH": "ANTACID REGULAR STRENGTH"
            },
            "MAJOR PHARMACEUTICALS": {
              "ALUM & MAG HYDROXIDE-SIMETH": "ALUM & MAG HYDROXIDE-SIMETH",
              "MI-ACID": "MI-ACID",
              "MI-ACID MAXIMUM STRENGTH": "MI-ACID MAXIMUM STRENGTH",
              "MINTOX": "MINTOX",
              "MINTOX MAXIMUM STRENGTH": "MINTOX MAXIMUM STRENGTH",
              "MINTOX PLUS": "MINTOX PLUS",
              "MINTOX REGULAR STRENGTH": "MINTOX REGULAR STRENGTH"
            },
            "MCKESSON": {
              "HM ADVANCED ANTACID MAX ST": "HM ADVANCED ANTACID MAX ST",
              "HM ANTACID": "HM ANTACID",
              "HM ANTACID ANTI-GAS EX ST": "HM ANTACID ANTI-GAS EX ST",
              "HM ANTACID/ANTIGAS": "HM ANTACID/ANTIGAS"
            },
            "MCKESSON SUNMARK": {
              "SM ANTACID ADVANCED": "SM ANTACID ADVANCED",
              "SM ANTACID ADVANCED MAX ST": "SM ANTACID ADVANCED MAX ST",
              "SM ANTACID ANTI-GAS": "SM ANTACID ANTI-GAS",
              "SM ANTACID ANTI-GAS EX ST": "SM ANTACID ANTI-GAS EX ST",
              "SM ANTACID MAXIMUM STRENGTH": "SM ANTACID MAXIMUM STRENGTH",
              "SM ANTACID/ANTIGAS": "SM ANTACID/ANTIGAS"
            },
            "MEDICINE SHOPPE": {
              "ANTACID/SIMETHICONE DS": "ANTACID/SIMETHICONE DS"
            },
            "MEDIQUE PRODUCTS": {
              "ALAMAG PLUS": "ALAMAG PLUS"
            },
            "MEIJER": {
              "ANTACID ADVANCED": "ANTACID ADVANCED",
              "MEIJER ANTACID": "MEIJER ANTACID",
              "MEIJER ANTACID ANTI-GAS": "MEIJER ANTACID ANTI-GAS"
            },
            "PERRIGO": {
              "ANTACID": "ANTACID",
              "ANTACID PLUS ANTI-GAS RELIEF": "ANTACID PLUS ANTI-GAS RELIEF"
            },
            "PERRIGO PHARMACEUTICALS": {
              "ANTACID ANTI-GAS MAX STRENGTH": "ANTACID ANTI-GAS MAX STRENGTH"
            },
            "PHARMACEUTICAL ASSOCIATES": {
              "MAG-AL PLUS": "MAG-AL PLUS",
              "MAG-AL PLUS XS": "MAG-AL PLUS XS"
            },
            "PREFERRED PHARMACEUTICALS": {
              "GNP ANTACID & ANTI-GAS": "GNP ANTACID & ANTI-GAS"
            },
            "PUBLIX SUPER MARKETS INC.": {
              "PX ANTACID MAXIMUM STRENGTH": "PX ANTACID MAXIMUM STRENGTH",
              "PX ANTACID REGULAR STRENGTH": "PX ANTACID REGULAR STRENGTH"
            },
            "RITE AID CORPORATION": {
              "RA ANTACID/ANTI-GAS": "RA ANTACID/ANTI-GAS",
              "RA ANTACID/ANTI-GAS MAX ST": "RA ANTACID/ANTI-GAS MAX ST",
              "RA ANTACID/GAS RELIEF MAX ST": "RA ANTACID/GAS RELIEF MAX ST",
              "RA LIQUID ANTACID": "RA LIQUID ANTACID"
            },
            "RUGBY LABORATORIES": {
              "ALMACONE": "ALMACONE",
              "ALMACONE DOUBLE STRENGTH": "ALMACONE DOUBLE STRENGTH",
              "ANTACID REGULAR STRENGTH": "ANTACID REGULAR STRENGTH",
              "RULOX": "RULOX"
            },
            "SAFECOR HEALTH": {
              "ANTACID REGULAR STRENGTH": "ANTACID REGULAR STRENGTH"
            },
            "SELECT BRAND": {
              "SB ANTACID ANTI-GAS": "SB ANTACID ANTI-GAS",
              "SB ANTACID ANTI-GAS DOUBLE ST": "SB ANTACID ANTI-GAS DOUBLE ST",
              "SB ANTACID/ANTIGAS": "SB ANTACID/ANTIGAS"
            },
            "STRATUS PHARMACEUTICALS": {
              "ANTACID & ANTIGAS": "ANTACID & ANTIGAS"
            },
            "TARGET": {
              "TGT ANTACID ANTI-GAS": "TGT ANTACID ANTI-GAS"
            },
            "TOPCO": {
              "ANTACID FAST RELIEF": "ANTACID FAST RELIEF",
              "ANTACID MAXIMUM STRENGTH": "ANTACID MAXIMUM STRENGTH",
              "ANTACID PLUS ANTI-GAS FAST ACT": "ANTACID PLUS ANTI-GAS FAST ACT",
              "FAST ACTING ANTACID/ANTI-GAS": "FAST ACTING ANTACID/ANTI-GAS"
            },
            "VISTAPHARM INC.": {
              "ALUM & MAG HYDROXIDE-SIMETH": "ALUM & MAG HYDROXIDE-SIMETH",
              "ALUMINA-MAGNESIA-SIMETHICONE": "ALUMINA-MAGNESIA-SIMETHICONE"
            },
            "WAL-MART": {
              "EQ ANTACID": "EQ ANTACID",
              "EQ ANTACID ANTIGAS MULTI-SYMPT": "EQ ANTACID ANTIGAS MULTI-SYMPT",
              "EQ ANTACID MAXIMUM STRENGTH": "EQ ANTACID MAXIMUM STRENGTH",
              "EQ ANTACID/ANTI-GAS": "EQ ANTACID/ANTI-GAS",
              "GERI-MOX": "GERI-MOX"
            },
            "WALGREENS": {
              "ANTACID": "ANTACID",
              "ANTACID & ANTIGAS": "ANTACID & ANTIGAS",
              "ANTACID ANTI-GAS": "ANTACID ANTI-GAS",
              "ANTACID ANTI-GAS MAX STRENGTH": "ANTACID ANTI-GAS MAX STRENGTH",
              "ANTACID LIQUID": "ANTACID LIQUID",
              "ANTACID M": "ANTACID M",
              "ANTACID MULTI-SYMPTOM": "ANTACID MULTI-SYMPTOM",
              "COMFORT GEL": "COMFORT GEL",
              "COMFORT GEL ANTACID & ANTI-GAS": "COMFORT GEL ANTACID & ANTI-GAS",
              "COMFORT GEL ANTACID ANTI-GAS": "COMFORT GEL ANTACID ANTI-GAS"
            },
            "WELLSPRING PHARMACEUTICAL CORP": {
              "GELUSIL": "GELUSIL"
            }
          },
          "Antacid Combinations": {
            "AMERISOURCE BERGEN DRUGS": {
              "GNP ANTACID": "GNP ANTACID",
              "GNP ANTACID EXTRA STRENGTH": "GNP ANTACID EXTRA STRENGTH"
            },
            "BAYER CONSUMER": {
              "ALKA-SELTZER HEARTBURN": "ALKA-SELTZER HEARTBURN"
            },
            "BERGEN BRUNSWIG": {
              "GNP FOAMING ANTACID": "GNP FOAMING ANTACID"
            },
            "CHAIN DRUG CONSORTIUM": {
              "ANTACID": "ANTACID",
              "ANTACID DOUBLE STRENGTH": "ANTACID DOUBLE STRENGTH",
              "CALCIUM RICH SUPREME ANTACID": "CALCIUM RICH SUPREME ANTACID",
              "FOAMING ANTACID EX ST": "FOAMING ANTACID EX ST",
              "HEARTBURN ANTACID EX ST": "HEARTBURN ANTACID EX ST"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC HEARTBURN ANTACID": "QC HEARTBURN ANTACID"
            },
            "CHATTEM INC": {
              "ROLAIDS": "ROLAIDS",
              "ROLAIDS ANTACID ULTRA STRENGTH": "ROLAIDS ANTACID ULTRA STRENGTH",
              "ROLAIDS EXTRA STRENGTH": "ROLAIDS EXTRA STRENGTH"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS ANTACID SUPREME": "CVS ANTACID SUPREME",
              "CVS HEARTBURN RELIEF": "CVS HEARTBURN RELIEF",
              "CVS HEARTBURN RELIEF EX ST": "CVS HEARTBURN RELIEF EX ST"
            },
            "EQUALINE": {
              "EQL ANTACID EXTRA STRENGTH": "EQL ANTACID EXTRA STRENGTH"
            },
            "GERI-CARE": {
              "GAVIS-CARE": "GAVIS-CARE",
              "GERI-LANTA SUPREME": "GERI-LANTA SUPREME"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "GAVISCON": "GAVISCON",
              "GAVISCON EXTRA RELIEF FORMULA": "GAVISCON EXTRA RELIEF FORMULA",
              "GAVISCON EXTRA STRENGTH": "GAVISCON EXTRA STRENGTH"
            },
            "HUMCO": {
              "DEWEES CARMINATIVE": "DEWEES CARMINATIVE"
            },
            "LEADER BRAND PRODUCTS": {
              "ANTACID EXTRA STRENGTH": "ANTACID EXTRA STRENGTH",
              "HEARTBURN RELIEF EX ST": "HEARTBURN RELIEF EX ST"
            },
            "MAJOR PHARMACEUTICALS": {
              "ACID GONE": "ACID GONE",
              "MI-ACID": "MI-ACID"
            },
            "MCKESSON SUNMARK": {
              "SM ANTACID EXTRA STRENGTH": "SM ANTACID EXTRA STRENGTH",
              "SM FOAMING ANTACID": "SM FOAMING ANTACID"
            },
            "PHARMACEUTICAL ASSOCIATES": {
              "MAG-AL": "MAG-AL"
            },
            "SHEFFIELD PHARMACEUTICALS": {
              "CITROCARBONATE": "CITROCARBONATE"
            },
            "TARGET": {
              "TGT ANTACID EXTRA STRENGTH": "TGT ANTACID EXTRA STRENGTH"
            },
            "WAL-MART": {
              "EQ ANTACID EXTRA STRENGTH": "EQ ANTACID EXTRA STRENGTH"
            },
            "WALGREENS": {
              "ANTACID": "ANTACID",
              "ANTACID EXTRA STRENGTH": "ANTACID EXTRA STRENGTH",
              "ANTACID ULTRA STRENGTH": "ANTACID ULTRA STRENGTH",
              "HEARTBURN ANTACID EX ST": "HEARTBURN ANTACID EX ST"
            }
          }
        },
        "Antacids - Aluminum Salts": {
          "Antacids - Aluminum Salts": {
            "ASAFI PHARMACEUTICAL": {
              "ALUMINUM HYDROXIDE GEL": "ALUMINUM HYDROXIDE GEL"
            },
            "ATLANTIC BIOLOGICALS": {
              "ALUMINUM HYDROXIDE GEL": "ALUMINUM HYDROXIDE GEL"
            },
            "RUGBY LABORATORIES": {
              "ALUMINUM HYDROXIDE GEL": "ALUMINUM HYDROXIDE GEL"
            }
          }
        },
        "Antacids - Bicarbonate": {
          "Antacids - Bicarbonate": {
            "A-S MEDICATION SOLUTIONS": {
              "SODIUM BICARBONATE": "SODIUM BICARBONATE"
            },
            "APHENA PHARMA SOLUTIONS": {
              "SODIUM BICARBONATE": "SODIUM BICARBONATE"
            },
            "CHAIN DRUG CONSORTIUM": {
              "SODIUM BICARBONATE": "SODIUM BICARBONATE"
            },
            "CITRAGEN PHARMACEUTICALS": {
              "SODIUM BICARBONATE": "SODIUM BICARBONATE"
            },
            "CONSOLIDATED MIDLAND CORP": {
              "SODIUM BICARBONATE": "SODIUM BICARBONATE"
            },
            "GENDOSE PHARMACEUTICALS": {
              "SODIUM BICARBONATE": "SODIUM BICARBONATE"
            },
            "GERI-CARE": {
              "SODIUM BICARBONATE": "SODIUM BICARBONATE"
            },
            "HOSPAK UNIT DOSE PRODUCTS": {
              "SODIUM BICARBONATE": "SODIUM BICARBONATE"
            },
            "HUMCO": {
              "SODIUM BICARBONATE": "SODIUM BICARBONATE"
            },
            "KALCHEM INTERNATIONAL": {
              "SODIUM BICARBONATE": "SODIUM BICARBONATE"
            },
            "LETCO MEDICAL": {
              "SODIUM BICARBONATE": "SODIUM BICARBONATE"
            },
            "MARLEX PHARMACEUTICALS": {
              "SODIUM BICARBONATE": "SODIUM BICARBONATE"
            },
            "RELIABLE 1 LABS": {
              "SODIUM BICARBONATE": "SODIUM BICARBONATE"
            },
            "RICHMOND PHARMACEUTICALS": {
              "SODIUM BICARBONATE": "SODIUM BICARBONATE"
            },
            "RISING PHARMACEUTICALS": {
              "SODIUM BICARBONATE": "SODIUM BICARBONATE"
            },
            "RUGBY LABORATORIES": {
              "SODIUM BICARBONATE": "SODIUM BICARBONATE"
            },
            "SPECTRUM": {
              "SODIUM BICARBONATE": "SODIUM BICARBONATE"
            }
          },
          "Antacids - Bicarbonate Combinations": {
            "BAYER CONSUMER": {
              "ALKA-SELTZER GOLD": "ALKA-SELTZER GOLD"
            },
            "VITALINE": {
              "ALKA-AID": "ALKA-AID"
            }
          }
        },
        "Antacids - Calcium Salts": {
          "Antacids - Calcium Salts": {
            "3M CONSUMER HEALTH CARE": {
              "TITRALAC": "TITRALAC"
            },
            "A-S MEDICATION SOLUTIONS": {
              "CALCIUM ANTACID": "CALCIUM ANTACID",
              "CALCIUM CARBONATE ANTACID": "CALCIUM CARBONATE ANTACID"
            },
            "AMERICAN HEALTH PACKAGING": {
              "ANTACID CALCIUM": "ANTACID CALCIUM",
              "ANTACID CALCIUM EXTRA STRENGTH": "ANTACID CALCIUM EXTRA STRENGTH"
            },
            "AMERICAN SALES COMPANY": {
              "CALCIUM ANTACID": "CALCIUM ANTACID",
              "CALCIUM ANTACID EXTRA STRENGTH": "CALCIUM ANTACID EXTRA STRENGTH",
              "CALCIUM ANTACID ULTRA STRENGTH": "CALCIUM ANTACID ULTRA STRENGTH"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP ANTACID EXTRA STRENGTH": "GNP ANTACID EXTRA STRENGTH"
            },
            "APHENA PHARMA SOLUTIONS": {
              "CALCIUM CARBONATE ANTACID": "CALCIUM CARBONATE ANTACID"
            },
            "ATLANTIC BIOLOGICALS": {
              "CALCIUM CARBONATE ANTACID": "CALCIUM CARBONATE ANTACID"
            },
            "AURORA HEALTHCARE": {
              "ANTACID": "ANTACID"
            },
            "BERGEN BRUNSWIG": {
              "GNP ANTACID": "GNP ANTACID",
              "GNP ANTACID EXTRA STRENGTH": "GNP ANTACID EXTRA STRENGTH",
              "GNP ANTACID ULTRA STRENGTH": "GNP ANTACID ULTRA STRENGTH"
            },
            "CHAIN DRUG CONSORTIUM": {
              "CALCIUM ANTACID": "CALCIUM ANTACID",
              "CALCIUM ANTACID EXTRA STRENGTH": "CALCIUM ANTACID EXTRA STRENGTH",
              "CALCIUM ANTACID ULTRA": "CALCIUM ANTACID ULTRA",
              "FAST DISSOLVE ANTACID": "FAST DISSOLVE ANTACID",
              "SMOOTH ANTACID EXTRA STRENGTH": "SMOOTH ANTACID EXTRA STRENGTH"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "CALCIUM ANTACID": "CALCIUM ANTACID",
              "QC ANTACID": "QC ANTACID",
              "QC ANTACID EXTRA STRENGTH": "QC ANTACID EXTRA STRENGTH",
              "QC ANTACID ULTRA STRENGTH": "QC ANTACID ULTRA STRENGTH"
            },
            "COSTCO WHOLESALE": {
              "KLS ANTACID": "KLS ANTACID"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS ANTACID CHILDRENS": "CVS ANTACID CHILDRENS",
              "CVS ANTACID EXTRA": "CVS ANTACID EXTRA",
              "CVS ANTACID EXTRA STRENGTH": "CVS ANTACID EXTRA STRENGTH",
              "CVS ANTACID KIDS": "CVS ANTACID KIDS",
              "CVS ANTACID MAXIMUM STRENGTH": "CVS ANTACID MAXIMUM STRENGTH",
              "CVS ANTACID SOFT CHEWS ULTR ST": "CVS ANTACID SOFT CHEWS ULTR ST",
              "CVS ANTACID ULTRA STRENGTH": "CVS ANTACID ULTRA STRENGTH",
              "CVS CHEWY NOT CHALKY FLAVOR": "CVS CHEWY NOT CHALKY FLAVOR",
              "CVS SMOOTH ANTACID EXTRA ST": "CVS SMOOTH ANTACID EXTRA ST"
            },
            "EQUALINE": {
              "EQ ANTACID EXTRA STRENGTH": "EQ ANTACID EXTRA STRENGTH",
              "EQL ANTACID": "EQL ANTACID",
              "EQL ANTACID EXTRA STRENGTH": "EQL ANTACID EXTRA STRENGTH",
              "EQL ANTACID ULTRA STRENGTH": "EQL ANTACID ULTRA STRENGTH"
            },
            "GEISS DESTIN & DUNN": {
              "GOODSENSE ANTACID": "GOODSENSE ANTACID"
            },
            "GERI-CARE": {
              "CALCIUM CARBONATE": "CALCIUM CARBONATE",
              "CALCIUM CARBONATE ANTACID": "CALCIUM CARBONATE ANTACID"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "MAALOX": "MAALOX",
              "MAALOX CHILDRENS": "MAALOX CHILDRENS",
              "TUMS": "TUMS",
              "TUMS CHEWY BITES": "TUMS CHEWY BITES",
              "TUMS CHEWY DELIGHTS": "TUMS CHEWY DELIGHTS",
              "TUMS E-X 750": "TUMS E-X 750",
              "TUMS EXTRA STRENGTH 750": "TUMS EXTRA STRENGTH 750",
              "TUMS FRESHERS": "TUMS FRESHERS",
              "TUMS KIDS": "TUMS KIDS",
              "TUMS LASTING EFFECTS": "TUMS LASTING EFFECTS",
              "TUMS SMOOTHIES": "TUMS SMOOTHIES",
              "TUMS ULTRA 1000": "TUMS ULTRA 1000"
            },
            "HANNAFORD BROTHERS": {
              "CALCIUM ANTACID": "CALCIUM ANTACID",
              "CALCIUM ANTACID EXTRA STRENGTH": "CALCIUM ANTACID EXTRA STRENGTH"
            },
            "HIKMA": {
              "CALCIUM CARBONATE ANTACID": "CALCIUM CARBONATE ANTACID"
            },
            "HOSPAK UNIT DOSE PRODUCTS": {
              "CALCIUM CARBONATE ANTACID": "CALCIUM CARBONATE ANTACID"
            },
            "HY-VEE": {
              "ANTACID": "ANTACID"
            },
            "LEADER BRAND PRODUCTS": {
              "ANTACID": "ANTACID",
              "ANTACID EXTRA STRENGTH": "ANTACID EXTRA STRENGTH",
              "ANTACID REGULAR STRENGTH": "ANTACID REGULAR STRENGTH",
              "ANTACID ULTRA STRENGTH": "ANTACID ULTRA STRENGTH",
              "CALCIUM ANTACID ULTRA": "CALCIUM ANTACID ULTRA"
            },
            "LIBERTY PHARMACEUTICAL": {
              "CALCIUM CARBONATE ANTACID": "CALCIUM CARBONATE ANTACID"
            },
            "MAJOR PHARMACEUTICALS": {
              "CALCIUM ANTACID": "CALCIUM ANTACID",
              "CALCIUM ANTACID EXTRA STRENGTH": "CALCIUM ANTACID EXTRA STRENGTH",
              "CALCIUM CARBONATE ANTACID": "CALCIUM CARBONATE ANTACID"
            },
            "MATERNAL SCIENCE": {
              "HEALTHY MAMA TAME THE FLAME": "HEALTHY MAMA TAME THE FLAME"
            },
            "MCKESSON": {
              "HM CALCIUM ANTACID": "HM CALCIUM ANTACID",
              "HM CALCIUM ANTACID EX ST": "HM CALCIUM ANTACID EX ST",
              "HM CALCIUM ANTACID ULTRA ST": "HM CALCIUM ANTACID ULTRA ST"
            },
            "MCKESSON SUNMARK": {
              "SM ANTACID": "SM ANTACID",
              "SM CALCIUM ANTACID": "SM CALCIUM ANTACID",
              "SM CALCIUM ANTACID EX ST": "SM CALCIUM ANTACID EX ST",
              "SM CALCIUM ANTACID ULTRA ST": "SM CALCIUM ANTACID ULTRA ST",
              "SM SMOOTH ANTACID EX ST": "SM SMOOTH ANTACID EX ST"
            },
            "MCNEIL CONSUMER PHARMACEUTICAL": {
              "CHILDRENS MYLANTA": "CHILDRENS MYLANTA"
            },
            "MEDICINE SHOPPE": {
              "ANTACID": "ANTACID"
            },
            "P & G HEALTH": {
              "CHILDRENS PEPTO": "CHILDRENS PEPTO"
            },
            "PERRIGO PHARMACEUTICALS": {
              "ANTACID": "ANTACID",
              "ANTACID EXTRA STRENGTH": "ANTACID EXTRA STRENGTH",
              "CALCIUM ANTACID": "CALCIUM ANTACID",
              "CALCIUM ANTACID EXTRA STRENGTH": "CALCIUM ANTACID EXTRA STRENGTH",
              "CALCIUM ANTACID ULTRA MAX ST": "CALCIUM ANTACID ULTRA MAX ST"
            },
            "PHARMACEUTICAL ASSOCIATES": {
              "CALCIUM CARBONATE ANTACID": "CALCIUM CARBONATE ANTACID"
            },
            "PLUS PHARMA": {
              "CALCIUM ANTACID": "CALCIUM ANTACID"
            },
            "PUBLIX SUPER MARKETS INC.": {
              "PX ANTACID EXTRA STRENGTH": "PX ANTACID EXTRA STRENGTH",
              "PX ANTACID MAXIMUM STRENGTH": "PX ANTACID MAXIMUM STRENGTH",
              "PX CALCIUM ANTACID": "PX CALCIUM ANTACID"
            },
            "RELIABLE 1 LABS": {
              "CALCIUM CARBONATE ANTACID": "CALCIUM CARBONATE ANTACID"
            },
            "RICHMOND PHARMACEUTICALS": {
              "ANTACID CALCIUM RICH": "ANTACID CALCIUM RICH"
            },
            "RITE AID CORPORATION": {
              "RA ANTACID": "RA ANTACID",
              "RA ANTACID EXTRA STRENGTH": "RA ANTACID EXTRA STRENGTH",
              "RA ANTACID ULTRA STRENGTH": "RA ANTACID ULTRA STRENGTH",
              "RA SMOOTH ANTACID EX ST": "RA SMOOTH ANTACID EX ST",
              "RA STOMACH RELIEF KIDS": "RA STOMACH RELIEF KIDS"
            },
            "ROMAVISION INVESTING": {
              "TC MAX": "TC MAX"
            },
            "RUGBY LABORATORIES": {
              "ANTACID CALCIUM": "ANTACID CALCIUM",
              "ANTACID CALCIUM EXTRA STRENGTH": "ANTACID CALCIUM EXTRA STRENGTH",
              "ANTACID EXTRA STRENGTH": "ANTACID EXTRA STRENGTH",
              "CAL-GEST ANTACID": "CAL-GEST ANTACID",
              "CALCIUM ANTACID EXTRA STRENGTH": "CALCIUM ANTACID EXTRA STRENGTH",
              "CALCIUM CARBONATE ANTACID": "CALCIUM CARBONATE ANTACID"
            },
            "SAFECOR HEALTH": {
              "CALCIUM ANTACID": "CALCIUM ANTACID",
              "CALCIUM ANTACID EXTRA STRENGTH": "CALCIUM ANTACID EXTRA STRENGTH"
            },
            "SELECT BRAND": {
              "SB ANTACID": "SB ANTACID",
              "SB ANTACID EXTRA STRENGTH": "SB ANTACID EXTRA STRENGTH"
            },
            "TARGET": {
              "TGT ANTACID": "TGT ANTACID",
              "TGT ANTACID EXTRA STRENGTH": "TGT ANTACID EXTRA STRENGTH"
            },
            "TOPCO": {
              "ANTACID CALCIUM": "ANTACID CALCIUM",
              "ANTACID CALCIUM EXTRA STRENGTH": "ANTACID CALCIUM EXTRA STRENGTH",
              "CALCIUM ANTACID": "CALCIUM ANTACID",
              "CALCIUM ANTACID EXTRA STRENGTH": "CALCIUM ANTACID EXTRA STRENGTH",
              "CALCIUM ANTACID ULTRA STRENGTH": "CALCIUM ANTACID ULTRA STRENGTH"
            },
            "WAL-MART": {
              "EQ ANTACID": "EQ ANTACID",
              "EQ ANTACID EXTRA STRENGTH": "EQ ANTACID EXTRA STRENGTH",
              "EQ ANTACID ULTRA STRENGTH": "EQ ANTACID ULTRA STRENGTH"
            },
            "WALGREENS": {
              "ANTACID": "ANTACID",
              "ANTACID EXTRA STRENGTH": "ANTACID EXTRA STRENGTH",
              "ANTACID FLAVOR CHEWS": "ANTACID FLAVOR CHEWS",
              "ANTACID MAXIMUM": "ANTACID MAXIMUM",
              "ANTACID SOFT CHEWS": "ANTACID SOFT CHEWS",
              "ANTACID ULTRA STRENGTH": "ANTACID ULTRA STRENGTH",
              "CHILDRENS SOOTHE": "CHILDRENS SOOTHE",
              "LONG LASTING ANTACID": "LONG LASTING ANTACID"
            }
          }
        },
        "Antacids - Magnesium Salts": {
          "Antacids - Magnesium Salts": {
            "21ST CENTURY HEALTHCARE": {
              "MAGNESIUM OXIDE": "MAGNESIUM OXIDE"
            },
            "AKORN": {
              "URO-MAG": "URO-MAG"
            },
            "AKORN CONSUMER": {
              "URO-MAG": "URO-MAG"
            },
            "APHENA PHARMA SOLUTIONS": {
              "MAGNESIUM OXIDE": "MAGNESIUM OXIDE"
            },
            "BASIC DRUGS": {
              "MAGNESIUM OXIDE": "MAGNESIUM OXIDE"
            },
            "BERGEN BRUNSWIG": {
              "GNP MAGNESIUM OXIDE": "GNP MAGNESIUM OXIDE"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC MAGNESIUM": "QC MAGNESIUM"
            },
            "EQUALINE": {
              "EQL MAGNESIUM": "EQL MAGNESIUM"
            },
            "FAGRON": {
              "MAGNESIUM OXIDE": "MAGNESIUM OXIDE"
            },
            "FREEDOM PHARMACEUTICALS": {
              "MAGNESIUM OXIDE": "MAGNESIUM OXIDE"
            },
            "HUMCO": {
              "MAGNESIUM CARBONATE": "MAGNESIUM CARBONATE"
            },
            "KMR PHARMACEUTICALS": {
              "MAGNESIUM OXIDE": "MAGNESIUM OXIDE"
            },
            "LEADER BRAND PRODUCTS": {
              "MAGNESIUM OXIDE": "MAGNESIUM OXIDE"
            },
            "LETCO MEDICAL": {
              "MAGNESIUM OXIDE": "MAGNESIUM OXIDE"
            },
            "MAGNO-HUMPHRIES LABORATORIES": {
              "MAGNESIUM OXIDE": "MAGNESIUM OXIDE"
            },
            "MANNE COMPANY": {
              "MAOX": "MAOX"
            },
            "MCKESSON": {
              "HM MAGNESIUM": "HM MAGNESIUM"
            },
            "MEDISCA": {
              "MAGNESIUM OXIDE": "MAGNESIUM OXIDE"
            },
            "METHOD PHARMACEUTICALS": {
              "MAGNESIUM OXIDE": "MAGNESIUM OXIDE"
            },
            "PAR PHARMACEUTICALS": {
              "MAGNESIUM OXIDE": "MAGNESIUM OXIDE"
            },
            "PCCA": {
              "MAGNESIUM OXIDE": "MAGNESIUM OXIDE",
              "MAGNESIUM TRISILICATE": "MAGNESIUM TRISILICATE"
            },
            "PHARMAVITE": {
              "MAGNESIUM OXIDE (ANTACID)": "MAGNESIUM OXIDE (ANTACID)"
            },
            "RICHMOND PHARMACEUTICALS": {
              "MAGNESIUM OXIDE": "MAGNESIUM OXIDE"
            },
            "RUGBY LABORATORIES": {
              "MAGNESIUM OXIDE": "MAGNESIUM OXIDE"
            },
            "SPECTRUM": {
              "MAGNESIUM OXIDE": "MAGNESIUM OXIDE",
              "MAGNESIUM TRISILICATE": "MAGNESIUM TRISILICATE"
            },
            "TWIN LABS": {
              "MAGNESIUM OXIDE": "MAGNESIUM OXIDE"
            },
            "VIRTUS PHARMACEUTICALS": {
              "MAGNESIUM OXIDE": "MAGNESIUM OXIDE"
            },
            "WESTMINSTER PHARMACEUTICALS": {
              "MAGNESIUM OXIDE": "MAGNESIUM OXIDE"
            }
          }
        },
        "Antacids - Sodium Citrate": {
          "Antacids - Sodium Citrate": {
            "VALMED INC.": {
              "CITRA PH": "CITRA PH"
            }
          }
        }
      },
      "ANTHELMINTICS": {
        "Anthelmintics": {
          "Anthelmintics": {
            "ACTAVIS PHARMA": {
              "ALBENDAZOLE": "ALBENDAZOLE"
            },
            "AMERICAN PHARMA INGREDIENTS": {
              "MEBENDAZOLE": "MEBENDAZOLE"
            },
            "AMNEAL PHARMACEUTICALS": {
              "ALBENDAZOLE": "ALBENDAZOLE"
            },
            "AMNEAL SPECIALTY": {
              "ALBENZA": "ALBENZA",
              "EMVERM": "EMVERM"
            },
            "AVKARE": {
              "ALBENDAZOLE": "ALBENDAZOLE"
            },
            "BAYER HEALTHCARE PHARMA": {
              "BILTRICIDE": "BILTRICIDE"
            },
            "CAMBER PHARMACEUTICALS": {
              "ALBENDAZOLE": "ALBENDAZOLE"
            },
            "CARA INCORPORATED": {
              "PIN-AWAY": "PIN-AWAY"
            },
            "CIPLA USA": {
              "ALBENDAZOLE": "ALBENDAZOLE"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "STROMECTOL": "STROMECTOL"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS PINWORM TREATMENT": "CVS PINWORM TREATMENT"
            },
            "EDENBRIDGE PHARMACEUTICALS": {
              "ALBENDAZOLE": "ALBENDAZOLE",
              "IVERMECTIN": "IVERMECTIN"
            },
            "ENCORE SCIENTIFIC": {
              "MEBENDAZOLE": "MEBENDAZOLE"
            },
            "EXELTIS USA": {
              "BENZNIDAZOLE": "BENZNIDAZOLE"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "ALBENDAZOLE": "ALBENDAZOLE"
            },
            "IMPAX GENERICS": {
              "ALBENDAZOLE": "ALBENDAZOLE"
            },
            "LETCO MEDICAL": {
              "MEBENDAZOLE": "MEBENDAZOLE",
              "THIABENDAZOLE": "THIABENDAZOLE"
            },
            "MEDISCA": {
              "MEBENDAZOLE": "MEBENDAZOLE"
            },
            "MERCK SHARP & DOHME": {
              "STROMECTOL": "STROMECTOL"
            },
            "NORTHSTAR RX": {
              "ALBENDAZOLE": "ALBENDAZOLE"
            },
            "NOVARTIS": {
              "EGATEN": "EGATEN"
            },
            "PAR PHARMACEUTICAL": {
              "PRAZIQUANTEL": "PRAZIQUANTEL"
            },
            "PCCA": {
              "MEBENDAZOLE": "MEBENDAZOLE",
              "THIABENDAZOLE": "THIABENDAZOLE"
            },
            "REESE PHARMACEUTICAL": {
              "REESES PINWORM MEDICINE": "REESES PINWORM MEDICINE"
            },
            "SPECTRUM": {
              "MEBENDAZOLE": "MEBENDAZOLE",
              "PIPERAZINE CITRATE": "PIPERAZINE CITRATE",
              "THIABENDAZOLE": "THIABENDAZOLE"
            },
            "WALGREENS": {
              "PINWORM MEDICINE": "PINWORM MEDICINE"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "ALBENDAZOLE": "ALBENDAZOLE"
            }
          }
        }
      },
      "ANTI-INFECTIVE AGENTS - MISC.": {
        "Anti-infective Agents - Misc.": {
          "Anti-infective Agents - Misc.": {
            "A-S MEDICATION SOLUTIONS": {
              "METRONIDAZOLE": "METRONIDAZOLE",
              "TINDAMAX": "TINDAMAX",
              "TRIMETHOPRIM": "TRIMETHOPRIM",
              "XIFAXAN": "XIFAXAN"
            },
            "ACTAVIS PHARMA": {
              "METRONIDAZOLE": "METRONIDAZOLE",
              "TRIMETHOPRIM": "TRIMETHOPRIM"
            },
            "AIDAREX PHARMACEUTICALS": {
              "METRONIDAZOLE": "METRONIDAZOLE"
            },
            "ALEMBIC PHARMACEUTICALS": {
              "METRONIDAZOLE": "METRONIDAZOLE"
            },
            "ALLEGIS HOLDINGS": {
              "PRIMSOL": "PRIMSOL"
            },
            "ALTURA PHARMACEUTICALS": {
              "METRONIDAZOLE": "METRONIDAZOLE"
            },
            "AMERICAN HEALTH PACKAGING": {
              "METRONIDAZOLE": "METRONIDAZOLE"
            },
            "AMERINET/ X-GEN": {
              "BACIIM": "BACIIM"
            },
            "AMERINET/PFIZER": {
              "BACITRACIN": "BACITRACIN"
            },
            "AMICI PHARMACEUTICALS": {
              "METRONIDAZOLE": "METRONIDAZOLE"
            },
            "APACE PACKAGING": {
              "METRONIDAZOLE": "METRONIDAZOLE"
            },
            "APHENA PHARMA SOLUTIONS": {
              "METRONIDAZOLE": "METRONIDAZOLE"
            },
            "AUROBINDO PHARMA": {
              "METRONIDAZOLE": "METRONIDAZOLE"
            },
            "AVET PHARMACEUTICALS": {
              "METRONIDAZOLE": "METRONIDAZOLE"
            },
            "AVKARE": {
              "TRIMETHOPRIM": "TRIMETHOPRIM"
            },
            "AVPAK": {
              "METRONIDAZOLE": "METRONIDAZOLE",
              "TRIMETHOPRIM": "TRIMETHOPRIM"
            },
            "AYTU BIOSCIENCE": {
              "PRIMSOL": "PRIMSOL"
            },
            "AZURITY PHARMACEUTICALS": {
              "FIRST-METRONIDAZOLE": "FIRST-METRONIDAZOLE"
            },
            "B. BRAUN MEDICAL": {
              "METRONIDAZOLE IN NACL": "METRONIDAZOLE IN NACL"
            },
            "BAUSCH HEALTH": {
              "XIFAXAN": "XIFAXAN"
            },
            "BAXTER HEALTHCARE CORP": {
              "METRONIDAZOLE": "METRONIDAZOLE",
              "METRONIDAZOLE IN NACL": "METRONIDAZOLE IN NACL"
            },
            "BIOCOMP PHARMA": {
              "TINIDAZOLE": "TINIDAZOLE"
            },
            "BLENHEIM PHARMACAL": {
              "METRONIDAZOLE": "METRONIDAZOLE"
            },
            "BLUE POINT LABORATORIES": {
              "METRONIDAZOLE": "METRONIDAZOLE"
            },
            "BRYANT RANCH PREPACK": {
              "METRONIDAZOLE": "METRONIDAZOLE"
            },
            "CAMBRIDGE THERAPEUTICS TECHN": {
              "METRONIDAZOLE": "METRONIDAZOLE"
            },
            "CARDINAL HEALTH REPACKAGING SO": {
              "METRONIDAZOLE": "METRONIDAZOLE"
            },
            "CIVICA": {
              "BACITRACIN": "BACITRACIN"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "METRONIDAZOLE": "METRONIDAZOLE"
            },
            "DENTON PHARMA": {
              "METRONIDAZOLE": "METRONIDAZOLE"
            },
            "DIRECT RX": {
              "METRONIDAZOLE": "METRONIDAZOLE"
            },
            "EDENBRIDGE PHARMACEUTICALS": {
              "TINIDAZOLE": "TINIDAZOLE"
            },
            "FAGRON": {
              "METRONIDAZOLE BENZO+SYRSPEND": "METRONIDAZOLE BENZO+SYRSPEND"
            },
            "FRESENIUS KABI USA": {
              "BACITRACIN": "BACITRACIN",
              "NEBUPENT": "NEBUPENT",
              "PENTAM": "PENTAM"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "METRONIDAZOLE": "METRONIDAZOLE",
              "TRIMETHOPRIM": "TRIMETHOPRIM"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "METRONIDAZOLE": "METRONIDAZOLE"
            },
            "HIKMA": {
              "TINIDAZOLE": "TINIDAZOLE"
            },
            "HOSPIRA": {
              "METRONIDAZOLE IN NACL": "METRONIDAZOLE IN NACL"
            },
            "KALCHEM INTERNATIONAL": {
              "TRIMETHOPRIM": "TRIMETHOPRIM"
            },
            "KEY THERAPEUTICS": {
              "TRIMPEX": "TRIMPEX"
            },
            "LETCO MEDICAL": {
              "TRIMETHOPRIM": "TRIMETHOPRIM"
            },
            "LIBERTY PHARMACEUTICAL": {
              "METRONIDAZOLE": "METRONIDAZOLE",
              "TRIMETHOPRIM": "TRIMETHOPRIM"
            },
            "LUPIN PHARMACEUTICALS": {
              "TINIDAZOLE": "TINIDAZOLE",
              "TRIMETHOPRIM": "TRIMETHOPRIM"
            },
            "MAJOR PHARMACEUTICALS": {
              "METRONIDAZOLE": "METRONIDAZOLE"
            },
            "MAYNE PHARMA": {
              "TRIMETHOPRIM": "TRIMETHOPRIM"
            },
            "MEDISCA": {
              "TRIMETHOPRIM": "TRIMETHOPRIM"
            },
            "MEDVANTX": {
              "METRONIDAZOLE": "METRONIDAZOLE"
            },
            "METCURE PHARMACEUTICALS": {
              "METRONIDAZOLE": "METRONIDAZOLE"
            },
            "METHOD PHARMACEUTICALS": {
              "METRONIDAZOLE": "METRONIDAZOLE"
            },
            "MISSION": {
              "TINDAMAX": "TINDAMAX"
            },
            "MYLAN INSTITUTIONAL": {
              "METRONIDAZOLE": "METRONIDAZOLE"
            },
            "NIVAGEN PHARMACEUTICALS": {
              "METRONIDAZOLE": "METRONIDAZOLE"
            },
            "NOVAPLUS/HOSPIRA": {
              "METRONIDAZOLE IN NACL": "METRONIDAZOLE IN NACL"
            },
            "NOVAPLUS/PFIZER U.S.": {
              "BACITRACIN": "BACITRACIN"
            },
            "NOVAPLUS/X-GEN": {
              "BACIIM": "BACIIM"
            },
            "NOVAPLUS/XELLIA": {
              "BACITRACIN": "BACITRACIN"
            },
            "NUCARE PHARMACEUTICALS": {
              "METRONIDAZOLE": "METRONIDAZOLE"
            },
            "PACK PHARMACEUTICALS, LLC": {
              "TINIDAZOLE": "TINIDAZOLE"
            },
            "PCCA": {
              "TRIMETHOPRIM": "TRIMETHOPRIM"
            },
            "PDRX PHARMACEUTICAL": {
              "METRONIDAZOLE": "METRONIDAZOLE",
              "TINDAMAX": "TINDAMAX",
              "TRIMETHOPRIM": "TRIMETHOPRIM"
            },
            "PDRX PHARMACEUTICALS": {
              "METRONIDAZOLE": "METRONIDAZOLE"
            },
            "PFIZER U.S.": {
              "BACITRACIN": "BACITRACIN",
              "FLAGYL": "FLAGYL"
            },
            "PHARMEDIX": {
              "METRONIDAZOLE": "METRONIDAZOLE"
            },
            "PHARMPAK": {
              "METRONIDAZOLE": "METRONIDAZOLE"
            },
            "PREFERRED PHARMACEUTICALS": {
              "METRONIDAZOLE": "METRONIDAZOLE"
            },
            "PROFICIENT RX": {
              "METRONIDAZOLE": "METRONIDAZOLE"
            },
            "PROFOUNDA": {
              "IMPAVIDO": "IMPAVIDO"
            },
            "QUALITY CARE": {
              "METRONIDAZOLE": "METRONIDAZOLE"
            },
            "QUALITY CARE PRODUCTS": {
              "METRONIDAZOLE": "METRONIDAZOLE"
            },
            "READYMEDS": {
              "METRONIDAZOLE": "METRONIDAZOLE"
            },
            "REDHILL BIOPHARMA": {
              "AEMCOLO": "AEMCOLO"
            },
            "RISING PHARMACEUTICALS": {
              "METRONIDAZOLE": "METRONIDAZOLE",
              "TINIDAZOLE": "TINIDAZOLE"
            },
            "RXCHANGE CO": {
              "METRONIDAZOLE": "METRONIDAZOLE"
            },
            "SAGENT PHARMACEUTICAL": {
              "METRONIDAZOLE IN NACL": "METRONIDAZOLE IN NACL"
            },
            "SETON PHARMACEUTICALS": {
              "PENTAMIDINE ISETHIONATE": "PENTAMIDINE ISETHIONATE"
            },
            "ST MARY'S MPP": {
              "METRONIDAZOLE": "METRONIDAZOLE"
            },
            "STRIDES PHARMA": {
              "METRONIDAZOLE": "METRONIDAZOLE"
            },
            "TEVA PHARMACEUTICALS USA": {
              "METRONIDAZOLE": "METRONIDAZOLE",
              "TRIMETHOPRIM": "TRIMETHOPRIM"
            },
            "UNICHEM PHARMACEUTICALS": {
              "METRONIDAZOLE": "METRONIDAZOLE"
            },
            "VIONA PHARMACEUTICALS": {
              "METRONIDAZOLE": "METRONIDAZOLE"
            },
            "X-GEN PHARMACEUTICALS, INC": {
              "BACIIM": "BACIIM",
              "BACITRACIN": "BACITRACIN"
            },
            "XELLIA PHARMACEUTICALS USA": {
              "BACITRACIN": "BACITRACIN"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "METRONIDAZOLE": "METRONIDAZOLE"
            }
          }
        },
        "Anti-infective Misc. - Combinations": {
          "Anti-infective Misc. - Combinations": {
            "A-S MEDICATION SOLUTIONS": {
              "SULFAMETHOXAZOLE-TRIMETHOPRIM": "SULFAMETHOXAZOLE-TRIMETHOPRIM"
            },
            "AIDAREX PHARMACEUTICALS": {
              "SULFAMETHOXAZOLE-TRIMETHOPRIM": "SULFAMETHOXAZOLE-TRIMETHOPRIM"
            },
            "ALTURA PHARMACEUTICALS": {
              "SULFAMETHOXAZOLE-TRIMETHOPRIM": "SULFAMETHOXAZOLE-TRIMETHOPRIM"
            },
            "AMERICAN HEALTH PACKAGING": {
              "SULFAMETHOXAZOLE-TRIMETHOPRIM": "SULFAMETHOXAZOLE-TRIMETHOPRIM"
            },
            "AMNEAL PHARMACEUTICALS": {
              "SULFAMETHOXAZOLE-TRIMETHOPRIM": "SULFAMETHOXAZOLE-TRIMETHOPRIM"
            },
            "ANI  PHARMACEUTICALS": {
              "SULFAMETHOXAZOLE-TRIMETHOPRIM": "SULFAMETHOXAZOLE-TRIMETHOPRIM"
            },
            "APHENA PHARMA SOLUTIONS": {
              "SULFAMETHOXAZOLE-TRIMETHOPRIM": "SULFAMETHOXAZOLE-TRIMETHOPRIM"
            },
            "ATLANTIC BIOLOGICALS": {
              "SULFAMETHOXAZOLE-TRIMETHOPRIM": "SULFAMETHOXAZOLE-TRIMETHOPRIM",
              "SULFATRIM PEDIATRIC": "SULFATRIM PEDIATRIC"
            },
            "AUROBINDO PHARMA": {
              "SULFAMETHOXAZOLE-TRIMETHOPRIM": "SULFAMETHOXAZOLE-TRIMETHOPRIM"
            },
            "AVKARE": {
              "SULFAMETHOXAZOLE-TRIMETHOPRIM": "SULFAMETHOXAZOLE-TRIMETHOPRIM"
            },
            "AVPAK": {
              "SULFAMETHOXAZOLE-TRIMETHOPRIM": "SULFAMETHOXAZOLE-TRIMETHOPRIM"
            },
            "BLENHEIM PHARMACAL": {
              "SULFAMETHOXAZOLE-TRIMETHOPRIM": "SULFAMETHOXAZOLE-TRIMETHOPRIM"
            },
            "BRYANT RANCH PREPACK": {
              "SULFAMETHOXAZOLE-TRIMETHOPRIM": "SULFAMETHOXAZOLE-TRIMETHOPRIM"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "SULFAMETHOXAZOLE-TRIMETHOPRIM": "SULFAMETHOXAZOLE-TRIMETHOPRIM"
            },
            "DENTON PHARMA": {
              "SULFAMETHOXAZOLE-TRIMETHOPRIM": "SULFAMETHOXAZOLE-TRIMETHOPRIM"
            },
            "DIRECT RX": {
              "SULFAMETHOXAZOLE-TRIMETHOPRIM": "SULFAMETHOXAZOLE-TRIMETHOPRIM"
            },
            "ENOVACHEM MANUFACTURING": {
              "SULFAMETHOXAZOLE-TRIMETHOPRIM": "SULFAMETHOXAZOLE-TRIMETHOPRIM"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "SULFAMETHOXAZOLE-TRIMETHOPRIM": "SULFAMETHOXAZOLE-TRIMETHOPRIM"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "SULFAMETHOXAZOLE-TRIMETHOPRIM": "SULFAMETHOXAZOLE-TRIMETHOPRIM"
            },
            "HI-TECH": {
              "SULFAMETHOXAZOLE-TRIMETHOPRIM": "SULFAMETHOXAZOLE-TRIMETHOPRIM"
            },
            "KAISER FOUNDATION HOSPITAL": {
              "SULFAMETHOXAZOLE-TRIMETHOPRIM": "SULFAMETHOXAZOLE-TRIMETHOPRIM"
            },
            "LANNETT": {
              "SULFAMETHOXAZOLE-TRIMETHOPRIM": "SULFAMETHOXAZOLE-TRIMETHOPRIM"
            },
            "LIBERTY PHARMACEUTICAL": {
              "SULFAMETHOXAZOLE-TRIMETHOPRIM": "SULFAMETHOXAZOLE-TRIMETHOPRIM"
            },
            "MAJOR PHARMACEUTICALS": {
              "SULFAMETHOXAZOLE-TRIMETHOPRIM": "SULFAMETHOXAZOLE-TRIMETHOPRIM"
            },
            "MEDVANTX": {
              "SULFAMETHOXAZOLE-TRIMETHOPRIM": "SULFAMETHOXAZOLE-TRIMETHOPRIM"
            },
            "MYLAN INSTITUTIONAL": {
              "SULFAMETHOXAZOLE-TRIMETHOPRIM": "SULFAMETHOXAZOLE-TRIMETHOPRIM"
            },
            "NEW HORIZON RX GROUP": {
              "SULFAMETHOXAZOLE-TRIMETHOPRIM": "SULFAMETHOXAZOLE-TRIMETHOPRIM"
            },
            "NOVAPLUS/MYLAN INSTITUTIONAL": {
              "SULFAMETHOXAZOLE-TRIMETHOPRIM": "SULFAMETHOXAZOLE-TRIMETHOPRIM"
            },
            "NOVAPLUS/TEVA PARENTAL MEDICIN": {
              "SULFAMETHOXAZOLE-TRIMETHOPRIM": "SULFAMETHOXAZOLE-TRIMETHOPRIM"
            },
            "NUCARE PHARMACEUTICALS": {
              "SULFAMETHOXAZOLE-TRIMETHOPRIM": "SULFAMETHOXAZOLE-TRIMETHOPRIM"
            },
            "PAR PHARMACEUTICALS": {
              "SULFAMETHOXAZOLE-TRIMETHOPRIM": "SULFAMETHOXAZOLE-TRIMETHOPRIM"
            },
            "PDRX PHARMACEUTICAL": {
              "SULFAMETHOXAZOLE-TRIMETHOPRIM": "SULFAMETHOXAZOLE-TRIMETHOPRIM"
            },
            "PDRX PHARMACEUTICALS": {
              "SULFAMETHOXAZOLE-TRIMETHOPRIM": "SULFAMETHOXAZOLE-TRIMETHOPRIM"
            },
            "PHARMACEUTICAL ASSOCIATES": {
              "SULFAMETHOXAZOLE-TRIMETHOPRIM": "SULFAMETHOXAZOLE-TRIMETHOPRIM",
              "SULFATRIM PEDIATRIC": "SULFATRIM PEDIATRIC"
            },
            "PHARMEDIX": {
              "SULFAMETHOXAZOLE-TRIMETHOPRIM": "SULFAMETHOXAZOLE-TRIMETHOPRIM"
            },
            "PHARMPAK": {
              "SULFAMETHOXAZOLE-TRIMETHOPRIM": "SULFAMETHOXAZOLE-TRIMETHOPRIM"
            },
            "PREFERRED PHARMACEUTICALS": {
              "SULFAMETHOXAZOLE-TRIMETHOPRIM": "SULFAMETHOXAZOLE-TRIMETHOPRIM",
              "SULFATRIM PEDIATRIC": "SULFATRIM PEDIATRIC"
            },
            "PROFICIENT RX": {
              "SULFAMETHOXAZOLE-TRIMETHOPRIM": "SULFAMETHOXAZOLE-TRIMETHOPRIM"
            },
            "QUALITY CARE": {
              "BACTRIM": "BACTRIM",
              "BACTRIM DS": "BACTRIM DS",
              "SULFAMETHOXAZOLE-TRIMETHOPRIM": "SULFAMETHOXAZOLE-TRIMETHOPRIM"
            },
            "QUALITY CARE PRODUCTS": {
              "SULFAMETHOXAZOLE-TRIMETHOPRIM": "SULFAMETHOXAZOLE-TRIMETHOPRIM"
            },
            "READYMEDS": {
              "SULFAMETHOXAZOLE-TRIMETHOPRIM": "SULFAMETHOXAZOLE-TRIMETHOPRIM"
            },
            "RISING PHARMACEUTICALS": {
              "SULFAMETHOXAZOLE-TRIMETHOPRIM": "SULFAMETHOXAZOLE-TRIMETHOPRIM"
            },
            "RXCHANGE CO": {
              "SULFAMETHOXAZOLE-TRIMETHOPRIM": "SULFAMETHOXAZOLE-TRIMETHOPRIM"
            },
            "SKY PACKAGING": {
              "SULFAMETHOXAZOLE-TRIMETHOPRIM": "SULFAMETHOXAZOLE-TRIMETHOPRIM"
            },
            "SOMERSET THERAPEUTICS": {
              "SULFAMETHOXAZOLE-TRIMETHOPRIM": "SULFAMETHOXAZOLE-TRIMETHOPRIM"
            },
            "ST MARY'S MPP": {
              "SULFAMETHOXAZOLE-TRIMETHOPRIM": "SULFAMETHOXAZOLE-TRIMETHOPRIM"
            },
            "STI PHARMA": {
              "SULFATRIM PEDIATRIC": "SULFATRIM PEDIATRIC"
            },
            "SUN PHARMACEUTICALS": {
              "BACTRIM": "BACTRIM",
              "BACTRIM DS": "BACTRIM DS",
              "SULFAMETHOXAZOLE-TRIMETHOPRIM": "SULFAMETHOXAZOLE-TRIMETHOPRIM"
            },
            "TEVA PARENTERAL MEDICINES": {
              "SULFAMETHOXAZOLE-TRIMETHOPRIM": "SULFAMETHOXAZOLE-TRIMETHOPRIM"
            },
            "TEVA PHARMACEUTICALS USA": {
              "SULFAMETHOXAZOLE-TRIMETHOPRIM": "SULFAMETHOXAZOLE-TRIMETHOPRIM"
            },
            "UNIT DOSE SERVICES": {
              "SULFAMETHOXAZOLE-TRIMETHOPRIM": "SULFAMETHOXAZOLE-TRIMETHOPRIM"
            },
            "VISTA PHARMACEUTICALS": {
              "SULFAMETHOXAZOLE-TRIMETHOPRIM": "SULFAMETHOXAZOLE-TRIMETHOPRIM"
            }
          },
          "Methenamine Combos": {
            "CLARION BRANDS": {
              "CYSTEX URINARY PAIN RELIEF": "CYSTEX URINARY PAIN RELIEF"
            },
            "I-HEALTH": {
              "AZO URINARY TRACT DEFENSE": "AZO URINARY TRACT DEFENSE"
            }
          },
          "Urinary Antiseptic-Antispasmodic &/or Analgesics": {
            "AG MARIN PHARMACEUTICALS": {
              "URETRON D/S": "URETRON D/S"
            },
            "ALLEGIS PHARMACEUTICALS": {
              "URIMAR-T": "URIMAR-T"
            },
            "AVKARE": {
              "UROAV-81": "UROAV-81",
              "UROAV-B": "UROAV-B"
            },
            "BIOCOMP PHARMA": {
              "HYOPHEN": "HYOPHEN",
              "PHOSPHASAL": "PHOSPHASAL",
              "USTELL": "USTELL"
            },
            "BUREL PHARMACEUTICALS": {
              "AZUPHEN MB": "AZUPHEN MB",
              "HYOLEV MB": "HYOLEV MB",
              "INDIOMIN MB": "INDIOMIN MB",
              "URAMIT MB": "URAMIT MB",
              "UROLET MB": "UROLET MB",
              "UROPHEN MB": "UROPHEN MB"
            },
            "CYPRESS PHARMACEUTICAL": {
              "UTICAP": "UTICAP",
              "UTRONA-C": "UTRONA-C"
            },
            "LLORENS PHARMACEUTICAL": {
              "URIN DS": "URIN DS"
            },
            "MAYNE PHARMA": {
              "UR N-C": "UR N-C"
            },
            "METHOD PHARMACEUTICALS": {
              "ME/NAPHOS/MB/HYO1": "ME/NAPHOS/MB/HYO1",
              "URO-458": "URO-458",
              "URO-MP": "URO-MP"
            },
            "MISSION": {
              "URIBEL": "URIBEL",
              "UTIRA-C": "UTIRA-C"
            },
            "MYLAN SPECIALTY L.P.": {
              "URELLE": "URELLE"
            },
            "PORTAL PHARMACEUTICALS": {
              "URYL": "URYL"
            },
            "SJ PHARMACEUTICALS": {
              "UTA": "UTA"
            },
            "VILVET PHARMACEUTICAL": {
              "VILAMIT MB": "VILAMIT MB",
              "VILEVEV MB": "VILEVEV MB"
            },
            "WOMEN'S CHOICE PHARMACEUTICALS": {
              "UROGESIC-BLUE": "UROGESIC-BLUE"
            }
          }
        },
        "Antiprotozoal Agents": {
          "Antiprotozoal Agents": {
            "AMERICAN HEALTH PACKAGING": {
              "ATOVAQUONE": "ATOVAQUONE"
            },
            "AMNEAL PHARMACEUTICALS": {
              "ATOVAQUONE": "ATOVAQUONE"
            },
            "AVPAK": {
              "ATOVAQUONE": "ATOVAQUONE"
            },
            "BAYER HEALTHCARE PHARMA": {
              "LAMPIT": "LAMPIT"
            },
            "CAMBER PHARMACEUTICALS": {
              "ATOVAQUONE": "ATOVAQUONE"
            },
            "GLAXO SMITH KLINE": {
              "MEPRON": "MEPRON"
            },
            "GLENMARK PHARMACEUTICALS": {
              "ATOVAQUONE": "ATOVAQUONE"
            },
            "KVK TECH": {
              "ATOVAQUONE": "ATOVAQUONE"
            },
            "LUPIN PHARMACEUTICALS": {
              "ALINIA": "ALINIA",
              "ATOVAQUONE": "ATOVAQUONE"
            },
            "NORTHSTAR RX": {
              "ATOVAQUONE": "ATOVAQUONE"
            },
            "PHARMACEUTICAL ASSOCIATES": {
              "ATOVAQUONE": "ATOVAQUONE"
            },
            "PRASCO LABORATORIES": {
              "ATOVAQUONE": "ATOVAQUONE"
            },
            "ROMARK PHARMACEUTICALS": {
              "ALINIA": "ALINIA"
            },
            "VISTAPHARM INC.": {
              "ATOVAQUONE": "ATOVAQUONE"
            }
          }
        },
        "Carbapenems": {
          "Carbapenem Combinations": {
            "FRESENIUS KABI USA": {
              "IMIPENEM-CILASTATIN": "IMIPENEM-CILASTATIN"
            },
            "HOSPIRA": {
              "IMIPENEM-CILASTATIN": "IMIPENEM-CILASTATIN"
            },
            "MELINTA THERAPEUTICS": {
              "VABOMERE": "VABOMERE"
            },
            "MERCK SHARP & DOHME": {
              "PRIMAXIN IV": "PRIMAXIN IV",
              "RECARBRIO": "RECARBRIO"
            },
            "NOVAPLUS/FRESENIUS KABI": {
              "IMIPENEM-CILASTATIN": "IMIPENEM-CILASTATIN"
            },
            "NOVAPLUS/HOSPIRA": {
              "IMIPENEM-CILASTATIN": "IMIPENEM-CILASTATIN"
            }
          },
          "Carbapenems": {
            "AMNEAL BIOSCIENCES": {
              "MEROPENEM": "MEROPENEM"
            },
            "APOTEX": {
              "DORIPENEM": "DORIPENEM",
              "ERTAPENEM SODIUM": "ERTAPENEM SODIUM"
            },
            "AUROMEDICS PHARMA": {
              "ERTAPENEM SODIUM": "ERTAPENEM SODIUM",
              "MEROPENEM": "MEROPENEM"
            },
            "B. BRAUN MEDICAL": {
              "MEROPENEM-SODIUM CHLORIDE": "MEROPENEM-SODIUM CHLORIDE"
            },
            "BLUE POINT LABORATORIES": {
              "ERTAPENEM SODIUM": "ERTAPENEM SODIUM",
              "MEROPENEM": "MEROPENEM"
            },
            "CIVICA": {
              "MEROPENEM": "MEROPENEM"
            },
            "FRESENIUS KABI USA": {
              "ERTAPENEM SODIUM": "ERTAPENEM SODIUM",
              "MEROPENEM": "MEROPENEM"
            },
            "HOSPIRA": {
              "ERTAPENEM SODIUM": "ERTAPENEM SODIUM",
              "MEROPENEM": "MEROPENEM"
            },
            "MEITHEAL PHARMACEUTICALS": {
              "MEROPENEM": "MEROPENEM"
            },
            "MERCK SHARP & DOHME": {
              "INVANZ": "INVANZ"
            },
            "NOVAPLUS/AUROMEDICS": {
              "ERTAPENEM SODIUM": "ERTAPENEM SODIUM"
            },
            "NOVAPLUS/FRESENIUS KABI": {
              "MEROPENEM": "MEROPENEM"
            },
            "PAR STERILE PRODUCTS": {
              "ERTAPENEM SODIUM": "ERTAPENEM SODIUM",
              "MEROPENEM": "MEROPENEM"
            },
            "PFIZER U.S.": {
              "MERREM": "MERREM"
            },
            "SAGENT PHARMACEUTICAL": {
              "MEROPENEM": "MEROPENEM"
            },
            "SANDOZ": {
              "MEROPENEM": "MEROPENEM"
            },
            "SHIONOGI PHARMA": {
              "DORIBAX": "DORIBAX"
            },
            "WG CRITICAL CARE": {
              "ERTAPENEM SODIUM": "ERTAPENEM SODIUM",
              "MEROPENEM": "MEROPENEM"
            }
          }
        },
        "Chloramphenicols": {
          "Chloramphenicals": {
            "FRESENIUS KABI USA": {
              "CHLORAMPHENICOL SOD SUCCINATE": "CHLORAMPHENICOL SOD SUCCINATE"
            }
          }
        },
        "Cyclic Lipopeptides": {
          "Cyclic Lipopeptides": {
            "ACCORD HEALTHCARE": {
              "DAPTOMYCIN": "DAPTOMYCIN"
            },
            "BE PHARMACEUTICALS": {
              "DAPTOMYCIN": "DAPTOMYCIN"
            },
            "BLUE POINT LABORATORIES": {
              "DAPTOMYCIN": "DAPTOMYCIN"
            },
            "CIPLA USA": {
              "DAPTOMYCIN": "DAPTOMYCIN"
            },
            "CIVICA": {
              "DAPTOMYCIN": "DAPTOMYCIN"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "DAPTOMYCIN": "DAPTOMYCIN"
            },
            "FRESENIUS KABI USA": {
              "DAPTOMYCIN": "DAPTOMYCIN"
            },
            "HIKMA": {
              "DAPTOMYCIN": "DAPTOMYCIN"
            },
            "HOSPIRA": {
              "DAPTOMYCIN": "DAPTOMYCIN"
            },
            "MEITHEAL PHARMACEUTICALS": {
              "DAPTOMYCIN": "DAPTOMYCIN"
            },
            "MERCK SHARP & DOHME": {
              "CUBICIN": "CUBICIN",
              "CUBICIN RF": "CUBICIN RF"
            },
            "MYLAN INSTITUTIONAL": {
              "DAPTOMYCIN": "DAPTOMYCIN"
            },
            "NORTHSTAR RX": {
              "DAPTOMYCIN": "DAPTOMYCIN"
            },
            "NOVAPLUS/FRESENIUS KABI": {
              "DAPTOMYCIN": "DAPTOMYCIN"
            },
            "NOVAPLUS/SAGENT PHARMACEUTICAL": {
              "DAPTOMYCIN": "DAPTOMYCIN"
            },
            "SAGENT PHARMACEUTICAL": {
              "DAPTOMYCIN": "DAPTOMYCIN"
            },
            "SANDOZ": {
              "DAPTOMYCIN": "DAPTOMYCIN"
            },
            "TEVA PARENTERAL MEDICINES": {
              "DAPTOMYCIN": "DAPTOMYCIN"
            },
            "XELLIA PHARMACEUTICALS USA": {
              "DAPTOMYCIN": "DAPTOMYCIN"
            }
          }
        },
        "Glycopeptides": {
          "Glycopeptides": {
            "A-S MEDICATION SOLUTIONS": {
              "VANCOMYCIN HCL": "VANCOMYCIN HCL"
            },
            "AKORN": {
              "VANCOMYCIN HCL": "VANCOMYCIN HCL"
            },
            "ALLERGAN": {
              "DALVANCE": "DALVANCE"
            },
            "ALVOGEN": {
              "VANCOMYCIN HCL": "VANCOMYCIN HCL"
            },
            "ANI  PHARMACEUTICALS": {
              "VANCOCIN": "VANCOCIN",
              "VANCOCIN HCL": "VANCOCIN HCL",
              "VANCOMYCIN HCL": "VANCOMYCIN HCL"
            },
            "ATHENEX PHARMACEUTICAL": {
              "VANCOMYCIN HCL": "VANCOMYCIN HCL"
            },
            "AUROMEDICS PHARMA": {
              "VANCOMYCIN HCL": "VANCOMYCIN HCL"
            },
            "AZURITY PHARMACEUTICALS": {
              "FIRST-VANCOMYCIN": "FIRST-VANCOMYCIN",
              "FIRVANQ": "FIRVANQ"
            },
            "BAXTER HEALTHCARE CORP": {
              "VANCOMYCIN HCL IN DEXTROSE": "VANCOMYCIN HCL IN DEXTROSE",
              "VANCOMYCIN HCL IN NACL": "VANCOMYCIN HCL IN NACL"
            },
            "BLUE POINT LABORATORIES": {
              "VANCOMYCIN HCL": "VANCOMYCIN HCL"
            },
            "BRECKENRIDGE": {
              "VANCOMYCIN HCL": "VANCOMYCIN HCL"
            },
            "CENTRAL ADMIXTURE PHARMACY SER": {
              "VANCOMYCIN HCL IN DEXTROSE": "VANCOMYCIN HCL IN DEXTROSE",
              "VANCOMYCIN HCL IN NACL": "VANCOMYCIN HCL IN NACL"
            },
            "CIVICA": {
              "VANCOMYCIN HCL": "VANCOMYCIN HCL"
            },
            "CUMBERLAND PHARMACEUTICALS": {
              "VIBATIV": "VIBATIV"
            },
            "FAGRON": {
              "VANCOMYCIN+SYRSPEND SF": "VANCOMYCIN+SYRSPEND SF"
            },
            "FRESENIUS KABI USA": {
              "VANCOMYCIN HCL": "VANCOMYCIN HCL"
            },
            "HIKMA": {
              "VANCOMYCIN HCL": "VANCOMYCIN HCL"
            },
            "HOSPIRA": {
              "VANCOMYCIN HCL": "VANCOMYCIN HCL"
            },
            "LEITERS HEALTH": {
              "VANCOMYCIN HCL IN NACL": "VANCOMYCIN HCL IN NACL"
            },
            "LUPIN PHARMACEUTICALS": {
              "VANCOMYCIN HCL": "VANCOMYCIN HCL"
            },
            "MELINTA THERAPEUTICS": {
              "ORBACTIV": "ORBACTIV"
            },
            "MYLAN INSTITUTIONAL": {
              "VANCOMYCIN HCL": "VANCOMYCIN HCL"
            },
            "NOVA PLUS/AZURITY PHARMACEUTIC": {
              "FIRVANQ": "FIRVANQ"
            },
            "NOVAPLUS/FRESENIUS KABI": {
              "VANCOMYCIN HCL": "VANCOMYCIN HCL"
            },
            "NOVAPLUS/HOSPIRA": {
              "VANCOMYCIN HCL": "VANCOMYCIN HCL"
            },
            "NOVAPLUS/MYLAN INSTITUTIONAL": {
              "VANCOMYCIN HCL": "VANCOMYCIN HCL"
            },
            "PENTEC HEALTH": {
              "VANCOMYCIN HCL": "VANCOMYCIN HCL"
            },
            "PHARMACEUTICAL ASSOCIATES": {
              "VANCOMYCIN HCL": "VANCOMYCIN HCL"
            },
            "PHARMEDIUM SERVICES": {
              "VANCOMYCIN HCL IN DEXTROSE": "VANCOMYCIN HCL IN DEXTROSE",
              "VANCOMYCIN HCL IN NACL": "VANCOMYCIN HCL IN NACL"
            },
            "PRASCO LABORATORIES": {
              "VANCOMYCIN HCL": "VANCOMYCIN HCL"
            },
            "QUVA PHARMA": {
              "VANCOMYCIN HCL IN DEXTROSE": "VANCOMYCIN HCL IN DEXTROSE",
              "VANCOMYCIN HCL IN NACL": "VANCOMYCIN HCL IN NACL"
            },
            "SAGENT PHARMACEUTICAL": {
              "VANCOMYCIN HCL": "VANCOMYCIN HCL"
            },
            "SAMSON MEDICAL TECH. LLC": {
              "VANCOMYCIN HCL": "VANCOMYCIN HCL"
            },
            "SCA PHARMACEUTICALS": {
              "VANCOMYCIN HCL IN NACL": "VANCOMYCIN HCL IN NACL"
            },
            "SLATE RUN PHARMACEUTICALS": {
              "VANCOMYCIN HCL": "VANCOMYCIN HCL"
            },
            "SOLUTECH PHARMACEUTICALS": {
              "VANCOSOL PACK": "VANCOSOL PACK"
            },
            "THERAVANCE BIOPHARMA": {
              "VIBATIV": "VIBATIV"
            },
            "XELLIA PHARMACEUTICALS USA": {
              "VANCOMYCIN HCL": "VANCOMYCIN HCL"
            }
          }
        },
        "Ketolides": {
          "Ketolides": {
            "SANOFI-AVENTIS U.S.": {
              "KETEK": "KETEK"
            }
          }
        },
        "Leprostatics": {
          "Leprostatics": {
            "A-S MEDICATION SOLUTIONS": {
              "DAPSONE": "DAPSONE"
            },
            "ALVOGEN": {
              "DAPSONE": "DAPSONE"
            },
            "APHENA PHARMA SOLUTIONS": {
              "DAPSONE": "DAPSONE"
            },
            "AVKARE": {
              "DAPSONE": "DAPSONE"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "DAPSONE": "DAPSONE"
            },
            "JACOBUS": {
              "DAPSONE": "DAPSONE"
            },
            "MAJOR PHARMACEUTICALS": {
              "DAPSONE": "DAPSONE"
            },
            "MARLEX PHARMACEUTICALS": {
              "DAPSONE": "DAPSONE"
            },
            "NOSTRUM LABORATORIES": {
              "DAPSONE": "DAPSONE"
            },
            "NOVITIUM PHARMA": {
              "DAPSONE": "DAPSONE"
            },
            "PDRX PHARMACEUTICAL": {
              "DAPSONE": "DAPSONE"
            },
            "SETON PHARMACEUTICALS": {
              "DAPSONE": "DAPSONE"
            },
            "VIRTUS PHARMACEUTICALS": {
              "DAPSONE": "DAPSONE"
            }
          }
        },
        "Lincosamides": {
          "Lincosamides": {
            "A-S MEDICATION SOLUTIONS": {
              "CLINDAMYCIN HCL": "CLINDAMYCIN HCL",
              "LINCOCIN": "LINCOCIN"
            },
            "ACTAVIS PHARMA": {
              "CLINDAMYCIN HCL": "CLINDAMYCIN HCL"
            },
            "AIDAREX PHARMACEUTICALS": {
              "CLINDAMYCIN HCL": "CLINDAMYCIN HCL"
            },
            "AKORN": {
              "CLINDAMYCIN PHOSPHATE IN D5W": "CLINDAMYCIN PHOSPHATE IN D5W"
            },
            "ALMAJECT": {
              "CLINDAMYCIN PHOSPHATE": "CLINDAMYCIN PHOSPHATE"
            },
            "ALTURA PHARMACEUTICALS": {
              "CLINDAMYCIN HCL": "CLINDAMYCIN HCL"
            },
            "ALVOGEN": {
              "CLINDAMYCIN PHOSPHATE": "CLINDAMYCIN PHOSPHATE"
            },
            "AMERICAN HEALTH PACKAGING": {
              "CLINDAMYCIN HCL": "CLINDAMYCIN HCL"
            },
            "AMNEAL PHARMACEUTICALS": {
              "CLINDAMYCIN PALMITATE HCL": "CLINDAMYCIN PALMITATE HCL"
            },
            "APHENA PHARMA SOLUTIONS": {
              "CLINDAMYCIN HCL": "CLINDAMYCIN HCL"
            },
            "AUROBINDO PHARMA": {
              "CLINDAMYCIN HCL": "CLINDAMYCIN HCL",
              "CLINDAMYCIN PALMITATE HCL": "CLINDAMYCIN PALMITATE HCL"
            },
            "AVET PHARMACEUTICALS": {
              "CLINDAMYCIN PALMITATE HCL": "CLINDAMYCIN PALMITATE HCL"
            },
            "AVPAK": {
              "CLINDAMYCIN HCL": "CLINDAMYCIN HCL"
            },
            "BAXTER HEALTHCARE CORP": {
              "CLINDAMYCIN PHOSPHATE IN D5W": "CLINDAMYCIN PHOSPHATE IN D5W",
              "CLINDAMYCIN PHOSPHATE IN NACL": "CLINDAMYCIN PHOSPHATE IN NACL"
            },
            "BLENHEIM PHARMACAL": {
              "CLINDAMYCIN HCL": "CLINDAMYCIN HCL"
            },
            "BRYANT RANCH PREPACK": {
              "CLINDAMYCIN HCL": "CLINDAMYCIN HCL"
            },
            "BURKE THERAPEUTICS": {
              "CLIN SINGLE USE": "CLIN SINGLE USE"
            },
            "CARDINAL HEALTH REPACKAGING SO": {
              "CLINDAMYCIN HCL": "CLINDAMYCIN HCL"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "CLINDAMYCIN HCL": "CLINDAMYCIN HCL"
            },
            "COSETTE PHARMACEUTICALS": {
              "CLINDAMYCIN HCL": "CLINDAMYCIN HCL"
            },
            "DENTON PHARMA": {
              "CLINDAMYCIN HCL": "CLINDAMYCIN HCL"
            },
            "DIRECT RX": {
              "CLINDAMYCIN HCL": "CLINDAMYCIN HCL"
            },
            "FRESENIUS KABI USA": {
              "CLINDAMYCIN PHOSPHATE": "CLINDAMYCIN PHOSPHATE"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "CLINDAMYCIN HCL": "CLINDAMYCIN HCL"
            },
            "GREENSTONE": {
              "CLINDAMYCIN HCL": "CLINDAMYCIN HCL",
              "CLINDAMYCIN PALMITATE HCL": "CLINDAMYCIN PALMITATE HCL"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "CLINDAMYCIN HCL": "CLINDAMYCIN HCL"
            },
            "KAISER FOUNDATION HOSPITAL": {
              "CLINDAMYCIN HCL": "CLINDAMYCIN HCL"
            },
            "LANNETT": {
              "CLINDAMYCIN HCL": "CLINDAMYCIN HCL"
            },
            "LIBERTY PHARMACEUTICAL": {
              "CLINDAMYCIN HCL": "CLINDAMYCIN HCL"
            },
            "MAJOR PHARMACEUTICALS": {
              "CLINDAMYCIN HCL": "CLINDAMYCIN HCL"
            },
            "MARLEX PHARMACEUTICALS": {
              "CLINDAMYCIN PALMITATE HCL": "CLINDAMYCIN PALMITATE HCL"
            },
            "MICRO LABORATORIES": {
              "CLINDAMYCIN HCL": "CLINDAMYCIN HCL"
            },
            "MYLAN": {
              "CLINDAMYCIN PALMITATE HCL": "CLINDAMYCIN PALMITATE HCL"
            },
            "MYLAN INSTITUTIONAL": {
              "CLINDAMYCIN HCL": "CLINDAMYCIN HCL",
              "CLINDAMYCIN PHOSPHATE": "CLINDAMYCIN PHOSPHATE"
            },
            "NORTHSTAR RX": {
              "CLINDAMYCIN PALMITATE HCL": "CLINDAMYCIN PALMITATE HCL"
            },
            "NOVAPLUS/PFIZER U.S.": {
              "CLEOCIN PHOSPHATE": "CLEOCIN PHOSPHATE"
            },
            "NOVAPLUS/SANDOZ": {
              "CLINDAMYCIN PHOSPHATE IN D5W": "CLINDAMYCIN PHOSPHATE IN D5W"
            },
            "NUCARE PHARMACEUTICALS": {
              "CLINDAMYCIN HCL": "CLINDAMYCIN HCL"
            },
            "PDRX PHARMACEUTICAL": {
              "CLINDAMYCIN HCL": "CLINDAMYCIN HCL"
            },
            "PDRX PHARMACEUTICALS": {
              "CLINDAMYCIN HCL": "CLINDAMYCIN HCL"
            },
            "PERRIGO": {
              "CLINDAMYCIN PALMITATE HCL": "CLINDAMYCIN PALMITATE HCL"
            },
            "PFIZER U.S.": {
              "CLEOCIN": "CLEOCIN",
              "CLEOCIN IN D5W": "CLEOCIN IN D5W",
              "CLEOCIN PHOSPHATE": "CLEOCIN PHOSPHATE",
              "LINCOCIN": "LINCOCIN"
            },
            "PHARMEDIX": {
              "CLINDAMYCIN HCL": "CLINDAMYCIN HCL"
            },
            "PHARMPAK": {
              "CLINDAMYCIN HCL": "CLINDAMYCIN HCL"
            },
            "PREFERRED PHARMACEUTICALS": {
              "CLINDAMYCIN HCL": "CLINDAMYCIN HCL"
            },
            "PROFICIENT RX": {
              "CLINDAMYCIN HCL": "CLINDAMYCIN HCL"
            },
            "QUALITY CARE": {
              "CLINDAMYCIN HCL": "CLINDAMYCIN HCL"
            },
            "QUALITY CARE PRODUCTS": {
              "CLINDAMYCIN HCL": "CLINDAMYCIN HCL"
            },
            "RISING PHARMACEUTICALS": {
              "CLINDAMYCIN PALMITATE HCL": "CLINDAMYCIN PALMITATE HCL"
            },
            "RXCHANGE CO": {
              "CLINDAMYCIN HCL": "CLINDAMYCIN HCL"
            },
            "SAGENT PHARMACEUTICAL": {
              "CLINDAMYCIN PHOSPHATE": "CLINDAMYCIN PHOSPHATE"
            },
            "SANDOZ": {
              "CLINDAMYCIN PHOSPHATE IN D5W": "CLINDAMYCIN PHOSPHATE IN D5W"
            },
            "SKY PACKAGING": {
              "CLINDAMYCIN HCL": "CLINDAMYCIN HCL"
            },
            "ST MARY'S MPP": {
              "CLINDAMYCIN HCL": "CLINDAMYCIN HCL"
            },
            "SUN PHARMACEUTICALS": {
              "CLINDAMYCIN HCL": "CLINDAMYCIN HCL"
            },
            "UNIT DOSE SERVICES": {
              "CLINDAMYCIN HCL": "CLINDAMYCIN HCL"
            },
            "X-GEN PHARMACEUTICALS, INC": {
              "LINCOMYCIN HCL": "LINCOMYCIN HCL"
            }
          }
        },
        "Monobactams": {
          "Monobactams": {
            "B-M SQUIBB U.S. (PRIMARY CARE)": {
              "AZACTAM": "AZACTAM",
              "AZACTAM IN DEXTROSE": "AZACTAM IN DEXTROSE"
            },
            "FRESENIUS KABI USA": {
              "AZTREONAM": "AZTREONAM"
            },
            "GILEAD SCIENCES": {
              "CAYSTON": "CAYSTON"
            },
            "NOVAPLUS/FRESENIUS KABI": {
              "AZTREONAM": "AZTREONAM"
            }
          }
        },
        "Oxazolidinones": {
          "Oxazolidinones": {
            "A-S MEDICATION SOLUTIONS": {
              "ZYVOX": "ZYVOX"
            },
            "ALEMBIC PHARMACEUTICALS": {
              "LINEZOLID": "LINEZOLID"
            },
            "AMERICAN HEALTH PACKAGING": {
              "LINEZOLID": "LINEZOLID"
            },
            "AMNEAL PHARMACEUTICALS": {
              "LINEZOLID": "LINEZOLID"
            },
            "APOTEX": {
              "LINEZOLID": "LINEZOLID"
            },
            "ASCEND LABORATORIES": {
              "LINEZOLID": "LINEZOLID"
            },
            "AUROMEDICS PHARMA": {
              "LINEZOLID": "LINEZOLID"
            },
            "AVPAK": {
              "LINEZOLID": "LINEZOLID"
            },
            "CAMBER PHARMACEUTICALS": {
              "LINEZOLID": "LINEZOLID"
            },
            "CELLTRION USA": {
              "LINEZOLID": "LINEZOLID"
            },
            "FRESENIUS KABI USA": {
              "LINEZOLID": "LINEZOLID"
            },
            "GLENMARK PHARMACEUTICALS": {
              "LINEZOLID": "LINEZOLID"
            },
            "GREENSTONE": {
              "LINEZOLID": "LINEZOLID"
            },
            "HIKMA": {
              "LINEZOLID": "LINEZOLID"
            },
            "HOSPIRA": {
              "LINEZOLID": "LINEZOLID",
              "LINEZOLID IN SODIUM CHLORIDE": "LINEZOLID IN SODIUM CHLORIDE"
            },
            "MAJOR PHARMACEUTICALS": {
              "LINEZOLID": "LINEZOLID"
            },
            "MERCK SHARP & DOHME": {
              "SIVEXTRO": "SIVEXTRO"
            },
            "MYLAN": {
              "LINEZOLID": "LINEZOLID"
            },
            "MYLAN INSTITUTIONAL": {
              "LINEZOLID": "LINEZOLID"
            },
            "NOVAPLUS/PFIZER U.S.": {
              "ZYVOX": "ZYVOX"
            },
            "PFIZER U.S.": {
              "ZYVOX": "ZYVOX"
            },
            "PIRAMAL CRITICAL CARE": {
              "LINEZOLID": "LINEZOLID"
            },
            "QUALITY CARE": {
              "ZYVOX": "ZYVOX"
            },
            "SAGENT PHARMACEUTICAL": {
              "LINEZOLID": "LINEZOLID"
            },
            "SANDOZ": {
              "LINEZOLID": "LINEZOLID"
            },
            "SUN PHARMACEUTICALS": {
              "LINEZOLID": "LINEZOLID"
            },
            "TEVA PARENTERAL MEDICINES": {
              "LINEZOLID": "LINEZOLID"
            },
            "TEVA PHARMACEUTICALS USA": {
              "LINEZOLID": "LINEZOLID"
            }
          }
        },
        "Pleuromutilins": {
          "Pleuromutilins": {
            "NABRIVA THERAPEUTICS": {
              "XENLETA": "XENLETA"
            }
          }
        },
        "Polymyxins": {
          "Polymyxins": {
            "ALVOGEN": {
              "POLYMYXIN B SULFATE": "POLYMYXIN B SULFATE"
            },
            "AMERICAN PHARMA INGREDIENTS": {
              "POLYMYXIN B SULFATE": "POLYMYXIN B SULFATE"
            },
            "ATHENEX PHARMACEUTICAL": {
              "POLYMYXIN B SULFATE": "POLYMYXIN B SULFATE"
            },
            "AUROMEDICS PHARMA": {
              "POLYMYXIN B SULFATE": "POLYMYXIN B SULFATE"
            },
            "AVET PHARMACEUTICALS": {
              "COLISTIMETHATE SODIUM (CBA)": "COLISTIMETHATE SODIUM (CBA)"
            },
            "BRECKENRIDGE": {
              "POLYMYXIN B SULFATE": "POLYMYXIN B SULFATE"
            },
            "FAGRON": {
              "POLYMYXIN B SULFATE": "POLYMYXIN B SULFATE"
            },
            "FRESENIUS KABI USA": {
              "COLISTIMETHATE SODIUM (CBA)": "COLISTIMETHATE SODIUM (CBA)",
              "POLYMYXIN B SULFATE": "POLYMYXIN B SULFATE"
            },
            "LETCO MEDICAL": {
              "POLYMYXIN B SULFATE": "POLYMYXIN B SULFATE"
            },
            "MEDISCA": {
              "POLYMYXIN B SULFATE": "POLYMYXIN B SULFATE"
            },
            "NOVAPLUS/X-GEN": {
              "POLYMYXIN B SULFATE": "POLYMYXIN B SULFATE"
            },
            "PAR STERILE PRODUCTS": {
              "COLISTIMETHATE SODIUM (CBA)": "COLISTIMETHATE SODIUM (CBA)",
              "COLY-MYCIN M": "COLY-MYCIN M"
            },
            "PCCA": {
              "POLYMYXIN B SULFATE": "POLYMYXIN B SULFATE"
            },
            "PERRIGO": {
              "COLISTIMETHATE SODIUM (CBA)": "COLISTIMETHATE SODIUM (CBA)"
            },
            "SAGENT PHARMACEUTICAL": {
              "COLISTIMETHATE SODIUM (CBA)": "COLISTIMETHATE SODIUM (CBA)",
              "POLYMYXIN B SULFATE": "POLYMYXIN B SULFATE"
            },
            "SPECTRUM": {
              "POLYMYXIN B SULFATE": "POLYMYXIN B SULFATE"
            },
            "X-GEN PHARMACEUTICALS, INC": {
              "COLISTIMETHATE SODIUM (CBA)": "COLISTIMETHATE SODIUM (CBA)",
              "POLYMYXIN B SULFATE": "POLYMYXIN B SULFATE"
            },
            "XELLIA PHARMACEUTICALS USA": {
              "COLISTIMETHATE SODIUM (CBA)": "COLISTIMETHATE SODIUM (CBA)",
              "POLYMYXIN B SULFATE": "POLYMYXIN B SULFATE"
            }
          }
        },
        "Streptogramins": {
          "Streptogramin Combinations": {
            "PFIZER U.S.": {
              "SYNERCID": "SYNERCID"
            }
          }
        },
        "Urinary Anti-infectives": {
          "Urinary Anti-infectives": {
            "A-S MEDICATION SOLUTIONS": {
              "MACROBID": "MACROBID",
              "NITROFURANTOIN MACROCRYSTAL": "NITROFURANTOIN MACROCRYSTAL",
              "NITROFURANTOIN MONOHYD MACRO": "NITROFURANTOIN MONOHYD MACRO"
            },
            "ACTAVIS MID ATLANTIC": {
              "NITROFURANTOIN": "NITROFURANTOIN"
            },
            "ACTAVIS PHARMA": {
              "NITROFURANTOIN MACROCRYSTAL": "NITROFURANTOIN MACROCRYSTAL",
              "NITROFURANTOIN MONOHYD MACRO": "NITROFURANTOIN MONOHYD MACRO"
            },
            "AIDAREX PHARMACEUTICALS": {
              "NITROFURANTOIN MACROCRYSTAL": "NITROFURANTOIN MACROCRYSTAL",
              "NITROFURANTOIN MONOHYD MACRO": "NITROFURANTOIN MONOHYD MACRO"
            },
            "ALLERGAN": {
              "MONUROL": "MONUROL"
            },
            "ALMATICA": {
              "MACROBID": "MACROBID",
              "MACRODANTIN": "MACRODANTIN"
            },
            "ALVOGEN": {
              "METHENAMINE HIPPURATE": "METHENAMINE HIPPURATE",
              "NITROFURANTOIN MACROCRYSTAL": "NITROFURANTOIN MACROCRYSTAL",
              "NITROFURANTOIN MONOHYD MACRO": "NITROFURANTOIN MONOHYD MACRO"
            },
            "AMERICAN HEALTH PACKAGING": {
              "NITROFURANTOIN MACROCRYSTAL": "NITROFURANTOIN MACROCRYSTAL",
              "NITROFURANTOIN MONOHYD MACRO": "NITROFURANTOIN MONOHYD MACRO"
            },
            "AMNEAL PHARMACEUTICALS": {
              "METHENAMINE HIPPURATE": "METHENAMINE HIPPURATE",
              "NITROFURANTOIN": "NITROFURANTOIN",
              "NITROFURANTOIN MACROCRYSTAL": "NITROFURANTOIN MACROCRYSTAL",
              "NITROFURANTOIN MONOHYD MACRO": "NITROFURANTOIN MONOHYD MACRO"
            },
            "AUROBINDO PHARMA": {
              "METHENAMINE HIPPURATE": "METHENAMINE HIPPURATE"
            },
            "AVKARE": {
              "NITROFURANTOIN MONOHYD MACRO": "NITROFURANTOIN MONOHYD MACRO"
            },
            "AVPAK": {
              "NITROFURANTOIN MACROCRYSTAL": "NITROFURANTOIN MACROCRYSTAL",
              "NITROFURANTOIN MONOHYD MACRO": "NITROFURANTOIN MONOHYD MACRO"
            },
            "BLENHEIM PHARMACAL": {
              "NITROFURANTOIN MACROCRYSTAL": "NITROFURANTOIN MACROCRYSTAL"
            },
            "BLUE POINT LABORATORIES": {
              "NITROFURANTOIN MACROCRYSTAL": "NITROFURANTOIN MACROCRYSTAL",
              "NITROFURANTOIN MONOHYD MACRO": "NITROFURANTOIN MONOHYD MACRO"
            },
            "BRYANT RANCH PREPACK": {
              "NITROFURANTOIN MACROCRYSTAL": "NITROFURANTOIN MACROCRYSTAL",
              "NITROFURANTOIN MONOHYD MACRO": "NITROFURANTOIN MONOHYD MACRO"
            },
            "CASPER PHARMA": {
              "FURADANTIN": "FURADANTIN"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "NITROFURANTOIN MACROCRYSTAL": "NITROFURANTOIN MACROCRYSTAL"
            },
            "COUNTY LINE PHARMACEUTICALS": {
              "METHENAMINE HIPPURATE": "METHENAMINE HIPPURATE"
            },
            "DENTON PHARMA": {
              "NITROFURANTOIN MONOHYD MACRO": "NITROFURANTOIN MONOHYD MACRO"
            },
            "DIRECT RX": {
              "NITROFURANTOIN MACROCRYSTAL": "NITROFURANTOIN MACROCRYSTAL",
              "NITROFURANTOIN MONOHYD MACRO": "NITROFURANTOIN MONOHYD MACRO"
            },
            "EDENBRIDGE PHARMACEUTICALS": {
              "METHENAMINE MANDELATE": "METHENAMINE MANDELATE"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "MACROBID": "MACROBID",
              "NITROFURANTOIN MACROCRYSTAL": "NITROFURANTOIN MACROCRYSTAL",
              "NITROFURANTOIN MONOHYD MACRO": "NITROFURANTOIN MONOHYD MACRO"
            },
            "IMPAX GENERICS": {
              "NITROFURANTOIN MACROCRYSTAL": "NITROFURANTOIN MACROCRYSTAL"
            },
            "LEADING PHARMA": {
              "METHENAMINE HIPPURATE": "METHENAMINE HIPPURATE"
            },
            "LIBERTY PHARMACEUTICAL": {
              "NITROFURANTOIN MACROCRYSTAL": "NITROFURANTOIN MACROCRYSTAL",
              "NITROFURANTOIN MONOHYD MACRO": "NITROFURANTOIN MONOHYD MACRO"
            },
            "LUPIN PHARMACEUTICALS": {
              "NITROFURANTOIN": "NITROFURANTOIN"
            },
            "MAJOR PHARMACEUTICALS": {
              "NITROFURANTOIN MACROCRYSTAL": "NITROFURANTOIN MACROCRYSTAL"
            },
            "MEDVANTX": {
              "MACROBID": "MACROBID",
              "NITROFURANTOIN MACROCRYSTAL": "NITROFURANTOIN MACROCRYSTAL"
            },
            "METHOD PHARMACEUTICALS": {
              "METHENAMINE MANDELATE": "METHENAMINE MANDELATE"
            },
            "MICRO LABORATORIES": {
              "METHENAMINE HIPPURATE": "METHENAMINE HIPPURATE"
            },
            "MYLAN": {
              "NITROFURANTOIN MACROCRYSTAL": "NITROFURANTOIN MACROCRYSTAL",
              "NITROFURANTOIN MONOHYD MACRO": "NITROFURANTOIN MONOHYD MACRO"
            },
            "MYLAN INSTITUTIONAL": {
              "NITROFURANTOIN MACROCRYSTAL": "NITROFURANTOIN MACROCRYSTAL",
              "NITROFURANTOIN MONOHYD MACRO": "NITROFURANTOIN MONOHYD MACRO"
            },
            "NORTHSTAR RX": {
              "NITROFURANTOIN MONOHYD MACRO": "NITROFURANTOIN MONOHYD MACRO"
            },
            "NOSTRUM LABORATORIES": {
              "NITROFURANTOIN": "NITROFURANTOIN"
            },
            "NUCARE PHARMACEUTICALS": {
              "NITROFURANTOIN MACROCRYSTAL": "NITROFURANTOIN MACROCRYSTAL"
            },
            "PCCA": {
              "NALIDIXIC ACID": "NALIDIXIC ACID",
              "NITROFURANTOIN MACROCRYSTAL": "NITROFURANTOIN MACROCRYSTAL"
            },
            "PDRX PHARMACEUTICAL": {
              "MACROBID": "MACROBID",
              "MACRODANTIN": "MACRODANTIN",
              "NITROFURANTOIN MACROCRYSTAL": "NITROFURANTOIN MACROCRYSTAL",
              "NITROFURANTOIN MONOHYD MACRO": "NITROFURANTOIN MONOHYD MACRO"
            },
            "PHARMEDIX": {
              "NITROFURANTOIN MONOHYD MACRO": "NITROFURANTOIN MONOHYD MACRO"
            },
            "PHARMPAK": {
              "NITROFURANTOIN MACROCRYSTAL": "NITROFURANTOIN MACROCRYSTAL"
            },
            "PRASCO LABORATORIES": {
              "NITROFURANTOIN": "NITROFURANTOIN"
            },
            "PREFERRED PHARMACEUTICALS": {
              "NITROFURANTOIN MACROCRYSTAL": "NITROFURANTOIN MACROCRYSTAL",
              "NITROFURANTOIN MONOHYD MACRO": "NITROFURANTOIN MONOHYD MACRO"
            },
            "PROFICIENT RX": {
              "NITROFURANTOIN MACROCRYSTAL": "NITROFURANTOIN MACROCRYSTAL",
              "NITROFURANTOIN MONOHYD MACRO": "NITROFURANTOIN MONOHYD MACRO"
            },
            "QUALITY CARE": {
              "MACROBID": "MACROBID",
              "NITROFURANTOIN MACROCRYSTAL": "NITROFURANTOIN MACROCRYSTAL"
            },
            "QUALITY CARE PRODUCTS": {
              "NITROFURANTOIN MACROCRYSTAL": "NITROFURANTOIN MACROCRYSTAL",
              "NITROFURANTOIN MONOHYD MACRO": "NITROFURANTOIN MONOHYD MACRO"
            },
            "RXCHANGE CO": {
              "NITROFURANTOIN MACROCRYSTAL": "NITROFURANTOIN MACROCRYSTAL",
              "NITROFURANTOIN MONOHYD MACRO": "NITROFURANTOIN MONOHYD MACRO"
            },
            "SANDOZ": {
              "NITROFURANTOIN MONOHYD MACRO": "NITROFURANTOIN MONOHYD MACRO"
            },
            "SETON PHARMACEUTICALS": {
              "METHENAMINE MANDELATE": "METHENAMINE MANDELATE"
            },
            "ST MARY'S MPP": {
              "NITROFURANTOIN MONOHYD MACRO": "NITROFURANTOIN MONOHYD MACRO"
            },
            "SUN PHARMACEUTICALS": {
              "NITROFURANTOIN MACROCRYSTAL": "NITROFURANTOIN MACROCRYSTAL"
            },
            "TRIGEN LABORATORIES": {
              "NITROFURANTOIN MONOHYD MACRO": "NITROFURANTOIN MONOHYD MACRO"
            },
            "UNIT DOSE SERVICES": {
              "NITROFURANTOIN MONOHYD MACRO": "NITROFURANTOIN MONOHYD MACRO"
            },
            "VALIDUS PHARMACEUTICALS": {
              "HIPREX": "HIPREX"
            },
            "XIROMED": {
              "FOSFOMYCIN TROMETHAMINE": "FOSFOMYCIN TROMETHAMINE"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "NITROFURANTOIN MACROCRYSTAL": "NITROFURANTOIN MACROCRYSTAL"
            }
          }
        }
      },
      "ANTIANGINAL AGENTS": {
        "Antianginals-Other": {
          "Antianginals-Other": {
            "ACTAVIS": {
              "RANOLAZINE ER": "RANOLAZINE ER"
            },
            "AJANTA PHARMA LIMITED": {
              "RANOLAZINE ER": "RANOLAZINE ER"
            },
            "AMERICAN HEALTH PACKAGING": {
              "RANOLAZINE ER": "RANOLAZINE ER"
            },
            "AMERIGEN PHARMACEUTICALS": {
              "RANOLAZINE ER": "RANOLAZINE ER"
            },
            "APHENA PHARMA SOLUTIONS": {
              "RANEXA": "RANEXA",
              "RANOLAZINE ER": "RANOLAZINE ER"
            },
            "AVKARE": {
              "RANOLAZINE ER": "RANOLAZINE ER"
            },
            "GILEAD SCIENCES": {
              "RANEXA": "RANEXA"
            },
            "GLENMARK PHARMACEUTICALS": {
              "RANOLAZINE ER": "RANOLAZINE ER"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "RANOLAZINE ER": "RANOLAZINE ER"
            },
            "LIFESTAR PHARMA": {
              "RANOLAZINE ER": "RANOLAZINE ER"
            },
            "LUPIN PHARMACEUTICALS": {
              "RANOLAZINE ER": "RANOLAZINE ER"
            },
            "SCIEGEN PHARMACEUTICALS": {
              "RANOLAZINE ER": "RANOLAZINE ER"
            },
            "SUN PHARMACEUTICALS": {
              "RANOLAZINE ER": "RANOLAZINE ER"
            },
            "VIONA PHARMACEUTICALS": {
              "RANOLAZINE ER": "RANOLAZINE ER"
            }
          }
        },
        "Nitrates": {
          "Nitrates": {
            "A-S MEDICATION SOLUTIONS": {
              "ISOSORBIDE DINITRATE": "ISOSORBIDE DINITRATE",
              "ISOSORBIDE MONONITRATE ER": "ISOSORBIDE MONONITRATE ER",
              "NITRO-BID": "NITRO-BID",
              "NITROGLYCERIN": "NITROGLYCERIN",
              "NITROLINGUAL": "NITROLINGUAL",
              "NITROSTAT": "NITROSTAT"
            },
            "ACTAVIS ELIZABETH": {
              "ISOSORBIDE MONONITRATE": "ISOSORBIDE MONONITRATE"
            },
            "AIDAREX PHARMACEUTICALS": {
              "ISOSORBIDE MONONITRATE ER": "ISOSORBIDE MONONITRATE ER",
              "NITROSTAT": "NITROSTAT"
            },
            "AKRIMAX PHARMACEUTICALS": {
              "NITROMIST": "NITROMIST"
            },
            "ALLEGIS PHARMACEUTICALS": {
              "GONITRO": "GONITRO",
              "NITROGLYCERIN": "NITROGLYCERIN",
              "NITROLINGUAL": "NITROLINGUAL"
            },
            "ALVOGEN": {
              "NITROGLYCERIN": "NITROGLYCERIN"
            },
            "AMERICAN HEALTH PACKAGING": {
              "ISOSORBIDE DINITRATE": "ISOSORBIDE DINITRATE",
              "ISOSORBIDE MONONITRATE ER": "ISOSORBIDE MONONITRATE ER"
            },
            "AMERICAN REGENT": {
              "NITROGLYCERIN": "NITROGLYCERIN"
            },
            "APHENA PHARMA SOLUTIONS": {
              "ISOSORBIDE DINITRATE": "ISOSORBIDE DINITRATE",
              "ISOSORBIDE MONONITRATE ER": "ISOSORBIDE MONONITRATE ER"
            },
            "ARBOR PHARMACEUTICALS": {
              "NITRONAL": "NITRONAL"
            },
            "AVET PHARMACEUTICALS": {
              "ISOSORBIDE MONONITRATE ER": "ISOSORBIDE MONONITRATE ER"
            },
            "AVKARE": {
              "ISOSORBIDE DINITRATE": "ISOSORBIDE DINITRATE",
              "NITROGLYCERIN ER": "NITROGLYCERIN ER"
            },
            "AVPAK": {
              "ISOSORBIDE DINITRATE": "ISOSORBIDE DINITRATE",
              "ISOSORBIDE MONONITRATE ER": "ISOSORBIDE MONONITRATE ER"
            },
            "BAUSCH HEALTH": {
              "ISORDIL TITRADOSE": "ISORDIL TITRADOSE",
              "MINITRAN": "MINITRAN"
            },
            "BAXTER HEALTHCARE CORP": {
              "NITROGLYCERIN IN D5W": "NITROGLYCERIN IN D5W"
            },
            "BLENHEIM PHARMACAL": {
              "ISOSORBIDE MONONITRATE ER": "ISOSORBIDE MONONITRATE ER"
            },
            "BLUE POINT LABORATORIES": {
              "ISOSORBIDE DINITRATE": "ISOSORBIDE DINITRATE"
            },
            "BRYANT RANCH PREPACK": {
              "ISOSORBIDE DINITRATE": "ISOSORBIDE DINITRATE",
              "ISOSORBIDE MONONITRATE ER": "ISOSORBIDE MONONITRATE ER"
            },
            "CARDINAL HEALTH REPACKAGING SO": {
              "ISOSORBIDE DINITRATE": "ISOSORBIDE DINITRATE",
              "ISOSORBIDE MONONITRATE ER": "ISOSORBIDE MONONITRATE ER"
            },
            "DIRECT RX": {
              "ISOSORBIDE MONONITRATE ER": "ISOSORBIDE MONONITRATE ER"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "NITROGLYCERIN": "NITROGLYCERIN"
            },
            "ENDO PHARMACEUTICALS": {
              "DILATRATE-SR": "DILATRATE-SR"
            },
            "ESPERO PHARMACEUTICALS": {
              "GONITRO": "GONITRO",
              "NITROLINGUAL": "NITROLINGUAL"
            },
            "GLENMARK PHARMACEUTICALS": {
              "NITROGLYCERIN": "NITROGLYCERIN"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "ISOSORBIDE DINITRATE": "ISOSORBIDE DINITRATE",
              "ISOSORBIDE MONONITRATE ER": "ISOSORBIDE MONONITRATE ER",
              "NITROGLYCERIN": "NITROGLYCERIN"
            },
            "GREENSTONE": {
              "NITROGLYCERIN": "NITROGLYCERIN"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "ISOSORBIDE DINITRATE": "ISOSORBIDE DINITRATE"
            },
            "HERCON LABORATORIES INC.": {
              "NITROGLYCERIN": "NITROGLYCERIN"
            },
            "HIKMA": {
              "ISOSORBIDE DINITRATE": "ISOSORBIDE DINITRATE",
              "ISOSORBIDE MONONITRATE": "ISOSORBIDE MONONITRATE",
              "ISOSORBIDE MONONITRATE ER": "ISOSORBIDE MONONITRATE ER"
            },
            "HOSPIRA": {
              "NITROGLYCERIN IN D5W": "NITROGLYCERIN IN D5W"
            },
            "INGENUS PHARMACEUTICALS": {
              "ISOSORBIDE MONONITRATE ER": "ISOSORBIDE MONONITRATE ER",
              "NITRO-DUR": "NITRO-DUR"
            },
            "JACKSONVILLE PHARMACEUTICALS": {
              "NITROGLYCERIN": "NITROGLYCERIN"
            },
            "KREMERS URBAN": {
              "ISOSORBIDE MONONITRATE": "ISOSORBIDE MONONITRATE",
              "ISOSORBIDE MONONITRATE ER": "ISOSORBIDE MONONITRATE ER",
              "NITROGLYCERIN": "NITROGLYCERIN"
            },
            "LIBERTY PHARMACEUTICAL": {
              "ISOSORBIDE DINITRATE": "ISOSORBIDE DINITRATE",
              "ISOSORBIDE DINITRATE ER": "ISOSORBIDE DINITRATE ER"
            },
            "MAJOR PHARMACEUTICALS": {
              "ISOSORBIDE DINITRATE": "ISOSORBIDE DINITRATE",
              "ISOSORBIDE MONONITRATE ER": "ISOSORBIDE MONONITRATE ER",
              "NITROGLYCERIN": "NITROGLYCERIN",
              "NITROGLYCERIN ER": "NITROGLYCERIN ER"
            },
            "MEDICIS": {
              "NITROGLYCERIN": "NITROGLYCERIN"
            },
            "MERCK SHARP & DOHME": {
              "NITRO-DUR": "NITRO-DUR"
            },
            "MIST PHARMACEUTICALS": {
              "NITROMIST": "NITROMIST"
            },
            "MYLAN": {
              "NITROGLYCERIN": "NITROGLYCERIN"
            },
            "NORTHSTAR RX": {
              "ISOSORBIDE DINITRATE": "ISOSORBIDE DINITRATE"
            },
            "NUCARE PHARMACEUTICALS": {
              "ISOSORBIDE MONONITRATE ER": "ISOSORBIDE MONONITRATE ER"
            },
            "OCEANSIDE PHARMACEUTICALS": {
              "ISOSORBIDE DINITRATE": "ISOSORBIDE DINITRATE"
            },
            "PAR PHARMACEUTICAL": {
              "ISOSORBIDE DINITRATE": "ISOSORBIDE DINITRATE"
            },
            "PAR PHARMACEUTICALS": {
              "ISOSORBIDE MONONITRATE ER": "ISOSORBIDE MONONITRATE ER"
            },
            "PDRX PHARMACEUTICAL": {
              "ISOSORBIDE DINITRATE": "ISOSORBIDE DINITRATE",
              "ISOSORBIDE MONONITRATE ER": "ISOSORBIDE MONONITRATE ER",
              "NITRO-TIME": "NITRO-TIME"
            },
            "PERRIGO PHARMACEUTICALS": {
              "NITROGLYCERIN": "NITROGLYCERIN"
            },
            "PFIZER U.S.": {
              "NITROSTAT": "NITROSTAT"
            },
            "PREFERRED PHARMACEUTICALS": {
              "ISOSORBIDE MONONITRATE ER": "ISOSORBIDE MONONITRATE ER",
              "NITROGLYCERIN": "NITROGLYCERIN"
            },
            "PRESCRIPTION SOLUTIONS, INC.": {
              "NITROGLYCERIN": "NITROGLYCERIN"
            },
            "PROFICIENT RX": {
              "ISOSORBIDE MONONITRATE": "ISOSORBIDE MONONITRATE",
              "NITROGLYCERIN": "NITROGLYCERIN",
              "NITROSTAT": "NITROSTAT"
            },
            "QUALITY CARE": {
              "ISOSORBIDE DINITRATE": "ISOSORBIDE DINITRATE",
              "ISOSORBIDE DINITRATE ER": "ISOSORBIDE DINITRATE ER",
              "ISOSORBIDE MONONITRATE ER": "ISOSORBIDE MONONITRATE ER",
              "NITROGLYCERIN": "NITROGLYCERIN"
            },
            "QUALITY CARE PRODUCTS": {
              "ISOSORBIDE MONONITRATE ER": "ISOSORBIDE MONONITRATE ER"
            },
            "ROUSES POINT": {
              "NITROGLYCERIN": "NITROGLYCERIN"
            },
            "SANDOZ": {
              "ISOSORBIDE DINITRATE": "ISOSORBIDE DINITRATE",
              "NITRO-BID": "NITRO-BID"
            },
            "SKY PACKAGING": {
              "ISOSORBIDE DINITRATE": "ISOSORBIDE DINITRATE"
            },
            "ST MARY'S MPP": {
              "ISOSORBIDE MONONITRATE ER": "ISOSORBIDE MONONITRATE ER"
            },
            "SUN PHARMACEUTICALS": {
              "ISOSORBIDE DINITRATE ER": "ISOSORBIDE DINITRATE ER"
            },
            "TIME-CAP LABS": {
              "NITRO-TIME": "NITRO-TIME"
            },
            "TORRENT PHARMACEUTICALS": {
              "ISOSORBIDE MONONITRATE ER": "ISOSORBIDE MONONITRATE ER"
            },
            "TRIMARC LABORATORIES": {
              "NITROGLYCERIN ER": "NITROGLYCERIN ER"
            },
            "VANGARD": {
              "ISOSORBIDE DINITRATE": "ISOSORBIDE DINITRATE"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "ISOSORBIDE DINITRATE": "ISOSORBIDE DINITRATE",
              "ISOSORBIDE MONONITRATE ER": "ISOSORBIDE MONONITRATE ER",
              "NITROGLYCERIN": "NITROGLYCERIN"
            }
          }
        }
      },
      "ANTIANXIETY AGENTS": {
        "Antianxiety Agents - Misc.": {
          "Antianxiety Agents - Misc.": {
            "A-S MEDICATION SOLUTIONS": {
              "BUSPIRONE HCL": "BUSPIRONE HCL",
              "HYDROXYZINE HCL": "HYDROXYZINE HCL",
              "HYDROXYZINE PAMOATE": "HYDROXYZINE PAMOATE"
            },
            "ACCORD HEALTHCARE": {
              "BUSPIRONE HCL": "BUSPIRONE HCL"
            },
            "AIDAREX PHARMACEUTICALS": {
              "BUSPIRONE HCL": "BUSPIRONE HCL",
              "HYDROXYZINE HCL": "HYDROXYZINE HCL",
              "HYDROXYZINE PAMOATE": "HYDROXYZINE PAMOATE"
            },
            "ALEMBIC PHARMACEUTICALS": {
              "MEPROBAMATE": "MEPROBAMATE"
            },
            "ALTURA PHARMACEUTICALS": {
              "BUSPIRONE HCL": "BUSPIRONE HCL",
              "HYDROXYZINE HCL": "HYDROXYZINE HCL",
              "HYDROXYZINE PAMOATE": "HYDROXYZINE PAMOATE"
            },
            "AMERICAN HEALTH PACKAGING": {
              "BUSPIRONE HCL": "BUSPIRONE HCL",
              "HYDROXYZINE HCL": "HYDROXYZINE HCL",
              "HYDROXYZINE PAMOATE": "HYDROXYZINE PAMOATE"
            },
            "AMERICAN REGENT": {
              "DROPERIDOL": "DROPERIDOL",
              "HYDROXYZINE HCL": "HYDROXYZINE HCL"
            },
            "AMNEAL PHARMACEUTICALS": {
              "BUSPIRONE HCL": "BUSPIRONE HCL",
              "HYDROXYZINE PAMOATE": "HYDROXYZINE PAMOATE"
            },
            "APHENA PHARMA SOLUTIONS": {
              "BUSPIRONE HCL": "BUSPIRONE HCL",
              "HYDROXYZINE PAMOATE": "HYDROXYZINE PAMOATE"
            },
            "APNAR PHARMA": {
              "BUSPIRONE HCL": "BUSPIRONE HCL"
            },
            "ATLANTIC BIOLOGICALS": {
              "HYDROXYZINE HCL": "HYDROXYZINE HCL"
            },
            "AVET PHARMACEUTICALS": {
              "BUSPIRONE HCL": "BUSPIRONE HCL",
              "HYDROXYZINE HCL": "HYDROXYZINE HCL",
              "HYDROXYZINE PAMOATE": "HYDROXYZINE PAMOATE"
            },
            "AVKARE": {
              "BUSPIRONE HCL": "BUSPIRONE HCL",
              "HYDROXYZINE PAMOATE": "HYDROXYZINE PAMOATE"
            },
            "AVPAK": {
              "BUSPIRONE HCL": "BUSPIRONE HCL"
            },
            "BLENHEIM PHARMACAL": {
              "BUSPIRONE HCL": "BUSPIRONE HCL",
              "HYDROXYZINE HCL": "HYDROXYZINE HCL"
            },
            "BRYANT RANCH PREPACK": {
              "BUSPIRONE HCL": "BUSPIRONE HCL",
              "HYDROXYZINE HCL": "HYDROXYZINE HCL",
              "HYDROXYZINE PAMOATE": "HYDROXYZINE PAMOATE"
            },
            "CARDINAL HEALTH REPACKAGING SO": {
              "HYDROXYZINE HCL": "HYDROXYZINE HCL"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "HYDROXYZINE HCL": "HYDROXYZINE HCL"
            },
            "DENTON PHARMA": {
              "HYDROXYZINE HCL": "HYDROXYZINE HCL"
            },
            "DIRECT RX": {
              "BUSPIRONE HCL": "BUSPIRONE HCL",
              "HYDROXYZINE HCL": "HYDROXYZINE HCL",
              "HYDROXYZINE PAMOATE": "HYDROXYZINE PAMOATE"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "MEPROBAMATE": "MEPROBAMATE"
            },
            "EPIC PHARMA": {
              "BUSPIRONE HCL": "BUSPIRONE HCL",
              "HYDROXYZINE HCL": "HYDROXYZINE HCL"
            },
            "GLENMARK PHARMACEUTICALS": {
              "HYDROXYZINE HCL": "HYDROXYZINE HCL"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "BUSPIRONE HCL": "BUSPIRONE HCL",
              "HYDROXYZINE HCL": "HYDROXYZINE HCL",
              "HYDROXYZINE PAMOATE": "HYDROXYZINE PAMOATE"
            },
            "GREENSTONE": {
              "HYDROXYZINE PAMOATE": "HYDROXYZINE PAMOATE"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "BUSPIRONE HCL": "BUSPIRONE HCL",
              "HYDROXYZINE HCL": "HYDROXYZINE HCL",
              "HYDROXYZINE PAMOATE": "HYDROXYZINE PAMOATE"
            },
            "HARRIS PHARMACEUTICAL": {
              "HYDROXYZINE HCL": "HYDROXYZINE HCL"
            },
            "HERITAGE PHARMACEUTICALS": {
              "MEPROBAMATE": "MEPROBAMATE"
            },
            "HOSPIRA": {
              "DROPERIDOL": "DROPERIDOL"
            },
            "IMPAX GENERICS": {
              "BUSPIRONE HCL": "BUSPIRONE HCL",
              "HYDROXYZINE PAMOATE": "HYDROXYZINE PAMOATE"
            },
            "KAISER FOUNDATION HOSPITAL": {
              "HYDROXYZINE HCL": "HYDROXYZINE HCL"
            },
            "KVK TECH": {
              "HYDROXYZINE HCL": "HYDROXYZINE HCL"
            },
            "LETCO MEDICAL": {
              "HYDROXYZINE PAMOATE": "HYDROXYZINE PAMOATE"
            },
            "LIBERTY PHARMACEUTICAL": {
              "BUSPIRONE HCL": "BUSPIRONE HCL",
              "HYDROXYZINE HCL": "HYDROXYZINE HCL",
              "HYDROXYZINE PAMOATE": "HYDROXYZINE PAMOATE"
            },
            "MAJOR PHARMACEUTICALS": {
              "BUSPIRONE HCL": "BUSPIRONE HCL",
              "HYDROXYZINE HCL": "HYDROXYZINE HCL",
              "HYDROXYZINE PAMOATE": "HYDROXYZINE PAMOATE"
            },
            "MEDISCA": {
              "DROPERIDOL": "DROPERIDOL"
            },
            "MEDVANTX": {
              "HYDROXYZINE HCL": "HYDROXYZINE HCL"
            },
            "MORTON GROVE PHARMACEUTICALS": {
              "HYDROXYZINE HCL": "HYDROXYZINE HCL"
            },
            "MYLAN": {
              "BUSPIRONE HCL": "BUSPIRONE HCL",
              "HYDROXYZINE HCL": "HYDROXYZINE HCL"
            },
            "MYLAN INSTITUTIONAL": {
              "BUSPIRONE HCL": "BUSPIRONE HCL",
              "HYDROXYZINE HCL": "HYDROXYZINE HCL",
              "HYDROXYZINE PAMOATE": "HYDROXYZINE PAMOATE"
            },
            "NEW HORIZON RX GROUP": {
              "BUSPIRONE HCL": "BUSPIRONE HCL"
            },
            "NORTHSTAR RX": {
              "HYDROXYZINE HCL": "HYDROXYZINE HCL"
            },
            "NUCARE PHARMACEUTICALS": {
              "BUSPIRONE HCL": "BUSPIRONE HCL",
              "HYDROXYZINE HCL": "HYDROXYZINE HCL",
              "HYDROXYZINE PAMOATE": "HYDROXYZINE PAMOATE"
            },
            "OXFORD PHARMACEUTICALS": {
              "BUSPIRONE HCL": "BUSPIRONE HCL"
            },
            "PAR PHARMACEUTICAL": {
              "BUSPIRONE HCL": "BUSPIRONE HCL"
            },
            "PAR PHARMACEUTICALS": {
              "HYDROXYZINE HCL": "HYDROXYZINE HCL"
            },
            "PCCA": {
              "DROPERIDOL": "DROPERIDOL",
              "HYDROXYZINE PAMOATE": "HYDROXYZINE PAMOATE"
            },
            "PDRX PHARMACEUTICAL": {
              "BUSPIRONE HCL": "BUSPIRONE HCL",
              "HYDROXYZINE HCL": "HYDROXYZINE HCL",
              "HYDROXYZINE PAMOATE": "HYDROXYZINE PAMOATE",
              "MEPROBAMATE": "MEPROBAMATE"
            },
            "PFIZER U.S.": {
              "VISTARIL": "VISTARIL"
            },
            "PHARMACEUTICAL ASSOCIATES": {
              "HYDROXYZINE HCL": "HYDROXYZINE HCL"
            },
            "PHARMEDIX": {
              "HYDROXYZINE PAMOATE": "HYDROXYZINE PAMOATE"
            },
            "PREFERRED PHARMACEUTICALS": {
              "BUSPIRONE HCL": "BUSPIRONE HCL",
              "HYDROXYZINE HCL": "HYDROXYZINE HCL",
              "HYDROXYZINE PAMOATE": "HYDROXYZINE PAMOATE"
            },
            "PROFICIENT RX": {
              "BUSPIRONE HCL": "BUSPIRONE HCL",
              "HYDROXYZINE HCL": "HYDROXYZINE HCL",
              "HYDROXYZINE PAMOATE": "HYDROXYZINE PAMOATE"
            },
            "QUALITY CARE": {
              "BUSPIRONE HCL": "BUSPIRONE HCL",
              "HYDROXYZINE HCL": "HYDROXYZINE HCL",
              "HYDROXYZINE PAMOATE": "HYDROXYZINE PAMOATE"
            },
            "QUALITY CARE PRODUCTS": {
              "BUSPIRONE HCL": "BUSPIRONE HCL",
              "HYDROXYZINE HCL": "HYDROXYZINE HCL",
              "HYDROXYZINE PAMOATE": "HYDROXYZINE PAMOATE"
            },
            "RISING PHARMACEUTICALS": {
              "HYDROXYZINE PAMOATE": "HYDROXYZINE PAMOATE"
            },
            "RXCHANGE CO": {
              "BUSPIRONE HCL": "BUSPIRONE HCL",
              "HYDROXYZINE HCL": "HYDROXYZINE HCL",
              "HYDROXYZINE PAMOATE": "HYDROXYZINE PAMOATE"
            },
            "SANDOZ": {
              "HYDROXYZINE PAMOATE": "HYDROXYZINE PAMOATE"
            },
            "SILARX": {
              "HYDROXYZINE HCL": "HYDROXYZINE HCL"
            },
            "SKY PACKAGING": {
              "HYDROXYZINE HCL": "HYDROXYZINE HCL"
            },
            "SPECTRUM": {
              "HYDROXYZINE PAMOATE": "HYDROXYZINE PAMOATE"
            },
            "ST MARY'S MPP": {
              "BUSPIRONE HCL": "BUSPIRONE HCL",
              "HYDROXYZINE HCL": "HYDROXYZINE HCL",
              "HYDROXYZINE PAMOATE": "HYDROXYZINE PAMOATE"
            },
            "STRIDES PHARMA": {
              "BUSPIRONE HCL": "BUSPIRONE HCL"
            },
            "TEVA PHARMACEUTICALS USA": {
              "BUSPIRONE HCL": "BUSPIRONE HCL",
              "HYDROXYZINE HCL": "HYDROXYZINE HCL",
              "HYDROXYZINE PAMOATE": "HYDROXYZINE PAMOATE"
            },
            "UNICHEM PHARMACEUTICALS": {
              "BUSPIRONE HCL": "BUSPIRONE HCL"
            },
            "UNIT DOSE SERVICES": {
              "BUSPIRONE HCL": "BUSPIRONE HCL",
              "HYDROXYZINE HCL": "HYDROXYZINE HCL"
            },
            "VA CMOP DALLAS": {
              "BUSPIRONE HCL": "BUSPIRONE HCL"
            },
            "YILING PHARMACEUTICAL": {
              "BUSPIRONE HCL": "BUSPIRONE HCL"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "BUSPIRONE HCL": "BUSPIRONE HCL"
            }
          }
        },
        "Benzodiazepines": {
          "Benzodiazepines": {
            "A-S MEDICATION SOLUTIONS": {
              "ALPRAZOLAM": "ALPRAZOLAM",
              "ALPRAZOLAM ER": "ALPRAZOLAM ER",
              "ATIVAN": "ATIVAN",
              "CHLORDIAZEPOXIDE HCL": "CHLORDIAZEPOXIDE HCL",
              "DIAZEPAM": "DIAZEPAM",
              "LORAZEPAM": "LORAZEPAM"
            },
            "ACTAVIS ELIZABETH": {
              "ALPRAZOLAM": "ALPRAZOLAM",
              "ALPRAZOLAM ER": "ALPRAZOLAM ER",
              "OXAZEPAM": "OXAZEPAM"
            },
            "ACTAVIS PHARMA": {
              "DIAZEPAM": "DIAZEPAM",
              "LORAZEPAM": "LORAZEPAM"
            },
            "AIDAREX PHARMACEUTICALS": {
              "ALPRAZOLAM": "ALPRAZOLAM",
              "ALPRAZOLAM ER": "ALPRAZOLAM ER",
              "CHLORDIAZEPOXIDE HCL": "CHLORDIAZEPOXIDE HCL",
              "DIAZEPAM": "DIAZEPAM",
              "LORAZEPAM": "LORAZEPAM"
            },
            "AKORN": {
              "LORAZEPAM": "LORAZEPAM"
            },
            "ALTURA PHARMACEUTICALS": {
              "ALPRAZOLAM": "ALPRAZOLAM",
              "CHLORDIAZEPOXIDE HCL": "CHLORDIAZEPOXIDE HCL",
              "CLORAZEPATE DIPOTASSIUM": "CLORAZEPATE DIPOTASSIUM",
              "DIAZEPAM": "DIAZEPAM",
              "LORAZEPAM": "LORAZEPAM"
            },
            "AMERICAN HEALTH PACKAGING": {
              "ALPRAZOLAM": "ALPRAZOLAM",
              "CLORAZEPATE DIPOTASSIUM": "CLORAZEPATE DIPOTASSIUM",
              "LORAZEPAM": "LORAZEPAM",
              "OXAZEPAM": "OXAZEPAM"
            },
            "AMNEAL PHARMACEUTICALS": {
              "LORAZEPAM": "LORAZEPAM"
            },
            "APHENA PHARMA SOLUTIONS": {
              "ALPRAZOLAM": "ALPRAZOLAM",
              "CHLORDIAZEPOXIDE HCL": "CHLORDIAZEPOXIDE HCL",
              "CLORAZEPATE DIPOTASSIUM": "CLORAZEPATE DIPOTASSIUM",
              "DIAZEPAM": "DIAZEPAM",
              "LORAZEPAM": "LORAZEPAM",
              "OXAZEPAM": "OXAZEPAM"
            },
            "AUROBINDO PHARMA": {
              "ALPRAZOLAM": "ALPRAZOLAM",
              "ALPRAZOLAM ER": "ALPRAZOLAM ER"
            },
            "AUROBINDO PHARMA USA": {
              "LORAZEPAM": "LORAZEPAM"
            },
            "AVKARE": {
              "CHLORDIAZEPOXIDE HCL": "CHLORDIAZEPOXIDE HCL"
            },
            "BAUSCH HEALTH": {
              "ATIVAN": "ATIVAN"
            },
            "BIONPHARMA": {
              "ALPRAZOLAM": "ALPRAZOLAM"
            },
            "BLENHEIM PHARMACAL": {
              "ALPRAZOLAM": "ALPRAZOLAM",
              "DIAZEPAM": "DIAZEPAM",
              "LORAZEPAM": "LORAZEPAM"
            },
            "BRECKENRIDGE": {
              "ALPRAZOLAM": "ALPRAZOLAM"
            },
            "BRYANT RANCH PREPACK": {
              "ALPRAZOLAM": "ALPRAZOLAM",
              "ALPRAZOLAM ER": "ALPRAZOLAM ER",
              "CHLORDIAZEPOXIDE HCL": "CHLORDIAZEPOXIDE HCL",
              "CLORAZEPATE DIPOTASSIUM": "CLORAZEPATE DIPOTASSIUM",
              "DIAZEPAM": "DIAZEPAM",
              "LORAZEPAM": "LORAZEPAM"
            },
            "CIVICA": {
              "DIAZEPAM": "DIAZEPAM",
              "LORAZEPAM": "LORAZEPAM"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "ALPRAZOLAM": "ALPRAZOLAM",
              "DIAZEPAM": "DIAZEPAM"
            },
            "DASH PHARMACEUTICALS": {
              "DIAZEPAM": "DIAZEPAM"
            },
            "DIRECT RX": {
              "ALPRAZOLAM": "ALPRAZOLAM",
              "ALPRAZOLAM ER": "ALPRAZOLAM ER",
              "CHLORDIAZEPOXIDE HCL": "CHLORDIAZEPOXIDE HCL",
              "DIAZEPAM": "DIAZEPAM",
              "LORAZEPAM": "LORAZEPAM"
            },
            "GENENTECH": {
              "VALIUM": "VALIUM"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "ALPRAZOLAM": "ALPRAZOLAM",
              "CHLORDIAZEPOXIDE HCL": "CHLORDIAZEPOXIDE HCL"
            },
            "GREENSTONE": {
              "ALPRAZOLAM": "ALPRAZOLAM",
              "ALPRAZOLAM XR": "ALPRAZOLAM XR"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "ALPRAZOLAM": "ALPRAZOLAM",
              "ALPRAZOLAM ER": "ALPRAZOLAM ER",
              "CHLORDIAZEPOXIDE HCL": "CHLORDIAZEPOXIDE HCL",
              "CLORAZEPATE DIPOTASSIUM": "CLORAZEPATE DIPOTASSIUM",
              "DIAZEPAM": "DIAZEPAM",
              "LORAZEPAM": "LORAZEPAM",
              "OXAZEPAM": "OXAZEPAM",
              "XANAX": "XANAX"
            },
            "HI-TECH": {
              "LORAZEPAM": "LORAZEPAM"
            },
            "HIKMA": {
              "ALPRAZOLAM INTENSOL": "ALPRAZOLAM INTENSOL",
              "ATIVAN": "ATIVAN",
              "DIAZEPAM": "DIAZEPAM",
              "DIAZEPAM INTENSOL": "DIAZEPAM INTENSOL",
              "LORAZEPAM": "LORAZEPAM",
              "LORAZEPAM INTENSOL": "LORAZEPAM INTENSOL"
            },
            "HOSPIRA": {
              "DIAZEPAM": "DIAZEPAM",
              "LORAZEPAM": "LORAZEPAM"
            },
            "INTRNTL MEDICATION SYSTEM": {
              "LORAZEPAM": "LORAZEPAM"
            },
            "KAISER FOUNDATION HOSPITAL": {
              "LORAZEPAM": "LORAZEPAM"
            },
            "LANNETT": {
              "DIAZEPAM": "DIAZEPAM"
            },
            "LEADING PHARMA": {
              "LORAZEPAM": "LORAZEPAM"
            },
            "LIBERTY PHARMACEUTICAL": {
              "ALPRAZOLAM": "ALPRAZOLAM",
              "CHLORDIAZEPOXIDE HCL": "CHLORDIAZEPOXIDE HCL",
              "DIAZEPAM": "DIAZEPAM",
              "LORAZEPAM": "LORAZEPAM",
              "OXAZEPAM": "OXAZEPAM"
            },
            "LOHXA": {
              "LORAZEPAM": "LORAZEPAM"
            },
            "MAJOR PHARMACEUTICALS": {
              "ALPRAZOLAM": "ALPRAZOLAM",
              "DIAZEPAM": "DIAZEPAM",
              "LORAZEPAM": "LORAZEPAM"
            },
            "MAYNE PHARMA": {
              "DIAZEPAM": "DIAZEPAM"
            },
            "MEDVANTX": {
              "ALPRAZOLAM": "ALPRAZOLAM"
            },
            "MERIDIAN MEDICAL TECHNOLOGIES": {
              "DIAZEPAM": "DIAZEPAM"
            },
            "MYLAN": {
              "ALPRAZOLAM": "ALPRAZOLAM",
              "ALPRAZOLAM ER": "ALPRAZOLAM ER",
              "CLORAZEPATE DIPOTASSIUM": "CLORAZEPATE DIPOTASSIUM",
              "DIAZEPAM": "DIAZEPAM",
              "LORAZEPAM": "LORAZEPAM"
            },
            "MYLAN INSTITUTIONAL": {
              "ALPRAZOLAM": "ALPRAZOLAM",
              "CHLORDIAZEPOXIDE HCL": "CHLORDIAZEPOXIDE HCL",
              "DIAZEPAM": "DIAZEPAM",
              "LORAZEPAM": "LORAZEPAM"
            },
            "NEPHRON STERILE COMPOUNDING": {
              "DIAZEPAM": "DIAZEPAM"
            },
            "NOVAPLUS/HIKMA": {
              "LORAZEPAM": "LORAZEPAM"
            },
            "NOVAPLUS/HOSPIRA": {
              "DIAZEPAM": "DIAZEPAM"
            },
            "NUCARE PHARMACEUTICALS": {
              "ALPRAZOLAM": "ALPRAZOLAM",
              "ATIVAN": "ATIVAN",
              "CHLORDIAZEPOXIDE HCL": "CHLORDIAZEPOXIDE HCL",
              "DIAZEPAM": "DIAZEPAM",
              "LORAZEPAM": "LORAZEPAM",
              "OXAZEPAM": "OXAZEPAM",
              "XANAX XR": "XANAX XR"
            },
            "PAR PHARMACEUTICAL": {
              "ALPRAZOLAM": "ALPRAZOLAM"
            },
            "PAR PHARMACEUTICALS": {
              "DIAZEPAM": "DIAZEPAM",
              "LORAZEPAM": "LORAZEPAM"
            },
            "PDRX PHARMACEUTICAL": {
              "ALPRAZOLAM": "ALPRAZOLAM",
              "CHLORDIAZEPOXIDE HCL": "CHLORDIAZEPOXIDE HCL",
              "CLORAZEPATE DIPOTASSIUM": "CLORAZEPATE DIPOTASSIUM",
              "DIAZEPAM": "DIAZEPAM",
              "LORAZEPAM": "LORAZEPAM",
              "VALIUM": "VALIUM",
              "XANAX": "XANAX"
            },
            "PDRX PHARMACEUTICALS": {
              "ALPRAZOLAM": "ALPRAZOLAM"
            },
            "PERRIGO": {
              "LORAZEPAM": "LORAZEPAM"
            },
            "PFIZER U.S.": {
              "XANAX": "XANAX",
              "XANAX XR": "XANAX XR"
            },
            "PHARMACEUTICAL ASSOCIATES": {
              "LORAZEPAM": "LORAZEPAM"
            },
            "PHARMEDIUM SERVICES": {
              "LORAZEPAM-DEXTROSE": "LORAZEPAM-DEXTROSE",
              "LORAZEPAM-SODIUM CHLORIDE": "LORAZEPAM-SODIUM CHLORIDE"
            },
            "PHARMEDIX": {
              "ALPRAZOLAM": "ALPRAZOLAM"
            },
            "PRECISION DOSE, INC": {
              "DIAZEPAM": "DIAZEPAM"
            },
            "PREFERRED PHARMACEUTICALS": {
              "ALPRAZOLAM": "ALPRAZOLAM",
              "ALPRAZOLAM ER": "ALPRAZOLAM ER",
              "DIAZEPAM": "DIAZEPAM",
              "LORAZEPAM": "LORAZEPAM"
            },
            "PRESCRIPTION SOLUTIONS, INC.": {
              "CLORAZEPATE DIPOTASSIUM": "CLORAZEPATE DIPOTASSIUM"
            },
            "PROFICIENT RX": {
              "ALPRAZOLAM": "ALPRAZOLAM",
              "DIAZEPAM": "DIAZEPAM",
              "LORAZEPAM": "LORAZEPAM"
            },
            "QUALITY CARE": {
              "ALPRAZOLAM": "ALPRAZOLAM",
              "ALPRAZOLAM XR": "ALPRAZOLAM XR",
              "CHLORDIAZEPOXIDE HCL": "CHLORDIAZEPOXIDE HCL",
              "CLORAZEPATE DIPOTASSIUM": "CLORAZEPATE DIPOTASSIUM",
              "DIAZEPAM": "DIAZEPAM",
              "LORAZEPAM": "LORAZEPAM",
              "OXAZEPAM": "OXAZEPAM",
              "VALIUM": "VALIUM",
              "XANAX": "XANAX"
            },
            "QUALITY CARE PRODUCTS": {
              "ALPRAZOLAM": "ALPRAZOLAM",
              "ALPRAZOLAM XR": "ALPRAZOLAM XR",
              "DIAZEPAM": "DIAZEPAM",
              "LORAZEPAM": "LORAZEPAM"
            },
            "RECORDATI RARE DISEASES": {
              "TRANXENE-T": "TRANXENE-T"
            },
            "RXCHANGE CO": {
              "ALPRAZOLAM": "ALPRAZOLAM",
              "DIAZEPAM": "DIAZEPAM",
              "LORAZEPAM": "LORAZEPAM"
            },
            "RXPAK MCKESSON": {
              "LORAZEPAM": "LORAZEPAM"
            },
            "SANDOZ": {
              "ALPRAZOLAM": "ALPRAZOLAM",
              "LORAZEPAM": "LORAZEPAM",
              "OXAZEPAM": "OXAZEPAM"
            },
            "SKY PACKAGING": {
              "ALPRAZOLAM": "ALPRAZOLAM",
              "DIAZEPAM": "DIAZEPAM",
              "LORAZEPAM": "LORAZEPAM"
            },
            "SOLCO HEALTHCARE": {
              "CHLORDIAZEPOXIDE HCL": "CHLORDIAZEPOXIDE HCL"
            },
            "ST MARY'S MPP": {
              "ALPRAZOLAM": "ALPRAZOLAM",
              "DIAZEPAM": "DIAZEPAM",
              "LORAZEPAM": "LORAZEPAM"
            },
            "SUN PHARMACEUTICAL": {
              "ALPRAZOLAM": "ALPRAZOLAM"
            },
            "TARO": {
              "CLORAZEPATE DIPOTASSIUM": "CLORAZEPATE DIPOTASSIUM"
            },
            "TEVA PHARMACEUTICALS USA": {
              "CHLORDIAZEPOXIDE HCL": "CHLORDIAZEPOXIDE HCL",
              "DIAZEPAM": "DIAZEPAM"
            },
            "TRUPHARMA": {
              "OXAZEPAM": "OXAZEPAM"
            },
            "UNIT DOSE SERVICES": {
              "ALPRAZOLAM": "ALPRAZOLAM",
              "DIAZEPAM": "DIAZEPAM",
              "LORAZEPAM": "LORAZEPAM"
            },
            "UPSHER-SMITH": {
              "LORAZEPAM": "LORAZEPAM"
            }
          }
        }
      },
      "ANTIARRHYTHMICS": {
        "Antiarrhythmics - Misc.": {
          "Antiarrhythmics - Misc.": {
            "A-S MEDICATION SOLUTIONS": {
              "ADENOSINE": "ADENOSINE"
            },
            "AKORN": {
              "ADENOSINE": "ADENOSINE"
            },
            "ASTELLAS": {
              "ADENOCARD": "ADENOCARD"
            },
            "FRESENIUS KABI USA": {
              "ADENOSINE": "ADENOSINE"
            },
            "HIKMA": {
              "ADENOSINE": "ADENOSINE"
            },
            "MYLAN INSTITUTIONAL": {
              "ADENOSINE": "ADENOSINE"
            },
            "NOVAPLUS / AKORN": {
              "ADENOSINE": "ADENOSINE"
            },
            "PHARMEDIUM SERVICES": {
              "ADENOSINE": "ADENOSINE"
            },
            "SAGENT PHARMACEUTICAL": {
              "ADENOSINE": "ADENOSINE"
            }
          }
        },
        "Antiarrhythmics Type I-A": {
          "Antiarrhythmics Type I-A": {
            "ACTAVIS PHARMA": {
              "DISOPYRAMIDE PHOSPHATE": "DISOPYRAMIDE PHOSPHATE"
            },
            "AVKARE": {
              "DISOPYRAMIDE PHOSPHATE": "DISOPYRAMIDE PHOSPHATE"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "DISOPYRAMIDE PHOSPHATE": "DISOPYRAMIDE PHOSPHATE",
              "QUINIDINE GLUCONATE ER": "QUINIDINE GLUCONATE ER"
            },
            "GREENSTONE": {
              "DISOPYRAMIDE PHOSPHATE": "DISOPYRAMIDE PHOSPHATE"
            },
            "HOSPIRA": {
              "PROCAINAMIDE HCL": "PROCAINAMIDE HCL"
            },
            "INTRNTL MEDICATION SYSTEM": {
              "PROCAINAMIDE HCL": "PROCAINAMIDE HCL"
            },
            "LETCO MEDICAL": {
              "PROCAINAMIDE HCL": "PROCAINAMIDE HCL"
            },
            "LILLY": {
              "QUINIDINE GLUCONATE": "QUINIDINE GLUCONATE"
            },
            "MAYNE PHARMA": {
              "DISOPYRAMIDE PHOSPHATE": "DISOPYRAMIDE PHOSPHATE"
            },
            "NEXUS PHARMA": {
              "PROCAINAMIDE HCL": "PROCAINAMIDE HCL"
            },
            "NOVAPLUS/HOSPIRA": {
              "PROCAINAMIDE HCL": "PROCAINAMIDE HCL"
            },
            "PCCA": {
              "PROCAINAMIDE HCL": "PROCAINAMIDE HCL"
            },
            "PFIZER U.S.": {
              "NORPACE": "NORPACE",
              "NORPACE CR": "NORPACE CR"
            },
            "PRESCRIPTION SOLUTIONS, INC.": {
              "QUINIDINE GLUCONATE ER": "QUINIDINE GLUCONATE ER"
            },
            "RICHMOND PHARMACEUTICALS": {
              "QUINIDINE GLUCONATE ER": "QUINIDINE GLUCONATE ER"
            },
            "SANDOZ": {
              "QUINIDINE SULFATE": "QUINIDINE SULFATE"
            },
            "SPECTRUM": {
              "PROCAINAMIDE HCL": "PROCAINAMIDE HCL"
            },
            "SUN PHARMACEUTICALS": {
              "QUINIDINE GLUCONATE ER": "QUINIDINE GLUCONATE ER"
            },
            "TEVA PHARMACEUTICALS USA": {
              "DISOPYRAMIDE PHOSPHATE": "DISOPYRAMIDE PHOSPHATE",
              "QUINIDINE SULFATE ER": "QUINIDINE SULFATE ER"
            }
          }
        },
        "Antiarrhythmics Type I-B": {
          "Antiarrhythmics Type I-B": {
            "A-S MEDICATION SOLUTIONS": {
              "LIDOCAINE HCL (CARDIAC)": "LIDOCAINE HCL (CARDIAC)",
              "XYLOCAINE (CARDIAC)": "XYLOCAINE (CARDIAC)"
            },
            "ANI  PHARMACEUTICALS": {
              "MEXILETINE HCL": "MEXILETINE HCL"
            },
            "AVKARE": {
              "MEXILETINE HCL": "MEXILETINE HCL"
            },
            "B. BRAUN MEDICAL": {
              "LIDOCAINE IN D5W": "LIDOCAINE IN D5W"
            },
            "BAXTER HEALTHCARE CORP": {
              "LIDOCAINE IN D5W": "LIDOCAINE IN D5W"
            },
            "FRESENIUS KABI USA": {
              "LIDOCAINE HCL (CARDIAC) PF": "LIDOCAINE HCL (CARDIAC) PF",
              "XYLOCAINE (CARDIAC)": "XYLOCAINE (CARDIAC)"
            },
            "HOSPIRA": {
              "LIDOCAINE HCL (CARDIAC)": "LIDOCAINE HCL (CARDIAC)",
              "LIDOCAINE HCL (CARDIAC) PF": "LIDOCAINE HCL (CARDIAC) PF"
            },
            "INTRNTL MEDICATION SYSTEM": {
              "LIDOCAINE HCL (CARDIAC)": "LIDOCAINE HCL (CARDIAC)"
            },
            "LANNETT": {
              "MEXILETINE HCL": "MEXILETINE HCL"
            },
            "NEPHRON STERILE COMPOUNDING": {
              "LIDOCAINE IN D5W": "LIDOCAINE IN D5W"
            },
            "QUALITY CARE": {
              "MEXILETINE HCL": "MEXILETINE HCL"
            },
            "TEVA PHARMACEUTICALS USA": {
              "MEXILETINE HCL": "MEXILETINE HCL"
            }
          }
        },
        "Antiarrhythmics Type I-C": {
          "Antiarrhythmics Type I-C": {
            "A-S MEDICATION SOLUTIONS": {
              "FLECAINIDE ACETATE": "FLECAINIDE ACETATE",
              "PROPAFENONE HCL": "PROPAFENONE HCL"
            },
            "ACTAVIS PHARMA": {
              "PROPAFENONE HCL": "PROPAFENONE HCL",
              "PROPAFENONE HCL ER": "PROPAFENONE HCL ER"
            },
            "ALVOGEN": {
              "FLECAINIDE ACETATE": "FLECAINIDE ACETATE"
            },
            "AMERICAN HEALTH PACKAGING": {
              "FLECAINIDE ACETATE": "FLECAINIDE ACETATE",
              "PROPAFENONE HCL": "PROPAFENONE HCL",
              "PROPAFENONE HCL ER": "PROPAFENONE HCL ER"
            },
            "AMNEAL PHARMACEUTICALS": {
              "FLECAINIDE ACETATE": "FLECAINIDE ACETATE"
            },
            "ANI  PHARMACEUTICALS": {
              "FLECAINIDE ACETATE": "FLECAINIDE ACETATE",
              "PROPAFENONE HCL": "PROPAFENONE HCL"
            },
            "AUROBINDO PHARMA": {
              "FLECAINIDE ACETATE": "FLECAINIDE ACETATE",
              "PROPAFENONE HCL": "PROPAFENONE HCL"
            },
            "AVKARE": {
              "FLECAINIDE ACETATE": "FLECAINIDE ACETATE",
              "PROPAFENONE HCL ER": "PROPAFENONE HCL ER"
            },
            "AVPAK": {
              "FLECAINIDE ACETATE": "FLECAINIDE ACETATE"
            },
            "BRYANT RANCH PREPACK": {
              "PROPAFENONE HCL": "PROPAFENONE HCL"
            },
            "GLAXO SMITH KLINE": {
              "RYTHMOL": "RYTHMOL",
              "RYTHMOL SR": "RYTHMOL SR"
            },
            "GLENMARK PHARMACEUTICALS": {
              "PROPAFENONE HCL ER": "PROPAFENONE HCL ER"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "FLECAINIDE ACETATE": "FLECAINIDE ACETATE",
              "PROPAFENONE HCL": "PROPAFENONE HCL",
              "PROPAFENONE HCL ER": "PROPAFENONE HCL ER"
            },
            "HIKMA": {
              "FLECAINIDE ACETATE": "FLECAINIDE ACETATE"
            },
            "LIBERTY PHARMACEUTICAL": {
              "FLECAINIDE ACETATE": "FLECAINIDE ACETATE"
            },
            "MYLAN": {
              "PROPAFENONE HCL ER": "PROPAFENONE HCL ER"
            },
            "MYLAN INSTITUTIONAL": {
              "PROPAFENONE HCL": "PROPAFENONE HCL"
            },
            "NORTHSTAR RX": {
              "PROPAFENONE HCL ER": "PROPAFENONE HCL ER"
            },
            "PAR PHARMACEUTICAL": {
              "PROPAFENONE HCL ER": "PROPAFENONE HCL ER"
            },
            "PAR PHARMACEUTICALS": {
              "PROPAFENONE HCL": "PROPAFENONE HCL"
            },
            "PRASCO LABORATORIES": {
              "PROPAFENONE HCL ER": "PROPAFENONE HCL ER"
            },
            "RISING PHARMACEUTICALS": {
              "FLECAINIDE ACETATE": "FLECAINIDE ACETATE"
            },
            "SKY PACKAGING": {
              "PROPAFENONE HCL": "PROPAFENONE HCL"
            },
            "SUN PHARMACEUTICALS": {
              "PROPAFENONE HCL": "PROPAFENONE HCL"
            },
            "TEVA PHARMACEUTICALS USA": {
              "FLECAINIDE ACETATE": "FLECAINIDE ACETATE"
            },
            "TWI PHARMACEUTICALS": {
              "PROPAFENONE HCL ER": "PROPAFENONE HCL ER"
            },
            "UPSHER-SMITH": {
              "PROPAFENONE HCL ER": "PROPAFENONE HCL ER"
            },
            "VITRUVIAS THERAPEUTICS": {
              "PROPAFENONE HCL ER": "PROPAFENONE HCL ER"
            },
            "WILSHIRE PHARMACEUTICALS": {
              "PROPAFENONE HCL ER": "PROPAFENONE HCL ER"
            }
          }
        },
        "Antiarrhythmics Type III": {
          "Antiarrhythmics Type III": {
            "A-S MEDICATION SOLUTIONS": {
              "AMIODARONE HCL": "AMIODARONE HCL"
            },
            "ACCORD HEALTHCARE": {
              "DOFETILIDE": "DOFETILIDE"
            },
            "AMERICAN HEALTH PACKAGING": {
              "AMIODARONE HCL": "AMIODARONE HCL"
            },
            "ANI  PHARMACEUTICALS": {
              "BRETYLIUM TOSYLATE": "BRETYLIUM TOSYLATE"
            },
            "APHENA PHARMA SOLUTIONS": {
              "PACERONE": "PACERONE"
            },
            "AUROBINDO PHARMA": {
              "AMIODARONE HCL": "AMIODARONE HCL",
              "DOFETILIDE": "DOFETILIDE"
            },
            "AUROMEDICS PHARMA": {
              "AMIODARONE HCL": "AMIODARONE HCL"
            },
            "AVKARE": {
              "AMIODARONE HCL": "AMIODARONE HCL",
              "DOFETILIDE": "DOFETILIDE"
            },
            "AVPAK": {
              "AMIODARONE HCL": "AMIODARONE HCL"
            },
            "BAXTER HEALTHCARE CORP": {
              "NEXTERONE": "NEXTERONE"
            },
            "BIONPHARMA": {
              "DOFETILIDE": "DOFETILIDE"
            },
            "BRYANT RANCH PREPACK": {
              "AMIODARONE HCL": "AMIODARONE HCL"
            },
            "CAMERON PHARMACEUTICALS": {
              "AMIODARONE HCL": "AMIODARONE HCL"
            },
            "FRESENIUS KABI USA": {
              "AMIODARONE HCL": "AMIODARONE HCL"
            },
            "GREENSTONE": {
              "DOFETILIDE": "DOFETILIDE"
            },
            "HIKMA": {
              "AMIODARONE HCL": "AMIODARONE HCL"
            },
            "LIBERTY BIOSCIENCE": {
              "DOFETILIDE": "DOFETILIDE"
            },
            "MAJOR PHARMACEUTICALS": {
              "AMIODARONE HCL": "AMIODARONE HCL"
            },
            "MAYNE PHARMA": {
              "AMIODARONE HCL": "AMIODARONE HCL",
              "DOFETILIDE": "DOFETILIDE"
            },
            "MYLAN INSTITUTIONAL": {
              "AMIODARONE HCL": "AMIODARONE HCL",
              "IBUTILIDE FUMARATE": "IBUTILIDE FUMARATE"
            },
            "NORTHSTAR RX": {
              "AMIODARONE HCL": "AMIODARONE HCL",
              "DOFETILIDE": "DOFETILIDE"
            },
            "NOVADOZ PHARMACEUTICALS": {
              "DOFETILIDE": "DOFETILIDE"
            },
            "NOVAPLUS/PFIZER U.S.": {
              "CORVERT": "CORVERT"
            },
            "PFIZER U.S.": {
              "CORDARONE": "CORDARONE",
              "CORVERT": "CORVERT",
              "TIKOSYN": "TIKOSYN"
            },
            "PHARMEDIUM SERVICES": {
              "AMIODARONE HCL IN DEXTROSE": "AMIODARONE HCL IN DEXTROSE"
            },
            "QUALITY CARE": {
              "AMIODARONE HCL": "AMIODARONE HCL"
            },
            "SAGENT PHARMACEUTICAL": {
              "AMIODARONE HCL": "AMIODARONE HCL"
            },
            "SANDOZ": {
              "AMIODARONE HCL": "AMIODARONE HCL"
            },
            "SANOFI-AVENTIS U.S.": {
              "MULTAQ": "MULTAQ"
            },
            "SIGMAPHARM LABORATORIES": {
              "DOFETILIDE": "DOFETILIDE"
            },
            "SKY PACKAGING": {
              "AMIODARONE HCL": "AMIODARONE HCL"
            },
            "SUN PHARMACEUTICALS": {
              "DOFETILIDE": "DOFETILIDE"
            },
            "TARO": {
              "AMIODARONE HCL": "AMIODARONE HCL"
            },
            "TEVA PHARMACEUTICALS USA": {
              "AMIODARONE HCL": "AMIODARONE HCL"
            },
            "UPSHER-SMITH": {
              "PACERONE": "PACERONE"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "AMIODARONE HCL": "AMIODARONE HCL"
            }
          }
        }
      },
      "ANTIASTHMATIC AND BRONCHODILATOR AGENTS": {
        "Anti-Inflammatory Agents": {
          "Anti-Inflammatory Agents": {
            "FAGRON": {
              "CROMOLYN SODIUM": "CROMOLYN SODIUM"
            },
            "LETCO MEDICAL": {
              "CROMOLYN SODIUM": "CROMOLYN SODIUM"
            },
            "MEDISCA": {
              "CROMOLYN SODIUM": "CROMOLYN SODIUM"
            },
            "SPECTRUM": {
              "CROMOLYN SODIUM": "CROMOLYN SODIUM"
            },
            "TEVA PHARMACEUTICALS USA": {
              "CROMOLYN SODIUM": "CROMOLYN SODIUM"
            },
            "WOODWARD PHARMA SERVICES": {
              "CROMOLYN SODIUM": "CROMOLYN SODIUM"
            }
          }
        },
        "Antiasthmatic - Monoclonal Antibodies": {
          "Anti-IgE Monoclonal Antibodies": {
            "GENENTECH": {
              "XOLAIR": "XOLAIR"
            }
          },
          "Interleukin-5 Antagonists (IgG1 kappa)": {
            "ASTRAZENECA": {
              "FASENRA": "FASENRA",
              "FASENRA PEN": "FASENRA PEN"
            },
            "GLAXO SMITH KLINE": {
              "NUCALA": "NUCALA"
            }
          },
          "Interleukin-5 Antagonists (IgG4 kappa)": {
            "TEVA RESPIRATORY": {
              "CINQAIR": "CINQAIR"
            }
          }
        },
        "Asthma and Bronchodilator Agent Combinations": {
          "Xanthine-Expectorants": {
            "SJ PHARMACEUTICALS": {
              "DIFIL-G FORTE": "DIFIL-G FORTE"
            },
            "STEWART JACKSON": {
              "DIFIL-G FORTE": "DIFIL-G FORTE"
            }
          }
        },
        "Bronchodilators - Anticholinergics": {
          "Bronchodilators - Anticholinergics": {
            "A-S MEDICATION SOLUTIONS": {
              "ATROVENT HFA": "ATROVENT HFA",
              "IPRATROPIUM BROMIDE": "IPRATROPIUM BROMIDE"
            },
            "ACTAVIS PHARMA": {
              "IPRATROPIUM BROMIDE": "IPRATROPIUM BROMIDE"
            },
            "AIDAREX PHARMACEUTICALS": {
              "IPRATROPIUM BROMIDE": "IPRATROPIUM BROMIDE"
            },
            "AMERICAN HEALTH PACKAGING": {
              "IPRATROPIUM BROMIDE": "IPRATROPIUM BROMIDE"
            },
            "APOTHECA SUPPLY": {
              "IPRATROPIUM BROMIDE": "IPRATROPIUM BROMIDE"
            },
            "ASTRAZENECA": {
              "TUDORZA PRESSAIR": "TUDORZA PRESSAIR"
            },
            "AUROBINDO PHARMA": {
              "IPRATROPIUM BROMIDE": "IPRATROPIUM BROMIDE"
            },
            "BOEHRINGER INGELHEIM": {
              "ATROVENT HFA": "ATROVENT HFA",
              "SPIRIVA HANDIHALER": "SPIRIVA HANDIHALER",
              "SPIRIVA RESPIMAT": "SPIRIVA RESPIMAT"
            },
            "CARDINAL HEALTH REPACKAGING SO": {
              "IPRATROPIUM BROMIDE": "IPRATROPIUM BROMIDE"
            },
            "CIRCASSIA PHARMACEUTICAL": {
              "TUDORZA PRESSAIR": "TUDORZA PRESSAIR"
            },
            "FAGRON": {
              "IPRATROPIUM BROMIDE": "IPRATROPIUM BROMIDE"
            },
            "FREEDOM PHARMACEUTICALS": {
              "IPRATROPIUM BROMIDE": "IPRATROPIUM BROMIDE"
            },
            "GLAXO SMITH KLINE": {
              "INCRUSE ELLIPTA": "INCRUSE ELLIPTA"
            },
            "MEDISCA": {
              "IPRATROPIUM BROMIDE": "IPRATROPIUM BROMIDE"
            },
            "MYLAN": {
              "IPRATROPIUM BROMIDE": "IPRATROPIUM BROMIDE"
            },
            "MYLAN SPECIALTY L.P.": {
              "YUPELRI": "YUPELRI"
            },
            "NEPHRON PHARMACEUTICALS CORP.": {
              "IPRATROPIUM BROMIDE": "IPRATROPIUM BROMIDE"
            },
            "PCCA": {
              "IPRATROPIUM BROMIDE": "IPRATROPIUM BROMIDE"
            },
            "QUALITY CARE": {
              "IPRATROPIUM BROMIDE": "IPRATROPIUM BROMIDE",
              "SPIRIVA HANDIHALER": "SPIRIVA HANDIHALER"
            },
            "RITEDOSE PHARMACEUTICALS": {
              "IPRATROPIUM BROMIDE": "IPRATROPIUM BROMIDE"
            },
            "SANDOZ": {
              "IPRATROPIUM BROMIDE": "IPRATROPIUM BROMIDE"
            },
            "SPECTRUM": {
              "IPRATROPIUM BROMIDE": "IPRATROPIUM BROMIDE"
            },
            "ST MARY'S MPP": {
              "IPRATROPIUM BROMIDE": "IPRATROPIUM BROMIDE"
            },
            "SUN PHARMACEUTICALS": {
              "IPRATROPIUM BROMIDE": "IPRATROPIUM BROMIDE"
            },
            "SUNOVION PHARMACEUTICALS": {
              "LONHALA MAGNAIR REFILL KIT": "LONHALA MAGNAIR REFILL KIT",
              "LONHALA MAGNAIR STARTER KIT": "LONHALA MAGNAIR STARTER KIT",
              "SEEBRI NEOHALER": "SEEBRI NEOHALER"
            }
          }
        },
        "Leukotriene Modulators": {
          "5-Lipoxygenase Inhibitors": {
            "CHIESI USA": {
              "ZYFLO": "ZYFLO",
              "ZYFLO CR": "ZYFLO CR"
            },
            "LUPIN PHARMACEUTICALS": {
              "ZILEUTON ER": "ZILEUTON ER"
            },
            "PAR PHARMACEUTICAL": {
              "ZILEUTON ER": "ZILEUTON ER"
            },
            "PRASCO LABORATORIES": {
              "ZILEUTON ER": "ZILEUTON ER"
            },
            "RISING PHARMACEUTICALS": {
              "ZILEUTON ER": "ZILEUTON ER"
            }
          },
          "Leukotriene Receptor Antagonists": {
            "A-S MEDICATION SOLUTIONS": {
              "MONTELUKAST SODIUM": "MONTELUKAST SODIUM",
              "SINGULAIR": "SINGULAIR",
              "ZAFIRLUKAST": "ZAFIRLUKAST"
            },
            "ACCORD HEALTHCARE": {
              "MONTELUKAST SODIUM": "MONTELUKAST SODIUM"
            },
            "ACETRIS HEALTH": {
              "MONTELUKAST SODIUM": "MONTELUKAST SODIUM"
            },
            "AIDAREX PHARMACEUTICALS": {
              "MONTELUKAST SODIUM": "MONTELUKAST SODIUM"
            },
            "AJANTA PHARMA LIMITED": {
              "MONTELUKAST SODIUM": "MONTELUKAST SODIUM"
            },
            "AMERICAN HEALTH PACKAGING": {
              "MONTELUKAST SODIUM": "MONTELUKAST SODIUM",
              "ZAFIRLUKAST": "ZAFIRLUKAST"
            },
            "APHENA PHARMA SOLUTIONS": {
              "MONTELUKAST SODIUM": "MONTELUKAST SODIUM"
            },
            "APOTEX": {
              "MONTELUKAST SODIUM": "MONTELUKAST SODIUM"
            },
            "AQ PHARMACEUTICALS": {
              "SINGULAIR": "SINGULAIR"
            },
            "AUROBINDO PHARMA": {
              "MONTELUKAST SODIUM": "MONTELUKAST SODIUM"
            },
            "AVKARE": {
              "MONTELUKAST SODIUM": "MONTELUKAST SODIUM"
            },
            "AVPAK": {
              "MONTELUKAST SODIUM": "MONTELUKAST SODIUM",
              "ZAFIRLUKAST": "ZAFIRLUKAST"
            },
            "BIONPHARMA": {
              "MONTELUKAST SODIUM": "MONTELUKAST SODIUM"
            },
            "BLUE POINT LABORATORIES": {
              "MONTELUKAST SODIUM": "MONTELUKAST SODIUM"
            },
            "BRYANT RANCH PREPACK": {
              "MONTELUKAST SODIUM": "MONTELUKAST SODIUM",
              "SINGULAIR": "SINGULAIR"
            },
            "CADISTA": {
              "MONTELUKAST SODIUM": "MONTELUKAST SODIUM"
            },
            "CAMBER PHARMACEUTICALS": {
              "MONTELUKAST SODIUM": "MONTELUKAST SODIUM"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "MONTELUKAST SODIUM": "MONTELUKAST SODIUM"
            },
            "DIRECT RX": {
              "MONTELUKAST SODIUM": "MONTELUKAST SODIUM"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "MONTELUKAST SODIUM": "MONTELUKAST SODIUM",
              "ZAFIRLUKAST": "ZAFIRLUKAST"
            },
            "GLENMARK PHARMACEUTICALS": {
              "MONTELUKAST SODIUM": "MONTELUKAST SODIUM"
            },
            "HIKMA": {
              "MONTELUKAST SODIUM": "MONTELUKAST SODIUM"
            },
            "INTERNATIONAL LABS, LLC": {
              "MONTELUKAST SODIUM": "MONTELUKAST SODIUM"
            },
            "KREMERS URBAN": {
              "MONTELUKAST SODIUM": "MONTELUKAST SODIUM"
            },
            "LEGACY PHARMACEUTICAL PKG": {
              "MONTELUKAST SODIUM": "MONTELUKAST SODIUM"
            },
            "MACLEODS PHARMACEUTICALS": {
              "MONTELUKAST SODIUM": "MONTELUKAST SODIUM"
            },
            "MAJOR PHARMACEUTICALS": {
              "MONTELUKAST SODIUM": "MONTELUKAST SODIUM",
              "ZAFIRLUKAST": "ZAFIRLUKAST"
            },
            "MERCK SHARP & DOHME": {
              "SINGULAIR": "SINGULAIR"
            },
            "MYLAN": {
              "MONTELUKAST SODIUM": "MONTELUKAST SODIUM"
            },
            "MYLAN INSTITUTIONAL": {
              "MONTELUKAST SODIUM": "MONTELUKAST SODIUM"
            },
            "NUCARE PHARMACEUTICALS": {
              "MONTELUKAST SODIUM": "MONTELUKAST SODIUM"
            },
            "PAR PHARMACEUTICAL": {
              "ACCOLATE": "ACCOLATE",
              "ZAFIRLUKAST": "ZAFIRLUKAST"
            },
            "PAR PHARMACEUTICALS": {
              "MONTELUKAST SODIUM": "MONTELUKAST SODIUM"
            },
            "PDRX PHARMACEUTICAL": {
              "MONTELUKAST SODIUM": "MONTELUKAST SODIUM",
              "SINGULAIR": "SINGULAIR"
            },
            "PRASCO LABORATORIES": {
              "MONTELUKAST SODIUM": "MONTELUKAST SODIUM"
            },
            "PREFERRED PHARMACEUTICALS": {
              "MONTELUKAST SODIUM": "MONTELUKAST SODIUM"
            },
            "PROFICIENT RX": {
              "MONTELUKAST SODIUM": "MONTELUKAST SODIUM"
            },
            "QUALITY CARE": {
              "SINGULAIR": "SINGULAIR"
            },
            "RISING PHARMACEUTICALS": {
              "MONTELUKAST SODIUM": "MONTELUKAST SODIUM"
            },
            "SANDOZ": {
              "MONTELUKAST SODIUM": "MONTELUKAST SODIUM"
            },
            "SLATE RUN PHARMACEUTICALS": {
              "MONTELUKAST SODIUM": "MONTELUKAST SODIUM"
            },
            "TEVA PHARMACEUTICALS USA": {
              "MONTELUKAST SODIUM": "MONTELUKAST SODIUM"
            },
            "TORRENT PHARMACEUTICALS": {
              "MONTELUKAST SODIUM": "MONTELUKAST SODIUM"
            },
            "UNICHEM PHARMACEUTICALS": {
              "MONTELUKAST SODIUM": "MONTELUKAST SODIUM"
            }
          }
        },
        "Selective Phosphodiesterase 4 (PDE4) Inhibitors": {
          "Selective Phosphodiesterase 4 (PDE4) Inhibitors": {
            "ASTRAZENECA": {
              "DALIRESP": "DALIRESP"
            }
          }
        },
        "Steroid Inhalants": {
          "Steroid Inhalants": {
            "A-S MEDICATION SOLUTIONS": {
              "BUDESONIDE": "BUDESONIDE",
              "FLOVENT DISKUS": "FLOVENT DISKUS",
              "FLOVENT HFA": "FLOVENT HFA",
              "PULMICORT FLEXHALER": "PULMICORT FLEXHALER",
              "QVAR": "QVAR",
              "QVAR REDIHALER": "QVAR REDIHALER"
            },
            "AMERICAN HEALTH PACKAGING": {
              "BUDESONIDE": "BUDESONIDE"
            },
            "AMNEAL PHARMACEUTICALS": {
              "BUDESONIDE": "BUDESONIDE"
            },
            "ASTRAZENECA LP": {
              "PULMICORT": "PULMICORT",
              "PULMICORT FLEXHALER": "PULMICORT FLEXHALER"
            },
            "CIPLA USA": {
              "BUDESONIDE": "BUDESONIDE"
            },
            "COVIS PHARMA": {
              "ALVESCO": "ALVESCO"
            },
            "EXELAN PHARMACEUTICALS": {
              "BUDESONIDE": "BUDESONIDE"
            },
            "GLAXO SMITH KLINE": {
              "ARNUITY ELLIPTA": "ARNUITY ELLIPTA",
              "FLOVENT DISKUS": "FLOVENT DISKUS",
              "FLOVENT HFA": "FLOVENT HFA"
            },
            "IMPAX GENERICS": {
              "BUDESONIDE": "BUDESONIDE"
            },
            "LUPIN PHARMACEUTICALS": {
              "BUDESONIDE": "BUDESONIDE"
            },
            "MEDA PHARMACEUTICALS": {
              "AEROSPAN": "AEROSPAN"
            },
            "MERCK SHARP & DOHME": {
              "ASMANEX (120 METERED DOSES)": "ASMANEX (120 METERED DOSES)",
              "ASMANEX (14 METERED DOSES)": "ASMANEX (14 METERED DOSES)",
              "ASMANEX (30 METERED DOSES)": "ASMANEX (30 METERED DOSES)",
              "ASMANEX (60 METERED DOSES)": "ASMANEX (60 METERED DOSES)",
              "ASMANEX (7 METERED DOSES)": "ASMANEX (7 METERED DOSES)",
              "ASMANEX HFA": "ASMANEX HFA"
            },
            "MYLAN SPECIALTY L.P.": {
              "AEROSPAN": "AEROSPAN"
            },
            "NEPHRON PHARMACEUTICALS CORP.": {
              "BUDESONIDE": "BUDESONIDE"
            },
            "PCCA": {
              "FLUNISOLIDE": "FLUNISOLIDE"
            },
            "PREFERRED PHARMACEUTICALS": {
              "BUDESONIDE": "BUDESONIDE",
              "FLOVENT HFA": "FLOVENT HFA",
              "QVAR": "QVAR"
            },
            "PROFICIENT RX": {
              "FLOVENT DISKUS": "FLOVENT DISKUS"
            },
            "QUALITY CARE": {
              "ASMANEX (14 METERED DOSES)": "ASMANEX (14 METERED DOSES)",
              "FLOVENT DISKUS": "FLOVENT DISKUS",
              "FLOVENT HFA": "FLOVENT HFA"
            },
            "SANDOZ": {
              "BUDESONIDE": "BUDESONIDE"
            },
            "TEVA PHARMACEUTICALS USA": {
              "BUDESONIDE": "BUDESONIDE"
            },
            "TEVA RESPIRATORY": {
              "ARMONAIR DIGIHALER": "ARMONAIR DIGIHALER",
              "ARMONAIR RESPICLICK 113": "ARMONAIR RESPICLICK 113",
              "ARMONAIR RESPICLICK 232": "ARMONAIR RESPICLICK 232",
              "ARMONAIR RESPICLICK 55": "ARMONAIR RESPICLICK 55",
              "QVAR": "QVAR",
              "QVAR REDIHALER": "QVAR REDIHALER"
            }
          }
        },
        "Sympathomimetics": {
          "Adrenergic Combinations": {
            "A-S MEDICATION SOLUTIONS": {
              "ADVAIR DISKUS": "ADVAIR DISKUS",
              "BREO ELLIPTA": "BREO ELLIPTA",
              "COMBIVENT RESPIMAT": "COMBIVENT RESPIMAT",
              "DULERA": "DULERA",
              "FLUTICASONE-SALMETEROL": "FLUTICASONE-SALMETEROL",
              "IPRATROPIUM-ALBUTEROL": "IPRATROPIUM-ALBUTEROL",
              "SYMBICORT": "SYMBICORT"
            },
            "ACTAVIS PHARMA": {
              "IPRATROPIUM-ALBUTEROL": "IPRATROPIUM-ALBUTEROL"
            },
            "AIDAREX PHARMACEUTICALS": {
              "ADVAIR DISKUS": "ADVAIR DISKUS"
            },
            "AMERICAN HEALTH PACKAGING": {
              "IPRATROPIUM-ALBUTEROL": "IPRATROPIUM-ALBUTEROL"
            },
            "ASTRAZENECA": {
              "BEVESPI AEROSPHERE": "BEVESPI AEROSPHERE",
              "BREZTRI AEROSPHERE": "BREZTRI AEROSPHERE"
            },
            "ASTRAZENECA LP": {
              "SYMBICORT": "SYMBICORT"
            },
            "BOEHRINGER INGELHEIM": {
              "COMBIVENT RESPIMAT": "COMBIVENT RESPIMAT",
              "STIOLTO RESPIMAT": "STIOLTO RESPIMAT"
            },
            "CIPLA USA": {
              "IPRATROPIUM-ALBUTEROL": "IPRATROPIUM-ALBUTEROL"
            },
            "CIRCASSIA PHARMACEUTICAL": {
              "DUAKLIR PRESSAIR": "DUAKLIR PRESSAIR"
            },
            "GLAXO SMITH KLINE": {
              "ADVAIR DISKUS": "ADVAIR DISKUS",
              "ADVAIR HFA": "ADVAIR HFA",
              "ANORO ELLIPTA": "ANORO ELLIPTA",
              "BREO ELLIPTA": "BREO ELLIPTA",
              "TRELEGY ELLIPTA": "TRELEGY ELLIPTA"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "IPRATROPIUM-ALBUTEROL": "IPRATROPIUM-ALBUTEROL"
            },
            "MERCK SHARP & DOHME": {
              "DULERA": "DULERA"
            },
            "MYLAN": {
              "IPRATROPIUM-ALBUTEROL": "IPRATROPIUM-ALBUTEROL",
              "WIXELA INHUB": "WIXELA INHUB"
            },
            "NEPHRON PHARMACEUTICALS CORP.": {
              "IPRATROPIUM-ALBUTEROL": "IPRATROPIUM-ALBUTEROL"
            },
            "PRASCO LABORATORIES": {
              "BUDESONIDE-FORMOTEROL FUMARATE": "BUDESONIDE-FORMOTEROL FUMARATE",
              "FLUTICASONE-SALMETEROL": "FLUTICASONE-SALMETEROL"
            },
            "PREFERRED PHARMACEUTICALS": {
              "IPRATROPIUM-ALBUTEROL": "IPRATROPIUM-ALBUTEROL"
            },
            "PROFICIENT RX": {
              "FLUTICASONE-SALMETEROL": "FLUTICASONE-SALMETEROL",
              "IPRATROPIUM-ALBUTEROL": "IPRATROPIUM-ALBUTEROL"
            },
            "QUALITY CARE": {
              "ADVAIR DISKUS": "ADVAIR DISKUS"
            },
            "RITEDOSE PHARMACEUTICALS": {
              "IPRATROPIUM-ALBUTEROL": "IPRATROPIUM-ALBUTEROL"
            },
            "SANDOZ": {
              "IPRATROPIUM-ALBUTEROL": "IPRATROPIUM-ALBUTEROL"
            },
            "SUNOVION PHARMACEUTICALS": {
              "UTIBRON NEOHALER": "UTIBRON NEOHALER"
            },
            "TEVA PHARMACEUTICALS USA": {
              "FLUTICASONE-SALMETEROL": "FLUTICASONE-SALMETEROL",
              "IPRATROPIUM-ALBUTEROL": "IPRATROPIUM-ALBUTEROL"
            },
            "TEVA RESPIRATORY": {
              "AIRDUO DIGIHALER": "AIRDUO DIGIHALER",
              "AIRDUO RESPICLICK 113/14": "AIRDUO RESPICLICK 113/14",
              "AIRDUO RESPICLICK 232/14": "AIRDUO RESPICLICK 232/14",
              "AIRDUO RESPICLICK 55/14": "AIRDUO RESPICLICK 55/14"
            }
          },
          "Beta Adrenergics": {
            "A-S MEDICATION SOLUTIONS": {
              "ALBUTEROL SULFATE": "ALBUTEROL SULFATE",
              "ALBUTEROL SULFATE HFA": "ALBUTEROL SULFATE HFA",
              "LEVALBUTEROL HCL": "LEVALBUTEROL HCL",
              "PROAIR HFA": "PROAIR HFA",
              "PROVENTIL HFA": "PROVENTIL HFA",
              "SEREVENT DISKUS": "SEREVENT DISKUS",
              "VENTOLIN HFA": "VENTOLIN HFA",
              "XOPENEX HFA": "XOPENEX HFA"
            },
            "ACTAVIS MID ATLANTIC": {
              "ALBUTEROL SULFATE": "ALBUTEROL SULFATE"
            },
            "ACTAVIS PHARMA": {
              "ALBUTEROL SULFATE": "ALBUTEROL SULFATE",
              "LEVALBUTEROL TARTRATE": "LEVALBUTEROL TARTRATE"
            },
            "AIDAREX PHARMACEUTICALS": {
              "ALBUTEROL SULFATE": "ALBUTEROL SULFATE",
              "PROAIR HFA": "PROAIR HFA",
              "VENTOLIN HFA": "VENTOLIN HFA"
            },
            "AKORN": {
              "XOPENEX": "XOPENEX",
              "XOPENEX CONCENTRATE": "XOPENEX CONCENTRATE"
            },
            "ALTURA PHARMACEUTICALS": {
              "ALBUTEROL SULFATE": "ALBUTEROL SULFATE",
              "METAPROTERENOL SULFATE": "METAPROTERENOL SULFATE"
            },
            "AMERICAN HEALTH PACKAGING": {
              "ALBUTEROL SULFATE": "ALBUTEROL SULFATE"
            },
            "AMNEAL BIOSCIENCES": {
              "ISOPROTERENOL HCL": "ISOPROTERENOL HCL"
            },
            "AMNEAL PHARMACEUTICALS": {
              "ALBUTEROL SULFATE": "ALBUTEROL SULFATE",
              "LEVALBUTEROL HCL": "LEVALBUTEROL HCL",
              "TERBUTALINE SULFATE": "TERBUTALINE SULFATE"
            },
            "AMPHASTAR-IMS": {
              "ISOPROTERENOL HCL": "ISOPROTERENOL HCL"
            },
            "ANI  PHARMACEUTICALS": {
              "TERBUTALINE SULFATE": "TERBUTALINE SULFATE"
            },
            "APOTHECA SUPPLY": {
              "TERBUTALINE SULFATE": "TERBUTALINE SULFATE"
            },
            "ATHENEX PHARMACEUTICAL": {
              "TERBUTALINE SULFATE": "TERBUTALINE SULFATE"
            },
            "AUROBINDO PHARMA": {
              "LEVALBUTEROL HCL": "LEVALBUTEROL HCL"
            },
            "AVKARE": {
              "TERBUTALINE SULFATE": "TERBUTALINE SULFATE"
            },
            "B & B PHARMACEUTICALS": {
              "ALBUTEROL SULFATE": "ALBUTEROL SULFATE"
            },
            "BAUSCH HEALTH": {
              "ALBUTEROL SULFATE": "ALBUTEROL SULFATE",
              "ISUPREL": "ISUPREL"
            },
            "BOEHRINGER INGELHEIM": {
              "STRIVERDI RESPIMAT": "STRIVERDI RESPIMAT"
            },
            "BRYANT RANCH PREPACK": {
              "ALBUTEROL SULFATE": "ALBUTEROL SULFATE",
              "METAPROTERENOL SULFATE": "METAPROTERENOL SULFATE"
            },
            "CARDINAL HEALTH REPACKAGING SO": {
              "ALBUTEROL SULFATE": "ALBUTEROL SULFATE"
            },
            "CIPLA USA": {
              "ALBUTEROL SULFATE HFA": "ALBUTEROL SULFATE HFA",
              "ISOPROTERENOL HCL": "ISOPROTERENOL HCL"
            },
            "CIVICA": {
              "ISOPROTERENOL HCL": "ISOPROTERENOL HCL"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "ALBUTEROL SULFATE": "ALBUTEROL SULFATE",
              "PROAIR HFA": "PROAIR HFA",
              "VENTOLIN HFA": "VENTOLIN HFA",
              "XOPENEX HFA": "XOPENEX HFA"
            },
            "DIRECT RX": {
              "VENTOLIN HFA": "VENTOLIN HFA"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "LEVALBUTEROL HCL": "LEVALBUTEROL HCL"
            },
            "FRESENIUS KABI USA": {
              "TERBUTALINE SULFATE": "TERBUTALINE SULFATE"
            },
            "GLAXO SMITH KLINE": {
              "SEREVENT DISKUS": "SEREVENT DISKUS",
              "VENTOLIN HFA": "VENTOLIN HFA"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "ALBUTEROL SULFATE": "ALBUTEROL SULFATE"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "ALBUTEROL SULFATE": "ALBUTEROL SULFATE",
              "METAPROTERENOL SULFATE": "METAPROTERENOL SULFATE",
              "PROAIR HFA": "PROAIR HFA",
              "PROVENTIL HFA": "PROVENTIL HFA"
            },
            "HI-TECH": {
              "ALBUTEROL SULFATE": "ALBUTEROL SULFATE"
            },
            "HIKMA": {
              "TERBUTALINE SULFATE": "TERBUTALINE SULFATE"
            },
            "IMPAX GENERICS": {
              "LEVALBUTEROL HCL": "LEVALBUTEROL HCL"
            },
            "LANNETT": {
              "TERBUTALINE SULFATE": "TERBUTALINE SULFATE"
            },
            "LETCO MEDICAL": {
              "TERBUTALINE SULFATE": "TERBUTALINE SULFATE"
            },
            "LIBERTY PHARMACEUTICAL": {
              "ALBUTEROL SULFATE": "ALBUTEROL SULFATE",
              "TERBUTALINE SULFATE": "TERBUTALINE SULFATE"
            },
            "LUPIN PHARMACEUTICALS": {
              "ALBUTEROL SULFATE HFA": "ALBUTEROL SULFATE HFA"
            },
            "MARLEX PHARMACEUTICALS": {
              "TERBUTALINE SULFATE": "TERBUTALINE SULFATE"
            },
            "MEDVANTX": {
              "ALBUTEROL SULFATE": "ALBUTEROL SULFATE"
            },
            "MERCK SHARP & DOHME": {
              "FORADIL AEROLIZER": "FORADIL AEROLIZER",
              "PROVENTIL HFA": "PROVENTIL HFA"
            },
            "MYLAN": {
              "ALBUTEROL SULFATE": "ALBUTEROL SULFATE",
              "ALBUTEROL SULFATE ER": "ALBUTEROL SULFATE ER",
              "LEVALBUTEROL HCL": "LEVALBUTEROL HCL"
            },
            "MYLAN INSTITUTIONAL": {
              "ALBUTEROL SULFATE": "ALBUTEROL SULFATE"
            },
            "MYLAN SPECIALTY L.P.": {
              "PERFOROMIST": "PERFOROMIST"
            },
            "NEPHRON PHARMACEUTICALS CORP.": {
              "ALBUTEROL SULFATE": "ALBUTEROL SULFATE"
            },
            "NEPHRON STERILE COMPOUNDING": {
              "ALBUTEROL SULFATE": "ALBUTEROL SULFATE"
            },
            "NEXUS PHARMA": {
              "ISOPROTERENOL HCL": "ISOPROTERENOL HCL"
            },
            "NORTHSTAR RX": {
              "LEVALBUTEROL HCL": "LEVALBUTEROL HCL"
            },
            "NOVAPLUS/HIKMA": {
              "TERBUTALINE SULFATE": "TERBUTALINE SULFATE"
            },
            "NUCARE PHARMACEUTICALS": {
              "ALBUTEROL SULFATE": "ALBUTEROL SULFATE",
              "PROAIR HFA": "PROAIR HFA"
            },
            "PAR PHARMACEUTICAL": {
              "ALBUTEROL SULFATE ER": "ALBUTEROL SULFATE ER",
              "ALBUTEROL SULFATE HFA": "ALBUTEROL SULFATE HFA",
              "METAPROTERENOL SULFATE": "METAPROTERENOL SULFATE",
              "VOSPIRE ER": "VOSPIRE ER"
            },
            "PAR PHARMACEUTICALS": {
              "ALBUTEROL SULFATE": "ALBUTEROL SULFATE"
            },
            "PCCA": {
              "ALBUTEROL SULFATE": "ALBUTEROL SULFATE",
              "TERBUTALINE SULFATE": "TERBUTALINE SULFATE"
            },
            "PDRX PHARMACEUTICAL": {
              "ALBUTEROL SULFATE": "ALBUTEROL SULFATE",
              "METAPROTERENOL SULFATE": "METAPROTERENOL SULFATE",
              "TERBUTALINE SULFATE": "TERBUTALINE SULFATE"
            },
            "PERRIGO PHARMACEUTICALS": {
              "ALBUTEROL SULFATE HFA": "ALBUTEROL SULFATE HFA"
            },
            "PHARMEDIX": {
              "PROAIR HFA": "PROAIR HFA"
            },
            "PRASCO LABORATORIES": {
              "ALBUTEROL SULFATE HFA": "ALBUTEROL SULFATE HFA",
              "LEVALBUTEROL HCL": "LEVALBUTEROL HCL"
            },
            "PREFERRED PHARMACEUTICALS": {
              "ALBUTEROL SULFATE": "ALBUTEROL SULFATE",
              "ALBUTEROL SULFATE HFA": "ALBUTEROL SULFATE HFA",
              "LEVALBUTEROL HCL": "LEVALBUTEROL HCL",
              "LEVALBUTEROL TARTRATE": "LEVALBUTEROL TARTRATE",
              "PROAIR HFA": "PROAIR HFA",
              "VENTOLIN HFA": "VENTOLIN HFA"
            },
            "PROFICIENT RX": {
              "ALBUTEROL SULFATE HFA": "ALBUTEROL SULFATE HFA",
              "LEVALBUTEROL HCL": "LEVALBUTEROL HCL",
              "LEVALBUTEROL TARTRATE": "LEVALBUTEROL TARTRATE",
              "VENTOLIN HFA": "VENTOLIN HFA"
            },
            "QUAGEN PHARMACEUTICALS": {
              "ALBUTEROL SULFATE": "ALBUTEROL SULFATE"
            },
            "QUALITY CARE": {
              "ALBUTEROL SULFATE": "ALBUTEROL SULFATE",
              "PROAIR HFA": "PROAIR HFA",
              "SEREVENT DISKUS": "SEREVENT DISKUS",
              "TERBUTALINE SULFATE": "TERBUTALINE SULFATE",
              "VENTOLIN HFA": "VENTOLIN HFA",
              "XOPENEX HFA": "XOPENEX HFA"
            },
            "RISING PHARMACEUTICALS": {
              "ALBUTEROL SULFATE": "ALBUTEROL SULFATE"
            },
            "RITEDOSE PHARMACEUTICALS": {
              "ALBUTEROL SULFATE": "ALBUTEROL SULFATE",
              "LEVALBUTEROL HCL": "LEVALBUTEROL HCL"
            },
            "SANDOZ": {
              "ALBUTEROL SULFATE": "ALBUTEROL SULFATE"
            },
            "SILARX": {
              "METAPROTERENOL SULFATE": "METAPROTERENOL SULFATE"
            },
            "SPECTRUM": {
              "ALBUTEROL SULFATE": "ALBUTEROL SULFATE",
              "METAPROTERENOL SULFATE": "METAPROTERENOL SULFATE",
              "TERBUTALINE SULFATE": "TERBUTALINE SULFATE"
            },
            "SUN PHARMACEUTICALS": {
              "ALBUTEROL SULFATE": "ALBUTEROL SULFATE",
              "LEVALBUTEROL HCL": "LEVALBUTEROL HCL"
            },
            "SUNOVION PHARMACEUTICALS": {
              "ARCAPTA NEOHALER": "ARCAPTA NEOHALER",
              "BROVANA": "BROVANA",
              "XOPENEX HFA": "XOPENEX HFA"
            },
            "TEVA PHARMACEUTICALS USA": {
              "ALBUTEROL SULFATE": "ALBUTEROL SULFATE",
              "ALBUTEROL SULFATE HFA": "ALBUTEROL SULFATE HFA",
              "LEVALBUTEROL HCL": "LEVALBUTEROL HCL"
            },
            "TEVA RESPIRATORY": {
              "PROAIR DIGIHALER": "PROAIR DIGIHALER",
              "PROAIR HFA": "PROAIR HFA",
              "PROAIR RESPICLICK": "PROAIR RESPICLICK"
            },
            "TWI PHARMACEUTICALS": {
              "TERBUTALINE SULFATE": "TERBUTALINE SULFATE"
            },
            "UNIT DOSE SERVICES": {
              "VENTOLIN HFA": "VENTOLIN HFA"
            },
            "VIRTUS PHARMACEUTICALS": {
              "ALBUTEROL SULFATE": "ALBUTEROL SULFATE"
            },
            "VISTAPHARM INC.": {
              "ALBUTEROL SULFATE": "ALBUTEROL SULFATE"
            }
          },
          "Mixed Adrenergics": {
            "AMPHASTAR-IMS": {
              "PRIMATENE MIST": "PRIMATENE MIST"
            },
            "CONTRACT PHARMACAL CORPORATION": {
              "EPHEDRINE HCL": "EPHEDRINE HCL"
            },
            "FOUNDATION CONSUMER HEALTHCARE": {
              "BRONKAID MAX": "BRONKAID MAX"
            },
            "NEPHRON PHARMACEUTICALS CORP.": {
              "ASTHMANEFRIN REFILL": "ASTHMANEFRIN REFILL",
              "S2 (RACEPINEPHRINE)": "S2 (RACEPINEPHRINE)"
            },
            "PCCA": {
              "EPHEDRINE HCL": "EPHEDRINE HCL",
              "EPHEDRINE SULFATE": "EPHEDRINE SULFATE"
            },
            "SPECTRUM": {
              "EPHEDRINE SULFATE": "EPHEDRINE SULFATE"
            }
          }
        },
        "Xanthines": {
          "Xanthines": {
            "A-S MEDICATION SOLUTIONS": {
              "THEOPHYLLINE ER": "THEOPHYLLINE ER"
            },
            "ALEMBIC PHARMACEUTICALS": {
              "THEOPHYLLINE ER": "THEOPHYLLINE ER"
            },
            "AMERICAN HEALTH PACKAGING": {
              "THEOPHYLLINE": "THEOPHYLLINE"
            },
            "AMERICAN REGENT": {
              "AMINOPHYLLINE": "AMINOPHYLLINE"
            },
            "APHENA PHARMA SOLUTIONS": {
              "THEOPHYLLINE ER": "THEOPHYLLINE ER"
            },
            "APOTHECA SUPPLY": {
              "AMINOPHYLLINE ANHYDROUS": "AMINOPHYLLINE ANHYDROUS"
            },
            "ATLANTIC BIOLOGICALS": {
              "ELIXOPHYLLIN": "ELIXOPHYLLIN"
            },
            "AVKARE": {
              "THEOPHYLLINE ER": "THEOPHYLLINE ER"
            },
            "B. BRAUN MEDICAL": {
              "THEOPHYLLINE IN D5W": "THEOPHYLLINE IN D5W"
            },
            "BRYANT RANCH PREPACK": {
              "THEOPHYLLINE ER": "THEOPHYLLINE ER"
            },
            "CARDINAL HEALTH REPACKAGING SO": {
              "AMINOPHYLLINE": "AMINOPHYLLINE"
            },
            "ENDO PHARMACEUTICALS": {
              "THEO-24": "THEO-24"
            },
            "FAGRON": {
              "AMINOPHYLLINE ANHYDROUS": "AMINOPHYLLINE ANHYDROUS"
            },
            "FREEDOM PHARMACEUTICALS": {
              "AMINOPHYLLINE ANHYDROUS": "AMINOPHYLLINE ANHYDROUS"
            },
            "GLENMARK PHARMACEUTICALS": {
              "THEOPHYLLINE ER": "THEOPHYLLINE ER"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "THEOPHYLLINE ER": "THEOPHYLLINE ER"
            },
            "HERITAGE PHARMACEUTICALS": {
              "THEOPHYLLINE ER": "THEOPHYLLINE ER"
            },
            "HOSPIRA": {
              "AMINOPHYLLINE": "AMINOPHYLLINE"
            },
            "HUMCO": {
              "AMINOPHYLLINE": "AMINOPHYLLINE"
            },
            "LETCO MEDICAL": {
              "AMINOPHYLLINE ANHYDROUS": "AMINOPHYLLINE ANHYDROUS",
              "THEOPHYLLINE-ETHYLENEDIAMINE": "THEOPHYLLINE-ETHYLENEDIAMINE"
            },
            "LIBERTY PHARMACEUTICAL": {
              "THEOPHYLLINE ER": "THEOPHYLLINE ER"
            },
            "MAJOR PHARMACEUTICALS": {
              "THEOPHYLLINE ER": "THEOPHYLLINE ER"
            },
            "MARLEX PHARMACEUTICALS": {
              "THEOPHYLLINE": "THEOPHYLLINE"
            },
            "MAYNE PHARMA": {
              "THEOPHYLLINE": "THEOPHYLLINE"
            },
            "MYLAN": {
              "THEOPHYLLINE ER": "THEOPHYLLINE ER"
            },
            "NOSTRUM LABORATORIES": {
              "ELIXOPHYLLIN": "ELIXOPHYLLIN",
              "THEOCHRON": "THEOCHRON",
              "THEOPHYLLINE ER": "THEOPHYLLINE ER"
            },
            "NOVAPLUS/HOSPIRA": {
              "AMINOPHYLLINE": "AMINOPHYLLINE"
            },
            "PCCA": {
              "AMINOPHYLLINE ANHYDROUS": "AMINOPHYLLINE ANHYDROUS"
            },
            "PDRX PHARMACEUTICAL": {
              "THEOPHYLLINE ER": "THEOPHYLLINE ER"
            },
            "PHARMACEUTICAL ASSOCIATES": {
              "THEOPHYLLINE": "THEOPHYLLINE"
            },
            "QUALITY CARE": {
              "THEOPHYLLINE ER": "THEOPHYLLINE ER"
            },
            "RHODES PHARMACEUTICAL": {
              "THEOPHYLLINE ER": "THEOPHYLLINE ER"
            },
            "SILARX": {
              "THEOPHYLLINE": "THEOPHYLLINE"
            },
            "SPECTRUM": {
              "AMINOPHYLLINE ANHYDROUS": "AMINOPHYLLINE ANHYDROUS"
            },
            "TEVA PHARMACEUTICALS USA": {
              "THEOPHYLLINE ER": "THEOPHYLLINE ER"
            },
            "TRIS PHARMA": {
              "THEOPHYLLINE": "THEOPHYLLINE"
            },
            "VA CMOP DALLAS": {
              "THEOPHYLLINE ER": "THEOPHYLLINE ER"
            }
          }
        }
      },
      "ANTICOAGULANTS": {
        "Anticoagulants - Misc.": {
          "Anticoagulants - Misc.": {
            "NEPHRON STERILE COMPOUNDING": {
              "SODIUM CITRATE": "SODIUM CITRATE"
            },
            "PHARMEDIUM SERVICES": {
              "SODIUM CITRATE": "SODIUM CITRATE"
            },
            "QUVA PHARMA": {
              "SODIUM CITRATE LOCK FLUSH": "SODIUM CITRATE LOCK FLUSH"
            },
            "SCA PHARMACEUTICALS": {
              "SODIUM CITRATE LOCK FLUSH": "SODIUM CITRATE LOCK FLUSH"
            }
          }
        },
        "Coumarin Anticoagulants": {
          "Coumarin Anticoagulants": {
            "A-S MEDICATION SOLUTIONS": {
              "COUMADIN": "COUMADIN",
              "WARFARIN SODIUM": "WARFARIN SODIUM"
            },
            "AIDAREX PHARMACEUTICALS": {
              "WARFARIN SODIUM": "WARFARIN SODIUM"
            },
            "AMERICAN HEALTH PACKAGING": {
              "WARFARIN SODIUM": "WARFARIN SODIUM"
            },
            "AMNEAL PHARMACEUTICALS": {
              "WARFARIN SODIUM": "WARFARIN SODIUM"
            },
            "APHENA PHARMA SOLUTIONS": {
              "COUMADIN": "COUMADIN",
              "WARFARIN SODIUM": "WARFARIN SODIUM"
            },
            "AQ PHARMACEUTICALS": {
              "COUMADIN": "COUMADIN"
            },
            "B-M SQUIBB U.S. (PRIMARY CARE)": {
              "COUMADIN": "COUMADIN"
            },
            "BRYANT RANCH PREPACK": {
              "WARFARIN SODIUM": "WARFARIN SODIUM"
            },
            "CAMBER PHARMACEUTICALS": {
              "WARFARIN SODIUM": "WARFARIN SODIUM"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "WARFARIN SODIUM": "WARFARIN SODIUM"
            },
            "DIRECT RX": {
              "WARFARIN SODIUM": "WARFARIN SODIUM"
            },
            "EXELAN PHARMACEUTICALS": {
              "WARFARIN SODIUM": "WARFARIN SODIUM"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "WARFARIN SODIUM": "WARFARIN SODIUM"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "WARFARIN SODIUM": "WARFARIN SODIUM"
            },
            "MALLINCKRODT PHARM": {
              "WARFARIN SODIUM": "WARFARIN SODIUM"
            },
            "MEDVANTX": {
              "WARFARIN SODIUM": "WARFARIN SODIUM"
            },
            "NEW HORIZON RX GROUP": {
              "WARFARIN SODIUM": "WARFARIN SODIUM"
            },
            "NUCARE PHARMACEUTICALS": {
              "COUMADIN": "COUMADIN",
              "WARFARIN SODIUM": "WARFARIN SODIUM"
            },
            "PCCA": {
              "WARFARIN SODIUM": "WARFARIN SODIUM"
            },
            "PDRX PHARMACEUTICAL": {
              "COUMADIN": "COUMADIN",
              "WARFARIN SODIUM": "WARFARIN SODIUM"
            },
            "PHYSICIANS DISPENSING RX": {
              "COUMADIN": "COUMADIN"
            },
            "PROFICIENT RX": {
              "WARFARIN SODIUM": "WARFARIN SODIUM"
            },
            "QUALITY CARE": {
              "COUMADIN": "COUMADIN",
              "WARFARIN SODIUM": "WARFARIN SODIUM"
            },
            "QUALITY CARE PRODUCTS": {
              "WARFARIN SODIUM": "WARFARIN SODIUM"
            },
            "RISING PHARMACEUTICALS": {
              "WARFARIN SODIUM": "WARFARIN SODIUM"
            },
            "RXCHANGE CO": {
              "WARFARIN SODIUM": "WARFARIN SODIUM"
            },
            "ST MARY'S MPP": {
              "WARFARIN SODIUM": "WARFARIN SODIUM"
            },
            "TARO": {
              "WARFARIN SODIUM": "WARFARIN SODIUM"
            },
            "TEVA PHARMACEUTICALS USA": {
              "WARFARIN SODIUM": "WARFARIN SODIUM"
            },
            "UPSHER-SMITH": {
              "JANTOVEN": "JANTOVEN"
            },
            "VA CMOP DALLAS": {
              "WARFARIN SODIUM": "WARFARIN SODIUM"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "WARFARIN SODIUM": "WARFARIN SODIUM"
            }
          }
        },
        "Direct Factor Xa Inhibitors": {
          "Direct Factor Xa Inhibitors": {
            "A-S MEDICATION SOLUTIONS": {
              "ELIQUIS": "ELIQUIS"
            },
            "B-M SQUIBB U.S. (PRIMARY CARE)": {
              "ELIQUIS": "ELIQUIS",
              "ELIQUIS DVT/PE STARTER PACK": "ELIQUIS DVT/PE STARTER PACK"
            },
            "JANSSEN": {
              "XARELTO": "XARELTO",
              "XARELTO STARTER PACK": "XARELTO STARTER PACK"
            },
            "PORTOLA PHARMACEUTICALS": {
              "BEVYXXA": "BEVYXXA"
            },
            "SANKYO": {
              "SAVAYSA": "SAVAYSA"
            }
          }
        },
        "Heparins And Heparinoid-Like Agents": {
          "Heparins And Heparinoid-Like Agents": {
            "AMSINO MEDICAL USA": {
              "HEPARIN LOCK FLUSH": "HEPARIN LOCK FLUSH",
              "HEPARIN SODIUM LOCK FLUSH": "HEPARIN SODIUM LOCK FLUSH"
            },
            "B. BRAUN MEDICAL": {
              "HEPARIN (PORCINE) IN NACL": "HEPARIN (PORCINE) IN NACL",
              "HEPARIN SOD (PORCINE) IN D5W": "HEPARIN SOD (PORCINE) IN D5W",
              "HEPARIN SODIUM (PORCINE) PF": "HEPARIN SODIUM (PORCINE) PF"
            },
            "BAXTER HEALTHCARE CORP": {
              "HEPARIN (PORCINE) IN NACL": "HEPARIN (PORCINE) IN NACL"
            },
            "BD MEDICAL SURGICAL SYSTEMS": {
              "HEPARIN LOCK FLUSH": "HEPARIN LOCK FLUSH",
              "HEPARIN SODIUM FLUSH": "HEPARIN SODIUM FLUSH",
              "HEPARIN SODIUM LOCK FLUSH": "HEPARIN SODIUM LOCK FLUSH",
              "SASH KIT": "SASH KIT"
            },
            "BD MICROBIOLOGY": {
              "SASH KIT": "SASH KIT"
            },
            "CARDINAL HEALTH REPACKAGING SO": {
              "HEPARIN SODIUM (PORCINE)": "HEPARIN SODIUM (PORCINE)"
            },
            "CENTRAL ADMIXTURE PHARMACY SER": {
              "HEPARIN (PORCINE) IN NACL": "HEPARIN (PORCINE) IN NACL"
            },
            "CIVICA": {
              "HEPARIN SODIUM (PORCINE)": "HEPARIN SODIUM (PORCINE)"
            },
            "COVIDIEN MEDICAL SUPPLIES": {
              "MONOJECT FLUSH SYR/HEP LOCK": "MONOJECT FLUSH SYR/HEP LOCK",
              "MONOJECT PREFILL ADV HEPARIN": "MONOJECT PREFILL ADV HEPARIN"
            },
            "FRESENIUS KABI USA": {
              "HEPARIN (PORCINE) IN NACL": "HEPARIN (PORCINE) IN NACL",
              "HEPARIN LOCK FLUSH": "HEPARIN LOCK FLUSH",
              "HEPARIN SOD (PORCINE) IN D5W": "HEPARIN SOD (PORCINE) IN D5W",
              "HEPARIN SODIUM (PORCINE)": "HEPARIN SODIUM (PORCINE)",
              "HEPARIN SODIUM (PORCINE) PF": "HEPARIN SODIUM (PORCINE) PF",
              "HEPARIN SODIUM LOCK FLUSH": "HEPARIN SODIUM LOCK FLUSH"
            },
            "HIKMA": {
              "HEPARIN SODIUM (PORCINE)": "HEPARIN SODIUM (PORCINE)",
              "HEPARIN SODIUM (PORCINE) PF": "HEPARIN SODIUM (PORCINE) PF"
            },
            "HOSPIRA": {
              "HEPARIN (PORCINE) IN NACL": "HEPARIN (PORCINE) IN NACL",
              "HEPARIN LOCK FLUSH": "HEPARIN LOCK FLUSH",
              "HEPARIN SOD (PORCINE) IN D5W": "HEPARIN SOD (PORCINE) IN D5W",
              "HEPARIN SODIUM (PORCINE)": "HEPARIN SODIUM (PORCINE)",
              "HEPARIN SODIUM (PORCINE) PF": "HEPARIN SODIUM (PORCINE) PF",
              "HEPARIN SODIUM LOCK FLUSH": "HEPARIN SODIUM LOCK FLUSH"
            },
            "MEDEFIL, INC": {
              "HEPARIN LOCK FLUSH": "HEPARIN LOCK FLUSH",
              "HEPARIN SODIUM LOCK FLUSH": "HEPARIN SODIUM LOCK FLUSH"
            },
            "MEDLINE INDUSTRIES": {
              "HEPARIN LOCK FLUSH": "HEPARIN LOCK FLUSH",
              "HEPARIN SODIUM LOCK FLUSH": "HEPARIN SODIUM LOCK FLUSH"
            },
            "MEITHEAL PHARMACEUTICALS": {
              "HEPARIN SODIUM (PORCINE)": "HEPARIN SODIUM (PORCINE)"
            },
            "MESORA PHARMA": {
              "HEPMED": "HEPMED"
            },
            "MYLAN INSTITUTIONAL": {
              "HEPARIN SODIUM (PORCINE)": "HEPARIN SODIUM (PORCINE)"
            },
            "NOVAPLUS/FRESENIUS KABI": {
              "HEPARIN SODIUM (PORCINE)": "HEPARIN SODIUM (PORCINE)"
            },
            "PFIZER U.S.": {
              "HEPARIN SODIUM (PORCINE)": "HEPARIN SODIUM (PORCINE)"
            },
            "PHARMEDIUM SERVICES": {
              "HEPARIN (PORCINE) IN NACL": "HEPARIN (PORCINE) IN NACL",
              "HEPARIN SOD (PORCINE) IN D5W": "HEPARIN SOD (PORCINE) IN D5W",
              "HEPARIN SOD (PORCINE)-D10": "HEPARIN SOD (PORCINE)-D10",
              "HEPARIN SODIUM (PORCINE)": "HEPARIN SODIUM (PORCINE)"
            },
            "QUVA PHARMA": {
              "HEPARIN (PORCINE) IN NACL": "HEPARIN (PORCINE) IN NACL"
            },
            "SAGENT PHARMACEUTICAL": {
              "HEPARIN SODIUM (PORCINE)": "HEPARIN SODIUM (PORCINE)"
            },
            "SANDOZ": {
              "HEPARIN SODIUM (PORCINE)": "HEPARIN SODIUM (PORCINE)"
            },
            "SKY PACKAGING": {
              "HEPARIN SODIUM (PORCINE)": "HEPARIN SODIUM (PORCINE)"
            },
            "SOLUTECH PHARMACEUTICALS": {
              "SOLU-PREF": "SOLU-PREF"
            }
          },
          "Low Molecular Weight Heparins": {
            "A-S MEDICATION SOLUTIONS": {
              "ENOXAPARIN SODIUM": "ENOXAPARIN SODIUM"
            },
            "ACTAVIS PHARMA": {
              "ENOXAPARIN SODIUM": "ENOXAPARIN SODIUM"
            },
            "AMERINET/AMPHASTAR-IMS": {
              "ENOXAPARIN SODIUM": "ENOXAPARIN SODIUM"
            },
            "AMPHASTAR-IMS": {
              "ENOXAPARIN SODIUM": "ENOXAPARIN SODIUM"
            },
            "APOTEX": {
              "ENOXAPARIN SODIUM": "ENOXAPARIN SODIUM"
            },
            "FRESENIUS KABI USA": {
              "ENOXAPARIN SODIUM": "ENOXAPARIN SODIUM"
            },
            "MEITHEAL PHARMACEUTICALS": {
              "ENOXAPARIN SODIUM": "ENOXAPARIN SODIUM"
            },
            "NORTHSTAR RX": {
              "ENOXAPARIN SODIUM": "ENOXAPARIN SODIUM"
            },
            "NOVAPLUS/FRESENIUS KABI": {
              "ENOXAPARIN SODIUM": "ENOXAPARIN SODIUM"
            },
            "PFIZER U.S.": {
              "FRAGMIN": "FRAGMIN"
            },
            "QUALITY CARE": {
              "LOVENOX": "LOVENOX"
            },
            "SANDOZ": {
              "ENOXAPARIN SODIUM": "ENOXAPARIN SODIUM"
            },
            "SANOFI-AVENTIS U.S.": {
              "LOVENOX": "LOVENOX"
            },
            "TEVA PARENTERAL MEDICINES": {
              "ENOXAPARIN SODIUM": "ENOXAPARIN SODIUM"
            },
            "WINTHROP, US": {
              "ENOXAPARIN SODIUM": "ENOXAPARIN SODIUM"
            }
          },
          "Synthetic Heparinoid-Like Agents": {
            "APOTEX": {
              "FONDAPARINUX SODIUM": "FONDAPARINUX SODIUM"
            },
            "AUROMEDICS PHARMA": {
              "FONDAPARINUX SODIUM": "FONDAPARINUX SODIUM"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "FONDAPARINUX SODIUM": "FONDAPARINUX SODIUM"
            },
            "MYLAN INSTITUTIONAL": {
              "ARIXTRA": "ARIXTRA",
              "FONDAPARINUX SODIUM": "FONDAPARINUX SODIUM"
            },
            "NOVAPLUS/DR.REDDY'S": {
              "FONDAPARINUX SODIUM": "FONDAPARINUX SODIUM"
            },
            "NOVAPLUS/MYLAN INSTITUTIONAL": {
              "FONDAPARINUX SODIUM": "FONDAPARINUX SODIUM"
            },
            "QUALITY CARE": {
              "ARIXTRA": "ARIXTRA"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "FONDAPARINUX SODIUM": "FONDAPARINUX SODIUM"
            }
          }
        },
        "In Vitro/Lock Anticoagulants": {
          "In Vitro/Lock Anticoagulant Combinations": {
            "FAGRON COMPOUNDING SERVICES": {
              "SODIUM CITRATE-GENTAMICIN SULF": "SODIUM CITRATE-GENTAMICIN SULF"
            }
          },
          "In Vitro/Lock Anticoagulants": {
            "CITRA LABS": {
              "ACD-A NOCLOT-50": "ACD-A NOCLOT-50",
              "TRICITRASOL": "TRICITRASOL"
            },
            "FENWAL INTERNATIONAL": {
              "ACD FORMULA A": "ACD FORMULA A",
              "ACD FORMULA B": "ACD FORMULA B",
              "ANTICOAGULANT SODIUM CITRATE": "ANTICOAGULANT SODIUM CITRATE"
            },
            "FRESENIUS KABI USA": {
              "ANTICOAGULANT SODIUM CITRATE": "ANTICOAGULANT SODIUM CITRATE"
            },
            "GAMBRO RENAL PRODUCTS": {
              "REGIOCIT": "REGIOCIT"
            },
            "HOSPIRA": {
              "ANTICOAGULANT COMPOUND": "ANTICOAGULANT COMPOUND"
            },
            "TERUMO BCT": {
              "ANTICOAGULANT CIT DEXT SOLN A": "ANTICOAGULANT CIT DEXT SOLN A"
            }
          }
        },
        "Thrombin Inhibitors": {
          "Thrombin Inhibitors - Hirudin Type": {
            "ACCORD HEALTHCARE": {
              "BIVALIRUDIN TRIFLUOROACETATE": "BIVALIRUDIN TRIFLUOROACETATE"
            },
            "APOTEX": {
              "BIVALIRUDIN TRIFLUOROACETATE": "BIVALIRUDIN TRIFLUOROACETATE"
            },
            "ATHENEX PHARMACEUTICAL": {
              "BIVALIRUDIN RTU": "BIVALIRUDIN RTU",
              "BIVALIRUDIN TRIFLUOROACETATE": "BIVALIRUDIN TRIFLUOROACETATE"
            },
            "AUROMEDICS PHARMA": {
              "BIVALIRUDIN TRIFLUOROACETATE": "BIVALIRUDIN TRIFLUOROACETATE"
            },
            "BAUSCH HEALTH": {
              "IPRIVASK": "IPRIVASK"
            },
            "BAXTER HEALTHCARE CORP": {
              "BIVALIRUDIN-SODIUM CHLORIDE": "BIVALIRUDIN-SODIUM CHLORIDE"
            },
            "CIVICA": {
              "BIVALIRUDIN TRIFLUOROACETATE": "BIVALIRUDIN TRIFLUOROACETATE"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "BIVALIRUDIN TRIFLUOROACETATE": "BIVALIRUDIN TRIFLUOROACETATE"
            },
            "FRESENIUS KABI USA": {
              "BIVALIRUDIN TRIFLUOROACETATE": "BIVALIRUDIN TRIFLUOROACETATE"
            },
            "HOSPIRA": {
              "BIVALIRUDIN TRIFLUOROACETATE": "BIVALIRUDIN TRIFLUOROACETATE"
            },
            "MYLAN INSTITUTIONAL": {
              "BIVALIRUDIN TRIFLUOROACETATE": "BIVALIRUDIN TRIFLUOROACETATE"
            },
            "NOVAPLUS/SANDOZ": {
              "BIVALIRUDIN TRIFLUOROACETATE": "BIVALIRUDIN TRIFLUOROACETATE"
            },
            "SANDOZ": {
              "ANGIOMAX": "ANGIOMAX",
              "BIVALIRUDIN TRIFLUOROACETATE": "BIVALIRUDIN TRIFLUOROACETATE"
            }
          },
          "Thrombin Inhibitors - Selective Direct & Reversible": {
            "A-S MEDICATION SOLUTIONS": {
              "PRADAXA": "PRADAXA"
            },
            "AUROMEDICS PHARMA": {
              "ARGATROBAN IN SODIUM CHLORIDE": "ARGATROBAN IN SODIUM CHLORIDE"
            },
            "BOEHRINGER INGELHEIM": {
              "PRADAXA": "PRADAXA"
            },
            "CHIESI USA": {
              "ARGATROBAN": "ARGATROBAN"
            },
            "FRESENIUS KABI USA": {
              "ARGATROBAN": "ARGATROBAN"
            },
            "HIKMA": {
              "ARGATROBAN": "ARGATROBAN"
            },
            "HOSPIRA": {
              "ARGATROBAN": "ARGATROBAN"
            },
            "MYLAN INSTITUTIONAL": {
              "ARGATROBAN": "ARGATROBAN"
            },
            "NOVAPLUS/GSK": {
              "ARGATROBAN": "ARGATROBAN"
            },
            "NOVAPLUS/HIKMA": {
              "ARGATROBAN": "ARGATROBAN"
            },
            "NOVAPLUS/SANDOZ": {
              "ARGATROBAN": "ARGATROBAN"
            },
            "NOVARTIS": {
              "ARGATROBAN": "ARGATROBAN"
            },
            "PAR STERILE PRODUCTS": {
              "ARGATROBAN": "ARGATROBAN"
            },
            "SANDOZ": {
              "ARGATROBAN": "ARGATROBAN",
              "ARGATROBAN IN SODIUM CHLORIDE": "ARGATROBAN IN SODIUM CHLORIDE"
            },
            "TEVA PARENTERAL MEDICINES": {
              "ARGATROBAN IN SODIUM CHLORIDE": "ARGATROBAN IN SODIUM CHLORIDE"
            }
          }
        }
      },
      "ANTICONVULSANTS": {
        "AMPA Glutamate Receptor Antagonists": {
          "AMPA Glutamate Receptor Antagonists": {
            "EISAI": {
              "FYCOMPA": "FYCOMPA"
            }
          }
        },
        "Anticonvulsants - Benzodiazepines": {
          "Anticonvulsants - Benzodiazepines": {
            "A-S MEDICATION SOLUTIONS": {
              "CLONAZEPAM": "CLONAZEPAM"
            },
            "ACCORD HEALTHCARE": {
              "CLONAZEPAM": "CLONAZEPAM"
            },
            "ACTAVIS ELIZABETH": {
              "CLONAZEPAM": "CLONAZEPAM"
            },
            "AIDAREX PHARMACEUTICALS": {
              "CLONAZEPAM": "CLONAZEPAM"
            },
            "ALEMBIC PHARMACEUTICALS": {
              "CLONAZEPAM": "CLONAZEPAM"
            },
            "ALTURA PHARMACEUTICALS": {
              "CLONAZEPAM": "CLONAZEPAM"
            },
            "AMERICAN HEALTH PACKAGING": {
              "CLOBAZAM": "CLOBAZAM",
              "CLONAZEPAM": "CLONAZEPAM"
            },
            "AMNEAL PHARMACEUTICALS": {
              "CLOBAZAM": "CLOBAZAM"
            },
            "APHENA PHARMA SOLUTIONS": {
              "CLONAZEPAM": "CLONAZEPAM"
            },
            "AQUESTIVE THERAPEUTICS": {
              "SYMPAZAN": "SYMPAZAN"
            },
            "ASCEND LABORATORIES": {
              "CLOBAZAM": "CLOBAZAM"
            },
            "BAUSCH HEALTH": {
              "DIASTAT ACUDIAL": "DIASTAT ACUDIAL",
              "DIASTAT PEDIATRIC": "DIASTAT PEDIATRIC"
            },
            "BIONPHARMA": {
              "CLOBAZAM": "CLOBAZAM"
            },
            "BRECKENRIDGE": {
              "CLOBAZAM": "CLOBAZAM"
            },
            "BRYANT RANCH PREPACK": {
              "CLONAZEPAM": "CLONAZEPAM"
            },
            "CAMBER PHARMACEUTICALS": {
              "CLOBAZAM": "CLOBAZAM"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "CLONAZEPAM": "CLONAZEPAM"
            },
            "DIRECT RX": {
              "CLONAZEPAM": "CLONAZEPAM"
            },
            "GENENTECH": {
              "KLONOPIN": "KLONOPIN"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "CLONAZEPAM": "CLONAZEPAM"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "CLONAZEPAM": "CLONAZEPAM"
            },
            "HIKMA": {
              "CLOBAZAM": "CLOBAZAM"
            },
            "LANNETT": {
              "CLOBAZAM": "CLOBAZAM"
            },
            "LIBERTY PHARMACEUTICAL": {
              "CLONAZEPAM": "CLONAZEPAM"
            },
            "LUNDBECK": {
              "ONFI": "ONFI"
            },
            "LUPIN PHARMACEUTICALS": {
              "CLOBAZAM": "CLOBAZAM"
            },
            "MAJOR PHARMACEUTICALS": {
              "CLONAZEPAM": "CLONAZEPAM"
            },
            "MICRO LABORATORIES": {
              "CLOBAZAM": "CLOBAZAM"
            },
            "MYLAN": {
              "CLOBAZAM": "CLOBAZAM",
              "CLONAZEPAM": "CLONAZEPAM"
            },
            "MYLAN INSTITUTIONAL": {
              "CLONAZEPAM": "CLONAZEPAM"
            },
            "NEURELIS": {
              "VALTOCO 10 MG DOSE": "VALTOCO 10 MG DOSE",
              "VALTOCO 15 MG DOSE": "VALTOCO 15 MG DOSE",
              "VALTOCO 20 MG DOSE": "VALTOCO 20 MG DOSE",
              "VALTOCO 5 MG DOSE": "VALTOCO 5 MG DOSE"
            },
            "NORTHSTAR RX": {
              "CLOBAZAM": "CLOBAZAM",
              "CLONAZEPAM": "CLONAZEPAM"
            },
            "NUCARE PHARMACEUTICALS": {
              "CLONAZEPAM": "CLONAZEPAM"
            },
            "OCEANSIDE PHARMACEUTICALS": {
              "DIAZEPAM": "DIAZEPAM"
            },
            "PAR PHARMACEUTICAL": {
              "CLONAZEPAM": "CLONAZEPAM"
            },
            "PAR PHARMACEUTICALS": {
              "CLONAZEPAM": "CLONAZEPAM"
            },
            "PDRX PHARMACEUTICAL": {
              "CLONAZEPAM": "CLONAZEPAM"
            },
            "PREFERRED PHARMACEUTICALS": {
              "CLONAZEPAM": "CLONAZEPAM"
            },
            "PROFICIENT RX": {
              "CLONAZEPAM": "CLONAZEPAM"
            },
            "QUALITY CARE": {
              "CLONAZEPAM": "CLONAZEPAM",
              "KLONOPIN": "KLONOPIN"
            },
            "QUALITY CARE PRODUCTS": {
              "CLONAZEPAM": "CLONAZEPAM"
            },
            "RXCHANGE CO": {
              "CLONAZEPAM": "CLONAZEPAM"
            },
            "SANDOZ": {
              "CLOBAZAM": "CLOBAZAM",
              "CLONAZEPAM": "CLONAZEPAM"
            },
            "SKY PACKAGING": {
              "CLONAZEPAM": "CLONAZEPAM"
            },
            "SOLCO HEALTHCARE": {
              "CLONAZEPAM": "CLONAZEPAM"
            },
            "ST MARY'S MPP": {
              "CLONAZEPAM": "CLONAZEPAM"
            },
            "SUN PHARMACEUTICALS": {
              "CLONAZEPAM": "CLONAZEPAM"
            },
            "TARO": {
              "CLOBAZAM": "CLOBAZAM"
            },
            "TEVA PHARMACEUTICALS USA": {
              "CLONAZEPAM": "CLONAZEPAM",
              "DIAZEPAM": "DIAZEPAM"
            },
            "UCB PHARMA": {
              "NAYZILAM": "NAYZILAM"
            },
            "UNIT DOSE SERVICES": {
              "CLONAZEPAM": "CLONAZEPAM"
            },
            "UPSHER-SMITH": {
              "CLOBAZAM": "CLOBAZAM",
              "CLONAZEPAM": "CLONAZEPAM"
            },
            "VISTAPHARM INC.": {
              "CLOBAZAM": "CLOBAZAM"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "CLOBAZAM": "CLOBAZAM"
            }
          }
        },
        "Anticonvulsants - Misc.": {
          "Anticonvulsants - Misc.": {
            "A-S MEDICATION SOLUTIONS": {
              "CARBAMAZEPINE": "CARBAMAZEPINE",
              "CARBAMAZEPINE ER": "CARBAMAZEPINE ER",
              "GABAPENTIN": "GABAPENTIN",
              "LAMOTRIGINE": "LAMOTRIGINE",
              "LEVETIRACETAM": "LEVETIRACETAM",
              "LYRICA": "LYRICA",
              "NEURONTIN": "NEURONTIN",
              "OXCARBAZEPINE": "OXCARBAZEPINE",
              "PREGABALIN": "PREGABALIN",
              "PRIMIDONE": "PRIMIDONE",
              "TOPIRAMATE": "TOPIRAMATE",
              "ZONISAMIDE": "ZONISAMIDE"
            },
            "ACCORD HEALTHCARE": {
              "LEVETIRACETAM": "LEVETIRACETAM"
            },
            "ACELLA PHARMACEUTICALS": {
              "GABAPENTIN": "GABAPENTIN"
            },
            "ACETRIS HEALTH": {
              "GABAPENTIN": "GABAPENTIN",
              "LEVETIRACETAM": "LEVETIRACETAM"
            },
            "ACI HEALTHCARE USA": {
              "GABAPENTIN": "GABAPENTIN",
              "LEVETIRACETAM": "LEVETIRACETAM"
            },
            "ACTAVIS": {
              "GABAPENTIN": "GABAPENTIN"
            },
            "ACTAVIS ELIZABETH": {
              "GABAPENTIN": "GABAPENTIN",
              "LAMOTRIGINE ER": "LAMOTRIGINE ER",
              "PREGABALIN": "PREGABALIN"
            },
            "ACTAVIS MID ATLANTIC": {
              "LEVETIRACETAM": "LEVETIRACETAM"
            },
            "ACTAVIS PHARMA": {
              "LAMOTRIGINE": "LAMOTRIGINE",
              "LEVETIRACETAM ER": "LEVETIRACETAM ER",
              "PRIMIDONE": "PRIMIDONE"
            },
            "AIDAREX PHARMACEUTICALS": {
              "CARBAMAZEPINE": "CARBAMAZEPINE",
              "GABAPENTIN": "GABAPENTIN",
              "LAMICTAL": "LAMICTAL",
              "LAMOTRIGINE": "LAMOTRIGINE",
              "LEVETIRACETAM": "LEVETIRACETAM",
              "LYRICA": "LYRICA",
              "OXCARBAZEPINE": "OXCARBAZEPINE",
              "TOPIRAMATE": "TOPIRAMATE",
              "ZONISAMIDE": "ZONISAMIDE"
            },
            "AJANTA PHARMA LIMITED": {
              "LEVETIRACETAM": "LEVETIRACETAM"
            },
            "AKORN": {
              "GABAPENTIN": "GABAPENTIN"
            },
            "AKRON PHARMA": {
              "GABAPENTIN": "GABAPENTIN"
            },
            "ALEMBIC PHARMACEUTICALS": {
              "LAMOTRIGINE": "LAMOTRIGINE",
              "PREGABALIN": "PREGABALIN"
            },
            "ALIVIO MEDICAL PRODUCTS": {
              "GABAPENTIN": "GABAPENTIN"
            },
            "ALTURA PHARMACEUTICALS": {
              "CARBAMAZEPINE": "CARBAMAZEPINE",
              "GABAPENTIN": "GABAPENTIN",
              "NEURONTIN": "NEURONTIN",
              "TOPAMAX": "TOPAMAX"
            },
            "AMERICAN HEALTH PACKAGING": {
              "CARBAMAZEPINE": "CARBAMAZEPINE",
              "CARBAMAZEPINE ER": "CARBAMAZEPINE ER",
              "GABAPENTIN": "GABAPENTIN",
              "LAMOTRIGINE": "LAMOTRIGINE",
              "LEVETIRACETAM": "LEVETIRACETAM",
              "OXCARBAZEPINE": "OXCARBAZEPINE",
              "PREGABALIN": "PREGABALIN",
              "PRIMIDONE": "PRIMIDONE",
              "TOPIRAMATE": "TOPIRAMATE",
              "ZONISAMIDE": "ZONISAMIDE"
            },
            "AMERICAN PHARMA INGREDIENTS": {
              "CARBAMAZEPINE": "CARBAMAZEPINE"
            },
            "AMERICAN REGENT": {
              "LEVETIRACETAM": "LEVETIRACETAM"
            },
            "AMNEAL PHARMACEUTICALS": {
              "GABAPENTIN": "GABAPENTIN",
              "LAMOTRIGINE": "LAMOTRIGINE",
              "LAMOTRIGINE ER": "LAMOTRIGINE ER",
              "OXCARBAZEPINE": "OXCARBAZEPINE",
              "PREGABALIN": "PREGABALIN",
              "PRIMIDONE": "PRIMIDONE"
            },
            "APHENA PHARMA SOLUTIONS": {
              "CARBAMAZEPINE": "CARBAMAZEPINE",
              "GABAPENTIN": "GABAPENTIN",
              "LYRICA": "LYRICA",
              "MYSOLINE": "MYSOLINE",
              "PREGABALIN": "PREGABALIN",
              "PRIMIDONE": "PRIMIDONE",
              "TOPIRAMATE": "TOPIRAMATE"
            },
            "APOTEX": {
              "CARBAMAZEPINE": "CARBAMAZEPINE",
              "CARBAMAZEPINE ER": "CARBAMAZEPINE ER",
              "GABAPENTIN": "GABAPENTIN",
              "LEVETIRACETAM ER": "LEVETIRACETAM ER",
              "OXCARBAZEPINE": "OXCARBAZEPINE"
            },
            "APOTHECA": {
              "TOPIRAMATE": "TOPIRAMATE"
            },
            "APOTHECA SUPPLY": {
              "CARBAMAZEPINE": "CARBAMAZEPINE"
            },
            "APRECIA PHARMACEUTICALS": {
              "SPRITAM": "SPRITAM"
            },
            "AQ PHARMACEUTICALS": {
              "NEURONTIN": "NEURONTIN"
            },
            "ASCEND LABORATORIES": {
              "GABAPENTIN": "GABAPENTIN",
              "PREGABALIN": "PREGABALIN"
            },
            "ATHENEX PHARMACEUTICAL": {
              "LEVETIRACETAM IN NACL": "LEVETIRACETAM IN NACL"
            },
            "ATLANTIC BIOLOGICALS": {
              "LEVETIRACETAM": "LEVETIRACETAM"
            },
            "AUROBINDO PHARMA": {
              "GABAPENTIN": "GABAPENTIN",
              "LAMOTRIGINE": "LAMOTRIGINE",
              "LEVETIRACETAM": "LEVETIRACETAM",
              "TOPIRAMATE": "TOPIRAMATE"
            },
            "AUROMEDICS PHARMA": {
              "LEVETIRACETAM": "LEVETIRACETAM",
              "LEVETIRACETAM IN NACL": "LEVETIRACETAM IN NACL"
            },
            "AVKARE": {
              "GABAPENTIN": "GABAPENTIN",
              "LAMOTRIGINE": "LAMOTRIGINE",
              "LAMOTRIGINE ER": "LAMOTRIGINE ER",
              "LEVETIRACETAM ER": "LEVETIRACETAM ER",
              "OXCARBAZEPINE": "OXCARBAZEPINE",
              "PRIMIDONE": "PRIMIDONE"
            },
            "AVPAK": {
              "GABAPENTIN": "GABAPENTIN",
              "LEVETIRACETAM": "LEVETIRACETAM",
              "PRIMIDONE": "PRIMIDONE",
              "TOPIRAMATE": "TOPIRAMATE",
              "ZONISAMIDE": "ZONISAMIDE"
            },
            "BAUSCH HEALTH": {
              "MYSOLINE": "MYSOLINE"
            },
            "BI COASTAL PHARMACEUTICAL": {
              "GABAPENTIN": "GABAPENTIN"
            },
            "BIOCODEX": {
              "DIACOMIT": "DIACOMIT"
            },
            "BLENHEIM PHARMACAL": {
              "GABAPENTIN": "GABAPENTIN",
              "TOPIRAMATE": "TOPIRAMATE"
            },
            "BLUE POINT LABORATORIES": {
              "GABAPENTIN": "GABAPENTIN",
              "LEVETIRACETAM": "LEVETIRACETAM",
              "LEVETIRACETAM ER": "LEVETIRACETAM ER",
              "ZONISAMIDE": "ZONISAMIDE"
            },
            "BRECKENRIDGE": {
              "LEVETIRACETAM": "LEVETIRACETAM",
              "OXCARBAZEPINE": "OXCARBAZEPINE"
            },
            "BRYANT RANCH PREPACK": {
              "CARBAMAZEPINE": "CARBAMAZEPINE",
              "GABAPENTIN": "GABAPENTIN",
              "LAMOTRIGINE": "LAMOTRIGINE",
              "LEVETIRACETAM": "LEVETIRACETAM",
              "LYRICA": "LYRICA",
              "OXCARBAZEPINE": "OXCARBAZEPINE",
              "PREGABALIN": "PREGABALIN",
              "TOPAMAX": "TOPAMAX",
              "TOPIRAMATE": "TOPIRAMATE",
              "ZONISAMIDE": "ZONISAMIDE"
            },
            "CADISTA": {
              "LAMOTRIGINE": "LAMOTRIGINE",
              "OXCARBAZEPINE": "OXCARBAZEPINE"
            },
            "CAMBER PHARMACEUTICALS": {
              "LEVETIRACETAM": "LEVETIRACETAM",
              "PREGABALIN": "PREGABALIN",
              "TOPIRAMATE": "TOPIRAMATE"
            },
            "CARDINAL HEALTH REPACKAGING SO": {
              "GABAPENTIN": "GABAPENTIN",
              "LEVETIRACETAM": "LEVETIRACETAM"
            },
            "CELLTRION USA": {
              "PREGABALIN": "PREGABALIN"
            },
            "CIPLA USA": {
              "GABAPENTIN": "GABAPENTIN",
              "LAMOTRIGINE": "LAMOTRIGINE",
              "PREGABALIN": "PREGABALIN",
              "TOPIRAMATE": "TOPIRAMATE",
              "ZONISAMIDE": "ZONISAMIDE"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "GABAPENTIN": "GABAPENTIN",
              "LAMOTRIGINE": "LAMOTRIGINE",
              "TOPIRAMATE": "TOPIRAMATE"
            },
            "CONCORDIA PHARMACEUTICALS": {
              "ZONEGRAN": "ZONEGRAN"
            },
            "DENTON PHARMA": {
              "TOPIRAMATE": "TOPIRAMATE"
            },
            "DIRECT RX": {
              "GABAPENTIN": "GABAPENTIN",
              "LAMOTRIGINE": "LAMOTRIGINE",
              "LEVETIRACETAM": "LEVETIRACETAM",
              "LYRICA": "LYRICA",
              "OXCARBAZEPINE": "OXCARBAZEPINE",
              "PREGABALIN": "PREGABALIN",
              "TOPIRAMATE": "TOPIRAMATE",
              "ZONISAMIDE": "ZONISAMIDE"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "LAMOTRIGINE": "LAMOTRIGINE",
              "LAMOTRIGINE ER": "LAMOTRIGINE ER",
              "LEVETIRACETAM": "LEVETIRACETAM",
              "LEVETIRACETAM IN NACL": "LEVETIRACETAM IN NACL",
              "PREGABALIN": "PREGABALIN"
            },
            "EISAI": {
              "BANZEL": "BANZEL"
            },
            "ENOVACHEM MANUFACTURING": {
              "PREGABALIN": "PREGABALIN"
            },
            "EPIC PHARMA": {
              "GABAPENTIN": "GABAPENTIN"
            },
            "EXELAN PHARMACEUTICALS": {
              "GABAPENTIN": "GABAPENTIN",
              "LEVETIRACETAM": "LEVETIRACETAM",
              "PREGABALIN": "PREGABALIN",
              "TOPIRAMATE": "TOPIRAMATE",
              "ZONISAMIDE": "ZONISAMIDE"
            },
            "EYWA PHARMA": {
              "LEVETIRACETAM": "LEVETIRACETAM"
            },
            "FAGRON": {
              "CARBAMAZEPINE": "CARBAMAZEPINE"
            },
            "FREEDOM PHARMACEUTICALS": {
              "CARBAMAZEPINE": "CARBAMAZEPINE"
            },
            "FRESENIUS KABI USA": {
              "LEVETIRACETAM": "LEVETIRACETAM"
            },
            "FUSION PHARMACEUTICALS": {
              "FANATREX FUSEPAQ": "FANATREX FUSEPAQ"
            },
            "GLAXO SMITH KLINE": {
              "LAMICTAL": "LAMICTAL",
              "LAMICTAL ODT": "LAMICTAL ODT",
              "LAMICTAL STARTER": "LAMICTAL STARTER",
              "LAMICTAL XR": "LAMICTAL XR",
              "POTIGA": "POTIGA"
            },
            "GLENMARK PHARMACEUTICALS": {
              "GABAPENTIN": "GABAPENTIN",
              "LAMOTRIGINE": "LAMOTRIGINE",
              "OXCARBAZEPINE": "OXCARBAZEPINE",
              "TOPIRAMATE": "TOPIRAMATE",
              "ZONISAMIDE": "ZONISAMIDE"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "CARBAMAZEPINE": "CARBAMAZEPINE",
              "GABAPENTIN": "GABAPENTIN",
              "LAMOTRIGINE": "LAMOTRIGINE",
              "LEVETIRACETAM ER": "LEVETIRACETAM ER",
              "OXCARBAZEPINE": "OXCARBAZEPINE",
              "PREGABALIN": "PREGABALIN",
              "TOPIRAMATE": "TOPIRAMATE",
              "ZONISAMIDE": "ZONISAMIDE"
            },
            "GRANULES PHARMACEUTICALS": {
              "GABAPENTIN": "GABAPENTIN"
            },
            "GREENSTONE": {
              "GABAPENTIN": "GABAPENTIN",
              "PREGABALIN": "PREGABALIN"
            },
            "GREENWICH BIOSCIENCES": {
              "EPIDIOLEX": "EPIDIOLEX"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "GABAPENTIN": "GABAPENTIN",
              "LYRICA": "LYRICA",
              "NEURONTIN": "NEURONTIN",
              "TEGRETOL": "TEGRETOL",
              "TOPAMAX": "TOPAMAX",
              "TOPIRAMATE": "TOPIRAMATE",
              "ZONEGRAN": "ZONEGRAN"
            },
            "HI-TECH": {
              "GABAPENTIN": "GABAPENTIN",
              "LEVETIRACETAM": "LEVETIRACETAM"
            },
            "HIKMA": {
              "LEVETIRACETAM": "LEVETIRACETAM",
              "OXCARBAZEPINE": "OXCARBAZEPINE",
              "RUFINAMIDE": "RUFINAMIDE"
            },
            "HOSPIRA": {
              "LEVETIRACETAM": "LEVETIRACETAM"
            },
            "IMPAX GENERICS": {
              "LAMOTRIGINE": "LAMOTRIGINE"
            },
            "JANSSEN": {
              "TOPAMAX": "TOPAMAX",
              "TOPAMAX SPRINKLE": "TOPAMAX SPRINKLE"
            },
            "LANNETT": {
              "PRIMIDONE": "PRIMIDONE"
            },
            "LETCO MEDICAL": {
              "CARBAMAZEPINE": "CARBAMAZEPINE"
            },
            "LIBERTY PHARMACEUTICAL": {
              "CARBAMAZEPINE": "CARBAMAZEPINE",
              "GABAPENTIN": "GABAPENTIN",
              "LAMOTRIGINE": "LAMOTRIGINE",
              "PRIMIDONE": "PRIMIDONE",
              "ZONISAMIDE": "ZONISAMIDE"
            },
            "LOHXA": {
              "GABAPENTIN": "GABAPENTIN",
              "LEVETIRACETAM": "LEVETIRACETAM"
            },
            "LUPIN PHARMACEUTICALS": {
              "LEVETIRACETAM": "LEVETIRACETAM",
              "LEVETIRACETAM ER": "LEVETIRACETAM ER"
            },
            "MAJOR PHARMACEUTICALS": {
              "CARBAMAZEPINE": "CARBAMAZEPINE",
              "GABAPENTIN": "GABAPENTIN",
              "LAMOTRIGINE": "LAMOTRIGINE",
              "LEVETIRACETAM": "LEVETIRACETAM",
              "PREGABALIN": "PREGABALIN",
              "PRIMIDONE": "PRIMIDONE"
            },
            "MARLEX PHARMACEUTICALS": {
              "GABAPENTIN": "GABAPENTIN",
              "LEVETIRACETAM": "LEVETIRACETAM",
              "PRIMIDONE": "PRIMIDONE"
            },
            "MAS MANAGEMENT GROUP": {
              "GABAPENTIN": "GABAPENTIN"
            },
            "MEDISCA": {
              "CARBAMAZEPINE": "CARBAMAZEPINE"
            },
            "MEGALITH PHARMACEUTICALS": {
              "GABAPENTIN": "GABAPENTIN"
            },
            "METHOD PHARMACEUTICALS": {
              "GABAPENTIN": "GABAPENTIN"
            },
            "MORTON GROVE PHARMACEUTICALS": {
              "CARBAMAZEPINE": "CARBAMAZEPINE",
              "LEVETIRACETAM": "LEVETIRACETAM"
            },
            "MURFREESBORO PHARM NURSING SUP": {
              "LAMOTRIGINE": "LAMOTRIGINE"
            },
            "MYLAN": {
              "GABAPENTIN": "GABAPENTIN",
              "LAMOTRIGINE": "LAMOTRIGINE",
              "LEVETIRACETAM": "LEVETIRACETAM",
              "TOPIRAMATE": "TOPIRAMATE",
              "ZONISAMIDE": "ZONISAMIDE"
            },
            "MYLAN INSTITUTIONAL": {
              "CARBAMAZEPINE": "CARBAMAZEPINE",
              "GABAPENTIN": "GABAPENTIN",
              "LAMOTRIGINE": "LAMOTRIGINE",
              "LEVETIRACETAM": "LEVETIRACETAM",
              "LEVETIRACETAM IN NACL": "LEVETIRACETAM IN NACL",
              "TOPIRAMATE": "TOPIRAMATE",
              "ZONISAMIDE": "ZONISAMIDE"
            },
            "NEW HORIZON RX GROUP": {
              "GABAPENTIN": "GABAPENTIN"
            },
            "NEXUS PHARMA": {
              "LEVETIRACETAM IN NACL": "LEVETIRACETAM IN NACL"
            },
            "NIVAGEN PHARMACEUTICALS": {
              "CARBAMAZEPINE": "CARBAMAZEPINE",
              "LEVETIRACETAM ER": "LEVETIRACETAM ER"
            },
            "NORTHSTAR RX": {
              "GABAPENTIN": "GABAPENTIN",
              "LAMOTRIGINE": "LAMOTRIGINE",
              "LAMOTRIGINE ER": "LAMOTRIGINE ER",
              "LEVETIRACETAM": "LEVETIRACETAM"
            },
            "NOSTRUM LABORATORIES": {
              "CARBAMAZEPINE ER": "CARBAMAZEPINE ER"
            },
            "NOVADOZ PHARMACEUTICALS": {
              "PREGABALIN": "PREGABALIN"
            },
            "NOVAPLUS/DR.REDDY'S": {
              "LEVETIRACETAM IN NACL": "LEVETIRACETAM IN NACL"
            },
            "NOVAPLUS/HIKMA": {
              "LEVETIRACETAM": "LEVETIRACETAM"
            },
            "NOVARTIS": {
              "TEGRETOL": "TEGRETOL",
              "TEGRETOL-XR": "TEGRETOL-XR",
              "TRILEPTAL": "TRILEPTAL"
            },
            "NUCARE PHARMACEUTICALS": {
              "CARBAMAZEPINE": "CARBAMAZEPINE",
              "GABAPENTIN": "GABAPENTIN",
              "KEPPRA": "KEPPRA",
              "LYRICA": "LYRICA",
              "NEURONTIN": "NEURONTIN",
              "TOPAMAX": "TOPAMAX",
              "TOPIRAMATE": "TOPIRAMATE",
              "TRILEPTAL": "TRILEPTAL"
            },
            "OCEANSIDE PHARMACEUTICALS": {
              "PRIMIDONE": "PRIMIDONE"
            },
            "ORCHIDPHARMA": {
              "LEVETIRACETAM": "LEVETIRACETAM"
            },
            "OWP PHARMACEUTICALS": {
              "LAMOTRIGINE STARTER KIT-BLUE": "LAMOTRIGINE STARTER KIT-BLUE",
              "LAMOTRIGINE STARTER KIT-GREEN": "LAMOTRIGINE STARTER KIT-GREEN",
              "LAMOTRIGINE STARTER KIT-ORANGE": "LAMOTRIGINE STARTER KIT-ORANGE",
              "ROWEEPRA": "ROWEEPRA",
              "ROWEEPRA XR": "ROWEEPRA XR",
              "SUBVENITE": "SUBVENITE",
              "SUBVENITE STARTER KIT-BLUE": "SUBVENITE STARTER KIT-BLUE",
              "SUBVENITE STARTER KIT-GREEN": "SUBVENITE STARTER KIT-GREEN",
              "SUBVENITE STARTER KIT-ORANGE": "SUBVENITE STARTER KIT-ORANGE"
            },
            "PAR PHARMACEUTICAL": {
              "LAMOTRIGINE": "LAMOTRIGINE",
              "LAMOTRIGINE ER": "LAMOTRIGINE ER",
              "LEVETIRACETAM": "LEVETIRACETAM"
            },
            "PAR PHARMACEUTICALS": {
              "LEVETIRACETAM": "LEVETIRACETAM",
              "LEVETIRACETAM ER": "LEVETIRACETAM ER",
              "PRIMIDONE": "PRIMIDONE"
            },
            "PCCA": {
              "CARBAMAZEPINE": "CARBAMAZEPINE"
            },
            "PDRX PHARMACEUTICAL": {
              "CARBAMAZEPINE": "CARBAMAZEPINE",
              "GABAPENTIN": "GABAPENTIN",
              "KEPPRA": "KEPPRA",
              "LAMICTAL": "LAMICTAL",
              "LAMOTRIGINE": "LAMOTRIGINE",
              "LEVETIRACETAM": "LEVETIRACETAM",
              "LYRICA": "LYRICA",
              "NEURONTIN": "NEURONTIN",
              "TEGRETOL": "TEGRETOL",
              "TEGRETOL-XR": "TEGRETOL-XR",
              "TOPAMAX": "TOPAMAX",
              "TOPIRAMATE": "TOPIRAMATE",
              "TRILEPTAL": "TRILEPTAL",
              "ZONISAMIDE": "ZONISAMIDE"
            },
            "PDRX PHARMACEUTICALS": {
              "GABAPENTIN": "GABAPENTIN",
              "TOPIRAMATE": "TOPIRAMATE"
            },
            "PFIZER U.S.": {
              "LYRICA": "LYRICA",
              "NEURONTIN": "NEURONTIN"
            },
            "PHARMACEUTICA NORTH AMERICA": {
              "GABAPENTIN": "GABAPENTIN"
            },
            "PHARMACEUTICAL ASSOCIATES": {
              "CARBAMAZEPINE": "CARBAMAZEPINE",
              "LEVETIRACETAM": "LEVETIRACETAM"
            },
            "PHARMEDIX": {
              "GABAPENTIN": "GABAPENTIN"
            },
            "PRASCO LABORATORIES": {
              "CARBAMAZEPINE ER": "CARBAMAZEPINE ER"
            },
            "PRECISION DOSE, INC": {
              "CARBAMAZEPINE": "CARBAMAZEPINE",
              "OXCARBAZEPINE": "OXCARBAZEPINE"
            },
            "PREFERRED PHARMACEUTICALS": {
              "CARBAMAZEPINE": "CARBAMAZEPINE",
              "GABAPENTIN": "GABAPENTIN",
              "LAMOTRIGINE": "LAMOTRIGINE",
              "LAMOTRIGINE ER": "LAMOTRIGINE ER",
              "LEVETIRACETAM": "LEVETIRACETAM",
              "OXCARBAZEPINE": "OXCARBAZEPINE",
              "PREGABALIN": "PREGABALIN",
              "TOPIRAMATE": "TOPIRAMATE",
              "ZONISAMIDE": "ZONISAMIDE"
            },
            "PROFICIENT RX": {
              "CARBAMAZEPINE": "CARBAMAZEPINE",
              "GABAPENTIN": "GABAPENTIN",
              "LAMOTRIGINE": "LAMOTRIGINE",
              "LEVETIRACETAM": "LEVETIRACETAM",
              "OXCARBAZEPINE": "OXCARBAZEPINE",
              "PREGABALIN": "PREGABALIN",
              "TOPIRAMATE": "TOPIRAMATE",
              "ZONISAMIDE": "ZONISAMIDE"
            },
            "QUALITY CARE": {
              "CARBAMAZEPINE": "CARBAMAZEPINE",
              "GABAPENTIN": "GABAPENTIN",
              "KEPPRA": "KEPPRA",
              "KEPPRA XR": "KEPPRA XR",
              "LAMICTAL": "LAMICTAL",
              "LAMOTRIGINE": "LAMOTRIGINE",
              "LEVETIRACETAM": "LEVETIRACETAM",
              "LYRICA": "LYRICA",
              "NEURONTIN": "NEURONTIN",
              "PRIMIDONE": "PRIMIDONE",
              "TOPAMAX": "TOPAMAX",
              "TOPIRAMATE": "TOPIRAMATE",
              "TRILEPTAL": "TRILEPTAL",
              "VIMPAT": "VIMPAT",
              "ZONEGRAN": "ZONEGRAN",
              "ZONISAMIDE": "ZONISAMIDE"
            },
            "QUALITY CARE PRODUCTS": {
              "GABAPENTIN": "GABAPENTIN",
              "LAMOTRIGINE": "LAMOTRIGINE",
              "LYRICA": "LYRICA",
              "OXCARBAZEPINE": "OXCARBAZEPINE",
              "PREGABALIN": "PREGABALIN",
              "TOPIRAMATE": "TOPIRAMATE",
              "ZONISAMIDE": "ZONISAMIDE"
            },
            "QUINN PHARMACEUTICALS": {
              "LEVETIRACETAM ER": "LEVETIRACETAM ER"
            },
            "RICHMOND PHARMACEUTICALS": {
              "PRIMIDONE": "PRIMIDONE"
            },
            "RISING PHARMACEUTICALS": {
              "PREGABALIN": "PREGABALIN"
            },
            "RXCHANGE CO": {
              "GABAPENTIN": "GABAPENTIN",
              "LYRICA": "LYRICA",
              "TOPAMAX": "TOPAMAX",
              "ZONEGRAN": "ZONEGRAN"
            },
            "RXPAK MCKESSON": {
              "GABAPENTIN": "GABAPENTIN"
            },
            "SAGENT PHARMACEUTICAL": {
              "LEVETIRACETAM": "LEVETIRACETAM"
            },
            "SANDOZ": {
              "CARBAMAZEPINE ER": "CARBAMAZEPINE ER",
              "LEVETIRACETAM": "LEVETIRACETAM",
              "OXCARBAZEPINE": "OXCARBAZEPINE",
              "TOPIRAMATE": "TOPIRAMATE"
            },
            "SCIEGEN PHARMACEUTICALS": {
              "GABAPENTIN": "GABAPENTIN",
              "PREGABALIN": "PREGABALIN"
            },
            "SHIRE US INC.": {
              "CARBATROL": "CARBATROL"
            },
            "SILARX": {
              "LEVETIRACETAM": "LEVETIRACETAM"
            },
            "SKY PACKAGING": {
              "GABAPENTIN": "GABAPENTIN",
              "LAMOTRIGINE": "LAMOTRIGINE",
              "LEVETIRACETAM": "LEVETIRACETAM"
            },
            "SOLCO HEALTHCARE": {
              "GABAPENTIN": "GABAPENTIN",
              "LEVETIRACETAM": "LEVETIRACETAM",
              "LEVETIRACETAM ER": "LEVETIRACETAM ER"
            },
            "SPECTRUM": {
              "CARBAMAZEPINE": "CARBAMAZEPINE"
            },
            "ST MARY'S MPP": {
              "GABAPENTIN": "GABAPENTIN",
              "LAMOTRIGINE": "LAMOTRIGINE",
              "LYRICA": "LYRICA",
              "PREGABALIN": "PREGABALIN",
              "TOPIRAMATE": "TOPIRAMATE"
            },
            "STERLING KNIGHT PHARMACEUTICAL": {
              "GABAPENTIN": "GABAPENTIN"
            },
            "STRIDES PHARMA": {
              "GABAPENTIN": "GABAPENTIN"
            },
            "SUN PHARMACEUTICALS": {
              "GABAPENTIN": "GABAPENTIN",
              "LEVETIRACETAM": "LEVETIRACETAM",
              "LEVETIRACETAM ER": "LEVETIRACETAM ER",
              "OXCARBAZEPINE": "OXCARBAZEPINE",
              "PREGABALIN": "PREGABALIN",
              "TOPIRAMATE": "TOPIRAMATE",
              "ZONISAMIDE": "ZONISAMIDE"
            },
            "SUNOVION PHARMACEUTICALS": {
              "APTIOM": "APTIOM"
            },
            "SUPERNUS PHARMACEUTICALS": {
              "OXTELLAR XR": "OXTELLAR XR",
              "TROKENDI XR": "TROKENDI XR"
            },
            "TAGI PHARMA": {
              "GABAPENTIN": "GABAPENTIN",
              "LEVETIRACETAM": "LEVETIRACETAM"
            },
            "TARO": {
              "CARBAMAZEPINE": "CARBAMAZEPINE",
              "CARBAMAZEPINE ER": "CARBAMAZEPINE ER",
              "LAMOTRIGINE": "LAMOTRIGINE",
              "LEVETIRACETAM": "LEVETIRACETAM"
            },
            "TEVA PHARMACEUTICALS USA": {
              "CARBAMAZEPINE": "CARBAMAZEPINE",
              "CARBAMAZEPINE ER": "CARBAMAZEPINE ER",
              "EPITOL": "EPITOL",
              "GABAPENTIN": "GABAPENTIN",
              "LAMOTRIGINE": "LAMOTRIGINE",
              "PREGABALIN": "PREGABALIN",
              "TOPIRAMATE": "TOPIRAMATE"
            },
            "TIME-CAP LABS": {
              "GABAPENTIN": "GABAPENTIN"
            },
            "TORRENT PHARMACEUTICALS": {
              "CARBAMAZEPINE": "CARBAMAZEPINE",
              "LAMOTRIGINE": "LAMOTRIGINE",
              "LAMOTRIGINE ER": "LAMOTRIGINE ER",
              "LEVETIRACETAM": "LEVETIRACETAM",
              "LEVETIRACETAM ER": "LEVETIRACETAM ER",
              "TOPIRAMATE": "TOPIRAMATE"
            },
            "TRIGEN LABORATORIES": {
              "LEVETIRACETAM": "LEVETIRACETAM"
            },
            "TRUPHARMA": {
              "LAMOTRIGINE ER": "LAMOTRIGINE ER"
            },
            "UCB PHARMA": {
              "BRIVIACT": "BRIVIACT",
              "KEPPRA": "KEPPRA",
              "KEPPRA XR": "KEPPRA XR",
              "VIMPAT": "VIMPAT"
            },
            "UNICHEM PHARMACEUTICALS": {
              "LAMOTRIGINE": "LAMOTRIGINE",
              "TOPIRAMATE": "TOPIRAMATE"
            },
            "UNIT DOSE SERVICES": {
              "CARBAMAZEPINE": "CARBAMAZEPINE",
              "GABAPENTIN": "GABAPENTIN",
              "LAMOTRIGINE": "LAMOTRIGINE",
              "LEVETIRACETAM": "LEVETIRACETAM",
              "LYRICA": "LYRICA",
              "TOPIRAMATE": "TOPIRAMATE"
            },
            "UPSHER-SMITH": {
              "CARBAMAZEPINE": "CARBAMAZEPINE",
              "LAMOTRIGINE": "LAMOTRIGINE",
              "QUDEXY XR": "QUDEXY XR",
              "TOPIRAMATE": "TOPIRAMATE",
              "TOPIRAMATE ER": "TOPIRAMATE ER"
            },
            "VA CMOP DALLAS": {
              "GABAPENTIN": "GABAPENTIN"
            },
            "VANGARD": {
              "CARBAMAZEPINE": "CARBAMAZEPINE"
            },
            "VISTAPHARM INC.": {
              "LEVETIRACETAM": "LEVETIRACETAM"
            },
            "WESTMINSTER PHARMACEUTICALS": {
              "GABAPENTIN": "GABAPENTIN"
            },
            "WILSHIRE PHARMACEUTICALS": {
              "LAMOTRIGINE ER": "LAMOTRIGINE ER"
            },
            "X-GEN PHARMACEUTICALS, INC": {
              "LEVETIRACETAM": "LEVETIRACETAM"
            },
            "ZOGENIX": {
              "FINTEPLA": "FINTEPLA"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "GABAPENTIN": "GABAPENTIN",
              "LAMOTRIGINE": "LAMOTRIGINE",
              "LAMOTRIGINE ER": "LAMOTRIGINE ER",
              "TOPIRAMATE": "TOPIRAMATE"
            }
          }
        },
        "Carbamates": {
          "Carbamates": {
            "ALVOGEN": {
              "FELBAMATE": "FELBAMATE"
            },
            "AMNEAL PHARMACEUTICALS": {
              "FELBAMATE": "FELBAMATE"
            },
            "ANI  PHARMACEUTICALS": {
              "FELBAMATE": "FELBAMATE"
            },
            "IMPAX GENERICS": {
              "FELBAMATE": "FELBAMATE"
            },
            "MARLEX PHARMACEUTICALS": {
              "FELBAMATE": "FELBAMATE"
            },
            "MYLAN": {
              "FELBAMATE": "FELBAMATE"
            },
            "MYLAN SPECIALTY L.P.": {
              "FELBATOL": "FELBATOL"
            },
            "NORTHSTAR RX": {
              "FELBAMATE": "FELBAMATE"
            },
            "PHARMACEUTICAL ASSOCIATES": {
              "FELBAMATE": "FELBAMATE"
            },
            "SK LIFE SCIENCE": {
              "XCOPRI": "XCOPRI",
              "XCOPRI (250 MG DAILY DOSE)": "XCOPRI (250 MG DAILY DOSE)",
              "XCOPRI (350 MG DAILY DOSE)": "XCOPRI (350 MG DAILY DOSE)"
            },
            "TARO": {
              "FELBAMATE": "FELBAMATE"
            },
            "VISTAPHARM INC.": {
              "FELBAMATE": "FELBAMATE"
            },
            "WALLACE PHARMACEUTICALS": {
              "FELBAMATE": "FELBAMATE"
            }
          }
        },
        "GABA Modulators": {
          "GABA Modulators": {
            "ACTAVIS PHARMA": {
              "VIGABATRIN": "VIGABATRIN"
            },
            "AIDAREX PHARMACEUTICALS": {
              "GABITRIL": "GABITRIL"
            },
            "AMNEAL PHARMACEUTICALS": {
              "TIAGABINE HCL": "TIAGABINE HCL",
              "VIGABATRIN": "VIGABATRIN"
            },
            "CEPHALON": {
              "TIAGABINE HCL": "TIAGABINE HCL"
            },
            "CIPLA USA": {
              "VIGABATRIN": "VIGABATRIN"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "VIGABATRIN": "VIGABATRIN"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "GABITRIL": "GABITRIL"
            },
            "LUNDBECK": {
              "SABRIL": "SABRIL"
            },
            "NUCARE PHARMACEUTICALS": {
              "GABITRIL": "GABITRIL"
            },
            "PAR PHARMACEUTICAL": {
              "VIGABATRIN": "VIGABATRIN"
            },
            "QUALITY CARE": {
              "GABITRIL": "GABITRIL"
            },
            "SUN PHARMACEUTICALS": {
              "TIAGABINE HCL": "TIAGABINE HCL"
            },
            "TEVA PHARMACEUTICALS USA": {
              "GABITRIL": "GABITRIL",
              "TIAGABINE HCL": "TIAGABINE HCL"
            },
            "UPSHER-SMITH": {
              "VIGADRONE": "VIGADRONE"
            }
          }
        },
        "Hydantoins": {
          "Hydantoins": {
            "A-S MEDICATION SOLUTIONS": {
              "DILANTIN": "DILANTIN",
              "PHENYTOIN SODIUM EXTENDED": "PHENYTOIN SODIUM EXTENDED"
            },
            "ACTAVIS MID ATLANTIC": {
              "PHENYTOIN": "PHENYTOIN"
            },
            "AIDAREX PHARMACEUTICALS": {
              "PHENYTOIN SODIUM EXTENDED": "PHENYTOIN SODIUM EXTENDED"
            },
            "AMERICAN HEALTH PACKAGING": {
              "PHENYTOIN": "PHENYTOIN",
              "PHENYTOIN SODIUM EXTENDED": "PHENYTOIN SODIUM EXTENDED"
            },
            "AMERICAN PHARMA INGREDIENTS": {
              "PHENYTOIN SODIUM": "PHENYTOIN SODIUM"
            },
            "AMERICAN REGENT": {
              "FOSPHENYTOIN SODIUM": "FOSPHENYTOIN SODIUM"
            },
            "AMNEAL BIOSCIENCES": {
              "FOSPHENYTOIN SODIUM": "FOSPHENYTOIN SODIUM"
            },
            "AMNEAL PHARMACEUTICALS": {
              "PHENYTOIN SODIUM EXTENDED": "PHENYTOIN SODIUM EXTENDED"
            },
            "APHENA PHARMA SOLUTIONS": {
              "DILANTIN": "DILANTIN",
              "PHENYTOIN SODIUM EXTENDED": "PHENYTOIN SODIUM EXTENDED"
            },
            "APOTHECA SUPPLY": {
              "PHENYTOIN SODIUM": "PHENYTOIN SODIUM"
            },
            "ATLANTIC BIOLOGICALS": {
              "PHENYTOIN": "PHENYTOIN"
            },
            "AUROBINDO PHARMA": {
              "PHENYTOIN SODIUM EXTENDED": "PHENYTOIN SODIUM EXTENDED"
            },
            "AVKARE": {
              "PHENYTOIN SODIUM EXTENDED": "PHENYTOIN SODIUM EXTENDED"
            },
            "AVPAK": {
              "PHENYTOIN SODIUM EXTENDED": "PHENYTOIN SODIUM EXTENDED"
            },
            "BRYANT RANCH PREPACK": {
              "PHENYTOIN SODIUM EXTENDED": "PHENYTOIN SODIUM EXTENDED"
            },
            "CARDINAL HEALTH REPACKAGING SO": {
              "PHENYTOIN SODIUM EXTENDED": "PHENYTOIN SODIUM EXTENDED"
            },
            "DIRECT RX": {
              "PHENYTOIN SODIUM EXTENDED": "PHENYTOIN SODIUM EXTENDED"
            },
            "FAGRON": {
              "PHENYTOIN SODIUM": "PHENYTOIN SODIUM"
            },
            "FRESENIUS KABI USA": {
              "FOSPHENYTOIN SODIUM": "FOSPHENYTOIN SODIUM"
            },
            "GREENSTONE": {
              "PHENYTOIN": "PHENYTOIN",
              "PHENYTOIN INFATABS": "PHENYTOIN INFATABS"
            },
            "HIKMA": {
              "FOSPHENYTOIN SODIUM": "FOSPHENYTOIN SODIUM",
              "PHENYTOIN SODIUM": "PHENYTOIN SODIUM"
            },
            "HOSPIRA": {
              "PHENYTOIN SODIUM": "PHENYTOIN SODIUM"
            },
            "HUMCO": {
              "PHENYTOIN SODIUM": "PHENYTOIN SODIUM"
            },
            "IMPAX GENERICS": {
              "PHENYTOIN": "PHENYTOIN"
            },
            "LETCO MEDICAL": {
              "PHENYTOIN SODIUM": "PHENYTOIN SODIUM"
            },
            "MAJOR PHARMACEUTICALS": {
              "PHENYTOIN SODIUM EXTENDED": "PHENYTOIN SODIUM EXTENDED"
            },
            "MARLEX PHARMACEUTICALS": {
              "PHENYTOIN SODIUM EXTENDED": "PHENYTOIN SODIUM EXTENDED"
            },
            "MEDISCA": {
              "PHENYTOIN SODIUM": "PHENYTOIN SODIUM"
            },
            "MORTON GROVE PHARMACEUTICALS": {
              "PHENYTOIN": "PHENYTOIN"
            },
            "MYLAN": {
              "PHENYTEK": "PHENYTEK",
              "PHENYTOIN": "PHENYTOIN",
              "PHENYTOIN SODIUM EXTENDED": "PHENYTOIN SODIUM EXTENDED"
            },
            "MYLAN INSTITUTIONAL": {
              "FOSPHENYTOIN SODIUM": "FOSPHENYTOIN SODIUM",
              "PHENYTOIN": "PHENYTOIN",
              "PHENYTOIN SODIUM EXTENDED": "PHENYTOIN SODIUM EXTENDED"
            },
            "NOVAPLUS/HIKMA": {
              "PHENYTOIN SODIUM": "PHENYTOIN SODIUM"
            },
            "NOVAPLUS/PFIZER U.S.": {
              "CEREBYX": "CEREBYX"
            },
            "NUCARE PHARMACEUTICALS": {
              "DILANTIN": "DILANTIN",
              "PHENYTOIN SODIUM": "PHENYTOIN SODIUM"
            },
            "PCCA": {
              "PHENYTOIN SODIUM": "PHENYTOIN SODIUM"
            },
            "PDRX PHARMACEUTICAL": {
              "DILANTIN": "DILANTIN",
              "PHENYTOIN SODIUM EXTENDED": "PHENYTOIN SODIUM EXTENDED"
            },
            "PFIZER U.S.": {
              "CEREBYX": "CEREBYX",
              "DILANTIN": "DILANTIN",
              "DILANTIN INFATABS": "DILANTIN INFATABS"
            },
            "PHARMACEUTICA NORTH AMERICA": {
              "PHENYTOIN SODIUM": "PHENYTOIN SODIUM"
            },
            "PHARMACEUTICAL ASSOCIATES": {
              "PHENYTOIN": "PHENYTOIN"
            },
            "PHARMEDIX": {
              "PHENYTOIN SODIUM EXTENDED": "PHENYTOIN SODIUM EXTENDED"
            },
            "PROFICIENT RX": {
              "PHENYTOIN SODIUM EXTENDED": "PHENYTOIN SODIUM EXTENDED"
            },
            "QUALITY CARE": {
              "DILANTIN": "DILANTIN",
              "PHENYTOIN SODIUM EXTENDED": "PHENYTOIN SODIUM EXTENDED"
            },
            "RECORDATI RARE DISEASES": {
              "PEGANONE": "PEGANONE"
            },
            "SPECTRUM": {
              "PHENYTOIN SODIUM": "PHENYTOIN SODIUM"
            },
            "SUN PHARMACEUTICALS": {
              "PHENYTOIN SODIUM EXTENDED": "PHENYTOIN SODIUM EXTENDED"
            },
            "TARO": {
              "PHENYTOIN": "PHENYTOIN",
              "PHENYTOIN SODIUM EXTENDED": "PHENYTOIN SODIUM EXTENDED"
            },
            "UPSHER-SMITH": {
              "PHENYTOIN SODIUM EXTENDED": "PHENYTOIN SODIUM EXTENDED"
            },
            "VISTAPHARM INC.": {
              "PHENYTOIN": "PHENYTOIN"
            },
            "WEAVER PHARMACEUTICALS": {
              "PHENYTOIN SODIUM": "PHENYTOIN SODIUM"
            },
            "WOCKHARDT USA": {
              "FOSPHENYTOIN SODIUM": "FOSPHENYTOIN SODIUM"
            },
            "X-GEN PHARMACEUTICALS, INC": {
              "PHENYTOIN SODIUM": "PHENYTOIN SODIUM"
            }
          }
        },
        "Succinimides": {
          "Succinimides": {
            "AKORN": {
              "ETHOSUXIMIDE": "ETHOSUXIMIDE"
            },
            "AVET PHARMACEUTICALS": {
              "ETHOSUXIMIDE": "ETHOSUXIMIDE"
            },
            "BIONPHARMA": {
              "ETHOSUXIMIDE": "ETHOSUXIMIDE"
            },
            "EPIC PHARMA": {
              "ETHOSUXIMIDE": "ETHOSUXIMIDE"
            },
            "GREENSTONE": {
              "ETHOSUXIMIDE": "ETHOSUXIMIDE"
            },
            "PFIZER U.S.": {
              "CELONTIN": "CELONTIN",
              "ZARONTIN": "ZARONTIN"
            },
            "PHARMACEUTICAL ASSOCIATES": {
              "ETHOSUXIMIDE": "ETHOSUXIMIDE"
            },
            "STRIDES PHARMA": {
              "ETHOSUXIMIDE": "ETHOSUXIMIDE"
            },
            "TEVA PHARMACEUTICALS USA": {
              "ETHOSUXIMIDE": "ETHOSUXIMIDE"
            }
          }
        },
        "Valproic Acid": {
          "Valproic Acid": {
            "A-S MEDICATION SOLUTIONS": {
              "DIVALPROEX SODIUM": "DIVALPROEX SODIUM",
              "DIVALPROEX SODIUM ER": "DIVALPROEX SODIUM ER"
            },
            "ABBVIE": {
              "DEPACON": "DEPACON",
              "DEPAKENE": "DEPAKENE",
              "DEPAKOTE": "DEPAKOTE",
              "DEPAKOTE ER": "DEPAKOTE ER",
              "DEPAKOTE SPRINKLES": "DEPAKOTE SPRINKLES"
            },
            "ACTAVIS PHARMA": {
              "VALPROIC ACID": "VALPROIC ACID"
            },
            "AIDAREX PHARMACEUTICALS": {
              "DIVALPROEX SODIUM": "DIVALPROEX SODIUM"
            },
            "AJANTA PHARMA LIMITED": {
              "DIVALPROEX SODIUM": "DIVALPROEX SODIUM"
            },
            "AMERICAN HEALTH PACKAGING": {
              "DIVALPROEX SODIUM": "DIVALPROEX SODIUM",
              "DIVALPROEX SODIUM ER": "DIVALPROEX SODIUM ER",
              "VALPROIC ACID": "VALPROIC ACID"
            },
            "AMNEAL PHARMACEUTICALS": {
              "DIVALPROEX SODIUM ER": "DIVALPROEX SODIUM ER"
            },
            "APHENA PHARMA SOLUTIONS": {
              "DEPAKOTE": "DEPAKOTE",
              "DEPAKOTE ER": "DEPAKOTE ER",
              "DIVALPROEX SODIUM": "DIVALPROEX SODIUM",
              "DIVALPROEX SODIUM ER": "DIVALPROEX SODIUM ER",
              "VALPROIC ACID": "VALPROIC ACID"
            },
            "AUROBINDO PHARMA": {
              "DIVALPROEX SODIUM": "DIVALPROEX SODIUM",
              "DIVALPROEX SODIUM ER": "DIVALPROEX SODIUM ER"
            },
            "AVKARE": {
              "VALPROIC ACID": "VALPROIC ACID"
            },
            "AVPAK": {
              "DIVALPROEX SODIUM": "DIVALPROEX SODIUM"
            },
            "BIONPHARMA": {
              "VALPROIC ACID": "VALPROIC ACID"
            },
            "BLUE POINT LABORATORIES": {
              "DIVALPROEX SODIUM ER": "DIVALPROEX SODIUM ER"
            },
            "BRYANT RANCH PREPACK": {
              "DEPAKOTE ER": "DEPAKOTE ER",
              "DIVALPROEX SODIUM": "DIVALPROEX SODIUM"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "DIVALPROEX SODIUM": "DIVALPROEX SODIUM"
            },
            "DIRECT RX": {
              "DIVALPROEX SODIUM": "DIVALPROEX SODIUM",
              "VALPROIC ACID": "VALPROIC ACID"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "DIVALPROEX SODIUM": "DIVALPROEX SODIUM",
              "DIVALPROEX SODIUM ER": "DIVALPROEX SODIUM ER"
            },
            "EYWA PHARMA": {
              "VALPROIC ACID": "VALPROIC ACID"
            },
            "FRESENIUS KABI USA": {
              "VALPROATE SODIUM": "VALPROATE SODIUM"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "VALPROIC ACID": "VALPROIC ACID"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "DEPAKOTE": "DEPAKOTE",
              "VALPROIC ACID": "VALPROIC ACID"
            },
            "HI-TECH": {
              "VALPROIC ACID": "VALPROIC ACID"
            },
            "HIKMA": {
              "VALPROATE SODIUM": "VALPROATE SODIUM"
            },
            "LANNETT": {
              "VALPROIC ACID": "VALPROIC ACID"
            },
            "LUPIN PHARMACEUTICALS": {
              "DIVALPROEX SODIUM ER": "DIVALPROEX SODIUM ER"
            },
            "MAJOR PHARMACEUTICALS": {
              "DIVALPROEX SODIUM": "DIVALPROEX SODIUM",
              "DIVALPROEX SODIUM ER": "DIVALPROEX SODIUM ER"
            },
            "MORTON GROVE PHARMACEUTICALS": {
              "VALPROIC ACID": "VALPROIC ACID"
            },
            "MURFREESBORO PHARM NURSING SUP": {
              "DEPAKOTE ER": "DEPAKOTE ER"
            },
            "MYLAN": {
              "DIVALPROEX SODIUM": "DIVALPROEX SODIUM",
              "DIVALPROEX SODIUM ER": "DIVALPROEX SODIUM ER"
            },
            "MYLAN INSTITUTIONAL": {
              "DIVALPROEX SODIUM": "DIVALPROEX SODIUM",
              "DIVALPROEX SODIUM ER": "DIVALPROEX SODIUM ER"
            },
            "NORTHSTAR RX": {
              "DIVALPROEX SODIUM ER": "DIVALPROEX SODIUM ER"
            },
            "NOVAPLUS/HIKMA": {
              "VALPROATE SODIUM": "VALPROATE SODIUM"
            },
            "NUCARE PHARMACEUTICALS": {
              "DIVALPROEX SODIUM": "DIVALPROEX SODIUM"
            },
            "PAR PHARMACEUTICAL": {
              "DIVALPROEX SODIUM ER": "DIVALPROEX SODIUM ER"
            },
            "PAR PHARMACEUTICALS": {
              "VALPROATE SODIUM": "VALPROATE SODIUM"
            },
            "PDRX PHARMACEUTICAL": {
              "DEPAKOTE": "DEPAKOTE",
              "DEPAKOTE ER": "DEPAKOTE ER",
              "DEPAKOTE SPRINKLES": "DEPAKOTE SPRINKLES",
              "DIVALPROEX SODIUM ER": "DIVALPROEX SODIUM ER",
              "VALPROIC ACID": "VALPROIC ACID"
            },
            "PHARMACEUTICAL ASSOCIATES": {
              "VALPROIC ACID": "VALPROIC ACID"
            },
            "PRECISION DOSE, INC": {
              "VALPROIC ACID": "VALPROIC ACID"
            },
            "PREFERRED PHARMACEUTICALS": {
              "DIVALPROEX SODIUM": "DIVALPROEX SODIUM",
              "DIVALPROEX SODIUM ER": "DIVALPROEX SODIUM ER"
            },
            "PROFICIENT RX": {
              "DIVALPROEX SODIUM": "DIVALPROEX SODIUM"
            },
            "QUALITY CARE": {
              "DEPAKOTE": "DEPAKOTE",
              "DEPAKOTE ER": "DEPAKOTE ER",
              "DEPAKOTE SPRINKLES": "DEPAKOTE SPRINKLES",
              "DIVALPROEX SODIUM": "DIVALPROEX SODIUM"
            },
            "QUALITY CARE PRODUCTS": {
              "DIVALPROEX SODIUM": "DIVALPROEX SODIUM",
              "DIVALPROEX SODIUM ER": "DIVALPROEX SODIUM ER"
            },
            "RISING PHARMACEUTICALS": {
              "DIVALPROEX SODIUM": "DIVALPROEX SODIUM"
            },
            "RXCHANGE CO": {
              "DEPAKOTE": "DEPAKOTE"
            },
            "SANDOZ": {
              "DIVALPROEX SODIUM": "DIVALPROEX SODIUM"
            },
            "SKY PACKAGING": {
              "DIVALPROEX SODIUM": "DIVALPROEX SODIUM",
              "VALPROIC ACID": "VALPROIC ACID"
            },
            "SUN PHARMACEUTICAL": {
              "VALPROIC ACID": "VALPROIC ACID"
            },
            "SUN PHARMACEUTICALS": {
              "DIVALPROEX SODIUM": "DIVALPROEX SODIUM",
              "VALPROIC ACID": "VALPROIC ACID"
            },
            "TEVA PHARMACEUTICALS USA": {
              "DIVALPROEX SODIUM": "DIVALPROEX SODIUM",
              "VALPROIC ACID": "VALPROIC ACID"
            },
            "UNICHEM PHARMACEUTICALS": {
              "DIVALPROEX SODIUM": "DIVALPROEX SODIUM"
            },
            "UNIT DOSE SERVICES": {
              "DIVALPROEX SODIUM": "DIVALPROEX SODIUM"
            },
            "UPSHER-SMITH": {
              "DIVALPROEX SODIUM": "DIVALPROEX SODIUM",
              "VALPROIC ACID": "VALPROIC ACID"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "DIVALPROEX SODIUM": "DIVALPROEX SODIUM",
              "DIVALPROEX SODIUM ER": "DIVALPROEX SODIUM ER"
            }
          }
        }
      },
      "ANTIDEPRESSANTS": {
        "Alpha-2 Receptor Antagonists (Tetracyclics)": {
          "Alpha-2 Receptor Antagonists (Tetracyclics)": {
            "A-S MEDICATION SOLUTIONS": {
              "MIRTAZAPINE": "MIRTAZAPINE"
            },
            "ACTAVIS PHARMA": {
              "MIRTAZAPINE": "MIRTAZAPINE"
            },
            "AIDAREX PHARMACEUTICALS": {
              "MIRTAZAPINE": "MIRTAZAPINE"
            },
            "ALTURA PHARMACEUTICALS": {
              "MIRTAZAPINE": "MIRTAZAPINE"
            },
            "AMERICAN HEALTH PACKAGING": {
              "MIRTAZAPINE": "MIRTAZAPINE"
            },
            "APHENA PHARMA SOLUTIONS": {
              "MIRTAZAPINE": "MIRTAZAPINE",
              "REMERON": "REMERON"
            },
            "APOTEX": {
              "MIRTAZAPINE": "MIRTAZAPINE"
            },
            "AUROBINDO PHARMA": {
              "MIRTAZAPINE": "MIRTAZAPINE"
            },
            "AUROBINDO PHARMA USA": {
              "MIRTAZAPINE": "MIRTAZAPINE"
            },
            "BRYANT RANCH PREPACK": {
              "MIRTAZAPINE": "MIRTAZAPINE"
            },
            "CARDINAL HEALTH REPACKAGING SO": {
              "MIRTAZAPINE": "MIRTAZAPINE"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "MIRTAZAPINE": "MIRTAZAPINE"
            },
            "DIRECT RX": {
              "MIRTAZAPINE": "MIRTAZAPINE"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "MIRTAZAPINE": "MIRTAZAPINE"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "MIRTAZAPINE": "MIRTAZAPINE"
            },
            "IMPAX GENERICS": {
              "MIRTAZAPINE": "MIRTAZAPINE"
            },
            "LIBERTY PHARMACEUTICAL": {
              "MIRTAZAPINE": "MIRTAZAPINE"
            },
            "MAJOR PHARMACEUTICALS": {
              "MIRTAZAPINE": "MIRTAZAPINE"
            },
            "MYLAN": {
              "MIRTAZAPINE": "MIRTAZAPINE"
            },
            "MYLAN INSTITUTIONAL": {
              "MIRTAZAPINE": "MIRTAZAPINE"
            },
            "NORTHSTAR RX": {
              "MIRTAZAPINE": "MIRTAZAPINE"
            },
            "NUCARE PHARMACEUTICALS": {
              "MIRTAZAPINE": "MIRTAZAPINE"
            },
            "ORGANON": {
              "REMERON": "REMERON",
              "REMERON SOLTAB": "REMERON SOLTAB"
            },
            "PDRX PHARMACEUTICAL": {
              "MIRTAZAPINE": "MIRTAZAPINE"
            },
            "PRASCO LABORATORIES": {
              "MIRTAZAPINE": "MIRTAZAPINE"
            },
            "PREFERRED PHARMACEUTICALS": {
              "MIRTAZAPINE": "MIRTAZAPINE"
            },
            "QUALITY CARE": {
              "MIRTAZAPINE": "MIRTAZAPINE"
            },
            "QUALITY CARE PRODUCTS": {
              "MIRTAZAPINE": "MIRTAZAPINE"
            },
            "RISING PHARMACEUTICALS": {
              "MIRTAZAPINE": "MIRTAZAPINE"
            },
            "SANDOZ": {
              "MIRTAZAPINE": "MIRTAZAPINE"
            },
            "SKY PACKAGING": {
              "MIRTAZAPINE": "MIRTAZAPINE"
            },
            "SUN PHARMACEUTICALS": {
              "MIRTAZAPINE": "MIRTAZAPINE"
            },
            "TEVA PHARMACEUTICALS USA": {
              "MIRTAZAPINE": "MIRTAZAPINE"
            },
            "UNIT DOSE SERVICES": {
              "MIRTAZAPINE": "MIRTAZAPINE"
            }
          }
        },
        "Antidepressant Combinations": {
          "SSRIs-Nutritional Supplement Combinations": {
            "ALLEGIS PHARMACEUTICALS": {
              "PRAMLYTE": "PRAMLYTE"
            }
          }
        },
        "Antidepressants - Misc.": {
          "Antidepressants - Misc.": {
            "A-S MEDICATION SOLUTIONS": {
              "BUPROPION HCL": "BUPROPION HCL",
              "BUPROPION HCL ER (SR)": "BUPROPION HCL ER (SR)",
              "BUPROPION HCL ER (XL)": "BUPROPION HCL ER (XL)",
              "WELLBUTRIN XL": "WELLBUTRIN XL"
            },
            "ACCORD HEALTHCARE": {
              "BUPROPION HCL ER (XL)": "BUPROPION HCL ER (XL)"
            },
            "ACTAVIS": {
              "BUPROPION HCL ER (XL)": "BUPROPION HCL ER (XL)"
            },
            "ACTAVIS PHARMA": {
              "BUPROPION HCL ER (SR)": "BUPROPION HCL ER (SR)",
              "BUPROPION HCL ER (XL)": "BUPROPION HCL ER (XL)"
            },
            "ACTAVIS SOUTHATLANTIC": {
              "BUPROPION HCL ER (SR)": "BUPROPION HCL ER (SR)"
            },
            "AIDAREX PHARMACEUTICALS": {
              "BUPROPION HCL": "BUPROPION HCL",
              "BUPROPION HCL ER (SR)": "BUPROPION HCL ER (SR)",
              "BUPROPION HCL ER (XL)": "BUPROPION HCL ER (XL)"
            },
            "ALMATICA": {
              "FORFIVO XL": "FORFIVO XL"
            },
            "ALTURA PHARMACEUTICALS": {
              "BUPROPION HCL": "BUPROPION HCL",
              "BUPROPION HCL ER (SR)": "BUPROPION HCL ER (SR)"
            },
            "ALVOGEN": {
              "BUPROPION HCL ER (XL)": "BUPROPION HCL ER (XL)"
            },
            "AMERICAN HEALTH PACKAGING": {
              "BUPROPION HCL": "BUPROPION HCL",
              "BUPROPION HCL ER (SR)": "BUPROPION HCL ER (SR)",
              "BUPROPION HCL ER (XL)": "BUPROPION HCL ER (XL)"
            },
            "AMNEAL PHARMACEUTICALS": {
              "BUPROPION HCL ER (SR)": "BUPROPION HCL ER (SR)",
              "BUPROPION HCL ER (XL)": "BUPROPION HCL ER (XL)"
            },
            "APHENA PHARMA SOLUTIONS": {
              "BUPROPION HCL": "BUPROPION HCL",
              "BUPROPION HCL ER (SR)": "BUPROPION HCL ER (SR)",
              "BUPROPION HCL ER (XL)": "BUPROPION HCL ER (XL)",
              "WELLBUTRIN SR": "WELLBUTRIN SR"
            },
            "APOTEX": {
              "BUPROPION HCL": "BUPROPION HCL"
            },
            "AQ PHARMACEUTICALS": {
              "WELLBUTRIN SR": "WELLBUTRIN SR"
            },
            "AVET PHARMACEUTICALS": {
              "BUPROPION HCL": "BUPROPION HCL"
            },
            "AVKARE": {
              "BUPROPION HCL ER (XL)": "BUPROPION HCL ER (XL)"
            },
            "AVPAK": {
              "BUPROPION HCL": "BUPROPION HCL",
              "BUPROPION HCL ER (XL)": "BUPROPION HCL ER (XL)"
            },
            "BAUSCH HEALTH": {
              "APLENZIN": "APLENZIN",
              "WELLBUTRIN XL": "WELLBUTRIN XL"
            },
            "BIOCON PHARMA": {
              "BUPROPION HCL ER (XL)": "BUPROPION HCL ER (XL)"
            },
            "BLUE POINT LABORATORIES": {
              "BUPROPION HCL": "BUPROPION HCL",
              "BUPROPION HCL ER (XL)": "BUPROPION HCL ER (XL)"
            },
            "BRYANT RANCH PREPACK": {
              "BUPROPION HCL": "BUPROPION HCL",
              "BUPROPION HCL ER (SR)": "BUPROPION HCL ER (SR)",
              "BUPROPION HCL ER (XL)": "BUPROPION HCL ER (XL)",
              "WELLBUTRIN XL": "WELLBUTRIN XL"
            },
            "CADISTA": {
              "BUPROPION HCL ER (SR)": "BUPROPION HCL ER (SR)",
              "BUPROPION HCL ER (XL)": "BUPROPION HCL ER (XL)"
            },
            "CIPLA USA": {
              "BUPROPION HCL": "BUPROPION HCL",
              "BUPROPION HCL ER (SR)": "BUPROPION HCL ER (SR)",
              "BUPROPION HCL ER (XL)": "BUPROPION HCL ER (XL)"
            },
            "DENTON PHARMA": {
              "BUPROPION HCL ER (SR)": "BUPROPION HCL ER (SR)"
            },
            "DIRECT RX": {
              "BUPROPION HCL": "BUPROPION HCL",
              "BUPROPION HCL ER (SR)": "BUPROPION HCL ER (SR)",
              "BUPROPION HCL ER (XL)": "BUPROPION HCL ER (XL)"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "BUPROPION HCL ER (SR)": "BUPROPION HCL ER (SR)",
              "BUPROPION HCL ER (XL)": "BUPROPION HCL ER (XL)"
            },
            "EPIC PHARMA": {
              "BUPROPION HCL ER (XL)": "BUPROPION HCL ER (XL)"
            },
            "EXELAN PHARMACEUTICALS": {
              "BUPROPION HCL ER (XL)": "BUPROPION HCL ER (XL)"
            },
            "GLAXO SMITH KLINE": {
              "WELLBUTRIN": "WELLBUTRIN",
              "WELLBUTRIN SR": "WELLBUTRIN SR"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "BUPROPION HCL": "BUPROPION HCL",
              "BUPROPION HCL ER (SR)": "BUPROPION HCL ER (SR)",
              "BUPROPION HCL ER (XL)": "BUPROPION HCL ER (XL)"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "BUPROPION HCL": "BUPROPION HCL",
              "BUPROPION HCL ER (SR)": "BUPROPION HCL ER (SR)",
              "WELLBUTRIN SR": "WELLBUTRIN SR",
              "WELLBUTRIN XL": "WELLBUTRIN XL"
            },
            "LIBERTY PHARMACEUTICAL": {
              "BUPROPION HCL": "BUPROPION HCL",
              "BUPROPION HCL ER (SR)": "BUPROPION HCL ER (SR)"
            },
            "LUPIN PHARMACEUTICALS": {
              "BUPROPION HCL ER (XL)": "BUPROPION HCL ER (XL)"
            },
            "MAJOR PHARMACEUTICALS": {
              "BUPROPION HCL": "BUPROPION HCL",
              "BUPROPION HCL ER (SR)": "BUPROPION HCL ER (SR)",
              "BUPROPION HCL ER (XL)": "BUPROPION HCL ER (XL)"
            },
            "MYLAN": {
              "BUPROPION HCL": "BUPROPION HCL",
              "BUPROPION HCL ER (SR)": "BUPROPION HCL ER (SR)",
              "BUPROPION HCL ER (XL)": "BUPROPION HCL ER (XL)",
              "MAPROTILINE HCL": "MAPROTILINE HCL"
            },
            "MYLAN INSTITUTIONAL": {
              "BUPROPION HCL": "BUPROPION HCL",
              "BUPROPION HCL ER (SR)": "BUPROPION HCL ER (SR)",
              "BUPROPION HCL ER (XL)": "BUPROPION HCL ER (XL)"
            },
            "NUCARE PHARMACEUTICALS": {
              "BUPROPION HCL": "BUPROPION HCL",
              "BUPROPION HCL ER (SR)": "BUPROPION HCL ER (SR)",
              "BUPROPION HCL ER (XL)": "BUPROPION HCL ER (XL)"
            },
            "PAR PHARMACEUTICAL": {
              "BUPROPION HCL ER (XL)": "BUPROPION HCL ER (XL)"
            },
            "PDRX PHARMACEUTICAL": {
              "BUPROPION HCL": "BUPROPION HCL",
              "BUPROPION HCL ER (SR)": "BUPROPION HCL ER (SR)",
              "BUPROPION HCL ER (XL)": "BUPROPION HCL ER (XL)",
              "WELLBUTRIN SR": "WELLBUTRIN SR",
              "WELLBUTRIN XL": "WELLBUTRIN XL"
            },
            "PDRX PHARMACEUTICALS": {
              "BUPROPION HCL": "BUPROPION HCL",
              "BUPROPION HCL ER (XL)": "BUPROPION HCL ER (XL)"
            },
            "PHARMEDIX": {
              "BUPROPION HCL": "BUPROPION HCL",
              "BUPROPION HCL ER (SR)": "BUPROPION HCL ER (SR)",
              "BUPROPION HCL ER (XL)": "BUPROPION HCL ER (XL)"
            },
            "PREFERRED PHARMACEUTICALS": {
              "BUPROPION HCL": "BUPROPION HCL",
              "BUPROPION HCL ER (SR)": "BUPROPION HCL ER (SR)",
              "BUPROPION HCL ER (XL)": "BUPROPION HCL ER (XL)"
            },
            "PROFICIENT RX": {
              "BUPROPION HCL ER (SR)": "BUPROPION HCL ER (SR)",
              "BUPROPION HCL ER (XL)": "BUPROPION HCL ER (XL)"
            },
            "QUALITY CARE": {
              "BUPROPION HCL": "BUPROPION HCL",
              "BUPROPION HCL ER (SR)": "BUPROPION HCL ER (SR)",
              "BUPROPION HCL ER (XL)": "BUPROPION HCL ER (XL)",
              "WELLBUTRIN SR": "WELLBUTRIN SR",
              "WELLBUTRIN XL": "WELLBUTRIN XL"
            },
            "QUALITY CARE PRODUCTS": {
              "BUPROPION HCL": "BUPROPION HCL",
              "BUPROPION HCL ER (SR)": "BUPROPION HCL ER (SR)",
              "BUPROPION HCL ER (XL)": "BUPROPION HCL ER (XL)"
            },
            "RISING PHARMACEUTICALS": {
              "BUPROPION HCL": "BUPROPION HCL"
            },
            "RXCHANGE CO": {
              "BUPROPION HCL": "BUPROPION HCL",
              "BUPROPION HCL ER (SR)": "BUPROPION HCL ER (SR)",
              "BUPROPION HCL ER (XL)": "BUPROPION HCL ER (XL)"
            },
            "SANDOZ": {
              "BUPROPION HCL": "BUPROPION HCL",
              "BUPROPION HCL ER (SR)": "BUPROPION HCL ER (SR)",
              "BUPROPION HCL ER (XL)": "BUPROPION HCL ER (XL)"
            },
            "SCIEGEN PHARMACEUTICALS": {
              "BUPROPION HCL ER (SR)": "BUPROPION HCL ER (SR)",
              "BUPROPION HCL ER (XL)": "BUPROPION HCL ER (XL)"
            },
            "SKY PACKAGING": {
              "BUPROPION HCL": "BUPROPION HCL",
              "BUPROPION HCL ER (SR)": "BUPROPION HCL ER (SR)",
              "BUPROPION HCL ER (XL)": "BUPROPION HCL ER (XL)"
            },
            "SLATE RUN PHARMACEUTICALS": {
              "BUPROPION HCL ER (SR)": "BUPROPION HCL ER (SR)",
              "BUPROPION HCL ER (XL)": "BUPROPION HCL ER (XL)"
            },
            "SOLCO HEALTHCARE": {
              "BUPROPION HCL ER (SR)": "BUPROPION HCL ER (SR)"
            },
            "ST MARY'S MPP": {
              "BUPROPION HCL": "BUPROPION HCL",
              "BUPROPION HCL ER (SR)": "BUPROPION HCL ER (SR)",
              "BUPROPION HCL ER (XL)": "BUPROPION HCL ER (XL)"
            },
            "SUN PHARMACEUTICAL": {
              "BUPROPION HCL ER (SR)": "BUPROPION HCL ER (SR)"
            },
            "SUN PHARMACEUTICALS": {
              "BUPROPION HCL ER (SR)": "BUPROPION HCL ER (SR)"
            },
            "TORRENT PHARMACEUTICALS": {
              "BUPROPION HCL ER (SR)": "BUPROPION HCL ER (SR)"
            },
            "TWI PHARMACEUTICALS": {
              "BUPROPION HCL ER (XL)": "BUPROPION HCL ER (XL)"
            },
            "UNIT DOSE SERVICES": {
              "BUPROPION HCL": "BUPROPION HCL",
              "BUPROPION HCL ER (SR)": "BUPROPION HCL ER (SR)",
              "BUPROPION HCL ER (XL)": "BUPROPION HCL ER (XL)"
            },
            "VA CMOP DALLAS": {
              "BUPROPION HCL": "BUPROPION HCL"
            },
            "WOCKHARDT USA": {
              "BUPROPION HCL ER (XL)": "BUPROPION HCL ER (XL)"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "BUPROPION HCL ER (XL)": "BUPROPION HCL ER (XL)"
            }
          }
        },
        "GABA Receptor Modulator - Neuroactive Steroid": {
          "GABA Receptor Modulator - Neuroactive Steroid": {
            "SAGE THERAPEUTICS": {
              "ZULRESSO": "ZULRESSO"
            }
          }
        },
        "Monoamine Oxidase Inhibitors (MAOIs)": {
          "Monoamine Oxidase Inhibitors (MAOIs)": {
            "ACTAVIS PHARMA": {
              "TRANYLCYPROMINE SULFATE": "TRANYLCYPROMINE SULFATE"
            },
            "ALVOGEN": {
              "TRANYLCYPROMINE SULFATE": "TRANYLCYPROMINE SULFATE"
            },
            "CONCORDIA PHARMACEUTICALS": {
              "PARNATE": "PARNATE"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "PHENELZINE SULFATE": "PHENELZINE SULFATE",
              "TRANYLCYPROMINE SULFATE": "TRANYLCYPROMINE SULFATE"
            },
            "GREENSTONE": {
              "PHENELZINE SULFATE": "PHENELZINE SULFATE"
            },
            "LUPIN PHARMACEUTICALS": {
              "PHENELZINE SULFATE": "PHENELZINE SULFATE"
            },
            "MYLAN SPECIALTY L.P.": {
              "EMSAM": "EMSAM"
            },
            "PAR PHARMACEUTICAL": {
              "TRANYLCYPROMINE SULFATE": "TRANYLCYPROMINE SULFATE"
            },
            "PFIZER U.S.": {
              "NARDIL": "NARDIL"
            },
            "RISING PHARMACEUTICALS": {
              "TRANYLCYPROMINE SULFATE": "TRANYLCYPROMINE SULFATE"
            },
            "VALIDUS PHARMACEUTICALS": {
              "MARPLAN": "MARPLAN"
            }
          }
        },
        "N-Methyl-D-aspartic acid (NMDA) Receptor Antagonists": {
          "N-Methyl-D-aspartic acid (NMDA) Receptor Antagonists": {
            "JANSSEN": {
              "SPRAVATO (56 MG DOSE)": "SPRAVATO (56 MG DOSE)",
              "SPRAVATO (84 MG DOSE)": "SPRAVATO (84 MG DOSE)"
            }
          }
        },
        "Selective Serotonin Reuptake Inhibitors (SSRIs)": {
          "Selective Serotonin Reuptake Inhibitors (SSRIs)": {
            "A-S MEDICATION SOLUTIONS": {
              "CITALOPRAM HYDROBROMIDE": "CITALOPRAM HYDROBROMIDE",
              "ESCITALOPRAM OXALATE": "ESCITALOPRAM OXALATE",
              "FLUOXETINE HCL": "FLUOXETINE HCL",
              "LEXAPRO": "LEXAPRO",
              "PAROXETINE HCL": "PAROXETINE HCL",
              "PAXIL": "PAXIL",
              "PAXIL CR": "PAXIL CR",
              "SERTRALINE HCL": "SERTRALINE HCL",
              "ZOLOFT": "ZOLOFT"
            },
            "ACCORD HEALTHCARE": {
              "ESCITALOPRAM OXALATE": "ESCITALOPRAM OXALATE",
              "SERTRALINE HCL": "SERTRALINE HCL"
            },
            "ACETRIS HEALTH": {
              "PAROXETINE HCL": "PAROXETINE HCL"
            },
            "ACI HEALTHCARE USA": {
              "SERTRALINE HCL": "SERTRALINE HCL"
            },
            "ACTAVIS": {
              "LEXAPRO": "LEXAPRO"
            },
            "ACTAVIS ELIZABETH": {
              "FLUVOXAMINE MALEATE ER": "FLUVOXAMINE MALEATE ER"
            },
            "ACTAVIS PHARMA": {
              "ESCITALOPRAM OXALATE": "ESCITALOPRAM OXALATE"
            },
            "AIDAREX PHARMACEUTICALS": {
              "CITALOPRAM HYDROBROMIDE": "CITALOPRAM HYDROBROMIDE",
              "ESCITALOPRAM OXALATE": "ESCITALOPRAM OXALATE",
              "FLUOXETINE HCL": "FLUOXETINE HCL",
              "FLUVOXAMINE MALEATE": "FLUVOXAMINE MALEATE",
              "LEXAPRO": "LEXAPRO",
              "PAROXETINE HCL": "PAROXETINE HCL",
              "SERTRALINE HCL": "SERTRALINE HCL"
            },
            "ALEMBIC PHARMACEUTICALS": {
              "FLUOXETINE HCL": "FLUOXETINE HCL"
            },
            "ALLERGAN": {
              "CELEXA": "CELEXA",
              "LEXAPRO": "LEXAPRO"
            },
            "ALMATICA": {
              "FLUOXETINE HCL": "FLUOXETINE HCL"
            },
            "ALTURA PHARMACEUTICALS": {
              "CELEXA": "CELEXA",
              "CITALOPRAM HYDROBROMIDE": "CITALOPRAM HYDROBROMIDE",
              "FLUOXETINE HCL": "FLUOXETINE HCL",
              "PAROXETINE HCL": "PAROXETINE HCL",
              "PAXIL": "PAXIL",
              "ZOLOFT": "ZOLOFT"
            },
            "ALVOGEN": {
              "FLUOXETINE HCL": "FLUOXETINE HCL"
            },
            "AMERICAN HEALTH PACKAGING": {
              "CITALOPRAM HYDROBROMIDE": "CITALOPRAM HYDROBROMIDE",
              "ESCITALOPRAM OXALATE": "ESCITALOPRAM OXALATE",
              "FLUOXETINE HCL": "FLUOXETINE HCL",
              "FLUVOXAMINE MALEATE": "FLUVOXAMINE MALEATE",
              "PAROXETINE HCL": "PAROXETINE HCL",
              "SERTRALINE HCL": "SERTRALINE HCL"
            },
            "AMNEAL PHARMACEUTICALS": {
              "CITALOPRAM HYDROBROMIDE": "CITALOPRAM HYDROBROMIDE",
              "ESCITALOPRAM OXALATE": "ESCITALOPRAM OXALATE"
            },
            "ANI  PHARMACEUTICALS": {
              "FLUVOXAMINE MALEATE": "FLUVOXAMINE MALEATE"
            },
            "APHENA PHARMA SOLUTIONS": {
              "CELEXA": "CELEXA",
              "CITALOPRAM HYDROBROMIDE": "CITALOPRAM HYDROBROMIDE",
              "ESCITALOPRAM OXALATE": "ESCITALOPRAM OXALATE",
              "FLUOXETINE HCL": "FLUOXETINE HCL",
              "PAROXETINE HCL": "PAROXETINE HCL",
              "SERTRALINE HCL": "SERTRALINE HCL",
              "ZOLOFT": "ZOLOFT"
            },
            "APOTEX": {
              "CITALOPRAM HYDROBROMIDE": "CITALOPRAM HYDROBROMIDE",
              "ESCITALOPRAM OXALATE": "ESCITALOPRAM OXALATE",
              "FLUVOXAMINE MALEATE": "FLUVOXAMINE MALEATE",
              "PAROXETINE HCL": "PAROXETINE HCL",
              "PAROXETINE HCL ER": "PAROXETINE HCL ER",
              "PAXIL": "PAXIL",
              "PAXIL CR": "PAXIL CR"
            },
            "APOTHECA": {
              "FLUOXETINE HCL": "FLUOXETINE HCL",
              "SERTRALINE HCL": "SERTRALINE HCL"
            },
            "AQ PHARMACEUTICALS": {
              "LEXAPRO": "LEXAPRO"
            },
            "ATLANTIC BIOLOGICALS": {
              "CITALOPRAM HYDROBROMIDE": "CITALOPRAM HYDROBROMIDE"
            },
            "AUROBINDO PHARMA": {
              "CITALOPRAM HYDROBROMIDE": "CITALOPRAM HYDROBROMIDE",
              "ESCITALOPRAM OXALATE": "ESCITALOPRAM OXALATE",
              "FLUOXETINE HCL": "FLUOXETINE HCL",
              "PAROXETINE HCL": "PAROXETINE HCL",
              "SERTRALINE HCL": "SERTRALINE HCL"
            },
            "AUROBINDO PHARMA USA": {
              "CITALOPRAM HYDROBROMIDE": "CITALOPRAM HYDROBROMIDE",
              "PAROXETINE HCL": "PAROXETINE HCL"
            },
            "AVKARE": {
              "FLUOXETINE HCL": "FLUOXETINE HCL",
              "PAROXETINE HCL ER": "PAROXETINE HCL ER"
            },
            "AVPAK": {
              "FLUOXETINE HCL": "FLUOXETINE HCL",
              "PAROXETINE HCL": "PAROXETINE HCL"
            },
            "BIOGEN PHARMACEUTICALS": {
              "LEXAPRO": "LEXAPRO"
            },
            "BLENHEIM PHARMACAL": {
              "CITALOPRAM HYDROBROMIDE": "CITALOPRAM HYDROBROMIDE",
              "ESCITALOPRAM OXALATE": "ESCITALOPRAM OXALATE",
              "FLUOXETINE HCL": "FLUOXETINE HCL",
              "PAROXETINE HCL": "PAROXETINE HCL"
            },
            "BLU PHARMACEUTICALS": {
              "CITALOPRAM HYDROBROMIDE": "CITALOPRAM HYDROBROMIDE"
            },
            "BLUE POINT LABORATORIES": {
              "ESCITALOPRAM OXALATE": "ESCITALOPRAM OXALATE",
              "FLUOXETINE HCL": "FLUOXETINE HCL"
            },
            "BRYANT RANCH PREPACK": {
              "CITALOPRAM HYDROBROMIDE": "CITALOPRAM HYDROBROMIDE",
              "ESCITALOPRAM OXALATE": "ESCITALOPRAM OXALATE",
              "FLUOXETINE HCL": "FLUOXETINE HCL",
              "FLUVOXAMINE MALEATE": "FLUVOXAMINE MALEATE",
              "LEXAPRO": "LEXAPRO",
              "PAROXETINE HCL": "PAROXETINE HCL",
              "PAROXETINE HCL ER": "PAROXETINE HCL ER",
              "SERTRALINE HCL": "SERTRALINE HCL"
            },
            "CADISTA": {
              "CITALOPRAM HYDROBROMIDE": "CITALOPRAM HYDROBROMIDE",
              "ESCITALOPRAM OXALATE": "ESCITALOPRAM OXALATE",
              "PAROXETINE HCL": "PAROXETINE HCL"
            },
            "CAMBER PHARMACEUTICALS": {
              "CITALOPRAM HYDROBROMIDE": "CITALOPRAM HYDROBROMIDE",
              "ESCITALOPRAM OXALATE": "ESCITALOPRAM OXALATE",
              "FLUOXETINE HCL": "FLUOXETINE HCL"
            },
            "CARDINAL HEALTH REPACKAGING SO": {
              "PAROXETINE HCL": "PAROXETINE HCL",
              "SERTRALINE HCL": "SERTRALINE HCL"
            },
            "CIPLA USA": {
              "CITALOPRAM HYDROBROMIDE": "CITALOPRAM HYDROBROMIDE",
              "ESCITALOPRAM OXALATE": "ESCITALOPRAM OXALATE",
              "SERTRALINE HCL": "SERTRALINE HCL"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "CITALOPRAM HYDROBROMIDE": "CITALOPRAM HYDROBROMIDE",
              "FLUOXETINE HCL": "FLUOXETINE HCL",
              "PAROXETINE HCL": "PAROXETINE HCL",
              "SERTRALINE HCL": "SERTRALINE HCL"
            },
            "COSETTE PHARMACEUTICALS": {
              "CITALOPRAM HYDROBROMIDE": "CITALOPRAM HYDROBROMIDE"
            },
            "CYPRESS PHARMACEUTICAL": {
              "CITALOPRAM HYDROBROMIDE": "CITALOPRAM HYDROBROMIDE"
            },
            "DENTON PHARMA": {
              "CITALOPRAM HYDROBROMIDE": "CITALOPRAM HYDROBROMIDE",
              "ESCITALOPRAM OXALATE": "ESCITALOPRAM OXALATE",
              "FLUOXETINE HCL": "FLUOXETINE HCL",
              "PAROXETINE HCL": "PAROXETINE HCL",
              "SERTRALINE HCL": "SERTRALINE HCL"
            },
            "DIRECT RX": {
              "CITALOPRAM HYDROBROMIDE": "CITALOPRAM HYDROBROMIDE",
              "ESCITALOPRAM OXALATE": "ESCITALOPRAM OXALATE",
              "FLUOXETINE HCL": "FLUOXETINE HCL",
              "PAROXETINE HCL": "PAROXETINE HCL",
              "SERTRALINE HCL": "SERTRALINE HCL"
            },
            "DISTA": {
              "PROZAC": "PROZAC"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "CITALOPRAM HYDROBROMIDE": "CITALOPRAM HYDROBROMIDE",
              "FLUOXETINE HCL": "FLUOXETINE HCL",
              "SERTRALINE HCL": "SERTRALINE HCL"
            },
            "EPIC PHARMA": {
              "CITALOPRAM HYDROBROMIDE": "CITALOPRAM HYDROBROMIDE"
            },
            "EXELAN PHARMACEUTICALS": {
              "CITALOPRAM HYDROBROMIDE": "CITALOPRAM HYDROBROMIDE",
              "ESCITALOPRAM OXALATE": "ESCITALOPRAM OXALATE",
              "SERTRALINE HCL": "SERTRALINE HCL"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "CITALOPRAM HYDROBROMIDE": "CITALOPRAM HYDROBROMIDE",
              "FLUOXETINE HCL": "FLUOXETINE HCL",
              "FLUVOXAMINE MALEATE": "FLUVOXAMINE MALEATE",
              "PAROXETINE HCL": "PAROXETINE HCL"
            },
            "GREENSTONE": {
              "SERTRALINE HCL": "SERTRALINE HCL"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "CELEXA": "CELEXA",
              "CITALOPRAM HYDROBROMIDE": "CITALOPRAM HYDROBROMIDE",
              "FLUOXETINE HCL": "FLUOXETINE HCL",
              "LEXAPRO": "LEXAPRO",
              "PAROXETINE HCL": "PAROXETINE HCL",
              "PAXIL": "PAXIL",
              "PAXIL CR": "PAXIL CR",
              "PROZAC": "PROZAC",
              "PROZAC WEEKLY": "PROZAC WEEKLY",
              "SERTRALINE HCL": "SERTRALINE HCL",
              "ZOLOFT": "ZOLOFT"
            },
            "HIKMA": {
              "CITALOPRAM HYDROBROMIDE": "CITALOPRAM HYDROBROMIDE",
              "ESCITALOPRAM OXALATE": "ESCITALOPRAM OXALATE",
              "SERTRALINE HCL": "SERTRALINE HCL"
            },
            "INTERNATIONAL LABS, LLC": {
              "CITALOPRAM HYDROBROMIDE": "CITALOPRAM HYDROBROMIDE",
              "ESCITALOPRAM OXALATE": "ESCITALOPRAM OXALATE",
              "PAROXETINE HCL": "PAROXETINE HCL"
            },
            "KAISER FOUNDATION HOSPITAL": {
              "SERTRALINE HCL": "SERTRALINE HCL"
            },
            "KREMERS URBAN": {
              "PAROXETINE HCL ER": "PAROXETINE HCL ER"
            },
            "LEGACY PHARMACEUTICAL PKG": {
              "CITALOPRAM HYDROBROMIDE": "CITALOPRAM HYDROBROMIDE",
              "ESCITALOPRAM OXALATE": "ESCITALOPRAM OXALATE",
              "FLUOXETINE HCL": "FLUOXETINE HCL",
              "SERTRALINE HCL": "SERTRALINE HCL"
            },
            "LIBERTY PHARMACEUTICAL": {
              "CITALOPRAM HYDROBROMIDE": "CITALOPRAM HYDROBROMIDE",
              "ESCITALOPRAM OXALATE": "ESCITALOPRAM OXALATE",
              "FLUOXETINE HCL": "FLUOXETINE HCL",
              "PAROXETINE HCL": "PAROXETINE HCL",
              "SERTRALINE HCL": "SERTRALINE HCL"
            },
            "LILLY": {
              "PROZAC WEEKLY": "PROZAC WEEKLY"
            },
            "LUPIN PHARMACEUTICALS": {
              "ESCITALOPRAM OXALATE": "ESCITALOPRAM OXALATE",
              "FLUOXETINE HCL": "FLUOXETINE HCL",
              "PAROXETINE HCL ER": "PAROXETINE HCL ER",
              "SERTRALINE HCL": "SERTRALINE HCL"
            },
            "MACLEODS PHARMACEUTICALS": {
              "ESCITALOPRAM OXALATE": "ESCITALOPRAM OXALATE"
            },
            "MAJOR PHARMACEUTICALS": {
              "CITALOPRAM HYDROBROMIDE": "CITALOPRAM HYDROBROMIDE",
              "ESCITALOPRAM OXALATE": "ESCITALOPRAM OXALATE",
              "FLUOXETINE HCL": "FLUOXETINE HCL",
              "PAROXETINE HCL": "PAROXETINE HCL"
            },
            "MALLINCKRODT PHARM": {
              "PAROXETINE HCL": "PAROXETINE HCL"
            },
            "MARLEX PHARMACEUTICALS": {
              "SERTRALINE HCL": "SERTRALINE HCL"
            },
            "MEDVANTX": {
              "FLUOXETINE HCL": "FLUOXETINE HCL"
            },
            "MYLAN": {
              "CITALOPRAM HYDROBROMIDE": "CITALOPRAM HYDROBROMIDE",
              "ESCITALOPRAM OXALATE": "ESCITALOPRAM OXALATE",
              "FLUOXETINE HCL": "FLUOXETINE HCL",
              "FLUVOXAMINE MALEATE": "FLUVOXAMINE MALEATE",
              "PAROXETINE HCL": "PAROXETINE HCL",
              "PAROXETINE HCL ER": "PAROXETINE HCL ER",
              "SERTRALINE HCL": "SERTRALINE HCL"
            },
            "MYLAN INSTITUTIONAL": {
              "ESCITALOPRAM OXALATE": "ESCITALOPRAM OXALATE",
              "FLUVOXAMINE MALEATE": "FLUVOXAMINE MALEATE",
              "PAROXETINE HCL": "PAROXETINE HCL",
              "SERTRALINE HCL": "SERTRALINE HCL"
            },
            "NEW HORIZON RX GROUP": {
              "CITALOPRAM HYDROBROMIDE": "CITALOPRAM HYDROBROMIDE",
              "PAROXETINE HCL": "PAROXETINE HCL"
            },
            "NIVAGEN PHARMACEUTICALS": {
              "FLUOXETINE HCL": "FLUOXETINE HCL"
            },
            "NORTHSTAR RX": {
              "FLUOXETINE HCL": "FLUOXETINE HCL",
              "SERTRALINE HCL": "SERTRALINE HCL"
            },
            "NORTHWIND PHARMACEUTICALS": {
              "SERTRALINE HCL": "SERTRALINE HCL"
            },
            "NUCARE PHARMACEUTICALS": {
              "CELEXA": "CELEXA",
              "CITALOPRAM HYDROBROMIDE": "CITALOPRAM HYDROBROMIDE",
              "FLUOXETINE HCL": "FLUOXETINE HCL",
              "LEXAPRO": "LEXAPRO",
              "PAROXETINE HCL": "PAROXETINE HCL",
              "SERTRALINE HCL": "SERTRALINE HCL",
              "ZOLOFT": "ZOLOFT"
            },
            "PAR PHARMACEUTICAL": {
              "FLUOXETINE HCL": "FLUOXETINE HCL",
              "FLUVOXAMINE MALEATE ER": "FLUVOXAMINE MALEATE ER"
            },
            "PDRX PHARMACEUTICAL": {
              "CELEXA": "CELEXA",
              "CITALOPRAM HYDROBROMIDE": "CITALOPRAM HYDROBROMIDE",
              "ESCITALOPRAM OXALATE": "ESCITALOPRAM OXALATE",
              "FLUOXETINE HCL": "FLUOXETINE HCL",
              "LEXAPRO": "LEXAPRO",
              "PAROXETINE HCL": "PAROXETINE HCL",
              "PAXIL": "PAXIL",
              "PROZAC": "PROZAC",
              "SERTRALINE HCL": "SERTRALINE HCL",
              "ZOLOFT": "ZOLOFT"
            },
            "PDRX PHARMACEUTICALS": {
              "CITALOPRAM HYDROBROMIDE": "CITALOPRAM HYDROBROMIDE",
              "SERTRALINE HCL": "SERTRALINE HCL"
            },
            "PFIZER U.S.": {
              "ZOLOFT": "ZOLOFT"
            },
            "PHARMACEUTICAL ASSOCIATES": {
              "CITALOPRAM HYDROBROMIDE": "CITALOPRAM HYDROBROMIDE",
              "ESCITALOPRAM OXALATE": "ESCITALOPRAM OXALATE",
              "FLUOXETINE HCL": "FLUOXETINE HCL"
            },
            "PHARMEDIX": {
              "CITALOPRAM HYDROBROMIDE": "CITALOPRAM HYDROBROMIDE",
              "FLUOXETINE HCL": "FLUOXETINE HCL",
              "LEXAPRO": "LEXAPRO",
              "SERTRALINE HCL": "SERTRALINE HCL"
            },
            "PREFERRED PHARMACEUTICALS": {
              "CITALOPRAM HYDROBROMIDE": "CITALOPRAM HYDROBROMIDE",
              "ESCITALOPRAM OXALATE": "ESCITALOPRAM OXALATE",
              "FLUOXETINE HCL": "FLUOXETINE HCL",
              "FLUVOXAMINE MALEATE": "FLUVOXAMINE MALEATE",
              "PAROXETINE HCL": "PAROXETINE HCL",
              "SERTRALINE HCL": "SERTRALINE HCL"
            },
            "PROFICIENT RX": {
              "CITALOPRAM HYDROBROMIDE": "CITALOPRAM HYDROBROMIDE",
              "ESCITALOPRAM OXALATE": "ESCITALOPRAM OXALATE",
              "FLUOXETINE HCL": "FLUOXETINE HCL",
              "PAROXETINE HCL": "PAROXETINE HCL",
              "SERTRALINE HCL": "SERTRALINE HCL"
            },
            "QUALITY CARE": {
              "CITALOPRAM HYDROBROMIDE": "CITALOPRAM HYDROBROMIDE",
              "ESCITALOPRAM OXALATE": "ESCITALOPRAM OXALATE",
              "FLUOXETINE HCL": "FLUOXETINE HCL",
              "FLUVOXAMINE MALEATE": "FLUVOXAMINE MALEATE",
              "LEXAPRO": "LEXAPRO",
              "PAROXETINE HCL": "PAROXETINE HCL",
              "PAXIL": "PAXIL",
              "PAXIL CR": "PAXIL CR",
              "SERTRALINE HCL": "SERTRALINE HCL",
              "ZOLOFT": "ZOLOFT"
            },
            "QUALITY CARE PRODUCTS": {
              "CITALOPRAM HYDROBROMIDE": "CITALOPRAM HYDROBROMIDE",
              "ESCITALOPRAM OXALATE": "ESCITALOPRAM OXALATE",
              "FLUOXETINE HCL": "FLUOXETINE HCL",
              "PAROXETINE HCL": "PAROXETINE HCL",
              "SERTRALINE HCL": "SERTRALINE HCL"
            },
            "READYMEDS": {
              "CITALOPRAM HYDROBROMIDE": "CITALOPRAM HYDROBROMIDE",
              "ESCITALOPRAM OXALATE": "ESCITALOPRAM OXALATE",
              "FLUOXETINE HCL": "FLUOXETINE HCL"
            },
            "RHODES PHARMACEUTICAL": {
              "PAROXETINE HCL ER": "PAROXETINE HCL ER"
            },
            "RISING PHARMACEUTICALS": {
              "SERTRALINE HCL": "SERTRALINE HCL"
            },
            "RXCHANGE CO": {
              "CITALOPRAM HYDROBROMIDE": "CITALOPRAM HYDROBROMIDE",
              "FLUOXETINE HCL": "FLUOXETINE HCL",
              "LEXAPRO": "LEXAPRO",
              "PAROXETINE HCL": "PAROXETINE HCL",
              "SERTRALINE HCL": "SERTRALINE HCL"
            },
            "SANDOZ": {
              "CITALOPRAM HYDROBROMIDE": "CITALOPRAM HYDROBROMIDE",
              "FLUOXETINE HCL": "FLUOXETINE HCL",
              "FLUVOXAMINE MALEATE": "FLUVOXAMINE MALEATE"
            },
            "SCIEGEN PHARMACEUTICALS": {
              "FLUOXETINE HCL": "FLUOXETINE HCL"
            },
            "SEBELA PHARMACEUTICALS": {
              "PEXEVA": "PEXEVA"
            },
            "SILARX": {
              "CITALOPRAM HYDROBROMIDE": "CITALOPRAM HYDROBROMIDE",
              "ESCITALOPRAM OXALATE": "ESCITALOPRAM OXALATE",
              "FLUOXETINE HCL": "FLUOXETINE HCL"
            },
            "SKY PACKAGING": {
              "PAROXETINE HCL": "PAROXETINE HCL"
            },
            "SOLCO HEALTHCARE": {
              "ESCITALOPRAM OXALATE": "ESCITALOPRAM OXALATE",
              "PAROXETINE HCL": "PAROXETINE HCL"
            },
            "ST MARY'S MPP": {
              "CITALOPRAM HYDROBROMIDE": "CITALOPRAM HYDROBROMIDE",
              "ESCITALOPRAM OXALATE": "ESCITALOPRAM OXALATE",
              "FLUOXETINE HCL": "FLUOXETINE HCL",
              "PAROXETINE HCL": "PAROXETINE HCL",
              "SERTRALINE HCL": "SERTRALINE HCL"
            },
            "STI PHARMA": {
              "ESCITALOPRAM OXALATE": "ESCITALOPRAM OXALATE"
            },
            "SUN PHARMACEUTICALS": {
              "CITALOPRAM HYDROBROMIDE": "CITALOPRAM HYDROBROMIDE",
              "FLUOXETINE HCL": "FLUOXETINE HCL"
            },
            "TARO": {
              "ESCITALOPRAM OXALATE": "ESCITALOPRAM OXALATE",
              "FLUOXETINE HCL": "FLUOXETINE HCL"
            },
            "TEVA PHARMACEUTICALS USA": {
              "CITALOPRAM HYDROBROMIDE": "CITALOPRAM HYDROBROMIDE",
              "ESCITALOPRAM OXALATE": "ESCITALOPRAM OXALATE",
              "FLUOXETINE HCL": "FLUOXETINE HCL",
              "PAROXETINE HCL": "PAROXETINE HCL",
              "SERTRALINE HCL": "SERTRALINE HCL"
            },
            "TORRENT PHARMACEUTICALS": {
              "CITALOPRAM HYDROBROMIDE": "CITALOPRAM HYDROBROMIDE",
              "ESCITALOPRAM OXALATE": "ESCITALOPRAM OXALATE",
              "SERTRALINE HCL": "SERTRALINE HCL"
            },
            "TRUPHARMA": {
              "PAROXETINE HCL": "PAROXETINE HCL"
            },
            "UNIT DOSE SERVICES": {
              "CITALOPRAM HYDROBROMIDE": "CITALOPRAM HYDROBROMIDE",
              "ESCITALOPRAM OXALATE": "ESCITALOPRAM OXALATE",
              "FLUOXETINE HCL": "FLUOXETINE HCL",
              "LEXAPRO": "LEXAPRO",
              "PAROXETINE HCL": "PAROXETINE HCL",
              "SERTRALINE HCL": "SERTRALINE HCL"
            },
            "UPSHER-SMITH": {
              "FLUOXETINE HCL": "FLUOXETINE HCL",
              "FLUVOXAMINE MALEATE": "FLUVOXAMINE MALEATE"
            },
            "VA CMOP DALLAS": {
              "CITALOPRAM HYDROBROMIDE": "CITALOPRAM HYDROBROMIDE",
              "FLUOXETINE HCL": "FLUOXETINE HCL",
              "PAROXETINE HCL": "PAROXETINE HCL",
              "SERTRALINE HCL": "SERTRALINE HCL"
            },
            "VENSUN PHARMACEUTICALS": {
              "FLUOXETINE HCL": "FLUOXETINE HCL"
            },
            "WESTMINSTER PHARMACEUTICALS": {
              "FLUOXETINE HCL": "FLUOXETINE HCL"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "PAROXETINE HCL": "PAROXETINE HCL"
            }
          }
        },
        "Serotonin Modulators": {
          "Serotonin Modulators": {
            "A-S MEDICATION SOLUTIONS": {
              "TRAZODONE HCL": "TRAZODONE HCL"
            },
            "ACCORD HEALTHCARE": {
              "TRAZODONE HCL": "TRAZODONE HCL"
            },
            "AIDAREX PHARMACEUTICALS": {
              "TRAZODONE HCL": "TRAZODONE HCL"
            },
            "ALLERGAN": {
              "VIIBRYD": "VIIBRYD",
              "VIIBRYD STARTER PACK": "VIIBRYD STARTER PACK"
            },
            "ALTURA PHARMACEUTICALS": {
              "NEFAZODONE HCL": "NEFAZODONE HCL",
              "TRAZODONE HCL": "TRAZODONE HCL"
            },
            "AMERICAN HEALTH PACKAGING": {
              "TRAZODONE HCL": "TRAZODONE HCL"
            },
            "APHENA PHARMA SOLUTIONS": {
              "TRAZODONE HCL": "TRAZODONE HCL"
            },
            "APOTEX": {
              "TRAZODONE HCL": "TRAZODONE HCL"
            },
            "APOTHECA": {
              "TRAZODONE HCL": "TRAZODONE HCL"
            },
            "AQ PHARMACEUTICALS": {
              "NEFAZODONE HCL": "NEFAZODONE HCL"
            },
            "AVKARE": {
              "NEFAZODONE HCL": "NEFAZODONE HCL",
              "TRAZODONE HCL": "TRAZODONE HCL"
            },
            "AVPAK": {
              "TRAZODONE HCL": "TRAZODONE HCL"
            },
            "BLENHEIM PHARMACAL": {
              "TRAZODONE HCL": "TRAZODONE HCL"
            },
            "BRYANT RANCH PREPACK": {
              "TRAZODONE HCL": "TRAZODONE HCL"
            },
            "CARDINAL HEALTH REPACKAGING SO": {
              "TRAZODONE HCL": "TRAZODONE HCL"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "TRAZODONE HCL": "TRAZODONE HCL"
            },
            "DENTON PHARMA": {
              "TRAZODONE HCL": "TRAZODONE HCL"
            },
            "DIRECT RX": {
              "TRAZODONE HCL": "TRAZODONE HCL"
            },
            "FAGRON": {
              "TRAZODONE HCL": "TRAZODONE HCL"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "TRAZODONE HCL": "TRAZODONE HCL"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "TRAZODONE HCL": "TRAZODONE HCL"
            },
            "LEGACY PHARMACEUTICAL PKG": {
              "TRAZODONE HCL": "TRAZODONE HCL"
            },
            "LIBERTY PHARMACEUTICAL": {
              "NEFAZODONE HCL": "NEFAZODONE HCL",
              "TRAZODONE HCL": "TRAZODONE HCL"
            },
            "MAJOR PHARMACEUTICALS": {
              "TRAZODONE HCL": "TRAZODONE HCL"
            },
            "MEDISCA": {
              "TRAZODONE HCL": "TRAZODONE HCL"
            },
            "MEDVANTX": {
              "TRAZODONE HCL": "TRAZODONE HCL"
            },
            "NUCARE PHARMACEUTICALS": {
              "TRAZODONE HCL": "TRAZODONE HCL"
            },
            "PAR PHARMACEUTICALS": {
              "TRAZODONE HCL": "TRAZODONE HCL"
            },
            "PCCA": {
              "TRAZODONE HCL": "TRAZODONE HCL"
            },
            "PDRX PHARMACEUTICAL": {
              "TRAZODONE HCL": "TRAZODONE HCL"
            },
            "PDRX PHARMACEUTICALS": {
              "TRAZODONE HCL": "TRAZODONE HCL"
            },
            "PHARMEDIX": {
              "TRAZODONE HCL": "TRAZODONE HCL"
            },
            "PREFERRED PHARMACEUTICALS": {
              "NEFAZODONE HCL": "NEFAZODONE HCL",
              "TRAZODONE HCL": "TRAZODONE HCL"
            },
            "PROFICIENT RX": {
              "TRAZODONE HCL": "TRAZODONE HCL"
            },
            "QUALITY CARE": {
              "TRAZODONE HCL": "TRAZODONE HCL"
            },
            "QUALITY CARE PRODUCTS": {
              "TRAZODONE HCL": "TRAZODONE HCL"
            },
            "RXCHANGE CO": {
              "TRAZODONE HCL": "TRAZODONE HCL"
            },
            "RXPAK MCKESSON": {
              "TRAZODONE HCL": "TRAZODONE HCL"
            },
            "SPECTRUM": {
              "TRAZODONE HCL": "TRAZODONE HCL"
            },
            "ST MARY'S MPP": {
              "TRAZODONE HCL": "TRAZODONE HCL"
            },
            "SUN PHARMACEUTICALS": {
              "TRAZODONE HCL": "TRAZODONE HCL"
            },
            "TAKEDA PHARMACEUTICALS": {
              "BRINTELLIX": "BRINTELLIX",
              "TRINTELLIX": "TRINTELLIX"
            },
            "TEVA PHARMACEUTICALS USA": {
              "NEFAZODONE HCL": "NEFAZODONE HCL",
              "TRAZODONE HCL": "TRAZODONE HCL"
            },
            "TORRENT PHARMACEUTICALS": {
              "TRAZODONE HCL": "TRAZODONE HCL"
            },
            "UNIT DOSE SERVICES": {
              "TRAZODONE HCL": "TRAZODONE HCL"
            },
            "VA CMOP DALLAS": {
              "TRAZODONE HCL": "TRAZODONE HCL"
            },
            "VANGARD": {
              "TRAZODONE HCL": "TRAZODONE HCL"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "TRAZODONE HCL": "TRAZODONE HCL"
            }
          }
        },
        "Serotonin-Norepinephrine Reuptake Inhibitors (SNRIs)": {
          "Serotonin-Norepinephrine Reuptake Inhibitors (SNRIs)": {
            "A-S MEDICATION SOLUTIONS": {
              "CYMBALTA": "CYMBALTA",
              "DULOXETINE HCL": "DULOXETINE HCL",
              "EFFEXOR XR": "EFFEXOR XR",
              "VENLAFAXINE HCL": "VENLAFAXINE HCL",
              "VENLAFAXINE HCL ER": "VENLAFAXINE HCL ER"
            },
            "ACETRIS HEALTH": {
              "VENLAFAXINE HCL ER": "VENLAFAXINE HCL ER"
            },
            "ACTAVIS ELIZABETH": {
              "DULOXETINE HCL": "DULOXETINE HCL"
            },
            "ACTAVIS PHARMA": {
              "DESVENLAFAXINE SUCCINATE ER": "DESVENLAFAXINE SUCCINATE ER"
            },
            "AIDAREX PHARMACEUTICALS": {
              "CYMBALTA": "CYMBALTA",
              "DULOXETINE HCL": "DULOXETINE HCL",
              "EFFEXOR XR": "EFFEXOR XR",
              "VENLAFAXINE HCL": "VENLAFAXINE HCL",
              "VENLAFAXINE HCL ER": "VENLAFAXINE HCL ER"
            },
            "AJANTA PHARMA LIMITED": {
              "DULOXETINE HCL": "DULOXETINE HCL"
            },
            "ALEMBIC PHARMACEUTICALS": {
              "VENLAFAXINE HCL": "VENLAFAXINE HCL"
            },
            "ALLERGAN": {
              "FETZIMA": "FETZIMA",
              "FETZIMA TITRATION": "FETZIMA TITRATION"
            },
            "ALTURA PHARMACEUTICALS": {
              "CYMBALTA": "CYMBALTA"
            },
            "AMERICAN HEALTH PACKAGING": {
              "DULOXETINE HCL": "DULOXETINE HCL",
              "VENLAFAXINE HCL": "VENLAFAXINE HCL",
              "VENLAFAXINE HCL ER": "VENLAFAXINE HCL ER"
            },
            "AMNEAL PHARMACEUTICALS": {
              "VENLAFAXINE HCL": "VENLAFAXINE HCL"
            },
            "APHENA PHARMA SOLUTIONS": {
              "CYMBALTA": "CYMBALTA",
              "DULOXETINE HCL": "DULOXETINE HCL",
              "EFFEXOR XR": "EFFEXOR XR",
              "VENLAFAXINE HCL": "VENLAFAXINE HCL",
              "VENLAFAXINE HCL ER": "VENLAFAXINE HCL ER"
            },
            "APOTEX": {
              "DULOXETINE HCL": "DULOXETINE HCL",
              "VENLAFAXINE HCL ER": "VENLAFAXINE HCL ER"
            },
            "AQ PHARMACEUTICALS": {
              "EFFEXOR XR": "EFFEXOR XR"
            },
            "ASCEND LABORATORIES": {
              "DULOXETINE HCL": "DULOXETINE HCL"
            },
            "AUROBINDO PHARMA": {
              "DULOXETINE HCL": "DULOXETINE HCL",
              "VENLAFAXINE HCL": "VENLAFAXINE HCL",
              "VENLAFAXINE HCL ER": "VENLAFAXINE HCL ER"
            },
            "AVET PHARMACEUTICALS": {
              "DULOXETINE HCL": "DULOXETINE HCL",
              "VENLAFAXINE HCL": "VENLAFAXINE HCL"
            },
            "AVKARE": {
              "DULOXETINE HCL": "DULOXETINE HCL",
              "VENLAFAXINE HCL": "VENLAFAXINE HCL",
              "VENLAFAXINE HCL ER": "VENLAFAXINE HCL ER"
            },
            "AVPAK": {
              "DULOXETINE HCL": "DULOXETINE HCL",
              "VENLAFAXINE HCL": "VENLAFAXINE HCL"
            },
            "BIOGEN PHARMACEUTICALS": {
              "CYMBALTA": "CYMBALTA"
            },
            "BLUE POINT LABORATORIES": {
              "DULOXETINE HCL": "DULOXETINE HCL",
              "VENLAFAXINE HCL": "VENLAFAXINE HCL"
            },
            "BRECKENRIDGE": {
              "DESVENLAFAXINE SUCCINATE ER": "DESVENLAFAXINE SUCCINATE ER",
              "DULOXETINE HCL": "DULOXETINE HCL"
            },
            "BRYANT RANCH PREPACK": {
              "CYMBALTA": "CYMBALTA",
              "DULOXETINE HCL": "DULOXETINE HCL",
              "EFFEXOR XR": "EFFEXOR XR",
              "VENLAFAXINE HCL": "VENLAFAXINE HCL",
              "VENLAFAXINE HCL ER": "VENLAFAXINE HCL ER"
            },
            "CAMBER PHARMACEUTICALS": {
              "DULOXETINE HCL": "DULOXETINE HCL",
              "VENLAFAXINE HCL ER": "VENLAFAXINE HCL ER"
            },
            "CIPLA USA": {
              "DULOXETINE HCL": "DULOXETINE HCL"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "CYMBALTA": "CYMBALTA",
              "VENLAFAXINE HCL": "VENLAFAXINE HCL",
              "VENLAFAXINE HCL ER": "VENLAFAXINE HCL ER"
            },
            "CURRAX PHARMACEUTICALS": {
              "KHEDEZLA": "KHEDEZLA"
            },
            "DIRECT RX": {
              "DESVENLAFAXINE SUCCINATE ER": "DESVENLAFAXINE SUCCINATE ER",
              "DULOXETINE HCL": "DULOXETINE HCL",
              "VENLAFAXINE HCL": "VENLAFAXINE HCL",
              "VENLAFAXINE HCL ER": "VENLAFAXINE HCL ER"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "DULOXETINE HCL": "DULOXETINE HCL",
              "VENLAFAXINE HCL": "VENLAFAXINE HCL"
            },
            "FLORIDA PHARMACEUTICAL PRODUCT": {
              "VENLAFAXINE HCL ER": "VENLAFAXINE HCL ER"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "DESVENLAFAXINE SUCCINATE ER": "DESVENLAFAXINE SUCCINATE ER",
              "DULOXETINE HCL": "DULOXETINE HCL",
              "VENLAFAXINE HCL ER": "VENLAFAXINE HCL ER"
            },
            "GREENSTONE": {
              "DESVENLAFAXINE SUCCINATE ER": "DESVENLAFAXINE SUCCINATE ER",
              "VENLAFAXINE HCL ER": "VENLAFAXINE HCL ER"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "CYMBALTA": "CYMBALTA",
              "EFFEXOR XR": "EFFEXOR XR",
              "PRISTIQ": "PRISTIQ",
              "VENLAFAXINE HCL": "VENLAFAXINE HCL"
            },
            "HIKMA": {
              "DESVENLAFAXINE SUCCINATE ER": "DESVENLAFAXINE SUCCINATE ER"
            },
            "INNOVIDA PHARMACEUTIQUE": {
              "DULOXETINE HCL": "DULOXETINE HCL"
            },
            "LANNETT": {
              "VENLAFAXINE HCL ER": "VENLAFAXINE HCL ER"
            },
            "LIBERTY PHARMACEUTICAL": {
              "DULOXETINE HCL": "DULOXETINE HCL"
            },
            "LILLY": {
              "CYMBALTA": "CYMBALTA"
            },
            "LUPIN PHARMACEUTICALS": {
              "DESVENLAFAXINE SUCCINATE ER": "DESVENLAFAXINE SUCCINATE ER",
              "DULOXETINE HCL": "DULOXETINE HCL"
            },
            "MACLEODS PHARMACEUTICALS": {
              "DULOXETINE HCL": "DULOXETINE HCL",
              "VENLAFAXINE HCL ER": "VENLAFAXINE HCL ER"
            },
            "MACOVEN PHARMACEUTICALS": {
              "DESVENLAFAXINE ER": "DESVENLAFAXINE ER"
            },
            "MAJOR PHARMACEUTICALS": {
              "DULOXETINE HCL": "DULOXETINE HCL",
              "VENLAFAXINE HCL ER": "VENLAFAXINE HCL ER"
            },
            "MYLAN": {
              "DESVENLAFAXINE SUCCINATE ER": "DESVENLAFAXINE SUCCINATE ER",
              "VENLAFAXINE HCL": "VENLAFAXINE HCL"
            },
            "MYLAN INSTITUTIONAL": {
              "DULOXETINE HCL": "DULOXETINE HCL",
              "VENLAFAXINE HCL": "VENLAFAXINE HCL"
            },
            "NIVAGEN PHARMACEUTICALS": {
              "VENLAFAXINE HCL ER": "VENLAFAXINE HCL ER"
            },
            "NORTHSTAR RX": {
              "VENLAFAXINE HCL": "VENLAFAXINE HCL",
              "VENLAFAXINE HCL ER": "VENLAFAXINE HCL ER"
            },
            "NOSTRUM LABORATORIES": {
              "VENLAFAXINE HCL ER": "VENLAFAXINE HCL ER"
            },
            "NUCARE PHARMACEUTICALS": {
              "CYMBALTA": "CYMBALTA",
              "EFFEXOR XR": "EFFEXOR XR",
              "VENLAFAXINE HCL": "VENLAFAXINE HCL"
            },
            "PAR PHARMACEUTICALS": {
              "VENLAFAXINE HCL": "VENLAFAXINE HCL"
            },
            "PDRX PHARMACEUTICAL": {
              "CYMBALTA": "CYMBALTA",
              "DULOXETINE HCL": "DULOXETINE HCL",
              "EFFEXOR XR": "EFFEXOR XR",
              "PRISTIQ": "PRISTIQ",
              "VENLAFAXINE HCL": "VENLAFAXINE HCL"
            },
            "PFIZER U.S.": {
              "EFFEXOR XR": "EFFEXOR XR",
              "PRISTIQ": "PRISTIQ"
            },
            "PHARMEDIX": {
              "VENLAFAXINE HCL": "VENLAFAXINE HCL",
              "VENLAFAXINE HCL ER": "VENLAFAXINE HCL ER"
            },
            "PRASCO LABORATORIES": {
              "DULOXETINE HCL": "DULOXETINE HCL"
            },
            "PREFERRED PHARMACEUTICALS": {
              "DESVENLAFAXINE SUCCINATE ER": "DESVENLAFAXINE SUCCINATE ER",
              "DULOXETINE HCL": "DULOXETINE HCL",
              "VENLAFAXINE HCL": "VENLAFAXINE HCL",
              "VENLAFAXINE HCL ER": "VENLAFAXINE HCL ER"
            },
            "PROFICIENT RX": {
              "DULOXETINE HCL": "DULOXETINE HCL",
              "VENLAFAXINE HCL": "VENLAFAXINE HCL",
              "VENLAFAXINE HCL ER": "VENLAFAXINE HCL ER"
            },
            "QUALITY CARE": {
              "CYMBALTA": "CYMBALTA",
              "EFFEXOR XR": "EFFEXOR XR",
              "PRISTIQ": "PRISTIQ",
              "VENLAFAXINE HCL": "VENLAFAXINE HCL",
              "VENLAFAXINE HCL ER": "VENLAFAXINE HCL ER"
            },
            "QUALITY CARE PRODUCTS": {
              "DESVENLAFAXINE SUCCINATE ER": "DESVENLAFAXINE SUCCINATE ER",
              "DULOXETINE HCL": "DULOXETINE HCL",
              "VENLAFAXINE HCL": "VENLAFAXINE HCL",
              "VENLAFAXINE HCL ER": "VENLAFAXINE HCL ER"
            },
            "RISING PHARMACEUTICALS": {
              "DULOXETINE HCL": "DULOXETINE HCL",
              "VENLAFAXINE HCL": "VENLAFAXINE HCL"
            },
            "RXCHANGE CO": {
              "CYMBALTA": "CYMBALTA",
              "EFFEXOR XR": "EFFEXOR XR",
              "VENLAFAXINE HCL ER": "VENLAFAXINE HCL ER"
            },
            "SKY PACKAGING": {
              "VENLAFAXINE HCL ER": "VENLAFAXINE HCL ER"
            },
            "SLATE RUN PHARMACEUTICALS": {
              "DESVENLAFAXINE SUCCINATE ER": "DESVENLAFAXINE SUCCINATE ER"
            },
            "SOLCO HEALTHCARE": {
              "DULOXETINE HCL": "DULOXETINE HCL"
            },
            "ST MARY'S MPP": {
              "CYMBALTA": "CYMBALTA",
              "DULOXETINE HCL": "DULOXETINE HCL",
              "VENLAFAXINE HCL": "VENLAFAXINE HCL"
            },
            "SUN PHARMACEUTICAL": {
              "DESVENLAFAXINE FUMARATE ER": "DESVENLAFAXINE FUMARATE ER",
              "VENLAFAXINE HCL ER": "VENLAFAXINE HCL ER"
            },
            "SUN PHARMACEUTICALS": {
              "DESVENLAFAXINE ER": "DESVENLAFAXINE ER",
              "DRIZALMA SPRINKLE": "DRIZALMA SPRINKLE",
              "DULOXETINE HCL": "DULOXETINE HCL",
              "VENLAFAXINE HCL": "VENLAFAXINE HCL",
              "VENLAFAXINE HCL ER": "VENLAFAXINE HCL ER"
            },
            "TEVA PHARMACEUTICALS USA": {
              "DULOXETINE HCL": "DULOXETINE HCL",
              "VENLAFAXINE HCL": "VENLAFAXINE HCL",
              "VENLAFAXINE HCL ER": "VENLAFAXINE HCL ER"
            },
            "TORRENT PHARMACEUTICALS": {
              "DULOXETINE HCL": "DULOXETINE HCL",
              "VENLAFAXINE HCL ER": "VENLAFAXINE HCL ER"
            },
            "TRIGEN LABORATORIES": {
              "DULOXETINE HCL": "DULOXETINE HCL",
              "VENLAFAXINE HCL": "VENLAFAXINE HCL",
              "VENLAFAXINE HCL ER": "VENLAFAXINE HCL ER"
            },
            "TRUPHARMA": {
              "VENLAFAXINE HCL": "VENLAFAXINE HCL"
            },
            "UCB PHARMA": {
              "VENLAFAXINE HCL ER": "VENLAFAXINE HCL ER"
            },
            "UNIT DOSE SERVICES": {
              "CYMBALTA": "CYMBALTA",
              "DULOXETINE HCL": "DULOXETINE HCL",
              "VENLAFAXINE HCL ER": "VENLAFAXINE HCL ER"
            },
            "VERTICAL PHARMACEUTICAL": {
              "VENLAFAXINE HCL ER": "VENLAFAXINE HCL ER"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "DULOXETINE HCL": "DULOXETINE HCL",
              "VENLAFAXINE HCL": "VENLAFAXINE HCL",
              "VENLAFAXINE HCL ER": "VENLAFAXINE HCL ER"
            }
          }
        },
        "Tricyclic Agents": {
          "Tricyclic Agents": {
            "A-S MEDICATION SOLUTIONS": {
              "AMITRIPTYLINE HCL": "AMITRIPTYLINE HCL",
              "DOXEPIN HCL": "DOXEPIN HCL",
              "IMIPRAMINE HCL": "IMIPRAMINE HCL",
              "NORTRIPTYLINE HCL": "NORTRIPTYLINE HCL"
            },
            "ACCORD HEALTHCARE": {
              "AMITRIPTYLINE HCL": "AMITRIPTYLINE HCL"
            },
            "ACTAVIS": {
              "DESIPRAMINE HCL": "DESIPRAMINE HCL",
              "TRIMIPRAMINE MALEATE": "TRIMIPRAMINE MALEATE"
            },
            "ACTAVIS PHARMA": {
              "AMOXAPINE": "AMOXAPINE",
              "NORTRIPTYLINE HCL": "NORTRIPTYLINE HCL"
            },
            "AIDAREX PHARMACEUTICALS": {
              "AMITRIPTYLINE HCL": "AMITRIPTYLINE HCL",
              "DESIPRAMINE HCL": "DESIPRAMINE HCL",
              "DOXEPIN HCL": "DOXEPIN HCL",
              "IMIPRAMINE HCL": "IMIPRAMINE HCL",
              "NORTRIPTYLINE HCL": "NORTRIPTYLINE HCL",
              "PROTRIPTYLINE HCL": "PROTRIPTYLINE HCL"
            },
            "AJANTA PHARMA LIMITED": {
              "DOXEPIN HCL": "DOXEPIN HCL"
            },
            "ALTURA PHARMACEUTICALS": {
              "AMITRIPTYLINE HCL": "AMITRIPTYLINE HCL",
              "DESIPRAMINE HCL": "DESIPRAMINE HCL",
              "DOXEPIN HCL": "DOXEPIN HCL",
              "NORTRIPTYLINE HCL": "NORTRIPTYLINE HCL"
            },
            "ALVIX LABORATORIES": {
              "IMIPRAMINE HCL": "IMIPRAMINE HCL"
            },
            "AMERICAN HEALTH PACKAGING": {
              "AMITRIPTYLINE HCL": "AMITRIPTYLINE HCL",
              "CLOMIPRAMINE HCL": "CLOMIPRAMINE HCL",
              "DESIPRAMINE HCL": "DESIPRAMINE HCL",
              "NORTRIPTYLINE HCL": "NORTRIPTYLINE HCL"
            },
            "AMNEAL PHARMACEUTICALS": {
              "CLOMIPRAMINE HCL": "CLOMIPRAMINE HCL",
              "DESIPRAMINE HCL": "DESIPRAMINE HCL",
              "DOXEPIN HCL": "DOXEPIN HCL"
            },
            "ANI  PHARMACEUTICALS": {
              "DESIPRAMINE HCL": "DESIPRAMINE HCL"
            },
            "APHENA PHARMA SOLUTIONS": {
              "AMITRIPTYLINE HCL": "AMITRIPTYLINE HCL",
              "DOXEPIN HCL": "DOXEPIN HCL",
              "NORTRIPTYLINE HCL": "NORTRIPTYLINE HCL"
            },
            "APOTHECA": {
              "AMITRIPTYLINE HCL": "AMITRIPTYLINE HCL"
            },
            "APOTHECA SUPPLY": {
              "NORTRIPTYLINE HCL": "NORTRIPTYLINE HCL"
            },
            "AUROBINDO PHARMA": {
              "DOXEPIN HCL": "DOXEPIN HCL"
            },
            "AVET PHARMACEUTICALS": {
              "DESIPRAMINE HCL": "DESIPRAMINE HCL"
            },
            "AVKARE": {
              "DOXEPIN HCL": "DOXEPIN HCL",
              "IMIPRAMINE HCL": "IMIPRAMINE HCL"
            },
            "AVPAK": {
              "NORTRIPTYLINE HCL": "NORTRIPTYLINE HCL"
            },
            "BLENHEIM PHARMACAL": {
              "AMITRIPTYLINE HCL": "AMITRIPTYLINE HCL",
              "NORTRIPTYLINE HCL": "NORTRIPTYLINE HCL"
            },
            "BRECKENRIDGE": {
              "TRIMIPRAMINE MALEATE": "TRIMIPRAMINE MALEATE"
            },
            "BRYANT RANCH PREPACK": {
              "AMITRIPTYLINE HCL": "AMITRIPTYLINE HCL",
              "CLOMIPRAMINE HCL": "CLOMIPRAMINE HCL",
              "DESIPRAMINE HCL": "DESIPRAMINE HCL",
              "DOXEPIN HCL": "DOXEPIN HCL",
              "IMIPRAMINE HCL": "IMIPRAMINE HCL",
              "NORTRIPTYLINE HCL": "NORTRIPTYLINE HCL"
            },
            "CADISTA": {
              "CLOMIPRAMINE HCL": "CLOMIPRAMINE HCL"
            },
            "CARDINAL HEALTH REPACKAGING SO": {
              "AMITRIPTYLINE HCL": "AMITRIPTYLINE HCL"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "AMITRIPTYLINE HCL": "AMITRIPTYLINE HCL",
              "NORTRIPTYLINE HCL": "NORTRIPTYLINE HCL"
            },
            "DENTON PHARMA": {
              "AMITRIPTYLINE HCL": "AMITRIPTYLINE HCL"
            },
            "DIRECT RX": {
              "AMITRIPTYLINE HCL": "AMITRIPTYLINE HCL",
              "DESIPRAMINE HCL": "DESIPRAMINE HCL",
              "DOXEPIN HCL": "DOXEPIN HCL",
              "NORTRIPTYLINE HCL": "NORTRIPTYLINE HCL"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "TRIMIPRAMINE MALEATE": "TRIMIPRAMINE MALEATE"
            },
            "EPIC PHARMA": {
              "PROTRIPTYLINE HCL": "PROTRIPTYLINE HCL"
            },
            "FAGRON": {
              "IMIPRAMINE HCL": "IMIPRAMINE HCL"
            },
            "FREEDOM PHARMACEUTICALS": {
              "IMIPRAMINE HCL": "IMIPRAMINE HCL"
            },
            "GLENMARK PHARMACEUTICALS": {
              "TRIMIPRAMINE MALEATE": "TRIMIPRAMINE MALEATE"
            },
            "GLENVIEW PHARMA": {
              "AMITRIPTYLINE HCL": "AMITRIPTYLINE HCL"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "CLOMIPRAMINE HCL": "CLOMIPRAMINE HCL",
              "DOXEPIN HCL": "DOXEPIN HCL",
              "IMIPRAMINE HCL": "IMIPRAMINE HCL"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "AMITRIPTYLINE HCL": "AMITRIPTYLINE HCL",
              "DESIPRAMINE HCL": "DESIPRAMINE HCL",
              "DOXEPIN HCL": "DOXEPIN HCL",
              "IMIPRAMINE HCL": "IMIPRAMINE HCL",
              "IMIPRAMINE PAMOATE": "IMIPRAMINE PAMOATE",
              "NORTRIPTYLINE HCL": "NORTRIPTYLINE HCL",
              "PAMELOR": "PAMELOR"
            },
            "HI-TECH": {
              "PROTRIPTYLINE HCL": "PROTRIPTYLINE HCL"
            },
            "HIKMA": {
              "IMIPRAMINE PAMOATE": "IMIPRAMINE PAMOATE",
              "PROTRIPTYLINE HCL": "PROTRIPTYLINE HCL"
            },
            "INGENUS PHARMACEUTICALS": {
              "DESIPRAMINE HCL": "DESIPRAMINE HCL"
            },
            "LEADING PHARMA": {
              "CLOMIPRAMINE HCL": "CLOMIPRAMINE HCL",
              "IMIPRAMINE HCL": "IMIPRAMINE HCL"
            },
            "LETCO MEDICAL": {
              "NORTRIPTYLINE HCL": "NORTRIPTYLINE HCL"
            },
            "LIBERTY PHARMACEUTICAL": {
              "AMITRIPTYLINE HCL": "AMITRIPTYLINE HCL",
              "CLOMIPRAMINE HCL": "CLOMIPRAMINE HCL",
              "IMIPRAMINE HCL": "IMIPRAMINE HCL"
            },
            "LIFESTAR PHARMA": {
              "CLOMIPRAMINE HCL": "CLOMIPRAMINE HCL"
            },
            "LUPIN PHARMACEUTICALS": {
              "CLOMIPRAMINE HCL": "CLOMIPRAMINE HCL",
              "IMIPRAMINE PAMOATE": "IMIPRAMINE PAMOATE"
            },
            "MAJOR PHARMACEUTICALS": {
              "AMITRIPTYLINE HCL": "AMITRIPTYLINE HCL",
              "CLOMIPRAMINE HCL": "CLOMIPRAMINE HCL",
              "DOXEPIN HCL": "DOXEPIN HCL"
            },
            "MALLINCKRODT PHARM": {
              "CLOMIPRAMINE HCL": "CLOMIPRAMINE HCL"
            },
            "MARLEX PHARMACEUTICALS": {
              "CLOMIPRAMINE HCL": "CLOMIPRAMINE HCL"
            },
            "MAYNE PHARMA": {
              "NORTRIPTYLINE HCL": "NORTRIPTYLINE HCL"
            },
            "MEDISCA": {
              "IMIPRAMINE HCL": "IMIPRAMINE HCL"
            },
            "MEDVANTX": {
              "AMITRIPTYLINE HCL": "AMITRIPTYLINE HCL",
              "IMIPRAMINE HCL": "IMIPRAMINE HCL",
              "NORTRIPTYLINE HCL": "NORTRIPTYLINE HCL"
            },
            "MICRO LABORATORIES": {
              "CLOMIPRAMINE HCL": "CLOMIPRAMINE HCL"
            },
            "MORTON GROVE PHARMACEUTICALS": {
              "DOXEPIN HCL": "DOXEPIN HCL"
            },
            "MYLAN": {
              "AMITRIPTYLINE HCL": "AMITRIPTYLINE HCL",
              "CLOMIPRAMINE HCL": "CLOMIPRAMINE HCL",
              "DOXEPIN HCL": "DOXEPIN HCL"
            },
            "MYLAN INSTITUTIONAL": {
              "AMITRIPTYLINE HCL": "AMITRIPTYLINE HCL",
              "DOXEPIN HCL": "DOXEPIN HCL"
            },
            "NORTHSTAR RX": {
              "AMITRIPTYLINE HCL": "AMITRIPTYLINE HCL",
              "CLOMIPRAMINE HCL": "CLOMIPRAMINE HCL"
            },
            "NUCARE PHARMACEUTICALS": {
              "AMITRIPTYLINE HCL": "AMITRIPTYLINE HCL",
              "DESIPRAMINE HCL": "DESIPRAMINE HCL",
              "DOXEPIN HCL": "DOXEPIN HCL",
              "IMIPRAMINE HCL": "IMIPRAMINE HCL",
              "NORTRIPTYLINE HCL": "NORTRIPTYLINE HCL"
            },
            "OXFORD PHARMACEUTICALS": {
              "IMIPRAMINE HCL": "IMIPRAMINE HCL"
            },
            "PAR PHARMACEUTICAL": {
              "DOXEPIN HCL": "DOXEPIN HCL",
              "IMIPRAMINE HCL": "IMIPRAMINE HCL"
            },
            "PAR PHARMACEUTICALS": {
              "AMITRIPTYLINE HCL": "AMITRIPTYLINE HCL"
            },
            "PCCA": {
              "DESIPRAMINE HCL": "DESIPRAMINE HCL",
              "IMIPRAMINE HCL": "IMIPRAMINE HCL",
              "NORTRIPTYLINE HCL": "NORTRIPTYLINE HCL",
              "TRIMIPRAMINE MALEATE": "TRIMIPRAMINE MALEATE"
            },
            "PDRX PHARMACEUTICAL": {
              "AMITRIPTYLINE HCL": "AMITRIPTYLINE HCL",
              "CLOMIPRAMINE HCL": "CLOMIPRAMINE HCL",
              "DOXEPIN HCL": "DOXEPIN HCL",
              "IMIPRAMINE HCL": "IMIPRAMINE HCL",
              "NORTRIPTYLINE HCL": "NORTRIPTYLINE HCL"
            },
            "PDRX PHARMACEUTICALS": {
              "DOXEPIN HCL": "DOXEPIN HCL"
            },
            "PHARMACEUTICAL ASSOCIATES": {
              "NORTRIPTYLINE HCL": "NORTRIPTYLINE HCL"
            },
            "PHARMEDIX": {
              "AMITRIPTYLINE HCL": "AMITRIPTYLINE HCL"
            },
            "PREFERRED PHARMACEUTICALS": {
              "AMITRIPTYLINE HCL": "AMITRIPTYLINE HCL",
              "DESIPRAMINE HCL": "DESIPRAMINE HCL",
              "DOXEPIN HCL": "DOXEPIN HCL",
              "NORTRIPTYLINE HCL": "NORTRIPTYLINE HCL"
            },
            "PROFICIENT RX": {
              "AMITRIPTYLINE HCL": "AMITRIPTYLINE HCL",
              "NORTRIPTYLINE HCL": "NORTRIPTYLINE HCL"
            },
            "QUALITY CARE": {
              "AMITRIPTYLINE HCL": "AMITRIPTYLINE HCL",
              "DESIPRAMINE HCL": "DESIPRAMINE HCL",
              "DOXEPIN HCL": "DOXEPIN HCL",
              "IMIPRAMINE HCL": "IMIPRAMINE HCL",
              "NORTRIPTYLINE HCL": "NORTRIPTYLINE HCL",
              "PAMELOR": "PAMELOR"
            },
            "QUALITY CARE PRODUCTS": {
              "AMITRIPTYLINE HCL": "AMITRIPTYLINE HCL",
              "DOXEPIN HCL": "DOXEPIN HCL",
              "IMIPRAMINE HCL": "IMIPRAMINE HCL",
              "NORTRIPTYLINE HCL": "NORTRIPTYLINE HCL"
            },
            "RICHMOND PHARMACEUTICALS": {
              "IMIPRAMINE HCL": "IMIPRAMINE HCL"
            },
            "RISING PHARMACEUTICALS": {
              "PROTRIPTYLINE HCL": "PROTRIPTYLINE HCL"
            },
            "RXCHANGE CO": {
              "AMITRIPTYLINE HCL": "AMITRIPTYLINE HCL",
              "DOXEPIN HCL": "DOXEPIN HCL",
              "IMIPRAMINE HCL": "IMIPRAMINE HCL",
              "IMIPRAMINE PAMOATE": "IMIPRAMINE PAMOATE",
              "NORTRIPTYLINE HCL": "NORTRIPTYLINE HCL"
            },
            "SANDOZ": {
              "AMITRIPTYLINE HCL": "AMITRIPTYLINE HCL",
              "CLOMIPRAMINE HCL": "CLOMIPRAMINE HCL",
              "DESIPRAMINE HCL": "DESIPRAMINE HCL",
              "IMIPRAMINE HCL": "IMIPRAMINE HCL"
            },
            "SANOFI-AVENTIS U.S.": {
              "NORPRAMIN": "NORPRAMIN"
            },
            "SIGMAPHARM LABORATORIES": {
              "PROTRIPTYLINE HCL": "PROTRIPTYLINE HCL"
            },
            "SILARX": {
              "DOXEPIN HCL": "DOXEPIN HCL"
            },
            "SOLUBIOMIX LLC": {
              "NORTRIPTYLINE HCL": "NORTRIPTYLINE HCL"
            },
            "SPECGX": {
              "ANAFRANIL": "ANAFRANIL",
              "IMIPRAMINE PAMOATE": "IMIPRAMINE PAMOATE",
              "PAMELOR": "PAMELOR",
              "TOFRANIL": "TOFRANIL",
              "TOFRANIL-PM": "TOFRANIL-PM"
            },
            "SPECTRUM": {
              "IMIPRAMINE HCL": "IMIPRAMINE HCL",
              "NORTRIPTYLINE HCL": "NORTRIPTYLINE HCL"
            },
            "ST MARY'S MPP": {
              "AMITRIPTYLINE HCL": "AMITRIPTYLINE HCL",
              "NORTRIPTYLINE HCL": "NORTRIPTYLINE HCL"
            },
            "SUN PHARMACEUTICALS": {
              "AMITRIPTYLINE HCL": "AMITRIPTYLINE HCL",
              "IMIPRAMINE HCL": "IMIPRAMINE HCL"
            },
            "TARO": {
              "CLOMIPRAMINE HCL": "CLOMIPRAMINE HCL",
              "DOXEPIN HCL": "DOXEPIN HCL",
              "NORTRIPTYLINE HCL": "NORTRIPTYLINE HCL"
            },
            "TEVA PHARMACEUTICALS USA": {
              "DOXEPIN HCL": "DOXEPIN HCL",
              "NORTRIPTYLINE HCL": "NORTRIPTYLINE HCL"
            },
            "TEVA/WOMENS HEALTH": {
              "SURMONTIL": "SURMONTIL"
            },
            "THOMPSON MEDICAL SOLUTIONS": {
              "ELAVIL": "ELAVIL"
            },
            "TOTAL PHARMACY SUPPLY": {
              "IMIPRAMINE HCL": "IMIPRAMINE HCL"
            },
            "TRUPHARMA": {
              "CLOMIPRAMINE HCL": "CLOMIPRAMINE HCL"
            },
            "UNIT DOSE SERVICES": {
              "AMITRIPTYLINE HCL": "AMITRIPTYLINE HCL",
              "DOXEPIN HCL": "DOXEPIN HCL",
              "NORTRIPTYLINE HCL": "NORTRIPTYLINE HCL"
            },
            "UPSHER-SMITH": {
              "CLOMIPRAMINE HCL": "CLOMIPRAMINE HCL"
            },
            "VA CMOP DALLAS": {
              "AMITRIPTYLINE HCL": "AMITRIPTYLINE HCL"
            },
            "VALIDUS PHARMACEUTICALS": {
              "NORPRAMIN": "NORPRAMIN"
            },
            "WINTHROP, US": {
              "DESIPRAMINE HCL": "DESIPRAMINE HCL"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "AMITRIPTYLINE HCL": "AMITRIPTYLINE HCL",
              "CLOMIPRAMINE HCL": "CLOMIPRAMINE HCL"
            }
          }
        }
      },
      "ANTIDIABETICS": {
        "Alpha-Glucosidase Inhibitors": {
          "Alpha-Glucosidase Inhibitors": {
            "A-S MEDICATION SOLUTIONS": {
              "PRECOSE": "PRECOSE"
            },
            "ACTAVIS PHARMA": {
              "ACARBOSE": "ACARBOSE"
            },
            "AIDAREX PHARMACEUTICALS": {
              "ACARBOSE": "ACARBOSE"
            },
            "ALVOGEN": {
              "ACARBOSE": "ACARBOSE"
            },
            "APHENA PHARMA SOLUTIONS": {
              "ACARBOSE": "ACARBOSE"
            },
            "AVET PHARMACEUTICALS": {
              "ACARBOSE": "ACARBOSE"
            },
            "AVKARE": {
              "ACARBOSE": "ACARBOSE"
            },
            "BAYER HEALTHCARE PHARMA": {
              "PRECOSE": "PRECOSE"
            },
            "BRYANT RANCH PREPACK": {
              "PRECOSE": "PRECOSE"
            },
            "HIKMA": {
              "ACARBOSE": "ACARBOSE"
            },
            "IMPAX GENERICS": {
              "ACARBOSE": "ACARBOSE"
            },
            "MAYNE PHARMA": {
              "ACARBOSE": "ACARBOSE"
            },
            "MYLAN": {
              "ACARBOSE": "ACARBOSE"
            },
            "PDRX PHARMACEUTICAL": {
              "ACARBOSE": "ACARBOSE"
            },
            "PDRX PHARMACEUTICALS": {
              "ACARBOSE": "ACARBOSE"
            },
            "PFIZER U.S.": {
              "GLYSET": "GLYSET"
            },
            "STRIDES PHARMA": {
              "ACARBOSE": "ACARBOSE"
            },
            "SUN PHARMACEUTICALS": {
              "MIGLITOL": "MIGLITOL"
            },
            "VIRTUS PHARMACEUTICALS": {
              "ACARBOSE": "ACARBOSE"
            },
            "VIRTUS PHARMACEUTICALS OPCO": {
              "ACARBOSE": "ACARBOSE"
            }
          }
        },
        "Antidiabetic - Amylin Analogs": {
          "Antidiabetic - Amylin Analogs": {
            "ASTRAZENECA": {
              "SYMLINPEN 120": "SYMLINPEN 120",
              "SYMLINPEN 60": "SYMLINPEN 60"
            },
            "QUALITY CARE": {
              "SYMLINPEN 120": "SYMLINPEN 120",
              "SYMLINPEN 60": "SYMLINPEN 60"
            }
          }
        },
        "Antidiabetic Combinations": {
          "Biguanide-Diabetic Supplies Combinations": {
            "HUDSON SCIENTIFIC": {
              "D-CARE DM2": "D-CARE DM2"
            }
          },
          "DPP-4 Inhibitor-Thiazolidinedione Combinations": {
            "PERRIGO PHARMACEUTICALS": {
              "ALOGLIPTIN-PIOGLITAZONE": "ALOGLIPTIN-PIOGLITAZONE"
            },
            "TAKEDA PHARMACEUTICALS": {
              "OSENI": "OSENI"
            }
          },
          "Dipeptidyl Peptidase-4 Inhibitor-Biguanide Combinations": {
            "ASTRAZENECA": {
              "KOMBIGLYZE XR": "KOMBIGLYZE XR"
            },
            "BOEHRINGER INGELHEIM": {
              "JENTADUETO": "JENTADUETO",
              "JENTADUETO XR": "JENTADUETO XR"
            },
            "MERCK SHARP & DOHME": {
              "JANUMET": "JANUMET",
              "JANUMET XR": "JANUMET XR"
            },
            "PERRIGO PHARMACEUTICALS": {
              "ALOGLIPTIN-METFORMIN HCL": "ALOGLIPTIN-METFORMIN HCL"
            },
            "TAKEDA PHARMACEUTICALS": {
              "KAZANO": "KAZANO"
            }
          },
          "Insulin-Incretin Mimetic Combinations": {
            "NOVO NORDISK": {
              "XULTOPHY": "XULTOPHY"
            },
            "SANOFI PHARMACEUTICALS": {
              "SOLIQUA": "SOLIQUA"
            }
          },
          "Meglitinide-Biguanide Combinations": {
            "LUPIN PHARMACEUTICALS": {
              "REPAGLINIDE-METFORMIN HCL": "REPAGLINIDE-METFORMIN HCL"
            },
            "NOVO NORDISK": {
              "PRANDIMET": "PRANDIMET"
            }
          },
          "SGLT2 Inhibitor - DPP-4 Inhibitor - Biguanide Comb": {
            "BOEHRINGER INGELHEIM": {
              "TRIJARDY XR": "TRIJARDY XR"
            }
          },
          "SGLT2 Inhibitor - DPP-4 Inhibitor Combinations": {
            "ASTRAZENECA": {
              "QTERN": "QTERN"
            },
            "BOEHRINGER INGELHEIM": {
              "GLYXAMBI": "GLYXAMBI"
            },
            "MERCK SHARP & DOHME": {
              "STEGLUJAN": "STEGLUJAN"
            }
          },
          "Sodium-Glucose Co-Transporter 2 Inhibitor-Biguanide Comb": {
            "ASTRAZENECA": {
              "XIGDUO XR": "XIGDUO XR"
            },
            "BOEHRINGER INGELHEIM": {
              "SYNJARDY": "SYNJARDY",
              "SYNJARDY XR": "SYNJARDY XR"
            },
            "JANSSEN": {
              "INVOKAMET": "INVOKAMET",
              "INVOKAMET XR": "INVOKAMET XR"
            },
            "MERCK SHARP & DOHME": {
              "SEGLUROMET": "SEGLUROMET"
            }
          },
          "Sulfonylurea-Biguanide Combinations": {
            "A-S MEDICATION SOLUTIONS": {
              "GLIPIZIDE-METFORMIN HCL": "GLIPIZIDE-METFORMIN HCL",
              "GLYBURIDE-METFORMIN": "GLYBURIDE-METFORMIN"
            },
            "ACTAVIS": {
              "GLYBURIDE-METFORMIN": "GLYBURIDE-METFORMIN"
            },
            "ACTAVIS MID ATLANTIC": {
              "GLYBURIDE-METFORMIN": "GLYBURIDE-METFORMIN"
            },
            "AIDAREX PHARMACEUTICALS": {
              "GLYBURIDE-METFORMIN": "GLYBURIDE-METFORMIN"
            },
            "AMERICAN HEALTH PACKAGING": {
              "GLYBURIDE-METFORMIN": "GLYBURIDE-METFORMIN"
            },
            "AMNEAL PHARMACEUTICALS": {
              "GLYBURIDE-METFORMIN": "GLYBURIDE-METFORMIN"
            },
            "APHENA PHARMA SOLUTIONS": {
              "GLUCOVANCE": "GLUCOVANCE",
              "GLYBURIDE-METFORMIN": "GLYBURIDE-METFORMIN"
            },
            "AUROBINDO PHARMA": {
              "GLYBURIDE-METFORMIN": "GLYBURIDE-METFORMIN"
            },
            "AVET PHARMACEUTICALS": {
              "GLIPIZIDE-METFORMIN HCL": "GLIPIZIDE-METFORMIN HCL",
              "GLYBURIDE-METFORMIN": "GLYBURIDE-METFORMIN"
            },
            "AVKARE": {
              "GLIPIZIDE-METFORMIN HCL": "GLIPIZIDE-METFORMIN HCL"
            },
            "B-M SQUIBB U.S. (PRIMARY CARE)": {
              "GLUCOVANCE": "GLUCOVANCE"
            },
            "BLENHEIM PHARMACAL": {
              "GLYBURIDE-METFORMIN": "GLYBURIDE-METFORMIN"
            },
            "BRYANT RANCH PREPACK": {
              "GLYBURIDE-METFORMIN": "GLYBURIDE-METFORMIN"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "GLYBURIDE-METFORMIN": "GLYBURIDE-METFORMIN"
            },
            "DIRECT RX": {
              "GLYBURIDE-METFORMIN": "GLYBURIDE-METFORMIN"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "GLYBURIDE-METFORMIN": "GLYBURIDE-METFORMIN"
            },
            "IMPAX GENERICS": {
              "GLYBURIDE-METFORMIN": "GLYBURIDE-METFORMIN"
            },
            "LIBERTY PHARMACEUTICAL": {
              "GLYBURIDE-METFORMIN": "GLYBURIDE-METFORMIN"
            },
            "MEDVANTX": {
              "GLUCOVANCE": "GLUCOVANCE"
            },
            "MYLAN": {
              "GLIPIZIDE-METFORMIN HCL": "GLIPIZIDE-METFORMIN HCL"
            },
            "NUCARE PHARMACEUTICALS": {
              "GLYBURIDE-METFORMIN": "GLYBURIDE-METFORMIN"
            },
            "PDRX PHARMACEUTICAL": {
              "GLUCOVANCE": "GLUCOVANCE",
              "GLYBURIDE-METFORMIN": "GLYBURIDE-METFORMIN"
            },
            "PREFERRED PHARMACEUTICALS": {
              "GLYBURIDE-METFORMIN": "GLYBURIDE-METFORMIN"
            },
            "PROFICIENT RX": {
              "GLIPIZIDE-METFORMIN HCL": "GLIPIZIDE-METFORMIN HCL"
            },
            "QUALITY CARE": {
              "GLIPIZIDE-METFORMIN HCL": "GLIPIZIDE-METFORMIN HCL",
              "GLUCOVANCE": "GLUCOVANCE",
              "GLYBURIDE-METFORMIN": "GLYBURIDE-METFORMIN"
            },
            "QUALITY CARE PRODUCTS": {
              "GLYBURIDE-METFORMIN": "GLYBURIDE-METFORMIN"
            },
            "RISING PHARMACEUTICALS": {
              "GLYBURIDE-METFORMIN": "GLYBURIDE-METFORMIN"
            },
            "ST MARY'S MPP": {
              "GLIPIZIDE-METFORMIN HCL": "GLIPIZIDE-METFORMIN HCL"
            },
            "TEVA PHARMACEUTICALS USA": {
              "GLIPIZIDE-METFORMIN HCL": "GLIPIZIDE-METFORMIN HCL"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "GLIPIZIDE-METFORMIN HCL": "GLIPIZIDE-METFORMIN HCL",
              "GLYBURIDE-METFORMIN": "GLYBURIDE-METFORMIN"
            }
          },
          "Sulfonylurea-Thiazolidinedione Combinations": {
            "PRASCO LABORATORIES": {
              "PIOGLITAZONE HCL-GLIMEPIRIDE": "PIOGLITAZONE HCL-GLIMEPIRIDE"
            },
            "SANDOZ": {
              "PIOGLITAZONE HCL-GLIMEPIRIDE": "PIOGLITAZONE HCL-GLIMEPIRIDE"
            },
            "TAKEDA PHARMACEUTICALS": {
              "DUETACT": "DUETACT"
            }
          },
          "Thiazolidinedione-Biguanide Combinations": {
            "AUROBINDO PHARMA": {
              "PIOGLITAZONE HCL-METFORMIN HCL": "PIOGLITAZONE HCL-METFORMIN HCL"
            },
            "AVKARE": {
              "PIOGLITAZONE HCL-METFORMIN HCL": "PIOGLITAZONE HCL-METFORMIN HCL"
            },
            "CAMBRIDGE THERAPEUTICS TECHN": {
              "PIOGLITAZONE HCL-METFORMIN HCL": "PIOGLITAZONE HCL-METFORMIN HCL"
            },
            "MACLEODS PHARMACEUTICALS": {
              "PIOGLITAZONE HCL-METFORMIN HCL": "PIOGLITAZONE HCL-METFORMIN HCL"
            },
            "MYLAN": {
              "PIOGLITAZONE HCL-METFORMIN HCL": "PIOGLITAZONE HCL-METFORMIN HCL"
            },
            "RISING PHARMACEUTICALS": {
              "PIOGLITAZONE HCL-METFORMIN HCL": "PIOGLITAZONE HCL-METFORMIN HCL"
            },
            "SANDOZ": {
              "PIOGLITAZONE HCL-METFORMIN HCL": "PIOGLITAZONE HCL-METFORMIN HCL"
            },
            "TAKEDA PHARMACEUTICALS": {
              "ACTOPLUS MET": "ACTOPLUS MET",
              "ACTOPLUS MET XR": "ACTOPLUS MET XR"
            },
            "TEVA PHARMACEUTICALS USA": {
              "PIOGLITAZONE HCL-METFORMIN HCL": "PIOGLITAZONE HCL-METFORMIN HCL"
            },
            "TORRENT PHARMACEUTICALS": {
              "PIOGLITAZONE HCL-METFORMIN HCL": "PIOGLITAZONE HCL-METFORMIN HCL"
            }
          }
        },
        "Biguanides": {
          "Biguanides": {
            "A-S MEDICATION SOLUTIONS": {
              "GLUCOPHAGE": "GLUCOPHAGE",
              "METFORMIN HCL": "METFORMIN HCL",
              "METFORMIN HCL ER": "METFORMIN HCL ER"
            },
            "ACI HEALTHCARE USA": {
              "METFORMIN HCL": "METFORMIN HCL"
            },
            "ACTAVIS PHARMA": {
              "METFORMIN HCL ER": "METFORMIN HCL ER",
              "METFORMIN HCL ER (MOD)": "METFORMIN HCL ER (MOD)",
              "METFORMIN HCL ER (OSM)": "METFORMIN HCL ER (OSM)"
            },
            "AIDAREX PHARMACEUTICALS": {
              "METFORMIN HCL": "METFORMIN HCL",
              "METFORMIN HCL ER": "METFORMIN HCL ER"
            },
            "AIPING PHARMACEUTICAL": {
              "METFORMIN HCL ER (OSM)": "METFORMIN HCL ER (OSM)"
            },
            "ALTURA PHARMACEUTICALS": {
              "METFORMIN HCL": "METFORMIN HCL"
            },
            "AMERICAN HEALTH PACKAGING": {
              "METFORMIN HCL": "METFORMIN HCL",
              "METFORMIN HCL ER": "METFORMIN HCL ER",
              "METFORMIN HCL ER (OSM)": "METFORMIN HCL ER (OSM)"
            },
            "AMNEAL PHARMACEUTICALS": {
              "METFORMIN HCL": "METFORMIN HCL",
              "METFORMIN HCL ER": "METFORMIN HCL ER"
            },
            "APHENA PHARMA SOLUTIONS": {
              "METFORMIN HCL": "METFORMIN HCL",
              "METFORMIN HCL ER": "METFORMIN HCL ER"
            },
            "APOTEX": {
              "METFORMIN HCL": "METFORMIN HCL",
              "METFORMIN HCL ER": "METFORMIN HCL ER"
            },
            "ASCEND LABORATORIES": {
              "METFORMIN HCL": "METFORMIN HCL",
              "METFORMIN HCL ER": "METFORMIN HCL ER"
            },
            "AUROBINDO PHARMA": {
              "METFORMIN HCL": "METFORMIN HCL",
              "METFORMIN HCL ER": "METFORMIN HCL ER"
            },
            "AVET PHARMACEUTICALS": {
              "METFORMIN HCL": "METFORMIN HCL"
            },
            "AVKARE": {
              "METFORMIN HCL": "METFORMIN HCL",
              "METFORMIN HCL ER": "METFORMIN HCL ER",
              "METFORMIN HCL ER (MOD)": "METFORMIN HCL ER (MOD)",
              "METFORMIN HCL ER (OSM)": "METFORMIN HCL ER (OSM)"
            },
            "AVPAK": {
              "METFORMIN HCL ER": "METFORMIN HCL ER"
            },
            "B-M SQUIBB U.S. (PRIMARY CARE)": {
              "GLUCOPHAGE": "GLUCOPHAGE",
              "GLUCOPHAGE XR": "GLUCOPHAGE XR"
            },
            "BAUSCH HEALTH": {
              "GLUMETZA": "GLUMETZA"
            },
            "BAYSHORE PHARMACEUTICALS": {
              "METFORMIN HCL ER": "METFORMIN HCL ER"
            },
            "BLENHEIM PHARMACAL": {
              "METFORMIN HCL": "METFORMIN HCL",
              "METFORMIN HCL ER": "METFORMIN HCL ER"
            },
            "BLU PHARMACEUTICALS": {
              "METFORMIN HCL": "METFORMIN HCL"
            },
            "BRECKENRIDGE": {
              "METFORMIN HCL": "METFORMIN HCL"
            },
            "BRYANT RANCH PREPACK": {
              "METFORMIN HCL": "METFORMIN HCL",
              "METFORMIN HCL ER": "METFORMIN HCL ER"
            },
            "CAMBRIDGE THERAPEUTICS TECHN": {
              "METFORMIN HCL": "METFORMIN HCL"
            },
            "CARDINAL HEALTH REPACKAGING SO": {
              "METFORMIN HCL": "METFORMIN HCL"
            },
            "CARLSBAD TECHNOLOGIES": {
              "METFORMIN HCL": "METFORMIN HCL"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "METFORMIN HCL": "METFORMIN HCL",
              "METFORMIN HCL ER": "METFORMIN HCL ER"
            },
            "DENTON PHARMA": {
              "METFORMIN HCL": "METFORMIN HCL",
              "METFORMIN HCL ER": "METFORMIN HCL ER"
            },
            "DIRECT RX": {
              "METFORMIN HCL": "METFORMIN HCL",
              "METFORMIN HCL ER": "METFORMIN HCL ER"
            },
            "EPIC PHARMA": {
              "METFORMIN HCL": "METFORMIN HCL",
              "METFORMIN HCL ER": "METFORMIN HCL ER",
              "METFORMIN HCL ER (OSM)": "METFORMIN HCL ER (OSM)"
            },
            "GLENMARK PHARMACEUTICALS": {
              "METFORMIN HCL": "METFORMIN HCL",
              "METFORMIN HCL ER (MOD)": "METFORMIN HCL ER (MOD)"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "METFORMIN HCL": "METFORMIN HCL",
              "METFORMIN HCL ER": "METFORMIN HCL ER",
              "METFORMIN HCL ER (OSM)": "METFORMIN HCL ER (OSM)"
            },
            "GRANULES PHARMACEUTICALS": {
              "METFORMIN HCL": "METFORMIN HCL",
              "METFORMIN HCL ER": "METFORMIN HCL ER"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "METFORMIN HCL": "METFORMIN HCL"
            },
            "INGENUS PHARMACEUTICALS": {
              "METFORMIN HCL": "METFORMIN HCL",
              "METFORMIN HCL ER (OSM)": "METFORMIN HCL ER (OSM)"
            },
            "INNOVIDA PHARMACEUTIQUE": {
              "METFORMIN HCL": "METFORMIN HCL"
            },
            "LAURUS LABS PRIVATE LIMITED": {
              "METFORMIN HCL": "METFORMIN HCL"
            },
            "LEGACY PHARMACEUTICAL PKG": {
              "METFORMIN HCL": "METFORMIN HCL"
            },
            "LIBERTY PHARMACEUTICAL": {
              "METFORMIN HCL": "METFORMIN HCL",
              "METFORMIN HCL ER": "METFORMIN HCL ER"
            },
            "LUPIN PHARMACEUTICALS": {
              "METFORMIN HCL ER (MOD)": "METFORMIN HCL ER (MOD)",
              "METFORMIN HCL ER (OSM)": "METFORMIN HCL ER (OSM)"
            },
            "MACLEODS PHARMACEUTICALS": {
              "METFORMIN HCL ER": "METFORMIN HCL ER"
            },
            "MAJOR PHARMACEUTICALS": {
              "METFORMIN HCL": "METFORMIN HCL",
              "METFORMIN HCL ER": "METFORMIN HCL ER"
            },
            "MALLINCKRODT PHARM": {
              "METFORMIN HCL": "METFORMIN HCL"
            },
            "MARLEX PHARMACEUTICALS": {
              "METFORMIN HCL": "METFORMIN HCL"
            },
            "MEDVANTX": {
              "METFORMIN HCL": "METFORMIN HCL"
            },
            "MEGALITH PHARMACEUTICALS": {
              "METFORMIN HCL": "METFORMIN HCL"
            },
            "METCURE PHARMACEUTICALS": {
              "METFORMIN HCL": "METFORMIN HCL"
            },
            "METHOD PHARMACEUTICALS": {
              "METFORMIN HCL": "METFORMIN HCL"
            },
            "MICRO LABORATORIES": {
              "METFORMIN HCL": "METFORMIN HCL"
            },
            "MYLAN": {
              "METFORMIN HCL": "METFORMIN HCL",
              "METFORMIN HCL ER (OSM)": "METFORMIN HCL ER (OSM)"
            },
            "MYLAN INSTITUTIONAL": {
              "METFORMIN HCL": "METFORMIN HCL"
            },
            "NEUROSCI": {
              "METFORMIN HCL ER": "METFORMIN HCL ER"
            },
            "NEW HORIZON RX GROUP": {
              "METFORMIN HCL": "METFORMIN HCL"
            },
            "NIVAGEN PHARMACEUTICALS": {
              "METFORMIN HCL ER": "METFORMIN HCL ER"
            },
            "NORTHSTAR RX": {
              "METFORMIN HCL ER (MOD)": "METFORMIN HCL ER (MOD)"
            },
            "NORTHWIND PHARMACEUTICALS": {
              "METFORMIN HCL ER": "METFORMIN HCL ER"
            },
            "NOSTRUM LABORATORIES": {
              "METFORMIN HCL ER": "METFORMIN HCL ER",
              "METFORMIN HCL ER (OSM)": "METFORMIN HCL ER (OSM)"
            },
            "NUCARE PHARMACEUTICALS": {
              "METFORMIN HCL": "METFORMIN HCL"
            },
            "OCEANSIDE PHARMACEUTICALS": {
              "METFORMIN HCL ER (MOD)": "METFORMIN HCL ER (MOD)"
            },
            "PDRX PHARMACEUTICAL": {
              "GLUCOPHAGE": "GLUCOPHAGE",
              "GLUCOPHAGE XR": "GLUCOPHAGE XR",
              "METFORMIN HCL": "METFORMIN HCL",
              "METFORMIN HCL ER": "METFORMIN HCL ER"
            },
            "PDRX PHARMACEUTICALS": {
              "METFORMIN HCL ER": "METFORMIN HCL ER"
            },
            "PHARMEDIX": {
              "METFORMIN HCL": "METFORMIN HCL"
            },
            "PREFERRED PHARMACEUTICALS": {
              "METFORMIN HCL": "METFORMIN HCL",
              "METFORMIN HCL ER": "METFORMIN HCL ER"
            },
            "PROFICIENT RX": {
              "METFORMIN HCL": "METFORMIN HCL",
              "METFORMIN HCL ER": "METFORMIN HCL ER"
            },
            "PROVIDENT PHARMACEUTICAL INC": {
              "METFORMIN HCL": "METFORMIN HCL"
            },
            "QUALITY CARE": {
              "METFORMIN HCL": "METFORMIN HCL",
              "METFORMIN HCL ER": "METFORMIN HCL ER"
            },
            "QUALITY CARE PRODUCTS": {
              "METFORMIN HCL": "METFORMIN HCL",
              "METFORMIN HCL ER": "METFORMIN HCL ER",
              "METFORMIN HCL ER (OSM)": "METFORMIN HCL ER (OSM)"
            },
            "RANBAXY LABORATORIES": {
              "RIOMET": "RIOMET",
              "RIOMET ER": "RIOMET ER"
            },
            "RXCHANGE CO": {
              "METFORMIN HCL": "METFORMIN HCL",
              "METFORMIN HCL ER": "METFORMIN HCL ER"
            },
            "SANDOZ": {
              "METFORMIN HCL": "METFORMIN HCL",
              "METFORMIN HCL ER": "METFORMIN HCL ER"
            },
            "SCIEGEN PHARMACEUTICALS": {
              "METFORMIN HCL": "METFORMIN HCL"
            },
            "SHIONOGI PHARMA": {
              "FORTAMET": "FORTAMET"
            },
            "SKY PACKAGING": {
              "METFORMIN HCL": "METFORMIN HCL"
            },
            "SOLCO HEALTHCARE": {
              "METFORMIN HCL": "METFORMIN HCL"
            },
            "ST MARY'S MPP": {
              "METFORMIN HCL": "METFORMIN HCL",
              "METFORMIN HCL ER": "METFORMIN HCL ER"
            },
            "SUN PHARMACEUTICALS": {
              "METFORMIN HCL": "METFORMIN HCL",
              "METFORMIN HCL ER": "METFORMIN HCL ER",
              "METFORMIN HCL ER (MOD)": "METFORMIN HCL ER (MOD)"
            },
            "TAGI PHARMA": {
              "METFORMIN HCL": "METFORMIN HCL",
              "METFORMIN HCL ER": "METFORMIN HCL ER"
            },
            "TEVA PHARMACEUTICALS USA": {
              "METFORMIN HCL": "METFORMIN HCL",
              "METFORMIN HCL ER": "METFORMIN HCL ER"
            },
            "TIME-CAP LABS": {
              "METFORMIN HCL": "METFORMIN HCL",
              "METFORMIN HCL ER": "METFORMIN HCL ER"
            },
            "VA CMOP DALLAS": {
              "METFORMIN HCL": "METFORMIN HCL"
            },
            "VIONA PHARMACEUTICALS": {
              "METFORMIN HCL ER": "METFORMIN HCL ER"
            },
            "WESTMINSTER PHARMACEUTICALS": {
              "METFORMIN HCL": "METFORMIN HCL"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "METFORMIN HCL": "METFORMIN HCL"
            }
          }
        },
        "Diabetic Other": {
          "Diabetic Other": {
            "A-S MEDICATION SOLUTIONS": {
              "GLUCAGON EMERGENCY": "GLUCAGON EMERGENCY",
              "INSTA-GLUCOSE": "INSTA-GLUCOSE"
            },
            "BAUSCH HEALTH": {
              "INSTA-GLUCOSE": "INSTA-GLUCOSE"
            },
            "BD DIABETES CARE": {
              "BD GLUCOSE": "BD GLUCOSE"
            },
            "BERGEN BRUNSWIG": {
              "GNP GLUCOSE": "GNP GLUCOSE"
            },
            "CHAIN DRUG CONSORTIUM": {
              "GLUCOSE": "GLUCOSE"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS GLUCOSE": "CVS GLUCOSE",
              "CVS SOFT GLUCOSE": "CVS SOFT GLUCOSE"
            },
            "E5 PHARMA": {
              "DIAZOXIDE": "DIAZOXIDE"
            },
            "FRESENIUS KABI USA": {
              "GLUCAGON EMERGENCY": "GLUCAGON EMERGENCY"
            },
            "LILLY": {
              "BAQSIMI ONE PACK": "BAQSIMI ONE PACK",
              "BAQSIMI TWO PACK": "BAQSIMI TWO PACK",
              "GLUCAGON EMERGENCY": "GLUCAGON EMERGENCY"
            },
            "MAGNO-HUMPHRIES LABORATORIES": {
              "GLUCOSE": "GLUCOSE"
            },
            "MAJOR PHARMACEUTICALS": {
              "GLUCOSE": "GLUCOSE"
            },
            "MCKESSON SUNMARK": {
              "SM GLUCOSE": "SM GLUCOSE"
            },
            "NOVO NORDISK": {
              "GLUCAGEN HYPOKIT": "GLUCAGEN HYPOKIT"
            },
            "PAR PHARMACEUTICAL": {
              "DIAZOXIDE": "DIAZOXIDE"
            },
            "PBM PHARMACEUTICALS": {
              "GLUCO BURST": "GLUCO BURST"
            },
            "PERRIGO": {
              "GLUTOSE 15": "GLUTOSE 15",
              "GLUTOSE 45": "GLUTOSE 45",
              "GLUTOSE 5": "GLUTOSE 5"
            },
            "PERRIGO DIABETES CARE": {
              "CVS GLUCOSE": "CVS GLUCOSE",
              "CVS GLUCOSE BITS": "CVS GLUCOSE BITS",
              "CVS GLUCOSE SHOT": "CVS GLUCOSE SHOT",
              "DEX4 GLUCOSE": "DEX4 GLUCOSE",
              "DEX4 GLUCOSE GO-POUCH": "DEX4 GLUCOSE GO-POUCH",
              "DEX4 QUICK DISSOLVE GLUCOSE": "DEX4 QUICK DISSOLVE GLUCOSE",
              "GNP QUICK DISSOLVE GLUCOSE": "GNP QUICK DISSOLVE GLUCOSE",
              "LEADER QUICK DISSOLVE GLUCOSE": "LEADER QUICK DISSOLVE GLUCOSE",
              "VALUE PLUS GLUCOSE": "VALUE PLUS GLUCOSE"
            },
            "RITE AID CORPORATION": {
              "RA GLUCOSE": "RA GLUCOSE",
              "RA TRUEPLUS GLUCOSE": "RA TRUEPLUS GLUCOSE"
            },
            "TEVA SELECT BRANDS": {
              "PROGLYCEM": "PROGLYCEM"
            },
            "TRIVIDIA HEALTH": {
              "TRUEPLUS GLUCOSE": "TRUEPLUS GLUCOSE"
            },
            "WAL-MART": {
              "RELION GLUCOSE": "RELION GLUCOSE",
              "RELION GLUCOSE DRINK": "RELION GLUCOSE DRINK"
            },
            "WALGREENS": {
              "GLUCOSE": "GLUCOSE",
              "WALGREENS GLUCOSE": "WALGREENS GLUCOSE"
            },
            "XERIS PHARMACEUTICALS": {
              "GVOKE HYPOPEN 1-PACK": "GVOKE HYPOPEN 1-PACK",
              "GVOKE HYPOPEN 2-PACK": "GVOKE HYPOPEN 2-PACK",
              "GVOKE PFS": "GVOKE PFS"
            }
          },
          "Diabetic Other - Combinations": {
            "BERGEN BRUNSWIG": {
              "GNP GLUCOSE": "GNP GLUCOSE"
            },
            "CHAIN DRUG CONSORTIUM": {
              "GLUCOSE": "GLUCOSE"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS GLUCOSE": "CVS GLUCOSE"
            },
            "HY-VEE": {
              "HY-VEE GLUCOSE": "HY-VEE GLUCOSE"
            },
            "LEADER BRAND PRODUCTS": {
              "GLUCOSE-VITAMIN C": "GLUCOSE-VITAMIN C"
            },
            "MCKESSON": {
              "HM GLUCOSE": "HM GLUCOSE"
            },
            "MCKESSON SUNMARK": {
              "SM GLUCOSE": "SM GLUCOSE"
            },
            "MEDICINE SHOPPE": {
              "GLUCOSE": "GLUCOSE"
            },
            "MEIJER": {
              "MEIJER GLUCOSE": "MEIJER GLUCOSE"
            },
            "PERRIGO DIABETES CARE": {
              "CVS GLUCOSE": "CVS GLUCOSE",
              "DEX4": "DEX4",
              "DEX4 GLUCOSE": "DEX4 GLUCOSE",
              "DEX4 NATURALS": "DEX4 NATURALS",
              "DEX4 POUCH PACK": "DEX4 POUCH PACK",
              "GLUCOSE": "GLUCOSE",
              "GLUCOSE INSTANT ENERGY": "GLUCOSE INSTANT ENERGY",
              "GNP GLUCOSE": "GNP GLUCOSE",
              "GOODSENSE GLUCOSE": "GOODSENSE GLUCOSE",
              "KROGER GLUCOSE": "KROGER GLUCOSE",
              "LEADER GLUCOSE": "LEADER GLUCOSE",
              "LONGS GLUCOSE": "LONGS GLUCOSE",
              "MEIJER GLUCOSE": "MEIJER GLUCOSE",
              "PREFERRED PLUS GLUCOSE": "PREFERRED PLUS GLUCOSE",
              "PX GLUCOSE": "PX GLUCOSE",
              "RA GLUCOSE": "RA GLUCOSE",
              "RELION GLUCOSE": "RELION GLUCOSE",
              "SMART SENSE GLUCOSE": "SMART SENSE GLUCOSE",
              "TGT GLUCOSE": "TGT GLUCOSE",
              "UP & UP GLUCOSE": "UP & UP GLUCOSE",
              "VALUE PLUS GLUCOSE": "VALUE PLUS GLUCOSE",
              "WALGREENS GLUCOSE": "WALGREENS GLUCOSE"
            },
            "PUBLIX SUPER MARKETS INC.": {
              "PX GLUCOSE": "PX GLUCOSE"
            },
            "RITE AID CORPORATION": {
              "RA GLUCOSE": "RA GLUCOSE"
            },
            "TARGET": {
              "TGT GLUCOSE": "TGT GLUCOSE"
            },
            "WAL-MART": {
              "RELION GLUCOSE": "RELION GLUCOSE"
            },
            "WALGREENS": {
              "GLUCOSE": "GLUCOSE"
            }
          },
          "Progesterone Receptor Antagonists": {
            "CORCEPT THERAPEUTICS": {
              "KORLYM": "KORLYM"
            }
          }
        },
        "Dipeptidyl Peptidase-4 (DPP-4) Inhibitors": {
          "Dipeptidyl Peptidase-4 (DPP-4) Inhibitors": {
            "A-S MEDICATION SOLUTIONS": {
              "JANUVIA": "JANUVIA"
            },
            "APHENA PHARMA SOLUTIONS": {
              "ALOGLIPTIN BENZOATE": "ALOGLIPTIN BENZOATE"
            },
            "AQ PHARMACEUTICALS": {
              "JANUVIA": "JANUVIA"
            },
            "ASTRAZENECA": {
              "ONGLYZA": "ONGLYZA"
            },
            "BOEHRINGER INGELHEIM": {
              "TRADJENTA": "TRADJENTA"
            },
            "MERCK SHARP & DOHME": {
              "JANUVIA": "JANUVIA"
            },
            "PERRIGO PHARMACEUTICALS": {
              "ALOGLIPTIN BENZOATE": "ALOGLIPTIN BENZOATE"
            },
            "QUALITY CARE": {
              "JANUVIA": "JANUVIA"
            },
            "TAKEDA PHARMACEUTICALS": {
              "NESINA": "NESINA"
            }
          }
        },
        "Dopamine Receptor Agonists - Antidiabetic": {
          "Dopamine Receptor Agonists - Ergot Derivatives": {
            "BAUSCH HEALTH": {
              "CYCLOSET": "CYCLOSET"
            }
          }
        },
        "Incretin Mimetic Agents (GLP-1 Receptor Agonists)": {
          "Incretin Mimetic Agents (GLP-1 Receptor Agonists)": {
            "A-S MEDICATION SOLUTIONS": {
              "VICTOZA": "VICTOZA"
            },
            "ASTRAZENECA": {
              "BYDUREON": "BYDUREON",
              "BYDUREON BCISE": "BYDUREON BCISE",
              "BYETTA 10 MCG PEN": "BYETTA 10 MCG PEN",
              "BYETTA 5 MCG PEN": "BYETTA 5 MCG PEN"
            },
            "GLAXO SMITH KLINE": {
              "TANZEUM": "TANZEUM"
            },
            "LILLY": {
              "TRULICITY": "TRULICITY"
            },
            "NOVO NORDISK": {
              "OZEMPIC (0.25 OR 0.5 MG/DOSE)": "OZEMPIC (0.25 OR 0.5 MG/DOSE)",
              "OZEMPIC (1 MG/DOSE)": "OZEMPIC (1 MG/DOSE)",
              "RYBELSUS": "RYBELSUS",
              "VICTOZA": "VICTOZA"
            },
            "SANOFI PHARMACEUTICALS": {
              "ADLYXIN": "ADLYXIN",
              "ADLYXIN STARTER PACK": "ADLYXIN STARTER PACK"
            }
          }
        },
        "Insulin": {
          "Human Insulin": {
            "A-S MEDICATION SOLUTIONS": {
              "HUMALOG": "HUMALOG",
              "HUMALOG KWIKPEN": "HUMALOG KWIKPEN",
              "HUMALOG MIX 75/25": "HUMALOG MIX 75/25",
              "HUMULIN 70/30": "HUMULIN 70/30",
              "HUMULIN N": "HUMULIN N",
              "HUMULIN R": "HUMULIN R",
              "LANTUS": "LANTUS",
              "LANTUS SOLOSTAR": "LANTUS SOLOSTAR",
              "LEVEMIR": "LEVEMIR",
              "LEVEMIR FLEXTOUCH": "LEVEMIR FLEXTOUCH",
              "NOVOLIN 70/30": "NOVOLIN 70/30",
              "NOVOLIN N": "NOVOLIN N",
              "NOVOLIN R": "NOVOLIN R",
              "NOVOLOG": "NOVOLOG",
              "NOVOLOG FLEXPEN": "NOVOLOG FLEXPEN",
              "NOVOLOG MIX 70/30": "NOVOLOG MIX 70/30",
              "NOVOLOG MIX 70/30 FLEXPEN": "NOVOLOG MIX 70/30 FLEXPEN",
              "NOVOLOG PENFILL": "NOVOLOG PENFILL",
              "TOUJEO SOLOSTAR": "TOUJEO SOLOSTAR"
            },
            "BAXTER HEALTHCARE CORP": {
              "MYXREDLIN": "MYXREDLIN"
            },
            "LILLY": {
              "BASAGLAR KWIKPEN": "BASAGLAR KWIKPEN",
              "HUMALOG": "HUMALOG",
              "HUMALOG JUNIOR KWIKPEN": "HUMALOG JUNIOR KWIKPEN",
              "HUMALOG KWIKPEN": "HUMALOG KWIKPEN",
              "HUMALOG MIX 50/50": "HUMALOG MIX 50/50",
              "HUMALOG MIX 50/50 KWIKPEN": "HUMALOG MIX 50/50 KWIKPEN",
              "HUMALOG MIX 75/25": "HUMALOG MIX 75/25",
              "HUMALOG MIX 75/25 KWIKPEN": "HUMALOG MIX 75/25 KWIKPEN",
              "HUMULIN 70/30": "HUMULIN 70/30",
              "HUMULIN 70/30 KWIKPEN": "HUMULIN 70/30 KWIKPEN",
              "HUMULIN N": "HUMULIN N",
              "HUMULIN N KWIKPEN": "HUMULIN N KWIKPEN",
              "HUMULIN R": "HUMULIN R",
              "HUMULIN R U-500 (CONCENTRATED)": "HUMULIN R U-500 (CONCENTRATED)",
              "HUMULIN R U-500 KWIKPEN": "HUMULIN R U-500 KWIKPEN",
              "INSULIN LISPRO": "INSULIN LISPRO",
              "INSULIN LISPRO (1 UNIT DIAL)": "INSULIN LISPRO (1 UNIT DIAL)",
              "INSULIN LISPRO JUNIOR KWIKPEN": "INSULIN LISPRO JUNIOR KWIKPEN",
              "INSULIN LISPRO PROT & LISPRO": "INSULIN LISPRO PROT & LISPRO",
              "LYUMJEV": "LYUMJEV",
              "LYUMJEV KWIKPEN": "LYUMJEV KWIKPEN"
            },
            "MANNKIND": {
              "AFREZZA": "AFREZZA"
            },
            "MYLAN SPECIALTY L.P.": {
              "SEMGLEE": "SEMGLEE"
            },
            "NOVAPLUS/SANOFI-AVENTIS": {
              "LANTUS": "LANTUS",
              "LANTUS SOLOSTAR": "LANTUS SOLOSTAR"
            },
            "NOVO NORDISK": {
              "FIASP": "FIASP",
              "FIASP FLEXTOUCH": "FIASP FLEXTOUCH",
              "FIASP PENFILL": "FIASP PENFILL",
              "LEVEMIR": "LEVEMIR",
              "LEVEMIR FLEXTOUCH": "LEVEMIR FLEXTOUCH",
              "NOVOLIN 70/30": "NOVOLIN 70/30",
              "NOVOLIN 70/30 FLEXPEN": "NOVOLIN 70/30 FLEXPEN",
              "NOVOLIN 70/30 FLEXPEN RELION": "NOVOLIN 70/30 FLEXPEN RELION",
              "NOVOLIN 70/30 RELION": "NOVOLIN 70/30 RELION",
              "NOVOLIN N": "NOVOLIN N",
              "NOVOLIN N FLEXPEN": "NOVOLIN N FLEXPEN",
              "NOVOLIN N FLEXPEN RELION": "NOVOLIN N FLEXPEN RELION",
              "NOVOLIN N RELION": "NOVOLIN N RELION",
              "NOVOLIN R": "NOVOLIN R",
              "NOVOLIN R FLEXPEN": "NOVOLIN R FLEXPEN",
              "NOVOLIN R FLEXPEN RELION": "NOVOLIN R FLEXPEN RELION",
              "NOVOLIN R RELION": "NOVOLIN R RELION",
              "NOVOLOG": "NOVOLOG",
              "NOVOLOG FLEXPEN": "NOVOLOG FLEXPEN",
              "NOVOLOG MIX 70/30": "NOVOLOG MIX 70/30",
              "NOVOLOG MIX 70/30 FLEXPEN": "NOVOLOG MIX 70/30 FLEXPEN",
              "NOVOLOG PENFILL": "NOVOLOG PENFILL",
              "TRESIBA": "TRESIBA",
              "TRESIBA FLEXTOUCH": "TRESIBA FLEXTOUCH"
            },
            "NOVO NORDISK PHARMA": {
              "INSULIN ASP PROT & ASP FLEXPEN": "INSULIN ASP PROT & ASP FLEXPEN",
              "INSULIN ASPART": "INSULIN ASPART",
              "INSULIN ASPART FLEXPEN": "INSULIN ASPART FLEXPEN",
              "INSULIN ASPART PENFILL": "INSULIN ASPART PENFILL",
              "INSULIN ASPART PROT & ASPART": "INSULIN ASPART PROT & ASPART"
            },
            "QUALITY CARE": {
              "HUMALOG": "HUMALOG",
              "HUMULIN 70/30": "HUMULIN 70/30",
              "LANTUS": "LANTUS"
            },
            "SANOFI PHARMACEUTICALS": {
              "ADMELOG": "ADMELOG",
              "ADMELOG SOLOSTAR": "ADMELOG SOLOSTAR",
              "AFREZZA": "AFREZZA",
              "TOUJEO MAX SOLOSTAR": "TOUJEO MAX SOLOSTAR",
              "TOUJEO SOLOSTAR": "TOUJEO SOLOSTAR"
            },
            "SANOFI-AVENTIS U.S.": {
              "APIDRA": "APIDRA",
              "APIDRA SOLOSTAR": "APIDRA SOLOSTAR",
              "LANTUS": "LANTUS",
              "LANTUS SOLOSTAR": "LANTUS SOLOSTAR"
            }
          }
        },
        "Insulin Sensitizing Agents": {
          "Thiazolidinediones": {
            "A-S MEDICATION SOLUTIONS": {
              "ACTOS": "ACTOS",
              "PIOGLITAZONE HCL": "PIOGLITAZONE HCL"
            },
            "ACCORD HEALTHCARE": {
              "PIOGLITAZONE HCL": "PIOGLITAZONE HCL"
            },
            "ACETRIS HEALTH": {
              "PIOGLITAZONE HCL": "PIOGLITAZONE HCL"
            },
            "ACTAVIS PHARMA": {
              "PIOGLITAZONE HCL": "PIOGLITAZONE HCL"
            },
            "AIDAREX PHARMACEUTICALS": {
              "PIOGLITAZONE HCL": "PIOGLITAZONE HCL"
            },
            "AMERICAN HEALTH PACKAGING": {
              "PIOGLITAZONE HCL": "PIOGLITAZONE HCL"
            },
            "APHENA PHARMA SOLUTIONS": {
              "ACTOS": "ACTOS",
              "PIOGLITAZONE HCL": "PIOGLITAZONE HCL"
            },
            "AQ PHARMACEUTICALS": {
              "ACTOS": "ACTOS",
              "AVANDIA": "AVANDIA"
            },
            "AUROBINDO PHARMA": {
              "PIOGLITAZONE HCL": "PIOGLITAZONE HCL"
            },
            "BRYANT RANCH PREPACK": {
              "AVANDIA": "AVANDIA"
            },
            "CELLTRION USA": {
              "PIOGLITAZONE HCL": "PIOGLITAZONE HCL"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "PIOGLITAZONE HCL": "PIOGLITAZONE HCL"
            },
            "DIRECT RX": {
              "PIOGLITAZONE HCL": "PIOGLITAZONE HCL"
            },
            "GLAXO SMITH KLINE": {
              "AVANDIA": "AVANDIA"
            },
            "INTERNATIONAL LABS, LLC": {
              "PIOGLITAZONE HCL": "PIOGLITAZONE HCL"
            },
            "MACLEODS PHARMACEUTICALS": {
              "PIOGLITAZONE HCL": "PIOGLITAZONE HCL"
            },
            "MYLAN": {
              "PIOGLITAZONE HCL": "PIOGLITAZONE HCL"
            },
            "MYLAN INSTITUTIONAL": {
              "PIOGLITAZONE HCL": "PIOGLITAZONE HCL"
            },
            "NUCARE PHARMACEUTICALS": {
              "ACTOS": "ACTOS"
            },
            "PDRX PHARMACEUTICAL": {
              "AVANDIA": "AVANDIA",
              "PIOGLITAZONE HCL": "PIOGLITAZONE HCL"
            },
            "PREFERRED PHARMACEUTICALS": {
              "PIOGLITAZONE HCL": "PIOGLITAZONE HCL"
            },
            "PROFICIENT RX": {
              "PIOGLITAZONE HCL": "PIOGLITAZONE HCL"
            },
            "QUALITY CARE": {
              "ACTOS": "ACTOS",
              "AVANDIA": "AVANDIA"
            },
            "RISING PHARMACEUTICALS": {
              "PIOGLITAZONE HCL": "PIOGLITAZONE HCL"
            },
            "SANDOZ": {
              "PIOGLITAZONE HCL": "PIOGLITAZONE HCL"
            },
            "SOLCO HEALTHCARE": {
              "PIOGLITAZONE HCL": "PIOGLITAZONE HCL"
            },
            "SUN PHARMACEUTICALS": {
              "PIOGLITAZONE HCL": "PIOGLITAZONE HCL"
            },
            "TAKEDA PHARMACEUTICALS": {
              "ACTOS": "ACTOS"
            },
            "TEVA PHARMACEUTICALS USA": {
              "PIOGLITAZONE HCL": "PIOGLITAZONE HCL"
            },
            "TORRENT PHARMACEUTICALS": {
              "PIOGLITAZONE HCL": "PIOGLITAZONE HCL"
            },
            "VA CMOP DALLAS": {
              "AVANDIA": "AVANDIA"
            }
          }
        },
        "Meglitinide Analogues": {
          "Meglitinide Analogues": {
            "ACTAVIS PHARMA": {
              "NATEGLINIDE": "NATEGLINIDE"
            },
            "AMERICAN HEALTH PACKAGING": {
              "NATEGLINIDE": "NATEGLINIDE",
              "REPAGLINIDE": "REPAGLINIDE"
            },
            "AMNEAL SPECIALTY": {
              "PRANDIN": "PRANDIN"
            },
            "AUROBINDO PHARMA": {
              "REPAGLINIDE": "REPAGLINIDE"
            },
            "AVKARE": {
              "NATEGLINIDE": "NATEGLINIDE",
              "REPAGLINIDE": "REPAGLINIDE"
            },
            "BRECKENRIDGE": {
              "REPAGLINIDE": "REPAGLINIDE"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "NATEGLINIDE": "NATEGLINIDE"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "NATEGLINIDE": "NATEGLINIDE",
              "REPAGLINIDE": "REPAGLINIDE"
            },
            "MYLAN": {
              "REPAGLINIDE": "REPAGLINIDE"
            },
            "MYLAN INSTITUTIONAL": {
              "REPAGLINIDE": "REPAGLINIDE"
            },
            "NIVAGEN PHARMACEUTICALS": {
              "NATEGLINIDE": "NATEGLINIDE"
            },
            "NOVARTIS": {
              "STARLIX": "STARLIX"
            },
            "NOVO NORDISK": {
              "PRANDIN": "PRANDIN"
            },
            "PAR PHARMACEUTICAL": {
              "NATEGLINIDE": "NATEGLINIDE"
            },
            "PERRIGO": {
              "REPAGLINIDE": "REPAGLINIDE"
            },
            "QUALITY CARE": {
              "PRANDIN": "PRANDIN"
            },
            "RISING PHARMACEUTICALS": {
              "REPAGLINIDE": "REPAGLINIDE"
            },
            "SANDOZ": {
              "REPAGLINIDE": "REPAGLINIDE"
            },
            "SUN PHARMACEUTICALS": {
              "REPAGLINIDE": "REPAGLINIDE"
            },
            "WILSHIRE PHARMACEUTICALS": {
              "NATEGLINIDE": "NATEGLINIDE"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "NATEGLINIDE": "NATEGLINIDE"
            }
          }
        },
        "Sodium-Glucose Co-Transporter 2 (SGLT2) Inhibitors": {
          "Sodium-Glucose Co-Transporter 2 (SGLT2) Inhibitors": {
            "APHENA PHARMA SOLUTIONS": {
              "JARDIANCE": "JARDIANCE"
            },
            "ASTRAZENECA": {
              "FARXIGA": "FARXIGA"
            },
            "BOEHRINGER INGELHEIM": {
              "JARDIANCE": "JARDIANCE"
            },
            "JANSSEN": {
              "INVOKANA": "INVOKANA"
            },
            "MERCK SHARP & DOHME": {
              "STEGLATRO": "STEGLATRO"
            }
          }
        },
        "Sulfonylureas": {
          "Sulfonylureas": {
            "A-S MEDICATION SOLUTIONS": {
              "GLIMEPIRIDE": "GLIMEPIRIDE",
              "GLIPIZIDE": "GLIPIZIDE",
              "GLIPIZIDE ER": "GLIPIZIDE ER",
              "GLIPIZIDE XL": "GLIPIZIDE XL",
              "GLUCOTROL XL": "GLUCOTROL XL",
              "GLYBURIDE": "GLYBURIDE"
            },
            "ACCORD HEALTHCARE": {
              "GLIMEPIRIDE": "GLIMEPIRIDE",
              "GLIPIZIDE": "GLIPIZIDE"
            },
            "ACTAVIS PHARMA": {
              "GLIPIZIDE": "GLIPIZIDE",
              "GLIPIZIDE ER": "GLIPIZIDE ER"
            },
            "AIDAREX PHARMACEUTICALS": {
              "GLIMEPIRIDE": "GLIMEPIRIDE",
              "GLIPIZIDE": "GLIPIZIDE",
              "GLIPIZIDE ER": "GLIPIZIDE ER",
              "GLYBURIDE": "GLYBURIDE"
            },
            "ALTURA PHARMACEUTICALS": {
              "CHLORPROPAMIDE": "CHLORPROPAMIDE",
              "GLIPIZIDE": "GLIPIZIDE",
              "GLYBURIDE": "GLYBURIDE"
            },
            "AMERICAN HEALTH PACKAGING": {
              "GLIMEPIRIDE": "GLIMEPIRIDE",
              "GLIPIZIDE ER": "GLIPIZIDE ER",
              "GLYBURIDE": "GLYBURIDE"
            },
            "APHENA PHARMA SOLUTIONS": {
              "GLIMEPIRIDE": "GLIMEPIRIDE",
              "GLIPIZIDE": "GLIPIZIDE",
              "GLUCOTROL XL": "GLUCOTROL XL",
              "GLYBURIDE": "GLYBURIDE"
            },
            "APOTEX": {
              "GLIPIZIDE": "GLIPIZIDE"
            },
            "AQ PHARMACEUTICALS": {
              "GLYBURIDE": "GLYBURIDE"
            },
            "AUROBINDO PHARMA": {
              "GLIPIZIDE ER": "GLIPIZIDE ER",
              "GLYBURIDE": "GLYBURIDE"
            },
            "AVET PHARMACEUTICALS": {
              "GLYBURIDE": "GLYBURIDE"
            },
            "AVKARE": {
              "GLIPIZIDE": "GLIPIZIDE",
              "GLYBURIDE": "GLYBURIDE"
            },
            "AVPAK": {
              "GLIMEPIRIDE": "GLIMEPIRIDE",
              "GLIPIZIDE": "GLIPIZIDE"
            },
            "BIONPHARMA": {
              "GLIMEPIRIDE": "GLIMEPIRIDE"
            },
            "BLENHEIM PHARMACAL": {
              "GLIMEPIRIDE": "GLIMEPIRIDE",
              "GLIPIZIDE": "GLIPIZIDE"
            },
            "BLUE POINT LABORATORIES": {
              "GLIMEPIRIDE": "GLIMEPIRIDE"
            },
            "BRYANT RANCH PREPACK": {
              "GLIMEPIRIDE": "GLIMEPIRIDE",
              "GLIPIZIDE": "GLIPIZIDE",
              "GLIPIZIDE ER": "GLIPIZIDE ER",
              "GLYBURIDE": "GLYBURIDE",
              "GLYBURIDE MICRONIZED": "GLYBURIDE MICRONIZED"
            },
            "CARDINAL HEALTH REPACKAGING SO": {
              "GLIPIZIDE": "GLIPIZIDE"
            },
            "CARLSBAD TECHNOLOGIES": {
              "GLIMEPIRIDE": "GLIMEPIRIDE"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "GLIMEPIRIDE": "GLIMEPIRIDE",
              "GLIPIZIDE": "GLIPIZIDE",
              "GLYBURIDE": "GLYBURIDE"
            },
            "DENTON PHARMA": {
              "GLIPIZIDE": "GLIPIZIDE"
            },
            "DIRECT RX": {
              "GLIMEPIRIDE": "GLIMEPIRIDE",
              "GLIPIZIDE": "GLIPIZIDE",
              "GLIPIZIDE ER": "GLIPIZIDE ER",
              "GLYBURIDE": "GLYBURIDE"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "GLIMEPIRIDE": "GLIMEPIRIDE"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "GLIMEPIRIDE": "GLIMEPIRIDE",
              "GLIPIZIDE": "GLIPIZIDE"
            },
            "GREENSTONE": {
              "GLIPIZIDE XL": "GLIPIZIDE XL"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "GLIMEPIRIDE": "GLIMEPIRIDE",
              "GLIPIZIDE": "GLIPIZIDE",
              "GLYBURIDE": "GLYBURIDE"
            },
            "HIKMA": {
              "GLYBURIDE MICRONIZED": "GLYBURIDE MICRONIZED"
            },
            "IMPAX GENERICS": {
              "GLYBURIDE": "GLYBURIDE"
            },
            "INTERNATIONAL LABS, LLC": {
              "GLIMEPIRIDE": "GLIMEPIRIDE"
            },
            "LEGACY PHARMACEUTICAL PKG": {
              "GLIMEPIRIDE": "GLIMEPIRIDE",
              "GLIPIZIDE": "GLIPIZIDE",
              "GLYBURIDE": "GLYBURIDE"
            },
            "LETCO MEDICAL": {
              "GLIPIZIDE": "GLIPIZIDE"
            },
            "LIBERTY PHARMACEUTICAL": {
              "GLIMEPIRIDE": "GLIMEPIRIDE",
              "GLIPIZIDE": "GLIPIZIDE",
              "GLIPIZIDE ER": "GLIPIZIDE ER",
              "GLYBURIDE": "GLYBURIDE"
            },
            "MAJOR PHARMACEUTICALS": {
              "GLIPIZIDE": "GLIPIZIDE"
            },
            "MEDISCA": {
              "GLIPIZIDE": "GLIPIZIDE"
            },
            "MICRO LABORATORIES": {
              "GLIMEPIRIDE": "GLIMEPIRIDE"
            },
            "MYLAN": {
              "CHLORPROPAMIDE": "CHLORPROPAMIDE",
              "GLIMEPIRIDE": "GLIMEPIRIDE",
              "GLIPIZIDE": "GLIPIZIDE",
              "GLIPIZIDE ER": "GLIPIZIDE ER",
              "GLYBURIDE MICRONIZED": "GLYBURIDE MICRONIZED",
              "TOLAZAMIDE": "TOLAZAMIDE",
              "TOLBUTAMIDE": "TOLBUTAMIDE"
            },
            "MYLAN INSTITUTIONAL": {
              "GLIMEPIRIDE": "GLIMEPIRIDE",
              "GLIPIZIDE": "GLIPIZIDE",
              "GLYBURIDE": "GLYBURIDE"
            },
            "NIVAGEN PHARMACEUTICALS": {
              "GLYBURIDE": "GLYBURIDE"
            },
            "NORTHSTAR RX": {
              "GLIPIZIDE ER": "GLIPIZIDE ER"
            },
            "NORTHWIND PHARMACEUTICALS": {
              "GLIPIZIDE": "GLIPIZIDE"
            },
            "NUCARE PHARMACEUTICALS": {
              "GLIMEPIRIDE": "GLIMEPIRIDE",
              "GLIPIZIDE": "GLIPIZIDE",
              "GLIPIZIDE ER": "GLIPIZIDE ER",
              "GLYBURIDE": "GLYBURIDE"
            },
            "PAR PHARMACEUTICAL": {
              "GLIPIZIDE ER": "GLIPIZIDE ER",
              "GLYBURIDE MICRONIZED": "GLYBURIDE MICRONIZED"
            },
            "PAR PHARMACEUTICALS": {
              "GLIMEPIRIDE": "GLIMEPIRIDE"
            },
            "PCCA": {
              "GLIPIZIDE": "GLIPIZIDE"
            },
            "PDRX PHARMACEUTICAL": {
              "CHLORPROPAMIDE": "CHLORPROPAMIDE",
              "GLIMEPIRIDE": "GLIMEPIRIDE",
              "GLIPIZIDE": "GLIPIZIDE",
              "GLIPIZIDE ER": "GLIPIZIDE ER",
              "GLUCOTROL": "GLUCOTROL",
              "GLUCOTROL XL": "GLUCOTROL XL",
              "GLYBURIDE": "GLYBURIDE",
              "GLYBURIDE MICRONIZED": "GLYBURIDE MICRONIZED",
              "TOLAZAMIDE": "TOLAZAMIDE"
            },
            "PDRX PHARMACEUTICALS": {
              "GLIMEPIRIDE": "GLIMEPIRIDE"
            },
            "PFIZER U.S.": {
              "GLUCOTROL": "GLUCOTROL",
              "GLUCOTROL XL": "GLUCOTROL XL",
              "GLYNASE": "GLYNASE"
            },
            "PHARMEDIX": {
              "GLIPIZIDE": "GLIPIZIDE"
            },
            "PHARMPAK": {
              "GLYBURIDE": "GLYBURIDE"
            },
            "PREFERRED PHARMACEUTICALS": {
              "GLIMEPIRIDE": "GLIMEPIRIDE",
              "GLIPIZIDE": "GLIPIZIDE",
              "GLIPIZIDE ER": "GLIPIZIDE ER",
              "GLYBURIDE": "GLYBURIDE"
            },
            "PROFICIENT RX": {
              "GLIPIZIDE": "GLIPIZIDE",
              "GLYBURIDE": "GLYBURIDE"
            },
            "QUALITY CARE": {
              "GLIMEPIRIDE": "GLIMEPIRIDE",
              "GLIPIZIDE": "GLIPIZIDE",
              "GLIPIZIDE ER": "GLIPIZIDE ER",
              "GLUCOTROL XL": "GLUCOTROL XL",
              "GLYBURIDE": "GLYBURIDE",
              "GLYBURIDE MICRONIZED": "GLYBURIDE MICRONIZED"
            },
            "QUALITY CARE PRODUCTS": {
              "GLIMEPIRIDE": "GLIMEPIRIDE",
              "GLIPIZIDE": "GLIPIZIDE",
              "GLIPIZIDE ER": "GLIPIZIDE ER",
              "GLYBURIDE": "GLYBURIDE"
            },
            "RISING PHARMACEUTICALS": {
              "GLIPIZIDE ER": "GLIPIZIDE ER",
              "GLYBURIDE": "GLYBURIDE"
            },
            "RXCHANGE CO": {
              "GLIPIZIDE": "GLIPIZIDE",
              "GLYBURIDE": "GLYBURIDE"
            },
            "SANDOZ": {
              "GLIPIZIDE": "GLIPIZIDE"
            },
            "SANOFI-AVENTIS U.S.": {
              "AMARYL": "AMARYL",
              "DIABETA": "DIABETA"
            },
            "SKY PACKAGING": {
              "GLYBURIDE": "GLYBURIDE"
            },
            "SOLCO HEALTHCARE": {
              "GLIMEPIRIDE": "GLIMEPIRIDE"
            },
            "SPECTRUM": {
              "GLIPIZIDE": "GLIPIZIDE",
              "GLYBURIDE": "GLYBURIDE"
            },
            "ST MARY'S MPP": {
              "GLIMEPIRIDE": "GLIMEPIRIDE",
              "GLIPIZIDE": "GLIPIZIDE",
              "GLIPIZIDE ER": "GLIPIZIDE ER",
              "GLIPIZIDE XL": "GLIPIZIDE XL"
            },
            "SUN PHARMACEUTICALS": {
              "GLIPIZIDE": "GLIPIZIDE"
            },
            "TEVA PHARMACEUTICALS USA": {
              "GLIMEPIRIDE": "GLIMEPIRIDE",
              "GLYBURIDE": "GLYBURIDE",
              "GLYBURIDE MICRONIZED": "GLYBURIDE MICRONIZED"
            },
            "TRUPHARMA": {
              "GLYBURIDE": "GLYBURIDE"
            },
            "UPSHER-SMITH": {
              "GLIPIZIDE": "GLIPIZIDE"
            },
            "VA CMOP DALLAS": {
              "GLIPIZIDE": "GLIPIZIDE",
              "GLYBURIDE": "GLYBURIDE"
            },
            "VIRTUS PHARMACEUTICALS": {
              "GLIMEPIRIDE": "GLIMEPIRIDE"
            },
            "VIRTUS PHARMACEUTICALS OPCO": {
              "GLIMEPIRIDE": "GLIMEPIRIDE"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "GLIPIZIDE ER": "GLIPIZIDE ER",
              "GLYBURIDE": "GLYBURIDE"
            }
          }
        }
      },
      "ANTIDIARRHEAL/PROBIOTIC AGENTS": {
        "Antidiarrheal - Chloride Channel Antagonists": {
          "Antidiarrheal - Chloride Channel Antagonists": {
            "BAUSCH HEALTH": {
              "FULYZAQ": "FULYZAQ"
            },
            "NAPO PHARMACEUTICALS": {
              "FULYZAQ": "FULYZAQ",
              "MYTESI": "MYTESI"
            }
          }
        },
        "Antidiarrheal/Probiotic Agents - Misc.": {
          "Antidiarrheal/Probiotic Agents - Misc.": {
            "21ST CENTURY HEALTHCARE": {
              "ACIDOPHILUS HIGH-POTENCY": "ACIDOPHILUS HIGH-POTENCY",
              "ACIDOPHILUS PROBIOTIC BLEND": "ACIDOPHILUS PROBIOTIC BLEND",
              "ADVANCED PROBIOTIC": "ADVANCED PROBIOTIC"
            },
            "A-S MEDICATION SOLUTIONS": {
              "KAOPECTATE": "KAOPECTATE",
              "PEPTO-BISMOL": "PEPTO-BISMOL"
            },
            "ABBOTT NUTRITION": {
              "SIMILAC PROBIOTIC TRI-BLEND": "SIMILAC PROBIOTIC TRI-BLEND"
            },
            "ACTIPHARMA": {
              "ACTIPHLORA": "ACTIPHLORA"
            },
            "ADVANCED GENERIC": {
              "BIOTINEX": "BIOTINEX"
            },
            "AG MARIN PHARMACEUTICALS": {
              "FLORA VANCE": "FLORA VANCE",
              "INTESTINEX": "INTESTINEX"
            },
            "AIDAREX PHARMACEUTICALS": {
              "BISMATROL": "BISMATROL"
            },
            "ALFASIGMA USA": {
              "VSL#3": "VSL#3",
              "VSL#3 DS": "VSL#3 DS",
              "VSL#3 JUNIOR": "VSL#3 JUNIOR"
            },
            "ALTAIRE": {
              "STOMACH RELIEF": "STOMACH RELIEF",
              "STOMACH RELIEF PLUS": "STOMACH RELIEF PLUS"
            },
            "AMELLA PHARMA": {
              "PROMELLA IN PREBIOTIC": "PROMELLA IN PREBIOTIC"
            },
            "AMERICAN HEALTH PACKAGING": {
              "DIGESTIVE ADVANTAGE": "DIGESTIVE ADVANTAGE"
            },
            "AMERICAN LIFELINE": {
              "FLORAJEN BIFIDOBLEND": "FLORAJEN BIFIDOBLEND",
              "FLORAJEN3": "FLORAJEN3"
            },
            "AMERICAN SALES COMPANY": {
              "STOMACH RELIEF": "STOMACH RELIEF",
              "STOMACH RELIEF MAX ST": "STOMACH RELIEF MAX ST"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP 4X PROBIOTIC": "GNP 4X PROBIOTIC",
              "GNP STOMACH RELIEF MAX ST": "GNP STOMACH RELIEF MAX ST"
            },
            "ATLANTIC BIOLOGICALS": {
              "ACIDOPHILUS": "ACIDOPHILUS",
              "BACID": "BACID",
              "BISMATROL": "BISMATROL",
              "FLORANEX": "FLORANEX",
              "FLORASTOR": "FLORASTOR"
            },
            "AURORA HEALTHCARE": {
              "PINK BISMUTH": "PINK BISMUTH",
              "STOMACH RELIEF": "STOMACH RELIEF"
            },
            "AVKARE": {
              "FLORANEX": "FLORANEX"
            },
            "BASIC DRUGS": {
              "ACIDOPHILUS": "ACIDOPHILUS",
              "PROBIOTIC GOLD EXTRA STRENGTH": "PROBIOTIC GOLD EXTRA STRENGTH"
            },
            "BAUSCH HEALTH": {
              "ENVIVE": "ENVIVE"
            },
            "BAYER CONSUMER": {
              "PHILLIPS COLON HEALTH": "PHILLIPS COLON HEALTH",
              "TRUBIOTICS": "TRUBIOTICS"
            },
            "BD DIABETES CARE": {
              "LACTINEX": "LACTINEX"
            },
            "BERGEN BRUNSWIG": {
              "GNP ACIDOPHILUS HIGH POTENCY": "GNP ACIDOPHILUS HIGH POTENCY",
              "GNP K-PEC": "GNP K-PEC",
              "GNP PINK BISMUTH": "GNP PINK BISMUTH",
              "GNP PROBIOTIC COLON SUPPORT": "GNP PROBIOTIC COLON SUPPORT",
              "GNP PROBIOTIC DIGESTIVE SUP": "GNP PROBIOTIC DIGESTIVE SUP",
              "GNP STOMACH RELIEF": "GNP STOMACH RELIEF",
              "GNP STOMACH RELIEF MAX ST": "GNP STOMACH RELIEF MAX ST"
            },
            "BIO-K-PLUS INTERNATIONAL": {
              "BIO-K PLUS STRONG": "BIO-K PLUS STRONG"
            },
            "BIO-TECH": {
              "LACTO-PECTIN": "LACTO-PECTIN"
            },
            "BIOCODEX INC.": {
              "FLORASTOR": "FLORASTOR",
              "FLORASTOR BABY": "FLORASTOR BABY",
              "FLORASTOR KIDS": "FLORASTOR KIDS",
              "FLORASTOR PLUS": "FLORASTOR PLUS",
              "FLORASTOR PRE": "FLORASTOR PRE",
              "FLORASTORMAX": "FLORASTORMAX"
            },
            "BIOPROB PR": {
              "BIO-KULT": "BIO-KULT",
              "BIO-KULT INFANTIS": "BIO-KULT INFANTIS",
              "RESTORE": "RESTORE"
            },
            "BROOKFIELD PHARMACEUTICALS": {
              "HIGH POTENCY PROBIOTIC": "HIGH POTENCY PROBIOTIC",
              "LACTOBACILLUS": "LACTOBACILLUS",
              "QUAD-PROBIOTIC": "QUAD-PROBIOTIC"
            },
            "CARDINAL HEALTH REPACKAGING SO": {
              "FLORANEX": "FLORANEX"
            },
            "CHAIN DRUG CONSORTIUM": {
              "DIARRHEA RELIEF": "DIARRHEA RELIEF",
              "PINK BISMUTH": "PINK BISMUTH",
              "PINK BISMUTH MAXIMUM STRENGTH": "PINK BISMUTH MAXIMUM STRENGTH",
              "SUPER PROBIOTIC": "SUPER PROBIOTIC"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "PINK BISMUTH MAXIMUM STRENGTH": "PINK BISMUTH MAXIMUM STRENGTH",
              "PROBIOTIC COLON SUPPORT": "PROBIOTIC COLON SUPPORT",
              "QC DIARRHEA RELIEF": "QC DIARRHEA RELIEF",
              "QC PINK BISMUTH": "QC PINK BISMUTH"
            },
            "CHAPMANMEDICALRX": {
              "LACTOJEN": "LACTOJEN"
            },
            "CHATTEM INC": {
              "KAOPECTATE": "KAOPECTATE",
              "KAOPECTATE EXTRA STRENGTH": "KAOPECTATE EXTRA STRENGTH"
            },
            "CHRONOHEALTH": {
              "PROBIOTIC": "PROBIOTIC"
            },
            "CLARION BRANDS": {
              "FLORAJEN ACIDOPHILUS": "FLORAJEN ACIDOPHILUS",
              "FLORAJEN DIGESTION": "FLORAJEN DIGESTION",
              "FLORAJEN WOMEN": "FLORAJEN WOMEN",
              "FLORAJEN3": "FLORAJEN3",
              "FLORAJEN4KIDS": "FLORAJEN4KIDS"
            },
            "CONTRACT PHARMACAL CORPORATION": {
              "BISMUTH": "BISMUTH"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS ACIDOPHILUS": "CVS ACIDOPHILUS",
              "CVS ACIDOPHILUS PROBIOTIC": "CVS ACIDOPHILUS PROBIOTIC",
              "CVS ADULT 50+ PROBIOTIC": "CVS ADULT 50+ PROBIOTIC",
              "CVS ADULT PROBIOTIC": "CVS ADULT PROBIOTIC",
              "CVS ADV PROBIOTIC GUMMIES": "CVS ADV PROBIOTIC GUMMIES",
              "CVS ANTI-DIARRHEAL": "CVS ANTI-DIARRHEAL",
              "CVS BISMUTH": "CVS BISMUTH",
              "CVS BISMUTH MAXIMUM STRENGTH": "CVS BISMUTH MAXIMUM STRENGTH",
              "CVS DIGESTIVE PROBIOTIC": "CVS DIGESTIVE PROBIOTIC",
              "CVS MOOD SUPPORT PROBIOTIC": "CVS MOOD SUPPORT PROBIOTIC",
              "CVS PROBIOTIC": "CVS PROBIOTIC",
              "CVS PROBIOTIC (LACTOBACILLUS)": "CVS PROBIOTIC (LACTOBACILLUS)",
              "CVS PROBIOTIC ACIDOPHILUS": "CVS PROBIOTIC ACIDOPHILUS",
              "CVS PROBIOTIC CHILDRENS": "CVS PROBIOTIC CHILDRENS",
              "CVS PROBIOTIC MAXIMUM STRENGTH": "CVS PROBIOTIC MAXIMUM STRENGTH",
              "CVS PROBIOTIC PEARLS EX ST": "CVS PROBIOTIC PEARLS EX ST",
              "CVS SENIOR PROBIOTIC": "CVS SENIOR PROBIOTIC",
              "CVS STOMACH RELIEF": "CVS STOMACH RELIEF",
              "CVS STOMACH RELIEF MAX ST": "CVS STOMACH RELIEF MAX ST"
            },
            "DR. FORMULAS": {
              "NEXABIOTIC": "NEXABIOTIC"
            },
            "ENOVACHEM MANUFACTURING": {
              "PREORBOTIC": "PREORBOTIC"
            },
            "ENZYMATIC THERAPY": {
              "ACIDOPHILUS PEARLS": "ACIDOPHILUS PEARLS",
              "PEARLS IC": "PEARLS IC",
              "PROBIOTIC PEARLS": "PROBIOTIC PEARLS"
            },
            "EQUALINE": {
              "EQL 2 IN 1 PROBIOTIC": "EQL 2 IN 1 PROBIOTIC",
              "EQL 4X PROBIOTIC": "EQL 4X PROBIOTIC",
              "EQL ACIDOPHILUS": "EQL ACIDOPHILUS",
              "EQL DAILY PROBIOTIC": "EQL DAILY PROBIOTIC",
              "EQL DIGESTIVE PROBIOTIC": "EQL DIGESTIVE PROBIOTIC",
              "EQL PROBIOTIC COLON SUPPORT": "EQL PROBIOTIC COLON SUPPORT",
              "EQL STOMACH RELIEF": "EQL STOMACH RELIEF",
              "EQL STOMACH RELIEF MAX ST": "EQL STOMACH RELIEF MAX ST"
            },
            "EVOLVE  BIOSYSTEMS": {
              "EVIVO": "EVIVO",
              "EVIVO REFILL": "EVIVO REFILL",
              "EVIVO STARTER PACK": "EVIVO STARTER PACK"
            },
            "EXEGI PHARMA": {
              "VISBIOME": "VISBIOME",
              "VISBIOME HIGH POTENCY": "VISBIOME HIGH POTENCY"
            },
            "FAGRON": {
              "BISMUTH SUBGALLATE": "BISMUTH SUBGALLATE"
            },
            "FLEET PHARMACEUTICALS": {
              "PEDIA-LAX PROBIOTIC YUMS": "PEDIA-LAX PROBIOTIC YUMS"
            },
            "FOXLAND PHARMACEUTICALS": {
              "ZELAC": "ZELAC"
            },
            "FREEDA VITAMINS": {
              "MORE-DOPHILUS ACIDOPHILUS": "MORE-DOPHILUS ACIDOPHILUS"
            },
            "GEISS DESTIN & DUNN": {
              "GOODSENSE 4X PROBIOTIC": "GOODSENSE 4X PROBIOTIC",
              "GOODSENSE STOMACH RELIEF": "GOODSENSE STOMACH RELIEF"
            },
            "GENDOSE PHARMACEUTICALS": {
              "ACIDOPHILUS LACTOBACILLUS": "ACIDOPHILUS LACTOBACILLUS",
              "SACCHAROMYCES BOULARDII": "SACCHAROMYCES BOULARDII"
            },
            "GERI-CARE": {
              "ACIDOPHILUS PROBIOTIC": "ACIDOPHILUS PROBIOTIC",
              "GERI-PECTATE": "GERI-PECTATE",
              "LACTOBACILLUS": "LACTOBACILLUS",
              "PINK BISMUTH": "PINK BISMUTH",
              "PROBIOTIC": "PROBIOTIC"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "PRO NUTRIENTS PROBIOTIC": "PRO NUTRIENTS PROBIOTIC"
            },
            "HANNAFORD BROTHERS": {
              "STOMACH RELIEF": "STOMACH RELIEF"
            },
            "HILLESTAD PHARMACEUTICALS": {
              "DIALYVITE PROBIOTIC": "DIALYVITE PROBIOTIC"
            },
            "HY-VEE": {
              "STOMACH RELIEF": "STOMACH RELIEF"
            },
            "I-HEALTH": {
              "AZO COMPLETE FEMININE BALANCE": "AZO COMPLETE FEMININE BALANCE",
              "CULTURELLE": "CULTURELLE",
              "CULTURELLE BABY GROW THRIVE": "CULTURELLE BABY GROW THRIVE",
              "CULTURELLE DIGESTIVE WOMENS": "CULTURELLE DIGESTIVE WOMENS",
              "CULTURELLE IMMUNE DEFENSE": "CULTURELLE IMMUNE DEFENSE",
              "CULTURELLE IMMUNITY SUPPORT": "CULTURELLE IMMUNITY SUPPORT",
              "CULTURELLE KIDS": "CULTURELLE KIDS",
              "CULTURELLE KIDS REGULARITY": "CULTURELLE KIDS REGULARITY",
              "CULTURELLE PRO-WELL": "CULTURELLE PRO-WELL",
              "CULTURELLE PRO-WELL HEALTH": "CULTURELLE PRO-WELL HEALTH",
              "CULTURELLE PROBIOTICS": "CULTURELLE PROBIOTICS",
              "CULTURELLE PROBIOTICS KIDS": "CULTURELLE PROBIOTICS KIDS",
              "UP4 PROBIOTICS": "UP4 PROBIOTICS",
              "UP4 PROBIOTICS ADULT": "UP4 PROBIOTICS ADULT",
              "UP4 PROBIOTICS KIDS CUBES": "UP4 PROBIOTICS KIDS CUBES",
              "UP4 PROBIOTICS ULTRA": "UP4 PROBIOTICS ULTRA",
              "UP4 PROBIOTICS WOMENS": "UP4 PROBIOTICS WOMENS"
            },
            "INNOVIDA PHARMACEUTIQUE": {
              "FOLIKA PROBIOTIC": "FOLIKA PROBIOTIC"
            },
            "INSIGHT PHARMACEUTICALS": {
              "BACID": "BACID"
            },
            "INTEGRATIVE THERAPEUTICS": {
              "PRO-FLORA IMMUNE": "PRO-FLORA IMMUNE",
              "PROBIOTIC PEARLS": "PROBIOTIC PEARLS",
              "PROBIOTIC PEARLS ADVANTAGE": "PROBIOTIC PEARLS ADVANTAGE",
              "PROBIOTIC PEARLS CHILDRENS": "PROBIOTIC PEARLS CHILDRENS"
            },
            "KIJIMEA": {
              "KIJIMEA IBS": "KIJIMEA IBS"
            },
            "KRAMER-NOVIS": {
              "ABATINEX": "ABATINEX"
            },
            "LAKE CONSUMER PRODUCTS": {
              "PROBIOTIC/PREBIOTIC/CRANBERRY": "PROBIOTIC/PREBIOTIC/CRANBERRY"
            },
            "LEADER BRAND PRODUCTS": {
              "4X PROBIOTIC": "4X PROBIOTIC",
              "ACIDOPHILUS HIGH-POTENCY": "ACIDOPHILUS HIGH-POTENCY",
              "ACIDOPHILUS PROBIOTIC": "ACIDOPHILUS PROBIOTIC",
              "ADVANCED PROBIOTIC": "ADVANCED PROBIOTIC",
              "DAILY PROBIOTIC SUPPLEMENT": "DAILY PROBIOTIC SUPPLEMENT",
              "PINK BISMUTH": "PINK BISMUTH",
              "PROBIOTIC ADVANCED": "PROBIOTIC ADVANCED",
              "STOMACH RELIEF": "STOMACH RELIEF",
              "STOMACH RELIEF MAX ST": "STOMACH RELIEF MAX ST",
              "STOMACH RELIEF ULTRA": "STOMACH RELIEF ULTRA"
            },
            "LETCO MEDICAL": {
              "BISMUTH SUBGALLATE": "BISMUTH SUBGALLATE"
            },
            "LIL DRUG STORE PRODUCTS": {
              "REPHRESH PRO-B": "REPHRESH PRO-B"
            },
            "MAGNO-HUMPHRIES LABORATORIES": {
              "ACIDOPHILUS": "ACIDOPHILUS"
            },
            "MAJOR PHARMACEUTICALS": {
              "ACIDOPHILUS/L-SPOROGENES": "ACIDOPHILUS/L-SPOROGENES",
              "BISMATROL": "BISMATROL",
              "BISMATROL MAXIMUM STRENGTH": "BISMATROL MAXIMUM STRENGTH",
              "KAO-TIN": "KAO-TIN"
            },
            "MAKE PEOPLE BETTER": {
              "RE:IIMMUNE": "RE:IIMMUNE"
            },
            "MARLEX PHARMACEUTICALS": {
              "LACTOBACILLUS": "LACTOBACILLUS"
            },
            "MASON VITAMINS": {
              "ACIDOPHILUS": "ACIDOPHILUS",
              "ACIDOPHILUS/BIFIDUS": "ACIDOPHILUS/BIFIDUS",
              "SOLUBLE FIBER/PROBIOTICS": "SOLUBLE FIBER/PROBIOTICS"
            },
            "MCKESSON": {
              "HM 4X PROBIOTIC": "HM 4X PROBIOTIC",
              "HM ACIDOPHILUS": "HM ACIDOPHILUS",
              "HM ACIDOPHILUS PROBIOTIC": "HM ACIDOPHILUS PROBIOTIC",
              "HM PROBIOTIC DIGESTIVE HEALTH": "HM PROBIOTIC DIGESTIVE HEALTH",
              "HM STOMACH RELIEF": "HM STOMACH RELIEF",
              "HM STOMACH RELIEF MAX STRENGTH": "HM STOMACH RELIEF MAX STRENGTH",
              "HM STOMACH RELIEF ULTRA": "HM STOMACH RELIEF ULTRA"
            },
            "MCKESSON SUNMARK": {
              "SM 4X PROBIOTIC": "SM 4X PROBIOTIC",
              "SM ACIDOPHILUS": "SM ACIDOPHILUS",
              "SM PROBIOTIC": "SM PROBIOTIC",
              "SM STOMACH RELIEF": "SM STOMACH RELIEF",
              "SM STOMACH RELIEF MAX ST": "SM STOMACH RELIEF MAX ST"
            },
            "MEDICAL PROBIOTICS": {
              "STABLEGI": "STABLEGI"
            },
            "MEDICINE SHOPPE": {
              "MEDI-BISMUTH": "MEDI-BISMUTH"
            },
            "MEDIQUE PRODUCTS": {
              "DIOTAME": "DIOTAME",
              "DIOTAME INSTYDOSE": "DIOTAME INSTYDOSE"
            },
            "MEDISCA": {
              "BISMUTH SUBGALLATE": "BISMUTH SUBGALLATE"
            },
            "MEDTRITION NATIONAL NUTRITION": {
              "BANATROL PLUS": "BANATROL PLUS",
              "PREB-2": "PREB-2"
            },
            "MEIJER": {
              "STOMACH RELIEF": "STOMACH RELIEF"
            },
            "MERICAL": {
              "TRUNATURE DIGESTIVE PROBIOTIC": "TRUNATURE DIGESTIVE PROBIOTIC",
              "TRUNATURE PROBIOTIC FOR KIDS": "TRUNATURE PROBIOTIC FOR KIDS"
            },
            "METAGENICS": {
              "ULTRAFLORA IMMUNE HEALTH": "ULTRAFLORA IMMUNE HEALTH"
            },
            "MILLER": {
              "DOFUS": "DOFUS"
            },
            "MOM ENTERPRISES": {
              "MOMMY\\'S BLISS PROBIOTIC": "MOMMY\\'S BLISS PROBIOTIC",
              "MOMMY\\'S BLISS PROBIOTIC 15 DAY": "MOMMY\\'S BLISS PROBIOTIC 15 DAY",
              "MOMMY\\'S BLISS PROBIOTIC DROPS": "MOMMY\\'S BLISS PROBIOTIC DROPS"
            },
            "MVW NUTRITIONALS": {
              "MVW COMPLETE PROBIOTIC": "MVW COMPLETE PROBIOTIC"
            },
            "NAT-RUL HEALTH PRODUCTS": {
              "ACIDOPHILUS/BIFIDUS": "ACIDOPHILUS/BIFIDUS",
              "ACIDOPHILUS/PECTIN": "ACIDOPHILUS/PECTIN",
              "NATRUL PROBIOTIC": "NATRUL PROBIOTIC"
            },
            "NATIONAL BIO GREEN SCIENCES": {
              "BACICAP": "BACICAP"
            },
            "NATIONAL VITAMIN": {
              "ACIDOPHILUS": "ACIDOPHILUS",
              "FREEZE DRIED ACIDOPHILUS": "FREEZE DRIED ACIDOPHILUS",
              "MEGA PROBIOTIC": "MEGA PROBIOTIC",
              "PRO-BIOTIC BLEND": "PRO-BIOTIC BLEND"
            },
            "NATROL": {
              "ACIDOPHILUS": "ACIDOPHILUS",
              "ACIDOPHILUS PROBIOTIC": "ACIDOPHILUS PROBIOTIC",
              "PROBIOTIC ACIDOPHILUS BIOBEADS": "PROBIOTIC ACIDOPHILUS BIOBEADS"
            },
            "NATURES BOUNTY": {
              "ACIDOPHILUS": "ACIDOPHILUS",
              "ACIDOPHILUS PROBIOTIC": "ACIDOPHILUS PROBIOTIC",
              "ADVANCED PROBIOTIC 10": "ADVANCED PROBIOTIC 10",
              "PROBIOTIC": "PROBIOTIC",
              "PROBIOTIC COMPLEX ACIDOPHILUS": "PROBIOTIC COMPLEX ACIDOPHILUS",
              "PROBIOTIC GOLD EXTRA STRENGTH": "PROBIOTIC GOLD EXTRA STRENGTH"
            },
            "NESTLE HEALTHCARE NUTRITION": {
              "GERBER GENTLE PROBIOTIC": "GERBER GENTLE PROBIOTIC",
              "GERBER SOOTHE COLIC": "GERBER SOOTHE COLIC",
              "GERBER SOOTHE PROBIOTIC COLIC": "GERBER SOOTHE PROBIOTIC COLIC",
              "GOOD START KIDS PROBIOTIC": "GOOD START KIDS PROBIOTIC",
              "GOOD START TODDLER PROBIOTIC": "GOOD START TODDLER PROBIOTIC"
            },
            "NOW HEALTH GROUP": {
              "ALOE 10000 & PROBIOTICS": "ALOE 10000 & PROBIOTICS"
            },
            "NUTRACEUTICS": {
              "BIOGAIA": "BIOGAIA",
              "BIOGAIA GASTRUS": "BIOGAIA GASTRUS",
              "BIOGAIA PROBIOTIC": "BIOGAIA PROBIOTIC",
              "BIOGAIA PROTECTIS BABY": "BIOGAIA PROTECTIS BABY"
            },
            "NUTRAFANA PHARMACEUTICALS": {
              "FLORATUMMYS KIDS": "FLORATUMMYS KIDS"
            },
            "NUTRICIA NA": {
              "DIFF-STAT": "DIFF-STAT"
            },
            "P & G HEALTH": {
              "ALIGN": "ALIGN",
              "ALIGN EXTRA STRENGTH": "ALIGN EXTRA STRENGTH",
              "ALIGN JR FOR KIDS": "ALIGN JR FOR KIDS",
              "META BIOTIC/BIO-ACTIVE 12": "META BIOTIC/BIO-ACTIVE 12",
              "PEPTO-BISMOL": "PEPTO-BISMOL",
              "PEPTO-BISMOL INSTACOOL": "PEPTO-BISMOL INSTACOOL",
              "PEPTO-BISMOL MAX STRENGTH": "PEPTO-BISMOL MAX STRENGTH",
              "PEPTO-BISMOL TO-GO": "PEPTO-BISMOL TO-GO"
            },
            "PAR PHARMACEUTICAL": {
              "PINK BISMUTH": "PINK BISMUTH"
            },
            "PATHOBIOME": {
              "BIOHM PROBIOTIC CHILDRENS": "BIOHM PROBIOTIC CHILDRENS",
              "BIOHM PROBIOTIC SUPPLEMENT": "BIOHM PROBIOTIC SUPPLEMENT",
              "BIOHM PROBIOTIC/SUPER GREENS": "BIOHM PROBIOTIC/SUPER GREENS",
              "BIOHM PROBIOTIC/VITAMIN C": "BIOHM PROBIOTIC/VITAMIN C"
            },
            "PCCA": {
              "BISMUTH SUBGALLATE": "BISMUTH SUBGALLATE"
            },
            "PERRIGO": {
              "STOMACH RELIEF": "STOMACH RELIEF"
            },
            "PERRIGO PHARMACEUTICALS": {
              "GOODSENSE STOMACH RELIEF": "GOODSENSE STOMACH RELIEF",
              "STOMACH RELIEF MAX ST": "STOMACH RELIEF MAX ST"
            },
            "PHARMACEUTICAL ASSOCIATES": {
              "BISMUTH SUBSALICYLATE": "BISMUTH SUBSALICYLATE"
            },
            "PHARMADERM": {
              "FLORA-Q": "FLORA-Q",
              "FLORA-Q 2": "FLORA-Q 2"
            },
            "PHARMASSURE, INC.": {
              "ACIDOPHILUS": "ACIDOPHILUS"
            },
            "PHARMATON": {
              "PROBIATA": "PROBIATA"
            },
            "PHARMAVITE": {
              "ACIDOPHILUS": "ACIDOPHILUS",
              "DIGESTIVE HEALTH PROBIOTIC": "DIGESTIVE HEALTH PROBIOTIC",
              "TRIPLE PROBIOTIC": "TRIPLE PROBIOTIC"
            },
            "PHYTOPHARMICA": {
              "PROBIOTIC PEARLS": "PROBIOTIC PEARLS"
            },
            "PREFERRED PHARMACEUTICALS": {
              "BISMATROL": "BISMATROL"
            },
            "PRINCETON RESEARCH": {
              "ADVANCED PROBIOTIC-14": "ADVANCED PROBIOTIC-14",
              "PROBIOTIC ACIDOPHILUS": "PROBIOTIC ACIDOPHILUS",
              "PROBIOTIC-10": "PROBIOTIC-10",
              "PROBIOTIC-10 ULTIMATE": "PROBIOTIC-10 ULTIMATE",
              "SD PROBIOTIC-10 COMPLEX ULTRA": "SD PROBIOTIC-10 COMPLEX ULTRA"
            },
            "PUBLIX SUPER MARKETS INC.": {
              "PX STOMACH RELIEF": "PX STOMACH RELIEF",
              "PX STOMACH RELIEF MAX ST": "PX STOMACH RELIEF MAX ST"
            },
            "PURE ENCAPSULATIONS": {
              "FOS": "FOS"
            },
            "RECKITT BENCKISER": {
              "DIGESTIVE ADV LACTOSE DEFENSE": "DIGESTIVE ADV LACTOSE DEFENSE",
              "DIGESTIVE ADVANTAGE": "DIGESTIVE ADVANTAGE",
              "DIGESTIVE ADVANTAGE GUMMIES": "DIGESTIVE ADVANTAGE GUMMIES"
            },
            "RELIABLE 1 LABS": {
              "BISMUTH SUBSALICYLATE": "BISMUTH SUBSALICYLATE"
            },
            "REXALL SUNDOWN": {
              "ACIDOPHILUS PROBIOTIC COMPLEX": "ACIDOPHILUS PROBIOTIC COMPLEX",
              "ACIDOPHILUS XTRA": "ACIDOPHILUS XTRA",
              "PROBIOTIC ACIDOPHILUS BEADS": "PROBIOTIC ACIDOPHILUS BEADS",
              "PROBIOTIC PRODUCT": "PROBIOTIC PRODUCT",
              "ULTIMATE PROBIOTIC FORMULA": "ULTIMATE PROBIOTIC FORMULA"
            },
            "RICHMOND PHARMACEUTICALS": {
              "BISMUTH": "BISMUTH"
            },
            "RISING PHARMACEUTICALS": {
              "FLORANEX": "FLORANEX",
              "RISA-BID PROBIOTIC": "RISA-BID PROBIOTIC",
              "RISAQUAD": "RISAQUAD",
              "RISAQUAD-2": "RISAQUAD-2"
            },
            "RITE AID CORPORATION": {
              "PA PROBIOTIC COMPLEX": "PA PROBIOTIC COMPLEX",
              "RA ACIDOPHILUS": "RA ACIDOPHILUS",
              "RA DIGESTIVE HEALTH": "RA DIGESTIVE HEALTH",
              "RA K-PEC": "RA K-PEC",
              "RA PINK BISMUTH": "RA PINK BISMUTH",
              "RA PROBIOTIC COLON CARE": "RA PROBIOTIC COLON CARE",
              "RA PROBIOTIC COMPLEX": "RA PROBIOTIC COMPLEX",
              "RA PROBIOTIC DIGESTIVE CARE": "RA PROBIOTIC DIGESTIVE CARE",
              "RA PROBIOTIC DIGESTIVE SUPPORT": "RA PROBIOTIC DIGESTIVE SUPPORT",
              "RA PROBIOTIC GUMMIES": "RA PROBIOTIC GUMMIES",
              "RA PROBIOTIC MAX STRENGTH": "RA PROBIOTIC MAX STRENGTH",
              "RA STOMACH RELIEF": "RA STOMACH RELIEF",
              "RA STOMACH RELIEF MAX ST": "RA STOMACH RELIEF MAX ST"
            },
            "RUGBY LABORATORIES": {
              "4X PROBIOTIC": "4X PROBIOTIC",
              "ACIDOPHILUS/L-SPOROGENES": "ACIDOPHILUS/L-SPOROGENES",
              "DAILY PROBIOTIC": "DAILY PROBIOTIC",
              "FLORA ASSIST": "FLORA ASSIST",
              "LACTOBACILLUS": "LACTOBACILLUS",
              "LACTOBACILLUS PROBIOTIC": "LACTOBACILLUS PROBIOTIC",
              "PEPTIC RELIEF": "PEPTIC RELIEF",
              "STOMACH RELIEF": "STOMACH RELIEF",
              "STOMACH RELIEF EXTRA STRENGTH": "STOMACH RELIEF EXTRA STRENGTH"
            },
            "SCHWABE NORTH AMERICA": {
              "FORTIFY DAILY PROBIOTIC": "FORTIFY DAILY PROBIOTIC",
              "FORTIFY OPTIMA PROBIOTIC": "FORTIFY OPTIMA PROBIOTIC",
              "FORTIFY PROBIOTIC WOMENS EX ST": "FORTIFY PROBIOTIC WOMENS EX ST",
              "PRIMADOPHILUS": "PRIMADOPHILUS"
            },
            "SELECT BRAND": {
              "SB BISMUTH": "SB BISMUTH",
              "SB BISMUTH MAXIMUM STRENGTH": "SB BISMUTH MAXIMUM STRENGTH",
              "SB PEPTIC RELIEF": "SB PEPTIC RELIEF"
            },
            "SMARTYPANTS": {
              "SMARTY PANTS KIDS PROBIOTIC": "SMARTY PANTS KIDS PROBIOTIC"
            },
            "SPECTRUM": {
              "BISMUTH SUBGALLATE": "BISMUTH SUBGALLATE"
            },
            "STERLING KNIGHT PHARMACEUTICAL": {
              "PRODIGEN": "PRODIGEN"
            },
            "TARGET": {
              "TGT STOMACH RELIEF": "TGT STOMACH RELIEF"
            },
            "THE KEY COMPANY": {
              "LACTO-BIFIDUS-600": "LACTO-BIFIDUS-600",
              "LACTO-KEY-100": "LACTO-KEY-100",
              "LACTO-KEY-600": "LACTO-KEY-600"
            },
            "THE PARTHENON CO.": {
              "DEVROM": "DEVROM"
            },
            "TMIG": {
              "PROVAD": "PROVAD"
            },
            "TOPCO": {
              "4X PROBIOTIC": "4X PROBIOTIC",
              "STOMACH RELIEF": "STOMACH RELIEF",
              "STOMACH RELIEF MAX ST": "STOMACH RELIEF MAX ST"
            },
            "U S PHARMACEUTICAL": {
              "RESTORA": "RESTORA"
            },
            "UPSPRING": {
              "PROBIOTIC": "PROBIOTIC",
              "PROBIOTIC COLIC": "PROBIOTIC COLIC",
              "UPSPRING DUAL PRENATAL IMMUN": "UPSPRING DUAL PRENATAL IMMUN"
            },
            "VERTICAL PHARMACEUTICAL": {
              "PROVELLA": "PROVELLA"
            },
            "WAL-MART": {
              "ACIDOPHILUS PROBIOTIC": "ACIDOPHILUS PROBIOTIC",
              "ACIDOPHILUS PROBIOTIC FORMULA": "ACIDOPHILUS PROBIOTIC FORMULA",
              "DAILY PROBIOTIC": "DAILY PROBIOTIC",
              "EQ PINK-BISMUTH": "EQ PINK-BISMUTH",
              "EQ PROBIOTIC": "EQ PROBIOTIC",
              "EQ STOMACH RELIEF": "EQ STOMACH RELIEF",
              "EQ STOMACH RELIEF MAX ST": "EQ STOMACH RELIEF MAX ST",
              "PROBIOTIC ACIDOPHILUS": "PROBIOTIC ACIDOPHILUS",
              "PROBIOTIC MULTI-ENZYME": "PROBIOTIC MULTI-ENZYME"
            },
            "WALGREENS": {
              "4X PROBIOTIC": "4X PROBIOTIC",
              "ACIDOPHILUS": "ACIDOPHILUS",
              "ACIDOPHILUS EXTRA STRENGTH": "ACIDOPHILUS EXTRA STRENGTH",
              "DIARRHEA": "DIARRHEA",
              "LACTOBACILLUS EXTRA STRENGTH": "LACTOBACILLUS EXTRA STRENGTH",
              "PROBIOTIC": "PROBIOTIC",
              "PROBIOTIC + OMEGA-3": "PROBIOTIC + OMEGA-3",
              "PROBIOTIC + TURMERIC EXTRACT": "PROBIOTIC + TURMERIC EXTRACT",
              "PROBIOTIC CHILDRENS": "PROBIOTIC CHILDRENS",
              "PROBIOTIC CHOCOLATE BEARS": "PROBIOTIC CHOCOLATE BEARS",
              "PROBIOTIC COLON SUPPORT": "PROBIOTIC COLON SUPPORT",
              "PROBIOTIC DAILY": "PROBIOTIC DAILY",
              "PROBIOTIC MATURE ADULT": "PROBIOTIC MATURE ADULT",
              "SOOTHE": "SOOTHE",
              "SOOTHE MAXIMUM STRENGTH": "SOOTHE MAXIMUM STRENGTH",
              "SUPER PROBIOTIC": "SUPER PROBIOTIC",
              "SUPER PROBIOTIC DIGESTIVE": "SUPER PROBIOTIC DIGESTIVE"
            },
            "WINDMILL CONSUMER PRODUCTS": {
              "ACIDOPHILUS PROBIOTIC BLEND": "ACIDOPHILUS PROBIOTIC BLEND",
              "ACIDOPHILUS SUPER PROBIOTIC": "ACIDOPHILUS SUPER PROBIOTIC",
              "ACIDOPHILUS/GOAT MILK": "ACIDOPHILUS/GOAT MILK",
              "CHILDRENS PROBIOTIC": "CHILDRENS PROBIOTIC",
              "PROBIOTIC & ACIDOPHILUS EX ST": "PROBIOTIC & ACIDOPHILUS EX ST"
            },
            "XYMOGEN": {
              "PROBIOMAX DAILY DF": "PROBIOMAX DAILY DF",
              "PROBIOMAX PLUS DF": "PROBIOMAX PLUS DF"
            }
          }
        },
        "Antidiarrheal/Probiotic Combinations": {
          "Antidiarrheal/Probiotic Combinations": {
            "A-S MEDICATION SOLUTIONS": {
              "ACIDOPHILUS/PECTIN": "ACIDOPHILUS/PECTIN",
              "PROBIOTIC FORMULA": "PROBIOTIC FORMULA"
            },
            "ATLANTIC BIOLOGICALS": {
              "ACIDOPHILUS/CITRUS PECTIN": "ACIDOPHILUS/CITRUS PECTIN"
            },
            "BASIEM": {
              "PROBICHEW": "PROBICHEW"
            },
            "CHAIN DRUG CONSORTIUM": {
              "ACIDOPHILUS PLUS PECTIN": "ACIDOPHILUS PLUS PECTIN",
              "PROBIOTIC-PREBIOTIC": "PROBIOTIC-PREBIOTIC"
            },
            "EQUALINE": {
              "EQL PROBIOTIC ACIDOPHILUS": "EQL PROBIOTIC ACIDOPHILUS"
            },
            "EVOLVE  BIOSYSTEMS": {
              "EVIVO": "EVIVO"
            },
            "FREEDA VITAMINS": {
              "KALA": "KALA"
            },
            "GERI-CARE": {
              "ACIDOPHILUS/PECTIN": "ACIDOPHILUS/PECTIN",
              "PROBIOTIC DIGESTIVE SUPPORT": "PROBIOTIC DIGESTIVE SUPPORT"
            },
            "I-HEALTH": {
              "CULTURELLE BABY CALM COMFORT": "CULTURELLE BABY CALM COMFORT",
              "CULTURELLE DIGESTIVE HEALTH": "CULTURELLE DIGESTIVE HEALTH",
              "CULTURELLE HEALTH & WELLNESS": "CULTURELLE HEALTH & WELLNESS"
            },
            "LEADER BRAND PRODUCTS": {
              "ACIDOPHILUS/PECTIN": "ACIDOPHILUS/PECTIN"
            },
            "MAJOR PHARMACEUTICALS": {
              "ACIDOPHILUS/CITRUS PECTIN": "ACIDOPHILUS/CITRUS PECTIN",
              "ACIDOPHILUS/PECTIN": "ACIDOPHILUS/PECTIN",
              "PROBIOTIC-PREBIOTIC": "PROBIOTIC-PREBIOTIC"
            },
            "MASON VITAMINS": {
              "ACIDOPHILUS/PECTIN": "ACIDOPHILUS/PECTIN",
              "PROBIOTIC-PREBIOTIC": "PROBIOTIC-PREBIOTIC"
            },
            "MOM ENTERPRISES": {
              "MOMMY\\'S BLISS PROBIOTIC DROP+D": "MOMMY\\'S BLISS PROBIOTIC DROP+D"
            },
            "NATURES BOUNTY": {
              "ACIDOPHILUS/PECTIN": "ACIDOPHILUS/PECTIN"
            },
            "NESTLE HEALTHCARE NUTRITION": {
              "GERBER SOOTHE VIT D PROBIOTIC": "GERBER SOOTHE VIT D PROBIOTIC"
            },
            "NUTRACEUTICS": {
              "BIOGAIA PROTECTIS BABY/VIT D": "BIOGAIA PROTECTIS BABY/VIT D"
            },
            "P & G HEALTH": {
              "ALIGN PREBIOTIC-PROBIOTIC": "ALIGN PREBIOTIC-PROBIOTIC"
            },
            "PLUS PHARMA": {
              "ACIDOPHILUS/PECTIN": "ACIDOPHILUS/PECTIN"
            },
            "RUGBY LABORATORIES": {
              "ACIDOPHILUS/CITRUS PECTIN": "ACIDOPHILUS/CITRUS PECTIN",
              "PROBIOTIC": "PROBIOTIC",
              "PROBIOTIC FORMULA": "PROBIOTIC FORMULA"
            },
            "U S PHARMACEUTICAL": {
              "RESTORA RX": "RESTORA RX",
              "RESTORA SPRINKLES": "RESTORA SPRINKLES"
            },
            "VIACTIV LIFESTYLE": {
              "VIACTIV DIGESTIVE HEALTH": "VIACTIV DIGESTIVE HEALTH"
            }
          },
          "Diarrhea Combinations - Opiates": {
            "CHAIN DRUG CONSORTIUM": {
              "ANTI-DIARRHEAL PLUS GAS RELIEF": "ANTI-DIARRHEAL PLUS GAS RELIEF"
            },
            "MCKESSON": {
              "HM ANTI-DIARRHEAL ANTI-GAS": "HM ANTI-DIARRHEAL ANTI-GAS"
            },
            "MCNEIL CONSUMER": {
              "IMODIUM MULTI-SYMPTOM RELIEF": "IMODIUM MULTI-SYMPTOM RELIEF"
            },
            "PERRIGO": {
              "GOODSENSE ANTI-DIARR/ANT-GAS": "GOODSENSE ANTI-DIARR/ANT-GAS"
            }
          }
        },
        "Antiperistaltic Agents": {
          "Antiperistaltic Agents": {
            "A-S MEDICATION SOLUTIONS": {
              "DIPHENATOL": "DIPHENATOL",
              "DIPHENOXYLATE-ATROPINE": "DIPHENOXYLATE-ATROPINE",
              "LOPERAMIDE HCL": "LOPERAMIDE HCL"
            },
            "AIDAREX PHARMACEUTICALS": {
              "DIPHENOXYLATE-ATROPINE": "DIPHENOXYLATE-ATROPINE",
              "LOPERAMIDE HCL": "LOPERAMIDE HCL"
            },
            "ALTURA PHARMACEUTICALS": {
              "DIPHENOXYLATE-ATROPINE": "DIPHENOXYLATE-ATROPINE"
            },
            "ALVIX LABORATORIES": {
              "LOPERAMIDE HCL": "LOPERAMIDE HCL"
            },
            "AMERICAN HEALTH PACKAGING": {
              "LOPERAMIDE HCL": "LOPERAMIDE HCL"
            },
            "AMERICAN SALES COMPANY": {
              "ANTI-DIARRHEAL": "ANTI-DIARRHEAL"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP ANTI-DIARRHEAL": "GNP ANTI-DIARRHEAL",
              "GNP LOPERAMIDE HCL": "GNP LOPERAMIDE HCL"
            },
            "ANI  PHARMACEUTICALS": {
              "DIPHENOXYLATE-ATROPINE": "DIPHENOXYLATE-ATROPINE",
              "OPIUM": "OPIUM"
            },
            "APHENA PHARMA SOLUTIONS": {
              "DIPHENOXYLATE-ATROPINE": "DIPHENOXYLATE-ATROPINE",
              "LOPERAMIDE HCL": "LOPERAMIDE HCL"
            },
            "AQ PHARMACEUTICALS": {
              "LOPERAMIDE HCL": "LOPERAMIDE HCL"
            },
            "ATLANTIC BIOLOGICALS": {
              "LOPERAMIDE HCL": "LOPERAMIDE HCL"
            },
            "AURORA HEALTHCARE": {
              "ANTI-DIARRHEAL": "ANTI-DIARRHEAL"
            },
            "BAUSCH HEALTH": {
              "OPIUM": "OPIUM"
            },
            "BAYSHORE PHARMACEUTICALS": {
              "DIPHENOXYLATE-ATROPINE": "DIPHENOXYLATE-ATROPINE"
            },
            "BERGEN BRUNSWIG": {
              "GNP ANTI-DIARRHEAL": "GNP ANTI-DIARRHEAL"
            },
            "BRYANT RANCH PREPACK": {
              "DIPHENOXYLATE-ATROPINE": "DIPHENOXYLATE-ATROPINE",
              "LOPERAMIDE HCL": "LOPERAMIDE HCL"
            },
            "CHAIN DRUG CONSORTIUM": {
              "ANTI-DIARRHEAL": "ANTI-DIARRHEAL"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC ANTI-DIARRHEAL": "QC ANTI-DIARRHEAL"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "LOPERAMIDE HCL": "LOPERAMIDE HCL"
            },
            "COSTCO WHOLESALE": {
              "KLS ANTI-DIARRHEAL": "KLS ANTI-DIARRHEAL",
              "LOPERAMIDE HCL": "LOPERAMIDE HCL"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS ANTI-DIARRHEAL": "CVS ANTI-DIARRHEAL",
              "CVS LOPERAMIDE HCL": "CVS LOPERAMIDE HCL"
            },
            "DIRECT RX": {
              "DIPHENOXYLATE-ATROPINE": "DIPHENOXYLATE-ATROPINE",
              "LOPERAMIDE HCL": "LOPERAMIDE HCL"
            },
            "EDENBRIDGE PHARMACEUTICALS": {
              "OPIUM": "OPIUM"
            },
            "EQUALINE": {
              "EQL ANTI-DIARRHEAL": "EQL ANTI-DIARRHEAL",
              "EQL LOPERAMIDE HCL": "EQL LOPERAMIDE HCL"
            },
            "FAGRON": {
              "LOPERAMIDE HCL": "LOPERAMIDE HCL"
            },
            "FREEDOM PHARMACEUTICALS": {
              "LOPERAMIDE HCL": "LOPERAMIDE HCL"
            },
            "GERI-CARE": {
              "ANTI-DIARRHEAL": "ANTI-DIARRHEAL"
            },
            "GREENSTONE": {
              "DIPHENOXYLATE-ATROPINE": "DIPHENOXYLATE-ATROPINE"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "DIPHENOXYLATE-ATROPINE": "DIPHENOXYLATE-ATROPINE",
              "LOPERAMIDE HCL": "LOPERAMIDE HCL"
            },
            "HANNAFORD BROTHERS": {
              "ANTI-DIARRHEAL": "ANTI-DIARRHEAL"
            },
            "HI-TECH": {
              "LOPERAMIDE HCL": "LOPERAMIDE HCL",
              "PAREGORIC": "PAREGORIC"
            },
            "HIKMA": {
              "DIPHENOXYLATE-ATROPINE": "DIPHENOXYLATE-ATROPINE"
            },
            "KROGER COMPANY": {
              "ANTI-DIARRHEAL": "ANTI-DIARRHEAL"
            },
            "LANNETT": {
              "DIPHENOXYLATE-ATROPINE": "DIPHENOXYLATE-ATROPINE"
            },
            "LEADER BRAND PRODUCTS": {
              "ANTI-DIARRHEAL": "ANTI-DIARRHEAL",
              "LOPERAMIDE HCL": "LOPERAMIDE HCL"
            },
            "LEADING PHARMA": {
              "DIPHENOXYLATE-ATROPINE": "DIPHENOXYLATE-ATROPINE"
            },
            "LETCO MEDICAL": {
              "LOPERAMIDE HCL": "LOPERAMIDE HCL"
            },
            "LIBERTY PHARMACEUTICAL": {
              "DIPHENOXYLATE-ATROPINE": "DIPHENOXYLATE-ATROPINE",
              "LOPERAMIDE HCL": "LOPERAMIDE HCL"
            },
            "LOHXA": {
              "DIPHENOXYLATE-ATROPINE": "DIPHENOXYLATE-ATROPINE"
            },
            "MAGNO-HUMPHRIES LABORATORIES": {
              "ANTI-DIARRHEAL": "ANTI-DIARRHEAL"
            },
            "MAJOR PHARMACEUTICALS": {
              "ANTI-DIARRHEAL": "ANTI-DIARRHEAL",
              "LOPERAMIDE HCL": "LOPERAMIDE HCL"
            },
            "MCKESSON": {
              "HM ANTI-DIARRHEAL": "HM ANTI-DIARRHEAL",
              "HM LOPERAMIDE HCL": "HM LOPERAMIDE HCL"
            },
            "MCKESSON SUNMARK": {
              "SM ANTI-DIARRHEAL": "SM ANTI-DIARRHEAL",
              "SM LOPERAMIDE HCL": "SM LOPERAMIDE HCL"
            },
            "MCNEIL CONSUMER": {
              "IMODIUM A-D": "IMODIUM A-D"
            },
            "MEDICINE SHOPPE": {
              "ANTI-DIARRHEAL": "ANTI-DIARRHEAL"
            },
            "MEDIQUE PRODUCTS": {
              "DIAMODE": "DIAMODE"
            },
            "MEDISCA": {
              "LOPERAMIDE HCL": "LOPERAMIDE HCL"
            },
            "MEIJER": {
              "ANTI-DIARRHEAL": "ANTI-DIARRHEAL",
              "MEIJER ANTI-DIARRHEAL": "MEIJER ANTI-DIARRHEAL"
            },
            "MYLAN": {
              "DIPHENOXYLATE-ATROPINE": "DIPHENOXYLATE-ATROPINE",
              "LOPERAMIDE HCL": "LOPERAMIDE HCL"
            },
            "MYLAN INSTITUTIONAL": {
              "LOPERAMIDE HCL": "LOPERAMIDE HCL"
            },
            "NEIL LABS": {
              "LOPERAMIDE A-D": "LOPERAMIDE A-D"
            },
            "NUCARE PHARMACEUTICALS": {
              "LOPERAMIDE HCL": "LOPERAMIDE HCL"
            },
            "OHM LABS": {
              "ANTI-DIARRHEAL": "ANTI-DIARRHEAL"
            },
            "PCCA": {
              "LOPERAMIDE HCL": "LOPERAMIDE HCL"
            },
            "PDRX PHARMACEUTICAL": {
              "DIPHENOXYLATE-ATROPINE": "DIPHENOXYLATE-ATROPINE",
              "LOMOTIL": "LOMOTIL",
              "LOPERAMIDE HCL": "LOPERAMIDE HCL"
            },
            "PERRIGO": {
              "GOODSENSE ANTI-DIARRHEAL": "GOODSENSE ANTI-DIARRHEAL"
            },
            "PERRIGO PHARMACEUTICALS": {
              "ANTI-DIARRHEAL": "ANTI-DIARRHEAL",
              "LOPERAMIDE HCL": "LOPERAMIDE HCL"
            },
            "PFIZER U.S.": {
              "LOMOTIL": "LOMOTIL"
            },
            "PHARMACEUTICA NORTH AMERICA": {
              "LOPERAMIDE HCL": "LOPERAMIDE HCL"
            },
            "PHARMEDIX": {
              "DIPHENOXYLATE-ATROPINE": "DIPHENOXYLATE-ATROPINE"
            },
            "PHARMPAK": {
              "DIPHENOXYLATE-ATROPINE": "DIPHENOXYLATE-ATROPINE"
            },
            "PRECISION DOSE, INC": {
              "LOPERAMIDE HCL": "LOPERAMIDE HCL"
            },
            "PREFERRED PHARMACEUTICALS": {
              "ANTI-DIARRHEAL": "ANTI-DIARRHEAL",
              "DIPHENOXYLATE-ATROPINE": "DIPHENOXYLATE-ATROPINE",
              "LOPERAMIDE HCL": "LOPERAMIDE HCL"
            },
            "PROFICIENT RX": {
              "DIPHENOXYLATE-ATROPINE": "DIPHENOXYLATE-ATROPINE",
              "LOPERAMIDE HCL": "LOPERAMIDE HCL"
            },
            "PUBLIX SUPER MARKETS INC.": {
              "PX ANTI-DIARRHEAL": "PX ANTI-DIARRHEAL"
            },
            "QUALITY CARE": {
              "DIPHENOXYLATE-ATROPINE": "DIPHENOXYLATE-ATROPINE",
              "LOPERAMIDE HCL": "LOPERAMIDE HCL"
            },
            "QUALITY CARE PRODUCTS": {
              "DIPHENOXYLATE-ATROPINE": "DIPHENOXYLATE-ATROPINE"
            },
            "RITE AID CORPORATION": {
              "RA ANTI-DIARRHEAL": "RA ANTI-DIARRHEAL",
              "RA LOPERAMIDE HCL": "RA LOPERAMIDE HCL"
            },
            "RXCHANGE CO": {
              "DIPHENOXYLATE-ATROPINE": "DIPHENOXYLATE-ATROPINE",
              "LOPERAMIDE HCL": "LOPERAMIDE HCL"
            },
            "SAM'S WEST": {
              "MM ANTI-DIARRHEAL": "MM ANTI-DIARRHEAL",
              "SR ANTI-DIARRHEAL": "SR ANTI-DIARRHEAL"
            },
            "SEBELA PHARMACEUTICALS": {
              "MOTOFEN": "MOTOFEN"
            },
            "SELECT BRAND": {
              "SB ANTI-DIARRHEA": "SB ANTI-DIARRHEA"
            },
            "SPECTRUM": {
              "LOPERAMIDE HCL": "LOPERAMIDE HCL"
            },
            "TARGET": {
              "TGT ANTI-DIARRHEAL": "TGT ANTI-DIARRHEAL",
              "TGT LOPERAMIDE HCL": "TGT LOPERAMIDE HCL"
            },
            "TEVA PHARMACEUTICALS USA": {
              "LOPERAMIDE HCL": "LOPERAMIDE HCL"
            },
            "TOPCO": {
              "ANTI-DIARRHEAL": "ANTI-DIARRHEAL",
              "LOPERAMIDE HCL": "LOPERAMIDE HCL"
            },
            "UNIT DOSE SERVICES": {
              "DIPHENOXYLATE-ATROPINE": "DIPHENOXYLATE-ATROPINE"
            },
            "UPSHER-SMITH": {
              "DIPHENOXYLATE-ATROPINE": "DIPHENOXYLATE-ATROPINE"
            },
            "WAL-MART": {
              "EQ ANTI-DIARRHEAL": "EQ ANTI-DIARRHEAL",
              "EQ LOPERAMIDE HCL": "EQ LOPERAMIDE HCL"
            },
            "WALGREENS": {
              "ANTI-DIARRHEAL": "ANTI-DIARRHEAL",
              "LOPERAMIDE HCL": "LOPERAMIDE HCL"
            }
          }
        },
        "Gastrointestinal Adsorbents": {
          "Gastrointestinal Adsorbents": {
            "FAGRON": {
              "KAOLIN": "KAOLIN",
              "PECTIN": "PECTIN"
            },
            "MEDISCA": {
              "KAOLIN": "KAOLIN"
            },
            "PCCA": {
              "KAOLIN": "KAOLIN",
              "PECTIN": "PECTIN"
            },
            "SPECTRUM": {
              "KAOLIN": "KAOLIN",
              "PECTIN": "PECTIN"
            }
          }
        }
      },
      "ANTIDOTES AND SPECIFIC ANTAGONISTS": {
        "Antidote Combinations": {
          "Antidote Combinations": {
            "HOPE PHARMACEUTICALS": {
              "NITHIODOTE": "NITHIODOTE"
            },
            "MERIDIAN MEDICAL TECHNOLOGIES": {
              "DUODOTE": "DUODOTE"
            }
          }
        },
        "Antidotes - Chelating Agents": {
          "Antidotes - Chelating Agents": {
            "ACTAVIS": {
              "DEFERASIROX": "DEFERASIROX"
            },
            "ACTAVIS PHARMA": {
              "DEFERASIROX": "DEFERASIROX"
            },
            "ALEMBIC PHARMACEUTICALS": {
              "DEFERASIROX": "DEFERASIROX"
            },
            "AMNEAL PHARMACEUTICALS": {
              "DEFERASIROX": "DEFERASIROX"
            },
            "ASCEND LABORATORIES": {
              "DEFERASIROX": "DEFERASIROX",
              "DEFERASIROX GRANULES": "DEFERASIROX GRANULES"
            },
            "BIONPHARMA": {
              "DEFERASIROX": "DEFERASIROX"
            },
            "CHIESI USA": {
              "FERRIPROX": "FERRIPROX",
              "FERRIPROX TWICE-A-DAY": "FERRIPROX TWICE-A-DAY"
            },
            "CIPLA USA": {
              "DEFERASIROX": "DEFERASIROX"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "DEFERASIROX": "DEFERASIROX"
            },
            "GLENMARK PHARMACEUTICALS": {
              "DEFERASIROX": "DEFERASIROX"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "PENTETATE CALCIUM TRISODIUM": "PENTETATE CALCIUM TRISODIUM",
              "PENTETATE ZINC TRISODIUM": "PENTETATE ZINC TRISODIUM"
            },
            "IMPRIMIS NJOF": {
              "DIMERCAPTOPROPANE-SULFONATE": "DIMERCAPTOPROPANE-SULFONATE"
            },
            "NORTHSTAR RX": {
              "DEFERASIROX": "DEFERASIROX"
            },
            "NOVARTIS": {
              "EXJADE": "EXJADE",
              "JADENU": "JADENU",
              "JADENU SPRINKLE": "JADENU SPRINKLE"
            },
            "RECORDATI RARE DISEASES": {
              "CHEMET": "CHEMET"
            },
            "SUN PHARMACEUTICALS": {
              "DEFERASIROX": "DEFERASIROX"
            },
            "TARO": {
              "DEFERIPRONE": "DEFERIPRONE"
            },
            "TEVA PHARMACEUTICALS USA": {
              "DEFERASIROX": "DEFERASIROX"
            },
            "XIROMED": {
              "DEFERASIROX": "DEFERASIROX"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "DEFERASIROX": "DEFERASIROX"
            }
          }
        },
        "Antidotes and Specific Antagonists": {
          "Antidotes and Specific Antagonists": {
            "A-S MEDICATION SOLUTIONS": {
              "EZ CHAR": "EZ CHAR"
            },
            "AKORN": {
              "ACETYLCYSTEINE": "ACETYLCYSTEINE",
              "BAL IN OIL": "BAL IN OIL",
              "METHYLENE BLUE": "METHYLENE BLUE",
              "PHYSOSTIGMINE SALICYLATE": "PHYSOSTIGMINE SALICYLATE"
            },
            "ALVOGEN": {
              "DEFEROXAMINE MESYLATE": "DEFEROXAMINE MESYLATE"
            },
            "AMERICAN REGENT": {
              "FOMEPIZOLE": "FOMEPIZOLE",
              "PROVAYBLUE": "PROVAYBLUE"
            },
            "ANBEX INC": {
              "IOSAT": "IOSAT"
            },
            "APO PHARMA USA": {
              "DEFEROXAMINE MESYLATE": "DEFEROXAMINE MESYLATE"
            },
            "ARBOR PHARMACEUTICALS": {
              "CETYLEV": "CETYLEV"
            },
            "AUROMEDICS PHARMA": {
              "ACETYLCYSTEINE": "ACETYLCYSTEINE"
            },
            "BAUSCH HEALTH": {
              "CALCIUM DISODIUM VERSENATE": "CALCIUM DISODIUM VERSENATE"
            },
            "BAXTER HEALTHCARE CORP": {
              "PROTOPAM CHLORIDE": "PROTOPAM CHLORIDE"
            },
            "BOEHRINGER INGELHEIM": {
              "PRAXBIND": "PRAXBIND"
            },
            "BTG INTERNATIONAL": {
              "DIGIFAB": "DIGIFAB"
            },
            "CUMBERLAND PHARMACEUTICALS": {
              "ACETADOTE": "ACETADOTE"
            },
            "DSE HEALTHCARE SOLUTIONS": {
              "CHARCOCAPS": "CHARCOCAPS"
            },
            "FAGRON COMPOUNDING SERVICES": {
              "SODIUM THIOSULFATE": "SODIUM THIOSULFATE"
            },
            "FRESENIUS KABI USA": {
              "ACETYLCYSTEINE": "ACETYLCYSTEINE",
              "DEFEROXAMINE MESYLATE": "DEFEROXAMINE MESYLATE"
            },
            "HEYL CHEM": {
              "RADIOGARDASE": "RADIOGARDASE"
            },
            "HOPE PHARMACEUTICALS": {
              "SODIUM NITRITE": "SODIUM NITRITE",
              "SODIUM THIOSULFATE": "SODIUM THIOSULFATE"
            },
            "HOSPIRA": {
              "DEFEROXAMINE MESYLATE": "DEFEROXAMINE MESYLATE"
            },
            "HUMCO": {
              "CHARCOAL": "CHARCOAL"
            },
            "JAZZ PHARMACEUTICALS": {
              "ANTIZOL": "ANTIZOL"
            },
            "KIMBERLY-CLARK": {
              "CHAR-FLO WITH SORBITOL": "CHAR-FLO WITH SORBITOL"
            },
            "LEADER BRAND PRODUCTS": {
              "CHARCOAL ACTIVATED": "CHARCOAL ACTIVATED"
            },
            "MASON VITAMINS": {
              "ACTIVATED VEGETABLE CHARCOAL": "ACTIVATED VEGETABLE CHARCOAL"
            },
            "MCKESSON SUNMARK": {
              "SM IPECAC SYRUP": "SM IPECAC SYRUP"
            },
            "MEDISCA": {
              "EDETATE CALCIUM DISODIUM": "EDETATE CALCIUM DISODIUM"
            },
            "MEDTECH": {
              "LITTLE REMEDIES POISON TREAT": "LITTLE REMEDIES POISON TREAT"
            },
            "MERCK SHARP & DOHME": {
              "BRIDION": "BRIDION"
            },
            "MERIDIAN MEDICAL TECHNOLOGIES": {
              "CYANOKIT": "CYANOKIT",
              "PRALIDOXIME CHLORIDE": "PRALIDOXIME CHLORIDE"
            },
            "MISSION": {
              "POTASSIUM IODIDE (ANTIDOTE)": "POTASSIUM IODIDE (ANTIDOTE)"
            },
            "MYLAN INSTITUTIONAL": {
              "FOMEPIZOLE": "FOMEPIZOLE"
            },
            "NAT-RUL HEALTH PRODUCTS": {
              "CHARCOAL": "CHARCOAL"
            },
            "NOVAPLUS/PERRIGO": {
              "ACETYLCYSTEINE": "ACETYLCYSTEINE"
            },
            "NOVARTIS": {
              "DESFERAL": "DESFERAL"
            },
            "PCCA": {
              "CHARCOAL ACTIVATED": "CHARCOAL ACTIVATED",
              "EDETATE CALCIUM DISODIUM": "EDETATE CALCIUM DISODIUM"
            },
            "PERRIGO": {
              "ACETYLCYSTEINE": "ACETYLCYSTEINE",
              "ACTIDOSE WITH SORBITOL": "ACTIDOSE WITH SORBITOL",
              "ACTIDOSE-AQUA": "ACTIDOSE-AQUA",
              "EZ CHAR": "EZ CHAR"
            },
            "PORTOLA PHARMACEUTICALS": {
              "ANDEXXA": "ANDEXXA"
            },
            "PRINCETON RESEARCH": {
              "CHARCOAL ACTIVATED": "CHARCOAL ACTIVATED"
            },
            "RECIP US": {
              "THYROSAFE": "THYROSAFE"
            },
            "SAGENT PHARMACEUTICAL": {
              "ACETYLCYSTEINE": "ACETYLCYSTEINE"
            },
            "SANDOZ": {
              "FOMEPIZOLE": "FOMEPIZOLE"
            },
            "SPECTRUM": {
              "CHARCOAL ACTIVATED": "CHARCOAL ACTIVATED",
              "EDETATE CALCIUM DISODIUM": "EDETATE CALCIUM DISODIUM"
            },
            "VISTAPHARM INC.": {
              "KERR INSTA-CHAR": "KERR INSTA-CHAR",
              "KERR INSTA-CHAR IN SORBITOL": "KERR INSTA-CHAR IN SORBITOL"
            },
            "WELLSTAT THERAPEUTICS CORP": {
              "VISTOGARD": "VISTOGARD"
            },
            "WOODWARD PHARMA SERVICES": {
              "CHARCOAL ACTIVATED": "CHARCOAL ACTIVATED"
            },
            "X-GEN PHARMACEUTICALS, INC": {
              "FOMEPIZOLE": "FOMEPIZOLE"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "FOMEPIZOLE": "FOMEPIZOLE"
            }
          }
        },
        "Benzodiazepine Antagonists": {
          "Benzodiazepine Antagonists": {
            "A-S MEDICATION SOLUTIONS": {
              "FLUMAZENIL": "FLUMAZENIL"
            },
            "BAXTER HEALTHCARE CORP": {
              "FLUMAZENIL": "FLUMAZENIL"
            },
            "FRESENIUS KABI USA": {
              "FLUMAZENIL": "FLUMAZENIL"
            },
            "HIKMA": {
              "FLUMAZENIL": "FLUMAZENIL"
            },
            "MYLAN INSTITUTIONAL": {
              "FLUMAZENIL": "FLUMAZENIL"
            },
            "NOVAPLUS/HIKMA": {
              "FLUMAZENIL": "FLUMAZENIL"
            },
            "SAGENT PHARMACEUTICAL": {
              "FLUMAZENIL": "FLUMAZENIL"
            },
            "SANDOZ": {
              "FLUMAZENIL": "FLUMAZENIL"
            }
          }
        },
        "Opioid Antagonists": {
          "Opioid Antagonist Combinations": {
            "COMPLETE PHARMACY AND MEDICAL": {
              "NALTREXONE": "NALTREXONE"
            }
          },
          "Opioid Antagonists": {
            "A-S MEDICATION SOLUTIONS": {
              "NALOXONE HCL": "NALOXONE HCL",
              "NALTREXONE HCL": "NALTREXONE HCL",
              "NARCAN": "NARCAN"
            },
            "ACCORD HEALTHCARE": {
              "NALTREXONE HCL": "NALTREXONE HCL"
            },
            "ADAPT PHARMA": {
              "NARCAN": "NARCAN"
            },
            "AIDAREX PHARMACEUTICALS": {
              "NALTREXONE HCL": "NALTREXONE HCL"
            },
            "AKORN": {
              "NALOXONE HCL": "NALOXONE HCL"
            },
            "ALKERMES": {
              "VIVITROL": "VIVITROL"
            },
            "AMERICAN HEALTH PACKAGING": {
              "NALTREXONE HCL": "NALTREXONE HCL"
            },
            "AUROMEDICS PHARMA": {
              "NALOXONE HCL": "NALOXONE HCL"
            },
            "AVKARE": {
              "NALTREXONE HCL": "NALTREXONE HCL"
            },
            "BRYANT RANCH PREPACK": {
              "NALTREXONE HCL": "NALTREXONE HCL"
            },
            "CIVICA": {
              "NALOXONE HCL": "NALOXONE HCL"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "NALOXONE HCL": "NALOXONE HCL"
            },
            "DRUG ABUSE SCIENCES": {
              "NALTREXONE HCL": "NALTREXONE HCL"
            },
            "HIKMA": {
              "NALOXONE HCL": "NALOXONE HCL"
            },
            "HOSPIRA": {
              "NALOXONE HCL": "NALOXONE HCL"
            },
            "IJ THERAPEUTICS": {
              "NALOXONE HCL": "NALOXONE HCL"
            },
            "INTRNTL MEDICATION SYSTEM": {
              "NALOXONE HCL": "NALOXONE HCL"
            },
            "KALEO": {
              "EVZIO": "EVZIO"
            },
            "MAJOR PHARMACEUTICALS": {
              "NALTREXONE HCL": "NALTREXONE HCL"
            },
            "MALLINCKRODT PHARM": {
              "NALTREXONE HCL": "NALTREXONE HCL"
            },
            "MYLAN INSTITUTIONAL": {
              "NALOXONE HCL": "NALOXONE HCL"
            },
            "NOVAPLUS/HOSPIRA": {
              "NALOXONE HCL": "NALOXONE HCL"
            },
            "NOVAPLUS/MYLAN INSTITUTIONAL": {
              "NALOXONE HCL": "NALOXONE HCL"
            },
            "PDRX PHARMACEUTICAL": {
              "NALTREXONE HCL": "NALTREXONE HCL"
            },
            "PRECISION DOSE, INC": {
              "NALTREXONE HCL": "NALTREXONE HCL"
            },
            "PREFERRED PHARMACEUTICALS": {
              "NALTREXONE HCL": "NALTREXONE HCL"
            },
            "QUALITY CARE PRODUCTS": {
              "NARCAN": "NARCAN"
            },
            "SOMERSET THERAPEUTICS": {
              "NALOXONE HCL": "NALOXONE HCL"
            },
            "SUN PHARMACEUTICALS": {
              "NALTREXONE HCL": "NALTREXONE HCL"
            },
            "TAGI PHARMA": {
              "NALTREXONE HCL": "NALTREXONE HCL"
            },
            "TEVA PHARMACEUTICALS USA": {
              "NALTREXONE HCL": "NALTREXONE HCL"
            },
            "UNIT DOSE SERVICES": {
              "NALTREXONE HCL": "NALTREXONE HCL"
            }
          }
        },
        "Topical Antidotes": {
          "Topical Antidotes": {
            "PHARMASCIENCE": {
              "CALGONATE": "CALGONATE"
            }
          }
        }
      },
      "ANTIEMETICS": {
        "5-HT3 Receptor Antagonists": {
          "5-HT3 Receptor Antagonists": {
            "A-S MEDICATION SOLUTIONS": {
              "ONDANSETRON": "ONDANSETRON",
              "ONDANSETRON HCL": "ONDANSETRON HCL"
            },
            "ACCORD HEALTHCARE": {
              "ONDANSETRON HCL": "ONDANSETRON HCL",
              "PALONOSETRON HCL": "PALONOSETRON HCL"
            },
            "ACTAVIS": {
              "ONDANSETRON HCL": "ONDANSETRON HCL"
            },
            "AIDAREX PHARMACEUTICALS": {
              "ONDANSETRON": "ONDANSETRON",
              "ONDANSETRON HCL": "ONDANSETRON HCL"
            },
            "AKORN": {
              "GRANISETRON HCL": "GRANISETRON HCL"
            },
            "AMERICAN HEALTH PACKAGING": {
              "ONDANSETRON HCL": "ONDANSETRON HCL"
            },
            "AMNEAL PHARMACEUTICALS": {
              "ONDANSETRON HCL": "ONDANSETRON HCL"
            },
            "APHENA PHARMA SOLUTIONS": {
              "ONDANSETRON HCL": "ONDANSETRON HCL"
            },
            "APOTEX": {
              "ONDANSETRON HCL": "ONDANSETRON HCL",
              "PALONOSETRON HCL": "PALONOSETRON HCL"
            },
            "ASCEND LABORATORIES": {
              "ONDANSETRON HCL": "ONDANSETRON HCL"
            },
            "ATHENEX PHARMACEUTICAL": {
              "ONDANSETRON HCL": "ONDANSETRON HCL"
            },
            "ATLANTIC BIOLOGICALS": {
              "ONDANSETRON HCL": "ONDANSETRON HCL"
            },
            "AUROBINDO PHARMA": {
              "ONDANSETRON": "ONDANSETRON",
              "ONDANSETRON HCL": "ONDANSETRON HCL"
            },
            "AUROMEDICS PHARMA": {
              "ONDANSETRON HCL": "ONDANSETRON HCL",
              "PALONOSETRON HCL": "PALONOSETRON HCL"
            },
            "AVET PHARMACEUTICALS": {
              "ONDANSETRON HCL": "ONDANSETRON HCL"
            },
            "AVPAK": {
              "ONDANSETRON HCL": "ONDANSETRON HCL"
            },
            "BAXTER HEALTHCARE CORP": {
              "ONDANSETRON HCL": "ONDANSETRON HCL"
            },
            "BLENHEIM PHARMACAL": {
              "ONDANSETRON HCL": "ONDANSETRON HCL"
            },
            "BLUE POINT LABORATORIES": {
              "ONDANSETRON": "ONDANSETRON",
              "PALONOSETRON HCL": "PALONOSETRON HCL"
            },
            "BRECKENRIDGE": {
              "GRANISETRON HCL": "GRANISETRON HCL"
            },
            "BRYANT RANCH PREPACK": {
              "ONDANSETRON": "ONDANSETRON",
              "ONDANSETRON HCL": "ONDANSETRON HCL"
            },
            "CARDINAL HEALTH REPACKAGING SO": {
              "ONDANSETRON HCL": "ONDANSETRON HCL"
            },
            "CIPLA USA": {
              "PALONOSETRON HCL": "PALONOSETRON HCL"
            },
            "CIVICA": {
              "ONDANSETRON HCL": "ONDANSETRON HCL"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "ONDANSETRON": "ONDANSETRON",
              "ONDANSETRON HCL": "ONDANSETRON HCL"
            },
            "DENTON PHARMA": {
              "ONDANSETRON HCL": "ONDANSETRON HCL"
            },
            "DIRECT RX": {
              "ONDANSETRON": "ONDANSETRON",
              "ONDANSETRON HCL": "ONDANSETRON HCL"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "ONDANSETRON HCL": "ONDANSETRON HCL",
              "PALONOSETRON HCL": "PALONOSETRON HCL"
            },
            "EYWA PHARMA": {
              "ONDANSETRON HCL": "ONDANSETRON HCL"
            },
            "FORTOVIA THERAPEUTICS": {
              "ZUPLENZ": "ZUPLENZ"
            },
            "FOSUN PHARMA USA": {
              "ONDANSETRON HCL": "ONDANSETRON HCL"
            },
            "FRESENIUS KABI USA": {
              "GRANISETRON HCL": "GRANISETRON HCL",
              "ONDANSETRON HCL": "ONDANSETRON HCL",
              "PALONOSETRON HCL": "PALONOSETRON HCL"
            },
            "GLENMARK PHARMACEUTICALS": {
              "ONDANSETRON": "ONDANSETRON",
              "ONDANSETRON HCL": "ONDANSETRON HCL"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "ONDANSETRON": "ONDANSETRON",
              "ONDANSETRON HCL": "ONDANSETRON HCL"
            },
            "HELSINN THERAPEUTICS U.S.": {
              "ALOXI": "ALOXI"
            },
            "HERON THERAPEUTICS": {
              "SUSTOL": "SUSTOL"
            },
            "HIKMA": {
              "GRANISETRON HCL": "GRANISETRON HCL",
              "ONDANSETRON HCL": "ONDANSETRON HCL",
              "PALONOSETRON HCL": "PALONOSETRON HCL"
            },
            "HOSPIRA": {
              "ONDANSETRON HCL": "ONDANSETRON HCL",
              "PALONOSETRON HCL": "PALONOSETRON HCL"
            },
            "INGENUS PHARMACEUTICALS": {
              "PALONOSETRON HCL": "PALONOSETRON HCL"
            },
            "KAISER FOUNDATION HOSPITAL": {
              "ONDANSETRON HCL": "ONDANSETRON HCL"
            },
            "KYOWA KIRIN": {
              "SANCUSO": "SANCUSO"
            },
            "LIBERTY PHARMACEUTICAL": {
              "ONDANSETRON HCL": "ONDANSETRON HCL"
            },
            "MAJOR PHARMACEUTICALS": {
              "ONDANSETRON HCL": "ONDANSETRON HCL"
            },
            "MAS MANAGEMENT GROUP": {
              "ONDANSETRON": "ONDANSETRON"
            },
            "MYLAN": {
              "ONDANSETRON": "ONDANSETRON",
              "ONDANSETRON HCL": "ONDANSETRON HCL"
            },
            "MYLAN INSTITUTIONAL": {
              "GRANISETRON HCL": "GRANISETRON HCL",
              "ONDANSETRON HCL": "ONDANSETRON HCL",
              "PALONOSETRON HCL": "PALONOSETRON HCL"
            },
            "NEPHRON STERILE COMPOUNDING": {
              "ONDANSETRON HCL": "ONDANSETRON HCL",
              "ONDANSETRON HCL-NACL": "ONDANSETRON HCL-NACL"
            },
            "NORTHSTAR RX": {
              "GRANISETRON HCL": "GRANISETRON HCL",
              "ONDANSETRON HCL": "ONDANSETRON HCL",
              "PALONOSETRON HCL": "PALONOSETRON HCL"
            },
            "NOVAPLUS/HIKMA": {
              "ONDANSETRON HCL": "ONDANSETRON HCL"
            },
            "NOVAPLUS/SAGENT PHARMACEUTICAL": {
              "GRANISETRON HCL": "GRANISETRON HCL"
            },
            "NOVAPLUS/SANDOZ": {
              "PALONOSETRON HCL": "PALONOSETRON HCL"
            },
            "NOVARTIS": {
              "ZOFRAN": "ZOFRAN",
              "ZOFRAN ODT": "ZOFRAN ODT"
            },
            "NUCARE PHARMACEUTICALS": {
              "ONDANSETRON": "ONDANSETRON"
            },
            "ORCHIDPHARMA": {
              "GRANISETRON HCL": "GRANISETRON HCL"
            },
            "PAR PHARMACEUTICAL": {
              "GRANISETRON HCL": "GRANISETRON HCL"
            },
            "PDRX PHARMACEUTICAL": {
              "ONDANSETRON": "ONDANSETRON",
              "ONDANSETRON HCL": "ONDANSETRON HCL"
            },
            "PDRX PHARMACEUTICALS": {
              "ONDANSETRON HCL": "ONDANSETRON HCL"
            },
            "PHARMEDIUM SERVICES": {
              "ONDANSETRON HCL-DEXTROSE": "ONDANSETRON HCL-DEXTROSE",
              "ONDANSETRON HCL-NACL": "ONDANSETRON HCL-NACL"
            },
            "PRAELIA PHARMACEUTICALS": {
              "ZUPLENZ": "ZUPLENZ"
            },
            "PRECISION DOSE, INC": {
              "ONDANSETRON HCL": "ONDANSETRON HCL"
            },
            "PREFERRED PHARMACEUTICALS": {
              "ONDANSETRON": "ONDANSETRON",
              "ONDANSETRON HCL": "ONDANSETRON HCL"
            },
            "PROFICIENT RX": {
              "ONDANSETRON": "ONDANSETRON",
              "ONDANSETRON HCL": "ONDANSETRON HCL"
            },
            "QUALITY CARE": {
              "ONDANSETRON": "ONDANSETRON",
              "ONDANSETRON HCL": "ONDANSETRON HCL",
              "ZOFRAN": "ZOFRAN"
            },
            "QUALITY CARE PRODUCTS": {
              "ONDANSETRON": "ONDANSETRON",
              "ONDANSETRON HCL": "ONDANSETRON HCL"
            },
            "RISING PHARMACEUTICALS": {
              "ONDANSETRON": "ONDANSETRON",
              "ONDANSETRON HCL": "ONDANSETRON HCL"
            },
            "RXCHANGE CO": {
              "ONDANSETRON": "ONDANSETRON"
            },
            "SAGENT PHARMACEUTICAL": {
              "GRANISETRON HCL": "GRANISETRON HCL",
              "ONDANSETRON HCL": "ONDANSETRON HCL",
              "PALONOSETRON HCL": "PALONOSETRON HCL"
            },
            "SANDOZ": {
              "GRANISETRON HCL": "GRANISETRON HCL",
              "ONDANSETRON": "ONDANSETRON",
              "ONDANSETRON HCL": "ONDANSETRON HCL",
              "PALONOSETRON HCL": "PALONOSETRON HCL"
            },
            "SANOFI-AVENTIS U.S.": {
              "ANZEMET": "ANZEMET"
            },
            "SILARX": {
              "ONDANSETRON HCL": "ONDANSETRON HCL"
            },
            "SKY PACKAGING": {
              "ONDANSETRON HCL": "ONDANSETRON HCL"
            },
            "ST MARY'S MPP": {
              "ONDANSETRON": "ONDANSETRON",
              "ONDANSETRON HCL": "ONDANSETRON HCL"
            },
            "SUN PHARMACEUTICALS": {
              "ONDANSETRON": "ONDANSETRON",
              "ONDANSETRON HCL": "ONDANSETRON HCL"
            },
            "TARO": {
              "GRANISETRON HCL": "GRANISETRON HCL",
              "ONDANSETRON HCL": "ONDANSETRON HCL"
            },
            "TEVA PARENTERAL MEDICINES": {
              "ONDANSETRON HCL": "ONDANSETRON HCL",
              "PALONOSETRON HCL": "PALONOSETRON HCL"
            },
            "TEVA PHARMACEUTICALS USA": {
              "GRANISETRON HCL": "GRANISETRON HCL",
              "ONDANSETRON HCL": "ONDANSETRON HCL"
            },
            "THE MEDICINES COMPANY": {
              "ONDANSETRON HCL": "ONDANSETRON HCL"
            },
            "UNIT DOSE SERVICES": {
              "ONDANSETRON": "ONDANSETRON",
              "ONDANSETRON HCL": "ONDANSETRON HCL"
            },
            "VALIDUS PHARMACEUTICALS": {
              "ANZEMET": "ANZEMET"
            },
            "VIRTUS PHARMACEUTICALS": {
              "PALONOSETRON HCL": "PALONOSETRON HCL"
            }
          }
        },
        "Antiemetics - Anticholinergic": {
          "Antiemetics - Anticholinergic": {
            "A-S MEDICATION SOLUTIONS": {
              "DIMENHYDRINATE": "DIMENHYDRINATE",
              "MECLIZINE HCL": "MECLIZINE HCL",
              "TRIMETHOBENZAMIDE HCL": "TRIMETHOBENZAMIDE HCL"
            },
            "AIDAREX PHARMACEUTICALS": {
              "MECLIZINE HCL": "MECLIZINE HCL",
              "TRIMETHOBENZAMIDE HCL": "TRIMETHOBENZAMIDE HCL"
            },
            "ALTURA PHARMACEUTICALS": {
              "MECLIZINE HCL": "MECLIZINE HCL"
            },
            "AMERICAN HEALTH PACKAGING": {
              "MECLIZINE HCL": "MECLIZINE HCL"
            },
            "AMERICAN SALES COMPANY": {
              "MOTION SICKNESS RELIEF": "MOTION SICKNESS RELIEF"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP MOTION SICKNESS RELIEF": "GNP MOTION SICKNESS RELIEF"
            },
            "AMNEAL PHARMACEUTICALS": {
              "MECLIZINE HCL": "MECLIZINE HCL"
            },
            "APHENA PHARMA SOLUTIONS": {
              "MECLIZINE HCL": "MECLIZINE HCL"
            },
            "AVKARE": {
              "MECLIZINE HCL": "MECLIZINE HCL"
            },
            "AVPAK": {
              "MECLIZINE HCL": "MECLIZINE HCL"
            },
            "BAXTER HEALTHCARE CORPORATION": {
              "TRANSDERM-SCOP (1.5 MG)": "TRANSDERM-SCOP (1.5 MG)"
            },
            "BERGEN BRUNSWIG": {
              "GNP MOTION SICKNESS RELIEF": "GNP MOTION SICKNESS RELIEF"
            },
            "BLENHEIM PHARMACAL": {
              "MECLIZINE HCL": "MECLIZINE HCL"
            },
            "BRYANT RANCH PREPACK": {
              "MECLIZINE HCL": "MECLIZINE HCL",
              "TRIMETHOBENZAMIDE HCL": "TRIMETHOBENZAMIDE HCL"
            },
            "CADISTA": {
              "MECLIZINE HCL": "MECLIZINE HCL"
            },
            "CARDINAL HEALTH REPACKAGING SO": {
              "MECLIZINE HCL": "MECLIZINE HCL"
            },
            "CHAIN DRUG CONSORTIUM": {
              "MOTION SICKNESS": "MOTION SICKNESS",
              "MOTION SICKNESS RELIEF II": "MOTION SICKNESS RELIEF II"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC MOTION SICKNESS RELIEF": "QC MOTION SICKNESS RELIEF",
              "QC TRAVEL EASE": "QC TRAVEL EASE",
              "TRAVEL SICKNESS": "TRAVEL SICKNESS"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "MECLIZINE HCL": "MECLIZINE HCL"
            },
            "CONTRACT PHARMACAL CORPORATION": {
              "DIMENHYDRINATE": "DIMENHYDRINATE",
              "MECLIZINE HCL": "MECLIZINE HCL"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS MOTION SICKNESS": "CVS MOTION SICKNESS",
              "CVS MOTION SICKNESS II": "CVS MOTION SICKNESS II",
              "CVS MOTION SICKNESS RELIEF": "CVS MOTION SICKNESS RELIEF"
            },
            "DENTON PHARMA": {
              "MECLIZINE HCL": "MECLIZINE HCL"
            },
            "DIRECT RX": {
              "MECLIZINE HCL": "MECLIZINE HCL",
              "TRIMETHOBENZAMIDE HCL": "TRIMETHOBENZAMIDE HCL"
            },
            "EPIC PHARMA": {
              "MECLIZINE HCL": "MECLIZINE HCL"
            },
            "EQUALINE": {
              "EQ MOTION SICKNESS RELIEF": "EQ MOTION SICKNESS RELIEF",
              "EQL MOTION SICKNESS RELIEF": "EQL MOTION SICKNESS RELIEF"
            },
            "FRESENIUS KABI USA": {
              "DIMENHYDRINATE": "DIMENHYDRINATE"
            },
            "GEISS DESTIN & DUNN": {
              "GOODSENSE MOTION SICKNESS": "GOODSENSE MOTION SICKNESS"
            },
            "GERI-CARE": {
              "TRAVEL-EASE": "TRAVEL-EASE"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "TRANSDERM SCOP (1.5 MG)": "TRANSDERM SCOP (1.5 MG)",
              "TRANSDERM-SCOP (1.5 MG)": "TRANSDERM-SCOP (1.5 MG)"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "MECLIZINE HCL": "MECLIZINE HCL"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "MECLIZINE HCL": "MECLIZINE HCL",
              "TRIMETHOBENZAMIDE HCL": "TRIMETHOBENZAMIDE HCL"
            },
            "KMR PHARMACEUTICALS": {
              "MECLIZINE HCL": "MECLIZINE HCL"
            },
            "LEADER BRAND PRODUCTS": {
              "MOTION SICKNESS": "MOTION SICKNESS",
              "MOTION SICKNESS RELIEF": "MOTION SICKNESS RELIEF"
            },
            "LIBERTY PHARMACEUTICAL": {
              "DIMENHYDRINATE": "DIMENHYDRINATE",
              "MECLIZINE HCL": "MECLIZINE HCL"
            },
            "LUPIN PHARMACEUTICALS": {
              "TRIMETHOBENZAMIDE HCL": "TRIMETHOBENZAMIDE HCL"
            },
            "MAGNO-HUMPHRIES LABORATORIES": {
              "TRAV-TABS": "TRAV-TABS"
            },
            "MAJOR PHARMACEUTICALS": {
              "DRIMINATE": "DRIMINATE",
              "MECLIZINE HCL": "MECLIZINE HCL"
            },
            "MAS MANAGEMENT GROUP": {
              "MECLIZINE HCL": "MECLIZINE HCL"
            },
            "MCKESSON": {
              "HM MOTION RELIEF": "HM MOTION RELIEF",
              "HM MOTION SICKNESS": "HM MOTION SICKNESS",
              "HM MOTION SICKNESS RELIEF": "HM MOTION SICKNESS RELIEF"
            },
            "MCKESSON SUNMARK": {
              "SM MOTION SICKNESS": "SM MOTION SICKNESS",
              "SM MOTION SICKNESS RELIEF": "SM MOTION SICKNESS RELIEF"
            },
            "MEDIQUE PRODUCTS": {
              "MEDI-MECLIZINE": "MEDI-MECLIZINE"
            },
            "MEDTECH": {
              "DRAMAMINE": "DRAMAMINE",
              "DRAMAMINE FOR KIDS": "DRAMAMINE FOR KIDS",
              "DRAMAMINE LESS DROWSY": "DRAMAMINE LESS DROWSY"
            },
            "MEDVANTX": {
              "MECLIZINE HCL": "MECLIZINE HCL"
            },
            "MYLAN": {
              "MECLIZINE HCL": "MECLIZINE HCL",
              "SCOPOLAMINE": "SCOPOLAMINE"
            },
            "MYLAN INSTITUTIONAL": {
              "MECLIZINE HCL": "MECLIZINE HCL"
            },
            "NORTIC PHARMA": {
              "MECLIZINE HCL": "MECLIZINE HCL"
            },
            "NUCARE PHARMACEUTICALS": {
              "MECLIZINE HCL": "MECLIZINE HCL"
            },
            "PAR PHARMACEUTICAL": {
              "MECLIZINE HCL": "MECLIZINE HCL"
            },
            "PAR PHARMACEUTICALS": {
              "DIMENHYDRINATE": "DIMENHYDRINATE"
            },
            "PAR STERILE PRODUCTS": {
              "TIGAN": "TIGAN"
            },
            "PCCA": {
              "MECLIZINE HCL": "MECLIZINE HCL"
            },
            "PDRX PHARMACEUTICAL": {
              "MECLIZINE HCL": "MECLIZINE HCL",
              "TRANSDERM-SCOP (1.5 MG)": "TRANSDERM-SCOP (1.5 MG)",
              "TRIMETHOBENZAMIDE HCL": "TRIMETHOBENZAMIDE HCL"
            },
            "PERRIGO PHARMACEUTICALS": {
              "SCOPOLAMINE": "SCOPOLAMINE"
            },
            "PFIZER U.S.": {
              "TIGAN": "TIGAN"
            },
            "PHARBEST PHARMACEUTICALS": {
              "MECLIZINE HCL": "MECLIZINE HCL"
            },
            "PHARMPAK": {
              "MECLIZINE HCL": "MECLIZINE HCL"
            },
            "PLUS PHARMA": {
              "MECLIZINE HCL": "MECLIZINE HCL"
            },
            "PREFERRED PHARMACEUTICALS": {
              "MECLIZINE HCL": "MECLIZINE HCL"
            },
            "PROFICIENT RX": {
              "MECLIZINE HCL": "MECLIZINE HCL"
            },
            "QUALITY CARE": {
              "MECLIZINE HCL": "MECLIZINE HCL",
              "TRANSDERM-SCOP (1.5 MG)": "TRANSDERM-SCOP (1.5 MG)",
              "TRIMETHOBENZAMIDE HCL": "TRIMETHOBENZAMIDE HCL"
            },
            "QUALITY CARE PRODUCTS": {
              "MECLIZINE HCL": "MECLIZINE HCL"
            },
            "RELIABLE 1 LABS": {
              "MECLIZINE HCL": "MECLIZINE HCL"
            },
            "RICHMOND PHARMACEUTICALS": {
              "MECLIZINE HCL": "MECLIZINE HCL"
            },
            "RISING PHARMACEUTICALS": {
              "MECLIZINE HCL": "MECLIZINE HCL"
            },
            "RITE AID CORPORATION": {
              "RA MOTION SICKNESS RELIEF": "RA MOTION SICKNESS RELIEF"
            },
            "RUGBY LABORATORIES": {
              "MECLIZINE HCL": "MECLIZINE HCL",
              "TRAVEL SICKNESS": "TRAVEL SICKNESS"
            },
            "RXCHANGE CO": {
              "MECLIZINE HCL": "MECLIZINE HCL"
            },
            "SELECT BRAND": {
              "SB MOTION SICKNESS": "SB MOTION SICKNESS"
            },
            "SPECTRUM": {
              "MECLIZINE HCL": "MECLIZINE HCL"
            },
            "ST MARY'S MPP": {
              "MECLIZINE HCL": "MECLIZINE HCL"
            },
            "SUN PHARMACEUTICALS": {
              "TRIMETHOBENZAMIDE HCL": "TRIMETHOBENZAMIDE HCL"
            },
            "TEVA PHARMACEUTICALS USA": {
              "MOTION SICKNESS": "MOTION SICKNESS"
            },
            "THE GENERIC PHARMACEUTICAL COM": {
              "MECLIZINE HCL": "MECLIZINE HCL"
            },
            "TIME-CAP LABS": {
              "MOTION-TIME": "MOTION-TIME"
            },
            "TRIGEN LABORATORIES": {
              "MECLIZINE HCL": "MECLIZINE HCL"
            },
            "UNIT DOSE SERVICES": {
              "MECLIZINE HCL": "MECLIZINE HCL"
            },
            "WAL-MART": {
              "EQ MOTION SICKNESS": "EQ MOTION SICKNESS",
              "EQ MOTION SICKNESS RELIEF": "EQ MOTION SICKNESS RELIEF"
            },
            "WALGREENS": {
              "MOTION SICKNESS RELIEF": "MOTION SICKNESS RELIEF",
              "WAL-DRAM": "WAL-DRAM",
              "WAL-DRAM II": "WAL-DRAM II"
            },
            "WELLSPRING PHARMACEUTICAL CORP": {
              "BONINE": "BONINE"
            },
            "WILSHIRE PHARMACEUTICALS": {
              "MECLIZINE HCL": "MECLIZINE HCL"
            }
          }
        },
        "Antiemetics - Antidopaminergic": {
          "Antiemetics - Antidopaminergic": {
            "ACACIA PHARMA": {
              "BARHEMSYS": "BARHEMSYS"
            }
          }
        },
        "Antiemetics - Miscellaneous": {
          "Antiemetic Combinations": {
            "ACTAVIS PHARMA": {
              "DOXYLAMINE-PYRIDOXINE": "DOXYLAMINE-PYRIDOXINE"
            },
            "ALVA-AMCO": {
              "NAUZENE": "NAUZENE"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP NAUSEA RELIEF": "GNP NAUSEA RELIEF"
            },
            "ANALOG PHARMA": {
              "DOXYLAMINE-PYRIDOXINE": "DOXYLAMINE-PYRIDOXINE"
            },
            "APOTHECARY PRODUCTS, INC.": {
              "COCA COLA": "COCA COLA"
            },
            "BERGEN BRUNSWIG": {
              "GNP ANTI-NAUSEA": "GNP ANTI-NAUSEA"
            },
            "CHAIN DRUG CONSORTIUM": {
              "ANTI-NAUSEA": "ANTI-NAUSEA"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS NAUSEA RELIEF": "CVS NAUSEA RELIEF"
            },
            "DUCHESNAY USA": {
              "BONJESTA": "BONJESTA",
              "DICLEGIS": "DICLEGIS"
            },
            "EISAI": {
              "AKYNZEO": "AKYNZEO"
            },
            "EQUALINE": {
              "EQL ANTI-NAUSEA": "EQL ANTI-NAUSEA"
            },
            "HELSINN THERAPEUTICS U.S.": {
              "AKYNZEO": "AKYNZEO"
            },
            "KROGER COMPANY": {
              "ANTI-NAUSEA": "ANTI-NAUSEA"
            },
            "LEADER BRAND PRODUCTS": {
              "ANTI-NAUSEA": "ANTI-NAUSEA",
              "NAUSEA RELIEF": "NAUSEA RELIEF"
            },
            "MAJOR PHARMACEUTICALS": {
              "FORMULA EM": "FORMULA EM"
            },
            "MCKESSON": {
              "HM ANTI-NAUSEA": "HM ANTI-NAUSEA"
            },
            "MCKESSON SUNMARK": {
              "SM ANTI-NAUSEA": "SM ANTI-NAUSEA"
            },
            "MEDICINE SHOPPE": {
              "ANTI-NAUSEA": "ANTI-NAUSEA"
            },
            "MEDIQUE PRODUCTS": {
              "NAUSATROL": "NAUSATROL"
            },
            "MEDTECH": {
              "LITTLE TUMMYS NAUSEA RELIEF": "LITTLE TUMMYS NAUSEA RELIEF"
            },
            "MYLAN": {
              "DOXYLAMINE-PYRIDOXINE": "DOXYLAMINE-PYRIDOXINE"
            },
            "PAR PHARMACEUTICAL": {
              "DOXYLAMINE-PYRIDOXINE": "DOXYLAMINE-PYRIDOXINE"
            },
            "PERRIGO": {
              "GOODSENSE NAUSEA RELIEF": "GOODSENSE NAUSEA RELIEF"
            },
            "PERRIGO PHARMACEUTICALS": {
              "ANTI-NAUSEA": "ANTI-NAUSEA"
            },
            "REESE PHARMACEUTICAL": {
              "ANTI-NAUSEA/REKEMATOL": "ANTI-NAUSEA/REKEMATOL"
            },
            "RITE AID CORPORATION": {
              "RA ANTI-NAUSEA": "RA ANTI-NAUSEA"
            },
            "SELECT BRAND": {
              "SB ANTI-NAUSEA": "SB ANTI-NAUSEA"
            },
            "WAL-MART": {
              "EQ ANTI-NAUSEA": "EQ ANTI-NAUSEA"
            },
            "WALGREENS": {
              "ANTI-NAUSEA": "ANTI-NAUSEA",
              "NAUSEA CONTROL": "NAUSEA CONTROL"
            },
            "WELLSPRING PHARMACEUTICAL CORP": {
              "EMETROL": "EMETROL"
            }
          },
          "Antiemetics - Miscellaneous": {
            "ABBVIE": {
              "MARINOL": "MARINOL"
            },
            "ACTAVIS PHARMA": {
              "DRONABINOL": "DRONABINOL"
            },
            "AKORN": {
              "DRONABINOL": "DRONABINOL"
            },
            "AMERICAN HEALTH PACKAGING": {
              "DRONABINOL": "DRONABINOL"
            },
            "ASCEND LABORATORIES": {
              "DRONABINOL": "DRONABINOL"
            },
            "AVKARE": {
              "DRONABINOL": "DRONABINOL"
            },
            "CAMBER PHARMACEUTICALS": {
              "DRONABINOL": "DRONABINOL"
            },
            "INSYS THERAPEUTICS": {
              "SYNDROS": "SYNDROS"
            },
            "LANNETT": {
              "DRONABINOL": "DRONABINOL"
            },
            "MAJOR PHARMACEUTICALS": {
              "DRONABINOL": "DRONABINOL"
            },
            "MYLAN": {
              "DRONABINOL": "DRONABINOL"
            },
            "MYLAN SPECIALTY L.P.": {
              "CESAMET": "CESAMET"
            },
            "PAR PHARMACEUTICAL": {
              "DRONABINOL": "DRONABINOL"
            },
            "RHODES PHARMACEUTICAL": {
              "DRONABINOL": "DRONABINOL"
            },
            "THEPHARMANETWORK": {
              "MARINOL": "MARINOL"
            }
          }
        },
        "Substance P/Neurokinin 1 (NK1) Receptor Antagonists": {
          "Substance P/Neurokinin 1 (NK1) Receptor Antagonists": {
            "ACCORD HEALTHCARE": {
              "FOSAPREPITANT DIMEGLUMINE": "FOSAPREPITANT DIMEGLUMINE"
            },
            "ACTAVIS PHARMA": {
              "FOSAPREPITANT DIMEGLUMINE": "FOSAPREPITANT DIMEGLUMINE"
            },
            "APOTEX": {
              "FOSAPREPITANT DIMEGLUMINE": "FOSAPREPITANT DIMEGLUMINE"
            },
            "ATHENEX PHARMACEUTICAL": {
              "FOSAPREPITANT DIMEGLUMINE": "FOSAPREPITANT DIMEGLUMINE"
            },
            "BAXTER HEALTHCARE CORP": {
              "FOSAPREPITANT DIMEGLUMINE": "FOSAPREPITANT DIMEGLUMINE"
            },
            "BE PHARMACEUTICALS": {
              "FOSAPREPITANT DIMEGLUMINE": "FOSAPREPITANT DIMEGLUMINE"
            },
            "BLUE POINT LABORATORIES": {
              "FOSAPREPITANT DIMEGLUMINE": "FOSAPREPITANT DIMEGLUMINE"
            },
            "CELLTRION USA": {
              "FOSAPREPITANT DIMEGLUMINE": "FOSAPREPITANT DIMEGLUMINE"
            },
            "CIPLA USA": {
              "FOSAPREPITANT DIMEGLUMINE": "FOSAPREPITANT DIMEGLUMINE"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "FOSAPREPITANT DIMEGLUMINE": "FOSAPREPITANT DIMEGLUMINE"
            },
            "FRESENIUS KABI USA": {
              "FOSAPREPITANT DIMEGLUMINE": "FOSAPREPITANT DIMEGLUMINE"
            },
            "GLENMARK PHARMACEUTICALS": {
              "APREPITANT": "APREPITANT"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "EMEND": "EMEND"
            },
            "HERON THERAPEUTICS": {
              "CINVANTI": "CINVANTI"
            },
            "HIKMA": {
              "FOSAPREPITANT DIMEGLUMINE": "FOSAPREPITANT DIMEGLUMINE"
            },
            "LUPIN PHARMACEUTICALS": {
              "FOSAPREPITANT DIMEGLUMINE": "FOSAPREPITANT DIMEGLUMINE"
            },
            "MEITHEAL PHARMACEUTICALS": {
              "FOSAPREPITANT DIMEGLUMINE": "FOSAPREPITANT DIMEGLUMINE"
            },
            "MERCK SHARP & DOHME": {
              "EMEND": "EMEND",
              "EMEND TRI-PACK": "EMEND TRI-PACK"
            },
            "MYLAN INSTITUTIONAL": {
              "FOSAPREPITANT DIMEGLUMINE": "FOSAPREPITANT DIMEGLUMINE"
            },
            "NORTHSTAR RX": {
              "FOSAPREPITANT DIMEGLUMINE": "FOSAPREPITANT DIMEGLUMINE"
            },
            "NOVADOZ PHARMACEUTICALS": {
              "FOSAPREPITANT DIMEGLUMINE": "FOSAPREPITANT DIMEGLUMINE"
            },
            "SANDOZ": {
              "APREPITANT": "APREPITANT",
              "FOSAPREPITANT DIMEGLUMINE": "FOSAPREPITANT DIMEGLUMINE"
            },
            "TERSERA THERAPEUTICS": {
              "VARUBI (180 MG DOSE)": "VARUBI (180 MG DOSE)"
            },
            "TESARO": {
              "VARUBI": "VARUBI"
            }
          }
        }
      },
      "ANTIFUNGALS": {
        "Antifungal - Glucan Synthesis Inhibitors (Echinocandins)": {
          "Antifungal - Glucan Synthesis Inhibitors (Echinocandins)": {
            "ALMAJECT": {
              "CASPOFUNGIN ACETATE": "CASPOFUNGIN ACETATE"
            },
            "ALVOGEN": {
              "CASPOFUNGIN ACETATE": "CASPOFUNGIN ACETATE"
            },
            "ASTELLAS": {
              "MYCAMINE": "MYCAMINE"
            },
            "ATHENEX PHARMACEUTICAL": {
              "CASPOFUNGIN ACETATE": "CASPOFUNGIN ACETATE"
            },
            "FOSUN PHARMA USA": {
              "CASPOFUNGIN ACETATE": "CASPOFUNGIN ACETATE"
            },
            "FRESENIUS KABI USA": {
              "CASPOFUNGIN ACETATE": "CASPOFUNGIN ACETATE",
              "MICAFUNGIN SODIUM": "MICAFUNGIN SODIUM"
            },
            "MERCK SHARP & DOHME": {
              "CANCIDAS": "CANCIDAS"
            },
            "MYLAN INSTITUTIONAL": {
              "CASPOFUNGIN ACETATE": "CASPOFUNGIN ACETATE"
            },
            "NOVAPLUS/ASTELLAS": {
              "MYCAMINE": "MYCAMINE"
            },
            "PFIZER U.S.": {
              "ERAXIS": "ERAXIS"
            },
            "SANDOZ": {
              "CASPOFUNGIN ACETATE": "CASPOFUNGIN ACETATE"
            }
          }
        },
        "Antifungals": {
          "Antifungals": {
            "A-S MEDICATION SOLUTIONS": {
              "TERBINAFINE HCL": "TERBINAFINE HCL"
            },
            "ACTAVIS MID ATLANTIC": {
              "GRISEOFULVIN MICROSIZE": "GRISEOFULVIN MICROSIZE"
            },
            "AIDAREX PHARMACEUTICALS": {
              "NYSTATIN": "NYSTATIN",
              "TERBINAFINE HCL": "TERBINAFINE HCL"
            },
            "AMERICAN HEALTH PACKAGING": {
              "FLUCYTOSINE": "FLUCYTOSINE",
              "TERBINAFINE HCL": "TERBINAFINE HCL"
            },
            "AMNEAL PHARMACEUTICALS": {
              "GRISEOFULVIN ULTRAMICROSIZE": "GRISEOFULVIN ULTRAMICROSIZE"
            },
            "APHENA PHARMA SOLUTIONS": {
              "TERBINAFINE HCL": "TERBINAFINE HCL"
            },
            "ASTELLAS": {
              "AMBISOME": "AMBISOME"
            },
            "AUROBINDO PHARMA": {
              "FLUCYTOSINE": "FLUCYTOSINE",
              "TERBINAFINE HCL": "TERBINAFINE HCL"
            },
            "AVET PHARMACEUTICALS": {
              "NYSTATIN": "NYSTATIN"
            },
            "AVKARE": {
              "NYSTATIN": "NYSTATIN",
              "TERBINAFINE HCL": "TERBINAFINE HCL"
            },
            "BAUSCH HEALTH": {
              "ANCOBON": "ANCOBON",
              "GRIFULVIN V": "GRIFULVIN V",
              "GRIS-PEG": "GRIS-PEG"
            },
            "BIO-TECH": {
              "BIO-STATIN": "BIO-STATIN"
            },
            "BRECKENRIDGE": {
              "TERBINAFINE HCL": "TERBINAFINE HCL"
            },
            "BRYANT RANCH PREPACK": {
              "TERBINAFINE HCL": "TERBINAFINE HCL"
            },
            "CAMERON PHARMACEUTICALS": {
              "FLUCYTOSINE": "FLUCYTOSINE"
            },
            "CIPLA USA": {
              "GRISEOFULVIN MICROSIZE": "GRISEOFULVIN MICROSIZE",
              "TERBINAFINE HCL": "TERBINAFINE HCL"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "TERBINAFINE HCL": "TERBINAFINE HCL"
            },
            "DIRECT RX": {
              "TERBINAFINE HCL": "TERBINAFINE HCL"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "TERBINAFINE HCL": "TERBINAFINE HCL"
            },
            "EXELAN PHARMACEUTICALS": {
              "TERBINAFINE HCL": "TERBINAFINE HCL"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "TERBINAFINE HCL": "TERBINAFINE HCL"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "NYSTATIN": "NYSTATIN",
              "TERBINAFINE HCL": "TERBINAFINE HCL"
            },
            "HARRIS PHARMACEUTICAL": {
              "TERBINAFINE HCL": "TERBINAFINE HCL"
            },
            "HIKMA": {
              "FLUCYTOSINE": "FLUCYTOSINE"
            },
            "KADMON PHARMACEUTICALS": {
              "AMPHOTEC": "AMPHOTEC"
            },
            "LEADIANT BIOSCIENCES": {
              "ABELCET": "ABELCET"
            },
            "LIBERTY PHARMACEUTICAL": {
              "TERBINAFINE HCL": "TERBINAFINE HCL"
            },
            "LUPIN PHARMACEUTICALS": {
              "FLUCYTOSINE": "FLUCYTOSINE"
            },
            "MAJOR PHARMACEUTICALS": {
              "FLUCYTOSINE": "FLUCYTOSINE"
            },
            "NORTHSTAR RX": {
              "TERBINAFINE HCL": "TERBINAFINE HCL"
            },
            "NOVARTIS": {
              "LAMISIL": "LAMISIL"
            },
            "OCEANSIDE PHARMACEUTICALS": {
              "GRISEOFULVIN ULTRAMICROSIZE": "GRISEOFULVIN ULTRAMICROSIZE"
            },
            "PAR PHARMACEUTICAL": {
              "GRISEOFULVIN MICROSIZE": "GRISEOFULVIN MICROSIZE"
            },
            "PDRX PHARMACEUTICAL": {
              "GRIFULVIN V": "GRIFULVIN V",
              "GRIS-PEG": "GRIS-PEG",
              "LAMISIL": "LAMISIL",
              "NYSTATIN": "NYSTATIN",
              "TERBINAFINE HCL": "TERBINAFINE HCL"
            },
            "PERRIGO": {
              "NYSTATIN": "NYSTATIN"
            },
            "PERRIGO PHARMACEUTICALS": {
              "GRISEOFULVIN MICROSIZE": "GRISEOFULVIN MICROSIZE"
            },
            "PREFERRED PHARMACEUTICALS": {
              "TERBINAFINE HCL": "TERBINAFINE HCL"
            },
            "PROFICIENT RX": {
              "TERBINAFINE HCL": "TERBINAFINE HCL"
            },
            "QUALITY CARE": {
              "GRIS-PEG": "GRIS-PEG",
              "LAMISIL": "LAMISIL",
              "TERBINAFINE HCL": "TERBINAFINE HCL"
            },
            "QUALITY CARE PRODUCTS": {
              "TERBINAFINE HCL": "TERBINAFINE HCL"
            },
            "RISING PHARMACEUTICALS": {
              "FLUCYTOSINE": "FLUCYTOSINE",
              "GRISEOFULVIN MICROSIZE": "GRISEOFULVIN MICROSIZE",
              "GRISEOFULVIN ULTRAMICROSIZE": "GRISEOFULVIN ULTRAMICROSIZE"
            },
            "RXCHANGE CO": {
              "LAMISIL": "LAMISIL"
            },
            "SANDOZ": {
              "GRISEOFULVIN MICROSIZE": "GRISEOFULVIN MICROSIZE",
              "GRISEOFULVIN ULTRAMICROSIZE": "GRISEOFULVIN ULTRAMICROSIZE"
            },
            "SIGMAPHARM LABORATORIES": {
              "FLUCYTOSINE": "FLUCYTOSINE",
              "GRISEOFULVIN MICROSIZE": "GRISEOFULVIN MICROSIZE"
            },
            "SUN PHARMACEUTICALS": {
              "NYSTATIN": "NYSTATIN"
            },
            "TEVA PHARMACEUTICALS USA": {
              "GRISEOFULVIN MICROSIZE": "GRISEOFULVIN MICROSIZE",
              "NYSTATIN": "NYSTATIN",
              "TERBINAFINE HCL": "TERBINAFINE HCL"
            },
            "X-GEN PHARMACEUTICALS, INC": {
              "AMPHOTERICIN B": "AMPHOTERICIN B"
            }
          }
        },
        "Imidazole-Related Antifungals": {
          "Imidazoles": {
            "A-S MEDICATION SOLUTIONS": {
              "KETOCONAZOLE": "KETOCONAZOLE"
            },
            "AIDAREX PHARMACEUTICALS": {
              "KETOCONAZOLE": "KETOCONAZOLE"
            },
            "ALVIX LABORATORIES": {
              "MICONAZOLE": "MICONAZOLE"
            },
            "AMERICAN HEALTH PACKAGING": {
              "KETOCONAZOLE": "KETOCONAZOLE"
            },
            "BRYANT RANCH PREPACK": {
              "KETOCONAZOLE": "KETOCONAZOLE"
            },
            "BUREL PHARMACEUTICALS": {
              "KETOCONAZOLE": "KETOCONAZOLE"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "KETOCONAZOLE": "KETOCONAZOLE"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "KETOCONAZOLE": "KETOCONAZOLE"
            },
            "HUMCO": {
              "MICONAZOLE": "MICONAZOLE"
            },
            "LETCO MEDICAL": {
              "MICONAZOLE": "MICONAZOLE"
            },
            "LIBERTY PHARMACEUTICAL": {
              "KETOCONAZOLE": "KETOCONAZOLE"
            },
            "MEDISCA": {
              "MICONAZOLE": "MICONAZOLE"
            },
            "MEDVANTX": {
              "KETOCONAZOLE": "KETOCONAZOLE"
            },
            "MYLAN": {
              "KETOCONAZOLE": "KETOCONAZOLE"
            },
            "PCCA": {
              "MICONAZOLE": "MICONAZOLE"
            },
            "PDRX PHARMACEUTICAL": {
              "KETOCONAZOLE": "KETOCONAZOLE"
            },
            "PDRX PHARMACEUTICALS": {
              "KETOCONAZOLE": "KETOCONAZOLE"
            },
            "PREFERRED PHARMACEUTICALS": {
              "KETOCONAZOLE": "KETOCONAZOLE"
            },
            "QUALITY CARE": {
              "KETOCONAZOLE": "KETOCONAZOLE"
            },
            "SPECTRUM": {
              "MICONAZOLE": "MICONAZOLE"
            },
            "STRIDES PHARMA": {
              "KETOCONAZOLE": "KETOCONAZOLE"
            },
            "TARO": {
              "KETOCONAZOLE": "KETOCONAZOLE"
            },
            "TEVA PHARMACEUTICALS USA": {
              "KETOCONAZOLE": "KETOCONAZOLE"
            }
          },
          "Triazoles": {
            "A-S MEDICATION SOLUTIONS": {
              "DIFLUCAN": "DIFLUCAN",
              "FLUCONAZOLE": "FLUCONAZOLE"
            },
            "ACCORD HEALTHCARE": {
              "ITRACONAZOLE": "ITRACONAZOLE"
            },
            "ACETRIS HEALTH": {
              "FLUCONAZOLE": "FLUCONAZOLE"
            },
            "AIDAREX PHARMACEUTICALS": {
              "FLUCONAZOLE": "FLUCONAZOLE"
            },
            "AJANTA PHARMA LIMITED": {
              "VORICONAZOLE": "VORICONAZOLE"
            },
            "ALEMBIC PHARMACEUTICALS": {
              "ITRACONAZOLE": "ITRACONAZOLE"
            },
            "ALVOGEN": {
              "VORICONAZOLE": "VORICONAZOLE"
            },
            "AMERICAN HEALTH PACKAGING": {
              "FLUCONAZOLE": "FLUCONAZOLE",
              "ITRACONAZOLE": "ITRACONAZOLE",
              "POSACONAZOLE": "POSACONAZOLE",
              "VORICONAZOLE": "VORICONAZOLE"
            },
            "AMERINET/PFIZER": {
              "VFEND IV": "VFEND IV"
            },
            "AMNEAL PHARMACEUTICALS": {
              "ITRACONAZOLE": "ITRACONAZOLE",
              "VORICONAZOLE": "VORICONAZOLE"
            },
            "AQ PHARMACEUTICALS": {
              "SPORANOX": "SPORANOX"
            },
            "ASCEND LABORATORIES": {
              "ITRACONAZOLE": "ITRACONAZOLE"
            },
            "ASTELLAS": {
              "CRESEMBA": "CRESEMBA"
            },
            "AUROBINDO PHARMA": {
              "VORICONAZOLE": "VORICONAZOLE"
            },
            "AVKARE": {
              "VORICONAZOLE": "VORICONAZOLE"
            },
            "AVPAK": {
              "FLUCONAZOLE": "FLUCONAZOLE",
              "ITRACONAZOLE": "ITRACONAZOLE",
              "VORICONAZOLE": "VORICONAZOLE"
            },
            "BAXTER HEALTHCARE CORP": {
              "FLUCONAZOLE IN SODIUM CHLORIDE": "FLUCONAZOLE IN SODIUM CHLORIDE"
            },
            "BLENHEIM PHARMACAL": {
              "FLUCONAZOLE": "FLUCONAZOLE"
            },
            "BLUE POINT LABORATORIES": {
              "FLUCONAZOLE": "FLUCONAZOLE"
            },
            "BRYANT RANCH PREPACK": {
              "FLUCONAZOLE": "FLUCONAZOLE"
            },
            "CADISTA": {
              "ITRACONAZOLE": "ITRACONAZOLE"
            },
            "CAMBER PHARMACEUTICALS": {
              "ITRACONAZOLE": "ITRACONAZOLE"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "FLUCONAZOLE": "FLUCONAZOLE"
            },
            "DIRECT RX": {
              "FLUCONAZOLE": "FLUCONAZOLE"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "FLUCONAZOLE": "FLUCONAZOLE"
            },
            "FRESENIUS KABI USA": {
              "FLUCONAZOLE IN SODIUM CHLORIDE": "FLUCONAZOLE IN SODIUM CHLORIDE"
            },
            "GLENMARK PHARMACEUTICALS": {
              "FLUCONAZOLE": "FLUCONAZOLE",
              "VORICONAZOLE": "VORICONAZOLE"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "FLUCONAZOLE": "FLUCONAZOLE",
              "VORICONAZOLE": "VORICONAZOLE"
            },
            "GREENSTONE": {
              "FLUCONAZOLE": "FLUCONAZOLE",
              "VORICONAZOLE": "VORICONAZOLE"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "DIFLUCAN": "DIFLUCAN",
              "FLUCONAZOLE": "FLUCONAZOLE"
            },
            "HARRIS PHARMACEUTICAL": {
              "FLUCONAZOLE": "FLUCONAZOLE"
            },
            "HIKMA": {
              "FLUCONAZOLE": "FLUCONAZOLE",
              "FLUCONAZOLE IN DEXTROSE": "FLUCONAZOLE IN DEXTROSE",
              "FLUCONAZOLE IN SODIUM CHLORIDE": "FLUCONAZOLE IN SODIUM CHLORIDE"
            },
            "HOSPIRA": {
              "FLUCONAZOLE IN DEXTROSE": "FLUCONAZOLE IN DEXTROSE",
              "FLUCONAZOLE IN SODIUM CHLORIDE": "FLUCONAZOLE IN SODIUM CHLORIDE"
            },
            "JANSSEN": {
              "SPORANOX": "SPORANOX",
              "SPORANOX PULSEPAK": "SPORANOX PULSEPAK"
            },
            "JANSSEN PRODUCTS": {
              "SPORANOX": "SPORANOX"
            },
            "LANNETT": {
              "POSACONAZOLE": "POSACONAZOLE"
            },
            "LIBERTY PHARMACEUTICAL": {
              "FLUCONAZOLE": "FLUCONAZOLE"
            },
            "LUPIN PHARMACEUTICALS": {
              "VORICONAZOLE": "VORICONAZOLE"
            },
            "MAJOR PHARMACEUTICALS": {
              "FLUCONAZOLE": "FLUCONAZOLE",
              "VORICONAZOLE": "VORICONAZOLE"
            },
            "MAYNE PHARMA": {
              "TOLSURA": "TOLSURA"
            },
            "MERCK SHARP & DOHME": {
              "NOXAFIL": "NOXAFIL"
            },
            "MYLAN": {
              "FLUCONAZOLE": "FLUCONAZOLE",
              "ITRACONAZOLE": "ITRACONAZOLE",
              "VORICONAZOLE": "VORICONAZOLE"
            },
            "MYLAN INSTITUTIONAL": {
              "VORICONAZOLE": "VORICONAZOLE"
            },
            "NORTHSTAR RX": {
              "FLUCONAZOLE": "FLUCONAZOLE",
              "ITRACONAZOLE": "ITRACONAZOLE",
              "VORICONAZOLE": "VORICONAZOLE"
            },
            "NOVAPLUS/HOSPIRA": {
              "FLUCONAZOLE IN SODIUM CHLORIDE": "FLUCONAZOLE IN SODIUM CHLORIDE"
            },
            "NOVAPLUS/PFIZER U.S.": {
              "VFEND IV": "VFEND IV"
            },
            "NOVAPLUS/SAGENT PHARMACEUTICAL": {
              "FLUCONAZOLE IN SODIUM CHLORIDE": "FLUCONAZOLE IN SODIUM CHLORIDE"
            },
            "NUCARE PHARMACEUTICALS": {
              "DIFLUCAN": "DIFLUCAN",
              "FLUCONAZOLE": "FLUCONAZOLE"
            },
            "PACK PHARMACEUTICALS, LLC": {
              "FLUCONAZOLE": "FLUCONAZOLE"
            },
            "PAR PHARMACEUTICAL": {
              "ITRACONAZOLE": "ITRACONAZOLE",
              "POSACONAZOLE": "POSACONAZOLE"
            },
            "PATRIOT PHARMACEUTICALS LLC": {
              "ITRACONAZOLE": "ITRACONAZOLE"
            },
            "PDRX PHARMACEUTICAL": {
              "DIFLUCAN": "DIFLUCAN",
              "FLUCONAZOLE": "FLUCONAZOLE"
            },
            "PDRX PHARMACEUTICALS": {
              "FLUCONAZOLE": "FLUCONAZOLE"
            },
            "PFIZER U.S.": {
              "DIFLUCAN": "DIFLUCAN",
              "FLUCONAZOLE IN DEXTROSE": "FLUCONAZOLE IN DEXTROSE",
              "FLUCONAZOLE IN SODIUM CHLORIDE": "FLUCONAZOLE IN SODIUM CHLORIDE",
              "VFEND": "VFEND",
              "VFEND IV": "VFEND IV"
            },
            "PHARMEDIX": {
              "FLUCONAZOLE": "FLUCONAZOLE"
            },
            "PREFERRED PHARMACEUTICALS": {
              "FLUCONAZOLE": "FLUCONAZOLE"
            },
            "PROFICIENT RX": {
              "FLUCONAZOLE": "FLUCONAZOLE"
            },
            "QUALITY CARE": {
              "FLUCONAZOLE": "FLUCONAZOLE"
            },
            "QUALITY CARE PRODUCTS": {
              "FLUCONAZOLE": "FLUCONAZOLE"
            },
            "RENAISSANCE LAKEWOOD": {
              "FLUCONAZOLE IN SODIUM CHLORIDE": "FLUCONAZOLE IN SODIUM CHLORIDE"
            },
            "RENAISSANCE PHARMA": {
              "FLUCONAZOLE IN DEXTROSE": "FLUCONAZOLE IN DEXTROSE",
              "FLUCONAZOLE IN SODIUM CHLORIDE": "FLUCONAZOLE IN SODIUM CHLORIDE"
            },
            "RISING PHARMACEUTICALS": {
              "FLUCONAZOLE": "FLUCONAZOLE",
              "VORICONAZOLE": "VORICONAZOLE"
            },
            "RXCHANGE CO": {
              "FLUCONAZOLE": "FLUCONAZOLE"
            },
            "SAGENT PHARMACEUTICAL": {
              "FLUCONAZOLE IN SODIUM CHLORIDE": "FLUCONAZOLE IN SODIUM CHLORIDE"
            },
            "SANDOZ": {
              "ITRACONAZOLE": "ITRACONAZOLE",
              "VORICONAZOLE": "VORICONAZOLE"
            },
            "SEBELA PHARMACEUTICALS": {
              "ONMEL": "ONMEL"
            },
            "SKY PACKAGING": {
              "VORICONAZOLE": "VORICONAZOLE"
            },
            "SLATE RUN PHARMACEUTICALS": {
              "VORICONAZOLE": "VORICONAZOLE"
            },
            "SOLCO HEALTHCARE": {
              "VORICONAZOLE": "VORICONAZOLE"
            },
            "ST MARY'S MPP": {
              "FLUCONAZOLE": "FLUCONAZOLE"
            },
            "TARO": {
              "FLUCONAZOLE": "FLUCONAZOLE"
            },
            "TEVA PHARMACEUTICALS USA": {
              "FLUCONAZOLE": "FLUCONAZOLE",
              "VORICONAZOLE": "VORICONAZOLE"
            },
            "TORRENT PHARMACEUTICALS": {
              "ITRACONAZOLE": "ITRACONAZOLE"
            },
            "VIONA PHARMACEUTICALS": {
              "VORICONAZOLE": "VORICONAZOLE"
            },
            "X-GEN PHARMACEUTICALS, INC": {
              "VORICONAZOLE": "VORICONAZOLE"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "FLUCONAZOLE": "FLUCONAZOLE",
              "VORICONAZOLE": "VORICONAZOLE"
            }
          }
        }
      },
      "ANTIHISTAMINES": {
        "Antihistamines - Alkylamines": {
          "Antihistamines - Alkylamines": {
            "A-S MEDICATION SOLUTIONS": {
              "CHLORPHENIRAMINE MALEATE": "CHLORPHENIRAMINE MALEATE"
            },
            "AIDAREX PHARMACEUTICALS": {
              "CHLORPHENIRAMINE MALEATE": "CHLORPHENIRAMINE MALEATE"
            },
            "AKORN CONSUMER": {
              "DIABETIC TUSSIN ALLERGY": "DIABETIC TUSSIN ALLERGY"
            },
            "ALLEGIS PHARMACEUTICALS": {
              "HISTEX": "HISTEX",
              "HISTEX PD": "HISTEX PD",
              "HISTEX PDX": "HISTEX PDX"
            },
            "ALTURA PHARMACEUTICALS": {
              "CHLORPHENIRAMINE MALEATE": "CHLORPHENIRAMINE MALEATE"
            },
            "AMBI PHARMACEUTICALS": {
              "BROMPHENIRAMINE TANNATE": "BROMPHENIRAMINE TANNATE"
            },
            "APHENA PHARMA SOLUTIONS": {
              "CHLORPHENIRAMINE MALEATE": "CHLORPHENIRAMINE MALEATE"
            },
            "APOTHECA SUPPLY": {
              "BROMPHENIRAMINE MALEATE": "BROMPHENIRAMINE MALEATE"
            },
            "AURORA HEALTHCARE": {
              "ALLERGY": "ALLERGY"
            },
            "BAYER CONSUMER": {
              "CHLOR-TRIMETON": "CHLOR-TRIMETON",
              "CHLOR-TRIMETON ALLERGY": "CHLOR-TRIMETON ALLERGY"
            },
            "BERGEN BRUNSWIG": {
              "GNP ALLERGY": "GNP ALLERGY"
            },
            "BRYANT RANCH PREPACK": {
              "CHLORPHENIRAMINE MALEATE": "CHLORPHENIRAMINE MALEATE"
            },
            "CARWIN ASSOCIATES": {
              "PEDIAVENT": "PEDIAVENT",
              "RYCLORA": "RYCLORA"
            },
            "CHAIN DRUG CONSORTIUM": {
              "ALLERGY": "ALLERGY",
              "ALLERGY RELIEF": "ALLERGY RELIEF"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC ALLERGY RELIEF 4-HOUR": "QC ALLERGY RELIEF 4-HOUR",
              "QC CHLOR-PHENIRAMINE": "QC CHLOR-PHENIRAMINE"
            },
            "CONTRACT PHARMACAL CORPORATION": {
              "CHLORPHENIRAMINE MALEATE": "CHLORPHENIRAMINE MALEATE"
            },
            "CREEKWOOD PHARMACEUTICAL": {
              "TRIPROLIDINE HCL": "TRIPROLIDINE HCL"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS ALLERGY RELIEF": "CVS ALLERGY RELIEF"
            },
            "DIRECT RX": {
              "CHLORPHENIRAMINE MALEATE": "CHLORPHENIRAMINE MALEATE"
            },
            "EQUALINE": {
              "EQL ALLERGY": "EQL ALLERGY"
            },
            "FAGRON": {
              "CHLORPHENIRAMINE MALEATE": "CHLORPHENIRAMINE MALEATE"
            },
            "FOXLAND PHARMACEUTICALS": {
              "DEXCHLORPHENIRAMINE MALEATE": "DEXCHLORPHENIRAMINE MALEATE"
            },
            "GERI-CARE": {
              "ALLERGY RELIEF": "ALLERGY RELIEF"
            },
            "GM PHARMACEUTICALS": {
              "CHLORPHEN SR": "CHLORPHEN SR",
              "PEDIACLEAR ALLERGY CHILDRENS": "PEDIACLEAR ALLERGY CHILDRENS",
              "PEDIACLEAR PD CHILDRENS": "PEDIACLEAR PD CHILDRENS",
              "VANACLEAR PD": "VANACLEAR PD",
              "VANAHIST PD": "VANAHIST PD"
            },
            "JAYMAC PHARMACEUTICAL LLC": {
              "J-TAN PD": "J-TAN PD"
            },
            "KEY THERAPEUTICS": {
              "DR MANZANILLA ANTIHISTAMINE": "DR MANZANILLA ANTIHISTAMINE",
              "MICLARA LQ": "MICLARA LQ"
            },
            "KROGER COMPANY": {
              "ALLERGY 4 HOUR": "ALLERGY 4 HOUR"
            },
            "KVK TECH": {
              "CHLORPHENIRAMINE MALEATE ER": "CHLORPHENIRAMINE MALEATE ER"
            },
            "LEADER BRAND PRODUCTS": {
              "ALLERGY": "ALLERGY",
              "ALLERGY RELIEF": "ALLERGY RELIEF"
            },
            "LETCO MEDICAL": {
              "BROMPHENIRAMINE MALEATE": "BROMPHENIRAMINE MALEATE",
              "CHLORPHENIRAMINE MALEATE": "CHLORPHENIRAMINE MALEATE"
            },
            "LIBERTY PHARMACEUTICAL": {
              "CHLORPHENIRAMINE MALEATE": "CHLORPHENIRAMINE MALEATE"
            },
            "MAGNO-HUMPHRIES LABORATORIES": {
              "CHLORHIST": "CHLORHIST"
            },
            "MAJOR PHARMACEUTICALS": {
              "ALLERGY": "ALLERGY"
            },
            "MARLEX PHARMACEUTICALS": {
              "ALLERGY": "ALLERGY"
            },
            "MCKESSON": {
              "HM ALLERGY RELIEF": "HM ALLERGY RELIEF"
            },
            "MCKESSON SUNMARK": {
              "SM ALLERGY 4 HOUR": "SM ALLERGY 4 HOUR"
            },
            "MEDICINE SHOPPE": {
              "ALLERGY": "ALLERGY"
            },
            "METHOD PHARMACEUTICALS": {
              "M-HIST PD": "M-HIST PD"
            },
            "PCCA": {
              "BROMPHENIRAMINE MALEATE": "BROMPHENIRAMINE MALEATE",
              "CHLORPHENIRAMINE MALEATE": "CHLORPHENIRAMINE MALEATE"
            },
            "PDRX PHARMACEUTICAL": {
              "ALLER-CHLOR": "ALLER-CHLOR",
              "CHLORPHENIRAMINE MALEATE": "CHLORPHENIRAMINE MALEATE"
            },
            "PERRIGO": {
              "ALLERGY RELIEF": "ALLERGY RELIEF"
            },
            "PHARBEST PHARMACEUTICALS": {
              "PHARBECHLOR": "PHARBECHLOR"
            },
            "POLY PHARMACEUTICALS": {
              "ALA-HIST IR": "ALA-HIST IR"
            },
            "PREFERRED PHARMACEUTICALS": {
              "ALLERGY": "ALLERGY"
            },
            "QUALITY CARE PRODUCTS": {
              "ALLER-CHLOR": "ALLER-CHLOR"
            },
            "RELIABLE 1 LABS": {
              "CHLORPHENIRAMINE MALEATE": "CHLORPHENIRAMINE MALEATE"
            },
            "RESPA PHARMACEUTICALS, INC.": {
              "RESPA-BR": "RESPA-BR"
            },
            "RICHMOND PHARMACEUTICALS": {
              "CHLORPHENIRAMINE MALEATE": "CHLORPHENIRAMINE MALEATE"
            },
            "RITE AID CORPORATION": {
              "RA ALLERGY RELIEF": "RA ALLERGY RELIEF",
              "RA CHLORPHENIRAMINE MALEATE": "RA CHLORPHENIRAMINE MALEATE"
            },
            "RUGBY LABORATORIES": {
              "ALLER-CHLOR": "ALLER-CHLOR",
              "TRIPROLIDINE HCL": "TRIPROLIDINE HCL"
            },
            "SDA LABORATORIES INC": {
              "CHLORPHENIRAMINE MALEATE": "CHLORPHENIRAMINE MALEATE"
            },
            "SELECT BRAND": {
              "SB CHLORPHENIRAMINE": "SB CHLORPHENIRAMINE"
            },
            "SPECTRUM": {
              "BROMPHENIRAMINE MALEATE": "BROMPHENIRAMINE MALEATE",
              "CHLORPHENIRAMINE MALEATE": "CHLORPHENIRAMINE MALEATE"
            },
            "STRIDES USA CONSUMER": {
              "TRIPROLIDINE HCL": "TRIPROLIDINE HCL"
            },
            "TIME-CAP LABS": {
              "ALLERGY-TIME": "ALLERGY-TIME"
            },
            "TOPCO": {
              "ALLERGY": "ALLERGY"
            },
            "US COMPOUNDING": {
              "BROMPHENIRAMINE MALEATE": "BROMPHENIRAMINE MALEATE"
            },
            "WAL-MART": {
              "EQ CHLORTABS": "EQ CHLORTABS"
            },
            "WALGREENS": {
              "WAL-FINATE": "WAL-FINATE"
            },
            "WESTMINSTER PHARMACEUTICALS": {
              "TRIPROLIDINE HCL": "TRIPROLIDINE HCL"
            },
            "WOMEN'S CHOICE PHARMACEUTICALS": {
              "ED CHLORPED": "ED CHLORPED",
              "ED CHLORPED JR": "ED CHLORPED JR",
              "ED-CHLORTAN": "ED-CHLORTAN"
            },
            "WOODWARD PHARMA SERVICES": {
              "TRIPROLIDINE HCL": "TRIPROLIDINE HCL"
            }
          }
        },
        "Antihistamines - Ethanolamines": {
          "Antihistamines - Ethanolamines": {
            "A-S MEDICATION SOLUTIONS": {
              "BANOPHEN": "BANOPHEN",
              "DIPHENHYDRAMINE HCL": "DIPHENHYDRAMINE HCL",
              "PHARBEDRYL": "PHARBEDRYL"
            },
            "ADVANTICE HEALTH": {
              "PEDIACARE CHILDRENS ALLERGY": "PEDIACARE CHILDRENS ALLERGY"
            },
            "AIDAREX PHARMACEUTICALS": {
              "DIPHENHYDRAMINE HCL": "DIPHENHYDRAMINE HCL"
            },
            "AKRON PHARMA": {
              "ALLERGY CHILDRENS": "ALLERGY CHILDRENS"
            },
            "ALTURA PHARMACEUTICALS": {
              "DIPHENHYDRAMINE HCL": "DIPHENHYDRAMINE HCL"
            },
            "AMERICAN HEALTH PACKAGING": {
              "SILADRYL ALLERGY": "SILADRYL ALLERGY"
            },
            "AMERICAN PHARMA INGREDIENTS": {
              "DIPHENHYDRAMINE HCL": "DIPHENHYDRAMINE HCL"
            },
            "AMERICAN SALES COMPANY": {
              "ALLERGY MEDICATION": "ALLERGY MEDICATION",
              "ALLERGY MEDICATION CHILDRENS": "ALLERGY MEDICATION CHILDRENS"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP ALLERGY": "GNP ALLERGY",
              "GNP ALLERGY ANTIHISTAMINE": "GNP ALLERGY ANTIHISTAMINE",
              "GNP ALLERGY RELIEF": "GNP ALLERGY RELIEF"
            },
            "APOTHECA SUPPLY": {
              "DIPHENHYDRAMINE HCL": "DIPHENHYDRAMINE HCL"
            },
            "ARMAS PHARMACEUTICALS": {
              "DIPHENHYDRAMINE HCL": "DIPHENHYDRAMINE HCL"
            },
            "AUROHEALTH": {
              "AURODRYL ALLERGY CHILDRENS": "AURODRYL ALLERGY CHILDRENS"
            },
            "AURORA HEALTHCARE": {
              "TOTAL ALLERGY": "TOTAL ALLERGY",
              "TOTAL ALLERGY MEDICINE": "TOTAL ALLERGY MEDICINE"
            },
            "AVADEL PHARMACEUTICALS USA": {
              "KARBINAL ER": "KARBINAL ER"
            },
            "BASIC DRUGS": {
              "ANTI-HIST ALLERGY": "ANTI-HIST ALLERGY"
            },
            "BAYER CONSUMER": {
              "ALKA-SELTZER PLUS ALLERGY": "ALKA-SELTZER PLUS ALLERGY"
            },
            "BERGEN BRUNSWIG": {
              "GNP ALLERGY": "GNP ALLERGY",
              "GNP CHILDRENS ALLERGY": "GNP CHILDRENS ALLERGY",
              "GNP DAYHIST ALLERGY": "GNP DAYHIST ALLERGY"
            },
            "BIOCOMP PHARMA": {
              "CARBINOXAMINE MALEATE": "CARBINOXAMINE MALEATE"
            },
            "BLENHEIM PHARMACAL": {
              "DIPHENHYDRAMINE HCL": "DIPHENHYDRAMINE HCL"
            },
            "BONITA PHARMACEUTICALS": {
              "DIPHENHYDRAMINE HCL": "DIPHENHYDRAMINE HCL"
            },
            "BRECKENRIDGE": {
              "CARBINOXAMINE MALEATE": "CARBINOXAMINE MALEATE"
            },
            "BRYANT RANCH PREPACK": {
              "DIPHENHYDRAMINE HCL": "DIPHENHYDRAMINE HCL"
            },
            "CALIFORNIA PHARMACEUTICALS": {
              "DICOPANOL RAPIDPAQ": "DICOPANOL RAPIDPAQ"
            },
            "CARDINAL HEALTH REPACKAGING SO": {
              "DIPHENHYDRAMINE HCL": "DIPHENHYDRAMINE HCL"
            },
            "CARWIN ASSOCIATES": {
              "RYVENT": "RYVENT"
            },
            "CHAIN DRUG CONSORTIUM": {
              "ALLERGY CHILDRENS": "ALLERGY CHILDRENS",
              "ALLERGY RAPID MELTS CHILDRENS": "ALLERGY RAPID MELTS CHILDRENS",
              "CHILDRENS ALLERGY": "CHILDRENS ALLERGY",
              "CHILDRENS COMPLETE ALLERGY": "CHILDRENS COMPLETE ALLERGY",
              "COMPLETE ALLERGY": "COMPLETE ALLERGY",
              "COMPLETE ALLERGY MEDICINE": "COMPLETE ALLERGY MEDICINE"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "ALLERGY CHILDRENS": "ALLERGY CHILDRENS",
              "COMPLETE ALLERGY MEDICINE": "COMPLETE ALLERGY MEDICINE",
              "QC ALLERGY CHILDRENS": "QC ALLERGY CHILDRENS",
              "QC ALLERGY RELIEF": "QC ALLERGY RELIEF",
              "QC COMPLETE ALLERGY MEDICINE": "QC COMPLETE ALLERGY MEDICINE"
            },
            "CONTRACT PHARMACAL CORPORATION": {
              "DIPHENHYDRAMINE HCL": "DIPHENHYDRAMINE HCL"
            },
            "COSTCO WHOLESALE": {
              "KLS ALLERGY MEDICINE": "KLS ALLERGY MEDICINE"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS ALLERGY": "CVS ALLERGY",
              "CVS ALLERGY CHILDRENS": "CVS ALLERGY CHILDRENS",
              "CVS ALLERGY RELIEF": "CVS ALLERGY RELIEF",
              "CVS ALLERGY RELIEF ADULT": "CVS ALLERGY RELIEF ADULT",
              "CVS ALLERGY RELIEF CHILDRENS": "CVS ALLERGY RELIEF CHILDRENS",
              "CVS ALLERGY RELIEF NIGHTTIME": "CVS ALLERGY RELIEF NIGHTTIME",
              "CVS CHILDRENS ALLERGY": "CVS CHILDRENS ALLERGY",
              "CVS DYE-FREE ALLERGY": "CVS DYE-FREE ALLERGY"
            },
            "DIRECT RX": {
              "DIPHENHYDRAMINE HCL": "DIPHENHYDRAMINE HCL"
            },
            "EPIC PHARMA": {
              "DIPHENHYDRAMINE HCL": "DIPHENHYDRAMINE HCL"
            },
            "EQUALINE": {
              "EQL ALLERGY": "EQL ALLERGY",
              "EQL ALLERGY RELIEF": "EQL ALLERGY RELIEF",
              "EQL ALLERGY RELIEF CHILDRENS": "EQL ALLERGY RELIEF CHILDRENS",
              "EQL CHILDRENS ALLERGY": "EQL CHILDRENS ALLERGY",
              "EQL DAYHIST ALLERGY": "EQL DAYHIST ALLERGY"
            },
            "FAGRON": {
              "DIPHENHYDRAMINE HCL": "DIPHENHYDRAMINE HCL",
              "DOXYLAMINE SUCCINATE": "DOXYLAMINE SUCCINATE"
            },
            "FOXLAND PHARMACEUTICALS": {
              "CARBINOXAMINE MALEATE": "CARBINOXAMINE MALEATE",
              "DIPHEN": "DIPHEN"
            },
            "FREEDOM PHARMACEUTICALS": {
              "DIPHENHYDRAMINE HCL": "DIPHENHYDRAMINE HCL"
            },
            "FRESENIUS KABI USA": {
              "DIPHENHYDRAMINE HCL": "DIPHENHYDRAMINE HCL"
            },
            "FUSION PHARMACEUTICALS": {
              "DICOPANOL FUSEPAQ": "DICOPANOL FUSEPAQ"
            },
            "GEISS DESTIN & DUNN": {
              "ALLERGY RELIEF": "ALLERGY RELIEF",
              "ALLERGY RELIEF CHILDRENS": "ALLERGY RELIEF CHILDRENS",
              "GOODSENSE ALLERGY RELIEF": "GOODSENSE ALLERGY RELIEF"
            },
            "GERI-CARE": {
              "GERI-DRYL": "GERI-DRYL"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "TAVIST ALLERGY": "TAVIST ALLERGY",
              "TRIAMINIC COUGH/RUNNY NOSE": "TRIAMINIC COUGH/RUNNY NOSE"
            },
            "GM PHARMACEUTICALS": {
              "PEDIACLEAR COUGH CHILDRENS": "PEDIACLEAR COUGH CHILDRENS",
              "VANAMINE PD": "VANAMINE PD"
            },
            "H E B": {
              "H-E-B CHILDRENS ALLERGY": "H-E-B CHILDRENS ALLERGY"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "CLEMASTINE FUMARATE": "CLEMASTINE FUMARATE",
              "DIPHENHYDRAMINE HCL": "DIPHENHYDRAMINE HCL"
            },
            "HANNAFORD BROTHERS": {
              "ALLERGY": "ALLERGY",
              "CHILDRENS ALLERGY": "CHILDRENS ALLERGY"
            },
            "HAWTHORN PHARMACEUTICALS": {
              "ARBINOXA": "ARBINOXA"
            },
            "HIKMA": {
              "DIPHENHYDRAMINE HCL": "DIPHENHYDRAMINE HCL"
            },
            "HOSPIRA": {
              "DIPHENHYDRAMINE HCL": "DIPHENHYDRAMINE HCL"
            },
            "HUMCO": {
              "DIPHENHYDRAMINE HCL": "DIPHENHYDRAMINE HCL"
            },
            "HY-VEE": {
              "ALLERGY CHILDRENS": "ALLERGY CHILDRENS"
            },
            "KAISER FOUNDATION HOSPITAL": {
              "KP DIPHENHYDRAMINE HCL": "KP DIPHENHYDRAMINE HCL"
            },
            "KALCHEM INTERNATIONAL": {
              "DIPHENHYDRAMINE HCL": "DIPHENHYDRAMINE HCL"
            },
            "KROGER COMPANY": {
              "ALLERGY": "ALLERGY",
              "CHILDRENS ALLERGY": "CHILDRENS ALLERGY"
            },
            "LEADER BRAND PRODUCTS": {
              "ALLERGY": "ALLERGY",
              "ALLERGY CHILDRENS": "ALLERGY CHILDRENS",
              "ALLERGY RELIEF": "ALLERGY RELIEF",
              "ALLERGY RELIEF CHILDRENS": "ALLERGY RELIEF CHILDRENS",
              "ALLERHIST": "ALLERHIST",
              "ALLERHIST-1": "ALLERHIST-1",
              "DYE-FREE ALLERGY RELIEF": "DYE-FREE ALLERGY RELIEF"
            },
            "LETCO MEDICAL": {
              "DIPHENHYDRAMINE HCL": "DIPHENHYDRAMINE HCL"
            },
            "LIBERTY PHARMACEUTICAL": {
              "CLEMASTINE FUMARATE": "CLEMASTINE FUMARATE",
              "DIPHENHYDRAMINE HCL": "DIPHENHYDRAMINE HCL"
            },
            "MAGNO-HUMPHRIES LABORATORIES": {
              "ALER-CAP": "ALER-CAP",
              "ALERTAB": "ALERTAB",
              "COMPLETE ALLERGY MEDICINE": "COMPLETE ALLERGY MEDICINE"
            },
            "MAJOR PHARMACEUTICALS": {
              "BANOPHEN": "BANOPHEN",
              "DIPHENHYDRAMINE HCL": "DIPHENHYDRAMINE HCL"
            },
            "MARLEX PHARMACEUTICALS": {
              "DIPHENHYDRAMINE HCL": "DIPHENHYDRAMINE HCL"
            },
            "MCKESSON": {
              "HM ALLERGY": "HM ALLERGY",
              "HM ALLERGY CHILDRENS": "HM ALLERGY CHILDRENS",
              "HM ALLERGY MULTI SYMPTOM": "HM ALLERGY MULTI SYMPTOM",
              "HM ALLERGY RELIEF": "HM ALLERGY RELIEF",
              "HM ALLERGY RELIEF CHILDRENS": "HM ALLERGY RELIEF CHILDRENS"
            },
            "MCKESSON SUNMARK": {
              "SM ALLERGY RELIEF": "SM ALLERGY RELIEF",
              "SM ALLERGY RELIEF CHILDRENS": "SM ALLERGY RELIEF CHILDRENS"
            },
            "MCNEIL CONSUMER": {
              "BENADRYL ALLERGY": "BENADRYL ALLERGY",
              "BENADRYL ALLERGY CHILDRENS": "BENADRYL ALLERGY CHILDRENS"
            },
            "MEDICINE SHOPPE": {
              "MEDI-PHEDRYL": "MEDI-PHEDRYL"
            },
            "MEDIQUE PRODUCTS": {
              "DIPHEN": "DIPHEN"
            },
            "MEDISCA": {
              "DIPHENHYDRAMINE HCL": "DIPHENHYDRAMINE HCL"
            },
            "MEDVANTX": {
              "BANOPHEN": "BANOPHEN",
              "DIPHENHYDRAMINE HCL": "DIPHENHYDRAMINE HCL"
            },
            "MEIJER": {
              "ALLERGY CHILDRENS": "ALLERGY CHILDRENS",
              "MEIJER ANTIHISTAMINE ALLERGY": "MEIJER ANTIHISTAMINE ALLERGY"
            },
            "METHOD PHARMACEUTICALS": {
              "M-DRYL": "M-DRYL"
            },
            "MYLAN INSTITUTIONAL": {
              "DIPHENHYDRAMINE HCL": "DIPHENHYDRAMINE HCL"
            },
            "NATIONAL PHARMA INDUSTRIES": {
              "NARAMIN": "NARAMIN"
            },
            "NEIL LABS": {
              "COMPLETE ALLERGY MEDICATION": "COMPLETE ALLERGY MEDICATION",
              "COMPLETE ALLERGY RELIEF": "COMPLETE ALLERGY RELIEF",
              "DIPHENHYDRAMINE HCL": "DIPHENHYDRAMINE HCL"
            },
            "NEW WORLD IMPORTS": {
              "COMPLETE ALLERGY RELIEF": "COMPLETE ALLERGY RELIEF"
            },
            "NUCARE PHARMACEUTICALS": {
              "DIPHENHYDRAMINE HCL": "DIPHENHYDRAMINE HCL"
            },
            "P & G HEALTH": {
              "QLEARQUIL NIGHTTIME ALLERGY": "QLEARQUIL NIGHTTIME ALLERGY"
            },
            "PAR PHARMACEUTICAL": {
              "CARBINOXAMINE MALEATE": "CARBINOXAMINE MALEATE",
              "Q-DRYL": "Q-DRYL"
            },
            "PAR PHARMACEUTICALS": {
              "DIPHENHYDRAMINE HCL": "DIPHENHYDRAMINE HCL",
              "Q-DRYL": "Q-DRYL",
              "QUENALIN": "QUENALIN"
            },
            "PCCA": {
              "CLEMASTINE FUMARATE": "CLEMASTINE FUMARATE",
              "DIPHENHYDRAMINE HCL": "DIPHENHYDRAMINE HCL",
              "DOXYLAMINE SUCCINATE": "DOXYLAMINE SUCCINATE"
            },
            "PDRX PHARMACEUTICAL": {
              "BANOPHEN": "BANOPHEN",
              "CLEMASTINE FUMARATE": "CLEMASTINE FUMARATE",
              "DIPHENHYDRAMINE HCL": "DIPHENHYDRAMINE HCL"
            },
            "PERRIGO": {
              "ALLERGY RELIEF": "ALLERGY RELIEF"
            },
            "PERRIGO PHARMACEUTICALS": {
              "DAYHIST ALLERGY 12 HOUR RELIEF": "DAYHIST ALLERGY 12 HOUR RELIEF"
            },
            "PHARBEST PHARMACEUTICALS": {
              "PHARBEDRYL": "PHARBEDRYL"
            },
            "PHARMACEUTICAL ASSOCIATES": {
              "DIPHENHYDRAMINE HCL": "DIPHENHYDRAMINE HCL"
            },
            "PHARMPAK": {
              "DIPHENHYDRAMINE HCL": "DIPHENHYDRAMINE HCL"
            },
            "PLUS PHARMA": {
              "DIPHENHYDRAMINE HCL": "DIPHENHYDRAMINE HCL"
            },
            "PRECISION DOSE, INC": {
              "DIPHENHYDRAMINE HCL": "DIPHENHYDRAMINE HCL",
              "DIPHENHYDRAMINE HCL CHILDRENS": "DIPHENHYDRAMINE HCL CHILDRENS"
            },
            "PROFICIENT RX": {
              "BANOPHEN": "BANOPHEN",
              "DIPHENHYDRAMINE HCL": "DIPHENHYDRAMINE HCL"
            },
            "PUBLIX SUPER MARKETS INC.": {
              "PX ALLERGY": "PX ALLERGY",
              "PX DAYHIST ALLERGY": "PX DAYHIST ALLERGY"
            },
            "QUALITY CARE": {
              "DIPHENHYDRAMINE HCL": "DIPHENHYDRAMINE HCL"
            },
            "QUALITY CARE PRODUCTS": {
              "BANOPHEN": "BANOPHEN",
              "DIPHENHYDRAMINE HCL": "DIPHENHYDRAMINE HCL",
              "Q-DRYL": "Q-DRYL"
            },
            "REESE PHARMACEUTICAL": {
              "ALER-DRYL": "ALER-DRYL"
            },
            "RELIABLE 1 LABS": {
              "DIPHENHYDRAMINE HCL": "DIPHENHYDRAMINE HCL"
            },
            "RICHMOND PHARMACEUTICALS": {
              "DIPHENHYDRAMINE HCL": "DIPHENHYDRAMINE HCL"
            },
            "RITE AID CORPORATION": {
              "RA ALLERGY": "RA ALLERGY",
              "RA ALLERGY MEDICATION": "RA ALLERGY MEDICATION",
              "RA ALLERGY RELIEF": "RA ALLERGY RELIEF",
              "RA ALLERGY RELIEF CHILDRENS": "RA ALLERGY RELIEF CHILDRENS",
              "RA COMPLETE ALLERGY": "RA COMPLETE ALLERGY",
              "RA DIPHEDRYL ALLERGY": "RA DIPHEDRYL ALLERGY"
            },
            "RUGBY LABORATORIES": {
              "DIPHENHIST": "DIPHENHIST",
              "DIPHENHYDRAMINE HCL": "DIPHENHYDRAMINE HCL"
            },
            "RXCHANGE CO": {
              "DIPHENHYDRAMINE HCL": "DIPHENHYDRAMINE HCL"
            },
            "SANDOZ": {
              "CLEMASTINE FUMARATE": "CLEMASTINE FUMARATE",
              "DIPHENHYDRAMINE HCL": "DIPHENHYDRAMINE HCL"
            },
            "SCOT-TUSSIN": {
              "SCOT-TUSSIN ALLERGY RELIEF": "SCOT-TUSSIN ALLERGY RELIEF"
            },
            "SDA LABORATORIES INC": {
              "DIPHENHYDRAMINE HCL": "DIPHENHYDRAMINE HCL"
            },
            "SELECT BRAND": {
              "SB ALLERGY": "SB ALLERGY",
              "SB ALLERGY MEDICINE": "SB ALLERGY MEDICINE"
            },
            "SILARX": {
              "SILADRYL ALLERGY": "SILADRYL ALLERGY",
              "SILPHEN COUGH": "SILPHEN COUGH"
            },
            "SPECTRUM": {
              "DIPHENHYDRAMINE HCL": "DIPHENHYDRAMINE HCL",
              "DOXYLAMINE SUCCINATE": "DOXYLAMINE SUCCINATE"
            },
            "ST MARY'S MPP": {
              "BANOPHEN": "BANOPHEN",
              "DIPHENHYDRAMINE HCL": "DIPHENHYDRAMINE HCL"
            },
            "TARGET": {
              "TGT ALLERGY CHILDRENS": "TGT ALLERGY CHILDRENS",
              "TGT ALLERGY MELTS CHILDRENS": "TGT ALLERGY MELTS CHILDRENS",
              "TGT ALLERGY RELIEF": "TGT ALLERGY RELIEF",
              "TGT ALLERGY RELIEF CHILDRENS": "TGT ALLERGY RELIEF CHILDRENS"
            },
            "TEVA PHARMACEUTICALS USA": {
              "CLEMASTINE FUMARATE": "CLEMASTINE FUMARATE",
              "GENAHIST": "GENAHIST"
            },
            "TIME-CAP LABS": {
              "DIPHENHYDRAMINE HCL": "DIPHENHYDRAMINE HCL"
            },
            "TOPCO": {
              "ALLERGY RELIEF": "ALLERGY RELIEF",
              "ALLERGY RELIEF CHILDRENS": "ALLERGY RELIEF CHILDRENS",
              "DAYHIST ALLERGY 12 HOUR RELIEF": "DAYHIST ALLERGY 12 HOUR RELIEF",
              "MULTI-SYMPTOM ALLERGY": "MULTI-SYMPTOM ALLERGY"
            },
            "TRIVUE PHARMACEUTICAL": {
              "DI-PHEN": "DI-PHEN"
            },
            "UNIT DOSE SERVICES": {
              "DIPHENHYDRAMINE HCL": "DIPHENHYDRAMINE HCL"
            },
            "WAL-MART": {
              "EQ ALLERGY": "EQ ALLERGY",
              "EQ ALLERGY RELIEF": "EQ ALLERGY RELIEF",
              "EQ ALLERGY RELIEF CHILDRENS": "EQ ALLERGY RELIEF CHILDRENS",
              "EQ DAYHIST ALLERGY": "EQ DAYHIST ALLERGY"
            },
            "WALGREENS": {
              "ALLERGY RELIEF CHILDRENS": "ALLERGY RELIEF CHILDRENS",
              "ALLERGY RELIEF NIGHTTIME": "ALLERGY RELIEF NIGHTTIME",
              "WAL-DRYL ALLERGY": "WAL-DRYL ALLERGY",
              "WAL-DRYL ALLERGY CHILDRENS": "WAL-DRYL ALLERGY CHILDRENS",
              "WAL-DRYL ALLERGY REL CHILDRENS": "WAL-DRYL ALLERGY REL CHILDRENS",
              "WAL-HIST": "WAL-HIST"
            },
            "WESTMINSTER PHARMACEUTICALS": {
              "DIPHENHYDRAMINE HCL": "DIPHENHYDRAMINE HCL"
            },
            "ZYLERA PHARMACEUTICALS": {
              "KARBINAL ER": "KARBINAL ER"
            }
          }
        },
        "Antihistamines - Ethylenediamines": {
          "Antihistamines - Ethylenediamines": {
            "GM PHARMACEUTICALS": {
              "PEDIACLEAR 8 CHILDRENS": "PEDIACLEAR 8 CHILDRENS"
            },
            "LETCO MEDICAL": {
              "TRIPELENNAMINE HCL": "TRIPELENNAMINE HCL"
            },
            "PCCA": {
              "PYRILAMINE MALEATE": "PYRILAMINE MALEATE",
              "TRIPELENNAMINE HCL": "TRIPELENNAMINE HCL"
            },
            "SPECTRUM": {
              "PYRILAMINE MALEATE": "PYRILAMINE MALEATE",
              "TRIPELENNAMINE HCL": "TRIPELENNAMINE HCL"
            }
          }
        },
        "Antihistamines - Non-Sedating": {
          "Antihistamines - Non-Sedating": {
            "A-S MEDICATION SOLUTIONS": {
              "ALLEGRA ALLERGY": "ALLEGRA ALLERGY",
              "ALLERGY RELIEF/INDOOR/OUTDOOR": "ALLERGY RELIEF/INDOOR/OUTDOOR",
              "CETIRIZINE HCL": "CETIRIZINE HCL",
              "CHILDRENS LORATADINE": "CHILDRENS LORATADINE",
              "CLARINEX": "CLARINEX",
              "CLARITIN": "CLARITIN",
              "FEXOFENADINE HCL": "FEXOFENADINE HCL",
              "LEVOCETIRIZINE DIHYDROCHLORIDE": "LEVOCETIRIZINE DIHYDROCHLORIDE",
              "LORATADINE": "LORATADINE"
            },
            "ACTAVIS MID ATLANTIC": {
              "FEXOFENADINE HCL CHILDRENS": "FEXOFENADINE HCL CHILDRENS"
            },
            "AIDAREX PHARMACEUTICALS": {
              "CETIRIZINE HCL": "CETIRIZINE HCL",
              "FEXOFENADINE HCL": "FEXOFENADINE HCL",
              "LEVOCETIRIZINE DIHYDROCHLORIDE": "LEVOCETIRIZINE DIHYDROCHLORIDE",
              "LORATADINE": "LORATADINE",
              "LORATADINE CHILDRENS": "LORATADINE CHILDRENS"
            },
            "AKRON PHARMA": {
              "ALLERGY RELIEF": "ALLERGY RELIEF"
            },
            "AMERICAN HEALTH PACKAGING": {
              "CETIRIZINE HCL": "CETIRIZINE HCL",
              "FEXOFENADINE HCL": "FEXOFENADINE HCL",
              "LEVOCETIRIZINE DIHYDROCHLORIDE": "LEVOCETIRIZINE DIHYDROCHLORIDE",
              "LORATADINE": "LORATADINE"
            },
            "AMERICAN SALES COMPANY": {
              "ALL DAY ALLERGY": "ALL DAY ALLERGY",
              "ALL DAY ALLERGY CHILDRENS": "ALL DAY ALLERGY CHILDRENS",
              "ALLERGY RELIEF": "ALLERGY RELIEF",
              "ALLERGY RELIEF CHILDRENS": "ALLERGY RELIEF CHILDRENS"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP ALL DAY ALLERGY CHILDRENS": "GNP ALL DAY ALLERGY CHILDRENS",
              "GNP ALLERGY RELIEF": "GNP ALLERGY RELIEF",
              "GNP LORATADINE": "GNP LORATADINE",
              "GNP LORATADINE CHILDRENS": "GNP LORATADINE CHILDRENS"
            },
            "APHENA PHARMA SOLUTIONS": {
              "CETIRIZINE HCL": "CETIRIZINE HCL",
              "FEXOFENADINE HCL": "FEXOFENADINE HCL",
              "LORATADINE": "LORATADINE"
            },
            "APOTEX": {
              "CETIRIZINE HCL": "CETIRIZINE HCL",
              "LEVOCETIRIZINE DIHYDROCHLORIDE": "LEVOCETIRIZINE DIHYDROCHLORIDE",
              "LORATADINE": "LORATADINE"
            },
            "APOTHECA": {
              "CETIRIZINE HCL": "CETIRIZINE HCL"
            },
            "AQ PHARMACEUTICALS": {
              "CLARINEX": "CLARINEX"
            },
            "AUROBINDO PHARMA USA": {
              "FEXOFENADINE HCL": "FEXOFENADINE HCL"
            },
            "AUROHEALTH": {
              "ALLERGY RELIEF CETIRIZINE": "ALLERGY RELIEF CETIRIZINE",
              "FEXOFENADINE HCL": "FEXOFENADINE HCL",
              "LORATADINE": "LORATADINE"
            },
            "AVET PHARMACEUTICALS": {
              "CETIRIZINE HCL": "CETIRIZINE HCL"
            },
            "AVKARE": {
              "DESLORATADINE": "DESLORATADINE",
              "FEXOFENADINE HCL": "FEXOFENADINE HCL",
              "LEVOCETIRIZINE DIHYDROCHLORIDE": "LEVOCETIRIZINE DIHYDROCHLORIDE"
            },
            "AVPAK": {
              "DESLORATADINE": "DESLORATADINE",
              "FEXOFENADINE HCL": "FEXOFENADINE HCL",
              "LORATADINE": "LORATADINE"
            },
            "BAYER CONSUMER": {
              "CLARITIN": "CLARITIN",
              "CLARITIN ALLERGY CHILDRENS": "CLARITIN ALLERGY CHILDRENS",
              "CLARITIN CHILDRENS": "CLARITIN CHILDRENS",
              "CLARITIN REDITABS": "CLARITIN REDITABS"
            },
            "BELCHER PHARMACEUTICALS": {
              "DESLORATADINE": "DESLORATADINE"
            },
            "BERGEN BRUNSWIG": {
              "GNP ALL DAY ALLERGY": "GNP ALL DAY ALLERGY",
              "GNP ALL DAY ALLERGY CHILDRENS": "GNP ALL DAY ALLERGY CHILDRENS",
              "GNP ALLERGY RELIEF": "GNP ALLERGY RELIEF",
              "GNP LORATADINE": "GNP LORATADINE"
            },
            "BLENHEIM PHARMACAL": {
              "LORATADINE": "LORATADINE"
            },
            "BLUE POINT LABORATORIES": {
              "CETIRIZINE HCL": "CETIRIZINE HCL",
              "FEXOFENADINE HCL": "FEXOFENADINE HCL",
              "LORATADINE": "LORATADINE"
            },
            "BRECKENRIDGE": {
              "CETIRIZINE HCL": "CETIRIZINE HCL"
            },
            "BRYANT RANCH PREPACK": {
              "ALLERGY RELIEF": "ALLERGY RELIEF",
              "CETIRIZINE HCL": "CETIRIZINE HCL",
              "FEXOFENADINE HCL": "FEXOFENADINE HCL",
              "LORATADINE": "LORATADINE"
            },
            "CADISTA": {
              "CETIRIZINE HCL CHILDRENS": "CETIRIZINE HCL CHILDRENS"
            },
            "CAMBER CONSUMER CARE": {
              "ALLERGY RELIEF": "ALLERGY RELIEF",
              "ALLERGY RELIEF CHILDRENS": "ALLERGY RELIEF CHILDRENS",
              "ALLERGY RELIEF/INDOOR/OUTDOOR": "ALLERGY RELIEF/INDOOR/OUTDOOR"
            },
            "CAMBER PHARMACEUTICALS": {
              "LEVOCETIRIZINE DIHYDROCHLORIDE": "LEVOCETIRIZINE DIHYDROCHLORIDE"
            },
            "CARDINAL HEALTH REPACKAGING SO": {
              "CETIRIZINE HCL": "CETIRIZINE HCL",
              "LORATADINE": "LORATADINE"
            },
            "CARLSBAD TECHNOLOGIES": {
              "LEVOCETIRIZINE DIHYDROCHLORIDE": "LEVOCETIRIZINE DIHYDROCHLORIDE"
            },
            "CHAIN DRUG CONSORTIUM": {
              "ALL DAY ALLERGY": "ALL DAY ALLERGY",
              "ALLERGY RELIEF": "ALLERGY RELIEF",
              "CETIRIZINE HCL": "CETIRIZINE HCL",
              "CETIRIZINE HCL CHILDRENS": "CETIRIZINE HCL CHILDRENS",
              "CHILDRENS LORATADINE": "CHILDRENS LORATADINE",
              "FEXOFENADINE HCL": "FEXOFENADINE HCL",
              "FEXOFENADINE HCL CHILDRENS": "FEXOFENADINE HCL CHILDRENS",
              "LORATADINE CHILDRENS": "LORATADINE CHILDRENS"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC ALL DAY ALLERGY": "QC ALL DAY ALLERGY",
              "QC ALLERGY RELIEF": "QC ALLERGY RELIEF",
              "QC ALLERGY RELIEF CHILDRENS": "QC ALLERGY RELIEF CHILDRENS",
              "QC CHILDRENS ALLERGY": "QC CHILDRENS ALLERGY",
              "QC FEXOFENADINE HYDROCHLORIDE": "QC FEXOFENADINE HYDROCHLORIDE",
              "QC LORATADINE ALLERGY RELIEF": "QC LORATADINE ALLERGY RELIEF"
            },
            "CHATTEM INC": {
              "ALLEGRA ALLERGY": "ALLEGRA ALLERGY",
              "ALLEGRA ALLERGY CHILDRENS": "ALLEGRA ALLERGY CHILDRENS",
              "XYZAL ALLERGY 24HR": "XYZAL ALLERGY 24HR",
              "XYZAL ALLERGY 24HR CHILDRENS": "XYZAL ALLERGY 24HR CHILDRENS"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "CETIRIZINE HCL": "CETIRIZINE HCL",
              "FEXOFENADINE HCL": "FEXOFENADINE HCL",
              "LORATADINE": "LORATADINE"
            },
            "COSTCO WHOLESALE": {
              "KLS ALLER-FEX": "KLS ALLER-FEX",
              "KLS ALLER-TEC": "KLS ALLER-TEC",
              "KLS ALLER-TEC CHILDRENS": "KLS ALLER-TEC CHILDRENS",
              "KLS ALLERCLEAR": "KLS ALLERCLEAR"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS ALLERGY RELIEF": "CVS ALLERGY RELIEF",
              "CVS ALLERGY RELIEF CHILDRENS": "CVS ALLERGY RELIEF CHILDRENS",
              "CVS INDOOR/OUTDOOR ALLERGY RLF": "CVS INDOOR/OUTDOOR ALLERGY RLF",
              "CVS LORATADINE": "CVS LORATADINE"
            },
            "DIRECT RX": {
              "CETIRIZINE HCL": "CETIRIZINE HCL",
              "FEXOFENADINE HCL": "FEXOFENADINE HCL",
              "LORATADINE": "LORATADINE"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "CETIRIZINE HCL": "CETIRIZINE HCL",
              "DESLORATADINE": "DESLORATADINE",
              "FEXOFENADINE HCL": "FEXOFENADINE HCL",
              "LEVOCETIRIZINE DIHYDROCHLORIDE": "LEVOCETIRIZINE DIHYDROCHLORIDE"
            },
            "EQUALINE": {
              "EQL ALL DAY ALLERGY": "EQL ALL DAY ALLERGY",
              "EQL ALL DAY ALLERGY CHILDRENS": "EQL ALL DAY ALLERGY CHILDRENS",
              "EQL ALLER-EASE": "EQL ALLER-EASE",
              "EQL ALLERGY CHILDRENS": "EQL ALLERGY CHILDRENS",
              "EQL ALLERGY RELIEF": "EQL ALLERGY RELIEF",
              "EQL CHILDRENS LORATADINE": "EQL CHILDRENS LORATADINE"
            },
            "GEISS DESTIN & DUNN": {
              "GOODSENSE ALL DAY ALLERGY": "GOODSENSE ALL DAY ALLERGY",
              "GOODSENSE ALLERGY RELIEF": "GOODSENSE ALLERGY RELIEF"
            },
            "GERI-CARE": {
              "ALLERGY RELIEF": "ALLERGY RELIEF",
              "ALLERGY RELIEF LORATADINE": "ALLERGY RELIEF LORATADINE",
              "LORATADINE": "LORATADINE"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "ALAVERT": "ALAVERT",
              "LORATADINE": "LORATADINE",
              "TRIAMINIC ALLERCHEWS": "TRIAMINIC ALLERCHEWS"
            },
            "GLENMARK PHARMACEUTICALS": {
              "LEVOCETIRIZINE DIHYDROCHLORIDE": "LEVOCETIRIZINE DIHYDROCHLORIDE"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "FEXOFENADINE HCL": "FEXOFENADINE HCL"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "CETIRIZINE HCL": "CETIRIZINE HCL",
              "CLARINEX": "CLARINEX",
              "LORATADINE": "LORATADINE"
            },
            "IMDS INC": {
              "LORATADINE": "LORATADINE"
            },
            "KAISER FOUNDATION HOSPITAL": {
              "KP CETIRIZINE HCL": "KP CETIRIZINE HCL",
              "KP FEXOFENADINE HCL": "KP FEXOFENADINE HCL",
              "KP LORATADINE": "KP LORATADINE"
            },
            "KROGER COMPANY": {
              "ALL DAY ALLERGY": "ALL DAY ALLERGY",
              "ALL DAY ALLERGY CHILDRENS": "ALL DAY ALLERGY CHILDRENS",
              "ALLERGY RELIEF": "ALLERGY RELIEF"
            },
            "LANNETT": {
              "LEVOCETIRIZINE DIHYDROCHLORIDE": "LEVOCETIRIZINE DIHYDROCHLORIDE"
            },
            "LEADER BRAND PRODUCTS": {
              "24HR ALLERGY RELIEF": "24HR ALLERGY RELIEF",
              "ALL DAY ALLERGY": "ALL DAY ALLERGY",
              "ALL DAY ALLERGY CHILDRENS": "ALL DAY ALLERGY CHILDRENS",
              "ALLER-EASE": "ALLER-EASE",
              "ALLER-EASE CHILDRENS": "ALLER-EASE CHILDRENS",
              "ALLERGY CHILDRENS": "ALLERGY CHILDRENS",
              "ALLERGY REL CHILD (CETIRIZINE)": "ALLERGY REL CHILD (CETIRIZINE)",
              "ALLERGY REL CHILD (LORATADINE)": "ALLERGY REL CHILD (LORATADINE)",
              "ALLERGY RELIEF": "ALLERGY RELIEF",
              "ALLERGY RELIEF CHILD": "ALLERGY RELIEF CHILD",
              "ALLERGY RELIEF CHILDRENS": "ALLERGY RELIEF CHILDRENS",
              "LORATADINE": "LORATADINE"
            },
            "LIBERTY PHARMACEUTICAL": {
              "CETIRIZINE HCL": "CETIRIZINE HCL",
              "FEXOFENADINE HCL": "FEXOFENADINE HCL",
              "LORATADINE": "LORATADINE"
            },
            "LIL DRUG STORE PRODUCTS": {
              "CLARITIN": "CLARITIN"
            },
            "LUPIN PHARMACEUTICALS": {
              "DESLORATADINE": "DESLORATADINE"
            },
            "MACLEODS PHARMACEUTICALS": {
              "LEVOCETIRIZINE DIHYDROCHLORIDE": "LEVOCETIRIZINE DIHYDROCHLORIDE"
            },
            "MAGNO-HUMPHRIES LABORATORIES": {
              "CETIRIZINE HCL": "CETIRIZINE HCL",
              "LORATADINE": "LORATADINE"
            },
            "MAJOR PHARMACEUTICALS": {
              "ALL DAY ALLERGY": "ALL DAY ALLERGY",
              "ALL DAY ALLERGY CHILDRENS": "ALL DAY ALLERGY CHILDRENS",
              "ALLERGY": "ALLERGY",
              "ALLERGY RELIEF": "ALLERGY RELIEF",
              "ALLERGY RELIEF FOR KIDS": "ALLERGY RELIEF FOR KIDS",
              "CETIRIZINE HCL": "CETIRIZINE HCL",
              "CETIRIZINE HCL ALLERGY CHILD": "CETIRIZINE HCL ALLERGY CHILD",
              "FEXOFENADINE HCL": "FEXOFENADINE HCL",
              "LORATADINE": "LORATADINE",
              "LORATADINE CHILDRENS": "LORATADINE CHILDRENS"
            },
            "MARLEX PHARMACEUTICALS": {
              "LEVOCETIRIZINE DIHYDROCHLORIDE": "LEVOCETIRIZINE DIHYDROCHLORIDE"
            },
            "MCKESSON": {
              "HM ALL DAY ALLERGY": "HM ALL DAY ALLERGY",
              "HM ALL DAY ALLERGY CHILDRENS": "HM ALL DAY ALLERGY CHILDRENS",
              "HM ALLERGY COMPLETE CHILDRENS": "HM ALLERGY COMPLETE CHILDRENS",
              "HM ALLERGY RELIEF": "HM ALLERGY RELIEF",
              "HM CETIRIZINE HCL": "HM CETIRIZINE HCL",
              "HM CETIRIZINE HCL CHILDRENS": "HM CETIRIZINE HCL CHILDRENS",
              "HM FEXOFENADINE HCL": "HM FEXOFENADINE HCL",
              "HM LORATADINE": "HM LORATADINE",
              "HM LORATADINE CHILDRENS": "HM LORATADINE CHILDRENS"
            },
            "MCKESSON SUNMARK": {
              "SM ALL DAY ALLERGY": "SM ALL DAY ALLERGY",
              "SM ALL DAY ALLERGY CHILDRENS": "SM ALL DAY ALLERGY CHILDRENS",
              "SM ALLERGY CHILDRENS": "SM ALLERGY CHILDRENS",
              "SM ALLERGY RELIEF": "SM ALLERGY RELIEF",
              "SM CHILDRENS LORATADINE": "SM CHILDRENS LORATADINE",
              "SM FEXOFENADINE HCL": "SM FEXOFENADINE HCL",
              "SM LORATADINE": "SM LORATADINE",
              "SM LORATADINE ALLERGY RELIEF": "SM LORATADINE ALLERGY RELIEF"
            },
            "MCNEIL CONSUMER": {
              "ZYRTEC ALLERGY": "ZYRTEC ALLERGY",
              "ZYRTEC ALLERGY CHILDRENS": "ZYRTEC ALLERGY CHILDRENS",
              "ZYRTEC CHILDRENS ALLERGY": "ZYRTEC CHILDRENS ALLERGY"
            },
            "MEDICINE SHOPPE": {
              "ALL DAY ALLERGY": "ALL DAY ALLERGY",
              "LORATADINE": "LORATADINE",
              "LORATADINE ALLERGY RELIEF": "LORATADINE ALLERGY RELIEF"
            },
            "MEDIQUE PRODUCTS": {
              "LORADAMED": "LORADAMED"
            },
            "MEIJER": {
              "ALLER-EASE": "ALLER-EASE",
              "ALLERGY 24HOUR INDOOR/OUTDOOR": "ALLERGY 24HOUR INDOOR/OUTDOOR",
              "ALLERGY RELIEF": "ALLERGY RELIEF",
              "MEIJER ALLERGY RELIEF": "MEIJER ALLERGY RELIEF",
              "MEIJER LORATADINE": "MEIJER LORATADINE"
            },
            "MERCK SHARP & DOHME": {
              "CLARINEX": "CLARINEX"
            },
            "MICRO LABORATORIES": {
              "LEVOCETIRIZINE DIHYDROCHLORIDE": "LEVOCETIRIZINE DIHYDROCHLORIDE"
            },
            "MYLAN": {
              "CETIRIZINE HCL": "CETIRIZINE HCL",
              "DESLORATADINE": "DESLORATADINE",
              "FEXOFENADINE HCL": "FEXOFENADINE HCL"
            },
            "MYLAN INSTITUTIONAL": {
              "CETIRIZINE HCL": "CETIRIZINE HCL",
              "FEXOFENADINE HCL": "FEXOFENADINE HCL",
              "LORATADINE": "LORATADINE"
            },
            "NORTHSTAR RX": {
              "CETIRIZINE HCL": "CETIRIZINE HCL",
              "FEXOFENADINE HCL": "FEXOFENADINE HCL",
              "LORATADINE": "LORATADINE"
            },
            "NUCARE PHARMACEUTICALS": {
              "CETIRIZINE HCL": "CETIRIZINE HCL",
              "LORATADINE": "LORATADINE"
            },
            "OHM LABS": {
              "ALLERGY RELIEF": "ALLERGY RELIEF",
              "CETIRIZINE HCL": "CETIRIZINE HCL",
              "CETIRIZINE HCL CHILDRENS": "CETIRIZINE HCL CHILDRENS",
              "LORATADINE": "LORATADINE",
              "LORATADINE CHILDRENS": "LORATADINE CHILDRENS"
            },
            "P & G HEALTH": {
              "QLEARQUIL 24 HOUR RELIEF": "QLEARQUIL 24 HOUR RELIEF"
            },
            "PACK PHARMACEUTICALS, LLC": {
              "CETIRIZINE HCL": "CETIRIZINE HCL"
            },
            "PDRX PHARMACEUTICAL": {
              "CETIRIZINE HCL": "CETIRIZINE HCL",
              "CLARINEX": "CLARINEX",
              "CLARITIN": "CLARITIN",
              "FEXOFENADINE HCL": "FEXOFENADINE HCL",
              "LEVOCETIRIZINE DIHYDROCHLORIDE": "LEVOCETIRIZINE DIHYDROCHLORIDE",
              "LORATADINE": "LORATADINE"
            },
            "PDRX PHARMACEUTICALS": {
              "LEVOCETIRIZINE DIHYDROCHLORIDE": "LEVOCETIRIZINE DIHYDROCHLORIDE"
            },
            "PERRIGO": {
              "ALLER-EASE": "ALLER-EASE",
              "ALLERGY RELIEF": "ALLERGY RELIEF",
              "CETIRIZINE HCL": "CETIRIZINE HCL",
              "DESLORATADINE": "DESLORATADINE",
              "GOODSENSE ALLER-EASE": "GOODSENSE ALLER-EASE",
              "GOODSENSE ALLERGY RELIEF": "GOODSENSE ALLERGY RELIEF",
              "KLS ALLER-TEC": "KLS ALLER-TEC",
              "KLS ALLERCLEAR": "KLS ALLERCLEAR"
            },
            "PERRIGO PHARMACEUTICALS": {
              "ALL DAY ALLERGY CHILDRENS": "ALL DAY ALLERGY CHILDRENS",
              "CETIRIZINE HCL": "CETIRIZINE HCL",
              "CETIRIZINE HCL CHILDRENS ALRGY": "CETIRIZINE HCL CHILDRENS ALRGY",
              "FEXOFENADINE HCL": "FEXOFENADINE HCL",
              "LEVOCETIRIZINE DIHYDROCHLORIDE": "LEVOCETIRIZINE DIHYDROCHLORIDE",
              "LORATADINE": "LORATADINE"
            },
            "PFIZER CONSUMER HEALTHCARE": {
              "ALAVERT": "ALAVERT"
            },
            "PHARMACEUTICAL ASSOCIATES": {
              "LORATADINE CHILDRENS": "LORATADINE CHILDRENS"
            },
            "PHARMEDIX": {
              "LORATADINE": "LORATADINE"
            },
            "PRECISION DOSE, INC": {
              "CETIRIZINE HCL CHILDRENS": "CETIRIZINE HCL CHILDRENS"
            },
            "PREFERRED PHARMACEUTICALS": {
              "ALLERGY RELIEF": "ALLERGY RELIEF",
              "CETIRIZINE HCL": "CETIRIZINE HCL",
              "CETIRIZINE HCL ALLERGY CHILD": "CETIRIZINE HCL ALLERGY CHILD",
              "CHILDRENS LORATADINE": "CHILDRENS LORATADINE",
              "FEXOFENADINE HCL": "FEXOFENADINE HCL",
              "LEVOCETIRIZINE DIHYDROCHLORIDE": "LEVOCETIRIZINE DIHYDROCHLORIDE",
              "LORATADINE": "LORATADINE",
              "LORATADINE CHILDRENS": "LORATADINE CHILDRENS"
            },
            "PROFICIENT RX": {
              "CETIRIZINE HCL": "CETIRIZINE HCL",
              "FEXOFENADINE HCL": "FEXOFENADINE HCL",
              "LEVOCETIRIZINE DIHYDROCHLORIDE": "LEVOCETIRIZINE DIHYDROCHLORIDE",
              "LORATADINE": "LORATADINE"
            },
            "PUBLIX SUPER MARKETS INC.": {
              "PX ALLERGY RELIEF": "PX ALLERGY RELIEF",
              "PX ALLERGY RELIEF CETIRIZINE": "PX ALLERGY RELIEF CETIRIZINE",
              "PX ALLERGY RELIEF LORATADINE": "PX ALLERGY RELIEF LORATADINE",
              "PX CHILDRENS ALLERGY": "PX CHILDRENS ALLERGY"
            },
            "QUALITY CARE": {
              "ALAVERT": "ALAVERT",
              "CETIRIZINE HCL": "CETIRIZINE HCL",
              "CLARINEX": "CLARINEX",
              "CLARITIN": "CLARITIN",
              "FEXOFENADINE HCL": "FEXOFENADINE HCL",
              "LORATADINE": "LORATADINE",
              "XYZAL": "XYZAL"
            },
            "QUALITY CARE PRODUCTS": {
              "ALLERGY RELIEF": "ALLERGY RELIEF",
              "CETIRIZINE HCL": "CETIRIZINE HCL",
              "FEXOFENADINE HCL": "FEXOFENADINE HCL",
              "LORATADINE": "LORATADINE"
            },
            "RECKITT BENCKISER": {
              "MUCINEX ALLERGY": "MUCINEX ALLERGY"
            },
            "RELIABLE 1 LABS": {
              "FEXOFENADINE HCL": "FEXOFENADINE HCL"
            },
            "RITE AID CORPORATION": {
              "RA ALLERGY RELIEF": "RA ALLERGY RELIEF",
              "RA ALLERGY RELIEF (CETIRIZINE)": "RA ALLERGY RELIEF (CETIRIZINE)",
              "RA ALLERGY RELIEF (LORATADINE)": "RA ALLERGY RELIEF (LORATADINE)",
              "RA ALLERGY RELIEF CHILDRENS": "RA ALLERGY RELIEF CHILDRENS",
              "RA CETIRIZINE": "RA CETIRIZINE",
              "RA CETIRIZINE CHILDRENS": "RA CETIRIZINE CHILDRENS",
              "RA CETIRIZINE HCL CHILD ALLRGY": "RA CETIRIZINE HCL CHILD ALLRGY",
              "RA LORATADINE": "RA LORATADINE",
              "RA LORATADINE CHILDRENS": "RA LORATADINE CHILDRENS"
            },
            "RUGBY LABORATORIES": {
              "ALLERGY NON-DROWSY": "ALLERGY NON-DROWSY",
              "CETIRIZINE HCL": "CETIRIZINE HCL",
              "FEXOFENADINE HCL": "FEXOFENADINE HCL",
              "FEXOFENADINE HCL CHILDRENS": "FEXOFENADINE HCL CHILDRENS",
              "LEVOCETIRIZINE DIHYDROCHLORIDE": "LEVOCETIRIZINE DIHYDROCHLORIDE"
            },
            "RXCHANGE CO": {
              "CLARINEX": "CLARINEX",
              "LORATADINE": "LORATADINE"
            },
            "SAFEWAY": {
              "SW ALLERGY RELIEF": "SW ALLERGY RELIEF"
            },
            "SAM'S WEST": {
              "MM CETIRIZINE HCL": "MM CETIRIZINE HCL"
            },
            "SANDOZ": {
              "CETIRIZINE HCL": "CETIRIZINE HCL",
              "DESLORATADINE": "DESLORATADINE",
              "LORATADINE": "LORATADINE"
            },
            "SANOFI-AVENTIS U.S.": {
              "XYZAL": "XYZAL"
            },
            "SCHERING-PLOUGH HEALTHCARE": {
              "CLARITIN": "CLARITIN"
            },
            "SCIEGEN PHARMACEUTICALS": {
              "LEVOCETIRIZINE DIHYDROCHLORIDE": "LEVOCETIRIZINE DIHYDROCHLORIDE"
            },
            "SELECT BRAND": {
              "SB ALLERGY": "SB ALLERGY",
              "SB ALLERGY RELIEF": "SB ALLERGY RELIEF",
              "SB CETIRIZINE HCL CHILDRENS": "SB CETIRIZINE HCL CHILDRENS",
              "SB LORATADINE": "SB LORATADINE",
              "SB LORATADINE ALLERGY RELIEF": "SB LORATADINE ALLERGY RELIEF"
            },
            "SILARX": {
              "CETIRIZINE HCL": "CETIRIZINE HCL",
              "CETIRIZINE HCL ALLERGY CHILD": "CETIRIZINE HCL ALLERGY CHILD",
              "CETIRIZINE HCL HIVES RELIEF": "CETIRIZINE HCL HIVES RELIEF",
              "CHILDRENS LORATADINE": "CHILDRENS LORATADINE",
              "LORATADINE HIVES RELIEF": "LORATADINE HIVES RELIEF"
            },
            "ST MARY'S MPP": {
              "CETIRIZINE HCL": "CETIRIZINE HCL",
              "FEXOFENADINE HCL": "FEXOFENADINE HCL",
              "LORATADINE": "LORATADINE"
            },
            "SUN PHARMACEUTICALS": {
              "CETIRIZINE HCL": "CETIRIZINE HCL",
              "DESLORATADINE": "DESLORATADINE",
              "LEVOCETIRIZINE DIHYDROCHLORIDE": "LEVOCETIRIZINE DIHYDROCHLORIDE"
            },
            "TARGET": {
              "TGT ALL DAY ALLERGY CHILDRENS": "TGT ALL DAY ALLERGY CHILDRENS",
              "TGT ALL DAY ALLERGY RELIEF": "TGT ALL DAY ALLERGY RELIEF",
              "TGT ALLERGY RELIEF": "TGT ALLERGY RELIEF",
              "TGT LORATADINE CHILDRENS": "TGT LORATADINE CHILDRENS"
            },
            "TARO": {
              "CETIRIZINE HCL": "CETIRIZINE HCL",
              "CETIRIZINE HCL ALLERGY CHILD": "CETIRIZINE HCL ALLERGY CHILD",
              "CETIRIZINE HCL CHILDRENS ALRGY": "CETIRIZINE HCL CHILDRENS ALRGY",
              "CHILDRENS LORATADINE": "CHILDRENS LORATADINE",
              "LEVOCETIRIZINE DIHYDROCHLORIDE": "LEVOCETIRIZINE DIHYDROCHLORIDE",
              "LORATADINE CHILDRENS": "LORATADINE CHILDRENS"
            },
            "TERSERA THERAPEUTICS": {
              "QUZYTTIR": "QUZYTTIR"
            },
            "TEVA PHARMACEUTICALS USA": {
              "LEVOCETIRIZINE DIHYDROCHLORIDE": "LEVOCETIRIZINE DIHYDROCHLORIDE"
            },
            "TIME-CAP LABS": {
              "LORATADINE": "LORATADINE"
            },
            "TOPCO": {
              "ALL DAY ALLERGY": "ALL DAY ALLERGY",
              "ALL DAY ALLERGY CHILDRENS": "ALL DAY ALLERGY CHILDRENS",
              "ALL-DAY ALLERGY CHILDRENS": "ALL-DAY ALLERGY CHILDRENS",
              "ALLERGY CHILDRENS": "ALLERGY CHILDRENS",
              "ALLERGY RELIEF": "ALLERGY RELIEF",
              "ALLERGY RELIEF CHILDRENS": "ALLERGY RELIEF CHILDRENS",
              "FEXOFENADINE HCL": "FEXOFENADINE HCL",
              "LORATADINE CHILDRENS": "LORATADINE CHILDRENS"
            },
            "TORRENT PHARMACEUTICALS": {
              "CETIRIZINE HCL": "CETIRIZINE HCL"
            },
            "TRIMARC LABORATORIES": {
              "CETIRIZINE HCL": "CETIRIZINE HCL",
              "LORATADINE": "LORATADINE"
            },
            "UCB PHARMA": {
              "XYZAL": "XYZAL"
            },
            "UNIT DOSE SERVICES": {
              "LORATADINE": "LORATADINE"
            },
            "VA CMOP DALLAS": {
              "CETIRIZINE HCL": "CETIRIZINE HCL",
              "LORATADINE": "LORATADINE"
            },
            "VENSUN PHARMACEUTICALS": {
              "LEVOCETIRIZINE DIHYDROCHLORIDE": "LEVOCETIRIZINE DIHYDROCHLORIDE"
            },
            "VIRTUS PHARMACEUTICALS": {
              "DESLORATADINE": "DESLORATADINE"
            },
            "WAL-MART": {
              "EQ ALLERGY CHILDRENS": "EQ ALLERGY CHILDRENS",
              "EQ ALLERGY RELIEF": "EQ ALLERGY RELIEF",
              "EQ ALLERGY RELIEF (CETIRIZINE)": "EQ ALLERGY RELIEF (CETIRIZINE)",
              "EQ ALLERGY RELIEF CHILDRENS": "EQ ALLERGY RELIEF CHILDRENS",
              "EQ CETIRIZINE HCL": "EQ CETIRIZINE HCL",
              "EQ CHILDRENS LORATADINE": "EQ CHILDRENS LORATADINE",
              "EQ LORATADINE": "EQ LORATADINE",
              "EQ LORATADINE CHILDRENS": "EQ LORATADINE CHILDRENS",
              "MM FEXOFENADINE HCL": "MM FEXOFENADINE HCL"
            },
            "WALGREENS": {
              "LEVOCETIRIZINE DIHYDROCHLORIDE": "LEVOCETIRIZINE DIHYDROCHLORIDE",
              "LORATADINE": "LORATADINE",
              "WAL-FEX": "WAL-FEX",
              "WAL-FEX ALLERGY": "WAL-FEX ALLERGY",
              "WAL-FEX CHILDRENS": "WAL-FEX CHILDRENS",
              "WAL-ITIN": "WAL-ITIN",
              "WAL-ITIN ALLER-MELTS": "WAL-ITIN ALLER-MELTS",
              "WAL-ITIN ALLERGY REDITABS": "WAL-ITIN ALLERGY REDITABS",
              "WAL-ITIN CHILDRENS": "WAL-ITIN CHILDRENS",
              "WAL-VERT": "WAL-VERT",
              "WAL-ZYR": "WAL-ZYR",
              "WAL-ZYR ALL DAY ALLERGY CHILD": "WAL-ZYR ALL DAY ALLERGY CHILD",
              "WAL-ZYR CHILDRENS": "WAL-ZYR CHILDRENS"
            },
            "WESTMINSTER PHARMACEUTICALS": {
              "LEVOCETIRIZINE DIHYDROCHLORIDE": "LEVOCETIRIZINE DIHYDROCHLORIDE"
            },
            "WINTHROP, US": {
              "LEVOCETIRIZINE DIHYDROCHLORIDE": "LEVOCETIRIZINE DIHYDROCHLORIDE"
            },
            "WOCKHARDT USA": {
              "ALLERGY 24-HR": "ALLERGY 24-HR"
            },
            "YILING PHARMACEUTICAL": {
              "LEVOCETIRIZINE DIHYDROCHLORIDE": "LEVOCETIRIZINE DIHYDROCHLORIDE"
            }
          }
        },
        "Antihistamines - Phenothiazines": {
          "Antihistamines - Phenothiazines": {
            "A-S MEDICATION SOLUTIONS": {
              "PHENERGAN": "PHENERGAN",
              "PROMETHAZINE HCL": "PROMETHAZINE HCL"
            },
            "ACTAVIS PHARMA": {
              "PHENADOZ": "PHENADOZ",
              "PROMETHAZINE HCL": "PROMETHAZINE HCL"
            },
            "AIDAREX PHARMACEUTICALS": {
              "PROMETHAZINE HCL": "PROMETHAZINE HCL"
            },
            "ALTURA PHARMACEUTICALS": {
              "PROMETHAZINE HCL": "PROMETHAZINE HCL"
            },
            "AMERICAN HEALTH PACKAGING": {
              "PROMETHAZINE HCL": "PROMETHAZINE HCL"
            },
            "AMNEAL PHARMACEUTICALS": {
              "PROMETHAZINE HCL": "PROMETHAZINE HCL"
            },
            "APOTHECA": {
              "PROMETHAZINE HCL": "PROMETHAZINE HCL"
            },
            "ATLANTIC BIOLOGICALS": {
              "PROMETHAZINE HCL": "PROMETHAZINE HCL"
            },
            "BLENHEIM PHARMACAL": {
              "PROMETHAZINE HCL": "PROMETHAZINE HCL"
            },
            "BLUE POINT LABORATORIES": {
              "PROMETHAZINE HCL": "PROMETHAZINE HCL"
            },
            "BRYANT RANCH PREPACK": {
              "PROMETHAZINE HCL": "PROMETHAZINE HCL"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "PHENADOZ": "PHENADOZ",
              "PROMETHAZINE HCL": "PROMETHAZINE HCL"
            },
            "COSETTE PHARMACEUTICALS": {
              "PROMETHEGAN": "PROMETHEGAN"
            },
            "DIRECT RX": {
              "PROMETHAZINE HCL": "PROMETHAZINE HCL"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "PROMETHAZINE HCL": "PROMETHAZINE HCL"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "PROMETHAZINE HCL": "PROMETHAZINE HCL"
            },
            "HI-TECH": {
              "PROMETHAZINE HCL": "PROMETHAZINE HCL"
            },
            "HIKMA": {
              "PHENERGAN": "PHENERGAN",
              "PROMETHAZINE HCL": "PROMETHAZINE HCL"
            },
            "IMPAX GENERICS": {
              "PROMETHAZINE HCL": "PROMETHAZINE HCL"
            },
            "KVK TECH": {
              "PROMETHAZINE HCL": "PROMETHAZINE HCL"
            },
            "LIBERTY PHARMACEUTICAL": {
              "PROMETHAZINE HCL": "PROMETHAZINE HCL"
            },
            "MAJOR PHARMACEUTICALS": {
              "PROMETHAZINE HCL": "PROMETHAZINE HCL"
            },
            "MARLEX PHARMACEUTICALS": {
              "PROMETHAZINE HCL": "PROMETHAZINE HCL"
            },
            "MEDVANTX": {
              "PROMETHAZINE HCL": "PROMETHAZINE HCL"
            },
            "MORTON GROVE PHARMACEUTICALS": {
              "PROMETHAZINE HCL": "PROMETHAZINE HCL"
            },
            "MYLAN": {
              "PROMETHAZINE HCL": "PROMETHAZINE HCL"
            },
            "MYLAN INSTITUTIONAL": {
              "PROMETHAZINE HCL": "PROMETHAZINE HCL"
            },
            "NOSTRUM LABORATORIES": {
              "PROMETHAZINE HCL": "PROMETHAZINE HCL"
            },
            "NOVAPLUS/HIKMA": {
              "PROMETHAZINE HCL": "PROMETHAZINE HCL"
            },
            "NUCARE PHARMACEUTICALS": {
              "PROMETHAZINE HCL": "PROMETHAZINE HCL"
            },
            "PAR PHARMACEUTICALS": {
              "PROMETHAZINE HCL": "PROMETHAZINE HCL"
            },
            "PDRX PHARMACEUTICAL": {
              "PROMETHAZINE HCL": "PROMETHAZINE HCL"
            },
            "PERRIGO PHARMACEUTICALS": {
              "PROMETHAZINE HCL": "PROMETHAZINE HCL"
            },
            "PHARMACEUTICAL ASSOCIATES": {
              "PROMETHAZINE HCL": "PROMETHAZINE HCL"
            },
            "PHARMEDIX": {
              "PROMETHAZINE HCL": "PROMETHAZINE HCL"
            },
            "PHARMPAK": {
              "PROMETHAZINE HCL": "PROMETHAZINE HCL"
            },
            "PREFERRED PHARMACEUTICALS": {
              "PROMETHAZINE HCL": "PROMETHAZINE HCL"
            },
            "PRESTIUM PHARMA": {
              "PHENERGAN": "PHENERGAN"
            },
            "PROFICIENT RX": {
              "PROMETHAZINE HCL": "PROMETHAZINE HCL"
            },
            "QUALITY CARE": {
              "PROMETHAZINE HCL": "PROMETHAZINE HCL"
            },
            "QUALITY CARE PRODUCTS": {
              "PROMETHAZINE HCL": "PROMETHAZINE HCL"
            },
            "READYMEDS": {
              "PROMETHAZINE HCL": "PROMETHAZINE HCL"
            },
            "RXCHANGE CO": {
              "PROMETHAZINE HCL": "PROMETHAZINE HCL"
            },
            "SANDOZ": {
              "PROMETHAZINE HCL": "PROMETHAZINE HCL"
            },
            "SKY PACKAGING": {
              "PROMETHAZINE HCL": "PROMETHAZINE HCL"
            },
            "ST MARY'S MPP": {
              "PROMETHAZINE HCL": "PROMETHAZINE HCL"
            },
            "SUN PHARMACEUTICALS": {
              "PROMETHAZINE HCL": "PROMETHAZINE HCL"
            },
            "TARO": {
              "PROMETHAZINE HCL": "PROMETHAZINE HCL"
            },
            "TEVA PARENTERAL MEDICINES": {
              "PROMETHAZINE HCL": "PROMETHAZINE HCL"
            },
            "UNIT DOSE SERVICES": {
              "PROMETHAZINE HCL": "PROMETHAZINE HCL"
            },
            "X-GEN PHARMACEUTICALS, INC": {
              "PROMETHAZINE HCL": "PROMETHAZINE HCL"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "PROMETHAZINE HCL": "PROMETHAZINE HCL"
            }
          }
        },
        "Antihistamines - Piperazines": {
          "Antihistamines - Piperazines": {
            "MAGNA PHARMACEUTICALS, INC": {
              "AHIST": "AHIST"
            }
          }
        },
        "Antihistamines - Piperidines": {
          "Antihistamines - Piperidines": {
            "ACTAVIS MID ATLANTIC": {
              "CYPROHEPTADINE HCL": "CYPROHEPTADINE HCL"
            },
            "ALEXSO": {
              "CYPROHEPTADINE HCL": "CYPROHEPTADINE HCL"
            },
            "AMNEAL PHARMACEUTICALS": {
              "CYPROHEPTADINE HCL": "CYPROHEPTADINE HCL"
            },
            "APNAR PHARMA": {
              "CYPROHEPTADINE HCL": "CYPROHEPTADINE HCL"
            },
            "AVET PHARMACEUTICALS": {
              "CYPROHEPTADINE HCL": "CYPROHEPTADINE HCL"
            },
            "AVKARE": {
              "CYPROHEPTADINE HCL": "CYPROHEPTADINE HCL"
            },
            "BAYSHORE PHARMACEUTICALS": {
              "CYPROHEPTADINE HCL": "CYPROHEPTADINE HCL"
            },
            "BRECKENRIDGE": {
              "CYPROHEPTADINE HCL": "CYPROHEPTADINE HCL"
            },
            "BRYANT RANCH PREPACK": {
              "CYPROHEPTADINE HCL": "CYPROHEPTADINE HCL"
            },
            "DASH PHARMACEUTICALS": {
              "CYPROHEPTADINE HCL": "CYPROHEPTADINE HCL"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "CYPROHEPTADINE HCL": "CYPROHEPTADINE HCL"
            },
            "INGENUS PHARMACEUTICALS": {
              "CYPROHEPTADINE HCL": "CYPROHEPTADINE HCL"
            },
            "LANNETT": {
              "CYPROHEPTADINE HCL": "CYPROHEPTADINE HCL"
            },
            "LIBERTY PHARMACEUTICAL": {
              "CYPROHEPTADINE HCL": "CYPROHEPTADINE HCL"
            },
            "MARLEX PHARMACEUTICALS": {
              "CYPROHEPTADINE HCL": "CYPROHEPTADINE HCL"
            },
            "PATRIN PHARMA": {
              "CYPROHEPTADINE HCL": "CYPROHEPTADINE HCL"
            },
            "PDRX PHARMACEUTICALS": {
              "CYPROHEPTADINE HCL": "CYPROHEPTADINE HCL"
            },
            "PHARMACEUTICAL ASSOCIATES": {
              "CYPROHEPTADINE HCL": "CYPROHEPTADINE HCL"
            },
            "PROFICIENT RX": {
              "CYPROHEPTADINE HCL": "CYPROHEPTADINE HCL"
            },
            "QUAGEN PHARMACEUTICALS": {
              "CYPROHEPTADINE HCL": "CYPROHEPTADINE HCL"
            },
            "QUALITY CARE": {
              "CYPROHEPTADINE HCL": "CYPROHEPTADINE HCL"
            },
            "QUALITY CARE PRODUCTS": {
              "CYPROHEPTADINE HCL": "CYPROHEPTADINE HCL"
            },
            "RISING PHARMACEUTICALS": {
              "CYPROHEPTADINE HCL": "CYPROHEPTADINE HCL"
            },
            "SOLUBIOMIX LLC": {
              "CYPROHEPTADINE HCL": "CYPROHEPTADINE HCL"
            },
            "STRIDES PHARMA": {
              "CYPROHEPTADINE HCL": "CYPROHEPTADINE HCL"
            },
            "TEVA PHARMACEUTICALS USA": {
              "CYPROHEPTADINE HCL": "CYPROHEPTADINE HCL"
            },
            "TRUPHARMA": {
              "CYPROHEPTADINE HCL": "CYPROHEPTADINE HCL"
            },
            "VISTAPHARM INC.": {
              "CYPROHEPTADINE HCL": "CYPROHEPTADINE HCL"
            },
            "WESTMINSTER PHARMACEUTICALS": {
              "CYPROHEPTADINE HCL": "CYPROHEPTADINE HCL"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "CYPROHEPTADINE HCL": "CYPROHEPTADINE HCL"
            }
          }
        }
      },
      "ANTIHYPERLIPIDEMICS": {
        "Adenosine Triphosphate-Citrate Lyase (ACL) Inhibitors": {
          "Adenosine Triphosphate-Citrate Lyase (ACL) Inhibitors": {
            "ESPERION THERAPEUTICS": {
              "NEXLETOL": "NEXLETOL"
            }
          }
        },
        "Antihyperlipidemics - Combinations": {
          "ACL Inhib-Intestinal Cholesterol Absorption Inhib Comb": {
            "ESPERION THERAPEUTICS": {
              "NEXLIZET": "NEXLIZET"
            }
          },
          "Antihyperlipidemics Misc. Combinations": {
            "INTERNATIONAL BRAND MANAGEMENT": {
              "SURE RESULT O3D3 SYSTEM": "SURE RESULT O3D3 SYSTEM"
            },
            "TMIG": {
              "OMEGA-3 RX COMPLETE": "OMEGA-3 RX COMPLETE",
              "OMEGA-3/D-3 WELLNESS PACK": "OMEGA-3/D-3 WELLNESS PACK"
            }
          },
          "Intest Cholest Absorp Inhib-HMG CoA Reductase Inhib Comb": {
            "A-S MEDICATION SOLUTIONS": {
              "VYTORIN": "VYTORIN"
            },
            "ACTAVIS": {
              "EZETIMIBE-SIMVASTATIN": "EZETIMIBE-SIMVASTATIN"
            },
            "AMNEAL PHARMACEUTICALS": {
              "EZETIMIBE-SIMVASTATIN": "EZETIMIBE-SIMVASTATIN"
            },
            "ANI  PHARMACEUTICALS": {
              "EZETIMIBE-SIMVASTATIN": "EZETIMIBE-SIMVASTATIN"
            },
            "ASCEND LABORATORIES": {
              "EZETIMIBE-SIMVASTATIN": "EZETIMIBE-SIMVASTATIN"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "EZETIMIBE-SIMVASTATIN": "EZETIMIBE-SIMVASTATIN"
            },
            "GLENMARK PHARMACEUTICALS": {
              "EZETIMIBE-SIMVASTATIN": "EZETIMIBE-SIMVASTATIN"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "EZETIMIBE-SIMVASTATIN": "EZETIMIBE-SIMVASTATIN"
            },
            "MERCK/SCHERING-PLOUGH PHARM": {
              "VYTORIN": "VYTORIN"
            },
            "NORTHSTAR RX": {
              "EZETIMIBE-SIMVASTATIN": "EZETIMIBE-SIMVASTATIN"
            },
            "PDRX PHARMACEUTICAL": {
              "VYTORIN": "VYTORIN"
            },
            "QUALITY CARE": {
              "VYTORIN": "VYTORIN"
            }
          }
        },
        "Antihyperlipidemics - Misc.": {
          "Antihyperlipidemics - Misc.": {
            "A-S MEDICATION SOLUTIONS": {
              "LOVAZA": "LOVAZA",
              "OMEGA-3-ACID ETHYL ESTERS": "OMEGA-3-ACID ETHYL ESTERS"
            },
            "AMARIN PHARMA": {
              "VASCEPA": "VASCEPA"
            },
            "AMERICAN HEALTH PACKAGING": {
              "OMEGA-3-ACID ETHYL ESTERS": "OMEGA-3-ACID ETHYL ESTERS"
            },
            "AMNEAL PHARMACEUTICALS": {
              "OMEGA-3-ACID ETHYL ESTERS": "OMEGA-3-ACID ETHYL ESTERS"
            },
            "ANI  PHARMACEUTICALS": {
              "OMEGA-3-ACID ETHYL ESTERS": "OMEGA-3-ACID ETHYL ESTERS"
            },
            "APOTEX": {
              "OMEGA-3-ACID ETHYL ESTERS": "OMEGA-3-ACID ETHYL ESTERS"
            },
            "ATLANTIC BIOLOGICALS": {
              "OMEGA-3-ACID ETHYL ESTERS": "OMEGA-3-ACID ETHYL ESTERS"
            },
            "AVKARE": {
              "OMEGA-3-ACID ETHYL ESTERS": "OMEGA-3-ACID ETHYL ESTERS"
            },
            "BIONPHARMA": {
              "OMEGA-3-ACID ETHYL ESTERS": "OMEGA-3-ACID ETHYL ESTERS"
            },
            "CAMBER PHARMACEUTICALS": {
              "OMEGA-3-ACID ETHYL ESTERS": "OMEGA-3-ACID ETHYL ESTERS"
            },
            "DIRECT RX": {
              "OMEGA-3-ACID ETHYL ESTERS": "OMEGA-3-ACID ETHYL ESTERS"
            },
            "EPIC PHARMA": {
              "OMEGA-3-ACID ETHYL ESTERS": "OMEGA-3-ACID ETHYL ESTERS"
            },
            "GLAXO SMITH KLINE": {
              "LOVAZA": "LOVAZA"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "OMEGA-3-ACID ETHYL ESTERS": "OMEGA-3-ACID ETHYL ESTERS"
            },
            "HIKMA": {
              "ICOSAPENT ETHYL": "ICOSAPENT ETHYL"
            },
            "KASTLE THERAPEUTICS": {
              "KYNAMRO": "KYNAMRO"
            },
            "KEY THERAPEUTICS": {
              "TRIKLO": "TRIKLO"
            },
            "MAJOR PHARMACEUTICALS": {
              "OMEGA-3-ACID ETHYL ESTERS": "OMEGA-3-ACID ETHYL ESTERS"
            },
            "NORTHSTAR RX": {
              "OMEGA-3-ACID ETHYL ESTERS": "OMEGA-3-ACID ETHYL ESTERS"
            },
            "PAR PHARMACEUTICAL": {
              "OMEGA-3-ACID ETHYL ESTERS": "OMEGA-3-ACID ETHYL ESTERS"
            },
            "PRASCO LABORATORIES": {
              "OMEGA-3-ACID ETHYL ESTERS": "OMEGA-3-ACID ETHYL ESTERS"
            },
            "QUALITY CARE": {
              "LOVAZA": "LOVAZA"
            },
            "QUALITY CARE PRODUCTS": {
              "OMEGA-3-ACID ETHYL ESTERS": "OMEGA-3-ACID ETHYL ESTERS"
            },
            "STRIDES PHARMA": {
              "OMEGA-3-ACID ETHYL ESTERS": "OMEGA-3-ACID ETHYL ESTERS"
            },
            "TEVA PHARMACEUTICALS USA": {
              "OMEGA-3-ACID ETHYL ESTERS": "OMEGA-3-ACID ETHYL ESTERS"
            }
          }
        },
        "Bile Acid Sequestrants": {
          "Bile Acid Sequestrants": {
            "A-S MEDICATION SOLUTIONS": {
              "WELCHOL": "WELCHOL"
            },
            "AJANTA PHARMA LIMITED": {
              "CHOLESTYRAMINE": "CHOLESTYRAMINE"
            },
            "AMERICAN HEALTH PACKAGING": {
              "COLESEVELAM HCL": "COLESEVELAM HCL"
            },
            "AMNEAL PHARMACEUTICALS": {
              "COLESEVELAM HCL": "COLESEVELAM HCL",
              "COLESTIPOL HCL": "COLESTIPOL HCL"
            },
            "ANI  PHARMACEUTICALS": {
              "CHOLESTYRAMINE": "CHOLESTYRAMINE"
            },
            "ASCEND LABORATORIES": {
              "COLESEVELAM HCL": "COLESEVELAM HCL"
            },
            "AVKARE": {
              "COLESEVELAM HCL": "COLESEVELAM HCL"
            },
            "BIONPHARMA": {
              "COLESEVELAM HCL": "COLESEVELAM HCL"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "COLESEVELAM HCL": "COLESEVELAM HCL"
            },
            "GLENMARK PHARMACEUTICALS": {
              "COLESEVELAM HCL": "COLESEVELAM HCL"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "CHOLESTYRAMINE": "CHOLESTYRAMINE",
              "CHOLESTYRAMINE LIGHT": "CHOLESTYRAMINE LIGHT",
              "COLESEVELAM HCL": "COLESEVELAM HCL"
            },
            "GREENSTONE": {
              "COLESTIPOL HCL": "COLESTIPOL HCL"
            },
            "OHM LABS": {
              "COLESEVELAM HCL": "COLESEVELAM HCL"
            },
            "PAR PHARMACEUTICAL": {
              "CHOLESTYRAMINE": "CHOLESTYRAMINE",
              "CHOLESTYRAMINE LIGHT": "CHOLESTYRAMINE LIGHT",
              "QUESTRAN": "QUESTRAN",
              "QUESTRAN LIGHT": "QUESTRAN LIGHT"
            },
            "PFIZER U.S.": {
              "COLESTID": "COLESTID",
              "COLESTID FLAVORED": "COLESTID FLAVORED"
            },
            "SANDOZ": {
              "CHOLESTYRAMINE": "CHOLESTYRAMINE",
              "CHOLESTYRAMINE LIGHT": "CHOLESTYRAMINE LIGHT"
            },
            "SANKYO": {
              "WELCHOL": "WELCHOL"
            },
            "UPSHER-SMITH": {
              "CHOLESTYRAMINE": "CHOLESTYRAMINE",
              "PREVALITE": "PREVALITE"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "CHOLESTYRAMINE": "CHOLESTYRAMINE",
              "CHOLESTYRAMINE LIGHT": "CHOLESTYRAMINE LIGHT",
              "COLESEVELAM HCL": "COLESEVELAM HCL"
            }
          }
        },
        "Fibric Acid Derivatives": {
          "Fibric Acid Derivatives": {
            "A-S MEDICATION SOLUTIONS": {
              "FENOFIBRATE": "FENOFIBRATE",
              "GEMFIBROZIL": "GEMFIBROZIL",
              "TRICOR": "TRICOR"
            },
            "ABBVIE": {
              "TRICOR": "TRICOR",
              "TRILIPIX": "TRILIPIX"
            },
            "ACTAVIS ELIZABETH": {
              "FENOFIBRIC ACID": "FENOFIBRIC ACID"
            },
            "AIDAREX PHARMACEUTICALS": {
              "FENOFIBRATE": "FENOFIBRATE",
              "GEMFIBROZIL": "GEMFIBROZIL"
            },
            "AJANTA PHARMA LIMITED": {
              "FENOFIBRATE": "FENOFIBRATE",
              "FENOFIBRATE MICRONIZED": "FENOFIBRATE MICRONIZED"
            },
            "ALEMBIC PHARMACEUTICALS": {
              "FENOFIBRATE": "FENOFIBRATE",
              "FENOFIBRIC ACID": "FENOFIBRIC ACID"
            },
            "AMERICAN HEALTH PACKAGING": {
              "FENOFIBRATE": "FENOFIBRATE",
              "FENOFIBRATE MICRONIZED": "FENOFIBRATE MICRONIZED",
              "FENOFIBRIC ACID": "FENOFIBRIC ACID",
              "GEMFIBROZIL": "GEMFIBROZIL"
            },
            "AMERIGEN PHARMACEUTICALS": {
              "FENOFIBRATE MICRONIZED": "FENOFIBRATE MICRONIZED"
            },
            "AMNEAL PHARMACEUTICALS": {
              "FENOFIBRATE": "FENOFIBRATE",
              "FENOFIBRATE MICRONIZED": "FENOFIBRATE MICRONIZED",
              "FENOFIBRIC ACID": "FENOFIBRIC ACID"
            },
            "ANI  PHARMACEUTICALS": {
              "FENOFIBRATE": "FENOFIBRATE"
            },
            "APHENA PHARMA SOLUTIONS": {
              "FENOFIBRATE": "FENOFIBRATE",
              "GEMFIBROZIL": "GEMFIBROZIL",
              "TRICOR": "TRICOR"
            },
            "APOTEX": {
              "FENOFIBRATE MICRONIZED": "FENOFIBRATE MICRONIZED",
              "GEMFIBROZIL": "GEMFIBROZIL"
            },
            "ARALEZ PHARMACEUTICALS": {
              "FENOFIBRIC ACID": "FENOFIBRIC ACID",
              "FIBRICOR": "FIBRICOR"
            },
            "ATHENA BIOSCIENCE": {
              "FIBRICOR": "FIBRICOR"
            },
            "AUROBINDO PHARMA": {
              "FENOFIBRATE": "FENOFIBRATE",
              "FENOFIBRIC ACID": "FENOFIBRIC ACID",
              "GEMFIBROZIL": "GEMFIBROZIL"
            },
            "AUSTARPHARMA": {
              "FENOFIBRATE": "FENOFIBRATE"
            },
            "AVKARE": {
              "FENOFIBRATE": "FENOFIBRATE",
              "FENOFIBRATE MICRONIZED": "FENOFIBRATE MICRONIZED"
            },
            "AVPAK": {
              "FENOFIBRATE": "FENOFIBRATE",
              "FENOFIBRATE MICRONIZED": "FENOFIBRATE MICRONIZED",
              "GEMFIBROZIL": "GEMFIBROZIL"
            },
            "BASIEM": {
              "FENOFIBRATE": "FENOFIBRATE"
            },
            "BAUSCH HEALTH": {
              "FENOGLIDE": "FENOGLIDE"
            },
            "BLENHEIM PHARMACAL": {
              "GEMFIBROZIL": "GEMFIBROZIL"
            },
            "BLU PHARMACEUTICALS": {
              "GEMFIBROZIL": "GEMFIBROZIL"
            },
            "BRYANT RANCH PREPACK": {
              "FENOFIBRATE": "FENOFIBRATE",
              "GEMFIBROZIL": "GEMFIBROZIL"
            },
            "CAMBER PHARMACEUTICALS": {
              "FENOFIBRATE": "FENOFIBRATE",
              "GEMFIBROZIL": "GEMFIBROZIL"
            },
            "CASPER PHARMA": {
              "TRIGLIDE": "TRIGLIDE"
            },
            "CIPLA USA": {
              "FENOFIBRATE": "FENOFIBRATE",
              "FENOFIBRATE MICRONIZED": "FENOFIBRATE MICRONIZED",
              "GEMFIBROZIL": "GEMFIBROZIL"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "FENOFIBRATE": "FENOFIBRATE",
              "GEMFIBROZIL": "GEMFIBROZIL"
            },
            "DIRECT RX": {
              "FENOFIBRATE": "FENOFIBRATE",
              "GEMFIBROZIL": "GEMFIBROZIL"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "FENOFIBRATE": "FENOFIBRATE",
              "FENOFIBRATE MICRONIZED": "FENOFIBRATE MICRONIZED"
            },
            "EPIC PHARMA": {
              "GEMFIBROZIL": "GEMFIBROZIL"
            },
            "EXELAN PHARMACEUTICALS": {
              "GEMFIBROZIL": "GEMFIBROZIL"
            },
            "GLENMARK PHARMACEUTICALS": {
              "FENOFIBRATE MICRONIZED": "FENOFIBRATE MICRONIZED"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "FENOFIBRATE": "FENOFIBRATE",
              "FENOFIBRATE MICRONIZED": "FENOFIBRATE MICRONIZED",
              "FENOFIBRIC ACID": "FENOFIBRIC ACID",
              "GEMFIBROZIL": "GEMFIBROZIL"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "GEMFIBROZIL": "GEMFIBROZIL"
            },
            "H2 PHARMA": {
              "FENOFIBRATE": "FENOFIBRATE"
            },
            "HALTON LABORATORIES": {
              "FENOFIBRIC ACID": "FENOFIBRIC ACID"
            },
            "HIKMA": {
              "GEMFIBROZIL": "GEMFIBROZIL"
            },
            "IMPAX GENERICS": {
              "FENOFIBRIC ACID": "FENOFIBRIC ACID"
            },
            "KAISER FOUNDATION HOSPITAL": {
              "FENOFIBRATE": "FENOFIBRATE"
            },
            "KEY THERAPEUTICS": {
              "TRIGLIDE": "TRIGLIDE"
            },
            "KOWA PHARMACEUTICALS AMERICA": {
              "LIPOFEN": "LIPOFEN"
            },
            "LAURUS LABS PRIVATE LIMITED": {
              "FENOFIBRATE": "FENOFIBRATE"
            },
            "LETCO MEDICAL": {
              "GEMFIBROZIL": "GEMFIBROZIL"
            },
            "LIBERTY PHARMACEUTICAL": {
              "GEMFIBROZIL": "GEMFIBROZIL"
            },
            "LIFESTAR PHARMA": {
              "FENOFIBRATE": "FENOFIBRATE"
            },
            "LUPIN PHARMACEUTICALS": {
              "ANTARA": "ANTARA",
              "FENOFIBRATE": "FENOFIBRATE",
              "FENOFIBRATE MICRONIZED": "FENOFIBRATE MICRONIZED",
              "FENOFIBRIC ACID": "FENOFIBRIC ACID"
            },
            "MAJOR PHARMACEUTICALS": {
              "FENOFIBRATE": "FENOFIBRATE"
            },
            "MYLAN": {
              "FENOFIBRATE": "FENOFIBRATE",
              "FENOFIBRATE MICRONIZED": "FENOFIBRATE MICRONIZED",
              "FENOFIBRIC ACID": "FENOFIBRIC ACID"
            },
            "MYLAN INSTITUTIONAL": {
              "FENOFIBRATE": "FENOFIBRATE",
              "FENOFIBRIC ACID": "FENOFIBRIC ACID",
              "GEMFIBROZIL": "GEMFIBROZIL"
            },
            "NIVAGEN PHARMACEUTICALS": {
              "GEMFIBROZIL": "GEMFIBROZIL"
            },
            "NORTHSTAR RX": {
              "FENOFIBRATE": "FENOFIBRATE",
              "FENOFIBRATE MICRONIZED": "FENOFIBRATE MICRONIZED",
              "GEMFIBROZIL": "GEMFIBROZIL"
            },
            "NORTHWIND PHARMACEUTICALS": {
              "FENOFIBRATE": "FENOFIBRATE"
            },
            "NUCARE PHARMACEUTICALS": {
              "FENOFIBRATE": "FENOFIBRATE",
              "GEMFIBROZIL": "GEMFIBROZIL",
              "TRICOR": "TRICOR"
            },
            "PAR PHARMACEUTICAL": {
              "FENOFIBRIC ACID": "FENOFIBRIC ACID",
              "GEMFIBROZIL": "GEMFIBROZIL"
            },
            "PCCA": {
              "GEMFIBROZIL": "GEMFIBROZIL"
            },
            "PDRX PHARMACEUTICAL": {
              "GEMFIBROZIL": "GEMFIBROZIL",
              "TRICOR": "TRICOR"
            },
            "PERRIGO PHARMACEUTICALS": {
              "FENOFIBRATE": "FENOFIBRATE"
            },
            "PFIZER U.S.": {
              "LOPID": "LOPID"
            },
            "PHARMEDIX": {
              "GEMFIBROZIL": "GEMFIBROZIL"
            },
            "PREFERRED PHARMACEUTICALS": {
              "FENOFIBRATE": "FENOFIBRATE",
              "GEMFIBROZIL": "GEMFIBROZIL"
            },
            "PROFICIENT RX": {
              "FENOFIBRATE": "FENOFIBRATE",
              "GEMFIBROZIL": "GEMFIBROZIL"
            },
            "QUALITY CARE": {
              "FENOFIBRATE": "FENOFIBRATE",
              "FENOFIBRATE MICRONIZED": "FENOFIBRATE MICRONIZED",
              "GEMFIBROZIL": "GEMFIBROZIL"
            },
            "QUALITY CARE PRODUCTS": {
              "FENOFIBRATE": "FENOFIBRATE",
              "GEMFIBROZIL": "GEMFIBROZIL"
            },
            "RHODES PHARMACEUTICAL": {
              "FENOFIBRATE": "FENOFIBRATE",
              "FENOFIBRATE MICRONIZED": "FENOFIBRATE MICRONIZED"
            },
            "RISING PHARMACEUTICALS": {
              "GEMFIBROZIL": "GEMFIBROZIL"
            },
            "RXCHANGE CO": {
              "GEMFIBROZIL": "GEMFIBROZIL"
            },
            "SARRAS HEALTH": {
              "FENOFIBRIC ACID": "FENOFIBRIC ACID"
            },
            "SOLCO HEALTHCARE": {
              "FENOFIBRATE": "FENOFIBRATE"
            },
            "SPECTRUM": {
              "GEMFIBROZIL": "GEMFIBROZIL"
            },
            "ST MARY'S MPP": {
              "FENOFIBRATE": "FENOFIBRATE",
              "FENOFIBRATE MICRONIZED": "FENOFIBRATE MICRONIZED",
              "GEMFIBROZIL": "GEMFIBROZIL"
            },
            "SUN PHARMACEUTICALS": {
              "FENOFIBRATE": "FENOFIBRATE",
              "FENOFIBRATE MICRONIZED": "FENOFIBRATE MICRONIZED"
            },
            "TEVA PHARMACEUTICALS USA": {
              "FENOFIBRATE": "FENOFIBRATE",
              "GEMFIBROZIL": "GEMFIBROZIL"
            },
            "TEVA SELECT BRANDS": {
              "LOFIBRA": "LOFIBRA"
            },
            "TORRENT PHARMACEUTICALS": {
              "FENOFIBRATE": "FENOFIBRATE"
            },
            "TWI PHARMACEUTICALS": {
              "FENOFIBRIC ACID": "FENOFIBRIC ACID"
            },
            "WESTMINSTER PHARMACEUTICALS": {
              "FENOFIBRATE": "FENOFIBRATE"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "FENOFIBRATE": "FENOFIBRATE",
              "FENOFIBRIC ACID": "FENOFIBRIC ACID"
            }
          }
        },
        "HMG CoA Reductase Inhibitors": {
          "HMG CoA Reductase Inhibitor Combinations": {
            "ABBVIE": {
              "ADVICOR": "ADVICOR",
              "SIMCOR": "SIMCOR"
            },
            "PHARMAGENETICO": {
              "EQUAPAX/ATORVASTATIN/COQ10": "EQUAPAX/ATORVASTATIN/COQ10"
            },
            "PHARMANEX": {
              "CHOLESTIN": "CHOLESTIN"
            }
          },
          "HMG CoA Reductase Inhibitors": {
            "A-S MEDICATION SOLUTIONS": {
              "ATORVASTATIN CALCIUM": "ATORVASTATIN CALCIUM",
              "CRESTOR": "CRESTOR",
              "LESCOL": "LESCOL",
              "LIPITOR": "LIPITOR",
              "LOVASTATIN": "LOVASTATIN",
              "PRAVASTATIN SODIUM": "PRAVASTATIN SODIUM",
              "ROSUVASTATIN CALCIUM": "ROSUVASTATIN CALCIUM",
              "SIMVASTATIN": "SIMVASTATIN"
            },
            "ACCORD HEALTHCARE": {
              "ATORVASTATIN CALCIUM": "ATORVASTATIN CALCIUM",
              "PRAVASTATIN SODIUM": "PRAVASTATIN SODIUM",
              "ROSUVASTATIN CALCIUM": "ROSUVASTATIN CALCIUM",
              "SIMVASTATIN": "SIMVASTATIN"
            },
            "ACETRIS HEALTH": {
              "ROSUVASTATIN CALCIUM": "ROSUVASTATIN CALCIUM",
              "SIMVASTATIN": "SIMVASTATIN"
            },
            "ACTAVIS": {
              "LOVASTATIN": "LOVASTATIN"
            },
            "ACTAVIS PHARMA": {
              "PRAVASTATIN SODIUM": "PRAVASTATIN SODIUM",
              "ROSUVASTATIN CALCIUM": "ROSUVASTATIN CALCIUM"
            },
            "AIDAREX PHARMACEUTICALS": {
              "ATORVASTATIN CALCIUM": "ATORVASTATIN CALCIUM",
              "CRESTOR": "CRESTOR",
              "LOVASTATIN": "LOVASTATIN",
              "PRAVASTATIN SODIUM": "PRAVASTATIN SODIUM",
              "ROSUVASTATIN CALCIUM": "ROSUVASTATIN CALCIUM",
              "SIMVASTATIN": "SIMVASTATIN"
            },
            "AKRON PHARMA": {
              "ATORVASTATIN CALCIUM": "ATORVASTATIN CALCIUM"
            },
            "ALTURA PHARMACEUTICALS": {
              "LIPITOR": "LIPITOR"
            },
            "AMERICAN HEALTH PACKAGING": {
              "ATORVASTATIN CALCIUM": "ATORVASTATIN CALCIUM",
              "LOVASTATIN": "LOVASTATIN",
              "PRAVASTATIN SODIUM": "PRAVASTATIN SODIUM",
              "ROSUVASTATIN CALCIUM": "ROSUVASTATIN CALCIUM",
              "SIMVASTATIN": "SIMVASTATIN"
            },
            "APHENA PHARMA SOLUTIONS": {
              "ATORVASTATIN CALCIUM": "ATORVASTATIN CALCIUM",
              "CRESTOR": "CRESTOR",
              "LIPITOR": "LIPITOR",
              "LOVASTATIN": "LOVASTATIN",
              "PRAVASTATIN SODIUM": "PRAVASTATIN SODIUM",
              "ROSUVASTATIN CALCIUM": "ROSUVASTATIN CALCIUM",
              "SIMVASTATIN": "SIMVASTATIN",
              "ZOCOR": "ZOCOR"
            },
            "APOTEX": {
              "ATORVASTATIN CALCIUM": "ATORVASTATIN CALCIUM",
              "PRAVASTATIN SODIUM": "PRAVASTATIN SODIUM",
              "ROSUVASTATIN CALCIUM": "ROSUVASTATIN CALCIUM"
            },
            "AQ PHARMACEUTICALS": {
              "CRESTOR": "CRESTOR",
              "LESCOL": "LESCOL",
              "PRAVACHOL": "PRAVACHOL",
              "ZOCOR": "ZOCOR"
            },
            "ASCEND LABORATORIES": {
              "ATORVASTATIN CALCIUM": "ATORVASTATIN CALCIUM",
              "ROSUVASTATIN CALCIUM": "ROSUVASTATIN CALCIUM"
            },
            "ASTRAZENECA": {
              "CRESTOR": "CRESTOR"
            },
            "AUROBINDO PHARMA": {
              "ROSUVASTATIN CALCIUM": "ROSUVASTATIN CALCIUM",
              "SIMVASTATIN": "SIMVASTATIN"
            },
            "AVKARE": {
              "ATORVASTATIN CALCIUM": "ATORVASTATIN CALCIUM",
              "LOVASTATIN": "LOVASTATIN",
              "PRAVASTATIN SODIUM": "PRAVASTATIN SODIUM",
              "ROSUVASTATIN CALCIUM": "ROSUVASTATIN CALCIUM"
            },
            "AVPAK": {
              "ATORVASTATIN CALCIUM": "ATORVASTATIN CALCIUM",
              "LOVASTATIN": "LOVASTATIN",
              "PRAVASTATIN SODIUM": "PRAVASTATIN SODIUM",
              "ROSUVASTATIN CALCIUM": "ROSUVASTATIN CALCIUM",
              "SIMVASTATIN": "SIMVASTATIN"
            },
            "AYURAX": {
              "SIMVASTATIN": "SIMVASTATIN"
            },
            "B-M SQUIBB U.S. (PRIMARY CARE)": {
              "PRAVACHOL": "PRAVACHOL"
            },
            "BIOCON PHARMA": {
              "ATORVASTATIN CALCIUM": "ATORVASTATIN CALCIUM",
              "ROSUVASTATIN CALCIUM": "ROSUVASTATIN CALCIUM",
              "SIMVASTATIN": "SIMVASTATIN"
            },
            "BLENHEIM PHARMACAL": {
              "LOVASTATIN": "LOVASTATIN",
              "PRAVASTATIN SODIUM": "PRAVASTATIN SODIUM",
              "SIMVASTATIN": "SIMVASTATIN"
            },
            "BLU PHARMACEUTICALS": {
              "SIMVASTATIN": "SIMVASTATIN"
            },
            "BLUE POINT LABORATORIES": {
              "LOVASTATIN": "LOVASTATIN"
            },
            "BRYANT RANCH PREPACK": {
              "ATORVASTATIN CALCIUM": "ATORVASTATIN CALCIUM",
              "CRESTOR": "CRESTOR",
              "LIPITOR": "LIPITOR",
              "LOVASTATIN": "LOVASTATIN",
              "PRAVASTATIN SODIUM": "PRAVASTATIN SODIUM",
              "ROSUVASTATIN CALCIUM": "ROSUVASTATIN CALCIUM",
              "SIMVASTATIN": "SIMVASTATIN"
            },
            "CAMBER PHARMACEUTICALS": {
              "ROSUVASTATIN CALCIUM": "ROSUVASTATIN CALCIUM",
              "SIMVASTATIN": "SIMVASTATIN"
            },
            "CAMBRIDGE THERAPEUTICS TECHN": {
              "ATORVASTATIN CALCIUM": "ATORVASTATIN CALCIUM"
            },
            "CARDINAL HEALTH REPACKAGING SO": {
              "ATORVASTATIN CALCIUM": "ATORVASTATIN CALCIUM"
            },
            "CARLSBAD TECHNOLOGIES": {
              "LOVASTATIN": "LOVASTATIN"
            },
            "CIPLA USA": {
              "ATORVASTATIN CALCIUM": "ATORVASTATIN CALCIUM"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "LOVASTATIN": "LOVASTATIN",
              "PRAVASTATIN SODIUM": "PRAVASTATIN SODIUM",
              "SIMVASTATIN": "SIMVASTATIN"
            },
            "COVIS PHARMA": {
              "ALTOPREV": "ALTOPREV"
            },
            "DENTON PHARMA": {
              "ATORVASTATIN CALCIUM": "ATORVASTATIN CALCIUM",
              "PRAVASTATIN SODIUM": "PRAVASTATIN SODIUM"
            },
            "DIRECT RX": {
              "ATORVASTATIN CALCIUM": "ATORVASTATIN CALCIUM",
              "LOVASTATIN": "LOVASTATIN",
              "PRAVASTATIN SODIUM": "PRAVASTATIN SODIUM",
              "ROSUVASTATIN CALCIUM": "ROSUVASTATIN CALCIUM",
              "SIMVASTATIN": "SIMVASTATIN"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "ATORVASTATIN CALCIUM": "ATORVASTATIN CALCIUM",
              "PRAVASTATIN SODIUM": "PRAVASTATIN SODIUM",
              "SIMVASTATIN": "SIMVASTATIN"
            },
            "GLENMARK PHARMACEUTICALS": {
              "PRAVASTATIN SODIUM": "PRAVASTATIN SODIUM",
              "ROSUVASTATIN CALCIUM": "ROSUVASTATIN CALCIUM"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "ATORVASTATIN CALCIUM": "ATORVASTATIN CALCIUM",
              "LOVASTATIN": "LOVASTATIN",
              "PRAVASTATIN SODIUM": "PRAVASTATIN SODIUM",
              "ROSUVASTATIN CALCIUM": "ROSUVASTATIN CALCIUM"
            },
            "GREENSTONE": {
              "ATORVASTATIN CALCIUM": "ATORVASTATIN CALCIUM"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "LIPITOR": "LIPITOR",
              "LOVASTATIN": "LOVASTATIN",
              "SIMVASTATIN": "SIMVASTATIN",
              "ZOCOR": "ZOCOR"
            },
            "INGENUS PHARMACEUTICALS": {
              "SIMVASTATIN": "SIMVASTATIN"
            },
            "INTERNATIONAL LABS, LLC": {
              "ATORVASTATIN CALCIUM": "ATORVASTATIN CALCIUM",
              "LOVASTATIN": "LOVASTATIN",
              "PRAVASTATIN SODIUM": "PRAVASTATIN SODIUM",
              "SIMVASTATIN": "SIMVASTATIN"
            },
            "KAISER FOUNDATION HOSPITAL": {
              "ATORVASTATIN CALCIUM": "ATORVASTATIN CALCIUM",
              "SIMVASTATIN": "SIMVASTATIN"
            },
            "KOWA PHARMACEUTICALS AMERICA": {
              "LIVALO": "LIVALO"
            },
            "KREMERS URBAN": {
              "ATORVASTATIN CALCIUM": "ATORVASTATIN CALCIUM"
            },
            "LEGACY PHARMACEUTICAL PKG": {
              "ATORVASTATIN CALCIUM": "ATORVASTATIN CALCIUM",
              "LOVASTATIN": "LOVASTATIN",
              "SIMVASTATIN": "SIMVASTATIN"
            },
            "LIBERTY PHARMACEUTICAL": {
              "ATORVASTATIN CALCIUM": "ATORVASTATIN CALCIUM",
              "LOVASTATIN": "LOVASTATIN",
              "PRAVASTATIN SODIUM": "PRAVASTATIN SODIUM",
              "SIMVASTATIN": "SIMVASTATIN"
            },
            "LUPIN PHARMACEUTICALS": {
              "ATORVASTATIN CALCIUM": "ATORVASTATIN CALCIUM",
              "LOVASTATIN": "LOVASTATIN",
              "PRAVASTATIN SODIUM": "PRAVASTATIN SODIUM",
              "SIMVASTATIN": "SIMVASTATIN"
            },
            "MAJOR PHARMACEUTICALS": {
              "ATORVASTATIN CALCIUM": "ATORVASTATIN CALCIUM",
              "PRAVASTATIN SODIUM": "PRAVASTATIN SODIUM",
              "ROSUVASTATIN CALCIUM": "ROSUVASTATIN CALCIUM",
              "SIMVASTATIN": "SIMVASTATIN"
            },
            "MALLINCKRODT PHARM": {
              "SIMVASTATIN": "SIMVASTATIN"
            },
            "MARLEX PHARMACEUTICALS": {
              "ATORVASTATIN CALCIUM": "ATORVASTATIN CALCIUM",
              "PRAVASTATIN SODIUM": "PRAVASTATIN SODIUM",
              "SIMVASTATIN": "SIMVASTATIN"
            },
            "MEDICURE": {
              "ZYPITAMAG": "ZYPITAMAG"
            },
            "MEDVANTX": {
              "LIPITOR": "LIPITOR",
              "LOVASTATIN": "LOVASTATIN",
              "PRAVACHOL": "PRAVACHOL"
            },
            "MERCK SHARP & DOHME": {
              "MEVACOR": "MEVACOR",
              "ZOCOR": "ZOCOR"
            },
            "MICRO LABORATORIES": {
              "ATORVASTATIN CALCIUM": "ATORVASTATIN CALCIUM",
              "SIMVASTATIN": "SIMVASTATIN"
            },
            "MYLAN": {
              "ATORVASTATIN CALCIUM": "ATORVASTATIN CALCIUM",
              "FLUVASTATIN SODIUM": "FLUVASTATIN SODIUM",
              "FLUVASTATIN SODIUM ER": "FLUVASTATIN SODIUM ER",
              "LOVASTATIN": "LOVASTATIN",
              "PRAVASTATIN SODIUM": "PRAVASTATIN SODIUM",
              "ROSUVASTATIN CALCIUM": "ROSUVASTATIN CALCIUM"
            },
            "MYLAN INSTITUTIONAL": {
              "ATORVASTATIN CALCIUM": "ATORVASTATIN CALCIUM",
              "LOVASTATIN": "LOVASTATIN",
              "PRAVASTATIN SODIUM": "PRAVASTATIN SODIUM",
              "ROSUVASTATIN CALCIUM": "ROSUVASTATIN CALCIUM",
              "SIMVASTATIN": "SIMVASTATIN"
            },
            "NEW HORIZON RX GROUP": {
              "ATORVASTATIN CALCIUM": "ATORVASTATIN CALCIUM"
            },
            "NORTHSTAR RX": {
              "ATORVASTATIN CALCIUM": "ATORVASTATIN CALCIUM",
              "ROSUVASTATIN CALCIUM": "ROSUVASTATIN CALCIUM",
              "SIMVASTATIN": "SIMVASTATIN"
            },
            "NOVADOZ PHARMACEUTICALS": {
              "ATORVASTATIN CALCIUM": "ATORVASTATIN CALCIUM",
              "ROSUVASTATIN CALCIUM": "ROSUVASTATIN CALCIUM"
            },
            "NOVARTIS": {
              "LESCOL XL": "LESCOL XL"
            },
            "NUCARE PHARMACEUTICALS": {
              "ATORVASTATIN CALCIUM": "ATORVASTATIN CALCIUM",
              "CRESTOR": "CRESTOR",
              "LIPITOR": "LIPITOR",
              "LOVASTATIN": "LOVASTATIN",
              "SIMVASTATIN": "SIMVASTATIN"
            },
            "PAR PHARMACEUTICAL": {
              "ROSUVASTATIN CALCIUM": "ROSUVASTATIN CALCIUM"
            },
            "PDRX PHARMACEUTICAL": {
              "ATORVASTATIN CALCIUM": "ATORVASTATIN CALCIUM",
              "CRESTOR": "CRESTOR",
              "LIPITOR": "LIPITOR",
              "LOVASTATIN": "LOVASTATIN",
              "MEVACOR": "MEVACOR",
              "PRAVACHOL": "PRAVACHOL",
              "PRAVASTATIN SODIUM": "PRAVASTATIN SODIUM",
              "SIMVASTATIN": "SIMVASTATIN",
              "ZOCOR": "ZOCOR"
            },
            "PDRX PHARMACEUTICALS": {
              "ATORVASTATIN CALCIUM": "ATORVASTATIN CALCIUM"
            },
            "PFIZER U.S.": {
              "LIPITOR": "LIPITOR"
            },
            "PHARMEDIX": {
              "LOVASTATIN": "LOVASTATIN",
              "SIMVASTATIN": "SIMVASTATIN"
            },
            "PREFERRED PHARMACEUTICALS": {
              "ATORVASTATIN CALCIUM": "ATORVASTATIN CALCIUM",
              "LOVASTATIN": "LOVASTATIN",
              "PRAVASTATIN SODIUM": "PRAVASTATIN SODIUM",
              "ROSUVASTATIN CALCIUM": "ROSUVASTATIN CALCIUM",
              "SIMVASTATIN": "SIMVASTATIN"
            },
            "PROFICIENT RX": {
              "ATORVASTATIN CALCIUM": "ATORVASTATIN CALCIUM",
              "LOVASTATIN": "LOVASTATIN",
              "PRAVASTATIN SODIUM": "PRAVASTATIN SODIUM",
              "ROSUVASTATIN CALCIUM": "ROSUVASTATIN CALCIUM",
              "SIMVASTATIN": "SIMVASTATIN"
            },
            "QUALITY CARE": {
              "ATORVASTATIN CALCIUM": "ATORVASTATIN CALCIUM",
              "CRESTOR": "CRESTOR",
              "LOVASTATIN": "LOVASTATIN",
              "PRAVASTATIN SODIUM": "PRAVASTATIN SODIUM",
              "SIMVASTATIN": "SIMVASTATIN",
              "ZOCOR": "ZOCOR"
            },
            "QUALITY CARE PRODUCTS": {
              "ATORVASTATIN CALCIUM": "ATORVASTATIN CALCIUM",
              "LOVASTATIN": "LOVASTATIN",
              "ROSUVASTATIN CALCIUM": "ROSUVASTATIN CALCIUM",
              "SIMVASTATIN": "SIMVASTATIN"
            },
            "RISING PHARMACEUTICALS": {
              "PRAVASTATIN SODIUM": "PRAVASTATIN SODIUM",
              "ROSUVASTATIN CALCIUM": "ROSUVASTATIN CALCIUM"
            },
            "RXCHANGE CO": {
              "LIPITOR": "LIPITOR",
              "LOVASTATIN": "LOVASTATIN",
              "SIMVASTATIN": "SIMVASTATIN"
            },
            "SALERNO PHARMACEUTICALS": {
              "FLOLIPID": "FLOLIPID"
            },
            "SANDOZ": {
              "ATORVASTATIN CALCIUM": "ATORVASTATIN CALCIUM",
              "FLUVASTATIN SODIUM ER": "FLUVASTATIN SODIUM ER",
              "LOVASTATIN": "LOVASTATIN",
              "PRAVASTATIN SODIUM": "PRAVASTATIN SODIUM",
              "ROSUVASTATIN CALCIUM": "ROSUVASTATIN CALCIUM",
              "SIMVASTATIN": "SIMVASTATIN"
            },
            "SKY PACKAGING": {
              "ATORVASTATIN CALCIUM": "ATORVASTATIN CALCIUM",
              "LOVASTATIN": "LOVASTATIN",
              "PRAVASTATIN SODIUM": "PRAVASTATIN SODIUM",
              "SIMVASTATIN": "SIMVASTATIN"
            },
            "ST MARY'S MPP": {
              "ATORVASTATIN CALCIUM": "ATORVASTATIN CALCIUM",
              "LOVASTATIN": "LOVASTATIN",
              "PRAVASTATIN SODIUM": "PRAVASTATIN SODIUM",
              "SIMVASTATIN": "SIMVASTATIN"
            },
            "SUN PHARMACEUTICALS": {
              "ATORVASTATIN CALCIUM": "ATORVASTATIN CALCIUM",
              "EZALLOR SPRINKLE": "EZALLOR SPRINKLE",
              "ROSUVASTATIN CALCIUM": "ROSUVASTATIN CALCIUM",
              "SIMVASTATIN": "SIMVASTATIN"
            },
            "TEVA PHARMACEUTICALS USA": {
              "ATORVASTATIN CALCIUM": "ATORVASTATIN CALCIUM",
              "FLUVASTATIN SODIUM": "FLUVASTATIN SODIUM",
              "FLUVASTATIN SODIUM ER": "FLUVASTATIN SODIUM ER",
              "LOVASTATIN": "LOVASTATIN",
              "PRAVASTATIN SODIUM": "PRAVASTATIN SODIUM",
              "ROSUVASTATIN CALCIUM": "ROSUVASTATIN CALCIUM",
              "SIMVASTATIN": "SIMVASTATIN"
            },
            "TORRENT PHARMACEUTICALS": {
              "ROSUVASTATIN CALCIUM": "ROSUVASTATIN CALCIUM"
            },
            "TRIS PHARMA": {
              "ROSUVASTATIN CALCIUM": "ROSUVASTATIN CALCIUM"
            },
            "UNIT DOSE SERVICES": {
              "ATORVASTATIN CALCIUM": "ATORVASTATIN CALCIUM",
              "SIMVASTATIN": "SIMVASTATIN"
            },
            "VA CMOP DALLAS": {
              "LOVASTATIN": "LOVASTATIN",
              "SIMVASTATIN": "SIMVASTATIN"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "ATORVASTATIN CALCIUM": "ATORVASTATIN CALCIUM",
              "PRAVASTATIN SODIUM": "PRAVASTATIN SODIUM",
              "SIMVASTATIN": "SIMVASTATIN"
            }
          }
        },
        "Intestinal Cholesterol Absorption Inhibitors": {
          "Intestinal Cholesterol Absorption Inhibitors": {
            "A-S MEDICATION SOLUTIONS": {
              "EZETIMIBE": "EZETIMIBE",
              "ZETIA": "ZETIA"
            },
            "ACCORD HEALTHCARE": {
              "EZETIMIBE": "EZETIMIBE"
            },
            "ACTAVIS PHARMA": {
              "EZETIMIBE": "EZETIMIBE"
            },
            "AMERICAN HEALTH PACKAGING": {
              "EZETIMIBE": "EZETIMIBE"
            },
            "AMNEAL PHARMACEUTICALS": {
              "EZETIMIBE": "EZETIMIBE"
            },
            "APHENA PHARMA SOLUTIONS": {
              "ZETIA": "ZETIA"
            },
            "APOTEX": {
              "EZETIMIBE": "EZETIMIBE"
            },
            "AQ PHARMACEUTICALS": {
              "ZETIA": "ZETIA"
            },
            "ASCEND LABORATORIES": {
              "EZETIMIBE": "EZETIMIBE"
            },
            "AUROBINDO PHARMA": {
              "EZETIMIBE": "EZETIMIBE"
            },
            "AVKARE": {
              "EZETIMIBE": "EZETIMIBE"
            },
            "AVPAK": {
              "EZETIMIBE": "EZETIMIBE"
            },
            "CARDINAL HEALTH REPACKAGING SO": {
              "ZETIA": "ZETIA"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "ZETIA": "ZETIA"
            },
            "DIRECT RX": {
              "EZETIMIBE": "EZETIMIBE"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "EZETIMIBE": "EZETIMIBE"
            },
            "MAJOR PHARMACEUTICALS": {
              "EZETIMIBE": "EZETIMIBE"
            },
            "MERCK/SCHERING-PLOUGH PHARM": {
              "ZETIA": "ZETIA"
            },
            "OHM LABS": {
              "EZETIMIBE": "EZETIMIBE"
            },
            "PAR PHARMACEUTICAL": {
              "EZETIMIBE": "EZETIMIBE"
            },
            "PDRX PHARMACEUTICAL": {
              "ZETIA": "ZETIA"
            },
            "PROFICIENT RX": {
              "EZETIMIBE": "EZETIMIBE"
            },
            "SANDOZ": {
              "EZETIMIBE": "EZETIMIBE"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "EZETIMIBE": "EZETIMIBE"
            }
          }
        },
        "Microsomal Triglyceride Transfer Protein (MTP) Inhibitors": {
          "Microsomal Triglyceride Transfer Protein Inhibitors": {
            "AEGERION PHARMACEUTICALS": {
              "JUXTAPID": "JUXTAPID"
            }
          }
        },
        "Nicotinic Acid Derivatives": {
          "Nicotinic Acid Derivatives": {
            "A-S MEDICATION SOLUTIONS": {
              "NIASPAN": "NIASPAN"
            },
            "ABBVIE": {
              "NIASPAN": "NIASPAN"
            },
            "AMERICAN HEALTH PACKAGING": {
              "NIACIN ER (ANTIHYPERLIPIDEMIC)": "NIACIN ER (ANTIHYPERLIPIDEMIC)"
            },
            "AMNEAL PHARMACEUTICALS": {
              "NIACIN ER (ANTIHYPERLIPIDEMIC)": "NIACIN ER (ANTIHYPERLIPIDEMIC)"
            },
            "APHENA PHARMA SOLUTIONS": {
              "NIASPAN": "NIASPAN"
            },
            "AUROBINDO PHARMA": {
              "NIACIN ER (ANTIHYPERLIPIDEMIC)": "NIACIN ER (ANTIHYPERLIPIDEMIC)"
            },
            "AVKARE": {
              "NIACIN ER (ANTIHYPERLIPIDEMIC)": "NIACIN ER (ANTIHYPERLIPIDEMIC)"
            },
            "AVONDALE": {
              "NIACOR": "NIACOR"
            },
            "AVPAK": {
              "NIACIN ER (ANTIHYPERLIPIDEMIC)": "NIACIN ER (ANTIHYPERLIPIDEMIC)"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "NIACIN ER (ANTIHYPERLIPIDEMIC)": "NIACIN ER (ANTIHYPERLIPIDEMIC)"
            },
            "KREMERS URBAN": {
              "NIACIN ER (ANTIHYPERLIPIDEMIC)": "NIACIN ER (ANTIHYPERLIPIDEMIC)"
            },
            "LUPIN PHARMACEUTICALS": {
              "NIACIN ER (ANTIHYPERLIPIDEMIC)": "NIACIN ER (ANTIHYPERLIPIDEMIC)"
            },
            "MARLEX PHARMACEUTICALS": {
              "NIACIN ER (ANTIHYPERLIPIDEMIC)": "NIACIN ER (ANTIHYPERLIPIDEMIC)"
            },
            "QUALITY CARE": {
              "NIASPAN": "NIASPAN"
            },
            "REDMONT PHARMACEUTICALS": {
              "NIACIN (ANTIHYPERLIPIDEMIC)": "NIACIN (ANTIHYPERLIPIDEMIC)"
            },
            "SUN PHARMACEUTICALS": {
              "NIACIN ER (ANTIHYPERLIPIDEMIC)": "NIACIN ER (ANTIHYPERLIPIDEMIC)"
            },
            "TEVA PHARMACEUTICALS USA": {
              "NIACIN ER (ANTIHYPERLIPIDEMIC)": "NIACIN ER (ANTIHYPERLIPIDEMIC)"
            },
            "UPSHER-SMITH": {
              "NIACOR": "NIACOR"
            },
            "VA CMOP DALLAS": {
              "NIASPAN": "NIASPAN"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "NIACIN ER (ANTIHYPERLIPIDEMIC)": "NIACIN ER (ANTIHYPERLIPIDEMIC)"
            }
          }
        },
        "Proprotein Convertase Subtilisin/Kexin Type 9 Inhibitors": {
          "PCSK9 Inhibitors": {
            "AMGEN": {
              "REPATHA": "REPATHA",
              "REPATHA PUSHTRONEX SYSTEM": "REPATHA PUSHTRONEX SYSTEM",
              "REPATHA SURECLICK": "REPATHA SURECLICK"
            },
            "SANOFI PHARMACEUTICALS": {
              "PRALUENT": "PRALUENT"
            },
            "SANOFI US": {
              "PRALUENT": "PRALUENT"
            }
          }
        }
      },
      "ANTIHYPERTENSIVES": {
        "ACE Inhibitors": {
          "ACE Inhibitors": {
            "A-S MEDICATION SOLUTIONS": {
              "ALTACE": "ALTACE",
              "BENAZEPRIL HCL": "BENAZEPRIL HCL",
              "CAPTOPRIL": "CAPTOPRIL",
              "ENALAPRIL MALEATE": "ENALAPRIL MALEATE",
              "FOSINOPRIL SODIUM": "FOSINOPRIL SODIUM",
              "LISINOPRIL": "LISINOPRIL",
              "MOEXIPRIL HCL": "MOEXIPRIL HCL",
              "QUINAPRIL HCL": "QUINAPRIL HCL",
              "RAMIPRIL": "RAMIPRIL",
              "ZESTRIL": "ZESTRIL"
            },
            "ABBVIE": {
              "MAVIK": "MAVIK"
            },
            "ACCORD HEALTHCARE": {
              "LISINOPRIL": "LISINOPRIL",
              "RAMIPRIL": "RAMIPRIL"
            },
            "ACTAVIS PHARMA": {
              "LISINOPRIL": "LISINOPRIL",
              "RAMIPRIL": "RAMIPRIL",
              "TRANDOLAPRIL": "TRANDOLAPRIL"
            },
            "ADHERA THERAPEUTICS": {
              "ACEON": "ACEON"
            },
            "AIDAREX PHARMACEUTICALS": {
              "BENAZEPRIL HCL": "BENAZEPRIL HCL",
              "CAPTOPRIL": "CAPTOPRIL",
              "ENALAPRIL MALEATE": "ENALAPRIL MALEATE",
              "FOSINOPRIL SODIUM": "FOSINOPRIL SODIUM",
              "LISINOPRIL": "LISINOPRIL",
              "QUINAPRIL HCL": "QUINAPRIL HCL",
              "RAMIPRIL": "RAMIPRIL"
            },
            "AJANTA PHARMA LIMITED": {
              "CAPTOPRIL": "CAPTOPRIL"
            },
            "ALMATICA": {
              "ZESTRIL": "ZESTRIL"
            },
            "ALTURA PHARMACEUTICALS": {
              "ACCUPRIL": "ACCUPRIL",
              "BENAZEPRIL HCL": "BENAZEPRIL HCL",
              "CAPTOPRIL": "CAPTOPRIL",
              "ENALAPRIL MALEATE": "ENALAPRIL MALEATE",
              "LISINOPRIL": "LISINOPRIL"
            },
            "AMERICAN HEALTH PACKAGING": {
              "BENAZEPRIL HCL": "BENAZEPRIL HCL",
              "CAPTOPRIL": "CAPTOPRIL",
              "ENALAPRIL MALEATE": "ENALAPRIL MALEATE",
              "LISINOPRIL": "LISINOPRIL",
              "QUINAPRIL HCL": "QUINAPRIL HCL",
              "RAMIPRIL": "RAMIPRIL"
            },
            "AMICI PHARMACEUTICALS": {
              "CAPTOPRIL": "CAPTOPRIL"
            },
            "AMNEAL PHARMACEUTICALS": {
              "BENAZEPRIL HCL": "BENAZEPRIL HCL"
            },
            "APACE PACKAGING": {
              "ENALAPRIL MALEATE": "ENALAPRIL MALEATE"
            },
            "APHENA PHARMA SOLUTIONS": {
              "CAPTOPRIL": "CAPTOPRIL",
              "ENALAPRIL MALEATE": "ENALAPRIL MALEATE",
              "FOSINOPRIL SODIUM": "FOSINOPRIL SODIUM",
              "LISINOPRIL": "LISINOPRIL",
              "PRINIVIL": "PRINIVIL"
            },
            "AUROBINDO PHARMA": {
              "BENAZEPRIL HCL": "BENAZEPRIL HCL",
              "FOSINOPRIL SODIUM": "FOSINOPRIL SODIUM",
              "LISINOPRIL": "LISINOPRIL",
              "PERINDOPRIL ERBUMINE": "PERINDOPRIL ERBUMINE",
              "QUINAPRIL HCL": "QUINAPRIL HCL",
              "RAMIPRIL": "RAMIPRIL",
              "TRANDOLAPRIL": "TRANDOLAPRIL"
            },
            "AVET PHARMACEUTICALS": {
              "ENALAPRIL MALEATE": "ENALAPRIL MALEATE"
            },
            "AVKARE": {
              "BENAZEPRIL HCL": "BENAZEPRIL HCL",
              "QUINAPRIL HCL": "QUINAPRIL HCL",
              "RAMIPRIL": "RAMIPRIL",
              "TRANDOLAPRIL": "TRANDOLAPRIL"
            },
            "AVPAK": {
              "BENAZEPRIL HCL": "BENAZEPRIL HCL",
              "FOSINOPRIL SODIUM": "FOSINOPRIL SODIUM"
            },
            "AZURITY PHARMACEUTICALS": {
              "EPANED": "EPANED",
              "QBRELIS": "QBRELIS"
            },
            "BAUSCH HEALTH": {
              "VASOTEC": "VASOTEC"
            },
            "BLENHEIM PHARMACAL": {
              "BENAZEPRIL HCL": "BENAZEPRIL HCL",
              "ENALAPRIL MALEATE": "ENALAPRIL MALEATE",
              "LISINOPRIL": "LISINOPRIL",
              "RAMIPRIL": "RAMIPRIL"
            },
            "BLU PHARMACEUTICALS": {
              "LISINOPRIL": "LISINOPRIL"
            },
            "BLUE POINT LABORATORIES": {
              "LISINOPRIL": "LISINOPRIL",
              "QUINAPRIL HCL": "QUINAPRIL HCL",
              "RAMIPRIL": "RAMIPRIL"
            },
            "BRYANT RANCH PREPACK": {
              "ACCUPRIL": "ACCUPRIL",
              "BENAZEPRIL HCL": "BENAZEPRIL HCL",
              "CAPTOPRIL": "CAPTOPRIL",
              "ENALAPRIL MALEATE": "ENALAPRIL MALEATE",
              "FOSINOPRIL SODIUM": "FOSINOPRIL SODIUM",
              "LISINOPRIL": "LISINOPRIL",
              "QUINAPRIL HCL": "QUINAPRIL HCL",
              "RAMIPRIL": "RAMIPRIL"
            },
            "CAMBER PHARMACEUTICALS": {
              "FOSINOPRIL SODIUM": "FOSINOPRIL SODIUM",
              "LISINOPRIL": "LISINOPRIL",
              "RAMIPRIL": "RAMIPRIL"
            },
            "CAMBRIDGE THERAPEUTICS TECHN": {
              "LISINOPRIL": "LISINOPRIL"
            },
            "CARDINAL HEALTH REPACKAGING SO": {
              "LISINOPRIL": "LISINOPRIL",
              "RAMIPRIL": "RAMIPRIL"
            },
            "CHARTWELL RX": {
              "RAMIPRIL": "RAMIPRIL"
            },
            "CIPLA USA": {
              "FOSINOPRIL SODIUM": "FOSINOPRIL SODIUM",
              "QUINAPRIL HCL": "QUINAPRIL HCL"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "BENAZEPRIL HCL": "BENAZEPRIL HCL",
              "LISINOPRIL": "LISINOPRIL",
              "RAMIPRIL": "RAMIPRIL"
            },
            "DENTON PHARMA": {
              "LISINOPRIL": "LISINOPRIL"
            },
            "DIRECT RX": {
              "BENAZEPRIL HCL": "BENAZEPRIL HCL",
              "ENALAPRIL MALEATE": "ENALAPRIL MALEATE",
              "LISINOPRIL": "LISINOPRIL",
              "RAMIPRIL": "RAMIPRIL"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "RAMIPRIL": "RAMIPRIL"
            },
            "EPIC PHARMA": {
              "TRANDOLAPRIL": "TRANDOLAPRIL"
            },
            "EXELAN PHARMACEUTICALS": {
              "FOSINOPRIL SODIUM": "FOSINOPRIL SODIUM",
              "LISINOPRIL": "LISINOPRIL",
              "RAMIPRIL": "RAMIPRIL"
            },
            "GLENMARK PHARMACEUTICALS": {
              "FOSINOPRIL SODIUM": "FOSINOPRIL SODIUM",
              "MOEXIPRIL HCL": "MOEXIPRIL HCL"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "BENAZEPRIL HCL": "BENAZEPRIL HCL",
              "ENALAPRIL MALEATE": "ENALAPRIL MALEATE",
              "LISINOPRIL": "LISINOPRIL",
              "TRANDOLAPRIL": "TRANDOLAPRIL"
            },
            "GREENSTONE": {
              "QUINAPRIL HCL": "QUINAPRIL HCL"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "BENAZEPRIL HCL": "BENAZEPRIL HCL",
              "ENALAPRIL MALEATE": "ENALAPRIL MALEATE",
              "LISINOPRIL": "LISINOPRIL"
            },
            "HIKMA": {
              "CAPTOPRIL": "CAPTOPRIL",
              "ENALAPRILAT": "ENALAPRILAT",
              "LISINOPRIL": "LISINOPRIL",
              "PERINDOPRIL ERBUMINE": "PERINDOPRIL ERBUMINE",
              "RAMIPRIL": "RAMIPRIL"
            },
            "HOSPIRA": {
              "ENALAPRILAT": "ENALAPRILAT"
            },
            "INTERNATIONAL LABS, LLC": {
              "BENAZEPRIL HCL": "BENAZEPRIL HCL",
              "LISINOPRIL": "LISINOPRIL"
            },
            "KAISER FOUNDATION HOSPITAL": {
              "LISINOPRIL": "LISINOPRIL"
            },
            "LANNETT": {
              "PERINDOPRIL ERBUMINE": "PERINDOPRIL ERBUMINE"
            },
            "LEGACY PHARMACEUTICAL PKG": {
              "CAPTOPRIL": "CAPTOPRIL",
              "ENALAPRIL MALEATE": "ENALAPRIL MALEATE",
              "LISINOPRIL": "LISINOPRIL"
            },
            "LIBERTY PHARMACEUTICAL": {
              "BENAZEPRIL HCL": "BENAZEPRIL HCL",
              "CAPTOPRIL": "CAPTOPRIL",
              "ENALAPRIL MALEATE": "ENALAPRIL MALEATE",
              "FOSINOPRIL SODIUM": "FOSINOPRIL SODIUM",
              "LISINOPRIL": "LISINOPRIL",
              "QUINAPRIL HCL": "QUINAPRIL HCL",
              "TRANDOLAPRIL": "TRANDOLAPRIL"
            },
            "LUPIN PHARMACEUTICALS": {
              "LISINOPRIL": "LISINOPRIL",
              "QUINAPRIL HCL": "QUINAPRIL HCL",
              "RAMIPRIL": "RAMIPRIL",
              "TRANDOLAPRIL": "TRANDOLAPRIL"
            },
            "MAJOR PHARMACEUTICALS": {
              "CAPTOPRIL": "CAPTOPRIL",
              "ENALAPRIL MALEATE": "ENALAPRIL MALEATE",
              "LISINOPRIL": "LISINOPRIL"
            },
            "MARLEX PHARMACEUTICALS": {
              "PERINDOPRIL ERBUMINE": "PERINDOPRIL ERBUMINE"
            },
            "MEDVANTX": {
              "ENALAPRIL MALEATE": "ENALAPRIL MALEATE",
              "LOTENSIN": "LOTENSIN",
              "ZESTRIL": "ZESTRIL"
            },
            "MERCK SHARP & DOHME": {
              "PRINIVIL": "PRINIVIL"
            },
            "MYLAN": {
              "BENAZEPRIL HCL": "BENAZEPRIL HCL",
              "CAPTOPRIL": "CAPTOPRIL",
              "ENALAPRIL MALEATE": "ENALAPRIL MALEATE",
              "LISINOPRIL": "LISINOPRIL"
            },
            "MYLAN INSTITUTIONAL": {
              "BENAZEPRIL HCL": "BENAZEPRIL HCL",
              "CAPTOPRIL": "CAPTOPRIL",
              "ENALAPRIL MALEATE": "ENALAPRIL MALEATE",
              "LISINOPRIL": "LISINOPRIL"
            },
            "NORTHSTAR RX": {
              "ENALAPRIL MALEATE": "ENALAPRIL MALEATE"
            },
            "NUCARE PHARMACEUTICALS": {
              "BENAZEPRIL HCL": "BENAZEPRIL HCL",
              "ENALAPRIL MALEATE": "ENALAPRIL MALEATE",
              "LISINOPRIL": "LISINOPRIL",
              "VASOTEC": "VASOTEC"
            },
            "OCEANSIDE PHARMACEUTICALS": {
              "ENALAPRIL MALEATE": "ENALAPRIL MALEATE"
            },
            "PAR PHARMACEUTICALS": {
              "LISINOPRIL": "LISINOPRIL"
            },
            "PDRX PHARMACEUTICAL": {
              "ACCUPRIL": "ACCUPRIL",
              "ALTACE": "ALTACE",
              "BENAZEPRIL HCL": "BENAZEPRIL HCL",
              "CAPTOPRIL": "CAPTOPRIL",
              "ENALAPRIL MALEATE": "ENALAPRIL MALEATE",
              "FOSINOPRIL SODIUM": "FOSINOPRIL SODIUM",
              "LISINOPRIL": "LISINOPRIL",
              "LOTENSIN": "LOTENSIN",
              "PRINIVIL": "PRINIVIL",
              "RAMIPRIL": "RAMIPRIL",
              "VASOTEC": "VASOTEC",
              "ZESTRIL": "ZESTRIL"
            },
            "PDRX PHARMACEUTICALS": {
              "ENALAPRIL MALEATE": "ENALAPRIL MALEATE",
              "LISINOPRIL": "LISINOPRIL"
            },
            "PFIZER U.S.": {
              "ACCUPRIL": "ACCUPRIL",
              "ALTACE": "ALTACE"
            },
            "PHARMEDIX": {
              "CAPTOPRIL": "CAPTOPRIL",
              "LISINOPRIL": "LISINOPRIL"
            },
            "PREFERRED PHARMACEUTICALS": {
              "BENAZEPRIL HCL": "BENAZEPRIL HCL",
              "ENALAPRIL MALEATE": "ENALAPRIL MALEATE",
              "FOSINOPRIL SODIUM": "FOSINOPRIL SODIUM",
              "LISINOPRIL": "LISINOPRIL",
              "RAMIPRIL": "RAMIPRIL"
            },
            "PROFICIENT RX": {
              "BENAZEPRIL HCL": "BENAZEPRIL HCL",
              "ENALAPRIL MALEATE": "ENALAPRIL MALEATE",
              "LISINOPRIL": "LISINOPRIL",
              "QUINAPRIL HCL": "QUINAPRIL HCL",
              "RAMIPRIL": "RAMIPRIL"
            },
            "QUALITY CARE": {
              "ALTACE": "ALTACE",
              "BENAZEPRIL HCL": "BENAZEPRIL HCL",
              "CAPTOPRIL": "CAPTOPRIL",
              "ENALAPRIL MALEATE": "ENALAPRIL MALEATE",
              "FOSINOPRIL SODIUM": "FOSINOPRIL SODIUM",
              "LISINOPRIL": "LISINOPRIL",
              "LOTENSIN": "LOTENSIN",
              "MAVIK": "MAVIK",
              "PERINDOPRIL ERBUMINE": "PERINDOPRIL ERBUMINE",
              "QUINAPRIL HCL": "QUINAPRIL HCL",
              "RAMIPRIL": "RAMIPRIL"
            },
            "QUALITY CARE PRODUCTS": {
              "BENAZEPRIL HCL": "BENAZEPRIL HCL",
              "LISINOPRIL": "LISINOPRIL",
              "RAMIPRIL": "RAMIPRIL"
            },
            "RISING PHARMACEUTICALS": {
              "LISINOPRIL": "LISINOPRIL",
              "RAMIPRIL": "RAMIPRIL",
              "TRANDOLAPRIL": "TRANDOLAPRIL"
            },
            "RXCHANGE CO": {
              "BENAZEPRIL HCL": "BENAZEPRIL HCL",
              "ENALAPRIL MALEATE": "ENALAPRIL MALEATE",
              "LISINOPRIL": "LISINOPRIL"
            },
            "SANDOZ": {
              "BENAZEPRIL HCL": "BENAZEPRIL HCL",
              "CAPTOPRIL": "CAPTOPRIL",
              "ENALAPRIL MALEATE": "ENALAPRIL MALEATE",
              "FOSINOPRIL SODIUM": "FOSINOPRIL SODIUM",
              "LISINOPRIL": "LISINOPRIL"
            },
            "SKY PACKAGING": {
              "LISINOPRIL": "LISINOPRIL"
            },
            "SOLCO HEALTHCARE": {
              "BENAZEPRIL HCL": "BENAZEPRIL HCL",
              "FOSINOPRIL SODIUM": "FOSINOPRIL SODIUM",
              "LISINOPRIL": "LISINOPRIL",
              "QUINAPRIL HCL": "QUINAPRIL HCL"
            },
            "ST MARY'S MPP": {
              "BENAZEPRIL HCL": "BENAZEPRIL HCL",
              "CAPTOPRIL": "CAPTOPRIL",
              "ENALAPRIL MALEATE": "ENALAPRIL MALEATE",
              "FOSINOPRIL SODIUM": "FOSINOPRIL SODIUM",
              "LISINOPRIL": "LISINOPRIL",
              "RAMIPRIL": "RAMIPRIL"
            },
            "SUN PHARMACEUTICALS": {
              "BENAZEPRIL HCL": "BENAZEPRIL HCL",
              "LISINOPRIL": "LISINOPRIL"
            },
            "TARO": {
              "ENALAPRIL MALEATE": "ENALAPRIL MALEATE"
            },
            "TEVA PHARMACEUTICALS USA": {
              "BENAZEPRIL HCL": "BENAZEPRIL HCL",
              "ENALAPRIL MALEATE": "ENALAPRIL MALEATE",
              "FOSINOPRIL SODIUM": "FOSINOPRIL SODIUM",
              "LISINOPRIL": "LISINOPRIL",
              "MOEXIPRIL HCL": "MOEXIPRIL HCL",
              "RAMIPRIL": "RAMIPRIL",
              "TRANDOLAPRIL": "TRANDOLAPRIL"
            },
            "TRIGEN LABORATORIES": {
              "BENAZEPRIL HCL": "BENAZEPRIL HCL"
            },
            "UNIT DOSE SERVICES": {
              "LISINOPRIL": "LISINOPRIL"
            },
            "VA CMOP DALLAS": {
              "LISINOPRIL": "LISINOPRIL"
            },
            "VALIDUS PHARMACEUTICALS": {
              "LOTENSIN": "LOTENSIN"
            },
            "WOCKHARDT USA": {
              "ENALAPRIL MALEATE": "ENALAPRIL MALEATE"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "RAMIPRIL": "RAMIPRIL"
            }
          }
        },
        "Agents for Pheochromocytoma": {
          "Agents for Pheochromocytoma": {
            "AMNEAL PHARMACEUTICALS": {
              "METYROSINE": "METYROSINE",
              "PHENOXYBENZAMINE HCL": "PHENOXYBENZAMINE HCL"
            },
            "BAUSCH HEALTH": {
              "DEMSER": "DEMSER"
            },
            "CONCORDIA PHARMACEUTICALS": {
              "DIBENZYLINE": "DIBENZYLINE"
            },
            "HIKMA": {
              "PHENOXYBENZAMINE HCL": "PHENOXYBENZAMINE HCL",
              "PHENTOLAMINE MESYLATE": "PHENTOLAMINE MESYLATE"
            },
            "OCEANSIDE PHARMACEUTICALS": {
              "METYROSINE": "METYROSINE"
            },
            "PAR PHARMACEUTICAL": {
              "PHENOXYBENZAMINE HCL": "PHENOXYBENZAMINE HCL"
            },
            "PRASCO LABORATORIES": {
              "PHENOXYBENZAMINE HCL": "PHENOXYBENZAMINE HCL"
            },
            "PRECISION DOSE, INC": {
              "PHENTOLAMINE MESYLATE": "PHENTOLAMINE MESYLATE"
            },
            "TAGI PHARMA": {
              "PHENTOLAMINE MESYLATE": "PHENTOLAMINE MESYLATE"
            }
          }
        },
        "Angiotensin II Receptor Antagonists": {
          "Angiotensin II Receptor Antagonists": {
            "A-S MEDICATION SOLUTIONS": {
              "ATACAND": "ATACAND",
              "AVAPRO": "AVAPRO",
              "BENICAR": "BENICAR",
              "COZAAR": "COZAAR",
              "DIOVAN": "DIOVAN",
              "IRBESARTAN": "IRBESARTAN",
              "LOSARTAN POTASSIUM": "LOSARTAN POTASSIUM",
              "OLMESARTAN MEDOXOMIL": "OLMESARTAN MEDOXOMIL",
              "VALSARTAN": "VALSARTAN"
            },
            "ACCORD HEALTHCARE": {
              "OLMESARTAN MEDOXOMIL": "OLMESARTAN MEDOXOMIL"
            },
            "ACETRIS HEALTH": {
              "VALSARTAN": "VALSARTAN"
            },
            "ACTAVIS PHARMA": {
              "LOSARTAN POTASSIUM": "LOSARTAN POTASSIUM",
              "TELMISARTAN": "TELMISARTAN",
              "VALSARTAN": "VALSARTAN"
            },
            "AIDAREX PHARMACEUTICALS": {
              "LOSARTAN POTASSIUM": "LOSARTAN POTASSIUM"
            },
            "ALEMBIC PHARMACEUTICALS": {
              "CANDESARTAN CILEXETIL": "CANDESARTAN CILEXETIL",
              "IRBESARTAN": "IRBESARTAN",
              "LOSARTAN POTASSIUM": "LOSARTAN POTASSIUM",
              "OLMESARTAN MEDOXOMIL": "OLMESARTAN MEDOXOMIL",
              "TELMISARTAN": "TELMISARTAN",
              "VALSARTAN": "VALSARTAN"
            },
            "AMERICAN HEALTH PACKAGING": {
              "CANDESARTAN CILEXETIL": "CANDESARTAN CILEXETIL",
              "IRBESARTAN": "IRBESARTAN",
              "LOSARTAN POTASSIUM": "LOSARTAN POTASSIUM",
              "VALSARTAN": "VALSARTAN"
            },
            "AMNEAL PHARMACEUTICALS": {
              "VALSARTAN": "VALSARTAN"
            },
            "ANI  PHARMACEUTICALS": {
              "ATACAND": "ATACAND"
            },
            "APHENA PHARMA SOLUTIONS": {
              "ATACAND": "ATACAND",
              "AVAPRO": "AVAPRO",
              "COZAAR": "COZAAR",
              "DIOVAN": "DIOVAN",
              "LOSARTAN POTASSIUM": "LOSARTAN POTASSIUM",
              "VALSARTAN": "VALSARTAN"
            },
            "APOTEX": {
              "CANDESARTAN CILEXETIL": "CANDESARTAN CILEXETIL",
              "LOSARTAN POTASSIUM": "LOSARTAN POTASSIUM",
              "OLMESARTAN MEDOXOMIL": "OLMESARTAN MEDOXOMIL"
            },
            "AQ PHARMACEUTICALS": {
              "AVAPRO": "AVAPRO",
              "COZAAR": "COZAAR",
              "MICARDIS": "MICARDIS"
            },
            "ARBOR PHARMACEUTICALS": {
              "EDARBI": "EDARBI"
            },
            "ASCEND LABORATORIES": {
              "OLMESARTAN MEDOXOMIL": "OLMESARTAN MEDOXOMIL",
              "TELMISARTAN": "TELMISARTAN",
              "VALSARTAN": "VALSARTAN"
            },
            "ASTRAZENECA LP": {
              "ATACAND": "ATACAND"
            },
            "AUROBINDO PHARMA": {
              "IRBESARTAN": "IRBESARTAN",
              "LOSARTAN POTASSIUM": "LOSARTAN POTASSIUM",
              "OLMESARTAN MEDOXOMIL": "OLMESARTAN MEDOXOMIL",
              "TELMISARTAN": "TELMISARTAN",
              "VALSARTAN": "VALSARTAN"
            },
            "AUROBINDO PHARMA USA": {
              "LOSARTAN POTASSIUM": "LOSARTAN POTASSIUM"
            },
            "AVET PHARMACEUTICALS": {
              "LOSARTAN POTASSIUM": "LOSARTAN POTASSIUM"
            },
            "AVKARE": {
              "IRBESARTAN": "IRBESARTAN",
              "LOSARTAN POTASSIUM": "LOSARTAN POTASSIUM",
              "OLMESARTAN MEDOXOMIL": "OLMESARTAN MEDOXOMIL",
              "TELMISARTAN": "TELMISARTAN",
              "VALSARTAN": "VALSARTAN"
            },
            "AVPAK": {
              "IRBESARTAN": "IRBESARTAN",
              "LOSARTAN POTASSIUM": "LOSARTAN POTASSIUM",
              "VALSARTAN": "VALSARTAN"
            },
            "B-M SQUIBB U.S. (PRIMARY CARE)": {
              "AVAPRO": "AVAPRO"
            },
            "BIOGEN PHARMACEUTICALS": {
              "COZAAR": "COZAAR"
            },
            "BOEHRINGER INGELHEIM": {
              "MICARDIS": "MICARDIS"
            },
            "BRYANT RANCH PREPACK": {
              "ATACAND": "ATACAND",
              "AVAPRO": "AVAPRO",
              "BENICAR": "BENICAR",
              "COZAAR": "COZAAR",
              "DIOVAN": "DIOVAN",
              "IRBESARTAN": "IRBESARTAN",
              "LOSARTAN POTASSIUM": "LOSARTAN POTASSIUM",
              "OLMESARTAN MEDOXOMIL": "OLMESARTAN MEDOXOMIL",
              "VALSARTAN": "VALSARTAN"
            },
            "CADISTA": {
              "IRBESARTAN": "IRBESARTAN",
              "LOSARTAN POTASSIUM": "LOSARTAN POTASSIUM",
              "OLMESARTAN MEDOXOMIL": "OLMESARTAN MEDOXOMIL",
              "TELMISARTAN": "TELMISARTAN",
              "VALSARTAN": "VALSARTAN"
            },
            "CAMBER PHARMACEUTICALS": {
              "IRBESARTAN": "IRBESARTAN",
              "LOSARTAN POTASSIUM": "LOSARTAN POTASSIUM",
              "TELMISARTAN": "TELMISARTAN",
              "VALSARTAN": "VALSARTAN"
            },
            "DIRECT RX": {
              "IRBESARTAN": "IRBESARTAN",
              "LOSARTAN POTASSIUM": "LOSARTAN POTASSIUM",
              "VALSARTAN": "VALSARTAN"
            },
            "GLENMARK PHARMACEUTICALS": {
              "OLMESARTAN MEDOXOMIL": "OLMESARTAN MEDOXOMIL",
              "TELMISARTAN": "TELMISARTAN"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "IRBESARTAN": "IRBESARTAN",
              "LOSARTAN POTASSIUM": "LOSARTAN POTASSIUM",
              "OLMESARTAN MEDOXOMIL": "OLMESARTAN MEDOXOMIL"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "DIOVAN": "DIOVAN"
            },
            "HIKMA": {
              "IRBESARTAN": "IRBESARTAN",
              "LOSARTAN POTASSIUM": "LOSARTAN POTASSIUM",
              "TELMISARTAN": "TELMISARTAN"
            },
            "HISUN PHARMACEUTICALS": {
              "IRBESARTAN": "IRBESARTAN"
            },
            "LEGACY PHARMACEUTICAL PKG": {
              "IRBESARTAN": "IRBESARTAN",
              "LOSARTAN POTASSIUM": "LOSARTAN POTASSIUM"
            },
            "LIFESTAR PHARMA": {
              "OLMESARTAN MEDOXOMIL": "OLMESARTAN MEDOXOMIL"
            },
            "LUPIN PHARMACEUTICALS": {
              "IRBESARTAN": "IRBESARTAN",
              "LOSARTAN POTASSIUM": "LOSARTAN POTASSIUM",
              "OLMESARTAN MEDOXOMIL": "OLMESARTAN MEDOXOMIL",
              "VALSARTAN": "VALSARTAN"
            },
            "MACLEODS PHARMACEUTICALS": {
              "CANDESARTAN CILEXETIL": "CANDESARTAN CILEXETIL",
              "IRBESARTAN": "IRBESARTAN",
              "LOSARTAN POTASSIUM": "LOSARTAN POTASSIUM",
              "OLMESARTAN MEDOXOMIL": "OLMESARTAN MEDOXOMIL",
              "VALSARTAN": "VALSARTAN"
            },
            "MAJOR PHARMACEUTICALS": {
              "CANDESARTAN CILEXETIL": "CANDESARTAN CILEXETIL",
              "LOSARTAN POTASSIUM": "LOSARTAN POTASSIUM",
              "VALSARTAN": "VALSARTAN"
            },
            "MERCK SHARP & DOHME": {
              "COZAAR": "COZAAR"
            },
            "METHOD PHARMACEUTICALS": {
              "LOSARTAN POTASSIUM": "LOSARTAN POTASSIUM"
            },
            "MICRO LABORATORIES": {
              "LOSARTAN POTASSIUM": "LOSARTAN POTASSIUM",
              "TELMISARTAN": "TELMISARTAN"
            },
            "MYLAN": {
              "CANDESARTAN CILEXETIL": "CANDESARTAN CILEXETIL",
              "EPROSARTAN MESYLATE": "EPROSARTAN MESYLATE",
              "LOSARTAN POTASSIUM": "LOSARTAN POTASSIUM",
              "OLMESARTAN MEDOXOMIL": "OLMESARTAN MEDOXOMIL",
              "TELMISARTAN": "TELMISARTAN",
              "VALSARTAN": "VALSARTAN"
            },
            "MYLAN INSTITUTIONAL": {
              "OLMESARTAN MEDOXOMIL": "OLMESARTAN MEDOXOMIL"
            },
            "NEW HORIZON RX GROUP": {
              "LOSARTAN POTASSIUM": "LOSARTAN POTASSIUM"
            },
            "NOVARTIS": {
              "DIOVAN": "DIOVAN"
            },
            "NUCARE PHARMACEUTICALS": {
              "DIOVAN": "DIOVAN",
              "LOSARTAN POTASSIUM": "LOSARTAN POTASSIUM"
            },
            "OHM LABS": {
              "VALSARTAN": "VALSARTAN"
            },
            "PAR PHARMACEUTICAL": {
              "CANDESARTAN CILEXETIL": "CANDESARTAN CILEXETIL"
            },
            "PAR PHARMACEUTICALS": {
              "TELMISARTAN": "TELMISARTAN",
              "VALSARTAN": "VALSARTAN"
            },
            "PDRX PHARMACEUTICAL": {
              "AVAPRO": "AVAPRO",
              "BENICAR": "BENICAR",
              "COZAAR": "COZAAR",
              "DIOVAN": "DIOVAN",
              "LOSARTAN POTASSIUM": "LOSARTAN POTASSIUM"
            },
            "PDRX PHARMACEUTICALS": {
              "LOSARTAN POTASSIUM": "LOSARTAN POTASSIUM"
            },
            "PREFERRED PHARMACEUTICALS": {
              "LOSARTAN POTASSIUM": "LOSARTAN POTASSIUM",
              "VALSARTAN": "VALSARTAN"
            },
            "PROFICIENT RX": {
              "IRBESARTAN": "IRBESARTAN",
              "LOSARTAN POTASSIUM": "LOSARTAN POTASSIUM",
              "OLMESARTAN MEDOXOMIL": "OLMESARTAN MEDOXOMIL",
              "TELMISARTAN": "TELMISARTAN",
              "VALSARTAN": "VALSARTAN"
            },
            "QUALITY CARE": {
              "ATACAND": "ATACAND",
              "AVAPRO": "AVAPRO",
              "BENICAR": "BENICAR",
              "COZAAR": "COZAAR",
              "DIOVAN": "DIOVAN",
              "LOSARTAN POTASSIUM": "LOSARTAN POTASSIUM"
            },
            "QUALITY CARE PRODUCTS": {
              "LOSARTAN POTASSIUM": "LOSARTAN POTASSIUM",
              "VALSARTAN": "VALSARTAN"
            },
            "RISING PHARMACEUTICALS": {
              "LOSARTAN POTASSIUM": "LOSARTAN POTASSIUM"
            },
            "SANDOZ": {
              "CANDESARTAN CILEXETIL": "CANDESARTAN CILEXETIL",
              "LOSARTAN POTASSIUM": "LOSARTAN POTASSIUM",
              "TELMISARTAN": "TELMISARTAN",
              "VALSARTAN": "VALSARTAN"
            },
            "SANKYO": {
              "BENICAR": "BENICAR"
            },
            "SANOFI PHARMACEUTICALS": {
              "AVAPRO": "AVAPRO"
            },
            "SCIEGEN PHARMACEUTICALS": {
              "OLMESARTAN MEDOXOMIL": "OLMESARTAN MEDOXOMIL"
            },
            "SKY PACKAGING": {
              "LOSARTAN POTASSIUM": "LOSARTAN POTASSIUM"
            },
            "SOLCO HEALTHCARE": {
              "IRBESARTAN": "IRBESARTAN",
              "LOSARTAN POTASSIUM": "LOSARTAN POTASSIUM",
              "TELMISARTAN": "TELMISARTAN",
              "VALSARTAN": "VALSARTAN"
            },
            "ST MARY'S MPP": {
              "LOSARTAN POTASSIUM": "LOSARTAN POTASSIUM"
            },
            "STRIDES PHARMA": {
              "LOSARTAN POTASSIUM": "LOSARTAN POTASSIUM"
            },
            "SUN PHARMACEUTICALS": {
              "OLMESARTAN MEDOXOMIL": "OLMESARTAN MEDOXOMIL"
            },
            "TEVA PHARMACEUTICALS USA": {
              "IRBESARTAN": "IRBESARTAN",
              "LOSARTAN POTASSIUM": "LOSARTAN POTASSIUM",
              "OLMESARTAN MEDOXOMIL": "OLMESARTAN MEDOXOMIL",
              "VALSARTAN": "VALSARTAN"
            },
            "TORRENT PHARMACEUTICALS": {
              "LOSARTAN POTASSIUM": "LOSARTAN POTASSIUM",
              "OLMESARTAN MEDOXOMIL": "OLMESARTAN MEDOXOMIL",
              "TELMISARTAN": "TELMISARTAN",
              "VALSARTAN": "VALSARTAN"
            },
            "UNICHEM PHARMACEUTICALS": {
              "VALSARTAN": "VALSARTAN"
            },
            "VIRTUS PHARMACEUTICALS": {
              "LOSARTAN POTASSIUM": "LOSARTAN POTASSIUM"
            },
            "VIRTUS PHARMACEUTICALS OPCO": {
              "LOSARTAN POTASSIUM": "LOSARTAN POTASSIUM"
            },
            "WESTMINSTER PHARMACEUTICALS": {
              "IRBESARTAN": "IRBESARTAN"
            },
            "WINTHROP, US": {
              "IRBESARTAN": "IRBESARTAN"
            },
            "XLCARE PHARMACEUTICALS": {
              "LOSARTAN POTASSIUM": "LOSARTAN POTASSIUM"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "LOSARTAN POTASSIUM": "LOSARTAN POTASSIUM",
              "OLMESARTAN MEDOXOMIL": "OLMESARTAN MEDOXOMIL",
              "TELMISARTAN": "TELMISARTAN"
            }
          }
        },
        "Antiadrenergic Antihypertensives": {
          "Antiadrenergics - Centrally Acting": {
            "A-S MEDICATION SOLUTIONS": {
              "CLONIDINE HCL": "CLONIDINE HCL",
              "METHYLDOPA": "METHYLDOPA"
            },
            "ACCORD HEALTHCARE": {
              "METHYLDOPA": "METHYLDOPA"
            },
            "ACTAVIS ELIZABETH": {
              "CLONIDINE HCL": "CLONIDINE HCL"
            },
            "ACTAVIS PHARMA": {
              "CLONIDINE": "CLONIDINE",
              "GUANFACINE HCL": "GUANFACINE HCL"
            },
            "AIDAREX PHARMACEUTICALS": {
              "CLONIDINE HCL": "CLONIDINE HCL"
            },
            "ALEMBIC PHARMACEUTICALS": {
              "CLONIDINE HCL": "CLONIDINE HCL"
            },
            "ALTURA PHARMACEUTICALS": {
              "CLONIDINE HCL": "CLONIDINE HCL"
            },
            "AMERICAN HEALTH PACKAGING": {
              "CLONIDINE HCL": "CLONIDINE HCL",
              "GUANFACINE HCL": "GUANFACINE HCL"
            },
            "AMERICAN PHARMA INGREDIENTS": {
              "CLONIDINE HCL": "CLONIDINE HCL"
            },
            "AMERICAN REGENT": {
              "METHYLDOPATE HCL": "METHYLDOPATE HCL"
            },
            "AMNEAL PHARMACEUTICALS": {
              "GUANFACINE HCL": "GUANFACINE HCL"
            },
            "APHENA PHARMA SOLUTIONS": {
              "CLONIDINE HCL": "CLONIDINE HCL"
            },
            "API SOLUTIONS": {
              "CLONIDINE HCL": "CLONIDINE HCL"
            },
            "APOTHECA SUPPLY": {
              "CLONIDINE HCL": "CLONIDINE HCL"
            },
            "AVKARE": {
              "GUANFACINE HCL": "GUANFACINE HCL"
            },
            "AVPAK": {
              "GUANFACINE HCL": "GUANFACINE HCL"
            },
            "B & B PHARMACEUTICALS": {
              "CLONIDINE HCL": "CLONIDINE HCL"
            },
            "BLENHEIM PHARMACAL": {
              "CLONIDINE HCL": "CLONIDINE HCL"
            },
            "BLU PHARMACEUTICALS": {
              "GUANFACINE HCL": "GUANFACINE HCL"
            },
            "BLUE POINT LABORATORIES": {
              "CLONIDINE HCL": "CLONIDINE HCL"
            },
            "BOEHRINGER INGELHEIM": {
              "CATAPRES": "CATAPRES",
              "CATAPRES-TTS-1": "CATAPRES-TTS-1",
              "CATAPRES-TTS-2": "CATAPRES-TTS-2",
              "CATAPRES-TTS-3": "CATAPRES-TTS-3"
            },
            "BPI LABS LLC": {
              "CLONIDINE HCL": "CLONIDINE HCL"
            },
            "BRYANT RANCH PREPACK": {
              "CLONIDINE HCL": "CLONIDINE HCL"
            },
            "CARDINAL HEALTH REPACKAGING SO": {
              "CLONIDINE HCL": "CLONIDINE HCL"
            },
            "CARLSBAD TECHNOLOGIES": {
              "CLONIDINE HCL": "CLONIDINE HCL"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "CLONIDINE HCL": "CLONIDINE HCL"
            },
            "DIRECT RX": {
              "CLONIDINE HCL": "CLONIDINE HCL"
            },
            "EPIC PHARMA": {
              "GUANFACINE HCL": "GUANFACINE HCL"
            },
            "FAGRON": {
              "CLONIDINE HCL": "CLONIDINE HCL"
            },
            "FREEDOM PHARMACEUTICALS": {
              "CLONIDINE HCL": "CLONIDINE HCL"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "CLONIDINE HCL": "CLONIDINE HCL",
              "GUANFACINE HCL": "GUANFACINE HCL"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "CATAPRES-TTS-2": "CATAPRES-TTS-2",
              "CLONIDINE HCL": "CLONIDINE HCL",
              "GUANFACINE HCL": "GUANFACINE HCL"
            },
            "HUMCO": {
              "CLONIDINE HCL": "CLONIDINE HCL"
            },
            "HURON PHARMACEUTICALS": {
              "CLONIDINE HCL": "CLONIDINE HCL"
            },
            "LETCO MEDICAL": {
              "CLONIDINE HCL": "CLONIDINE HCL"
            },
            "LIBERTY PHARMACEUTICAL": {
              "CLONIDINE HCL": "CLONIDINE HCL",
              "METHYLDOPA": "METHYLDOPA"
            },
            "MAJOR PHARMACEUTICALS": {
              "CLONIDINE HCL": "CLONIDINE HCL"
            },
            "MARLEX PHARMACEUTICALS": {
              "CLONIDINE HCL": "CLONIDINE HCL"
            },
            "MAYNE PHARMA": {
              "CLONIDINE": "CLONIDINE"
            },
            "MEDISCA": {
              "CLONIDINE HCL": "CLONIDINE HCL"
            },
            "METHOD PHARMACEUTICALS": {
              "CLONIDINE HCL": "CLONIDINE HCL"
            },
            "MYLAN": {
              "CLONIDINE": "CLONIDINE",
              "CLONIDINE HCL": "CLONIDINE HCL",
              "GUANFACINE HCL": "GUANFACINE HCL",
              "METHYLDOPA": "METHYLDOPA"
            },
            "MYLAN INSTITUTIONAL": {
              "CLONIDINE HCL": "CLONIDINE HCL",
              "METHYLDOPA": "METHYLDOPA"
            },
            "NUCARE PHARMACEUTICALS": {
              "CLONIDINE HCL": "CLONIDINE HCL"
            },
            "PAR PHARMACEUTICALS": {
              "CLONIDINE HCL": "CLONIDINE HCL"
            },
            "PCCA": {
              "CLONIDINE HCL": "CLONIDINE HCL"
            },
            "PDRX PHARMACEUTICAL": {
              "CLONIDINE HCL": "CLONIDINE HCL",
              "METHYLDOPA": "METHYLDOPA"
            },
            "PDRX PHARMACEUTICALS": {
              "CLONIDINE HCL": "CLONIDINE HCL"
            },
            "PHARMACEUTICA NORTH AMERICA": {
              "CLONIDINE HCL": "CLONIDINE HCL"
            },
            "PRECISION DOSE, INC": {
              "GUANFACINE HCL": "GUANFACINE HCL"
            },
            "PREFERRED PHARMACEUTICALS": {
              "CLONIDINE HCL": "CLONIDINE HCL"
            },
            "PROFICIENT RX": {
              "CLONIDINE HCL": "CLONIDINE HCL"
            },
            "PROMIUS PHARMA": {
              "TENEX": "TENEX"
            },
            "QUALITY CARE": {
              "CLONIDINE": "CLONIDINE",
              "CLONIDINE HCL": "CLONIDINE HCL",
              "GUANFACINE HCL": "GUANFACINE HCL",
              "METHYLDOPA": "METHYLDOPA"
            },
            "QUALITY CARE PRODUCTS": {
              "CLONIDINE": "CLONIDINE",
              "CLONIDINE HCL": "CLONIDINE HCL"
            },
            "RICHMOND PHARMACEUTICALS": {
              "CLONIDINE HCL": "CLONIDINE HCL"
            },
            "RXCHANGE CO": {
              "CLONIDINE HCL": "CLONIDINE HCL"
            },
            "SKY PACKAGING": {
              "CLONIDINE HCL": "CLONIDINE HCL"
            },
            "SPECTRUM": {
              "CLONIDINE HCL": "CLONIDINE HCL"
            },
            "ST MARY'S MPP": {
              "CLONIDINE HCL": "CLONIDINE HCL"
            },
            "SUN PHARMACEUTICALS": {
              "CLONIDINE HCL": "CLONIDINE HCL"
            },
            "TEVA PHARMACEUTICALS USA": {
              "METHYLDOPA": "METHYLDOPA"
            },
            "TOTAL PHARMACY SUPPLY": {
              "CLONIDINE HCL": "CLONIDINE HCL"
            },
            "TRIOVA PHARMACEUTICALS": {
              "CLONIDINE HCL": "CLONIDINE HCL"
            },
            "TRUPHARMA": {
              "CLONIDINE HCL": "CLONIDINE HCL"
            },
            "UNICHEM PHARMACEUTICALS": {
              "CLONIDINE HCL": "CLONIDINE HCL"
            },
            "UNIT DOSE SERVICES": {
              "CLONIDINE HCL": "CLONIDINE HCL"
            },
            "VANGARD": {
              "CLONIDINE HCL": "CLONIDINE HCL"
            }
          },
          "Antiadrenergics - Peripherally Acting": {
            "A-S MEDICATION SOLUTIONS": {
              "DOXAZOSIN MESYLATE": "DOXAZOSIN MESYLATE",
              "PRAZOSIN HCL": "PRAZOSIN HCL",
              "TERAZOSIN HCL": "TERAZOSIN HCL"
            },
            "ACCORD HEALTHCARE": {
              "DOXAZOSIN MESYLATE": "DOXAZOSIN MESYLATE"
            },
            "AIDAREX PHARMACEUTICALS": {
              "DOXAZOSIN MESYLATE": "DOXAZOSIN MESYLATE",
              "PRAZOSIN HCL": "PRAZOSIN HCL",
              "TERAZOSIN HCL": "TERAZOSIN HCL"
            },
            "AMERICAN HEALTH PACKAGING": {
              "DOXAZOSIN MESYLATE": "DOXAZOSIN MESYLATE",
              "PRAZOSIN HCL": "PRAZOSIN HCL"
            },
            "APHENA PHARMA SOLUTIONS": {
              "DOXAZOSIN MESYLATE": "DOXAZOSIN MESYLATE",
              "MINIPRESS": "MINIPRESS",
              "PRAZOSIN HCL": "PRAZOSIN HCL",
              "TERAZOSIN HCL": "TERAZOSIN HCL"
            },
            "APNAR PHARMA": {
              "TERAZOSIN HCL": "TERAZOSIN HCL"
            },
            "APOTEX": {
              "DOXAZOSIN MESYLATE": "DOXAZOSIN MESYLATE",
              "TERAZOSIN HCL": "TERAZOSIN HCL"
            },
            "AVET PHARMACEUTICALS": {
              "DOXAZOSIN MESYLATE": "DOXAZOSIN MESYLATE"
            },
            "AVKARE": {
              "DOXAZOSIN MESYLATE": "DOXAZOSIN MESYLATE",
              "TERAZOSIN HCL": "TERAZOSIN HCL"
            },
            "AVPAK": {
              "DOXAZOSIN MESYLATE": "DOXAZOSIN MESYLATE",
              "TERAZOSIN HCL": "TERAZOSIN HCL"
            },
            "BLENHEIM PHARMACAL": {
              "DOXAZOSIN MESYLATE": "DOXAZOSIN MESYLATE",
              "TERAZOSIN HCL": "TERAZOSIN HCL"
            },
            "BRYANT RANCH PREPACK": {
              "DOXAZOSIN MESYLATE": "DOXAZOSIN MESYLATE",
              "PRAZOSIN HCL": "PRAZOSIN HCL",
              "TERAZOSIN HCL": "TERAZOSIN HCL"
            },
            "CADISTA": {
              "TERAZOSIN HCL": "TERAZOSIN HCL"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "DOXAZOSIN MESYLATE": "DOXAZOSIN MESYLATE"
            },
            "DIRECT RX": {
              "DOXAZOSIN MESYLATE": "DOXAZOSIN MESYLATE",
              "PRAZOSIN HCL": "PRAZOSIN HCL"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "DOXAZOSIN MESYLATE": "DOXAZOSIN MESYLATE",
              "PRAZOSIN HCL": "PRAZOSIN HCL"
            },
            "GREENSTONE": {
              "DOXAZOSIN MESYLATE": "DOXAZOSIN MESYLATE",
              "PRAZOSIN HCL": "PRAZOSIN HCL"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "TERAZOSIN HCL": "TERAZOSIN HCL"
            },
            "LEGACY PHARMACEUTICAL PKG": {
              "DOXAZOSIN MESYLATE": "DOXAZOSIN MESYLATE"
            },
            "LIBERTY PHARMACEUTICAL": {
              "DOXAZOSIN MESYLATE": "DOXAZOSIN MESYLATE",
              "PRAZOSIN HCL": "PRAZOSIN HCL",
              "TERAZOSIN HCL": "TERAZOSIN HCL"
            },
            "MAJOR PHARMACEUTICALS": {
              "DOXAZOSIN MESYLATE": "DOXAZOSIN MESYLATE",
              "PRAZOSIN HCL": "PRAZOSIN HCL",
              "TERAZOSIN HCL": "TERAZOSIN HCL"
            },
            "MARLEX PHARMACEUTICALS": {
              "PRAZOSIN HCL": "PRAZOSIN HCL"
            },
            "MEDVANTX": {
              "CARDURA": "CARDURA"
            },
            "MYLAN": {
              "DOXAZOSIN MESYLATE": "DOXAZOSIN MESYLATE",
              "PRAZOSIN HCL": "PRAZOSIN HCL",
              "TERAZOSIN HCL": "TERAZOSIN HCL"
            },
            "MYLAN INSTITUTIONAL": {
              "DOXAZOSIN MESYLATE": "DOXAZOSIN MESYLATE",
              "PRAZOSIN HCL": "PRAZOSIN HCL",
              "TERAZOSIN HCL": "TERAZOSIN HCL"
            },
            "NOVITIUM PHARMA": {
              "PRAZOSIN HCL": "PRAZOSIN HCL"
            },
            "PAR PHARMACEUTICAL": {
              "DOXAZOSIN MESYLATE": "DOXAZOSIN MESYLATE"
            },
            "PCCA": {
              "PRAZOSIN HCL": "PRAZOSIN HCL"
            },
            "PDRX PHARMACEUTICAL": {
              "CARDURA": "CARDURA",
              "DOXAZOSIN MESYLATE": "DOXAZOSIN MESYLATE",
              "PRAZOSIN HCL": "PRAZOSIN HCL",
              "TERAZOSIN HCL": "TERAZOSIN HCL"
            },
            "PFIZER U.S.": {
              "CARDURA": "CARDURA",
              "MINIPRESS": "MINIPRESS"
            },
            "PREFERRED PHARMACEUTICALS": {
              "DOXAZOSIN MESYLATE": "DOXAZOSIN MESYLATE",
              "PRAZOSIN HCL": "PRAZOSIN HCL"
            },
            "PRESCRIPTION SOLUTIONS, INC.": {
              "TERAZOSIN HCL": "TERAZOSIN HCL"
            },
            "QUALITY CARE": {
              "DOXAZOSIN MESYLATE": "DOXAZOSIN MESYLATE",
              "TERAZOSIN HCL": "TERAZOSIN HCL"
            },
            "QUALITY CARE PRODUCTS": {
              "DOXAZOSIN MESYLATE": "DOXAZOSIN MESYLATE",
              "PRAZOSIN HCL": "PRAZOSIN HCL",
              "TERAZOSIN HCL": "TERAZOSIN HCL"
            },
            "RXCHANGE CO": {
              "DOXAZOSIN MESYLATE": "DOXAZOSIN MESYLATE"
            },
            "RXPAK MCKESSON": {
              "TERAZOSIN HCL": "TERAZOSIN HCL"
            },
            "SANDOZ": {
              "TERAZOSIN HCL": "TERAZOSIN HCL"
            },
            "ST MARY'S MPP": {
              "DOXAZOSIN MESYLATE": "DOXAZOSIN MESYLATE",
              "TERAZOSIN HCL": "TERAZOSIN HCL"
            },
            "TEVA PHARMACEUTICALS USA": {
              "DOXAZOSIN MESYLATE": "DOXAZOSIN MESYLATE",
              "PRAZOSIN HCL": "PRAZOSIN HCL"
            },
            "UPSHER-SMITH": {
              "DOXAZOSIN MESYLATE": "DOXAZOSIN MESYLATE"
            },
            "VA CMOP DALLAS": {
              "TERAZOSIN HCL": "TERAZOSIN HCL"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "DOXAZOSIN MESYLATE": "DOXAZOSIN MESYLATE"
            }
          },
          "Reserpine": {
            "LETCO MEDICAL": {
              "RESERPINE": "RESERPINE"
            },
            "MEDISCA": {
              "RESERPINE": "RESERPINE"
            },
            "PCCA": {
              "RESERPINE": "RESERPINE"
            },
            "SANDOZ": {
              "RESERPINE": "RESERPINE"
            },
            "SPECTRUM": {
              "RESERPINE": "RESERPINE"
            }
          }
        },
        "Antihypertensive Combinations": {
          "ACE Inhibitor & Calcium Channel Blocker Combinations": {
            "A-S MEDICATION SOLUTIONS": {
              "AMLODIPINE BESY-BENAZEPRIL HCL": "AMLODIPINE BESY-BENAZEPRIL HCL",
              "LOTREL": "LOTREL"
            },
            "ABBVIE": {
              "TARKA": "TARKA"
            },
            "ACTAVIS PHARMA": {
              "AMLODIPINE BESY-BENAZEPRIL HCL": "AMLODIPINE BESY-BENAZEPRIL HCL"
            },
            "ADHERA THERAPEUTICS": {
              "PRESTALIA": "PRESTALIA"
            },
            "AIDAREX PHARMACEUTICALS": {
              "AMLODIPINE BESY-BENAZEPRIL HCL": "AMLODIPINE BESY-BENAZEPRIL HCL"
            },
            "AMERICAN HEALTH PACKAGING": {
              "AMLODIPINE BESY-BENAZEPRIL HCL": "AMLODIPINE BESY-BENAZEPRIL HCL"
            },
            "AUROBINDO PHARMA": {
              "AMLODIPINE BESY-BENAZEPRIL HCL": "AMLODIPINE BESY-BENAZEPRIL HCL"
            },
            "BLUE POINT LABORATORIES": {
              "AMLODIPINE BESY-BENAZEPRIL HCL": "AMLODIPINE BESY-BENAZEPRIL HCL"
            },
            "BRYANT RANCH PREPACK": {
              "AMLODIPINE BESY-BENAZEPRIL HCL": "AMLODIPINE BESY-BENAZEPRIL HCL"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "AMLODIPINE BESY-BENAZEPRIL HCL": "AMLODIPINE BESY-BENAZEPRIL HCL"
            },
            "DIRECT RX": {
              "AMLODIPINE BESY-BENAZEPRIL HCL": "AMLODIPINE BESY-BENAZEPRIL HCL"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "AMLODIPINE BESY-BENAZEPRIL HCL": "AMLODIPINE BESY-BENAZEPRIL HCL"
            },
            "GLENMARK PHARMACEUTICALS": {
              "TRANDOLAPRIL-VERAPAMIL HCL ER": "TRANDOLAPRIL-VERAPAMIL HCL ER"
            },
            "GREENSTONE": {
              "TRANDOLAPRIL-VERAPAMIL HCL ER": "TRANDOLAPRIL-VERAPAMIL HCL ER"
            },
            "LIBERTY PHARMACEUTICAL": {
              "AMLODIPINE BESY-BENAZEPRIL HCL": "AMLODIPINE BESY-BENAZEPRIL HCL"
            },
            "LUPIN PHARMACEUTICALS": {
              "AMLODIPINE BESY-BENAZEPRIL HCL": "AMLODIPINE BESY-BENAZEPRIL HCL"
            },
            "MYLAN": {
              "AMLODIPINE BESY-BENAZEPRIL HCL": "AMLODIPINE BESY-BENAZEPRIL HCL"
            },
            "NOVARTIS": {
              "LOTREL": "LOTREL"
            },
            "PDRX PHARMACEUTICAL": {
              "AMLODIPINE BESY-BENAZEPRIL HCL": "AMLODIPINE BESY-BENAZEPRIL HCL",
              "LOTREL": "LOTREL"
            },
            "PROFICIENT RX": {
              "AMLODIPINE BESY-BENAZEPRIL HCL": "AMLODIPINE BESY-BENAZEPRIL HCL"
            },
            "QUALITY CARE": {
              "AMLODIPINE BESY-BENAZEPRIL HCL": "AMLODIPINE BESY-BENAZEPRIL HCL",
              "LOTREL": "LOTREL",
              "TARKA": "TARKA"
            },
            "QUALITY CARE PRODUCTS": {
              "AMLODIPINE BESY-BENAZEPRIL HCL": "AMLODIPINE BESY-BENAZEPRIL HCL"
            },
            "RISING PHARMACEUTICALS": {
              "AMLODIPINE BESY-BENAZEPRIL HCL": "AMLODIPINE BESY-BENAZEPRIL HCL"
            },
            "RXCHANGE CO": {
              "LOTREL": "LOTREL"
            },
            "SANDOZ": {
              "AMLODIPINE BESY-BENAZEPRIL HCL": "AMLODIPINE BESY-BENAZEPRIL HCL"
            },
            "TEVA PHARMACEUTICALS USA": {
              "AMLODIPINE BESY-BENAZEPRIL HCL": "AMLODIPINE BESY-BENAZEPRIL HCL"
            }
          },
          "ACE Inhibitors & Thiazide/Thiazide-Like": {
            "A-S MEDICATION SOLUTIONS": {
              "BENAZEPRIL-HYDROCHLOROTHIAZIDE": "BENAZEPRIL-HYDROCHLOROTHIAZIDE",
              "LISINOPRIL-HYDROCHLOROTHIAZIDE": "LISINOPRIL-HYDROCHLOROTHIAZIDE"
            },
            "ACTAVIS PHARMA": {
              "LISINOPRIL-HYDROCHLOROTHIAZIDE": "LISINOPRIL-HYDROCHLOROTHIAZIDE"
            },
            "AIDAREX PHARMACEUTICALS": {
              "BENAZEPRIL-HYDROCHLOROTHIAZIDE": "BENAZEPRIL-HYDROCHLOROTHIAZIDE",
              "LISINOPRIL-HYDROCHLOROTHIAZIDE": "LISINOPRIL-HYDROCHLOROTHIAZIDE"
            },
            "ALMATICA": {
              "ZESTORETIC": "ZESTORETIC"
            },
            "AMERICAN HEALTH PACKAGING": {
              "LISINOPRIL-HYDROCHLOROTHIAZIDE": "LISINOPRIL-HYDROCHLOROTHIAZIDE"
            },
            "APHENA PHARMA SOLUTIONS": {
              "LISINOPRIL-HYDROCHLOROTHIAZIDE": "LISINOPRIL-HYDROCHLOROTHIAZIDE"
            },
            "APOTEX": {
              "BENAZEPRIL-HYDROCHLOROTHIAZIDE": "BENAZEPRIL-HYDROCHLOROTHIAZIDE",
              "ENALAPRIL-HYDROCHLOROTHIAZIDE": "ENALAPRIL-HYDROCHLOROTHIAZIDE",
              "LISINOPRIL-HYDROCHLOROTHIAZIDE": "LISINOPRIL-HYDROCHLOROTHIAZIDE",
              "QUINAPRIL-HYDROCHLOROTHIAZIDE": "QUINAPRIL-HYDROCHLOROTHIAZIDE"
            },
            "AUROBINDO PHARMA": {
              "FOSINOPRIL SODIUM-HCTZ": "FOSINOPRIL SODIUM-HCTZ",
              "LISINOPRIL-HYDROCHLOROTHIAZIDE": "LISINOPRIL-HYDROCHLOROTHIAZIDE",
              "QUINAPRIL-HYDROCHLOROTHIAZIDE": "QUINAPRIL-HYDROCHLOROTHIAZIDE"
            },
            "AVET PHARMACEUTICALS": {
              "FOSINOPRIL SODIUM-HCTZ": "FOSINOPRIL SODIUM-HCTZ"
            },
            "AVKARE": {
              "ENALAPRIL-HYDROCHLOROTHIAZIDE": "ENALAPRIL-HYDROCHLOROTHIAZIDE",
              "LISINOPRIL-HYDROCHLOROTHIAZIDE": "LISINOPRIL-HYDROCHLOROTHIAZIDE",
              "MOEXIPRIL-HYDROCHLOROTHIAZIDE": "MOEXIPRIL-HYDROCHLOROTHIAZIDE",
              "QUINAPRIL-HYDROCHLOROTHIAZIDE": "QUINAPRIL-HYDROCHLOROTHIAZIDE"
            },
            "BAUSCH HEALTH": {
              "VASERETIC": "VASERETIC"
            },
            "BLENHEIM PHARMACAL": {
              "LISINOPRIL-HYDROCHLOROTHIAZIDE": "LISINOPRIL-HYDROCHLOROTHIAZIDE"
            },
            "BRYANT RANCH PREPACK": {
              "BENAZEPRIL-HYDROCHLOROTHIAZIDE": "BENAZEPRIL-HYDROCHLOROTHIAZIDE",
              "ENALAPRIL-HYDROCHLOROTHIAZIDE": "ENALAPRIL-HYDROCHLOROTHIAZIDE",
              "LISINOPRIL-HYDROCHLOROTHIAZIDE": "LISINOPRIL-HYDROCHLOROTHIAZIDE"
            },
            "CIPLA USA": {
              "FOSINOPRIL SODIUM-HCTZ": "FOSINOPRIL SODIUM-HCTZ",
              "LISINOPRIL-HYDROCHLOROTHIAZIDE": "LISINOPRIL-HYDROCHLOROTHIAZIDE",
              "QUINAPRIL-HYDROCHLOROTHIAZIDE": "QUINAPRIL-HYDROCHLOROTHIAZIDE"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "BENAZEPRIL-HYDROCHLOROTHIAZIDE": "BENAZEPRIL-HYDROCHLOROTHIAZIDE",
              "LISINOPRIL-HYDROCHLOROTHIAZIDE": "LISINOPRIL-HYDROCHLOROTHIAZIDE"
            },
            "COSETTE PHARMACEUTICALS": {
              "ENALAPRIL-HYDROCHLOROTHIAZIDE": "ENALAPRIL-HYDROCHLOROTHIAZIDE"
            },
            "DENTON PHARMA": {
              "LISINOPRIL-HYDROCHLOROTHIAZIDE": "LISINOPRIL-HYDROCHLOROTHIAZIDE"
            },
            "DIRECT RX": {
              "LISINOPRIL-HYDROCHLOROTHIAZIDE": "LISINOPRIL-HYDROCHLOROTHIAZIDE"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "ENALAPRIL-HYDROCHLOROTHIAZIDE": "ENALAPRIL-HYDROCHLOROTHIAZIDE"
            },
            "EXELAN PHARMACEUTICALS": {
              "LISINOPRIL-HYDROCHLOROTHIAZIDE": "LISINOPRIL-HYDROCHLOROTHIAZIDE"
            },
            "GLENMARK PHARMACEUTICALS": {
              "FOSINOPRIL SODIUM-HCTZ": "FOSINOPRIL SODIUM-HCTZ",
              "MOEXIPRIL-HYDROCHLOROTHIAZIDE": "MOEXIPRIL-HYDROCHLOROTHIAZIDE"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "BENAZEPRIL-HYDROCHLOROTHIAZIDE": "BENAZEPRIL-HYDROCHLOROTHIAZIDE",
              "LISINOPRIL-HYDROCHLOROTHIAZIDE": "LISINOPRIL-HYDROCHLOROTHIAZIDE"
            },
            "GREENSTONE": {
              "QUINAPRIL-HYDROCHLOROTHIAZIDE": "QUINAPRIL-HYDROCHLOROTHIAZIDE"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "BENAZEPRIL-HYDROCHLOROTHIAZIDE": "BENAZEPRIL-HYDROCHLOROTHIAZIDE",
              "LISINOPRIL-HYDROCHLOROTHIAZIDE": "LISINOPRIL-HYDROCHLOROTHIAZIDE",
              "ZESTORETIC": "ZESTORETIC"
            },
            "HIKMA": {
              "LISINOPRIL-HYDROCHLOROTHIAZIDE": "LISINOPRIL-HYDROCHLOROTHIAZIDE"
            },
            "INTERNATIONAL LABS, LLC": {
              "LISINOPRIL-HYDROCHLOROTHIAZIDE": "LISINOPRIL-HYDROCHLOROTHIAZIDE"
            },
            "LEGACY PHARMACEUTICAL PKG": {
              "LISINOPRIL-HYDROCHLOROTHIAZIDE": "LISINOPRIL-HYDROCHLOROTHIAZIDE"
            },
            "LIBERTY PHARMACEUTICAL": {
              "LISINOPRIL-HYDROCHLOROTHIAZIDE": "LISINOPRIL-HYDROCHLOROTHIAZIDE"
            },
            "LUPIN PHARMACEUTICALS": {
              "LISINOPRIL-HYDROCHLOROTHIAZIDE": "LISINOPRIL-HYDROCHLOROTHIAZIDE",
              "QUINAPRIL-HYDROCHLOROTHIAZIDE": "QUINAPRIL-HYDROCHLOROTHIAZIDE"
            },
            "MYLAN": {
              "BENAZEPRIL-HYDROCHLOROTHIAZIDE": "BENAZEPRIL-HYDROCHLOROTHIAZIDE",
              "CAPTOPRIL-HYDROCHLOROTHIAZIDE": "CAPTOPRIL-HYDROCHLOROTHIAZIDE",
              "ENALAPRIL-HYDROCHLOROTHIAZIDE": "ENALAPRIL-HYDROCHLOROTHIAZIDE",
              "LISINOPRIL-HYDROCHLOROTHIAZIDE": "LISINOPRIL-HYDROCHLOROTHIAZIDE",
              "QUINAPRIL-HYDROCHLOROTHIAZIDE": "QUINAPRIL-HYDROCHLOROTHIAZIDE"
            },
            "NUCARE PHARMACEUTICALS": {
              "LISINOPRIL-HYDROCHLOROTHIAZIDE": "LISINOPRIL-HYDROCHLOROTHIAZIDE"
            },
            "OCEANSIDE PHARMACEUTICALS": {
              "ENALAPRIL-HYDROCHLOROTHIAZIDE": "ENALAPRIL-HYDROCHLOROTHIAZIDE"
            },
            "PDRX PHARMACEUTICAL": {
              "LISINOPRIL-HYDROCHLOROTHIAZIDE": "LISINOPRIL-HYDROCHLOROTHIAZIDE",
              "VASERETIC": "VASERETIC"
            },
            "PERRIGO": {
              "BENAZEPRIL-HYDROCHLOROTHIAZIDE": "BENAZEPRIL-HYDROCHLOROTHIAZIDE"
            },
            "PFIZER U.S.": {
              "ACCURETIC": "ACCURETIC"
            },
            "PREFERRED PHARMACEUTICALS": {
              "LISINOPRIL-HYDROCHLOROTHIAZIDE": "LISINOPRIL-HYDROCHLOROTHIAZIDE"
            },
            "PROFICIENT RX": {
              "LISINOPRIL-HYDROCHLOROTHIAZIDE": "LISINOPRIL-HYDROCHLOROTHIAZIDE"
            },
            "QUALITY CARE": {
              "ENALAPRIL-HYDROCHLOROTHIAZIDE": "ENALAPRIL-HYDROCHLOROTHIAZIDE",
              "LISINOPRIL-HYDROCHLOROTHIAZIDE": "LISINOPRIL-HYDROCHLOROTHIAZIDE",
              "QUINAPRIL-HYDROCHLOROTHIAZIDE": "QUINAPRIL-HYDROCHLOROTHIAZIDE"
            },
            "QUALITY CARE PRODUCTS": {
              "ENALAPRIL-HYDROCHLOROTHIAZIDE": "ENALAPRIL-HYDROCHLOROTHIAZIDE",
              "LISINOPRIL-HYDROCHLOROTHIAZIDE": "LISINOPRIL-HYDROCHLOROTHIAZIDE"
            },
            "RISING PHARMACEUTICALS": {
              "BENAZEPRIL-HYDROCHLOROTHIAZIDE": "BENAZEPRIL-HYDROCHLOROTHIAZIDE",
              "FOSINOPRIL SODIUM-HCTZ": "FOSINOPRIL SODIUM-HCTZ"
            },
            "RXCHANGE CO": {
              "BENAZEPRIL-HYDROCHLOROTHIAZIDE": "BENAZEPRIL-HYDROCHLOROTHIAZIDE",
              "LISINOPRIL-HYDROCHLOROTHIAZIDE": "LISINOPRIL-HYDROCHLOROTHIAZIDE"
            },
            "SANDOZ": {
              "BENAZEPRIL-HYDROCHLOROTHIAZIDE": "BENAZEPRIL-HYDROCHLOROTHIAZIDE",
              "FOSINOPRIL SODIUM-HCTZ": "FOSINOPRIL SODIUM-HCTZ",
              "LISINOPRIL-HYDROCHLOROTHIAZIDE": "LISINOPRIL-HYDROCHLOROTHIAZIDE"
            },
            "SOLCO HEALTHCARE": {
              "LISINOPRIL-HYDROCHLOROTHIAZIDE": "LISINOPRIL-HYDROCHLOROTHIAZIDE"
            },
            "ST MARY'S MPP": {
              "LISINOPRIL-HYDROCHLOROTHIAZIDE": "LISINOPRIL-HYDROCHLOROTHIAZIDE"
            },
            "SUN PHARMACEUTICALS": {
              "LISINOPRIL-HYDROCHLOROTHIAZIDE": "LISINOPRIL-HYDROCHLOROTHIAZIDE"
            },
            "TARO": {
              "ENALAPRIL-HYDROCHLOROTHIAZIDE": "ENALAPRIL-HYDROCHLOROTHIAZIDE"
            },
            "TEVA PHARMACEUTICALS USA": {
              "LISINOPRIL-HYDROCHLOROTHIAZIDE": "LISINOPRIL-HYDROCHLOROTHIAZIDE",
              "MOEXIPRIL-HYDROCHLOROTHIAZIDE": "MOEXIPRIL-HYDROCHLOROTHIAZIDE"
            },
            "UPSHER-SMITH": {
              "BENAZEPRIL-HYDROCHLOROTHIAZIDE": "BENAZEPRIL-HYDROCHLOROTHIAZIDE"
            },
            "VALIDUS PHARMACEUTICALS": {
              "LOTENSIN HCT": "LOTENSIN HCT"
            }
          },
          "Adrenolytics-Central & Thiazide/Thiazide-Like Comb": {
            "MYLAN": {
              "CLORPRES": "CLORPRES",
              "METHYLDOPA-HYDROCHLOROTHIAZIDE": "METHYLDOPA-HYDROCHLOROTHIAZIDE"
            },
            "QUALITY CARE": {
              "METHYLDOPA-HYDROCHLOROTHIAZIDE": "METHYLDOPA-HYDROCHLOROTHIAZIDE"
            }
          },
          "Angiotensin II Receptor Ant-Ca Channel Blocker-Thiazides": {
            "AUROBINDO PHARMA": {
              "AMLODIPINE-VALSARTAN-HCTZ": "AMLODIPINE-VALSARTAN-HCTZ"
            },
            "LUPIN PHARMACEUTICALS": {
              "AMLODIPINE-VALSARTAN-HCTZ": "AMLODIPINE-VALSARTAN-HCTZ"
            },
            "NOVARTIS": {
              "EXFORGE HCT": "EXFORGE HCT"
            },
            "PAR PHARMACEUTICAL": {
              "AMLODIPINE-VALSARTAN-HCTZ": "AMLODIPINE-VALSARTAN-HCTZ",
              "OLMESARTAN-AMLODIPINE-HCTZ": "OLMESARTAN-AMLODIPINE-HCTZ"
            },
            "SANDOZ": {
              "AMLODIPINE-VALSARTAN-HCTZ": "AMLODIPINE-VALSARTAN-HCTZ"
            },
            "SANKYO": {
              "TRIBENZOR": "TRIBENZOR"
            },
            "SUN PHARMACEUTICALS": {
              "OLMESARTAN-AMLODIPINE-HCTZ": "OLMESARTAN-AMLODIPINE-HCTZ"
            },
            "TEVA PHARMACEUTICALS USA": {
              "AMLODIPINE-VALSARTAN-HCTZ": "AMLODIPINE-VALSARTAN-HCTZ",
              "OLMESARTAN-AMLODIPINE-HCTZ": "OLMESARTAN-AMLODIPINE-HCTZ"
            },
            "TORRENT PHARMACEUTICALS": {
              "AMLODIPINE-VALSARTAN-HCTZ": "AMLODIPINE-VALSARTAN-HCTZ",
              "OLMESARTAN-AMLODIPINE-HCTZ": "OLMESARTAN-AMLODIPINE-HCTZ"
            }
          },
          "Angiotensin II Receptor Antag & Ca Channel Blocker Comb": {
            "A-S MEDICATION SOLUTIONS": {
              "AMLODIPINE BESYLATE-VALSARTAN": "AMLODIPINE BESYLATE-VALSARTAN"
            },
            "AJANTA PHARMA LIMITED": {
              "AMLODIPINE-OLMESARTAN": "AMLODIPINE-OLMESARTAN"
            },
            "ALEMBIC PHARMACEUTICALS": {
              "AMLODIPINE BESYLATE-VALSARTAN": "AMLODIPINE BESYLATE-VALSARTAN",
              "AMLODIPINE-OLMESARTAN": "AMLODIPINE-OLMESARTAN"
            },
            "APOTEX": {
              "AMLODIPINE-OLMESARTAN": "AMLODIPINE-OLMESARTAN"
            },
            "ASCEND LABORATORIES": {
              "AMLODIPINE-OLMESARTAN": "AMLODIPINE-OLMESARTAN"
            },
            "AUROBINDO PHARMA": {
              "AMLODIPINE BESYLATE-VALSARTAN": "AMLODIPINE BESYLATE-VALSARTAN",
              "AMLODIPINE-OLMESARTAN": "AMLODIPINE-OLMESARTAN"
            },
            "AVKARE": {
              "AMLODIPINE BESYLATE-VALSARTAN": "AMLODIPINE BESYLATE-VALSARTAN",
              "TELMISARTAN-AMLODIPINE": "TELMISARTAN-AMLODIPINE"
            },
            "BOEHRINGER INGELHEIM": {
              "TWYNSTA": "TWYNSTA"
            },
            "GLENMARK PHARMACEUTICALS": {
              "AMLODIPINE-OLMESARTAN": "AMLODIPINE-OLMESARTAN"
            },
            "LUPIN PHARMACEUTICALS": {
              "AMLODIPINE BESYLATE-VALSARTAN": "AMLODIPINE BESYLATE-VALSARTAN",
              "TELMISARTAN-AMLODIPINE": "TELMISARTAN-AMLODIPINE"
            },
            "MACLEODS PHARMACEUTICALS": {
              "AMLODIPINE-OLMESARTAN": "AMLODIPINE-OLMESARTAN"
            },
            "MICRO LABORATORIES": {
              "AMLODIPINE-OLMESARTAN": "AMLODIPINE-OLMESARTAN"
            },
            "MYLAN": {
              "AMLODIPINE BESYLATE-VALSARTAN": "AMLODIPINE BESYLATE-VALSARTAN",
              "TELMISARTAN-AMLODIPINE": "TELMISARTAN-AMLODIPINE"
            },
            "NOVARTIS": {
              "EXFORGE": "EXFORGE"
            },
            "PAR PHARMACEUTICAL": {
              "AMLODIPINE BESYLATE-VALSARTAN": "AMLODIPINE BESYLATE-VALSARTAN"
            },
            "SANDOZ": {
              "AMLODIPINE BESYLATE-VALSARTAN": "AMLODIPINE BESYLATE-VALSARTAN"
            },
            "SANKYO": {
              "AZOR": "AZOR"
            },
            "SUN PHARMACEUTICALS": {
              "AMLODIPINE-OLMESARTAN": "AMLODIPINE-OLMESARTAN"
            },
            "TEVA PHARMACEUTICALS USA": {
              "AMLODIPINE BESYLATE-VALSARTAN": "AMLODIPINE BESYLATE-VALSARTAN",
              "AMLODIPINE-OLMESARTAN": "AMLODIPINE-OLMESARTAN"
            },
            "TORRENT PHARMACEUTICALS": {
              "AMLODIPINE BESYLATE-VALSARTAN": "AMLODIPINE BESYLATE-VALSARTAN",
              "AMLODIPINE-OLMESARTAN": "AMLODIPINE-OLMESARTAN",
              "TELMISARTAN-AMLODIPINE": "TELMISARTAN-AMLODIPINE"
            },
            "TRIGEN LABORATORIES": {
              "AMLODIPINE BESYLATE-VALSARTAN": "AMLODIPINE BESYLATE-VALSARTAN"
            }
          },
          "Angiotensin II Receptor Antag & Thiazide/Thiazide-Like": {
            "A-S MEDICATION SOLUTIONS": {
              "ATACAND HCT": "ATACAND HCT",
              "BENICAR HCT": "BENICAR HCT",
              "DIOVAN HCT": "DIOVAN HCT",
              "HYZAAR": "HYZAAR",
              "LOSARTAN POTASSIUM-HCTZ": "LOSARTAN POTASSIUM-HCTZ",
              "MICARDIS HCT": "MICARDIS HCT",
              "OLMESARTAN MEDOXOMIL-HCTZ": "OLMESARTAN MEDOXOMIL-HCTZ",
              "VALSARTAN-HYDROCHLOROTHIAZIDE": "VALSARTAN-HYDROCHLOROTHIAZIDE"
            },
            "ACCORD HEALTHCARE": {
              "OLMESARTAN MEDOXOMIL-HCTZ": "OLMESARTAN MEDOXOMIL-HCTZ"
            },
            "ACETRIS HEALTH": {
              "LOSARTAN POTASSIUM-HCTZ": "LOSARTAN POTASSIUM-HCTZ"
            },
            "ACTAVIS PHARMA": {
              "VALSARTAN-HYDROCHLOROTHIAZIDE": "VALSARTAN-HYDROCHLOROTHIAZIDE"
            },
            "AIDAREX PHARMACEUTICALS": {
              "LOSARTAN POTASSIUM-HCTZ": "LOSARTAN POTASSIUM-HCTZ",
              "VALSARTAN-HYDROCHLOROTHIAZIDE": "VALSARTAN-HYDROCHLOROTHIAZIDE"
            },
            "ALEMBIC PHARMACEUTICALS": {
              "IRBESARTAN-HYDROCHLOROTHIAZIDE": "IRBESARTAN-HYDROCHLOROTHIAZIDE",
              "LOSARTAN POTASSIUM-HCTZ": "LOSARTAN POTASSIUM-HCTZ",
              "OLMESARTAN MEDOXOMIL-HCTZ": "OLMESARTAN MEDOXOMIL-HCTZ",
              "TELMISARTAN-HCTZ": "TELMISARTAN-HCTZ",
              "VALSARTAN-HYDROCHLOROTHIAZIDE": "VALSARTAN-HYDROCHLOROTHIAZIDE"
            },
            "AMERICAN HEALTH PACKAGING": {
              "LOSARTAN POTASSIUM-HCTZ": "LOSARTAN POTASSIUM-HCTZ",
              "VALSARTAN-HYDROCHLOROTHIAZIDE": "VALSARTAN-HYDROCHLOROTHIAZIDE"
            },
            "ANI  PHARMACEUTICALS": {
              "ATACAND HCT": "ATACAND HCT",
              "CANDESARTAN CILEXETIL-HCTZ": "CANDESARTAN CILEXETIL-HCTZ"
            },
            "APHENA PHARMA SOLUTIONS": {
              "HYZAAR": "HYZAAR",
              "VALSARTAN-HYDROCHLOROTHIAZIDE": "VALSARTAN-HYDROCHLOROTHIAZIDE"
            },
            "APOTEX": {
              "CANDESARTAN CILEXETIL-HCTZ": "CANDESARTAN CILEXETIL-HCTZ",
              "IRBESARTAN-HYDROCHLOROTHIAZIDE": "IRBESARTAN-HYDROCHLOROTHIAZIDE",
              "LOSARTAN POTASSIUM-HCTZ": "LOSARTAN POTASSIUM-HCTZ",
              "VALSARTAN-HYDROCHLOROTHIAZIDE": "VALSARTAN-HYDROCHLOROTHIAZIDE"
            },
            "AQ PHARMACEUTICALS": {
              "DIOVAN HCT": "DIOVAN HCT",
              "HYZAAR": "HYZAAR"
            },
            "ARBOR PHARMACEUTICALS": {
              "EDARBYCLOR": "EDARBYCLOR"
            },
            "ASTRAZENECA LP": {
              "ATACAND HCT": "ATACAND HCT"
            },
            "AUROBINDO PHARMA": {
              "LOSARTAN POTASSIUM-HCTZ": "LOSARTAN POTASSIUM-HCTZ",
              "OLMESARTAN MEDOXOMIL-HCTZ": "OLMESARTAN MEDOXOMIL-HCTZ",
              "TELMISARTAN-HCTZ": "TELMISARTAN-HCTZ",
              "VALSARTAN-HYDROCHLOROTHIAZIDE": "VALSARTAN-HYDROCHLOROTHIAZIDE"
            },
            "AVKARE": {
              "IRBESARTAN-HYDROCHLOROTHIAZIDE": "IRBESARTAN-HYDROCHLOROTHIAZIDE",
              "LOSARTAN POTASSIUM-HCTZ": "LOSARTAN POTASSIUM-HCTZ",
              "VALSARTAN-HYDROCHLOROTHIAZIDE": "VALSARTAN-HYDROCHLOROTHIAZIDE"
            },
            "AVPAK": {
              "LOSARTAN POTASSIUM-HCTZ": "LOSARTAN POTASSIUM-HCTZ",
              "VALSARTAN-HYDROCHLOROTHIAZIDE": "VALSARTAN-HYDROCHLOROTHIAZIDE"
            },
            "BOEHRINGER INGELHEIM": {
              "MICARDIS HCT": "MICARDIS HCT"
            },
            "BRYANT RANCH PREPACK": {
              "ATACAND HCT": "ATACAND HCT",
              "AVALIDE": "AVALIDE",
              "IRBESARTAN-HYDROCHLOROTHIAZIDE": "IRBESARTAN-HYDROCHLOROTHIAZIDE",
              "LOSARTAN POTASSIUM-HCTZ": "LOSARTAN POTASSIUM-HCTZ",
              "VALSARTAN-HYDROCHLOROTHIAZIDE": "VALSARTAN-HYDROCHLOROTHIAZIDE"
            },
            "CADISTA": {
              "LOSARTAN POTASSIUM-HCTZ": "LOSARTAN POTASSIUM-HCTZ"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "LOSARTAN POTASSIUM-HCTZ": "LOSARTAN POTASSIUM-HCTZ"
            },
            "DIRECT RX": {
              "LOSARTAN POTASSIUM-HCTZ": "LOSARTAN POTASSIUM-HCTZ",
              "VALSARTAN-HYDROCHLOROTHIAZIDE": "VALSARTAN-HYDROCHLOROTHIAZIDE"
            },
            "HIKMA": {
              "IRBESARTAN-HYDROCHLOROTHIAZIDE": "IRBESARTAN-HYDROCHLOROTHIAZIDE",
              "LOSARTAN POTASSIUM-HCTZ": "LOSARTAN POTASSIUM-HCTZ",
              "TELMISARTAN-HCTZ": "TELMISARTAN-HCTZ"
            },
            "LEGACY PHARMACEUTICAL PKG": {
              "IRBESARTAN-HYDROCHLOROTHIAZIDE": "IRBESARTAN-HYDROCHLOROTHIAZIDE"
            },
            "LIFESTAR PHARMA": {
              "OLMESARTAN MEDOXOMIL-HCTZ": "OLMESARTAN MEDOXOMIL-HCTZ"
            },
            "LUPIN PHARMACEUTICALS": {
              "IRBESARTAN-HYDROCHLOROTHIAZIDE": "IRBESARTAN-HYDROCHLOROTHIAZIDE",
              "LOSARTAN POTASSIUM-HCTZ": "LOSARTAN POTASSIUM-HCTZ",
              "TELMISARTAN-HCTZ": "TELMISARTAN-HCTZ",
              "VALSARTAN-HYDROCHLOROTHIAZIDE": "VALSARTAN-HYDROCHLOROTHIAZIDE"
            },
            "MACLEODS PHARMACEUTICALS": {
              "CANDESARTAN CILEXETIL-HCTZ": "CANDESARTAN CILEXETIL-HCTZ",
              "IRBESARTAN-HYDROCHLOROTHIAZIDE": "IRBESARTAN-HYDROCHLOROTHIAZIDE",
              "LOSARTAN POTASSIUM-HCTZ": "LOSARTAN POTASSIUM-HCTZ",
              "TELMISARTAN-HCTZ": "TELMISARTAN-HCTZ",
              "VALSARTAN-HYDROCHLOROTHIAZIDE": "VALSARTAN-HYDROCHLOROTHIAZIDE"
            },
            "MERCK SHARP & DOHME": {
              "HYZAAR": "HYZAAR"
            },
            "MYLAN": {
              "CANDESARTAN CILEXETIL-HCTZ": "CANDESARTAN CILEXETIL-HCTZ",
              "IRBESARTAN-HYDROCHLOROTHIAZIDE": "IRBESARTAN-HYDROCHLOROTHIAZIDE",
              "LOSARTAN POTASSIUM-HCTZ": "LOSARTAN POTASSIUM-HCTZ",
              "OLMESARTAN MEDOXOMIL-HCTZ": "OLMESARTAN MEDOXOMIL-HCTZ",
              "TELMISARTAN-HCTZ": "TELMISARTAN-HCTZ",
              "VALSARTAN-HYDROCHLOROTHIAZIDE": "VALSARTAN-HYDROCHLOROTHIAZIDE"
            },
            "MYLAN INSTITUTIONAL": {
              "VALSARTAN-HYDROCHLOROTHIAZIDE": "VALSARTAN-HYDROCHLOROTHIAZIDE"
            },
            "NORTHSTAR RX": {
              "LOSARTAN POTASSIUM-HCTZ": "LOSARTAN POTASSIUM-HCTZ"
            },
            "NOVARTIS": {
              "DIOVAN HCT": "DIOVAN HCT"
            },
            "NUCARE PHARMACEUTICALS": {
              "DIOVAN HCT": "DIOVAN HCT",
              "HYZAAR": "HYZAAR"
            },
            "PAR PHARMACEUTICALS": {
              "IRBESARTAN-HYDROCHLOROTHIAZIDE": "IRBESARTAN-HYDROCHLOROTHIAZIDE",
              "LOSARTAN POTASSIUM-HCTZ": "LOSARTAN POTASSIUM-HCTZ",
              "TELMISARTAN-HCTZ": "TELMISARTAN-HCTZ",
              "VALSARTAN-HYDROCHLOROTHIAZIDE": "VALSARTAN-HYDROCHLOROTHIAZIDE"
            },
            "PDRX PHARMACEUTICAL": {
              "BENICAR HCT": "BENICAR HCT",
              "DIOVAN HCT": "DIOVAN HCT",
              "HYZAAR": "HYZAAR",
              "LOSARTAN POTASSIUM-HCTZ": "LOSARTAN POTASSIUM-HCTZ"
            },
            "PREFERRED PHARMACEUTICALS": {
              "LOSARTAN POTASSIUM-HCTZ": "LOSARTAN POTASSIUM-HCTZ",
              "VALSARTAN-HYDROCHLOROTHIAZIDE": "VALSARTAN-HYDROCHLOROTHIAZIDE"
            },
            "PROFICIENT RX": {
              "IRBESARTAN-HYDROCHLOROTHIAZIDE": "IRBESARTAN-HYDROCHLOROTHIAZIDE",
              "LOSARTAN POTASSIUM-HCTZ": "LOSARTAN POTASSIUM-HCTZ",
              "OLMESARTAN MEDOXOMIL-HCTZ": "OLMESARTAN MEDOXOMIL-HCTZ",
              "VALSARTAN-HYDROCHLOROTHIAZIDE": "VALSARTAN-HYDROCHLOROTHIAZIDE"
            },
            "QUALITY CARE": {
              "AVALIDE": "AVALIDE",
              "BENICAR HCT": "BENICAR HCT",
              "DIOVAN HCT": "DIOVAN HCT",
              "HYZAAR": "HYZAAR",
              "LOSARTAN POTASSIUM-HCTZ": "LOSARTAN POTASSIUM-HCTZ",
              "MICARDIS HCT": "MICARDIS HCT",
              "VALSARTAN-HYDROCHLOROTHIAZIDE": "VALSARTAN-HYDROCHLOROTHIAZIDE"
            },
            "QUALITY CARE PRODUCTS": {
              "LOSARTAN POTASSIUM-HCTZ": "LOSARTAN POTASSIUM-HCTZ",
              "VALSARTAN-HYDROCHLOROTHIAZIDE": "VALSARTAN-HYDROCHLOROTHIAZIDE"
            },
            "RISING PHARMACEUTICALS": {
              "LOSARTAN POTASSIUM-HCTZ": "LOSARTAN POTASSIUM-HCTZ"
            },
            "SANDOZ": {
              "LOSARTAN POTASSIUM-HCTZ": "LOSARTAN POTASSIUM-HCTZ",
              "VALSARTAN-HYDROCHLOROTHIAZIDE": "VALSARTAN-HYDROCHLOROTHIAZIDE"
            },
            "SANKYO": {
              "BENICAR HCT": "BENICAR HCT"
            },
            "SANOFI PHARMACEUTICALS": {
              "AVALIDE": "AVALIDE"
            },
            "SOLCO HEALTHCARE": {
              "IRBESARTAN-HYDROCHLOROTHIAZIDE": "IRBESARTAN-HYDROCHLOROTHIAZIDE",
              "LOSARTAN POTASSIUM-HCTZ": "LOSARTAN POTASSIUM-HCTZ",
              "OLMESARTAN MEDOXOMIL-HCTZ": "OLMESARTAN MEDOXOMIL-HCTZ",
              "TELMISARTAN-HCTZ": "TELMISARTAN-HCTZ",
              "VALSARTAN-HYDROCHLOROTHIAZIDE": "VALSARTAN-HYDROCHLOROTHIAZIDE"
            },
            "ST MARY'S MPP": {
              "LOSARTAN POTASSIUM-HCTZ": "LOSARTAN POTASSIUM-HCTZ"
            },
            "SUN PHARMACEUTICALS": {
              "OLMESARTAN MEDOXOMIL-HCTZ": "OLMESARTAN MEDOXOMIL-HCTZ"
            },
            "TEVA PHARMACEUTICALS USA": {
              "IRBESARTAN-HYDROCHLOROTHIAZIDE": "IRBESARTAN-HYDROCHLOROTHIAZIDE",
              "LOSARTAN POTASSIUM-HCTZ": "LOSARTAN POTASSIUM-HCTZ",
              "OLMESARTAN MEDOXOMIL-HCTZ": "OLMESARTAN MEDOXOMIL-HCTZ"
            },
            "TORRENT PHARMACEUTICALS": {
              "LOSARTAN POTASSIUM-HCTZ": "LOSARTAN POTASSIUM-HCTZ",
              "OLMESARTAN MEDOXOMIL-HCTZ": "OLMESARTAN MEDOXOMIL-HCTZ",
              "TELMISARTAN-HCTZ": "TELMISARTAN-HCTZ"
            },
            "WINTHROP, US": {
              "IRBESARTAN-HYDROCHLOROTHIAZIDE": "IRBESARTAN-HYDROCHLOROTHIAZIDE"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "LOSARTAN POTASSIUM-HCTZ": "LOSARTAN POTASSIUM-HCTZ"
            }
          },
          "Beta Blocker & Angiotensin II Receptor Antagonist Comb": {
            "ALLERGAN": {
              "BYVALSON": "BYVALSON"
            }
          },
          "Beta Blocker & Diuretic Combinations": {
            "A-S MEDICATION SOLUTIONS": {
              "BISOPROLOL-HYDROCHLOROTHIAZIDE": "BISOPROLOL-HYDROCHLOROTHIAZIDE"
            },
            "ACTAVIS PHARMA": {
              "ATENOLOL-CHLORTHALIDONE": "ATENOLOL-CHLORTHALIDONE"
            },
            "AIDAREX PHARMACEUTICALS": {
              "ATENOLOL-CHLORTHALIDONE": "ATENOLOL-CHLORTHALIDONE",
              "BISOPROLOL-HYDROCHLOROTHIAZIDE": "BISOPROLOL-HYDROCHLOROTHIAZIDE"
            },
            "ALEMBIC PHARMACEUTICALS": {
              "METOPROLOL-HYDROCHLOROTHIAZIDE": "METOPROLOL-HYDROCHLOROTHIAZIDE"
            },
            "ALMATICA": {
              "TENORETIC 100": "TENORETIC 100",
              "TENORETIC 50": "TENORETIC 50"
            },
            "APHENA PHARMA SOLUTIONS": {
              "ATENOLOL-CHLORTHALIDONE": "ATENOLOL-CHLORTHALIDONE"
            },
            "BLENHEIM PHARMACAL": {
              "BISOPROLOL-HYDROCHLOROTHIAZIDE": "BISOPROLOL-HYDROCHLOROTHIAZIDE"
            },
            "BRYANT RANCH PREPACK": {
              "ATENOLOL-CHLORTHALIDONE": "ATENOLOL-CHLORTHALIDONE",
              "BISOPROLOL-HYDROCHLOROTHIAZIDE": "BISOPROLOL-HYDROCHLOROTHIAZIDE"
            },
            "CONCORDIA PHARMACEUTICALS": {
              "DUTOPROL": "DUTOPROL"
            },
            "EDENBRIDGE PHARMACEUTICALS": {
              "BISOPROLOL-HYDROCHLOROTHIAZIDE": "BISOPROLOL-HYDROCHLOROTHIAZIDE"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "BISOPROLOL-HYDROCHLOROTHIAZIDE": "BISOPROLOL-HYDROCHLOROTHIAZIDE"
            },
            "IMPAX GENERICS": {
              "NADOLOL-BENDROFLUMETHIAZIDE": "NADOLOL-BENDROFLUMETHIAZIDE"
            },
            "MYLAN": {
              "ATENOLOL-CHLORTHALIDONE": "ATENOLOL-CHLORTHALIDONE",
              "BISOPROLOL-HYDROCHLOROTHIAZIDE": "BISOPROLOL-HYDROCHLOROTHIAZIDE",
              "METOPROLOL-HYDROCHLOROTHIAZIDE": "METOPROLOL-HYDROCHLOROTHIAZIDE",
              "PROPRANOLOL-HCTZ": "PROPRANOLOL-HCTZ"
            },
            "NORTHSTAR RX": {
              "ATENOLOL-CHLORTHALIDONE": "ATENOLOL-CHLORTHALIDONE",
              "METOPROLOL-HYDROCHLOROTHIAZIDE": "METOPROLOL-HYDROCHLOROTHIAZIDE"
            },
            "PDRX PHARMACEUTICAL": {
              "ATENOLOL-CHLORTHALIDONE": "ATENOLOL-CHLORTHALIDONE",
              "BISOPROLOL-HYDROCHLOROTHIAZIDE": "BISOPROLOL-HYDROCHLOROTHIAZIDE"
            },
            "PFIZER U.S.": {
              "CORZIDE": "CORZIDE"
            },
            "PREFERRED PHARMACEUTICALS": {
              "BISOPROLOL-HYDROCHLOROTHIAZIDE": "BISOPROLOL-HYDROCHLOROTHIAZIDE"
            },
            "QUALITY CARE": {
              "ATENOLOL-CHLORTHALIDONE": "ATENOLOL-CHLORTHALIDONE"
            },
            "QUALITY CARE PRODUCTS": {
              "ATENOLOL-CHLORTHALIDONE": "ATENOLOL-CHLORTHALIDONE"
            },
            "SANDOZ": {
              "BISOPROLOL-HYDROCHLOROTHIAZIDE": "BISOPROLOL-HYDROCHLOROTHIAZIDE"
            },
            "SOLUBIOMIX LLC": {
              "METOPROLOL-HCTZ ER": "METOPROLOL-HCTZ ER"
            },
            "SUN PHARMACEUTICAL": {
              "METOPROLOL-HYDROCHLOROTHIAZIDE": "METOPROLOL-HYDROCHLOROTHIAZIDE"
            },
            "TEVA PHARMACEUTICALS USA": {
              "BISOPROLOL-HYDROCHLOROTHIAZIDE": "BISOPROLOL-HYDROCHLOROTHIAZIDE"
            },
            "TEVA/WOMENS HEALTH": {
              "ZIAC": "ZIAC"
            },
            "TRIGEN LABORATORIES": {
              "METOPROLOL-HYDROCHLOROTHIAZIDE": "METOPROLOL-HYDROCHLOROTHIAZIDE"
            },
            "UNICHEM PHARMACEUTICALS": {
              "BISOPROLOL-HYDROCHLOROTHIAZIDE": "BISOPROLOL-HYDROCHLOROTHIAZIDE"
            },
            "VALIDUS PHARMACEUTICALS": {
              "LOPRESSOR HCT": "LOPRESSOR HCT"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "ATENOLOL-CHLORTHALIDONE": "ATENOLOL-CHLORTHALIDONE"
            }
          },
          "Direct Renin Inhibitors & Thiazide/Thiazide-Like Comb": {
            "NODEN PHARMA USA": {
              "TEKTURNA HCT": "TEKTURNA HCT"
            },
            "NOVARTIS": {
              "TEKTURNA HCT": "TEKTURNA HCT"
            }
          }
        },
        "Antihypertensives - Misc.": {
          "Antihypertensives - Misc.": {
            "VYERA PHARMACEUTICALS": {
              "VECAMYL": "VECAMYL"
            }
          }
        },
        "Direct Renin Inhibitors": {
          "Direct Renin Inhibitors": {
            "A-S MEDICATION SOLUTIONS": {
              "TEKTURNA": "TEKTURNA"
            },
            "NODEN PHARMA USA": {
              "TEKTURNA": "TEKTURNA"
            },
            "PAR PHARMACEUTICAL": {
              "ALISKIREN FUMARATE": "ALISKIREN FUMARATE"
            },
            "PRASCO LABORATORIES": {
              "ALISKIREN FUMARATE": "ALISKIREN FUMARATE"
            }
          }
        },
        "Selective Aldosterone Receptor Antagonists (SARAs)": {
          "Selective Aldosterone Receptor Antagonists (SARAs)": {
            "A-S MEDICATION SOLUTIONS": {
              "INSPRA": "INSPRA"
            },
            "ACCORD HEALTHCARE": {
              "EPLERENONE": "EPLERENONE"
            },
            "AMERICAN HEALTH PACKAGING": {
              "EPLERENONE": "EPLERENONE"
            },
            "APOTEX": {
              "EPLERENONE": "EPLERENONE"
            },
            "BRECKENRIDGE": {
              "EPLERENONE": "EPLERENONE"
            },
            "GREENSTONE": {
              "EPLERENONE": "EPLERENONE"
            },
            "PFIZER U.S.": {
              "INSPRA": "INSPRA"
            },
            "PRASCO LABORATORIES": {
              "EPLERENONE": "EPLERENONE"
            },
            "SANDOZ": {
              "EPLERENONE": "EPLERENONE"
            },
            "UPSHER-SMITH": {
              "EPLERENONE": "EPLERENONE"
            }
          }
        },
        "Vasodilators": {
          "Dopamine D1 Receptor Agonists": {
            "HOSPIRA": {
              "CORLOPAM": "CORLOPAM"
            }
          },
          "Vasodilators": {
            "A-S MEDICATION SOLUTIONS": {
              "HYDRALAZINE HCL": "HYDRALAZINE HCL",
              "MINOXIDIL": "MINOXIDIL"
            },
            "ACTAVIS PHARMA": {
              "MINOXIDIL": "MINOXIDIL"
            },
            "AIDAREX PHARMACEUTICALS": {
              "HYDRALAZINE HCL": "HYDRALAZINE HCL"
            },
            "AKORN": {
              "HYDRALAZINE HCL": "HYDRALAZINE HCL",
              "NITROPRUSSIDE SODIUM": "NITROPRUSSIDE SODIUM"
            },
            "AMERICAN HEALTH PACKAGING": {
              "HYDRALAZINE HCL": "HYDRALAZINE HCL",
              "MINOXIDIL": "MINOXIDIL"
            },
            "AMERICAN REGENT": {
              "HYDRALAZINE HCL": "HYDRALAZINE HCL"
            },
            "APHENA PHARMA SOLUTIONS": {
              "HYDRALAZINE HCL": "HYDRALAZINE HCL",
              "MINOXIDIL": "MINOXIDIL"
            },
            "ARMAS PHARMACEUTICALS": {
              "NITROPRUSSIDE SODIUM": "NITROPRUSSIDE SODIUM"
            },
            "AVET PHARMACEUTICALS": {
              "HYDRALAZINE HCL": "HYDRALAZINE HCL"
            },
            "AVKARE": {
              "MINOXIDIL": "MINOXIDIL"
            },
            "BAUSCH HEALTH": {
              "NITROPRESS": "NITROPRESS"
            },
            "BRYANT RANCH PREPACK": {
              "HYDRALAZINE HCL": "HYDRALAZINE HCL",
              "MINOXIDIL": "MINOXIDIL"
            },
            "CAMBER PHARMACEUTICALS": {
              "HYDRALAZINE HCL": "HYDRALAZINE HCL"
            },
            "CIVICA": {
              "HYDRALAZINE HCL": "HYDRALAZINE HCL"
            },
            "DIRECT RX": {
              "HYDRALAZINE HCL": "HYDRALAZINE HCL"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "NITROPRUSSIDE SODIUM": "NITROPRUSSIDE SODIUM"
            },
            "EXELA PHARMA SCIENCES": {
              "NIPRIDE RTU": "NIPRIDE RTU"
            },
            "EXELAN PHARMACEUTICALS": {
              "HYDRALAZINE HCL": "HYDRALAZINE HCL"
            },
            "FRESENIUS KABI USA": {
              "HYDRALAZINE HCL": "HYDRALAZINE HCL"
            },
            "GLENMARK PHARMACEUTICALS": {
              "HYDRALAZINE HCL": "HYDRALAZINE HCL"
            },
            "LIBERTY PHARMACEUTICAL": {
              "HYDRALAZINE HCL": "HYDRALAZINE HCL"
            },
            "MAJOR PHARMACEUTICALS": {
              "HYDRALAZINE HCL": "HYDRALAZINE HCL"
            },
            "MEITHEAL PHARMACEUTICALS": {
              "NITROPRUSSIDE SODIUM": "NITROPRUSSIDE SODIUM"
            },
            "MICRO LABORATORIES": {
              "NITROPRUSSIDE SODIUM": "NITROPRUSSIDE SODIUM"
            },
            "MYLAN INSTITUTIONAL": {
              "HYDRALAZINE HCL": "HYDRALAZINE HCL",
              "NITROPRUSSIDE SODIUM": "NITROPRUSSIDE SODIUM",
              "SODIUM NITROPRUSSIDE": "SODIUM NITROPRUSSIDE"
            },
            "NEXUS PHARMA": {
              "NITROPRUSSIDE SODIUM": "NITROPRUSSIDE SODIUM"
            },
            "NIVAGEN PHARMACEUTICALS": {
              "HYDRALAZINE HCL": "HYDRALAZINE HCL"
            },
            "NOVAPLUS/FRESENIUS KABI": {
              "HYDRALAZINE HCL": "HYDRALAZINE HCL"
            },
            "NOVAPLUS/SAGENT PHARMACEUTICAL": {
              "NITROPRUSSIDE SODIUM": "NITROPRUSSIDE SODIUM"
            },
            "PAR PHARMACEUTICAL": {
              "HYDRALAZINE HCL": "HYDRALAZINE HCL",
              "MINOXIDIL": "MINOXIDIL"
            },
            "PDRX PHARMACEUTICAL": {
              "HYDRALAZINE HCL": "HYDRALAZINE HCL"
            },
            "PREFERRED PHARMACEUTICALS": {
              "HYDRALAZINE HCL": "HYDRALAZINE HCL"
            },
            "PROFICIENT RX": {
              "HYDRALAZINE HCL": "HYDRALAZINE HCL"
            },
            "QUALITY CARE": {
              "HYDRALAZINE HCL": "HYDRALAZINE HCL"
            },
            "QUALITY CARE PRODUCTS": {
              "HYDRALAZINE HCL": "HYDRALAZINE HCL"
            },
            "SAGENT PHARMACEUTICAL": {
              "NITROPRUSSIDE SODIUM": "NITROPRUSSIDE SODIUM"
            },
            "SCIEGEN PHARMACEUTICALS": {
              "HYDRALAZINE HCL": "HYDRALAZINE HCL"
            },
            "SKY PACKAGING": {
              "HYDRALAZINE HCL": "HYDRALAZINE HCL"
            },
            "SLATE RUN PHARMACEUTICALS": {
              "SODIUM NITROPRUSSIDE": "SODIUM NITROPRUSSIDE"
            },
            "SOMERSET THERAPEUTICS": {
              "NITROPRUSSIDE SODIUM": "NITROPRUSSIDE SODIUM"
            },
            "ST MARY'S MPP": {
              "HYDRALAZINE HCL": "HYDRALAZINE HCL"
            },
            "STRIDES PHARMA": {
              "HYDRALAZINE HCL": "HYDRALAZINE HCL"
            },
            "SUN PHARMACEUTICALS": {
              "MINOXIDIL": "MINOXIDIL"
            },
            "TEVA PHARMACEUTICALS USA": {
              "HYDRALAZINE HCL": "HYDRALAZINE HCL"
            },
            "X-GEN PHARMACEUTICALS, INC": {
              "HYDRALAZINE HCL": "HYDRALAZINE HCL"
            }
          }
        }
      },
      "ANTIMALARIALS": {
        "Antimalarial Combinations": {
          "Antimalarial Combinations": {
            "A-S MEDICATION SOLUTIONS": {
              "ATOVAQUONE-PROGUANIL HCL": "ATOVAQUONE-PROGUANIL HCL",
              "COARTEM": "COARTEM",
              "MALARONE": "MALARONE"
            },
            "AIDAREX PHARMACEUTICALS": {
              "ATOVAQUONE-PROGUANIL HCL": "ATOVAQUONE-PROGUANIL HCL"
            },
            "BLENHEIM PHARMACAL": {
              "ATOVAQUONE-PROGUANIL HCL": "ATOVAQUONE-PROGUANIL HCL"
            },
            "BLUE POINT LABORATORIES": {
              "ATOVAQUONE-PROGUANIL HCL": "ATOVAQUONE-PROGUANIL HCL"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "ATOVAQUONE-PROGUANIL HCL": "ATOVAQUONE-PROGUANIL HCL"
            },
            "GLAXO SMITH KLINE": {
              "MALARONE": "MALARONE"
            },
            "GLENMARK PHARMACEUTICALS": {
              "ATOVAQUONE-PROGUANIL HCL": "ATOVAQUONE-PROGUANIL HCL"
            },
            "IMPRIMIS NJOF": {
              "PYRIMETHAMINE-LEUCOVORIN": "PYRIMETHAMINE-LEUCOVORIN"
            },
            "LIBERTY PHARMACEUTICAL": {
              "ATOVAQUONE-PROGUANIL HCL": "ATOVAQUONE-PROGUANIL HCL"
            },
            "MYLAN": {
              "ATOVAQUONE-PROGUANIL HCL": "ATOVAQUONE-PROGUANIL HCL"
            },
            "NOVARTIS": {
              "COARTEM": "COARTEM"
            },
            "PDRX PHARMACEUTICAL": {
              "ATOVAQUONE-PROGUANIL HCL": "ATOVAQUONE-PROGUANIL HCL",
              "MALARONE": "MALARONE"
            },
            "PRASCO LABORATORIES": {
              "ATOVAQUONE-PROGUANIL HCL": "ATOVAQUONE-PROGUANIL HCL"
            },
            "PROFICIENT RX": {
              "ATOVAQUONE-PROGUANIL HCL": "ATOVAQUONE-PROGUANIL HCL"
            },
            "ST MARY'S MPP": {
              "ATOVAQUONE-PROGUANIL HCL": "ATOVAQUONE-PROGUANIL HCL"
            }
          }
        },
        "Antimalarials": {
          "Antimalarials": {
            "60 DEGREES PHARMACEUTICALS": {
              "ARAKODA": "ARAKODA"
            },
            "A-S MEDICATION SOLUTIONS": {
              "CHLOROQUINE PHOSPHATE": "CHLOROQUINE PHOSPHATE",
              "HYDROXYCHLOROQUINE SULFATE": "HYDROXYCHLOROQUINE SULFATE",
              "MEFLOQUINE HCL": "MEFLOQUINE HCL"
            },
            "ACCORD HEALTHCARE": {
              "HYDROXYCHLOROQUINE SULFATE": "HYDROXYCHLOROQUINE SULFATE"
            },
            "ACTAVIS PHARMA": {
              "HYDROXYCHLOROQUINE SULFATE": "HYDROXYCHLOROQUINE SULFATE"
            },
            "AIDAREX PHARMACEUTICALS": {
              "HYDROXYCHLOROQUINE SULFATE": "HYDROXYCHLOROQUINE SULFATE",
              "MEFLOQUINE HCL": "MEFLOQUINE HCL"
            },
            "AMERICAN HEALTH PACKAGING": {
              "HYDROXYCHLOROQUINE SULFATE": "HYDROXYCHLOROQUINE SULFATE",
              "QUININE SULFATE": "QUININE SULFATE"
            },
            "AMNEAL PHARMACEUTICALS": {
              "HYDROXYCHLOROQUINE SULFATE": "HYDROXYCHLOROQUINE SULFATE"
            },
            "APHENA PHARMA SOLUTIONS": {
              "HYDROXYCHLOROQUINE SULFATE": "HYDROXYCHLOROQUINE SULFATE",
              "PLAQUENIL": "PLAQUENIL"
            },
            "AVKARE": {
              "HYDROXYCHLOROQUINE SULFATE": "HYDROXYCHLOROQUINE SULFATE",
              "PLAQUENIL": "PLAQUENIL",
              "PRIMAQUINE PHOSPHATE": "PRIMAQUINE PHOSPHATE"
            },
            "AVPAK": {
              "HYDROXYCHLOROQUINE SULFATE": "HYDROXYCHLOROQUINE SULFATE"
            },
            "AX PHARMACEUTICAL CORP": {
              "QUINACRINE HCL": "QUINACRINE HCL"
            },
            "BAYSHORE PHARMACEUTICALS": {
              "CHLOROQUINE PHOSPHATE": "CHLOROQUINE PHOSPHATE",
              "HYDROXYCHLOROQUINE SULFATE": "HYDROXYCHLOROQUINE SULFATE",
              "PRIMAQUINE PHOSPHATE": "PRIMAQUINE PHOSPHATE"
            },
            "BLENHEIM PHARMACAL": {
              "HYDROXYCHLOROQUINE SULFATE": "HYDROXYCHLOROQUINE SULFATE"
            },
            "BRYANT RANCH PREPACK": {
              "HYDROXYCHLOROQUINE SULFATE": "HYDROXYCHLOROQUINE SULFATE"
            },
            "CADISTA": {
              "HYDROXYCHLOROQUINE SULFATE": "HYDROXYCHLOROQUINE SULFATE"
            },
            "CONCORDIA PHARMACEUTICALS": {
              "PLAQUENIL": "PLAQUENIL"
            },
            "DIRECT RX": {
              "HYDROXYCHLOROQUINE SULFATE": "HYDROXYCHLOROQUINE SULFATE"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "HYDROXYCHLOROQUINE SULFATE": "HYDROXYCHLOROQUINE SULFATE",
              "PYRIMETHAMINE": "PYRIMETHAMINE"
            },
            "FAGRON": {
              "CHLOROQUINE PHOSPHATE": "CHLOROQUINE PHOSPHATE",
              "QUINACRINE HCL": "QUINACRINE HCL"
            },
            "FREEDOM PHARMACEUTICALS": {
              "QUINACRINE HCL": "QUINACRINE HCL",
              "QUININE SULFATE DIHYDRATE": "QUININE SULFATE DIHYDRATE"
            },
            "GLAXO SMITH KLINE": {
              "KRINTAFEL": "KRINTAFEL"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "PRIMAQUINE PHOSPHATE": "PRIMAQUINE PHOSPHATE"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "HYDROXYCHLOROQUINE SULFATE": "HYDROXYCHLOROQUINE SULFATE",
              "MEFLOQUINE HCL": "MEFLOQUINE HCL"
            },
            "HIKMA": {
              "CHLOROQUINE PHOSPHATE": "CHLOROQUINE PHOSPHATE",
              "MEFLOQUINE HCL": "MEFLOQUINE HCL"
            },
            "IMPAX GENERICS": {
              "QUININE SULFATE": "QUININE SULFATE"
            },
            "INGENUS PHARMACEUTICALS": {
              "PRIMAQUINE PHOSPHATE": "PRIMAQUINE PHOSPHATE",
              "QUININE SULFATE": "QUININE SULFATE"
            },
            "KALCHEM INTERNATIONAL": {
              "QUININE SULFATE": "QUININE SULFATE"
            },
            "LETCO MEDICAL": {
              "QUININE SULFATE DIHYDRATE": "QUININE SULFATE DIHYDRATE"
            },
            "LIBERTY PHARMACEUTICAL": {
              "CHLOROQUINE PHOSPHATE": "CHLOROQUINE PHOSPHATE",
              "HYDROXYCHLOROQUINE SULFATE": "HYDROXYCHLOROQUINE SULFATE",
              "PRIMAQUINE PHOSPHATE": "PRIMAQUINE PHOSPHATE"
            },
            "LUPIN PHARMACEUTICALS": {
              "QUININE SULFATE": "QUININE SULFATE"
            },
            "MAJOR PHARMACEUTICALS": {
              "HYDROXYCHLOROQUINE SULFATE": "HYDROXYCHLOROQUINE SULFATE"
            },
            "MYLAN": {
              "HYDROXYCHLOROQUINE SULFATE": "HYDROXYCHLOROQUINE SULFATE",
              "QUININE SULFATE": "QUININE SULFATE"
            },
            "MYLAN INSTITUTIONAL": {
              "HYDROXYCHLOROQUINE SULFATE": "HYDROXYCHLOROQUINE SULFATE"
            },
            "NORTHSTAR RX": {
              "HYDROXYCHLOROQUINE SULFATE": "HYDROXYCHLOROQUINE SULFATE"
            },
            "OAKRUM PHARMA": {
              "PYRIMETHAMINE": "PYRIMETHAMINE"
            },
            "PCCA": {
              "CHLOROQUINE PHOSPHATE": "CHLOROQUINE PHOSPHATE",
              "QUINACRINE HCL": "QUINACRINE HCL",
              "QUININE SULFATE DIHYDRATE": "QUININE SULFATE DIHYDRATE"
            },
            "PDRX PHARMACEUTICAL": {
              "CHLOROQUINE PHOSPHATE": "CHLOROQUINE PHOSPHATE",
              "PRIMAQUINE PHOSPHATE": "PRIMAQUINE PHOSPHATE"
            },
            "PDRX PHARMACEUTICALS": {
              "HYDROXYCHLOROQUINE SULFATE": "HYDROXYCHLOROQUINE SULFATE"
            },
            "PRASCO LABORATORIES": {
              "HYDROXYCHLOROQUINE SULFATE": "HYDROXYCHLOROQUINE SULFATE"
            },
            "PREFERRED PHARMACEUTICALS": {
              "HYDROXYCHLOROQUINE SULFATE": "HYDROXYCHLOROQUINE SULFATE"
            },
            "PROFICIENT RX": {
              "PLAQUENIL": "PLAQUENIL"
            },
            "QUALITY CARE": {
              "HYDROXYCHLOROQUINE SULFATE": "HYDROXYCHLOROQUINE SULFATE"
            },
            "RISING PHARMACEUTICALS": {
              "CHLOROQUINE PHOSPHATE": "CHLOROQUINE PHOSPHATE",
              "HYDROXYCHLOROQUINE SULFATE": "HYDROXYCHLOROQUINE SULFATE"
            },
            "SANDOZ": {
              "HYDROXYCHLOROQUINE SULFATE": "HYDROXYCHLOROQUINE SULFATE"
            },
            "SANOFI-AVENTIS U.S.": {
              "PRIMAQUINE PHOSPHATE": "PRIMAQUINE PHOSPHATE"
            },
            "SKY PACKAGING": {
              "HYDROXYCHLOROQUINE SULFATE": "HYDROXYCHLOROQUINE SULFATE"
            },
            "SPECTRUM": {
              "CHLOROQUINE PHOSPHATE": "CHLOROQUINE PHOSPHATE",
              "QUININE SULFATE DIHYDRATE": "QUININE SULFATE DIHYDRATE"
            },
            "SUN PHARMACEUTICALS": {
              "CHLOROQUINE PHOSPHATE": "CHLOROQUINE PHOSPHATE",
              "HYDROXYCHLOROQUINE SULFATE": "HYDROXYCHLOROQUINE SULFATE",
              "QUALAQUIN": "QUALAQUIN",
              "QUININE SULFATE": "QUININE SULFATE"
            },
            "TEVA PHARMACEUTICALS USA": {
              "HYDROXYCHLOROQUINE SULFATE": "HYDROXYCHLOROQUINE SULFATE",
              "MEFLOQUINE HCL": "MEFLOQUINE HCL",
              "QUININE SULFATE": "QUININE SULFATE"
            },
            "VA CMOP DALLAS": {
              "HYDROXYCHLOROQUINE SULFATE": "HYDROXYCHLOROQUINE SULFATE"
            },
            "VYERA PHARMACEUTICALS": {
              "DARAPRIM": "DARAPRIM"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "HYDROXYCHLOROQUINE SULFATE": "HYDROXYCHLOROQUINE SULFATE"
            }
          }
        }
      },
      "ANTIMYASTHENIC/CHOLINERGIC AGENTS": {
        "Antimyasthenic/Cholinergic Agents": {
          "Antimyasthenic/Cholinergic Agents": {
            "ALVOGEN": {
              "PYRIDOSTIGMINE BROMIDE ER": "PYRIDOSTIGMINE BROMIDE ER"
            },
            "AMERICAN HEALTH PACKAGING": {
              "PYRIDOSTIGMINE BROMIDE": "PYRIDOSTIGMINE BROMIDE"
            },
            "AMERICAN REGENT": {
              "NEOSTIGMINE METHYLSULFATE": "NEOSTIGMINE METHYLSULFATE"
            },
            "AMNEAL BIOSCIENCES": {
              "NEOSTIGMINE METHYLSULFATE": "NEOSTIGMINE METHYLSULFATE"
            },
            "AMNEAL PHARMACEUTICALS": {
              "PYRIDOSTIGMINE BROMIDE": "PYRIDOSTIGMINE BROMIDE",
              "PYRIDOSTIGMINE BROMIDE ER": "PYRIDOSTIGMINE BROMIDE ER"
            },
            "AMPHASTAR-IMS": {
              "NEOSTIGMINE METHYLSULFATE": "NEOSTIGMINE METHYLSULFATE"
            },
            "AVADEL LEGACY PHARMACEUTICALS": {
              "BLOXIVERZ": "BLOXIVERZ"
            },
            "AVPAK": {
              "PYRIDOSTIGMINE BROMIDE": "PYRIDOSTIGMINE BROMIDE"
            },
            "BAUSCH HEALTH": {
              "MESTINON": "MESTINON"
            },
            "BE PHARMACEUTICALS": {
              "NEOSTIGMINE METHYLSULFATE": "NEOSTIGMINE METHYLSULFATE"
            },
            "CATALYST PHARMACEUTICALS": {
              "FIRDAPSE": "FIRDAPSE"
            },
            "CIVICA": {
              "NEOSTIGMINE METHYLSULFATE": "NEOSTIGMINE METHYLSULFATE"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "NEOSTIGMINE METHYLSULFATE": "NEOSTIGMINE METHYLSULFATE"
            },
            "EYWA PHARMA": {
              "PYRIDOSTIGMINE BROMIDE": "PYRIDOSTIGMINE BROMIDE"
            },
            "FAGRON COMPOUNDING SERVICES": {
              "NEOSTIGMINE METHYLSULFATE": "NEOSTIGMINE METHYLSULFATE"
            },
            "FRESENIUS KABI USA": {
              "NEOSTIGMINE METHYLSULFATE": "NEOSTIGMINE METHYLSULFATE"
            },
            "HIKMA": {
              "NEOSTIGMINE METHYLSULFATE": "NEOSTIGMINE METHYLSULFATE"
            },
            "INTEGRADOSE COMPOUNDING SERVIC": {
              "NEOSTIGMINE METHYLSULFATE": "NEOSTIGMINE METHYLSULFATE"
            },
            "JACOBUS": {
              "RUZURGI": "RUZURGI"
            },
            "KEY": {
              "GUANIDINE HCL": "GUANIDINE HCL"
            },
            "LEITERS HEALTH": {
              "NEOSTIGMINE METHYLSULFATE": "NEOSTIGMINE METHYLSULFATE"
            },
            "MAJOR PHARMACEUTICALS": {
              "PYRIDOSTIGMINE BROMIDE": "PYRIDOSTIGMINE BROMIDE"
            },
            "METHOD PHARMACEUTICALS": {
              "PYRIDOSTIGMINE BROMIDE": "PYRIDOSTIGMINE BROMIDE"
            },
            "MYLAN INSTITUTIONAL": {
              "ENLON": "ENLON"
            },
            "NEPHRON STERILE COMPOUNDING": {
              "NEOSTIGMINE METHYLSULFATE": "NEOSTIGMINE METHYLSULFATE"
            },
            "NOVAPLUS/AVADEL LEGACY PHARMA": {
              "BLOXIVERZ": "BLOXIVERZ"
            },
            "NOVITIUM PHARMA": {
              "PYRIDOSTIGMINE BROMIDE": "PYRIDOSTIGMINE BROMIDE"
            },
            "OCEANSIDE PHARMACEUTICALS": {
              "PYRIDOSTIGMINE BROMIDE": "PYRIDOSTIGMINE BROMIDE",
              "PYRIDOSTIGMINE BROMIDE ER": "PYRIDOSTIGMINE BROMIDE ER"
            },
            "PAR STERILE PRODUCTS": {
              "NEOSTIGMINE METHYLSULFATE": "NEOSTIGMINE METHYLSULFATE"
            },
            "PHARMEDIUM SERVICES": {
              "NEOSTIGMINE METHYLSULFATE": "NEOSTIGMINE METHYLSULFATE"
            },
            "QUVA PHARMA": {
              "NEOSTIGMINE METHYLSULFATE": "NEOSTIGMINE METHYLSULFATE"
            },
            "RISING PHARMACEUTICALS": {
              "PYRIDOSTIGMINE BROMIDE ER": "PYRIDOSTIGMINE BROMIDE ER"
            },
            "SANDOZ": {
              "REGONOL": "REGONOL"
            },
            "SCA PHARMACEUTICALS": {
              "NEOSTIGMINE METHYLSULFATE": "NEOSTIGMINE METHYLSULFATE"
            },
            "SKY PACKAGING": {
              "PYRIDOSTIGMINE BROMIDE": "PYRIDOSTIGMINE BROMIDE"
            },
            "TRIGEN LABORATORIES": {
              "PYRIDOSTIGMINE BROMIDE": "PYRIDOSTIGMINE BROMIDE"
            },
            "VISTAPHARM INC.": {
              "PYRIDOSTIGMINE BROMIDE": "PYRIDOSTIGMINE BROMIDE"
            },
            "XIROMED": {
              "NEOSTIGMINE METHYLSULFATE": "NEOSTIGMINE METHYLSULFATE"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "PYRIDOSTIGMINE BROMIDE": "PYRIDOSTIGMINE BROMIDE"
            }
          }
        }
      },
      "ANTIMYCOBACTERIAL AGENTS": {
        "Anti TB Combinations": {
          "Anti TB Combinations": {
            "SANOFI-AVENTIS U.S.": {
              "RIFAMATE": "RIFAMATE",
              "RIFATER": "RIFATER"
            }
          }
        },
        "Antimycobacterial Agents": {
          "Antimycobacterial Agents": {
            "A-S MEDICATION SOLUTIONS": {
              "ETHAMBUTOL HCL": "ETHAMBUTOL HCL",
              "ISONIAZID": "ISONIAZID",
              "MYAMBUTOL": "MYAMBUTOL",
              "MYCOBUTIN": "MYCOBUTIN",
              "PYRAZINAMIDE": "PYRAZINAMIDE",
              "RIFAMPIN": "RIFAMPIN",
              "TRECATOR": "TRECATOR"
            },
            "AIDAREX PHARMACEUTICALS": {
              "ISONIAZID": "ISONIAZID",
              "RIFAMPIN": "RIFAMPIN"
            },
            "AKORN": {
              "CAPASTAT SULFATE": "CAPASTAT SULFATE",
              "ETHAMBUTOL HCL": "ETHAMBUTOL HCL",
              "PYRAZINAMIDE": "PYRAZINAMIDE",
              "RIFAMPIN": "RIFAMPIN"
            },
            "AMERICAN HEALTH PACKAGING": {
              "ETHAMBUTOL HCL": "ETHAMBUTOL HCL",
              "ISONIAZID": "ISONIAZID",
              "PYRAZINAMIDE": "PYRAZINAMIDE",
              "RIFABUTIN": "RIFABUTIN",
              "RIFAMPIN": "RIFAMPIN"
            },
            "APHENA PHARMA SOLUTIONS": {
              "ISONIAZID": "ISONIAZID"
            },
            "AVET PHARMACEUTICALS": {
              "RIFAMPIN": "RIFAMPIN"
            },
            "BLENHEIM PHARMACAL": {
              "ISONIAZID": "ISONIAZID"
            },
            "BRYANT RANCH PREPACK": {
              "ISONIAZID": "ISONIAZID",
              "RIFAMPIN": "RIFAMPIN"
            },
            "CMP PHARMA": {
              "ISONIAZID": "ISONIAZID"
            },
            "EPIC PHARMA": {
              "ETHAMBUTOL HCL": "ETHAMBUTOL HCL"
            },
            "FAGRON": {
              "ISONIAZID": "ISONIAZID",
              "RIFAMPIN+SYRSPEND SF": "RIFAMPIN+SYRSPEND SF"
            },
            "FRESENIUS KABI USA": {
              "RIFAMPIN": "RIFAMPIN"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "RIFAMPIN": "RIFAMPIN"
            },
            "GREENSTONE": {
              "RIFABUTIN": "RIFABUTIN"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "ISONIAZID": "ISONIAZID",
              "RIFADIN": "RIFADIN",
              "RIFAMPIN": "RIFAMPIN"
            },
            "JACOBUS": {
              "PASER": "PASER"
            },
            "JANSSEN PRODUCTS": {
              "SIRTURO": "SIRTURO"
            },
            "LANNETT": {
              "ISONIAZID": "ISONIAZID",
              "RIFAMPIN": "RIFAMPIN"
            },
            "LETCO MEDICAL": {
              "ETHAMBUTOL HCL": "ETHAMBUTOL HCL"
            },
            "LIBERTY PHARMACEUTICAL": {
              "ISONIAZID": "ISONIAZID",
              "RIFAMPIN": "RIFAMPIN"
            },
            "LUPIN PHARMACEUTICALS": {
              "ETHAMBUTOL HCL": "ETHAMBUTOL HCL",
              "RIFABUTIN": "RIFABUTIN",
              "RIFAMPIN": "RIFAMPIN"
            },
            "MACLEODS PHARMACEUTICALS": {
              "CYCLOSERINE": "CYCLOSERINE"
            },
            "MAJOR PHARMACEUTICALS": {
              "PYRAZINAMIDE": "PYRAZINAMIDE",
              "RIFAMPIN": "RIFAMPIN"
            },
            "MARLEX PHARMACEUTICALS": {
              "ISONIAZID": "ISONIAZID"
            },
            "MYLAN INSTITUTIONAL": {
              "ISONIAZID": "ISONIAZID",
              "RIFAMPIN": "RIFAMPIN"
            },
            "MYLAN SPECIALTY L.P.": {
              "PRETOMANID": "PRETOMANID"
            },
            "NOVAPLUS/SANOFI-AVENTIS": {
              "RIFADIN": "RIFADIN"
            },
            "NUCARE PHARMACEUTICALS": {
              "ISONIAZID": "ISONIAZID"
            },
            "PAR PHARMACEUTICAL": {
              "PYRAZINAMIDE": "PYRAZINAMIDE"
            },
            "PCCA": {
              "AMINOSALICYLIC ACID-4": "AMINOSALICYLIC ACID-4",
              "ISONIAZID": "ISONIAZID"
            },
            "PDRX PHARMACEUTICAL": {
              "ISONIAZID": "ISONIAZID",
              "RIFAMPIN": "RIFAMPIN"
            },
            "PFIZER U.S.": {
              "MYCOBUTIN": "MYCOBUTIN",
              "TRECATOR": "TRECATOR"
            },
            "PHARMEDIX": {
              "ISONIAZID": "ISONIAZID"
            },
            "PHARMPAK": {
              "ISONIAZID": "ISONIAZID",
              "MYAMBUTOL": "MYAMBUTOL",
              "PYRAZINAMIDE": "PYRAZINAMIDE"
            },
            "PREFERRED PHARMACEUTICALS": {
              "ISONIAZID": "ISONIAZID"
            },
            "PURDUE GMP CENTER": {
              "CYCLOSERINE": "CYCLOSERINE"
            },
            "QUALITY CARE": {
              "ISONIAZID": "ISONIAZID",
              "RIFAMPIN": "RIFAMPIN"
            },
            "SANDOZ": {
              "ISONIAZID": "ISONIAZID",
              "RIFAMPIN": "RIFAMPIN"
            },
            "SANOFI-AVENTIS U.S.": {
              "PRIFTIN": "PRIFTIN",
              "RIFADIN": "RIFADIN"
            },
            "SKY PACKAGING": {
              "RIFAMPIN": "RIFAMPIN"
            },
            "STI PHARMA": {
              "ETHAMBUTOL HCL": "ETHAMBUTOL HCL"
            },
            "TEVA PHARMACEUTICALS USA": {
              "ETHAMBUTOL HCL": "ETHAMBUTOL HCL",
              "ISONIAZID": "ISONIAZID"
            },
            "X-GEN PHARMACEUTICALS, INC": {
              "MYAMBUTOL": "MYAMBUTOL"
            }
          }
        }
      },
      "ANTINEOPLASTICS AND ADJUNCTIVE THERAPIES": {
        "Alkylating Agents": {
          "Alkylating Agents": {
            "ACCORD BIOPHARMA": {
              "PARAPLATIN": "PARAPLATIN"
            },
            "ACCORD HEALTHCARE": {
              "BUSULFAN": "BUSULFAN",
              "CARBOPLATIN": "CARBOPLATIN",
              "CISPLATIN": "CISPLATIN",
              "OXALIPLATIN": "OXALIPLATIN"
            },
            "ACTAVIS": {
              "BUSULFAN": "BUSULFAN",
              "OXALIPLATIN": "OXALIPLATIN"
            },
            "ALVOGEN": {
              "CARBOPLATIN": "CARBOPLATIN",
              "CISPLATIN": "CISPLATIN",
              "OXALIPLATIN": "OXALIPLATIN"
            },
            "AMERICAN REGENT": {
              "BUSULFAN": "BUSULFAN"
            },
            "AMERINET/BEDFORD": {
              "CARBOPLATIN": "CARBOPLATIN"
            },
            "AMNEAL BIOSCIENCES": {
              "BUSULFAN": "BUSULFAN",
              "TEPADINA": "TEPADINA"
            },
            "APOTEX": {
              "BUSULFAN": "BUSULFAN",
              "OXALIPLATIN": "OXALIPLATIN"
            },
            "ARMAS PHARMACEUTICALS": {
              "BUSULFAN": "BUSULFAN"
            },
            "ATHENEX PHARMACEUTICAL": {
              "BUSULFAN": "BUSULFAN",
              "CISPLATIN": "CISPLATIN",
              "OXALIPLATIN": "OXALIPLATIN"
            },
            "AUROMEDICS PHARMA": {
              "OXALIPLATIN": "OXALIPLATIN"
            },
            "BAXTER HEALTHCARE CORP": {
              "OXALIPLATIN": "OXALIPLATIN"
            },
            "BLUE POINT LABORATORIES": {
              "CISPLATIN": "CISPLATIN",
              "OXALIPLATIN": "OXALIPLATIN"
            },
            "BRECKENRIDGE": {
              "OXALIPLATIN": "OXALIPLATIN"
            },
            "CELLTRION USA": {
              "BUSULFAN": "BUSULFAN"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "THIOTEPA": "THIOTEPA"
            },
            "EAGLE PHARMACEUTICALS": {
              "BELRAPZO": "BELRAPZO",
              "BENDAMUSTINE HCL": "BENDAMUSTINE HCL"
            },
            "EISAI": {
              "HEXALEN": "HEXALEN"
            },
            "FOSUN PHARMA USA": {
              "OXALIPLATIN": "OXALIPLATIN"
            },
            "FRESENIUS KABI USA": {
              "BUSULFAN": "BUSULFAN",
              "CARBOPLATIN": "CARBOPLATIN",
              "CISPLATIN": "CISPLATIN",
              "OXALIPLATIN": "OXALIPLATIN"
            },
            "HIKMA": {
              "CISPLATIN": "CISPLATIN",
              "THIOTEPA": "THIOTEPA"
            },
            "HOSPIRA": {
              "BUSULFAN": "BUSULFAN",
              "CARBOPLATIN": "CARBOPLATIN",
              "OXALIPLATIN": "OXALIPLATIN"
            },
            "INGENUS PHARMACEUTICALS": {
              "CARBOPLATIN": "CARBOPLATIN",
              "OXALIPLATIN": "OXALIPLATIN"
            },
            "JAZZ PHARMACEUTICALS": {
              "ZEPZELCA": "ZEPZELCA"
            },
            "MEITHEAL PHARMACEUTICALS": {
              "CARBOPLATIN": "CARBOPLATIN",
              "OXALIPLATIN": "OXALIPLATIN"
            },
            "MYLAN INSTITUTIONAL": {
              "BUSULFAN": "BUSULFAN",
              "CARBOPLATIN": "CARBOPLATIN",
              "CISPLATIN": "CISPLATIN",
              "OXALIPLATIN": "OXALIPLATIN"
            },
            "NEXTGEN PHARMACEUTICALS": {
              "OXALIPLATIN": "OXALIPLATIN"
            },
            "NORTHSTAR RX": {
              "OXALIPLATIN": "OXALIPLATIN"
            },
            "NOVADOZ PHARMACEUTICALS": {
              "THIOTEPA": "THIOTEPA"
            },
            "NOVAPLUS/HIKMA": {
              "THIOTEPA": "THIOTEPA"
            },
            "NOVAPLUS/HOSPIRA": {
              "CARBOPLATIN": "CARBOPLATIN"
            },
            "NOVAPLUS/MYLAN INSTITUTIONAL": {
              "CARBOPLATIN": "CARBOPLATIN"
            },
            "NOVAPLUS/OTSUKA AMERICA": {
              "BUSULFEX": "BUSULFEX"
            },
            "NOVAPLUS/SANDOZ": {
              "OXALIPLATIN": "OXALIPLATIN"
            },
            "NOVAPLUS/TEVA PARENTAL MEDICIN": {
              "CARBOPLATIN": "CARBOPLATIN"
            },
            "OTSUKA AMERICA": {
              "BUSULFEX": "BUSULFEX"
            },
            "PFIZER U.S.": {
              "CARBOPLATIN": "CARBOPLATIN",
              "OXALIPLATIN": "OXALIPLATIN"
            },
            "PRASCO LABORATORIES": {
              "MYLERAN": "MYLERAN"
            },
            "SAGENT PHARMACEUTICAL": {
              "BUSULFAN": "BUSULFAN",
              "CARBOPLATIN": "CARBOPLATIN",
              "OXALIPLATIN": "OXALIPLATIN",
              "THIOTEPA": "THIOTEPA"
            },
            "SANDOZ": {
              "CARBOPLATIN": "CARBOPLATIN",
              "OXALIPLATIN": "OXALIPLATIN"
            },
            "SUN PHARMACEUTICALS": {
              "CARBOPLATIN": "CARBOPLATIN",
              "OXALIPLATIN": "OXALIPLATIN"
            },
            "TEVA PARENTERAL MEDICINES": {
              "CARBOPLATIN": "CARBOPLATIN",
              "CISPLATIN": "CISPLATIN",
              "OXALIPLATIN": "OXALIPLATIN"
            },
            "TEVA PHARMACEUTICALS USA": {
              "BENDEKA": "BENDEKA",
              "TREANDA": "TREANDA"
            },
            "WG CRITICAL CARE": {
              "CISPLATIN": "CISPLATIN"
            },
            "WINTHROP, US": {
              "OXALIPLATIN": "OXALIPLATIN"
            }
          },
          "Imidazotetrazines": {
            "ACCORD HEALTHCARE": {
              "TEMOZOLOMIDE": "TEMOZOLOMIDE"
            },
            "AMERIGEN PHARMACEUTICALS": {
              "TEMOZOLOMIDE": "TEMOZOLOMIDE"
            },
            "AMNEAL PHARMACEUTICALS": {
              "TEMOZOLOMIDE": "TEMOZOLOMIDE"
            },
            "AREVA PHARMACEUTICALS": {
              "TEMOZOLOMIDE": "TEMOZOLOMIDE"
            },
            "ASCEND LABORATORIES": {
              "TEMOZOLOMIDE": "TEMOZOLOMIDE"
            },
            "AVPAK": {
              "TEMOZOLOMIDE": "TEMOZOLOMIDE"
            },
            "HIKMA": {
              "TEMOZOLOMIDE": "TEMOZOLOMIDE"
            },
            "MAYNE PHARMA": {
              "TEMOZOLOMIDE": "TEMOZOLOMIDE"
            },
            "MERCK SHARP & DOHME": {
              "TEMODAR": "TEMODAR"
            },
            "MYLAN": {
              "TEMOZOLOMIDE": "TEMOZOLOMIDE"
            },
            "RISING PHARMACEUTICALS": {
              "TEMOZOLOMIDE": "TEMOZOLOMIDE"
            },
            "SANDOZ": {
              "TEMOZOLOMIDE": "TEMOZOLOMIDE"
            },
            "SUN PHARMACEUTICALS": {
              "TEMOZOLOMIDE": "TEMOZOLOMIDE"
            },
            "TEVA PHARMACEUTICALS USA": {
              "TEMOZOLOMIDE": "TEMOZOLOMIDE"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "TEMOZOLOMIDE": "TEMOZOLOMIDE"
            }
          },
          "Nitrogen Mustards": {
            "ACROTECH BIOPHARMA": {
              "EVOMELA": "EVOMELA"
            },
            "ACTAVIS": {
              "MELPHALAN HCL": "MELPHALAN HCL"
            },
            "ALVOGEN": {
              "MELPHALAN": "MELPHALAN"
            },
            "AMERIGEN PHARMACEUTICALS": {
              "CYCLOPHOSPHAMIDE": "CYCLOPHOSPHAMIDE"
            },
            "AMERINET/BAXTER": {
              "CYCLOPHOSPHAMIDE": "CYCLOPHOSPHAMIDE"
            },
            "AMNEAL BIOSCIENCES": {
              "CYCLOPHOSPHAMIDE": "CYCLOPHOSPHAMIDE"
            },
            "ANI  PHARMACEUTICALS": {
              "CYCLOPHOSPHAMIDE": "CYCLOPHOSPHAMIDE"
            },
            "APO PHARMA USA": {
              "ALKERAN": "ALKERAN"
            },
            "ATHENEX PHARMACEUTICAL": {
              "MELPHALAN HCL": "MELPHALAN HCL"
            },
            "BAXTER HEALTHCARE CORP": {
              "IFEX": "IFEX"
            },
            "BAXTER HEALTHCARE CORPORATION": {
              "CYCLOPHOSPHAMIDE": "CYCLOPHOSPHAMIDE",
              "IFOSFAMIDE": "IFOSFAMIDE"
            },
            "BLUE POINT LABORATORIES": {
              "CYCLOPHOSPHAMIDE": "CYCLOPHOSPHAMIDE"
            },
            "CIPLA USA": {
              "CYCLOPHOSPHAMIDE": "CYCLOPHOSPHAMIDE"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "MELPHALAN HCL": "MELPHALAN HCL"
            },
            "FOSUN PHARMA USA": {
              "MELPHALAN HCL": "MELPHALAN HCL"
            },
            "FRESENIUS KABI USA": {
              "IFOSFAMIDE": "IFOSFAMIDE",
              "MELPHALAN HCL": "MELPHALAN HCL"
            },
            "HIKMA": {
              "CYCLOPHOSPHAMIDE": "CYCLOPHOSPHAMIDE",
              "IFOSFAMIDE": "IFOSFAMIDE"
            },
            "INGENUS PHARMACEUTICALS": {
              "CYCLOPHOSPHAMIDE": "CYCLOPHOSPHAMIDE"
            },
            "MEITHEAL PHARMACEUTICALS": {
              "MELPHALAN HCL": "MELPHALAN HCL"
            },
            "MYLAN INSTITUTIONAL": {
              "IFOSFAMIDE": "IFOSFAMIDE",
              "MELPHALAN HCL": "MELPHALAN HCL"
            },
            "NORTHSTAR RX": {
              "CYCLOPHOSPHAMIDE": "CYCLOPHOSPHAMIDE"
            },
            "NOVAPLUS/BAXTER HEALTHCARE": {
              "CYCLOPHOSPHAMIDE": "CYCLOPHOSPHAMIDE",
              "IFOSFAMIDE": "IFOSFAMIDE"
            },
            "NOVAPLUS/FRESENIUS KABI": {
              "IFOSFAMIDE": "IFOSFAMIDE"
            },
            "NOVAPLUS/MYLAN INSTITUTIONAL": {
              "IFOSFAMIDE": "IFOSFAMIDE",
              "MELPHALAN HCL": "MELPHALAN HCL"
            },
            "PAR STERILE PRODUCTS": {
              "MELPHALAN HCL": "MELPHALAN HCL"
            },
            "PRASCO LABORATORIES": {
              "LEUKERAN": "LEUKERAN"
            },
            "RECORDATI RARE DISEASES": {
              "MUSTARGEN": "MUSTARGEN"
            },
            "SAGENT PHARMACEUTICAL": {
              "MELPHALAN HCL": "MELPHALAN HCL"
            },
            "SANDOZ": {
              "CYCLOPHOSPHAMIDE": "CYCLOPHOSPHAMIDE"
            },
            "STI PHARMA": {
              "CYCLOPHOSPHAMIDE": "CYCLOPHOSPHAMIDE"
            },
            "TEVA PARENTERAL MEDICINES": {
              "IFOSFAMIDE": "IFOSFAMIDE"
            }
          },
          "Nitrosoureas": {
            "AMNEAL BIOSCIENCES": {
              "CARMUSTINE": "CARMUSTINE"
            },
            "ARBOR PHARMACEUTICALS": {
              "GLIADEL WAFER": "GLIADEL WAFER"
            },
            "AVET PHARMACEUTICALS": {
              "BICNU": "BICNU",
              "CARMUSTINE": "CARMUSTINE"
            },
            "NEXTSOURCE BIOTECHNOLOGY": {
              "GLEOSTINE": "GLEOSTINE",
              "LOMUSTINE": "LOMUSTINE"
            },
            "STI PHARMA": {
              "CARMUSTINE": "CARMUSTINE"
            },
            "TEVA PARENTERAL MEDICINES": {
              "ZANOSAR": "ZANOSAR"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "CARMUSTINE": "CARMUSTINE"
            }
          },
          "Tetrahydroisoquinolines": {
            "JANSSEN PRODUCTS": {
              "YONDELIS": "YONDELIS"
            }
          }
        },
        "Antimetabolites": {
          "Antimetabolites": {
            "A-S MEDICATION SOLUTIONS": {
              "METHOTREXATE": "METHOTREXATE",
              "METHOTREXATE SODIUM": "METHOTREXATE SODIUM"
            },
            "ACCORD HEALTHCARE": {
              "AZACITIDINE": "AZACITIDINE",
              "CAPECITABINE": "CAPECITABINE",
              "DECITABINE": "DECITABINE",
              "FLUOROURACIL": "FLUOROURACIL",
              "GEMCITABINE HCL": "GEMCITABINE HCL",
              "METHOTREXATE SODIUM": "METHOTREXATE SODIUM",
              "METHOTREXATE SODIUM (PF)": "METHOTREXATE SODIUM (PF)"
            },
            "ACROTECH BIOPHARMA": {
              "FOLOTYN": "FOLOTYN"
            },
            "ACTAVIS": {
              "FLUDARABINE PHOSPHATE": "FLUDARABINE PHOSPHATE",
              "GEMCITABINE HCL": "GEMCITABINE HCL"
            },
            "ACTAVIS PHARMA": {
              "AZACITIDINE": "AZACITIDINE"
            },
            "AIDAREX PHARMACEUTICALS": {
              "METHOTREXATE": "METHOTREXATE"
            },
            "AMERICAN HEALTH PACKAGING": {
              "CAPECITABINE": "CAPECITABINE"
            },
            "AMNEAL BIOSCIENCES": {
              "CLOFARABINE": "CLOFARABINE",
              "DECITABINE": "DECITABINE"
            },
            "AMNEAL PHARMACEUTICALS": {
              "CAPECITABINE": "CAPECITABINE",
              "METHOTREXATE SODIUM": "METHOTREXATE SODIUM"
            },
            "APOTEX": {
              "CLOFARABINE": "CLOFARABINE",
              "GEMCITABINE HCL": "GEMCITABINE HCL"
            },
            "AREVA PHARMACEUTICALS": {
              "CAPECITABINE": "CAPECITABINE",
              "FLUDARABINE PHOSPHATE": "FLUDARABINE PHOSPHATE"
            },
            "ARMAS PHARMACEUTICALS": {
              "AZACITIDINE": "AZACITIDINE",
              "CAPECITABINE": "CAPECITABINE",
              "GEMCITABINE HCL": "GEMCITABINE HCL"
            },
            "ASCEND LABORATORIES": {
              "CAPECITABINE": "CAPECITABINE"
            },
            "ATHENEX PHARMACEUTICAL": {
              "GEMCITABINE HCL": "GEMCITABINE HCL"
            },
            "AUROBINDO PHARMA": {
              "CAPECITABINE": "CAPECITABINE",
              "METHOTREXATE SODIUM": "METHOTREXATE SODIUM"
            },
            "AVET PHARMACEUTICALS": {
              "GEMCITABINE HCL": "GEMCITABINE HCL"
            },
            "AVKARE": {
              "CAPECITABINE": "CAPECITABINE",
              "METHOTREXATE": "METHOTREXATE",
              "METHOTREXATE SODIUM": "METHOTREXATE SODIUM"
            },
            "AVPAK": {
              "CAPECITABINE": "CAPECITABINE"
            },
            "AZURITY PHARMACEUTICALS": {
              "XATMEP": "XATMEP"
            },
            "BLUE POINT LABORATORIES": {
              "AZACITIDINE": "AZACITIDINE",
              "DECITABINE": "DECITABINE",
              "FLUOROURACIL": "FLUOROURACIL",
              "GEMCITABINE HCL": "GEMCITABINE HCL"
            },
            "BRECKENRIDGE": {
              "AZACITIDINE": "AZACITIDINE"
            },
            "BRYANT RANCH PREPACK": {
              "METHOTREXATE": "METHOTREXATE"
            },
            "CELGENE CORP": {
              "ONUREG": "ONUREG",
              "VIDAZA": "VIDAZA"
            },
            "CELLTRION USA": {
              "AZACITIDINE": "AZACITIDINE",
              "CAPECITABINE": "CAPECITABINE"
            },
            "CIPLA USA": {
              "AZACITIDINE": "AZACITIDINE",
              "CAPECITABINE": "CAPECITABINE",
              "DECITABINE": "DECITABINE"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "METHOTREXATE": "METHOTREXATE"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "AZACITIDINE": "AZACITIDINE",
              "CLOFARABINE": "CLOFARABINE",
              "DECITABINE": "DECITABINE",
              "GEMCITABINE HCL": "GEMCITABINE HCL"
            },
            "EISAI": {
              "DACOGEN": "DACOGEN"
            },
            "FOSUN PHARMA USA": {
              "CLOFARABINE": "CLOFARABINE"
            },
            "FRESENIUS KABI USA": {
              "AZACITIDINE": "AZACITIDINE",
              "CLADRIBINE": "CLADRIBINE",
              "CLOFARABINE": "CLOFARABINE",
              "CYTARABINE (PF)": "CYTARABINE (PF)",
              "DECITABINE": "DECITABINE",
              "FLOXURIDINE": "FLOXURIDINE",
              "FLUDARABINE PHOSPHATE": "FLUDARABINE PHOSPHATE",
              "FLUOROURACIL": "FLUOROURACIL",
              "GEMCITABINE HCL": "GEMCITABINE HCL",
              "METHOTREXATE SODIUM": "METHOTREXATE SODIUM",
              "METHOTREXATE SODIUM (PF)": "METHOTREXATE SODIUM (PF)"
            },
            "GENENTECH": {
              "XELODA": "XELODA"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "CAPECITABINE": "CAPECITABINE",
              "METHOTREXATE": "METHOTREXATE"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "METHOTREXATE": "METHOTREXATE"
            },
            "HIKMA": {
              "AZACITIDINE": "AZACITIDINE",
              "CAPECITABINE": "CAPECITABINE",
              "CLADRIBINE": "CLADRIBINE",
              "FLOXURIDINE": "FLOXURIDINE",
              "MERCAPTOPURINE": "MERCAPTOPURINE",
              "METHOTREXATE": "METHOTREXATE",
              "METHOTREXATE SODIUM": "METHOTREXATE SODIUM",
              "METHOTREXATE SODIUM (PF)": "METHOTREXATE SODIUM (PF)"
            },
            "HISUN PHARMACEUTICALS": {
              "CLADRIBINE": "CLADRIBINE"
            },
            "HOSPIRA": {
              "GEMCITABINE HCL": "GEMCITABINE HCL",
              "METHOTREXATE SODIUM (PF)": "METHOTREXATE SODIUM (PF)"
            },
            "INGENUS PHARMACEUTICALS": {
              "CLOFARABINE": "CLOFARABINE",
              "DECITABINE": "DECITABINE"
            },
            "LEADIANT BIOSCIENCES": {
              "DEPOCYT": "DEPOCYT"
            },
            "LETCO MEDICAL": {
              "METHOTREXATE": "METHOTREXATE"
            },
            "LEUCADIA PHARMACEUTICALS": {
              "FLUDARABINE PHOSPHATE": "FLUDARABINE PHOSPHATE"
            },
            "LIFESTAR PHARMA": {
              "CAPECITABINE": "CAPECITABINE"
            },
            "LILLY": {
              "ALIMTA": "ALIMTA",
              "GEMZAR": "GEMZAR"
            },
            "MEITHEAL PHARMACEUTICALS": {
              "CYTARABINE (PF)": "CYTARABINE (PF)",
              "GEMCITABINE HCL": "GEMCITABINE HCL"
            },
            "MYLAN": {
              "CAPECITABINE": "CAPECITABINE",
              "MERCAPTOPURINE": "MERCAPTOPURINE",
              "METHOTREXATE": "METHOTREXATE"
            },
            "MYLAN INSTITUTIONAL": {
              "AZACITIDINE": "AZACITIDINE",
              "CAPECITABINE": "CAPECITABINE",
              "CLADRIBINE": "CLADRIBINE",
              "CLOFARABINE": "CLOFARABINE",
              "CYTARABINE (PF)": "CYTARABINE (PF)",
              "DECITABINE": "DECITABINE",
              "FLUDARABINE PHOSPHATE": "FLUDARABINE PHOSPHATE",
              "GEMCITABINE HCL": "GEMCITABINE HCL",
              "METHOTREXATE": "METHOTREXATE",
              "METHOTREXATE SODIUM (PF)": "METHOTREXATE SODIUM (PF)"
            },
            "NORTHSTAR RX": {
              "AZACITIDINE": "AZACITIDINE",
              "CAPECITABINE": "CAPECITABINE",
              "DECITABINE": "DECITABINE",
              "GEMCITABINE HCL": "GEMCITABINE HCL"
            },
            "NOVA LABORATORIES": {
              "PURIXAN": "PURIXAN"
            },
            "NOVADOZ PHARMACEUTICALS": {
              "CAPECITABINE": "CAPECITABINE",
              "DECITABINE": "DECITABINE"
            },
            "NOVAPLUS/DR.REDDY'S": {
              "DECITABINE": "DECITABINE"
            },
            "NOVAPLUS/FRESENIUS KABI": {
              "FLUOROURACIL": "FLUOROURACIL",
              "METHOTREXATE SODIUM": "METHOTREXATE SODIUM"
            },
            "NOVAPLUS/HERITAGE PHARMA": {
              "GEMCITABINE HCL": "GEMCITABINE HCL"
            },
            "NOVAPLUS/HIKMA": {
              "METHOTREXATE SODIUM": "METHOTREXATE SODIUM"
            },
            "NOVAPLUS/HOSPIRA": {
              "GEMCITABINE HCL": "GEMCITABINE HCL",
              "METHOTREXATE SODIUM": "METHOTREXATE SODIUM",
              "METHOTREXATE SODIUM (PF)": "METHOTREXATE SODIUM (PF)"
            },
            "NOVAPLUS/MYLAN INSTITUTIONAL": {
              "CLADRIBINE": "CLADRIBINE"
            },
            "NOVAPLUS/NOVADOZ PHARMA": {
              "DECITABINE": "DECITABINE"
            },
            "NOVAPLUS/SANDOZ": {
              "AZACITIDINE": "AZACITIDINE"
            },
            "NOVAPLUS/SANOFI-AVENTIS": {
              "CLOLAR": "CLOLAR"
            },
            "NOVAPLUS/TEVA PARENTAL MEDICIN": {
              "METHOTREXATE SODIUM (PF)": "METHOTREXATE SODIUM (PF)"
            },
            "NOVARTIS": {
              "ARRANON": "ARRANON"
            },
            "OTSUKA AMERICA": {
              "DACOGEN": "DACOGEN"
            },
            "PAR PHARMACEUTICAL": {
              "METHOTREXATE": "METHOTREXATE"
            },
            "PCCA": {
              "METHOTREXATE": "METHOTREXATE"
            },
            "PDRX PHARMACEUTICAL": {
              "METHOTREXATE": "METHOTREXATE"
            },
            "PFIZER U.S.": {
              "CYTARABINE": "CYTARABINE",
              "CYTARABINE (PF)": "CYTARABINE (PF)",
              "FLUDARABINE PHOSPHATE": "FLUDARABINE PHOSPHATE",
              "FLUOROURACIL": "FLUOROURACIL",
              "METHOTREXATE SODIUM": "METHOTREXATE SODIUM"
            },
            "PRASCO LABORATORIES": {
              "TABLOID": "TABLOID"
            },
            "QUALITY CARE": {
              "METHOTREXATE": "METHOTREXATE"
            },
            "QUINN PHARMACEUTICALS": {
              "MERCAPTOPURINE": "MERCAPTOPURINE"
            },
            "RISING PHARMACEUTICALS": {
              "CAPECITABINE": "CAPECITABINE"
            },
            "SAGENT PHARMACEUTICAL": {
              "DECITABINE": "DECITABINE",
              "FLUDARABINE PHOSPHATE": "FLUDARABINE PHOSPHATE",
              "FLUOROURACIL": "FLUOROURACIL",
              "GEMCITABINE HCL": "GEMCITABINE HCL"
            },
            "SANDOZ": {
              "AZACITIDINE": "AZACITIDINE",
              "DECITABINE": "DECITABINE",
              "FLUDARABINE PHOSPHATE": "FLUDARABINE PHOSPHATE",
              "METHOTREXATE SODIUM (PF)": "METHOTREXATE SODIUM (PF)"
            },
            "SANOFI PHARMACEUTICALS": {
              "CLOLAR": "CLOLAR"
            },
            "SETON PHARMACEUTICALS": {
              "AZACITIDINE": "AZACITIDINE"
            },
            "SPECTRUM": {
              "METHOTREXATE": "METHOTREXATE"
            },
            "SUN PHARMACEUTICALS": {
              "CAPECITABINE": "CAPECITABINE",
              "DECITABINE": "DECITABINE",
              "GEMCITABINE HCL": "GEMCITABINE HCL",
              "INFUGEM": "INFUGEM",
              "METHOTREXATE SODIUM": "METHOTREXATE SODIUM"
            },
            "TEVA PARENTERAL MEDICINES": {
              "ADRUCIL": "ADRUCIL",
              "FLUDARABINE PHOSPHATE": "FLUDARABINE PHOSPHATE",
              "METHOTREXATE SODIUM (PF)": "METHOTREXATE SODIUM (PF)"
            },
            "TEVA PHARMACEUTICALS USA": {
              "CAPECITABINE": "CAPECITABINE",
              "METHOTREXATE": "METHOTREXATE"
            },
            "TEVA/WOMENS HEALTH": {
              "TREXALL": "TREXALL"
            },
            "WINTHROP, US": {
              "CLOFARABINE": "CLOFARABINE"
            },
            "WOCKHARDT USA": {
              "AZACITIDINE": "AZACITIDINE"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "METHOTREXATE": "METHOTREXATE"
            }
          }
        },
        "Antineoplastic - Angiogenesis Inhibitors": {
          "Vascular Endothelial Growth Factor (VEGF) Inhibitors": {
            "AMGEN": {
              "MVASI": "MVASI"
            },
            "GENENTECH": {
              "AVASTIN": "AVASTIN"
            },
            "LILLY": {
              "CYRAMZA": "CYRAMZA"
            },
            "PFIZER U.S.": {
              "ZIRABEV": "ZIRABEV"
            },
            "SANOFI-AVENTIS U.S.": {
              "ZALTRAP": "ZALTRAP"
            }
          }
        },
        "Antineoplastic - Antibodies": {
          "Antineoplastic - Bispecific T-cell Engagers": {
            "AMGEN": {
              "BLINCYTO": "BLINCYTO"
            }
          },
          "Antineoplastic - Monoclonal Antibodies": {
            "AMGEN": {
              "KANJINTI": "KANJINTI",
              "VECTIBIX": "VECTIBIX"
            },
            "ASTRAZENECA": {
              "IMFINZI": "IMFINZI",
              "LUMOXITI": "LUMOXITI"
            },
            "B-M SQUIBB U.S. (PRIMARY CARE)": {
              "EMPLICITI": "EMPLICITI",
              "OPDIVO": "OPDIVO",
              "YERVOY": "YERVOY"
            },
            "GENENTECH": {
              "GAZYVA": "GAZYVA",
              "HERCEPTIN": "HERCEPTIN",
              "PERJETA": "PERJETA",
              "RITUXAN": "RITUXAN",
              "TECENTRIQ": "TECENTRIQ"
            },
            "GENZYME": {
              "CAMPATH": "CAMPATH"
            },
            "INNATE PHARMA": {
              "LUMOXITI": "LUMOXITI"
            },
            "JANSSEN BIOTECH": {
              "DARZALEX": "DARZALEX"
            },
            "KYOWA KIRIN": {
              "POTELIGEO": "POTELIGEO"
            },
            "LILLY": {
              "ERBITUX": "ERBITUX",
              "LARTRUVO": "LARTRUVO",
              "PORTRAZZA": "PORTRAZZA"
            },
            "MERCK SHARP & DOHME": {
              "KEYTRUDA": "KEYTRUDA",
              "ONTRUZANT": "ONTRUZANT"
            },
            "MORPHOSYS US": {
              "MONJUVI": "MONJUVI"
            },
            "MYLAN INSTITUTIONAL": {
              "OGIVRI": "OGIVRI"
            },
            "NOVARTIS": {
              "ARZERRA": "ARZERRA"
            },
            "PFIZER U.S.": {
              "RUXIENCE": "RUXIENCE",
              "TRAZIMERA": "TRAZIMERA"
            },
            "REGENERON PHARMACEUTICALS": {
              "LIBTAYO": "LIBTAYO"
            },
            "SANOFI PHARMACEUTICALS": {
              "SARCLISA": "SARCLISA"
            },
            "SERONO": {
              "BAVENCIO": "BAVENCIO"
            },
            "TEVA PHARMACEUTICALS USA": {
              "HERZUMA": "HERZUMA",
              "TRUXIMA": "TRUXIMA"
            },
            "UNITED THERAPEUTICS CORP": {
              "UNITUXIN": "UNITUXIN"
            }
          },
          "Antineoplastic -Antibody for Radiopharmaceutical Therapy": {
            "ACROTECH BIOPHARMA": {
              "ZEVALIN Y-90": "ZEVALIN Y-90"
            }
          },
          "Antineoplastic Antibody-Drug Complexes": {
            "GENENTECH": {
              "KADCYLA": "KADCYLA",
              "POLIVY": "POLIVY"
            },
            "GLAXO SMITH KLINE": {
              "BLENREP": "BLENREP"
            },
            "IMMUNOMEDICS": {
              "TRODELVY": "TRODELVY"
            },
            "PFIZER U.S.": {
              "BESPONSA": "BESPONSA",
              "MYLOTARG": "MYLOTARG"
            },
            "SANKYO": {
              "ENHERTU": "ENHERTU"
            },
            "SEATTLE GENETICS": {
              "ADCETRIS": "ADCETRIS",
              "PADCEV": "PADCEV"
            }
          }
        },
        "Antineoplastic - BCL-2 Inhibitors": {
          "Antineoplastic - BCL-2 Inhibitors": {
            "ABBVIE": {
              "VENCLEXTA": "VENCLEXTA",
              "VENCLEXTA STARTING PACK": "VENCLEXTA STARTING PACK"
            }
          }
        },
        "Antineoplastic - Cellular Immunotherapy": {
          "Antineoplastic - Autologous Cellular Immunotherapy": {
            "DENDREON CORPORATION": {
              "PROVENGE": "PROVENGE"
            },
            "KITE PHARMA": {
              "TECARTUS": "TECARTUS",
              "YESCARTA": "YESCARTA"
            },
            "NOVARTIS": {
              "KYMRIAH": "KYMRIAH"
            }
          }
        },
        "Antineoplastic - Hedgehog Pathway Inhibitors": {
          "Antineoplastic - Hedgehog Pathway Inhibitors": {
            "GENENTECH": {
              "ERIVEDGE": "ERIVEDGE"
            },
            "PFIZER U.S.": {
              "DAURISMO": "DAURISMO"
            },
            "SUN PHARMACEUTICALS": {
              "ODOMZO": "ODOMZO"
            }
          }
        },
        "Antineoplastic - Hormonal and Related Agents": {
          "Androgen Biosynthesis Inhibitors": {
            "AMERICAN HEALTH PACKAGING": {
              "ABIRATERONE ACETATE": "ABIRATERONE ACETATE"
            },
            "AMNEAL PHARMACEUTICALS": {
              "ABIRATERONE ACETATE": "ABIRATERONE ACETATE"
            },
            "APOTEX": {
              "ABIRATERONE ACETATE": "ABIRATERONE ACETATE"
            },
            "AVKARE": {
              "ABIRATERONE ACETATE": "ABIRATERONE ACETATE"
            },
            "CELLTRION USA": {
              "ABIRATERONE ACETATE": "ABIRATERONE ACETATE"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "ABIRATERONE ACETATE": "ABIRATERONE ACETATE"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "ABIRATERONE ACETATE": "ABIRATERONE ACETATE"
            },
            "HIKMA": {
              "ABIRATERONE ACETATE": "ABIRATERONE ACETATE"
            },
            "JANSSEN BIOTECH": {
              "ZYTIGA": "ZYTIGA"
            },
            "MAJOR PHARMACEUTICALS": {
              "ABIRATERONE ACETATE": "ABIRATERONE ACETATE"
            },
            "MYLAN": {
              "ABIRATERONE ACETATE": "ABIRATERONE ACETATE"
            },
            "MYLAN INSTITUTIONAL": {
              "ABIRATERONE ACETATE": "ABIRATERONE ACETATE"
            },
            "NORTHSTAR RX": {
              "ABIRATERONE ACETATE": "ABIRATERONE ACETATE"
            },
            "NOVADOZ PHARMACEUTICALS": {
              "ABIRATERONE ACETATE": "ABIRATERONE ACETATE"
            },
            "PATRIOT PHARMACEUTICALS LLC": {
              "ABIRATERONE ACETATE": "ABIRATERONE ACETATE"
            },
            "RISING PHARMACEUTICALS": {
              "ABIRATERONE ACETATE": "ABIRATERONE ACETATE"
            },
            "SUN PHARMACEUTICALS": {
              "YONSA": "YONSA"
            },
            "TEVA PHARMACEUTICALS USA": {
              "ABIRATERONE ACETATE": "ABIRATERONE ACETATE"
            },
            "WOCKHARDT USA": {
              "ABIRATERONE ACETATE": "ABIRATERONE ACETATE"
            }
          },
          "Antiadrenals": {
            "HRA PHARMA RARE DISEASES": {
              "LYSODREN": "LYSODREN"
            }
          },
          "Antiandrogens": {
            "ACCORD HEALTHCARE": {
              "BICALUTAMIDE": "BICALUTAMIDE"
            },
            "ACTAVIS PHARMA": {
              "FLUTAMIDE": "FLUTAMIDE"
            },
            "AMERICAN HEALTH PACKAGING": {
              "BICALUTAMIDE": "BICALUTAMIDE"
            },
            "ANI  PHARMACEUTICALS": {
              "CASODEX": "CASODEX",
              "NILUTAMIDE": "NILUTAMIDE"
            },
            "APOTEX": {
              "BICALUTAMIDE": "BICALUTAMIDE"
            },
            "ASTELLAS": {
              "XTANDI": "XTANDI"
            },
            "ASTRAZENECA": {
              "CASODEX": "CASODEX"
            },
            "AVKARE": {
              "BICALUTAMIDE": "BICALUTAMIDE"
            },
            "BAYER HEALTHCARE PHARMA": {
              "NUBEQA": "NUBEQA"
            },
            "CIPLA USA": {
              "FLUTAMIDE": "FLUTAMIDE"
            },
            "CONCORDIA PHARMACEUTICALS": {
              "NILANDRON": "NILANDRON"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "BICALUTAMIDE": "BICALUTAMIDE",
              "FLUTAMIDE": "FLUTAMIDE"
            },
            "JANSSEN PRODUCTS": {
              "ERLEADA": "ERLEADA"
            },
            "KREMERS URBAN": {
              "BICALUTAMIDE": "BICALUTAMIDE"
            },
            "MAJOR PHARMACEUTICALS": {
              "BICALUTAMIDE": "BICALUTAMIDE"
            },
            "MEDVANTX": {
              "CASODEX": "CASODEX"
            },
            "MYLAN": {
              "BICALUTAMIDE": "BICALUTAMIDE"
            },
            "NORTHSTAR RX": {
              "BICALUTAMIDE": "BICALUTAMIDE"
            },
            "PAR PHARMACEUTICAL": {
              "FLUTAMIDE": "FLUTAMIDE"
            },
            "PRASCO LABORATORIES": {
              "NILUTAMIDE": "NILUTAMIDE"
            },
            "SANDOZ": {
              "BICALUTAMIDE": "BICALUTAMIDE"
            },
            "SUN PHARMACEUTICAL": {
              "BICALUTAMIDE": "BICALUTAMIDE"
            },
            "SUN PHARMACEUTICALS": {
              "BICALUTAMIDE": "BICALUTAMIDE"
            },
            "TEVA PHARMACEUTICALS USA": {
              "BICALUTAMIDE": "BICALUTAMIDE",
              "FLUTAMIDE": "FLUTAMIDE"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "BICALUTAMIDE": "BICALUTAMIDE"
            }
          },
          "Antiestrogens": {
            "A-S MEDICATION SOLUTIONS": {
              "TAMOXIFEN CITRATE": "TAMOXIFEN CITRATE"
            },
            "ACTAVIS PHARMA": {
              "TAMOXIFEN CITRATE": "TAMOXIFEN CITRATE"
            },
            "AMERICAN HEALTH PACKAGING": {
              "TAMOXIFEN CITRATE": "TAMOXIFEN CITRATE"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "TAMOXIFEN CITRATE": "TAMOXIFEN CITRATE"
            },
            "KYOWA KIRIN": {
              "FARESTON": "FARESTON"
            },
            "LIBERTY PHARMACEUTICAL": {
              "TAMOXIFEN CITRATE": "TAMOXIFEN CITRATE"
            },
            "MAYNE PHARMA": {
              "SOLTAMOX": "SOLTAMOX",
              "TAMOXIFEN CITRATE": "TAMOXIFEN CITRATE"
            },
            "MYLAN": {
              "TAMOXIFEN CITRATE": "TAMOXIFEN CITRATE"
            },
            "NOVADOZ PHARMACEUTICALS": {
              "TOREMIFENE CITRATE": "TOREMIFENE CITRATE"
            },
            "RISING PHARMACEUTICALS": {
              "TOREMIFENE CITRATE": "TOREMIFENE CITRATE"
            },
            "ROSEMONT PHARMACEUTICALS": {
              "SOLTAMOX": "SOLTAMOX"
            },
            "SKY PACKAGING": {
              "TAMOXIFEN CITRATE": "TAMOXIFEN CITRATE"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "TAMOXIFEN CITRATE": "TAMOXIFEN CITRATE"
            }
          },
          "Antineoplastic - Hormonal and Related Agent Combinations": {
            "AXIA PHARMACEUTICAL": {
              "LEUPROLIDE ACETATE-BUPIVACAINE": "LEUPROLIDE ACETATE-BUPIVACAINE"
            }
          },
          "Aromatase Inhibitors": {
            "A-S MEDICATION SOLUTIONS": {
              "ANASTROZOLE": "ANASTROZOLE",
              "FEMARA": "FEMARA"
            },
            "ACCORD HEALTHCARE": {
              "ANASTROZOLE": "ANASTROZOLE",
              "LETROZOLE": "LETROZOLE"
            },
            "AIDAREX PHARMACEUTICALS": {
              "ANASTROZOLE": "ANASTROZOLE",
              "LETROZOLE": "LETROZOLE"
            },
            "ALVOGEN": {
              "EXEMESTANE": "EXEMESTANE"
            },
            "AMERICAN HEALTH PACKAGING": {
              "ANASTROZOLE": "ANASTROZOLE",
              "EXEMESTANE": "EXEMESTANE",
              "LETROZOLE": "LETROZOLE"
            },
            "ANI  PHARMACEUTICALS": {
              "ARIMIDEX": "ARIMIDEX"
            },
            "APOTEX": {
              "ANASTROZOLE": "ANASTROZOLE",
              "LETROZOLE": "LETROZOLE"
            },
            "ASTRAZENECA": {
              "ARIMIDEX": "ARIMIDEX"
            },
            "AUROBINDO PHARMA": {
              "ANASTROZOLE": "ANASTROZOLE",
              "LETROZOLE": "LETROZOLE"
            },
            "AVKARE": {
              "ANASTROZOLE": "ANASTROZOLE",
              "LETROZOLE": "LETROZOLE"
            },
            "AVPAK": {
              "ANASTROZOLE": "ANASTROZOLE",
              "LETROZOLE": "LETROZOLE"
            },
            "BLUE POINT LABORATORIES": {
              "ANASTROZOLE": "ANASTROZOLE"
            },
            "BRECKENRIDGE": {
              "ANASTROZOLE": "ANASTROZOLE",
              "EXEMESTANE": "EXEMESTANE",
              "LETROZOLE": "LETROZOLE"
            },
            "CIPLA USA": {
              "EXEMESTANE": "EXEMESTANE"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "ANASTROZOLE": "ANASTROZOLE"
            },
            "FRESENIUS KABI USA": {
              "ANASTROZOLE": "ANASTROZOLE",
              "LETROZOLE": "LETROZOLE"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "ANASTROZOLE": "ANASTROZOLE"
            },
            "GREENSTONE": {
              "EXEMESTANE": "EXEMESTANE"
            },
            "HIKMA": {
              "ANASTROZOLE": "ANASTROZOLE",
              "EXEMESTANE": "EXEMESTANE",
              "LETROZOLE": "LETROZOLE"
            },
            "KADMON PHARMACEUTICALS": {
              "ANASTROZOLE": "ANASTROZOLE"
            },
            "MAJOR PHARMACEUTICALS": {
              "ANASTROZOLE": "ANASTROZOLE"
            },
            "MYLAN": {
              "ANASTROZOLE": "ANASTROZOLE",
              "EXEMESTANE": "EXEMESTANE",
              "LETROZOLE": "LETROZOLE"
            },
            "NOVARTIS": {
              "FEMARA": "FEMARA"
            },
            "PACK PHARMACEUTICALS, LLC": {
              "ANASTROZOLE": "ANASTROZOLE"
            },
            "PDRX PHARMACEUTICAL": {
              "ANASTROZOLE": "ANASTROZOLE"
            },
            "PDRX PHARMACEUTICALS": {
              "ANASTROZOLE": "ANASTROZOLE"
            },
            "PFIZER U.S.": {
              "AROMASIN": "AROMASIN"
            },
            "PREFERRED PHARMACEUTICALS": {
              "ANASTROZOLE": "ANASTROZOLE"
            },
            "QUALITY CARE": {
              "ARIMIDEX": "ARIMIDEX",
              "AROMASIN": "AROMASIN",
              "FEMARA": "FEMARA"
            },
            "SANDOZ": {
              "ANASTROZOLE": "ANASTROZOLE"
            },
            "SUN PHARMACEUTICAL": {
              "ANASTROZOLE": "ANASTROZOLE"
            },
            "SUN PHARMACEUTICALS": {
              "LETROZOLE": "LETROZOLE"
            },
            "TEVA PHARMACEUTICALS USA": {
              "ANASTROZOLE": "ANASTROZOLE",
              "LETROZOLE": "LETROZOLE"
            },
            "UPSHER-SMITH": {
              "EXEMESTANE": "EXEMESTANE"
            },
            "YILING PHARMACEUTICAL": {
              "ANASTROZOLE": "ANASTROZOLE",
              "LETROZOLE": "LETROZOLE"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "ANASTROZOLE": "ANASTROZOLE",
              "EXEMESTANE": "EXEMESTANE"
            }
          },
          "Estrogen Receptor Antagonist": {
            "ACTAVIS PHARMA": {
              "FULVESTRANT": "FULVESTRANT"
            },
            "AMNEAL BIOSCIENCES": {
              "FULVESTRANT": "FULVESTRANT"
            },
            "ASTRAZENECA": {
              "FASLODEX": "FASLODEX"
            },
            "ATHENEX PHARMACEUTICAL": {
              "FULVESTRANT": "FULVESTRANT"
            },
            "BLUE POINT LABORATORIES": {
              "FULVESTRANT": "FULVESTRANT"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "FULVESTRANT": "FULVESTRANT"
            },
            "FRESENIUS KABI USA": {
              "FULVESTRANT": "FULVESTRANT"
            },
            "GLENMARK PHARMACEUTICALS": {
              "FULVESTRANT": "FULVESTRANT"
            },
            "MEITHEAL PHARMACEUTICALS": {
              "FULVESTRANT": "FULVESTRANT"
            },
            "MYLAN INSTITUTIONAL": {
              "FULVESTRANT": "FULVESTRANT"
            },
            "NORTHSTAR RX": {
              "FULVESTRANT": "FULVESTRANT"
            },
            "NOVAPLUS/SANDOZ": {
              "FULVESTRANT": "FULVESTRANT"
            },
            "PERRIGO PHARMACEUTICALS": {
              "FULVESTRANT": "FULVESTRANT"
            },
            "SAGENT PHARMACEUTICAL": {
              "FULVESTRANT": "FULVESTRANT"
            },
            "SANDOZ": {
              "FULVESTRANT": "FULVESTRANT"
            }
          },
          "Estrogens-Antineoplastic": {
            "PFIZER U.S.": {
              "EMCYT": "EMCYT"
            }
          },
          "Gonadotropin Releasing Hormone (GnRH) Antagonists": {
            "FERRING": {
              "FIRMAGON": "FIRMAGON",
              "FIRMAGON (240 MG DOSE)": "FIRMAGON (240 MG DOSE)"
            }
          },
          "LHRH Analogs": {
            "A-S MEDICATION SOLUTIONS": {
              "ZOLADEX": "ZOLADEX"
            },
            "ABBVIE": {
              "LUPRON DEPOT (1-MONTH)": "LUPRON DEPOT (1-MONTH)",
              "LUPRON DEPOT (3-MONTH)": "LUPRON DEPOT (3-MONTH)",
              "LUPRON DEPOT (4-MONTH)": "LUPRON DEPOT (4-MONTH)",
              "LUPRON DEPOT (6-MONTH)": "LUPRON DEPOT (6-MONTH)"
            },
            "ACTAVIS PHARMA": {
              "TRELSTAR MIXJECT": "TRELSTAR MIXJECT"
            },
            "ALLERGAN": {
              "TRELSTAR": "TRELSTAR"
            },
            "ENDO PHARMACEUTICALS": {
              "VANTAS": "VANTAS"
            },
            "SANDOZ": {
              "LEUPROLIDE ACETATE": "LEUPROLIDE ACETATE"
            },
            "SUN PHARMACEUTICALS": {
              "LEUPROLIDE ACETATE": "LEUPROLIDE ACETATE"
            },
            "TERSERA THERAPEUTICS": {
              "ZOLADEX": "ZOLADEX"
            },
            "TEVA PARENTERAL MEDICINES": {
              "LEUPROLIDE ACETATE": "LEUPROLIDE ACETATE"
            },
            "TOLMAR PHARMACEUTICALS": {
              "ELIGARD": "ELIGARD"
            },
            "VERITY PHARMACEUTICALS": {
              "TRELSTAR MIXJECT": "TRELSTAR MIXJECT"
            }
          },
          "Progestins-Antineoplastic": {
            "AKORN": {
              "MEGESTROL ACETATE": "MEGESTROL ACETATE"
            },
            "ATLANTIC BIOLOGICALS": {
              "MEGESTROL ACETATE": "MEGESTROL ACETATE"
            },
            "B & B PHARMACEUTICALS": {
              "MEGESTROL ACETATE": "MEGESTROL ACETATE"
            },
            "B-M SQUIBB ONCOLOGY/IMMUNOLOGY": {
              "MEGACE ORAL": "MEGACE ORAL"
            },
            "FAGRON": {
              "MEGESTROL ACETATE": "MEGESTROL ACETATE"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "MEGESTROL ACETATE": "MEGESTROL ACETATE"
            },
            "HIKMA": {
              "MEGESTROL ACETATE": "MEGESTROL ACETATE"
            },
            "LIBERTY PHARMACEUTICAL": {
              "MEGESTROL ACETATE": "MEGESTROL ACETATE"
            },
            "MAJOR PHARMACEUTICALS": {
              "MEGESTROL ACETATE": "MEGESTROL ACETATE"
            },
            "MEDISCA": {
              "MEGESTROL ACETATE": "MEGESTROL ACETATE"
            },
            "MORTON GROVE PHARMACEUTICALS": {
              "MEGESTROL ACETATE": "MEGESTROL ACETATE"
            },
            "MYLAN": {
              "HYDROXYPROGESTERONE CAPROATE": "HYDROXYPROGESTERONE CAPROATE"
            },
            "MYLAN INSTITUTIONAL": {
              "HYDROXYPROGESTERONE CAPROATE": "HYDROXYPROGESTERONE CAPROATE",
              "MEGESTROL ACETATE": "MEGESTROL ACETATE"
            },
            "PAR PHARMACEUTICAL": {
              "MEGESTROL ACETATE": "MEGESTROL ACETATE"
            },
            "PCCA": {
              "MEGESTROL ACETATE": "MEGESTROL ACETATE"
            },
            "PFIZER U.S.": {
              "DEPO-PROVERA": "DEPO-PROVERA"
            },
            "PHARMACEUTICAL ASSOCIATES": {
              "MEGESTROL ACETATE": "MEGESTROL ACETATE"
            },
            "PRECISION DOSE, INC": {
              "MEGESTROL ACETATE": "MEGESTROL ACETATE"
            },
            "RXCHANGE CO": {
              "MEGESTROL ACETATE": "MEGESTROL ACETATE"
            },
            "SKY PACKAGING": {
              "MEGESTROL ACETATE": "MEGESTROL ACETATE"
            },
            "SPECTRUM": {
              "MEGESTROL ACETATE": "MEGESTROL ACETATE"
            },
            "TEVA PHARMACEUTICALS USA": {
              "MEGESTROL ACETATE": "MEGESTROL ACETATE"
            },
            "VISTAPHARM INC.": {
              "MEGESTROL ACETATE": "MEGESTROL ACETATE"
            }
          }
        },
        "Antineoplastic - Immunomodulators": {
          "Antineoplastic - Immunomodulators": {
            "CELGENE CORP": {
              "POMALYST": "POMALYST"
            },
            "LETCO MEDICAL": {
              "LEVAMISOLE HCL": "LEVAMISOLE HCL"
            },
            "PCCA": {
              "LEVAMISOLE HCL": "LEVAMISOLE HCL"
            },
            "SPECTRUM": {
              "LEVAMISOLE HCL": "LEVAMISOLE HCL"
            },
            "WILLOW BIRCH PHARMA": {
              "LEVAMISOLE HCL": "LEVAMISOLE HCL"
            }
          }
        },
        "Antineoplastic - XPO1 Inhibitors": {
          "Antineoplastic - XPO1 Inhibitors": {
            "KARYOPHARM THERAPEUTICS": {
              "XPOVIO (100 MG ONCE WEEKLY)": "XPOVIO (100 MG ONCE WEEKLY)",
              "XPOVIO (40 MG ONCE WEEKLY)": "XPOVIO (40 MG ONCE WEEKLY)",
              "XPOVIO (40 MG TWICE WEEKLY)": "XPOVIO (40 MG TWICE WEEKLY)",
              "XPOVIO (60 MG ONCE WEEKLY)": "XPOVIO (60 MG ONCE WEEKLY)",
              "XPOVIO (60 MG TWICE WEEKLY)": "XPOVIO (60 MG TWICE WEEKLY)",
              "XPOVIO (80 MG ONCE WEEKLY)": "XPOVIO (80 MG ONCE WEEKLY)",
              "XPOVIO (80 MG TWICE WEEKLY)": "XPOVIO (80 MG TWICE WEEKLY)"
            }
          }
        },
        "Antineoplastic Antibiotics": {
          "Antineoplastic Antibiotics": {
            "ACCORD BIOPHARMA": {
              "MUTAMYCIN": "MUTAMYCIN"
            },
            "ACCORD HEALTHCARE": {
              "MITOMYCIN": "MITOMYCIN"
            },
            "ACTAVIS": {
              "DOXORUBICIN HCL": "DOXORUBICIN HCL",
              "EPIRUBICIN HCL": "EPIRUBICIN HCL"
            },
            "AMNEAL BIOSCIENCES": {
              "BLEO 15K": "BLEO 15K"
            },
            "AMNEAL PHARMACEUTICALS": {
              "EPIRUBICIN HCL": "EPIRUBICIN HCL"
            },
            "ARCHIS PHARMA": {
              "MITOMYCIN": "MITOMYCIN"
            },
            "AREVA PHARMACEUTICALS": {
              "EPIRUBICIN HCL": "EPIRUBICIN HCL"
            },
            "ATHENEX PHARMACEUTICAL": {
              "DOXORUBICIN HCL": "DOXORUBICIN HCL"
            },
            "BAXTER HEALTHCARE CORP": {
              "DOXIL": "DOXIL",
              "DOXORUBICIN HCL LIPOSOMAL": "DOXORUBICIN HCL LIPOSOMAL"
            },
            "BLUE POINT LABORATORIES": {
              "DOXORUBICIN HCL LIPOSOMAL": "DOXORUBICIN HCL LIPOSOMAL",
              "MITOMYCIN": "MITOMYCIN"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "DOXORUBICIN HCL LIPOSOMAL": "DOXORUBICIN HCL LIPOSOMAL"
            },
            "ENDO PHARMACEUTICALS": {
              "VALSTAR": "VALSTAR"
            },
            "FAGRON COMPOUNDING SERVICES": {
              "MITOMYCIN": "MITOMYCIN"
            },
            "FRESENIUS KABI USA": {
              "BLEOMYCIN SULFATE": "BLEOMYCIN SULFATE",
              "DOXORUBICIN HCL": "DOXORUBICIN HCL",
              "EPIRUBICIN HCL": "EPIRUBICIN HCL",
              "IDARUBICIN HCL": "IDARUBICIN HCL",
              "MITOXANTRONE HCL": "MITOXANTRONE HCL"
            },
            "GALEN US": {
              "DAUNOXOME": "DAUNOXOME"
            },
            "HIKMA": {
              "ADRIAMYCIN": "ADRIAMYCIN",
              "BLEOMYCIN SULFATE": "BLEOMYCIN SULFATE",
              "DAUNORUBICIN HCL": "DAUNORUBICIN HCL",
              "EPIRUBICIN HCL": "EPIRUBICIN HCL",
              "IDARUBICIN HCL": "IDARUBICIN HCL",
              "MITOMYCIN": "MITOMYCIN"
            },
            "HISUN PHARMACEUTICALS": {
              "DAUNORUBICIN HCL": "DAUNORUBICIN HCL"
            },
            "HOSPIRA": {
              "EPIRUBICIN HCL": "EPIRUBICIN HCL",
              "MITOXANTRONE HCL": "MITOXANTRONE HCL"
            },
            "IMPAX GENERICS": {
              "EPIRUBICIN HCL": "EPIRUBICIN HCL"
            },
            "JANSSEN PRODUCTS": {
              "DOXIL": "DOXIL"
            },
            "LEUCADIA PHARMACEUTICALS": {
              "VALRUBICIN": "VALRUBICIN"
            },
            "MEITHEAL PHARMACEUTICALS": {
              "BLEOMYCIN SULFATE": "BLEOMYCIN SULFATE"
            },
            "MYLAN INSTITUTIONAL": {
              "DACTINOMYCIN": "DACTINOMYCIN",
              "DOXORUBICIN HCL": "DOXORUBICIN HCL",
              "MITOMYCIN": "MITOMYCIN"
            },
            "NORTHSTAR RX": {
              "BLEOMYCIN SULFATE": "BLEOMYCIN SULFATE",
              "DOXORUBICIN HCL LIPOSOMAL": "DOXORUBICIN HCL LIPOSOMAL"
            },
            "NOVAPLUS/ACCORD HEALTHCARE": {
              "MITOMYCIN": "MITOMYCIN"
            },
            "NOVAPLUS/DR.REDDY'S": {
              "DOXORUBICIN HCL LIPOSOMAL": "DOXORUBICIN HCL LIPOSOMAL"
            },
            "NOVAPLUS/HIKMA": {
              "IDARUBICIN HCL": "IDARUBICIN HCL"
            },
            "NOVAPLUS/HOSPIRA": {
              "BLEOMYCIN SULFATE": "BLEOMYCIN SULFATE",
              "EPIRUBICIN HCL": "EPIRUBICIN HCL"
            },
            "NOVAPLUS/MYLAN INSTITUTIONAL": {
              "DACTINOMYCIN": "DACTINOMYCIN"
            },
            "NOVAPLUS/PFIZER U.S.": {
              "DOXORUBICIN HCL": "DOXORUBICIN HCL"
            },
            "NOVAPLUS/TEVA PARENTAL MEDICIN": {
              "DAUNORUBICIN HCL": "DAUNORUBICIN HCL"
            },
            "PATRIOT PHARMACEUTICALS LLC": {
              "DOXORUBICIN HCL LIPOSOMAL": "DOXORUBICIN HCL LIPOSOMAL"
            },
            "PFIZER U.S.": {
              "BLEOMYCIN SULFATE": "BLEOMYCIN SULFATE",
              "DOXORUBICIN HCL": "DOXORUBICIN HCL",
              "ELLENCE": "ELLENCE",
              "EPIRUBICIN HCL": "EPIRUBICIN HCL",
              "IDAMYCIN PFS": "IDAMYCIN PFS"
            },
            "PRASCO LABORATORIES": {
              "DACTINOMYCIN": "DACTINOMYCIN"
            },
            "RECORDATI RARE DISEASES": {
              "COSMEGEN": "COSMEGEN"
            },
            "SAGENT PHARMACEUTICAL": {
              "DOXORUBICIN HCL": "DOXORUBICIN HCL",
              "EPIRUBICIN HCL": "EPIRUBICIN HCL"
            },
            "SANDOZ": {
              "EPIRUBICIN HCL": "EPIRUBICIN HCL"
            },
            "SUN PHARMACEUTICAL": {
              "LIPODOX": "LIPODOX"
            },
            "SUN PHARMACEUTICALS": {
              "DOXORUBICIN HCL": "DOXORUBICIN HCL",
              "DOXORUBICIN HCL LIPOSOMAL": "DOXORUBICIN HCL LIPOSOMAL",
              "LIPODOX 50": "LIPODOX 50"
            },
            "TEVA PARENTERAL MEDICINES": {
              "BLEOMYCIN SULFATE": "BLEOMYCIN SULFATE",
              "DAUNORUBICIN HCL": "DAUNORUBICIN HCL",
              "DOXORUBICIN HCL": "DOXORUBICIN HCL",
              "IDARUBICIN HCL": "IDARUBICIN HCL",
              "MITOXANTRONE HCL": "MITOXANTRONE HCL"
            },
            "UROGEN PHARMA": {
              "JELMYTO": "JELMYTO"
            },
            "X-GEN PHARMACEUTICALS, INC": {
              "DACTINOMYCIN": "DACTINOMYCIN"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "DOXORUBICIN HCL LIPOSOMAL": "DOXORUBICIN HCL LIPOSOMAL"
            }
          }
        },
        "Antineoplastic Combinations": {
          "Antineoplastic Combinations": {
            "GENENTECH": {
              "HERCEPTIN HYLECTA": "HERCEPTIN HYLECTA",
              "PHESGO": "PHESGO",
              "RITUXAN HYCELA": "RITUXAN HYCELA"
            },
            "JANSSEN BIOTECH": {
              "DARZALEX FASPRO": "DARZALEX FASPRO"
            },
            "JAZZ PHARMACEUTICALS": {
              "VYXEOS": "VYXEOS"
            },
            "NOVARTIS": {
              "KISQALI FEMARA (400 MG DOSE)": "KISQALI FEMARA (400 MG DOSE)",
              "KISQALI FEMARA (600 MG DOSE)": "KISQALI FEMARA (600 MG DOSE)",
              "KISQALI FEMARA(200 MG DOSE)": "KISQALI FEMARA(200 MG DOSE)"
            },
            "TAIHO PHARMACEUTICAL": {
              "INQOVI": "INQOVI",
              "LONSURF": "LONSURF"
            }
          }
        },
        "Antineoplastic Enzyme Inhibitors": {
          "Antineoplastic - BRAF Kinase Inhibitors": {
            "ARRAY BIOPHARMA": {
              "BRAFTOVI": "BRAFTOVI"
            },
            "GENENTECH": {
              "ZELBORAF": "ZELBORAF"
            },
            "NOVARTIS": {
              "TAFINLAR": "TAFINLAR"
            }
          },
          "Antineoplastic - FGFR Kinase Inhibitors": {
            "INCYTE CORPORATION": {
              "PEMAZYRE": "PEMAZYRE"
            },
            "JANSSEN PRODUCTS": {
              "BALVERSA": "BALVERSA"
            }
          },
          "Antineoplastic - Histone Deacetylase Inhibitors": {
            "ACROTECH BIOPHARMA": {
              "BELEODAQ": "BELEODAQ"
            },
            "CELGENE CORP": {
              "ISTODAX": "ISTODAX",
              "ISTODAX (OVERFILL)": "ISTODAX (OVERFILL)"
            },
            "MERCK SHARP & DOHME": {
              "ZOLINZA": "ZOLINZA"
            },
            "PFIZER U.S.": {
              "ROMIDEPSIN": "ROMIDEPSIN"
            },
            "SECURA BIO": {
              "FARYDAK": "FARYDAK"
            },
            "TEVA PARENTERAL MEDICINES": {
              "ROMIDEPSIN": "ROMIDEPSIN"
            }
          },
          "Antineoplastic - MEK Inhibitors": {
            "ARRAY BIOPHARMA": {
              "MEKTOVI": "MEKTOVI"
            },
            "ASTRAZENECA": {
              "KOSELUGO": "KOSELUGO"
            },
            "GENENTECH": {
              "COTELLIC": "COTELLIC"
            },
            "NOVARTIS": {
              "MEKINIST": "MEKINIST"
            }
          },
          "Antineoplastic - Methyltransferase Inhibitors": {
            "EPIZYME": {
              "TAZVERIK": "TAZVERIK"
            }
          },
          "Antineoplastic - Multikinase Inhibitors": {
            "BAYER HEALTHCARE PHARMA": {
              "NEXAVAR": "NEXAVAR",
              "STIVARGA": "STIVARGA"
            },
            "NOVARTIS": {
              "RYDAPT": "RYDAPT"
            },
            "PFIZER U.S.": {
              "SUTENT": "SUTENT"
            }
          },
          "Antineoplastic - Proteasome Inhibitors": {
            "AMGEN": {
              "KYPROLIS": "KYPROLIS"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "BORTEZOMIB": "BORTEZOMIB"
            },
            "FRESENIUS KABI USA": {
              "BORTEZOMIB": "BORTEZOMIB"
            },
            "MILLENNIUM PHARMACEUTICALS": {
              "NINLARO": "NINLARO",
              "VELCADE": "VELCADE"
            }
          },
          "Antineoplastic - Tropomyosin Receptor Kinase Inhibitors": {
            "BAYER HEALTHCARE PHARMA": {
              "VITRAKVI": "VITRAKVI"
            },
            "GENENTECH": {
              "ROZLYTREK": "ROZLYTREK"
            }
          },
          "Antineoplastic - Tyrosine Kinase Inhibitors": {
            "A-S MEDICATION SOLUTIONS": {
              "TARCEVA": "TARCEVA"
            },
            "AMERICAN HEALTH PACKAGING": {
              "IMATINIB MESYLATE": "IMATINIB MESYLATE"
            },
            "APOTEX": {
              "IMATINIB MESYLATE": "IMATINIB MESYLATE"
            },
            "AREVA PHARMACEUTICALS": {
              "ERLOTINIB HCL": "ERLOTINIB HCL",
              "IMATINIB MESYLATE": "IMATINIB MESYLATE"
            },
            "ARIAD PHARMACEUTICALS": {
              "ALUNBRIG": "ALUNBRIG",
              "ICLUSIG": "ICLUSIG"
            },
            "ARMAS PHARMACEUTICALS": {
              "ERLOTINIB HCL": "ERLOTINIB HCL",
              "IMATINIB MESYLATE": "IMATINIB MESYLATE"
            },
            "ASCEND LABORATORIES": {
              "IMATINIB MESYLATE": "IMATINIB MESYLATE"
            },
            "ASTELLAS": {
              "XOSPATA": "XOSPATA"
            },
            "ASTRAZENECA": {
              "CALQUENCE": "CALQUENCE",
              "IRESSA": "IRESSA",
              "TAGRISSO": "TAGRISSO"
            },
            "AUROBINDO PHARMA": {
              "IMATINIB MESYLATE": "IMATINIB MESYLATE"
            },
            "AVKARE": {
              "IMATINIB MESYLATE": "IMATINIB MESYLATE"
            },
            "AVPAK": {
              "IMATINIB MESYLATE": "IMATINIB MESYLATE"
            },
            "B-M SQUIBB U.S. (PRIMARY CARE)": {
              "SPRYCEL": "SPRYCEL"
            },
            "BEIGENE USA": {
              "BRUKINSA": "BRUKINSA"
            },
            "BLUEPRINT MEDICINES CORP": {
              "AYVAKIT": "AYVAKIT",
              "GAVRETO": "GAVRETO"
            },
            "BOEHRINGER INGELHEIM": {
              "GILOTRIF": "GILOTRIF"
            },
            "BRECKENRIDGE": {
              "ERLOTINIB HCL": "ERLOTINIB HCL",
              "IMATINIB MESYLATE": "IMATINIB MESYLATE"
            },
            "CELLTRION USA": {
              "IMATINIB MESYLATE": "IMATINIB MESYLATE"
            },
            "DECIPHERA PHARMACEUTICALS": {
              "QINLOCK": "QINLOCK"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "IMATINIB MESYLATE": "IMATINIB MESYLATE"
            },
            "EISAI": {
              "LENVIMA (10 MG DAILY DOSE)": "LENVIMA (10 MG DAILY DOSE)",
              "LENVIMA (12 MG DAILY DOSE)": "LENVIMA (12 MG DAILY DOSE)",
              "LENVIMA (14 MG DAILY DOSE)": "LENVIMA (14 MG DAILY DOSE)",
              "LENVIMA (18 MG DAILY DOSE)": "LENVIMA (18 MG DAILY DOSE)",
              "LENVIMA (20 MG DAILY DOSE)": "LENVIMA (20 MG DAILY DOSE)",
              "LENVIMA (24 MG DAILY DOSE)": "LENVIMA (24 MG DAILY DOSE)",
              "LENVIMA (4 MG DAILY DOSE)": "LENVIMA (4 MG DAILY DOSE)",
              "LENVIMA (8 MG DAILY DOSE)": "LENVIMA (8 MG DAILY DOSE)"
            },
            "EXELIXIS": {
              "CABOMETYX": "CABOMETYX",
              "COMETRIQ (100 MG DAILY DOSE)": "COMETRIQ (100 MG DAILY DOSE)",
              "COMETRIQ (140 MG DAILY DOSE)": "COMETRIQ (140 MG DAILY DOSE)",
              "COMETRIQ (60 MG DAILY DOSE)": "COMETRIQ (60 MG DAILY DOSE)"
            },
            "GENENTECH": {
              "ALECENSA": "ALECENSA",
              "TARCEVA": "TARCEVA"
            },
            "GENZYME": {
              "CAPRELSA": "CAPRELSA"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "IMATINIB MESYLATE": "IMATINIB MESYLATE"
            },
            "HIKMA": {
              "IMATINIB MESYLATE": "IMATINIB MESYLATE"
            },
            "LILLY": {
              "RETEVMO": "RETEVMO"
            },
            "LUPIN PHARMACEUTICALS": {
              "IMATINIB MESYLATE": "IMATINIB MESYLATE",
              "LAPATINIB DITOSYLATE": "LAPATINIB DITOSYLATE"
            },
            "MAJOR PHARMACEUTICALS": {
              "IMATINIB MESYLATE": "IMATINIB MESYLATE"
            },
            "MILLENNIUM PHARMACEUTICALS": {
              "ALUNBRIG": "ALUNBRIG",
              "ICLUSIG": "ICLUSIG"
            },
            "MYLAN": {
              "ERLOTINIB HCL": "ERLOTINIB HCL",
              "IMATINIB MESYLATE": "IMATINIB MESYLATE"
            },
            "MYLAN INSTITUTIONAL": {
              "ERLOTINIB HCL": "ERLOTINIB HCL",
              "IMATINIB MESYLATE": "IMATINIB MESYLATE"
            },
            "NORTHSTAR RX": {
              "IMATINIB MESYLATE": "IMATINIB MESYLATE"
            },
            "NOVARTIS": {
              "GLEEVEC": "GLEEVEC",
              "TABRECTA": "TABRECTA",
              "TASIGNA": "TASIGNA",
              "TYKERB": "TYKERB",
              "VOTRIENT": "VOTRIENT",
              "ZYKADIA": "ZYKADIA"
            },
            "PFIZER U.S.": {
              "BOSULIF": "BOSULIF",
              "INLYTA": "INLYTA",
              "LORBRENA": "LORBRENA",
              "VIZIMPRO": "VIZIMPRO",
              "XALKORI": "XALKORI"
            },
            "PHARMACYCLICS": {
              "IMBRUVICA": "IMBRUVICA"
            },
            "PUMA BIOTECHNOLOGY": {
              "NERLYNX": "NERLYNX"
            },
            "SANKYO": {
              "TURALIO": "TURALIO"
            },
            "SEATTLE GENETICS": {
              "TUKYSA": "TUKYSA"
            },
            "SUN PHARMACEUTICALS": {
              "ERLOTINIB HCL": "ERLOTINIB HCL",
              "IMATINIB MESYLATE": "IMATINIB MESYLATE"
            },
            "TEVA PHARMACEUTICALS USA": {
              "ERLOTINIB HCL": "ERLOTINIB HCL",
              "IMATINIB MESYLATE": "IMATINIB MESYLATE"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "ERLOTINIB HCL": "ERLOTINIB HCL"
            }
          },
          "Antineoplastic - mTOR Kinase Inhibitors": {
            "ACCORD HEALTHCARE": {
              "TEMSIROLIMUS": "TEMSIROLIMUS"
            },
            "ALMAJECT": {
              "TEMSIROLIMUS": "TEMSIROLIMUS"
            },
            "HIKMA": {
              "EVEROLIMUS": "EVEROLIMUS"
            },
            "MYLAN": {
              "EVEROLIMUS": "EVEROLIMUS"
            },
            "NOVARTIS": {
              "AFINITOR": "AFINITOR",
              "AFINITOR DISPERZ": "AFINITOR DISPERZ"
            },
            "PAR PHARMACEUTICAL": {
              "EVEROLIMUS": "EVEROLIMUS"
            },
            "PFIZER U.S.": {
              "TORISEL": "TORISEL"
            },
            "TEVA PHARMACEUTICALS USA": {
              "EVEROLIMUS": "EVEROLIMUS"
            }
          },
          "Cyclin-Dependent Kinases (CDK) Inhibitors": {
            "LILLY": {
              "VERZENIO": "VERZENIO"
            },
            "NOVARTIS": {
              "KISQALI (200 MG DOSE)": "KISQALI (200 MG DOSE)",
              "KISQALI (400 MG DOSE)": "KISQALI (400 MG DOSE)",
              "KISQALI (600 MG DOSE)": "KISQALI (600 MG DOSE)"
            },
            "PFIZER U.S.": {
              "IBRANCE": "IBRANCE"
            }
          },
          "Isocitrate Dehydrogenase-1 (IDH1) Inhibitors": {
            "AGIOS PHARMACEUTICALS": {
              "TIBSOVO": "TIBSOVO"
            }
          },
          "Isocitrate Dehydrogenase-2 (IDH2) Inhibitors": {
            "CELGENE CORP": {
              "IDHIFA": "IDHIFA"
            }
          },
          "Janus Associated Kinase (JAK) Inhibitors": {
            "CELGENE CORP": {
              "INREBIC": "INREBIC"
            },
            "INCYTE CORPORATION": {
              "JAKAFI": "JAKAFI"
            }
          },
          "Phosphatidylinositol 3-Kinase (PI3K) Inhibitors": {
            "BAYER HEALTHCARE PHARMA": {
              "ALIQOPA": "ALIQOPA"
            },
            "GILEAD SCIENCES": {
              "ZYDELIG": "ZYDELIG"
            },
            "NOVARTIS": {
              "PIQRAY (200 MG DAILY DOSE)": "PIQRAY (200 MG DAILY DOSE)",
              "PIQRAY (250 MG DAILY DOSE)": "PIQRAY (250 MG DAILY DOSE)",
              "PIQRAY (300 MG DAILY DOSE)": "PIQRAY (300 MG DAILY DOSE)"
            },
            "VERASTEM": {
              "COPIKTRA": "COPIKTRA"
            }
          },
          "Poly (ADP-ribose) Polymerase (PARP) Inhibitors": {
            "ASTRAZENECA": {
              "LYNPARZA": "LYNPARZA"
            },
            "CLOVIS ONCOLOGY": {
              "RUBRACA": "RUBRACA"
            },
            "GLAXO SMITH KLINE": {
              "ZEJULA": "ZEJULA"
            },
            "PFIZER U.S.": {
              "TALZENNA": "TALZENNA"
            }
          }
        },
        "Antineoplastic Enzymes": {
          "Antineoplastic Enzymes": {
            "BAXALTA": {
              "ONCASPAR": "ONCASPAR"
            },
            "JAZZ PHARMACEUTICALS": {
              "ERWINAZE": "ERWINAZE"
            },
            "SERVIER PHARMACEUTICALS": {
              "ASPARLAS": "ASPARLAS",
              "ONCASPAR": "ONCASPAR"
            }
          }
        },
        "Antineoplastic Radiopharmaceuticals": {
          "Antineoplastic Radiopharmaceuticals": {
            "ADVANCED ACCELERATOR APPL": {
              "LUTATHERA": "LUTATHERA"
            },
            "BAYER HEALTHCARE PHARMA": {
              "XOFIGO": "XOFIGO"
            },
            "GE HEALTHCARE": {
              "METASTRON": "METASTRON"
            },
            "LANTHEUS MEDICAL IMAGING": {
              "QUADRAMET": "QUADRAMET"
            },
            "PROGENICS PHARMACEUTICALS": {
              "AZEDRA DOSIMETRIC": "AZEDRA DOSIMETRIC",
              "AZEDRA THERAPEUTIC": "AZEDRA THERAPEUTIC"
            },
            "Q BIOMED": {
              "STRONTIUM CHLORIDE SR-89": "STRONTIUM CHLORIDE SR-89"
            }
          }
        },
        "Antineoplastics Misc.": {
          "Antineoplastics - Interleukins": {
            "CLINIGEN GROUP PLC": {
              "PROLEUKIN": "PROLEUKIN"
            },
            "STEMLINE THERAPEUTICS": {
              "ELZONRIS": "ELZONRIS"
            }
          },
          "Antineoplastics - Photoactivated Agents": {
            "PINNACLE BIOLOGICS": {
              "PHOTOFRIN": "PHOTOFRIN"
            },
            "THERAKOS INC.": {
              "UVADEX": "UVADEX"
            }
          },
          "Antineoplastics Misc.": {
            "A-S MEDICATION SOLUTIONS": {
              "HYDROXYUREA": "HYDROXYUREA"
            },
            "AMERICAN HEALTH PACKAGING": {
              "HYDROXYUREA": "HYDROXYUREA"
            },
            "AMRING PHARMACEUTICALS": {
              "ARSENIC TRIOXIDE": "ARSENIC TRIOXIDE"
            },
            "AVKARE": {
              "HYDROXYUREA": "HYDROXYUREA"
            },
            "B-M SQUIBB ONCOLOGY/IMMUNOLOGY": {
              "HYDREA": "HYDREA"
            },
            "FRESENIUS KABI USA": {
              "ARSENIC TRIOXIDE": "ARSENIC TRIOXIDE",
              "DACARBAZINE": "DACARBAZINE"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "HYDROXYUREA": "HYDROXYUREA"
            },
            "HEMISPHERX BIOPHARMA, INC.": {
              "ALFERON N": "ALFERON N"
            },
            "HIKMA": {
              "DACARBAZINE": "DACARBAZINE"
            },
            "HORIZON THERAPEUTICS USA": {
              "ACTIMMUNE": "ACTIMMUNE"
            },
            "HOSPIRA": {
              "NIPENT": "NIPENT"
            },
            "INGENUS PHARMACEUTICALS": {
              "ARSENIC TRIOXIDE": "ARSENIC TRIOXIDE"
            },
            "LEADIANT BIOSCIENCES": {
              "MATULANE": "MATULANE"
            },
            "LIBERTY PHARMACEUTICAL": {
              "HYDROXYUREA": "HYDROXYUREA"
            },
            "MAJOR PHARMACEUTICALS": {
              "HYDROXYUREA": "HYDROXYUREA"
            },
            "MERCK SHARP & DOHME": {
              "INTRON A": "INTRON A",
              "SYLATRON": "SYLATRON"
            },
            "NEXUS PHARMA": {
              "ARSENIC TRIOXIDE": "ARSENIC TRIOXIDE"
            },
            "NOVAPLUS/HOSPIRA": {
              "NIPENT": "NIPENT"
            },
            "ORGANON": {
              "TICE BCG": "TICE BCG"
            },
            "PAR PHARMACEUTICAL": {
              "HYDROXYUREA": "HYDROXYUREA"
            },
            "PFIZER U.S.": {
              "DACARBAZINE": "DACARBAZINE"
            },
            "SANOFI PASTEUR": {
              "THERACYS": "THERACYS"
            },
            "TEVA PARENTERAL MEDICINES": {
              "DACARBAZINE": "DACARBAZINE"
            },
            "TEVA PHARMACEUTICALS USA": {
              "HYDROXYUREA": "HYDROXYUREA",
              "SYNRIBO": "SYNRIBO",
              "TRISENOX": "TRISENOX"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "ARSENIC TRIOXIDE": "ARSENIC TRIOXIDE"
            }
          },
          "Retinoids": {
            "AMERICAN HEALTH PACKAGING": {
              "TRETINOIN": "TRETINOIN"
            },
            "AVKARE": {
              "TRETINOIN": "TRETINOIN"
            },
            "GLENMARK PHARMACEUTICALS": {
              "TRETINOIN": "TRETINOIN"
            },
            "MAJOR PHARMACEUTICALS": {
              "TRETINOIN": "TRETINOIN"
            },
            "PAR PHARMACEUTICAL": {
              "TRETINOIN": "TRETINOIN"
            },
            "TEVA PHARMACEUTICALS USA": {
              "TRETINOIN": "TRETINOIN"
            }
          },
          "Selective Retinoid X Receptor Agonists": {
            "AMERIGEN PHARMACEUTICALS": {
              "BEXAROTENE": "BEXAROTENE"
            },
            "AMNEAL PHARMACEUTICALS": {
              "BEXAROTENE": "BEXAROTENE"
            },
            "BAUSCH HEALTH": {
              "TARGRETIN": "TARGRETIN"
            },
            "MYLAN": {
              "BEXAROTENE": "BEXAROTENE"
            },
            "MYLAN INSTITUTIONAL": {
              "BEXAROTENE": "BEXAROTENE"
            },
            "OCEANSIDE PHARMACEUTICALS": {
              "BEXAROTENE": "BEXAROTENE"
            },
            "UPSHER-SMITH": {
              "BEXAROTENE": "BEXAROTENE"
            }
          }
        },
        "Chemotherapy Adjuncts": {
          "Chemotherapy Adjuncts - Hyperuricemia Agents": {
            "SANOFI-AVENTIS U.S.": {
              "ELITEK": "ELITEK"
            }
          },
          "Chemotherapy Adjuncts - Keratinocyte Growth Factors": {
            "SOBI": {
              "KEPIVANCE": "KEPIVANCE"
            }
          }
        },
        "Chemotherapy Rescue/Antidote Agents": {
          "Carboxypeptidase Enzyme Agents": {
            "BTG INTERNATIONAL": {
              "VORAXAZE": "VORAXAZE"
            }
          },
          "Cardiac Protective Agents": {
            "ALVOGEN": {
              "DEXRAZOXANE HCL": "DEXRAZOXANE HCL"
            },
            "BRECKENRIDGE": {
              "DEXRAZOXANE HCL": "DEXRAZOXANE HCL"
            },
            "CLINIGEN GROUP PLC": {
              "TOTECT": "TOTECT"
            },
            "FOSUN PHARMA USA": {
              "DEXRAZOXANE HCL": "DEXRAZOXANE HCL"
            },
            "HIKMA": {
              "DEXRAZOXANE HCL": "DEXRAZOXANE HCL"
            },
            "MYLAN INSTITUTIONAL": {
              "DEXRAZOXANE HCL": "DEXRAZOXANE HCL"
            },
            "PFIZER U.S.": {
              "ZINECARD": "ZINECARD"
            }
          },
          "Folic Acid Antagonists Rescue Agents": {
            "ACROTECH BIOPHARMA": {
              "FUSILEV": "FUSILEV",
              "KHAPZORY": "KHAPZORY"
            },
            "ACTAVIS": {
              "LEVOLEUCOVORIN CALCIUM": "LEVOLEUCOVORIN CALCIUM"
            },
            "ACTAVIS PHARMA": {
              "LEVOLEUCOVORIN CALCIUM": "LEVOLEUCOVORIN CALCIUM"
            },
            "AMERICAN HEALTH PACKAGING": {
              "LEUCOVORIN CALCIUM": "LEUCOVORIN CALCIUM"
            },
            "AMNEAL BIOSCIENCES": {
              "LEVOLEUCOVORIN CALCIUM": "LEVOLEUCOVORIN CALCIUM",
              "LEVOLEUCOVORIN CALCIUM PF": "LEVOLEUCOVORIN CALCIUM PF"
            },
            "BLUE POINT LABORATORIES": {
              "LEUCOVORIN CALCIUM": "LEUCOVORIN CALCIUM"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "LEVOLEUCOVORIN CALCIUM PF": "LEVOLEUCOVORIN CALCIUM PF"
            },
            "EPIC PHARMA": {
              "LEUCOVORIN CALCIUM": "LEUCOVORIN CALCIUM"
            },
            "FOSUN PHARMA USA": {
              "LEVOLEUCOVORIN CALCIUM PF": "LEVOLEUCOVORIN CALCIUM PF"
            },
            "FRESENIUS KABI USA": {
              "LEUCOVORIN CALCIUM": "LEUCOVORIN CALCIUM"
            },
            "HIKMA": {
              "LEUCOVORIN CALCIUM": "LEUCOVORIN CALCIUM",
              "LEVOLEUCOVORIN CALCIUM": "LEVOLEUCOVORIN CALCIUM"
            },
            "INGENUS PHARMACEUTICALS": {
              "LEUCOVORIN CALCIUM": "LEUCOVORIN CALCIUM",
              "LEVOLEUCOVORIN CALCIUM PF": "LEVOLEUCOVORIN CALCIUM PF"
            },
            "MAJOR PHARMACEUTICALS": {
              "LEUCOVORIN CALCIUM": "LEUCOVORIN CALCIUM"
            },
            "MEITHEAL PHARMACEUTICALS": {
              "LEVOLEUCOVORIN CALCIUM": "LEVOLEUCOVORIN CALCIUM",
              "LEVOLEUCOVORIN CALCIUM PF": "LEVOLEUCOVORIN CALCIUM PF"
            },
            "MYLAN INSTITUTIONAL": {
              "LEUCOVORIN CALCIUM": "LEUCOVORIN CALCIUM",
              "LEVOLEUCOVORIN CALCIUM PF": "LEVOLEUCOVORIN CALCIUM PF"
            },
            "NORTHSTAR RX": {
              "LEVOLEUCOVORIN CALCIUM PF": "LEVOLEUCOVORIN CALCIUM PF"
            },
            "NOVAPLUS/FRESENIUS KABI": {
              "LEUCOVORIN CALCIUM": "LEUCOVORIN CALCIUM"
            },
            "NOVAPLUS/HIKMA": {
              "LEUCOVORIN CALCIUM": "LEUCOVORIN CALCIUM"
            },
            "NOVAPLUS/TEVA PARENTAL MEDICIN": {
              "LEUCOVORIN CALCIUM": "LEUCOVORIN CALCIUM"
            },
            "SAGENT PHARMACEUTICAL": {
              "LEUCOVORIN CALCIUM": "LEUCOVORIN CALCIUM"
            },
            "SANDOZ": {
              "LEVOLEUCOVORIN CALCIUM PF": "LEVOLEUCOVORIN CALCIUM PF"
            },
            "TEVA PARENTERAL MEDICINES": {
              "LEUCOVORIN CALCIUM": "LEUCOVORIN CALCIUM"
            },
            "TEVA PHARMACEUTICALS USA": {
              "LEUCOVORIN CALCIUM": "LEUCOVORIN CALCIUM"
            }
          },
          "Urinary Tract Protective Agents": {
            "ALVOGEN": {
              "MESNA": "MESNA"
            },
            "ATHENEX PHARMACEUTICAL": {
              "MESNA": "MESNA"
            },
            "BAXTER HEALTHCARE CORP": {
              "MESNEX": "MESNEX"
            },
            "BAXTER HEALTHCARE CORPORATION": {
              "MESNA": "MESNA",
              "MESNEX": "MESNEX"
            },
            "CLINIGEN GROUP PLC": {
              "ETHYOL": "ETHYOL"
            },
            "CUMBERLAND PHARMACEUTICALS": {
              "ETHYOL": "ETHYOL"
            },
            "FRESENIUS KABI USA": {
              "MESNA": "MESNA"
            },
            "HIKMA": {
              "AMIFOSTINE": "AMIFOSTINE"
            },
            "MYLAN INSTITUTIONAL": {
              "MESNA": "MESNA"
            },
            "NOVAPLUS/BAXTER HEALTHCARE": {
              "MESNA": "MESNA"
            },
            "NOVAPLUS/SAGENT PHARMACEUTICAL": {
              "MESNA": "MESNA"
            },
            "SAGENT PHARMACEUTICAL": {
              "MESNA": "MESNA"
            },
            "SUN PHARMACEUTICAL": {
              "AMIFOSTINE": "AMIFOSTINE"
            },
            "TEVA PARENTERAL MEDICINES": {
              "MESNA": "MESNA"
            }
          }
        },
        "Mitotic Inhibitors": {
          "Mitotic Inhibitors": {
            "ACCORD HEALTHCARE": {
              "DOCETAXEL": "DOCETAXEL",
              "ETOPOSIDE": "ETOPOSIDE"
            },
            "ACROTECH BIOPHARMA": {
              "MARQIBO": "MARQIBO"
            },
            "ACTAVIS": {
              "DOCETAXEL": "DOCETAXEL",
              "PACLITAXEL": "PACLITAXEL",
              "VINORELBINE TARTRATE": "VINORELBINE TARTRATE"
            },
            "ALVOGEN": {
              "PACLITAXEL": "PACLITAXEL"
            },
            "AMERINET/BEDFORD": {
              "VINORELBINE TARTRATE": "VINORELBINE TARTRATE"
            },
            "AMERINET/PIERRE FABRE PHARMA": {
              "VINORELBINE TARTRATE": "VINORELBINE TARTRATE"
            },
            "ARMAS PHARMACEUTICALS": {
              "DOCETAXEL": "DOCETAXEL"
            },
            "ATHENEX PHARMACEUTICAL": {
              "PACLITAXEL": "PACLITAXEL"
            },
            "BAXTER HEALTHCARE CORP": {
              "DOCETAXEL": "DOCETAXEL"
            },
            "BLUE POINT LABORATORIES": {
              "ETOPOSIDE": "ETOPOSIDE"
            },
            "BRECKENRIDGE": {
              "PACLITAXEL": "PACLITAXEL"
            },
            "CELGENE CORP": {
              "ABRAXANE": "ABRAXANE"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "DOCETAXEL": "DOCETAXEL"
            },
            "EAGLE PHARMACEUTICALS": {
              "DOCETAXEL (NON-ALCOHOL)": "DOCETAXEL (NON-ALCOHOL)"
            },
            "EISAI": {
              "HALAVEN": "HALAVEN"
            },
            "FRESENIUS KABI USA": {
              "ETOPOSIDE": "ETOPOSIDE",
              "PACLITAXEL": "PACLITAXEL",
              "VINBLASTINE SULFATE": "VINBLASTINE SULFATE",
              "VINORELBINE TARTRATE": "VINORELBINE TARTRATE"
            },
            "H2 PHARMA": {
              "ETOPOPHOS": "ETOPOPHOS"
            },
            "HIKMA": {
              "ETOPOSIDE": "ETOPOSIDE"
            },
            "HOSPIRA": {
              "DOCETAXEL": "DOCETAXEL",
              "PACLITAXEL": "PACLITAXEL",
              "VINORELBINE TARTRATE": "VINORELBINE TARTRATE"
            },
            "INGENUS PHARMACEUTICALS": {
              "DOCETAXEL": "DOCETAXEL"
            },
            "MYLAN": {
              "ETOPOSIDE": "ETOPOSIDE"
            },
            "MYLAN INSTITUTIONAL": {
              "DOCETAXEL": "DOCETAXEL",
              "PACLITAXEL": "PACLITAXEL",
              "VINORELBINE TARTRATE": "VINORELBINE TARTRATE"
            },
            "NORTHSTAR RX": {
              "DOCETAXEL": "DOCETAXEL"
            },
            "NOVADOZ PHARMACEUTICALS": {
              "PACLITAXEL": "PACLITAXEL"
            },
            "NOVAPLUS/ACCORD HEALTHCARE": {
              "ETOPOSIDE": "ETOPOSIDE"
            },
            "NOVAPLUS/DR.REDDY'S": {
              "DOCETAXEL": "DOCETAXEL"
            },
            "NOVAPLUS/HIKMA": {
              "ETOPOSIDE": "ETOPOSIDE"
            },
            "NOVAPLUS/HOSPIRA": {
              "DOCETAXEL": "DOCETAXEL",
              "VINCRISTINE SULFATE": "VINCRISTINE SULFATE"
            },
            "NOVAPLUS/MYLAN INSTITUTIONAL": {
              "VINORELBINE TARTRATE": "VINORELBINE TARTRATE"
            },
            "NOVAPLUS/SANDOZ": {
              "DOCETAXEL": "DOCETAXEL"
            },
            "NOVAPLUS/TEVA PARENTAL MEDICIN": {
              "TOPOSAR": "TOPOSAR"
            },
            "PFIZER U.S.": {
              "DOCETAXEL": "DOCETAXEL",
              "PACLITAXEL": "PACLITAXEL",
              "VINCRISTINE SULFATE": "VINCRISTINE SULFATE",
              "VINORELBINE TARTRATE": "VINORELBINE TARTRATE"
            },
            "PIERRE FABRE PHARMACEUTICALS": {
              "NAVELBINE": "NAVELBINE"
            },
            "R-PHARM": {
              "IXEMPRA KIT": "IXEMPRA KIT"
            },
            "SAGENT PHARMACEUTICAL": {
              "DOCETAXEL": "DOCETAXEL",
              "VINORELBINE TARTRATE": "VINORELBINE TARTRATE"
            },
            "SANDOZ": {
              "DOCETAXEL": "DOCETAXEL",
              "PACLITAXEL": "PACLITAXEL",
              "VINORELBINE TARTRATE": "VINORELBINE TARTRATE"
            },
            "SANOFI PHARMACEUTICALS": {
              "JEVTANA": "JEVTANA"
            },
            "SANOFI-AVENTIS U.S.": {
              "TAXOTERE": "TAXOTERE"
            },
            "SKY PACKAGING": {
              "DOCETAXEL": "DOCETAXEL"
            },
            "SUN PHARMACEUTICAL": {
              "DOCEFREZ": "DOCEFREZ"
            },
            "TEVA PARENTERAL MEDICINES": {
              "DOCETAXEL": "DOCETAXEL",
              "PACLITAXEL": "PACLITAXEL",
              "TOPOSAR": "TOPOSAR",
              "VINCASAR PFS": "VINCASAR PFS",
              "VINORELBINE TARTRATE": "VINORELBINE TARTRATE"
            },
            "WG CRITICAL CARE": {
              "PACLITAXEL": "PACLITAXEL",
              "TENIPOSIDE": "TENIPOSIDE"
            },
            "WINTHROP, US": {
              "DOCETAXEL": "DOCETAXEL"
            },
            "X-GEN PHARMACEUTICALS, INC": {
              "DOCETAXEL": "DOCETAXEL"
            }
          }
        },
        "Oncolytic Viral Agents": {
          "Oncolytic Viral Agents - HSV1": {
            "AMGEN": {
              "IMLYGIC": "IMLYGIC"
            }
          }
        },
        "Topoisomerase I Inhibitors": {
          "Topoisomerase I Inhibitors": {
            "ACCORD HEALTHCARE": {
              "TOPOTECAN HCL": "TOPOTECAN HCL"
            },
            "ACTAVIS": {
              "IRINOTECAN HCL": "IRINOTECAN HCL",
              "TOPOTECAN HCL": "TOPOTECAN HCL"
            },
            "AMERINET/HOSPIRA": {
              "IRINOTECAN HCL": "IRINOTECAN HCL"
            },
            "APOTEX": {
              "IRINOTECAN HCL": "IRINOTECAN HCL"
            },
            "AREVA PHARMACEUTICALS": {
              "IRINOTECAN HCL": "IRINOTECAN HCL"
            },
            "ARMAS PHARMACEUTICALS": {
              "IRINOTECAN HCL": "IRINOTECAN HCL"
            },
            "AVET PHARMACEUTICALS": {
              "IRINOTECAN HCL": "IRINOTECAN HCL"
            },
            "BLUE POINT LABORATORIES": {
              "IRINOTECAN HCL": "IRINOTECAN HCL"
            },
            "FRESENIUS KABI USA": {
              "IRINOTECAN HCL": "IRINOTECAN HCL",
              "TOPOTECAN HCL": "TOPOTECAN HCL"
            },
            "HIKMA": {
              "IRINOTECAN HCL": "IRINOTECAN HCL",
              "TOPOTECAN HCL": "TOPOTECAN HCL"
            },
            "HOSPIRA": {
              "IRINOTECAN HCL": "IRINOTECAN HCL",
              "TOPOTECAN HCL": "TOPOTECAN HCL"
            },
            "INGENUS PHARMACEUTICALS": {
              "IRINOTECAN HCL": "IRINOTECAN HCL",
              "TOPOTECAN HCL": "TOPOTECAN HCL"
            },
            "IPSEN BIOPHARMACEUTICALS": {
              "ONIVYDE": "ONIVYDE"
            },
            "JIANGSU HENGRUI MEDICINE": {
              "IRINOTECAN HCL": "IRINOTECAN HCL"
            },
            "MYLAN INSTITUTIONAL": {
              "TOPOTECAN HCL": "TOPOTECAN HCL"
            },
            "NORTHSTAR RX": {
              "IRINOTECAN HCL": "IRINOTECAN HCL"
            },
            "NOVAPLUS/HIKMA": {
              "IRINOTECAN HCL": "IRINOTECAN HCL"
            },
            "NOVAPLUS/HOSPIRA": {
              "TOPOTECAN HCL": "TOPOTECAN HCL"
            },
            "NOVAPLUS/PFIZER U.S.": {
              "CAMPTOSAR": "CAMPTOSAR"
            },
            "NOVAPLUS/SAGENT PHARMACEUTICAL": {
              "TOPOTECAN HCL": "TOPOTECAN HCL"
            },
            "NOVARTIS": {
              "HYCAMTIN": "HYCAMTIN"
            },
            "PFIZER U.S.": {
              "CAMPTOSAR": "CAMPTOSAR",
              "IRINOTECAN HCL": "IRINOTECAN HCL"
            },
            "SAGENT PHARMACEUTICAL": {
              "IRINOTECAN HCL": "IRINOTECAN HCL",
              "TOPOTECAN HCL": "TOPOTECAN HCL"
            },
            "SANDOZ": {
              "IRINOTECAN HCL": "IRINOTECAN HCL"
            },
            "TEVA PARENTERAL MEDICINES": {
              "IRINOTECAN HCL": "IRINOTECAN HCL",
              "TOPOTECAN HCL": "TOPOTECAN HCL"
            },
            "XIROMED": {
              "IRINOTECAN HCL": "IRINOTECAN HCL"
            }
          }
        }
      },
      "ANTIPARKINSON AND RELATED THERAPY AGENTS": {
        "Antiparkinson Adjunctive Therapy": {
          "Adenosine Receptor Antagonist": {
            "KYOWA KIRIN": {
              "NOURIANZ": "NOURIANZ"
            }
          },
          "Decarboxylase Inhibitors": {
            "ALVOGEN": {
              "CARBIDOPA": "CARBIDOPA"
            },
            "AMERIGEN PHARMACEUTICALS": {
              "CARBIDOPA": "CARBIDOPA"
            },
            "AUROBINDO PHARMA": {
              "CARBIDOPA": "CARBIDOPA"
            },
            "AVKARE": {
              "CARBIDOPA": "CARBIDOPA"
            },
            "BAUSCH HEALTH": {
              "LODOSYN": "LODOSYN"
            },
            "EDENBRIDGE PHARMACEUTICALS": {
              "CARBIDOPA": "CARBIDOPA"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "CARBIDOPA": "CARBIDOPA"
            },
            "LUPIN PHARMACEUTICALS": {
              "CARBIDOPA": "CARBIDOPA"
            },
            "NORTHSTAR RX": {
              "CARBIDOPA": "CARBIDOPA"
            },
            "OCEANSIDE PHARMACEUTICALS": {
              "CARBIDOPA": "CARBIDOPA"
            }
          }
        },
        "Antiparkinson Anticholinergics": {
          "Antiparkinson Anticholinergics": {
            "A-S MEDICATION SOLUTIONS": {
              "BENZTROPINE MESYLATE": "BENZTROPINE MESYLATE",
              "TRIHEXYPHENIDYL HCL": "TRIHEXYPHENIDYL HCL"
            },
            "ACTAVIS PHARMA": {
              "TRIHEXYPHENIDYL HCL": "TRIHEXYPHENIDYL HCL"
            },
            "AIDAREX PHARMACEUTICALS": {
              "BENZTROPINE MESYLATE": "BENZTROPINE MESYLATE"
            },
            "AKORN": {
              "BENZTROPINE MESYLATE": "BENZTROPINE MESYLATE",
              "COGENTIN": "COGENTIN",
              "TRIHEXYPHENIDYL HCL": "TRIHEXYPHENIDYL HCL"
            },
            "ALTURA PHARMACEUTICALS": {
              "BENZTROPINE MESYLATE": "BENZTROPINE MESYLATE"
            },
            "AMERICAN HEALTH PACKAGING": {
              "BENZTROPINE MESYLATE": "BENZTROPINE MESYLATE",
              "TRIHEXYPHENIDYL HCL": "TRIHEXYPHENIDYL HCL"
            },
            "ANI  PHARMACEUTICALS": {
              "BENZTROPINE MESYLATE": "BENZTROPINE MESYLATE"
            },
            "APHENA PHARMA SOLUTIONS": {
              "BENZTROPINE MESYLATE": "BENZTROPINE MESYLATE"
            },
            "AVPAK": {
              "BENZTROPINE MESYLATE": "BENZTROPINE MESYLATE"
            },
            "BAYSHORE PHARMACEUTICALS": {
              "BENZTROPINE MESYLATE": "BENZTROPINE MESYLATE"
            },
            "BIONPHARMA": {
              "TRIHEXYPHENIDYL HCL": "TRIHEXYPHENIDYL HCL"
            },
            "BRYANT RANCH PREPACK": {
              "BENZTROPINE MESYLATE": "BENZTROPINE MESYLATE"
            },
            "CIPLA USA": {
              "BENZTROPINE MESYLATE": "BENZTROPINE MESYLATE"
            },
            "DIRECT RX": {
              "BENZTROPINE MESYLATE": "BENZTROPINE MESYLATE"
            },
            "FRESENIUS KABI USA": {
              "BENZTROPINE MESYLATE": "BENZTROPINE MESYLATE"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "TRIHEXYPHENIDYL HCL": "TRIHEXYPHENIDYL HCL"
            },
            "HIKMA": {
              "BENZTROPINE MESYLATE": "BENZTROPINE MESYLATE",
              "TRIHEXYPHENIDYL HCL": "TRIHEXYPHENIDYL HCL"
            },
            "IMPAX GENERICS": {
              "BENZTROPINE MESYLATE": "BENZTROPINE MESYLATE"
            },
            "LEADING PHARMA": {
              "BENZTROPINE MESYLATE": "BENZTROPINE MESYLATE"
            },
            "LIBERTY PHARMACEUTICAL": {
              "BENZTROPINE MESYLATE": "BENZTROPINE MESYLATE"
            },
            "MAJOR PHARMACEUTICALS": {
              "BENZTROPINE MESYLATE": "BENZTROPINE MESYLATE"
            },
            "MARLEX PHARMACEUTICALS": {
              "BENZTROPINE MESYLATE": "BENZTROPINE MESYLATE"
            },
            "MYLAN INSTITUTIONAL": {
              "BENZTROPINE MESYLATE": "BENZTROPINE MESYLATE"
            },
            "NOVITIUM PHARMA": {
              "TRIHEXYPHENIDYL HCL": "TRIHEXYPHENIDYL HCL"
            },
            "PACK PHARMACEUTICALS, LLC": {
              "TRIHEXYPHENIDYL HCL": "TRIHEXYPHENIDYL HCL"
            },
            "PAR PHARMACEUTICALS": {
              "BENZTROPINE MESYLATE": "BENZTROPINE MESYLATE",
              "TRIHEXYPHENIDYL HCL": "TRIHEXYPHENIDYL HCL"
            },
            "PDRX PHARMACEUTICAL": {
              "BENZTROPINE MESYLATE": "BENZTROPINE MESYLATE"
            },
            "PHARMACEUTICAL ASSOCIATES": {
              "TRIHEXYPHENIDYL HCL": "TRIHEXYPHENIDYL HCL"
            },
            "PROFICIENT RX": {
              "BENZTROPINE MESYLATE": "BENZTROPINE MESYLATE"
            },
            "RICHMOND PHARMACEUTICALS": {
              "TRIHEXYPHENIDYL HCL": "TRIHEXYPHENIDYL HCL"
            },
            "SUNRISE PHARMACEUTICAL": {
              "BENZTROPINE MESYLATE": "BENZTROPINE MESYLATE"
            },
            "UPSHER-SMITH": {
              "BENZTROPINE MESYLATE": "BENZTROPINE MESYLATE"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "BENZTROPINE MESYLATE": "BENZTROPINE MESYLATE"
            }
          }
        },
        "Antiparkinson COMT Inhibitors": {
          "Central/Peripheral COMT Inhibitors": {
            "BAUSCH HEALTH": {
              "TASMAR": "TASMAR"
            },
            "INGENUS PHARMACEUTICALS": {
              "TOLCAPONE": "TOLCAPONE"
            },
            "OCEANSIDE PHARMACEUTICALS": {
              "TOLCAPONE": "TOLCAPONE"
            },
            "PAR PHARMACEUTICAL": {
              "TOLCAPONE": "TOLCAPONE"
            }
          },
          "Peripheral COMT Inhibitors": {
            "AJANTA PHARMA LIMITED": {
              "ENTACAPONE": "ENTACAPONE"
            },
            "ALMATICA": {
              "COMTAN": "COMTAN"
            },
            "AMERICAN HEALTH PACKAGING": {
              "ENTACAPONE": "ENTACAPONE"
            },
            "APHENA PHARMA SOLUTIONS": {
              "ENTACAPONE": "ENTACAPONE"
            },
            "AUROBINDO PHARMA": {
              "ENTACAPONE": "ENTACAPONE"
            },
            "AVKARE": {
              "ENTACAPONE": "ENTACAPONE"
            },
            "AVPAK": {
              "ENTACAPONE": "ENTACAPONE"
            },
            "LANNETT": {
              "ENTACAPONE": "ENTACAPONE"
            },
            "MACLEODS PHARMACEUTICALS": {
              "ENTACAPONE": "ENTACAPONE"
            },
            "MAJOR PHARMACEUTICALS": {
              "ENTACAPONE": "ENTACAPONE"
            },
            "MYLAN": {
              "ENTACAPONE": "ENTACAPONE"
            },
            "MYLAN INSTITUTIONAL": {
              "ENTACAPONE": "ENTACAPONE"
            },
            "NEUROCRINE BIOSCIENCES": {
              "ONGENTYS": "ONGENTYS"
            },
            "SANDOZ": {
              "ENTACAPONE": "ENTACAPONE"
            },
            "SUN PHARMACEUTICALS": {
              "ENTACAPONE": "ENTACAPONE"
            },
            "WOCKHARDT USA": {
              "ENTACAPONE": "ENTACAPONE"
            }
          }
        },
        "Antiparkinson Dopaminergics": {
          "Antiparkinson Dopaminergics": {
            "A-S MEDICATION SOLUTIONS": {
              "AMANTADINE HCL": "AMANTADINE HCL"
            },
            "ACORDA THERAPEUTICS": {
              "INBRIJA": "INBRIJA"
            },
            "ACTAVIS PHARMA": {
              "AMANTADINE HCL": "AMANTADINE HCL"
            },
            "ADAMAS PHARMA": {
              "GOCOVRI": "GOCOVRI"
            },
            "AIDAREX PHARMACEUTICALS": {
              "AMANTADINE HCL": "AMANTADINE HCL"
            },
            "ALEMBIC PHARMACEUTICALS": {
              "AMANTADINE HCL": "AMANTADINE HCL"
            },
            "AMERICAN HEALTH PACKAGING": {
              "AMANTADINE HCL": "AMANTADINE HCL",
              "BROMOCRIPTINE MESYLATE": "BROMOCRIPTINE MESYLATE"
            },
            "APHENA PHARMA SOLUTIONS": {
              "AMANTADINE HCL": "AMANTADINE HCL"
            },
            "AVET PHARMACEUTICALS": {
              "AMANTADINE HCL": "AMANTADINE HCL"
            },
            "AVKARE": {
              "AMANTADINE HCL": "AMANTADINE HCL"
            },
            "AVPAK": {
              "AMANTADINE HCL": "AMANTADINE HCL"
            },
            "BIONPHARMA": {
              "AMANTADINE HCL": "AMANTADINE HCL"
            },
            "BRYANT RANCH PREPACK": {
              "AMANTADINE HCL": "AMANTADINE HCL"
            },
            "CADISTA": {
              "AMANTADINE HCL": "AMANTADINE HCL"
            },
            "CIPLA USA": {
              "AMANTADINE HCL": "AMANTADINE HCL"
            },
            "CMP PHARMA": {
              "AMANTADINE HCL": "AMANTADINE HCL"
            },
            "FAGRON": {
              "LEVODOPA": "LEVODOPA"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "AMANTADINE HCL": "AMANTADINE HCL"
            },
            "HI-TECH": {
              "AMANTADINE HCL": "AMANTADINE HCL"
            },
            "LANNETT": {
              "AMANTADINE HCL": "AMANTADINE HCL"
            },
            "LETCO MEDICAL": {
              "LEVODOPA": "LEVODOPA"
            },
            "LIBERTY PHARMACEUTICAL": {
              "AMANTADINE HCL": "AMANTADINE HCL"
            },
            "MAJOR PHARMACEUTICALS": {
              "AMANTADINE HCL": "AMANTADINE HCL"
            },
            "MARLEX PHARMACEUTICALS": {
              "AMANTADINE HCL": "AMANTADINE HCL"
            },
            "MORTON GROVE PHARMACEUTICALS": {
              "AMANTADINE HCL": "AMANTADINE HCL"
            },
            "MYLAN": {
              "BROMOCRIPTINE MESYLATE": "BROMOCRIPTINE MESYLATE"
            },
            "MYLAN INSTITUTIONAL": {
              "AMANTADINE HCL": "AMANTADINE HCL"
            },
            "NUCARE PHARMACEUTICALS": {
              "AMANTADINE HCL": "AMANTADINE HCL"
            },
            "PCCA": {
              "BROMOCRIPTINE MESYLATE": "BROMOCRIPTINE MESYLATE",
              "LEVODOPA": "LEVODOPA"
            },
            "PDRX PHARMACEUTICAL": {
              "AMANTADINE HCL": "AMANTADINE HCL"
            },
            "PERRIGO": {
              "BROMOCRIPTINE MESYLATE": "BROMOCRIPTINE MESYLATE"
            },
            "PHARMACEUTICAL ASSOCIATES": {
              "AMANTADINE HCL": "AMANTADINE HCL"
            },
            "PREFERRED PHARMACEUTICALS": {
              "AMANTADINE HCL": "AMANTADINE HCL"
            },
            "PROFICIENT RX": {
              "AMANTADINE HCL": "AMANTADINE HCL"
            },
            "QUALITY CARE": {
              "AMANTADINE HCL": "AMANTADINE HCL"
            },
            "SANDOZ": {
              "AMANTADINE HCL": "AMANTADINE HCL",
              "BROMOCRIPTINE MESYLATE": "BROMOCRIPTINE MESYLATE"
            },
            "SPECTRUM": {
              "LEVODOPA": "LEVODOPA"
            },
            "SUN PHARMACEUTICALS": {
              "BROMOCRIPTINE MESYLATE": "BROMOCRIPTINE MESYLATE"
            },
            "UPSHER-SMITH": {
              "AMANTADINE HCL": "AMANTADINE HCL"
            },
            "VALIDUS PHARMACEUTICALS": {
              "PARLODEL": "PARLODEL"
            },
            "VENSUN PHARMACEUTICALS": {
              "AMANTADINE HCL": "AMANTADINE HCL"
            },
            "VERTICAL PHARMACEUTICAL": {
              "OSMOLEX ER": "OSMOLEX ER"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "AMANTADINE HCL": "AMANTADINE HCL",
              "BROMOCRIPTINE MESYLATE": "BROMOCRIPTINE MESYLATE"
            }
          },
          "Levodopa Combinations": {
            "A-S MEDICATION SOLUTIONS": {
              "CARBIDOPA-LEVODOPA": "CARBIDOPA-LEVODOPA"
            },
            "ABBVIE": {
              "DUOPA": "DUOPA"
            },
            "ACCORD HEALTHCARE": {
              "CARBIDOPA-LEVODOPA ER": "CARBIDOPA-LEVODOPA ER"
            },
            "ACTAVIS ELIZABETH": {
              "CARBIDOPA-LEVODOPA": "CARBIDOPA-LEVODOPA"
            },
            "AIDAREX PHARMACEUTICALS": {
              "CARBIDOPA-LEVODOPA": "CARBIDOPA-LEVODOPA"
            },
            "ALMATICA": {
              "STALEVO 100": "STALEVO 100",
              "STALEVO 125": "STALEVO 125",
              "STALEVO 150": "STALEVO 150",
              "STALEVO 200": "STALEVO 200",
              "STALEVO 50": "STALEVO 50",
              "STALEVO 75": "STALEVO 75"
            },
            "AMERICAN HEALTH PACKAGING": {
              "CARBIDOPA-LEVODOPA": "CARBIDOPA-LEVODOPA",
              "CARBIDOPA-LEVODOPA ER": "CARBIDOPA-LEVODOPA ER"
            },
            "AMNEAL SPECIALTY": {
              "RYTARY": "RYTARY"
            },
            "APHENA PHARMA SOLUTIONS": {
              "CARBIDOPA-LEVODOPA": "CARBIDOPA-LEVODOPA",
              "CARBIDOPA-LEVODOPA ER": "CARBIDOPA-LEVODOPA ER",
              "SINEMET CR": "SINEMET CR"
            },
            "BLUE POINT LABORATORIES": {
              "CARBIDOPA-LEVODOPA ER": "CARBIDOPA-LEVODOPA ER"
            },
            "BRYANT RANCH PREPACK": {
              "SINEMET": "SINEMET"
            },
            "CARDINAL HEALTH REPACKAGING SO": {
              "CARBIDOPA-LEVODOPA": "CARBIDOPA-LEVODOPA"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "CARBIDOPA-LEVODOPA": "CARBIDOPA-LEVODOPA"
            },
            "MAJOR PHARMACEUTICALS": {
              "CARBIDOPA-LEVODOPA": "CARBIDOPA-LEVODOPA"
            },
            "MAYNE PHARMA": {
              "CARBIDOPA-LEVODOPA": "CARBIDOPA-LEVODOPA"
            },
            "MERCK SHARP & DOHME": {
              "SINEMET": "SINEMET",
              "SINEMET CR": "SINEMET CR"
            },
            "MYLAN": {
              "CARBIDOPA-LEVODOPA": "CARBIDOPA-LEVODOPA",
              "CARBIDOPA-LEVODOPA ER": "CARBIDOPA-LEVODOPA ER",
              "CARBIDOPA-LEVODOPA-ENTACAPONE": "CARBIDOPA-LEVODOPA-ENTACAPONE"
            },
            "MYLAN INSTITUTIONAL": {
              "CARBIDOPA-LEVODOPA": "CARBIDOPA-LEVODOPA",
              "CARBIDOPA-LEVODOPA ER": "CARBIDOPA-LEVODOPA ER"
            },
            "QUALITY CARE": {
              "CARBIDOPA-LEVODOPA": "CARBIDOPA-LEVODOPA"
            },
            "QUALITY CARE PRODUCTS": {
              "CARBIDOPA-LEVODOPA": "CARBIDOPA-LEVODOPA"
            },
            "SANDOZ": {
              "CARBIDOPA-LEVODOPA-ENTACAPONE": "CARBIDOPA-LEVODOPA-ENTACAPONE"
            },
            "SKY PACKAGING": {
              "CARBIDOPA-LEVODOPA": "CARBIDOPA-LEVODOPA"
            },
            "SUN PHARMACEUTICALS": {
              "CARBIDOPA-LEVODOPA": "CARBIDOPA-LEVODOPA",
              "CARBIDOPA-LEVODOPA ER": "CARBIDOPA-LEVODOPA ER",
              "CARBIDOPA-LEVODOPA-ENTACAPONE": "CARBIDOPA-LEVODOPA-ENTACAPONE"
            },
            "TEVA PHARMACEUTICALS USA": {
              "CARBIDOPA-LEVODOPA": "CARBIDOPA-LEVODOPA"
            },
            "UNIT DOSE SERVICES": {
              "CARBIDOPA-LEVODOPA": "CARBIDOPA-LEVODOPA"
            },
            "VANGARD": {
              "CARBIDOPA-LEVODOPA": "CARBIDOPA-LEVODOPA"
            },
            "WOCKHARDT USA": {
              "CARBIDOPA-LEVODOPA-ENTACAPONE": "CARBIDOPA-LEVODOPA-ENTACAPONE"
            }
          },
          "Nonergoline Dopamine Receptor Agonists": {
            "A-S MEDICATION SOLUTIONS": {
              "PRAMIPEXOLE DIHYDROCHLORIDE": "PRAMIPEXOLE DIHYDROCHLORIDE",
              "ROPINIROLE HCL": "ROPINIROLE HCL"
            },
            "ACCORD HEALTHCARE": {
              "ROPINIROLE HCL": "ROPINIROLE HCL"
            },
            "ACTAVIS ELIZABETH": {
              "ROPINIROLE HCL ER": "ROPINIROLE HCL ER"
            },
            "AIDAREX PHARMACEUTICALS": {
              "ROPINIROLE HCL": "ROPINIROLE HCL"
            },
            "ALEMBIC PHARMACEUTICALS": {
              "ROPINIROLE HCL": "ROPINIROLE HCL"
            },
            "AMERICAN HEALTH PACKAGING": {
              "PRAMIPEXOLE DIHYDROCHLORIDE": "PRAMIPEXOLE DIHYDROCHLORIDE",
              "ROPINIROLE HCL": "ROPINIROLE HCL"
            },
            "APHENA PHARMA SOLUTIONS": {
              "ROPINIROLE HCL": "ROPINIROLE HCL"
            },
            "AUROBINDO PHARMA": {
              "PRAMIPEXOLE DIHYDROCHLORIDE": "PRAMIPEXOLE DIHYDROCHLORIDE"
            },
            "AVKARE": {
              "PRAMIPEXOLE DIHYDROCHLORIDE": "PRAMIPEXOLE DIHYDROCHLORIDE",
              "PRAMIPEXOLE DIHYDROCHLORIDE ER": "PRAMIPEXOLE DIHYDROCHLORIDE ER",
              "ROPINIROLE HCL ER": "ROPINIROLE HCL ER"
            },
            "BOEHRINGER INGELHEIM": {
              "MIRAPEX": "MIRAPEX",
              "MIRAPEX ER": "MIRAPEX ER"
            },
            "BRYANT RANCH PREPACK": {
              "PRAMIPEXOLE DIHYDROCHLORIDE": "PRAMIPEXOLE DIHYDROCHLORIDE",
              "REQUIP": "REQUIP",
              "ROPINIROLE HCL": "ROPINIROLE HCL"
            },
            "CAMBER PHARMACEUTICALS": {
              "PRAMIPEXOLE DIHYDROCHLORIDE": "PRAMIPEXOLE DIHYDROCHLORIDE"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "ROPINIROLE HCL": "ROPINIROLE HCL"
            },
            "DIRECT RX": {
              "ROPINIROLE HCL": "ROPINIROLE HCL"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "PRAMIPEXOLE DIHYDROCHLORIDE ER": "PRAMIPEXOLE DIHYDROCHLORIDE ER",
              "ROPINIROLE HCL ER": "ROPINIROLE HCL ER"
            },
            "GLAXO SMITH KLINE": {
              "REQUIP": "REQUIP",
              "REQUIP XL": "REQUIP XL"
            },
            "GLENMARK PHARMACEUTICALS": {
              "PRAMIPEXOLE DIHYDROCHLORIDE": "PRAMIPEXOLE DIHYDROCHLORIDE",
              "ROPINIROLE HCL": "ROPINIROLE HCL"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "PRAMIPEXOLE DIHYDROCHLORIDE": "PRAMIPEXOLE DIHYDROCHLORIDE",
              "ROPINIROLE HCL": "ROPINIROLE HCL"
            },
            "HERITAGE PHARMACEUTICALS": {
              "ROPINIROLE HCL": "ROPINIROLE HCL"
            },
            "HIKMA": {
              "ROPINIROLE HCL": "ROPINIROLE HCL"
            },
            "MACLEODS PHARMACEUTICALS": {
              "PRAMIPEXOLE DIHYDROCHLORIDE": "PRAMIPEXOLE DIHYDROCHLORIDE",
              "PRAMIPEXOLE DIHYDROCHLORIDE ER": "PRAMIPEXOLE DIHYDROCHLORIDE ER"
            },
            "MAJOR PHARMACEUTICALS": {
              "PRAMIPEXOLE DIHYDROCHLORIDE": "PRAMIPEXOLE DIHYDROCHLORIDE",
              "ROPINIROLE HCL": "ROPINIROLE HCL"
            },
            "MARLEX PHARMACEUTICALS": {
              "ROPINIROLE HCL": "ROPINIROLE HCL"
            },
            "MYLAN": {
              "PRAMIPEXOLE DIHYDROCHLORIDE": "PRAMIPEXOLE DIHYDROCHLORIDE",
              "ROPINIROLE HCL": "ROPINIROLE HCL",
              "ROPINIROLE HCL ER": "ROPINIROLE HCL ER"
            },
            "NORTHSTAR RX": {
              "PRAMIPEXOLE DIHYDROCHLORIDE ER": "PRAMIPEXOLE DIHYDROCHLORIDE ER"
            },
            "NUCARE PHARMACEUTICALS": {
              "ROPINIROLE HCL": "ROPINIROLE HCL"
            },
            "PAR PHARMACEUTICAL": {
              "PRAMIPEXOLE DIHYDROCHLORIDE ER": "PRAMIPEXOLE DIHYDROCHLORIDE ER"
            },
            "PDRX PHARMACEUTICAL": {
              "REQUIP": "REQUIP"
            },
            "QUALITY CARE": {
              "REQUIP": "REQUIP",
              "ROPINIROLE HCL": "ROPINIROLE HCL"
            },
            "QUALITY CARE PRODUCTS": {
              "ROPINIROLE HCL": "ROPINIROLE HCL"
            },
            "RISING PHARMACEUTICALS": {
              "PRAMIPEXOLE DIHYDROCHLORIDE": "PRAMIPEXOLE DIHYDROCHLORIDE"
            },
            "SANDOZ": {
              "PRAMIPEXOLE DIHYDROCHLORIDE": "PRAMIPEXOLE DIHYDROCHLORIDE",
              "ROPINIROLE HCL ER": "ROPINIROLE HCL ER"
            },
            "SOLCO HEALTHCARE": {
              "ROPINIROLE HCL": "ROPINIROLE HCL"
            },
            "SUNOVION PHARMACEUTICALS": {
              "KYNMOBI": "KYNMOBI",
              "KYNMOBI TITRATION KIT": "KYNMOBI TITRATION KIT"
            },
            "TORRENT PHARMACEUTICALS": {
              "PRAMIPEXOLE DIHYDROCHLORIDE": "PRAMIPEXOLE DIHYDROCHLORIDE"
            },
            "TRIGEN LABORATORIES": {
              "ROPINIROLE HCL ER": "ROPINIROLE HCL ER"
            },
            "UCB PHARMA": {
              "NEUPRO": "NEUPRO"
            },
            "US WORLDMEDS": {
              "APOKYN": "APOKYN"
            },
            "VENSUN PHARMACEUTICALS": {
              "PRAMIPEXOLE DIHYDROCHLORIDE": "PRAMIPEXOLE DIHYDROCHLORIDE"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "PRAMIPEXOLE DIHYDROCHLORIDE": "PRAMIPEXOLE DIHYDROCHLORIDE",
              "ROPINIROLE HCL": "ROPINIROLE HCL"
            }
          }
        },
        "Antiparkinson Monoamine Oxidase Inhibitors": {
          "Antiparkinson Monoamine Oxidase Inhibitors": {
            "A-S MEDICATION SOLUTIONS": {
              "SELEGILINE HCL": "SELEGILINE HCL"
            },
            "ALVOGEN": {
              "RASAGILINE MESYLATE": "RASAGILINE MESYLATE"
            },
            "APHENA PHARMA SOLUTIONS": {
              "AZILECT": "AZILECT"
            },
            "APOTEX": {
              "SELEGILINE HCL": "SELEGILINE HCL"
            },
            "ASCEND LABORATORIES": {
              "RASAGILINE MESYLATE": "RASAGILINE MESYLATE"
            },
            "AVET PHARMACEUTICALS": {
              "RASAGILINE MESYLATE": "RASAGILINE MESYLATE"
            },
            "BAUSCH HEALTH": {
              "ZELAPAR": "ZELAPAR"
            },
            "FAGRON": {
              "SELEGILINE HCL": "SELEGILINE HCL"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "SELEGILINE HCL": "SELEGILINE HCL"
            },
            "LETCO MEDICAL": {
              "SELEGILINE HCL": "SELEGILINE HCL"
            },
            "MAYNE PHARMA": {
              "SELEGILINE HCL": "SELEGILINE HCL"
            },
            "MEDISCA": {
              "SELEGILINE HCL": "SELEGILINE HCL"
            },
            "MICRO LABORATORIES": {
              "RASAGILINE MESYLATE": "RASAGILINE MESYLATE"
            },
            "MYLAN": {
              "RASAGILINE MESYLATE": "RASAGILINE MESYLATE",
              "SELEGILINE HCL": "SELEGILINE HCL"
            },
            "MYLAN SPECIALTY L.P.": {
              "ELDEPRYL": "ELDEPRYL"
            },
            "NORTHSTAR RX": {
              "RASAGILINE MESYLATE": "RASAGILINE MESYLATE"
            },
            "PAR PHARMACEUTICAL": {
              "SELEGILINE HCL": "SELEGILINE HCL"
            },
            "PCCA": {
              "SELEGILINE HCL": "SELEGILINE HCL"
            },
            "RISING PHARMACEUTICALS": {
              "SELEGILINE HCL": "SELEGILINE HCL"
            },
            "SPECTRUM": {
              "SELEGILINE HCL": "SELEGILINE HCL"
            },
            "TEVA NEUROSCIENCE": {
              "AZILECT": "AZILECT"
            },
            "TEVA PHARMACEUTICALS USA": {
              "RASAGILINE MESYLATE": "RASAGILINE MESYLATE"
            },
            "US WORLDMEDS": {
              "XADAGO": "XADAGO"
            }
          }
        }
      },
      "ANTIPSYCHOTICS/ANTIMANIC AGENTS": {
        "Antimanic Agents": {
          "Antimanic Agents": {
            "A-S MEDICATION SOLUTIONS": {
              "LITHIUM CARBONATE": "LITHIUM CARBONATE",
              "LITHIUM CARBONATE ER": "LITHIUM CARBONATE ER"
            },
            "AIDAREX PHARMACEUTICALS": {
              "LITHIUM CARBONATE": "LITHIUM CARBONATE"
            },
            "ALEMBIC PHARMACEUTICALS": {
              "LITHIUM CARBONATE": "LITHIUM CARBONATE"
            },
            "AMERICAN HEALTH PACKAGING": {
              "LITHIUM CARBONATE ER": "LITHIUM CARBONATE ER"
            },
            "ANI  PHARMACEUTICALS": {
              "LITHIUM CARBONATE ER": "LITHIUM CARBONATE ER",
              "LITHOBID": "LITHOBID"
            },
            "APHENA PHARMA SOLUTIONS": {
              "LITHIUM CARBONATE": "LITHIUM CARBONATE",
              "LITHIUM CARBONATE ER": "LITHIUM CARBONATE ER"
            },
            "AVET PHARMACEUTICALS": {
              "LITHIUM CARBONATE ER": "LITHIUM CARBONATE ER"
            },
            "BRYANT RANCH PREPACK": {
              "LITHIUM CARBONATE": "LITHIUM CARBONATE"
            },
            "CAMBER PHARMACEUTICALS": {
              "LITHIUM CARBONATE": "LITHIUM CARBONATE"
            },
            "GLENMARK PHARMACEUTICALS": {
              "LITHIUM CARBONATE": "LITHIUM CARBONATE",
              "LITHIUM CARBONATE ER": "LITHIUM CARBONATE ER"
            },
            "HIKMA": {
              "LITHIUM": "LITHIUM",
              "LITHIUM CARBONATE": "LITHIUM CARBONATE",
              "LITHIUM CARBONATE ER": "LITHIUM CARBONATE ER"
            },
            "LIBERTY PHARMACEUTICAL": {
              "LITHIUM CARBONATE": "LITHIUM CARBONATE"
            },
            "MCKESSON PACKAGING SERVICES": {
              "LITHIUM CARBONATE": "LITHIUM CARBONATE"
            },
            "MYLAN": {
              "LITHIUM CARBONATE ER": "LITHIUM CARBONATE ER"
            },
            "MYLAN INSTITUTIONAL": {
              "LITHIUM CARBONATE ER": "LITHIUM CARBONATE ER"
            },
            "PCCA": {
              "LITHIUM CARBONATE": "LITHIUM CARBONATE"
            },
            "PDRX PHARMACEUTICAL": {
              "LITHIUM CARBONATE": "LITHIUM CARBONATE",
              "LITHIUM CARBONATE ER": "LITHIUM CARBONATE ER"
            },
            "PRECISION DOSE, INC": {
              "LITHIUM": "LITHIUM"
            },
            "PREFERRED PHARMACEUTICALS": {
              "LITHIUM CARBONATE": "LITHIUM CARBONATE"
            },
            "PROFICIENT RX": {
              "LITHIUM CARBONATE": "LITHIUM CARBONATE"
            },
            "QUALITY CARE": {
              "LITHIUM CARBONATE": "LITHIUM CARBONATE",
              "LITHIUM CARBONATE ER": "LITHIUM CARBONATE ER"
            },
            "RISING PHARMACEUTICALS": {
              "LITHIUM CARBONATE ER": "LITHIUM CARBONATE ER"
            },
            "RXCHANGE CO": {
              "LITHIUM CARBONATE": "LITHIUM CARBONATE"
            },
            "SUN PHARMACEUTICALS": {
              "LITHIUM CARBONATE": "LITHIUM CARBONATE"
            }
          }
        },
        "Antipsychotics - Misc.": {
          "Antipsychotics - Misc.": {
            "A-S MEDICATION SOLUTIONS": {
              "ZIPRASIDONE HCL": "ZIPRASIDONE HCL"
            },
            "ACADIA PHARMACEUTICALS": {
              "NUPLAZID": "NUPLAZID"
            },
            "ALLERGAN": {
              "VRAYLAR": "VRAYLAR"
            },
            "ALTURA PHARMACEUTICALS": {
              "GEODON": "GEODON"
            },
            "AMERICAN HEALTH PACKAGING": {
              "ZIPRASIDONE HCL": "ZIPRASIDONE HCL"
            },
            "APOTEX": {
              "ZIPRASIDONE HCL": "ZIPRASIDONE HCL"
            },
            "AUROBINDO PHARMA": {
              "ZIPRASIDONE HCL": "ZIPRASIDONE HCL"
            },
            "BIOGEN PHARMACEUTICALS": {
              "GEODON": "GEODON"
            },
            "BLUE POINT LABORATORIES": {
              "ZIPRASIDONE HCL": "ZIPRASIDONE HCL"
            },
            "BRYANT RANCH PREPACK": {
              "ZIPRASIDONE HCL": "ZIPRASIDONE HCL"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "ZIPRASIDONE HCL": "ZIPRASIDONE HCL",
              "ZIPRASIDONE MESYLATE": "ZIPRASIDONE MESYLATE"
            },
            "FOSUN PHARMA USA": {
              "ZIPRASIDONE MESYLATE": "ZIPRASIDONE MESYLATE"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "ZIPRASIDONE HCL": "ZIPRASIDONE HCL"
            },
            "GREENSTONE": {
              "ZIPRASIDONE HCL": "ZIPRASIDONE HCL"
            },
            "INTRA-CELLULAR THERAPIES": {
              "CAPLYTA": "CAPLYTA"
            },
            "LUPIN PHARMACEUTICALS": {
              "ZIPRASIDONE HCL": "ZIPRASIDONE HCL"
            },
            "MACLEODS PHARMACEUTICALS": {
              "ZIPRASIDONE HCL": "ZIPRASIDONE HCL"
            },
            "MAJOR PHARMACEUTICALS": {
              "ZIPRASIDONE HCL": "ZIPRASIDONE HCL"
            },
            "MYLAN": {
              "ZIPRASIDONE HCL": "ZIPRASIDONE HCL"
            },
            "MYLAN INSTITUTIONAL": {
              "ZIPRASIDONE HCL": "ZIPRASIDONE HCL"
            },
            "NORTHSTAR RX": {
              "ZIPRASIDONE HCL": "ZIPRASIDONE HCL"
            },
            "NOVAPLUS/FOSUN PHARMA": {
              "ZIPRASIDONE MESYLATE": "ZIPRASIDONE MESYLATE"
            },
            "NUCARE PHARMACEUTICALS": {
              "GEODON": "GEODON"
            },
            "PDRX PHARMACEUTICAL": {
              "GEODON": "GEODON"
            },
            "PFIZER U.S.": {
              "GEODON": "GEODON"
            },
            "PREFERRED PHARMACEUTICALS": {
              "ZIPRASIDONE HCL": "ZIPRASIDONE HCL"
            },
            "QUALITY CARE": {
              "GEODON": "GEODON"
            },
            "SANDOZ": {
              "ZIPRASIDONE HCL": "ZIPRASIDONE HCL"
            },
            "SKY PACKAGING": {
              "ZIPRASIDONE HCL": "ZIPRASIDONE HCL"
            },
            "SUNOVION PHARMACEUTICALS": {
              "LATUDA": "LATUDA"
            },
            "UNIT DOSE SERVICES": {
              "GEODON": "GEODON",
              "ZIPRASIDONE HCL": "ZIPRASIDONE HCL"
            },
            "VALIDUS PHARMACEUTICALS": {
              "EQUETRO": "EQUETRO"
            }
          }
        },
        "Benzisoxazoles": {
          "Benzisoxazoles": {
            "A-S MEDICATION SOLUTIONS": {
              "RISPERDAL": "RISPERDAL",
              "RISPERIDONE": "RISPERIDONE"
            },
            "ACTAVIS PHARMA": {
              "PALIPERIDONE ER": "PALIPERIDONE ER"
            },
            "AIDAREX PHARMACEUTICALS": {
              "RISPERIDONE": "RISPERIDONE"
            },
            "AJANTA PHARMA LIMITED": {
              "RISPERIDONE": "RISPERIDONE"
            },
            "AMERICAN HEALTH PACKAGING": {
              "PALIPERIDONE ER": "PALIPERIDONE ER",
              "RISPERIDONE": "RISPERIDONE"
            },
            "AMERIGEN PHARMACEUTICALS": {
              "PALIPERIDONE ER": "PALIPERIDONE ER"
            },
            "AMNEAL PHARMACEUTICALS": {
              "PALIPERIDONE ER": "PALIPERIDONE ER",
              "RISPERIDONE": "RISPERIDONE"
            },
            "APHENA PHARMA SOLUTIONS": {
              "RISPERDAL": "RISPERDAL",
              "RISPERIDONE": "RISPERIDONE"
            },
            "APOTEX": {
              "RISPERIDONE": "RISPERIDONE"
            },
            "AQ PHARMACEUTICALS": {
              "RISPERDAL": "RISPERDAL"
            },
            "ARCHIS PHARMA": {
              "PALIPERIDONE ER": "PALIPERIDONE ER"
            },
            "ATLANTIC BIOLOGICALS": {
              "RISPERIDONE": "RISPERIDONE"
            },
            "AUROBINDO PHARMA": {
              "RISPERIDONE": "RISPERIDONE"
            },
            "AUROBINDO PHARMA USA": {
              "RISPERIDONE": "RISPERIDONE"
            },
            "AVET PHARMACEUTICALS": {
              "RISPERIDONE": "RISPERIDONE"
            },
            "AVPAK": {
              "RISPERIDONE": "RISPERIDONE"
            },
            "BRECKENRIDGE": {
              "RISPERIDONE": "RISPERIDONE"
            },
            "BRYANT RANCH PREPACK": {
              "RISPERIDONE": "RISPERIDONE"
            },
            "CADISTA": {
              "RISPERIDONE": "RISPERIDONE"
            },
            "DIRECT RX": {
              "RISPERIDONE": "RISPERIDONE"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "RISPERIDONE": "RISPERIDONE"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "RISPERDAL": "RISPERDAL",
              "RISPERIDONE": "RISPERIDONE"
            },
            "HIKMA": {
              "RISPERIDONE": "RISPERIDONE"
            },
            "INDIVIOR INC": {
              "PERSERIS": "PERSERIS"
            },
            "JANSSEN": {
              "INVEGA": "INVEGA",
              "INVEGA SUSTENNA": "INVEGA SUSTENNA",
              "INVEGA TRINZA": "INVEGA TRINZA",
              "RISPERDAL": "RISPERDAL",
              "RISPERDAL CONSTA": "RISPERDAL CONSTA",
              "RISPERDAL M-TAB": "RISPERDAL M-TAB"
            },
            "MAJOR PHARMACEUTICALS": {
              "PALIPERIDONE ER": "PALIPERIDONE ER",
              "RISPERIDONE": "RISPERIDONE"
            },
            "MURFREESBORO PHARM NURSING SUP": {
              "RISPERIDONE": "RISPERIDONE"
            },
            "MYLAN": {
              "RISPERIDONE": "RISPERIDONE"
            },
            "MYLAN INSTITUTIONAL": {
              "PALIPERIDONE ER": "PALIPERIDONE ER",
              "RISPERIDONE": "RISPERIDONE"
            },
            "NORTHSTAR RX": {
              "PALIPERIDONE ER": "PALIPERIDONE ER"
            },
            "PAR PHARMACEUTICAL": {
              "RISPERIDONE": "RISPERIDONE"
            },
            "PAR PHARMACEUTICALS": {
              "RISPERIDONE": "RISPERIDONE"
            },
            "PATRIOT PHARMACEUTICALS LLC": {
              "PALIPERIDONE ER": "PALIPERIDONE ER",
              "RISPERIDONE": "RISPERIDONE",
              "RISPERIDONE M-TAB": "RISPERIDONE M-TAB"
            },
            "PDRX PHARMACEUTICAL": {
              "RISPERDAL": "RISPERDAL",
              "RISPERIDONE": "RISPERIDONE"
            },
            "PREFERRED PHARMACEUTICALS": {
              "RISPERIDONE": "RISPERIDONE"
            },
            "QUALITY CARE": {
              "INVEGA": "INVEGA",
              "RISPERDAL": "RISPERDAL"
            },
            "QUALITY CARE PRODUCTS": {
              "RISPERIDONE": "RISPERIDONE"
            },
            "RXCHANGE CO": {
              "RISPERDAL": "RISPERDAL"
            },
            "SANDOZ": {
              "RISPERIDONE": "RISPERIDONE"
            },
            "SKY PACKAGING": {
              "RISPERIDONE": "RISPERIDONE"
            },
            "SOLCO HEALTHCARE": {
              "RISPERIDONE": "RISPERIDONE"
            },
            "SUN PHARMACEUTICALS": {
              "PALIPERIDONE ER": "PALIPERIDONE ER"
            },
            "TORRENT PHARMACEUTICALS": {
              "RISPERIDONE": "RISPERIDONE"
            },
            "VANDA PHARMACEUTICALS": {
              "FANAPT": "FANAPT",
              "FANAPT TITRATION PACK": "FANAPT TITRATION PACK"
            },
            "WOCKHARDT USA": {
              "RISPERIDONE": "RISPERIDONE"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "RISPERIDONE": "RISPERIDONE"
            }
          }
        },
        "Butyrophenones": {
          "Butyrophenones": {
            "A-S MEDICATION SOLUTIONS": {
              "HALOPERIDOL": "HALOPERIDOL",
              "HALOPERIDOL DECANOATE": "HALOPERIDOL DECANOATE"
            },
            "ALTURA PHARMACEUTICALS": {
              "HALOPERIDOL": "HALOPERIDOL"
            },
            "AMERICAN HEALTH PACKAGING": {
              "HALOPERIDOL": "HALOPERIDOL"
            },
            "AVPAK": {
              "HALOPERIDOL": "HALOPERIDOL"
            },
            "BRECKENRIDGE": {
              "HALOPERIDOL LACTATE": "HALOPERIDOL LACTATE"
            },
            "CARDINAL HEALTH REPACKAGING SO": {
              "HALOPERIDOL LACTATE": "HALOPERIDOL LACTATE"
            },
            "DIRECT RX": {
              "HALOPERIDOL": "HALOPERIDOL"
            },
            "FRESENIUS KABI USA": {
              "HALOPERIDOL DECANOATE": "HALOPERIDOL DECANOATE",
              "HALOPERIDOL LACTATE": "HALOPERIDOL LACTATE"
            },
            "HIKMA": {
              "HALOPERIDOL DECANOATE": "HALOPERIDOL DECANOATE",
              "HALOPERIDOL LACTATE": "HALOPERIDOL LACTATE"
            },
            "JANSSEN": {
              "HALDOL": "HALDOL",
              "HALDOL DECANOATE": "HALDOL DECANOATE"
            },
            "MAJOR PHARMACEUTICALS": {
              "HALOPERIDOL": "HALOPERIDOL"
            },
            "METHOD PHARMACEUTICALS": {
              "HALOPERIDOL": "HALOPERIDOL"
            },
            "MYLAN": {
              "HALOPERIDOL": "HALOPERIDOL"
            },
            "MYLAN INSTITUTIONAL": {
              "HALOPERIDOL": "HALOPERIDOL",
              "HALOPERIDOL DECANOATE": "HALOPERIDOL DECANOATE",
              "HALOPERIDOL LACTATE": "HALOPERIDOL LACTATE"
            },
            "NUCARE PHARMACEUTICALS": {
              "HALOPERIDOL": "HALOPERIDOL"
            },
            "PATRIOT PHARMACEUTICALS LLC": {
              "HALOPERIDOL DECANOATE": "HALOPERIDOL DECANOATE",
              "HALOPERIDOL LACTATE": "HALOPERIDOL LACTATE"
            },
            "PDRX PHARMACEUTICAL": {
              "HALOPERIDOL": "HALOPERIDOL"
            },
            "PHARMACEUTICAL ASSOCIATES": {
              "HALOPERIDOL LACTATE": "HALOPERIDOL LACTATE"
            },
            "PROFICIENT RX": {
              "HALOPERIDOL": "HALOPERIDOL"
            },
            "QUALITY CARE": {
              "HALOPERIDOL": "HALOPERIDOL"
            },
            "SAGENT PHARMACEUTICAL": {
              "HALOPERIDOL DECANOATE": "HALOPERIDOL DECANOATE",
              "HALOPERIDOL LACTATE": "HALOPERIDOL LACTATE"
            },
            "SANDOZ": {
              "HALOPERIDOL": "HALOPERIDOL"
            },
            "SILARX": {
              "HALOPERIDOL LACTATE": "HALOPERIDOL LACTATE"
            },
            "SOMERSET THERAPEUTICS": {
              "HALOPERIDOL DECANOATE": "HALOPERIDOL DECANOATE"
            },
            "SPECGX": {
              "HALOPERIDOL": "HALOPERIDOL"
            },
            "TEVA PARENTERAL MEDICINES": {
              "HALOPERIDOL DECANOATE": "HALOPERIDOL DECANOATE"
            },
            "TEVA PHARMACEUTICALS USA": {
              "HALOPERIDOL LACTATE": "HALOPERIDOL LACTATE"
            },
            "UPSHER-SMITH": {
              "HALOPERIDOL": "HALOPERIDOL"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "HALOPERIDOL": "HALOPERIDOL",
              "HALOPERIDOL DECANOATE": "HALOPERIDOL DECANOATE"
            }
          }
        },
        "Dibenzapines": {
          "Dibenzo-oxepino Pyrroles": {
            "ALLERGAN": {
              "SAPHRIS": "SAPHRIS"
            },
            "NOVEN THERAPEUTICS": {
              "SECUADO": "SECUADO"
            }
          },
          "Dibenzodiazepines": {
            "ACCORD HEALTHCARE": {
              "CLOZAPINE": "CLOZAPINE"
            },
            "AMERICAN HEALTH PACKAGING": {
              "CLOZAPINE": "CLOZAPINE"
            },
            "AUROBINDO PHARMA": {
              "CLOZAPINE": "CLOZAPINE"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "CLOZAPINE": "CLOZAPINE"
            },
            "HEARTWOOD PHARMA": {
              "CLOZAPINE": "CLOZAPINE"
            },
            "HLS THERAPEUTICS (USA)": {
              "CLOZARIL": "CLOZARIL"
            },
            "JAZZ PHARMACEUTICALS COMMERCIA": {
              "FAZACLO": "FAZACLO"
            },
            "LIBERTY PHARMACEUTICAL": {
              "CLOZAPINE": "CLOZAPINE"
            },
            "MAYNE PHARMA": {
              "CLOZAPINE": "CLOZAPINE"
            },
            "MYLAN": {
              "CLOZAPINE": "CLOZAPINE"
            },
            "MYLAN INSTITUTIONAL": {
              "CLOZAPINE": "CLOZAPINE"
            },
            "SANDOZ": {
              "CLOZAPINE": "CLOZAPINE"
            },
            "SUN PHARMACEUTICALS": {
              "CLOZAPINE": "CLOZAPINE"
            },
            "TEVA PHARMACEUTICALS USA": {
              "CLOZAPINE": "CLOZAPINE"
            },
            "TRUPHARMA": {
              "VERSACLOZ": "VERSACLOZ"
            }
          },
          "Dibenzothiazepines": {
            "A-S MEDICATION SOLUTIONS": {
              "QUETIAPINE FUMARATE": "QUETIAPINE FUMARATE",
              "QUETIAPINE FUMARATE ER": "QUETIAPINE FUMARATE ER"
            },
            "ACCORD HEALTHCARE": {
              "QUETIAPINE FUMARATE": "QUETIAPINE FUMARATE",
              "QUETIAPINE FUMARATE ER": "QUETIAPINE FUMARATE ER"
            },
            "ACI HEALTHCARE USA": {
              "QUETIAPINE FUMARATE ER": "QUETIAPINE FUMARATE ER"
            },
            "AIDAREX PHARMACEUTICALS": {
              "QUETIAPINE FUMARATE": "QUETIAPINE FUMARATE"
            },
            "AKRON PHARMA": {
              "QUETIAPINE FUMARATE ER": "QUETIAPINE FUMARATE ER"
            },
            "AMERICAN HEALTH PACKAGING": {
              "QUETIAPINE FUMARATE": "QUETIAPINE FUMARATE"
            },
            "APHENA PHARMA SOLUTIONS": {
              "QUETIAPINE FUMARATE": "QUETIAPINE FUMARATE",
              "SEROQUEL": "SEROQUEL"
            },
            "APOTEX": {
              "QUETIAPINE FUMARATE": "QUETIAPINE FUMARATE"
            },
            "AQ PHARMACEUTICALS": {
              "SEROQUEL": "SEROQUEL"
            },
            "ASCEND LABORATORIES": {
              "QUETIAPINE FUMARATE": "QUETIAPINE FUMARATE"
            },
            "ASTRAZENECA": {
              "SEROQUEL": "SEROQUEL",
              "SEROQUEL XR": "SEROQUEL XR"
            },
            "AUROBINDO PHARMA": {
              "QUETIAPINE FUMARATE": "QUETIAPINE FUMARATE",
              "QUETIAPINE FUMARATE ER": "QUETIAPINE FUMARATE ER"
            },
            "AVKARE": {
              "QUETIAPINE FUMARATE": "QUETIAPINE FUMARATE"
            },
            "AVPAK": {
              "QUETIAPINE FUMARATE": "QUETIAPINE FUMARATE"
            },
            "BIOGEN PHARMACEUTICALS": {
              "SEROQUEL": "SEROQUEL"
            },
            "BLUE POINT LABORATORIES": {
              "QUETIAPINE FUMARATE": "QUETIAPINE FUMARATE"
            },
            "BRYANT RANCH PREPACK": {
              "QUETIAPINE FUMARATE": "QUETIAPINE FUMARATE",
              "SEROQUEL": "SEROQUEL"
            },
            "CARDINAL HEALTH REPACKAGING SO": {
              "QUETIAPINE FUMARATE": "QUETIAPINE FUMARATE"
            },
            "DIRECT RX": {
              "QUETIAPINE FUMARATE": "QUETIAPINE FUMARATE"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "QUETIAPINE FUMARATE": "QUETIAPINE FUMARATE"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "QUETIAPINE FUMARATE": "QUETIAPINE FUMARATE",
              "QUETIAPINE FUMARATE ER": "QUETIAPINE FUMARATE ER"
            },
            "HIKMA": {
              "QUETIAPINE FUMARATE": "QUETIAPINE FUMARATE"
            },
            "INGENUS PHARMACEUTICALS": {
              "QUETIAPINE FUMARATE ER": "QUETIAPINE FUMARATE ER"
            },
            "LUPIN PHARMACEUTICALS": {
              "QUETIAPINE FUMARATE": "QUETIAPINE FUMARATE",
              "QUETIAPINE FUMARATE ER": "QUETIAPINE FUMARATE ER"
            },
            "MACLEODS PHARMACEUTICALS": {
              "QUETIAPINE FUMARATE": "QUETIAPINE FUMARATE",
              "QUETIAPINE FUMARATE ER": "QUETIAPINE FUMARATE ER"
            },
            "MAJOR PHARMACEUTICALS": {
              "QUETIAPINE FUMARATE": "QUETIAPINE FUMARATE",
              "QUETIAPINE FUMARATE ER": "QUETIAPINE FUMARATE ER"
            },
            "MARLEX PHARMACEUTICALS": {
              "QUETIAPINE FUMARATE ER": "QUETIAPINE FUMARATE ER"
            },
            "NORTHSTAR RX": {
              "QUETIAPINE FUMARATE": "QUETIAPINE FUMARATE"
            },
            "NUCARE PHARMACEUTICALS": {
              "SEROQUEL": "SEROQUEL"
            },
            "PAR PHARMACEUTICAL": {
              "QUETIAPINE FUMARATE ER": "QUETIAPINE FUMARATE ER"
            },
            "PDRX PHARMACEUTICAL": {
              "SEROQUEL": "SEROQUEL"
            },
            "PERRIGO PHARMACEUTICALS": {
              "QUETIAPINE FUMARATE ER": "QUETIAPINE FUMARATE ER"
            },
            "PREFERRED PHARMACEUTICALS": {
              "QUETIAPINE FUMARATE": "QUETIAPINE FUMARATE"
            },
            "PROFICIENT RX": {
              "QUETIAPINE FUMARATE": "QUETIAPINE FUMARATE"
            },
            "QUALITY CARE": {
              "QUETIAPINE FUMARATE": "QUETIAPINE FUMARATE",
              "SEROQUEL": "SEROQUEL"
            },
            "QUALITY CARE PRODUCTS": {
              "QUETIAPINE FUMARATE": "QUETIAPINE FUMARATE"
            },
            "RXCHANGE CO": {
              "SEROQUEL": "SEROQUEL"
            },
            "SANDOZ": {
              "QUETIAPINE FUMARATE": "QUETIAPINE FUMARATE"
            },
            "SCIEGEN PHARMACEUTICALS": {
              "QUETIAPINE FUMARATE ER": "QUETIAPINE FUMARATE ER"
            },
            "SKY PACKAGING": {
              "QUETIAPINE FUMARATE": "QUETIAPINE FUMARATE"
            },
            "SPECGX": {
              "QUETIAPINE FUMARATE ER": "QUETIAPINE FUMARATE ER"
            },
            "SUN PHARMACEUTICALS": {
              "QUETIAPINE FUMARATE": "QUETIAPINE FUMARATE"
            },
            "TEVA PHARMACEUTICALS USA": {
              "QUETIAPINE FUMARATE": "QUETIAPINE FUMARATE"
            },
            "TRUPHARMA": {
              "QUETIAPINE FUMARATE ER": "QUETIAPINE FUMARATE ER"
            },
            "UNICHEM PHARMACEUTICALS": {
              "QUETIAPINE FUMARATE": "QUETIAPINE FUMARATE"
            }
          },
          "Dibenzoxazepines": {
            "A-S MEDICATION SOLUTIONS": {
              "LOXAPINE SUCCINATE": "LOXAPINE SUCCINATE"
            },
            "ACTAVIS PHARMA": {
              "LOXAPINE SUCCINATE": "LOXAPINE SUCCINATE"
            },
            "AMERICAN HEALTH PACKAGING": {
              "LOXAPINE SUCCINATE": "LOXAPINE SUCCINATE"
            },
            "GALEN US": {
              "ADASUVE": "ADASUVE"
            },
            "LANNETT": {
              "LOXAPINE SUCCINATE": "LOXAPINE SUCCINATE"
            },
            "MARLEX PHARMACEUTICALS": {
              "LOXAPINE SUCCINATE": "LOXAPINE SUCCINATE"
            },
            "MYLAN": {
              "LOXAPINE SUCCINATE": "LOXAPINE SUCCINATE"
            },
            "MYLAN INSTITUTIONAL": {
              "LOXAPINE SUCCINATE": "LOXAPINE SUCCINATE"
            }
          },
          "Thienbenzodiazepines": {
            "A-S MEDICATION SOLUTIONS": {
              "OLANZAPINE": "OLANZAPINE"
            },
            "ACETRIS HEALTH": {
              "OLANZAPINE": "OLANZAPINE"
            },
            "AMERICAN HEALTH PACKAGING": {
              "OLANZAPINE": "OLANZAPINE"
            },
            "AMERICAN REGENT": {
              "OLANZAPINE": "OLANZAPINE"
            },
            "APHENA PHARMA SOLUTIONS": {
              "OLANZAPINE": "OLANZAPINE",
              "ZYPREXA": "ZYPREXA"
            },
            "APOTEX": {
              "OLANZAPINE": "OLANZAPINE"
            },
            "AQ PHARMACEUTICALS": {
              "ZYPREXA": "ZYPREXA"
            },
            "AUROBINDO PHARMA": {
              "OLANZAPINE": "OLANZAPINE"
            },
            "AVPAK": {
              "OLANZAPINE": "OLANZAPINE"
            },
            "BIOGEN PHARMACEUTICALS": {
              "ZYPREXA": "ZYPREXA",
              "ZYPREXA ZYDIS": "ZYPREXA ZYDIS"
            },
            "BRYANT RANCH PREPACK": {
              "OLANZAPINE": "OLANZAPINE",
              "ZYPREXA": "ZYPREXA"
            },
            "CADISTA": {
              "OLANZAPINE": "OLANZAPINE"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "OLANZAPINE": "OLANZAPINE"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "OLANZAPINE": "OLANZAPINE"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "ZYPREXA": "ZYPREXA"
            },
            "LILLY": {
              "ZYPREXA": "ZYPREXA",
              "ZYPREXA RELPREVV": "ZYPREXA RELPREVV",
              "ZYPREXA ZYDIS": "ZYPREXA ZYDIS"
            },
            "MACLEODS PHARMACEUTICALS": {
              "OLANZAPINE": "OLANZAPINE"
            },
            "MAJOR PHARMACEUTICALS": {
              "OLANZAPINE": "OLANZAPINE"
            },
            "MYLAN": {
              "OLANZAPINE": "OLANZAPINE"
            },
            "MYLAN INSTITUTIONAL": {
              "OLANZAPINE": "OLANZAPINE"
            },
            "PAR PHARMACEUTICAL": {
              "OLANZAPINE": "OLANZAPINE"
            },
            "PDRX PHARMACEUTICAL": {
              "ZYPREXA": "ZYPREXA"
            },
            "PRASCO LABORATORIES": {
              "OLANZAPINE": "OLANZAPINE"
            },
            "PREFERRED PHARMACEUTICALS": {
              "OLANZAPINE": "OLANZAPINE"
            },
            "PROFICIENT RX": {
              "OLANZAPINE": "OLANZAPINE"
            },
            "QUALITY CARE": {
              "OLANZAPINE": "OLANZAPINE",
              "ZYPREXA": "ZYPREXA",
              "ZYPREXA ZYDIS": "ZYPREXA ZYDIS"
            },
            "QUALITY CARE PRODUCTS": {
              "OLANZAPINE": "OLANZAPINE"
            },
            "RXCHANGE CO": {
              "ZYPREXA": "ZYPREXA"
            },
            "SANDOZ": {
              "OLANZAPINE": "OLANZAPINE"
            },
            "SUN PHARMACEUTICALS": {
              "OLANZAPINE": "OLANZAPINE"
            },
            "TEVA PHARMACEUTICALS USA": {
              "OLANZAPINE": "OLANZAPINE"
            },
            "TORRENT PHARMACEUTICALS": {
              "OLANZAPINE": "OLANZAPINE"
            },
            "VIRTUS PHARMACEUTICALS": {
              "OLANZAPINE": "OLANZAPINE"
            }
          }
        },
        "Dihydroindolones": {
          "Dihydroindolones": {
            "EPIC PHARMA": {
              "MOLINDONE HCL": "MOLINDONE HCL"
            },
            "IMPAX GENERICS": {
              "MOLINDONE HCL": "MOLINDONE HCL"
            }
          }
        },
        "Phenothiazines": {
          "Phenothiazines": {
            "A-S MEDICATION SOLUTIONS": {
              "CHLORPROMAZINE HCL": "CHLORPROMAZINE HCL",
              "COMPRO": "COMPRO",
              "FLUPHENAZINE HCL": "FLUPHENAZINE HCL",
              "PERPHENAZINE": "PERPHENAZINE",
              "PROCHLORPERAZINE": "PROCHLORPERAZINE",
              "PROCHLORPERAZINE MALEATE": "PROCHLORPERAZINE MALEATE"
            },
            "ACTAVIS PHARMA": {
              "PERPHENAZINE": "PERPHENAZINE"
            },
            "AIDAREX PHARMACEUTICALS": {
              "PROCHLORPERAZINE MALEATE": "PROCHLORPERAZINE MALEATE",
              "THIORIDAZINE HCL": "THIORIDAZINE HCL"
            },
            "ALTURA PHARMACEUTICALS": {
              "CHLORPROMAZINE HCL": "CHLORPROMAZINE HCL",
              "PROCHLORPERAZINE MALEATE": "PROCHLORPERAZINE MALEATE"
            },
            "AMERICAN HEALTH PACKAGING": {
              "CHLORPROMAZINE HCL": "CHLORPROMAZINE HCL",
              "FLUPHENAZINE HCL": "FLUPHENAZINE HCL",
              "PERPHENAZINE": "PERPHENAZINE"
            },
            "AMNEAL PHARMACEUTICALS": {
              "CHLORPROMAZINE HCL": "CHLORPROMAZINE HCL",
              "FLUPHENAZINE HCL": "FLUPHENAZINE HCL"
            },
            "ATHENEX PHARMACEUTICAL": {
              "PROCHLORPERAZINE EDISYLATE": "PROCHLORPERAZINE EDISYLATE"
            },
            "AUROMEDICS PHARMA": {
              "CHLORPROMAZINE HCL": "CHLORPROMAZINE HCL",
              "FLUPHENAZINE DECANOATE": "FLUPHENAZINE DECANOATE"
            },
            "AVET PHARMACEUTICALS": {
              "PROCHLORPERAZINE EDISYLATE": "PROCHLORPERAZINE EDISYLATE"
            },
            "AVKARE": {
              "FLUPHENAZINE HCL": "FLUPHENAZINE HCL",
              "PROCHLORPERAZINE MALEATE": "PROCHLORPERAZINE MALEATE"
            },
            "AVPAK": {
              "CHLORPROMAZINE HCL": "CHLORPROMAZINE HCL",
              "PROCHLORPERAZINE MALEATE": "PROCHLORPERAZINE MALEATE"
            },
            "BLENHEIM PHARMACAL": {
              "PROCHLORPERAZINE MALEATE": "PROCHLORPERAZINE MALEATE"
            },
            "BRYANT RANCH PREPACK": {
              "PROCHLORPERAZINE MALEATE": "PROCHLORPERAZINE MALEATE"
            },
            "CADISTA": {
              "PROCHLORPERAZINE MALEATE": "PROCHLORPERAZINE MALEATE"
            },
            "CIVICA": {
              "PROCHLORPERAZINE EDISYLATE": "PROCHLORPERAZINE EDISYLATE"
            },
            "COSETTE PHARMACEUTICALS": {
              "PROCHLORPERAZINE": "PROCHLORPERAZINE"
            },
            "DIRECT RX": {
              "PROCHLORPERAZINE MALEATE": "PROCHLORPERAZINE MALEATE"
            },
            "FRESENIUS KABI USA": {
              "FLUPHENAZINE DECANOATE": "FLUPHENAZINE DECANOATE",
              "FLUPHENAZINE HCL": "FLUPHENAZINE HCL"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "PERPHENAZINE": "PERPHENAZINE",
              "PROCHLORPERAZINE": "PROCHLORPERAZINE",
              "PROCHLORPERAZINE MALEATE": "PROCHLORPERAZINE MALEATE"
            },
            "HIKMA": {
              "CHLORPROMAZINE HCL": "CHLORPROMAZINE HCL",
              "FLUPHENAZINE DECANOATE": "FLUPHENAZINE DECANOATE",
              "PROCHLORPERAZINE EDISYLATE": "PROCHLORPERAZINE EDISYLATE"
            },
            "LANNETT": {
              "FLUPHENAZINE HCL": "FLUPHENAZINE HCL"
            },
            "LETCO MEDICAL": {
              "PROCHLORPERAZINE MALEATE": "PROCHLORPERAZINE MALEATE"
            },
            "LIBERTY PHARMACEUTICAL": {
              "CHLORPROMAZINE HCL": "CHLORPROMAZINE HCL",
              "PERPHENAZINE": "PERPHENAZINE",
              "PROCHLORPERAZINE MALEATE": "PROCHLORPERAZINE MALEATE"
            },
            "MAJOR PHARMACEUTICALS": {
              "CHLORPROMAZINE HCL": "CHLORPROMAZINE HCL",
              "PERPHENAZINE": "PERPHENAZINE"
            },
            "MARLEX PHARMACEUTICALS": {
              "PROCHLORPERAZINE MALEATE": "PROCHLORPERAZINE MALEATE"
            },
            "MEDISCA": {
              "PROCHLORPERAZINE MALEATE": "PROCHLORPERAZINE MALEATE"
            },
            "MEDVANTX": {
              "PROCHLORPERAZINE MALEATE": "PROCHLORPERAZINE MALEATE"
            },
            "MYLAN": {
              "FLUPHENAZINE HCL": "FLUPHENAZINE HCL",
              "PERPHENAZINE": "PERPHENAZINE",
              "PROCHLORPERAZINE MALEATE": "PROCHLORPERAZINE MALEATE",
              "THIORIDAZINE HCL": "THIORIDAZINE HCL",
              "TRIFLUOPERAZINE HCL": "TRIFLUOPERAZINE HCL"
            },
            "MYLAN INSTITUTIONAL": {
              "CHLORPROMAZINE HCL": "CHLORPROMAZINE HCL",
              "FLUPHENAZINE DECANOATE": "FLUPHENAZINE DECANOATE",
              "FLUPHENAZINE HCL": "FLUPHENAZINE HCL",
              "PROCHLORPERAZINE EDISYLATE": "PROCHLORPERAZINE EDISYLATE",
              "PROCHLORPERAZINE MALEATE": "PROCHLORPERAZINE MALEATE",
              "THIORIDAZINE HCL": "THIORIDAZINE HCL",
              "TRIFLUOPERAZINE HCL": "TRIFLUOPERAZINE HCL"
            },
            "NEXUS PHARMA": {
              "PROCHLORPERAZINE EDISYLATE": "PROCHLORPERAZINE EDISYLATE"
            },
            "NORTHSTAR RX": {
              "CHLORPROMAZINE HCL": "CHLORPROMAZINE HCL"
            },
            "NOVAPLUS/HERITAGE PHARMA": {
              "PROCHLORPERAZINE EDISYLATE": "PROCHLORPERAZINE EDISYLATE"
            },
            "NUCARE PHARMACEUTICALS": {
              "PROCHLORPERAZINE": "PROCHLORPERAZINE",
              "PROCHLORPERAZINE MALEATE": "PROCHLORPERAZINE MALEATE",
              "TRIFLUOPERAZINE HCL": "TRIFLUOPERAZINE HCL"
            },
            "PAR PHARMACEUTICALS": {
              "PERPHENAZINE": "PERPHENAZINE"
            },
            "PAR STERILE PRODUCTS": {
              "FLUPHENAZINE DECANOATE": "FLUPHENAZINE DECANOATE"
            },
            "PCCA": {
              "PROCHLORPERAZINE MALEATE": "PROCHLORPERAZINE MALEATE"
            },
            "PDRX PHARMACEUTICAL": {
              "FLUPHENAZINE HCL": "FLUPHENAZINE HCL",
              "PROCHLORPERAZINE": "PROCHLORPERAZINE",
              "PROCHLORPERAZINE MALEATE": "PROCHLORPERAZINE MALEATE"
            },
            "PERRIGO": {
              "COMPRO": "COMPRO"
            },
            "PHARMACEUTICAL ASSOCIATES": {
              "FLUPHENAZINE HCL": "FLUPHENAZINE HCL"
            },
            "PHARMPAK": {
              "PROCHLORPERAZINE MALEATE": "PROCHLORPERAZINE MALEATE"
            },
            "PREFERRED PHARMACEUTICALS": {
              "PROCHLORPERAZINE MALEATE": "PROCHLORPERAZINE MALEATE"
            },
            "PROFICIENT RX": {
              "PROCHLORPERAZINE MALEATE": "PROCHLORPERAZINE MALEATE"
            },
            "QUALITY CARE": {
              "CHLORPROMAZINE HCL": "CHLORPROMAZINE HCL",
              "PERPHENAZINE": "PERPHENAZINE",
              "PROCHLORPERAZINE MALEATE": "PROCHLORPERAZINE MALEATE",
              "TRIFLUOPERAZINE HCL": "TRIFLUOPERAZINE HCL"
            },
            "RISING PHARMACEUTICALS": {
              "PERPHENAZINE": "PERPHENAZINE"
            },
            "RXCHANGE CO": {
              "PROCHLORPERAZINE": "PROCHLORPERAZINE",
              "PROCHLORPERAZINE MALEATE": "PROCHLORPERAZINE MALEATE"
            },
            "SANDOZ": {
              "CHLORPROMAZINE HCL": "CHLORPROMAZINE HCL",
              "FLUPHENAZINE HCL": "FLUPHENAZINE HCL",
              "PERPHENAZINE": "PERPHENAZINE",
              "PROCHLORPERAZINE MALEATE": "PROCHLORPERAZINE MALEATE",
              "TRIFLUOPERAZINE HCL": "TRIFLUOPERAZINE HCL"
            },
            "SPECTRUM": {
              "PROCHLORPERAZINE MALEATE": "PROCHLORPERAZINE MALEATE"
            },
            "SUN PHARMACEUTICALS": {
              "THIORIDAZINE HCL": "THIORIDAZINE HCL"
            },
            "TEVA PHARMACEUTICALS USA": {
              "PROCHLORPERAZINE MALEATE": "PROCHLORPERAZINE MALEATE"
            },
            "UPSHER-SMITH": {
              "CHLORPROMAZINE HCL": "CHLORPROMAZINE HCL",
              "TRIFLUOPERAZINE HCL": "TRIFLUOPERAZINE HCL"
            },
            "WILSHIRE PHARMACEUTICALS": {
              "PERPHENAZINE": "PERPHENAZINE"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "CHLORPROMAZINE HCL": "CHLORPROMAZINE HCL"
            }
          }
        },
        "Quinolinone Derivatives": {
          "Quinolinone Derivatives": {
            "A-S MEDICATION SOLUTIONS": {
              "ARIPIPRAZOLE": "ARIPIPRAZOLE"
            },
            "ACCORD HEALTHCARE": {
              "ARIPIPRAZOLE": "ARIPIPRAZOLE"
            },
            "AJANTA PHARMA LIMITED": {
              "ARIPIPRAZOLE": "ARIPIPRAZOLE"
            },
            "ALEMBIC PHARMACEUTICALS": {
              "ARIPIPRAZOLE": "ARIPIPRAZOLE"
            },
            "ALKERMES": {
              "ARISTADA": "ARISTADA",
              "ARISTADA INITIO": "ARISTADA INITIO"
            },
            "AMERICAN HEALTH PACKAGING": {
              "ARIPIPRAZOLE": "ARIPIPRAZOLE"
            },
            "AMNEAL PHARMACEUTICALS": {
              "ARIPIPRAZOLE": "ARIPIPRAZOLE"
            },
            "APHENA PHARMA SOLUTIONS": {
              "ABILIFY": "ABILIFY",
              "ARIPIPRAZOLE": "ARIPIPRAZOLE"
            },
            "APOTEX": {
              "ARIPIPRAZOLE": "ARIPIPRAZOLE"
            },
            "ASCEND LABORATORIES": {
              "ARIPIPRAZOLE": "ARIPIPRAZOLE"
            },
            "AUROBINDO PHARMA": {
              "ARIPIPRAZOLE": "ARIPIPRAZOLE"
            },
            "AVKARE": {
              "ARIPIPRAZOLE": "ARIPIPRAZOLE"
            },
            "AVPAK": {
              "ARIPIPRAZOLE": "ARIPIPRAZOLE"
            },
            "B-M SQUIBB U.S. (PRIMARY CARE)": {
              "ABILIFY": "ABILIFY"
            },
            "BIOGEN PHARMACEUTICALS": {
              "ABILIFY": "ABILIFY"
            },
            "BRYANT RANCH PREPACK": {
              "ABILIFY": "ABILIFY",
              "ARIPIPRAZOLE": "ARIPIPRAZOLE"
            },
            "CAMBER PHARMACEUTICALS": {
              "ARIPIPRAZOLE": "ARIPIPRAZOLE"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "ARIPIPRAZOLE": "ARIPIPRAZOLE"
            },
            "EXELAN PHARMACEUTICALS": {
              "ARIPIPRAZOLE": "ARIPIPRAZOLE"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "ARIPIPRAZOLE": "ARIPIPRAZOLE"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "ABILIFY": "ABILIFY"
            },
            "MACLEODS PHARMACEUTICALS": {
              "ARIPIPRAZOLE": "ARIPIPRAZOLE"
            },
            "MAJOR PHARMACEUTICALS": {
              "ARIPIPRAZOLE": "ARIPIPRAZOLE"
            },
            "NORTHSTAR RX": {
              "ARIPIPRAZOLE": "ARIPIPRAZOLE"
            },
            "NUCARE PHARMACEUTICALS": {
              "ABILIFY": "ABILIFY"
            },
            "ORCHIDPHARMA": {
              "ARIPIPRAZOLE": "ARIPIPRAZOLE"
            },
            "OTSUKA AMERICA": {
              "ABILIFY MAINTENA": "ABILIFY MAINTENA",
              "ABILIFY MYCITE": "ABILIFY MYCITE",
              "REXULTI": "REXULTI"
            },
            "PDRX PHARMACEUTICAL": {
              "ABILIFY": "ABILIFY"
            },
            "PREFERRED PHARMACEUTICALS": {
              "ARIPIPRAZOLE": "ARIPIPRAZOLE"
            },
            "PROFICIENT RX": {
              "ARIPIPRAZOLE": "ARIPIPRAZOLE"
            },
            "QUALITY CARE": {
              "ABILIFY": "ABILIFY"
            },
            "QUALITY CARE PRODUCTS": {
              "ARIPIPRAZOLE": "ARIPIPRAZOLE"
            },
            "SAFECOR HEALTH": {
              "ARIPIPRAZOLE": "ARIPIPRAZOLE"
            },
            "SILARX": {
              "ARIPIPRAZOLE": "ARIPIPRAZOLE"
            },
            "SKY PACKAGING": {
              "ARIPIPRAZOLE": "ARIPIPRAZOLE"
            },
            "SOLCO HEALTHCARE": {
              "ARIPIPRAZOLE": "ARIPIPRAZOLE"
            },
            "TEVA PHARMACEUTICALS USA": {
              "ARIPIPRAZOLE": "ARIPIPRAZOLE"
            },
            "TORRENT PHARMACEUTICALS": {
              "ARIPIPRAZOLE": "ARIPIPRAZOLE"
            },
            "TRIGEN LABORATORIES": {
              "ARIPIPRAZOLE": "ARIPIPRAZOLE"
            },
            "VISTAPHARM INC.": {
              "ARIPIPRAZOLE": "ARIPIPRAZOLE"
            }
          }
        },
        "Thioxanthenes": {
          "Thioxanthenes": {
            "GOLDEN STATE MEDICAL SUPPLY": {
              "THIOTHIXENE": "THIOTHIXENE"
            },
            "MYLAN": {
              "THIOTHIXENE": "THIOTHIXENE"
            },
            "MYLAN INSTITUTIONAL": {
              "THIOTHIXENE": "THIOTHIXENE"
            },
            "NOVITIUM PHARMA": {
              "THIOTHIXENE": "THIOTHIXENE"
            },
            "SANDOZ": {
              "THIOTHIXENE": "THIOTHIXENE"
            }
          }
        }
      },
      "ANTISEPTICS & DISINFECTANTS": {
        "Antiseptic Combinations": {
          "Antiseptic Combinations": {
            "COLOPLAST": {
              "ISAGEL": "ISAGEL"
            },
            "COVIDIEN MEDICAL SUPPLIES": {
              "KENDALL WET SKIN SCRUB PREP": "KENDALL WET SKIN SCRUB PREP"
            },
            "GIL PHARMACEUTICAL CORP.": {
              "BUCALSEP": "BUCALSEP"
            },
            "J & J MEDICAL": {
              "MICROCLENS WIPES": "MICROCLENS WIPES"
            },
            "MEDIQUE PRODUCTS": {
              "ANTISEPTIC": "ANTISEPTIC"
            },
            "MEDLINE/DERMAL MANAGEMENT": {
              "EXCEL AP": "EXCEL AP"
            },
            "ROCHE INSULIN DELIVERY SYSTEMS": {
              "IV PREP WIPES": "IV PREP WIPES",
              "UNI-SOLVE WIPES": "UNI-SOLVE WIPES"
            },
            "SMITH & NEPHEW UNITED": {
              "IV PREP WIPES": "IV PREP WIPES",
              "UNI-SOLVE": "UNI-SOLVE"
            }
          }
        },
        "Antiseptics & Disinfectants": {
          "Antiseptics & Disinfectants": {
            "ALLIANCE TECH MEDICAL": {
              "CETYLCIDE-G": "CETYLCIDE-G"
            },
            "AMERICAN SALES COMPANY": {
              "HYDROGEN PEROXIDE": "HYDROGEN PEROXIDE"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP HYDROGEN PEROXIDE": "GNP HYDROGEN PEROXIDE",
              "GNP HYDROGEN PEROXIDE WIPES": "GNP HYDROGEN PEROXIDE WIPES"
            },
            "AURORA HEALTHCARE": {
              "HYDROGEN PEROXIDE": "HYDROGEN PEROXIDE"
            },
            "BAUSCH HEALTH": {
              "PHENOL EZ SWABS": "PHENOL EZ SWABS"
            },
            "BERGEN BRUNSWIG": {
              "GNP HYDROGEN PEROXIDE": "GNP HYDROGEN PEROXIDE"
            },
            "CHAIN DRUG CONSORTIUM": {
              "HYDROGEN PEROXIDE": "HYDROGEN PEROXIDE",
              "HYDROGEN PEROXIDE WIPES": "HYDROGEN PEROXIDE WIPES"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC HYDROGEN PEROXIDE": "QC HYDROGEN PEROXIDE"
            },
            "COLOPLAST": {
              "PREP PROTECTIVE SKIN BARRIER": "PREP PROTECTIVE SKIN BARRIER"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS HYDROGEN PEROXIDE": "CVS HYDROGEN PEROXIDE"
            },
            "DENISON LABS": {
              "HYDROGEN PEROXIDE": "HYDROGEN PEROXIDE"
            },
            "EQUALINE": {
              "EQL HYDROGEN PEROXIDE": "EQL HYDROGEN PEROXIDE"
            },
            "EXELTIS USA DERMATOLOGY": {
              "HYLAMEND FIRST AID ANTISEPTIC": "HYLAMEND FIRST AID ANTISEPTIC"
            },
            "FAGRON": {
              "FORMALDEHYDE": "FORMALDEHYDE",
              "PHENOL": "PHENOL"
            },
            "GEISS DESTIN & DUNN": {
              "GOODSENSE HYDROGEN PEROXIDE": "GOODSENSE HYDROGEN PEROXIDE"
            },
            "GORDON": {
              "FORMA-RAY": "FORMA-RAY",
              "FORMADON": "FORMADON"
            },
            "HUMCO": {
              "HYDROGEN PEROXIDE": "HYDROGEN PEROXIDE"
            },
            "LEADER BRAND PRODUCTS": {
              "HYDROGEN PEROXIDE": "HYDROGEN PEROXIDE"
            },
            "LETCO MEDICAL": {
              "FORMALDEHYDE": "FORMALDEHYDE",
              "PHENOL": "PHENOL"
            },
            "MCKESSON": {
              "HM HYDROGEN PEROXIDE": "HM HYDROGEN PEROXIDE"
            },
            "MCKESSON SUNMARK": {
              "SM HYDROGEN PEROXIDE": "SM HYDROGEN PEROXIDE"
            },
            "MEDICINE SHOPPE": {
              "HYDROGEN PEROXIDE": "HYDROGEN PEROXIDE"
            },
            "MEDISCA": {
              "PHENOL": "PHENOL"
            },
            "MEDLINE/DERMAL MANAGEMENT": {
              "HYDROGEN PEROXIDE": "HYDROGEN PEROXIDE"
            },
            "MEIJER": {
              "MEIJER HYDROGEN PEROXIDE": "MEIJER HYDROGEN PEROXIDE"
            },
            "PCCA": {
              "FORMALDEHYDE": "FORMALDEHYDE",
              "GLUTARALDEHYDE": "GLUTARALDEHYDE",
              "HYDROGEN PEROXIDE": "HYDROGEN PEROXIDE",
              "PHENOL": "PHENOL"
            },
            "PL DEVELOPMENTS": {
              "HYDROGEN PEROXIDE": "HYDROGEN PEROXIDE"
            },
            "PUBLIX SUPER MARKETS INC.": {
              "PX HYDROGEN PEROXIDE": "PX HYDROGEN PEROXIDE"
            },
            "RITE AID CORPORATION": {
              "RA HYDROGEN PEROXIDE": "RA HYDROGEN PEROXIDE",
              "RA HYDROGEN PEROXIDE WIPES": "RA HYDROGEN PEROXIDE WIPES"
            },
            "ROCHESTER PHARMACEUTICALS": {
              "FORMALDEHYDE": "FORMALDEHYDE"
            },
            "ROSEDALE THERAPEUTICS": {
              "DERMA CIDOL 2000": "DERMA CIDOL 2000"
            },
            "SPECTRUM": {
              "FORMALDEHYDE": "FORMALDEHYDE",
              "HYDROGEN PEROXIDE": "HYDROGEN PEROXIDE",
              "PHENOL": "PHENOL"
            },
            "TARGET": {
              "TGT HYDROGEN PEROXIDE": "TGT HYDROGEN PEROXIDE"
            },
            "VI-JON": {
              "HYDROGEN PEROXIDE": "HYDROGEN PEROXIDE"
            },
            "VISTAPHARM INC.": {
              "KERR TRIPLE DYE SWABS": "KERR TRIPLE DYE SWABS"
            },
            "WAL-MART": {
              "EQ HYDROGEN PEROXIDE": "EQ HYDROGEN PEROXIDE",
              "HYDROGEN PEROXIDE": "HYDROGEN PEROXIDE"
            },
            "WALGREENS": {
              "HYDROGEN PEROXIDE": "HYDROGEN PEROXIDE"
            },
            "WILLIAM LABS": {
              "TRIPLE DYE": "TRIPLE DYE"
            }
          }
        },
        "Chlorine Antiseptics": {
          "Chlorine Antiseptic Combinations": {
            "CHAIN DRUG CONSORTIUM": {
              "MERTHIOLATE (NEW FORMULA)": "MERTHIOLATE (NEW FORMULA)"
            },
            "CHATTEM INC": {
              "GOLD BOND FIRST AID QUICK": "GOLD BOND FIRST AID QUICK"
            },
            "DLC LABORATORIES": {
              "MERTHIOLATE (NEW FORMULA)": "MERTHIOLATE (NEW FORMULA)"
            },
            "GORDON": {
              "OXYZAL WET DRESSING": "OXYZAL WET DRESSING"
            },
            "MEDI-FLEX, INC.": {
              "CHLORAPREP ONE STEP": "CHLORAPREP ONE STEP"
            }
          },
          "Chlorine Antiseptics": {
            "3M MEDICAL": {
              "CAVILON SKIN CLEANSER": "CAVILON SKIN CLEANSER",
              "TEGADERM CHG DRESSING": "TEGADERM CHG DRESSING"
            },
            "A-S MEDICATION SOLUTIONS": {
              "HIBICLENS": "HIBICLENS"
            },
            "AMERIDERM LABORATORIES LTD": {
              "AMERIWASH": "AMERIWASH"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP ANTISEPTIC SKIN CLEANSER": "GNP ANTISEPTIC SKIN CLEANSER"
            },
            "ANACAPA TECHNOLOGIES": {
              "ANASEPT": "ANASEPT",
              "ANASEPT ANTIMICROBIAL": "ANASEPT ANTIMICROBIAL"
            },
            "APLICARE": {
              "ANTIBACTERIAL LOTION SOAP": "ANTIBACTERIAL LOTION SOAP",
              "ANTISEPTIC PERINEAL WASH II": "ANTISEPTIC PERINEAL WASH II",
              "PHYSICIANS ANTIBACTERIAL": "PHYSICIANS ANTIBACTERIAL"
            },
            "APOTHECA SUPPLY": {
              "BENZALKONIUM CHLORIDE": "BENZALKONIUM CHLORIDE"
            },
            "AVKARE": {
              "WALTZ FREE HAND SANITZER": "WALTZ FREE HAND SANITZER"
            },
            "AVPAK": {
              "GERMBLOC HEALTH": "GERMBLOC HEALTH"
            },
            "AVRIO HEALTH LP": {
              "BETASEPT SURGICAL SCRUB": "BETASEPT SURGICAL SCRUB"
            },
            "CENTURY": {
              "DAKINS (1/2 STRENGTH)": "DAKINS (1/2 STRENGTH)",
              "DAKINS (1/4 STRENGTH)": "DAKINS (1/4 STRENGTH)",
              "DAKINS (FULL STRENGTH)": "DAKINS (FULL STRENGTH)",
              "DI-DAK-SOL": "DI-DAK-SOL"
            },
            "CHAIN DRUG CONSORTIUM": {
              "ANTI-BACTERIAL CLEANSING WIPES": "ANTI-BACTERIAL CLEANSING WIPES",
              "ANTISEPTIC SKIN CLEANSER": "ANTISEPTIC SKIN CLEANSER",
              "MOIST TOWELETTES ANTIBACTERIAL": "MOIST TOWELETTES ANTIBACTERIAL"
            },
            "CHATTEM INC": {
              "GOLD BOND ULTIM SANITIZR/MOIST": "GOLD BOND ULTIM SANITIZR/MOIST",
              "GOLD BOND ULTIM SANITIZR/SHEER": "GOLD BOND ULTIM SANITIZR/SHEER"
            },
            "COLOPLAST": {
              "GENTLE RAIN": "GENTLE RAIN"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS ANTISEPTIC SKIN CLEANSER": "CVS ANTISEPTIC SKIN CLEANSER",
              "CVS HAND WASH ADVANCED ANTIBAC": "CVS HAND WASH ADVANCED ANTIBAC",
              "CVS MERTHIOLATE": "CVS MERTHIOLATE"
            },
            "DIAL CORP": {
              "DIAL GOLD": "DIAL GOLD"
            },
            "DYNAREX CORPORATION": {
              "BZK ANTISEPTIC TOWELETTES": "BZK ANTISEPTIC TOWELETTES"
            },
            "EQUALINE": {
              "EQL ANTIBAC DEODORANT SOAP": "EQL ANTIBAC DEODORANT SOAP",
              "EQL ANTIBAC FOAMING HAND WASH": "EQL ANTIBAC FOAMING HAND WASH",
              "EQL ANTIBACTERIAL HAND SOAP": "EQL ANTIBACTERIAL HAND SOAP",
              "EQL HAND SOAP": "EQL HAND SOAP"
            },
            "FAGRON": {
              "BENZALKONIUM CHLORIDE": "BENZALKONIUM CHLORIDE",
              "CHLORHEXIDINE GLUCONATE": "CHLORHEXIDINE GLUCONATE"
            },
            "FNC MEDICAL CORPORATION": {
              "CA-REZZ": "CA-REZZ",
              "CA-REZZ GENTLE": "CA-REZZ GENTLE",
              "CA-REZZ MOISTURE BARRIER": "CA-REZZ MOISTURE BARRIER",
              "CA-REZZ NORISC": "CA-REZZ NORISC",
              "DIABET-X DAILY PREVENTION": "DIABET-X DAILY PREVENTION"
            },
            "GALDERMA": {
              "CETAPHIL ANTIBACTERIAL": "CETAPHIL ANTIBACTERIAL"
            },
            "GERITREX CORPORATION": {
              "ALC-FREE": "ALC-FREE"
            },
            "GLOBAL HEALTH SOLUTIONS": {
              "CURX ANTIMICROBIAL": "CURX ANTIMICROBIAL"
            },
            "GUARDIAN LABORATORIES": {
              "CLORPACTIN": "CLORPACTIN"
            },
            "HUMCO": {
              "MERTHIOLATE": "MERTHIOLATE"
            },
            "J & J CONSUMER PRODUCTS": {
              "NEOSPORIN WOUND CLEANSER": "NEOSPORIN WOUND CLEANSER"
            },
            "J & J MEDICAL": {
              "BIOPATCH": "BIOPATCH",
              "BIOPATCH PROTECTIVE DISK/CHG": "BIOPATCH PROTECTIVE DISK/CHG"
            },
            "LEADER BRAND PRODUCTS": {
              "ANTISEPTIC SKIN CLEANSER": "ANTISEPTIC SKIN CLEANSER"
            },
            "LETCO MEDICAL": {
              "CHLORHEXIDINE GLUCONATE": "CHLORHEXIDINE GLUCONATE"
            },
            "MCKESSON": {
              "HM ANTISEPTIC SKIN CLEANSER": "HM ANTISEPTIC SKIN CLEANSER"
            },
            "MCKESSON SUNMARK": {
              "SM ANTIBACTERIAL LIQUID SOAP": "SM ANTIBACTERIAL LIQUID SOAP"
            },
            "MEDIQUE PRODUCTS": {
              "ANTISEPTIC SPRAY": "ANTISEPTIC SPRAY"
            },
            "MEDISCA": {
              "BENZALKONIUM CHLORIDE": "BENZALKONIUM CHLORIDE",
              "CHLORHEXIDINE GLUCONATE": "CHLORHEXIDINE GLUCONATE"
            },
            "MEDLINE/DERMAL MANAGEMENT": {
              "CHLORHEXIDINE GLUCONATE": "CHLORHEXIDINE GLUCONATE",
              "MICROKLENZ": "MICROKLENZ",
              "REMEDY ANTIMICROBIAL CLEANSER": "REMEDY ANTIMICROBIAL CLEANSER"
            },
            "MOLNLYCKE HEALTHCARE": {
              "HIBICLENS": "HIBICLENS"
            },
            "MPM MEDICAL, INC.": {
              "ANTISEPTIC WOUND/SKIN CLEANSER": "ANTISEPTIC WOUND/SKIN CLEANSER"
            },
            "NEOSTRATA": {
              "EXUVIANCE FACIAL CLEANSER": "EXUVIANCE FACIAL CLEANSER",
              "NEOSTRATA FACIAL CLEANSER": "NEOSTRATA FACIAL CLEANSER"
            },
            "PATRIN PHARMA": {
              "H-CHLOR 12": "H-CHLOR 12",
              "H-CHLOR 6": "H-CHLOR 6",
              "H-CHLOR WOUND": "H-CHLOR WOUND",
              "HYSEPT": "HYSEPT"
            },
            "PCCA": {
              "BENZALKONIUM CHLORIDE": "BENZALKONIUM CHLORIDE"
            },
            "RITE AID CORPORATION": {
              "RA ANTIBACTERIAL WIPES": "RA ANTIBACTERIAL WIPES",
              "RA ANTISEPTIC SKIN CLEANSER": "RA ANTISEPTIC SKIN CLEANSER",
              "RA FIRST AID SALINE WOUND WASH": "RA FIRST AID SALINE WOUND WASH",
              "RA FOAMING HAND SOAP REFILL": "RA FOAMING HAND SOAP REFILL",
              "RA RENEWAL ANTIBACTERIAL SOAP": "RA RENEWAL ANTIBACTERIAL SOAP",
              "RA RENEWAL HAND SOAP": "RA RENEWAL HAND SOAP"
            },
            "RUGBY LABORATORIES": {
              "ANTISEPTIC SKIN CLEANSER": "ANTISEPTIC SKIN CLEANSER"
            },
            "SAGE PRODUCTS": {
              "CHLORHEXIDINE GLUCONATE CLOTH": "CHLORHEXIDINE GLUCONATE CLOTH"
            },
            "SMITH & NEPHEW UNITED": {
              "DERMAL WOUND CLEANSER": "DERMAL WOUND CLEANSER",
              "SECURA MOISTURIZING CLEANSER": "SECURA MOISTURIZING CLEANSER",
              "SECURA PERSONAL CLEANSER": "SECURA PERSONAL CLEANSER",
              "SECURA TOTAL BODY CLEANSER": "SECURA TOTAL BODY CLEANSER"
            },
            "SPECTRUM": {
              "BENZALKONIUM CHLORIDE": "BENZALKONIUM CHLORIDE"
            },
            "WAL-MART": {
              "EQ ANTIBACTERIAL HANDS & FACE": "EQ ANTIBACTERIAL HANDS & FACE"
            },
            "WALGREENS": {
              "ANTIBACTERIAL HAND SOAP": "ANTIBACTERIAL HAND SOAP",
              "ANTIBACTERIAL LIQUID SOAP": "ANTIBACTERIAL LIQUID SOAP",
              "ANTISEPTIC SKIN CLEANSER": "ANTISEPTIC SKIN CLEANSER",
              "CURECHROME": "CURECHROME",
              "HAND WIPES": "HAND WIPES"
            },
            "WOODWARD LABS": {
              "DIABETIC BASICS HEALTHY FOOT": "DIABETIC BASICS HEALTHY FOOT",
              "HANDCLENS 2 IN 1": "HANDCLENS 2 IN 1"
            },
            "XTTRIUM": {
              "DYNA-HEX 2": "DYNA-HEX 2",
              "DYNA-HEX 4": "DYNA-HEX 4"
            }
          }
        },
        "Disinfectants": {
          "Disinfectants": {
            "ALLIANCE TECH MEDICAL": {
              "CETYLCIDE II": "CETYLCIDE II"
            },
            "OAKHURST": {
              "CREOLIN": "CREOLIN"
            }
          }
        },
        "Iodine Antiseptics": {
          "Iodine Antiseptic Combinations": {
            "SHEFFIELD PHARMACEUTICALS": {
              "IODEX/METHYL SALICYLATE": "IODEX/METHYL SALICYLATE"
            }
          },
          "Iodine Antiseptics": {
            "AMERICAN SALES COMPANY": {
              "POVIDONE-IODINE": "POVIDONE-IODINE"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP POVIDONE-IODINE": "GNP POVIDONE-IODINE"
            },
            "APLICARE": {
              "CLOROX NASAL ANTISEPTIC": "CLOROX NASAL ANTISEPTIC",
              "NASAL ANTISEPTIC": "NASAL ANTISEPTIC",
              "POVIDONE-IODINE": "POVIDONE-IODINE",
              "POVIDONE-IODINE PAINT SPONGE": "POVIDONE-IODINE PAINT SPONGE",
              "POVIDONE-IODINE SCRUB SPONGE": "POVIDONE-IODINE SCRUB SPONGE"
            },
            "ASAFI PHARMACEUTICAL": {
              "POVIDONE-IODINE": "POVIDONE-IODINE"
            },
            "AVRIO HEALTH LP": {
              "BETADINE": "BETADINE",
              "BETADINE ANTISEPTIC": "BETADINE ANTISEPTIC",
              "BETADINE ANTISEPTIC DRY POWDER": "BETADINE ANTISEPTIC DRY POWDER",
              "BETADINE SKIN CLEANSER": "BETADINE SKIN CLEANSER",
              "BETADINE SURGICAL SCRUB": "BETADINE SURGICAL SCRUB",
              "BETADINE SWAB AID": "BETADINE SWAB AID",
              "BETADINE SWABSTICKS": "BETADINE SWABSTICKS"
            },
            "BERGEN BRUNSWIG": {
              "GNP IODIDES": "GNP IODIDES",
              "GNP IODINE": "GNP IODINE",
              "GNP POVIDONE-IODINE": "GNP POVIDONE-IODINE"
            },
            "CAREFUSION 213": {
              "SCRUB CARE POVIDONE-IODINE": "SCRUB CARE POVIDONE-IODINE"
            },
            "CHAIN DRUG CONSORTIUM": {
              "ANTISEPTIC": "ANTISEPTIC",
              "IODINE": "IODINE"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC IODIDES": "QC IODIDES",
              "QC IODINE TINCTURE": "QC IODINE TINCTURE",
              "QC POVIDONE IODINE": "QC POVIDONE IODINE"
            },
            "COVIDIEN MEDICAL SUPPLIES": {
              "CURITY POVIDONE-IODINE PAINT": "CURITY POVIDONE-IODINE PAINT",
              "CURITY POVIDONE-IODINE SCRUB": "CURITY POVIDONE-IODINE SCRUB",
              "KENDALL LG WINGED SPONGES/PVP": "KENDALL LG WINGED SPONGES/PVP",
              "KENDALL SKIN SCRUB PAK/SPONGES": "KENDALL SKIN SCRUB PAK/SPONGES",
              "KENDALL SPONGE STICK/PVP": "KENDALL SPONGE STICK/PVP",
              "KENDALL VAGINAL PREP PACK": "KENDALL VAGINAL PREP PACK",
              "KENDALL VAGINAL PREP TRAY": "KENDALL VAGINAL PREP TRAY",
              "KENDALL WET SKIN SCRUB PACK": "KENDALL WET SKIN SCRUB PACK",
              "ONE-STEP PREP POVIDONE-IODINE": "ONE-STEP PREP POVIDONE-IODINE"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS IODINE TINCTURE": "CVS IODINE TINCTURE",
              "CVS POVIDONE-IODINE": "CVS POVIDONE-IODINE"
            },
            "DENISON LABS": {
              "IODINE": "IODINE"
            },
            "DYNAREX CORPORATION": {
              "POVIDONE-IODINE": "POVIDONE-IODINE",
              "POVIDONE-IODINE PREP": "POVIDONE-IODINE PREP"
            },
            "EQUALINE": {
              "EQ POVIDONE-IODINE": "EQ POVIDONE-IODINE"
            },
            "ESCALON MEDICAL CORP": {
              "NUPREP 5% POVIDONE-IODINE": "NUPREP 5% POVIDONE-IODINE"
            },
            "GEISS DESTIN & DUNN": {
              "GOODSENSE IODINE": "GOODSENSE IODINE"
            },
            "GERITREX CORPORATION": {
              "GRX DYNE": "GRX DYNE",
              "GRX DYNE SCRUB": "GRX DYNE SCRUB"
            },
            "GORDON": {
              "LUGOLS": "LUGOLS"
            },
            "HUMCO": {
              "IODINE": "IODINE",
              "IODINE STRONG": "IODINE STRONG",
              "IODINE TINCTURE": "IODINE TINCTURE",
              "POVIDONE-IODINE": "POVIDONE-IODINE"
            },
            "LEADER BRAND PRODUCTS": {
              "DECOLORIZED IODINE": "DECOLORIZED IODINE",
              "IODIDES TINCTURE": "IODIDES TINCTURE",
              "IODINE": "IODINE",
              "IODINE TINCTURE": "IODINE TINCTURE",
              "POVIDONE-IODINE": "POVIDONE-IODINE"
            },
            "LETCO MEDICAL": {
              "IODINE TINCTURE": "IODINE TINCTURE"
            },
            "LIBBY LABORATORIES": {
              "IODINE": "IODINE"
            },
            "MAJOR PHARMACEUTICALS": {
              "POVIDONE-IODINE": "POVIDONE-IODINE"
            },
            "MCKESSON": {
              "HM IODIDES": "HM IODIDES",
              "HM IODINE": "HM IODINE",
              "HM POVIDONE-IODINE": "HM POVIDONE-IODINE"
            },
            "MCKESSON SUNMARK": {
              "SM IODIDES": "SM IODIDES",
              "SM IODINE TINCTURE": "SM IODINE TINCTURE",
              "SM POVIDONE-IODINE": "SM POVIDONE-IODINE"
            },
            "MEDLINE/DERMAL MANAGEMENT": {
              "APLICARE POVIDONE-IODINE": "APLICARE POVIDONE-IODINE",
              "APLICARE POVIDONE-IODINE SCRUB": "APLICARE POVIDONE-IODINE SCRUB",
              "POVIDONE-IODINE": "POVIDONE-IODINE",
              "POVIDONE-IODINE PAINT SPONGE": "POVIDONE-IODINE PAINT SPONGE",
              "POVIDONE-IODINE PREP": "POVIDONE-IODINE PREP",
              "POVIDONE-IODINE SCRUB SPONGE": "POVIDONE-IODINE SCRUB SPONGE",
              "PVP PREP": "PVP PREP",
              "PVP SCRUB": "PVP SCRUB"
            },
            "PAR PHARMACEUTICAL": {
              "POVIDONE-IODINE": "POVIDONE-IODINE"
            },
            "PAR PHARMACEUTICALS": {
              "POVIDONE-IODINE": "POVIDONE-IODINE"
            },
            "PURDUE PRODUCTS LP": {
              "BETADINE SPRAY": "BETADINE SPRAY"
            },
            "RITE AID CORPORATION": {
              "RA ANTISEPTIC": "RA ANTISEPTIC",
              "RA FIRST AID IODINE": "RA FIRST AID IODINE",
              "RA POVIDINE IODINE": "RA POVIDINE IODINE"
            },
            "SELECT BRAND": {
              "SB POVIDONE-IODINE": "SB POVIDONE-IODINE"
            },
            "SHEFFIELD PHARMACEUTICALS": {
              "IODEX": "IODEX"
            },
            "SMITH & NEPHEW UNITED": {
              "IODOFLEX": "IODOFLEX",
              "IODOSORB": "IODOSORB"
            },
            "SPECTRUM": {
              "IODINE": "IODINE"
            },
            "TOPCO": {
              "POVIDONE-IODINE": "POVIDONE-IODINE"
            },
            "VI-JON": {
              "IODINE TINCTURE": "IODINE TINCTURE"
            },
            "WAL-MART": {
              "EQ FIRST AID ANTISEPTIC": "EQ FIRST AID ANTISEPTIC"
            },
            "WALGREENS": {
              "IODINE": "IODINE",
              "IODINE TINCTURE": "IODINE TINCTURE",
              "POVIDONE-IODINE": "POVIDONE-IODINE"
            }
          }
        },
        "Mercury Antiseptics": {
          "Mercury Antiseptics": {
            "SPECTRUM": {
              "THIMEROSAL": "THIMEROSAL"
            }
          }
        },
        "Silver Antiseptics": {
          "Silver Antiseptics": {
            "LETCO MEDICAL": {
              "SILVER PROTEIN MILD": "SILVER PROTEIN MILD"
            },
            "PCCA": {
              "SILVER PROTEIN MILD": "SILVER PROTEIN MILD"
            },
            "SPECTRUM": {
              "SILVER PROTEIN MILD": "SILVER PROTEIN MILD"
            }
          }
        }
      },
      "ANTIVIRALS": {
        "Antiretrovirals": {
          "Antiretroviral Combinations": {
            "A-S MEDICATION SOLUTIONS": {
              "ATRIPLA": "ATRIPLA",
              "COMBIVIR": "COMBIVIR",
              "COMPLERA": "COMPLERA",
              "EPZICOM": "EPZICOM",
              "EVOTAZ": "EVOTAZ",
              "GENVOYA": "GENVOYA",
              "KALETRA": "KALETRA",
              "LAMIVUDINE-ZIDOVUDINE": "LAMIVUDINE-ZIDOVUDINE",
              "ODEFSEY": "ODEFSEY",
              "PREZCOBIX": "PREZCOBIX",
              "STRIBILD": "STRIBILD",
              "TRIUMEQ": "TRIUMEQ",
              "TRIZIVIR": "TRIZIVIR",
              "TRUVADA": "TRUVADA"
            },
            "ABBVIE": {
              "KALETRA": "KALETRA"
            },
            "AIDAREX PHARMACEUTICALS": {
              "LAMIVUDINE-ZIDOVUDINE": "LAMIVUDINE-ZIDOVUDINE"
            },
            "AMERICAN HEALTH PACKAGING": {
              "LAMIVUDINE-ZIDOVUDINE": "LAMIVUDINE-ZIDOVUDINE"
            },
            "AUROBINDO PHARMA": {
              "ABACAVIR SULFATE-LAMIVUDINE": "ABACAVIR SULFATE-LAMIVUDINE",
              "LAMIVUDINE-ZIDOVUDINE": "LAMIVUDINE-ZIDOVUDINE"
            },
            "AVKARE": {
              "ABACAVIR SULFATE-LAMIVUDINE": "ABACAVIR SULFATE-LAMIVUDINE",
              "LAMIVUDINE-ZIDOVUDINE": "LAMIVUDINE-ZIDOVUDINE"
            },
            "AVPAK": {
              "LAMIVUDINE-ZIDOVUDINE": "LAMIVUDINE-ZIDOVUDINE"
            },
            "B-M SQUIBB U.S. (PRIMARY CARE)": {
              "EVOTAZ": "EVOTAZ"
            },
            "BUREL PHARMACEUTICALS": {
              "ABACAVIR SULFATE-LAMIVUDINE": "ABACAVIR SULFATE-LAMIVUDINE"
            },
            "CAMBER PHARMACEUTICALS": {
              "LAMIVUDINE-ZIDOVUDINE": "LAMIVUDINE-ZIDOVUDINE"
            },
            "CELLTRION USA": {
              "TEMIXYS": "TEMIXYS"
            },
            "CIPLA USA": {
              "ABACAVIR SULFATE-LAMIVUDINE": "ABACAVIR SULFATE-LAMIVUDINE"
            },
            "DIRECT RX": {
              "LAMIVUDINE-ZIDOVUDINE": "LAMIVUDINE-ZIDOVUDINE",
              "TRUVADA": "TRUVADA"
            },
            "EXELAN PHARMACEUTICALS": {
              "LAMIVUDINE-ZIDOVUDINE": "LAMIVUDINE-ZIDOVUDINE"
            },
            "GILEAD SCIENCES": {
              "ATRIPLA": "ATRIPLA",
              "BIKTARVY": "BIKTARVY",
              "COMPLERA": "COMPLERA",
              "DESCOVY": "DESCOVY",
              "GENVOYA": "GENVOYA",
              "ODEFSEY": "ODEFSEY",
              "STRIBILD": "STRIBILD",
              "TRUVADA": "TRUVADA"
            },
            "H.J. HARKINS COMPANY": {
              "LAMIVUDINE-ZIDOVUDINE": "LAMIVUDINE-ZIDOVUDINE"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "COMBIVIR": "COMBIVIR",
              "KALETRA": "KALETRA",
              "TRUVADA": "TRUVADA"
            },
            "JANSSEN PRODUCTS": {
              "PREZCOBIX": "PREZCOBIX",
              "SYMTUZA": "SYMTUZA"
            },
            "LANNETT": {
              "LOPINAVIR-RITONAVIR": "LOPINAVIR-RITONAVIR"
            },
            "LAURUS LABS PRIVATE LIMITED": {
              "EFAVIRENZ-LAMIVUDINE-TENOFOVIR": "EFAVIRENZ-LAMIVUDINE-TENOFOVIR"
            },
            "LUPIN PHARMACEUTICALS": {
              "ABACAVIR SULFATE-LAMIVUDINE": "ABACAVIR SULFATE-LAMIVUDINE",
              "ABACAVIR-LAMIVUDINE-ZIDOVUDINE": "ABACAVIR-LAMIVUDINE-ZIDOVUDINE",
              "LAMIVUDINE-ZIDOVUDINE": "LAMIVUDINE-ZIDOVUDINE"
            },
            "MACLEODS PHARMACEUTICALS": {
              "LAMIVUDINE-ZIDOVUDINE": "LAMIVUDINE-ZIDOVUDINE"
            },
            "MERCK SHARP & DOHME": {
              "DELSTRIGO": "DELSTRIGO"
            },
            "MYLAN": {
              "LAMIVUDINE-ZIDOVUDINE": "LAMIVUDINE-ZIDOVUDINE"
            },
            "MYLAN SPECIALTY L.P.": {
              "CIMDUO": "CIMDUO",
              "SYMFI": "SYMFI",
              "SYMFI LO": "SYMFI LO"
            },
            "NUCARE PHARMACEUTICALS": {
              "COMBIVIR": "COMBIVIR",
              "KALETRA": "KALETRA",
              "LAMIVUDINE-ZIDOVUDINE": "LAMIVUDINE-ZIDOVUDINE"
            },
            "PDRX PHARMACEUTICAL": {
              "KALETRA": "KALETRA",
              "LAMIVUDINE-ZIDOVUDINE": "LAMIVUDINE-ZIDOVUDINE"
            },
            "PRASCO LABORATORIES": {
              "ABACAVIR SULFATE-LAMIVUDINE": "ABACAVIR SULFATE-LAMIVUDINE"
            },
            "PREFERRED PHARMACEUTICALS": {
              "LAMIVUDINE-ZIDOVUDINE": "LAMIVUDINE-ZIDOVUDINE"
            },
            "QUALITY CARE": {
              "COMBIVIR": "COMBIVIR",
              "KALETRA": "KALETRA",
              "LAMIVUDINE-ZIDOVUDINE": "LAMIVUDINE-ZIDOVUDINE",
              "TRIZIVIR": "TRIZIVIR",
              "TRUVADA": "TRUVADA"
            },
            "QUALITY CARE PRODUCTS": {
              "LAMIVUDINE-ZIDOVUDINE": "LAMIVUDINE-ZIDOVUDINE"
            },
            "ST MARY'S MPP": {
              "COMBIVIR": "COMBIVIR",
              "LAMIVUDINE-ZIDOVUDINE": "LAMIVUDINE-ZIDOVUDINE"
            },
            "STRIDES PHARMA": {
              "LAMIVUDINE-ZIDOVUDINE": "LAMIVUDINE-ZIDOVUDINE"
            },
            "TEVA PHARMACEUTICALS USA": {
              "ABACAVIR SULFATE-LAMIVUDINE": "ABACAVIR SULFATE-LAMIVUDINE",
              "EFAVIRENZ-EMTRICITAB-TENOFOVIR": "EFAVIRENZ-EMTRICITAB-TENOFOVIR",
              "EMTRICITABINE-TENOFOVIR DF": "EMTRICITABINE-TENOFOVIR DF",
              "LAMIVUDINE-ZIDOVUDINE": "LAMIVUDINE-ZIDOVUDINE"
            },
            "UNIT DOSE SERVICES": {
              "ATRIPLA": "ATRIPLA",
              "COMPLERA": "COMPLERA",
              "EPZICOM": "EPZICOM",
              "TRUVADA": "TRUVADA"
            },
            "VIIV HEALTHCARE": {
              "COMBIVIR": "COMBIVIR",
              "DOVATO": "DOVATO",
              "EPZICOM": "EPZICOM",
              "JULUCA": "JULUCA",
              "TRIUMEQ": "TRIUMEQ",
              "TRIZIVIR": "TRIZIVIR"
            },
            "XLCARE PHARMACEUTICALS": {
              "LAMIVUDINE-ZIDOVUDINE": "LAMIVUDINE-ZIDOVUDINE"
            }
          },
          "Antiretrovirals - CCR5 Antagonists (Entry Inhibitor)": {
            "A-S MEDICATION SOLUTIONS": {
              "SELZENTRY": "SELZENTRY"
            },
            "QUALITY CARE": {
              "SELZENTRY": "SELZENTRY"
            },
            "VIIV HEALTHCARE": {
              "SELZENTRY": "SELZENTRY"
            }
          },
          "Antiretrovirals - CD4-Directed Post-Attachment Inhibitor": {
            "THERATECHNOLOGIES": {
              "TROGARZO": "TROGARZO"
            }
          },
          "Antiretrovirals - Fusion Inhibitors": {
            "GENENTECH": {
              "FUZEON": "FUZEON"
            }
          },
          "Antiretrovirals - Integrase Inhibitors": {
            "A-S MEDICATION SOLUTIONS": {
              "ISENTRESS": "ISENTRESS",
              "TIVICAY": "TIVICAY"
            },
            "DIRECT RX": {
              "ISENTRESS": "ISENTRESS"
            },
            "GILEAD SCIENCES": {
              "VITEKTA": "VITEKTA"
            },
            "MERCK SHARP & DOHME": {
              "ISENTRESS": "ISENTRESS",
              "ISENTRESS HD": "ISENTRESS HD"
            },
            "VIIV HEALTHCARE": {
              "TIVICAY": "TIVICAY",
              "TIVICAY PD": "TIVICAY PD"
            }
          },
          "Antiretrovirals - Protease Inhibitors": {
            "A-S MEDICATION SOLUTIONS": {
              "APTIVUS": "APTIVUS",
              "CRIXIVAN": "CRIXIVAN",
              "INVIRASE": "INVIRASE",
              "LEXIVA": "LEXIVA",
              "NORVIR": "NORVIR",
              "PREZISTA": "PREZISTA",
              "REYATAZ": "REYATAZ",
              "VIRACEPT": "VIRACEPT"
            },
            "ABBVIE": {
              "NORVIR": "NORVIR"
            },
            "AMERICAN HEALTH PACKAGING": {
              "ATAZANAVIR SULFATE": "ATAZANAVIR SULFATE",
              "RITONAVIR": "RITONAVIR"
            },
            "AMNEAL PHARMACEUTICALS": {
              "ATAZANAVIR SULFATE": "ATAZANAVIR SULFATE",
              "RITONAVIR": "RITONAVIR"
            },
            "AUROBINDO PHARMA": {
              "ATAZANAVIR SULFATE": "ATAZANAVIR SULFATE",
              "RITONAVIR": "RITONAVIR"
            },
            "B-M SQUIBB U.S. (PRIMARY CARE)": {
              "REYATAZ": "REYATAZ"
            },
            "BOEHRINGER INGELHEIM": {
              "APTIVUS": "APTIVUS"
            },
            "CAMBER PHARMACEUTICALS": {
              "RITONAVIR": "RITONAVIR"
            },
            "CIPLA USA": {
              "ATAZANAVIR SULFATE": "ATAZANAVIR SULFATE"
            },
            "GENENTECH": {
              "INVIRASE": "INVIRASE"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "ATAZANAVIR SULFATE": "ATAZANAVIR SULFATE"
            },
            "GREENSTONE": {
              "ATAZANAVIR SULFATE": "ATAZANAVIR SULFATE"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "CRIXIVAN": "CRIXIVAN",
              "VIRACEPT": "VIRACEPT"
            },
            "HIKMA": {
              "RITONAVIR": "RITONAVIR"
            },
            "JANSSEN PRODUCTS": {
              "PREZISTA": "PREZISTA"
            },
            "MAJOR PHARMACEUTICALS": {
              "ATAZANAVIR SULFATE": "ATAZANAVIR SULFATE"
            },
            "MERCK SHARP & DOHME": {
              "CRIXIVAN": "CRIXIVAN"
            },
            "MYLAN": {
              "FOSAMPRENAVIR CALCIUM": "FOSAMPRENAVIR CALCIUM"
            },
            "NORTHSTAR RX": {
              "ATAZANAVIR SULFATE": "ATAZANAVIR SULFATE"
            },
            "NUCARE PHARMACEUTICALS": {
              "VIRACEPT": "VIRACEPT"
            },
            "PDRX PHARMACEUTICAL": {
              "VIRACEPT": "VIRACEPT"
            },
            "QUALITY CARE": {
              "CRIXIVAN": "CRIXIVAN",
              "LEXIVA": "LEXIVA",
              "NORVIR": "NORVIR",
              "PREZISTA": "PREZISTA",
              "REYATAZ": "REYATAZ",
              "VIRACEPT": "VIRACEPT"
            },
            "ST MARY'S MPP": {
              "VIRACEPT": "VIRACEPT"
            },
            "SUN PHARMACEUTICALS": {
              "FOSAMPRENAVIR CALCIUM": "FOSAMPRENAVIR CALCIUM"
            },
            "TEVA PHARMACEUTICALS USA": {
              "ATAZANAVIR SULFATE": "ATAZANAVIR SULFATE"
            },
            "VIIV HEALTHCARE": {
              "LEXIVA": "LEXIVA",
              "VIRACEPT": "VIRACEPT"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "RITONAVIR": "RITONAVIR"
            }
          },
          "Antiretrovirals - RTI-Non-Nucleoside Analogues": {
            "A-S MEDICATION SOLUTIONS": {
              "INTELENCE": "INTELENCE",
              "SUSTIVA": "SUSTIVA",
              "VIRAMUNE": "VIRAMUNE",
              "VIRAMUNE XR": "VIRAMUNE XR"
            },
            "ALVOGEN": {
              "NEVIRAPINE": "NEVIRAPINE",
              "NEVIRAPINE ER": "NEVIRAPINE ER"
            },
            "APOTEX": {
              "NEVIRAPINE": "NEVIRAPINE",
              "NEVIRAPINE ER": "NEVIRAPINE ER"
            },
            "AUROBINDO PHARMA": {
              "EFAVIRENZ": "EFAVIRENZ",
              "NEVIRAPINE": "NEVIRAPINE",
              "NEVIRAPINE ER": "NEVIRAPINE ER"
            },
            "B-M SQUIBB U.S. (PRIMARY CARE)": {
              "SUSTIVA": "SUSTIVA"
            },
            "BOEHRINGER INGELHEIM": {
              "VIRAMUNE": "VIRAMUNE",
              "VIRAMUNE XR": "VIRAMUNE XR"
            },
            "BRECKENRIDGE": {
              "NEVIRAPINE": "NEVIRAPINE"
            },
            "CAMBER PHARMACEUTICALS": {
              "EFAVIRENZ": "EFAVIRENZ",
              "NEVIRAPINE": "NEVIRAPINE"
            },
            "CARLSBAD TECHNOLOGIES": {
              "NEVIRAPINE": "NEVIRAPINE"
            },
            "CIPLA USA": {
              "EFAVIRENZ": "EFAVIRENZ",
              "NEVIRAPINE ER": "NEVIRAPINE ER"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "NEVIRAPINE": "NEVIRAPINE"
            },
            "HIKMA": {
              "NEVIRAPINE": "NEVIRAPINE"
            },
            "JANSSEN PRODUCTS": {
              "EDURANT": "EDURANT",
              "INTELENCE": "INTELENCE"
            },
            "MACLEODS PHARMACEUTICALS": {
              "NEVIRAPINE": "NEVIRAPINE",
              "NEVIRAPINE ER": "NEVIRAPINE ER"
            },
            "MERCK SHARP & DOHME": {
              "PIFELTRO": "PIFELTRO"
            },
            "MYLAN": {
              "EFAVIRENZ": "EFAVIRENZ",
              "NEVIRAPINE": "NEVIRAPINE",
              "NEVIRAPINE ER": "NEVIRAPINE ER"
            },
            "PDRX PHARMACEUTICAL": {
              "VIRAMUNE": "VIRAMUNE"
            },
            "QUALITY CARE": {
              "SUSTIVA": "SUSTIVA",
              "VIRAMUNE": "VIRAMUNE"
            },
            "RISING PHARMACEUTICALS": {
              "EFAVIRENZ": "EFAVIRENZ"
            },
            "SANDOZ": {
              "NEVIRAPINE ER": "NEVIRAPINE ER"
            },
            "STRIDES PHARMA": {
              "EFAVIRENZ": "EFAVIRENZ"
            },
            "UNIT DOSE SERVICES": {
              "VIRAMUNE XR": "VIRAMUNE XR"
            },
            "VIIV HEALTHCARE": {
              "RESCRIPTOR": "RESCRIPTOR"
            }
          },
          "Antiretrovirals - RTI-Nucleoside Analogues-Purines": {
            "A-S MEDICATION SOLUTIONS": {
              "ABACAVIR SULFATE": "ABACAVIR SULFATE",
              "DIDANOSINE": "DIDANOSINE",
              "VIDEX EC": "VIDEX EC",
              "ZIAGEN": "ZIAGEN"
            },
            "AMERICAN HEALTH PACKAGING": {
              "ABACAVIR SULFATE": "ABACAVIR SULFATE"
            },
            "APOTEX": {
              "ABACAVIR SULFATE": "ABACAVIR SULFATE"
            },
            "AUROBINDO PHARMA": {
              "ABACAVIR SULFATE": "ABACAVIR SULFATE",
              "DIDANOSINE": "DIDANOSINE"
            },
            "AVPAK": {
              "ABACAVIR SULFATE": "ABACAVIR SULFATE"
            },
            "B-M SQUIBB ONCOLOGY/IMMUNOLOGY": {
              "VIDEX": "VIDEX",
              "VIDEX EC": "VIDEX EC"
            },
            "CAMBER PHARMACEUTICALS": {
              "ABACAVIR SULFATE": "ABACAVIR SULFATE"
            },
            "CIPLA USA": {
              "ABACAVIR SULFATE": "ABACAVIR SULFATE"
            },
            "MAJOR PHARMACEUTICALS": {
              "ABACAVIR SULFATE": "ABACAVIR SULFATE"
            },
            "MURFREESBORO PHARM NURSING SUP": {
              "DIDANOSINE": "DIDANOSINE"
            },
            "MYLAN": {
              "ABACAVIR SULFATE": "ABACAVIR SULFATE",
              "DIDANOSINE": "DIDANOSINE"
            },
            "MYLAN INSTITUTIONAL": {
              "ABACAVIR SULFATE": "ABACAVIR SULFATE"
            },
            "QUALITY CARE": {
              "DIDANOSINE": "DIDANOSINE",
              "VIDEX EC": "VIDEX EC",
              "ZIAGEN": "ZIAGEN"
            },
            "RISING PHARMACEUTICALS": {
              "ABACAVIR SULFATE": "ABACAVIR SULFATE"
            },
            "STRIDES PHARMA": {
              "ABACAVIR SULFATE": "ABACAVIR SULFATE"
            },
            "TEVA PHARMACEUTICALS USA": {
              "DIDANOSINE": "DIDANOSINE"
            },
            "VIIV HEALTHCARE": {
              "ZIAGEN": "ZIAGEN"
            }
          },
          "Antiretrovirals - RTI-Nucleoside Analogues-Pyrimidines": {
            "A-S MEDICATION SOLUTIONS": {
              "EMTRIVA": "EMTRIVA",
              "EPIVIR": "EPIVIR"
            },
            "AMERICAN HEALTH PACKAGING": {
              "LAMIVUDINE": "LAMIVUDINE"
            },
            "APOTEX": {
              "LAMIVUDINE": "LAMIVUDINE"
            },
            "AUROBINDO PHARMA": {
              "LAMIVUDINE": "LAMIVUDINE"
            },
            "AVPAK": {
              "LAMIVUDINE": "LAMIVUDINE"
            },
            "CAMBER PHARMACEUTICALS": {
              "LAMIVUDINE": "LAMIVUDINE"
            },
            "CIPLA USA": {
              "EMTRICITABINE": "EMTRICITABINE"
            },
            "ECI PHARMACEUTICALS": {
              "LAMIVUDINE": "LAMIVUDINE"
            },
            "GILEAD SCIENCES": {
              "EMTRIVA": "EMTRIVA"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "LAMIVUDINE": "LAMIVUDINE"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "EPIVIR": "EPIVIR"
            },
            "INGENUS PHARMACEUTICALS": {
              "LAMIVUDINE": "LAMIVUDINE"
            },
            "LUPIN PHARMACEUTICALS": {
              "LAMIVUDINE": "LAMIVUDINE"
            },
            "MACLEODS PHARMACEUTICALS": {
              "LAMIVUDINE": "LAMIVUDINE"
            },
            "MAJOR PHARMACEUTICALS": {
              "LAMIVUDINE": "LAMIVUDINE"
            },
            "MARLEX PHARMACEUTICALS": {
              "LAMIVUDINE": "LAMIVUDINE"
            },
            "MYLAN": {
              "LAMIVUDINE": "LAMIVUDINE"
            },
            "NUCARE PHARMACEUTICALS": {
              "EPIVIR": "EPIVIR"
            },
            "QUALITY CARE": {
              "EMTRIVA": "EMTRIVA",
              "EPIVIR": "EPIVIR"
            },
            "RISING PHARMACEUTICALS": {
              "LAMIVUDINE": "LAMIVUDINE"
            },
            "SILARX": {
              "LAMIVUDINE": "LAMIVUDINE"
            },
            "STRIDES PHARMA": {
              "LAMIVUDINE": "LAMIVUDINE"
            },
            "VIIV HEALTHCARE": {
              "EPIVIR": "EPIVIR"
            }
          },
          "Antiretrovirals - RTI-Nucleoside Analogues-Thymidines": {
            "A-S MEDICATION SOLUTIONS": {
              "STAVUDINE": "STAVUDINE",
              "ZERIT": "ZERIT",
              "ZIDOVUDINE": "ZIDOVUDINE"
            },
            "ACETRIS HEALTH": {
              "ZIDOVUDINE": "ZIDOVUDINE"
            },
            "AMERICAN HEALTH PACKAGING": {
              "STAVUDINE": "STAVUDINE",
              "ZIDOVUDINE": "ZIDOVUDINE"
            },
            "AUROBINDO PHARMA": {
              "STAVUDINE": "STAVUDINE",
              "ZIDOVUDINE": "ZIDOVUDINE"
            },
            "B-M SQUIBB ONCOLOGY/IMMUNOLOGY": {
              "ZERIT": "ZERIT"
            },
            "CAMBER PHARMACEUTICALS": {
              "STAVUDINE": "STAVUDINE",
              "ZIDOVUDINE": "ZIDOVUDINE"
            },
            "EDENBRIDGE PHARMACEUTICALS": {
              "ZIDOVUDINE": "ZIDOVUDINE"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "RETROVIR": "RETROVIR"
            },
            "LANNETT": {
              "ZIDOVUDINE": "ZIDOVUDINE"
            },
            "MYLAN": {
              "STAVUDINE": "STAVUDINE",
              "ZIDOVUDINE": "ZIDOVUDINE"
            },
            "PAR PHARMACEUTICAL": {
              "STAVUDINE": "STAVUDINE",
              "ZIDOVUDINE": "ZIDOVUDINE"
            },
            "QUALITY CARE": {
              "RETROVIR": "RETROVIR",
              "ZERIT": "ZERIT"
            },
            "RISING PHARMACEUTICALS": {
              "STAVUDINE": "STAVUDINE"
            },
            "VIIV HEALTHCARE": {
              "RETROVIR": "RETROVIR"
            }
          },
          "Antiretrovirals - RTI-Nucleotide Analogues": {
            "A-S MEDICATION SOLUTIONS": {
              "VIREAD": "VIREAD"
            },
            "AMERICAN HEALTH PACKAGING": {
              "TENOFOVIR DISOPROXIL FUMARATE": "TENOFOVIR DISOPROXIL FUMARATE"
            },
            "APOTEX": {
              "TENOFOVIR DISOPROXIL FUMARATE": "TENOFOVIR DISOPROXIL FUMARATE"
            },
            "AUROBINDO PHARMA": {
              "TENOFOVIR DISOPROXIL FUMARATE": "TENOFOVIR DISOPROXIL FUMARATE"
            },
            "AVKARE": {
              "TENOFOVIR DISOPROXIL FUMARATE": "TENOFOVIR DISOPROXIL FUMARATE"
            },
            "AVPAK": {
              "TENOFOVIR DISOPROXIL FUMARATE": "TENOFOVIR DISOPROXIL FUMARATE"
            },
            "CAMBER PHARMACEUTICALS": {
              "TENOFOVIR DISOPROXIL FUMARATE": "TENOFOVIR DISOPROXIL FUMARATE"
            },
            "CIPLA USA": {
              "TENOFOVIR DISOPROXIL FUMARATE": "TENOFOVIR DISOPROXIL FUMARATE"
            },
            "GILEAD SCIENCES": {
              "VIREAD": "VIREAD"
            },
            "LAURUS LABS PRIVATE LIMITED": {
              "TENOFOVIR DISOPROXIL FUMARATE": "TENOFOVIR DISOPROXIL FUMARATE"
            },
            "MACLEODS PHARMACEUTICALS": {
              "TENOFOVIR DISOPROXIL FUMARATE": "TENOFOVIR DISOPROXIL FUMARATE"
            },
            "MAJOR PHARMACEUTICALS": {
              "TENOFOVIR DISOPROXIL FUMARATE": "TENOFOVIR DISOPROXIL FUMARATE"
            },
            "NORTHSTAR RX": {
              "TENOFOVIR DISOPROXIL FUMARATE": "TENOFOVIR DISOPROXIL FUMARATE"
            },
            "QUALITY CARE": {
              "VIREAD": "VIREAD"
            },
            "QUINN PHARMACEUTICALS": {
              "TENOFOVIR DISOPROXIL FUMARATE": "TENOFOVIR DISOPROXIL FUMARATE"
            },
            "STRIDES PHARMA": {
              "TENOFOVIR DISOPROXIL FUMARATE": "TENOFOVIR DISOPROXIL FUMARATE"
            },
            "TEVA PHARMACEUTICALS USA": {
              "TENOFOVIR DISOPROXIL FUMARATE": "TENOFOVIR DISOPROXIL FUMARATE"
            }
          },
          "Antiretrovirals - gp120-Directed Attachment Inhibitor": {
            "VIIV HEALTHCARE": {
              "RUKOBIA": "RUKOBIA"
            }
          },
          "Antiretrovirals Adjuvants": {
            "GILEAD SCIENCES": {
              "TYBOST": "TYBOST"
            }
          }
        },
        "CMV Agents": {
          "CMV Agents": {
            "A-S MEDICATION SOLUTIONS": {
              "VALCYTE": "VALCYTE"
            },
            "ACTAVIS PHARMA": {
              "VALGANCICLOVIR HCL": "VALGANCICLOVIR HCL"
            },
            "AJANTA PHARMA LIMITED": {
              "VALGANCICLOVIR HCL": "VALGANCICLOVIR HCL"
            },
            "AMERICAN HEALTH PACKAGING": {
              "VALGANCICLOVIR HCL": "VALGANCICLOVIR HCL"
            },
            "AUROBINDO PHARMA": {
              "VALGANCICLOVIR HCL": "VALGANCICLOVIR HCL"
            },
            "AVET PHARMACEUTICALS": {
              "CIDOFOVIR": "CIDOFOVIR"
            },
            "AVKARE": {
              "VALGANCICLOVIR HCL": "VALGANCICLOVIR HCL"
            },
            "AVPAK": {
              "VALGANCICLOVIR HCL": "VALGANCICLOVIR HCL"
            },
            "CAMBER PHARMACEUTICALS": {
              "VALGANCICLOVIR HCL": "VALGANCICLOVIR HCL"
            },
            "CIPLA USA": {
              "VALGANCICLOVIR HCL": "VALGANCICLOVIR HCL"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "VALGANCICLOVIR HCL": "VALGANCICLOVIR HCL"
            },
            "EXELA PHARMA SCIENCES": {
              "GANCICLOVIR": "GANCICLOVIR"
            },
            "FRESENIUS KABI USA": {
              "GANCICLOVIR SODIUM": "GANCICLOVIR SODIUM"
            },
            "GENENTECH": {
              "CYTOVENE": "CYTOVENE",
              "VALCYTE": "VALCYTE"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "VALGANCICLOVIR HCL": "VALGANCICLOVIR HCL"
            },
            "H2 PHARMA": {
              "CYTOVENE": "CYTOVENE"
            },
            "MAJOR PHARMACEUTICALS": {
              "VALGANCICLOVIR HCL": "VALGANCICLOVIR HCL"
            },
            "MERCK SHARP & DOHME": {
              "PREVYMIS": "PREVYMIS"
            },
            "MYLAN INSTITUTIONAL": {
              "CIDOFOVIR": "CIDOFOVIR"
            },
            "NORTHSTAR RX": {
              "VALGANCICLOVIR HCL": "VALGANCICLOVIR HCL"
            },
            "NOVAPLUS/HOSPIRA": {
              "FOSCAVIR": "FOSCAVIR"
            },
            "PAR PHARMACEUTICALS": {
              "VALGANCICLOVIR HCL": "VALGANCICLOVIR HCL"
            },
            "PAR STERILE PRODUCTS": {
              "GANCICLOVIR SODIUM": "GANCICLOVIR SODIUM"
            },
            "PFIZER U.S.": {
              "FOSCAVIR": "FOSCAVIR"
            },
            "SAGENT PHARMACEUTICAL": {
              "GANCICLOVIR SODIUM": "GANCICLOVIR SODIUM"
            },
            "SKY PACKAGING": {
              "VALGANCICLOVIR HCL": "VALGANCICLOVIR HCL"
            },
            "SLATE RUN PHARMACEUTICALS": {
              "GANCICLOVIR SODIUM": "GANCICLOVIR SODIUM"
            }
          }
        },
        "Hepatitis Agents": {
          "Hepatitis B Agents": {
            "ACCORD HEALTHCARE": {
              "ENTECAVIR": "ENTECAVIR"
            },
            "ACETRIS HEALTH": {
              "ENTECAVIR": "ENTECAVIR"
            },
            "AMERICAN HEALTH PACKAGING": {
              "ENTECAVIR": "ENTECAVIR"
            },
            "AMNEAL PHARMACEUTICALS": {
              "ENTECAVIR": "ENTECAVIR"
            },
            "APOTEX": {
              "ADEFOVIR DIPIVOXIL": "ADEFOVIR DIPIVOXIL",
              "LAMIVUDINE": "LAMIVUDINE"
            },
            "AUROBINDO PHARMA": {
              "ENTECAVIR": "ENTECAVIR"
            },
            "AVKARE": {
              "ENTECAVIR": "ENTECAVIR"
            },
            "AVPAK": {
              "ENTECAVIR": "ENTECAVIR"
            },
            "B-M SQUIBB U.S. (PRIMARY CARE)": {
              "BARACLUDE": "BARACLUDE"
            },
            "BRECKENRIDGE": {
              "ENTECAVIR": "ENTECAVIR"
            },
            "CAMBER PHARMACEUTICALS": {
              "ENTECAVIR": "ENTECAVIR",
              "LAMIVUDINE": "LAMIVUDINE"
            },
            "CIPLA USA": {
              "ENTECAVIR": "ENTECAVIR"
            },
            "EPIC PHARMA": {
              "ENTECAVIR": "ENTECAVIR"
            },
            "GILEAD SCIENCES": {
              "HEPSERA": "HEPSERA",
              "VEMLIDY": "VEMLIDY"
            },
            "GLAXO SMITH KLINE": {
              "EPIVIR HBV": "EPIVIR HBV"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "ENTECAVIR": "ENTECAVIR"
            },
            "MARLEX PHARMACEUTICALS": {
              "ENTECAVIR": "ENTECAVIR"
            },
            "MYLAN": {
              "LAMIVUDINE": "LAMIVUDINE"
            },
            "NORTHSTAR RX": {
              "ENTECAVIR": "ENTECAVIR"
            },
            "NOVARTIS": {
              "TYZEKA": "TYZEKA"
            },
            "PAR PHARMACEUTICAL": {
              "ENTECAVIR": "ENTECAVIR"
            },
            "PRASCO LABORATORIES": {
              "LAMIVUDINE": "LAMIVUDINE"
            },
            "SIGMAPHARM LABORATORIES": {
              "ADEFOVIR DIPIVOXIL": "ADEFOVIR DIPIVOXIL"
            },
            "SOLCO HEALTHCARE": {
              "ENTECAVIR": "ENTECAVIR"
            },
            "TEVA PHARMACEUTICALS USA": {
              "ENTECAVIR": "ENTECAVIR"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "ENTECAVIR": "ENTECAVIR"
            }
          },
          "Hepatitis C Agent - Combinations": {
            "ABBVIE": {
              "MAVYRET": "MAVYRET",
              "TECHNIVIE": "TECHNIVIE",
              "VIEKIRA PAK": "VIEKIRA PAK",
              "VIEKIRA XR": "VIEKIRA XR"
            },
            "ASEGUA THERAPEUTICS": {
              "LEDIPASVIR-SOFOSBUVIR": "LEDIPASVIR-SOFOSBUVIR",
              "SOFOSBUVIR-VELPATASVIR": "SOFOSBUVIR-VELPATASVIR"
            },
            "GILEAD SCIENCES": {
              "EPCLUSA": "EPCLUSA",
              "HARVONI": "HARVONI",
              "VOSEVI": "VOSEVI"
            },
            "MERCK SHARP & DOHME": {
              "ZEPATIER": "ZEPATIER"
            }
          },
          "Hepatitis C Agents": {
            "ABBVIE": {
              "MODERIBA": "MODERIBA",
              "MODERIBA (1000 MG PACK)": "MODERIBA (1000 MG PACK)",
              "MODERIBA (1200 MG PACK)": "MODERIBA (1200 MG PACK)",
              "MODERIBA (600 MG PACK)": "MODERIBA (600 MG PACK)",
              "MODERIBA (800 MG PACK)": "MODERIBA (800 MG PACK)"
            },
            "AMERICAN HEALTH PACKAGING": {
              "RIBAVIRIN": "RIBAVIRIN"
            },
            "AUROBINDO PHARMA": {
              "RIBAVIRIN": "RIBAVIRIN"
            },
            "AVKARE": {
              "RIBAVIRIN": "RIBAVIRIN"
            },
            "B-M SQUIBB U.S. (PRIMARY CARE)": {
              "DAKLINZA": "DAKLINZA"
            },
            "GENENTECH": {
              "COPEGUS": "COPEGUS",
              "PEGASYS": "PEGASYS",
              "PEGASYS PROCLICK": "PEGASYS PROCLICK"
            },
            "GILEAD SCIENCES": {
              "SOVALDI": "SOVALDI"
            },
            "JANSSEN PRODUCTS": {
              "OLYSIO": "OLYSIO"
            },
            "KADMON PHARMACEUTICALS": {
              "RIBASPHERE": "RIBASPHERE",
              "RIBASPHERE RIBAPAK (1000 PACK)": "RIBASPHERE RIBAPAK (1000 PACK)",
              "RIBASPHERE RIBAPAK (1200 PACK)": "RIBASPHERE RIBAPAK (1200 PACK)",
              "RIBASPHERE RIBAPAK (600 PACK)": "RIBASPHERE RIBAPAK (600 PACK)",
              "RIBASPHERE RIBAPAK (800 PACK)": "RIBASPHERE RIBAPAK (800 PACK)",
              "RIBAVIRIN": "RIBAVIRIN"
            },
            "MERCK SHARP & DOHME": {
              "PEG-INTRON": "PEG-INTRON",
              "PEG-INTRON REDIPEN": "PEG-INTRON REDIPEN",
              "PEG-INTRON REDIPEN PAK 4": "PEG-INTRON REDIPEN PAK 4",
              "PEGINTRON": "PEGINTRON",
              "REBETOL": "REBETOL",
              "VICTRELIS": "VICTRELIS"
            },
            "PRX PHARMACEUTICALS": {
              "RIBATAB (1000 MG PACK)": "RIBATAB (1000 MG PACK)",
              "RIBATAB (1200 MG PACK)": "RIBATAB (1200 MG PACK)",
              "RIBATAB (800 MG PACK)": "RIBATAB (800 MG PACK)"
            },
            "RICHMOND PHARMACEUTICALS": {
              "RIBAVIRIN": "RIBAVIRIN"
            },
            "SANDOZ": {
              "RIBAVIRIN": "RIBAVIRIN"
            },
            "TEVA PHARMACEUTICALS USA": {
              "RIBAVIRIN": "RIBAVIRIN"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "RIBAVIRIN": "RIBAVIRIN"
            }
          }
        },
        "Herpes Agents": {
          "Herpes Agents - Purine Analogues": {
            "A-S MEDICATION SOLUTIONS": {
              "ACYCLOVIR": "ACYCLOVIR",
              "VALACYCLOVIR HCL": "VALACYCLOVIR HCL",
              "VALTREX": "VALTREX"
            },
            "ACETRIS HEALTH": {
              "VALACYCLOVIR HCL": "VALACYCLOVIR HCL"
            },
            "ACTAVIS": {
              "VALACYCLOVIR HCL": "VALACYCLOVIR HCL"
            },
            "ACTAVIS MID ATLANTIC": {
              "ACYCLOVIR": "ACYCLOVIR"
            },
            "AIDAREX PHARMACEUTICALS": {
              "ACYCLOVIR": "ACYCLOVIR",
              "VALACYCLOVIR HCL": "VALACYCLOVIR HCL"
            },
            "ALTURA PHARMACEUTICALS": {
              "ACYCLOVIR": "ACYCLOVIR"
            },
            "AMERICAN HEALTH PACKAGING": {
              "ACYCLOVIR": "ACYCLOVIR",
              "VALACYCLOVIR HCL": "VALACYCLOVIR HCL"
            },
            "APHENA PHARMA SOLUTIONS": {
              "ACYCLOVIR": "ACYCLOVIR",
              "VALACYCLOVIR HCL": "VALACYCLOVIR HCL"
            },
            "APOTEX": {
              "ACYCLOVIR": "ACYCLOVIR"
            },
            "ATLANTIC BIOLOGICALS": {
              "ACYCLOVIR": "ACYCLOVIR"
            },
            "AUROBINDO PHARMA": {
              "VALACYCLOVIR HCL": "VALACYCLOVIR HCL"
            },
            "AUROMEDICS PHARMA": {
              "ACYCLOVIR SODIUM": "ACYCLOVIR SODIUM"
            },
            "AVET PHARMACEUTICALS": {
              "ACYCLOVIR": "ACYCLOVIR"
            },
            "AVKARE": {
              "ACYCLOVIR": "ACYCLOVIR",
              "VALACYCLOVIR HCL": "VALACYCLOVIR HCL"
            },
            "AVPAK": {
              "ACYCLOVIR": "ACYCLOVIR",
              "VALACYCLOVIR HCL": "VALACYCLOVIR HCL"
            },
            "BIONPHARMA": {
              "ACYCLOVIR": "ACYCLOVIR"
            },
            "BLENHEIM PHARMACAL": {
              "ACYCLOVIR": "ACYCLOVIR",
              "VALACYCLOVIR HCL": "VALACYCLOVIR HCL"
            },
            "BRYANT RANCH PREPACK": {
              "ACYCLOVIR": "ACYCLOVIR",
              "VALACYCLOVIR HCL": "VALACYCLOVIR HCL",
              "VALTREX": "VALTREX"
            },
            "CADISTA": {
              "VALACYCLOVIR HCL": "VALACYCLOVIR HCL"
            },
            "CAMBER PHARMACEUTICALS": {
              "ACYCLOVIR": "ACYCLOVIR",
              "VALACYCLOVIR HCL": "VALACYCLOVIR HCL"
            },
            "CARLSBAD TECHNOLOGIES": {
              "ACYCLOVIR": "ACYCLOVIR"
            },
            "CIPLA USA": {
              "VALACYCLOVIR HCL": "VALACYCLOVIR HCL"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "ACYCLOVIR": "ACYCLOVIR",
              "VALACYCLOVIR HCL": "VALACYCLOVIR HCL"
            },
            "DENTON PHARMA": {
              "ACYCLOVIR": "ACYCLOVIR",
              "VALACYCLOVIR HCL": "VALACYCLOVIR HCL"
            },
            "DIRECT RX": {
              "ACYCLOVIR": "ACYCLOVIR",
              "VALACYCLOVIR HCL": "VALACYCLOVIR HCL"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "VALACYCLOVIR HCL": "VALACYCLOVIR HCL"
            },
            "EPI HEALTH": {
              "SITAVIG": "SITAVIG"
            },
            "FRESENIUS KABI USA": {
              "ACYCLOVIR SODIUM": "ACYCLOVIR SODIUM"
            },
            "GLAXO SMITH KLINE": {
              "VALTREX": "VALTREX"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "ACYCLOVIR": "ACYCLOVIR"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "ACYCLOVIR": "ACYCLOVIR",
              "VALACYCLOVIR HCL": "VALACYCLOVIR HCL",
              "VALTREX": "VALTREX",
              "ZOVIRAX": "ZOVIRAX"
            },
            "HI-TECH": {
              "ACYCLOVIR": "ACYCLOVIR"
            },
            "HIKMA": {
              "VALACYCLOVIR HCL": "VALACYCLOVIR HCL"
            },
            "LIBERTY PHARMACEUTICAL": {
              "ACYCLOVIR": "ACYCLOVIR",
              "VALTREX": "VALTREX"
            },
            "MAJOR PHARMACEUTICALS": {
              "ACYCLOVIR": "ACYCLOVIR",
              "VALACYCLOVIR HCL": "VALACYCLOVIR HCL"
            },
            "MEDVANTX": {
              "ACYCLOVIR": "ACYCLOVIR"
            },
            "MYLAN": {
              "ACYCLOVIR": "ACYCLOVIR",
              "VALACYCLOVIR HCL": "VALACYCLOVIR HCL",
              "ZOVIRAX": "ZOVIRAX"
            },
            "MYLAN INSTITUTIONAL": {
              "VALACYCLOVIR HCL": "VALACYCLOVIR HCL"
            },
            "NIVAGEN PHARMACEUTICALS": {
              "ACYCLOVIR": "ACYCLOVIR"
            },
            "NORTHSTAR RX": {
              "VALACYCLOVIR HCL": "VALACYCLOVIR HCL"
            },
            "NOVAPLUS/AUROMEDICS": {
              "ACYCLOVIR SODIUM": "ACYCLOVIR SODIUM"
            },
            "NOVITIUM PHARMA": {
              "ACYCLOVIR": "ACYCLOVIR"
            },
            "NUCARE PHARMACEUTICALS": {
              "ACYCLOVIR": "ACYCLOVIR"
            },
            "PAR PHARMACEUTICAL": {
              "ACYCLOVIR": "ACYCLOVIR"
            },
            "PDRX PHARMACEUTICAL": {
              "ACYCLOVIR": "ACYCLOVIR",
              "VALACYCLOVIR HCL": "VALACYCLOVIR HCL",
              "VALTREX": "VALTREX",
              "ZOVIRAX": "ZOVIRAX"
            },
            "PHARMEDIX": {
              "ACYCLOVIR": "ACYCLOVIR",
              "VALACYCLOVIR HCL": "VALACYCLOVIR HCL"
            },
            "PHARMPAK": {
              "ACYCLOVIR": "ACYCLOVIR"
            },
            "PREFERRED PHARMACEUTICALS": {
              "ACYCLOVIR": "ACYCLOVIR",
              "VALACYCLOVIR HCL": "VALACYCLOVIR HCL"
            },
            "PRESTIUM PHARMA": {
              "ZOVIRAX": "ZOVIRAX"
            },
            "PROFICIENT RX": {
              "ACYCLOVIR": "ACYCLOVIR",
              "VALACYCLOVIR HCL": "VALACYCLOVIR HCL"
            },
            "QUALITY CARE": {
              "ACYCLOVIR": "ACYCLOVIR",
              "VALTREX": "VALTREX"
            },
            "QUALITY CARE PRODUCTS": {
              "ACYCLOVIR": "ACYCLOVIR",
              "VALACYCLOVIR HCL": "VALACYCLOVIR HCL"
            },
            "QUINN PHARMACEUTICALS": {
              "ACYCLOVIR": "ACYCLOVIR"
            },
            "RISING PHARMACEUTICALS": {
              "VALACYCLOVIR HCL": "VALACYCLOVIR HCL"
            },
            "RXCHANGE CO": {
              "ACYCLOVIR": "ACYCLOVIR",
              "VALTREX": "VALTREX"
            },
            "SANDOZ": {
              "VALACYCLOVIR HCL": "VALACYCLOVIR HCL"
            },
            "SKY PACKAGING": {
              "ACYCLOVIR": "ACYCLOVIR",
              "VALACYCLOVIR HCL": "VALACYCLOVIR HCL"
            },
            "ST MARY'S MPP": {
              "ACYCLOVIR": "ACYCLOVIR"
            },
            "SUN PHARMACEUTICALS": {
              "ACYCLOVIR": "ACYCLOVIR",
              "VALACYCLOVIR HCL": "VALACYCLOVIR HCL"
            },
            "TEVA PHARMACEUTICALS USA": {
              "ACYCLOVIR": "ACYCLOVIR",
              "VALACYCLOVIR HCL": "VALACYCLOVIR HCL"
            },
            "YILING PHARMACEUTICAL": {
              "ACYCLOVIR": "ACYCLOVIR"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "ACYCLOVIR": "ACYCLOVIR"
            }
          },
          "Herpes Agents - Thymidine Analogues": {
            "A-S MEDICATION SOLUTIONS": {
              "FAMCICLOVIR": "FAMCICLOVIR"
            },
            "ACTAVIS PHARMA": {
              "FAMCICLOVIR": "FAMCICLOVIR"
            },
            "AMERICAN HEALTH PACKAGING": {
              "FAMCICLOVIR": "FAMCICLOVIR"
            },
            "APOTEX": {
              "FAMCICLOVIR": "FAMCICLOVIR"
            },
            "AUROBINDO PHARMA": {
              "FAMCICLOVIR": "FAMCICLOVIR"
            },
            "AVKARE": {
              "FAMCICLOVIR": "FAMCICLOVIR"
            },
            "AVPAK": {
              "FAMCICLOVIR": "FAMCICLOVIR"
            },
            "CAMBER PHARMACEUTICALS": {
              "FAMCICLOVIR": "FAMCICLOVIR"
            },
            "CIPLA USA": {
              "FAMCICLOVIR": "FAMCICLOVIR"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "FAMCICLOVIR": "FAMCICLOVIR"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "FAMCICLOVIR": "FAMCICLOVIR"
            },
            "HIKMA": {
              "FAMCICLOVIR": "FAMCICLOVIR"
            },
            "LIBERTY PHARMACEUTICAL": {
              "FAMCICLOVIR": "FAMCICLOVIR"
            },
            "MACLEODS PHARMACEUTICALS": {
              "FAMCICLOVIR": "FAMCICLOVIR"
            },
            "MYLAN": {
              "FAMCICLOVIR": "FAMCICLOVIR"
            },
            "NORTHSTAR RX": {
              "FAMCICLOVIR": "FAMCICLOVIR"
            },
            "NOVARTIS": {
              "FAMVIR": "FAMVIR"
            },
            "PDRX PHARMACEUTICAL": {
              "FAMCICLOVIR": "FAMCICLOVIR"
            },
            "PHARMPAK": {
              "FAMVIR": "FAMVIR"
            },
            "PROFICIENT RX": {
              "FAMCICLOVIR": "FAMCICLOVIR"
            },
            "QUALITY CARE": {
              "FAMVIR": "FAMVIR"
            },
            "TEVA PHARMACEUTICALS USA": {
              "FAMCICLOVIR": "FAMCICLOVIR"
            }
          }
        },
        "Influenza Agents": {
          "Influenza Agents": {
            "A-S MEDICATION SOLUTIONS": {
              "RIMANTADINE HCL": "RIMANTADINE HCL"
            },
            "AMNEAL PHARMACEUTICALS": {
              "RIMANTADINE HCL": "RIMANTADINE HCL"
            },
            "BRYANT RANCH PREPACK": {
              "RIMANTADINE HCL": "RIMANTADINE HCL"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "FLUMADINE": "FLUMADINE"
            },
            "PDRX PHARMACEUTICAL": {
              "RIMANTADINE HCL": "RIMANTADINE HCL"
            },
            "QUALITY CARE": {
              "RIMANTADINE HCL": "RIMANTADINE HCL"
            },
            "SUN PHARMACEUTICALS": {
              "FLUMADINE": "FLUMADINE"
            }
          },
          "Neuraminidase Inhibitors": {
            "A-S MEDICATION SOLUTIONS": {
              "OSELTAMIVIR PHOSPHATE": "OSELTAMIVIR PHOSPHATE",
              "RELENZA DISKHALER": "RELENZA DISKHALER",
              "TAMIFLU": "TAMIFLU"
            },
            "AIDAREX PHARMACEUTICALS": {
              "OSELTAMIVIR PHOSPHATE": "OSELTAMIVIR PHOSPHATE",
              "TAMIFLU": "TAMIFLU"
            },
            "AJANTA PHARMA LIMITED": {
              "OSELTAMIVIR PHOSPHATE": "OSELTAMIVIR PHOSPHATE"
            },
            "ALEMBIC PHARMACEUTICALS": {
              "OSELTAMIVIR PHOSPHATE": "OSELTAMIVIR PHOSPHATE"
            },
            "ALTURA PHARMACEUTICALS": {
              "TAMIFLU": "TAMIFLU"
            },
            "ALVOGEN": {
              "OSELTAMIVIR PHOSPHATE": "OSELTAMIVIR PHOSPHATE"
            },
            "AMNEAL PHARMACEUTICALS": {
              "OSELTAMIVIR PHOSPHATE": "OSELTAMIVIR PHOSPHATE"
            },
            "AVKARE": {
              "OSELTAMIVIR PHOSPHATE": "OSELTAMIVIR PHOSPHATE"
            },
            "BIOCRYST PHARMACEUTICALS": {
              "RAPIVAB": "RAPIVAB"
            },
            "BIOCSL": {
              "RAPIVAB": "RAPIVAB"
            },
            "CAMBER PHARMACEUTICALS": {
              "OSELTAMIVIR PHOSPHATE": "OSELTAMIVIR PHOSPHATE"
            },
            "GENENTECH": {
              "TAMIFLU": "TAMIFLU"
            },
            "GLAXO SMITH KLINE": {
              "RELENZA DISKHALER": "RELENZA DISKHALER"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "TAMIFLU": "TAMIFLU"
            },
            "LUPIN PHARMACEUTICALS": {
              "OSELTAMIVIR PHOSPHATE": "OSELTAMIVIR PHOSPHATE"
            },
            "MACLEODS PHARMACEUTICALS": {
              "OSELTAMIVIR PHOSPHATE": "OSELTAMIVIR PHOSPHATE"
            },
            "NORTHSTAR RX": {
              "OSELTAMIVIR PHOSPHATE": "OSELTAMIVIR PHOSPHATE"
            },
            "NOVADOZ PHARMACEUTICALS": {
              "OSELTAMIVIR PHOSPHATE": "OSELTAMIVIR PHOSPHATE"
            },
            "PHARMEDIX": {
              "OSELTAMIVIR PHOSPHATE": "OSELTAMIVIR PHOSPHATE"
            },
            "PREFERRED PHARMACEUTICALS": {
              "OSELTAMIVIR PHOSPHATE": "OSELTAMIVIR PHOSPHATE"
            },
            "PROFICIENT RX": {
              "OSELTAMIVIR PHOSPHATE": "OSELTAMIVIR PHOSPHATE"
            },
            "QUALITY CARE": {
              "TAMIFLU": "TAMIFLU"
            },
            "QUALITY CARE PRODUCTS": {
              "OSELTAMIVIR PHOSPHATE": "OSELTAMIVIR PHOSPHATE"
            },
            "SKY PACKAGING": {
              "OSELTAMIVIR PHOSPHATE": "OSELTAMIVIR PHOSPHATE"
            },
            "STRIDES PHARMA": {
              "OSELTAMIVIR PHOSPHATE": "OSELTAMIVIR PHOSPHATE"
            },
            "TEVA PHARMACEUTICALS USA": {
              "OSELTAMIVIR PHOSPHATE": "OSELTAMIVIR PHOSPHATE"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "OSELTAMIVIR PHOSPHATE": "OSELTAMIVIR PHOSPHATE"
            }
          },
          "PA Endonuclease Inhibitors": {
            "A-S MEDICATION SOLUTIONS": {
              "XOFLUZA (80 MG DOSE)": "XOFLUZA (80 MG DOSE)"
            },
            "GENENTECH": {
              "XOFLUZA (40 MG DOSE)": "XOFLUZA (40 MG DOSE)",
              "XOFLUZA (80 MG DOSE)": "XOFLUZA (80 MG DOSE)"
            }
          }
        },
        "Misc. Antivirals": {
          "Misc. Antivirals": {
            "FUJIFILM TOYAMA": {
              "FAVIPIRAVIR": "FAVIPIRAVIR"
            },
            "GILEAD SCIENCES": {
              "REMDESIVIR": "REMDESIVIR",
              "VEKLURY": "VEKLURY"
            }
          }
        },
        "Respiratory Syncytial Virus (RSV) Agents": {
          "RSV Agents - Nucleoside Analogues": {
            "BAUSCH HEALTH": {
              "VIRAZOLE": "VIRAZOLE"
            },
            "CAMERON PHARMACEUTICALS": {
              "RIBAVIRIN": "RIBAVIRIN"
            },
            "OCEANSIDE PHARMACEUTICALS": {
              "RIBAVIRIN": "RIBAVIRIN"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "RIBAVIRIN": "RIBAVIRIN"
            }
          }
        }
      },
      "BETA BLOCKERS": {
        "Alpha-Beta Blockers": {
          "Alpha-Beta Blockers": {
            "A-S MEDICATION SOLUTIONS": {
              "CARVEDILOL": "CARVEDILOL",
              "LABETALOL HCL": "LABETALOL HCL"
            },
            "ACETRIS HEALTH": {
              "CARVEDILOL": "CARVEDILOL"
            },
            "ACTAVIS PHARMA": {
              "LABETALOL HCL": "LABETALOL HCL"
            },
            "AIDAREX PHARMACEUTICALS": {
              "CARVEDILOL": "CARVEDILOL",
              "LABETALOL HCL": "LABETALOL HCL"
            },
            "AKORN": {
              "LABETALOL HCL": "LABETALOL HCL"
            },
            "ALVOGEN": {
              "LABETALOL HCL": "LABETALOL HCL"
            },
            "AMERICAN HEALTH PACKAGING": {
              "CARVEDILOL": "CARVEDILOL",
              "LABETALOL HCL": "LABETALOL HCL"
            },
            "AMICI PHARMACEUTICALS": {
              "LABETALOL HCL": "LABETALOL HCL"
            },
            "APHENA PHARMA SOLUTIONS": {
              "CARVEDILOL": "CARVEDILOL",
              "COREG": "COREG"
            },
            "APOTEX": {
              "CARVEDILOL PHOSPHATE ER": "CARVEDILOL PHOSPHATE ER"
            },
            "AUROBINDO PHARMA": {
              "CARVEDILOL": "CARVEDILOL"
            },
            "AUROBINDO PHARMA USA": {
              "CARVEDILOL": "CARVEDILOL"
            },
            "AVET PHARMACEUTICALS": {
              "LABETALOL HCL": "LABETALOL HCL"
            },
            "AVKARE": {
              "CARVEDILOL": "CARVEDILOL"
            },
            "BAYSHORE PHARMACEUTICALS": {
              "CARVEDILOL": "CARVEDILOL"
            },
            "BLENHEIM PHARMACAL": {
              "CARVEDILOL": "CARVEDILOL"
            },
            "BLUE POINT LABORATORIES": {
              "CARVEDILOL": "CARVEDILOL",
              "LABETALOL HCL": "LABETALOL HCL"
            },
            "BRECKENRIDGE": {
              "LABETALOL HCL": "LABETALOL HCL"
            },
            "BRYANT RANCH PREPACK": {
              "CARVEDILOL": "CARVEDILOL",
              "LABETALOL HCL": "LABETALOL HCL"
            },
            "CARDINAL HEALTH REPACKAGING SO": {
              "CARVEDILOL": "CARVEDILOL"
            },
            "CIVICA": {
              "LABETALOL HCL": "LABETALOL HCL"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "CARVEDILOL": "CARVEDILOL"
            },
            "COUNTY LINE PHARMACEUTICALS": {
              "LABETALOL HCL": "LABETALOL HCL"
            },
            "DIRECT RX": {
              "CARVEDILOL": "CARVEDILOL"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "CARVEDILOL": "CARVEDILOL"
            },
            "EYWA PHARMA": {
              "LABETALOL HCL": "LABETALOL HCL"
            },
            "FOSUN PHARMA USA": {
              "LABETALOL HCL": "LABETALOL HCL"
            },
            "GLAXO SMITH KLINE": {
              "COREG": "COREG",
              "COREG CR": "COREG CR"
            },
            "GLENMARK PHARMACEUTICALS": {
              "CARVEDILOL": "CARVEDILOL"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "CARVEDILOL": "CARVEDILOL",
              "CARVEDILOL PHOSPHATE ER": "CARVEDILOL PHOSPHATE ER",
              "LABETALOL HCL": "LABETALOL HCL"
            },
            "HIKMA": {
              "LABETALOL HCL": "LABETALOL HCL"
            },
            "HOSPIRA": {
              "LABETALOL HCL": "LABETALOL HCL"
            },
            "LEGACY PHARMACEUTICAL PKG": {
              "CARVEDILOL": "CARVEDILOL"
            },
            "LIBERTY PHARMACEUTICAL": {
              "LABETALOL HCL": "LABETALOL HCL"
            },
            "MAJOR PHARMACEUTICALS": {
              "CARVEDILOL": "CARVEDILOL",
              "LABETALOL HCL": "LABETALOL HCL"
            },
            "MARLEX PHARMACEUTICALS": {
              "CARVEDILOL": "CARVEDILOL",
              "LABETALOL HCL": "LABETALOL HCL"
            },
            "METHOD PHARMACEUTICALS": {
              "LABETALOL HCL": "LABETALOL HCL"
            },
            "MYLAN": {
              "CARVEDILOL": "CARVEDILOL"
            },
            "MYLAN INSTITUTIONAL": {
              "CARVEDILOL": "CARVEDILOL",
              "LABETALOL HCL": "LABETALOL HCL"
            },
            "NEPHRON STERILE COMPOUNDING": {
              "LABETALOL HCL": "LABETALOL HCL"
            },
            "NOVAPLUS/HOSPIRA": {
              "LABETALOL HCL": "LABETALOL HCL"
            },
            "NUCARE PHARMACEUTICALS": {
              "CARVEDILOL": "CARVEDILOL"
            },
            "PAR PHARMACEUTICAL": {
              "LABETALOL HCL": "LABETALOL HCL"
            },
            "PDRX PHARMACEUTICAL": {
              "CARVEDILOL": "CARVEDILOL",
              "COREG": "COREG"
            },
            "PHARMEDIUM SERVICES": {
              "LABETALOL HCL": "LABETALOL HCL",
              "LABETALOL HCL-DEXTROSE": "LABETALOL HCL-DEXTROSE",
              "LABETALOL HCL-SODIUM CHLORIDE": "LABETALOL HCL-SODIUM CHLORIDE"
            },
            "PREFERRED PHARMACEUTICALS": {
              "CARVEDILOL": "CARVEDILOL"
            },
            "PROFICIENT RX": {
              "CARVEDILOL": "CARVEDILOL",
              "LABETALOL HCL": "LABETALOL HCL"
            },
            "QUALITY CARE": {
              "CARVEDILOL": "CARVEDILOL",
              "COREG": "COREG",
              "COREG CR": "COREG CR",
              "LABETALOL HCL": "LABETALOL HCL"
            },
            "QUALITY CARE PRODUCTS": {
              "CARVEDILOL": "CARVEDILOL"
            },
            "QUVA PHARMA": {
              "LABETALOL HCL": "LABETALOL HCL"
            },
            "SAGENT PHARMACEUTICAL": {
              "LABETALOL HCL": "LABETALOL HCL"
            },
            "SANDOZ": {
              "CARVEDILOL": "CARVEDILOL",
              "LABETALOL HCL": "LABETALOL HCL"
            },
            "SCA PHARMACEUTICALS": {
              "LABETALOL HCL": "LABETALOL HCL"
            },
            "SKY PACKAGING": {
              "LABETALOL HCL": "LABETALOL HCL"
            },
            "SOLCO HEALTHCARE": {
              "CARVEDILOL": "CARVEDILOL"
            },
            "ST MARY'S MPP": {
              "CARVEDILOL": "CARVEDILOL",
              "LABETALOL HCL": "LABETALOL HCL"
            },
            "SUN PHARMACEUTICALS": {
              "CARVEDILOL": "CARVEDILOL",
              "CARVEDILOL PHOSPHATE ER": "CARVEDILOL PHOSPHATE ER"
            },
            "TEVA PHARMACEUTICALS USA": {
              "CARVEDILOL": "CARVEDILOL",
              "LABETALOL HCL": "LABETALOL HCL"
            },
            "TWI PHARMACEUTICALS": {
              "LABETALOL HCL": "LABETALOL HCL"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "CARVEDILOL": "CARVEDILOL",
              "LABETALOL HCL": "LABETALOL HCL"
            }
          }
        },
        "Beta Blockers Cardio-Selective": {
          "Beta Blockers Cardio-Selective": {
            "A-S MEDICATION SOLUTIONS": {
              "ATENOLOL": "ATENOLOL",
              "BYSTOLIC": "BYSTOLIC",
              "METOPROLOL SUCCINATE ER": "METOPROLOL SUCCINATE ER",
              "METOPROLOL TARTRATE": "METOPROLOL TARTRATE",
              "TOPROL XL": "TOPROL XL"
            },
            "ACETRIS HEALTH": {
              "METOPROLOL TARTRATE": "METOPROLOL TARTRATE"
            },
            "ACTAVIS": {
              "METOPROLOL SUCCINATE ER": "METOPROLOL SUCCINATE ER"
            },
            "ACTAVIS PHARMA": {
              "METOPROLOL SUCCINATE ER": "METOPROLOL SUCCINATE ER",
              "METOPROLOL TARTRATE": "METOPROLOL TARTRATE"
            },
            "AIDAREX PHARMACEUTICALS": {
              "ATENOLOL": "ATENOLOL",
              "METOPROLOL SUCCINATE ER": "METOPROLOL SUCCINATE ER",
              "METOPROLOL TARTRATE": "METOPROLOL TARTRATE"
            },
            "ALEMBIC PHARMACEUTICALS": {
              "METOPROLOL TARTRATE": "METOPROLOL TARTRATE"
            },
            "ALLERGAN": {
              "BYSTOLIC": "BYSTOLIC"
            },
            "ALMAJECT": {
              "METOPROLOL TARTRATE": "METOPROLOL TARTRATE"
            },
            "ALMATICA": {
              "TENORMIN": "TENORMIN"
            },
            "ALTURA PHARMACEUTICALS": {
              "ATENOLOL": "ATENOLOL",
              "METOPROLOL TARTRATE": "METOPROLOL TARTRATE"
            },
            "ALVOGEN": {
              "METOPROLOL TARTRATE": "METOPROLOL TARTRATE"
            },
            "AMERICAN HEALTH PACKAGING": {
              "ATENOLOL": "ATENOLOL",
              "BISOPROLOL FUMARATE": "BISOPROLOL FUMARATE",
              "METOPROLOL SUCCINATE ER": "METOPROLOL SUCCINATE ER",
              "METOPROLOL TARTRATE": "METOPROLOL TARTRATE"
            },
            "AMNEAL PHARMACEUTICALS": {
              "ACEBUTOLOL HCL": "ACEBUTOLOL HCL"
            },
            "APHENA PHARMA SOLUTIONS": {
              "ATENOLOL": "ATENOLOL",
              "METOPROLOL SUCCINATE ER": "METOPROLOL SUCCINATE ER",
              "METOPROLOL TARTRATE": "METOPROLOL TARTRATE",
              "TOPROL XL": "TOPROL XL"
            },
            "AQ PHARMACEUTICALS": {
              "METOPROLOL TARTRATE": "METOPROLOL TARTRATE"
            },
            "ASTRAZENECA LP": {
              "TOPROL XL": "TOPROL XL"
            },
            "ATHENEX PHARMACEUTICAL": {
              "METOPROLOL TARTRATE": "METOPROLOL TARTRATE"
            },
            "AUROBINDO PHARMA": {
              "ATENOLOL": "ATENOLOL",
              "BISOPROLOL FUMARATE": "BISOPROLOL FUMARATE",
              "METOPROLOL TARTRATE": "METOPROLOL TARTRATE"
            },
            "AUROMEDICS PHARMA": {
              "ESMOLOL HCL": "ESMOLOL HCL"
            },
            "AVKARE": {
              "ACEBUTOLOL HCL": "ACEBUTOLOL HCL",
              "ATENOLOL": "ATENOLOL"
            },
            "AVPAK": {
              "ACEBUTOLOL HCL": "ACEBUTOLOL HCL",
              "METOPROLOL SUCCINATE ER": "METOPROLOL SUCCINATE ER"
            },
            "AZURITY PHARMACEUTICALS": {
              "FIRST - METOPROLOL": "FIRST - METOPROLOL",
              "FIRST-ATENOLOL": "FIRST-ATENOLOL"
            },
            "BAXTER HEALTHCARE CORP": {
              "METOPROLOL TARTRATE": "METOPROLOL TARTRATE"
            },
            "BAXTER HEALTHCARE CORPORATION": {
              "BREVIBLOC": "BREVIBLOC",
              "BREVIBLOC IN NACL": "BREVIBLOC IN NACL",
              "BREVIBLOC PREMIXED": "BREVIBLOC PREMIXED",
              "BREVIBLOC PREMIXED DS": "BREVIBLOC PREMIXED DS",
              "ESMOLOL HCL": "ESMOLOL HCL"
            },
            "BLENHEIM PHARMACAL": {
              "ATENOLOL": "ATENOLOL",
              "METOPROLOL TARTRATE": "METOPROLOL TARTRATE"
            },
            "BLU PHARMACEUTICALS": {
              "BETAXOLOL HCL": "BETAXOLOL HCL"
            },
            "BLUE POINT LABORATORIES": {
              "METOPROLOL SUCCINATE ER": "METOPROLOL SUCCINATE ER"
            },
            "BRYANT RANCH PREPACK": {
              "ACEBUTOLOL HCL": "ACEBUTOLOL HCL",
              "ATENOLOL": "ATENOLOL",
              "METOPROLOL SUCCINATE ER": "METOPROLOL SUCCINATE ER",
              "METOPROLOL TARTRATE": "METOPROLOL TARTRATE",
              "TOPROL XL": "TOPROL XL"
            },
            "CAMBRIDGE THERAPEUTICS TECHN": {
              "METOPROLOL SUCCINATE ER": "METOPROLOL SUCCINATE ER",
              "METOPROLOL TARTRATE": "METOPROLOL TARTRATE"
            },
            "CARDINAL HEALTH REPACKAGING SO": {
              "METOPROLOL SUCCINATE ER": "METOPROLOL SUCCINATE ER",
              "METOPROLOL TARTRATE": "METOPROLOL TARTRATE"
            },
            "CIPLA USA": {
              "METOPROLOL SUCCINATE ER": "METOPROLOL SUCCINATE ER"
            },
            "CIVICA": {
              "METOPROLOL TARTRATE": "METOPROLOL TARTRATE"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "ATENOLOL": "ATENOLOL",
              "METOPROLOL SUCCINATE ER": "METOPROLOL SUCCINATE ER",
              "METOPROLOL TARTRATE": "METOPROLOL TARTRATE"
            },
            "DENTON PHARMA": {
              "ATENOLOL": "ATENOLOL",
              "METOPROLOL SUCCINATE ER": "METOPROLOL SUCCINATE ER",
              "METOPROLOL TARTRATE": "METOPROLOL TARTRATE"
            },
            "DIRECT RX": {
              "ATENOLOL": "ATENOLOL",
              "BISOPROLOL FUMARATE": "BISOPROLOL FUMARATE",
              "METOPROLOL SUCCINATE ER": "METOPROLOL SUCCINATE ER",
              "METOPROLOL TARTRATE": "METOPROLOL TARTRATE"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "METOPROLOL SUCCINATE ER": "METOPROLOL SUCCINATE ER"
            },
            "EPIC PHARMA": {
              "BETAXOLOL HCL": "BETAXOLOL HCL"
            },
            "FAGRON": {
              "ATENOLOL": "ATENOLOL",
              "ATENOLOL+SYRSPEND SF": "ATENOLOL+SYRSPEND SF",
              "METOPROLOL TARTRATE": "METOPROLOL TARTRATE"
            },
            "FOSUN PHARMA USA": {
              "METOPROLOL TARTRATE": "METOPROLOL TARTRATE"
            },
            "FRESENIUS KABI USA": {
              "ESMOLOL HCL": "ESMOLOL HCL",
              "METOPROLOL TARTRATE": "METOPROLOL TARTRATE"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "ATENOLOL": "ATENOLOL",
              "BETAXOLOL HCL": "BETAXOLOL HCL",
              "METOPROLOL SUCCINATE ER": "METOPROLOL SUCCINATE ER"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "ATENOLOL": "ATENOLOL",
              "METOPROLOL TARTRATE": "METOPROLOL TARTRATE",
              "TENORMIN": "TENORMIN"
            },
            "HIKMA": {
              "METOPROLOL TARTRATE": "METOPROLOL TARTRATE"
            },
            "HOSPIRA": {
              "METOPROLOL TARTRATE": "METOPROLOL TARTRATE"
            },
            "INGENUS PHARMACEUTICALS": {
              "ATENOLOL": "ATENOLOL",
              "METOPROLOL SUCCINATE ER": "METOPROLOL SUCCINATE ER",
              "METOPROLOL TARTRATE": "METOPROLOL TARTRATE"
            },
            "KVK TECH": {
              "BETAXOLOL HCL": "BETAXOLOL HCL"
            },
            "LANNETT": {
              "METOPROLOL SUCCINATE ER": "METOPROLOL SUCCINATE ER"
            },
            "LEGACY PHARMACEUTICAL PKG": {
              "ATENOLOL": "ATENOLOL",
              "METOPROLOL SUCCINATE ER": "METOPROLOL SUCCINATE ER",
              "METOPROLOL TARTRATE": "METOPROLOL TARTRATE"
            },
            "LETCO MEDICAL": {
              "ATENOLOL": "ATENOLOL",
              "METOPROLOL TARTRATE": "METOPROLOL TARTRATE"
            },
            "LIBERTY PHARMACEUTICAL": {
              "ATENOLOL": "ATENOLOL",
              "METOPROLOL TARTRATE": "METOPROLOL TARTRATE"
            },
            "MAJOR PHARMACEUTICALS": {
              "ATENOLOL": "ATENOLOL",
              "METOPROLOL SUCCINATE ER": "METOPROLOL SUCCINATE ER",
              "METOPROLOL TARTRATE": "METOPROLOL TARTRATE"
            },
            "MALLINCKRODT PHARM": {
              "ATENOLOL": "ATENOLOL"
            },
            "MARLEX PHARMACEUTICALS": {
              "ACEBUTOLOL HCL": "ACEBUTOLOL HCL",
              "BETAXOLOL HCL": "BETAXOLOL HCL"
            },
            "MEDISCA": {
              "ATENOLOL": "ATENOLOL",
              "METOPROLOL TARTRATE": "METOPROLOL TARTRATE"
            },
            "MEDVANTX": {
              "ATENOLOL": "ATENOLOL",
              "METOPROLOL TARTRATE": "METOPROLOL TARTRATE"
            },
            "MYLAN": {
              "ACEBUTOLOL HCL": "ACEBUTOLOL HCL",
              "ATENOLOL": "ATENOLOL",
              "BISOPROLOL FUMARATE": "BISOPROLOL FUMARATE",
              "METOPROLOL SUCCINATE ER": "METOPROLOL SUCCINATE ER",
              "METOPROLOL TARTRATE": "METOPROLOL TARTRATE"
            },
            "MYLAN INSTITUTIONAL": {
              "ATENOLOL": "ATENOLOL",
              "ESMOLOL HCL": "ESMOLOL HCL",
              "ESMOLOL HCL-SODIUM CHLORIDE": "ESMOLOL HCL-SODIUM CHLORIDE",
              "METOPROLOL SUCCINATE ER": "METOPROLOL SUCCINATE ER",
              "METOPROLOL TARTRATE": "METOPROLOL TARTRATE"
            },
            "NEW AMERICAN THERAPEUTICS": {
              "TOPROL XL": "TOPROL XL"
            },
            "NEW HORIZON RX GROUP": {
              "METOPROLOL TARTRATE": "METOPROLOL TARTRATE"
            },
            "NORTHSTAR RX": {
              "METOPROLOL SUCCINATE ER": "METOPROLOL SUCCINATE ER"
            },
            "NOVAPLUS/BAXTER HEALTHCARE": {
              "BREVIBLOC IN NACL": "BREVIBLOC IN NACL"
            },
            "NUCARE PHARMACEUTICALS": {
              "ATENOLOL": "ATENOLOL",
              "METOPROLOL TARTRATE": "METOPROLOL TARTRATE"
            },
            "PACK PHARMACEUTICALS, LLC": {
              "ATENOLOL": "ATENOLOL"
            },
            "PAR PHARMACEUTICAL": {
              "METOPROLOL SUCCINATE ER": "METOPROLOL SUCCINATE ER"
            },
            "PCCA": {
              "ACEBUTOLOL HCL": "ACEBUTOLOL HCL",
              "ATENOLOL": "ATENOLOL",
              "METOPROLOL TARTRATE": "METOPROLOL TARTRATE"
            },
            "PDRX PHARMACEUTICAL": {
              "ATENOLOL": "ATENOLOL",
              "LOPRESSOR": "LOPRESSOR",
              "METOPROLOL SUCCINATE ER": "METOPROLOL SUCCINATE ER",
              "METOPROLOL TARTRATE": "METOPROLOL TARTRATE",
              "TENORMIN": "TENORMIN",
              "TOPROL XL": "TOPROL XL"
            },
            "PDRX PHARMACEUTICALS": {
              "METOPROLOL SUCCINATE ER": "METOPROLOL SUCCINATE ER"
            },
            "PHARMEDIUM SERVICES": {
              "ESMOLOL HCL": "ESMOLOL HCL",
              "METOPROLOL TARTRATE": "METOPROLOL TARTRATE"
            },
            "PHARMEDIX": {
              "ATENOLOL": "ATENOLOL"
            },
            "PHARMPAK": {
              "ATENOLOL": "ATENOLOL"
            },
            "PREFERRED PHARMACEUTICALS": {
              "ATENOLOL": "ATENOLOL",
              "BISOPROLOL FUMARATE": "BISOPROLOL FUMARATE",
              "METOPROLOL SUCCINATE ER": "METOPROLOL SUCCINATE ER",
              "METOPROLOL TARTRATE": "METOPROLOL TARTRATE"
            },
            "PROFICIENT RX": {
              "ATENOLOL": "ATENOLOL",
              "BISOPROLOL FUMARATE": "BISOPROLOL FUMARATE",
              "METOPROLOL SUCCINATE ER": "METOPROLOL SUCCINATE ER",
              "METOPROLOL TARTRATE": "METOPROLOL TARTRATE"
            },
            "PROMIUS PHARMA": {
              "SECTRAL": "SECTRAL"
            },
            "QUALITY CARE": {
              "ATENOLOL": "ATENOLOL",
              "BISOPROLOL FUMARATE": "BISOPROLOL FUMARATE",
              "METOPROLOL SUCCINATE ER": "METOPROLOL SUCCINATE ER",
              "METOPROLOL TARTRATE": "METOPROLOL TARTRATE",
              "TOPROL XL": "TOPROL XL"
            },
            "QUALITY CARE PRODUCTS": {
              "ATENOLOL": "ATENOLOL",
              "METOPROLOL SUCCINATE ER": "METOPROLOL SUCCINATE ER",
              "METOPROLOL TARTRATE": "METOPROLOL TARTRATE"
            },
            "RANBAXY LABORATORIES": {
              "KAPSPARGO SPRINKLE": "KAPSPARGO SPRINKLE"
            },
            "RISING PHARMACEUTICALS": {
              "ATENOLOL": "ATENOLOL",
              "METOPROLOL TARTRATE": "METOPROLOL TARTRATE"
            },
            "RXCHANGE CO": {
              "ATENOLOL": "ATENOLOL",
              "METOPROLOL TARTRATE": "METOPROLOL TARTRATE"
            },
            "SAGENT PHARMACEUTICAL": {
              "ESMOLOL HCL": "ESMOLOL HCL",
              "ESMOLOL HCL-SODIUM CHLORIDE": "ESMOLOL HCL-SODIUM CHLORIDE",
              "METOPROLOL TARTRATE": "METOPROLOL TARTRATE"
            },
            "SANDOZ": {
              "ATENOLOL": "ATENOLOL",
              "BISOPROLOL FUMARATE": "BISOPROLOL FUMARATE",
              "METOPROLOL TARTRATE": "METOPROLOL TARTRATE"
            },
            "SKY PACKAGING": {
              "METOPROLOL SUCCINATE ER": "METOPROLOL SUCCINATE ER"
            },
            "SPECTRUM": {
              "ATENOLOL": "ATENOLOL",
              "METOPROLOL TARTRATE": "METOPROLOL TARTRATE"
            },
            "ST MARY'S MPP": {
              "ATENOLOL": "ATENOLOL",
              "METOPROLOL SUCCINATE ER": "METOPROLOL SUCCINATE ER",
              "METOPROLOL TARTRATE": "METOPROLOL TARTRATE"
            },
            "SUN PHARMACEUTICALS": {
              "ATENOLOL": "ATENOLOL",
              "METOPROLOL TARTRATE": "METOPROLOL TARTRATE"
            },
            "TEVA PHARMACEUTICALS USA": {
              "ATENOLOL": "ATENOLOL",
              "METOPROLOL TARTRATE": "METOPROLOL TARTRATE"
            },
            "TEVA/WOMENS HEALTH": {
              "ZEBETA": "ZEBETA"
            },
            "TRUPHARMA": {
              "BISOPROLOL FUMARATE": "BISOPROLOL FUMARATE",
              "METOPROLOL TARTRATE": "METOPROLOL TARTRATE"
            },
            "TWI PHARMACEUTICALS": {
              "METOPROLOL SUCCINATE ER": "METOPROLOL SUCCINATE ER"
            },
            "UNICHEM PHARMACEUTICALS": {
              "ATENOLOL": "ATENOLOL",
              "BISOPROLOL FUMARATE": "BISOPROLOL FUMARATE"
            },
            "UNIT DOSE SERVICES": {
              "METOPROLOL SUCCINATE ER": "METOPROLOL SUCCINATE ER"
            },
            "VA CMOP DALLAS": {
              "ATENOLOL": "ATENOLOL",
              "METOPROLOL TARTRATE": "METOPROLOL TARTRATE"
            },
            "VALIDUS PHARMACEUTICALS": {
              "LOPRESSOR": "LOPRESSOR"
            },
            "VENSUN PHARMACEUTICALS": {
              "METOPROLOL TARTRATE": "METOPROLOL TARTRATE"
            },
            "WG CRITICAL CARE": {
              "ESMOLOL HCL": "ESMOLOL HCL"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "ATENOLOL": "ATENOLOL",
              "METOPROLOL SUCCINATE ER": "METOPROLOL SUCCINATE ER"
            }
          }
        },
        "Beta Blockers Non-Selective": {
          "Beta Blockers Non-Selective": {
            "A-S MEDICATION SOLUTIONS": {
              "PROPRANOLOL HCL": "PROPRANOLOL HCL",
              "PROPRANOLOL HCL ER": "PROPRANOLOL HCL ER",
              "SOTALOL HCL": "SOTALOL HCL"
            },
            "ACTAVIS ELIZABETH": {
              "PROPRANOLOL HCL ER": "PROPRANOLOL HCL ER"
            },
            "ACTAVIS PHARMA": {
              "PROPRANOLOL HCL": "PROPRANOLOL HCL"
            },
            "AIDAREX PHARMACEUTICALS": {
              "PROPRANOLOL HCL": "PROPRANOLOL HCL",
              "SOTALOL HCL": "SOTALOL HCL"
            },
            "AKRIMAX PHARMACEUTICALS": {
              "INDERAL LA": "INDERAL LA",
              "INNOPRAN XL": "INNOPRAN XL"
            },
            "ALTATHERA PHARMACEUTICALS": {
              "SOTALOL HCL": "SOTALOL HCL"
            },
            "ALTURA PHARMACEUTICALS": {
              "PROPRANOLOL HCL": "PROPRANOLOL HCL"
            },
            "AMERICAN HEALTH PACKAGING": {
              "NADOLOL": "NADOLOL",
              "PROPRANOLOL HCL": "PROPRANOLOL HCL",
              "PROPRANOLOL HCL ER": "PROPRANOLOL HCL ER",
              "SOTALOL HCL": "SOTALOL HCL"
            },
            "AMERICAN PHARMA INGREDIENTS": {
              "PROPRANOLOL HCL": "PROPRANOLOL HCL"
            },
            "AMICI PHARMACEUTICALS": {
              "PROPRANOLOL HCL": "PROPRANOLOL HCL"
            },
            "AMNEAL PHARMACEUTICALS": {
              "NADOLOL": "NADOLOL",
              "PROPRANOLOL HCL": "PROPRANOLOL HCL"
            },
            "ANI  PHARMACEUTICALS": {
              "INDERAL LA": "INDERAL LA",
              "INDERAL XL": "INDERAL XL",
              "INNOPRAN XL": "INNOPRAN XL",
              "PINDOLOL": "PINDOLOL",
              "PROPRANOLOL HCL ER": "PROPRANOLOL HCL ER"
            },
            "APHENA PHARMA SOLUTIONS": {
              "PROPRANOLOL HCL": "PROPRANOLOL HCL",
              "SOTALOL HCL": "SOTALOL HCL",
              "SOTALOL HCL (AF)": "SOTALOL HCL (AF)"
            },
            "APOTEX": {
              "SOTALOL HCL": "SOTALOL HCL",
              "SOTALOL HCL (AF)": "SOTALOL HCL (AF)"
            },
            "ARBOR PHARMACEUTICALS": {
              "SOTYLIZE": "SOTYLIZE"
            },
            "AVET PHARMACEUTICALS": {
              "NADOLOL": "NADOLOL",
              "PROPRANOLOL HCL": "PROPRANOLOL HCL"
            },
            "AVKARE": {
              "PROPRANOLOL HCL ER": "PROPRANOLOL HCL ER",
              "SOTALOL HCL (AF)": "SOTALOL HCL (AF)"
            },
            "AVPAK": {
              "PROPRANOLOL HCL": "PROPRANOLOL HCL"
            },
            "BAYSHORE PHARMACEUTICALS": {
              "NADOLOL": "NADOLOL",
              "PINDOLOL": "PINDOLOL",
              "SOTALOL HCL": "SOTALOL HCL",
              "SOTALOL HCL (AF)": "SOTALOL HCL (AF)"
            },
            "BLENHEIM PHARMACAL": {
              "PROPRANOLOL HCL": "PROPRANOLOL HCL",
              "PROPRANOLOL HCL ER": "PROPRANOLOL HCL ER"
            },
            "BLUE POINT LABORATORIES": {
              "NADOLOL": "NADOLOL"
            },
            "BRECKENRIDGE": {
              "PROPRANOLOL HCL ER": "PROPRANOLOL HCL ER"
            },
            "BRYANT RANCH PREPACK": {
              "PROPRANOLOL HCL": "PROPRANOLOL HCL",
              "PROPRANOLOL HCL ER": "PROPRANOLOL HCL ER"
            },
            "CAMBER PHARMACEUTICALS": {
              "NADOLOL": "NADOLOL"
            },
            "CIPLA USA": {
              "NADOLOL": "NADOLOL"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "PROPRANOLOL HCL": "PROPRANOLOL HCL"
            },
            "COVIS PHARMA": {
              "BETAPACE": "BETAPACE",
              "BETAPACE AF": "BETAPACE AF"
            },
            "DIRECT RX": {
              "PROPRANOLOL HCL": "PROPRANOLOL HCL",
              "PROPRANOLOL HCL ER": "PROPRANOLOL HCL ER"
            },
            "EPIC PHARMA": {
              "SOTALOL HCL (AF)": "SOTALOL HCL (AF)"
            },
            "EXELAN PHARMACEUTICALS": {
              "NADOLOL": "NADOLOL"
            },
            "FAGRON": {
              "PROPRANOLOL HCL": "PROPRANOLOL HCL"
            },
            "FRESENIUS KABI USA": {
              "PROPRANOLOL HCL": "PROPRANOLOL HCL"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "PROPRANOLOL HCL": "PROPRANOLOL HCL",
              "PROPRANOLOL HCL ER": "PROPRANOLOL HCL ER",
              "SOTALOL HCL": "SOTALOL HCL",
              "SOTALOL HCL (AF)": "SOTALOL HCL (AF)"
            },
            "GREENSTONE": {
              "NADOLOL": "NADOLOL"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "PROPRANOLOL HCL": "PROPRANOLOL HCL",
              "PROPRANOLOL HCL ER": "PROPRANOLOL HCL ER"
            },
            "HIKMA": {
              "PROPRANOLOL HCL": "PROPRANOLOL HCL"
            },
            "IMPAX GENERICS": {
              "PROPRANOLOL HCL": "PROPRANOLOL HCL"
            },
            "INGENUS PHARMACEUTICALS": {
              "NADOLOL": "NADOLOL"
            },
            "LANNETT": {
              "PROPRANOLOL HCL ER": "PROPRANOLOL HCL ER"
            },
            "LETCO MEDICAL": {
              "TIMOLOL MALEATE": "TIMOLOL MALEATE"
            },
            "LIBERTY PHARMACEUTICAL": {
              "PROPRANOLOL HCL": "PROPRANOLOL HCL",
              "SOTALOL HCL": "SOTALOL HCL"
            },
            "MAJOR PHARMACEUTICALS": {
              "NADOLOL": "NADOLOL",
              "PROPRANOLOL HCL": "PROPRANOLOL HCL"
            },
            "MARLEX PHARMACEUTICALS": {
              "NADOLOL": "NADOLOL",
              "SOTALOL HCL": "SOTALOL HCL",
              "SOTALOL HCL (AF)": "SOTALOL HCL (AF)"
            },
            "MEDISCA": {
              "PROPRANOLOL HCL": "PROPRANOLOL HCL",
              "TIMOLOL MALEATE": "TIMOLOL MALEATE"
            },
            "MIST PHARMACEUTICALS": {
              "INDERAL XL": "INDERAL XL"
            },
            "MYLAN": {
              "NADOLOL": "NADOLOL",
              "PINDOLOL": "PINDOLOL",
              "PROPRANOLOL HCL": "PROPRANOLOL HCL",
              "PROPRANOLOL HCL ER": "PROPRANOLOL HCL ER",
              "SOTALOL HCL (AF)": "SOTALOL HCL (AF)",
              "TIMOLOL MALEATE": "TIMOLOL MALEATE"
            },
            "MYLAN INSTITUTIONAL": {
              "NADOLOL": "NADOLOL",
              "PROPRANOLOL HCL": "PROPRANOLOL HCL"
            },
            "NORTHSTAR RX": {
              "PROPRANOLOL HCL": "PROPRANOLOL HCL"
            },
            "NOSTRUM LABORATORIES": {
              "PINDOLOL": "PINDOLOL"
            },
            "OXFORD PHARMACEUTICALS": {
              "SOTALOL HCL": "SOTALOL HCL"
            },
            "PAR PHARMACEUTICALS": {
              "PROPRANOLOL HCL": "PROPRANOLOL HCL",
              "SOTALOL HCL": "SOTALOL HCL"
            },
            "PCCA": {
              "NADOLOL": "NADOLOL",
              "PROPRANOLOL HCL": "PROPRANOLOL HCL",
              "TIMOLOL MALEATE": "TIMOLOL MALEATE"
            },
            "PDRX PHARMACEUTICAL": {
              "PROPRANOLOL HCL": "PROPRANOLOL HCL",
              "SOTALOL HCL": "SOTALOL HCL"
            },
            "PDRX PHARMACEUTICALS": {
              "PINDOLOL": "PINDOLOL"
            },
            "PIERRE FABRE PHARMACEUTICALS": {
              "HEMANGEOL": "HEMANGEOL"
            },
            "PREFERRED PHARMACEUTICALS": {
              "PROPRANOLOL HCL": "PROPRANOLOL HCL",
              "PROPRANOLOL HCL ER": "PROPRANOLOL HCL ER"
            },
            "PRESCRIPTION SOLUTIONS, INC.": {
              "SOTALOL HCL": "SOTALOL HCL"
            },
            "PROFICIENT RX": {
              "PROPRANOLOL HCL": "PROPRANOLOL HCL",
              "SOTALOL HCL": "SOTALOL HCL"
            },
            "QUALITY CARE": {
              "NADOLOL": "NADOLOL",
              "PROPRANOLOL HCL": "PROPRANOLOL HCL",
              "SOTALOL HCL": "SOTALOL HCL"
            },
            "QUALITY CARE PRODUCTS": {
              "PROPRANOLOL HCL": "PROPRANOLOL HCL",
              "PROPRANOLOL HCL ER": "PROPRANOLOL HCL ER"
            },
            "ROUSES POINT": {
              "PROPRANOLOL HCL ER": "PROPRANOLOL HCL ER"
            },
            "RXCHANGE CO": {
              "PROPRANOLOL HCL": "PROPRANOLOL HCL",
              "PROPRANOLOL HCL ER": "PROPRANOLOL HCL ER"
            },
            "SANDOZ": {
              "NADOLOL": "NADOLOL",
              "SOTALOL HCL": "SOTALOL HCL"
            },
            "SPECTRUM": {
              "PROPRANOLOL HCL": "PROPRANOLOL HCL",
              "TIMOLOL MALEATE": "TIMOLOL MALEATE"
            },
            "ST MARY'S MPP": {
              "PROPRANOLOL HCL": "PROPRANOLOL HCL",
              "PROPRANOLOL HCL ER": "PROPRANOLOL HCL ER"
            },
            "SUN PHARMACEUTICALS": {
              "PINDOLOL": "PINDOLOL"
            },
            "TEVA PHARMACEUTICALS USA": {
              "NADOLOL": "NADOLOL",
              "SOTALOL HCL": "SOTALOL HCL"
            },
            "TRIGEN LABORATORIES": {
              "TIMOLOL MALEATE": "TIMOLOL MALEATE"
            },
            "UNIT DOSE SERVICES": {
              "PROPRANOLOL HCL": "PROPRANOLOL HCL",
              "PROPRANOLOL HCL ER": "PROPRANOLOL HCL ER"
            },
            "UPSHER-SMITH": {
              "PROPRANOLOL HCL ER": "PROPRANOLOL HCL ER",
              "SORINE": "SORINE"
            },
            "US WORLDMEDS": {
              "CORGARD": "CORGARD"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "NADOLOL": "NADOLOL"
            }
          }
        }
      },
      "CALCIUM CHANNEL BLOCKERS": {
        "Calcium Channel Blocker Combinations": {
          "Calcium Channel Blocker-NSAID Combinations": {
            "BURKE THERAPEUTICS": {
              "CONSENSI": "CONSENSI"
            }
          }
        },
        "Calcium Channel Blockers": {
          "Calcium Channel Blockers": {
            "A-S MEDICATION SOLUTIONS": {
              "AMLODIPINE BESYLATE": "AMLODIPINE BESYLATE",
              "CARTIA XT": "CARTIA XT",
              "DILTIAZEM HCL": "DILTIAZEM HCL",
              "DILTIAZEM HCL ER COATED BEADS": "DILTIAZEM HCL ER COATED BEADS",
              "FELODIPINE ER": "FELODIPINE ER",
              "NIFEDIPINE": "NIFEDIPINE",
              "NIFEDIPINE ER": "NIFEDIPINE ER",
              "NIFEDIPINE ER OSMOTIC RELEASE": "NIFEDIPINE ER OSMOTIC RELEASE",
              "NORVASC": "NORVASC",
              "VERAPAMIL HCL": "VERAPAMIL HCL",
              "VERAPAMIL HCL ER": "VERAPAMIL HCL ER"
            },
            "ACCORD HEALTHCARE": {
              "DILTIAZEM HCL ER": "DILTIAZEM HCL ER"
            },
            "ACELLA PHARMACEUTICALS": {
              "NIFEDIPINE": "NIFEDIPINE"
            },
            "ACI HEALTHCARE USA": {
              "AMLODIPINE BESYLATE": "AMLODIPINE BESYLATE"
            },
            "ACTAVIS": {
              "NIFEDIPINE ER": "NIFEDIPINE ER"
            },
            "ACTAVIS ELIZABETH": {
              "DILTIAZEM HCL ER COATED BEADS": "DILTIAZEM HCL ER COATED BEADS",
              "NIFEDIPINE": "NIFEDIPINE"
            },
            "ACTAVIS PHARMA": {
              "AFEDITAB CR": "AFEDITAB CR",
              "AMLODIPINE BESYLATE": "AMLODIPINE BESYLATE",
              "CARTIA XT": "CARTIA XT",
              "ISRADIPINE": "ISRADIPINE",
              "MATZIM LA": "MATZIM LA",
              "TAZTIA XT": "TAZTIA XT",
              "VERAPAMIL HCL": "VERAPAMIL HCL",
              "VERAPAMIL HCL ER": "VERAPAMIL HCL ER"
            },
            "AIDAREX PHARMACEUTICALS": {
              "AMLODIPINE BESYLATE": "AMLODIPINE BESYLATE",
              "DILTIAZEM HCL": "DILTIAZEM HCL",
              "DILTIAZEM HCL ER BEADS": "DILTIAZEM HCL ER BEADS",
              "DILTIAZEM HCL ER COATED BEADS": "DILTIAZEM HCL ER COATED BEADS",
              "NIFEDICAL XL": "NIFEDICAL XL",
              "NIFEDIPINE": "NIFEDIPINE",
              "NIFEDIPINE ER OSMOTIC RELEASE": "NIFEDIPINE ER OSMOTIC RELEASE",
              "VERAPAMIL HCL ER": "VERAPAMIL HCL ER"
            },
            "AKORN": {
              "DILTIAZEM HCL": "DILTIAZEM HCL"
            },
            "ALMATICA": {
              "ADALAT CC": "ADALAT CC"
            },
            "ALVIX LABORATORIES": {
              "VERAPAMIL HCL": "VERAPAMIL HCL"
            },
            "ALVOGEN": {
              "NIFEDIPINE ER": "NIFEDIPINE ER"
            },
            "AMERICAN HEALTH PACKAGING": {
              "AMLODIPINE BESYLATE": "AMLODIPINE BESYLATE",
              "DILTIAZEM HCL": "DILTIAZEM HCL",
              "DILTIAZEM HCL ER COATED BEADS": "DILTIAZEM HCL ER COATED BEADS",
              "FELODIPINE ER": "FELODIPINE ER",
              "NIFEDIPINE": "NIFEDIPINE",
              "NIFEDIPINE ER": "NIFEDIPINE ER",
              "NIFEDIPINE ER OSMOTIC RELEASE": "NIFEDIPINE ER OSMOTIC RELEASE",
              "NIMODIPINE": "NIMODIPINE",
              "TAZTIA XT": "TAZTIA XT",
              "VERAPAMIL HCL ER": "VERAPAMIL HCL ER"
            },
            "AMERICAN PHARMA INGREDIENTS": {
              "VERAPAMIL HCL": "VERAPAMIL HCL"
            },
            "AMERICAN REGENT": {
              "NICARDIPINE HCL": "NICARDIPINE HCL"
            },
            "AMNEAL BIOSCIENCES": {
              "VERAPAMIL HCL": "VERAPAMIL HCL"
            },
            "ANI  PHARMACEUTICALS": {
              "NIMODIPINE": "NIMODIPINE"
            },
            "APHENA PHARMA SOLUTIONS": {
              "ADALAT CC": "ADALAT CC",
              "AFEDITAB CR": "AFEDITAB CR",
              "AMLODIPINE BESYLATE": "AMLODIPINE BESYLATE",
              "DILTIAZEM HCL ER BEADS": "DILTIAZEM HCL ER BEADS",
              "NIFEDIPINE ER": "NIFEDIPINE ER",
              "NIFEDIPINE ER OSMOTIC RELEASE": "NIFEDIPINE ER OSMOTIC RELEASE",
              "NORVASC": "NORVASC",
              "VERAPAMIL HCL ER": "VERAPAMIL HCL ER"
            },
            "APOTEX": {
              "DILT-XR": "DILT-XR",
              "VERAPAMIL HCL ER": "VERAPAMIL HCL ER"
            },
            "APOTHECA SUPPLY": {
              "NIFEDIPINE": "NIFEDIPINE",
              "VERAPAMIL HCL": "VERAPAMIL HCL"
            },
            "AQ PHARMACEUTICALS": {
              "NORVASC": "NORVASC"
            },
            "ARBOR PHARMACEUTICALS": {
              "NYMALIZE": "NYMALIZE"
            },
            "ARMAS PHARMACEUTICALS": {
              "VERAPAMIL HCL": "VERAPAMIL HCL"
            },
            "ASCEND LABORATORIES": {
              "AMLODIPINE BESYLATE": "AMLODIPINE BESYLATE"
            },
            "ATHENEX PHARMACEUTICAL": {
              "DILTIAZEM HCL": "DILTIAZEM HCL"
            },
            "ATTIX PHARMACEUTICALS": {
              "VERAPAMIL HCL": "VERAPAMIL HCL"
            },
            "AUROBINDO PHARMA": {
              "AMLODIPINE BESYLATE": "AMLODIPINE BESYLATE",
              "FELODIPINE ER": "FELODIPINE ER"
            },
            "AUROMEDICS PHARMA": {
              "VERAPAMIL HCL": "VERAPAMIL HCL"
            },
            "AVET PHARMACEUTICALS": {
              "FELODIPINE ER": "FELODIPINE ER",
              "NIFEDIPINE": "NIFEDIPINE",
              "NIMODIPINE": "NIMODIPINE",
              "VERAPAMIL HCL": "VERAPAMIL HCL"
            },
            "AVKARE": {
              "DILTIAZEM CD": "DILTIAZEM CD",
              "DILTIAZEM HCL ER COATED BEADS": "DILTIAZEM HCL ER COATED BEADS",
              "FELODIPINE ER": "FELODIPINE ER",
              "NICARDIPINE HCL": "NICARDIPINE HCL",
              "VERAPAMIL HCL": "VERAPAMIL HCL"
            },
            "AVPAK": {
              "AMLODIPINE BESYLATE": "AMLODIPINE BESYLATE",
              "ISRADIPINE": "ISRADIPINE",
              "NIFEDIPINE": "NIFEDIPINE",
              "NIFEDIPINE ER OSMOTIC RELEASE": "NIFEDIPINE ER OSMOTIC RELEASE"
            },
            "AZURITY PHARMACEUTICALS": {
              "KATERZIA": "KATERZIA"
            },
            "B & B PHARMACEUTICALS": {
              "VERAPAMIL HCL": "VERAPAMIL HCL"
            },
            "BAUSCH HEALTH": {
              "CARDIZEM": "CARDIZEM",
              "CARDIZEM CD": "CARDIZEM CD",
              "CARDIZEM LA": "CARDIZEM LA",
              "TIAZAC": "TIAZAC"
            },
            "BAXTER HEALTHCARE CORP": {
              "CARDENE IV": "CARDENE IV",
              "VERAPAMIL HCL": "VERAPAMIL HCL"
            },
            "BI COASTAL PHARMACEUTICAL": {
              "AMLODIPINE BESYLATE": "AMLODIPINE BESYLATE"
            },
            "BIOMES PHARMACEUTICALS": {
              "AMLODIPINE BESYLATE": "AMLODIPINE BESYLATE"
            },
            "BIONPHARMA": {
              "NIMODIPINE": "NIMODIPINE"
            },
            "BLENHEIM PHARMACAL": {
              "AMLODIPINE BESYLATE": "AMLODIPINE BESYLATE",
              "VERAPAMIL HCL": "VERAPAMIL HCL",
              "VERAPAMIL HCL ER": "VERAPAMIL HCL ER"
            },
            "BLU PHARMACEUTICALS": {
              "DILTIAZEM HCL ER BEADS": "DILTIAZEM HCL ER BEADS",
              "NICARDIPINE HCL": "NICARDIPINE HCL"
            },
            "BRYANT RANCH PREPACK": {
              "AFEDITAB CR": "AFEDITAB CR",
              "AMLODIPINE BESYLATE": "AMLODIPINE BESYLATE",
              "CARTIA XT": "CARTIA XT",
              "DILTIAZEM HCL": "DILTIAZEM HCL",
              "DILTIAZEM HCL ER COATED BEADS": "DILTIAZEM HCL ER COATED BEADS",
              "FELODIPINE ER": "FELODIPINE ER",
              "NIFEDIPINE": "NIFEDIPINE",
              "NIFEDIPINE ER OSMOTIC RELEASE": "NIFEDIPINE ER OSMOTIC RELEASE",
              "VERAPAMIL HCL": "VERAPAMIL HCL",
              "VERAPAMIL HCL ER": "VERAPAMIL HCL ER"
            },
            "BURKE THERAPEUTICS": {
              "CONJUPRI": "CONJUPRI"
            },
            "CADISTA": {
              "FELODIPINE ER": "FELODIPINE ER"
            },
            "CENTRAL ADMIXTURE PHARMACY SER": {
              "DILTIAZEM HCL-SODIUM CHLORIDE": "DILTIAZEM HCL-SODIUM CHLORIDE"
            },
            "CHIESI USA": {
              "CARDENE IV": "CARDENE IV",
              "CLEVIPREX": "CLEVIPREX"
            },
            "CIPLA USA": {
              "AMLODIPINE BESYLATE": "AMLODIPINE BESYLATE"
            },
            "CIVICA": {
              "NICARDIPINE HCL": "NICARDIPINE HCL"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "AMLODIPINE BESYLATE": "AMLODIPINE BESYLATE",
              "NIFEDIPINE ER OSMOTIC RELEASE": "NIFEDIPINE ER OSMOTIC RELEASE"
            },
            "COVIS PHARMA": {
              "SULAR": "SULAR"
            },
            "DENTON PHARMA": {
              "AMLODIPINE BESYLATE": "AMLODIPINE BESYLATE"
            },
            "DIRECT RX": {
              "AMLODIPINE BESYLATE": "AMLODIPINE BESYLATE",
              "NIFEDIPINE ER OSMOTIC RELEASE": "NIFEDIPINE ER OSMOTIC RELEASE",
              "VERAPAMIL HCL": "VERAPAMIL HCL"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "AMLODIPINE BESYLATE": "AMLODIPINE BESYLATE"
            },
            "EDENBRIDGE PHARMACEUTICALS": {
              "DILTIAZEM HCL": "DILTIAZEM HCL"
            },
            "EPIC PHARMA": {
              "AMLODIPINE BESYLATE": "AMLODIPINE BESYLATE",
              "ISRADIPINE": "ISRADIPINE",
              "NICARDIPINE HCL": "NICARDIPINE HCL"
            },
            "EXELA PHARMA SCIENCES": {
              "VERAPAMIL HCL": "VERAPAMIL HCL"
            },
            "EXELAN PHARMACEUTICALS": {
              "AMLODIPINE BESYLATE": "AMLODIPINE BESYLATE"
            },
            "FAGRON": {
              "AMLODIPINE BES+SYRSPEND SF": "AMLODIPINE BES+SYRSPEND SF",
              "VERAPAMIL HCL": "VERAPAMIL HCL"
            },
            "FREEDOM PHARMACEUTICALS": {
              "VERAPAMIL HCL": "VERAPAMIL HCL"
            },
            "GLENMARK PHARMACEUTICALS": {
              "FELODIPINE ER": "FELODIPINE ER",
              "ISRADIPINE": "ISRADIPINE",
              "VERAPAMIL HCL ER": "VERAPAMIL HCL ER"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "AMLODIPINE BESYLATE": "AMLODIPINE BESYLATE",
              "ISRADIPINE": "ISRADIPINE",
              "NIFEDIPINE": "NIFEDIPINE",
              "NIFEDIPINE ER OSMOTIC RELEASE": "NIFEDIPINE ER OSMOTIC RELEASE",
              "NIMODIPINE": "NIMODIPINE",
              "VERAPAMIL HCL ER": "VERAPAMIL HCL ER"
            },
            "GREENSTONE": {
              "AMLODIPINE BESYLATE": "AMLODIPINE BESYLATE",
              "NIFEDIPINE": "NIFEDIPINE",
              "NIFEDIPINE ER OSMOTIC RELEASE": "NIFEDIPINE ER OSMOTIC RELEASE"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "AMLODIPINE BESYLATE": "AMLODIPINE BESYLATE",
              "DILTIAZEM HCL ER": "DILTIAZEM HCL ER",
              "NIFEDIPINE": "NIFEDIPINE",
              "NIFEDIPINE ER": "NIFEDIPINE ER",
              "VERAPAMIL HCL ER": "VERAPAMIL HCL ER"
            },
            "HIKMA": {
              "AMLODIPINE BESYLATE": "AMLODIPINE BESYLATE",
              "DILTIAZEM HCL": "DILTIAZEM HCL",
              "NICARDIPINE HCL": "NICARDIPINE HCL",
              "NICARDIPINE HCL IN NACL": "NICARDIPINE HCL IN NACL"
            },
            "HOSPIRA": {
              "DILTIAZEM HCL": "DILTIAZEM HCL",
              "VERAPAMIL HCL": "VERAPAMIL HCL"
            },
            "HUMCO": {
              "VERAPAMIL HCL": "VERAPAMIL HCL"
            },
            "HURON PHARMACEUTICALS": {
              "VERAPAMIL HCL": "VERAPAMIL HCL"
            },
            "INGENUS PHARMACEUTICALS": {
              "DILTIAZEM HCL ER COATED BEADS": "DILTIAZEM HCL ER COATED BEADS",
              "NIFEDIPINE ER": "NIFEDIPINE ER",
              "NIFEDIPINE ER OSMOTIC RELEASE": "NIFEDIPINE ER OSMOTIC RELEASE"
            },
            "INTERNATIONAL LABS, LLC": {
              "NIFEDIPINE ER OSMOTIC RELEASE": "NIFEDIPINE ER OSMOTIC RELEASE"
            },
            "KREMERS URBAN": {
              "NIFEDIPINE ER OSMOTIC RELEASE": "NIFEDIPINE ER OSMOTIC RELEASE",
              "VERAPAMIL HCL ER": "VERAPAMIL HCL ER",
              "VERELAN": "VERELAN",
              "VERELAN PM": "VERELAN PM"
            },
            "LEADING PHARMA": {
              "NIFEDIPINE": "NIFEDIPINE"
            },
            "LEGACY PHARMACEUTICAL PKG": {
              "AMLODIPINE BESYLATE": "AMLODIPINE BESYLATE",
              "NIFEDIPINE ER OSMOTIC RELEASE": "NIFEDIPINE ER OSMOTIC RELEASE"
            },
            "LETCO MEDICAL": {
              "VERAPAMIL HCL": "VERAPAMIL HCL"
            },
            "LIBERTY PHARMACEUTICAL": {
              "AMLODIPINE BESYLATE": "AMLODIPINE BESYLATE",
              "DILTIAZEM HCL": "DILTIAZEM HCL",
              "DILTIAZEM HCL ER": "DILTIAZEM HCL ER",
              "FELODIPINE ER": "FELODIPINE ER",
              "NIFEDIAC CC": "NIFEDIAC CC",
              "NIFEDIPINE": "NIFEDIPINE",
              "NIFEDIPINE ER": "NIFEDIPINE ER",
              "NIFEDIPINE ER OSMOTIC RELEASE": "NIFEDIPINE ER OSMOTIC RELEASE",
              "VERAPAMIL HCL ER": "VERAPAMIL HCL ER"
            },
            "LUPIN PHARMACEUTICALS": {
              "AMLODIPINE BESYLATE": "AMLODIPINE BESYLATE",
              "NIFEDIPINE": "NIFEDIPINE"
            },
            "MACLEODS PHARMACEUTICALS": {
              "AMLODIPINE BESYLATE": "AMLODIPINE BESYLATE"
            },
            "MAJOR PHARMACEUTICALS": {
              "AMLODIPINE BESYLATE": "AMLODIPINE BESYLATE",
              "NIFEDIPINE ER": "NIFEDIPINE ER",
              "NIMODIPINE": "NIMODIPINE",
              "VERAPAMIL HCL": "VERAPAMIL HCL"
            },
            "MARLEX PHARMACEUTICALS": {
              "AMLODIPINE BESYLATE": "AMLODIPINE BESYLATE"
            },
            "MEDISCA": {
              "VERAPAMIL HCL": "VERAPAMIL HCL"
            },
            "MEDVANTX": {
              "VERAPAMIL HCL ER": "VERAPAMIL HCL ER"
            },
            "METHOD PHARMACEUTICALS": {
              "FELODIPINE ER": "FELODIPINE ER"
            },
            "MICRO LABORATORIES": {
              "VERAPAMIL HCL": "VERAPAMIL HCL"
            },
            "MYLAN": {
              "AMLODIPINE BESYLATE": "AMLODIPINE BESYLATE",
              "DILTIAZEM HCL": "DILTIAZEM HCL",
              "DILTIAZEM HCL ER": "DILTIAZEM HCL ER",
              "FELODIPINE ER": "FELODIPINE ER",
              "NICARDIPINE HCL": "NICARDIPINE HCL",
              "NIFEDIPINE ER": "NIFEDIPINE ER",
              "NIFEDIPINE ER OSMOTIC RELEASE": "NIFEDIPINE ER OSMOTIC RELEASE",
              "NISOLDIPINE ER": "NISOLDIPINE ER",
              "VERAPAMIL HCL": "VERAPAMIL HCL",
              "VERAPAMIL HCL ER": "VERAPAMIL HCL ER"
            },
            "MYLAN INSTITUTIONAL": {
              "AMLODIPINE BESYLATE": "AMLODIPINE BESYLATE",
              "DILTIAZEM HCL": "DILTIAZEM HCL",
              "DILTIAZEM HCL ER": "DILTIAZEM HCL ER",
              "FELODIPINE ER": "FELODIPINE ER",
              "NIFEDIPINE ER OSMOTIC RELEASE": "NIFEDIPINE ER OSMOTIC RELEASE",
              "VERAPAMIL HCL ER": "VERAPAMIL HCL ER"
            },
            "NEPHRON STERILE COMPOUNDING": {
              "DILTIAZEM HCL": "DILTIAZEM HCL",
              "DILTIAZEM HCL-SODIUM CHLORIDE": "DILTIAZEM HCL-SODIUM CHLORIDE"
            },
            "NEW HORIZON RX GROUP": {
              "AMLODIPINE BESYLATE": "AMLODIPINE BESYLATE"
            },
            "NIVAGEN PHARMACEUTICALS": {
              "VERAPAMIL HCL ER": "VERAPAMIL HCL ER"
            },
            "NOVAPLUS/HIKMA": {
              "DILTIAZEM HCL": "DILTIAZEM HCL",
              "NICARDIPINE HCL": "NICARDIPINE HCL"
            },
            "NOVAPLUS/HOSPIRA": {
              "VERAPAMIL HCL": "VERAPAMIL HCL"
            },
            "NUCARE PHARMACEUTICALS": {
              "AMLODIPINE BESYLATE": "AMLODIPINE BESYLATE",
              "DILTIAZEM HCL ER COATED BEADS": "DILTIAZEM HCL ER COATED BEADS",
              "NIFEDIPINE": "NIFEDIPINE",
              "NIFEDIPINE ER OSMOTIC RELEASE": "NIFEDIPINE ER OSMOTIC RELEASE",
              "VERAPAMIL HCL ER": "VERAPAMIL HCL ER"
            },
            "OCEANSIDE PHARMACEUTICALS": {
              "DILTIAZEM HCL": "DILTIAZEM HCL",
              "DILTIAZEM HCL ER BEADS": "DILTIAZEM HCL ER BEADS",
              "DILTIAZEM HCL ER COATED BEADS": "DILTIAZEM HCL ER COATED BEADS",
              "NIFEDIPINE ER": "NIFEDIPINE ER",
              "NIFEDIPINE ER OSMOTIC RELEASE": "NIFEDIPINE ER OSMOTIC RELEASE"
            },
            "PAR PHARMACEUTICAL": {
              "DILTIAZEM HCL ER COATED BEADS": "DILTIAZEM HCL ER COATED BEADS",
              "NIFEDIPINE ER": "NIFEDIPINE ER"
            },
            "PAR PHARMACEUTICALS": {
              "AMLODIPINE BESYLATE": "AMLODIPINE BESYLATE",
              "FELODIPINE ER": "FELODIPINE ER"
            },
            "PCCA": {
              "VERAPAMIL HCL": "VERAPAMIL HCL"
            },
            "PDRX PHARMACEUTICAL": {
              "ADALAT CC": "ADALAT CC",
              "AMLODIPINE BESYLATE": "AMLODIPINE BESYLATE",
              "DILTIAZEM HCL": "DILTIAZEM HCL",
              "DILTIAZEM HCL ER": "DILTIAZEM HCL ER",
              "DILTIAZEM HCL ER COATED BEADS": "DILTIAZEM HCL ER COATED BEADS",
              "FELODIPINE ER": "FELODIPINE ER",
              "ISOPTIN SR": "ISOPTIN SR",
              "NIFEDIPINE": "NIFEDIPINE",
              "NIFEDIPINE ER": "NIFEDIPINE ER",
              "NIFEDIPINE ER OSMOTIC RELEASE": "NIFEDIPINE ER OSMOTIC RELEASE",
              "NORVASC": "NORVASC",
              "PROCARDIA": "PROCARDIA",
              "PROCARDIA XL": "PROCARDIA XL",
              "VERAPAMIL HCL": "VERAPAMIL HCL",
              "VERAPAMIL HCL ER": "VERAPAMIL HCL ER"
            },
            "PDRX PHARMACEUTICALS": {
              "AMLODIPINE BESYLATE": "AMLODIPINE BESYLATE"
            },
            "PFIZER U.S.": {
              "CALAN": "CALAN",
              "CALAN SR": "CALAN SR",
              "NORVASC": "NORVASC",
              "PROCARDIA": "PROCARDIA",
              "PROCARDIA XL": "PROCARDIA XL"
            },
            "PHARMACEUTICA NORTH AMERICA": {
              "VERAPAMIL HCL": "VERAPAMIL HCL"
            },
            "PHARMEDIUM SERVICES": {
              "DILTIAZEM HCL-DEXTROSE": "DILTIAZEM HCL-DEXTROSE",
              "DILTIAZEM HCL-SODIUM CHLORIDE": "DILTIAZEM HCL-SODIUM CHLORIDE",
              "NICARDIPINE HCL IN DEXTROSE": "NICARDIPINE HCL IN DEXTROSE",
              "NICARDIPINE HCL IN NACL": "NICARDIPINE HCL IN NACL"
            },
            "PHARMEDIX": {
              "VERAPAMIL HCL ER": "VERAPAMIL HCL ER"
            },
            "PHARMPAK": {
              "NIFEDIPINE": "NIFEDIPINE"
            },
            "PRASCO LABORATORIES": {
              "NISOLDIPINE ER": "NISOLDIPINE ER"
            },
            "PREFERRED PHARMACEUTICALS": {
              "AMLODIPINE BESYLATE": "AMLODIPINE BESYLATE",
              "DILTIAZEM HCL ER COATED BEADS": "DILTIAZEM HCL ER COATED BEADS",
              "NIFEDIPINE ER OSMOTIC RELEASE": "NIFEDIPINE ER OSMOTIC RELEASE",
              "VERAPAMIL HCL": "VERAPAMIL HCL",
              "VERAPAMIL HCL ER": "VERAPAMIL HCL ER"
            },
            "PRESCRIPTION SOLUTIONS, INC.": {
              "DILTIAZEM HCL ER": "DILTIAZEM HCL ER",
              "NIFEDIPINE ER": "NIFEDIPINE ER"
            },
            "PROFICIENT RX": {
              "AMLODIPINE BESYLATE": "AMLODIPINE BESYLATE",
              "NIFEDIPINE ER OSMOTIC RELEASE": "NIFEDIPINE ER OSMOTIC RELEASE",
              "VERAPAMIL HCL": "VERAPAMIL HCL",
              "VERAPAMIL HCL ER": "VERAPAMIL HCL ER"
            },
            "QUALITY CARE": {
              "AMLODIPINE BESYLATE": "AMLODIPINE BESYLATE",
              "CARDIZEM CD": "CARDIZEM CD",
              "DILTIAZEM HCL": "DILTIAZEM HCL",
              "DILTIAZEM HCL ER BEADS": "DILTIAZEM HCL ER BEADS",
              "DILTIAZEM HCL ER COATED BEADS": "DILTIAZEM HCL ER COATED BEADS",
              "FELODIPINE ER": "FELODIPINE ER",
              "NIFEDIPINE": "NIFEDIPINE",
              "NIFEDIPINE ER": "NIFEDIPINE ER",
              "NIFEDIPINE ER OSMOTIC RELEASE": "NIFEDIPINE ER OSMOTIC RELEASE",
              "NORVASC": "NORVASC",
              "VERAPAMIL HCL": "VERAPAMIL HCL",
              "VERAPAMIL HCL ER": "VERAPAMIL HCL ER"
            },
            "QUALITY CARE PRODUCTS": {
              "AMLODIPINE BESYLATE": "AMLODIPINE BESYLATE",
              "NIFEDIPINE ER": "NIFEDIPINE ER",
              "NIFEDIPINE ER OSMOTIC RELEASE": "NIFEDIPINE ER OSMOTIC RELEASE",
              "VERAPAMIL HCL ER": "VERAPAMIL HCL ER"
            },
            "QUVA PHARMA": {
              "DILTIAZEM HCL-DEXTROSE": "DILTIAZEM HCL-DEXTROSE",
              "NICARDIPINE HCL IN NACL": "NICARDIPINE HCL IN NACL"
            },
            "RICHMOND PHARMACEUTICALS": {
              "FELODIPINE ER": "FELODIPINE ER"
            },
            "RISING PHARMACEUTICALS": {
              "FELODIPINE ER": "FELODIPINE ER"
            },
            "RXCHANGE CO": {
              "DILTIAZEM HCL ER": "DILTIAZEM HCL ER",
              "NIFEDIPINE": "NIFEDIPINE",
              "NIFEDIPINE ER OSMOTIC RELEASE": "NIFEDIPINE ER OSMOTIC RELEASE"
            },
            "RXPAK MCKESSON": {
              "NIFEDIPINE ER": "NIFEDIPINE ER"
            },
            "SANDOZ": {
              "DILTIAZEM HCL ER BEADS": "DILTIAZEM HCL ER BEADS",
              "NICARDIPINE HCL": "NICARDIPINE HCL"
            },
            "SCA PHARMACEUTICALS": {
              "DILTIAZEM HCL-DEXTROSE": "DILTIAZEM HCL-DEXTROSE"
            },
            "SKY PACKAGING": {
              "AMLODIPINE BESYLATE": "AMLODIPINE BESYLATE",
              "DILTIAZEM CD": "DILTIAZEM CD",
              "DILTIAZEM HCL": "DILTIAZEM HCL",
              "DILTIAZEM HCL ER COATED BEADS": "DILTIAZEM HCL ER COATED BEADS",
              "NIMODIPINE": "NIMODIPINE"
            },
            "SOLCO HEALTHCARE": {
              "AMLODIPINE BESYLATE": "AMLODIPINE BESYLATE"
            },
            "SOMERSET THERAPEUTICS": {
              "VERAPAMIL HCL": "VERAPAMIL HCL"
            },
            "SPECTRUM": {
              "VERAPAMIL HCL": "VERAPAMIL HCL"
            },
            "ST MARY'S MPP": {
              "AMLODIPINE BESYLATE": "AMLODIPINE BESYLATE",
              "DILTIAZEM HCL ER COATED BEADS": "DILTIAZEM HCL ER COATED BEADS",
              "NIFEDIPINE ER OSMOTIC RELEASE": "NIFEDIPINE ER OSMOTIC RELEASE",
              "VERAPAMIL HCL ER": "VERAPAMIL HCL ER"
            },
            "STERRX": {
              "DILTIAZEM HCL-DEXTROSE": "DILTIAZEM HCL-DEXTROSE",
              "DILTIAZEM HCL-SODIUM CHLORIDE": "DILTIAZEM HCL-SODIUM CHLORIDE"
            },
            "SUN PHARMACEUTICAL": {
              "NICARDIPINE HCL": "NICARDIPINE HCL"
            },
            "SUN PHARMACEUTICALS": {
              "AMLODIPINE BESYLATE": "AMLODIPINE BESYLATE",
              "DILTIAZEM HCL ER BEADS": "DILTIAZEM HCL ER BEADS",
              "DILTIAZEM HCL ER COATED BEADS": "DILTIAZEM HCL ER COATED BEADS",
              "FELODIPINE ER": "FELODIPINE ER",
              "NIMODIPINE": "NIMODIPINE",
              "VERAPAMIL HCL ER": "VERAPAMIL HCL ER"
            },
            "TEVA PHARMACEUTICALS USA": {
              "AMLODIPINE BESYLATE": "AMLODIPINE BESYLATE",
              "DILTIAZEM HCL": "DILTIAZEM HCL",
              "DILTIAZEM HCL ER COATED BEADS": "DILTIAZEM HCL ER COATED BEADS",
              "NIFEDICAL XL": "NIFEDICAL XL",
              "NIFEDIPINE ER": "NIFEDIPINE ER",
              "VERAPAMIL HCL ER": "VERAPAMIL HCL ER"
            },
            "TORRENT PHARMACEUTICALS": {
              "FELODIPINE ER": "FELODIPINE ER"
            },
            "TOTAL PHARMACY SUPPLY": {
              "VERAPAMIL HCL": "VERAPAMIL HCL"
            },
            "TWI PHARMACEUTICALS": {
              "DILTIAZEM HCL ER COATED BEADS": "DILTIAZEM HCL ER COATED BEADS",
              "NIFEDIPINE ER OSMOTIC RELEASE": "NIFEDIPINE ER OSMOTIC RELEASE"
            },
            "UNICHEM PHARMACEUTICALS": {
              "AMLODIPINE BESYLATE": "AMLODIPINE BESYLATE"
            },
            "VA CMOP DALLAS": {
              "FELODIPINE ER": "FELODIPINE ER",
              "NIFEDIPINE ER": "NIFEDIPINE ER",
              "VERAPAMIL HCL ER": "VERAPAMIL HCL ER"
            },
            "YILING PHARMACEUTICAL": {
              "FELODIPINE ER": "FELODIPINE ER"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "AMLODIPINE BESYLATE": "AMLODIPINE BESYLATE",
              "DILTIAZEM HCL ER COATED BEADS": "DILTIAZEM HCL ER COATED BEADS",
              "TIADYLT ER": "TIADYLT ER"
            }
          }
        }
      },
      "CARDIOTONICS": {
        "Cardiac Glycosides": {
          "Cardiac Glycosides": {
            "A-S MEDICATION SOLUTIONS": {
              "DIGOX": "DIGOX",
              "DIGOXIN": "DIGOXIN",
              "LANOXIN": "LANOXIN"
            },
            "AIDAREX PHARMACEUTICALS": {
              "DIGOX": "DIGOX",
              "DIGOXIN": "DIGOXIN"
            },
            "AMERICAN HEALTH PACKAGING": {
              "DIGOXIN": "DIGOXIN"
            },
            "AMNEAL PHARMACEUTICALS": {
              "DIGOXIN": "DIGOXIN"
            },
            "APHENA PHARMA SOLUTIONS": {
              "DIGOXIN": "DIGOXIN",
              "LANOXIN": "LANOXIN"
            },
            "ATLANTIC BIOLOGICALS": {
              "DIGOXIN": "DIGOXIN"
            },
            "BRYANT RANCH PREPACK": {
              "DIGOX": "DIGOX",
              "DIGOXIN": "DIGOXIN"
            },
            "CONCORDIA PHARMACEUTICALS": {
              "LANOXIN": "LANOXIN"
            },
            "COVIS PHARMA": {
              "LANOXIN": "LANOXIN",
              "LANOXIN PEDIATRIC": "LANOXIN PEDIATRIC"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "DIGOXIN": "DIGOXIN"
            },
            "HIKMA": {
              "DIGOXIN": "DIGOXIN"
            },
            "LANNETT": {
              "DIGOX": "DIGOX"
            },
            "LIBERTY PHARMACEUTICAL": {
              "DIGOXIN": "DIGOXIN"
            },
            "MAJOR PHARMACEUTICALS": {
              "DIGOXIN": "DIGOXIN"
            },
            "MARLEX PHARMACEUTICALS": {
              "DIGOXIN": "DIGOXIN"
            },
            "MEDVANTX": {
              "DIGOXIN": "DIGOXIN"
            },
            "MYLAN": {
              "DIGITEK": "DIGITEK"
            },
            "MYLAN INSTITUTIONAL": {
              "DIGITEK": "DIGITEK"
            },
            "NORTHSTAR RX": {
              "DIGOXIN": "DIGOXIN"
            },
            "NOVAPLUS/HIKMA": {
              "DIGOXIN": "DIGOXIN"
            },
            "NUCARE PHARMACEUTICALS": {
              "DIGOXIN": "DIGOXIN"
            },
            "PAR PHARMACEUTICAL": {
              "DIGOXIN": "DIGOXIN"
            },
            "PDRX PHARMACEUTICAL": {
              "DIGOXIN": "DIGOXIN",
              "LANOXIN": "LANOXIN"
            },
            "PRECISION DOSE, INC": {
              "DIGOXIN": "DIGOXIN"
            },
            "PROFICIENT RX": {
              "DIGOXIN": "DIGOXIN"
            },
            "QUALITY CARE": {
              "DIGOX": "DIGOX",
              "DIGOXIN": "DIGOXIN",
              "LANOXIN": "LANOXIN"
            },
            "RXCHANGE CO": {
              "DIGOXIN": "DIGOXIN"
            },
            "SANDOZ": {
              "DIGOXIN": "DIGOXIN"
            },
            "ST MARY'S MPP": {
              "DIGOX": "DIGOX"
            },
            "SUN PHARMACEUTICALS": {
              "DIGOXIN": "DIGOXIN"
            },
            "VA CMOP DALLAS": {
              "DIGOXIN": "DIGOXIN"
            },
            "VANGARD": {
              "DIGOXIN": "DIGOXIN"
            },
            "VISTAPHARM INC.": {
              "DIGOXIN": "DIGOXIN"
            }
          }
        },
        "Phosphodiesterase Inhibitors": {
          "Phosphodiesterase Inhibitors": {
            "BAXTER HEALTHCARE CORP": {
              "MILRINONE LACTATE IN DEXTROSE": "MILRINONE LACTATE IN DEXTROSE"
            },
            "FRESENIUS KABI USA": {
              "MILRINONE LACTATE": "MILRINONE LACTATE"
            },
            "HIKMA": {
              "MILRINONE LACTATE": "MILRINONE LACTATE",
              "MILRINONE LACTATE IN DEXTROSE": "MILRINONE LACTATE IN DEXTROSE"
            },
            "HOSPIRA": {
              "MILRINONE LACTATE": "MILRINONE LACTATE",
              "MILRINONE LACTATE IN DEXTROSE": "MILRINONE LACTATE IN DEXTROSE"
            },
            "MEITHEAL PHARMACEUTICALS": {
              "MILRINONE LACTATE": "MILRINONE LACTATE"
            }
          }
        }
      },
      "CARDIOVASCULAR AGENTS - MISC.": {
        "Cardioplegic Solutions": {
          "Cardioplegic Solutions": {
            "BAXTER HEALTHCARE CORP": {
              "CARDIOPLEGIC": "CARDIOPLEGIC"
            },
            "CENTRAL ADMIXTURE PHARMACY SER": {
              "CARDIOPLEGIA DEL NIDO FORMULA": "CARDIOPLEGIA DEL NIDO FORMULA",
              "CARDIOPLEGIA IND PLASMA HIGH K": "CARDIOPLEGIA IND PLASMA HIGH K",
              "CARDIOPLEGIA IND PLASMA-TROMET": "CARDIOPLEGIA IND PLASMA-TROMET",
              "CARDIOPLEGIA INDUCTION HIGH K": "CARDIOPLEGIA INDUCTION HIGH K",
              "CARDIOPLEGIA INDUCTION LOW DEX": "CARDIOPLEGIA INDUCTION LOW DEX",
              "CARDIOPLEGIA INDUCTION NON-ENR": "CARDIOPLEGIA INDUCTION NON-ENR",
              "CARDIOPLEGIA MAIN LOW DEXTROSE": "CARDIOPLEGIA MAIN LOW DEXTROSE",
              "CARDIOPLEGIA MAIN LOW TROMETHA": "CARDIOPLEGIA MAIN LOW TROMETHA",
              "CARDIOPLEGIA MAIN PLASMA-TROME": "CARDIOPLEGIA MAIN PLASMA-TROME",
              "CARDIOPLEGIA MAINTENANCE": "CARDIOPLEGIA MAINTENANCE",
              "CARDIOPLEGIA REPERFUSATE 4:1": "CARDIOPLEGIA REPERFUSATE 4:1",
              "MICROPLEGIA MSA-MSG": "MICROPLEGIA MSA-MSG"
            },
            "HOSPIRA": {
              "PLEGISOL": "PLEGISOL"
            },
            "NEPHRON STERILE COMPOUNDING": {
              "CARDIOPLEGIC": "CARDIOPLEGIC"
            }
          }
        },
        "Cardiovascular Agents Misc. - Combinations": {
          "Calcium Channel Blocker & HMG CoA Reductase Inhibit Comb": {
            "A-S MEDICATION SOLUTIONS": {
              "CADUET": "CADUET"
            },
            "APOTEX": {
              "AMLODIPINE-ATORVASTATIN": "AMLODIPINE-ATORVASTATIN"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "AMLODIPINE-ATORVASTATIN": "AMLODIPINE-ATORVASTATIN"
            },
            "GREENSTONE": {
              "AMLODIPINE-ATORVASTATIN": "AMLODIPINE-ATORVASTATIN"
            },
            "MYLAN": {
              "AMLODIPINE-ATORVASTATIN": "AMLODIPINE-ATORVASTATIN"
            },
            "PFIZER U.S.": {
              "CADUET": "CADUET"
            },
            "QUALITY CARE": {
              "CADUET": "CADUET"
            },
            "SUN PHARMACEUTICALS": {
              "AMLODIPINE-ATORVASTATIN": "AMLODIPINE-ATORVASTATIN"
            }
          },
          "Neprilysin Inhib (ARNI)-Angiotensin II Recept Antag Comb": {
            "NOVARTIS": {
              "ENTRESTO": "ENTRESTO"
            }
          },
          "Nitrate & Vasodilator Combinations": {
            "ARBOR PHARMACEUTICALS": {
              "BIDIL": "BIDIL"
            }
          }
        },
        "Impotence Agents": {
          "Impotence Agent Combinations": {
            "EMPOWER PHARMACY": {
              "BI-MIX": "BI-MIX",
              "QUAD-MIX": "QUAD-MIX",
              "SUPER BI-MIX": "SUPER BI-MIX",
              "SUPER QUAD-MIX": "SUPER QUAD-MIX",
              "SUPER TRI-MIX": "SUPER TRI-MIX",
              "TRI-MIX": "TRI-MIX"
            },
            "LEITER'S ENTERPRISES": {
              "PAPAV-PHENTOLAMINE-ALPROSTADIL": "PAPAV-PHENTOLAMINE-ALPROSTADIL",
              "PAPAVERINE-ALPROSTADIL": "PAPAVERINE-ALPROSTADIL",
              "PAPAVERINE-PHENTOLAMINE": "PAPAVERINE-PHENTOLAMINE",
              "PHENTOLAMINE-ALPROSTADIL": "PHENTOLAMINE-ALPROSTADIL"
            }
          },
          "Impotence Agents - Other": {
            "EMPOWER PHARMACY": {
              "PHENYLEPHRINE HCL": "PHENYLEPHRINE HCL"
            }
          },
          "Prostaglandin - Impotence Agents": {
            "ENDO PHARMACEUTICALS": {
              "EDEX": "EDEX"
            },
            "MYLAN SPECIALTY L.P.": {
              "MUSE": "MUSE"
            },
            "NUBRATORI": {
              "IFE-PG20": "IFE-PG20"
            },
            "PFIZER U.S.": {
              "CAVERJECT": "CAVERJECT",
              "CAVERJECT IMPULSE": "CAVERJECT IMPULSE"
            }
          },
          "Selective cGMP Phosphodiesterase Type 5 Inhibitors": {
            "A-S MEDICATION SOLUTIONS": {
              "CIALIS": "CIALIS",
              "LEVITRA": "LEVITRA",
              "STAXYN": "STAXYN",
              "VIAGRA": "VIAGRA"
            },
            "ACCORD HEALTHCARE": {
              "TADALAFIL": "TADALAFIL"
            },
            "AJANTA PHARMA LIMITED": {
              "SILDENAFIL CITRATE": "SILDENAFIL CITRATE",
              "TADALAFIL": "TADALAFIL"
            },
            "ALEMBIC PHARMACEUTICALS": {
              "TADALAFIL": "TADALAFIL",
              "VARDENAFIL HCL": "VARDENAFIL HCL"
            },
            "ALTURA PHARMACEUTICALS": {
              "VIAGRA": "VIAGRA"
            },
            "AMNEAL PHARMACEUTICALS": {
              "SILDENAFIL CITRATE": "SILDENAFIL CITRATE",
              "TADALAFIL": "TADALAFIL"
            },
            "APHENA PHARMA SOLUTIONS": {
              "CIALIS": "CIALIS",
              "LEVITRA": "LEVITRA",
              "SILDENAFIL CITRATE": "SILDENAFIL CITRATE",
              "TADALAFIL": "TADALAFIL",
              "VARDENAFIL HCL": "VARDENAFIL HCL",
              "VIAGRA": "VIAGRA"
            },
            "APOTEX": {
              "TADALAFIL": "TADALAFIL"
            },
            "AUROBINDO PHARMA": {
              "SILDENAFIL CITRATE": "SILDENAFIL CITRATE"
            },
            "AVKARE": {
              "SILDENAFIL CITRATE": "SILDENAFIL CITRATE",
              "TADALAFIL": "TADALAFIL",
              "VARDENAFIL HCL": "VARDENAFIL HCL"
            },
            "AVPAK": {
              "TADALAFIL": "TADALAFIL"
            },
            "BRYANT RANCH PREPACK": {
              "CIALIS": "CIALIS",
              "LEVITRA": "LEVITRA",
              "VIAGRA": "VIAGRA"
            },
            "BUREL PHARMACEUTICALS": {
              "TADALAFIL": "TADALAFIL"
            },
            "CAMBER PHARMACEUTICALS": {
              "SILDENAFIL CITRATE": "SILDENAFIL CITRATE",
              "TADALAFIL": "TADALAFIL"
            },
            "CIPLA USA": {
              "SILDENAFIL CITRATE": "SILDENAFIL CITRATE",
              "TADALAFIL": "TADALAFIL"
            },
            "DIRECT RX": {
              "SILDENAFIL CITRATE": "SILDENAFIL CITRATE",
              "TADALAFIL": "TADALAFIL"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "TADALAFIL": "TADALAFIL"
            },
            "GLAXO SMITH KLINE": {
              "LEVITRA": "LEVITRA",
              "STAXYN": "STAXYN"
            },
            "GREENSTONE": {
              "SILDENAFIL CITRATE": "SILDENAFIL CITRATE"
            },
            "LANNETT": {
              "VARDENAFIL HCL": "VARDENAFIL HCL"
            },
            "LIBERTY PHARMACEUTICAL": {
              "LEVITRA": "LEVITRA"
            },
            "LILLY": {
              "CIALIS": "CIALIS"
            },
            "LUPIN PHARMACEUTICALS": {
              "SILDENAFIL CITRATE": "SILDENAFIL CITRATE",
              "TADALAFIL": "TADALAFIL"
            },
            "MACLEODS PHARMACEUTICALS": {
              "TADALAFIL": "TADALAFIL",
              "VARDENAFIL HCL": "VARDENAFIL HCL"
            },
            "MEDVANTX": {
              "VIAGRA": "VIAGRA"
            },
            "METUCHEN PHARMACEUTICALS": {
              "STENDRA": "STENDRA"
            },
            "MIST PHARMACEUTICALS": {
              "STENDRA": "STENDRA"
            },
            "MURFREESBORO PHARM NURSING SUP": {
              "LEVITRA": "LEVITRA",
              "VIAGRA": "VIAGRA"
            },
            "MYLAN": {
              "SILDENAFIL CITRATE": "SILDENAFIL CITRATE",
              "TADALAFIL": "TADALAFIL"
            },
            "NORTHSTAR RX": {
              "TADALAFIL": "TADALAFIL"
            },
            "NUCARE PHARMACEUTICALS": {
              "CIALIS": "CIALIS",
              "VIAGRA": "VIAGRA"
            },
            "PDRX PHARMACEUTICAL": {
              "CIALIS": "CIALIS",
              "LEVITRA": "LEVITRA",
              "SILDENAFIL CITRATE": "SILDENAFIL CITRATE",
              "TADALAFIL": "TADALAFIL",
              "VIAGRA": "VIAGRA"
            },
            "PDRX PHARMACEUTICALS": {
              "SILDENAFIL CITRATE": "SILDENAFIL CITRATE"
            },
            "PFIZER U.S.": {
              "VIAGRA": "VIAGRA"
            },
            "PRASCO LABORATORIES": {
              "TADALAFIL": "TADALAFIL"
            },
            "PREFERRED PHARMACEUTICALS": {
              "SILDENAFIL CITRATE": "SILDENAFIL CITRATE"
            },
            "PROFICIENT RX": {
              "SILDENAFIL CITRATE": "SILDENAFIL CITRATE",
              "TADALAFIL": "TADALAFIL"
            },
            "QUALITY CARE": {
              "CIALIS": "CIALIS",
              "LEVITRA": "LEVITRA",
              "VIAGRA": "VIAGRA"
            },
            "QUALITY CARE PRODUCTS": {
              "SILDENAFIL CITRATE": "SILDENAFIL CITRATE"
            },
            "RXCHANGE CO": {
              "CIALIS": "CIALIS",
              "LEVITRA": "LEVITRA",
              "VIAGRA": "VIAGRA"
            },
            "SUN PHARMACEUTICALS": {
              "TADALAFIL": "TADALAFIL"
            },
            "TEVA PHARMACEUTICALS USA": {
              "SILDENAFIL CITRATE": "SILDENAFIL CITRATE",
              "TADALAFIL": "TADALAFIL",
              "VARDENAFIL HCL": "VARDENAFIL HCL"
            },
            "TORRENT PHARMACEUTICALS": {
              "SILDENAFIL CITRATE": "SILDENAFIL CITRATE",
              "TADALAFIL": "TADALAFIL"
            },
            "TRUPHARMA": {
              "SILDENAFIL CITRATE": "SILDENAFIL CITRATE"
            },
            "UNICHEM PHARMACEUTICALS": {
              "TADALAFIL": "TADALAFIL"
            },
            "VA CMOP DALLAS": {
              "LEVITRA": "LEVITRA",
              "VIAGRA": "VIAGRA"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "TADALAFIL": "TADALAFIL",
              "VARDENAFIL HCL": "VARDENAFIL HCL"
            }
          }
        },
        "Peripheral Vasodilators": {
          "Peripheral Vasodilators": {
            "AMERICAN REGENT": {
              "PAPAVERINE HCL": "PAPAVERINE HCL"
            },
            "BI COASTAL PHARMACEUTICAL": {
              "ISOXSUPRINE HCL": "ISOXSUPRINE HCL"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC NIACIN": "QC NIACIN"
            },
            "ECI PHARMACEUTICALS": {
              "ISOXSUPRINE HCL": "ISOXSUPRINE HCL"
            },
            "ENCORE SCIENTIFIC": {
              "PAPAVERINE HCL": "PAPAVERINE HCL"
            },
            "EQUALINE": {
              "EQL NIACIN FLUSH FREE": "EQL NIACIN FLUSH FREE"
            },
            "FAGRON": {
              "PAPAVERINE HCL": "PAPAVERINE HCL"
            },
            "FREEDOM PHARMACEUTICALS": {
              "PAPAVERINE HCL": "PAPAVERINE HCL"
            },
            "HUMCO": {
              "PAPAVERINE HCL": "PAPAVERINE HCL"
            },
            "INTEGRATIVE THERAPEUTICS": {
              "NIACIN FLUSH FREE": "NIACIN FLUSH FREE"
            },
            "LEADER BRAND PRODUCTS": {
              "NIACIN FLUSH FREE": "NIACIN FLUSH FREE"
            },
            "LETCO MEDICAL": {
              "PAPAVERINE HCL": "PAPAVERINE HCL"
            },
            "MASON VITAMINS": {
              "NIACIN FLUSH FREE": "NIACIN FLUSH FREE",
              "NIACIN FLUSH-FREE EX ST": "NIACIN FLUSH-FREE EX ST"
            },
            "MEDISCA": {
              "PAPAVERINE HCL": "PAPAVERINE HCL"
            },
            "NAT-RUL HEALTH PRODUCTS": {
              "NO FLUSH NIACIN": "NO FLUSH NIACIN"
            },
            "NATURES BOUNTY": {
              "NIACIN FLUSH FREE": "NIACIN FLUSH FREE"
            },
            "PCCA": {
              "NYLIDRIN HCL": "NYLIDRIN HCL",
              "PAPAVERINE HCL": "PAPAVERINE HCL"
            },
            "PHARMAVITE": {
              "NIACIN FLUSH FREE": "NIACIN FLUSH FREE"
            },
            "PHYSICIAN RECOMMENDED NUTR": {
              "HDL BENEFIT": "HDL BENEFIT"
            },
            "PRINCETON RESEARCH": {
              "NIACIN FLUSH FREE": "NIACIN FLUSH FREE"
            },
            "REXALL SUNDOWN": {
              "NIACIN FLUSH FREE": "NIACIN FLUSH FREE"
            },
            "SANDOZ": {
              "PAPAVERINE HCL": "PAPAVERINE HCL"
            },
            "SPECTRUM": {
              "PAPAVERINE HCL": "PAPAVERINE HCL"
            },
            "WALGREENS": {
              "NIACIN FLUSH FREE": "NIACIN FLUSH FREE"
            },
            "WILLOW BIRCH PHARMA": {
              "PAPAVERINE HCL": "PAPAVERINE HCL"
            },
            "WINDMILL CONSUMER PRODUCTS": {
              "NO FLUSH NIACIN": "NO FLUSH NIACIN"
            }
          }
        },
        "Prostaglandin Vasodilators": {
          "Prostaglandin Vasodilators": {
            "ACTELION PHARMACEUTICALS": {
              "VELETRI": "VELETRI",
              "VENTAVIS": "VENTAVIS"
            },
            "GLAXO SMITH KLINE": {
              "FLOLAN": "FLOLAN"
            },
            "PAR STERILE PRODUCTS": {
              "TREPROSTINIL": "TREPROSTINIL"
            },
            "SANDOZ": {
              "TREPROSTINIL": "TREPROSTINIL"
            },
            "TEVA PARENTERAL MEDICINES": {
              "EPOPROSTENOL SODIUM": "EPOPROSTENOL SODIUM",
              "TREPROSTINIL": "TREPROSTINIL"
            },
            "UNITED THERAPEUTICS CORP": {
              "ORENITRAM": "ORENITRAM",
              "REMODULIN": "REMODULIN",
              "TYVASO": "TYVASO",
              "TYVASO REFILL": "TYVASO REFILL",
              "TYVASO STARTER": "TYVASO STARTER"
            }
          }
        },
        "Pulmonary Hypertension - Endothelin Receptor Antagonists": {
          "Pulmonary Hypertension - Endothelin Receptor Antagonists": {
            "ACTAVIS PHARMA": {
              "AMBRISENTAN": "AMBRISENTAN",
              "BOSENTAN": "BOSENTAN"
            },
            "ACTELION PHARMACEUTICALS": {
              "OPSUMIT": "OPSUMIT",
              "TRACLEER": "TRACLEER"
            },
            "AMNEAL PHARMACEUTICALS": {
              "BOSENTAN": "BOSENTAN"
            },
            "CIPLA USA": {
              "AMBRISENTAN": "AMBRISENTAN"
            },
            "GILEAD SCIENCES": {
              "LETAIRIS": "LETAIRIS"
            },
            "HIKMA": {
              "BOSENTAN": "BOSENTAN"
            },
            "MYLAN": {
              "AMBRISENTAN": "AMBRISENTAN"
            },
            "PAR PHARMACEUTICAL": {
              "AMBRISENTAN": "AMBRISENTAN",
              "BOSENTAN": "BOSENTAN"
            },
            "PATRIOT PHARMACEUTICALS LLC": {
              "BOSENTAN": "BOSENTAN"
            },
            "SIGMAPHARM LABORATORIES": {
              "AMBRISENTAN": "AMBRISENTAN"
            },
            "SUN PHARMACEUTICALS": {
              "AMBRISENTAN": "AMBRISENTAN",
              "BOSENTAN": "BOSENTAN"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "AMBRISENTAN": "AMBRISENTAN",
              "BOSENTAN": "BOSENTAN"
            }
          }
        },
        "Pulmonary Hypertension - Phosphodiesterase Inhibitors": {
          "Pulmonary Hypertension - Phosphodiesterase Inhibitors": {
            "A-S MEDICATION SOLUTIONS": {
              "SILDENAFIL CITRATE": "SILDENAFIL CITRATE"
            },
            "ACTAVIS PHARMA": {
              "SILDENAFIL CITRATE": "SILDENAFIL CITRATE"
            },
            "AIDAREX PHARMACEUTICALS": {
              "SILDENAFIL CITRATE": "SILDENAFIL CITRATE"
            },
            "AJANTA PHARMA LIMITED": {
              "SILDENAFIL CITRATE": "SILDENAFIL CITRATE",
              "TADALAFIL (PAH)": "TADALAFIL (PAH)"
            },
            "AMERICAN HEALTH PACKAGING": {
              "SILDENAFIL CITRATE": "SILDENAFIL CITRATE"
            },
            "AMNEAL PHARMACEUTICALS": {
              "SILDENAFIL CITRATE": "SILDENAFIL CITRATE"
            },
            "APHENA PHARMA SOLUTIONS": {
              "ADCIRCA": "ADCIRCA",
              "SILDENAFIL CITRATE": "SILDENAFIL CITRATE"
            },
            "APOTEX": {
              "SILDENAFIL CITRATE": "SILDENAFIL CITRATE"
            },
            "AUROBINDO PHARMA": {
              "SILDENAFIL CITRATE": "SILDENAFIL CITRATE",
              "TADALAFIL (PAH)": "TADALAFIL (PAH)"
            },
            "AUROMEDICS PHARMA": {
              "SILDENAFIL CITRATE": "SILDENAFIL CITRATE"
            },
            "AVKARE": {
              "SILDENAFIL CITRATE": "SILDENAFIL CITRATE",
              "TADALAFIL (PAH)": "TADALAFIL (PAH)"
            },
            "AVPAK": {
              "SILDENAFIL CITRATE": "SILDENAFIL CITRATE"
            },
            "BLUE POINT LABORATORIES": {
              "SILDENAFIL CITRATE": "SILDENAFIL CITRATE"
            },
            "BRYANT RANCH PREPACK": {
              "SILDENAFIL CITRATE": "SILDENAFIL CITRATE"
            },
            "CAMBER PHARMACEUTICALS": {
              "SILDENAFIL CITRATE": "SILDENAFIL CITRATE",
              "TADALAFIL (PAH)": "TADALAFIL (PAH)"
            },
            "CIPLA USA": {
              "TADALAFIL (PAH)": "TADALAFIL (PAH)"
            },
            "DIRECT RX": {
              "SILDENAFIL CITRATE": "SILDENAFIL CITRATE"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "SILDENAFIL CITRATE": "SILDENAFIL CITRATE",
              "TADALAFIL (PAH)": "TADALAFIL (PAH)"
            },
            "GREENSTONE": {
              "SILDENAFIL CITRATE": "SILDENAFIL CITRATE"
            },
            "LILLY": {
              "ADCIRCA": "ADCIRCA"
            },
            "LUPIN PHARMACEUTICALS": {
              "TADALAFIL (PAH)": "TADALAFIL (PAH)"
            },
            "MACLEODS PHARMACEUTICALS": {
              "SILDENAFIL CITRATE": "SILDENAFIL CITRATE"
            },
            "MAJOR PHARMACEUTICALS": {
              "SILDENAFIL CITRATE": "SILDENAFIL CITRATE"
            },
            "MYLAN": {
              "SILDENAFIL CITRATE": "SILDENAFIL CITRATE",
              "TADALAFIL (PAH)": "TADALAFIL (PAH)"
            },
            "NORTHSTAR RX": {
              "SILDENAFIL CITRATE": "SILDENAFIL CITRATE"
            },
            "NOVADOZ PHARMACEUTICALS": {
              "SILDENAFIL CITRATE": "SILDENAFIL CITRATE"
            },
            "NOVITIUM PHARMA": {
              "SILDENAFIL CITRATE": "SILDENAFIL CITRATE"
            },
            "NUCARE PHARMACEUTICALS": {
              "SILDENAFIL CITRATE": "SILDENAFIL CITRATE"
            },
            "PDRX PHARMACEUTICAL": {
              "SILDENAFIL CITRATE": "SILDENAFIL CITRATE"
            },
            "PFIZER U.S.": {
              "REVATIO": "REVATIO"
            },
            "PREFERRED PHARMACEUTICALS": {
              "SILDENAFIL CITRATE": "SILDENAFIL CITRATE"
            },
            "PROFICIENT RX": {
              "SILDENAFIL CITRATE": "SILDENAFIL CITRATE"
            },
            "QUALITY CARE PRODUCTS": {
              "SILDENAFIL CITRATE": "SILDENAFIL CITRATE"
            },
            "TEVA PHARMACEUTICALS USA": {
              "ALYQ": "ALYQ",
              "SILDENAFIL CITRATE": "SILDENAFIL CITRATE"
            },
            "TORRENT PHARMACEUTICALS": {
              "SILDENAFIL CITRATE": "SILDENAFIL CITRATE",
              "TADALAFIL (PAH)": "TADALAFIL (PAH)"
            },
            "TRUPHARMA": {
              "SILDENAFIL CITRATE": "SILDENAFIL CITRATE"
            },
            "VENSUN PHARMACEUTICALS": {
              "SILDENAFIL CITRATE": "SILDENAFIL CITRATE"
            }
          }
        },
        "Pulmonary Hypertension - Prostacyclin Receptor Agonist": {
          "Pulmonary Hypertension - Prostacyclin Receptor Agonist": {
            "ACTELION PHARMACEUTICALS": {
              "UPTRAVI": "UPTRAVI"
            }
          }
        },
        "Pulmonary Hypertension - Sol Guanylate Cyclase Stimulator": {
          "Pulm Hyperten-Soluble Guanylate Cyclase Stimulator (sGC)": {
            "BAYER HEALTHCARE PHARMA": {
              "ADEMPAS": "ADEMPAS"
            }
          }
        },
        "Septal Agents": {
          "Septal Agents - Ablatio": {
            "BPI LABS LLC": {
              "ABLYSINOL": "ABLYSINOL"
            }
          }
        },
        "Sinus Node Inhibitor": {
          "Sinus Node Inhibitor": {
            "AMGEN": {
              "CORLANOR": "CORLANOR"
            }
          }
        },
        "Transthyretin Stabilizers": {
          "Transthyretin Stabilizers": {
            "PFIZER U.S.": {
              "VYNDAMAX": "VYNDAMAX",
              "VYNDAQEL": "VYNDAQEL"
            }
          }
        },
        "Vasoactive Natriuretic Peptides": {
          "Vasoactive Natriuretic Peptides": {
            "JANSSEN PHARMACEUTICALS": {
              "NATRECOR": "NATRECOR"
            }
          }
        }
      },
      "CEPHALOSPORINS": {
        "Cephalosporin Combinations": {
          "Cephalosporin Combinations": {
            "ALLERGAN": {
              "AVYCAZ": "AVYCAZ"
            },
            "MERCK SHARP & DOHME": {
              "ZERBAXA": "ZERBAXA"
            }
          }
        },
        "Cephalosporins - 1st Generation": {
          "Cephalosporins - 1st Generation": {
            "A-S MEDICATION SOLUTIONS": {
              "CEFADROXIL": "CEFADROXIL",
              "CEFAZOLIN SODIUM": "CEFAZOLIN SODIUM",
              "CEPHALEXIN": "CEPHALEXIN"
            },
            "AIDAREX PHARMACEUTICALS": {
              "CEFADROXIL": "CEFADROXIL",
              "CEPHALEXIN": "CEPHALEXIN"
            },
            "ALTURA PHARMACEUTICALS": {
              "CEFADROXIL": "CEFADROXIL",
              "CEPHALEXIN": "CEPHALEXIN"
            },
            "AMERICAN HEALTH PACKAGING": {
              "CEPHALEXIN": "CEPHALEXIN"
            },
            "APOTEX": {
              "CEFAZOLIN SODIUM": "CEFAZOLIN SODIUM"
            },
            "APOTHECA": {
              "CEPHALEXIN": "CEPHALEXIN"
            },
            "ASCEND LABORATORIES": {
              "CEPHALEXIN": "CEPHALEXIN"
            },
            "AUROBINDO PHARMA": {
              "CEFADROXIL": "CEFADROXIL",
              "CEPHALEXIN": "CEPHALEXIN"
            },
            "AVKARE": {
              "CEPHALEXIN": "CEPHALEXIN"
            },
            "AVPAK": {
              "CEPHALEXIN": "CEPHALEXIN"
            },
            "B. BRAUN MEDICAL": {
              "CEFAZOLIN SODIUM-DEXTROSE": "CEFAZOLIN SODIUM-DEXTROSE"
            },
            "BAXTER HEALTHCARE CORP": {
              "CEFAZOLIN SODIUM-DEXTROSE": "CEFAZOLIN SODIUM-DEXTROSE"
            },
            "BELCHER PHARMACEUTICALS": {
              "CEPHALEXIN": "CEPHALEXIN"
            },
            "BI COASTAL PHARMACEUTICAL": {
              "CEPHALEXIN": "CEPHALEXIN"
            },
            "BLENHEIM PHARMACAL": {
              "CEFADROXIL": "CEFADROXIL",
              "CEPHALEXIN": "CEPHALEXIN"
            },
            "BRYANT RANCH PREPACK": {
              "CEFADROXIL": "CEFADROXIL",
              "CEPHALEXIN": "CEPHALEXIN"
            },
            "CARLSBAD TECHNOLOGIES": {
              "CEPHALEXIN": "CEPHALEXIN"
            },
            "CEPHAZONE PHARMA": {
              "CEFAZOLIN SODIUM": "CEFAZOLIN SODIUM"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "CEPHALEXIN": "CEPHALEXIN"
            },
            "CROWN LABORATORIES": {
              "DAXBIA": "DAXBIA"
            },
            "DENTON PHARMA": {
              "CEFADROXIL": "CEFADROXIL",
              "CEPHALEXIN": "CEPHALEXIN"
            },
            "DIRECT RX": {
              "CEFADROXIL": "CEFADROXIL",
              "CEPHALEXIN": "CEPHALEXIN"
            },
            "FERA PHARMACEUTICALS": {
              "CEPHALEXIN": "CEPHALEXIN"
            },
            "FRESENIUS KABI USA": {
              "CEFAZOLIN SODIUM": "CEFAZOLIN SODIUM"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "CEPHALEXIN": "CEPHALEXIN"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "CEFADROXIL": "CEFADROXIL",
              "CEPHALEXIN": "CEPHALEXIN",
              "KEFLEX": "KEFLEX"
            },
            "HIKMA": {
              "CEFADROXIL": "CEFADROXIL",
              "CEFAZOLIN SODIUM": "CEFAZOLIN SODIUM",
              "CEPHALEXIN": "CEPHALEXIN"
            },
            "HOSPIRA": {
              "CEFAZOLIN SODIUM": "CEFAZOLIN SODIUM"
            },
            "INTEGRADOSE COMPOUNDING SERVIC": {
              "CEFAZOLIN IN SODIUM CHLORIDE": "CEFAZOLIN IN SODIUM CHLORIDE",
              "CEFAZOLIN SODIUM": "CEFAZOLIN SODIUM"
            },
            "LIBERTY PHARMACEUTICAL": {
              "CEFADROXIL": "CEFADROXIL",
              "CEPHALEXIN": "CEPHALEXIN"
            },
            "LUPIN PHARMACEUTICALS": {
              "CEFADROXIL": "CEFADROXIL",
              "CEPHALEXIN": "CEPHALEXIN"
            },
            "MEDVANTX": {
              "CEPHALEXIN": "CEPHALEXIN"
            },
            "NORTHSTAR RX": {
              "CEFADROXIL": "CEFADROXIL"
            },
            "NORTHWIND PHARMACEUTICALS": {
              "CEPHALEXIN": "CEPHALEXIN"
            },
            "NOVAPLUS/APOTEX USA": {
              "CEFAZOLIN SODIUM": "CEFAZOLIN SODIUM"
            },
            "NOVAPLUS/HIKMA": {
              "CEFAZOLIN SODIUM": "CEFAZOLIN SODIUM"
            },
            "NOVAPLUS/SANDOZ": {
              "CEFAZOLIN SODIUM": "CEFAZOLIN SODIUM"
            },
            "NUCARE PHARMACEUTICALS": {
              "CEFADROXIL": "CEFADROXIL",
              "CEPHALEXIN": "CEPHALEXIN"
            },
            "ORCHIDPHARMA": {
              "CEFADROXIL": "CEFADROXIL",
              "CEPHALEXIN": "CEPHALEXIN"
            },
            "PDRX PHARMACEUTICAL": {
              "CEFADROXIL": "CEFADROXIL",
              "CEPHALEXIN": "CEPHALEXIN",
              "KEFLEX": "KEFLEX"
            },
            "PHARMEDIUM SERVICES": {
              "CEFAZOLIN IN SODIUM CHLORIDE": "CEFAZOLIN IN SODIUM CHLORIDE",
              "CEFAZOLIN SODIUM": "CEFAZOLIN SODIUM",
              "CEFAZOLIN SODIUM-DEXTROSE": "CEFAZOLIN SODIUM-DEXTROSE",
              "CEFAZOLIN SODIUM-NACL": "CEFAZOLIN SODIUM-NACL"
            },
            "PHARMEDIX": {
              "CEFADROXIL": "CEFADROXIL",
              "CEPHALEXIN": "CEPHALEXIN"
            },
            "PHARMPAK": {
              "CEPHALEXIN": "CEPHALEXIN"
            },
            "PRAGMA PHARMACEUTICALS": {
              "KEFLEX": "KEFLEX"
            },
            "PREFERRED PHARMACEUTICALS": {
              "CEFADROXIL": "CEFADROXIL",
              "CEPHALEXIN": "CEPHALEXIN"
            },
            "PROFICIENT RX": {
              "CEPHALEXIN": "CEPHALEXIN"
            },
            "QUALITY CARE": {
              "CEFADROXIL": "CEFADROXIL",
              "CEPHALEXIN": "CEPHALEXIN"
            },
            "QUALITY CARE PRODUCTS": {
              "CEPHALEXIN": "CEPHALEXIN"
            },
            "QUVA PHARMA": {
              "CEFAZOLIN IN SODIUM CHLORIDE": "CEFAZOLIN IN SODIUM CHLORIDE",
              "CEFAZOLIN SODIUM": "CEFAZOLIN SODIUM"
            },
            "READYMEDS": {
              "CEPHALEXIN": "CEPHALEXIN"
            },
            "RISING PHARMACEUTICALS": {
              "CEFADROXIL": "CEFADROXIL"
            },
            "RXCHANGE CO": {
              "CEFADROXIL": "CEFADROXIL",
              "CEPHALEXIN": "CEPHALEXIN"
            },
            "SAGENT PHARMACEUTICAL": {
              "CEFAZOLIN SODIUM": "CEFAZOLIN SODIUM"
            },
            "SAMSON MEDICAL TECH. LLC": {
              "CEFAZOLIN SODIUM": "CEFAZOLIN SODIUM"
            },
            "SANDOZ": {
              "CEFADROXIL": "CEFADROXIL",
              "CEFAZOLIN SODIUM": "CEFAZOLIN SODIUM"
            },
            "SHIONOGI PHARMA": {
              "KEFLEX": "KEFLEX"
            },
            "SUN PHARMACEUTICALS": {
              "CEPHALEXIN": "CEPHALEXIN"
            },
            "TEVA PHARMACEUTICALS USA": {
              "CEFADROXIL": "CEFADROXIL",
              "CEPHALEXIN": "CEPHALEXIN"
            },
            "TRUXTON": {
              "CEPHALEXIN": "CEPHALEXIN"
            },
            "TWI PHARMACEUTICALS": {
              "CEPHALEXIN": "CEPHALEXIN"
            },
            "UNIT DOSE SERVICES": {
              "CEPHALEXIN": "CEPHALEXIN"
            },
            "VIRTUS PHARMACEUTICALS": {
              "CEPHALEXIN": "CEPHALEXIN"
            },
            "VIRTUS PHARMACEUTICALS OPCO": {
              "CEPHALEXIN": "CEPHALEXIN"
            },
            "WG CRITICAL CARE": {
              "CEFAZOLIN SODIUM": "CEFAZOLIN SODIUM"
            }
          }
        },
        "Cephalosporins - 2nd Generation": {
          "Cephalosporins - 2nd Generation": {
            "A-S MEDICATION SOLUTIONS": {
              "CEFACLOR": "CEFACLOR",
              "CEFPROZIL": "CEFPROZIL",
              "CEFTIN": "CEFTIN",
              "CEFUROXIME AXETIL": "CEFUROXIME AXETIL"
            },
            "ACETRIS HEALTH": {
              "CEFUROXIME AXETIL": "CEFUROXIME AXETIL"
            },
            "AIDAREX PHARMACEUTICALS": {
              "CEFACLOR": "CEFACLOR",
              "CEFPROZIL": "CEFPROZIL",
              "CEFUROXIME AXETIL": "CEFUROXIME AXETIL"
            },
            "ALTURA PHARMACEUTICALS": {
              "CEFACLOR": "CEFACLOR"
            },
            "ALVOGEN": {
              "CEFACLOR": "CEFACLOR"
            },
            "AMERICAN HEALTH PACKAGING": {
              "CEFUROXIME AXETIL": "CEFUROXIME AXETIL"
            },
            "APOTEX": {
              "CEFOXITIN SODIUM": "CEFOXITIN SODIUM"
            },
            "AQ PHARMACEUTICALS": {
              "CEFTIN": "CEFTIN"
            },
            "ASCEND LABORATORIES": {
              "CEFUROXIME AXETIL": "CEFUROXIME AXETIL"
            },
            "AUROBINDO PHARMA": {
              "CEFPROZIL": "CEFPROZIL",
              "CEFUROXIME AXETIL": "CEFUROXIME AXETIL"
            },
            "AVADEL PHARMACEUTICALS USA": {
              "CEFACLOR": "CEFACLOR"
            },
            "B. BRAUN MEDICAL": {
              "CEFOTETAN DISODIUM-DEXTROSE": "CEFOTETAN DISODIUM-DEXTROSE",
              "CEFOXITIN SODIUM-DEXTROSE": "CEFOXITIN SODIUM-DEXTROSE"
            },
            "BRYANT RANCH PREPACK": {
              "CEFACLOR": "CEFACLOR",
              "CEFUROXIME AXETIL": "CEFUROXIME AXETIL"
            },
            "CARLSBAD TECHNOLOGIES": {
              "CEFACLOR": "CEFACLOR"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "CEFUROXIME AXETIL": "CEFUROXIME AXETIL"
            },
            "DENTON PHARMA": {
              "CEFUROXIME AXETIL": "CEFUROXIME AXETIL"
            },
            "DIRECT RX": {
              "CEFUROXIME AXETIL": "CEFUROXIME AXETIL"
            },
            "FRESENIUS KABI USA": {
              "CEFOTETAN DISODIUM": "CEFOTETAN DISODIUM",
              "CEFOXITIN SODIUM": "CEFOXITIN SODIUM",
              "CEFUROXIME SODIUM": "CEFUROXIME SODIUM"
            },
            "GLAXO SMITH KLINE": {
              "CEFTIN": "CEFTIN"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "CEFUROXIME AXETIL": "CEFUROXIME AXETIL"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "CEFACLOR": "CEFACLOR",
              "CEFPROZIL": "CEFPROZIL",
              "CEFTIN": "CEFTIN",
              "CEFUROXIME AXETIL": "CEFUROXIME AXETIL"
            },
            "HIKMA": {
              "CEFACLOR": "CEFACLOR",
              "CEFOTETAN DISODIUM": "CEFOTETAN DISODIUM",
              "CEFOXITIN SODIUM": "CEFOXITIN SODIUM",
              "CEFUROXIME SODIUM": "CEFUROXIME SODIUM"
            },
            "LIBERTY PHARMACEUTICAL": {
              "CEFACLOR": "CEFACLOR",
              "CEFPROZIL": "CEFPROZIL",
              "CEFUROXIME AXETIL": "CEFUROXIME AXETIL"
            },
            "LUPIN PHARMACEUTICALS": {
              "CEFPROZIL": "CEFPROZIL",
              "CEFUROXIME AXETIL": "CEFUROXIME AXETIL"
            },
            "NORTHSTAR RX": {
              "CEFPROZIL": "CEFPROZIL",
              "CEFUROXIME AXETIL": "CEFUROXIME AXETIL"
            },
            "NOVAPLUS/APOTEX USA": {
              "CEFOXITIN SODIUM": "CEFOXITIN SODIUM"
            },
            "NOVAPLUS/HIKMA": {
              "CEFUROXIME SODIUM": "CEFUROXIME SODIUM"
            },
            "NOVAPLUS/WG CRITICAL CARE": {
              "CEFOXITIN SODIUM": "CEFOXITIN SODIUM"
            },
            "NUCARE PHARMACEUTICALS": {
              "CEFACLOR": "CEFACLOR"
            },
            "ORCHIDPHARMA": {
              "CEFPROZIL": "CEFPROZIL",
              "CEFUROXIME AXETIL": "CEFUROXIME AXETIL"
            },
            "PAR PHARMACEUTICAL": {
              "CEFPROZIL": "CEFPROZIL"
            },
            "PDRX PHARMACEUTICAL": {
              "CEFACLOR": "CEFACLOR",
              "CEFPROZIL": "CEFPROZIL",
              "CEFTIN": "CEFTIN",
              "CEFUROXIME AXETIL": "CEFUROXIME AXETIL"
            },
            "PDRX PHARMACEUTICALS": {
              "CEFUROXIME AXETIL": "CEFUROXIME AXETIL"
            },
            "PHARMEDIX": {
              "CEFACLOR": "CEFACLOR",
              "CEFUROXIME AXETIL": "CEFUROXIME AXETIL"
            },
            "PREFERRED PHARMACEUTICALS": {
              "CEFUROXIME AXETIL": "CEFUROXIME AXETIL"
            },
            "PROFICIENT RX": {
              "CEFUROXIME AXETIL": "CEFUROXIME AXETIL"
            },
            "QUALITY CARE": {
              "CEFACLOR": "CEFACLOR",
              "CEFUROXIME AXETIL": "CEFUROXIME AXETIL"
            },
            "QUALITY CARE PRODUCTS": {
              "CEFPROZIL": "CEFPROZIL",
              "CEFUROXIME AXETIL": "CEFUROXIME AXETIL"
            },
            "RISING PHARMACEUTICALS": {
              "CEFPROZIL": "CEFPROZIL",
              "CEFUROXIME AXETIL": "CEFUROXIME AXETIL"
            },
            "RXCHANGE CO": {
              "CEFACLOR": "CEFACLOR"
            },
            "SAGENT PHARMACEUTICAL": {
              "CEFOXITIN SODIUM": "CEFOXITIN SODIUM",
              "CEFUROXIME SODIUM": "CEFUROXIME SODIUM"
            },
            "SANDOZ": {
              "CEFPROZIL": "CEFPROZIL"
            },
            "SUN PHARMACEUTICALS": {
              "CEFPROZIL": "CEFPROZIL"
            },
            "TELIGENT": {
              "CEFOTAN": "CEFOTAN",
              "ZINACEF": "ZINACEF",
              "ZINACEF IN STERILE WATER": "ZINACEF IN STERILE WATER"
            },
            "TEVA PHARMACEUTICALS USA": {
              "CEFACLOR ER": "CEFACLOR ER",
              "CEFPROZIL": "CEFPROZIL"
            },
            "UNIT DOSE SERVICES": {
              "CEFUROXIME AXETIL": "CEFUROXIME AXETIL"
            },
            "WG CRITICAL CARE": {
              "CEFOXITIN SODIUM": "CEFOXITIN SODIUM"
            },
            "WOCKHARDT USA": {
              "CEFUROXIME AXETIL": "CEFUROXIME AXETIL"
            },
            "ZYLERA PHARMACEUTICALS": {
              "CEFACLOR": "CEFACLOR"
            }
          }
        },
        "Cephalosporins - 3rd Generation": {
          "Cephalosporins - 3rd Generation": {
            "A-S MEDICATION SOLUTIONS": {
              "CEFDINIR": "CEFDINIR",
              "CEFTRIAXONE SODIUM": "CEFTRIAXONE SODIUM",
              "ROCEPHIN": "ROCEPHIN",
              "SUPRAX": "SUPRAX"
            },
            "AIDAREX PHARMACEUTICALS": {
              "CEFDINIR": "CEFDINIR",
              "CEFTRIAXONE SODIUM": "CEFTRIAXONE SODIUM"
            },
            "APOTEX": {
              "CEFTRIAXONE SODIUM": "CEFTRIAXONE SODIUM"
            },
            "ASCEND LABORATORIES": {
              "CEFIXIME": "CEFIXIME"
            },
            "AUROBINDO PHARMA": {
              "CEFDINIR": "CEFDINIR",
              "CEFIXIME": "CEFIXIME",
              "CEFPODOXIME PROXETIL": "CEFPODOXIME PROXETIL"
            },
            "B. BRAUN MEDICAL": {
              "CEFTAZIDIME AND DEXTROSE": "CEFTAZIDIME AND DEXTROSE",
              "CEFTRIAXONE SODIUM-DEXTROSE": "CEFTRIAXONE SODIUM-DEXTROSE"
            },
            "BAXTER HEALTHCARE CORP": {
              "CEFTRIAXONE SODIUM IN DEXTROSE": "CEFTRIAXONE SODIUM IN DEXTROSE",
              "CLAFORAN IN D5W": "CLAFORAN IN D5W"
            },
            "BLUE POINT LABORATORIES": {
              "CEFDINIR": "CEFDINIR"
            },
            "BRYANT RANCH PREPACK": {
              "CEFDINIR": "CEFDINIR"
            },
            "CEPHAZONE PHARMA": {
              "CEFTRIAXONE SODIUM": "CEFTRIAXONE SODIUM"
            },
            "CIVICA": {
              "CEFTRIAXONE SODIUM": "CEFTRIAXONE SODIUM"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "CEFDINIR": "CEFDINIR"
            },
            "CURRAX PHARMACEUTICALS": {
              "CEDAX": "CEDAX"
            },
            "DENTON PHARMA": {
              "CEFDINIR": "CEFDINIR",
              "SUPRAX": "SUPRAX"
            },
            "DIRECT RX": {
              "CEFDINIR": "CEFDINIR"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "CEFIXIME": "CEFIXIME"
            },
            "FRESENIUS KABI USA": {
              "CEFTRIAXONE SODIUM": "CEFTRIAXONE SODIUM"
            },
            "GLAXO SMITH KLINE": {
              "TAZICEF": "TAZICEF"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "CEFDINIR": "CEFDINIR",
              "CEFPODOXIME PROXETIL": "CEFPODOXIME PROXETIL"
            },
            "HIKMA": {
              "CEFOTAXIME SODIUM": "CEFOTAXIME SODIUM",
              "CEFTRIAXONE SODIUM": "CEFTRIAXONE SODIUM"
            },
            "HOSPIRA": {
              "CEFOTAXIME SODIUM": "CEFOTAXIME SODIUM",
              "CEFTRIAXONE SODIUM": "CEFTRIAXONE SODIUM",
              "CLAFORAN": "CLAFORAN",
              "TAZICEF": "TAZICEF"
            },
            "LUPIN PHARMACEUTICALS": {
              "CEFDINIR": "CEFDINIR",
              "CEFIXIME": "CEFIXIME",
              "CEFTRIAXONE SODIUM": "CEFTRIAXONE SODIUM",
              "SUPRAX": "SUPRAX"
            },
            "MACOVEN PHARMACEUTICALS": {
              "CEFTIBUTEN": "CEFTIBUTEN"
            },
            "METHAPHARM": {
              "SPECTRACEF": "SPECTRACEF"
            },
            "NORTHSTAR RX": {
              "CEFDINIR": "CEFDINIR",
              "CEFIXIME": "CEFIXIME",
              "CEFPODOXIME PROXETIL": "CEFPODOXIME PROXETIL"
            },
            "NOVAPLUS/APOTEX USA": {
              "CEFTRIAXONE SODIUM": "CEFTRIAXONE SODIUM"
            },
            "NOVAPLUS/HOSPIRA": {
              "CEFTRIAXONE SODIUM": "CEFTRIAXONE SODIUM",
              "TAZICEF": "TAZICEF"
            },
            "NOVAPLUS/SANDOZ": {
              "CEFTRIAXONE SODIUM": "CEFTRIAXONE SODIUM"
            },
            "ORCHID HEALTHCARE": {
              "CEFDINIR": "CEFDINIR"
            },
            "ORCHIDPHARMA": {
              "CEFDINIR": "CEFDINIR",
              "CEFPODOXIME PROXETIL": "CEFPODOXIME PROXETIL"
            },
            "PAR PHARMACEUTICAL": {
              "CEFDINIR": "CEFDINIR"
            },
            "PDRX PHARMACEUTICAL": {
              "CEFDINIR": "CEFDINIR",
              "CEFPODOXIME PROXETIL": "CEFPODOXIME PROXETIL"
            },
            "PDRX PHARMACEUTICALS": {
              "CEFDINIR": "CEFDINIR"
            },
            "PHARMA ROMLEV": {
              "CEFDITOREN PIVOXIL": "CEFDITOREN PIVOXIL"
            },
            "PIRAMAL CRITICAL CARE": {
              "CEFTRIAXONE SODIUM": "CEFTRIAXONE SODIUM"
            },
            "PREFERRED PHARMACEUTICALS": {
              "CEFDINIR": "CEFDINIR"
            },
            "PROFICIENT RX": {
              "CEFDINIR": "CEFDINIR",
              "CEFTRIAXONE SODIUM": "CEFTRIAXONE SODIUM"
            },
            "QUALITY CARE": {
              "CEFDINIR": "CEFDINIR",
              "CEFTRIAXONE SODIUM": "CEFTRIAXONE SODIUM",
              "ROCEPHIN": "ROCEPHIN"
            },
            "QUALITY CARE PRODUCTS": {
              "CEFDINIR": "CEFDINIR"
            },
            "RISING PHARMACEUTICALS": {
              "CEFDINIR": "CEFDINIR",
              "CEFPODOXIME PROXETIL": "CEFPODOXIME PROXETIL"
            },
            "RXCHANGE CO": {
              "CEFPODOXIME PROXETIL": "CEFPODOXIME PROXETIL"
            },
            "SAGENT PHARMACEUTICAL": {
              "CEFTAZIDIME": "CEFTAZIDIME",
              "CEFTRIAXONE SODIUM": "CEFTRIAXONE SODIUM"
            },
            "SAMSON MEDICAL TECH. LLC": {
              "CEFTRIAXONE SODIUM": "CEFTRIAXONE SODIUM"
            },
            "SANDOZ": {
              "CEFDINIR": "CEFDINIR",
              "CEFPODOXIME PROXETIL": "CEFPODOXIME PROXETIL",
              "CEFTAZIDIME": "CEFTAZIDIME",
              "CEFTRIAXONE SODIUM": "CEFTRIAXONE SODIUM"
            },
            "SOLUTECH PHARMACEUTICALS": {
              "CEFTRI-IM": "CEFTRI-IM",
              "CEFTRISOL PLUS": "CEFTRISOL PLUS"
            },
            "STERIMAX": {
              "CEFOTAXIME SODIUM": "CEFOTAXIME SODIUM"
            },
            "TELIGENT": {
              "FORTAZ": "FORTAZ",
              "FORTAZ IN D5W": "FORTAZ IN D5W"
            },
            "TEVA PHARMACEUTICALS USA": {
              "CEFDINIR": "CEFDINIR"
            },
            "WG CRITICAL CARE": {
              "CEFTAZIDIME": "CEFTAZIDIME",
              "CEFTRIAXONE SODIUM": "CEFTRIAXONE SODIUM"
            },
            "WOCKHARDT USA": {
              "CEFTRIAXONE SODIUM": "CEFTRIAXONE SODIUM"
            }
          }
        },
        "Cephalosporins - 4th Generation": {
          "Cephalosporins - 4th Generation": {
            "APOTEX": {
              "CEFEPIME HCL": "CEFEPIME HCL"
            },
            "B. BRAUN MEDICAL": {
              "CEFEPIME-DEXTROSE": "CEFEPIME-DEXTROSE"
            },
            "BAXTER HEALTHCARE CORP": {
              "CEFEPIME HCL": "CEFEPIME HCL"
            },
            "FRESENIUS KABI USA": {
              "CEFEPIME HCL": "CEFEPIME HCL"
            },
            "HOSPIRA": {
              "CEFEPIME HCL": "CEFEPIME HCL",
              "MAXIPIME": "MAXIPIME"
            },
            "MEITHEAL PHARMACEUTICALS": {
              "CEFEPIME HCL": "CEFEPIME HCL"
            },
            "NOVAPLUS/APOTEX USA": {
              "CEFEPIME HCL": "CEFEPIME HCL"
            },
            "PIRAMAL CRITICAL CARE": {
              "CEFEPIME HCL": "CEFEPIME HCL"
            },
            "SAGENT PHARMACEUTICAL": {
              "CEFEPIME HCL": "CEFEPIME HCL"
            },
            "SAMSON MEDICAL TECH. LLC": {
              "CEFEPIME HCL": "CEFEPIME HCL"
            },
            "SANDOZ": {
              "CEFEPIME HCL": "CEFEPIME HCL"
            },
            "WG CRITICAL CARE": {
              "CEFEPIME HCL": "CEFEPIME HCL"
            }
          }
        },
        "Cephalosporins - 5th Generation": {
          "Cephalosporins - 5th Generation": {
            "ALLERGAN": {
              "TEFLARO": "TEFLARO"
            }
          }
        },
        "Cephalosporins - Siderophores": {
          "Cephalosporins - Siderophores": {
            "SHIONOGI PHARMA": {
              "FETROJA": "FETROJA"
            }
          }
        }
      },
      "CHEMICALS": {
        "Acids, Bases, & Buffers": {
          "Acids": {
            "APOTHECA SUPPLY": {
              "HYDROCHLORIC ACID": "HYDROCHLORIC ACID",
              "PHOSPHORIC ACID": "PHOSPHORIC ACID"
            },
            "FAGRON": {
              "ACETIC ACID": "ACETIC ACID",
              "ACETIC ACID GLACIAL": "ACETIC ACID GLACIAL",
              "GLYCOLIC ACID": "GLYCOLIC ACID",
              "HYDROCHLORIC ACID": "HYDROCHLORIC ACID",
              "LACTIC ACID": "LACTIC ACID"
            },
            "HUMCO": {
              "LACTIC ACID": "LACTIC ACID",
              "OXALIC ACID": "OXALIC ACID"
            },
            "LETCO MEDICAL": {
              "ACETIC ACID GLACIAL": "ACETIC ACID GLACIAL",
              "GLYCOLIC ACID": "GLYCOLIC ACID",
              "HYDROCHLORIC ACID": "HYDROCHLORIC ACID",
              "LACTIC ACID": "LACTIC ACID"
            },
            "MEDISCA": {
              "ACETIC ACID GLACIAL": "ACETIC ACID GLACIAL",
              "GLYCOLIC ACID": "GLYCOLIC ACID",
              "HYDROCHLORIC ACID": "HYDROCHLORIC ACID",
              "LACTIC ACID": "LACTIC ACID"
            },
            "PCCA": {
              "ACETIC ACID GLACIAL": "ACETIC ACID GLACIAL",
              "FUMARIC ACID": "FUMARIC ACID",
              "GLYCOLIC ACID": "GLYCOLIC ACID",
              "HYDROCHLORIC ACID": "HYDROCHLORIC ACID",
              "LACTIC ACID": "LACTIC ACID",
              "NITRIC ACID": "NITRIC ACID",
              "OXALIC ACID DIHYDRATE": "OXALIC ACID DIHYDRATE",
              "PHOSPHORIC ACID": "PHOSPHORIC ACID",
              "SULFURIC ACID": "SULFURIC ACID"
            },
            "SPECTRUM": {
              "ACETIC ACID GLACIAL": "ACETIC ACID GLACIAL",
              "FUMARIC ACID": "FUMARIC ACID",
              "HYDROCHLORIC ACID": "HYDROCHLORIC ACID",
              "LACTIC ACID": "LACTIC ACID",
              "PHOSPHORIC ACID": "PHOSPHORIC ACID",
              "SULFURIC ACID": "SULFURIC ACID"
            }
          },
          "Bases": {
            "FAGRON": {
              "POTASSIUM HYDROXIDE": "POTASSIUM HYDROXIDE",
              "SODIUM HYDROXIDE": "SODIUM HYDROXIDE"
            },
            "FREEDOM PHARMACEUTICALS": {
              "SODIUM HYDROXIDE": "SODIUM HYDROXIDE"
            },
            "GORDON": {
              "POTASSIUM HYDROXIDE": "POTASSIUM HYDROXIDE",
              "SODIUM HYDROXIDE": "SODIUM HYDROXIDE"
            },
            "KALCHEM INTERNATIONAL": {
              "POTASSIUM HYDROXIDE": "POTASSIUM HYDROXIDE"
            },
            "LETCO MEDICAL": {
              "POTASSIUM HYDROXIDE": "POTASSIUM HYDROXIDE",
              "SODIUM HYDROXIDE": "SODIUM HYDROXIDE"
            },
            "MEDISCA": {
              "POTASSIUM HYDROXIDE": "POTASSIUM HYDROXIDE",
              "SODIUM HYDROXIDE": "SODIUM HYDROXIDE"
            },
            "PCCA": {
              "AMMONIUM HYDROXIDE": "AMMONIUM HYDROXIDE",
              "POTASSIUM HYDROXIDE": "POTASSIUM HYDROXIDE",
              "SODIUM HYDROXIDE": "SODIUM HYDROXIDE"
            },
            "SPECTRUM": {
              "AMMONIUM HYDROXIDE": "AMMONIUM HYDROXIDE",
              "POTASSIUM HYDROXIDE": "POTASSIUM HYDROXIDE",
              "SODIUM HYDROXIDE": "SODIUM HYDROXIDE"
            }
          },
          "Buffers": {
            "FAGRON": {
              "SODIUM BORATE": "SODIUM BORATE",
              "TARTARIC ACID": "TARTARIC ACID"
            },
            "HUMCO": {
              "BORAX": "BORAX"
            },
            "PCCA": {
              "SODIUM BORATE DECAHYDRATE": "SODIUM BORATE DECAHYDRATE",
              "SODIUM CARBONATE ANHYDROUS": "SODIUM CARBONATE ANHYDROUS",
              "TARTARIC ACID": "TARTARIC ACID"
            },
            "SPECTRUM": {
              "SODIUM BORATE DECAHYDRATE": "SODIUM BORATE DECAHYDRATE",
              "SODIUM CARBONATE ANHYDROUS": "SODIUM CARBONATE ANHYDROUS",
              "SODIUM CARBONATE MONOHYDRATE": "SODIUM CARBONATE MONOHYDRATE",
              "TARTARIC ACID": "TARTARIC ACID"
            }
          }
        },
        "Bulk Chemical Compound Kits": {
          "Bulk Chemical Compound Kits": {
            "ALVIX LABORATORIES": {
              "GAPEAM BUDIBAC": "GAPEAM BUDIBAC"
            }
          }
        },
        "Bulk Chemicals": {
          "Bulk Chemicals": {
            "HUMCO": {
              "BAY RUM": "BAY RUM"
            },
            "LETCO MEDICAL": {
              "BIORE HYDRATING MOISTURIZER": "BIORE HYDRATING MOISTURIZER",
              "CUCUMBER MELON": "CUCUMBER MELON",
              "DRAKKAR NOIR": "DRAKKAR NOIR",
              "FRESH LINEN FRAGRANCE": "FRESH LINEN FRAGRANCE",
              "VICTORIAS SECRET VANILLA LACE": "VICTORIAS SECRET VANILLA LACE"
            },
            "MEDISCA": {
              "POLYOX WSR-301": "POLYOX WSR-301"
            },
            "PCCA": {
              "NATAPRES": "NATAPRES",
              "POLYOX WSR-301": "POLYOX WSR-301"
            },
            "SPECTRUM": {
              "ETHYLPARABEN": "ETHYLPARABEN",
              "FERRIC PYROPHOSPHATE": "FERRIC PYROPHOSPHATE",
              "L-ASPARAGINE": "L-ASPARAGINE",
              "SODIUM PYROPHOSPHATE": "SODIUM PYROPHOSPHATE"
            }
          }
        },
        "Bulk Chemicals - A\\'s": {},
        "Bulk Chemicals - B\\'s": {},
        "Bulk Chemicals - C\\'s": {},
        "Bulk Chemicals - D\\'s": {},
        "Bulk Chemicals - E\\'s": {},
        "Bulk Chemicals - F\\'s": {},
        "Bulk Chemicals - G\\'s": {},
        "Bulk Chemicals - H\\'s": {},
        "Bulk Chemicals - I\\'s": {},
        "Bulk Chemicals - J\\'s": {},
        "Bulk Chemicals - K\\'s": {},
        "Bulk Chemicals - L\\'s": {},
        "Bulk Chemicals - M\\'s": {},
        "Bulk Chemicals - N\\'s": {},
        "Bulk Chemicals - O\\'s": {},
        "Bulk Chemicals - P\\'s": {},
        "Bulk Chemicals - Q\\'s": {},
        "Bulk Chemicals - R\\'s": {},
        "Bulk Chemicals - S\\'s": {},
        "Bulk Chemicals - T\\'s": {},
        "Bulk Chemicals - U\\'s": {},
        "Bulk Chemicals - V\\'s": {},
        "Bulk Chemicals - W\\'s": {},
        "Bulk Chemicals - X": {
          "Bulk Chemicals - Xy": {
            "FAGRON": {
              "XYLITOL": "XYLITOL"
            },
            "FREEDOM PHARMACEUTICALS": {
              "XYLITOL": "XYLITOL"
            },
            "KALCHEM INTERNATIONAL": {
              "XYLAZINE HCL": "XYLAZINE HCL"
            },
            "LETCO MEDICAL": {
              "XYLAZINE HCL": "XYLAZINE HCL",
              "XYLITOL": "XYLITOL"
            },
            "MEDISCA": {
              "XYLAZINE HCL": "XYLAZINE HCL",
              "XYLITOL": "XYLITOL"
            },
            "PCCA": {
              "XYLAZINE HCL": "XYLAZINE HCL",
              "XYLITOL": "XYLITOL",
              "XYLOMETAZOLINE HCL": "XYLOMETAZOLINE HCL"
            },
            "SPECTRUM": {
              "XYLAZINE HCL": "XYLAZINE HCL",
              "XYLITOL": "XYLITOL"
            }
          }
        },
        "Bulk Chemicals - Y\\'s": {},
        "Bulk Chemicals - Z\\'s": {},
        "Liquids": {
          "Essential Oils": {
            "BERGEN BRUNSWIG": {
              "GNP CINNAMON": "GNP CINNAMON",
              "GNP EUCALYPTUS": "GNP EUCALYPTUS"
            },
            "FAGRON": {
              "EUCALYPTUS OIL": "EUCALYPTUS OIL",
              "EUGENOL": "EUGENOL",
              "LAVENDER OIL": "LAVENDER OIL",
              "ROSE OIL": "ROSE OIL"
            },
            "HUMCO": {
              "ANISE": "ANISE",
              "BERGAMOT OIL": "BERGAMOT OIL",
              "CEDARWOOD OIL": "CEDARWOOD OIL",
              "CINNAMON OIL": "CINNAMON OIL",
              "CITRONELLA OIL": "CITRONELLA OIL",
              "CLOVE OIL": "CLOVE OIL",
              "EUCALYPTUS OIL": "EUCALYPTUS OIL",
              "GERANIUM OIL": "GERANIUM OIL",
              "GRAPEFRUIT OIL": "GRAPEFRUIT OIL",
              "LAVENDER OIL": "LAVENDER OIL",
              "LEMON OIL": "LEMON OIL",
              "LEMONGRASS OIL": "LEMONGRASS OIL",
              "LIME OIL": "LIME OIL",
              "ORANGE OIL": "ORANGE OIL",
              "PEPPERMINT OIL": "PEPPERMINT OIL",
              "ROSEMARY OIL": "ROSEMARY OIL",
              "TANGERINE OIL": "TANGERINE OIL",
              "TURPENTINE OIL": "TURPENTINE OIL"
            },
            "KALCHEM INTERNATIONAL": {
              "JUNIPER TAR": "JUNIPER TAR"
            },
            "LETCO MEDICAL": {
              "CELERY SEED": "CELERY SEED",
              "CINNAMON OIL": "CINNAMON OIL",
              "EUCALYPTUS OIL": "EUCALYPTUS OIL",
              "LAVENDER OIL": "LAVENDER OIL",
              "LEMON OIL": "LEMON OIL",
              "LIME OIL": "LIME OIL",
              "ORANGE OIL": "ORANGE OIL",
              "PEPPERMINT OIL": "PEPPERMINT OIL",
              "SPEARMINT OIL": "SPEARMINT OIL",
              "TANGERINE": "TANGERINE"
            },
            "MEDISCA": {
              "CLOVE OIL": "CLOVE OIL",
              "EUCALYPTUS OIL": "EUCALYPTUS OIL",
              "LAVENDER OIL": "LAVENDER OIL",
              "PEPPERMINT OIL": "PEPPERMINT OIL"
            },
            "PCCA": {
              "ACTIPHYTE OF LEMONGRASS": "ACTIPHYTE OF LEMONGRASS",
              "CEDAR LEAF OIL": "CEDAR LEAF OIL",
              "CITRONELLA OIL": "CITRONELLA OIL",
              "CLOVE OIL": "CLOVE OIL",
              "GERANIUM OIL": "GERANIUM OIL",
              "JUNIPER TAR": "JUNIPER TAR",
              "LAVENDER OIL": "LAVENDER OIL",
              "NIAOULI": "NIAOULI",
              "PINE OIL": "PINE OIL",
              "ROSE OIL": "ROSE OIL",
              "ROSEMARY OIL": "ROSEMARY OIL",
              "SASSAFRAS OIL": "SASSAFRAS OIL"
            },
            "SPECTRUM": {
              "ANISE": "ANISE",
              "BAY OIL": "BAY OIL",
              "BERGAMOT OIL": "BERGAMOT OIL",
              "CARAWAY OIL": "CARAWAY OIL",
              "CEDAR LEAF OIL": "CEDAR LEAF OIL",
              "CITRONELLA OIL": "CITRONELLA OIL",
              "CLOVE OIL": "CLOVE OIL",
              "EUCALYPTUS OIL": "EUCALYPTUS OIL",
              "EUGENOL": "EUGENOL",
              "MUSTARD OIL": "MUSTARD OIL",
              "ORANGE OIL": "ORANGE OIL",
              "PEPPERMINT OIL": "PEPPERMINT OIL",
              "SPEARMINT OIL": "SPEARMINT OIL"
            }
          },
          "Fixed Oils": {
            "CHAIN DRUG CONSORTIUM": {
              "CASTOR OIL": "CASTOR OIL",
              "SWEET OIL": "SWEET OIL"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC CASTOR OIL": "QC CASTOR OIL",
              "QC SWEET OIL": "QC SWEET OIL"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS CASTOR OIL": "CVS CASTOR OIL"
            },
            "FAGRON": {
              "ALMOND OIL (SWEET)": "ALMOND OIL (SWEET)",
              "CASTOR OIL": "CASTOR OIL",
              "COCONUT OIL": "COCONUT OIL",
              "COTTONSEED OIL": "COTTONSEED OIL",
              "OLIVE OIL": "OLIVE OIL",
              "PEANUT OIL": "PEANUT OIL",
              "SAFFLOWER OIL": "SAFFLOWER OIL",
              "SESAME OIL": "SESAME OIL",
              "SOYBEAN OIL": "SOYBEAN OIL"
            },
            "FREEDOM PHARMACEUTICALS": {
              "ALMOND OIL (SWEET)": "ALMOND OIL (SWEET)",
              "GLYCINE SOYA EXTRACT": "GLYCINE SOYA EXTRACT",
              "SAFFLOWER OIL": "SAFFLOWER OIL"
            },
            "HUMCO": {
              "ALMOND OIL (SWEET)": "ALMOND OIL (SWEET)",
              "CASTOR OIL": "CASTOR OIL",
              "COCONUT OIL": "COCONUT OIL",
              "COTTONSEED OIL": "COTTONSEED OIL",
              "OLIVE OIL": "OLIVE OIL",
              "SWEET OIL": "SWEET OIL"
            },
            "KALCHEM INTERNATIONAL": {
              "BASE G ALMOND OIL (SWEET)": "BASE G ALMOND OIL (SWEET)",
              "CASTOR OIL": "CASTOR OIL"
            },
            "LETCO MEDICAL": {
              "ALMOND OIL (SWEET)": "ALMOND OIL (SWEET)",
              "CASTOR OIL": "CASTOR OIL",
              "COTTONSEED OIL": "COTTONSEED OIL",
              "SESAME OIL": "SESAME OIL"
            },
            "MCKESSON": {
              "HM CASTOR OIL": "HM CASTOR OIL"
            },
            "MCKESSON SUNMARK": {
              "SM SWEET OIL": "SM SWEET OIL"
            },
            "MEDISCA": {
              "ALMOND OIL (SWEET)": "ALMOND OIL (SWEET)",
              "CASTOR OIL": "CASTOR OIL",
              "COCONUT OIL": "COCONUT OIL",
              "COTTONSEED OIL": "COTTONSEED OIL",
              "OLIVE OIL": "OLIVE OIL",
              "SESAME OIL": "SESAME OIL",
              "SOYBEAN OIL": "SOYBEAN OIL"
            },
            "NATURES BOUNTY": {
              "ORGANIC COCONUT OIL": "ORGANIC COCONUT OIL"
            },
            "PCCA": {
              "BASE G ALMOND OIL (SWEET)": "BASE G ALMOND OIL (SWEET)",
              "CASTOR OIL": "CASTOR OIL",
              "COCONUT OIL": "COCONUT OIL",
              "COTTONSEED OIL": "COTTONSEED OIL",
              "GLYCINE SOYA PROTEIN": "GLYCINE SOYA PROTEIN",
              "LINSEED OIL": "LINSEED OIL",
              "MACADAMIA NUT OIL": "MACADAMIA NUT OIL",
              "OLIVE OIL": "OLIVE OIL",
              "PEANUT OIL": "PEANUT OIL",
              "SAFFLOWER OIL": "SAFFLOWER OIL",
              "SESAME OIL": "SESAME OIL",
              "SOYBEAN OIL": "SOYBEAN OIL",
              "WA-001 EXPERIMENTAL SOIL SURFA": "WA-001 EXPERIMENTAL SOIL SURFA"
            },
            "SPECTRUM": {
              "ALMOND OIL (SWEET)": "ALMOND OIL (SWEET)",
              "CASTOR OIL": "CASTOR OIL",
              "COTTONSEED OIL": "COTTONSEED OIL",
              "OLIVE OIL": "OLIVE OIL",
              "PEANUT OIL": "PEANUT OIL",
              "SAFFLOWER OIL": "SAFFLOWER OIL",
              "SESAME OIL": "SESAME OIL",
              "SOYBEAN OIL": "SOYBEAN OIL"
            },
            "WAL-MART": {
              "COCONUT OIL": "COCONUT OIL"
            },
            "WALGREENS": {
              "SWEET OIL": "SWEET OIL"
            }
          },
          "Liquid Combinations": {
            "SPECTRUM": {
              "ALCOHOL ANHYDROUS": "ALCOHOL ANHYDROUS"
            }
          },
          "Liquids": {
            "BERGEN BRUNSWIG": {
              "GNP PEPPERMINT SPIRIT": "GNP PEPPERMINT SPIRIT",
              "GNP SODIUM SILICATE": "GNP SODIUM SILICATE"
            },
            "BPI LABS LLC": {
              "GLYCERIN": "GLYCERIN"
            },
            "CAMBER PHARMACEUTICALS": {
              "GLYCERIN": "GLYCERIN"
            },
            "DENISON LABS": {
              "GLYCERIN": "GLYCERIN",
              "PEPPERMINT SPIRIT": "PEPPERMINT SPIRIT"
            },
            "FAGRON": {
              "BENZYL BENZOATE": "BENZYL BENZOATE",
              "DIMETHYL SULFOXIDE": "DIMETHYL SULFOXIDE",
              "GLYCERIN": "GLYCERIN",
              "ISOPROPYL PALMITATE": "ISOPROPYL PALMITATE",
              "POLYSORBATE 20": "POLYSORBATE 20",
              "POLYSORBATE 80": "POLYSORBATE 80",
              "UNDECYLENIC ACID": "UNDECYLENIC ACID"
            },
            "FREEDOM PHARMACEUTICALS": {
              "BENZYL BENZOATE": "BENZYL BENZOATE",
              "DIMETHYL SULFOXIDE": "DIMETHYL SULFOXIDE",
              "GLYCERIN": "GLYCERIN"
            },
            "HUMCO": {
              "CAMPHOR SPIRIT": "CAMPHOR SPIRIT",
              "GLYCERIN": "GLYCERIN",
              "PEPPERMINT SPIRIT": "PEPPERMINT SPIRIT",
              "SODIUM SILICATE": "SODIUM SILICATE"
            },
            "KALCHEM INTERNATIONAL": {
              "BENZYL BENZOATE": "BENZYL BENZOATE",
              "GLYCERINE": "GLYCERINE",
              "ISOPROPYL PALMITATE": "ISOPROPYL PALMITATE",
              "POLYSORBATE 80": "POLYSORBATE 80"
            },
            "LEADER BRAND PRODUCTS": {
              "CAMPHOR SPIRIT": "CAMPHOR SPIRIT"
            },
            "LETCO MEDICAL": {
              "BENZYL BENZOATE": "BENZYL BENZOATE",
              "DIMETHYL SULFOXIDE": "DIMETHYL SULFOXIDE",
              "GLYCERIN": "GLYCERIN",
              "GLYCEROL FORMAL": "GLYCEROL FORMAL",
              "ISOPROPYL PALMITATE": "ISOPROPYL PALMITATE",
              "POLYSORBATE 20": "POLYSORBATE 20",
              "POLYSORBATE 80": "POLYSORBATE 80"
            },
            "MCKESSON SUNMARK": {
              "SM CAMPHOR SPIRIT": "SM CAMPHOR SPIRIT"
            },
            "MEDISCA": {
              "BENZYL BENZOATE": "BENZYL BENZOATE",
              "DIMETHYL SULFOXIDE": "DIMETHYL SULFOXIDE",
              "GLYCERIN": "GLYCERIN",
              "ISOPROPYL PALMITATE": "ISOPROPYL PALMITATE",
              "POLYSORBATE 80": "POLYSORBATE 80"
            },
            "MYLAN INSTITUTIONAL": {
              "CRYOSERV": "CRYOSERV"
            },
            "PARNELL": {
              "PRETZ": "PRETZ"
            },
            "PCCA": {
              "AMMONIUM LACTATE": "AMMONIUM LACTATE",
              "BENZYL BENZOATE": "BENZYL BENZOATE",
              "CHLORHEXIDINE GLUCONATE": "CHLORHEXIDINE GLUCONATE",
              "DIMETHYL SULFOXIDE": "DIMETHYL SULFOXIDE",
              "GLYCERIN": "GLYCERIN",
              "GUAIACOL": "GUAIACOL",
              "ISOPROPYL PALMITATE": "ISOPROPYL PALMITATE",
              "PINE TAR": "PINE TAR",
              "POLYSORBATE 20": "POLYSORBATE 20",
              "POLYSORBATE 40": "POLYSORBATE 40",
              "POLYSORBATE 60": "POLYSORBATE 60",
              "POLYSORBATE 80": "POLYSORBATE 80",
              "UNDECYLENIC ACID": "UNDECYLENIC ACID"
            },
            "PERRIGO": {
              "GLYCERIN": "GLYCERIN"
            },
            "SPECTRUM": {
              "BENZYL BENZOATE": "BENZYL BENZOATE",
              "CHLORHEXIDINE GLUCONATE": "CHLORHEXIDINE GLUCONATE",
              "DIMETHYL SULFOXIDE": "DIMETHYL SULFOXIDE",
              "GLYCERIN": "GLYCERIN",
              "GUAIACOL": "GUAIACOL",
              "ISOPROPYL PALMITATE": "ISOPROPYL PALMITATE",
              "POLYSORBATE 20": "POLYSORBATE 20",
              "POLYSORBATE 40": "POLYSORBATE 40",
              "POLYSORBATE 60": "POLYSORBATE 60",
              "POLYSORBATE 80": "POLYSORBATE 80",
              "UNDECYLENIC ACID": "UNDECYLENIC ACID"
            },
            "VI-JON": {
              "GLYCERIN": "GLYCERIN"
            },
            "WALGREENS": {
              "GLYCERIN": "GLYCERIN"
            },
            "WEAVER PHARMACEUTICALS": {
              "DIMETHYL SULFOXIDE": "DIMETHYL SULFOXIDE"
            }
          },
          "Solvents": {
            "AAPER ALCOHOL & CHEMICAL CO.": {
              "ACETONE": "ACETONE",
              "ETHYL ALCOHOL": "ETHYL ALCOHOL",
              "ISOPROPYL ALCOHOL": "ISOPROPYL ALCOHOL",
              "METHANOL": "METHANOL"
            },
            "ACUTE CARE PHARMACEUTICALS": {
              "ISOPROPYL ALCOHOL": "ISOPROPYL ALCOHOL"
            },
            "AMERICAN SALES COMPANY": {
              "ISOPROPYL ALCOHOL": "ISOPROPYL ALCOHOL"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP ETHYL ALCOHOL": "GNP ETHYL ALCOHOL",
              "GNP ISOPROPYL ALCOHOL": "GNP ISOPROPYL ALCOHOL",
              "GNP RUBBING ALCOHOL": "GNP RUBBING ALCOHOL"
            },
            "AURORA HEALTHCARE": {
              "ISOPROPYL ALCOHOL (RUBBING)": "ISOPROPYL ALCOHOL (RUBBING)"
            },
            "BERGEN BRUNSWIG": {
              "GNP ALCOHOL DENATURED": "GNP ALCOHOL DENATURED",
              "GNP ETHYL RUBBING ALCOHOL": "GNP ETHYL RUBBING ALCOHOL",
              "GNP ISOPROPYL ALCOHOL": "GNP ISOPROPYL ALCOHOL"
            },
            "CHAIN DRUG CONSORTIUM": {
              "ETHYL ALCOHOL (RUBBING)": "ETHYL ALCOHOL (RUBBING)",
              "ISOPROPYL ALCOHOL": "ISOPROPYL ALCOHOL",
              "ISOPROPYL ALCOHOL (RUBBING)": "ISOPROPYL ALCOHOL (RUBBING)"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC ISOPROPYL RUBBING ALCOHOL": "QC ISOPROPYL RUBBING ALCOHOL"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS ALCOHOL": "CVS ALCOHOL",
              "CVS ETHYL ALCOHOL": "CVS ETHYL ALCOHOL",
              "CVS ISOPROPYL ALCOHOL": "CVS ISOPROPYL ALCOHOL",
              "CVS ISOPROPYL RUBBING ALCOHOL": "CVS ISOPROPYL RUBBING ALCOHOL",
              "CVS RUBBING ALCOHOL": "CVS RUBBING ALCOHOL"
            },
            "DENISON LABS": {
              "ACETONE": "ACETONE",
              "ALCOHOL (RUBBING)": "ALCOHOL (RUBBING)",
              "ISOPROPYL ALCOHOL": "ISOPROPYL ALCOHOL"
            },
            "EQUALINE": {
              "EQL ETHYL ALCOHOL (RUBBING)": "EQL ETHYL ALCOHOL (RUBBING)",
              "EQL ISOPROPYL ALCOHOL": "EQL ISOPROPYL ALCOHOL",
              "EQL ISOPROPYL RUBBING ALCOHOL": "EQL ISOPROPYL RUBBING ALCOHOL"
            },
            "FAGRON": {
              "ACETONE": "ACETONE",
              "CHLOROFORM": "CHLOROFORM",
              "ETHYL ALCOHOL": "ETHYL ALCOHOL",
              "ISOPROPYL ALCOHOL": "ISOPROPYL ALCOHOL"
            },
            "GEISS DESTIN & DUNN": {
              "ETHYL ALCOHOL (RUBBING)": "ETHYL ALCOHOL (RUBBING)",
              "GOODSENSE ISOPROPYL ALCOHOL": "GOODSENSE ISOPROPYL ALCOHOL"
            },
            "HUMCO": {
              "ACETONE": "ACETONE",
              "ALCOHOL (RUBBING)": "ALCOHOL (RUBBING)",
              "ALCOHOL DENATURED": "ALCOHOL DENATURED",
              "ISOPROPYL ALCOHOL": "ISOPROPYL ALCOHOL",
              "ISOPROPYL ALCOHOL (RUBBING)": "ISOPROPYL ALCOHOL (RUBBING)",
              "TURPENTINE": "TURPENTINE"
            },
            "KALCHEM INTERNATIONAL": {
              "ISOPROPYL ALCOHOL": "ISOPROPYL ALCOHOL"
            },
            "LEADER BRAND PRODUCTS": {
              "ALCOHOL (RUBBING)": "ALCOHOL (RUBBING)",
              "ISOPROPYL ALCOHOL": "ISOPROPYL ALCOHOL",
              "ISOPROPYL ALCOHOL (RUBBING)": "ISOPROPYL ALCOHOL (RUBBING)",
              "ISOPROPYL ALCOHOL, RUBBING": "ISOPROPYL ALCOHOL, RUBBING",
              "RUBBING ALCOHOL": "RUBBING ALCOHOL"
            },
            "LETCO MEDICAL": {
              "ACETONE": "ACETONE",
              "ETHYL ALCOHOL": "ETHYL ALCOHOL",
              "ISOPROPYL ALCOHOL": "ISOPROPYL ALCOHOL"
            },
            "MAJOR PHARMACEUTICALS": {
              "ISOPROPYL ALCOHOL (RUBBING)": "ISOPROPYL ALCOHOL (RUBBING)"
            },
            "MARLEX PHARMACEUTICALS": {
              "ISOPROPYL ALCOHOL": "ISOPROPYL ALCOHOL"
            },
            "MCKESSON": {
              "HM ETHYL RUBBING ALCOHOL": "HM ETHYL RUBBING ALCOHOL",
              "HM ISOPROPYL ALCOHOL": "HM ISOPROPYL ALCOHOL"
            },
            "MCKESSON SUNMARK": {
              "SM ALCOHOL": "SM ALCOHOL",
              "SM ETHYL ALCOHOL (RUBBING)": "SM ETHYL ALCOHOL (RUBBING)",
              "SM ISOPROPYL ALCOHOL": "SM ISOPROPYL ALCOHOL"
            },
            "MEDICINE SHOPPE": {
              "ISOPROPYL ALCOHOL": "ISOPROPYL ALCOHOL",
              "ISOPROPYL ALCOHOL (RUBBING)": "ISOPROPYL ALCOHOL (RUBBING)"
            },
            "MEDISCA": {
              "CHLOROFORM": "CHLOROFORM",
              "ISOPROPANOL": "ISOPROPANOL",
              "ISOPROPYL ALCOHOL": "ISOPROPYL ALCOHOL"
            },
            "MEDLINE/DERMAL MANAGEMENT": {
              "ISOPROPYL ALCOHOL": "ISOPROPYL ALCOHOL"
            },
            "MEIJER": {
              "MEIJER ISOPROPYL ALCOHOL": "MEIJER ISOPROPYL ALCOHOL"
            },
            "PCCA": {
              "ACETONE": "ACETONE",
              "CHLOROFORM": "CHLOROFORM",
              "ETHER": "ETHER",
              "ETHYL ALCOHOL": "ETHYL ALCOHOL",
              "ISOPROPYL ALCOHOL": "ISOPROPYL ALCOHOL",
              "METHANOL": "METHANOL"
            },
            "PERRIGO": {
              "ISOPROPYL ALCOHOL": "ISOPROPYL ALCOHOL"
            },
            "RITE AID CORPORATION": {
              "RA ETHYL ALCOHOL": "RA ETHYL ALCOHOL",
              "RA ETHYL RUBBING ALCOHOL": "RA ETHYL RUBBING ALCOHOL",
              "RA ISOPROPYL ALCOHOL": "RA ISOPROPYL ALCOHOL",
              "RA ISOPROPYL RUBBING ALCOHOL": "RA ISOPROPYL RUBBING ALCOHOL"
            },
            "SPECTRUM": {
              "ACETONE": "ACETONE",
              "ALCOHOL, USP": "ALCOHOL, USP",
              "ETHER": "ETHER",
              "ISOPROPYL ALCOHOL": "ISOPROPYL ALCOHOL",
              "METHYL ALCOHOL": "METHYL ALCOHOL",
              "TURPENTINE": "TURPENTINE"
            },
            "VI-JON": {
              "ETHYL ALCOHOL (RUBBING)": "ETHYL ALCOHOL (RUBBING)",
              "ISOPROPYL ALCOHOL": "ISOPROPYL ALCOHOL"
            },
            "WAL-MART": {
              "ISOPROPYL ALCOHOL": "ISOPROPYL ALCOHOL",
              "ISOPROPYL ALCOHOL (RUBBING)": "ISOPROPYL ALCOHOL (RUBBING)"
            },
            "WALGREENS": {
              "ETHYL ALCOHOL (RUBBING)": "ETHYL ALCOHOL (RUBBING)",
              "ISOPROPYL ALCOHOL": "ISOPROPYL ALCOHOL",
              "ISOPROPYL ALCOHOL (RUBBING)": "ISOPROPYL ALCOHOL (RUBBING)"
            }
          }
        },
        "Semi-Solids": {
          "Semi-Solids": {
            "FAGRON": {
              "COAL TAR": "COAL TAR",
              "PERUVIAN BALSAM": "PERUVIAN BALSAM"
            },
            "HUMCO": {
              "COAL TAR EXTRACT": "COAL TAR EXTRACT",
              "PERUVIAN BALSAM": "PERUVIAN BALSAM"
            },
            "LETCO MEDICAL": {
              "COAL TAR": "COAL TAR",
              "PERUVIAN BALSAM": "PERUVIAN BALSAM"
            },
            "PCCA": {
              "COAL TAR": "COAL TAR",
              "PERUVIAN BALSAM": "PERUVIAN BALSAM"
            },
            "SPECTRUM": {
              "COAL TAR": "COAL TAR",
              "PERUVIAN BALSAM": "PERUVIAN BALSAM"
            }
          }
        },
        "Solids": {
          "Additional Solids": {
            "ALVIX LABORATORIES": {
              "KETOCONAZOLE": "KETOCONAZOLE",
              "UBIDECARENONE": "UBIDECARENONE"
            },
            "AMERICAN PHARMA INGREDIENTS": {
              "5-HTP": "5-HTP",
              "ALPROSTADIL": "ALPROSTADIL",
              "COENZYME Q10": "COENZYME Q10",
              "KETOCONAZOLE": "KETOCONAZOLE",
              "MELATONIN": "MELATONIN",
              "MINOXIDIL": "MINOXIDIL"
            },
            "API SOLUTIONS": {
              "DIMERCAPTOPROPANE-SULFONATE NA": "DIMERCAPTOPROPANE-SULFONATE NA"
            },
            "APOTHECA SUPPLY": {
              "ALUMINUM HYDROXIDE DRIED GEL": "ALUMINUM HYDROXIDE DRIED GEL",
              "COENZYME Q10": "COENZYME Q10"
            },
            "ATTIX PHARMACEUTICALS": {
              "COENZYME Q10": "COENZYME Q10"
            },
            "B & B PHARMACEUTICALS": {
              "COENZYME Q10": "COENZYME Q10"
            },
            "BPI LABS LLC": {
              "COENZYME Q10": "COENZYME Q10"
            },
            "DARMERICA": {
              "ALPROSTADIL": "ALPROSTADIL"
            },
            "ENCORE SCIENTIFIC": {
              "ALPROSTADIL": "ALPROSTADIL",
              "COENZYME Q10": "COENZYME Q10"
            },
            "FAGRON": {
              "ALLANTOIN": "ALLANTOIN",
              "ALPROSTADIL": "ALPROSTADIL",
              "ALUMINUM HYDROXIDE DRIED GEL": "ALUMINUM HYDROXIDE DRIED GEL",
              "CARBIDOPA": "CARBIDOPA",
              "COENZYME Q10": "COENZYME Q10",
              "DIMENHYDRINATE": "DIMENHYDRINATE",
              "EDETATE DISODIUM DIHYDRATE": "EDETATE DISODIUM DIHYDRATE",
              "EPINEPHRINE": "EPINEPHRINE",
              "HYDROXYTRYPTOPHAN L-5": "HYDROXYTRYPTOPHAN L-5",
              "KETOCONAZOLE": "KETOCONAZOLE",
              "KOJIC ACID": "KOJIC ACID",
              "MELATONIN": "MELATONIN",
              "MINOXIDIL": "MINOXIDIL",
              "PYRUVIC ACID": "PYRUVIC ACID",
              "TINIDAZOLE": "TINIDAZOLE"
            },
            "FREEDOM PHARMACEUTICALS": {
              "5-HYDROXY-L-TRYPTOPHAN": "5-HYDROXY-L-TRYPTOPHAN",
              "ALPROSTADIL": "ALPROSTADIL",
              "ALUMINUM HYDROXIDE DRIED GEL": "ALUMINUM HYDROXIDE DRIED GEL",
              "COENZYME Q10": "COENZYME Q10",
              "DIMERCAPTOPROPANE-SULFONATE NA": "DIMERCAPTOPROPANE-SULFONATE NA",
              "EDETATE DISODIUM DIHYDRATE": "EDETATE DISODIUM DIHYDRATE",
              "KETOCONAZOLE": "KETOCONAZOLE",
              "KOJIC ACID": "KOJIC ACID",
              "MINOXIDIL": "MINOXIDIL"
            },
            "HUMCO": {
              "ALPROSTADIL": "ALPROSTADIL",
              "COENZYME Q10": "COENZYME Q10",
              "KETOCONAZOLE": "KETOCONAZOLE",
              "MINOXIDIL": "MINOXIDIL"
            },
            "KALCHEM INTERNATIONAL": {
              "KETOCONAZOLE": "KETOCONAZOLE"
            },
            "LETCO MEDICAL": {
              "5-HYDROXY-L-TRYPTOPHAN": "5-HYDROXY-L-TRYPTOPHAN",
              "ALUMINUM HYDROXIDE DRIED GEL": "ALUMINUM HYDROXIDE DRIED GEL",
              "CARBIDOPA": "CARBIDOPA",
              "COENZYME Q10": "COENZYME Q10",
              "EDETATE DISODIUM DIHYDRATE": "EDETATE DISODIUM DIHYDRATE",
              "KETOCONAZOLE": "KETOCONAZOLE",
              "MELATONIN": "MELATONIN",
              "MINOXIDIL": "MINOXIDIL",
              "PROSTAGLANDIN E1": "PROSTAGLANDIN E1"
            },
            "MEDISCA": {
              "ALPROSTADIL": "ALPROSTADIL",
              "CARBIDOPA ANHYDROUS": "CARBIDOPA ANHYDROUS",
              "COENZYME Q10": "COENZYME Q10",
              "DIMERCAPTOPROPANE-SULFONATE NA": "DIMERCAPTOPROPANE-SULFONATE NA",
              "EDETATE DISODIUM": "EDETATE DISODIUM",
              "HYDROXYTRYPTOPHAN": "HYDROXYTRYPTOPHAN",
              "KETOCONAZOLE": "KETOCONAZOLE",
              "KOJIC ACID": "KOJIC ACID",
              "MELATONIN": "MELATONIN",
              "METHYLENE BLUE": "METHYLENE BLUE",
              "MINOXIDIL": "MINOXIDIL",
              "PYRUVIC ACID": "PYRUVIC ACID",
              "TINIDAZOLE": "TINIDAZOLE"
            },
            "PCCA": {
              "ALLANTOIN": "ALLANTOIN",
              "ALPROSTADIL": "ALPROSTADIL",
              "ALUMINUM HYDROXIDE DRIED GEL": "ALUMINUM HYDROXIDE DRIED GEL",
              "CAPSICUM OLEORESIN": "CAPSICUM OLEORESIN",
              "CARBIDOPA": "CARBIDOPA",
              "COENZYME Q10": "COENZYME Q10",
              "DIMENHYDRINATE": "DIMENHYDRINATE",
              "DIMERCAPTOPROPANE-SULFONATE NA": "DIMERCAPTOPROPANE-SULFONATE NA",
              "EDETATE ACID": "EDETATE ACID",
              "EDETATE DISODIUM DIHYDRATE": "EDETATE DISODIUM DIHYDRATE",
              "EDETATE SODIUM": "EDETATE SODIUM",
              "EPINEPHRINE": "EPINEPHRINE",
              "FLUORESCEIN": "FLUORESCEIN",
              "FLUORESCEIN SODIUM": "FLUORESCEIN SODIUM",
              "HOMATROPINE METHYLBROMIDE": "HOMATROPINE METHYLBROMIDE",
              "HYDROXYTRYPTOPHAN L-5": "HYDROXYTRYPTOPHAN L-5",
              "KETOCONAZOLE": "KETOCONAZOLE",
              "KOJIC ACID": "KOJIC ACID",
              "MELATONIN": "MELATONIN",
              "MENADIONE SODIUM BISULFITE": "MENADIONE SODIUM BISULFITE",
              "METHYLENE BLUE": "METHYLENE BLUE",
              "MINOXIDIL": "MINOXIDIL",
              "OXYBENZONE": "OXYBENZONE",
              "PYRUVIC ACID": "PYRUVIC ACID",
              "TINIDAZOLE": "TINIDAZOLE"
            },
            "SPECTRUM": {
              "ALLANTOIN": "ALLANTOIN",
              "ALPROSTADIL": "ALPROSTADIL",
              "ALUMINUM HYDROXIDE DRIED GEL": "ALUMINUM HYDROXIDE DRIED GEL",
              "CARBIDOPA ANHYDROUS": "CARBIDOPA ANHYDROUS",
              "DIMENHYDRINATE": "DIMENHYDRINATE",
              "EDETATE DISODIUM DIHYDRATE": "EDETATE DISODIUM DIHYDRATE",
              "EDETIC ACID": "EDETIC ACID",
              "EPINEPHRINE": "EPINEPHRINE",
              "FLUORESCEIN SODIUM": "FLUORESCEIN SODIUM",
              "KETOCONAZOLE": "KETOCONAZOLE",
              "KOJIC ACID": "KOJIC ACID",
              "MELATONIN": "MELATONIN",
              "METHYLENE BLUE": "METHYLENE BLUE",
              "MINOXIDIL": "MINOXIDIL",
              "OXYBENZONE": "OXYBENZONE",
              "TINIDAZOLE": "TINIDAZOLE",
              "UBIDECARENONE": "UBIDECARENONE"
            },
            "TRIOVA PHARMACEUTICALS": {
              "KETOCONAZOLE": "KETOCONAZOLE",
              "MELATONIN": "MELATONIN"
            },
            "WILLOW BIRCH PHARMA": {
              "ALPROSTADIL": "ALPROSTADIL",
              "EPINEPHRINE BASE": "EPINEPHRINE BASE"
            }
          },
          "Solid Combinations": {
            "HUMCO": {
              "SODIUM BICARBONATE-NACL": "SODIUM BICARBONATE-NACL"
            },
            "PCCA": {
              "DOCUSATE SODIUM-SOD BENZOATE": "DOCUSATE SODIUM-SOD BENZOATE"
            }
          },
          "Solids": {
            "AMERICAN PHARMA INGREDIENTS": {
              "MANNITOL": "MANNITOL"
            },
            "APOTHECA SUPPLY": {
              "AMMONIUM BROMIDE": "AMMONIUM BROMIDE",
              "DHEA": "DHEA",
              "MENTHOL": "MENTHOL",
              "QUINIDINE SULFATE": "QUINIDINE SULFATE"
            },
            "B & B PHARMACEUTICALS": {
              "DEHYDROEPIANDROSTERONE": "DEHYDROEPIANDROSTERONE",
              "PREGNENOLONE": "PREGNENOLONE",
              "PREGNENOLONE MICRONIZED": "PREGNENOLONE MICRONIZED"
            },
            "BERGEN BRUNSWIG": {
              "GNP BORIC ACID": "GNP BORIC ACID"
            },
            "BPI LABS LLC": {
              "MANNITOL": "MANNITOL"
            },
            "CHAIN DRUG CONSORTIUM": {
              "BORIC ACID": "BORIC ACID"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC BORIC ACID": "QC BORIC ACID"
            },
            "ENCORE SCIENTIFIC": {
              "MENTHOL": "MENTHOL",
              "PRASTERONE MICRONIZED": "PRASTERONE MICRONIZED",
              "PREGNENOLONE MICRONIZED": "PREGNENOLONE MICRONIZED"
            },
            "FAGRON": {
              "AMMONIUM CHLORIDE": "AMMONIUM CHLORIDE",
              "BISMUTH SUBCARBONATE": "BISMUTH SUBCARBONATE",
              "BISMUTH SUBNITRATE": "BISMUTH SUBNITRATE",
              "BORIC ACID": "BORIC ACID",
              "BUTYLATED HYDROXYTOLUENE": "BUTYLATED HYDROXYTOLUENE",
              "CALCIUM HYDROXIDE": "CALCIUM HYDROXIDE",
              "CARBOXYMETHYLCELLULOSE SODIUM": "CARBOXYMETHYLCELLULOSE SODIUM",
              "CITRIC ACID ANHYDROUS": "CITRIC ACID ANHYDROUS",
              "CITRIC ACID MONOHYDRATE": "CITRIC ACID MONOHYDRATE",
              "CORN STARCH": "CORN STARCH",
              "DEHYDROEPIANDROSTERONE": "DEHYDROEPIANDROSTERONE",
              "DEHYDROEPIANDROSTERONE MICRO": "DEHYDROEPIANDROSTERONE MICRO",
              "FULLERS EARTH": "FULLERS EARTH",
              "IODOFORM": "IODOFORM",
              "MANNITOL": "MANNITOL",
              "MENTHOL": "MENTHOL",
              "POTASSIUM BROMIDE": "POTASSIUM BROMIDE",
              "POTASSIUM GLUCONATE ANHYDROUS": "POTASSIUM GLUCONATE ANHYDROUS",
              "POTASSIUM NITRATE": "POTASSIUM NITRATE",
              "PREGNENOLONE": "PREGNENOLONE",
              "PREGNENOLONE MICRONIZED": "PREGNENOLONE MICRONIZED",
              "RESORCINOL": "RESORCINOL",
              "SILICA": "SILICA",
              "SODIUM BROMIDE": "SODIUM BROMIDE",
              "SODIUM BUTYRATE": "SODIUM BUTYRATE",
              "SODIUM SULFITE": "SODIUM SULFITE",
              "SORBITOL": "SORBITOL",
              "SUCROSE": "SUCROSE",
              "TALC": "TALC",
              "THEOPHYLLINE ANHYDROUS": "THEOPHYLLINE ANHYDROUS",
              "THYMOL": "THYMOL"
            },
            "FOCUS HEALTH GROUP": {
              "ALUM POTASSIUM": "ALUM POTASSIUM"
            },
            "FREEDOM PHARMACEUTICALS": {
              "BORIC ACID": "BORIC ACID",
              "BUTYLATED HYDROXYTOLUENE": "BUTYLATED HYDROXYTOLUENE",
              "CITRIC ACID ANHYDROUS": "CITRIC ACID ANHYDROUS",
              "DEHYDROEPIANDROSTERONE MICRO": "DEHYDROEPIANDROSTERONE MICRO",
              "MANNITOL": "MANNITOL",
              "MENTHOL": "MENTHOL",
              "PREGNENOLONE": "PREGNENOLONE"
            },
            "HUMCO": {
              "ALUM AMMONIUM": "ALUM AMMONIUM",
              "BISMUTH SUBCARBONATE": "BISMUTH SUBCARBONATE",
              "BORIC ACID TOPICAL": "BORIC ACID TOPICAL",
              "BUTYLATED HYDROXYTOLUENE": "BUTYLATED HYDROXYTOLUENE",
              "CATNIP": "CATNIP",
              "CITRIC ACID ANHYDROUS": "CITRIC ACID ANHYDROUS",
              "DHEA MICRONIZED": "DHEA MICRONIZED",
              "FULLERS EARTH": "FULLERS EARTH",
              "MENTHOL": "MENTHOL",
              "POTASSIUM NITRATE": "POTASSIUM NITRATE",
              "PREGNENOLONE MICRONIZED": "PREGNENOLONE MICRONIZED",
              "ROSIN": "ROSIN",
              "SODIUM PERBORATE": "SODIUM PERBORATE",
              "TALC": "TALC",
              "THEOPHYLLINE ANHYDROUS": "THEOPHYLLINE ANHYDROUS"
            },
            "KALCHEM INTERNATIONAL": {
              "BORIC ACID": "BORIC ACID",
              "BUTYLATED HYDROXYTOLUENE": "BUTYLATED HYDROXYTOLUENE",
              "CITRIC ACID ANHYDROUS": "CITRIC ACID ANHYDROUS",
              "DHEA": "DHEA",
              "MENTHOL": "MENTHOL",
              "POTASSIUM BROMIDE": "POTASSIUM BROMIDE",
              "RESORCINOL": "RESORCINOL",
              "THYMOL": "THYMOL"
            },
            "LEADER BRAND PRODUCTS": {
              "BORIC ACID NF": "BORIC ACID NF"
            },
            "LETCO MEDICAL": {
              "BISMUTH SUBCARBONATE": "BISMUTH SUBCARBONATE",
              "BISMUTH SUBNITRATE": "BISMUTH SUBNITRATE",
              "BISMUTH SUBSALICYLATE": "BISMUTH SUBSALICYLATE",
              "BORIC ACID": "BORIC ACID",
              "BUTYLATED HYDROXYTOLUENE": "BUTYLATED HYDROXYTOLUENE",
              "CARBOXYMETHYLCELLULOSE SODIUM": "CARBOXYMETHYLCELLULOSE SODIUM",
              "CITRIC ACID ANHYDROUS": "CITRIC ACID ANHYDROUS",
              "CORN STARCH": "CORN STARCH",
              "DEHYDROEPIANDROSTERONE": "DEHYDROEPIANDROSTERONE",
              "IODOFORM": "IODOFORM",
              "MANNITOL": "MANNITOL",
              "MENTHOL": "MENTHOL",
              "PILOCARPINE NITRATE": "PILOCARPINE NITRATE",
              "POTASSIUM BROMIDE": "POTASSIUM BROMIDE",
              "POTASSIUM GLUCONATE ANHYDROUS": "POTASSIUM GLUCONATE ANHYDROUS",
              "PREGNENOLONE": "PREGNENOLONE",
              "QUINIDINE SULFATE DIHYDRATE": "QUINIDINE SULFATE DIHYDRATE",
              "RESORCINOL": "RESORCINOL",
              "SILICA": "SILICA",
              "SODIUM NITRITE": "SODIUM NITRITE",
              "SORBITOL": "SORBITOL",
              "SUCROSE": "SUCROSE",
              "SULFANILAMIDE": "SULFANILAMIDE",
              "TALC": "TALC",
              "THEOPHYLLINE ANHYDROUS": "THEOPHYLLINE ANHYDROUS",
              "THYMOL": "THYMOL",
              "THYMOL IODIDE": "THYMOL IODIDE"
            },
            "MCKESSON": {
              "HM BORIC ACID": "HM BORIC ACID"
            },
            "MCKESSON SUNMARK": {
              "SM BORIC ACID": "SM BORIC ACID"
            },
            "MEDISCA": {
              "BISMUTH SUBCARBONATE": "BISMUTH SUBCARBONATE",
              "BISMUTH SUBNITRATE": "BISMUTH SUBNITRATE",
              "BORIC ACID": "BORIC ACID",
              "BUTYLATED HYDROXYTOLUENE": "BUTYLATED HYDROXYTOLUENE",
              "CITRIC ACID ANHYDROUS": "CITRIC ACID ANHYDROUS",
              "CORN STARCH": "CORN STARCH",
              "DEHYDROEPIANDROSTERONE": "DEHYDROEPIANDROSTERONE",
              "IODOFORM": "IODOFORM",
              "L-ASPARTIC ACID": "L-ASPARTIC ACID",
              "MANNITOL": "MANNITOL",
              "MENTHOL": "MENTHOL",
              "PILOCARPINE HCL": "PILOCARPINE HCL",
              "POTASSIUM BROMIDE": "POTASSIUM BROMIDE",
              "PREGNENOLONE": "PREGNENOLONE",
              "PREGNENOLONE MICRONIZED": "PREGNENOLONE MICRONIZED",
              "RESORCINOL": "RESORCINOL",
              "SILICA": "SILICA",
              "SILICON DIOXIDE (SYLOID 244FP)": "SILICON DIOXIDE (SYLOID 244FP)",
              "SODIUM BROMIDE": "SODIUM BROMIDE",
              "SORBITOL": "SORBITOL",
              "TALC": "TALC",
              "THEOPHYLLINE ANHYDROUS": "THEOPHYLLINE ANHYDROUS",
              "THYMOL": "THYMOL"
            },
            "PCCA": {
              "ALUMINUM POTASSIUM SULFATE": "ALUMINUM POTASSIUM SULFATE",
              "ALUMINUM SULFATE": "ALUMINUM SULFATE",
              "AMMONIUM BROMIDE": "AMMONIUM BROMIDE",
              "AMMONIUM CHLORIDE": "AMMONIUM CHLORIDE",
              "AMMONIUM SULFATE": "AMMONIUM SULFATE",
              "BISMUTH SUBCARBONATE": "BISMUTH SUBCARBONATE",
              "BISMUTH SUBNITRATE": "BISMUTH SUBNITRATE",
              "BISMUTH SUBSALICYLATE": "BISMUTH SUBSALICYLATE",
              "BORIC ACID": "BORIC ACID",
              "BUTYLATED HYDROXYTOLUENE": "BUTYLATED HYDROXYTOLUENE",
              "CALCIUM HYDROXIDE": "CALCIUM HYDROXIDE",
              "CALCIUM SULFATE": "CALCIUM SULFATE",
              "CALCIUM SULFATE HEMIHYDRATE": "CALCIUM SULFATE HEMIHYDRATE",
              "CARBOXYMETHYLCELLULOSE SODIUM": "CARBOXYMETHYLCELLULOSE SODIUM",
              "CITRIC ACID ANHYDROUS": "CITRIC ACID ANHYDROUS",
              "CITRIC ACID MONOHYDRATE": "CITRIC ACID MONOHYDRATE",
              "CORN STARCH": "CORN STARCH",
              "DEHYDROEPIANDROSTERONE": "DEHYDROEPIANDROSTERONE",
              "DINITROCHLOROBENZENE": "DINITROCHLOROBENZENE",
              "FULLERS EARTH": "FULLERS EARTH",
              "GERMANIUM SESQUIOXIDE": "GERMANIUM SESQUIOXIDE",
              "GINGER ROOT": "GINGER ROOT",
              "IODOFORM": "IODOFORM",
              "L-ASPARTIC ACID": "L-ASPARTIC ACID",
              "LEAD ACETATE TRIHYDRATE": "LEAD ACETATE TRIHYDRATE",
              "LICORICE ROOT": "LICORICE ROOT",
              "MANNITOL": "MANNITOL",
              "MENTHOL": "MENTHOL",
              "METHENAMINE": "METHENAMINE",
              "METHENAMINE MANDELATE": "METHENAMINE MANDELATE",
              "PHENYLMERCURIC ACETATE": "PHENYLMERCURIC ACETATE",
              "PHENYLMERCURIC NITRATE": "PHENYLMERCURIC NITRATE",
              "PILOCARPINE HCL": "PILOCARPINE HCL",
              "POTASH SULFURATED LUMP": "POTASH SULFURATED LUMP",
              "POTASSIUM BITARTRATE": "POTASSIUM BITARTRATE",
              "POTASSIUM BROMIDE": "POTASSIUM BROMIDE",
              "POTASSIUM GLUCONATE ANHYDROUS": "POTASSIUM GLUCONATE ANHYDROUS",
              "POTASSIUM NITRATE": "POTASSIUM NITRATE",
              "POTASSIUM PERCHLORATE": "POTASSIUM PERCHLORATE",
              "PREGNENOLONE": "PREGNENOLONE",
              "PUMICE (FLOUR)": "PUMICE (FLOUR)",
              "PYROGALLOL": "PYROGALLOL",
              "QUINIDINE SULFATE DIHYDRATE": "QUINIDINE SULFATE DIHYDRATE",
              "RESORCINOL": "RESORCINOL",
              "ROSIN": "ROSIN",
              "SILICA": "SILICA",
              "SILICON DIOXIDE": "SILICON DIOXIDE",
              "SODIUM BROMIDE": "SODIUM BROMIDE",
              "SODIUM BUTYRATE": "SODIUM BUTYRATE",
              "SODIUM CACODYLATE": "SODIUM CACODYLATE",
              "SODIUM NITRITE": "SODIUM NITRITE",
              "SODIUM PERBORATE": "SODIUM PERBORATE",
              "SODIUM SULFATE": "SODIUM SULFATE",
              "SODIUM SULFITE": "SODIUM SULFITE",
              "SORBITOL": "SORBITOL",
              "STANNOUS FLUORIDE": "STANNOUS FLUORIDE",
              "STRONTIUM NITRATE": "STRONTIUM NITRATE",
              "SUCROSE": "SUCROSE",
              "SULFANILAMIDE": "SULFANILAMIDE",
              "TALC": "TALC",
              "THEOPHYLLINE ANHYDROUS": "THEOPHYLLINE ANHYDROUS",
              "THYMOL": "THYMOL",
              "THYMOL IODIDE": "THYMOL IODIDE"
            },
            "PHARMACEUTICA NORTH AMERICA": {
              "MENTHOL": "MENTHOL"
            },
            "PHOENIX PHARMA": {
              "DEHYDROEPIANDROSTERONE": "DEHYDROEPIANDROSTERONE"
            },
            "RITE AID CORPORATION": {
              "RA BORIC ACID": "RA BORIC ACID"
            },
            "SPECTRUM": {
              "ALUMINUM AMMONIUM SULFATE": "ALUMINUM AMMONIUM SULFATE",
              "ALUMINUM POTASSIUM SULFATE": "ALUMINUM POTASSIUM SULFATE",
              "ALUMINUM SULFATE": "ALUMINUM SULFATE",
              "AMMONIUM CARBONATE": "AMMONIUM CARBONATE",
              "AMMONIUM CHLORIDE": "AMMONIUM CHLORIDE",
              "AMMONIUM PHOSPHATE DIBASIC": "AMMONIUM PHOSPHATE DIBASIC",
              "AMMONIUM SULFATE": "AMMONIUM SULFATE",
              "BHT": "BHT",
              "BISMUTH SUBCARBONATE": "BISMUTH SUBCARBONATE",
              "BISMUTH SUBNITRATE": "BISMUTH SUBNITRATE",
              "BISMUTH SUBSALICYLATE": "BISMUTH SUBSALICYLATE",
              "BORIC ACID": "BORIC ACID",
              "CALCIUM HYDROXIDE": "CALCIUM HYDROXIDE",
              "CALCIUM SULFATE": "CALCIUM SULFATE",
              "CARBOXYMETHYLCELLULOSE SODIUM": "CARBOXYMETHYLCELLULOSE SODIUM",
              "CITRIC ACID ANHYDROUS": "CITRIC ACID ANHYDROUS",
              "CITRIC ACID MONOHYDRATE": "CITRIC ACID MONOHYDRATE",
              "CORN STARCH": "CORN STARCH",
              "DEHYDROEPIANDROSTERONE": "DEHYDROEPIANDROSTERONE",
              "FULLERS EARTH": "FULLERS EARTH",
              "IODOFORM": "IODOFORM",
              "L-ASPARTIC ACID": "L-ASPARTIC ACID",
              "L-MENTHOL": "L-MENTHOL",
              "MANNITOL": "MANNITOL",
              "MENTHOL": "MENTHOL",
              "METHENAMINE": "METHENAMINE",
              "PILOCARPINE NITRATE": "PILOCARPINE NITRATE",
              "POTASSIUM BITARTRATE": "POTASSIUM BITARTRATE",
              "POTASSIUM BROMIDE": "POTASSIUM BROMIDE",
              "POTASSIUM CARBONATE": "POTASSIUM CARBONATE",
              "POTASSIUM GLUCONATE ANHYDROUS": "POTASSIUM GLUCONATE ANHYDROUS",
              "POTASSIUM NITRATE": "POTASSIUM NITRATE",
              "PREGNENOLONE": "PREGNENOLONE",
              "QUINIDINE SULFATE DIHYDRATE": "QUINIDINE SULFATE DIHYDRATE",
              "RESORCINOL": "RESORCINOL",
              "SILICA": "SILICA",
              "SODIUM NITRITE": "SODIUM NITRITE",
              "SODIUM PERBORATE": "SODIUM PERBORATE",
              "SODIUM SULFATE": "SODIUM SULFATE",
              "SODIUM SULFITE": "SODIUM SULFITE",
              "SORBITOL": "SORBITOL",
              "STARCH": "STARCH",
              "STARCH RICE": "STARCH RICE",
              "SUCROSE": "SUCROSE",
              "SULFANILAMIDE": "SULFANILAMIDE",
              "TALC": "TALC",
              "THEOPHYLLINE ANHYDROUS": "THEOPHYLLINE ANHYDROUS",
              "THYMOL": "THYMOL"
            },
            "TOTAL PHARMACY SUPPLY": {
              "CITRIC ACID": "CITRIC ACID"
            },
            "TRIOVA PHARMACEUTICALS": {
              "DHEA MICRONIZED": "DHEA MICRONIZED"
            },
            "TYSON": {
              "L-ASPARTIC ACID": "L-ASPARTIC ACID"
            }
          }
        }
      },
      "CONTRACEPTIVES": {
        "Combination Contraceptives - Oral": {
          "Biphasic Contraceptives - Oral": {
            "A-S MEDICATION SOLUTIONS": {
              "KARIVA": "KARIVA",
              "LO LOESTRIN FE": "LO LOESTRIN FE",
              "VIORELE": "VIORELE"
            },
            "ACTAVIS PHARMA": {
              "NECON 10/11 (28)": "NECON 10/11 (28)"
            },
            "ALLERGAN": {
              "LO LOESTRIN FE": "LO LOESTRIN FE"
            },
            "AUROBINDO PHARMA": {
              "SIMLIYA": "SIMLIYA"
            },
            "GLENMARK PHARMACEUTICALS": {
              "VIORELE": "VIORELE"
            },
            "LUPIN PHARMACEUTICALS": {
              "BEKYREE": "BEKYREE"
            },
            "MAYNE PHARMA": {
              "AZURETTE": "AZURETTE"
            },
            "MYLAN": {
              "DESOGESTREL-ETHINYL ESTRADIOL": "DESOGESTREL-ETHINYL ESTRADIOL"
            },
            "NORTHSTAR RX": {
              "PIMTREA": "PIMTREA"
            },
            "PAR PHARMACEUTICALS": {
              "KIMIDESS": "KIMIDESS"
            },
            "QUALITY CARE": {
              "KARIVA": "KARIVA"
            },
            "TEVA PHARMACEUTICALS USA": {
              "KARIVA": "KARIVA"
            },
            "TEVA/WOMENS HEALTH": {
              "MIRCETTE": "MIRCETTE"
            },
            "XIROMED": {
              "VOLNEA": "VOLNEA"
            }
          },
          "Combination Contraceptives - Oral": {
            "1ST CLASS PHARMACEUTICALS": {
              "AFIRMELLE": "AFIRMELLE"
            },
            "A-S MEDICATION SOLUTIONS": {
              "ALYACEN 1/35": "ALYACEN 1/35",
              "APRI": "APRI",
              "CRYSELLE-28": "CRYSELLE-28",
              "CYCLAFEM 1/35": "CYCLAFEM 1/35",
              "DROSPIRENONE-ETHINYL ESTRADIOL": "DROSPIRENONE-ETHINYL ESTRADIOL",
              "EMOQUETTE": "EMOQUETTE",
              "GENERESS FE": "GENERESS FE",
              "GIANVI": "GIANVI",
              "GILDESS FE 1.5/30": "GILDESS FE 1.5/30",
              "GILDESS FE 1/20": "GILDESS FE 1/20",
              "JUNEL FE 1.5/30": "JUNEL FE 1.5/30",
              "JUNEL FE 1/20": "JUNEL FE 1/20",
              "KELNOR 1/35": "KELNOR 1/35",
              "LARIN FE 1.5/30": "LARIN FE 1.5/30",
              "LARIN FE 1/20": "LARIN FE 1/20",
              "LEVONORGESTREL-ETHINYL ESTRAD": "LEVONORGESTREL-ETHINYL ESTRAD",
              "LEVORA 0.15/30 (28)": "LEVORA 0.15/30 (28)",
              "LOW-OGESTREL": "LOW-OGESTREL",
              "LUTERA": "LUTERA",
              "MARLISSA": "MARLISSA",
              "MICROGESTIN FE 1/20": "MICROGESTIN FE 1/20",
              "MONO-LINYAH": "MONO-LINYAH",
              "MONONESSA": "MONONESSA",
              "NECON 0.5/35 (28)": "NECON 0.5/35 (28)",
              "NECON 1/35 (28)": "NECON 1/35 (28)",
              "NIKKI": "NIKKI",
              "NORGESTIMATE-ETH ESTRADIOL": "NORGESTIMATE-ETH ESTRADIOL",
              "NORTREL 0.5/35 (28)": "NORTREL 0.5/35 (28)",
              "OCELLA": "OCELLA",
              "ORSYTHIA": "ORSYTHIA",
              "ORTHO-CYCLEN (28)": "ORTHO-CYCLEN (28)",
              "PREVIFEM": "PREVIFEM",
              "RECLIPSEN": "RECLIPSEN",
              "VESTURA": "VESTURA",
              "WERA": "WERA",
              "YASMIN 28": "YASMIN 28",
              "YAZ": "YAZ",
              "ZARAH": "ZARAH",
              "ZOVIA 1/35E (28)": "ZOVIA 1/35E (28)"
            },
            "ACTAVIS": {
              "FEMCON FE": "FEMCON FE"
            },
            "ACTAVIS PHARMA": {
              "LAYOLIS FE": "LAYOLIS FE",
              "LOW-OGESTREL": "LOW-OGESTREL",
              "MICROGESTIN 24 FE": "MICROGESTIN 24 FE",
              "MICROGESTIN FE 1.5/30": "MICROGESTIN FE 1.5/30",
              "MONONESSA": "MONONESSA",
              "NECON 0.5/35 (28)": "NECON 0.5/35 (28)",
              "NECON 1/35 (28)": "NECON 1/35 (28)",
              "NECON 1/50 (28)": "NECON 1/50 (28)",
              "NORETHIN ACE-ETH ESTRAD-FE": "NORETHIN ACE-ETH ESTRAD-FE",
              "NORINYL 1+35 (28)": "NORINYL 1+35 (28)",
              "NORINYL 1+50 (28)": "NORINYL 1+50 (28)",
              "RAJANI": "RAJANI",
              "RECLIPSEN": "RECLIPSEN",
              "SRONYX": "SRONYX",
              "VESTURA": "VESTURA",
              "ZENCHENT": "ZENCHENT",
              "ZOVIA 1/35E (28)": "ZOVIA 1/35E (28)",
              "ZOVIA 1/50E (28)": "ZOVIA 1/50E (28)"
            },
            "AFAXYS": {
              "AUBRA": "AUBRA",
              "AUBRA EQ": "AUBRA EQ",
              "CHATEAL": "CHATEAL",
              "CHATEAL EQ": "CHATEAL EQ",
              "CYRED": "CYRED",
              "CYRED EQ": "CYRED EQ",
              "JASMIEL": "JASMIEL",
              "TARINA 24 FE": "TARINA 24 FE",
              "TARINA FE 1/20": "TARINA FE 1/20",
              "TARINA FE 1/20 EQ": "TARINA FE 1/20 EQ",
              "VYLIBRA": "VYLIBRA"
            },
            "AIDAREX PHARMACEUTICALS": {
              "RECLIPSEN": "RECLIPSEN",
              "SPRINTEC 28": "SPRINTEC 28"
            },
            "AKORN": {
              "NORGESTIMATE-ETH ESTRADIOL": "NORGESTIMATE-ETH ESTRADIOL"
            },
            "ALLERGAN": {
              "BREVICON (28)": "BREVICON (28)",
              "GENERESS FE": "GENERESS FE",
              "MINASTRIN 24 FE": "MINASTRIN 24 FE",
              "OVCON-35 (28)": "OVCON-35 (28)",
              "TAYTULLA": "TAYTULLA"
            },
            "AMNEAL PHARMACEUTICALS": {
              "FEMYNOR": "FEMYNOR",
              "LARISSIA": "LARISSIA",
              "LILLOW": "LILLOW",
              "LOMEDIA 24 FE": "LOMEDIA 24 FE",
              "MELODETTA 24 FE": "MELODETTA 24 FE",
              "ZENCHENT FE": "ZENCHENT FE"
            },
            "APOTEX": {
              "DROSPIRENONE-ETHINYL ESTRADIOL": "DROSPIRENONE-ETHINYL ESTRADIOL"
            },
            "AUROBINDO PHARMA": {
              "AUROVELA 1.5/30": "AUROVELA 1.5/30",
              "AUROVELA 1/20": "AUROVELA 1/20",
              "AUROVELA 24 FE": "AUROVELA 24 FE",
              "AUROVELA FE 1.5/30": "AUROVELA FE 1.5/30",
              "AUROVELA FE 1/20": "AUROVELA FE 1/20",
              "AYUNA": "AYUNA",
              "KALLIGA": "KALLIGA",
              "LO-ZUMANDIMINE": "LO-ZUMANDIMINE",
              "MILI": "MILI",
              "ZUMANDIMINE": "ZUMANDIMINE"
            },
            "AVION PHARMACEUTICALS": {
              "BALCOLTRA": "BALCOLTRA",
              "FALESSA": "FALESSA"
            },
            "AVKARE": {
              "NORGESTIMATE-ETH ESTRADIOL": "NORGESTIMATE-ETH ESTRADIOL"
            },
            "BAYER HEALTHCARE PHARMA": {
              "BEYAZ": "BEYAZ",
              "SAFYRAL": "SAFYRAL",
              "YASMIN 28": "YASMIN 28",
              "YAZ": "YAZ"
            },
            "BRYANT RANCH PREPACK": {
              "OGESTREL": "OGESTREL"
            },
            "CADISTA": {
              "DROSPIRENONE-ETHINYL ESTRADIOL": "DROSPIRENONE-ETHINYL ESTRADIOL"
            },
            "CAMBER PHARMACEUTICALS": {
              "DROSPIRENONE-ETHINYL ESTRADIOL": "DROSPIRENONE-ETHINYL ESTRADIOL"
            },
            "EXELTIS USA": {
              "TYBLUME": "TYBLUME"
            },
            "GLENMARK PHARMACEUTICALS": {
              "ALYACEN 1/35": "ALYACEN 1/35",
              "BRIELLYN": "BRIELLYN",
              "CHARLOTTE 24 FE": "CHARLOTTE 24 FE",
              "DROSPIRENONE-ETHINYL ESTRADIOL": "DROSPIRENONE-ETHINYL ESTRADIOL",
              "HAILEY 1.5/30": "HAILEY 1.5/30",
              "HAILEY 24 FE": "HAILEY 24 FE",
              "HAILEY FE 1.5/30": "HAILEY FE 1.5/30",
              "HAILEY FE 1/20": "HAILEY FE 1/20",
              "MARLISSA": "MARLISSA",
              "NORETHIN ACE-ETH ESTRAD-FE": "NORETHIN ACE-ETH ESTRAD-FE",
              "NORETHINDRONE ACET-ETHINYL EST": "NORETHINDRONE ACET-ETHINYL EST",
              "NORGESTIMATE-ETH ESTRADIOL": "NORGESTIMATE-ETH ESTRADIOL"
            },
            "JANSSEN": {
              "MODICON (28)": "MODICON (28)",
              "ORTHO-CYCLEN (28)": "ORTHO-CYCLEN (28)",
              "ORTHO-NOVUM 1/35 (28)": "ORTHO-NOVUM 1/35 (28)"
            },
            "LUPIN PHARMACEUTICALS": {
              "BLISOVI 24 FE": "BLISOVI 24 FE",
              "BLISOVI FE 1.5/30": "BLISOVI FE 1.5/30",
              "BLISOVI FE 1/20": "BLISOVI FE 1/20",
              "DROSPIREN-ETH ESTRAD-LEVOMEFOL": "DROSPIREN-ETH ESTRAD-LEVOMEFOL",
              "DROSPIRENONE-ETHINYL ESTRADIOL": "DROSPIRENONE-ETHINYL ESTRADIOL",
              "ENSKYCE": "ENSKYCE",
              "KAITLIB FE": "KAITLIB FE",
              "KURVELO": "KURVELO",
              "LEVONORGESTREL-ETHINYL ESTRAD": "LEVONORGESTREL-ETHINYL ESTRAD",
              "MIBELAS 24 FE": "MIBELAS 24 FE",
              "NIKKI": "NIKKI",
              "NORGESTIMATE-ETH ESTRADIOL": "NORGESTIMATE-ETH ESTRADIOL",
              "PIRMELLA 1/35": "PIRMELLA 1/35",
              "TYDEMY": "TYDEMY",
              "VYFEMLA": "VYFEMLA",
              "WYMZYA FE": "WYMZYA FE"
            },
            "MAYNE PHARMA": {
              "LEVORA 0.15/30 (28)": "LEVORA 0.15/30 (28)",
              "LOW-OGESTREL": "LOW-OGESTREL",
              "LUTERA": "LUTERA",
              "MICROGESTIN 1.5/30": "MICROGESTIN 1.5/30",
              "MICROGESTIN 1/20": "MICROGESTIN 1/20",
              "MICROGESTIN FE 1.5/30": "MICROGESTIN FE 1.5/30",
              "MICROGESTIN FE 1/20": "MICROGESTIN FE 1/20",
              "NECON 0.5/35 (28)": "NECON 0.5/35 (28)",
              "SRONYX": "SRONYX",
              "ZARAH": "ZARAH",
              "ZOVIA 1/35E (28)": "ZOVIA 1/35E (28)"
            },
            "MYLAN": {
              "DESOGESTREL-ETHINYL ESTRADIOL": "DESOGESTREL-ETHINYL ESTRADIOL",
              "DROSPIRENONE-ETHINYL ESTRADIOL": "DROSPIRENONE-ETHINYL ESTRADIOL",
              "ETHYNODIOL DIAC-ETH ESTRADIOL": "ETHYNODIOL DIAC-ETH ESTRADIOL",
              "LEVONORGESTREL-ETHINYL ESTRAD": "LEVONORGESTREL-ETHINYL ESTRAD",
              "NORETHIN ACE-ETH ESTRAD-FE": "NORETHIN ACE-ETH ESTRAD-FE",
              "NORETHIN-ETH ESTRADIOL-FE": "NORETHIN-ETH ESTRADIOL-FE",
              "NORETHINDRONE ACET-ETHINYL EST": "NORETHINDRONE ACET-ETHINYL EST",
              "NORGESTIMATE-ETH ESTRADIOL": "NORGESTIMATE-ETH ESTRADIOL"
            },
            "NIVAGEN PHARMACEUTICALS": {
              "DROSPIRENONE-ETHINYL ESTRADIOL": "DROSPIRENONE-ETHINYL ESTRADIOL"
            },
            "NORTHSTAR RX": {
              "DASETTA 1/35": "DASETTA 1/35",
              "DESOGESTREL-ETHINYL ESTRADIOL": "DESOGESTREL-ETHINYL ESTRADIOL",
              "ELINEST": "ELINEST",
              "FALMINA": "FALMINA",
              "JULEBER": "JULEBER",
              "LARIN 1.5/30": "LARIN 1.5/30",
              "LARIN 1/20": "LARIN 1/20",
              "LARIN 24 FE": "LARIN 24 FE",
              "LARIN FE 1.5/30": "LARIN FE 1.5/30",
              "LARIN FE 1/20": "LARIN FE 1/20",
              "MONO-LINYAH": "MONO-LINYAH",
              "PHILITH": "PHILITH",
              "WERA": "WERA"
            },
            "OHM LABS": {
              "DELYLA": "DELYLA"
            },
            "ORGANON": {
              "DESOGEN": "DESOGEN"
            },
            "PAR PHARMACEUTICAL": {
              "EMOQUETTE": "EMOQUETTE",
              "GILDESS 1.5/30": "GILDESS 1.5/30",
              "GILDESS 1/20": "GILDESS 1/20",
              "GILDESS 24 FE": "GILDESS 24 FE",
              "GILDESS FE 1.5/30": "GILDESS FE 1.5/30",
              "GILDESS FE 1/20": "GILDESS FE 1/20",
              "ORSYTHIA": "ORSYTHIA",
              "PREVIFEM": "PREVIFEM"
            },
            "PAR PHARMACEUTICALS": {
              "CYCLAFEM 1/35": "CYCLAFEM 1/35",
              "EMOQUETTE": "EMOQUETTE",
              "GILDAGIA": "GILDAGIA",
              "ORSYTHIA": "ORSYTHIA",
              "PREVIFEM": "PREVIFEM"
            },
            "PDRX PHARMACEUTICAL": {
              "OGESTREL": "OGESTREL"
            },
            "PHARMEDIX": {
              "LEVORA 0.15/30 (28)": "LEVORA 0.15/30 (28)",
              "MICROGESTIN 1/20": "MICROGESTIN 1/20",
              "MICROGESTIN FE 1.5/30": "MICROGESTIN FE 1.5/30",
              "MONONESSA": "MONONESSA",
              "NECON 1/35 (28)": "NECON 1/35 (28)",
              "OCELLA": "OCELLA",
              "PREVIFEM": "PREVIFEM",
              "SOLIA": "SOLIA",
              "SRONYX": "SRONYX"
            },
            "PREFERRED PHARMACEUTICALS": {
              "MARLISSA": "MARLISSA"
            },
            "PROFICIENT RX": {
              "AVIANE": "AVIANE",
              "DROSPIRENONE-ETHINYL ESTRADIOL": "DROSPIRENONE-ETHINYL ESTRADIOL",
              "JUNEL FE 1.5/30": "JUNEL FE 1.5/30",
              "SPRINTEC 28": "SPRINTEC 28"
            },
            "QUALITY CARE": {
              "CRYSELLE-28": "CRYSELLE-28",
              "LOESTRIN FE 1/20": "LOESTRIN FE 1/20",
              "NECON 1/35 (28)": "NECON 1/35 (28)",
              "YAZ": "YAZ"
            },
            "SANDOZ": {
              "DROSPIREN-ETH ESTRAD-LEVOMEFOL": "DROSPIREN-ETH ESTRAD-LEVOMEFOL"
            },
            "TEVA PHARMACEUTICALS USA": {
              "APRI": "APRI",
              "AVIANE": "AVIANE",
              "BALZIVA": "BALZIVA",
              "CRYSELLE-28": "CRYSELLE-28",
              "GIANVI": "GIANVI",
              "JUNEL 1.5/30": "JUNEL 1.5/30",
              "JUNEL 1/20": "JUNEL 1/20",
              "JUNEL FE 1.5/30": "JUNEL FE 1.5/30",
              "JUNEL FE 1/20": "JUNEL FE 1/20",
              "JUNEL FE 24": "JUNEL FE 24",
              "KELNOR 1/35": "KELNOR 1/35",
              "KELNOR 1/50": "KELNOR 1/50",
              "LESSINA": "LESSINA",
              "MICROGESTIN 1.5/30": "MICROGESTIN 1.5/30",
              "NORTREL 0.5/35 (28)": "NORTREL 0.5/35 (28)",
              "NORTREL 1/35 (21)": "NORTREL 1/35 (21)",
              "NORTREL 1/35 (28)": "NORTREL 1/35 (28)",
              "OCELLA": "OCELLA",
              "OGESTREL": "OGESTREL",
              "PORTIA-28": "PORTIA-28",
              "RECLIPSEN": "RECLIPSEN",
              "SPRINTEC 28": "SPRINTEC 28",
              "ZENCHENT": "ZENCHENT"
            },
            "TEVA/WOMENS HEALTH": {
              "LOESTRIN 1.5/30 (21)": "LOESTRIN 1.5/30 (21)",
              "LOESTRIN 1/20 (21)": "LOESTRIN 1/20 (21)",
              "LOESTRIN FE 1.5/30": "LOESTRIN FE 1.5/30",
              "LOESTRIN FE 1/20": "LOESTRIN FE 1/20"
            },
            "XIROMED": {
              "ALTAVERA": "ALTAVERA",
              "ESTARYLLA": "ESTARYLLA",
              "ISIBLOOM": "ISIBLOOM",
              "LORYNA": "LORYNA",
              "NORETHIN ACE-ETH ESTRAD-FE": "NORETHIN ACE-ETH ESTRAD-FE",
              "SYEDA": "SYEDA",
              "VIENVA": "VIENVA"
            }
          },
          "Continuous Contraceptives - Oral": {
            "ACTAVIS PHARMA": {
              "AMETHYST": "AMETHYST"
            },
            "GLENMARK PHARMACEUTICALS": {
              "LEVONORGESTREL-ETHINYL ESTRAD": "LEVONORGESTREL-ETHINYL ESTRAD"
            }
          },
          "Extended-Cycle Contraceptives - Oral": {
            "A-S MEDICATION SOLUTIONS": {
              "ASHLYNA": "ASHLYNA",
              "QUASENSE": "QUASENSE"
            },
            "ACTAVIS PHARMA": {
              "QUASENSE": "QUASENSE"
            },
            "AUROBINDO PHARMA": {
              "SIMPESSE": "SIMPESSE"
            },
            "GLENMARK PHARMACEUTICALS": {
              "ASHLYNA": "ASHLYNA",
              "LEVONORGEST-ETH ESTRAD 91-DAY": "LEVONORGEST-ETH ESTRAD 91-DAY"
            },
            "LUPIN PHARMACEUTICALS": {
              "DAYSEE": "DAYSEE",
              "FAYOSIM": "FAYOSIM",
              "LEVONORGEST-ETH ESTRAD 91-DAY": "LEVONORGEST-ETH ESTRAD 91-DAY"
            },
            "MAYNE PHARMA": {
              "AMETHIA": "AMETHIA",
              "AMETHIA LO": "AMETHIA LO",
              "LEVONORGEST-ETH EST & ETH EST": "LEVONORGEST-ETH EST & ETH EST"
            },
            "MYLAN": {
              "LEVONORGEST-ETH EST & ETH EST": "LEVONORGEST-ETH EST & ETH EST",
              "LEVONORGEST-ETH ESTRAD 91-DAY": "LEVONORGEST-ETH ESTRAD 91-DAY"
            },
            "NORTHSTAR RX": {
              "SETLAKIN": "SETLAKIN"
            },
            "TEVA PHARMACEUTICALS USA": {
              "CAMRESE": "CAMRESE",
              "CAMRESE LO": "CAMRESE LO",
              "JOLESSA": "JOLESSA",
              "RIVELSA": "RIVELSA"
            },
            "TEVA/WOMENS HEALTH": {
              "LOSEASONIQUE": "LOSEASONIQUE",
              "QUARTETTE": "QUARTETTE",
              "SEASONIQUE": "SEASONIQUE"
            },
            "XIROMED": {
              "INTROVALE": "INTROVALE",
              "JAIMIESS": "JAIMIESS",
              "LOJAIMIESS": "LOJAIMIESS"
            }
          },
          "Four Phase Contraceptives - Oral": {
            "BAYER HEALTHCARE PHARMA": {
              "NATAZIA": "NATAZIA"
            }
          },
          "Triphasic Contraceptives - Oral": {
            "A-S MEDICATION SOLUTIONS": {
              "ALYACEN 7/7/7": "ALYACEN 7/7/7",
              "CYCLAFEM 7/7/7": "CYCLAFEM 7/7/7",
              "LEVONEST": "LEVONEST",
              "MYZILRA": "MYZILRA",
              "NORGESTIM-ETH ESTRAD TRIPHASIC": "NORGESTIM-ETH ESTRAD TRIPHASIC",
              "ORTHO TRI-CYCLEN (28)": "ORTHO TRI-CYCLEN (28)",
              "ORTHO TRI-CYCLEN LO": "ORTHO TRI-CYCLEN LO",
              "ORTHO-NOVUM 7/7/7 (28)": "ORTHO-NOVUM 7/7/7 (28)",
              "TRI FEMYNOR": "TRI FEMYNOR",
              "TRI-LO-MARZIA": "TRI-LO-MARZIA",
              "TRI-LO-SPRINTEC": "TRI-LO-SPRINTEC",
              "TRI-PREVIFEM": "TRI-PREVIFEM",
              "TRINESSA (28)": "TRINESSA (28)",
              "TRIVORA (28)": "TRIVORA (28)"
            },
            "ACTAVIS PHARMA": {
              "LEENA": "LEENA",
              "TRINESSA (28)": "TRINESSA (28)",
              "TRINESSA LO": "TRINESSA LO",
              "TRIVORA (28)": "TRIVORA (28)"
            },
            "AFAXYS": {
              "TRI-VYLIBRA": "TRI-VYLIBRA",
              "TRI-VYLIBRA LO": "TRI-VYLIBRA LO"
            },
            "AKORN": {
              "NORGESTIM-ETH ESTRAD TRIPHASIC": "NORGESTIM-ETH ESTRAD TRIPHASIC"
            },
            "ALLERGAN": {
              "ESTROSTEP FE": "ESTROSTEP FE",
              "TRI-NORINYL (28)": "TRI-NORINYL (28)"
            },
            "AMNEAL PHARMACEUTICALS": {
              "TRI FEMYNOR": "TRI FEMYNOR"
            },
            "AUROBINDO PHARMA": {
              "TRI-LO-MILI": "TRI-LO-MILI",
              "TRI-MILI": "TRI-MILI"
            },
            "AVKARE": {
              "NORGESTIM-ETH ESTRAD TRIPHASIC": "NORGESTIM-ETH ESTRAD TRIPHASIC"
            },
            "GLENMARK PHARMACEUTICALS": {
              "ALYACEN 7/7/7": "ALYACEN 7/7/7",
              "NORGESTIM-ETH ESTRAD TRIPHASIC": "NORGESTIM-ETH ESTRAD TRIPHASIC"
            },
            "JANSSEN": {
              "ORTHO TRI-CYCLEN (28)": "ORTHO TRI-CYCLEN (28)",
              "ORTHO TRI-CYCLEN LO": "ORTHO TRI-CYCLEN LO",
              "ORTHO-NOVUM 7/7/7 (28)": "ORTHO-NOVUM 7/7/7 (28)"
            },
            "LUPIN PHARMACEUTICALS": {
              "LEVONORG-ETH ESTRAD TRIPHASIC": "LEVONORG-ETH ESTRAD TRIPHASIC",
              "NORGESTIM-ETH ESTRAD TRIPHASIC": "NORGESTIM-ETH ESTRAD TRIPHASIC",
              "PIRMELLA 7/7/7": "PIRMELLA 7/7/7",
              "TRI-LO-MARZIA": "TRI-LO-MARZIA"
            },
            "MAYNE PHARMA": {
              "CAZIANT": "CAZIANT",
              "LEENA": "LEENA",
              "TILIA FE": "TILIA FE",
              "TRI-NORINYL (28)": "TRI-NORINYL (28)",
              "TRIVORA (28)": "TRIVORA (28)"
            },
            "MYLAN": {
              "NORGESTIM-ETH ESTRAD TRIPHASIC": "NORGESTIM-ETH ESTRAD TRIPHASIC"
            },
            "NORTHSTAR RX": {
              "DASETTA 7/7/7": "DASETTA 7/7/7",
              "LEVONEST": "LEVONEST",
              "TRI-LINYAH": "TRI-LINYAH"
            },
            "PAR PHARMACEUTICAL": {
              "TRI-PREVIFEM": "TRI-PREVIFEM"
            },
            "PAR PHARMACEUTICALS": {
              "CYCLAFEM 7/7/7": "CYCLAFEM 7/7/7",
              "MYZILRA": "MYZILRA"
            },
            "PHARMEDIX": {
              "CESIA": "CESIA",
              "TRI-PREVIFEM": "TRI-PREVIFEM",
              "TRINESSA (28)": "TRINESSA (28)",
              "TRIVORA (28)": "TRIVORA (28)"
            },
            "PRASCO LABORATORIES": {
              "CYCLESSA": "CYCLESSA"
            },
            "PREFERRED PHARMACEUTICALS": {
              "NORGESTIM-ETH ESTRAD TRIPHASIC": "NORGESTIM-ETH ESTRAD TRIPHASIC",
              "TRI-LO-SPRINTEC": "TRI-LO-SPRINTEC",
              "TRI-SPRINTEC": "TRI-SPRINTEC",
              "TRINESSA (28)": "TRINESSA (28)"
            },
            "PROFICIENT RX": {
              "NORGESTIM-ETH ESTRAD TRIPHASIC": "NORGESTIM-ETH ESTRAD TRIPHASIC",
              "TRI-LO-MARZIA": "TRI-LO-MARZIA"
            },
            "QUALITY CARE": {
              "ORTHO TRI-CYCLEN (28)": "ORTHO TRI-CYCLEN (28)",
              "ORTHO TRI-CYCLEN LO": "ORTHO TRI-CYCLEN LO",
              "TRINESSA (28)": "TRINESSA (28)"
            },
            "TEVA PHARMACEUTICALS USA": {
              "ARANELLE": "ARANELLE",
              "ENPRESSE-28": "ENPRESSE-28",
              "NECON 7/7/7": "NECON 7/7/7",
              "NORTREL 7/7/7": "NORTREL 7/7/7",
              "TILIA FE": "TILIA FE",
              "TRI-LEGEST FE": "TRI-LEGEST FE",
              "TRI-LO-SPRINTEC": "TRI-LO-SPRINTEC",
              "TRI-SPRINTEC": "TRI-SPRINTEC",
              "VELIVET": "VELIVET"
            },
            "TEVA/WOMENS HEALTH": {
              "ENPRESSE-28": "ENPRESSE-28"
            },
            "XIROMED": {
              "TRI-ESTARYLLA": "TRI-ESTARYLLA",
              "TRI-LO-ESTARYLLA": "TRI-LO-ESTARYLLA"
            }
          }
        },
        "Combination Contraceptives - Transdermal": {
          "Combination Contraceptives - Transdermal": {
            "A-S MEDICATION SOLUTIONS": {
              "XULANE": "XULANE"
            },
            "AGILE THERAPEUTICS": {
              "TWIRLA": "TWIRLA"
            },
            "MYLAN": {
              "XULANE": "XULANE"
            }
          }
        },
        "Combination Contraceptives - Vaginal": {
          "Combination Contraceptives - Vaginal": {
            "A-S MEDICATION SOLUTIONS": {
              "NUVARING": "NUVARING"
            },
            "AMNEAL PHARMACEUTICALS": {
              "ELURYNG": "ELURYNG"
            },
            "ORGANON": {
              "NUVARING": "NUVARING"
            },
            "PRASCO LABORATORIES": {
              "ETONOGESTREL-ETHINYL ESTRADIOL": "ETONOGESTREL-ETHINYL ESTRADIOL"
            },
            "QUALITY CARE": {
              "NUVARING": "NUVARING"
            },
            "THERAPEUTICSMD": {
              "ANNOVERA": "ANNOVERA"
            }
          }
        },
        "Copper Contraceptives - IUD": {
          "Copper Contraceptives - IUD": {
            "COOPER SURGICAL": {
              "PARAGARD INTRAUTERINE COPPER": "PARAGARD INTRAUTERINE COPPER"
            }
          }
        },
        "Emergency Contraceptives": {
          "Emergency Contraceptives": {
            "A-S MEDICATION SOLUTIONS": {
              "ELLA": "ELLA",
              "MY WAY": "MY WAY",
              "NEXT CHOICE ONE DOSE": "NEXT CHOICE ONE DOSE",
              "PLAN B ONE-STEP": "PLAN B ONE-STEP"
            },
            "ACTAVIS PHARMA": {
              "NEXT CHOICE ONE DOSE": "NEXT CHOICE ONE DOSE"
            },
            "AFAXYS": {
              "ECONTRA EZ": "ECONTRA EZ",
              "ECONTRA ONE-STEP": "ECONTRA ONE-STEP",
              "ELLA": "ELLA"
            },
            "COMBE": {
              "PREVENTEZA": "PREVENTEZA"
            },
            "FOUNDATION CONSUMER HEALTHCARE": {
              "AFTERA": "AFTERA",
              "PLAN B ONE-STEP": "PLAN B ONE-STEP",
              "TAKE ACTION": "TAKE ACTION"
            },
            "GLENMARK PHARMACEUTICALS": {
              "LEVONORGESTREL": "LEVONORGESTREL"
            },
            "HRA PHARMA AMERICA": {
              "ELLA": "ELLA"
            },
            "LUPIN PHARMACEUTICALS": {
              "FALLBACK SOLO": "FALLBACK SOLO",
              "MY WAY": "MY WAY"
            },
            "NORTHSTAR RX": {
              "NEW DAY": "NEW DAY"
            },
            "PERRIGO": {
              "OPTION 2": "OPTION 2"
            },
            "PHARMACIST PHARMACEUTICAL LLC": {
              "LEVONORGESTREL": "LEVONORGESTREL"
            },
            "PROFICIENT RX": {
              "MY CHOICE": "MY CHOICE",
              "MY WAY": "MY WAY"
            },
            "RUGBY LABORATORIES": {
              "LEVONORGESTREL": "LEVONORGESTREL"
            },
            "SUN PHARMACEUTICALS": {
              "MY CHOICE": "MY CHOICE",
              "OPCICON ONE-STEP": "OPCICON ONE-STEP"
            },
            "WOCKHARDT USA": {
              "REACT": "REACT"
            },
            "XIROMED": {
              "LEVONORGESTREL": "LEVONORGESTREL"
            }
          }
        },
        "Progestin Contraceptives - IUD": {
          "Progestin Contraceptives - IUD": {
            "ACTAVIS PHARMA": {
              "LILETTA (52 MG)": "LILETTA (52 MG)"
            },
            "ALLERGAN": {
              "LILETTA (52 MG)": "LILETTA (52 MG)"
            },
            "BAYER HEALTHCARE PHARMA": {
              "KYLEENA": "KYLEENA",
              "MIRENA (52 MG)": "MIRENA (52 MG)",
              "SKYLA": "SKYLA"
            }
          }
        },
        "Progestin Contraceptives - Implants": {
          "Progestin Contraceptives - Implants": {
            "ORGANON": {
              "NEXPLANON": "NEXPLANON"
            }
          }
        },
        "Progestin Contraceptives - Injectable": {
          "Progestin Contraceptives - Injectable": {
            "A-S MEDICATION SOLUTIONS": {
              "DEPO-PROVERA": "DEPO-PROVERA",
              "DEPO-SUBQ PROVERA 104": "DEPO-SUBQ PROVERA 104",
              "MEDROXYPROGESTERONE ACETATE": "MEDROXYPROGESTERONE ACETATE"
            },
            "AFAXYS": {
              "MEDROXYPROGESTERONE ACETATE": "MEDROXYPROGESTERONE ACETATE"
            },
            "AMPHASTAR-IMS": {
              "MEDROXYPROGESTERONE ACETATE": "MEDROXYPROGESTERONE ACETATE"
            },
            "GREENSTONE": {
              "MEDROXYPROGESTERONE ACETATE": "MEDROXYPROGESTERONE ACETATE"
            },
            "MYLAN INSTITUTIONAL": {
              "MEDROXYPROGESTERONE ACETATE": "MEDROXYPROGESTERONE ACETATE"
            },
            "NORTHSTAR RX": {
              "MEDROXYPROGESTERONE ACETATE": "MEDROXYPROGESTERONE ACETATE"
            },
            "NOVAPLUS/AMPHASTAR": {
              "MEDROXYPROGESTERONE ACETATE": "MEDROXYPROGESTERONE ACETATE"
            },
            "PFIZER U.S.": {
              "DEPO-PROVERA": "DEPO-PROVERA",
              "DEPO-SUBQ PROVERA 104": "DEPO-SUBQ PROVERA 104"
            },
            "PROFICIENT RX": {
              "MEDROXYPROGESTERONE ACETATE": "MEDROXYPROGESTERONE ACETATE"
            },
            "SUN PHARMACEUTICALS": {
              "MEDROXYPROGESTERONE ACETATE": "MEDROXYPROGESTERONE ACETATE"
            },
            "TEVA PARENTERAL MEDICINES": {
              "MEDROXYPROGESTERONE ACETATE": "MEDROXYPROGESTERONE ACETATE"
            }
          }
        },
        "Progestin Contraceptives - Oral": {
          "Progestin Contraceptives - Oral": {
            "A-S MEDICATION SOLUTIONS": {
              "HEATHER": "HEATHER",
              "NORETHINDRONE": "NORETHINDRONE",
              "NORLYDA": "NORLYDA"
            },
            "ACTAVIS PHARMA": {
              "JOLIVETTE": "JOLIVETTE",
              "NOR-QD": "NOR-QD",
              "NORA-BE": "NORA-BE"
            },
            "AFAXYS": {
              "LYZA": "LYZA",
              "TULANA": "TULANA"
            },
            "AMNEAL PHARMACEUTICALS": {
              "NORLYDA": "NORLYDA"
            },
            "AUROBINDO PHARMA": {
              "INCASSIA": "INCASSIA"
            },
            "EXELTIS USA": {
              "SLYND": "SLYND"
            },
            "GLENMARK PHARMACEUTICALS": {
              "HEATHER": "HEATHER",
              "NORETHINDRONE": "NORETHINDRONE"
            },
            "JANSSEN": {
              "ORTHO MICRONOR": "ORTHO MICRONOR"
            },
            "LUPIN PHARMACEUTICALS": {
              "JENCYCLA": "JENCYCLA",
              "NORETHINDRONE": "NORETHINDRONE"
            },
            "MAYNE PHARMA": {
              "CAMILA": "CAMILA",
              "ERRIN": "ERRIN"
            },
            "MYLAN": {
              "NORETHINDRONE": "NORETHINDRONE"
            },
            "NORTHSTAR RX": {
              "DEBLITANE": "DEBLITANE",
              "NORETHINDRONE": "NORETHINDRONE",
              "SHAROBEL": "SHAROBEL"
            },
            "OHM LABS": {
              "NORLYROC": "NORLYROC"
            },
            "PROFICIENT RX": {
              "NORETHINDRONE": "NORETHINDRONE"
            },
            "TEVA PHARMACEUTICALS USA": {
              "CAMILA": "CAMILA"
            }
          }
        }
      },
      "CORTICOSTEROIDS": {
        "Glucocorticosteroids": {
          "Glucocorticosteroids": {
            "A-S MEDICATION SOLUTIONS": {
              "DEPO-MEDROL": "DEPO-MEDROL",
              "DEXAMETHASONE": "DEXAMETHASONE",
              "DEXAMETHASONE SOD PHOSPHATE PF": "DEXAMETHASONE SOD PHOSPHATE PF",
              "DEXAMETHASONE SODIUM PHOSPHATE": "DEXAMETHASONE SODIUM PHOSPHATE",
              "KENALOG": "KENALOG",
              "KENALOG-40": "KENALOG-40",
              "MEDROL": "MEDROL",
              "METHYLPREDNISOLONE": "METHYLPREDNISOLONE",
              "PREDNISOLONE": "PREDNISOLONE",
              "PREDNISOLONE SODIUM PHOSPHATE": "PREDNISOLONE SODIUM PHOSPHATE",
              "PREDNISONE": "PREDNISONE",
              "SOLU-CORTEF": "SOLU-CORTEF",
              "SOLU-MEDROL": "SOLU-MEDROL"
            },
            "ACROTECH BIOPHARMA": {
              "HEMADY": "HEMADY"
            },
            "ACTAVIS PHARMA": {
              "BUDESONIDE ER": "BUDESONIDE ER",
              "METHYLPREDNISOLONE": "METHYLPREDNISOLONE",
              "PREDNISONE": "PREDNISONE"
            },
            "AIDAREX PHARMACEUTICALS": {
              "DEXAMETHASONE": "DEXAMETHASONE",
              "METHYLPREDNISOLONE": "METHYLPREDNISOLONE",
              "METHYLPREDNISOLONE ACETATE": "METHYLPREDNISOLONE ACETATE",
              "PREDNISOLONE": "PREDNISOLONE",
              "PREDNISOLONE SODIUM PHOSPHATE": "PREDNISOLONE SODIUM PHOSPHATE",
              "PREDNISONE": "PREDNISONE"
            },
            "ALLEGIS HOLDINGS": {
              "LOCORT 11-DAY": "LOCORT 11-DAY",
              "LOCORT 7-DAY": "LOCORT 7-DAY"
            },
            "ALTURA PHARMACEUTICALS": {
              "DEXAMETHASONE": "DEXAMETHASONE",
              "METHYLPREDNISOLONE": "METHYLPREDNISOLONE",
              "PREDNISONE": "PREDNISONE"
            },
            "ALVOGEN": {
              "BUDESONIDE": "BUDESONIDE"
            },
            "AMERICAN HEALTH PACKAGING": {
              "BUDESONIDE": "BUDESONIDE",
              "HYDROCORTISONE": "HYDROCORTISONE",
              "METHYLPREDNISOLONE": "METHYLPREDNISOLONE",
              "PREDNISONE": "PREDNISONE"
            },
            "AMNEAL BIOSCIENCES": {
              "METHYLPREDNISOLONE ACETATE": "METHYLPREDNISOLONE ACETATE",
              "METHYLPREDNISOLONE SODIUM SUCC": "METHYLPREDNISOLONE SODIUM SUCC",
              "TRIAMCINOLONE ACETONIDE": "TRIAMCINOLONE ACETONIDE"
            },
            "AMNEAL PHARMACEUTICALS": {
              "BUDESONIDE": "BUDESONIDE",
              "HYDROCORTISONE": "HYDROCORTISONE"
            },
            "APHENA PHARMA SOLUTIONS": {
              "PREDNISONE": "PREDNISONE"
            },
            "API SOLUTIONS": {
              "CORTISONE ACETATE": "CORTISONE ACETATE"
            },
            "APOTHECA SUPPLY": {
              "BETAMETHASONE SODIUM PHOSPHATE": "BETAMETHASONE SODIUM PHOSPHATE",
              "METHYLPREDNISOLONE ACETATE": "METHYLPREDNISOLONE ACETATE",
              "PREDNISOLONE ANHYDROUS": "PREDNISOLONE ANHYDROUS",
              "PREDNISOLONE SODIUM PHOSPHATE": "PREDNISOLONE SODIUM PHOSPHATE"
            },
            "ASTRAZENECA LP": {
              "ENTOCORT EC": "ENTOCORT EC"
            },
            "ATLANTIC BIOLOGICALS": {
              "PREDNISOLONE SODIUM PHOSPHATE": "PREDNISOLONE SODIUM PHOSPHATE",
              "PREDNISONE": "PREDNISONE"
            },
            "AUROMEDICS PHARMA": {
              "DEXAMETHASONE SODIUM PHOSPHATE": "DEXAMETHASONE SODIUM PHOSPHATE"
            },
            "AVKARE": {
              "PREDNISONE": "PREDNISONE"
            },
            "AXIA PHARMACEUTICAL": {
              "DEXAMETHASONE (LA)": "DEXAMETHASONE (LA)",
              "METHYLPREDNISOLONE ACETATE": "METHYLPREDNISOLONE ACETATE",
              "TRIAMCINOLONE ACETONIDE": "TRIAMCINOLONE ACETONIDE",
              "TRIAMCINOLONE DIACETATE": "TRIAMCINOLONE DIACETATE"
            },
            "B & B PHARMACEUTICALS": {
              "METHYLPREDNISOLONE ACETATE": "METHYLPREDNISOLONE ACETATE",
              "TRIAMCINOLONE DIACET MICRONIZE": "TRIAMCINOLONE DIACET MICRONIZE"
            },
            "B-M SQUIBB U.S. (PRIMARY CARE)": {
              "KENALOG": "KENALOG",
              "KENALOG-80": "KENALOG-80"
            },
            "BAUSCH HEALTH": {
              "DEXPAK 10 DAY": "DEXPAK 10 DAY",
              "DEXPAK 13 DAY": "DEXPAK 13 DAY",
              "DEXPAK 6 DAY": "DEXPAK 6 DAY",
              "UCERIS": "UCERIS"
            },
            "BIOCOMP PHARMA": {
              "PREDNISOLONE SODIUM PHOSPHATE": "PREDNISOLONE SODIUM PHOSPHATE"
            },
            "BLENHEIM PHARMACAL": {
              "DEXAMETHASONE": "DEXAMETHASONE",
              "PREDNISONE": "PREDNISONE"
            },
            "BLUE POINT LABORATORIES": {
              "METHYLPREDNISOLONE": "METHYLPREDNISOLONE"
            },
            "BRYANT RANCH PREPACK": {
              "DEXAMETHASONE": "DEXAMETHASONE",
              "METHYLPREDNISOLONE": "METHYLPREDNISOLONE",
              "PREDNISOLONE": "PREDNISOLONE",
              "PREDNISONE": "PREDNISONE"
            },
            "BURKE THERAPEUTICS": {
              "PREDNISOLONE SODIUM PHOSPHATE": "PREDNISOLONE SODIUM PHOSPHATE"
            },
            "CADISTA": {
              "METHYLPREDNISOLONE": "METHYLPREDNISOLONE",
              "PREDNISONE": "PREDNISONE"
            },
            "CAMBRIDGE THERAPEUTICS TECHN": {
              "PREDNISONE": "PREDNISONE"
            },
            "CARDINAL HEALTH REPACKAGING SO": {
              "DEXAMETHASONE": "DEXAMETHASONE",
              "DEXAMETHASONE SODIUM PHOSPHATE": "DEXAMETHASONE SODIUM PHOSPHATE",
              "PREDNISONE": "PREDNISONE",
              "SOLU-CORTEF": "SOLU-CORTEF",
              "SOLU-MEDROL": "SOLU-MEDROL"
            },
            "CIVICA": {
              "DEXAMETHASONE SODIUM PHOSPHATE": "DEXAMETHASONE SODIUM PHOSPHATE"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "METHYLPREDNISOLONE": "METHYLPREDNISOLONE",
              "PREDNISOLONE": "PREDNISOLONE",
              "PREDNISONE": "PREDNISONE"
            },
            "CONCORDIA PHARMACEUTICALS": {
              "ORAPRED ODT": "ORAPRED ODT"
            },
            "DARMERICA": {
              "PREDNISOLONE SODIUM PHOSPHATE": "PREDNISOLONE SODIUM PHOSPHATE"
            },
            "DENTON PHARMA": {
              "PREDNISONE": "PREDNISONE"
            },
            "DIRECT RX": {
              "DEXAMETHASONE": "DEXAMETHASONE",
              "METHYLPREDNISOLONE": "METHYLPREDNISOLONE",
              "PREDNISOLONE": "PREDNISOLONE",
              "PREDNISONE": "PREDNISONE"
            },
            "EDENBRIDGE PHARMACEUTICALS": {
              "PREDNISOLONE SODIUM PHOSPHATE": "PREDNISOLONE SODIUM PHOSPHATE"
            },
            "ENOVACHEM MANUFACTURING": {
              "DEPO-MEDROL": "DEPO-MEDROL",
              "DOUBLEDEX": "DOUBLEDEX",
              "KENALOG": "KENALOG"
            },
            "EPIC PHARMA": {
              "METHYLPREDNISOLONE": "METHYLPREDNISOLONE"
            },
            "ETON PHARMACEUTICALS": {
              "ALKINDI SPRINKLE": "ALKINDI SPRINKLE"
            },
            "FAGRON": {
              "BETAMETHASONE SODIUM PHOSPHATE": "BETAMETHASONE SODIUM PHOSPHATE",
              "METHYLPREDNISOLONE": "METHYLPREDNISOLONE",
              "METHYLPREDNISOLONE ACETATE": "METHYLPREDNISOLONE ACETATE",
              "PREDNISOLONE ACETATE": "PREDNISOLONE ACETATE",
              "PREDNISOLONE ANHYDROUS": "PREDNISOLONE ANHYDROUS",
              "PREDNISOLONE SODIUM PHOSPHATE": "PREDNISOLONE SODIUM PHOSPHATE",
              "PREDNISONE": "PREDNISONE"
            },
            "FAGRON COMPOUNDING SERVICES": {
              "BETAMETHASONE SODIUM PHOSPHATE": "BETAMETHASONE SODIUM PHOSPHATE",
              "DEXAMETHASONE SODIUM PHOSPHATE": "DEXAMETHASONE SODIUM PHOSPHATE",
              "METHYLPREDNISOLONE ACETATE": "METHYLPREDNISOLONE ACETATE",
              "TRIAMCINOLONE ACETONIDE": "TRIAMCINOLONE ACETONIDE"
            },
            "FERA PHARMACEUTICALS": {
              "DEXAMETHASONE": "DEXAMETHASONE"
            },
            "FERRING": {
              "ORTIKOS": "ORTIKOS"
            },
            "FLEXION THERAPEUTICS": {
              "ZILRETTA": "ZILRETTA"
            },
            "FREEDOM PHARMACEUTICALS": {
              "BETAMETHASONE SODIUM PHOSPHATE": "BETAMETHASONE SODIUM PHOSPHATE",
              "METHYLPREDNISOLONE": "METHYLPREDNISOLONE",
              "METHYLPREDNISOLONE ACETATE": "METHYLPREDNISOLONE ACETATE"
            },
            "FRESENIUS KABI USA": {
              "DEXAMETHASONE SOD PHOSPHATE PF": "DEXAMETHASONE SOD PHOSPHATE PF",
              "DEXAMETHASONE SODIUM PHOSPHATE": "DEXAMETHASONE SODIUM PHOSPHATE",
              "METHYLPREDNISOLONE SODIUM SUCC": "METHYLPREDNISOLONE SODIUM SUCC"
            },
            "GENTEX PHARMA LLC": {
              "HIDEX 6-DAY": "HIDEX 6-DAY"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "BUDESONIDE": "BUDESONIDE",
              "BUDESONIDE ER": "BUDESONIDE ER",
              "DEXAMETHASONE": "DEXAMETHASONE",
              "PREDNISONE": "PREDNISONE"
            },
            "GREENSTONE": {
              "HYDROCORTISONE": "HYDROCORTISONE",
              "METHYLPREDNISOLONE": "METHYLPREDNISOLONE"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "DEXAMETHASONE": "DEXAMETHASONE",
              "METHYLPREDNISOLONE": "METHYLPREDNISOLONE",
              "PREDNISOLONE": "PREDNISOLONE",
              "PREDNISONE": "PREDNISONE"
            },
            "HI-TECH": {
              "PREDNISOLONE": "PREDNISOLONE",
              "PREDNISOLONE SODIUM PHOSPHATE": "PREDNISOLONE SODIUM PHOSPHATE"
            },
            "HIKMA": {
              "CORTISONE ACETATE": "CORTISONE ACETATE",
              "DEXAMETHASONE": "DEXAMETHASONE",
              "DEXAMETHASONE INTENSOL": "DEXAMETHASONE INTENSOL",
              "DEXAMETHASONE SODIUM PHOSPHATE": "DEXAMETHASONE SODIUM PHOSPHATE",
              "HYDROCORTISONE": "HYDROCORTISONE",
              "METHYLPREDNISOLONE SODIUM SUCC": "METHYLPREDNISOLONE SODIUM SUCC",
              "PREDNISONE": "PREDNISONE",
              "PREDNISONE INTENSOL": "PREDNISONE INTENSOL"
            },
            "HORIZON THERAPEUTICS USA": {
              "RAYOS": "RAYOS"
            },
            "HUMCO": {
              "PREDNISONE": "PREDNISONE"
            },
            "IMPAX GENERICS": {
              "HYDROCORTISONE": "HYDROCORTISONE"
            },
            "INGENUS PHARMACEUTICALS": {
              "METHYLPREDNISOLONE": "METHYLPREDNISOLONE"
            },
            "INTEGRATED PHARMA SOLUTIONS": {
              "ARZE-JECT-A": "ARZE-JECT-A"
            },
            "KALCHEM INTERNATIONAL": {
              "METHYLPREDNISOLONE ACETATE": "METHYLPREDNISOLONE ACETATE"
            },
            "KEY THERAPEUTICS": {
              "ZONACORT 11 DAY": "ZONACORT 11 DAY",
              "ZONACORT 7 DAY": "ZONACORT 7 DAY"
            },
            "LANNETT": {
              "PREDNISONE": "PREDNISONE"
            },
            "LARKEN LABORATORIES, INC.": {
              "DEXAMETHASONE": "DEXAMETHASONE"
            },
            "LETCO MEDICAL": {
              "BETAMETHASONE SODIUM PHOSPHATE": "BETAMETHASONE SODIUM PHOSPHATE",
              "METHYLPREDNISOLONE": "METHYLPREDNISOLONE",
              "METHYLPREDNISOLONE ACETATE": "METHYLPREDNISOLONE ACETATE",
              "PREDNISOLONE ACETATE": "PREDNISOLONE ACETATE",
              "PREDNISOLONE ANHYDROUS": "PREDNISOLONE ANHYDROUS",
              "PREDNISOLONE SODIUM PHOSPHATE": "PREDNISOLONE SODIUM PHOSPHATE",
              "PREDNISONE": "PREDNISONE",
              "TRIAMCINOLONE DIACETATE": "TRIAMCINOLONE DIACETATE"
            },
            "LEVINS PHARMACEUTICALS": {
              "DEXABLISS": "DEXABLISS"
            },
            "LIBERTY PHARMACEUTICAL": {
              "METHYLPREDNISOLONE": "METHYLPREDNISOLONE",
              "PREDNISONE": "PREDNISONE"
            },
            "LOHXA": {
              "PREDNISOLONE SODIUM PHOSPHATE": "PREDNISOLONE SODIUM PHOSPHATE"
            },
            "MAJOR PHARMACEUTICALS": {
              "METHYLPREDNISOLONE": "METHYLPREDNISOLONE",
              "PREDNISONE": "PREDNISONE"
            },
            "MAS MANAGEMENT GROUP": {
              "MAS CARE-PAK": "MAS CARE-PAK"
            },
            "MAYNE PHARMA": {
              "BUDESONIDE": "BUDESONIDE"
            },
            "MEDISCA": {
              "BETAMETHASONE SODIUM PHOSPHATE": "BETAMETHASONE SODIUM PHOSPHATE",
              "METHYLPREDNISOLONE ACETATE": "METHYLPREDNISOLONE ACETATE",
              "PREDNISOLONE ACETATE": "PREDNISOLONE ACETATE",
              "PREDNISOLONE ANHYDROUS": "PREDNISOLONE ANHYDROUS",
              "PREDNISOLONE SODIUM PHOSPHATE": "PREDNISOLONE SODIUM PHOSPHATE",
              "PREDNISONE": "PREDNISONE",
              "TRIAMCINOLONE DIACETATE": "TRIAMCINOLONE DIACETATE"
            },
            "MEDVANTX": {
              "METHYLPREDNISOLONE": "METHYLPREDNISOLONE",
              "PREDNISOLONE": "PREDNISOLONE",
              "PREDNISONE": "PREDNISONE"
            },
            "MISSION": {
              "PREDNISOLONE SODIUM PHOSPHATE": "PREDNISOLONE SODIUM PHOSPHATE"
            },
            "MORTON GROVE PHARMACEUTICALS": {
              "DEXAMETHASONE": "DEXAMETHASONE",
              "PREDNISOLONE SODIUM PHOSPHATE": "PREDNISOLONE SODIUM PHOSPHATE"
            },
            "MYLAN": {
              "BUDESONIDE": "BUDESONIDE",
              "BUDESONIDE ER": "BUDESONIDE ER",
              "PREDNISOLONE SODIUM PHOSPHATE": "PREDNISOLONE SODIUM PHOSPHATE",
              "PREDNISONE": "PREDNISONE"
            },
            "MYLAN INSTITUTIONAL": {
              "BUDESONIDE": "BUDESONIDE",
              "DEXAMETHASONE SODIUM PHOSPHATE": "DEXAMETHASONE SODIUM PHOSPHATE"
            },
            "NORTHSTAR RX": {
              "BUDESONIDE": "BUDESONIDE",
              "TRIAMCINOLONE ACETONIDE": "TRIAMCINOLONE ACETONIDE"
            },
            "NOVAPLUS/MYLAN INSTITUTIONAL": {
              "DEXAMETHASONE SODIUM PHOSPHATE": "DEXAMETHASONE SODIUM PHOSPHATE"
            },
            "NOVAPLUS/PFIZER U.S.": {
              "DEPO-MEDROL": "DEPO-MEDROL",
              "SOLU-MEDROL": "SOLU-MEDROL"
            },
            "NOVITIUM PHARMA": {
              "PREDNISONE": "PREDNISONE"
            },
            "NUBRATORI": {
              "DEXONTO 0.4%": "DEXONTO 0.4%"
            },
            "NUCARE PHARMACEUTICALS": {
              "DEXAMETHASONE": "DEXAMETHASONE",
              "METHYLPREDNISOLONE": "METHYLPREDNISOLONE",
              "PREDNISOLONE": "PREDNISOLONE",
              "PREDNISONE": "PREDNISONE"
            },
            "OAKLOCK": {
              "PRO-C-DURE 5": "PRO-C-DURE 5",
              "PRO-C-DURE 6": "PRO-C-DURE 6"
            },
            "OAKRUM PHARMA": {
              "METHYLPREDNISOLONE": "METHYLPREDNISOLONE"
            },
            "OCEANSIDE PHARMACEUTICALS": {
              "BUDESONIDE ER": "BUDESONIDE ER"
            },
            "OCULUS INNOVATIVE SERVICES": {
              "DELTASONE": "DELTASONE"
            },
            "PAR PHARMACEUTICAL": {
              "BUDESONIDE": "BUDESONIDE",
              "DEXAMETHASONE": "DEXAMETHASONE"
            },
            "PAR PHARMACEUTICALS": {
              "DEXAMETHASONE": "DEXAMETHASONE",
              "HYDROCORTISONE": "HYDROCORTISONE",
              "METHYLPREDNISOLONE": "METHYLPREDNISOLONE",
              "PREDNISOLONE": "PREDNISOLONE",
              "PREDNISONE": "PREDNISONE"
            },
            "PCCA": {
              "BETAMETHASONE SODIUM PHOSPHATE": "BETAMETHASONE SODIUM PHOSPHATE",
              "CORTISONE ACETATE": "CORTISONE ACETATE",
              "METHYLPREDNISOLONE": "METHYLPREDNISOLONE",
              "METHYLPREDNISOLONE ACETATE": "METHYLPREDNISOLONE ACETATE",
              "PREDNISOLONE": "PREDNISOLONE",
              "PREDNISOLONE ACETATE": "PREDNISOLONE ACETATE",
              "PREDNISOLONE SODIUM PHOSPHATE": "PREDNISOLONE SODIUM PHOSPHATE",
              "PREDNISONE": "PREDNISONE",
              "TRIAMCINOLONE DIACETATE": "TRIAMCINOLONE DIACETATE"
            },
            "PDRX PHARMACEUTICAL": {
              "DEXAMETHASONE": "DEXAMETHASONE",
              "HYDROCORTISONE": "HYDROCORTISONE",
              "METHYLPREDNISOLONE": "METHYLPREDNISOLONE",
              "PREDNISONE": "PREDNISONE"
            },
            "PDRX PHARMACEUTICALS": {
              "HYDROCORTISONE": "HYDROCORTISONE"
            },
            "PERRIGO": {
              "BUDESONIDE": "BUDESONIDE",
              "ENTOCORT EC": "ENTOCORT EC"
            },
            "PFIZER U.S.": {
              "CORTEF": "CORTEF",
              "DEPO-MEDROL": "DEPO-MEDROL",
              "MEDROL": "MEDROL",
              "SOLU-CORTEF": "SOLU-CORTEF",
              "SOLU-MEDROL": "SOLU-MEDROL"
            },
            "PHARMACEUTICA NORTH AMERICA": {
              "ACTIVE INJECTION D": "ACTIVE INJECTION D"
            },
            "PHARMACEUTICAL ASSOCIATES": {
              "PREDNISOLONE SODIUM PHOSPHATE": "PREDNISOLONE SODIUM PHOSPHATE"
            },
            "PHARMEDIUM SERVICES": {
              "DEXAMETHASONE SOD PHOS IN D5W": "DEXAMETHASONE SOD PHOS IN D5W",
              "DEXAMETHASONE SOD PHOS-NACL": "DEXAMETHASONE SOD PHOS-NACL",
              "DEXAMETHASONE SODIUM PHOSPHATE": "DEXAMETHASONE SODIUM PHOSPHATE"
            },
            "PHARMEDIX": {
              "METHYLPREDNISOLONE": "METHYLPREDNISOLONE",
              "PREDNISONE": "PREDNISONE"
            },
            "PHARMPAK": {
              "PREDNISONE": "PREDNISONE"
            },
            "PHLIGHT PHARMA": {
              "DXEVO 11-DAY": "DXEVO 11-DAY"
            },
            "PRAGMA PHARMACEUTICALS": {
              "DECADRON": "DECADRON"
            },
            "PRASCO LABORATORIES": {
              "PREDNISOLONE SODIUM PHOSPHATE": "PREDNISOLONE SODIUM PHOSPHATE"
            },
            "PREFERRED PHARMACEUTICALS": {
              "DEXAMETHASONE": "DEXAMETHASONE",
              "METHYLPREDNISOLONE": "METHYLPREDNISOLONE",
              "PREDNISOLONE": "PREDNISOLONE",
              "PREDNISOLONE SODIUM PHOSPHATE": "PREDNISOLONE SODIUM PHOSPHATE",
              "PREDNISONE": "PREDNISONE",
              "READYSHARP DEXAMETHASONE": "READYSHARP DEXAMETHASONE"
            },
            "PROFICIENT RX": {
              "DEXAMETHASONE": "DEXAMETHASONE",
              "METHYLPREDNISOLONE": "METHYLPREDNISOLONE",
              "PREDNISONE": "PREDNISONE",
              "TAPERDEX 12-DAY": "TAPERDEX 12-DAY",
              "TAPERDEX 6-DAY": "TAPERDEX 6-DAY"
            },
            "PTC THERAPEUTICS": {
              "EMFLAZA": "EMFLAZA"
            },
            "QUALITY CARE": {
              "DEPO-MEDROL": "DEPO-MEDROL",
              "DEXAMETHASONE": "DEXAMETHASONE",
              "DEXAMETHASONE SODIUM PHOSPHATE": "DEXAMETHASONE SODIUM PHOSPHATE",
              "KENALOG": "KENALOG",
              "MEDROL": "MEDROL",
              "METHYLPREDNISOLONE": "METHYLPREDNISOLONE",
              "METHYLPREDNISOLONE ACETATE": "METHYLPREDNISOLONE ACETATE",
              "PREDNISOLONE": "PREDNISOLONE",
              "PREDNISONE": "PREDNISONE"
            },
            "QUALITY CARE PRODUCTS": {
              "BUDESONIDE": "BUDESONIDE",
              "DEXAMETHASONE": "DEXAMETHASONE",
              "PREDNISONE": "PREDNISONE",
              "TAPERDEX 6-DAY": "TAPERDEX 6-DAY"
            },
            "RISING PHARMACEUTICALS": {
              "BUDESONIDE": "BUDESONIDE",
              "DEXAMETHASONE": "DEXAMETHASONE"
            },
            "ROYAL PHARMACEUTICALS": {
              "PEDIAPRED": "PEDIAPRED"
            },
            "RX PHARMA PACK": {
              "P-CARE D40": "P-CARE D40",
              "P-CARE D80": "P-CARE D80",
              "P-CARE K40": "P-CARE K40",
              "P-CARE K80": "P-CARE K80",
              "POD-CARE 100K": "POD-CARE 100K"
            },
            "RXCHANGE CO": {
              "METHYLPREDNISOLONE": "METHYLPREDNISOLONE",
              "PREDNISOLONE": "PREDNISOLONE",
              "PREDNISONE": "PREDNISONE"
            },
            "SAGENT PHARMACEUTICAL": {
              "METHYLPREDNISOLONE SODIUM SUCC": "METHYLPREDNISOLONE SODIUM SUCC"
            },
            "SANDOZ": {
              "METHYLPREDNISOLONE": "METHYLPREDNISOLONE",
              "METHYLPREDNISOLONE ACETATE": "METHYLPREDNISOLONE ACETATE"
            },
            "SCITE PHARMA": {
              "ZCORT 7-DAY": "ZCORT 7-DAY"
            },
            "SETON PHARMACEUTICALS": {
              "PREDNISOLONE SODIUM PHOSPHATE": "PREDNISOLONE SODIUM PHOSPHATE"
            },
            "SKY PACKAGING": {
              "METHYLPREDNISOLONE": "METHYLPREDNISOLONE",
              "PREDNISONE": "PREDNISONE"
            },
            "SKYLAR LABORATORIES": {
              "DXEVO 11-DAY": "DXEVO 11-DAY"
            },
            "SOMERSET THERAPEUTICS": {
              "DEXAMETHASONE SOD PHOSPHATE PF": "DEXAMETHASONE SOD PHOSPHATE PF",
              "DEXAMETHASONE SODIUM PHOSPHATE": "DEXAMETHASONE SODIUM PHOSPHATE"
            },
            "SPECTRUM": {
              "BETAMETHASONE SODIUM PHOSPHATE": "BETAMETHASONE SODIUM PHOSPHATE",
              "CORTISONE ACETATE": "CORTISONE ACETATE",
              "METHYLPREDNISOLONE": "METHYLPREDNISOLONE",
              "METHYLPREDNISOLONE ACETATE": "METHYLPREDNISOLONE ACETATE",
              "PREDNISOLONE": "PREDNISOLONE",
              "PREDNISOLONE ACETATE": "PREDNISOLONE ACETATE",
              "PREDNISOLONE SODIUM PHOSPHATE": "PREDNISOLONE SODIUM PHOSPHATE",
              "PREDNISONE": "PREDNISONE",
              "TRIAMCINOLONE DIACET MICRONIZE": "TRIAMCINOLONE DIACET MICRONIZE"
            },
            "ST MARY'S MPP": {
              "METHYLPREDNISOLONE": "METHYLPREDNISOLONE",
              "PREDNISONE": "PREDNISONE"
            },
            "STI PHARMA": {
              "DEXAMETHASONE": "DEXAMETHASONE"
            },
            "STRIDES PHARMA": {
              "HYDROCORTISONE": "HYDROCORTISONE"
            },
            "TERRAIN PHARMACEUTICALS": {
              "READYSHARP DEXAMETHASONE": "READYSHARP DEXAMETHASONE",
              "READYSHARP METHYLPREDNISOLONE": "READYSHARP METHYLPREDNISOLONE",
              "READYSHARP TRIAMCINOLONE": "READYSHARP TRIAMCINOLONE"
            },
            "TEVA PARENTERAL MEDICINES": {
              "METHYLPREDNISOLONE ACETATE": "METHYLPREDNISOLONE ACETATE",
              "TRIAMCINOLONE ACETONIDE": "TRIAMCINOLONE ACETONIDE"
            },
            "TEVA PHARMACEUTICALS USA": {
              "BUDESONIDE": "BUDESONIDE",
              "PREDNISOLONE": "PREDNISOLONE"
            },
            "TOPICARE MANAGEMENT": {
              "TOPIDEX": "TOPIDEX"
            },
            "UNIT DOSE SERVICES": {
              "METHYLPREDNISOLONE": "METHYLPREDNISOLONE",
              "PREDNISONE": "PREDNISONE"
            },
            "US COMPOUNDING": {
              "DEX LA 16": "DEX LA 16",
              "METHYLPREDNISOLONE ACETATE": "METHYLPREDNISOLONE ACETATE",
              "TRIAMCINOLONE ACETONIDE": "TRIAMCINOLONE ACETONIDE",
              "TRIAMCINOLONE DIACETATE": "TRIAMCINOLONE DIACETATE"
            },
            "VENSUN PHARMACEUTICALS": {
              "HYDROCORTISONE": "HYDROCORTISONE"
            },
            "WILLOW BIRCH PHARMA": {
              "BETAMETHASONE SODIUM PHOSPHATE": "BETAMETHASONE SODIUM PHOSPHATE",
              "METHYLPREDNISOLONE ACETATE": "METHYLPREDNISOLONE ACETATE"
            },
            "XSPIRE PHARMA": {
              "DEXAMETHASONE": "DEXAMETHASONE",
              "TAPERDEX 12-DAY": "TAPERDEX 12-DAY",
              "TAPERDEX 6-DAY": "TAPERDEX 6-DAY",
              "TAPERDEX 7-DAY": "TAPERDEX 7-DAY",
              "ZODEX 12-DAY": "ZODEX 12-DAY",
              "ZODEX 6-DAY": "ZODEX 6-DAY"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "BUDESONIDE": "BUDESONIDE",
              "METHYLPREDNISOLONE": "METHYLPREDNISOLONE"
            },
            "ZYLERA PHARMACEUTICALS": {
              "MILLIPRED": "MILLIPRED",
              "MILLIPRED DP": "MILLIPRED DP",
              "MILLIPRED DP 12-DAY": "MILLIPRED DP 12-DAY",
              "VERIPRED 20": "VERIPRED 20"
            }
          },
          "Steroid Combinations": {
            "AMERICAN REGENT": {
              "BETAMETHASONE SOD PHOS & ACET": "BETAMETHASONE SOD PHOS & ACET"
            },
            "AXIA PHARMACEUTICAL": {
              "BETAMETHASONE SOD PHOS & ACET": "BETAMETHASONE SOD PHOS & ACET",
              "DEXAMETHASONE ACE & SOD PHOS": "DEXAMETHASONE ACE & SOD PHOS",
              "METHYLPREDNISOLONE-BUPIVACAINE": "METHYLPREDNISOLONE-BUPIVACAINE",
              "TRIAMCINOLONE-BUPIVACAINE": "TRIAMCINOLONE-BUPIVACAINE"
            },
            "ENOVACHEM MANUFACTURING": {
              "BETALIDO": "BETALIDO",
              "BETALOAN SUIK": "BETALOAN SUIK",
              "BUPIVILOG": "BUPIVILOG",
              "DEXLIDO": "DEXLIDO",
              "DEXLIDO-M": "DEXLIDO-M",
              "DEXOPIN": "DEXOPIN",
              "DMT SUIK": "DMT SUIK",
              "DYURAL-40": "DYURAL-40",
              "DYURAL-80": "DYURAL-80",
              "DYURAL-L": "DYURAL-L",
              "DYURAL-LM": "DYURAL-LM",
              "INTERARTICULAR JOINT": "INTERARTICULAR JOINT",
              "LIDOLOG": "LIDOLOG",
              "MARBETA-25": "MARBETA-25",
              "MARBETA-L": "MARBETA-L",
              "MARDEX-25": "MARDEX-25",
              "MEDROLOAN II SUIK": "MEDROLOAN II SUIK",
              "MEDROLOAN SUIK": "MEDROLOAN SUIK",
              "MLK F1": "MLK F1",
              "MLK F2": "MLK F2",
              "MLK F3": "MLK F3",
              "MLK F4": "MLK F4",
              "MULTI-SPECIALTY": "MULTI-SPECIALTY",
              "PHYSICIANS EZ USE JOINT/TUNNEL": "PHYSICIANS EZ USE JOINT/TUNNEL",
              "PHYSICIANS EZ USE M-PRED": "PHYSICIANS EZ USE M-PRED",
              "ROPIDEX": "ROPIDEX",
              "TRILOAN II SUIK": "TRILOAN II SUIK",
              "TRILOAN SUIK": "TRILOAN SUIK"
            },
            "EXELA PHARMA SCIENCES": {
              "BETAMETHASONE SOD PHOS & ACET": "BETAMETHASONE SOD PHOS & ACET"
            },
            "LEITERS HEALTH": {
              "DEXAMETH SOD PHOS-BUPIV-EPIN": "DEXAMETH SOD PHOS-BUPIV-EPIN",
              "DEXAMETHASONE SOD PHOS-BUPIV": "DEXAMETHASONE SOD PHOS-BUPIV"
            },
            "MERCK SHARP & DOHME": {
              "CELESTONE SOLUSPAN": "CELESTONE SOLUSPAN"
            },
            "NOVAPLUS/AMER REGENT": {
              "BETAMETHASONE SOD PHOS & ACET": "BETAMETHASONE SOD PHOS & ACET"
            },
            "NUBRATORI": {
              "LIDOCIDEX I": "LIDOCIDEX I"
            },
            "NUCARE PHARMACEUTICALS": {
              "POINT OF CARE KM": "POINT OF CARE KM",
              "POINT OF CARE L.2": "POINT OF CARE L.2",
              "POINT OF CARE L.5": "POINT OF CARE L.5",
              "POINT OF CARE LM DEP 2": "POINT OF CARE LM DEP 2"
            },
            "OAKLOCK": {
              "BETA 1 KIT": "BETA 1 KIT",
              "BSP 0820": "BSP 0820"
            },
            "PHARMACEUTICA NORTH AMERICA": {
              "ACTIVE INJECTION BLM-1": "ACTIVE INJECTION BLM-1",
              "ACTIVE INJECTION BM": "ACTIVE INJECTION BM",
              "ACTIVE INJECTION DL": "ACTIVE INJECTION DL",
              "ACTIVE INJECTION DLM": "ACTIVE INJECTION DLM",
              "ACTIVE INJECTION KIT L": "ACTIVE INJECTION KIT L",
              "ACTIVE INJECTION KL-3": "ACTIVE INJECTION KL-3",
              "ACTIVE INJECTION KM": "ACTIVE INJECTION KM",
              "ACTIVE INJECTION LM-DEP-1": "ACTIVE INJECTION LM-DEP-1",
              "ACTIVE INJECTION LM-DEP-2": "ACTIVE INJECTION LM-DEP-2",
              "ACTIVE INJECTION M-1": "ACTIVE INJECTION M-1"
            },
            "PREFERRED PHARMACEUTICALS": {
              "PHYSICIANS EZ USE JOINT/TUNNEL": "PHYSICIANS EZ USE JOINT/TUNNEL",
              "READYSHARP BETAMETHASONE": "READYSHARP BETAMETHASONE"
            },
            "PROFICIENT RX": {
              "DEXLIDO-M": "DEXLIDO-M",
              "INTERARTICULAR JOINT": "INTERARTICULAR JOINT",
              "MULTI-SPECIALTY": "MULTI-SPECIALTY",
              "PHYSICIANS EZ USE M-PRED": "PHYSICIANS EZ USE M-PRED"
            },
            "PURE TEK": {
              "DERMACINRX CINLONE-I CPI": "DERMACINRX CINLONE-I CPI"
            },
            "QUALITY CARE": {
              "CELESTONE SOLUSPAN": "CELESTONE SOLUSPAN"
            },
            "RX PHARMA PACK": {
              "JTT PHYSICIANS": "JTT PHYSICIANS",
              "MLP A-1": "MLP A-1",
              "MLP A-2": "MLP A-2",
              "P-CARE D40G": "P-CARE D40G",
              "P-CARE D40MX": "P-CARE D40MX",
              "P-CARE D80G": "P-CARE D80G",
              "P-CARE D80MX": "P-CARE D80MX",
              "P-CARE K40G": "P-CARE K40G",
              "P-CARE K40MX": "P-CARE K40MX",
              "P-CARE K80G": "P-CARE K80G",
              "P-CARE K80MX": "P-CARE K80MX",
              "POD-CARE 100C": "POD-CARE 100C",
              "POD-CARE 100CG": "POD-CARE 100CG",
              "POD-CARE 100CMX": "POD-CARE 100CMX",
              "POD-CARE 100KG": "POD-CARE 100KG",
              "POD-CARE 100KMX": "POD-CARE 100KMX"
            },
            "SA3": {
              "BT INJECTION": "BT INJECTION",
              "LT INJECTION KIT": "LT INJECTION KIT"
            },
            "SHERTECH LABORATORIES": {
              "CONTRAST ALLERGY PREMED PACK": "CONTRAST ALLERGY PREMED PACK"
            },
            "TERRAIN PHARMACEUTICALS": {
              "READYSHARP ANESTH + BETAMETH": "READYSHARP ANESTH + BETAMETH",
              "READYSHARP ANESTH + DEXAMETH": "READYSHARP ANESTH + DEXAMETH",
              "READYSHARP ANESTH + METHYLPRED": "READYSHARP ANESTH + METHYLPRED",
              "READYSHARP BETAMETHASONE": "READYSHARP BETAMETHASONE",
              "READYSHARP-K": "READYSHARP-K",
              "READYSHARP-P40": "READYSHARP-P40",
              "READYSHARP-P80": "READYSHARP-P80"
            },
            "US COMPOUNDING": {
              "BETAMETHASONE COMBO": "BETAMETHASONE COMBO",
              "DEX COMBO": "DEX COMBO",
              "METHYLPREDNISOLONE ACE-LIDO": "METHYLPREDNISOLONE ACE-LIDO"
            }
          }
        },
        "Mineralocorticoids": {
          "Mineralocorticoids": {
            "AMERICAN HEALTH PACKAGING": {
              "FLUDROCORTISONE ACETATE": "FLUDROCORTISONE ACETATE"
            },
            "AMNEAL PHARMACEUTICALS": {
              "FLUDROCORTISONE ACETATE": "FLUDROCORTISONE ACETATE"
            },
            "AVKARE": {
              "FLUDROCORTISONE ACETATE": "FLUDROCORTISONE ACETATE"
            },
            "AVPAK": {
              "FLUDROCORTISONE ACETATE": "FLUDROCORTISONE ACETATE"
            },
            "FAGRON": {
              "FLUDROCORTISONE ACETATE": "FLUDROCORTISONE ACETATE"
            },
            "LETCO MEDICAL": {
              "FLUDROCORTISONE ACETATE": "FLUDROCORTISONE ACETATE"
            },
            "MEDISCA": {
              "FLUDROCORTISONE ACETATE": "FLUDROCORTISONE ACETATE"
            },
            "PCCA": {
              "FLUDROCORTISONE ACETATE": "FLUDROCORTISONE ACETATE"
            },
            "PDRX PHARMACEUTICAL": {
              "FLUDROCORTISONE ACETATE": "FLUDROCORTISONE ACETATE"
            },
            "SPECTRUM": {
              "FLUDROCORTISONE ACETATE": "FLUDROCORTISONE ACETATE"
            },
            "TEVA PHARMACEUTICALS USA": {
              "FLUDROCORTISONE ACETATE": "FLUDROCORTISONE ACETATE"
            }
          }
        }
      },
      "COUGH/COLD/ALLERGY": {
        "Antitussives": {
          "Antitussive - Nonnarcotic": {
            "A-S MEDICATION SOLUTIONS": {
              "BENZONATATE": "BENZONATATE",
              "DELSYM": "DELSYM"
            },
            "ADVANTICE HEALTH": {
              "PEDIACARE CHILDRENS LONG-ACT": "PEDIACARE CHILDRENS LONG-ACT"
            },
            "AIDAREX PHARMACEUTICALS": {
              "BENZONATATE": "BENZONATATE"
            },
            "AIPING PHARMACEUTICAL": {
              "BENZONATATE": "BENZONATATE"
            },
            "ALEMBIC PHARMACEUTICALS": {
              "BENZONATATE": "BENZONATATE"
            },
            "ALVIX LABORATORIES": {
              "DEXTROMETHORPHAN HBR": "DEXTROMETHORPHAN HBR"
            },
            "AMERICAN HEALTH PACKAGING": {
              "BENZONATATE": "BENZONATATE"
            },
            "AMERICAN SALES COMPANY": {
              "COUGH RELIEF": "COUGH RELIEF",
              "TUSSIN COUGH LONG-ACTING": "TUSSIN COUGH LONG-ACTING"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP COUGH DM ER": "GNP COUGH DM ER",
              "GNP COUGH GELS": "GNP COUGH GELS"
            },
            "AMNEAL PHARMACEUTICALS": {
              "BENZONATATE": "BENZONATATE"
            },
            "ASCEND LABORATORIES": {
              "BENZONATATE": "BENZONATATE"
            },
            "AVKARE": {
              "BENZONATATE": "BENZONATATE"
            },
            "B.F. ASCHER": {
              "HOLD": "HOLD"
            },
            "BERGEN BRUNSWIG": {
              "GNP COUGH RELIEF": "GNP COUGH RELIEF",
              "GNP TUSSIN COUGH LONG ACTING": "GNP TUSSIN COUGH LONG ACTING"
            },
            "BIONPHARMA": {
              "BENZONATATE": "BENZONATATE"
            },
            "BLENHEIM PHARMACAL": {
              "BENZONATATE": "BENZONATATE"
            },
            "BRYANT RANCH PREPACK": {
              "BENZONATATE": "BENZONATATE"
            },
            "CAMBER PHARMACEUTICALS": {
              "BENZONATATE": "BENZONATATE"
            },
            "CHAIN DRUG CONSORTIUM": {
              "COUGH RELIEF": "COUGH RELIEF",
              "COUGH RELIEF ADULT": "COUGH RELIEF ADULT",
              "TUSSIN COUGH": "TUSSIN COUGH",
              "TUSSIN COUGH LONG-ACTING": "TUSSIN COUGH LONG-ACTING"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC COUGH RELIEF": "QC COUGH RELIEF"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "BENZONATATE": "BENZONATATE"
            },
            "CREOMULSION": {
              "CREOMULSION ADULT": "CREOMULSION ADULT",
              "CREOMULSION FOR CHILDREN": "CREOMULSION FOR CHILDREN"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS COUGH DM": "CVS COUGH DM",
              "CVS COUGH DM CHILDRENS": "CVS COUGH DM CHILDRENS",
              "CVS TUSSIN COUGH": "CVS TUSSIN COUGH",
              "CVS TUSSIN LONG-ACTING": "CVS TUSSIN LONG-ACTING",
              "CVS TUSSIN MAXIMUM STRENGTH": "CVS TUSSIN MAXIMUM STRENGTH"
            },
            "DENTON PHARMA": {
              "BENZONATATE": "BENZONATATE"
            },
            "DIRECT RX": {
              "BENZONATATE": "BENZONATATE"
            },
            "EPIC PHARMA": {
              "BENZONATATE": "BENZONATATE"
            },
            "EQUALINE": {
              "EQL COUGH DM": "EQL COUGH DM",
              "EQL TUSSIN COUGH LONG-ACTING": "EQL TUSSIN COUGH LONG-ACTING"
            },
            "FAGRON": {
              "DEXTROMETHORPHAN HBR MONOHYD": "DEXTROMETHORPHAN HBR MONOHYD"
            },
            "FREEDOM PHARMACEUTICALS": {
              "DEXTROMETHORPHAN HBR MONOHYD": "DEXTROMETHORPHAN HBR MONOHYD"
            },
            "GEISS DESTIN & DUNN": {
              "GOODSENSE COUGH DM": "GOODSENSE COUGH DM",
              "GOODSENSE COUGH DM CHILDRENS": "GOODSENSE COUGH DM CHILDRENS"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "BUCKLEYS COUGH": "BUCKLEYS COUGH",
              "ROBITUSSIN 12 HOUR COUGH": "ROBITUSSIN 12 HOUR COUGH",
              "ROBITUSSIN 12 HOUR COUGH CHILD": "ROBITUSSIN 12 HOUR COUGH CHILD",
              "ROBITUSSIN CHILDRENS COUGH LA": "ROBITUSSIN CHILDRENS COUGH LA",
              "ROBITUSSIN LINGERING COUGHGELS": "ROBITUSSIN LINGERING COUGHGELS",
              "ROBITUSSIN LINGERING LA COUGH": "ROBITUSSIN LINGERING LA COUGH",
              "TRIAMINIC LONG ACTING COUGH": "TRIAMINIC LONG ACTING COUGH"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "BENZONATATE": "BENZONATATE"
            },
            "GREENSTONE": {
              "BENZONATATE": "BENZONATATE"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "BENZONATATE": "BENZONATATE"
            },
            "HUMCO": {
              "DEXTROMETHORPHAN HBR": "DEXTROMETHORPHAN HBR"
            },
            "IRISYS": {
              "SCOT-TUSSIN DIABETES": "SCOT-TUSSIN DIABETES",
              "SCOT-TUSSIN DIABETES CF": "SCOT-TUSSIN DIABETES CF"
            },
            "KAISER FOUNDATION HOSPITAL": {
              "BENZONATATE": "BENZONATATE"
            },
            "LEADER BRAND PRODUCTS": {
              "COUGH DM": "COUGH DM",
              "COUGH DM CHILDRENS": "COUGH DM CHILDRENS"
            },
            "LETCO MEDICAL": {
              "DEXTROMETHORPHAN HBR": "DEXTROMETHORPHAN HBR"
            },
            "LIBERTY PHARMACEUTICAL": {
              "BENZONATATE": "BENZONATATE"
            },
            "MAJOR PHARMACEUTICALS": {
              "BENZONATATE": "BENZONATATE",
              "COUGH DM": "COUGH DM"
            },
            "MALLINCKRODT PHARM": {
              "BENZONATATE": "BENZONATATE"
            },
            "MARLEX PHARMACEUTICALS": {
              "BENZONATATE": "BENZONATATE"
            },
            "MCKESSON": {
              "HM COUGH DM": "HM COUGH DM",
              "HM COUGH RELIEF": "HM COUGH RELIEF"
            },
            "MCKESSON SUNMARK": {
              "SM COUGH RELIEF": "SM COUGH RELIEF"
            },
            "MEDICINE SHOPPE": {
              "TUSSIN COUGH": "TUSSIN COUGH"
            },
            "MEDIQUE PRODUCTS": {
              "TROCAL COUGH SUPPRESSANT": "TROCAL COUGH SUPPRESSANT"
            },
            "MEDISCA": {
              "DEXTROMETHORPHAN HBR MONOHYD": "DEXTROMETHORPHAN HBR MONOHYD"
            },
            "MEDSTONE PHARMA": {
              "BENZONATATE": "BENZONATATE"
            },
            "MEDVANTX": {
              "BENZONATATE": "BENZONATATE"
            },
            "NUCARE PHARMACEUTICALS": {
              "BENZONATATE": "BENZONATATE"
            },
            "OAKHURST": {
              "FATHER JOHNS MEDICINE": "FATHER JOHNS MEDICINE"
            },
            "P & G HEALTH": {
              "VICKS DAYQUIL COUGH": "VICKS DAYQUIL COUGH"
            },
            "PCCA": {
              "DEXTROMETHORPHAN HBR": "DEXTROMETHORPHAN HBR"
            },
            "PDRX PHARMACEUTICAL": {
              "BENZONATATE": "BENZONATATE",
              "TESSALON PERLES": "TESSALON PERLES"
            },
            "PDRX PHARMACEUTICALS": {
              "BENZONATATE": "BENZONATATE"
            },
            "PERRIGO PHARMACEUTICALS": {
              "DEXTROMETHORPHAN POLISTIREX ER": "DEXTROMETHORPHAN POLISTIREX ER"
            },
            "PFIZER U.S.": {
              "TESSALON PERLES": "TESSALON PERLES"
            },
            "PHARMEDIX": {
              "BENZONATATE": "BENZONATATE"
            },
            "PREFERRED PHARMACEUTICALS": {
              "BENZONATATE": "BENZONATATE"
            },
            "PROFICIENT RX": {
              "BENZONATATE": "BENZONATATE"
            },
            "PUBLIX SUPER MARKETS INC.": {
              "PX TUSSIN MAX": "PX TUSSIN MAX"
            },
            "QUALITY CARE": {
              "BENZONATATE": "BENZONATATE",
              "TESSALON PERLES": "TESSALON PERLES"
            },
            "QUALITY CARE PRODUCTS": {
              "BENZONATATE": "BENZONATATE"
            },
            "READYMEDS": {
              "BENZONATATE": "BENZONATATE"
            },
            "RECKITT BENCKISER": {
              "DELSYM": "DELSYM",
              "DELSYM COUGH CHILDRENS": "DELSYM COUGH CHILDRENS"
            },
            "RITE AID CORPORATION": {
              "RA COUGH DM": "RA COUGH DM",
              "RA TUSSIN COUGH": "RA TUSSIN COUGH",
              "RA TUSSIN COUGH ADULT": "RA TUSSIN COUGH ADULT",
              "RA TUSSIN LONG ACTING COUGH": "RA TUSSIN LONG ACTING COUGH",
              "RA TUSSIN MAXIMUM STRENGTH": "RA TUSSIN MAXIMUM STRENGTH"
            },
            "RUGBY LABORATORIES": {
              "ROBAFEN COUGH": "ROBAFEN COUGH"
            },
            "SELECT BRAND": {
              "SB COUGH CONTROL": "SB COUGH CONTROL",
              "SB COUGH RELIEF": "SB COUGH RELIEF"
            },
            "SHEFFIELD PHARMACEUTICALS": {
              "CREO-TERPIN": "CREO-TERPIN"
            },
            "SILARX": {
              "SILPHEN DM COUGH": "SILPHEN DM COUGH"
            },
            "SKY PACKAGING": {
              "BENZONATATE": "BENZONATATE"
            },
            "SOLUBIOMIX LLC": {
              "BENZONATATE": "BENZONATATE"
            },
            "SPECTRUM": {
              "DEXTROMETHORPHAN HBR MONOHYD": "DEXTROMETHORPHAN HBR MONOHYD"
            },
            "ST MARY'S MPP": {
              "BENZONATATE": "BENZONATATE"
            },
            "STERLING KNIGHT PHARMACEUTICAL": {
              "BENZONATATE": "BENZONATATE"
            },
            "STRIDES PHARMA": {
              "BENZONATATE": "BENZONATATE"
            },
            "SUN PHARMACEUTICALS": {
              "BENZONATATE": "BENZONATATE"
            },
            "TAGI PHARMA": {
              "BENZONATATE": "BENZONATATE"
            },
            "TARO": {
              "ELIXSURE COUGH": "ELIXSURE COUGH"
            },
            "TOPCO": {
              "COUGH DM": "COUGH DM",
              "TUSSIN COUGH": "TUSSIN COUGH"
            },
            "UNIT DOSE SERVICES": {
              "BENZONATATE": "BENZONATATE"
            },
            "VERTICAL PHARMACEUTICAL": {
              "ZONATUSS": "ZONATUSS"
            },
            "VETCO, INC.": {
              "LITTLE COLDS COUGH FORMULA": "LITTLE COLDS COUGH FORMULA"
            },
            "WAL-MART": {
              "EQ COUGH DM": "EQ COUGH DM"
            },
            "WALGREENS": {
              "COUGH DM": "COUGH DM",
              "COUGH DM CHILDRENS": "COUGH DM CHILDRENS",
              "DAYTIME COUGH": "DAYTIME COUGH",
              "WAL-TUSSIN COUGH": "WAL-TUSSIN COUGH",
              "WAL-TUSSIN COUGH LONG ACTING": "WAL-TUSSIN COUGH LONG ACTING",
              "WAL-TUSSIN COUGH RELIEF": "WAL-TUSSIN COUGH RELIEF"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "BENZONATATE": "BENZONATATE"
            }
          },
          "Antitussive - Opioid": {
            "A-S MEDICATION SOLUTIONS": {
              "HYDROCODONE-HOMATROPINE": "HYDROCODONE-HOMATROPINE"
            },
            "ACTAVIS MID ATLANTIC": {
              "HYDROMET": "HYDROMET"
            },
            "BRYANT RANCH PREPACK": {
              "HYDROCODONE-HOMATROPINE": "HYDROCODONE-HOMATROPINE"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "HYDROCODONE-HOMATROPINE": "HYDROCODONE-HOMATROPINE"
            },
            "HI-TECH": {
              "HYDROCODONE-HOMATROPINE": "HYDROCODONE-HOMATROPINE"
            },
            "KVK TECH": {
              "HYDROCODONE-HOMATROPINE": "HYDROCODONE-HOMATROPINE"
            },
            "LUPIN PHARMACEUTICALS": {
              "HYDROCODONE-HOMATROPINE": "HYDROCODONE-HOMATROPINE"
            },
            "MORTON GROVE PHARMACEUTICALS": {
              "HYDROCODONE-HOMATROPINE": "HYDROCODONE-HOMATROPINE"
            },
            "PERRIGO": {
              "HYDROCODONE-HOMATROPINE": "HYDROCODONE-HOMATROPINE"
            },
            "PFIZER U.S.": {
              "TUSSIGON": "TUSSIGON"
            },
            "PHARMACEUTICAL ASSOCIATES": {
              "HYDROCODONE-HOMATROPINE": "HYDROCODONE-HOMATROPINE"
            },
            "QUALITY CARE": {
              "HYDROCODONE-HOMATROPINE": "HYDROCODONE-HOMATROPINE"
            },
            "TORRENT PHARMACEUTICALS": {
              "HYDROCODONE-HOMATROPINE": "HYDROCODONE-HOMATROPINE"
            }
          }
        },
        "Cough/Cold/Allergy Combinations": {
          "Analgesic-Expectorant": {
            "CHAIN DRUG CONSORTIUM": {
              "CHEST CONGESTION/PAIN RELIEF": "CHEST CONGESTION/PAIN RELIEF"
            },
            "EQUALINE": {
              "EQL CHEST CONGESTION DAYTIME": "EQL CHEST CONGESTION DAYTIME"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "COMTREX DEEP CHEST COLD": "COMTREX DEEP CHEST COLD"
            },
            "REESE PHARMACEUTICAL": {
              "REFENESEN CHEST CONG/PAIN RLF": "REFENESEN CHEST CONG/PAIN RLF"
            }
          },
          "Antihistamine-Analgesics": {
            "ACTIPHARMA": {
              "ACTIDOGESIC": "ACTIDOGESIC",
              "ACTIDOGESIC-DF": "ACTIDOGESIC-DF"
            },
            "BAYER CONSUMER": {
              "CORICIDIN HBP COLD/FLU": "CORICIDIN HBP COLD/FLU",
              "CORICIDIN NIGHTTIME COLD-COUGH": "CORICIDIN NIGHTTIME COLD-COUGH"
            },
            "CHAIN DRUG CONSORTIUM": {
              "NIGHTTIME PAIN PLUS SLEEP": "NIGHTTIME PAIN PLUS SLEEP"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC COLD RELIEF": "QC COLD RELIEF",
              "QC SEVERE ALLERGY": "QC SEVERE ALLERGY"
            },
            "EQUALINE": {
              "EQL SEVERE ALLERGY PAIN RELIEF": "EQL SEVERE ALLERGY PAIN RELIEF"
            },
            "KRAMER-NOVIS": {
              "DOLOGEN": "DOLOGEN",
              "G-DOLOGEN": "G-DOLOGEN"
            },
            "LLORENS PHARMACEUTICAL": {
              "DOLOGESIC": "DOLOGESIC",
              "DOLOGESIC-DF": "DOLOGESIC-DF"
            },
            "MAGNA PHARMACEUTICALS, INC": {
              "STAFLEX": "STAFLEX"
            },
            "MEDTECH": {
              "PERCOGESIC": "PERCOGESIC",
              "PERCOGESIC EXTRA STRENGTH": "PERCOGESIC EXTRA STRENGTH"
            },
            "RITE AID CORPORATION": {
              "RA COLD & FLU": "RA COLD & FLU"
            },
            "RUGBY LABORATORIES": {
              "ACETA-GESIC": "ACETA-GESIC"
            },
            "SELECT BRAND": {
              "SB COLD & FLU HBP": "SB COLD & FLU HBP"
            },
            "WALGREENS": {
              "SEVERE ALLERGY": "SEVERE ALLERGY"
            }
          },
          "Antitussive-Antihistamine-Analgesic": {
            "AKORN CONSUMER": {
              "DIABETIC TUSSIN COLD & FLU": "DIABETIC TUSSIN COLD & FLU",
              "DIABETIC TUSSIN COLD/FLU": "DIABETIC TUSSIN COLD/FLU",
              "DIABETIC TUSSIN NIGHT COLD/FLU": "DIABETIC TUSSIN NIGHT COLD/FLU"
            },
            "AMERICAN SALES COMPANY": {
              "CHILDRENS COUGH/RUNNY NOSE": "CHILDRENS COUGH/RUNNY NOSE",
              "COUGH & SORE THROAT NIGHTTIME": "COUGH & SORE THROAT NIGHTTIME",
              "NITE TIME MULTI-SYMPTOM RELIEF": "NITE TIME MULTI-SYMPTOM RELIEF"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP NIGHT TIME COLD & FLU": "GNP NIGHT TIME COLD & FLU",
              "GNP NIGHT TIME COLD-FLU": "GNP NIGHT TIME COLD-FLU"
            },
            "AURORA HEALTHCARE": {
              "NIGHTTIME COLD MEDICINE": "NIGHTTIME COLD MEDICINE"
            },
            "BAYER CONSUMER": {
              "CORICIDIN HBP": "CORICIDIN HBP",
              "CORICIDIN HBP FLU": "CORICIDIN HBP FLU",
              "CORICIDIN HBP NIGHTTIME COLD": "CORICIDIN HBP NIGHTTIME COLD"
            },
            "BERGEN BRUNSWIG": {
              "GNP NIGHT TIME COLD & FLU": "GNP NIGHT TIME COLD & FLU",
              "GNP NIGHT TIME COLD/FLU RELIEF": "GNP NIGHT TIME COLD/FLU RELIEF"
            },
            "CENTURION LABS": {
              "NINJACOF-A": "NINJACOF-A"
            },
            "CHAIN DRUG CONSORTIUM": {
              "CHILDRENS COUGH/RUNNY NOSE": "CHILDRENS COUGH/RUNNY NOSE",
              "COUGH & SORE THROAT NIGHT TIME": "COUGH & SORE THROAT NIGHT TIME",
              "NIGHT TIME MS COLD/FLU RELIEF": "NIGHT TIME MS COLD/FLU RELIEF",
              "NIGHT-TIME COLD/FLU RELIEF": "NIGHT-TIME COLD/FLU RELIEF",
              "NIGHTTIME COLD/FLU RELIEF": "NIGHTTIME COLD/FLU RELIEF"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC COUGH/SORE THROAT NIGHTTIME": "QC COUGH/SORE THROAT NIGHTTIME",
              "QC NIGHTTIME COLD & FLU": "QC NIGHTTIME COLD & FLU",
              "QC NIGHTTIME COLD/FLU RELIEF": "QC NIGHTTIME COLD/FLU RELIEF",
              "QC NIGHTTIME MULTI-SYMPTOM": "QC NIGHTTIME MULTI-SYMPTOM"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS COLD/FLU NIGHTTIME": "CVS COLD/FLU NIGHTTIME",
              "CVS COUGH & RUNNY NOSE CHILD": "CVS COUGH & RUNNY NOSE CHILD",
              "CVS NIGHT TIME COLD/FLU RELIEF": "CVS NIGHT TIME COLD/FLU RELIEF",
              "CVS NIGHTTIME COLD/FLU RELIEF": "CVS NIGHTTIME COLD/FLU RELIEF"
            },
            "EQUALINE": {
              "EQL NIGHTTIME COLD & FLU": "EQL NIGHTTIME COLD & FLU",
              "EQL NIGHTTIME COLD/FLU RELIEF": "EQL NIGHTTIME COLD/FLU RELIEF"
            },
            "GEISS DESTIN & DUNN": {
              "GOODSENSE NIGHTTIME COLD & FLU": "GOODSENSE NIGHTTIME COLD & FLU"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "ROBITUSSIN COLD+FLU NIGHTTIME": "ROBITUSSIN COLD+FLU NIGHTTIME",
              "TRIAMINIC FLU COUGH & FEVER": "TRIAMINIC FLU COUGH & FEVER",
              "TRIAMINIC FLU/COUGH/FEVER": "TRIAMINIC FLU/COUGH/FEVER"
            },
            "LEADER BRAND PRODUCTS": {
              "COLD & FLU RELIEF NIGHTTIME": "COLD & FLU RELIEF NIGHTTIME",
              "FLU HBP": "FLU HBP",
              "NIGHTTIME COLD & FLU MAX STR": "NIGHTTIME COLD & FLU MAX STR",
              "NIGHTTIME COLD/FLU RELIEF": "NIGHTTIME COLD/FLU RELIEF",
              "NITE-TIME COLD/FLU": "NITE-TIME COLD/FLU",
              "NITE-TIME COLD/FLU RELIEF": "NITE-TIME COLD/FLU RELIEF"
            },
            "MAGNO-HUMPHRIES LABORATORIES": {
              "NIGHTTIME COLD/FLU RELIEF": "NIGHTTIME COLD/FLU RELIEF"
            },
            "MAJOR PHARMACEUTICALS": {
              "ALL-NITE COLD & FLU NIGHTTIME": "ALL-NITE COLD & FLU NIGHTTIME",
              "ALL-NITE MULTI-SYMPT COLD/FLU": "ALL-NITE MULTI-SYMPT COLD/FLU",
              "COLD & FLU NIGHTTIME RELIEF": "COLD & FLU NIGHTTIME RELIEF",
              "NIGHT TIME COLD/FLU RELIEF": "NIGHT TIME COLD/FLU RELIEF"
            },
            "MARLEX PHARMACEUTICALS": {
              "NIGHT TIME COLD/FLU RELIEF": "NIGHT TIME COLD/FLU RELIEF"
            },
            "MCKESSON": {
              "HM NIGHT TIME COLD & FLU": "HM NIGHT TIME COLD & FLU",
              "HM NIGHT TIME MS COLD & FLU": "HM NIGHT TIME MS COLD & FLU",
              "HM NIGHTTIME COLD & FLU RELIEF": "HM NIGHTTIME COLD & FLU RELIEF"
            },
            "MCKESSON SUNMARK": {
              "SM COUGH/SORE THROAT NIGHTTIME": "SM COUGH/SORE THROAT NIGHTTIME",
              "SM NIGHT TIME COLD & FLU": "SM NIGHT TIME COLD & FLU",
              "SM NITE TIME COLD & FLU": "SM NITE TIME COLD & FLU",
              "SM NITE TIME COLD & FLU RELIEF": "SM NITE TIME COLD & FLU RELIEF"
            },
            "MCNEIL CONSUMER": {
              "TYLENOL CHILDRENS CLD+CGH": "TYLENOL CHILDRENS CLD+CGH"
            },
            "MEDA CONSUMER HEALTHCARE": {
              "CONTAC COLD+FLU NIGHT": "CONTAC COLD+FLU NIGHT"
            },
            "P & G HEALTH": {
              "NYQUIL HBP COLD & FLU": "NYQUIL HBP COLD & FLU",
              "VICKS NYQUIL COLD & FLU": "VICKS NYQUIL COLD & FLU",
              "VICKS NYQUIL COLD & FLU NIGHT": "VICKS NYQUIL COLD & FLU NIGHT"
            },
            "PERRIGO PHARMACEUTICALS": {
              "NIGHTTIME COLD/FLU RELIEF": "NIGHTTIME COLD/FLU RELIEF"
            },
            "PUBLIX SUPER MARKETS INC.": {
              "PX NITETIME COLD/FLU RELIEF": "PX NITETIME COLD/FLU RELIEF"
            },
            "RECKITT BENCKISER": {
              "MUCINEX NIGHTSHIFT COLD/FLU": "MUCINEX NIGHTSHIFT COLD/FLU"
            },
            "RITE AID CORPORATION": {
              "RA CHILDRENS COUGH/RUNNY NOSE": "RA CHILDRENS COUGH/RUNNY NOSE",
              "RA COLD/FLU MULTI-SYMPTOM NITE": "RA COLD/FLU MULTI-SYMPTOM NITE",
              "RA COLD/FLU RELIEF NIGHTTIME": "RA COLD/FLU RELIEF NIGHTTIME",
              "RA FLU MAXIMUM STRENGTH": "RA FLU MAXIMUM STRENGTH",
              "RA NIGHTTIME COLD/FLU RELIEF": "RA NIGHTTIME COLD/FLU RELIEF",
              "RA NITE TIME COLD/FLU FORMULA": "RA NITE TIME COLD/FLU FORMULA"
            },
            "SELECT BRAND": {
              "SB CHILDRENS COUGH/RUNNY NOSE": "SB CHILDRENS COUGH/RUNNY NOSE",
              "SB FLU MAXIMUM STRENGTH HBP": "SB FLU MAXIMUM STRENGTH HBP",
              "SB NIGHT TIME COLD/FLU RELIEF": "SB NIGHT TIME COLD/FLU RELIEF"
            },
            "STRIDES USA CONSUMER": {
              "PEDIACARE COUGH/RUNNY NOSE": "PEDIACARE COUGH/RUNNY NOSE"
            },
            "TARGET": {
              "TGT COLD/FLU RELIEF NIGHTTIME": "TGT COLD/FLU RELIEF NIGHTTIME"
            },
            "TOPCO": {
              "COUGH & SORE THROAT NIGHTTIME": "COUGH & SORE THROAT NIGHTTIME",
              "NITE TIME COLD/FLU RELIEF": "NITE TIME COLD/FLU RELIEF",
              "NITE TIME MULTI-SYMPTOM RELIEF": "NITE TIME MULTI-SYMPTOM RELIEF",
              "NITE-TIME COLD/FLU RELIEF": "NITE-TIME COLD/FLU RELIEF"
            },
            "VETCO, INC.": {
              "CLEAR COUGH PM MULTI-SYMPTOM": "CLEAR COUGH PM MULTI-SYMPTOM"
            },
            "WAL-MART": {
              "EQ NITETIME COLD/FLU MS RELIEF": "EQ NITETIME COLD/FLU MS RELIEF"
            },
            "WALGREENS": {
              "CHILDRENS COUGH/RUNNY NOSE": "CHILDRENS COUGH/RUNNY NOSE",
              "COLD & FLU NIGHTTIME": "COLD & FLU NIGHTTIME",
              "COLD & FLU RELIEF NIGHTTIME": "COLD & FLU RELIEF NIGHTTIME",
              "COLD/FLU RELIEF NIGHTTIME": "COLD/FLU RELIEF NIGHTTIME",
              "COUGH & SORE THROAT NIGHTTIME": "COUGH & SORE THROAT NIGHTTIME",
              "FLU BP MAXIMUM STRENGTH": "FLU BP MAXIMUM STRENGTH",
              "NIGHTTIME COLD/FLU RELIEF": "NIGHTTIME COLD/FLU RELIEF"
            }
          },
          "Antitussive-Decongestant-Analgesic": {
            "AMERICAN SALES COMPANY": {
              "COLD MULTI-SYMPTOM DAYTIME": "COLD MULTI-SYMPTOM DAYTIME",
              "DAYTIME COLD/FLU RELIEF": "DAYTIME COLD/FLU RELIEF"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP COLD MAX DAYTIME": "GNP COLD MAX DAYTIME",
              "GNP COLD RELIEF MULTI-SYMPTOM": "GNP COLD RELIEF MULTI-SYMPTOM",
              "GNP DAY TIME COLD/FLU": "GNP DAY TIME COLD/FLU",
              "GNP FLU RELIEF THERAPY DAYTIME": "GNP FLU RELIEF THERAPY DAYTIME",
              "GNP FLU/SEVERE COLD/COUGH DAY": "GNP FLU/SEVERE COLD/COUGH DAY"
            },
            "BAYER CONSUMER": {
              "ALKA-SELTZER PLS SINUS & COUGH": "ALKA-SELTZER PLS SINUS & COUGH",
              "ALKA-SELTZER PLUS DAY COLD/FLU": "ALKA-SELTZER PLUS DAY COLD/FLU"
            },
            "BERGEN BRUNSWIG": {
              "GNP COLD HEAD CONGESTION": "GNP COLD HEAD CONGESTION",
              "GNP COLD MULTI-SYMPTOM DAYTIME": "GNP COLD MULTI-SYMPTOM DAYTIME",
              "GNP DAY TIME COLD/FLU": "GNP DAY TIME COLD/FLU",
              "GNP DAY TIME COLD/FLU RELIEF": "GNP DAY TIME COLD/FLU RELIEF"
            },
            "CHAIN DRUG CONSORTIUM": {
              "COLD MULTI-SYMPTOM DAYTIME": "COLD MULTI-SYMPTOM DAYTIME",
              "DAY-TIME COLD/FLU RELIEF": "DAY-TIME COLD/FLU RELIEF",
              "DAYTIME COLD & FLU RELIEF PLUS": "DAYTIME COLD & FLU RELIEF PLUS",
              "DAYTIME COLD/FLU RELIEF": "DAYTIME COLD/FLU RELIEF",
              "FLU RELIEF THERAPY DAYTIME": "FLU RELIEF THERAPY DAYTIME"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC DAYTIME COLD/FLU": "QC DAYTIME COLD/FLU",
              "QC FLU RELIEF THERAPY DAYTIME": "QC FLU RELIEF THERAPY DAYTIME",
              "QC SEVERE COLD/COUGH DAYTIME": "QC SEVERE COLD/COUGH DAYTIME"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS DAYTIME COLD/FLU RELIEF": "CVS DAYTIME COLD/FLU RELIEF",
              "CVS FLU/SEVERE COLD DAYTIME": "CVS FLU/SEVERE COLD DAYTIME",
              "CVS SEVERE COLD/FLU DAYTIME": "CVS SEVERE COLD/FLU DAYTIME",
              "DAYTIME COLD & FLU RELIEF": "DAYTIME COLD & FLU RELIEF"
            },
            "EQUALINE": {
              "EQL COLD MULTI-SYMPTOM DAYTIME": "EQL COLD MULTI-SYMPTOM DAYTIME",
              "EQL DAYTIME COLD & FLU RELIEF": "EQL DAYTIME COLD & FLU RELIEF",
              "EQL DAYTIME COLD/FLU RELIEF": "EQL DAYTIME COLD/FLU RELIEF",
              "EQL FLU/SEVERE COLD DAYTIME": "EQL FLU/SEVERE COLD DAYTIME",
              "EQL SEVERE COLD": "EQL SEVERE COLD"
            },
            "GEISS DESTIN & DUNN": {
              "GOODSENSE DAY TIME COLD & FLU": "GOODSENSE DAY TIME COLD & FLU",
              "GOODSENSE DAYTIME": "GOODSENSE DAYTIME",
              "GOODSENSE FLU/COLD/DAYTIME": "GOODSENSE FLU/COLD/DAYTIME",
              "GOODSENSE PRESSURE/PAIN/COUGH": "GOODSENSE PRESSURE/PAIN/COUGH",
              "GOODSENSE SEVERE COLD/COUGH": "GOODSENSE SEVERE COLD/COUGH"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "COMTREX COLD & COUGH MAX ST": "COMTREX COLD & COUGH MAX ST",
              "ROBITUSSIN COLD+FLU DAYTIME": "ROBITUSSIN COLD+FLU DAYTIME",
              "THERAFLU EXPRESSMAX": "THERAFLU EXPRESSMAX",
              "THERAFLU EXPRESSMAX SEV CLD/CG": "THERAFLU EXPRESSMAX SEV CLD/CG",
              "THERAFLU POWERPODS SEVERE COLD": "THERAFLU POWERPODS SEVERE COLD",
              "THERAFLU SEVERE COLD": "THERAFLU SEVERE COLD",
              "THERAFLU SEVERE COLD DAYTIME": "THERAFLU SEVERE COLD DAYTIME",
              "THERAFLU SEVERE COLD/CGH DAY": "THERAFLU SEVERE COLD/CGH DAY",
              "THERAFLU WARMING RELIEF COLD": "THERAFLU WARMING RELIEF COLD",
              "THERAFLU WARMING RELIEF DAY": "THERAFLU WARMING RELIEF DAY"
            },
            "KROGER COMPANY": {
              "DAYTIME COLD/FLU RELIEF": "DAYTIME COLD/FLU RELIEF"
            },
            "LEADER BRAND PRODUCTS": {
              "COLD & FLU RELIEF DAYTIME": "COLD & FLU RELIEF DAYTIME",
              "COLD HEAD CONGESTION DAYTIME": "COLD HEAD CONGESTION DAYTIME",
              "COLD RELIEF": "COLD RELIEF",
              "DAY TIME MULTI-SYMPT COLD/FLU": "DAY TIME MULTI-SYMPT COLD/FLU",
              "DAY-TIME PE": "DAY-TIME PE",
              "DAYTIME COLD & FLU RELIEF": "DAYTIME COLD & FLU RELIEF",
              "DAYTIME COLD/FLU RELIEF": "DAYTIME COLD/FLU RELIEF",
              "FLU/SEVERE COLD & COUGH DAY": "FLU/SEVERE COLD & COUGH DAY",
              "MULTI SYMPTOM FLU/SEVERE COLD": "MULTI SYMPTOM FLU/SEVERE COLD"
            },
            "MAGNO-HUMPHRIES LABORATORIES": {
              "DAYTIME COLD/FLU RELIEF": "DAYTIME COLD/FLU RELIEF"
            },
            "MAJOR PHARMACEUTICALS": {
              "COLD/FLU DAYTIME RELIEF": "COLD/FLU DAYTIME RELIEF",
              "DAY TIME COLD/FLU RELIEF": "DAY TIME COLD/FLU RELIEF",
              "DAY TIME MULTI-SYMPT COLD/FLU": "DAY TIME MULTI-SYMPT COLD/FLU",
              "DAYTIME COLD & FLU RELIEF": "DAYTIME COLD & FLU RELIEF",
              "MAPAP COLD FORMULA MULTI-SYMPT": "MAPAP COLD FORMULA MULTI-SYMPT"
            },
            "MCKESSON": {
              "HM DAY TIME": "HM DAY TIME",
              "HM DAYTIME COLD & FLU": "HM DAYTIME COLD & FLU",
              "HM SEVERE COLD/COUGH/FLU": "HM SEVERE COLD/COUGH/FLU"
            },
            "MCKESSON SUNMARK": {
              "SM COLD HEAD CONGESTION DAY": "SM COLD HEAD CONGESTION DAY",
              "SM DAY TIME": "SM DAY TIME",
              "SM DAY TIME COLD & FLU RELIEF": "SM DAY TIME COLD & FLU RELIEF",
              "SM DAY TIME NON DROWSY": "SM DAY TIME NON DROWSY",
              "SM DAY TIME PE COLD/FLU RELIEF": "SM DAY TIME PE COLD/FLU RELIEF",
              "SM DAYTIME LIQUID": "SM DAYTIME LIQUID"
            },
            "MCNEIL CONSUMER": {
              "SUDAFED PE PRESSURE+PAIN+COUGH": "SUDAFED PE PRESSURE+PAIN+COUGH",
              "TYLENOL COLD MAX": "TYLENOL COLD MAX"
            },
            "MEDICINE SHOPPE": {
              "DAY-TIME COLD/FLU RELIEF": "DAY-TIME COLD/FLU RELIEF",
              "DAY-TIME PE COLD/FLU RELIEF": "DAY-TIME PE COLD/FLU RELIEF"
            },
            "MEDTECH": {
              "LITTLE REMEDIES FOR COLDS": "LITTLE REMEDIES FOR COLDS"
            },
            "P & G HEALTH": {
              "VICKS DAYQUIL COLD & FLU": "VICKS DAYQUIL COLD & FLU"
            },
            "PERRIGO": {
              "GOODSENSE COLD MAX": "GOODSENSE COLD MAX"
            },
            "PERRIGO PHARMACEUTICALS": {
              "FLU/SEVERE COLD & COUGH DAY": "FLU/SEVERE COLD & COUGH DAY",
              "GOODSENSE COLD & FLU": "GOODSENSE COLD & FLU"
            },
            "PUBLIX SUPER MARKETS INC.": {
              "PX DAYTIME COLD": "PX DAYTIME COLD",
              "PX DAYTIME COLD/FLU RELIEF": "PX DAYTIME COLD/FLU RELIEF",
              "PX DAYTIME MULTI-SYMPTOM": "PX DAYTIME MULTI-SYMPTOM",
              "PX DAYTIME PE": "PX DAYTIME PE"
            },
            "RECKITT BENCKISER": {
              "MUCINEX FAST-MAX COLD & SINUS": "MUCINEX FAST-MAX COLD & SINUS",
              "MUCINEX FAST-MAX CONG HEADACHE": "MUCINEX FAST-MAX CONG HEADACHE",
              "MUCINEX SINUS-MAX CONG & PAIN": "MUCINEX SINUS-MAX CONG & PAIN"
            },
            "RITE AID CORPORATION": {
              "RA ACETAMINOPHEN FLU COLD": "RA ACETAMINOPHEN FLU COLD",
              "RA COLD MULTI-SYMPTOM DAYTIME": "RA COLD MULTI-SYMPTOM DAYTIME",
              "RA COLD/FLU RELIEF DAYTIME": "RA COLD/FLU RELIEF DAYTIME",
              "RA DAY TIME COLD/FLU FORMULA": "RA DAY TIME COLD/FLU FORMULA",
              "RA DAYTIME COLD/FLU": "RA DAYTIME COLD/FLU",
              "RA DAYTIME COLD/FLU RELIEF": "RA DAYTIME COLD/FLU RELIEF",
              "RA DAYTIME MULTI-SYMP COLD/FLU": "RA DAYTIME MULTI-SYMP COLD/FLU",
              "RA DAYTIME MULTI-SYMPTOM COLD": "RA DAYTIME MULTI-SYMPTOM COLD",
              "RA SEVERE COLD & COUGH DAY": "RA SEVERE COLD & COUGH DAY"
            },
            "SELECT BRAND": {
              "SB DAY TIME COLD/FLU RELIEF": "SB DAY TIME COLD/FLU RELIEF",
              "SB DAYTIME": "SB DAYTIME",
              "SB FLU RELIEF THERAPY DAYTIME": "SB FLU RELIEF THERAPY DAYTIME"
            },
            "SHEFFIELD PHARMACEUTICALS": {
              "666 COLD PREPARATION": "666 COLD PREPARATION"
            },
            "SYENZ LABORATORY": {
              "HERBIOMED FAST ACTING": "HERBIOMED FAST ACTING"
            },
            "TARGET": {
              "TGT COLD HEAD CONGESTION": "TGT COLD HEAD CONGESTION",
              "TGT COLD RELIEF MULTI-SYMP DAY": "TGT COLD RELIEF MULTI-SYMP DAY",
              "TGT COLD/FLU RELIEF DAY TIME": "TGT COLD/FLU RELIEF DAY TIME",
              "TGT COLD/FLU RELIEF DAY-TIME": "TGT COLD/FLU RELIEF DAY-TIME",
              "TGT FLU/SEVERE COLD/COUGH RLF": "TGT FLU/SEVERE COLD/COUGH RLF"
            },
            "TOPCO": {
              "COLD HEAD CONGESTION DAYTIME": "COLD HEAD CONGESTION DAYTIME",
              "COLD MULTI-SYMPTOM DAYTIME": "COLD MULTI-SYMPTOM DAYTIME",
              "DAY TIME COLD/FLU RELIEF": "DAY TIME COLD/FLU RELIEF",
              "DAY TIME PE COLD/FLU RELIEF": "DAY TIME PE COLD/FLU RELIEF",
              "FLU/SEVERE COLD & COUGH DAY": "FLU/SEVERE COLD & COUGH DAY"
            },
            "WAL-MART": {
              "EQ COLD MULTI-SYMPTOM DAYTIME": "EQ COLD MULTI-SYMPTOM DAYTIME",
              "EQ DAYTIME COLD/FLU MS RELIEF": "EQ DAYTIME COLD/FLU MS RELIEF",
              "EQ DAYTIME COLD/FLU RELIEF": "EQ DAYTIME COLD/FLU RELIEF"
            },
            "WALGREENS": {
              "COLD MULTI-SYMPTOM DAYTIME": "COLD MULTI-SYMPTOM DAYTIME",
              "COLD/FLU RELIEF": "COLD/FLU RELIEF",
              "DAYTIME COLD/FLU RELIEF": "DAYTIME COLD/FLU RELIEF",
              "WAL-FLU SEVERE COLD & COUGH": "WAL-FLU SEVERE COLD & COUGH"
            }
          },
          "Antitussive-Expectorant": {
            "A-S MEDICATION SOLUTIONS": {
              "CHERATUSSIN AC": "CHERATUSSIN AC",
              "DEXTROMETHORPHAN-GUAIFENESIN": "DEXTROMETHORPHAN-GUAIFENESIN",
              "DIABETIC SILTUSSIN-DM": "DIABETIC SILTUSSIN-DM",
              "GUAIATUSSIN AC": "GUAIATUSSIN AC",
              "GUAIFENESIN-CODEINE": "GUAIFENESIN-CODEINE",
              "GUAIFENESIN-DM": "GUAIFENESIN-DM",
              "MUCINEX DM": "MUCINEX DM",
              "MUCINEX DM MAXIMUM STRENGTH": "MUCINEX DM MAXIMUM STRENGTH",
              "VIRTUSSIN A/C": "VIRTUSSIN A/C"
            },
            "ACCELIS PHARMA": {
              "OBREDON": "OBREDON"
            },
            "ADVANCED GENERIC": {
              "BIOCOTRON": "BIOCOTRON",
              "BIOSPEC DMX": "BIOSPEC DMX"
            },
            "AG MARIN PHARMACEUTICALS": {
              "NEOTUSS": "NEOTUSS"
            },
            "AIDAREX PHARMACEUTICALS": {
              "CHERATUSSIN AC": "CHERATUSSIN AC",
              "GUAIFENESIN-CODEINE": "GUAIFENESIN-CODEINE",
              "ROBAFEN DM": "ROBAFEN DM",
              "TUSSIN DM": "TUSSIN DM"
            },
            "AKORN CONSUMER": {
              "DIABETIC TUSSIN DM": "DIABETIC TUSSIN DM",
              "DIABETIC TUSSIN FOR CHILDREN": "DIABETIC TUSSIN FOR CHILDREN",
              "DIABETIC TUSSIN MAX ST": "DIABETIC TUSSIN MAX ST"
            },
            "ALLEGIS PHARMACEUTICALS": {
              "MAR-COF CG EXPECTORANT": "MAR-COF CG EXPECTORANT"
            },
            "ALTAIRE": {
              "ALTARUSSIN DM": "ALTARUSSIN DM"
            },
            "ALTURA PHARMACEUTICALS": {
              "GUAIFENESIN-CODEINE": "GUAIFENESIN-CODEINE"
            },
            "AMBI PHARMACEUTICALS": {
              "G-FEN DM": "G-FEN DM"
            },
            "AMERICAN SALES COMPANY": {
              "CHEST CONGESTION RELIEF DM": "CHEST CONGESTION RELIEF DM",
              "MUCUS RELIEF COUGH CHILDRENS": "MUCUS RELIEF COUGH CHILDRENS",
              "TUSSIN COUGH DM": "TUSSIN COUGH DM",
              "TUSSIN DM": "TUSSIN DM",
              "TUSSIN DM MAX": "TUSSIN DM MAX"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP DAY TIME MUCUS RELIEF DM": "GNP DAY TIME MUCUS RELIEF DM",
              "GNP MUCUS DM MAX STRENGTH": "GNP MUCUS DM MAX STRENGTH",
              "GNP MUCUS RELIEF DM MAX": "GNP MUCUS RELIEF DM MAX",
              "GNP TAB TUSSIN DM": "GNP TAB TUSSIN DM",
              "GNP TUSSIN DM MAX": "GNP TUSSIN DM MAX"
            },
            "ATLANTIC BIOLOGICALS": {
              "CHERATUSSIN AC": "CHERATUSSIN AC",
              "ROBAFEN DM CLEAR": "ROBAFEN DM CLEAR",
              "ROBAFEN DM COUGH CLEAR": "ROBAFEN DM COUGH CLEAR"
            },
            "AURORA HEALTHCARE": {
              "TUSSIN DM": "TUSSIN DM"
            },
            "BAYER CONSUMER": {
              "ALKA-SELTZER PLUS MUCUS & CONG": "ALKA-SELTZER PLUS MUCUS & CONG",
              "CORICIDIN HBP CONGESTION/COUGH": "CORICIDIN HBP CONGESTION/COUGH"
            },
            "BERGEN BRUNSWIG": {
              "GNP MUCUS RELIEF COUGH CHILD": "GNP MUCUS RELIEF COUGH CHILD",
              "GNP MUCUS RELIEF DM": "GNP MUCUS RELIEF DM",
              "GNP TUSSIN DM": "GNP TUSSIN DM",
              "GNP TUSSIN DM COUGH": "GNP TUSSIN DM COUGH"
            },
            "BIORAMO": {
              "GUAIFENESIN-CODEINE": "GUAIFENESIN-CODEINE"
            },
            "BRYANT RANCH PREPACK": {
              "GUAIFENESIN-CODEINE": "GUAIFENESIN-CODEINE",
              "REFENESEN DM": "REFENESEN DM"
            },
            "BUREL PHARMACEUTICALS": {
              "RELCOF C": "RELCOF C"
            },
            "BURKE THERAPEUTICS": {
              "HYDROCODONE-GUAIFENESIN": "HYDROCODONE-GUAIFENESIN"
            },
            "CENTURION LABS": {
              "NINJACOF-XG": "NINJACOF-XG",
              "TRYMINE CG": "TRYMINE CG"
            },
            "CHAIN DRUG CONSORTIUM": {
              "CHILDRENS MUCUS RELIEF COUGH": "CHILDRENS MUCUS RELIEF COUGH",
              "DM MAX MAXIMUM STRENGTH": "DM MAX MAXIMUM STRENGTH",
              "MUCUS RELIEF DM": "MUCUS RELIEF DM",
              "TUSSIN COUGH DM SUGAR FREE": "TUSSIN COUGH DM SUGAR FREE",
              "TUSSIN DM": "TUSSIN DM",
              "TUSSIN DM MAX": "TUSSIN DM MAX",
              "TUSSIN DM MAX SUGAR-FREE": "TUSSIN DM MAX SUGAR-FREE"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC MEDIFIN DM": "QC MEDIFIN DM",
              "QC MUCUS & COUGH RELIEF CHILD": "QC MUCUS & COUGH RELIEF CHILD",
              "QC MUCUS RELIEF DM MAX": "QC MUCUS RELIEF DM MAX",
              "QC TUSSIN DM COUGH/CONGESTION": "QC TUSSIN DM COUGH/CONGESTION",
              "TUSSIN DM": "TUSSIN DM"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS CHEST CONGEST/COUGH CHILD": "CVS CHEST CONGEST/COUGH CHILD",
              "CVS CHEST CONGESTION RELIEF DM": "CVS CHEST CONGESTION RELIEF DM",
              "CVS COUGH & CHEST CONGESTION": "CVS COUGH & CHEST CONGESTION",
              "CVS COUGH/CHEST DM CHILDRENS": "CVS COUGH/CHEST DM CHILDRENS",
              "CVS DM MAXIMUM ADULT": "CVS DM MAXIMUM ADULT",
              "CVS MUCUS DM EXTENDED RELEASE": "CVS MUCUS DM EXTENDED RELEASE",
              "CVS TUSSIN COUGH": "CVS TUSSIN COUGH",
              "CVS TUSSIN DM": "CVS TUSSIN DM",
              "CVS TUSSIN DM COUGH/CHEST": "CVS TUSSIN DM COUGH/CHEST",
              "CVS TUSSIN DM MAX ST": "CVS TUSSIN DM MAX ST"
            },
            "DASH PHARMACEUTICALS": {
              "GUAIFENESIN-DM": "GUAIFENESIN-DM"
            },
            "DELIZ PHARMACEUTICAL CORP": {
              "TRISPEC DMX": "TRISPEC DMX",
              "TRISPEC DMX PEDIATRIC": "TRISPEC DMX PEDIATRIC"
            },
            "EQUALINE": {
              "EQL MUCUS-DM": "EQL MUCUS-DM",
              "EQL MUCUS-DM MAXIMUM STRENGTH": "EQL MUCUS-DM MAXIMUM STRENGTH",
              "EQL TUSSIN COUGH/CHEST CONGEST": "EQL TUSSIN COUGH/CHEST CONGEST",
              "EQL TUSSIN COUGH/CHEST DM MAX": "EQL TUSSIN COUGH/CHEST DM MAX",
              "EQL TUSSIN DM COUGH/CHEST CONG": "EQL TUSSIN DM COUGH/CHEST CONG"
            },
            "GEISS DESTIN & DUNN": {
              "GOODSENSE MUCUS RELIEF DM": "GOODSENSE MUCUS RELIEF DM",
              "MUCUS RELIEF COUGH CHILDRENS": "MUCUS RELIEF COUGH CHILDRENS"
            },
            "GERI-CARE": {
              "GERI-TUSSIN DM": "GERI-TUSSIN DM",
              "GUAIASORB DM": "GUAIASORB DM"
            },
            "GIL PHARMACEUTICAL CORP.": {
              "GILTUSS COUGH & CHEST": "GILTUSS COUGH & CHEST",
              "GILTUSS COUGH & CHEST CHILDREN": "GILTUSS COUGH & CHEST CHILDREN",
              "GILTUSS DIABETIC COUGH & COLD": "GILTUSS DIABETIC COUGH & COLD"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "ROBITUSSIN COLD COUGH+ CHEST": "ROBITUSSIN COLD COUGH+ CHEST",
              "ROBITUSSIN COUGH+ CHEST MAX ST": "ROBITUSSIN COUGH+ CHEST MAX ST",
              "ROBITUSSIN COUGH+CHEST CONG DM": "ROBITUSSIN COUGH+CHEST CONG DM",
              "ROBITUSSIN DM SUGAR FREE": "ROBITUSSIN DM SUGAR FREE",
              "ROBITUSSIN PEAK COLD DM": "ROBITUSSIN PEAK COLD DM",
              "ROBITUSSIN TO GO CGH/CHEST DM": "ROBITUSSIN TO GO CGH/CHEST DM",
              "TRIAMINIC COUGH & CONGESTION": "TRIAMINIC COUGH & CONGESTION"
            },
            "GLENDALE INC": {
              "CODITUSSIN AC": "CODITUSSIN AC"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "GUAIFENESIN-CODEINE": "GUAIFENESIN-CODEINE",
              "GUAIFENESIN-DM": "GUAIFENESIN-DM"
            },
            "HANNAFORD BROTHERS": {
              "TUSSIN DM": "TUSSIN DM"
            },
            "HI-TECH": {
              "DEXTROMETHORPHAN-GUAIFENESIN": "DEXTROMETHORPHAN-GUAIFENESIN",
              "GUAIATUSSIN AC": "GUAIATUSSIN AC"
            },
            "ION LABS": {
              "EXTUSSIVE": "EXTUSSIVE",
              "SORBUTUSS": "SORBUTUSS"
            },
            "IRISYS": {
              "SCOT-TUSSIN SENIOR": "SCOT-TUSSIN SENIOR"
            },
            "KRAMER LABS": {
              "SAFE TUSSIN DM": "SAFE TUSSIN DM"
            },
            "KRAMER-NOVIS": {
              "G-TRON": "G-TRON",
              "G-ZYNCOF": "G-ZYNCOF",
              "PECGEN DMX": "PECGEN DMX",
              "SORBUGEN NR": "SORBUGEN NR",
              "SUPRESS DM PEDIATRIC": "SUPRESS DM PEDIATRIC",
              "ZYNCOF": "ZYNCOF"
            },
            "KROGER COMPANY": {
              "CHILDRENS MUCUS RELIEF COUGH": "CHILDRENS MUCUS RELIEF COUGH"
            },
            "LEADER BRAND PRODUCTS": {
              "CHEST CONGESTION RELIEF DM": "CHEST CONGESTION RELIEF DM",
              "INTENSE COUGH RELIEVER EX ST": "INTENSE COUGH RELIEVER EX ST",
              "MUCUS & COUGH RELIEF CHILDRENS": "MUCUS & COUGH RELIEF CHILDRENS",
              "MUCUS RELIEF COUGH CHILDRENS": "MUCUS RELIEF COUGH CHILDRENS",
              "MUCUS RELIEF DM": "MUCUS RELIEF DM",
              "MUCUS RELIEF DM COUGH": "MUCUS RELIEF DM COUGH",
              "MUCUS RELIEF DM MAX": "MUCUS RELIEF DM MAX",
              "MUCUS-DM": "MUCUS-DM",
              "MUCUS-DM MAX": "MUCUS-DM MAX",
              "MUCUS-DM MAXIMUM STRENGTH": "MUCUS-DM MAXIMUM STRENGTH",
              "TABTUSSIN DM": "TABTUSSIN DM",
              "TUSSIN DM": "TUSSIN DM",
              "TUSSIN DM CLEAR": "TUSSIN DM CLEAR",
              "TUSSIN DM COUGH + CHEST": "TUSSIN DM COUGH + CHEST",
              "TUSSIN DM MAX": "TUSSIN DM MAX",
              "TUSSIN DM MAX ADULT": "TUSSIN DM MAX ADULT"
            },
            "LEADING PHARMA": {
              "GUAIFENESIN-CODEINE": "GUAIFENESIN-CODEINE"
            },
            "LLORENS PHARMACEUTICAL": {
              "TUSNEL DIABETIC": "TUSNEL DIABETIC"
            },
            "MAJOR PHARMACEUTICALS": {
              "DEXTROMETHORPHAN-GUAIFENESIN": "DEXTROMETHORPHAN-GUAIFENESIN",
              "MUCUS RELIEF DM": "MUCUS RELIEF DM",
              "MUCUSRELIEF DM COUGH": "MUCUSRELIEF DM COUGH",
              "ROBAFEN AC": "ROBAFEN AC",
              "ROBAFEN DM": "ROBAFEN DM",
              "ROBAFEN DM CGH/CHEST CONGEST": "ROBAFEN DM CGH/CHEST CONGEST",
              "ROBAFEN DM COUGH": "ROBAFEN DM COUGH",
              "ROBAFEN DM COUGH CLEAR": "ROBAFEN DM COUGH CLEAR",
              "ROBAFEN DM PEAK COLD CGH/CONG": "ROBAFEN DM PEAK COLD CGH/CONG"
            },
            "MCKESSON": {
              "HM ADULT TUSSIN COUGH & CHEST": "HM ADULT TUSSIN COUGH & CHEST",
              "HM CHEST CONGESTION RELIEF DM": "HM CHEST CONGESTION RELIEF DM",
              "HM TUSSIN ADULT DM": "HM TUSSIN ADULT DM"
            },
            "MCKESSON SUNMARK": {
              "HM MUCUS RELIEF DM MAX ST": "HM MUCUS RELIEF DM MAX ST",
              "SM CHEST CONGESTION RELIEF DM": "SM CHEST CONGESTION RELIEF DM",
              "SM MUCUS RELIEF COUGH CHILDREN": "SM MUCUS RELIEF COUGH CHILDREN",
              "SM TUSSIN COUGH/CHEST CONGEST": "SM TUSSIN COUGH/CHEST CONGEST",
              "SM TUSSIN DM": "SM TUSSIN DM",
              "SM TUSSIN DM MAX": "SM TUSSIN DM MAX"
            },
            "MCR/AMERICAN PHARMACEUTICAL": {
              "MAXI-TUSS AC": "MAXI-TUSS AC",
              "MAXI-TUSS G": "MAXI-TUSS G",
              "MAXI-TUSS GMX": "MAXI-TUSS GMX"
            },
            "MEDICINE SHOPPE": {
              "CHEST CONGESTION/COUGH RELIEF": "CHEST CONGESTION/COUGH RELIEF",
              "INTENSE COUGH RELIEVER": "INTENSE COUGH RELIEVER",
              "MEDI-TUSSIN DM": "MEDI-TUSSIN DM",
              "MEDI-TUSSIN DM DIABETIC": "MEDI-TUSSIN DM DIABETIC",
              "MEDI-TUSSIN DM DOUBLE STRENGTH": "MEDI-TUSSIN DM DOUBLE STRENGTH"
            },
            "MEDIQUE PRODUCTS": {
              "GUAICON DMS": "GUAICON DMS"
            },
            "MEIJER": {
              "MEIJER COUGH SYRUP DM": "MEIJER COUGH SYRUP DM"
            },
            "METHOD PHARMACEUTICALS": {
              "DEXTROMETHORPHAN-GUAIFENESIN": "DEXTROMETHORPHAN-GUAIFENESIN",
              "GUAIFENESIN-CODEINE": "GUAIFENESIN-CODEINE"
            },
            "MISSION": {
              "FLOWTUSS": "FLOWTUSS"
            },
            "NOREGA LABORATORIES": {
              "NORTUSS-EX": "NORTUSS-EX"
            },
            "NUCARE PHARMACEUTICALS": {
              "GUAIFENESIN-CODEINE": "GUAIFENESIN-CODEINE"
            },
            "OHM LABS": {
              "DM-GUAIFENESIN ER": "DM-GUAIFENESIN ER"
            },
            "P & G HEALTH": {
              "VICKS DAYQUIL MUCUS CONTROL DM": "VICKS DAYQUIL MUCUS CONTROL DM"
            },
            "PACK PHARMACEUTICALS, LLC": {
              "GUAIFENESIN AC": "GUAIFENESIN AC"
            },
            "PAR PHARMACEUTICALS": {
              "CHERATUSSIN AC": "CHERATUSSIN AC",
              "IOPHEN C-NR": "IOPHEN C-NR",
              "IOPHEN DM-NR": "IOPHEN DM-NR",
              "Q-TUSSIN DM": "Q-TUSSIN DM"
            },
            "PDRX PHARMACEUTICAL": {
              "DEXTROMETHORPHAN-GUAIFENESIN": "DEXTROMETHORPHAN-GUAIFENESIN"
            },
            "PERRIGO": {
              "GOODSENSE MUCUS DM": "GOODSENSE MUCUS DM",
              "GOODSENSE TUSSIN DM MAX": "GOODSENSE TUSSIN DM MAX",
              "TUSSIN DM": "TUSSIN DM",
              "TUSSIN DM MAX ADULT": "TUSSIN DM MAX ADULT"
            },
            "PERRIGO PHARMACEUTICALS": {
              "DM-GUAIFENESIN ER": "DM-GUAIFENESIN ER"
            },
            "PHARBEST PHARMACEUTICALS": {
              "PHARBINEX-DM": "PHARBINEX-DM"
            },
            "PHARMA MEDICA": {
              "FENESIN DM IR": "FENESIN DM IR"
            },
            "PHARMACEUTICAL ASSOCIATES": {
              "GUAIFENESIN-CODEINE": "GUAIFENESIN-CODEINE",
              "GUAIFENESIN-DM": "GUAIFENESIN-DM"
            },
            "PREFERRED PHARMACEUTICALS": {
              "CHERATUSSIN AC": "CHERATUSSIN AC",
              "GUAIFENESIN-CODEINE": "GUAIFENESIN-CODEINE",
              "MUCINEX DM": "MUCINEX DM",
              "TUSSIN DM": "TUSSIN DM"
            },
            "PROFICIENT RX": {
              "Q-TUSSIN DM": "Q-TUSSIN DM",
              "VIRTUSSIN A/C": "VIRTUSSIN A/C"
            },
            "PUBLIX SUPER MARKETS INC.": {
              "PX TUSSIN DM": "PX TUSSIN DM"
            },
            "QUALITY CARE": {
              "GUAIFENESIN-CODEINE": "GUAIFENESIN-CODEINE"
            },
            "QUALITY CARE PRODUCTS": {
              "GUAIFENESIN-CODEINE": "GUAIFENESIN-CODEINE",
              "Q-TUSSIN DM": "Q-TUSSIN DM"
            },
            "R A MCNEIL": {
              "CHLO TUSS EX": "CHLO TUSS EX",
              "M-CLEAR WC": "M-CLEAR WC"
            },
            "RECKITT BENCKISER": {
              "DELSYM CGH/CHEST CONG DM CHILD": "DELSYM CGH/CHEST CONG DM CHILD",
              "DELSYM COUGH/CHEST CONGEST DM": "DELSYM COUGH/CHEST CONGEST DM",
              "MUCINEX CHILDRENS FREEFROM": "MUCINEX CHILDRENS FREEFROM",
              "MUCINEX COUGH CHILDRENS": "MUCINEX COUGH CHILDRENS",
              "MUCINEX COUGH FOR KIDS": "MUCINEX COUGH FOR KIDS",
              "MUCINEX DM": "MUCINEX DM",
              "MUCINEX DM MAXIMUM STRENGTH": "MUCINEX DM MAXIMUM STRENGTH",
              "MUCINEX FAST-MAX DM MAX": "MUCINEX FAST-MAX DM MAX"
            },
            "REESE PHARMACEUTICAL": {
              "DOUBLE-TUSSIN DM": "DOUBLE-TUSSIN DM",
              "PEDIATRIC FORMULA COUGH/CONGST": "PEDIATRIC FORMULA COUGH/CONGST",
              "RECOFEN D": "RECOFEN D",
              "REFENESEN DM": "REFENESEN DM"
            },
            "RESPA PHARMACEUTICALS, INC.": {
              "CODAR GF": "CODAR GF"
            },
            "RICHMOND PHARMACEUTICALS": {
              "DEXTROMETHORPHAN-GUAIFENESIN": "DEXTROMETHORPHAN-GUAIFENESIN"
            },
            "RITE AID CORPORATION": {
              "RA MUCUS RELIEF DM": "RA MUCUS RELIEF DM",
              "RA TUSSIN CGH/CHEST CONGEST DM": "RA TUSSIN CGH/CHEST CONGEST DM",
              "RA TUSSIN COUGH": "RA TUSSIN COUGH",
              "RA TUSSIN COUGH DM SUGAR FREE": "RA TUSSIN COUGH DM SUGAR FREE",
              "RA TUSSIN COUGH/CHEST DM MAX": "RA TUSSIN COUGH/CHEST DM MAX",
              "RA TUSSIN DM": "RA TUSSIN DM"
            },
            "RUGBY LABORATORIES": {
              "CHEST CONGESTION RELIEF DM": "CHEST CONGESTION RELIEF DM",
              "COUGH/CHEST CONGESTION DM": "COUGH/CHEST CONGESTION DM",
              "EXTRA ACTION COUGH": "EXTRA ACTION COUGH",
              "MUCUS RELIEF DM": "MUCUS RELIEF DM",
              "MUCUS RELIEF DM MAX": "MUCUS RELIEF DM MAX"
            },
            "SCHERER": {
              "TOLU-SED DM": "TOLU-SED DM"
            },
            "SELECT BRAND": {
              "SB COUGH CONTROL DM": "SB COUGH CONTROL DM",
              "SB COUGH CONTROL DM MAX": "SB COUGH CONTROL DM MAX",
              "SB MUCUS RELIEF DM": "SB MUCUS RELIEF DM",
              "SB TAB TUSSIN DM": "SB TAB TUSSIN DM"
            },
            "SHEFFIELD PHARMACEUTICALS": {
              "CHERACOL PLUS": "CHERACOL PLUS",
              "CHERACOL-D COUGH": "CHERACOL-D COUGH"
            },
            "SILARX": {
              "DIABETIC SILTUSSIN-DM": "DIABETIC SILTUSSIN-DM",
              "DIABETIC SILTUSSIN-DM MAX ST": "DIABETIC SILTUSSIN-DM MAX ST",
              "SILTUSSIN DM DAS": "SILTUSSIN DM DAS",
              "SILTUSSIN-DM ALCOHOL FREE": "SILTUSSIN-DM ALCOHOL FREE"
            },
            "STRIDES USA CONSUMER": {
              "PEDIACARE COUGH/CONGESTION": "PEDIACARE COUGH/CONGESTION"
            },
            "TARGET": {
              "TGT COUGH FORMULA DM": "TGT COUGH FORMULA DM",
              "TGT COUGH FORMULA DM MAX ADULT": "TGT COUGH FORMULA DM MAX ADULT",
              "TGT MUCUS RELIEF COUGH CHILD": "TGT MUCUS RELIEF COUGH CHILD",
              "TGT MUCUS/COUGH RELIEF": "TGT MUCUS/COUGH RELIEF",
              "TGT TUSSIN DM COUGH": "TGT TUSSIN DM COUGH"
            },
            "TERAL": {
              "SORBUTUSS NR": "SORBUTUSS NR"
            },
            "THE GENERIC PHARMACEUTICAL COM": {
              "G TUSSIN AC": "G TUSSIN AC"
            },
            "TIME-CAP LABS": {
              "MUCOSA DM": "MUCOSA DM"
            },
            "TOPCO": {
              "CHEST CONGESTION/COUGH RELIEF": "CHEST CONGESTION/COUGH RELIEF",
              "CHILDRENS MUCUS RELIEF COUGH": "CHILDRENS MUCUS RELIEF COUGH",
              "MUCUS DM": "MUCUS DM",
              "MUCUS RELIEF DM MAX": "MUCUS RELIEF DM MAX",
              "TUSSIN DM": "TUSSIN DM",
              "TUSSIN DM MAX": "TUSSIN DM MAX"
            },
            "TRIMARC LABORATORIES": {
              "GUAIFENESIN DM": "GUAIFENESIN DM"
            },
            "UNIT DOSE SERVICES": {
              "GUAIFENESIN-CODEINE": "GUAIFENESIN-CODEINE"
            },
            "VIRTUS PHARMACEUTICALS": {
              "GUAIFENESIN-CODEINE": "GUAIFENESIN-CODEINE",
              "VIRTUSSIN A/C": "VIRTUSSIN A/C",
              "VIRTUSSIN AC W/ALC": "VIRTUSSIN AC W/ALC"
            },
            "VIRTUS PHARMACEUTICALS OPCO": {
              "VIRTUSSIN A/C": "VIRTUSSIN A/C"
            },
            "WAL-MART": {
              "EQ COUGH CHILDRENS": "EQ COUGH CHILDRENS",
              "EQ MUCUS DM": "EQ MUCUS DM",
              "EQ MUCUS RELIEF DM": "EQ MUCUS RELIEF DM",
              "EQ TUSSIN DM COUGH/CHEST": "EQ TUSSIN DM COUGH/CHEST",
              "EQ TUSSIN DM MAX": "EQ TUSSIN DM MAX",
              "EQ TUSSIN DM MAX ADULT": "EQ TUSSIN DM MAX ADULT",
              "EQ TUSSIN DM MAX DAYTIME": "EQ TUSSIN DM MAX DAYTIME"
            },
            "WALGREENS": {
              "CHILDRENS COUGH": "CHILDRENS COUGH",
              "COUGH & CHEST CONGESTION DM": "COUGH & CHEST CONGESTION DM",
              "MUCUS RELIEF DM": "MUCUS RELIEF DM",
              "MUCUS RELIEF DM COUGH": "MUCUS RELIEF DM COUGH",
              "MUCUS RELIEF DM MAX": "MUCUS RELIEF DM MAX",
              "WAL-TUSSIN COUGH/CHEST DM": "WAL-TUSSIN COUGH/CHEST DM",
              "WAL-TUSSIN COUGH/CHEST DM MAX": "WAL-TUSSIN COUGH/CHEST DM MAX",
              "WAL-TUSSIN DM": "WAL-TUSSIN DM",
              "WAL-TUSSIN DM CGH/CHEST CONG": "WAL-TUSSIN DM CGH/CHEST CONG"
            },
            "WESTMINSTER PHARMACEUTICALS": {
              "GUAIFENESIN-CODEINE": "GUAIFENESIN-CODEINE"
            }
          },
          "Antitussive-Expectorant - Antihist-Analgesic": {
            "BAYER CONSUMER": {
              "CORICIDIN HBP DAY/NIGHT COLD": "CORICIDIN HBP DAY/NIGHT COLD"
            }
          },
          "Antitussive-Expectorant - Decongest-Analgesic": {
            "AMERISOURCE BERGEN DRUGS": {
              "GNP COLD RELIEF COLD & FLU": "GNP COLD RELIEF COLD & FLU",
              "GNP COLD RELIEF DAYTIME": "GNP COLD RELIEF DAYTIME",
              "GNP COLD RELIEF MULTI-SYMPTOM": "GNP COLD RELIEF MULTI-SYMPTOM",
              "GNP COLD/FLU SEVERE": "GNP COLD/FLU SEVERE",
              "GNP MUCUS RELIEF COLD FLU": "GNP MUCUS RELIEF COLD FLU",
              "GNP MUCUS RELIEF CONGEST/COLD": "GNP MUCUS RELIEF CONGEST/COLD",
              "GNP MUCUS RELIEF MAX ST": "GNP MUCUS RELIEF MAX ST",
              "GNP MULTI-SYMPTOM COLD DAYTIME": "GNP MULTI-SYMPTOM COLD DAYTIME",
              "GNP SEVERE DAY TIME COLD/FLU": "GNP SEVERE DAY TIME COLD/FLU"
            },
            "BERGEN BRUNSWIG": {
              "GNP COLD HEAD CONGESTION": "GNP COLD HEAD CONGESTION",
              "GNP COLD SEVERE CONGESTION DAY": "GNP COLD SEVERE CONGESTION DAY"
            },
            "CHAIN DRUG CONSORTIUM": {
              "COLD & FLU SEVERE": "COLD & FLU SEVERE",
              "COLD HEAD CONGESTION SEVERE": "COLD HEAD CONGESTION SEVERE",
              "COLD/COUGH/SORE THROAT CHILD": "COLD/COUGH/SORE THROAT CHILD",
              "DAYTIME SEVERE COLD & FLU": "DAYTIME SEVERE COLD & FLU",
              "MUCUS RELIEF COLD FLU THROAT": "MUCUS RELIEF COLD FLU THROAT",
              "MUCUS RELIEF SEVERE COLD": "MUCUS RELIEF SEVERE COLD",
              "NON-ASPIRIN SEVERE CONGESTION": "NON-ASPIRIN SEVERE CONGESTION",
              "PRESSURE & PAIN & COLD": "PRESSURE & PAIN & COLD"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC COLD HEAD CONGESTION DAY": "QC COLD HEAD CONGESTION DAY",
              "QC COLD MULTI-SYMPTOM DAYTIME": "QC COLD MULTI-SYMPTOM DAYTIME"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "COUGH/COLD/SORE THROAT CHILD": "COUGH/COLD/SORE THROAT CHILD",
              "CVS COLD/FLU/SORE THROAT ADULT": "CVS COLD/FLU/SORE THROAT ADULT",
              "CVS MULTI-SYMPTOMS COLD/FEVER": "CVS MULTI-SYMPTOMS COLD/FEVER",
              "CVS SINUS PE": "CVS SINUS PE"
            },
            "EQUALINE": {
              "EQL COLD HEAD CONGESTION DAY": "EQL COLD HEAD CONGESTION DAY",
              "EQL COLD MULTI-SYMPTOM SEVERE": "EQL COLD MULTI-SYMPTOM SEVERE",
              "EQL DAYTIME SEVERE COLD/FLU": "EQL DAYTIME SEVERE COLD/FLU",
              "EQL MUCUS RELIEF MAX STRENGTH": "EQL MUCUS RELIEF MAX STRENGTH",
              "EQL PAIN RELIEF/COLD": "EQL PAIN RELIEF/COLD",
              "EQL PRESSURE/PAIN PE PLUS COLD": "EQL PRESSURE/PAIN PE PLUS COLD"
            },
            "GEISS DESTIN & DUNN": {
              "GOODSENSE COLD & FLU": "GOODSENSE COLD & FLU",
              "GOODSENSE PRESSURE/PAIN/COLD": "GOODSENSE PRESSURE/PAIN/COLD"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "ROBITUSSIN SEVERE MULTI-SYMP": "ROBITUSSIN SEVERE MULTI-SYMP",
              "THERAFLU EXPRESSMAX SEV CLD/FL": "THERAFLU EXPRESSMAX SEV CLD/FL"
            },
            "LEADER BRAND PRODUCTS": {
              "COLD & FLU SEVERE": "COLD & FLU SEVERE",
              "DAYTIME SEVERE COLD & FLU": "DAYTIME SEVERE COLD & FLU",
              "MUCUS RELIEF COLD FLU THROAT": "MUCUS RELIEF COLD FLU THROAT",
              "MUCUS RELIEF SEVERE COLD": "MUCUS RELIEF SEVERE COLD",
              "PAIN RELIEF COLD CONGESTION": "PAIN RELIEF COLD CONGESTION",
              "SEVERE COLD & FLU": "SEVERE COLD & FLU",
              "TUSSIN CF SEVERE MULTI-SYMPTOM": "TUSSIN CF SEVERE MULTI-SYMPTOM"
            },
            "MCKESSON": {
              "HM COLD FLU & SORE THROAT": "HM COLD FLU & SORE THROAT",
              "HM DAYTIME COLD & FLU": "HM DAYTIME COLD & FLU",
              "HM MUCUS RELIEF FM COLD/FLU": "HM MUCUS RELIEF FM COLD/FLU",
              "HM MUCUS RELIEF FM SEVERE": "HM MUCUS RELIEF FM SEVERE",
              "HM SEVERE COLD/FLU": "HM SEVERE COLD/FLU"
            },
            "MCKESSON SUNMARK": {
              "SM COLD & FLU SEVERE": "SM COLD & FLU SEVERE",
              "SM COLD HEAD CONGESTION": "SM COLD HEAD CONGESTION"
            },
            "MCNEIL CONSUMER": {
              "SUDAFED PE HEAD CONGESTION": "SUDAFED PE HEAD CONGESTION",
              "TYLENOL COLD MULTI-SYMPTOM DAY": "TYLENOL COLD MULTI-SYMPTOM DAY",
              "TYLENOL COLD/FLU SEVERE": "TYLENOL COLD/FLU SEVERE",
              "TYLENOL WARMING COUGH/CONGEST": "TYLENOL WARMING COUGH/CONGEST"
            },
            "MEDIQUE PRODUCTS": {
              "DECOREL FORTE PLUS COLD/COUGH": "DECOREL FORTE PLUS COLD/COUGH"
            },
            "P & G HEALTH": {
              "DAYQUIL SEVERE + VAPOCOOL": "DAYQUIL SEVERE + VAPOCOOL",
              "VICKS DAYQUIL SEVERE COLD/FLU": "VICKS DAYQUIL SEVERE COLD/FLU"
            },
            "PERRIGO": {
              "GOODSENSE DAY TIME COLD & FLU": "GOODSENSE DAY TIME COLD & FLU"
            },
            "PERRIGO PHARMACEUTICALS": {
              "COLD HEAD CONGESTION SEVERE": "COLD HEAD CONGESTION SEVERE"
            },
            "POLY PHARMACEUTICALS": {
              "DURAFLU": "DURAFLU"
            },
            "PUBLIX SUPER MARKETS INC.": {
              "PX SEVERE COLD": "PX SEVERE COLD"
            },
            "RECKITT BENCKISER": {
              "DELSYM COUGH/COLD DAYTIME": "DELSYM COUGH/COLD DAYTIME",
              "MUCINEX CHILD COLD/SORE THROAT": "MUCINEX CHILD COLD/SORE THROAT",
              "MUCINEX CHILD MULTI-SYMPTOM": "MUCINEX CHILD MULTI-SYMPTOM",
              "MUCINEX CHILDRENS FREEFROM": "MUCINEX CHILDRENS FREEFROM",
              "MUCINEX COLD CGH THROAT CHILD": "MUCINEX COLD CGH THROAT CHILD",
              "MUCINEX FAST-MAX": "MUCINEX FAST-MAX",
              "MUCINEX FAST-MAX COLD FLU": "MUCINEX FAST-MAX COLD FLU",
              "MUCINEX FAST-MAX COLD/FLU": "MUCINEX FAST-MAX COLD/FLU",
              "MUCINEX FAST-MAX SEVERE COLD": "MUCINEX FAST-MAX SEVERE COLD",
              "MUCINEX JUNIOR COLD/FLU": "MUCINEX JUNIOR COLD/FLU",
              "MUCINEX SINUS-MAX": "MUCINEX SINUS-MAX"
            },
            "REESE PHARMACEUTICAL": {
              "THERACOF PLUS COUGH/COLD REL": "THERACOF PLUS COUGH/COLD REL"
            },
            "RITE AID CORPORATION": {
              "RA COLD MULTI-SYMPTOM DAYTIME": "RA COLD MULTI-SYMPTOM DAYTIME",
              "RA COLD/COUGH SINUS RELIEF PE": "RA COLD/COUGH SINUS RELIEF PE",
              "RA COLD/FLU/SORE THROAT MAX": "RA COLD/FLU/SORE THROAT MAX",
              "RA HEAD CONGEST COLD DAYTIME": "RA HEAD CONGEST COLD DAYTIME",
              "RA MUCUS RELIEF PLUS": "RA MUCUS RELIEF PLUS",
              "RA SEVERE CONGESTION/COLD MAX": "RA SEVERE CONGESTION/COLD MAX"
            },
            "SELECT BRAND": {
              "SB COLD & FLU SEVERE": "SB COLD & FLU SEVERE",
              "SB COLD HEAD CONGESTION SEVERE": "SB COLD HEAD CONGESTION SEVERE",
              "SB COLD MULTI-SYMPTOM SEVERE": "SB COLD MULTI-SYMPTOM SEVERE"
            },
            "SYENZ LABORATORY": {
              "HERBIOMED SEVERE COLD & FLU": "HERBIOMED SEVERE COLD & FLU"
            },
            "TARGET": {
              "TGT SEVERE COLD RELIEF": "TGT SEVERE COLD RELIEF"
            },
            "TOPCO": {
              "COLD HEAD CONGESTION SEVERE": "COLD HEAD CONGESTION SEVERE",
              "COLD MULTI-SYMPTOM SEVERE DAY": "COLD MULTI-SYMPTOM SEVERE DAY"
            },
            "WAL-MART": {
              "EQ COLD FLU & SORE THROAT": "EQ COLD FLU & SORE THROAT",
              "EQ COLD MULTI-SYMPTOM SEVERE": "EQ COLD MULTI-SYMPTOM SEVERE",
              "EQ MUCUS RELIEF COLD FLU": "EQ MUCUS RELIEF COLD FLU",
              "EQ MULTI-SYMP COLD/FEVER CHILD": "EQ MULTI-SYMP COLD/FEVER CHILD"
            },
            "WALGREENS": {
              "COLD & FLU SEVERE DAYTIME": "COLD & FLU SEVERE DAYTIME",
              "COUGH/COLD/SORE THROAT CHILD": "COUGH/COLD/SORE THROAT CHILD",
              "MUCUS RELIEF COLD FLU THROAT": "MUCUS RELIEF COLD FLU THROAT",
              "MUCUS RELIEF PLUS": "MUCUS RELIEF PLUS",
              "MUCUS RELIEF SEVERE COLD DAY": "MUCUS RELIEF SEVERE COLD DAY",
              "MUCUS RELIEF SEVERE CONG/COLD": "MUCUS RELIEF SEVERE CONG/COLD",
              "WAL-PHED PE COLD & COUGH": "WAL-PHED PE COLD & COUGH",
              "WAL-PHED PE PRESSURE+PAIN+COLD": "WAL-PHED PE PRESSURE+PAIN+COLD",
              "WAL-TUSSIN CF MAX": "WAL-TUSSIN CF MAX"
            }
          },
          "Antitussive-Expectorant - Decongest-Antihist": {
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "ROBITUSSIN DAY/NIGHT VALUE PAK": "ROBITUSSIN DAY/NIGHT VALUE PAK"
            }
          },
          "Antitussive-Expectorant - Decongest-Antihist-Analg": {
            "COSTCO WHOLESALE": {
              "KLS COLD & FLU MS DAY/NIGHT": "KLS COLD & FLU MS DAY/NIGHT"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "ROBITUSSIN PK COLD DAY/NGHT DM": "ROBITUSSIN PK COLD DAY/NGHT DM",
              "ROBITUSSIN PK COLD DAY/NGHT MS": "ROBITUSSIN PK COLD DAY/NGHT MS",
              "ROBITUSSIN SEVERE DAY/NIGHT": "ROBITUSSIN SEVERE DAY/NIGHT"
            },
            "MCNEIL CONSUMER": {
              "TYLENOL COLD & FLU DAY/NIGHT": "TYLENOL COLD & FLU DAY/NIGHT",
              "TYLENOL COLD/FLU SEVERE": "TYLENOL COLD/FLU SEVERE"
            },
            "P & G HEALTH": {
              "VICKS DAYQUIL/NYQUIL SEVERE": "VICKS DAYQUIL/NYQUIL SEVERE"
            },
            "RECKITT BENCKISER": {
              "DELSYM CHILDRENS DAY NIGHT": "DELSYM CHILDRENS DAY NIGHT",
              "DELSYM DAY NIGHT": "DELSYM DAY NIGHT",
              "MUCINEX CHILD MS DAY-NIGHT CLD": "MUCINEX CHILD MS DAY-NIGHT CLD",
              "MUCINEX CLEAR & COOL DAY/NIGHT": "MUCINEX CLEAR & COOL DAY/NIGHT",
              "MUCINEX FAST-MAX": "MUCINEX FAST-MAX",
              "MUCINEX FAST-MAX CLD/FLU DY/NT": "MUCINEX FAST-MAX CLD/FLU DY/NT",
              "MUCINEX FAST-MAX DAY/NGHT COOL": "MUCINEX FAST-MAX DAY/NGHT COOL",
              "MUCINEX FAST-MAX DAY/NIGHT": "MUCINEX FAST-MAX DAY/NIGHT",
              "MUCINEX FAST-MAX DAY/NIGHT M/S": "MUCINEX FAST-MAX DAY/NIGHT M/S",
              "MUCINEX FAST-MAX DAY/NIGHT TAB": "MUCINEX FAST-MAX DAY/NIGHT TAB",
              "MUCINEX FAST-MAX DAY/NIGHTTIME": "MUCINEX FAST-MAX DAY/NIGHTTIME",
              "MUCINEX FREEFROM DAY-NIGHT": "MUCINEX FREEFROM DAY-NIGHT",
              "MUCINEX SINUS-MAX DAY/NIGHT": "MUCINEX SINUS-MAX DAY/NIGHT",
              "MUCINEX SINUS-MAX/NIGHTSHIFT": "MUCINEX SINUS-MAX/NIGHTSHIFT"
            },
            "RITE AID CORPORATION": {
              "RA DAY/NIGHT": "RA DAY/NIGHT",
              "RA SEVERE COLD/NIGHT COLD&FLU": "RA SEVERE COLD/NIGHT COLD&FLU",
              "RA SUPHEDRINE DAY/NIGHT COMBO": "RA SUPHEDRINE DAY/NIGHT COMBO"
            },
            "WALGREENS": {
              "MULTI-SYMPTOM COLD DAY/NIGHT": "MULTI-SYMPTOM COLD DAY/NIGHT",
              "SEVERE COLD & FLU DAY/NIGHT": "SEVERE COLD & FLU DAY/NIGHT",
              "WAL-PHED PE DAYTIME/NIGHTTIME": "WAL-PHED PE DAYTIME/NIGHTTIME"
            }
          },
          "Antitussive-Expectorants-Antihistamine": {
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS COUGH/CHEST DM CHILDRENS": "CVS COUGH/CHEST DM CHILDRENS",
              "CVS TUSSIN DM": "CVS TUSSIN DM"
            }
          },
          "Antitussive-Expectorants-Decongestant": {
            "ACELLA PHARMACEUTICALS": {
              "ENTRE-COUGH": "ENTRE-COUGH"
            },
            "ACTIPHARMA": {
              "ACTIDOM DMX": "ACTIDOM DMX",
              "ACTINEL": "ACTINEL",
              "ACTINEL DM": "ACTINEL DM",
              "ACTINEL PEDIATRIC": "ACTINEL PEDIATRIC"
            },
            "ADVANCED GENERIC": {
              "BIO T PRES": "BIO T PRES",
              "BIO T PRES PEDIATRIC": "BIO T PRES PEDIATRIC",
              "BIOBRON SF": "BIOBRON SF",
              "BIODESP DM": "BIODESP DM",
              "BIOGTUSS": "BIOGTUSS",
              "BIONEL": "BIONEL",
              "BIONEL PEDIATRIC": "BIONEL PEDIATRIC"
            },
            "AG MARIN PHARMACEUTICALS": {
              "NEOTUSS-D": "NEOTUSS-D"
            },
            "ALLEGIS PHARMACEUTICALS": {
              "DURAVENT DM": "DURAVENT DM"
            },
            "AMBI PHARMACEUTICALS": {
              "AMBI 10PEH/400GFN/20DM": "AMBI 10PEH/400GFN/20DM",
              "AMBI 12.5CPD/100GFN/30PSE": "AMBI 12.5CPD/100GFN/30PSE",
              "AMBI 60PSE/400GFN/20DM": "AMBI 60PSE/400GFN/20DM"
            },
            "AMERICAN SALES COMPANY": {
              "TUSSIN CF COUGH & COLD": "TUSSIN CF COUGH & COLD"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP MUCUS RELIEF CHILDRENS": "GNP MUCUS RELIEF CHILDRENS",
              "GNP MUCUS RELIEF CONGEST/COUGH": "GNP MUCUS RELIEF CONGEST/COUGH",
              "GNP TUSSIN CF MAX": "GNP TUSSIN CF MAX"
            },
            "BERGEN BRUNSWIG": {
              "GNP TUSSIN CF COUGH & COLD": "GNP TUSSIN CF COUGH & COLD"
            },
            "BUREL PHARMACEUTICALS": {
              "RELHIST DMX": "RELHIST DMX"
            },
            "CAPITAL PHARMACEUTICAL": {
              "AQUANAZ": "AQUANAZ",
              "CAPMIST DM": "CAPMIST DM"
            },
            "CHAIN DRUG CONSORTIUM": {
              "MULTI-SYMPTOM COLD CHILDRENS": "MULTI-SYMPTOM COLD CHILDRENS",
              "SEVERE CONGESTION/COUGH MAX": "SEVERE CONGESTION/COUGH MAX",
              "TUSSIN CF": "TUSSIN CF",
              "TUSSIN COLD/CONGESTION": "TUSSIN COLD/CONGESTION"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC TUSSIN CF": "QC TUSSIN CF"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS MULTI-SYMPTOMS COLD CHILD": "CVS MULTI-SYMPTOMS COLD CHILD",
              "CVS SEVERE COUGH/CONGEST": "CVS SEVERE COUGH/CONGEST",
              "CVS TUSSIN CF": "CVS TUSSIN CF",
              "CVS TUSSIN COUGH/COLD CF": "CVS TUSSIN COUGH/COLD CF"
            },
            "DELIZ PHARMACEUTICAL CORP": {
              "TRISPEC PSE": "TRISPEC PSE",
              "TRISPEC PSE PEDIATRIC": "TRISPEC PSE PEDIATRIC"
            },
            "DOMEL LABORATORIES": {
              "DOMETUSS-DMX": "DOMETUSS-DMX"
            },
            "EQUALINE": {
              "EQL MUCUS RELIEF CHILDRENS": "EQL MUCUS RELIEF CHILDRENS",
              "EQL TUSSIN CF CGH/COLD/CONGEST": "EQL TUSSIN CF CGH/COLD/CONGEST",
              "EQL TUSSIN COUGH & COLD CF": "EQL TUSSIN COUGH & COLD CF"
            },
            "GEISS DESTIN & DUNN": {
              "GOODSENSE MUCUS RELIEF CHILD": "GOODSENSE MUCUS RELIEF CHILD",
              "GOODSENSE MUCUS/CONGEST/COUGH": "GOODSENSE MUCUS/CONGEST/COUGH",
              "GOODSENSE TUSSIN CF": "GOODSENSE TUSSIN CF"
            },
            "GIL PHARMACEUTICAL CORP.": {
              "BIOTUSS": "BIOTUSS",
              "BIOTUSS PEDIATRIC": "BIOTUSS PEDIATRIC",
              "EXACTUSS": "EXACTUSS",
              "EXACTUSS TR": "EXACTUSS TR",
              "GILTUSS": "GILTUSS",
              "GILTUSS COUGH & COLD": "GILTUSS COUGH & COLD",
              "GILTUSS COUGH & COLD CHILDRENS": "GILTUSS COUGH & COLD CHILDRENS",
              "GILTUSS PEDIATRIC": "GILTUSS PEDIATRIC",
              "GILTUSS TR": "GILTUSS TR"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "ROBITUSSIN CHILD COUGH/COLD CF": "ROBITUSSIN CHILD COUGH/COLD CF",
              "ROBITUSSIN MULTI-SYMPTOM MAX": "ROBITUSSIN MULTI-SYMPTOM MAX",
              "ROBITUSSIN PEAK COLD MULTI-SYM": "ROBITUSSIN PEAK COLD MULTI-SYM",
              "ROBITUSSIN TO GO COUGH/COLD CF": "ROBITUSSIN TO GO COUGH/COLD CF"
            },
            "GLENDALE INC": {
              "CODITUSSIN DAC": "CODITUSSIN DAC"
            },
            "GM PHARMACEUTICALS": {
              "VANACOF DM": "VANACOF DM",
              "VANACOF DMX": "VANACOF DMX",
              "VANATAB DM": "VANATAB DM"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "PSEUDOEPHEDRINE-DM-GG": "PSEUDOEPHEDRINE-DM-GG",
              "TUSSIN CF": "TUSSIN CF"
            },
            "INTERNATIONAL ETHICAL LABS": {
              "DESPEC DM": "DESPEC DM",
              "DESPEC DM-G": "DESPEC DM-G",
              "DESPEC EDA": "DESPEC EDA",
              "DESPEC-DM": "DESPEC-DM"
            },
            "KRAMER-NOVIS": {
              "DESGEN DM": "DESGEN DM",
              "DESGEN PEDIATRIC": "DESGEN PEDIATRIC",
              "G-SUPRESS DX PEDIATRIC": "G-SUPRESS DX PEDIATRIC",
              "G-TRON PED": "G-TRON PED",
              "G-TRON PEDIATRIC DROPS": "G-TRON PEDIATRIC DROPS",
              "G-TUSICOF": "G-TUSICOF",
              "PECGEN PSE": "PECGEN PSE",
              "PRES GEN": "PRES GEN",
              "PRES GEN PEDIATRIC": "PRES GEN PEDIATRIC",
              "SUPRESS-DX PEDIATRIC": "SUPRESS-DX PEDIATRIC",
              "TUSICOF": "TUSICOF",
              "TUSSI-PRES": "TUSSI-PRES",
              "TUSSI-PRES PEDIATRIC": "TUSSI-PRES PEDIATRIC",
              "TUSSLIN": "TUSSLIN",
              "TUSSLIN PEDIATRIC": "TUSSLIN PEDIATRIC"
            },
            "LARK PHARMACEUTICAL": {
              "TEO-TUS": "TEO-TUS"
            },
            "LEADER BRAND PRODUCTS": {
              "MUCUS RELIEF CHILDRENS": "MUCUS RELIEF CHILDRENS",
              "TUSSIN CF": "TUSSIN CF",
              "TUSSIN CF MAX MULTI-SYMPTOM": "TUSSIN CF MAX MULTI-SYMPTOM",
              "TUSSIN CF MULTI-SYMPTOM COLD": "TUSSIN CF MULTI-SYMPTOM COLD",
              "TUSSIN MULTI-SYMPTOM COLD CF": "TUSSIN MULTI-SYMPTOM COLD CF"
            },
            "LLORENS PHARMACEUTICAL": {
              "TUSNEL": "TUSNEL",
              "TUSNEL C": "TUSNEL C",
              "TUSNEL DM": "TUSNEL DM",
              "TUSNEL DM PEDIATRIC": "TUSNEL DM PEDIATRIC",
              "TUSNEL PEDIATRIC": "TUSNEL PEDIATRIC",
              "TUSNEL-DM PEDIATRIC": "TUSNEL-DM PEDIATRIC"
            },
            "MAJOR PHARMACEUTICALS": {
              "ROBAFEN CF COUGH/COLD": "ROBAFEN CF COUGH/COLD",
              "ROBAFEN CF MULTI-SYMPTOM COLD": "ROBAFEN CF MULTI-SYMPTOM COLD"
            },
            "MCKESSON": {
              "HM MUCUS RELIEF MULTI-SYMPTOM": "HM MUCUS RELIEF MULTI-SYMPTOM",
              "HM SEVERE CONGESTION & COUGH": "HM SEVERE CONGESTION & COUGH",
              "HM TUSSIN ADULT MULTI-SYMPTOM": "HM TUSSIN ADULT MULTI-SYMPTOM"
            },
            "MCKESSON SUNMARK": {
              "SM MUCUS RELIEF COLD CHILDRENS": "SM MUCUS RELIEF COLD CHILDRENS",
              "SM SEVERE CONGESTION & COUGH": "SM SEVERE CONGESTION & COUGH",
              "SM TUSSIN CF": "SM TUSSIN CF"
            },
            "MEDICINE SHOPPE": {
              "MEDI-TUSSIN COUGH/COLD": "MEDI-TUSSIN COUGH/COLD",
              "TUSSIN CF COUGH & COLD": "TUSSIN CF COUGH & COLD"
            },
            "MISSION": {
              "HYCOFENIX": "HYCOFENIX"
            },
            "NIVAGEN PHARMACEUTICALS": {
              "NIVANEX DMX": "NIVANEX DMX"
            },
            "NOREGA LABORATORIES": {
              "NORTUSS-DE": "NORTUSS-DE"
            },
            "PACK PHARMACEUTICALS, LLC": {
              "GUAIFENESIN DAC": "GUAIFENESIN DAC"
            },
            "PAR PHARMACEUTICALS": {
              "CHERATUSSIN DAC": "CHERATUSSIN DAC"
            },
            "PERRIGO PHARMACEUTICALS": {
              "TUSSIN CF COUGH & COLD": "TUSSIN CF COUGH & COLD"
            },
            "POLY PHARMACEUTICALS": {
              "DECONEX DMX": "DECONEX DMX",
              "POLY-VENT DM": "POLY-VENT DM"
            },
            "PORTAL PHARMACEUTICALS": {
              "BRONTUSS DX": "BRONTUSS DX",
              "BRONTUSS SF NR": "BRONTUSS SF NR",
              "CHLOPHED": "CHLOPHED"
            },
            "PUBLIX SUPER MARKETS INC.": {
              "PX TUSSIN CF": "PX TUSSIN CF"
            },
            "RECKITT BENCKISER": {
              "MUCINEX CHILDRENS FREEFROM": "MUCINEX CHILDRENS FREEFROM",
              "MUCINEX COLD CHILDRENS": "MUCINEX COLD CHILDRENS",
              "MUCINEX CONGEST & COUGH CHILD": "MUCINEX CONGEST & COUGH CHILD",
              "MUCINEX COUGH & CONGEST CHILD": "MUCINEX COUGH & CONGEST CHILD",
              "MUCINEX FAST-MAX CONGEST COUGH": "MUCINEX FAST-MAX CONGEST COUGH",
              "MUCINEX JUNIOR COUGH/CONGEST": "MUCINEX JUNIOR COUGH/CONGEST"
            },
            "RESPA PHARMACEUTICALS, INC.": {
              "TRICODE GF": "TRICODE GF"
            },
            "RITE AID CORPORATION": {
              "RA TUSSIN CF": "RA TUSSIN CF",
              "RA TUSSIN CF MAX": "RA TUSSIN CF MAX",
              "RA TUSSIN CGH & COLD MUCUS CF": "RA TUSSIN CGH & COLD MUCUS CF"
            },
            "SALERNO PHARMACEUTICALS": {
              "TG 10PEH/380GFN/15DM": "TG 10PEH/380GFN/15DM",
              "TGQ 30PSE/150GFN/15DM": "TGQ 30PSE/150GFN/15DM"
            },
            "SALLUS LABORATORIES": {
              "LORTUSS EX": "LORTUSS EX"
            },
            "SELECT BRAND": {
              "SB COUGH CONTROL CF": "SB COUGH CONTROL CF"
            },
            "SEYER PHARMATEC": {
              "BRONCOTRON PED": "BRONCOTRON PED",
              "BRONCOTRON PED DROPS": "BRONCOTRON PED DROPS"
            },
            "TARGET": {
              "TGT COUGH & COLD": "TGT COUGH & COLD"
            },
            "THE GENERIC PHARMACEUTICAL COM": {
              "GCON DMX": "GCON DMX"
            },
            "TOPCO": {
              "MUCUS RELIEF MULTI SYMPTOM": "MUCUS RELIEF MULTI SYMPTOM",
              "TUSSIN CF": "TUSSIN CF",
              "TUSSIN CF MAX MULTI-SYMPTOM": "TUSSIN CF MAX MULTI-SYMPTOM"
            },
            "VIRTUS PHARMACEUTICALS": {
              "VIRTUSSIN DAC": "VIRTUSSIN DAC"
            },
            "VIRTUS PHARMACEUTICALS OPCO": {
              "VIRTUSSIN DAC": "VIRTUSSIN DAC"
            },
            "WAL-MART": {
              "EQ MUCUS RELIEF CONGEST/COUGH": "EQ MUCUS RELIEF CONGEST/COUGH",
              "EQ MULTI-SYMPTOM COLD CHILDREN": "EQ MULTI-SYMPTOM COLD CHILDREN",
              "EQ TUSSIN CF COUGH & COLD": "EQ TUSSIN CF COUGH & COLD",
              "EQ TUSSIN CF MULTI-SYMPTOM": "EQ TUSSIN CF MULTI-SYMPTOM"
            },
            "WALGREENS": {
              "MUCUS CONGEST & COUGH CHILD": "MUCUS CONGEST & COUGH CHILD",
              "MUCUS RELIEF SEVERE CONGST/CGH": "MUCUS RELIEF SEVERE CONGST/CGH",
              "MULTI-SYMPTOM COLD CHILDRENS": "MULTI-SYMPTOM COLD CHILDRENS",
              "MULTI-SYMPTOM COLD PLUS CHILD": "MULTI-SYMPTOM COLD PLUS CHILD",
              "WAL-TUSSIN CF": "WAL-TUSSIN CF",
              "WAL-TUSSIN CF MAX": "WAL-TUSSIN CF MAX",
              "WAL-TUSSIN COUGH/COLD CF": "WAL-TUSSIN COUGH/COLD CF"
            },
            "WESTMINSTER PHARMACEUTICALS": {
              "PHENYLEPHRINE-DM-GG": "PHENYLEPHRINE-DM-GG"
            }
          },
          "Decongestant & Antihistamine": {
            "A-S MEDICATION SOLUTIONS": {
              "ALLEGRA-D ALLERGY & CONGESTION": "ALLEGRA-D ALLERGY & CONGESTION",
              "APRODINE": "APRODINE",
              "CETIRIZINE-PSEUDOEPHEDRINE ER": "CETIRIZINE-PSEUDOEPHEDRINE ER",
              "ED A-HIST": "ED A-HIST",
              "PROMETHAZINE-PHENYLEPHRINE": "PROMETHAZINE-PHENYLEPHRINE",
              "RYMED": "RYMED",
              "TRIPROLIDINE-PSE": "TRIPROLIDINE-PSE"
            },
            "ACELLA PHARMACEUTICALS": {
              "ENTRE-B": "ENTRE-B",
              "ENTRE-HIST PSE": "ENTRE-HIST PSE"
            },
            "ACTAVIS PHARMA": {
              "ALLERGY RELIEF-D": "ALLERGY RELIEF-D"
            },
            "ACTIPHARMA": {
              "ACTICON": "ACTICON"
            },
            "ADVANTICE HEALTH": {
              "PEDIACARE CHILD ALLERGY/COLD": "PEDIACARE CHILD ALLERGY/COLD",
              "PEDIACARE CHILDRENS NIGHTREST": "PEDIACARE CHILDRENS NIGHTREST"
            },
            "AIDAREX PHARMACEUTICALS": {
              "ALLEGRA-D ALLERGY & CONGESTION": "ALLEGRA-D ALLERGY & CONGESTION",
              "CETIRIZINE-PSEUDOEPHEDRINE ER": "CETIRIZINE-PSEUDOEPHEDRINE ER"
            },
            "ALLEGIS PHARMACEUTICALS": {
              "BROHIST D": "BROHIST D",
              "HISTEX-PE": "HISTEX-PE",
              "RU-HIST D": "RU-HIST D"
            },
            "ALTURA PHARMACEUTICALS": {
              "PROMETHAZINE VC": "PROMETHAZINE VC"
            },
            "AMBI PHARMACEUTICALS": {
              "AMBI 10PEH/4CPM": "AMBI 10PEH/4CPM",
              "AMBI 60PSE/4CPM": "AMBI 60PSE/4CPM"
            },
            "AMERICAN SALES COMPANY": {
              "ALL DAY ALLERGY-D": "ALL DAY ALLERGY-D",
              "COLD & ALLERGY": "COLD & ALLERGY",
              "COLD & ALLERGY CHILDRENS": "COLD & ALLERGY CHILDRENS",
              "SINUS & ALLERGY PE MAX ST": "SINUS & ALLERGY PE MAX ST",
              "TRIACTING NIGHT TIME COLD/CGH": "TRIACTING NIGHT TIME COLD/CGH"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP ALL DAY ALLERGY-D": "GNP ALL DAY ALLERGY-D",
              "GNP ALLERGY & CONGESTION": "GNP ALLERGY & CONGESTION",
              "GNP ALLERGY-D ALLERGY & CONGES": "GNP ALLERGY-D ALLERGY & CONGES",
              "GNP ALLERGY/CONGESTION RELIEF": "GNP ALLERGY/CONGESTION RELIEF",
              "GNP COLD & ALLERGY": "GNP COLD & ALLERGY",
              "GNP FEXOFENADINE/PSE ER": "GNP FEXOFENADINE/PSE ER",
              "GNP LORATADINE-D 12HR": "GNP LORATADINE-D 12HR",
              "GNP LORATADINE-D 24 HOUR": "GNP LORATADINE-D 24 HOUR",
              "GNP SINUS & ALLERGY PE": "GNP SINUS & ALLERGY PE"
            },
            "AURORA HEALTHCARE": {
              "COLD & ALLERGY": "COLD & ALLERGY"
            },
            "BAUSCH HEALTH": {
              "LODRANE D": "LODRANE D"
            },
            "BAYER CONSUMER": {
              "CLARITIN-D 12 HOUR": "CLARITIN-D 12 HOUR",
              "CLARITIN-D 24 HOUR": "CLARITIN-D 24 HOUR",
              "DISOPHROL": "DISOPHROL",
              "DRIXORAL COLD/ALLERGY": "DRIXORAL COLD/ALLERGY"
            },
            "BERGEN BRUNSWIG": {
              "GNP ALL DAY ALLERGY-D": "GNP ALL DAY ALLERGY-D",
              "GNP COLD/ALLERGY CHILDRENS": "GNP COLD/ALLERGY CHILDRENS",
              "GNP COLD/ALLERGY PE": "GNP COLD/ALLERGY PE",
              "GNP SINUS/ALLERGY PE": "GNP SINUS/ALLERGY PE",
              "GNP TRIACTING NIGHT TIME CHILD": "GNP TRIACTING NIGHT TIME CHILD"
            },
            "BRYANT RANCH PREPACK": {
              "LORATADINE-PSEUDOEPHEDRINE ER": "LORATADINE-PSEUDOEPHEDRINE ER",
              "PROMETHAZINE VC": "PROMETHAZINE VC"
            },
            "BUREL PHARMACEUTICALS": {
              "RELHIST": "RELHIST",
              "RELHIST BP": "RELHIST BP"
            },
            "CAPELLON PHARMACEUTICALS": {
              "RESCON": "RESCON"
            },
            "CHAIN DRUG CONSORTIUM": {
              "ALLERGY RELIEF/NASAL DECONGEST": "ALLERGY RELIEF/NASAL DECONGEST",
              "CETIRIZINE-PSEUDOEPHEDRINE ER": "CETIRIZINE-PSEUDOEPHEDRINE ER",
              "COLD & ALLERGY": "COLD & ALLERGY",
              "COLD/ALLERGY": "COLD/ALLERGY",
              "COLD/ALLERGY CHILDRENS": "COLD/ALLERGY CHILDRENS",
              "NIGHT TIME COUGH/COLD CHILD": "NIGHT TIME COUGH/COLD CHILD",
              "NIGHT TIME COUGH/COLD RELIEF": "NIGHT TIME COUGH/COLD RELIEF",
              "SINUS & ALLERGY": "SINUS & ALLERGY",
              "SINUS/ALLERGY MAXIMUM STRENGTH": "SINUS/ALLERGY MAXIMUM STRENGTH"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC LORATADINE-D": "QC LORATADINE-D"
            },
            "CHATTEM INC": {
              "ALLEGRA-D ALLERGY & CONGESTION": "ALLEGRA-D ALLERGY & CONGESTION"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "ALLEGRA-D ALLERGY & CONGESTION": "ALLEGRA-D ALLERGY & CONGESTION"
            },
            "COSTCO WHOLESALE": {
              "KLS ALLER-TEC D": "KLS ALLER-TEC D",
              "KLS ALLERCLEAR D-12HR": "KLS ALLERCLEAR D-12HR",
              "KLS ALLERCLEAR D-24HR": "KLS ALLERCLEAR D-24HR"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS ALLERGY & CONGESTION CHILD": "CVS ALLERGY & CONGESTION CHILD",
              "CVS ALLERGY RELIEF D": "CVS ALLERGY RELIEF D",
              "CVS ALLERGY RELIEF-D": "CVS ALLERGY RELIEF-D",
              "CVS ALLERGY RELIEF-D12": "CVS ALLERGY RELIEF-D12",
              "CVS ALLERGY-D": "CVS ALLERGY-D",
              "CVS COLD & ALLERGY CHILDRENS": "CVS COLD & ALLERGY CHILDRENS",
              "CVS COLD & COUGH NIGHTTIME": "CVS COLD & COUGH NIGHTTIME",
              "CVS LORATADINE-D 24 HOUR": "CVS LORATADINE-D 24 HOUR",
              "CVS SINUS & ALLERGY MAX ST": "CVS SINUS & ALLERGY MAX ST",
              "CVS SINUS PE/ALLERGY MAX ST": "CVS SINUS PE/ALLERGY MAX ST"
            },
            "DELIZ PHARMACEUTICAL CORP": {
              "DELTUSS DP": "DELTUSS DP"
            },
            "DOMEL LABORATORIES": {
              "DOMETUSS-DA/CHILDREN": "DOMETUSS-DA/CHILDREN"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "FEXOFENADINE-PSEUDOEPHED ER": "FEXOFENADINE-PSEUDOEPHED ER"
            },
            "ENDO PHARMACEUTICALS": {
              "SEMPREX-D": "SEMPREX-D"
            },
            "EQUALINE": {
              "EQ ALLERGY & CONGESTION RELIEF": "EQ ALLERGY & CONGESTION RELIEF",
              "EQL ALL DAY ALLERGY-D": "EQL ALL DAY ALLERGY-D",
              "EQL ALLERGY/CONGESTION RELIEF": "EQL ALLERGY/CONGESTION RELIEF",
              "EQL COLD & ALLERGY PE": "EQL COLD & ALLERGY PE",
              "EQL COLD/ALLERGY CHILDRENS": "EQL COLD/ALLERGY CHILDRENS",
              "EQL SINUS & ALLERGY PE": "EQL SINUS & ALLERGY PE",
              "EQL TRIACTING COLD/ALLERGY": "EQL TRIACTING COLD/ALLERGY",
              "FEXOFENADINE-PSEUDOEPHED ER": "FEXOFENADINE-PSEUDOEPHED ER"
            },
            "GEISS DESTIN & DUNN": {
              "ALL DAY ALLERGY-D": "ALL DAY ALLERGY-D"
            },
            "GENTEX PHARMA LLC": {
              "VAZOTAB": "VAZOTAB"
            },
            "GIL PHARMACEUTICAL CORP.": {
              "EXAPHEN": "EXAPHEN",
              "GILTUSS ALLERGY & SINUS": "GILTUSS ALLERGY & SINUS",
              "PHENAGIL": "PHENAGIL"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "ALAVERT ALLERGY/SINUS": "ALAVERT ALLERGY/SINUS",
              "DIMETAPP COLD/ALLERGY": "DIMETAPP COLD/ALLERGY",
              "DIMETAPP NIGHT COLD/CONGESTION": "DIMETAPP NIGHT COLD/CONGESTION",
              "TRIAMINIC COLD & ALLERGY": "TRIAMINIC COLD & ALLERGY",
              "TRIAMINIC NIGHT TIME COLD/CGH": "TRIAMINIC NIGHT TIME COLD/CGH"
            },
            "GLENDALE INC": {
              "GLEN PE": "GLEN PE",
              "GLENMAX PEB": "GLENMAX PEB"
            },
            "GM PHARMACEUTICALS": {
              "NASOPEN PE": "NASOPEN PE"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "CLARITIN-D 12 HOUR": "CLARITIN-D 12 HOUR",
              "LORATADINE-PSEUDOEPHEDRINE ER": "LORATADINE-PSEUDOEPHEDRINE ER",
              "PROMETHAZINE VC": "PROMETHAZINE VC"
            },
            "HI-TECH": {
              "PROMETHAZINE-PHENYLEPHRINE": "PROMETHAZINE-PHENYLEPHRINE"
            },
            "KEY THERAPEUTICS": {
              "DR MANZANILLA PE": "DR MANZANILLA PE",
              "LORTUSS LQ": "LORTUSS LQ"
            },
            "KROGER COMPANY": {
              "ALL DAY ALLERGY-D": "ALL DAY ALLERGY-D",
              "CHILDRENS COLD & ALLERGY": "CHILDRENS COLD & ALLERGY",
              "COLD/ALLERGY PE": "COLD/ALLERGY PE"
            },
            "LARKEN LABORATORIES, INC.": {
              "LOHIST-D": "LOHIST-D",
              "NOHIST-LQ": "NOHIST-LQ"
            },
            "LASER PHARMACEUTICALS": {
              "DALLERGY": "DALLERGY"
            },
            "LEADER BRAND PRODUCTS": {
              "12HR ALLERGY & CONGESTION": "12HR ALLERGY & CONGESTION",
              "ALL DAY ALLERGY D-12": "ALL DAY ALLERGY D-12",
              "ALLERGY RELIEF D": "ALLERGY RELIEF D",
              "ALLERGY RELIEF D-12": "ALLERGY RELIEF D-12",
              "ALLERGY RELIEF D-24": "ALLERGY RELIEF D-24",
              "COLD & ALLERGY CHILDRENS": "COLD & ALLERGY CHILDRENS",
              "COLD/ALLERGY CHILDRENS": "COLD/ALLERGY CHILDRENS"
            },
            "LIBERTY PHARMACEUTICAL": {
              "TRIPROLIDINE-PSE": "TRIPROLIDINE-PSE"
            },
            "LLORENS PHARMACEUTICAL": {
              "CONEX COLD/ALLERGY": "CONEX COLD/ALLERGY"
            },
            "MAGNA PHARMACEUTICALS, INC": {
              "STAHIST": "STAHIST",
              "STAHIST AD": "STAHIST AD",
              "STAHIST TP": "STAHIST TP"
            },
            "MAJOR PHARMACEUTICALS": {
              "ALL DAY ALLERGY-D": "ALL DAY ALLERGY-D",
              "APRODINE": "APRODINE",
              "DIMAPHEN CHILDRENS": "DIMAPHEN CHILDRENS",
              "LORATADINE-D 24HR": "LORATADINE-D 24HR",
              "SUDOGEST SINUS/ALLERGY": "SUDOGEST SINUS/ALLERGY"
            },
            "MCKESSON": {
              "HM ALLERGY & CONGESTION": "HM ALLERGY & CONGESTION",
              "HM ALLERGY COMPLETE-D": "HM ALLERGY COMPLETE-D",
              "HM ALLERGY RELIEF/NASAL DECONG": "HM ALLERGY RELIEF/NASAL DECONG",
              "HM COLD & ALLERGY CHILDRENS": "HM COLD & ALLERGY CHILDRENS"
            },
            "MCKESSON SUNMARK": {
              "SM ALL DAY ALLERGY-D": "SM ALL DAY ALLERGY-D",
              "SM COLD & ALLERGY CHILDRENS": "SM COLD & ALLERGY CHILDRENS",
              "SM COLD & ALLERGY PE": "SM COLD & ALLERGY PE",
              "SM LORATA-DINE D": "SM LORATA-DINE D",
              "SM LORATADINE D": "SM LORATADINE D",
              "SM LORATADINE D 12HR": "SM LORATADINE D 12HR",
              "SM SINUS & ALLERGY MAX ST": "SM SINUS & ALLERGY MAX ST",
              "SM SINUS & ALLERGY PE MAX ST": "SM SINUS & ALLERGY PE MAX ST"
            },
            "MCNEIL CONSUMER": {
              "BENADRYL ALLERGY CHILDRENS": "BENADRYL ALLERGY CHILDRENS",
              "SUDAFED PE SINUS CONG DAY/NGHT": "SUDAFED PE SINUS CONG DAY/NGHT",
              "ZYRTEC-D ALLERGY & CONGESTION": "ZYRTEC-D ALLERGY & CONGESTION"
            },
            "MCR/AMERICAN PHARMACEUTICAL": {
              "MAXI-TUSS PE": "MAXI-TUSS PE",
              "MAXI-TUSS TR": "MAXI-TUSS TR",
              "MAXIFED TR": "MAXIFED TR"
            },
            "MEDICINE SHOPPE": {
              "ALLERGY D-12": "ALLERGY D-12",
              "ALLERGY RELIEF D-24": "ALLERGY RELIEF D-24",
              "CHILDRENS COLD & ALLERGY": "CHILDRENS COLD & ALLERGY"
            },
            "MEIJER": {
              "12 HOUR ALLERGY-D": "12 HOUR ALLERGY-D",
              "ALLERGY RELIEF-D": "ALLERGY RELIEF-D",
              "ALLERGY RELIEF/NASAL DECONGEST": "ALLERGY RELIEF/NASAL DECONGEST",
              "ALLERGY/CONGESTION RELIEF": "ALLERGY/CONGESTION RELIEF",
              "MEIJER ALLERGY RELIEF-D": "MEIJER ALLERGY RELIEF-D",
              "MEIJER ALLERGY/CONGESTION": "MEIJER ALLERGY/CONGESTION"
            },
            "MERCK SHARP & DOHME": {
              "CLARINEX-D 12 HOUR": "CLARINEX-D 12 HOUR"
            },
            "NOREGA LABORATORIES": {
              "DECON-A": "DECON-A"
            },
            "OHM LABS": {
              "ALLERGY RELIEF/NASAL DECONGEST": "ALLERGY RELIEF/NASAL DECONGEST"
            },
            "PAR PHARMACEUTICALS": {
              "PROMETHAZINE VC PLAIN": "PROMETHAZINE VC PLAIN",
              "Q-TAPP": "Q-TAPP"
            },
            "PDRX PHARMACEUTICAL": {
              "CLARITIN-D 12 HOUR": "CLARITIN-D 12 HOUR"
            },
            "PERRIGO": {
              "ALL DAY ALLERGY-D": "ALL DAY ALLERGY-D",
              "ALLERGY/CONGESTION RELIEF": "ALLERGY/CONGESTION RELIEF",
              "CHILDRENS COLD & ALLERGY": "CHILDRENS COLD & ALLERGY"
            },
            "PERRIGO PHARMACEUTICALS": {
              "CETIRIZINE-PSEUDOEPHEDRINE ER": "CETIRIZINE-PSEUDOEPHEDRINE ER",
              "LORATADINE-D 12HR": "LORATADINE-D 12HR",
              "LORATADINE-D 24HR": "LORATADINE-D 24HR"
            },
            "POLY PHARMACEUTICALS": {
              "ALA-HIST PE": "ALA-HIST PE",
              "ALAHIST D": "ALAHIST D",
              "ALAHIST PE": "ALAHIST PE",
              "POLY HIST FORTE": "POLY HIST FORTE"
            },
            "PREFERRED PHARMACEUTICALS": {
              "DIMAPHEN CHILDRENS": "DIMAPHEN CHILDRENS"
            },
            "PROFICIENT RX": {
              "APRODINE": "APRODINE",
              "CETIRIZINE-PSEUDOEPHEDRINE ER": "CETIRIZINE-PSEUDOEPHEDRINE ER"
            },
            "PUBLIX SUPER MARKETS INC.": {
              "ALLERGY RELIEF D": "ALLERGY RELIEF D",
              "PX ALLERGY RELIEF D": "PX ALLERGY RELIEF D",
              "PX ALLERGY RELIEF D (LORATID)": "PX ALLERGY RELIEF D (LORATID)",
              "PX DIBROMM COLD/ALLERGY CHILD": "PX DIBROMM COLD/ALLERGY CHILD"
            },
            "QUALITY CARE": {
              "LORATADINE-D 24HR": "LORATADINE-D 24HR",
              "PROMETHAZINE VC": "PROMETHAZINE VC",
              "ZYRTEC-D ALLERGY & CONGESTION": "ZYRTEC-D ALLERGY & CONGESTION"
            },
            "RECKITT BENCKISER": {
              "DELSYM NIGHT CGH/COLD CHILDREN": "DELSYM NIGHT CGH/COLD CHILDREN",
              "DELSYM NIGHT TIME COUGH/COLD": "DELSYM NIGHT TIME COUGH/COLD"
            },
            "RITE AID CORPORATION": {
              "RA ACTA-TABS PE": "RA ACTA-TABS PE",
              "RA ALLERGY PLUS SINUS": "RA ALLERGY PLUS SINUS",
              "RA ALLERGY RELF & NASAL DECONG": "RA ALLERGY RELF & NASAL DECONG",
              "RA ALLERGY RELIEF-D": "RA ALLERGY RELIEF-D",
              "RA ALLERGY/CONGESTION": "RA ALLERGY/CONGESTION",
              "RA ALLERGY/CONGESTION RELIEF": "RA ALLERGY/CONGESTION RELIEF",
              "RA CETIRI-D": "RA CETIRI-D",
              "RA CHILDRENS COLD & ALLERGY": "RA CHILDRENS COLD & ALLERGY",
              "RA COLD & ALLERGY": "RA COLD & ALLERGY",
              "RA LORATA-D": "RA LORATA-D",
              "RA SUPHEDRINE": "RA SUPHEDRINE",
              "RA SUPHEDRINE PE": "RA SUPHEDRINE PE"
            },
            "RUGBY LABORATORIES": {
              "ANTIHISTAMINE & NASAL DECONGES": "ANTIHISTAMINE & NASAL DECONGES"
            },
            "SAFEWAY": {
              "SW ALLERGY RELIEF-D": "SW ALLERGY RELIEF-D"
            },
            "SALLUS LABORATORIES": {
              "LORTUSS LQ": "LORTUSS LQ"
            },
            "SELECT BRAND": {
              "SB ALLERFED COLD & ALLERGY": "SB ALLERFED COLD & ALLERGY",
              "SB ALLERGY RELIEF/NASAL DECONG": "SB ALLERGY RELIEF/NASAL DECONG",
              "SB COLD & ALLERGY CHILDRENS": "SB COLD & ALLERGY CHILDRENS",
              "SB SINUS & ALLERGY MAX ST": "SB SINUS & ALLERGY MAX ST"
            },
            "SHOPKO STORES OPERATING CO": {
              "SHOPKO ALLERGY RELIEF-D (CETI)": "SHOPKO ALLERGY RELIEF-D (CETI)",
              "SHOPKO ALLERGY RELIEF-D (LORA)": "SHOPKO ALLERGY RELIEF-D (LORA)"
            },
            "SILARX": {
              "BROTAPP": "BROTAPP"
            },
            "TARGET": {
              "TGT ALL DAY ALLERGY-D": "TGT ALL DAY ALLERGY-D",
              "TGT ALLERGY+ CONGESTION RELIEF": "TGT ALLERGY+ CONGESTION RELIEF",
              "TGT ALLERGY/CONGESTION RELIEF": "TGT ALLERGY/CONGESTION RELIEF",
              "TGT COLD/COUGH RELIEF NIGHT": "TGT COLD/COUGH RELIEF NIGHT"
            },
            "THE GENERIC PHARMACEUTICAL COM": {
              "G HIST FORTE": "G HIST FORTE",
              "G-HIST PE": "G-HIST PE"
            },
            "TOPCO": {
              "ALL DAY ALLERGY D": "ALL DAY ALLERGY D",
              "ALLERGY RELIEF-D": "ALLERGY RELIEF-D",
              "CHILDRENS COLD & ALLERGY": "CHILDRENS COLD & ALLERGY",
              "COLD & ALLERGY": "COLD & ALLERGY",
              "SINUS & ALLERGY PE MAX ST": "SINUS & ALLERGY PE MAX ST",
              "TRIACTING NIGHTIME COLD&COUGH": "TRIACTING NIGHTIME COLD&COUGH"
            },
            "WAL-MART": {
              "EQ ALLERGY RELIEF": "EQ ALLERGY RELIEF",
              "EQ ALLERGY RELIEF D 24 HOUR": "EQ ALLERGY RELIEF D 24 HOUR",
              "EQ COLD/ALLERGY CHILDRENS": "EQ COLD/ALLERGY CHILDRENS",
              "EQ NIGHT TIME COLD & COUGH": "EQ NIGHT TIME COLD & COUGH",
              "EQ SUPHEDRINE PE": "EQ SUPHEDRINE PE",
              "EQ TRIACTING COLD/ALLERGY": "EQ TRIACTING COLD/ALLERGY",
              "MM LORATADINE-D 24 HOUR": "MM LORATADINE-D 24 HOUR"
            },
            "WALGREENS": {
              "WAL-ACT": "WAL-ACT",
              "WAL-DRYL PE ALLERGY & SINUS": "WAL-DRYL PE ALLERGY & SINUS",
              "WAL-DRYL-D ALLERGY/SINUS": "WAL-DRYL-D ALLERGY/SINUS",
              "WAL-FEX D ALLERGY & CONGESTION": "WAL-FEX D ALLERGY & CONGESTION",
              "WAL-FINATE-D": "WAL-FINATE-D",
              "WAL-ITIN D": "WAL-ITIN D",
              "WAL-ITIN D 24 HOUR": "WAL-ITIN D 24 HOUR",
              "WAL-PHED PE SINUS/ALLERGY": "WAL-PHED PE SINUS/ALLERGY",
              "WAL-PHED SINUS/ALLERGY": "WAL-PHED SINUS/ALLERGY",
              "WAL-TAP CHILDRENS": "WAL-TAP CHILDRENS",
              "WAL-TAP COLD/ALLERGY": "WAL-TAP COLD/ALLERGY",
              "WAL-ZYR D": "WAL-ZYR D"
            },
            "WESTMINSTER PHARMACEUTICALS": {
              "DEXBROMPHENIRAMINE-PHENYLEPH": "DEXBROMPHENIRAMINE-PHENYLEPH",
              "DOXYLAMINE-PHENYLEPHRINE": "DOXYLAMINE-PHENYLEPHRINE"
            },
            "WOMEN'S CHOICE PHARMACEUTICALS": {
              "ED A-HIST": "ED A-HIST",
              "ED A-HIST PSE": "ED A-HIST PSE",
              "ED CHLORPED D": "ED CHLORPED D",
              "RYMED": "RYMED",
              "RYNEX PE": "RYNEX PE",
              "RYNEX PSE": "RYNEX PSE"
            },
            "WRASER PHARMACEUTICALS": {
              "VAZOBID-PD": "VAZOBID-PD"
            },
            "XSPIRE PHARMA": {
              "PYRILAMINE-PHENYLEPHRINE": "PYRILAMINE-PHENYLEPHRINE"
            }
          },
          "Decongestant w/ Expectorant": {
            "A-S MEDICATION SOLUTIONS": {
              "MUCINEX D": "MUCINEX D"
            },
            "ACELLA PHARMACEUTICALS": {
              "PHENYLEPHRINE-GUAIFENESIN": "PHENYLEPHRINE-GUAIFENESIN"
            },
            "ALLEGIS PHARMACEUTICALS": {
              "DURAVENT PE": "DURAVENT PE"
            },
            "ALTAIRE": {
              "ALTARUSSIN-PE": "ALTARUSSIN-PE"
            },
            "AMBI PHARMACEUTICALS": {
              "AMBI 10PEH/400GFN": "AMBI 10PEH/400GFN",
              "AMBI 40PSE/400GFN": "AMBI 40PSE/400GFN",
              "AMBI 60PSE/400GFN": "AMBI 60PSE/400GFN"
            },
            "AMERICAN SALES COMPANY": {
              "CHEST CONGESTION/SINUS RELIEF": "CHEST CONGESTION/SINUS RELIEF"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP MUCUS D 12 HR": "GNP MUCUS D 12 HR"
            },
            "B.F. ASCHER": {
              "CONGESTAC": "CONGESTAC"
            },
            "BERGEN BRUNSWIG": {
              "GNP MUCUS RELIEF PE": "GNP MUCUS RELIEF PE"
            },
            "BUREL PHARMACEUTICALS": {
              "RELCOF IR": "RELCOF IR"
            },
            "CAPELLON PHARMACEUTICALS": {
              "RESCON-GG": "RESCON-GG"
            },
            "CARWIN ASSOCIATES": {
              "NU-COPD": "NU-COPD"
            },
            "CENTURION LABS": {
              "LUSAIR": "LUSAIR",
              "RYDEX G": "RYDEX G"
            },
            "CHAIN DRUG CONSORTIUM": {
              "CHEST CONGESTION RELIEF D": "CHEST CONGESTION RELIEF D",
              "MUCUS RELIEF D": "MUCUS RELIEF D",
              "MUCUS RELIEF PE": "MUCUS RELIEF PE",
              "NON-DRYING SINUS": "NON-DRYING SINUS",
              "TUSSIN PE": "TUSSIN PE"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC MEDIFIN PE": "QC MEDIFIN PE"
            },
            "CONTRACT PHARMACAL CORPORATION": {
              "PHENYLEPHRINE-GUAIFENESIN": "PHENYLEPHRINE-GUAIFENESIN"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS CHEST CONGESTION RELIEF D": "CVS CHEST CONGESTION RELIEF D",
              "CVS CHEST CONGESTION RELIEF PE": "CVS CHEST CONGESTION RELIEF PE",
              "CVS MUCUS D EXTENDED RELEASE": "CVS MUCUS D EXTENDED RELEASE",
              "CVS MUCUS D MAX ST ER": "CVS MUCUS D MAX ST ER",
              "CVS NON-DRYING SINUS PE": "CVS NON-DRYING SINUS PE",
              "CVS STUFFY NOSE & COLD CHILD": "CVS STUFFY NOSE & COLD CHILD"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "PSEUDOEPHEDRINE-GUAIFENESIN ER": "PSEUDOEPHEDRINE-GUAIFENESIN ER"
            },
            "EQUALINE": {
              "EQL NON-DRYING SINUS": "EQL NON-DRYING SINUS"
            },
            "FOUNDATION CONSUMER HEALTHCARE": {
              "BRONKAID": "BRONKAID"
            },
            "GEISS DESTIN & DUNN": {
              "GOODSENSE MUCUS-D": "GOODSENSE MUCUS-D"
            },
            "GIL PHARMACEUTICAL CORP.": {
              "EXAPHEX TR": "EXAPHEX TR",
              "GILPHEX TR": "GILPHEX TR",
              "GILTUSS SINUS & CONGESTION": "GILTUSS SINUS & CONGESTION"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "PRIMATENE ASTHMA": "PRIMATENE ASTHMA",
              "TRIAMINIC CHEST/NASAL CONGEST": "TRIAMINIC CHEST/NASAL CONGEST"
            },
            "INTERNATIONAL ETHICAL LABS": {
              "DESPEC": "DESPEC"
            },
            "JAYMAC PHARMACEUTICAL LLC": {
              "J-MAX": "J-MAX"
            },
            "KRAMER-NOVIS": {
              "SUPRESS-PE PEDIATRIC": "SUPRESS-PE PEDIATRIC",
              "TUSSI-PRES PE PEDIATRIC": "TUSSI-PRES PE PEDIATRIC"
            },
            "LASER PHARMACEUTICALS": {
              "RESPAIRE-30": "RESPAIRE-30"
            },
            "LEADER BRAND PRODUCTS": {
              "CHEST CONGESTION RELIEF PE": "CHEST CONGESTION RELIEF PE",
              "MUCUS RELIEF D": "MUCUS RELIEF D",
              "MUCUS RELIEF PE SINUS": "MUCUS RELIEF PE SINUS"
            },
            "LLORENS PHARMACEUTICAL": {
              "TUSNEL PEDIATRIC": "TUSNEL PEDIATRIC"
            },
            "LNK INTERNATIONAL": {
              "MUCUS RELIEF D": "MUCUS RELIEF D"
            },
            "MAJOR PHARMACEUTICALS": {
              "MUCUSRELIEF SINUS": "MUCUSRELIEF SINUS"
            },
            "MCKESSON": {
              "HM MUCUS RELIEF D": "HM MUCUS RELIEF D"
            },
            "MCKESSON SUNMARK": {
              "SM CHEST CONGESTION RELIEF PE": "SM CHEST CONGESTION RELIEF PE",
              "SM MUCUS RELIEF D": "SM MUCUS RELIEF D"
            },
            "MCR/AMERICAN PHARMACEUTICAL": {
              "MAXI-TUSS PE JR": "MAXI-TUSS PE JR",
              "MAXI-TUSS PE MAX": "MAXI-TUSS PE MAX",
              "MAXIFED": "MAXIFED"
            },
            "MEDICINE SHOPPE": {
              "CHEST CONGESTION/SINUS RELIEF": "CHEST CONGESTION/SINUS RELIEF"
            },
            "PERRIGO": {
              "MUCUS D": "MUCUS D"
            },
            "PERRIGO PHARMACEUTICALS": {
              "PSEUDOEPHEDRINE-GUAIFENESIN ER": "PSEUDOEPHEDRINE-GUAIFENESIN ER"
            },
            "PHARBEST PHARMACEUTICALS": {
              "PHARBINEX-PE": "PHARBINEX-PE"
            },
            "PHARMA MEDICA": {
              "FENESIN PE IR": "FENESIN PE IR"
            },
            "POLY PHARMACEUTICALS": {
              "DECONEX IR": "DECONEX IR",
              "POLY-VENT IR": "POLY-VENT IR"
            },
            "PROFICIENT RX": {
              "PSEUDOEPHEDRINE-GUAIFENESIN ER": "PSEUDOEPHEDRINE-GUAIFENESIN ER"
            },
            "QUALITY CARE": {
              "MUCINEX D": "MUCINEX D"
            },
            "RECKITT BENCKISER": {
              "MUCINEX D": "MUCINEX D",
              "MUCINEX D MAX STRENGTH": "MUCINEX D MAX STRENGTH",
              "MUCINEX STUFFY NOSE & CHEST": "MUCINEX STUFFY NOSE & CHEST",
              "MUCINEX STUFFY NOSE/COLD CHILD": "MUCINEX STUFFY NOSE/COLD CHILD"
            },
            "REESE PHARMACEUTICAL": {
              "REESES ONETAB CONGEST/COUGH": "REESES ONETAB CONGEST/COUGH",
              "REFENESEN PE": "REFENESEN PE"
            },
            "RITE AID CORPORATION": {
              "RA MUCUS RELIEF D": "RA MUCUS RELIEF D",
              "RA MUCUS RELIEF D MAX STRENGTH": "RA MUCUS RELIEF D MAX STRENGTH",
              "RA MUCUS RELIEF SINUS": "RA MUCUS RELIEF SINUS"
            },
            "RUGBY LABORATORIES": {
              "MUCUS RELIEF D": "MUCUS RELIEF D"
            },
            "SALERNO PHARMACEUTICALS": {
              "TG 10PEH/380GFN": "TG 10PEH/380GFN"
            },
            "SELECT BRAND": {
              "SB BRONCHIAL": "SB BRONCHIAL",
              "SB MUCUS RELIEF PE": "SB MUCUS RELIEF PE"
            },
            "THE GENERIC PHARMACEUTICAL COM": {
              "GCON IR": "GCON IR"
            },
            "TOPCO": {
              "MUCUS-D": "MUCUS-D"
            },
            "WAL-MART": {
              "EQ MUCUS-D": "EQ MUCUS-D"
            },
            "WALGREENS": {
              "BRONCHIAL ASTHMA RELIEF": "BRONCHIAL ASTHMA RELIEF",
              "MUCUS RELIEF D": "MUCUS RELIEF D",
              "MUCUS RELIEF PE": "MUCUS RELIEF PE"
            },
            "WOMEN'S CHOICE PHARMACEUTICALS": {
              "ED BRON GP": "ED BRON GP",
              "MUCAPHED": "MUCAPHED"
            },
            "WRASER PHARMACEUTICALS": {
              "ENTEX T": "ENTEX T"
            },
            "XSPIRE PHARMA": {
              "PSEUDOEPHEDRINE-GUAIFENESIN": "PSEUDOEPHEDRINE-GUAIFENESIN"
            }
          },
          "Decongestant-Analgesic": {
            "AMERICAN SALES COMPANY": {
              "SINUS HEADACHE PE MAX ST": "SINUS HEADACHE PE MAX ST"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP DAY TIME SINUS": "GNP DAY TIME SINUS",
              "GNP SINUS & COLD-D": "GNP SINUS & COLD-D"
            },
            "BAYER CONSUMER": {
              "ALEVE-D SINUS & COLD": "ALEVE-D SINUS & COLD",
              "ALEVE-D SINUS & HEADACHE": "ALEVE-D SINUS & HEADACHE",
              "ALKA-SELTZER PLUS SINUS": "ALKA-SELTZER PLUS SINUS"
            },
            "BERGEN BRUNSWIG": {
              "GNP FLU/SEVERE COLD DAYTIME": "GNP FLU/SEVERE COLD DAYTIME",
              "GNP IBUPROFEN COLD/SINUS": "GNP IBUPROFEN COLD/SINUS",
              "GNP SINUS CONGESTION/PAIN DAY": "GNP SINUS CONGESTION/PAIN DAY"
            },
            "CHAIN DRUG CONSORTIUM": {
              "DAY-TIME SINUS": "DAY-TIME SINUS",
              "DAYTIME SINUS": "DAYTIME SINUS",
              "DAYTIME SINUS CONGESTION": "DAYTIME SINUS CONGESTION",
              "IBUPROFEN AND PSE COLD & SINUS": "IBUPROFEN AND PSE COLD & SINUS",
              "PRESSURE & PAIN MAX STRENGTH": "PRESSURE & PAIN MAX STRENGTH",
              "SINUS CONGESTION/PAIN DAYTIME": "SINUS CONGESTION/PAIN DAYTIME"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC IBUPROFEN COLD/SINUS": "QC IBUPROFEN COLD/SINUS",
              "QC PRESSURE & PAIN PE": "QC PRESSURE & PAIN PE",
              "QC SINUS PAIN RELIEF": "QC SINUS PAIN RELIEF"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS COLD & SINUS RELIEF": "CVS COLD & SINUS RELIEF",
              "CVS SINUS & COLD-D": "CVS SINUS & COLD-D",
              "CVS SINUS HEADACHE PE": "CVS SINUS HEADACHE PE",
              "CVS SINUS PAIN/CONGESTION DAY": "CVS SINUS PAIN/CONGESTION DAY"
            },
            "EQUALINE": {
              "EQL CONGESTION RELIEF": "EQL CONGESTION RELIEF",
              "EQL IBUPROFEN COLD & SINUS": "EQL IBUPROFEN COLD & SINUS",
              "EQL IBUPROFEN COLD/SINUS": "EQL IBUPROFEN COLD/SINUS",
              "EQL PAIN RELIEF/SINUS": "EQL PAIN RELIEF/SINUS",
              "EQL SINUS CONGESTION/PAIN DAY": "EQL SINUS CONGESTION/PAIN DAY",
              "EQL SINUS HEADACHE": "EQL SINUS HEADACHE",
              "EQL SINUS HEADACHE PE MAX ST": "EQL SINUS HEADACHE PE MAX ST"
            },
            "GEISS DESTIN & DUNN": {
              "GOODSENSE PRESSURE/PAIN PE": "GOODSENSE PRESSURE/PAIN PE"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "ADVIL COLD & SINUS LIQUI-GELS": "ADVIL COLD & SINUS LIQUI-GELS",
              "ADVIL COLD/SINUS": "ADVIL COLD/SINUS",
              "ADVIL CONGESTION RELIEF": "ADVIL CONGESTION RELIEF",
              "ADVIL SINUS CONGESTION & PAIN": "ADVIL SINUS CONGESTION & PAIN",
              "PANADOL COLD/FLU": "PANADOL COLD/FLU"
            },
            "LEADER BRAND PRODUCTS": {
              "IBU-PROFEN COLD/SINUS": "IBU-PROFEN COLD/SINUS",
              "PAIN RELIEF SINUS PE DAYTIME": "PAIN RELIEF SINUS PE DAYTIME",
              "SINUS AND HEADACHE": "SINUS AND HEADACHE",
              "SINUS PRESSURE + PAIN": "SINUS PRESSURE + PAIN"
            },
            "MAINPOINTE PHARMACEUTICALS": {
              "NEXAFED SINUS PRESSURE + PAIN": "NEXAFED SINUS PRESSURE + PAIN"
            },
            "MAJOR PHARMACEUTICALS": {
              "ACETAMINOPHEN CONGESTION/PAIN": "ACETAMINOPHEN CONGESTION/PAIN",
              "MAPAP SINUS MAXIMUM STRENGTH": "MAPAP SINUS MAXIMUM STRENGTH"
            },
            "MCKESSON": {
              "HM COLD & SINUS RELIEF": "HM COLD & SINUS RELIEF",
              "HM SINUS & COLD-D": "HM SINUS & COLD-D"
            },
            "MCKESSON SUNMARK": {
              "SM COLD & SINUS RELIEF": "SM COLD & SINUS RELIEF",
              "SM IBUPROFEN COLD & SINUS": "SM IBUPROFEN COLD & SINUS",
              "SM PAIN RELIEVER SINUS PE": "SM PAIN RELIEVER SINUS PE",
              "SM SINUS & COLD-D": "SM SINUS & COLD-D"
            },
            "MCNEIL CONSUMER": {
              "SUDAFED PE HEAD CONGESTION": "SUDAFED PE HEAD CONGESTION",
              "SUDAFED PE SINUS PRESSURE+PAIN": "SUDAFED PE SINUS PRESSURE+PAIN",
              "SUDAFED SINUS 12HR PRESS+PAIN": "SUDAFED SINUS 12HR PRESS+PAIN",
              "TYLENOL SINUS+HEADACHE": "TYLENOL SINUS+HEADACHE"
            },
            "MEDA CONSUMER HEALTHCARE": {
              "CONTAC COLD+FLU MAX ST": "CONTAC COLD+FLU MAX ST"
            },
            "MEDIQUE PRODUCTS": {
              "NON-PSEUDO SINUS PAIN/PRESSURE": "NON-PSEUDO SINUS PAIN/PRESSURE"
            },
            "OHM LABS": {
              "COLD & SINUS RELIEF": "COLD & SINUS RELIEF"
            },
            "P & G HEALTH": {
              "QLEARQUIL SINUS & CONGESTION": "QLEARQUIL SINUS & CONGESTION",
              "VICKS SINEX DAYTIME": "VICKS SINEX DAYTIME"
            },
            "PERRIGO": {
              "SINUS & COLD-D": "SINUS & COLD-D",
              "SINUS CONGESTION/PAIN DAYTIME": "SINUS CONGESTION/PAIN DAYTIME"
            },
            "PUBLIX SUPER MARKETS INC.": {
              "PX IBUPROFEN COLD & SINUS": "PX IBUPROFEN COLD & SINUS",
              "PX SINUS RELIEF": "PX SINUS RELIEF"
            },
            "RITE AID CORPORATION": {
              "RA IBU-PROFEN COLD/SINUS": "RA IBU-PROFEN COLD/SINUS",
              "RA IBUPROFEN COLD CHILDRENS": "RA IBUPROFEN COLD CHILDRENS",
              "RA SINUS CONGESTION/PAIN DAY": "RA SINUS CONGESTION/PAIN DAY",
              "RA SUPHEDRINE PE": "RA SUPHEDRINE PE",
              "RA SUPHEDRINE PE SINUS": "RA SUPHEDRINE PE SINUS"
            },
            "RUGBY LABORATORIES": {
              "CONGESTION RELIEF": "CONGESTION RELIEF",
              "SINUS CONGESTION/PAIN": "SINUS CONGESTION/PAIN"
            },
            "SAFEWAY": {
              "SW COLD & SINUS PAIN RELIEF": "SW COLD & SINUS PAIN RELIEF"
            },
            "SELECT BRAND": {
              "SB DAYTIME SINUS": "SB DAYTIME SINUS",
              "SB SINUS CONGESTION/PAIN DAY": "SB SINUS CONGESTION/PAIN DAY"
            },
            "TARGET": {
              "TGT SINUS CONGESTION/PAIN DAY": "TGT SINUS CONGESTION/PAIN DAY"
            },
            "TOPCO": {
              "IBUPROFEN COLD & SINUS": "IBUPROFEN COLD & SINUS",
              "SINUS CONGESTION/PAIN DAYTIME": "SINUS CONGESTION/PAIN DAYTIME"
            },
            "WAL-MART": {
              "EQ DAYTIME SINUS": "EQ DAYTIME SINUS",
              "EQ SINUS CONGESTION & PAIN DAY": "EQ SINUS CONGESTION & PAIN DAY",
              "EQ SINUS RELIEF MAX ST": "EQ SINUS RELIEF MAX ST",
              "EQ SUPHEDRINE PE": "EQ SUPHEDRINE PE"
            },
            "WALGREENS": {
              "ALL DAY PAIN RELF SINUS/COLD D": "ALL DAY PAIN RELF SINUS/COLD D",
              "SINUS CONGESTION/PAIN DAYTIME": "SINUS CONGESTION/PAIN DAYTIME",
              "WAL-FLU SEVERE COLD DAYTIME": "WAL-FLU SEVERE COLD DAYTIME",
              "WAL-PHED PE SINUS HEADACHE": "WAL-PHED PE SINUS HEADACHE",
              "WAL-PROFEN COLD & SINUS": "WAL-PROFEN COLD & SINUS"
            }
          },
          "Decongestant-Analgesic-Expectorant": {
            "AMERISOURCE BERGEN DRUGS": {
              "GNP COLD/HEAD CONGESTION": "GNP COLD/HEAD CONGESTION",
              "GNP MUCUS RELIEF COLD & SINUS": "GNP MUCUS RELIEF COLD & SINUS",
              "GNP SINUS RELIEF CONGEST/PAIN": "GNP SINUS RELIEF CONGEST/PAIN",
              "GNP SINUS RELIEF PRESSURE/PAIN": "GNP SINUS RELIEF PRESSURE/PAIN",
              "GNP SINUS RELIEF SEVERE CNG": "GNP SINUS RELIEF SEVERE CNG",
              "GNP SINUS SEVERE DAYTIME": "GNP SINUS SEVERE DAYTIME"
            },
            "CHAIN DRUG CONSORTIUM": {
              "CHEST CONGESTION/PAIN & SINUS": "CHEST CONGESTION/PAIN & SINUS",
              "COLD HEAD CONGEST SEVERE DAY": "COLD HEAD CONGEST SEVERE DAY",
              "MUCUS RELIEF SINUS SEVERE CONG": "MUCUS RELIEF SINUS SEVERE CONG"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC SINUS CONGEST/PAIN SEVERE": "QC SINUS CONGEST/PAIN SEVERE"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS COLD & SINUS MULTI-SYMPTOM": "CVS COLD & SINUS MULTI-SYMPTOM",
              "CVS SEVERE CONGESTION RELIEF": "CVS SEVERE CONGESTION RELIEF",
              "CVS SINUS RELIEF PRESSURE/PAIN": "CVS SINUS RELIEF PRESSURE/PAIN"
            },
            "EQUALINE": {
              "EQL PRESSURE & PAIN PLS/MUCUS": "EQL PRESSURE & PAIN PLS/MUCUS"
            },
            "GEISS DESTIN & DUNN": {
              "GOODSENSE COLD & HEAD CONGEST": "GOODSENSE COLD & HEAD CONGEST",
              "GOODSENSE PRESSURE/PAIN/MUCUS": "GOODSENSE PRESSURE/PAIN/MUCUS",
              "GOODSENSE SINUS RELIEF MAX ST": "GOODSENSE SINUS RELIEF MAX ST",
              "GOODSENSE SINUS SEVERE DAYTIME": "GOODSENSE SINUS SEVERE DAYTIME"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "THERAFLU WARMING COLD & CHEST": "THERAFLU WARMING COLD & CHEST"
            },
            "LEADER BRAND PRODUCTS": {
              "MUCUS RELIEF COLD/SINUS MAX ST": "MUCUS RELIEF COLD/SINUS MAX ST",
              "SINUS CONGESTION/PAIN DAYTIME": "SINUS CONGESTION/PAIN DAYTIME"
            },
            "MCKESSON": {
              "HM MUCUS RELIEF FM COLD/SINUS": "HM MUCUS RELIEF FM COLD/SINUS"
            },
            "MCNEIL CONSUMER": {
              "SUDAFED PE HEAD CONGESTION": "SUDAFED PE HEAD CONGESTION",
              "TYLENOL COLD & HEAD": "TYLENOL COLD & HEAD",
              "TYLENOL SINUS SEVERE": "TYLENOL SINUS SEVERE"
            },
            "P & G HEALTH": {
              "VICKS SINEX SEVERE": "VICKS SINEX SEVERE"
            },
            "RECKITT BENCKISER": {
              "MUCINEX FAST-MAX": "MUCINEX FAST-MAX",
              "MUCINEX FAST-MAX COLD & SINUS": "MUCINEX FAST-MAX COLD & SINUS",
              "MUCINEX SINUS-MAX": "MUCINEX SINUS-MAX",
              "MUCINEX SINUS-MAX CONG & PAIN": "MUCINEX SINUS-MAX CONG & PAIN",
              "MUCINEX SINUS-MAX CONGESTION": "MUCINEX SINUS-MAX CONGESTION",
              "MUCINEX SINUS-MAX PRESS & PAIN": "MUCINEX SINUS-MAX PRESS & PAIN"
            },
            "REESE PHARMACEUTICAL": {
              "REFENESEN CHST CONG/PAIN PE": "REFENESEN CHST CONG/PAIN PE"
            },
            "RITE AID CORPORATION": {
              "RA COLD/SINUS MAX": "RA COLD/SINUS MAX",
              "RA SINUS CONGEST/PAIN RELIEF": "RA SINUS CONGEST/PAIN RELIEF"
            },
            "SELECT BRAND": {
              "SB SINUS CONGESTION/PAIN": "SB SINUS CONGESTION/PAIN"
            },
            "WAL-MART": {
              "EQ SINUS CONGESTION & PAIN": "EQ SINUS CONGESTION & PAIN"
            },
            "WALGREENS": {
              "MUCUS RELIEF COLD/SINUS MAX ST": "MUCUS RELIEF COLD/SINUS MAX ST",
              "MUCUS RELIEF SEVERE SINUS": "MUCUS RELIEF SEVERE SINUS",
              "MUCUS RELIEF SINUS PRESSURE": "MUCUS RELIEF SINUS PRESSURE",
              "SEVERE CONGESTION": "SEVERE CONGESTION",
              "WAL-PHED PE TRIPLE RELIEF": "WAL-PHED PE TRIPLE RELIEF"
            }
          },
          "Decongestant-Antihistamine w/ Expectorant": {
            "NOREGA LABORATORIES": {
              "DECON-G": "DECON-G"
            }
          },
          "Decongestant-Antihistamine-Analgesic": {
            "AMERISOURCE BERGEN DRUGS": {
              "GNP ALLERGY MULTI-SYMPTOM": "GNP ALLERGY MULTI-SYMPTOM",
              "GNP ALLERGY PLS SINUS HEADACHE": "GNP ALLERGY PLS SINUS HEADACHE",
              "GNP ALLERGY PLUS SEVERE SINUS": "GNP ALLERGY PLUS SEVERE SINUS",
              "GNP COLD RELIEF PLUS": "GNP COLD RELIEF PLUS",
              "GNP FLU & SEV COLD/COUGH NIGHT": "GNP FLU & SEV COLD/COUGH NIGHT",
              "GNP FLU & SORE THROAT": "GNP FLU & SORE THROAT",
              "GNP FLU RELIEF THERAPY NIGHT": "GNP FLU RELIEF THERAPY NIGHT",
              "GNP NIGHT TIME SINUS": "GNP NIGHT TIME SINUS",
              "GNP SINUS RELIEF CONGEST/PAIN": "GNP SINUS RELIEF CONGEST/PAIN",
              "GNP SINUS/HEADACHE": "GNP SINUS/HEADACHE"
            },
            "BAYER CONSUMER": {
              "ALKA-SELTZER PLUS COLD": "ALKA-SELTZER PLUS COLD",
              "CORICIDIN D COLD/FLU/SINUS": "CORICIDIN D COLD/FLU/SINUS",
              "DRIXORAL ALLERGY SINUS": "DRIXORAL ALLERGY SINUS",
              "DRIXORAL COLD/FLU": "DRIXORAL COLD/FLU"
            },
            "BERGEN BRUNSWIG": {
              "GNP ALLERGY & SINUS HEADACHE": "GNP ALLERGY & SINUS HEADACHE",
              "GNP ALLERGY MULTI-SYMPTOM": "GNP ALLERGY MULTI-SYMPTOM",
              "GNP SINUS CONG/PAIN DAY/NIGHT": "GNP SINUS CONG/PAIN DAY/NIGHT",
              "GNP SINUS CONGEST/PAIN NIGHT": "GNP SINUS CONGEST/PAIN NIGHT"
            },
            "CHAIN DRUG CONSORTIUM": {
              "ALLERGY & SINUS INTENSE ST": "ALLERGY & SINUS INTENSE ST",
              "ALLERGY MULTI-SYMPTOM": "ALLERGY MULTI-SYMPTOM",
              "ALLERGY RELIEF PLUS SINUS": "ALLERGY RELIEF PLUS SINUS",
              "CHILDRENS PLUS COLD & ALLERGY": "CHILDRENS PLUS COLD & ALLERGY",
              "COLD & FLU INTENSE STRENGTH": "COLD & FLU INTENSE STRENGTH",
              "COLD RELIEF PLUS": "COLD RELIEF PLUS",
              "COMPLETE SINUS RELIEF": "COMPLETE SINUS RELIEF",
              "FLU & SORE THROAT RELIEF": "FLU & SORE THROAT RELIEF",
              "FLU RELIEF THERAPY NIGHTTIME": "FLU RELIEF THERAPY NIGHTTIME",
              "INTENSE COLD/FLU MEDICINE": "INTENSE COLD/FLU MEDICINE",
              "NIGHT TIME SINUS/PAIN": "NIGHT TIME SINUS/PAIN",
              "NIGHT-TIME SINUS": "NIGHT-TIME SINUS",
              "NIGHTTIME SINUS & CONGESTION": "NIGHTTIME SINUS & CONGESTION",
              "NIGHTTIME SINUS/MULTI-SYMPTOM": "NIGHTTIME SINUS/MULTI-SYMPTOM",
              "SEVERE COLD": "SEVERE COLD",
              "SINUS CONGESTION/PAIN DAY/NGHT": "SINUS CONGESTION/PAIN DAY/NGHT"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "COLD RELIEF PLUS": "COLD RELIEF PLUS",
              "QC ALLERGY RELIEF MULTI-SYMPT": "QC ALLERGY RELIEF MULTI-SYMPT",
              "QC ALLERGY/SINUS HEADACHE": "QC ALLERGY/SINUS HEADACHE",
              "QC FLU RELIEF THERAPY NIGHT": "QC FLU RELIEF THERAPY NIGHT",
              "QC SEVERE ALLERGY RELIEF SINUS": "QC SEVERE ALLERGY RELIEF SINUS",
              "QC SEVERE COLD/COUGH NIGHTTIME": "QC SEVERE COLD/COUGH NIGHTTIME"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS COUGH & SEVERE COLD NIGHT": "CVS COUGH & SEVERE COLD NIGHT",
              "CVS FLU/SEVERE COLD NIGHTTIME": "CVS FLU/SEVERE COLD NIGHTTIME",
              "CVS SEV ALLERGY/SINUS HEADACHE": "CVS SEV ALLERGY/SINUS HEADACHE",
              "CVS SEVERE COLD/FLU NIGHTTIME": "CVS SEVERE COLD/FLU NIGHTTIME",
              "CVS SEVERE COUGH & COLD NIGHT": "CVS SEVERE COUGH & COLD NIGHT",
              "CVS SINUS CONGEST/PAIN DT/NT": "CVS SINUS CONGEST/PAIN DT/NT",
              "CVS SINUS PAIN/CONGEST NIGHT": "CVS SINUS PAIN/CONGEST NIGHT"
            },
            "DOMEL LABORATORIES": {
              "DOMETUSS-NR": "DOMETUSS-NR"
            },
            "EQUALINE": {
              "EQL ALLERGY MULTI-SYMPTOM": "EQL ALLERGY MULTI-SYMPTOM",
              "EQL CHILDRENS PAIN RELIEF/COLD": "EQL CHILDRENS PAIN RELIEF/COLD",
              "EQL EFFERVESCENT COLD RELIEF": "EQL EFFERVESCENT COLD RELIEF",
              "EQL EFFERVESCENT PLUS COLD": "EQL EFFERVESCENT PLUS COLD",
              "EQL FLU/COLD/COUGH NIGHT TIME": "EQL FLU/COLD/COUGH NIGHT TIME",
              "EQL SEVERE COLD PE": "EQL SEVERE COLD PE"
            },
            "GEISS DESTIN & DUNN": {
              "ALLERGY MULTI-SYMPTOM": "ALLERGY MULTI-SYMPTOM",
              "GOODSENSE ALLERGY MULTI-SYMPTM": "GOODSENSE ALLERGY MULTI-SYMPTM",
              "GOODSENSE ALLERGY PLUS SINUS": "GOODSENSE ALLERGY PLUS SINUS",
              "GOODSENSE COLD RELIEF": "GOODSENSE COLD RELIEF",
              "GOODSENSE FLU/COLD/COUGH/NIGHT": "GOODSENSE FLU/COLD/COUGH/NIGHT",
              "GOODSENSE NIGHTTIME SINUS": "GOODSENSE NIGHTTIME SINUS",
              "GOODSENSE SINUS CONGEST/PAIN": "GOODSENSE SINUS CONGEST/PAIN",
              "GOODSENSE SINUS/HEADACHE DT/NT": "GOODSENSE SINUS/HEADACHE DT/NT"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "ADVIL ALLERGY & CONGESTION": "ADVIL ALLERGY & CONGESTION",
              "ADVIL ALLERGY SINUS": "ADVIL ALLERGY SINUS",
              "ADVIL MULTI-SYMPTOM COLD & FLU": "ADVIL MULTI-SYMPTOM COLD & FLU",
              "COMTREX FLU THERAPY DAY/NIGHT": "COMTREX FLU THERAPY DAY/NIGHT",
              "COMTREX SEVERE COLD & SINUS": "COMTREX SEVERE COLD & SINUS",
              "DIMETAPP MULTISYMPTOM COLD/FLU": "DIMETAPP MULTISYMPTOM COLD/FLU",
              "DRISTAN COLD": "DRISTAN COLD",
              "ROBITUSSIN PEAK COLD MULTI-SYM": "ROBITUSSIN PEAK COLD MULTI-SYM",
              "ROBITUSSIN PEAK COLD NASAL RLF": "ROBITUSSIN PEAK COLD NASAL RLF",
              "ROBITUSSIN SEVERE NIGHTTIME": "ROBITUSSIN SEVERE NIGHTTIME",
              "THERAFLU COLD & SORE THROAT": "THERAFLU COLD & SORE THROAT",
              "THERAFLU EXPRESSMAX": "THERAFLU EXPRESSMAX",
              "THERAFLU EXPRESSMAX SEV CLD/CG": "THERAFLU EXPRESSMAX SEV CLD/CG",
              "THERAFLU FLU & SORE THROAT": "THERAFLU FLU & SORE THROAT",
              "THERAFLU POWERPODS SEVERE COLD": "THERAFLU POWERPODS SEVERE COLD",
              "THERAFLU SEVERE COLD NIGHTTIME": "THERAFLU SEVERE COLD NIGHTTIME",
              "THERAFLU SEVERE COLD/CGH NIGHT": "THERAFLU SEVERE COLD/CGH NIGHT",
              "THERAFLU SINUS & COLD": "THERAFLU SINUS & COLD",
              "THERAFLU WARMING RELIEF FLU": "THERAFLU WARMING RELIEF FLU",
              "THERAFLU WARMING RELIEF NIGHT": "THERAFLU WARMING RELIEF NIGHT",
              "THERAFLU WARMING RELIEF SINUS": "THERAFLU WARMING RELIEF SINUS"
            },
            "IRISYS": {
              "SCOT-TUSSIN": "SCOT-TUSSIN"
            },
            "LEADER BRAND PRODUCTS": {
              "ALLERGY MULTI-SYMPTOM": "ALLERGY MULTI-SYMPTOM",
              "SEVERE COLD & COUGH NIGHTTIME": "SEVERE COLD & COUGH NIGHTTIME",
              "SEVERE COLD/COUGH": "SEVERE COLD/COUGH"
            },
            "MCKESSON": {
              "HM SEVERE COLD COUGH & FLU": "HM SEVERE COLD COUGH & FLU"
            },
            "MCKESSON SUNMARK": {
              "SM ALLERGY MULTI-SYMPTOM": "SM ALLERGY MULTI-SYMPTOM",
              "SM FLU RELIEF THERAPY NIGHT": "SM FLU RELIEF THERAPY NIGHT"
            },
            "MEDA CONSUMER HEALTHCARE": {
              "CONTAC COLD+FLU MAX ST": "CONTAC COLD+FLU MAX ST",
              "CONTAC COLD/FLU DAY & NIGHT": "CONTAC COLD/FLU DAY & NIGHT"
            },
            "MEDIQUE PRODUCTS": {
              "MEDICIDIN-D": "MEDICIDIN-D",
              "ONSET FORTE": "ONSET FORTE",
              "VALIHIST": "VALIHIST"
            },
            "P & G HEALTH": {
              "QLEARQUIL NIGHTTIME SINUS": "QLEARQUIL NIGHTTIME SINUS",
              "VICKS SINEX DAYQUIL/NYQUIL": "VICKS SINEX DAYQUIL/NYQUIL",
              "VICKS SINEX DAYTIME/NIGHTTIME": "VICKS SINEX DAYTIME/NIGHTTIME",
              "VICKS SINEX NIGHTTIME": "VICKS SINEX NIGHTTIME"
            },
            "PUBLIX SUPER MARKETS INC.": {
              "PX ALLERGY SINUS PE": "PX ALLERGY SINUS PE"
            },
            "RECKITT BENCKISER": {
              "DELSYM CGH/CLD NIGHTTIME CHILD": "DELSYM CGH/CLD NIGHTTIME CHILD",
              "DELSYM COUGH/COLD NIGHT TIME": "DELSYM COUGH/COLD NIGHT TIME",
              "MUCINEX CHILDRENS NIGHT TIME": "MUCINEX CHILDRENS NIGHT TIME",
              "MUCINEX FAST-MAX COLD FLU NGHT": "MUCINEX FAST-MAX COLD FLU NGHT",
              "MUCINEX MS COLD NIGHT CHILDREN": "MUCINEX MS COLD NIGHT CHILDREN",
              "MUCINEX SINUS-MAX NIGHT TIME": "MUCINEX SINUS-MAX NIGHT TIME"
            },
            "REESE PHARMACEUTICAL": {
              "COLD CONTROL PE COLD/FLU MED": "COLD CONTROL PE COLD/FLU MED",
              "SINADRIN PE COMPLETE SINUS REL": "SINADRIN PE COMPLETE SINUS REL",
              "SUPER COLD TABS": "SUPER COLD TABS",
              "SUPER STRENGTH SINADRIN": "SUPER STRENGTH SINADRIN"
            },
            "RITE AID CORPORATION": {
              "RA ALLERGY MULTI-SYMPTOM": "RA ALLERGY MULTI-SYMPTOM",
              "RA ALLERGY MULTI-SYMPTOM NIGHT": "RA ALLERGY MULTI-SYMPTOM NIGHT",
              "RA SEVERE ALLERGY PLUS SINUS": "RA SEVERE ALLERGY PLUS SINUS",
              "RA SEVERE COLD/SINUS RELIEF PE": "RA SEVERE COLD/SINUS RELIEF PE",
              "RA SINUS CONGST/PAIN DAY/NIGHT": "RA SINUS CONGST/PAIN DAY/NIGHT"
            },
            "SCOT-TUSSIN": {
              "SCOT-TUSSIN": "SCOT-TUSSIN",
              "SCOT-TUSSIN SUGAR-FREE": "SCOT-TUSSIN SUGAR-FREE"
            },
            "SELECT BRAND": {
              "SB ALLERGY & COLD PE": "SB ALLERGY & COLD PE",
              "SB ALLERGY MULTI-SYMPTOM": "SB ALLERGY MULTI-SYMPTOM",
              "SB FLU RELIEF THERAPY": "SB FLU RELIEF THERAPY",
              "SB FLU RELIEF THERAPY NIGHT": "SB FLU RELIEF THERAPY NIGHT",
              "SB NIGHTTIME SINUS MULTI-SYMPT": "SB NIGHTTIME SINUS MULTI-SYMPT",
              "SB SEVERE COLD PE": "SB SEVERE COLD PE",
              "SB SINUS CONGEST/PAIN DAY/NGHT": "SB SINUS CONGEST/PAIN DAY/NGHT",
              "SB SINUS CONGESTION/PAIN NIGHT": "SB SINUS CONGESTION/PAIN NIGHT"
            },
            "SYENZ LABORATORY": {
              "HERBIOMED ALLERGY COLD & SINUS": "HERBIOMED ALLERGY COLD & SINUS"
            },
            "TARGET": {
              "TGT FLU/SEVERE COLD/COUGH RLF": "TGT FLU/SEVERE COLD/COUGH RLF",
              "TGT PE MULTI-SYM SEVERE COLD": "TGT PE MULTI-SYM SEVERE COLD",
              "TGT SINUS CONGESTION/PAIN": "TGT SINUS CONGESTION/PAIN"
            },
            "TOPCO": {
              "ALLERGY MULTI-SYMPTOM": "ALLERGY MULTI-SYMPTOM",
              "EFFERVESCENT COLD RELIEF": "EFFERVESCENT COLD RELIEF",
              "FLU & SORE THROAT": "FLU & SORE THROAT",
              "SINUS CONGESTION/PAIN NIGHT": "SINUS CONGESTION/PAIN NIGHT"
            },
            "U S PHARMACEUTICAL": {
              "NOREL AD": "NOREL AD"
            },
            "WAL-MART": {
              "EQ ALLERGY & SINUS HEADACHE": "EQ ALLERGY & SINUS HEADACHE",
              "EQ ALLERGY RELIEF": "EQ ALLERGY RELIEF",
              "EQ ALLERGY/SINUS HEADACHE": "EQ ALLERGY/SINUS HEADACHE",
              "EQ DAYTIME/NITETIME SINUS": "EQ DAYTIME/NITETIME SINUS",
              "EQ EFFERVESCENT COLD RELIEF": "EQ EFFERVESCENT COLD RELIEF",
              "EQ FLU & SEVERE COLD & COUGH": "EQ FLU & SEVERE COLD & COUGH",
              "EQ SEVERE ALLERGY & SINUS": "EQ SEVERE ALLERGY & SINUS"
            },
            "WALGREENS": {
              "ALLERGY MULTI-SYMPTOM DAY/NGHT": "ALLERGY MULTI-SYMPTOM DAY/NGHT",
              "ALLERGY MULTI-SYMPTOM DAYTIME": "ALLERGY MULTI-SYMPTOM DAYTIME",
              "ALLERGY MULTI-SYMPTOM NIGHT": "ALLERGY MULTI-SYMPTOM NIGHT",
              "CHILDRENS PLUS COLD": "CHILDRENS PLUS COLD",
              "COLD & FLU RELIEF NIGHTTIME": "COLD & FLU RELIEF NIGHTTIME",
              "COLD RELIEF PLUS": "COLD RELIEF PLUS",
              "NIGHTTIME COLD & FLU MAX STR": "NIGHTTIME COLD & FLU MAX STR",
              "SINUS & CONGESTION DAY/NIGHT": "SINUS & CONGESTION DAY/NIGHT",
              "SINUS DAYTIME/NIGHTTIME": "SINUS DAYTIME/NIGHTTIME",
              "WAL-DRYL ALLRGY/SINUS HEADACHE": "WAL-DRYL ALLRGY/SINUS HEADACHE",
              "WAL-FLU COLD & SORE THROAT": "WAL-FLU COLD & SORE THROAT",
              "WAL-FLU SEVERE COLD NIGHT TIME": "WAL-FLU SEVERE COLD NIGHT TIME",
              "WAL-FLU SEVERE COLD NIGHTTIME": "WAL-FLU SEVERE COLD NIGHTTIME",
              "WAL-FLU SEVERE COLD/CGH NIGHT": "WAL-FLU SEVERE COLD/CGH NIGHT",
              "WAL-PHED PE NIGHTTIME COLD": "WAL-PHED PE NIGHTTIME COLD",
              "WAL-PHED PE SEVERE COLD": "WAL-PHED PE SEVERE COLD"
            }
          },
          "Decongestant-Antihistamine-Analgesic w/ Expectorant": {
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS SINUS RELIEF DAY/NIGHT": "CVS SINUS RELIEF DAY/NIGHT"
            },
            "RECKITT BENCKISER": {
              "MUCINEX SINUS-MAX DAY/NIGHT": "MUCINEX SINUS-MAX DAY/NIGHT"
            },
            "RITE AID CORPORATION": {
              "RA SINUS RELIEF D/N": "RA SINUS RELIEF D/N"
            }
          },
          "Non-Narc Antitussive-Analgesic": {
            "AURORA HEALTHCARE": {
              "DAYTIME COLD MEDICINE": "DAYTIME COLD MEDICINE"
            },
            "BAYER CONSUMER": {
              "DRIXORAL COUGH/SORE THROAT": "DRIXORAL COUGH/SORE THROAT"
            },
            "BERGEN BRUNSWIG": {
              "GNP CHILDRENS PLUS COUGH/SORE": "GNP CHILDRENS PLUS COUGH/SORE"
            },
            "CHAIN DRUG CONSORTIUM": {
              "COUGH & SORE THROAT DAY TIME": "COUGH & SORE THROAT DAY TIME"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS COUGH & SORE THROAT CHILD": "CVS COUGH & SORE THROAT CHILD"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "ROBITUSSIN SEVERE CGH/SR THRT": "ROBITUSSIN SEVERE CGH/SR THRT",
              "TRIAMINIC COUGH & SORE THROAT": "TRIAMINIC COUGH & SORE THROAT",
              "TRIAMINIC COUGH/SORE THROAT": "TRIAMINIC COUGH/SORE THROAT"
            },
            "MCKESSON SUNMARK": {
              "SM COUGH/SORE THROAT DAYTIME": "SM COUGH/SORE THROAT DAYTIME"
            },
            "MCNEIL CONSUMER": {
              "TYLENOL CHILDRENS COLD/COUGH": "TYLENOL CHILDRENS COLD/COUGH"
            },
            "P & G HEALTH": {
              "VICKS DAYQUIL HBP COLD & FLU": "VICKS DAYQUIL HBP COLD & FLU"
            },
            "RECKITT BENCKISER": {
              "DELSYM COUGH + SORE THROAT": "DELSYM COUGH + SORE THROAT"
            },
            "TOPCO": {
              "COUGH & SORE THROAT DAY": "COUGH & SORE THROAT DAY"
            },
            "WALGREENS": {
              "COLD & COUGH DAYTIME": "COLD & COUGH DAYTIME"
            }
          },
          "Non-Narc Antitussive-Antihistamine": {
            "A-S MEDICATION SOLUTIONS": {
              "PROMETHAZINE-DM": "PROMETHAZINE-DM"
            },
            "AIDAREX PHARMACEUTICALS": {
              "PROMETHAZINE-DM": "PROMETHAZINE-DM"
            },
            "AMERICAN SALES COMPANY": {
              "NITE TIME COUGH": "NITE TIME COUGH"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP TUSSIN DM MAX NIGHTTIME": "GNP TUSSIN DM MAX NIGHTTIME"
            },
            "AMNEAL PHARMACEUTICALS": {
              "PROMETHAZINE-DM": "PROMETHAZINE-DM"
            },
            "ATLANTIC BIOLOGICALS": {
              "PROMETHAZINE-DM": "PROMETHAZINE-DM"
            },
            "BAYER CONSUMER": {
              "CORICIDIN HBP COUGH/COLD": "CORICIDIN HBP COUGH/COLD"
            },
            "BERGEN BRUNSWIG": {
              "GNP NIGHT TIME COUGH": "GNP NIGHT TIME COUGH"
            },
            "BRYANT RANCH PREPACK": {
              "PROMETHAZINE-DM": "PROMETHAZINE-DM"
            },
            "CAPITAL PHARMACEUTICAL": {
              "CAPRON DM": "CAPRON DM",
              "CAPRON DMT": "CAPRON DMT"
            },
            "CENTURION LABS": {
              "NINJACOF": "NINJACOF"
            },
            "CHAIN DRUG CONSORTIUM": {
              "NIGHT-TIME COUGH": "NIGHT-TIME COUGH"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC COUGH/COLD HBP": "QC COUGH/COLD HBP",
              "QC NIGHTTIME COUGH": "QC NIGHTTIME COUGH"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS COUGH & COLD HBP": "CVS COUGH & COLD HBP",
              "CVS DAYTIME/NIGHTTIME COUGH": "CVS DAYTIME/NIGHTTIME COUGH",
              "CVS NIGHTTIME COUGH": "CVS NIGHTTIME COUGH",
              "CVS NIGHTTIME TUSSIN DM": "CVS NIGHTTIME TUSSIN DM",
              "CVS TRIACTING COUGH/RUNNY NOSE": "CVS TRIACTING COUGH/RUNNY NOSE"
            },
            "DIRECT RX": {
              "PROMETHAZINE-DM": "PROMETHAZINE-DM"
            },
            "EQUALINE": {
              "EQL COUGH & COLD RELIEF HBP": "EQL COUGH & COLD RELIEF HBP",
              "EQL NIGHT TIME COUGH": "EQL NIGHT TIME COUGH",
              "EQL NIGHTTIME COUGH RELIEF": "EQL NIGHTTIME COUGH RELIEF"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "DIMETAPP LONG ACT COUGH/COLD": "DIMETAPP LONG ACT COUGH/COLD",
              "ROBITUSSIN CHILD COUGH/COLD LA": "ROBITUSSIN CHILD COUGH/COLD LA",
              "ROBITUSSIN NIGHTTIME COUGH": "ROBITUSSIN NIGHTTIME COUGH",
              "ROBITUSSIN NIGHTTIME COUGH DM": "ROBITUSSIN NIGHTTIME COUGH DM",
              "TRIAMINIC COUGH/RUNNY NOSE": "TRIAMINIC COUGH/RUNNY NOSE"
            },
            "GM PHARMACEUTICALS": {
              "DAY CLEAR ALLERGY/COUGH": "DAY CLEAR ALLERGY/COUGH",
              "DAYCLEAR ALLERGY RELIEF": "DAYCLEAR ALLERGY RELIEF",
              "VANACOF AC": "VANACOF AC",
              "VANACOF-8": "VANACOF-8",
              "VANATAB AC": "VANATAB AC"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "PROMETHAZINE-DM": "PROMETHAZINE-DM"
            },
            "HI-TECH": {
              "PROMETHAZINE-DM": "PROMETHAZINE-DM"
            },
            "IRISYS": {
              "SCOT-TUSSIN DM": "SCOT-TUSSIN DM"
            },
            "KRAMER LABS": {
              "SAFE TUSSIN PM": "SAFE TUSSIN PM"
            },
            "LEADER BRAND PRODUCTS": {
              "COUGH & COLD HBP": "COUGH & COLD HBP",
              "NIGHTTIME COUGH": "NIGHTTIME COUGH",
              "NITE-TIME COUGH": "NITE-TIME COUGH"
            },
            "MAJOR PHARMACEUTICALS": {
              "COUGH & COLD": "COUGH & COLD"
            },
            "MCKESSON SUNMARK": {
              "SM COUGH/RUNNY NOSE CHILDRENS": "SM COUGH/RUNNY NOSE CHILDRENS",
              "SM NITE TIME COUGH": "SM NITE TIME COUGH"
            },
            "MEDVANTX": {
              "PROMETHAZINE-DM": "PROMETHAZINE-DM"
            },
            "MORTON GROVE PHARMACEUTICALS": {
              "PROMETHAZINE-DM": "PROMETHAZINE-DM"
            },
            "P & G HEALTH": {
              "VICKS DAYQUIL/NYQUIL COUGH": "VICKS DAYQUIL/NYQUIL COUGH",
              "VICKS NYQUIL CHILDRENS CLD/CGH": "VICKS NYQUIL CHILDRENS CLD/CGH",
              "VICKS NYQUIL COUGH": "VICKS NYQUIL COUGH"
            },
            "PAR PHARMACEUTICALS": {
              "PROMETHAZINE-DM": "PROMETHAZINE-DM"
            },
            "PERRIGO PHARMACEUTICALS": {
              "GOODSENSE NIGHT TIME COUGH": "GOODSENSE NIGHT TIME COUGH"
            },
            "POLY PHARMACEUTICALS": {
              "POLY-HIST PD": "POLY-HIST PD"
            },
            "PROFICIENT RX": {
              "PROMETHAZINE-DM": "PROMETHAZINE-DM"
            },
            "PUBLIX SUPER MARKETS INC.": {
              "PX NITETIME COUGH": "PX NITETIME COUGH"
            },
            "QUALITY CARE": {
              "PROMETHAZINE-DM": "PROMETHAZINE-DM"
            },
            "R A MCNEIL": {
              "CHLO HIST": "CHLO HIST"
            },
            "RITE AID CORPORATION": {
              "RA NIGHTTIME COUGH RELIEF": "RA NIGHTTIME COUGH RELIEF",
              "RA NITE TIME COUGH": "RA NITE TIME COUGH",
              "RA TUSSIN NIGHTTIME COUGH DM": "RA TUSSIN NIGHTTIME COUGH DM"
            },
            "RXCHANGE CO": {
              "PROMETHAZINE-DM": "PROMETHAZINE-DM"
            },
            "SELECT BRAND": {
              "SB COLD & COUGH HBP": "SB COLD & COUGH HBP",
              "SB NIGHTTIME COUGH": "SB NIGHTTIME COUGH"
            },
            "SLATE RUN PHARMACEUTICALS": {
              "PROMETHAZINE-DM": "PROMETHAZINE-DM"
            },
            "UNIT DOSE SERVICES": {
              "PROMETHAZINE-DM": "PROMETHAZINE-DM"
            },
            "WAL-MART": {
              "EQ NIGHT TIME COUGH": "EQ NIGHT TIME COUGH"
            },
            "WALGREENS": {
              "COUGH DAYTIME/NIGHTTIME": "COUGH DAYTIME/NIGHTTIME",
              "NIGHTTIME COUGH": "NIGHTTIME COUGH"
            }
          },
          "Non-Narc Antitussive-Decongestant": {
            "ADVANTICE HEALTH": {
              "PEDIACARE CHILDRENS MULTI-SYMP": "PEDIACARE CHILDRENS MULTI-SYMP"
            },
            "BERGEN BRUNSWIG": {
              "GNP TRIACTING DAY CHILDRENS": "GNP TRIACTING DAY CHILDRENS"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC TRIACTING DAYTIME CHILDRENS": "QC TRIACTING DAYTIME CHILDRENS"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS COLD & COUGH CHILDRENS": "CVS COLD & COUGH CHILDRENS"
            },
            "EQUALINE": {
              "EQL INFANTS DECONGESTANT/COUGH": "EQL INFANTS DECONGESTANT/COUGH",
              "EQL TUSSIN COUGH/COLD": "EQL TUSSIN COUGH/COLD",
              "EQL TUSSIN PED COUGH/COLD": "EQL TUSSIN PED COUGH/COLD"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "TRIAMINIC COLD/COUGH DAY TIME": "TRIAMINIC COLD/COUGH DAY TIME"
            },
            "LASER PHARMACEUTICALS": {
              "RONDEC-D": "RONDEC-D"
            },
            "MCKESSON SUNMARK": {
              "SM DAYTIME COLD & COUGH CHILD": "SM DAYTIME COLD & COUGH CHILD"
            },
            "MCNEIL CONSUMER": {
              "SUDAFED PE COLD & COUGH CHILD": "SUDAFED PE COLD & COUGH CHILD"
            },
            "MCR/AMERICAN PHARMACEUTICAL": {
              "MAXI-TUSS JR": "MAXI-TUSS JR"
            },
            "MEDICINE SHOPPE": {
              "COUGH SYRUP D": "COUGH SYRUP D",
              "MEDI-TUSSIN MAX STRENGTH": "MEDI-TUSSIN MAX STRENGTH"
            },
            "MEDTECH": {
              "PEDIA CARE MULTI-SYMPTOM COLD": "PEDIA CARE MULTI-SYMPTOM COLD"
            },
            "TOPCO": {
              "TRIACTING DAY TIME COLD/COUGH": "TRIACTING DAY TIME COLD/COUGH"
            },
            "WALGREENS": {
              "DAYTIME COLD & COUGH CHILDRENS": "DAYTIME COLD & COUGH CHILDRENS"
            }
          },
          "Non-Narc Antitussive-Decongestant-Antihistamine": {
            "ACELLA PHARMACEUTICALS": {
              "PSEUDOEPH-BROMPHEN-DM": "PSEUDOEPH-BROMPHEN-DM"
            },
            "ADVANCED GENERIC": {
              "BIO T PRES-B": "BIO T PRES-B",
              "BIO-DTUSS DMX": "BIO-DTUSS DMX",
              "BIO-RYTUSS": "BIO-RYTUSS",
              "BIONATUSS DXP": "BIONATUSS DXP"
            },
            "AG MARIN PHARMACEUTICALS": {
              "NEOTUSS PLUS": "NEOTUSS PLUS"
            },
            "ALLEGIS PHARMACEUTICALS": {
              "AP-HIST DM": "AP-HIST DM",
              "HISTEX-DM": "HISTEX-DM"
            },
            "AMBI PHARMACEUTICALS": {
              "AMBI 12.5CPD/1DCPM/30PSE": "AMBI 12.5CPD/1DCPM/30PSE"
            },
            "AMERICAN SALES COMPANY": {
              "COLD & COUGH CHILDRENS": "COLD & COUGH CHILDRENS"
            },
            "BALLAY PHARMACEUTICALS": {
              "BALAMINE DM": "BALAMINE DM"
            },
            "BERGEN BRUNSWIG": {
              "GNP COLD/COUGH CHILDRENS": "GNP COLD/COUGH CHILDRENS"
            },
            "BRECKENRIDGE": {
              "PSEUDOEPH-BROMPHEN-DM": "PSEUDOEPH-BROMPHEN-DM"
            },
            "BUREL PHARMACEUTICALS": {
              "RELCOF DM": "RELCOF DM"
            },
            "CAPELLON PHARMACEUTICALS": {
              "RESCON DM": "RESCON DM"
            },
            "CARWIN ASSOCIATES": {
              "ATUSS DA": "ATUSS DA"
            },
            "CENTURION LABS": {
              "NINJACOF-D": "NINJACOF-D"
            },
            "CHAIN DRUG CONSORTIUM": {
              "COLD/COUGH DM CHILDRENS": "COLD/COUGH DM CHILDRENS"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC DIBROMM CHILDRENS COLD&CGH": "QC DIBROMM CHILDRENS COLD&CGH"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "BROMFED DM": "BROMFED DM",
              "NOHIST-DM": "NOHIST-DM"
            },
            "CURRAX PHARMACEUTICALS": {
              "BROVEX PEB DM": "BROVEX PEB DM"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS COLD & COUGH CHILDRENS": "CVS COLD & COUGH CHILDRENS",
              "CVS COLD & COUGH DM CHILDRENS": "CVS COLD & COUGH DM CHILDRENS",
              "CVS COLD/COUGH DM CHILDRENS": "CVS COLD/COUGH DM CHILDRENS"
            },
            "DELIZ PHARMACEUTICAL CORP": {
              "DELTUSS DMX": "DELTUSS DMX"
            },
            "EQUALINE": {
              "EQL COLD/COUGH": "EQL COLD/COUGH",
              "EQL TRIACTING COLD/COUGH": "EQL TRIACTING COLD/COUGH"
            },
            "EYWA PHARMA": {
              "PSEUDOEPH-BROMPHEN-DM": "PSEUDOEPH-BROMPHEN-DM"
            },
            "GIL PHARMACEUTICAL CORP.": {
              "CARBAPHEN 12": "CARBAPHEN 12",
              "CARBAPHEN 12 PED": "CARBAPHEN 12 PED",
              "CARBAPHEN CH": "CARBAPHEN CH",
              "CARBAPHEN PED CH": "CARBAPHEN PED CH",
              "EXAPHEN CH": "EXAPHEN CH",
              "GILTUSS ALLERGY CGH&CONG CHILD": "GILTUSS ALLERGY CGH&CONG CHILD",
              "GILTUSS ALLERGY COUGH & CONGES": "GILTUSS ALLERGY COUGH & CONGES",
              "GILTUSS COUGH ALLERGY & SINUS": "GILTUSS COUGH ALLERGY & SINUS",
              "PHENAGIL CH": "PHENAGIL CH"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "DIMETAPP COLD RELIEF CHILDRENS": "DIMETAPP COLD RELIEF CHILDRENS",
              "DIMETAPP DM COLD/COUGH": "DIMETAPP DM COLD/COUGH",
              "THERAFLU COLD & COUGH": "THERAFLU COLD & COUGH",
              "TRIAMINIC COLD/COUGH": "TRIAMINIC COLD/COUGH"
            },
            "GLENDALE INC": {
              "GLENMAX PEB DM": "GLENMAX PEB DM",
              "GLENMAX PEB DM FORTE": "GLENMAX PEB DM FORTE",
              "GLENTUSS": "GLENTUSS"
            },
            "GM PHARMACEUTICALS": {
              "VANACOF": "VANACOF"
            },
            "KEY THERAPEUTICS": {
              "DR MANZANILLA DM": "DR MANZANILLA DM",
              "MICLARA DM": "MICLARA DM"
            },
            "KRAMER-NOVIS": {
              "ABANATUSS PED": "ABANATUSS PED",
              "ABATUSS DMX": "ABATUSS DMX",
              "G-P-TUSS DXP": "G-P-TUSS DXP",
              "GENCONTUSS": "GENCONTUSS",
              "PRESGEN B": "PRESGEN B",
              "SUPRESS A PEDIATRIC": "SUPRESS A PEDIATRIC",
              "TUSSI-PRES B": "TUSSI-PRES B"
            },
            "KROGER COMPANY": {
              "COLD/COUGH CHILDRENS": "COLD/COUGH CHILDRENS"
            },
            "LANNETT": {
              "PSEUDOEPH-BROMPHEN-DM": "PSEUDOEPH-BROMPHEN-DM"
            },
            "LARKEN LABORATORIES, INC.": {
              "ENDACOF-DM": "ENDACOF-DM",
              "LOHIST-DM": "LOHIST-DM",
              "NOHIST-DM": "NOHIST-DM"
            },
            "LASER PHARMACEUTICALS": {
              "DONATUSSIN": "DONATUSSIN"
            },
            "LEADER BRAND PRODUCTS": {
              "COLD & COUGH CHILDRENS": "COLD & COUGH CHILDRENS",
              "COLD/COUGH DM CHILDRENS": "COLD/COUGH DM CHILDRENS"
            },
            "MAGNA PHARMACEUTICALS, INC": {
              "ATUSS DA": "ATUSS DA",
              "Y-TUSS": "Y-TUSS"
            },
            "MAJOR PHARMACEUTICALS": {
              "DIMAPHEN DM COLD/COUGH": "DIMAPHEN DM COLD/COUGH",
              "PEDIA RELIEF COUGH/COLD": "PEDIA RELIEF COUGH/COLD"
            },
            "MAYNE PHARMA": {
              "PSEUDOEPH-BROMPHEN-DM": "PSEUDOEPH-BROMPHEN-DM"
            },
            "MCKESSON": {
              "HM COLD & COUGH CHILDRENS": "HM COLD & COUGH CHILDRENS"
            },
            "MCKESSON SUNMARK": {
              "SM COLD & COUGH DM CHILDRENS": "SM COLD & COUGH DM CHILDRENS"
            },
            "MCR/AMERICAN PHARMACEUTICAL": {
              "MAXI-TUSS DM": "MAXI-TUSS DM",
              "MAXICHLOR PEH DM": "MAXICHLOR PEH DM"
            },
            "MEDICINE SHOPPE": {
              "COLD/COUGH DM CHILDRENS": "COLD/COUGH DM CHILDRENS"
            },
            "METHOD PHARMACEUTICALS": {
              "M-HIST DM": "M-HIST DM"
            },
            "MORTON GROVE PHARMACEUTICALS": {
              "BROMFED DM": "BROMFED DM",
              "PSEUDOEPH-BROMPHEN-DM": "PSEUDOEPH-BROMPHEN-DM"
            },
            "NIVAGEN PHARMACEUTICALS": {
              "NIVA-HIST DM": "NIVA-HIST DM"
            },
            "OAKHURST": {
              "FATHER JOHNS MEDICINE PLUS": "FATHER JOHNS MEDICINE PLUS"
            },
            "OKENDPHARMA": {
              "RYCONTUSS": "RYCONTUSS"
            },
            "PAR PHARMACEUTICAL": {
              "PSEUDOEPH-BROMPHEN-DM": "PSEUDOEPH-BROMPHEN-DM"
            },
            "PAR PHARMACEUTICALS": {
              "CODITUSS DM": "CODITUSS DM",
              "Q-TAPP DM": "Q-TAPP DM"
            },
            "PERRIGO": {
              "COLD/COUGH CHILDRENS": "COLD/COUGH CHILDRENS",
              "PSEUDOEPH-BROMPHEN-DM": "PSEUDOEPH-BROMPHEN-DM"
            },
            "POLY PHARMACEUTICALS": {
              "ALAHIST CF": "ALAHIST CF",
              "ALAHIST DM": "ALAHIST DM",
              "POLY-HIST DM": "POLY-HIST DM",
              "POLYTUSSIN DM": "POLYTUSSIN DM"
            },
            "PORTAL PHARMACEUTICALS": {
              "BRONKIDS": "BRONKIDS"
            },
            "PREFERRED PHARMACEUTICALS": {
              "DIMAPHEN DM COLD/COUGH": "DIMAPHEN DM COLD/COUGH",
              "PEDIA RELIEF COUGH/COLD": "PEDIA RELIEF COUGH/COLD",
              "PSEUDOEPH-BROMPHEN-DM": "PSEUDOEPH-BROMPHEN-DM"
            },
            "PRO-PHARMA LLC": {
              "PRO-CHLO": "PRO-CHLO"
            },
            "PROFICIENT RX": {
              "PSEUDOEPH-BROMPHEN-DM": "PSEUDOEPH-BROMPHEN-DM"
            },
            "PUBLIX SUPER MARKETS INC.": {
              "PX DIBROMM DM COLD/COUGH CHILD": "PX DIBROMM DM COLD/COUGH CHILD"
            },
            "R A MCNEIL": {
              "CHLO TUSS": "CHLO TUSS",
              "M-END DMX": "M-END DMX"
            },
            "RITE AID CORPORATION": {
              "RA COLD & COUGH CHILDRENS": "RA COLD & COUGH CHILDRENS",
              "RA COLD/COUGH DM": "RA COLD/COUGH DM"
            },
            "RUGBY LABORATORIES": {
              "KIDKARE COUGH/COLD": "KIDKARE COUGH/COLD"
            },
            "SALERNO PHARMACEUTICALS": {
              "TGQ 15DM/5PEH/2CPM": "TGQ 15DM/5PEH/2CPM",
              "TGQ 30PSE/3BRM/15DM": "TGQ 30PSE/3BRM/15DM",
              "TGQ 7.5PEH/4BRM/15DM": "TGQ 7.5PEH/4BRM/15DM"
            },
            "SALLUS LABORATORIES": {
              "LORTUSS DM": "LORTUSS DM"
            },
            "SELECT BRAND": {
              "SB COLD & COUGH DM CHILDRENS": "SB COLD & COUGH DM CHILDRENS"
            },
            "SEYER PHARMATEC": {
              "PANATUSS PED": "PANATUSS PED",
              "PANATUSS PED DROPS": "PANATUSS PED DROPS"
            },
            "SILARX": {
              "BROTAPP DM": "BROTAPP DM",
              "PEDIATRIC COUGH/COLD": "PEDIATRIC COUGH/COLD"
            },
            "TARGET": {
              "TGT COLD/COUGH CHILDRENS": "TGT COLD/COUGH CHILDRENS"
            },
            "TARO": {
              "PSEUDOEPH-BROMPHEN-DM": "PSEUDOEPH-BROMPHEN-DM"
            },
            "TOPCO": {
              "COLD/COUGH CHILDRENS": "COLD/COUGH CHILDRENS"
            },
            "U S PHARMACEUTICAL": {
              "NOREL CS": "NOREL CS"
            },
            "WAL-MART": {
              "EQ COLD/COUGH DM CHILDRENS": "EQ COLD/COUGH DM CHILDRENS"
            },
            "WALGREENS": {
              "COLD & COUGH DAY/NIGHT CHILD": "COLD & COUGH DAY/NIGHT CHILD",
              "WAL-TAP DM COLD/COUGH": "WAL-TAP DM COLD/COUGH"
            },
            "WOMEN'S CHOICE PHARMACEUTICALS": {
              "ED A-HIST DM": "ED A-HIST DM",
              "ED-A-HIST DM": "ED-A-HIST DM",
              "RYNEX DM": "RYNEX DM"
            }
          },
          "Non-Narc Antitussive-Decongestant-Antihistamine-Analg": {
            "AMERICAN SALES COMPANY": {
              "CHILDRENS PLUS MULTI-SYMPT CLD": "CHILDRENS PLUS MULTI-SYMPT CLD",
              "COLD MULTI-SYMPTOM NIGHTTIME": "COLD MULTI-SYMPTOM NIGHTTIME",
              "DAYTIME/NITE TIME COLD/FLU": "DAYTIME/NITE TIME COLD/FLU"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP CHILDRENS PLUS MULTI-SYMPT": "GNP CHILDRENS PLUS MULTI-SYMPT",
              "GNP COLD MAX DAY/NIGHT": "GNP COLD MAX DAY/NIGHT",
              "GNP COLD/HEAD CONGESTION": "GNP COLD/HEAD CONGESTION",
              "GNP MULTI-SYMPTOM COLD NIGHT": "GNP MULTI-SYMPTOM COLD NIGHT",
              "GNP TUSSIN NIGHT TIME": "GNP TUSSIN NIGHT TIME"
            },
            "BAYER CONSUMER": {
              "ALKA-SELTZER PLS ALLERGY & CGH": "ALKA-SELTZER PLS ALLERGY & CGH",
              "ALKA-SELTZER PLS NIGHT CLD/FLU": "ALKA-SELTZER PLS NIGHT CLD/FLU",
              "ALKA-SELTZER PLUS COLD & COUGH": "ALKA-SELTZER PLUS COLD & COUGH",
              "ALKA-SELTZER PLUS COLD & FLU": "ALKA-SELTZER PLUS COLD & FLU",
              "ALKA-SELTZER PLUS DAY/NIGHT": "ALKA-SELTZER PLUS DAY/NIGHT",
              "ALKA-SELTZER PLUS NIGHT COLD": "ALKA-SELTZER PLUS NIGHT COLD",
              "ALKA-SELTZER PLUS-D SINUS/COLD": "ALKA-SELTZER PLUS-D SINUS/COLD"
            },
            "BERGEN BRUNSWIG": {
              "GNP CHILDRENS PAIN RELIEF/COLD": "GNP CHILDRENS PAIN RELIEF/COLD",
              "GNP COLD HEAD CONGEST DAY/NGHT": "GNP COLD HEAD CONGEST DAY/NGHT",
              "GNP COLD HEAD CONGESTION": "GNP COLD HEAD CONGESTION",
              "GNP COLD MULTI-SYMPT DAY/NIGHT": "GNP COLD MULTI-SYMPT DAY/NIGHT",
              "GNP COLD MULTI-SYMPTOM NIGHT": "GNP COLD MULTI-SYMPTOM NIGHT"
            },
            "CHAIN DRUG CONSORTIUM": {
              "CHILDRENS PLUS MULTI-SYMPT CLD": "CHILDRENS PLUS MULTI-SYMPT CLD",
              "COLD HEAD CONGESTION NIGHTTIME": "COLD HEAD CONGESTION NIGHTTIME",
              "COLD MULTI-SYMPTOM DAY/NIGHT": "COLD MULTI-SYMPTOM DAY/NIGHT",
              "COLD MULTI-SYMPTOM NIGHTTIME": "COLD MULTI-SYMPTOM NIGHTTIME",
              "COLD/FLU RELIEF DAY/NIGHT": "COLD/FLU RELIEF DAY/NIGHT",
              "DAYTIME/NIGHTTIME": "DAYTIME/NIGHTTIME",
              "DAYTIME/NIGHTTIME COLD/FLU": "DAYTIME/NIGHTTIME COLD/FLU",
              "DAYTIME/NIGHTTIME RELIEF PLUS": "DAYTIME/NIGHTTIME RELIEF PLUS",
              "NIGHTTIME COLD/FLU RELIEF PLUS": "NIGHTTIME COLD/FLU RELIEF PLUS"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC COLD HEAD CONGESTION NIGHT": "QC COLD HEAD CONGESTION NIGHT",
              "QC COLD MULTI-SYMPTOM NIGHT": "QC COLD MULTI-SYMPTOM NIGHT",
              "QC COLD RELIEF PLUS MULTI-SYMP": "QC COLD RELIEF PLUS MULTI-SYMP"
            },
            "COSTCO WHOLESALE": {
              "KLS MULTI-SYMPTOM COLD CHILD": "KLS MULTI-SYMPTOM COLD CHILD",
              "KLS NIGHTTIME COLD MULTI-SYMPT": "KLS NIGHTTIME COLD MULTI-SYMPT"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS CHILDRENS MULTI-SYMPT CLD": "CVS CHILDRENS MULTI-SYMPT CLD",
              "CVS COLD RELIEF DAY/NIGHT": "CVS COLD RELIEF DAY/NIGHT",
              "CVS DAYTIME/NIGHTTIME COLD/FLU": "CVS DAYTIME/NIGHTTIME COLD/FLU",
              "CVS FLU RELIEF CHILDRENS": "CVS FLU RELIEF CHILDRENS",
              "CVS SEVERE COLD & COUGH": "CVS SEVERE COLD & COUGH",
              "CVS SEVERE COLD/FLU": "CVS SEVERE COLD/FLU"
            },
            "EQUALINE": {
              "EQL CHILDRENS PAIN RELIEF PLUS": "EQL CHILDRENS PAIN RELIEF PLUS",
              "EQL CHILDRENS PLUS COLD MULTI": "EQL CHILDRENS PLUS COLD MULTI",
              "EQL DAYTIME & NIGHTTIME COLD": "EQL DAYTIME & NIGHTTIME COLD",
              "EQL DAYTIME/NIGHTTIME COLD/FLU": "EQL DAYTIME/NIGHTTIME COLD/FLU",
              "EQL FLU/SEVERE COLD/CONGESTION": "EQL FLU/SEVERE COLD/CONGESTION",
              "EQL NIGHTTIME COLD MULTI-SYMP": "EQL NIGHTTIME COLD MULTI-SYMP",
              "EQL NIGHTTIME COLD/FLU RELIEF": "EQL NIGHTTIME COLD/FLU RELIEF",
              "EQL NIGHTTIME SEVERE COLD/FLU": "EQL NIGHTTIME SEVERE COLD/FLU",
              "EQL PAIN RELIEF PLUS COLD/CGH": "EQL PAIN RELIEF PLUS COLD/CGH",
              "EQL PAIN RELIEF/COLD": "EQL PAIN RELIEF/COLD"
            },
            "GEISS DESTIN & DUNN": {
              "GOODSENSE COLD MULTI-SYMPTOM": "GOODSENSE COLD MULTI-SYMPTOM",
              "GOODSENSE NIGHTTIME COLD & FLU": "GOODSENSE NIGHTTIME COLD & FLU",
              "GOODSENSE SEVERE SINUS CONGEST": "GOODSENSE SEVERE SINUS CONGEST"
            },
            "GIL PHARMACEUTICAL CORP.": {
              "GILTUSS COLD & FLU CHILDRENS": "GILTUSS COLD & FLU CHILDRENS",
              "GILTUSS MULTI-SYMP COLD & FLU": "GILTUSS MULTI-SYMP COLD & FLU"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "COMTREX COLD & COUGH NIGHTTIME": "COMTREX COLD & COUGH NIGHTTIME",
              "COMTREX COLD/COUGH DAY/NITE MS": "COMTREX COLD/COUGH DAY/NITE MS",
              "DIMETAPP MULTISYMPTOM COLD/FLU": "DIMETAPP MULTISYMPTOM COLD/FLU",
              "THERAFLU SEVERE COLD & COUGH": "THERAFLU SEVERE COLD & COUGH",
              "THERAFLU SEVERE COLD NIGHTTIME": "THERAFLU SEVERE COLD NIGHTTIME",
              "TRIAMINIC FEVER & COLD": "TRIAMINIC FEVER & COLD"
            },
            "LEADER BRAND PRODUCTS": {
              "NIGHTTIME SEVERE COLD & FLU": "NIGHTTIME SEVERE COLD & FLU"
            },
            "MCKESSON SUNMARK": {
              "SM CHILDRENS PLUS MS COLD": "SM CHILDRENS PLUS MS COLD",
              "SM COLD HEAD CONGESTION NIGHT": "SM COLD HEAD CONGESTION NIGHT"
            },
            "MCNEIL CONSUMER": {
              "TYLENOL CHILDRENS COLD/FLU": "TYLENOL CHILDRENS COLD/FLU",
              "TYLENOL CHILDRENS PLUS MS COLD": "TYLENOL CHILDRENS PLUS MS COLD",
              "TYLENOL COLD MULTI-SYMPTOM": "TYLENOL COLD MULTI-SYMPTOM",
              "TYLENOL COLD/FLU/COUGH NIGHT": "TYLENOL COLD/FLU/COUGH NIGHT"
            },
            "MEDICINE SHOPPE": {
              "NITE-TIME COLD/FLU RELIEF": "NITE-TIME COLD/FLU RELIEF"
            },
            "P & G HEALTH": {
              "NYQUIL SEVERE COLD/FLU": "NYQUIL SEVERE COLD/FLU",
              "NYQUIL SEVERE+ VAPOCOOL": "NYQUIL SEVERE+ VAPOCOOL",
              "SINEX SEVERE+ VAPOCOOL": "SINEX SEVERE+ VAPOCOOL",
              "VICKS DAYQUIL/NYQUIL CLD & FLU": "VICKS DAYQUIL/NYQUIL CLD & FLU",
              "VICKS NYQUIL SEVERE COLD & FLU": "VICKS NYQUIL SEVERE COLD & FLU",
              "VICKS NYQUIL SEVERE COLD/FLU": "VICKS NYQUIL SEVERE COLD/FLU"
            },
            "PERRIGO": {
              "GOODSENSE NIGHTTIME COLD & FLU": "GOODSENSE NIGHTTIME COLD & FLU"
            },
            "PUBLIX SUPER MARKETS INC.": {
              "PX COLD RELIEF DAY/NIGHT": "PX COLD RELIEF DAY/NIGHT",
              "PX COLD/FLU RELIEF DAY/NIGHT": "PX COLD/FLU RELIEF DAY/NIGHT",
              "PX NIGHTTIME COLD": "PX NIGHTTIME COLD",
              "PX NITETIME MULTI-SYMPTOM": "PX NITETIME MULTI-SYMPTOM"
            },
            "RECKITT BENCKISER": {
              "MUCINEX FAST-MAX COLD FLU NGHT": "MUCINEX FAST-MAX COLD FLU NGHT",
              "MUCINEX NIGHTSHIFT COLD/FLU": "MUCINEX NIGHTSHIFT COLD/FLU",
              "MUCINEX NIGHTSHIFT SINUS": "MUCINEX NIGHTSHIFT SINUS",
              "MUCINEX NIGHTSHIFT SINUS CLEAR": "MUCINEX NIGHTSHIFT SINUS CLEAR"
            },
            "RESPA PHARMACEUTICALS, INC.": {
              "RESPA C&C IR": "RESPA C&C IR"
            },
            "RITE AID CORPORATION": {
              "RA CHILDRENS FLU RELIEF PLUS": "RA CHILDRENS FLU RELIEF PLUS",
              "RA CHILDRENS PLUS COLD": "RA CHILDRENS PLUS COLD",
              "RA COLD HEAD CONGESTION": "RA COLD HEAD CONGESTION",
              "RA COLD MULTI-SYMPTOM NIGHT": "RA COLD MULTI-SYMPTOM NIGHT",
              "RA DAY/NIGHT/COLD/FLU RELIEF": "RA DAY/NIGHT/COLD/FLU RELIEF",
              "RA MULTI-SYMPTOM DAY/NIGHT": "RA MULTI-SYMPTOM DAY/NIGHT"
            },
            "SELECT BRAND": {
              "SB CHILDRENS MULTISYMPT COLD": "SB CHILDRENS MULTISYMPT COLD",
              "SB COLD MULTI-SYMPTOM DAY/NGHT": "SB COLD MULTI-SYMPTOM DAY/NGHT"
            },
            "STRIDES USA CONSUMER": {
              "PEDIACARE MULTI-SYMPTOM": "PEDIACARE MULTI-SYMPTOM"
            },
            "SYENZ LABORATORY": {
              "HERBIOMED DEEP COLD & FLU NT": "HERBIOMED DEEP COLD & FLU NT"
            },
            "TARGET": {
              "TGT COLD RELIEF MULTI-SYMPTOM": "TGT COLD RELIEF MULTI-SYMPTOM",
              "TGT COLD/FLU RELIEF DAY/NIGHT": "TGT COLD/FLU RELIEF DAY/NIGHT",
              "TGT MULTI-SYMPTOM COLD CHILD": "TGT MULTI-SYMPTOM COLD CHILD"
            },
            "TOPCO": {
              "COLD HEAD CONGESTION NIGHTTIME": "COLD HEAD CONGESTION NIGHTTIME",
              "COLD MULTI-SYMPTOM NIGHTTIME": "COLD MULTI-SYMPTOM NIGHTTIME",
              "DAY TIME/NITE TIME COLD/FLU": "DAY TIME/NITE TIME COLD/FLU",
              "MULTI-SYMPTOM COLD CHILDRENS": "MULTI-SYMPTOM COLD CHILDRENS",
              "PAIN RELIEF PLUS MULTI-SYMPTOM": "PAIN RELIEF PLUS MULTI-SYMPTOM"
            },
            "WAL-MART": {
              "EQ COLD/COUGH PLUS": "EQ COLD/COUGH PLUS",
              "EQ DAYTIME/NITETIME COLD & FLU": "EQ DAYTIME/NITETIME COLD & FLU"
            },
            "WALGREENS": {
              "CHILDRENS PLUS FLU": "CHILDRENS PLUS FLU",
              "CHILDRENS PLUS MULTI-SYMPT CLD": "CHILDRENS PLUS MULTI-SYMPT CLD",
              "COLD & FLU NIGHTTIME/DAYTIME": "COLD & FLU NIGHTTIME/DAYTIME",
              "COLD & FLU RELIEF NIGHTTIME D": "COLD & FLU RELIEF NIGHTTIME D",
              "COLD MULTI-SYMPTOM DAY/NIGHT": "COLD MULTI-SYMPTOM DAY/NIGHT",
              "COLD MULTI-SYMPTOM NIGHTTIME": "COLD MULTI-SYMPTOM NIGHTTIME",
              "COLD MULTI-SYMPTOM WARM NIGHT": "COLD MULTI-SYMPTOM WARM NIGHT",
              "COLD/FLU RELIEF DAY/NIGHT": "COLD/FLU RELIEF DAY/NIGHT",
              "HEAD CONGESTION COLD DAY/NIGHT": "HEAD CONGESTION COLD DAY/NIGHT",
              "MULTI-SYMPTOM COLD PLUS CHILD": "MULTI-SYMPTOM COLD PLUS CHILD",
              "SEVERE COLD/FLU NIGHTTIME MS": "SEVERE COLD/FLU NIGHTTIME MS",
              "WAL-FLU SEV COLD/CGH DAY/NIGHT": "WAL-FLU SEV COLD/CGH DAY/NIGHT"
            }
          },
          "Opioid Antitussive-Antihistamine": {
            "A-S MEDICATION SOLUTIONS": {
              "PROMETHAZINE-CODEINE": "PROMETHAZINE-CODEINE",
              "TUSSIONEX PENNKINETIC ER": "TUSSIONEX PENNKINETIC ER"
            },
            "AIDAREX PHARMACEUTICALS": {
              "PROMETHAZINE-CODEINE": "PROMETHAZINE-CODEINE"
            },
            "ALTURA PHARMACEUTICALS": {
              "PROMETHAZINE-CODEINE": "PROMETHAZINE-CODEINE"
            },
            "ARISTOS PHARMACEUTICALS": {
              "HYDROCOD POLST-CPM POLST ER": "HYDROCOD POLST-CPM POLST ER"
            },
            "ATLANTIC BIOLOGICALS": {
              "HYDROCOD POLST-CPM POLST ER": "HYDROCOD POLST-CPM POLST ER",
              "PROMETHAZINE-CODEINE": "PROMETHAZINE-CODEINE"
            },
            "AYTU BIOSCIENCE": {
              "TUZISTRA XR": "TUZISTRA XR"
            },
            "BAUSCH HEALTH": {
              "TUSSICAPS": "TUSSICAPS"
            },
            "BRYANT RANCH PREPACK": {
              "PROMETHAZINE-CODEINE": "PROMETHAZINE-CODEINE"
            },
            "CENTURION LABS": {
              "LEXUSS 210": "LEXUSS 210"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "PROMETHAZINE-CODEINE": "PROMETHAZINE-CODEINE"
            },
            "HAWTHORN PHARMACEUTICALS": {
              "VITUZ": "VITUZ"
            },
            "HI-TECH": {
              "PROMETHAZINE-CODEINE": "PROMETHAZINE-CODEINE"
            },
            "KREMERS URBAN": {
              "HYDROCOD POLST-CPM POLST ER": "HYDROCOD POLST-CPM POLST ER"
            },
            "MAGNA PHARMACEUTICALS, INC": {
              "Z-TUSS AC": "Z-TUSS AC"
            },
            "MAINPOINTE PHARMACEUTICALS": {
              "TUXARIN ER": "TUXARIN ER"
            },
            "MORTON GROVE PHARMACEUTICALS": {
              "PROMETHAZINE-CODEINE": "PROMETHAZINE-CODEINE"
            },
            "NEOS THERAPEUTICS": {
              "HYDROCOD POLST-CPM POLST ER": "HYDROCOD POLST-CPM POLST ER"
            },
            "NOSTRUM LABORATORIES": {
              "PROMETHAZINE-CODEINE": "PROMETHAZINE-CODEINE"
            },
            "PAR PHARMACEUTICAL": {
              "HYDROCOD POLST-CPM POLST ER": "HYDROCOD POLST-CPM POLST ER"
            },
            "PAR PHARMACEUTICALS": {
              "PROMETHAZINE-CODEINE": "PROMETHAZINE-CODEINE"
            },
            "PHARMEDIX": {
              "PROMETHAZINE-CODEINE": "PROMETHAZINE-CODEINE"
            },
            "PREFERRED PHARMACEUTICALS": {
              "PROMETHAZINE-CODEINE": "PROMETHAZINE-CODEINE"
            },
            "PRO-PHARMA LLC": {
              "PRO-CLEAR AC": "PRO-CLEAR AC"
            },
            "QUALITY CARE": {
              "PROMETHAZINE-CODEINE": "PROMETHAZINE-CODEINE",
              "TUSSIONEX PENNKINETIC ER": "TUSSIONEX PENNKINETIC ER"
            },
            "RESPA PHARMACEUTICALS, INC.": {
              "CODAR AR": "CODAR AR"
            },
            "RXCHANGE CO": {
              "PROMETHAZINE-CODEINE": "PROMETHAZINE-CODEINE"
            },
            "TRIS PHARMA": {
              "HYDROCOD POLST-CPM POLST ER": "HYDROCOD POLST-CPM POLST ER",
              "PROMETHAZINE-CODEINE": "PROMETHAZINE-CODEINE"
            },
            "UCB PHARMA": {
              "TUSSIONEX PENNKINETIC ER": "TUSSIONEX PENNKINETIC ER"
            },
            "VERNALIS THERAPEUTICS": {
              "TUZISTRA XR": "TUZISTRA XR"
            }
          },
          "Opioid Antitussive-Decongestant": {
            "HAWTHORN PHARMACEUTICALS": {
              "REZIRA": "REZIRA"
            },
            "RESPA PHARMACEUTICALS, INC.": {
              "CODAR D": "CODAR D"
            }
          },
          "Opioid Antitussive-Decongestant-Antihistamine": {
            "A-S MEDICATION SOLUTIONS": {
              "PROMETHAZINE VC/CODEINE": "PROMETHAZINE VC/CODEINE"
            },
            "ALLEGIS PHARMACEUTICALS": {
              "HISTEX-AC": "HISTEX-AC",
              "MAR-COF BP": "MAR-COF BP"
            },
            "ALTURA PHARMACEUTICALS": {
              "PROMETHAZINE VC/CODEINE": "PROMETHAZINE VC/CODEINE"
            },
            "AMNEAL PHARMACEUTICALS": {
              "PROMETHAZINE-PHENYLEPH-CODEINE": "PROMETHAZINE-PHENYLEPH-CODEINE"
            },
            "BRYANT RANCH PREPACK": {
              "PROMETHAZINE VC/CODEINE": "PROMETHAZINE VC/CODEINE"
            },
            "CAPITAL PHARMACEUTICAL": {
              "CAPCOF": "CAPCOF"
            },
            "CENTURION LABS": {
              "RYDEX": "RYDEX"
            },
            "CYPRESS PHARMACEUTICAL": {
              "PSEUDOEPH-CHLORPHEN-HYDROCOD": "PSEUDOEPH-CHLORPHEN-HYDROCOD"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "PROMETHAZINE VC/CODEINE": "PROMETHAZINE VC/CODEINE"
            },
            "HAWTHORN PHARMACEUTICALS": {
              "ZUTRIPRO": "ZUTRIPRO"
            },
            "HI-TECH": {
              "PROMETHAZINE-PHENYLEPH-CODEINE": "PROMETHAZINE-PHENYLEPH-CODEINE"
            },
            "MCR/AMERICAN PHARMACEUTICAL": {
              "MAXI-TUSS CD": "MAXI-TUSS CD"
            },
            "PAR PHARMACEUTICALS": {
              "PHENYLHISTINE DH": "PHENYLHISTINE DH",
              "PROMETHAZINE VC/CODEINE": "PROMETHAZINE VC/CODEINE"
            },
            "PERRIGO": {
              "PSEUDOEPH-CHLORPHEN-HYDROCOD": "PSEUDOEPH-CHLORPHEN-HYDROCOD"
            },
            "POLY PHARMACEUTICALS": {
              "POLY-TUSSIN AC": "POLY-TUSSIN AC"
            },
            "PRO-PHARMA LLC": {
              "PRO-RED AC": "PRO-RED AC"
            },
            "QUALITY CARE": {
              "PROMETHAZINE VC/CODEINE": "PROMETHAZINE VC/CODEINE"
            },
            "R A MCNEIL": {
              "M-END MAX D": "M-END MAX D",
              "M-END PE": "M-END PE",
              "M-END WC": "M-END WC"
            },
            "RESPA PHARMACEUTICALS, INC.": {
              "TRICODE AR": "TRICODE AR"
            },
            "RXCHANGE CO": {
              "PROMETHAZINE VC/CODEINE": "PROMETHAZINE VC/CODEINE"
            },
            "TRIS PHARMA": {
              "PSEUDOEPH-CHLORPHEN-HYDROCOD": "PSEUDOEPH-CHLORPHEN-HYDROCOD"
            }
          }
        },
        "Expectorants": {
          "Expectorants": {
            "21ST CENTURY HEALTHCARE": {
              "MUCUS RELIEF ER": "MUCUS RELIEF ER"
            },
            "A-S MEDICATION SOLUTIONS": {
              "GUAIFENESIN": "GUAIFENESIN",
              "GUAIFENESIN ER": "GUAIFENESIN ER",
              "MUCINEX": "MUCINEX",
              "MUCINEX MAXIMUM STRENGTH": "MUCINEX MAXIMUM STRENGTH",
              "SILTUSSIN SA": "SILTUSSIN SA"
            },
            "AIDAREX PHARMACEUTICALS": {
              "MUCINEX": "MUCINEX",
              "SILTUSSIN SA": "SILTUSSIN SA",
              "TUSSIN MUCUS+CHEST CONGESTION": "TUSSIN MUCUS+CHEST CONGESTION"
            },
            "AKORN CONSUMER": {
              "DIABETIC TUSSIN": "DIABETIC TUSSIN",
              "DIABETIC TUSSIN EX": "DIABETIC TUSSIN EX"
            },
            "ALTAIRE": {
              "ALTARUSSIN": "ALTARUSSIN"
            },
            "ALVIX LABORATORIES": {
              "GUAIFENESIN": "GUAIFENESIN"
            },
            "AMBI PHARMACEUTICALS": {
              "G-FEN EX": "G-FEN EX"
            },
            "AMERICAN HEALTH PACKAGING": {
              "MUCINEX": "MUCINEX",
              "ORGAN-I NR": "ORGAN-I NR"
            },
            "AMERICAN SALES COMPANY": {
              "CHEST CONGESTION RELIEF": "CHEST CONGESTION RELIEF",
              "MUCUS RELIEF CHILDRENS": "MUCUS RELIEF CHILDRENS",
              "TUSSIN CHEST CONGESTION": "TUSSIN CHEST CONGESTION"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP MUCUS ER": "GNP MUCUS ER",
              "GNP TAB TUSSIN": "GNP TAB TUSSIN",
              "GNP TUSSIN MUCUS & CHEST CONG": "GNP TUSSIN MUCUS & CHEST CONG"
            },
            "APHENA PHARMA SOLUTIONS": {
              "GUAIFENESIN": "GUAIFENESIN",
              "MUCINEX": "MUCINEX",
              "ORGAN-I NR": "ORGAN-I NR"
            },
            "APOTHECA SUPPLY": {
              "GUAIFENESIN": "GUAIFENESIN"
            },
            "ATLANTIC BIOLOGICALS": {
              "GUAIFENESIN ER": "GUAIFENESIN ER",
              "SILTUSSIN SA": "SILTUSSIN SA"
            },
            "AURORA HEALTHCARE": {
              "TUSSIN": "TUSSIN"
            },
            "AVKARE": {
              "GUAIFENESIN": "GUAIFENESIN"
            },
            "AVPAK": {
              "GUAIFENESIN": "GUAIFENESIN",
              "MUCOSA": "MUCOSA"
            },
            "BERGEN BRUNSWIG": {
              "GNP MUCUS RELIEF": "GNP MUCUS RELIEF",
              "GNP MUCUS RELIEF CHILDRENS": "GNP MUCUS RELIEF CHILDRENS",
              "GNP TUSSIN": "GNP TUSSIN"
            },
            "BRYANT RANCH PREPACK": {
              "GUAIFENESIN": "GUAIFENESIN"
            },
            "CAMBER CONSUMER CARE": {
              "MUCUS RELIEF MAX ST": "MUCUS RELIEF MAX ST"
            },
            "CAPELLON PHARMACEUTICALS": {
              "LIQUIBID": "LIQUIBID",
              "LIQUITUSS GG": "LIQUITUSS GG"
            },
            "CHAIN DRUG CONSORTIUM": {
              "CHILDRENS MUCUS RELIEF EXPECT": "CHILDRENS MUCUS RELIEF EXPECT",
              "MUCUS RELIEF": "MUCUS RELIEF",
              "TABTUSSIN": "TABTUSSIN",
              "TRIACTIN CHEST CONGESTION": "TRIACTIN CHEST CONGESTION",
              "TUSSIN EXPECTORANT": "TUSSIN EXPECTORANT"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC MEDIFIN 400": "QC MEDIFIN 400",
              "QC MEDIFIN MUCUS RELIEF CHILD": "QC MEDIFIN MUCUS RELIEF CHILD",
              "QC MUCUS RELIEF": "QC MUCUS RELIEF",
              "QC MUCUS RELIEF CHILDRENS": "QC MUCUS RELIEF CHILDRENS",
              "QC MUCUS RELIEF ER": "QC MUCUS RELIEF ER",
              "QC MUCUS RELIEF MAX ST": "QC MUCUS RELIEF MAX ST",
              "QC TUSSIN MUCUS/CONGESTION": "QC TUSSIN MUCUS/CONGESTION",
              "TUSSIN": "TUSSIN"
            },
            "COSTCO WHOLESALE": {
              "KLS MUCUS RELIEF CHEST": "KLS MUCUS RELIEF CHEST"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS CHEST CONGESTION CHILDRENS": "CVS CHEST CONGESTION CHILDRENS",
              "CVS CHEST CONGESTION RELIEF": "CVS CHEST CONGESTION RELIEF",
              "CVS MUCUS EXTENDED RELEASE": "CVS MUCUS EXTENDED RELEASE",
              "CVS TUSSIN ADULT CHEST CONGEST": "CVS TUSSIN ADULT CHEST CONGEST"
            },
            "DIRECT RX": {
              "MUCINEX": "MUCINEX"
            },
            "EQUALINE": {
              "EQL MUCUS-ER": "EQL MUCUS-ER",
              "EQL MUCUS-ER MAXIMUM STRENGTH": "EQL MUCUS-ER MAXIMUM STRENGTH",
              "EQL TUSSIN CHEST CONGESTION": "EQL TUSSIN CHEST CONGESTION",
              "EQL TUSSIN MUCUS/CHEST CONGEST": "EQL TUSSIN MUCUS/CHEST CONGEST"
            },
            "FAGRON": {
              "GUAIFENESIN": "GUAIFENESIN"
            },
            "FREEDOM PHARMACEUTICALS": {
              "GUAIFENESIN": "GUAIFENESIN"
            },
            "GEISS DESTIN & DUNN": {
              "GOODSENSE MUCUS RELIEF": "GOODSENSE MUCUS RELIEF"
            },
            "GERI-CARE": {
              "GERI-TUSSIN": "GERI-TUSSIN",
              "MUCUS RELIEF": "MUCUS RELIEF"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "BUCKLEYS CHEST CONGESTION": "BUCKLEYS CHEST CONGESTION",
              "ROBITUSSIN MUCUS+CHEST CONGEST": "ROBITUSSIN MUCUS+CHEST CONGEST"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "GUAIFENESIN": "GUAIFENESIN",
              "GUIATUSS": "GUIATUSS"
            },
            "HANNAFORD BROTHERS": {
              "TUSSIN": "TUSSIN"
            },
            "HAWTHORN PHARMACEUTICALS": {
              "XPECT": "XPECT"
            },
            "HI-TECH": {
              "GUAIFENESIN": "GUAIFENESIN"
            },
            "HY-VEE": {
              "TUSSIN MUCUS & CHEST CONGEST": "TUSSIN MUCUS & CHEST CONGEST"
            },
            "IRISYS": {
              "SCOT-TUSSIN EXPECTORANT": "SCOT-TUSSIN EXPECTORANT"
            },
            "KALCHEM INTERNATIONAL": {
              "GUAIFENESIN": "GUAIFENESIN"
            },
            "LEADER BRAND PRODUCTS": {
              "CHEST CONGESTION RELIEF": "CHEST CONGESTION RELIEF",
              "COUGHTAB": "COUGHTAB",
              "MUCUS RELIEF CHEST CONGESTION": "MUCUS RELIEF CHEST CONGESTION",
              "MUCUS RELIEF MAX ST": "MUCUS RELIEF MAX ST",
              "MUCUS-ER": "MUCUS-ER",
              "MUCUS-ER MAX": "MUCUS-ER MAX",
              "TABTUSSIN": "TABTUSSIN",
              "TUSSIN": "TUSSIN",
              "TUSSIN MUCUS+CHEST CONGESTION": "TUSSIN MUCUS+CHEST CONGESTION"
            },
            "LETCO MEDICAL": {
              "GUAIFENESIN": "GUAIFENESIN"
            },
            "LIBERTY PHARMACEUTICAL": {
              "GUAIFENESIN": "GUAIFENESIN",
              "MUCINEX": "MUCINEX"
            },
            "LLORENS PHARMACEUTICAL": {
              "TUSNEL-EX": "TUSNEL-EX"
            },
            "MAGNO-HUMPHRIES LABORATORIES": {
              "MUCOSA": "MUCOSA"
            },
            "MAJOR PHARMACEUTICALS": {
              "GUAIFENESIN": "GUAIFENESIN",
              "MUCUS RELIEF": "MUCUS RELIEF",
              "MUCUS RELIEF ER": "MUCUS RELIEF ER",
              "ROBAFEN": "ROBAFEN",
              "ROBAFEN MUCUS/CHEST CONGESTION": "ROBAFEN MUCUS/CHEST CONGESTION"
            },
            "MARLEX PHARMACEUTICALS": {
              "GUAIFENESIN": "GUAIFENESIN"
            },
            "MCKESSON": {
              "HM CHEST CONGESTION RELIEF": "HM CHEST CONGESTION RELIEF",
              "HM MUCUS ER": "HM MUCUS ER",
              "HM MUCUS RELIEF": "HM MUCUS RELIEF",
              "HM TUSSIN ADULT": "HM TUSSIN ADULT"
            },
            "MCKESSON SUNMARK": {
              "SM CHEST CONGESTION RELIEF": "SM CHEST CONGESTION RELIEF",
              "SM MUCUS ER": "SM MUCUS ER",
              "SM MUCUS RELIEF": "SM MUCUS RELIEF",
              "SM MUCUS RELIEF CHILDRENS": "SM MUCUS RELIEF CHILDRENS",
              "SM MUCUS RELIEF MAX STRENGTH": "SM MUCUS RELIEF MAX STRENGTH",
              "SM TUSSIN": "SM TUSSIN",
              "SM TUSSIN MUCUS+CHEST CONGEST": "SM TUSSIN MUCUS+CHEST CONGEST"
            },
            "MEDICINE SHOPPE": {
              "CHEST CONGESTION RELIEF": "CHEST CONGESTION RELIEF",
              "COUGHTAB": "COUGHTAB",
              "MUCUS RELIEF CHEST CONGESTION": "MUCUS RELIEF CHEST CONGESTION"
            },
            "MEDISCA": {
              "GUAIFENESIN": "GUAIFENESIN"
            },
            "MEIJER": {
              "COUGH SYRUP": "COUGH SYRUP",
              "MUCUS+CHEST CONGESTION": "MUCUS+CHEST CONGESTION"
            },
            "METHOD PHARMACEUTICALS": {
              "GUAIFENESIN": "GUAIFENESIN"
            },
            "NATURADE": {
              "HERBAL EXPEC": "HERBAL EXPEC"
            },
            "NOREGA LABORATORIES": {
              "YODEFAN-NF CHEST CONGESTION": "YODEFAN-NF CHEST CONGESTION"
            },
            "NUCARE PHARMACEUTICALS": {
              "MUCINEX": "MUCINEX"
            },
            "PAR PHARMACEUTICALS": {
              "IOPHEN-NR": "IOPHEN-NR",
              "ORGAN-I NR": "ORGAN-I NR",
              "Q-TUSSIN": "Q-TUSSIN"
            },
            "PCCA": {
              "BROMHEXINE HCL": "BROMHEXINE HCL",
              "GUAIFENESIN": "GUAIFENESIN",
              "TERPIN HYDRATE": "TERPIN HYDRATE"
            },
            "PDRX PHARMACEUTICAL": {
              "MUCINEX": "MUCINEX"
            },
            "PERRIGO": {
              "TUSSIN MUCUS & CHEST CONGEST": "TUSSIN MUCUS & CHEST CONGEST",
              "TUSSIN MUCUS+CHEST CONGESTION": "TUSSIN MUCUS+CHEST CONGESTION"
            },
            "PERRIGO PHARMACEUTICALS": {
              "GUAIFENESIN ER": "GUAIFENESIN ER",
              "MUCUS RELIEF CHILDRENS": "MUCUS RELIEF CHILDRENS"
            },
            "PHARBEST PHARMACEUTICALS": {
              "PHARBINEX": "PHARBINEX"
            },
            "PHARMA MEDICA": {
              "FENESIN IR": "FENESIN IR"
            },
            "PHARMACEUTICAL ASSOCIATES": {
              "GUAIFENESIN": "GUAIFENESIN"
            },
            "PROFICIENT RX": {
              "MUCINEX": "MUCINEX"
            },
            "PUBLIX SUPER MARKETS INC.": {
              "PX TUSSIN": "PX TUSSIN"
            },
            "QUALITY CARE": {
              "COUGH SYRUP": "COUGH SYRUP",
              "GUAIFENESIN": "GUAIFENESIN",
              "GUAIFENEX LA": "GUAIFENEX LA",
              "GUIATUSS": "GUIATUSS"
            },
            "QUALITY CARE PRODUCTS": {
              "MUCINEX": "MUCINEX"
            },
            "RECKITT BENCKISER": {
              "MUCINEX": "MUCINEX",
              "MUCINEX CHEST CONGESTION CHILD": "MUCINEX CHEST CONGESTION CHILD",
              "MUCINEX FOR KIDS": "MUCINEX FOR KIDS",
              "MUCINEX MAXIMUM STRENGTH": "MUCINEX MAXIMUM STRENGTH"
            },
            "REESE PHARMACEUTICAL": {
              "REFENESEN": "REFENESEN",
              "REFENESEN 400": "REFENESEN 400"
            },
            "RELIABLE 1 LABS": {
              "GUAIFENESIN": "GUAIFENESIN"
            },
            "RICHMOND PHARMACEUTICALS": {
              "GUAIFENESIN": "GUAIFENESIN"
            },
            "RITE AID CORPORATION": {
              "PA MUCUS RELIEF": "PA MUCUS RELIEF",
              "RA MUCUS RELIEF": "RA MUCUS RELIEF",
              "RA MUCUS RELIEF CHEST": "RA MUCUS RELIEF CHEST",
              "RA MUCUS RELIEF MAX ST": "RA MUCUS RELIEF MAX ST",
              "RA TUSSIN": "RA TUSSIN",
              "RA TUSSIN CHEST CONGESTION": "RA TUSSIN CHEST CONGESTION"
            },
            "RUGBY LABORATORIES": {
              "CHEST CONGESTION RELIEF": "CHEST CONGESTION RELIEF",
              "COUGH SYRUP": "COUGH SYRUP",
              "MUCUS & CHEST CONGESTION": "MUCUS & CHEST CONGESTION",
              "MUCUS RELIEF": "MUCUS RELIEF",
              "MUCUS-ER": "MUCUS-ER"
            },
            "RXCHANGE CO": {
              "MUCINEX": "MUCINEX"
            },
            "SCOT-TUSSIN": {
              "GUAIFENESIN": "GUAIFENESIN"
            },
            "SELECT BRAND": {
              "SB COUGH CONTROL": "SB COUGH CONTROL",
              "SB COUGHTAB": "SB COUGHTAB",
              "SB MUCUS RELIEF": "SB MUCUS RELIEF"
            },
            "SILARX": {
              "DIABETIC SILTUSSIN DAS-NA": "DIABETIC SILTUSSIN DAS-NA",
              "SILTUSSIN DAS": "SILTUSSIN DAS",
              "SILTUSSIN SA": "SILTUSSIN SA"
            },
            "SPECTRUM": {
              "BROMHEXINE HCL": "BROMHEXINE HCL",
              "GUAIFENESIN": "GUAIFENESIN",
              "TERPIN HYDRATE MONOHYDRATE": "TERPIN HYDRATE MONOHYDRATE"
            },
            "STEWART JACKSON": {
              "BIDEX": "BIDEX"
            },
            "TARGET": {
              "TGT MUCUS RELIEF CHILDRENS": "TGT MUCUS RELIEF CHILDRENS"
            },
            "TIME-CAP LABS": {
              "MUCOSA": "MUCOSA"
            },
            "TOPCO": {
              "CHEST CONGESTION RELIEF": "CHEST CONGESTION RELIEF",
              "CHILDRENS MUCUS RELIEF EXPECT": "CHILDRENS MUCUS RELIEF EXPECT",
              "MUCUS RELIEF MAX ST": "MUCUS RELIEF MAX ST",
              "MUCUS-ER": "MUCUS-ER",
              "TUSSIN CHEST CONGESTION": "TUSSIN CHEST CONGESTION",
              "TUSSIN MUCUS+CHEST CONGESTION": "TUSSIN MUCUS+CHEST CONGESTION"
            },
            "TOTAL PHARMACY SUPPLY": {
              "GUAIFENESIN": "GUAIFENESIN"
            },
            "UNIT DOSE SERVICES": {
              "GUAIFENESIN": "GUAIFENESIN"
            },
            "VA CMOP DALLAS": {
              "MUCINEX": "MUCINEX"
            },
            "WAL-MART": {
              "EQ MUCUS ER": "EQ MUCUS ER",
              "EQ TUSSIN": "EQ TUSSIN"
            },
            "WALGREENS": {
              "CHEST CONGESTION CHILDRENS": "CHEST CONGESTION CHILDRENS",
              "MUCUS RELIEF": "MUCUS RELIEF",
              "MUCUS RELIEF CHEST CONGESTION": "MUCUS RELIEF CHEST CONGESTION",
              "MUCUS RELIEF ER": "MUCUS RELIEF ER",
              "WAL-TUSSIN": "WAL-TUSSIN",
              "WAL-TUSSIN CHEST CONGESTION": "WAL-TUSSIN CHEST CONGESTION"
            }
          },
          "Iodine Expectorants": {
            "AVONDALE": {
              "SSKI": "SSKI"
            },
            "UPSHER-SMITH": {
              "SSKI": "SSKI"
            }
          }
        },
        "Misc. Respiratory Inhalants": {
          "Aromatic Inhalants": {
            "AMERICAN SALES COMPANY": {
              "MEDICATED CHEST RUB": "MEDICATED CHEST RUB"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP CHEST RUB": "GNP CHEST RUB"
            },
            "CHAIN DRUG CONSORTIUM": {
              "HOT STEAM": "HOT STEAM",
              "MEDICATED CHEST RUB": "MEDICATED CHEST RUB",
              "SHOWER TABLETS": "SHOWER TABLETS",
              "VAPOR INHALER": "VAPOR INHALER"
            },
            "CHATTEM INC": {
              "ICY HOT NO-MESS VAPOR GEL": "ICY HOT NO-MESS VAPOR GEL",
              "ICY HOT NO-MESS VAPOR GEL KIDS": "ICY HOT NO-MESS VAPOR GEL KIDS"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS CHEST RUB MEDICATED": "CVS CHEST RUB MEDICATED",
              "CVS HOT STEAM": "CVS HOT STEAM",
              "CVS NASAL DECONGESTANT": "CVS NASAL DECONGESTANT"
            },
            "EQUALINE": {
              "EQL MEDICATED CHEST RUB": "EQL MEDICATED CHEST RUB",
              "EQL VAPOR NASAL DECONGESTANT": "EQL VAPOR NASAL DECONGESTANT"
            },
            "GEISS DESTIN & DUNN": {
              "GOODSENSE VAPOR": "GOODSENSE VAPOR"
            },
            "GERITREX CORPORATION": {
              "VAPORX BALM": "VAPORX BALM"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "VAPOR PATCH": "VAPOR PATCH"
            },
            "KAZ": {
              "VICKS VAPO STEAM": "VICKS VAPO STEAM"
            },
            "LEADER BRAND PRODUCTS": {
              "CHEST RUB": "CHEST RUB"
            },
            "MCKESSON": {
              "HM CHEST RUB": "HM CHEST RUB"
            },
            "MCKESSON SUNMARK": {
              "SM MEDICATED CHEST RUB": "SM MEDICATED CHEST RUB"
            },
            "NEW WORLD IMPORTS": {
              "CHEST RUB": "CHEST RUB"
            },
            "P & G HEALTH": {
              "VICKS BABYRUB": "VICKS BABYRUB",
              "VICKS VAPO STEAM": "VICKS VAPO STEAM",
              "VICKS VAPOINHALER": "VICKS VAPOINHALER",
              "VICKS VAPORUB": "VICKS VAPORUB",
              "VICKS VAPORUB CHILDRENS": "VICKS VAPORUB CHILDRENS"
            },
            "PUBLIX SUPER MARKETS INC.": {
              "PX MEDICATED CHESTRUB": "PX MEDICATED CHESTRUB"
            },
            "RITE AID CORPORATION": {
              "RA DECONGESTANT INHALER": "RA DECONGESTANT INHALER",
              "RA MEDICATED CHEST RUB": "RA MEDICATED CHEST RUB",
              "RA MENTHOL NASAL INHALER": "RA MENTHOL NASAL INHALER",
              "RA VAPORIZING STEAM": "RA VAPORIZING STEAM"
            },
            "TARGET": {
              "TGT COUGH SUPPRESS/ANALGESIC": "TGT COUGH SUPPRESS/ANALGESIC"
            },
            "WAL-MART": {
              "EQ MEDICATED CHEST RUB": "EQ MEDICATED CHEST RUB",
              "EQ VAPORIZING RUB": "EQ VAPORIZING RUB"
            },
            "WALGREENS": {
              "BABY CHEST RUB": "BABY CHEST RUB",
              "CHEST RUB": "CHEST RUB",
              "CHEST RUB HANDS-FREE MEDICATED": "CHEST RUB HANDS-FREE MEDICATED",
              "DECONGESTANT VAPOR": "DECONGESTANT VAPOR",
              "VAPORIZING CHEST RUB": "VAPORIZING CHEST RUB",
              "VAPORIZING STEAM": "VAPORIZING STEAM"
            }
          },
          "Misc. Respiratory Inhalants": {
            "BLAIREX LABS": {
              "BRONCHO SALINE": "BRONCHO SALINE",
              "SIMPLY SALINE BABY": "SIMPLY SALINE BABY"
            },
            "CHAIN DRUG CONSORTIUM": {
              "NASAL MIST": "NASAL MIST"
            },
            "MYLAN": {
              "SODIUM CHLORIDE": "SODIUM CHLORIDE"
            },
            "NEPHRON PHARMACEUTICALS CORP.": {
              "SODIUM CHLORIDE": "SODIUM CHLORIDE"
            },
            "PARI RESPIRATORY": {
              "HYPERSAL": "HYPERSAL",
              "SODIUM CHLORIDE": "SODIUM CHLORIDE"
            },
            "PHARMACARIBE": {
              "NEBUSAL": "NEBUSAL",
              "PULMOSAL": "PULMOSAL",
              "SODIUM CHLORIDE": "SODIUM CHLORIDE"
            },
            "RITEDOSE PHARMACEUTICALS": {
              "SODIUM CHLORIDE": "SODIUM CHLORIDE"
            },
            "WALGREENS": {
              "NASAL MIST": "NASAL MIST"
            }
          }
        },
        "Mucolytics": {
          "Mucolytics": {
            "ALVIX LABORATORIES": {
              "ACETYLCYSTEINE": "ACETYLCYSTEINE"
            },
            "AMERICAN REGENT": {
              "ACETYLCYSTEINE": "ACETYLCYSTEINE"
            },
            "BPI LABS LLC": {
              "N-ACETYL-L-CYSTEINE": "N-ACETYL-L-CYSTEINE"
            },
            "FAGRON": {
              "ACETYLCYSTEINE": "ACETYLCYSTEINE"
            },
            "FREEDOM PHARMACEUTICALS": {
              "ACETYLCYSTEINE": "ACETYLCYSTEINE"
            },
            "FRESENIUS KABI USA": {
              "ACETYLCYSTEINE": "ACETYLCYSTEINE"
            },
            "HOSPIRA": {
              "ACETYLCYSTEINE": "ACETYLCYSTEINE"
            },
            "HUMCO": {
              "ACETYLCYSTEINE": "ACETYLCYSTEINE"
            },
            "LETCO MEDICAL": {
              "ACETYLCYSTEINE": "ACETYLCYSTEINE"
            },
            "MEDISCA": {
              "ACETYLCYSTEINE": "ACETYLCYSTEINE"
            },
            "PCCA": {
              "ACETYLCYSTEINE": "ACETYLCYSTEINE"
            },
            "SPECTRUM": {
              "ACETYLCYSTEINE": "ACETYLCYSTEINE"
            }
          }
        }
      },
      "DERMATOLOGICALS": {
        "Acne Products": {
          "Acne Antibiotics": {
            "A-S MEDICATION SOLUTIONS": {
              "CLEOCIN-T": "CLEOCIN-T",
              "CLINDAMYCIN PHOSPHATE": "CLINDAMYCIN PHOSPHATE",
              "ERY": "ERY",
              "ERYTHROMYCIN": "ERYTHROMYCIN"
            },
            "ACTAVIS MID ATLANTIC": {
              "CLINDAMYCIN PHOSPHATE": "CLINDAMYCIN PHOSPHATE"
            },
            "AIDAREX PHARMACEUTICALS": {
              "CLINDAMYCIN PHOSPHATE": "CLINDAMYCIN PHOSPHATE",
              "ERYTHROMYCIN": "ERYTHROMYCIN"
            },
            "AKORN": {
              "CLINDAMYCIN PHOSPHATE": "CLINDAMYCIN PHOSPHATE",
              "ERYTHROMYCIN": "ERYTHROMYCIN"
            },
            "ALMIRALL": {
              "ACZONE": "ACZONE"
            },
            "AVKARE": {
              "CLINDAMYCIN PHOSPHATE": "CLINDAMYCIN PHOSPHATE"
            },
            "BAUSCH HEALTH": {
              "CLINDAGEL": "CLINDAGEL",
              "KLARON": "KLARON"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "CLINDAMYCIN PHOSPHATE": "CLINDAMYCIN PHOSPHATE"
            },
            "ENCUBE ETHICALS": {
              "CLINDAMYCIN PHOSPHATE": "CLINDAMYCIN PHOSPHATE"
            },
            "FOAMIX PHARMACEUTICALS": {
              "AMZEEQ": "AMZEEQ"
            },
            "FOUGERA": {
              "CLINDAMYCIN PHOSPHATE": "CLINDAMYCIN PHOSPHATE",
              "ERYTHROMYCIN": "ERYTHROMYCIN",
              "SULFACETAMIDE SODIUM (ACNE)": "SULFACETAMIDE SODIUM (ACNE)"
            },
            "GLASSHOUSE PHARMACEUTICALS": {
              "CLINDAMYCIN PHOSPHATE": "CLINDAMYCIN PHOSPHATE"
            },
            "GREENSTONE": {
              "CLINDAMYCIN PHOSPHATE": "CLINDAMYCIN PHOSPHATE",
              "DAPSONE": "DAPSONE"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "CLEOCIN-T": "CLEOCIN-T"
            },
            "MARLEX PHARMACEUTICALS": {
              "CLINDAMYCIN PHOSPHATE": "CLINDAMYCIN PHOSPHATE"
            },
            "MEDIMETRIKS PHARMACEUTICALS": {
              "CLINDACIN ETZ": "CLINDACIN ETZ",
              "CLINDACIN-P": "CLINDACIN-P"
            },
            "MORTON GROVE PHARMACEUTICALS": {
              "ERYTHROMYCIN": "ERYTHROMYCIN"
            },
            "MYLAN": {
              "ERYGEL": "ERYGEL",
              "ERYTHROMYCIN": "ERYTHROMYCIN",
              "EVOCLIN": "EVOCLIN"
            },
            "NORTHSTAR RX": {
              "DAPSONE": "DAPSONE"
            },
            "OCEANSIDE PHARMACEUTICALS": {
              "CLINDAMYCIN PHOSPHATE": "CLINDAMYCIN PHOSPHATE",
              "SULFACETAMIDE SODIUM (ACNE)": "SULFACETAMIDE SODIUM (ACNE)"
            },
            "PAR PHARMACEUTICALS": {
              "CLINDAMYCIN PHOSPHATE": "CLINDAMYCIN PHOSPHATE"
            },
            "PERRIGO PHARMACEUTICALS": {
              "CLINDAMYCIN PHOSPHATE": "CLINDAMYCIN PHOSPHATE",
              "ERY": "ERY",
              "ERYTHROMYCIN": "ERYTHROMYCIN",
              "SULFACETAMIDE SODIUM (ACNE)": "SULFACETAMIDE SODIUM (ACNE)"
            },
            "PFIZER U.S.": {
              "CLEOCIN-T": "CLEOCIN-T"
            },
            "PHARMEDIX": {
              "CLINDAMYCIN PHOSPHATE": "CLINDAMYCIN PHOSPHATE",
              "ERYTHROMYCIN": "ERYTHROMYCIN"
            },
            "PREFERRED PHARMACEUTICALS": {
              "CLINDAMYCIN PHOSPHATE": "CLINDAMYCIN PHOSPHATE"
            },
            "PRESTIUM PHARMA": {
              "ERYGEL": "ERYGEL",
              "EVOCLIN": "EVOCLIN"
            },
            "QUALITY CARE": {
              "CLINDAMYCIN PHOSPHATE": "CLINDAMYCIN PHOSPHATE",
              "ERYTHROMYCIN": "ERYTHROMYCIN",
              "EVOCLIN": "EVOCLIN"
            },
            "SANDOZ": {
              "ERYTHROMYCIN": "ERYTHROMYCIN"
            },
            "SKY PACKAGING": {
              "ERYTHROMYCIN": "ERYTHROMYCIN"
            },
            "SOLA PHARMACEUTICALS": {
              "ERYTHROMYCIN": "ERYTHROMYCIN"
            },
            "SOLARIS PHARMA": {
              "CLINDAMYCIN PHOSPHATE": "CLINDAMYCIN PHOSPHATE"
            },
            "TARO": {
              "CLINDAMYCIN PHOSPHATE": "CLINDAMYCIN PHOSPHATE",
              "DAPSONE": "DAPSONE"
            },
            "TELIGENT": {
              "CLINDAMYCIN PHOSPHATE": "CLINDAMYCIN PHOSPHATE",
              "ERYTHROMYCIN": "ERYTHROMYCIN"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "CLINDAMYCIN PHOSPHATE": "CLINDAMYCIN PHOSPHATE"
            }
          },
          "Acne Cleansers": {
            "GLAXO SMITH KLINE": {
              "BRASIVOL": "BRASIVOL",
              "SASTID SOAP": "SASTID SOAP"
            },
            "JOHNSON&JOHNSON HEALTHCARE": {
              "CLEAN & CLEAR BLACKHEAD ERASER": "CLEAN & CLEAR BLACKHEAD ERASER"
            },
            "RITE AID CORPORATION": {
              "RA ACNE MAXIMUM": "RA ACNE MAXIMUM"
            }
          },
          "Acne Combinations": {
            "A-S MEDICATION SOLUTIONS": {
              "BENZACLIN": "BENZACLIN",
              "DUAC": "DUAC",
              "SULFACETAMIDE SODIUM-SULFUR": "SULFACETAMIDE SODIUM-SULFUR"
            },
            "ACCUMIX PHARMACEUTICALS": {
              "CLINDAP-T": "CLINDAP-T",
              "TRISEON": "TRISEON"
            },
            "ACELLA PHARMACEUTICALS": {
              "BP 10-1": "BP 10-1",
              "BP CLEANSING WASH": "BP CLEANSING WASH",
              "SSS 10-5": "SSS 10-5",
              "SULFACETAMIDE SODIUM-SULFUR": "SULFACETAMIDE SODIUM-SULFUR"
            },
            "ACTAVIS MID ATLANTIC": {
              "ADAPALENE-BENZOYL PEROXIDE": "ADAPALENE-BENZOYL PEROXIDE",
              "CLINDAMYCIN-TRETINOIN": "CLINDAMYCIN-TRETINOIN"
            },
            "ACTAVIS PHARMA": {
              "CLINDAMYCIN PHOS-BENZOYL PEROX": "CLINDAMYCIN PHOS-BENZOYL PEROX"
            },
            "ALMIRALL": {
              "VELTIN": "VELTIN"
            },
            "ARTESA LABS": {
              "PLEXION": "PLEXION",
              "PLEXION CLEANSER": "PLEXION CLEANSER",
              "PLEXION CLEANSING CLOTH": "PLEXION CLEANSING CLOTH"
            },
            "AVION PHARMACEUTICALS": {
              "ROSULA": "ROSULA",
              "ROSULA WASH": "ROSULA WASH"
            },
            "BAUSCH HEALTH": {
              "ACANYA": "ACANYA",
              "BENZACLIN": "BENZACLIN",
              "BENZACLIN WITH PUMP": "BENZACLIN WITH PUMP",
              "BENZAMYCIN": "BENZAMYCIN",
              "CLARIFOAM EF": "CLARIFOAM EF",
              "ONEXTON": "ONEXTON",
              "ZIANA": "ZIANA"
            },
            "BI COASTAL PHARMACEUTICAL": {
              "SULFACETAMIDE SODIUM-SULFUR": "SULFACETAMIDE SODIUM-SULFUR"
            },
            "BIOCOMP PHARMA": {
              "SULFACETAMIDE SOD-SULFUR WASH": "SULFACETAMIDE SOD-SULFUR WASH",
              "SULFACETAMIDE SODIUM-SULFUR": "SULFACETAMIDE SODIUM-SULFUR",
              "SULFACETAMIDE-SULFUR-SUNSCREEN": "SULFACETAMIDE-SULFUR-SUNSCREEN"
            },
            "BUREL PHARMACEUTICALS": {
              "SULFACETAMIDE SODIUM-SULFUR": "SULFACETAMIDE SODIUM-SULFUR"
            },
            "BURKE THERAPEUTICS": {
              "CLINOIN": "CLINOIN"
            },
            "CAMERON PHARMACEUTICALS": {
              "SULFACETAMIDE SODIUM-SULFUR": "SULFACETAMIDE SODIUM-SULFUR"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "CLARIFOAM EF": "CLARIFOAM EF",
              "CLINDAMYCIN PHOS-BENZOYL PEROX": "CLINDAMYCIN PHOS-BENZOYL PEROX",
              "SUMADAN WASH": "SUMADAN WASH",
              "VELTIN": "VELTIN"
            },
            "CUTANEA LIFE SCIENCES": {
              "AKTIPAK": "AKTIPAK"
            },
            "DSE HEALTHCARE SOLUTIONS": {
              "ADULT ACNOMEL": "ADULT ACNOMEL"
            },
            "ECR PHARMACEUTICALS": {
              "BENSULFOID": "BENSULFOID"
            },
            "EPI HEALTH": {
              "INOVA": "INOVA",
              "INOVA 4/1 ACNE CONTROL THERAPY": "INOVA 4/1 ACNE CONTROL THERAPY",
              "INOVA 8/2 ACNE CONTROL THERAPY": "INOVA 8/2 ACNE CONTROL THERAPY"
            },
            "EXACT-RX": {
              "SULFACETAMIDE SODIUM-SULFUR": "SULFACETAMIDE SODIUM-SULFUR",
              "SULFACETAMIDE-SULFUR IN UREA": "SULFACETAMIDE-SULFUR IN UREA"
            },
            "GALDERMA": {
              "EPIDUO": "EPIDUO",
              "EPIDUO FORTE": "EPIDUO FORTE",
              "ROSANIL CLEANSER": "ROSANIL CLEANSER"
            },
            "GENESIS PHARMACEUTICAL": {
              "ACNOTEX": "ACNOTEX"
            },
            "GLAXO SMITH KLINE": {
              "DUAC": "DUAC"
            },
            "GLENMARK PHARMACEUTICALS": {
              "ADAPALENE-BENZOYL PEROXIDE": "ADAPALENE-BENZOYL PEROXIDE",
              "CLINDAMYCIN PHOS-BENZOYL PEROX": "CLINDAMYCIN PHOS-BENZOYL PEROX"
            },
            "HAWTHORN PHARMACEUTICALS": {
              "ZACARE": "ZACARE"
            },
            "J & J CONSUMER PRODUCTS": {
              "CLEAN & CLEAR ACNE CONTROL": "CLEAN & CLEAR ACNE CONTROL"
            },
            "KMM PHARMACEUTICALS": {
              "SULFACETAMIDE SODIUM-SULFUR": "SULFACETAMIDE SODIUM-SULFUR"
            },
            "MARNEL PHARMACEUTICAL": {
              "VANOXIDE-HC": "VANOXIDE-HC"
            },
            "MEDCO LAB": {
              "RA LOTION": "RA LOTION"
            },
            "MEDIMETRIKS PHARMACEUTICALS": {
              "CLINDACIN ETZ": "CLINDACIN ETZ",
              "CLINDACIN PAC": "CLINDACIN PAC",
              "NEUAC": "NEUAC",
              "SUMADAN": "SUMADAN",
              "SUMADAN WASH": "SUMADAN WASH",
              "SUMADAN XLT": "SUMADAN XLT",
              "SUMAXIN": "SUMAXIN",
              "SUMAXIN CP": "SUMAXIN CP",
              "SUMAXIN TS": "SUMAXIN TS",
              "SUMAXIN WASH": "SUMAXIN WASH"
            },
            "MESORA PHARMA": {
              "BENZOYL PEROX-HYDROCORTISONE": "BENZOYL PEROX-HYDROCORTISONE",
              "BENZOYL PEROXIDE FORTE- HC": "BENZOYL PEROXIDE FORTE- HC",
              "RESORCINOL-SULFUR": "RESORCINOL-SULFUR",
              "SULFAMEZ WASH": "SULFAMEZ WASH"
            },
            "METHOD PHARMACEUTICALS": {
              "SULFACETAMIDE SODIUM-SULFUR": "SULFACETAMIDE SODIUM-SULFUR"
            },
            "MISSION": {
              "AVAR": "AVAR",
              "AVAR CLEANSER": "AVAR CLEANSER",
              "AVAR LS": "AVAR LS",
              "AVAR LS CLEANSER": "AVAR LS CLEANSER",
              "AVAR-E EMOLLIENT": "AVAR-E EMOLLIENT",
              "AVAR-E GREEN": "AVAR-E GREEN",
              "AVAR-E LS": "AVAR-E LS"
            },
            "MYLAN": {
              "CLINDAMYCIN PHOS-BENZOYL PEROX": "CLINDAMYCIN PHOS-BENZOYL PEROX"
            },
            "NOBLE PHARMACEUTICALS": {
              "SULFACETAMIDE SODIUM-SULFUR": "SULFACETAMIDE SODIUM-SULFUR"
            },
            "NORTHSTAR RX": {
              "CLINDAMYCIN PHOS-BENZOYL PEROX": "CLINDAMYCIN PHOS-BENZOYL PEROX"
            },
            "NUCARE PHARMACEUTICALS": {
              "NUCARACLINPAK": "NUCARACLINPAK",
              "NUCARARXPAK": "NUCARARXPAK"
            },
            "OCEANSIDE PHARMACEUTICALS": {
              "BENZOYL PEROXIDE-ERYTHROMYCIN": "BENZOYL PEROXIDE-ERYTHROMYCIN",
              "CLINDAMYCIN PHOS-BENZOYL PEROX": "CLINDAMYCIN PHOS-BENZOYL PEROX",
              "CLINDAMYCIN-TRETINOIN": "CLINDAMYCIN-TRETINOIN"
            },
            "PERRIGO PHARMACEUTICALS": {
              "ADAPALENE-BENZOYL PEROXIDE": "ADAPALENE-BENZOYL PEROXIDE",
              "CLINDAMYCIN PHOS-BENZOYL PEROX": "CLINDAMYCIN PHOS-BENZOYL PEROX"
            },
            "PRASCO LABORATORIES": {
              "ADAPALENE-BENZOYL PEROXIDE": "ADAPALENE-BENZOYL PEROXIDE",
              "CLINDAMYCIN PHOS-BENZOYL PEROX": "CLINDAMYCIN PHOS-BENZOYL PEROX",
              "CLINDAMYCIN-TRETINOIN": "CLINDAMYCIN-TRETINOIN"
            },
            "PRUGEN": {
              "SULFACLEANSE 8/4": "SULFACLEANSE 8/4"
            },
            "QUALITY CARE": {
              "BENZACLIN WITH PUMP": "BENZACLIN WITH PUMP",
              "EPIDUO": "EPIDUO"
            },
            "RECKITT BENCKISER": {
              "CLEARASIL DAILY CLEAR": "CLEARASIL DAILY CLEAR"
            },
            "RISING PHARMACEUTICALS": {
              "BENZOYL PEROXIDE-ERYTHROMYCIN": "BENZOYL PEROXIDE-ERYTHROMYCIN"
            },
            "ROCHESTER PHARMACEUTICALS": {
              "SULFACETAMIDE SODIUM-SULFUR": "SULFACETAMIDE SODIUM-SULFUR"
            },
            "ROSEMAR LABS": {
              "SULFACETAMIDE SODIUM-SULFUR": "SULFACETAMIDE SODIUM-SULFUR"
            },
            "SANDOZ": {
              "ADAPALENE-BENZOYL PEROXIDE": "ADAPALENE-BENZOYL PEROXIDE",
              "BENZOYL PEROXIDE-ERYTHROMYCIN": "BENZOYL PEROXIDE-ERYTHROMYCIN",
              "CLINDAMYCIN PHOS-BENZOYL PEROX": "CLINDAMYCIN PHOS-BENZOYL PEROX"
            },
            "SETON PHARMACEUTICALS": {
              "SULFACETAMIDE-SULFUR IN UREA": "SULFACETAMIDE-SULFUR IN UREA"
            },
            "SINCERUS FLORIDA": {
              "ADAPALENE-BENZOYL PER-CLINDAMY": "ADAPALENE-BENZOYL PER-CLINDAMY",
              "ADAPALENE-BENZOYL PER-NIACINAM": "ADAPALENE-BENZOYL PER-NIACINAM",
              "AZELAIC ACID-NIACINAMIDE": "AZELAIC ACID-NIACINAMIDE",
              "BENZ PER-CLIND-NIACIN-TRETIN": "BENZ PER-CLIND-NIACIN-TRETIN",
              "CLIND-NIACIN-SPIRONOLAC-TRETIN": "CLIND-NIACIN-SPIRONOLAC-TRETIN",
              "CLINDAMY-BENZOYL PER-NIACINAM": "CLINDAMY-BENZOYL PER-NIACINAM",
              "CLINDAMYCIN PHOS-NIACINAMIDE": "CLINDAMYCIN PHOS-NIACINAMIDE",
              "CLINDAMYCIN-NIACIN-TRETINOIN": "CLINDAMYCIN-NIACIN-TRETINOIN",
              "DAPSONE-NIACINAMIDE": "DAPSONE-NIACINAMIDE",
              "DAPSONE-NIACINAMIDE-SPIRONOLAC": "DAPSONE-NIACINAMIDE-SPIRONOLAC",
              "HYALURONATE-NIACINAM-TRETINOIN": "HYALURONATE-NIACINAM-TRETINOIN",
              "NIACIN-SPIRONOLACTON-TRETINOIN": "NIACIN-SPIRONOLACTON-TRETINOIN",
              "NIACINAMIDE-SPIRONOLACTONE": "NIACINAMIDE-SPIRONOLACTONE",
              "NIACINAMIDE-SULFACETAMIDE": "NIACINAMIDE-SULFACETAMIDE",
              "NIACINAMIDE-TAZAROTENE": "NIACINAMIDE-TAZAROTENE",
              "NIACINAMIDE-TRETINOIN": "NIACINAMIDE-TRETINOIN",
              "SALICYLIC ACID-SULFACETAMIDE": "SALICYLIC ACID-SULFACETAMIDE"
            },
            "SOLUTECH PHARMACEUTICALS": {
              "REZESOL": "REZESOL"
            },
            "STRATUS PHARMACEUTICALS": {
              "CERISA WASH": "CERISA WASH",
              "ZENCIA": "ZENCIA"
            },
            "SUMMERS LABS": {
              "REZAMID": "REZAMID",
              "SEBASORB": "SEBASORB"
            },
            "TARO": {
              "ADAPALENE-BENZOYL PEROXIDE": "ADAPALENE-BENZOYL PEROXIDE",
              "CLINDAMYCIN PHOS-BENZOYL PEROX": "CLINDAMYCIN PHOS-BENZOYL PEROX"
            },
            "TOTAL PHARMACY SUPPLY": {
              "DERMAPAK PLUS": "DERMAPAK PLUS"
            },
            "TRINITY PHARMACEUTICALS": {
              "SULFACETAMIDE SODIUM-SULFUR": "SULFACETAMIDE SODIUM-SULFUR"
            },
            "WESTMINSTER PHARMACEUTICALS": {
              "SULFACETAMIDE SODIUM-SULFUR": "SULFACETAMIDE SODIUM-SULFUR"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "CLINDAMYCIN PHOS-BENZOYL PEROX": "CLINDAMYCIN PHOS-BENZOYL PEROX"
            }
          },
          "Acne Products": {
            "A-S MEDICATION SOLUTIONS": {
              "ACNE MEDICATION 10": "ACNE MEDICATION 10",
              "ACNE MEDICATION 5": "ACNE MEDICATION 5",
              "ADAPALENE": "ADAPALENE",
              "BENZOYL PEROXIDE": "BENZOYL PEROXIDE",
              "DIFFERIN": "DIFFERIN",
              "RETIN-A": "RETIN-A",
              "RETIN-A MICRO": "RETIN-A MICRO",
              "TRETINOIN": "TRETINOIN"
            },
            "ACELLA PHARMACEUTICALS": {
              "BP FOAMING WASH": "BP FOAMING WASH",
              "BPO": "BPO",
              "BPO CREAMY WASH": "BPO CREAMY WASH",
              "BPO FOAMING CLOTHS": "BPO FOAMING CLOTHS"
            },
            "ACTAVIS": {
              "TRETIN-X": "TRETIN-X"
            },
            "ACTAVIS MID ATLANTIC": {
              "ADAPALENE": "ADAPALENE",
              "TRETINOIN": "TRETINOIN"
            },
            "AIDAREX PHARMACEUTICALS": {
              "BENZOYL PEROXIDE": "BENZOYL PEROXIDE",
              "TRETINOIN": "TRETINOIN"
            },
            "AKORN": {
              "MYORISAN": "MYORISAN"
            },
            "ALEMBIC PHARMACEUTICALS": {
              "ADAPALENE": "ADAPALENE"
            },
            "ALLEGIS HOLDINGS": {
              "ADAPALENE": "ADAPALENE"
            },
            "ALLEGIS PHARMACEUTICALS": {
              "ADAPALENE": "ADAPALENE"
            },
            "ALMIRALL": {
              "AZELEX": "AZELEX"
            },
            "ALTAIRE": {
              "ACNE-CLEAR": "ACNE-CLEAR",
              "CLEARSKIN": "CLEARSKIN"
            },
            "AMERICAN PHARMA INGREDIENTS": {
              "TRETINOIN": "TRETINOIN"
            },
            "AMERICAN SALES COMPANY": {
              "INVISIBLE ACNE TREATMENT": "INVISIBLE ACNE TREATMENT"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP ACNE TREATMENT": "GNP ACNE TREATMENT"
            },
            "AMNEAL PHARMACEUTICALS": {
              "ISOTRETINOIN": "ISOTRETINOIN"
            },
            "ARTESA LABS": {
              "RIAX": "RIAX"
            },
            "BAUSCH HEALTH": {
              "ACNEFREE ACNE CLEARING SYSTEM": "ACNEFREE ACNE CLEARING SYSTEM",
              "ACNEFREE SEVERE CLEARING SYST": "ACNEFREE SEVERE CLEARING SYST",
              "ALTRENO": "ALTRENO",
              "ARAZLO": "ARAZLO",
              "ATRALIN": "ATRALIN",
              "BENZEFOAM": "BENZEFOAM",
              "RETIN-A": "RETIN-A",
              "RETIN-A MICRO": "RETIN-A MICRO",
              "RETIN-A MICRO PUMP": "RETIN-A MICRO PUMP"
            },
            "BERGEN BRUNSWIG": {
              "GNP ACNE TREATMENT": "GNP ACNE TREATMENT"
            },
            "BPI LABS LLC": {
              "TRETINOIN": "TRETINOIN"
            },
            "C S  DENT": {
              "GRANDPAS THYLOX SOAP": "GRANDPAS THYLOX SOAP"
            },
            "CHAIN DRUG CONSORTIUM": {
              "ACNE PIMPLE MEDICATION": "ACNE PIMPLE MEDICATION",
              "INVISIBLE ACNE MAX ST": "INVISIBLE ACNE MAX ST"
            },
            "CINTEX": {
              "BENZOYL PEROXIDE WASH": "BENZOYL PEROXIDE WASH",
              "BP FOAM": "BP FOAM",
              "BP GEL": "BP GEL",
              "BP WASH": "BP WASH"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "ABSORICA": "ABSORICA",
              "ADAPALENE": "ADAPALENE",
              "ATRALIN": "ATRALIN",
              "BENZEFOAMULTRA": "BENZEFOAMULTRA",
              "DIFFERIN": "DIFFERIN",
              "TRETINOIN": "TRETINOIN"
            },
            "CROWN LABORATORIES": {
              "PANOXYL CREAMY WASH": "PANOXYL CREAMY WASH",
              "PANOXYL FOAMING WASH": "PANOXYL FOAMING WASH",
              "SULFO LO": "SULFO LO",
              "SULFO-LO": "SULFO-LO"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS ACNE": "CVS ACNE",
              "CVS ACNE CLEANSING": "CVS ACNE CLEANSING",
              "CVS ACNE CONTROL CLEANSER": "CVS ACNE CONTROL CLEANSER",
              "CVS ACNE FOAMING FACE WASH": "CVS ACNE FOAMING FACE WASH",
              "CVS ACNE TREATMENT": "CVS ACNE TREATMENT",
              "CVS ADVANCED 3-IN-1 CLEANSER": "CVS ADVANCED 3-IN-1 CLEANSER",
              "CVS CREAMY ACNE FACE WASH": "CVS CREAMY ACNE FACE WASH",
              "CVS FOAMING ACNE FACE WASH": "CVS FOAMING ACNE FACE WASH",
              "CVS TARGETED ACNE SPOT": "CVS TARGETED ACNE SPOT"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "ZENATANE": "ZENATANE"
            },
            "E.T. BROWNE DRUG CO": {
              "PALMERS SKIN SUCCESS": "PALMERS SKIN SUCCESS"
            },
            "ELORAC": {
              "ADVANCED ACNE WASH": "ADVANCED ACNE WASH"
            },
            "ENCORE DERMATOLOGY": {
              "BENZEFOAM": "BENZEFOAM",
              "BENZEFOAMULTRA": "BENZEFOAMULTRA",
              "BENZOYL PEROXIDE": "BENZOYL PEROXIDE",
              "BENZOYL PEROXIDE SHORT CONTACT": "BENZOYL PEROXIDE SHORT CONTACT"
            },
            "ERMIS LABS": {
              "ACNE TREATMENT": "ACNE TREATMENT"
            },
            "FAGRON": {
              "RETINOIC ACID": "RETINOIC ACID"
            },
            "FERNDALE LAB": {
              "OC8": "OC8"
            },
            "FOUGERA": {
              "ADAPALENE": "ADAPALENE"
            },
            "FREEDOM PHARMACEUTICALS": {
              "TRETINOIN": "TRETINOIN"
            },
            "GALDERMA": {
              "AKLIEF": "AKLIEF",
              "BENZAC AC WASH": "BENZAC AC WASH",
              "DIFFERIN": "DIFFERIN"
            },
            "GERITREX CORPORATION": {
              "BENZOYL PEROXIDE": "BENZOYL PEROXIDE"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "PANOXYL": "PANOXYL",
              "PANOXYL WASH": "PANOXYL WASH",
              "PANOXYL-4 CREAMY WASH": "PANOXYL-4 CREAMY WASH"
            },
            "GLAXO SMITH KLINE": {
              "PANOXYL": "PANOXYL",
              "SULFUR": "SULFUR"
            },
            "GLENMARK PHARMACEUTICALS": {
              "ADAPALENE": "ADAPALENE"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "BENZOYL PEROXIDE": "BENZOYL PEROXIDE",
              "RETIN-A": "RETIN-A",
              "TRETINOIN": "TRETINOIN"
            },
            "HARRIS PHARMACEUTICAL": {
              "BENZOYL PEROXIDE CLEANSER": "BENZOYL PEROXIDE CLEANSER",
              "BENZOYL PEROXIDE WASH": "BENZOYL PEROXIDE WASH"
            },
            "HAWTHORN PHARMACEUTICALS": {
              "ZACLIR CLEANSING": "ZACLIR CLEANSING"
            },
            "HUMCO": {
              "TRETINOIN": "TRETINOIN"
            },
            "J & J CONSUMER PRODUCTS": {
              "CLEAN & CLEAR ADVANTAGE 3-IN-1": "CLEAN & CLEAR ADVANTAGE 3-IN-1",
              "CLEAN & CLEAR CONTINUOUS": "CLEAN & CLEAR CONTINUOUS",
              "CLEAN & CLEAR PERSA-GEL MAX ST": "CLEAN & CLEAR PERSA-GEL MAX ST"
            },
            "KAISER FOUNDATION HOSPITAL": {
              "KP BENZOYL PEROXIDE": "KP BENZOYL PEROXIDE",
              "KP BENZOYL PEROXIDE WASH": "KP BENZOYL PEROXIDE WASH"
            },
            "L'OREAL": {
              "CERAVE ACNE FOAMING CREAM": "CERAVE ACNE FOAMING CREAM",
              "EFFACLAR DUO": "EFFACLAR DUO"
            },
            "LACER PHARMA": {
              "ENZOCLEAR": "ENZOCLEAR"
            },
            "LAYDAN LABS": {
              "BP GEL": "BP GEL"
            },
            "LEADER BRAND PRODUCTS": {
              "ADAPALENE": "ADAPALENE"
            },
            "LETCO MEDICAL": {
              "BENZOYL PEROXIDE HYDROUS": "BENZOYL PEROXIDE HYDROUS",
              "RETINOIC ACID": "RETINOIC ACID"
            },
            "MAYNE PHARMA": {
              "FABIOR": "FABIOR"
            },
            "MED-DERM": {
              "CLEARPLEX V": "CLEARPLEX V",
              "CLEARPLEX X": "CLEARPLEX X"
            },
            "MEDICIS": {
              "BENZIQ": "BENZIQ",
              "BENZIQ LS": "BENZIQ LS",
              "BENZIQ WASH": "BENZIQ WASH"
            },
            "MEDISCA": {
              "TRETINOIN": "TRETINOIN"
            },
            "MESORA PHARMA": {
              "BENZOYL PEROXIDE": "BENZOYL PEROXIDE"
            },
            "MYLAN": {
              "AMNESTEEM": "AMNESTEEM",
              "AVITA": "AVITA",
              "TRETINOIN": "TRETINOIN",
              "TRETINOIN MICROSPHERE": "TRETINOIN MICROSPHERE"
            },
            "NEUTROGENA": {
              "NEUTROGENA CLEAR PORE": "NEUTROGENA CLEAR PORE",
              "NEUTROGENA ON-THE-SPOT": "NEUTROGENA ON-THE-SPOT"
            },
            "OCEANSIDE PHARMACEUTICALS": {
              "TRETINOIN": "TRETINOIN",
              "TRETINOIN MICROSPHERE": "TRETINOIN MICROSPHERE",
              "TRETINOIN MICROSPHERE PUMP": "TRETINOIN MICROSPHERE PUMP"
            },
            "ONSET DERMATOLOGICS": {
              "TRETIN-X": "TRETIN-X"
            },
            "OWEN LABORATORIES": {
              "ADAPALENE": "ADAPALENE"
            },
            "PCCA": {
              "BENZOYL PEROXIDE": "BENZOYL PEROXIDE",
              "TRETINOIN": "TRETINOIN"
            },
            "PERRIGO": {
              "TRETINOIN": "TRETINOIN"
            },
            "PERRIGO PHARMACEUTICALS": {
              "ADAPALENE": "ADAPALENE",
              "BENZOYL PEROXIDE": "BENZOYL PEROXIDE",
              "BENZOYL PEROXIDE WASH": "BENZOYL PEROXIDE WASH"
            },
            "PRASCO LABORATORIES": {
              "ADAPALENE": "ADAPALENE",
              "BENZOYL PEROXIDE CLEANSER": "BENZOYL PEROXIDE CLEANSER",
              "OSCION CLEANSER": "OSCION CLEANSER"
            },
            "PREFERRED PHARMACEUTICALS": {
              "TRETINOIN": "TRETINOIN"
            },
            "PRUGEN": {
              "ADAPALENE": "ADAPALENE",
              "BENZEPRO": "BENZEPRO",
              "BENZEPRO CREAMY WASH": "BENZEPRO CREAMY WASH",
              "BENZEPRO FOAMING CLOTHS": "BENZEPRO FOAMING CLOTHS",
              "BENZEPRO SHORT CONTACT": "BENZEPRO SHORT CONTACT",
              "PR BENZOYL PEROXIDE": "PR BENZOYL PEROXIDE",
              "PR BENZOYL PEROXIDE WASH": "PR BENZOYL PEROXIDE WASH"
            },
            "QUALITY CARE": {
              "AZELEX": "AZELEX",
              "BENZOYL PEROXIDE": "BENZOYL PEROXIDE",
              "BENZOYL PEROXIDE WASH": "BENZOYL PEROXIDE WASH",
              "DIFFERIN": "DIFFERIN",
              "RETIN-A": "RETIN-A",
              "RETIN-A MICRO": "RETIN-A MICRO",
              "RETIN-A MICRO PUMP": "RETIN-A MICRO PUMP"
            },
            "RANBAXY LABORATORIES": {
              "ABSORICA": "ABSORICA",
              "ABSORICA LD": "ABSORICA LD",
              "DESQUAM-X WASH": "DESQUAM-X WASH"
            },
            "RECKITT BENCKISER": {
              "CLEARASIL DAILY CLEAR ACNE": "CLEARASIL DAILY CLEAR ACNE"
            },
            "RITE AID CORPORATION": {
              "RA ACNE TREATMENT": "RA ACNE TREATMENT",
              "RA DAYLOGIC ACNE FOAMING WASH": "RA DAYLOGIC ACNE FOAMING WASH",
              "RA RENEWAL ACNE TREATMENT": "RA RENEWAL ACNE TREATMENT",
              "RA VANISHING ACNE TREATMENT": "RA VANISHING ACNE TREATMENT"
            },
            "ROCHESTER PHARMACEUTICALS": {
              "ADAPALENE": "ADAPALENE"
            },
            "ROUSES POINT": {
              "TRETINOIN": "TRETINOIN"
            },
            "RUGBY LABORATORIES": {
              "ACNE MEDICATION 10": "ACNE MEDICATION 10",
              "ACNE MEDICATION 2.5": "ACNE MEDICATION 2.5",
              "ACNE MEDICATION 5": "ACNE MEDICATION 5",
              "BENZOYL PEROXIDE WASH": "BENZOYL PEROXIDE WASH"
            },
            "SANDOZ": {
              "ADAPALENE": "ADAPALENE"
            },
            "SPEAR DERMATOLOGY PRODUCTS": {
              "TRETINOIN": "TRETINOIN",
              "TRETINOIN MICROSPHERE": "TRETINOIN MICROSPHERE"
            },
            "SPECTRUM": {
              "BENZOYL PEROXIDE HYDROUS": "BENZOYL PEROXIDE HYDROUS",
              "TRETINOIN": "TRETINOIN"
            },
            "SUMMERS LABS": {
              "LIQUIMAT": "LIQUIMAT"
            },
            "TAGI PHARMA": {
              "BENZOYL PEROXIDE": "BENZOYL PEROXIDE"
            },
            "TARO": {
              "ADAPALENE": "ADAPALENE",
              "TRETINOIN": "TRETINOIN"
            },
            "TEVA PHARMACEUTICALS USA": {
              "ADAPALENE": "ADAPALENE",
              "CLARAVIS": "CLARAVIS"
            },
            "TRIAX PHARMACEUTICALS": {
              "TRETINOIN": "TRETINOIN"
            },
            "TRIMARC LABORATORIES": {
              "BENZOYL PEROXIDE": "BENZOYL PEROXIDE",
              "BPO-10 WASH": "BPO-10 WASH",
              "BPO-5 WASH": "BPO-5 WASH"
            },
            "WALGREENS": {
              "ACNE FOAMING WASH": "ACNE FOAMING WASH",
              "ACNE MAXIMUM STRENGTH": "ACNE MAXIMUM STRENGTH",
              "ACNE TREATMENT": "ACNE TREATMENT",
              "BENZOYL PEROXIDE": "BENZOYL PEROXIDE",
              "SPOT ACNE TREATMENT": "SPOT ACNE TREATMENT"
            }
          }
        },
        "Agents for External Genital and Perianal Warts": {
          "Agents for External Genital and Perianal Warts": {
            "SANDOZ": {
              "VEREGEN": "VEREGEN"
            }
          }
        },
        "Agents for Wrinkles/Lipoatrophy/Other Aesthetic Uses": {
          "Agents for Facial Lipoatrophy": {
            "GALDERMA": {
              "SCULPTRA": "SCULPTRA"
            }
          },
          "Agents for Facial Wrinkles - Dermal Fillers": {
            "BAUSCH HEALTH": {
              "PERLANE": "PERLANE",
              "PERLANE-L": "PERLANE-L",
              "RESTYLANE": "RESTYLANE",
              "RESTYLANE-L": "RESTYLANE-L"
            }
          },
          "Agents for Facial Wrinkles - Retinoids": {
            "ALLERGAN": {
              "AVAGE": "AVAGE"
            },
            "BAUSCH HEALTH": {
              "RENOVA": "RENOVA",
              "RENOVA PUMP": "RENOVA PUMP"
            },
            "ZO SKIN HEALTH": {
              "REFISSA": "REFISSA",
              "TRETINOIN (EMOLLIENT)": "TRETINOIN (EMOLLIENT)"
            }
          },
          "Agents for Submental Fat": {
            "ALLERGAN": {
              "KYBELLA": "KYBELLA"
            }
          }
        },
        "Analgesics - Topical": {
          "Analgesic Combinations - Topical": {
            "ALEXSO": {
              "A.A.G.C. KIT IN TERODERM": "A.A.G.C. KIT IN TERODERM"
            },
            "PHARMACEUTICA NORTH AMERICA": {
              "ACTIVE-PREP KIT IV": "ACTIVE-PREP KIT IV"
            }
          },
          "Analgesics - Topical": {
            "ADVANTICE HEALTH": {
              "JOINTFLEX NO MESS ROLL-ON": "JOINTFLEX NO MESS ROLL-ON"
            },
            "AIDAREX PHARMACEUTICALS": {
              "ENOVARX-BACLOFEN": "ENOVARX-BACLOFEN"
            },
            "ALEXSO": {
              "MENTHOL (TOPICAL ANALGESIC)": "MENTHOL (TOPICAL ANALGESIC)"
            },
            "ALVIX LABORATORIES": {
              "EQUIPTO-BACLOFEN": "EQUIPTO-BACLOFEN"
            },
            "AMERICAN SALES COMPANY": {
              "THERAPEUTIC MENTHOL": "THERAPEUTIC MENTHOL"
            },
            "BEIERSDORF DERMA": {
              "EUCERIN SKIN CALMING": "EUCERIN SKIN CALMING"
            },
            "BELL-HORN": {
              "FAST FREEZE PRO STYLE THERAPY": "FAST FREEZE PRO STYLE THERAPY"
            },
            "BERGEN BRUNSWIG": {
              "GNP THERAPEUTIC BLUE": "GNP THERAPEUTIC BLUE"
            },
            "BINGER CONSULTING": {
              "THRITEX": "THRITEX"
            },
            "CAL PHARMA": {
              "ULTRACIN M": "ULTRACIN M",
              "ULTRACIN-M": "ULTRACIN-M"
            },
            "CHAIN DRUG CONSORTIUM": {
              "COLD & HOT": "COLD & HOT",
              "COLD & HOT MEDICATED": "COLD & HOT MEDICATED",
              "ICE BLUE": "ICE BLUE",
              "PAIN RELIEF MAXIMUM STRENGTH": "PAIN RELIEF MAXIMUM STRENGTH",
              "PAIN RELIEVING ULTRA ST": "PAIN RELIEVING ULTRA ST"
            },
            "CHATTEM INC": {
              "ASPERCREME HEAT": "ASPERCREME HEAT",
              "ASPERCREME MAX ROLL-ON": "ASPERCREME MAX ROLL-ON",
              "FLEXALL": "FLEXALL",
              "GOLD BOND FOOT SPRAY MAX ST": "GOLD BOND FOOT SPRAY MAX ST",
              "GOLD BOND PAIN RELIEVING FOOT": "GOLD BOND PAIN RELIEVING FOOT",
              "ICY HOT": "ICY HOT",
              "ICY HOT ADVANCED RELIEF": "ICY HOT ADVANCED RELIEF",
              "ICY HOT BACK": "ICY HOT BACK",
              "ICY HOT BACK EXTRA STRENGTH": "ICY HOT BACK EXTRA STRENGTH",
              "ICY HOT MEDICATED SPRAY": "ICY HOT MEDICATED SPRAY",
              "ICY HOT NATURALS": "ICY HOT NATURALS",
              "ICY HOT PAIN RELIEVING": "ICY HOT PAIN RELIEVING",
              "ICY HOT PM": "ICY HOT PM",
              "ICY HOT POWER": "ICY HOT POWER",
              "ICY HOT SLEEVE": "ICY HOT SLEEVE"
            },
            "CORGANICS": {
              "RELIEF PAIN RELIEVING": "RELIEF PAIN RELIEVING"
            },
            "CROWN LABORATORIES": {
              "MINERAL ICE": "MINERAL ICE"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS COLD & HOT MEDICATED": "CVS COLD & HOT MEDICATED",
              "CVS PAIN PADS": "CVS PAIN PADS",
              "CVS PAIN RELIEVING ULTRA ST": "CVS PAIN RELIEVING ULTRA ST",
              "CVS SORE MUSCLE RUB": "CVS SORE MUSCLE RUB",
              "CVS THERAPEUTIC MENTHOL": "CVS THERAPEUTIC MENTHOL"
            },
            "ENOVACHEM MANUFACTURING": {
              "ENOVARX-BACLOFEN": "ENOVARX-BACLOFEN",
              "ENOVARX-TRAMADOL": "ENOVARX-TRAMADOL"
            },
            "G2 PRODUCTS": {
              "ARTHRITIS WONDER": "ARTHRITIS WONDER"
            },
            "GEISS DESTIN & DUNN": {
              "GOODSENSE COLD/HOT MEDICATED": "GOODSENSE COLD/HOT MEDICATED"
            },
            "GERITREX CORPORATION": {
              "BERRI-FREEZ PAIN RELIEVING": "BERRI-FREEZ PAIN RELIEVING",
              "MINERAL FREEZ": "MINERAL FREEZ"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "MINERAL ICE": "MINERAL ICE"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "POLAR FROST": "POLAR FROST"
            },
            "J & J CONSUMER PRODUCTS": {
              "BENGAY COLD THERAPY": "BENGAY COLD THERAPY",
              "BENGAY ULTRA STRENGTH": "BENGAY ULTRA STRENGTH",
              "BENGAY VANISHING SCENT": "BENGAY VANISHING SCENT",
              "BENGAY ZERO DEGREES": "BENGAY ZERO DEGREES"
            },
            "JOHNSON&JOHNSON HEALTHCARE": {
              "BENGAY ULTRA STRENGTH": "BENGAY ULTRA STRENGTH"
            },
            "JONATHAN INTERNATIONAL": {
              "BAMA FREEZE": "BAMA FREEZE"
            },
            "LEADER BRAND PRODUCTS": {
              "COLD & HOT MEDICATED": "COLD & HOT MEDICATED",
              "ICE BLUE": "ICE BLUE",
              "PAIN RELIEVING": "PAIN RELIEVING",
              "PAIN RELIEVING ULTRA ST": "PAIN RELIEVING ULTRA ST",
              "THERAPEUTIC ICE": "THERAPEUTIC ICE"
            },
            "LIQUIDCAPSULE MANUFACTURING": {
              "FREEZE IT FAST PAIN RELIEF": "FREEZE IT FAST PAIN RELIEF",
              "FREEZE IT PAIN RELIEF ROLL-ON": "FREEZE IT PAIN RELIEF ROLL-ON"
            },
            "MAJOR PHARMACEUTICALS": {
              "COLD/HOT PAIN RELIEF THERAPY": "COLD/HOT PAIN RELIEF THERAPY"
            },
            "MAXPAC": {
              "BACLOFEN": "BACLOFEN"
            },
            "MCKESSON": {
              "HM PAIN RELIEF THERAPY": "HM PAIN RELIEF THERAPY"
            },
            "MEDICINE SHOPPE": {
              "PAIN RELIEVING": "PAIN RELIEVING"
            },
            "METTLER ELECTRONICS": {
              "POLAR FROST": "POLAR FROST"
            },
            "NEW WORLD IMPORTS": {
              "MUSCLE & JOINT": "MUSCLE & JOINT"
            },
            "NFI CONSUMER PRODUCTS": {
              "BLUE-EMU MAXIMUM STRENGTH": "BLUE-EMU MAXIMUM STRENGTH"
            },
            "NUBRATORI": {
              "NEURAPTINE": "NEURAPTINE"
            },
            "PERFECTA PRODUCTS": {
              "ZIMS MAX-FREEZE": "ZIMS MAX-FREEZE"
            },
            "PERFORMANCE HEALTH PRODUCTS": {
              "BIOFREEZE": "BIOFREEZE",
              "BIOFREEZE COLORLESS": "BIOFREEZE COLORLESS",
              "BIOFREEZE ROLL-ON": "BIOFREEZE ROLL-ON",
              "BIOFREEZE ROLL-ON COLORLESS": "BIOFREEZE ROLL-ON COLORLESS",
              "PERFORM FOOT PAIN RELIEF": "PERFORM FOOT PAIN RELIEF",
              "PERFORM PAIN RELIEVING": "PERFORM PAIN RELIEVING",
              "PERFORM PAIN RELIEVING ROLL-ON": "PERFORM PAIN RELIEVING ROLL-ON"
            },
            "PHARMACEUTICA NORTH AMERICA": {
              "ACTIVE-TRAMADOL": "ACTIVE-TRAMADOL"
            },
            "PREFERRED PHARMACEUTICALS": {
              "BIOFREEZE": "BIOFREEZE"
            },
            "RITE AID CORPORATION": {
              "RA COLD & HOT SORE MUSCLE": "RA COLD & HOT SORE MUSCLE",
              "RA COLD/HOT MEDICATED": "RA COLD/HOT MEDICATED",
              "RA PAIN CARE MUSCLE & JOINT": "RA PAIN CARE MUSCLE & JOINT",
              "RA THERAPEUTIC BLUE": "RA THERAPEUTIC BLUE"
            },
            "RUGBY LABORATORIES": {
              "BLUE GEL": "BLUE GEL",
              "COOL THERAPY": "COOL THERAPY"
            },
            "SOLA PHARMACEUTICALS": {
              "DR GREENES GOOD STUFF": "DR GREENES GOOD STUFF"
            },
            "SOMBRA COSMETICS INC.": {
              "SOMBRA COOL THERAPY": "SOMBRA COOL THERAPY"
            },
            "STRATUS PHARMACEUTICALS": {
              "ARCTIC RELIEF PAIN RELIEVING": "ARCTIC RELIEF PAIN RELIEVING"
            },
            "TARGET": {
              "TGT COLD & HOT MEDICATED EX ST": "TGT COLD & HOT MEDICATED EX ST"
            },
            "TROY HEALTHCARE": {
              "STOPAIN": "STOPAIN",
              "STOPAIN ROLL-ON": "STOPAIN ROLL-ON"
            },
            "TWO OLD GOATS": {
              "TWO OLD GOATS ARTHRITIS": "TWO OLD GOATS ARTHRITIS"
            },
            "WAL-MART": {
              "EQ MENTHOL": "EQ MENTHOL"
            },
            "WALGREENS": {
              "COLD THERAPY PAIN RELIEF": "COLD THERAPY PAIN RELIEF",
              "COOL & HEAT": "COOL & HEAT",
              "COOL N HEAT": "COOL N HEAT",
              "COOL N HEAT ARM/NECK/LEG": "COOL N HEAT ARM/NECK/LEG",
              "COOL N HEAT EX ST": "COOL N HEAT EX ST",
              "COOL N HEAT MAXIMUM STRENGTH": "COOL N HEAT MAXIMUM STRENGTH",
              "COOL N HEAT/BACK": "COOL N HEAT/BACK",
              "ICE BLUE": "ICE BLUE",
              "PAIN RELIEVING ULTRA ST": "PAIN RELIEVING ULTRA ST"
            }
          }
        },
        "Anti-inflammatory Agents - Topical": {
          "Anti-inflammatory Agents - Topical": {
            "A-S MEDICATION SOLUTIONS": {
              "DICLOFENAC SODIUM": "DICLOFENAC SODIUM",
              "FLECTOR": "FLECTOR",
              "VOLTAREN": "VOLTAREN"
            },
            "ACTAVIS PHARMA": {
              "DICLOFENAC SODIUM": "DICLOFENAC SODIUM"
            },
            "AIDAREX PHARMACEUTICALS": {
              "ENOVARX-IBUPROFEN": "ENOVARX-IBUPROFEN",
              "ENOVARX-NAPROXEN": "ENOVARX-NAPROXEN",
              "FLECTOR": "FLECTOR",
              "VOLTAREN": "VOLTAREN"
            },
            "AKORN": {
              "DICLOFENAC SODIUM": "DICLOFENAC SODIUM"
            },
            "ALL PHARMA": {
              "DICLOFENAC SODIUM": "DICLOFENAC SODIUM"
            },
            "ALVIX LABORATORIES": {
              "DST PLUS PAK": "DST PLUS PAK",
              "EQUIPTO-NAPROXEN": "EQUIPTO-NAPROXEN"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP ARTHRITIS PAIN": "GNP ARTHRITIS PAIN"
            },
            "AMNEAL PHARMACEUTICALS": {
              "DICLOFENAC SODIUM": "DICLOFENAC SODIUM"
            },
            "APOTEX": {
              "DICLOFENAC SODIUM": "DICLOFENAC SODIUM"
            },
            "AVKARE": {
              "DICLOFENAC SODIUM": "DICLOFENAC SODIUM"
            },
            "BRYANT RANCH PREPACK": {
              "DICLOFENAC SODIUM": "DICLOFENAC SODIUM",
              "FLECTOR": "FLECTOR",
              "VOLTAREN": "VOLTAREN"
            },
            "CALIFORNIA PHARMACEUTICALS": {
              "KETOPHENE RAPIDPAQ": "KETOPHENE RAPIDPAQ"
            },
            "CIPLA USA": {
              "DICLOFENAC SODIUM": "DICLOFENAC SODIUM"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "FLECTOR": "FLECTOR",
              "VOLTAREN": "VOLTAREN"
            },
            "DIRECT RX": {
              "DICLOFENAC SODIUM": "DICLOFENAC SODIUM",
              "FLECTOR": "FLECTOR",
              "VOLTAREN": "VOLTAREN"
            },
            "ENCUBE ETHICALS": {
              "DICLOFENAC SODIUM": "DICLOFENAC SODIUM"
            },
            "ENDO PHARMACEUTICALS": {
              "VOLTAREN": "VOLTAREN"
            },
            "ENOVACHEM MANUFACTURING": {
              "ENOVARX-DICLOFENAC SODIUM": "ENOVARX-DICLOFENAC SODIUM",
              "ENOVARX-IBUPROFEN": "ENOVARX-IBUPROFEN",
              "ENOVARX-NAPROXEN": "ENOVARX-NAPROXEN",
              "NAPRO": "NAPRO"
            },
            "EXELAN PHARMACEUTICALS": {
              "DICLOFENAC SODIUM": "DICLOFENAC SODIUM"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "VOLTAREN": "VOLTAREN"
            },
            "GREENSTONE": {
              "DICLOFENAC EPOLAMINE": "DICLOFENAC EPOLAMINE"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "FLECTOR": "FLECTOR",
              "VOLTAREN": "VOLTAREN"
            },
            "HORIZON THERAPEUTICS USA": {
              "PENNSAID": "PENNSAID"
            },
            "HUMCO": {
              "DICLOFENAC SODIUM": "DICLOFENAC SODIUM"
            },
            "IBSA PHARMA": {
              "LICART": "LICART"
            },
            "INGENUS PHARMACEUTICALS": {
              "DICLOFENAC SODIUM": "DICLOFENAC SODIUM"
            },
            "INTERNATIONAL BRAND MANAGEMENT": {
              "DICLOFENAC SODIUM": "DICLOFENAC SODIUM"
            },
            "IPG PHARMACEUTICALS": {
              "DICLOFENAC SODIUM": "DICLOFENAC SODIUM"
            },
            "LUPIN PHARMACEUTICALS": {
              "DICLOFENAC SODIUM": "DICLOFENAC SODIUM"
            },
            "MYLAN": {
              "DICLOFENAC SODIUM": "DICLOFENAC SODIUM"
            },
            "NORTHSTAR RX": {
              "DICLOFENAC SODIUM": "DICLOFENAC SODIUM"
            },
            "NUBRATORI": {
              "DICLOFONO": "DICLOFONO",
              "FROTEK": "FROTEK"
            },
            "NUCARE PHARMACEUTICALS": {
              "FLECTOR": "FLECTOR",
              "VOLTAREN": "VOLTAREN"
            },
            "PAR PHARMACEUTICAL": {
              "DICLOFENAC SODIUM": "DICLOFENAC SODIUM"
            },
            "PERRIGO": {
              "DICLOFENAC SODIUM": "DICLOFENAC SODIUM",
              "GOODSENSE ARTHRITIS PAIN": "GOODSENSE ARTHRITIS PAIN"
            },
            "PERRIGO PHARMACEUTICALS": {
              "DICLOFENAC SODIUM": "DICLOFENAC SODIUM"
            },
            "PFIZER U.S.": {
              "FLECTOR": "FLECTOR"
            },
            "PHARMACEUTICA NORTH AMERICA": {
              "ACTIVE-KETOPROFEN": "ACTIVE-KETOPROFEN",
              "DICLOFENAC SODIUM": "DICLOFENAC SODIUM",
              "REXAPHENAC": "REXAPHENAC"
            },
            "PREFERRED PHARMACEUTICALS": {
              "DICLOFENAC SODIUM": "DICLOFENAC SODIUM",
              "ENOVARX-NAPROXEN": "ENOVARX-NAPROXEN",
              "FLECTOR": "FLECTOR"
            },
            "PROFICIENT RX": {
              "DICLOFENAC SODIUM": "DICLOFENAC SODIUM"
            },
            "PURE TEK": {
              "DICLOFENAC SODIUM": "DICLOFENAC SODIUM",
              "KLOFENSAID II": "KLOFENSAID II"
            },
            "QUALITY CARE": {
              "FLECTOR": "FLECTOR",
              "VOLTAREN": "VOLTAREN"
            },
            "QUALITY CARE PRODUCTS": {
              "DICLOFENAC EPOLAMINE": "DICLOFENAC EPOLAMINE",
              "DICLOFENAC SODIUM": "DICLOFENAC SODIUM"
            },
            "SHORELINE PHARMACEUTICALS": {
              "DICLO GEL": "DICLO GEL"
            },
            "SIRCLE LABORATORIES": {
              "DICLOFENAC SODIUM": "DICLOFENAC SODIUM",
              "VOPAC": "VOPAC",
              "VOPAC MDS": "VOPAC MDS"
            },
            "SOLA PHARMACEUTICALS": {
              "DICLOFENAC SODIUM": "DICLOFENAC SODIUM"
            },
            "SOLUBIOMIX LLC": {
              "DICLOFENAC SODIUM": "DICLOFENAC SODIUM"
            },
            "ST MARY'S MPP": {
              "DICLOFENAC SODIUM": "DICLOFENAC SODIUM",
              "FLECTOR": "FLECTOR",
              "VOLTAREN": "VOLTAREN"
            },
            "TARO": {
              "DICLOFENAC SODIUM": "DICLOFENAC SODIUM"
            },
            "TELIGENT": {
              "DICLOFENAC SODIUM": "DICLOFENAC SODIUM"
            },
            "TEVA PHARMACEUTICALS USA": {
              "DICLOFENAC EPOLAMINE": "DICLOFENAC EPOLAMINE"
            },
            "UNIT DOSE SERVICES": {
              "DICLOFENAC SODIUM": "DICLOFENAC SODIUM",
              "FLECTOR": "FLECTOR",
              "VOLTAREN": "VOLTAREN"
            },
            "WEAVER PHARMACEUTICALS": {
              "KETOROLAC TROMETHAMINE": "KETOROLAC TROMETHAMINE"
            }
          },
          "Anti-inflammatory Combinations - Topical": {
            "19 AND PACIFIC": {
              "DICLOPR": "DICLOPR"
            },
            "ACCUMIX PHARMACEUTICALS": {
              "EMVOREN": "EMVOREN",
              "EXTARDOL": "EXTARDOL",
              "INNOPRAX-5": "INNOPRAX-5",
              "NOVOCLAIR": "NOVOCLAIR",
              "NUVYA": "NUVYA",
              "ZYVODOL": "ZYVODOL"
            },
            "ALEXSO": {
              "FBL KIT": "FBL KIT",
              "K.B.G.L IN TERODERM": "K.B.G.L IN TERODERM"
            },
            "ALVIX LABORATORIES": {
              "DIPENTOCAINE": "DIPENTOCAINE",
              "DS PREP PAK": "DS PREP PAK"
            },
            "ASHOKA HEALTH": {
              "BIIFENAC 1000": "BIIFENAC 1000",
              "BIIFENAC 500": "BIIFENAC 500"
            },
            "BASIEM": {
              "NAPROPAX": "NAPROPAX"
            },
            "BIOPHYSICS PHARMA": {
              "GABAPENTIN-NAPROXEN CMPD KIT": "GABAPENTIN-NAPROXEN CMPD KIT"
            },
            "BODYSPHERE MANUFACTURING": {
              "CAPSINAC": "CAPSINAC"
            },
            "CHADWICK PHARMACEUTICALS": {
              "DICLOSAICIN": "DICLOSAICIN"
            },
            "FORTUS PHARMA": {
              "DITHOL": "DITHOL"
            },
            "INTERNATIONAL BRAND MANAGEMENT": {
              "SURE RESULT DSS PREMIUM PACK": "SURE RESULT DSS PREMIUM PACK"
            },
            "LEVINS PHARMACEUTICALS": {
              "DIMENTHO": "DIMENTHO"
            },
            "NUCARE PHARMACEUTICALS": {
              "DFS/MS/MENTH/CAP PAK": "DFS/MS/MENTH/CAP PAK",
              "DUAL COMPLEX FORMULA 1 KIT": "DUAL COMPLEX FORMULA 1 KIT",
              "NUDICLO SOLUPAK": "NUDICLO SOLUPAK",
              "TRIPLE COMPLEX FORMULA 3 KIT": "TRIPLE COMPLEX FORMULA 3 KIT"
            },
            "PATCHWERX LABS": {
              "DICLOTRAL": "DICLOTRAL"
            },
            "PHARMACEUTICA NORTH AMERICA": {
              "ACTIVE-PREP KIT I": "ACTIVE-PREP KIT I",
              "ACTIVE-PREP KIT II": "ACTIVE-PREP KIT II",
              "ACTIVE-PREP KIT III": "ACTIVE-PREP KIT III"
            },
            "PRIMARY PHARMACEUTICALS": {
              "DICLOVIX": "DICLOVIX",
              "DICLOVIX M": "DICLOVIX M"
            },
            "PURE TEK": {
              "DERMACINRX LEXITRAL PHARMAPAK": "DERMACINRX LEXITRAL PHARMAPAK",
              "DICLOTREX": "DICLOTREX",
              "XRYLIX": "XRYLIX"
            },
            "SA3": {
              "CAPSFENAC PAK": "CAPSFENAC PAK"
            },
            "SHORELINE PHARMACEUTICALS": {
              "DICLO GEL WITH XRYLIX SHEETS": "DICLO GEL WITH XRYLIX SHEETS",
              "LEXIXRYL": "LEXIXRYL",
              "TRIXYLITRAL": "TRIXYLITRAL",
              "XELITRAL": "XELITRAL"
            },
            "SIRCLE LABORATORIES": {
              "DICLOFEX DC": "DICLOFEX DC",
              "DYNABAC 5.0": "DYNABAC 5.0",
              "LIDOPROFEN": "LIDOPROFEN",
              "VOPAC": "VOPAC",
              "VOPAC GB": "VOPAC GB",
              "VOPAC KT": "VOPAC KT"
            },
            "SOLUBIOMIX LLC": {
              "NAPROXENPAX": "NAPROXENPAX"
            },
            "SOLUTECH PHARMACEUTICALS": {
              "INFLAMMA-K": "INFLAMMA-K"
            },
            "STERLING KNIGHT PHARMACEUTICAL": {
              "DICLOPAK": "DICLOPAK",
              "DICLOZOR": "DICLOZOR"
            },
            "TYA PHARMACEUTICALS": {
              "AIF #2 DRUG PREPARATION KIT": "AIF #2 DRUG PREPARATION KIT",
              "AIF #3 DRUG PREPARATION KIT": "AIF #3 DRUG PREPARATION KIT",
              "NP #2 DRUG PREPARATION KIT": "NP #2 DRUG PREPARATION KIT"
            },
            "UPSTREAM PHARMA": {
              "DICLOSTREAM": "DICLOSTREAM"
            },
            "V2 PHARMA": {
              "VAROPHEN": "VAROPHEN"
            },
            "VILLAGE PHARMA": {
              "VP FC KIT": "VP FC KIT",
              "VP GKL KIT": "VP GKL KIT"
            }
          }
        },
        "Antibiotics - Topical": {
          "Antibiotic Mixtures Topical": {
            "A-S MEDICATION SOLUTIONS": {
              "BACITRACIN-NEOMYCIN-POLYMYXIN": "BACITRACIN-NEOMYCIN-POLYMYXIN",
              "BACITRACIN-POLYMYXIN B": "BACITRACIN-POLYMYXIN B",
              "NEOSPORIN + PAIN RELIEF MAX ST": "NEOSPORIN + PAIN RELIEF MAX ST",
              "NEOSPORIN PLUS MAX ST": "NEOSPORIN PLUS MAX ST",
              "POLYSPORIN": "POLYSPORIN",
              "TRIPLE ANTIBIOTIC": "TRIPLE ANTIBIOTIC"
            },
            "ACTAVIS MID ATLANTIC": {
              "TRIPLE ANTIBIOTIC": "TRIPLE ANTIBIOTIC"
            },
            "AIDAREX PHARMACEUTICALS": {
              "PROCOMYCIN": "PROCOMYCIN",
              "TRIPLE ANTIBIOTIC": "TRIPLE ANTIBIOTIC"
            },
            "ALTAIRE": {
              "TRIPLE ANTIBIOTIC": "TRIPLE ANTIBIOTIC",
              "TRIPLE ANTIBIOTIC PLUS MAX ST": "TRIPLE ANTIBIOTIC PLUS MAX ST"
            },
            "AMERICAN SALES COMPANY": {
              "ANTIBIOTIC PLUS PAIN RELIEF": "ANTIBIOTIC PLUS PAIN RELIEF",
              "TRIPLE ANTIBIOTIC": "TRIPLE ANTIBIOTIC",
              "TRIPLE ANTIBIOTIC PLUS": "TRIPLE ANTIBIOTIC PLUS"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP FIRST AID ANTIBIOTIC": "GNP FIRST AID ANTIBIOTIC",
              "GNP TRIPLE ANTIBIOTIC": "GNP TRIPLE ANTIBIOTIC"
            },
            "ASAFI PHARMACEUTICAL": {
              "TRIPLE ANTIBIOTIC": "TRIPLE ANTIBIOTIC"
            },
            "BEIERSDORF-FUTURO": {
              "FIRST AID ANTIBIOTIC": "FIRST AID ANTIBIOTIC"
            },
            "BERGEN BRUNSWIG": {
              "GNP ANTIBIOTIC PLUS PRAMOXINE": "GNP ANTIBIOTIC PLUS PRAMOXINE",
              "GNP TRIPLE ANTIBIOTIC": "GNP TRIPLE ANTIBIOTIC",
              "GNP TRIPLE ANTIBIOTIC PLUS": "GNP TRIPLE ANTIBIOTIC PLUS"
            },
            "BRYANT RANCH PREPACK": {
              "PROCOMYCIN": "PROCOMYCIN"
            },
            "CHAIN DRUG CONSORTIUM": {
              "ANTIBIOTIC PLUS PAIN RELIEF": "ANTIBIOTIC PLUS PAIN RELIEF",
              "POLY BACITRACIN": "POLY BACITRACIN",
              "TRIPLE ANTIBIOTIC": "TRIPLE ANTIBIOTIC",
              "TRIPLE ANTIBIOTIC PLUS": "TRIPLE ANTIBIOTIC PLUS",
              "TRIPLE ANTIBIOTIC+PAIN RELIEF": "TRIPLE ANTIBIOTIC+PAIN RELIEF"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC TRIPLE ANTIBIOTIC MAX ST": "QC TRIPLE ANTIBIOTIC MAX ST"
            },
            "COMBE": {
              "LANABIOTIC": "LANABIOTIC"
            },
            "COSETTE PHARMACEUTICALS": {
              "TRIPLE ANTIBIOTIC": "TRIPLE ANTIBIOTIC",
              "TRIPLE ANTIBIOTIC PLUS": "TRIPLE ANTIBIOTIC PLUS"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS ANTIBIOTIC": "CVS ANTIBIOTIC",
              "CVS ANTIBIOTIC PAIN/SCAR": "CVS ANTIBIOTIC PAIN/SCAR",
              "CVS ANTIBIOTIC PLUS": "CVS ANTIBIOTIC PLUS",
              "CVS POLY BACITRACIN": "CVS POLY BACITRACIN",
              "CVS TRIPLE ANTIBIOTIC": "CVS TRIPLE ANTIBIOTIC",
              "CVS TRIPLE ANTIBIOTIC MAX STR": "CVS TRIPLE ANTIBIOTIC MAX STR",
              "CVS TRIPLE ANTIBIOTIC/PAIN": "CVS TRIPLE ANTIBIOTIC/PAIN"
            },
            "EQUALINE": {
              "EQL ANTIBIOTIC + PAIN RELIEF": "EQL ANTIBIOTIC + PAIN RELIEF",
              "EQL FIRST AID ANTIBIOTIC": "EQL FIRST AID ANTIBIOTIC"
            },
            "FIRST AID RESEARCH CORP": {
              "BACITRAYCIN PLUS": "BACITRAYCIN PLUS"
            },
            "FOUGERA": {
              "BACITRACIN-NEOMYCIN-POLYMYXIN": "BACITRACIN-NEOMYCIN-POLYMYXIN",
              "DOUBLE ANTIBIOTIC": "DOUBLE ANTIBIOTIC"
            },
            "GEISS DESTIN & DUNN": {
              "GOODSENSE ANTIBIOTIC/PAIN": "GOODSENSE ANTIBIOTIC/PAIN"
            },
            "GERI-CARE": {
              "TRIPLE ANTIBIOTIC": "TRIPLE ANTIBIOTIC"
            },
            "GERITREX CORPORATION": {
              "TRIPLE ANTIBIOTIC": "TRIPLE ANTIBIOTIC"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "BACITRACIN-POLYMYXIN B": "BACITRACIN-POLYMYXIN B",
              "TRIPLE ANTIBIOTIC": "TRIPLE ANTIBIOTIC",
              "TRIPLE ANTIBIOTIC PLUS": "TRIPLE ANTIBIOTIC PLUS"
            },
            "HANNAFORD BROTHERS": {
              "TRIPLE ANTIBIOTIC": "TRIPLE ANTIBIOTIC",
              "TRIPLE ANTIBIOTIC PLUS": "TRIPLE ANTIBIOTIC PLUS"
            },
            "J & J CONSUMER PRODUCTS": {
              "BAND-AID PLUS ANTIBIOTIC": "BAND-AID PLUS ANTIBIOTIC",
              "NEOSPORIN": "NEOSPORIN",
              "NEOSPORIN + PAIN RELIEF MAX ST": "NEOSPORIN + PAIN RELIEF MAX ST",
              "NEOSPORIN + PAIN/ITCH/SCAR": "NEOSPORIN + PAIN/ITCH/SCAR",
              "NEOSPORIN ORIGINAL": "NEOSPORIN ORIGINAL",
              "NEOSPORIN PLUS PAIN RELIEF MS": "NEOSPORIN PLUS PAIN RELIEF MS",
              "POLYSPORIN": "POLYSPORIN"
            },
            "JOHNSON&JOHNSON HEALTHCARE": {
              "NEOSPORIN/BURN RELIEF": "NEOSPORIN/BURN RELIEF"
            },
            "KAISER FOUNDATION HOSPITAL": {
              "KP DOUBLE ANTIBIOTIC": "KP DOUBLE ANTIBIOTIC"
            },
            "LEADER BRAND PRODUCTS": {
              "POLY BACITRACIN": "POLY BACITRACIN",
              "TRIPLE ANTIBIOTIC": "TRIPLE ANTIBIOTIC",
              "TRIPLE ANTIBIOTIC PLUS": "TRIPLE ANTIBIOTIC PLUS",
              "TRIPLE ANTIBIOTIC+PAIN RELIEF": "TRIPLE ANTIBIOTIC+PAIN RELIEF"
            },
            "MAJOR PHARMACEUTICALS": {
              "TRIPLE ANTIBIOTIC": "TRIPLE ANTIBIOTIC",
              "TRIPLE ANTIBIOTIC FIRST AID": "TRIPLE ANTIBIOTIC FIRST AID"
            },
            "MCKESSON": {
              "HM DOUBLE ANTIBIOTIC": "HM DOUBLE ANTIBIOTIC",
              "HM TRIPLE ANTIBIOTIC": "HM TRIPLE ANTIBIOTIC",
              "HM TRIPLE ANTIBIOTIC MAX ST": "HM TRIPLE ANTIBIOTIC MAX ST"
            },
            "MCKESSON SUNMARK": {
              "SM ANTIBIOTIC PLUS PAIN RELIEF": "SM ANTIBIOTIC PLUS PAIN RELIEF",
              "SM DOUBLE ANTIBIOTIC": "SM DOUBLE ANTIBIOTIC",
              "SM TRIPLE ANTIBIOTIC": "SM TRIPLE ANTIBIOTIC",
              "SM TRIPLE ANTIBIOTIC MAX ST": "SM TRIPLE ANTIBIOTIC MAX ST",
              "SM TRIPLE ANTIBIOTIC ORIGINAL": "SM TRIPLE ANTIBIOTIC ORIGINAL"
            },
            "MEDICINE SHOPPE": {
              "TRIPLE ANTIBIOTIC": "TRIPLE ANTIBIOTIC"
            },
            "MEDIQUE PRODUCTS": {
              "MEDI-FIRST TRIPLE ANTIBIOTIC": "MEDI-FIRST TRIPLE ANTIBIOTIC"
            },
            "MEDLINE/DERMAL MANAGEMENT": {
              "CURAD TRIPLE ANTIBIOTIC": "CURAD TRIPLE ANTIBIOTIC"
            },
            "MEIJER": {
              "MEIJER TRIPLE ANTIBIOTIC": "MEIJER TRIPLE ANTIBIOTIC"
            },
            "NATUREPLEX": {
              "TRIPLE ANTIBIOTIC": "TRIPLE ANTIBIOTIC"
            },
            "NEW WORLD IMPORTS": {
              "TRIPLE ANTIBIOTIC": "TRIPLE ANTIBIOTIC"
            },
            "NUCARE PHARMACEUTICALS": {
              "TRIPLE ANTIBIOTIC": "TRIPLE ANTIBIOTIC"
            },
            "PAR PHARMACEUTICAL": {
              "TRIPLE ANTIBIOTIC": "TRIPLE ANTIBIOTIC"
            },
            "PERRIGO": {
              "FIRST AID ANTIBIOTIC": "FIRST AID ANTIBIOTIC"
            },
            "PERRIGO PHARMACEUTICALS": {
              "TRIPLE ANTIBIOTIC": "TRIPLE ANTIBIOTIC"
            },
            "PHYSICIANS SCIENCE AND NATURE": {
              "PROCOMYCIN": "PROCOMYCIN"
            },
            "PREFERRED PHARMACEUTICALS": {
              "POLYSPORIN": "POLYSPORIN",
              "TRIPLE ANTIBIOTIC": "TRIPLE ANTIBIOTIC"
            },
            "PROFICIENT RX": {
              "TRIPLE ANTIBIOTIC": "TRIPLE ANTIBIOTIC"
            },
            "PUBLIX SUPER MARKETS INC.": {
              "PX TRIPLE": "PX TRIPLE"
            },
            "QUALITY CARE": {
              "POLYSPORIN": "POLYSPORIN",
              "TRIPLE ANTIBIOTIC": "TRIPLE ANTIBIOTIC"
            },
            "REESE PHARMACEUTICAL": {
              "TRI-BIOZENE": "TRI-BIOZENE"
            },
            "RITE AID CORPORATION": {
              "RA ANTIBIOTIC + PAIN RELIEF": "RA ANTIBIOTIC + PAIN RELIEF",
              "RA ANTIBIOTIC PLUS": "RA ANTIBIOTIC PLUS",
              "RA ANTIBIOTIC/PAIN RELIEF": "RA ANTIBIOTIC/PAIN RELIEF",
              "RA DOUBLE ANTIBIOTIC": "RA DOUBLE ANTIBIOTIC",
              "RA TRIPLE ANTIBIOTIC": "RA TRIPLE ANTIBIOTIC",
              "RA TRIPLE ANTIBIOTIC PLUS": "RA TRIPLE ANTIBIOTIC PLUS"
            },
            "RUGBY LABORATORIES": {
              "DOUBLE ANTIBIOTIC": "DOUBLE ANTIBIOTIC"
            },
            "SELECT BRAND": {
              "SB TRIPLE ANTIBIOTIC": "SB TRIPLE ANTIBIOTIC"
            },
            "SHEFFIELD PHARMACEUTICALS": {
              "TRIPLE ANTIBIOTIC": "TRIPLE ANTIBIOTIC"
            },
            "STRATUS PHARMACEUTICALS": {
              "DOUBLE ANTIBIOTIC": "DOUBLE ANTIBIOTIC"
            },
            "TARGET": {
              "TGT ANTIBIOTIC": "TGT ANTIBIOTIC",
              "TGT FIRST AID ANTIBIOTIC": "TGT FIRST AID ANTIBIOTIC"
            },
            "TARO": {
              "DOUBLE ANTIBIOTIC": "DOUBLE ANTIBIOTIC",
              "TRIPLE ANTIBIOTIC": "TRIPLE ANTIBIOTIC",
              "TRIPLE ANTIBIOTIC PLUS": "TRIPLE ANTIBIOTIC PLUS"
            },
            "TRIFECTA PHARMACEUTICALS": {
              "DOUBLE ANTIBIOTIC": "DOUBLE ANTIBIOTIC",
              "TRIPLE ANTIBIOTIC": "TRIPLE ANTIBIOTIC"
            },
            "UNIT DOSE SERVICES": {
              "POLYSPORIN": "POLYSPORIN"
            },
            "WAL-MART": {
              "EQ ANTIBIOTIC + PAIN RELIEF": "EQ ANTIBIOTIC + PAIN RELIEF",
              "EQ TRIPLE ANTIBIOTIC": "EQ TRIPLE ANTIBIOTIC"
            },
            "WALGREENS": {
              "DOUBLE ANTIBIOTIC + PAIN RLF": "DOUBLE ANTIBIOTIC + PAIN RLF",
              "FIRST AID ANTIBIOTIC": "FIRST AID ANTIBIOTIC",
              "MULTI ANTIBIOTIC PLUS": "MULTI ANTIBIOTIC PLUS",
              "TRIPLE ANTIBIOTIC": "TRIPLE ANTIBIOTIC",
              "TRIPLE ANTIBIOTIC PAIN RELIEF": "TRIPLE ANTIBIOTIC PAIN RELIEF",
              "TRIPLE ANTIBIOTIC PLUS": "TRIPLE ANTIBIOTIC PLUS",
              "WAL-SPORIN": "WAL-SPORIN"
            }
          },
          "Antibiotic Steroid Combinations - Topical": {
            "MEDIMETRIKS PHARMACEUTICALS": {
              "NEO-SYNALAR": "NEO-SYNALAR"
            },
            "PFIZER U.S.": {
              "CORTISPORIN": "CORTISPORIN"
            }
          },
          "Antibiotics - Topical": {
            "A-S MEDICATION SOLUTIONS": {
              "BACITRACIN": "BACITRACIN",
              "BACITRACIN ZINC": "BACITRACIN ZINC",
              "BACTROBAN": "BACTROBAN",
              "GENTAMICIN SULFATE": "GENTAMICIN SULFATE",
              "MUPIROCIN": "MUPIROCIN"
            },
            "ACTAVIS MID ATLANTIC": {
              "BACITRACIN ZINC": "BACITRACIN ZINC"
            },
            "AIDAREX PHARMACEUTICALS": {
              "BACITRACIN": "BACITRACIN",
              "BACITRACIN ZINC": "BACITRACIN ZINC",
              "MUPIROCIN": "MUPIROCIN"
            },
            "ALMIRALL": {
              "ALTABAX": "ALTABAX"
            },
            "ALTAIRE": {
              "BACITRACIN": "BACITRACIN"
            },
            "AMERICAN PHARMA INGREDIENTS": {
              "BACITRACIN": "BACITRACIN",
              "GENTAMICIN SULFATE": "GENTAMICIN SULFATE",
              "TETRACYCLINE HCL": "TETRACYCLINE HCL"
            },
            "APOTHECA SUPPLY": {
              "GENTAMICIN SULFATE": "GENTAMICIN SULFATE"
            },
            "AVPAK": {
              "MUPIROCIN": "MUPIROCIN"
            },
            "BERGEN BRUNSWIG": {
              "GNP BACITRACIN ZINC": "GNP BACITRACIN ZINC"
            },
            "BIOFRONTERA": {
              "XEPI": "XEPI"
            },
            "BIOMES PHARMACEUTICALS": {
              "MUPIROCIN": "MUPIROCIN",
              "MUPIROCIN CALCIUM": "MUPIROCIN CALCIUM"
            },
            "BPI LABS LLC": {
              "TETRACYCLINE HCL": "TETRACYCLINE HCL"
            },
            "CHAIN DRUG CONSORTIUM": {
              "BACITRACIN ZINC": "BACITRACIN ZINC"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC BACITRACIN": "QC BACITRACIN"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "MUPIROCIN": "MUPIROCIN"
            },
            "COSETTE PHARMACEUTICALS": {
              "BACITRACIN": "BACITRACIN",
              "GENTAMICIN SULFATE": "GENTAMICIN SULFATE"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS BACITRACIN": "CVS BACITRACIN",
              "CVS BACITRACIN ZINC": "CVS BACITRACIN ZINC"
            },
            "DIRECT RX": {
              "BACITRACIN": "BACITRACIN",
              "BACITRACIN ZINC": "BACITRACIN ZINC",
              "MUPIROCIN": "MUPIROCIN"
            },
            "DYNAREX CORPORATION": {
              "BACITRACIN": "BACITRACIN",
              "BACITRACIN ZINC": "BACITRACIN ZINC"
            },
            "EQUALINE": {
              "EQL BACITRACIN ZINC": "EQL BACITRACIN ZINC"
            },
            "FAGRON": {
              "GENTAMICIN SULFATE": "GENTAMICIN SULFATE",
              "NEOMYCIN SULFATE": "NEOMYCIN SULFATE",
              "TETRACYCLINE HCL": "TETRACYCLINE HCL"
            },
            "FIRST AID RESEARCH CORP": {
              "BACITRACIN ZINC": "BACITRACIN ZINC",
              "BACITRAYCIN PLUS": "BACITRAYCIN PLUS"
            },
            "FOUGERA": {
              "BACITRACIN ZINC": "BACITRACIN ZINC",
              "MUPIROCIN": "MUPIROCIN"
            },
            "FREEDOM PHARMACEUTICALS": {
              "NEOMYCIN SULFATE": "NEOMYCIN SULFATE",
              "TETRACYCLINE HCL": "TETRACYCLINE HCL"
            },
            "GERI-CARE": {
              "BACITRACIN ZINC": "BACITRACIN ZINC"
            },
            "GERITREX CORPORATION": {
              "BACITRACIN ZINC": "BACITRACIN ZINC"
            },
            "GLAXO SMITH KLINE": {
              "BACTROBAN": "BACTROBAN"
            },
            "GLENMARK PHARMACEUTICALS": {
              "MUPIROCIN": "MUPIROCIN",
              "MUPIROCIN CALCIUM": "MUPIROCIN CALCIUM"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "BACITRACIN": "BACITRACIN",
              "BACITRACIN ZINC": "BACITRACIN ZINC",
              "BACTROBAN": "BACTROBAN",
              "MUPIROCIN": "MUPIROCIN"
            },
            "HANNAFORD BROTHERS": {
              "BACITRACIN": "BACITRACIN"
            },
            "HUMCO": {
              "GENTAMICIN SULFATE": "GENTAMICIN SULFATE",
              "TETRACYCLINE HCL": "TETRACYCLINE HCL"
            },
            "KAISER FOUNDATION HOSPITAL": {
              "KP BACITRACIN ZINC": "KP BACITRACIN ZINC"
            },
            "KALCHEM INTERNATIONAL": {
              "GENTAMICIN SULFATE": "GENTAMICIN SULFATE",
              "TETRACYCLINE HCL": "TETRACYCLINE HCL"
            },
            "LEADER BRAND PRODUCTS": {
              "BACITRACIN": "BACITRACIN",
              "BACITRACIN ZINC": "BACITRACIN ZINC"
            },
            "LETCO MEDICAL": {
              "BACITRACIN": "BACITRACIN",
              "GENTAMICIN SULFATE": "GENTAMICIN SULFATE",
              "NEOMYCIN SULFATE": "NEOMYCIN SULFATE",
              "TETRACYCLINE HCL": "TETRACYCLINE HCL"
            },
            "LOHXA": {
              "MUPIROCIN": "MUPIROCIN"
            },
            "MAJOR PHARMACEUTICALS": {
              "BACITRACIN ZINC": "BACITRACIN ZINC"
            },
            "MCKESSON": {
              "HM BACITRACIN ZINC": "HM BACITRACIN ZINC"
            },
            "MCKESSON SUNMARK": {
              "SM ANTIBIOTIC": "SM ANTIBIOTIC"
            },
            "MEDICINE SHOPPE": {
              "BACITRACIN": "BACITRACIN"
            },
            "MEDIMETRIKS PHARMACEUTICALS": {
              "CENTANY": "CENTANY",
              "CENTANY AT": "CENTANY AT"
            },
            "MEDISCA": {
              "GENTAMICIN SULFATE": "GENTAMICIN SULFATE",
              "NEOMYCIN SULFATE": "NEOMYCIN SULFATE",
              "TETRACYCLINE HCL": "TETRACYCLINE HCL"
            },
            "NEW WORLD IMPORTS": {
              "BACITRACIN ZINC": "BACITRACIN ZINC"
            },
            "NUCARE PHARMACEUTICALS": {
              "BACITRACIN": "BACITRACIN",
              "BACITRACIN ZINC": "BACITRACIN ZINC",
              "BACTROBAN": "BACTROBAN"
            },
            "PAR PHARMACEUTICAL": {
              "BACITRACIN ZINC": "BACITRACIN ZINC"
            },
            "PCCA": {
              "BACITRACIN ZINC": "BACITRACIN ZINC",
              "GENTAMICIN SULFATE": "GENTAMICIN SULFATE",
              "NEOMYCIN SULFATE": "NEOMYCIN SULFATE",
              "TETRACYCLINE HCL": "TETRACYCLINE HCL"
            },
            "PERRIGO PHARMACEUTICALS": {
              "BACITRACIN": "BACITRACIN",
              "GENTAMICIN SULFATE": "GENTAMICIN SULFATE",
              "MUPIROCIN": "MUPIROCIN"
            },
            "PHARMEDIX": {
              "MUPIROCIN": "MUPIROCIN"
            },
            "PRASCO LABORATORIES": {
              "MUPIROCIN CALCIUM": "MUPIROCIN CALCIUM"
            },
            "PREFERRED PHARMACEUTICALS": {
              "MUPIROCIN": "MUPIROCIN"
            },
            "PROFICIENT RX": {
              "BACITRACIN": "BACITRACIN",
              "MUPIROCIN": "MUPIROCIN"
            },
            "QUALITY CARE": {
              "BACITRACIN": "BACITRACIN",
              "BACTROBAN": "BACTROBAN",
              "GENTAMICIN SULFATE": "GENTAMICIN SULFATE",
              "MUPIROCIN": "MUPIROCIN"
            },
            "QUALITY CARE PRODUCTS": {
              "MUPIROCIN": "MUPIROCIN"
            },
            "RITE AID CORPORATION": {
              "RA BACITRACIN": "RA BACITRACIN",
              "RA BACITRACIN ZINC FIRST AID": "RA BACITRACIN ZINC FIRST AID"
            },
            "RUGBY LABORATORIES": {
              "BACITRACIN": "BACITRACIN",
              "BACITRACIN ZINC": "BACITRACIN ZINC"
            },
            "SELECT BRAND": {
              "SB BACITRACIN": "SB BACITRACIN"
            },
            "SHEFFIELD PHARMACEUTICALS": {
              "BACIGUENT": "BACIGUENT"
            },
            "SOLA PHARMACEUTICALS": {
              "GENTAMICIN SULFATE": "GENTAMICIN SULFATE"
            },
            "STRATUS PHARMACEUTICALS": {
              "BACITRACIN ZINC": "BACITRACIN ZINC"
            },
            "TARGET": {
              "TGT BACITRACIN": "TGT BACITRACIN"
            },
            "TARO": {
              "BACITRACIN": "BACITRACIN",
              "BACITRACIN ZINC": "BACITRACIN ZINC",
              "MUPIROCIN": "MUPIROCIN",
              "MUPIROCIN CALCIUM": "MUPIROCIN CALCIUM"
            },
            "TELIGENT": {
              "GENTAMICIN SULFATE": "GENTAMICIN SULFATE"
            },
            "TEVA PHARMACEUTICALS USA": {
              "MUPIROCIN": "MUPIROCIN"
            },
            "TOPCO": {
              "BACITRACIN ZINC": "BACITRACIN ZINC"
            },
            "TRIFECTA PHARMACEUTICALS": {
              "BACITRACIN ZINC": "BACITRACIN ZINC"
            },
            "TRIOVA PHARMACEUTICALS": {
              "GENTAMICIN SULFATE": "GENTAMICIN SULFATE"
            },
            "UNIT DOSE SERVICES": {
              "MUPIROCIN": "MUPIROCIN"
            },
            "WAL-MART": {
              "EQ BACITRACIN ZINC": "EQ BACITRACIN ZINC"
            },
            "WALGREENS": {
              "BACITRACIN": "BACITRACIN",
              "BACITRACIN ZINC": "BACITRACIN ZINC"
            },
            "WEAVER PHARMACEUTICALS": {
              "GENTAMICIN SULFATE": "GENTAMICIN SULFATE"
            },
            "WELGO": {
              "MUPIROCIN CALCIUM": "MUPIROCIN CALCIUM"
            }
          }
        },
        "Antifungals - Topical": {
          "Antifungals - Topical": {
            "A-S MEDICATION SOLUTIONS": {
              "ANTIFUNGAL": "ANTIFUNGAL",
              "CICLOPIROX": "CICLOPIROX",
              "LAMISIL AT": "LAMISIL AT",
              "NYSTATIN": "NYSTATIN",
              "TERBINAFINE HCL": "TERBINAFINE HCL",
              "TOLNAFTATE": "TOLNAFTATE"
            },
            "ACELLA PHARMACEUTICALS": {
              "CICLOPIROX": "CICLOPIROX",
              "CICLOPIROX TREATMENT": "CICLOPIROX TREATMENT"
            },
            "ACTAVIS MID ATLANTIC": {
              "NYSTATIN": "NYSTATIN"
            },
            "ACTAVIS PHARMA": {
              "CICLOPIROX": "CICLOPIROX"
            },
            "AIDAREX PHARMACEUTICALS": {
              "CICLOPIROX": "CICLOPIROX",
              "CICLOPIROX OLAMINE": "CICLOPIROX OLAMINE",
              "NYSTATIN": "NYSTATIN",
              "TERBINAFINE HCL": "TERBINAFINE HCL"
            },
            "AKORN CONSUMER": {
              "DIABETIDERM ANTIFUNGAL": "DIABETIDERM ANTIFUNGAL"
            },
            "ALTAIRE": {
              "FUNGI-GUARD": "FUNGI-GUARD",
              "TINASPORE": "TINASPORE"
            },
            "ALVA-AMCO": {
              "FUNGICURE MAXIMUM STRENGTH": "FUNGICURE MAXIMUM STRENGTH"
            },
            "ALVOGEN": {
              "CICLOPIROX": "CICLOPIROX"
            },
            "AMERICAN SALES COMPANY": {
              "ANTIFUNGAL": "ANTIFUNGAL",
              "ANTIFUNGAL FOOT": "ANTIFUNGAL FOOT"
            },
            "AMNEAL PHARMACEUTICALS": {
              "NAFTIFINE HCL": "NAFTIFINE HCL"
            },
            "ARBOR PHARMACEUTICALS": {
              "PEDIADERM AF COMPLETE": "PEDIADERM AF COMPLETE"
            },
            "ASAFI PHARMACEUTICAL": {
              "TOLNAFTATE": "TOLNAFTATE"
            },
            "BAUSCH HEALTH": {
              "FUNGOID-D": "FUNGOID-D",
              "LOPROX": "LOPROX",
              "PENLAC": "PENLAC"
            },
            "BAYER CONSUMER": {
              "LOTRIMIN ULTRA": "LOTRIMIN ULTRA",
              "TINACTIN": "TINACTIN",
              "TINACTIN DEODORANT": "TINACTIN DEODORANT",
              "TINACTIN JOCK ITCH": "TINACTIN JOCK ITCH"
            },
            "BERGEN BRUNSWIG": {
              "GNP ANTI-FUNGAL": "GNP ANTI-FUNGAL",
              "GNP GENTIAN VIOLET": "GNP GENTIAN VIOLET",
              "GNP TERBINAFINE HYDROCHLORIDE": "GNP TERBINAFINE HYDROCHLORIDE",
              "GNP TOLNAFTATE": "GNP TOLNAFTATE"
            },
            "BIORX  PHARMACEUTICALS": {
              "BIORX SPONIX ANTI-FUNGAL": "BIORX SPONIX ANTI-FUNGAL"
            },
            "CHAIN DRUG CONSORTIUM": {
              "ANTI-FUNGAL": "ANTI-FUNGAL",
              "ANTIFUNGAL": "ANTIFUNGAL",
              "ANTIFUNGAL FOOT/SNEAKER SPRAY": "ANTIFUNGAL FOOT/SNEAKER SPRAY",
              "ANTIFUNGAL SPRAY POWDER": "ANTIFUNGAL SPRAY POWDER",
              "ATHLETES FOOT": "ATHLETES FOOT",
              "JOCK ITCH SPRAY POWDER": "JOCK ITCH SPRAY POWDER",
              "TOLNAFTATE": "TOLNAFTATE"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC ATHLETES FOOT": "QC ATHLETES FOOT",
              "QC TOLNAFTATE": "QC TOLNAFTATE"
            },
            "CIPHER PHARMACEUTICALS": {
              "CNL8 NAIL": "CNL8 NAIL"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "CICLOPIROX": "CICLOPIROX"
            },
            "COMBE": {
              "ODOR EATERS ANTIFUNGAL": "ODOR EATERS ANTIFUNGAL",
              "ODOR EATERS FOOT/SNEAKER SPRAY": "ODOR EATERS FOOT/SNEAKER SPRAY"
            },
            "COSETTE PHARMACEUTICALS": {
              "CICLOPIROX": "CICLOPIROX",
              "CICLOPIROX OLAMINE": "CICLOPIROX OLAMINE",
              "NYSTATIN": "NYSTATIN"
            },
            "CROWN LABORATORIES": {
              "NYATA": "NYATA",
              "NYSTATIN": "NYSTATIN"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS AF SPRAY POWDER": "CVS AF SPRAY POWDER",
              "CVS ANTIFUNGAL": "CVS ANTIFUNGAL",
              "CVS ANTIFUNGAL MAXIMUM STR": "CVS ANTIFUNGAL MAXIMUM STR",
              "CVS ATHLETES FOOT": "CVS ATHLETES FOOT",
              "CVS FOOT & SNEAKER": "CVS FOOT & SNEAKER",
              "CVS JOCK ITCH": "CVS JOCK ITCH"
            },
            "DARTMOUTH": {
              "ELON DUAL DEFENSE ANTI-FUNGAL": "ELON DUAL DEFENSE ANTI-FUNGAL"
            },
            "DIRECT RX": {
              "NYSTATIN": "NYSTATIN"
            },
            "DLC LABORATORIES": {
              "GENTIAN VIOLET": "GENTIAN VIOLET"
            },
            "DR. CANUSO": {
              "FOOT REPAIR SERUM": "FOOT REPAIR SERUM",
              "FUNGAL NAIL ERASER": "FUNGAL NAIL ERASER"
            },
            "EPIC PHARMA": {
              "NYSTATIN": "NYSTATIN"
            },
            "EQUALINE": {
              "EQL ANTIFUNGAL (TOLNAFTATE)": "EQL ANTIFUNGAL (TOLNAFTATE)",
              "EQL ATHLETES FOOT": "EQL ATHLETES FOOT",
              "EQL ATHLETES FOOT SPRAY": "EQL ATHLETES FOOT SPRAY",
              "EQL ATHLETES FOOT(TERBINAFINE)": "EQL ATHLETES FOOT(TERBINAFINE)",
              "EQL JOCK ITCH": "EQL JOCK ITCH"
            },
            "FAGRON": {
              "BENZOIC ACID": "BENZOIC ACID",
              "CLIOQUINOL": "CLIOQUINOL"
            },
            "FOUGERA": {
              "CICLOPIROX": "CICLOPIROX",
              "CICLOPIROX OLAMINE": "CICLOPIROX OLAMINE",
              "NYSTATIN": "NYSTATIN"
            },
            "GERI-CARE": {
              "TOLNAFTATE": "TOLNAFTATE"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "LAMISIL ADVANCED": "LAMISIL ADVANCED",
              "LAMISIL AF DEFENSE": "LAMISIL AF DEFENSE",
              "LAMISIL AT": "LAMISIL AT",
              "LAMISIL AT JOCK ITCH": "LAMISIL AT JOCK ITCH",
              "LAMISIL AT SPRAY": "LAMISIL AT SPRAY"
            },
            "GLENMARK PHARMACEUTICALS": {
              "CICLOPIROX": "CICLOPIROX",
              "CICLOPIROX OLAMINE": "CICLOPIROX OLAMINE"
            },
            "GORDON": {
              "GORDOCHOM": "GORDOCHOM"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "LAMISIL AT": "LAMISIL AT",
              "NYSTATIN": "NYSTATIN"
            },
            "HANNAFORD BROTHERS": {
              "ANTIFUNGAL": "ANTIFUNGAL"
            },
            "HARRIS PHARMACEUTICAL": {
              "CICLOPIROX": "CICLOPIROX"
            },
            "HI-TECH": {
              "CICLOPIROX": "CICLOPIROX"
            },
            "HUMCO": {
              "GENTIAN VIOLET": "GENTIAN VIOLET"
            },
            "KAISER FOUNDATION HOSPITAL": {
              "KP TERBINAFINE HYDROCHLORIDE": "KP TERBINAFINE HYDROCHLORIDE",
              "KP TOLNAFTATE": "KP TOLNAFTATE"
            },
            "KAREWAY PRODUCT": {
              "TOLNAFTATE": "TOLNAFTATE"
            },
            "KRAMER LABS": {
              "FUNGI-NAIL": "FUNGI-NAIL",
              "HONGO CURA ANTI-FUNGAL": "HONGO CURA ANTI-FUNGAL"
            },
            "KRAMER-NOVIS": {
              "MYCO NAIL A": "MYCO NAIL A"
            },
            "LEADER BRAND PRODUCTS": {
              "ANTIFUNGAL": "ANTIFUNGAL",
              "ATHLETES FOOT (TERBINAFINE)": "ATHLETES FOOT (TERBINAFINE)",
              "ATHLETES FOOT AF": "ATHLETES FOOT AF",
              "ATHLETES FOOT POWDER SPRAY": "ATHLETES FOOT POWDER SPRAY",
              "TOLNAFTATE ANTIFUNGAL": "TOLNAFTATE ANTIFUNGAL"
            },
            "LEADING PHARMA": {
              "CICLOPIROX OLAMINE": "CICLOPIROX OLAMINE",
              "NYSTATIN": "NYSTATIN"
            },
            "LETCO MEDICAL": {
              "CLIOQUINOL": "CLIOQUINOL"
            },
            "LUPIN PHARMACEUTICALS": {
              "NYSTATIN": "NYSTATIN"
            },
            "MAJOR PHARMACEUTICALS": {
              "ANTIFUNGAL": "ANTIFUNGAL",
              "NYSTATIN": "NYSTATIN",
              "TOLNAFTATE": "TOLNAFTATE"
            },
            "MAYNE PHARMA": {
              "NYSTATIN": "NYSTATIN"
            },
            "MCKESSON SUNMARK": {
              "SM ANTIFUNGAL TOLNAFTATE": "SM ANTIFUNGAL TOLNAFTATE",
              "SM ATHLETES FOOT": "SM ATHLETES FOOT"
            },
            "MEDIMETRIKS PHARMACEUTICALS": {
              "CICLODAN": "CICLODAN",
              "CICLODAN CREAM": "CICLODAN CREAM",
              "CICLODAN SOLUTION": "CICLODAN SOLUTION",
              "LOPROX": "LOPROX"
            },
            "MEDIQUE PRODUCTS": {
              "MEDI-FIRST ANTI-FUNGAL": "MEDI-FIRST ANTI-FUNGAL"
            },
            "MEDISCA": {
              "BENZOIC ACID": "BENZOIC ACID",
              "CICLOPIROX OLAMINE": "CICLOPIROX OLAMINE",
              "CLIOQUINOL": "CLIOQUINOL"
            },
            "MERICON": {
              "TOLNAFTATE ANTIFUNGAL": "TOLNAFTATE ANTIFUNGAL"
            },
            "MERZ PHARMACEUTICAL": {
              "NAFTIN": "NAFTIN"
            },
            "MYLAN": {
              "MENTAX": "MENTAX",
              "NAFTIFINE HCL": "NAFTIFINE HCL"
            },
            "NEW WORLD IMPORTS": {
              "TOLNAFTATE": "TOLNAFTATE"
            },
            "NOREGA LABORATORIES": {
              "HALOTIN": "HALOTIN"
            },
            "NORTHSTAR RX": {
              "NYSTATIN": "NYSTATIN"
            },
            "NUCARE PHARMACEUTICALS": {
              "NYSTATIN": "NYSTATIN"
            },
            "OAKHURST": {
              "BLIS-TO-SOL": "BLIS-TO-SOL"
            },
            "PAR PHARMACEUTICALS": {
              "NYSTATIN": "NYSTATIN"
            },
            "PCCA": {
              "BENZOIC ACID": "BENZOIC ACID",
              "CICLOPIROX OLAMINE": "CICLOPIROX OLAMINE",
              "CLIOQUINOL": "CLIOQUINOL",
              "GENTIAN VIOLET": "GENTIAN VIOLET",
              "TOLNAFTATE": "TOLNAFTATE"
            },
            "PERRIGO": {
              "CICLOPIROX": "CICLOPIROX",
              "NYSTOP": "NYSTOP",
              "TOLNAFTATE": "TOLNAFTATE"
            },
            "PERRIGO PHARMACEUTICALS": {
              "ATHLETES FOOT SPRAY": "ATHLETES FOOT SPRAY",
              "CICLOPIROX": "CICLOPIROX",
              "CICLOPIROX OLAMINE": "CICLOPIROX OLAMINE",
              "JOCK ITCH SPRAY": "JOCK ITCH SPRAY",
              "NYSTATIN": "NYSTATIN",
              "TOLNAFTATE": "TOLNAFTATE"
            },
            "PHARMEDIX": {
              "CICLOPIROX OLAMINE": "CICLOPIROX OLAMINE",
              "NYSTATIN": "NYSTATIN"
            },
            "PREFERRED PHARMACEUTICALS": {
              "CICLOPIROX": "CICLOPIROX",
              "NYSTATIN": "NYSTATIN"
            },
            "PROFICIENT RX": {
              "NYSTATIN": "NYSTATIN"
            },
            "QUALITY CARE": {
              "CICLOPIROX OLAMINE": "CICLOPIROX OLAMINE",
              "LAMISIL AT ATHLETES FOOT": "LAMISIL AT ATHLETES FOOT",
              "NAFTIN": "NAFTIN",
              "NYSTATIN": "NYSTATIN",
              "TOLNAFTATE": "TOLNAFTATE"
            },
            "REESE PHARMACEUTICAL": {
              "PODACTIN": "PODACTIN"
            },
            "RITE AID CORPORATION": {
              "RA ANTI-FUNGAL": "RA ANTI-FUNGAL",
              "RA ANTI-FUNGAL FOOT CARE": "RA ANTI-FUNGAL FOOT CARE",
              "RA ANTIFUNGAL": "RA ANTIFUNGAL",
              "RA ANTIFUNGAL FOOT CARE": "RA ANTIFUNGAL FOOT CARE",
              "RA ANTIFUNGAL PEN": "RA ANTIFUNGAL PEN",
              "RA ATHLETES FOOT (TOLNAFTATE)": "RA ATHLETES FOOT (TOLNAFTATE)",
              "RA FOOT CARE (TERBINAFINE)": "RA FOOT CARE (TERBINAFINE)",
              "RA FOOT CARE (TOLNAFTATE)": "RA FOOT CARE (TOLNAFTATE)",
              "RA JOCK ITCH MAX ST": "RA JOCK ITCH MAX ST"
            },
            "RUGBY LABORATORIES": {
              "ANTI-FUNGAL": "ANTI-FUNGAL",
              "TERBINAFINE HCL": "TERBINAFINE HCL"
            },
            "SANDOZ": {
              "CICLOPIROX": "CICLOPIROX"
            },
            "SEBELA PHARMACEUTICALS": {
              "NAFTIN": "NAFTIN"
            },
            "SELECT BRAND": {
              "SB ANTI-FUNGAL": "SB ANTI-FUNGAL"
            },
            "SPECTRUM": {
              "BENZOIC ACID": "BENZOIC ACID",
              "CICLOPIROX OLAMINE": "CICLOPIROX OLAMINE",
              "CLIOQUINOL": "CLIOQUINOL",
              "TOLNAFTATE": "TOLNAFTATE"
            },
            "TARGET": {
              "TGT ANTIFUNGAL": "TGT ANTIFUNGAL",
              "TGT ANTIFUNGAL SPRAY POWDER": "TGT ANTIFUNGAL SPRAY POWDER",
              "TGT ATHLETES FOOT": "TGT ATHLETES FOOT"
            },
            "TARO": {
              "BUTENAFINE HCL": "BUTENAFINE HCL",
              "CICLOPIROX": "CICLOPIROX",
              "CICLOPIROX OLAMINE": "CICLOPIROX OLAMINE",
              "NAFTIFINE HCL": "NAFTIFINE HCL",
              "NYSTATIN": "NYSTATIN",
              "TERBINAFINE HCL": "TERBINAFINE HCL",
              "TOLNAFTATE": "TOLNAFTATE"
            },
            "TELIGENT": {
              "CICLOPIROX": "CICLOPIROX"
            },
            "TEVA PHARMACEUTICALS USA": {
              "TOLNAFTATE": "TOLNAFTATE"
            },
            "THE PODIATREE COMPANY": {
              "GENTIAN VIOLET": "GENTIAN VIOLET"
            },
            "THE TETRA CORPORATION": {
              "THE TREATMENT FORMULA 3": "THE TREATMENT FORMULA 3"
            },
            "TORRENT PHARMACEUTICALS": {
              "NYSTATIN": "NYSTATIN"
            },
            "TRIFECTA PHARMACEUTICALS": {
              "TOLNAFTATE": "TOLNAFTATE"
            },
            "UPSHER-SMITH": {
              "NYAMYC": "NYAMYC"
            },
            "VENSUN PHARMACEUTICALS": {
              "NYSTATIN": "NYSTATIN"
            },
            "VIONA PHARMACEUTICALS": {
              "NYSTATIN": "NYSTATIN"
            },
            "WAL-MART": {
              "ANTI-FUNGAL": "ANTI-FUNGAL",
              "EQ ATHLETES FOOT": "EQ ATHLETES FOOT",
              "EQ ATHLETES FOOT (TERBINAFINE)": "EQ ATHLETES FOOT (TERBINAFINE)",
              "EQ ATHLETES FOOT (TOLNAFTATE)": "EQ ATHLETES FOOT (TOLNAFTATE)",
              "EQ TOLNAFTATE": "EQ TOLNAFTATE"
            },
            "WALGREENS": {
              "ANTI-FUNGAL": "ANTI-FUNGAL",
              "ATHLETES FOOT (TERBINAFINE)": "ATHLETES FOOT (TERBINAFINE)",
              "BUTENAFINE HCL": "BUTENAFINE HCL",
              "JOCK ITCH SPRAY POWDER": "JOCK ITCH SPRAY POWDER",
              "MEDICATED ANTI-FUNGAL": "MEDICATED ANTI-FUNGAL",
              "ODOR CONTROL FOOT & SNEAKER": "ODOR CONTROL FOOT & SNEAKER",
              "TERBINAFINE HCL": "TERBINAFINE HCL",
              "TOLNAFTATE": "TOLNAFTATE"
            },
            "WOODWARD LABS": {
              "DR GS CLEAR NAIL": "DR GS CLEAR NAIL",
              "MYCOCIDE CLINICAL NS": "MYCOCIDE CLINICAL NS"
            },
            "X-GEN PHARMACEUTICALS, INC": {
              "NYSTATIN": "NYSTATIN"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "NYSTATIN": "NYSTATIN"
            }
          },
          "Antifungals - Topical Combinations": {
            "A-S MEDICATION SOLUTIONS": {
              "CLOTRIMAZOLE-BETAMETHASONE": "CLOTRIMAZOLE-BETAMETHASONE",
              "HYDROCORTISONE-IODOQUINOL": "HYDROCORTISONE-IODOQUINOL",
              "NYSTATIN-TRIAMCINOLONE": "NYSTATIN-TRIAMCINOLONE"
            },
            "ACTAVIS MID ATLANTIC": {
              "CLOTRIMAZOLE-BETAMETHASONE": "CLOTRIMAZOLE-BETAMETHASONE",
              "NYSTATIN-TRIAMCINOLONE": "NYSTATIN-TRIAMCINOLONE"
            },
            "AG MARIN PHARMACEUTICALS": {
              "EXODERM": "EXODERM"
            },
            "AIDAREX PHARMACEUTICALS": {
              "CLOTRIMAZOLE-BETAMETHASONE": "CLOTRIMAZOLE-BETAMETHASONE",
              "HYDROCORTISONE-IODOQUINOL": "HYDROCORTISONE-IODOQUINOL",
              "NYSTATIN-TRIAMCINOLONE": "NYSTATIN-TRIAMCINOLONE"
            },
            "ARTESA LABS": {
              "VYTONE": "VYTONE"
            },
            "BAUSCH HEALTH": {
              "BREEZEE MIST": "BREEZEE MIST",
              "CASTELLANI PAINT MODIFIED": "CASTELLANI PAINT MODIFIED"
            },
            "BOWYN LABS": {
              "IODOQUINOL-HYDROCORTISONE-ALOE": "IODOQUINOL-HYDROCORTISONE-ALOE"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "CLOTRIMAZOLE-BETAMETHASONE": "CLOTRIMAZOLE-BETAMETHASONE",
              "NYSTATIN-TRIAMCINOLONE": "NYSTATIN-TRIAMCINOLONE"
            },
            "COLOPLAST": {
              "BAZA": "BAZA"
            },
            "CROWN LABORATORIES": {
              "DERMASORB AF": "DERMASORB AF",
              "NYATA": "NYATA",
              "NYSTATIN-TRIAMCINOLONE": "NYSTATIN-TRIAMCINOLONE"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "NYSTATIN-TRIAMCINOLONE": "NYSTATIN-TRIAMCINOLONE"
            },
            "ENCUBE ETHICALS": {
              "NYSTATIN-TRIAMCINOLONE": "NYSTATIN-TRIAMCINOLONE"
            },
            "FOUGERA": {
              "CLOTRIMAZOLE-BETAMETHASONE": "CLOTRIMAZOLE-BETAMETHASONE",
              "NYSTATIN-TRIAMCINOLONE": "NYSTATIN-TRIAMCINOLONE"
            },
            "GLAXO SMITH KLINE": {
              "XOLEGEL COREPAK": "XOLEGEL COREPAK",
              "XOLEGEL DUO/HEAD & SHOULDERS": "XOLEGEL DUO/HEAD & SHOULDERS",
              "XOLEGEL DUO/XOLEX": "XOLEGEL DUO/XOLEX"
            },
            "GLENMARK PHARMACEUTICALS": {
              "CLOTRIMAZOLE-BETAMETHASONE": "CLOTRIMAZOLE-BETAMETHASONE",
              "NYSTATIN-TRIAMCINOLONE": "NYSTATIN-TRIAMCINOLONE"
            },
            "GORDON": {
              "GORDONS NO 5": "GORDONS NO 5",
              "UNDELENIC": "UNDELENIC"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "CLOTRIMAZOLE-BETAMETHASONE": "CLOTRIMAZOLE-BETAMETHASONE",
              "HYDROCORTISONE-IODOQUINOL": "HYDROCORTISONE-IODOQUINOL",
              "LOTRISONE": "LOTRISONE",
              "NYSTATIN-TRIAMCINOLONE": "NYSTATIN-TRIAMCINOLONE"
            },
            "HUMCO": {
              "RECURA": "RECURA"
            },
            "IPG PHARMACEUTICALS": {
              "NYSTATIN-TRIAMCINOLONE": "NYSTATIN-TRIAMCINOLONE"
            },
            "KMM PHARMACEUTICALS": {
              "HYDROCORTISONE-IODOQUINOL": "HYDROCORTISONE-IODOQUINOL"
            },
            "KRAMER LABS": {
              "FUNGI-NAIL TOE & FOOT": "FUNGI-NAIL TOE & FOOT",
              "HONGO CURA ANTI-FUNGAL": "HONGO CURA ANTI-FUNGAL"
            },
            "KRAMER-NOVIS": {
              "G-MYCO NAIL": "G-MYCO NAIL",
              "MYCO NAIL": "MYCO NAIL"
            },
            "LIFESTAR PHARMA": {
              "NYSTATIN-TRIAMCINOLONE": "NYSTATIN-TRIAMCINOLONE"
            },
            "LUPIN PHARMACEUTICALS": {
              "NYSTATIN-TRIAMCINOLONE": "NYSTATIN-TRIAMCINOLONE"
            },
            "MARNEL PHARMACEUTICAL": {
              "ALA-QUIN": "ALA-QUIN"
            },
            "MEDHART PHARMACEUTICALS": {
              "ONYCHO-MED": "ONYCHO-MED"
            },
            "MEDTECH": {
              "CLOVERINE SALVE": "CLOVERINE SALVE"
            },
            "MEDVANTX": {
              "NYSTATIN-TRIAMCINOLONE": "NYSTATIN-TRIAMCINOLONE"
            },
            "MERCK SHARP & DOHME": {
              "LOTRISONE": "LOTRISONE"
            },
            "MESORA PHARMA": {
              "FUNGIMEZ": "FUNGIMEZ",
              "IODOQUIMEZ-HC": "IODOQUIMEZ-HC",
              "IODOQUINOL-HC-ALOE POLYSACCH": "IODOQUINOL-HC-ALOE POLYSACCH"
            },
            "MYLAN": {
              "MICONAZOLE-ZINC OXIDE-PETROLAT": "MICONAZOLE-ZINC OXIDE-PETROLAT",
              "VUSION": "VUSION"
            },
            "NORTHSTAR RX": {
              "CLOTRIMAZOLE-BETAMETHASONE": "CLOTRIMAZOLE-BETAMETHASONE"
            },
            "NOVUM PHARMA": {
              "ALCORTIN A": "ALCORTIN A",
              "ALOQUIN": "ALOQUIN",
              "QUINJA": "QUINJA"
            },
            "NUCARE PHARMACEUTICALS": {
              "NYSTATIN-TRIAMCINOLONE": "NYSTATIN-TRIAMCINOLONE",
              "PEDIZOLPAK": "PEDIZOLPAK"
            },
            "PERRIGO PHARMACEUTICALS": {
              "HYDROCORTISONE-IODOQUINOL": "HYDROCORTISONE-IODOQUINOL",
              "NYSTATIN-TRIAMCINOLONE": "NYSTATIN-TRIAMCINOLONE"
            },
            "PHARMACEUTICA NORTH AMERICA": {
              "ACTIVE-PREP KIT V": "ACTIVE-PREP KIT V"
            },
            "PHARMEDIX": {
              "CLOTRIMAZOLE-BETAMETHASONE": "CLOTRIMAZOLE-BETAMETHASONE",
              "NYSTATIN-TRIAMCINOLONE": "NYSTATIN-TRIAMCINOLONE"
            },
            "PRASCO LABORATORIES": {
              "CLOTRIMAZOLE-BETAMETHASONE": "CLOTRIMAZOLE-BETAMETHASONE"
            },
            "PREFERRED PHARMACEUTICALS": {
              "CLOTRIMAZOLE-BETAMETHASONE": "CLOTRIMAZOLE-BETAMETHASONE"
            },
            "PRESTIUM PHARMA": {
              "VUSION": "VUSION"
            },
            "PROFICIENT RX": {
              "CLOTRIMAZOLE-BETAMETHASONE": "CLOTRIMAZOLE-BETAMETHASONE"
            },
            "PURE TEK": {
              "DERMACINRX THERAZOLE PAK": "DERMACINRX THERAZOLE PAK",
              "ECONASIL": "ECONASIL",
              "ZOLPAK": "ZOLPAK"
            },
            "QUALITY CARE": {
              "CLOTRIMAZOLE-BETAMETHASONE": "CLOTRIMAZOLE-BETAMETHASONE",
              "NYSTATIN-TRIAMCINOLONE": "NYSTATIN-TRIAMCINOLONE"
            },
            "QUALITY CARE PRODUCTS": {
              "CLOTRIMAZOLE-BETAMETHASONE": "CLOTRIMAZOLE-BETAMETHASONE"
            },
            "RISING PHARMACEUTICALS": {
              "NYSTATIN-TRIAMCINOLONE": "NYSTATIN-TRIAMCINOLONE"
            },
            "SANDOZ": {
              "NYSTATIN-TRIAMCINOLONE": "NYSTATIN-TRIAMCINOLONE"
            },
            "SETON PHARMACEUTICALS": {
              "HYDROCORTISONE-IODOQUINOL": "HYDROCORTISONE-IODOQUINOL",
              "IODOQUINOL-HC-ALOE POLYSACCH": "IODOQUINOL-HC-ALOE POLYSACCH"
            },
            "SINCERUS FLORIDA": {
              "ECONAZOLE NITRATE-NIACINAMIDE": "ECONAZOLE NITRATE-NIACINAMIDE",
              "FLUCON-IBUPROF-ITRACON-TERBINA": "FLUCON-IBUPROF-ITRACON-TERBINA",
              "IODOQUINOL-HC-KETOCONAZOLE": "IODOQUINOL-HC-KETOCONAZOLE",
              "KETOCONAZOLE-HYDROCORTISONE": "KETOCONAZOLE-HYDROCORTISONE"
            },
            "STRATUS PHARMACEUTICALS": {
              "CASTELLANI PAINT MODIFIED": "CASTELLANI PAINT MODIFIED",
              "DERMAZENE": "DERMAZENE"
            },
            "STRIDES PHARMA": {
              "NYSTATIN-TRIAMCINOLONE": "NYSTATIN-TRIAMCINOLONE"
            },
            "SYNTENZA PHARMACEUTICALS": {
              "HYDROCORTISONE-IODOQUINOL": "HYDROCORTISONE-IODOQUINOL",
              "IODOQUINOL-HC-ALOE POLYSACCH": "IODOQUINOL-HC-ALOE POLYSACCH"
            },
            "TARO": {
              "CLOTRIMAZOLE-BETAMETHASONE": "CLOTRIMAZOLE-BETAMETHASONE",
              "NYSTATIN-TRIAMCINOLONE": "NYSTATIN-TRIAMCINOLONE"
            },
            "TELIGENT": {
              "NYSTATIN-TRIAMCINOLONE": "NYSTATIN-TRIAMCINOLONE"
            },
            "THE PODIATREE COMPANY": {
              "CASTELLANI PAINT": "CASTELLANI PAINT"
            },
            "TOTAL PHARMACY SUPPLY": {
              "PEDIPAK": "PEDIPAK"
            },
            "UNIT DOSE SERVICES": {
              "CLOTRIMAZOLE-BETAMETHASONE": "CLOTRIMAZOLE-BETAMETHASONE"
            },
            "WALGREENS": {
              "ATHLETES FOOT MAXIMUM STRENGTH": "ATHLETES FOOT MAXIMUM STRENGTH"
            }
          },
          "Imidazole-Related Antifungals - Topical": {
            "3M MEDICAL": {
              "CAVILON": "CAVILON"
            },
            "A-S MEDICATION SOLUTIONS": {
              "ANTIFUNGAL": "ANTIFUNGAL",
              "CLOTRIMAZOLE": "CLOTRIMAZOLE",
              "ECONAZOLE NITRATE": "ECONAZOLE NITRATE",
              "KETOCONAZOLE": "KETOCONAZOLE",
              "MICONAZOLE NITRATE": "MICONAZOLE NITRATE",
              "NIZORAL": "NIZORAL",
              "SM ANTIFUNGAL MICONAZOLE": "SM ANTIFUNGAL MICONAZOLE"
            },
            "ACTAVIS MID ATLANTIC": {
              "ANTIFUNGAL": "ANTIFUNGAL",
              "CLOTRIMAZOLE ANTI-FUNGAL": "CLOTRIMAZOLE ANTI-FUNGAL"
            },
            "AIDAREX PHARMACEUTICALS": {
              "CLOTRIMAZOLE AF": "CLOTRIMAZOLE AF",
              "CLOTRIMAZOLE ANTI-FUNGAL": "CLOTRIMAZOLE ANTI-FUNGAL",
              "ECONAZOLE NITRATE": "ECONAZOLE NITRATE",
              "KETOCONAZOLE": "KETOCONAZOLE",
              "MICONAZOLE NITRATE": "MICONAZOLE NITRATE"
            },
            "AKRON PHARMA": {
              "CLOTRIMAZOLE": "CLOTRIMAZOLE"
            },
            "ALMIRALL": {
              "XOLEGEL": "XOLEGEL"
            },
            "ALTAIRE": {
              "MICADERM": "MICADERM"
            },
            "ALVA-AMCO": {
              "FUNGICURE INTENSIVE/NAILGUARD": "FUNGICURE INTENSIVE/NAILGUARD"
            },
            "ALVIX LABORATORIES": {
              "CLOTRIMAZOLE": "CLOTRIMAZOLE"
            },
            "ALVOGEN": {
              "ECONAZOLE NITRATE": "ECONAZOLE NITRATE"
            },
            "AMERICAN PHARMA INGREDIENTS": {
              "MICONAZOLE NITRATE": "MICONAZOLE NITRATE"
            },
            "AMERICAN SALES COMPANY": {
              "ATHLETES FOOT SPRAY": "ATHLETES FOOT SPRAY",
              "CLOTRIMAZOLE ANTI-FUNGAL": "CLOTRIMAZOLE ANTI-FUNGAL"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP ATHLETES FOOT": "GNP ATHLETES FOOT",
              "GNP MICONAZORB AF": "GNP MICONAZORB AF"
            },
            "ARU PHARMA": {
              "CLOTRIMAZOLE": "CLOTRIMAZOLE",
              "MICONAZOLE NITRATE": "MICONAZOLE NITRATE"
            },
            "BAUSCH HEALTH": {
              "ERTACZO": "ERTACZO",
              "FUNGOID TINCTURE": "FUNGOID TINCTURE",
              "JUBLIA": "JUBLIA",
              "LUZU": "LUZU"
            },
            "BAYER CONSUMER": {
              "LOTRIMIN AF": "LOTRIMIN AF",
              "LOTRIMIN AF DEODORANT POWDER": "LOTRIMIN AF DEODORANT POWDER",
              "LOTRIMIN AF FOR HER": "LOTRIMIN AF FOR HER",
              "LOTRIMIN AF JOCK ITCH": "LOTRIMIN AF JOCK ITCH",
              "LOTRIMIN AF JOCK ITCH POWDER": "LOTRIMIN AF JOCK ITCH POWDER",
              "LOTRIMIN AF POWDER": "LOTRIMIN AF POWDER"
            },
            "BERGEN BRUNSWIG": {
              "GNP ATHLETES FOOT": "GNP ATHLETES FOOT",
              "GNP MICONAZOLE NITRATE": "GNP MICONAZOLE NITRATE"
            },
            "BLAINE LABORATORIES": {
              "TINEACIDE": "TINEACIDE"
            },
            "CAPITAL PHARMACEUTICAL": {
              "ALEVAZOL": "ALEVAZOL"
            },
            "CHAIN DRUG CONSORTIUM": {
              "ANTI-FUNGAL": "ANTI-FUNGAL",
              "CLOTRIMAZOLE AF": "CLOTRIMAZOLE AF",
              "MICONAZOLE NITRATE": "MICONAZOLE NITRATE",
              "MICONAZORB AF": "MICONAZORB AF"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC CLOTRIMAZOLE": "QC CLOTRIMAZOLE"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "ECONAZOLE NITRATE": "ECONAZOLE NITRATE",
              "KETOCONAZOLE": "KETOCONAZOLE"
            },
            "COLOPLAST": {
              "BAZA ANTIFUNGAL": "BAZA ANTIFUNGAL",
              "CRITIC-AID CLEAR AF": "CRITIC-AID CLEAR AF",
              "MICRO GUARD": "MICRO GUARD"
            },
            "CONVATEC": {
              "ALOE VESTA ANTIFUNGAL": "ALOE VESTA ANTIFUNGAL",
              "ALOE VESTA CLEAR ANTIFUNGAL": "ALOE VESTA CLEAR ANTIFUNGAL"
            },
            "COSETTE PHARMACEUTICALS": {
              "KETOCONAZOLE": "KETOCONAZOLE"
            },
            "CROWN LABORATORIES": {
              "DESENEX": "DESENEX",
              "ZEASORB-AF": "ZEASORB-AF"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS ANTI-FUNGAL": "CVS ANTI-FUNGAL",
              "CVS ATHLETES FOOT": "CVS ATHLETES FOOT",
              "CVS ATHLETES FOOT SPRAY": "CVS ATHLETES FOOT SPRAY",
              "CVS CLOTRIMAZOLE": "CVS CLOTRIMAZOLE",
              "CVS ITCH RELIEF": "CVS ITCH RELIEF",
              "CVS RINGWORM": "CVS RINGWORM"
            },
            "DERMARITE": {
              "DERMAFUNGAL": "DERMAFUNGAL"
            },
            "DIRECT RX": {
              "KETOCONAZOLE": "KETOCONAZOLE",
              "MICONAZOLE NITRATE": "MICONAZOLE NITRATE"
            },
            "EQUALINE": {
              "EQL ANTIFUNGAL": "EQL ANTIFUNGAL",
              "EQL ATHLETES FOOT": "EQL ATHLETES FOOT",
              "EQL ATHLETES FOOT POWDER SPRAY": "EQL ATHLETES FOOT POWDER SPRAY"
            },
            "FAGRON": {
              "CLOTRIMAZOLE": "CLOTRIMAZOLE",
              "MICONAZOLE NITRATE": "MICONAZOLE NITRATE"
            },
            "FOUGERA": {
              "CLOTRIMAZOLE": "CLOTRIMAZOLE",
              "ECONAZOLE NITRATE": "ECONAZOLE NITRATE"
            },
            "FREEDOM PHARMACEUTICALS": {
              "CLOTRIMAZOLE": "CLOTRIMAZOLE",
              "MICONAZOLE NITRATE": "MICONAZOLE NITRATE"
            },
            "GERITREX CORPORATION": {
              "CLOTRIMAZOLE GRX": "CLOTRIMAZOLE GRX"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "CRUEX PRESCRIPTION STRENGTH": "CRUEX PRESCRIPTION STRENGTH",
              "DESENEX": "DESENEX",
              "DESENEX JOCK ITCH": "DESENEX JOCK ITCH",
              "ZEASORB-AF": "ZEASORB-AF"
            },
            "GLENMARK PHARMACEUTICALS": {
              "CLOTRIMAZOLE": "CLOTRIMAZOLE"
            },
            "GLENMARK THERAPEUTICS": {
              "ECOZA": "ECOZA"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "KETOCONAZOLE": "KETOCONAZOLE"
            },
            "GRANULES USA": {
              "CLOTRIMAZOLE": "CLOTRIMAZOLE"
            },
            "GRAXCELL PHARMACEUTICAL": {
              "CLOTRIMAZOLE": "CLOTRIMAZOLE"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "CLOTRIMAZOLE": "CLOTRIMAZOLE",
              "CLOTRIMAZOLE ANTI-FUNGAL": "CLOTRIMAZOLE ANTI-FUNGAL",
              "KETOCONAZOLE": "KETOCONAZOLE"
            },
            "HUMCO": {
              "CLOTRIMAZOLE": "CLOTRIMAZOLE"
            },
            "IPG PHARMACEUTICALS": {
              "KETOCONAZOLE": "KETOCONAZOLE"
            },
            "JANSSEN": {
              "NIZORAL": "NIZORAL"
            },
            "JG PHARMA": {
              "SULCONAZOLE NITRATE": "SULCONAZOLE NITRATE"
            },
            "JOURNEY MEDICAL CORPORATION": {
              "EXELDERM": "EXELDERM"
            },
            "KAISER FOUNDATION HOSPITAL": {
              "KP CLOTRIMAZOLE": "KP CLOTRIMAZOLE",
              "KP MICONAZOLE NITRATE": "KP MICONAZOLE NITRATE"
            },
            "KALCHEM INTERNATIONAL": {
              "CLOTRIMAZOLE": "CLOTRIMAZOLE"
            },
            "KRAMER LABS": {
              "NIZORAL A-D": "NIZORAL A-D"
            },
            "LEADER BRAND PRODUCTS": {
              "ANTIFUNGAL": "ANTIFUNGAL",
              "ATHLETES FOOT POWDER SPRAY": "ATHLETES FOOT POWDER SPRAY",
              "CLOTRIMAZOLE ANTI-FUNGAL": "CLOTRIMAZOLE ANTI-FUNGAL",
              "CLOTRIMAZOLE ATHLETES FOOT": "CLOTRIMAZOLE ATHLETES FOOT",
              "MICONAZOLE NITRATE": "MICONAZOLE NITRATE",
              "MICONAZORB AF": "MICONAZORB AF"
            },
            "LETCO MEDICAL": {
              "CLOTRIMAZOLE": "CLOTRIMAZOLE",
              "MICONAZOLE NITRATE": "MICONAZOLE NITRATE"
            },
            "MAJOR PHARMACEUTICALS": {
              "CLOTRIMAZOLE": "CLOTRIMAZOLE"
            },
            "MARLEX PHARMACEUTICALS": {
              "CLOTRIMAZOLE": "CLOTRIMAZOLE"
            },
            "MCKESSON SUNMARK": {
              "SM ANTIFUNGAL CLOTRIMAZOLE": "SM ANTIFUNGAL CLOTRIMAZOLE",
              "SM ANTIFUNGAL MICONAZOLE": "SM ANTIFUNGAL MICONAZOLE"
            },
            "MCNEIL CONSUMER": {
              "NIZORAL A-D": "NIZORAL A-D"
            },
            "MEDIMETRIKS PHARMACEUTICALS": {
              "KETODAN": "KETODAN"
            },
            "MEDISCA": {
              "CLOTRIMAZOLE": "CLOTRIMAZOLE",
              "MICONAZOLE NITRATE": "MICONAZOLE NITRATE"
            },
            "MEDLINE/DERMAL MANAGEMENT": {
              "CARRINGTON ANTIFUNGAL": "CARRINGTON ANTIFUNGAL",
              "REMEDY ANTIFUNGAL": "REMEDY ANTIFUNGAL",
              "REMEDY ANTIFUNGAL CLEAR": "REMEDY ANTIFUNGAL CLEAR",
              "REMEDY PHYTOPLEX ANTIFUNGAL": "REMEDY PHYTOPLEX ANTIFUNGAL",
              "SOOTHE & COOL INZO ANTIFUNGAL": "SOOTHE & COOL INZO ANTIFUNGAL"
            },
            "MPM MEDICAL, INC.": {
              "ANTI-FUNGAL": "ANTI-FUNGAL"
            },
            "MYLAN": {
              "ECONAZOLE NITRATE": "ECONAZOLE NITRATE",
              "EXTINA": "EXTINA",
              "KETOCONAZOLE": "KETOCONAZOLE"
            },
            "NATUREPLEX": {
              "PRO-EX ANTIFUNGAL": "PRO-EX ANTIFUNGAL"
            },
            "NEW WORLD IMPORTS": {
              "CLOTRIMAZOLE": "CLOTRIMAZOLE"
            },
            "NORTHSTAR RX": {
              "KETOCONAZOLE": "KETOCONAZOLE"
            },
            "NOVANA MEDICAL": {
              "ANTIFUNGAL": "ANTIFUNGAL"
            },
            "NOVITIUM PHARMA": {
              "CLOTRIMAZOLE": "CLOTRIMAZOLE"
            },
            "NUCARE PHARMACEUTICALS": {
              "CLOTRIMAZOLE": "CLOTRIMAZOLE",
              "KETOCONAZOLE": "KETOCONAZOLE",
              "MICONAZOLE NITRATE": "MICONAZOLE NITRATE"
            },
            "OCEANSIDE PHARMACEUTICALS": {
              "LULICONAZOLE": "LULICONAZOLE"
            },
            "PAR PHARMACEUTICAL": {
              "MICONAZOLE NITRATE": "MICONAZOLE NITRATE"
            },
            "PATRIOT PHARMACEUTICALS LLC": {
              "KETOCONAZOLE": "KETOCONAZOLE"
            },
            "PCCA": {
              "CLOTRIMAZOLE": "CLOTRIMAZOLE",
              "MICONAZOLE NITRATE": "MICONAZOLE NITRATE"
            },
            "PERRIGO": {
              "CLOTRIMAZOLE": "CLOTRIMAZOLE"
            },
            "PERRIGO PHARMACEUTICALS": {
              "ECONAZOLE NITRATE": "ECONAZOLE NITRATE",
              "KETOCONAZOLE": "KETOCONAZOLE"
            },
            "PHARMEDIX": {
              "ECONAZOLE NITRATE": "ECONAZOLE NITRATE",
              "KETOCONAZOLE": "KETOCONAZOLE"
            },
            "PREFERRED PHARMACEUTICALS": {
              "ANTIFUNGAL": "ANTIFUNGAL",
              "CLOTRIMAZOLE": "CLOTRIMAZOLE",
              "ECONAZOLE NITRATE": "ECONAZOLE NITRATE",
              "KETOCONAZOLE": "KETOCONAZOLE"
            },
            "PRESTIUM PHARMA": {
              "EXTINA": "EXTINA"
            },
            "PROFICIENT RX": {
              "ANTIFUNGAL": "ANTIFUNGAL",
              "CLOTRIMAZOLE": "CLOTRIMAZOLE",
              "KETOCONAZOLE": "KETOCONAZOLE"
            },
            "PUBLIX SUPER MARKETS INC.": {
              "PX ATHLETIC FOOT": "PX ATHLETIC FOOT"
            },
            "QUALITY CARE": {
              "CLOTRIMAZOLE": "CLOTRIMAZOLE",
              "ECONAZOLE NITRATE": "ECONAZOLE NITRATE",
              "ERTACZO": "ERTACZO",
              "KETOCONAZOLE": "KETOCONAZOLE",
              "MICRO GUARD": "MICRO GUARD",
              "OXISTAT": "OXISTAT"
            },
            "QUALITY CARE PRODUCTS": {
              "ECONAZOLE NITRATE": "ECONAZOLE NITRATE"
            },
            "REESE PHARMACEUTICAL": {
              "PODACTIN": "PODACTIN"
            },
            "RITE AID CORPORATION": {
              "RA ANTIFUNGAL": "RA ANTIFUNGAL",
              "RA ATHELETES FOOT": "RA ATHELETES FOOT",
              "RA ATHLETES FOOT": "RA ATHLETES FOOT",
              "RA CLOTRIMAZOLE": "RA CLOTRIMAZOLE",
              "RA JOCK ITCH": "RA JOCK ITCH"
            },
            "RUGBY LABORATORIES": {
              "ANTIFUNGAL CLOTRIMAZOLE": "ANTIFUNGAL CLOTRIMAZOLE",
              "CLOTRIMAZOLE": "CLOTRIMAZOLE",
              "CLOTRIMAZOLE ANTI-FUNGAL": "CLOTRIMAZOLE ANTI-FUNGAL",
              "MICONAZOLE NITRATE": "MICONAZOLE NITRATE"
            },
            "SANDOZ": {
              "KETOCONAZOLE": "KETOCONAZOLE",
              "OXICONAZOLE NITRATE": "OXICONAZOLE NITRATE",
              "OXISTAT": "OXISTAT"
            },
            "SELECT BRAND": {
              "SB CLOTRIMAZOLE FOOT": "SB CLOTRIMAZOLE FOOT"
            },
            "SHEFFIELD PHARMACEUTICALS": {
              "ATHLETES FOOT (CLOTRIMAZOLE)": "ATHLETES FOOT (CLOTRIMAZOLE)"
            },
            "SHOPKO STORES OPERATING CO": {
              "SHOPKO ATHLETES FOOT": "SHOPKO ATHLETES FOOT"
            },
            "SMITH & NEPHEW UNITED": {
              "SECURA ANTIFUNGAL": "SECURA ANTIFUNGAL",
              "SECURA ANTIFUNGAL EXTRA THICK": "SECURA ANTIFUNGAL EXTRA THICK"
            },
            "SOLA PHARMACEUTICALS": {
              "CLOTRIMAZOLE": "CLOTRIMAZOLE",
              "ECONAZOLE NITRATE": "ECONAZOLE NITRATE"
            },
            "SPECTRUM": {
              "CLOTRIMAZOLE": "CLOTRIMAZOLE",
              "MICONAZOLE NITRATE": "MICONAZOLE NITRATE"
            },
            "SSS": {
              "TETTERINE": "TETTERINE"
            },
            "STRATUS PHARMACEUTICALS": {
              "AZOLEN TINCTURE": "AZOLEN TINCTURE"
            },
            "SUMMERS LABS": {
              "TRIPLE PASTE AF": "TRIPLE PASTE AF"
            },
            "TARGET": {
              "TGT CLOTRIMAZOLE": "TGT CLOTRIMAZOLE"
            },
            "TARO": {
              "CLOTRIMAZOLE": "CLOTRIMAZOLE",
              "ECONAZOLE NITRATE": "ECONAZOLE NITRATE",
              "KETOCONAZOLE": "KETOCONAZOLE",
              "MICONAZOLE NITRATE": "MICONAZOLE NITRATE",
              "OXICONAZOLE NITRATE": "OXICONAZOLE NITRATE"
            },
            "TELIGENT": {
              "ECONAZOLE NITRATE": "ECONAZOLE NITRATE"
            },
            "TEVA PHARMACEUTICALS USA": {
              "CLOTRIMAZOLE": "CLOTRIMAZOLE",
              "KETOCONAZOLE": "KETOCONAZOLE"
            },
            "THE PODIATREE COMPANY": {
              "CLOTRIMAZOLE": "CLOTRIMAZOLE"
            },
            "TOLMAR": {
              "KETOCONAZOLE": "KETOCONAZOLE"
            },
            "TRIFECTA PHARMACEUTICALS": {
              "CLOTRIMAZOLE": "CLOTRIMAZOLE",
              "MICONAZOLE ANTIFUNGAL": "MICONAZOLE ANTIFUNGAL"
            },
            "TRUPHARMA": {
              "CLOTRIMAZOLE": "CLOTRIMAZOLE"
            },
            "WAL-MART": {
              "EQ ANTIFUNGAL": "EQ ANTIFUNGAL",
              "EQ ATHLETES FOOT": "EQ ATHLETES FOOT",
              "EQ ATHLETES FOOT SPRAY": "EQ ATHLETES FOOT SPRAY",
              "EQ JOCK ITCH": "EQ JOCK ITCH"
            },
            "WALGREENS": {
              "ATHLETES FOOT": "ATHLETES FOOT",
              "ATHLETES FOOT POWDER SPRAY": "ATHLETES FOOT POWDER SPRAY",
              "CLOTRIMAZOLE": "CLOTRIMAZOLE",
              "CLOTRIMAZOLE AF": "CLOTRIMAZOLE AF",
              "CLOTRIMAZOLE ANTI-FUNGAL": "CLOTRIMAZOLE ANTI-FUNGAL",
              "JOCK ITCH": "JOCK ITCH",
              "JOCK ITCH RELIEF": "JOCK ITCH RELIEF"
            },
            "WELGO": {
              "OXICONAZOLE NITRATE": "OXICONAZOLE NITRATE"
            },
            "WELLSPRING PHARMACEUTICAL CORP": {
              "MICATIN": "MICATIN"
            }
          },
          "Oxaborole-Related Antifungals - Topical": {
            "ALEMBIC PHARMACEUTICALS": {
              "TAVABOROLE": "TAVABOROLE"
            },
            "ENCUBE ETHICALS": {
              "TAVABOROLE": "TAVABOROLE"
            },
            "SANDOZ": {
              "KERYDIN": "KERYDIN"
            }
          }
        },
        "Antihistamines-Topical": {
          "Antihistamine-Topical Combinations": {
            "ACTAVIS MID ATLANTIC": {
              "ANTI-ITCH EXTRA STRENGTH": "ANTI-ITCH EXTRA STRENGTH"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP ITCH RELIEF EXTRA STRENGTH": "GNP ITCH RELIEF EXTRA STRENGTH"
            },
            "BERGEN BRUNSWIG": {
              "GNP ANTI-ITCH": "GNP ANTI-ITCH"
            },
            "BLISTEX": {
              "IVAREST POISON IVY ITCH": "IVAREST POISON IVY ITCH"
            },
            "CHAIN DRUG CONSORTIUM": {
              "ALLERGY": "ALLERGY",
              "ALLERGY RELIEF MAX ST": "ALLERGY RELIEF MAX ST"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC ANTI-ITCH EXTRA STRENGTH": "QC ANTI-ITCH EXTRA STRENGTH"
            },
            "CHATTEM INC": {
              "ALLEGRA INTENSIVE RELIEF": "ALLEGRA INTENSIVE RELIEF"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS INSTANT ITCH RELIEF": "CVS INSTANT ITCH RELIEF",
              "CVS ITCH RELIEF": "CVS ITCH RELIEF",
              "CVS ITCH RELIEF EXTRA STRENGTH": "CVS ITCH RELIEF EXTRA STRENGTH",
              "CVS ITCH RELIEF MAX ST": "CVS ITCH RELIEF MAX ST"
            },
            "EQUALINE": {
              "EQL ANTI-ITCH EXTRA STRENGTH": "EQL ANTI-ITCH EXTRA STRENGTH"
            },
            "HANNAFORD BROTHERS": {
              "ALLERGY": "ALLERGY"
            },
            "J & J CONSUMER PRODUCTS": {
              "BENADRYL EXTRA STRENGTH": "BENADRYL EXTRA STRENGTH",
              "BENADRYL ITCH RELIEF": "BENADRYL ITCH RELIEF",
              "BENADRYL ITCH STOPPING": "BENADRYL ITCH STOPPING"
            },
            "LEADER BRAND PRODUCTS": {
              "ANTI-ITCH": "ANTI-ITCH",
              "ITCH RELIEF EXTRA STRENGTH": "ITCH RELIEF EXTRA STRENGTH"
            },
            "MAJOR PHARMACEUTICALS": {
              "BANOPHEN": "BANOPHEN"
            },
            "MCKESSON SUNMARK": {
              "SM ANTI-ITCH EXTRA STRENGTH": "SM ANTI-ITCH EXTRA STRENGTH"
            },
            "MEDICINE SHOPPE": {
              "ANTI-ITCH": "ANTI-ITCH"
            },
            "PERRIGO": {
              "DIPHENHYDRAMINE-ZINC ACETATE": "DIPHENHYDRAMINE-ZINC ACETATE",
              "ITCH RELIEF EXTRA STRENGTH": "ITCH RELIEF EXTRA STRENGTH"
            },
            "PHARMAGENETICO": {
              "ALLO-PAX": "ALLO-PAX"
            },
            "QUALITY CARE": {
              "BENADRYL ITCH STOPPING": "BENADRYL ITCH STOPPING"
            },
            "RITE AID CORPORATION": {
              "RA ALLERGY": "RA ALLERGY",
              "RA ANTI-ITCH EXTRA STRENGTH": "RA ANTI-ITCH EXTRA STRENGTH",
              "RA ANTI-ITCH SKIN PROTECTANT": "RA ANTI-ITCH SKIN PROTECTANT"
            },
            "SELECT BRAND": {
              "SB ANTI-ITCH MAXIMUM STRENGTH": "SB ANTI-ITCH MAXIMUM STRENGTH"
            },
            "TARGET": {
              "TGT ITCH RELIEF EXTRA STRENGTH": "TGT ITCH RELIEF EXTRA STRENGTH"
            },
            "TARO": {
              "ANTI-ITCH": "ANTI-ITCH"
            },
            "TEC LABS": {
              "CALAGEL MAXIMUM STRENGTH": "CALAGEL MAXIMUM STRENGTH"
            },
            "TOPCO": {
              "ANTI-ITCH": "ANTI-ITCH"
            },
            "TRIFECTA PHARMACEUTICALS": {
              "ANTI-ITCH": "ANTI-ITCH"
            },
            "WAL-MART": {
              "EQ ANTI-ITCH EXTRA STRENGTH": "EQ ANTI-ITCH EXTRA STRENGTH"
            },
            "WALGREENS": {
              "ANTI-ITCH": "ANTI-ITCH",
              "WAL-DRYL": "WAL-DRYL",
              "WAL-DRYL ANTI-ITCH": "WAL-DRYL ANTI-ITCH"
            }
          },
          "Antihistamines - Topical": {
            "ALTAIRE": {
              "ITCH RELIEF": "ITCH RELIEF"
            },
            "CHAIN DRUG CONSORTIUM": {
              "ALLERGY RELIEF": "ALLERGY RELIEF"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS ANTI-ITCH MAX STR": "CVS ANTI-ITCH MAX STR",
              "CVS ITCH RELIEF": "CVS ITCH RELIEF"
            },
            "J & J CONSUMER PRODUCTS": {
              "BENADRYL ITCH STOPPING": "BENADRYL ITCH STOPPING"
            },
            "LEADER BRAND PRODUCTS": {
              "ANTI-ITCH MAXIMUM STRENGTH": "ANTI-ITCH MAXIMUM STRENGTH"
            },
            "RITE AID CORPORATION": {
              "RA ANTI-ITCH EXTRA STRENGTH": "RA ANTI-ITCH EXTRA STRENGTH"
            },
            "SELECT BRAND": {
              "SB ITCH RELIEF MAX ST": "SB ITCH RELIEF MAX ST"
            },
            "TENDER CORPORATION": {
              "THE ITCH ERASER": "THE ITCH ERASER"
            },
            "TEVA PHARMACEUTICALS USA": {
              "ANTI-ITCH MAXIMUM STRENGTH": "ANTI-ITCH MAXIMUM STRENGTH"
            }
          }
        },
        "Antineoplastic or Premalignant Lesion Agents - Topical": {
          "Antineoplastic Alkylating Agents - Topical": {
            "HELSINN THERAPEUTICS U.S.": {
              "VALCHLOR": "VALCHLOR"
            }
          },
          "Antineoplastic Antimetabolites - Topical": {
            "A-S MEDICATION SOLUTIONS": {
              "FLUOROURACIL": "FLUOROURACIL"
            },
            "ALMIRALL": {
              "FLUOROPLEX": "FLUOROPLEX"
            },
            "BAUSCH HEALTH": {
              "CARAC": "CARAC",
              "EFUDEX": "EFUDEX"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "CARAC": "CARAC"
            },
            "MAYNE PHARMA": {
              "FLUOROURACIL": "FLUOROURACIL"
            },
            "MYLAN": {
              "FLUOROURACIL": "FLUOROURACIL"
            },
            "PIERRE FABRE PHARMACEUTICALS": {
              "TOLAK": "TOLAK"
            },
            "SOLCO HEALTHCARE": {
              "FLUOROURACIL": "FLUOROURACIL"
            },
            "SPEAR DERMATOLOGY PRODUCTS": {
              "FLUOROURACIL": "FLUOROURACIL"
            },
            "TARO": {
              "FLUOROURACIL": "FLUOROURACIL"
            }
          },
          "Antineoplastic Retinoids - Topical": {
            "CONCORDIA PHARMACEUTICALS": {
              "PANRETIN": "PANRETIN"
            }
          },
          "Antineoplastic or Premalignant Lesion Agent - Comb": {
            "PRIMARY PHARMACEUTICALS": {
              "SOLARAVIX": "SOLARAVIX"
            },
            "SINCERUS FLORIDA": {
              "DICLOFENAC-NA HYALURON-NIACIN": "DICLOFENAC-NA HYALURON-NIACIN",
              "IMIQUIMOD-LEVOCET-TRETINOIN": "IMIQUIMOD-LEVOCET-TRETINOIN",
              "IMIQUIMOD-LEVOCETIRIZIN-NIACIN": "IMIQUIMOD-LEVOCETIRIZIN-NIACIN"
            },
            "SIRCLE LABORATORIES": {
              "ORMECA": "ORMECA"
            },
            "TERRAIN PHARMACEUTICALS": {
              "HYALUCIL-4": "HYALUCIL-4"
            }
          },
          "Antineoplastic or Premalignant Lesions - Topical Misc.": {
            "LEO PHARMA INC": {
              "PICATO": "PICATO"
            }
          },
          "Antineoplastic or Premalignant Lesions - Topical NSAID\\'s": {},
          "Photodynamic Therapy Agents - Topical": {
            "BIOFRONTERA": {
              "AMELUZ": "AMELUZ"
            },
            "DUSA PHARMACEUTICALS INC.": {
              "LEVULAN KERASTICK": "LEVULAN KERASTICK"
            }
          },
          "Topical Selective Retinoid X Receptor Agonists": {
            "BAUSCH HEALTH": {
              "TARGRETIN": "TARGRETIN"
            }
          }
        },
        "Antipruritics - Topical": {
          "Antipruritic Combinations - Topical": {
            "AMERISOURCE BERGEN DRUGS": {
              "GNP ANTI-ITCH": "GNP ANTI-ITCH"
            },
            "BAYER CONSUMER": {
              "CAMPHO-PHENIQUE": "CAMPHO-PHENIQUE"
            },
            "BELL-HORN": {
              "FAST FREEZE PRO STYLE THERAPY": "FAST FREEZE PRO STYLE THERAPY"
            },
            "BLAINE LABORATORIES": {
              "ORTHO-NESIC": "ORTHO-NESIC"
            },
            "CHAIN DRUG CONSORTIUM": {
              "INSTANT PAIN RELIEF": "INSTANT PAIN RELIEF"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC ANTISEPTIC PAIN RELIEF": "QC ANTISEPTIC PAIN RELIEF"
            },
            "CROWN LABORATORIES": {
              "SARNA": "SARNA"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS ANTI-ITCH": "CVS ANTI-ITCH"
            },
            "DERMARITE": {
              "DERMASARRA ANTI-ITCH": "DERMASARRA ANTI-ITCH"
            },
            "FOUNDATION CONSUMER HEALTHCARE": {
              "CAMPHO-PHENIQUE": "CAMPHO-PHENIQUE"
            },
            "GERITREX CORPORATION": {
              "MEN-PHOR": "MEN-PHOR"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "SARNA": "SARNA"
            },
            "LEADER BRAND PRODUCTS": {
              "ANTI-ITCH": "ANTI-ITCH",
              "CAMPHOR PHENOL COLD SORE": "CAMPHOR PHENOL COLD SORE"
            },
            "PERFECTA PRODUCTS": {
              "FREEZE IT": "FREEZE IT"
            },
            "QUALITY CARE": {
              "SARNA": "SARNA"
            },
            "RUGBY LABORATORIES": {
              "ANTI-ITCH": "ANTI-ITCH"
            },
            "STRATUS PHARMACEUTICALS": {
              "ARCTIC RELIEF PAIN RELIEVING": "ARCTIC RELIEF PAIN RELIEVING"
            },
            "TRIFECTA PHARMACEUTICALS": {
              "RHULI GEL": "RHULI GEL"
            },
            "WALGREENS": {
              "ANTI-ITCH": "ANTI-ITCH",
              "ANTISEPTIC PAIN RELIEF": "ANTISEPTIC PAIN RELIEF",
              "COLD SORE TREATMENT": "COLD SORE TREATMENT"
            }
          },
          "Antipruritics - Topical": {
            "ADVANTICE HEALTH": {
              "JOINTFLEX": "JOINTFLEX"
            },
            "CHAIN DRUG CONSORTIUM": {
              "ORIGINAL OINTMENT": "ORIGINAL OINTMENT"
            },
            "FAGRON": {
              "CAMPHOR": "CAMPHOR"
            },
            "J & J CONSUMER PRODUCTS": {
              "BENADRYL ANTI-ITCH CHILDRENS": "BENADRYL ANTI-ITCH CHILDRENS"
            },
            "LETCO MEDICAL": {
              "CAMPHOR": "CAMPHOR"
            },
            "MEDISCA": {
              "CAMPHOR": "CAMPHOR"
            },
            "MYLAN": {
              "DOXEPIN HCL": "DOXEPIN HCL",
              "PRUDOXIN": "PRUDOXIN",
              "ZONALON": "ZONALON"
            },
            "PCCA": {
              "CAMPHOR": "CAMPHOR"
            },
            "PHARMACEUTICA NORTH AMERICA": {
              "CAMPHOR": "CAMPHOR"
            },
            "PRESTIUM PHARMA": {
              "PRUDOXIN": "PRUDOXIN",
              "ZONALON": "ZONALON"
            },
            "SPECTRUM": {
              "CAMPHOR": "CAMPHOR"
            },
            "STRIDES USA CONSUMER": {
              "JOINTFLEX": "JOINTFLEX"
            }
          }
        },
        "Antipsoriatics": {
          "Antipsoriatic Combinations": {
            "NUCARE PHARMACEUTICALS": {
              "NUDERMRXPAK 120": "NUDERMRXPAK 120",
              "NUDERMRXPAK 60": "NUDERMRXPAK 60"
            }
          },
          "Antipsoriatics": {
            "A-S MEDICATION SOLUTIONS": {
              "DRITHO-CREME HP": "DRITHO-CREME HP"
            },
            "ALMIRALL": {
              "TAZORAC": "TAZORAC"
            },
            "AMNEAL PHARMACEUTICALS": {
              "CALCIPOTRIENE": "CALCIPOTRIENE"
            },
            "AYURAX": {
              "CALCIPOTRIENE": "CALCIPOTRIENE"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "CALCIPOTRIENE": "CALCIPOTRIENE",
              "TAZORAC": "TAZORAC"
            },
            "COSETTE PHARMACEUTICALS": {
              "CALCIPOTRIENE": "CALCIPOTRIENE",
              "TAZAROTENE": "TAZAROTENE"
            },
            "ELORAC": {
              "ZITHRANOL": "ZITHRANOL",
              "ZITHRANOL-RR": "ZITHRANOL-RR"
            },
            "FAGRON": {
              "ANTHRALIN": "ANTHRALIN"
            },
            "FOUGERA": {
              "CALCIPOTRIENE": "CALCIPOTRIENE"
            },
            "GALDERMA": {
              "VECTICAL": "VECTICAL"
            },
            "GLENMARK PHARMACEUTICALS": {
              "CALCIPOTRIENE": "CALCIPOTRIENE"
            },
            "GREENSTONE": {
              "TAZAROTENE": "TAZAROTENE"
            },
            "HI-TECH": {
              "CALCIPOTRIENE": "CALCIPOTRIENE"
            },
            "LEO PHARMA INC": {
              "DOVONEX": "DOVONEX"
            },
            "MARNEL PHARMACEUTICAL": {
              "DRITHO-CREME HP": "DRITHO-CREME HP"
            },
            "MAYNE PHARMA": {
              "SORILUX": "SORILUX"
            },
            "NORTHSTAR RX": {
              "CALCIPOTRIENE": "CALCIPOTRIENE"
            },
            "PCCA": {
              "ANTHRALIN": "ANTHRALIN"
            },
            "PERRIGO PHARMACEUTICALS": {
              "CALCITRIOL": "CALCITRIOL"
            },
            "PRASCO LABORATORIES": {
              "CALCIPOTRIENE": "CALCIPOTRIENE"
            },
            "QUALITY CARE": {
              "DOVONEX": "DOVONEX"
            },
            "SANDOZ": {
              "CALCIPOTRIENE": "CALCIPOTRIENE"
            },
            "SPECTRUM": {
              "ANTHRALIN": "ANTHRALIN"
            },
            "TARO": {
              "CALCIPOTRIENE": "CALCIPOTRIENE",
              "CALCITRENE": "CALCITRENE",
              "TAZAROTENE": "TAZAROTENE"
            },
            "TRIFLUENT PHARMA": {
              "CALCIPOTRIENE": "CALCIPOTRIENE"
            }
          },
          "Antipsoriatics - Systemic": {
            "ABBVIE": {
              "SKYRIZI (150 MG DOSE)": "SKYRIZI (150 MG DOSE)"
            },
            "ACTAVIS": {
              "METHOXSALEN RAPID": "METHOXSALEN RAPID"
            },
            "AMNEAL PHARMACEUTICALS": {
              "ACITRETIN": "ACITRETIN"
            },
            "AVKARE": {
              "ACITRETIN": "ACITRETIN"
            },
            "BAUSCH HEALTH": {
              "8-MOP": "8-MOP",
              "OXSORALEN ULTRA": "OXSORALEN ULTRA",
              "SILIQ": "SILIQ"
            },
            "GLAXO SMITH KLINE": {
              "SORIATANE": "SORIATANE"
            },
            "IMPAX GENERICS": {
              "ACITRETIN": "ACITRETIN"
            },
            "JANSSEN BIOTECH": {
              "STELARA": "STELARA",
              "TREMFYA": "TREMFYA"
            },
            "LILLY": {
              "TALTZ": "TALTZ"
            },
            "MYLAN": {
              "ACITRETIN": "ACITRETIN"
            },
            "NOVARTIS": {
              "COSENTYX": "COSENTYX",
              "COSENTYX (300 MG DOSE)": "COSENTYX (300 MG DOSE)",
              "COSENTYX SENSOREADY (300 MG)": "COSENTYX SENSOREADY (300 MG)",
              "COSENTYX SENSOREADY PEN": "COSENTYX SENSOREADY PEN"
            },
            "OCEANSIDE PHARMACEUTICALS": {
              "METHOXSALEN RAPID": "METHOXSALEN RAPID"
            },
            "PRASCO LABORATORIES": {
              "ACITRETIN": "ACITRETIN"
            },
            "SIGMAPHARM LABORATORIES": {
              "ACITRETIN": "ACITRETIN"
            },
            "STRIDES PHARMA": {
              "METHOXSALEN RAPID": "METHOXSALEN RAPID"
            },
            "SUN PHARMACEUTICALS": {
              "ILUMYA": "ILUMYA"
            },
            "TEVA PHARMACEUTICALS USA": {
              "ACITRETIN": "ACITRETIN"
            }
          }
        },
        "Antiseborrheic Products": {
          "Antiseborrheic Combinations": {
            "ACELLA PHARMACEUTICALS": {
              "LOUTREX": "LOUTREX"
            },
            "AVION PHARMACEUTICALS": {
              "LOUTREX": "LOUTREX"
            },
            "BAKER CUMMINS DERMATOLOGICALS": {
              "P & S": "P & S"
            },
            "BAUSCH HEALTH": {
              "IONIL": "IONIL"
            },
            "CROWN LABORATORIES": {
              "ALA SEB": "ALA SEB"
            },
            "DERMALOGIX PARTNERS": {
              "DERMAZINC BABY": "DERMAZINC BABY",
              "DERMAZINC CREAM": "DERMAZINC CREAM",
              "DERMAZINC SCALP": "DERMAZINC SCALP",
              "DERMAZINC SOAP": "DERMAZINC SOAP"
            },
            "ENCORE DERMATOLOGY": {
              "PROMISEB": "PROMISEB"
            },
            "ISDIN": {
              "NUTRASEB": "NUTRASEB"
            },
            "KMM PHARMACEUTICALS": {
              "SODIUM SULFACETAMIDE WASH": "SODIUM SULFACETAMIDE WASH"
            },
            "PROMIUS PHARMA": {
              "PROMISEB COMPLETE": "PROMISEB COMPLETE"
            },
            "RANBAXY LABORATORIES": {
              "SEBULEX": "SEBULEX"
            },
            "RUGBY LABORATORIES": {
              "SEBEX": "SEBEX"
            },
            "SINCERUS FLORIDA": {
              "CICLOPIROX-CLOBETASOL": "CICLOPIROX-CLOBETASOL",
              "CICLOPIROX-CLOBETASOL-SAL ACID": "CICLOPIROX-CLOBETASOL-SAL ACID",
              "CICLOPIROX-SALICYLIC ACID": "CICLOPIROX-SALICYLIC ACID"
            },
            "ULTIMARK PRODUCTS": {
              "DENOREX DUAL FORCE": "DENOREX DUAL FORCE"
            }
          },
          "Antiseborrheic Products": {
            "A-S MEDICATION SOLUTIONS": {
              "SELENIUM SULFIDE": "SELENIUM SULFIDE"
            },
            "ACELLA PHARMACEUTICALS": {
              "SELENIUM SULFIDE": "SELENIUM SULFIDE",
              "SULFACETAMIDE SODIUM": "SULFACETAMIDE SODIUM"
            },
            "AKRON PHARMA": {
              "SODIUM SULFACETAMIDE": "SODIUM SULFACETAMIDE"
            },
            "ALTAIRE": {
              "DANDREX": "DANDREX"
            },
            "API SOLUTIONS": {
              "SULFACETAMIDE SODIUM": "SULFACETAMIDE SODIUM"
            },
            "ARTESA LABS": {
              "SELRX": "SELRX"
            },
            "BETA DERMS": {
              "BETA MED": "BETA MED"
            },
            "BI COASTAL PHARMACEUTICAL": {
              "SELENIUM SULFIDE": "SELENIUM SULFIDE"
            },
            "BIOCOMP PHARMA": {
              "SODIUM SULFACETAMIDE": "SODIUM SULFACETAMIDE",
              "SULFACETAMIDE SODIUM": "SULFACETAMIDE SODIUM"
            },
            "BOWYN LABS": {
              "SELENIUM SULFIDE": "SELENIUM SULFIDE"
            },
            "CAMERON PHARMACEUTICALS": {
              "SELENIUM SULFIDE": "SELENIUM SULFIDE"
            },
            "CHAIN DRUG CONSORTIUM": {
              "DANDRUFF SHAMPOO": "DANDRUFF SHAMPOO"
            },
            "CHATTEM INC": {
              "SELSUN BLUE": "SELSUN BLUE",
              "SELSUN BLUE DAILY": "SELSUN BLUE DAILY",
              "SELSUN BLUE DRY SCALP": "SELSUN BLUE DRY SCALP",
              "SELSUN BLUE FULL & THICK": "SELSUN BLUE FULL & THICK",
              "SELSUN BLUE MEDICATED": "SELSUN BLUE MEDICATED",
              "SELSUN BLUE MOISTURIZING": "SELSUN BLUE MOISTURIZING",
              "SELSUN BLUE SALON": "SELSUN BLUE SALON"
            },
            "COMBE": {
              "CONTROLGX ANTI-DANDRUFF": "CONTROLGX ANTI-DANDRUFF"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS ANTI-DANDRUFF": "CVS ANTI-DANDRUFF",
              "CVS DANDRUFF EVERYDAY CLEAN": "CVS DANDRUFF EVERYDAY CLEAN"
            },
            "DERMALOGIX PARTNERS": {
              "DERMAZINC SHAMPOO": "DERMAZINC SHAMPOO",
              "DERMAZINC SPRAY": "DERMAZINC SPRAY",
              "DERMAZINC ZINC THERAPY SOAP": "DERMAZINC ZINC THERAPY SOAP"
            },
            "EQUALINE": {
              "EQL DRY SCALP 2 IN 1": "EQL DRY SCALP 2 IN 1",
              "EQL EVERYDAY CLEAN": "EQL EVERYDAY CLEAN",
              "EQL EVERYDAY CLEAN 2 IN 1": "EQL EVERYDAY CLEAN 2 IN 1",
              "EQL ITCHY SCALP 2 IN 1": "EQL ITCHY SCALP 2 IN 1",
              "EQL MEDICATED DANDRUFF": "EQL MEDICATED DANDRUFF",
              "EQL SMOOTH SPICE 2 IN 1": "EQL SMOOTH SPICE 2 IN 1"
            },
            "EXACT-RX": {
              "SULFACETAMIDE SODIUM": "SULFACETAMIDE SODIUM"
            },
            "EXELTIS USA DERMATOLOGY": {
              "TERSI": "TERSI"
            },
            "FAGRON": {
              "GLYCOLIC ACID": "GLYCOLIC ACID",
              "SULFACETAMIDE SODIUM": "SULFACETAMIDE SODIUM"
            },
            "HUMCO": {
              "SULFACETAMIDE SODIUM": "SULFACETAMIDE SODIUM"
            },
            "KMM PHARMACEUTICALS": {
              "SELENIUM SULFIDE": "SELENIUM SULFIDE"
            },
            "LASER PHARMACEUTICALS": {
              "SELENIUM SULFIDE": "SELENIUM SULFIDE"
            },
            "LEADER BRAND PRODUCTS": {
              "DANDRUFF SHAMPOO": "DANDRUFF SHAMPOO"
            },
            "LETCO MEDICAL": {
              "GLYCOLIC ACID": "GLYCOLIC ACID"
            },
            "MCKESSON SUNMARK": {
              "SM DANDRUFF 2 IN 1": "SM DANDRUFF 2 IN 1",
              "SM DANDRUFF SHAMPOO": "SM DANDRUFF SHAMPOO"
            },
            "MEDICIS": {
              "THERAPLEX Z": "THERAPLEX Z"
            },
            "MEDISCA": {
              "GLYCOLIC ACID": "GLYCOLIC ACID",
              "SULFACETAMIDE SODIUM": "SULFACETAMIDE SODIUM"
            },
            "MEDTECH": {
              "ZINCON DANDRUFF": "ZINCON DANDRUFF"
            },
            "METHOD PHARMACEUTICALS": {
              "SELENIUM SULFIDE": "SELENIUM SULFIDE"
            },
            "MISSION": {
              "OVACE PLUS": "OVACE PLUS",
              "OVACE PLUS WASH": "OVACE PLUS WASH",
              "OVACE WASH": "OVACE WASH"
            },
            "MORTON GROVE PHARMACEUTICALS": {
              "SELENIUM SULFIDE": "SELENIUM SULFIDE"
            },
            "NEUTROGENA": {
              "NEUTROGENA T/GEL DAILY CONTROL": "NEUTROGENA T/GEL DAILY CONTROL"
            },
            "NOBLE PHARMACEUTICALS": {
              "SELENIUM SULFIDE": "SELENIUM SULFIDE"
            },
            "ONTOS": {
              "NOBLE FORMULA": "NOBLE FORMULA"
            },
            "ONTOS, INC": {
              "NOBLE FORMULA": "NOBLE FORMULA"
            },
            "P & G HEALTH": {
              "HEAD & SHOULDERS 2 IN 1": "HEAD & SHOULDERS 2 IN 1",
              "HEAD & SHOULDERS CLASSIC CLEAN": "HEAD & SHOULDERS CLASSIC CLEAN",
              "HEAD & SHOULDERS DRY 2 IN 1": "HEAD & SHOULDERS DRY 2 IN 1"
            },
            "PCCA": {
              "GLYCOLIC ACID": "GLYCOLIC ACID",
              "SULFACETAMIDE SODIUM": "SULFACETAMIDE SODIUM"
            },
            "PERRIGO PHARMACEUTICALS": {
              "SEB-PREV WASH": "SEB-PREV WASH",
              "SELENIUM SULFIDE": "SELENIUM SULFIDE"
            },
            "PERSON COVEY": {
              "DHS BODY WASH": "DHS BODY WASH",
              "DHS ZINC": "DHS ZINC"
            },
            "PHARMACEUTICAL SPECIALITIES": {
              "VANICREAM Z-BAR": "VANICREAM Z-BAR"
            },
            "QUALITY CARE": {
              "SELENIUM SULFIDE": "SELENIUM SULFIDE"
            },
            "RITE AID CORPORATION": {
              "RA DANDRUFF SHAMPOO": "RA DANDRUFF SHAMPOO",
              "RA RENEWAL DANDRUFF": "RA RENEWAL DANDRUFF"
            },
            "ROCHESTER PHARMACEUTICALS": {
              "SELENIUM SULFIDE": "SELENIUM SULFIDE"
            },
            "RUGBY LABORATORIES": {
              "ANTI-DANDRUFF": "ANTI-DANDRUFF"
            },
            "SPECTRUM": {
              "GLYCOLIC ACID": "GLYCOLIC ACID",
              "SULFACETAMIDE SODIUM": "SULFACETAMIDE SODIUM"
            },
            "WALGREENS": {
              "DANDRUFF 2 IN 1": "DANDRUFF 2 IN 1",
              "DANDRUFF DRY SCALP CARE": "DANDRUFF DRY SCALP CARE",
              "DANDRUFF EVERYDAY CLEAN": "DANDRUFF EVERYDAY CLEAN",
              "DANDRUFF SHAMPOO": "DANDRUFF SHAMPOO"
            },
            "WESTMINSTER PHARMACEUTICALS": {
              "SELENIUM SULFIDE": "SELENIUM SULFIDE"
            }
          },
          "Seborrheic Keratosis Product": {
            "ACLARIS THERAPEUTICS": {
              "ESKATA": "ESKATA"
            }
          }
        },
        "Antivirals - Topical": {
          "Antiviral Topical Combinations": {
            "BAUSCH HEALTH": {
              "XERESE": "XERESE"
            }
          },
          "Antivirals - Topical": {
            "A-S MEDICATION SOLUTIONS": {
              "DENAVIR": "DENAVIR",
              "ZOVIRAX": "ZOVIRAX"
            },
            "ACTAVIS PHARMA": {
              "ACYCLOVIR": "ACYCLOVIR",
              "DOCOSANOL": "DOCOSANOL"
            },
            "ALEMBIC PHARMACEUTICALS": {
              "ACYCLOVIR": "ACYCLOVIR"
            },
            "AMNEAL PHARMACEUTICALS": {
              "ACYCLOVIR": "ACYCLOVIR"
            },
            "BAUSCH HEALTH": {
              "ZOVIRAX": "ZOVIRAX"
            },
            "BUREL PHARMACEUTICALS": {
              "ACYCLOVIR": "ACYCLOVIR"
            },
            "COSETTE PHARMACEUTICALS": {
              "ACYCLOVIR": "ACYCLOVIR"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "ABREVA": "ABREVA"
            },
            "GLENMARK PHARMACEUTICALS": {
              "ACYCLOVIR": "ACYCLOVIR"
            },
            "MYLAN": {
              "ACYCLOVIR": "ACYCLOVIR",
              "DENAVIR": "DENAVIR"
            },
            "MYLAN INSTITUTIONAL": {
              "ACYCLOVIR": "ACYCLOVIR"
            },
            "NORTHSTAR RX": {
              "ACYCLOVIR": "ACYCLOVIR"
            },
            "PERRIGO PHARMACEUTICALS": {
              "ACYCLOVIR": "ACYCLOVIR"
            },
            "PRESTIUM PHARMA": {
              "DENAVIR": "DENAVIR"
            },
            "QUALITY CARE": {
              "ZOVIRAX": "ZOVIRAX"
            },
            "SANDOZ": {
              "ACYCLOVIR": "ACYCLOVIR"
            },
            "TARO": {
              "ACYCLOVIR": "ACYCLOVIR"
            },
            "TEVA PHARMACEUTICALS USA": {
              "ACYCLOVIR": "ACYCLOVIR"
            },
            "TOLMAR": {
              "ACYCLOVIR": "ACYCLOVIR"
            },
            "TORRENT PHARMACEUTICALS": {
              "ACYCLOVIR": "ACYCLOVIR"
            },
            "VIONA PHARMACEUTICALS": {
              "ACYCLOVIR": "ACYCLOVIR"
            },
            "XIROMED": {
              "ACYCLOVIR": "ACYCLOVIR"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "ACYCLOVIR": "ACYCLOVIR"
            }
          }
        },
        "Bath Products": {
          "Bath Products": {
            "AMERIDERM LABORATORIES LTD": {
              "ALPHASOFT": "ALPHASOFT"
            },
            "BEIERSDORF DERMA": {
              "NIVEA SHOWER": "NIVEA SHOWER",
              "NIVEA SHOWER/BATH": "NIVEA SHOWER/BATH",
              "NIVEA SKIN-SMOOTHING COMPLEX": "NIVEA SKIN-SMOOTHING COMPLEX"
            },
            "C S  DENT": {
              "GRANDPAS PINE TAR BATH-SHOWER": "GRANDPAS PINE TAR BATH-SHOWER"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS BEAUTY 360 SHOWER BATH OIL": "CVS BEAUTY 360 SHOWER BATH OIL"
            },
            "GERITREX CORPORATION": {
              "BODI WHIRL OIL": "BODI WHIRL OIL",
              "GERISILK BATH OIL": "GERISILK BATH OIL"
            },
            "J & J CONSUMER PRODUCTS": {
              "AVEENO CALMING BODY WASH": "AVEENO CALMING BODY WASH",
              "AVEENO HYDRATING BODY WASH": "AVEENO HYDRATING BODY WASH",
              "AVEENO MOISTURIZING BODY WASH": "AVEENO MOISTURIZING BODY WASH",
              "AVEENO POSITIVELY RADIANT WASH": "AVEENO POSITIVELY RADIANT WASH",
              "AVEENO POSITIVELY SMOOTH": "AVEENO POSITIVELY SMOOTH",
              "AVEENO RESTORATIVE SKIN THERAP": "AVEENO RESTORATIVE SKIN THERAP",
              "AVEENO SKIN RELIEF BODY WASH": "AVEENO SKIN RELIEF BODY WASH",
              "AVEENO SKIN RELIEF SHOWER/BATH": "AVEENO SKIN RELIEF SHOWER/BATH",
              "AVEENO SMOOTHING BODY WASH": "AVEENO SMOOTHING BODY WASH",
              "AVEENO STRESS RELIEF BATH": "AVEENO STRESS RELIEF BATH",
              "AVEENO STRESS RELIEF BODY WASH": "AVEENO STRESS RELIEF BODY WASH",
              "AVEENO THERAPEUTIC": "AVEENO THERAPEUTIC"
            },
            "MAJOR PHARMACEUTICALS": {
              "THERA-DERM BATH": "THERA-DERM BATH"
            },
            "MEDCO LAB": {
              "CAMEO OIL": "CAMEO OIL"
            },
            "MERZ PHARMACEUTICAL": {
              "MAPO BATH": "MAPO BATH"
            },
            "NEUTROGENA": {
              "NEUTROGENA RAINBATH": "NEUTROGENA RAINBATH"
            },
            "PHARMACEUTICAL SPECIALITIES": {
              "ROBATHOL": "ROBATHOL"
            },
            "RITE AID CORPORATION": {
              "RA RENEWAL SHOWER & BATH": "RA RENEWAL SHOWER & BATH",
              "RA RENEWAL SKIN RELIEF WASH": "RA RENEWAL SKIN RELIEF WASH"
            },
            "TEVA PHARMACEUTICALS USA": {
              "THERAPEUTIC BATH": "THERAPEUTIC BATH"
            },
            "TEVA SELECT BRANDS": {
              "ULTRA DERM BATH OIL": "ULTRA DERM BATH OIL"
            },
            "WALGREENS": {
              "COMPLETE ULTRA BODY WASH/SHEA": "COMPLETE ULTRA BODY WASH/SHEA",
              "DEEP MOISTURE BODY WASH": "DEEP MOISTURE BODY WASH",
              "PURIFYING BODY WASH": "PURIFYING BODY WASH",
              "SENSITIVE SKIN BODY WASH": "SENSITIVE SKIN BODY WASH",
              "SOOTHING BODY WASH/OATMEAL": "SOOTHING BODY WASH/OATMEAL"
            }
          }
        },
        "Burn Products": {
          "Burn Product Combinations": {
            "OAKHURST": {
              "UNGUENTINE": "UNGUENTINE",
              "UNGUENTINE MAXIMUM STRENGTH": "UNGUENTINE MAXIMUM STRENGTH"
            }
          },
          "Burn Products": {
            "A-S MEDICATION SOLUTIONS": {
              "SILVADENE": "SILVADENE",
              "SILVER SULFADIAZINE": "SILVER SULFADIAZINE"
            },
            "ACTAVIS PHARMA": {
              "SILVER SULFADIAZINE": "SILVER SULFADIAZINE"
            },
            "AIDAREX PHARMACEUTICALS": {
              "SILVER SULFADIAZINE": "SILVER SULFADIAZINE"
            },
            "ALTURA PHARMACEUTICALS": {
              "SSD": "SSD"
            },
            "ASCEND LABORATORIES": {
              "SILVER SULFADIAZINE": "SILVER SULFADIAZINE"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "SILVER SULFADIAZINE": "SILVER SULFADIAZINE"
            },
            "DIRECT RX": {
              "SILVER SULFADIAZINE": "SILVER SULFADIAZINE",
              "SSD": "SSD"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "SSD": "SSD"
            },
            "FAGRON": {
              "NITROFURAZONE": "NITROFURAZONE"
            },
            "GREENSTONE": {
              "SILVER SULFADIAZINE": "SILVER SULFADIAZINE"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "SILVADENE": "SILVADENE",
              "SILVER SULFADIAZINE": "SILVER SULFADIAZINE"
            },
            "INGENUS PHARMACEUTICALS": {
              "MAFENIDE ACETATE": "MAFENIDE ACETATE"
            },
            "LIBERTY PHARMACEUTICAL": {
              "SILVER SULFADIAZINE": "SILVER SULFADIAZINE"
            },
            "MYLAN INSTITUTIONAL": {
              "SULFAMYLON": "SULFAMYLON"
            },
            "NUCARE PHARMACEUTICALS": {
              "SILVADENE": "SILVADENE",
              "SSD": "SSD"
            },
            "PAR PHARMACEUTICAL": {
              "MAFENIDE ACETATE": "MAFENIDE ACETATE"
            },
            "PCCA": {
              "NITROFURAZONE": "NITROFURAZONE"
            },
            "PFIZER U.S.": {
              "SILVADENE": "SILVADENE"
            },
            "PHARMEDIX": {
              "SILVER SULFADIAZINE": "SILVER SULFADIAZINE"
            },
            "PREFERRED PHARMACEUTICALS": {
              "SILVER SULFADIAZINE": "SILVER SULFADIAZINE"
            },
            "PROFICIENT RX": {
              "SILVER SULFADIAZINE": "SILVER SULFADIAZINE"
            },
            "QUALITY CARE": {
              "SILVADENE": "SILVADENE",
              "SILVER SULFADIAZINE": "SILVER SULFADIAZINE",
              "THERMAZENE": "THERMAZENE"
            },
            "QUALITY CARE PRODUCTS": {
              "SILVER SULFADIAZINE": "SILVER SULFADIAZINE"
            },
            "RISING PHARMACEUTICALS": {
              "SULFAMYLON": "SULFAMYLON"
            },
            "RXCHANGE CO": {
              "SSD": "SSD"
            },
            "UNIT DOSE SERVICES": {
              "SILVER SULFADIAZINE": "SILVER SULFADIAZINE"
            }
          }
        },
        "Cauterizing Agents": {
          "Cauterizing Agent Combinations": {
            "A-S MEDICATION SOLUTIONS": {
              "ARZOL SILVER NIT APPLICATORS": "ARZOL SILVER NIT APPLICATORS"
            },
            "ARZOL": {
              "ARZOL SILVER NIT APPLICATORS": "ARZOL SILVER NIT APPLICATORS"
            },
            "GRAHAM-FIELD": {
              "GRAFCO SILVER NIT APPLICATOR": "GRAFCO SILVER NIT APPLICATOR"
            }
          },
          "Cauterizing Agents": {
            "FAGRON": {
              "SILVER NITRATE": "SILVER NITRATE"
            },
            "GORDON": {
              "SILVER NITRATE": "SILVER NITRATE",
              "TRI-CHLOR": "TRI-CHLOR"
            },
            "LETCO MEDICAL": {
              "SILVER NITRATE": "SILVER NITRATE"
            },
            "PCCA": {
              "CHLOROACETIC ACID": "CHLOROACETIC ACID",
              "SILVER NITRATE": "SILVER NITRATE"
            },
            "SPECTRUM": {
              "SILVER NITRATE": "SILVER NITRATE"
            },
            "TEVA PHARMACEUTICALS USA": {
              "SILVER NITRATE": "SILVER NITRATE"
            }
          }
        },
        "Corticosteroids - Topical": {
          "Corticosteroids - Topical": {
            "A-S MEDICATION SOLUTIONS": {
              "BETAMETHASONE DIPROPIONATE": "BETAMETHASONE DIPROPIONATE",
              "BETAMETHASONE DIPROPIONATE AUG": "BETAMETHASONE DIPROPIONATE AUG",
              "BETAMETHASONE VALERATE": "BETAMETHASONE VALERATE",
              "CLOBETASOL PROPIONATE": "CLOBETASOL PROPIONATE",
              "DESONIDE": "DESONIDE",
              "DESOXIMETASONE": "DESOXIMETASONE",
              "ELOCON": "ELOCON",
              "FLUOCINOLONE ACETONIDE": "FLUOCINOLONE ACETONIDE",
              "FLUOCINONIDE": "FLUOCINONIDE",
              "FLUTICASONE PROPIONATE": "FLUTICASONE PROPIONATE",
              "HALOBETASOL PROPIONATE": "HALOBETASOL PROPIONATE",
              "HYDROCORTISONE": "HYDROCORTISONE",
              "HYDROCORTISONE VALERATE": "HYDROCORTISONE VALERATE",
              "MOMETASONE FUROATE": "MOMETASONE FUROATE",
              "TRIAMCINOLONE ACETONIDE": "TRIAMCINOLONE ACETONIDE"
            },
            "ACTAVIS MID ATLANTIC": {
              "BETAMETHASONE DIPROPIONATE": "BETAMETHASONE DIPROPIONATE",
              "BETAMETHASONE DIPROPIONATE AUG": "BETAMETHASONE DIPROPIONATE AUG",
              "BETAMETHASONE VALERATE": "BETAMETHASONE VALERATE",
              "CLOBETASOL PROPIONATE": "CLOBETASOL PROPIONATE",
              "DESONIDE": "DESONIDE",
              "DESOXIMETASONE": "DESOXIMETASONE",
              "FLUOCINONIDE": "FLUOCINONIDE",
              "HYDROCORTISONE": "HYDROCORTISONE"
            },
            "ACTAVIS PHARMA": {
              "CLOBETASOL PROPIONATE": "CLOBETASOL PROPIONATE",
              "CORDRAN": "CORDRAN",
              "FLUOCINOLONE ACETONIDE": "FLUOCINOLONE ACETONIDE"
            },
            "AIDAREX PHARMACEUTICALS": {
              "BETAMETHASONE DIPROPIONATE": "BETAMETHASONE DIPROPIONATE",
              "BETAMETHASONE DIPROPIONATE AUG": "BETAMETHASONE DIPROPIONATE AUG",
              "BETAMETHASONE VALERATE": "BETAMETHASONE VALERATE",
              "CLOBETASOL PROPIONATE": "CLOBETASOL PROPIONATE",
              "DESONIDE": "DESONIDE",
              "FLUOCINOLONE ACETONIDE": "FLUOCINOLONE ACETONIDE",
              "FLUOCINONIDE": "FLUOCINONIDE",
              "FLUTICASONE PROPIONATE": "FLUTICASONE PROPIONATE",
              "HALOBETASOL PROPIONATE": "HALOBETASOL PROPIONATE",
              "HYDROCORTISONE": "HYDROCORTISONE",
              "HYDROCORTISONE VALERATE": "HYDROCORTISONE VALERATE",
              "MOMETASONE FUROATE": "MOMETASONE FUROATE",
              "TRIAMCINOLONE ACETONIDE": "TRIAMCINOLONE ACETONIDE"
            },
            "AKORN": {
              "BETAMETHASONE DIPROPIONATE": "BETAMETHASONE DIPROPIONATE",
              "BETAMETHASONE DIPROPIONATE AUG": "BETAMETHASONE DIPROPIONATE AUG",
              "DESONIDE": "DESONIDE",
              "DESOXIMETASONE": "DESOXIMETASONE",
              "TRIAMCINOLONE ACETONIDE": "TRIAMCINOLONE ACETONIDE"
            },
            "AKRON PHARMA": {
              "HYDROCORTISONE": "HYDROCORTISONE"
            },
            "ALEMBIC PHARMACEUTICALS": {
              "CLOBETASOL PROPIONATE": "CLOBETASOL PROPIONATE",
              "DESONIDE": "DESONIDE"
            },
            "ALMIRALL": {
              "CORDRAN": "CORDRAN",
              "VERDESO": "VERDESO"
            },
            "ALTAIRE": {
              "HYDROCORTISONE": "HYDROCORTISONE",
              "INSTACORT 5": "INSTACORT 5"
            },
            "ALTURA PHARMACEUTICALS": {
              "HYDROCORTISONE": "HYDROCORTISONE",
              "TRIAMCINOLONE ACETONIDE": "TRIAMCINOLONE ACETONIDE"
            },
            "ALVOGEN": {
              "FLUOCINONIDE": "FLUOCINONIDE",
              "FLUOCINONIDE EMULSIFIED BASE": "FLUOCINONIDE EMULSIFIED BASE"
            },
            "AMERICAN PHARMA INGREDIENTS": {
              "CLOBETASOL 17 PROPIONATE": "CLOBETASOL 17 PROPIONATE"
            },
            "AMERICAN SALES COMPANY": {
              "HYDROCORTISONE MAX ST": "HYDROCORTISONE MAX ST"
            },
            "AMNEAL PHARMACEUTICALS": {
              "CLOBETASOL PROPIONATE": "CLOBETASOL PROPIONATE",
              "FLUOCINOLONE ACETONIDE BODY": "FLUOCINOLONE ACETONIDE BODY",
              "FLUOCINOLONE ACETONIDE SCALP": "FLUOCINOLONE ACETONIDE SCALP",
              "FLUOCINONIDE": "FLUOCINONIDE",
              "FLUOCINONIDE EMULSIFIED BASE": "FLUOCINONIDE EMULSIFIED BASE"
            },
            "APOTHECA SUPPLY": {
              "HYDROCORTISONE MICRONIZED": "HYDROCORTISONE MICRONIZED"
            },
            "ARBOR PHARMACEUTICALS": {
              "PEDIADERM HC": "PEDIADERM HC",
              "PEDIADERM TA": "PEDIADERM TA"
            },
            "ARTESA LABS": {
              "NOLIX": "NOLIX"
            },
            "ARU PHARMA": {
              "HYDROCORTISONE": "HYDROCORTISONE"
            },
            "ASAFI PHARMACEUTICAL": {
              "HYDROCORTISONE": "HYDROCORTISONE"
            },
            "ASCEND LABORATORIES": {
              "TRIAMCINOLONE ACETONIDE": "TRIAMCINOLONE ACETONIDE"
            },
            "AVIDAS PHARMACEUTICALS": {
              "SCALACORT": "SCALACORT"
            },
            "AVKARE": {
              "CLOBETASOL PROPIONATE": "CLOBETASOL PROPIONATE",
              "FLUOCINOLONE ACETONIDE BODY": "FLUOCINOLONE ACETONIDE BODY",
              "FLUOCINOLONE ACETONIDE SCALP": "FLUOCINOLONE ACETONIDE SCALP"
            },
            "AYURAX": {
              "HYDROCORTISONE IN ABSORBASE": "HYDROCORTISONE IN ABSORBASE"
            },
            "AZURITY PHARMACEUTICALS": {
              "FIRST-HYDROCORTISONE": "FIRST-HYDROCORTISONE"
            },
            "B & B PHARMACEUTICALS": {
              "TRIAMCINOLONE ACETONIDE": "TRIAMCINOLONE ACETONIDE"
            },
            "BAUSCH HEALTH": {
              "BRYHALI": "BRYHALI",
              "CORMAX SCALP APPLICATION": "CORMAX SCALP APPLICATION",
              "CORTAID MAXIMUM STRENGTH": "CORTAID MAXIMUM STRENGTH",
              "DERMATOP": "DERMATOP",
              "LOCOID": "LOCOID",
              "LOCOID LIPOCREAM": "LOCOID LIPOCREAM",
              "VANOS": "VANOS"
            },
            "BERGEN BRUNSWIG": {
              "GNP HYDRO-LOTION": "GNP HYDRO-LOTION",
              "GNP HYDROCORTISONE": "GNP HYDROCORTISONE",
              "GNP HYDROCORTISONE MAX ST": "GNP HYDROCORTISONE MAX ST",
              "GNP HYDROCORTISONE PLUS": "GNP HYDROCORTISONE PLUS"
            },
            "BETA DERMS": {
              "BETA HC": "BETA HC"
            },
            "BPI LABS LLC": {
              "ADVANCED ALLERGY COLLECTION": "ADVANCED ALLERGY COLLECTION"
            },
            "CHAIN DRUG CONSORTIUM": {
              "HYDROCORTISONE": "HYDROCORTISONE",
              "HYDROCORTISONE INTENSIVE HEAL": "HYDROCORTISONE INTENSIVE HEAL",
              "HYDROCORTISONE MAX ST": "HYDROCORTISONE MAX ST",
              "HYDROCORTISONE PLUS": "HYDROCORTISONE PLUS"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC HYDROCORTISONE": "QC HYDROCORTISONE",
              "QC HYDROCORTISONE MAX ST": "QC HYDROCORTISONE MAX ST"
            },
            "CHATTEM INC": {
              "CORTIZONE-10": "CORTIZONE-10",
              "CORTIZONE-10 DIABETICS SKIN": "CORTIZONE-10 DIABETICS SKIN",
              "CORTIZONE-10 ECZEMA": "CORTIZONE-10 ECZEMA",
              "CORTIZONE-10 HYDRATENSIVE": "CORTIZONE-10 HYDRATENSIVE"
            },
            "CINTEX": {
              "DESONIDE": "DESONIDE",
              "FLURANDRENOLIDE": "FLURANDRENOLIDE"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "BETAMETHASONE DIPROPIONATE AUG": "BETAMETHASONE DIPROPIONATE AUG",
              "BETAMETHASONE VALERATE": "BETAMETHASONE VALERATE",
              "CLOBETASOL PROPIONATE": "CLOBETASOL PROPIONATE",
              "CLOBETASOL PROPIONATE E": "CLOBETASOL PROPIONATE E",
              "CLOBEX SPRAY": "CLOBEX SPRAY",
              "CLODERM": "CLODERM",
              "DESOXIMETASONE": "DESOXIMETASONE",
              "FLUOCINOLONE ACETONIDE": "FLUOCINOLONE ACETONIDE",
              "HYDROCORTISONE": "HYDROCORTISONE",
              "LOCOID LIPOCREAM": "LOCOID LIPOCREAM",
              "TRIAMCINOLONE ACETONIDE": "TRIAMCINOLONE ACETONIDE",
              "VANOS": "VANOS"
            },
            "CMP PHARMA": {
              "HYDROCORTISONE": "HYDROCORTISONE"
            },
            "COMBE": {
              "GYNECORT 10": "GYNECORT 10",
              "LANACORT 10": "LANACORT 10",
              "SCALPICIN MAXIMUM STRENGTH": "SCALPICIN MAXIMUM STRENGTH",
              "VAGISIL": "VAGISIL"
            },
            "COSETTE PHARMACEUTICALS": {
              "BETAMETHASONE DIPROPIONATE": "BETAMETHASONE DIPROPIONATE",
              "BETAMETHASONE VALERATE": "BETAMETHASONE VALERATE",
              "CLOBETASOL PROPIONATE": "CLOBETASOL PROPIONATE",
              "DESONIDE": "DESONIDE",
              "FLUOCINOLONE ACETONIDE": "FLUOCINOLONE ACETONIDE",
              "FLUOCINONIDE": "FLUOCINONIDE",
              "FLUOCINONIDE EMULSIFIED BASE": "FLUOCINONIDE EMULSIFIED BASE",
              "FLUTICASONE PROPIONATE": "FLUTICASONE PROPIONATE",
              "HALOBETASOL PROPIONATE": "HALOBETASOL PROPIONATE",
              "HYDROCORTISONE VALERATE": "HYDROCORTISONE VALERATE",
              "MOMETASONE FUROATE": "MOMETASONE FUROATE",
              "TRIAMCINOLONE ACETONIDE": "TRIAMCINOLONE ACETONIDE"
            },
            "CROWN LABORATORIES": {
              "ALA-CORT": "ALA-CORT",
              "DERMASORB HC": "DERMASORB HC",
              "DERMASORB TA": "DERMASORB TA",
              "HYDROCORTISONE": "HYDROCORTISONE",
              "TRIDERM": "TRIDERM"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS ANTI-ITCH MAXIMUM STRENGTH": "CVS ANTI-ITCH MAXIMUM STRENGTH",
              "CVS CORTISONE COOLING RELIEF": "CVS CORTISONE COOLING RELIEF",
              "CVS CORTISONE INTENSE HEALING": "CVS CORTISONE INTENSE HEALING",
              "CVS CORTISONE LONG-LASTING": "CVS CORTISONE LONG-LASTING",
              "CVS CORTISONE MAXIMUM STRENGTH": "CVS CORTISONE MAXIMUM STRENGTH",
              "CVS ECZEMA ANTI-ITCH": "CVS ECZEMA ANTI-ITCH",
              "CVS HYDROCORTISONE ACETATE": "CVS HYDROCORTISONE ACETATE",
              "CVS HYDROCORTISONE ANTI-ITCH": "CVS HYDROCORTISONE ANTI-ITCH",
              "CVS HYDROCORTISONE MAX ST": "CVS HYDROCORTISONE MAX ST"
            },
            "DERM VENTURES": {
              "ALA SCALP": "ALA SCALP"
            },
            "DERMALOGIX PARTNERS": {
              "CLOBETASOL 17 PROPIONATE": "CLOBETASOL 17 PROPIONATE",
              "CLOBETASOL PROPIONATE": "CLOBETASOL PROPIONATE"
            },
            "DIRECT RX": {
              "FLUTICASONE PROPIONATE": "FLUTICASONE PROPIONATE",
              "HYDROCORTISONE": "HYDROCORTISONE",
              "HYDROCORTISONE VALERATE": "HYDROCORTISONE VALERATE",
              "TRIAMCINOLONE ACETONIDE": "TRIAMCINOLONE ACETONIDE"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "CLOCORTOLONE PIVALATE": "CLOCORTOLONE PIVALATE",
              "CLOCORTOLONE PIVALATE PUMP": "CLOCORTOLONE PIVALATE PUMP"
            },
            "ENCORE DERMATOLOGY": {
              "IMPOYZ": "IMPOYZ",
              "SERNIVO": "SERNIVO",
              "TRIANEX": "TRIANEX",
              "TRIDESILON": "TRIDESILON"
            },
            "ENCORE SCIENTIFIC": {
              "HYDROCORTISONE MICRONIZED": "HYDROCORTISONE MICRONIZED"
            },
            "ENCUBE ETHICALS": {
              "CLOBETASOL PROPIONATE": "CLOBETASOL PROPIONATE",
              "DESONIDE": "DESONIDE",
              "FLUOCINOLONE ACETONIDE": "FLUOCINOLONE ACETONIDE",
              "TRIAMCINOLONE ACETONIDE": "TRIAMCINOLONE ACETONIDE"
            },
            "EPI HEALTH": {
              "CLODERM": "CLODERM",
              "CLODERM PUMP": "CLODERM PUMP"
            },
            "EQUALINE": {
              "EQL ANTI-ITCH INTENSIVE HEAL": "EQL ANTI-ITCH INTENSIVE HEAL",
              "EQL ANTI-ITCH MAXIMUM STRENGTH": "EQL ANTI-ITCH MAXIMUM STRENGTH",
              "EQL SCALP RELIEF MAX STRENGTH": "EQL SCALP RELIEF MAX STRENGTH"
            },
            "FAGRON": {
              "BETAMETHASONE DIPROPIONATE": "BETAMETHASONE DIPROPIONATE",
              "BETAMETHASONE VALERATE": "BETAMETHASONE VALERATE",
              "CLOBETASOL PROPIONATE": "CLOBETASOL PROPIONATE",
              "FLUOCINOLONE ACETONIDE": "FLUOCINOLONE ACETONIDE",
              "FLUOCINONIDE": "FLUOCINONIDE",
              "HYDROCORTISONE ACETATE": "HYDROCORTISONE ACETATE",
              "HYDROCORTISONE MICRONIZED": "HYDROCORTISONE MICRONIZED",
              "TRIAMCINOLONE ACETONIDE": "TRIAMCINOLONE ACETONIDE"
            },
            "FOUGERA": {
              "ALCLOMETASONE DIPROPIONATE": "ALCLOMETASONE DIPROPIONATE",
              "AMCINONIDE": "AMCINONIDE",
              "BETAMETHASONE DIPROPIONATE AUG": "BETAMETHASONE DIPROPIONATE AUG",
              "CLOBETASOL PROPIONATE": "CLOBETASOL PROPIONATE",
              "CLOBETASOL PROPIONATE E": "CLOBETASOL PROPIONATE E",
              "DESONIDE": "DESONIDE",
              "DESOXIMETASONE": "DESOXIMETASONE",
              "FLUOCINOLONE ACETONIDE": "FLUOCINOLONE ACETONIDE",
              "FLUOCINONIDE": "FLUOCINONIDE",
              "FLUTICASONE PROPIONATE": "FLUTICASONE PROPIONATE",
              "HALOBETASOL PROPIONATE": "HALOBETASOL PROPIONATE",
              "HYDROCORTISONE": "HYDROCORTISONE",
              "MOMETASONE FUROATE": "MOMETASONE FUROATE",
              "PREDNICARBATE": "PREDNICARBATE",
              "TRIAMCINOLONE ACETONIDE": "TRIAMCINOLONE ACETONIDE"
            },
            "FREEDOM PHARMACEUTICALS": {
              "BETAMETHASONE VALERATE": "BETAMETHASONE VALERATE",
              "HYDROCORTISONE ACETATE": "HYDROCORTISONE ACETATE",
              "HYDROCORTISONE MICRONIZED": "HYDROCORTISONE MICRONIZED",
              "TRIAMCINOLONE ACETONIDE": "TRIAMCINOLONE ACETONIDE"
            },
            "GALDERMA": {
              "CAPEX": "CAPEX",
              "CLOBEX": "CLOBEX",
              "CLOBEX SPRAY": "CLOBEX SPRAY",
              "DESOWEN": "DESOWEN"
            },
            "GERITREX CORPORATION": {
              "HYDROCORTISONE": "HYDROCORTISONE"
            },
            "GLASSHOUSE PHARMACEUTICALS": {
              "FLUOCINOLONE ACETONIDE": "FLUOCINOLONE ACETONIDE",
              "FLUOCINONIDE": "FLUOCINONIDE"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "KERICORT 10": "KERICORT 10",
              "PREPARATION H": "PREPARATION H"
            },
            "GLAXO SMITH KLINE": {
              "SARNOL-HC": "SARNOL-HC"
            },
            "GLENMARK PHARMACEUTICALS": {
              "ALCLOMETASONE DIPROPIONATE": "ALCLOMETASONE DIPROPIONATE",
              "BETAMETHASONE DIPROPIONATE AUG": "BETAMETHASONE DIPROPIONATE AUG",
              "CLOBETASOL PROPIONATE": "CLOBETASOL PROPIONATE",
              "CLOBETASOL PROPIONATE EMULSION": "CLOBETASOL PROPIONATE EMULSION",
              "DESONIDE": "DESONIDE",
              "DESOXIMETASONE": "DESOXIMETASONE",
              "FLUOCINOLONE ACETONIDE BODY": "FLUOCINOLONE ACETONIDE BODY",
              "FLUOCINOLONE ACETONIDE SCALP": "FLUOCINOLONE ACETONIDE SCALP",
              "FLUOCINONIDE": "FLUOCINONIDE",
              "FLUTICASONE PROPIONATE": "FLUTICASONE PROPIONATE",
              "HYDROCORTISONE BUTYR LIPO BASE": "HYDROCORTISONE BUTYR LIPO BASE",
              "HYDROCORTISONE VALERATE": "HYDROCORTISONE VALERATE",
              "MOMETASONE FUROATE": "MOMETASONE FUROATE",
              "TRIAMCINOLONE ACETONIDE": "TRIAMCINOLONE ACETONIDE"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "BETAMETHASONE DIPROPIONATE": "BETAMETHASONE DIPROPIONATE",
              "CLOBETASOL PROPIONATE": "CLOBETASOL PROPIONATE",
              "FLUOCINONIDE": "FLUOCINONIDE"
            },
            "H.J. HARKINS COMPANY": {
              "FLUTICASONE PROPIONATE": "FLUTICASONE PROPIONATE"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "BETAMETHASONE DIPROPIONATE": "BETAMETHASONE DIPROPIONATE",
              "BETAMETHASONE DIPROPIONATE AUG": "BETAMETHASONE DIPROPIONATE AUG",
              "BETAMETHASONE VALERATE": "BETAMETHASONE VALERATE",
              "CLOBETASOL PROPIONATE": "CLOBETASOL PROPIONATE",
              "DESOXIMETASONE": "DESOXIMETASONE",
              "ELOCON": "ELOCON",
              "FLUOCINOLONE ACETONIDE": "FLUOCINOLONE ACETONIDE",
              "FLUOCINONIDE": "FLUOCINONIDE",
              "FLUOCINONIDE-E": "FLUOCINONIDE-E",
              "HALOBETASOL PROPIONATE": "HALOBETASOL PROPIONATE",
              "HYDROCORTISONE": "HYDROCORTISONE",
              "HYDROCORTISONE BUTYRATE": "HYDROCORTISONE BUTYRATE",
              "MOMETASONE FUROATE": "MOMETASONE FUROATE",
              "TRIAMCINOLONE ACETONIDE": "TRIAMCINOLONE ACETONIDE"
            },
            "HANNAFORD BROTHERS": {
              "HYDROCORTISONE ACETATE": "HYDROCORTISONE ACETATE"
            },
            "HARRIS PHARMACEUTICAL": {
              "MOMETASONE FUROATE": "MOMETASONE FUROATE"
            },
            "HI-TECH": {
              "CLOBETASOL PROPIONATE": "CLOBETASOL PROPIONATE",
              "CLOBETASOL PROPIONATE E": "CLOBETASOL PROPIONATE E"
            },
            "HUMCO": {
              "HYDROCORTISONE MICRONIZED": "HYDROCORTISONE MICRONIZED"
            },
            "IMPAX GENERICS": {
              "BETAMETHASONE DIPROPIONATE AUG": "BETAMETHASONE DIPROPIONATE AUG",
              "DESONIDE": "DESONIDE",
              "FLUTICASONE PROPIONATE": "FLUTICASONE PROPIONATE",
              "MOMETASONE FUROATE": "MOMETASONE FUROATE"
            },
            "INA PHARMACEUTICS": {
              "TRIAMCINOLONE ACETONIDE": "TRIAMCINOLONE ACETONIDE"
            },
            "INGENUS PHARMACEUTICALS": {
              "BETAMETHASONE VALERATE": "BETAMETHASONE VALERATE",
              "CLOBETASOL PROP EMOLLIENT BASE": "CLOBETASOL PROP EMOLLIENT BASE",
              "CLOBETASOL PROPIONATE": "CLOBETASOL PROPIONATE"
            },
            "INNOVIDA PHARMACEUTIQUE": {
              "DIFLORASONE DIACETATE": "DIFLORASONE DIACETATE",
              "TRIAMCINOLONE ACETONIDE": "TRIAMCINOLONE ACETONIDE"
            },
            "INSIGHT PHARMACEUTICALS": {
              "DERMAREST ECZEMA": "DERMAREST ECZEMA",
              "MONISTAT SOOTHING CARE ITCH": "MONISTAT SOOTHING CARE ITCH"
            },
            "IPG PHARMACEUTICALS": {
              "CLOBETASOL PROPIONATE": "CLOBETASOL PROPIONATE",
              "FLUOCINONIDE": "FLUOCINONIDE"
            },
            "J & J CONSUMER PRODUCTS": {
              "AVEENO ANTI-ITCH MAX ST": "AVEENO ANTI-ITCH MAX ST",
              "NEOSPORIN ECZEMA ESSENT MAX ST": "NEOSPORIN ECZEMA ESSENT MAX ST"
            },
            "KAISER FOUNDATION HOSPITAL": {
              "KP HYDROCORTISONE": "KP HYDROCORTISONE",
              "KP HYDROCORTISONE MAX ST": "KP HYDROCORTISONE MAX ST"
            },
            "KALCHEM INTERNATIONAL": {
              "HYDROCORTISONE MICRONIZED": "HYDROCORTISONE MICRONIZED"
            },
            "LAKE CONSUMER PRODUCTS": {
              "MG217 PSORIASIS ANIT-ITCH": "MG217 PSORIASIS ANIT-ITCH"
            },
            "LEADER BRAND PRODUCTS": {
              "HYDROCORTISONE": "HYDROCORTISONE",
              "HYDROCORTISONE ACETATE": "HYDROCORTISONE ACETATE"
            },
            "LEO PHARMA INC": {
              "DESONATE": "DESONATE"
            },
            "LETCO MEDICAL": {
              "BETAMETHASONE DIPROPIONATE": "BETAMETHASONE DIPROPIONATE",
              "BETAMETHASONE VALERATE": "BETAMETHASONE VALERATE",
              "CLOBETASOL PROPIONATE": "CLOBETASOL PROPIONATE",
              "FLUOCINONIDE": "FLUOCINONIDE",
              "HYDROCORTISONE": "HYDROCORTISONE",
              "HYDROCORTISONE ACETATE": "HYDROCORTISONE ACETATE",
              "TRIAMCINOLONE ACETONIDE": "TRIAMCINOLONE ACETONIDE"
            },
            "LIBERTY PHARMACEUTICAL": {
              "TRIAMCINOLONE ACETONIDE": "TRIAMCINOLONE ACETONIDE"
            },
            "LUPIN PHARMACEUTICALS": {
              "BETAMETHASONE DIPROPIONATE AUG": "BETAMETHASONE DIPROPIONATE AUG",
              "CLOBETASOL PROPIONATE": "CLOBETASOL PROPIONATE",
              "DESOXIMETASONE": "DESOXIMETASONE",
              "FLUOCINOLONE ACETONIDE": "FLUOCINOLONE ACETONIDE",
              "FLUOCINONIDE": "FLUOCINONIDE",
              "HYDROCORTISONE BUTYRATE": "HYDROCORTISONE BUTYRATE",
              "HYDROCORTISONE VALERATE": "HYDROCORTISONE VALERATE"
            },
            "MACLEODS PHARMACEUTICALS": {
              "CLOBETASOL PROPIONATE": "CLOBETASOL PROPIONATE",
              "FLUOCINONIDE": "FLUOCINONIDE",
              "TRIAMCINOLONE ACETONIDE": "TRIAMCINOLONE ACETONIDE"
            },
            "MAJOR PHARMACEUTICALS": {
              "BETAMETHASONE VALERATE": "BETAMETHASONE VALERATE",
              "HYDROCORTISONE": "HYDROCORTISONE"
            },
            "MARLEX PHARMACEUTICALS": {
              "FLUOCINOLONE ACETONIDE": "FLUOCINOLONE ACETONIDE"
            },
            "MARNEL PHARMACEUTICAL": {
              "ALA SCALP": "ALA SCALP",
              "NUCORT": "NUCORT",
              "TRIDERM": "TRIDERM"
            },
            "MAYNE PHARMA": {
              "FLUOCINONIDE": "FLUOCINONIDE",
              "FLURANDRENOLIDE": "FLURANDRENOLIDE",
              "HALOBETASOL PROPIONATE": "HALOBETASOL PROPIONATE",
              "HYDROCORTISONE BUTYRATE": "HYDROCORTISONE BUTYRATE",
              "LEXETTE": "LEXETTE",
              "TRIAMCINOLONE ACETONIDE": "TRIAMCINOLONE ACETONIDE"
            },
            "MCKESSON SUNMARK": {
              "SM HYDROCORTISONE": "SM HYDROCORTISONE",
              "SM HYDROCORTISONE MAX ST": "SM HYDROCORTISONE MAX ST"
            },
            "MED-DERM": {
              "MED-DERM HYDROCORTISONE": "MED-DERM HYDROCORTISONE",
              "REDERM": "REDERM"
            },
            "MEDIMETRIKS PHARMACEUTICALS": {
              "BESER": "BESER",
              "CLODAN": "CLODAN",
              "SYNALAR": "SYNALAR",
              "TOVET": "TOVET"
            },
            "MEDIQUE PRODUCTS": {
              "MEDI-FIRST HYDROCORTISONE": "MEDI-FIRST HYDROCORTISONE"
            },
            "MEDISCA": {
              "BETAMETHASONE DIPROPIONATE": "BETAMETHASONE DIPROPIONATE",
              "BETAMETHASONE VALERATE": "BETAMETHASONE VALERATE",
              "CLOBETASOL 17 PROPIONATE": "CLOBETASOL 17 PROPIONATE",
              "DESONIDE": "DESONIDE",
              "FLUOCINOLONE ACETONIDE": "FLUOCINOLONE ACETONIDE",
              "HYDROCORTISONE": "HYDROCORTISONE",
              "HYDROCORTISONE ACETATE": "HYDROCORTISONE ACETATE",
              "TRIAMCINOLONE ACETONIDE": "TRIAMCINOLONE ACETONIDE"
            },
            "MEDLINE/DERMAL MANAGEMENT": {
              "CURAD HYDROCORTISONE": "CURAD HYDROCORTISONE"
            },
            "MEDVANTX": {
              "TRIAMCINOLONE ACETONIDE": "TRIAMCINOLONE ACETONIDE"
            },
            "MEIJER": {
              "MEIJER HYDROCORTISONE": "MEIJER HYDROCORTISONE"
            },
            "MERCK SHARP & DOHME": {
              "DIPROLENE": "DIPROLENE",
              "DIPROLENE AF": "DIPROLENE AF",
              "ELOCON": "ELOCON"
            },
            "MERICON": {
              "HYDROCORTISONE": "HYDROCORTISONE"
            },
            "MICRO LABORATORIES": {
              "CLOBETASOL PROPIONATE": "CLOBETASOL PROPIONATE"
            },
            "MISSION": {
              "TEXACORT": "TEXACORT"
            },
            "MORTON GROVE PHARMACEUTICALS": {
              "CLOBETASOL PROPIONATE": "CLOBETASOL PROPIONATE",
              "TRIAMCINOLONE ACETONIDE": "TRIAMCINOLONE ACETONIDE"
            },
            "MYLAN": {
              "BETAMETHASONE VALERATE": "BETAMETHASONE VALERATE",
              "CLOBETASOL PROPIONATE": "CLOBETASOL PROPIONATE",
              "CLOBETASOL PROPIONATE EMULSION": "CLOBETASOL PROPIONATE EMULSION",
              "HALCINONIDE": "HALCINONIDE",
              "LUXIQ": "LUXIQ",
              "OLUX": "OLUX",
              "OLUX-E": "OLUX-E",
              "TRIAMCINOLONE ACETONIDE": "TRIAMCINOLONE ACETONIDE"
            },
            "MYLAN SPECIALTY L.P.": {
              "IMPEKLO": "IMPEKLO"
            },
            "NATUREPLEX": {
              "HYDROCORTISONE": "HYDROCORTISONE"
            },
            "NEW WORLD IMPORTS": {
              "HYDROCORTISONE ANTI-ITCH": "HYDROCORTISONE ANTI-ITCH"
            },
            "NORTHSTAR RX": {
              "BETAMETHASONE DIPROPIONATE": "BETAMETHASONE DIPROPIONATE",
              "CLOBETASOL PROPIONATE": "CLOBETASOL PROPIONATE",
              "DESONIDE": "DESONIDE",
              "FLUOCINONIDE": "FLUOCINONIDE",
              "MOMETASONE FUROATE": "MOMETASONE FUROATE"
            },
            "NUCARE PHARMACEUTICALS": {
              "BETAMETHASONE DIPROPIONATE": "BETAMETHASONE DIPROPIONATE",
              "BETAMETHASONE VALERATE": "BETAMETHASONE VALERATE",
              "FLUOCINONIDE": "FLUOCINONIDE",
              "HYDROCORTISONE": "HYDROCORTISONE",
              "MOMETASONE FUROATE": "MOMETASONE FUROATE",
              "TRIAMCINOLONE ACETONIDE": "TRIAMCINOLONE ACETONIDE"
            },
            "OCEANSIDE PHARMACEUTICALS": {
              "FLUOCINONIDE": "FLUOCINONIDE",
              "HYDROCORTISONE BUTYR LIPO BASE": "HYDROCORTISONE BUTYR LIPO BASE",
              "HYDROCORTISONE BUTYRATE": "HYDROCORTISONE BUTYRATE",
              "PREDNICARBATE": "PREDNICARBATE"
            },
            "ONTOS": {
              "NOBLE FORMULA HC": "NOBLE FORMULA HC"
            },
            "OWEN LABORATORIES": {
              "DESONIDE": "DESONIDE"
            },
            "PAR PHARMACEUTICAL": {
              "HYDROCORTISONE": "HYDROCORTISONE"
            },
            "PAR PHARMACEUTICALS": {
              "TRIAMCINOLONE ACETONIDE": "TRIAMCINOLONE ACETONIDE"
            },
            "PCCA": {
              "BETAMETHASONE DIPROPIONATE": "BETAMETHASONE DIPROPIONATE",
              "BETAMETHASONE VALERATE": "BETAMETHASONE VALERATE",
              "CLOBETASOL PROPIONATE": "CLOBETASOL PROPIONATE",
              "DESONIDE": "DESONIDE",
              "FLUOCINOLONE ACETONIDE": "FLUOCINOLONE ACETONIDE",
              "FLUOCINONIDE": "FLUOCINONIDE",
              "HYDROCORTISONE": "HYDROCORTISONE",
              "HYDROCORTISONE ACETATE": "HYDROCORTISONE ACETATE",
              "TRIAMCINOLONE ACETONIDE": "TRIAMCINOLONE ACETONIDE"
            },
            "PERRIGO": {
              "CLOBETASOL PROPIONATE": "CLOBETASOL PROPIONATE",
              "HYDROCORTISONE": "HYDROCORTISONE",
              "HYDROCORTISONE ACETATE": "HYDROCORTISONE ACETATE",
              "HYDROCORTISONE MICRONIZED": "HYDROCORTISONE MICRONIZED"
            },
            "PERRIGO PHARMACEUTICALS": {
              "ANTI-ITCH MAXIMUM STRENGTH": "ANTI-ITCH MAXIMUM STRENGTH",
              "BETAMETHASONE DIPROPIONATE": "BETAMETHASONE DIPROPIONATE",
              "BETAMETHASONE DIPROPIONATE AUG": "BETAMETHASONE DIPROPIONATE AUG",
              "BETAMETHASONE VALERATE": "BETAMETHASONE VALERATE",
              "CLOBETASOL PROPIONATE": "CLOBETASOL PROPIONATE",
              "CLOBETASOL PROPIONATE EMULSION": "CLOBETASOL PROPIONATE EMULSION",
              "DESONIDE": "DESONIDE",
              "DESOXIMETASONE": "DESOXIMETASONE",
              "FLUOCINOLONE ACETONIDE BODY": "FLUOCINOLONE ACETONIDE BODY",
              "FLUOCINOLONE ACETONIDE SCALP": "FLUOCINOLONE ACETONIDE SCALP",
              "FLUOCINONIDE": "FLUOCINONIDE",
              "FLURANDRENOLIDE": "FLURANDRENOLIDE",
              "FLUTICASONE PROPIONATE": "FLUTICASONE PROPIONATE",
              "HALOBETASOL PROPIONATE": "HALOBETASOL PROPIONATE",
              "HYDROCORTISONE": "HYDROCORTISONE",
              "HYDROCORTISONE VALERATE": "HYDROCORTISONE VALERATE",
              "MOMETASONE FUROATE": "MOMETASONE FUROATE",
              "TRIAMCINOLONE ACETONIDE": "TRIAMCINOLONE ACETONIDE"
            },
            "PERSON COVEY": {
              "AQUANIL HC": "AQUANIL HC"
            },
            "PHARMACEUTICA NORTH AMERICA": {
              "FLUOCINONIDE": "FLUOCINONIDE"
            },
            "PHARMACEUTICAL SPECIALITIES": {
              "VANICREAM HC MAXIMUM STRENGTH": "VANICREAM HC MAXIMUM STRENGTH"
            },
            "PHARMADERM": {
              "ACLOVATE": "ACLOVATE",
              "ALPHATREX": "ALPHATREX",
              "CUTIVATE": "CUTIVATE",
              "LOKARA": "LOKARA",
              "TEMOVATE": "TEMOVATE",
              "TEMOVATE E": "TEMOVATE E"
            },
            "PHARMEDIX": {
              "BETAMETHASONE DIPROPIONATE": "BETAMETHASONE DIPROPIONATE",
              "BETAMETHASONE VALERATE": "BETAMETHASONE VALERATE",
              "CLOBETASOL PROPIONATE": "CLOBETASOL PROPIONATE",
              "FLUOCINONIDE": "FLUOCINONIDE",
              "HYDROCORTISONE": "HYDROCORTISONE",
              "HYDROCORTISONE VALERATE": "HYDROCORTISONE VALERATE",
              "MOMETASONE FUROATE": "MOMETASONE FUROATE",
              "TRIAMCINOLONE ACETONIDE": "TRIAMCINOLONE ACETONIDE"
            },
            "PRASCO LABORATORIES": {
              "BETAMETHASONE DIPROPIONATE AUG": "BETAMETHASONE DIPROPIONATE AUG",
              "CLOBETASOL PROPIONATE": "CLOBETASOL PROPIONATE",
              "CLOBETASOL PROPIONATE EMULSION": "CLOBETASOL PROPIONATE EMULSION",
              "CLOCORTOLONE PIVALATE": "CLOCORTOLONE PIVALATE",
              "PREDNICARBATE": "PREDNICARBATE"
            },
            "PREFERRED PHARMACEUTICALS": {
              "BETAMETHASONE DIPROPIONATE": "BETAMETHASONE DIPROPIONATE",
              "BETAMETHASONE DIPROPIONATE AUG": "BETAMETHASONE DIPROPIONATE AUG",
              "BETAMETHASONE VALERATE": "BETAMETHASONE VALERATE",
              "CLOBETASOL PROPIONATE": "CLOBETASOL PROPIONATE",
              "DESOXIMETASONE": "DESOXIMETASONE",
              "FLUOCINONIDE": "FLUOCINONIDE",
              "TRIAMCINOLONE ACETONIDE": "TRIAMCINOLONE ACETONIDE"
            },
            "PRESTIUM PHARMA": {
              "LUXIQ": "LUXIQ",
              "OLUX": "OLUX",
              "OLUX-E": "OLUX-E"
            },
            "PROFICIENT RX": {
              "BETAMETHASONE DIPROPIONATE AUG": "BETAMETHASONE DIPROPIONATE AUG",
              "BETAMETHASONE VALERATE": "BETAMETHASONE VALERATE",
              "CLOBETASOL PROPIONATE": "CLOBETASOL PROPIONATE",
              "DESONIDE": "DESONIDE",
              "DESOXIMETASONE": "DESOXIMETASONE",
              "FLUOCINONIDE": "FLUOCINONIDE",
              "FLUTICASONE PROPIONATE": "FLUTICASONE PROPIONATE",
              "HYDROCORTISONE": "HYDROCORTISONE",
              "MOMETASONE FUROATE": "MOMETASONE FUROATE",
              "TRIAMCINOLONE ACETONIDE": "TRIAMCINOLONE ACETONIDE"
            },
            "PUBLIX SUPER MARKETS INC.": {
              "PX HYDROCREAM": "PX HYDROCREAM"
            },
            "PURE TEK": {
              "TASOPROL": "TASOPROL"
            },
            "QUALITY CARE": {
              "BETAMETHASONE DIPROPIONATE": "BETAMETHASONE DIPROPIONATE",
              "BETAMETHASONE DIPROPIONATE AUG": "BETAMETHASONE DIPROPIONATE AUG",
              "BETAMETHASONE VALERATE": "BETAMETHASONE VALERATE",
              "CLOBETASOL PROPIONATE": "CLOBETASOL PROPIONATE",
              "CLODERM": "CLODERM",
              "CUTIVATE": "CUTIVATE",
              "DESOXIMETASONE": "DESOXIMETASONE",
              "DIPROLENE": "DIPROLENE",
              "FLUOCINONIDE": "FLUOCINONIDE",
              "FLUTICASONE PROPIONATE": "FLUTICASONE PROPIONATE",
              "HALOBETASOL PROPIONATE": "HALOBETASOL PROPIONATE",
              "HALOG": "HALOG",
              "HYDROCORTISONE": "HYDROCORTISONE",
              "HYDROCORTISONE VALERATE": "HYDROCORTISONE VALERATE",
              "LOCOID": "LOCOID",
              "OLUX": "OLUX",
              "OLUX-E": "OLUX-E",
              "TRIAMCINOLONE ACETONIDE": "TRIAMCINOLONE ACETONIDE"
            },
            "QUALITY CARE PRODUCTS": {
              "FLUOCINONIDE": "FLUOCINONIDE",
              "HYDROCORTISONE": "HYDROCORTISONE",
              "TRIAMCINOLONE ACETONIDE": "TRIAMCINOLONE ACETONIDE"
            },
            "RANBAXY LABORATORIES": {
              "HALOG": "HALOG",
              "KENALOG": "KENALOG",
              "ULTRAVATE": "ULTRAVATE",
              "WESTCORT": "WESTCORT"
            },
            "RECKITT BENCKISER": {
              "SCALPICIN MAXIMUM STRENGTH": "SCALPICIN MAXIMUM STRENGTH"
            },
            "REESE PHARMACEUTICAL": {
              "RECORT PLUS": "RECORT PLUS"
            },
            "RISING PHARMACEUTICALS": {
              "CLOBETASOL PROPIONATE": "CLOBETASOL PROPIONATE",
              "DIFLORASONE DIACETATE": "DIFLORASONE DIACETATE",
              "FLUOCINOLONE ACETONIDE BODY": "FLUOCINOLONE ACETONIDE BODY",
              "FLUOCINOLONE ACETONIDE SCALP": "FLUOCINOLONE ACETONIDE SCALP",
              "FLUOCINONIDE": "FLUOCINONIDE",
              "TRIAMCINOLONE ACETONIDE": "TRIAMCINOLONE ACETONIDE"
            },
            "RITE AID CORPORATION": {
              "RA ANTI-ITCH MAXIMUM STRENGTH": "RA ANTI-ITCH MAXIMUM STRENGTH",
              "RA FIRST AID ANTI-ITCH SPRAY": "RA FIRST AID ANTI-ITCH SPRAY",
              "RA HYDROCORTISONE MAX ST": "RA HYDROCORTISONE MAX ST",
              "RA HYDROCORTISONE PLUS 12": "RA HYDROCORTISONE PLUS 12"
            },
            "ROUSES POINT": {
              "HYDROCORTISONE BUTYRATE": "HYDROCORTISONE BUTYRATE"
            },
            "ROYAL PHARMACEUTICALS": {
              "DERMA-SMOOTHE/FS BODY": "DERMA-SMOOTHE/FS BODY",
              "DERMA-SMOOTHE/FS SCALP": "DERMA-SMOOTHE/FS SCALP"
            },
            "RUGBY LABORATORIES": {
              "HYDROSKIN": "HYDROSKIN"
            },
            "RXCHANGE CO": {
              "HYDROCORTISONE": "HYDROCORTISONE",
              "HYDROCORTISONE BUTYRATE": "HYDROCORTISONE BUTYRATE",
              "HYDROCORTISONE VALERATE": "HYDROCORTISONE VALERATE",
              "TRIAMCINOLONE ACETONIDE": "TRIAMCINOLONE ACETONIDE"
            },
            "SANDOZ": {
              "APEXICON E": "APEXICON E",
              "BETAMETHASONE DIPROPIONATE": "BETAMETHASONE DIPROPIONATE",
              "BETAMETHASONE DIPROPIONATE AUG": "BETAMETHASONE DIPROPIONATE AUG",
              "BETAMETHASONE VALERATE": "BETAMETHASONE VALERATE",
              "CLOBETASOL PROPIONATE": "CLOBETASOL PROPIONATE",
              "CUTIVATE": "CUTIVATE",
              "FLUOCINONIDE": "FLUOCINONIDE",
              "FLUTICASONE PROPIONATE": "FLUTICASONE PROPIONATE",
              "PANDEL": "PANDEL",
              "TEMOVATE": "TEMOVATE"
            },
            "SEBELA PHARMACEUTICALS": {
              "MICORT-HC": "MICORT-HC"
            },
            "SELECT BRAND": {
              "SB HYDROCORTISONE": "SB HYDROCORTISONE",
              "SB HYDROCORTISONE MAX ST": "SB HYDROCORTISONE MAX ST",
              "SB HYDROCORTISONE PLUS": "SB HYDROCORTISONE PLUS"
            },
            "SETON PHARMACEUTICALS": {
              "FLUOCINOLONE ACETONIDE BODY": "FLUOCINOLONE ACETONIDE BODY",
              "FLUOCINOLONE ACETONIDE SCALP": "FLUOCINOLONE ACETONIDE SCALP"
            },
            "SKY PACKAGING": {
              "BETAMETHASONE DIPROPIONATE AUG": "BETAMETHASONE DIPROPIONATE AUG",
              "HALOBETASOL PROPIONATE": "HALOBETASOL PROPIONATE"
            },
            "SOLA PHARMACEUTICALS": {
              "CLOBETASOL PROPIONATE": "CLOBETASOL PROPIONATE",
              "DESOXIMETASONE": "DESOXIMETASONE",
              "DIFLORASONE DIACETATE": "DIFLORASONE DIACETATE",
              "FLUOCINONIDE": "FLUOCINONIDE",
              "HALOBETASOL PROPIONATE": "HALOBETASOL PROPIONATE",
              "HYDROCORTISONE ACETATE": "HYDROCORTISONE ACETATE",
              "HYDROCORTISONE BUTYRATE": "HYDROCORTISONE BUTYRATE"
            },
            "SOLUBIOMIX LLC": {
              "HYDROCORTISONE IN ABSORBASE": "HYDROCORTISONE IN ABSORBASE"
            },
            "SOLUTECH PHARMACEUTICALS": {
              "FLUOCINOLONE ACETONIDE": "FLUOCINOLONE ACETONIDE"
            },
            "SPECTRUM": {
              "BETAMETHASONE DIPROPIONATE": "BETAMETHASONE DIPROPIONATE",
              "BETAMETHASONE VALERATE": "BETAMETHASONE VALERATE",
              "CLOBETASOL PROPIONATE": "CLOBETASOL PROPIONATE",
              "FLUOCINOLONE ACETONIDE": "FLUOCINOLONE ACETONIDE",
              "FLUOCINONIDE": "FLUOCINONIDE",
              "HYDROCORTISONE": "HYDROCORTISONE",
              "HYDROCORTISONE ACETATE": "HYDROCORTISONE ACETATE",
              "TRIAMCINOLONE ACETONIDE": "TRIAMCINOLONE ACETONIDE"
            },
            "STI PHARMA": {
              "BETAMETHASONE VALERATE": "BETAMETHASONE VALERATE"
            },
            "STRIDES PHARMA": {
              "TRIAMCINOLONE ACETONIDE": "TRIAMCINOLONE ACETONIDE"
            },
            "TARGET": {
              "TGT ANTI-ITCH MAXIMUM STRENGTH": "TGT ANTI-ITCH MAXIMUM STRENGTH",
              "TGT ANTI-ITCH PLUS OATMEAL": "TGT ANTI-ITCH PLUS OATMEAL",
              "TGT ANTI-ITCH/ALOE/VIT E": "TGT ANTI-ITCH/ALOE/VIT E"
            },
            "TARO": {
              "ALCLOMETASONE DIPROPIONATE": "ALCLOMETASONE DIPROPIONATE",
              "BETAMETHASONE DIPROPIONATE": "BETAMETHASONE DIPROPIONATE",
              "BETAMETHASONE DIPROPIONATE AUG": "BETAMETHASONE DIPROPIONATE AUG",
              "BETAMETHASONE VALERATE": "BETAMETHASONE VALERATE",
              "CLOBETASOL PROPIONATE": "CLOBETASOL PROPIONATE",
              "CLOBETASOL PROPIONATE E": "CLOBETASOL PROPIONATE E",
              "DESONIDE": "DESONIDE",
              "DESOXIMETASONE": "DESOXIMETASONE",
              "DIFLORASONE DIACETATE": "DIFLORASONE DIACETATE",
              "FLUOCINOLONE ACETONIDE": "FLUOCINOLONE ACETONIDE",
              "FLUOCINOLONE ACETONIDE BODY": "FLUOCINOLONE ACETONIDE BODY",
              "FLUOCINOLONE ACETONIDE SCALP": "FLUOCINOLONE ACETONIDE SCALP",
              "FLUOCINONIDE": "FLUOCINONIDE",
              "FLUOCINONIDE EMULSIFIED BASE": "FLUOCINONIDE EMULSIFIED BASE",
              "FLURANDRENOLIDE": "FLURANDRENOLIDE",
              "HALOBETASOL PROPIONATE": "HALOBETASOL PROPIONATE",
              "HYDROCORTISONE": "HYDROCORTISONE",
              "HYDROCORTISONE BUTYRATE": "HYDROCORTISONE BUTYRATE",
              "HYDROCORTISONE MAX ST": "HYDROCORTISONE MAX ST",
              "HYDROCORTISONE MAX ST/12 MOIST": "HYDROCORTISONE MAX ST/12 MOIST",
              "HYDROCORTISONE VALERATE": "HYDROCORTISONE VALERATE",
              "MOMETASONE FUROATE": "MOMETASONE FUROATE",
              "PSORCON": "PSORCON",
              "TOPICORT": "TOPICORT",
              "TOPICORT SPRAY": "TOPICORT SPRAY",
              "TRIAMCINOLONE ACETONIDE": "TRIAMCINOLONE ACETONIDE"
            },
            "TEC LABS": {
              "CORTICOOL": "CORTICOOL"
            },
            "TELIGENT": {
              "BETAMETHASONE DIPROPIONATE AUG": "BETAMETHASONE DIPROPIONATE AUG",
              "CLOBETASOL PROP EMOLLIENT BASE": "CLOBETASOL PROP EMOLLIENT BASE",
              "CLOBETASOL PROPIONATE": "CLOBETASOL PROPIONATE",
              "DESONIDE": "DESONIDE",
              "DESOXIMETASONE": "DESOXIMETASONE",
              "DIFLORASONE DIACETATE": "DIFLORASONE DIACETATE",
              "FLUOCINOLONE ACETONIDE": "FLUOCINOLONE ACETONIDE",
              "FLUOCINONIDE": "FLUOCINONIDE",
              "FLURANDRENOLIDE": "FLURANDRENOLIDE",
              "HALOBETASOL PROPIONATE": "HALOBETASOL PROPIONATE",
              "HYDROCORTISONE BUTYRATE": "HYDROCORTISONE BUTYRATE",
              "TRIAMCINOLONE ACETONIDE": "TRIAMCINOLONE ACETONIDE"
            },
            "TEVA PHARMACEUTICALS USA": {
              "FLUOCINONIDE": "FLUOCINONIDE",
              "FLUOCINONIDE EMULSIFIED BASE": "FLUOCINONIDE EMULSIFIED BASE"
            },
            "TOLMAR": {
              "CLOBETASOL PROPIONATE": "CLOBETASOL PROPIONATE"
            },
            "TOTAL PHARMACY SUPPLY": {
              "TRIAMCINOLONE ACETONIDE": "TRIAMCINOLONE ACETONIDE"
            },
            "TRIFECTA PHARMACEUTICALS": {
              "HYDROCORTISONE": "HYDROCORTISONE"
            },
            "TRIOVA PHARMACEUTICALS": {
              "HYDROCORTISONE": "HYDROCORTISONE"
            },
            "UNIT DOSE SERVICES": {
              "CLOBETASOL PROPIONATE": "CLOBETASOL PROPIONATE",
              "HYDROCORTISONE": "HYDROCORTISONE",
              "TRIAMCINOLONE ACETONIDE": "TRIAMCINOLONE ACETONIDE"
            },
            "VIONA PHARMACEUTICALS": {
              "BETAMETHASONE DIPROPIONATE": "BETAMETHASONE DIPROPIONATE",
              "DESONIDE": "DESONIDE",
              "DESOXIMETASONE": "DESOXIMETASONE",
              "FLUOCINONIDE": "FLUOCINONIDE"
            },
            "WAL-MART": {
              "EQ ANTI-ITCH MAX STRENGTH": "EQ ANTI-ITCH MAX STRENGTH",
              "EQ HYDROCORTISONE": "EQ HYDROCORTISONE",
              "EQ HYDROCORTISONE MAX ST": "EQ HYDROCORTISONE MAX ST",
              "EQ HYDROCORTISONE PLUS": "EQ HYDROCORTISONE PLUS"
            },
            "WALGREENS": {
              "ANTI-ITCH INTENSIVE HEALING": "ANTI-ITCH INTENSIVE HEALING",
              "HYDROCORTISONE": "HYDROCORTISONE",
              "HYDROCORTISONE INTENSIVE HEAL": "HYDROCORTISONE INTENSIVE HEAL",
              "HYDROCORTISONE MAX ST": "HYDROCORTISONE MAX ST",
              "HYDROCORTISONE PLUS": "HYDROCORTISONE PLUS",
              "SCALP RELIEF MAXIMUM STRENGTH": "SCALP RELIEF MAXIMUM STRENGTH"
            },
            "XIROMED": {
              "CLOBETASOL PROPIONATE": "CLOBETASOL PROPIONATE",
              "FLUOCINONIDE": "FLUOCINONIDE"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "BETAMETHASONE DIPROPIONATE": "BETAMETHASONE DIPROPIONATE",
              "CLOBETASOL PROPIONATE": "CLOBETASOL PROPIONATE",
              "DESONIDE": "DESONIDE",
              "DESOXIMETASONE": "DESOXIMETASONE",
              "FLUOCINONIDE": "FLUOCINONIDE"
            }
          },
          "Steroid-Local Anesthetic Combinations": {
            "AKRON PHARMA": {
              "HYDROCORTISONE ACE-PRAMOXINE": "HYDROCORTISONE ACE-PRAMOXINE"
            },
            "BI COASTAL PHARMACEUTICAL": {
              "HYDROCORTISONE ACE-PRAMOXINE": "HYDROCORTISONE ACE-PRAMOXINE"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "HYDROCORTISONE ACE-PRAMOXINE": "HYDROCORTISONE ACE-PRAMOXINE",
              "PRAMOSONE": "PRAMOSONE"
            },
            "KMM PHARMACEUTICALS": {
              "HYDROCORTISONE ACE-PRAMOXINE": "HYDROCORTISONE ACE-PRAMOXINE"
            },
            "MARNEL PHARMACEUTICAL": {
              "CORTANE-B": "CORTANE-B"
            },
            "MESORA PHARMA": {
              "LIDOCAINE-HYDROCORTISONE ACE": "LIDOCAINE-HYDROCORTISONE ACE"
            },
            "MYLAN SPECIALTY L.P.": {
              "EPIFOAM": "EPIFOAM"
            },
            "NOVUM PHARMA": {
              "NOVACORT": "NOVACORT"
            },
            "PERRIGO PHARMACEUTICALS": {
              "HYDROCORTISONE ACE-PRAMOXINE": "HYDROCORTISONE ACE-PRAMOXINE"
            },
            "SEBELA PHARMACEUTICALS": {
              "PRAMOSONE": "PRAMOSONE",
              "PRAMOSONE E": "PRAMOSONE E"
            },
            "SOLUTECH PHARMACEUTICALS": {
              "LIDOCAINE-HYDROCORTISONE ACE": "LIDOCAINE-HYDROCORTISONE ACE",
              "LIDOSOL-HC": "LIDOSOL-HC"
            }
          },
          "Topical Steroid Combinations": {
            "A-S MEDICATION SOLUTIONS": {
              "HYDROCORTISONE-ALOE": "HYDROCORTISONE-ALOE"
            },
            "ACCUMIX PHARMACEUTICALS": {
              "TRIDERMA FORTE": "TRIDERMA FORTE",
              "VALIDERM": "VALIDERM"
            },
            "ACELLA PHARMACEUTICALS": {
              "HALAC": "HALAC"
            },
            "ACTAVIS MID ATLANTIC": {
              "HYDROCORTISONE-ALOE": "HYDROCORTISONE-ALOE"
            },
            "ALVIX LABORATORIES": {
              "RRB PAK": "RRB PAK"
            },
            "AVIDAS PHARMACEUTICALS": {
              "SCALACORT DK": "SCALACORT DK"
            },
            "BAUSCH HEALTH": {
              "DUOBRII": "DUOBRII"
            },
            "BERGEN BRUNSWIG": {
              "GNP HYDROCORTISONE/ALOE": "GNP HYDROCORTISONE/ALOE"
            },
            "CHATTEM INC": {
              "CORTIZONE-10 INTENSIVE HEALING": "CORTIZONE-10 INTENSIVE HEALING",
              "CORTIZONE-10 PLUS": "CORTIZONE-10 PLUS",
              "CORTIZONE-10/ALOE": "CORTIZONE-10/ALOE"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "TACLONEX": "TACLONEX"
            },
            "COSTCO WHOLESALE": {
              "KLS HYDROCORTISONE PLUS": "KLS HYDROCORTISONE PLUS"
            },
            "GENPAK SOLUTIONS": {
              "SANADERMRX SKIN REPAIR": "SANADERMRX SKIN REPAIR"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "HYDROCORTISONE-ALOE": "HYDROCORTISONE-ALOE"
            },
            "INTERNATIONAL BRAND MANAGEMENT": {
              "SURE RESULT TAC PAK": "SURE RESULT TAC PAK"
            },
            "KAISER FOUNDATION HOSPITAL": {
              "KP HYDROCORTISONE-ALOE": "KP HYDROCORTISONE-ALOE"
            },
            "LEADER BRAND PRODUCTS": {
              "HYDROCORTISONE-ALOE": "HYDROCORTISONE-ALOE"
            },
            "LEO PHARMA INC": {
              "ENSTILAR": "ENSTILAR",
              "TACLONEX": "TACLONEX"
            },
            "MCKESSON": {
              "HM HYDROCORTISONE PLUS": "HM HYDROCORTISONE PLUS",
              "HM HYDROCORTISONE-ALOE MAX ST": "HM HYDROCORTISONE-ALOE MAX ST"
            },
            "MCKESSON SUNMARK": {
              "SM HYDROCORTISONE PLUS": "SM HYDROCORTISONE PLUS",
              "SM HYDROCORTISONE-ALOE MAX ST": "SM HYDROCORTISONE-ALOE MAX ST"
            },
            "MEDIMETRIKS PHARMACEUTICALS": {
              "BESER": "BESER",
              "CLODAN": "CLODAN",
              "SYNALAR (CREAM)": "SYNALAR (CREAM)",
              "SYNALAR (OINTMENT)": "SYNALAR (OINTMENT)",
              "SYNALAR TS": "SYNALAR TS"
            },
            "NUCARE PHARMACEUTICALS": {
              "NUTRIARX CREAMPAK": "NUTRIARX CREAMPAK"
            },
            "PATCHWERX LABS": {
              "DERMA SILKRX SDS PAK": "DERMA SILKRX SDS PAK",
              "DERMAWERX SDS": "DERMAWERX SDS"
            },
            "PERRIGO PHARMACEUTICALS": {
              "CALCIPOTRIENE-BETAMETH DIPROP": "CALCIPOTRIENE-BETAMETH DIPROP"
            },
            "PRASCO LABORATORIES": {
              "CALCIPOTRIENE-BETAMETH DIPROP": "CALCIPOTRIENE-BETAMETH DIPROP"
            },
            "PRIMARY PHARMACEUTICALS": {
              "CLOBETAVIX": "CLOBETAVIX",
              "FLUOVIX": "FLUOVIX",
              "FLUOVIX PLUS": "FLUOVIX PLUS",
              "TRIVIX": "TRIVIX"
            },
            "PURE TEK": {
              "DERMACINRX SILAPAK": "DERMACINRX SILAPAK",
              "ELLZIA PAK": "ELLZIA PAK",
              "FLUOPAR": "FLUOPAR",
              "SILA III": "SILA III",
              "SILAZONE PHARMAPAK": "SILAZONE PHARMAPAK",
              "SILAZONE-II": "SILAZONE-II"
            },
            "RANBAXY LABORATORIES": {
              "ULTRAVATE X (CREAM)": "ULTRAVATE X (CREAM)",
              "ULTRAVATE X (OINTMENT)": "ULTRAVATE X (OINTMENT)"
            },
            "RECSEI LABS": {
              "HC DERMAPAX": "HC DERMAPAX"
            },
            "RITE AID CORPORATION": {
              "RA HYDROCORTISONE PLUS": "RA HYDROCORTISONE PLUS"
            },
            "SANDOZ": {
              "CALCIPOTRIENE-BETAMETH DIPROP": "CALCIPOTRIENE-BETAMETH DIPROP"
            },
            "SHORELINE PHARMACEUTICALS": {
              "DERMAZONE": "DERMAZONE",
              "SILALITE PAK": "SILALITE PAK",
              "TRI-SILA": "TRI-SILA"
            },
            "SINCERUS FLORIDA": {
              "CALCIPOTRIENE-CLOBETASOL PROP": "CALCIPOTRIENE-CLOBETASOL PROP",
              "CLOBETASOL PROP-LEVOCETIRIZINE": "CLOBETASOL PROP-LEVOCETIRIZINE",
              "CLOBETASOL PROP-NIACINAMIDE": "CLOBETASOL PROP-NIACINAMIDE",
              "FLUOCINOLONE ACET-NIACINAMIDE": "FLUOCINOLONE ACET-NIACINAMIDE",
              "NIACINAMIDE-TRIAMCINOLONE ACET": "NIACINAMIDE-TRIAMCINOLONE ACET"
            },
            "SOLUTECH PHARMACEUTICALS": {
              "NOXIPAK": "NOXIPAK",
              "XILAPAK": "XILAPAK"
            },
            "STERLING KNIGHT PHARMACEUTICAL": {
              "TRIAMSIL COMBIPAK": "TRIAMSIL COMBIPAK",
              "TRIAMSIL MULTIPAK": "TRIAMSIL MULTIPAK"
            },
            "TARGET": {
              "TGT ANTI-ITCH/ALOE MAX ST": "TGT ANTI-ITCH/ALOE MAX ST"
            },
            "TARO": {
              "CALCIPOTRIENE-BETAMETH DIPROP": "CALCIPOTRIENE-BETAMETH DIPROP",
              "HYDROCORTISONE-ALOE": "HYDROCORTISONE-ALOE"
            },
            "TOLMAR": {
              "CALCIPOTRIENE-BETAMETH DIPROP": "CALCIPOTRIENE-BETAMETH DIPROP"
            },
            "V2 PHARMA": {
              "QUINIXIL": "QUINIXIL"
            },
            "VILLAGE PHARMA": {
              "SCARZEN SKIN REPAIR": "SCARZEN SKIN REPAIR"
            },
            "WALGREENS": {
              "HYDROCORTISONE-ALOE": "HYDROCORTISONE-ALOE"
            },
            "WHYTEMAN LABS": {
              "WHYTEDERM TRILASIL PAK": "WHYTEDERM TRILASIL PAK"
            }
          }
        },
        "Diaper Rash Products": {
          "Diaper Rash Products": {
            "AMERICAN SALES COMPANY": {
              "COTTONTAILS A + D": "COTTONTAILS A + D"
            },
            "BAYER CONSUMER": {
              "A+D DIAPER RASH": "A+D DIAPER RASH",
              "A+D PREVENT": "A+D PREVENT"
            },
            "BENSON'S BOTTOM PAINT": {
              "BENSONS BOTTOM PAINT": "BENSONS BOTTOM PAINT"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS ALL-PURPOSE SKIN PROTECT": "CVS ALL-PURPOSE SKIN PROTECT",
              "CVS DIAPER": "CVS DIAPER",
              "CVS PEDIATRIC OINTMENT": "CVS PEDIATRIC OINTMENT"
            },
            "EATON LABORATORIES": {
              "RASH AWAY": "RASH AWAY"
            },
            "EMJAY LABORATORIES": {
              "PINXAV": "PINXAV"
            },
            "J & J CONSUMER PRODUCTS": {
              "DESITIN": "DESITIN"
            },
            "JOHNSON&JOHNSON HEALTHCARE": {
              "DESITIN MULTI-PURPOSE HEALING": "DESITIN MULTI-PURPOSE HEALING"
            },
            "L'OREAL": {
              "CERAVE BABY": "CERAVE BABY"
            },
            "MEDCARA PHARMACEUTICALS": {
              "DR BOLDTS HINEY HEALER": "DR BOLDTS HINEY HEALER"
            },
            "PAL MIDWEST LTD": {
              "PALADIN": "PALADIN"
            },
            "RANDOB LABS": {
              "BALMEX MULTI-PURPOSE": "BALMEX MULTI-PURPOSE"
            },
            "RISING PHARMACEUTICALS": {
              "MEDI-PASTE": "MEDI-PASTE"
            }
          }
        },
        "Eczema Agents": {
          "Atopic Dermatitis - Monoclonal Antibodies": {
            "SANOFI PHARMACEUTICALS": {
              "DUPIXENT": "DUPIXENT"
            }
          }
        },
        "Emollient/Keratolytic Agents": {
          "Emollient/Keratolytic Agents": {
            "ACELLA PHARMACEUTICALS": {
              "UREA NAIL FILM": "UREA NAIL FILM"
            },
            "AKRON PHARMA": {
              "UREA NAIL": "UREA NAIL"
            },
            "ARTESA LABS": {
              "KERALAC": "KERALAC",
              "UTOPIC": "UTOPIC"
            },
            "ASCENSIA DIABETES CARE": {
              "DERMAL THERAPY FINGER CARE": "DERMAL THERAPY FINGER CARE"
            },
            "AVKARE": {
              "UREA": "UREA"
            },
            "BAKER CUMMINS DERMATOLOGICALS": {
              "ULTRA MIDE 25": "ULTRA MIDE 25"
            },
            "BAUSCH HEALTH": {
              "NUTRAPLUS": "NUTRAPLUS",
              "UREACIN-10": "UREACIN-10",
              "UREACIN-20": "UREACIN-20"
            },
            "BETA DERMS": {
              "BETA CARE BETAMIDE": "BETA CARE BETAMIDE"
            },
            "BIOCOMP PHARMA": {
              "UREA": "UREA"
            },
            "BOWYN LABS": {
              "UREA": "UREA"
            },
            "CIPHER PHARMACEUTICALS": {
              "UMECTA": "UMECTA",
              "UMECTA NAIL FILM": "UMECTA NAIL FILM"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "UREA": "UREA"
            },
            "COLOPLAST": {
              "ATRAC-TAIN": "ATRAC-TAIN"
            },
            "CROWN LABORATORIES": {
              "DERMASORB XM": "DERMASORB XM",
              "REA LO 39": "REA LO 39",
              "REA LO 40": "REA LO 40",
              "REA-LO": "REA-LO"
            },
            "DSE HEALTHCARE SOLUTIONS": {
              "AQUA CARE": "AQUA CARE"
            },
            "EPI HEALTH": {
              "UMECTA MOUSSE": "UMECTA MOUSSE"
            },
            "EXACT-RX": {
              "UREA": "UREA",
              "UREA NAIL": "UREA NAIL"
            },
            "EXELTIS USA DERMATOLOGY": {
              "HYDRO 40": "HYDRO 40"
            },
            "GERITREX CORPORATION": {
              "CARB-O-PHILIC/10": "CARB-O-PHILIC/10",
              "CARB-O-PHILIC/20": "CARB-O-PHILIC/20"
            },
            "GORDON": {
              "GORDONS UREA": "GORDONS UREA",
              "GORMEL": "GORMEL",
              "GORMEL 10": "GORMEL 10"
            },
            "H2 PHARMA": {
              "UREA": "UREA",
              "UREA NAIL": "UREA NAIL"
            },
            "HAWTHORN PHARMACEUTICALS": {
              "CEROVEL": "CEROVEL"
            },
            "LASER PHARMACEUTICALS": {
              "UREA": "UREA"
            },
            "MARLEX PHARMACEUTICALS": {
              "UREA": "UREA"
            },
            "MAYNE PHARMA": {
              "UREA": "UREA",
              "UREA NAIL": "UREA NAIL"
            },
            "MED-DERM": {
              "REA-LO": "REA-LO"
            },
            "MEDCO LAB": {
              "AQUAPHILIC/CARBAMIDE": "AQUAPHILIC/CARBAMIDE",
              "LANAPHILIC/UREA": "LANAPHILIC/UREA"
            },
            "MEDIMETRIKS PHARMACEUTICALS": {
              "URAMAXIN": "URAMAXIN",
              "URAMAXIN GT": "URAMAXIN GT"
            },
            "MERZ PHARMACEUTICAL": {
              "ALUVEA": "ALUVEA"
            },
            "MESORA PHARMA": {
              "UREA": "UREA",
              "UREDEB": "UREDEB",
              "UREMEZ-40": "UREMEZ-40",
              "URESOL": "URESOL"
            },
            "METHOD PHARMACEUTICALS": {
              "METOPIC": "METOPIC",
              "UREA": "UREA"
            },
            "NATIONAL BIO GREEN SCIENCES": {
              "XUREA": "XUREA"
            },
            "PERRIGO PHARMACEUTICALS": {
              "UREA": "UREA"
            },
            "PHARMADERM": {
              "CARMOL": "CARMOL",
              "CARMOL 10": "CARMOL 10",
              "CARMOL 20": "CARMOL 20"
            },
            "PRASCO LABORATORIES": {
              "UREA": "UREA",
              "UREA-C40": "UREA-C40"
            },
            "PRUGEN": {
              "CEM-UREA": "CEM-UREA"
            },
            "QUALITY CARE": {
              "UMECTA MOUSSE": "UMECTA MOUSSE"
            },
            "ROSEMAR LABS": {
              "UREA": "UREA"
            },
            "RUGBY LABORATORIES": {
              "UREA 10 HYDRATING": "UREA 10 HYDRATING",
              "UREA 20 INTENSIVE HYDRATING": "UREA 20 INTENSIVE HYDRATING"
            },
            "SOLUTECH PHARMACEUTICALS": {
              "RYNODERM": "RYNODERM",
              "SALRIX": "SALRIX",
              "URALISS": "URALISS",
              "URE-K": "URE-K",
              "UREA": "UREA"
            },
            "STERLING KNIGHT PHARMACEUTICAL": {
              "PROTEXA": "PROTEXA",
              "UREVAZ": "UREVAZ"
            },
            "STRATUS PHARMACEUTICALS": {
              "LATRIX": "LATRIX",
              "REMEVEN": "REMEVEN",
              "UREA": "UREA",
              "UREA 20": "UREA 20"
            },
            "TRINITY PHARMACEUTICALS": {
              "UREA": "UREA"
            }
          },
          "Emollient/Keratolytic Combinations": {
            "ACELLA PHARMACEUTICALS": {
              "UREA HYDRATING": "UREA HYDRATING",
              "UREA IN ZN UNDECYL-LACTIC ACID": "UREA IN ZN UNDECYL-LACTIC ACID",
              "UREA NAIL": "UREA NAIL"
            },
            "ADVANTICE HEALTH": {
              "KERASAL ULTRA20": "KERASAL ULTRA20"
            },
            "CIPHER PHARMACEUTICALS": {
              "UMECTA PD": "UMECTA PD"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "HYDRO 35": "HYDRO 35"
            },
            "EXACT-RX": {
              "UREA": "UREA",
              "UREA NAIL": "UREA NAIL"
            },
            "EXELTIS USA DERMATOLOGY": {
              "HYDRO 35": "HYDRO 35"
            },
            "GERITREX CORPORATION": {
              "CARB-O-LAC HP": "CARB-O-LAC HP",
              "CARB-O-LAC5": "CARB-O-LAC5"
            },
            "MEDIMETRIKS PHARMACEUTICALS": {
              "URAMAXIN": "URAMAXIN"
            },
            "STRATUS PHARMACEUTICALS": {
              "LATRIX XM": "LATRIX XM"
            },
            "WOODWARD LABS": {
              "MYCOCIDE CX CALLUS EXFOLIATOR": "MYCOCIDE CX CALLUS EXFOLIATOR"
            }
          }
        },
        "Emollients": {
          "Emollient Combinations": {
            "3M MEDICAL": {
              "CAVILON EMOLLIENT": "CAVILON EMOLLIENT",
              "CAVILON FOOT & DRY SKIN": "CAVILON FOOT & DRY SKIN"
            },
            "CMP PHARMA": {
              "MINERAL OIL-HYDROPHIL PETROLAT": "MINERAL OIL-HYDROPHIL PETROLAT"
            },
            "CYPRESS PHARMACEUTICAL": {
              "LACTIC ACID E": "LACTIC ACID E"
            },
            "GORDON": {
              "VITA-RAY": "VITA-RAY"
            },
            "NATIONAL VITAMIN": {
              "VITAMIN E & C BEAUTY LOTION": "VITAMIN E & C BEAUTY LOTION",
              "VITAMIN E & K BEAUTIFUL SKIN": "VITAMIN E & K BEAUTIFUL SKIN",
              "VITAMIN E BEAUTY": "VITAMIN E BEAUTY",
              "VITAMINS E & A BEAUTY OIL": "VITAMINS E & A BEAUTY OIL",
              "VITAMINS E & D BEAUTY OIL": "VITAMINS E & D BEAUTY OIL"
            },
            "QUALITY CARE": {
              "LACTIC ACID E": "LACTIC ACID E"
            },
            "RISING PHARMACEUTICALS": {
              "LACTIC ACID E": "LACTIC ACID E"
            },
            "STRATUS PHARMACEUTICALS": {
              "LACTIC ACID E": "LACTIC ACID E"
            }
          },
          "Emollients": {
            "A-S MEDICATION SOLUTIONS": {
              "VITAMINS A & D": "VITAMINS A & D"
            },
            "AKORN CONSUMER": {
              "DIABETIDERM": "DIABETIDERM",
              "DIABETIDERM FOOT REJUVENATING": "DIABETIDERM FOOT REJUVENATING",
              "DIABETIDERM HAND & BODY": "DIABETIDERM HAND & BODY"
            },
            "AKRON PHARMA": {
              "AMMONIUM LACTATE": "AMMONIUM LACTATE",
              "HYDROPHOR": "HYDROPHOR"
            },
            "ALBERTO CULVER": {
              "ST IVES SWISS FORMULA MOISTURE": "ST IVES SWISS FORMULA MOISTURE"
            },
            "ALIGON PHARMACEUTICALS": {
              "SKIN REPAIR": "SKIN REPAIR"
            },
            "AMERIDERM LABORATORIES LTD": {
              "VITAMINS A & D": "VITAMINS A & D"
            },
            "ARTESA LABS": {
              "HYLINATE": "HYLINATE"
            },
            "ASCENSIA DIABETES CARE": {
              "DERMAL THERAPY EXTRA STRENGTH": "DERMAL THERAPY EXTRA STRENGTH",
              "DERMAL THERAPY FACE CARE": "DERMAL THERAPY FACE CARE",
              "DERMAL THERAPY FOOT MASSAGE": "DERMAL THERAPY FOOT MASSAGE",
              "DERMAL THERAPY HAND/ELBOW": "DERMAL THERAPY HAND/ELBOW",
              "DERMAL THERAPY HEEL CARE": "DERMAL THERAPY HEEL CARE"
            },
            "B.F. ASCHER": {
              "PEN-KERA": "PEN-KERA",
              "PRETTY FEET/HANDS": "PRETTY FEET/HANDS"
            },
            "BARD": {
              "SPECIAL CARE": "SPECIAL CARE"
            },
            "BASIC DRUGS": {
              "VITAMIN E": "VITAMIN E"
            },
            "BASIC ORGANICS": {
              "E-OINTMENT": "E-OINTMENT"
            },
            "BAUSCH HEALTH": {
              "CERAVE AM SPF 30": "CERAVE AM SPF 30",
              "LACTINOL HX": "LACTINOL HX",
              "NUTRADERM": "NUTRADERM",
              "NUTRADERM ADVANCED FORMULA": "NUTRADERM ADVANCED FORMULA",
              "VELVACHOL": "VELVACHOL",
              "WIBI": "WIBI"
            },
            "BAYER CONSUMER": {
              "A + D PERSONAL CARE LOTION": "A + D PERSONAL CARE LOTION",
              "ALOE AFTERSUN": "ALOE AFTERSUN",
              "SARDOETTES": "SARDOETTES"
            },
            "BEIERSDORF DERMA": {
              "AQUAPHOR": "AQUAPHOR",
              "AQUAPHOR ADVANCED THERAPY": "AQUAPHOR ADVANCED THERAPY",
              "AQUAPHOR ADVANCED THERAPY BABY": "AQUAPHOR ADVANCED THERAPY BABY",
              "EUCERIN": "EUCERIN",
              "EUCERIN ADVANCED REPAIR": "EUCERIN ADVANCED REPAIR",
              "EUCERIN BABY": "EUCERIN BABY",
              "EUCERIN BABY ECZEMA RELIEF": "EUCERIN BABY ECZEMA RELIEF",
              "EUCERIN CALMING DAILY MOIST": "EUCERIN CALMING DAILY MOIST",
              "EUCERIN DAILY PROTECTION/SPF30": "EUCERIN DAILY PROTECTION/SPF30",
              "EUCERIN ECZEMA RELIEF": "EUCERIN ECZEMA RELIEF",
              "EUCERIN INTENSIVE REPAIR": "EUCERIN INTENSIVE REPAIR",
              "EUCERIN INTENSIVE REPAIR HAND": "EUCERIN INTENSIVE REPAIR HAND",
              "EUCERIN ORIGINAL HEALING": "EUCERIN ORIGINAL HEALING",
              "EUCERIN PLUS": "EUCERIN PLUS",
              "EUCERIN PLUS INTENSIVE REPAIR": "EUCERIN PLUS INTENSIVE REPAIR",
              "EUCERIN PROFESSIONAL REPAIR": "EUCERIN PROFESSIONAL REPAIR",
              "EUCERIN ROUGHNESS RELIEF": "EUCERIN ROUGHNESS RELIEF",
              "EUCERIN SKIN CALMING": "EUCERIN SKIN CALMING",
              "EUCERIN SMOOTHING REPAIR": "EUCERIN SMOOTHING REPAIR",
              "NIVEA": "NIVEA",
              "NIVEA ORIGINAL MOISTURE": "NIVEA ORIGINAL MOISTURE",
              "NIVEA SOFT": "NIVEA SOFT",
              "NIVEA VISAGE": "NIVEA VISAGE",
              "NIVEA VISAGE INNER BEAUTY": "NIVEA VISAGE INNER BEAUTY"
            },
            "BERGEN BRUNSWIG": {
              "GNP ADVANCED RECOVERY": "GNP ADVANCED RECOVERY",
              "GNP GLYCERIN": "GNP GLYCERIN",
              "GNP GLYCERIN/ROSE WATER": "GNP GLYCERIN/ROSE WATER",
              "GNP SOOTHING BATH TREATMENT": "GNP SOOTHING BATH TREATMENT",
              "GNP VITAMIN A & D": "GNP VITAMIN A & D"
            },
            "BETA DERMS": {
              "BETA CARE": "BETA CARE",
              "BETA XMA": "BETA XMA"
            },
            "BLISTEX": {
              "DAILY CONDITIONING TREATMENT": "DAILY CONDITIONING TREATMENT"
            },
            "CHAIN DRUG CONSORTIUM": {
              "BABY DARLINGS SOOTHING BATH": "BABY DARLINGS SOOTHING BATH",
              "COLLOIDAL OATMEAL BATH": "COLLOIDAL OATMEAL BATH",
              "DERMALUBE DAILY MOISTURIZING": "DERMALUBE DAILY MOISTURIZING",
              "GLYCERIN": "GLYCERIN",
              "VITAMINS A & D": "VITAMINS A & D"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC GLYCERIN": "QC GLYCERIN"
            },
            "CHATTEM INC": {
              "GOLD BOND ECZEMA RELIEF": "GOLD BOND ECZEMA RELIEF",
              "GOLD BOND MEDICATED BODY": "GOLD BOND MEDICATED BODY",
              "GOLD BOND MEDICATED BODY EX ST": "GOLD BOND MEDICATED BODY EX ST",
              "GOLD BOND ULT ROUGH/BUMPY SKIN": "GOLD BOND ULT ROUGH/BUMPY SKIN",
              "GOLD BOND ULT SHEER RIBBONS": "GOLD BOND ULT SHEER RIBBONS",
              "GOLD BOND ULTIMATE": "GOLD BOND ULTIMATE",
              "GOLD BOND ULTIMATE HEALING": "GOLD BOND ULTIMATE HEALING",
              "GOLD BOND ULTIMATE OVERNIGHT": "GOLD BOND ULTIMATE OVERNIGHT",
              "GOLD BOND ULTIMATE PROTECTION": "GOLD BOND ULTIMATE PROTECTION",
              "GOLD BOND ULTIMATE RESTORING": "GOLD BOND ULTIMATE RESTORING",
              "GOLD BOND ULTIMATE SOFTENING": "GOLD BOND ULTIMATE SOFTENING",
              "GOLD BOND ULTIMATE SOOTHING": "GOLD BOND ULTIMATE SOOTHING",
              "GOLD BOND ULTRA ECZEMA RELIEF": "GOLD BOND ULTRA ECZEMA RELIEF"
            },
            "CIPHER PHARMACEUTICALS": {
              "PRO:12 MOUSSE AL12": "PRO:12 MOUSSE AL12"
            },
            "COLOPLAST": {
              "BAZA CLEAR": "BAZA CLEAR",
              "SWEEN": "SWEEN",
              "XTRA-CARE": "XTRA-CARE"
            },
            "CONVATEC": {
              "ALOE VESTA CLEAR BARRIER": "ALOE VESTA CLEAR BARRIER",
              "ALOE VESTA SKIN PROTECTANT": "ALOE VESTA SKIN PROTECTANT"
            },
            "CROWN LABORATORIES": {
              "BASLE": "BASLE",
              "MAXAM": "MAXAM",
              "SORBOLENE": "SORBOLENE"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS ADVANCED HEALING": "CVS ADVANCED HEALING",
              "CVS BEAUTY 360 DRY SKIN": "CVS BEAUTY 360 DRY SKIN",
              "CVS BEAUTY 360 PURE GLYCERIN": "CVS BEAUTY 360 PURE GLYCERIN",
              "CVS DAILY ULTRA MOISTURE": "CVS DAILY ULTRA MOISTURE",
              "CVS DRY SKIN CARE": "CVS DRY SKIN CARE",
              "CVS DRY SKIN THERAPY": "CVS DRY SKIN THERAPY",
              "CVS ECZEMA CARE": "CVS ECZEMA CARE",
              "CVS ECZEMA RELIEF": "CVS ECZEMA RELIEF",
              "CVS EXTRA MOISTURIZING": "CVS EXTRA MOISTURIZING",
              "CVS GENTLE SKIN CLEANSER": "CVS GENTLE SKIN CLEANSER",
              "CVS HYDRATING SKIN TREATMENT": "CVS HYDRATING SKIN TREATMENT",
              "CVS MOISTURIZING": "CVS MOISTURIZING",
              "CVS MOISTURIZING EXTRA DRY": "CVS MOISTURIZING EXTRA DRY",
              "CVS PURE GLYCERIN": "CVS PURE GLYCERIN",
              "CVS SKIN TREATMENT": "CVS SKIN TREATMENT",
              "CVS SPECIAL CARE": "CVS SPECIAL CARE",
              "CVS VITAMIN A&D": "CVS VITAMIN A&D",
              "CVS VITAMIN E": "CVS VITAMIN E",
              "CVS VITAMIN E MOISTURIZING": "CVS VITAMIN E MOISTURIZING"
            },
            "CYPRESS PHARMACEUTICAL": {
              "LACTIC ACID": "LACTIC ACID",
              "SODIUM HYALURONATE": "SODIUM HYALURONATE"
            },
            "DARTMOUTH": {
              "ELON SKIN REPAIR SYSTEM": "ELON SKIN REPAIR SYSTEM"
            },
            "DENISON LABS": {
              "HYDROLATUM": "HYDROLATUM",
              "SOFTDEN": "SOFTDEN"
            },
            "DERMAIDE RESEARCH": {
              "DERMAIDE ALOE": "DERMAIDE ALOE"
            },
            "DERMARITE": {
              "DERMADAILY": "DERMADAILY",
              "DERMAPHOR": "DERMAPHOR",
              "DERMAVANTAGE": "DERMAVANTAGE",
              "LUBRISILK": "LUBRISILK"
            },
            "DOMEL LABORATORIES": {
              "HYDRAZONE LOTION": "HYDRAZONE LOTION"
            },
            "DSE HEALTHCARE SOLUTIONS": {
              "ALBOLENE": "ALBOLENE",
              "SARATOGA": "SARATOGA"
            },
            "DYNAREX CORPORATION": {
              "VITAMINS A & D": "VITAMINS A & D"
            },
            "E.T. BROWNE DRUG CO": {
              "PALMERS COCOA BUTTER FORMULA": "PALMERS COCOA BUTTER FORMULA",
              "PALMERS COCONUT OIL BODY": "PALMERS COCONUT OIL BODY",
              "PALMERS COCONUT OIL HAND": "PALMERS COCONUT OIL HAND",
              "PALMERS INTENSIVE RELIEF HAND": "PALMERS INTENSIVE RELIEF HAND",
              "PALMERS NATURAL VITAMIN E": "PALMERS NATURAL VITAMIN E",
              "PALMERS NIGHT CREAM": "PALMERS NIGHT CREAM",
              "PALMERS STRETCH MARKS": "PALMERS STRETCH MARKS"
            },
            "EQUALINE": {
              "EQ THERAPEUTIC MOISTURIZING": "EQ THERAPEUTIC MOISTURIZING",
              "EQL ABSOLUTE MOISTURE DRY SKIN": "EQL ABSOLUTE MOISTURE DRY SKIN",
              "EQL ADVANCED HEALING": "EQL ADVANCED HEALING",
              "EQL ADVANCED RECOVERY": "EQL ADVANCED RECOVERY",
              "EQL ADVANCED SKIN THERAPY": "EQL ADVANCED SKIN THERAPY",
              "EQL ALOE AFTER SUN": "EQL ALOE AFTER SUN",
              "EQL MOISTURIZING": "EQL MOISTURIZING",
              "EQL ULTRA MOISTURIZING DAILY": "EQL ULTRA MOISTURIZING DAILY",
              "EQL VITAMIN E ULTRA STRENGTH": "EQL VITAMIN E ULTRA STRENGTH"
            },
            "FABRICATION ENTERPRISES": {
              "WAXWEL PARAFFIN BATH": "WAXWEL PARAFFIN BATH"
            },
            "FAGRON": {
              "PENTRAVAN": "PENTRAVAN",
              "PENTRAVAN PLUS": "PENTRAVAN PLUS"
            },
            "FERNDALE LAB": {
              "DERMEND BRUISE FORMULA": "DERMEND BRUISE FORMULA"
            },
            "FLANDERS": {
              "FLANDERS BUTTOCKS": "FLANDERS BUTTOCKS"
            },
            "FLEET PHARMACEUTICALS": {
              "BOUDREAUXS BABY BUTT SMOOTH": "BOUDREAUXS BABY BUTT SMOOTH"
            },
            "FOCUS HEALTH GROUP": {
              "RADIAGUARD ADVANCED": "RADIAGUARD ADVANCED"
            },
            "FOUGERA": {
              "VITAMINS A & D": "VITAMINS A & D"
            },
            "FREEDA VITAMINS": {
              "VITAMIN E": "VITAMIN E"
            },
            "GALDERMA": {
              "CETAPHIL": "CETAPHIL",
              "CETAPHIL DAILY ADVANCE": "CETAPHIL DAILY ADVANCE",
              "CETAPHIL DAILY FACIAL MOIST": "CETAPHIL DAILY FACIAL MOIST",
              "CETAPHIL DERMACONTROL/SPF 30": "CETAPHIL DERMACONTROL/SPF 30",
              "CETAPHIL MOISTURIZING": "CETAPHIL MOISTURIZING",
              "CETAPHIL PRO ECZEMA SOOTHING": "CETAPHIL PRO ECZEMA SOOTHING",
              "CETAPHIL RESTORADERM": "CETAPHIL RESTORADERM",
              "CETAPHIL THERAPEUTIC HAND": "CETAPHIL THERAPEUTIC HAND"
            },
            "GERI-CARE": {
              "VITAMIN A&D": "VITAMIN A&D"
            },
            "GERITREX CORPORATION": {
              "CARB-O-LAN 10": "CARB-O-LAN 10",
              "CARB-O-LAN 20": "CARB-O-LAN 20",
              "CARB-O-PHILIC/20": "CARB-O-PHILIC/20",
              "CARB-O-SAL 5": "CARB-O-SAL 5",
              "GENTLE": "GENTLE",
              "GERI-HYDROLAC 12": "GERI-HYDROLAC 12",
              "GERI-HYDROLAC 5": "GERI-HYDROLAC 5",
              "GERI-SOFT": "GERI-SOFT",
              "GRX VITAMIN E": "GRX VITAMIN E",
              "HYDROPHOR": "HYDROPHOR",
              "LUBRISKIN": "LUBRISKIN",
              "VITAMIN A & D": "VITAMIN A & D"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "KERI ADVANCED MOISTURE THERAPY": "KERI ADVANCED MOISTURE THERAPY",
              "KERI BASIC ESSENTIALS": "KERI BASIC ESSENTIALS",
              "KERI LONG LASTING": "KERI LONG LASTING",
              "KERI NOURISHING SHEA BUTTER": "KERI NOURISHING SHEA BUTTER",
              "KERI ORIGINAL": "KERI ORIGINAL",
              "KERI OVERNIGHT": "KERI OVERNIGHT",
              "KERI RENEWAL MILK BODY": "KERI RENEWAL MILK BODY",
              "KERI RENEWAL SERUM": "KERI RENEWAL SERUM",
              "KERI RENEWAL SKIN FIRMING": "KERI RENEWAL SKIN FIRMING",
              "KERI RENEWAL STRETCH MARK": "KERI RENEWAL STRETCH MARK",
              "KERI SENSITIVE SKIN": "KERI SENSITIVE SKIN",
              "LAMISILK REPAIR COMPLEX SERUM": "LAMISILK REPAIR COMPLEX SERUM"
            },
            "GLAXO SMITH KLINE": {
              "EPILYT": "EPILYT"
            },
            "GORDON": {
              "ALOE GRANDE": "ALOE GRANDE",
              "EMOLLIA-CREME": "EMOLLIA-CREME",
              "EMOLLIA-LOTION": "EMOLLIA-LOTION",
              "GORDOMATIC": "GORDOMATIC",
              "GORDONS-VITE A": "GORDONS-VITE A",
              "GORDONS-VITE E": "GORDONS-VITE E"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "AQUAPHOR": "AQUAPHOR",
              "PETROLATUM & LANOLIN": "PETROLATUM & LANOLIN"
            },
            "HAWTHORN PHARMACEUTICALS": {
              "HYLIRA": "HYLIRA"
            },
            "HRA PHARMA RARE DISEASES": {
              "MEDERMA AG FACE": "MEDERMA AG FACE",
              "MEDERMA AG HAND & BODY": "MEDERMA AG HAND & BODY",
              "MEDERMA STRETCH MARKS THERAPY": "MEDERMA STRETCH MARKS THERAPY"
            },
            "HUMCO": {
              "GLYCERIN-ROSE WATER": "GLYCERIN-ROSE WATER"
            },
            "IVAX DERMATOLOGICALS": {
              "AQUADERM": "AQUADERM"
            },
            "J & J CONSUMER PRODUCTS": {
              "AVEENO ACTIVE NAT SKIN RELIEF": "AVEENO ACTIVE NAT SKIN RELIEF",
              "AVEENO ACTIVE NATURALS DAILY": "AVEENO ACTIVE NATURALS DAILY",
              "AVEENO BABY BATH TREATMENT": "AVEENO BABY BATH TREATMENT",
              "AVEENO BABY ECZEMA THERAPY": "AVEENO BABY ECZEMA THERAPY",
              "AVEENO CALM & RESTORE": "AVEENO CALM & RESTORE",
              "AVEENO CREAMY MOISTURIZING": "AVEENO CREAMY MOISTURIZING",
              "AVEENO DAILY MOISTURIZING": "AVEENO DAILY MOISTURIZING",
              "AVEENO ECZEMA THERAPY": "AVEENO ECZEMA THERAPY",
              "AVEENO INTENSE RELIEF HAND": "AVEENO INTENSE RELIEF HAND",
              "AVEENO POSITIVELY AGELESS BODY": "AVEENO POSITIVELY AGELESS BODY",
              "AVEENO POSITIVELY AGELESS EYE": "AVEENO POSITIVELY AGELESS EYE",
              "AVEENO POSITIVELY AGELESS NGHT": "AVEENO POSITIVELY AGELESS NGHT",
              "AVEENO POSITIVELY NOURISHING": "AVEENO POSITIVELY NOURISHING",
              "AVEENO POSITIVELY RADIANT": "AVEENO POSITIVELY RADIANT",
              "AVEENO RESTORATIVE SKIN THERAP": "AVEENO RESTORATIVE SKIN THERAP",
              "AVEENO SKIN STRENGTHENING BODY": "AVEENO SKIN STRENGTHENING BODY",
              "AVEENO SKIN STRENGTHENING HAND": "AVEENO SKIN STRENGTHENING HAND",
              "AVEENO SOOTHING BATH TREATMENT": "AVEENO SOOTHING BATH TREATMENT",
              "AVEENO STRESS RELIEF": "AVEENO STRESS RELIEF",
              "CLEAN & CLEAN WATERMELON MOIST": "CLEAN & CLEAN WATERMELON MOIST",
              "LUBRIDERM": "LUBRIDERM",
              "LUBRIDERM ADVANCED THERAPY": "LUBRIDERM ADVANCED THERAPY",
              "LUBRIDERM DAILY MOISTURE": "LUBRIDERM DAILY MOISTURE",
              "LUBRIDERM INTENSE SKIN REPAIR": "LUBRIDERM INTENSE SKIN REPAIR",
              "LUBRIDERM MENS 3-IN-1": "LUBRIDERM MENS 3-IN-1",
              "LUBRIDERM SERIOUSLY SENSITIVE": "LUBRIDERM SERIOUSLY SENSITIVE",
              "LUBRIDERM SKIN NOURISHING": "LUBRIDERM SKIN NOURISHING",
              "LUBRIDERM SOOTHING RELIEF/COOL": "LUBRIDERM SOOTHING RELIEF/COOL",
              "NEOSPORIN ECZEMA ESSENTIALS": "NEOSPORIN ECZEMA ESSENTIALS",
              "ROC DEEP WRINKLE SERUM": "ROC DEEP WRINKLE SERUM",
              "ROC MULTI CORREXION LIFT NIGHT": "ROC MULTI CORREXION LIFT NIGHT",
              "ROC MULTI-CORREXION EYE": "ROC MULTI-CORREXION EYE",
              "ROC MULTI-CORREXION NIGHT": "ROC MULTI-CORREXION NIGHT",
              "ROC RETINOL CORREXION": "ROC RETINOL CORREXION",
              "ROC RETINOL CORREXION EYE": "ROC RETINOL CORREXION EYE",
              "ROC RETINOL CORREXION NIGHT": "ROC RETINOL CORREXION NIGHT"
            },
            "J & J MEDICAL": {
              "J & J BURN CREAM": "J & J BURN CREAM",
              "PREVACARE TOTAL SKIN CARE": "PREVACARE TOTAL SKIN CARE"
            },
            "JOHNSON&JOHNSON HEALTHCARE": {
              "AVEENO BABY ECZEMA THERAPY": "AVEENO BABY ECZEMA THERAPY"
            },
            "JSJ PHARMACEUTICALS": {
              "AL12": "AL12"
            },
            "L'OREAL": {
              "CERAVE": "CERAVE",
              "CERAVE AM SPF 30": "CERAVE AM SPF 30",
              "CERAVE DAILY MOISTURIZING": "CERAVE DAILY MOISTURIZING",
              "CERAVE HEALING": "CERAVE HEALING",
              "CERAVE MOISTURIZING": "CERAVE MOISTURIZING",
              "CERAVE PM": "CERAVE PM",
              "CERAVE SA RENEWING": "CERAVE SA RENEWING",
              "CERAVE SA ROUGH & BUMPY SKIN": "CERAVE SA ROUGH & BUMPY SKIN"
            },
            "LANTISEPTIC": {
              "LANTISEPTIC THERAPEUTIC": "LANTISEPTIC THERAPEUTIC"
            },
            "LEADER BRAND PRODUCTS": {
              "A&D": "A&D",
              "GLYCERIN": "GLYCERIN",
              "MOISTURIZING CREAM": "MOISTURIZING CREAM"
            },
            "LINKS MEDICAL PRODUCTS": {
              "HYDRASYN25": "HYDRASYN25"
            },
            "MAJOR PHARMACEUTICALS": {
              "AMMONIUM LACTATE": "AMMONIUM LACTATE",
              "LUBRISOFT": "LUBRISOFT",
              "MINERIN": "MINERIN",
              "THERA-DERM": "THERA-DERM",
              "VITAMINS A & D": "VITAMINS A & D"
            },
            "MARLEX PHARMACEUTICALS": {
              "GLYCERIN": "GLYCERIN"
            },
            "MASON VITAMINS": {
              "COCONUT OIL BEAUTY": "COCONUT OIL BEAUTY",
              "COLLAGEN": "COLLAGEN",
              "VITAMIN E": "VITAMIN E"
            },
            "MCKESSON": {
              "HM GLYCERIN": "HM GLYCERIN"
            },
            "MCKESSON SUNMARK": {
              "SM DRY SKIN THERAPY": "SM DRY SKIN THERAPY",
              "SM GLYCERIN": "SM GLYCERIN",
              "SM OATMEAL BATH": "SM OATMEAL BATH"
            },
            "MEDCO LAB": {
              "AQUAPHILIC": "AQUAPHILIC",
              "LANAPHILIC": "LANAPHILIC",
              "OINTMENT BASE": "OINTMENT BASE"
            },
            "MEDICINE SHOPPE": {
              "LUBRICATING LOTION": "LUBRICATING LOTION",
              "MOISTURIZING CREAM": "MOISTURIZING CREAM",
              "MOISTURIZING LOTION": "MOISTURIZING LOTION"
            },
            "MEDIMETRIKS PHARMACEUTICALS": {
              "KERADAN": "KERADAN",
              "NISEKO HYDRATING FACIAL": "NISEKO HYDRATING FACIAL"
            },
            "MEDLINE/DERMAL MANAGEMENT": {
              "CURAD VITAMIN A & D": "CURAD VITAMIN A & D",
              "SOOTHE & COOL MOISTURIZING": "SOOTHE & COOL MOISTURIZING",
              "SOOTHE & COOL SKIN": "SOOTHE & COOL SKIN",
              "VITAMINS A & D": "VITAMINS A & D"
            },
            "MERZ PHARMACEUTICAL": {
              "AQUA GLYCOLIC FACE": "AQUA GLYCOLIC FACE",
              "AQUA GLYCOLIC HAND/BODY": "AQUA GLYCOLIC HAND/BODY",
              "AQUA LACTEN": "AQUA LACTEN",
              "AQUAMED": "AQUAMED",
              "CAM": "CAM"
            },
            "NAT-RUL HEALTH PRODUCTS": {
              "E-CREAM": "E-CREAM",
              "E-OIL": "E-OIL",
              "MOISTURIZING VITAMIN E/ALOE": "MOISTURIZING VITAMIN E/ALOE"
            },
            "NATIONAL VITAMIN": {
              "COCOA BUTTER HAND & BODY": "COCOA BUTTER HAND & BODY",
              "COCOA BUTTER SKIN": "COCOA BUTTER SKIN",
              "MSM SKIN": "MSM SKIN",
              "NATURAL VITAMIN E MOISTURIZING": "NATURAL VITAMIN E MOISTURIZING",
              "VITAMIN A WRINKLE TREATMENT": "VITAMIN A WRINKLE TREATMENT",
              "VITAMIN E BEAUTY": "VITAMIN E BEAUTY",
              "VITAMIN E SKIN": "VITAMIN E SKIN",
              "VITAMIN E WITH PANTHENOL": "VITAMIN E WITH PANTHENOL",
              "VITAMIN E-VIT A & D": "VITAMIN E-VIT A & D"
            },
            "NATURES BOUNTY": {
              "DMAE": "DMAE",
              "E-OIL": "E-OIL",
              "VITAMIN E": "VITAMIN E"
            },
            "NEUTROGENA": {
              "NEUTROGENA BODY": "NEUTROGENA BODY",
              "NEUTROGENA HAND": "NEUTROGENA HAND",
              "NEUTROGENA HEALTHY SKIN": "NEUTROGENA HEALTHY SKIN",
              "NEUTROGENA HEALTHY SKIN FACE": "NEUTROGENA HEALTHY SKIN FACE",
              "NEUTROGENA MOISTURE SENS SKIN": "NEUTROGENA MOISTURE SENS SKIN"
            },
            "NEW WORLD IMPORTS": {
              "VITAMIN A & D": "VITAMIN A & D"
            },
            "NNODUM CORPORATION": {
              "AMMONIUM LACTATE": "AMMONIUM LACTATE"
            },
            "NUMARK LABS": {
              "SARATOGA": "SARATOGA"
            },
            "ONTOS": {
              "NOBLE MYSTIQUE EMU-LAC": "NOBLE MYSTIQUE EMU-LAC"
            },
            "PERFECTA PRODUCTS": {
              "ZIMS CRACK CREME": "ZIMS CRACK CREME",
              "ZIMS CRACK CREME DIABETIC": "ZIMS CRACK CREME DIABETIC"
            },
            "PERRIGO": {
              "AMMONIUM LACTATE": "AMMONIUM LACTATE",
              "DERMABASE": "DERMABASE",
              "VITAMINS A & D": "VITAMINS A & D"
            },
            "PERRIGO DIABETES CARE": {
              "LEADER FINGER CREAM": "LEADER FINGER CREAM"
            },
            "PERRIGO PHARMACEUTICALS": {
              "AMMONIUM LACTATE": "AMMONIUM LACTATE"
            },
            "PERSON COVEY": {
              "DML": "DML",
              "DML FORTE": "DML FORTE"
            },
            "PHARMACEUTICAL SPECIALITIES": {
              "VANICREAM": "VANICREAM",
              "VITEC": "VITEC"
            },
            "PHARMADERM": {
              "AFIRM 1X": "AFIRM 1X",
              "AFIRM 2X": "AFIRM 2X",
              "AFIRM 3X": "AFIRM 3X",
              "FORMULA 405 ENRICHED EYE": "FORMULA 405 ENRICHED EYE",
              "FORMULA 405 FACE": "FORMULA 405 FACE",
              "FORMULA 405 LIGHT MOISTURIZER": "FORMULA 405 LIGHT MOISTURIZER",
              "FORMULA 405 MOISTURIZING": "FORMULA 405 MOISTURIZING"
            },
            "PRINCETON RESEARCH": {
              "E-CREAM COMPLEX": "E-CREAM COMPLEX",
              "E-OIL": "E-OIL"
            },
            "PRUGEN": {
              "HPR PLUS-MB HYDROGEL": "HPR PLUS-MB HYDROGEL"
            },
            "RANBAXY LABORATORIES": {
              "LAC-HYDRIN": "LAC-HYDRIN",
              "LAC-HYDRIN FIVE": "LAC-HYDRIN FIVE",
              "LAC-HYDRIN TWELVE": "LAC-HYDRIN TWELVE"
            },
            "REDEX INDUSTRIES": {
              "UDDERLY SMOOTH": "UDDERLY SMOOTH",
              "UDDERLY SMOOTH EXTRA CARE": "UDDERLY SMOOTH EXTRA CARE",
              "UDDERLY SMOOTH EXTRA CARE 20": "UDDERLY SMOOTH EXTRA CARE 20"
            },
            "REESE PHARMACEUTICAL": {
              "DHEA": "DHEA",
              "VITAMIN E": "VITAMIN E"
            },
            "REXALL SUNDOWN": {
              "E-OIL": "E-OIL"
            },
            "RISING PHARMACEUTICALS": {
              "LACTIC ACID": "LACTIC ACID",
              "RISABAL-PH": "RISABAL-PH"
            },
            "RITE AID CORPORATION": {
              "RA ADVANCED HEALING": "RA ADVANCED HEALING",
              "RA ADVANCED RECOVERY": "RA ADVANCED RECOVERY",
              "RA BABY CARE VIT A&D TO GO": "RA BABY CARE VIT A&D TO GO",
              "RA CALMING DAILY MOISTURIZING": "RA CALMING DAILY MOISTURIZING",
              "RA DAYLOGIC HEALING DRY SKIN": "RA DAYLOGIC HEALING DRY SKIN",
              "RA DERMA": "RA DERMA",
              "RA GENTLE SKIN": "RA GENTLE SKIN",
              "RA GLYCERIN": "RA GLYCERIN",
              "RA HYDRATING HEALING": "RA HYDRATING HEALING",
              "RA MOISTURIZING OATMEAL": "RA MOISTURIZING OATMEAL",
              "RA MOISTURIZING THERAPY": "RA MOISTURIZING THERAPY",
              "RA RENEWAL COCOA BUTTER": "RA RENEWAL COCOA BUTTER",
              "RA RENEWAL DARK SPOT CORRECTOR": "RA RENEWAL DARK SPOT CORRECTOR",
              "RA RENEWAL DRY SKIN THERAPY": "RA RENEWAL DRY SKIN THERAPY",
              "RA RENEWAL ECZEMA MOISTURIZING": "RA RENEWAL ECZEMA MOISTURIZING",
              "RA RENEWAL MOISTURIZING": "RA RENEWAL MOISTURIZING",
              "RA RENEWAL SOOTHING BATH": "RA RENEWAL SOOTHING BATH",
              "RA SKIN TREATMENT": "RA SKIN TREATMENT",
              "RA TOTAL MOISTURE": "RA TOTAL MOISTURE",
              "RA VITAMIN A & D": "RA VITAMIN A & D",
              "RA VITAMIN E": "RA VITAMIN E",
              "RA VITAMIN E-VIT A & D": "RA VITAMIN E-VIT A & D",
              "RA VITAMINS A & D": "RA VITAMINS A & D"
            },
            "ROSEDALE THERAPEUTICS": {
              "PANTHODERM": "PANTHODERM"
            },
            "RUGBY LABORATORIES": {
              "DAILY MOISTURIZING": "DAILY MOISTURIZING",
              "MOISTURIZING CREAM": "MOISTURIZING CREAM"
            },
            "SANDOZ": {
              "AMLACTIN DAILY": "AMLACTIN DAILY"
            },
            "SHEFFIELD PHARMACEUTICALS": {
              "LADY ESTHER 4 PURPOSE FACE": "LADY ESTHER 4 PURPOSE FACE",
              "ROSE MILK": "ROSE MILK"
            },
            "SSS": {
              "MOTHERS FRIEND": "MOTHERS FRIEND"
            },
            "STEADMED MEDICAL": {
              "ELTA": "ELTA",
              "RESTA": "RESTA",
              "RESTA LITE": "RESTA LITE"
            },
            "STRATUS PHARMACEUTICALS": {
              "DROXY": "DROXY",
              "LACTIC ACID": "LACTIC ACID"
            },
            "SUMMERS LABS": {
              "CUTEMOL": "CUTEMOL",
              "DERMANAIL": "DERMANAIL",
              "TRIPLE CREAM": "TRIPLE CREAM"
            },
            "TARO": {
              "AMMONIUM LACTATE": "AMMONIUM LACTATE"
            },
            "TEVA SELECT BRANDS": {
              "AQUADERM TREATMENT/MOISTURIZER": "AQUADERM TREATMENT/MOISTURIZER"
            },
            "THE GORILLA GLUE COMPANY": {
              "OKEEFFES WORKING HANDS": "OKEEFFES WORKING HANDS"
            },
            "THE KEY COMPANY": {
              "ALPH-E-CREAM": "ALPH-E-CREAM"
            },
            "THE THERAPLEX COMPANY": {
              "THERAPLEX HYDROLOTION": "THERAPLEX HYDROLOTION"
            },
            "TOPCO": {
              "VITAMIN E": "VITAMIN E"
            },
            "TOPMD": {
              "CLN FACIAL MOISTURIZER NOURISH": "CLN FACIAL MOISTURIZER NOURISH"
            },
            "TYCHASIS": {
              "COATS ALOE": "COATS ALOE"
            },
            "UPSHER-SMITH": {
              "AMLACTIN": "AMLACTIN",
              "AMLACTIN CERAPEUTIC": "AMLACTIN CERAPEUTIC",
              "AMLACTIN ULTRA": "AMLACTIN ULTRA"
            },
            "VERMONT'S ORIGINAL": {
              "BAG BALM": "BAG BALM"
            },
            "WAL-MART": {
              "EQ THERAPEUTIC DRY SKIN": "EQ THERAPEUTIC DRY SKIN",
              "EQ VITAMINS A & D": "EQ VITAMINS A & D",
              "VITAMIN E SKIN": "VITAMIN E SKIN"
            },
            "WALGREENS": {
              "ADVANCED HEALING/BABY": "ADVANCED HEALING/BABY",
              "BABY VITAMIN A & D": "BABY VITAMIN A & D",
              "BEAUTY LOTION": "BEAUTY LOTION",
              "COCOA BUTTER": "COCOA BUTTER",
              "COCOA BUTTER PETROLEUM JELLY": "COCOA BUTTER PETROLEUM JELLY",
              "COMPLETE MOISTURE": "COMPLETE MOISTURE",
              "DRY SKIN TREATMENT": "DRY SKIN TREATMENT",
              "DRY SKIN TREATMENT ADV THERAPY": "DRY SKIN TREATMENT ADV THERAPY",
              "ECZEMA MOISTURIZING": "ECZEMA MOISTURIZING",
              "GEL LINED HEEL SLEEVES": "GEL LINED HEEL SLEEVES",
              "GEL LINED MOISTURIZING BOOTIES": "GEL LINED MOISTURIZING BOOTIES",
              "GEL LINED MOISTURIZING GLOVES": "GEL LINED MOISTURIZING GLOVES",
              "MOISTURE": "MOISTURE",
              "MOISTURE RECOVERY": "MOISTURE RECOVERY",
              "MOISTURIZING CREAM": "MOISTURIZING CREAM",
              "MOISTURIZING LOTION": "MOISTURIZING LOTION",
              "MOISTURIZING SENSITIVE SKIN": "MOISTURIZING SENSITIVE SKIN",
              "NATURAL OATMEAL BATH TREATMENT": "NATURAL OATMEAL BATH TREATMENT",
              "REFRESHING ALOE": "REFRESHING ALOE",
              "STUDIO 35 EXTRA MOISTURIZING": "STUDIO 35 EXTRA MOISTURIZING",
              "STUDIO 35 MOISTURIZING SKIN": "STUDIO 35 MOISTURIZING SKIN",
              "THERAPEUTIC MOISTURIZING": "THERAPEUTIC MOISTURIZING",
              "VITAMIN E": "VITAMIN E",
              "VITAMIN E SKIN": "VITAMIN E SKIN",
              "VITAMINS A & D": "VITAMINS A & D"
            },
            "WINDMILL CONSUMER PRODUCTS": {
              "SUPER E": "SUPER E",
              "SUPER E DAY": "SUPER E DAY",
              "SUPER E EYE": "SUPER E EYE",
              "SUPER E HAND/BODY": "SUPER E HAND/BODY",
              "SUPER E NIGHT": "SUPER E NIGHT",
              "THERABETIC SKIN CARE": "THERABETIC SKIN CARE"
            }
          }
        },
        "Enzymes - Topical": {
          "Enzymes - Topical": {
            "A-S MEDICATION SOLUTIONS": {
              "SANTYL": "SANTYL"
            },
            "API SOLUTIONS": {
              "COLLAGENASE": "COLLAGENASE"
            },
            "DELTA PHARMACEUTICALS": {
              "TBC": "TBC"
            },
            "FAGRON": {
              "COLLAGENASE": "COLLAGENASE"
            },
            "FREEDOM PHARMACEUTICALS": {
              "COLLAGENASE": "COLLAGENASE"
            },
            "MYLAN INSTITUTIONAL": {
              "GRANULEX": "GRANULEX"
            },
            "PCCA": {
              "COLLAGENASE": "COLLAGENASE"
            },
            "SMITH & NEPHEW BIOTHERAPEUTICS": {
              "SANTYL": "SANTYL"
            },
            "SMITH & NEPHEW UNITED": {
              "SANTYL": "SANTYL"
            }
          }
        },
        "Glabellar Lines (Frown Lines) Agents": {
          "Glabellar Lines (Frown Lines) Agents": {
            "ALLERGAN": {
              "BOTOX COSMETIC": "BOTOX COSMETIC"
            }
          }
        },
        "Hair Growth Agents": {
          "Hair Growth Agent - Combinations": {
            "SINCERUS FLORIDA": {
              "BETAMETHASONE DIPROP-MINOXIDIL": "BETAMETHASONE DIPROP-MINOXIDIL",
              "FINASTERIDE-MINOXIDIL": "FINASTERIDE-MINOXIDIL",
              "MINOXIDIL-PROGEST-TRETINOIN": "MINOXIDIL-PROGEST-TRETINOIN",
              "PROGESTERONE-MINOXIDIL": "PROGESTERONE-MINOXIDIL"
            }
          },
          "Prostaglandins - Topical": {
            "A-S MEDICATION SOLUTIONS": {
              "LATISSE": "LATISSE"
            },
            "ALLERGAN": {
              "LATISSE": "LATISSE"
            },
            "SANDOZ": {
              "BIMATOPROST": "BIMATOPROST"
            }
          },
          "Type II 5-Alpha Reductase Inhibitors": {
            "A-S MEDICATION SOLUTIONS": {
              "FINASTERIDE": "FINASTERIDE",
              "PROPECIA": "PROPECIA"
            },
            "ACCORD HEALTHCARE": {
              "FINASTERIDE": "FINASTERIDE"
            },
            "ACTAVIS": {
              "FINASTERIDE": "FINASTERIDE"
            },
            "AMERICAN HEALTH PACKAGING": {
              "FINASTERIDE": "FINASTERIDE"
            },
            "ASCEND LABORATORIES": {
              "FINASTERIDE": "FINASTERIDE"
            },
            "AUROBINDO PHARMA": {
              "FINASTERIDE": "FINASTERIDE"
            },
            "BRYANT RANCH PREPACK": {
              "FINASTERIDE": "FINASTERIDE",
              "PROPECIA": "PROPECIA"
            },
            "BUREL PHARMACEUTICALS": {
              "FINASTERIDE": "FINASTERIDE"
            },
            "CAMBER PHARMACEUTICALS": {
              "FINASTERIDE": "FINASTERIDE"
            },
            "CIPLA USA": {
              "FINASTERIDE": "FINASTERIDE"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "FINASTERIDE": "FINASTERIDE"
            },
            "MERCK SHARP & DOHME": {
              "PROPECIA": "PROPECIA"
            },
            "MYLAN": {
              "FINASTERIDE": "FINASTERIDE"
            },
            "PREFERRED PHARMACEUTICALS": {
              "FINASTERIDE": "FINASTERIDE"
            },
            "PROFICIENT RX": {
              "FINASTERIDE": "FINASTERIDE"
            },
            "QUALITY CARE": {
              "PROPECIA": "PROPECIA"
            },
            "RISING PHARMACEUTICALS": {
              "FINASTERIDE": "FINASTERIDE"
            },
            "RXCHANGE CO": {
              "PROPECIA": "PROPECIA"
            },
            "SUN PHARMACEUTICAL": {
              "FINASTERIDE": "FINASTERIDE"
            }
          },
          "Vascular Agents": {
            "ACTAVIS MID ATLANTIC": {
              "MINOXIDIL FOR MEN": "MINOXIDIL FOR MEN"
            },
            "CHAIN DRUG CONSORTIUM": {
              "MINOXIDIL FOR MEN": "MINOXIDIL FOR MEN",
              "MINOXIDIL FOR WOMEN": "MINOXIDIL FOR WOMEN"
            },
            "COSTCO WHOLESALE": {
              "KLS MINOXIDIL": "KLS MINOXIDIL"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS HAIR REGROWTH FOR MEN": "CVS HAIR REGROWTH FOR MEN",
              "CVS HAIR REGROWTH FOR WOMEN": "CVS HAIR REGROWTH FOR WOMEN",
              "CVS HAIR REGROWTH WOMENS": "CVS HAIR REGROWTH WOMENS"
            },
            "EQUALINE": {
              "EQL HAIR REGROWTH": "EQL HAIR REGROWTH",
              "EQL HAIR REGROWTH FOR MEN": "EQL HAIR REGROWTH FOR MEN",
              "EQL HAIR REGROWTH FOR WOMEN": "EQL HAIR REGROWTH FOR WOMEN"
            },
            "HEALTHGUARD": {
              "MINOXIDIL FOR MEN": "MINOXIDIL FOR MEN"
            },
            "J & J CONSUMER PRODUCTS": {
              "ROGAINE WOMENS": "ROGAINE WOMENS"
            },
            "JOHNSON&JOHNSON HEALTHCARE": {
              "ROGAINE": "ROGAINE",
              "ROGAINE EXTRA STRENGTH FOR MEN": "ROGAINE EXTRA STRENGTH FOR MEN",
              "ROGAINE MENS": "ROGAINE MENS",
              "ROGAINE MENS EXTRA STRENGTH": "ROGAINE MENS EXTRA STRENGTH",
              "ROGAINE WOMENS": "ROGAINE WOMENS"
            },
            "LEADER BRAND PRODUCTS": {
              "HAIR REGROWTH TREATMENT MEN": "HAIR REGROWTH TREATMENT MEN"
            },
            "PERRIGO PHARMACEUTICALS": {
              "HAIR REGROWTH TREATMENT MEN": "HAIR REGROWTH TREATMENT MEN"
            },
            "PUBLIX SUPER MARKETS INC.": {
              "PX MINOXIDIL FOR MEN": "PX MINOXIDIL FOR MEN",
              "PX MINOXIDIL FOR WOMEN": "PX MINOXIDIL FOR WOMEN"
            },
            "RITE AID CORPORATION": {
              "RA DAYLOGIC HAIR REGROWTH MEN": "RA DAYLOGIC HAIR REGROWTH MEN",
              "RA HAIR REGROWTH EX ST FOR MEN": "RA HAIR REGROWTH EX ST FOR MEN",
              "RA HAIR REGROWTH FOR WOMEN": "RA HAIR REGROWTH FOR WOMEN",
              "RA RENEWAL FOR MEN": "RA RENEWAL FOR MEN"
            },
            "TARGET": {
              "TGT HAIR REGROWTH FOR WOMEN": "TGT HAIR REGROWTH FOR WOMEN",
              "TGT MINOXIDIL HAIR REGROWTH": "TGT MINOXIDIL HAIR REGROWTH"
            },
            "TARO": {
              "MINOXIDIL FOR MEN": "MINOXIDIL FOR MEN"
            },
            "WALGREENS": {
              "MINOXIDIL FOR MEN": "MINOXIDIL FOR MEN",
              "MINOXIDIL FOR WOMEN": "MINOXIDIL FOR WOMEN",
              "MINOXIDIL HAIR REGROWTH/MEN": "MINOXIDIL HAIR REGROWTH/MEN"
            }
          }
        },
        "Hair Reduction Agents": {
          "Ornithine Decarboxylase (ODC) Inhibitors - Topical": {
            "ALLERGAN": {
              "VANIQA": "VANIQA"
            }
          }
        },
        "Immunomodulating Agents - Topical": {
          "Immunomodulators Imidazoquinolinamines - Topical": {
            "A-S MEDICATION SOLUTIONS": {
              "ALDARA": "ALDARA"
            },
            "APOTEX": {
              "IMIQUIMOD": "IMIQUIMOD"
            },
            "BAUSCH HEALTH": {
              "ALDARA": "ALDARA",
              "ZYCLARA": "ZYCLARA",
              "ZYCLARA PUMP": "ZYCLARA PUMP"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "IMIQUIMOD": "IMIQUIMOD"
            },
            "DIRECT RX": {
              "IMIQUIMOD": "IMIQUIMOD"
            },
            "FOUGERA": {
              "IMIQUIMOD": "IMIQUIMOD"
            },
            "GLENMARK PHARMACEUTICALS": {
              "IMIQUIMOD": "IMIQUIMOD"
            },
            "IMPAX GENERICS": {
              "IMIQUIMOD": "IMIQUIMOD"
            },
            "OCEANSIDE PHARMACEUTICALS": {
              "IMIQUIMOD PUMP": "IMIQUIMOD PUMP"
            },
            "PERRIGO PHARMACEUTICALS": {
              "IMIQUIMOD": "IMIQUIMOD"
            },
            "STRIDES PHARMA": {
              "IMIQUIMOD": "IMIQUIMOD"
            },
            "TARO": {
              "IMIQUIMOD": "IMIQUIMOD"
            },
            "TEVA PHARMACEUTICALS USA": {
              "IMIQUIMOD PUMP": "IMIQUIMOD PUMP"
            }
          }
        },
        "Immunosuppressive Agents - Topical": {
          "Immunosuppressive Agents - Topical Combinations": {
            "SINCERUS FLORIDA": {
              "HYALURONATE-NIACIN-TACROLIMUS": "HYALURONATE-NIACIN-TACROLIMUS",
              "NIACINAMIDE-TACROLIMUS": "NIACINAMIDE-TACROLIMUS"
            }
          },
          "Macrolide Immunosuppressants - Topical": {
            "A-S MEDICATION SOLUTIONS": {
              "ELIDEL": "ELIDEL"
            },
            "ACCORD HEALTHCARE": {
              "TACROLIMUS": "TACROLIMUS"
            },
            "ACTAVIS PHARMA": {
              "PIMECROLIMUS": "PIMECROLIMUS"
            },
            "BAUSCH HEALTH": {
              "ELIDEL": "ELIDEL"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "ELIDEL": "ELIDEL",
              "PROTOPIC": "PROTOPIC"
            },
            "GLENMARK PHARMACEUTICALS": {
              "PIMECROLIMUS": "PIMECROLIMUS",
              "TACROLIMUS": "TACROLIMUS"
            },
            "LEO PHARMA INC": {
              "PROTOPIC": "PROTOPIC"
            },
            "OCEANSIDE PHARMACEUTICALS": {
              "PIMECROLIMUS": "PIMECROLIMUS"
            },
            "PERRIGO PHARMACEUTICALS": {
              "TACROLIMUS": "TACROLIMUS"
            },
            "QUALITY CARE": {
              "ELIDEL": "ELIDEL",
              "PROTOPIC": "PROTOPIC"
            },
            "SANDOZ": {
              "TACROLIMUS": "TACROLIMUS"
            },
            "SINCERUS FLORIDA": {
              "TACROLIMUS": "TACROLIMUS"
            }
          }
        },
        "Keratolytic/Antimitotic Agents": {
          "Keratolytic and/or Antimitotic Combinations": {
            "ACCUMIX PHARMACEUTICALS": {
              "SUPRACIL": "SUPRACIL",
              "VERRUNEX": "VERRUNEX"
            },
            "ADVANTICE HEALTH": {
              "KERASAL": "KERASAL"
            },
            "CHAIN DRUG CONSORTIUM": {
              "EXFOLIATING MOISTURIZER": "EXFOLIATING MOISTURIZER"
            },
            "EPI HEALTH": {
              "BENSAL HP": "BENSAL HP"
            },
            "EXELTIS USA DERMATOLOGY": {
              "SALVAX DUO PLUS": "SALVAX DUO PLUS"
            },
            "FAGRON": {
              "JESSNERS": "JESSNERS"
            },
            "GORDON": {
              "GORDOFILM": "GORDOFILM",
              "PYROGALLIC ACID": "PYROGALLIC ACID"
            },
            "MEDTECH": {
              "COMPOUND W 2-IN-1 TREATMENT": "COMPOUND W 2-IN-1 TREATMENT",
              "COMPOUND W FREEZE OFF": "COMPOUND W FREEZE OFF",
              "COMPOUND W FREEZE OFF PLANTAR": "COMPOUND W FREEZE OFF PLANTAR"
            },
            "RANBAXY LABORATORIES": {
              "PERNOX": "PERNOX"
            },
            "RITE AID CORPORATION": {
              "RA EXFOLIATING MOISTURIZER": "RA EXFOLIATING MOISTURIZER"
            },
            "SINCERUS FLORIDA": {
              "CIMETIDINE-LIDO-SALICYLIC ACID": "CIMETIDINE-LIDO-SALICYLIC ACID"
            },
            "WAL-MART": {
              "EQ CRYOGENIC WART REMOVAL SYS": "EQ CRYOGENIC WART REMOVAL SYS"
            },
            "WALGREENS": {
              "EXFOLIATING MOISTURIZER": "EXFOLIATING MOISTURIZER"
            }
          },
          "Keratolytic/Antimitotic Agents": {
            "ACELLA PHARMACEUTICALS": {
              "SALICYLIC ACID": "SALICYLIC ACID",
              "SALICYLIC ACID WART REMOVER": "SALICYLIC ACID WART REMOVER"
            },
            "ACTAVIS PHARMA": {
              "CONDYLOX": "CONDYLOX",
              "PODOFILOX": "PODOFILOX"
            },
            "ALLERGAN": {
              "CONDYLOX": "CONDYLOX"
            },
            "ALVA-AMCO": {
              "PSORIASIN": "PSORIASIN"
            },
            "AMERICAN PHARMA INGREDIENTS": {
              "CANTHARIDIN": "CANTHARIDIN"
            },
            "AMERICAN SALES COMPANY": {
              "ACNE MAXIMUM STRENGTH": "ACNE MAXIMUM STRENGTH",
              "ACNE WASH": "ACNE WASH",
              "CALLUS REMOVERS": "CALLUS REMOVERS",
              "CORN REMOVERS": "CORN REMOVERS",
              "LIQUID CORN & CALLUS REMOVER": "LIQUID CORN & CALLUS REMOVER",
              "ONE-STEP WART REMOVER": "ONE-STEP WART REMOVER"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP WART REMOVER": "GNP WART REMOVER"
            },
            "ARTESA LABS": {
              "XALIX": "XALIX"
            },
            "BAKER CUMMINS DERMATOLOGICALS": {
              "P & S": "P & S"
            },
            "BALASSA LABORATORIES": {
              "WARTSTICK": "WARTSTICK"
            },
            "BAUSCH HEALTH": {
              "AMBI EVEN & CLEAR CLEANSER": "AMBI EVEN & CLEAR CLEANSER",
              "AMBI EVEN & CLEAR WASH": "AMBI EVEN & CLEAR WASH",
              "SAL-PLANT": "SAL-PLANT",
              "SALACTIC FILM": "SALACTIC FILM",
              "SALEX": "SALEX"
            },
            "BAYER CONSUMER": {
              "CLEAR AWAY": "CLEAR AWAY",
              "CLEAR AWAY 1-STEP WART REMOVER": "CLEAR AWAY 1-STEP WART REMOVER",
              "CLEAR AWAY PLANTAR SYSTEM": "CLEAR AWAY PLANTAR SYSTEM",
              "CLEAR AWAY WART REMOVER SYSTEM": "CLEAR AWAY WART REMOVER SYSTEM",
              "CORN REMOVER ONE STEP": "CORN REMOVER ONE STEP",
              "CORN REMOVER ULTRA THIN": "CORN REMOVER ULTRA THIN",
              "CORN REMOVER WATERPROOF": "CORN REMOVER WATERPROOF",
              "DR SCHOLLS CALLUS REM/DURAGEL": "DR SCHOLLS CALLUS REM/DURAGEL",
              "DUOFILM": "DUOFILM",
              "ONE STEP CALLUS REMOVER": "ONE STEP CALLUS REMOVER"
            },
            "BEIERSDORF DERMA": {
              "MEDIPLAST": "MEDIPLAST"
            },
            "BERGEN BRUNSWIG": {
              "GNP CORN REMOVERS": "GNP CORN REMOVERS",
              "GNP SCALP RELIEF": "GNP SCALP RELIEF",
              "GNP WART REMOVER": "GNP WART REMOVER"
            },
            "BETA DERMS": {
              "BETASAL": "BETASAL"
            },
            "BLISTEX": {
              "STRI-DEX MAXIMUM STRENGTH": "STRI-DEX MAXIMUM STRENGTH",
              "STRI-DEX SENSITIVE SKIN": "STRI-DEX SENSITIVE SKIN",
              "STRIDEX ESSENTIAL": "STRIDEX ESSENTIAL"
            },
            "CHAIN DRUG CONSORTIUM": {
              "ACNE PADS MAXIMUM STRENGTH": "ACNE PADS MAXIMUM STRENGTH",
              "CALLUS REMOVERS": "CALLUS REMOVERS",
              "CORN REMOVERS": "CORN REMOVERS",
              "CORN/CALLUS REMOVER": "CORN/CALLUS REMOVER",
              "DAILY CLEANSING MAX ST": "DAILY CLEANSING MAX ST",
              "PLANTAR WART REMOVER": "PLANTAR WART REMOVER",
              "SCALP RELIEF": "SCALP RELIEF",
              "WART REMOVER": "WART REMOVER",
              "WART REMOVER MAXIMUM STRENGTH": "WART REMOVER MAXIMUM STRENGTH"
            },
            "CHATTEM INC": {
              "GOLD BOND PSORIASIS RELIEF": "GOLD BOND PSORIASIS RELIEF",
              "SELSUN BLUE 3-IN-1 TREATMENT": "SELSUN BLUE 3-IN-1 TREATMENT",
              "SELSUN BLUE DEEP CLEANSING": "SELSUN BLUE DEEP CLEANSING",
              "SELSUN BLUE NATURALS": "SELSUN BLUE NATURALS",
              "SELSUN BLUE NATURALS DRY SCALP": "SELSUN BLUE NATURALS DRY SCALP"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "SALICYLIC ACID": "SALICYLIC ACID"
            },
            "COMBE": {
              "SCALPICIN": "SCALPICIN"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS ACNE SPOT TREATMENT": "CVS ACNE SPOT TREATMENT",
              "CVS ADV ACNE SPOT TREATMENT": "CVS ADV ACNE SPOT TREATMENT",
              "CVS ADVANCED ACNE SPOT TREAT": "CVS ADVANCED ACNE SPOT TREAT",
              "CVS CORN REMOVER": "CVS CORN REMOVER",
              "CVS CORN REMOVERS": "CVS CORN REMOVERS",
              "CVS CORN/CALLUS REMOVER": "CVS CORN/CALLUS REMOVER",
              "CVS DAILY ACNE WASH": "CVS DAILY ACNE WASH",
              "CVS MEDICATED SPOT": "CVS MEDICATED SPOT",
              "CVS ONE STEP WART REMOVER": "CVS ONE STEP WART REMOVER",
              "CVS PLANTAR WART REMOVER": "CVS PLANTAR WART REMOVER",
              "CVS PSORIASIS MEDICATED": "CVS PSORIASIS MEDICATED",
              "CVS THERAPEUTIC DANDRUFF": "CVS THERAPEUTIC DANDRUFF",
              "CVS THERAPEUTIC MAX ST": "CVS THERAPEUTIC MAX ST",
              "CVS WART REMOVER": "CVS WART REMOVER",
              "CVS WART REMOVER ONE STEP": "CVS WART REMOVER ONE STEP",
              "CVS WART REMOVER PEN": "CVS WART REMOVER PEN"
            },
            "ELORAC": {
              "ULTRASAL-ER": "ULTRASAL-ER",
              "VIRASAL": "VIRASAL"
            },
            "EQUALINE": {
              "EQL ACNE MAXIMUM STRENGTH": "EQL ACNE MAXIMUM STRENGTH",
              "EQL ACNE SCRUB PINK GRAPEFRUIT": "EQL ACNE SCRUB PINK GRAPEFRUIT",
              "EQL APRICOT SCRUB": "EQL APRICOT SCRUB",
              "EQL CALLUS REMOVER EXTRA THICK": "EQL CALLUS REMOVER EXTRA THICK",
              "EQL SCALP RELIEF MAX STRENGTH": "EQL SCALP RELIEF MAX STRENGTH"
            },
            "EXACT-RX": {
              "SALICYLIC ACID": "SALICYLIC ACID",
              "SALICYLIC ACID-CLEANSER": "SALICYLIC ACID-CLEANSER"
            },
            "EXELTIS USA DERMATOLOGY": {
              "SALVAX": "SALVAX"
            },
            "FAGRON": {
              "PODOPHYLLUM RESIN": "PODOPHYLLUM RESIN"
            },
            "INSIGHT PHARMACEUTICALS": {
              "DERMAREST PSORIASIS": "DERMAREST PSORIASIS"
            },
            "J & J CONSUMER PRODUCTS": {
              "CLEAN & CLEAR ACNE CLAY MASK": "CLEAN & CLEAR ACNE CLAY MASK",
              "CLEAN & CLEAR ACNE CLEANSER": "CLEAN & CLEAR ACNE CLEANSER",
              "CLEAN & CLEAR ACNE SCRUB": "CLEAN & CLEAR ACNE SCRUB",
              "CLEAN & CLEAR ACNE TREATMENT": "CLEAN & CLEAR ACNE TREATMENT",
              "CLEAN & CLEAR ADVANTAGE 3-IN-1": "CLEAN & CLEAR ADVANTAGE 3-IN-1",
              "CLEAN & CLEAR ADVANTAGE ACNE": "CLEAN & CLEAR ADVANTAGE ACNE",
              "CLEAN & CLEAR ADVANTAGE MARK": "CLEAN & CLEAR ADVANTAGE MARK",
              "CLEAN & CLEAR ADVANTAGE SCRUB": "CLEAN & CLEAR ADVANTAGE SCRUB",
              "CLEAN & CLEAR BLACKHEAD ERASER": "CLEAN & CLEAR BLACKHEAD ERASER",
              "CLEAN & CLEAR DEEP CLEANING": "CLEAN & CLEAR DEEP CLEANING",
              "CLEAN & CLEAR DUAL ACTION": "CLEAN & CLEAR DUAL ACTION",
              "CLEAN & CLEAR OIL ABSORBING": "CLEAN & CLEAR OIL ABSORBING"
            },
            "KMM PHARMACEUTICALS": {
              "SALICYLIC ACID": "SALICYLIC ACID",
              "SALICYLIC ACID ER": "SALICYLIC ACID ER",
              "SALICYLIC ACID WART REMOVER": "SALICYLIC ACID WART REMOVER"
            },
            "L'OREAL": {
              "CERAVE PSORIASIS": "CERAVE PSORIASIS"
            },
            "LAKE CONSUMER PRODUCTS": {
              "MG217 PSORIASIS MULTI-SYMPTOM": "MG217 PSORIASIS MULTI-SYMPTOM"
            },
            "LEADER BRAND PRODUCTS": {
              "CORN & CALLUS REMOVER": "CORN & CALLUS REMOVER",
              "CORN/CALLUS REMOVER": "CORN/CALLUS REMOVER",
              "MEDICATED CALLUS REMOVERS": "MEDICATED CALLUS REMOVERS",
              "MEDICATED CORN REMOVERS": "MEDICATED CORN REMOVERS",
              "THERAPEUTIC DANDRUFF": "THERAPEUTIC DANDRUFF",
              "WART REMOVER MAXIMUM STRENGTH": "WART REMOVER MAXIMUM STRENGTH"
            },
            "LEITERS HEALTH": {
              "CANTHARIDIN": "CANTHARIDIN"
            },
            "LETCO MEDICAL": {
              "CANTHARIDIN": "CANTHARIDIN",
              "PODOPHYLLUM RESIN": "PODOPHYLLUM RESIN"
            },
            "MCKESSON SUNMARK": {
              "SM MEDICATED CORN REMOVERS": "SM MEDICATED CORN REMOVERS"
            },
            "MEDICIS": {
              "SALAC": "SALAC"
            },
            "MEDISCA": {
              "CANTHARIDIN": "CANTHARIDIN",
              "PODOPHYLLUM RESIN": "PODOPHYLLUM RESIN"
            },
            "MEDLINE/DERMAL MANAGEMENT": {
              "MEDIPLAST": "MEDIPLAST"
            },
            "MEDTECH": {
              "COMPOUND W": "COMPOUND W",
              "COMPOUND W COMPLETE": "COMPOUND W COMPLETE",
              "COMPOUND W FOR KIDS": "COMPOUND W FOR KIDS",
              "COMPOUND W FREEZE OFF ADVANCED": "COMPOUND W FREEZE OFF ADVANCED",
              "COMPOUND W MAXIMUM STRENGTH": "COMPOUND W MAXIMUM STRENGTH",
              "COMPOUND W ONE STEP": "COMPOUND W ONE STEP",
              "COMPOUND W ONE STEP INVISIBLE": "COMPOUND W ONE STEP INVISIBLE",
              "FREEZONE": "FREEZONE",
              "FREEZONE CALLUS REMOVER": "FREEZONE CALLUS REMOVER",
              "MOSCO CALLUS/CORN REMOVER": "MOSCO CALLUS/CORN REMOVER",
              "MOSCO CORN REMOVER MAX STR": "MOSCO CORN REMOVER MAX STR"
            },
            "MESORA PHARMA": {
              "SALIMEZ": "SALIMEZ",
              "SALIMEZ FORTE": "SALIMEZ FORTE"
            },
            "NATIONWIDE LABORATORIES": {
              "SALICYLIC ACID": "SALICYLIC ACID"
            },
            "NEOSTRATA": {
              "EXUVIANCE BLEMISH TREATMENT": "EXUVIANCE BLEMISH TREATMENT"
            },
            "NEUTROGENA": {
              "NEUTROGENA BODY CLEAR WASH": "NEUTROGENA BODY CLEAR WASH",
              "NEUTROGENA OIL-FREE ACNE WASH": "NEUTROGENA OIL-FREE ACNE WASH",
              "NEUTROGENA RAPID CLEAR": "NEUTROGENA RAPID CLEAR",
              "NEUTROGENA T/SAL": "NEUTROGENA T/SAL"
            },
            "OAKHURST": {
              "GETS-IT CORN/CALLUS REMOVER": "GETS-IT CORN/CALLUS REMOVER"
            },
            "OCEANSIDE PHARMACEUTICALS": {
              "PODOFILOX": "PODOFILOX"
            },
            "ONTOS": {
              "NOBLE FORMULA S": "NOBLE FORMULA S"
            },
            "PCCA": {
              "CANTHARIDIN": "CANTHARIDIN",
              "PODOPHYLLUM RESIN": "PODOPHYLLUM RESIN"
            },
            "PERRIGO": {
              "PODOCON": "PODOCON",
              "PODOFILOX": "PODOFILOX"
            },
            "PERRIGO PHARMACEUTICALS": {
              "SALICYLIC ACID": "SALICYLIC ACID"
            },
            "PRUGEN": {
              "SALICYLIC ACID": "SALICYLIC ACID",
              "SALICYLIC ACID-CLEANSER": "SALICYLIC ACID-CLEANSER"
            },
            "QUALITY CARE": {
              "DUOFILM": "DUOFILM",
              "SALICYLIC ACID": "SALICYLIC ACID"
            },
            "RECKITT BENCKISER": {
              "CLEARASIL DAILY CLEAN": "CLEARASIL DAILY CLEAN",
              "CLEARASIL RAPID RESCUE DEEP": "CLEARASIL RAPID RESCUE DEEP",
              "SCALPICIN 2 IN 1": "SCALPICIN 2 IN 1"
            },
            "RITE AID CORPORATION": {
              "RA ACNE CLEANSER OIL-FREE": "RA ACNE CLEANSER OIL-FREE",
              "RA APRICOT SCRUB": "RA APRICOT SCRUB",
              "RA CORN REMOVERS ULTRA THIN": "RA CORN REMOVERS ULTRA THIN",
              "RA ONE-STEP CORN REMOVER": "RA ONE-STEP CORN REMOVER",
              "RA SCALP ITCH/DANDRUFF RELIEF": "RA SCALP ITCH/DANDRUFF RELIEF",
              "RA WART REMOVER": "RA WART REMOVER",
              "RA WART REMOVER MAX STRENGTH": "RA WART REMOVER MAX STRENGTH"
            },
            "ROCHESTER PHARMACEUTICALS": {
              "SALICYLIC ACID": "SALICYLIC ACID",
              "SALICYLIC ACID WART REMOVER": "SALICYLIC ACID WART REMOVER"
            },
            "SCIVOLUTIONS MEDICAL": {
              "DRS CHOICE CORN/CALLUS REMOVER": "DRS CHOICE CORN/CALLUS REMOVER"
            },
            "SETON PHARMACEUTICALS": {
              "SALICYLIC ACID-CLEANSER": "SALICYLIC ACID-CLEANSER"
            },
            "SOLUTECH PHARMACEUTICALS": {
              "SALICYLIC ACID": "SALICYLIC ACID",
              "SALISOL": "SALISOL",
              "SALISOL FORTE": "SALISOL FORTE",
              "SALITECH": "SALITECH",
              "SALITECH FORTE": "SALITECH FORTE"
            },
            "SPECTRUM": {
              "PODOPHYLLUM RESIN": "PODOPHYLLUM RESIN"
            },
            "STRATUS PHARMACEUTICALS": {
              "SALACYN": "SALACYN"
            },
            "SUMMERS LABS": {
              "KERALYT": "KERALYT",
              "KERALYT 5": "KERALYT 5",
              "KERALYT SCALP": "KERALYT SCALP"
            },
            "TARGET": {
              "TGT WART REMOVER": "TGT WART REMOVER"
            },
            "TOPCO": {
              "WART REMOVER MAXIMUM STRENGTH": "WART REMOVER MAXIMUM STRENGTH"
            },
            "TOPMD": {
              "CLN ACNE CLEANSER": "CLN ACNE CLEANSER"
            },
            "TRINITY PHARMACEUTICALS": {
              "SALICYLIC ACID": "SALICYLIC ACID"
            },
            "TRITON CONSUMER PRODUCTS": {
              "MG217 SAL-ACID": "MG217 SAL-ACID"
            },
            "ULTIMARK PRODUCTS": {
              "DENOREX EXTRA STRENGTH 2-IN-1": "DENOREX EXTRA STRENGTH 2-IN-1"
            },
            "WAL-MART": {
              "EQ WART REMOVER": "EQ WART REMOVER"
            },
            "WALGREENS": {
              "ACNE": "ACNE",
              "CALLUS REMOVERS": "CALLUS REMOVERS",
              "CALLUS REMOVERS EXTRA THICK": "CALLUS REMOVERS EXTRA THICK",
              "CORN REMOVER ONE-STEP": "CORN REMOVER ONE-STEP",
              "DAILY FACE WASH": "DAILY FACE WASH",
              "GEL CALLUS REMOVERS": "GEL CALLUS REMOVERS",
              "LIQUID CORN & CALLUS REMOVER": "LIQUID CORN & CALLUS REMOVER",
              "LIQUID WART REMOVER": "LIQUID WART REMOVER",
              "MEDICATED WART REMOVERS": "MEDICATED WART REMOVERS",
              "THERAPEUTIC T+PLUS MAX ST": "THERAPEUTIC T+PLUS MAX ST",
              "ULTRA THIN CORN REMOVERS": "ULTRA THIN CORN REMOVERS",
              "WART REMOVER": "WART REMOVER",
              "WART REMOVER MAXIMUM STRENGTH": "WART REMOVER MAXIMUM STRENGTH",
              "WART REMOVER MEDICATED": "WART REMOVER MEDICATED"
            }
          }
        },
        "Liniments": {
          "Liniment Combinations": {
            "1ST CLASS PHARMACEUTICALS": {
              "CAMPHOMEX": "CAMPHOMEX"
            },
            "7T PHARMA": {
              "HISTAMINE DHCL & MENTHOL": "HISTAMINE DHCL & MENTHOL"
            },
            "A-S MEDICATION SOLUTIONS": {
              "DENDRACIN NEURODENDRAXCIN": "DENDRACIN NEURODENDRAXCIN",
              "ICY HOT EXTRA STRENGTH": "ICY HOT EXTRA STRENGTH",
              "MEDI-DERM": "MEDI-DERM",
              "NEW TEROCIN": "NEW TEROCIN",
              "THERA-GESIC": "THERA-GESIC"
            },
            "AIDAREX PHARMACEUTICALS": {
              "DENDRACIN NEURODENDRAXCIN": "DENDRACIN NEURODENDRAXCIN",
              "MEDROX": "MEDROX",
              "NEW TEROCIN": "NEW TEROCIN"
            },
            "ALEXSO": {
              "LIMENCIN": "LIMENCIN",
              "MENTHOL-CAMPHOR": "MENTHOL-CAMPHOR",
              "NEW TEROCIN": "NEW TEROCIN"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP MUSCLE RUB ULTRA STRENGTH": "GNP MUSCLE RUB ULTRA STRENGTH"
            },
            "BERGEN BRUNSWIG": {
              "GNP COLD & HOT EXTRA STRENGTH": "GNP COLD & HOT EXTRA STRENGTH",
              "GNP MUSCLE RUB": "GNP MUSCLE RUB",
              "GNP MUSCLE RUB ULTRA STRENGTH": "GNP MUSCLE RUB ULTRA STRENGTH"
            },
            "BRYANT RANCH PREPACK": {
              "DENDRACIN NEURODENDRAXCIN": "DENDRACIN NEURODENDRAXCIN",
              "MEDROX": "MEDROX"
            },
            "CAL PHARMA": {
              "ULTRACIN": "ULTRACIN"
            },
            "CHAIN DRUG CONSORTIUM": {
              "COLD & HOT BALM EXTRA STRENGTH": "COLD & HOT BALM EXTRA STRENGTH",
              "MUSCLE RUB": "MUSCLE RUB",
              "MUSCLE RUB ULTRA STRENGTH": "MUSCLE RUB ULTRA STRENGTH",
              "SPORTS RUB INTENSE STRENGTH": "SPORTS RUB INTENSE STRENGTH"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC RELIEF PATCH": "QC RELIEF PATCH"
            },
            "CHATTEM INC": {
              "ARTHRITIS HOT": "ARTHRITIS HOT",
              "FLEXALL ULTRA PLUS": "FLEXALL ULTRA PLUS",
              "ICY HOT ADVANCED RELIEF": "ICY HOT ADVANCED RELIEF",
              "ICY HOT ARTHRITIS PAIN RELIEF": "ICY HOT ARTHRITIS PAIN RELIEF",
              "ICY HOT BALM EXTRA STRENGTH": "ICY HOT BALM EXTRA STRENGTH",
              "ICY HOT EXTRA STRENGTH": "ICY HOT EXTRA STRENGTH"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "DENDRACIN NEURODENDRAXCIN": "DENDRACIN NEURODENDRAXCIN",
              "NEW TEROCIN": "NEW TEROCIN"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS COLD & HOT PAIN RELIEVING": "CVS COLD & HOT PAIN RELIEVING",
              "CVS MUSCLE RUB": "CVS MUSCLE RUB",
              "CVS MUSCLE RUB ULTRA STRENGTH": "CVS MUSCLE RUB ULTRA STRENGTH",
              "CVS PAIN-RELIEVING": "CVS PAIN-RELIEVING"
            },
            "DIRECT RX": {
              "DENDRACIN NEURODENDRAXCIN": "DENDRACIN NEURODENDRAXCIN",
              "NEW TEROCIN": "NEW TEROCIN"
            },
            "ENOVACHEM MANUFACTURING": {
              "CALYPXO": "CALYPXO",
              "EXOTEN-C PAIN RELIEF": "EXOTEN-C PAIN RELIEF"
            },
            "EQUALINE": {
              "EQL COOL HEAT EXTRA STRENGTH": "EQL COOL HEAT EXTRA STRENGTH",
              "EQL MUSCLE RUB PAIN RELIEVING": "EQL MUSCLE RUB PAIN RELIEVING"
            },
            "FAMILY FIRST PHARMACEUTICAL": {
              "ACTIVON ARTHRITIS ULTRA ST": "ACTIVON ARTHRITIS ULTRA ST"
            },
            "GEISS DESTIN & DUNN": {
              "GOODSENSE MUSCLE RUB": "GOODSENSE MUSCLE RUB"
            },
            "GERITREX CORPORATION": {
              "BERRI-FREEZ PLUS": "BERRI-FREEZ PLUS",
              "GRX ANALGESIC BALM": "GRX ANALGESIC BALM",
              "KERA TEK": "KERA TEK"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "DENDRACIN NEURODENDRAXCIN": "DENDRACIN NEURODENDRAXCIN",
              "ICY HOT": "ICY HOT",
              "MEDROX": "MEDROX",
              "THERA-GESIC": "THERA-GESIC"
            },
            "HANNAFORD BROTHERS": {
              "PAIN RELIEVING RUB": "PAIN RELIEVING RUB"
            },
            "HISAMITSU AMERICA": {
              "SALONPAS": "SALONPAS",
              "SALONPAS DEEP RELIEVING": "SALONPAS DEEP RELIEVING",
              "SALONPAS JET SPRAY": "SALONPAS JET SPRAY",
              "SALONPAS PAIN RELIEF PATCH": "SALONPAS PAIN RELIEF PATCH"
            },
            "HOME AIDE DIAGNOSTICS": {
              "REMATEX": "REMATEX",
              "ROTEX": "ROTEX",
              "SILMANIX PAIN RELIEIVING": "SILMANIX PAIN RELIEIVING"
            },
            "HUMCO": {
              "CASTIVA COOLING": "CASTIVA COOLING"
            },
            "ICON HEALTH SCIENCE": {
              "RELIADERM": "RELIADERM"
            },
            "INTERNATIONAL BRAND MANAGEMENT": {
              "AMPLIFY RELIEF MM": "AMPLIFY RELIEF MM"
            },
            "J & J CONSUMER PRODUCTS": {
              "BENGAY GREASELESS": "BENGAY GREASELESS",
              "BENGAY ULTRA STRENGTH": "BENGAY ULTRA STRENGTH"
            },
            "LEADER BRAND PRODUCTS": {
              "MUSCLE RUB ULTRA STRENGTH": "MUSCLE RUB ULTRA STRENGTH",
              "PAIN RELIEVING": "PAIN RELIEVING"
            },
            "LIVING WELL PHARMACY": {
              "XOTEN": "XOTEN",
              "XOTEN-C": "XOTEN-C"
            },
            "MAINPOINTE PHARMACEUTICALS": {
              "THERA-GESIC": "THERA-GESIC",
              "THERA-GESIC PLUS": "THERA-GESIC PLUS"
            },
            "MAS MANAGEMENT GROUP": {
              "VITACIN": "VITACIN"
            },
            "MCKESSON": {
              "HM MUSCLE RUB": "HM MUSCLE RUB",
              "HM MUSCLE RUB ULTRA STRENGTH": "HM MUSCLE RUB ULTRA STRENGTH",
              "HM SALONPAS PAIN RELIEF": "HM SALONPAS PAIN RELIEF"
            },
            "MCKESSON SUNMARK": {
              "SM COLD & HOT EXTRA STRENGTH": "SM COLD & HOT EXTRA STRENGTH",
              "SM MUSCLE RUB": "SM MUSCLE RUB",
              "SM MUSCLE RUB ULTRA STRENGTH": "SM MUSCLE RUB ULTRA STRENGTH"
            },
            "NEW WORLD IMPORTS": {
              "MUSCLE RUB": "MUSCLE RUB"
            },
            "NNODUM CORPORATION": {
              "ZIKS ARTHRITIS PAIN RELIEF": "ZIKS ARTHRITIS PAIN RELIEF"
            },
            "NUCARE PHARMACEUTICALS": {
              "MEDROX": "MEDROX",
              "MUSCLE RUB": "MUSCLE RUB",
              "NUDROXICIN V2": "NUDROXICIN V2",
              "THERA-GESIC": "THERA-GESIC"
            },
            "OAKHURST": {
              "SLOANS LINIMENT": "SLOANS LINIMENT",
              "SOLTICE QUICK-RUB": "SOLTICE QUICK-RUB"
            },
            "PERRIGO": {
              "MUSCLE RUB": "MUSCLE RUB"
            },
            "PHARMACEUTICA NORTH AMERICA": {
              "DURAPROXIN": "DURAPROXIN",
              "DURAPROXIN ES": "DURAPROXIN ES",
              "MEDROX": "MEDROX",
              "MEDROX-RX": "MEDROX-RX",
              "MENTHODERM": "MENTHODERM"
            },
            "PHYSICIANS SCIENCE AND NATURE": {
              "DENDRACIN NEURODENDRAXCIN": "DENDRACIN NEURODENDRAXCIN",
              "TRANSANOSIL": "TRANSANOSIL"
            },
            "PREFERRED PHARMACEUTICALS": {
              "DENDRACIN NEURODENDRAXCIN": "DENDRACIN NEURODENDRAXCIN",
              "MUSCLE RUB": "MUSCLE RUB",
              "NEW TEROCIN": "NEW TEROCIN"
            },
            "PRINCE OF PEACE": {
              "KWAN LOONG PAIN RELIEVING": "KWAN LOONG PAIN RELIEVING",
              "TIGER BALM ARTHRITIS RUB": "TIGER BALM ARTHRITIS RUB",
              "TIGER BALM EXTRA STRENGTH": "TIGER BALM EXTRA STRENGTH",
              "TIGER BALM LINIMENT": "TIGER BALM LINIMENT",
              "TIGER BALM MUSCLE RUB": "TIGER BALM MUSCLE RUB",
              "TIGER BALM NECK & SHOULDER RUB": "TIGER BALM NECK & SHOULDER RUB",
              "TIGER BALM PAIN RELIEVING": "TIGER BALM PAIN RELIEVING",
              "TIGER BALM PAIN RELIEVING LG": "TIGER BALM PAIN RELIEVING LG",
              "TIGER BALM RED EXTRA STRENGTH": "TIGER BALM RED EXTRA STRENGTH",
              "TIGER BALM REGULAR STRENGTH": "TIGER BALM REGULAR STRENGTH",
              "TIGER BALM ULTRA STRENGTH": "TIGER BALM ULTRA STRENGTH"
            },
            "PROFICIENT RX": {
              "DENDRACIN NEURODENDRAXCIN": "DENDRACIN NEURODENDRAXCIN",
              "MENTHOZEN": "MENTHOZEN"
            },
            "PUBLIX SUPER MARKETS INC.": {
              "PX ULTRA STRENGTH RUB": "PX ULTRA STRENGTH RUB"
            },
            "PURE TEK": {
              "CAMPHOTREX": "CAMPHOTREX"
            },
            "QUALITY CARE": {
              "DENDRACIN NEURODENDRAXCIN": "DENDRACIN NEURODENDRAXCIN",
              "MEDROX": "MEDROX",
              "NEW TEROCIN": "NEW TEROCIN",
              "SOMBRA NATURAL PAIN RELIEVING": "SOMBRA NATURAL PAIN RELIEVING"
            },
            "RELIABLE 1 LABS": {
              "BOROLEUM": "BOROLEUM"
            },
            "RITE AID CORPORATION": {
              "RA COOL HEAT": "RA COOL HEAT",
              "RA MUSCLE RUB ULTRA STRENGTH": "RA MUSCLE RUB ULTRA STRENGTH"
            },
            "RUGBY LABORATORIES": {
              "PAIN RELIEVING": "PAIN RELIEVING"
            },
            "SHEFFIELD PHARMACEUTICALS": {
              "MUSCLE RUB": "MUSCLE RUB"
            },
            "SOMBRA COSMETICS INC.": {
              "SOMBRA WARM THERAPY": "SOMBRA WARM THERAPY"
            },
            "ST MARY'S MPP": {
              "MUSCLE RUB": "MUSCLE RUB"
            },
            "TARGET": {
              "TGT COLD & HOT PAIN RELIEVING": "TGT COLD & HOT PAIN RELIEVING"
            },
            "TRIMARC LABORATORIES": {
              "DURAFLEX COMFORT": "DURAFLEX COMFORT"
            },
            "TWO HIP": {
              "MEDI-DERM": "MEDI-DERM",
              "MEDI-DERM-RX": "MEDI-DERM-RX"
            },
            "UNIT DOSE SERVICES": {
              "DENDRACIN NEURODENDRAXCIN": "DENDRACIN NEURODENDRAXCIN",
              "MEDI-DERM": "MEDI-DERM",
              "MEDROX": "MEDROX",
              "NEW TEROCIN": "NEW TEROCIN"
            },
            "VENTRIQ": {
              "DOULEURIN": "DOULEURIN"
            },
            "VILLAGE PHARMA": {
              "MENTHOZEN": "MENTHOZEN"
            },
            "WAL-MART": {
              "EQ PAIN RELIEVING": "EQ PAIN RELIEVING"
            },
            "WALGREENS": {
              "COOL & HEAT EXTRA STRENGTH": "COOL & HEAT EXTRA STRENGTH",
              "COOL N HEAT": "COOL N HEAT",
              "COOL N HEAT EXTRA STRENGTH": "COOL N HEAT EXTRA STRENGTH",
              "COOL N HEAT MUSCLE & JOINT": "COOL N HEAT MUSCLE & JOINT",
              "MEDICATED PAIN RELIEVING": "MEDICATED PAIN RELIEVING",
              "MUSCLE RUB ULTRA STRENGTH": "MUSCLE RUB ULTRA STRENGTH",
              "PAIN RELIEVING": "PAIN RELIEVING"
            },
            "WEEKS & LEO": {
              "BIO-THERM PAIN RELIEVING": "BIO-THERM PAIN RELIEVING"
            },
            "WORKERS CHOICE PHARMACY": {
              "POLAR FREEZE": "POLAR FREEZE"
            }
          },
          "Liniments": {
            "7T PHARMA": {
              "HISTAMINE DIHYDROCHLORIDE": "HISTAMINE DIHYDROCHLORIDE"
            },
            "A-S MEDICATION SOLUTIONS": {
              "MOBISYL": "MOBISYL",
              "MYOFLEX": "MYOFLEX",
              "TROLAMINE SALICYLATE": "TROLAMINE SALICYLATE"
            },
            "ALEXSO": {
              "METHYL SALICYLATE": "METHYL SALICYLATE"
            },
            "AMERICAN SALES COMPANY": {
              "COTTONTAILS BABY CHEST RUB": "COTTONTAILS BABY CHEST RUB"
            },
            "B.F. ASCHER": {
              "MOBISYL": "MOBISYL"
            },
            "BELMORA": {
              "FLANAX PAIN RELIEF LINIMENT": "FLANAX PAIN RELIEF LINIMENT"
            },
            "BERGEN BRUNSWIG": {
              "GNP ARTHRICREAM": "GNP ARTHRICREAM"
            },
            "CAL PHARMA": {
              "ULTRACIN T": "ULTRACIN T"
            },
            "CHAIN DRUG CONSORTIUM": {
              "ANALGESIC CREAM/ALOE": "ANALGESIC CREAM/ALOE",
              "SPORTS PAIN RELIEF RUB": "SPORTS PAIN RELIEF RUB"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC ARTHRITIS": "QC ARTHRITIS"
            },
            "CHATTEM INC": {
              "ASPERCREME": "ASPERCREME",
              "ASPERCREME NIGHTTIME": "ASPERCREME NIGHTTIME",
              "ASPERCREME/ALOE": "ASPERCREME/ALOE",
              "SPORTSCREME": "SPORTSCREME"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS ARTHRICREAM": "CVS ARTHRICREAM",
              "CVS ARTHRITIS PAIN RELIEF": "CVS ARTHRITIS PAIN RELIEF"
            },
            "DUCERE PHARMA": {
              "MYOFLEX": "MYOFLEX"
            },
            "ECR PHARMACEUTICALS": {
              "PANALGESIC": "PANALGESIC"
            },
            "FAGRON": {
              "METHYL SALICYLATE": "METHYL SALICYLATE"
            },
            "GERITREX CORPORATION": {
              "ASPER-FLEX": "ASPER-FLEX"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "MYOFLEX": "MYOFLEX"
            },
            "GORDON": {
              "CYDONOL": "CYDONOL",
              "GORDOBALM": "GORDOBALM",
              "MECHOLYL": "MECHOLYL",
              "METHAGUAL": "METHAGUAL",
              "SPORTBALM": "SPORTBALM"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "MYOFLEX": "MYOFLEX"
            },
            "HEALTH CARE LABORATORIES": {
              "BIOFLEXOR": "BIOFLEXOR"
            },
            "HI-TECH": {
              "VAPORIZING CREAM": "VAPORIZING CREAM"
            },
            "HUMCO": {
              "METHYL SALICYLATE": "METHYL SALICYLATE"
            },
            "KROGER COMPANY": {
              "ARTHRICREAM RUB": "ARTHRICREAM RUB"
            },
            "LEADER BRAND PRODUCTS": {
              "ARTHRITIS/ALOE": "ARTHRITIS/ALOE",
              "PAIN RELIEVING": "PAIN RELIEVING"
            },
            "LETCO MEDICAL": {
              "METHYL SALICYLATE": "METHYL SALICYLATE",
              "WINTERGREEN OIL": "WINTERGREEN OIL"
            },
            "MAJOR PHARMACEUTICALS": {
              "ANALGESIC CREME RUB/ALOE": "ANALGESIC CREME RUB/ALOE",
              "ARTHRICREAM": "ARTHRICREAM"
            },
            "MCKESSON": {
              "HM ARTHRITIS CREME": "HM ARTHRITIS CREME"
            },
            "MCKESSON SUNMARK": {
              "SM ARTHRICREAM RUB": "SM ARTHRICREAM RUB",
              "SM PAIN RELIEF/MENTHOL": "SM PAIN RELIEF/MENTHOL",
              "SM SPORTS PAIN RELIEF RUB": "SM SPORTS PAIN RELIEF RUB"
            },
            "MEAD-RAYMOND": {
              "LINI MIST ANALGESIC": "LINI MIST ANALGESIC"
            },
            "MEDTECH": {
              "HEET ANALGESIC LINIMENT": "HEET ANALGESIC LINIMENT"
            },
            "NATIONAL VITAMIN": {
              "DEEP BLUE RELIEF": "DEEP BLUE RELIEF"
            },
            "NATURE'S HEALTH CONNECTION": {
              "AUSTRALIAN DREAM ARTHRITIS": "AUSTRALIAN DREAM ARTHRITIS"
            },
            "NFI CONSUMER PRODUCTS": {
              "BLUE-EMU SUPER STRENGTH": "BLUE-EMU SUPER STRENGTH"
            },
            "OAKHURST": {
              "DR JH MCLEANS VOLCANIC OIL": "DR JH MCLEANS VOLCANIC OIL",
              "YAGERS LINIMENT": "YAGERS LINIMENT"
            },
            "ORISON MEDICAL": {
              "ARTHOGENIC PLUS": "ARTHOGENIC PLUS"
            },
            "P & G HEALTH": {
              "VICKS BABYRUB": "VICKS BABYRUB"
            },
            "PAR PHARMACEUTICAL": {
              "TRIXAICIN": "TRIXAICIN"
            },
            "PCCA": {
              "METHYL SALICYLATE": "METHYL SALICYLATE",
              "TURPENTINE": "TURPENTINE"
            },
            "PERRIGO PHARMACEUTICALS": {
              "TROLAMINE SALICYLATE": "TROLAMINE SALICYLATE"
            },
            "QUALITY CARE": {
              "MYOFLEX": "MYOFLEX"
            },
            "REESE PHARMACEUTICAL": {
              "PHARMACISTS CREME": "PHARMACISTS CREME"
            },
            "RITE AID CORPORATION": {
              "RA ARTHRITIC PAIN RUB": "RA ARTHRITIC PAIN RUB"
            },
            "RXCHANGE CO": {
              "ARTHRICREAM": "ARTHRICREAM"
            },
            "SELECT BRAND": {
              "SB ANALGESIC": "SB ANALGESIC",
              "SB ANALGESIC CREME RUB": "SB ANALGESIC CREME RUB"
            },
            "SHEFFIELD PHARMACEUTICALS": {
              "HEET TRIPLE ACTION": "HEET TRIPLE ACTION"
            },
            "SPECTRUM": {
              "METHYL SALICYLATE": "METHYL SALICYLATE"
            },
            "STARMAN CONSULTING": {
              "NEUROMAX": "NEUROMAX"
            },
            "TARGET": {
              "TGT ANALGESIC RUB": "TGT ANALGESIC RUB"
            },
            "TRANSDERMAL TECHNOLOGIES": {
              "PENETRAN": "PENETRAN",
              "PENETRAN PLUS": "PENETRAN PLUS"
            },
            "TRUCEUTICALS": {
              "TRU-MICIN": "TRU-MICIN"
            },
            "TYCHASIS": {
              "COATS ALOE LINIMENT": "COATS ALOE LINIMENT"
            },
            "WAL-MART": {
              "EQ ARTHRICREAM RUB": "EQ ARTHRICREAM RUB",
              "EQ BABY CHEST RUB": "EQ BABY CHEST RUB"
            },
            "WALGREENS": {
              "ANALGESIC CREME/ALOE": "ANALGESIC CREME/ALOE",
              "ARTHRITIS RELIEF/ALOE": "ARTHRITIS RELIEF/ALOE"
            }
          }
        },
        "Local Anesthetics - Topical": {
          "Local Anesthetics - Topical": {
            "7T PHARMA": {
              "7T LIDO": "7T LIDO",
              "CAPSAICIN": "CAPSAICIN",
              "GEN7T": "GEN7T",
              "LIDOCAINE HCL": "LIDOCAINE HCL"
            },
            "A-S MEDICATION SOLUTIONS": {
              "CAPSAICIN": "CAPSAICIN",
              "LIDOCAINE": "LIDOCAINE",
              "LIDOCAINE HCL": "LIDOCAINE HCL",
              "LIDOCAINE HCL URETHRAL/MUCOSAL": "LIDOCAINE HCL URETHRAL/MUCOSAL",
              "LIDODERM": "LIDODERM",
              "LIDORX": "LIDORX"
            },
            "ACTAVIS PHARMA": {
              "LIDOCAINE": "LIDOCAINE",
              "LIDOCAINE HCL URETHRAL/MUCOSAL": "LIDOCAINE HCL URETHRAL/MUCOSAL"
            },
            "ADLER-STERN": {
              "LIDOPIN": "LIDOPIN"
            },
            "AIDAREX PHARMACEUTICALS": {
              "ENOVARX-LIDOCAINE HCL": "ENOVARX-LIDOCAINE HCL",
              "LIDOCAINE": "LIDOCAINE",
              "LIDOCAINE HCL URETHRAL/MUCOSAL": "LIDOCAINE HCL URETHRAL/MUCOSAL",
              "LIDODERM": "LIDODERM",
              "LIDORX": "LIDORX",
              "XOLIDO": "XOLIDO"
            },
            "AKORN": {
              "LIDOCAINE HCL URETHRAL/MUCOSAL": "LIDOCAINE HCL URETHRAL/MUCOSAL"
            },
            "AKORN CONSUMER": {
              "ZOSTRIX DIABETIC FOOT PAIN": "ZOSTRIX DIABETIC FOOT PAIN",
              "ZOSTRIX HP": "ZOSTRIX HP",
              "ZOSTRIX NATURAL PAIN RELIEF": "ZOSTRIX NATURAL PAIN RELIEF"
            },
            "AKRON PHARMA": {
              "ASPERFLEX MAX ST": "ASPERFLEX MAX ST"
            },
            "ALEMBIC PHARMACEUTICALS": {
              "LIDOCAINE": "LIDOCAINE"
            },
            "ALEXSO": {
              "CAPSAICIN": "CAPSAICIN",
              "CAPSAICIN HP": "CAPSAICIN HP",
              "LIDOCAINE": "LIDOCAINE",
              "LIDOCAINE PLUS": "LIDOCAINE PLUS"
            },
            "ALIVIO MEDICAL PRODUCTS": {
              "PREMIUM LIDOCAINE": "PREMIUM LIDOCAINE"
            },
            "ALL PHARMA": {
              "ASTERO": "ASTERO",
              "LIDOCAINE": "LIDOCAINE"
            },
            "ALTURA PHARMACEUTICALS": {
              "CAPSAICIN": "CAPSAICIN",
              "LIDODERM": "LIDODERM"
            },
            "ALVIX LABORATORIES": {
              "CIDALEAZE": "CIDALEAZE",
              "LIDOCAINE HCL": "LIDOCAINE HCL"
            },
            "ALVOGEN": {
              "LIDOCAINE": "LIDOCAINE"
            },
            "AMBI PHARMACEUTICALS": {
              "LIDOCREAM": "LIDOCREAM"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP BURN RELIEF": "GNP BURN RELIEF",
              "GNP BURN RELIEF SPRAY": "GNP BURN RELIEF SPRAY",
              "GNP CAPSAICIN": "GNP CAPSAICIN",
              "GNP LIDOCAINE PAIN RELIEF": "GNP LIDOCAINE PAIN RELIEF",
              "GNP LIDOCAINE PAIN RELIEVING": "GNP LIDOCAINE PAIN RELIEVING"
            },
            "AMNEAL PHARMACEUTICALS": {
              "LIDOCAINE": "LIDOCAINE"
            },
            "AMNEAL SPECIALTY": {
              "LIDOCAINE": "LIDOCAINE"
            },
            "APOTHECA SUPPLY": {
              "CAPSAICIN": "CAPSAICIN"
            },
            "ASCEND LABORATORIES": {
              "LIDOCAINE": "LIDOCAINE"
            },
            "AUM PHARMACEUTICALS": {
              "LIDOCAINE MAX ST 24 HOURS": "LIDOCAINE MAX ST 24 HOURS"
            },
            "AVERITAS PHARMA": {
              "QUTENZA": "QUTENZA",
              "QUTENZA (2 PATCH)": "QUTENZA (2 PATCH)"
            },
            "AVKARE": {
              "LIDOCAINE": "LIDOCAINE"
            },
            "B & B PHARMACEUTICALS": {
              "COCAINE HCL": "COCAINE HCL"
            },
            "BAYER CONSUMER": {
              "SOLARCAINE COOL ALOE": "SOLARCAINE COOL ALOE"
            },
            "BODYSPHERE MANUFACTURING": {
              "ZIONODIL": "ZIONODIL",
              "ZIONODIL 100": "ZIONODIL 100"
            },
            "BROOKFIELD PHARMACEUTICALS": {
              "LIDOCAINE": "LIDOCAINE",
              "LIDOCAINE HCL": "LIDOCAINE HCL"
            },
            "BRYANT RANCH PREPACK": {
              "LIDOCAINE": "LIDOCAINE",
              "LIDODERM": "LIDODERM"
            },
            "CHADWICK PHARMACEUTICALS": {
              "LIDO-SORB": "LIDO-SORB"
            },
            "CHAIN DRUG CONSORTIUM": {
              "ALOE BURN RELIEF": "ALOE BURN RELIEF",
              "CAPSAICIN HP": "CAPSAICIN HP",
              "MUSCLE RELIEF": "MUSCLE RELIEF"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC LIDOCAINE PAIN RELIEF": "QC LIDOCAINE PAIN RELIEF"
            },
            "CHATTEM INC": {
              "ASPERCREME LIDOCAINE": "ASPERCREME LIDOCAINE",
              "ASPERCREME LIDOCAINE ESSENTIAL": "ASPERCREME LIDOCAINE ESSENTIAL",
              "ASPERCREME MAX STRENGTH": "ASPERCREME MAX STRENGTH",
              "ASPERCREME PAIN RELIEF PATCH": "ASPERCREME PAIN RELIEF PATCH",
              "ASPERCREME W/LIDOCAINE": "ASPERCREME W/LIDOCAINE",
              "CAPZASIN": "CAPZASIN",
              "CAPZASIN-HP": "CAPZASIN-HP",
              "CAPZASIN-P": "CAPZASIN-P",
              "GOLD BOND MULTI-SYMPTOM": "GOLD BOND MULTI-SYMPTOM"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "LIDOCAINE": "LIDOCAINE"
            },
            "COMBE": {
              "VAGISIL ANTI-ITCH MEDICATED": "VAGISIL ANTI-ITCH MEDICATED",
              "VAGISIL MAXIMUM STRENGTH": "VAGISIL MAXIMUM STRENGTH"
            },
            "COUNTY LINE PHARMACEUTICALS": {
              "LIDOCAINE": "LIDOCAINE"
            },
            "CROWN LABORATORIES": {
              "SARNA SENSITIVE": "SARNA SENSITIVE"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS AFTERSUN ALOE/LIDOCAINE": "CVS AFTERSUN ALOE/LIDOCAINE",
              "CVS ANTI-ITCH SENSITIVE": "CVS ANTI-ITCH SENSITIVE",
              "CVS BOIL RELIEF": "CVS BOIL RELIEF",
              "CVS CAPSAICIN": "CVS CAPSAICIN",
              "CVS CAPSAICIN HP": "CVS CAPSAICIN HP",
              "CVS FEMININE WIPES MAX ST": "CVS FEMININE WIPES MAX ST",
              "CVS HEMORRHOIDAL & ANALGESIC": "CVS HEMORRHOIDAL & ANALGESIC",
              "CVS INSTANT BURN RELIEF": "CVS INSTANT BURN RELIEF",
              "CVS LIDOCAINE MAXIMUM STRENGTH": "CVS LIDOCAINE MAXIMUM STRENGTH",
              "CVS LIDOCAINE PAIN RELIEF MAXS": "CVS LIDOCAINE PAIN RELIEF MAXS",
              "CVS MEDICATED HEAT PATCH": "CVS MEDICATED HEAT PATCH",
              "CVS PAIN RELIEF": "CVS PAIN RELIEF"
            },
            "DIABETIC SUPPLY OF SUNCOAST": {
              "AFTERTEST TOPICAL PAIN RELIEF": "AFTERTEST TOPICAL PAIN RELIEF"
            },
            "DIRECT RX": {
              "LIDOCAINE": "LIDOCAINE"
            },
            "DUCERE PHARMA": {
              "NUPERCAINAL": "NUPERCAINAL"
            },
            "ENDO PHARMACEUTICALS": {
              "LIDODERM": "LIDODERM"
            },
            "ENOVACHEM MANUFACTURING": {
              "EHA": "EHA",
              "ENOVARX-LIDOCAINE HCL": "ENOVARX-LIDOCAINE HCL",
              "XOLIDO": "XOLIDO",
              "XOLIDO XP": "XOLIDO XP"
            },
            "ESBA LABORATORIES": {
              "LIPOCAINE 5": "LIPOCAINE 5",
              "LUBRICAINE": "LUBRICAINE",
              "TOPICAINE": "TOPICAINE"
            },
            "FAGRON": {
              "CAPSAICIN": "CAPSAICIN",
              "COCAINE HCL": "COCAINE HCL",
              "PRAMOXINE HCL": "PRAMOXINE HCL"
            },
            "FERNDALE LAB": {
              "LMX 4": "LMX 4",
              "PRAX": "PRAX"
            },
            "FOCUS HEALTH GROUP": {
              "ANECREAM": "ANECREAM",
              "BLUE TUBE/ ALOE": "BLUE TUBE/ ALOE"
            },
            "FOUGERA": {
              "DIBUCAINE": "DIBUCAINE",
              "LIDOCAINE": "LIDOCAINE"
            },
            "FREEDOM PHARMACEUTICALS": {
              "CAPSAICIN": "CAPSAICIN"
            },
            "FRESENIUS KABI USA": {
              "XYLOCAINE": "XYLOCAINE"
            },
            "GALAXY BIO": {
              "LIDO KING": "LIDO KING"
            },
            "GEISS DESTIN & DUNN": {
              "CAPSAICIN ARTHRITIS RELIEF": "CAPSAICIN ARTHRITIS RELIEF",
              "PAIN RELIEF MAXIMUM STRENGTH": "PAIN RELIEF MAXIMUM STRENGTH"
            },
            "GENSCO LABORATORIES": {
              "ASTERO": "ASTERO",
              "LDO PLUS": "LDO PLUS",
              "LIDODOSE": "LIDODOSE",
              "LIDODOSE PEDIATRIC BULK PACK": "LIDODOSE PEDIATRIC BULK PACK",
              "LIDORX": "LIDORX"
            },
            "GERITREX CORPORATION": {
              "DIBUCAINE": "DIBUCAINE"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "SARNA SENSITIVE": "SARNA SENSITIVE"
            },
            "GLENMARK PHARMACEUTICALS": {
              "LIDOCAINE": "LIDOCAINE"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "LIDOCAINE": "LIDOCAINE"
            },
            "GORDON": {
              "ANACAINE": "ANACAINE"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "CAPSAICIN": "CAPSAICIN",
              "LIDOCAINE": "LIDOCAINE",
              "LIDODERM": "LIDODERM"
            },
            "HAWTHORN PHARMACEUTICALS": {
              "PRAMOX": "PRAMOX"
            },
            "HI-TECH": {
              "LIDOCAINE": "LIDOCAINE"
            },
            "HIKMA": {
              "LIDOCAINE HCL": "LIDOCAINE HCL"
            },
            "HISAMITSU AMERICA": {
              "SALONPAS PAIN RELIEVING": "SALONPAS PAIN RELIEVING",
              "SALONPAS-HOT": "SALONPAS-HOT"
            },
            "HUMCO": {
              "CASTIVA WARMING": "CASTIVA WARMING",
              "LIDOCAINE": "LIDOCAINE"
            },
            "INGENUS PHARMACEUTICALS": {
              "LIDOCAINE": "LIDOCAINE"
            },
            "INSIGHT PHARMACEUTICALS": {
              "AMERICAINE": "AMERICAINE",
              "BOIL EASE MAXIMUM STRENGTH": "BOIL EASE MAXIMUM STRENGTH"
            },
            "INTERNATIONAL BRAND MANAGEMENT": {
              "SURE RESULT SR RELIEF": "SURE RESULT SR RELIEF"
            },
            "INTRNTL MEDICATION SYSTEM": {
              "LIDOCAINE HCL URETHRAL/MUCOSAL": "LIDOCAINE HCL URETHRAL/MUCOSAL"
            },
            "IPG PHARMACEUTICALS": {
              "LIDOCAINE": "LIDOCAINE"
            },
            "IT3 MEDICAL": {
              "LIDOCAINE": "LIDOCAINE"
            },
            "IYATA PHARMACEUTICAL": {
              "CAPSAGEL": "CAPSAGEL",
              "CAPSAGEL EXTRA STRENGTH": "CAPSAGEL EXTRA STRENGTH",
              "CAPSAGEL MAXIMUM STRENGTH": "CAPSAGEL MAXIMUM STRENGTH"
            },
            "J & J CONSUMER PRODUCTS": {
              "AVEENO RESTORATIVE SKIN THERAP": "AVEENO RESTORATIVE SKIN THERAP",
              "BENGAY LIDOCAINE": "BENGAY LIDOCAINE"
            },
            "KALCHEM INTERNATIONAL": {
              "PRAMOXINE HCL": "PRAMOXINE HCL"
            },
            "L'OREAL": {
              "CERAVE ITCH RELIEF": "CERAVE ITCH RELIEF"
            },
            "LACLEDE": {
              "LUVENA FEMININE WIPES": "LUVENA FEMININE WIPES"
            },
            "LANNETT": {
              "C-TOPICAL": "C-TOPICAL",
              "LIDOCAINE HCL": "LIDOCAINE HCL"
            },
            "LEADER BRAND PRODUCTS": {
              "BURN RELIEF": "BURN RELIEF",
              "CAPSAICIN": "CAPSAICIN",
              "LIDOCAINE PAIN RELIEF": "LIDOCAINE PAIN RELIEF"
            },
            "LETCO MEDICAL": {
              "CAPSAICIN": "CAPSAICIN",
              "PRAMOXINE HCL": "PRAMOXINE HCL"
            },
            "LLORENS PHARMACEUTICAL": {
              "DOLOGESIC PAIN RELIEF ROLL-ON": "DOLOGESIC PAIN RELIEF ROLL-ON"
            },
            "MAJESTIC DRUG": {
              "MANDELAY": "MANDELAY"
            },
            "MALLINCKRODT PHARM": {
              "COCAINE HCL": "COCAINE HCL"
            },
            "MARLEX PHARMACEUTICALS": {
              "LIDOCAINE": "LIDOCAINE"
            },
            "MCKESSON": {
              "HM LIDOCAINE PATCH": "HM LIDOCAINE PATCH"
            },
            "MEDICIS": {
              "PRAMEGEL": "PRAMEGEL"
            },
            "MEDISCA": {
              "CAPSAICIN": "CAPSAICIN",
              "COCAINE HCL": "COCAINE HCL",
              "PRAMOXINE HCL": "PRAMOXINE HCL"
            },
            "MEDTECH": {
              "OUTGRO PAIN RELIEF": "OUTGRO PAIN RELIEF"
            },
            "MESORA PHARMA": {
              "LIDOCAINE HCL": "LIDOCAINE HCL"
            },
            "MPM MEDICAL, INC.": {
              "REGENECARE HA": "REGENECARE HA"
            },
            "MYLAN": {
              "LIDOCAINE": "LIDOCAINE"
            },
            "NFI CONSUMER PRODUCTS": {
              "BLUE-EMU PAIN RELIEF DRY": "BLUE-EMU PAIN RELIEF DRY",
              "LIDOCARE ARM/NECK/LEG": "LIDOCARE ARM/NECK/LEG",
              "LIDOCARE BACK/SHOULDER": "LIDOCARE BACK/SHOULDER"
            },
            "NORTHSTAR RX": {
              "LIDOCAINE": "LIDOCAINE"
            },
            "NOVAPLUS / AKORN": {
              "LIDOCAINE HCL URETHRAL/MUCOSAL": "LIDOCAINE HCL URETHRAL/MUCOSAL"
            },
            "NOVAPLUS/ACTAVIS": {
              "LIDOCAINE": "LIDOCAINE"
            },
            "NUBRATORI": {
              "LIDTOPIC MAX": "LIDTOPIC MAX"
            },
            "NUCARE PHARMACEUTICALS": {
              "LIDODERM": "LIDODERM",
              "TRIXAICIN HP": "TRIXAICIN HP"
            },
            "OAKHURST": {
              "GUADALUPANO PAIN RELIEVING": "GUADALUPANO PAIN RELIEVING"
            },
            "PAR PHARMACEUTICAL": {
              "TRIXAICIN HP": "TRIXAICIN HP"
            },
            "PAR PHARMACEUTICALS": {
              "LIDOCAINE": "LIDOCAINE",
              "LIDOCAINE HCL": "LIDOCAINE HCL"
            },
            "PATRIN PHARMA": {
              "LIDOCAINE": "LIDOCAINE"
            },
            "PCCA": {
              "CAPSAICIN": "CAPSAICIN",
              "COCAINE HCL": "COCAINE HCL",
              "PRAMOXINE HCL": "PRAMOXINE HCL"
            },
            "PHARMACEUTICA NORTH AMERICA": {
              "LIDOCAINE": "LIDOCAINE",
              "LIDOCAINE PAK": "LIDOCAINE PAK"
            },
            "PHARMACURE": {
              "LIDOCANNA": "LIDOCANNA"
            },
            "PREFERRED PHARMACEUTICALS": {
              "LIDOCAINE": "LIDOCAINE",
              "LIDOCAINE PAK": "LIDOCAINE PAK"
            },
            "PROFICIENT RX": {
              "LIDOCAINE": "LIDOCAINE"
            },
            "PURE TEK": {
              "DERMACINRX PENETRAL": "DERMACINRX PENETRAL",
              "LIDOCAINE": "LIDOCAINE",
              "LIDOCAINE HCL": "LIDOCAINE HCL",
              "LIDOTRAL": "LIDOTRAL",
              "LYDEXA": "LYDEXA"
            },
            "QUALITY CARE": {
              "CAPSAICIN": "CAPSAICIN",
              "LIDOCAINE": "LIDOCAINE",
              "LIDODERM": "LIDODERM",
              "LIDORX": "LIDORX"
            },
            "QUALITY CARE PRODUCTS": {
              "ASTERO": "ASTERO",
              "LIDOCAINE": "LIDOCAINE"
            },
            "QUEST PRODUCTS": {
              "ALOCANE EMERGENCY BURN MAX STR": "ALOCANE EMERGENCY BURN MAX STR",
              "SUN BURNT PLUS": "SUN BURNT PLUS"
            },
            "RECKITT BENCKISER": {
              "K-Y DURATION SPRAY FOR MEN": "K-Y DURATION SPRAY FOR MEN"
            },
            "RHODES PHARMACEUTICAL": {
              "LIDOCAINE": "LIDOCAINE"
            },
            "RITE AID CORPORATION": {
              "RA ALOE VERA/LIDOCAINE": "RA ALOE VERA/LIDOCAINE",
              "RA BURN RELIEF ALOE EXTRA": "RA BURN RELIEF ALOE EXTRA",
              "RA CAPSICUM HOT PATCH": "RA CAPSICUM HOT PATCH",
              "RA LIDOCAINE PAIN RELIEVING": "RA LIDOCAINE PAIN RELIEVING",
              "RA PAIN RELIEF": "RA PAIN RELIEF",
              "RA PAIN RELIEVING": "RA PAIN RELIEVING",
              "RA SENSITIVE ANTI-ITCH": "RA SENSITIVE ANTI-ITCH"
            },
            "RODLEN LABORATORIES": {
              "ZOSTRIX-HP": "ZOSTRIX-HP"
            },
            "RUGBY LABORATORIES": {
              "ARTHRITIS PAIN RELIEVING": "ARTHRITIS PAIN RELIEVING",
              "CAPSAICIN": "CAPSAICIN",
              "DIBUCAINE": "DIBUCAINE",
              "LIDOCAINE": "LIDOCAINE",
              "LIDOCAINE PAIN RELIEF": "LIDOCAINE PAIN RELIEF",
              "PAIN RELIEVING": "PAIN RELIEVING"
            },
            "RXCHANGE CO": {
              "LIDODERM": "LIDODERM"
            },
            "SAGENT PHARMACEUTICAL": {
              "GLYDO": "GLYDO"
            },
            "SAMBRIA PHARMACEUTICALS": {
              "NEUROMED7": "NEUROMED7",
              "PREDATOR": "PREDATOR"
            },
            "SCHERER": {
              "CHIGGEREX": "CHIGGEREX",
              "CHIGGERTOX": "CHIGGERTOX"
            },
            "SCILEX PHARMACEUTICALS": {
              "ZTLIDO": "ZTLIDO"
            },
            "SETON PHARMACEUTICALS": {
              "LIDOCAINE HCL": "LIDOCAINE HCL"
            },
            "SHORELINE PHARMACEUTICALS": {
              "LIDOCAINE HCL": "LIDOCAINE HCL"
            },
            "SIRCLE LABORATORIES": {
              "LIDOZION": "LIDOZION"
            },
            "SKY PACKAGING": {
              "LIDOCAINE HCL": "LIDOCAINE HCL"
            },
            "SKYA HEALTH": {
              "CAPSAICIN": "CAPSAICIN",
              "LIDOCAINE": "LIDOCAINE"
            },
            "SOLA PHARMACEUTICALS": {
              "LIDOCAINE": "LIDOCAINE"
            },
            "SOLUBIOMIX LLC": {
              "LIDOCAINE": "LIDOCAINE"
            },
            "SOLUTECH PHARMACEUTICALS": {
              "ANASTIA": "ANASTIA",
              "LIDO-K": "LIDO-K",
              "NUMBONEX": "NUMBONEX"
            },
            "SPECTRUM": {
              "CAPSAICIN": "CAPSAICIN",
              "COCAINE HCL": "COCAINE HCL",
              "PRAMOXINE HCL": "PRAMOXINE HCL"
            },
            "ST MARY'S MPP": {
              "ASTERO": "ASTERO",
              "LIDOCAINE": "LIDOCAINE",
              "LIDODERM": "LIDODERM",
              "LIDORX": "LIDORX"
            },
            "STERLING KNIGHT PHARMACEUTICAL": {
              "LIDOCAINE": "LIDOCAINE",
              "LIDOVEX": "LIDOVEX",
              "LIDOVIN": "LIDOVIN",
              "LIDOZOL": "LIDOZOL",
              "TETRAVEX": "TETRAVEX"
            },
            "STRATUS PHARMACEUTICALS": {
              "LIDOCAINE HCL": "LIDOCAINE HCL"
            },
            "STRIDES PHARMA": {
              "LIDOCAINE": "LIDOCAINE"
            },
            "TALEOS PHARMA": {
              "TRANZAREL": "TRANZAREL"
            },
            "TARO": {
              "LIDOCAINE": "LIDOCAINE"
            },
            "TELIGENT": {
              "LIDOCAINE": "LIDOCAINE",
              "LIDOCAINE HCL": "LIDOCAINE HCL"
            },
            "TENDER CORPORATION": {
              "AFTERBURN": "AFTERBURN"
            },
            "TOPRAIL CHP": {
              "CAPZIX": "CAPZIX"
            },
            "TRANSFER TECHNOLOGY": {
              "RE-LIEVED MAXIMUM STRENGTH": "RE-LIEVED MAXIMUM STRENGTH"
            },
            "TRIMARC LABORATORIES": {
              "LC-4 LIDOCAINE": "LC-4 LIDOCAINE"
            },
            "TWO HIP": {
              "LIDOCAINE": "LIDOCAINE"
            },
            "UNIT DOSE SERVICES": {
              "LIDOCAINE": "LIDOCAINE",
              "LIDODERM": "LIDODERM",
              "LIDORX": "LIDORX"
            },
            "USPHARMA": {
              "FIRST CARE PAIN RELIEF": "FIRST CARE PAIN RELIEF"
            },
            "VERIDIAN HEALTHCARE": {
              "THERACARE PAIN RELIEF": "THERACARE PAIN RELIEF"
            },
            "VITRUVIAS THERAPEUTICS": {
              "LIDOCAINE": "LIDOCAINE"
            },
            "WAL-MART": {
              "EQ CAPSAICIN PATCH": "EQ CAPSAICIN PATCH",
              "EQ LIDOCAINE PAIN RELIEVING": "EQ LIDOCAINE PAIN RELIEVING",
              "EQ PAIN RELIEVING": "EQ PAIN RELIEVING"
            },
            "WALGREENS": {
              "ALOE VERA BURN RELIEF": "ALOE VERA BURN RELIEF",
              "ALOE/LIDOCAINE PAIN RELIEVER": "ALOE/LIDOCAINE PAIN RELIEVER",
              "BOIL PAIN RELIEF": "BOIL PAIN RELIEF",
              "CAPSAICIN": "CAPSAICIN",
              "CAPSAICIN HOT PATCH": "CAPSAICIN HOT PATCH",
              "CAPSAICIN HP": "CAPSAICIN HP",
              "COOLING": "COOLING",
              "PAIN RELIEF ROLL-ON": "PAIN RELIEF ROLL-ON",
              "PAIN RELIEVING LIDOCAINE": "PAIN RELIEVING LIDOCAINE"
            },
            "WESTMINSTER PHARMACEUTICALS": {
              "LIDOCAINE HCL": "LIDOCAINE HCL"
            }
          },
          "Topical Anesthetic Combinations": {
            "1ST CLASS PHARMACEUTICALS": {
              "1ST RELIEF SPRAY": "1ST RELIEF SPRAY",
              "MENTHOLIX": "MENTHOLIX",
              "TETRAMEX": "TETRAMEX"
            },
            "1ST MEDX": {
              "1ST MEDX-PATCH/ LIDOCAINE": "1ST MEDX-PATCH/ LIDOCAINE",
              "CBD KINGS": "CBD KINGS"
            },
            "7T PHARMA": {
              "GEN7T PLUS": "GEN7T PLUS",
              "LIDOCAINE & MENTHOL": "LIDOCAINE & MENTHOL"
            },
            "A-S MEDICATION SOLUTIONS": {
              "LIDOCAINE-PRILOCAINE": "LIDOCAINE-PRILOCAINE",
              "LIDOPRO": "LIDOPRO",
              "LIDOTHOL": "LIDOTHOL",
              "MEDI-PATCH-LIDOCAINE": "MEDI-PATCH-LIDOCAINE"
            },
            "ACCELIS PHARMA": {
              "RELADOR PAK": "RELADOR PAK",
              "RELADOR PAK PLUS": "RELADOR PAK PLUS"
            },
            "ACTAVIS PHARMA": {
              "LIDOCAINE-PRILOCAINE": "LIDOCAINE-PRILOCAINE"
            },
            "ADLER-STERN": {
              "ZERUVIA": "ZERUVIA"
            },
            "ADVANTICE HEALTH": {
              "DERMOPLAST": "DERMOPLAST",
              "DERMOPLAST FIRST AID": "DERMOPLAST FIRST AID"
            },
            "AIDAREX PHARMACEUTICALS": {
              "LENZAGEL": "LENZAGEL",
              "LIDOCAINE-PRILOCAINE": "LIDOCAINE-PRILOCAINE",
              "LIDOPRO": "LIDOPRO",
              "MEDROX": "MEDROX",
              "TEROCIN": "TEROCIN"
            },
            "AKORN": {
              "EMLA": "EMLA",
              "LIDOCAINE-PRILOCAINE": "LIDOCAINE-PRILOCAINE"
            },
            "AKORN CONSUMER": {
              "ZOSTRIX HOT & COLD THERAPY": "ZOSTRIX HOT & COLD THERAPY"
            },
            "ALEXSO": {
              "LIMENCIN": "LIMENCIN",
              "MENCAPS": "MENCAPS",
              "RELYYKS": "RELYYKS",
              "RELYYT": "RELYYT",
              "TEROCIN": "TEROCIN"
            },
            "ALIVIO MEDICAL PRODUCTS": {
              "ALIVIO": "ALIVIO",
              "LEVATIO": "LEVATIO",
              "PREMIUM SCAR": "PREMIUM SCAR",
              "VENIA": "VENIA"
            },
            "ALL PHARMA": {
              "NULIDO": "NULIDO"
            },
            "ALVIX LABORATORIES": {
              "AGONEAZE": "AGONEAZE",
              "LIVIXIL PAK": "LIVIXIL PAK",
              "LP LITE PAK": "LP LITE PAK"
            },
            "ALVOGEN": {
              "LIDOCAINE-TRANSPARENT DRESSING": "LIDOCAINE-TRANSPARENT DRESSING"
            },
            "AMBI PHARMACEUTICALS": {
              "LIDOCREAM": "LIDOCREAM"
            },
            "AMERICAN SALES COMPANY": {
              "ANTI-ITCH CLEAR": "ANTI-ITCH CLEAR"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP MEDICATED FIRST AID SPRAY": "GNP MEDICATED FIRST AID SPRAY"
            },
            "AMNEAL PHARMACEUTICALS": {
              "LIDOCAINE-PRILOCAINE": "LIDOCAINE-PRILOCAINE"
            },
            "API SOLUTIONS": {
              "ALLEVESS": "ALLEVESS",
              "RELIEVER": "RELIEVER"
            },
            "B.F. ASCHER": {
              "ITCH-X": "ITCH-X"
            },
            "BASIEM": {
              "MEGRIX": "MEGRIX",
              "PALLERIX": "PALLERIX"
            },
            "BAUSCH HEALTH": {
              "CALADRYL": "CALADRYL",
              "CALADRYL CLEAR": "CALADRYL CLEAR"
            },
            "BAYER CONSUMER": {
              "A+D CRACKED SKIN RELIEF": "A+D CRACKED SKIN RELIEF",
              "BACTINE": "BACTINE"
            },
            "BELCHER PHARMACEUTICALS": {
              "LIDOCAINE-TETRACAINE": "LIDOCAINE-TETRACAINE"
            },
            "BERGEN BRUNSWIG": {
              "GNP ALOE VERA/LIDOCAINE": "GNP ALOE VERA/LIDOCAINE",
              "GNP CALDYPHEN": "GNP CALDYPHEN",
              "GNP CALDYPHEN CLEAR": "GNP CALDYPHEN CLEAR",
              "GNP LIQUID BANDAGE": "GNP LIQUID BANDAGE",
              "GNP MERCUROCLEAR": "GNP MERCUROCLEAR",
              "GNP PAIN RELIEF/BENZOCAINE": "GNP PAIN RELIEF/BENZOCAINE"
            },
            "BETTER PAIN SOLUTIONS": {
              "SOOTHEE": "SOOTHEE"
            },
            "BINGER CONSULTING": {
              "SCAR": "SCAR",
              "SITEROL": "SITEROL"
            },
            "BIORX  PHARMACEUTICALS": {
              "BIORX SPONIX ARTHR & MUSC": "BIORX SPONIX ARTHR & MUSC"
            },
            "BLISTEX": {
              "FOILLE": "FOILLE"
            },
            "BONITA PHARMACEUTICALS": {
              "MENTHOCIN WITH LIDOCAINE": "MENTHOCIN WITH LIDOCAINE"
            },
            "BRYANT RANCH PREPACK": {
              "TEROCIN": "TEROCIN"
            },
            "CETYLITE": {
              "CETACAINE": "CETACAINE"
            },
            "CHAIN DRUG CONSORTIUM": {
              "ALCOHOL PREP WITH BENZOCAINE": "ALCOHOL PREP WITH BENZOCAINE",
              "BURN RELIEF ALOE": "BURN RELIEF ALOE",
              "CALAGESIC": "CALAGESIC",
              "CALAHIST CLEAR": "CALAHIST CLEAR",
              "FIRST AID ANTISEPTIC": "FIRST AID ANTISEPTIC",
              "FIRST AID SPRAY": "FIRST AID SPRAY"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC ANTI-ITCH CLEAR": "QC ANTI-ITCH CLEAR"
            },
            "CHATTEM INC": {
              "CAPZASIN QUICK RELIEF": "CAPZASIN QUICK RELIEF",
              "GOLD BOND INTENSIVE HEALING": "GOLD BOND INTENSIVE HEALING",
              "GOLD BOND MEDICATED ANTI ITCH": "GOLD BOND MEDICATED ANTI ITCH",
              "GOLD BOND RAPID RELIEF": "GOLD BOND RAPID RELIEF",
              "ICY HOT LIDOCAINE PLUS MENTHOL": "ICY HOT LIDOCAINE PLUS MENTHOL",
              "ICY HOT PM": "ICY HOT PM"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "TEROCIN": "TEROCIN"
            },
            "CLOVERLEAF PHARMA": {
              "LIDO-RX 4.1": "LIDO-RX 4.1"
            },
            "COMBE": {
              "LANACANE": "LANACANE",
              "LANACANE ANTI-BACTERIAL": "LANACANE ANTI-BACTERIAL",
              "LANACANE MAXIMUM STRENGTH": "LANACANE MAXIMUM STRENGTH"
            },
            "COUNTY LINE PHARMACEUTICALS": {
              "LIDOCAINE-TRANSPARENT DRESSING": "LIDOCAINE-TRANSPARENT DRESSING"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS CALAMINE PLUS": "CVS CALAMINE PLUS",
              "CVS CALOHIST": "CVS CALOHIST",
              "CVS COLD & HOT MAX STRENGTH": "CVS COLD & HOT MAX STRENGTH",
              "CVS ITCH RELIEF": "CVS ITCH RELIEF",
              "CVS MEDICATED ANTI-ITCH": "CVS MEDICATED ANTI-ITCH",
              "CVS MEDICATED FIRST AID SPRAY": "CVS MEDICATED FIRST AID SPRAY"
            },
            "DIRECT RX": {
              "1ST MEDX-PATCH/ LIDOCAINE": "1ST MEDX-PATCH/ LIDOCAINE",
              "LIDOCAINE-PRILOCAINE": "LIDOCAINE-PRILOCAINE",
              "LIDOPRO": "LIDOPRO",
              "LIDOTHOL": "LIDOTHOL",
              "TEROCIN": "TEROCIN",
              "TRANSDERM-IQ": "TRANSDERM-IQ"
            },
            "EASY DISTRIBUTORS": {
              "AFLEXERYL-LC": "AFLEXERYL-LC",
              "AFLEXERYL-MC": "AFLEXERYL-MC"
            },
            "ENOVACHEM MANUFACTURING": {
              "ACCUCAINE": "ACCUCAINE",
              "CADIRAMD": "CADIRAMD",
              "LIDO BDK": "LIDO BDK"
            },
            "EQUALINE": {
              "EQL ANTI-ITCH CLEAR": "EQL ANTI-ITCH CLEAR",
              "EQL CALAMINE MEDICATED": "EQL CALAMINE MEDICATED"
            },
            "FAGRON COMPOUNDING SERVICES": {
              "LIDO-EPINEPHRINE-TETRACAINE": "LIDO-EPINEPHRINE-TETRACAINE"
            },
            "FERNDALE LAB": {
              "LMX 4 PLUS": "LMX 4 PLUS"
            },
            "FOCUS HEALTH GROUP": {
              "ANECREAM": "ANECREAM"
            },
            "FORTUS PHARMA": {
              "ANODYNE LPT": "ANODYNE LPT"
            },
            "FORWARD SCIENCE TECHNOLOGIES": {
              "LEVIGOLT": "LEVIGOLT",
              "LEVIGOSP": "LEVIGOSP"
            },
            "FOUGERA": {
              "LIDOCAINE-PRILOCAINE": "LIDOCAINE-PRILOCAINE"
            },
            "GALDERMA": {
              "PLIAGLIS": "PLIAGLIS"
            },
            "GALEN US": {
              "SYNERA": "SYNERA"
            },
            "GEISS DESTIN & DUNN": {
              "GOODSENSE CLEAR ANTI-ITCH": "GOODSENSE CLEAR ANTI-ITCH",
              "GOODSENSE MEDICATED CALAMINE": "GOODSENSE MEDICATED CALAMINE"
            },
            "HI-TECH": {
              "LIDOCAINE-PRILOCAINE": "LIDOCAINE-PRILOCAINE"
            },
            "HISAMITSU AMERICA": {
              "SALONPAS GEL-PATCH HOT": "SALONPAS GEL-PATCH HOT",
              "SALONPAS LIDOCAINE PLUS": "SALONPAS LIDOCAINE PLUS",
              "SALONPAS PAIN REL GEL-PTCH HOT": "SALONPAS PAIN REL GEL-PTCH HOT"
            },
            "HOME AIDE DIAGNOSTICS": {
              "LORENZA": "LORENZA",
              "PERMAVAN": "PERMAVAN",
              "REMAXAZON": "REMAXAZON",
              "SILVERA PAIN RELIEF": "SILVERA PAIN RELIEF",
              "VELMA PAIN RELIEF": "VELMA PAIN RELIEF",
              "VELTRIX": "VELTRIX"
            },
            "HUMCO": {
              "CALACLEAR": "CALACLEAR",
              "CALAGESIC": "CALAGESIC",
              "CHIGG AWAY": "CHIGG AWAY",
              "LIDOCAINE-PRILOCAINE": "LIDOCAINE-PRILOCAINE"
            },
            "INNOVIDA PHARMACEUTIQUE": {
              "LIDOCAINE-TETRACAINE": "LIDOCAINE-TETRACAINE"
            },
            "INTERNATIONAL BRAND MANAGEMENT": {
              "PAINGO KFT": "PAINGO KFT",
              "WPR PLUS WOUND HEALING SYSTEM": "WPR PLUS WOUND HEALING SYSTEM"
            },
            "IT3 MEDICAL": {
              "SX1 MEDICATED POST-OPERATIVE": "SX1 MEDICATED POST-OPERATIVE",
              "VENIPUNCTURE PX1 PHLEBOTOMY": "VENIPUNCTURE PX1 PHLEBOTOMY"
            },
            "J & J CONSUMER PRODUCTS": {
              "AVEENO ANTI-ITCH": "AVEENO ANTI-ITCH",
              "BAND-AID ANTISEPTIC WASH": "BAND-AID ANTISEPTIC WASH",
              "NEOSPORIN NEO TO GO": "NEOSPORIN NEO TO GO"
            },
            "JAR LABORATORIES": {
              "LIDOPATCH PAIN RELIEF": "LIDOPATCH PAIN RELIEF"
            },
            "KEY2HEALTH": {
              "ARTH ARREST": "ARTH ARREST"
            },
            "LEADER BRAND PRODUCTS": {
              "CALAMINE CLEAR": "CALAMINE CLEAR",
              "CALAMINE PLUS": "CALAMINE PLUS",
              "CALDYPHEN": "CALDYPHEN",
              "CALDYPHEN CLEAR": "CALDYPHEN CLEAR"
            },
            "LLORENS PHARMACEUTICAL": {
              "DERMAGESIC": "DERMAGESIC"
            },
            "MAJOR PHARMACEUTICALS": {
              "CALLERGY CLEAR": "CALLERGY CLEAR",
              "LIDOCAINE-PRILOCAINE": "LIDOCAINE-PRILOCAINE"
            },
            "MAS MANAGEMENT GROUP": {
              "AVADERM": "AVADERM"
            },
            "MCKESSON SUNMARK": {
              "SM ALCOHOL PREP": "SM ALCOHOL PREP",
              "SM ALCOHOL PREP/BENZOCAINE": "SM ALCOHOL PREP/BENZOCAINE",
              "SM CALDYPHEN": "SM CALDYPHEN",
              "SM CALDYPHEN CLEAR": "SM CALDYPHEN CLEAR",
              "SM FIRST AID ANTISEPTIC": "SM FIRST AID ANTISEPTIC"
            },
            "MEDARBOR": {
              "LIPROZONEPAK": "LIPROZONEPAK",
              "MEDOLOR PAK": "MEDOLOR PAK"
            },
            "MEDICINE SHOPPE": {
              "ALCOHOL SWABS WITH BENZOCAINE": "ALCOHOL SWABS WITH BENZOCAINE",
              "ANTI-ITCH CLEAR": "ANTI-ITCH CLEAR"
            },
            "MEDIQUE PRODUCTS": {
              "MEDI-FIRST/LIDOCAINE": "MEDI-FIRST/LIDOCAINE"
            },
            "MEDTECH": {
              "DERMOPLAST ANTIBACTERIAL": "DERMOPLAST ANTIBACTERIAL"
            },
            "MESORA PHARMA": {
              "PREPIV SUPPLY": "PREPIV SUPPLY"
            },
            "NEPTUNE PRODUCTS": {
              "NEPTUNE ICE": "NEPTUNE ICE"
            },
            "NUCARE PHARMACEUTICALS": {
              "LIDOCAINE-PRILOCAINE": "LIDOCAINE-PRILOCAINE"
            },
            "OBA PHARMACEUTICALS": {
              "PLIAGLIS": "PLIAGLIS"
            },
            "PATCHWERX LABS": {
              "LIDENZA": "LIDENZA",
              "RELEEVIA": "RELEEVIA",
              "RELEEVIA MC": "RELEEVIA MC",
              "RELEEVIA ML": "RELEEVIA ML",
              "RENUU": "RENUU"
            },
            "PATRIN PHARMA": {
              "LIDOCAINE-TRANSPARENT DRESSING": "LIDOCAINE-TRANSPARENT DRESSING"
            },
            "PERFECTA PRODUCTS": {
              "ZIMS MAX-FREEZE": "ZIMS MAX-FREEZE",
              "ZIMS MAX-FREEZE PAIN RELIEF": "ZIMS MAX-FREEZE PAIN RELIEF"
            },
            "PERFORMANCE HEALTH PRODUCTS": {
              "PERFORM ATOMIC HEAT": "PERFORM ATOMIC HEAT"
            },
            "PHARMACEUTICA NORTH AMERICA": {
              "ENDOXCIN": "ENDOXCIN",
              "LENZAGEL": "LENZAGEL",
              "LENZAPATCH": "LENZAPATCH",
              "MEDROX": "MEDROX"
            },
            "PHARMACEUTICS CORP": {
              "NEUVAXIN": "NEUVAXIN"
            },
            "POCONO COATED PRODUCTS": {
              "TRANSDERM-IQ": "TRANSDERM-IQ"
            },
            "PREFERRED PHARMACEUTICALS": {
              "LIDOCAINE-PRILOCAINE": "LIDOCAINE-PRILOCAINE",
              "LIDOPRO": "LIDOPRO",
              "LIDOTHOL": "LIDOTHOL",
              "TEROCIN": "TEROCIN"
            },
            "PRIMARY PHARMACEUTICALS": {
              "CLM": "CLM",
              "CML": "CML",
              "LIDOVIX L": "LIDOVIX L",
              "LMC PAD": "LMC PAD",
              "MCL": "MCL",
              "MICROVIX LP": "MICROVIX LP",
              "MLC": "MLC",
              "PRILOVIX": "PRILOVIX",
              "PRILOVIX LITE": "PRILOVIX LITE",
              "PRILOVIX LITE PLUS": "PRILOVIX LITE PLUS",
              "PRILOVIX PLUS": "PRILOVIX PLUS",
              "PRILOVIX ULTRALITE": "PRILOVIX ULTRALITE",
              "PRILOVIX ULTRALITE PLUS": "PRILOVIX ULTRALITE PLUS",
              "PRILOVIXIL": "PRILOVIXIL",
              "ZENEVIX": "ZENEVIX"
            },
            "PROFICIENT RX": {
              "LENZAGEL": "LENZAGEL",
              "LENZAPATCH": "LENZAPATCH",
              "LIDOZENGEL": "LIDOZENGEL",
              "LIDOZENPATCH": "LIDOZENPATCH",
              "MEDI-DERM/L": "MEDI-DERM/L",
              "MEDI-PATCH-LIDOCAINE": "MEDI-PATCH-LIDOCAINE",
              "MENTHOZEN HYDROGEL": "MENTHOZEN HYDROGEL"
            },
            "PRONOVA CORPORATION": {
              "CAPSIDERM": "CAPSIDERM",
              "PROLIDA": "PROLIDA"
            },
            "PURE TEK": {
              "APRIZIO PAK": "APRIZIO PAK",
              "APRIZIO PAK II": "APRIZIO PAK II",
              "DERMACINRX DUOPATCH PHARMAPAK": "DERMACINRX DUOPATCH PHARMAPAK",
              "DERMACINRX EMPRICAINE": "DERMACINRX EMPRICAINE",
              "DERMACINRX NEUROTRAL PHARMAPAK": "DERMACINRX NEUROTRAL PHARMAPAK",
              "DERMACINRX PHN": "DERMACINRX PHN",
              "DERMACINRX PRIZOPAK": "DERMACINRX PRIZOPAK",
              "DERMACINRX ZRM": "DERMACINRX ZRM",
              "EMPRICAINE-II": "EMPRICAINE-II",
              "IV INFUSION CPI": "IV INFUSION CPI",
              "LIDOPURE PATCH": "LIDOPURE PATCH",
              "NUVAKAAN": "NUVAKAAN",
              "NUVAKAAN-II": "NUVAKAAN-II",
              "PRILO PATCH": "PRILO PATCH",
              "PRIZOPAK II": "PRIZOPAK II",
              "PRIZOTRAL": "PRIZOTRAL",
              "PRIZOTRAL-II": "PRIZOTRAL-II",
              "VENIPUNCTURE CPI": "VENIPUNCTURE CPI",
              "ZILACAINE PATCH": "ZILACAINE PATCH"
            },
            "QUALITY CARE": {
              "LIDOCAINE-PRILOCAINE": "LIDOCAINE-PRILOCAINE",
              "TEROCIN": "TEROCIN"
            },
            "QUALITY CARE PRODUCTS": {
              "LIDOCAINE-PRILOCAINE": "LIDOCAINE-PRILOCAINE",
              "LIDOPRO": "LIDOPRO",
              "TEROCIN": "TEROCIN"
            },
            "QUEST PRODUCTS": {
              "ALOCANE PLUS": "ALOCANE PLUS"
            },
            "QUVA PHARMA": {
              "L.E.T.": "L.E.T.",
              "LIDO-EPINEPHRINE-TETRACAINE": "LIDO-EPINEPHRINE-TETRACAINE"
            },
            "RECKITT BENCKISER": {
              "LANACANE ANTI-ITCH 2-IN-1": "LANACANE ANTI-ITCH 2-IN-1",
              "LANACANE FIRST AID 2-IN-1": "LANACANE FIRST AID 2-IN-1"
            },
            "REESE PHARMACEUTICAL": {
              "BITE/ITCH": "BITE/ITCH"
            },
            "RITE AID CORPORATION": {
              "RA ANTI-ITCH CLEAR": "RA ANTI-ITCH CLEAR",
              "RA ANTI-ITCH MEDICATED": "RA ANTI-ITCH MEDICATED",
              "RA FIRST AID SPRAY": "RA FIRST AID SPRAY",
              "RA HOT & COLD LIDOCAINE": "RA HOT & COLD LIDOCAINE",
              "RA LIQUID BANDAGE": "RA LIQUID BANDAGE"
            },
            "RODLEN LABORATORIES": {
              "AXSAIN": "AXSAIN"
            },
            "RUGBY LABORATORIES": {
              "CALDYPHEN CLEAR": "CALDYPHEN CLEAR"
            },
            "RXCHANGE CO": {
              "AVALIN": "AVALIN"
            },
            "SA3": {
              "LIDOCAINE-TETRACAINE": "LIDOCAINE-TETRACAINE"
            },
            "SANDOZ": {
              "LIDOCAINE-PRILOCAINE": "LIDOCAINE-PRILOCAINE"
            },
            "SHORELINE PHARMACEUTICALS": {
              "DERMAZYL": "DERMAZYL",
              "LIDOTRANS 5 PAK": "LIDOTRANS 5 PAK",
              "NEURCAINE": "NEURCAINE",
              "PRIKAAN": "PRIKAAN",
              "PRIKAAN LITE": "PRIKAAN LITE",
              "XRYLIDERM": "XRYLIDERM",
              "ZEYOCAINE": "ZEYOCAINE"
            },
            "SINCERUS FLORIDA": {
              "BENZO-LIDOCAINE-TETRACAINE": "BENZO-LIDOCAINE-TETRACAINE",
              "LIDOCAINE-TETRACAINE": "LIDOCAINE-TETRACAINE"
            },
            "SIRCLE LABORATORIES": {
              "PRILOXX LP": "PRILOXX LP"
            },
            "SKYA HEALTH": {
              "CAPSAICIN-MENTHOL": "CAPSAICIN-MENTHOL",
              "LIDOCAINE-MENTHOL": "LIDOCAINE-MENTHOL"
            },
            "SOLA PHARMACEUTICALS": {
              "LENZAPRO": "LENZAPRO",
              "LIDOCAINE-PRILOCAINE": "LIDOCAINE-PRILOCAINE"
            },
            "SOLUBIOMIX LLC": {
              "ANODYNZ": "ANODYNZ",
              "MAC PATCH": "MAC PATCH",
              "MIGRYL": "MIGRYL",
              "XYPHEX": "XYPHEX"
            },
            "SOLUTECH PHARMACEUTICALS": {
              "IV NOVICE PACK": "IV NOVICE PACK",
              "MENTHO-CAINE": "MENTHO-CAINE",
              "PORT-PREP": "PORT-PREP",
              "SOLULINE": "SOLULINE",
              "SOLUPAK": "SOLUPAK",
              "SOLUPICC": "SOLUPICC"
            },
            "SPRING HILL THERAPEUTICS": {
              "SOLMARA": "SOLMARA"
            },
            "STERLING KNIGHT PHARMACEUTICAL": {
              "ADAZIN": "ADAZIN",
              "LIDOPAC": "LIDOPAC",
              "LIDOPRIL": "LIDOPRIL",
              "LIDOPRIL XR": "LIDOPRIL XR",
              "PRILOLID": "PRILOLID",
              "SYNVEXIA": "SYNVEXIA",
              "SYNVEXIA TC": "SYNVEXIA TC",
              "VEXATROL": "VEXATROL"
            },
            "TALEOS PHARMA": {
              "DOLOTRANZ": "DOLOTRANZ"
            },
            "TARGET": {
              "TGT FIRST AID CLEANSING": "TGT FIRST AID CLEANSING"
            },
            "TARO": {
              "PLIAGLIS": "PLIAGLIS"
            },
            "TEC LABS": {
              "TECNU FIRST AID": "TECNU FIRST AID"
            },
            "TELIGENT": {
              "LIDOCAINE-PRILOCAINE": "LIDOCAINE-PRILOCAINE"
            },
            "TERRAIN PHARMACEUTICALS": {
              "LIDOPRO": "LIDOPRO",
              "LIDOTHOL": "LIDOTHOL",
              "VIVA": "VIVA"
            },
            "TMIG": {
              "LIDO-PRILO CAINE PACK": "LIDO-PRILO CAINE PACK",
              "RENOVO": "RENOVO"
            },
            "TOPCO": {
              "CALAMINE MEDICATED": "CALAMINE MEDICATED",
              "CLEAR ANTI-ITCH": "CLEAR ANTI-ITCH"
            },
            "TRITON CONSUMER PRODUCTS": {
              "SKEETER STIK": "SKEETER STIK"
            },
            "TWO HIP": {
              "MEDI-DERM/L": "MEDI-DERM/L",
              "MEDI-DERM/L-RX": "MEDI-DERM/L-RX",
              "MEDI-PATCH RX": "MEDI-PATCH RX",
              "MEDI-PATCH-LIDOCAINE": "MEDI-PATCH-LIDOCAINE"
            },
            "UNIK PHARMACEUTICALS": {
              "CMX": "CMX",
              "MTX TOPICAL PAIN": "MTX TOPICAL PAIN"
            },
            "UNIT DOSE SERVICES": {
              "MEDROX": "MEDROX",
              "TEROCIN": "TEROCIN"
            },
            "UPSTREAM PHARMA": {
              "LIDOSTREAM": "LIDOSTREAM"
            },
            "V2 PHARMA": {
              "LMR PLUS": "LMR PLUS"
            },
            "VILLAGE PHARMA": {
              "LIDOZENGEL": "LIDOZENGEL",
              "LIDOZENPATCH": "LIDOZENPATCH",
              "MENTHOZEN HYDROGEL": "MENTHOZEN HYDROGEL"
            },
            "WALGREENS": {
              "ALCOHOL SWABS WITH BENZOCAINE": "ALCOHOL SWABS WITH BENZOCAINE",
              "ALOE VERA PAIN RELIEVING": "ALOE VERA PAIN RELIEVING",
              "BURN RELIEF/LIDOCAINE/ALOE": "BURN RELIEF/LIDOCAINE/ALOE",
              "CALAHIST": "CALAHIST",
              "CALAHIST CLEAR": "CALAHIST CLEAR",
              "COOLING BURN RELIEF": "COOLING BURN RELIEF",
              "FIRST AID ANTISEPTIC": "FIRST AID ANTISEPTIC",
              "FIRST AID ANTISEPTIC SPRAY": "FIRST AID ANTISEPTIC SPRAY"
            },
            "WELGO": {
              "LEVA SET": "LEVA SET",
              "LEVA SET/OCCLUSIVE DRESSING": "LEVA SET/OCCLUSIVE DRESSING",
              "LIDOCAINE-PRILOCAINE": "LIDOCAINE-PRILOCAINE"
            },
            "WHYTEMAN LABS": {
              "FLEXIN": "FLEXIN"
            }
          },
          "Topical Anesthetic Gases": {
            "GEBAUER": {
              "ETHYL CHLORIDE": "ETHYL CHLORIDE",
              "GEBAUERS INSTANT ICE": "GEBAUERS INSTANT ICE",
              "GEBAUERS PAIN EASE": "GEBAUERS PAIN EASE",
              "GEBAUERS SPRAY AND STRETCH": "GEBAUERS SPRAY AND STRETCH"
            },
            "SPECTRUM": {
              "ETHYL CHLORIDE": "ETHYL CHLORIDE"
            }
          }
        },
        "Misc. Dermatological Products": {
          "Misc. Dermatological Products": {
            "3M CONSUMER HEALTH CARE": {
              "NEXCARE LIQUID BANDAGE DROPS": "NEXCARE LIQUID BANDAGE DROPS",
              "NEXCARE LIQUID BANDAGE SPRAY": "NEXCARE LIQUID BANDAGE SPRAY"
            },
            "7T PHARMA": {
              "7TOPIC": "7TOPIC"
            },
            "ADVANTICE HEALTH": {
              "KERASAL FUNGAL NAIL RENEWAL": "KERASAL FUNGAL NAIL RENEWAL",
              "NEW SKIN": "NEW SKIN"
            },
            "AIDEN INDUSTRIES": {
              "MIADERM RADIATION RELIEF": "MIADERM RADIATION RELIEF"
            },
            "AKORN CONSUMER": {
              "DIABETIDERM MASSAGE STIMULATOR": "DIABETIDERM MASSAGE STIMULATOR"
            },
            "ALVIX LABORATORIES": {
              "KAMDOY": "KAMDOY"
            },
            "ARTESA LABS": {
              "MIMYX": "MIMYX"
            },
            "BAUSCH HEALTH": {
              "ATOPICLAIR": "ATOPICLAIR",
              "AURSTAT ANTI-ITCH HYDROGEL": "AURSTAT ANTI-ITCH HYDROGEL",
              "HYLATOPIC PLUS": "HYLATOPIC PLUS",
              "NAIL SCRUB": "NAIL SCRUB",
              "TETRIX": "TETRIX",
              "TROPAZONE": "TROPAZONE"
            },
            "BIODERM": {
              "FREEDERM ADHESIVE REMOVER": "FREEDERM ADHESIVE REMOVER"
            },
            "BSN MEDICAL": {
              "JOBST IT STAYS": "JOBST IT STAYS"
            },
            "BURKE THERAPEUTICS": {
              "ENTTY SPRAY": "ENTTY SPRAY"
            },
            "C S  DENT": {
              "LE STICK DEODORANT": "LE STICK DEODORANT"
            },
            "CHAIN DRUG CONSORTIUM": {
              "HEADACHE RELIEF": "HEADACHE RELIEF",
              "LIQUID BANDAGE": "LIQUID BANDAGE"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "HYLATOPIC PLUS": "HYLATOPIC PLUS"
            },
            "COLOPLAST": {
              "MOISTURE": "MOISTURE",
              "MOISTURE BARRIER": "MOISTURE BARRIER"
            },
            "COMBE": {
              "VAGISIL ODOR BLOCK DRY WASH": "VAGISIL ODOR BLOCK DRY WASH",
              "VAGISIL SCENTSITIVE DRY WASH": "VAGISIL SCENTSITIVE DRY WASH"
            },
            "DARTMOUTH": {
              "ELON NAIL CONDITIONER": "ELON NAIL CONDITIONER"
            },
            "DC ALPINE PARTNERS": {
              "SWEATBLOCK": "SWEATBLOCK",
              "SWEATBLOCK HANDS & FEET": "SWEATBLOCK HANDS & FEET",
              "SWEATBLOCK MAXIMUM STRENGTH": "SWEATBLOCK MAXIMUM STRENGTH"
            },
            "DSE HEALTHCARE SOLUTIONS": {
              "5 DAY": "5 DAY",
              "5 DAY FRESH": "5 DAY FRESH",
              "YODORA DEODORANT": "YODORA DEODORANT"
            },
            "ENCORE DERMATOLOGY": {
              "HYLATOPIC PLUS": "HYLATOPIC PLUS",
              "TETRIX": "TETRIX"
            },
            "EPI HEALTH": {
              "NUVAIL": "NUVAIL"
            },
            "EXELTIS USA DERMATOLOGY": {
              "NEOCERA": "NEOCERA",
              "PRESERA": "PRESERA"
            },
            "FERNDALE LAB": {
              "OC8": "OC8"
            },
            "FLEET PHARMACEUTICALS": {
              "SUMMERS EVE DEODORANT": "SUMMERS EVE DEODORANT",
              "SUMMERS EVE DEODORANT ULTRA": "SUMMERS EVE DEODORANT ULTRA"
            },
            "GENESIS PHARMACEUTICAL": {
              "COLLADERM": "COLLADERM"
            },
            "GLENMARK THERAPEUTICS": {
              "NEOSALUS": "NEOSALUS",
              "NEOSALUS CP": "NEOSALUS CP"
            },
            "GORDON": {
              "BROMI-LOTION": "BROMI-LOTION",
              "CALICYLIC": "CALICYLIC"
            },
            "HERAN PHARM": {
              "EPIMIDE": "EPIMIDE"
            },
            "HUMCO": {
              "XERALUX": "XERALUX"
            },
            "INTRADERM": {
              "LEVICYN": "LEVICYN",
              "LOYON": "LOYON"
            },
            "INTRADERM PHARMACEUTICALS": {
              "ALEVICYN ANTIPRURITIC": "ALEVICYN ANTIPRURITIC",
              "ALEVICYN ANTIPRURITIC SG": "ALEVICYN ANTIPRURITIC SG",
              "ALEVICYN PLUS": "ALEVICYN PLUS",
              "CERAMAX": "CERAMAX",
              "SEBUDERM": "SEBUDERM"
            },
            "JOURNEY MEDICAL CORPORATION": {
              "CERACADE": "CERACADE"
            },
            "LACER PHARMA": {
              "HYLAGUARD": "HYLAGUARD"
            },
            "LEVINS PHARMACEUTICALS": {
              "ILIDERM": "ILIDERM"
            },
            "MEDIMETRIKS PHARMACEUTICALS": {
              "GENADUR": "GENADUR"
            },
            "MEDIQUE PRODUCTS": {
              "BURN SPRAY": "BURN SPRAY"
            },
            "MESORA PHARMA": {
              "ALEVAMAX": "ALEVAMAX",
              "REMIGEN": "REMIGEN"
            },
            "MISSION": {
              "ELETONE": "ELETONE",
              "ELETONE TWINPACK": "ELETONE TWINPACK"
            },
            "NATIONAL BIO GREEN SCIENCES": {
              "HALUCORT": "HALUCORT"
            },
            "NUMARK LABS": {
              "5 DAY": "5 DAY"
            },
            "OAKHURST": {
              "STRIP EASE ADHESIVE REMOVER": "STRIP EASE ADHESIVE REMOVER",
              "THUM": "THUM"
            },
            "ONPACE PHARMA": {
              "ATOPADERM": "ATOPADERM"
            },
            "PHARMACEUTICAL SPECIALITIES": {
              "FREE & CLEAR": "FREE & CLEAR"
            },
            "PHLIGHT PHARMA": {
              "KIVIK": "KIVIK",
              "PHLAG SPRAY": "PHLAG SPRAY",
              "SYNERDERM": "SYNERDERM"
            },
            "PIERRE FABRE PHARMACEUTICALS": {
              "DEXERYL": "DEXERYL"
            },
            "PRIMUS PHARMACEUTICALS": {
              "EPICERAM": "EPICERAM"
            },
            "PRUGEN": {
              "EMULSION SB": "EMULSION SB",
              "HPR": "HPR",
              "HPR PLUS": "HPR PLUS",
              "HPR PLUS HYDROGEL": "HPR PLUS HYDROGEL",
              "MB HYDROGEL": "MB HYDROGEL",
              "PR CREAM": "PR CREAM",
              "PRUCLAIR": "PRUCLAIR",
              "PRUMYX": "PRUMYX"
            },
            "PURACAP PHARMACEUTICAL": {
              "EPICERAM": "EPICERAM"
            },
            "REESE PHARMACEUTICAL": {
              "HEAL AID PLUS": "HEAL AID PLUS"
            },
            "RESICAL INC": {
              "RESINOL": "RESINOL"
            },
            "RITE AID CORPORATION": {
              "RA DRAW OUT SALVE": "RA DRAW OUT SALVE"
            },
            "SI PHARMACEUTICALS": {
              "PENLEN": "PENLEN"
            },
            "SMITH & NEPHEW UNITED": {
              "REMOVE ADHESIVE REMOVER": "REMOVE ADHESIVE REMOVER",
              "REMOVE ADHESIVE REMOVER WIPES": "REMOVE ADHESIVE REMOVER WIPES"
            },
            "SOLA PHARMACEUTICALS": {
              "HYLAMIX": "HYLAMIX"
            },
            "STERLING KNIGHT PHARMACEUTICAL": {
              "SUVICORT": "SUVICORT"
            },
            "STRATPHARMA AG": {
              "STRATA CTX": "STRATA CTX",
              "STRATA MARK": "STRATA MARK",
              "STRATA XRT": "STRATA XRT"
            },
            "TAGI PHARMA": {
              "NIVATOPIC PLUS": "NIVATOPIC PLUS"
            },
            "TRUTEK CORP": {
              "NASALGUARD": "NASALGUARD"
            },
            "UROCARE PRODUCTS, INC.": {
              "ITCH-ENDER!": "ITCH-ENDER!"
            },
            "WALGREENS": {
              "FRESHN FEMININE DEODORANT": "FRESHN FEMININE DEODORANT",
              "LIQUID BANDAGE": "LIQUID BANDAGE"
            },
            "WATER JEL TECHNOLOGIES": {
              "RADIADERM SYSTEM R1+R2": "RADIADERM SYSTEM R1+R2",
              "RADIADERM SYSTEM R2": "RADIADERM SYSTEM R2"
            },
            "WORKERS CHOICE PHARMACY": {
              "CURE-ALL": "CURE-ALL"
            },
            "XENNA": {
              "NONYX": "NONYX"
            }
          }
        },
        "Misc. Topical": {
          "Astringents": {
            "ADVANTICE HEALTH": {
              "BALMEX COMPLETE PROTECTION": "BALMEX COMPLETE PROTECTION",
              "DOMEBORO": "DOMEBORO"
            },
            "AMERICAN SALES COMPANY": {
              "COTTONTAILS DIAPER RASH": "COTTONTAILS DIAPER RASH",
              "COTTONTAILS DIAPER RASH CREAMY": "COTTONTAILS DIAPER RASH CREAMY",
              "ZINC OXIDE": "ZINC OXIDE"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP DIAPER RASH": "GNP DIAPER RASH",
              "GNP DIAPER RASH CREAMY": "GNP DIAPER RASH CREAMY",
              "GNP ZINC OXIDE": "GNP ZINC OXIDE"
            },
            "BAUSCH HEALTH": {
              "PEDI-BORO SOAK": "PEDI-BORO SOAK"
            },
            "BAYER CONSUMER": {
              "MEXSANA": "MEXSANA"
            },
            "BEIERSDORF DERMA": {
              "AQUAPHOR 3 IN 1 DIAPER RASH": "AQUAPHOR 3 IN 1 DIAPER RASH"
            },
            "BERGEN BRUNSWIG": {
              "GNP CALAMINE PHENOLATED": "GNP CALAMINE PHENOLATED",
              "GNP ZINC OXIDE": "GNP ZINC OXIDE"
            },
            "BIRCHWOOD LABS": {
              "A.E.R. TRAVELER": "A.E.R. TRAVELER",
              "A.E.R. WITCH HAZEL": "A.E.R. WITCH HAZEL"
            },
            "CHAIN DRUG CONSORTIUM": {
              "CALAMINE": "CALAMINE",
              "CALAMINE PHENOLATED": "CALAMINE PHENOLATED",
              "DIAPER RASH": "DIAPER RASH",
              "HYGIENIC CLEANSING": "HYGIENIC CLEANSING",
              "ZINC OXIDE": "ZINC OXIDE"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC CALAMINE": "QC CALAMINE"
            },
            "CHATTEM INC": {
              "BALMEX": "BALMEX",
              "BALMEX ADULT CARE": "BALMEX ADULT CARE"
            },
            "COVIDIEN MEDICAL SUPPLIES": {
              "SOOTHING OINTMENT": "SOOTHING OINTMENT"
            },
            "CSI PHARM": {
              "SOOZE PADS": "SOOZE PADS"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS ASTRINGENT SOLUTION": "CVS ASTRINGENT SOLUTION",
              "CVS DIAPER RASH": "CVS DIAPER RASH",
              "CVS HYGIENIC CLEANSING": "CVS HYGIENIC CLEANSING",
              "CVS QUICK RELIEF DIAPER RASH": "CVS QUICK RELIEF DIAPER RASH",
              "CVS ZINC OXIDE": "CVS ZINC OXIDE"
            },
            "DENISON LABS": {
              "ZINC OXIDE": "ZINC OXIDE"
            },
            "DINNO PHARMACEUTICALS": {
              "BABY EASE": "BABY EASE"
            },
            "DSE HEALTHCARE SOLUTIONS": {
              "BABY ANTI MONKEY BUTT": "BABY ANTI MONKEY BUTT"
            },
            "DYNAREX CORPORATION": {
              "ZINC OXIDE": "ZINC OXIDE"
            },
            "EMJAY LABORATORIES": {
              "PINXAV": "PINXAV"
            },
            "EQUALINE": {
              "EQL BABY BASICS DIAPER RASH": "EQL BABY BASICS DIAPER RASH"
            },
            "FAGRON": {
              "CALAMINE": "CALAMINE",
              "PHARMABASE BARRIER": "PHARMABASE BARRIER",
              "TANNIC ACID": "TANNIC ACID",
              "ZINC OXIDE": "ZINC OXIDE"
            },
            "FLEET PHARMACEUTICALS": {
              "BOUDREAUXS BUTT PASTE": "BOUDREAUXS BUTT PASTE"
            },
            "FOUGERA": {
              "ZINC OXIDE": "ZINC OXIDE"
            },
            "GENTELL": {
              "ZINC OXIDE": "ZINC OXIDE"
            },
            "GERITREX CORPORATION": {
              "ZINC OXIDE": "ZINC OXIDE"
            },
            "GM PHARMACEUTICALS": {
              "COZIMA": "COZIMA",
              "Z-BUM": "Z-BUM"
            },
            "GORDON": {
              "BORO-PACKS": "BORO-PACKS"
            },
            "HUMCO": {
              "ALUMINUM ACETATE": "ALUMINUM ACETATE",
              "CALAMINE PHENOLATED": "CALAMINE PHENOLATED"
            },
            "J & J CONSUMER PRODUCTS": {
              "AVEENO BABY SOOTHING RELIEF": "AVEENO BABY SOOTHING RELIEF",
              "DESITIN": "DESITIN",
              "DESITIN MAXIMUM STRENGTH": "DESITIN MAXIMUM STRENGTH",
              "DESITIN RAPID RELIEF": "DESITIN RAPID RELIEF"
            },
            "KALCHEM INTERNATIONAL": {
              "CALAMINE": "CALAMINE"
            },
            "KIMBERLY-CLARK": {
              "HUGGIES DIAPER RASH": "HUGGIES DIAPER RASH"
            },
            "LEADER BRAND PRODUCTS": {
              "DIAPER RASH": "DIAPER RASH",
              "ZINC OXIDE": "ZINC OXIDE"
            },
            "LETCO MEDICAL": {
              "CALAMINE": "CALAMINE",
              "TANNIC ACID": "TANNIC ACID"
            },
            "MAINPOINTE PHARMACEUTICALS": {
              "DR SMITHS ADULT BARRIER": "DR SMITHS ADULT BARRIER",
              "DR SMITHS DIAPER": "DR SMITHS DIAPER",
              "DR SMITHS DIAPER RASH": "DR SMITHS DIAPER RASH"
            },
            "MAJOR PHARMACEUTICALS": {
              "MEDI PADS": "MEDI PADS"
            },
            "MCKESSON SUNMARK": {
              "SM CALAMINE": "SM CALAMINE",
              "SM CALAMINE PHENOLATED": "SM CALAMINE PHENOLATED",
              "SM HYGIENIC CLEANSING": "SM HYGIENIC CLEANSING"
            },
            "MEDISCA": {
              "CALAMINE": "CALAMINE",
              "TANNIC ACID": "TANNIC ACID"
            },
            "MEIJER": {
              "MEIJER CALAMINE": "MEIJER CALAMINE",
              "MEIJER ZINC OXIDE": "MEIJER ZINC OXIDE"
            },
            "MERICON": {
              "DELAZINC": "DELAZINC"
            },
            "MISSION": {
              "DR SMITHS RASH + SKIN": "DR SMITHS RASH + SKIN"
            },
            "NEW WORLD IMPORTS": {
              "ZINC OXIDE": "ZINC OXIDE"
            },
            "NIVAGEN PHARMACEUTICALS": {
              "ZINC OXIDE": "ZINC OXIDE"
            },
            "NOVANA MEDICAL": {
              "SKIN PROTECTANT": "SKIN PROTECTANT"
            },
            "PCCA": {
              "CALAMINE": "CALAMINE",
              "TANNIC ACID": "TANNIC ACID"
            },
            "PERRIGO": {
              "CALAMINE": "CALAMINE"
            },
            "PERSON COVEY": {
              "XERAC AC": "XERAC AC"
            },
            "PHARMADERM": {
              "A-MANTLE": "A-MANTLE"
            },
            "QUALITY CARE": {
              "CALAMINE": "CALAMINE"
            },
            "RANDOB LABS": {
              "BALMEX ADULT CARE": "BALMEX ADULT CARE",
              "BALMEX COMPLETE PROTECTION": "BALMEX COMPLETE PROTECTION"
            },
            "RITE AID CORPORATION": {
              "DIAPER RASH": "DIAPER RASH",
              "RA DIAPER RASH": "RA DIAPER RASH",
              "RA HEMORRHOIDAL MEDICATED": "RA HEMORRHOIDAL MEDICATED",
              "RA ZINC OXIDE": "RA ZINC OXIDE"
            },
            "RUGBY LABORATORIES": {
              "ZINC OXIDE": "ZINC OXIDE"
            },
            "SELECT BRAND": {
              "SB HEMORRHOID": "SB HEMORRHOID"
            },
            "SHEFFIELD PHARMACEUTICALS": {
              "DIAPER RASH": "DIAPER RASH"
            },
            "SMITH & NEPHEW UNITED": {
              "SECURA EXTRA PROTECTIVE": "SECURA EXTRA PROTECTIVE",
              "SECURA PROTECTIVE": "SECURA PROTECTIVE"
            },
            "SPECTRUM": {
              "ALUMINUM ACETATE": "ALUMINUM ACETATE",
              "CALAMINE": "CALAMINE",
              "TANNIC ACID": "TANNIC ACID"
            },
            "STRATUS PHARMACEUTICALS": {
              "HYPERCARE": "HYPERCARE"
            },
            "SUMMERS LABS": {
              "TRIPLE PASTE": "TRIPLE PASTE",
              "TRIPLE PASTE SP": "TRIPLE PASTE SP"
            },
            "SWISS-AMERICAN PRODUCTS": {
              "ELTA SEAL MOISTURE BARRIER": "ELTA SEAL MOISTURE BARRIER"
            },
            "TAGI PHARMA": {
              "ALUM SULFATE-CA ACETATE": "ALUM SULFATE-CA ACETATE"
            },
            "TEVA PHARMACEUTICALS USA": {
              "ZINC OXIDE": "ZINC OXIDE"
            },
            "TOPCO": {
              "TIPPY TOES DIAPER RASH": "TIPPY TOES DIAPER RASH"
            },
            "TRIFECTA PHARMACEUTICALS": {
              "ZINC OXIDE": "ZINC OXIDE"
            },
            "WAL-MART": {
              "EQ DIAPER RASH": "EQ DIAPER RASH"
            },
            "WALGREENS": {
              "BABY DIAPER RASH": "BABY DIAPER RASH",
              "DIAPER RASH": "DIAPER RASH",
              "HYGIENIC CLEANSING": "HYGIENIC CLEANSING",
              "ZINC OXIDE": "ZINC OXIDE"
            }
          },
          "Eyelid Cleansers & Lubricants": {
            "AKORN CONSUMER": {
              "STERILID": "STERILID"
            },
            "ALCON VISION": {
              "EYE-SCRUB": "EYE-SCRUB",
              "SYSTANE LID WIPES": "SYSTANE LID WIPES"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS CLEANSING EYELID WIPES": "CVS CLEANSING EYELID WIPES",
              "CVS EYELID WIPES EXTRA STR": "CVS EYELID WIPES EXTRA STR"
            },
            "EQUALINE": {
              "EQL MAKEUP REMOVER TOWELETTES": "EQL MAKEUP REMOVER TOWELETTES"
            },
            "MARNEL PHARMACEUTICAL": {
              "HYPOCYN": "HYPOCYN"
            },
            "MCKESSON": {
              "HM EYELID WIPES": "HM EYELID WIPES"
            },
            "NEUTROGENA": {
              "NEUTROGENA MAKEUP REMOVER": "NEUTROGENA MAKEUP REMOVER"
            },
            "NOVABAY PHARMACEUTICALS": {
              "AVENOVA": "AVENOVA",
              "AVENOVA/NEUTROX": "AVENOVA/NEUTROX"
            },
            "OCULUS INNOVATIVE SCIENCES": {
              "ACUICYN": "ACUICYN"
            },
            "OCUSOFT": {
              "OCUSOFT BABY EYELID & EYELASH": "OCUSOFT BABY EYELID & EYELASH",
              "OCUSOFT CLEAN \\'N SPRAY": "OCUSOFT CLEAN \\'N SPRAY",
              "OCUSOFT EYELID CLEANSING": "OCUSOFT EYELID CLEANSING",
              "OCUSOFT HYPOCHLOR": "OCUSOFT HYPOCHLOR",
              "OCUSOFT LID SCRUB": "OCUSOFT LID SCRUB",
              "OCUSOFT LID SCRUB ALLERGY": "OCUSOFT LID SCRUB ALLERGY",
              "OCUSOFT LID SCRUB FOAMING": "OCUSOFT LID SCRUB FOAMING",
              "OCUSOFT LID SCRUB ORIGINAL": "OCUSOFT LID SCRUB ORIGINAL",
              "OCUSOFT LID SCRUB PLUS": "OCUSOFT LID SCRUB PLUS",
              "OCUSOFT LID SCRUB PLUS PLATINU": "OCUSOFT LID SCRUB PLUS PLATINU",
              "OUST DEMODEX CLEANSER EXT ST": "OUST DEMODEX CLEANSER EXT ST",
              "TEARS AGAIN ADVANCED EYELID": "TEARS AGAIN ADVANCED EYELID"
            },
            "PARAGON BIOTECK": {
              "ZENOPTIQ": "ZENOPTIQ"
            },
            "RITE AID CORPORATION": {
              "RA EYELID WIPES": "RA EYELID WIPES",
              "RA MAKEUP REMOVER EYELID": "RA MAKEUP REMOVER EYELID"
            },
            "SONOMA PHARMACEUTICALS": {
              "ACUICYN": "ACUICYN"
            },
            "WALGREENS": {
              "CLEANSING EYELID": "CLEANSING EYELID"
            }
          },
          "Insect Repellents": {
            "3M CONSUMER HEALTH CARE": {
              "ULTRATHON INSECT REPELLENT": "ULTRATHON INSECT REPELLENT",
              "ULTRATHON INSECT REPELLENT 8": "ULTRATHON INSECT REPELLENT 8"
            },
            "ACE HEALTHY": {
              "EAGLE WATCH MOSQUITO ELIM": "EAGLE WATCH MOSQUITO ELIM"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS INSECT REPELLENT": "CVS INSECT REPELLENT",
              "CVS TOTAL HOME INSECT REPEL": "CVS TOTAL HOME INSECT REPEL"
            },
            "RANGER READY REPELLENTS": {
              "RANGER READY REPELLENT": "RANGER READY REPELLENT"
            },
            "S.C. JOHNSON": {
              "OFF ACTIVE": "OFF ACTIVE",
              "OFF DEEP WOODS": "OFF DEEP WOODS",
              "OFF DEEP WOODS DRY": "OFF DEEP WOODS DRY",
              "OFF DEEP WOODS SPORTSMEN": "OFF DEEP WOODS SPORTSMEN",
              "OFF DEEP WOODS TOWELETTES": "OFF DEEP WOODS TOWELETTES",
              "OFF FAMILYCARE CLEAN FEEL": "OFF FAMILYCARE CLEAN FEEL",
              "OFF FAMILYCARE TROPICAL FRESH": "OFF FAMILYCARE TROPICAL FRESH",
              "OFF FAMILYCARE UNSCENTED": "OFF FAMILYCARE UNSCENTED",
              "OFF SMOOTH & DRY": "OFF SMOOTH & DRY"
            },
            "SAFFETA": {
              "MAXI DEET": "MAXI DEET",
              "SAWYER INSECT REPELLENT": "SAWYER INSECT REPELLENT"
            },
            "SOLSKYN PERSONAL CARE": {
              "BULL FROG MOSQUITO COAST": "BULL FROG MOSQUITO COAST"
            },
            "SPECTRUM BRANDS": {
              "CUTTER": "CUTTER",
              "CUTTER ALL FAMILY": "CUTTER ALL FAMILY",
              "CUTTER ALL FAMILY WIPES": "CUTTER ALL FAMILY WIPES",
              "CUTTER BACKWOODS": "CUTTER BACKWOODS",
              "CUTTER BACKWOODS DRY": "CUTTER BACKWOODS DRY",
              "CUTTER DRY": "CUTTER DRY",
              "CUTTER LEMON EUCALYPTUS": "CUTTER LEMON EUCALYPTUS",
              "CUTTER NATURAL": "CUTTER NATURAL",
              "CUTTER SKINSATIONS": "CUTTER SKINSATIONS",
              "CUTTER SPORT": "CUTTER SPORT",
              "REPEL 100": "REPEL 100",
              "REPEL FAMILY": "REPEL FAMILY",
              "REPEL FAMILY DRY": "REPEL FAMILY DRY",
              "REPEL HUNTERS FORMULA": "REPEL HUNTERS FORMULA",
              "REPEL LEMON EUCALYPTUS": "REPEL LEMON EUCALYPTUS",
              "REPEL MOSQUITO WIPES": "REPEL MOSQUITO WIPES",
              "REPEL SPORTSMEN": "REPEL SPORTSMEN",
              "REPEL SPORTSMEN DRY": "REPEL SPORTSMEN DRY",
              "REPEL SPORTSMEN MAX": "REPEL SPORTSMEN MAX",
              "REPEL TICK DEFENSE": "REPEL TICK DEFENSE"
            },
            "TENDER CORPORATION": {
              "NATRAPEL": "NATRAPEL",
              "NATRAPEL 12-HOUR TICK/INSECT": "NATRAPEL 12-HOUR TICK/INSECT"
            },
            "WISCONSIN PHARMACAL COMPANY": {
              "COLEMAN 100 MAX CONTINUOUS SPR": "COLEMAN 100 MAX CONTINUOUS SPR",
              "COLEMAN 100 MAX INSECT REPEL": "COLEMAN 100 MAX INSECT REPEL",
              "COLEMAN BOTANICALS INSECT REP": "COLEMAN BOTANICALS INSECT REP",
              "COLEMAN INSECT REPEL HIGH&DRY": "COLEMAN INSECT REPEL HIGH&DRY",
              "COLEMAN INSECT REPEL SPORTSMEN": "COLEMAN INSECT REPEL SPORTSMEN",
              "COLEMAN SKINSMART INSECT REPEL": "COLEMAN SKINSMART INSECT REPEL"
            }
          },
          "Intimacy Products": {
            "TRIMARC LABORATORIES": {
              "YES FOR WOMEN": "YES FOR WOMEN"
            }
          },
          "Lubricants": {
            "APOTHECUS": {
              "VCF PERSONAL LUBRICANT": "VCF PERSONAL LUBRICANT"
            },
            "BAYER CONSUMER": {
              "GYNE-MOISTRIN": "GYNE-MOISTRIN"
            },
            "BERGEN BRUNSWIG": {
              "GNP LUBRICATING JELLY": "GNP LUBRICATING JELLY"
            },
            "BIOFILM": {
              "ASTROGLIDE": "ASTROGLIDE"
            },
            "CHAIN DRUG CONSORTIUM": {
              "LUBRICATING JELLY": "LUBRICATING JELLY",
              "PERSONAL LUBRICANT": "PERSONAL LUBRICANT",
              "PERSONAL LUBRICANT WARMING": "PERSONAL LUBRICANT WARMING"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS LUBRICATING JELLY": "CVS LUBRICATING JELLY",
              "CVS PERSONAL LUBRICANT/MOIST": "CVS PERSONAL LUBRICANT/MOIST"
            },
            "EQUALINE": {
              "EQL LUBRICATING JELLY": "EQL LUBRICATING JELLY"
            },
            "GERITREX CORPORATION": {
              "GERI-LUB": "GERI-LUB"
            },
            "GOOD CLEAN LOVE": {
              "ALMOST NAKED": "ALMOST NAKED"
            },
            "HR PHARMACEUTICALS": {
              "H-R LUBRICATING JELLY": "H-R LUBRICATING JELLY",
              "H-R LUBRICATING JELLY 2-X": "H-R LUBRICATING JELLY 2-X",
              "H-R LUBRICATING JELLY ONE SHOT": "H-R LUBRICATING JELLY ONE SHOT",
              "SURGILUBE": "SURGILUBE"
            },
            "J & J MEDICAL": {
              "K-Y LUBRICATING": "K-Y LUBRICATING"
            },
            "LEADER BRAND PRODUCTS": {
              "LUBRICATING JELLY": "LUBRICATING JELLY"
            },
            "MAINPOINTE PHARMACEUTICALS": {
              "MAXILUBE": "MAXILUBE"
            },
            "MAYER LABORATORIES": {
              "AQUA LUBE": "AQUA LUBE",
              "AQUA LUBE PLUS": "AQUA LUBE PLUS"
            },
            "MCKESSON SUNMARK": {
              "SM LUBRICATING JELLY": "SM LUBRICATING JELLY"
            },
            "MEDICINE SHOPPE": {
              "LUBRICATING JELLY": "LUBRICATING JELLY"
            },
            "MEDLINE INDUSTRIES": {
              "LUBRICATING JELLY": "LUBRICATING JELLY"
            },
            "PERRIGO": {
              "LIQUA-GEL": "LIQUA-GEL"
            },
            "PERRIGO PHARMACEUTICALS": {
              "PERSONAL LUBRICANT": "PERSONAL LUBRICANT"
            },
            "PUBLIX SUPER MARKETS INC.": {
              "PX PERSONAL LUBRICANT": "PX PERSONAL LUBRICANT"
            },
            "RECKITT BENCKISER": {
              "K-Y": "K-Y",
              "K-Y JELLY": "K-Y JELLY",
              "K-Y LOVE PASSION": "K-Y LOVE PASSION",
              "K-Y NATURAL FEELING ALOE VERA": "K-Y NATURAL FEELING ALOE VERA",
              "K-Y NATURAL FEELING BOTANICAL": "K-Y NATURAL FEELING BOTANICAL",
              "K-Y TRUE FEEL DELUXE": "K-Y TRUE FEEL DELUXE",
              "K-Y ULTRAGEL": "K-Y ULTRAGEL",
              "K-Y WARMING": "K-Y WARMING"
            },
            "RITE AID CORPORATION": {
              "RA INTIMACY GELS/HIM/HER": "RA INTIMACY GELS/HIM/HER",
              "RA LUBRICANT": "RA LUBRICANT",
              "RA LUBRICATING": "RA LUBRICATING",
              "RA PERSONAL LUBRICANT": "RA PERSONAL LUBRICANT",
              "RA PERSONAL LUBRICATING": "RA PERSONAL LUBRICATING",
              "RA PERSONAL LUBRICATING JELLY": "RA PERSONAL LUBRICATING JELLY",
              "RA WARMING JELLY LUBRICANT": "RA WARMING JELLY LUBRICANT"
            },
            "SELECT BRAND": {
              "SB LUBRICATING JELLY": "SB LUBRICATING JELLY"
            },
            "WAL-MART": {
              "EQ PERSONAL LUBRICANT JELLY": "EQ PERSONAL LUBRICANT JELLY",
              "EQ PERSONAL LUBRICATING": "EQ PERSONAL LUBRICATING"
            },
            "WALGREENS": {
              "FEMININE MOISTURIZER/LUBRICANT": "FEMININE MOISTURIZER/LUBRICANT",
              "LUBRICATING JELLY": "LUBRICATING JELLY",
              "MASSAGE/LUBRICANT WARMING": "MASSAGE/LUBRICANT WARMING",
              "PERSONAL LUBRICANT WARMING": "PERSONAL LUBRICANT WARMING"
            }
          },
          "Misc. Topical": {
            "AKRON PHARMA": {
              "HEMORRHOIDAL HYGIENE": "HEMORRHOIDAL HYGIENE"
            },
            "AMERICAN SALES COMPANY": {
              "HEMORRHOIDAL": "HEMORRHOIDAL",
              "WITCH HAZEL": "WITCH HAZEL"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP HYGIENIC CLEANSING": "GNP HYGIENIC CLEANSING",
              "GNP MEDICATED WIPES": "GNP MEDICATED WIPES"
            },
            "BAUSCH HEALTH": {
              "KINERASE": "KINERASE"
            },
            "BAYER CONSUMER": {
              "ALOE AFTERSUN": "ALOE AFTERSUN"
            },
            "BERGEN BRUNSWIG": {
              "GNP HYGIENIC CLEANSING": "GNP HYGIENIC CLEANSING",
              "GNP MEDICATED WIPES": "GNP MEDICATED WIPES",
              "GNP SALINE WOUND WASH": "GNP SALINE WOUND WASH",
              "GNP WITCH HAZEL": "GNP WITCH HAZEL"
            },
            "BLAIREX LABS": {
              "WOUND WASH SALINE": "WOUND WASH SALINE"
            },
            "CHAIN DRUG CONSORTIUM": {
              "ANTI-PERSPIRANT": "ANTI-PERSPIRANT",
              "MEDICATED WIPES": "MEDICATED WIPES",
              "PROSIL": "PROSIL",
              "WITCH HAZEL": "WITCH HAZEL"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC MEDICATED PRE-MOISTENED": "QC MEDICATED PRE-MOISTENED",
              "QC WITCH HAZEL": "QC WITCH HAZEL"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS AFTERSUN ALOE VERA": "CVS AFTERSUN ALOE VERA",
              "CVS AFTERSUN ALOE VERA SOOTHNG": "CVS AFTERSUN ALOE VERA SOOTHNG",
              "CVS MEDICATED WIPES": "CVS MEDICATED WIPES",
              "CVS MEDICATED WITCH HAZEL": "CVS MEDICATED WITCH HAZEL",
              "CVS SALINE WOUND WASH": "CVS SALINE WOUND WASH",
              "CVS WITCH HAZEL": "CVS WITCH HAZEL"
            },
            "DENISON LABS": {
              "BORIC ACID": "BORIC ACID",
              "GREEN SOAP": "GREEN SOAP"
            },
            "DERMARITE": {
              "DERMAMED": "DERMAMED",
              "SAFE WASH": "SAFE WASH"
            },
            "DERMIRA": {
              "QBREXZA": "QBREXZA"
            },
            "DICKINSON BRANDS INC.": {
              "DICKINSONS WITCH HAZEL": "DICKINSONS WITCH HAZEL",
              "TN DICKINSONS WITCH HAZEL": "TN DICKINSONS WITCH HAZEL"
            },
            "EQUALINE": {
              "EQL ALOE VERA AFTER SUN": "EQL ALOE VERA AFTER SUN",
              "EQL MEDICATED WIPES": "EQL MEDICATED WIPES",
              "EQL WITCH HAZEL": "EQL WITCH HAZEL"
            },
            "FAGRON": {
              "ALUMINUM CHLORIDE": "ALUMINUM CHLORIDE",
              "BORIC ACID": "BORIC ACID"
            },
            "FREEDOM PHARMACEUTICALS": {
              "ALUMINUM CHLORIDE HEXAHYDRATE": "ALUMINUM CHLORIDE HEXAHYDRATE"
            },
            "GEISS DESTIN & DUNN": {
              "GOODSENSE MEDICATED": "GOODSENSE MEDICATED",
              "GOODSENSE MEDICATED WIPES": "GOODSENSE MEDICATED WIPES"
            },
            "GENESIS PHARMACEUTICAL": {
              "HYDROPEL": "HYDROPEL"
            },
            "GERI-CARE": {
              "HEMORRHOIDAL HYGIENE": "HEMORRHOIDAL HYGIENE"
            },
            "GERITREX CORPORATION": {
              "GREEN SOAP": "GREEN SOAP",
              "HEMORRHOIDAL HYGIENE": "HEMORRHOIDAL HYGIENE",
              "ICHTHAMMOL": "ICHTHAMMOL"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "PREPARATION H": "PREPARATION H",
              "PREPARATION H FOR WOMEN": "PREPARATION H FOR WOMEN",
              "PREPARATION H TOTABLES WIPES": "PREPARATION H TOTABLES WIPES"
            },
            "HUMCO": {
              "BORIC ACID": "BORIC ACID",
              "GREEN SOAP": "GREEN SOAP"
            },
            "INTEGRA LIFESCIENCES": {
              "DERMAGRAN": "DERMAGRAN",
              "DERMAGRAN SKIN PROTECTANT": "DERMAGRAN SKIN PROTECTANT"
            },
            "JOHNSON&JOHNSON HEALTHCARE": {
              "TUCKS": "TUCKS",
              "TUCKS MEDICATED COOLING": "TUCKS MEDICATED COOLING"
            },
            "LAKE CONSUMER PRODUCTS": {
              "MG217 FIRST AID ICHTHAMMOL": "MG217 FIRST AID ICHTHAMMOL"
            },
            "LEADER BRAND PRODUCTS": {
              "ALOE VERA": "ALOE VERA",
              "HEMORRHOIDAL": "HEMORRHOIDAL",
              "MEDICATED CLEANSING": "MEDICATED CLEANSING",
              "SALINE WOUND WASH": "SALINE WOUND WASH"
            },
            "LETCO MEDICAL": {
              "ALUMINUM CHLORIDE HEXAHYDRATE": "ALUMINUM CHLORIDE HEXAHYDRATE"
            },
            "MAJOR PHARMACEUTICALS": {
              "MEDI-PADS": "MEDI-PADS"
            },
            "MCKESSON": {
              "HM MEDICATED COOLING": "HM MEDICATED COOLING",
              "HM WITCH HAZEL": "HM WITCH HAZEL"
            },
            "MCKESSON SUNMARK": {
              "SM MEDICATED WIPES": "SM MEDICATED WIPES",
              "SM WITCH HAZEL": "SM WITCH HAZEL"
            },
            "MEDISCA": {
              "ALUMINUM CHLORIDE ANHYDROUS": "ALUMINUM CHLORIDE ANHYDROUS"
            },
            "MOLNLYCKE HEALTH CARE": {
              "NORMLGEL": "NORMLGEL"
            },
            "NATIONAL BIO GREEN SCIENCES": {
              "PROSILK": "PROSILK"
            },
            "NATIONAL VITAMIN": {
              "ALOE VERA": "ALOE VERA",
              "ALOE VERA MOISTURIZING": "ALOE VERA MOISTURIZING"
            },
            "NOVAPLUS/BIRCHWOOL LABS": {
              "PRE-MOISTENED WITCH HAZEL": "PRE-MOISTENED WITCH HAZEL"
            },
            "PCCA": {
              "ALUMINUM CHLORIDE HEXAHYDRATE": "ALUMINUM CHLORIDE HEXAHYDRATE",
              "ARNICA FLOWER": "ARNICA FLOWER",
              "BORIC ACID": "BORIC ACID",
              "ICHTHAMMOL": "ICHTHAMMOL"
            },
            "PERSON COVEY": {
              "DRYSOL": "DRYSOL"
            },
            "QUALITY CARE": {
              "DRYSOL": "DRYSOL"
            },
            "QUEST PRODUCTS": {
              "SUN BURNT AFTER-SUN GEL": "SUN BURNT AFTER-SUN GEL",
              "SUN BURNT DAILY MOISTURIZING": "SUN BURNT DAILY MOISTURIZING"
            },
            "RITE AID CORPORATION": {
              "RA HEMORRHOID RELIEF MEDICATED": "RA HEMORRHOID RELIEF MEDICATED",
              "RA MEDICATED WIPES": "RA MEDICATED WIPES",
              "RA RENEWAL SOOTHING ALOE VERA": "RA RENEWAL SOOTHING ALOE VERA",
              "RA SALINE WOUND WASH": "RA SALINE WOUND WASH",
              "RA WITCH HAZEL": "RA WITCH HAZEL"
            },
            "SHEFFIELD PHARMACEUTICALS": {
              "ALOE 99": "ALOE 99",
              "SUNDANCE": "SUNDANCE"
            },
            "SPECTRUM": {
              "ALUMINUM CHLORIDE HEXAHYDRATE": "ALUMINUM CHLORIDE HEXAHYDRATE",
              "BORIC ACID": "BORIC ACID",
              "GREEN SOAP": "GREEN SOAP",
              "ICHTHAMMOL": "ICHTHAMMOL",
              "WITCH HAZEL": "WITCH HAZEL"
            },
            "SPENCO MEDICAL CORPORATION": {
              "2ND SKIN SCARGEL": "2ND SKIN SCARGEL"
            },
            "TARGET": {
              "TGT MEDICATED HEMORRHOIDAL": "TGT MEDICATED HEMORRHOIDAL",
              "TGT MEDICATED WIPE/WITCH HAZEL": "TGT MEDICATED WIPE/WITCH HAZEL"
            },
            "TEVA PHARMACEUTICALS USA": {
              "TROPICAL GOLD ALOE VERA": "TROPICAL GOLD ALOE VERA"
            },
            "TYCHASIS": {
              "COATS ALOE": "COATS ALOE"
            },
            "VI-JON": {
              "WITCH HAZEL": "WITCH HAZEL"
            },
            "WAL-MART": {
              "EQ HEMORRHOIDAL": "EQ HEMORRHOIDAL",
              "EQ HYGIENIC CLEANSING WIPES": "EQ HYGIENIC CLEANSING WIPES"
            },
            "WALGREENS": {
              "ALOE VERA": "ALOE VERA",
              "ALOE VERA REPLENISHING BODY": "ALOE VERA REPLENISHING BODY",
              "MEDICATED WIPES": "MEDICATED WIPES",
              "SALINE WOUND WASH": "SALINE WOUND WASH",
              "WITCH HAZEL": "WITCH HAZEL"
            },
            "WINCHESTER LABORATORIES": {
              "SALJET": "SALJET",
              "SALJET RINSE": "SALJET RINSE"
            }
          },
          "Misc. Topical Combinations": {
            "A-S MEDICATION SOLUTIONS": {
              "CALAMINE": "CALAMINE"
            },
            "AMERICAN SALES COMPANY": {
              "CALAMINE": "CALAMINE",
              "MEDICATED BODY": "MEDICATED BODY"
            },
            "BAYER CONSUMER": {
              "A & D ZINC OXIDE": "A & D ZINC OXIDE"
            },
            "BERGEN BRUNSWIG": {
              "GNP CALAMINE": "GNP CALAMINE"
            },
            "CALMOSEPTINE": {
              "CALMOSEPTINE": "CALMOSEPTINE"
            },
            "CHAIN DRUG CONSORTIUM": {
              "MEDICATED BODY": "MEDICATED BODY"
            },
            "CHATTEM INC": {
              "GOLD BOND": "GOLD BOND",
              "GOLD BOND EXTRA STRENGTH": "GOLD BOND EXTRA STRENGTH"
            },
            "CONVATEC": {
              "SENSI-CARE PROTECTIVE BARRIER": "SENSI-CARE PROTECTIVE BARRIER"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS BODY POWDER MEDICATED": "CVS BODY POWDER MEDICATED",
              "CVS MULTI-PURPOSE": "CVS MULTI-PURPOSE",
              "CVS PROTECTIVE": "CVS PROTECTIVE",
              "CVS ZINC OXIDE DIAPER": "CVS ZINC OXIDE DIAPER"
            },
            "FIRST AID RESEARCH CORP": {
              "ZINC-OXYDE PLUS": "ZINC-OXYDE PLUS"
            },
            "GEISS DESTIN & DUNN": {
              "GOODSENSE CALAMINE": "GOODSENSE CALAMINE"
            },
            "GERITREX CORPORATION": {
              "CALADROX": "CALADROX"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "LAMISIL AT": "LAMISIL AT"
            },
            "INSIGHT PHARMACEUTICALS": {
              "CALDESENE BABY": "CALDESENE BABY"
            },
            "LANTISEPTIC": {
              "LANTISEPTIC MULTI-PURPOSE": "LANTISEPTIC MULTI-PURPOSE"
            },
            "LINKS MEDICAL PRODUCTS": {
              "CHAMOSYN": "CHAMOSYN"
            },
            "MAJOR PHARMACEUTICALS": {
              "CALAMINE": "CALAMINE"
            },
            "MCKESSON": {
              "HM CALAMINE": "HM CALAMINE"
            },
            "MCKESSON SUNMARK": {
              "SM MEDICATED BODY": "SM MEDICATED BODY"
            },
            "MEDLINE/DERMAL MANAGEMENT": {
              "PHYTOPLEX Z-GUARD": "PHYTOPLEX Z-GUARD",
              "REMEDY CALAZIME": "REMEDY CALAZIME"
            },
            "MEDTECH": {
              "LITTLE FEVERS COOLING CLOTHS": "LITTLE FEVERS COOLING CLOTHS"
            },
            "MISSION": {
              "DR SMITHS RASH + SKIN": "DR SMITHS RASH + SKIN"
            },
            "NUCARE PHARMACEUTICALS": {
              "NUSURGEPAK SURGICAL PREP/CARE": "NUSURGEPAK SURGICAL PREP/CARE"
            },
            "PATCHWERX LABS": {
              "DERMAWERX SURGICAL PLUS PAK": "DERMAWERX SURGICAL PLUS PAK"
            },
            "PREFERRED PHARMACEUTICALS": {
              "CALAMINE": "CALAMINE"
            },
            "PUBLIX SUPER MARKETS INC.": {
              "PX CALAMINE": "PX CALAMINE"
            },
            "PURE TEK": {
              "DERMACINRX CLORHEXACIN": "DERMACINRX CLORHEXACIN",
              "DERMACINRX SURGICAL COMBOPAK": "DERMACINRX SURGICAL COMBOPAK",
              "DERMACINRX SURGICAL PHARMAPAK": "DERMACINRX SURGICAL PHARMAPAK"
            },
            "RISING PHARMACEUTICALS": {
              "RISAMINE": "RISAMINE"
            },
            "RITE AID CORPORATION": {
              "RA BODY POWDER MEDICATED": "RA BODY POWDER MEDICATED",
              "RA CALAMINE": "RA CALAMINE"
            },
            "RUGBY LABORATORIES": {
              "MENTHOL-ZINC OXIDE": "MENTHOL-ZINC OXIDE"
            },
            "SAGE PRODUCTS": {
              "COMFORT CLEAN/COMFORT SHIELD": "COMFORT CLEAN/COMFORT SHIELD"
            },
            "SHEFFIELD PHARMACEUTICALS": {
              "SAYMAN SALVE": "SAYMAN SALVE"
            },
            "SMITH & NEPHEW UNITED": {
              "SECURA EPC SKIN CARE STARTER": "SECURA EPC SKIN CARE STARTER",
              "SECURA PERSONAL SKIN CARE": "SECURA PERSONAL SKIN CARE",
              "SECURA STARTER": "SECURA STARTER",
              "SECURA TWO-STEP": "SECURA TWO-STEP"
            },
            "TERRAIN PHARMACEUTICALS": {
              "PRE & POST SX POUCH": "PRE & POST SX POUCH"
            },
            "TOPCO": {
              "CALAMINE": "CALAMINE"
            },
            "VI-JON": {
              "CALAMINE": "CALAMINE"
            },
            "WAL-MART": {
              "EQ CALAMINE": "EQ CALAMINE",
              "EQ MEDICATED BODY": "EQ MEDICATED BODY"
            },
            "WALGREENS": {
              "CALAMINE": "CALAMINE",
              "CALAMINE-ZINC OXIDE": "CALAMINE-ZINC OXIDE",
              "MOISTURE BARRIER": "MOISTURE BARRIER"
            },
            "WHYTEMAN LABS": {
              "WHYTEDERM SURGIPAK": "WHYTEDERM SURGIPAK"
            }
          },
          "Powders": {
            "CHAIN DRUG CONSORTIUM": {
              "BABY DARLINGS CORNSTARCH": "BABY DARLINGS CORNSTARCH"
            },
            "CHATTEM INC": {
              "BALMEX BABY": "BALMEX BABY",
              "GOLD BOND CORNSTARCH PLUS": "GOLD BOND CORNSTARCH PLUS",
              "GOLD BOND NO MESS BODY POWDER": "GOLD BOND NO MESS BODY POWDER",
              "GOLD BOND ULTIMATE": "GOLD BOND ULTIMATE"
            },
            "COLOPLAST": {
              "FORDUSTIN": "FORDUSTIN"
            },
            "COMBE": {
              "VAGISIL DEODORANT": "VAGISIL DEODORANT"
            },
            "CROWN LABORATORIES": {
              "ZEASORB": "ZEASORB"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS BABY POWDER": "CVS BABY POWDER",
              "CVS OATMEAL BATH": "CVS OATMEAL BATH"
            },
            "DSE HEALTHCARE SOLUTIONS": {
              "ANTI MONKEY BUTT": "ANTI MONKEY BUTT",
              "LADY ANTI MONKEY BUTT": "LADY ANTI MONKEY BUTT"
            },
            "EQUALINE": {
              "EQL MEDICATED BODY": "EQL MEDICATED BODY"
            },
            "FC STURTEVANT": {
              "COLUMBIA ANTISEPTIC": "COLUMBIA ANTISEPTIC"
            },
            "FLEET PHARMACEUTICALS": {
              "SUMMERS EVE BODY": "SUMMERS EVE BODY",
              "SUMMERS EVE FEMININE": "SUMMERS EVE FEMININE"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "ZEASORB": "ZEASORB"
            },
            "J & J CONSUMER PRODUCTS": {
              "JOHNSONS BABY CORNSTARCH": "JOHNSONS BABY CORNSTARCH",
              "JOHNSONS BABY MEDICATED": "JOHNSONS BABY MEDICATED",
              "JOHNSONS BABY POWDER": "JOHNSONS BABY POWDER",
              "JOHNSONS BABY PURE CORNSTARCH": "JOHNSONS BABY PURE CORNSTARCH"
            },
            "MCKESSON": {
              "HM BABY CORNSTARCH": "HM BABY CORNSTARCH"
            },
            "MCKESSON SUNMARK": {
              "SM BABY POWDER": "SM BABY POWDER",
              "SM BABY POWDER CORNSTARCH": "SM BABY POWDER CORNSTARCH"
            },
            "MEDICINE SHOPPE": {
              "BABY POWDER": "BABY POWDER"
            },
            "MEDLINE INDUSTRIES": {
              "SOOTHE & COOL BODY": "SOOTHE & COOL BODY"
            },
            "MEDLINE/DERMAL MANAGEMENT": {
              "SOOTHE & COOL BODY": "SOOTHE & COOL BODY"
            },
            "NUMARK LABS": {
              "B.F.I.": "B.F.I."
            },
            "RECREO MEDICATED POWDER CO": {
              "BALSAM PERU-ZN STEAR-BORIC ACD": "BALSAM PERU-ZN STEAR-BORIC ACD"
            },
            "RITE AID CORPORATION": {
              "RA GENTLE BABY": "RA GENTLE BABY",
              "RA SHOWER & BATH BODY": "RA SHOWER & BATH BODY",
              "RA TUGABOOS BABY": "RA TUGABOOS BABY",
              "RA TUGABOOS BABY (TALC)": "RA TUGABOOS BABY (TALC)"
            },
            "WALGREENS": {
              "BABY CORNSTARCH": "BABY CORNSTARCH",
              "BABY POWDER": "BABY POWDER"
            }
          },
          "Shampoos": {
            "BAUSCH HEALTH": {
              "DARA": "DARA"
            },
            "CHATTEM INC": {
              "ULTRASWIM": "ULTRASWIM",
              "ULTRASWIM ULTRA REPAIR": "ULTRASWIM ULTRA REPAIR"
            },
            "COLOPLAST": {
              "GENTLE RAIN": "GENTLE RAIN"
            },
            "FOCUS CONSUMER HEALTHCARE": {
              "ULTRASWIM CHLORINE REMOVAL": "ULTRASWIM CHLORINE REMOVAL",
              "ULTRASWIM ULTRA REPAIR": "ULTRASWIM ULTRA REPAIR"
            },
            "GERITREX CORPORATION": {
              "GERI-SHAMPOO/SHEA BUTTER": "GERI-SHAMPOO/SHEA BUTTER"
            },
            "J & J CONSUMER PRODUCTS": {
              "AVEENO ACTIVE NAT PURE RENEWAL": "AVEENO ACTIVE NAT PURE RENEWAL",
              "JOHNSONS KIDS CLEAN & FRESH": "JOHNSONS KIDS CLEAN & FRESH",
              "JOHNSONS KIDS CURL DEFINING": "JOHNSONS KIDS CURL DEFINING",
              "JOHNSONS KIDS SHINY & SOFT": "JOHNSONS KIDS SHINY & SOFT",
              "JOHNSONS KIDS STRENGTHENING": "JOHNSONS KIDS STRENGTHENING",
              "JOHNSONS KIDS ULTRA-HYDRATING": "JOHNSONS KIDS ULTRA-HYDRATING"
            },
            "MCNEIL CONSUMER": {
              "JOHNSONS KIDS STRG CONDITIONER": "JOHNSONS KIDS STRG CONDITIONER"
            },
            "NEUTROGENA": {
              "NEUTROGENA": "NEUTROGENA",
              "NEUTROGENA TRIPLE MOISTURE": "NEUTROGENA TRIPLE MOISTURE"
            },
            "PERSON COVEY": {
              "DHS": "DHS",
              "DHS COLOR SAFE": "DHS COLOR SAFE",
              "DHS CONDITIONING RINSE": "DHS CONDITIONING RINSE"
            },
            "PHARMACEUTICAL SPECIALITIES": {
              "FREE & CLEAR": "FREE & CLEAR"
            },
            "TEVA SELECT BRANDS": {
              "PHACID": "PHACID"
            },
            "TOPMD": {
              "CLN HEALTHY SCALP": "CLN HEALTHY SCALP",
              "CLN MOISTURE RICH GENTLE": "CLN MOISTURE RICH GENTLE"
            },
            "WALGREENS": {
              "DRY SHAMPOO": "DRY SHAMPOO"
            }
          },
          "Skin Cleansers": {
            "AKRON PHARMA": {
              "MEDPURA ALCOHOL PADS": "MEDPURA ALCOHOL PADS",
              "MEDPURA HAND SANITIZER": "MEDPURA HAND SANITIZER"
            },
            "AMERICAN SALES COMPANY": {
              "ISO-BLU": "ISO-BLU",
              "ISOPROPYL ALCOHOL WIPES": "ISOPROPYL ALCOHOL WIPES"
            },
            "AMERIDERM LABORATORIES LTD": {
              "INSTACLEAN": "INSTACLEAN",
              "PERICLEAN": "PERICLEAN",
              "PERISCENT": "PERISCENT"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP ISOPROPYL ALCOHOL WIPES": "GNP ISOPROPYL ALCOHOL WIPES"
            },
            "APLICARE": {
              "ANTISEPTIC HAND RINSE": "ANTISEPTIC HAND RINSE",
              "ISOPROPYL ALCOHOL": "ISOPROPYL ALCOHOL"
            },
            "BERGEN BRUNSWIG": {
              "GNP SHAMPOO & BODY WASH": "GNP SHAMPOO & BODY WASH"
            },
            "CAMELLIX": {
              "PROTECTEAV": "PROTECTEAV"
            },
            "CHAIN DRUG CONSORTIUM": {
              "ISOPROPYL ALCOHOL WIPES": "ISOPROPYL ALCOHOL WIPES"
            },
            "COLOPLAST": {
              "BEDSIDE-CARE": "BEDSIDE-CARE",
              "BEDSIDE-CARE PERINEAL WASH": "BEDSIDE-CARE PERINEAL WASH"
            },
            "CONVATEC": {
              "ALOE VESTA CLEANSING FOAM": "ALOE VESTA CLEANSING FOAM",
              "ALOE VESTA MULTI PURPOSE": "ALOE VESTA MULTI PURPOSE",
              "ALOE VESTA PERINEAL/SKIN": "ALOE VESTA PERINEAL/SKIN",
              "SENSI-CARE PERINEAL/SKIN": "SENSI-CARE PERINEAL/SKIN"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS HYGIENIC CLEANSING": "CVS HYGIENIC CLEANSING",
              "CVS INSTANT HAND SANITIZER": "CVS INSTANT HAND SANITIZER",
              "CVS ISOPROPYL ALCOHOL WIPES": "CVS ISOPROPYL ALCOHOL WIPES"
            },
            "DERMARITE": {
              "GELRITE HAND SANITIZER": "GELRITE HAND SANITIZER",
              "PERIFRESH": "PERIFRESH"
            },
            "E.T. BROWNE DRUG CO": {
              "PALMERS SKIN THERAPY CLEANSING": "PALMERS SKIN THERAPY CLEANSING"
            },
            "ENOVACHEM MANUFACTURING": {
              "ENOVATIZER GEL": "ENOVATIZER GEL"
            },
            "EQUALINE": {
              "EQL HAND SANITIZER": "EQL HAND SANITIZER",
              "EQL HAND SANITIZER ADVANCED": "EQL HAND SANITIZER ADVANCED",
              "EQL HAND SANITIZER/ALOE": "EQL HAND SANITIZER/ALOE"
            },
            "FNC MEDICAL CORPORATION": {
              "CA-REZZ NORISC": "CA-REZZ NORISC"
            },
            "GENSCO LABORATORIES": {
              "GERMPHOBIC HAND SANITIZER": "GERMPHOBIC HAND SANITIZER"
            },
            "GERITREX CORPORATION": {
              "GEL-STAT": "GEL-STAT",
              "GRX RINSE-FREE": "GRX RINSE-FREE"
            },
            "GLOBAL LIFE TECHNOLOGIES": {
              "NOZIN NASAL SANITIZER": "NOZIN NASAL SANITIZER"
            },
            "HUMANICARE INTERNATIONAL": {
              "NEW DIGNITY ODOR ELIMINATOR": "NEW DIGNITY ODOR ELIMINATOR"
            },
            "J & J MEDICAL": {
              "PREVACARE ANTIMICROBIAL": "PREVACARE ANTIMICROBIAL"
            },
            "LANTISEPTIC": {
              "LANTESEPTIC CLEANSING FOAM": "LANTESEPTIC CLEANSING FOAM",
              "LANTISEPTIC ALL BODY WASH": "LANTISEPTIC ALL BODY WASH",
              "LANTISEPTIC DAILY BODY WASH": "LANTISEPTIC DAILY BODY WASH",
              "LANTISEPTIC NO-RINSE FOAM": "LANTISEPTIC NO-RINSE FOAM",
              "LANTISEPTIC PERINEAL WASH": "LANTISEPTIC PERINEAL WASH"
            },
            "LEADER BRAND PRODUCTS": {
              "ADVANCED HAND SANITIZER": "ADVANCED HAND SANITIZER",
              "ADVANCED HAND SANITIZER/ALOE": "ADVANCED HAND SANITIZER/ALOE",
              "ALCOHOL WIPES": "ALCOHOL WIPES"
            },
            "MARNEL PHARMACEUTICAL": {
              "HYCLODEX": "HYCLODEX"
            },
            "MCKESSON SUNMARK": {
              "SM ADVANCED HAND SANITIZER": "SM ADVANCED HAND SANITIZER"
            },
            "MEDA CONSUMER HEALTHCARE": {
              "BALNEOL": "BALNEOL"
            },
            "MEDICAP LABORATORIES": {
              "HAND SANITIZER": "HAND SANITIZER"
            },
            "MEDIQUE PRODUCTS": {
              "MEDI-FIRST ANTISEPTIC CLEANER": "MEDI-FIRST ANTISEPTIC CLEANER"
            },
            "MEDISCA": {
              "ESSENTRA WIPES 9X9\"\"": "ESSENTRA WIPES 9X9\"\""
            },
            "MEDLINE/DERMAL MANAGEMENT": {
              "CARRAFOAM": "CARRAFOAM",
              "CARRAWASH": "CARRAWASH",
              "PERINEAL CLEANSING": "PERINEAL CLEANSING",
              "SOOTHE & COOL PERINEAL WASH": "SOOTHE & COOL PERINEAL WASH"
            },
            "NATIONAL VITAMIN": {
              "CHANTAL ANTI-BACTERIAL HAND": "CHANTAL ANTI-BACTERIAL HAND"
            },
            "NOVANA MEDICAL": {
              "ONE STEP PERINEAL": "ONE STEP PERINEAL",
              "PERINEAL SKIN CLEANSER": "PERINEAL SKIN CLEANSER"
            },
            "RITE AID CORPORATION": {
              "RA GERM DEFENSE": "RA GERM DEFENSE",
              "RA INSTANT HAND SANITIZER": "RA INSTANT HAND SANITIZER",
              "RA INSTANT HAND SANITIZER/ALOE": "RA INSTANT HAND SANITIZER/ALOE",
              "RA ISOPROPYL ALCOHOL WIPES": "RA ISOPROPYL ALCOHOL WIPES",
              "RA RENEWAL HAND SANITIZER": "RA RENEWAL HAND SANITIZER"
            },
            "ROSEDALE THERAPEUTICS": {
              "DERMASTAT": "DERMASTAT"
            },
            "SDI USA": {
              "CLEVER CHOICE HAND SANITIZER": "CLEVER CHOICE HAND SANITIZER"
            },
            "SKY ORGANICS": {
              "HAND SANITIZER": "HAND SANITIZER"
            },
            "SMITH & NEPHEW BIOTHERAPEUTICS": {
              "PROSHIELD FOAM/SPRAY CLEANSER": "PROSHIELD FOAM/SPRAY CLEANSER",
              "PROSHIELD SPRAY CLEANSER": "PROSHIELD SPRAY CLEANSER"
            },
            "SONOMA PHARMACEUTICALS": {
              "EPICYN": "EPICYN"
            },
            "STABLE CONCEPTS": {
              "HANDCLEAN HAND SANITIZER": "HANDCLEAN HAND SANITIZER"
            },
            "STEADMED MEDICAL": {
              "ELTA CLEANSE PERINEAL WASH": "ELTA CLEANSE PERINEAL WASH",
              "ELTA SHAMPOO/BODY WASH": "ELTA SHAMPOO/BODY WASH"
            },
            "VI-JON": {
              "GERM-X CITRUS HAND SANITIZER": "GERM-X CITRUS HAND SANITIZER",
              "GERM-X ORIGINAL HAND SANITIZER": "GERM-X ORIGINAL HAND SANITIZER"
            },
            "VILLAGE PHARMA": {
              "SANIZEN": "SANIZEN"
            },
            "WALGREENS": {
              "ADVANCED HAND SANI/ALOE/VIT E": "ADVANCED HAND SANI/ALOE/VIT E",
              "ADVANCED HAND SANITIZER": "ADVANCED HAND SANITIZER",
              "ADVANCED HAND SANITIZER/ALOE": "ADVANCED HAND SANITIZER/ALOE",
              "ADVANCED HAND SANITIZER/VIT E": "ADVANCED HAND SANITIZER/VIT E",
              "INSTANT HAND SANITIZER": "INSTANT HAND SANITIZER",
              "ISOPROPYL ALCOHOL": "ISOPROPYL ALCOHOL",
              "ISOPROPYL ALCOHOL WIPES": "ISOPROPYL ALCOHOL WIPES"
            }
          },
          "Skin Oils": {
            "BAUSCH HEALTH": {
              "NUTRADERM": "NUTRADERM"
            },
            "BAYER CONSUMER": {
              "SARDO": "SARDO"
            },
            "BEIERSDORF DERMA": {
              "NIVEA": "NIVEA",
              "NIVEA SKIN": "NIVEA SKIN"
            },
            "CHAIN DRUG CONSORTIUM": {
              "BABY DARLINGS MOISTURIZING": "BABY DARLINGS MOISTURIZING"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS BABY OIL": "CVS BABY OIL",
              "CVS BATH OIL": "CVS BATH OIL"
            },
            "DERMARITE": {
              "ALPHABATH": "ALPHABATH"
            },
            "E.T. BROWNE DRUG CO": {
              "PALMERS SKIN THERAPY": "PALMERS SKIN THERAPY"
            },
            "GERITREX CORPORATION": {
              "GRX REVITALIZING MASSAGE": "GRX REVITALIZING MASSAGE"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "ALPHA KERI SHOWER & BATH": "ALPHA KERI SHOWER & BATH"
            },
            "J & J CONSUMER PRODUCTS": {
              "JOHNSONS ALMOND OIL": "JOHNSONS ALMOND OIL",
              "JOHNSONS BABY OIL": "JOHNSONS BABY OIL",
              "JOHNSONS BABY OIL SHEA/COCOA": "JOHNSONS BABY OIL SHEA/COCOA",
              "JOHNSONS CREAMY BABY OIL": "JOHNSONS CREAMY BABY OIL"
            },
            "MCKESSON": {
              "HM BABY OIL": "HM BABY OIL"
            },
            "MCKESSON SUNMARK": {
              "SM BABY OIL": "SM BABY OIL"
            },
            "NEUTROGENA": {
              "NEUTROGENA BODY": "NEUTROGENA BODY"
            },
            "NOW HEALTH GROUP": {
              "CASTOR OIL": "CASTOR OIL"
            },
            "RITE AID CORPORATION": {
              "RA BABY OIL": "RA BABY OIL",
              "RA TUGABOOS BABY": "RA TUGABOOS BABY"
            },
            "WALGREENS": {
              "BABY OIL": "BABY OIL",
              "SKIN TREATMENT": "SKIN TREATMENT",
              "THERABATH": "THERABATH"
            }
          },
          "Skin Protectants": {
            "3M CONSUMER HEALTH CARE": {
              "STERI-STRIP COMPOUND BENZOIN": "STERI-STRIP COMPOUND BENZOIN"
            },
            "3M MEDICAL": {
              "CAVILON DURABLE BARRIER": "CAVILON DURABLE BARRIER",
              "CAVILON NO STING BARRIER FILM": "CAVILON NO STING BARRIER FILM",
              "CAVILON ONE-STEP SKIN CARE": "CAVILON ONE-STEP SKIN CARE"
            },
            "A-S MEDICATION SOLUTIONS": {
              "EUCERIN": "EUCERIN"
            },
            "AKRON PHARMA": {
              "HYDROCERIN": "HYDROCERIN"
            },
            "AMEDA": {
              "AMEDA TRIPLE ZERO LANOLIN": "AMEDA TRIPLE ZERO LANOLIN"
            },
            "AMERIDERM LABORATORIES LTD": {
              "AMERICERIN": "AMERICERIN",
              "AMERIPHOR": "AMERIPHOR",
              "AMERISTORE": "AMERISTORE",
              "DERMAFIX": "DERMAFIX",
              "PERISHIELD": "PERISHIELD",
              "SUPERSOFT": "SUPERSOFT"
            },
            "AMERX HEALTH CARE CORP.": {
              "AMERIGEL BARRIER": "AMERIGEL BARRIER",
              "AMERIGEL CARE": "AMERIGEL CARE"
            },
            "APLICARE": {
              "COMPOUND BENZOIN TINCTURE": "COMPOUND BENZOIN TINCTURE"
            },
            "BAUSCH HEALTH": {
              "THERASEAL HAND PROTECTION": "THERASEAL HAND PROTECTION"
            },
            "BAYER CONSUMER": {
              "A+D FIRST AID": "A+D FIRST AID"
            },
            "BD DIABETES CARE": {
              "YALE CLEANER": "YALE CLEANER"
            },
            "BEIERSDORF DERMA": {
              "AQUAPHOR LIP REPAIR": "AQUAPHOR LIP REPAIR",
              "BASIS FACIAL MOISTURIZER": "BASIS FACIAL MOISTURIZER",
              "BASIS OVERNIGHT": "BASIS OVERNIGHT",
              "EUCERIN": "EUCERIN"
            },
            "CARMA LAB INC": {
              "CARMEX CLASSIC LIP BALM": "CARMEX CLASSIC LIP BALM",
              "CARMEX COMFORT CARE LIP BALM": "CARMEX COMFORT CARE LIP BALM"
            },
            "CHAIN DRUG CONSORTIUM": {
              "BEESWAX LIP BALM": "BEESWAX LIP BALM",
              "LIP BALM": "LIP BALM",
              "LIP TREATMENT": "LIP TREATMENT",
              "MEDICATED BODY": "MEDICATED BODY"
            },
            "CHATTEM INC": {
              "BALMEX SKIN PROTECTANT": "BALMEX SKIN PROTECTANT",
              "GOLD BOND ULTIMATE": "GOLD BOND ULTIMATE"
            },
            "CMP PHARMA": {
              "ABSORBASE": "ABSORBASE"
            },
            "COLOPLAST": {
              "BAZA CLEANSE & PROTECT": "BAZA CLEANSE & PROTECT",
              "BAZA PROTECT": "BAZA PROTECT",
              "CRITIC-AID CLEAR": "CRITIC-AID CLEAR",
              "CRITIC-AID THICK MOIST BARRIER": "CRITIC-AID THICK MOIST BARRIER",
              "INTERDRY 10\"\"X36\"\"": "INTERDRY 10\"\"X36\"\"",
              "INTERDRY AG TEXTILE 10\"\"X144\"\"": "INTERDRY AG TEXTILE 10\"\"X144\"\"",
              "SWEEN PREP": "SWEEN PREP"
            },
            "CONVATEC": {
              "ALOE VESTA DAILY MOISTURIZER": "ALOE VESTA DAILY MOISTURIZER",
              "ALOE VESTA PROTECTIVE": "ALOE VESTA PROTECTIVE",
              "ALOE VESTA SKIN CONDITIONER": "ALOE VESTA SKIN CONDITIONER",
              "SENSI-CARE BODY": "SENSI-CARE BODY",
              "SENSI-CARE CLEAR ZINC": "SENSI-CARE CLEAR ZINC",
              "SENSI-CARE MOISTURIZING": "SENSI-CARE MOISTURIZING"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS DAILY MOISTURIZING": "CVS DAILY MOISTURIZING",
              "CVS INTENSE HYDRATION": "CVS INTENSE HYDRATION",
              "CVS SKIN RELIEF": "CVS SKIN RELIEF"
            },
            "DARTMOUTH": {
              "ELON BARRIER PROTECTANT": "ELON BARRIER PROTECTANT"
            },
            "DENISON LABS": {
              "BENZOIN COMPOUND": "BENZOIN COMPOUND"
            },
            "DERMARITE": {
              "4-N-1": "4-N-1",
              "DERMACERIN": "DERMACERIN",
              "PERIGUARD": "PERIGUARD"
            },
            "E.T. BROWNE DRUG CO": {
              "PALMERS COCONUT SWIVEL STICK": "PALMERS COCONUT SWIVEL STICK",
              "PALMERS SWIVEL STICK": "PALMERS SWIVEL STICK"
            },
            "EATON LABORATORIES": {
              "PETROLEUM JELLY": "PETROLEUM JELLY"
            },
            "ENOVACHEM MANUFACTURING": {
              "ENOVASHIELD": "ENOVASHIELD"
            },
            "EQUALINE": {
              "EQL HYDRATING BEAUTY": "EQL HYDRATING BEAUTY"
            },
            "FAGRON": {
              "BENZOIN": "BENZOIN"
            },
            "FLEET PHARMACEUTICALS": {
              "BOUDREAUXS RASH PREVENTOR": "BOUDREAUXS RASH PREVENTOR",
              "BOUDREAUXS RASH PROTECTOR": "BOUDREAUXS RASH PROTECTOR"
            },
            "GERI-CARE": {
              "LIP-CARE": "LIP-CARE"
            },
            "GERITREX CORPORATION": {
              "BENZ-PROTECT": "BENZ-PROTECT",
              "BENZOIN COMPOUND": "BENZOIN COMPOUND",
              "BODI LOTION": "BODI LOTION",
              "BODI PROTECT": "BODI PROTECT",
              "DERMADROX": "DERMADROX",
              "GERI PROTECT": "GERI PROTECT",
              "HYDRO-LAN": "HYDRO-LAN",
              "HYDROCERIN": "HYDROCERIN",
              "HYDROCERIN PLUS": "HYDROCERIN PLUS",
              "LAN-O-SOOTHE": "LAN-O-SOOTHE",
              "LANOLIN": "LANOLIN",
              "LANOLIN HYDROUS": "LANOLIN HYDROUS",
              "LANOLIN HYDROUS-GRX": "LANOLIN HYDROUS-GRX",
              "MINERAL OIL LIGHT": "MINERAL OIL LIGHT",
              "SPRAYZOIN": "SPRAYZOIN"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "CHAPSTICK BOTANICAL MEDLEY": "CHAPSTICK BOTANICAL MEDLEY",
              "CHAPSTICK CLASSIC MEDICATED": "CHAPSTICK CLASSIC MEDICATED",
              "CHAPSTICK FLAVA-CRAZE": "CHAPSTICK FLAVA-CRAZE",
              "CHAPSTICK FRESH EFFECTS": "CHAPSTICK FRESH EFFECTS",
              "CHAPSTICK HYDRATION LOCK": "CHAPSTICK HYDRATION LOCK",
              "CHAPSTICK MEDICATED": "CHAPSTICK MEDICATED",
              "CHAPSTICK MIXSTIX": "CHAPSTICK MIXSTIX",
              "CHAPSTICK NATURALS LIP BUTTER": "CHAPSTICK NATURALS LIP BUTTER",
              "CHAPSTICK OVERNIGHT": "CHAPSTICK OVERNIGHT",
              "CHAPSTICK TRUE SHIMMER": "CHAPSTICK TRUE SHIMMER",
              "CHAPSTICK ULTRA MOISTURE": "CHAPSTICK ULTRA MOISTURE",
              "CHAPSTICK ULTRA RENEWAL": "CHAPSTICK ULTRA RENEWAL",
              "CHAPSTICK ULTRA SHIMMER": "CHAPSTICK ULTRA SHIMMER",
              "CHAPSTICK ULTRASMOOTH FORTIFY": "CHAPSTICK ULTRASMOOTH FORTIFY",
              "CHAPSTICK ULTRASMOOTH NOURISH": "CHAPSTICK ULTRASMOOTH NOURISH",
              "CHAPSTICK ULTRASMOOTH REJUVEN": "CHAPSTICK ULTRASMOOTH REJUVEN",
              "CHAPSTICK ULTRASMOOTH SOOTHE": "CHAPSTICK ULTRASMOOTH SOOTHE"
            },
            "GLOVES IN A BOTTLE": {
              "GLOVES IN A BOTTLE": "GLOVES IN A BOTTLE"
            },
            "GORDON": {
              "SORBIDON HYDRATE": "SORBIDON HYDRATE"
            },
            "HARMONY LABORATORIES, INC.": {
              "THERATEIN": "THERATEIN"
            },
            "HOLLISTER": {
              "UNIVERSAL REMOVER WIPES": "UNIVERSAL REMOVER WIPES"
            },
            "HOLLISTER WOUND CARE": {
              "RESTORE CLEANSER & MOISTURIZER": "RESTORE CLEANSER & MOISTURIZER",
              "RESTORE DIMETHICREME": "RESTORE DIMETHICREME"
            },
            "HRA PHARMA RARE DISEASES": {
              "MEDERMA PM": "MEDERMA PM"
            },
            "HUMCO": {
              "BENZOIN": "BENZOIN",
              "BENZOIN COMPOUND": "BENZOIN COMPOUND",
              "MINERAL OIL LIGHT": "MINERAL OIL LIGHT"
            },
            "INSIGHT PHARMACEUTICALS": {
              "MONISTAT COMPLETE CARE": "MONISTAT COMPLETE CARE"
            },
            "J & J CONSUMER PRODUCTS": {
              "AVEENO ACTIVE NATURALS": "AVEENO ACTIVE NATURALS",
              "AVEENO BABY CALMING COMFORT": "AVEENO BABY CALMING COMFORT",
              "AVEENO BABY DAILY MOISTURE": "AVEENO BABY DAILY MOISTURE",
              "AVEENO DAILY MOISTURIZING": "AVEENO DAILY MOISTURIZING",
              "AVEENO INTENSE RELIEF": "AVEENO INTENSE RELIEF",
              "AVEENO SKIN RELIEF": "AVEENO SKIN RELIEF",
              "JOHNSONS BABY INTENSE MOISTURE": "JOHNSONS BABY INTENSE MOISTURE",
              "NEOSPORIN LIP HEALTH OVERNIGHT": "NEOSPORIN LIP HEALTH OVERNIGHT"
            },
            "J & J MEDICAL": {
              "PREVACARE EXTRA PROTECTIVE": "PREVACARE EXTRA PROTECTIVE",
              "PREVACARE PERSONAL PROTECT": "PREVACARE PERSONAL PROTECT"
            },
            "L'OREAL": {
              "CERAVE": "CERAVE",
              "CERAVE BABY": "CERAVE BABY"
            },
            "LANSINOH LABORATORIES": {
              "HPA LANOLIN": "HPA LANOLIN",
              "LANSINOH LANOLIN": "LANSINOH LANOLIN",
              "LANSINOH LANOLIN MINIS NIPPLE": "LANSINOH LANOLIN MINIS NIPPLE",
              "LANSINOH LANOLIN NIPPLE": "LANSINOH LANOLIN NIPPLE"
            },
            "LANTISEPTIC": {
              "DAILY CARE SKIN PROTECTANT": "DAILY CARE SKIN PROTECTANT",
              "LANTISEPTIC SKIN PROTECTANT": "LANTISEPTIC SKIN PROTECTANT"
            },
            "LEADER BRAND PRODUCTS": {
              "MINERIN CREME": "MINERIN CREME"
            },
            "LTC PRODUCTS": {
              "PELEVERUS": "PELEVERUS",
              "PELEVERUS CLEAR": "PELEVERUS CLEAR",
              "PELEVERUS GOLD": "PELEVERUS GOLD"
            },
            "MAJOR PHARMACEUTICALS": {
              "MINERIN CREME": "MINERIN CREME"
            },
            "MCKESSON SUNMARK": {
              "SM BENZOIN TINCTURE": "SM BENZOIN TINCTURE"
            },
            "MED-DERM": {
              "ZNLIN": "ZNLIN"
            },
            "MEDCON BIOLAB TEHCNOLOGIES INC": {
              "ILEX SKIN PROTECTANT": "ILEX SKIN PROTECTANT"
            },
            "MEDELA": {
              "MEDELA TENDER CARE LANOLIN": "MEDELA TENDER CARE LANOLIN"
            },
            "MEDICINE SHOPPE": {
              "MEDI-SOOTHE": "MEDI-SOOTHE"
            },
            "MEDIQUE PRODUCTS": {
              "BLOOD CLOTTING SPRAY": "BLOOD CLOTTING SPRAY"
            },
            "MEDISCA": {
              "BENZOIN": "BENZOIN"
            },
            "MEDLINE INDUS": {
              "REMEDY PHYTOPLEX HYDRAGUARD": "REMEDY PHYTOPLEX HYDRAGUARD"
            },
            "MEDLINE INDUSTRIES": {
              "REMEDY CALAZIME/OLIVAMINE": "REMEDY CALAZIME/OLIVAMINE"
            },
            "MEDLINE/DERMAL MANAGEMENT": {
              "CALAZIME SKIN PROTECTANT": "CALAZIME SKIN PROTECTANT",
              "CARRAFOAM SKIN CARE": "CARRAFOAM SKIN CARE",
              "CARRINGTON DERMAL WOUND": "CARRINGTON DERMAL WOUND",
              "CARRINGTON MOIST BARRIER/ZINC": "CARRINGTON MOIST BARRIER/ZINC",
              "CARRINGTON MOISTURE BARRIER": "CARRINGTON MOISTURE BARRIER",
              "COMPOUND BENZOIN TINCTURE": "COMPOUND BENZOIN TINCTURE",
              "MOISTURE GUARD": "MOISTURE GUARD",
              "REMEDY CALAZIME/OLIVAMINE": "REMEDY CALAZIME/OLIVAMINE",
              "REMEDY CLEANSING BODY": "REMEDY CLEANSING BODY",
              "REMEDY CLEAR-AID": "REMEDY CLEAR-AID",
              "REMEDY MOISTURE BARRIER": "REMEDY MOISTURE BARRIER",
              "REMEDY NUTRASHIELD": "REMEDY NUTRASHIELD",
              "REMEDY SKIN REPAIR": "REMEDY SKIN REPAIR",
              "SOOTHE & COOL FREE MEDSEPTIC": "SOOTHE & COOL FREE MEDSEPTIC",
              "SOOTHE & COOL FREE MOISTURE": "SOOTHE & COOL FREE MOISTURE",
              "SOOTHE & COOL FREE SKIN PASTE": "SOOTHE & COOL FREE SKIN PASTE",
              "SOOTHE & COOL INZO BARRIER": "SOOTHE & COOL INZO BARRIER",
              "SOOTHE & COOL MOISTURE BARRIER": "SOOTHE & COOL MOISTURE BARRIER",
              "SOOTHE & COOL PROTECT MOISTURE": "SOOTHE & COOL PROTECT MOISTURE"
            },
            "MEDTECH": {
              "CHAP-ET": "CHAP-ET",
              "KERODEX-51": "KERODEX-51",
              "KERODEX-71": "KERODEX-71",
              "LITTLE BOTTOMS DAILY CARE": "LITTLE BOTTOMS DAILY CARE"
            },
            "MEIJER": {
              "DAILY MOISTURIZING": "DAILY MOISTURIZING"
            },
            "MPM MEDICAL, INC.": {
              "NORMLSHIELD": "NORMLSHIELD"
            },
            "NOVANA MEDICAL": {
              "MOISTURIZING SKIN PROTECTANT": "MOISTURIZING SKIN PROTECTANT"
            },
            "NUMARK LABS": {
              "LANOLOR": "LANOLOR"
            },
            "PAL MIDWEST LTD": {
              "PALOMAR E": "PALOMAR E"
            },
            "PCCA": {
              "BENZOIN": "BENZOIN",
              "BENZOIN COMPOUND": "BENZOIN COMPOUND"
            },
            "PERRIGO": {
              "BENZOIN COMPOUND": "BENZOIN COMPOUND"
            },
            "PH R&D": {
              "COOL BOTTOMS": "COOL BOTTOMS",
              "NEUTRAPHOR": "NEUTRAPHOR",
              "NEUTRAPHORUS REX": "NEUTRAPHORUS REX"
            },
            "PHARMACEUTICAL SPECIALITIES": {
              "VANICREAM DIAPER RASH": "VANICREAM DIAPER RASH",
              "VANIPLY": "VANIPLY"
            },
            "PRIMROSE INVESTMENTS": {
              "SUPER DUPER DIAPER DOO": "SUPER DUPER DIAPER DOO"
            },
            "PURE TEK": {
              "DERMACINRX SKIN REPAIR": "DERMACINRX SKIN REPAIR"
            },
            "QUALITY CARE": {
              "ALOE VESTA 2-N-1 PROTECTIVE": "ALOE VESTA 2-N-1 PROTECTIVE"
            },
            "REESE PHARMACEUTICAL": {
              "LIPMAGIK": "LIPMAGIK",
              "ULTRA-DERM": "ULTRA-DERM"
            },
            "RITE AID CORPORATION": {
              "RA ADVANCED HEALING": "RA ADVANCED HEALING",
              "RA LIP BALM": "RA LIP BALM",
              "RA LIP CARE MEDICATED": "RA LIP CARE MEDICATED",
              "RA OATMEAL MOISTURIZING": "RA OATMEAL MOISTURIZING",
              "RA RENEWAL ADVANCED HEALING": "RA RENEWAL ADVANCED HEALING",
              "RA RENEWAL DAILY MOISTURIZING": "RA RENEWAL DAILY MOISTURIZING"
            },
            "RUGBY LABORATORIES": {
              "MINERIN CREME": "MINERIN CREME"
            },
            "SAGE PRODUCTS": {
              "COMFORT SHIELD": "COMFORT SHIELD"
            },
            "SHIELD LINE LLC": {
              "AMERIDERM PERISHIELD": "AMERIDERM PERISHIELD"
            },
            "SMITH & NEPHEW BIOTHERAPEUTICS": {
              "PROSHIELD PLUS SKIN PROTECTANT": "PROSHIELD PLUS SKIN PROTECTANT",
              "PROSHIELD PROTECTIVE HAND": "PROSHIELD PROTECTIVE HAND",
              "PROSHIELD SKIN CARE": "PROSHIELD SKIN CARE"
            },
            "SMITH & NEPHEW UNITED": {
              "NO-STING SKIN-PREP": "NO-STING SKIN-PREP",
              "SECURA DIMETHICONE PROTECTANT": "SECURA DIMETHICONE PROTECTANT"
            },
            "SPECTRUM": {
              "BENZOIN": "BENZOIN",
              "BENZOIN COMPOUND": "BENZOIN COMPOUND"
            },
            "STEADMED MEDICAL": {
              "ELTA LITE": "ELTA LITE",
              "ELTA SEAL": "ELTA SEAL"
            },
            "TENDER CORPORATION": {
              "AFTER BITE": "AFTER BITE",
              "AFTER BITE KIDS": "AFTER BITE KIDS",
              "THE ITCH ERASER SENSITIVE": "THE ITCH ERASER SENSITIVE"
            },
            "TEVA PHARMACEUTICALS USA": {
              "MOISTUREL": "MOISTUREL"
            },
            "TOUCHLESS CARE CONCEPTS": {
              "RASH RELIEF": "RASH RELIEF"
            },
            "UROCARE PRODUCTS, INC.": {
              "URO-PREP PROTECTIVE SKIN WIPES": "URO-PREP PROTECTIVE SKIN WIPES"
            },
            "VILLAGE PHARMA": {
              "SCARZEN": "SCARZEN"
            },
            "WALGREENS": {
              "ADULT WASH CLOTHS WITH ALOE": "ADULT WASH CLOTHS WITH ALOE",
              "BEESWAX LIP BALM": "BEESWAX LIP BALM",
              "CHAP-AID": "CHAP-AID",
              "LIP BALM": "LIP BALM",
              "NATURAL OATMEAL": "NATURAL OATMEAL",
              "PETROLEUM JELLY LIP TREATMENT": "PETROLEUM JELLY LIP TREATMENT"
            }
          },
          "Soaps": {
            "AKORN CONSUMER": {
              "DIABETIDERM CLEANSING": "DIABETIDERM CLEANSING"
            },
            "BAUSCH HEALTH": {
              "CERAVE FOAMING FACIAL CLEANSER": "CERAVE FOAMING FACIAL CLEANSER",
              "IONIL": "IONIL",
              "PURPOSE GENTLE CLEANING WASH": "PURPOSE GENTLE CLEANING WASH"
            },
            "BEIERSDORF DERMA": {
              "BASIS ALL CLEAR": "BASIS ALL CLEAR",
              "BASIS CLEANSER": "BASIS CLEANSER",
              "BASIS COMBINATION": "BASIS COMBINATION",
              "BASIS EXTRA DRY": "BASIS EXTRA DRY",
              "BASIS NORMAL/DRY": "BASIS NORMAL/DRY",
              "BASIS SENSITIVE SKIN": "BASIS SENSITIVE SKIN",
              "EUCERIN": "EUCERIN",
              "EUCERIN ADVANCED CLEANSING": "EUCERIN ADVANCED CLEANSING",
              "EUCERIN SKIN CALMING BODY WASH": "EUCERIN SKIN CALMING BODY WASH",
              "NIVEA MOISTURIZING BODY WASH": "NIVEA MOISTURIZING BODY WASH",
              "NIVEA MOISTURIZING CREME SOAP": "NIVEA MOISTURIZING CREME SOAP",
              "NIVEA TOUCH OF SMOOTHNESS": "NIVEA TOUCH OF SMOOTHNESS",
              "NIVEA VISAGE": "NIVEA VISAGE",
              "NIVEA VISAGE GENTLE CLEANSING": "NIVEA VISAGE GENTLE CLEANSING"
            },
            "BEIERSDORF-FUTURO": {
              "MOIST CLEANSING TOWELETTES": "MOIST CLEANSING TOWELETTES"
            },
            "BLANSETT PHARMACAL": {
              "STAPHSCRUB": "STAPHSCRUB"
            },
            "C S  DENT": {
              "GRANDPAS BAKING SODA SOAP": "GRANDPAS BAKING SODA SOAP",
              "GRANDPAS INDIAN CORN SOAP": "GRANDPAS INDIAN CORN SOAP",
              "GRANDPAS LOVE MY LOOFAH SOAP": "GRANDPAS LOVE MY LOOFAH SOAP",
              "GRANDPAS OATMEAL SOAP": "GRANDPAS OATMEAL SOAP",
              "GRANDPAS ORANGE ESSENCE SOAP": "GRANDPAS ORANGE ESSENCE SOAP",
              "GRANDPAS PATCHOULI SOAP": "GRANDPAS PATCHOULI SOAP",
              "GRANDPAS SHEA BUTTER SOAP": "GRANDPAS SHEA BUTTER SOAP",
              "GRANDPAS WITCH HAZEL SOAP": "GRANDPAS WITCH HAZEL SOAP",
              "NATURE DE FRANCE ALGOLI SOAP": "NATURE DE FRANCE ALGOLI SOAP",
              "NATURE DE FRANCE ARGILE SOAP": "NATURE DE FRANCE ARGILE SOAP",
              "NATURE DE FRANCE ARGIMIEL SOAP": "NATURE DE FRANCE ARGIMIEL SOAP"
            },
            "CHAIN DRUG CONSORTIUM": {
              "FEMININE CLEANSING CLOTHS": "FEMININE CLEANSING CLOTHS",
              "PERSONAL WASHCLOTHS": "PERSONAL WASHCLOTHS"
            },
            "CHATTEM INC": {
              "GOLD BOND ULT WASH/EXFOLIATING": "GOLD BOND ULT WASH/EXFOLIATING",
              "GOLD BOND ULT WASH/HEALING": "GOLD BOND ULT WASH/HEALING",
              "GOLD BOND ULT WASH/SENSITIVE": "GOLD BOND ULT WASH/SENSITIVE",
              "GOLD BOND ULT WASH/SOFTENING": "GOLD BOND ULT WASH/SOFTENING"
            },
            "COLOPLAST": {
              "PERI-WASH": "PERI-WASH"
            },
            "CONVATEC": {
              "ALOE VESTA BODY WASH/SHAMPOO": "ALOE VESTA BODY WASH/SHAMPOO",
              "SENSI-CARE SEPTI-SOFT": "SENSI-CARE SEPTI-SOFT"
            },
            "COVIDIEN MEDICAL SUPPLIES": {
              "BODY WASH & SHAMPOO": "BODY WASH & SHAMPOO"
            },
            "CROWN LABORATORIES": {
              "DEL CLENS": "DEL CLENS"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS CLEANSING SKIN": "CVS CLEANSING SKIN",
              "CVS DAILY FACIAL CLEANSER": "CVS DAILY FACIAL CLEANSER"
            },
            "DERMALAB": {
              "SUPERFATTED SOAP": "SUPERFATTED SOAP"
            },
            "DICKINSON BRANDS INC.": {
              "DICKINSONS WITCH HAZEL": "DICKINSONS WITCH HAZEL"
            },
            "DSE HEALTHCARE SOLUTIONS": {
              "CONTI CASTILE SOAP": "CONTI CASTILE SOAP"
            },
            "EQUALINE": {
              "EQL BODY WASH/SENSITIVE SKIN": "EQL BODY WASH/SENSITIVE SKIN",
              "EQL BODY WASH/SHEA BUTTER": "EQL BODY WASH/SHEA BUTTER",
              "EQL CLEAR HAND SOAP REFILL": "EQL CLEAR HAND SOAP REFILL",
              "EQL DEODORANT SOAP": "EQL DEODORANT SOAP",
              "EQL GENTLE SKIN CLEANSER": "EQL GENTLE SKIN CLEANSER",
              "EQL HIGH POWER BODY WASH": "EQL HIGH POWER BODY WASH",
              "EQL LIQUID HAND SOAP": "EQL LIQUID HAND SOAP",
              "EQL MOISTURIZING BEAUTY": "EQL MOISTURIZING BEAUTY",
              "EQL SKIN ASTRINGENT": "EQL SKIN ASTRINGENT",
              "EQL WET CLEANSING TOWELETTES": "EQL WET CLEANSING TOWELETTES"
            },
            "ESSITY": {
              "TENA CLEANSING CREAM": "TENA CLEANSING CREAM",
              "TENA SKIN-CARING BODY WASH": "TENA SKIN-CARING BODY WASH",
              "TENA SKIN-CARING WASH CREAM": "TENA SKIN-CARING WASH CREAM",
              "TENA ULTRA WASHCLOTH": "TENA ULTRA WASHCLOTH",
              "TENA WASH": "TENA WASH"
            },
            "FERNDALE LAB": {
              "DETACHOL STER TIP": "DETACHOL STER TIP"
            },
            "FLEET PHARMACEUTICALS": {
              "BOUDREAUXS BUTT BATH": "BOUDREAUXS BUTT BATH",
              "SUMMERS EVE BATH & SHOWER": "SUMMERS EVE BATH & SHOWER",
              "SUMMERS EVE CLEANSING CLOTHS": "SUMMERS EVE CLEANSING CLOTHS",
              "SUMMERS EVE NIGHT-TIME": "SUMMERS EVE NIGHT-TIME",
              "SUMMERS EVE SIMPLY SENSITIVE": "SUMMERS EVE SIMPLY SENSITIVE"
            },
            "GALDERMA": {
              "CETAPHIL": "CETAPHIL",
              "CETAPHIL CLEANSING CLOTHS": "CETAPHIL CLEANSING CLOTHS",
              "CETAPHIL DERMACONTROL FOAM WSH": "CETAPHIL DERMACONTROL FOAM WSH",
              "CETAPHIL GENTLE CLEANSER": "CETAPHIL GENTLE CLEANSER",
              "CETAPHIL GENTLE CLEANSING": "CETAPHIL GENTLE CLEANSING",
              "CETAPHIL RESTORADERM": "CETAPHIL RESTORADERM"
            },
            "GERITREX CORPORATION": {
              "ASC LOTIONIZED ANTIMICROBIAL": "ASC LOTIONIZED ANTIMICROBIAL",
              "BODI BATH": "BODI BATH",
              "BODI KLEEN": "BODI KLEEN",
              "CETAKLENZ": "CETAKLENZ",
              "GERI-WASH": "GERI-WASH",
              "GRX ASC LOTIONIZED": "GRX ASC LOTIONIZED",
              "NATURES WASH PLUS": "NATURES WASH PLUS"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "OILATUM": "OILATUM"
            },
            "GLAXO SMITH KLINE": {
              "ACNE-AID": "ACNE-AID"
            },
            "HRA PHARMA RARE DISEASES": {
              "MEDERMA AG BODY CLEANSER": "MEDERMA AG BODY CLEANSER",
              "MEDERMA AG FACIAL CLEANSER": "MEDERMA AG FACIAL CLEANSER",
              "MEDERMA AG FACIAL TONER": "MEDERMA AG FACIAL TONER"
            },
            "J & J CONSUMER PRODUCTS": {
              "AVEENO ACTIVE NAT MAKEUP WIPES": "AVEENO ACTIVE NAT MAKEUP WIPES",
              "AVEENO ACTIVE NAT ULT-CALMING": "AVEENO ACTIVE NAT ULT-CALMING",
              "AVEENO AGELESS CLEANSER": "AVEENO AGELESS CLEANSER",
              "AVEENO BABY CALMING COMFORT": "AVEENO BABY CALMING COMFORT",
              "AVEENO BABY CLEANSING THERAPY": "AVEENO BABY CLEANSING THERAPY",
              "AVEENO CALM & RESTORE": "AVEENO CALM & RESTORE",
              "AVEENO MOISTURIZING": "AVEENO MOISTURIZING",
              "AVEENO POSITIVE RADIANT CLEANS": "AVEENO POSITIVE RADIANT CLEANS",
              "AVEENO POSITIVELY AGELESS": "AVEENO POSITIVELY AGELESS",
              "AVEENO POSITIVELY NOURISHING": "AVEENO POSITIVELY NOURISHING",
              "AVEENO POSITIVELY RADIANT": "AVEENO POSITIVELY RADIANT",
              "AVEENO SKIN BRIGHTENING SCRUB": "AVEENO SKIN BRIGHTENING SCRUB",
              "AVEENO SMART ESSENTIALS SCRUB": "AVEENO SMART ESSENTIALS SCRUB",
              "AVEENO ULTRA-CALMING CLEANSER": "AVEENO ULTRA-CALMING CLEANSER",
              "CLEAN & CLEAR ABSORBING SHEETS": "CLEAN & CLEAR ABSORBING SHEETS",
              "CLEAN & CLEAR CLEANSING WIPES": "CLEAN & CLEAR CLEANSING WIPES",
              "CLEAN & CLEAR DEEP ACTION": "CLEAN & CLEAR DEEP ACTION",
              "CLEAN & CLEAR ESSENTIALS": "CLEAN & CLEAR ESSENTIALS",
              "CLEAN & CLEAR FACIAL CLEANSER": "CLEAN & CLEAR FACIAL CLEANSER",
              "CLEAN & CLEAR MORNING BURST": "CLEAN & CLEAR MORNING BURST",
              "CLEAN & CLEAR NIGHT RELAX WASH": "CLEAN & CLEAR NIGHT RELAX WASH",
              "CLEAN & CLEAR NIGHT RELAXING": "CLEAN & CLEAR NIGHT RELAXING",
              "CLEAN & CLEAR PORE CLEANSER": "CLEAN & CLEAR PORE CLEANSER",
              "CLEAN & CLEAR WATERMELON": "CLEAN & CLEAR WATERMELON",
              "CLEAN & CLEAR WATERMELON SCRUB": "CLEAN & CLEAR WATERMELON SCRUB",
              "JOHNSONS BABY BAR": "JOHNSONS BABY BAR",
              "JOHNSONS BABY OIL BODY WASH": "JOHNSONS BABY OIL BODY WASH",
              "JOHNSONS KIDS CLEAN & FRESH": "JOHNSONS KIDS CLEAN & FRESH",
              "ROC MAX RESURFACING FACIAL": "ROC MAX RESURFACING FACIAL"
            },
            "KAISER FOUNDATION HOSPITAL": {
              "KP GENTLE SKIN CLEANSER": "KP GENTLE SKIN CLEANSER"
            },
            "L'OREAL": {
              "CERAVE FOAMING FACIAL CLEANSER": "CERAVE FOAMING FACIAL CLEANSER",
              "CERAVE HYDRATING CLEANSER": "CERAVE HYDRATING CLEANSER",
              "CERAVE SA BODY WASH": "CERAVE SA BODY WASH"
            },
            "LOBOB": {
              "LOBOB CONTACT LENS WEARER SOAP": "LOBOB CONTACT LENS WEARER SOAP"
            },
            "MAJOR PHARMACEUTICALS": {
              "CETAKLENZ": "CETAKLENZ"
            },
            "MCKESSON SUNMARK": {
              "SM SKIN CLEANSER GENTLE": "SM SKIN CLEANSER GENTLE"
            },
            "MED-DERM": {
              "TOPICLEAR": "TOPICLEAR"
            },
            "MEDIMETRIKS PHARMACEUTICALS": {
              "ACUWASH": "ACUWASH",
              "REHYLA HAIR + BODY CLEANSER": "REHYLA HAIR + BODY CLEANSER",
              "REHYLA WASH": "REHYLA WASH"
            },
            "MEDLINE INDUSTRIES": {
              "REMEDY FOAMING BODY CLEANSER": "REMEDY FOAMING BODY CLEANSER"
            },
            "MEDLINE/DERMAL MANAGEMENT": {
              "CARA-KLENZ": "CARA-KLENZ",
              "CARRAFOAM": "CARRAFOAM",
              "SOOTHE & COOL SHAMPOO/BODY WSH": "SOOTHE & COOL SHAMPOO/BODY WSH"
            },
            "MERZ PHARMACEUTICAL": {
              "AQUA GLYCOLIC FACIAL CLEANSER": "AQUA GLYCOLIC FACIAL CLEANSER",
              "AQUA GLYCOLIC SHAMPOO/BODY": "AQUA GLYCOLIC SHAMPOO/BODY",
              "AQUA GLYCOLIC TONER": "AQUA GLYCOLIC TONER"
            },
            "NATIONAL VITAMIN": {
              "ANTI-BACTERIAL HAND": "ANTI-BACTERIAL HAND",
              "GENTLE CLEANSING SKIN": "GENTLE CLEANSING SKIN"
            },
            "NEUTROGENA": {
              "NEUTROGENA ACNE CLEANSING SOAP": "NEUTROGENA ACNE CLEANSING SOAP",
              "NEUTROGENA DEEP CLEAN": "NEUTROGENA DEEP CLEAN",
              "NEUTROGENA FACIAL SOAP": "NEUTROGENA FACIAL SOAP",
              "NEUTROGENA FRESH FOAMING": "NEUTROGENA FRESH FOAMING",
              "NEUTROGENA NATURALS FACE/BODY": "NEUTROGENA NATURALS FACE/BODY"
            },
            "NO-RINSE LABORATORIES": {
              "NO RINSE BATHING WIPES": "NO RINSE BATHING WIPES"
            },
            "NOVARTIS OPTHALMICS": {
              "EYESCRUB": "EYESCRUB"
            },
            "OCUSOFT": {
              "OCUSOFT HAND SOAP": "OCUSOFT HAND SOAP"
            },
            "ONTOS": {
              "NOBLE MYSTIQUE BODY CLEANSER": "NOBLE MYSTIQUE BODY CLEANSER"
            },
            "PERSON COVEY": {
              "AQUANIL SKIN CLEANSER": "AQUANIL SKIN CLEANSER"
            },
            "PHARMACEUTICAL SPECIALITIES": {
              "FREE & CLEAR/SENSITIVE": "FREE & CLEAR/SENSITIVE",
              "VANICREAM": "VANICREAM"
            },
            "PHARMADERM": {
              "FORMULA 405 FACIAL & BODY": "FORMULA 405 FACIAL & BODY",
              "FORMULA 405 MOISTURIZING SOAP": "FORMULA 405 MOISTURIZING SOAP"
            },
            "RANBAXY LABORATORIES": {
              "LOWILA": "LOWILA"
            },
            "RITE AID CORPORATION": {
              "RA BEAUTY BAR": "RA BEAUTY BAR",
              "RA DAILY FACE WASH": "RA DAILY FACE WASH",
              "RA FEMININE CLEANSING CLOTHS": "RA FEMININE CLEANSING CLOTHS",
              "RA GENTLE SKIN CLEANSER": "RA GENTLE SKIN CLEANSER",
              "RA SKIN CLEANSING": "RA SKIN CLEANSING",
              "RA TOWELETTES": "RA TOWELETTES"
            },
            "SAGE PRODUCTS": {
              "BATH CLOTH CLEANSING WASHCLOTH": "BATH CLOTH CLEANSING WASHCLOTH",
              "COMFORT BATH CLEANSING CLOTHS": "COMFORT BATH CLEANSING CLOTHS",
              "COMFORT BATH CLOTHS DEODORANT": "COMFORT BATH CLOTHS DEODORANT",
              "COMFORT DEODORANT CLEAN-UP": "COMFORT DEODORANT CLEAN-UP",
              "ESSENTIAL BATH CLEANSNG CLOTHS": "ESSENTIAL BATH CLEANSNG CLOTHS",
              "IMPREVA BATH WASHCLOTHS": "IMPREVA BATH WASHCLOTHS"
            },
            "SHEFFIELD PHARMACEUTICALS": {
              "SAYMAN LANOLIN": "SAYMAN LANOLIN",
              "SAYMAN WITCH HAZEL": "SAYMAN WITCH HAZEL"
            },
            "STEADMED MEDICAL": {
              "ELTA CLEANSE": "ELTA CLEANSE"
            },
            "TOPMD": {
              "CLN BODY WASH": "CLN BODY WASH",
              "CLN FACIAL CLEANSER": "CLN FACIAL CLEANSER",
              "CLN HAND & FOOT WASH": "CLN HAND & FOOT WASH",
              "CLN SPORT WASH HIGH PERFORM": "CLN SPORT WASH HIGH PERFORM",
              "CLN SPORTWASH": "CLN SPORTWASH"
            },
            "TRIMARC LABORATORIES": {
              "Z-CLINZ": "Z-CLINZ"
            },
            "WALGREENS": {
              "CLEANSING": "CLEANSING",
              "GENTLE SKIN CLEANSER": "GENTLE SKIN CLEANSER",
              "REFRESH CLEANSER": "REFRESH CLEANSER",
              "REFRESHING FACIAL CLEANSER": "REFRESHING FACIAL CLEANSER",
              "SHAMPOO & BODY WASH RINSE-FREE": "SHAMPOO & BODY WASH RINSE-FREE"
            }
          }
        },
        "Phosphodiesterase 4 (PDE4) Inhibitors - Topical": {
          "Phosphodiesterase 4 (PDE4) Inhibitors - Topical": {
            "PFIZER U.S.": {
              "EUCRISA": "EUCRISA"
            }
          }
        },
        "Pigmenting-Depigmenting Agents": {
          "Depigmenting Agents": {
            "ACELLA PHARMACEUTICALS": {
              "HYDROQUINONE": "HYDROQUINONE"
            },
            "AGI DERMATICS": {
              "REMERGENT HQ": "REMERGENT HQ"
            },
            "AIDAREX PHARMACEUTICALS": {
              "HYDROQUINONE": "HYDROQUINONE"
            },
            "AKRON PHARMA": {
              "HYDROQUINONE": "HYDROQUINONE"
            },
            "ALVIX LABORATORIES": {
              "HYDROQUINONE": "HYDROQUINONE"
            },
            "API SOLUTIONS": {
              "HYDROQUINONE": "HYDROQUINONE"
            },
            "BAUSCH HEALTH": {
              "AMBI FADE": "AMBI FADE",
              "ESOTERICA DAYTIME": "ESOTERICA DAYTIME",
              "ESOTERICA FADE NIGHTTIME": "ESOTERICA FADE NIGHTTIME"
            },
            "BI COASTAL PHARMACEUTICAL": {
              "HYDROQUINONE": "HYDROQUINONE"
            },
            "BPI LABS LLC": {
              "HYDROQUINONE": "HYDROQUINONE"
            },
            "CIPHER PHARMACEUTICALS": {
              "ACLARO": "ACLARO"
            },
            "E.T. BROWNE DRUG CO": {
              "SKIN SUCCESS FADE CREAM": "SKIN SUCCESS FADE CREAM"
            },
            "ENCORE SCIENTIFIC": {
              "HYDROQUINONE": "HYDROQUINONE"
            },
            "FAGRON": {
              "HYDROQUINONE": "HYDROQUINONE"
            },
            "FREEDOM PHARMACEUTICALS": {
              "HYDROQUINONE": "HYDROQUINONE"
            },
            "GENESIS PHARMACEUTICAL": {
              "SKIN BLEACHING": "SKIN BLEACHING",
              "SKIN BLEACHING-SUNSCREEN": "SKIN BLEACHING-SUNSCREEN"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "HYDROQUINONE": "HYDROQUINONE"
            },
            "HUMCO": {
              "HYDROQUINONE": "HYDROQUINONE"
            },
            "LETCO MEDICAL": {
              "HYDROQUINONE": "HYDROQUINONE"
            },
            "MEDICIS": {
              "ESOTERICA FACIAL": "ESOTERICA FACIAL",
              "ESOTERICA SENSITIVE SKIN": "ESOTERICA SENSITIVE SKIN"
            },
            "MEDISCA": {
              "HYDROQUINONE": "HYDROQUINONE"
            },
            "NEOCUTIS": {
              "BLANCHE": "BLANCHE"
            },
            "NEOSTRATA": {
              "EXUVIANCE LIGHTENING COMPLEX": "EXUVIANCE LIGHTENING COMPLEX",
              "NEOSTRATA HQ SKIN LIGHTENING": "NEOSTRATA HQ SKIN LIGHTENING"
            },
            "PCCA": {
              "HYDROQUINONE": "HYDROQUINONE"
            },
            "PERRIGO PHARMACEUTICALS": {
              "HYDROQUINONE": "HYDROQUINONE",
              "HYDROQUINONE TIME RELEASE": "HYDROQUINONE TIME RELEASE"
            },
            "PREFERRED PHARMACEUTICALS": {
              "HYDROQUINONE": "HYDROQUINONE"
            },
            "SINCERUS FLORIDA": {
              "HYDROQUINONE": "HYDROQUINONE"
            },
            "SKINMEDICA, INC": {
              "EPIQUIN MICRO": "EPIQUIN MICRO"
            },
            "SPECTRUM": {
              "HYDROQUINONE": "HYDROQUINONE"
            },
            "STRATUS PHARMACEUTICALS": {
              "ALPHAQUIN HP": "ALPHAQUIN HP",
              "MELPAQUE HP": "MELPAQUE HP",
              "MELQUIN 3": "MELQUIN 3"
            },
            "SYNTENZA PHARMACEUTICALS": {
              "HYDROQUINONE": "HYDROQUINONE"
            },
            "TRIGEN LABORATORIES": {
              "TL HYDROQUINONE": "TL HYDROQUINONE"
            },
            "TWI PHARMACEUTICALS": {
              "HYDROQUINONE": "HYDROQUINONE",
              "HYDROQUINONE TIME RELEASE": "HYDROQUINONE TIME RELEASE"
            },
            "WESTMINSTER PHARMACEUTICALS": {
              "HYDROQUINONE": "HYDROQUINONE"
            }
          },
          "Depigmenting Combinations": {
            "ACCUMIX PHARMACEUTICALS": {
              "CENOVIA": "CENOVIA",
              "CLARYS": "CLARYS"
            },
            "GALDERMA": {
              "TRI-LUMA": "TRI-LUMA"
            },
            "SINCERUS FLORIDA": {
              "HYALURONIC ACID-HYDROQUINONE": "HYALURONIC ACID-HYDROQUINONE",
              "HYDROQUINONE-HC-TRETINOIN": "HYDROQUINONE-HC-TRETINOIN",
              "HYDROQUINONE-HYDROCORTISONE": "HYDROQUINONE-HYDROCORTISONE",
              "LACTIC ACID-NIACINAMIDE": "LACTIC ACID-NIACINAMIDE"
            }
          },
          "Pigmenting Agents": {
            "BAUSCH HEALTH": {
              "OXSORALEN": "OXSORALEN",
              "VITADYE": "VITADYE"
            },
            "GALDERMA": {
              "DY-O-DERM VITILIGO STAIN": "DY-O-DERM VITILIGO STAIN"
            },
            "LETCO MEDICAL": {
              "METHOXSALEN": "METHOXSALEN"
            },
            "PCCA": {
              "METHOXSALEN": "METHOXSALEN"
            },
            "SPECTRUM": {
              "METHOXSALEN": "METHOXSALEN"
            },
            "SUMMERS LABS": {
              "CHROMELIN": "CHROMELIN"
            }
          }
        },
        "Podiatric Products": {
          "Podiatric Product - Combinations": {
            "GORDON": {
              "GORDOMATIC": "GORDOMATIC"
            }
          },
          "Podiatric Products": {
            "AKORN CONSUMER": {
              "DIABETIDERM HEEL & TOE": "DIABETIDERM HEEL & TOE",
              "DIADERM FOOT REJUVENATING": "DIADERM FOOT REJUVENATING"
            },
            "BAUSCH HEALTH": {
              "EXFOLIX EXFOLIATING FOOT SOAP": "EXFOLIX EXFOLIATING FOOT SOAP",
              "SLEEP-N-HEEL": "SLEEP-N-HEEL"
            },
            "BAYER CONSUMER": {
              "TINACTIN FOOT/SNEAKER DEODOR": "TINACTIN FOOT/SNEAKER DEODOR"
            },
            "BEIERSDORF DERMA": {
              "EUCERIN ADVANCED REPAIR FOOT": "EUCERIN ADVANCED REPAIR FOOT"
            },
            "CHAIN DRUG CONSORTIUM": {
              "HEEL BALM": "HEEL BALM"
            },
            "CHATTEM INC": {
              "GOLD BOND FOOT": "GOLD BOND FOOT"
            },
            "DARTMOUTH": {
              "ELON HERBAL FOOT": "ELON HERBAL FOOT"
            },
            "DR. CANUSO": {
              "DAILY EXFOLIATING CLEANSER": "DAILY EXFOLIATING CLEANSER"
            },
            "E.T. BROWNE DRUG CO": {
              "PALMERS FOOT MAGIC SCRUB": "PALMERS FOOT MAGIC SCRUB",
              "PALMERS HEEL REPAIR": "PALMERS HEEL REPAIR"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "LAMISILK CLEANSE": "LAMISILK CLEANSE",
              "LAMISILK PROTECT": "LAMISILK PROTECT"
            },
            "LACORIUM HEALTH USA": {
              "FLEXITOL HEEL BALM": "FLEXITOL HEEL BALM"
            },
            "MEDIMETRIKS PHARMACEUTICALS": {
              "TOETAL FRESH": "TOETAL FRESH"
            },
            "NEUTROGENA": {
              "NEUTROGENA FOOT": "NEUTROGENA FOOT"
            },
            "REDEX INDUSTRIES": {
              "UDDERLY SMOOTH FOOT": "UDDERLY SMOOTH FOOT"
            },
            "SANDOZ": {
              "AMLACTIN FOOT CREAM THERAPY": "AMLACTIN FOOT CREAM THERAPY"
            },
            "UPSHER-SMITH": {
              "AMLACTIN FOOT CREAM THERAPY": "AMLACTIN FOOT CREAM THERAPY"
            },
            "WALGREENS": {
              "CRACKED HEEL SKIN SOFTENER": "CRACKED HEEL SKIN SOFTENER",
              "FOOT TREATMENT ADVANCED": "FOOT TREATMENT ADVANCED",
              "HEEL BALM": "HEEL BALM"
            },
            "XENNA": {
              "CALLEX": "CALLEX"
            }
          }
        },
        "Poison Ivy Products": {
          "Poison Ivy Product Combinations": {
            "IVY-DRY": {
              "IVY-DRY": "IVY-DRY",
              "IVY-DRY SUPER": "IVY-DRY SUPER",
              "IVY-DRY SUPER CONTINUOUS SPRAY": "IVY-DRY SUPER CONTINUOUS SPRAY"
            },
            "WALGREENS": {
              "POISON IVY TREATMENT": "POISON IVY TREATMENT"
            }
          },
          "Poison Ivy Products": {
            "AMERICAN SALES COMPANY": {
              "POISON IVY WASH FAST ACTING": "POISON IVY WASH FAST ACTING"
            },
            "BERGEN BRUNSWIG": {
              "GNP POISON IVY WASH": "GNP POISON IVY WASH"
            },
            "BLISTEX": {
              "IVAREST MEDICATED POISON IVY": "IVAREST MEDICATED POISON IVY"
            },
            "CHAIN DRUG CONSORTIUM": {
              "POISON IVY SCRUB": "POISON IVY SCRUB"
            },
            "ENVIRODERM PHARMACEUTICALS": {
              "IVY BLOCK": "IVY BLOCK"
            },
            "HUMCO": {
              "IVY WASH POISON IVY CLEANSER": "IVY WASH POISON IVY CLEANSER"
            },
            "IVY-DRY": {
              "IVY DRY": "IVY DRY",
              "IVY-DRY SOAP": "IVY-DRY SOAP"
            },
            "LEADER BRAND PRODUCTS": {
              "POISON IVY WASH": "POISON IVY WASH"
            },
            "MEDIQUE PRODUCTS": {
              "IVY-RID": "IVY-RID"
            },
            "TEC LABS": {
              "TECNU OUTDOOR SKIN CLEANSER": "TECNU OUTDOOR SKIN CLEANSER"
            },
            "WALGREENS": {
              "POISON IVY WASH": "POISON IVY WASH"
            },
            "ZANFEL LABORATORIES, INC.": {
              "ZANFEL": "ZANFEL"
            }
          }
        },
        "Protectives Against UV Radiation": {
          "Melanocortin Receptor Agonists (UV Protective)": {
            "CLINUVEL": {
              "SCENESSE": "SCENESSE"
            }
          },
          "Sunscreens": {
            "AKORN CONSUMER": {
              "DIABETIDERM SUNSCREEN SPF15": "DIABETIDERM SUNSCREEN SPF15"
            },
            "B-M SQUIBB U.S. (PRIMARY CARE)": {
              "PRESUN ULTRA/PARSOL": "PRESUN ULTRA/PARSOL"
            },
            "BAUSCH HEALTH": {
              "CERAVE SUNSCREEN": "CERAVE SUNSCREEN",
              "CERAVE SUNSCREEN SPF50": "CERAVE SUNSCREEN SPF50",
              "RV PAQUE": "RV PAQUE"
            },
            "BAYER CONSUMER": {
              "COPPERTONE SPORT SPF50 COMBO": "COPPERTONE SPORT SPF50 COMBO",
              "SHADE OIL FREE CLEAR": "SHADE OIL FREE CLEAR",
              "SHADE SUNBLOCK SPF45": "SHADE SUNBLOCK SPF45",
              "SHADE UVAGUARD SPF15": "SHADE UVAGUARD SPF15",
              "WATER BABIES SPF30": "WATER BABIES SPF30"
            },
            "BEIERSDORF DERMA": {
              "COPPERTONE KIDS SPF50": "COPPERTONE KIDS SPF50",
              "COPPERTONE KIDS SPF70": "COPPERTONE KIDS SPF70",
              "COPPERTONE PURE & SIMPLE SPF50": "COPPERTONE PURE & SIMPLE SPF50",
              "COPPERTONE SPORT CLEAR": "COPPERTONE SPORT CLEAR",
              "COPPERTONE SPORT FACE SPF50": "COPPERTONE SPORT FACE SPF50",
              "COPPERTONE SPORT SPF 100": "COPPERTONE SPORT SPF 100",
              "COPPERTONE SPORT SPF 30": "COPPERTONE SPORT SPF 30",
              "COPPERTONE SPORT SPF15": "COPPERTONE SPORT SPF15",
              "COPPERTONE SPORT SPF30": "COPPERTONE SPORT SPF30",
              "COPPERTONE SPORT SPF50": "COPPERTONE SPORT SPF50",
              "COPPERTONE WATERBABIES WHIPPED": "COPPERTONE WATERBABIES WHIPPED",
              "NIVEA HAND THERAPY": "NIVEA HAND THERAPY",
              "NIVEA VISAGE 12-HR DEEP MOIST": "NIVEA VISAGE 12-HR DEEP MOIST",
              "NIVEA VISAGE ADVANCED VITALITY": "NIVEA VISAGE ADVANCED VITALITY",
              "NIVEA VISAGE ANTI-WRINKLE": "NIVEA VISAGE ANTI-WRINKLE",
              "NIVEA VISAGE EYE CONTOUR": "NIVEA VISAGE EYE CONTOUR",
              "NIVEA VISAGE UV CARE": "NIVEA VISAGE UV CARE"
            },
            "BLISTEX": {
              "BLISTEX COMPLETE MOISTURE": "BLISTEX COMPLETE MOISTURE",
              "BLISTEX FIVE STAR PROTECTION": "BLISTEX FIVE STAR PROTECTION",
              "BLISTEX FRUIT SMOOTHIES": "BLISTEX FRUIT SMOOTHIES",
              "BLISTEX HERBAL ANSWER": "BLISTEX HERBAL ANSWER",
              "BLISTEX LIP BALM": "BLISTEX LIP BALM",
              "BLISTEX SILK & SHINE": "BLISTEX SILK & SHINE"
            },
            "CARMA LAB INC": {
              "CARMEX CLASSIC LIP BALM": "CARMEX CLASSIC LIP BALM",
              "CARMEX DAILY CARE LIP BALM": "CARMEX DAILY CARE LIP BALM"
            },
            "CHAIN DRUG CONSORTIUM": {
              "CONTINUOUS SPRAY KIDS SPF50": "CONTINUOUS SPRAY KIDS SPF50",
              "OIL OF BEAUTY": "OIL OF BEAUTY",
              "SUNSCREEN SPF30": "SUNSCREEN SPF30"
            },
            "CHATTEM INC": {
              "BULL FROG FASTBLAST SPF 36": "BULL FROG FASTBLAST SPF 36",
              "BULL FROG MARATHON MIST KIDS": "BULL FROG MARATHON MIST KIDS",
              "BULL FROG MARATHON MIST SPF 36": "BULL FROG MARATHON MIST SPF 36",
              "BULL FROG MARATHON MIST SPF50": "BULL FROG MARATHON MIST SPF50",
              "BULL FROG QUIK": "BULL FROG QUIK",
              "BULL FROG QUIK SPF 36": "BULL FROG QUIK SPF 36",
              "BULL FROG QUIK SPF50": "BULL FROG QUIK SPF50",
              "BULL FROG QUIK SPORT SPF 36": "BULL FROG QUIK SPORT SPF 36",
              "BULL FROG QUIK SPORT SPF 50": "BULL FROG QUIK SPORT SPF 50",
              "BULL FROG SHEER PROTECTION": "BULL FROG SHEER PROTECTION",
              "BULL FROG SPF36": "BULL FROG SPF36",
              "BULL FROG SUPERBLOCK SPF50": "BULL FROG SUPERBLOCK SPF50",
              "BULL FROG WATER ARMOR SPORT": "BULL FROG WATER ARMOR SPORT"
            },
            "CROWN LABORATORIES": {
              "BLUE LIZARD SPF30+ BABY": "BLUE LIZARD SPF30+ BABY",
              "BLUE LIZARD SPF30+ FACE": "BLUE LIZARD SPF30+ FACE",
              "BLUE LIZARD SPF30+ REGULAR": "BLUE LIZARD SPF30+ REGULAR",
              "BLUE LIZARD SPF30+ SENSITIVE": "BLUE LIZARD SPF30+ SENSITIVE",
              "BLUE LIZARD SPF30+ SPORT": "BLUE LIZARD SPF30+ SPORT"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS SENSITIVE SKIN SUN": "CVS SENSITIVE SKIN SUN",
              "CVS SUNSCREEN SPF 30": "CVS SUNSCREEN SPF 30",
              "CVS SUNSCREEN SPF 30 OIL FREE": "CVS SUNSCREEN SPF 30 OIL FREE"
            },
            "E.T. BROWNE DRUG CO": {
              "PALMERS COCONUT OIL LIP BALM": "PALMERS COCONUT OIL LIP BALM",
              "PALMERS LIP BALM": "PALMERS LIP BALM",
              "PALMERS MOISTURIZING DAY": "PALMERS MOISTURIZING DAY"
            },
            "EQUALINE": {
              "EQL LIP BALM": "EQL LIP BALM",
              "EQL SPORT CONTINUOUS SPR SPF50": "EQL SPORT CONTINUOUS SPR SPF50",
              "EQL ULTRA PROTECTION SPF50": "EQL ULTRA PROTECTION SPF50"
            },
            "FALLENE": {
              "COTZ": "COTZ",
              "FACE COTZ": "FACE COTZ",
              "LIPCOTZ": "LIPCOTZ",
              "TOTAL BLOCK SPF 60 COVER UP": "TOTAL BLOCK SPF 60 COVER UP",
              "TOTAL BLOCK SPF 65 CLEAR": "TOTAL BLOCK SPF 65 CLEAR"
            },
            "GALDERMA": {
              "CETAPHIL DEFENSE SPF 50": "CETAPHIL DEFENSE SPF 50"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "CHAPSTICK": "CHAPSTICK",
              "CHAPSTICK ACTIVE SPORT READY": "CHAPSTICK ACTIVE SPORT READY",
              "CHAPSTICK LIPSHIELD 365": "CHAPSTICK LIPSHIELD 365",
              "CHAPSTICK MOISTURIZER": "CHAPSTICK MOISTURIZER",
              "CHAPSTICK ULTRA SPF30": "CHAPSTICK ULTRA SPF30",
              "KERI AGE DEFY & PROTECT": "KERI AGE DEFY & PROTECT"
            },
            "J & J CONSUMER PRODUCTS": {
              "AVEENO ABSOLUTE AGELESS SPF30": "AVEENO ABSOLUTE AGELESS SPF30",
              "AVEENO ACT NAT PROTECT+HYDRATE": "AVEENO ACT NAT PROTECT+HYDRATE",
              "AVEENO BABY MINERALBLOCK SPF50": "AVEENO BABY MINERALBLOCK SPF50",
              "AVEENO BABY NATURAL SPF50": "AVEENO BABY NATURAL SPF50",
              "AVEENO BABY SUNBLOCK SPF55": "AVEENO BABY SUNBLOCK SPF55",
              "AVEENO BABY SUNSCREEN": "AVEENO BABY SUNSCREEN",
              "AVEENO DAILY MOISTURIZER": "AVEENO DAILY MOISTURIZER",
              "AVEENO HYDROSPORT SPF30": "AVEENO HYDROSPORT SPF30",
              "AVEENO NATURAL PROTECT SPF50": "AVEENO NATURAL PROTECT SPF50",
              "AVEENO POSITIVE RADIANT SPF15": "AVEENO POSITIVE RADIANT SPF15",
              "AVEENO POSITIVE RADIANT SPF30": "AVEENO POSITIVE RADIANT SPF30",
              "AVEENO PROTECT+HYDRATE SPF50": "AVEENO PROTECT+HYDRATE SPF50",
              "AVEENO PROTECT+HYDRATE SPF70": "AVEENO PROTECT+HYDRATE SPF70",
              "AVEENO ULTRA-CALMING SPF15": "AVEENO ULTRA-CALMING SPF15",
              "AVEENO ULTRA-CALMING SPF30": "AVEENO ULTRA-CALMING SPF30",
              "ROC MULTI CORREXION 5 IN 1": "ROC MULTI CORREXION 5 IN 1",
              "ROC RETINOL CORREXION SPF30": "ROC RETINOL CORREXION SPF30"
            },
            "JOHNSON&JOHNSON HEALTHCARE": {
              "AVEENO BABY CONTINUOUS PROTECT": "AVEENO BABY CONTINUOUS PROTECT",
              "AVEENO KIDS CONTINUOUS PROTECT": "AVEENO KIDS CONTINUOUS PROTECT"
            },
            "KIMBERLY-CLARK": {
              "HUGGIES LITTLE SWIMMERS SPF50": "HUGGIES LITTLE SWIMMERS SPF50"
            },
            "L'OREAL": {
              "ANTHELIOS 30 ULTRA LIGHT": "ANTHELIOS 30 ULTRA LIGHT",
              "ANTHELIOS 40": "ANTHELIOS 40",
              "ANTHELIOS 45 ULTRA LIGHT": "ANTHELIOS 45 ULTRA LIGHT",
              "ANTHELIOS 50 ANTI-AGING PRIMER": "ANTHELIOS 50 ANTI-AGING PRIMER",
              "ANTHELIOS 50 MINERAL": "ANTHELIOS 50 MINERAL",
              "ANTHELIOS 60 MELT-IN": "ANTHELIOS 60 MELT-IN",
              "ANTHELIOS 60 MELT-IN MILK": "ANTHELIOS 60 MELT-IN MILK",
              "ANTHELIOS 60 ULTRA LIGHT": "ANTHELIOS 60 ULTRA LIGHT",
              "ANTHELIOS SX": "ANTHELIOS SX"
            },
            "LEADER BRAND PRODUCTS": {
              "BABY SUNSCREEN SPF50": "BABY SUNSCREEN SPF50",
              "GENERAL PROTECTION SUNSCREEN": "GENERAL PROTECTION SUNSCREEN",
              "KIDS CONTINUOUS SPRAY SPF50": "KIDS CONTINUOUS SPRAY SPF50",
              "LIP BALM SPF15": "LIP BALM SPF15",
              "LIP BALM SPF4": "LIP BALM SPF4",
              "SPORT SUNSCREEN SPF30": "SPORT SUNSCREEN SPF30",
              "SPORT SUNSCREEN SPF50": "SPORT SUNSCREEN SPF50",
              "SUNSCREEN KIDS SPF50+": "SUNSCREEN KIDS SPF50+",
              "SUNSCREEN ULTRA SHEER": "SUNSCREEN ULTRA SHEER"
            },
            "MCKESSON SUNMARK": {
              "SM SUNSCREEN CHILDRENS SPF 45": "SM SUNSCREEN CHILDRENS SPF 45"
            },
            "MED-DERM": {
              "SPORTZ BLOC": "SPORTZ BLOC"
            },
            "MEDICINE SHOPPE": {
              "CHILDRENS SUNBLOCK SPF30": "CHILDRENS SUNBLOCK SPF30"
            },
            "MEDIMETRIKS PHARMACEUTICALS": {
              "NISEKO SUNSCREEN SPF 25": "NISEKO SUNSCREEN SPF 25"
            },
            "NATIONAL VITAMIN": {
              "CHANTAL SUN SCREEN SPF 30": "CHANTAL SUN SCREEN SPF 30"
            },
            "NEUTROGENA": {
              "NEUTROGENA AGE SHIELD": "NEUTROGENA AGE SHIELD",
              "NEUTROGENA AGE SHIELD SPF70": "NEUTROGENA AGE SHIELD SPF70",
              "NEUTROGENA BABY PURE & FREE": "NEUTROGENA BABY PURE & FREE",
              "NEUTROGENA BEACH DEFENSE SPF70": "NEUTROGENA BEACH DEFENSE SPF70",
              "NEUTROGENA CLEAR FACE SPF 55": "NEUTROGENA CLEAR FACE SPF 55",
              "NEUTROGENA COOLDRY SPF 30": "NEUTROGENA COOLDRY SPF 30",
              "NEUTROGENA HEALTHY DEFENSE": "NEUTROGENA HEALTHY DEFENSE",
              "NEUTROGENA HEALTHY SKIN SPF15": "NEUTROGENA HEALTHY SKIN SPF15",
              "NEUTROGENA LIP MOISTURIZER": "NEUTROGENA LIP MOISTURIZER",
              "NEUTROGENA MEN SPF 20": "NEUTROGENA MEN SPF 20",
              "NEUTROGENA MOISTURE SPF15": "NEUTROGENA MOISTURE SPF15",
              "NEUTROGENA SENSITIVE SKIN 60+": "NEUTROGENA SENSITIVE SKIN 60+",
              "NEUTROGENA SPORT FACE SPF70": "NEUTROGENA SPORT FACE SPF70",
              "NEUTROGENA ULTRA SHEER SPF 100": "NEUTROGENA ULTRA SHEER SPF 100",
              "NEUTROGENA ULTRA SHEER SPF 45": "NEUTROGENA ULTRA SHEER SPF 45",
              "NEUTROGENA ULTRA SHEER SPF 55": "NEUTROGENA ULTRA SHEER SPF 55",
              "NEUTROGENA ULTRA SHEER SPF 70": "NEUTROGENA ULTRA SHEER SPF 70",
              "PURE & FREE BABY SUNSCREEN": "PURE & FREE BABY SUNSCREEN"
            },
            "PERSON COVEY": {
              "DML FACIAL MOISTURIZER": "DML FACIAL MOISTURIZER",
              "SOLBAR AVO": "SOLBAR AVO",
              "SOLBAR FIFTY": "SOLBAR FIFTY",
              "SOLBAR PF SPF15": "SOLBAR PF SPF15",
              "SOLBAR SHIELD SPF 40": "SOLBAR SHIELD SPF 40",
              "SOLBAR SPF30": "SOLBAR SPF30",
              "SOLBAR SPF50": "SOLBAR SPF50",
              "SOLBAR ZINC SPF38": "SOLBAR ZINC SPF38"
            },
            "PHARMACEUTICAL SPECIALITIES": {
              "VANICREAM LIP PROTECTANT": "VANICREAM LIP PROTECTANT",
              "VANICREAM SPF 30": "VANICREAM SPF 30",
              "VANICREAM SPF 35": "VANICREAM SPF 35",
              "VANICREAM SPF 50+": "VANICREAM SPF 50+",
              "VANICREAM SPF 60": "VANICREAM SPF 60",
              "VANICREAM SUNSCREEN": "VANICREAM SUNSCREEN"
            },
            "RITE AID CORPORATION": {
              "RA BABY SUNBLOCK SPF 45": "RA BABY SUNBLOCK SPF 45",
              "RA BABY SUNSCREEN SPF 45": "RA BABY SUNSCREEN SPF 45",
              "RA BABY SUNSCREEN SPRAY": "RA BABY SUNSCREEN SPRAY",
              "RA DARK TANNING": "RA DARK TANNING",
              "RA DARK TANNING SPF 4": "RA DARK TANNING SPF 4",
              "RA ESSENTIAL DEFENSE SPF 45": "RA ESSENTIAL DEFENSE SPF 45",
              "RA KIDS SPRAY SUNSCREEN SPF 50": "RA KIDS SPRAY SUNSCREEN SPF 50",
              "RA OIL FREE FACES": "RA OIL FREE FACES",
              "RA RX SUNCARE ADV PROTECTION": "RA RX SUNCARE ADV PROTECTION",
              "RA SHEER SUNSCREEN SPF 45": "RA SHEER SUNSCREEN SPF 45",
              "RA SPORT SUNSCREEN SPF 30": "RA SPORT SUNSCREEN SPF 30",
              "RA SPORT SUNSCREEN SPF 48": "RA SPORT SUNSCREEN SPF 48",
              "RA SPORT SUNSCREEN SPF15": "RA SPORT SUNSCREEN SPF15",
              "RA SUNSCREEN SPF15": "RA SUNSCREEN SPF15",
              "RA SUNSCREEN SPF30": "RA SUNSCREEN SPF30",
              "RA SUNSCREEN SPF50": "RA SUNSCREEN SPF50"
            },
            "SHEFFIELD PHARMACEUTICALS": {
              "CHAP-EX TROPICALS": "CHAP-EX TROPICALS",
              "LIP-EX": "LIP-EX"
            },
            "STEADMED MEDICAL": {
              "ELTA BLOCK SPF32": "ELTA BLOCK SPF32"
            },
            "TEVA PHARMACEUTICALS USA": {
              "TROPICAL GOLD BABY SUNBLOCK": "TROPICAL GOLD BABY SUNBLOCK",
              "TROPICAL GOLD DARK TANNING": "TROPICAL GOLD DARK TANNING",
              "TROPICAL GOLD SPORT SUNBLOCK": "TROPICAL GOLD SPORT SUNBLOCK",
              "TROPICAL GOLD SUNBLOCK": "TROPICAL GOLD SUNBLOCK",
              "TROPICAL GOLD SUNSCREEN": "TROPICAL GOLD SUNSCREEN"
            },
            "WAL-MART": {
              "EQ SUNSCREEN SPORT": "EQ SUNSCREEN SPORT"
            },
            "WALGREENS": {
              "ANTI-WRINKLE DAILY SPF15": "ANTI-WRINKLE DAILY SPF15",
              "CHAP-AID SPF15": "CHAP-AID SPF15",
              "CHAP-AID SPF4": "CHAP-AID SPF4",
              "CLEAR ZINC SPF 50": "CLEAR ZINC SPF 50",
              "CONTINUOUS SPRAY SPF30": "CONTINUOUS SPRAY SPF30",
              "MOISTURIZING FACIAL SPF 15": "MOISTURIZING FACIAL SPF 15",
              "MOISTURIZING SPF15": "MOISTURIZING SPF15",
              "SHEER SUNSCREEN SPF 70": "SHEER SUNSCREEN SPF 70",
              "SUNBLOCK LOTION SPF30": "SUNBLOCK LOTION SPF30",
              "SUNBLOCK SPF30": "SUNBLOCK SPF30",
              "SUNSCREEN SPF50": "SUNSCREEN SPF50",
              "SUNSCREEN SPORT SPF 70": "SUNSCREEN SPORT SPF 70"
            },
            "WESTWOOD-SQUIBB": {
              "PRESUN FOR KIDS SPF29": "PRESUN FOR KIDS SPF29",
              "PRESUN ULTRA/PARSOL": "PRESUN ULTRA/PARSOL"
            }
          }
        },
        "Rosacea Agents": {
          "Rosacea Agents": {
            "A-S MEDICATION SOLUTIONS": {
              "FINACEA": "FINACEA",
              "METROGEL": "METROGEL"
            },
            "ACTAVIS PHARMA": {
              "AZELAIC ACID": "AZELAIC ACID",
              "IVERMECTIN": "IVERMECTIN"
            },
            "ALLERGAN": {
              "RHOFADE": "RHOFADE"
            },
            "AMNEAL PHARMACEUTICALS": {
              "AZELAIC ACID": "AZELAIC ACID",
              "METRONIDAZOLE": "METRONIDAZOLE"
            },
            "BAUSCH HEALTH": {
              "NORITATE": "NORITATE"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "FINACEA": "FINACEA",
              "METRONIDAZOLE": "METRONIDAZOLE",
              "ORACEA": "ORACEA"
            },
            "COSETTE PHARMACEUTICALS": {
              "METRONIDAZOLE": "METRONIDAZOLE"
            },
            "EPI HEALTH": {
              "RHOFADE": "RHOFADE"
            },
            "FOAMIX PHARMACEUTICALS": {
              "ZILXI": "ZILXI"
            },
            "FOUGERA": {
              "METRONIDAZOLE": "METRONIDAZOLE"
            },
            "GALDERMA": {
              "METROCREAM": "METROCREAM",
              "METROGEL": "METROGEL",
              "METROLOTION": "METROLOTION",
              "MIRVASO": "MIRVASO",
              "ORACEA": "ORACEA",
              "SOOLANTRA": "SOOLANTRA"
            },
            "GLENMARK PHARMACEUTICALS": {
              "AZELAIC ACID": "AZELAIC ACID"
            },
            "HARRIS PHARMACEUTICAL": {
              "METRONIDAZOLE": "METRONIDAZOLE"
            },
            "LEO PHARMA INC": {
              "FINACEA": "FINACEA"
            },
            "MEDIMETRIKS PHARMACEUTICALS": {
              "ROSADAN": "ROSADAN"
            },
            "OWEN LABORATORIES": {
              "DOXYCYCLINE": "DOXYCYCLINE",
              "METRONIDAZOLE": "METRONIDAZOLE"
            },
            "PRASCO LABORATORIES": {
              "DOXYCYCLINE": "DOXYCYCLINE",
              "IVERMECTIN": "IVERMECTIN",
              "METRONIDAZOLE": "METRONIDAZOLE"
            },
            "PRUGEN": {
              "METRONIDAZOLE": "METRONIDAZOLE"
            },
            "QUALITY CARE": {
              "FINACEA": "FINACEA"
            },
            "SANDOZ": {
              "AZELAIC ACID": "AZELAIC ACID",
              "METRONIDAZOLE": "METRONIDAZOLE"
            },
            "TARO": {
              "AZELAIC ACID": "AZELAIC ACID",
              "METRONIDAZOLE": "METRONIDAZOLE"
            }
          },
          "Rosacea Combinations": {
            "SINCERUS FLORIDA": {
              "IVERMECTIN-METRONIDAZOL-NIACIN": "IVERMECTIN-METRONIDAZOL-NIACIN"
            }
          }
        },
        "Scabicides & Pediculicides": {
          "Nit Removers": {
            "BERGEN BRUNSWIG": {
              "GNP LICE EGG REMOVER": "GNP LICE EGG REMOVER"
            },
            "BIOSAFE TECHNOLOGIES": {
              "SCHOOLTIME SHAMPOO": "SCHOOLTIME SHAMPOO"
            },
            "CHAIN DRUG CONSORTIUM": {
              "LICE EGG REMOVER": "LICE EGG REMOVER"
            },
            "COMBE": {
              "LICEMD": "LICEMD"
            },
            "GENDERM": {
              "STEP 2": "STEP 2"
            },
            "INSIGHT PHARMACEUTICALS": {
              "NIX ULTRA": "NIX ULTRA"
            },
            "KIEL LABORATORIES": {
              "KLOUT": "KLOUT"
            },
            "MAINPOINTE PHARMACEUTICALS": {
              "LYCELLE": "LYCELLE"
            },
            "MEDICINE SHOPPE": {
              "MEDI-LICE COMBING": "MEDI-LICE COMBING"
            },
            "RITE AID CORPORATION": {
              "RA LICE EGG REMOVER": "RA LICE EGG REMOVER"
            },
            "SAFE EFFECTIVE ALTERNATIVES": {
              "LICE B GONE": "LICE B GONE",
              "LICE B GONE CAP SATURATED": "LICE B GONE CAP SATURATED"
            },
            "WAL-MED": {
              "LICEOUT": "LICEOUT"
            },
            "WALGREENS": {
              "STOP LICE STEP 2": "STOP LICE STEP 2"
            }
          },
          "Scabicide Combinations": {
            "A-S MEDICATION SOLUTIONS": {
              "LICE TREATMENT": "LICE TREATMENT"
            },
            "AG MARIN PHARMACEUTICALS": {
              "EXODERM": "EXODERM"
            },
            "AMERICAN SALES COMPANY": {
              "LICE KILLING MAXIMUM STRENGTH": "LICE KILLING MAXIMUM STRENGTH",
              "LICE SOLUTION COMPLETE": "LICE SOLUTION COMPLETE"
            },
            "BAYER CONSUMER": {
              "RID": "RID",
              "RID COMPLETE LICE ELIMINATION": "RID COMPLETE LICE ELIMINATION",
              "RID ESSENTIAL LICE ELIMINATION": "RID ESSENTIAL LICE ELIMINATION",
              "RID LICE KILLING SHAMPOO": "RID LICE KILLING SHAMPOO"
            },
            "BERGEN BRUNSWIG": {
              "GNP LICE SOLUTION": "GNP LICE SOLUTION",
              "GNP LICE TREATMENT": "GNP LICE TREATMENT"
            },
            "CHAIN DRUG CONSORTIUM": {
              "LICE KILLING MAXIMUM STRENGTH": "LICE KILLING MAXIMUM STRENGTH",
              "LICE TREATMENT MAX ST": "LICE TREATMENT MAX ST"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS LICE KILLING": "CVS LICE KILLING",
              "CVS LICE SOLUTION": "CVS LICE SOLUTION"
            },
            "EQUALINE": {
              "EQL LICE KILLING MAX ST": "EQL LICE KILLING MAX ST",
              "EQL LICE SOLUTION": "EQL LICE SOLUTION",
              "EQL LICE TREATMENT MAXIMUM STR": "EQL LICE TREATMENT MAXIMUM STR"
            },
            "GM PHARMACEUTICALS": {
              "VANALICE": "VANALICE"
            },
            "HOGIL PHARMACEUTICAL CORP": {
              "A-200": "A-200",
              "A-200 MAXIMUM STRENGTH": "A-200 MAXIMUM STRENGTH"
            },
            "INSIGHT PHARMACEUTICALS": {
              "NIX COMPLETE LICE TREATMENT": "NIX COMPLETE LICE TREATMENT"
            },
            "LEADER BRAND PRODUCTS": {
              "LICE KILLING": "LICE KILLING",
              "LICE KILLING MAXIMUM STRENGTH": "LICE KILLING MAXIMUM STRENGTH",
              "LICE SOLUTION": "LICE SOLUTION",
              "LICE TREATMENT": "LICE TREATMENT"
            },
            "MAJOR PHARMACEUTICALS": {
              "LICE KILLING MAXIMUM STRENGTH": "LICE KILLING MAXIMUM STRENGTH"
            },
            "MCKESSON": {
              "HM LICE KILLING MAX ST": "HM LICE KILLING MAX ST"
            },
            "MCKESSON SUNMARK": {
              "SM LICE KILLING": "SM LICE KILLING",
              "SM LICE KILLING MAX STRENGTH": "SM LICE KILLING MAX STRENGTH",
              "SM LICE SOLUTION": "SM LICE SOLUTION",
              "SM LICE SOLUTION KIT": "SM LICE SOLUTION KIT"
            },
            "PERRIGO PHARMACEUTICALS": {
              "LICE KILLING MAXIMUM STRENGTH": "LICE KILLING MAXIMUM STRENGTH"
            },
            "RECKITT BENCKISER": {
              "LICEMD": "LICEMD",
              "LICEMD COMPLETE": "LICEMD COMPLETE"
            },
            "REESE PHARMACEUTICAL": {
              "LICIDE": "LICIDE",
              "LICIDE COMPLETE LICE TREATMENT": "LICIDE COMPLETE LICE TREATMENT",
              "LICIDE MAXIMUM STRENGTH": "LICIDE MAXIMUM STRENGTH",
              "LICIDE TREATMENT": "LICIDE TREATMENT"
            },
            "RITE AID CORPORATION": {
              "RA LICE MAXIMUM STRENGTH": "RA LICE MAXIMUM STRENGTH",
              "RA LICE SOLUTION": "RA LICE SOLUTION"
            },
            "SELECT BRAND": {
              "SB LICE KILLING MAX ST": "SB LICE KILLING MAX ST",
              "SB LICE TREATMENT": "SB LICE TREATMENT"
            },
            "TARGET": {
              "TGT LICE COMPLETE 1-2-3": "TGT LICE COMPLETE 1-2-3",
              "TGT LICE KILLING MAX ST": "TGT LICE KILLING MAX ST"
            },
            "TOPCO": {
              "COMPLETE LICE TREATMENT": "COMPLETE LICE TREATMENT",
              "LICE KILLING": "LICE KILLING"
            },
            "WAL-MART": {
              "EQ COMPLETE LICE TREATMENT": "EQ COMPLETE LICE TREATMENT",
              "EQ LICE KILLING MAX ST": "EQ LICE KILLING MAX ST"
            },
            "WALGREENS": {
              "STOP LICE COMPLETE TREATMENT": "STOP LICE COMPLETE TREATMENT",
              "STOP LICE MAXIMUM STRENGTH": "STOP LICE MAXIMUM STRENGTH"
            }
          },
          "Scabicides & Pediculicides": {
            "A-S MEDICATION SOLUTIONS": {
              "EURAX": "EURAX",
              "NIX CREME RINSE": "NIX CREME RINSE",
              "PERMETHRIN": "PERMETHRIN"
            },
            "ACTAVIS MID ATLANTIC": {
              "PERMETHRIN": "PERMETHRIN"
            },
            "AIDAREX PHARMACEUTICALS": {
              "PERMETHRIN": "PERMETHRIN"
            },
            "ALLEGIS PHARMACEUTICALS": {
              "SPINOSAD": "SPINOSAD"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP LICE TREATMENT": "GNP LICE TREATMENT"
            },
            "ARBOR PHARMACEUTICALS": {
              "SKLICE": "SKLICE"
            },
            "BAYER CONSUMER": {
              "RID": "RID"
            },
            "BERGEN BRUNSWIG": {
              "GNP LICE BEDDING": "GNP LICE BEDDING"
            },
            "CHAIN DRUG CONSORTIUM": {
              "BEDDING SPRAY LICE TREATMENT": "BEDDING SPRAY LICE TREATMENT"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS BEDDING SPRAY LICE TREAT": "CVS BEDDING SPRAY LICE TREAT",
              "CVS LICE TREATMENT": "CVS LICE TREATMENT",
              "CVS PERMETHRIN": "CVS PERMETHRIN"
            },
            "ENCUBE ETHICALS": {
              "PERMETHRIN": "PERMETHRIN"
            },
            "EQUALINE": {
              "EQL LICE TREATMENT": "EQL LICE TREATMENT"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "PERMETHRIN": "PERMETHRIN"
            },
            "HOGIL PHARMACEUTICAL CORP": {
              "A-200": "A-200"
            },
            "HY-VEE": {
              "LICE TREATMENT": "LICE TREATMENT"
            },
            "INSIGHT PHARMACEUTICALS": {
              "NIX CREME RINSE": "NIX CREME RINSE"
            },
            "LACHLAN PHARMACEUTICALS": {
              "ULESFIA": "ULESFIA"
            },
            "LEADER BRAND PRODUCTS": {
              "BEDDING SPRAY LICE TREATMENT": "BEDDING SPRAY LICE TREATMENT",
              "LICE TREATMENT": "LICE TREATMENT"
            },
            "MARNEL PHARMACEUTICAL": {
              "CROTAN": "CROTAN"
            },
            "MCKESSON": {
              "HM LICE TREATMENT": "HM LICE TREATMENT"
            },
            "MCKESSON SUNMARK": {
              "SM BEDDING LICE TREATMENT": "SM BEDDING LICE TREATMENT",
              "SM LICE TREATMENT": "SM LICE TREATMENT"
            },
            "MORTON GROVE PHARMACEUTICALS": {
              "LINDANE": "LINDANE"
            },
            "MYLAN": {
              "PERMETHRIN": "PERMETHRIN"
            },
            "NORTHSTAR RX": {
              "PERMETHRIN": "PERMETHRIN"
            },
            "PARAPRO": {
              "NATROBA": "NATROBA"
            },
            "PCCA": {
              "SULFURATED LIME": "SULFURATED LIME"
            },
            "PERRIGO PHARMACEUTICALS": {
              "BEDDING SPRAY LICE TREATMENT": "BEDDING SPRAY LICE TREATMENT",
              "PERMETHRIN": "PERMETHRIN"
            },
            "PHARMEDIX": {
              "PERMETHRIN": "PERMETHRIN"
            },
            "PRESTIUM PHARMA": {
              "ELIMITE": "ELIMITE"
            },
            "RANBAXY LABORATORIES": {
              "EURAX": "EURAX"
            },
            "REESE PHARMACEUTICAL": {
              "LICIDE": "LICIDE"
            },
            "RITE AID CORPORATION": {
              "RA LICE BEDDING": "RA LICE BEDDING",
              "RA LICE TREATMENT": "RA LICE TREATMENT"
            },
            "SELECT BRAND": {
              "SB LICE TREATMENT": "SB LICE TREATMENT"
            },
            "TARO": {
              "MALATHION": "MALATHION",
              "OVIDE": "OVIDE"
            },
            "TOPCO": {
              "LICE TREATMENT": "LICE TREATMENT"
            },
            "VERSAPHARM": {
              "LINDANE": "LINDANE"
            },
            "WALGREENS": {
              "LICE TREATMENT": "LICE TREATMENT",
              "STOP LICE": "STOP LICE",
              "STOP LICE STEP 3": "STOP LICE STEP 3"
            },
            "ZYLERA PHARMACEUTICALS": {
              "ULESFIA": "ULESFIA"
            }
          }
        },
        "Scar Treatment Products": {
          "Scar Treatment Products": {
            "A-S MEDICATION SOLUTIONS": {
              "MEDERMA": "MEDERMA"
            },
            "ADVANCED BIO-TECHNOLOGIES": {
              "KELO-COTE": "KELO-COTE",
              "KELO-COTE KIDS": "KELO-COTE KIDS"
            },
            "ADVANTICE HEALTH": {
              "NEW SKIN SCAR THERAPY": "NEW SKIN SCAR THERAPY"
            },
            "ALEXSO": {
              "SCARCIN": "SCARCIN"
            },
            "AVOCET POLYMER TECHNOLOGIES": {
              "AVOSIL": "AVOSIL"
            },
            "CHAIN DRUG CONSORTIUM": {
              "SCAR GEL": "SCAR GEL"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS SCAR": "CVS SCAR"
            },
            "E.T. BROWNE DRUG CO": {
              "PALMERS SCAR SERUM": "PALMERS SCAR SERUM"
            },
            "ENOVACHEM MANUFACTURING": {
              "SKARJEL": "SKARJEL"
            },
            "EQUALINE": {
              "EQL SCAR": "EQL SCAR"
            },
            "GLENMARK THERAPEUTICS": {
              "RECEDO": "RECEDO"
            },
            "HRA PHARMA RARE DISEASES": {
              "MEDERMA": "MEDERMA",
              "MEDERMA FOR KIDS": "MEDERMA FOR KIDS",
              "MEDERMA SPF 30": "MEDERMA SPF 30"
            },
            "INTRADERM PHARMACEUTICALS": {
              "CELACYN": "CELACYN"
            },
            "LEADER BRAND PRODUCTS": {
              "SCAR GEL": "SCAR GEL"
            },
            "MEDISCA": {
              "COPADERM": "COPADERM",
              "COPASIL": "COPASIL"
            },
            "NATIONAL BIO GREEN SCIENCES": {
              "SCARSILK": "SCARSILK"
            },
            "OCULUS INNOVATIVE SCIENCES": {
              "RESTIZAN": "RESTIZAN"
            },
            "PERRIGO": {
              "SCARAWAY": "SCARAWAY"
            },
            "PREFERRED PHARMACEUTICALS": {
              "SKARJEL": "SKARJEL"
            },
            "RITE AID CORPORATION": {
              "RA SCAR": "RA SCAR"
            },
            "SA3": {
              "SCARCIN": "SCARCIN"
            },
            "SONOMA PHARMACEUTICALS": {
              "SP SCAR MANAGEMENT": "SP SCAR MANAGEMENT"
            },
            "STERLING KNIGHT PHARMACEUTICAL": {
              "BEAU RX": "BEAU RX",
              "KELARX": "KELARX"
            },
            "STRATPHARMA AG": {
              "STRATA TRIZ": "STRATA TRIZ"
            },
            "WALGREENS": {
              "SCAR": "SCAR",
              "SCAR GEL": "SCAR GEL"
            }
          },
          "Scar Treatment Products - Combinations": {
            "BINGER CONSULTING": {
              "DERMOVIX": "DERMOVIX"
            },
            "PATCHWERX LABS": {
              "RENUU NL": "RENUU NL"
            },
            "STERLING KNIGHT PHARMACEUTICAL": {
              "SILIPAC": "SILIPAC"
            }
          }
        },
        "Sunscreens": {
          "Sunscreens": {
            "J & J CONSUMER PRODUCTS": {
              "AVEENO CLEAR COMPLEXION BB": "AVEENO CLEAR COMPLEXION BB",
              "AVEENO POSITIVELY AGELESS": "AVEENO POSITIVELY AGELESS",
              "ROC MULTI CORREXION SPF30": "ROC MULTI CORREXION SPF30"
            },
            "MEDLINE/DERMAL MANAGEMENT": {
              "RADIABLOCK LIP BALM": "RADIABLOCK LIP BALM"
            },
            "NEUTROGENA": {
              "NEUTROGENA ULTRA SHEER SPF 55": "NEUTROGENA ULTRA SHEER SPF 55"
            }
          }
        },
        "Tar Products": {
          "Tar Combinations": {
            "CROWN LABORATORIES": {
              "ALA SEB T": "ALA SEB T"
            },
            "GLAXO SMITH KLINE": {
              "POLYTAR": "POLYTAR"
            },
            "MEDTECH": {
              "OXIPOR VHC PSORIASIS": "OXIPOR VHC PSORIASIS"
            },
            "STRATUS PHARMACEUTICALS": {
              "PAZOL XS": "PAZOL XS"
            }
          },
          "Tar Products": {
            "ALVA-AMCO": {
              "PSORIASIN": "PSORIASIN",
              "PSORIASIN DEEP MOISTURIZING": "PSORIASIN DEEP MOISTURIZING"
            },
            "BAKER CUMMINS DERMATOLOGICALS": {
              "X-SEB T PEARL": "X-SEB T PEARL",
              "X-SEB T PLUS": "X-SEB T PLUS"
            },
            "BAUSCH HEALTH": {
              "IONIL-T": "IONIL-T"
            },
            "BETA DERMS": {
              "BETA CARE BETATAR GEL": "BETA CARE BETATAR GEL"
            },
            "C S  DENT": {
              "GRANDPAS PINE TAR": "GRANDPAS PINE TAR",
              "GRANDPAS PINE TAR CONDITIONER": "GRANDPAS PINE TAR CONDITIONER",
              "GRANDPAS PINE TAR SOAP": "GRANDPAS PINE TAR SOAP"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS THERAPEUTIC": "CVS THERAPEUTIC"
            },
            "EQUALINE": {
              "EQL THERAPEUTIC": "EQL THERAPEUTIC"
            },
            "GERITREX CORPORATION": {
              "PC-TAR": "PC-TAR",
              "TERA-GEL TAR": "TERA-GEL TAR"
            },
            "LAKE CONSUMER PRODUCTS": {
              "MG217 PSORIASIS MEDICATED": "MG217 PSORIASIS MEDICATED",
              "MG217 PSORIASIS MULTI-SYMPTOM": "MG217 PSORIASIS MULTI-SYMPTOM"
            },
            "MAJOR PHARMACEUTICALS": {
              "THERAPEUTIC": "THERAPEUTIC"
            },
            "MCKESSON SUNMARK": {
              "SM ANTI-DANDRUFF COAL TAR": "SM ANTI-DANDRUFF COAL TAR"
            },
            "MEDICIS": {
              "PENTRAX GOLD": "PENTRAX GOLD",
              "THERAPLEX T": "THERAPLEX T"
            },
            "MEDISCA": {
              "COAL TAR": "COAL TAR"
            },
            "NEUTROGENA": {
              "NEUTROGENA T/GEL": "NEUTROGENA T/GEL",
              "NEUTROGENA T/GEL CONDITIONER": "NEUTROGENA T/GEL CONDITIONER",
              "NEUTROGENA T/GEL STUBBORN ITCH": "NEUTROGENA T/GEL STUBBORN ITCH"
            },
            "PERSON COVEY": {
              "DHS TAR": "DHS TAR",
              "DHS TAR GEL": "DHS TAR GEL"
            },
            "PROMIUS PHARMA": {
              "SCYTERA": "SCYTERA"
            },
            "RITE AID CORPORATION": {
              "RA THERAPEUTIC": "RA THERAPEUTIC"
            },
            "STEADMED MEDICAL": {
              "ELTA LITE TAR": "ELTA LITE TAR",
              "ELTA TAR": "ELTA TAR"
            },
            "SUMMERS LABS": {
              "CUTAR": "CUTAR",
              "TARSUM PROFESSIONAL": "TARSUM PROFESSIONAL"
            },
            "TRITON CONSUMER PRODUCTS": {
              "MG217 MEDICATED TAR": "MG217 MEDICATED TAR"
            },
            "ULTIMARK PRODUCTS": {
              "DENOREX THERAPEUTIC 2-IN-1": "DENOREX THERAPEUTIC 2-IN-1"
            },
            "WALGREENS": {
              "THERAPEUTIC T+PLUS": "THERAPEUTIC T+PLUS"
            }
          }
        },
        "Wound Care Products": {
          "Tissue Replacements": {
            "ALLIQUA BIOMEDICAL": {
              "BIOVANCE": "BIOVANCE"
            },
            "AMNIO TECHNOLOGY": {
              "KARDIAMEMBRANE": "KARDIAMEMBRANE",
              "PALINGEN FLOW": "PALINGEN FLOW",
              "PALINGEN HYDROMEMBRANE": "PALINGEN HYDROMEMBRANE",
              "PALINGEN INOVOFLO": "PALINGEN INOVOFLO",
              "PALINGEN MEMBRANE": "PALINGEN MEMBRANE",
              "PALINGEN XPLUS HYDROMEMBRANE": "PALINGEN XPLUS HYDROMEMBRANE",
              "PALINGEN XPLUS MEMBRANE": "PALINGEN XPLUS MEMBRANE"
            },
            "AMNIOX MEDICAL": {
              "NEOX 100": "NEOX 100",
              "NEOX CORD 1K": "NEOX CORD 1K"
            },
            "IT3 MEDICAL": {
              "AMPHENOL-40": "AMPHENOL-40"
            },
            "MIMEDX GROUP": {
              "AMNIOFIX": "AMNIOFIX",
              "AMNIOFIX SPORTS MED": "AMNIOFIX SPORTS MED",
              "EPICORD": "EPICORD",
              "EPIFIX": "EPIFIX",
              "EPIFIX MICRONIZED": "EPIFIX MICRONIZED",
              "ORTHOFLO": "ORTHOFLO",
              "ORTHOFLO SPORT": "ORTHOFLO SPORT"
            },
            "ORGANOGENESIS": {
              "AFFINITY": "AFFINITY",
              "APLIGRAF": "APLIGRAF",
              "DERMAGRAFT": "DERMAGRAFT",
              "NOVACHOR": "NOVACHOR",
              "NUCEL": "NUCEL",
              "NUSHIELD": "NUSHIELD",
              "OSTEOCONDUCTIVE MATRIX PLUS": "OSTEOCONDUCTIVE MATRIX PLUS",
              "TRANSCYTE": "TRANSCYTE"
            },
            "OSIRIS THERAPEUTICS": {
              "GRAFIX CORE 1.5CM X 2CM": "GRAFIX CORE 1.5CM X 2CM",
              "GRAFIX CORE 14MM": "GRAFIX CORE 14MM",
              "GRAFIX CORE 16MM": "GRAFIX CORE 16MM",
              "GRAFIX CORE 2CM X 3CM": "GRAFIX CORE 2CM X 3CM",
              "GRAFIX CORE 3CM X 4CM": "GRAFIX CORE 3CM X 4CM",
              "GRAFIX CORE 5CM X 5CM": "GRAFIX CORE 5CM X 5CM",
              "GRAFIX PRIME 1.5CM X 2CM": "GRAFIX PRIME 1.5CM X 2CM",
              "GRAFIX PRIME 14MM": "GRAFIX PRIME 14MM",
              "GRAFIX PRIME 16MM": "GRAFIX PRIME 16MM",
              "GRAFIX PRIME 2CM X 3CM": "GRAFIX PRIME 2CM X 3CM",
              "GRAFIX PRIME 3CM X 4CM": "GRAFIX PRIME 3CM X 4CM",
              "GRAFIX PRIME 5CM X 5CM": "GRAFIX PRIME 5CM X 5CM",
              "GRAFIX XC 7.5CM X 15CM": "GRAFIX XC 7.5CM X 15CM",
              "STRAVIX": "STRAVIX",
              "TRUSKIN": "TRUSKIN"
            },
            "REGENATIVE LABS": {
              "CORETEXT": "CORETEXT",
              "PROTEXT": "PROTEXT"
            },
            "STABILITY BIOLOGICS": {
              "AMNIOCORE HUMAN TISSUE": "AMNIOCORE HUMAN TISSUE"
            }
          },
          "Wound Care - Growth Factor Agents": {
            "QUALITY CARE": {
              "REGRANEX": "REGRANEX"
            },
            "SMITH & NEPHEW UNITED": {
              "REGRANEX": "REGRANEX"
            }
          },
          "Wound Care Combinations": {
            "AVPAK": {
              "BPCO": "BPCO"
            },
            "BASIEM": {
              "COLLIGINIX": "COLLIGINIX"
            },
            "BROOKFIELD PHARMACEUTICALS": {
              "BPCO": "BPCO"
            },
            "CANTON LABORATORIES": {
              "DERMULCERA": "DERMULCERA"
            },
            "COVIDIEN MEDICAL SUPPLIES": {
              "XEROFORM OIL EMULSION 2\"\"X2\"\"": "XEROFORM OIL EMULSION 2\"\"X2\"\"",
              "XEROFORM OIL EMULSION GAUZE": "XEROFORM OIL EMULSION GAUZE",
              "XEROFORM OIL EMULSION STRIP": "XEROFORM OIL EMULSION STRIP",
              "XEROFORM OIL ROLL 4\"\"X9\\'": "XEROFORM OIL ROLL 4\"\"X9\\'",
              "XEROFORM PETROLAT GAUZE 1\"\"X8\"\"": "XEROFORM PETROLAT GAUZE 1\"\"X8\"\"",
              "XEROFORM PETROLAT GAUZE 5\"\"X9\"\"": "XEROFORM PETROLAT GAUZE 5\"\"X9\"\"",
              "XEROFORM PETROLAT PATCH 2\"\"X2\"\"": "XEROFORM PETROLAT PATCH 2\"\"X2\"\"",
              "XEROFORM PETROLAT PATCH 4\"\"X4\"\"": "XEROFORM PETROLAT PATCH 4\"\"X4\"\"",
              "XEROFORM PETROLATUM DRESSING": "XEROFORM PETROLATUM DRESSING",
              "XEROFORM PETROLATUM OVERWRAP": "XEROFORM PETROLATUM OVERWRAP",
              "XEROFORM PETROLATUM ROLL": "XEROFORM PETROLATUM ROLL",
              "XEROFORM PETROLATUM ROLL 4\"\"X9\\'": "XEROFORM PETROLATUM ROLL 4\"\"X9\\'"
            },
            "DELTA PHARMACEUTICALS": {
              "AMBERDERM": "AMBERDERM"
            },
            "MESORA PHARMA": {
              "REXASIL PATCH & VITAMIN E LIQ": "REXASIL PATCH & VITAMIN E LIQ"
            },
            "MPM MEDICAL, INC.": {
              "REGENECARE": "REGENECARE"
            },
            "MYLAN INSTITUTIONAL": {
              "PRODERM": "PRODERM"
            },
            "NIVAGEN PHARMACEUTICALS": {
              "BALSAM PERU-CASTOR OIL": "BALSAM PERU-CASTOR OIL"
            },
            "STERLING KNIGHT PHARMACEUTICAL": {
              "LIDOTREX": "LIDOTREX"
            },
            "STRATUS PHARMACEUTICALS": {
              "VENELEX": "VENELEX"
            },
            "VIVERA PHARMACEUTICALS": {
              "SCARCARE GEL-PAD KIT/LARGE": "SCARCARE GEL-PAD KIT/LARGE"
            }
          },
          "Wound Care Supplies": {
            "BAUSCH HEALTH": {
              "PEDI-PRE TAPE SPRAY": "PEDI-PRE TAPE SPRAY"
            },
            "FERNDALE LAB": {
              "MASTISOL ADHESIVE": "MASTISOL ADHESIVE"
            },
            "GORDON": {
              "STIK-IT": "STIK-IT"
            },
            "HOLLISTER": {
              "HORIZONTAL DRAIN/TUBE ATT DEV": "HORIZONTAL DRAIN/TUBE ATT DEV",
              "SUCTION TUBE ATTACHMENT DEVICE": "SUCTION TUBE ATTACHMENT DEVICE",
              "UNIVERSAL CATHETER ACCESS PORT": "UNIVERSAL CATHETER ACCESS PORT",
              "VERTICAL DRAIN/TUBE ATT DEVICE": "VERTICAL DRAIN/TUBE ATT DEVICE",
              "WOUND DRAINAGE COLLECTOR": "WOUND DRAINAGE COLLECTOR"
            }
          },
          "Wound Cleansers/Decubitus Ulcer Therapy": {
            "3M CONSUMER HEALTH CARE": {
              "NEXCARE WOUND CLEANSER": "NEXCARE WOUND CLEANSER"
            },
            "AMERICAN SALES COMPANY": {
              "WOUND WASH": "WOUND WASH"
            },
            "AMERIDERM LABORATORIES LTD": {
              "WOUND CLEANSER": "WOUND CLEANSER"
            },
            "AMERX HEALTH CARE CORP.": {
              "AMERIGEL WOUND WASH": "AMERIGEL WOUND WASH"
            },
            "COLOPLAST": {
              "SEA-CLENS WOUND CLEANSER": "SEA-CLENS WOUND CLEANSER"
            },
            "CONVATEC": {
              "SAF-CLENS AF": "SAF-CLENS AF",
              "SHUR-CLENS": "SHUR-CLENS"
            },
            "COVIDIEN MEDICAL SUPPLIES": {
              "CONSTANT-CLENS": "CONSTANT-CLENS"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS WOUND WASH ADVANCED": "CVS WOUND WASH ADVANCED"
            },
            "EXELTIS USA DERMATOLOGY": {
              "ATRAPRO DERMAL SPRAY": "ATRAPRO DERMAL SPRAY"
            },
            "FRIANA": {
              "ACTIMARIS ALL-NATURAL WOUND": "ACTIMARIS ALL-NATURAL WOUND"
            },
            "HUMCO": {
              "LAVARE WOUND WASH": "LAVARE WOUND WASH"
            },
            "INNOVACYN": {
              "PURACYN PLUS DUO-CARE": "PURACYN PLUS DUO-CARE"
            },
            "INTRADERM": {
              "LEVICYN DERMAL SPRAY": "LEVICYN DERMAL SPRAY"
            },
            "INTRADERM PHARMACEUTICALS": {
              "ALEVICYN DERMAL SPRAY": "ALEVICYN DERMAL SPRAY"
            },
            "LTC PRODUCTS": {
              "PELEVERUS": "PELEVERUS"
            },
            "MEDLINE INDUSTRIES": {
              "CARRAKLENZ": "CARRAKLENZ"
            },
            "MEDLINE/DERMAL MANAGEMENT": {
              "DIAB KLENZ": "DIAB KLENZ",
              "MICROKLENZ WOUND CLEANSER": "MICROKLENZ WOUND CLEANSER",
              "RADIAKLENZ": "RADIAKLENZ",
              "REMEDY 4-IN-1 BODY CLEANSER": "REMEDY 4-IN-1 BODY CLEANSER",
              "SKINTEGRITY WOUND": "SKINTEGRITY WOUND",
              "ULTRA-KLENZ": "ULTRA-KLENZ"
            },
            "MPM MEDICAL, INC.": {
              "SILVERMED": "SILVERMED",
              "WOUND/SKIN CLEANSER": "WOUND/SKIN CLEANSER"
            },
            "OCULUS INNOVATIVE SCIENCES": {
              "MICROCYN": "MICROCYN",
              "MICROCYN SKIN AND WOUND": "MICROCYN SKIN AND WOUND"
            },
            "SOLUS RX": {
              "DELUO": "DELUO"
            },
            "STEADMED MEDICAL": {
              "ELTA DERMAL WOUND CLEANSER": "ELTA DERMAL WOUND CLEANSER",
              "RESTA WOUND CLEANSER": "RESTA WOUND CLEANSER",
              "VASHE CLEANSING": "VASHE CLEANSING",
              "VASHE WOUND THERAPY": "VASHE WOUND THERAPY"
            }
          },
          "Wound Dressings": {
            "3M MEDICAL": {
              "TEGADERM AG MESH": "TEGADERM AG MESH",
              "TEGADERM ALGINATE AG DRESSING": "TEGADERM ALGINATE AG DRESSING",
              "TEGADERM ALGINATE AG ROPE": "TEGADERM ALGINATE AG ROPE",
              "TEGADERM HIGH GELLING ALGINATE": "TEGADERM HIGH GELLING ALGINATE",
              "TEGADERM HIGH INTEG ALGINATE": "TEGADERM HIGH INTEG ALGINATE",
              "TEGADERM HYDROCOLLOID": "TEGADERM HYDROCOLLOID",
              "TEGADERM HYDROCOLLOID THIN": "TEGADERM HYDROCOLLOID THIN",
              "TEGADERM HYDROGEL WOUND FILLER": "TEGADERM HYDROGEL WOUND FILLER",
              "TEGADERM MATRIX DRESSING": "TEGADERM MATRIX DRESSING"
            },
            "ABL MEDICAL": {
              "SILVRSTAT WOUND DRESSING": "SILVRSTAT WOUND DRESSING"
            },
            "ALLIQUA BIOMEDICAL": {
              "SILVERSEAL HYDROGEL DRESSING": "SILVERSEAL HYDROGEL DRESSING"
            },
            "ALVIX LABORATORIES": {
              "ARIDA": "ARIDA",
              "AZADROX": "AZADROX",
              "BASADROX": "BASADROX",
              "LURADROX": "LURADROX"
            },
            "AMERIDERM LABORATORIES LTD": {
              "WOUND GEL": "WOUND GEL",
              "WOUND GEL SPRAY": "WOUND GEL SPRAY"
            },
            "AMERX HEALTH CARE CORP.": {
              "AMERIGEL GAUZE DRESSING 2\"\"X2\"\"": "AMERIGEL GAUZE DRESSING 2\"\"X2\"\"",
              "AMERIGEL WOUND DRESSING": "AMERIGEL WOUND DRESSING"
            },
            "AREZA MEDICAL": {
              "ANTIBACTERIAL ALGINATE W/SILV": "ANTIBACTERIAL ALGINATE W/SILV"
            },
            "AVOCET POLYMER TECHNOLOGIES": {
              "AVOGEL DRESSING 4\"\"X4\"\"": "AVOGEL DRESSING 4\"\"X4\"\"",
              "AVOGEL DRESSING 6\"\"X48\"\"": "AVOGEL DRESSING 6\"\"X48\"\"",
              "AVOGEL DRESSING 8\"\"X8\"\"": "AVOGEL DRESSING 8\"\"X8\"\"",
              "AVOGEL SHEET": "AVOGEL SHEET"
            },
            "AVONDALE": {
              "PROTYL AG": "PROTYL AG"
            },
            "BARD": {
              "VIGILON PRIMARY WOUND DRESSING": "VIGILON PRIMARY WOUND DRESSING"
            },
            "BAUSCH HEALTH": {
              "BIAFINE": "BIAFINE"
            },
            "BAYER CONSUMER": {
              "DOME-PASTE BANDAGE": "DOME-PASTE BANDAGE"
            },
            "BLAINE LABORATORIES": {
              "REVITADERM WOUND CARE": "REVITADERM WOUND CARE"
            },
            "BSN MEDICAL": {
              "GELOCAST UNNAS BOOT": "GELOCAST UNNAS BOOT",
              "HYPAFIX": "HYPAFIX"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "BIONECT": "BIONECT"
            },
            "COLOPLAST": {
              "COMFEEL FILM": "COMFEEL FILM",
              "COMFEEL PASTE": "COMFEEL PASTE",
              "COMFEEL PLUS CLEAR DRESSING": "COMFEEL PLUS CLEAR DRESSING",
              "COMFEEL PLUS CONTOUR DRESSING": "COMFEEL PLUS CONTOUR DRESSING",
              "COMFEEL PLUS DRESSING": "COMFEEL PLUS DRESSING",
              "COMFEEL PLUS TRIANGLE DRESSING": "COMFEEL PLUS TRIANGLE DRESSING",
              "COMFEEL PLUS ULCER DRESSING": "COMFEEL PLUS ULCER DRESSING",
              "COMFEEL POWDER": "COMFEEL POWDER",
              "COMFEEL ULCER CARE DRESSING": "COMFEEL ULCER CARE DRESSING",
              "CONTREET FOAM": "CONTREET FOAM",
              "CONTREET FOAM CAVITY": "CONTREET FOAM CAVITY",
              "CONTREET FOAM HEEL": "CONTREET FOAM HEEL",
              "CONTREET FOAM SACRAL": "CONTREET FOAM SACRAL",
              "CONTREET HYDROCOLLOID": "CONTREET HYDROCOLLOID",
              "INTERDRY AG TEXTILE 10\"\"X12\\'": "INTERDRY AG TEXTILE 10\"\"X12\\'",
              "PURILON": "PURILON",
              "SEASORB SOFT": "SEASORB SOFT",
              "SEASORB-AG": "SEASORB-AG",
              "TRIAD HYDROPHILIC WOUND DRESS": "TRIAD HYDROPHILIC WOUND DRESS",
              "WOUN\\'DRES HYDROGEL WOUND DRESS": "WOUN\\'DRES HYDROGEL WOUND DRESS"
            },
            "CONVATEC": {
              "AQUACEL AG BURN": "AQUACEL AG BURN",
              "AQUACEL AG FOAM": "AQUACEL AG FOAM",
              "AQUACEL AG FOAM/HEEL": "AQUACEL AG FOAM/HEEL",
              "AQUACEL AG FOAM/SACRAL": "AQUACEL AG FOAM/SACRAL",
              "AQUACEL EXTRA HYDROFIBER": "AQUACEL EXTRA HYDROFIBER",
              "AQUACEL EXTRA HYDROFIBER 2X2": "AQUACEL EXTRA HYDROFIBER 2X2",
              "AQUACEL EXTRA HYDROFIBER 6X6": "AQUACEL EXTRA HYDROFIBER 6X6",
              "AQUACEL FOAM 3.2\"\"X3.2\"\"": "AQUACEL FOAM 3.2\"\"X3.2\"\"",
              "AQUACEL FOAM 4\"\"X4\"\"": "AQUACEL FOAM 4\"\"X4\"\"",
              "AQUACEL FOAM 5\"\"X5\"\"": "AQUACEL FOAM 5\"\"X5\"\"",
              "AQUACEL FOAM 6\"\"X6\"\"": "AQUACEL FOAM 6\"\"X6\"\"",
              "AQUACEL FOAM 6\"\"X8\"\"": "AQUACEL FOAM 6\"\"X8\"\"",
              "AQUACEL FOAM 7\"\"X7\"\"": "AQUACEL FOAM 7\"\"X7\"\"",
              "AQUACEL FOAM 8\"\"X5.5\"\"": "AQUACEL FOAM 8\"\"X5.5\"\"",
              "AQUACEL FOAM 8\"\"X7\"\"": "AQUACEL FOAM 8\"\"X7\"\"",
              "AQUACEL HYDROFIBER 0.39\"\"X18\"\"": "AQUACEL HYDROFIBER 0.39\"\"X18\"\"",
              "AQUACEL-AG EXTRA HYDROFIBER": "AQUACEL-AG EXTRA HYDROFIBER",
              "AQUACEL-AG FOAM": "AQUACEL-AG FOAM",
              "AQUACEL-AG HYDROFIBER": "AQUACEL-AG HYDROFIBER",
              "AQUACEL-AG SURGICAL HYDROFIBER": "AQUACEL-AG SURGICAL HYDROFIBER",
              "CARBOFLEX ODOR CONTROL": "CARBOFLEX ODOR CONTROL",
              "DUODERM CGF BORDER": "DUODERM CGF BORDER",
              "DUODERM CGF DRESSING": "DUODERM CGF DRESSING",
              "DUODERM CGF EXTRA THIN": "DUODERM CGF EXTRA THIN",
              "DUODERM CGF SPOTS EXTRA THIN": "DUODERM CGF SPOTS EXTRA THIN",
              "DUODERM HYDROACTIVE": "DUODERM HYDROACTIVE",
              "DUODERM SIGNAL DRESSING": "DUODERM SIGNAL DRESSING",
              "KALTOSTAT 12\"\"X24\"\"": "KALTOSTAT 12\"\"X24\"\"",
              "KALTOSTAT 2\"\"X2\"\"": "KALTOSTAT 2\"\"X2\"\"",
              "KALTOSTAT 4\"\"X8\"\"": "KALTOSTAT 4\"\"X8\"\"",
              "KALTOSTAT 6\"\"X9-1/2\"\"": "KALTOSTAT 6\"\"X9-1/2\"\"",
              "KALTOSTAT FORTEX 4\"\"X4\"\"": "KALTOSTAT FORTEX 4\"\"X4\"\"",
              "KALTOSTAT ROPE": "KALTOSTAT ROPE",
              "KALTOSTAT WOUND DRESSING": "KALTOSTAT WOUND DRESSING",
              "SAF-GEL": "SAF-GEL",
              "UNNA-FLEX ELASTIC UNNA BOOT": "UNNA-FLEX ELASTIC UNNA BOOT",
              "UNNA-FLEX PLUS VENOUS ULCER": "UNNA-FLEX PLUS VENOUS ULCER"
            },
            "COVIDIEN MEDICAL SUPPLIES": {
              "CURAFIL WOUND DRESSING": "CURAFIL WOUND DRESSING",
              "CURITY HEAVY DRAINAGE PACK": "CURITY HEAVY DRAINAGE PACK",
              "CURITY HYPERTONIC NACL STRIP": "CURITY HYPERTONIC NACL STRIP",
              "CURITY NACL DRESSING 6\"\"X6-3/4\"\"": "CURITY NACL DRESSING 6\"\"X6-3/4\"\"",
              "CURITY SALINE DRESSING 8\"\"X4\"\"": "CURITY SALINE DRESSING 8\"\"X4\"\"",
              "CURITY UNNA BOOT": "CURITY UNNA BOOT",
              "KENDALL ALGINATE 12\"\" ROPE": "KENDALL ALGINATE 12\"\" ROPE",
              "KENDALL ALGINATE DRESS 2\"\"X2\"\"": "KENDALL ALGINATE DRESS 2\"\"X2\"\"",
              "KENDALL ALGINATE DRESS 4\"\"X4\"\"": "KENDALL ALGINATE DRESS 4\"\"X4\"\"",
              "KENDALL ALGINATE DRESS 4\"\"X5\"\"": "KENDALL ALGINATE DRESS 4\"\"X5\"\"",
              "KENDALL ALGINATE DRESS 4\"\"X8\"\"": "KENDALL ALGINATE DRESS 4\"\"X8\"\"",
              "KENDALL ALGINATE DRESS 6\"\"X6\"\"": "KENDALL ALGINATE DRESS 6\"\"X6\"\"",
              "KENDALL ALGINATE DRESS 6\"\"X7\"\"": "KENDALL ALGINATE DRESS 6\"\"X7\"\"",
              "KENDALL ALGINATE DRESS 8\"\"X8\"\"": "KENDALL ALGINATE DRESS 8\"\"X8\"\"",
              "KENDALL AMORPHOUS WOUND": "KENDALL AMORPHOUS WOUND",
              "KENDALL ANTIMICROBIAL BANDAGE": "KENDALL ANTIMICROBIAL BANDAGE",
              "KENDALL CA ALGINATE 12\"\" ROPE": "KENDALL CA ALGINATE 12\"\" ROPE",
              "KENDALL CA ALGINATE 12\"\"X24\"\"": "KENDALL CA ALGINATE 12\"\"X24\"\"",
              "KENDALL CA ALGINATE 2\"\"X2\"\"": "KENDALL CA ALGINATE 2\"\"X2\"\"",
              "KENDALL CA ALGINATE 24\"\" ROPE": "KENDALL CA ALGINATE 24\"\" ROPE",
              "KENDALL CA ALGINATE 36\"\" ROPE": "KENDALL CA ALGINATE 36\"\" ROPE",
              "KENDALL CA ALGINATE 4\"\"X4\"\"": "KENDALL CA ALGINATE 4\"\"X4\"\"",
              "KENDALL CA ALGINATE 4\"\"X5-1/2\"\"": "KENDALL CA ALGINATE 4\"\"X5-1/2\"\"",
              "KENDALL CA ALGINATE 6\"\"X10\"\"": "KENDALL CA ALGINATE 6\"\"X10\"\"",
              "KENDALL CA ALGINATE 8\"\"X4\"\"": "KENDALL CA ALGINATE 8\"\"X4\"\"",
              "KENDALL CA ALGINATE PLUS 4\"\"X4\"\"": "KENDALL CA ALGINATE PLUS 4\"\"X4\"\"",
              "KENDALL HYDROGEL GAUZE 2\"\"X2\"\"": "KENDALL HYDROGEL GAUZE 2\"\"X2\"\"",
              "KENDALL HYDROGEL GAUZE 4\"\"X4\"\"": "KENDALL HYDROGEL GAUZE 4\"\"X4\"\"",
              "KENDALL HYDROGEL GAUZE 4\"\"X8\"\"": "KENDALL HYDROGEL GAUZE 4\"\"X8\"\"",
              "KENDALL HYDROGEL WOUND DRESS": "KENDALL HYDROGEL WOUND DRESS",
              "KERLIX SUPER SPONGE SALINE": "KERLIX SUPER SPONGE SALINE",
              "TENDERWRAP UNNA BOOT": "TENDERWRAP UNNA BOOT",
              "TENDERWRAP UNNA BOOT BANDAGE": "TENDERWRAP UNNA BOOT BANDAGE",
              "ULTEC HYDROCOLLOID DRESSING": "ULTEC HYDROCOLLOID DRESSING",
              "VASELINE PETROLATUM GAUZE": "VASELINE PETROLATUM GAUZE",
              "VASELINE PETROLATUM TUBE FOIL": "VASELINE PETROLATUM TUBE FOIL",
              "XEROFLO DRESSING 1\"\"X8\"\"": "XEROFLO DRESSING 1\"\"X8\"\"",
              "XEROFLO DRESSING 2\"\"X2\"\"": "XEROFLO DRESSING 2\"\"X2\"\"",
              "XEROFLO DRESSING 5\"\"X9\"\"": "XEROFLO DRESSING 5\"\"X9\"\""
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS HYDROCOLLOID PADS": "CVS HYDROCOLLOID PADS",
              "CVS MANUKA HONEY WOUND": "CVS MANUKA HONEY WOUND",
              "CVS SILVER": "CVS SILVER"
            },
            "DERMARITE": {
              "DERMAGAUZE DRESSING": "DERMAGAUZE DRESSING",
              "DERMAGAUZE HYDROGEL DRESSING": "DERMAGAUZE HYDROGEL DRESSING",
              "DERMAGINATE DRESSING 12\"\"": "DERMAGINATE DRESSING 12\"\"",
              "DERMAGINATE DRESSING 4\"\"X4\"\"": "DERMAGINATE DRESSING 4\"\"X4\"\"",
              "DERMASYN": "DERMASYN"
            },
            "DYNAREX CORPORATION": {
              "DYNADERM": "DYNADERM"
            },
            "EMERALD THERAPEUTICS": {
              "COLLATYL": "COLLATYL"
            },
            "EPI HEALTH": {
              "BIONECT": "BIONECT"
            },
            "EXELTIS USA DERMATOLOGY": {
              "ATRAPRO CP": "ATRAPRO CP",
              "ATRAPRO HYDROGEL": "ATRAPRO HYDROGEL"
            },
            "FERRIS CORP": {
              "POLYMEM ALGINATE DRESSING": "POLYMEM ALGINATE DRESSING",
              "POLYMEM SILVER DRESSING": "POLYMEM SILVER DRESSING",
              "POLYMEM SILVER ISLAND DRESSING": "POLYMEM SILVER ISLAND DRESSING",
              "POLYWIC SILVER WOUND FILLER": "POLYWIC SILVER WOUND FILLER"
            },
            "FRIANA": {
              "ACTIMARIS WOUND": "ACTIMARIS WOUND"
            },
            "FUTURE DIAGNOSTICS": {
              "CARETOUCH 4\"\"X4\"\" DRESSING": "CARETOUCH 4\"\"X4\"\" DRESSING"
            },
            "GENTELL": {
              "HYDROGEL": "HYDROGEL",
              "HYDROGEL AG": "HYDROGEL AG"
            },
            "GENTEX PHARMA LLC": {
              "HYGEL": "HYGEL",
              "THERAPEVO": "THERAPEVO"
            },
            "GERITREX CORPORATION": {
              "AGIE-GRX": "AGIE-GRX",
              "CALALGIN-GRX": "CALALGIN-GRX",
              "CALALGIN-GRX ROPE": "CALALGIN-GRX ROPE",
              "GRX HYDROGEL GAUZE 2X2": "GRX HYDROGEL GAUZE 2X2",
              "GRX HYDROGEL GAUZE 4X4": "GRX HYDROGEL GAUZE 4X4",
              "GRX WOUND": "GRX WOUND",
              "HYCOLOID-GRX": "HYCOLOID-GRX"
            },
            "GWM PRODUCTS": {
              "RTD WOUND CARE DRESSING": "RTD WOUND CARE DRESSING"
            },
            "HOLLISTER": {
              "RESTORE CX WOUND CARE DRESSING": "RESTORE CX WOUND CARE DRESSING",
              "RESTORE DRESSING FOR PSORIASIS": "RESTORE DRESSING FOR PSORIASIS"
            },
            "HOLLISTER WOUND CARE": {
              "ENDOFORM DERMAL TEMPLATE": "ENDOFORM DERMAL TEMPLATE",
              "ENDOFORM DERMAL/FENESTRATED": "ENDOFORM DERMAL/FENESTRATED",
              "HYDROFERA BLUE 4\"\"X4\"\"": "HYDROFERA BLUE 4\"\"X4\"\"",
              "HYDROFERA BLUE 6\"\"X6\"\"": "HYDROFERA BLUE 6\"\"X6\"\"",
              "HYDROFERA BLUE FOAM DRESSING": "HYDROFERA BLUE FOAM DRESSING",
              "HYDROFERA BLUE FOAM/TUNNELING": "HYDROFERA BLUE FOAM/TUNNELING",
              "HYDROFERA BLUE MRF DRESSING": "HYDROFERA BLUE MRF DRESSING",
              "HYDROFERA BLUE READY FOAM": "HYDROFERA BLUE READY FOAM",
              "RESTORE CALCICARE DRESSING": "RESTORE CALCICARE DRESSING",
              "RESTORE CALCIUM ALGINATE": "RESTORE CALCIUM ALGINATE",
              "RESTORE EXTRA THIN DRESSING": "RESTORE EXTRA THIN DRESSING",
              "RESTORE HYDROGEL DRESSING": "RESTORE HYDROGEL DRESSING",
              "RESTORE HYDROGEL GAUZE": "RESTORE HYDROGEL GAUZE",
              "RESTORE PLUS WOUND CARE DRESS": "RESTORE PLUS WOUND CARE DRESS",
              "RESTORE SILVER DRESSING": "RESTORE SILVER DRESSING",
              "RESTORE WOUND CARE DRESSING": "RESTORE WOUND CARE DRESSING"
            },
            "INTEGRA LIFESCIENCES": {
              "ALGICELL AG": "ALGICELL AG",
              "ALGICELL CALCIUM DRESS 3/4\"\"X12": "ALGICELL CALCIUM DRESS 3/4\"\"X12",
              "ALGICELL CALCIUM DRESSING 2\"\"X2": "ALGICELL CALCIUM DRESSING 2\"\"X2",
              "ALGICELL CALCIUM DRESSING 4\"\"X4": "ALGICELL CALCIUM DRESSING 4\"\"X4",
              "ALGICELL CALCIUM DRESSING 4\"\"X8": "ALGICELL CALCIUM DRESSING 4\"\"X8",
              "AQUASITE IMPREG DRESSING 2\"\"X2\"\"": "AQUASITE IMPREG DRESSING 2\"\"X2\"\"",
              "AQUASITE IMPREG DRESSING 4\"\"X4\"\"": "AQUASITE IMPREG DRESSING 4\"\"X4\"\"",
              "AQUASITE IMPREG DRESSING 4\"\"X8\"\"": "AQUASITE IMPREG DRESSING 4\"\"X8\"\"",
              "AQUASITE IMPREG GAUZE 2\"\"X2\"\"": "AQUASITE IMPREG GAUZE 2\"\"X2\"\"",
              "AQUASITE IMPREG GAUZE 4\"\"X4\"\"": "AQUASITE IMPREG GAUZE 4\"\"X4\"\"",
              "AQUASITE SHEET DRESSING 4\"\"X4\"\"": "AQUASITE SHEET DRESSING 4\"\"X4\"\"",
              "DERMA PAK-ITS IODOFORM": "DERMA PAK-ITS IODOFORM",
              "DERMAGRAN HYDROGEL WOUND": "DERMAGRAN HYDROGEL WOUND",
              "DERMAGRAN HYDROPHILIC DRESSING": "DERMAGRAN HYDROPHILIC DRESSING",
              "DERMAGRAN-B HYDROPHILIC": "DERMAGRAN-B HYDROPHILIC",
              "DUDRESS ISLAND DRESSING 4\"\"X4\"\"": "DUDRESS ISLAND DRESSING 4\"\"X4\"\"",
              "DUDRESS ISLAND DRESSING 6\"\"X6\"\"": "DUDRESS ISLAND DRESSING 6\"\"X6\"\"",
              "MEDIHONEY CA ALGINATE 2\"\"X2\"\"": "MEDIHONEY CA ALGINATE 2\"\"X2\"\"",
              "MEDIHONEY CA ALGINATE 4\"\"X5\"\"": "MEDIHONEY CA ALGINATE 4\"\"X5\"\"",
              "MEDIHONEY WOUND/BURN DRESSING": "MEDIHONEY WOUND/BURN DRESSING",
              "PRIMACOL BORDERED DRESSING 2X2": "PRIMACOL BORDERED DRESSING 2X2",
              "PRIMACOL BORDERED DRESSING 4X4": "PRIMACOL BORDERED DRESSING 4X4",
              "PRIMACOL BORDERED DRESSING 6X6": "PRIMACOL BORDERED DRESSING 6X6",
              "PRIMACOL DRESSING 4\"\"X4\"\"": "PRIMACOL DRESSING 4\"\"X4\"\"",
              "PRIMACOL DRESSING 6\"\"X6\"\"": "PRIMACOL DRESSING 6\"\"X6\"\"",
              "PRIMACOL DRESSING 8\"\"X8\"\"": "PRIMACOL DRESSING 8\"\"X8\"\"",
              "PRIMACOL SPECIALTY DRESSING": "PRIMACOL SPECIALTY DRESSING",
              "PRIMACOL THIN DRESSING 4\"\"X4\"\"": "PRIMACOL THIN DRESSING 4\"\"X4\"\"",
              "PRIMACOL THIN DRESSING 6\"\"X6\"\"": "PRIMACOL THIN DRESSING 6\"\"X6\"\"",
              "PRIMER MODIFIED UNNA BOOT": "PRIMER MODIFIED UNNA BOOT",
              "SORBACELL FOAM DRESSING 4\"\"X4\"\"": "SORBACELL FOAM DRESSING 4\"\"X4\"\"",
              "SORBACELL FOAM DRESSING STRIP": "SORBACELL FOAM DRESSING STRIP"
            },
            "INTRADERM PHARMACEUTICALS": {
              "CELACYN POST-PROCEDURE PACK": "CELACYN POST-PROCEDURE PACK"
            },
            "J & J MEDICAL": {
              "ADAPTIC NON-ADHERING DRESSING": "ADAPTIC NON-ADHERING DRESSING",
              "FIBRACOL": "FIBRACOL",
              "NU-GEL": "NU-GEL"
            },
            "JOURNEY MEDICAL CORPORATION": {
              "LUXAMEND": "LUXAMEND"
            },
            "KERAPLAST TECHNOLOGIES": {
              "KERAGEL": "KERAGEL",
              "KERAGELT": "KERAGELT",
              "KERAMATRIX REPLICINE 10CMX10CM": "KERAMATRIX REPLICINE 10CMX10CM",
              "KERAMATRIX REPLICINE 5CMX5CM": "KERAMATRIX REPLICINE 5CMX5CM"
            },
            "MCKESSON SUNMARK": {
              "MEDI-PAK PERFORMANCE PLUS ABD": "MEDI-PAK PERFORMANCE PLUS ABD"
            },
            "MEDLINE INDUS": {
              "CURAD GERM SHIELD": "CURAD GERM SHIELD",
              "HYALOMATRIX": "HYALOMATRIX",
              "MAXORB EXTRA AG+": "MAXORB EXTRA AG+",
              "RADIAGEL": "RADIAGEL",
              "THERAHONEY": "THERAHONEY"
            },
            "MEDLINE/DERMAL MANAGEMENT": {
              "ARGLAES": "ARGLAES",
              "ARGLAES FILM": "ARGLAES FILM",
              "ARGLAES ISLAND": "ARGLAES ISLAND",
              "ARGLAES ISLAND 4\"\"X4-3/4\"\"": "ARGLAES ISLAND 4\"\"X4-3/4\"\"",
              "CARRACOLLOID 4\"\"X4\"\"": "CARRACOLLOID 4\"\"X4\"\"",
              "CARRACOLLOID 6\"\"X6\"\"": "CARRACOLLOID 6\"\"X6\"\"",
              "CARRADRES": "CARRADRES",
              "CARRAGAUZE": "CARRAGAUZE",
              "CARRAGINATE": "CARRAGINATE",
              "CARRASMART": "CARRASMART",
              "CARRASORB H": "CARRASORB H",
              "CARRASORB M DRIGEL": "CARRASORB M DRIGEL",
              "CARRASYN HYDROGEL WOUND DRESS": "CARRASYN HYDROGEL WOUND DRESS",
              "CARRASYN V WOUND DRESSING": "CARRASYN V WOUND DRESSING",
              "DERMA-GEL 4\"\"X4\"\"": "DERMA-GEL 4\"\"X4\"\"",
              "DIAB": "DIAB",
              "DIAB DAILY CARE": "DIAB DAILY CARE",
              "DIAB F.D.G. FREEZE-DRIED": "DIAB F.D.G. FREEZE-DRIED",
              "EXUDERM LP 4\"\"X4\"\"": "EXUDERM LP 4\"\"X4\"\"",
              "EXUDERM LP 6\"\"X6\"\"": "EXUDERM LP 6\"\"X6\"\"",
              "EXUDERM RCD 4\"\"X4\"\"": "EXUDERM RCD 4\"\"X4\"\"",
              "EXUDERM RCD 6\"\"X6\"\"": "EXUDERM RCD 6\"\"X6\"\"",
              "EXUDERM RCD 8\"\"X8\"\"": "EXUDERM RCD 8\"\"X8\"\"",
              "EXUDERM SACRUM 4\"\"X3.6\"\"": "EXUDERM SACRUM 4\"\"X3.6\"\"",
              "EXUDERM SATIN 2\"\"X2\"\"": "EXUDERM SATIN 2\"\"X2\"\"",
              "EXUDERM SATIN 4\"\"X4\"\"": "EXUDERM SATIN 4\"\"X4\"\"",
              "EXUDERM SATIN 6\"\"X6\"\"": "EXUDERM SATIN 6\"\"X6\"\"",
              "EXUDERM SATIN 8\"\"X8\"\"": "EXUDERM SATIN 8\"\"X8\"\"",
              "EXUDERM ULTRA 4\"\"X4\"\"": "EXUDERM ULTRA 4\"\"X4\"\"",
              "MAXORB EXTRA 2\"\"X2\"\"": "MAXORB EXTRA 2\"\"X2\"\"",
              "MAXORB EXTRA 4\"\"X4\"\"": "MAXORB EXTRA 4\"\"X4\"\"",
              "MAXORB EXTRA 4\"\"X8\"\"": "MAXORB EXTRA 4\"\"X8\"\"",
              "MAXORB EXTRA ROPE": "MAXORB EXTRA ROPE",
              "MEDFIX EZ": "MEDFIX EZ",
              "PRIMARY DRESSING TRAY": "PRIMARY DRESSING TRAY",
              "RADIACARE POST HEALING": "RADIACARE POST HEALING",
              "RADIADRES GEL SHEET": "RADIADRES GEL SHEET",
              "RADIAGEL": "RADIAGEL",
              "SILVASORB": "SILVASORB",
              "SKINTEGRITY 2\"\"X2\"\"": "SKINTEGRITY 2\"\"X2\"\"",
              "SKINTEGRITY 4\"\"X4\"\"": "SKINTEGRITY 4\"\"X4\"\"",
              "SKINTEGRITY HYDROGEL": "SKINTEGRITY HYDROGEL",
              "STRATASORB ISLAND DRESSING": "STRATASORB ISLAND DRESSING",
              "TENDERWET ACTIVE 1.6\"\"": "TENDERWET ACTIVE 1.6\"\"",
              "TENDERWET ACTIVE 2.2\"\"": "TENDERWET ACTIVE 2.2\"\"",
              "TENDERWET ACTIVE 3\"\"X3\"\"": "TENDERWET ACTIVE 3\"\"X3\"\"",
              "TENDERWET ACTIVE 4\"\"X5\"\"": "TENDERWET ACTIVE 4\"\"X5\"\"",
              "TENDERWET ACTIVE CAVITY 1.6\"\"": "TENDERWET ACTIVE CAVITY 1.6\"\"",
              "TENDERWET ACTIVE CAVITY 2.2\"\"": "TENDERWET ACTIVE CAVITY 2.2\"\"",
              "TENDERWET ACTIVE CAVITY 3\"\"X3\"\"": "TENDERWET ACTIVE CAVITY 3\"\"X3\"\"",
              "TENDERWET ACTIVE CAVITY 3\"\"X8\"\"": "TENDERWET ACTIVE CAVITY 3\"\"X8\"\"",
              "TENDERWET ACTIVE CAVITY 4\"\"X5\"\"": "TENDERWET ACTIVE CAVITY 4\"\"X5\"\"",
              "THERAHONEY": "THERAHONEY"
            },
            "MIROMATRIX MEDICAL": {
              "MIRODERM BIOLOGIC MATRIX FENES": "MIRODERM BIOLOGIC MATRIX FENES",
              "MIRODERM BIOLOGIC WOUND MATRIX": "MIRODERM BIOLOGIC WOUND MATRIX"
            },
            "MOLECULAR  BIOLOGICS": {
              "KERAGEL": "KERAGEL",
              "KERAGELT": "KERAGELT",
              "KERAMATRIX REPLICINE 10CMX10CM": "KERAMATRIX REPLICINE 10CMX10CM",
              "KERAMATRIX REPLICINE 5CMX5CM": "KERAMATRIX REPLICINE 5CMX5CM"
            },
            "MOLNLYCKE HEALTHCARE": {
              "NORMLGEL AG": "NORMLGEL AG"
            },
            "MPM MEDICAL, INC.": {
              "COOLMAGIC": "COOLMAGIC",
              "COOLMAGIC TUBE SITE DRESSING": "COOLMAGIC TUBE SITE DRESSING",
              "DERMAPLEX": "DERMAPLEX",
              "EXCEL-GEL": "EXCEL-GEL",
              "FOAM DRESSING BORDERED": "FOAM DRESSING BORDERED",
              "FOAM DRESSING CIRCULAR BORDER": "FOAM DRESSING CIRCULAR BORDER",
              "FOAM DRESSING NON-BORDERED": "FOAM DRESSING NON-BORDERED",
              "RADIAPLEXRX": "RADIAPLEXRX",
              "SILVERMED": "SILVERMED",
              "TRIPLE HELIX COLLAGEN": "TRIPLE HELIX COLLAGEN",
              "TRIPLE HELIX COLLAGEN 12\"\" ROPE": "TRIPLE HELIX COLLAGEN 12\"\" ROPE",
              "TRIPLE HELIX COLLAGEN 2\"\"X2\"\"": "TRIPLE HELIX COLLAGEN 2\"\"X2\"\"",
              "WOUNDGARD 2-1/2\"\"X2-1/2\"\"": "WOUNDGARD 2-1/2\"\"X2-1/2\"\"",
              "WOUNDGARD 4\"\"X4-1/4\"\"": "WOUNDGARD 4\"\"X4-1/4\"\"",
              "WOUNDGARD 4\"\"X6\"\"": "WOUNDGARD 4\"\"X6\"\""
            },
            "MYLAN INSTITUTIONAL": {
              "HYDROCOL": "HYDROCOL",
              "HYDROCOL II": "HYDROCOL II",
              "HYDROCOL II SACRAL": "HYDROCOL II SACRAL",
              "HYDROCOL II THIN": "HYDROCOL II THIN",
              "SORBSAN WOUND DRESSING": "SORBSAN WOUND DRESSING"
            },
            "NATIONAL BIO GREEN SCIENCES": {
              "ACESO AG": "ACESO AG"
            },
            "OAKLOCK": {
              "VACUSTIM BLACK": "VACUSTIM BLACK",
              "VACUSTIM SILVER": "VACUSTIM SILVER",
              "WOUND DEBRIDEMENT": "WOUND DEBRIDEMENT"
            },
            "OCULUS INNOVATIVE SCIENCES": {
              "ZANABIN HYDROGEL": "ZANABIN HYDROGEL"
            },
            "ONPACE PHARMA": {
              "ATOPAVO": "ATOPAVO"
            },
            "ORGANOGENESIS": {
              "FORTADERM": "FORTADERM",
              "FORTADERM ANTIMICROBIAL PHMB": "FORTADERM ANTIMICROBIAL PHMB",
              "PURAPLY": "PURAPLY",
              "PURAPLY 1.6CM": "PURAPLY 1.6CM",
              "PURAPLY ANTIMICROBIAL 2X2CM": "PURAPLY ANTIMICROBIAL 2X2CM",
              "PURAPLY ANTIMICROBIAL 2X4CM": "PURAPLY ANTIMICROBIAL 2X4CM",
              "PURAPLY ANTIMICROBIAL 3X4CM": "PURAPLY ANTIMICROBIAL 3X4CM",
              "PURAPLY ANTIMICROBIAL 4X4CM": "PURAPLY ANTIMICROBIAL 4X4CM",
              "PURAPLY ANTIMICROBIAL 5X5CM": "PURAPLY ANTIMICROBIAL 5X5CM",
              "PURAPLY ANTIMICROBIAL 6X9CM": "PURAPLY ANTIMICROBIAL 6X9CM",
              "PURAPLY ANTIMICROBIAL 8X16CM": "PURAPLY ANTIMICROBIAL 8X16CM",
              "PURAPLY XT ANTIMICROBIAL 5X5CM": "PURAPLY XT ANTIMICROBIAL 5X5CM",
              "PURAPLY XT ANTIMICROBIAL 6X9CM": "PURAPLY XT ANTIMICROBIAL 6X9CM"
            },
            "PRIMUS PHARMACEUTICALS": {
              "VASCUDERM HYDROGEL": "VASCUDERM HYDROGEL",
              "VASCUDERM STASIS": "VASCUDERM STASIS"
            },
            "PRUGEN": {
              "PRUTECT": "PRUTECT"
            },
            "RELIAMED": {
              "SILVER HYDROGEL": "SILVER HYDROGEL"
            },
            "SCIVOLUTIONS MEDICAL": {
              "DRS CHOICE BLISTER CARE": "DRS CHOICE BLISTER CARE",
              "DRS CHOICE BURNS/SCALDS/ABRASN": "DRS CHOICE BURNS/SCALDS/ABRASN",
              "DRS CHOICE DIABETIC BANDAGES": "DRS CHOICE DIABETIC BANDAGES",
              "DRS CHOICE SKIN CLOSURE": "DRS CHOICE SKIN CLOSURE",
              "DRS CHOICE SLOW HEAL BANDAGES": "DRS CHOICE SLOW HEAL BANDAGES",
              "DRS CHOICE ULTRA-FLEX": "DRS CHOICE ULTRA-FLEX"
            },
            "SMITH & NEPHEW BIOTHERAPEUTICS": {
              "OASIS ULTRA MATRIX FENESTRATED": "OASIS ULTRA MATRIX FENESTRATED",
              "OASIS ULTRA TRI-LAYER MATRIX": "OASIS ULTRA TRI-LAYER MATRIX",
              "OASIS WOUND MATRIX FENESTRATED": "OASIS WOUND MATRIX FENESTRATED"
            },
            "SMITH & NEPHEW UNITED": {
              "ACTICOAT": "ACTICOAT",
              "ACTICOAT 7": "ACTICOAT 7",
              "ACTICOAT ABSORBENT": "ACTICOAT ABSORBENT",
              "ACTICOAT ANTIMICROBIAL": "ACTICOAT ANTIMICROBIAL",
              "ACTICOAT FLEX 3": "ACTICOAT FLEX 3",
              "ACTICOAT FLEX 3 4\"\"X4\"\"": "ACTICOAT FLEX 3 4\"\"X4\"\"",
              "ACTICOAT FLEX 7": "ACTICOAT FLEX 7",
              "ACTICOAT MOISTURE CONTROL": "ACTICOAT MOISTURE CONTROL",
              "ACTICOAT SITE": "ACTICOAT SITE",
              "ACTICOAT SURGICAL": "ACTICOAT SURGICAL",
              "ALGISITE M 2\"\"X2\"\"": "ALGISITE M 2\"\"X2\"\"",
              "ALGISITE M 3/4\"\"X12\"\"": "ALGISITE M 3/4\"\"X12\"\"",
              "ALGISITE M 4\"\"X4\"\"": "ALGISITE M 4\"\"X4\"\"",
              "ALGISITE M 6\"\"X8\"\"": "ALGISITE M 6\"\"X8\"\"",
              "ALLEVYN ADHESIVE": "ALLEVYN ADHESIVE",
              "ALLEVYN AG ADHESIVE": "ALLEVYN AG ADHESIVE",
              "ALLEVYN AG GENTLE": "ALLEVYN AG GENTLE",
              "ALLEVYN AG GENTLE BORDER": "ALLEVYN AG GENTLE BORDER",
              "ALLEVYN AG NON-ADHESIVE": "ALLEVYN AG NON-ADHESIVE",
              "ALLEVYN AG SACRUM 6-3/4\"\"": "ALLEVYN AG SACRUM 6-3/4\"\"",
              "ALLEVYN AG SACRUM 9\"\"X9\"\"": "ALLEVYN AG SACRUM 9\"\"X9\"\"",
              "ALLEVYN COMPRESSION": "ALLEVYN COMPRESSION",
              "ALLEVYN GENTLE": "ALLEVYN GENTLE",
              "ALLEVYN GENTLE BORDER": "ALLEVYN GENTLE BORDER",
              "ALLEVYN GENTLE BORDER HEEL": "ALLEVYN GENTLE BORDER HEEL",
              "ALLEVYN GENTLE BORDER LITE": "ALLEVYN GENTLE BORDER LITE",
              "ALLEVYN GENTLE BORDER SACRUM": "ALLEVYN GENTLE BORDER SACRUM",
              "ALLEVYN GENTLE BORDR MULTISITE": "ALLEVYN GENTLE BORDR MULTISITE",
              "ALLEVYN HEEL": "ALLEVYN HEEL",
              "ALLEVYN LIFE": "ALLEVYN LIFE",
              "ALLEVYN LIFE HEEL": "ALLEVYN LIFE HEEL",
              "ALLEVYN LIFE SACRUM": "ALLEVYN LIFE SACRUM",
              "ALLEVYN NON-ADHESIVE": "ALLEVYN NON-ADHESIVE",
              "ALLEVYN PLUS ADHESIVE": "ALLEVYN PLUS ADHESIVE",
              "ALLEVYN PLUS CAVITY": "ALLEVYN PLUS CAVITY",
              "ALLEVYN PLUS SACRUM": "ALLEVYN PLUS SACRUM",
              "ALLEVYN SACRUM": "ALLEVYN SACRUM",
              "ALLEVYN TRACHEOSTOMY": "ALLEVYN TRACHEOSTOMY",
              "BIOSTEP": "BIOSTEP",
              "BIOSTEP AG": "BIOSTEP AG",
              "CICA-CARE": "CICA-CARE",
              "CONFORMANT 2 WOUND VEIL": "CONFORMANT 2 WOUND VEIL",
              "CUTICERIN 3\"\"X16\"\"": "CUTICERIN 3\"\"X16\"\"",
              "CUTICERIN 3\"\"X3\"\"": "CUTICERIN 3\"\"X3\"\"",
              "CUTICERIN 3\"\"X8\"\"": "CUTICERIN 3\"\"X8\"\"",
              "CUTICERIN 4\"\"X4\"\"": "CUTICERIN 4\"\"X4\"\"",
              "CUTICERIN 8\"\"X16\"\"": "CUTICERIN 8\"\"X16\"\"",
              "CUTINOVA HYDRO 2\"\"X2-3/8\"\"": "CUTINOVA HYDRO 2\"\"X2-3/8\"\"",
              "CUTINOVA HYDRO 4\"\"X4\"\"": "CUTINOVA HYDRO 4\"\"X4\"\"",
              "CUTINOVA HYDRO 6\"\"X8\"\"": "CUTINOVA HYDRO 6\"\"X8\"\"",
              "DURAFIBER": "DURAFIBER",
              "DURAFIBER 2\"\"X2\"\"": "DURAFIBER 2\"\"X2\"\"",
              "DURAFIBER 3/4\"\"X18\"\"": "DURAFIBER 3/4\"\"X18\"\"",
              "DURAFIBER 4\"\"X4\"\"": "DURAFIBER 4\"\"X4\"\"",
              "DURAFIBER 6\"\"X6\"\"": "DURAFIBER 6\"\"X6\"\"",
              "DURAFIBER AG": "DURAFIBER AG",
              "EXU-DRY 15\"\"X18\"\"": "EXU-DRY 15\"\"X18\"\"",
              "EXU-DRY 15\"\"X24\"\"": "EXU-DRY 15\"\"X24\"\"",
              "EXU-DRY 20\"\"X28\"\"": "EXU-DRY 20\"\"X28\"\"",
              "EXU-DRY 3\"\"X4\"\"": "EXU-DRY 3\"\"X4\"\"",
              "EXU-DRY 4\"\"X6\"\"": "EXU-DRY 4\"\"X6\"\"",
              "EXU-DRY 6\"\"X9\"\"": "EXU-DRY 6\"\"X9\"\"",
              "EXU-DRY 9\"\"X15\"\"": "EXU-DRY 9\"\"X15\"\"",
              "EXU-DRY ARM 27\"\"X31\"\"": "EXU-DRY ARM 27\"\"X31\"\"",
              "EXU-DRY BOOT/FOOT CHILD": "EXU-DRY BOOT/FOOT CHILD",
              "EXU-DRY BURN JACKET 17\"\"X20\"\"": "EXU-DRY BURN JACKET 17\"\"X20\"\"",
              "EXU-DRY BURN JACKET 31\"\"X32\"\"": "EXU-DRY BURN JACKET 31\"\"X32\"\"",
              "EXU-DRY BURN JACKET 36\"\"X40\"\"": "EXU-DRY BURN JACKET 36\"\"X40\"\"",
              "EXU-DRY BURN VEST 26\"\"X38\"\"": "EXU-DRY BURN VEST 26\"\"X38\"\"",
              "EXU-DRY BURN VEST CHILD 15X20\"\"": "EXU-DRY BURN VEST CHILD 15X20\"\"",
              "EXU-DRY BUTTOCKS 23\"\"X53\"\"": "EXU-DRY BUTTOCKS 23\"\"X53\"\"",
              "EXU-DRY DISC 3\"\"": "EXU-DRY DISC 3\"\"",
              "EXU-DRY ELBOW/KNEE/HEEL 10X17": "EXU-DRY ELBOW/KNEE/HEEL 10X17",
              "EXU-DRY FACE 9\"\"X14\"\"": "EXU-DRY FACE 9\"\"X14\"\"",
              "EXU-DRY INCISION 3\"\"X9\"\"": "EXU-DRY INCISION 3\"\"X9\"\"",
              "EXU-DRY LEG 34\"\"X37\"\"": "EXU-DRY LEG 34\"\"X37\"\"",
              "EXU-DRY NON-PERMEABLE 24\"\"X36\"\"": "EXU-DRY NON-PERMEABLE 24\"\"X36\"\"",
              "EXU-DRY NON-PERMEABLE 36\"\"X72\"\"": "EXU-DRY NON-PERMEABLE 36\"\"X72\"\"",
              "EXU-DRY PAD HAND CHILD 8\"\"X8\"\"": "EXU-DRY PAD HAND CHILD 8\"\"X8\"\"",
              "EXU-DRY PADDED HAND 12\"\"X13\"\"": "EXU-DRY PADDED HAND 12\"\"X13\"\"",
              "EXU-DRY PADDED NECK 6\"\"X25\"\"": "EXU-DRY PADDED NECK 6\"\"X25\"\"",
              "EXU-DRY PERMEABLE 24\"\"X36\"\"": "EXU-DRY PERMEABLE 24\"\"X36\"\"",
              "EXU-DRY QUILTED 36\"\"X72\"\"": "EXU-DRY QUILTED 36\"\"X72\"\"",
              "EXU-DRY SCALP 3\"\"X14\"\"": "EXU-DRY SCALP 3\"\"X14\"\"",
              "EXU-DRY SLIT DISC 3\"\"": "EXU-DRY SLIT DISC 3\"\"",
              "EXU-DRY SLIT TUBE 2\"\"X3\"\"": "EXU-DRY SLIT TUBE 2\"\"X3\"\"",
              "EXU-DRY SLIT TUBE 3\"\"X4\"\"": "EXU-DRY SLIT TUBE 3\"\"X4\"\"",
              "EXU-DRY SLIT TUBE 4\"\"X6\"\"": "EXU-DRY SLIT TUBE 4\"\"X6\"\"",
              "INTRASITE GEL APPLIPAK": "INTRASITE GEL APPLIPAK",
              "PICO WOUND THERAPY SYSTEM": "PICO WOUND THERAPY SYSTEM",
              "REPLICARE 1-1/2\"\"X2-1/2\"\"": "REPLICARE 1-1/2\"\"X2-1/2\"\"",
              "REPLICARE 4\"\"X4\"\"": "REPLICARE 4\"\"X4\"\"",
              "REPLICARE 6\"\"X6\"\"": "REPLICARE 6\"\"X6\"\"",
              "REPLICARE 8\"\"X8\"\"": "REPLICARE 8\"\"X8\"\"",
              "REPLICARE THIN 2\"\"X2.75\"\"": "REPLICARE THIN 2\"\"X2.75\"\"",
              "REPLICARE THIN 3.5\"\"X5.5\"\"": "REPLICARE THIN 3.5\"\"X5.5\"\"",
              "REPLICARE THIN 6\"\"X8\"\"": "REPLICARE THIN 6\"\"X8\"\"",
              "REPLICARE ULTRA 4\"\"X4\"\"": "REPLICARE ULTRA 4\"\"X4\"\"",
              "REPLICARE ULTRA 6\"\"X6\"\"": "REPLICARE ULTRA 6\"\"X6\"\"",
              "REPLICARE ULTRA SACRUM 7\"\"X8\"\"": "REPLICARE ULTRA SACRUM 7\"\"X8\"\"",
              "SOLOSITE CONFORMABLE WOUND": "SOLOSITE CONFORMABLE WOUND",
              "SOLOSITE WOUND GEL": "SOLOSITE WOUND GEL"
            },
            "SOLUS RX": {
              "SOLOX": "SOLOX"
            },
            "SOLUTECH PHARMACEUTICALS": {
              "NOXIFINE WOUND DRESSING": "NOXIFINE WOUND DRESSING",
              "SIL-K PAD LARGE": "SIL-K PAD LARGE",
              "SIL-K PAD MEDIUM": "SIL-K PAD MEDIUM",
              "SIL-K PAD SMALL": "SIL-K PAD SMALL"
            },
            "SONOMA PHARMACEUTICALS": {
              "SP ANTIPRURITIC": "SP ANTIPRURITIC"
            },
            "SOUTHWEST TECHNOLOGIES": {
              "COMFORT-AID 1.5\"\"X2.5\"\"": "COMFORT-AID 1.5\"\"X2.5\"\"",
              "ELASTO-GEL 12\"\"X12\"\"": "ELASTO-GEL 12\"\"X12\"\"",
              "ELASTO-GEL 2\"\"X3\"\"": "ELASTO-GEL 2\"\"X3\"\"",
              "ELASTO-GEL 3\"\" ROUND": "ELASTO-GEL 3\"\" ROUND",
              "ELASTO-GEL 4\"\"X4\"\"": "ELASTO-GEL 4\"\"X4\"\"",
              "ELASTO-GEL 5\"\"X5\"\"": "ELASTO-GEL 5\"\"X5\"\"",
              "ELASTO-GEL 6\"\"X8\"\"": "ELASTO-GEL 6\"\"X8\"\"",
              "ELASTO-GEL 8\"\"X16\"\"": "ELASTO-GEL 8\"\"X16\"\"",
              "ELASTO-GEL CAST/SPLINT 12\"\"X12\"\"": "ELASTO-GEL CAST/SPLINT 12\"\"X12\"\"",
              "ELASTO-GEL CAST/SPLINT 4\"\"X4\"\"": "ELASTO-GEL CAST/SPLINT 4\"\"X4\"\"",
              "ELASTO-GEL CAST/SPLINT 6\"\"X8\"\"": "ELASTO-GEL CAST/SPLINT 6\"\"X8\"\"",
              "ELASTO-GEL CAST/SPLINT 8\"\"X16\"\"": "ELASTO-GEL CAST/SPLINT 8\"\"X16\"\"",
              "ELASTO-GEL FACE MASK": "ELASTO-GEL FACE MASK",
              "ELASTO-GEL PLUS 2\"\"X3\"\"": "ELASTO-GEL PLUS 2\"\"X3\"\"",
              "ELASTO-GEL PLUS 4\"\"X4\"\"": "ELASTO-GEL PLUS 4\"\"X4\"\"",
              "ELASTO-GEL PLUS 8\"\"X8\"\"": "ELASTO-GEL PLUS 8\"\"X8\"\"",
              "GOLD DUST WOUND FILLER": "GOLD DUST WOUND FILLER",
              "STIMULEN": "STIMULEN",
              "TOE-AID": "TOE-AID"
            },
            "SPECTRUM DESIGNS, INC.": {
              "SPECTRAGEL": "SPECTRAGEL"
            },
            "SPENCO MEDICAL CORPORATION": {
              "2ND SKIN MOIST BURN 1.5\"\"X2\"\"": "2ND SKIN MOIST BURN 1.5\"\"X2\"\"",
              "2ND SKIN MOIST BURN 2\"\"X3\"\"": "2ND SKIN MOIST BURN 2\"\"X3\"\"",
              "2ND SKIN MOIST BURN 3\"\"X4\"\"": "2ND SKIN MOIST BURN 3\"\"X4\"\"",
              "2ND SKIN MOIST DRESSING KIT": "2ND SKIN MOIST DRESSING KIT",
              "2ND SKIN MOIST GEL 1\"\"X1\"\"": "2ND SKIN MOIST GEL 1\"\"X1\"\"",
              "2ND SKIN MOIST GEL 3\"\" ROUND": "2ND SKIN MOIST GEL 3\"\" ROUND",
              "2ND SKIN QUICK HEAL": "2ND SKIN QUICK HEAL"
            },
            "STEADMED MEDICAL": {
              "DRAWTEX 2\"\"X2\"\"": "DRAWTEX 2\"\"X2\"\"",
              "DRAWTEX 3\"\"X3\"\"": "DRAWTEX 3\"\"X3\"\"",
              "DRAWTEX 3\"\"X39\"\"": "DRAWTEX 3\"\"X39\"\"",
              "DRAWTEX 4\"\"X39\"\"": "DRAWTEX 4\"\"X39\"\"",
              "DRAWTEX 4\"\"X4\"\"": "DRAWTEX 4\"\"X4\"\"",
              "DRAWTEX 6\"\"X8\"\"": "DRAWTEX 6\"\"X8\"\"",
              "DRAWTEX 8\"\"X39\"\"": "DRAWTEX 8\"\"X39\"\"",
              "DRAWTEX 8\"\"X8\"\"": "DRAWTEX 8\"\"X8\"\"",
              "ELTA DERMAL": "ELTA DERMAL",
              "ELTA DERMAL WOUND": "ELTA DERMAL WOUND",
              "ELTA DERMAL WOUND DRESSING": "ELTA DERMAL WOUND DRESSING",
              "ELTA SILVERGEL": "ELTA SILVERGEL",
              "RESTA SILVER": "RESTA SILVER"
            },
            "STERLING KNIGHT PHARMACEUTICAL": {
              "FORAXA": "FORAXA",
              "VEXASYN": "VEXASYN"
            },
            "STRATPHARMA AG": {
              "STRATA GRT": "STRATA GRT"
            },
            "STRATUS PHARMACEUTICALS": {
              "SONAFINE": "SONAFINE"
            },
            "TRIGEN LABORATORIES": {
              "AVO CREAM": "AVO CREAM"
            },
            "UDL": {
              "BIOBRANE 10\"\"X15\"\"": "BIOBRANE 10\"\"X15\"\"",
              "BIOBRANE 15\"\"X20\"\"": "BIOBRANE 15\"\"X20\"\"",
              "BIOBRANE 5\"\"X15\"\"": "BIOBRANE 5\"\"X15\"\"",
              "BIOBRANE 5\"\"X5\"\"": "BIOBRANE 5\"\"X5\"\"",
              "BIOBRANE GLOVES LARGE": "BIOBRANE GLOVES LARGE",
              "BIOBRANE GLOVES MEDIUM": "BIOBRANE GLOVES MEDIUM",
              "BIOBRANE GLOVES PEDIATRIC": "BIOBRANE GLOVES PEDIATRIC",
              "BIOBRANE GLOVES SMALL": "BIOBRANE GLOVES SMALL",
              "BIOBRANE-L 10\"\"X15\"\"": "BIOBRANE-L 10\"\"X15\"\"",
              "BIOBRANE-L 5\"\"X15\"\"": "BIOBRANE-L 5\"\"X15\"\"",
              "BIOBRANE-L 5\"\"X5\"\"": "BIOBRANE-L 5\"\"X5\"\""
            }
          }
        }
      },
      "DIAGNOSTIC PRODUCTS": {
        "Diagnostic Biologicals": {
          "Diagnostic Biologicals": {
            "A-S MEDICATION SOLUTIONS": {
              "APLISOL": "APLISOL",
              "TUBERSOL": "TUBERSOL"
            },
            "ALK LABS": {
              "ASPERGILLUS FUMIGATUS": "ASPERGILLUS FUMIGATUS",
              "CONSTANT TRICHOPHYTON": "CONSTANT TRICHOPHYTON",
              "TRICHOPHYTON": "TRICHOPHYTON"
            },
            "ALK-ABELLO": {
              "ASPERGILLUS FUMIGATUS": "ASPERGILLUS FUMIGATUS",
              "AUREOBASIDIUM PULLULANS": "AUREOBASIDIUM PULLULANS",
              "BOTRYTIS CINEREA": "BOTRYTIS CINEREA",
              "PENICILLIUM NOTATUM": "PENICILLIUM NOTATUM",
              "SACCHAROMYCES CEREVISIAE": "SACCHAROMYCES CEREVISIAE",
              "TRICHOPHYTON MENTAG (DIAGNOST)": "TRICHOPHYTON MENTAG (DIAGNOST)"
            },
            "JUBILANT HOLLISTERSTIER": {
              "CANDIDA ALBICANS SKN TST ANTGN": "CANDIDA ALBICANS SKN TST ANTGN"
            },
            "NIELSEN BIOSCIENCES": {
              "CANDIN": "CANDIN",
              "SPHERUSOL": "SPHERUSOL"
            },
            "PAR STERILE PRODUCTS": {
              "APLISOL": "APLISOL"
            },
            "PHARMAGENETICO": {
              "ALMOND (DIAGNOSTIC)": "ALMOND (DIAGNOSTIC)",
              "APPLE (DIAGNOSTIC)": "APPLE (DIAGNOSTIC)",
              "AVOCADO (DIAGNOSTIC)": "AVOCADO (DIAGNOSTIC)",
              "BANANA (DIAGNOSTIC)": "BANANA (DIAGNOSTIC)",
              "BEEF (DIAGNOSTIC)": "BEEF (DIAGNOSTIC)",
              "CANTALOUPE (DIAGNOSTIC)": "CANTALOUPE (DIAGNOSTIC)",
              "CASEIN (DIAGNOSTIC)": "CASEIN (DIAGNOSTIC)",
              "CHICKEN MEAT (DIAGNOSTIC)": "CHICKEN MEAT (DIAGNOSTIC)",
              "COCOA BEAN (DIAGNOSTIC)": "COCOA BEAN (DIAGNOSTIC)",
              "CRAB (DIAGNOSTIC)": "CRAB (DIAGNOSTIC)",
              "EGG WHITE (DIAGNOSTIC)": "EGG WHITE (DIAGNOSTIC)",
              "MOSQUITO (DIAGNOSTIC)": "MOSQUITO (DIAGNOSTIC)",
              "OAT GRAIN (DIAGNOSTIC)": "OAT GRAIN (DIAGNOSTIC)",
              "ORANGE (DIAGNOSTIC)": "ORANGE (DIAGNOSTIC)",
              "PEANUT (DIAGNOSTIC)": "PEANUT (DIAGNOSTIC)",
              "PECAN NUT (DIAGNOSTIC)": "PECAN NUT (DIAGNOSTIC)",
              "PISTACHIO NUT (DIAGNOSTIC)": "PISTACHIO NUT (DIAGNOSTIC)",
              "PORK (DIAGNOSTIC)": "PORK (DIAGNOSTIC)",
              "RICE (DIAGNOSTIC)": "RICE (DIAGNOSTIC)",
              "SESAME SEED (DIAGNOSTIC)": "SESAME SEED (DIAGNOSTIC)",
              "SHRIMP (DIAGNOSTIC)": "SHRIMP (DIAGNOSTIC)",
              "SOYBEAN (DIAGNOSTIC)": "SOYBEAN (DIAGNOSTIC)",
              "STRAWBERRY (DIAGNOSTIC)": "STRAWBERRY (DIAGNOSTIC)",
              "SWEET CORN (DIAGNOSTIC)": "SWEET CORN (DIAGNOSTIC)",
              "TOMATO (DIAGNOSTIC)": "TOMATO (DIAGNOSTIC)",
              "TRICHOPHYTON": "TRICHOPHYTON",
              "WHOLE EGG (DIAGNOSTIC)": "WHOLE EGG (DIAGNOSTIC)"
            },
            "QUALITY CARE": {
              "TUBERSOL": "TUBERSOL"
            },
            "SANOFI PASTEUR": {
              "TUBERSOL": "TUBERSOL"
            }
          },
          "Multiple Skin Tests": {
            "ALK LABS": {
              "DIAGNOSTIC": "DIAGNOSTIC"
            },
            "GLAXO SMITH KLINE": {
              "T.R.U.E. TEST": "T.R.U.E. TEST"
            },
            "PHARMAGENETICO": {
              "HYMENOPTRA TESTS": "HYMENOPTRA TESTS"
            }
          }
        },
        "Diagnostic Drugs": {
          "Diagnostic Drugs": {
            "AKORN": {
              "ADENOSINE": "ADENOSINE",
              "IC GREEN": "IC GREEN"
            },
            "ALK-ABELLO": {
              "HISTATROL": "HISTATROL"
            },
            "ALLERQUEST": {
              "PRE-PEN": "PRE-PEN"
            },
            "AMERICAN REGENT": {
              "INDIGO CARMINE": "INDIGO CARMINE"
            },
            "AMPHASTAR-IMS": {
              "CORTROSYN": "CORTROSYN"
            },
            "ASTELLAS": {
              "ADENOSCAN": "ADENOSCAN",
              "LEXISCAN": "LEXISCAN"
            },
            "AUROMEDICS PHARMA": {
              "ADENOSINE (DIAGNOSTIC)": "ADENOSINE (DIAGNOSTIC)",
              "ISOSULFAN BLUE": "ISOSULFAN BLUE"
            },
            "BOEHRINGER INGELHEIM": {
              "GLUCAGEN DIAGNOSTIC": "GLUCAGEN DIAGNOSTIC"
            },
            "BRACCO DIAGNOSTICS": {
              "KINEVAC": "KINEVAC"
            },
            "CHIRHOCLIN": {
              "CHIRHOSTIM": "CHIRHOSTIM",
              "SECREFLO": "SECREFLO"
            },
            "DIAGNOSTIC GREEN GMBH": {
              "INDOCYANINE GREEN": "INDOCYANINE GREEN"
            },
            "EMPOWER PHARMACY": {
              "SERMORELIN ACETATE DIAGNOSTIC": "SERMORELIN ACETATE DIAGNOSTIC"
            },
            "FAGRON": {
              "D-XYLOSE": "D-XYLOSE"
            },
            "FERRING": {
              "ACTHREL": "ACTHREL"
            },
            "FRESENIUS KABI USA": {
              "ADENOSINE (DIAGNOSTIC)": "ADENOSINE (DIAGNOSTIC)",
              "GLUCAGON HCL (DIAGNOSTIC)": "GLUCAGON HCL (DIAGNOSTIC)"
            },
            "GENZYME": {
              "THYROGEN": "THYROGEN"
            },
            "HIKMA": {
              "DIPYRIDAMOLE": "DIPYRIDAMOLE"
            },
            "HOSPIRA": {
              "ADENOSINE (DIAGNOSTIC)": "ADENOSINE (DIAGNOSTIC)"
            },
            "HRA PHARMA RARE DISEASES": {
              "METOPIRONE": "METOPIRONE"
            },
            "HUB PHARMACEUTICALS": {
              "INDOCYANINE GREEN": "INDOCYANINE GREEN"
            },
            "INTEGRATED PHARMA SOLUTIONS": {
              "CUSHING SYNDROME DIAGNOSTIC": "CUSHING SYNDROME DIAGNOSTIC"
            },
            "JUBILANT HOLLISTERSTIER": {
              "POSITIVE SKIN TEST CONTROL": "POSITIVE SKIN TEST CONTROL"
            },
            "MEDISCA": {
              "D-XYLOSE": "D-XYLOSE"
            },
            "METHAPHARM": {
              "ARIDOL": "ARIDOL",
              "PROVOCHOLINE": "PROVOCHOLINE"
            },
            "MYLAN INSTITUTIONAL": {
              "ADENOSINE (DIAGNOSTIC)": "ADENOSINE (DIAGNOSTIC)",
              "COSYNTROPIN": "COSYNTROPIN",
              "ISOSULFAN BLUE": "ISOSULFAN BLUE"
            },
            "NEPHRON STERILE COMPOUNDING": {
              "METHACHOLINE CHLORIDE": "METHACHOLINE CHLORIDE"
            },
            "NOVAPLUS/FRESENIUS KABI": {
              "GLUCAGON HCL (DIAGNOSTIC)": "GLUCAGON HCL (DIAGNOSTIC)"
            },
            "NOVAPLUS/MYLAN INSTITUTIONAL": {
              "ADENOSINE (DIAGNOSTIC)": "ADENOSINE (DIAGNOSTIC)",
              "COSYNTROPIN": "COSYNTROPIN",
              "ISOSULFAN BLUE": "ISOSULFAN BLUE"
            },
            "NOVAPLUS/SAGENT PHARMACEUTICAL": {
              "ADENOSINE (DIAGNOSTIC)": "ADENOSINE (DIAGNOSTIC)"
            },
            "NX DEVELOPMENT CORP": {
              "GLEOLAN": "GLEOLAN"
            },
            "PCCA": {
              "D-XYLOSE": "D-XYLOSE"
            },
            "PERRIGO": {
              "GLUTOL": "GLUTOL"
            },
            "PFIZER U.S.": {
              "R-GENE 10": "R-GENE 10"
            },
            "PHOTOCURE": {
              "CYSVIEW": "CYSVIEW"
            },
            "QUESTCOR": {
              "INULIN": "INULIN"
            },
            "SAGENT PHARMACEUTICAL": {
              "ADENOSINE (DIAGNOSTIC)": "ADENOSINE (DIAGNOSTIC)"
            },
            "SANDOZ": {
              "COSYNTROPIN": "COSYNTROPIN"
            },
            "SPECTRUM": {
              "D-XYLOSE": "D-XYLOSE"
            },
            "STRONGBRIDGE BIOPHARMA": {
              "MACRILEN": "MACRILEN"
            },
            "TEVA PARENTERAL MEDICINES": {
              "ADENOSINE (DIAGNOSTIC)": "ADENOSINE (DIAGNOSTIC)"
            }
          }
        },
        "Diagnostic Products, Misc.": {
          "Diagnostic Products, Misc.": {
            "COOPER SURGICAL": {
              "HYSKON": "HYSKON"
            }
          },
          "Diagnostic Supplies": {
            "BD MEDICAL SURGICAL SYSTEMS": {
              "DRIHEP ARTERIAL LINE SYRINGE": "DRIHEP ARTERIAL LINE SYRINGE",
              "DRIHEP PLUS PREFILLED SYRINGE": "DRIHEP PLUS PREFILLED SYRINGE",
              "GLASS SYRINGE 3 ML": "GLASS SYRINGE 3 ML",
              "GLASS SYRINGE 5 ML": "GLASS SYRINGE 5 ML",
              "LIQUIHEP II PREFILLED SYRINGE": "LIQUIHEP II PREFILLED SYRINGE",
              "LIQUIHEP PREFILLED SYRINGE": "LIQUIHEP PREFILLED SYRINGE",
              "LITHIUM HEPARIN PREFILLED SYR": "LITHIUM HEPARIN PREFILLED SYR",
              "LYOPHIL LITH HEP PREFILLED SYR": "LYOPHIL LITH HEP PREFILLED SYR"
            },
            "GERITREX CORPORATION": {
              "HYDROSONIC ULTRASOUND GEL": "HYDROSONIC ULTRASOUND GEL"
            },
            "GORDON": {
              "ULTRASONE": "ULTRASONE"
            },
            "GRAHAM-FIELD": {
              "GRAFCO ULTRASOUND": "GRAFCO ULTRASOUND",
              "GRAFCO ULTRASOUND TRANSMISSION": "GRAFCO ULTRASOUND TRANSMISSION"
            },
            "HR PHARMACEUTICALS": {
              "H-R ULTRASOUND JELLY": "H-R ULTRASOUND JELLY",
              "H-R ULTRASOUND JELLY ONE SHOT": "H-R ULTRASOUND JELLY ONE SHOT"
            },
            "MEDISCA": {
              "CERVICAL SPECIMEN COLLECTION": "CERVICAL SPECIMEN COLLECTION"
            },
            "ROCHE DIAGNOSTICS": {
              "CHEMSTRIP CALIBRATION": "CHEMSTRIP CALIBRATION"
            },
            "SIEMENS HEALTHCARE DIAGNOSTICS": {
              "HEMA-CHEK": "HEMA-CHEK"
            },
            "SPECTRUM": {
              "AQUASONIC 100": "AQUASONIC 100"
            }
          }
        },
        "Diagnostic Radiopharmaceuticals": {
          "Diagnostic Radiopharmaceuticals - Brain": {
            "GE HEALTHCARE": {
              "DATSCAN": "DATSCAN",
              "VIZAMYL": "VIZAMYL"
            },
            "LILLY": {
              "AMYVID": "AMYVID"
            }
          },
          "Diagnostic Radiopharmaceuticals - Cardiac": {
            "GE HEALTHCARE": {
              "MYOVIEW 30ML": "MYOVIEW 30ML",
              "THALLOUS CHLORIDE TL 201": "THALLOUS CHLORIDE TL 201"
            },
            "IONETIX CORPORATION": {
              "AMMONIA N 13": "AMMONIA N 13"
            },
            "LANTHEUS MEDICAL IMAGING": {
              "THALLOUS CHLORIDE TL 201": "THALLOUS CHLORIDE TL 201"
            },
            "PHARMALUCENCE": {
              "TECHNETIUM TC 99M SESTAMIBI": "TECHNETIUM TC 99M SESTAMIBI"
            }
          },
          "Diagnostic Radiopharmaceuticals - Endocrine": {
            "ADVANCED ACCELERATOR APPL": {
              "NETSPOT": "NETSPOT"
            },
            "CURIUM US": {
              "DETECTNET": "DETECTNET"
            },
            "GE HEALTHCARE": {
              "ADREVIEW": "ADREVIEW",
              "INDICLOR": "INDICLOR",
              "INDIUM IN 111 DTPA": "INDIUM IN 111 DTPA"
            },
            "MALLINCKRODT NUCLEAR MEDICINE": {
              "OCTREOSCAN": "OCTREOSCAN"
            },
            "UIHC-PET IMAGING CENTER": {
              "DOTATOC GA 68": "DOTATOC GA 68"
            }
          },
          "Diagnostic Radiopharmaceuticals - Gases": {
            "LANTHEUS MEDICAL IMAGING": {
              "XENON XE 133": "XENON XE 133"
            }
          },
          "Diagnostic Radiopharmaceuticals - Hepatobiliary": {
            "BRACCO DIAGNOSTICS": {
              "CHOLETEC": "CHOLETEC"
            },
            "PHARMALUCENCE": {
              "HEPATOLITE": "HEPATOLITE",
              "TECHNETIUM TC 99M MEBROFENIN": "TECHNETIUM TC 99M MEBROFENIN"
            }
          },
          "Diagnostic Radiopharmaceuticals - Miscellaneous": {
            "DAXOR CORPORATION": {
              "VOLUMEX": "VOLUMEX"
            },
            "LANTHEUS MEDICAL IMAGING": {
              "GALLIUM CITRATE GA 67": "GALLIUM CITRATE GA 67",
              "LEU TECHNELITE": "LEU TECHNELITE",
              "TECHNELITE": "TECHNELITE"
            },
            "PHARMALUCENCE": {
              "TECHNET TC 99M SULFUR COLLOID": "TECHNET TC 99M SULFUR COLLOID",
              "TECHNETIUM TC 99M PYROPHOS": "TECHNETIUM TC 99M PYROPHOS"
            },
            "SOFIE": {
              "FLUDEOXYGLUCOSE F 18": "FLUDEOXYGLUCOSE F 18"
            },
            "ZIONEXA US": {
              "CERIANNA": "CERIANNA"
            }
          },
          "Diagnostic Radiopharmaceuticals - Prostatic": {
            "AYTU BIOSCIENCE": {
              "PROSTASCINT": "PROSTASCINT"
            },
            "BLUE EARTH DIAGNOSTICS": {
              "AXUMIN": "AXUMIN"
            }
          },
          "Diagnostic Radiopharmaceuticals - Renal": {
            "GE HEALTHCARE": {
              "DMSA": "DMSA"
            },
            "JUBILANT DRAXIMAGE": {
              "DRAX IMAGE DTPA": "DRAX IMAGE DTPA"
            }
          },
          "Diagnostic Radiopharmaceuticals - Skeletal": {
            "PHARMALUCENCE": {
              "TECHNETIUM TC 99M MEDRONATE": "TECHNETIUM TC 99M MEDRONATE"
            }
          },
          "Diagnostic Radiopharmaceuticals-Immune Cell Radiolabelin": {
            "GE HEALTHCARE": {
              "CERETEC": "CERETEC",
              "INDIUM IN 111 OXYQUINOLINE": "INDIUM IN 111 OXYQUINOLINE"
            }
          }
        },
        "Diagnostic Tests": {
          "Diagnostic Test Combinations": {
            "AMERICAN SCREENING CORP": {
              "REVEAL GET PREGNANT QUICK": "REVEAL GET PREGNANT QUICK"
            }
          },
          "Diagnostic Tests": {
            "A-S MEDICATION SOLUTIONS": {
              "TRUE METRIX BLOOD GLUCOSE TEST": "TRUE METRIX BLOOD GLUCOSE TEST",
              "TRUETEST TEST": "TRUETEST TEST",
              "TRUETRACK TEST": "TRUETRACK TEST"
            },
            "ABBOTT DIABETES CARE": {
              "EXACTECH R-S-G TEST": "EXACTECH R-S-G TEST",
              "EXACTECH TEST": "EXACTECH TEST",
              "FREESTYLE INSULINX TEST": "FREESTYLE INSULINX TEST",
              "FREESTYLE LITE TEST": "FREESTYLE LITE TEST",
              "FREESTYLE PRECISION NEO TEST": "FREESTYLE PRECISION NEO TEST",
              "FREESTYLE TEST": "FREESTYLE TEST",
              "KROGER TEST": "KROGER TEST",
              "OPTIUM TEST": "OPTIUM TEST",
              "OPTIUMEZ TEST": "OPTIUMEZ TEST",
              "PRECISION PCX": "PRECISION PCX",
              "PRECISION PCX PLUS TEST": "PRECISION PCX PLUS TEST",
              "PRECISION POINT OF CARE TEST": "PRECISION POINT OF CARE TEST",
              "PRECISION QID TEST": "PRECISION QID TEST",
              "PRECISION SOF-TACT TEST": "PRECISION SOF-TACT TEST",
              "PRECISION XTRA BLOOD GLUCOSE": "PRECISION XTRA BLOOD GLUCOSE",
              "PRECISION XTRA KETONE": "PRECISION XTRA KETONE",
              "RELION BLOOD GLUCOSE TEST": "RELION BLOOD GLUCOSE TEST"
            },
            "ABLE DIAGNOSTICS": {
              "VIVAGUARD INO TEST STRIPS": "VIVAGUARD INO TEST STRIPS"
            },
            "ACON LABORATORIES": {
              "ON CALL EXPRESS BLOOD GLUCOSE": "ON CALL EXPRESS BLOOD GLUCOSE",
              "ON CALL PLUS BLOOD GLUCOSE": "ON CALL PLUS BLOOD GLUCOSE",
              "ON CALL VIVID BLOOD GLUCOSE": "ON CALL VIVID BLOOD GLUCOSE",
              "OPTUMRX BLOOD GLUCOSE TEST": "OPTUMRX BLOOD GLUCOSE TEST"
            },
            "AGAMATRIX": {
              "AGAMATRIX AMP TEST": "AGAMATRIX AMP TEST",
              "AGAMATRIX JAZZ TEST": "AGAMATRIX JAZZ TEST",
              "AGAMATRIX KEYNOTE TEST": "AGAMATRIX KEYNOTE TEST",
              "AGAMATRIX PRESTO TEST": "AGAMATRIX PRESTO TEST",
              "LIBERTY NEXT GENERATION TEST": "LIBERTY NEXT GENERATION TEST",
              "WAVESENSE PRESTO": "WAVESENSE PRESTO"
            },
            "ALVIX LABORATORIES": {
              "DYNAMIC": "DYNAMIC",
              "DYNAMIC PLUS PAK": "DYNAMIC PLUS PAK",
              "SPECIMEN COLLECTION": "SPECIMEN COLLECTION"
            },
            "AMERICAN SCREENING CORP": {
              "REVEAL BLOOD GLUCOSE TEST": "REVEAL BLOOD GLUCOSE TEST",
              "REVEAL COLON DISEASE": "REVEAL COLON DISEASE",
              "REVEAL DNA PATERNITY TEST": "REVEAL DNA PATERNITY TEST",
              "REVEAL MULTI-DRUG TESTING CUP": "REVEAL MULTI-DRUG TESTING CUP",
              "REVEAL OVULATION": "REVEAL OVULATION",
              "REVEAL OVULATION PREDICTOR": "REVEAL OVULATION PREDICTOR",
              "REVEAL URINE PREGNANCY": "REVEAL URINE PREGNANCY"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP ONE STEP PREGNANCY": "GNP ONE STEP PREGNANCY"
            },
            "AMIRA MEDICAL": {
              "AT LAST TEST": "AT LAST TEST"
            },
            "ANSELL": {
              "CONCEIVE": "CONCEIVE",
              "Q-TEST": "Q-TEST",
              "RAPIDVUE": "RAPIDVUE"
            },
            "APOTHECARY PRODUCTS, INC.": {
              "UNIVERSAL PH": "UNIVERSAL PH"
            },
            "APOTHECUS": {
              "PURALIN ONE-STEP PREGNANCY": "PURALIN ONE-STEP PREGNANCY"
            },
            "ARKRAY USA": {
              "ADVANCE INTUITION TEST": "ADVANCE INTUITION TEST",
              "ADVANCE MICRO-DRAW TEST": "ADVANCE MICRO-DRAW TEST",
              "ASSURE 3 TEST": "ASSURE 3 TEST",
              "ASSURE 4 TEST": "ASSURE 4 TEST",
              "ASSURE II": "ASSURE II",
              "ASSURE II CHECK": "ASSURE II CHECK",
              "ASSURE PLATINUM": "ASSURE PLATINUM",
              "ASSURE PRISM MULTI TEST": "ASSURE PRISM MULTI TEST",
              "ASSURE PRO TEST": "ASSURE PRO TEST",
              "EASYPRO BLOOD GLUCOSE TEST": "EASYPRO BLOOD GLUCOSE TEST",
              "EASYPRO PLUS": "EASYPRO PLUS",
              "GLUCOCARD 01 SENSOR PLUS": "GLUCOCARD 01 SENSOR PLUS",
              "GLUCOCARD EXPRESSION TEST": "GLUCOCARD EXPRESSION TEST",
              "GLUCOCARD SHINE TEST": "GLUCOCARD SHINE TEST",
              "GLUCOCARD VITAL TEST": "GLUCOCARD VITAL TEST",
              "GLUCOCARD X-SENSOR": "GLUCOCARD X-SENSOR",
              "POCKETCHEM EZ TEST": "POCKETCHEM EZ TEST",
              "QUICKTEK TEST": "QUICKTEK TEST",
              "RELION BLOOD GLUCOSE TEST": "RELION BLOOD GLUCOSE TEST",
              "RELION PREMIER TEST": "RELION PREMIER TEST",
              "RELION PRIME TEST": "RELION PRIME TEST",
              "SUPREME TEST": "SUPREME TEST"
            },
            "ASCENSIA DIABETES CARE": {
              "BAYER BREEZE 2 TEST": "BAYER BREEZE 2 TEST",
              "BREEZE 2 TEST": "BREEZE 2 TEST",
              "CLINITEST": "CLINITEST",
              "CONTOUR NEXT TEST": "CONTOUR NEXT TEST",
              "CONTOUR TEST": "CONTOUR TEST",
              "DIASTIX": "DIASTIX",
              "KETOSTIX": "KETOSTIX"
            },
            "B-M SQUIBB U.S. (PRIMARY CARE)": {
              "NITRATEST PAPER": "NITRATEST PAPER"
            },
            "BD DIABETES CARE": {
              "PRECISE PREGNANCY": "PRECISE PREGNANCY"
            },
            "BERGEN BRUNSWIG": {
              "GNP ONE STEP PREGNANCY": "GNP ONE STEP PREGNANCY",
              "GNP OVULATION PREDICTOR": "GNP OVULATION PREDICTOR"
            },
            "BIONIME USA CORPORATION": {
              "GE100 BLOOD GLUCOSE TEST": "GE100 BLOOD GLUCOSE TEST",
              "RIGHTEST GS100 BLOOD GLUCOSE": "RIGHTEST GS100 BLOOD GLUCOSE",
              "RIGHTEST GS300 BLOOD GLUCOSE": "RIGHTEST GS300 BLOOD GLUCOSE",
              "RIGHTEST GS550 BLOOD GLUCOSE": "RIGHTEST GS550 BLOOD GLUCOSE"
            },
            "BIOSAFE LABORATORIES": {
              "PERSONAL BIOMETRIC TEST": "PERSONAL BIOMETRIC TEST"
            },
            "BIOSENSE MEDICAL DEVICES": {
              "SOLUS V2 TEST": "SOLUS V2 TEST"
            },
            "BPI LABS LLC": {
              "PRO DNA COLLECTION": "PRO DNA COLLECTION"
            },
            "CAMBRIDGE SENSORS USA": {
              "MICRODOT TEST": "MICRODOT TEST"
            },
            "CARDIOCOM": {
              "GLUCOCOM TEST": "GLUCOCOM TEST"
            },
            "CHAIN DRUG CONSORTIUM": {
              "1-STEP PREGNANCY": "1-STEP PREGNANCY",
              "DIGITAL PREGNANCY": "DIGITAL PREGNANCY",
              "EARLY RESULT PREGNANCY": "EARLY RESULT PREGNANCY",
              "ONE-STEP OVULATION PREDICTOR": "ONE-STEP OVULATION PREDICTOR",
              "ONE-STEP PREGNANCY": "ONE-STEP PREGNANCY",
              "TRUETRACK TEST": "TRUETRACK TEST"
            },
            "CHRONOHEALTH": {
              "VITAMIN D TEST": "VITAMIN D TEST"
            },
            "CHURCH DWIGHT": {
              "ANSWER OVULATION": "ANSWER OVULATION",
              "ANSWER PLUS": "ANSWER PLUS",
              "ANSWER QUICK/SIMPLE": "ANSWER QUICK/SIMPLE",
              "ANSWER QUICK/SIMPLE 2": "ANSWER QUICK/SIMPLE 2",
              "FIRST RESPONSE DOUBLE": "FIRST RESPONSE DOUBLE",
              "FIRST RESPONSE OVULATION BASE": "FIRST RESPONSE OVULATION BASE",
              "FIRST RESPONSE SINGLE": "FIRST RESPONSE SINGLE"
            },
            "CITIHEALTH": {
              "NEUTEK 2TEK TEST": "NEUTEK 2TEK TEST"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS ADVANCED GLUCOSE TEST": "CVS ADVANCED GLUCOSE TEST",
              "CVS AT HOME A1C TEST": "CVS AT HOME A1C TEST",
              "CVS DAILY OVULATION PREDICTOR": "CVS DAILY OVULATION PREDICTOR",
              "CVS DIGITAL PREGNANCY TEST": "CVS DIGITAL PREGNANCY TEST",
              "CVS EARLY PREGNANCY": "CVS EARLY PREGNANCY",
              "CVS EARLY RESULT PREGNANCY": "CVS EARLY RESULT PREGNANCY",
              "CVS GLUCOSE METER TEST STRIPS": "CVS GLUCOSE METER TEST STRIPS",
              "CVS ONE STEP PREGNANCY": "CVS ONE STEP PREGNANCY",
              "CVS PREGNANCY TEST KIT": "CVS PREGNANCY TEST KIT"
            },
            "DIABETIC SUPPLY OF SUNCOAST": {
              "ADVOCATE REDI-CODE": "ADVOCATE REDI-CODE",
              "ADVOCATE REDI-CODE+ TEST": "ADVOCATE REDI-CODE+ TEST",
              "ADVOCATE TEST": "ADVOCATE TEST"
            },
            "DIATHRIVE": {
              "DIATHRIVE BLOOD GLUCOSE TEST": "DIATHRIVE BLOOD GLUCOSE TEST",
              "DIATHRIVE GLUCOSE TEST": "DIATHRIVE GLUCOSE TEST",
              "DIATHRIVE+ GLUCOSE TEST": "DIATHRIVE+ GLUCOSE TEST"
            },
            "ENOVACHEM MANUFACTURING": {
              "ADVANCED DNA COLLECTION": "ADVANCED DNA COLLECTION",
              "ADVANCED DNA COLLECTION II": "ADVANCED DNA COLLECTION II",
              "ADVANCED DNA COLLECTION III": "ADVANCED DNA COLLECTION III"
            },
            "ENTRA HEALTH SYSTEMS": {
              "MYGLUCOHEALTH TEST": "MYGLUCOHEALTH TEST"
            },
            "EOCENE HEALTH SYSTEMS": {
              "DIATRUE PLUS TEST": "DIATRUE PLUS TEST"
            },
            "EPS BIOTECHNOLOGY": {
              "EASYMAX 15 TEST": "EASYMAX 15 TEST",
              "EASYMAX TEST": "EASYMAX TEST",
              "EASYPLUS BLOOD GLUCOSE TEST": "EASYPLUS BLOOD GLUCOSE TEST",
              "FORTISCARE TEST": "FORTISCARE TEST"
            },
            "EQUALINE": {
              "EQL ONE-STEP PREGNANCY": "EQL ONE-STEP PREGNANCY",
              "EQL PREGNANCY EARLY RESULT": "EQL PREGNANCY EARLY RESULT",
              "EQL PREGNANCY TEST DIGITAL": "EQL PREGNANCY TEST DIGITAL",
              "EQL TRUETEST TEST": "EQL TRUETEST TEST",
              "EQL TRUETRACK TEST": "EQL TRUETRACK TEST"
            },
            "EZ UPC.INFO": {
              "REFUAH PLUS BLOOD GLUCOSE TEST": "REFUAH PLUS BLOOD GLUCOSE TEST"
            },
            "FIFTY50 MEDICAL": {
              "FIFTY50 GLUCOSE TEST 2.0": "FIFTY50 GLUCOSE TEST 2.0"
            },
            "FORA CARE": {
              "FORA 6 CONNECT": "FORA 6 CONNECT",
              "FORA BLOOD GLUCOSE TEST": "FORA BLOOD GLUCOSE TEST",
              "FORA D15G BLOOD GLUCOSE TEST": "FORA D15G BLOOD GLUCOSE TEST",
              "FORA D20 BLOOD GLUCOSE TEST": "FORA D20 BLOOD GLUCOSE TEST",
              "FORA D40/G31 BLOOD GLUCOSE": "FORA D40/G31 BLOOD GLUCOSE",
              "FORA G20 BLOOD GLUCOSE TEST": "FORA G20 BLOOD GLUCOSE TEST",
              "FORA G30/PREM V10 GLUCOSE TEST": "FORA G30/PREM V10 GLUCOSE TEST",
              "FORA GD20 TEST": "FORA GD20 TEST",
              "FORA GD50 BLOOD GLUCOSE TEST": "FORA GD50 BLOOD GLUCOSE TEST",
              "FORA GTEL BLOOD GLUCOSE TEST": "FORA GTEL BLOOD GLUCOSE TEST",
              "FORA GTEL BLOOD KETONE TEST": "FORA GTEL BLOOD KETONE TEST",
              "FORA TN\\'G/TN\\'G VOICE": "FORA TN\\'G/TN\\'G VOICE",
              "FORA V10 BLOOD GLUCOSE TEST": "FORA V10 BLOOD GLUCOSE TEST",
              "FORA V12 BLOOD GLUCOSE TEST": "FORA V12 BLOOD GLUCOSE TEST",
              "FORA V20 BLOOD GLUCOSE TEST": "FORA V20 BLOOD GLUCOSE TEST",
              "FORA V30A BLOOD GLUCOSE TEST": "FORA V30A BLOOD GLUCOSE TEST",
              "FORACARE GD40 TEST": "FORACARE GD40 TEST",
              "FORACARE PREMIUM V10 TEST": "FORACARE PREMIUM V10 TEST",
              "FORACARE TEST N GO TEST": "FORACARE TEST N GO TEST",
              "PRO VOICE V8/V9 GLUCOSE": "PRO VOICE V8/V9 GLUCOSE"
            },
            "FUTURE DIAGNOSTICS": {
              "CARETOUCH TEST": "CARETOUCH TEST"
            },
            "GENESIS HEALTH TECHNOLOGIES": {
              "GHT TEST": "GHT TEST"
            },
            "GENEXEL-SEIN": {
              "DUO-CARE TEST": "DUO-CARE TEST"
            },
            "GLUCO PERFECT": {
              "GLUCO PERFECT 3 TEST": "GLUCO PERFECT 3 TEST"
            },
            "GOJJI": {
              "GOJJI BLOOD GLUCOSE TEST": "GOJJI BLOOD GLUCOSE TEST",
              "GOJJI BLOOD KETONE TEST": "GOJJI BLOOD KETONE TEST",
              "GOJJI BLOOD TEST STRIP/LANCETS": "GOJJI BLOOD TEST STRIP/LANCETS"
            },
            "HOME AIDE DIAGNOSTICS": {
              "EASY PLUS BLOOD GLUCOSE TEST": "EASY PLUS BLOOD GLUCOSE TEST",
              "EASY PLUS II GLUCOSE TEST": "EASY PLUS II GLUCOSE TEST",
              "EASY STEP TEST": "EASY STEP TEST",
              "EASY TALK BLOOD GLUCOSE TEST": "EASY TALK BLOOD GLUCOSE TEST",
              "EASY TRAK BLOOD GLUCOSE TEST": "EASY TRAK BLOOD GLUCOSE TEST",
              "EASY TRAK II GLUCOSE TEST": "EASY TRAK II GLUCOSE TEST"
            },
            "HUDSON SCIENTIFIC": {
              "D-CARE BLOOD GLUCOSE": "D-CARE BLOOD GLUCOSE"
            },
            "I-HEALTH": {
              "AZO TEST": "AZO TEST"
            },
            "I-SENS": {
              "CARESENS N GLUCOSE TEST": "CARESENS N GLUCOSE TEST",
              "COOL BLOOD GLUCOSE TEST STRIPS": "COOL BLOOD GLUCOSE TEST STRIPS"
            },
            "INFOPIA USA": {
              "ELEMENT COMPACT TEST": "ELEMENT COMPACT TEST",
              "ELEMENT TEST": "ELEMENT TEST",
              "EVOLUTION AUTOCODE": "EVOLUTION AUTOCODE"
            },
            "INFORMED DATA SYSTEMS": {
              "ONE DROP TEST": "ONE DROP TEST"
            },
            "INSIGHT PHARMACEUTICALS": {
              "EPT": "EPT",
              "EPT DIGITAL": "EPT DIGITAL"
            },
            "INTERNATIONAL BRAND MANAGEMENT": {
              "HOME PAP KIT": "HOME PAP KIT"
            },
            "IT3 MEDICAL": {
              "12-PANEL POC TOXICOLOGY SYSTEM": "12-PANEL POC TOXICOLOGY SYSTEM",
              "DX1 ORAGENOMIC DNA SCREEN": "DX1 ORAGENOMIC DNA SCREEN",
              "DX2 ORAGENOMIC DNA SCREEN": "DX2 ORAGENOMIC DNA SCREEN",
              "TOXICOLOGY MED COLLECTION SYS": "TOXICOLOGY MED COLLECTION SYS"
            },
            "LEADER BRAND PRODUCTS": {
              "BLOOD GLUCOSE TEST": "BLOOD GLUCOSE TEST",
              "EARLY RESULT PREGNANCY": "EARLY RESULT PREGNANCY",
              "ONE STEP PREGNANCY": "ONE STEP PREGNANCY",
              "ONE-STEP PREGNANCY": "ONE-STEP PREGNANCY",
              "OVULATION PREDICTOR": "OVULATION PREDICTOR",
              "PREGNANCY TEST": "PREGNANCY TEST"
            },
            "LIBERTY MEDICAL SUPPLY": {
              "LIBERTY TEST": "LIBERTY TEST",
              "TRUE METRIX BLOOD GLUCOSE TEST": "TRUE METRIX BLOOD GLUCOSE TEST"
            },
            "LIFESCAN INC": {
              "ONETOUCH ULTRA": "ONETOUCH ULTRA",
              "ONETOUCH VERIO": "ONETOUCH VERIO"
            },
            "LINKS MEDICAL PRODUCTS": {
              "MICRODOT TEST": "MICRODOT TEST"
            },
            "LIVONGO HEALTH": {
              "IN TOUCH BLOOD GLUCOSE TEST": "IN TOUCH BLOOD GLUCOSE TEST"
            },
            "MAS MANAGEMENT GROUP": {
              "RX-SPECIMEN COLLECTION": "RX-SPECIMEN COLLECTION"
            },
            "MATRIX DISTRIBUTORS": {
              "NEXGEN TEST": "NEXGEN TEST"
            },
            "MAVERON HEALTH": {
              "DIUSCREEN MEDICATED COLLECTION": "DIUSCREEN MEDICATED COLLECTION",
              "DIUSCREEN MULTI-DRUG MEDICATED": "DIUSCREEN MULTI-DRUG MEDICATED"
            },
            "MCKESSON SUNMARK": {
              "SM OVULATION PREDICTOR": "SM OVULATION PREDICTOR",
              "SM PREGNANCY": "SM PREGNANCY",
              "SM PREGNANCY TEST KIT": "SM PREGNANCY TEST KIT"
            },
            "MEDISCA": {
              "PH STRIPS": "PH STRIPS"
            },
            "MEDLINE INDUS": {
              "EVENCARE + BLOOD GLUCOSE TEST": "EVENCARE + BLOOD GLUCOSE TEST",
              "EVENCARE BLOOD GLUCOSE TEST": "EVENCARE BLOOD GLUCOSE TEST",
              "EVENCARE G2 TEST": "EVENCARE G2 TEST",
              "EVENCARE G3 TEST": "EVENCARE G3 TEST",
              "EVENCARE MINI GLUCOSE TEST": "EVENCARE MINI GLUCOSE TEST",
              "EVENCARE PROVIEW GLUCOSE TEST": "EVENCARE PROVIEW GLUCOSE TEST",
              "HARMONY BLOOD GLUCOSE TEST": "HARMONY BLOOD GLUCOSE TEST"
            },
            "MEIJER": {
              "MEIJER TRUETEST TEST": "MEIJER TRUETEST TEST",
              "MEIJER TRUETRACK TEST": "MEIJER TRUETRACK TEST"
            },
            "MHC MEDICAL PRODUCTS": {
              "EASY TOUCH TEST": "EASY TOUCH TEST",
              "GNP EASY TOUCH GLUCOSE TEST": "GNP EASY TOUCH GLUCOSE TEST",
              "KROGER HEALTHPRO GLUCOSE TEST": "KROGER HEALTHPRO GLUCOSE TEST"
            },
            "NOVA BIOMEDICAL": {
              "NOVA MAX GLUCOSE TEST": "NOVA MAX GLUCOSE TEST",
              "NOVA MAX PLUS KETONE TEST": "NOVA MAX PLUS KETONE TEST"
            },
            "NUCARE PHARMACEUTICALS": {
              "MEDICATED DNA COLLECTION": "MEDICATED DNA COLLECTION",
              "MEDICATED DNA COLLECTION 2": "MEDICATED DNA COLLECTION 2"
            },
            "OMNIS HEALTH": {
              "EMBRACE BLOOD GLUCOSE TEST": "EMBRACE BLOOD GLUCOSE TEST",
              "EMBRACE EVO BLOOD GLUCOSE TEST": "EMBRACE EVO BLOOD GLUCOSE TEST",
              "EMBRACE PRO GLUCOSE TEST": "EMBRACE PRO GLUCOSE TEST",
              "EMBRACE TALK GLUCOSE TEST": "EMBRACE TALK GLUCOSE TEST",
              "HW EMBRACE PRO GLUCOSE TEST": "HW EMBRACE PRO GLUCOSE TEST",
              "HW EMBRACE TALK GLUCOSE TEST": "HW EMBRACE TALK GLUCOSE TEST",
              "VICTORY AGM-4000 TEST": "VICTORY AGM-4000 TEST"
            },
            "ONE PHARMA & MEDICAL SUPPLY CO": {
              "SURE-TEST EASYPLUS MINI TEST": "SURE-TEST EASYPLUS MINI TEST"
            },
            "P & G HEALTH": {
              "ACCU-CLEAR PREGNANCY": "ACCU-CLEAR PREGNANCY",
              "CLEARBLUE DIGITAL OVULATION": "CLEARBLUE DIGITAL OVULATION",
              "CLEARBLUE DIGITAL PLUS": "CLEARBLUE DIGITAL PLUS",
              "CLEARBLUE DIGITAL PREGNANCY": "CLEARBLUE DIGITAL PREGNANCY",
              "CLEARBLUE EASY OVULATION COMBO": "CLEARBLUE EASY OVULATION COMBO",
              "CLEARBLUE FERTILITY MONITOR": "CLEARBLUE FERTILITY MONITOR",
              "CLEARBLUE PLUS PREGNANCY": "CLEARBLUE PLUS PREGNANCY",
              "FACT PLUS+ PREGNANCY": "FACT PLUS+ PREGNANCY"
            },
            "PERRIGO DIABETES CARE": {
              "BLOOD GLUCOSE TEST": "BLOOD GLUCOSE TEST",
              "CAREONE BLOOD GLUCOSE TEST": "CAREONE BLOOD GLUCOSE TEST",
              "CVS ADVANCED GLUCOSE TEST": "CVS ADVANCED GLUCOSE TEST",
              "GLUCOSE METER TEST": "GLUCOSE METER TEST",
              "GOODSENSE BLOOD GLUCOSE": "GOODSENSE BLOOD GLUCOSE",
              "KROGER BLOOD GLUCOSE TEST": "KROGER BLOOD GLUCOSE TEST",
              "KROGER PREMIUM GLUCOSE TEST": "KROGER PREMIUM GLUCOSE TEST",
              "MEIJER BLOOD GLUCOSE TEST": "MEIJER BLOOD GLUCOSE TEST",
              "MEIJER ESSENTIAL GLUCOSE TEST": "MEIJER ESSENTIAL GLUCOSE TEST",
              "MEIJER PREMIUM GLUCOSE TEST": "MEIJER PREMIUM GLUCOSE TEST",
              "PREMIUM BLOOD GLUCOSE TEST": "PREMIUM BLOOD GLUCOSE TEST",
              "REXALL BLOOD GLUCOSE TEST": "REXALL BLOOD GLUCOSE TEST",
              "SMART SENSE PREMIUM TEST": "SMART SENSE PREMIUM TEST",
              "SMART SENSE VALUE TEST": "SMART SENSE VALUE TEST",
              "TGT BLOOD GLUCOSE TEST": "TGT BLOOD GLUCOSE TEST"
            },
            "PERRIGO PHARMACEUTICALS": {
              "PREGNANCY TEST": "PREGNANCY TEST"
            },
            "PHARMA TECH SOLUTIONS": {
              "GENULTIMATE TEST": "GENULTIMATE TEST"
            },
            "PHARMACEUTICA NORTH AMERICA": {
              "ACTIVE-MEDICATED SPEC COLLECT": "ACTIVE-MEDICATED SPEC COLLECT"
            },
            "PHARMACO TECHNOLOGY": {
              "URINX MEDICATED SPEC COLLECT": "URINX MEDICATED SPEC COLLECT"
            },
            "PHARMATECH SOLUTIONS": {
              "GENSTRIP 50": "GENSTRIP 50"
            },
            "PHILOSYS": {
              "GMATE BLOOD GLUCOSE TEST": "GMATE BLOOD GLUCOSE TEST"
            },
            "POLYMER TECHNOLOGY SYSTEMS": {
              "BIOSCANNER GLUCOSE TEST": "BIOSCANNER GLUCOSE TEST",
              "CARDIO CHEK HDL": "CARDIO CHEK HDL",
              "CARDIO CHEK TRIGLYCERIDES": "CARDIO CHEK TRIGLYCERIDES",
              "PTS PANELS GLUCOSE TEST": "PTS PANELS GLUCOSE TEST",
              "PTS PANELS KETONE TEST": "PTS PANELS KETONE TEST"
            },
            "PRODIGY DIABETES CARE": {
              "PRODIGY NO CODING BLOOD GLUC": "PRODIGY NO CODING BLOOD GLUC"
            },
            "PROGRESSIVE HEALTH SUPPLY": {
              "SMARTEST BLOOD GLUCOSE TEST": "SMARTEST BLOOD GLUCOSE TEST"
            },
            "PSS WORLD MEDICAL": {
              "QUINTET AC BLOOD GLUCOSE TEST": "QUINTET AC BLOOD GLUCOSE TEST",
              "QUINTET BLOOD GLUCOSE TEST": "QUINTET BLOOD GLUCOSE TEST"
            },
            "PUBLIX SUPER MARKETS INC.": {
              "PX PREGNANCY TEST": "PX PREGNANCY TEST"
            },
            "RITE AID CORPORATION": {
              "RA OVULATION PREDICTOR": "RA OVULATION PREDICTOR",
              "RA PREGNANCY TEST DIGITAL": "RA PREGNANCY TEST DIGITAL",
              "RA PREGNANCY TEST EARLY RESULT": "RA PREGNANCY TEST EARLY RESULT",
              "RA PREGNANCY TEST KIT": "RA PREGNANCY TEST KIT",
              "RA TRUETEST TEST": "RA TRUETEST TEST"
            },
            "ROCHE DIAGNOSTICS": {
              "ACCU-CHEK ACTIVE": "ACCU-CHEK ACTIVE",
              "ACCU-CHEK AVIVA": "ACCU-CHEK AVIVA",
              "ACCU-CHEK AVIVA PLUS": "ACCU-CHEK AVIVA PLUS",
              "ACCU-CHEK COMPACT PLUS": "ACCU-CHEK COMPACT PLUS",
              "ACCU-CHEK COMPACT TEST DRUM": "ACCU-CHEK COMPACT TEST DRUM",
              "ACCU-CHEK GUIDE": "ACCU-CHEK GUIDE",
              "ACCU-CHEK SMARTVIEW": "ACCU-CHEK SMARTVIEW",
              "ACCUTREND CHOLESTEROL": "ACCUTREND CHOLESTEROL",
              "ACCUTREND GLUCOSE": "ACCUTREND GLUCOSE",
              "CHEMSTRIP 2": "CHEMSTRIP 2",
              "CHEMSTRIP K": "CHEMSTRIP K",
              "CHEMSTRIP MICRAL": "CHEMSTRIP MICRAL",
              "COAGUCHEK PT TEST": "COAGUCHEK PT TEST",
              "COAGUCHEK XS PT TEST": "COAGUCHEK XS PT TEST"
            },
            "SAM'S WEST": {
              "MM EASY TOUCH GLUCOSE": "MM EASY TOUCH GLUCOSE"
            },
            "SANOFI-AVENTIS U.S.": {
              "BG STAR TEST": "BG STAR TEST"
            },
            "SD BIOSENSOR": {
              "GLUCONAVII BLOOD GLUCOSE TEST": "GLUCONAVII BLOOD GLUCOSE TEST"
            },
            "SELECT BRAND": {
              "SB PREGNANCY TEST KIT": "SB PREGNANCY TEST KIT"
            },
            "SIEMENS HEALTHCARE DIAGNOSTICS": {
              "ALBUSTIX": "ALBUSTIX",
              "AZOSTIX": "AZOSTIX",
              "CHEK-STIX CONTROL": "CHEK-STIX CONTROL",
              "HEMA-CHEK": "HEMA-CHEK",
              "HEMASTIX": "HEMASTIX",
              "HEMATEST": "HEMATEST"
            },
            "SIMPLE DIAGNOSTICS": {
              "CLEVER CHEK AUTO-CODE TEST": "CLEVER CHEK AUTO-CODE TEST",
              "CLEVER CHEK AUTO-CODE VOICE": "CLEVER CHEK AUTO-CODE VOICE",
              "CLEVER CHEK TEST": "CLEVER CHEK TEST",
              "CLEVER CHOICE AUTO-CODE TEST": "CLEVER CHOICE AUTO-CODE TEST",
              "CLEVER CHOICE MICRO TEST": "CLEVER CHOICE MICRO TEST",
              "CLEVER CHOICE NO CODING": "CLEVER CHOICE NO CODING",
              "CLEVER CHOICE TALK SYSTEM": "CLEVER CHOICE TALK SYSTEM",
              "PHARMACIST CHOICE AUTOCODE": "PHARMACIST CHOICE AUTOCODE"
            },
            "SIRCLE LABORATORIES": {
              "PROFESSIONAL DNA COLLECTION": "PROFESSIONAL DNA COLLECTION"
            },
            "SMART METER": {
              "IGLUCOSE TEST STRIPS": "IGLUCOSE TEST STRIPS"
            },
            "SPECIALTY MEDICAL SUPPLIES": {
              "SURE EDGE TEST": "SURE EDGE TEST",
              "SURECHEK BLOOD GLUCOSE TEST": "SURECHEK BLOOD GLUCOSE TEST",
              "VOCAL POINT BLOOD GLUCOSE TEST": "VOCAL POINT BLOOD GLUCOSE TEST"
            },
            "STERLING KNIGHT PHARMACEUTICAL": {
              "PROFESSIONAL DNA COLLECTION": "PROFESSIONAL DNA COLLECTION"
            },
            "TELCARE": {
              "TELCARE BLOOD GLUCOSE TEST": "TELCARE BLOOD GLUCOSE TEST"
            },
            "TMIG": {
              "TOXICOLOGY SALIVA COLLECTION": "TOXICOLOGY SALIVA COLLECTION"
            },
            "TRIVIDIA HEALTH": {
              "KETONE TEST": "KETONE TEST",
              "LIBERTY TEST": "LIBERTY TEST",
              "TRUE METRIX BLOOD GLUCOSE TEST": "TRUE METRIX BLOOD GLUCOSE TEST",
              "TRUE METRIX PRO BLOOD GLUCOSE": "TRUE METRIX PRO BLOOD GLUCOSE",
              "TRUETEST TEST": "TRUETEST TEST",
              "TRUETRACK TEST": "TRUETRACK TEST"
            },
            "TRUE FIT RX": {
              "MEDICATED DNA COLLECTION": "MEDICATED DNA COLLECTION"
            },
            "UNIPATH DIAGNOSTIC": {
              "CLEARBLUE EASY FERTILITY": "CLEARBLUE EASY FERTILITY",
              "CLEARBLUE EASY OVULATION": "CLEARBLUE EASY OVULATION"
            },
            "UNISTRIP TECHNOLOGIES": {
              "UNISTRIP1 GENERIC": "UNISTRIP1 GENERIC"
            },
            "UPSPRING": {
              "MILKSCREEN FOR BREASTFEEDING": "MILKSCREEN FOR BREASTFEEDING"
            },
            "US DIAGNOSTICS": {
              "ACURA BLOOD GLUCOSE TEST": "ACURA BLOOD GLUCOSE TEST",
              "CONTROL AST": "CONTROL AST",
              "CONTROL TEST": "CONTROL TEST",
              "EASYGLUCO": "EASYGLUCO",
              "EASYGLUCO PLUS": "EASYGLUCO PLUS",
              "INFINITY BLOOD GLUCOSE TEST": "INFINITY BLOOD GLUCOSE TEST",
              "INFINITY VOICE": "INFINITY VOICE",
              "MAXIMA BLOOD GLUCOSE TEST": "MAXIMA BLOOD GLUCOSE TEST",
              "VERASENS BLOOD GLUCOSE TEST": "VERASENS BLOOD GLUCOSE TEST"
            },
            "VERTEX DIAGNOSTICS": {
              "ULTRATRAK PRO TEST": "ULTRATRAK PRO TEST",
              "ULTRATRAK ULTIMATE TEST": "ULTRATRAK ULTIMATE TEST"
            },
            "VIP INTERNATIONAL": {
              "EZ SMART BLOOD GLUCOSE TEST": "EZ SMART BLOOD GLUCOSE TEST",
              "EZ SMART PLUS GLUCOSE TEST": "EZ SMART PLUS GLUCOSE TEST"
            },
            "WAL-MART": {
              "EQ BLOOD GLUCOSE TEST": "EQ BLOOD GLUCOSE TEST",
              "EQ EARLY OVULATION TEST": "EQ EARLY OVULATION TEST",
              "EQ PREGNANCY TEST": "EQ PREGNANCY TEST",
              "EQ PREGNANCY TEST EARLY RESULT": "EQ PREGNANCY TEST EARLY RESULT",
              "RELION A1C": "RELION A1C",
              "RELION CONFIRM/MICRO TEST": "RELION CONFIRM/MICRO TEST",
              "RELION KETONE": "RELION KETONE",
              "RELION KETONE TEST": "RELION KETONE TEST",
              "RELION PRIME TEST": "RELION PRIME TEST",
              "RELION ULTIMA TEST": "RELION ULTIMA TEST",
              "ULTIMA TEST": "ULTIMA TEST"
            },
            "WALGREENS": {
              "A1C TEST AT-HOME": "A1C TEST AT-HOME",
              "BLOOD GLUCOSE TEST": "BLOOD GLUCOSE TEST",
              "DAILY OVULATION PREDICTOR": "DAILY OVULATION PREDICTOR",
              "DIGITAL PREGNANCY": "DIGITAL PREGNANCY",
              "EARLY PREGNANCY": "EARLY PREGNANCY",
              "KETONE TEST": "KETONE TEST",
              "ONE STEP OVULATION TEST": "ONE STEP OVULATION TEST",
              "ONE STEP PREGNANCY": "ONE STEP PREGNANCY",
              "ONE-STEP PREGNANCY": "ONE-STEP PREGNANCY",
              "OVULATION PREDICTOR": "OVULATION PREDICTOR",
              "OVULATION PREDICTOR ONE STEP": "OVULATION PREDICTOR ONE STEP",
              "TRUE FOCUS BLOOD GLUCOSE STRIP": "TRUE FOCUS BLOOD GLUCOSE STRIP",
              "TRUE METRIX BLOOD GLUCOSE TEST": "TRUE METRIX BLOOD GLUCOSE TEST",
              "TRUETEST TEST": "TRUETEST TEST",
              "TRUETRACK TEST": "TRUETRACK TEST"
            },
            "XPRESS MEDICAL SUPPLY": {
              "PHARMACIST CHOICE NO CODING": "PHARMACIST CHOICE NO CODING"
            }
          },
          "Infection Tests": {
            "ABBOTT DIAGNOSTICS SCARBOROUGH": {
              "BINAXNOW COVID-19 AG CARD": "BINAXNOW COVID-19 AG CARD",
              "ID NOW COVID-19": "ID NOW COVID-19",
              "ID NOW COVID-19 CONTROL": "ID NOW COVID-19 CONTROL"
            },
            "AMERICAN SCREENING CORP": {
              "REVEAL URINARY TRACT": "REVEAL URINARY TRACT"
            },
            "BTNX": {
              "RAPID RESPONSE COVID-19": "RAPID RESPONSE COVID-19"
            },
            "CEPHEID": {
              "XPERT XPRESS SARS-COV-2": "XPERT XPRESS SARS-COV-2"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "COVID-19 TESTING BY PHARMACIST": "COVID-19 TESTING BY PHARMACIST"
            },
            "ENOVACHEM MANUFACTURING": {
              "ACAT SUK TEST": "ACAT SUK TEST",
              "ACST KIT": "ACST KIT",
              "ACT KIT": "ACT KIT",
              "CAAT KIT": "CAAT KIT",
              "CAS KIT": "CAS KIT",
              "ST COVID 2020": "ST COVID 2020",
              "ST COVID-POD": "ST COVID-POD"
            },
            "HOME ACCESS HEALTH CORP.": {
              "HOME ACCESS EXPRESS HIV-1 TEST": "HOME ACCESS EXPRESS HIV-1 TEST",
              "HOME ACCESS HIV-1 TEST SYSTEM": "HOME ACCESS HIV-1 TEST SYSTEM"
            },
            "LAKE CONSUMER PRODUCTS": {
              "UTI HOME": "UTI HOME"
            },
            "LEADER BRAND PRODUCTS": {
              "URINARY TRACT INFECTION TEST": "URINARY TRACT INFECTION TEST"
            },
            "MYLAB BOX": {
              "MYLAB BOX COVID-19 TESTING": "MYLAB BOX COVID-19 TESTING"
            },
            "NCPDP EMERGENCY PREPAREDNESS": {
              "COVID-19 SPECIMEN COLLECTION": "COVID-19 SPECIMEN COLLECTION"
            },
            "QUIDEL": {
              "LYRA DIRECT SARS-COV-2 ASSAY": "LYRA DIRECT SARS-COV-2 ASSAY",
              "LYRA SARS-COV-2 ASSAY": "LYRA SARS-COV-2 ASSAY",
              "QUICKVUE + STREP A TEST": "QUICKVUE + STREP A TEST",
              "QUICKVUE DIPSTICK STREP A TEST": "QUICKVUE DIPSTICK STREP A TEST",
              "QUICKVUE IN-LINE STREP A TEST": "QUICKVUE IN-LINE STREP A TEST",
              "QUICKVUE INFLUENZA A+B TEST": "QUICKVUE INFLUENZA A+B TEST",
              "SOFIA INFLUENZA A+B FIA": "SOFIA INFLUENZA A+B FIA",
              "SOFIA SARS ANTIGEN FIA": "SOFIA SARS ANTIGEN FIA",
              "SOFIA STREP A FIA": "SOFIA STREP A FIA",
              "SOFIA STREP A+ FIA": "SOFIA STREP A+ FIA",
              "SOFIA2 SARS ANTIGEN FIA": "SOFIA2 SARS ANTIGEN FIA"
            },
            "RITE AID CORPORATION": {
              "RA URINARY TRACT INFECTION": "RA URINARY TRACT INFECTION",
              "RA VAGINAL INFECTION SCREENING": "RA VAGINAL INFECTION SCREENING"
            }
          },
          "Multiple Urine Tests": {
            "ASCENSIA DIABETES CARE": {
              "KETO-DIASTIX": "KETO-DIASTIX"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS KETONE CARE": "CVS KETONE CARE"
            },
            "ROCHE DIAGNOSTICS": {
              "CHEMSTRIP 10 MD": "CHEMSTRIP 10 MD",
              "CHEMSTRIP 10/SG": "CHEMSTRIP 10/SG",
              "CHEMSTRIP 2 GP": "CHEMSTRIP 2 GP",
              "CHEMSTRIP 5 OB": "CHEMSTRIP 5 OB",
              "CHEMSTRIP 7": "CHEMSTRIP 7",
              "CHEMSTRIP 9": "CHEMSTRIP 9",
              "CHEMSTRIP UGK": "CHEMSTRIP UGK"
            },
            "SIEMENS HEALTHCARE DIAGNOSTICS": {
              "COMBISTIX": "COMBISTIX",
              "HEMA-COMBISTIX": "HEMA-COMBISTIX",
              "LABSTIX": "LABSTIX",
              "MULTISTIX": "MULTISTIX",
              "MULTISTIX 10 SG": "MULTISTIX 10 SG",
              "MULTISTIX 5": "MULTISTIX 5",
              "MULTISTIX 7": "MULTISTIX 7",
              "MULTISTIX 8": "MULTISTIX 8",
              "MULTISTIX 9": "MULTISTIX 9",
              "MULTISTIX 9 SG": "MULTISTIX 9 SG",
              "URISTIX": "URISTIX",
              "URISTIX 4": "URISTIX 4"
            }
          }
        },
        "Miscellaneous Contrast Media": {
          "Miscellaneous Contrast Media": {
            "BAYER HEALTHCARE PHARMA": {
              "EOVIST": "EOVIST",
              "GADAVIST": "GADAVIST",
              "MAGNEVIST": "MAGNEVIST"
            },
            "BRACCO DIAGNOSTICS": {
              "LUMASON": "LUMASON",
              "MULTIHANCE": "MULTIHANCE",
              "PROHANCE": "PROHANCE"
            },
            "GE HEALTHCARE": {
              "CLARISCAN": "CLARISCAN",
              "OMNISCAN": "OMNISCAN",
              "OMNISCAN/SODIUM CHLORIDE": "OMNISCAN/SODIUM CHLORIDE",
              "OPTISON": "OPTISON"
            },
            "GUERBET": {
              "DOTAREM": "DOTAREM",
              "OPTIMARK": "OPTIMARK"
            },
            "LANTHEUS MEDICAL IMAGING": {
              "ABLAVAR": "ABLAVAR",
              "DEFINITY": "DEFINITY"
            },
            "NOVAPLUS/GE HEALTHCARE": {
              "OMNISCAN": "OMNISCAN"
            }
          }
        },
        "Radiographic Contrast Media": {
          "Radiographic Contrast Media - Barium": {
            "BRACCO DIAGNOSTICS": {
              "E-Z-CAT DRY": "E-Z-CAT DRY",
              "E-Z-DISK": "E-Z-DISK",
              "E-Z-DOSE": "E-Z-DOSE",
              "E-Z-HD": "E-Z-HD",
              "E-Z-PAQUE": "E-Z-PAQUE",
              "E-Z-PASTE": "E-Z-PASTE",
              "ENTERO VU": "ENTERO VU",
              "LIQUID E-Z-PAQUE": "LIQUID E-Z-PAQUE",
              "LIQUID POLIBAR PLUS": "LIQUID POLIBAR PLUS",
              "NEULUMEX": "NEULUMEX",
              "READI-CAT 2": "READI-CAT 2",
              "TAGITOL V": "TAGITOL V",
              "VARIBAR HONEY": "VARIBAR HONEY",
              "VARIBAR NECTAR": "VARIBAR NECTAR",
              "VARIBAR PUDDING": "VARIBAR PUDDING",
              "VARIBAR THIN HONEY": "VARIBAR THIN HONEY",
              "VARIBAR THIN LIQUID": "VARIBAR THIN LIQUID",
              "VOLUMEN": "VOLUMEN"
            },
            "FAGRON": {
              "BARIUM SULFATE": "BARIUM SULFATE"
            },
            "HUMCO": {
              "BARIUM SULFATE": "BARIUM SULFATE"
            },
            "KONSYL PHARMACEUTICAL": {
              "SITZMARKS": "SITZMARKS",
              "SITZMARKS COMBO PACKAGE": "SITZMARKS COMBO PACKAGE",
              "SITZMARKS RADIOPAQUE MARKERS": "SITZMARKS RADIOPAQUE MARKERS"
            },
            "PCCA": {
              "BARIUM SULFATE": "BARIUM SULFATE"
            },
            "SPECTRUM": {
              "BARIUM SULFATE": "BARIUM SULFATE"
            }
          },
          "Radiographic Contrast Media - Iodinated": {
            "BAYER HEALTHCARE PHARMA": {
              "ULTRAVIST": "ULTRAVIST"
            },
            "BRACCO DIAGNOSTICS": {
              "CYSTOGRAFIN": "CYSTOGRAFIN",
              "CYSTOGRAFIN-DILUTE": "CYSTOGRAFIN-DILUTE",
              "GASTROGRAFIN": "GASTROGRAFIN",
              "ISOVUE-200": "ISOVUE-200",
              "ISOVUE-250": "ISOVUE-250",
              "ISOVUE-300": "ISOVUE-300",
              "ISOVUE-370": "ISOVUE-370",
              "ISOVUE-M 200": "ISOVUE-M 200",
              "ISOVUE-M 300": "ISOVUE-M 300",
              "SINOGRAFIN": "SINOGRAFIN"
            },
            "FAGRON COMPOUNDING SERVICES": {
              "IOHEXOL": "IOHEXOL"
            },
            "GE HEALTHCARE": {
              "OMNIPAQUE": "OMNIPAQUE",
              "VISIPAQUE": "VISIPAQUE"
            },
            "GUERBET": {
              "CONRAY": "CONRAY",
              "CONRAY 43": "CONRAY 43",
              "CYSTO-CONRAY II": "CYSTO-CONRAY II",
              "LIPIODOL": "LIPIODOL",
              "MD-GASTROVIEW": "MD-GASTROVIEW",
              "OPTIRAY 240": "OPTIRAY 240",
              "OPTIRAY 300": "OPTIRAY 300",
              "OPTIRAY 320": "OPTIRAY 320",
              "OPTIRAY 350": "OPTIRAY 350"
            },
            "MALLINCKRODT": {
              "CONRAY 30": "CONRAY 30",
              "MD-76 R": "MD-76 R"
            },
            "NOVAPLUS/GE HEALTHCARE": {
              "OMNIPAQUE": "OMNIPAQUE",
              "VISIPAQUE": "VISIPAQUE"
            },
            "QUESTCOR": {
              "GLOFIL-125": "GLOFIL-125"
            }
          }
        }
      },
      "DIETARY PRODUCTS/DIETARY MANAGEMENT PRODUCTS": {
        "Dietary Management Products": {
          "Dietary Management Product Combinations": {
            "A-S MEDICATION SOLUTIONS": {
              "CEREFOLIN NAC": "CEREFOLIN NAC"
            },
            "AJINOMOTO CAMBROOKE": {
              "PRO-CRITIC": "PRO-CRITIC"
            },
            "ALFASIGMA USA": {
              "CEREFOLIN": "CEREFOLIN",
              "CEREFOLIN NAC": "CEREFOLIN NAC",
              "DEPLIN 15": "DEPLIN 15",
              "DEPLIN 7.5": "DEPLIN 7.5",
              "FOLTX": "FOLTX",
              "METANX": "METANX"
            },
            "ALLEGIS PHARMACEUTICALS": {
              "DERMANIC": "DERMANIC"
            },
            "AMERICAN HEALTH PACKAGING": {
              "FOLBIC": "FOLBIC"
            },
            "ARTESA LABS": {
              "NICAPRIN": "NICAPRIN"
            },
            "ASTAVITA": {
              "ASTAMED MYO": "ASTAMED MYO"
            },
            "AVENTURA PHARMACEUTICALS": {
              "ELFOLATE PLUS": "ELFOLATE PLUS"
            },
            "AVKARE": {
              "AV-VITE FB FORTE": "AV-VITE FB FORTE",
              "L-METHYL-MC NAC": "L-METHYL-MC NAC",
              "L-METHYLFOLATE-B6-B12": "L-METHYLFOLATE-B6-B12"
            },
            "BASIEM": {
              "OLLIZAC": "OLLIZAC",
              "SULFZIX": "SULFZIX",
              "XIZFLUS": "XIZFLUS",
              "XYZBAC": "XYZBAC"
            },
            "BONAFIDE INNOVATIONS": {
              "L-METHYLFOLATE-ALGAE": "L-METHYLFOLATE-ALGAE",
              "L-METHYLFOLATE-ALGAE-B12-B6": "L-METHYLFOLATE-ALGAE-B12-B6",
              "METHYLFOL-ALGAE-B12-ACETYLCYST": "METHYLFOL-ALGAE-B12-ACETYLCYST"
            },
            "BRECKENRIDGE": {
              "FOLBIC": "FOLBIC",
              "FOLBIC RF": "FOLBIC RF",
              "FOLTANX": "FOLTANX",
              "FOLTANX RF": "FOLTANX RF",
              "L-METHYLFOLATE FORTE": "L-METHYLFOLATE FORTE",
              "METAFOLBIC": "METAFOLBIC",
              "METAFOLBIC PLUS": "METAFOLBIC PLUS",
              "METAFOLBIC PLUS RF": "METAFOLBIC PLUS RF"
            },
            "CENTURION LABS": {
              "PURALOR CI": "PURALOR CI"
            },
            "CERECIN": {
              "AXONA": "AXONA"
            },
            "EMISPHERE TECHNOLOGIES": {
              "ELIGEN B12": "ELIGEN B12"
            },
            "GLYCOM": {
              "GLYGEST": "GLYGEST"
            },
            "IM HEALTHSCIENCE": {
              "FDGARD": "FDGARD"
            },
            "INNOVATIONS 4 HEALTH": {
              "MEDACTIV": "MEDACTIV"
            },
            "JAYMAC PHARMACEUTICAL LLC": {
              "ENLYTE": "ENLYTE"
            },
            "LANATA": {
              "PROLEVA": "PROLEVA"
            },
            "LANFAM": {
              "PROLEEVA": "PROLEEVA"
            },
            "LEVINS PHARMACEUTICALS": {
              "TOBAKIENT": "TOBAKIENT"
            },
            "MAGNA PHARMACEUTICALS, INC": {
              "NUFOLA": "NUFOLA"
            },
            "MEDICAP LABORATORIES": {
              "MEDI-10": "MEDI-10"
            },
            "METHOD PHARMACEUTICALS": {
              "L-METHYLFOLATE-ALGAE": "L-METHYLFOLATE-ALGAE",
              "L-METHYLFOLATE-ALGAE-B12-B6": "L-METHYLFOLATE-ALGAE-B12-B6",
              "METHYLFOL-ALGAE-B12-ACETYLCYST": "METHYLFOL-ALGAE-B12-ACETYLCYST"
            },
            "MISEMER": {
              "ZYVEXOL": "ZYVEXOL"
            },
            "NECESE": {
              "NEOKE BCAA4": "NEOKE BCAA4"
            },
            "NIVAGEN PHARMACEUTICALS": {
              "NIVA-FOL": "NIVA-FOL"
            },
            "OCUSOFT": {
              "TEARS AGAIN HYDRATE": "TEARS AGAIN HYDRATE",
              "ZYTAZE": "ZYTAZE"
            },
            "OPTIMEDRX": {
              "PROTEOLIN": "PROTEOLIN",
              "PROTEOLIN DS": "PROTEOLIN DS"
            },
            "PHARMACEUTIX": {
              "LIPICHOL 540": "LIPICHOL 540",
              "PODIAPN": "PODIAPN"
            },
            "PHYSICIAN THERAPEUTICS": {
              "APPTRIM": "APPTRIM",
              "APPTRIM-D": "APPTRIM-D",
              "GABADONE": "GABADONE",
              "HYPERTENSA": "HYPERTENSA",
              "LISTER-V": "LISTER-V",
              "PERCURA": "PERCURA",
              "PULMONA": "PULMONA",
              "SENTRA AM": "SENTRA AM",
              "SENTRA PM": "SENTRA PM",
              "THERAMINE": "THERAMINE",
              "THERAMINE PLUS": "THERAMINE PLUS",
              "TREPADONE": "TREPADONE"
            },
            "PREFERRED PHARMACEUTICALS": {
              "SENTRA PM": "SENTRA PM"
            },
            "PRIMUS PHARMACEUTICALS": {
              "FOSTEUM": "FOSTEUM",
              "FOSTEUM PLUS": "FOSTEUM PLUS",
              "LIMBREL250": "LIMBREL250",
              "LIMBREL500": "LIMBREL500",
              "RHEUMATE": "RHEUMATE",
              "VASCULERA": "VASCULERA"
            },
            "PRISMIC PHARMACEUTICALS": {
              "NEUREPA": "NEUREPA"
            },
            "REDMONT PHARMACEUTICALS": {
              "LORMATE": "LORMATE"
            },
            "RESPA PHARMACEUTICALS, INC.": {
              "VITA-RESPA": "VITA-RESPA"
            },
            "SIRCLE LABORATORIES": {
              "FOVEX": "FOVEX",
              "MACUTEK": "MACUTEK"
            },
            "SJ PHARMACEUTICALS": {
              "CARDIOTEK RX": "CARDIOTEK RX"
            },
            "SOLACE NUTRITION": {
              "CHOLEXMAX": "CHOLEXMAX",
              "CHOLEXTRA T/F": "CHOLEXTRA T/F",
              "LDL CARE": "LDL CARE",
              "NEOKE BCAA4": "NEOKE BCAA4",
              "NEOKE BHB": "NEOKE BHB",
              "NEOPHE": "NEOPHE",
              "VB6 P5P": "VB6 P5P"
            },
            "SOLUBIOMIX LLC": {
              "2-FUCOSYLLACT-LACTO-N-NEOTETRA": "2-FUCOSYLLACT-LACTO-N-NEOTETRA",
              "ALGESIS": "ALGESIS",
              "HYPNOSOM": "HYPNOSOM",
              "MEBOLIC": "MEBOLIC",
              "SODIUM POLYSULTHIONATE-FA": "SODIUM POLYSULTHIONATE-FA"
            },
            "STERLING KNIGHT PHARMACEUTICAL": {
              "LEXAZIN": "LEXAZIN",
              "MACUZIN": "MACUZIN",
              "METHAVER": "METHAVER",
              "METHAZEL": "METHAZEL",
              "NICAZYME": "NICAZYME",
              "OMNIQUIN": "OMNIQUIN",
              "OMNIVEX": "OMNIVEX",
              "RIBOZEL": "RIBOZEL",
              "ZAVITROL": "ZAVITROL"
            },
            "TMIG": {
              "T-SUPPORT MAX": "T-SUPPORT MAX",
              "ZYVIT": "ZYVIT"
            },
            "TRIGEN LABORATORIES": {
              "TL-ICARE": "TL-ICARE"
            },
            "UNIT DOSE SERVICES": {
              "GABADONE": "GABADONE",
              "SENTRA AM": "SENTRA AM",
              "SENTRA PM": "SENTRA PM",
              "TREPADONE": "TREPADONE"
            },
            "VAYA PHARMA": {
              "VAYACOG": "VAYACOG",
              "VAYARIN": "VAYARIN",
              "VAYARIN PLUS": "VAYARIN PLUS",
              "VAYAROL": "VAYAROL"
            },
            "VIRTUS PHARMACEUTICALS": {
              "VP-GSTN": "VP-GSTN",
              "VP-PRECIP": "VP-PRECIP"
            },
            "VIRTUS PHARMACEUTICALS OPCO": {
              "L-METHYL-MC": "L-METHYL-MC",
              "L-METHYL-MC NAC": "L-METHYL-MC NAC",
              "L-METHYLFOLATE CA ME-CBL NAC": "L-METHYLFOLATE CA ME-CBL NAC",
              "L-METHYLFOLATE-ALGAE": "L-METHYLFOLATE-ALGAE",
              "L-METHYLFOLATE-ALGAE-B12-B6": "L-METHYLFOLATE-ALGAE-B12-B6",
              "L-METHYLFOLATE-B6-B12": "L-METHYLFOLATE-B6-B12",
              "VIRT-VITE FORTE": "VIRT-VITE FORTE",
              "VP-GSTN": "VP-GSTN",
              "VP-PRECIP": "VP-PRECIP"
            },
            "VITAFLO": {
              "TYR SPHERE 20": "TYR SPHERE 20"
            },
            "WESTMINSTER PHARMACEUTICALS": {
              "WESTAB MAX": "WESTAB MAX"
            },
            "ZERXIS": {
              "L-METHYLFOLATE CA ME-CBL NAC": "L-METHYLFOLATE CA ME-CBL NAC",
              "L-METHYLFOLATE FORMULA 15": "L-METHYLFOLATE FORMULA 15",
              "L-METHYLFOLATE FORMULA 7.5": "L-METHYLFOLATE FORMULA 7.5",
              "L-METHYLFOLATE-ALGAE-B12-B6": "L-METHYLFOLATE-ALGAE-B12-B6",
              "L-METHYLFOLATE-B6-B12": "L-METHYLFOLATE-B6-B12",
              "LUKAID GLA": "LUKAID GLA"
            }
          },
          "Dietary Management Products": {
            "A-S MEDICATION SOLUTIONS": {
              "LIMBREL": "LIMBREL"
            },
            "AVENTURA PHARMACEUTICALS": {
              "ELFOLATE": "ELFOLATE"
            },
            "AVION PHARMACEUTICALS": {
              "FALESSA": "FALESSA"
            },
            "BRECKENRIDGE": {
              "L-METHYLFOLATE": "L-METHYLFOLATE"
            },
            "BRYANT RANCH PREPACK": {
              "L-METHYLFOLATE CALCIUM": "L-METHYLFOLATE CALCIUM"
            },
            "ENTERA HEALTH": {
              "ENTERAGAM": "ENTERAGAM"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "LIMBREL": "LIMBREL"
            },
            "HALL BIOSCIENCE": {
              "AVAILNEX": "AVAILNEX"
            },
            "JARROW FORMULAS": {
              "METHYLFOLATE": "METHYLFOLATE"
            },
            "MAGNA PHARMACEUTICALS, INC": {
              "DENOVO": "DENOVO"
            },
            "NECESE": {
              "GALAXTRA": "GALAXTRA"
            },
            "NEPHCENTRIC": {
              "URE-NA": "URE-NA"
            },
            "PHARMACEUTIX": {
              "XAQUIL XR": "XAQUIL XR"
            },
            "PIVOTAL THERAPEUTICS": {
              "VASCAZEN": "VASCAZEN"
            },
            "PRIMUS PHARMACEUTICALS": {
              "LIMBREL": "LIMBREL"
            },
            "QUALITY CARE": {
              "LIMBREL": "LIMBREL"
            },
            "SOLACE NUTRITION": {
              "CYTOSE": "CYTOSE",
              "GALAXTRA": "GALAXTRA"
            },
            "STERLING KNIGHT PHARMACEUTICAL": {
              "LUVIRA": "LUVIRA"
            },
            "THORNE RESEARCH": {
              "5-MTHF": "5-MTHF"
            },
            "VIRTUS PHARMACEUTICALS OPCO": {
              "L-METHYLFOLATE CALCIUM": "L-METHYLFOLATE CALCIUM"
            }
          }
        },
        "Infant Foods": {
          "Infant Foods": {
            "ABBOTT NUTRITION": {
              "ALIMENTUM": "ALIMENTUM",
              "CALCILO XD": "CALCILO XD",
              "ISOMIL 2": "ISOMIL 2",
              "ISOMIL ADVANCE SOY FORMULA-FE": "ISOMIL ADVANCE SOY FORMULA-FE",
              "ISOMIL DF": "ISOMIL DF",
              "ISOMIL SF/IRON": "ISOMIL SF/IRON",
              "ISOMIL SOY FORMULA/IRON": "ISOMIL SOY FORMULA/IRON",
              "ISOMIL SOY/IRON": "ISOMIL SOY/IRON",
              "ISOMIL/IRON": "ISOMIL/IRON",
              "NEOSURE ADVANCE": "NEOSURE ADVANCE",
              "PROTEIN FORTIFIER": "PROTEIN FORTIFIER",
              "RCF": "RCF",
              "RCF LOW-IRON": "RCF LOW-IRON",
              "SIMILAC": "SIMILAC",
              "SIMILAC 2 ADVANCE": "SIMILAC 2 ADVANCE",
              "SIMILAC 2-IRON": "SIMILAC 2-IRON",
              "SIMILAC ADVANCE COMPLETE": "SIMILAC ADVANCE COMPLETE",
              "SIMILAC ADVANCE EARLY SHIELD": "SIMILAC ADVANCE EARLY SHIELD",
              "SIMILAC ADVANCE KOSHER": "SIMILAC ADVANCE KOSHER",
              "SIMILAC ADVANCE LAMEHADRIN": "SIMILAC ADVANCE LAMEHADRIN",
              "SIMILAC ADVANCE NON-GMO": "SIMILAC ADVANCE NON-GMO",
              "SIMILAC ADVANCE ON-THE-GO": "SIMILAC ADVANCE ON-THE-GO",
              "SIMILAC ADVANCE ORGANIC": "SIMILAC ADVANCE ORGANIC",
              "SIMILAC ADVANCE-IRON": "SIMILAC ADVANCE-IRON",
              "SIMILAC ADVANCE/IRON": "SIMILAC ADVANCE/IRON",
              "SIMILAC ALIMENTUM ADVANCE-IRON": "SIMILAC ALIMENTUM ADVANCE-IRON",
              "SIMILAC ALIMENTUM-IRON": "SIMILAC ALIMENTUM-IRON",
              "SIMILAC EXPERT CARE ALIMENTUM": "SIMILAC EXPERT CARE ALIMENTUM",
              "SIMILAC EXPERT CARE DIARRHEA": "SIMILAC EXPERT CARE DIARRHEA",
              "SIMILAC EXPERT CARE NEOSURE/FE": "SIMILAC EXPERT CARE NEOSURE/FE",
              "SIMILAC FOR SPIT-UP": "SIMILAC FOR SPIT-UP",
              "SIMILAC FOR SUPPLEMENTATION": "SIMILAC FOR SUPPLEMENTATION",
              "SIMILAC GO & GROW EARLY SHIELD": "SIMILAC GO & GROW EARLY SHIELD",
              "SIMILAC GO & GROW HMO": "SIMILAC GO & GROW HMO",
              "SIMILAC GO & GROW NON-GMO": "SIMILAC GO & GROW NON-GMO",
              "SIMILAC GO & GROW OPTIGRO": "SIMILAC GO & GROW OPTIGRO",
              "SIMILAC GO & GROW TODDLER": "SIMILAC GO & GROW TODDLER",
              "SIMILAC HUMAN MILK FORTIFIER": "SIMILAC HUMAN MILK FORTIFIER",
              "SIMILAC ISOMIL ADVANCE": "SIMILAC ISOMIL ADVANCE",
              "SIMILAC LACTOSE FREE": "SIMILAC LACTOSE FREE",
              "SIMILAC LACTOSE FREE ADVANCE": "SIMILAC LACTOSE FREE ADVANCE",
              "SIMILAC LOW-IRON": "SIMILAC LOW-IRON",
              "SIMILAC NATURAL CARE": "SIMILAC NATURAL CARE",
              "SIMILAC NEOSURE": "SIMILAC NEOSURE",
              "SIMILAC NEOSURE ADVANCE/IRON": "SIMILAC NEOSURE ADVANCE/IRON",
              "SIMILAC NEOSURE OPTIGRO": "SIMILAC NEOSURE OPTIGRO",
              "SIMILAC ORGANIC/A2 MILK/IRON": "SIMILAC ORGANIC/A2 MILK/IRON",
              "SIMILAC ORGANIC/IRON": "SIMILAC ORGANIC/IRON",
              "SIMILAC PM": "SIMILAC PM",
              "SIMILAC PRO-ADVANCE OPTIGRO": "SIMILAC PRO-ADVANCE OPTIGRO",
              "SIMILAC PRO-ADVANCE WITH IRON": "SIMILAC PRO-ADVANCE WITH IRON",
              "SIMILAC PRO-SENSITIVE": "SIMILAC PRO-SENSITIVE",
              "SIMILAC PRO-SENSITIVE OPTIGRO": "SIMILAC PRO-SENSITIVE OPTIGRO",
              "SIMILAC PRO-TOTAL COMFORT": "SIMILAC PRO-TOTAL COMFORT",
              "SIMILAC PURE BLISS": "SIMILAC PURE BLISS",
              "SIMILAC PURE BLISS/IRON": "SIMILAC PURE BLISS/IRON",
              "SIMILAC SENSITIVE": "SIMILAC SENSITIVE",
              "SIMILAC SENSITIVE EARLY SHIELD": "SIMILAC SENSITIVE EARLY SHIELD",
              "SIMILAC SENSITIVE FUSSINESS": "SIMILAC SENSITIVE FUSSINESS",
              "SIMILAC SENSITIVE NON-GMO": "SIMILAC SENSITIVE NON-GMO",
              "SIMILAC SENSITIVE OPTIGRO": "SIMILAC SENSITIVE OPTIGRO",
              "SIMILAC SENSITIVE SPIT-UP": "SIMILAC SENSITIVE SPIT-UP",
              "SIMILAC SOY ISOMIL": "SIMILAC SOY ISOMIL",
              "SIMILAC SPECIAL CARE": "SIMILAC SPECIAL CARE",
              "SIMILAC SPECIAL CARE PREMATURE": "SIMILAC SPECIAL CARE PREMATURE",
              "SIMILAC SPECIAL CARE/IRON": "SIMILAC SPECIAL CARE/IRON",
              "SIMILAC SPECIAL CARE/LOW IRON": "SIMILAC SPECIAL CARE/LOW IRON",
              "SIMILAC TOTAL COMFORT": "SIMILAC TOTAL COMFORT",
              "SIMILAC/IRON": "SIMILAC/IRON"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS ADVANTAGE/IRON": "CVS ADVANTAGE/IRON",
              "CVS GENTLE INFANT FORMULA/IRON": "CVS GENTLE INFANT FORMULA/IRON",
              "CVS INFANT FORMULA/IRON": "CVS INFANT FORMULA/IRON",
              "CVS SENSITIVITY/IRON": "CVS SENSITIVITY/IRON",
              "CVS TENDER/IRON": "CVS TENDER/IRON",
              "CVS TODDLER & INFANT/IRON": "CVS TODDLER & INFANT/IRON"
            },
            "M J NUTR": {
              "ENFAGROW NEUROPRO TODDLER": "ENFAGROW NEUROPRO TODDLER",
              "ENFAGROW NEXT STEP": "ENFAGROW NEXT STEP",
              "ENFAGROW PREMIUM LIPIL": "ENFAGROW PREMIUM LIPIL",
              "ENFAGROW PREMIUM NEXT STEP": "ENFAGROW PREMIUM NEXT STEP",
              "ENFAGROW PREMIUM OLDER TODDLER": "ENFAGROW PREMIUM OLDER TODDLER",
              "ENFAGROW PREMIUM TODDLER": "ENFAGROW PREMIUM TODDLER",
              "ENFAGROW TODDLER GENTLEASE": "ENFAGROW TODDLER GENTLEASE",
              "ENFAGROW TODDLER SOY": "ENFAGROW TODDLER SOY",
              "ENFAGROW TODDLER TRANSITIONS": "ENFAGROW TODDLER TRANSITIONS",
              "ENFAMIL 24": "ENFAMIL 24",
              "ENFAMIL AR LIPIL": "ENFAMIL AR LIPIL",
              "ENFAMIL AR SPIT-UP": "ENFAMIL AR SPIT-UP",
              "ENFAMIL ENFACARE": "ENFAMIL ENFACARE",
              "ENFAMIL ENFACARE LIPIL": "ENFAMIL ENFACARE LIPIL",
              "ENFAMIL ENSPIRE GENTLEASE": "ENFAMIL ENSPIRE GENTLEASE",
              "ENFAMIL ENSPIRE/IRON": "ENFAMIL ENSPIRE/IRON",
              "ENFAMIL FOR SUPPLEMENTING": "ENFAMIL FOR SUPPLEMENTING",
              "ENFAMIL GENTLEASE": "ENFAMIL GENTLEASE",
              "ENFAMIL GENTLEASE LIPIL": "ENFAMIL GENTLEASE LIPIL",
              "ENFAMIL HUMAN MILK FORTIFIER": "ENFAMIL HUMAN MILK FORTIFIER",
              "ENFAMIL INFANT": "ENFAMIL INFANT",
              "ENFAMIL INFANT NON GMO": "ENFAMIL INFANT NON GMO",
              "ENFAMIL LACTOFREE LIPIL": "ENFAMIL LACTOFREE LIPIL",
              "ENFAMIL LIPIL ENFACARE": "ENFAMIL LIPIL ENFACARE",
              "ENFAMIL LIPIL/IRON": "ENFAMIL LIPIL/IRON",
              "ENFAMIL LIPIL/IRON PREMATURE": "ENFAMIL LIPIL/IRON PREMATURE",
              "ENFAMIL NEUROPRO ENFACARE": "ENFAMIL NEUROPRO ENFACARE",
              "ENFAMIL NEUROPRO GENTLEASE": "ENFAMIL NEUROPRO GENTLEASE",
              "ENFAMIL NEUROPRO INFANT": "ENFAMIL NEUROPRO INFANT",
              "ENFAMIL NEUROPRO SENSITIVE": "ENFAMIL NEUROPRO SENSITIVE",
              "ENFAMIL NEWBORN NON GMO": "ENFAMIL NEWBORN NON GMO",
              "ENFAMIL NUTRAMIGEN": "ENFAMIL NUTRAMIGEN",
              "ENFAMIL NUTRAMIGEN LIPIL": "ENFAMIL NUTRAMIGEN LIPIL",
              "ENFAMIL NUTRAMIGEN TOD/ENF LGG": "ENFAMIL NUTRAMIGEN TOD/ENF LGG",
              "ENFAMIL PREGESTIMIL LIPIL": "ENFAMIL PREGESTIMIL LIPIL",
              "ENFAMIL PREMATURE": "ENFAMIL PREMATURE",
              "ENFAMIL PREMATURE LIPIL": "ENFAMIL PREMATURE LIPIL",
              "ENFAMIL PREMIUM INFANT": "ENFAMIL PREMIUM INFANT",
              "ENFAMIL PREMIUM LIPIL": "ENFAMIL PREMIUM LIPIL",
              "ENFAMIL PREMIUM NEWBORN": "ENFAMIL PREMIUM NEWBORN",
              "ENFAMIL PROSOBEE LIPIL": "ENFAMIL PROSOBEE LIPIL",
              "ENFAMIL PROSOBEE SOY": "ENFAMIL PROSOBEE SOY",
              "ENFAMIL PROSOBEE/SENSITIVE": "ENFAMIL PROSOBEE/SENSITIVE",
              "ENFAMIL REGULINE-IRON": "ENFAMIL REGULINE-IRON",
              "ENFAMIL SOY PROSOBEE": "ENFAMIL SOY PROSOBEE",
              "ENFAPORT": "ENFAPORT",
              "PHENYL-FREE 1": "PHENYL-FREE 1",
              "PREGESTIMIL": "PREGESTIMIL",
              "PURAMINO DHA/ARA": "PURAMINO DHA/ARA",
              "PURAMINO JR": "PURAMINO JR",
              "PURAMINO TODDLER": "PURAMINO TODDLER",
              "WATER ORAL": "WATER ORAL"
            },
            "MEAD JOHNSON": {
              "ENFAMIL PREMIUM NEWBORN": "ENFAMIL PREMIUM NEWBORN"
            },
            "NATURE'S ONE": {
              "BABYS ONLY ORGANIC/DAIRY": "BABYS ONLY ORGANIC/DAIRY",
              "BABYS ONLY ORGANIC/DHA & ARA": "BABYS ONLY ORGANIC/DHA & ARA",
              "BABYS ONLY ORGANIC/LACTOS FREE": "BABYS ONLY ORGANIC/LACTOS FREE",
              "BABYS ONLY ORGANIC/SOY": "BABYS ONLY ORGANIC/SOY"
            },
            "NESTLE HEALTHCARE NUTRITION": {
              "ALFAMINO INFANT": "ALFAMINO INFANT",
              "ALFAMINO JUNIOR": "ALFAMINO JUNIOR",
              "ALSOY SOY FORMULA": "ALSOY SOY FORMULA",
              "FOLLOW-UP": "FOLLOW-UP",
              "FOLLOW-UP SOY": "FOLLOW-UP SOY",
              "FOLLOW-UP/FE": "FOLLOW-UP/FE",
              "GERBER EXTENSIVE HA": "GERBER EXTENSIVE HA",
              "GERBER GOOD START GENTLE": "GERBER GOOD START GENTLE",
              "GERBER GOOD START GENTLE 2": "GERBER GOOD START GENTLE 2",
              "GERBER GOOD START GENTLEPRO 2": "GERBER GOOD START GENTLEPRO 2",
              "GERBER GOOD START GENTLEPRO/FE": "GERBER GOOD START GENTLEPRO/FE",
              "GERBER GOOD START GROW 3": "GERBER GOOD START GROW 3",
              "GERBER GOOD START NOURISH": "GERBER GOOD START NOURISH",
              "GERBER GOOD START PREMATURE": "GERBER GOOD START PREMATURE",
              "GERBER GOOD START PROTECT/IRON": "GERBER GOOD START PROTECT/IRON",
              "GERBER GOOD START SOOTHE": "GERBER GOOD START SOOTHE",
              "GERBER GOOD START SOOTHE 1": "GERBER GOOD START SOOTHE 1",
              "GERBER GOOD START SOOTHE 2": "GERBER GOOD START SOOTHE 2",
              "GERBER GOOD START SOOTHEPRO/FE": "GERBER GOOD START SOOTHEPRO/FE",
              "GERBER GOOD START SOY": "GERBER GOOD START SOY",
              "GERBER GOOD START SOY/IRON": "GERBER GOOD START SOY/IRON",
              "GERBER GRADUATES GENTLE/IRON": "GERBER GRADUATES GENTLE/IRON",
              "GERBER GRADUATES PROTECT/IRON": "GERBER GRADUATES PROTECT/IRON",
              "GERBER GRADUATES SOOTHE": "GERBER GRADUATES SOOTHE",
              "GERBER GRADUATES SOY/IRON": "GERBER GRADUATES SOY/IRON",
              "GERBER NATURA STAGE 1": "GERBER NATURA STAGE 1",
              "GERBER NATURA STAGE 2": "GERBER NATURA STAGE 2",
              "GERBER NATURA STAGE 3": "GERBER NATURA STAGE 3",
              "GOOD START": "GOOD START",
              "GOOD START 2 ESSENTIALS SOY/FE": "GOOD START 2 ESSENTIALS SOY/FE",
              "GOOD START 2 ESSENTIALS/IRON": "GOOD START 2 ESSENTIALS/IRON",
              "GOOD START 2 SUPREME/IRON": "GOOD START 2 SUPREME/IRON",
              "GOOD START ESSENTIALS SOY/IRON": "GOOD START ESSENTIALS SOY/IRON",
              "GOOD START ESSENTIALS/IRON": "GOOD START ESSENTIALS/IRON",
              "GOOD START GENTLE PLUS": "GOOD START GENTLE PLUS",
              "GOOD START NATURAL CULTURES": "GOOD START NATURAL CULTURES",
              "GOOD START SOY PLUS 2": "GOOD START SOY PLUS 2",
              "GOOD START SUPREME SOY/IRON": "GOOD START SUPREME SOY/IRON",
              "GOOD START SUPREME/IRON": "GOOD START SUPREME/IRON",
              "GOOD START/FE": "GOOD START/FE",
              "NAN": "NAN"
            },
            "NUTRICIA NORTH AMERICA": {
              "MSUD ANALOG": "MSUD ANALOG",
              "NEOCATE SYNEO INFANT": "NEOCATE SYNEO INFANT",
              "PERIFLEX INFANT": "PERIFLEX INFANT",
              "SOD ANAMIX EARLY YEARS": "SOD ANAMIX EARLY YEARS",
              "XLEU ANALOG": "XLEU ANALOG",
              "XLYS XTRP ANALOG": "XLYS XTRP ANALOG",
              "XMET ANALOG": "XMET ANALOG",
              "XMTVI ANALOG": "XMTVI ANALOG",
              "XPHE-XTYR ANALOG": "XPHE-XTYR ANALOG",
              "XPTM ANALOG": "XPTM ANALOG"
            },
            "PBM PHARMACEUTICALS": {
              "BRIGHT BEGINNINGS SOY": "BRIGHT BEGINNINGS SOY"
            },
            "RITE AID CORPORATION": {
              "RA GENTLE INFANT FORMULA/IRON": "RA GENTLE INFANT FORMULA/IRON",
              "RA IMMUNE SUPPORT FORMULA/IRON": "RA IMMUNE SUPPORT FORMULA/IRON",
              "RA INFANT FORMULA/IRON": "RA INFANT FORMULA/IRON",
              "RA MILK BASE INFANT FORMULA/FE": "RA MILK BASE INFANT FORMULA/FE",
              "RA SOY INFANT FORMULA/IRON": "RA SOY INFANT FORMULA/IRON",
              "RA TUGABOOS INFANT FORMULA/FE": "RA TUGABOOS INFANT FORMULA/FE"
            },
            "WALGREENS": {
              "ADVANTAGE INFANT FORMULA/IRON": "ADVANTAGE INFANT FORMULA/IRON",
              "PREMIUM INFANT FORMULA/IRON": "PREMIUM INFANT FORMULA/IRON"
            }
          }
        },
        "Nutritional Substitutes": {
          "Sweeteners": {
            "AKORN CONSUMER": {
              "DIABETISWEET": "DIABETISWEET",
              "DIABETISWEET BROWN SUGAR": "DIABETISWEET BROWN SUGAR"
            },
            "DSE HEALTHCARE SOLUTIONS": {
              "SWEETA": "SWEETA"
            },
            "FAGRON": {
              "ASPARTAME": "ASPARTAME",
              "SACCHARIN": "SACCHARIN",
              "SODIUM SACCHARIN": "SODIUM SACCHARIN"
            },
            "FREEDOM PHARMACEUTICALS": {
              "SODIUM SACCHARIN DIHYDRATE": "SODIUM SACCHARIN DIHYDRATE"
            },
            "LETCO MEDICAL": {
              "ASPARTAME (NUTRASWEET)": "ASPARTAME (NUTRASWEET)",
              "SODIUM SACCHARIN": "SODIUM SACCHARIN"
            },
            "MEDISCA": {
              "ASPARTAME": "ASPARTAME",
              "SODIUM SACCHARIN": "SODIUM SACCHARIN"
            },
            "OAKHURST": {
              "PEARSON SAKRIN": "PEARSON SAKRIN"
            },
            "PCCA": {
              "ASPARTAME": "ASPARTAME",
              "SACCHARIN": "SACCHARIN",
              "SODIUM SACCHARIN": "SODIUM SACCHARIN"
            },
            "RITE AID CORPORATION": {
              "RA SACCHARIN": "RA SACCHARIN"
            },
            "SPECTRUM": {
              "ASPARTAME": "ASPARTAME",
              "SACCHARIN": "SACCHARIN",
              "SODIUM SACCHARIN": "SODIUM SACCHARIN"
            }
          }
        },
        "Nutritional Supplements": {
          "Nutritional Supplements": {
            "ABBOTT NUTRITION": {
              "ADVERA": "ADVERA",
              "ALITRAQ": "ALITRAQ",
              "CYCLINEX-1": "CYCLINEX-1",
              "CYCLINEX-2": "CYCLINEX-2",
              "ELECARE": "ELECARE",
              "ELECARE DHA/ARA": "ELECARE DHA/ARA",
              "ELECARE DHA/ARA INFANT": "ELECARE DHA/ARA INFANT",
              "ELECARE JR": "ELECARE JR",
              "ENLIVE": "ENLIVE",
              "ENSURE": "ENSURE",
              "ENSURE ACTIVE": "ENSURE ACTIVE",
              "ENSURE ACTIVE HEART HEALTH": "ENSURE ACTIVE HEART HEALTH",
              "ENSURE ACTIVE HIGH PROTEIN": "ENSURE ACTIVE HIGH PROTEIN",
              "ENSURE ACTIVE LIGHT": "ENSURE ACTIVE LIGHT",
              "ENSURE BONE HEALTH REVIGOR": "ENSURE BONE HEALTH REVIGOR",
              "ENSURE CLEAR": "ENSURE CLEAR",
              "ENSURE CLINICAL ST REVIGOR": "ENSURE CLINICAL ST REVIGOR",
              "ENSURE COMPACT": "ENSURE COMPACT",
              "ENSURE COMPLETE": "ENSURE COMPLETE",
              "ENSURE COMPLETE SHAKE": "ENSURE COMPLETE SHAKE",
              "ENSURE ENLIVE": "ENSURE ENLIVE",
              "ENSURE HARVEST 1.2 CAL": "ENSURE HARVEST 1.2 CAL",
              "ENSURE HEALTHY MOM": "ENSURE HEALTHY MOM",
              "ENSURE HIGH CALCIUM": "ENSURE HIGH CALCIUM",
              "ENSURE HIGH PROTEIN": "ENSURE HIGH PROTEIN",
              "ENSURE IMMUNE HEALTH": "ENSURE IMMUNE HEALTH",
              "ENSURE MAX PROTEIN": "ENSURE MAX PROTEIN",
              "ENSURE MUSCLE HEALTH REVIGOR": "ENSURE MUSCLE HEALTH REVIGOR",
              "ENSURE NUTRA SHAKE HI-CAL": "ENSURE NUTRA SHAKE HI-CAL",
              "ENSURE NUTRITION SHAKE": "ENSURE NUTRITION SHAKE",
              "ENSURE ORIG THERAPEUTIC NUTRI": "ENSURE ORIG THERAPEUTIC NUTRI",
              "ENSURE ORIGINAL": "ENSURE ORIGINAL",
              "ENSURE PLANT-BASED PROTEIN": "ENSURE PLANT-BASED PROTEIN",
              "ENSURE PLUS": "ENSURE PLUS",
              "ENSURE PLUS HN": "ENSURE PLUS HN",
              "ENSURE PRE-SURGERY": "ENSURE PRE-SURGERY",
              "ENSURE PUDDING": "ENSURE PUDDING",
              "ENSURE SURGERY": "ENSURE SURGERY",
              "ENSURE/FIBER": "ENSURE/FIBER",
              "EQUATE": "EQUATE",
              "EQUATE PLUS": "EQUATE PLUS",
              "FORTA DRINK": "FORTA DRINK",
              "FORTA SHAKE": "FORTA SHAKE",
              "GLUCERNA": "GLUCERNA",
              "GLUCERNA 1.0 CAL": "GLUCERNA 1.0 CAL",
              "GLUCERNA 1.0 CAL/CARBSTEADY": "GLUCERNA 1.0 CAL/CARBSTEADY",
              "GLUCERNA 1.0 CAL/FIBER": "GLUCERNA 1.0 CAL/FIBER",
              "GLUCERNA 1.2 CAL": "GLUCERNA 1.2 CAL",
              "GLUCERNA 1.5 CAL": "GLUCERNA 1.5 CAL",
              "GLUCERNA ADVANCE SHAKE": "GLUCERNA ADVANCE SHAKE",
              "GLUCERNA CARBSTEADY": "GLUCERNA CARBSTEADY",
              "GLUCERNA CEREAL": "GLUCERNA CEREAL",
              "GLUCERNA CRISPY DELIGHTS": "GLUCERNA CRISPY DELIGHTS",
              "GLUCERNA HUNGER SMART SHAKE": "GLUCERNA HUNGER SMART SHAKE",
              "GLUCERNA MEAL": "GLUCERNA MEAL",
              "GLUCERNA MEAL REPLACEMENT": "GLUCERNA MEAL REPLACEMENT",
              "GLUCERNA MINI SNACK": "GLUCERNA MINI SNACK",
              "GLUCERNA MINI SNACKS": "GLUCERNA MINI SNACKS",
              "GLUCERNA OS": "GLUCERNA OS",
              "GLUCERNA SELECT": "GLUCERNA SELECT",
              "GLUCERNA SHAKE": "GLUCERNA SHAKE",
              "GLUCERNA SNACK": "GLUCERNA SNACK",
              "GLUCERNA SNACK SHAKE": "GLUCERNA SNACK SHAKE",
              "GLUCERNA WEIGHT LOSS SHAKE": "GLUCERNA WEIGHT LOSS SHAKE",
              "GLUTAREX-1": "GLUTAREX-1",
              "GLUTAREX-2": "GLUTAREX-2",
              "HEALTH SOURCE": "HEALTH SOURCE",
              "HI-CAL": "HI-CAL",
              "HOMINEX-1": "HOMINEX-1",
              "HOMINEX-2": "HOMINEX-2",
              "I-VALEX-1": "I-VALEX-1",
              "I-VALEX-2": "I-VALEX-2",
              "INTROLITE": "INTROLITE",
              "JEVITY 1 CAL": "JEVITY 1 CAL",
              "JEVITY 1 CAL/FIBER": "JEVITY 1 CAL/FIBER",
              "JEVITY 1.2 CAL": "JEVITY 1.2 CAL",
              "JEVITY 1.2 CAL/FIBER": "JEVITY 1.2 CAL/FIBER",
              "JEVITY 1.5 CAL": "JEVITY 1.5 CAL",
              "JEVITY 1.5 CAL/FIBER": "JEVITY 1.5 CAL/FIBER",
              "JUVEN": "JUVEN",
              "JUVEN NUTRIVIGOR": "JUVEN NUTRIVIGOR",
              "JUVEN REVIGOR": "JUVEN REVIGOR",
              "KETONEX-1": "KETONEX-1",
              "KETONEX-2": "KETONEX-2",
              "NEPRO": "NEPRO",
              "NEPRO/CARBSTEADY": "NEPRO/CARBSTEADY",
              "NUTRIFOCUS": "NUTRIFOCUS",
              "OPTIMENTAL": "OPTIMENTAL",
              "OSMOLITE": "OSMOLITE",
              "OSMOLITE 1 CAL": "OSMOLITE 1 CAL",
              "OSMOLITE 1.2 CAL": "OSMOLITE 1.2 CAL",
              "OSMOLITE 1.5 CAL": "OSMOLITE 1.5 CAL",
              "OSMOLITE HN": "OSMOLITE HN",
              "OXEPA": "OXEPA",
              "OXEPA 1.5": "OXEPA 1.5",
              "PEDIASURE": "PEDIASURE",
              "PEDIASURE 1.0 CAL/FIBER": "PEDIASURE 1.0 CAL/FIBER",
              "PEDIASURE 1.5 CAL": "PEDIASURE 1.5 CAL",
              "PEDIASURE 1.5 CAL/FIBER": "PEDIASURE 1.5 CAL/FIBER",
              "PEDIASURE ENTERAL 1.0 CAL": "PEDIASURE ENTERAL 1.0 CAL",
              "PEDIASURE ENTERAL 1.0CAL/FIBER": "PEDIASURE ENTERAL 1.0CAL/FIBER",
              "PEDIASURE GROW & GAIN": "PEDIASURE GROW & GAIN",
              "PEDIASURE GROW & GAIN ORGANIC": "PEDIASURE GROW & GAIN ORGANIC",
              "PEDIASURE GROW & GAIN/FIBER": "PEDIASURE GROW & GAIN/FIBER",
              "PEDIASURE HARVEST 1.0 CAL": "PEDIASURE HARVEST 1.0 CAL",
              "PEDIASURE NUTRIPALS": "PEDIASURE NUTRIPALS",
              "PEDIASURE PEDIATRIC": "PEDIASURE PEDIATRIC",
              "PEDIASURE PEPTIDE 1.0 CAL": "PEDIASURE PEPTIDE 1.0 CAL",
              "PEDIASURE PEPTIDE 1.5 CAL": "PEDIASURE PEPTIDE 1.5 CAL",
              "PEDIASURE REDUCED CALORIE": "PEDIASURE REDUCED CALORIE",
              "PEDIASURE SHAKE MIX": "PEDIASURE SHAKE MIX",
              "PEDIASURE SHAKE/FIBER": "PEDIASURE SHAKE/FIBER",
              "PEDIASURE SIDEKICKS": "PEDIASURE SIDEKICKS",
              "PEDIASURE SIDEKICKS CLEAR": "PEDIASURE SIDEKICKS CLEAR",
              "PEDIASURE SIDEKICKS SHAKE": "PEDIASURE SIDEKICKS SHAKE",
              "PEDIASURE/FIBER": "PEDIASURE/FIBER",
              "PERATIVE": "PERATIVE",
              "PERATIVE 1.3 CAL": "PERATIVE 1.3 CAL",
              "PHENEX CHEWS": "PHENEX CHEWS",
              "PHENEX-1": "PHENEX-1",
              "PHENEX-2": "PHENEX-2",
              "PIVOT 1.5 CAL": "PIVOT 1.5 CAL",
              "PRO-PHREE": "PRO-PHREE",
              "PROMOD": "PROMOD",
              "PROMOTE": "PROMOTE",
              "PROMOTE 1.0": "PROMOTE 1.0",
              "PROMOTE 1.0 WITH FIBER": "PROMOTE 1.0 WITH FIBER",
              "PROMOTE/FIBER": "PROMOTE/FIBER",
              "PROPIMEX-1": "PROPIMEX-1",
              "PROPIMEX-2": "PROPIMEX-2",
              "PROSURE": "PROSURE",
              "PROVIMIN": "PROVIMIN",
              "PULMOCARE": "PULMOCARE",
              "PULMOCARE 1.5": "PULMOCARE 1.5",
              "RE/NEPH": "RE/NEPH",
              "RE/NEPH HP/HC": "RE/NEPH HP/HC",
              "RE/NEPH LP/HC": "RE/NEPH LP/HC",
              "RE/NEPH REDUCED SUGAR": "RE/NEPH REDUCED SUGAR",
              "SUPLENA": "SUPLENA",
              "SUPLENA 1.8/CARBSTEADY": "SUPLENA 1.8/CARBSTEADY",
              "SUPLENA/CARB STEADY": "SUPLENA/CARB STEADY",
              "TWOCAL HN": "TWOCAL HN",
              "TWOCAL HN 2.0": "TWOCAL HN 2.0",
              "TYREX-1": "TYREX-1",
              "TYREX-2": "TYREX-2",
              "VITAL 1.0 CAL": "VITAL 1.0 CAL",
              "VITAL 1.5 CAL": "VITAL 1.5 CAL",
              "VITAL AF 1.2 CAL": "VITAL AF 1.2 CAL",
              "VITAL AF 1.2 CAL ADV FORMULA": "VITAL AF 1.2 CAL ADV FORMULA",
              "VITAL HIGH PROTEIN": "VITAL HIGH PROTEIN",
              "VITAL HN": "VITAL HN",
              "VITAL HP 1.0 CAL": "VITAL HP 1.0 CAL",
              "VITAL JR": "VITAL JR",
              "VITAL PEPTIDE 1.5 CAL": "VITAL PEPTIDE 1.5 CAL"
            },
            "AJINOMOTO CAMBROOKE": {
              "CAMINO PRO 15PE": "CAMINO PRO 15PE",
              "CAMINO PRO BETTERMILK/GLYTACTN": "CAMINO PRO BETTERMILK/GLYTACTN",
              "CAMINO PRO COMPLETE/GLYTACTIN": "CAMINO PRO COMPLETE/GLYTACTIN",
              "CAMINO PRO PKU": "CAMINO PRO PKU",
              "CAMINO PRO RESTORE/GLYTACTIN": "CAMINO PRO RESTORE/GLYTACTIN",
              "EQUACARE JR": "EQUACARE JR",
              "ESSENTIAL CARE JR": "ESSENTIAL CARE JR",
              "GLYTACTIN BETTERMILK 15": "GLYTACTIN BETTERMILK 15",
              "GLYTACTIN BETTERMILK DE-LITE": "GLYTACTIN BETTERMILK DE-LITE",
              "GLYTACTIN BUILD 10PE": "GLYTACTIN BUILD 10PE",
              "GLYTACTIN BUILD 20/20 PKU": "GLYTACTIN BUILD 20/20 PKU",
              "GLYTACTIN BURST": "GLYTACTIN BURST",
              "GLYTACTIN COMPLETE 10PE": "GLYTACTIN COMPLETE 10PE",
              "GLYTACTIN RESTORE 10": "GLYTACTIN RESTORE 10",
              "GLYTACTIN RESTORE 5": "GLYTACTIN RESTORE 5",
              "GLYTACTIN RESTORE LITE 10": "GLYTACTIN RESTORE LITE 10",
              "GLYTACTIN RESTORE LITE 10PE": "GLYTACTIN RESTORE LITE 10PE",
              "GLYTACTIN RTD 10": "GLYTACTIN RTD 10",
              "GLYTACTIN RTD 15": "GLYTACTIN RTD 15",
              "GLYTACTIN RTD LITE 15": "GLYTACTIN RTD LITE 15",
              "GLYTACTIN SWIRL 15PE": "GLYTACTIN SWIRL 15PE",
              "HOMACTIN AA PLUS": "HOMACTIN AA PLUS",
              "ISOVACTIN AA PLUS": "ISOVACTIN AA PLUS",
              "KETOVIE": "KETOVIE",
              "KETOVIE PEPTIDE": "KETOVIE PEPTIDE",
              "PHENACTIN AA PLUS": "PHENACTIN AA PLUS",
              "PROMACTIN AA PLUS": "PROMACTIN AA PLUS",
              "PROMACTIN AA PLUS 20PE": "PROMACTIN AA PLUS 20PE",
              "TYLACTIN BUILD 20PE TYR": "TYLACTIN BUILD 20PE TYR",
              "TYLACTIN COMPLETE 15 PE": "TYLACTIN COMPLETE 15 PE",
              "TYLACTIN RESTORE 10": "TYLACTIN RESTORE 10",
              "TYLACTIN RESTORE 5PE": "TYLACTIN RESTORE 5PE",
              "TYLACTIN RTD 15": "TYLACTIN RTD 15",
              "VILACTIN AA PLUS": "VILACTIN AA PLUS"
            },
            "ALLERGAN": {
              "SCANDICAL": "SCANDICAL",
              "SCANDISHAKE": "SCANDISHAKE",
              "SCANDISHAKE (ASPARTAME)": "SCANDISHAKE (ASPARTAME)",
              "SCANDISHAKE (LACTOSE FREE)": "SCANDISHAKE (LACTOSE FREE)"
            },
            "ALTERNATIVA NATURAL": {
              "IMMULIFE": "IMMULIFE"
            },
            "AMERICAN NATURAL SNACK": {
              "BALANCE TOTAL NUTRITIONAL SNAC": "BALANCE TOTAL NUTRITIONAL SNAC"
            },
            "APPLIED NUTRITION CORPORATION": {
              "PHENYLADE DRINK MIX": "PHENYLADE DRINK MIX",
              "PHENYLADE ESSENTIAL DRINK MIX": "PHENYLADE ESSENTIAL DRINK MIX",
              "PHENYLADE ESSENTIAL MIX/FIBER": "PHENYLADE ESSENTIAL MIX/FIBER",
              "PHENYLADE RTD PKU 10": "PHENYLADE RTD PKU 10",
              "PHENYLADE60 DRINK MIX": "PHENYLADE60 DRINK MIX"
            },
            "BERGEN BRUNSWIG": {
              "GNP NUTRITIONAL DRINK": "GNP NUTRITIONAL DRINK",
              "GNP NUTRITIONAL DRINK PLUS": "GNP NUTRITIONAL DRINK PLUS"
            },
            "BIO-TECH": {
              "BROMASE": "BROMASE",
              "CREATINE": "CREATINE",
              "LITHATE": "LITHATE",
              "MALIC B6": "MALIC B6",
              "PROS-TECH PLUS": "PROS-TECH PLUS",
              "SILICA": "SILICA",
              "SODIUM BENZOATE": "SODIUM BENZOATE"
            },
            "BIOMOLECULAR SCIENCES, INC.": {
              "IMMUNOPRO RX": "IMMUNOPRO RX"
            },
            "BIONEXUS, LTD": {
              "NUTRIVIR": "NUTRIVIR",
              "NUTRIVIR NSA": "NUTRIVIR NSA"
            },
            "BIOTICS RESEARCH": {
              "METHIONINE-200": "METHIONINE-200"
            },
            "CALWOOD NUTRITIONALS": {
              "NUTRAMINE": "NUTRAMINE",
              "NUTRAMINE AMINO BITES": "NUTRAMINE AMINO BITES"
            },
            "CHAIN DRUG CONSORTIUM": {
              "COMPLETE NUTRITION": "COMPLETE NUTRITION",
              "COMPLETE NUTRITION PLUS": "COMPLETE NUTRITION PLUS",
              "COMPLETE NUTRITIONAL DRINK": "COMPLETE NUTRITIONAL DRINK",
              "PROSTA VITE": "PROSTA VITE",
              "VENOVITE PLUS ACEROLA": "VENOVITE PLUS ACEROLA"
            },
            "CHATTEM INC": {
              "NEWPHASE COMPLETE": "NEWPHASE COMPLETE",
              "NEWPHASE COMPLETE ES": "NEWPHASE COMPLETE ES"
            },
            "CHRONOHEALTH": {
              "ACID BLOCKERS DEPLETION": "ACID BLOCKERS DEPLETION",
              "ACID BLOCKERS SUPPORT": "ACID BLOCKERS SUPPORT",
              "ADULT GROWTH HORMONE SUPPORT": "ADULT GROWTH HORMONE SUPPORT",
              "ANTI-INFLAMMATORY ENZYME": "ANTI-INFLAMMATORY ENZYME",
              "ANTI-SEIZURE DEPLETION": "ANTI-SEIZURE DEPLETION",
              "ANTI-SEIZURE SUPPORT": "ANTI-SEIZURE SUPPORT",
              "ANTIOXIDANT FORMULA": "ANTIOXIDANT FORMULA",
              "CARDIO COMPLETE": "CARDIO COMPLETE",
              "CHLORELLA-SPIRULINA COMPLEX": "CHLORELLA-SPIRULINA COMPLEX",
              "CHOLESTEROL DEFENSE": "CHOLESTEROL DEFENSE",
              "CHRONIC KIDNEY DISEASE SUPPORT": "CHRONIC KIDNEY DISEASE SUPPORT",
              "CHRONIC PAIN SUPPORT": "CHRONIC PAIN SUPPORT",
              "CHRONO-BASIC": "CHRONO-BASIC",
              "CHRONO-BONE BUILDER": "CHRONO-BONE BUILDER",
              "CHRONO-IMMUNE SHIELD": "CHRONO-IMMUNE SHIELD",
              "CHRONO-MATURE": "CHRONO-MATURE",
              "CHRONOFLEX": "CHRONOFLEX",
              "CHRONOVISION": "CHRONOVISION",
              "COLD AND FLU": "COLD AND FLU",
              "CORTICOSTEROIDS DEPLETION": "CORTICOSTEROIDS DEPLETION",
              "CORTICOSTEROIDS SUPPORT": "CORTICOSTEROIDS SUPPORT",
              "CROHNS DISEASE SUPPORT": "CROHNS DISEASE SUPPORT",
              "CYSTIC FIBROSIS SUPPORT": "CYSTIC FIBROSIS SUPPORT",
              "DIABETES SUPPORT": "DIABETES SUPPORT",
              "FEMALE INFERTILITY SUPPORT": "FEMALE INFERTILITY SUPPORT",
              "GLUCOSE MANAGEMENT": "GLUCOSE MANAGEMENT",
              "HEMOPHILIA SUPPORT": "HEMOPHILIA SUPPORT",
              "HIV SUPPORT": "HIV SUPPORT",
              "HOMOCYSTEINE SUPPORT": "HOMOCYSTEINE SUPPORT",
              "HRT SUPPORT": "HRT SUPPORT",
              "IBS SUPPORT": "IBS SUPPORT",
              "IMMUNE ENHANCE": "IMMUNE ENHANCE",
              "LIVER DEFENSE": "LIVER DEFENSE",
              "MALE INFERTILITY SUPPORT": "MALE INFERTILITY SUPPORT",
              "MALE SUPPORT": "MALE SUPPORT",
              "MULTIPLE SCLEROSIS SUPPORT": "MULTIPLE SCLEROSIS SUPPORT",
              "ONCOLOGY SUPPORT": "ONCOLOGY SUPPORT",
              "OSTEO ADVANCE": "OSTEO ADVANCE",
              "OSTEOPOROSIS SUPPORT": "OSTEOPOROSIS SUPPORT",
              "PSORIASIS SUPPORT": "PSORIASIS SUPPORT",
              "PULMONARY HYPERTENSION SUPPORT": "PULMONARY HYPERTENSION SUPPORT",
              "RHEUMATOID ARTHRITIS SUPPORT": "RHEUMATOID ARTHRITIS SUPPORT",
              "STATINS DEPLETION": "STATINS DEPLETION",
              "STATINS SUPPORT": "STATINS SUPPORT",
              "STRESS SHIELD": "STRESS SHIELD",
              "VITAMIN D BOOSTER": "VITAMIN D BOOSTER",
              "VITAMIN D MAINTENANCE": "VITAMIN D MAINTENANCE",
              "VITAMIN D PLUS COFACTORS": "VITAMIN D PLUS COFACTORS",
              "WOMENS HEALTH SUPPORT": "WOMENS HEALTH SUPPORT"
            },
            "CLEARFAST": {
              "CFPREOP": "CFPREOP"
            },
            "CLICKCO": {
              "CLICK ESPRESSO PROTEIN DRINK": "CLICK ESPRESSO PROTEIN DRINK"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS NUTRITION LIQUID": "CVS NUTRITION LIQUID",
              "CVS NUTRITION PLUS": "CVS NUTRITION PLUS",
              "CVS NUTRITION PLUS CHOCOLATE": "CVS NUTRITION PLUS CHOCOLATE",
              "CVS NUTRITION PLUS VANILLA": "CVS NUTRITION PLUS VANILLA",
              "CVS NUTRITIONAL SHAKE": "CVS NUTRITIONAL SHAKE"
            },
            "DELHAIZE AMERICA": {
              "HEALTHY ACCENTS NUTRA FIT": "HEALTHY ACCENTS NUTRA FIT",
              "HEALTHY ACCENTS NUTRA FIT PLUS": "HEALTHY ACCENTS NUTRA FIT PLUS"
            },
            "ENTRINSIC BIOSCIENCE": {
              "ENTERADE": "ENTERADE"
            },
            "ENVARA HEALTH": {
              "ENCALA": "ENCALA"
            },
            "EQUALINE": {
              "EQL NUTRITIONAL DRINK ADVANCED": "EQL NUTRITIONAL DRINK ADVANCED",
              "EQL NUTRITIONAL DRINK PLUS": "EQL NUTRITIONAL DRINK PLUS",
              "EQL PEDIATRIC DRINK": "EQL PEDIATRIC DRINK",
              "EQL PEDIATRIC DRINK/FIBER": "EQL PEDIATRIC DRINK/FIBER"
            },
            "FREEDA VITAMINS": {
              "ULTRAMINO SOY PROTEIN": "ULTRAMINO SOY PROTEIN"
            },
            "GLEN VENTURES": {
              "AMINOPMRMS": "AMINOPMRMS",
              "ASILNASALRMS": "ASILNASALRMS"
            },
            "GVM ASSOCIATES": {
              "SOL CARB": "SOL CARB"
            },
            "HAELAN-HTPI": {
              "HAELAN 951 FERMENTED SOY": "HAELAN 951 FERMENTED SOY",
              "HAELAN HTPI FERMENTED SOY": "HAELAN HTPI FERMENTED SOY"
            },
            "I-HEALTH": {
              "ESTROVEN NIGHTTIME": "ESTROVEN NIGHTTIME",
              "ESTROVEN WEIGHT MANAGEMENT": "ESTROVEN WEIGHT MANAGEMENT"
            },
            "INVOLVE PHARMACEUTICALS": {
              "KETOGEN": "KETOGEN"
            },
            "JACKSON-MITCHELL": {
              "GOAT MILK": "GOAT MILK"
            },
            "K-PAX  VITAMINS": {
              "K-PAX PROTEIN BLEND IMMUNE": "K-PAX PROTEIN BLEND IMMUNE"
            },
            "KATE FARMS": {
              "KATE FARMS CORE ESSENTIALS 1.0": "KATE FARMS CORE ESSENTIALS 1.0",
              "KATE FARMS CORE ESSENTIALS 1.2": "KATE FARMS CORE ESSENTIALS 1.2",
              "KATE FARMS CORE ESSENTIALS 1.5": "KATE FARMS CORE ESSENTIALS 1.5",
              "KATE FARMS PED PEPTIDE 1.0": "KATE FARMS PED PEPTIDE 1.0",
              "KATE FARMS PED PEPTIDE 1.5": "KATE FARMS PED PEPTIDE 1.5",
              "KATE FARMS PED STANDARD 1.2": "KATE FARMS PED STANDARD 1.2",
              "KATE FARMS PEPTIDE 1.5": "KATE FARMS PEPTIDE 1.5",
              "KATE FARMS STANDARD 1.0": "KATE FARMS STANDARD 1.0",
              "KATE FARMS STANDARD 1.4": "KATE FARMS STANDARD 1.4"
            },
            "KENT PRECISION FOODS": {
              "THICK-IT CHICKEN A LA KING": "THICK-IT CHICKEN A LA KING",
              "THICK-IT MIXED FRUIT/BERRY": "THICK-IT MIXED FRUIT/BERRY",
              "THICK-IT PUREES BEEF LASAGNA": "THICK-IT PUREES BEEF LASAGNA",
              "THICK-IT PUREES CHICKEN PATTY": "THICK-IT PUREES CHICKEN PATTY",
              "THICK-IT PUREES FRENCH TOAST": "THICK-IT PUREES FRENCH TOAST",
              "THICK-IT PUREES SWEET CORN": "THICK-IT PUREES SWEET CORN",
              "THICK-IT THICKENED CRANBERRY": "THICK-IT THICKENED CRANBERRY"
            },
            "KRAMER-NOVIS": {
              "BIO-IMMUNEX": "BIO-IMMUNEX"
            },
            "LEADER BRAND PRODUCTS": {
              "ESTRO SUPPORT ES": "ESTRO SUPPORT ES"
            },
            "LUTRISH": {
              "LUTRISH CHOCOLATE SHAKE": "LUTRISH CHOCOLATE SHAKE",
              "LUTRISH VANILLA SHAKE": "LUTRISH VANILLA SHAKE"
            },
            "M J NUTR": {
              "3232A INFANT FORMULA": "3232A INFANT FORMULA",
              "BCAD 1": "BCAD 1",
              "BCAD 2": "BCAD 2",
              "GA": "GA",
              "GA DIET": "GA DIET",
              "HCY 1": "HCY 1",
              "HCY 1 DIET": "HCY 1 DIET",
              "HCY 2": "HCY 2",
              "LMD": "LMD",
              "LMD DIET": "LMD DIET",
              "OA 1": "OA 1",
              "OA 1 DIET": "OA 1 DIET",
              "OA 2": "OA 2",
              "OA 2 DIET": "OA 2 DIET",
              "PFD 1": "PFD 1",
              "PFD 2": "PFD 2",
              "PHENYL-FREE 2": "PHENYL-FREE 2",
              "PHENYL-FREE 2HP": "PHENYL-FREE 2HP",
              "PORTAGEN": "PORTAGEN",
              "TYROS 1": "TYROS 1",
              "TYROS 2": "TYROS 2",
              "WND 1": "WND 1",
              "WND 2": "WND 2"
            },
            "MAGNO-HUMPHRIES LABORATORIES": {
              "5-HTP TRYPTOPHAN": "5-HTP TRYPTOPHAN"
            },
            "MASON VITAMINS": {
              "ECHINACEA/GOLDENSEAL IMMUNE": "ECHINACEA/GOLDENSEAL IMMUNE",
              "FRUIT & VEGETABLE DAILY": "FRUIT & VEGETABLE DAILY",
              "SALMON OIL": "SALMON OIL"
            },
            "MCKESSON": {
              "HM NUTRISURE": "HM NUTRISURE",
              "HM NUTRISURE PLUS": "HM NUTRISURE PLUS"
            },
            "MCKESSON SUNMARK": {
              "SM ESTRO VITAL NUTRIENTS EX ST": "SM ESTRO VITAL NUTRIENTS EX ST",
              "SM ESTROPLUS EXTRA STRENGTH": "SM ESTROPLUS EXTRA STRENGTH",
              "SM NUTRI-DRINK": "SM NUTRI-DRINK",
              "SM NUTRI-DRINK +": "SM NUTRI-DRINK +"
            },
            "MEDLINE/DERMAL MANAGEMENT": {
              "DIAB EXTRA": "DIAB EXTRA",
              "DIAB NUTRI": "DIAB NUTRI"
            },
            "MEDTRITION NATIONAL NUTRITION": {
              "PHYTOFUSION": "PHYTOFUSION",
              "PROSOURCE": "PROSOURCE",
              "PROSOURCE NO CARB": "PROSOURCE NO CARB",
              "PROSOURCE PLUS": "PROSOURCE PLUS",
              "PROSOURCE TF": "PROSOURCE TF",
              "PROSOURCE ZAC": "PROSOURCE ZAC",
              "UTYCHEWS": "UTYCHEWS",
              "UTYMAX": "UTYMAX",
              "XTRACAL PLUS": "XTRACAL PLUS"
            },
            "METAGENES": {
              "KETOVOLVE 4:1": "KETOVOLVE 4:1"
            },
            "METAGENICS": {
              "WELLNESS ESSENTIALS": "WELLNESS ESSENTIALS",
              "WELLNESS ESSENTIALS AI": "WELLNESS ESSENTIALS AI",
              "WELLNESS ESSENTIALS BLOOD SUGR": "WELLNESS ESSENTIALS BLOOD SUGR",
              "WELLNESS ESSENTIALS FOR JOINT": "WELLNESS ESSENTIALS FOR JOINT",
              "WELLNESS ESSENTIALS FOR MEN": "WELLNESS ESSENTIALS FOR MEN",
              "WELLNESS ESSENTIALS FOR WOMEN": "WELLNESS ESSENTIALS FOR WOMEN",
              "WELLNESS ESSENTIALS PREGNANCY": "WELLNESS ESSENTIALS PREGNANCY"
            },
            "MILLENNIUM BIOTECHNOLOGIES": {
              "RESURGEX": "RESURGEX",
              "RESURGEX PLUS": "RESURGEX PLUS",
              "RESURGEX SELECT": "RESURGEX SELECT"
            },
            "MILLER": {
              "A/G PRO": "A/G PRO",
              "GLUCOSAMINE FORTE": "GLUCOSAMINE FORTE"
            },
            "NAT-RUL HEALTH PRODUCTS": {
              "BEE POLLEN-1000/ROYAL JELLY": "BEE POLLEN-1000/ROYAL JELLY",
              "PYCNOGENOL": "PYCNOGENOL"
            },
            "NATIONAL VITAMIN": {
              "ULTRA ENERGY": "ULTRA ENERGY"
            },
            "NATURE'S ONE": {
              "ORGANIC PEDIA SMART": "ORGANIC PEDIA SMART"
            },
            "NATURES BOUNTY": {
              "COMPLETE PROTEIN/VITAMIN SHAKE": "COMPLETE PROTEIN/VITAMIN SHAKE",
              "GRAPESEED EXTRACT": "GRAPESEED EXTRACT",
              "MENOPAUSE FORMULA": "MENOPAUSE FORMULA",
              "PYCNOGENOL": "PYCNOGENOL",
              "SOY PROTEIN SHAKE": "SOY PROTEIN SHAKE"
            },
            "NESTLE HEALTHCARE NUTRITION": {
              "ARGINAID": "ARGINAID",
              "ARGINAID EXTRA": "ARGINAID EXTRA",
              "BENECALORIE": "BENECALORIE",
              "BOOST": "BOOST",
              "BOOST 100 CALORIE SMART": "BOOST 100 CALORIE SMART",
              "BOOST BREEZE": "BOOST BREEZE",
              "BOOST CALORIE SMART": "BOOST CALORIE SMART",
              "BOOST COMPACT": "BOOST COMPACT",
              "BOOST GLUCOSE CONTROL": "BOOST GLUCOSE CONTROL",
              "BOOST HIGH PROTEIN": "BOOST HIGH PROTEIN",
              "BOOST KID ESSENTIALS 1.0 CAL": "BOOST KID ESSENTIALS 1.0 CAL",
              "BOOST KID ESSENTIALS 1.5 CAL": "BOOST KID ESSENTIALS 1.5 CAL",
              "BOOST KID ESSENTIALS 1.5/FIBER": "BOOST KID ESSENTIALS 1.5/FIBER",
              "BOOST KIDS ESSENTIALS": "BOOST KIDS ESSENTIALS",
              "BOOST MAX": "BOOST MAX",
              "BOOST MAX PROTEIN": "BOOST MAX PROTEIN",
              "BOOST PLUS": "BOOST PLUS",
              "BOOST PUDDING": "BOOST PUDDING",
              "BOOST SMOOTHIE": "BOOST SMOOTHIE",
              "BOOST VHC": "BOOST VHC",
              "BOOST/BENEFIBER": "BOOST/BENEFIBER",
              "CARNATION BREAKFAST ESSENTIALS": "CARNATION BREAKFAST ESSENTIALS",
              "CARNATION INST BREAKFAST JUICE": "CARNATION INST BREAKFAST JUICE",
              "CARNATION INST BREAKFAST PLUS": "CARNATION INST BREAKFAST PLUS",
              "CARNATION INST BREAKFAST VHC": "CARNATION INST BREAKFAST VHC",
              "CARNATION INSTANT BREAKFAST": "CARNATION INSTANT BREAKFAST",
              "CHOICE DM": "CHOICE DM",
              "CHOICE DM TF": "CHOICE DM TF",
              "COMPLEAT": "COMPLEAT",
              "COMPLEAT ORGANIC BLENDS": "COMPLEAT ORGANIC BLENDS",
              "COMPLEAT PEDI PEPTIDE 1.5": "COMPLEAT PEDI PEPTIDE 1.5",
              "COMPLEAT PEDIATRIC": "COMPLEAT PEDIATRIC",
              "COMPLEAT PEDIATRIC ORG BLENDS": "COMPLEAT PEDIATRIC ORG BLENDS",
              "COMPLEAT PEPTIDE 1.5": "COMPLEAT PEPTIDE 1.5",
              "CRITICARE HN": "CRITICARE HN",
              "CRUCIAL": "CRUCIAL",
              "DIABETIC TF": "DIABETIC TF",
              "DIABETISHIELD": "DIABETISHIELD",
              "DIABETISOURCE": "DIABETISOURCE",
              "DIABETISOURCE AC": "DIABETISOURCE AC",
              "F.A.A.": "F.A.A.",
              "FIBERSOURCE": "FIBERSOURCE",
              "FIBERSOURCE HN": "FIBERSOURCE HN",
              "GLYTROL": "GLYTROL",
              "GLYTROL PREBIO1": "GLYTROL PREBIO1",
              "IMPACT": "IMPACT",
              "IMPACT 1.5": "IMPACT 1.5",
              "IMPACT ADVANCED RECOVERY": "IMPACT ADVANCED RECOVERY",
              "IMPACT GLUTAMINE": "IMPACT GLUTAMINE",
              "IMPACT PEPTIDE 1.5": "IMPACT PEPTIDE 1.5",
              "IMPACT/FIBER": "IMPACT/FIBER",
              "ISOCAL": "ISOCAL",
              "ISOCAL HN": "ISOCAL HN",
              "ISOCAL HN PLUS": "ISOCAL HN PLUS",
              "ISOSOURCE": "ISOSOURCE",
              "ISOSOURCE 1.5 CAL": "ISOSOURCE 1.5 CAL",
              "ISOSOURCE HN": "ISOSOURCE HN",
              "ISOSOURCE VHN": "ISOSOURCE VHN",
              "MERITENE": "MERITENE",
              "MODULEN": "MODULEN",
              "MODULEN IBD": "MODULEN IBD",
              "NESTLE FLAVOR": "NESTLE FLAVOR",
              "NESTLE FLAVOR EXTRA STRENGTH": "NESTLE FLAVOR EXTRA STRENGTH",
              "NESTLE FLAVOR FOR KIDS": "NESTLE FLAVOR FOR KIDS",
              "NOVASOURCE PULMONARY": "NOVASOURCE PULMONARY",
              "NOVASOURCE RENAL": "NOVASOURCE RENAL",
              "NUTRAMENT": "NUTRAMENT",
              "NUTREN 1.0": "NUTREN 1.0",
              "NUTREN 1.0/FIBER": "NUTREN 1.0/FIBER",
              "NUTREN 1.5": "NUTREN 1.5",
              "NUTREN 1.5 FIBER": "NUTREN 1.5 FIBER",
              "NUTREN 2.0": "NUTREN 2.0",
              "NUTREN JR": "NUTREN JR",
              "NUTREN JR FIBER": "NUTREN JR FIBER",
              "NUTREN JUNIOR": "NUTREN JUNIOR",
              "NUTREN JUNIOR 1.0": "NUTREN JUNIOR 1.0",
              "NUTREN JUNIOR/FIBER": "NUTREN JUNIOR/FIBER",
              "NUTREN PULMONARY": "NUTREN PULMONARY",
              "NUTREN RENAL": "NUTREN RENAL",
              "NUTRIHEAL": "NUTRIHEAL",
              "NUTRIHEP 1.5 CAL": "NUTRIHEP 1.5 CAL",
              "NUTRIRENAL": "NUTRIRENAL",
              "NUTRIVENT": "NUTRIVENT",
              "NUTRIVENT 1.5": "NUTRIVENT 1.5",
              "OPTISOURCE": "OPTISOURCE",
              "PEDIATRIC PEPTINEX DT": "PEDIATRIC PEPTINEX DT",
              "PEDIATRIC PEPTINEX DT/FIBER": "PEDIATRIC PEPTINEX DT/FIBER",
              "PEPTAMEN": "PEPTAMEN",
              "PEPTAMEN 1 CAL": "PEPTAMEN 1 CAL",
              "PEPTAMEN 1 CAL/PREBIO1": "PEPTAMEN 1 CAL/PREBIO1",
              "PEPTAMEN 1.5": "PEPTAMEN 1.5",
              "PEPTAMEN 1.5 CAL": "PEPTAMEN 1.5 CAL",
              "PEPTAMEN 1.5 CAL/PREBIO1": "PEPTAMEN 1.5 CAL/PREBIO1",
              "PEPTAMEN AF": "PEPTAMEN AF",
              "PEPTAMEN BARIATRIC": "PEPTAMEN BARIATRIC",
              "PEPTAMEN INTENSE VHP": "PEPTAMEN INTENSE VHP",
              "PEPTAMEN JUNIOR": "PEPTAMEN JUNIOR",
              "PEPTAMEN JUNIOR 1 CAL": "PEPTAMEN JUNIOR 1 CAL",
              "PEPTAMEN JUNIOR 1 CAL/PREBIO1": "PEPTAMEN JUNIOR 1 CAL/PREBIO1",
              "PEPTAMEN JUNIOR 1.5": "PEPTAMEN JUNIOR 1.5",
              "PEPTAMEN JUNIOR 1.5 CAL": "PEPTAMEN JUNIOR 1.5 CAL",
              "PEPTAMEN JUNIOR FIBER": "PEPTAMEN JUNIOR FIBER",
              "PEPTAMEN JUNIOR HP": "PEPTAMEN JUNIOR HP",
              "PEPTAMEN JUNIOR/PREBIO1": "PEPTAMEN JUNIOR/PREBIO1",
              "PEPTAMEN OS": "PEPTAMEN OS",
              "PEPTAMEN OS 1.5": "PEPTAMEN OS 1.5",
              "PEPTAMEN VHP": "PEPTAMEN VHP",
              "PEPTAMEN/PREBIO1": "PEPTAMEN/PREBIO1",
              "PEPTINEX 1.0": "PEPTINEX 1.0",
              "PEPTINEX 1.5": "PEPTINEX 1.5",
              "PEPTINEX DT": "PEPTINEX DT",
              "PEPTINEX DT/PREBIOTICS": "PEPTINEX DT/PREBIOTICS",
              "PROBALANCE": "PROBALANCE",
              "PRONOURISH": "PRONOURISH",
              "PROTAIN XL": "PROTAIN XL",
              "RENALCAL": "RENALCAL",
              "REPLETE": "REPLETE",
              "REPLETE FIBER": "REPLETE FIBER",
              "REPLETE FIBER 1 CAL": "REPLETE FIBER 1 CAL",
              "REPLETE/FIBER": "REPLETE/FIBER",
              "RESOURCE 2.0": "RESOURCE 2.0",
              "RESOURCE ARGINAID": "RESOURCE ARGINAID",
              "RESOURCE DAIRY THICK": "RESOURCE DAIRY THICK",
              "RESOURCE DIABETIC TF": "RESOURCE DIABETIC TF",
              "RESOURCE JUICE DRINK": "RESOURCE JUICE DRINK",
              "RESOURCE JUST FOR KIDS": "RESOURCE JUST FOR KIDS",
              "RESOURCE JUST FOR KIDS/FIBER": "RESOURCE JUST FOR KIDS/FIBER",
              "RESOURCE SUPPORT": "RESOURCE SUPPORT",
              "RESOURCE THICKENUP DAIRY": "RESOURCE THICKENUP DAIRY",
              "RESOURCE THICKENUP JUICE": "RESOURCE THICKENUP JUICE",
              "RESPALOR": "RESPALOR",
              "SUBDUE": "SUBDUE",
              "SUBDUE PLUS": "SUBDUE PLUS",
              "TOLEREX": "TOLEREX",
              "ULTRACAL": "ULTRACAL",
              "ULTRACAL HN PLUS": "ULTRACAL HN PLUS",
              "VHC 2.25": "VHC 2.25",
              "VIVONEX PEDIATRIC": "VIVONEX PEDIATRIC",
              "VIVONEX PLUS": "VIVONEX PLUS",
              "VIVONEX RTF": "VIVONEX RTF",
              "VIVONEX T.E.N.": "VIVONEX T.E.N."
            },
            "NOVAM-NUTRITION": {
              "HCU EASY": "HCU EASY",
              "MSUD EASY": "MSUD EASY",
              "PKU EASY": "PKU EASY",
              "PKU EASY MICROTABS": "PKU EASY MICROTABS",
              "TYR EASY": "TYR EASY"
            },
            "NUTRA BALANCE": {
              "EGG/PRO": "EGG/PRO",
              "JUICE PLUS FIBRE": "JUICE PLUS FIBRE",
              "NUTRA BALANCE DIABETIC/FIBER": "NUTRA BALANCE DIABETIC/FIBER",
              "NUTRA BALANCE FIBER COOKIE": "NUTRA BALANCE FIBER COOKIE",
              "NUTRA BALANCE PROTEIN COOKIE": "NUTRA BALANCE PROTEIN COOKIE",
              "NUTRA SHAKE": "NUTRA SHAKE",
              "NUTRA SHAKE/SUPREME": "NUTRA SHAKE/SUPREME",
              "NUTRA/BALANCE RE/GEN": "NUTRA/BALANCE RE/GEN",
              "NUTRA/BALANCE RE/GEN FREE": "NUTRA/BALANCE RE/GEN FREE",
              "NUTRA/SHAKE": "NUTRA/SHAKE",
              "NUTRA/SHAKE FRUIT PLUS": "NUTRA/SHAKE FRUIT PLUS",
              "NUTRA/SHAKE SUPREME": "NUTRA/SHAKE SUPREME",
              "RE/GEN PROTEIN COOKIE": "RE/GEN PROTEIN COOKIE"
            },
            "NUTRICIA NA": {
              "FIBER-STAT": "FIBER-STAT"
            },
            "NUTRICIA NORTH AMERICA": {
              "ACERFLEX": "ACERFLEX",
              "DUOCAL": "DUOCAL",
              "EO28 SPLASH": "EO28 SPLASH",
              "FLAVOR PACKETS": "FLAVOR PACKETS",
              "GA-1 ANAMIX EARLY YEARS": "GA-1 ANAMIX EARLY YEARS",
              "HCU ANAMIX EARLY YEARS": "HCU ANAMIX EARLY YEARS",
              "HCU ANAMIX NEXT": "HCU ANAMIX NEXT",
              "HCU LOPHLEX LQ": "HCU LOPHLEX LQ",
              "HOM 2": "HOM 2",
              "IVA ANAMIX EARLY YEARS": "IVA ANAMIX EARLY YEARS",
              "IVA ANAMIX NEXT": "IVA ANAMIX NEXT",
              "KETOCAL 2.5:1 LQ MULTI FIBER": "KETOCAL 2.5:1 LQ MULTI FIBER",
              "KETOCAL 3:1": "KETOCAL 3:1",
              "KETOCAL 4:1": "KETOCAL 4:1",
              "KETOCAL 4:1 LQ MULTI FIBER": "KETOCAL 4:1 LQ MULTI FIBER",
              "LANAFLEX": "LANAFLEX",
              "LOPHLEX": "LOPHLEX",
              "LOPHLEX LQ 20": "LOPHLEX LQ 20",
              "METHIONAID": "METHIONAID",
              "MMA/PA ANAMIX EARLY YEARS": "MMA/PA ANAMIX EARLY YEARS",
              "MMA/PA ANAMIX NEXT": "MMA/PA ANAMIX NEXT",
              "MONOGEN": "MONOGEN",
              "MSUD 2": "MSUD 2",
              "MSUD AID": "MSUD AID",
              "MSUD ANAMIX EARLY YEARS": "MSUD ANAMIX EARLY YEARS",
              "MSUD LOPHLEX LQ": "MSUD LOPHLEX LQ",
              "MSUD MAXAMAID": "MSUD MAXAMAID",
              "MSUD MAXAMUM": "MSUD MAXAMUM",
              "NEOCATE INFANT DHA/ARA": "NEOCATE INFANT DHA/ARA",
              "NEOCATE JUNIOR": "NEOCATE JUNIOR",
              "NEOCATE JUNIOR PREBIOTICS": "NEOCATE JUNIOR PREBIOTICS",
              "NEOCATE NUTRA": "NEOCATE NUTRA",
              "NEOCATE SPLASH": "NEOCATE SPLASH",
              "NUTRICIA PREOP": "NUTRICIA PREOP",
              "OS 2": "OS 2",
              "PEPDITE JUNIOR": "PEPDITE JUNIOR",
              "PERIFLEX ADVANCE": "PERIFLEX ADVANCE",
              "PERIFLEX JUNIOR": "PERIFLEX JUNIOR",
              "PHENYLADE GMP": "PHENYLADE GMP",
              "PHENYLADE GMP MIX-IN": "PHENYLADE GMP MIX-IN",
              "PHENYLADE GMP READY": "PHENYLADE GMP READY",
              "PHENYLADE GMP ULTRA": "PHENYLADE GMP ULTRA",
              "PHLEXY-10": "PHLEXY-10",
              "PKU 2": "PKU 2",
              "PKU 3": "PKU 3",
              "PKU LOPHLEX LQ 20": "PKU LOPHLEX LQ 20",
              "PKU PERIFLEX EARLY YEARS": "PKU PERIFLEX EARLY YEARS",
              "PKU PERIFLEX JUNIOR PLUS": "PKU PERIFLEX JUNIOR PLUS",
              "POLYCAL": "POLYCAL",
              "TYR ANAMIX EARLY YEARS": "TYR ANAMIX EARLY YEARS",
              "TYR ANAMIX NEXT": "TYR ANAMIX NEXT",
              "TYR LOPHLEX GMP MIX-IN": "TYR LOPHLEX GMP MIX-IN",
              "TYR LOPHLEX LQ": "TYR LOPHLEX LQ",
              "UCD 2": "UCD 2",
              "UCD ANAMIX JUNIOR": "UCD ANAMIX JUNIOR",
              "XLEU MAXAMAID": "XLEU MAXAMAID",
              "XLEU MAXAMUM": "XLEU MAXAMUM",
              "XLYS-XTRP MAXAMAID": "XLYS-XTRP MAXAMAID",
              "XLYS-XTRP MAXAMUM": "XLYS-XTRP MAXAMUM",
              "XMET MAXAMAID": "XMET MAXAMAID",
              "XMET MAXAMUM": "XMET MAXAMUM",
              "XMET XCYS MAXAMAID": "XMET XCYS MAXAMAID",
              "XMTVI MAXAMAID": "XMTVI MAXAMAID",
              "XMTVI MAXAMUM": "XMTVI MAXAMUM",
              "XPHE MAXAMAID": "XPHE MAXAMAID",
              "XPHE-XTYR MAXAMAID": "XPHE-XTYR MAXAMAID"
            },
            "NUTRISENS INC": {
              "KINRAY PREFERRED PLUS": "KINRAY PREFERRED PLUS"
            },
            "NUTRITIONAL DESIGNS": {
              "FIBER FLOW": "FIBER FLOW",
              "LPS CRITICAL CARE SUGAR FREE": "LPS CRITICAL CARE SUGAR FREE",
              "LPS SUGAR FREE": "LPS SUGAR FREE",
              "SOYPRO": "SOYPRO"
            },
            "NUTRITIONAL FOCUS": {
              "PROTEIN FORTIFIED COOKIE": "PROTEIN FORTIFIED COOKIE"
            },
            "NUTRITIONAL MEDICINALS": {
              "LIQUID HOPE": "LIQUID HOPE",
              "NOURISH": "NOURISH"
            },
            "OPTIMEDRX": {
              "PROTEOLIN": "PROTEOLIN"
            },
            "ORGAIN": {
              "KIDS PROTEIN ORGANIC SHAKE": "KIDS PROTEIN ORGANIC SHAKE",
              "ORGANIC NUTRITION SHAKE": "ORGANIC NUTRITION SHAKE"
            },
            "P & G HEALTH": {
              "META HEALTH BAR": "META HEALTH BAR"
            },
            "PANTHERYX": {
              "DIARESQ": "DIARESQ",
              "DIARESQ CHILDRENS": "DIARESQ CHILDRENS"
            },
            "PBM PHARMACEUTICALS": {
              "A-SOY": "A-SOY",
              "GLUCO BURST": "GLUCO BURST"
            },
            "PHARMASSURE, INC.": {
              "COLON FORMULA": "COLON FORMULA",
              "PROSTAMEN": "PROSTAMEN",
              "VIGRAPLEX": "VIGRAPLEX"
            },
            "PUBLIX SUPER MARKETS INC.": {
              "PX VANILLA PLUS": "PX VANILLA PLUS"
            },
            "RDS SOLUTIONS": {
              "THRIVACIN 30": "THRIVACIN 30",
              "THRIVACIN DETOX": "THRIVACIN DETOX"
            },
            "RDS SOLUTIONS SYSTEMS": {
              "INNOVACIN": "INNOVACIN"
            },
            "REAL FOOD BLENDS": {
              "BEEF/POTATOES/SPINACH": "BEEF/POTATOES/SPINACH",
              "CHICKEN/CARROTS/BROWN RICE": "CHICKEN/CARROTS/BROWN RICE",
              "EGGS/APPLES/OATS": "EGGS/APPLES/OATS",
              "QUINOA/KALE/HEMP": "QUINOA/KALE/HEMP",
              "SALMON/OATS/SQUASH": "SALMON/OATS/SQUASH",
              "TURKEY/SWEET POTATOES/PEACHES": "TURKEY/SWEET POTATOES/PEACHES"
            },
            "REESE PHARMACEUTICAL": {
              "DHEA": "DHEA",
              "ECHINACEA/GOLDEN SEAL": "ECHINACEA/GOLDEN SEAL"
            },
            "REXALL SUNDOWN": {
              "SALMON OIL": "SALMON OIL"
            },
            "RITE AID CORPORATION": {
              "RA BALANCED NUTRITIONAL": "RA BALANCED NUTRITIONAL",
              "RA BALANCED NUTRITIONAL PLUS": "RA BALANCED NUTRITIONAL PLUS",
              "RA MELATONIN/B-6": "RA MELATONIN/B-6",
              "RA NUTRITIONAL SUPPLEMENT": "RA NUTRITIONAL SUPPLEMENT",
              "RA NUTRITIONAL SUPPORT": "RA NUTRITIONAL SUPPORT",
              "RA PEDIATRIC NUTRITIONAL DRINK": "RA PEDIATRIC NUTRITIONAL DRINK"
            },
            "SELECT BRAND": {
              "SB COMPLETE NUTRITION": "SB COMPLETE NUTRITION",
              "SB COMPLETE NUTRITION PLUS": "SB COMPLETE NUTRITION PLUS"
            },
            "SOLACE NUTRITION": {
              "CHOLEXTRA": "CHOLEXTRA",
              "KETOVOLVE 4:1": "KETOVOLVE 4:1",
              "PURECARB": "PURECARB",
              "SOL CARB": "SOL CARB"
            },
            "SOURCECF": {
              "SOURCE COOKIE": "SOURCE COOKIE"
            },
            "SWANSON HEALTH PRODUCTS": {
              "SYTRINOL": "SYTRINOL"
            },
            "SWISS BIOCEUTICAL": {
              "IMUPLUS": "IMUPLUS"
            },
            "THE KEY COMPANY": {
              "EX-L": "EX-L",
              "KIDNEY": "KIDNEY",
              "OVARY": "OVARY",
              "PROSTATE": "PROSTATE"
            },
            "THERALOGIX": {
              "BLADDER 2.2": "BLADDER 2.2",
              "CONCEPTIONXR REPRODUCTIVE": "CONCEPTIONXR REPRODUCTIVE",
              "PROSTATE 2.4": "PROSTATE 2.4",
              "THERALITH XR": "THERALITH XR"
            },
            "TOPCO": {
              "BALANCED NUTRITIONAL SHAKE PLS": "BALANCED NUTRITIONAL SHAKE PLS",
              "NUTRITIONAL SUPPLEMENT": "NUTRITIONAL SUPPLEMENT",
              "NUTRITIONAL SUPPLEMENT PLUS": "NUTRITIONAL SUPPLEMENT PLUS"
            },
            "TROVRX": {
              "ENU COMPLETE NUTRITION SHAKE": "ENU COMPLETE NUTRITION SHAKE",
              "ENU NUTRITIONAL SHAKE": "ENU NUTRITIONAL SHAKE",
              "ULTRIENT 1.5 SAFE-T FEED": "ULTRIENT 1.5 SAFE-T FEED"
            },
            "UNITHER PHARMA": {
              "HEARTBAR": "HEARTBAR"
            },
            "VITAFLO": {
              "EAA SUPPLEMENT": "EAA SUPPLEMENT",
              "FLAVOUR PAC": "FLAVOUR PAC",
              "FRUITIVITS": "FRUITIVITS",
              "GA EXPRESS15": "GA EXPRESS15",
              "GA GEL": "GA GEL",
              "GLYCOSADE": "GLYCOSADE",
              "HCU COOLER": "HCU COOLER",
              "HCU EXPRESS": "HCU EXPRESS",
              "HCU EXPRESS20": "HCU EXPRESS20",
              "HCU GEL": "HCU GEL",
              "KEYO": "KEYO",
              "LEU-FREE COOLER": "LEU-FREE COOLER",
              "LIPISTART": "LIPISTART",
              "MCT PRO-CAL": "MCT PRO-CAL",
              "MCT PROCAL": "MCT PROCAL",
              "MMA/PA COOLER": "MMA/PA COOLER",
              "MMA/PA COOLER15": "MMA/PA COOLER15",
              "MMA/PA GEL": "MMA/PA GEL",
              "MSUD COOLER": "MSUD COOLER",
              "MSUD EXPRESS": "MSUD EXPRESS",
              "MSUD EXPRESS20": "MSUD EXPRESS20",
              "MSUD GEL": "MSUD GEL",
              "PKU AIR15 GOLD": "PKU AIR15 GOLD",
              "PKU AIR15 GREEN": "PKU AIR15 GREEN",
              "PKU AIR15 YELLOW": "PKU AIR15 YELLOW",
              "PKU AIR20 GOLD": "PKU AIR20 GOLD",
              "PKU AIR20 GREEN": "PKU AIR20 GREEN",
              "PKU AIR20 YELLOW": "PKU AIR20 YELLOW",
              "PKU COOLER 10": "PKU COOLER 10",
              "PKU COOLER 15": "PKU COOLER 15",
              "PKU COOLER 20": "PKU COOLER 20",
              "PKU EXPLORE10": "PKU EXPLORE10",
              "PKU EXPLORE5": "PKU EXPLORE5",
              "PKU EXPRESS": "PKU EXPRESS",
              "PKU EXPRESS20": "PKU EXPRESS20",
              "PKU GEL": "PKU GEL",
              "PKU SPHERE 15": "PKU SPHERE 15",
              "PKU SPHERE 20": "PKU SPHERE 20",
              "PKU TRIO": "PKU TRIO",
              "PPA/MMA EXPRESS": "PPA/MMA EXPRESS",
              "PRO-CAL": "PRO-CAL",
              "RENASTART": "RENASTART",
              "S.O.S. 15": "S.O.S. 15",
              "S.O.S. 20": "S.O.S. 20",
              "S.O.S. 25": "S.O.S. 25",
              "TYR COOLER": "TYR COOLER",
              "TYR COOLER20": "TYR COOLER20",
              "TYR EXPRESS": "TYR EXPRESS",
              "TYR EXPRESS20": "TYR EXPRESS20",
              "TYR GEL": "TYR GEL",
              "UCD TRIO": "UCD TRIO",
              "VITAJOULE": "VITAJOULE",
              "VITAPRO": "VITAPRO",
              "VITAQUICK": "VITAQUICK"
            },
            "VITALINE": {
              "CATALYTIC FORMULA": "CATALYTIC FORMULA",
              "L-GLUTAMINE/CHOLINE/INOSITOL": "L-GLUTAMINE/CHOLINE/INOSITOL",
              "PROTEOLYTIC FORMULA": "PROTEOLYTIC FORMULA"
            },
            "WAL-MART": {
              "EQ ESTROBLEND MENOPAUSE": "EQ ESTROBLEND MENOPAUSE",
              "EQ NUTRITIONAL SHAKE": "EQ NUTRITIONAL SHAKE",
              "EQ NUTRITIONAL SHAKE PLUS": "EQ NUTRITIONAL SHAKE PLUS",
              "EQ WEIGHT LOSS SHAKE": "EQ WEIGHT LOSS SHAKE"
            },
            "WALGREENS": {
              "BALANCED NUTRITIONAL DRINK": "BALANCED NUTRITIONAL DRINK",
              "BALANCED NUTRITIONAL DRINK PLS": "BALANCED NUTRITIONAL DRINK PLS",
              "ESTRONATURAL": "ESTRONATURAL",
              "ESTRONATURAL EXTRA STRENGTH": "ESTRONATURAL EXTRA STRENGTH",
              "HIGH-PROTEIN NUTRITIONAL SHAKE": "HIGH-PROTEIN NUTRITIONAL SHAKE",
              "NUTRITIONAL DRINK": "NUTRITIONAL DRINK",
              "NUTRITIONAL DRINK MIX": "NUTRITIONAL DRINK MIX",
              "NUTRITIONAL DRINK PLUS": "NUTRITIONAL DRINK PLUS",
              "NUTRITIONAL DRINK SHAKE MIX": "NUTRITIONAL DRINK SHAKE MIX",
              "NUTRITIONAL SHAKE": "NUTRITIONAL SHAKE",
              "NUTRITIONAL SHAKE COMPLETE": "NUTRITIONAL SHAKE COMPLETE",
              "NUTRITIONAL SHAKE HIGH PROTEIN": "NUTRITIONAL SHAKE HIGH PROTEIN",
              "NUTRITIONAL SHAKE PLUS": "NUTRITIONAL SHAKE PLUS",
              "NUTRITIONAL SHAKE PLUS PROTEIN": "NUTRITIONAL SHAKE PLUS PROTEIN",
              "PEDIATRIC DRINK": "PEDIATRIC DRINK"
            },
            "WEIDER NUTRITION INTERNATIONAL": {
              "METAFORM": "METAFORM"
            },
            "XYMOGEN": {
              "OPTICLEANSE GHI": "OPTICLEANSE GHI"
            }
          },
          "Nutritional Supplements - Diet Aids": {
            "AKORN CONSUMER": {
              "DIABETITRIM": "DIABETITRIM"
            },
            "CHATTEM INC": {
              "DEXATRIM MAX": "DEXATRIM MAX",
              "DEXATRIM MAX COMPLEX 7": "DEXATRIM MAX COMPLEX 7",
              "DEXATRIM MAX DAYTIME CONTROL": "DEXATRIM MAX DAYTIME CONTROL",
              "DEXATRIM NATURAL": "DEXATRIM NATURAL",
              "DEXATRIM NATURAL EXTRA ENERGY": "DEXATRIM NATURAL EXTRA ENERGY"
            },
            "GLEN VENTURES": {
              "APP SLIM RMS": "APP SLIM RMS"
            },
            "KRAMER-NOVIS": {
              "DIETEX FORTE": "DIETEX FORTE"
            },
            "MASON VITAMINS": {
              "ACAI WEIGHT CONTROL": "ACAI WEIGHT CONTROL",
              "DIETERS DETOX": "DIETERS DETOX",
              "FAT BLOCKER PLUS": "FAT BLOCKER PLUS",
              "MINUS WEIGHT PLUS ENERGY": "MINUS WEIGHT PLUS ENERGY",
              "ULTRA DIET AID": "ULTRA DIET AID"
            },
            "MEDTECH": {
              "FIBERCHOICE WEIGHT MANAGEMENT": "FIBERCHOICE WEIGHT MANAGEMENT"
            },
            "NATROL": {
              "CARB INTERCEPT/PHASE 2": "CARB INTERCEPT/PHASE 2"
            },
            "P & G HEALTH": {
              "META APPETITE CONTROL": "META APPETITE CONTROL"
            },
            "REESE PHARMACEUTICAL": {
              "DIETERS SUPER TEA": "DIETERS SUPER TEA"
            },
            "RITE AID CORPORATION": {
              "RA FIBER WEIGHT MANAGEMENT": "RA FIBER WEIGHT MANAGEMENT"
            }
          }
        }
      },
      "DIGESTIVE AIDS": {
        "Digestive Aids - Mixtures": {
          "Digestive Aids - Mixtures": {
            "MILLER": {
              "KARBOZYME": "KARBOZYME",
              "PROTEOLYTIC ENZYMES": "PROTEOLYTIC ENZYMES"
            },
            "PLAINVIEW LLC": {
              "DAIRYCARE": "DAIRYCARE"
            },
            "PURE ENCAPSULATIONS": {
              "DIGESTION GB": "DIGESTION GB"
            }
          }
        },
        "Digestive Enzymes": {
          "Digestive Enzyme Combinations": {
            "21ST CENTURY HEALTHCARE": {
              "DIGESTIVE ENZYMES": "DIGESTIVE ENZYMES"
            },
            "BIO-TECH": {
              "DIGEST II": "DIGEST II",
              "DIGEST III": "DIGEST III"
            },
            "CHATTEM INC": {
              "OMNIGEST EZ": "OMNIGEST EZ"
            },
            "CHRONOHEALTH": {
              "DIGESTIVE SUPPORT": "DIGESTIVE SUPPORT",
              "HEPATITIS SUPPORT": "HEPATITIS SUPPORT"
            },
            "EQUALINE": {
              "EQL DIGESTIVE ENZYMES": "EQL DIGESTIVE ENZYMES"
            },
            "FREEDA VITAMINS": {
              "PARVENZYME DIGESTIVE ENZYME": "PARVENZYME DIGESTIVE ENZYME"
            },
            "INTEGRATIVE THERAPEUTICS": {
              "BETAINE HCL": "BETAINE HCL",
              "BIO-ZYME": "BIO-ZYME",
              "LIPASE CONCENTRATE-HP": "LIPASE CONCENTRATE-HP",
              "PANCREATIN 8X": "PANCREATIN 8X",
              "PANPLEX 2-PHASE": "PANPLEX 2-PHASE",
              "SIMILASE JR": "SIMILASE JR",
              "SIMILASE LIPO": "SIMILASE LIPO",
              "TYLER PANPLEX 2-PHASE": "TYLER PANPLEX 2-PHASE",
              "TYLER SIMILASE": "TYLER SIMILASE",
              "TYLER SIMILASE SENSITIVE": "TYLER SIMILASE SENSITIVE"
            },
            "KRAMER-NOVIS": {
              "ABATRACE": "ABATRACE"
            },
            "MAGNO-HUMPHRIES LABORATORIES": {
              "BETAINE HCL": "BETAINE HCL",
              "ENZYME DIGEST": "ENZYME DIGEST"
            },
            "MASON VITAMINS": {
              "DIGESTIVE ENZYMES": "DIGESTIVE ENZYMES",
              "ENZYMATIC DIGESTANT": "ENZYMATIC DIGESTANT"
            },
            "MILLER": {
              "MILCO-ZYME": "MILCO-ZYME",
              "MM-ZYME": "MM-ZYME"
            },
            "NAT-RUL HEALTH PRODUCTS": {
              "SUPER PAPAYA PLUS": "SUPER PAPAYA PLUS"
            },
            "NATIONAL VITAMIN": {
              "DIGESTIVE ENZYME": "DIGESTIVE ENZYME"
            },
            "NATURES BOUNTY": {
              "DIGEST 13": "DIGEST 13",
              "MULTI-ENZYME": "MULTI-ENZYME"
            },
            "NOW HEALTH GROUP": {
              "SUPER ENZYMES": "SUPER ENZYMES"
            },
            "PATHOBIOME": {
              "BIOHM PREBIOTIC SUPPLEMENT": "BIOHM PREBIOTIC SUPPLEMENT"
            },
            "PRONOVA CORPORATION": {
              "DIGAZ": "DIGAZ"
            },
            "PURE ENCAPSULATIONS": {
              "DIGESTIVE ENZYME ULTRA": "DIGESTIVE ENZYME ULTRA"
            },
            "SEYER PHARMATEC": {
              "GASTRACE DIGESTIVE SUPPORT": "GASTRACE DIGESTIVE SUPPORT"
            },
            "STERLING KNIGHT PHARMACEUTICAL": {
              "ENZADYNE": "ENZADYNE"
            },
            "THE KEY COMPANY": {
              "ACIDOLL": "ACIDOLL",
              "ACIGEST": "ACIGEST",
              "ACIGEST II": "ACIGEST II",
              "DUODENUM": "DUODENUM"
            },
            "WALGREENS": {
              "PAPAYA AND ENZYMES": "PAPAYA AND ENZYMES"
            },
            "WINDMILL CONSUMER PRODUCTS": {
              "PAPAYA/PINEAPPLE ENZYMES": "PAPAYA/PINEAPPLE ENZYMES"
            }
          },
          "Digestive Enzymes": {
            "ABBVIE": {
              "CREON": "CREON"
            },
            "ALLERGAN": {
              "ZENPEP": "ZENPEP"
            },
            "AMERICAN HEALTH PACKAGING": {
              "DAIRY RELIEF": "DAIRY RELIEF"
            },
            "AMERICAN SALES COMPANY": {
              "LACTASE ENZYME FAST ACTING": "LACTASE ENZYME FAST ACTING"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP FAST ACTING DAIRY RELIEF": "GNP FAST ACTING DAIRY RELIEF"
            },
            "APTALIS PHARMA": {
              "ULTRESA": "ULTRESA"
            },
            "BERGEN BRUNSWIG": {
              "GNP DAIRY RELIEF": "GNP DAIRY RELIEF"
            },
            "CHAIN DRUG CONSORTIUM": {
              "DAIRY DIGEST EXTRA": "DAIRY DIGEST EXTRA",
              "DAIRY DIGEST FAST ACTING": "DAIRY DIGEST FAST ACTING",
              "QUICK ACTION DAIRY RELIEF": "QUICK ACTION DAIRY RELIEF"
            },
            "COSTCO WHOLESALE": {
              "KLS FAST ACTING LACTASE": "KLS FAST ACTING LACTASE"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS DAIRY RELIEF": "CVS DAIRY RELIEF",
              "CVS DAIRY RELIEF EX ST": "CVS DAIRY RELIEF EX ST",
              "CVS DAIRY RELIEF FAST ACTING": "CVS DAIRY RELIEF FAST ACTING",
              "CVS LACTASE": "CVS LACTASE",
              "CVS LACTASE ENZYME ULTRA STR": "CVS LACTASE ENZYME ULTRA STR"
            },
            "DIGESTIVE CARE": {
              "PERTZYE": "PERTZYE"
            },
            "EQUALINE": {
              "EQL DAIRY DIGEST FAST ACTING": "EQL DAIRY DIGEST FAST ACTING",
              "EQL DAIRY DIGESTIVE EXTRA": "EQL DAIRY DIGESTIVE EXTRA",
              "EQL DAIRY DIGESTIVE ULTRA": "EQL DAIRY DIGESTIVE ULTRA"
            },
            "JANSSEN": {
              "PANCREAZE": "PANCREAZE"
            },
            "KROGER COMPANY": {
              "DAIRY DIGESTIVE SUPPLEMENT": "DAIRY DIGESTIVE SUPPLEMENT"
            },
            "LEADER BRAND PRODUCTS": {
              "DAIRY DIGEST ULTRA": "DAIRY DIGEST ULTRA",
              "DAIRY DIGESTIVE": "DAIRY DIGESTIVE"
            },
            "MAJOR PHARMACEUTICALS": {
              "LACTASE": "LACTASE",
              "LACTASE ENZYME": "LACTASE ENZYME",
              "LACTASE FAST ACTING": "LACTASE FAST ACTING"
            },
            "MCKESSON SUNMARK": {
              "SM ULTRA DAIRY DIGESTIVE": "SM ULTRA DAIRY DIGESTIVE"
            },
            "MCNEIL CONSUMER": {
              "LACTAID": "LACTAID",
              "LACTAID FAST ACT": "LACTAID FAST ACT"
            },
            "MEDICINE SHOPPE": {
              "DAIRY-DIGESTIVE": "DAIRY-DIGESTIVE"
            },
            "NESTLE HEALTHCARE NUTRITION": {
              "VIOKACE": "VIOKACE",
              "ZENPEP": "ZENPEP"
            },
            "PCCA": {
              "PEPSIN": "PEPSIN"
            },
            "PUBLIX SUPER MARKETS INC.": {
              "PX DAIRY DIGESTIVE": "PX DAIRY DIGESTIVE"
            },
            "QOL MEDICAL": {
              "SUCRAID": "SUCRAID"
            },
            "RELIABLE 1 LABS": {
              "LACTASE ENZYME": "LACTASE ENZYME"
            },
            "RICHMOND PHARMACEUTICALS": {
              "DAIRY RELIEF": "DAIRY RELIEF"
            },
            "RITE AID CORPORATION": {
              "RA DAIRY AID": "RA DAIRY AID",
              "RA DAIRY RELIEF FAST ACTING": "RA DAIRY RELIEF FAST ACTING"
            },
            "RUGBY LABORATORIES": {
              "LAC-DOSE": "LAC-DOSE"
            },
            "SELECT BRAND": {
              "SB DAIRY RELIEF": "SB DAIRY RELIEF",
              "SB LACTASE": "SB LACTASE"
            },
            "SUN PHARMACEUTICALS": {
              "SURELAC": "SURELAC"
            },
            "TARGET": {
              "TGT DAIRY DIGESTIVE AID": "TGT DAIRY DIGESTIVE AID"
            },
            "THE KEY COMPANY": {
              "PANCREATIN": "PANCREATIN"
            },
            "TOPCO": {
              "FAST ACTING DAIRY AID": "FAST ACTING DAIRY AID"
            },
            "TWIN LABS": {
              "PANCREATIN": "PANCREATIN"
            },
            "VIVUS": {
              "PANCREAZE": "PANCREAZE"
            },
            "WAL-MART": {
              "EQ DAIRY DIGESTIVE FAST ACTING": "EQ DAIRY DIGESTIVE FAST ACTING"
            },
            "WALGREENS": {
              "DAIRY DIGESTIVE ULTRA": "DAIRY DIGESTIVE ULTRA",
              "LACTOSE FAST ACTING RELIEF": "LACTOSE FAST ACTING RELIEF"
            }
          }
        },
        "Gastric Acidifiers": {
          "Gastric Acidifiers": {
            "FREEDA VITAMINS": {
              "BETAINE HCL": "BETAINE HCL"
            },
            "PCCA": {
              "L-GLUTAMIC ACID HCL": "L-GLUTAMIC ACID HCL"
            }
          }
        }
      },
      "DIURETICS": {
        "Carbonic Anhydrase Inhibitors": {
          "Carbonic Anhydrase Inhibitors": {
            "A-S MEDICATION SOLUTIONS": {
              "ACETAZOLAMIDE": "ACETAZOLAMIDE",
              "ACETAZOLAMIDE ER": "ACETAZOLAMIDE ER",
              "METHAZOLAMIDE": "METHAZOLAMIDE"
            },
            "ALEMBIC PHARMACEUTICALS": {
              "ACETAZOLAMIDE ER": "ACETAZOLAMIDE ER"
            },
            "AMERICAN HEALTH PACKAGING": {
              "ACETAZOLAMIDE": "ACETAZOLAMIDE",
              "ACETAZOLAMIDE ER": "ACETAZOLAMIDE ER"
            },
            "ANI  PHARMACEUTICALS": {
              "METHAZOLAMIDE": "METHAZOLAMIDE"
            },
            "AVET PHARMACEUTICALS": {
              "ACETAZOLAMIDE": "ACETAZOLAMIDE",
              "ACETAZOLAMIDE ER": "ACETAZOLAMIDE ER"
            },
            "AVKARE": {
              "ACETAZOLAMIDE": "ACETAZOLAMIDE"
            },
            "AVPAK": {
              "ACETAZOLAMIDE": "ACETAZOLAMIDE"
            },
            "BRECKENRIDGE": {
              "ACETAZOLAMIDE": "ACETAZOLAMIDE"
            },
            "EYWA PHARMA": {
              "ACETAZOLAMIDE": "ACETAZOLAMIDE"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "ACETAZOLAMIDE": "ACETAZOLAMIDE",
              "METHAZOLAMIDE": "METHAZOLAMIDE"
            },
            "HIKMA": {
              "ACETAZOLAMIDE SODIUM": "ACETAZOLAMIDE SODIUM"
            },
            "INGENUS PHARMACEUTICALS": {
              "ACETAZOLAMIDE ER": "ACETAZOLAMIDE ER"
            },
            "LANNETT": {
              "ACETAZOLAMIDE": "ACETAZOLAMIDE"
            },
            "MAJOR PHARMACEUTICALS": {
              "ACETAZOLAMIDE": "ACETAZOLAMIDE"
            },
            "MARLEX PHARMACEUTICALS": {
              "ACETAZOLAMIDE": "ACETAZOLAMIDE"
            },
            "MYLAN INSTITUTIONAL": {
              "ACETAZOLAMIDE SODIUM": "ACETAZOLAMIDE SODIUM"
            },
            "NOSTRUM LABORATORIES": {
              "ACETAZOLAMIDE ER": "ACETAZOLAMIDE ER"
            },
            "NOVAPLUS/X-GEN": {
              "ACETAZOLAMIDE SODIUM": "ACETAZOLAMIDE SODIUM"
            },
            "NOVITIUM PHARMA": {
              "ACETAZOLAMIDE": "ACETAZOLAMIDE"
            },
            "OCEANSIDE PHARMACEUTICALS": {
              "METHAZOLAMIDE": "METHAZOLAMIDE"
            },
            "PCCA": {
              "METHAZOLAMIDE": "METHAZOLAMIDE"
            },
            "PDRX PHARMACEUTICAL": {
              "ACETAZOLAMIDE": "ACETAZOLAMIDE"
            },
            "PDRX PHARMACEUTICALS": {
              "ACETAZOLAMIDE": "ACETAZOLAMIDE"
            },
            "PERRIGO": {
              "METHAZOLAMIDE": "METHAZOLAMIDE",
              "NEPTAZANE": "NEPTAZANE"
            },
            "PROFICIENT RX": {
              "ACETAZOLAMIDE": "ACETAZOLAMIDE"
            },
            "QUALITY CARE": {
              "ACETAZOLAMIDE": "ACETAZOLAMIDE"
            },
            "SAGENT PHARMACEUTICAL": {
              "ACETAZOLAMIDE SODIUM": "ACETAZOLAMIDE SODIUM"
            },
            "SANDOZ": {
              "METHAZOLAMIDE": "METHAZOLAMIDE"
            },
            "STRIDES PHARMA": {
              "ACETAZOLAMIDE": "ACETAZOLAMIDE"
            },
            "STRONGBRIDGE BIOPHARMA": {
              "KEVEYIS": "KEVEYIS"
            },
            "TARO": {
              "ACETAZOLAMIDE": "ACETAZOLAMIDE",
              "KEVEYIS": "KEVEYIS"
            },
            "TEVA PHARMACEUTICALS USA": {
              "ACETAZOLAMIDE ER": "ACETAZOLAMIDE ER"
            },
            "TEVA/WOMENS HEALTH": {
              "DIAMOX SEQUELS": "DIAMOX SEQUELS"
            },
            "X-GEN PHARMACEUTICALS, INC": {
              "ACETAZOLAMIDE SODIUM": "ACETAZOLAMIDE SODIUM"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "ACETAZOLAMIDE ER": "ACETAZOLAMIDE ER"
            }
          }
        },
        "Diuretic Combinations": {
          "Diuretic Combinations": {
            "A-S MEDICATION SOLUTIONS": {
              "AMILORIDE-HYDROCHLOROTHIAZIDE": "AMILORIDE-HYDROCHLOROTHIAZIDE",
              "SPIRONOLACTONE-HCTZ": "SPIRONOLACTONE-HCTZ",
              "TRIAMTERENE-HCTZ": "TRIAMTERENE-HCTZ"
            },
            "ACTAVIS PHARMA": {
              "TRIAMTERENE-HCTZ": "TRIAMTERENE-HCTZ"
            },
            "AIDAREX PHARMACEUTICALS": {
              "TRIAMTERENE-HCTZ": "TRIAMTERENE-HCTZ"
            },
            "ALTURA PHARMACEUTICALS": {
              "TRIAMTERENE-HCTZ": "TRIAMTERENE-HCTZ"
            },
            "AMERICAN HEALTH PACKAGING": {
              "SPIRONOLACTONE-HCTZ": "SPIRONOLACTONE-HCTZ",
              "TRIAMTERENE-HCTZ": "TRIAMTERENE-HCTZ"
            },
            "APHENA PHARMA SOLUTIONS": {
              "TRIAMTERENE-HCTZ": "TRIAMTERENE-HCTZ"
            },
            "APOTEX": {
              "TRIAMTERENE-HCTZ": "TRIAMTERENE-HCTZ"
            },
            "AVKARE": {
              "TRIAMTERENE-HCTZ": "TRIAMTERENE-HCTZ"
            },
            "BLENHEIM PHARMACAL": {
              "TRIAMTERENE-HCTZ": "TRIAMTERENE-HCTZ"
            },
            "BLUE POINT LABORATORIES": {
              "TRIAMTERENE-HCTZ": "TRIAMTERENE-HCTZ"
            },
            "BRYANT RANCH PREPACK": {
              "SPIRONOLACTONE-HCTZ": "SPIRONOLACTONE-HCTZ",
              "TRIAMTERENE-HCTZ": "TRIAMTERENE-HCTZ"
            },
            "DENTON PHARMA": {
              "TRIAMTERENE-HCTZ": "TRIAMTERENE-HCTZ"
            },
            "DIRECT RX": {
              "TRIAMTERENE-HCTZ": "TRIAMTERENE-HCTZ"
            },
            "GLAXO SMITH KLINE": {
              "DYAZIDE": "DYAZIDE"
            },
            "GREENSTONE": {
              "SPIRONOLACTONE-HCTZ": "SPIRONOLACTONE-HCTZ"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "TRIAMTERENE-HCTZ": "TRIAMTERENE-HCTZ"
            },
            "KAISER FOUNDATION HOSPITAL": {
              "TRIAMTERENE-HCTZ": "TRIAMTERENE-HCTZ"
            },
            "LANNETT": {
              "TRIAMTERENE-HCTZ": "TRIAMTERENE-HCTZ"
            },
            "LIBERTY PHARMACEUTICAL": {
              "SPIRONOLACTONE-HCTZ": "SPIRONOLACTONE-HCTZ",
              "TRIAMTERENE-HCTZ": "TRIAMTERENE-HCTZ"
            },
            "MARLEX PHARMACEUTICALS": {
              "TRIAMTERENE-HCTZ": "TRIAMTERENE-HCTZ"
            },
            "MEDVANTX": {
              "TRIAMTERENE-HCTZ": "TRIAMTERENE-HCTZ"
            },
            "MYLAN": {
              "AMILORIDE-HYDROCHLOROTHIAZIDE": "AMILORIDE-HYDROCHLOROTHIAZIDE",
              "MAXZIDE": "MAXZIDE",
              "MAXZIDE-25": "MAXZIDE-25",
              "SPIRONOLACTONE-HCTZ": "SPIRONOLACTONE-HCTZ",
              "TRIAMTERENE-HCTZ": "TRIAMTERENE-HCTZ"
            },
            "MYLAN INSTITUTIONAL": {
              "SPIRONOLACTONE-HCTZ": "SPIRONOLACTONE-HCTZ",
              "TRIAMTERENE-HCTZ": "TRIAMTERENE-HCTZ"
            },
            "NUCARE PHARMACEUTICALS": {
              "TRIAMTERENE-HCTZ": "TRIAMTERENE-HCTZ"
            },
            "PDRX PHARMACEUTICAL": {
              "DYAZIDE": "DYAZIDE",
              "TRIAMTERENE-HCTZ": "TRIAMTERENE-HCTZ"
            },
            "PFIZER U.S.": {
              "ALDACTAZIDE": "ALDACTAZIDE"
            },
            "PHARMEDIX": {
              "TRIAMTERENE-HCTZ": "TRIAMTERENE-HCTZ"
            },
            "PREFERRED PHARMACEUTICALS": {
              "TRIAMTERENE-HCTZ": "TRIAMTERENE-HCTZ"
            },
            "PRESCRIPTION SOLUTIONS, INC.": {
              "TRIAMTERENE-HCTZ": "TRIAMTERENE-HCTZ"
            },
            "PROFICIENT RX": {
              "TRIAMTERENE-HCTZ": "TRIAMTERENE-HCTZ"
            },
            "QUALITY CARE": {
              "TRIAMTERENE-HCTZ": "TRIAMTERENE-HCTZ"
            },
            "QUALITY CARE PRODUCTS": {
              "TRIAMTERENE-HCTZ": "TRIAMTERENE-HCTZ"
            },
            "RXCHANGE CO": {
              "TRIAMTERENE-HCTZ": "TRIAMTERENE-HCTZ"
            },
            "SANDOZ": {
              "TRIAMTERENE-HCTZ": "TRIAMTERENE-HCTZ"
            },
            "ST MARY'S MPP": {
              "TRIAMTERENE-HCTZ": "TRIAMTERENE-HCTZ"
            },
            "SUN PHARMACEUTICALS": {
              "SPIRONOLACTONE-HCTZ": "SPIRONOLACTONE-HCTZ"
            },
            "TEVA PHARMACEUTICALS USA": {
              "AMILORIDE-HYDROCHLOROTHIAZIDE": "AMILORIDE-HYDROCHLOROTHIAZIDE"
            },
            "VA CMOP DALLAS": {
              "TRIAMTERENE-HCTZ": "TRIAMTERENE-HCTZ"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "TRIAMTERENE-HCTZ": "TRIAMTERENE-HCTZ"
            }
          },
          "Non Prescription Diuretics": {
            "ALVA-AMCO": {
              "DIUREX": "DIUREX"
            },
            "MAJOR PHARMACEUTICALS": {
              "HYDRO-TABS": "HYDRO-TABS",
              "WATER TABS": "WATER TABS"
            },
            "MASON VITAMINS": {
              "NATURAL HERBAL DIURETIC": "NATURAL HERBAL DIURETIC"
            },
            "NATURES BOUNTY": {
              "WATER PILL/POTASSIUM": "WATER PILL/POTASSIUM",
              "WATER PILL/POTASSIUM EX ST": "WATER PILL/POTASSIUM EX ST"
            },
            "REXALL SUNDOWN": {
              "WATER PILLS": "WATER PILLS"
            }
          }
        },
        "Diuretics - Miscellaneous": {
          "Diuretics - Miscellaneous": {
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS DIURETIC MAXIMUM STRENGTH": "CVS DIURETIC MAXIMUM STRENGTH"
            }
          }
        },
        "Loop Diuretics": {
          "Loop Diuretics": {
            "A-S MEDICATION SOLUTIONS": {
              "FUROSEMIDE": "FUROSEMIDE"
            },
            "ACTAVIS PHARMA": {
              "FUROSEMIDE": "FUROSEMIDE"
            },
            "AIDAREX PHARMACEUTICALS": {
              "BUMETANIDE": "BUMETANIDE",
              "FUROSEMIDE": "FUROSEMIDE",
              "TORSEMIDE": "TORSEMIDE"
            },
            "ALTURA PHARMACEUTICALS": {
              "FUROSEMIDE": "FUROSEMIDE"
            },
            "ALVOGEN": {
              "ETHACRYNIC ACID": "ETHACRYNIC ACID"
            },
            "AMERICAN HEALTH PACKAGING": {
              "BUMETANIDE": "BUMETANIDE",
              "TORSEMIDE": "TORSEMIDE"
            },
            "AMNEAL BIOSCIENCES": {
              "FUROSEMIDE": "FUROSEMIDE"
            },
            "AMNEAL PHARMACEUTICALS": {
              "BUMETANIDE": "BUMETANIDE",
              "ETHACRYNIC ACID": "ETHACRYNIC ACID"
            },
            "APHENA PHARMA SOLUTIONS": {
              "BUMETANIDE": "BUMETANIDE",
              "FUROSEMIDE": "FUROSEMIDE",
              "TORSEMIDE": "TORSEMIDE"
            },
            "APOTEX": {
              "TORSEMIDE": "TORSEMIDE"
            },
            "AUROBINDO PHARMA": {
              "TORSEMIDE": "TORSEMIDE"
            },
            "AUROMEDICS PHARMA": {
              "FUROSEMIDE": "FUROSEMIDE"
            },
            "AVET PHARMACEUTICALS": {
              "BUMETANIDE": "BUMETANIDE",
              "FUROSEMIDE": "FUROSEMIDE"
            },
            "AVKARE": {
              "BUMETANIDE": "BUMETANIDE",
              "TORSEMIDE": "TORSEMIDE"
            },
            "AVPAK": {
              "BUMETANIDE": "BUMETANIDE",
              "TORSEMIDE": "TORSEMIDE"
            },
            "BAUSCH HEALTH": {
              "EDECRIN": "EDECRIN",
              "SODIUM EDECRIN": "SODIUM EDECRIN"
            },
            "BAXTER HEALTHCARE CORP": {
              "FUROSEMIDE": "FUROSEMIDE"
            },
            "BLENHEIM PHARMACAL": {
              "FUROSEMIDE": "FUROSEMIDE"
            },
            "BRYANT RANCH PREPACK": {
              "BUMETANIDE": "BUMETANIDE",
              "FUROSEMIDE": "FUROSEMIDE",
              "TORSEMIDE": "TORSEMIDE"
            },
            "CAMBER PHARMACEUTICALS": {
              "TORSEMIDE": "TORSEMIDE"
            },
            "CARDINAL HEALTH REPACKAGING SO": {
              "FUROSEMIDE": "FUROSEMIDE"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "FUROSEMIDE": "FUROSEMIDE"
            },
            "DIRECT RX": {
              "FUROSEMIDE": "FUROSEMIDE",
              "TORSEMIDE": "TORSEMIDE"
            },
            "EDENBRIDGE PHARMACEUTICALS": {
              "BUMETANIDE": "BUMETANIDE",
              "ETHACRYNIC ACID": "ETHACRYNIC ACID"
            },
            "FAGRON": {
              "FUROSEMIDE": "FUROSEMIDE"
            },
            "FRESENIUS KABI USA": {
              "FUROSEMIDE": "FUROSEMIDE"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "ETHACRYNIC ACID": "ETHACRYNIC ACID",
              "FUROSEMIDE": "FUROSEMIDE",
              "TORSEMIDE": "TORSEMIDE"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "FUROSEMIDE": "FUROSEMIDE"
            },
            "HIKMA": {
              "BUMETANIDE": "BUMETANIDE",
              "ETHACRYNIC ACID": "ETHACRYNIC ACID",
              "FUROSEMIDE": "FUROSEMIDE",
              "TORSEMIDE": "TORSEMIDE"
            },
            "HOSPIRA": {
              "BUMETANIDE": "BUMETANIDE",
              "FUROSEMIDE": "FUROSEMIDE"
            },
            "INGENUS PHARMACEUTICALS": {
              "FUROSEMIDE": "FUROSEMIDE"
            },
            "LEADING PHARMA": {
              "FUROSEMIDE": "FUROSEMIDE"
            },
            "LETCO MEDICAL": {
              "FUROSEMIDE": "FUROSEMIDE"
            },
            "LIBERTY PHARMACEUTICAL": {
              "BUMETANIDE": "BUMETANIDE",
              "FUROSEMIDE": "FUROSEMIDE"
            },
            "LUPIN PHARMACEUTICALS": {
              "ETHACRYNIC ACID": "ETHACRYNIC ACID"
            },
            "MAJOR PHARMACEUTICALS": {
              "BUMETANIDE": "BUMETANIDE",
              "FUROSEMIDE": "FUROSEMIDE"
            },
            "MEDA PHARMACEUTICALS": {
              "DEMADEX": "DEMADEX"
            },
            "MEDVANTX": {
              "FUROSEMIDE": "FUROSEMIDE"
            },
            "MORTON GROVE PHARMACEUTICALS": {
              "FUROSEMIDE": "FUROSEMIDE"
            },
            "MYLAN": {
              "FUROSEMIDE": "FUROSEMIDE"
            },
            "MYLAN INSTITUTIONAL": {
              "ETHACRYNATE SODIUM": "ETHACRYNATE SODIUM",
              "FUROSEMIDE": "FUROSEMIDE"
            },
            "MYLAN SPECIALTY L.P.": {
              "DEMADEX": "DEMADEX"
            },
            "NEW HORIZON RX GROUP": {
              "FUROSEMIDE": "FUROSEMIDE"
            },
            "NORTHSTAR RX": {
              "BUMETANIDE": "BUMETANIDE"
            },
            "NOVAPLUS/HIKMA": {
              "BUMETANIDE": "BUMETANIDE"
            },
            "NOVAPLUS/HOSPIRA": {
              "BUMETANIDE": "BUMETANIDE",
              "FUROSEMIDE": "FUROSEMIDE"
            },
            "NOVAPLUS/OCEANSIDE": {
              "ETHACRYNATE SODIUM": "ETHACRYNATE SODIUM"
            },
            "NOVAPLUS/PAR": {
              "ETHACRYNATE SODIUM": "ETHACRYNATE SODIUM"
            },
            "NUCARE PHARMACEUTICALS": {
              "FUROSEMIDE": "FUROSEMIDE"
            },
            "OCEANSIDE PHARMACEUTICALS": {
              "ETHACRYNATE SODIUM": "ETHACRYNATE SODIUM",
              "ETHACRYNIC ACID": "ETHACRYNIC ACID"
            },
            "PAR PHARMACEUTICAL": {
              "ETHACRYNIC ACID": "ETHACRYNIC ACID"
            },
            "PAR PHARMACEUTICALS": {
              "FUROSEMIDE": "FUROSEMIDE",
              "TORSEMIDE": "TORSEMIDE"
            },
            "PAR STERILE PRODUCTS": {
              "ETHACRYNATE SODIUM": "ETHACRYNATE SODIUM"
            },
            "PCCA": {
              "FUROSEMIDE": "FUROSEMIDE"
            },
            "PDRX PHARMACEUTICAL": {
              "FUROSEMIDE": "FUROSEMIDE"
            },
            "PDRX PHARMACEUTICALS": {
              "FUROSEMIDE": "FUROSEMIDE"
            },
            "PHARMEDIUM SERVICES": {
              "FUROSEMIDE IN SODIUM CHLORIDE": "FUROSEMIDE IN SODIUM CHLORIDE"
            },
            "PHARMEDIX": {
              "FUROSEMIDE": "FUROSEMIDE"
            },
            "PHARMPAK": {
              "FUROSEMIDE": "FUROSEMIDE"
            },
            "PRECISION DOSE, INC": {
              "FUROSEMIDE": "FUROSEMIDE"
            },
            "PREFERRED PHARMACEUTICALS": {
              "FUROSEMIDE": "FUROSEMIDE"
            },
            "PROFICIENT RX": {
              "FUROSEMIDE": "FUROSEMIDE"
            },
            "QUALITY CARE": {
              "BUMETANIDE": "BUMETANIDE",
              "FUROSEMIDE": "FUROSEMIDE"
            },
            "QUALITY CARE PRODUCTS": {
              "FUROSEMIDE": "FUROSEMIDE"
            },
            "QUVA PHARMA": {
              "FUROSEMIDE IN SODIUM CHLORIDE": "FUROSEMIDE IN SODIUM CHLORIDE"
            },
            "RISING PHARMACEUTICALS": {
              "TORSEMIDE": "TORSEMIDE"
            },
            "RXCHANGE CO": {
              "FUROSEMIDE": "FUROSEMIDE"
            },
            "SANDOZ": {
              "BUMETANIDE": "BUMETANIDE",
              "FUROSEMIDE": "FUROSEMIDE"
            },
            "SANOFI-AVENTIS U.S.": {
              "LASIX": "LASIX"
            },
            "SCIEGEN PHARMACEUTICALS": {
              "ETHACRYNIC ACID": "ETHACRYNIC ACID"
            },
            "SKY PACKAGING": {
              "FUROSEMIDE": "FUROSEMIDE"
            },
            "SOLCO HEALTHCARE": {
              "FUROSEMIDE": "FUROSEMIDE"
            },
            "SPECTRUM": {
              "FUROSEMIDE": "FUROSEMIDE"
            },
            "ST MARY'S MPP": {
              "FUROSEMIDE": "FUROSEMIDE"
            },
            "SUN PHARMACEUTICALS": {
              "FUROSEMIDE": "FUROSEMIDE"
            },
            "TEVA PHARMACEUTICALS USA": {
              "BUMETANIDE": "BUMETANIDE",
              "FUROSEMIDE": "FUROSEMIDE",
              "TORSEMIDE": "TORSEMIDE"
            },
            "TRUPHARMA": {
              "ETHACRYNIC ACID": "ETHACRYNIC ACID"
            },
            "UNIT DOSE SERVICES": {
              "FUROSEMIDE": "FUROSEMIDE"
            },
            "UPSHER-SMITH": {
              "BUMETANIDE": "BUMETANIDE",
              "ETHACRYNIC ACID": "ETHACRYNIC ACID"
            },
            "VALIDUS PHARMACEUTICALS": {
              "BUMEX": "BUMEX",
              "LASIX": "LASIX"
            },
            "VANGARD": {
              "BUMETANIDE": "BUMETANIDE",
              "FUROSEMIDE": "FUROSEMIDE"
            },
            "WOCKHARDT USA": {
              "FUROSEMIDE": "FUROSEMIDE"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "BUMETANIDE": "BUMETANIDE",
              "ETHACRYNATE SODIUM": "ETHACRYNATE SODIUM"
            }
          }
        },
        "Osmotic Diuretics": {
          "Osmotic Diuretics": {
            "B. BRAUN MEDICAL": {
              "MANNITOL": "MANNITOL"
            },
            "BAXTER HEALTHCARE CORP": {
              "OSMITROL": "OSMITROL"
            },
            "FRESENIUS KABI USA": {
              "MANNITOL": "MANNITOL"
            },
            "HOSPIRA": {
              "MANNITOL": "MANNITOL"
            },
            "ICU MEDICAL": {
              "MANNITOL": "MANNITOL"
            },
            "NOVAPLUS/FRESENIUS KABI": {
              "MANNITOL": "MANNITOL"
            }
          }
        },
        "Potassium Sparing Diuretics": {
          "Potassium Sparing Diuretics": {
            "A-S MEDICATION SOLUTIONS": {
              "SPIRONOLACTONE": "SPIRONOLACTONE"
            },
            "ACCORD HEALTHCARE": {
              "SPIRONOLACTONE": "SPIRONOLACTONE"
            },
            "ACTAVIS ELIZABETH": {
              "SPIRONOLACTONE": "SPIRONOLACTONE"
            },
            "AIDAREX PHARMACEUTICALS": {
              "SPIRONOLACTONE": "SPIRONOLACTONE"
            },
            "AMERICAN HEALTH PACKAGING": {
              "SPIRONOLACTONE": "SPIRONOLACTONE"
            },
            "AMNEAL PHARMACEUTICALS": {
              "SPIRONOLACTONE": "SPIRONOLACTONE"
            },
            "APHENA PHARMA SOLUTIONS": {
              "ALDACTONE": "ALDACTONE",
              "SPIRONOLACTONE": "SPIRONOLACTONE"
            },
            "AVKARE": {
              "AMILORIDE HCL": "AMILORIDE HCL"
            },
            "AVPAK": {
              "AMILORIDE HCL": "AMILORIDE HCL",
              "SPIRONOLACTONE": "SPIRONOLACTONE"
            },
            "BLENHEIM PHARMACAL": {
              "SPIRONOLACTONE": "SPIRONOLACTONE"
            },
            "BRYANT RANCH PREPACK": {
              "SPIRONOLACTONE": "SPIRONOLACTONE"
            },
            "CADISTA": {
              "SPIRONOLACTONE": "SPIRONOLACTONE"
            },
            "CMP PHARMA": {
              "CAROSPIR": "CAROSPIR"
            },
            "CONCORDIA PHARMACEUTICALS": {
              "DYRENIUM": "DYRENIUM"
            },
            "DIRECT RX": {
              "SPIRONOLACTONE": "SPIRONOLACTONE"
            },
            "FAGRON": {
              "SPIRONOLACTONE": "SPIRONOLACTONE"
            },
            "FREEDOM PHARMACEUTICALS": {
              "SPIRONOLACTONE": "SPIRONOLACTONE"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "AMILORIDE HCL": "AMILORIDE HCL"
            },
            "GREENSTONE": {
              "SPIRONOLACTONE": "SPIRONOLACTONE"
            },
            "HUMCO": {
              "SPIRONOLACTONE": "SPIRONOLACTONE"
            },
            "LETCO MEDICAL": {
              "SPIRONOLACTONE": "SPIRONOLACTONE"
            },
            "LIBERTY PHARMACEUTICAL": {
              "SPIRONOLACTONE": "SPIRONOLACTONE"
            },
            "MAJOR PHARMACEUTICALS": {
              "SPIRONOLACTONE": "SPIRONOLACTONE"
            },
            "MEDISCA": {
              "SPIRONOLACTONE": "SPIRONOLACTONE"
            },
            "MYLAN": {
              "SPIRONOLACTONE": "SPIRONOLACTONE"
            },
            "MYLAN INSTITUTIONAL": {
              "SPIRONOLACTONE": "SPIRONOLACTONE"
            },
            "NORTHSTAR RX": {
              "SPIRONOLACTONE": "SPIRONOLACTONE"
            },
            "NUCARE PHARMACEUTICALS": {
              "SPIRONOLACTONE": "SPIRONOLACTONE"
            },
            "PAR PHARMACEUTICAL": {
              "AMILORIDE HCL": "AMILORIDE HCL"
            },
            "PAR PHARMACEUTICALS": {
              "SPIRONOLACTONE": "SPIRONOLACTONE"
            },
            "PCCA": {
              "SPIRONOLACTONE": "SPIRONOLACTONE",
              "TRIAMTERENE": "TRIAMTERENE"
            },
            "PDRX PHARMACEUTICAL": {
              "SPIRONOLACTONE": "SPIRONOLACTONE"
            },
            "PERRIGO": {
              "AMILORIDE HCL": "AMILORIDE HCL"
            },
            "PFIZER U.S.": {
              "ALDACTONE": "ALDACTONE"
            },
            "PRASCO LABORATORIES": {
              "TRIAMTERENE": "TRIAMTERENE"
            },
            "PREFERRED PHARMACEUTICALS": {
              "SPIRONOLACTONE": "SPIRONOLACTONE"
            },
            "PROFICIENT RX": {
              "SPIRONOLACTONE": "SPIRONOLACTONE"
            },
            "QUALITY CARE": {
              "SPIRONOLACTONE": "SPIRONOLACTONE"
            },
            "QUALITY CARE PRODUCTS": {
              "SPIRONOLACTONE": "SPIRONOLACTONE"
            },
            "RICHMOND PHARMACEUTICALS": {
              "SPIRONOLACTONE": "SPIRONOLACTONE"
            },
            "RISING PHARMACEUTICALS": {
              "AMILORIDE HCL": "AMILORIDE HCL"
            },
            "SANDOZ": {
              "SPIRONOLACTONE": "SPIRONOLACTONE"
            },
            "SKY PACKAGING": {
              "SPIRONOLACTONE": "SPIRONOLACTONE"
            },
            "SPECTRUM": {
              "AMILORIDE HCL": "AMILORIDE HCL",
              "SPIRONOLACTONE": "SPIRONOLACTONE"
            },
            "ST MARY'S MPP": {
              "SPIRONOLACTONE": "SPIRONOLACTONE"
            },
            "SUN PHARMACEUTICALS": {
              "SPIRONOLACTONE": "SPIRONOLACTONE"
            },
            "TRUPHARMA": {
              "TRIAMTERENE": "TRIAMTERENE"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "SPIRONOLACTONE": "SPIRONOLACTONE"
            }
          }
        },
        "Thiazides and Thiazide-Like Diuretics": {
          "Thiazides and Thiazide-Like Diuretics": {
            "A-S MEDICATION SOLUTIONS": {
              "CHLORTHALIDONE": "CHLORTHALIDONE",
              "HYDROCHLOROTHIAZIDE": "HYDROCHLOROTHIAZIDE",
              "INDAPAMIDE": "INDAPAMIDE"
            },
            "ACCORD HEALTHCARE": {
              "HYDROCHLOROTHIAZIDE": "HYDROCHLOROTHIAZIDE"
            },
            "ACTAVIS ELIZABETH": {
              "HYDROCHLOROTHIAZIDE": "HYDROCHLOROTHIAZIDE",
              "INDAPAMIDE": "INDAPAMIDE"
            },
            "ACTAVIS PHARMA": {
              "HYDROCHLOROTHIAZIDE": "HYDROCHLOROTHIAZIDE"
            },
            "AIDAREX PHARMACEUTICALS": {
              "CHLORTHALIDONE": "CHLORTHALIDONE",
              "HYDROCHLOROTHIAZIDE": "HYDROCHLOROTHIAZIDE"
            },
            "AKORN": {
              "CHLOROTHIAZIDE SODIUM": "CHLOROTHIAZIDE SODIUM",
              "SODIUM DIURIL": "SODIUM DIURIL"
            },
            "ALLERGAN": {
              "MICROZIDE": "MICROZIDE"
            },
            "ALTURA PHARMACEUTICALS": {
              "CHLORTHALIDONE": "CHLORTHALIDONE",
              "HYDROCHLOROTHIAZIDE": "HYDROCHLOROTHIAZIDE"
            },
            "AMERICAN HEALTH PACKAGING": {
              "CHLORTHALIDONE": "CHLORTHALIDONE",
              "HYDROCHLOROTHIAZIDE": "HYDROCHLOROTHIAZIDE",
              "METOLAZONE": "METOLAZONE"
            },
            "AMERICAN REGENT": {
              "CHLOROTHIAZIDE SODIUM": "CHLOROTHIAZIDE SODIUM"
            },
            "AMERIGEN PHARMACEUTICALS": {
              "INDAPAMIDE": "INDAPAMIDE"
            },
            "AMNEAL PHARMACEUTICALS": {
              "CHLORTHALIDONE": "CHLORTHALIDONE"
            },
            "ANI  PHARMACEUTICALS": {
              "INDAPAMIDE": "INDAPAMIDE"
            },
            "APHENA PHARMA SOLUTIONS": {
              "CHLORTHALIDONE": "CHLORTHALIDONE",
              "HYDROCHLOROTHIAZIDE": "HYDROCHLOROTHIAZIDE",
              "METOLAZONE": "METOLAZONE"
            },
            "ASCEND LABORATORIES": {
              "CHLORTHALIDONE": "CHLORTHALIDONE"
            },
            "AUROBINDO PHARMA": {
              "HYDROCHLOROTHIAZIDE": "HYDROCHLOROTHIAZIDE"
            },
            "AVET PHARMACEUTICALS": {
              "HYDROCHLOROTHIAZIDE": "HYDROCHLOROTHIAZIDE"
            },
            "AVKARE": {
              "HYDROCHLOROTHIAZIDE": "HYDROCHLOROTHIAZIDE",
              "INDAPAMIDE": "INDAPAMIDE"
            },
            "AVPAK": {
              "CHLORTHALIDONE": "CHLORTHALIDONE"
            },
            "BAUSCH HEALTH": {
              "DIURIL": "DIURIL"
            },
            "BLENHEIM PHARMACAL": {
              "HYDROCHLOROTHIAZIDE": "HYDROCHLOROTHIAZIDE"
            },
            "BRYANT RANCH PREPACK": {
              "HYDROCHLOROTHIAZIDE": "HYDROCHLOROTHIAZIDE"
            },
            "CADISTA": {
              "HYDROCHLOROTHIAZIDE": "HYDROCHLOROTHIAZIDE"
            },
            "CARDINAL HEALTH REPACKAGING SO": {
              "HYDROCHLOROTHIAZIDE": "HYDROCHLOROTHIAZIDE"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "HYDROCHLOROTHIAZIDE": "HYDROCHLOROTHIAZIDE"
            },
            "DENTON PHARMA": {
              "HYDROCHLOROTHIAZIDE": "HYDROCHLOROTHIAZIDE"
            },
            "DIRECT RX": {
              "HYDROCHLOROTHIAZIDE": "HYDROCHLOROTHIAZIDE"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "CHLORTHALIDONE": "CHLORTHALIDONE"
            },
            "FRESENIUS KABI USA": {
              "CHLOROTHIAZIDE SODIUM": "CHLOROTHIAZIDE SODIUM"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "HYDROCHLOROTHIAZIDE": "HYDROCHLOROTHIAZIDE",
              "INDAPAMIDE": "INDAPAMIDE"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "HYDROCHLOROTHIAZIDE": "HYDROCHLOROTHIAZIDE"
            },
            "HIKMA": {
              "CHLOROTHIAZIDE": "CHLOROTHIAZIDE",
              "HYDROCHLOROTHIAZIDE": "HYDROCHLOROTHIAZIDE"
            },
            "INTERNATIONAL LABS, LLC": {
              "HYDROCHLOROTHIAZIDE": "HYDROCHLOROTHIAZIDE"
            },
            "KAISER FOUNDATION HOSPITAL": {
              "HYDROCHLOROTHIAZIDE": "HYDROCHLOROTHIAZIDE"
            },
            "LANNETT": {
              "HYDROCHLOROTHIAZIDE": "HYDROCHLOROTHIAZIDE",
              "METOLAZONE": "METOLAZONE"
            },
            "LEADING PHARMA": {
              "HYDROCHLOROTHIAZIDE": "HYDROCHLOROTHIAZIDE"
            },
            "LEGACY PHARMACEUTICAL PKG": {
              "HYDROCHLOROTHIAZIDE": "HYDROCHLOROTHIAZIDE"
            },
            "LIBERTY PHARMACEUTICAL": {
              "HYDROCHLOROTHIAZIDE": "HYDROCHLOROTHIAZIDE",
              "INDAPAMIDE": "INDAPAMIDE",
              "METOLAZONE": "METOLAZONE"
            },
            "MAJOR PHARMACEUTICALS": {
              "CHLORTHALIDONE": "CHLORTHALIDONE",
              "METOLAZONE": "METOLAZONE"
            },
            "MEDVANTX": {
              "HYDROCHLOROTHIAZIDE": "HYDROCHLOROTHIAZIDE"
            },
            "MYLAN": {
              "CHLOROTHIAZIDE": "CHLOROTHIAZIDE",
              "CHLORTHALIDONE": "CHLORTHALIDONE",
              "HYDROCHLOROTHIAZIDE": "HYDROCHLOROTHIAZIDE",
              "INDAPAMIDE": "INDAPAMIDE",
              "METHYCLOTHIAZIDE": "METHYCLOTHIAZIDE",
              "METOLAZONE": "METOLAZONE"
            },
            "MYLAN INSTITUTIONAL": {
              "CHLOROTHIAZIDE SODIUM": "CHLOROTHIAZIDE SODIUM",
              "CHLORTHALIDONE": "CHLORTHALIDONE",
              "HYDROCHLOROTHIAZIDE": "HYDROCHLOROTHIAZIDE",
              "INDAPAMIDE": "INDAPAMIDE",
              "METOLAZONE": "METOLAZONE"
            },
            "NIVAGEN PHARMACEUTICALS": {
              "CHLORTHALIDONE": "CHLORTHALIDONE"
            },
            "NORTHSTAR RX": {
              "CHLORTHALIDONE": "CHLORTHALIDONE"
            },
            "NOVAPLUS/FRESENIUS KABI": {
              "CHLOROTHIAZIDE SODIUM": "CHLOROTHIAZIDE SODIUM"
            },
            "NOVAPLUS/SAGENT PHARMACEUTICAL": {
              "CHLOROTHIAZIDE SODIUM": "CHLOROTHIAZIDE SODIUM"
            },
            "NUCARE PHARMACEUTICALS": {
              "HYDROCHLOROTHIAZIDE": "HYDROCHLOROTHIAZIDE"
            },
            "PAR PHARMACEUTICALS": {
              "HYDROCHLOROTHIAZIDE": "HYDROCHLOROTHIAZIDE"
            },
            "PCCA": {
              "HYDROCHLOROTHIAZIDE": "HYDROCHLOROTHIAZIDE"
            },
            "PDRX PHARMACEUTICAL": {
              "CHLOROTHIAZIDE": "CHLOROTHIAZIDE",
              "CHLORTHALIDONE": "CHLORTHALIDONE",
              "HYDROCHLOROTHIAZIDE": "HYDROCHLOROTHIAZIDE",
              "INDAPAMIDE": "INDAPAMIDE",
              "METOLAZONE": "METOLAZONE"
            },
            "PHARMEDIX": {
              "HYDROCHLOROTHIAZIDE": "HYDROCHLOROTHIAZIDE"
            },
            "PHARMPAK": {
              "HYDROCHLOROTHIAZIDE": "HYDROCHLOROTHIAZIDE"
            },
            "PREFERRED PHARMACEUTICALS": {
              "HYDROCHLOROTHIAZIDE": "HYDROCHLOROTHIAZIDE",
              "INDAPAMIDE": "INDAPAMIDE"
            },
            "PROFICIENT RX": {
              "HYDROCHLOROTHIAZIDE": "HYDROCHLOROTHIAZIDE"
            },
            "QUALITY CARE": {
              "CHLORTHALIDONE": "CHLORTHALIDONE",
              "HYDROCHLOROTHIAZIDE": "HYDROCHLOROTHIAZIDE",
              "INDAPAMIDE": "INDAPAMIDE"
            },
            "QUALITY CARE PRODUCTS": {
              "CHLORTHALIDONE": "CHLORTHALIDONE",
              "HYDROCHLOROTHIAZIDE": "HYDROCHLOROTHIAZIDE",
              "INDAPAMIDE": "INDAPAMIDE"
            },
            "RISING PHARMACEUTICALS": {
              "CHLORTHALIDONE": "CHLORTHALIDONE",
              "HYDROCHLOROTHIAZIDE": "HYDROCHLOROTHIAZIDE"
            },
            "RXCHANGE CO": {
              "HYDROCHLOROTHIAZIDE": "HYDROCHLOROTHIAZIDE"
            },
            "SAGENT PHARMACEUTICAL": {
              "CHLOROTHIAZIDE SODIUM": "CHLOROTHIAZIDE SODIUM"
            },
            "SANDOZ": {
              "METOLAZONE": "METOLAZONE"
            },
            "SCIEGEN PHARMACEUTICALS": {
              "HYDROCHLOROTHIAZIDE": "HYDROCHLOROTHIAZIDE"
            },
            "SKY PACKAGING": {
              "HYDROCHLOROTHIAZIDE": "HYDROCHLOROTHIAZIDE"
            },
            "SOLCO HEALTHCARE": {
              "HYDROCHLOROTHIAZIDE": "HYDROCHLOROTHIAZIDE"
            },
            "SPECTRUM": {
              "HYDROCHLOROTHIAZIDE": "HYDROCHLOROTHIAZIDE"
            },
            "ST MARY'S MPP": {
              "HYDROCHLOROTHIAZIDE": "HYDROCHLOROTHIAZIDE"
            },
            "SUN PHARMACEUTICALS": {
              "CHLOROTHIAZIDE SODIUM": "CHLOROTHIAZIDE SODIUM",
              "CHLORTHALIDONE": "CHLORTHALIDONE",
              "HYDROCHLOROTHIAZIDE": "HYDROCHLOROTHIAZIDE"
            },
            "TEVA PHARMACEUTICALS USA": {
              "HYDROCHLOROTHIAZIDE": "HYDROCHLOROTHIAZIDE"
            },
            "UNICHEM PHARMACEUTICALS": {
              "CHLORTHALIDONE": "CHLORTHALIDONE",
              "HYDROCHLOROTHIAZIDE": "HYDROCHLOROTHIAZIDE"
            },
            "UPSTATE PHARMA, LLC": {
              "METOLAZONE": "METOLAZONE"
            },
            "VA CMOP DALLAS": {
              "HYDROCHLOROTHIAZIDE": "HYDROCHLOROTHIAZIDE"
            },
            "VENSUN PHARMACEUTICALS": {
              "HYDROCHLOROTHIAZIDE": "HYDROCHLOROTHIAZIDE"
            },
            "VISTAPHARM INC.": {
              "CHLORTHALIDONE": "CHLORTHALIDONE"
            },
            "WESTMINSTER PHARMACEUTICALS": {
              "HYDROCHLOROTHIAZIDE": "HYDROCHLOROTHIAZIDE"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "CHLORTHALIDONE": "CHLORTHALIDONE"
            }
          }
        }
      },
      "ENDOCRINE AND METABOLIC AGENTS - MISC.": {
        "Adrenal Steroid Inhibitors": {
          "Cortisol Synthesis Inhibitors": {
            "RECORDATI RARE DISEASES": {
              "ISTURISA": "ISTURISA"
            }
          }
        },
        "Bone Density Regulators": {
          "Bisphosphonates": {
            "A-S MEDICATION SOLUTIONS": {
              "ACTONEL": "ACTONEL",
              "ALENDRONATE SODIUM": "ALENDRONATE SODIUM",
              "FOSAMAX": "FOSAMAX"
            },
            "ACCORD HEALTHCARE": {
              "ZOLEDRONIC ACID": "ZOLEDRONIC ACID"
            },
            "ACTAVIS": {
              "ZOLEDRONIC ACID": "ZOLEDRONIC ACID"
            },
            "ACTAVIS PHARMA": {
              "ALENDRONATE SODIUM": "ALENDRONATE SODIUM",
              "IBANDRONATE SODIUM": "IBANDRONATE SODIUM"
            },
            "AIDAREX PHARMACEUTICALS": {
              "ALENDRONATE SODIUM": "ALENDRONATE SODIUM"
            },
            "AKORN": {
              "ZOLEDRONIC ACID": "ZOLEDRONIC ACID"
            },
            "ALLERGAN": {
              "ACTONEL": "ACTONEL",
              "ATELVIA": "ATELVIA"
            },
            "ALVOGEN": {
              "IBANDRONATE SODIUM": "IBANDRONATE SODIUM"
            },
            "AMNEAL PHARMACEUTICALS": {
              "ALENDRONATE SODIUM": "ALENDRONATE SODIUM"
            },
            "APOTEX": {
              "ALENDRONATE SODIUM": "ALENDRONATE SODIUM",
              "IBANDRONATE SODIUM": "IBANDRONATE SODIUM",
              "RISEDRONATE SODIUM": "RISEDRONATE SODIUM",
              "ZOLEDRONIC ACID": "ZOLEDRONIC ACID"
            },
            "AREVA PHARMACEUTICALS": {
              "PAMIDRONATE DISODIUM": "PAMIDRONATE DISODIUM"
            },
            "ASCEND THERAPEUTICS": {
              "BINOSTO": "BINOSTO"
            },
            "ATHENEX PHARMACEUTICAL": {
              "ZOLEDRONIC ACID": "ZOLEDRONIC ACID"
            },
            "AUROBINDO PHARMA": {
              "ALENDRONATE SODIUM": "ALENDRONATE SODIUM",
              "IBANDRONATE SODIUM": "IBANDRONATE SODIUM",
              "RISEDRONATE SODIUM": "RISEDRONATE SODIUM"
            },
            "AUROMEDICS PHARMA": {
              "IBANDRONATE SODIUM": "IBANDRONATE SODIUM",
              "ZOLEDRONIC ACID": "ZOLEDRONIC ACID"
            },
            "AVET PHARMACEUTICALS": {
              "ZOLEDRONIC ACID": "ZOLEDRONIC ACID"
            },
            "AVKARE": {
              "IBANDRONATE SODIUM": "IBANDRONATE SODIUM"
            },
            "BLUE POINT LABORATORIES": {
              "ZOLEDRONIC ACID": "ZOLEDRONIC ACID"
            },
            "BPI LABS LLC": {
              "ZOLEDRONIC ACID": "ZOLEDRONIC ACID"
            },
            "BRECKENRIDGE": {
              "ZOLEDRONIC ACID": "ZOLEDRONIC ACID"
            },
            "CADISTA": {
              "ALENDRONATE SODIUM": "ALENDRONATE SODIUM"
            },
            "CIPLA USA": {
              "ALENDRONATE SODIUM": "ALENDRONATE SODIUM"
            },
            "DIRECT RX": {
              "ALENDRONATE SODIUM": "ALENDRONATE SODIUM"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "ALENDRONATE SODIUM": "ALENDRONATE SODIUM",
              "IBANDRONATE SODIUM": "IBANDRONATE SODIUM",
              "ZOLEDRONIC ACID": "ZOLEDRONIC ACID"
            },
            "FRESENIUS KABI USA": {
              "PAMIDRONATE DISODIUM": "PAMIDRONATE DISODIUM",
              "ZOLEDRONIC ACID": "ZOLEDRONIC ACID"
            },
            "GENENTECH": {
              "BONIVA": "BONIVA"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "ALENDRONATE SODIUM": "ALENDRONATE SODIUM",
              "IBANDRONATE SODIUM": "IBANDRONATE SODIUM"
            },
            "GREENSTONE": {
              "RISEDRONATE SODIUM": "RISEDRONATE SODIUM"
            },
            "HIKMA": {
              "ZOLEDRONIC ACID": "ZOLEDRONIC ACID"
            },
            "HOSPIRA": {
              "ZOLEDRONIC ACID": "ZOLEDRONIC ACID"
            },
            "IMPAX GENERICS": {
              "ALENDRONATE SODIUM": "ALENDRONATE SODIUM"
            },
            "INGENUS PHARMACEUTICALS": {
              "ZOLEDRONIC ACID": "ZOLEDRONIC ACID"
            },
            "MACLEODS PHARMACEUTICALS": {
              "IBANDRONATE SODIUM": "IBANDRONATE SODIUM",
              "RISEDRONATE SODIUM": "RISEDRONATE SODIUM"
            },
            "MARLEX PHARMACEUTICALS": {
              "ALENDRONATE SODIUM": "ALENDRONATE SODIUM"
            },
            "MERCK SHARP & DOHME": {
              "FOSAMAX": "FOSAMAX",
              "FOSAMAX PLUS D": "FOSAMAX PLUS D"
            },
            "MISSION": {
              "BINOSTO": "BINOSTO"
            },
            "MYLAN": {
              "ALENDRONATE SODIUM": "ALENDRONATE SODIUM",
              "ETIDRONATE DISODIUM": "ETIDRONATE DISODIUM",
              "RISEDRONATE SODIUM": "RISEDRONATE SODIUM"
            },
            "MYLAN INSTITUTIONAL": {
              "ALENDRONATE SODIUM": "ALENDRONATE SODIUM",
              "IBANDRONATE SODIUM": "IBANDRONATE SODIUM",
              "PAMIDRONATE DISODIUM": "PAMIDRONATE DISODIUM",
              "ZOLEDRONIC ACID": "ZOLEDRONIC ACID"
            },
            "NORTHSTAR RX": {
              "ALENDRONATE SODIUM": "ALENDRONATE SODIUM",
              "RISEDRONATE SODIUM": "RISEDRONATE SODIUM",
              "ZOLEDRONIC ACID": "ZOLEDRONIC ACID"
            },
            "NOVAPLUS/DR.REDDY'S": {
              "ZOLEDRONIC ACID": "ZOLEDRONIC ACID"
            },
            "NOVAPLUS/MYLAN INSTITUTIONAL": {
              "PAMIDRONATE DISODIUM": "PAMIDRONATE DISODIUM",
              "ZOLEDRONIC ACID": "ZOLEDRONIC ACID"
            },
            "NOVAPLUS/SAGENT PHARMACEUTICAL": {
              "ZOLEDRONIC ACID": "ZOLEDRONIC ACID"
            },
            "NOVARTIS": {
              "RECLAST": "RECLAST",
              "ZOMETA": "ZOMETA"
            },
            "NUCARE PHARMACEUTICALS": {
              "ALENDRONATE SODIUM": "ALENDRONATE SODIUM"
            },
            "PAR STERILE PRODUCTS": {
              "ZOLEDRONIC ACID": "ZOLEDRONIC ACID"
            },
            "PFIZER U.S.": {
              "PAMIDRONATE DISODIUM": "PAMIDRONATE DISODIUM"
            },
            "PREFERRED PHARMACEUTICALS": {
              "ALENDRONATE SODIUM": "ALENDRONATE SODIUM"
            },
            "PROFICIENT RX": {
              "ALENDRONATE SODIUM": "ALENDRONATE SODIUM"
            },
            "QUALITY CARE": {
              "ALENDRONATE SODIUM": "ALENDRONATE SODIUM",
              "BONIVA": "BONIVA",
              "FOSAMAX": "FOSAMAX",
              "RECLAST": "RECLAST"
            },
            "RISING PHARMACEUTICALS": {
              "ALENDRONATE SODIUM": "ALENDRONATE SODIUM"
            },
            "SAGENT PHARMACEUTICAL": {
              "IBANDRONATE SODIUM": "IBANDRONATE SODIUM",
              "ZOLEDRONIC ACID": "ZOLEDRONIC ACID"
            },
            "SANDOZ": {
              "PAMIDRONATE DISODIUM": "PAMIDRONATE DISODIUM"
            },
            "SUN PHARMACEUTICAL": {
              "ZOLEDRONIC ACID": "ZOLEDRONIC ACID"
            },
            "SUN PHARMACEUTICALS": {
              "ALENDRONATE SODIUM": "ALENDRONATE SODIUM",
              "IBANDRONATE SODIUM": "IBANDRONATE SODIUM",
              "RISEDRONATE SODIUM": "RISEDRONATE SODIUM"
            },
            "TAGI PHARMA": {
              "ALENDRONATE SODIUM": "ALENDRONATE SODIUM"
            },
            "TEVA PARENTERAL MEDICINES": {
              "PAMIDRONATE DISODIUM": "PAMIDRONATE DISODIUM"
            },
            "TEVA PHARMACEUTICALS USA": {
              "RISEDRONATE SODIUM": "RISEDRONATE SODIUM"
            },
            "VIRTUS PHARMACEUTICALS": {
              "ALENDRONATE SODIUM": "ALENDRONATE SODIUM"
            },
            "VIRTUS PHARMACEUTICALS OPCO": {
              "ALENDRONATE SODIUM": "ALENDRONATE SODIUM"
            }
          },
          "Calcitonins": {
            "APOTEX": {
              "CALCITONIN (SALMON)": "CALCITONIN (SALMON)"
            },
            "MYLAN INSTITUTIONAL": {
              "MIACALCIN": "MIACALCIN"
            },
            "PAR PHARMACEUTICAL": {
              "CALCITONIN (SALMON)": "CALCITONIN (SALMON)"
            },
            "QUALITY CARE": {
              "MIACALCIN": "MIACALCIN"
            },
            "SANDOZ": {
              "CALCITONIN (SALMON)": "CALCITONIN (SALMON)"
            },
            "UPSHER-SMITH": {
              "FORTICAL": "FORTICAL"
            }
          },
          "Parathyroid Hormone And Derivatives": {
            "ALVOGEN": {
              "TERIPARATIDE (RECOMBINANT)": "TERIPARATIDE (RECOMBINANT)"
            },
            "LILLY": {
              "FORTEO": "FORTEO"
            },
            "RADIUS HEALTH": {
              "TYMLOS": "TYMLOS"
            },
            "SHIRE US INC.": {
              "NATPARA": "NATPARA"
            }
          },
          "RANK Ligand (RANKL) Inhibitors": {
            "AMGEN": {
              "PROLIA": "PROLIA",
              "XGEVA": "XGEVA"
            }
          },
          "Sclerostin Inhibitors": {
            "AMGEN": {
              "EVENITY": "EVENITY"
            }
          }
        },
        "Corticotropin": {
          "Corticotropin": {
            "QUESTCOR": {
              "ACTHAR": "ACTHAR"
            }
          }
        },
        "Fertility Regulators": {
          "Ovulation Stimulants-Gonadotropins": {
            "EMPOWER PHARMACY": {
              "HCG": "HCG"
            },
            "FERRING": {
              "BRAVELLE": "BRAVELLE",
              "MENOPUR": "MENOPUR",
              "NOVAREL": "NOVAREL"
            },
            "FRESENIUS KABI USA": {
              "CHORIONIC GONADOTROPIN": "CHORIONIC GONADOTROPIN"
            },
            "ORGANON": {
              "FOLLISTIM AQ": "FOLLISTIM AQ",
              "PREGNYL": "PREGNYL"
            },
            "SERONO": {
              "GONAL-F": "GONAL-F",
              "GONAL-F RFF": "GONAL-F RFF",
              "GONAL-F RFF REDIJECT": "GONAL-F RFF REDIJECT",
              "OVIDREL": "OVIDREL"
            }
          },
          "Ovulation Stimulants-Synthetic": {
            "A-S MEDICATION SOLUTIONS": {
              "CLOMIPHENE CITRATE": "CLOMIPHENE CITRATE"
            },
            "AIDAREX PHARMACEUTICALS": {
              "CLOMIPHENE CITRATE": "CLOMIPHENE CITRATE"
            },
            "AMERICAN PHARMA INGREDIENTS": {
              "CLOMIPHENE CITRATE": "CLOMIPHENE CITRATE"
            },
            "API SOLUTIONS": {
              "CLOMIPHENE CITRATE": "CLOMIPHENE CITRATE"
            },
            "B & B PHARMACEUTICALS": {
              "CLOMIPHENE CITRATE": "CLOMIPHENE CITRATE"
            },
            "BRYANT RANCH PREPACK": {
              "CLOMIPHENE CITRATE": "CLOMIPHENE CITRATE"
            },
            "DIRECT RX": {
              "CLOMIPHENE CITRATE": "CLOMIPHENE CITRATE"
            },
            "LETCO MEDICAL": {
              "CLOMIPHENE CITRATE": "CLOMIPHENE CITRATE"
            },
            "MEDISCA": {
              "CLOMIPHENE CITRATE": "CLOMIPHENE CITRATE"
            },
            "PAR PHARMACEUTICAL": {
              "CLOMIPHENE CITRATE": "CLOMIPHENE CITRATE"
            },
            "PCCA": {
              "CLOMIPHENE CITRATE": "CLOMIPHENE CITRATE"
            },
            "PDRX PHARMACEUTICAL": {
              "CLOMIPHENE CITRATE": "CLOMIPHENE CITRATE"
            },
            "SPECTRUM": {
              "CLOMIPHENE CITRATE": "CLOMIPHENE CITRATE"
            },
            "TEVA PHARMACEUTICALS USA": {
              "CLOMIPHENE CITRATE": "CLOMIPHENE CITRATE"
            }
          }
        },
        "GnRH/LHRH Antagonists": {
          "GnRH/LHRH Antagonists": {
            "ABBVIE": {
              "ORILISSA": "ORILISSA"
            },
            "FERRING": {
              "GANIRELIX ACETATE": "GANIRELIX ACETATE"
            },
            "ORGANON": {
              "GANIRELIX ACETATE": "GANIRELIX ACETATE"
            },
            "SERONO": {
              "CETROTIDE": "CETROTIDE"
            }
          }
        },
        "Growth Hormone Receptor Antagonists": {
          "Growth Hormone Receptor Antagonists": {
            "PFIZER U.S.": {
              "SOMAVERT": "SOMAVERT"
            }
          }
        },
        "Growth Hormone Releasing Hormones (GHRH)": {
          "Growth Hormone Releasing Hormones (GHRH)": {
            "EMPOWER PHARMACY": {
              "IPAMORELIN ACETATE": "IPAMORELIN ACETATE"
            },
            "THERATECHNOLOGIES": {
              "EGRIFTA": "EGRIFTA",
              "EGRIFTA SV": "EGRIFTA SV"
            }
          },
          "Growth Hormone Releasing Hormones (GHRH) Combinations": {
            "EMPOWER PHARMACY": {
              "GHRP2-GHRP6-SERMORELIN": "GHRP2-GHRP6-SERMORELIN",
              "GHRP2-SERMORELIN": "GHRP2-SERMORELIN"
            }
          }
        },
        "Growth Hormones": {
          "Growth Hormones": {
            "A-S MEDICATION SOLUTIONS": {
              "SAIZEN": "SAIZEN"
            },
            "FERRING": {
              "ZOMACTON": "ZOMACTON",
              "ZOMACTON (FOR ZOMA-JET 10)": "ZOMACTON (FOR ZOMA-JET 10)"
            },
            "GENENTECH": {
              "NUTROPIN AQ NUSPIN 10": "NUTROPIN AQ NUSPIN 10",
              "NUTROPIN AQ NUSPIN 20": "NUTROPIN AQ NUSPIN 20",
              "NUTROPIN AQ NUSPIN 5": "NUTROPIN AQ NUSPIN 5",
              "NUTROPIN AQ PEN": "NUTROPIN AQ PEN"
            },
            "LILLY": {
              "HUMATROPE": "HUMATROPE"
            },
            "NOVO NORDISK": {
              "NORDITROPIN FLEXPRO": "NORDITROPIN FLEXPRO"
            },
            "PFIZER U.S.": {
              "GENOTROPIN": "GENOTROPIN",
              "GENOTROPIN MINIQUICK": "GENOTROPIN MINIQUICK"
            },
            "SANDOZ": {
              "OMNITROPE": "OMNITROPE"
            },
            "SERONO": {
              "SAIZEN": "SAIZEN",
              "SAIZEN CLICK.EASY": "SAIZEN CLICK.EASY",
              "SAIZENPREP": "SAIZENPREP",
              "SEROSTIM": "SEROSTIM",
              "ZORBTIVE": "ZORBTIVE"
            }
          }
        },
        "Hormone Receptor Modulators": {
          "Selective Estrogen Receptor Modulators (SERMs)": {
            "A-S MEDICATION SOLUTIONS": {
              "EVISTA": "EVISTA",
              "RALOXIFENE HCL": "RALOXIFENE HCL"
            },
            "ACETRIS HEALTH": {
              "RALOXIFENE HCL": "RALOXIFENE HCL"
            },
            "ACTAVIS PHARMA": {
              "RALOXIFENE HCL": "RALOXIFENE HCL"
            },
            "AMERICAN HEALTH PACKAGING": {
              "RALOXIFENE HCL": "RALOXIFENE HCL"
            },
            "AMNEAL PHARMACEUTICALS": {
              "RALOXIFENE HCL": "RALOXIFENE HCL"
            },
            "APHENA PHARMA SOLUTIONS": {
              "RALOXIFENE HCL": "RALOXIFENE HCL"
            },
            "AQ PHARMACEUTICALS": {
              "EVISTA": "EVISTA"
            },
            "AVKARE": {
              "RALOXIFENE HCL": "RALOXIFENE HCL"
            },
            "AVPAK": {
              "RALOXIFENE HCL": "RALOXIFENE HCL"
            },
            "CIPLA USA": {
              "RALOXIFENE HCL": "RALOXIFENE HCL"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "RALOXIFENE HCL": "RALOXIFENE HCL"
            },
            "EXELAN PHARMACEUTICALS": {
              "RALOXIFENE HCL": "RALOXIFENE HCL"
            },
            "GLENMARK PHARMACEUTICALS": {
              "RALOXIFENE HCL": "RALOXIFENE HCL"
            },
            "LIBERTY PHARMACEUTICAL": {
              "RALOXIFENE HCL": "RALOXIFENE HCL"
            },
            "LILLY": {
              "EVISTA": "EVISTA"
            },
            "MAJOR PHARMACEUTICALS": {
              "RALOXIFENE HCL": "RALOXIFENE HCL"
            },
            "PDRX PHARMACEUTICAL": {
              "EVISTA": "EVISTA"
            },
            "PRASCO LABORATORIES": {
              "RALOXIFENE HCL": "RALOXIFENE HCL"
            },
            "SHIONOGI PHARMA": {
              "OSPHENA": "OSPHENA"
            },
            "TEVA PHARMACEUTICALS USA": {
              "RALOXIFENE HCL": "RALOXIFENE HCL"
            }
          }
        },
        "Insulin-Like Growth Factor Receptor Inhibitors": {
          "Insulin-Like Growth Factor-1 Receptor Inhibitors(IGF-1R)": {
            "HORIZON THERAPEUTICS USA": {
              "TEPEZZA": "TEPEZZA"
            }
          }
        },
        "Insulin-Like Growth Factors (Somatomedins)": {
          "Insulin-Like Growth Factors (Somatomedins)": {
            "IPSEN BIOPHARMACEUTICALS": {
              "INCRELEX": "INCRELEX"
            }
          }
        },
        "LHRH/GnRH Agonist Analog Pituitary Suppressants": {
          "LHRH/GnRH Agonist Analog Combinations": {
            "ABBVIE": {
              "LUPANETA PACK": "LUPANETA PACK"
            }
          },
          "LHRH/GnRH Agonist Analog Pituitary Suppressants": {
            "ABBVIE": {
              "LUPRON DEPOT-PED (1-MONTH)": "LUPRON DEPOT-PED (1-MONTH)",
              "LUPRON DEPOT-PED (3-MONTH)": "LUPRON DEPOT-PED (3-MONTH)"
            },
            "ARBOR PHARMACEUTICALS": {
              "TRIPTODUR": "TRIPTODUR"
            },
            "ENDO PHARMACEUTICALS": {
              "SUPPRELIN LA": "SUPPRELIN LA"
            },
            "PFIZER U.S.": {
              "SYNAREL": "SYNAREL"
            },
            "TOLMAR PHARMACEUTICALS": {
              "FENSOLVI (6 MONTH)": "FENSOLVI (6 MONTH)"
            }
          }
        },
        "Metabolic Modifiers": {
          "Adenosine Deaminase SCID Treatment - Agents": {
            "LEADIANT BIOSCIENCES": {
              "REVCOVI": "REVCOVI"
            }
          },
          "Calcimimetic Agents": {
            "ACCORD HEALTHCARE": {
              "CINACALCET HCL": "CINACALCET HCL"
            },
            "ACTAVIS PHARMA": {
              "CINACALCET HCL": "CINACALCET HCL"
            },
            "AMERICAN HEALTH PACKAGING": {
              "CINACALCET HCL": "CINACALCET HCL"
            },
            "AMGEN": {
              "PARSABIV": "PARSABIV",
              "SENSIPAR": "SENSIPAR"
            },
            "ASCEND LABORATORIES": {
              "CINACALCET HCL": "CINACALCET HCL"
            },
            "AUROBINDO PHARMA": {
              "CINACALCET HCL": "CINACALCET HCL"
            },
            "CIPLA USA": {
              "CINACALCET HCL": "CINACALCET HCL"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "CINACALCET HCL": "CINACALCET HCL"
            },
            "EXELAN PHARMACEUTICALS": {
              "CINACALCET HCL": "CINACALCET HCL"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "CINACALCET HCL": "CINACALCET HCL"
            },
            "MAJOR PHARMACEUTICALS": {
              "CINACALCET HCL": "CINACALCET HCL"
            },
            "MYLAN": {
              "CINACALCET HCL": "CINACALCET HCL"
            },
            "NORTHSTAR RX": {
              "CINACALCET HCL": "CINACALCET HCL"
            },
            "SLATE RUN PHARMACEUTICALS": {
              "CINACALCET HCL": "CINACALCET HCL"
            },
            "STRIDES PHARMA": {
              "CINACALCET HCL": "CINACALCET HCL"
            },
            "SUN PHARMACEUTICALS": {
              "CINACALCET HCL": "CINACALCET HCL"
            }
          },
          "Carnitine Replenisher - Agents": {
            "EMPOWER PHARMACY": {
              "LEVOCARNITINE": "LEVOCARNITINE"
            },
            "HI-TECH": {
              "LEVOCARNITINE": "LEVOCARNITINE"
            },
            "LEADIANT BIOSCIENCES": {
              "CARNITOR": "CARNITOR",
              "CARNITOR SF": "CARNITOR SF"
            },
            "MCLAREN MEDICAL": {
              "MCCARNITINE": "MCCARNITINE"
            },
            "NOVITIUM PHARMA": {
              "LEVOCARNITINE": "LEVOCARNITINE",
              "LEVOCARNITINE SF": "LEVOCARNITINE SF"
            },
            "RISING PHARMACEUTICALS": {
              "LEVOCARNITINE": "LEVOCARNITINE"
            }
          },
          "Fabry Disease - Agents": {
            "AMICUS THERAPEUTICS": {
              "GALAFOLD": "GALAFOLD"
            },
            "GENZYME": {
              "FABRAZYME": "FABRAZYME"
            }
          },
          "GAA Deficiency Treatment - Agents": {
            "GENZYME": {
              "LUMIZYME": "LUMIZYME"
            }
          },
          "Hereditary Orotic Aciduria Treatment - Agent": {
            "WELLSTAT THERAPEUTICS CORP": {
              "XURIDEN": "XURIDEN"
            }
          },
          "Hereditary Tyrosinemia Type 1 (HT-1) Treatment - Agents": {
            "CYCLE PHARMACEUTICALS": {
              "NITYR": "NITYR"
            },
            "PAR PHARMACEUTICAL": {
              "NITISINONE": "NITISINONE"
            },
            "SOBI": {
              "ORFADIN": "ORFADIN"
            }
          },
          "Homocystinuria Treatment - Agents": {
            "RARE DISEASE THERAPEUTICS, INC": {
              "CYSTADANE": "CYSTADANE"
            },
            "RECORDATI RARE DISEASES": {
              "CYSTADANE": "CYSTADANE"
            }
          },
          "Hyperammonemia Treatment - Agents": {
            "RECORDATI RARE DISEASES": {
              "CARBAGLU": "CARBAGLU"
            }
          },
          "Hyperparathyroid Treatment - Vitamin D Analogs": {
            "ABBVIE": {
              "ZEMPLAR": "ZEMPLAR"
            },
            "ACCORD HEALTHCARE": {
              "PARICALCITOL": "PARICALCITOL"
            },
            "AKORN": {
              "CALCITRIOL": "CALCITRIOL",
              "DOXERCALCIFEROL": "DOXERCALCIFEROL"
            },
            "AMERICAN HEALTH PACKAGING": {
              "CALCITRIOL": "CALCITRIOL",
              "DOXERCALCIFEROL": "DOXERCALCIFEROL",
              "PARICALCITOL": "PARICALCITOL"
            },
            "AMNEAL BIOSCIENCES": {
              "DOXERCALCIFEROL": "DOXERCALCIFEROL"
            },
            "APHENA PHARMA SOLUTIONS": {
              "CALCITRIOL": "CALCITRIOL"
            },
            "AUROBINDO PHARMA": {
              "PARICALCITOL": "PARICALCITOL"
            },
            "AUROMEDICS PHARMA": {
              "PARICALCITOL": "PARICALCITOL"
            },
            "AVET PHARMACEUTICALS": {
              "CALCITRIOL": "CALCITRIOL"
            },
            "BIONPHARMA": {
              "CALCITRIOL": "CALCITRIOL",
              "PARICALCITOL": "PARICALCITOL"
            },
            "BRYANT RANCH PREPACK": {
              "CALCITRIOL": "CALCITRIOL"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "DOXERCALCIFEROL": "DOXERCALCIFEROL",
              "PARICALCITOL": "PARICALCITOL"
            },
            "FRESENIUS KABI USA": {
              "CALCITRIOL": "CALCITRIOL"
            },
            "GENZYME": {
              "HECTOROL": "HECTOROL"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "CALCITRIOL": "CALCITRIOL",
              "PARICALCITOL": "PARICALCITOL"
            },
            "HIKMA": {
              "CALCITRIOL": "CALCITRIOL",
              "DOXERCALCIFEROL": "DOXERCALCIFEROL",
              "PARICALCITOL": "PARICALCITOL"
            },
            "HOSPIRA": {
              "DOXERCALCIFEROL": "DOXERCALCIFEROL",
              "PARICALCITOL": "PARICALCITOL"
            },
            "LUPIN PHARMACEUTICALS": {
              "DOXERCALCIFEROL": "DOXERCALCIFEROL"
            },
            "MEITHEAL PHARMACEUTICALS": {
              "DOXERCALCIFEROL": "DOXERCALCIFEROL"
            },
            "MYLAN INSTITUTIONAL": {
              "PARICALCITOL": "PARICALCITOL"
            },
            "NOVAPLUS / AKORN": {
              "CALCITRIOL": "CALCITRIOL"
            },
            "OPKO PHARMACEUTICALS": {
              "RAYALDEE": "RAYALDEE"
            },
            "PDRX PHARMACEUTICALS": {
              "CALCITRIOL": "CALCITRIOL"
            },
            "PRASCO LABORATORIES": {
              "DOXERCALCIFEROL": "DOXERCALCIFEROL"
            },
            "RISING PHARMACEUTICALS": {
              "CALCITRIOL": "CALCITRIOL",
              "PARICALCITOL": "PARICALCITOL"
            },
            "SANDOZ": {
              "DOXERCALCIFEROL": "DOXERCALCIFEROL"
            },
            "STRIDES PHARMA": {
              "CALCITRIOL": "CALCITRIOL"
            },
            "SUN PHARMACEUTICALS": {
              "CALCITRIOL": "CALCITRIOL"
            },
            "TEVA PHARMACEUTICALS USA": {
              "CALCITRIOL": "CALCITRIOL",
              "PARICALCITOL": "PARICALCITOL"
            },
            "TIME-CAP LABS": {
              "PARICALCITOL": "PARICALCITOL"
            },
            "VALIDUS PHARMACEUTICALS": {
              "ROCALTROL": "ROCALTROL"
            },
            "WINTHROP, US": {
              "DOXERCALCIFEROL": "DOXERCALCIFEROL"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "PARICALCITOL": "PARICALCITOL"
            }
          },
          "Hypophosphatasia (HPP) Agents": {
            "ALEXION PHARMACEUTICALS": {
              "STRENSIQ": "STRENSIQ"
            }
          },
          "Leptin Analogues": {
            "AEGERION PHARMACEUTICALS": {
              "MYALEPT": "MYALEPT"
            }
          },
          "Lysosomal Acid Lipase (LAL) Deficiency - Agents": {
            "ALEXION PHARMACEUTICALS": {
              "KANUMA": "KANUMA"
            }
          },
          "Mucopolysaccharidosis I (MPS I) - Agents": {
            "GENZYME": {
              "ALDURAZYME": "ALDURAZYME"
            }
          },
          "Mucopolysaccharidosis II (MPS II) - Agents": {
            "SHIRE US INC.": {
              "ELAPRASE": "ELAPRASE"
            }
          },
          "Mucopolysaccharidosis IV (MPS IV) - Agents": {
            "BIOMARIN PHARMACEUTICALS": {
              "VIMIZIM": "VIMIZIM"
            }
          },
          "Mucopolysaccharidosis VI (MPS VI) - Agents": {
            "BIOMARIN PHARMACEUTICALS": {
              "NAGLAZYME": "NAGLAZYME"
            }
          },
          "Mucopolysaccharidosis VII (MPS VII) - Agents": {
            "ULTRAGENYX PHARMACEUTICAL": {
              "MEPSEVII": "MEPSEVII"
            }
          },
          "Phenylketonuria Treatment - Agents": {
            "BIOMARIN PHARMACEUTICALS": {
              "KUVAN": "KUVAN",
              "PALYNZIQ": "PALYNZIQ"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "SAPROPTERIN DIHYDROCHLORIDE": "SAPROPTERIN DIHYDROCHLORIDE"
            },
            "PAR PHARMACEUTICAL": {
              "SAPROPTERIN DIHYDROCHLORIDE": "SAPROPTERIN DIHYDROCHLORIDE"
            }
          },
          "Tripeptidyl Peptidase 1 Deficiency Treatment - Agents": {
            "BIOMARIN PHARMACEUTICALS": {
              "BRINEURA": "BRINEURA"
            }
          },
          "Urea Cycle Disorder - Agents": {
            "BAUSCH HEALTH": {
              "AMMONUL": "AMMONUL"
            },
            "HORIZON THERAPEUTICS": {
              "BUPHENYL": "BUPHENYL",
              "RAVICTI": "RAVICTI"
            },
            "HORIZON THERAPEUTICS USA": {
              "BUPHENYL": "BUPHENYL",
              "RAVICTI": "RAVICTI"
            },
            "MYLAN INSTITUTIONAL": {
              "SOD BENZ-SOD PHENYLACET": "SOD BENZ-SOD PHENYLACET"
            },
            "OCEANSIDE PHARMACEUTICALS": {
              "SOD BENZ-SOD PHENYLACET": "SOD BENZ-SOD PHENYLACET"
            },
            "PAR PHARMACEUTICAL": {
              "SODIUM PHENYLBUTYRATE": "SODIUM PHENYLBUTYRATE"
            },
            "SIGMAPHARM LABORATORIES": {
              "SODIUM PHENYLBUTYRATE": "SODIUM PHENYLBUTYRATE"
            },
            "TRIGEN LABORATORIES": {
              "SOD BENZ-SOD PHENYLACET": "SOD BENZ-SOD PHENYLACET"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "SOD BENZ-SOD PHENYLACET": "SOD BENZ-SOD PHENYLACET"
            }
          },
          "X-Linked Hypophosphatemia (XLH) Treatment - Agents": {
            "ULTRAGENYX PHARMACEUTICAL": {
              "CRYSVITA": "CRYSVITA"
            }
          }
        },
        "Posterior Pituitary Hormones": {
          "Vasopressin": {
            "ACTAVIS PHARMA": {
              "DESMOPRESSIN ACETATE": "DESMOPRESSIN ACETATE"
            },
            "AMERICAN HEALTH PACKAGING": {
              "DESMOPRESSIN ACETATE": "DESMOPRESSIN ACETATE"
            },
            "AMRING PHARMACEUTICALS": {
              "DESMOPRESSIN ACETATE": "DESMOPRESSIN ACETATE",
              "DESMOPRESSIN ACETATE SPRAY": "DESMOPRESSIN ACETATE SPRAY"
            },
            "ANTARES PHARMA": {
              "NOCDURNA": "NOCDURNA"
            },
            "APOTEX": {
              "DESMOPRESSIN ACETATE": "DESMOPRESSIN ACETATE"
            },
            "AVADEL SPECIALTY PHARMACEUTICA": {
              "NOCTIVA": "NOCTIVA"
            },
            "AVET PHARMACEUTICALS": {
              "DESMOPRESSIN ACETATE": "DESMOPRESSIN ACETATE"
            },
            "AVPAK": {
              "DESMOPRESSIN ACETATE": "DESMOPRESSIN ACETATE"
            },
            "BAUSCH HEALTH": {
              "DESMOPRESSIN ACE SPRAY REFRIG": "DESMOPRESSIN ACE SPRAY REFRIG"
            },
            "BLUE POINT LABORATORIES": {
              "DESMOPRESSIN ACETATE": "DESMOPRESSIN ACETATE"
            },
            "CSL BEHRING": {
              "STIMATE": "STIMATE"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "DESMOPRESSIN ACETATE": "DESMOPRESSIN ACETATE"
            },
            "FERRING": {
              "DDAVP": "DDAVP",
              "DDAVP RHINAL TUBE": "DDAVP RHINAL TUBE",
              "DESMOPRESSIN ACE RHINAL TUBE": "DESMOPRESSIN ACE RHINAL TUBE",
              "DESMOPRESSIN ACETATE": "DESMOPRESSIN ACETATE"
            },
            "GLENMARK PHARMACEUTICALS": {
              "DESMOPRESSIN ACETATE": "DESMOPRESSIN ACETATE"
            },
            "HOSPIRA": {
              "DESMOPRESSIN ACETATE": "DESMOPRESSIN ACETATE"
            },
            "IMPAX GENERICS": {
              "DESMOPRESSIN ACETATE": "DESMOPRESSIN ACETATE"
            },
            "KVK TECH": {
              "DESMOPRESSIN ACETATE": "DESMOPRESSIN ACETATE"
            },
            "LIBERTY PHARMACEUTICAL": {
              "DESMOPRESSIN ACETATE": "DESMOPRESSIN ACETATE"
            },
            "MEDVANTX": {
              "DDAVP": "DDAVP"
            },
            "MYLAN INSTITUTIONAL": {
              "DESMOPRESSIN ACETATE": "DESMOPRESSIN ACETATE"
            },
            "NORTHSTAR RX": {
              "DESMOPRESSIN ACETATE": "DESMOPRESSIN ACETATE"
            },
            "NOVAPLUS/SUN PHARMA": {
              "DESMOPRESSIN ACETATE": "DESMOPRESSIN ACETATE"
            },
            "PAR STERILE PRODUCTS": {
              "VASOSTRICT": "VASOSTRICT"
            },
            "PHARMEDIUM SERVICES": {
              "VASOPRESSIN-DEXTROSE": "VASOPRESSIN-DEXTROSE",
              "VASOPRESSIN-SODIUM CHLORIDE": "VASOPRESSIN-SODIUM CHLORIDE"
            },
            "PRASCO LABORATORIES": {
              "DESMOPRESSIN ACETATE SPRAY": "DESMOPRESSIN ACETATE SPRAY"
            },
            "QUALITY CARE": {
              "DESMOPRESSIN ACETATE": "DESMOPRESSIN ACETATE"
            },
            "SUN PHARMACEUTICALS": {
              "DESMOPRESSIN ACE SPRAY REFRIG": "DESMOPRESSIN ACE SPRAY REFRIG",
              "DESMOPRESSIN ACETATE": "DESMOPRESSIN ACETATE",
              "DESMOPRESSIN ACETATE SPRAY": "DESMOPRESSIN ACETATE SPRAY"
            },
            "TEVA PARENTERAL MEDICINES": {
              "DESMOPRESSIN ACETATE": "DESMOPRESSIN ACETATE"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "DESMOPRESSIN ACETATE SPRAY": "DESMOPRESSIN ACETATE SPRAY"
            }
          }
        },
        "Progesterone Receptor Antagonists": {
          "Abortifacient - Progesterone Receptor Antagonists": {
            "DANCO LABORATORIES, LLC": {
              "MIFEPREX": "MIFEPREX"
            },
            "GENBIOPRO": {
              "MIFEPRISTONE": "MIFEPRISTONE"
            }
          }
        },
        "Prolactin Inhibitors": {
          "Dopamine Receptor Agonists": {
            "A-S MEDICATION SOLUTIONS": {
              "CABERGOLINE": "CABERGOLINE"
            },
            "APOTEX": {
              "CABERGOLINE": "CABERGOLINE"
            },
            "GREENSTONE": {
              "CABERGOLINE": "CABERGOLINE"
            },
            "INGENUS PHARMACEUTICALS": {
              "CABERGOLINE": "CABERGOLINE"
            },
            "MYLAN": {
              "CABERGOLINE": "CABERGOLINE"
            },
            "PAR PHARMACEUTICAL": {
              "CABERGOLINE": "CABERGOLINE"
            },
            "TEVA PHARMACEUTICALS USA": {
              "CABERGOLINE": "CABERGOLINE"
            }
          }
        },
        "Somatostatic Agents": {
          "Somatostatic Agents": {
            "AVET PHARMACEUTICALS": {
              "OCTREOTIDE ACETATE": "OCTREOTIDE ACETATE"
            },
            "CHIASMA": {
              "MYCAPSSA": "MYCAPSSA"
            },
            "FRESENIUS KABI USA": {
              "OCTREOTIDE ACETATE": "OCTREOTIDE ACETATE"
            },
            "HIKMA": {
              "OCTREOTIDE ACETATE": "OCTREOTIDE ACETATE"
            },
            "IPSEN BIOPHARMACEUTICALS": {
              "SOMATULINE DEPOT": "SOMATULINE DEPOT"
            },
            "MYLAN INSTITUTIONAL": {
              "OCTREOTIDE ACETATE": "OCTREOTIDE ACETATE"
            },
            "NOVARTIS": {
              "SANDOSTATIN": "SANDOSTATIN",
              "SANDOSTATIN LAR DEPOT": "SANDOSTATIN LAR DEPOT"
            },
            "RECORDATI RARE DISEASES": {
              "SIGNIFOR": "SIGNIFOR",
              "SIGNIFOR LAR": "SIGNIFOR LAR"
            },
            "SAGENT PHARMACEUTICAL": {
              "OCTREOTIDE ACETATE": "OCTREOTIDE ACETATE"
            },
            "SANDOZ": {
              "OCTREOTIDE ACETATE": "OCTREOTIDE ACETATE"
            },
            "SUN PHARMACEUTICAL": {
              "OCTREOTIDE ACETATE": "OCTREOTIDE ACETATE"
            },
            "SUN PHARMACEUTICALS": {
              "BYNFEZIA PEN": "BYNFEZIA PEN"
            },
            "TEVA PARENTERAL MEDICINES": {
              "OCTREOTIDE ACETATE": "OCTREOTIDE ACETATE"
            }
          }
        },
        "Vasopressin Receptor Antagonists": {
          "Selective Vasopressin V2-Receptor Antagonists": {
            "APOTEX": {
              "TOLVAPTAN": "TOLVAPTAN"
            },
            "ASCEND LABORATORIES": {
              "TOLVAPTAN": "TOLVAPTAN"
            },
            "OTSUKA AMERICA": {
              "JYNARQUE": "JYNARQUE",
              "SAMSCA": "SAMSCA"
            }
          },
          "V1a/V2-Arginine Vasopressin (AVP) Receptor Antagonists": {
            "CUMBERLAND PHARMACEUTICALS": {
              "VAPRISOL": "VAPRISOL"
            }
          }
        }
      },
      "ESTROGENS": {
        "Estrogen Combinations": {
          "Estrogen & Androgen": {
            "AIDAREX PHARMACEUTICALS": {
              "EST ESTROGENS-METHYLTEST HS": "EST ESTROGENS-METHYLTEST HS"
            },
            "ANI  PHARMACEUTICALS": {
              "EST ESTROGENS-METHYLTEST": "EST ESTROGENS-METHYLTEST",
              "EST ESTROGENS-METHYLTEST HS": "EST ESTROGENS-METHYLTEST HS"
            },
            "CENTRIX PHARMACEUTICAL": {
              "COVARYX": "COVARYX",
              "COVARYX HS": "COVARYX HS"
            },
            "CREEKWOOD PHARMACEUTICAL": {
              "EEMT": "EEMT",
              "EEMT HS": "EEMT HS"
            },
            "METHOD PHARMACEUTICALS": {
              "EST ESTROGENS-METHYLTEST DS": "EST ESTROGENS-METHYLTEST DS",
              "EST ESTROGENS-METHYLTEST HS": "EST ESTROGENS-METHYLTEST HS"
            },
            "SETON PHARMACEUTICALS": {
              "EST ESTROGENS-METHYLTEST": "EST ESTROGENS-METHYLTEST",
              "EST ESTROGENS-METHYLTEST HS": "EST ESTROGENS-METHYLTEST HS"
            },
            "TAL PHARMA": {
              "EST ESTROGENS-METHYLTEST DS": "EST ESTROGENS-METHYLTEST DS",
              "EST ESTROGENS-METHYLTEST HS": "EST ESTROGENS-METHYLTEST HS"
            }
          },
          "Estrogen & Progestin": {
            "A-S MEDICATION SOLUTIONS": {
              "PREMPRO": "PREMPRO"
            },
            "ACTAVIS PHARMA": {
              "JEVANTIQUE LO": "JEVANTIQUE LO"
            },
            "ALEXSO": {
              "BIEST/PROGESTERONE": "BIEST/PROGESTERONE"
            },
            "ALLERGAN": {
              "FEMHRT LOW DOSE": "FEMHRT LOW DOSE"
            },
            "AMNEAL PHARMACEUTICALS": {
              "LOPREEZA": "LOPREEZA"
            },
            "AMNEAL SPECIALTY": {
              "ACTIVELLA": "ACTIVELLA"
            },
            "BAYER HEALTHCARE PHARMA": {
              "ANGELIQ": "ANGELIQ",
              "CLIMARA PRO": "CLIMARA PRO"
            },
            "BRECKENRIDGE": {
              "ESTRADIOL-NORETHINDRONE ACET": "ESTRADIOL-NORETHINDRONE ACET"
            },
            "GLENMARK PHARMACEUTICALS": {
              "NORETHINDRONE-ETH ESTRADIOL": "NORETHINDRONE-ETH ESTRADIOL"
            },
            "HUMCO": {
              "BI-EST 80:20 PROGESTERONE": "BI-EST 80:20 PROGESTERONE",
              "ESTRIOL-PROGESTERONE MICRO": "ESTRIOL-PROGESTERONE MICRO"
            },
            "INGENUS PHARMACEUTICALS": {
              "ESTRADIOL-NORETHINDRONE ACET": "ESTRADIOL-NORETHINDRONE ACET"
            },
            "LUPIN PHARMACEUTICALS": {
              "AMABELZ": "AMABELZ",
              "FYAVOLV": "FYAVOLV"
            },
            "MYLAN": {
              "ESTRADIOL-NORETHINDRONE ACET": "ESTRADIOL-NORETHINDRONE ACET"
            },
            "NIVAGEN PHARMACEUTICALS": {
              "NORETHINDRONE-ETH ESTRADIOL": "NORETHINDRONE-ETH ESTRADIOL"
            },
            "NOVEN THERAPEUTICS": {
              "COMBIPATCH": "COMBIPATCH"
            },
            "NOVO NORDISK": {
              "ACTIVELLA": "ACTIVELLA"
            },
            "PFIZER U.S.": {
              "PREMPHASE": "PREMPHASE",
              "PREMPRO": "PREMPRO"
            },
            "QUALITY CARE": {
              "PREMPRO": "PREMPRO"
            },
            "TEVA PHARMACEUTICALS USA": {
              "JINTELI": "JINTELI",
              "MIMVEY": "MIMVEY",
              "MIMVEY LO": "MIMVEY LO"
            },
            "TEVA/WOMENS HEALTH": {
              "PREFEST": "PREFEST"
            },
            "THERAPEUTICSMD": {
              "BIJUVA": "BIJUVA"
            }
          },
          "Estrogen Combinations": {
            "HUMCO": {
              "BI-EST 50:50": "BI-EST 50:50"
            }
          },
          "Estrogen-Androgen-Progestin": {
            "HUMCO": {
              "BI-EST PROGEST-TESTOSTERONE": "BI-EST PROGEST-TESTOSTERONE"
            }
          },
          "Estrogen-Progestin-GnRH Antagonist": {
            "ABBVIE": {
              "ORIAHNN": "ORIAHNN"
            }
          },
          "Estrogen-Selective Estrogen Receptor Modulator Comb": {
            "PFIZER U.S.": {
              "DUAVEE": "DUAVEE"
            }
          }
        },
        "Estrogens": {
          "Estrogens": {
            "A-S MEDICATION SOLUTIONS": {
              "DEPO-ESTRADIOL": "DEPO-ESTRADIOL",
              "ESTRADIOL": "ESTRADIOL",
              "PREMARIN": "PREMARIN"
            },
            "ACTAVIS PHARMA": {
              "ESTRADIOL": "ESTRADIOL",
              "ESTROPIPATE": "ESTROPIPATE"
            },
            "AIDAREX PHARMACEUTICALS": {
              "ESTRADIOL": "ESTRADIOL"
            },
            "ALLERGAN": {
              "ALORA": "ALORA",
              "ESTRACE": "ESTRACE"
            },
            "ALVOGEN": {
              "ESTRADIOL": "ESTRADIOL"
            },
            "AMERICAN REGENT": {
              "ESTRADIOL VALERATE": "ESTRADIOL VALERATE"
            },
            "AMNEAL PHARMACEUTICALS": {
              "DOTTI": "DOTTI"
            },
            "APHENA PHARMA SOLUTIONS": {
              "PREMARIN": "PREMARIN"
            },
            "APOTHECA SUPPLY": {
              "ETHINYL ESTRADIOL": "ETHINYL ESTRADIOL"
            },
            "ASCEND THERAPEUTICS": {
              "ESTROGEL": "ESTROGEL"
            },
            "AVPAK": {
              "ESTRADIOL": "ESTRADIOL"
            },
            "BAYER HEALTHCARE PHARMA": {
              "CLIMARA": "CLIMARA",
              "MENOSTAR": "MENOSTAR"
            },
            "BRYANT RANCH PREPACK": {
              "ESTRADIOL": "ESTRADIOL"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "ESTRADIOL": "ESTRADIOL"
            },
            "DIRECT RX": {
              "ESTRADIOL": "ESTRADIOL"
            },
            "ENOVACHEM MANUFACTURING": {
              "EC-RX ESTRADIOL": "EC-RX ESTRADIOL"
            },
            "EPIC PHARMA": {
              "ESTRADIOL": "ESTRADIOL"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "ESTRADIOL": "ESTRADIOL"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "ESTRADIOL": "ESTRADIOL",
              "ESTROPIPATE": "ESTROPIPATE",
              "PREMARIN": "PREMARIN"
            },
            "MAYNE PHARMA": {
              "ESTRADIOL": "ESTRADIOL"
            },
            "MEDVANTX": {
              "PREMARIN": "PREMARIN"
            },
            "MYLAN": {
              "ESTRADIOL": "ESTRADIOL"
            },
            "MYLAN SPECIALTY L.P.": {
              "ELESTRIN": "ELESTRIN"
            },
            "NOVARTIS": {
              "VIVELLE-DOT": "VIVELLE-DOT"
            },
            "NOVEN THERAPEUTICS": {
              "ESTRADIOL": "ESTRADIOL",
              "MINIVELLE": "MINIVELLE"
            },
            "NUCARE PHARMACEUTICALS": {
              "ESTRADIOL": "ESTRADIOL",
              "PREMARIN": "PREMARIN"
            },
            "PAR STERILE PRODUCTS": {
              "DELESTROGEN": "DELESTROGEN"
            },
            "PCCA": {
              "ETHINYL ESTRADIOL": "ETHINYL ESTRADIOL"
            },
            "PDRX PHARMACEUTICAL": {
              "ESTRACE": "ESTRACE",
              "ESTRADIOL": "ESTRADIOL",
              "MENEST": "MENEST"
            },
            "PERRIGO": {
              "ESTRADIOL VALERATE": "ESTRADIOL VALERATE",
              "EVAMIST": "EVAMIST"
            },
            "PFIZER U.S.": {
              "DEPO-ESTRADIOL": "DEPO-ESTRADIOL",
              "MENEST": "MENEST",
              "PREMARIN": "PREMARIN"
            },
            "PREFERRED PHARMACEUTICALS": {
              "ESTRADIOL": "ESTRADIOL"
            },
            "PROFICIENT RX": {
              "ESTRADIOL": "ESTRADIOL"
            },
            "QUALITY CARE": {
              "ESTRADIOL": "ESTRADIOL",
              "PREMARIN": "PREMARIN"
            },
            "QUALITY CARE PRODUCTS": {
              "ESTRADIOL": "ESTRADIOL"
            },
            "READYMEDS": {
              "ESTRADIOL": "ESTRADIOL"
            },
            "RXCHANGE CO": {
              "PREMARIN": "PREMARIN"
            },
            "SANDOZ": {
              "ESTRADIOL": "ESTRADIOL"
            },
            "TEVA PHARMACEUTICALS USA": {
              "ESTRADIOL": "ESTRADIOL"
            },
            "TEVA/WOMENS HEALTH": {
              "ENJUVIA": "ENJUVIA"
            },
            "US COMPOUNDING": {
              "ESTRADIOL": "ESTRADIOL"
            },
            "VERTICAL PHARMACEUTICAL": {
              "DIVIGEL": "DIVIGEL"
            }
          }
        }
      },
      "FLUOROQUINOLONES": {
        "Fluoroquinolones": {
          "Fluoroquinolones": {
            "A-S MEDICATION SOLUTIONS": {
              "AVELOX": "AVELOX",
              "CIPRO": "CIPRO",
              "CIPROFLOXACIN HCL": "CIPROFLOXACIN HCL",
              "CIPROFLOXACIN-CIPROFLOX HCL ER": "CIPROFLOXACIN-CIPROFLOX HCL ER",
              "LEVAQUIN": "LEVAQUIN",
              "LEVOFLOXACIN": "LEVOFLOXACIN",
              "MOXIFLOXACIN HCL": "MOXIFLOXACIN HCL"
            },
            "ACETRIS HEALTH": {
              "LEVOFLOXACIN": "LEVOFLOXACIN"
            },
            "ACTAVIS PHARMA": {
              "CIPROFLOXACIN HCL": "CIPROFLOXACIN HCL"
            },
            "AIDAREX PHARMACEUTICALS": {
              "CIPROFLOXACIN HCL": "CIPROFLOXACIN HCL",
              "LEVOFLOXACIN": "LEVOFLOXACIN"
            },
            "AKORN": {
              "LEVOFLOXACIN": "LEVOFLOXACIN"
            },
            "ALTURA PHARMACEUTICALS": {
              "CIPRO": "CIPRO",
              "CIPROFLOXACIN HCL": "CIPROFLOXACIN HCL"
            },
            "ALVOGEN": {
              "MOXIFLOXACIN HCL": "MOXIFLOXACIN HCL"
            },
            "AMERICAN HEALTH PACKAGING": {
              "CIPROFLOXACIN HCL": "CIPROFLOXACIN HCL",
              "LEVOFLOXACIN": "LEVOFLOXACIN",
              "MOXIFLOXACIN HCL": "MOXIFLOXACIN HCL"
            },
            "APHENA PHARMA SOLUTIONS": {
              "CIPROFLOXACIN HCL": "CIPROFLOXACIN HCL"
            },
            "APOTEX": {
              "CIPROFLOXACIN HCL": "CIPROFLOXACIN HCL"
            },
            "AQ PHARMACEUTICALS": {
              "AVELOX": "AVELOX",
              "CIPRO": "CIPRO"
            },
            "AUROBINDO PHARMA": {
              "CIPROFLOXACIN HCL": "CIPROFLOXACIN HCL",
              "LEVOFLOXACIN": "LEVOFLOXACIN",
              "MOXIFLOXACIN HCL": "MOXIFLOXACIN HCL"
            },
            "AUROBINDO PHARMA USA": {
              "CIPROFLOXACIN HCL": "CIPROFLOXACIN HCL"
            },
            "AUROMEDICS PHARMA": {
              "LEVOFLOXACIN": "LEVOFLOXACIN",
              "LEVOFLOXACIN IN D5W": "LEVOFLOXACIN IN D5W"
            },
            "AVET PHARMACEUTICALS": {
              "LEVOFLOXACIN": "LEVOFLOXACIN"
            },
            "AVKARE": {
              "CIPROFLOXACIN HCL": "CIPROFLOXACIN HCL",
              "LEVOFLOXACIN": "LEVOFLOXACIN"
            },
            "AVPAK": {
              "MOXIFLOXACIN HCL": "MOXIFLOXACIN HCL"
            },
            "BAXTER HEALTHCARE CORP": {
              "CIPROFLOXACIN": "CIPROFLOXACIN",
              "CIPROFLOXACIN IN D5W": "CIPROFLOXACIN IN D5W",
              "LEVOFLOXACIN": "LEVOFLOXACIN",
              "LEVOFLOXACIN IN D5W": "LEVOFLOXACIN IN D5W"
            },
            "BAYER HEALTHCARE PHARMA": {
              "AVELOX": "AVELOX",
              "CIPRO": "CIPRO",
              "CIPRO IN D5W": "CIPRO IN D5W",
              "CIPRO XR": "CIPRO XR"
            },
            "BLENHEIM PHARMACAL": {
              "CIPROFLOXACIN HCL": "CIPROFLOXACIN HCL",
              "LEVOFLOXACIN": "LEVOFLOXACIN"
            },
            "BLU PHARMACEUTICALS": {
              "CIPROFLOXACIN HCL": "CIPROFLOXACIN HCL"
            },
            "BRECKENRIDGE": {
              "MOXIFLOXACIN HCL": "MOXIFLOXACIN HCL"
            },
            "BRYANT RANCH PREPACK": {
              "CIPROFLOXACIN HCL": "CIPROFLOXACIN HCL",
              "LEVAQUIN": "LEVAQUIN",
              "LEVOFLOXACIN": "LEVOFLOXACIN"
            },
            "CAMBER PHARMACEUTICALS": {
              "LEVOFLOXACIN": "LEVOFLOXACIN"
            },
            "CARDINAL HEALTH REPACKAGING SO": {
              "CIPROFLOXACIN HCL": "CIPROFLOXACIN HCL"
            },
            "CARLSBAD TECHNOLOGIES": {
              "CIPROFLOXACIN HCL": "CIPROFLOXACIN HCL"
            },
            "CHARTWELL GOVERNMENTAL & SPECI": {
              "CIPROFLOXACIN HCL": "CIPROFLOXACIN HCL"
            },
            "CIPLA USA": {
              "LEVOFLOXACIN": "LEVOFLOXACIN"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "CIPROFLOXACIN HCL": "CIPROFLOXACIN HCL",
              "LEVOFLOXACIN": "LEVOFLOXACIN"
            },
            "DENTON PHARMA": {
              "CIPROFLOXACIN HCL": "CIPROFLOXACIN HCL",
              "LEVOFLOXACIN": "LEVOFLOXACIN"
            },
            "DIRECT RX": {
              "CIPROFLOXACIN HCL": "CIPROFLOXACIN HCL",
              "LEVOFLOXACIN": "LEVOFLOXACIN"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "CIPROFLOXACIN HCL": "CIPROFLOXACIN HCL",
              "LEVOFLOXACIN": "LEVOFLOXACIN",
              "MOXIFLOXACIN HCL": "MOXIFLOXACIN HCL",
              "OFLOXACIN": "OFLOXACIN"
            },
            "ENOVACHEM MANUFACTURING": {
              "CIPROFLOXACIN HCL": "CIPROFLOXACIN HCL"
            },
            "FRESENIUS KABI USA": {
              "LEVOFLOXACIN IN D5W": "LEVOFLOXACIN IN D5W",
              "MOXIFLOXACIN HCL": "MOXIFLOXACIN HCL"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "CIPROFLOXACIN HCL": "CIPROFLOXACIN HCL",
              "MOXIFLOXACIN HCL": "MOXIFLOXACIN HCL"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "AVELOX": "AVELOX",
              "CIPRO": "CIPRO",
              "CIPROFLOXACIN HCL": "CIPROFLOXACIN HCL",
              "LEVAQUIN": "LEVAQUIN"
            },
            "HI-TECH": {
              "LEVOFLOXACIN": "LEVOFLOXACIN"
            },
            "HIKMA": {
              "CIPROFLOXACIN HCL": "CIPROFLOXACIN HCL",
              "LEVOFLOXACIN IN D5W": "LEVOFLOXACIN IN D5W"
            },
            "HOSPIRA": {
              "CIPROFLOXACIN IN D5W": "CIPROFLOXACIN IN D5W",
              "LEVOFLOXACIN IN D5W": "LEVOFLOXACIN IN D5W"
            },
            "JANSSEN": {
              "LEVAQUIN": "LEVAQUIN"
            },
            "KAISER FOUNDATION HOSPITAL": {
              "CIPROFLOXACIN HCL": "CIPROFLOXACIN HCL"
            },
            "LANNETT": {
              "LEVOFLOXACIN": "LEVOFLOXACIN"
            },
            "LARKEN LABORATORIES, INC.": {
              "OFLOXACIN": "OFLOXACIN"
            },
            "LIBERTY PHARMACEUTICAL": {
              "CIPROFLOXACIN HCL": "CIPROFLOXACIN HCL",
              "LEVOFLOXACIN": "LEVOFLOXACIN"
            },
            "LUPIN PHARMACEUTICALS": {
              "CIPROFLOXACIN": "CIPROFLOXACIN",
              "LEVOFLOXACIN": "LEVOFLOXACIN"
            },
            "MACLEODS PHARMACEUTICALS": {
              "LEVOFLOXACIN": "LEVOFLOXACIN"
            },
            "MAJOR PHARMACEUTICALS": {
              "CIPROFLOXACIN HCL": "CIPROFLOXACIN HCL",
              "LEVOFLOXACIN": "LEVOFLOXACIN",
              "MOXIFLOXACIN HCL": "MOXIFLOXACIN HCL"
            },
            "MARLEX PHARMACEUTICALS": {
              "CIPROFLOXACIN HCL": "CIPROFLOXACIN HCL"
            },
            "MEDVANTX": {
              "CIPRO": "CIPRO",
              "LEVAQUIN": "LEVAQUIN"
            },
            "MELINTA THERAPEUTICS": {
              "BAXDELA": "BAXDELA"
            },
            "MERCK SHARP & DOHME": {
              "AVELOX": "AVELOX",
              "AVELOX ABC PACK": "AVELOX ABC PACK"
            },
            "METHOD PHARMACEUTICALS": {
              "CIPROFLOXACIN HCL": "CIPROFLOXACIN HCL"
            },
            "MYLAN": {
              "CIPROFLOXACIN HCL": "CIPROFLOXACIN HCL",
              "CIPROFLOXACIN-CIPROFLOX HCL ER": "CIPROFLOXACIN-CIPROFLOX HCL ER",
              "MOXIFLOXACIN HCL": "MOXIFLOXACIN HCL",
              "MOXIFLOXACIN HCL IN NACL": "MOXIFLOXACIN HCL IN NACL"
            },
            "MYLAN INSTITUTIONAL": {
              "CIPROFLOXACIN HCL": "CIPROFLOXACIN HCL"
            },
            "NEW HORIZON RX GROUP": {
              "LEVOFLOXACIN": "LEVOFLOXACIN"
            },
            "NIVAGEN PHARMACEUTICALS": {
              "OFLOXACIN": "OFLOXACIN"
            },
            "NORTHSTAR RX": {
              "CIPROFLOXACIN HCL": "CIPROFLOXACIN HCL"
            },
            "NOVADOZ PHARMACEUTICALS": {
              "MOXIFLOXACIN HCL": "MOXIFLOXACIN HCL"
            },
            "NOVAPLUS/BAXTER HEALTHCARE": {
              "CIPROFLOXACIN IN D5W": "CIPROFLOXACIN IN D5W"
            },
            "NOVAPLUS/HIKMA": {
              "LEVOFLOXACIN IN D5W": "LEVOFLOXACIN IN D5W"
            },
            "NOVAPLUS/HOSPIRA": {
              "CIPROFLOXACIN IN D5W": "CIPROFLOXACIN IN D5W"
            },
            "NOVAPLUS/SAGENT PHARMACEUTICAL": {
              "LEVOFLOXACIN IN D5W": "LEVOFLOXACIN IN D5W"
            },
            "NUCARE PHARMACEUTICALS": {
              "CIPRO": "CIPRO",
              "CIPROFLOXACIN HCL": "CIPROFLOXACIN HCL",
              "LEVAQUIN": "LEVAQUIN",
              "LEVOFLOXACIN": "LEVOFLOXACIN"
            },
            "PACK PHARMACEUTICALS, LLC": {
              "CIPROFLOXACIN HCL": "CIPROFLOXACIN HCL"
            },
            "PAR PHARMACEUTICAL": {
              "LEVOFLOXACIN": "LEVOFLOXACIN"
            },
            "PATRIOT PHARMACEUTICALS LLC": {
              "LEVOFLOXACIN": "LEVOFLOXACIN"
            },
            "PDRX PHARMACEUTICAL": {
              "AVELOX": "AVELOX",
              "CIPRO": "CIPRO",
              "CIPROFLOXACIN HCL": "CIPROFLOXACIN HCL",
              "LEVAQUIN": "LEVAQUIN",
              "LEVOFLOXACIN": "LEVOFLOXACIN",
              "MOXIFLOXACIN HCL": "MOXIFLOXACIN HCL",
              "OFLOXACIN": "OFLOXACIN"
            },
            "PDRX PHARMACEUTICALS": {
              "CIPROFLOXACIN HCL": "CIPROFLOXACIN HCL",
              "LEVOFLOXACIN": "LEVOFLOXACIN",
              "MOXIFLOXACIN HCL": "MOXIFLOXACIN HCL"
            },
            "PHARMACEUTICAL ASSOCIATES": {
              "LEVOFLOXACIN": "LEVOFLOXACIN"
            },
            "PHARMEDIX": {
              "CIPROFLOXACIN HCL": "CIPROFLOXACIN HCL"
            },
            "PREFERRED PHARMACEUTICALS": {
              "CIPROFLOXACIN HCL": "CIPROFLOXACIN HCL",
              "LEVOFLOXACIN": "LEVOFLOXACIN"
            },
            "PROFICIENT RX": {
              "CIPROFLOXACIN HCL": "CIPROFLOXACIN HCL",
              "LEVOFLOXACIN": "LEVOFLOXACIN"
            },
            "QUALITY CARE": {
              "AVELOX": "AVELOX",
              "CIPRO": "CIPRO",
              "CIPROFLOXACIN HCL": "CIPROFLOXACIN HCL",
              "LEVAQUIN": "LEVAQUIN",
              "LEVOFLOXACIN": "LEVOFLOXACIN"
            },
            "QUALITY CARE PRODUCTS": {
              "CIPROFLOXACIN HCL": "CIPROFLOXACIN HCL",
              "LEVOFLOXACIN": "LEVOFLOXACIN",
              "MOXIFLOXACIN HCL": "MOXIFLOXACIN HCL"
            },
            "READYMEDS": {
              "CIPROFLOXACIN HCL": "CIPROFLOXACIN HCL",
              "LEVOFLOXACIN": "LEVOFLOXACIN"
            },
            "RISING PHARMACEUTICALS": {
              "MOXIFLOXACIN HCL": "MOXIFLOXACIN HCL"
            },
            "RXCHANGE CO": {
              "CIPROFLOXACIN HCL": "CIPROFLOXACIN HCL",
              "LEVAQUIN": "LEVAQUIN"
            },
            "SAGENT PHARMACEUTICAL": {
              "CIPROFLOXACIN IN D5W": "CIPROFLOXACIN IN D5W",
              "LEVOFLOXACIN": "LEVOFLOXACIN",
              "LEVOFLOXACIN IN D5W": "LEVOFLOXACIN IN D5W"
            },
            "SANDOZ": {
              "CIPROFLOXACIN IN D5W": "CIPROFLOXACIN IN D5W",
              "LEVOFLOXACIN": "LEVOFLOXACIN",
              "LEVOFLOXACIN IN D5W": "LEVOFLOXACIN IN D5W"
            },
            "SKY PACKAGING": {
              "CIPROFLOXACIN HCL": "CIPROFLOXACIN HCL"
            },
            "ST MARY'S MPP": {
              "CIPROFLOXACIN HCL": "CIPROFLOXACIN HCL"
            },
            "TEVA PHARMACEUTICALS USA": {
              "CIPROFLOXACIN HCL": "CIPROFLOXACIN HCL",
              "LEVOFLOXACIN": "LEVOFLOXACIN",
              "MOXIFLOXACIN HCL": "MOXIFLOXACIN HCL"
            },
            "TORRENT PHARMACEUTICALS": {
              "LEVOFLOXACIN": "LEVOFLOXACIN",
              "MOXIFLOXACIN HCL": "MOXIFLOXACIN HCL"
            },
            "UNIT DOSE SERVICES": {
              "CIPROFLOXACIN HCL": "CIPROFLOXACIN HCL",
              "LEVOFLOXACIN": "LEVOFLOXACIN"
            },
            "VANSEN PHARMA": {
              "FACTIVE": "FACTIVE"
            },
            "WG CRITICAL CARE": {
              "LEVOFLOXACIN IN D5W": "LEVOFLOXACIN IN D5W"
            },
            "YILING PHARMACEUTICAL": {
              "CIPROFLOXACIN HCL": "CIPROFLOXACIN HCL"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "LEVOFLOXACIN": "LEVOFLOXACIN"
            }
          }
        }
      },
      "GASTROINTESTINAL AGENTS - MISC.": {
        "5-HT4 Receptor Agonists": {
          "5-HT4 Receptor Agonists": {
            "SHIRE US INC.": {
              "MOTEGRITY": "MOTEGRITY"
            }
          }
        },
        "Agents for Chronic Idiopathic Constipation (CIC)": {
          "CIC Agents - Guanylate Cyclase-C (GC-C) Agonists": {
            "BAUSCH HEALTH": {
              "TRULANCE": "TRULANCE"
            }
          }
        },
        "Antiflatulents": {
          "Antiflatulents": {
            "A-S MEDICATION SOLUTIONS": {
              "SIMETHICONE": "SIMETHICONE"
            },
            "ADVANTICE HEALTH": {
              "PEDIACARE INFANTS GAS RELIEF": "PEDIACARE INFANTS GAS RELIEF"
            },
            "AIDAREX PHARMACEUTICALS": {
              "SIMETHICONE": "SIMETHICONE"
            },
            "AMERICAN SALES COMPANY": {
              "GAS RELIEF EXTRA STRENGTH": "GAS RELIEF EXTRA STRENGTH",
              "GAS RELIEF ULTRA STRENGTH": "GAS RELIEF ULTRA STRENGTH",
              "INFANTS GAS RELIEF": "INFANTS GAS RELIEF"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP ANTI-GAS": "GNP ANTI-GAS",
              "GNP GAS RELIEF EXTRA STRENGTH": "GNP GAS RELIEF EXTRA STRENGTH",
              "GNP INFANT GAS RELIEF": "GNP INFANT GAS RELIEF",
              "GNP INFANTS GAS RELIEF": "GNP INFANTS GAS RELIEF"
            },
            "APHENA PHARMA SOLUTIONS": {
              "MYTAB GAS": "MYTAB GAS"
            },
            "BERGEN BRUNSWIG": {
              "GNP ANTI-GAS": "GNP ANTI-GAS",
              "GNP GAS RELIEF": "GNP GAS RELIEF",
              "GNP GAS RELIEF EXTRA STRENGTH": "GNP GAS RELIEF EXTRA STRENGTH",
              "GNP GAS RELIEF MAX ST": "GNP GAS RELIEF MAX ST",
              "GNP INFANTS GAS RELIEF": "GNP INFANTS GAS RELIEF"
            },
            "CHAIN DRUG CONSORTIUM": {
              "ANTI-GAS ULTRA STRENGTH": "ANTI-GAS ULTRA STRENGTH",
              "GAS RELIEF": "GAS RELIEF",
              "GAS RELIEF EXTRA STRENGTH": "GAS RELIEF EXTRA STRENGTH",
              "GAS RELIEF ULTRA STRENGTH": "GAS RELIEF ULTRA STRENGTH",
              "INFANTS GAS RELIEF": "INFANTS GAS RELIEF"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "INFANTS GAS RELIEF": "INFANTS GAS RELIEF",
              "QC ANTI-GAS": "QC ANTI-GAS",
              "QC GAS RELIEF": "QC GAS RELIEF",
              "QC GAS RELIEF EXTRA STRENGTH": "QC GAS RELIEF EXTRA STRENGTH"
            },
            "CMP PHARMA": {
              "SIMETHICONE": "SIMETHICONE"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS BEANAID": "CVS BEANAID",
              "CVS GAS RELIEF": "CVS GAS RELIEF",
              "CVS GAS RELIEF DROPS EX ST": "CVS GAS RELIEF DROPS EX ST",
              "CVS GAS RELIEF EXTRA STRENGTH": "CVS GAS RELIEF EXTRA STRENGTH",
              "CVS GAS RELIEF INFANTS": "CVS GAS RELIEF INFANTS",
              "CVS GAS RELIEF ULTRA STRENGTH": "CVS GAS RELIEF ULTRA STRENGTH",
              "CVS INFANTS GAS RELIEF": "CVS INFANTS GAS RELIEF"
            },
            "EQUALINE": {
              "EQL GAS GONE": "EQL GAS GONE",
              "EQL GAS PREVENTION": "EQL GAS PREVENTION",
              "EQL GAS RELIEF": "EQL GAS RELIEF",
              "EQL GAS RELIEF ULTRA STRENGTH": "EQL GAS RELIEF ULTRA STRENGTH",
              "EQL INFANTS GAS RELIEF": "EQL INFANTS GAS RELIEF"
            },
            "FAGRON": {
              "SIMETHICONE": "SIMETHICONE"
            },
            "FLEET PHARMACEUTICALS": {
              "PHAZYME MAXIMUM STRENGTH": "PHAZYME MAXIMUM STRENGTH",
              "PHAZYME ULTRA STRENGTH": "PHAZYME ULTRA STRENGTH"
            },
            "GEISS DESTIN & DUNN": {
              "GOODSENSE GAS RELIEF": "GOODSENSE GAS RELIEF"
            },
            "GERI-CARE": {
              "GAS RELIEF": "GAS RELIEF",
              "GAS RELIEF EXTRA STRENGTH": "GAS RELIEF EXTRA STRENGTH",
              "GAS RELIEF INFANTS": "GAS RELIEF INFANTS",
              "SIMETHICONE": "SIMETHICONE"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "GAS-X": "GAS-X",
              "GAS-X CHILDRENS": "GAS-X CHILDRENS",
              "GAS-X EXTRA STRENGTH": "GAS-X EXTRA STRENGTH",
              "GAS-X INFANT DROPS": "GAS-X INFANT DROPS",
              "GAS-X PREVENTION": "GAS-X PREVENTION",
              "GAS-X ULTRA STRENGTH": "GAS-X ULTRA STRENGTH"
            },
            "GLAXO SMITH KLINE": {
              "PHAZYME": "PHAZYME"
            },
            "HANNAFORD BROTHERS": {
              "SIMETHICONE": "SIMETHICONE"
            },
            "INFIRST HEALTHCARE": {
              "MYLANTA GAS MINIS": "MYLANTA GAS MINIS",
              "MYLICON INFANTS GAS RELIEF": "MYLICON INFANTS GAS RELIEF"
            },
            "KRAMER-NOVIS": {
              "BICARSIM": "BICARSIM",
              "BICARSIM FORTE": "BICARSIM FORTE",
              "SIMEPED": "SIMEPED"
            },
            "KROGER COMPANY": {
              "INFANTS GAS RELIEF": "INFANTS GAS RELIEF"
            },
            "LEADER BRAND PRODUCTS": {
              "GAS RELIEF": "GAS RELIEF",
              "GAS RELIEF DROPS INFANTS": "GAS RELIEF DROPS INFANTS",
              "GAS RELIEF EXTRA STRENGTH": "GAS RELIEF EXTRA STRENGTH",
              "GAS RELIEF ULTRA STRENGTH": "GAS RELIEF ULTRA STRENGTH",
              "SIMETHICONE": "SIMETHICONE"
            },
            "LETCO MEDICAL": {
              "SIMETHICONE": "SIMETHICONE"
            },
            "LIBERTY PHARMACEUTICAL": {
              "SIMETHICONE": "SIMETHICONE"
            },
            "MAGNO-HUMPHRIES LABORATORIES": {
              "SIMETHICONE EXTRA STRENGTH": "SIMETHICONE EXTRA STRENGTH"
            },
            "MAJOR PHARMACEUTICALS": {
              "GAS RELIEF EXTRA STRENGTH": "GAS RELIEF EXTRA STRENGTH",
              "GAS RELIEF ULTRA STRENGTH": "GAS RELIEF ULTRA STRENGTH",
              "INFANTS GAS RELIEF": "INFANTS GAS RELIEF",
              "MI-ACID GAS RELIEF": "MI-ACID GAS RELIEF"
            },
            "MARLEX PHARMACEUTICALS": {
              "SIMETHICONE": "SIMETHICONE"
            },
            "MCKESSON": {
              "HM GAS RELIEF": "HM GAS RELIEF",
              "HM GAS RELIEF EXTRA STRENGTH": "HM GAS RELIEF EXTRA STRENGTH",
              "HM GAS RELIEF INFANTS DROPS": "HM GAS RELIEF INFANTS DROPS"
            },
            "MCKESSON SUNMARK": {
              "SM GAS RELIEF": "SM GAS RELIEF",
              "SM GAS RELIEF ANTIFLATUENT": "SM GAS RELIEF ANTIFLATUENT",
              "SM GAS RELIEF EXTRA STRENGTH": "SM GAS RELIEF EXTRA STRENGTH",
              "SM GAS RELIEF INFANTS": "SM GAS RELIEF INFANTS",
              "SM GAS RELIEF INFANTS DROPS": "SM GAS RELIEF INFANTS DROPS"
            },
            "MCNEIL CONSUMER PHARMACEUTICAL": {
              "MYLICON": "MYLICON",
              "MYLICON INFANTS GAS RELIEF": "MYLICON INFANTS GAS RELIEF"
            },
            "MEDICINE SHOPPE": {
              "GAS RELIEF EXTRA STRENGTH": "GAS RELIEF EXTRA STRENGTH",
              "INFANTS GAS RELIEF": "INFANTS GAS RELIEF"
            },
            "MEDTECH": {
              "BEANO": "BEANO",
              "BEANO MELTAWAYS": "BEANO MELTAWAYS",
              "BEANO TO GO": "BEANO TO GO",
              "BEANO ULTRA 800": "BEANO ULTRA 800",
              "LITTLE REMEDIES FOR TUMMYS": "LITTLE REMEDIES FOR TUMMYS",
              "LITTLE TUMMYS GAS RELIEF": "LITTLE TUMMYS GAS RELIEF",
              "PHAZYME": "PHAZYME"
            },
            "MEIJER": {
              "GAS RELIEF INFANTS": "GAS RELIEF INFANTS"
            },
            "MOM ENTERPRISES": {
              "MOMMY\\'S BLISS GAS RELIEF DROPS": "MOMMY\\'S BLISS GAS RELIEF DROPS"
            },
            "PAR PHARMACEUTICAL": {
              "MYTAB GAS": "MYTAB GAS",
              "MYTAB GAS MAXIMUM STRENGTH": "MYTAB GAS MAXIMUM STRENGTH"
            },
            "PAR PHARMACEUTICALS": {
              "SIMETHICONE": "SIMETHICONE"
            },
            "PCCA": {
              "SIMETHICONE": "SIMETHICONE"
            },
            "PERRIGO": {
              "GAS RELIEF EXTRA STRENGTH": "GAS RELIEF EXTRA STRENGTH"
            },
            "PERRIGO PHARMACEUTICALS": {
              "GAS RELIEF ULTRA STRENGTH": "GAS RELIEF ULTRA STRENGTH",
              "INFANTS SIMETHICONE": "INFANTS SIMETHICONE"
            },
            "PLUS PHARMA": {
              "SIMETHICONE": "SIMETHICONE"
            },
            "PUBLIX SUPER MARKETS INC.": {
              "PX GAS RELIEF EXTRA STRENGTH": "PX GAS RELIEF EXTRA STRENGTH",
              "PX GAS RELIEF INFANTS": "PX GAS RELIEF INFANTS",
              "PX GAS RELIEF ULTRA STRENGTH": "PX GAS RELIEF ULTRA STRENGTH"
            },
            "RELIABLE 1 LABS": {
              "INFANTS GAS RELIEF": "INFANTS GAS RELIEF",
              "SIMETHICONE": "SIMETHICONE"
            },
            "RICHMOND PHARMACEUTICALS": {
              "SIMETHICONE": "SIMETHICONE"
            },
            "RITE AID CORPORATION": {
              "RA ANTI-GAS": "RA ANTI-GAS",
              "RA GAS RELIEF": "RA GAS RELIEF",
              "RA GAS RELIEF EXTRA STRENGTH": "RA GAS RELIEF EXTRA STRENGTH",
              "RA GAS RELIEF MAXIMUM STRENGTH": "RA GAS RELIEF MAXIMUM STRENGTH",
              "RA GAS RELIEF ULTRA STRENGTH": "RA GAS RELIEF ULTRA STRENGTH",
              "RA GAS RELIEF/INFANTS": "RA GAS RELIEF/INFANTS"
            },
            "RUGBY LABORATORIES": {
              "GAS RELIEF": "GAS RELIEF",
              "GAS RELIEF EXTRA STRENGTH": "GAS RELIEF EXTRA STRENGTH",
              "GAS RELIEF MAXIMUM STRENGTH": "GAS RELIEF MAXIMUM STRENGTH",
              "SIMETHICONE": "SIMETHICONE"
            },
            "SELECT BRAND": {
              "SB ANTI-GAS": "SB ANTI-GAS",
              "SB GAS RELIEF": "SB GAS RELIEF"
            },
            "SKY PACKAGING": {
              "SIMETHICONE": "SIMETHICONE"
            },
            "SPECTRUM": {
              "SIMETHICONE": "SIMETHICONE"
            },
            "STRIDES USA CONSUMER": {
              "PEDIACARE INFANTS GAS RELIEF": "PEDIACARE INFANTS GAS RELIEF"
            },
            "TARGET": {
              "TGT GAS RELIEF EXTRA STRENGTH": "TGT GAS RELIEF EXTRA STRENGTH",
              "TGT GAS RELIEF INFANTS": "TGT GAS RELIEF INFANTS"
            },
            "TOPCO": {
              "GAS RELIEF": "GAS RELIEF",
              "GAS RELIEF EXTRA STRENGTH": "GAS RELIEF EXTRA STRENGTH",
              "GAS RELIEF ULTRA STRENGTH": "GAS RELIEF ULTRA STRENGTH"
            },
            "WAL-MART": {
              "DRXCHOICE GAS RELIEF": "DRXCHOICE GAS RELIEF",
              "EQ GAS RELIEF": "EQ GAS RELIEF",
              "EQ GAS RELIEF EXTRA STRENGTH": "EQ GAS RELIEF EXTRA STRENGTH",
              "EQ INFANTS GAS RELIEF": "EQ INFANTS GAS RELIEF"
            },
            "WALGREENS": {
              "ANTI-GAS": "ANTI-GAS",
              "GAS RELIEF": "GAS RELIEF",
              "GAS RELIEF EXTRA STRENGTH": "GAS RELIEF EXTRA STRENGTH",
              "GAS RELIEF ULTRA STRENGTH": "GAS RELIEF ULTRA STRENGTH",
              "INFANTS GAS RELIEF": "INFANTS GAS RELIEF"
            }
          }
        },
        "Bile Acid Synthesis Disorder Agents": {
          "Bile Acid Synthesis Disorder Agents": {
            "MANCHESTER PHARMACEUTICALS": {
              "CHOLBAM": "CHOLBAM"
            }
          }
        },
        "Farnesoid X Receptor (FXR) Agonists": {
          "Farnesoid X Receptor (FXR) Agonists": {
            "INTERCEPT PHARMACEUTICALS": {
              "OCALIVA": "OCALIVA"
            }
          }
        },
        "Gallstone Solubilizing Agents": {
          "Gallstone Solubilizing Agents": {
            "A-S MEDICATION SOLUTIONS": {
              "URSODIOL": "URSODIOL"
            },
            "ACTAVIS PHARMA": {
              "URSODIOL": "URSODIOL"
            },
            "ALLERGAN": {
              "ACTIGALL": "ACTIGALL",
              "URSO 250": "URSO 250",
              "URSO FORTE": "URSO FORTE"
            },
            "AMERICAN HEALTH PACKAGING": {
              "URSODIOL": "URSODIOL"
            },
            "AMNEAL PHARMACEUTICALS": {
              "URSODIOL": "URSODIOL"
            },
            "AVKARE": {
              "URSODIOL": "URSODIOL"
            },
            "AVPAK": {
              "URSODIOL": "URSODIOL"
            },
            "BLU PHARMACEUTICALS": {
              "URSODIOL": "URSODIOL"
            },
            "BLUE POINT LABORATORIES": {
              "URSODIOL": "URSODIOL"
            },
            "BRYANT RANCH PREPACK": {
              "URSODIOL": "URSODIOL"
            },
            "EPIC PHARMA": {
              "URSODIOL": "URSODIOL"
            },
            "EYWA PHARMA": {
              "URSODIOL": "URSODIOL"
            },
            "FAGRON": {
              "URSODIOL+SYRSPEND SF": "URSODIOL+SYRSPEND SF"
            },
            "GLENMARK PHARMACEUTICALS": {
              "URSODIOL": "URSODIOL"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "URSODIOL": "URSODIOL"
            },
            "IMPAX GENERICS": {
              "URSODIOL": "URSODIOL"
            },
            "KVK TECH": {
              "URSODIOL": "URSODIOL"
            },
            "LANNETT": {
              "URSODIOL": "URSODIOL"
            },
            "MAJOR PHARMACEUTICALS": {
              "URSODIOL": "URSODIOL"
            },
            "MARLEX PHARMACEUTICALS": {
              "URSODIOL": "URSODIOL"
            },
            "MYLAN": {
              "URSODIOL": "URSODIOL"
            },
            "MYLAN INSTITUTIONAL": {
              "URSODIOL": "URSODIOL"
            },
            "PAR PHARMACEUTICAL": {
              "URSODIOL": "URSODIOL"
            },
            "PDRX PHARMACEUTICAL": {
              "URSODIOL": "URSODIOL"
            },
            "PRASCO LABORATORIES": {
              "URSODIOL": "URSODIOL"
            },
            "PREFERRED PHARMACEUTICALS": {
              "URSODIOL": "URSODIOL"
            },
            "QUALITY CARE PRODUCTS": {
              "URSODIOL": "URSODIOL"
            },
            "RETROPHIN": {
              "CHENODAL": "CHENODAL"
            },
            "STRIDES PHARMA": {
              "URSODIOL": "URSODIOL"
            },
            "TAGI PHARMA": {
              "URSODIOL": "URSODIOL"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "URSODIOL": "URSODIOL"
            }
          }
        },
        "Gastrointestinal Antiallergy Agents": {
          "Gastrointestinal Antiallergy Agents": {
            "MICRO LABORATORIES": {
              "CROMOLYN SODIUM": "CROMOLYN SODIUM"
            },
            "MYLAN SPECIALTY L.P.": {
              "GASTROCROM": "GASTROCROM"
            },
            "PACK PHARMACEUTICALS, LLC": {
              "CROMOLYN SODIUM": "CROMOLYN SODIUM"
            },
            "PRASCO LABORATORIES": {
              "CROMOLYN SODIUM": "CROMOLYN SODIUM"
            },
            "WALLACE PHARMACEUTICALS": {
              "CROMOLYN SODIUM": "CROMOLYN SODIUM"
            },
            "WOODWARD PHARMA SERVICES": {
              "CROMOLYN SODIUM": "CROMOLYN SODIUM"
            }
          }
        },
        "Gastrointestinal Chloride Channel Activators": {
          "Gastrointestinal Chloride Channel Activators": {
            "BRYANT RANCH PREPACK": {
              "AMITIZA": "AMITIZA"
            },
            "QUALITY CARE": {
              "AMITIZA": "AMITIZA"
            },
            "TAKEDA PHARMACEUTICALS": {
              "AMITIZA": "AMITIZA"
            }
          }
        },
        "Gastrointestinal Stimulants": {
          "Gastrointestinal Stimulants": {
            "A-S MEDICATION SOLUTIONS": {
              "METOCLOPRAMIDE HCL": "METOCLOPRAMIDE HCL"
            },
            "ALTURA PHARMACEUTICALS": {
              "METOCLOPRAMIDE HCL": "METOCLOPRAMIDE HCL"
            },
            "AMERICAN HEALTH PACKAGING": {
              "METOCLOPRAMIDE HCL": "METOCLOPRAMIDE HCL"
            },
            "AMERINET/HOSPIRA": {
              "METOCLOPRAMIDE HCL": "METOCLOPRAMIDE HCL"
            },
            "AMNEAL PHARMACEUTICALS": {
              "METOCLOPRAMIDE HCL": "METOCLOPRAMIDE HCL"
            },
            "ANI  PHARMACEUTICALS": {
              "METOCLOPRAMIDE HCL": "METOCLOPRAMIDE HCL",
              "REGLAN": "REGLAN"
            },
            "APHENA PHARMA SOLUTIONS": {
              "METOCLOPRAMIDE HCL": "METOCLOPRAMIDE HCL"
            },
            "ATLANTIC BIOLOGICALS": {
              "METOCLOPRAMIDE HCL": "METOCLOPRAMIDE HCL"
            },
            "AVET PHARMACEUTICALS": {
              "METOCLOPRAMIDE HCL": "METOCLOPRAMIDE HCL"
            },
            "AVKARE": {
              "METOCLOPRAMIDE HCL": "METOCLOPRAMIDE HCL"
            },
            "BAUSCH HEALTH": {
              "METOZOLV ODT": "METOZOLV ODT"
            },
            "BRYANT RANCH PREPACK": {
              "METOCLOPRAMIDE HCL": "METOCLOPRAMIDE HCL"
            },
            "CARDINAL HEALTH REPACKAGING SO": {
              "METOCLOPRAMIDE HCL": "METOCLOPRAMIDE HCL"
            },
            "DIRECT RX": {
              "METOCLOPRAMIDE HCL": "METOCLOPRAMIDE HCL"
            },
            "EMPOWER PHARMACY": {
              "DEXPANTHENOL": "DEXPANTHENOL"
            },
            "EVOKE PHARMA": {
              "GIMOTI": "GIMOTI"
            },
            "FAGRON": {
              "METOCLOPRAMIDE HCL": "METOCLOPRAMIDE HCL",
              "METOCLOPRAMIDE HCL MONOHYDRATE": "METOCLOPRAMIDE HCL MONOHYDRATE"
            },
            "FRESENIUS KABI USA": {
              "METOCLOPRAMIDE HCL": "METOCLOPRAMIDE HCL"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "METOCLOPRAMIDE HCL": "METOCLOPRAMIDE HCL"
            },
            "HOSPIRA": {
              "METOCLOPRAMIDE HCL": "METOCLOPRAMIDE HCL"
            },
            "IMPAX GENERICS": {
              "METOCLOPRAMIDE HCL": "METOCLOPRAMIDE HCL"
            },
            "LETCO MEDICAL": {
              "METOCLOPRAMIDE HCL": "METOCLOPRAMIDE HCL"
            },
            "LIBERTY PHARMACEUTICAL": {
              "METOCLOPRAMIDE HCL": "METOCLOPRAMIDE HCL"
            },
            "LUPIN PHARMACEUTICALS": {
              "METOCLOPRAMIDE HCL": "METOCLOPRAMIDE HCL"
            },
            "MEDISCA": {
              "METOCLOPRAMIDE HCL MONOHYDRATE": "METOCLOPRAMIDE HCL MONOHYDRATE"
            },
            "MYLAN INSTITUTIONAL": {
              "METOCLOPRAMIDE HCL": "METOCLOPRAMIDE HCL"
            },
            "NORTHSTAR RX": {
              "METOCLOPRAMIDE HCL": "METOCLOPRAMIDE HCL"
            },
            "NOVAPLUS/TEVA PARENTAL MEDICIN": {
              "METOCLOPRAMIDE HCL": "METOCLOPRAMIDE HCL"
            },
            "NUCARE PHARMACEUTICALS": {
              "METOCLOPRAMIDE HCL": "METOCLOPRAMIDE HCL"
            },
            "PAR PHARMACEUTICAL": {
              "METOCLOPRAMIDE HCL": "METOCLOPRAMIDE HCL"
            },
            "PCCA": {
              "METOCLOPRAMIDE HCL": "METOCLOPRAMIDE HCL",
              "METOCLOPRAMIDE HCL MONOHYDRATE": "METOCLOPRAMIDE HCL MONOHYDRATE"
            },
            "PHARMACEUTICAL ASSOCIATES": {
              "METOCLOPRAMIDE HCL": "METOCLOPRAMIDE HCL"
            },
            "PREFERRED PHARMACEUTICALS": {
              "METOCLOPRAMIDE HCL": "METOCLOPRAMIDE HCL"
            },
            "PROFICIENT RX": {
              "METOCLOPRAMIDE HCL": "METOCLOPRAMIDE HCL"
            },
            "QUALITY CARE": {
              "METOCLOPRAMIDE HCL": "METOCLOPRAMIDE HCL"
            },
            "SKY PACKAGING": {
              "METOCLOPRAMIDE HCL": "METOCLOPRAMIDE HCL"
            },
            "SPECTRUM": {
              "METOCLOPRAMIDE HCL MONOHYDRATE": "METOCLOPRAMIDE HCL MONOHYDRATE"
            },
            "SUN PHARMACEUTICALS": {
              "METOCLOPRAMIDE HCL": "METOCLOPRAMIDE HCL"
            },
            "TEVA PARENTERAL MEDICINES": {
              "METOCLOPRAMIDE HCL": "METOCLOPRAMIDE HCL"
            },
            "TEVA PHARMACEUTICALS USA": {
              "METOCLOPRAMIDE HCL": "METOCLOPRAMIDE HCL"
            },
            "VISTAPHARM INC.": {
              "METOCLOPRAMIDE HCL": "METOCLOPRAMIDE HCL"
            }
          }
        },
        "Inflammatory Bowel Agents": {
          "Inflammatory Bowel Agents": {
            "A-S MEDICATION SOLUTIONS": {
              "DELZICOL": "DELZICOL",
              "SULFASALAZINE": "SULFASALAZINE"
            },
            "ACTAVIS": {
              "ASACOL HD": "ASACOL HD",
              "DELZICOL": "DELZICOL"
            },
            "ACTAVIS PHARMA": {
              "MESALAMINE": "MESALAMINE",
              "SULFASALAZINE": "SULFASALAZINE"
            },
            "AIDAREX PHARMACEUTICALS": {
              "SULFASALAZINE": "SULFASALAZINE"
            },
            "ALAVEN PHARMACEUTICAL, LLC": {
              "ROWASA": "ROWASA"
            },
            "ALLERGAN": {
              "ASACOL HD": "ASACOL HD",
              "CANASA": "CANASA",
              "DELZICOL": "DELZICOL"
            },
            "AMERICAN HEALTH PACKAGING": {
              "BALSALAZIDE DISODIUM": "BALSALAZIDE DISODIUM",
              "MESALAMINE": "MESALAMINE"
            },
            "AMNEAL PHARMACEUTICALS": {
              "MESALAMINE": "MESALAMINE"
            },
            "AMRING PHARMACEUTICALS": {
              "MESALAMINE": "MESALAMINE"
            },
            "ANI  PHARMACEUTICALS": {
              "MESALAMINE": "MESALAMINE"
            },
            "APHENA PHARMA SOLUTIONS": {
              "APRISO": "APRISO",
              "SULFASALAZINE": "SULFASALAZINE",
              "SULFAZINE": "SULFAZINE"
            },
            "APOTEX": {
              "BALSALAZIDE DISODIUM": "BALSALAZIDE DISODIUM"
            },
            "AVKARE": {
              "BALSALAZIDE DISODIUM": "BALSALAZIDE DISODIUM",
              "MESALAMINE": "MESALAMINE"
            },
            "AVPAK": {
              "BALSALAZIDE DISODIUM": "BALSALAZIDE DISODIUM",
              "SULFASALAZINE": "SULFASALAZINE"
            },
            "BAUSCH HEALTH": {
              "APRISO": "APRISO",
              "COLAZAL": "COLAZAL",
              "GIAZO": "GIAZO"
            },
            "BLENHEIM PHARMACAL": {
              "SULFAZINE": "SULFAZINE"
            },
            "CAMBER PHARMACEUTICALS": {
              "MESALAMINE": "MESALAMINE"
            },
            "FAGRON": {
              "AMINOSALICYLIC ACID-5": "AMINOSALICYLIC ACID-5",
              "SULFASALAZINE": "SULFASALAZINE"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "BALSALAZIDE DISODIUM": "BALSALAZIDE DISODIUM"
            },
            "GREENSTONE": {
              "MESALAMINE": "MESALAMINE",
              "SULFASALAZINE": "SULFASALAZINE"
            },
            "HIKMA": {
              "BALSALAZIDE DISODIUM": "BALSALAZIDE DISODIUM"
            },
            "LETCO MEDICAL": {
              "MESALAMINE": "MESALAMINE",
              "SULFASALAZINE": "SULFASALAZINE"
            },
            "LUPIN PHARMACEUTICALS": {
              "MESALAMINE": "MESALAMINE",
              "MESALAMINE ER": "MESALAMINE ER"
            },
            "MEDA PHARMACEUTICALS": {
              "SFROWASA": "SFROWASA"
            },
            "MEDISCA": {
              "SULFASALAZINE": "SULFASALAZINE"
            },
            "MYLAN": {
              "MESALAMINE": "MESALAMINE",
              "MESALAMINE ER": "MESALAMINE ER"
            },
            "MYLAN SPECIALTY L.P.": {
              "DIPENTUM": "DIPENTUM",
              "ROWASA": "ROWASA",
              "SFROWASA": "SFROWASA"
            },
            "NORTHSTAR RX": {
              "MESALAMINE": "MESALAMINE"
            },
            "OCEANSIDE PHARMACEUTICALS": {
              "BALSALAZIDE DISODIUM": "BALSALAZIDE DISODIUM",
              "MESALAMINE ER": "MESALAMINE ER"
            },
            "PAR PHARMACEUTICALS": {
              "SULFASALAZINE": "SULFASALAZINE"
            },
            "PCCA": {
              "AMINOSALICYLIC ACID-5": "AMINOSALICYLIC ACID-5",
              "SULFASALAZINE": "SULFASALAZINE"
            },
            "PDRX PHARMACEUTICAL": {
              "SULFASALAZINE": "SULFASALAZINE"
            },
            "PERRIGO PHARMACEUTICALS": {
              "MESALAMINE": "MESALAMINE",
              "MESALAMINE-CLEANSER": "MESALAMINE-CLEANSER"
            },
            "PFIZER U.S.": {
              "AZULFIDINE": "AZULFIDINE",
              "AZULFIDINE EN-TABS": "AZULFIDINE EN-TABS"
            },
            "QUALITY CARE": {
              "SULFASALAZINE": "SULFASALAZINE"
            },
            "QUALITY CARE PRODUCTS": {
              "BALSALAZIDE DISODIUM": "BALSALAZIDE DISODIUM"
            },
            "RISING PHARMACEUTICALS": {
              "MESALAMINE": "MESALAMINE"
            },
            "SANDOZ": {
              "MESALAMINE": "MESALAMINE"
            },
            "SHIRE US INC.": {
              "LIALDA": "LIALDA",
              "MESALAMINE": "MESALAMINE",
              "PENTASA": "PENTASA"
            },
            "SPECTRUM": {
              "AMINOSALICYLIC ACID-5": "AMINOSALICYLIC ACID-5",
              "SULFASALAZINE": "SULFASALAZINE"
            },
            "SUN PHARMACEUTICALS": {
              "MESALAMINE": "MESALAMINE"
            },
            "TEVA PHARMACEUTICALS USA": {
              "MESALAMINE": "MESALAMINE"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "MESALAMINE": "MESALAMINE"
            }
          },
          "Integrin Receptor Antagonists": {
            "TAKEDA PHARMACEUTICALS": {
              "ENTYVIO": "ENTYVIO"
            }
          },
          "Interleukin Antagonists": {
            "JANSSEN BIOTECH": {
              "STELARA": "STELARA"
            }
          },
          "Tumor Necrosis Factor Alpha Blockers": {
            "AMGEN": {
              "AVSOLA": "AVSOLA"
            },
            "JANSSEN BIOTECH": {
              "REMICADE": "REMICADE"
            },
            "MERCK SHARP & DOHME": {
              "RENFLEXIS": "RENFLEXIS"
            },
            "PFIZER U.S.": {
              "INFLECTRA": "INFLECTRA"
            },
            "UCB PHARMA": {
              "CIMZIA": "CIMZIA",
              "CIMZIA PREFILLED": "CIMZIA PREFILLED",
              "CIMZIA STARTER KIT": "CIMZIA STARTER KIT"
            }
          }
        },
        "Intestinal Acidifiers": {
          "Intestinal Acidifiers": {
            "ACTAVIS": {
              "ENULOSE": "ENULOSE"
            },
            "HI-TECH": {
              "LACTULOSE ENCEPHALOPATHY": "LACTULOSE ENCEPHALOPATHY"
            },
            "MORTON GROVE PHARMACEUTICALS": {
              "GENERLAC": "GENERLAC"
            },
            "PATRIN PHARMA": {
              "LACTULOSE ENCEPHALOPATHY": "LACTULOSE ENCEPHALOPATHY"
            },
            "TORRENT PHARMACEUTICALS": {
              "LACTULOSE ENCEPHALOPATHY": "LACTULOSE ENCEPHALOPATHY"
            }
          }
        },
        "Irritable Bowel Syndrome (IBS) Agents": {
          "IBS Agent - 5-HT4 Receptor Partial Agonists": {
            "ALFASIGMA USA": {
              "ZELNORM": "ZELNORM"
            }
          },
          "IBS Agent - Guanylate Cyclase-C (GC-C) Agonists": {
            "ALLERGAN": {
              "LINZESS": "LINZESS"
            },
            "QUALITY CARE PRODUCTS": {
              "LINZESS": "LINZESS"
            }
          },
          "IBS Agent - Mu-Opioid Receptor Agonists": {
            "ALLERGAN": {
              "VIBERZI": "VIBERZI"
            }
          },
          "IBS Agent - Selective 5-HT3 Receptor Antagonists": {
            "ACTAVIS": {
              "ALOSETRON HCL": "ALOSETRON HCL"
            },
            "AMNEAL PHARMACEUTICALS": {
              "ALOSETRON HCL": "ALOSETRON HCL"
            },
            "AVKARE": {
              "ALOSETRON HCL": "ALOSETRON HCL"
            },
            "HIKMA": {
              "ALOSETRON HCL": "ALOSETRON HCL"
            },
            "PAR PHARMACEUTICAL": {
              "ALOSETRON HCL": "ALOSETRON HCL"
            },
            "RISING PHARMACEUTICALS": {
              "ALOSETRON HCL": "ALOSETRON HCL"
            },
            "SEBELA PHARMACEUTICALS": {
              "LOTRONEX": "LOTRONEX"
            }
          }
        },
        "Peripheral Opioid Receptor Antagonists": {
          "Peripheral Opioid Receptor Antagonists": {
            "ASTRAZENECA": {
              "MOVANTIK": "MOVANTIK"
            },
            "BAUSCH HEALTH": {
              "RELISTOR": "RELISTOR"
            },
            "BIODELIVERY SCIENCES": {
              "SYMPROIC": "SYMPROIC"
            },
            "MERCK SHARP & DOHME": {
              "ENTEREG": "ENTEREG"
            },
            "PURDUE PHARMA L.P.": {
              "SYMPROIC": "SYMPROIC"
            },
            "QUALITY CARE PRODUCTS": {
              "MOVANTIK": "MOVANTIK"
            },
            "REDHILL BIOPHARMA": {
              "MOVANTIK": "MOVANTIK"
            },
            "SHIONOGI PHARMA": {
              "SYMPROIC": "SYMPROIC"
            }
          }
        },
        "Phosphate Binder Agents": {
          "Phosphate Binder Agents": {
            "AMERICAN HEALTH PACKAGING": {
              "CALCIUM ACETATE (PHOS BINDER)": "CALCIUM ACETATE (PHOS BINDER)",
              "SEVELAMER CARBONATE": "SEVELAMER CARBONATE",
              "SEVELAMER HCL": "SEVELAMER HCL"
            },
            "AMNEAL PHARMACEUTICALS": {
              "CALCIUM ACETATE (PHOS BINDER)": "CALCIUM ACETATE (PHOS BINDER)",
              "SEVELAMER CARBONATE": "SEVELAMER CARBONATE"
            },
            "APNAR PHARMA": {
              "CALCIUM ACETATE (PHOS BINDER)": "CALCIUM ACETATE (PHOS BINDER)"
            },
            "ATLANTIC BIOLOGICALS": {
              "CALCIUM ACETATE (PHOS BINDER)": "CALCIUM ACETATE (PHOS BINDER)",
              "RENAGEL": "RENAGEL"
            },
            "AUROBINDO PHARMA": {
              "SEVELAMER CARBONATE": "SEVELAMER CARBONATE"
            },
            "AVET PHARMACEUTICALS": {
              "CALCIUM ACETATE (PHOS BINDER)": "CALCIUM ACETATE (PHOS BINDER)"
            },
            "AVKARE": {
              "CALCIUM ACETATE (PHOS BINDER)": "CALCIUM ACETATE (PHOS BINDER)",
              "SEVELAMER CARBONATE": "SEVELAMER CARBONATE"
            },
            "AVPAK": {
              "CALCIUM ACETATE (PHOS BINDER)": "CALCIUM ACETATE (PHOS BINDER)",
              "SEVELAMER CARBONATE": "SEVELAMER CARBONATE"
            },
            "BRANDYWINE PHARMACEUTICALS": {
              "CALCIUM ACETATE (PHOS BINDER)": "CALCIUM ACETATE (PHOS BINDER)"
            },
            "CARDINAL HEALTH REPACKAGING SO": {
              "CALCIUM ACETATE (PHOS BINDER)": "CALCIUM ACETATE (PHOS BINDER)"
            },
            "CHARTWELL RX": {
              "CALCIUM ACETATE": "CALCIUM ACETATE",
              "CALCIUM ACETATE (PHOS BINDER)": "CALCIUM ACETATE (PHOS BINDER)"
            },
            "CIPLA USA": {
              "CALCIUM ACETATE (PHOS BINDER)": "CALCIUM ACETATE (PHOS BINDER)",
              "SEVELAMER CARBONATE": "SEVELAMER CARBONATE"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "SEVELAMER CARBONATE": "SEVELAMER CARBONATE"
            },
            "ECI PHARMACEUTICALS": {
              "CALCIUM ACETATE (PHOS BINDER)": "CALCIUM ACETATE (PHOS BINDER)"
            },
            "EXELAN PHARMACEUTICALS": {
              "CALCIUM ACETATE (PHOS BINDER)": "CALCIUM ACETATE (PHOS BINDER)",
              "SEVELAMER CARBONATE": "SEVELAMER CARBONATE"
            },
            "FRESENIUS": {
              "CALCIUM ACETATE (PHOS BINDER)": "CALCIUM ACETATE (PHOS BINDER)",
              "PHOSLYRA": "PHOSLYRA",
              "VELPHORO": "VELPHORO"
            },
            "GENZYME": {
              "RENAGEL": "RENAGEL",
              "RENVELA": "RENVELA"
            },
            "GLENMARK PHARMACEUTICALS": {
              "SEVELAMER HCL": "SEVELAMER HCL"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "CALCIUM ACETATE (PHOS BINDER)": "CALCIUM ACETATE (PHOS BINDER)",
              "SEVELAMER CARBONATE": "SEVELAMER CARBONATE"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "RENAGEL": "RENAGEL"
            },
            "HAWTHORN PHARMACEUTICALS": {
              "ELIPHOS": "ELIPHOS"
            },
            "HIKMA": {
              "CALCIUM ACETATE (PHOS BINDER)": "CALCIUM ACETATE (PHOS BINDER)"
            },
            "IMPAX GENERICS": {
              "SEVELAMER CARBONATE": "SEVELAMER CARBONATE"
            },
            "KERYX BIOPHARMACEUTICALS": {
              "AURYXIA": "AURYXIA"
            },
            "LUPIN PHARMACEUTICALS": {
              "CALCIUM ACETATE (PHOS BINDER)": "CALCIUM ACETATE (PHOS BINDER)",
              "LANTHANUM CARBONATE": "LANTHANUM CARBONATE"
            },
            "MAJOR PHARMACEUTICALS": {
              "CALCIUM ACETATE (PHOS BINDER)": "CALCIUM ACETATE (PHOS BINDER)",
              "SEVELAMER CARBONATE": "SEVELAMER CARBONATE"
            },
            "MARLEX PHARMACEUTICALS": {
              "CALCIUM ACETATE (PHOS BINDER)": "CALCIUM ACETATE (PHOS BINDER)"
            },
            "NEPHRO-TECH": {
              "CALPHRON": "CALPHRON"
            },
            "NORTHSTAR RX": {
              "SEVELAMER CARBONATE": "SEVELAMER CARBONATE"
            },
            "NOSTRUM LABORATORIES": {
              "CALCIUM ACETATE (PHOS BINDER)": "CALCIUM ACETATE (PHOS BINDER)"
            },
            "PDRX PHARMACEUTICAL": {
              "PHOSLO": "PHOSLO"
            },
            "PERRIGO": {
              "CALCIUM ACETATE (PHOS BINDER)": "CALCIUM ACETATE (PHOS BINDER)"
            },
            "PRASCO LABORATORIES": {
              "LANTHANUM CARBONATE": "LANTHANUM CARBONATE"
            },
            "PRECISION DOSE, INC": {
              "CALCIUM ACETATE (PHOS BINDER)": "CALCIUM ACETATE (PHOS BINDER)"
            },
            "SANDOZ": {
              "CALCIUM ACETATE (PHOS BINDER)": "CALCIUM ACETATE (PHOS BINDER)",
              "SEVELAMER CARBONATE": "SEVELAMER CARBONATE"
            },
            "SHIRE US INC.": {
              "FOSRENOL": "FOSRENOL"
            },
            "TWI PHARMACEUTICALS": {
              "SEVELAMER CARBONATE": "SEVELAMER CARBONATE"
            },
            "WILSHIRE PHARMACEUTICALS": {
              "SEVELAMER CARBONATE": "SEVELAMER CARBONATE"
            },
            "WINTHROP, US": {
              "SEVELAMER CARBONATE": "SEVELAMER CARBONATE",
              "SEVELAMER HCL": "SEVELAMER HCL"
            }
          }
        },
        "Short Bowel Syndrome (SBS) Agents": {
          "Glucagon-Like Peptide-2 (GLP-2) Analogs": {
            "SHIRE US INC.": {
              "GATTEX": "GATTEX"
            }
          }
        },
        "Tryptophan Hydroxylase Inhibitors": {
          "Tryptophan Hydroxylase Inhibitors": {
            "TERSERA THERAPEUTICS": {
              "XERMELO": "XERMELO"
            }
          }
        }
      },
      "GENERAL ANESTHETICS": {
        "Anesthetics - Misc.": {
          "Anesthetics - Misc.": {
            "A-S MEDICATION SOLUTIONS": {
              "PROPOFOL": "PROPOFOL"
            },
            "ACTAVIS PHARMA": {
              "PROPOFOL": "PROPOFOL"
            },
            "AMERICAN REGENT": {
              "ETOMIDATE": "ETOMIDATE"
            },
            "ATHENEX PHARMACEUTICAL": {
              "ETOMIDATE": "ETOMIDATE"
            },
            "AUROMEDICS PHARMA": {
              "ETOMIDATE": "ETOMIDATE"
            },
            "AVET PHARMACEUTICALS": {
              "ETOMIDATE": "ETOMIDATE"
            },
            "CIVICA": {
              "KETAMINE HCL": "KETAMINE HCL",
              "PROPOFOL": "PROPOFOL"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "PROPOFOL": "PROPOFOL"
            },
            "FAGRON COMPOUNDING SERVICES": {
              "KETAMINE HCL": "KETAMINE HCL"
            },
            "FOSUN PHARMA USA": {
              "ETOMIDATE": "ETOMIDATE"
            },
            "FRESENIUS KABI USA": {
              "DIPRIVAN": "DIPRIVAN",
              "FRESENIUS PROPOVEN": "FRESENIUS PROPOVEN",
              "PROPOFOL": "PROPOFOL"
            },
            "GENERAL INJECTABLES & VACCINES": {
              "KETAMINE HCL": "KETAMINE HCL"
            },
            "HIKMA": {
              "ETOMIDATE": "ETOMIDATE",
              "KETAMINE HCL": "KETAMINE HCL",
              "PROPOFOL": "PROPOFOL"
            },
            "HOSPIRA": {
              "AMIDATE": "AMIDATE",
              "KETAMINE HCL": "KETAMINE HCL",
              "PROPOFOL": "PROPOFOL"
            },
            "IMPRIMIS NJOF": {
              "KETAMINE HCL": "KETAMINE HCL"
            },
            "LEITERS HEALTH": {
              "KETAMINE HCL": "KETAMINE HCL"
            },
            "MYLAN INSTITUTIONAL": {
              "ETOMIDATE": "ETOMIDATE",
              "KETAMINE HCL": "KETAMINE HCL"
            },
            "NEPHRON STERILE COMPOUNDING": {
              "KETAMINE HCL": "KETAMINE HCL"
            },
            "NOVAPLUS/FRESENIUS KABI": {
              "DIPRIVAN": "DIPRIVAN",
              "PROPOFOL": "PROPOFOL"
            },
            "NOVAPLUS/HIKMA": {
              "ETOMIDATE": "ETOMIDATE"
            },
            "NOVAPLUS/HOSPIRA": {
              "AMIDATE": "AMIDATE"
            },
            "PAR STERILE PRODUCTS": {
              "KETALAR": "KETALAR",
              "KETAMINE HCL": "KETAMINE HCL"
            },
            "PFIZER U.S.": {
              "PROPOFOL": "PROPOFOL"
            },
            "PHARMEDIUM SERVICES": {
              "KETAMINE HCL": "KETAMINE HCL",
              "KETAMINE HCL-SODIUM CHLORIDE": "KETAMINE HCL-SODIUM CHLORIDE",
              "PROPOFOL": "PROPOFOL"
            },
            "QUVA PHARMA": {
              "KETAMINE HCL": "KETAMINE HCL",
              "KETAMINE HCL-SODIUM CHLORIDE": "KETAMINE HCL-SODIUM CHLORIDE"
            },
            "RX PHARMA PACK": {
              "ANESTHESIA S/I-40": "ANESTHESIA S/I-40",
              "ANESTHESIA S/I-40A": "ANESTHESIA S/I-40A",
              "ANESTHESIA S/I-40H": "ANESTHESIA S/I-40H",
              "ANESTHESIA S/I-40S": "ANESTHESIA S/I-40S",
              "ANESTHESIA S/I-60": "ANESTHESIA S/I-60"
            },
            "SAGENT PHARMACEUTICAL": {
              "ETOMIDATE": "ETOMIDATE",
              "PROPOFOL": "PROPOFOL"
            },
            "SCA PHARMACEUTICALS": {
              "KETAMINE HCL-SODIUM CHLORIDE": "KETAMINE HCL-SODIUM CHLORIDE"
            },
            "TEVA PARENTERAL MEDICINES": {
              "PROPOFOL": "PROPOFOL"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "ETOMIDATE": "ETOMIDATE"
            }
          }
        },
        "Barbiturate Anesthetics": {
          "Barbiturate Anesthetics": {
            "A-S MEDICATION SOLUTIONS": {
              "BREVITAL SODIUM": "BREVITAL SODIUM"
            },
            "PAR STERILE PRODUCTS": {
              "BREVITAL SODIUM": "BREVITAL SODIUM"
            },
            "PHARMEDIUM SERVICES": {
              "METHOHEXITAL SODIUM": "METHOHEXITAL SODIUM"
            },
            "QUVA PHARMA": {
              "METHOHEXITAL SODIUM": "METHOHEXITAL SODIUM"
            }
          }
        },
        "Volatile Anesthetics": {
          "Volatile Anesthetics": {
            "ABBVIE": {
              "ULTANE": "ULTANE"
            },
            "AMERINET/BAXTER": {
              "SEVOFLURANE": "SEVOFLURANE",
              "SUPRANE": "SUPRANE"
            },
            "BAXTER HEALTHCARE CORPORATION": {
              "FORANE": "FORANE",
              "SEVOFLURANE": "SEVOFLURANE",
              "SUPRANE": "SUPRANE"
            },
            "HALOCARBON PRODUCTS": {
              "ISOFLURANE": "ISOFLURANE",
              "SEVOFLURANE": "SEVOFLURANE"
            },
            "NOVAPLUS/ABBVIE": {
              "ULTANE": "ULTANE"
            },
            "NOVAPLUS/BAXTER HEALTHCARE": {
              "SUPRANE": "SUPRANE"
            },
            "NOVAPLUS/PIRAMAL CRITICAL CARE": {
              "SEVOFLURANE": "SEVOFLURANE",
              "TERRELL": "TERRELL"
            },
            "PIRAMAL CRITICAL CARE": {
              "ISOFLURANE": "ISOFLURANE",
              "SEVOFLURANE": "SEVOFLURANE",
              "SOJOURN": "SOJOURN",
              "TERRELL": "TERRELL"
            },
            "SANDOZ": {
              "DESFLURANE": "DESFLURANE",
              "SEVOFLURANE": "SEVOFLURANE"
            }
          }
        }
      },
      "GENITOURINARY AGENTS - MISCELLANEOUS": {
        "Acidifiers": {
          "Phosphates": {
            "BEACH": {
              "K-PHOS NO 2": "K-PHOS NO 2"
            }
          }
        },
        "Alkalinizers": {
          "Citrates": {
            "ACTAVIS PHARMA": {
              "POTASSIUM CITRATE ER": "POTASSIUM CITRATE ER"
            },
            "AMERICAN HEALTH PACKAGING": {
              "POTASSIUM CITRATE ER": "POTASSIUM CITRATE ER"
            },
            "ANI  PHARMACEUTICALS": {
              "POTASSIUM CITRATE ER": "POTASSIUM CITRATE ER"
            },
            "ATLANTIC BIOLOGICALS": {
              "POTASSIUM CITRATE-CITRIC ACID": "POTASSIUM CITRATE-CITRIC ACID"
            },
            "BIOCOMP PHARMA": {
              "POTASSIUM CITRATE ER": "POTASSIUM CITRATE ER"
            },
            "CMP PHARMA": {
              "ORACIT": "ORACIT"
            },
            "CYPRESS PHARMACEUTICAL": {
              "CYTRA K CRYSTALS": "CYTRA K CRYSTALS",
              "CYTRA-2": "CYTRA-2",
              "CYTRA-3": "CYTRA-3",
              "CYTRA-K": "CYTRA-K"
            },
            "FAGRON": {
              "POTASSIUM CITRATE": "POTASSIUM CITRATE"
            },
            "FREEDOM PHARMACEUTICALS": {
              "POTASSIUM CITRATE MONOHYDRATE": "POTASSIUM CITRATE MONOHYDRATE"
            },
            "HUMCO": {
              "SHOHLS MODIFIED": "SHOHLS MODIFIED"
            },
            "LETCO MEDICAL": {
              "POTASSIUM CITRATE": "POTASSIUM CITRATE",
              "SODIUM CITRATE ANHYDROUS": "SODIUM CITRATE ANHYDROUS"
            },
            "MEDISCA": {
              "POTASSIUM CITRATE": "POTASSIUM CITRATE",
              "SODIUM CITRATE": "SODIUM CITRATE"
            },
            "METHOD PHARMACEUTICALS": {
              "POT & SOD CIT-CIT AC": "POT & SOD CIT-CIT AC",
              "POTASSIUM CITRATE-CITRIC ACID": "POTASSIUM CITRATE-CITRIC ACID",
              "SOD CITRATE-CITRIC ACID": "SOD CITRATE-CITRIC ACID"
            },
            "MISSION": {
              "UROCIT-K 10": "UROCIT-K 10",
              "UROCIT-K 15": "UROCIT-K 15",
              "UROCIT-K 5": "UROCIT-K 5"
            },
            "PCCA": {
              "POTASSIUM CITRATE": "POTASSIUM CITRATE",
              "SODIUM CITRATE ANHYDROUS": "SODIUM CITRATE ANHYDROUS"
            },
            "PHARMACEUTICAL ASSOCIATES": {
              "POTASSIUM CITRATE-CITRIC ACID": "POTASSIUM CITRATE-CITRIC ACID",
              "SOD CITRATE-CITRIC ACID": "SOD CITRATE-CITRIC ACID",
              "TRICITRATES": "TRICITRATES"
            },
            "SPECTRUM": {
              "POTASSIUM CITRATE MONOHYDRATE": "POTASSIUM CITRATE MONOHYDRATE",
              "SODIUM CITRATE": "SODIUM CITRATE"
            },
            "TRIGEN LABORATORIES": {
              "TARON-CRYSTALS": "TARON-CRYSTALS"
            },
            "UPSHER-SMITH": {
              "POTASSIUM CITRATE ER": "POTASSIUM CITRATE ER"
            },
            "VENSUN PHARMACEUTICALS": {
              "POTASSIUM CITRATE ER": "POTASSIUM CITRATE ER"
            },
            "VIRTUS PHARMACEUTICALS": {
              "VIRTRATE-2": "VIRTRATE-2",
              "VIRTRATE-3": "VIRTRATE-3",
              "VIRTRATE-K": "VIRTRATE-K"
            },
            "VIRTUS PHARMACEUTICALS OPCO": {
              "POTASSIUM CITRATE-CITRIC ACID": "POTASSIUM CITRATE-CITRIC ACID",
              "VIRTRATE-2": "VIRTRATE-2",
              "VIRTRATE-3": "VIRTRATE-3"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "POTASSIUM CITRATE ER": "POTASSIUM CITRATE ER"
            }
          }
        },
        "Cystinosis Agents": {
          "Cystinosis Agents": {
            "HORIZON THERAPEUTICS USA": {
              "PROCYSBI": "PROCYSBI"
            },
            "MYLAN": {
              "CYSTAGON": "CYSTAGON"
            },
            "RAPTOR PHARMACEUTICAL": {
              "PROCYSBI": "PROCYSBI"
            }
          }
        },
        "Genitourinary Irrigants": {
          "Anti-infective Genitourinary Irrigants": {
            "ACTAVIS PHARMA": {
              "NEOMYCIN-POLYMYXIN B GU": "NEOMYCIN-POLYMYXIN B GU"
            },
            "PFIZER U.S.": {
              "NEOSPORIN GU IRRIGANT": "NEOSPORIN GU IRRIGANT"
            },
            "X-GEN PHARMACEUTICALS, INC": {
              "NEOMYCIN-POLYMYXIN B GU": "NEOMYCIN-POLYMYXIN B GU"
            }
          },
          "Genitourinary Irrigants": {
            "B. BRAUN MEDICAL": {
              "ACETIC ACID": "ACETIC ACID",
              "AMINOACETIC ACID": "AMINOACETIC ACID",
              "GLYCINE": "GLYCINE",
              "RESECTISOL": "RESECTISOL",
              "SODIUM CHLORIDE": "SODIUM CHLORIDE",
              "SORBITOL": "SORBITOL"
            },
            "BAXTER HEALTHCARE CORP": {
              "ACETIC ACID": "ACETIC ACID",
              "GLYCINE UROLOGIC": "GLYCINE UROLOGIC",
              "SODIUM CHLORIDE": "SODIUM CHLORIDE",
              "SORBITOL": "SORBITOL"
            },
            "COVIDIEN MEDICAL SUPPLIES": {
              "ARGYLE STERILE SALINE": "ARGYLE STERILE SALINE",
              "CURITY STERILE SALINE": "CURITY STERILE SALINE"
            },
            "FAGRON": {
              "GLYCINE": "GLYCINE"
            },
            "GERI-CARE": {
              "SODIUM CHLORIDE": "SODIUM CHLORIDE"
            },
            "GUARDIAN LABORATORIES": {
              "RENACIDIN": "RENACIDIN"
            },
            "HOSPIRA": {
              "ACETIC ACID": "ACETIC ACID",
              "GLYCINE": "GLYCINE",
              "SODIUM CHLORIDE": "SODIUM CHLORIDE",
              "SORBITOL-MANNITOL": "SORBITOL-MANNITOL"
            },
            "ICU MEDICAL": {
              "ACETIC ACID": "ACETIC ACID",
              "GLYCINE": "GLYCINE",
              "SODIUM CHLORIDE": "SODIUM CHLORIDE",
              "SORBITOL-MANNITOL": "SORBITOL-MANNITOL"
            },
            "LETCO MEDICAL": {
              "GLYCINE": "GLYCINE"
            },
            "MEDISCA": {
              "GLYCINE": "GLYCINE"
            },
            "PCCA": {
              "GLYCINE": "GLYCINE"
            },
            "SPECTRUM": {
              "GLYCINE": "GLYCINE"
            },
            "TYSON": {
              "GLYCINE": "GLYCINE"
            }
          }
        },
        "Interstitial Cystitis Agents": {
          "Interstitial Cystitis Agents": {
            "A-S MEDICATION SOLUTIONS": {
              "ELMIRON": "ELMIRON"
            },
            "IMPRIMIS NJOF": {
              "PENTOSAN POLYSULFATE SODIUM": "PENTOSAN POLYSULFATE SODIUM"
            },
            "JANSSEN": {
              "ELMIRON": "ELMIRON"
            },
            "MYLAN INSTITUTIONAL": {
              "RIMSO-50": "RIMSO-50"
            }
          }
        },
        "Prostatic Hypertrophy Agents": {
          "5-Alpha Reductase Inhibitors": {
            "A-S MEDICATION SOLUTIONS": {
              "AVODART": "AVODART",
              "DUTASTERIDE": "DUTASTERIDE",
              "FINASTERIDE": "FINASTERIDE"
            },
            "ACCORD HEALTHCARE": {
              "FINASTERIDE": "FINASTERIDE"
            },
            "ACTAVIS": {
              "FINASTERIDE": "FINASTERIDE"
            },
            "ACTAVIS PHARMA": {
              "DUTASTERIDE": "DUTASTERIDE"
            },
            "AIDAREX PHARMACEUTICALS": {
              "FINASTERIDE": "FINASTERIDE"
            },
            "AMERICAN HEALTH PACKAGING": {
              "FINASTERIDE": "FINASTERIDE"
            },
            "AMNEAL PHARMACEUTICALS": {
              "DUTASTERIDE": "DUTASTERIDE"
            },
            "APHENA PHARMA SOLUTIONS": {
              "PROSCAR": "PROSCAR"
            },
            "APOTEX": {
              "DUTASTERIDE": "DUTASTERIDE"
            },
            "AQ PHARMACEUTICALS": {
              "AVODART": "AVODART",
              "PROSCAR": "PROSCAR"
            },
            "ASCEND LABORATORIES": {
              "FINASTERIDE": "FINASTERIDE"
            },
            "AUROBINDO PHARMA": {
              "FINASTERIDE": "FINASTERIDE"
            },
            "AVKARE": {
              "DUTASTERIDE": "DUTASTERIDE",
              "FINASTERIDE": "FINASTERIDE"
            },
            "AVPAK": {
              "DUTASTERIDE": "DUTASTERIDE",
              "FINASTERIDE": "FINASTERIDE"
            },
            "BRECKENRIDGE": {
              "DUTASTERIDE": "DUTASTERIDE"
            },
            "BRYANT RANCH PREPACK": {
              "FINASTERIDE": "FINASTERIDE"
            },
            "CAMBER PHARMACEUTICALS": {
              "DUTASTERIDE": "DUTASTERIDE",
              "FINASTERIDE": "FINASTERIDE"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "FINASTERIDE": "FINASTERIDE"
            },
            "DIRECT RX": {
              "FINASTERIDE": "FINASTERIDE"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "FINASTERIDE": "FINASTERIDE"
            },
            "EPIC PHARMA": {
              "DUTASTERIDE": "DUTASTERIDE"
            },
            "EXELAN PHARMACEUTICALS": {
              "FINASTERIDE": "FINASTERIDE"
            },
            "GLAXO SMITH KLINE": {
              "AVODART": "AVODART"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "DUTASTERIDE": "DUTASTERIDE"
            },
            "HIKMA": {
              "DUTASTERIDE": "DUTASTERIDE"
            },
            "INGENUS PHARMACEUTICALS": {
              "FINASTERIDE": "FINASTERIDE"
            },
            "LEGACY PHARMACEUTICAL PKG": {
              "FINASTERIDE": "FINASTERIDE"
            },
            "LIBERTY PHARMACEUTICAL": {
              "FINASTERIDE": "FINASTERIDE"
            },
            "MAJOR PHARMACEUTICALS": {
              "FINASTERIDE": "FINASTERIDE"
            },
            "MERCK SHARP & DOHME": {
              "PROSCAR": "PROSCAR"
            },
            "MYLAN": {
              "DUTASTERIDE": "DUTASTERIDE",
              "FINASTERIDE": "FINASTERIDE"
            },
            "MYLAN INSTITUTIONAL": {
              "FINASTERIDE": "FINASTERIDE"
            },
            "NEW HORIZON RX GROUP": {
              "FINASTERIDE": "FINASTERIDE"
            },
            "NUCARE PHARMACEUTICALS": {
              "FINASTERIDE": "FINASTERIDE"
            },
            "PAR PHARMACEUTICALS": {
              "FINASTERIDE": "FINASTERIDE"
            },
            "PDRX PHARMACEUTICAL": {
              "PROSCAR": "PROSCAR"
            },
            "PREFERRED PHARMACEUTICALS": {
              "FINASTERIDE": "FINASTERIDE"
            },
            "PROFICIENT RX": {
              "DUTASTERIDE": "DUTASTERIDE",
              "FINASTERIDE": "FINASTERIDE"
            },
            "QUALITY CARE": {
              "AVODART": "AVODART"
            },
            "QUALITY CARE PRODUCTS": {
              "FINASTERIDE": "FINASTERIDE"
            },
            "RISING PHARMACEUTICALS": {
              "DUTASTERIDE": "DUTASTERIDE",
              "FINASTERIDE": "FINASTERIDE"
            },
            "STRIDES PHARMA": {
              "DUTASTERIDE": "DUTASTERIDE"
            },
            "SUN PHARMACEUTICAL": {
              "FINASTERIDE": "FINASTERIDE"
            },
            "TEVA PHARMACEUTICALS USA": {
              "DUTASTERIDE": "DUTASTERIDE",
              "FINASTERIDE": "FINASTERIDE"
            },
            "XLCARE PHARMACEUTICALS": {
              "DUTASTERIDE": "DUTASTERIDE"
            }
          },
          "Alpha 1-Adrenoceptor Antagonists": {
            "A-S MEDICATION SOLUTIONS": {
              "FLOMAX": "FLOMAX",
              "TAMSULOSIN HCL": "TAMSULOSIN HCL",
              "UROXATRAL": "UROXATRAL"
            },
            "ACTAVIS ELIZABETH": {
              "TAMSULOSIN HCL": "TAMSULOSIN HCL"
            },
            "AIDAREX PHARMACEUTICALS": {
              "ALFUZOSIN HCL ER": "ALFUZOSIN HCL ER",
              "TAMSULOSIN HCL": "TAMSULOSIN HCL"
            },
            "AJANTA PHARMA LIMITED": {
              "SILODOSIN": "SILODOSIN"
            },
            "ALLERGAN": {
              "RAPAFLO": "RAPAFLO"
            },
            "AMERICAN HEALTH PACKAGING": {
              "TAMSULOSIN HCL": "TAMSULOSIN HCL"
            },
            "AMNEAL PHARMACEUTICALS": {
              "SILODOSIN": "SILODOSIN"
            },
            "APHENA PHARMA SOLUTIONS": {
              "ALFUZOSIN HCL ER": "ALFUZOSIN HCL ER",
              "FLOMAX": "FLOMAX",
              "TAMSULOSIN HCL": "TAMSULOSIN HCL"
            },
            "APOTEX": {
              "ALFUZOSIN HCL ER": "ALFUZOSIN HCL ER"
            },
            "AQ PHARMACEUTICALS": {
              "FLOMAX": "FLOMAX"
            },
            "ASCEND LABORATORIES": {
              "TAMSULOSIN HCL": "TAMSULOSIN HCL"
            },
            "AUROBINDO PHARMA": {
              "ALFUZOSIN HCL ER": "ALFUZOSIN HCL ER",
              "SILODOSIN": "SILODOSIN",
              "TAMSULOSIN HCL": "TAMSULOSIN HCL"
            },
            "AVKARE": {
              "ALFUZOSIN HCL ER": "ALFUZOSIN HCL ER"
            },
            "AVPAK": {
              "TAMSULOSIN HCL": "TAMSULOSIN HCL"
            },
            "BIOGEN PHARMACEUTICALS": {
              "FLOMAX": "FLOMAX"
            },
            "BLENHEIM PHARMACAL": {
              "TAMSULOSIN HCL": "TAMSULOSIN HCL"
            },
            "BRYANT RANCH PREPACK": {
              "FLOMAX": "FLOMAX",
              "TAMSULOSIN HCL": "TAMSULOSIN HCL"
            },
            "CAMBER PHARMACEUTICALS": {
              "SILODOSIN": "SILODOSIN"
            },
            "CIPLA USA": {
              "ALFUZOSIN HCL ER": "ALFUZOSIN HCL ER"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "TAMSULOSIN HCL": "TAMSULOSIN HCL"
            },
            "CONCORDIA PHARMACEUTICALS": {
              "UROXATRAL": "UROXATRAL"
            },
            "DENTON PHARMA": {
              "TAMSULOSIN HCL": "TAMSULOSIN HCL"
            },
            "DIRECT RX": {
              "ALFUZOSIN HCL ER": "ALFUZOSIN HCL ER",
              "TAMSULOSIN HCL": "TAMSULOSIN HCL"
            },
            "EXELAN PHARMACEUTICALS": {
              "ALFUZOSIN HCL ER": "ALFUZOSIN HCL ER"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "TAMSULOSIN HCL": "TAMSULOSIN HCL"
            },
            "LIBERTY PHARMACEUTICAL": {
              "ALFUZOSIN HCL ER": "ALFUZOSIN HCL ER",
              "TAMSULOSIN HCL": "TAMSULOSIN HCL"
            },
            "LUPIN PHARMACEUTICALS": {
              "SILODOSIN": "SILODOSIN"
            },
            "MACLEODS PHARMACEUTICALS": {
              "SILODOSIN": "SILODOSIN",
              "TAMSULOSIN HCL": "TAMSULOSIN HCL"
            },
            "MAJOR PHARMACEUTICALS": {
              "TAMSULOSIN HCL": "TAMSULOSIN HCL"
            },
            "MEDVANTX": {
              "FLOMAX": "FLOMAX"
            },
            "MYLAN": {
              "ALFUZOSIN HCL ER": "ALFUZOSIN HCL ER",
              "TAMSULOSIN HCL": "TAMSULOSIN HCL"
            },
            "MYLAN INSTITUTIONAL": {
              "TAMSULOSIN HCL": "TAMSULOSIN HCL"
            },
            "NORTHSTAR RX": {
              "TAMSULOSIN HCL": "TAMSULOSIN HCL"
            },
            "NOVADOZ PHARMACEUTICALS": {
              "SILODOSIN": "SILODOSIN"
            },
            "NUCARE PHARMACEUTICALS": {
              "FLOMAX": "FLOMAX",
              "TAMSULOSIN HCL": "TAMSULOSIN HCL",
              "UROXATRAL": "UROXATRAL"
            },
            "PAR PHARMACEUTICAL": {
              "TAMSULOSIN HCL": "TAMSULOSIN HCL"
            },
            "PDRX PHARMACEUTICAL": {
              "FLOMAX": "FLOMAX",
              "TAMSULOSIN HCL": "TAMSULOSIN HCL"
            },
            "PDRX PHARMACEUTICALS": {
              "SILODOSIN": "SILODOSIN"
            },
            "PFIZER U.S.": {
              "CARDURA XL": "CARDURA XL"
            },
            "PREFERRED PHARMACEUTICALS": {
              "TAMSULOSIN HCL": "TAMSULOSIN HCL"
            },
            "PROFICIENT RX": {
              "TAMSULOSIN HCL": "TAMSULOSIN HCL"
            },
            "QUALITY CARE": {
              "FLOMAX": "FLOMAX",
              "TAMSULOSIN HCL": "TAMSULOSIN HCL"
            },
            "QUALITY CARE PRODUCTS": {
              "TAMSULOSIN HCL": "TAMSULOSIN HCL"
            },
            "RISING PHARMACEUTICALS": {
              "ALFUZOSIN HCL ER": "ALFUZOSIN HCL ER",
              "TAMSULOSIN HCL": "TAMSULOSIN HCL"
            },
            "SANDOZ": {
              "SILODOSIN": "SILODOSIN",
              "TAMSULOSIN HCL": "TAMSULOSIN HCL"
            },
            "SANOFI PHARMACEUTICALS": {
              "FLOMAX": "FLOMAX"
            },
            "SANOFI-AVENTIS U.S.": {
              "FLOMAX": "FLOMAX"
            },
            "SKY PACKAGING": {
              "TAMSULOSIN HCL": "TAMSULOSIN HCL"
            },
            "ST MARY'S MPP": {
              "TAMSULOSIN HCL": "TAMSULOSIN HCL"
            },
            "SUN PHARMACEUTICALS": {
              "ALFUZOSIN HCL ER": "ALFUZOSIN HCL ER",
              "TAMSULOSIN HCL": "TAMSULOSIN HCL"
            },
            "TORRENT PHARMACEUTICALS": {
              "ALFUZOSIN HCL ER": "ALFUZOSIN HCL ER"
            },
            "UNICHEM PHARMACEUTICALS": {
              "ALFUZOSIN HCL ER": "ALFUZOSIN HCL ER"
            },
            "VA CMOP DALLAS": {
              "FLOMAX": "FLOMAX",
              "UROXATRAL": "UROXATRAL"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "TAMSULOSIN HCL": "TAMSULOSIN HCL"
            }
          },
          "Prostatic Hypertrophy Agent Combinations": {
            "ACTAVIS PHARMA": {
              "DUTASTERIDE-TAMSULOSIN HCL": "DUTASTERIDE-TAMSULOSIN HCL"
            },
            "GLAXO SMITH KLINE": {
              "JALYN": "JALYN"
            },
            "PAR PHARMACEUTICAL": {
              "DUTASTERIDE-TAMSULOSIN HCL": "DUTASTERIDE-TAMSULOSIN HCL"
            },
            "PRASCO LABORATORIES": {
              "DUTASTERIDE-TAMSULOSIN HCL": "DUTASTERIDE-TAMSULOSIN HCL"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "DUTASTERIDE-TAMSULOSIN HCL": "DUTASTERIDE-TAMSULOSIN HCL"
            }
          }
        },
        "Urinary Analgesics": {
          "Urinary Analgesic Combinations": {
            "I-HEALTH": {
              "AZO URINARY TRACT SUPPORT": "AZO URINARY TRACT SUPPORT"
            },
            "INSIGHT PHARMACEUTICALS": {
              "URISTAT UTI RELIEF PAK": "URISTAT UTI RELIEF PAK"
            }
          },
          "Urinary Analgesics": {
            "A-S MEDICATION SOLUTIONS": {
              "AZO URINARY PAIN RELIEF": "AZO URINARY PAIN RELIEF",
              "PHENAZOPYRIDINE HCL": "PHENAZOPYRIDINE HCL"
            },
            "ACELLA PHARMACEUTICALS": {
              "PHENAZOPYRIDINE HCL": "PHENAZOPYRIDINE HCL"
            },
            "AIDAREX PHARMACEUTICALS": {
              "PHENAZOPYRIDINE HCL": "PHENAZOPYRIDINE HCL"
            },
            "ALTURA PHARMACEUTICALS": {
              "PHENAZOPYRIDINE HCL": "PHENAZOPYRIDINE HCL"
            },
            "AMERIFIT PHARMA": {
              "AZO-STANDARD": "AZO-STANDARD"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP URINARY PAIN RELIEF": "GNP URINARY PAIN RELIEF"
            },
            "AMNEAL PHARMACEUTICALS": {
              "PHENAZOPYRIDINE HCL": "PHENAZOPYRIDINE HCL"
            },
            "AMNEAL SPECIALTY": {
              "PYRIDIUM": "PYRIDIUM"
            },
            "AVKARE": {
              "PHENAZOPYRIDINE HCL": "PHENAZOPYRIDINE HCL"
            },
            "BERGEN BRUNSWIG": {
              "GNP URINARY PAIN RELIEF": "GNP URINARY PAIN RELIEF"
            },
            "BLENHEIM PHARMACAL": {
              "PHENAZOPYRIDINE HCL": "PHENAZOPYRIDINE HCL"
            },
            "BRYANT RANCH PREPACK": {
              "PHENAZOPYRIDINE HCL": "PHENAZOPYRIDINE HCL"
            },
            "BUREL PHARMACEUTICALS": {
              "PHENAZOPYRIDINE HCL": "PHENAZOPYRIDINE HCL"
            },
            "CHAIN DRUG CONSORTIUM": {
              "AZO DINE": "AZO DINE",
              "AZO DINE MAXIMUM STRENGTH": "AZO DINE MAXIMUM STRENGTH",
              "URINARY PAIN RELIEF": "URINARY PAIN RELIEF"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC AZO": "QC AZO",
              "QC URINARY PAIN RELIEF": "QC URINARY PAIN RELIEF",
              "QC URINARY PAIN RELIEF MAX ST": "QC URINARY PAIN RELIEF MAX ST"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "PHENAZOPYRIDINE HCL": "PHENAZOPYRIDINE HCL"
            },
            "CONTRACT PHARMACAL CORPORATION": {
              "PHENAZO": "PHENAZO"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS URINARY PAIN RELIEF": "CVS URINARY PAIN RELIEF",
              "CVS URINARY PAIN RELIEF MAX ST": "CVS URINARY PAIN RELIEF MAX ST"
            },
            "DIRECT RX": {
              "PHENAZOPYRIDINE HCL": "PHENAZOPYRIDINE HCL"
            },
            "ECI PHARMACEUTICALS": {
              "PHENAZOPYRIDINE HCL": "PHENAZOPYRIDINE HCL"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "PHENAZOPYRIDINE HCL": "PHENAZOPYRIDINE HCL"
            },
            "I-HEALTH": {
              "AZO URINARY PAIN RELIEF": "AZO URINARY PAIN RELIEF"
            },
            "INSIGHT PHARMACEUTICALS": {
              "URISTAT": "URISTAT",
              "URISTAT ULTRA": "URISTAT ULTRA"
            },
            "LAKE CONSUMER PRODUCTS": {
              "VH ESSENTIALS UTI RELIEF": "VH ESSENTIALS UTI RELIEF"
            },
            "LEADER BRAND PRODUCTS": {
              "AZO TABS": "AZO TABS",
              "URINARY PAIN RELIEF": "URINARY PAIN RELIEF",
              "URINARY PAIN RELIEF MAX ST": "URINARY PAIN RELIEF MAX ST"
            },
            "LIBERTY PHARMACEUTICAL": {
              "PHENAZOPYRIDINE HCL": "PHENAZOPYRIDINE HCL"
            },
            "MARLEX PHARMACEUTICALS": {
              "PHENAZOPYRIDINE HCL": "PHENAZOPYRIDINE HCL"
            },
            "MCKESSON": {
              "HM URINARY PAIN RELIEF": "HM URINARY PAIN RELIEF"
            },
            "MCKESSON SUNMARK": {
              "SM URINARY PAIN RELIEF": "SM URINARY PAIN RELIEF",
              "SM URINARY PAIN RELIEF MAX ST": "SM URINARY PAIN RELIEF MAX ST"
            },
            "MEDICINE SHOPPE": {
              "AZO TABS": "AZO TABS"
            },
            "MEDVANTX": {
              "PHENAZOPYRIDINE HCL": "PHENAZOPYRIDINE HCL"
            },
            "METHOD PHARMACEUTICALS": {
              "PHENAZOPYRIDINE HCL": "PHENAZOPYRIDINE HCL"
            },
            "NATIONWIDE LABORATORIES": {
              "PHENAZOPYRIDINE HCL": "PHENAZOPYRIDINE HCL"
            },
            "NUCARE PHARMACEUTICALS": {
              "PHENAZOPYRIDINE HCL": "PHENAZOPYRIDINE HCL"
            },
            "PCCA": {
              "PHENAZOPYRIDINE HCL": "PHENAZOPYRIDINE HCL"
            },
            "PDRX PHARMACEUTICAL": {
              "PHENAZOPYRIDINE HCL": "PHENAZOPYRIDINE HCL",
              "PYRIDIUM": "PYRIDIUM"
            },
            "PFEIFFER": {
              "BARIDIUM": "BARIDIUM"
            },
            "PHARMEDIX": {
              "PHENAZOPYRIDINE HCL": "PHENAZOPYRIDINE HCL"
            },
            "PREFERRED PHARMACEUTICALS": {
              "PHENAZOPYRIDINE HCL": "PHENAZOPYRIDINE HCL"
            },
            "PROFICIENT RX": {
              "PHENAZOPYRIDINE HCL": "PHENAZOPYRIDINE HCL"
            },
            "QUALITY CARE": {
              "PHENAZOPYRIDINE HCL": "PHENAZOPYRIDINE HCL"
            },
            "QUALITY CARE PRODUCTS": {
              "PHENAZOPYRIDINE HCL": "PHENAZOPYRIDINE HCL"
            },
            "READYMEDS": {
              "PHENAZOPYRIDINE HCL": "PHENAZOPYRIDINE HCL"
            },
            "REESE PHARMACEUTICAL": {
              "URINARY PAIN RELIEF": "URINARY PAIN RELIEF"
            },
            "RITE AID CORPORATION": {
              "RA URINARY PAIN RELIEF": "RA URINARY PAIN RELIEF",
              "RA URINARY PAIN RELIEF MAX ST": "RA URINARY PAIN RELIEF MAX ST",
              "RA URINARY TRACT PAIN RELIEF": "RA URINARY TRACT PAIN RELIEF"
            },
            "RXCHANGE CO": {
              "PHENAZOPYRIDINE HCL": "PHENAZOPYRIDINE HCL"
            },
            "SELECT BRAND": {
              "SB URINARY PAIN RELIEF": "SB URINARY PAIN RELIEF",
              "SB URINARY PAIN RELIEF MAX ST": "SB URINARY PAIN RELIEF MAX ST"
            },
            "SHOPKO STORES OPERATING CO": {
              "URINARY PAIN RELIEF": "URINARY PAIN RELIEF"
            },
            "SOLA PHARMACEUTICALS": {
              "PHENAZOPYRIDINE HCL": "PHENAZOPYRIDINE HCL"
            },
            "SPECTRUM": {
              "PHENAZOPYRIDINE HCL": "PHENAZOPYRIDINE HCL"
            },
            "TOPCO": {
              "URINARY PAIN RELIEF": "URINARY PAIN RELIEF"
            },
            "UNIT DOSE SERVICES": {
              "PHENAZOPYRIDINE HCL": "PHENAZOPYRIDINE HCL"
            },
            "VILVET PHARMACEUTICAL": {
              "PHENAZOPYRIDINE HCL": "PHENAZOPYRIDINE HCL"
            },
            "WAL-MART": {
              "EQ URINARY PAIN RELIEF": "EQ URINARY PAIN RELIEF",
              "EQ URINARY PAIN RELIEF MAX ST": "EQ URINARY PAIN RELIEF MAX ST"
            },
            "WALGREENS": {
              "URINARY PAIN RELIEF": "URINARY PAIN RELIEF",
              "URINARY PAIN RELIEF MAX ST": "URINARY PAIN RELIEF MAX ST"
            },
            "WESTMINSTER PHARMACEUTICALS": {
              "PHENAZOPYRIDINE HCL": "PHENAZOPYRIDINE HCL"
            },
            "WINDER LABORATORIES": {
              "PHENAZOPYRIDINE HCL": "PHENAZOPYRIDINE HCL"
            }
          }
        },
        "Urinary Stone Agents": {
          "Urinary Stone Agents": {
            "MISSION": {
              "LITHOSTAT": "LITHOSTAT",
              "THIOLA EC": "THIOLA EC"
            },
            "RETROPHIN": {
              "THIOLA": "THIOLA"
            }
          }
        },
        "Vesicoureteral Reflux (VUR) Agents": {
          "Vesicoureteral Reflux (VUR) Agent Combinations": {
            "BAUSCH HEALTH": {
              "DEFLUX": "DEFLUX"
            },
            "PALETTE LIFE SCIENCES": {
              "DEFLUX": "DEFLUX"
            }
          }
        }
      },
      "GOUT AGENTS": {
        "Gout Agent Combinations": {
          "Gout Agent Combinations": {
            "ACTAVIS PHARMA": {
              "COLCHICINE-PROBENECID": "COLCHICINE-PROBENECID"
            },
            "AVKARE": {
              "COLCHICINE-PROBENECID": "COLCHICINE-PROBENECID"
            },
            "INGENUS PHARMACEUTICALS": {
              "COLCHICINE-PROBENECID": "COLCHICINE-PROBENECID"
            },
            "IRONWOOD PHARMACEUTICALS": {
              "DUZALLO": "DUZALLO"
            },
            "RISING PHARMACEUTICALS": {
              "COLCHICINE-PROBENECID": "COLCHICINE-PROBENECID"
            }
          }
        },
        "Gout Agents": {
          "Gout Agents": {
            "A-S MEDICATION SOLUTIONS": {
              "ALLOPURINOL": "ALLOPURINOL",
              "COLCHICINE": "COLCHICINE"
            },
            "ACCORD HEALTHCARE": {
              "ALLOPURINOL": "ALLOPURINOL"
            },
            "ACTAVIS PHARMA": {
              "ALLOPURINOL": "ALLOPURINOL"
            },
            "AIDAREX PHARMACEUTICALS": {
              "ALLOPURINOL": "ALLOPURINOL"
            },
            "ALEMBIC PHARMACEUTICALS": {
              "FEBUXOSTAT": "FEBUXOSTAT"
            },
            "ALTURA PHARMACEUTICALS": {
              "ALLOPURINOL": "ALLOPURINOL"
            },
            "AMERICAN HEALTH PACKAGING": {
              "ALLOPURINOL": "ALLOPURINOL",
              "COLCHICINE": "COLCHICINE"
            },
            "AMNEAL PHARMACEUTICALS": {
              "COLCHICINE": "COLCHICINE"
            },
            "APHENA PHARMA SOLUTIONS": {
              "ALLOPURINOL": "ALLOPURINOL",
              "COLCRYS": "COLCRYS",
              "FEBUXOSTAT": "FEBUXOSTAT",
              "ULORIC": "ULORIC"
            },
            "API SOLUTIONS": {
              "COLCHICINE": "COLCHICINE"
            },
            "ARISE PHARMACEUTICALS": {
              "ALLOPURINOL": "ALLOPURINOL"
            },
            "ASCEND LABORATORIES": {
              "COLCHICINE": "COLCHICINE"
            },
            "ASTRAZENECA": {
              "ZURAMPIC": "ZURAMPIC"
            },
            "AVION PHARMACEUTICALS": {
              "GLOPERBA": "GLOPERBA"
            },
            "AVPAK": {
              "ALLOPURINOL": "ALLOPURINOL"
            },
            "BRYANT RANCH PREPACK": {
              "ALLOPURINOL": "ALLOPURINOL"
            },
            "CASPER PHARMA": {
              "ZYLOPRIM": "ZYLOPRIM"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "ALLOPURINOL": "ALLOPURINOL"
            },
            "DENTON PHARMA": {
              "ALLOPURINOL": "ALLOPURINOL"
            },
            "DIRECT RX": {
              "ALLOPURINOL": "ALLOPURINOL"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "ALLOPURINOL": "ALLOPURINOL",
              "COLCHICINE": "COLCHICINE"
            },
            "FAGRON": {
              "COLCHICINE": "COLCHICINE"
            },
            "FREEDOM PHARMACEUTICALS": {
              "COLCHICINE": "COLCHICINE"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "ALLOPURINOL": "ALLOPURINOL",
              "COLCHICINE": "COLCHICINE",
              "FEBUXOSTAT": "FEBUXOSTAT"
            },
            "GRANULES PHARMACEUTICALS": {
              "COLCHICINE": "COLCHICINE"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "ALLOPURINOL": "ALLOPURINOL"
            },
            "HIKMA": {
              "ALLOPURINOL SODIUM": "ALLOPURINOL SODIUM",
              "COLCHICINE": "COLCHICINE",
              "FEBUXOSTAT": "FEBUXOSTAT"
            },
            "HIKMA AMERICAS": {
              "MITIGARE": "MITIGARE"
            },
            "HORIZON PHARMA": {
              "KRYSTEXXA": "KRYSTEXXA"
            },
            "HORIZON THERAPEUTICS USA": {
              "KRYSTEXXA": "KRYSTEXXA"
            },
            "INGENUS PHARMACEUTICALS": {
              "ALLOPURINOL": "ALLOPURINOL"
            },
            "IRONWOOD PHARMACEUTICALS": {
              "ZURAMPIC": "ZURAMPIC"
            },
            "LIBERTY PHARMACEUTICAL": {
              "ALLOPURINOL": "ALLOPURINOL"
            },
            "MAJOR PHARMACEUTICALS": {
              "ALLOPURINOL": "ALLOPURINOL",
              "COLCHICINE": "COLCHICINE"
            },
            "MEDVANTX": {
              "ALLOPURINOL": "ALLOPURINOL"
            },
            "MYLAN": {
              "ALLOPURINOL": "ALLOPURINOL",
              "COLCHICINE": "COLCHICINE",
              "FEBUXOSTAT": "FEBUXOSTAT"
            },
            "MYLAN INSTITUTIONAL": {
              "ALLOPURINOL": "ALLOPURINOL",
              "ALOPRIM": "ALOPRIM",
              "COLCHICINE": "COLCHICINE"
            },
            "NORTHSTAR RX": {
              "ALLOPURINOL": "ALLOPURINOL"
            },
            "NOVADOZ PHARMACEUTICALS": {
              "FEBUXOSTAT": "FEBUXOSTAT"
            },
            "NUCARE PHARMACEUTICALS": {
              "ALLOPURINOL": "ALLOPURINOL"
            },
            "PAR PHARMACEUTICAL": {
              "COLCHICINE": "COLCHICINE"
            },
            "PAR PHARMACEUTICALS": {
              "ALLOPURINOL": "ALLOPURINOL"
            },
            "PCCA": {
              "COLCHICINE": "COLCHICINE"
            },
            "PDRX PHARMACEUTICAL": {
              "ALLOPURINOL": "ALLOPURINOL"
            },
            "PHOENIX PHARMA": {
              "COLCHICINE": "COLCHICINE"
            },
            "PRASCO LABORATORIES": {
              "COLCHICINE": "COLCHICINE"
            },
            "PREFERRED PHARMACEUTICALS": {
              "ALLOPURINOL": "ALLOPURINOL"
            },
            "PROFICIENT RX": {
              "ALLOPURINOL": "ALLOPURINOL",
              "COLCHICINE": "COLCHICINE"
            },
            "QUALITY CARE": {
              "ALLOPURINOL": "ALLOPURINOL"
            },
            "QUALITY CARE PRODUCTS": {
              "ALLOPURINOL": "ALLOPURINOL"
            },
            "RXCHANGE CO": {
              "ALLOPURINOL": "ALLOPURINOL"
            },
            "SKY PACKAGING": {
              "ALLOPURINOL": "ALLOPURINOL"
            },
            "SPECTRUM": {
              "COLCHICINE": "COLCHICINE"
            },
            "ST MARY'S MPP": {
              "ALLOPURINOL": "ALLOPURINOL"
            },
            "SUN PHARMACEUTICALS": {
              "ALLOPURINOL": "ALLOPURINOL",
              "FEBUXOSTAT": "FEBUXOSTAT"
            },
            "TAKEDA PHARMACEUTICALS": {
              "COLCRYS": "COLCRYS",
              "ULORIC": "ULORIC"
            },
            "TRIS PHARMA": {
              "FEBUXOSTAT": "FEBUXOSTAT"
            },
            "UNICHEM PHARMACEUTICALS": {
              "ALLOPURINOL": "ALLOPURINOL"
            },
            "UNIT DOSE SERVICES": {
              "ALLOPURINOL": "ALLOPURINOL"
            },
            "VA CMOP DALLAS": {
              "ALLOPURINOL": "ALLOPURINOL"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "ALLOPURINOL": "ALLOPURINOL",
              "COLCHICINE": "COLCHICINE"
            }
          }
        },
        "Uricosurics": {
          "Uricosurics": {
            "ACTAVIS PHARMA": {
              "PROBENECID": "PROBENECID"
            },
            "AIDAREX PHARMACEUTICALS": {
              "PROBENECID": "PROBENECID"
            },
            "AMERICAN HEALTH PACKAGING": {
              "PROBENECID": "PROBENECID"
            },
            "APHENA PHARMA SOLUTIONS": {
              "PROBENECID": "PROBENECID"
            },
            "LANNETT": {
              "PROBENECID": "PROBENECID"
            },
            "MARLEX PHARMACEUTICALS": {
              "PROBENECID": "PROBENECID"
            },
            "MYLAN": {
              "PROBENECID": "PROBENECID"
            },
            "PDRX PHARMACEUTICAL": {
              "PROBENECID": "PROBENECID"
            }
          }
        }
      },
      "HEMATOLOGICAL AGENTS - MISC.": {
        "Aminolevulinate Synthase 1-Directed siRNA": {
          "Aminolevulinate Synthase 1-Directed siRNA": {
            "ALNYLAM PHARMACEUTICALS": {
              "GIVLAARI": "GIVLAARI"
            }
          }
        },
        "Antihemophilic Products": {
          "Antihemophilic Products": {
            "APTEVO BIOTHERAPEUTICS": {
              "IXINITY": "IXINITY"
            },
            "BAXALTA": {
              "ADVATE": "ADVATE",
              "BEBULIN": "BEBULIN",
              "FEIBA": "FEIBA",
              "FEIBA NF": "FEIBA NF",
              "HEMOFIL M": "HEMOFIL M",
              "OBIZUR": "OBIZUR",
              "RECOMBINATE": "RECOMBINATE",
              "RIXUBIS": "RIXUBIS",
              "VONVENDI": "VONVENDI"
            },
            "BAYER PHARMACEUTICAL": {
              "JIVI": "JIVI",
              "KOGENATE FS": "KOGENATE FS",
              "KOGENATE FS BIO-SET": "KOGENATE FS BIO-SET",
              "KOVALTRY": "KOVALTRY"
            },
            "BIO PRODUCTS LABORATORY": {
              "COAGADEX": "COAGADEX"
            },
            "BIOGEN IDEC": {
              "ELOCTATE": "ELOCTATE"
            },
            "BIOVERATIV THERAPEUTICS": {
              "ALPROLIX": "ALPROLIX",
              "ELOCTATE": "ELOCTATE"
            },
            "CSL BEHRING": {
              "AFSTYLA": "AFSTYLA",
              "CORIFACT": "CORIFACT",
              "HELIXATE FS": "HELIXATE FS",
              "HUMATE-P": "HUMATE-P",
              "IDELVION": "IDELVION",
              "KCENTRA": "KCENTRA",
              "MONOCLATE-P": "MONOCLATE-P",
              "MONONINE": "MONONINE",
              "RIASTAP": "RIASTAP"
            },
            "EMERGENT BIOSOLUTIONS": {
              "IXINITY": "IXINITY"
            },
            "GRIFOLS USA": {
              "ALPHANATE/VWF COMPLEX/HUMAN": "ALPHANATE/VWF COMPLEX/HUMAN",
              "ALPHANINE SD": "ALPHANINE SD",
              "PROFILNINE": "PROFILNINE",
              "PROFILNINE SD": "PROFILNINE SD"
            },
            "HEMA BIOLOGICS": {
              "SEVENFACT": "SEVENFACT"
            },
            "KEDRION BIOPHARMA": {
              "KOATE": "KOATE",
              "KOATE-DVI": "KOATE-DVI"
            },
            "NOVO NORDISK": {
              "ESPEROCT": "ESPEROCT",
              "NOVOEIGHT": "NOVOEIGHT",
              "NOVOSEVEN RT": "NOVOSEVEN RT",
              "REBINYN": "REBINYN",
              "TRETTEN": "TRETTEN"
            },
            "OCTAPHARMA USA": {
              "FIBRYGA": "FIBRYGA",
              "NUWIQ": "NUWIQ",
              "WILATE": "WILATE"
            },
            "PFIZER U.S.": {
              "BENEFIX": "BENEFIX",
              "XYNTHA": "XYNTHA",
              "XYNTHA SOLOFUSE": "XYNTHA SOLOFUSE"
            },
            "SHIRE US INC.": {
              "ADYNOVATE": "ADYNOVATE"
            }
          },
          "Antihemophilic Products - Monoclonal Antibodies": {
            "GENENTECH": {
              "HEMLIBRA": "HEMLIBRA"
            }
          }
        },
        "Bradykinin B2 Receptor Antagonists": {
          "Bradykinin B2 Receptor Antagonists": {
            "CIPLA USA": {
              "ICATIBANT ACETATE": "ICATIBANT ACETATE"
            },
            "FRESENIUS KABI USA": {
              "ICATIBANT ACETATE": "ICATIBANT ACETATE"
            },
            "LEUCADIA PHARMACEUTICALS": {
              "ICATIBANT ACETATE": "ICATIBANT ACETATE"
            },
            "SHIRE US INC.": {
              "FIRAZYR": "FIRAZYR",
              "ICATIBANT ACETATE": "ICATIBANT ACETATE"
            },
            "TEVA PHARMACEUTICALS USA": {
              "ICATIBANT ACETATE": "ICATIBANT ACETATE"
            }
          }
        },
        "Complement Inhibitors": {
          "C1 Inhibitors": {
            "CSL BEHRING": {
              "BERINERT": "BERINERT",
              "HAEGARDA": "HAEGARDA"
            },
            "PHARMING HEALTHCARE": {
              "RUCONEST": "RUCONEST"
            },
            "VIROPHARMA ,INC": {
              "CINRYZE": "CINRYZE"
            }
          },
          "Complement Inhibitors": {
            "ALEXION PHARMACEUTICALS": {
              "SOLIRIS": "SOLIRIS",
              "ULTOMIRIS": "ULTOMIRIS"
            }
          }
        },
        "Hemataologic - Tyrosine Kinase Inhibitors": {
          "Spleen Tyrosine Kinase (SYK) Inhibitors": {
            "RIGEL PHARMACEUTICALS": {
              "TAVALISSE": "TAVALISSE"
            }
          }
        },
        "Hematorheologic Agents": {
          "Hematorheologic Agents": {
            "A-S MEDICATION SOLUTIONS": {
              "PENTOXIFYLLINE ER": "PENTOXIFYLLINE ER"
            },
            "AIDAREX PHARMACEUTICALS": {
              "PENTOXIFYLLINE ER": "PENTOXIFYLLINE ER"
            },
            "APHENA PHARMA SOLUTIONS": {
              "PENTOXIFYLLINE ER": "PENTOXIFYLLINE ER"
            },
            "APOTEX": {
              "PENTOXIFYLLINE ER": "PENTOXIFYLLINE ER"
            },
            "AVKARE": {
              "PENTOXIFYLLINE ER": "PENTOXIFYLLINE ER"
            },
            "BRYANT RANCH PREPACK": {
              "PENTOXIFYLLINE ER": "PENTOXIFYLLINE ER"
            },
            "DIRECT RX": {
              "PENTOXIFYLLINE ER": "PENTOXIFYLLINE ER"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "PENTOXIFYLLINE ER": "PENTOXIFYLLINE ER"
            },
            "MAJOR PHARMACEUTICALS": {
              "PENTOXIFYLLINE ER": "PENTOXIFYLLINE ER"
            },
            "MYLAN": {
              "PENTOXIFYLLINE ER": "PENTOXIFYLLINE ER"
            },
            "MYLAN INSTITUTIONAL": {
              "PENTOXIFYLLINE ER": "PENTOXIFYLLINE ER"
            },
            "NUCARE PHARMACEUTICALS": {
              "PENTOXIFYLLINE ER": "PENTOXIFYLLINE ER"
            },
            "OCEANSIDE PHARMACEUTICALS": {
              "PENTOXIFYLLINE ER": "PENTOXIFYLLINE ER"
            },
            "PDRX PHARMACEUTICAL": {
              "PENTOXIFYLLINE ER": "PENTOXIFYLLINE ER"
            },
            "QUALITY CARE": {
              "PENTOXIFYLLINE ER": "PENTOXIFYLLINE ER"
            },
            "QUALITY CARE PRODUCTS": {
              "PENTOXIFYLLINE ER": "PENTOXIFYLLINE ER"
            },
            "RXCHANGE CO": {
              "PENTOXIFYLLINE ER": "PENTOXIFYLLINE ER"
            },
            "TEVA PHARMACEUTICALS USA": {
              "PENTOXIFYLLINE ER": "PENTOXIFYLLINE ER"
            },
            "VA CMOP DALLAS": {
              "PENTOXIFYLLINE ER": "PENTOXIFYLLINE ER"
            },
            "VANGARD": {
              "PENTOXIFYLLINE ER": "PENTOXIFYLLINE ER"
            }
          }
        },
        "Hemin": {
          "Hemin": {
            "RECORDATI RARE DISEASES": {
              "PANHEMATIN": "PANHEMATIN"
            }
          }
        },
        "Human Protein C": {
          "Human Protein C": {
            "BAXALTA": {
              "CEPROTIN": "CEPROTIN"
            }
          }
        },
        "Plasma Expanders": {
          "Plasma Expanders": {
            "B. BRAUN MEDICAL": {
              "HESPAN": "HESPAN"
            },
            "HOSPIRA": {
              "HETASTARCH-NACL": "HETASTARCH-NACL",
              "HEXTEND": "HEXTEND",
              "LMD IN D5W": "LMD IN D5W",
              "LMD IN NACL": "LMD IN NACL",
              "VOLUVEN": "VOLUVEN"
            },
            "NOVAPLUS/HOSPIRA": {
              "HETASTARCH-NACL": "HETASTARCH-NACL"
            }
          }
        },
        "Plasma Kallikrein Inhibitors": {
          "Plasma Kallikrein Inhibitors": {
            "SHIRE US INC.": {
              "KALBITOR": "KALBITOR"
            }
          },
          "Plasma Kallikrein Inhibitors - Monoclonal Antibodies": {
            "SHIRE US INC.": {
              "TAKHZYRO": "TAKHZYRO"
            }
          }
        },
        "Plasma Proteins": {
          "Plasma Proteins": {
            "ALPINE BIOLOGICS": {
              "ALBUMIN-ZLB": "ALBUMIN-ZLB"
            },
            "BAXALTA": {
              "BUMINATE": "BUMINATE",
              "FLEXBUMIN": "FLEXBUMIN"
            },
            "BIO PRODUCTS LABORATORY": {
              "ALBUMINEX": "ALBUMINEX"
            },
            "CSL BEHRING": {
              "ALBUMIN HUMAN": "ALBUMIN HUMAN",
              "ALBUMINAR-25": "ALBUMINAR-25",
              "ALBUMINAR-5": "ALBUMINAR-5",
              "ALBURX": "ALBURX"
            },
            "GRIFOLS USA": {
              "ALBUTEIN": "ALBUTEIN",
              "HUMAN ALBUMIN GRIFOLS": "HUMAN ALBUMIN GRIFOLS",
              "PLASBUMIN-25": "PLASBUMIN-25",
              "PLASBUMIN-5": "PLASBUMIN-5",
              "PLASMANATE": "PLASMANATE",
              "THROMBATE III": "THROMBATE III"
            },
            "KEDRION BIOPHARMA": {
              "ALBUKED 25": "ALBUKED 25",
              "ALBUKED 5": "ALBUKED 5",
              "KEDBUMIN": "KEDBUMIN"
            },
            "OCTAPHARMA USA": {
              "ALBUMIN HUMAN": "ALBUMIN HUMAN",
              "OCTAPLAS BLOOD GROUP A": "OCTAPLAS BLOOD GROUP A",
              "OCTAPLAS BLOOD GROUP AB": "OCTAPLAS BLOOD GROUP AB",
              "OCTAPLAS BLOOD GROUP B": "OCTAPLAS BLOOD GROUP B",
              "OCTAPLAS BLOOD GROUP O": "OCTAPLAS BLOOD GROUP O"
            }
          }
        },
        "Platelet Aggregation Inhibitors": {
          "Anti-von Willebrand Factor Agents": {
            "GENZYME": {
              "CABLIVI": "CABLIVI"
            }
          },
          "Direct-Acting P2Y12 Inhibitors": {
            "ASTRAZENECA LP": {
              "BRILINTA": "BRILINTA"
            },
            "CHIESI USA": {
              "KENGREAL": "KENGREAL"
            }
          },
          "Glycoprotein IIb/IIIa Receptor Inhibitors": {
            "ACCORD HEALTHCARE": {
              "EPTIFIBATIDE": "EPTIFIBATIDE"
            },
            "AKORN": {
              "EPTIFIBATIDE": "EPTIFIBATIDE"
            },
            "AMNEAL BIOSCIENCES": {
              "EPTIFIBATIDE": "EPTIFIBATIDE"
            },
            "AUROMEDICS PHARMA": {
              "EPTIFIBATIDE": "EPTIFIBATIDE"
            },
            "BAXTER HEALTHCARE CORP": {
              "EPTIFIBATIDE": "EPTIFIBATIDE"
            },
            "JANSSEN BIOTECH": {
              "REOPRO": "REOPRO"
            },
            "LILLY": {
              "REOPRO": "REOPRO"
            },
            "MEDICURE": {
              "AGGRASTAT": "AGGRASTAT"
            },
            "MERCK SHARP & DOHME": {
              "INTEGRILIN": "INTEGRILIN"
            },
            "MYLAN INSTITUTIONAL": {
              "EPTIFIBATIDE": "EPTIFIBATIDE"
            },
            "NOVAPLUS/MERCK SHARP & DOHME": {
              "INTEGRILIN": "INTEGRILIN"
            },
            "SAGENT PHARMACEUTICAL": {
              "EPTIFIBATIDE": "EPTIFIBATIDE"
            },
            "SLATE RUN PHARMACEUTICALS": {
              "EPTIFIBATIDE": "EPTIFIBATIDE"
            },
            "TEVA PARENTERAL MEDICINES": {
              "EPTIFIBATIDE": "EPTIFIBATIDE"
            }
          },
          "Phosphodiesterase III Inhibitors": {
            "AIDAREX PHARMACEUTICALS": {
              "CILOSTAZOL": "CILOSTAZOL"
            },
            "AMERICAN HEALTH PACKAGING": {
              "CILOSTAZOL": "CILOSTAZOL"
            },
            "APHENA PHARMA SOLUTIONS": {
              "CILOSTAZOL": "CILOSTAZOL"
            },
            "APOTEX": {
              "CILOSTAZOL": "CILOSTAZOL"
            },
            "AVPAK": {
              "CILOSTAZOL": "CILOSTAZOL"
            },
            "DIRECT RX": {
              "CILOSTAZOL": "CILOSTAZOL"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "CILOSTAZOL": "CILOSTAZOL"
            },
            "HIKMA": {
              "CILOSTAZOL": "CILOSTAZOL"
            },
            "LIBERTY PHARMACEUTICAL": {
              "CILOSTAZOL": "CILOSTAZOL"
            },
            "NUCARE PHARMACEUTICALS": {
              "CILOSTAZOL": "CILOSTAZOL"
            },
            "OTSUKA AMERICA": {
              "PLETAL": "PLETAL"
            },
            "SANDOZ": {
              "CILOSTAZOL": "CILOSTAZOL"
            },
            "SLATE RUN PHARMACEUTICALS": {
              "CILOSTAZOL": "CILOSTAZOL"
            },
            "TEVA PHARMACEUTICALS USA": {
              "CILOSTAZOL": "CILOSTAZOL"
            }
          },
          "Platelet Aggregation Inhibitor Combinations": {
            "AMERICAN HEALTH PACKAGING": {
              "ASPIRIN-DIPYRIDAMOLE ER": "ASPIRIN-DIPYRIDAMOLE ER"
            },
            "AMNEAL PHARMACEUTICALS": {
              "ASPIRIN-DIPYRIDAMOLE ER": "ASPIRIN-DIPYRIDAMOLE ER"
            },
            "ANI  PHARMACEUTICALS": {
              "ASPIRIN-DIPYRIDAMOLE ER": "ASPIRIN-DIPYRIDAMOLE ER"
            },
            "ARALEZ PHARMACEUTICALS": {
              "YOSPRALA": "YOSPRALA"
            },
            "AVKARE": {
              "ASPIRIN-DIPYRIDAMOLE ER": "ASPIRIN-DIPYRIDAMOLE ER"
            },
            "BIOGEN PHARMACEUTICALS": {
              "AGGRENOX": "AGGRENOX"
            },
            "BOEHRINGER INGELHEIM": {
              "AGGRENOX": "AGGRENOX"
            },
            "CAMBRIDGE THERAPEUTICS TECHN": {
              "CLOPIDOGREL & ASPIRIN": "CLOPIDOGREL & ASPIRIN"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "ASPIRIN-DIPYRIDAMOLE ER": "ASPIRIN-DIPYRIDAMOLE ER"
            },
            "GENUS LIFESCIENCES": {
              "YOSPRALA": "YOSPRALA"
            },
            "GLENMARK PHARMACEUTICALS": {
              "ASPIRIN-DIPYRIDAMOLE ER": "ASPIRIN-DIPYRIDAMOLE ER"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "ASPIRIN-DIPYRIDAMOLE ER": "ASPIRIN-DIPYRIDAMOLE ER"
            },
            "HIKMA": {
              "ASPIRIN-DIPYRIDAMOLE ER": "ASPIRIN-DIPYRIDAMOLE ER"
            },
            "INNOVIDA PHARMACEUTIQUE": {
              "ASPIRIN-OMEPRAZOLE": "ASPIRIN-OMEPRAZOLE",
              "YOSPRALA": "YOSPRALA"
            },
            "INNOVIDA THERAPEUTIQUE": {
              "ASPIRIN-OMEPRAZOLE": "ASPIRIN-OMEPRAZOLE"
            },
            "KREMERS URBAN": {
              "ASPIRIN-DIPYRIDAMOLE ER": "ASPIRIN-DIPYRIDAMOLE ER"
            },
            "NORTHSTAR RX": {
              "ASPIRIN-DIPYRIDAMOLE ER": "ASPIRIN-DIPYRIDAMOLE ER"
            },
            "PAR PHARMACEUTICAL": {
              "ASPIRIN-DIPYRIDAMOLE ER": "ASPIRIN-DIPYRIDAMOLE ER"
            },
            "PHARMACEUTIKA": {
              "YOSPRALA": "YOSPRALA"
            },
            "QUALITY CARE": {
              "AGGRENOX": "AGGRENOX"
            },
            "SLATE RUN PHARMACEUTICALS": {
              "ASPIRIN-DIPYRIDAMOLE ER": "ASPIRIN-DIPYRIDAMOLE ER"
            },
            "TEVA PHARMACEUTICALS USA": {
              "ASPIRIN-DIPYRIDAMOLE ER": "ASPIRIN-DIPYRIDAMOLE ER"
            }
          },
          "Platelet Aggregation Inhibitors": {
            "A-S MEDICATION SOLUTIONS": {
              "DIPYRIDAMOLE": "DIPYRIDAMOLE"
            },
            "AVKARE": {
              "DIPYRIDAMOLE": "DIPYRIDAMOLE"
            },
            "AVPAK": {
              "DIPYRIDAMOLE": "DIPYRIDAMOLE"
            },
            "BOEHRINGER INGELHEIM": {
              "PERSANTINE": "PERSANTINE"
            },
            "HIKMA": {
              "DIPYRIDAMOLE": "DIPYRIDAMOLE"
            },
            "IMPAX GENERICS": {
              "DIPYRIDAMOLE": "DIPYRIDAMOLE"
            },
            "LANNETT": {
              "DIPYRIDAMOLE": "DIPYRIDAMOLE"
            },
            "MARLEX PHARMACEUTICALS": {
              "DIPYRIDAMOLE": "DIPYRIDAMOLE"
            },
            "NEW HAVEN PHARMACEUTICALS": {
              "DURLAZA": "DURLAZA"
            },
            "PDRX PHARMACEUTICAL": {
              "DIPYRIDAMOLE": "DIPYRIDAMOLE"
            },
            "RISING PHARMACEUTICALS": {
              "DIPYRIDAMOLE": "DIPYRIDAMOLE"
            },
            "SPECGX": {
              "DIPYRIDAMOLE": "DIPYRIDAMOLE"
            },
            "TEVA PHARMACEUTICALS USA": {
              "DIPYRIDAMOLE": "DIPYRIDAMOLE"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "DIPYRIDAMOLE": "DIPYRIDAMOLE"
            }
          },
          "Protease-Activated Receptor-1 (PAR-1) Antagonists": {
            "MERCK SHARP & DOHME": {
              "ZONTIVITY": "ZONTIVITY"
            },
            "XSPIRE PHARMA": {
              "ZONTIVITY": "ZONTIVITY"
            }
          },
          "Quinazoline Agents": {
            "AMNEAL PHARMACEUTICALS": {
              "ANAGRELIDE HCL": "ANAGRELIDE HCL"
            },
            "SHIRE US INC.": {
              "AGRYLIN": "AGRYLIN"
            },
            "TEVA PHARMACEUTICALS USA": {
              "ANAGRELIDE HCL": "ANAGRELIDE HCL"
            },
            "TORRENT PHARMACEUTICALS": {
              "ANAGRELIDE HCL": "ANAGRELIDE HCL"
            }
          },
          "Thienopyridine Derivatives": {
            "A-S MEDICATION SOLUTIONS": {
              "CLOPIDOGREL BISULFATE": "CLOPIDOGREL BISULFATE",
              "EFFIENT": "EFFIENT",
              "PLAVIX": "PLAVIX"
            },
            "ACCORD HEALTHCARE": {
              "CLOPIDOGREL BISULFATE": "CLOPIDOGREL BISULFATE",
              "PRASUGREL HCL": "PRASUGREL HCL"
            },
            "AIDAREX PHARMACEUTICALS": {
              "CLOPIDOGREL BISULFATE": "CLOPIDOGREL BISULFATE"
            },
            "AKRON PHARMA": {
              "CLOPIDOGREL BISULFATE": "CLOPIDOGREL BISULFATE"
            },
            "AMERICAN HEALTH PACKAGING": {
              "CLOPIDOGREL BISULFATE": "CLOPIDOGREL BISULFATE"
            },
            "AMNEAL PHARMACEUTICALS": {
              "PRASUGREL HCL": "PRASUGREL HCL"
            },
            "APHENA PHARMA SOLUTIONS": {
              "CLOPIDOGREL BISULFATE": "CLOPIDOGREL BISULFATE"
            },
            "APOTEX": {
              "CLOPIDOGREL BISULFATE": "CLOPIDOGREL BISULFATE",
              "PRASUGREL HCL": "PRASUGREL HCL"
            },
            "AQ PHARMACEUTICALS": {
              "PLAVIX": "PLAVIX"
            },
            "ASCEND LABORATORIES": {
              "PRASUGREL HCL": "PRASUGREL HCL"
            },
            "AUROBINDO PHARMA": {
              "CLOPIDOGREL BISULFATE": "CLOPIDOGREL BISULFATE",
              "PRASUGREL HCL": "PRASUGREL HCL"
            },
            "AVPAK": {
              "CLOPIDOGREL BISULFATE": "CLOPIDOGREL BISULFATE"
            },
            "B-M SQUIBB U.S. (PRIMARY CARE)": {
              "PLAVIX": "PLAVIX"
            },
            "BIOGEN PHARMACEUTICALS": {
              "PLAVIX": "PLAVIX"
            },
            "BLENHEIM PHARMACAL": {
              "CLOPIDOGREL BISULFATE": "CLOPIDOGREL BISULFATE"
            },
            "BRYANT RANCH PREPACK": {
              "CLOPIDOGREL BISULFATE": "CLOPIDOGREL BISULFATE",
              "PLAVIX": "PLAVIX"
            },
            "CAMBER PHARMACEUTICALS": {
              "CLOPIDOGREL BISULFATE": "CLOPIDOGREL BISULFATE"
            },
            "CAMBRIDGE THERAPEUTICS TECHN": {
              "CLOPIDOGREL BISULFATE": "CLOPIDOGREL BISULFATE"
            },
            "CARDINAL HEALTH REPACKAGING SO": {
              "CLOPIDOGREL BISULFATE": "CLOPIDOGREL BISULFATE"
            },
            "DIRECT RX": {
              "CLOPIDOGREL BISULFATE": "CLOPIDOGREL BISULFATE"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "CLOPIDOGREL BISULFATE": "CLOPIDOGREL BISULFATE"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "CLOPIDOGREL BISULFATE": "CLOPIDOGREL BISULFATE",
              "PRASUGREL HCL": "PRASUGREL HCL"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "PLAVIX": "PLAVIX"
            },
            "INTERNATIONAL LABS, LLC": {
              "CLOPIDOGREL BISULFATE": "CLOPIDOGREL BISULFATE"
            },
            "LEGACY PHARMACEUTICAL PKG": {
              "CLOPIDOGREL BISULFATE": "CLOPIDOGREL BISULFATE"
            },
            "LIBERTY PHARMACEUTICAL": {
              "PRASUGREL HCL": "PRASUGREL HCL"
            },
            "LILLY": {
              "EFFIENT": "EFFIENT"
            },
            "MACLEODS PHARMACEUTICALS": {
              "CLOPIDOGREL BISULFATE": "CLOPIDOGREL BISULFATE"
            },
            "MAJOR PHARMACEUTICALS": {
              "CLOPIDOGREL BISULFATE": "CLOPIDOGREL BISULFATE"
            },
            "MEGALITH PHARMACEUTICALS": {
              "CLOPIDOGREL BISULFATE": "CLOPIDOGREL BISULFATE"
            },
            "MYLAN": {
              "CLOPIDOGREL BISULFATE": "CLOPIDOGREL BISULFATE",
              "PRASUGREL HCL": "PRASUGREL HCL"
            },
            "MYLAN INSTITUTIONAL": {
              "CLOPIDOGREL BISULFATE": "CLOPIDOGREL BISULFATE"
            },
            "NUCARE PHARMACEUTICALS": {
              "CLOPIDOGREL BISULFATE": "CLOPIDOGREL BISULFATE",
              "PLAVIX": "PLAVIX"
            },
            "PDRX PHARMACEUTICAL": {
              "CLOPIDOGREL BISULFATE": "CLOPIDOGREL BISULFATE",
              "PLAVIX": "PLAVIX"
            },
            "PRASCO LABORATORIES": {
              "PRASUGREL HCL": "PRASUGREL HCL"
            },
            "PREFERRED PHARMACEUTICALS": {
              "CLOPIDOGREL BISULFATE": "CLOPIDOGREL BISULFATE"
            },
            "PROFICIENT RX": {
              "CLOPIDOGREL BISULFATE": "CLOPIDOGREL BISULFATE"
            },
            "QUALITY CARE PRODUCTS": {
              "CLOPIDOGREL BISULFATE": "CLOPIDOGREL BISULFATE"
            },
            "SANOFI PHARMACEUTICALS": {
              "PLAVIX": "PLAVIX"
            },
            "SCIEGEN PHARMACEUTICALS": {
              "CLOPIDOGREL BISULFATE": "CLOPIDOGREL BISULFATE"
            },
            "SUN PHARMACEUTICALS": {
              "CLOPIDOGREL BISULFATE": "CLOPIDOGREL BISULFATE"
            },
            "TEVA PHARMACEUTICALS USA": {
              "CLOPIDOGREL BISULFATE": "CLOPIDOGREL BISULFATE"
            },
            "TORRENT PHARMACEUTICALS": {
              "CLOPIDOGREL BISULFATE": "CLOPIDOGREL BISULFATE"
            },
            "VENSUN PHARMACEUTICALS": {
              "CLOPIDOGREL BISULFATE": "CLOPIDOGREL BISULFATE"
            },
            "WESTMINSTER PHARMACEUTICALS": {
              "CLOPIDOGREL BISULFATE": "CLOPIDOGREL BISULFATE"
            },
            "YILING PHARMACEUTICAL": {
              "CLOPIDOGREL BISULFATE": "CLOPIDOGREL BISULFATE"
            }
          }
        },
        "Protamine": {
          "Protamine": {
            "FRESENIUS KABI USA": {
              "PROTAMINE SULFATE": "PROTAMINE SULFATE"
            },
            "NOVAPLUS/FRESENIUS KABI": {
              "PROTAMINE SULFATE": "PROTAMINE SULFATE"
            }
          }
        },
        "Thrombolytic Agent - Misc": {
          "Thrombolytic Agent - Misc": {
            "JAZZ PHARMACEUTICALS": {
              "DEFITELIO": "DEFITELIO"
            }
          }
        },
        "Thrombolytic Enzymes": {
          "Tissue Plasminogen Activators": {
            "CHIESI USA": {
              "RETAVASE": "RETAVASE",
              "RETAVASE HALF-KIT": "RETAVASE HALF-KIT"
            },
            "GENENTECH": {
              "ACTIVASE": "ACTIVASE",
              "CATHFLO ACTIVASE": "CATHFLO ACTIVASE",
              "TNKASE": "TNKASE"
            }
          }
        }
      },
      "HEMATOPOIETIC AGENTS": {
        "Agents for Gaucher Disease": {
          "Agents for Gaucher Disease": {
            "ACTELION PHARMACEUTICALS": {
              "ZAVESCA": "ZAVESCA"
            },
            "AMERIGEN PHARMACEUTICALS": {
              "MIGLUSTAT": "MIGLUSTAT"
            },
            "EDENBRIDGE PHARMACEUTICALS": {
              "MIGLUSTAT": "MIGLUSTAT"
            },
            "GENZYME": {
              "CERDELGA": "CERDELGA",
              "CEREZYME": "CEREZYME"
            },
            "PATRIOT PHARMACEUTICALS LLC": {
              "MIGLUSTAT": "MIGLUSTAT"
            },
            "PFIZER U.S.": {
              "ELELYSO": "ELELYSO"
            },
            "SHIRE US INC.": {
              "VPRIV": "VPRIV"
            }
          }
        },
        "Agents for Sickle Cell Disease": {
          "Amino Acids": {
            "EMMAUS MEDICAL": {
              "ENDARI": "ENDARI"
            }
          },
          "Cytotoxic Agents": {
            "B-M SQUIBB ONCOLOGY/IMMUNOLOGY": {
              "DROXIA": "DROXIA"
            },
            "MEDUNIK USA": {
              "SIKLOS": "SIKLOS"
            }
          },
          "Hemoglobin S (HbS) Polymerization Inhibitors": {
            "GLOBAL BLOOD THERAPEUTICS": {
              "OXBRYTA": "OXBRYTA"
            }
          },
          "Selectin Blockers": {
            "NOVARTIS": {
              "ADAKVEO": "ADAKVEO"
            }
          }
        },
        "Cobalamins": {
          "Cobalamins": {
            "21ST CENTURY HEALTHCARE": {
              "B-12": "B-12",
              "VITAMIN B-12": "VITAMIN B-12"
            },
            "A-S MEDICATION SOLUTIONS": {
              "CYANOCOBALAMIN": "CYANOCOBALAMIN",
              "VITAMIN B-12": "VITAMIN B-12"
            },
            "ACTAVIS PHARMA": {
              "HYDROXOCOBALAMIN ACETATE": "HYDROXOCOBALAMIN ACETATE"
            },
            "AIDAREX PHARMACEUTICALS": {
              "HYDROXOCOBALAMIN ACETATE": "HYDROXOCOBALAMIN ACETATE",
              "VITAMIN B-12": "VITAMIN B-12"
            },
            "AMERICAN REGENT": {
              "CYANOCOBALAMIN": "CYANOCOBALAMIN"
            },
            "APHENA PHARMA SOLUTIONS": {
              "VITAMIN B-12": "VITAMIN B-12"
            },
            "AVPAK": {
              "VITAMIN B-12": "VITAMIN B-12",
              "VITAMIN B12": "VITAMIN B12"
            },
            "BASIC DRUGS": {
              "VITAMIN B-12": "VITAMIN B-12",
              "VITAMIN B-12 ER": "VITAMIN B-12 ER"
            },
            "BERGEN BRUNSWIG": {
              "GNP B-12": "GNP B-12",
              "GNP VITAMIN B-12": "GNP VITAMIN B-12",
              "GNP VITAMIN B-12 TR": "GNP VITAMIN B-12 TR"
            },
            "BRYANT RANCH PREPACK": {
              "VITAMIN B-12": "VITAMIN B-12"
            },
            "CARDINAL HEALTH REPACKAGING SO": {
              "VITAMIN B-12": "VITAMIN B-12"
            },
            "CHAIN DRUG CONSORTIUM": {
              "B-12": "B-12",
              "VITAMIN B-12": "VITAMIN B-12"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC VITAMIN B12": "QC VITAMIN B12"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS B-12": "CVS B-12",
              "CVS B12": "CVS B12",
              "CVS B12 GUMMIES": "CVS B12 GUMMIES",
              "CVS B12 QUICK DISSOLVE": "CVS B12 QUICK DISSOLVE",
              "CVS VITAMIN B-12": "CVS VITAMIN B-12",
              "CVS VITAMIN B12": "CVS VITAMIN B12"
            },
            "EMPOWER PHARMACY": {
              "CYANOCOBALAMIN": "CYANOCOBALAMIN",
              "METHYLCOBALAMIN": "METHYLCOBALAMIN"
            },
            "ENDO PHARMACEUTICALS": {
              "NASCOBAL": "NASCOBAL"
            },
            "ENOVACHEM MANUFACTURING": {
              "PHYSICIANS EZ USE B-12": "PHYSICIANS EZ USE B-12"
            },
            "EQUALINE": {
              "EQL B-12": "EQL B-12",
              "EQL VITAMIN B-12": "EQL VITAMIN B-12",
              "EQL VITAMIN B-12 TR": "EQL VITAMIN B-12 TR"
            },
            "FREEDA VITAMINS": {
              "VITAMIN B 12": "VITAMIN B 12",
              "VITAMIN B-12": "VITAMIN B-12"
            },
            "FRESENIUS KABI USA": {
              "CYANOCOBALAMIN": "CYANOCOBALAMIN"
            },
            "GENDOSE PHARMACEUTICALS": {
              "VITAMIN B-12": "VITAMIN B-12"
            },
            "GERI-CARE": {
              "VITAMIN B-12": "VITAMIN B-12"
            },
            "HIKMA": {
              "CYANOCOBALAMIN": "CYANOCOBALAMIN"
            },
            "IMPRIMIS NJOF": {
              "METHYLCOBALAMIN": "METHYLCOBALAMIN"
            },
            "INTEGRATIVE THERAPEUTICS": {
              "B12-ACTIVE": "B12-ACTIVE"
            },
            "INTERNATIONAL VITAMIN CORP": {
              "FAST ACTING B12": "FAST ACTING B12"
            },
            "IT3 MEDICAL": {
              "VITAMIN DEFICIENCY SYSTEM-B12": "VITAMIN DEFICIENCY SYSTEM-B12"
            },
            "J R CARLSON LABORATORIES": {
              "B-12-SL": "B-12-SL"
            },
            "JARROW FORMULAS": {
              "METHYL B-12": "METHYL B-12"
            },
            "JJ BALAN": {
              "VITAMIN B-12": "VITAMIN B-12"
            },
            "KAISER FOUNDATION HOSPITAL": {
              "KP VITAMIN B-12": "KP VITAMIN B-12"
            },
            "LEADER BRAND PRODUCTS": {
              "B-12": "B-12",
              "VITAMIN B 12": "VITAMIN B 12",
              "VITAMIN B-12": "VITAMIN B-12",
              "VITAMIN B-12 ER": "VITAMIN B-12 ER",
              "VITAMIN B12 TR": "VITAMIN B12 TR"
            },
            "MAGNO-HUMPHRIES LABORATORIES": {
              "VITAMIN B-12": "VITAMIN B-12",
              "VITAMIN B-12 ER": "VITAMIN B-12 ER"
            },
            "MAJOR PHARMACEUTICALS": {
              "VITAMIN B-12": "VITAMIN B-12",
              "VITAMIN B12": "VITAMIN B12"
            },
            "MARLEX PHARMACEUTICALS": {
              "B-12": "B-12"
            },
            "MASON VITAMINS": {
              "B-12": "B-12",
              "RAPID B-12 ENERGY": "RAPID B-12 ENERGY",
              "VITAMIN B-12": "VITAMIN B-12",
              "VITAMIN B-12 ER": "VITAMIN B-12 ER"
            },
            "MAVERON HEALTH": {
              "B-12": "B-12"
            },
            "MCKESSON": {
              "HM SUPER VITAMIN B12": "HM SUPER VITAMIN B12",
              "HM VITAMIN B-12": "HM VITAMIN B-12",
              "HM VITAMIN B-12 TR": "HM VITAMIN B-12 TR",
              "HM VITAMIN B-12 ULTRA STRENGTH": "HM VITAMIN B-12 ULTRA STRENGTH",
              "HM VITAMIN B12": "HM VITAMIN B12"
            },
            "MCKESSON SUNMARK": {
              "SM VITAMIN B-12": "SM VITAMIN B-12",
              "SM VITAMIN B12 TR": "SM VITAMIN B12 TR"
            },
            "MEDICINE SHOPPE": {
              "VITAMIN B-12": "VITAMIN B-12",
              "VITAMIN B-12 ER": "VITAMIN B-12 ER"
            },
            "MYLAN INSTITUTIONAL": {
              "CYANOCOBALAMIN": "CYANOCOBALAMIN"
            },
            "NAT-RUL HEALTH PRODUCTS": {
              "VITAMIN B-12": "VITAMIN B-12",
              "VITAMIN B-12 ER": "VITAMIN B-12 ER"
            },
            "NATIONAL VITAMIN": {
              "VITAMIN B-12": "VITAMIN B-12",
              "VITAMIN B12": "VITAMIN B12"
            },
            "NATROL": {
              "VITAMIN B-12": "VITAMIN B-12"
            },
            "NATURES BOUNTY": {
              "B-12": "B-12",
              "B-12 (METHYLCOBALAMIN)": "B-12 (METHYLCOBALAMIN)",
              "B-12 DUAL SPECTRUM": "B-12 DUAL SPECTRUM",
              "B-12 MICROLOZENGE": "B-12 MICROLOZENGE",
              "B-12 SUPER STRENGTH": "B-12 SUPER STRENGTH",
              "CYANOCOBALAMIN": "CYANOCOBALAMIN",
              "VITAMIN B-12": "VITAMIN B-12",
              "VITAMIN B-12 ER": "VITAMIN B-12 ER"
            },
            "PHARMA NATURAL": {
              "VITAMIN B-12": "VITAMIN B-12"
            },
            "PHARMASSURE, INC.": {
              "VITAMIN B-12": "VITAMIN B-12",
              "VITAMIN B-12 ER": "VITAMIN B-12 ER"
            },
            "PHARMAVITE": {
              "B-12": "B-12",
              "B-12 TR": "B-12 TR",
              "VITAMELTS ENERGY VITAMIN B-12": "VITAMELTS ENERGY VITAMIN B-12",
              "VITAMIN B-12": "VITAMIN B-12",
              "VITAMIN B-12 ER": "VITAMIN B-12 ER",
              "VITAMIN B12": "VITAMIN B12"
            },
            "PLUS PHARMA": {
              "VITAMIN B-12": "VITAMIN B-12"
            },
            "PRINCETON RESEARCH": {
              "B-12": "B-12",
              "B-12 (METHYLCOBALAMIN)": "B-12 (METHYLCOBALAMIN)",
              "B-12 FAST DISSOLVE": "B-12 FAST DISSOLVE",
              "B-12 METHYLCOBALAMIN": "B-12 METHYLCOBALAMIN"
            },
            "RELIABLE 1 LABS": {
              "VITAMIN B-12": "VITAMIN B-12"
            },
            "REXALL SUNDOWN": {
              "VITAMIN B-12": "VITAMIN B-12",
              "VITAMIN B-12 ER": "VITAMIN B-12 ER",
              "VITAMIN B12 TR": "VITAMIN B12 TR"
            },
            "RICHMOND PHARMACEUTICALS": {
              "VITAMIN B 12": "VITAMIN B 12",
              "VITAMIN B-12": "VITAMIN B-12"
            },
            "RITE AID CORPORATION": {
              "PA VITAMIN B-12 TR": "PA VITAMIN B-12 TR",
              "RA VITAMIN B-12": "RA VITAMIN B-12",
              "RA VITAMIN B-12 TR": "RA VITAMIN B-12 TR",
              "RA VITAMIN B12": "RA VITAMIN B12"
            },
            "RUGBY LABORATORIES": {
              "VITAMIN B-12": "VITAMIN B-12",
              "VITAMIN B12": "VITAMIN B12"
            },
            "RX PHARMA PACK": {
              "B-12 COMPLIANCE INJECTION": "B-12 COMPLIANCE INJECTION"
            },
            "SOLACE NUTRITION": {
              "VB12 MAX": "VB12 MAX"
            },
            "SOMERSET THERAPEUTICS": {
              "CYANOCOBALAMIN": "CYANOCOBALAMIN"
            },
            "TEVA PHARMACEUTICALS USA": {
              "VITAMIN B-12": "VITAMIN B-12"
            },
            "TWIN LABS": {
              "B-12 DOTS": "B-12 DOTS"
            },
            "VITALINE": {
              "VITAMIN B-12": "VITAMIN B-12"
            },
            "VITRUVIAS THERAPEUTICS": {
              "CYANOCOBALAMIN": "CYANOCOBALAMIN"
            },
            "WAL-MART": {
              "B-12": "B-12",
              "B-12 TR": "B-12 TR",
              "B12 FAST DISSOLVE": "B12 FAST DISSOLVE",
              "MM VITAMIN B12": "MM VITAMIN B12",
              "SV VITAMIN B-12 ER": "SV VITAMIN B-12 ER",
              "VITAMIN B-12": "VITAMIN B-12",
              "VITAMIN B-12 ER": "VITAMIN B-12 ER"
            },
            "WALGREENS": {
              "B-12": "B-12",
              "B-12 TR": "B-12 TR",
              "VITAMIN B-12": "VITAMIN B-12",
              "VITAMIN B-12 ER": "VITAMIN B-12 ER",
              "VITAMIN B12": "VITAMIN B12",
              "VITAMIN B12 TR": "VITAMIN B12 TR"
            },
            "WINDMILL CONSUMER PRODUCTS": {
              "B-12": "B-12"
            }
          },
          "Liver Preparations": {
            "MILLER": {
              "LIVER EXTRACT": "LIVER EXTRACT"
            },
            "THE KEY COMPANY": {
              "LIVER": "LIVER"
            }
          }
        },
        "Folic Acid/Folates": {
          "Folic Acid/Folates": {
            "21ST CENTURY HEALTHCARE": {
              "FOLIC ACID": "FOLIC ACID"
            },
            "A-S MEDICATION SOLUTIONS": {
              "FOLIC ACID": "FOLIC ACID"
            },
            "ACTAVIS PHARMA": {
              "FOLIC ACID": "FOLIC ACID"
            },
            "AIDAREX PHARMACEUTICALS": {
              "FOLIC ACID": "FOLIC ACID"
            },
            "AIPING PHARMACEUTICAL": {
              "FOLIC ACID": "FOLIC ACID"
            },
            "ALTUS PHARMA": {
              "FOLIC ACID": "FOLIC ACID"
            },
            "AMERICAN HEALTH PACKAGING": {
              "FOLIC ACID": "FOLIC ACID"
            },
            "AMNEAL PHARMACEUTICALS": {
              "FOLIC ACID": "FOLIC ACID"
            },
            "APACE PACKAGING": {
              "FOLIC ACID": "FOLIC ACID"
            },
            "APHENA PHARMA SOLUTIONS": {
              "FOLIC ACID": "FOLIC ACID"
            },
            "AVPAK": {
              "FOLIC ACID": "FOLIC ACID"
            },
            "BASIC DRUGS": {
              "FOLIC ACID": "FOLIC ACID"
            },
            "BERGEN BRUNSWIG": {
              "GNP FOLIC ACID": "GNP FOLIC ACID"
            },
            "BIO-TECH": {
              "FA-8": "FA-8",
              "FOLIC ACID": "FOLIC ACID"
            },
            "BRYANT RANCH PREPACK": {
              "FOLIC ACID": "FOLIC ACID"
            },
            "CARDINAL HEALTH REPACKAGING SO": {
              "FOLIC ACID": "FOLIC ACID"
            },
            "CHAIN DRUG CONSORTIUM": {
              "FOLIC ACID": "FOLIC ACID"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC FOLIC ACID": "QC FOLIC ACID"
            },
            "CHARTWELL RX": {
              "FOLIC ACID": "FOLIC ACID"
            },
            "CHRONOHEALTH": {
              "FOLIC ACID": "FOLIC ACID"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS FOLIC ACID": "CVS FOLIC ACID"
            },
            "ENCORE SCIENTIFIC": {
              "FOLIC ACID": "FOLIC ACID"
            },
            "EQUALINE": {
              "EQL FOLIC ACID": "EQL FOLIC ACID"
            },
            "EXELAN PHARMACEUTICALS": {
              "FOLIC ACID": "FOLIC ACID"
            },
            "FAGRON": {
              "FOLIC ACID": "FOLIC ACID"
            },
            "FREEDA VITAMINS": {
              "FOLIC ACID": "FOLIC ACID"
            },
            "FREEDOM PHARMACEUTICALS": {
              "FOLIC ACID": "FOLIC ACID"
            },
            "FRESENIUS KABI USA": {
              "FOLIC ACID": "FOLIC ACID"
            },
            "HANNAFORD BROTHERS": {
              "FOLIC ACID": "FOLIC ACID"
            },
            "KAISER FOUNDATION HOSPITAL": {
              "KP FOLIC ACID": "KP FOLIC ACID"
            },
            "LEADER BRAND PRODUCTS": {
              "FOLIC ACID": "FOLIC ACID"
            },
            "LEADING PHARMA": {
              "FOLIC ACID": "FOLIC ACID"
            },
            "LEINER HEALTH PRODUCTS": {
              "YL FOLIC ACID": "YL FOLIC ACID"
            },
            "LETCO MEDICAL": {
              "FOLIC ACID": "FOLIC ACID"
            },
            "LIBERTY PHARMACEUTICAL": {
              "FOLIC ACID": "FOLIC ACID"
            },
            "MAGNO-HUMPHRIES LABORATORIES": {
              "FOLIC ACID": "FOLIC ACID"
            },
            "MAJOR PHARMACEUTICALS": {
              "FOLIC ACID": "FOLIC ACID"
            },
            "MARLEX PHARMACEUTICALS": {
              "FOLIC ACID": "FOLIC ACID"
            },
            "MASON VITAMINS": {
              "FOLIC ACID": "FOLIC ACID"
            },
            "MCKESSON": {
              "HM FOLIC ACID": "HM FOLIC ACID"
            },
            "MCKESSON SUNMARK": {
              "SM FOLIC ACID": "SM FOLIC ACID"
            },
            "MEDISCA": {
              "FOLIC ACID": "FOLIC ACID"
            },
            "METHOD PHARMACEUTICALS": {
              "FOLIC ACID": "FOLIC ACID"
            },
            "MYLAN INSTITUTIONAL": {
              "FOLIC ACID": "FOLIC ACID"
            },
            "NAT-RUL HEALTH PRODUCTS": {
              "FOLIC ACID": "FOLIC ACID"
            },
            "NATIONAL VITAMIN": {
              "FOLIC ACID": "FOLIC ACID"
            },
            "NATURES BOUNTY": {
              "FOLIC ACID": "FOLIC ACID"
            },
            "NUCARE PHARMACEUTICALS": {
              "FOLIC ACID": "FOLIC ACID"
            },
            "PAR PHARMACEUTICALS": {
              "FOLIC ACID": "FOLIC ACID"
            },
            "PCCA": {
              "FOLIC ACID": "FOLIC ACID"
            },
            "PDRX PHARMACEUTICAL": {
              "FOLIC ACID": "FOLIC ACID"
            },
            "PHARMASSURE, INC.": {
              "FOLIC ACID": "FOLIC ACID"
            },
            "PHARMAVITE": {
              "FOLIC ACID": "FOLIC ACID"
            },
            "PLUS PHARMA": {
              "FOLIC ACID": "FOLIC ACID"
            },
            "PREFERRED PHARMACEUTICALS": {
              "FOLIC ACID": "FOLIC ACID"
            },
            "PRINCETON RESEARCH": {
              "FOLIC ACID": "FOLIC ACID"
            },
            "PUBLIX SUPER MARKETS INC.": {
              "PX FOLIC ACID": "PX FOLIC ACID"
            },
            "QUALITY CARE": {
              "FOLIC ACID": "FOLIC ACID"
            },
            "QUALITY CARE PRODUCTS": {
              "FOLIC ACID": "FOLIC ACID"
            },
            "REXALL SUNDOWN": {
              "FOLATE": "FOLATE",
              "FOLIC ACID": "FOLIC ACID"
            },
            "RICHMOND PHARMACEUTICALS": {
              "FOLIC ACID": "FOLIC ACID"
            },
            "RITE AID CORPORATION": {
              "RA FOLIC ACID": "RA FOLIC ACID"
            },
            "RUGBY LABORATORIES": {
              "FOLIC ACID": "FOLIC ACID"
            },
            "SKY PACKAGING": {
              "FOLIC ACID": "FOLIC ACID"
            },
            "SPECTRUM": {
              "FOLIC ACID": "FOLIC ACID"
            },
            "ST MARY'S MPP": {
              "FOLIC ACID": "FOLIC ACID"
            },
            "SUNRISE PHARMACEUTICAL": {
              "FOLIC ACID": "FOLIC ACID"
            },
            "TWIN LABS": {
              "FOLIC ACID": "FOLIC ACID"
            },
            "VANGARD": {
              "FOLIC ACID": "FOLIC ACID"
            },
            "WAL-MART": {
              "FOLIC ACID": "FOLIC ACID"
            },
            "WALGREENS": {
              "FOLIC ACID": "FOLIC ACID"
            },
            "WINDMILL CONSUMER PRODUCTS": {
              "FOLIC ACID": "FOLIC ACID"
            },
            "X-GEN PHARMACEUTICALS, INC": {
              "FOLIC ACID": "FOLIC ACID"
            }
          }
        },
        "Hematopoietic Growth Factors": {
          "Erythroid Maturation Agents": {
            "CELGENE CORP": {
              "REBLOZYL": "REBLOZYL"
            }
          },
          "Erythropoiesis-Stimulating Agents (ESAs)": {
            "AMGEN": {
              "ARANESP (ALBUMIN FREE)": "ARANESP (ALBUMIN FREE)",
              "EPOGEN": "EPOGEN"
            },
            "JANSSEN PRODUCTS": {
              "PROCRIT": "PROCRIT"
            },
            "PFIZER U.S.": {
              "RETACRIT": "RETACRIT"
            },
            "VIFOR": {
              "MIRCERA": "MIRCERA",
              "RETACRIT": "RETACRIT"
            }
          },
          "Granulocyte Colony-Stimulating Factors (G-CSF)": {
            "AMGEN": {
              "NEULASTA": "NEULASTA",
              "NEULASTA ONPRO": "NEULASTA ONPRO",
              "NEUPOGEN": "NEUPOGEN"
            },
            "COHERUS BIOSCIENCES": {
              "UDENYCA": "UDENYCA"
            },
            "MYLAN INSTITUTIONAL": {
              "FULPHILA": "FULPHILA"
            },
            "PFIZER U.S.": {
              "NIVESTYM": "NIVESTYM"
            },
            "SANDOZ": {
              "ZARXIO": "ZARXIO",
              "ZIEXTENZO": "ZIEXTENZO"
            },
            "TEVA PHARMACEUTICALS USA": {
              "GRANIX": "GRANIX"
            }
          },
          "Granulocyte/Macrophage Colony-Stimulating Factor(GM-CSF)": {
            "PARTNER THERAPEUTICS": {
              "LEUKINE": "LEUKINE"
            }
          },
          "Thrombopoietin (TPO) Receptor Agonists": {
            "AKARX": {
              "DOPTELET": "DOPTELET"
            },
            "AMGEN": {
              "NPLATE": "NPLATE"
            },
            "NOVARTIS": {
              "PROMACTA": "PROMACTA"
            },
            "SHIONOGI PHARMA": {
              "MULPLETA": "MULPLETA"
            }
          }
        },
        "Hematopoietic Mixtures": {
          "Cobalamin Combinations": {
            "EMPOWER PHARMACY": {
              "LIPO-B": "LIPO-B"
            },
            "FREEDA VITAMINS": {
              "VITAMIN B12-FOLIC ACID": "VITAMIN B12-FOLIC ACID"
            },
            "GENERAL NUTRITION CORP": {
              "B-12": "B-12"
            },
            "KRAMER-NOVIS": {
              "ABANEU-SL": "ABANEU-SL"
            },
            "MASON VITAMINS": {
              "B-12": "B-12"
            },
            "NATURES BOUNTY": {
              "B-12": "B-12"
            },
            "POLY PHARMACEUTICALS": {
              "FOLTRATE": "FOLTRATE"
            },
            "PRINCETON RESEARCH": {
              "B-12 + FOLIC ACID": "B-12 + FOLIC ACID",
              "B-12 PLUS FOLIC ACID": "B-12 PLUS FOLIC ACID",
              "B-12 ULTRA": "B-12 ULTRA",
              "NATURES TRUTH B-12": "NATURES TRUTH B-12"
            },
            "PROSYNTHESIS LABORATORIES": {
              "OPURITY B12/FOLIC ACID": "OPURITY B12/FOLIC ACID"
            },
            "PURE ENCAPSULATIONS": {
              "B12 FOLATE": "B12 FOLATE"
            },
            "RITE AID CORPORATION": {
              "RA B-12": "RA B-12"
            },
            "SORTER LABS": {
              "NEURIN-SL": "NEURIN-SL"
            },
            "THE KEY COMPANY": {
              "FOLIC + B12": "FOLIC + B12"
            },
            "THERALOGIX": {
              "MTX SUPPORT": "MTX SUPPORT"
            },
            "US COMPOUNDING": {
              "VIT B12-METHIONINE-INOS-CHOL": "VIT B12-METHIONINE-INOS-CHOL"
            }
          },
          "Folic Acid/Folate Combinations": {
            "7T PHARMA": {
              "GENICIN VITA-D": "GENICIN VITA-D"
            },
            "ACELLA PHARMACEUTICALS": {
              "BP VIT 3": "BP VIT 3",
              "BP VIT 3 PLUS": "BP VIT 3 PLUS"
            },
            "ADLER-STERN": {
              "CIFEREX": "CIFEREX"
            },
            "AMELLA PHARMA": {
              "CHOLECAL DF": "CHOLECAL DF"
            },
            "AUM PHARMACEUTICALS": {
              "FOLVIK-D": "FOLVIK-D"
            },
            "AVKARE": {
              "AV-VITE FB": "AV-VITE FB"
            },
            "AXION PHAMA": {
              "ZOLATE": "ZOLATE"
            },
            "AXIS PHARMACEUTICS": {
              "AXIFOL": "AXIFOL"
            },
            "BASIEM": {
              "ORTHO DF": "ORTHO DF"
            },
            "BIO-TECH": {
              "B-6 FOLIC ACID": "B-6 FOLIC ACID"
            },
            "BRECKENRIDGE": {
              "FOLBEE": "FOLBEE",
              "FOLPLEX 2.2": "FOLPLEX 2.2"
            },
            "CENTURION LABS": {
              "AIRAVITE": "AIRAVITE",
              "CENFOL": "CENFOL"
            },
            "DOMEL LABORATORIES": {
              "BTREX": "BTREX"
            },
            "GM PHARMACEUTICALS": {
              "FOLENE": "FOLENE"
            },
            "H2 PHARMA": {
              "FABB": "FABB",
              "FOLTABS 800": "FOLTABS 800"
            },
            "INNOVATIVE APOTHECARY SOLUTION": {
              "FOLIC D3": "FOLIC D3"
            },
            "INNOVIDA PHARMACEUTIQUE": {
              "FOLIKA-D": "FOLIKA-D"
            },
            "KEY THERAPEUTICS": {
              "CYFOLEX": "CYFOLEX"
            },
            "KRAMER-NOVIS": {
              "ABATREX": "ABATREX"
            },
            "MASON VITAMINS": {
              "D 1000 PLUS ALOE": "D 1000 PLUS ALOE",
              "FOLIC ACID-VIT B6-VIT B12": "FOLIC ACID-VIT B6-VIT B12",
              "HOMOCYSTEINE FORMULA": "HOMOCYSTEINE FORMULA",
              "RX SUPPORT HB/REFLUX/ALOE": "RX SUPPORT HB/REFLUX/ALOE"
            },
            "MAYNE PHARMA": {
              "FABB": "FABB",
              "FOLTABS 800": "FOLTABS 800"
            },
            "MESORA PHARMA": {
              "FOLI-D": "FOLI-D",
              "VITAMEZ": "VITAMEZ"
            },
            "METAGENICS": {
              "INTRINSI B12-FOLATE": "INTRINSI B12-FOLATE"
            },
            "MISEMER": {
              "ZAVARA": "ZAVARA"
            },
            "NATIONAL VITAMIN": {
              "B COMPLEX-FOLIC ACID": "B COMPLEX-FOLIC ACID"
            },
            "NEOMED PHARMACEUTICAL": {
              "FOLVITE-D": "FOLVITE-D"
            },
            "PBM PHARMACEUTICALS": {
              "ANIMI-3": "ANIMI-3",
              "ANIMI-3/VITAMIN D": "ANIMI-3/VITAMIN D"
            },
            "POLY PHARMACEUTICALS": {
              "FOLITE": "FOLITE"
            },
            "PRO-PHARMA LLC": {
              "FOLINIC-PLUS": "FOLINIC-PLUS"
            },
            "PURE TEK": {
              "DERMACINRX PUREFOLIX": "DERMACINRX PUREFOLIX",
              "FOLIXAPURE": "FOLIXAPURE",
              "FOLTREXYL": "FOLTREXYL"
            },
            "QUALITY CARE": {
              "FOLGARD RX": "FOLGARD RX"
            },
            "RE-POR LABS": {
              "PRE-FOLIC": "PRE-FOLIC"
            },
            "REXALL SUNDOWN": {
              "FOLIC ACID XTRA": "FOLIC ACID XTRA"
            },
            "RISING PHARMACEUTICALS": {
              "NUFOL": "NUFOL"
            },
            "SHORELINE PHARMACEUTICALS": {
              "FOLIXAPURE": "FOLIXAPURE"
            },
            "SOLUBIOMIX LLC": {
              "ORTHO D": "ORTHO D"
            },
            "SOLUTECH PHARMACEUTICALS": {
              "NOXIFOL-D": "NOXIFOL-D",
              "ROXIFOL-D": "ROXIFOL-D"
            },
            "SPRING HILL THERAPEUTICS": {
              "B-SERENE": "B-SERENE"
            },
            "STERLING KNIGHT PHARMACEUTICAL": {
              "CIFRAZOL": "CIFRAZOL",
              "DURACHOL": "DURACHOL",
              "REVESTA": "REVESTA"
            },
            "TALEOS PHARMA": {
              "TALIVA": "TALIVA"
            },
            "TRIGEN LABORATORIES": {
              "TL GARD RX": "TL GARD RX"
            },
            "TRIVUE PHARMACEUTICAL": {
              "OVEEZA": "OVEEZA"
            },
            "U S PHARMACEUTICAL": {
              "DIVISTA": "DIVISTA"
            },
            "UPSHER-SMITH": {
              "FA-VITAMIN B-6-VITAMIN B-12": "FA-VITAMIN B-6-VITAMIN B-12",
              "FOLGARD": "FOLGARD",
              "FOLGARD RX": "FOLGARD RX"
            },
            "VIRTUS PHARMACEUTICALS OPCO": {
              "VIRT-GARD": "VIRT-GARD",
              "VIRT-VITE": "VIRT-VITE"
            },
            "WESTMINSTER PHARMACEUTICALS": {
              "WESTAB MINI": "WESTAB MINI",
              "WESTAB ONE": "WESTAB ONE"
            },
            "WINDMILL CONSUMER PRODUCTS": {
              "MILLGUARD": "MILLGUARD"
            }
          },
          "Iron Combinations": {
            "ACTIPHARMA": {
              "ACTIRON": "ACTIRON"
            },
            "ALLEGIS PHARMACEUTICALS": {
              "ACTIVE FE": "ACTIVE FE"
            },
            "AUM PHARMACEUTICALS": {
              "FOLVITE-FE": "FOLVITE-FE"
            },
            "AVION PHARMACEUTICALS": {
              "CHROMAGEN": "CHROMAGEN",
              "FERIVA": "FERIVA",
              "FERIVAFA": "FERIVAFA",
              "NIFEREX": "NIFEREX"
            },
            "BIO-TECH": {
              "FERROTRIN": "FERROTRIN"
            },
            "BRECKENRIDGE": {
              "FEROCON": "FEROCON",
              "FERREX 150 FORTE PLUS": "FERREX 150 FORTE PLUS",
              "FERREX 150 PLUS": "FERREX 150 PLUS",
              "FERROCITE PLUS": "FERROCITE PLUS",
              "MULTIGEN": "MULTIGEN",
              "MULTIGEN FOLIC": "MULTIGEN FOLIC",
              "MULTIGEN PLUS": "MULTIGEN PLUS"
            },
            "CARWIN ASSOCIATES": {
              "NUFERA": "NUFERA"
            },
            "CENTURION LABS": {
              "CENTRATEX": "CENTRATEX"
            },
            "CONTRACT PHARMACAL CORPORATION": {
              "FEROTRINSIC": "FEROTRINSIC",
              "POLYSACCHARIDE IRON FORTE": "POLYSACCHARIDE IRON FORTE"
            },
            "CYPRESS PHARMACEUTICAL": {
              "HEMATINIC PLUS VIT/MINERALS": "HEMATINIC PLUS VIT/MINERALS",
              "IRON 100 PLUS": "IRON 100 PLUS",
              "IRON 100/C": "IRON 100/C",
              "POLY-IRON 150 FORTE": "POLY-IRON 150 FORTE"
            },
            "DOMEL LABORATORIES": {
              "IRO-PLEX": "IRO-PLEX"
            },
            "G I PHARMACEUTICALS": {
              "IROFOL": "IROFOL"
            },
            "GENSAVIS PHARMACEUTICALS": {
              "NOVAFERRUM 125": "NOVAFERRUM 125"
            },
            "GIL PHARMACEUTICAL CORP.": {
              "PROTECTIRON": "PROTECTIRON"
            },
            "GM PHARMACEUTICALS": {
              "ACTIVE FE": "ACTIVE FE"
            },
            "HAWTHORN PHARMACEUTICALS": {
              "ICAR-C": "ICAR-C",
              "ICAR-C PLUS": "ICAR-C PLUS"
            },
            "INSIGHT PHARMACEUTICALS": {
              "VITRON-C": "VITRON-C"
            },
            "INTEGRATIVE THERAPEUTICS": {
              "IRON COMPLEX": "IRON COMPLEX"
            },
            "INTERNATIONAL VITAMIN CORP": {
              "FERRO-SEQUELS": "FERRO-SEQUELS"
            },
            "KMR PHARMACEUTICALS": {
              "FE C TAB": "FE C TAB",
              "FE C TAB PLUS": "FE C TAB PLUS",
              "K-TAN PLUS": "K-TAN PLUS"
            },
            "KRAMER-NOVIS": {
              "ABATRON": "ABATRON",
              "ABATRON AF": "ABATRON AF"
            },
            "ME PHARMACEUTICALS": {
              "MYFERON 150 FORTE": "MYFERON 150 FORTE"
            },
            "MEDA CONSUMER HEALTHCARE": {
              "BIFERA": "BIFERA",
              "FEOSOL BIFERA": "FEOSOL BIFERA"
            },
            "MILLER": {
              "FE PLUS PROTEIN": "FE PLUS PROTEIN"
            },
            "NATIONAL VITAMIN": {
              "CHEWABLE IRON": "CHEWABLE IRON"
            },
            "NATURES BOUNTY": {
              "GENTLE IRON": "GENTLE IRON"
            },
            "NEPHRO-TECH": {
              "NEPHRON FA": "NEPHRON FA"
            },
            "NNODUM CORPORATION": {
              "HEMATOGEN": "HEMATOGEN",
              "HEMATOGEN FA": "HEMATOGEN FA",
              "HEMATOGEN FORTE": "HEMATOGEN FORTE",
              "IFEREX 150 FORTE": "IFEREX 150 FORTE",
              "TRICON": "TRICON"
            },
            "PAR PHARMACEUTICAL": {
              "FE C": "FE C"
            },
            "PHARMADERM": {
              "I.L.X. B-12": "I.L.X. B-12"
            },
            "PRONOVA CORPORATION": {
              "HEMAX": "HEMAX",
              "MAXFE": "MAXFE"
            },
            "PRUGEN": {
              "IS 24/6": "IS 24/6"
            },
            "PURE TEK": {
              "PUREFE PLUS": "PUREFE PLUS",
              "PUREVIT DUALFE PLUS": "PUREVIT DUALFE PLUS"
            },
            "REXALL SUNDOWN": {
              "GENTLE IRON": "GENTLE IRON"
            },
            "RISING PHARMACEUTICALS": {
              "FOLITAB 500": "FOLITAB 500"
            },
            "RV NUTRITIONAL": {
              "VITABEX IRON": "VITABEX IRON"
            },
            "SANDOZ": {
              "FOLTRIN": "FOLTRIN"
            },
            "SETON PHARMACEUTICALS": {
              "SE-TAN PLUS": "SE-TAN PLUS"
            },
            "SEYER PHARMATEC": {
              "HEMATRON-AF": "HEMATRON-AF"
            },
            "SORTER LABS": {
              "FERRO-PLEX HEMATINIC": "FERRO-PLEX HEMATINIC"
            },
            "TRIGEN LABORATORIES": {
              "CORVITA 150": "CORVITA 150",
              "FOLIVANE-PLUS": "FOLIVANE-PLUS",
              "TARON FORTE": "TARON FORTE",
              "TL ICON": "TL ICON",
              "TL-HEM 150": "TL-HEM 150",
              "TRIGELS-F FORTE": "TRIGELS-F FORTE"
            },
            "U S PHARMACEUTICAL": {
              "FUSION": "FUSION",
              "FUSION PLUS": "FUSION PLUS",
              "HEMOCYTE PLUS": "HEMOCYTE PLUS",
              "HEMOCYTE-F": "HEMOCYTE-F",
              "HEMOCYTE-PLUS": "HEMOCYTE-PLUS",
              "INTEGRA": "INTEGRA",
              "INTEGRA PLUS": "INTEGRA PLUS",
              "TANDEM": "TANDEM",
              "TANDEM PLUS": "TANDEM PLUS"
            },
            "VERTICAL PHARMACEUTICAL": {
              "CORVITE 150": "CORVITE 150",
              "CORVITE FE": "CORVITE FE"
            },
            "VIRTUS PHARMACEUTICALS": {
              "VIRT-FEFA PLUS": "VIRT-FEFA PLUS"
            },
            "VIRTUS PHARMACEUTICALS OPCO": {
              "VIRT-FEFA PLUS": "VIRT-FEFA PLUS"
            },
            "VITAMEDMD": {
              "IRON 21/7": "IRON 21/7"
            },
            "WINDMILL CONSUMER PRODUCTS": {
              "FE CAPS/STOOL SOFTENER": "FE CAPS/STOOL SOFTENER"
            },
            "WOMEN'S CHOICE PHARMACEUTICALS": {
              "IROSPAN 24/6": "IROSPAN 24/6"
            }
          },
          "Iron w/ Folic Acid": {
            "COLORADO BIOLABS": {
              "PROFERRIN-FORTE": "PROFERRIN-FORTE"
            },
            "CYPRESS PHARMACEUTICAL": {
              "HEMATINIC/FOLIC ACID": "HEMATINIC/FOLIC ACID"
            },
            "TRIGEN LABORATORIES": {
              "FOLIVANE-F": "FOLIVANE-F"
            },
            "U S PHARMACEUTICAL": {
              "FUSION SPRINKLES": "FUSION SPRINKLES",
              "HEMOCYTE-F": "HEMOCYTE-F",
              "INTEGRA F": "INTEGRA F",
              "TANDEM F": "TANDEM F"
            }
          },
          "Iron-B12-Folate": {
            "AVION PHARMACEUTICALS": {
              "FERIVA 21/7": "FERIVA 21/7"
            },
            "BRECKENRIDGE": {
              "FERREX 150 FORTE": "FERREX 150 FORTE",
              "FERREX 28": "FERREX 28"
            },
            "G I PHARMACEUTICALS": {
              "IROFOL": "IROFOL"
            },
            "MASON VITAMINS": {
              "IRON FORMULA": "IRON FORMULA"
            },
            "METHOD PHARMACEUTICALS": {
              "FOCALGIN DSS": "FOCALGIN DSS"
            },
            "MISSION": {
              "FERRALET 90": "FERRALET 90"
            },
            "MYLAN SPECIALTY L.P.": {
              "BIFERARX": "BIFERARX"
            },
            "TRIGEN LABORATORIES": {
              "FERRAPLUS 90": "FERRAPLUS 90"
            },
            "WH NUTRITIONALS": {
              "HEMETAB": "HEMETAB"
            }
          }
        },
        "Iron": {
          "Iron": {
            "21ST CENTURY HEALTHCARE": {
              "IRON": "IRON",
              "SLOW RELEASE IRON": "SLOW RELEASE IRON"
            },
            "A-S MEDICATION SOLUTIONS": {
              "FEROSUL": "FEROSUL",
              "FERROUS GLUCONATE": "FERROUS GLUCONATE",
              "FERROUS SULFATE": "FERROUS SULFATE"
            },
            "A. NELSON & CO": {
              "SPATONE PUR-ABSORB IRON": "SPATONE PUR-ABSORB IRON"
            },
            "ACTAVIS PHARMA": {
              "NA FERRIC GLUC CPLX IN SUCROSE": "NA FERRIC GLUC CPLX IN SUCROSE"
            },
            "ADVANTICE HEALTH": {
              "FERGON": "FERGON"
            },
            "AIDAREX PHARMACEUTICALS": {
              "FERROUS SULFATE": "FERROUS SULFATE"
            },
            "AKORN": {
              "FERROUS SULFATE": "FERROUS SULFATE"
            },
            "ALLERGAN": {
              "INFED": "INFED"
            },
            "AMAG PHARMACEUTICALS": {
              "FERAHEME": "FERAHEME"
            },
            "AMERICAN REGENT": {
              "INJECTAFER": "INJECTAFER",
              "VENOFER": "VENOFER"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP IRON": "GNP IRON"
            },
            "APHENA PHARMA SOLUTIONS": {
              "FERROUS SULFATE": "FERROUS SULFATE"
            },
            "ATLANTIC BIOLOGICALS": {
              "FERROUS SULFATE": "FERROUS SULFATE"
            },
            "AUBURN PHARMACEUTICAL": {
              "FERROUSUL": "FERROUSUL"
            },
            "BASIC DRUGS": {
              "FERROUS SULFATE": "FERROUS SULFATE"
            },
            "BAYER CONSUMER": {
              "FERGON": "FERGON"
            },
            "BAYSHORE PHARMACEUTICALS": {
              "BPROTECTED PEDIA IRON": "BPROTECTED PEDIA IRON"
            },
            "BENE HEALTH OTC": {
              "FERROUS SULFATE": "FERROUS SULFATE"
            },
            "BERGEN BRUNSWIG": {
              "GNP IRON": "GNP IRON",
              "GNP SLOW RELEASE IRON": "GNP SLOW RELEASE IRON"
            },
            "BI COASTAL PHARMACEUTICAL": {
              "POLYSACCHARIDE-IRON COMPLEX": "POLYSACCHARIDE-IRON COMPLEX"
            },
            "BRECKENRIDGE": {
              "FERREX 150": "FERREX 150",
              "FERROCITE": "FERROCITE"
            },
            "BRYANT RANCH PREPACK": {
              "FERROUS SULFATE": "FERROUS SULFATE"
            },
            "CALLION PHARMA": {
              "IRON UP": "IRON UP"
            },
            "CENTURION LABS": {
              "WEE CARE": "WEE CARE"
            },
            "CHAIN DRUG CONSORTIUM": {
              "FERROUS GLUCONATE": "FERROUS GLUCONATE",
              "IRON SUPPLEMENT": "IRON SUPPLEMENT",
              "SLOW RELEASE IRON": "SLOW RELEASE IRON"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC FERROUS SULFATE": "QC FERROUS SULFATE"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "FERROUS SULFATE": "FERROUS SULFATE"
            },
            "COLORADO BIOLABS": {
              "PROFERRIN ES": "PROFERRIN ES"
            },
            "CONTRACT PHARMACAL CORPORATION": {
              "FERROUS SULFATE": "FERROUS SULFATE"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS IRON": "CVS IRON",
              "CVS SLOW RELEASE IRON": "CVS SLOW RELEASE IRON"
            },
            "CYPRESS PHARMACEUTICAL": {
              "FERROUS FUMARATE": "FERROUS FUMARATE",
              "POLY-IRON 150": "POLY-IRON 150"
            },
            "DIRECT RX": {
              "FERROUS SULFATE": "FERROUS SULFATE"
            },
            "DSE HEALTHCARE SOLUTIONS": {
              "FERGON": "FERGON",
              "NU-IRON": "NU-IRON"
            },
            "EQUALINE": {
              "EQL CARBONYL IRON": "EQL CARBONYL IRON",
              "EQL IRON SUPPLEMENT THERAPY": "EQL IRON SUPPLEMENT THERAPY",
              "EQL SLOW RELEASE IRON": "EQL SLOW RELEASE IRON"
            },
            "FAGRON": {
              "FERROUS SULFATE": "FERROUS SULFATE"
            },
            "FREEDA VITAMINS": {
              "FERROUS FUMARATE": "FERROUS FUMARATE"
            },
            "FRESENIUS": {
              "VENOFER": "VENOFER"
            },
            "GEISS DESTIN & DUNN": {
              "GOODSENSE IRON": "GOODSENSE IRON"
            },
            "GENSAVIS PHARMACEUTICALS": {
              "NOVAFERRUM 50": "NOVAFERRUM 50",
              "NOVAFERRUM PEDIATRIC DROPS": "NOVAFERRUM PEDIATRIC DROPS"
            },
            "GERI-CARE": {
              "FERRIC X-150": "FERRIC X-150",
              "FERROUS GLUCONATE": "FERROUS GLUCONATE",
              "FERROUS SULFATE": "FERROUS SULFATE",
              "IRON": "IRON",
              "IRON SUPPLEMENT": "IRON SUPPLEMENT"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "SLOW FE": "SLOW FE"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "FERROUS SULFATE": "FERROUS SULFATE"
            },
            "H2 PHARMA": {
              "IRON CHEWS PEDIATRIC": "IRON CHEWS PEDIATRIC"
            },
            "HANNAFORD BROTHERS": {
              "HIGH POTENCY IRON": "HIGH POTENCY IRON"
            },
            "HAWTHORN PHARMACEUTICALS": {
              "ICAR": "ICAR"
            },
            "HI-TECH": {
              "FERROUS SULFATE": "FERROUS SULFATE"
            },
            "HIKMA": {
              "NA FERRIC GLUC CPLX IN SUCROSE": "NA FERRIC GLUC CPLX IN SUCROSE"
            },
            "HILLESTAD PHARMACEUTICALS": {
              "FERRIMIN 150": "FERRIMIN 150"
            },
            "HRA PHARMA RARE DISEASES": {
              "NU-IRON": "NU-IRON"
            },
            "KAISER FOUNDATION HOSPITAL": {
              "KP FERROUS GLUCONATE": "KP FERROUS GLUCONATE",
              "KP FERROUS SULFATE": "KP FERROUS SULFATE"
            },
            "KMR PHARMACEUTICALS": {
              "FERROUS SULFATE": "FERROUS SULFATE"
            },
            "LEADER BRAND PRODUCTS": {
              "FERROUS SULFATE": "FERROUS SULFATE",
              "FERROUS SULFATE IRON": "FERROUS SULFATE IRON",
              "IRON": "IRON",
              "IRON (FERROUS SULFATE)": "IRON (FERROUS SULFATE)",
              "IRON 27": "IRON 27",
              "IRON SLOW RELEASE": "IRON SLOW RELEASE"
            },
            "LIBERTY PHARMACEUTICAL": {
              "FERROUS SULFATE": "FERROUS SULFATE"
            },
            "LLORENS PHARMACEUTICAL": {
              "FERROUS SULFATE": "FERROUS SULFATE",
              "HEMATEX": "HEMATEX",
              "HEMATEX IRON COMPLEX": "HEMATEX IRON COMPLEX"
            },
            "M J NUTR": {
              "FER-IN-SOL": "FER-IN-SOL"
            },
            "MAGNO-HUMPHRIES LABORATORIES": {
              "FERROUS SULFATE": "FERROUS SULFATE",
              "FERROUS SULFATE ER": "FERROUS SULFATE ER"
            },
            "MAJOR PHARMACEUTICALS": {
              "FERATE": "FERATE",
              "FEROSUL": "FEROSUL",
              "FERREX 150": "FERREX 150",
              "FERROUS GLUCONATE": "FERROUS GLUCONATE",
              "FERROUS SULFATE": "FERROUS SULFATE"
            },
            "MARLEX PHARMACEUTICALS": {
              "FERROUS SULFATE": "FERROUS SULFATE"
            },
            "MASON VITAMINS": {
              "FERROUS GLUCONATE": "FERROUS GLUCONATE",
              "FERROUS SULFATE": "FERROUS SULFATE",
              "IRON": "IRON",
              "SLOW RELEASE IRON": "SLOW RELEASE IRON"
            },
            "MAYNE PHARMA": {
              "IRON CHEWS PEDIATRIC": "IRON CHEWS PEDIATRIC"
            },
            "MCKESSON": {
              "HM IRON": "HM IRON",
              "HM IRON SLOW RELEASE": "HM IRON SLOW RELEASE",
              "HM SLOW RELEASE IRON": "HM SLOW RELEASE IRON"
            },
            "MCKESSON SUNMARK": {
              "SM IRON": "SM IRON",
              "SM IRON SLOW RELEASE": "SM IRON SLOW RELEASE",
              "SM SLOW RELEASE IRON": "SM SLOW RELEASE IRON"
            },
            "ME PHARMACEUTICALS": {
              "FERROUS SULFATE": "FERROUS SULFATE",
              "MYFERON 150": "MYFERON 150"
            },
            "MEDA CONSUMER HEALTHCARE": {
              "FEOSOL": "FEOSOL",
              "FEOSOL NATURAL RELEASE": "FEOSOL NATURAL RELEASE"
            },
            "MEDICINE SHOPPE": {
              "FERROUS SULFATE": "FERROUS SULFATE"
            },
            "MEIJER": {
              "MEIJER FERROUS SULFATE": "MEIJER FERROUS SULFATE"
            },
            "MILLER": {
              "ELITE IRON": "ELITE IRON"
            },
            "NAT-RUL HEALTH PRODUCTS": {
              "FERROTABS": "FERROTABS",
              "NAT-RUL IRON": "NAT-RUL IRON"
            },
            "NATIONAL VITAMIN": {
              "FERROUS GLUCONATE": "FERROUS GLUCONATE",
              "FERROUS SULFATE": "FERROUS SULFATE",
              "SLOW IRON": "SLOW IRON"
            },
            "NATIONWIDE PHARMACEUTICAL": {
              "FERROUS SULFATE": "FERROUS SULFATE"
            },
            "NATURES BOUNTY": {
              "IRON": "IRON",
              "IRON (FERROUS GLUCONATE)": "IRON (FERROUS GLUCONATE)"
            },
            "NNODUM CORPORATION": {
              "IFEREX 150": "IFEREX 150"
            },
            "NOVAPLUS/ACTAVIS": {
              "NA FERRIC GLUC CPLX IN SUCROSE": "NA FERRIC GLUC CPLX IN SUCROSE"
            },
            "NOVAPLUS/HIKMA": {
              "NA FERRIC GLUC CPLX IN SUCROSE": "NA FERRIC GLUC CPLX IN SUCROSE"
            },
            "NUCARE PHARMACEUTICALS": {
              "FERROUS SULFATE": "FERROUS SULFATE"
            },
            "OPTIMED CONTROLLED RELEASE LAB": {
              "PIC 200": "PIC 200"
            },
            "PAR PHARMACEUTICAL": {
              "FERROUS SULFATE": "FERROUS SULFATE"
            },
            "PAR PHARMACEUTICALS": {
              "FERROUS SULFATE": "FERROUS SULFATE"
            },
            "PATRIN PHARMA": {
              "FERROUS SULFATE": "FERROUS SULFATE",
              "IRON SUPPLEMENT CHILDRENS": "IRON SUPPLEMENT CHILDRENS"
            },
            "PCCA": {
              "FERROUS SULFATE": "FERROUS SULFATE"
            },
            "PDRX PHARMACEUTICAL": {
              "FERROUS SULFATE": "FERROUS SULFATE"
            },
            "PERRIGO": {
              "FERROUS GLUCONATE": "FERROUS GLUCONATE",
              "FERROUS SULFATE": "FERROUS SULFATE"
            },
            "PHARBEST PHARMACEUTICALS": {
              "FERROUS SULFATE": "FERROUS SULFATE"
            },
            "PHARMACEUTICAL ASSOCIATES": {
              "FERROUS SULFATE": "FERROUS SULFATE"
            },
            "PHARMACOSMOS THERAPEUTICS": {
              "MONOFERRIC": "MONOFERRIC"
            },
            "PHARMASSURE, INC.": {
              "IRON": "IRON"
            },
            "PHARMAVITE": {
              "FERROUS SULFATE": "FERROUS SULFATE",
              "IRON": "IRON",
              "IRON (FERROUS SULFATE)": "IRON (FERROUS SULFATE)"
            },
            "PHARMICS": {
              "FERRETTS": "FERRETTS",
              "FERRETTS CHEWABLE IRON": "FERRETTS CHEWABLE IRON",
              "FERRETTS IPS": "FERRETTS IPS"
            },
            "PLUS PHARMA": {
              "FERROUS SULFATE": "FERROUS SULFATE"
            },
            "PREFERRED PHARMACEUTICALS": {
              "FERROUS SULFATE": "FERROUS SULFATE"
            },
            "PRINCETON RESEARCH": {
              "EASY IRON": "EASY IRON",
              "IRON": "IRON",
              "IRON SLOW RELEASE": "IRON SLOW RELEASE"
            },
            "PRO-PHARMA LLC": {
              "PROFE": "PROFE"
            },
            "PROFICIENT RX": {
              "FERROUS SULFATE": "FERROUS SULFATE"
            },
            "PUBLIX SUPER MARKETS INC.": {
              "PX IRON": "PX IRON"
            },
            "QUALITY CARE": {
              "FERROUS SULFATE": "FERROUS SULFATE"
            },
            "R A MCNEIL": {
              "EZFE 200": "EZFE 200"
            },
            "RELIABLE 1 LABS": {
              "FERROUS SULFATE": "FERROUS SULFATE"
            },
            "REXALL SUNDOWN": {
              "IRON": "IRON",
              "PERFECT IRON": "PERFECT IRON"
            },
            "RICHMOND PHARMACEUTICALS": {
              "FERROUS SULFATE": "FERROUS SULFATE"
            },
            "RITE AID CORPORATION": {
              "RA HIGH POTENCY IRON": "RA HIGH POTENCY IRON",
              "RA IRON": "RA IRON",
              "RA SLOW RELEASE IRON": "RA SLOW RELEASE IRON"
            },
            "ROCKWELL MEDICAL": {
              "TRIFERIC": "TRIFERIC"
            },
            "RUGBY LABORATORIES": {
              "FER-IRON": "FER-IRON",
              "FERROUS SULFATE": "FERROUS SULFATE",
              "FERROUS SULFATE ER": "FERROUS SULFATE ER",
              "IRON SLOW RELEASE": "IRON SLOW RELEASE"
            },
            "SANOFI PHARMACEUTICALS": {
              "FERRLECIT": "FERRLECIT"
            },
            "SANOFI-AVENTIS U.S.": {
              "FERRLECIT": "FERRLECIT"
            },
            "SCOT-TUSSIN": {
              "FERRO-BOB": "FERRO-BOB"
            },
            "SHEFFIELD PHARMACEUTICALS": {
              "FEMIRON": "FEMIRON"
            },
            "SILARX": {
              "FERROUS SULFATE": "FERROUS SULFATE"
            },
            "SIMPLE DIAGNOSTICS": {
              "PC PEDIATRIC IRON DROPS": "PC PEDIATRIC IRON DROPS"
            },
            "SPECTRUM": {
              "FERROUS SULFATE": "FERROUS SULFATE"
            },
            "SUN PHARMACEUTICALS": {
              "FERROUS SULFATE": "FERROUS SULFATE"
            },
            "TIME-CAP LABS": {
              "FERROUS SULFATE": "FERROUS SULFATE"
            },
            "TRIS PHARMA": {
              "MYKIDZ IRON 10": "MYKIDZ IRON 10"
            },
            "U S PHARMACEUTICAL": {
              "HEMOCYTE": "HEMOCYTE"
            },
            "UPSHER-SMITH": {
              "FERROUS SULFATE": "FERROUS SULFATE"
            },
            "WAL-MART": {
              "EQ SLOW-RELEASE IRON": "EQ SLOW-RELEASE IRON",
              "IRON": "IRON",
              "SLOW RELEASE IRON": "SLOW RELEASE IRON"
            },
            "WALGREENS": {
              "FERROUS GLUCONATE": "FERROUS GLUCONATE",
              "FERROUS SULFATE": "FERROUS SULFATE",
              "HIGH POTENCY IRON": "HIGH POTENCY IRON",
              "IRON": "IRON",
              "IRON HIGH-POTENCY": "IRON HIGH-POTENCY",
              "SLOW RELEASE IRON": "SLOW RELEASE IRON"
            },
            "WESTMINSTER PHARMACEUTICALS": {
              "FERROUS GLUCONATE": "FERROUS GLUCONATE",
              "FERROUS SULFATE": "FERROUS SULFATE",
              "POLYSACCHARIDE IRON COMPLEX": "POLYSACCHARIDE IRON COMPLEX"
            },
            "WINDMILL CONSUMER PRODUCTS": {
              "FE TABS": "FE TABS",
              "FERROUS GLUCONATE": "FERROUS GLUCONATE"
            }
          }
        },
        "Stem Cell Mobilizers": {
          "CXCR4 Receptor Antagonist": {
            "SANOFI PHARMACEUTICALS": {
              "MOZOBIL": "MOZOBIL"
            }
          }
        }
      },
      "HEMOSTATICS": {
        "Hemostatics - Systemic": {
          "Hemostatics - Systemic": {
            "ACELLA PHARMACEUTICALS": {
              "TRANEXAMIC ACID": "TRANEXAMIC ACID"
            },
            "ACTAVIS PHARMA": {
              "TRANEXAMIC ACID": "TRANEXAMIC ACID"
            },
            "AKORN": {
              "AMINOCAPROIC ACID": "AMINOCAPROIC ACID",
              "TRANEXAMIC ACID": "TRANEXAMIC ACID"
            },
            "ALVOGEN": {
              "TRANEXAMIC ACID": "TRANEXAMIC ACID"
            },
            "AMERICAN HEALTH PACKAGING": {
              "AMINOCAPROIC ACID": "AMINOCAPROIC ACID",
              "TRANEXAMIC ACID": "TRANEXAMIC ACID"
            },
            "AMERICAN REGENT": {
              "AMINOCAPROIC ACID": "AMINOCAPROIC ACID",
              "TRANEXAMIC ACID": "TRANEXAMIC ACID"
            },
            "AMNEAL PHARMACEUTICALS": {
              "AMINOCAPROIC ACID": "AMINOCAPROIC ACID"
            },
            "AMRING PHARMACEUTICALS": {
              "TRANEXAMIC ACID": "TRANEXAMIC ACID"
            },
            "APOLLO PHARMACEUTICALS": {
              "TRANEXAMIC ACID": "TRANEXAMIC ACID"
            },
            "APOTEX": {
              "TRANEXAMIC ACID": "TRANEXAMIC ACID"
            },
            "ATHENEX PHARMACEUTICAL": {
              "TRANEXAMIC ACID": "TRANEXAMIC ACID"
            },
            "AUROMEDICS PHARMA": {
              "TRANEXAMIC ACID": "TRANEXAMIC ACID"
            },
            "AVET PHARMACEUTICALS": {
              "TRANEXAMIC ACID": "TRANEXAMIC ACID"
            },
            "CIVICA": {
              "AMINOCAPROIC ACID": "AMINOCAPROIC ACID"
            },
            "CLOVER PHARMACEUTICALS": {
              "AMICAR": "AMICAR"
            },
            "EXELA PHARMA SCIENCES": {
              "TRANEXAMIC ACID-NACL": "TRANEXAMIC ACID-NACL"
            },
            "FERRING": {
              "LYSTEDA": "LYSTEDA"
            },
            "FRESENIUS KABI USA": {
              "TRANEXAMIC ACID": "TRANEXAMIC ACID"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "TRANEXAMIC ACID": "TRANEXAMIC ACID"
            },
            "HOSPIRA": {
              "AMINOCAPROIC ACID": "AMINOCAPROIC ACID"
            },
            "METHAPHARM": {
              "TRANEXAMIC ACID": "TRANEXAMIC ACID"
            },
            "MYLAN INSTITUTIONAL": {
              "TRANEXAMIC ACID": "TRANEXAMIC ACID"
            },
            "NOVAPLUS/AMER REGENT": {
              "AMINOCAPROIC ACID": "AMINOCAPROIC ACID"
            },
            "NOVAPLUS/HOSPIRA": {
              "AMINOCAPROIC ACID": "AMINOCAPROIC ACID"
            },
            "NOVAPLUS/MYLAN INSTITUTIONAL": {
              "TRANEXAMIC ACID": "TRANEXAMIC ACID"
            },
            "NOVAPLUS/X-GEN": {
              "TRANEXAMIC ACID": "TRANEXAMIC ACID"
            },
            "PFIZER U.S.": {
              "CYKLOKAPRON": "CYKLOKAPRON"
            },
            "PRASCO LABORATORIES": {
              "TRANEXAMIC ACID": "TRANEXAMIC ACID"
            },
            "TRUPHARMA": {
              "AMINOCAPROIC ACID": "AMINOCAPROIC ACID"
            },
            "VIRTUS PHARMACEUTICALS": {
              "TRANEXAMIC ACID": "TRANEXAMIC ACID"
            },
            "VISTAPHARM INC.": {
              "AMINOCAPROIC ACID": "AMINOCAPROIC ACID"
            },
            "VITRUVIAS THERAPEUTICS": {
              "AMINOCAPROIC ACID": "AMINOCAPROIC ACID"
            },
            "X-GEN PHARMACEUTICALS, INC": {
              "TRANEXAMIC ACID": "TRANEXAMIC ACID"
            }
          }
        },
        "Hemostatics - Topical": {
          "Hemostatic Combinations - Topical": {
            "BAXTER HEALTHCARE CORP": {
              "ARTISS": "ARTISS",
              "TISSEEL": "TISSEEL",
              "TISSEEL VH": "TISSEEL VH",
              "TISSEEL VHSD": "TISSEEL VHSD"
            },
            "ETHICON": {
              "EVICEL": "EVICEL"
            },
            "MALLINCKRODT HOSPITAL PRODUCTS": {
              "RAPLIXA": "RAPLIXA"
            },
            "PFIZER U.S.": {
              "GEL-FLOW": "GEL-FLOW",
              "GELFOAM-JMI POWDER": "GELFOAM-JMI POWDER",
              "GELFOAM-JMI SPONGE": "GELFOAM-JMI SPONGE",
              "THROMBI-GEL 10": "THROMBI-GEL 10",
              "THROMBI-GEL 100": "THROMBI-GEL 100",
              "THROMBI-GEL 40": "THROMBI-GEL 40",
              "THROMBI-PAD": "THROMBI-PAD"
            }
          },
          "Hemostatics - Topical": {
            "BAXTER HEALTHCARE CORP": {
              "RECOTHROM": "RECOTHROM",
              "RECOTHROM SPRAY KIT": "RECOTHROM SPRAY KIT",
              "TACHOSIL": "TACHOSIL"
            },
            "BD MEDICAL SURGICAL SYSTEMS": {
              "OXYCEL COTTON-TYPE PLEDGET": "OXYCEL COTTON-TYPE PLEDGET",
              "OXYCEL GAUZE-TYPE": "OXYCEL GAUZE-TYPE",
              "OXYCEL GAUZE-TYPE STRIP": "OXYCEL GAUZE-TYPE STRIP"
            },
            "BIOLIFE, LLC": {
              "NOSEBLEEDQR": "NOSEBLEEDQR",
              "WOUNDSEAL": "WOUNDSEAL"
            },
            "CATALINA HEALTHCARE": {
              "BLEEDCEASE": "BLEEDCEASE",
              "NASALCEASE": "NASALCEASE"
            },
            "COOPER SURGICAL": {
              "ASTRINGYN": "ASTRINGYN"
            },
            "DAVOL": {
              "ACTIFOAM COLLAGEN SPONGE": "ACTIFOAM COLLAGEN SPONGE",
              "AVITENE": "AVITENE",
              "AVITENE FLOUR": "AVITENE FLOUR",
              "ENDO AVITENE": "ENDO AVITENE",
              "SYRINGE AVITENE": "SYRINGE AVITENE",
              "ULTRAFOAM SPONGE 2X6.25X7CM": "ULTRAFOAM SPONGE 2X6.25X7CM",
              "ULTRAFOAM SPONGE 8X12.5X1CM": "ULTRAFOAM SPONGE 8X12.5X1CM",
              "ULTRAFOAM SPONGE 8X12.5X3CM": "ULTRAFOAM SPONGE 8X12.5X3CM",
              "ULTRAFOAM SPONGE 8X25X1CM": "ULTRAFOAM SPONGE 8X25X1CM",
              "ULTRAFOAM SPONGE 8X6.25X1CM": "ULTRAFOAM SPONGE 8X6.25X1CM"
            },
            "ETHICON": {
              "EVITHROM": "EVITHROM",
              "SURGICEL": "SURGICEL",
              "SURGICEL FIBRILLAR": "SURGICEL FIBRILLAR",
              "SURGICEL NU-KNIT": "SURGICEL NU-KNIT",
              "SURGICEL SNOW 1\"\"X2\"\"": "SURGICEL SNOW 1\"\"X2\"\"",
              "SURGICEL SNOW 2\"\"X4\"\"": "SURGICEL SNOW 2\"\"X4\"\"",
              "SURGICEL SNOW 4\"\"X4\"\"": "SURGICEL SNOW 4\"\"X4\"\"",
              "SURGIFOAM": "SURGIFOAM"
            },
            "GORDON": {
              "MONSELS FERRIC SUBSULFATE": "MONSELS FERRIC SUBSULFATE"
            },
            "INTEGRA LIFESCIENCES": {
              "HELISTAT SPONGE": "HELISTAT SPONGE"
            },
            "J & J MEDICAL": {
              "INSTAT": "INSTAT",
              "INSTAT MCH": "INSTAT MCH",
              "INTERCEED": "INTERCEED",
              "INTERCEED (TC7)": "INTERCEED (TC7)",
              "SURGICEL FIBRILLAR": "SURGICEL FIBRILLAR",
              "SURGICEL NU-KNIT": "SURGICEL NU-KNIT",
              "THROMBOGEN": "THROMBOGEN"
            },
            "MALLINCKRODT BRAND PHARMA": {
              "RECOTHROM": "RECOTHROM",
              "RECOTHROM SPRAY KIT": "RECOTHROM SPRAY KIT"
            },
            "PFIZER U.S.": {
              "GEL-FLOW NT": "GEL-FLOW NT",
              "GELFILM": "GELFILM",
              "GELFOAM": "GELFOAM",
              "GELFOAM COMPRESSED SIZE 100": "GELFOAM COMPRESSED SIZE 100",
              "GELFOAM DENTAL PACK SIZE 4": "GELFOAM DENTAL PACK SIZE 4",
              "GELFOAM SPONGE": "GELFOAM SPONGE",
              "GELFOAM SPONGE SIZE 100": "GELFOAM SPONGE SIZE 100",
              "GELFOAM SPONGE SIZE 200": "GELFOAM SPONGE SIZE 200",
              "GELFOAM SPONGE SIZE 50": "GELFOAM SPONGE SIZE 50",
              "THROMBIN-JMI": "THROMBIN-JMI",
              "THROMBIN-JMI EPISTAXIS": "THROMBIN-JMI EPISTAXIS"
            }
          }
        }
      },
      "HYPNOTICS/SEDATIVES/SLEEP DISORDER AGENTS": {
        "Antihistamine Hypnotics": {
          "Antihistamine Hypnotic Combinations": {
            "A-S MEDICATION SOLUTIONS": {
              "ACETADRYL": "ACETADRYL"
            },
            "AIDAREX PHARMACEUTICALS": {
              "ACETADRYL": "ACETADRYL"
            },
            "AMERICAN SALES COMPANY": {
              "IBUPROFEN PM": "IBUPROFEN PM",
              "PAIN RELIEF DAY & NIGHT EX ST": "PAIN RELIEF DAY & NIGHT EX ST",
              "PAIN RELIEF PM EXTRA STRENGTH": "PAIN RELIEF PM EXTRA STRENGTH"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP HEADACHE PM": "GNP HEADACHE PM",
              "GNP IBUPROFEN PM": "GNP IBUPROFEN PM",
              "GNP PAIN RELIEF NIGHTTIME": "GNP PAIN RELIEF NIGHTTIME",
              "GNP PAIN RELIEF PM EX ST": "GNP PAIN RELIEF PM EX ST"
            },
            "BAYER CONSUMER": {
              "ALEVE PM": "ALEVE PM"
            },
            "BERGEN BRUNSWIG": {
              "GNP IBUPROFEN PM": "GNP IBUPROFEN PM",
              "GNP PAIN RELIEF EX ST AM/PM": "GNP PAIN RELIEF EX ST AM/PM",
              "GNP PAIN RELIEF PM EX ST": "GNP PAIN RELIEF PM EX ST"
            },
            "BRYANT RANCH PREPACK": {
              "ACETADRYL": "ACETADRYL"
            },
            "CHAIN DRUG CONSORTIUM": {
              "IBUPROFEN-DIPHENHYDRAMINE HCL": "IBUPROFEN-DIPHENHYDRAMINE HCL",
              "NON-ASPIRIN PM EXTRA STRENGTH": "NON-ASPIRIN PM EXTRA STRENGTH",
              "PAIN RELIEVER PM": "PAIN RELIEVER PM",
              "PAIN RELIEVER PM EX ST": "PAIN RELIEVER PM EX ST"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "PAIN RELIEVER PM EX ST": "PAIN RELIEVER PM EX ST",
              "QC HEADACHE RELIEF PM": "QC HEADACHE RELIEF PM",
              "QC IBUPROFEN-DIPHENHYDRAMINE": "QC IBUPROFEN-DIPHENHYDRAMINE",
              "QC NON-ASPIRIN PM": "QC NON-ASPIRIN PM",
              "QC PAIN RELIEF PM EXT ST": "QC PAIN RELIEF PM EXT ST"
            },
            "CHATTEM INC": {
              "UNISOM PM PAIN": "UNISOM PM PAIN"
            },
            "COSTCO WHOLESALE": {
              "KLS RAPID RELEASE APAP PM": "KLS RAPID RELEASE APAP PM"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS IBUPROFEN PM": "CVS IBUPROFEN PM",
              "CVS NON-ASPIRIN HEADACHE PM": "CVS NON-ASPIRIN HEADACHE PM",
              "CVS NON-ASPIRIN PM": "CVS NON-ASPIRIN PM",
              "CVS PAIN RELIEF PM EX ST": "CVS PAIN RELIEF PM EX ST"
            },
            "EQUALINE": {
              "EQL ACETAMINOPHEN PM": "EQL ACETAMINOPHEN PM",
              "EQL HEADACHE PM": "EQL HEADACHE PM",
              "EQL IBUPROFEN PM": "EQL IBUPROFEN PM",
              "EQL PAIN RELIEF PM": "EQL PAIN RELIEF PM",
              "EQL PAIN RELIEF PM EX ST": "EQL PAIN RELIEF PM EX ST"
            },
            "GEISS DESTIN & DUNN": {
              "GOODSENSE HEADACHE PM": "GOODSENSE HEADACHE PM",
              "GOODSENSE IBUPROFEN PM": "GOODSENSE IBUPROFEN PM",
              "GOODSENSE PAIN RELIEF PM EX ST": "GOODSENSE PAIN RELIEF PM EX ST"
            },
            "GERI-CARE": {
              "NIGHT TIME PAIN MEDICINE EX ST": "NIGHT TIME PAIN MEDICINE EX ST"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "ADVIL PM": "ADVIL PM",
              "EXCEDRIN PM": "EXCEDRIN PM",
              "PANDOL PM EXTRA STRENGTH": "PANDOL PM EXTRA STRENGTH"
            },
            "KROGER COMPANY": {
              "IBUPROFEN PM": "IBUPROFEN PM"
            },
            "LEADER BRAND PRODUCTS": {
              "ACETAMINOPHEN PM": "ACETAMINOPHEN PM",
              "ACETAMINOPHEN PM EX ST": "ACETAMINOPHEN PM EX ST",
              "HEADACHE PM FORMULA": "HEADACHE PM FORMULA",
              "HEADACHE RELIEF PM": "HEADACHE RELIEF PM",
              "IBUPROFEN PM": "IBUPROFEN PM",
              "PAIN RELIEVER PM": "PAIN RELIEVER PM"
            },
            "MAGNO-HUMPHRIES LABORATORIES": {
              "PAIN RELIEVER PM": "PAIN RELIEVER PM"
            },
            "MAJOR PHARMACEUTICALS": {
              "ACETAMINOPHEN PM": "ACETAMINOPHEN PM",
              "MAPAP PM": "MAPAP PM"
            },
            "MATERNAL SCIENCE": {
              "HEALTHY MAMA EAZZZE THE PAIN": "HEALTHY MAMA EAZZZE THE PAIN"
            },
            "MCKESSON": {
              "HM ACETAMINOPHEN PM EX ST": "HM ACETAMINOPHEN PM EX ST",
              "HM IBUPROFEN PM": "HM IBUPROFEN PM",
              "HM PAIN RELIEVER PM EX ST": "HM PAIN RELIEVER PM EX ST"
            },
            "MCKESSON SUNMARK": {
              "SM HEADACHE RELIEF PM": "SM HEADACHE RELIEF PM",
              "SM IBUPROFEN PM": "SM IBUPROFEN PM",
              "SM PAIN RELIEVER PM EX ST": "SM PAIN RELIEVER PM EX ST"
            },
            "MCNEIL CONSUMER": {
              "MOTRIN PM": "MOTRIN PM",
              "TYLENOL PM EXTRA STRENGTH": "TYLENOL PM EXTRA STRENGTH"
            },
            "MEDICINE SHOPPE": {
              "IBUPROFEN PM": "IBUPROFEN PM",
              "MEDI-TABS PM EXTRA STRENGTH": "MEDI-TABS PM EXTRA STRENGTH"
            },
            "NEW WORLD IMPORTS": {
              "NON-ASPIRIN PM": "NON-ASPIRIN PM"
            },
            "PERRIGO PHARMACEUTICALS": {
              "GOODSENSE PAIN RELIEF PM EX ST": "GOODSENSE PAIN RELIEF PM EX ST"
            },
            "PHYSICIANS SCIENCE AND NATURE": {
              "ACETADRYL": "ACETADRYL"
            },
            "PLUS PHARMA": {
              "ACETAMINOPHEN PM EX ST": "ACETAMINOPHEN PM EX ST"
            },
            "PUBLIX SUPER MARKETS INC.": {
              "PX PAIN RELIEF PM EX ST": "PX PAIN RELIEF PM EX ST"
            },
            "RELIABLE 1 LABS": {
              "DIPHENHYDRAMINE-APAP (SLEEP)": "DIPHENHYDRAMINE-APAP (SLEEP)"
            },
            "RITE AID CORPORATION": {
              "RA ACETAMINOPHEN PM EX ST": "RA ACETAMINOPHEN PM EX ST",
              "RA IBUPROFEN PM": "RA IBUPROFEN PM",
              "RA NAPROXEN SODIUM PM": "RA NAPROXEN SODIUM PM",
              "RA PAIN RELIEVER PM": "RA PAIN RELIEVER PM"
            },
            "RUGBY LABORATORIES": {
              "DIPHENHYDRAMINE-APAP (SLEEP)": "DIPHENHYDRAMINE-APAP (SLEEP)"
            },
            "SELECT BRAND": {
              "SB NON-ASA NIGHT TIME": "SB NON-ASA NIGHT TIME",
              "SB NON-ASPIRIN NIGHTTIME": "SB NON-ASPIRIN NIGHTTIME",
              "SB PAIN RELIEVER PM": "SB PAIN RELIEVER PM"
            },
            "TARGET": {
              "TGT IBUPROFEN PM": "TGT IBUPROFEN PM",
              "TGT PAIN RELIEVER PM": "TGT PAIN RELIEVER PM"
            },
            "TOPCO": {
              "HEADACHE PM": "HEADACHE PM",
              "IBUPROFEN PM": "IBUPROFEN PM",
              "PAIN RELIEF PM EXTRA STRENGTH": "PAIN RELIEF PM EXTRA STRENGTH"
            },
            "WAL-MART": {
              "EQ ACETAMINOPHEN PM": "EQ ACETAMINOPHEN PM",
              "EQ IBUPROFEN PM": "EQ IBUPROFEN PM"
            },
            "WALGREENS": {
              "ACETAMINOPHEN PM EX ST": "ACETAMINOPHEN PM EX ST",
              "HEADACHE RELIEF PM": "HEADACHE RELIEF PM",
              "IBUPROFEN PM": "IBUPROFEN PM",
              "PAIN RELIEVER PM": "PAIN RELIEVER PM",
              "PAIN RELIEVER PM EX ST": "PAIN RELIEVER PM EX ST"
            }
          },
          "Antihistamine Hypnotics": {
            "AMERICAN SALES COMPANY": {
              "SLEEP-AID MAXIMUM STRENGTH": "SLEEP-AID MAXIMUM STRENGTH"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP SLEEP TIME": "GNP SLEEP TIME"
            },
            "BERGEN BRUNSWIG": {
              "GNP NIGHTTIME SLEEP AID": "GNP NIGHTTIME SLEEP AID",
              "GNP SLEEP AID": "GNP SLEEP AID"
            },
            "CHAIN DRUG CONSORTIUM": {
              "NIGHTTIME SLEEP AID": "NIGHTTIME SLEEP AID",
              "NYT-TIME SLEEP": "NYT-TIME SLEEP",
              "REST SIMPLY": "REST SIMPLY",
              "SLEEP AID (DIPHENHYDRAMINE)": "SLEEP AID (DIPHENHYDRAMINE)"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "NIGHT TIME SLEEP AID": "NIGHT TIME SLEEP AID",
              "QC E Z NITE SLEEP": "QC E Z NITE SLEEP",
              "QC REST SIMPLY": "QC REST SIMPLY",
              "QC SLEEP AID MAX ST": "QC SLEEP AID MAX ST",
              "QC SLEEP-AID MAX ST": "QC SLEEP-AID MAX ST"
            },
            "CHATTEM INC": {
              "UNISOM": "UNISOM",
              "UNISOM SLEEPGELS": "UNISOM SLEEPGELS",
              "UNISOM SLEEPMELTS": "UNISOM SLEEPMELTS",
              "UNISOM SLEEPTABS": "UNISOM SLEEPTABS"
            },
            "COSTCO WHOLESALE": {
              "KLS SLEEP AID": "KLS SLEEP AID"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS SLEEP AID": "CVS SLEEP AID",
              "CVS SLEEP AID NIGHTTIME": "CVS SLEEP AID NIGHTTIME",
              "CVS SLEEP-AID (DOXYLAMINE)": "CVS SLEEP-AID (DOXYLAMINE)",
              "CVS SLEEP-AID NIGHTTIME": "CVS SLEEP-AID NIGHTTIME",
              "CVS SLEEPAID (DIPHENHYDRAMINE)": "CVS SLEEPAID (DIPHENHYDRAMINE)",
              "CVS ULTRA SLEEP": "CVS ULTRA SLEEP"
            },
            "EQUALINE": {
              "EQL NIGHTTIME SLEEP AID": "EQL NIGHTTIME SLEEP AID",
              "EQL SLEEP AID": "EQL SLEEP AID"
            },
            "GEISS DESTIN & DUNN": {
              "GOODSENSE SLEEP AID": "GOODSENSE SLEEP AID",
              "SLEEP AID": "SLEEP AID"
            },
            "GERI-CARE": {
              "NIGHTTIME SLEEP AID": "NIGHTTIME SLEEP AID"
            },
            "LEADER BRAND PRODUCTS": {
              "RESTFULLY SLEEP": "RESTFULLY SLEEP",
              "SLEEP AID": "SLEEP AID",
              "SLEEP AID (DIPHENHYDRAMINE)": "SLEEP AID (DIPHENHYDRAMINE)",
              "SLEEP-AID": "SLEEP-AID"
            },
            "MAGNO-HUMPHRIES LABORATORIES": {
              "DIPHENHYDRAMINE HCL (SLEEP)": "DIPHENHYDRAMINE HCL (SLEEP)",
              "SLEEP-TABS": "SLEEP-TABS"
            },
            "MAJOR PHARMACEUTICALS": {
              "SLEEP TABS": "SLEEP TABS"
            },
            "MCKESSON": {
              "HM NIGHTTIME SLEEP AID": "HM NIGHTTIME SLEEP AID",
              "HM SLEEP AID": "HM SLEEP AID",
              "HM Z-SLEEP": "HM Z-SLEEP"
            },
            "MCKESSON SUNMARK": {
              "SM NIGHTTIME SLEEP AID": "SM NIGHTTIME SLEEP AID",
              "SM SLEEP AID": "SM SLEEP AID",
              "SM SLEEP AID MAXIMUM STRENGTH": "SM SLEEP AID MAXIMUM STRENGTH",
              "SM SLEEP AID NIGHT TIME": "SM SLEEP AID NIGHT TIME",
              "SM Z-SLEEP": "SM Z-SLEEP"
            },
            "MCNEIL CONSUMER": {
              "SIMPLY SLEEP": "SIMPLY SLEEP"
            },
            "MEDICINE SHOPPE": {
              "MEDI-SLEEP": "MEDI-SLEEP",
              "NIGHTTIME SLEEP": "NIGHTTIME SLEEP"
            },
            "MEDTECH": {
              "COMPOZ": "COMPOZ",
              "NYTOL": "NYTOL",
              "NYTOL MAXIMUM STRENGTH": "NYTOL MAXIMUM STRENGTH",
              "SOMINEX": "SOMINEX"
            },
            "P & G HEALTH": {
              "ZZZQUIL": "ZZZQUIL"
            },
            "PERRIGO": {
              "GOODSENSE SLEEPTIME": "GOODSENSE SLEEPTIME",
              "NIGHTTIME SLEEP AID": "NIGHTTIME SLEEP AID"
            },
            "REESE PHARMACEUTICAL": {
              "TETRA-FORMULA NIGHTTIME SLEEP": "TETRA-FORMULA NIGHTTIME SLEEP"
            },
            "RITE AID CORPORATION": {
              "RA NIGHT SLEEP AID": "RA NIGHT SLEEP AID",
              "RA NIGHTTIME SLEEP AID": "RA NIGHTTIME SLEEP AID",
              "RA SLEEP AID": "RA SLEEP AID",
              "RA SLEEP AID (DIPHENHYDRAMINE)": "RA SLEEP AID (DIPHENHYDRAMINE)",
              "RA SLEEP-AID NIGHTTIME": "RA SLEEP-AID NIGHTTIME"
            },
            "SELECT BRAND": {
              "SB SLEEP": "SB SLEEP"
            },
            "TARGET": {
              "TGT NIGHTTIME SLEEP AID": "TGT NIGHTTIME SLEEP AID",
              "TGT SLEEP AID MAX STRENGTH": "TGT SLEEP AID MAX STRENGTH"
            },
            "TEVA PHARMACEUTICALS USA": {
              "NITETIME SLEEP-AID": "NITETIME SLEEP-AID"
            },
            "TOPCO": {
              "NIGHTTIME SLEEP AID": "NIGHTTIME SLEEP AID",
              "SLEEP AID": "SLEEP AID"
            },
            "WAL-MART": {
              "EQ NIGHTTIME SLEEP AID": "EQ NIGHTTIME SLEEP AID",
              "EQ NIGHTTIME SLEEP AID MAX ST": "EQ NIGHTTIME SLEEP AID MAX ST",
              "EQ SLEEP-AID NIGHTTIME": "EQ SLEEP-AID NIGHTTIME"
            },
            "WALGREENS": {
              "NIGHTTIME SLEEP AID": "NIGHTTIME SLEEP AID",
              "SLEEP AID (DIPHENHYDRAMINE)": "SLEEP AID (DIPHENHYDRAMINE)",
              "SLEEP II": "SLEEP II",
              "WAL-SLEEP Z": "WAL-SLEEP Z",
              "WAL-SLEEP Z LIQUID SHOTS": "WAL-SLEEP Z LIQUID SHOTS",
              "WAL-SOM": "WAL-SOM",
              "WAL-SOM MAXIMUM STRENGTH": "WAL-SOM MAXIMUM STRENGTH"
            },
            "WEEKS & LEO": {
              "ORMIR": "ORMIR"
            }
          }
        },
        "Barbiturate Hypnotics": {
          "Barbiturate Hypnotics": {
            "AKORN": {
              "NEMBUTAL": "NEMBUTAL"
            },
            "AMERICAN HEALTH PACKAGING": {
              "PHENOBARBITAL": "PHENOBARBITAL"
            },
            "APHENA PHARMA SOLUTIONS": {
              "PHENOBARBITAL": "PHENOBARBITAL"
            },
            "ATLANTIC BIOLOGICALS": {
              "PHENOBARBITAL": "PHENOBARBITAL"
            },
            "AVPAK": {
              "PHENOBARBITAL": "PHENOBARBITAL"
            },
            "BAUSCH HEALTH": {
              "AMYTAL SODIUM": "AMYTAL SODIUM",
              "SECONAL": "SECONAL"
            },
            "BIORAMO": {
              "PHENOBARBITAL": "PHENOBARBITAL"
            },
            "BRYANT RANCH PREPACK": {
              "PHENOBARBITAL": "PHENOBARBITAL"
            },
            "CAMERON PHARMACEUTICALS": {
              "PHENOBARBITAL SODIUM": "PHENOBARBITAL SODIUM"
            },
            "DIRECT RX": {
              "PHENOBARBITAL": "PHENOBARBITAL"
            },
            "E5 PHARMA": {
              "PHENOBARBITAL": "PHENOBARBITAL"
            },
            "ECI PHARMACEUTICALS": {
              "PHENOBARBITAL": "PHENOBARBITAL"
            },
            "FAGRON": {
              "PHENOBARBITAL": "PHENOBARBITAL"
            },
            "FREEDOM PHARMACEUTICALS": {
              "PHENOBARBITAL": "PHENOBARBITAL"
            },
            "HIKMA": {
              "PHENOBARBITAL": "PHENOBARBITAL",
              "PHENOBARBITAL SODIUM": "PHENOBARBITAL SODIUM"
            },
            "HUMCO": {
              "PHENOBARBITAL": "PHENOBARBITAL"
            },
            "LEUCADIA PHARMACEUTICALS": {
              "PENTOBARBITAL SODIUM": "PENTOBARBITAL SODIUM"
            },
            "LIBERTY PHARMACEUTICAL": {
              "PHENOBARBITAL": "PHENOBARBITAL"
            },
            "MAJOR PHARMACEUTICALS": {
              "PHENOBARBITAL": "PHENOBARBITAL"
            },
            "MEDISCA": {
              "PHENOBARBITAL": "PHENOBARBITAL"
            },
            "MYLAN SPECIALTY L.P.": {
              "BUTISOL SODIUM": "BUTISOL SODIUM"
            },
            "NOVAPLUS / AKORN": {
              "NEMBUTAL": "NEMBUTAL"
            },
            "PACK PHARMACEUTICALS, LLC": {
              "PHENOBARBITAL": "PHENOBARBITAL"
            },
            "PAR PHARMACEUTICALS": {
              "PHENOBARBITAL": "PHENOBARBITAL"
            },
            "PCCA": {
              "PENTOBARBITAL SODIUM": "PENTOBARBITAL SODIUM",
              "PHENOBARBITAL": "PHENOBARBITAL"
            },
            "PDRX PHARMACEUTICAL": {
              "PHENOBARBITAL": "PHENOBARBITAL"
            },
            "PROFICIENT RX": {
              "PHENOBARBITAL": "PHENOBARBITAL"
            },
            "QUALITY CARE": {
              "PHENOBARBITAL": "PHENOBARBITAL"
            },
            "RISING PHARMACEUTICALS": {
              "PHENOBARBITAL": "PHENOBARBITAL"
            },
            "SAGENT PHARMACEUTICAL": {
              "PENTOBARBITAL SODIUM": "PENTOBARBITAL SODIUM"
            },
            "SKY PACKAGING": {
              "PHENOBARBITAL": "PHENOBARBITAL"
            },
            "SPECTRUM": {
              "PHENOBARBITAL": "PHENOBARBITAL",
              "PHENOBARBITAL SODIUM": "PHENOBARBITAL SODIUM"
            },
            "STERLING KNIGHT PHARMACEUTICAL": {
              "PHENOBARBITAL": "PHENOBARBITAL"
            },
            "WESTMINSTER PHARMACEUTICALS": {
              "PHENOBARBITAL": "PHENOBARBITAL"
            },
            "WINDER LABORATORIES": {
              "PHENOBARBITAL": "PHENOBARBITAL"
            }
          }
        },
        "Hypnotic Combinations": {
          "Hypnotic Combinations": {
            "IMPRIMIS NJOF": {
              "MIDAZOLAM-KETAMINE-ONDANSETRON": "MIDAZOLAM-KETAMINE-ONDANSETRON",
              "MKO MELT DOSE PACK": "MKO MELT DOSE PACK"
            }
          }
        },
        "Hypnotics - Tricyclic Agents": {
          "Hypnotics - Tricyclic Agents": {
            "ACTAVIS ELIZABETH": {
              "DOXEPIN HCL": "DOXEPIN HCL"
            },
            "CURRAX PHARMACEUTICALS": {
              "SILENOR": "SILENOR"
            },
            "MACOVEN PHARMACEUTICALS": {
              "DOXEPIN HCL": "DOXEPIN HCL"
            },
            "MYLAN": {
              "DOXEPIN HCL": "DOXEPIN HCL"
            }
          }
        },
        "Non-Barbiturate Hypnotics": {
          "Benzodiazepine Hypnotics": {
            "A-S MEDICATION SOLUTIONS": {
              "FLURAZEPAM HCL": "FLURAZEPAM HCL",
              "MIDAZOLAM HCL": "MIDAZOLAM HCL",
              "QUAZEPAM": "QUAZEPAM",
              "TEMAZEPAM": "TEMAZEPAM",
              "TRIAZOLAM": "TRIAZOLAM"
            },
            "ACTAVIS ELIZABETH": {
              "TEMAZEPAM": "TEMAZEPAM"
            },
            "ACTAVIS PHARMA": {
              "ESTAZOLAM": "ESTAZOLAM"
            },
            "AIDAREX PHARMACEUTICALS": {
              "ESTAZOLAM": "ESTAZOLAM",
              "FLURAZEPAM HCL": "FLURAZEPAM HCL",
              "QUAZEPAM": "QUAZEPAM",
              "TEMAZEPAM": "TEMAZEPAM",
              "TRIAZOLAM": "TRIAZOLAM"
            },
            "AKORN": {
              "MIDAZOLAM HCL": "MIDAZOLAM HCL"
            },
            "ALEMBIC PHARMACEUTICALS": {
              "TEMAZEPAM": "TEMAZEPAM"
            },
            "ALMAJECT": {
              "MIDAZOLAM HCL": "MIDAZOLAM HCL"
            },
            "ALTURA PHARMACEUTICALS": {
              "FLURAZEPAM HCL": "FLURAZEPAM HCL",
              "TEMAZEPAM": "TEMAZEPAM",
              "TRIAZOLAM": "TRIAZOLAM"
            },
            "ALVOGEN": {
              "MIDAZOLAM HCL": "MIDAZOLAM HCL"
            },
            "AMERICAN HEALTH PACKAGING": {
              "TEMAZEPAM": "TEMAZEPAM"
            },
            "AMNEAL PHARMACEUTICALS": {
              "TEMAZEPAM": "TEMAZEPAM"
            },
            "APHENA PHARMA SOLUTIONS": {
              "FLURAZEPAM HCL": "FLURAZEPAM HCL",
              "RESTORIL": "RESTORIL",
              "TEMAZEPAM": "TEMAZEPAM",
              "TRIAZOLAM": "TRIAZOLAM"
            },
            "ASCEND LABORATORIES": {
              "TEMAZEPAM": "TEMAZEPAM"
            },
            "ATHENEX PHARMACEUTICAL": {
              "MIDAZOLAM HCL": "MIDAZOLAM HCL"
            },
            "ATLAND PHARMACEUTICALS": {
              "QUAZEPAM": "QUAZEPAM"
            },
            "ATLANTIC BIOLOGICALS": {
              "MIDAZOLAM HCL": "MIDAZOLAM HCL"
            },
            "AVET PHARMACEUTICALS": {
              "MIDAZOLAM HCL": "MIDAZOLAM HCL"
            },
            "AVKARE": {
              "TEMAZEPAM": "TEMAZEPAM"
            },
            "BRYANT RANCH PREPACK": {
              "FLURAZEPAM HCL": "FLURAZEPAM HCL",
              "QUAZEPAM": "QUAZEPAM",
              "TEMAZEPAM": "TEMAZEPAM",
              "TRIAZOLAM": "TRIAZOLAM"
            },
            "CENTRAL ADMIXTURE PHARMACY SER": {
              "MIDAZOLAM HCL-SODIUM CHLORIDE": "MIDAZOLAM HCL-SODIUM CHLORIDE"
            },
            "CIVICA": {
              "MIDAZOLAM HCL": "MIDAZOLAM HCL"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "TEMAZEPAM": "TEMAZEPAM"
            },
            "DIRECT RX": {
              "TEMAZEPAM": "TEMAZEPAM"
            },
            "EXELA PHARMA SCIENCES": {
              "MIDAZOLAM HCL-SODIUM CHLORIDE": "MIDAZOLAM HCL-SODIUM CHLORIDE"
            },
            "FAGRON": {
              "MIDAZOLAM+SYRSPEND SF": "MIDAZOLAM+SYRSPEND SF"
            },
            "FAGRON COMPOUNDING SERVICES": {
              "MIDAZOLAM": "MIDAZOLAM"
            },
            "FRESENIUS KABI USA": {
              "MIDAZOLAM HCL": "MIDAZOLAM HCL",
              "MIDAZOLAM HCL (PF)": "MIDAZOLAM HCL (PF)"
            },
            "GALT PHARMACEUTICALS": {
              "DORAL": "DORAL"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "FLURAZEPAM HCL": "FLURAZEPAM HCL",
              "TEMAZEPAM": "TEMAZEPAM"
            },
            "GREENSTONE": {
              "TRIAZOLAM": "TRIAZOLAM"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "ESTAZOLAM": "ESTAZOLAM",
              "FLURAZEPAM HCL": "FLURAZEPAM HCL",
              "TEMAZEPAM": "TEMAZEPAM",
              "TRIAZOLAM": "TRIAZOLAM"
            },
            "HIKMA": {
              "MIDAZOLAM HCL": "MIDAZOLAM HCL",
              "TRIAZOLAM": "TRIAZOLAM"
            },
            "HOSPIRA": {
              "MIDAZOLAM HCL": "MIDAZOLAM HCL",
              "MIDAZOLAM HCL (PF)": "MIDAZOLAM HCL (PF)"
            },
            "KLE 2": {
              "QUAZEPAM": "QUAZEPAM"
            },
            "LEITERS HEALTH": {
              "MIDAZOLAM HCL": "MIDAZOLAM HCL"
            },
            "LIBERTY PHARMACEUTICAL": {
              "ESTAZOLAM": "ESTAZOLAM",
              "FLURAZEPAM HCL": "FLURAZEPAM HCL",
              "TEMAZEPAM": "TEMAZEPAM"
            },
            "MAJOR PHARMACEUTICALS": {
              "TEMAZEPAM": "TEMAZEPAM"
            },
            "MALLINCKRODT PHARM": {
              "TEMAZEPAM": "TEMAZEPAM"
            },
            "MAYNE PHARMA": {
              "ESTAZOLAM": "ESTAZOLAM"
            },
            "MEDVANTX": {
              "TEMAZEPAM": "TEMAZEPAM"
            },
            "MYLAN": {
              "FLURAZEPAM HCL": "FLURAZEPAM HCL",
              "TEMAZEPAM": "TEMAZEPAM"
            },
            "MYLAN INSTITUTIONAL": {
              "TEMAZEPAM": "TEMAZEPAM"
            },
            "NEPHRON STERILE COMPOUNDING": {
              "MIDAZOLAM": "MIDAZOLAM"
            },
            "NOVAPLUS/HIKMA": {
              "MIDAZOLAM HCL": "MIDAZOLAM HCL"
            },
            "NOVAPLUS/HOSPIRA": {
              "MIDAZOLAM HCL": "MIDAZOLAM HCL",
              "MIDAZOLAM HCL (PF)": "MIDAZOLAM HCL (PF)"
            },
            "NUCARE PHARMACEUTICALS": {
              "FLURAZEPAM HCL": "FLURAZEPAM HCL",
              "TEMAZEPAM": "TEMAZEPAM",
              "TRIAZOLAM": "TRIAZOLAM"
            },
            "PAR PHARMACEUTICALS": {
              "TEMAZEPAM": "TEMAZEPAM"
            },
            "PDRX PHARMACEUTICAL": {
              "FLURAZEPAM HCL": "FLURAZEPAM HCL",
              "TEMAZEPAM": "TEMAZEPAM",
              "TRIAZOLAM": "TRIAZOLAM"
            },
            "PERRIGO": {
              "MIDAZOLAM HCL": "MIDAZOLAM HCL"
            },
            "PFIZER U.S.": {
              "HALCION": "HALCION"
            },
            "PHARMEDIUM SERVICES": {
              "MIDAZOLAM HCL": "MIDAZOLAM HCL",
              "MIDAZOLAM HCL-DEXTROSE": "MIDAZOLAM HCL-DEXTROSE",
              "MIDAZOLAM HCL-NACL": "MIDAZOLAM HCL-NACL",
              "MIDAZOLAM HCL-SODIUM CHLORIDE": "MIDAZOLAM HCL-SODIUM CHLORIDE"
            },
            "PRECISION DOSE, INC": {
              "MIDAZOLAM HCL": "MIDAZOLAM HCL"
            },
            "PREFERRED PHARMACEUTICALS": {
              "TEMAZEPAM": "TEMAZEPAM"
            },
            "QUALITY CARE": {
              "ESTAZOLAM": "ESTAZOLAM",
              "FLURAZEPAM HCL": "FLURAZEPAM HCL",
              "HALCION": "HALCION",
              "MIDAZOLAM HCL": "MIDAZOLAM HCL",
              "TEMAZEPAM": "TEMAZEPAM",
              "TRIAZOLAM": "TRIAZOLAM"
            },
            "QUVA PHARMA": {
              "MIDAZOLAM HCL-SODIUM CHLORIDE": "MIDAZOLAM HCL-SODIUM CHLORIDE",
              "MIDAZOLAM-SODIUM CHLORIDE": "MIDAZOLAM-SODIUM CHLORIDE"
            },
            "RXCHANGE CO": {
              "FLURAZEPAM HCL": "FLURAZEPAM HCL",
              "TEMAZEPAM": "TEMAZEPAM",
              "TRIAZOLAM": "TRIAZOLAM"
            },
            "SAGENT PHARMACEUTICAL": {
              "MIDAZOLAM HCL": "MIDAZOLAM HCL"
            },
            "SANDOZ": {
              "TEMAZEPAM": "TEMAZEPAM"
            },
            "SCA PHARMACEUTICALS": {
              "MIDAZOLAM HCL-SODIUM CHLORIDE": "MIDAZOLAM HCL-SODIUM CHLORIDE"
            },
            "SKY PACKAGING": {
              "TEMAZEPAM": "TEMAZEPAM"
            },
            "SPECGX": {
              "RESTORIL": "RESTORIL",
              "TEMAZEPAM": "TEMAZEPAM"
            },
            "ST MARY'S MPP": {
              "TEMAZEPAM": "TEMAZEPAM",
              "TRIAZOLAM": "TRIAZOLAM"
            },
            "SUN PHARMACEUTICALS": {
              "MIDAZOLAM HCL": "MIDAZOLAM HCL",
              "TEMAZEPAM": "TEMAZEPAM"
            },
            "TEVA PHARMACEUTICALS USA": {
              "ESTAZOLAM": "ESTAZOLAM"
            },
            "THE MEDICINES COMPANY": {
              "MIDAZOLAM HCL": "MIDAZOLAM HCL"
            },
            "THOMPSON MEDICAL SOLUTIONS": {
              "QUAZEPAM": "QUAZEPAM"
            },
            "UNIT DOSE SERVICES": {
              "QUAZEPAM": "QUAZEPAM",
              "TEMAZEPAM": "TEMAZEPAM",
              "TRIAZOLAM": "TRIAZOLAM"
            }
          },
          "Non-Barbiturate Hypnotics": {
            "FAGRON": {
              "CHLORAL HYDRATE": "CHLORAL HYDRATE"
            },
            "MEDISCA": {
              "CHLORAL HYDRATE": "CHLORAL HYDRATE"
            },
            "PCCA": {
              "CHLORAL HYDRATE": "CHLORAL HYDRATE"
            }
          },
          "Non-Benzodiazepine - GABA-Receptor Modulators": {
            "A-S MEDICATION SOLUTIONS": {
              "AMBIEN": "AMBIEN",
              "AMBIEN CR": "AMBIEN CR",
              "ESZOPICLONE": "ESZOPICLONE",
              "LUNESTA": "LUNESTA",
              "SONATA": "SONATA",
              "ZALEPLON": "ZALEPLON",
              "ZOLPIDEM TARTRATE": "ZOLPIDEM TARTRATE",
              "ZOLPIDEM TARTRATE ER": "ZOLPIDEM TARTRATE ER"
            },
            "ACI HEALTHCARE USA": {
              "ZOLPIDEM TARTRATE": "ZOLPIDEM TARTRATE"
            },
            "ACTAVIS ELIZABETH": {
              "ZOLPIDEM TARTRATE ER": "ZOLPIDEM TARTRATE ER"
            },
            "AIDAREX PHARMACEUTICALS": {
              "AMBIEN CR": "AMBIEN CR",
              "ESZOPICLONE": "ESZOPICLONE",
              "ZALEPLON": "ZALEPLON",
              "ZOLPIDEM TARTRATE": "ZOLPIDEM TARTRATE"
            },
            "ALTURA PHARMACEUTICALS": {
              "AMBIEN": "AMBIEN",
              "SONATA": "SONATA",
              "ZOLPIDEM TARTRATE": "ZOLPIDEM TARTRATE"
            },
            "AMERICAN HEALTH PACKAGING": {
              "ZOLPIDEM TARTRATE": "ZOLPIDEM TARTRATE",
              "ZOLPIDEM TARTRATE ER": "ZOLPIDEM TARTRATE ER"
            },
            "APHENA PHARMA SOLUTIONS": {
              "AMBIEN": "AMBIEN",
              "AMBIEN CR": "AMBIEN CR",
              "ESZOPICLONE": "ESZOPICLONE",
              "LUNESTA": "LUNESTA",
              "ZOLPIDEM TARTRATE": "ZOLPIDEM TARTRATE",
              "ZOLPIDEM TARTRATE ER": "ZOLPIDEM TARTRATE ER"
            },
            "APOTHECA": {
              "ZOLPIDEM TARTRATE": "ZOLPIDEM TARTRATE"
            },
            "AUROBINDO PHARMA": {
              "ESZOPICLONE": "ESZOPICLONE",
              "ZALEPLON": "ZALEPLON",
              "ZOLPIDEM TARTRATE": "ZOLPIDEM TARTRATE"
            },
            "AVKARE": {
              "ESZOPICLONE": "ESZOPICLONE",
              "ZOLPIDEM TARTRATE": "ZOLPIDEM TARTRATE"
            },
            "AYTU BIOSCIENCE": {
              "ZOLPIMIST": "ZOLPIMIST"
            },
            "BAUSCH HEALTH": {
              "ZOLPIMIST": "ZOLPIMIST"
            },
            "BLENHEIM PHARMACAL": {
              "ZOLPIDEM TARTRATE": "ZOLPIDEM TARTRATE"
            },
            "BRYANT RANCH PREPACK": {
              "AMBIEN CR": "AMBIEN CR",
              "ESZOPICLONE": "ESZOPICLONE",
              "LUNESTA": "LUNESTA",
              "ZALEPLON": "ZALEPLON",
              "ZOLPIDEM TARTRATE": "ZOLPIDEM TARTRATE",
              "ZOLPIDEM TARTRATE ER": "ZOLPIDEM TARTRATE ER"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "ZOLPIDEM TARTRATE": "ZOLPIDEM TARTRATE",
              "ZOLPIDEM TARTRATE ER": "ZOLPIDEM TARTRATE ER"
            },
            "DIRECT RX": {
              "ESZOPICLONE": "ESZOPICLONE",
              "ZALEPLON": "ZALEPLON",
              "ZOLPIDEM TARTRATE": "ZOLPIDEM TARTRATE",
              "ZOLPIDEM TARTRATE ER": "ZOLPIDEM TARTRATE ER"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "ESZOPICLONE": "ESZOPICLONE",
              "ZOLPIDEM TARTRATE": "ZOLPIDEM TARTRATE"
            },
            "ENOVACHEM MANUFACTURING": {
              "ESZOPICLONE": "ESZOPICLONE"
            },
            "GLENMARK PHARMACEUTICALS": {
              "ESZOPICLONE": "ESZOPICLONE"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "ESZOPICLONE": "ESZOPICLONE",
              "ZOLPIDEM TARTRATE ER": "ZOLPIDEM TARTRATE ER"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "AMBIEN": "AMBIEN",
              "AMBIEN CR": "AMBIEN CR",
              "LUNESTA": "LUNESTA",
              "SONATA": "SONATA",
              "ZALEPLON": "ZALEPLON",
              "ZOLPIDEM TARTRATE": "ZOLPIDEM TARTRATE"
            },
            "HIKMA": {
              "ESZOPICLONE": "ESZOPICLONE",
              "ZALEPLON": "ZALEPLON"
            },
            "KAISER FOUNDATION HOSPITAL": {
              "ZOLPIDEM TARTRATE": "ZOLPIDEM TARTRATE"
            },
            "LIBERTY PHARMACEUTICAL": {
              "LUNESTA": "LUNESTA",
              "ZOLPIDEM TARTRATE": "ZOLPIDEM TARTRATE"
            },
            "LUPIN PHARMACEUTICALS": {
              "ESZOPICLONE": "ESZOPICLONE",
              "ZOLPIDEM TARTRATE": "ZOLPIDEM TARTRATE",
              "ZOLPIDEM TARTRATE ER": "ZOLPIDEM TARTRATE ER"
            },
            "MACLEODS PHARMACEUTICALS": {
              "ESZOPICLONE": "ESZOPICLONE"
            },
            "MAJOR PHARMACEUTICALS": {
              "ZOLPIDEM TARTRATE": "ZOLPIDEM TARTRATE"
            },
            "MEDVANTX": {
              "AMBIEN": "AMBIEN"
            },
            "MYLAN": {
              "ESZOPICLONE": "ESZOPICLONE",
              "ZALEPLON": "ZALEPLON",
              "ZOLPIDEM TARTRATE": "ZOLPIDEM TARTRATE"
            },
            "MYLAN INSTITUTIONAL": {
              "ESZOPICLONE": "ESZOPICLONE",
              "ZOLPIDEM TARTRATE": "ZOLPIDEM TARTRATE"
            },
            "MYLAN SPECIALTY L.P.": {
              "EDLUAR": "EDLUAR"
            },
            "NORTHSTAR RX": {
              "ZALEPLON": "ZALEPLON",
              "ZOLPIDEM TARTRATE": "ZOLPIDEM TARTRATE"
            },
            "NUCARE PHARMACEUTICALS": {
              "AMBIEN": "AMBIEN",
              "AMBIEN CR": "AMBIEN CR",
              "LUNESTA": "LUNESTA",
              "SONATA": "SONATA",
              "ZALEPLON": "ZALEPLON",
              "ZOLPIDEM TARTRATE": "ZOLPIDEM TARTRATE"
            },
            "ORCHIDPHARMA": {
              "ESZOPICLONE": "ESZOPICLONE",
              "ZALEPLON": "ZALEPLON"
            },
            "PAR PHARMACEUTICAL": {
              "ZOLPIDEM TARTRATE": "ZOLPIDEM TARTRATE",
              "ZOLPIDEM TARTRATE ER": "ZOLPIDEM TARTRATE ER"
            },
            "PAR PHARMACEUTICALS": {
              "ZOLPIDEM TARTRATE": "ZOLPIDEM TARTRATE"
            },
            "PDRX PHARMACEUTICAL": {
              "AMBIEN": "AMBIEN",
              "AMBIEN CR": "AMBIEN CR",
              "ESZOPICLONE": "ESZOPICLONE",
              "LUNESTA": "LUNESTA",
              "ZALEPLON": "ZALEPLON",
              "ZOLPIDEM TARTRATE": "ZOLPIDEM TARTRATE"
            },
            "PDRX PHARMACEUTICALS": {
              "ESZOPICLONE": "ESZOPICLONE"
            },
            "PFIZER U.S.": {
              "SONATA": "SONATA"
            },
            "PHARMEDIX": {
              "ZALEPLON": "ZALEPLON",
              "ZOLPIDEM TARTRATE": "ZOLPIDEM TARTRATE"
            },
            "PREFERRED PHARMACEUTICALS": {
              "ESZOPICLONE": "ESZOPICLONE",
              "ZALEPLON": "ZALEPLON",
              "ZOLPIDEM TARTRATE": "ZOLPIDEM TARTRATE",
              "ZOLPIDEM TARTRATE ER": "ZOLPIDEM TARTRATE ER"
            },
            "PROFICIENT RX": {
              "ESZOPICLONE": "ESZOPICLONE",
              "ZOLPIDEM TARTRATE": "ZOLPIDEM TARTRATE",
              "ZOLPIDEM TARTRATE ER": "ZOLPIDEM TARTRATE ER"
            },
            "PURDUE PHARMA L.P.": {
              "INTERMEZZO": "INTERMEZZO"
            },
            "QUALITY CARE": {
              "AMBIEN": "AMBIEN",
              "AMBIEN CR": "AMBIEN CR",
              "LUNESTA": "LUNESTA",
              "SONATA": "SONATA",
              "ZALEPLON": "ZALEPLON",
              "ZOLPIDEM TARTRATE": "ZOLPIDEM TARTRATE",
              "ZOLPIDEM TARTRATE ER": "ZOLPIDEM TARTRATE ER"
            },
            "QUALITY CARE PRODUCTS": {
              "ESZOPICLONE": "ESZOPICLONE",
              "ZALEPLON": "ZALEPLON",
              "ZOLPIDEM TARTRATE": "ZOLPIDEM TARTRATE",
              "ZOLPIDEM TARTRATE ER": "ZOLPIDEM TARTRATE ER"
            },
            "RISING PHARMACEUTICALS": {
              "ZALEPLON": "ZALEPLON"
            },
            "RXCHANGE CO": {
              "LUNESTA": "LUNESTA",
              "SONATA": "SONATA",
              "ZOLPIDEM TARTRATE": "ZOLPIDEM TARTRATE"
            },
            "SANDOZ": {
              "ZOLPIDEM TARTRATE": "ZOLPIDEM TARTRATE",
              "ZOLPIDEM TARTRATE ER": "ZOLPIDEM TARTRATE ER"
            },
            "SANOFI-AVENTIS U.S.": {
              "AMBIEN": "AMBIEN",
              "AMBIEN CR": "AMBIEN CR"
            },
            "SKY PACKAGING": {
              "ZOLPIDEM TARTRATE": "ZOLPIDEM TARTRATE"
            },
            "ST MARY'S MPP": {
              "AMBIEN CR": "AMBIEN CR",
              "ESZOPICLONE": "ESZOPICLONE",
              "LUNESTA": "LUNESTA",
              "ZALEPLON": "ZALEPLON",
              "ZOLPIDEM TARTRATE": "ZOLPIDEM TARTRATE",
              "ZOLPIDEM TARTRATE ER": "ZOLPIDEM TARTRATE ER"
            },
            "SUN PHARMACEUTICALS": {
              "ESZOPICLONE": "ESZOPICLONE",
              "ZOLPIDEM TARTRATE ER": "ZOLPIDEM TARTRATE ER"
            },
            "SUNOVION PHARMACEUTICALS": {
              "LUNESTA": "LUNESTA"
            },
            "TEVA PHARMACEUTICALS USA": {
              "ESZOPICLONE": "ESZOPICLONE",
              "ZALEPLON": "ZALEPLON",
              "ZOLPIDEM TARTRATE": "ZOLPIDEM TARTRATE"
            },
            "TORRENT PHARMACEUTICALS": {
              "ZOLPIDEM TARTRATE": "ZOLPIDEM TARTRATE"
            },
            "UNICHEM PHARMACEUTICALS": {
              "ZALEPLON": "ZALEPLON"
            },
            "UNIT DOSE SERVICES": {
              "ESZOPICLONE": "ESZOPICLONE",
              "LUNESTA": "LUNESTA",
              "ZALEPLON": "ZALEPLON",
              "ZOLPIDEM TARTRATE": "ZOLPIDEM TARTRATE",
              "ZOLPIDEM TARTRATE ER": "ZOLPIDEM TARTRATE ER"
            },
            "WINTHROP, US": {
              "ZOLPIDEM TARTRATE ER": "ZOLPIDEM TARTRATE ER"
            }
          },
          "Selective Alpha2-Adrenoreceptor Agonist Sedatives": {
            "ACCORD HEALTHCARE": {
              "DEXMEDETOMIDINE HCL": "DEXMEDETOMIDINE HCL"
            },
            "ACTAVIS": {
              "DEXMEDETOMIDINE HCL": "DEXMEDETOMIDINE HCL"
            },
            "AKORN": {
              "DEXMEDETOMIDINE HCL": "DEXMEDETOMIDINE HCL"
            },
            "AMNEAL BIOSCIENCES": {
              "DEXMEDETOMIDINE HCL IN NACL": "DEXMEDETOMIDINE HCL IN NACL"
            },
            "ATHENEX PHARMACEUTICAL": {
              "DEXMEDETOMIDINE HCL": "DEXMEDETOMIDINE HCL"
            },
            "AUROMEDICS PHARMA": {
              "DEXMEDETOMIDINE HCL": "DEXMEDETOMIDINE HCL"
            },
            "BAXTER HEALTHCARE CORP": {
              "DEXMEDETOMIDINE HCL IN NACL": "DEXMEDETOMIDINE HCL IN NACL"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "DEXMEDETOMIDINE HCL IN NACL": "DEXMEDETOMIDINE HCL IN NACL"
            },
            "FRESENIUS KABI USA": {
              "DEXMEDETOMIDINE HCL": "DEXMEDETOMIDINE HCL",
              "DEXMEDETOMIDINE HCL IN NACL": "DEXMEDETOMIDINE HCL IN NACL"
            },
            "HIKMA": {
              "DEXMEDETOMIDINE HCL": "DEXMEDETOMIDINE HCL"
            },
            "HOSPIRA": {
              "PRECEDEX": "PRECEDEX"
            },
            "MYLAN INSTITUTIONAL": {
              "DEXMEDETOMIDINE HCL": "DEXMEDETOMIDINE HCL"
            },
            "NOVAPLUS/ACCORD HEALTHCARE": {
              "DEXMEDETOMIDINE HCL": "DEXMEDETOMIDINE HCL"
            },
            "NOVAPLUS/BAXTER HEALTHCARE": {
              "DEXMEDETOMIDINE HCL IN NACL": "DEXMEDETOMIDINE HCL IN NACL"
            },
            "NOVAPLUS/MYLAN INSTITUTIONAL": {
              "DEXMEDETOMIDINE HCL": "DEXMEDETOMIDINE HCL"
            },
            "PAR STERILE PRODUCTS": {
              "DEXMEDETOMIDINE HCL": "DEXMEDETOMIDINE HCL",
              "DEXMEDETOMIDINE HCL IN NACL": "DEXMEDETOMIDINE HCL IN NACL"
            },
            "SANDOZ": {
              "DEXMEDETOMIDINE HCL": "DEXMEDETOMIDINE HCL",
              "DEXMEDETOMIDINE HCL IN NACL": "DEXMEDETOMIDINE HCL IN NACL"
            },
            "SLAYBACK PHARMA": {
              "DEXMEDETOMIDINE HCL IN NACL": "DEXMEDETOMIDINE HCL IN NACL"
            },
            "SUN PHARMACEUTICALS": {
              "DEXMEDETOMIDINE HCL": "DEXMEDETOMIDINE HCL"
            },
            "WG CRITICAL CARE": {
              "DEXMEDETOMIDINE HCL": "DEXMEDETOMIDINE HCL",
              "DEXMEDETOMIDINE HCL-DEXTROSE": "DEXMEDETOMIDINE HCL-DEXTROSE"
            }
          }
        },
        "Orexin Receptor Antagonists": {
          "Orexin Receptor Antagonists": {
            "EISAI": {
              "DAYVIGO": "DAYVIGO"
            },
            "MERCK SHARP & DOHME": {
              "BELSOMRA": "BELSOMRA"
            }
          }
        },
        "Selective Melatonin Receptor Agonists": {
          "Selective Melatonin Receptor Agonists": {
            "A-S MEDICATION SOLUTIONS": {
              "ROZEREM": "ROZEREM"
            },
            "ACTAVIS PHARMA": {
              "RAMELTEON": "RAMELTEON"
            },
            "BRYANT RANCH PREPACK": {
              "ROZEREM": "ROZEREM"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "RAMELTEON": "RAMELTEON"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "RAMELTEON": "RAMELTEON"
            },
            "GRANULES PHARMACEUTICALS": {
              "RAMELTEON": "RAMELTEON"
            },
            "NUCARE PHARMACEUTICALS": {
              "ROZEREM": "ROZEREM"
            },
            "QUALITY CARE": {
              "ROZEREM": "ROZEREM"
            },
            "QUALITY CARE PRODUCTS": {
              "RAMELTEON": "RAMELTEON"
            },
            "TAKEDA PHARMACEUTICALS": {
              "ROZEREM": "ROZEREM"
            },
            "TRUPHARMA": {
              "RAMELTEON": "RAMELTEON"
            },
            "VANDA PHARMACEUTICALS": {
              "HETLIOZ": "HETLIOZ"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "RAMELTEON": "RAMELTEON"
            }
          }
        }
      },
      "LAXATIVES": {
        "Bulk Laxatives": {
          "Bulk Laxatives": {
            "21ST CENTURY HEALTHCARE": {
              "PSYLLIUM FIBER": "PSYLLIUM FIBER"
            },
            "A-S MEDICATION SOLUTIONS": {
              "REGULOID": "REGULOID"
            },
            "ADVANTICE HEALTH": {
              "FIBER CHOICE": "FIBER CHOICE",
              "FIBER CHOICE FRUITY BITES": "FIBER CHOICE FRUITY BITES",
              "FIBER CHOICE PREBIOTIC FIBER": "FIBER CHOICE PREBIOTIC FIBER"
            },
            "AMERICAN HEALTH PACKAGING": {
              "FIBER-CAPS": "FIBER-CAPS",
              "NATURAL FIBER": "NATURAL FIBER"
            },
            "AMERICAN SALES COMPANY": {
              "EASY FIBER": "EASY FIBER",
              "FIBER THERAPY": "FIBER THERAPY"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP BEST FIBER": "GNP BEST FIBER",
              "GNP NATURAL FIBER": "GNP NATURAL FIBER"
            },
            "AURORA HEALTHCARE": {
              "FIBER": "FIBER",
              "FIBER LAXATIVE": "FIBER LAXATIVE",
              "NATURAL VEGETABLE FIBER": "NATURAL VEGETABLE FIBER"
            },
            "BAYER CONSUMER": {
              "MIRAFIBER": "MIRAFIBER",
              "PHILLIPS FIBER GOOD": "PHILLIPS FIBER GOOD"
            },
            "BERGEN BRUNSWIG": {
              "GNP FIBER THERAPY": "GNP FIBER THERAPY",
              "GNP FIBER-CAPS": "GNP FIBER-CAPS",
              "GNP NATURAL FIBER": "GNP NATURAL FIBER"
            },
            "BIO-TECH": {
              "EVAC": "EVAC"
            },
            "CHAIN DRUG CONSORTIUM": {
              "FIBER": "FIBER",
              "FIBER (GUAR GUM)": "FIBER (GUAR GUM)",
              "FIBER LAXATIVE": "FIBER LAXATIVE",
              "FIBER THERAPY": "FIBER THERAPY",
              "NATURAL FIBER LAXATIVE": "NATURAL FIBER LAXATIVE"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "FIBER LAXATIVE": "FIBER LAXATIVE",
              "QC FIBER LAXATIVE": "QC FIBER LAXATIVE",
              "QC FIBER THERAPY": "QC FIBER THERAPY",
              "QC NATURAL VEGETABLE": "QC NATURAL VEGETABLE"
            },
            "CONTRACT PHARMACAL CORPORATION": {
              "PSYLDEX": "PSYLDEX"
            },
            "COSTCO WHOLESALE": {
              "KLS FIBER-TABS": "KLS FIBER-TABS",
              "KLS NATURAL PSYLLIUM FIBER": "KLS NATURAL PSYLLIUM FIBER"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS DAILY FIBER": "CVS DAILY FIBER",
              "CVS EASY FIBER": "CVS EASY FIBER",
              "CVS FIBER": "CVS FIBER",
              "CVS FIBER GUMMIES": "CVS FIBER GUMMIES",
              "CVS FIBER GUMMY BEARS CHILDREN": "CVS FIBER GUMMY BEARS CHILDREN",
              "CVS FIBER LAXATIVE": "CVS FIBER LAXATIVE",
              "CVS NATURAL DAILY FIBER": "CVS NATURAL DAILY FIBER",
              "CVS NATURAL FIBER SUPPLEMENT": "CVS NATURAL FIBER SUPPLEMENT",
              "CVS SOLUBLE FIBER THERAPY": "CVS SOLUBLE FIBER THERAPY",
              "CVS YOGURT + FIBER GUMMIES": "CVS YOGURT + FIBER GUMMIES"
            },
            "DOMEL LABORATORIES": {
              "PROFIBER": "PROFIBER"
            },
            "DSE HEALTHCARE SOLUTIONS": {
              "EQUALACTIN": "EQUALACTIN",
              "HYDROCIL": "HYDROCIL"
            },
            "EQUALINE": {
              "EQL FIBER LAXATIVE": "EQL FIBER LAXATIVE",
              "EQL FIBER SUPPLEMENT": "EQL FIBER SUPPLEMENT",
              "EQL FIBER THERAPY": "EQL FIBER THERAPY",
              "EQL NATURAL FIBER": "EQL NATURAL FIBER",
              "EQL SMOOTH TEXTURE FIBER": "EQL SMOOTH TEXTURE FIBER"
            },
            "FLEET PHARMACEUTICALS": {
              "PEDIA-LAX FIBER GUMMIES": "PEDIA-LAX FIBER GUMMIES"
            },
            "FREEDA VITAMINS": {
              "NATURAL PSYLLIUM SEED": "NATURAL PSYLLIUM SEED"
            },
            "GEISS DESTIN & DUNN": {
              "GOODSENSE BEST FIBER": "GOODSENSE BEST FIBER",
              "GOODSENSE FIBER": "GOODSENSE FIBER",
              "GOODSENSE NATURAL FIBER": "GOODSENSE NATURAL FIBER",
              "GOODSENSE PSYLLIUM FIBER": "GOODSENSE PSYLLIUM FIBER"
            },
            "GENDOSE PHARMACEUTICALS": {
              "FIBER LAXATIVE": "FIBER LAXATIVE"
            },
            "GERI-CARE": {
              "FIBER LAXATIVE": "FIBER LAXATIVE",
              "GERI-MUCIL": "GERI-MUCIL"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "BENEFIBER": "BENEFIBER",
              "BENEFIBER DRINK MIX": "BENEFIBER DRINK MIX",
              "BENEFIBER FOR CHILDREN": "BENEFIBER FOR CHILDREN",
              "BENEFIBER ON THE GO": "BENEFIBER ON THE GO",
              "CITRUCEL": "CITRUCEL",
              "FIBERCON": "FIBERCON"
            },
            "GLOBAL HEALTH INC": {
              "LIQUAFIBER": "LIQUAFIBER"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "NATURAL FIBER THERAPY": "NATURAL FIBER THERAPY"
            },
            "IM HEALTHSCIENCE": {
              "FIBER CHOICE PREBIOTIC FIBER": "FIBER CHOICE PREBIOTIC FIBER"
            },
            "INTEGRATIVE THERAPEUTICS": {
              "FIBER FORMULA": "FIBER FORMULA"
            },
            "ION LABS": {
              "SORBULAX": "SORBULAX"
            },
            "KONSYL PHARMACEUTICAL": {
              "KONSYL": "KONSYL",
              "KONSYL DAILY FIBER": "KONSYL DAILY FIBER",
              "KONSYL FIBER": "KONSYL FIBER",
              "KONSYL ORIGINAL DAILY FIBER": "KONSYL ORIGINAL DAILY FIBER",
              "KONSYL ORIGINAL FORMULA": "KONSYL ORIGINAL FORMULA",
              "KONSYL-D": "KONSYL-D"
            },
            "KROGER COMPANY": {
              "FIBER LAXATIVE": "FIBER LAXATIVE"
            },
            "LEADER BRAND PRODUCTS": {
              "CLEAR FIBER POWDER": "CLEAR FIBER POWDER",
              "DAILY FIBER": "DAILY FIBER",
              "FIBER": "FIBER",
              "FIBER LAXATIVE": "FIBER LAXATIVE",
              "NATURAL FIBER": "NATURAL FIBER",
              "NATURAL FIBER LAXATIVE": "NATURAL FIBER LAXATIVE"
            },
            "LLORENS PHARMACEUTICAL": {
              "FIBEREX F15": "FIBEREX F15"
            },
            "MAGNO-HUMPHRIES LABORATORIES": {
              "BRAN": "BRAN",
              "FIBER DIET": "FIBER DIET"
            },
            "MAJOR PHARMACEUTICALS": {
              "FIBER": "FIBER",
              "FIBER THERAPY": "FIBER THERAPY",
              "NATURAL FIBER THERAPY": "NATURAL FIBER THERAPY",
              "REGULOID": "REGULOID",
              "SOLUBLE FIBER THERAPY": "SOLUBLE FIBER THERAPY"
            },
            "MARLEX PHARMACEUTICALS": {
              "FIBER": "FIBER"
            },
            "MASON VITAMINS": {
              "FIBER COMPLETE": "FIBER COMPLETE"
            },
            "MCKESSON": {
              "HM CLEAR FIBER": "HM CLEAR FIBER",
              "HM FIBER": "HM FIBER",
              "HM FIBER POWDER": "HM FIBER POWDER",
              "HM TRUEPLUS FIBER": "HM TRUEPLUS FIBER"
            },
            "MCKESSON SUNMARK": {
              "SM FIBER": "SM FIBER",
              "SM FIBER LAXATIVE": "SM FIBER LAXATIVE",
              "SM FIBER POWDER": "SM FIBER POWDER"
            },
            "MEDA CONSUMER HEALTHCARE": {
              "UNIFIBER": "UNIFIBER"
            },
            "MEDICINE SHOPPE": {
              "FIBER": "FIBER",
              "MEDI-MUCIL": "MEDI-MUCIL",
              "NATURAL FIBER LAXATIVE": "NATURAL FIBER LAXATIVE"
            },
            "MEDTECH": {
              "FIBERCHOICE": "FIBERCHOICE",
              "LITTLE TUMMYS FIBER GUMMIES": "LITTLE TUMMYS FIBER GUMMIES"
            },
            "MEDTRITION NATIONAL NUTRITION": {
              "HYFIBER WITH FOS": "HYFIBER WITH FOS"
            },
            "MEIJER": {
              "NATURAL FIBER LAXATIVE": "NATURAL FIBER LAXATIVE"
            },
            "NAT-RUL HEALTH PRODUCTS": {
              "BRAN FIBER": "BRAN FIBER",
              "NAT-RUL PSYLLIUM SEED HUSKS": "NAT-RUL PSYLLIUM SEED HUSKS",
              "NATRUL COLON CARE": "NATRUL COLON CARE"
            },
            "NEIL LABS": {
              "FIBERGEN": "FIBERGEN"
            },
            "NESTLE HEALTHCARE NUTRITION": {
              "NUTRISOURCE FIBER": "NUTRISOURCE FIBER"
            },
            "P & G HEALTH": {
              "METAMUCIL": "METAMUCIL",
              "METAMUCIL FIBER": "METAMUCIL FIBER",
              "METAMUCIL FREE & NATURAL": "METAMUCIL FREE & NATURAL",
              "METAMUCIL MULTIHEALTH FIBER": "METAMUCIL MULTIHEALTH FIBER",
              "METAMUCIL SMOOTH TEXTURE": "METAMUCIL SMOOTH TEXTURE"
            },
            "PARADIGM PHARMACEUTICALS": {
              "MUCILIN": "MUCILIN",
              "MUCILIN SF": "MUCILIN SF"
            },
            "PDRX PHARMACEUTICAL": {
              "FIBER-LAX": "FIBER-LAX"
            },
            "PERRIGO PHARMACEUTICALS": {
              "FIBER LAXATIVE": "FIBER LAXATIVE"
            },
            "PHARMAVITE": {
              "FIBER ADULT GUMMIES": "FIBER ADULT GUMMIES"
            },
            "PREFERRED PHARMACEUTICALS": {
              "REGULOID": "REGULOID"
            },
            "PUBLIX SUPER MARKETS INC.": {
              "PX FIBER": "PX FIBER"
            },
            "RELIABLE 1 LABS": {
              "CALCIUM POLYCARBOPHIL": "CALCIUM POLYCARBOPHIL",
              "PSYLLIUM": "PSYLLIUM"
            },
            "REXALL SUNDOWN": {
              "INULIN FIBER PREBIOTIC": "INULIN FIBER PREBIOTIC",
              "PSYLLIUM HUSK": "PSYLLIUM HUSK"
            },
            "RITE AID CORPORATION": {
              "RA FIBER": "RA FIBER",
              "RA FIBER LAXATIVE": "RA FIBER LAXATIVE",
              "RA FIBER SUPPLEMENT": "RA FIBER SUPPLEMENT",
              "RA FIBER THERAPY": "RA FIBER THERAPY",
              "RA FIBER-CAP": "RA FIBER-CAP",
              "RA FIBER-TAB": "RA FIBER-TAB",
              "RA MULTIHEALTH FIBER": "RA MULTIHEALTH FIBER",
              "RA SOLUBLE FIBER": "RA SOLUBLE FIBER"
            },
            "RUGBY LABORATORIES": {
              "FIBER-LAX": "FIBER-LAX",
              "REGULOID": "REGULOID"
            },
            "SELECT BRAND": {
              "SB FIB LAX ORANGE": "SB FIB LAX ORANGE",
              "SB FIBER LAXATIVE": "SB FIBER LAXATIVE",
              "SB NATURAL FIBER LAXATIVE": "SB NATURAL FIBER LAXATIVE"
            },
            "SOLACE NUTRITION": {
              "SOLFIBER": "SOLFIBER"
            },
            "TARGET": {
              "TGT FIBER LAXATIVE": "TGT FIBER LAXATIVE",
              "TGT FIBER THERAPY": "TGT FIBER THERAPY",
              "TGT PSYLLIUM FIBER": "TGT PSYLLIUM FIBER"
            },
            "TOPCO": {
              "FIBER LAXATIVE": "FIBER LAXATIVE",
              "TOTAL FIBER": "TOTAL FIBER"
            },
            "WAL-MART": {
              "EQ DAILY FIBER": "EQ DAILY FIBER",
              "EQ FIBER LAXATIVE": "EQ FIBER LAXATIVE",
              "EQ FIBER POWDER": "EQ FIBER POWDER",
              "EQ FIBER SUPPLEMENT": "EQ FIBER SUPPLEMENT",
              "EQ FIBER THERAPY": "EQ FIBER THERAPY",
              "EQ NATURAL FIBER LAXATIVE": "EQ NATURAL FIBER LAXATIVE"
            },
            "WALGREENS": {
              "ADVANCED FIBER COMPLEX": "ADVANCED FIBER COMPLEX",
              "CLEAR SOLUBLE FIBER": "CLEAR SOLUBLE FIBER",
              "FIBER (CORN DEXTRIN)": "FIBER (CORN DEXTRIN)",
              "FIBER SELECT GUMMIES": "FIBER SELECT GUMMIES",
              "FIBER THERAPY": "FIBER THERAPY",
              "FIBER-LAX": "FIBER-LAX",
              "NATURAL FIBER": "NATURAL FIBER",
              "WAL-MUCIL": "WAL-MUCIL"
            }
          }
        },
        "Laxative Combinations": {
          "Bowel Evacuant Combinations": {
            "ACCELIS PHARMA": {
              "PEG-PREP": "PEG-PREP"
            },
            "AFFORDABLE PHARMACEUTICALS": {
              "PEG 3350-KCL-NA BICARB-NACL": "PEG 3350-KCL-NA BICARB-NACL",
              "PEG-3350/ELECTROLYTES": "PEG-3350/ELECTROLYTES"
            },
            "BAUSCH HEALTH": {
              "MOVIPREP": "MOVIPREP",
              "PLENVU": "PLENVU"
            },
            "BRAINTREE LAB": {
              "GOLYTELY": "GOLYTELY",
              "NULYTELY LEMON-LIME": "NULYTELY LEMON-LIME",
              "NULYTELY WITH FLAVOR PACKS": "NULYTELY WITH FLAVOR PACKS",
              "SUPREP BOWEL PREP KIT": "SUPREP BOWEL PREP KIT"
            },
            "ENOVACHEM MANUFACTURING": {
              "PCP 100": "PCP 100"
            },
            "FERRING": {
              "CLENPIQ": "CLENPIQ",
              "PREPOPIK": "PREPOPIK"
            },
            "KREMERS URBAN": {
              "PEG 3350/ELECTROLYTES": "PEG 3350/ELECTROLYTES"
            },
            "LUPIN PHARMACEUTICALS": {
              "GAVILYTE-C": "GAVILYTE-C",
              "GAVILYTE-G": "GAVILYTE-G",
              "GAVILYTE-H": "GAVILYTE-H",
              "GAVILYTE-N WITH FLAVOR PACK": "GAVILYTE-N WITH FLAVOR PACK"
            },
            "MYLAN": {
              "PEG-3350/ELECTROLYTES": "PEG-3350/ELECTROLYTES"
            },
            "MYLAN SPECIALTY L.P.": {
              "COLYTE WITH FLAVOR PACKS": "COLYTE WITH FLAVOR PACKS"
            },
            "OCEANSIDE PHARMACEUTICALS": {
              "PEG-3350/ELECTROLYTES/ASCORBAT": "PEG-3350/ELECTROLYTES/ASCORBAT"
            },
            "PURE TEK": {
              "POLY-PREP": "POLY-PREP"
            },
            "STRIDES PHARMA": {
              "PEG 3350-KCL-NA BICARB-NACL": "PEG 3350-KCL-NA BICARB-NACL",
              "PEG-3350/ELECTROLYTES": "PEG-3350/ELECTROLYTES"
            },
            "TEVA PHARMACEUTICALS USA": {
              "PEG-KCL-NACL-NASULF-NA ASC-C": "PEG-KCL-NACL-NASULF-NA ASC-C"
            },
            "WALLACE PHARMACEUTICALS": {
              "TRILYTE": "TRILYTE"
            }
          },
          "Laxative Combinations": {
            "BAYER CONSUMER": {
              "PHILLIPS DAILY CARE FIBER GOOD": "PHILLIPS DAILY CARE FIBER GOOD"
            },
            "CHAIN DRUG CONSORTIUM": {
              "FIBER PLUS CALCIUM": "FIBER PLUS CALCIUM"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS EASY FIBER/CALCIUM": "CVS EASY FIBER/CALCIUM"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "BENEFIBER PLUS B VITS & FA": "BENEFIBER PLUS B VITS & FA",
              "BENEFIBER PLUS CALCIUM": "BENEFIBER PLUS CALCIUM",
              "BENEFIBER PLUS HEART HEALTH": "BENEFIBER PLUS HEART HEALTH"
            },
            "KONSYL PHARMACEUTICAL": {
              "SENNA PROMPT": "SENNA PROMPT"
            },
            "MASON VITAMINS": {
              "NATURAL VEGETABLE LAXATIVE": "NATURAL VEGETABLE LAXATIVE"
            },
            "MEDTECH": {
              "FIBERCHOICE PLUS CALCIUM": "FIBERCHOICE PLUS CALCIUM"
            },
            "NATURES BOUNTY": {
              "FIBER/D3 ADULT GUMMIES": "FIBER/D3 ADULT GUMMIES"
            },
            "P & G HEALTH": {
              "METAMUCIL PLUS CALCIUM": "METAMUCIL PLUS CALCIUM"
            },
            "PATHOBIOME": {
              "BIOHM COLON CLEANSER": "BIOHM COLON CLEANSER"
            },
            "REXALL SUNDOWN": {
              "FIBER/D3 ADULT GUMMIES": "FIBER/D3 ADULT GUMMIES"
            },
            "WALGREENS": {
              "WAL-MUCIL PLUS CALCIUM": "WAL-MUCIL PLUS CALCIUM"
            }
          },
          "Laxatives & DSS": {
            "AIDAREX PHARMACEUTICALS": {
              "SENNA-DOCUSATE SODIUM": "SENNA-DOCUSATE SODIUM",
              "SENNOSIDES-DOCUSATE SODIUM": "SENNOSIDES-DOCUSATE SODIUM"
            },
            "ALEXSO": {
              "LAXACIN": "LAXACIN"
            },
            "AMERICAN SALES COMPANY": {
              "SENNA-S": "SENNA-S",
              "STOOL SOFTENER & LAXATIVE": "STOOL SOFTENER & LAXATIVE"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP SENNA PLUS": "GNP SENNA PLUS",
              "GNP STOOL SOFTENER/LAXATIVE": "GNP STOOL SOFTENER/LAXATIVE"
            },
            "AVRIO HEALTH LP": {
              "COLACE 2-IN-1": "COLACE 2-IN-1",
              "PERI-COLACE": "PERI-COLACE",
              "SENOKOT S": "SENOKOT S"
            },
            "BASIC DRUGS": {
              "SENNA-S": "SENNA-S"
            },
            "BERGEN BRUNSWIG": {
              "GNP SENNA PLUS": "GNP SENNA PLUS",
              "GNP STOOL SOFTENER/LAXATIVE": "GNP STOOL SOFTENER/LAXATIVE"
            },
            "BRYANT RANCH PREPACK": {
              "SENNA-S": "SENNA-S",
              "SENNOSIDES-DOCUSATE SODIUM": "SENNOSIDES-DOCUSATE SODIUM"
            },
            "CHAIN DRUG CONSORTIUM": {
              "SENNA PLUS": "SENNA PLUS",
              "STOOL SOFTENER PLUS LAXATIVE": "STOOL SOFTENER PLUS LAXATIVE"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC SENNA-S": "QC SENNA-S",
              "QC STOOL SOFTENER PLS LAXATIVE": "QC STOOL SOFTENER PLS LAXATIVE"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS SENNA PLUS": "CVS SENNA PLUS",
              "CVS STOOL SOFTENER": "CVS STOOL SOFTENER",
              "CVS STOOL SOFTENER/LAXATIVE": "CVS STOOL SOFTENER/LAXATIVE"
            },
            "CYPRESS PHARMACEUTICAL": {
              "SENNA-S": "SENNA-S"
            },
            "DIRECT RX": {
              "LAXACIN": "LAXACIN",
              "SENNA PLUS": "SENNA PLUS",
              "SENNA S": "SENNA S",
              "SENNA-DOCUSATE SODIUM": "SENNA-DOCUSATE SODIUM"
            },
            "EQUALINE": {
              "EQL SENNA-S": "EQL SENNA-S",
              "EQL STOOL SOFTENER/STIMULANT": "EQL STOOL SOFTENER/STIMULANT"
            },
            "GEISS DESTIN & DUNN": {
              "GOODSENSE STIMULANT LAXATIVE": "GOODSENSE STIMULANT LAXATIVE"
            },
            "GERI-CARE": {
              "SENNA PLUS": "SENNA PLUS",
              "SENNA-PLUS": "SENNA-PLUS",
              "STOOL SOFTENER/LAXATIVE": "STOOL SOFTENER/LAXATIVE"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "SENNA-DOCUSATE SODIUM": "SENNA-DOCUSATE SODIUM"
            },
            "LEADER BRAND PRODUCTS": {
              "SENNA PLUS": "SENNA PLUS",
              "SENNA-PLUS": "SENNA-PLUS",
              "SENNA-S": "SENNA-S",
              "STOOL SOFTENER PLUS LAXATIVE": "STOOL SOFTENER PLUS LAXATIVE",
              "STOOL SOFTENER/LAXATIVE": "STOOL SOFTENER/LAXATIVE"
            },
            "MAJOR PHARMACEUTICALS": {
              "DOK PLUS": "DOK PLUS",
              "SENNA PLUS": "SENNA PLUS"
            },
            "MARLEX PHARMACEUTICALS": {
              "SENNA S": "SENNA S"
            },
            "MASON VITAMINS": {
              "SENNOSIDES-DOCUSATE SODIUM": "SENNOSIDES-DOCUSATE SODIUM"
            },
            "MCKESSON": {
              "HM SENNA-S": "HM SENNA-S",
              "HM STOOL SOFTENER/LAXATIVE": "HM STOOL SOFTENER/LAXATIVE"
            },
            "MCKESSON SUNMARK": {
              "SM NATURAL LAXATIVE/STOOL SOFT": "SM NATURAL LAXATIVE/STOOL SOFT",
              "SM SENNA-S": "SM SENNA-S",
              "SM STOOL SOFTENER": "SM STOOL SOFTENER",
              "SM STOOL SOFTENER/LAXATIVE": "SM STOOL SOFTENER/LAXATIVE"
            },
            "MEDICINE SHOPPE": {
              "MEDI-NATURAL PLUS": "MEDI-NATURAL PLUS",
              "STOOL SOFTENER LAXATIVE": "STOOL SOFTENER LAXATIVE"
            },
            "MEIJER": {
              "SENNA-S": "SENNA-S"
            },
            "NUCARE PHARMACEUTICALS": {
              "SENNA-DOCUSATE SODIUM": "SENNA-DOCUSATE SODIUM"
            },
            "PAR PHARMACEUTICAL": {
              "DOC-Q-LAX": "DOC-Q-LAX",
              "SENNALAX-S": "SENNALAX-S"
            },
            "PDRX PHARMACEUTICAL": {
              "SENEXON-S": "SENEXON-S",
              "SENNOSIDES-DOCUSATE SODIUM": "SENNOSIDES-DOCUSATE SODIUM"
            },
            "PHARBEST PHARMACEUTICALS": {
              "SENNA S": "SENNA S",
              "SENNA-DOCUSATE SODIUM": "SENNA-DOCUSATE SODIUM"
            },
            "PLUS PHARMA": {
              "SENNA PLUS": "SENNA PLUS"
            },
            "PREFERRED PHARMACEUTICALS": {
              "STIMULANT LAXATIVE": "STIMULANT LAXATIVE"
            },
            "PROFICIENT RX": {
              "DOCUZEN": "DOCUZEN",
              "MEDI-LAXX": "MEDI-LAXX"
            },
            "QUALITY CARE": {
              "DOC-Q-LAX": "DOC-Q-LAX",
              "SENNA-DOCUSATE SODIUM": "SENNA-DOCUSATE SODIUM",
              "SM NATURAL LAXATIVE/STOOL SOFT": "SM NATURAL LAXATIVE/STOOL SOFT"
            },
            "QUALITY CARE PRODUCTS": {
              "SENNA-S": "SENNA-S",
              "SENNALAX-S": "SENNALAX-S",
              "SENOKOT S": "SENOKOT S"
            },
            "RELIABLE 1 LABS": {
              "SENNA PLUS": "SENNA PLUS"
            },
            "RITE AID CORPORATION": {
              "RA LAXATIVE & STOOL SOFTENER": "RA LAXATIVE & STOOL SOFTENER",
              "RA P COL-RITE": "RA P COL-RITE",
              "RA SENNA PLUS": "RA SENNA PLUS"
            },
            "RUGBY LABORATORIES": {
              "SENEXON-S": "SENEXON-S",
              "SENNA-DOCUSATE SODIUM": "SENNA-DOCUSATE SODIUM",
              "SENNOSIDES-DOCUSATE SODIUM": "SENNOSIDES-DOCUSATE SODIUM",
              "STIMULANT LAXATIVE": "STIMULANT LAXATIVE"
            },
            "SELECT BRAND": {
              "SB DOCUSATE SODIUM/SENNA": "SB DOCUSATE SODIUM/SENNA"
            },
            "SKY PACKAGING": {
              "SENNOSIDES-DOCUSATE SODIUM": "SENNOSIDES-DOCUSATE SODIUM"
            },
            "SUNRISE PHARMACEUTICAL": {
              "SENNA-DOCUSATE SODIUM": "SENNA-DOCUSATE SODIUM"
            },
            "TARGET": {
              "TGT SENNA LAX/STOOL SOFTENER": "TGT SENNA LAX/STOOL SOFTENER",
              "TGT SENNA LAXATIVE": "TGT SENNA LAXATIVE",
              "TGT STOOL SOFTENER & STIMULANT": "TGT STOOL SOFTENER & STIMULANT"
            },
            "TIME-CAP LABS": {
              "SENNA-TIME S": "SENNA-TIME S"
            },
            "TOPCO": {
              "SENNA-S": "SENNA-S",
              "STOOL SOFTENER PLUS LAXATIVE": "STOOL SOFTENER PLUS LAXATIVE",
              "VEGETABLE LAX+STOOL SOFTENER": "VEGETABLE LAX+STOOL SOFTENER"
            },
            "TWO HIP": {
              "MEDI-LAXX": "MEDI-LAXX"
            },
            "VILLAGE PHARMA": {
              "DOCUZEN": "DOCUZEN"
            },
            "WAL-MART": {
              "EQ SENNA-S": "EQ SENNA-S",
              "EQ STOOL SOFTENER/LAXATIVE": "EQ STOOL SOFTENER/LAXATIVE",
              "SENNA-S": "SENNA-S"
            },
            "WALGREENS": {
              "EASY-LAX PLUS": "EASY-LAX PLUS",
              "SENNA S": "SENNA S",
              "SENNA-S": "SENNA-S",
              "STOOL SOFTENER PLUS LAXATIVE": "STOOL SOFTENER PLUS LAXATIVE"
            }
          }
        },
        "Laxatives - Miscellaneous": {
          "Laxatives - Miscellaneous": {
            "A-S MEDICATION SOLUTIONS": {
              "CONSTULOSE": "CONSTULOSE",
              "LACTULOSE": "LACTULOSE",
              "PEG 3350": "PEG 3350",
              "POLYETHYLENE GLYCOL 3350": "POLYETHYLENE GLYCOL 3350",
              "SM CLEARLAX": "SM CLEARLAX"
            },
            "ACTAVIS": {
              "CONSTULOSE": "CONSTULOSE"
            },
            "AIDAREX PHARMACEUTICALS": {
              "LACTULOSE": "LACTULOSE",
              "POLYETHYLENE GLYCOL 3350": "POLYETHYLENE GLYCOL 3350"
            },
            "AMERICAN HEALTH PACKAGING": {
              "HEALTHYLAX": "HEALTHYLAX"
            },
            "AMERICAN SALES COMPANY": {
              "CLEARLAX": "CLEARLAX",
              "GLYCERIN (ADULT)": "GLYCERIN (ADULT)",
              "GLYCERIN (PEDIATRIC)": "GLYCERIN (PEDIATRIC)"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP CLEARLAX": "GNP CLEARLAX",
              "GNP GLYCERIN (ADULT)": "GNP GLYCERIN (ADULT)",
              "GNP GLYCERIN CHILD": "GNP GLYCERIN CHILD"
            },
            "ANI  PHARMACEUTICALS": {
              "POLYETHYLENE GLYCOL 3350": "POLYETHYLENE GLYCOL 3350"
            },
            "APLICARE": {
              "CASTILE SOAP": "CASTILE SOAP"
            },
            "ATLANTIC BIOLOGICALS": {
              "GAVILAX": "GAVILAX",
              "LACTULOSE": "LACTULOSE"
            },
            "AVKARE": {
              "POLYETHYLENE GLYCOL 3350": "POLYETHYLENE GLYCOL 3350"
            },
            "BAYER CONSUMER": {
              "MIRALAX": "MIRALAX",
              "MIRALAX MIX-IN PAX": "MIRALAX MIX-IN PAX"
            },
            "BERGEN BRUNSWIG": {
              "GNP GLYCERIN (ADULT)": "GNP GLYCERIN (ADULT)",
              "GNP GLYCERIN (INFANT)": "GNP GLYCERIN (INFANT)"
            },
            "BEUTLICH PHARMA": {
              "CEO-TWO": "CEO-TWO"
            },
            "BOEHRINGER INGELHEIM CONSUMER": {
              "DULCOLAX BALANCE": "DULCOLAX BALANCE"
            },
            "BRECKENRIDGE": {
              "POLYETHYLENE GLYCOL 3350": "POLYETHYLENE GLYCOL 3350"
            },
            "CARDINAL HEALTH REPACKAGING SO": {
              "LACTULOSE": "LACTULOSE",
              "PEG 3350": "PEG 3350",
              "POLYETHYLENE GLYCOL 3350": "POLYETHYLENE GLYCOL 3350"
            },
            "CHAIN DRUG CONSORTIUM": {
              "GLYCERIN (ADULT)": "GLYCERIN (ADULT)",
              "GLYCERIN (INFANT)": "GLYCERIN (INFANT)",
              "LAXATIVE POLYETHYLENE GLYCOL": "LAXATIVE POLYETHYLENE GLYCOL",
              "POLYETHYLENE GLYCOL 3350": "POLYETHYLENE GLYCOL 3350"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC NATURA-LAX": "QC NATURA-LAX"
            },
            "COSETTE PHARMACEUTICALS": {
              "SANI-SUPP ADULT": "SANI-SUPP ADULT",
              "SANI-SUPP PEDIATRIC": "SANI-SUPP PEDIATRIC"
            },
            "COSTCO WHOLESALE": {
              "KLS LAXACLEAR": "KLS LAXACLEAR"
            },
            "COVIDIEN MEDICAL SUPPLIES": {
              "DOVER ENEMA BAG AND TUBE 24FR": "DOVER ENEMA BAG AND TUBE 24FR"
            },
            "CUMBERLAND PHARMACEUTICALS": {
              "KRISTALOSE": "KRISTALOSE"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS GLYCERIN ADULT": "CVS GLYCERIN ADULT",
              "CVS GLYCERIN CHILD": "CVS GLYCERIN CHILD",
              "CVS PURELAX": "CVS PURELAX"
            },
            "CYPRESS PHARMACEUTICAL": {
              "SORBITOL": "SORBITOL"
            },
            "DIRECT RX": {
              "GAVILAX": "GAVILAX",
              "POLYETHYLENE GLYCOL 3350": "POLYETHYLENE GLYCOL 3350"
            },
            "EQUALINE": {
              "EQL CLEARLAX": "EQL CLEARLAX",
              "EQL GLYCERIN (ADULT)": "EQL GLYCERIN (ADULT)"
            },
            "FLEET PHARMACEUTICALS": {
              "FLEET LIQUID GLYCERIN SUPP": "FLEET LIQUID GLYCERIN SUPP",
              "GLYCERIN (ADULT)": "GLYCERIN (ADULT)",
              "PEDIA-LAX": "PEDIA-LAX"
            },
            "FOXLAND PHARMACEUTICALS": {
              "LACTULOSE": "LACTULOSE"
            },
            "GERI-CARE": {
              "POLYETHYLENE GLYCOL 3350": "POLYETHYLENE GLYCOL 3350",
              "SORBITOL": "SORBITOL"
            },
            "GERITREX CORPORATION": {
              "POLYETHYLENE GLYCOL 3350-GRX": "POLYETHYLENE GLYCOL 3350-GRX",
              "SORBITOL": "SORBITOL"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "POLYETHYLENE GLYCOL 3350": "POLYETHYLENE GLYCOL 3350"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "LACTULOSE": "LACTULOSE"
            },
            "HANNAFORD BROTHERS": {
              "GLYCERIN (ADULT)": "GLYCERIN (ADULT)",
              "GLYCERIN (PEDIATRIC)": "GLYCERIN (PEDIATRIC)"
            },
            "HI-TECH": {
              "LACTULOSE": "LACTULOSE"
            },
            "HIKMA": {
              "LACTULOSE": "LACTULOSE"
            },
            "KREMERS URBAN": {
              "GLYCOLAX": "GLYCOLAX",
              "POLYETHYLENE GLYCOL 3350": "POLYETHYLENE GLYCOL 3350"
            },
            "KROGER COMPANY": {
              "GENTLELAX": "GENTLELAX"
            },
            "LANNETT": {
              "LACTULOSE": "LACTULOSE"
            },
            "LEADER BRAND PRODUCTS": {
              "CLEARLAX": "CLEARLAX",
              "GLYCERIN (PEDIATRIC)": "GLYCERIN (PEDIATRIC)",
              "GLYCERIN ADULT": "GLYCERIN ADULT",
              "GLYCERIN CHILDRENS": "GLYCERIN CHILDRENS"
            },
            "LOHXA": {
              "GLYCERIN (ADULT)": "GLYCERIN (ADULT)",
              "GLYCERIN (CHILD)": "GLYCERIN (CHILD)"
            },
            "LUPIN PHARMACEUTICALS": {
              "GAVILAX": "GAVILAX"
            },
            "MAJOR PHARMACEUTICALS": {
              "PEG 3350": "PEG 3350"
            },
            "MARLEX PHARMACEUTICALS": {
              "POLYETHYLENE GLYCOL 3350": "POLYETHYLENE GLYCOL 3350",
              "SORBITOL": "SORBITOL"
            },
            "MCKESSON": {
              "HM CLEARLAX": "HM CLEARLAX"
            },
            "MCKESSON SUNMARK": {
              "SM CLEARLAX": "SM CLEARLAX",
              "SM GLYCERIN PEDIATRIC": "SM GLYCERIN PEDIATRIC"
            },
            "ME PHARMACEUTICALS": {
              "PEGYLAX": "PEGYLAX"
            },
            "MORTON GROVE PHARMACEUTICALS": {
              "LACTULOSE": "LACTULOSE"
            },
            "MYLAN INSTITUTIONAL": {
              "POLYETHYLENE GLYCOL 3350": "POLYETHYLENE GLYCOL 3350"
            },
            "NEXGEN PHARMA": {
              "POLYETHYLENE GLYCOL 3350": "POLYETHYLENE GLYCOL 3350"
            },
            "NUCARE PHARMACEUTICALS": {
              "LACTULOSE": "LACTULOSE",
              "POLYETHYLENE GLYCOL 3350": "POLYETHYLENE GLYCOL 3350"
            },
            "PAR PHARMACEUTICALS": {
              "LACTULOSE": "LACTULOSE",
              "SORBITOL": "SORBITOL"
            },
            "PERRIGO": {
              "GOODSENSE CLEARLAX": "GOODSENSE CLEARLAX",
              "POLYETHYLENE GLYCOL 3350": "POLYETHYLENE GLYCOL 3350"
            },
            "PERRIGO PHARMACEUTICALS": {
              "POLYETHYLENE GLYCOL 3350": "POLYETHYLENE GLYCOL 3350"
            },
            "PHARMACEUTICAL ASSOCIATES": {
              "LACTULOSE": "LACTULOSE"
            },
            "PHLIGHT PHARMA": {
              "GIALAX": "GIALAX"
            },
            "PROFICIENT RX": {
              "POLYETHYLENE GLYCOL 3350": "POLYETHYLENE GLYCOL 3350"
            },
            "PUBLIX SUPER MARKETS INC.": {
              "PX GLYCERIN": "PX GLYCERIN"
            },
            "QUALITY CARE": {
              "LACTULOSE": "LACTULOSE",
              "POLYETHYLENE GLYCOL 3350": "POLYETHYLENE GLYCOL 3350"
            },
            "RATIOPHARM": {
              "LACTULOSE": "LACTULOSE"
            },
            "RITE AID CORPORATION": {
              "RA GLYCERIN ADULT": "RA GLYCERIN ADULT",
              "RA GLYCERIN CHILD": "RA GLYCERIN CHILD",
              "RA LAXATIVE": "RA LAXATIVE"
            },
            "RUGBY LABORATORIES": {
              "PEG 3350": "PEG 3350"
            },
            "SAFEWAY": {
              "SW CLEARLAX": "SW CLEARLAX"
            },
            "SELECT BRAND": {
              "SB GLYCERIN ADULT": "SB GLYCERIN ADULT",
              "SB GLYCERIN PEDIATRIC": "SB GLYCERIN PEDIATRIC",
              "SB POLYETHYLENE GLYCOL 3350": "SB POLYETHYLENE GLYCOL 3350"
            },
            "SKY PACKAGING": {
              "PEG 3350": "PEG 3350"
            },
            "STRATUS PHARMACEUTICALS": {
              "GLYCERIN (ADULT)": "GLYCERIN (ADULT)",
              "GLYCERIN (INFANTS & CHILDREN)": "GLYCERIN (INFANTS & CHILDREN)"
            },
            "SUNRISE PHARMACEUTICAL": {
              "POLYETHYLENE GLYCOL 3350": "POLYETHYLENE GLYCOL 3350"
            },
            "TARGET": {
              "TGT POWDERLAX": "TGT POWDERLAX"
            },
            "TOPCO": {
              "CLEARLAX": "CLEARLAX"
            },
            "TORRENT PHARMACEUTICALS": {
              "LACTULOSE": "LACTULOSE"
            },
            "UNIT DOSE SERVICES": {
              "LACTULOSE": "LACTULOSE"
            },
            "VISTAPHARM INC.": {
              "LACTULOSE": "LACTULOSE"
            },
            "WAL-MART": {
              "EQ CLEARLAX": "EQ CLEARLAX"
            },
            "WALGREENS": {
              "GLYCERIN (ADULT)": "GLYCERIN (ADULT)",
              "GLYCERIN (CHILD)": "GLYCERIN (CHILD)",
              "GLYCERIN (INFANTS & CHILDREN)": "GLYCERIN (INFANTS & CHILDREN)",
              "GLYCERIN (PEDIATRIC)": "GLYCERIN (PEDIATRIC)",
              "GLYCERIN ADULT": "GLYCERIN ADULT",
              "SMOOTH LAX": "SMOOTH LAX"
            },
            "WOODWARD PHARMA SERVICES": {
              "POLYETHYLENE GLYCOL 3350": "POLYETHYLENE GLYCOL 3350"
            }
          }
        },
        "Lubricant Laxatives": {
          "Lubricant Laxatives": {
            "AMERICAN SALES COMPANY": {
              "MINERAL OIL": "MINERAL OIL"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP MINERAL OIL": "GNP MINERAL OIL"
            },
            "BERGEN BRUNSWIG": {
              "GNP MINERAL OIL HEAVY": "GNP MINERAL OIL HEAVY"
            },
            "CHAIN DRUG CONSORTIUM": {
              "ENEMA MINERAL OIL": "ENEMA MINERAL OIL",
              "MINERAL OIL": "MINERAL OIL"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC MINERAL OIL HEAVY": "QC MINERAL OIL HEAVY"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS MINERAL OIL": "CVS MINERAL OIL",
              "CVS MINERAL OIL ENEMA": "CVS MINERAL OIL ENEMA"
            },
            "DENISON LABS": {
              "MINERAL OIL LIGHT": "MINERAL OIL LIGHT"
            },
            "FAGRON": {
              "MINERAL OIL HEAVY": "MINERAL OIL HEAVY",
              "MINERAL OIL LIGHT": "MINERAL OIL LIGHT"
            },
            "FLEET PHARMACEUTICALS": {
              "FLEET OIL": "FLEET OIL"
            },
            "FRESENIUS KABI USA": {
              "MURI-LUBE": "MURI-LUBE"
            },
            "GEISS DESTIN & DUNN": {
              "GOODSENSE MINERAL OIL": "GOODSENSE MINERAL OIL"
            },
            "GERITREX CORPORATION": {
              "MINERAL OIL": "MINERAL OIL"
            },
            "HUMCO": {
              "MINERAL OIL HEAVY": "MINERAL OIL HEAVY"
            },
            "INSIGHT PHARMACEUTICALS": {
              "KONDREMUL": "KONDREMUL"
            },
            "KALCHEM INTERNATIONAL": {
              "MINERAL OIL": "MINERAL OIL"
            },
            "LEADER BRAND PRODUCTS": {
              "ENEMA MINERAL OIL": "ENEMA MINERAL OIL",
              "MINERAL OIL": "MINERAL OIL"
            },
            "LETCO MEDICAL": {
              "MINERAL OIL LIGHT": "MINERAL OIL LIGHT"
            },
            "MCKESSON": {
              "HM ENEMA MINERAL OIL": "HM ENEMA MINERAL OIL",
              "HM MINERAL OIL": "HM MINERAL OIL"
            },
            "MCKESSON SUNMARK": {
              "SM MINERAL OIL": "SM MINERAL OIL"
            },
            "MEDICINE SHOPPE": {
              "MINERAL OIL": "MINERAL OIL"
            },
            "MEDISCA": {
              "MINERAL OIL HEAVY": "MINERAL OIL HEAVY",
              "MINERAL OIL LIGHT": "MINERAL OIL LIGHT"
            },
            "PCCA": {
              "MINERAL OIL HEAVY": "MINERAL OIL HEAVY",
              "MINERAL OIL LIGHT": "MINERAL OIL LIGHT"
            },
            "PERRIGO": {
              "MINERAL OIL": "MINERAL OIL"
            },
            "PREFERRED PHARMACEUTICALS": {
              "MINERAL OIL": "MINERAL OIL"
            },
            "RITE AID CORPORATION": {
              "RA MINERAL OIL": "RA MINERAL OIL"
            },
            "SAFECOR HEALTH": {
              "MINERAL OIL": "MINERAL OIL"
            },
            "SPECTRUM": {
              "MINERAL OIL": "MINERAL OIL",
              "MINERAL OIL LIGHT": "MINERAL OIL LIGHT"
            },
            "TOPCO": {
              "MINERAL OIL": "MINERAL OIL"
            },
            "VI-JON": {
              "MINERAL OIL": "MINERAL OIL"
            },
            "WAL-MART": {
              "EQ MINERAL OIL": "EQ MINERAL OIL"
            },
            "WALGREENS": {
              "ENEMA MINERAL OIL": "ENEMA MINERAL OIL",
              "MINERAL OIL": "MINERAL OIL"
            }
          }
        },
        "Saline Laxatives": {
          "Saline Laxative Mixtures": {
            "A-S MEDICATION SOLUTIONS": {
              "FLEET ENEMA": "FLEET ENEMA"
            },
            "AMERICAN SALES COMPANY": {
              "ENEMA": "ENEMA"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP ENEMA": "GNP ENEMA"
            },
            "BAUSCH HEALTH": {
              "OSMOPREP": "OSMOPREP"
            },
            "BERGEN BRUNSWIG": {
              "GNP ENEMA": "GNP ENEMA"
            },
            "CHAIN DRUG CONSORTIUM": {
              "COMPLETE READY-TO-USE ENEMA": "COMPLETE READY-TO-USE ENEMA",
              "ENEMA": "ENEMA"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC ENEMA": "QC ENEMA"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS ENEMA DISPOSABLE": "CVS ENEMA DISPOSABLE",
              "CVS ENEMA READY-TO-USE": "CVS ENEMA READY-TO-USE",
              "CVS PHOSPHATE SALINE LAXATIVE": "CVS PHOSPHATE SALINE LAXATIVE"
            },
            "EQUALINE": {
              "EQL READY-TO-USE ENEMA": "EQL READY-TO-USE ENEMA"
            },
            "FLEET PHARMACEUTICALS": {
              "FLEET ENEMA": "FLEET ENEMA",
              "FLEET PEDIATRIC": "FLEET PEDIATRIC"
            },
            "GEISS DESTIN & DUNN": {
              "GOODSENSE ENEMA": "GOODSENSE ENEMA"
            },
            "LEADER BRAND PRODUCTS": {
              "ENEMA": "ENEMA",
              "ENEMA READY-TO-USE": "ENEMA READY-TO-USE"
            },
            "MAJOR PHARMACEUTICALS": {
              "ENEMA": "ENEMA"
            },
            "MCKESSON": {
              "HM ENEMA": "HM ENEMA"
            },
            "MCKESSON SUNMARK": {
              "SM ENEMA": "SM ENEMA",
              "SM ORAL SALINE LAXATIVE": "SM ORAL SALINE LAXATIVE"
            },
            "MEDICINE SHOPPE": {
              "ENEMA": "ENEMA"
            },
            "MEDLINE/DERMAL MANAGEMENT": {
              "PURE & GENTLE ENEMA": "PURE & GENTLE ENEMA"
            },
            "NATUREPLEX": {
              "ENEMA": "ENEMA"
            },
            "RITE AID CORPORATION": {
              "RA ENEMA": "RA ENEMA",
              "RA ORAL SALINE LAXATIVE": "RA ORAL SALINE LAXATIVE",
              "RA SALINE ENEMA": "RA SALINE ENEMA",
              "RA SALINE LAXATIVE": "RA SALINE LAXATIVE"
            },
            "RUGBY LABORATORIES": {
              "ENEMA": "ENEMA"
            },
            "TARGET": {
              "TGT SALINE LAXATIVE": "TGT SALINE LAXATIVE"
            },
            "TOPCO": {
              "ENEMA": "ENEMA"
            },
            "WAL-MART": {
              "EQ ENEMA": "EQ ENEMA"
            },
            "WALGREENS": {
              "ENEMA": "ENEMA",
              "ENEMA DISPOSABLE": "ENEMA DISPOSABLE",
              "ENEMA PEDIATRIC": "ENEMA PEDIATRIC",
              "PHOSPHATE LAXATIVE": "PHOSPHATE LAXATIVE",
              "SALINE LAXATIVE": "SALINE LAXATIVE"
            }
          },
          "Saline Laxatives": {
            "A-S MEDICATION SOLUTIONS": {
              "MILK OF MAGNESIA": "MILK OF MAGNESIA"
            },
            "ALTAIRE": {
              "MILK OF MAGNESIA": "MILK OF MAGNESIA"
            },
            "AMERICAN SALES COMPANY": {
              "EPSOM SALT": "EPSOM SALT",
              "MAGNESIUM CITRATE": "MAGNESIUM CITRATE",
              "MILK OF MAGNESIA": "MILK OF MAGNESIA"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP EPSOM SALT": "GNP EPSOM SALT",
              "GNP MILK OF MAGNESIA": "GNP MILK OF MAGNESIA"
            },
            "AUBURN PHARMACEUTICAL": {
              "MILK OF MAGNESIA": "MILK OF MAGNESIA"
            },
            "AURORA HEALTHCARE": {
              "CITRATE OF MAGNESIA": "CITRATE OF MAGNESIA",
              "MILK OF MAGNESIA": "MILK OF MAGNESIA"
            },
            "BAYER CONSUMER": {
              "PHILLIPS": "PHILLIPS",
              "PHILLIPS MILK OF MAGNESIA": "PHILLIPS MILK OF MAGNESIA"
            },
            "BERGEN BRUNSWIG": {
              "GNP EPSOM SALT": "GNP EPSOM SALT",
              "GNP MAGNESIUM CITRATE": "GNP MAGNESIUM CITRATE",
              "GNP MILK OF MAGNESIA": "GNP MILK OF MAGNESIA"
            },
            "BOEHRINGER INGELHEIM CONSUMER": {
              "DULCOLAX MILK OF MAGNESIA": "DULCOLAX MILK OF MAGNESIA"
            },
            "CHAIN DRUG CONSORTIUM": {
              "EPSOM SALT": "EPSOM SALT",
              "MAGNESIUM CITRATE": "MAGNESIUM CITRATE",
              "MILK OF MAGNESIA": "MILK OF MAGNESIA"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC EPSOM SALT": "QC EPSOM SALT",
              "QC MAGNESIUM CITRATE": "QC MAGNESIUM CITRATE",
              "QC MILK OF MAGNESIA": "QC MILK OF MAGNESIA"
            },
            "CHATTEM INC": {
              "DULCOLAX": "DULCOLAX"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS CITRATE OF MAGNESIA": "CVS CITRATE OF MAGNESIA",
              "CVS EPSOM SALT": "CVS EPSOM SALT",
              "CVS LAXATIVE DIETARY SUPPLEMNT": "CVS LAXATIVE DIETARY SUPPLEMNT",
              "CVS MAGNESIUM CITRATE": "CVS MAGNESIUM CITRATE",
              "CVS MILK OF MAGNESIA": "CVS MILK OF MAGNESIA"
            },
            "CYPRESS PHARMACEUTICAL": {
              "MILK OF MAGNESIA": "MILK OF MAGNESIA"
            },
            "DENISON LABS": {
              "EPSOM SALT": "EPSOM SALT",
              "MILK OF MAGNESIA": "MILK OF MAGNESIA"
            },
            "EQUALINE": {
              "EQL EPSOM SALT": "EQL EPSOM SALT",
              "EQL MAGNESIUM CITRATE": "EQL MAGNESIUM CITRATE",
              "EQL MILK OF MAGNESIA": "EQL MILK OF MAGNESIA"
            },
            "FAGRON": {
              "EPSOM SALT": "EPSOM SALT",
              "MAGNESIUM SULFATE": "MAGNESIUM SULFATE"
            },
            "FLEET PHARMACEUTICALS": {
              "PEDIA-LAX": "PEDIA-LAX"
            },
            "FREEDOM PHARMACEUTICALS": {
              "MAGNESIUM SULFATE": "MAGNESIUM SULFATE"
            },
            "GEISS DESTIN & DUNN": {
              "GOODSENSE EPSOM SALT": "GOODSENSE EPSOM SALT",
              "GOODSENSE MAGNESIUM CITRATE": "GOODSENSE MAGNESIUM CITRATE"
            },
            "GERI-CARE": {
              "MAGNESIUM CITRATE": "MAGNESIUM CITRATE",
              "MILK OF MAGNESIA": "MILK OF MAGNESIA"
            },
            "HANNAFORD BROTHERS": {
              "MILK OF MAGNESIA": "MILK OF MAGNESIA"
            },
            "HIKMA": {
              "MILK OF MAGNESIA CONCENTRATE": "MILK OF MAGNESIA CONCENTRATE"
            },
            "HUMCO": {
              "MILK OF MAGNESIA": "MILK OF MAGNESIA"
            },
            "HY-VEE": {
              "MAGNESIUM CITRATE": "MAGNESIUM CITRATE",
              "MILK OF MAGNESIA": "MILK OF MAGNESIA"
            },
            "LEADER BRAND PRODUCTS": {
              "CITRATE OF MAGNESIA": "CITRATE OF MAGNESIA",
              "EPSOM SALT": "EPSOM SALT",
              "MAGNESIUM CITRATE": "MAGNESIUM CITRATE",
              "MILK OF MAGNESIA": "MILK OF MAGNESIA"
            },
            "LETCO MEDICAL": {
              "MAGNESIUM SULFATE": "MAGNESIUM SULFATE"
            },
            "MAJOR PHARMACEUTICALS": {
              "MAGNESIUM CITRATE": "MAGNESIUM CITRATE",
              "MILK OF MAGNESIA": "MILK OF MAGNESIA",
              "MILK OF MAGNESIA CONCENTRATE": "MILK OF MAGNESIA CONCENTRATE"
            },
            "MCKESSON": {
              "HM EPSOM SALT": "HM EPSOM SALT",
              "HM MAGNESIUM CITRATE": "HM MAGNESIUM CITRATE",
              "HM MILK OF MAGNESIA": "HM MILK OF MAGNESIA"
            },
            "MCKESSON SUNMARK": {
              "SM EPSOM SALT": "SM EPSOM SALT",
              "SM MAGNESIUM CITRATE": "SM MAGNESIUM CITRATE",
              "SM MILK OF MAGNESIA": "SM MILK OF MAGNESIA"
            },
            "MEDICINE SHOPPE": {
              "CITRATE OF MAGNESIA": "CITRATE OF MAGNESIA",
              "EPSOM SALT": "EPSOM SALT",
              "MAGNESIUM CITRATE": "MAGNESIUM CITRATE",
              "MILK OF MAGNESIA": "MILK OF MAGNESIA"
            },
            "MEIJER": {
              "MAGNESIUM CITRATE": "MAGNESIUM CITRATE",
              "MILK OF MAGNESIA": "MILK OF MAGNESIA"
            },
            "PCCA": {
              "MAGNESIUM SULFATE": "MAGNESIUM SULFATE"
            },
            "PERRIGO": {
              "MILK OF MAGNESIA": "MILK OF MAGNESIA"
            },
            "PHARMACEUTICAL ASSOCIATES": {
              "MILK OF MAGNESIA": "MILK OF MAGNESIA",
              "MILK OF MAGNESIA CONCENTRATE": "MILK OF MAGNESIA CONCENTRATE"
            },
            "PUBLIX SUPER MARKETS INC.": {
              "PX MILK OF MAGNESIA": "PX MILK OF MAGNESIA"
            },
            "QUALITY CARE": {
              "MILK OF MAGNESIA": "MILK OF MAGNESIA"
            },
            "RITE AID CORPORATION": {
              "RA EPSOM SALT": "RA EPSOM SALT",
              "RA EPSOM SALT LAVENDER": "RA EPSOM SALT LAVENDER",
              "RA MAGNESIUM CITRATE": "RA MAGNESIUM CITRATE",
              "RA MILK OF MAGNESIA": "RA MILK OF MAGNESIA"
            },
            "RUGBY LABORATORIES": {
              "MILK OF MAGNESIA": "MILK OF MAGNESIA"
            },
            "SELECT BRAND": {
              "SB MAGNESIUM CITRATE": "SB MAGNESIUM CITRATE",
              "SB MILK OF MAGNESIA": "SB MILK OF MAGNESIA"
            },
            "SPECTRUM": {
              "MAGNESIUM SULFATE": "MAGNESIUM SULFATE"
            },
            "STRATUS PHARMACEUTICALS": {
              "MILK OF MAGNESIA": "MILK OF MAGNESIA"
            },
            "TOPCO": {
              "MAGNESIUM CITRATE": "MAGNESIUM CITRATE",
              "MILK OF MAGNESIA": "MILK OF MAGNESIA"
            },
            "VI-JON": {
              "CITROMA": "CITROMA",
              "EPSOM SALT": "EPSOM SALT"
            },
            "VIJON LABORATORIES": {
              "EPSOM SALT": "EPSOM SALT"
            },
            "VISTAPHARM INC.": {
              "MILK OF MAGNESIA": "MILK OF MAGNESIA"
            },
            "WAL-MART": {
              "EPSOM SALT": "EPSOM SALT",
              "EQ MAGNESIUM CITRATE": "EQ MAGNESIUM CITRATE",
              "EQ MILK OF MAGNESIA": "EQ MILK OF MAGNESIA",
              "MAGNESIUM CITRATE": "MAGNESIUM CITRATE"
            },
            "WALGREENS": {
              "EPSOM SALT": "EPSOM SALT",
              "MAGNESIUM CITRATE": "MAGNESIUM CITRATE",
              "MILK OF MAGNESIA": "MILK OF MAGNESIA"
            },
            "WILLOW BIRCH PHARMA": {
              "MAGNESIUM SULFATE": "MAGNESIUM SULFATE"
            }
          }
        },
        "Stimulant Laxatives": {
          "Stimulant Laxatives": {
            "A-S MEDICATION SOLUTIONS": {
              "BISACODYL": "BISACODYL",
              "BISACODYL EC": "BISACODYL EC",
              "SENNA-LAX": "SENNA-LAX"
            },
            "ACINO PRODUCTS": {
              "BISACODYL LAXATIVE": "BISACODYL LAXATIVE"
            },
            "AIDAREX PHARMACEUTICALS": {
              "STIMULANT LAXATIVE": "STIMULANT LAXATIVE"
            },
            "AKRON PHARMA": {
              "SENNA": "SENNA"
            },
            "ALTAIRE": {
              "SENNA": "SENNA"
            },
            "ALTURA PHARMACEUTICALS": {
              "SENNOSIDES": "SENNOSIDES"
            },
            "AMERICAN SALES COMPANY": {
              "CASTOR OIL": "CASTOR OIL",
              "GENTLE LAXATIVE": "GENTLE LAXATIVE",
              "GENTLE LAXATIVE FOR WOMEN": "GENTLE LAXATIVE FOR WOMEN",
              "LAXATIVE PILLS": "LAXATIVE PILLS",
              "SENNA LAXATIVE": "SENNA LAXATIVE"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP GENTLE LAXATIVE": "GNP GENTLE LAXATIVE",
              "GNP SENNA LAX": "GNP SENNA LAX",
              "GNP WOMENS GENTLE LAXATIVE": "GNP WOMENS GENTLE LAXATIVE"
            },
            "APHENA PHARMA SOLUTIONS": {
              "BISACODYL EC": "BISACODYL EC",
              "SENNA": "SENNA",
              "SENNOSIDES": "SENNOSIDES"
            },
            "ATLANTIC BIOLOGICALS": {
              "SENEXON": "SENEXON"
            },
            "AUBURN PHARMACEUTICAL": {
              "DUCODYL": "DUCODYL",
              "SENNO": "SENNO"
            },
            "AURORA HEALTHCARE": {
              "GENTLE LAXATIVE": "GENTLE LAXATIVE",
              "SENNA-LAX": "SENNA-LAX",
              "WOMENS LAXATIVE": "WOMENS LAXATIVE"
            },
            "AVRIO HEALTH LP": {
              "SENOKOT": "SENOKOT",
              "SENOKOT EXTRA STRENGTH": "SENOKOT EXTRA STRENGTH",
              "SENOKOT LAXATIVE GUMMIES": "SENOKOT LAXATIVE GUMMIES",
              "SENOKOT XTRA": "SENOKOT XTRA"
            },
            "BASIC DRUGS": {
              "NATURAL SENNA LAXATIVE": "NATURAL SENNA LAXATIVE"
            },
            "BAYER CONSUMER": {
              "CORRECTOL": "CORRECTOL",
              "CORRECTOL HERBAL TEA": "CORRECTOL HERBAL TEA",
              "FEENAMINT": "FEENAMINT"
            },
            "BAYSHORE PHARMACEUTICALS": {
              "SENNAZON": "SENNAZON"
            },
            "BERGEN BRUNSWIG": {
              "GNP BISA-LAX": "GNP BISA-LAX",
              "GNP CASTOR OIL": "GNP CASTOR OIL",
              "GNP LAXATIVE": "GNP LAXATIVE",
              "GNP LAXATIVE PILLS": "GNP LAXATIVE PILLS",
              "GNP SENNA-LAX": "GNP SENNA-LAX",
              "GNP WOMENS LAXATIVE": "GNP WOMENS LAXATIVE"
            },
            "BOEHRINGER INGELHEIM CONSUMER": {
              "DULCOLAX BOWEL PREP KIT": "DULCOLAX BOWEL PREP KIT"
            },
            "BRYANT RANCH PREPACK": {
              "BISACODYL EC": "BISACODYL EC",
              "SENNOSIDES": "SENNOSIDES",
              "SM SENNA LAXATIVE": "SM SENNA LAXATIVE"
            },
            "CHAIN DRUG CONSORTIUM": {
              "BISACODYL EC": "BISACODYL EC",
              "BISACODYL LAXATIVE": "BISACODYL LAXATIVE",
              "CASCARA SAGRADA": "CASCARA SAGRADA",
              "CHOCOLATED LAXATIVE": "CHOCOLATED LAXATIVE",
              "LAXATIVE": "LAXATIVE",
              "LAXATIVE PILLS": "LAXATIVE PILLS",
              "SENNA": "SENNA",
              "WOMANS LAXATIVE": "WOMANS LAXATIVE",
              "WOMENS GENTLE LAXATIVE": "WOMENS GENTLE LAXATIVE"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC GENTLE LAXATIVE": "QC GENTLE LAXATIVE",
              "QC LAXATIVE": "QC LAXATIVE",
              "QC NATURAL VEGETABLE LAXATIVE": "QC NATURAL VEGETABLE LAXATIVE",
              "QC SENNA": "QC SENNA",
              "WOMANS LAXATIVE": "WOMANS LAXATIVE"
            },
            "CHATTEM INC": {
              "DULCOLAX": "DULCOLAX"
            },
            "CHURCH DWIGHT": {
              "CARTERS LITTLE PILLS": "CARTERS LITTLE PILLS"
            },
            "CONCEPTS IN CONFIDENCE": {
              "THE MAGIC BULLET": "THE MAGIC BULLET"
            },
            "COSETTE PHARMACEUTICALS": {
              "BISAC-EVAC": "BISAC-EVAC"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS BISACODYL": "CVS BISACODYL",
              "CVS C-LAX LAXATIVE": "CVS C-LAX LAXATIVE",
              "CVS CASTOR OIL": "CVS CASTOR OIL",
              "CVS CHOCOLATE LAXATIVE PIECES": "CVS CHOCOLATE LAXATIVE PIECES",
              "CVS GENTLE LAXATIVE": "CVS GENTLE LAXATIVE",
              "CVS GENTLE LAXATIVE WOMENS": "CVS GENTLE LAXATIVE WOMENS",
              "CVS LAXATIVE PILLS": "CVS LAXATIVE PILLS",
              "CVS SENNA": "CVS SENNA",
              "CVS SENNA-EXTRA": "CVS SENNA-EXTRA"
            },
            "CYPRESS PHARMACEUTICAL": {
              "SENNA": "SENNA"
            },
            "DIRECT RX": {
              "BISACODYL EC": "BISACODYL EC",
              "SENNA": "SENNA",
              "SENNA LAX": "SENNA LAX"
            },
            "DSE HEALTHCARE SOLUTIONS": {
              "AGORAL MAXIMUM STRENGTH": "AGORAL MAXIMUM STRENGTH",
              "ALOPHEN": "ALOPHEN"
            },
            "EQUALINE": {
              "EQL CASTOR OIL": "EQL CASTOR OIL",
              "EQL GENTLE LAXATIVE": "EQL GENTLE LAXATIVE",
              "EQL LAXATIVE": "EQL LAXATIVE",
              "EQL LAXATIVE MAXIMUM STRENGTH": "EQL LAXATIVE MAXIMUM STRENGTH",
              "EQL SENNA LAXATIVE": "EQL SENNA LAXATIVE",
              "EQL WOMANS LAXATIVE": "EQL WOMANS LAXATIVE"
            },
            "FLEET PHARMACEUTICALS": {
              "FLEET BISACODYL": "FLEET BISACODYL",
              "FLEET LAXATIVE": "FLEET LAXATIVE"
            },
            "GEISS DESTIN & DUNN": {
              "GOODSENSE BISACODYL EC": "GOODSENSE BISACODYL EC",
              "GOODSENSE CASTOR OIL": "GOODSENSE CASTOR OIL",
              "GOODSENSE LAXATIVE PILLS": "GOODSENSE LAXATIVE PILLS",
              "GOODSENSE SENNA LAXATIVE": "GOODSENSE SENNA LAXATIVE",
              "GOODSENSE WOMENS LAXATIVE": "GOODSENSE WOMENS LAXATIVE"
            },
            "GERI-CARE": {
              "BISACODYL": "BISACODYL",
              "BISACODYL EC": "BISACODYL EC",
              "GERI-KOT": "GERI-KOT",
              "SENNA": "SENNA"
            },
            "GERITREX CORPORATION": {
              "BISACODYL": "BISACODYL",
              "SENNA-GRX": "SENNA-GRX"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "EX-LAX": "EX-LAX",
              "EX-LAX MAXIMUM STRENGTH": "EX-LAX MAXIMUM STRENGTH",
              "EX-LAX ULTRA": "EX-LAX ULTRA",
              "PERDIEM OVERNIGHT RELIEF": "PERDIEM OVERNIGHT RELIEF",
              "SENNA SMOOTH": "SENNA SMOOTH"
            },
            "GRAXCELL PHARMACEUTICAL": {
              "LAXATIVE": "LAXATIVE"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "BISACODYL": "BISACODYL",
              "BISACODYL EC": "BISACODYL EC"
            },
            "HANNAFORD BROTHERS": {
              "LAXATIVE": "LAXATIVE",
              "SENNA LAXATIVE": "SENNA LAXATIVE"
            },
            "HUMCO": {
              "SENNA LEAVES": "SENNA LEAVES"
            },
            "KAISER FOUNDATION HOSPITAL": {
              "KP BISACODYL": "KP BISACODYL",
              "KP SENNA": "KP SENNA"
            },
            "LEADER BRAND PRODUCTS": {
              "BISACODYL EC": "BISACODYL EC",
              "CASTOR OIL": "CASTOR OIL",
              "CHOCOLATED LAXATIVE": "CHOCOLATED LAXATIVE",
              "GENTLE LAXATIVE": "GENTLE LAXATIVE",
              "LAXATIVE": "LAXATIVE",
              "LAXATIVE FEMININE": "LAXATIVE FEMININE",
              "LAXATIVE MAX STR": "LAXATIVE MAX STR",
              "LAXATIVE REGULAR STRENGTH": "LAXATIVE REGULAR STRENGTH",
              "SENNA": "SENNA",
              "SENNA LAXATIVE": "SENNA LAXATIVE",
              "WOMENS LAXATIVE": "WOMENS LAXATIVE"
            },
            "LIBERTY PHARMACEUTICAL": {
              "BISACODYL EC": "BISACODYL EC",
              "SENNA": "SENNA"
            },
            "LLORENS PHARMACEUTICAL": {
              "SENNA": "SENNA"
            },
            "LOHXA": {
              "SENNA": "SENNA"
            },
            "MAGNO-HUMPHRIES LABORATORIES": {
              "BISACODYL EC": "BISACODYL EC",
              "GENTLE LAXATIVE": "GENTLE LAXATIVE"
            },
            "MAJOR PHARMACEUTICALS": {
              "BISACODYL": "BISACODYL",
              "BISACODYL EC": "BISACODYL EC",
              "BISCOLAX": "BISCOLAX",
              "SENNA": "SENNA",
              "SENNA-LAX": "SENNA-LAX"
            },
            "MARLEX PHARMACEUTICALS": {
              "BISACODYL EC": "BISACODYL EC",
              "SENNA LAXATIVE": "SENNA LAXATIVE"
            },
            "MCKESSON": {
              "HM LAXATIVE": "HM LAXATIVE",
              "HM SENNA": "HM SENNA"
            },
            "MCKESSON SUNMARK": {
              "SM CASTOR OIL": "SM CASTOR OIL",
              "SM GENTLE LAXATIVE": "SM GENTLE LAXATIVE",
              "SM LAXATIVE": "SM LAXATIVE",
              "SM LAXATIVE MAXIMUM STRENGTH": "SM LAXATIVE MAXIMUM STRENGTH",
              "SM SENNA LAXATIVE": "SM SENNA LAXATIVE",
              "SM SENNA LAXATIVE MAX ST": "SM SENNA LAXATIVE MAX ST",
              "SM WOMANS LAXATIVE": "SM WOMANS LAXATIVE"
            },
            "MEDICINE SHOPPE": {
              "GENTLE LAXATIVE": "GENTLE LAXATIVE",
              "MEDI-LAX": "MEDI-LAX",
              "MEDI-NATURAL": "MEDI-NATURAL",
              "SENNA LAXATIVE": "SENNA LAXATIVE"
            },
            "MEDTECH": {
              "LITTLE TUMMYS LAXATIVE": "LITTLE TUMMYS LAXATIVE"
            },
            "MEIJER": {
              "LAXATIVE": "LAXATIVE",
              "SENNA LAXATIVE": "SENNA LAXATIVE"
            },
            "MURFREESBORO PHARM NURSING SUP": {
              "BISACODYL EC": "BISACODYL EC"
            },
            "NATURES BOUNTY": {
              "CASCARA SAGRADA": "CASCARA SAGRADA"
            },
            "NEIL LABS": {
              "SENNACON": "SENNACON",
              "STIMULANT LAXATIVE": "STIMULANT LAXATIVE"
            },
            "NEW WORLD IMPORTS": {
              "SENNA LAXATIVE": "SENNA LAXATIVE"
            },
            "NUCARE PHARMACEUTICALS": {
              "SENNA": "SENNA"
            },
            "NUMARK LABS": {
              "VERACOLATE": "VERACOLATE"
            },
            "OAKHURST": {
              "DR EDWARDS OLIVE LAXATIVE": "DR EDWARDS OLIVE LAXATIVE",
              "EVAC-U-GEN": "EVAC-U-GEN"
            },
            "PAR PHARMACEUTICAL": {
              "SENNA LAX": "SENNA LAX"
            },
            "PAR PHARMACEUTICALS": {
              "BISACODYL EC": "BISACODYL EC"
            },
            "PCCA": {
              "BISACODYL": "BISACODYL",
              "CASCARA SAGRADA": "CASCARA SAGRADA"
            },
            "PDRX PHARMACEUTICAL": {
              "BISACODYL EC": "BISACODYL EC",
              "SENNA LAXATIVE": "SENNA LAXATIVE",
              "SENNA-TABS": "SENNA-TABS"
            },
            "PERRIGO": {
              "BISACODYL": "BISACODYL"
            },
            "PERRIGO PHARMACEUTICALS": {
              "WOMENS LAXATIVE": "WOMENS LAXATIVE"
            },
            "PHARBEST PHARMACEUTICALS": {
              "BISACODYL EC": "BISACODYL EC",
              "SENNA-TABS": "SENNA-TABS"
            },
            "PHARMACEUTICAL ASSOCIATES": {
              "SENNA": "SENNA"
            },
            "PLUS PHARMA": {
              "SENNA": "SENNA"
            },
            "PREFERRED PHARMACEUTICALS": {
              "SENNA-LAX": "SENNA-LAX"
            },
            "PUBLIX SUPER MARKETS INC.": {
              "PX LAXATIVE": "PX LAXATIVE",
              "PX VEGETABLE LAXATIVE": "PX VEGETABLE LAXATIVE"
            },
            "QUALITY CARE": {
              "BISACODYL EC": "BISACODYL EC",
              "SENNA": "SENNA",
              "SENNOSIDES": "SENNOSIDES",
              "SENOKOT": "SENOKOT"
            },
            "QUALITY CARE PRODUCTS": {
              "BISACODYL EC": "BISACODYL EC",
              "SENNA": "SENNA",
              "SENNA-LAX": "SENNA-LAX",
              "STIMULANT LAXATIVE": "STIMULANT LAXATIVE"
            },
            "RELIABLE 1 LABS": {
              "BISACODYL EC": "BISACODYL EC",
              "SENNA": "SENNA"
            },
            "REXALL SUNDOWN": {
              "CASCARA SAGRADA": "CASCARA SAGRADA"
            },
            "RITE AID CORPORATION": {
              "RA FAST RELIEF LAXATIVE": "RA FAST RELIEF LAXATIVE",
              "RA LAXATIVE": "RA LAXATIVE",
              "RA LAXATIVE EXTRA STRENGTH": "RA LAXATIVE EXTRA STRENGTH",
              "RA LAXATIVE MAXIMUM STRENGTH": "RA LAXATIVE MAXIMUM STRENGTH",
              "RA SENNA": "RA SENNA",
              "RA STIMULANT LAXATIVE": "RA STIMULANT LAXATIVE",
              "RA WOMENS LAXATIVE": "RA WOMENS LAXATIVE"
            },
            "RUGBY LABORATORIES": {
              "BISACODYL": "BISACODYL",
              "LAXATIVE": "LAXATIVE",
              "SENEXON": "SENEXON",
              "SENNA": "SENNA",
              "STIMULANT LAXATIVE": "STIMULANT LAXATIVE"
            },
            "SAFECOR HEALTH": {
              "SENNA": "SENNA"
            },
            "SDA LABORATORIES INC": {
              "SENNA": "SENNA"
            },
            "SELECT BRAND": {
              "SB BISACODYL LAXATIVE EC": "SB BISACODYL LAXATIVE EC",
              "SB GENTLE LAX-WOMEN": "SB GENTLE LAX-WOMEN",
              "SB GENTLE LAXATIVE WOMENS": "SB GENTLE LAXATIVE WOMENS",
              "SB LAXATIVE": "SB LAXATIVE",
              "SB SENNA-LAX": "SB SENNA-LAX"
            },
            "SHEFFIELD PHARMACEUTICALS": {
              "BLACK DRAUGHT": "BLACK DRAUGHT",
              "EVAC-U-GEN": "EVAC-U-GEN"
            },
            "STRATUS PHARMACEUTICALS": {
              "GENTLE LAXATIVE": "GENTLE LAXATIVE"
            },
            "TARGET": {
              "TGT GENTLE LAXATIVE": "TGT GENTLE LAXATIVE",
              "TGT LAXATIVE PILLS MAX ST": "TGT LAXATIVE PILLS MAX ST",
              "TGT NATURAL LAXATIVE PILLS": "TGT NATURAL LAXATIVE PILLS",
              "TGT SENNA": "TGT SENNA",
              "TGT SENNA LAXATIVE": "TGT SENNA LAXATIVE",
              "TGT WOMENS LAXATIVE": "TGT WOMENS LAXATIVE"
            },
            "TIME-CAP LABS": {
              "BISACODYL EC": "BISACODYL EC",
              "CORRECT": "CORRECT",
              "SENNA-TIME": "SENNA-TIME"
            },
            "TOPCO": {
              "LAXATIVE": "LAXATIVE",
              "SENNA LAXATIVE": "SENNA LAXATIVE",
              "WOMANS LAXATIVE": "WOMANS LAXATIVE"
            },
            "TYCHASIS": {
              "COATS ALOE JUICE DRINK": "COATS ALOE JUICE DRINK"
            },
            "UNIT DOSE SERVICES": {
              "SENNA": "SENNA"
            },
            "VANGARD": {
              "BISACODYL EC": "BISACODYL EC"
            },
            "VI-JON": {
              "CASTOR OIL": "CASTOR OIL"
            },
            "WAL-MART": {
              "CASCARA SAGRADA": "CASCARA SAGRADA",
              "EQ GENTLE LAXATIVE": "EQ GENTLE LAXATIVE",
              "EQ LAXATIVE": "EQ LAXATIVE",
              "EQ LAXATIVE MAXIMUM STRENGTH": "EQ LAXATIVE MAXIMUM STRENGTH",
              "EQ NATURAL LAXATIVE": "EQ NATURAL LAXATIVE",
              "EQ NATURAL VEGETABLE LAXATIVE": "EQ NATURAL VEGETABLE LAXATIVE",
              "EQ VEGETABLE LAXATIVE": "EQ VEGETABLE LAXATIVE",
              "EQ WOMANS LAXATIVE": "EQ WOMANS LAXATIVE",
              "EQ WOMENS LAXATIVE": "EQ WOMENS LAXATIVE"
            },
            "WALGREENS": {
              "CASTOR OIL STIMULANT LAXATIVE": "CASTOR OIL STIMULANT LAXATIVE",
              "CHOCOLATED LAXATIVE": "CHOCOLATED LAXATIVE",
              "GENTLE LAXATIVE": "GENTLE LAXATIVE",
              "LAXATIVE PILLS": "LAXATIVE PILLS",
              "SENNA": "SENNA",
              "WOMANS LAXATIVE": "WOMANS LAXATIVE"
            }
          }
        },
        "Surfactant Laxatives": {
          "Surfactant Laxatives": {
            "A-S MEDICATION SOLUTIONS": {
              "DOCUSATE SODIUM": "DOCUSATE SODIUM",
              "DOK": "DOK",
              "STOOL SOFTENER": "STOOL SOFTENER"
            },
            "AIDAREX PHARMACEUTICALS": {
              "DOCUSATE SODIUM": "DOCUSATE SODIUM",
              "PROMOLAXIN": "PROMOLAXIN",
              "STOOL SOFTENER": "STOOL SOFTENER"
            },
            "AKRON PHARMA": {
              "DOCUSATE SODIUM": "DOCUSATE SODIUM"
            },
            "ALL PHARMA": {
              "DQZATE": "DQZATE"
            },
            "ALTURA PHARMACEUTICALS": {
              "DOCUSATE SODIUM": "DOCUSATE SODIUM"
            },
            "AMERICAN HEALTH PACKAGING": {
              "DOCUSATE SODIUM": "DOCUSATE SODIUM"
            },
            "AMERICAN SALES COMPANY": {
              "STOOL SOFTENER": "STOOL SOFTENER"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP STOOL SOFTENER": "GNP STOOL SOFTENER"
            },
            "ATLANTIC BIOLOGICALS": {
              "DOCUSATE CALCIUM": "DOCUSATE CALCIUM",
              "GNP STOOL SOFTENER EX ST": "GNP STOOL SOFTENER EX ST",
              "SILACE": "SILACE"
            },
            "AUBURN PHARMACEUTICAL": {
              "DOCUSIL": "DOCUSIL"
            },
            "AURORA HEALTHCARE": {
              "DOCUSATE SODIUM": "DOCUSATE SODIUM",
              "DSS": "DSS",
              "STOOL SOFTENER": "STOOL SOFTENER"
            },
            "AVPAK": {
              "DOCUSATE CALCIUM": "DOCUSATE CALCIUM",
              "DOCUSATE SODIUM": "DOCUSATE SODIUM"
            },
            "AVRIO HEALTH LP": {
              "COLACE": "COLACE",
              "COLACE CLEAR": "COLACE CLEAR"
            },
            "BASIC DRUGS": {
              "LAXA BASIC": "LAXA BASIC"
            },
            "BAYER CONSUMER": {
              "CORRECTOL EXTRA GENTLE": "CORRECTOL EXTRA GENTLE",
              "PHILLIPS STOOL SOFTENER": "PHILLIPS STOOL SOFTENER"
            },
            "BENE HEALTH OTC": {
              "STOOL SOFTENER": "STOOL SOFTENER"
            },
            "BERGEN BRUNSWIG": {
              "GNP DOCUSATE CALCIUM": "GNP DOCUSATE CALCIUM",
              "GNP STOOL SOFTENER": "GNP STOOL SOFTENER"
            },
            "BLENHEIM PHARMACAL": {
              "DOCUSATE SODIUM": "DOCUSATE SODIUM"
            },
            "BRYANT RANCH PREPACK": {
              "DOCUSATE SODIUM": "DOCUSATE SODIUM",
              "PROMOLAXIN": "PROMOLAXIN",
              "STOOL SOFTENER": "STOOL SOFTENER"
            },
            "CARDINAL HEALTH REPACKAGING SO": {
              "DOCUSATE SODIUM": "DOCUSATE SODIUM"
            },
            "CHAIN DRUG CONSORTIUM": {
              "GENTLE STOOL SOFTENER": "GENTLE STOOL SOFTENER",
              "STOOL SOFTENER": "STOOL SOFTENER",
              "WOMANS STOOL SOFTENER": "WOMANS STOOL SOFTENER"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC DOCUSATE CALCIUM": "QC DOCUSATE CALCIUM",
              "QC STOOL SOFTENER": "QC STOOL SOFTENER"
            },
            "CHATTEM INC": {
              "DULCOLAX PINK STOOL SOFTENER": "DULCOLAX PINK STOOL SOFTENER",
              "DULCOLAX STOOL SOFTENER": "DULCOLAX STOOL SOFTENER",
              "SURFAK": "SURFAK"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "DOK": "DOK"
            },
            "COSTCO WHOLESALE": {
              "KLS STOOL SOFTENER": "KLS STOOL SOFTENER"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS MINI ENEMA KIDS": "CVS MINI ENEMA KIDS",
              "CVS STOOL SOFTENER": "CVS STOOL SOFTENER"
            },
            "DIRECT RX": {
              "DOCQLACE": "DOCQLACE",
              "DOCUSATE SODIUM": "DOCUSATE SODIUM"
            },
            "ENEMEEZ": {
              "DOCUSOL KIDS": "DOCUSOL KIDS",
              "DOCUSOL MINI": "DOCUSOL MINI",
              "DOCUSOL PLUS MINI-ENEMA": "DOCUSOL PLUS MINI-ENEMA",
              "ENEMEEZ MINI": "ENEMEEZ MINI",
              "ENEMEEZ PLUS": "ENEMEEZ PLUS"
            },
            "EQUALINE": {
              "EQL STOOL SOFTENER": "EQL STOOL SOFTENER"
            },
            "FAGRON": {
              "DOCUSATE SODIUM": "DOCUSATE SODIUM"
            },
            "FLEET PHARMACEUTICALS": {
              "PEDIA-LAX": "PEDIA-LAX",
              "SOF-LAX": "SOF-LAX"
            },
            "GEISS DESTIN & DUNN": {
              "GOODSENSE STOOL SOFTENER": "GOODSENSE STOOL SOFTENER"
            },
            "GERI-CARE": {
              "DIOCTO": "DIOCTO",
              "DOCUSATE CALCIUM": "DOCUSATE CALCIUM",
              "DOCUSATE SODIUM": "DOCUSATE SODIUM",
              "STOOL SOFTENER": "STOOL SOFTENER"
            },
            "GERITREX CORPORATION": {
              "DOCUSATE SODIUM": "DOCUSATE SODIUM"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "DOCUPRENE": "DOCUPRENE",
              "DOCUSATE SODIUM": "DOCUSATE SODIUM"
            },
            "HANNAFORD BROTHERS": {
              "STOOL SOFTENER": "STOOL SOFTENER"
            },
            "HI-TECH": {
              "DOCU": "DOCU"
            },
            "LEADER BRAND PRODUCTS": {
              "STOOL SOFTENER": "STOOL SOFTENER",
              "STOOL SOFTENER LAXATIVE": "STOOL SOFTENER LAXATIVE"
            },
            "LIBERTY PHARMACEUTICAL": {
              "DOCUSATE CALCIUM": "DOCUSATE CALCIUM",
              "DOCUSATE SODIUM": "DOCUSATE SODIUM"
            },
            "MAGNO-HUMPHRIES": {
              "STOOL SOFTENER": "STOOL SOFTENER"
            },
            "MAGNO-HUMPHRIES LABORATORIES": {
              "DSS": "DSS"
            },
            "MAJOR PHARMACEUTICALS": {
              "DOCUSATE CALCIUM": "DOCUSATE CALCIUM",
              "DOCUSATE MINI": "DOCUSATE MINI",
              "DOCUSATE SODIUM": "DOCUSATE SODIUM",
              "DOK": "DOK",
              "KAO-TIN": "KAO-TIN"
            },
            "MARLEX PHARMACEUTICALS": {
              "DOCUSATE CALCIUM": "DOCUSATE CALCIUM",
              "DOCUSATE SODIUM": "DOCUSATE SODIUM"
            },
            "MATERNAL SCIENCE": {
              "HEALTHY MAMA MOVE IT ALONG": "HEALTHY MAMA MOVE IT ALONG"
            },
            "MCKESSON": {
              "HM STOOL SOFTENER": "HM STOOL SOFTENER"
            },
            "MCKESSON SUNMARK": {
              "SM DOCUSATE CALCIUM": "SM DOCUSATE CALCIUM",
              "SM STOOL SOFTENER": "SM STOOL SOFTENER"
            },
            "MEDLINE/DERMAL MANAGEMENT": {
              "DOCUSATE SODIUM": "DOCUSATE SODIUM"
            },
            "NATIONAL VITAMIN": {
              "DOCUSATE CALCIUM": "DOCUSATE CALCIUM",
              "DOCUSATE SODIUM": "DOCUSATE SODIUM",
              "DSS": "DSS"
            },
            "NUCARE PHARMACEUTICALS": {
              "DOCUSATE SODIUM": "DOCUSATE SODIUM"
            },
            "PAR PHARMACEUTICALS": {
              "DOCQLACE": "DOCQLACE"
            },
            "PCCA": {
              "DOCUSATE SODIUM": "DOCUSATE SODIUM"
            },
            "PDRX PHARMACEUTICAL": {
              "DOCUSATE CALCIUM": "DOCUSATE CALCIUM",
              "DOCUSATE SODIUM": "DOCUSATE SODIUM"
            },
            "PERRIGO PHARMACEUTICALS": {
              "DOCUSATE SODIUM": "DOCUSATE SODIUM",
              "STOOL SOFTENER": "STOOL SOFTENER"
            },
            "PHARBEST PHARMACEUTICALS": {
              "DOCUSATE SODIUM": "DOCUSATE SODIUM"
            },
            "PHARMACEUTICA NORTH AMERICA": {
              "DOCUPRENE": "DOCUPRENE"
            },
            "PHARMACEUTICAL ASSOCIATES": {
              "DOCUSATE SODIUM": "DOCUSATE SODIUM"
            },
            "PHYSICIANS SCIENCE AND NATURE": {
              "PROMOLAXIN": "PROMOLAXIN"
            },
            "PL DEVELOPMENTS": {
              "KS STOOL SOFTENER": "KS STOOL SOFTENER"
            },
            "PLUS PHARMA": {
              "DOCUSATE SODIUM": "DOCUSATE SODIUM"
            },
            "PRECISION DOSE, INC": {
              "DOCUSATE SODIUM": "DOCUSATE SODIUM"
            },
            "PREFERRED PHARMACEUTICALS": {
              "DOCUSATE SODIUM": "DOCUSATE SODIUM",
              "PROMOLAXIN": "PROMOLAXIN"
            },
            "PROFICIENT RX": {
              "PROMOLAXIN": "PROMOLAXIN"
            },
            "PUBLIX SUPER MARKETS INC.": {
              "PX DOCUSATE SODIUM": "PX DOCUSATE SODIUM"
            },
            "QUALITY CARE": {
              "COLACE": "COLACE",
              "DOCQLACE": "DOCQLACE",
              "DOCUSATE SODIUM": "DOCUSATE SODIUM",
              "PROMOLAXIN": "PROMOLAXIN"
            },
            "QUALITY CARE PRODUCTS": {
              "DOK": "DOK"
            },
            "REESE PHARMACEUTICAL": {
              "DOCU SOFT": "DOCU SOFT"
            },
            "RELIABLE 1 LABS": {
              "DOCUSATE SODIUM": "DOCUSATE SODIUM"
            },
            "RICHMOND PHARMACEUTICALS": {
              "DOCUSATE SODIUM": "DOCUSATE SODIUM"
            },
            "RITE AID CORPORATION": {
              "RA COL-RITE": "RA COL-RITE",
              "RA STOOL SOFTENER": "RA STOOL SOFTENER"
            },
            "RUGBY LABORATORIES": {
              "DIOCTO": "DIOCTO",
              "DOCUSATE SODIUM": "DOCUSATE SODIUM",
              "STOOL SOFTENER": "STOOL SOFTENER"
            },
            "RXCHANGE CO": {
              "DOCUSATE SODIUM": "DOCUSATE SODIUM"
            },
            "SAFECOR HEALTH": {
              "DOCUSATE SODIUM": "DOCUSATE SODIUM"
            },
            "SAM'S WEST": {
              "MM STOOL SOFTENER LAXATIVE": "MM STOOL SOFTENER LAXATIVE"
            },
            "SDA LABORATORIES INC": {
              "STOOL SOFTENER": "STOOL SOFTENER"
            },
            "SELECT BRAND": {
              "SB DOCUSATE SODIUM": "SB DOCUSATE SODIUM",
              "SB STOOL SOFTENER": "SB STOOL SOFTENER"
            },
            "SILARX": {
              "SILACE": "SILACE"
            },
            "SKY PACKAGING": {
              "DOCUSATE SODIUM": "DOCUSATE SODIUM"
            },
            "SPECTRUM": {
              "DOCUSATE SODIUM": "DOCUSATE SODIUM"
            },
            "ST MARY'S MPP": {
              "DOCUSATE SODIUM": "DOCUSATE SODIUM"
            },
            "TARGET": {
              "TGT STOOL SOFTENER": "TGT STOOL SOFTENER"
            },
            "TOPCO": {
              "STOOL SOFTENER": "STOOL SOFTENER"
            },
            "UNIT DOSE SERVICES": {
              "PROMOLAXIN": "PROMOLAXIN",
              "STOOL SOFTENER": "STOOL SOFTENER"
            },
            "VANGARD": {
              "DOCUSATE SODIUM": "DOCUSATE SODIUM"
            },
            "WAL-MART": {
              "EQ STOOL SOFTENER": "EQ STOOL SOFTENER",
              "MM STOOL SOFTENER": "MM STOOL SOFTENER",
              "STOOL SOFTENER": "STOOL SOFTENER"
            },
            "WALGREENS": {
              "EASY-LAX": "EASY-LAX",
              "STOOL SOFTENER": "STOOL SOFTENER"
            }
          }
        }
      },
      "LOCAL ANESTHETICS-Parenteral": {
        "Local Anesthetic Combinations": {
          "Local Anesthetic & Sympathomimetic": {
            "A-S MEDICATION SOLUTIONS": {
              "LIDOCAINE-EPINEPHRINE": "LIDOCAINE-EPINEPHRINE",
              "MARCAINE/EPINEPHRINE": "MARCAINE/EPINEPHRINE"
            },
            "BENCO DENTAL COMPANY": {
              "LIDOCAINE-EPINEPHRINE": "LIDOCAINE-EPINEPHRINE"
            },
            "DENTSPLY PHARMACEUTICAL": {
              "ARTICADENT DENTAL": "ARTICADENT DENTAL",
              "CITANEST FORTE DENTAL": "CITANEST FORTE DENTAL",
              "XYLOCAINE DENTAL": "XYLOCAINE DENTAL"
            },
            "EASTMAN KODAK DENTAL SYSTEMS": {
              "LIDOCAINE-EPINEPHRINE": "LIDOCAINE-EPINEPHRINE"
            },
            "FAGRON": {
              "LETS": "LETS"
            },
            "FRESENIUS KABI USA": {
              "SENSORCAINE-MPF/EPINEPHRINE": "SENSORCAINE-MPF/EPINEPHRINE",
              "SENSORCAINE/EPINEPHRINE": "SENSORCAINE/EPINEPHRINE",
              "XYLOCAINE-MPF/EPINEPHRINE": "XYLOCAINE-MPF/EPINEPHRINE",
              "XYLOCAINE/EPINEPHRINE": "XYLOCAINE/EPINEPHRINE"
            },
            "HOSPIRA": {
              "BUPIVACAINE-EPINEPHRINE": "BUPIVACAINE-EPINEPHRINE",
              "BUPIVACAINE-EPINEPHRINE (PF)": "BUPIVACAINE-EPINEPHRINE (PF)",
              "LIDOCAINE-EPINEPHRINE": "LIDOCAINE-EPINEPHRINE",
              "MARCAINE/EPINEPHRINE": "MARCAINE/EPINEPHRINE",
              "MARCAINE/EPINEPHRINE PF": "MARCAINE/EPINEPHRINE PF"
            },
            "NEPHRON STERILE COMPOUNDING": {
              "BUPIVACAINE-EPINEPHRINE": "BUPIVACAINE-EPINEPHRINE",
              "ROPIV-CLONIDINE-KETOROLAC": "ROPIV-CLONIDINE-KETOROLAC"
            },
            "NOVAPLUS/HOSPIRA": {
              "BUPIVACAINE-EPINEPHRINE (PF)": "BUPIVACAINE-EPINEPHRINE (PF)"
            },
            "PIERREL": {
              "ORABLOC": "ORABLOC"
            },
            "QUALITY CARE": {
              "MARCAINE/EPINEPHRINE": "MARCAINE/EPINEPHRINE"
            },
            "QUVA PHARMA": {
              "RECK": "RECK"
            },
            "RX PHARMA PACK": {
              "D-CARE 100X": "D-CARE 100X"
            },
            "SA3": {
              "BL INJECTION": "BL INJECTION"
            }
          },
          "Local Anesthetic Combinations": {
            "ENOVACHEM MANUFACTURING": {
              "MARLIDO": "MARLIDO",
              "MARLIDO-25": "MARLIDO-25",
              "MARVONA SUIK": "MARVONA SUIK"
            },
            "MAS MANAGEMENT GROUP": {
              "MLD": "MLD"
            },
            "NEPHRON STERILE COMPOUNDING": {
              "LIDOCAINE-SODIUM BICARBONATE": "LIDOCAINE-SODIUM BICARBONATE"
            },
            "NUBRATORI": {
              "LIDOMAR": "LIDOMAR"
            },
            "NUCARE PHARMACEUTICALS": {
              "POINT OF CARE LM-2.2": "POINT OF CARE LM-2.2",
              "POINT OF CARE LM-2.5": "POINT OF CARE LM-2.5"
            },
            "PHARMACEUTICA NORTH AMERICA": {
              "ACTIVE INJECTION LM-2": "ACTIVE INJECTION LM-2"
            },
            "PHARMEDIUM SERVICES": {
              "BUFFERED LIDOCAINE": "BUFFERED LIDOCAINE",
              "LIDOCAINE HCL-SODIUM CHLORIDE": "LIDOCAINE HCL-SODIUM CHLORIDE",
              "LIDOCAINE-SODIUM BICARBONATE": "LIDOCAINE-SODIUM BICARBONATE"
            },
            "QUVA PHARMA": {
              "LIDOCAINE-SODIUM BICARBONATE": "LIDOCAINE-SODIUM BICARBONATE"
            },
            "RX PHARMA PACK": {
              "P-CARE 100MX": "P-CARE 100MX",
              "P-CARE MG": "P-CARE MG"
            },
            "TERRAIN PHARMACEUTICALS": {
              "READYSHARP-A": "READYSHARP-A"
            }
          }
        },
        "Local Anesthetics - Amides": {
          "Local Anesthetics - Amides": {
            "7T PHARMA": {
              "ZINGO": "ZINGO"
            },
            "A-S MEDICATION SOLUTIONS": {
              "LIDOCAINE HCL": "LIDOCAINE HCL",
              "LIDOCAINE HCL (PF)": "LIDOCAINE HCL (PF)",
              "MARCAINE": "MARCAINE",
              "MARCAINE PRESERVATIVE FREE": "MARCAINE PRESERVATIVE FREE",
              "XYLOCAINE": "XYLOCAINE"
            },
            "AKORN": {
              "ROPIVACAINE HCL": "ROPIVACAINE HCL"
            },
            "ALVIX LABORATORIES": {
              "BUPIVACAINE HCL": "BUPIVACAINE HCL"
            },
            "AMERICAN PHARMA INGREDIENTS": {
              "BUPIVACAINE HCL": "BUPIVACAINE HCL"
            },
            "API SOLUTIONS": {
              "BUPIVACAINE HCL": "BUPIVACAINE HCL"
            },
            "APOTHECA SUPPLY": {
              "BUPIVACAINE HCL": "BUPIVACAINE HCL"
            },
            "AREVA PHARMACEUTICALS": {
              "BUPIVACAINE FISIOPHARMA": "BUPIVACAINE FISIOPHARMA"
            },
            "ATTIX PHARMACEUTICALS": {
              "BUPIVACAINE HCL": "BUPIVACAINE HCL"
            },
            "AUROMEDICS PHARMA": {
              "BUPIVACAINE HCL": "BUPIVACAINE HCL",
              "BUPIVACAINE HCL (PF)": "BUPIVACAINE HCL (PF)",
              "LIDOCAINE HCL": "LIDOCAINE HCL",
              "LIDOCAINE HCL (PF)": "LIDOCAINE HCL (PF)",
              "ROPIVACAINE HCL": "ROPIVACAINE HCL"
            },
            "B & B PHARMACEUTICALS": {
              "BUPIVACAINE HCL": "BUPIVACAINE HCL"
            },
            "BAXTER HEALTHCARE CORP": {
              "BUPIVACAINE IN DEXTROSE": "BUPIVACAINE IN DEXTROSE",
              "ROPIVACAINE HCL": "ROPIVACAINE HCL"
            },
            "BD MEDICAL SURGICAL SYSTEMS": {
              "BD PUDENDAL/LOCAL TRAY/1% LIDO": "BD PUDENDAL/LOCAL TRAY/1% LIDO",
              "DURASAFE SPINAL/EPIDURAL TRAY": "DURASAFE SPINAL/EPIDURAL TRAY"
            },
            "BPI LABS LLC": {
              "BUPIVACAINE HCL": "BUPIVACAINE HCL"
            },
            "CIVICA": {
              "LIDOCAINE HCL (PF)": "LIDOCAINE HCL (PF)"
            },
            "COVIDIEN MEDICAL SUPPLIES": {
              "MONOJECT BONE MARROW BIOPSY": "MONOJECT BONE MARROW BIOPSY",
              "MONOJECT MARROW BIOPSY TRAY": "MONOJECT MARROW BIOPSY TRAY",
              "TURKEL PARACENTESIS TRAY 8FR": "TURKEL PARACENTESIS TRAY 8FR",
              "TURKEL PNEUMOTHORAX TRAY 8FR": "TURKEL PNEUMOTHORAX TRAY 8FR"
            },
            "DENTSPLY PHARMACEUTICAL": {
              "CITANEST PLAIN DENTAL": "CITANEST PLAIN DENTAL"
            },
            "ENOVACHEM MANUFACTURING": {
              "BUPIVACAINE HCL (PF)": "BUPIVACAINE HCL (PF)",
              "LIDOCAINE HCL (PF)": "LIDOCAINE HCL (PF)"
            },
            "FAGRON": {
              "BUPIVACAINE HCL": "BUPIVACAINE HCL"
            },
            "FREEDOM PHARMACEUTICALS": {
              "BUPIVACAINE HCL": "BUPIVACAINE HCL"
            },
            "FRESENIUS KABI USA": {
              "LIDOCAINE HCL": "LIDOCAINE HCL",
              "NAROPIN": "NAROPIN",
              "POLOCAINE": "POLOCAINE",
              "POLOCAINE-MPF": "POLOCAINE-MPF",
              "SENSORCAINE": "SENSORCAINE",
              "SENSORCAINE-MPF": "SENSORCAINE-MPF",
              "SENSORCAINE-MPF SPINAL": "SENSORCAINE-MPF SPINAL",
              "XYLOCAINE": "XYLOCAINE",
              "XYLOCAINE-MPF": "XYLOCAINE-MPF"
            },
            "GENPAK SOLUTIONS": {
              "BUPIVACAINE HCL": "BUPIVACAINE HCL"
            },
            "HIKMA": {
              "LIDOCAINE HCL": "LIDOCAINE HCL",
              "LIDOCAINE HCL (PF)": "LIDOCAINE HCL (PF)"
            },
            "HOSPIRA": {
              "BUPIVACAINE HCL": "BUPIVACAINE HCL",
              "BUPIVACAINE HCL (PF)": "BUPIVACAINE HCL (PF)",
              "BUPIVACAINE SPINAL": "BUPIVACAINE SPINAL",
              "CARBOCAINE": "CARBOCAINE",
              "CARBOCAINE PRESERVATIVE-FREE": "CARBOCAINE PRESERVATIVE-FREE",
              "LIDOCAINE HCL": "LIDOCAINE HCL",
              "LIDOCAINE HCL (PF)": "LIDOCAINE HCL (PF)",
              "LIDOCAINE IN DEXTROSE": "LIDOCAINE IN DEXTROSE",
              "MARCAINE": "MARCAINE",
              "MARCAINE PRESERVATIVE FREE": "MARCAINE PRESERVATIVE FREE",
              "MARCAINE SPINAL": "MARCAINE SPINAL",
              "ROPIVACAINE HCL": "ROPIVACAINE HCL"
            },
            "HUMCO": {
              "BUPIVACAINE HCL": "BUPIVACAINE HCL"
            },
            "HURON PHARMACEUTICALS": {
              "BUPIVACAINE HCL": "BUPIVACAINE HCL"
            },
            "LEITER'S ENTERPRISES": {
              "ROPIVACAINE HCL-NACL": "ROPIVACAINE HCL-NACL"
            },
            "LEITERS HEALTH": {
              "LIDOCAINE HCL": "LIDOCAINE HCL",
              "ROPIVACAINE HCL": "ROPIVACAINE HCL",
              "ROPIVACAINE HCL-NACL": "ROPIVACAINE HCL-NACL"
            },
            "LETCO MEDICAL": {
              "BUPIVACAINE HCL": "BUPIVACAINE HCL"
            },
            "MEDICAP LABORATORIES": {
              "BUPIVACAINE HCL": "BUPIVACAINE HCL"
            },
            "MEDISCA": {
              "BUPIVACAINE HCL": "BUPIVACAINE HCL"
            },
            "MEDLINE/DERMAL MANAGEMENT": {
              "ZINGO": "ZINGO"
            },
            "MYLAN INSTITUTIONAL": {
              "LIDOCAINE HCL (PF)": "LIDOCAINE HCL (PF)",
              "ROPIVACAINE HCL": "ROPIVACAINE HCL"
            },
            "NEPHRON STERILE COMPOUNDING": {
              "BUPIVACAINE HCL": "BUPIVACAINE HCL",
              "BUPIVACAINE IN DEXTROSE": "BUPIVACAINE IN DEXTROSE",
              "LIDOCAINE HCL": "LIDOCAINE HCL",
              "ROPIVACAINE HCL": "ROPIVACAINE HCL"
            },
            "NOVAPLUS/FRESENIUS KABI": {
              "NAROPIN": "NAROPIN"
            },
            "NOVAPLUS/HOSPIRA": {
              "BUPIVACAINE HCL (PF)": "BUPIVACAINE HCL (PF)"
            },
            "OAKLOCK": {
              "LIDOMARK 1/5": "LIDOMARK 1/5",
              "LIDOMARK 2/5": "LIDOMARK 2/5"
            },
            "PACIRA PHARMACEUTICALS, INC.": {
              "EXPAREL": "EXPAREL"
            },
            "PCCA": {
              "BUPIVACAINE HCL": "BUPIVACAINE HCL"
            },
            "PENTEC HEALTH": {
              "BUPIVACAINE HCL": "BUPIVACAINE HCL",
              "ROPIVACAINE HCL": "ROPIVACAINE HCL"
            },
            "PHARMEDIUM SERVICES": {
              "BUPIVACAINE HCL": "BUPIVACAINE HCL",
              "BUPIVACAINE HCL-NACL": "BUPIVACAINE HCL-NACL",
              "LIDOCAINE HCL": "LIDOCAINE HCL",
              "ROPIVACAINE HCL": "ROPIVACAINE HCL",
              "ROPIVACAINE HCL-NACL": "ROPIVACAINE HCL-NACL"
            },
            "QUALITY CARE": {
              "LIDOCAINE HCL": "LIDOCAINE HCL"
            },
            "QUVA PHARMA": {
              "BUPIVACAINE HCL-NACL": "BUPIVACAINE HCL-NACL",
              "LIDOCAINE HCL": "LIDOCAINE HCL",
              "ROPIVACAINE HCL-NACL": "ROPIVACAINE HCL-NACL"
            },
            "RX PHARMA PACK": {
              "P-CARE M": "P-CARE M",
              "P-CARE X": "P-CARE X"
            },
            "SA3": {
              "LIDOCAINE HCL": "LIDOCAINE HCL"
            },
            "SAGENT PHARMACEUTICAL": {
              "ROPIVACAINE HCL": "ROPIVACAINE HCL"
            },
            "SANDOZ": {
              "ROPIVACAINE HCL": "ROPIVACAINE HCL"
            },
            "SOMERSET THERAPEUTICS": {
              "ROPIVACAINE HCL": "ROPIVACAINE HCL"
            },
            "SPECTRA MEDICAL DEVICES": {
              "LIDOCAINE HCL (PF)": "LIDOCAINE HCL (PF)"
            },
            "SPECTRUM": {
              "BUPIVACAINE HCL": "BUPIVACAINE HCL",
              "MEPIVACAINE HCL": "MEPIVACAINE HCL"
            },
            "TERRAIN PHARMACEUTICALS": {
              "READYSHARP BUPIVACAINE": "READYSHARP BUPIVACAINE",
              "READYSHARP LIDOCAINE": "READYSHARP LIDOCAINE"
            },
            "TOTAL PHARMACY SUPPLY": {
              "BUPIVACAINE HCL": "BUPIVACAINE HCL"
            },
            "TRIOVA PHARMACEUTICALS": {
              "BUPIVACAINE HCL": "BUPIVACAINE HCL"
            },
            "WILLOW BIRCH PHARMA": {
              "BUPIVACAINE HCL": "BUPIVACAINE HCL"
            }
          }
        },
        "Local Anesthetics - Esters": {
          "Local Anesthetics - Esters": {
            "AKORN": {
              "TETRACAINE HCL": "TETRACAINE HCL"
            },
            "B. BRAUN MEDICAL": {
              "CLOROTEKAL": "CLOROTEKAL"
            },
            "BPI LABS LLC": {
              "TETRACAINE HCL": "TETRACAINE HCL"
            },
            "FAGRON": {
              "PROCAINE HCL": "PROCAINE HCL"
            },
            "FRESENIUS KABI USA": {
              "NESACAINE": "NESACAINE",
              "NESACAINE-MPF": "NESACAINE-MPF"
            },
            "HIKMA": {
              "CHLOROPROCAINE HCL (PF)": "CHLOROPROCAINE HCL (PF)"
            },
            "PCCA": {
              "PROCAINE HCL": "PROCAINE HCL"
            },
            "SPECTRUM": {
              "PROCAINE HCL": "PROCAINE HCL"
            }
          }
        }
      },
      "MACROLIDES": {
        "Azithromycin": {
          "Azithromycin": {
            "A-S MEDICATION SOLUTIONS": {
              "AZITHROMYCIN": "AZITHROMYCIN",
              "ZITHROMAX": "ZITHROMAX",
              "ZITHROMAX TRI-PAK": "ZITHROMAX TRI-PAK",
              "ZITHROMAX Z-PAK": "ZITHROMAX Z-PAK"
            },
            "AIDAREX PHARMACEUTICALS": {
              "AZITHROMYCIN": "AZITHROMYCIN"
            },
            "ALEMBIC PHARMACEUTICALS": {
              "AZITHROMYCIN": "AZITHROMYCIN"
            },
            "ALTURA PHARMACEUTICALS": {
              "AZITHROMYCIN": "AZITHROMYCIN",
              "ZITHROMAX": "ZITHROMAX"
            },
            "AMERICAN HEALTH PACKAGING": {
              "AZITHROMYCIN": "AZITHROMYCIN"
            },
            "AMERINET/PFIZER": {
              "ZITHROMAX": "ZITHROMAX"
            },
            "APHENA PHARMA SOLUTIONS": {
              "AZITHROMYCIN": "AZITHROMYCIN"
            },
            "APOTEX": {
              "AZITHROMYCIN": "AZITHROMYCIN"
            },
            "AQ PHARMACEUTICALS": {
              "AZITHROMYCIN": "AZITHROMYCIN",
              "ZITHROMAX": "ZITHROMAX"
            },
            "ATHENEX PHARMACEUTICAL": {
              "AZITHROMYCIN": "AZITHROMYCIN"
            },
            "AUROBINDO PHARMA": {
              "AZITHROMYCIN": "AZITHROMYCIN"
            },
            "AUROMEDICS PHARMA": {
              "AZITHROMYCIN": "AZITHROMYCIN"
            },
            "AVPAK": {
              "AZITHROMYCIN": "AZITHROMYCIN"
            },
            "BIONPHARMA": {
              "AZITHROMYCIN": "AZITHROMYCIN"
            },
            "BRYANT RANCH PREPACK": {
              "AZITHROMYCIN": "AZITHROMYCIN"
            },
            "CAMBRIDGE THERAPEUTICS TECHN": {
              "AZITHROMYCIN": "AZITHROMYCIN"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "AZITHROMYCIN": "AZITHROMYCIN"
            },
            "DIRECT RX": {
              "AZITHROMYCIN": "AZITHROMYCIN"
            },
            "ENOVACHEM MANUFACTURING": {
              "AZITHROMYCIN": "AZITHROMYCIN"
            },
            "EPIC PHARMA": {
              "AZITHROMYCIN": "AZITHROMYCIN"
            },
            "FRESENIUS KABI USA": {
              "AZITHROMYCIN": "AZITHROMYCIN"
            },
            "GREENSTONE": {
              "AZITHROMYCIN": "AZITHROMYCIN"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "AZITHROMYCIN": "AZITHROMYCIN",
              "ZITHROMAX": "ZITHROMAX",
              "ZITHROMAX Z-PAK": "ZITHROMAX Z-PAK"
            },
            "HOSPIRA": {
              "AZITHROMYCIN": "AZITHROMYCIN"
            },
            "LANNETT": {
              "AZITHROMYCIN": "AZITHROMYCIN"
            },
            "LIBERTY PHARMACEUTICAL": {
              "AZITHROMYCIN": "AZITHROMYCIN"
            },
            "LUPIN PHARMACEUTICALS": {
              "AZITHROMYCIN": "AZITHROMYCIN"
            },
            "MAJOR PHARMACEUTICALS": {
              "AZITHROMYCIN": "AZITHROMYCIN"
            },
            "MAS MANAGEMENT GROUP": {
              "AZITHROMYCIN": "AZITHROMYCIN"
            },
            "MEDVANTX": {
              "ZITHROMAX Z-PAK": "ZITHROMAX Z-PAK"
            },
            "MYLAN INSTITUTIONAL": {
              "AZITHROMYCIN": "AZITHROMYCIN"
            },
            "NOVAPLUS/FRESENIUS KABI": {
              "AZITHROMYCIN": "AZITHROMYCIN"
            },
            "NUCARE PHARMACEUTICALS": {
              "AZITHROMYCIN": "AZITHROMYCIN",
              "ZITHROMAX": "ZITHROMAX"
            },
            "PDRX PHARMACEUTICAL": {
              "AZITHROMYCIN": "AZITHROMYCIN",
              "ZITHROMAX": "ZITHROMAX"
            },
            "PDRX PHARMACEUTICALS": {
              "AZITHROMYCIN": "AZITHROMYCIN"
            },
            "PFIZER U.S.": {
              "ZITHROMAX": "ZITHROMAX",
              "ZITHROMAX TRI-PAK": "ZITHROMAX TRI-PAK",
              "ZITHROMAX Z-PAK": "ZITHROMAX Z-PAK",
              "ZMAX": "ZMAX"
            },
            "PHARMEDIX": {
              "AZITHROMYCIN": "AZITHROMYCIN"
            },
            "PREFERRED PHARMACEUTICALS": {
              "AZITHROMYCIN": "AZITHROMYCIN"
            },
            "PROFICIENT RX": {
              "AZITHROMYCIN": "AZITHROMYCIN"
            },
            "QUALITY CARE": {
              "AZITHROMYCIN": "AZITHROMYCIN",
              "ZITHROMAX": "ZITHROMAX"
            },
            "QUALITY CARE PRODUCTS": {
              "AZITHROMYCIN": "AZITHROMYCIN"
            },
            "RISING PHARMACEUTICALS": {
              "AZITHROMYCIN": "AZITHROMYCIN"
            },
            "RXCHANGE CO": {
              "AZITHROMYCIN": "AZITHROMYCIN",
              "ZITHROMAX": "ZITHROMAX"
            },
            "SAGENT PHARMACEUTICAL": {
              "AZITHROMYCIN": "AZITHROMYCIN"
            },
            "SANDOZ": {
              "AZITHROMYCIN": "AZITHROMYCIN"
            },
            "SKY PACKAGING": {
              "AZITHROMYCIN": "AZITHROMYCIN"
            },
            "SLATE RUN PHARMACEUTICALS": {
              "AZITHROMYCIN": "AZITHROMYCIN"
            },
            "ST MARY'S MPP": {
              "AZITHROMYCIN": "AZITHROMYCIN"
            },
            "SUN PHARMACEUTICALS": {
              "AZITHROMYCIN": "AZITHROMYCIN"
            },
            "TAGI PHARMA": {
              "AZITHROMYCIN": "AZITHROMYCIN"
            },
            "TEVA PHARMACEUTICALS USA": {
              "AZITHROMYCIN": "AZITHROMYCIN"
            },
            "UNIT DOSE SERVICES": {
              "AZITHROMYCIN": "AZITHROMYCIN"
            },
            "WOCKHARDT USA": {
              "AZITHROMYCIN": "AZITHROMYCIN"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "AZITHROMYCIN": "AZITHROMYCIN"
            }
          }
        },
        "Clarithromycin": {
          "Clarithromycin": {
            "A-S MEDICATION SOLUTIONS": {
              "BIAXIN": "BIAXIN",
              "CLARITHROMYCIN": "CLARITHROMYCIN",
              "CLARITHROMYCIN ER": "CLARITHROMYCIN ER"
            },
            "ABBVIE": {
              "BIAXIN": "BIAXIN"
            },
            "ACTAVIS PHARMA": {
              "CLARITHROMYCIN ER": "CLARITHROMYCIN ER"
            },
            "AIDAREX PHARMACEUTICALS": {
              "CLARITHROMYCIN": "CLARITHROMYCIN",
              "CLARITHROMYCIN ER": "CLARITHROMYCIN ER"
            },
            "AMERICAN HEALTH PACKAGING": {
              "CLARITHROMYCIN": "CLARITHROMYCIN"
            },
            "APOTEX": {
              "CLARITHROMYCIN": "CLARITHROMYCIN"
            },
            "AQ PHARMACEUTICALS": {
              "BIAXIN": "BIAXIN"
            },
            "AUROBINDO PHARMA": {
              "CLARITHROMYCIN": "CLARITHROMYCIN"
            },
            "AVKARE": {
              "CLARITHROMYCIN ER": "CLARITHROMYCIN ER"
            },
            "AVPAK": {
              "CLARITHROMYCIN": "CLARITHROMYCIN"
            },
            "BLENHEIM PHARMACAL": {
              "CLARITHROMYCIN": "CLARITHROMYCIN"
            },
            "BRYANT RANCH PREPACK": {
              "CLARITHROMYCIN": "CLARITHROMYCIN",
              "CLARITHROMYCIN ER": "CLARITHROMYCIN ER"
            },
            "CADISTA": {
              "CLARITHROMYCIN": "CLARITHROMYCIN"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "CLARITHROMYCIN": "CLARITHROMYCIN"
            },
            "DIRECT RX": {
              "CLARITHROMYCIN": "CLARITHROMYCIN"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "BIAXIN": "BIAXIN",
              "CLARITHROMYCIN": "CLARITHROMYCIN"
            },
            "HIKMA": {
              "CLARITHROMYCIN": "CLARITHROMYCIN"
            },
            "LANNETT": {
              "CLARITHROMYCIN": "CLARITHROMYCIN",
              "CLARITHROMYCIN ER": "CLARITHROMYCIN ER"
            },
            "LIBERTY PHARMACEUTICAL": {
              "CLARITHROMYCIN": "CLARITHROMYCIN",
              "CLARITHROMYCIN ER": "CLARITHROMYCIN ER"
            },
            "MAJOR PHARMACEUTICALS": {
              "CLARITHROMYCIN": "CLARITHROMYCIN"
            },
            "MAYNE PHARMA": {
              "CLARITHROMYCIN ER": "CLARITHROMYCIN ER"
            },
            "MEDVANTX": {
              "BIAXIN": "BIAXIN"
            },
            "NUCARE PHARMACEUTICALS": {
              "BIAXIN": "BIAXIN",
              "CLARITHROMYCIN": "CLARITHROMYCIN"
            },
            "PDRX PHARMACEUTICAL": {
              "BIAXIN": "BIAXIN",
              "CLARITHROMYCIN": "CLARITHROMYCIN",
              "CLARITHROMYCIN ER": "CLARITHROMYCIN ER"
            },
            "PHARMEDIX": {
              "CLARITHROMYCIN": "CLARITHROMYCIN"
            },
            "PREFERRED PHARMACEUTICALS": {
              "CLARITHROMYCIN": "CLARITHROMYCIN"
            },
            "PROFICIENT RX": {
              "CLARITHROMYCIN": "CLARITHROMYCIN"
            },
            "QUALITY CARE": {
              "BIAXIN": "BIAXIN",
              "CLARITHROMYCIN": "CLARITHROMYCIN"
            },
            "QUALITY CARE PRODUCTS": {
              "CLARITHROMYCIN": "CLARITHROMYCIN"
            },
            "RISING PHARMACEUTICALS": {
              "CLARITHROMYCIN": "CLARITHROMYCIN"
            },
            "RXCHANGE CO": {
              "CLARITHROMYCIN": "CLARITHROMYCIN"
            },
            "SANDOZ": {
              "CLARITHROMYCIN": "CLARITHROMYCIN"
            },
            "ST MARY'S MPP": {
              "CLARITHROMYCIN": "CLARITHROMYCIN"
            },
            "STRIDES PHARMA": {
              "CLARITHROMYCIN": "CLARITHROMYCIN"
            },
            "TEVA PHARMACEUTICALS USA": {
              "CLARITHROMYCIN": "CLARITHROMYCIN",
              "CLARITHROMYCIN ER": "CLARITHROMYCIN ER"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "CLARITHROMYCIN": "CLARITHROMYCIN"
            }
          }
        },
        "Erythromycins": {
          "Erythromycins": {
            "A-S MEDICATION SOLUTIONS": {
              "E.E.S. 400": "E.E.S. 400",
              "E.E.S. GRANULES": "E.E.S. GRANULES",
              "ERY-TAB": "ERY-TAB",
              "ERYTHROCIN STEARATE": "ERYTHROCIN STEARATE",
              "ERYTHROMYCIN BASE": "ERYTHROMYCIN BASE",
              "ERYTHROMYCIN ETHYLSUCCINATE": "ERYTHROMYCIN ETHYLSUCCINATE"
            },
            "AIDAREX PHARMACEUTICALS": {
              "ERY-TAB": "ERY-TAB",
              "ERYTHROMYCIN BASE": "ERYTHROMYCIN BASE"
            },
            "ALTURA PHARMACEUTICALS": {
              "ERY-TAB": "ERY-TAB",
              "ERYTHROCIN STEARATE": "ERYTHROCIN STEARATE",
              "ERYTHROMYCIN BASE": "ERYTHROMYCIN BASE",
              "ERYTHROMYCIN ETHYLSUCCINATE": "ERYTHROMYCIN ETHYLSUCCINATE"
            },
            "AMNEAL PHARMACEUTICALS": {
              "ERYTHROMYCIN BASE": "ERYTHROMYCIN BASE",
              "ERYTHROMYCIN ETHYLSUCCINATE": "ERYTHROMYCIN ETHYLSUCCINATE"
            },
            "ANI  PHARMACEUTICALS": {
              "ERYTHROMYCIN ETHYLSUCCINATE": "ERYTHROMYCIN ETHYLSUCCINATE"
            },
            "ARBOR PHARMACEUTICALS": {
              "E.E.S. 400": "E.E.S. 400",
              "E.E.S. GRANULES": "E.E.S. GRANULES",
              "ERY-TAB": "ERY-TAB",
              "ERYPED 200": "ERYPED 200",
              "ERYPED 400": "ERYPED 400",
              "ERYTHROCIN STEARATE": "ERYTHROCIN STEARATE",
              "ERYTHROMYCIN BASE": "ERYTHROMYCIN BASE",
              "ERYTHROMYCIN ETHYLSUCCINATE": "ERYTHROMYCIN ETHYLSUCCINATE",
              "PCE": "PCE"
            },
            "BLENHEIM PHARMACAL": {
              "ERY-TAB": "ERY-TAB",
              "ERYTHROCIN STEARATE": "ERYTHROCIN STEARATE"
            },
            "BRYANT RANCH PREPACK": {
              "E.E.S. 400": "E.E.S. 400",
              "ERY-TAB": "ERY-TAB",
              "ERYTHROMYCIN BASE": "ERYTHROMYCIN BASE",
              "ERYTHROMYCIN STEARATE": "ERYTHROMYCIN STEARATE"
            },
            "FAGRON": {
              "ERYTHROMYCIN": "ERYTHROMYCIN"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "ERY-TAB": "ERY-TAB",
              "ERYTHROMYCIN BASE": "ERYTHROMYCIN BASE",
              "ERYTHROMYCIN ETHYLSUCCINATE": "ERYTHROMYCIN ETHYLSUCCINATE"
            },
            "HOSPIRA": {
              "ERYTHROCIN LACTOBIONATE": "ERYTHROCIN LACTOBIONATE"
            },
            "KALCHEM INTERNATIONAL": {
              "ERYTHROMYCIN ETHYLSUCCINATE": "ERYTHROMYCIN ETHYLSUCCINATE"
            },
            "LETCO MEDICAL": {
              "ERYTHROMYCIN ETHYLSUCCINATE": "ERYTHROMYCIN ETHYLSUCCINATE"
            },
            "MAYNE PHARMA": {
              "ERYTHROMYCIN BASE": "ERYTHROMYCIN BASE"
            },
            "MEDISCA": {
              "ERYTHROMYCIN": "ERYTHROMYCIN",
              "ERYTHROMYCIN ETHYLSUCCINATE": "ERYTHROMYCIN ETHYLSUCCINATE"
            },
            "MEDVANTX": {
              "ERY-TAB": "ERY-TAB",
              "ERYTHROCIN STEARATE": "ERYTHROCIN STEARATE"
            },
            "NUCARE PHARMACEUTICALS": {
              "E.E.S. 400": "E.E.S. 400",
              "ERY-TAB": "ERY-TAB",
              "ERYTHROMYCIN BASE": "ERYTHROMYCIN BASE"
            },
            "PAR PHARMACEUTICAL": {
              "ERYTHROMYCIN ETHYLSUCCINATE": "ERYTHROMYCIN ETHYLSUCCINATE"
            },
            "PCCA": {
              "ERYTHROMYCIN": "ERYTHROMYCIN",
              "ERYTHROMYCIN ETHYLSUCCINATE": "ERYTHROMYCIN ETHYLSUCCINATE"
            },
            "PDRX PHARMACEUTICAL": {
              "E.E.S. 400": "E.E.S. 400",
              "ERY-TAB": "ERY-TAB",
              "ERYTHROCIN STEARATE": "ERYTHROCIN STEARATE",
              "ERYTHROMYCIN BASE": "ERYTHROMYCIN BASE",
              "ERYTHROMYCIN ETHYLSUCCINATE": "ERYTHROMYCIN ETHYLSUCCINATE",
              "ERYTHROMYCIN STEARATE": "ERYTHROMYCIN STEARATE",
              "PCE": "PCE"
            },
            "PHARMEDIX": {
              "ERY-TAB": "ERY-TAB",
              "ERYTHROMYCIN ETHYLSUCCINATE": "ERYTHROMYCIN ETHYLSUCCINATE"
            },
            "QUALITY CARE": {
              "E.E.S. 400": "E.E.S. 400",
              "E.E.S. GRANULES": "E.E.S. GRANULES",
              "ERY-TAB": "ERY-TAB",
              "ERYTHROMYCIN BASE": "ERYTHROMYCIN BASE",
              "ERYTHROMYCIN STEARATE": "ERYTHROMYCIN STEARATE"
            },
            "RXCHANGE CO": {
              "ERY-TAB": "ERY-TAB",
              "ERYTHROCIN STEARATE": "ERYTHROCIN STEARATE"
            },
            "TORRENT PHARMACEUTICALS": {
              "ERYTHROMYCIN BASE": "ERYTHROMYCIN BASE"
            },
            "WILSHIRE PHARMACEUTICALS": {
              "ERYTHROMYCIN": "ERYTHROMYCIN",
              "ERYTHROMYCIN BASE": "ERYTHROMYCIN BASE",
              "ERYTHROMYCIN ETHYLSUCCINATE": "ERYTHROMYCIN ETHYLSUCCINATE"
            }
          }
        },
        "Fidaxomicin": {
          "Fidaxomicin": {
            "MERCK SHARP & DOHME": {
              "DIFICID": "DIFICID"
            }
          }
        }
      },
      "MEDICAL DEVICES AND SUPPLIES": {
        "Auditory Supplies": {
          "Hearing Aid Supplies-Batteries": {
            "CHAIN DRUG CONSORTIUM": {
              "HEARING AID BATTERIES": "HEARING AID BATTERIES"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS HEARING AID BATTERIES": "CVS HEARING AID BATTERIES",
              "CVS MAX HEARING AID BATTERIES": "CVS MAX HEARING AID BATTERIES"
            },
            "LEADER BRAND PRODUCTS": {
              "HEARING AID BATTERIES": "HEARING AID BATTERIES"
            },
            "P & G PAPER PRODUCTS": {
              "DURACELL HEARING AID BATTERIES": "DURACELL HEARING AID BATTERIES"
            },
            "RITE AID CORPORATION": {
              "RA HEARING AID BATTERIES S 312": "RA HEARING AID BATTERIES S 312",
              "RA HEARING AID BATTERIES SZ 10": "RA HEARING AID BATTERIES SZ 10",
              "RA HEARING AID BATTERIES SZ 13": "RA HEARING AID BATTERIES SZ 13",
              "RA HEARING AID BATTERY": "RA HEARING AID BATTERY"
            },
            "WALGREENS": {
              "HEARING AID BATTERIES": "HEARING AID BATTERIES",
              "HEARING AID BATTERY": "HEARING AID BATTERY",
              "PREMIUM HEARING AID BATTERIES": "PREMIUM HEARING AID BATTERIES"
            }
          }
        },
        "Bandages-Dressings-Tape": {
          "Adhesive Bandages": {
            "3M CONSUMER HEALTH CARE": {
              "NEXCARE ABSOLUTE WATERPROOF": "NEXCARE ABSOLUTE WATERPROOF",
              "NEXCARE ACTIVE BRIGHTS BANDAGE": "NEXCARE ACTIVE BRIGHTS BANDAGE",
              "NEXCARE ACTIVE SPORT BANDAGES": "NEXCARE ACTIVE SPORT BANDAGES",
              "NEXCARE COMFORT FABRIC BANDAGE": "NEXCARE COMFORT FABRIC BANDAGE",
              "NEXCARE HEAVY DUTY CLR BANDAGE": "NEXCARE HEAVY DUTY CLR BANDAGE",
              "NEXCARE HEAVY DUTY FAB BANDAGE": "NEXCARE HEAVY DUTY FAB BANDAGE",
              "NEXCARE SOFT \\'N FLEX BANDAGES": "NEXCARE SOFT \\'N FLEX BANDAGES",
              "NEXCARE TATTOO BANDAGES": "NEXCARE TATTOO BANDAGES",
              "NEXCARE WATERPROOF BANDAGES": "NEXCARE WATERPROOF BANDAGES"
            },
            "3M MEDICAL": {
              "STERI-STRIP": "STERI-STRIP",
              "STERI-STRIP DRESSING": "STERI-STRIP DRESSING"
            },
            "BEIERSDORF-FUTURO": {
              "ADVANCED CURAD AQUA-PROTECT": "ADVANCED CURAD AQUA-PROTECT",
              "ADVANCED CURAD BLISTER-CARE": "ADVANCED CURAD BLISTER-CARE",
              "ADVANCED CURAD COOL-WRAP": "ADVANCED CURAD COOL-WRAP",
              "ADVANCED CURAD SOF-GEL": "ADVANCED CURAD SOF-GEL",
              "CURAD ACTI-FLEX FOAM BANDAGES": "CURAD ACTI-FLEX FOAM BANDAGES",
              "CURAD ADHESIVE BANDAGES": "CURAD ADHESIVE BANDAGES",
              "CURAD KID SIZE BANDAGES": "CURAD KID SIZE BANDAGES",
              "CURAD SENSITIVE SKIN BANDAGES": "CURAD SENSITIVE SKIN BANDAGES",
              "CURITY CURAD ADHESIVE BANDAGES": "CURITY CURAD ADHESIVE BANDAGES",
              "CURITY CURAD FABRIC BANDAGES": "CURITY CURAD FABRIC BANDAGES",
              "CURITY CURAD NEON STRIPS": "CURITY CURAD NEON STRIPS"
            },
            "BERGEN BRUNSWIG": {
              "GNP ADHESIVE PADS 3\"\"X4\"\"": "GNP ADHESIVE PADS 3\"\"X4\"\"",
              "GNP CLEAR STRIPS": "GNP CLEAR STRIPS",
              "GNP PLASTIC STRIPS 3/4\"\"": "GNP PLASTIC STRIPS 3/4\"\"",
              "GNP SHEER STRIPS": "GNP SHEER STRIPS",
              "GNP SHEER STRIPS ASSORTED": "GNP SHEER STRIPS ASSORTED",
              "GNP SUPER STRIP WATERSEAL": "GNP SUPER STRIP WATERSEAL"
            },
            "BSN MEDICAL": {
              "COVERLET STRIPS": "COVERLET STRIPS"
            },
            "CARPAL AID": {
              "CARPALAID EMPLOYEE SURVIVAL LG": "CARPALAID EMPLOYEE SURVIVAL LG",
              "CARPALAID EMPLOYEE SURVIVAL SM": "CARPALAID EMPLOYEE SURVIVAL SM",
              "CARPALAID LARGE": "CARPALAID LARGE",
              "CARPALAID PRACTIONER PACK LG": "CARPALAID PRACTIONER PACK LG",
              "CARPALAID PRACTIONER PACK SMAL": "CARPALAID PRACTIONER PACK SMAL",
              "CARPALAID SMALL": "CARPALAID SMALL"
            },
            "CHAIN DRUG CONSORTIUM": {
              "ADHESIVE BANDAGES BUTTERFLY": "ADHESIVE BANDAGES BUTTERFLY",
              "ADHESIVE BANDAGES CLEAR": "ADHESIVE BANDAGES CLEAR",
              "ADHESIVE BANDAGES FEXIBLE": "ADHESIVE BANDAGES FEXIBLE",
              "ADHESIVE BANDAGES FOAM": "ADHESIVE BANDAGES FOAM",
              "ADHESIVE BANDAGES HEALTH AWARE": "ADHESIVE BANDAGES HEALTH AWARE",
              "ADHESIVE BANDAGES PLASTIC": "ADHESIVE BANDAGES PLASTIC",
              "ADHESIVE BANDAGES SHEER": "ADHESIVE BANDAGES SHEER",
              "ADHESIVE BANDAGES WATER SEAL": "ADHESIVE BANDAGES WATER SEAL",
              "NON-ADHERENT PADS": "NON-ADHERENT PADS",
              "SENSITIVE SKIN BANDAGES": "SENSITIVE SKIN BANDAGES",
              "STRONG STRIPS": "STRONG STRIPS",
              "WOUND CLOSURE STRIPS": "WOUND CLOSURE STRIPS"
            },
            "COVIDIEN MEDICAL SUPPLIES": {
              "CURITY WOUND CLOSURE 1/2\"\"X4\"\"": "CURITY WOUND CLOSURE 1/2\"\"X4\"\"",
              "CURITY WOUND CLOSURE 1/4\"\"X1.5\"\"": "CURITY WOUND CLOSURE 1/4\"\"X1.5\"\"",
              "CURITY WOUND CLOSURE 1/4\"\"X3\"\"": "CURITY WOUND CLOSURE 1/4\"\"X3\"\"",
              "CURITY WOUND CLOSURE 1/4\"\"X4\"\"": "CURITY WOUND CLOSURE 1/4\"\"X4\"\"",
              "CURITY WOUND CLOSURE 1/8\"\"X3\"\"": "CURITY WOUND CLOSURE 1/8\"\"X3\"\""
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS ADHESIVE BANDAGES": "CVS ADHESIVE BANDAGES",
              "CVS ADHESIVE PAD 4\"\"X4\"\"": "CVS ADHESIVE PAD 4\"\"X4\"\"",
              "CVS ADHESIVE PAD 6\"\"X6\"\"": "CVS ADHESIVE PAD 6\"\"X6\"\"",
              "CVS ADHESIVE PADS 2.25\"\"X3\"\"": "CVS ADHESIVE PADS 2.25\"\"X3\"\"",
              "CVS ADVANCE HEALING BANDAGES": "CVS ADVANCE HEALING BANDAGES",
              "CVS ANTI-BACT BANDAGES CHILD": "CVS ANTI-BACT BANDAGES CHILD",
              "CVS ANTI-BACT BANDAGES WATERPR": "CVS ANTI-BACT BANDAGES WATERPR",
              "CVS ANTI-BACTERIAL BANDAGES": "CVS ANTI-BACTERIAL BANDAGES",
              "CVS BUTTERFLY CLOSURES": "CVS BUTTERFLY CLOSURES",
              "CVS CLEAR BANDAGES": "CVS CLEAR BANDAGES",
              "CVS FLEXIBLE FABRIC BANDAGE": "CVS FLEXIBLE FABRIC BANDAGE",
              "CVS PLASTIC BANDAGES": "CVS PLASTIC BANDAGES",
              "CVS SHEER BANDAGES": "CVS SHEER BANDAGES",
              "CVS SHEER BANDAGES EXTRA LARGE": "CVS SHEER BANDAGES EXTRA LARGE",
              "CVS SHEER STRIP": "CVS SHEER STRIP",
              "CVS SPOT BANDAGE SHEER": "CVS SPOT BANDAGE SHEER"
            },
            "EQUALINE": {
              "EQL ADHESIVE BANDAGES PACK": "EQL ADHESIVE BANDAGES PACK",
              "EQL ADHESIVE PADS": "EQL ADHESIVE PADS",
              "EQL ADVANCED HEALING": "EQL ADVANCED HEALING",
              "EQL ANIMAL PRINT STRIPS": "EQL ANIMAL PRINT STRIPS",
              "EQL ANTIBACTERIAL FABRIC STRIP": "EQL ANTIBACTERIAL FABRIC STRIP",
              "EQL BANDAGES": "EQL BANDAGES",
              "EQL BUTTERFLY CLOSURES": "EQL BUTTERFLY CLOSURES",
              "EQL FIRST AID BANDAGES": "EQL FIRST AID BANDAGES",
              "EQL FLEXIBLE FABRIC BANDAGES": "EQL FLEXIBLE FABRIC BANDAGES",
              "EQL FLEXIBLE FOAM STRIPS": "EQL FLEXIBLE FOAM STRIPS",
              "EQL GENTLE STRIPS": "EQL GENTLE STRIPS",
              "EQL HEAVY DUTY BANDAGES": "EQL HEAVY DUTY BANDAGES",
              "EQL HEAVY DUTY FABRIC STRIPS": "EQL HEAVY DUTY FABRIC STRIPS",
              "EQL NATURAL CARE BANDAGES": "EQL NATURAL CARE BANDAGES",
              "EQL PLASTIC STRIPS": "EQL PLASTIC STRIPS",
              "EQL SHEER SPOTS SMALL": "EQL SHEER SPOTS SMALL",
              "EQL SHEER STRIPS": "EQL SHEER STRIPS",
              "EQL SHEER STRIPS EXTRA LARGE": "EQL SHEER STRIPS EXTRA LARGE",
              "EQL STRIPS": "EQL STRIPS",
              "EQL STRONG STRIPS WATERPROOF": "EQL STRONG STRIPS WATERPROOF"
            },
            "FACET TECHNOLOGIES": {
              "SURESEAL": "SURESEAL"
            },
            "FERRIS MFG. CORP.": {
              "POLYMEM FILM DOT": "POLYMEM FILM DOT"
            },
            "HANNAFORD BROTHERS": {
              "FLEXIBLE FABRIC BANDAGES": "FLEXIBLE FABRIC BANDAGES"
            },
            "J & J CONSUMER PRODUCTS": {
              "BAND-AID": "BAND-AID",
              "BAND-AID ACTIV-FLEX/REGULAR": "BAND-AID ACTIV-FLEX/REGULAR",
              "BAND-AID ADV HEALING BLISTER": "BAND-AID ADV HEALING BLISTER",
              "BAND-AID ALL-IN-ONE GAUZE LG": "BAND-AID ALL-IN-ONE GAUZE LG",
              "BAND-AID ALL-IN-ONE GAUZE MED": "BAND-AID ALL-IN-ONE GAUZE MED",
              "BAND-AID BABY SHARK": "BAND-AID BABY SHARK",
              "BAND-AID CLEAR PLASTIC": "BAND-AID CLEAR PLASTIC",
              "BAND-AID CLEAR SPOTS": "BAND-AID CLEAR SPOTS",
              "BAND-AID CLEAR STRIPS": "BAND-AID CLEAR STRIPS",
              "BAND-AID FLEXIBLE ASSORTED": "BAND-AID FLEXIBLE ASSORTED",
              "BAND-AID FLEXIBLE FABRIC": "BAND-AID FLEXIBLE FABRIC",
              "BAND-AID HELLO KITTY": "BAND-AID HELLO KITTY",
              "BAND-AID HURT-FREE NON-STICK": "BAND-AID HURT-FREE NON-STICK",
              "BAND-AID HYDRO SEAL": "BAND-AID HYDRO SEAL",
              "BAND-AID HYDRO SEAL FINGERS": "BAND-AID HYDRO SEAL FINGERS",
              "BAND-AID OUR VETERAN HEROES": "BAND-AID OUR VETERAN HEROES",
              "BAND-AID PLASTIC": "BAND-AID PLASTIC",
              "BAND-AID PLASTIC SMALL": "BAND-AID PLASTIC SMALL",
              "BAND-AID QUILTVENT WATERPROOF": "BAND-AID QUILTVENT WATERPROOF",
              "BAND-AID SHEER COMFORT-FLEX": "BAND-AID SHEER COMFORT-FLEX",
              "BAND-AID SHEER STRIPS": "BAND-AID SHEER STRIPS",
              "BAND-AID SPORT STRIP EX WIDE": "BAND-AID SPORT STRIP EX WIDE",
              "BAND-AID TEENAGE MUTANT NINJA": "BAND-AID TEENAGE MUTANT NINJA",
              "BAND-AID TOUGH": "BAND-AID TOUGH",
              "BAND-AID TOUGH-STRIPS": "BAND-AID TOUGH-STRIPS",
              "BAND-AID VARIETY PACK": "BAND-AID VARIETY PACK",
              "BAND-AID WATER BLOCK PLUS": "BAND-AID WATER BLOCK PLUS",
              "BAND-AID/EXTRA LARGE": "BAND-AID/EXTRA LARGE",
              "J & J ADHESIVE LARGE": "J & J ADHESIVE LARGE"
            },
            "J & J MEDICAL": {
              "BAND-AID": "BAND-AID",
              "BAND-AID BUTTERFLY CLOSURE": "BAND-AID BUTTERFLY CLOSURE",
              "BAND-AID FLEXIBLE": "BAND-AID FLEXIBLE",
              "BAND-AID FLEXIBLE FABRIC": "BAND-AID FLEXIBLE FABRIC",
              "BAND-AID GLOW IN THE DARK": "BAND-AID GLOW IN THE DARK",
              "BAND-AID HOT COLORS": "BAND-AID HOT COLORS",
              "BAND-AID MEDICATED STRIPS": "BAND-AID MEDICATED STRIPS",
              "BAND-AID PLASTIC": "BAND-AID PLASTIC",
              "BAND-AID PLASTIC STRIPS": "BAND-AID PLASTIC STRIPS",
              "BAND-AID SHEER STRIPS": "BAND-AID SHEER STRIPS",
              "BAND-AID SUPER STRIPS": "BAND-AID SUPER STRIPS",
              "J & J NON-STICK LARGE": "J & J NON-STICK LARGE",
              "PROXI-STRIPS": "PROXI-STRIPS"
            },
            "JOHNSON&JOHNSON HEALTHCARE": {
              "BAND-AID": "BAND-AID",
              "BAND-AID SKIN-FLEX": "BAND-AID SKIN-FLEX"
            },
            "LEADER BRAND PRODUCTS": {
              "ADHESIVE/LARGE/3\"\"X4\"\"": "ADHESIVE/LARGE/3\"\"X4\"\"",
              "ADHESIVE/MEDIUM/2\"\"X3\"\"": "ADHESIVE/MEDIUM/2\"\"X3\"\"",
              "BANDAGES FABRIC KNUCKLE/FINGER": "BANDAGES FABRIC KNUCKLE/FINGER",
              "BANDAGES FABRIC STRIPS 3/4\"\"": "BANDAGES FABRIC STRIPS 3/4\"\"",
              "CLEAR BANDAGES": "CLEAR BANDAGES",
              "FABRIC BANDAGES": "FABRIC BANDAGES",
              "PEANUTS BANDAGES": "PEANUTS BANDAGES",
              "PLASTIC BANDAGES 3/4\"\"": "PLASTIC BANDAGES 3/4\"\"",
              "SHEER ADHESIVE BANDAGES": "SHEER ADHESIVE BANDAGES",
              "SHEER BANDAGES": "SHEER BANDAGES",
              "SHEER BANDAGES 3/4\"\"": "SHEER BANDAGES 3/4\"\"",
              "SHEER BANDAGES/ASSORTED": "SHEER BANDAGES/ASSORTED",
              "SHEER BANDAGES/EX-LARGE": "SHEER BANDAGES/EX-LARGE",
              "WATERSHIELD BANDAGES": "WATERSHIELD BANDAGES"
            },
            "MCKESSON": {
              "HM ADHESIVE ANTIBACTERIAL": "HM ADHESIVE ANTIBACTERIAL",
              "HM ADHESIVE BANDAGES": "HM ADHESIVE BANDAGES",
              "HM BUTTERFLY CLOSURES": "HM BUTTERFLY CLOSURES"
            },
            "MCKESSON SUNMARK": {
              "SM ADHESIVE PADS 2\"\"X3\"\"": "SM ADHESIVE PADS 2\"\"X3\"\"",
              "SM ADHESIVE PADS 3\"\"X4\"\"": "SM ADHESIVE PADS 3\"\"X4\"\"",
              "SM BANDAGES CLEAR SPOTS": "SM BANDAGES CLEAR SPOTS",
              "SM BANDAGES FABRIC 3/4\"\"": "SM BANDAGES FABRIC 3/4\"\"",
              "SM BANDAGES FABRIC EXTRA LARGE": "SM BANDAGES FABRIC EXTRA LARGE",
              "SM BANDAGES FOAM": "SM BANDAGES FOAM",
              "SM BANDAGES FOAM EXTRA LARGE": "SM BANDAGES FOAM EXTRA LARGE",
              "SM BANDAGES PLASTIC": "SM BANDAGES PLASTIC",
              "SM BANDAGES SHEER": "SM BANDAGES SHEER",
              "SM BANDAGES SHEER EXTRA LARGE": "SM BANDAGES SHEER EXTRA LARGE",
              "SM BANDAGES STRONG STRIPS 1\"\"": "SM BANDAGES STRONG STRIPS 1\"\"",
              "SM BANDAGES WATERSHIELD": "SM BANDAGES WATERSHIELD",
              "SM BANDAGES/ANTIBACTERIAL": "SM BANDAGES/ANTIBACTERIAL",
              "SM BANDAGES/CLEAR/ASSORTED": "SM BANDAGES/CLEAR/ASSORTED",
              "SM BANDAGES/FLEXIBLE/ASSORTED": "SM BANDAGES/FLEXIBLE/ASSORTED",
              "SM FABRIC KNUCKLE/FINGERTIP": "SM FABRIC KNUCKLE/FINGERTIP",
              "SM HYPO-ALLERGENIC BANDAGES": "SM HYPO-ALLERGENIC BANDAGES",
              "SM STRONG STRIPS": "SM STRONG STRIPS",
              "SM STURDY STRIP FABRIC BANDAGE": "SM STURDY STRIP FABRIC BANDAGE"
            },
            "MEDICINE SHOPPE": {
              "ADHESIVE BANDAGES PLASTIC": "ADHESIVE BANDAGES PLASTIC",
              "FLEXIBLE FABRIC BANDAGES": "FLEXIBLE FABRIC BANDAGES",
              "PLASTIC ADHESIVE BANDAGES": "PLASTIC ADHESIVE BANDAGES"
            },
            "MEDLINE INDUS": {
              "CURAD COMFORT FABRIC": "CURAD COMFORT FABRIC"
            },
            "MEDLINE INDUSTRIES": {
              "CURAD NON-STICK": "CURAD NON-STICK"
            },
            "MEDTRONIC MINIMED": {
              "SOF-SET ADHESIVE PATCH": "SOF-SET ADHESIVE PATCH"
            },
            "MEIJER": {
              "SHEER BANDAGES": "SHEER BANDAGES"
            },
            "PUBLIX SUPER MARKETS INC.": {
              "PX SUPERSTRIP 1\"\"X3\"\"": "PX SUPERSTRIP 1\"\"X3\"\""
            },
            "QWIKSTRIP PRODUCTS": {
              "QWIKSTRIP FLEX FABRIC": "QWIKSTRIP FLEX FABRIC",
              "QWIKSTRIP PLASTIC": "QWIKSTRIP PLASTIC",
              "QWIKSTRIP SHEER": "QWIKSTRIP SHEER"
            },
            "RELIAMED": {
              "SELF-ADHESIVE RETENTION SHEET": "SELF-ADHESIVE RETENTION SHEET"
            },
            "RITE AID CORPORATION": {
              "RA ADHESIVE BANDAGES": "RA ADHESIVE BANDAGES",
              "RA ADHESIVE BANDAGES FLEX FOAM": "RA ADHESIVE BANDAGES FLEX FOAM",
              "RA ADHESIVE BANDAGES FLEXIBLE": "RA ADHESIVE BANDAGES FLEXIBLE",
              "RA ADHESIVE BANDAGES PLASTIC": "RA ADHESIVE BANDAGES PLASTIC",
              "RA ADHESIVE BANDAGES SHEER": "RA ADHESIVE BANDAGES SHEER",
              "RA ADHESIVE PADS": "RA ADHESIVE PADS",
              "RA BANDAGES CLEAR": "RA BANDAGES CLEAR",
              "RA BANDAGES CLEAR ASSORTED": "RA BANDAGES CLEAR ASSORTED",
              "RA BANDAGES FLEX FABRIC 3/4\"\"X3": "RA BANDAGES FLEX FABRIC 3/4\"\"X3",
              "RA BANDAGES FLEX FABRIC ASSORT": "RA BANDAGES FLEX FABRIC ASSORT",
              "RA BANDAGES FLEXIBLE FABRIC": "RA BANDAGES FLEXIBLE FABRIC",
              "RA BANDAGES FLEXIBLE FABRIC XL": "RA BANDAGES FLEXIBLE FABRIC XL",
              "RA BANDAGES FLEXIBLE FOAM": "RA BANDAGES FLEXIBLE FOAM",
              "RA BANDAGES HYPO-ALLERGENIC": "RA BANDAGES HYPO-ALLERGENIC",
              "RA BANDAGES/EXTRA LONG FABRIC": "RA BANDAGES/EXTRA LONG FABRIC",
              "RA BANDAGES/STRONG-STRIPS XL": "RA BANDAGES/STRONG-STRIPS XL",
              "RA BUTTERFLY BANDAGES MEDIUM": "RA BUTTERFLY BANDAGES MEDIUM",
              "RA FIRST AID ADV ANTIBACTERIAL": "RA FIRST AID ADV ANTIBACTERIAL",
              "RA FIRST AID CLEAR SPOT 7/8\"\"": "RA FIRST AID CLEAR SPOT 7/8\"\"",
              "RA FIRST AID NON-STICK": "RA FIRST AID NON-STICK",
              "RA FIRST AID SHEER BANDAGE": "RA FIRST AID SHEER BANDAGE",
              "RA SHEER ADHESIVE LARGE": "RA SHEER ADHESIVE LARGE",
              "RA STRONG STRIPS BANDAGES": "RA STRONG STRIPS BANDAGES",
              "RA SUPER STRIP 1\"\"X3\"\"": "RA SUPER STRIP 1\"\"X3\"\""
            },
            "RUGBY LABORATORIES": {
              "ADHESIVE BANDAGES SHEER": "ADHESIVE BANDAGES SHEER",
              "SHEER ADHESIVE BANDAGES": "SHEER ADHESIVE BANDAGES"
            },
            "SMITH & NEPHEW UNITED": {
              "LEUKOSTRIP 1/2\"\"X4\"\"": "LEUKOSTRIP 1/2\"\"X4\"\"",
              "LEUKOSTRIP 1/4\"\"X3\"\"": "LEUKOSTRIP 1/4\"\"X3\"\"",
              "LEUKOSTRIP 1/4\"\"X4\"\"": "LEUKOSTRIP 1/4\"\"X4\"\"",
              "LEUKOSTRIP 1/8\"\"X1-1/2\"\"": "LEUKOSTRIP 1/8\"\"X1-1/2\"\""
            },
            "SPENCO MEDICAL CORPORATION": {
              "2ND SKIN BLISTER KIT": "2ND SKIN BLISTER KIT",
              "2ND SKIN BLISTER PADS": "2ND SKIN BLISTER PADS",
              "2ND SKIN QUIKSTIK ADH BANDAGES": "2ND SKIN QUIKSTIK ADH BANDAGES",
              "2ND SKIN SELF SEAL CUT CLOSURE": "2ND SKIN SELF SEAL CUT CLOSURE",
              "SPENCO ADHESIVE KNIT 3\"\"X5\"\"": "SPENCO ADHESIVE KNIT 3\"\"X5\"\""
            },
            "WAL-MART": {
              "EQ FLEXIBLE FABRIC BANDAGES": "EQ FLEXIBLE FABRIC BANDAGES",
              "EQ STRONG STRIPS BANDAGES": "EQ STRONG STRIPS BANDAGES"
            },
            "WALGREENS": {
              "ADHESIVE BANDAGES": "ADHESIVE BANDAGES",
              "ADHESIVE BANDAGES ANTIBACTERIA": "ADHESIVE BANDAGES ANTIBACTERIA",
              "ADHESIVE BANDAGES CLEAR": "ADHESIVE BANDAGES CLEAR",
              "ADHESIVE BANDAGES FEXIBLE": "ADHESIVE BANDAGES FEXIBLE",
              "ADHESIVE BANDAGES FOAM": "ADHESIVE BANDAGES FOAM",
              "ADHESIVE BANDAGES HEALTH AWARE": "ADHESIVE BANDAGES HEALTH AWARE",
              "ADHESIVE BANDAGES PLASTIC": "ADHESIVE BANDAGES PLASTIC",
              "ADHESIVE BANDAGES SHEER": "ADHESIVE BANDAGES SHEER",
              "ADHESIVE BANDAGES STRONG STRIP": "ADHESIVE BANDAGES STRONG STRIP",
              "ADHESIVE BANDAGES WATER SHIELD": "ADHESIVE BANDAGES WATER SHIELD",
              "ADHESIVE/LARGE/3\"\"X4\"\"": "ADHESIVE/LARGE/3\"\"X4\"\"",
              "ADHESIVE/MEDIUM/2\"\"X3\"\"": "ADHESIVE/MEDIUM/2\"\"X3\"\"",
              "ANTIBACTERIAL BANDAGES": "ANTIBACTERIAL BANDAGES",
              "ANTIBACTERIAL CLEAR BANDAGE": "ANTIBACTERIAL CLEAR BANDAGE",
              "ANTIBACTERIAL CLEAR SPOT 7/8\"\"": "ANTIBACTERIAL CLEAR SPOT 7/8\"\"",
              "ANTIBACTERIAL PLASTIC BANDAGES": "ANTIBACTERIAL PLASTIC BANDAGES",
              "BLISTER RELIEF BANDAGE": "BLISTER RELIEF BANDAGE",
              "BUTTERFLY CLOSURES": "BUTTERFLY CLOSURES",
              "CRAYON BANDAGES STRIPS": "CRAYON BANDAGES STRIPS",
              "FLEXIBLE FABRIC BANDAGES": "FLEXIBLE FABRIC BANDAGES",
              "GENTLE ADHESIVE BANDAGES XL": "GENTLE ADHESIVE BANDAGES XL",
              "MOLESKIN FOAM": "MOLESKIN FOAM",
              "PEANUTS BANDAGES": "PEANUTS BANDAGES",
              "SHEER BANDAGES": "SHEER BANDAGES",
              "SORESPOT BLISTER/SKIN BANDAGES": "SORESPOT BLISTER/SKIN BANDAGES"
            }
          },
          "Adhesive Tape": {
            "3M CONSUMER HEALTH CARE": {
              "DURAPORE CLOTH": "DURAPORE CLOTH",
              "FUTURO SPORT TAPING KIT": "FUTURO SPORT TAPING KIT",
              "MICROPORE": "MICROPORE",
              "NEXCARE ADV HOLD CLOTH 1\"\"X6YD": "NEXCARE ADV HOLD CLOTH 1\"\"X6YD",
              "NEXCARE ATHLETIC 1-1/2\"\"X10YD": "NEXCARE ATHLETIC 1-1/2\"\"X10YD",
              "NEXCARE ATHLETIC 1-1/2\"\"X12.5YD": "NEXCARE ATHLETIC 1-1/2\"\"X12.5YD",
              "NEXCARE DURABLE CLOTH 1\"\"X10YD": "NEXCARE DURABLE CLOTH 1\"\"X10YD",
              "NEXCARE DURABLE CLOTH 2\"\"X10YD": "NEXCARE DURABLE CLOTH 2\"\"X10YD",
              "NEXCARE DURABLE CLOTH 3/4\"\"X6YD": "NEXCARE DURABLE CLOTH 3/4\"\"X6YD",
              "NEXCARE FLEXIBLE CLEAR 1\"\"X10YD": "NEXCARE FLEXIBLE CLEAR 1\"\"X10YD",
              "NEXCARE FLEXIBLE CLEAR 2\"\"X10YD": "NEXCARE FLEXIBLE CLEAR 2\"\"X10YD",
              "NEXCARE FLEXIBLE TAPE 3/4\"\"X7YD": "NEXCARE FLEXIBLE TAPE 3/4\"\"X7YD",
              "NEXCARE GENTLE PAPER 1\"\"X10YD": "NEXCARE GENTLE PAPER 1\"\"X10YD",
              "NEXCARE GENTLE PAPER 1/2\"\"X10YD": "NEXCARE GENTLE PAPER 1/2\"\"X10YD",
              "NEXCARE GENTLE PAPER 2\"\"X10YD": "NEXCARE GENTLE PAPER 2\"\"X10YD",
              "NEXCARE GENTLE PAPER 3/4\"\"X8YD": "NEXCARE GENTLE PAPER 3/4\"\"X8YD",
              "NEXCARE NO HURT 1\"\"X2.2YD": "NEXCARE NO HURT 1\"\"X2.2YD",
              "NEXCARE STERI-STRIP 1/2\"\"X4\"\"": "NEXCARE STERI-STRIP 1/2\"\"X4\"\"",
              "NEXCARE STERI-STRIP 1/4\"\"X4\"\"": "NEXCARE STERI-STRIP 1/4\"\"X4\"\"",
              "NEXCARE WATERPROOF 1\"\"X5YD": "NEXCARE WATERPROOF 1\"\"X5YD",
              "TRANSPORE PLASTIC": "TRANSPORE PLASTIC"
            },
            "3M MEDICAL": {
              "BLENDERM SURGICAL 1\"\"X5YD": "BLENDERM SURGICAL 1\"\"X5YD",
              "BLENDERM SURGICAL 1/2\"\"X5YD": "BLENDERM SURGICAL 1/2\"\"X5YD",
              "BLENDERM SURGICAL 2\"\"X5YD": "BLENDERM SURGICAL 2\"\"X5YD",
              "CLOTH ADHESIVE SURG 1\"\"X10YD": "CLOTH ADHESIVE SURG 1\"\"X10YD",
              "CLOTH ADHESIVE SURG 1/2\"\"X10YD": "CLOTH ADHESIVE SURG 1/2\"\"X10YD",
              "CLOTH ADHESIVE SURG 2\"\"X10YD": "CLOTH ADHESIVE SURG 2\"\"X10YD",
              "CLOTH ADHESIVE SURG 3\"\"X10YD": "CLOTH ADHESIVE SURG 3\"\"X10YD",
              "DURAPORE SURGICAL 1\"\"X1-1/2YD": "DURAPORE SURGICAL 1\"\"X1-1/2YD",
              "DURAPORE SURGICAL 1\"\"X10YD": "DURAPORE SURGICAL 1\"\"X10YD",
              "DURAPORE SURGICAL 1/2\"\"X10YD": "DURAPORE SURGICAL 1/2\"\"X10YD",
              "DURAPORE SURGICAL 2\"\"X1-1/2YD": "DURAPORE SURGICAL 2\"\"X1-1/2YD",
              "DURAPORE SURGICAL 2\"\"X10YD": "DURAPORE SURGICAL 2\"\"X10YD",
              "DURAPORE SURGICAL 3\"\"X10YD": "DURAPORE SURGICAL 3\"\"X10YD",
              "MEDIPORE DRESS-IT 3-7/8\"\"X4-5/8": "MEDIPORE DRESS-IT 3-7/8\"\"X4-5/8",
              "MEDIPORE DRESS-IT 3-7/8\"\"X7-7/8": "MEDIPORE DRESS-IT 3-7/8\"\"X7-7/8",
              "MEDIPORE DRESS-IT 5-7/8\"\"X11\"\"": "MEDIPORE DRESS-IT 5-7/8\"\"X11\"\"",
              "MEDIPORE DRESS-IT 5-7/8\"\"X5-7/8": "MEDIPORE DRESS-IT 5-7/8\"\"X5-7/8",
              "MEDIPORE DRESS-IT 7-7/8\"\"X11\"\"": "MEDIPORE DRESS-IT 7-7/8\"\"X11\"\"",
              "MEDIPORE H SURGICAL 1\"\"X10YD": "MEDIPORE H SURGICAL 1\"\"X10YD",
              "MEDIPORE H SURGICAL 2\"\"X10YD": "MEDIPORE H SURGICAL 2\"\"X10YD",
              "MEDIPORE H SURGICAL 2\"\"X2YD": "MEDIPORE H SURGICAL 2\"\"X2YD",
              "MEDIPORE H SURGICAL 3\"\"X10YD": "MEDIPORE H SURGICAL 3\"\"X10YD",
              "MEDIPORE H SURGICAL 4\"\"X10YD": "MEDIPORE H SURGICAL 4\"\"X10YD",
              "MEDIPORE H SURGICAL 4\"\"X2YD": "MEDIPORE H SURGICAL 4\"\"X2YD",
              "MEDIPORE H SURGICAL 6\"\"X10YD": "MEDIPORE H SURGICAL 6\"\"X10YD",
              "MEDIPORE H SURGICAL 6\"\"X2YD": "MEDIPORE H SURGICAL 6\"\"X2YD",
              "MEDIPORE H SURGICAL 8\"\"X10YD": "MEDIPORE H SURGICAL 8\"\"X10YD",
              "MEDIPORE SURGICAL 1\"\"X10YD": "MEDIPORE SURGICAL 1\"\"X10YD",
              "MEDIPORE SURGICAL 2\"\"X10YD": "MEDIPORE SURGICAL 2\"\"X10YD",
              "MEDIPORE SURGICAL 2\"\"X2YD": "MEDIPORE SURGICAL 2\"\"X2YD",
              "MEDIPORE SURGICAL 3\"\"X10YD": "MEDIPORE SURGICAL 3\"\"X10YD",
              "MEDIPORE SURGICAL 4\"\"X10YD": "MEDIPORE SURGICAL 4\"\"X10YD",
              "MEDIPORE SURGICAL 4\"\"X2YD": "MEDIPORE SURGICAL 4\"\"X2YD",
              "MEDIPORE SURGICAL 6\"\"X10YD": "MEDIPORE SURGICAL 6\"\"X10YD",
              "MEDIPORE SURGICAL 6\"\"X2YD": "MEDIPORE SURGICAL 6\"\"X2YD",
              "MEDIPORE SURGICAL 8\"\"X10YD": "MEDIPORE SURGICAL 8\"\"X10YD",
              "MICROFOAM PATCH 4\"\"X7\"\"": "MICROFOAM PATCH 4\"\"X7\"\"",
              "MICROFOAM SURGICAL 1\"\"X5-1/2YD": "MICROFOAM SURGICAL 1\"\"X5-1/2YD",
              "MICROFOAM SURGICAL 2\"\"X5-1/2YD": "MICROFOAM SURGICAL 2\"\"X5-1/2YD",
              "MICROFOAM SURGICAL 3\"\"X5-1/2YD": "MICROFOAM SURGICAL 3\"\"X5-1/2YD",
              "MICROFOAM SURGICAL 4\"\"X5-1/2YD": "MICROFOAM SURGICAL 4\"\"X5-1/2YD",
              "MICROPORE SURGICAL 1\"\"X1-1/2YD": "MICROPORE SURGICAL 1\"\"X1-1/2YD",
              "MICROPORE SURGICAL 1\"\"X10YD": "MICROPORE SURGICAL 1\"\"X10YD",
              "MICROPORE SURGICAL 1/2\"\"X10YD": "MICROPORE SURGICAL 1/2\"\"X10YD",
              "MICROPORE SURGICAL 2\"\"X1-1/2YD": "MICROPORE SURGICAL 2\"\"X1-1/2YD",
              "MICROPORE SURGICAL 2\"\"X10YD": "MICROPORE SURGICAL 2\"\"X10YD",
              "MICROPORE SURGICAL 3\"\"X10YD": "MICROPORE SURGICAL 3\"\"X10YD",
              "TRANSPORE SURGICAL 1\"\"X1-1/2YD": "TRANSPORE SURGICAL 1\"\"X1-1/2YD",
              "TRANSPORE SURGICAL 1\"\"X10YD": "TRANSPORE SURGICAL 1\"\"X10YD",
              "TRANSPORE SURGICAL 1/2\"\"X10YD": "TRANSPORE SURGICAL 1/2\"\"X10YD",
              "TRANSPORE SURGICAL 2\"\"X1-1/2YD": "TRANSPORE SURGICAL 2\"\"X1-1/2YD",
              "TRANSPORE SURGICAL 2\"\"X10YD": "TRANSPORE SURGICAL 2\"\"X10YD",
              "TRANSPORE SURGICAL 3\"\"X10YD": "TRANSPORE SURGICAL 3\"\"X10YD",
              "TRANSPORE WHITE 1\"\"X10YD": "TRANSPORE WHITE 1\"\"X10YD",
              "TRANSPORE WHITE 1/2\"\"X10YD": "TRANSPORE WHITE 1/2\"\"X10YD",
              "TRANSPORE WHITE 2\"\"X10YD": "TRANSPORE WHITE 2\"\"X10YD",
              "TRANSPORE WHITE 3\"\"X10YD": "TRANSPORE WHITE 3\"\"X10YD"
            },
            "BARD": {
              "SCANPOR TAPE": "SCANPOR TAPE"
            },
            "BEIERSDORF-FUTURO": {
              "CURAD ADVANCED SOF-TAPE": "CURAD ADVANCED SOF-TAPE",
              "CURITY SPORTS ADHESIVE": "CURITY SPORTS ADHESIVE",
              "CURITY WET-PRUF ADHESIVE": "CURITY WET-PRUF ADHESIVE"
            },
            "CHAIN DRUG CONSORTIUM": {
              "ADHESIVE TAPE 1\"\"X5YD": "ADHESIVE TAPE 1\"\"X5YD",
              "ADHESIVE TAPE 1/2\"\"X10YD": "ADHESIVE TAPE 1/2\"\"X10YD",
              "ADHESIVE TAPE 1/2\"\"X5YD": "ADHESIVE TAPE 1/2\"\"X5YD",
              "PAPER TAPE 1\"\"X10YD": "PAPER TAPE 1\"\"X10YD",
              "SPORTS TAPE 1-1/2\"\"X10YD": "SPORTS TAPE 1-1/2\"\"X10YD"
            },
            "COVIDIEN MEDICAL SUPPLIES": {
              "KENDALL CLOTH TAPE 1\"\"X10YD": "KENDALL CLOTH TAPE 1\"\"X10YD",
              "KENDALL CLOTH TAPE 2\"\"X10YD": "KENDALL CLOTH TAPE 2\"\"X10YD",
              "KENDALL CLOTH TAPE 2\"\"X2YD": "KENDALL CLOTH TAPE 2\"\"X2YD",
              "KENDALL CLOTH TAPE 3\"\"X10YD": "KENDALL CLOTH TAPE 3\"\"X10YD",
              "KENDALL CLOTH TAPE 4\"\"X10YD": "KENDALL CLOTH TAPE 4\"\"X10YD",
              "KENDALL CLOTH TAPE 4\"\"X2YD": "KENDALL CLOTH TAPE 4\"\"X2YD",
              "KENDALL CLOTH TAPE 6\"\"X10YD": "KENDALL CLOTH TAPE 6\"\"X10YD",
              "KENDALL CLOTH TAPE 6\"\"X2YD": "KENDALL CLOTH TAPE 6\"\"X2YD",
              "KENDALL CLOTH TAPE 8\"\"X10YD": "KENDALL CLOTH TAPE 8\"\"X10YD",
              "KENDALL PAPER 1\"\"X-1-1/2YD": "KENDALL PAPER 1\"\"X-1-1/2YD",
              "KENDALL PAPER 1\"\"X10YD": "KENDALL PAPER 1\"\"X10YD",
              "KENDALL PAPER 1/2\"\"X10YD": "KENDALL PAPER 1/2\"\"X10YD",
              "KENDALL PAPER 2\"\"X1-1/2YD": "KENDALL PAPER 2\"\"X1-1/2YD",
              "KENDALL PAPER 2\"\"X10YD": "KENDALL PAPER 2\"\"X10YD",
              "KENDALL PAPER 3\"\"X10YD": "KENDALL PAPER 3\"\"X10YD"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS ADHESIVE TAPE 1\"\"X10YD": "CVS ADHESIVE TAPE 1\"\"X10YD",
              "CVS ADHESIVE TAPE 1\"\"X2.2YD": "CVS ADHESIVE TAPE 1\"\"X2.2YD",
              "CVS ADHESIVE TAPE 1\"\"X360\"\"": "CVS ADHESIVE TAPE 1\"\"X360\"\"",
              "CVS ADHESIVE TAPE 1.5\"\"X10YD": "CVS ADHESIVE TAPE 1.5\"\"X10YD",
              "CVS ADHESIVE TAPE 1/2\"\"X10YD": "CVS ADHESIVE TAPE 1/2\"\"X10YD",
              "CVS ADHESIVE TAPE 2\"\"X2.2YD": "CVS ADHESIVE TAPE 2\"\"X2.2YD",
              "CVS ADHESIVE TAPE 2\"\"X360\"\"": "CVS ADHESIVE TAPE 2\"\"X360\"\"",
              "CVS CLEAR BREATHABLE": "CVS CLEAR BREATHABLE",
              "CVS KINESIOLOGY": "CVS KINESIOLOGY",
              "CVS NON-IRRITATING PAPER": "CVS NON-IRRITATING PAPER"
            },
            "EQUALINE": {
              "EQL ADHESIVE 1\"\"X5YDS": "EQL ADHESIVE 1\"\"X5YDS",
              "EQL ADHESIVE 1/2\"\"X10YDS": "EQL ADHESIVE 1/2\"\"X10YDS",
              "EQL FIRST AID 1\"\"X2.3YDS": "EQL FIRST AID 1\"\"X2.3YDS",
              "EQL FIRST AID 2\"\"X2.3YDS": "EQL FIRST AID 2\"\"X2.3YDS",
              "EQL PAPER 1\"\"X10YDS": "EQL PAPER 1\"\"X10YDS",
              "EQL PAPER TAPE DISPENSER": "EQL PAPER TAPE DISPENSER"
            },
            "J & J CONSUMER PRODUCTS": {
              "BAND-AID DURA-WEAVE CLOTH": "BAND-AID DURA-WEAVE CLOTH",
              "BAND-AID HURT-FREE WRAP MEDIUM": "BAND-AID HURT-FREE WRAP MEDIUM",
              "BAND-AID HURT-FREE WRAP SMALL": "BAND-AID HURT-FREE WRAP SMALL",
              "BAND-AID PAPER SMALL": "BAND-AID PAPER SMALL",
              "BAND-AID WATERPROOF": "BAND-AID WATERPROOF",
              "COACH SPORTS": "COACH SPORTS"
            },
            "J & J MEDICAL": {
              "DERMICEL CLOTH": "DERMICEL CLOTH",
              "DERMIFORM HYPO-ALLERGENIC": "DERMIFORM HYPO-ALLERGENIC",
              "J & J WATERPROOF TAPE": "J & J WATERPROOF TAPE",
              "ZONAS POROUS": "ZONAS POROUS"
            },
            "KINESIO HOLDING CORPORATION": {
              "KINESIO FAN CUT": "KINESIO FAN CUT",
              "KINESIO TEX CLASSIC": "KINESIO TEX CLASSIC",
              "KINESIO TEX GOLD FP": "KINESIO TEX GOLD FP"
            },
            "KT HEALTH": {
              "KT FLEX KNEE SUPPORT": "KT FLEX KNEE SUPPORT",
              "KT PRO EXTREME": "KT PRO EXTREME",
              "KT PROX": "KT PROX",
              "KT RECOVERY +": "KT RECOVERY +",
              "KT TAPE GENTLE": "KT TAPE GENTLE",
              "KT TAPE ORIGINAL": "KT TAPE ORIGINAL",
              "KT TAPE PRO": "KT TAPE PRO",
              "KT TAPE PRO USA": "KT TAPE PRO USA"
            },
            "MCKESSON SUNMARK": {
              "SM WATERPROOF ADH 1/2\"\"X10YD": "SM WATERPROOF ADH 1/2\"\"X10YD",
              "SM WATERPROOF ADHESIVE 1\"\"X5YD": "SM WATERPROOF ADHESIVE 1\"\"X5YD"
            },
            "MEDICINE SHOPPE": {
              "FIRST AID TAPE PAPER": "FIRST AID TAPE PAPER"
            },
            "MEDLINE INDUSTRIES": {
              "CURAD PAPER TAPE/GENTLE": "CURAD PAPER TAPE/GENTLE"
            },
            "MUELLER": {
              "KINESIOLOGY TAPE": "KINESIOLOGY TAPE",
              "MUELLER ATHLETIC": "MUELLER ATHLETIC",
              "MUELLER KINESIOLOGY": "MUELLER KINESIOLOGY"
            },
            "RICHMARK INTERNATIONAL CORP": {
              "REJUVENESS": "REJUVENESS"
            },
            "RITE AID CORPORATION": {
              "RA ADHESIVE 1\"\"X10YD": "RA ADHESIVE 1\"\"X10YD",
              "RA ADHESIVE TAPE": "RA ADHESIVE TAPE",
              "RA ADHESIVE TAPE 1\"\"X5YD": "RA ADHESIVE TAPE 1\"\"X5YD",
              "RA ADHESIVE TAPE 1/2\"\"X10YD": "RA ADHESIVE TAPE 1/2\"\"X10YD",
              "RA ADHESIVE TAPE 1/2\"\"X5YD": "RA ADHESIVE TAPE 1/2\"\"X5YD",
              "RA CLOTH FIRST AID 1\"\"X10YD": "RA CLOTH FIRST AID 1\"\"X10YD",
              "RA GENTLE PAPER": "RA GENTLE PAPER",
              "RA PAIN-FREE": "RA PAIN-FREE",
              "RA PAPER TAPE/DISPENSER": "RA PAPER TAPE/DISPENSER",
              "RA PRO SPORTS": "RA PRO SPORTS"
            },
            "WALGREENS": {
              "ADHESIVE 1\"\"X6YD": "ADHESIVE 1\"\"X6YD",
              "ADHESIVE 1/2\"\"X6YD": "ADHESIVE 1/2\"\"X6YD",
              "ADHESIVE PAPER": "ADHESIVE PAPER",
              "ADHESIVE TAPE 1/2\"\"X10YD": "ADHESIVE TAPE 1/2\"\"X10YD",
              "ADHESIVE TAPE 1/2\"\"X12YD": "ADHESIVE TAPE 1/2\"\"X12YD",
              "ADHESIVE TAPE 1/2\"\"X5YD": "ADHESIVE TAPE 1/2\"\"X5YD",
              "ADHESIVE TAPE 1/2\"\"X6YD": "ADHESIVE TAPE 1/2\"\"X6YD",
              "ATHLETIC TAPE": "ATHLETIC TAPE",
              "ATHLETIC TAPE 1.5\"\"X10YDS": "ATHLETIC TAPE 1.5\"\"X10YDS",
              "CLEAR TAPE 1\"\"X10YDS": "CLEAR TAPE 1\"\"X10YDS",
              "CLOTH TAPE 1\"\"X10YD": "CLOTH TAPE 1\"\"X10YD",
              "ELASTIC FOAM TAPE 1\"\"X5YD": "ELASTIC FOAM TAPE 1\"\"X5YD",
              "PAPER TAPE 1\"\"X10YD": "PAPER TAPE 1\"\"X10YD",
              "PAPER TAPE 1\"\"X12YD": "PAPER TAPE 1\"\"X12YD",
              "PAPER TAPE DISPENSER 1\"\"X10YD": "PAPER TAPE DISPENSER 1\"\"X10YD",
              "SKIN CLOSURE STRIPS": "SKIN CLOSURE STRIPS"
            }
          },
          "Compression Bandages": {
            "BSN MEDICAL": {
              "GELOCAST 3\"\"X10YD": "GELOCAST 3\"\"X10YD"
            },
            "CHAIN DRUG CONSORTIUM": {
              "HOT/COLD COMPRESS": "HOT/COLD COMPRESS",
              "HOT/COLD COMPRESS WRAP 18\"\"": "HOT/COLD COMPRESS WRAP 18\"\"",
              "HOT/COLD COMPRESS WRAP 30\"\"": "HOT/COLD COMPRESS WRAP 30\"\""
            },
            "CONVATEC": {
              "SUREPRESS HI COMPRESS BANDAGE": "SUREPRESS HI COMPRESS BANDAGE"
            },
            "KAZ": {
              "DUNLAP COLD COMPRESSION WRAP": "DUNLAP COLD COMPRESSION WRAP"
            },
            "KT HEALTH": {
              "KT RECOVERY + ICE/HEAT": "KT RECOVERY + ICE/HEAT"
            },
            "MCKESSON SUNMARK": {
              "SM DELUXE REUSABLE COMPRESS": "SM DELUXE REUSABLE COMPRESS"
            },
            "RITE AID CORPORATION": {
              "RA HOT/COLD COMPRESS": "RA HOT/COLD COMPRESS",
              "RA HOT/COLD GEL SLEEVE": "RA HOT/COLD GEL SLEEVE",
              "RA INSTANT COLD THERAPY WRAP": "RA INSTANT COLD THERAPY WRAP"
            },
            "SMITH & NEPHEW UNITED": {
              "PROFORE": "PROFORE",
              "PROFORE LITE": "PROFORE LITE",
              "PROFORE WCL 5-1/2\"\"X8\"\"": "PROFORE WCL 5-1/2\"\"X8\"\""
            }
          },
          "Gauze Bandages": {
            "BEIERSDORF DERMA": {
              "COVER-ROLL STRETCH 2\"\"X1YD": "COVER-ROLL STRETCH 2\"\"X1YD"
            },
            "BERGEN BRUNSWIG": {
              "GNP CONFORM GAUZE 2\"\"X2YD": "GNP CONFORM GAUZE 2\"\"X2YD"
            },
            "BSN MEDICAL": {
              "ELASTOMULL 2\"\"X4.1YD": "ELASTOMULL 2\"\"X4.1YD"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC GAUZE BANDAGE ROLL 4.5\"\"X3YD": "QC GAUZE BANDAGE ROLL 4.5\"\"X3YD",
              "QC STRETCH BANDAGE 3\"\"X2.1YD": "QC STRETCH BANDAGE 3\"\"X2.1YD",
              "QC STRETCH GAUZE BDG 2\"\"X2YD": "QC STRETCH GAUZE BDG 2\"\"X2YD",
              "QC STRETCH GAUZE BDG 3\"\"X2YD": "QC STRETCH GAUZE BDG 3\"\"X2YD",
              "QC STRETCH GAUZE BDG 4\"\"X2YD": "QC STRETCH GAUZE BDG 4\"\"X2YD"
            },
            "CONVATEC": {
              "SUREPRESS ABSORBENT PADDING": "SUREPRESS ABSORBENT PADDING"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS GENTLE WRAP 3\"\"X2.2YDS": "CVS GENTLE WRAP 3\"\"X2.2YDS",
              "CVS ROLLED GAUZE": "CVS ROLLED GAUZE"
            },
            "EQUALINE": {
              "EQL ROLLED GAUZE 2\"\"X2.5YD": "EQL ROLLED GAUZE 2\"\"X2.5YD",
              "EQL ROLLED GAUZE 2\"\"X4.1YDS": "EQL ROLLED GAUZE 2\"\"X4.1YDS",
              "EQL ROLLED GAUZE 3\"\"X2.5YD": "EQL ROLLED GAUZE 3\"\"X2.5YD",
              "EQL ROLLED GAUZE 3\"\"X4.1YDS": "EQL ROLLED GAUZE 3\"\"X4.1YDS"
            },
            "J & J MEDICAL": {
              "KLING FLUFF ROLLS": "KLING FLUFF ROLLS",
              "SOF-KLING CONFORMING BANDAGE": "SOF-KLING CONFORMING BANDAGE"
            },
            "MCKESSON": {
              "HM ROLLED GAUZE BANDAGE": "HM ROLLED GAUZE BANDAGE"
            },
            "MCKESSON SUNMARK": {
              "SM ROLLED GAUZE 2\"\"X2.5YD": "SM ROLLED GAUZE 2\"\"X2.5YD"
            },
            "MEDLINE INDUSTRIES": {
              "BULKEE II GAUZE 4.5\"\"X4.1YD": "BULKEE II GAUZE 4.5\"\"X4.1YD"
            },
            "RITE AID CORPORATION": {
              "RA GAUZE BANDAGE 2\"\"X2.5YD": "RA GAUZE BANDAGE 2\"\"X2.5YD",
              "RA GAUZE BANDAGE 3\"\"X2.4YD": "RA GAUZE BANDAGE 3\"\"X2.4YD",
              "RA GAUZE BANDAGE 3\"\"X2.5YD": "RA GAUZE BANDAGE 3\"\"X2.5YD",
              "RA GAUZE BANDAGE 4\"\"X2.5YD": "RA GAUZE BANDAGE 4\"\"X2.5YD"
            },
            "WALGREENS": {
              "BANDAGE COTTON ROLL 4.5\"\"X4YD": "BANDAGE COTTON ROLL 4.5\"\"X4YD",
              "CONFORMING ROLLED GAUZE": "CONFORMING ROLLED GAUZE",
              "ROLLED GAUZE": "ROLLED GAUZE",
              "ROLLED GAUZE BANDAGE 2\"\"X2.5YD": "ROLLED GAUZE BANDAGE 2\"\"X2.5YD",
              "ROLLED GAUZE BANDAGE 3\"\"X2.5YD": "ROLLED GAUZE BANDAGE 3\"\"X2.5YD",
              "ROLLED GAUZE BANDAGE 4\"\"X2.5YD": "ROLLED GAUZE BANDAGE 4\"\"X2.5YD",
              "TUBULAR GAUZE": "TUBULAR GAUZE"
            }
          },
          "Gauze Pads & Dressings": {
            "3M CONSUMER HEALTH CARE": {
              "NEXCARE ADHESIVE DRESSING/PAD": "NEXCARE ADHESIVE DRESSING/PAD",
              "NEXCARE NON-STICK": "NEXCARE NON-STICK",
              "NEXCARE PREMIUM ADHESIVE GAUZE": "NEXCARE PREMIUM ADHESIVE GAUZE",
              "NEXCARE TEGADERM 2-3/8\"\"X2-3/4\"\"": "NEXCARE TEGADERM 2-3/8\"\"X2-3/4\"\"",
              "NEXCARE TEGADERM 4\"\"X4-3/4\"\"": "NEXCARE TEGADERM 4\"\"X4-3/4\"\"",
              "NEXCARE WATERPROOF PREMIUM PAD": "NEXCARE WATERPROOF PREMIUM PAD",
              "TEGADERM FILM 2-3/8\"\"X2-3/4\"\"": "TEGADERM FILM 2-3/8\"\"X2-3/4\"\""
            },
            "3M MEDICAL": {
              "MEDIPORE + PAD ADHESIVE DRESS": "MEDIPORE + PAD ADHESIVE DRESS",
              "TEGADERM + PAD 2\"\"X2-3/4\"\"": "TEGADERM + PAD 2\"\"X2-3/4\"\"",
              "TEGADERM + PAD 2-3/8\"\"X4\"\"": "TEGADERM + PAD 2-3/8\"\"X4\"\"",
              "TEGADERM + PAD 3-1/2\"\"X10\"\"": "TEGADERM + PAD 3-1/2\"\"X10\"\"",
              "TEGADERM + PAD 3-1/2\"\"X13-3/4\"\"": "TEGADERM + PAD 3-1/2\"\"X13-3/4\"\"",
              "TEGADERM + PAD 3-1/2\"\"X4\"\"": "TEGADERM + PAD 3-1/2\"\"X4\"\"",
              "TEGADERM + PAD 3-1/2\"\"X4-1/8\"\"": "TEGADERM + PAD 3-1/2\"\"X4-1/8\"\"",
              "TEGADERM + PAD 3-1/2\"\"X6\"\"": "TEGADERM + PAD 3-1/2\"\"X6\"\"",
              "TEGADERM + PAD 3-1/2\"\"X8\"\"": "TEGADERM + PAD 3-1/2\"\"X8\"\"",
              "TEGADERM + PAD 6\"\"X6\"\"": "TEGADERM + PAD 6\"\"X6\"\"",
              "TEGADERM ABSORBENT DRESSING": "TEGADERM ABSORBENT DRESSING",
              "TEGADERM CONTACT LAYER": "TEGADERM CONTACT LAYER",
              "TEGADERM FILM 1-3/4\"\"X1-3/4\"\"": "TEGADERM FILM 1-3/4\"\"X1-3/4\"\"",
              "TEGADERM FILM 2-3/8\"\"X2-3/4\"\"": "TEGADERM FILM 2-3/8\"\"X2-3/4\"\"",
              "TEGADERM FILM 4\"\"X10\"\"": "TEGADERM FILM 4\"\"X10\"\"",
              "TEGADERM FILM 4\"\"X4-1/2\"\"": "TEGADERM FILM 4\"\"X4-1/2\"\"",
              "TEGADERM FILM 4\"\"X4-3/4\"\"": "TEGADERM FILM 4\"\"X4-3/4\"\"",
              "TEGADERM FILM 6\"\"X8\"\"": "TEGADERM FILM 6\"\"X8\"\"",
              "TEGADERM FILM 8\"\"X12\"\"": "TEGADERM FILM 8\"\"X12\"\"",
              "TEGADERM FOAM": "TEGADERM FOAM",
              "TEGADERM FOAM ADHESIVE": "TEGADERM FOAM ADHESIVE",
              "TEGADERM FOAM ROLL 4\"\"X24\"\"": "TEGADERM FOAM ROLL 4\"\"X24\"\"",
              "TEGADERM HP 2-1/8\"\"X2-1/2\"\"": "TEGADERM HP 2-1/8\"\"X2-1/2\"\"",
              "TEGADERM HP 2-3/8\"\"X2-3/4\"\"": "TEGADERM HP 2-3/8\"\"X2-3/4\"\"",
              "TEGADERM HP 2-3/8\"\"X2-3/8\"\"": "TEGADERM HP 2-3/8\"\"X2-3/8\"\"",
              "TEGADERM HP 4\"\"X4-1/2\"\"": "TEGADERM HP 4\"\"X4-1/2\"\"",
              "TEGADERM HP 4\"\"X4-3/4\"\"": "TEGADERM HP 4\"\"X4-3/4\"\"",
              "TEGADERM HP 4-1/2\"\"X4-3/4\"\"": "TEGADERM HP 4-1/2\"\"X4-3/4\"\"",
              "TEGADERM HP 5-1/2\"\"X6-1/2\"\"": "TEGADERM HP 5-1/2\"\"X6-1/2\"\"",
              "TEGADERM I.V. 2\"\"X2-1/4\"\"": "TEGADERM I.V. 2\"\"X2-1/4\"\"",
              "TEGADERM I.V. 2-3/4\"\"X3-1/4\"\"": "TEGADERM I.V. 2-3/4\"\"X3-1/4\"\"",
              "TEGADERM I.V. 3-1/2\"\"X4-1/2\"\"": "TEGADERM I.V. 3-1/2\"\"X4-1/2\"\"",
              "TEGADERM I.V. 3-1/2\"\"X4-1/4\"\"": "TEGADERM I.V. 3-1/2\"\"X4-1/4\"\"",
              "TEGADERM I.V. 4\"\"X6-1/8\"\"": "TEGADERM I.V. 4\"\"X6-1/8\"\"",
              "TEGADERM I.V. ADVANCED": "TEGADERM I.V. ADVANCED",
              "TEGADERM ROLL 2\"\"X11YD": "TEGADERM ROLL 2\"\"X11YD",
              "TEGADERM ROLL 4\"\"X11YD": "TEGADERM ROLL 4\"\"X11YD",
              "TEGADERM ROLL 6\"\"X11YD": "TEGADERM ROLL 6\"\"X11YD"
            },
            "BEIERSDORF-FUTURO": {
              "CURITY GAUZE": "CURITY GAUZE",
              "CURITY KERLIX BANDAGE ROLL": "CURITY KERLIX BANDAGE ROLL",
              "CURITY KERLIX ROLL": "CURITY KERLIX ROLL",
              "CURITY STRETCH GAUZE BANDAGE": "CURITY STRETCH GAUZE BANDAGE",
              "CURITY TELFA ADHESIVE": "CURITY TELFA ADHESIVE",
              "CURITY TELFA NON-STICK": "CURITY TELFA NON-STICK",
              "EXCILON DRAIN SPONGES": "EXCILON DRAIN SPONGES"
            },
            "BERGEN BRUNSWIG": {
              "GNP STERILE PADS": "GNP STERILE PADS"
            },
            "CARDINAL HEALTH": {
              "ABDOMINAL PAD": "ABDOMINAL PAD"
            },
            "CHAIN DRUG CONSORTIUM": {
              "BANDAGE ROLL 3\"\"X75\"\"": "BANDAGE ROLL 3\"\"X75\"\"",
              "DRESSING SPONGES": "DRESSING SPONGES",
              "GAUZE BANDAGE 2\"\"": "GAUZE BANDAGE 2\"\"",
              "GAUZE BANDAGE 3\"\"": "GAUZE BANDAGE 3\"\"",
              "GAUZE BANDAGE 4\"\"": "GAUZE BANDAGE 4\"\"",
              "GAUZE SPONGE": "GAUZE SPONGE",
              "STERILE": "STERILE",
              "SURGICAL DRESSING": "SURGICAL DRESSING"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC ALL PURPOSE DRESSINGS": "QC ALL PURPOSE DRESSINGS",
              "QC BORDER ISLAND GAUZE": "QC BORDER ISLAND GAUZE",
              "QC NON-ADHERENT": "QC NON-ADHERENT",
              "QC STERILE PADS": "QC STERILE PADS"
            },
            "COLOPLAST": {
              "BIATAIN ADHESIVE FOAM DRESSING": "BIATAIN ADHESIVE FOAM DRESSING",
              "BIATAIN FOAM DRESSING": "BIATAIN FOAM DRESSING",
              "BIATAIN HEEL ADH FOAM DRESSING": "BIATAIN HEEL ADH FOAM DRESSING",
              "BIATAIN SACRAL FOAM DRESSING": "BIATAIN SACRAL FOAM DRESSING"
            },
            "CONVATEC": {
              "OPTIPORE SPONGE": "OPTIPORE SPONGE",
              "VERSIVA XC": "VERSIVA XC"
            },
            "COVIDIEN MEDICAL SUPPLIES": {
              "AMD FOAM DRESSING": "AMD FOAM DRESSING",
              "AMD FOAM DRESSING TOPSHEET": "AMD FOAM DRESSING TOPSHEET",
              "COPA ISLAND BORDERED FOAM": "COPA ISLAND BORDERED FOAM",
              "COPA PLUS HYDROPHILIC FOAM": "COPA PLUS HYDROPHILIC FOAM",
              "CURAFOAM FOAM DRESSING": "CURAFOAM FOAM DRESSING",
              "CUREX ALL-PURPOSE SPONGES": "CUREX ALL-PURPOSE SPONGES",
              "CURITY": "CURITY",
              "CURITY #10 BURN DRESS 12\"\"X12\"\"": "CURITY #10 BURN DRESS 12\"\"X12\"\"",
              "CURITY #10 BURN DRESS 18\"\"X18\"\"": "CURITY #10 BURN DRESS 18\"\"X18\"\"",
              "CURITY #10 BURN DRESS 36\"\"X36\"\"": "CURITY #10 BURN DRESS 36\"\"X36\"\"",
              "CURITY #10 GAUZE BOLT 36\"\"X300\\'": "CURITY #10 GAUZE BOLT 36\"\"X300\\'",
              "CURITY ABDOMINAL": "CURITY ABDOMINAL",
              "CURITY ABSORBENT WADDING": "CURITY ABSORBENT WADDING",
              "CURITY ALL PURPOSE SPONGES": "CURITY ALL PURPOSE SPONGES",
              "CURITY AMD ANTIMICROBIAL SPNGE": "CURITY AMD ANTIMICROBIAL SPNGE",
              "CURITY AMD ANTIMICROBIAL STRIP": "CURITY AMD ANTIMICROBIAL STRIP",
              "CURITY BURN DRESSING": "CURITY BURN DRESSING",
              "CURITY COVER SPONGE": "CURITY COVER SPONGE",
              "CURITY DRESSING SPONGES": "CURITY DRESSING SPONGES",
              "CURITY GAUZE": "CURITY GAUZE",
              "CURITY GAUZE SPONGE": "CURITY GAUZE SPONGE",
              "CURITY GAUZE SPONGES": "CURITY GAUZE SPONGES",
              "CURITY INCISION/DRAINAGE": "CURITY INCISION/DRAINAGE",
              "CURITY IODOFORM PACKING STRIP": "CURITY IODOFORM PACKING STRIP",
              "CURITY LACERATION": "CURITY LACERATION",
              "CURITY MESH GAUZE 4.5\"\"X100YD": "CURITY MESH GAUZE 4.5\"\"X100YD",
              "CURITY MESH GAUZE 9\"\"X100YD": "CURITY MESH GAUZE 9\"\"X100YD",
              "CURITY MESH GAUZE BNDG 1\"\"X30\\'": "CURITY MESH GAUZE BNDG 1\"\"X30\\'",
              "CURITY MESH GAUZE BNDG 2\"\"X30\\'": "CURITY MESH GAUZE BNDG 2\"\"X30\\'",
              "CURITY MESH GAUZE BNDG 3\"\"X30\\'": "CURITY MESH GAUZE BNDG 3\"\"X30\\'",
              "CURITY MESH GAUZE BNDG 4\"\"X30\\'": "CURITY MESH GAUZE BNDG 4\"\"X30\\'",
              "CURITY MULTI-TRAUMA DRESSING": "CURITY MULTI-TRAUMA DRESSING",
              "CURITY NON-ADHERENT": "CURITY NON-ADHERENT",
              "CURITY NON-ADHERENT STRIPS": "CURITY NON-ADHERENT STRIPS",
              "CURITY NON-ADHERING DRESSING": "CURITY NON-ADHERING DRESSING",
              "CURITY PLAIN PACKING STRIP": "CURITY PLAIN PACKING STRIP",
              "CURITY SPONGES": "CURITY SPONGES",
              "CURITY TRIANGULAR BANDAGE": "CURITY TRIANGULAR BANDAGE",
              "CURITY WET DRESSING": "CURITY WET DRESSING",
              "DERMACEA DRAIN SPONGES": "DERMACEA DRAIN SPONGES",
              "DERMACEA GAUZE FLUFF ROLL": "DERMACEA GAUZE FLUFF ROLL",
              "DERMACEA GAUZE ROLL 2\"\"X4-1/8YD": "DERMACEA GAUZE ROLL 2\"\"X4-1/8YD",
              "DERMACEA GAUZE ROLL 3\"\"X4-1/8YD": "DERMACEA GAUZE ROLL 3\"\"X4-1/8YD",
              "DERMACEA GAUZE ROLL 4\"\"X4-1/8YD": "DERMACEA GAUZE ROLL 4\"\"X4-1/8YD",
              "DERMACEA GAUZE ROLL 6\"\"X4-1/8YD": "DERMACEA GAUZE ROLL 6\"\"X4-1/8YD",
              "DERMACEA GAUZE SPONGE": "DERMACEA GAUZE SPONGE",
              "DERMACEA IV DRAIN SPONGES": "DERMACEA IV DRAIN SPONGES",
              "DERMACEA IV SPONGES": "DERMACEA IV SPONGES",
              "DERMACEA NON-ADHERENT DRESSING": "DERMACEA NON-ADHERENT DRESSING",
              "DERMACEA NON-WOVEN SPONGES": "DERMACEA NON-WOVEN SPONGES",
              "DERMACEA STRETCH BANDAGE": "DERMACEA STRETCH BANDAGE",
              "DERMACEA STRETCH BANDAGE ROLL": "DERMACEA STRETCH BANDAGE ROLL",
              "DERMACEA SUPER SPONGE": "DERMACEA SUPER SPONGE",
              "DERMACEA TYPE VII GAUZE": "DERMACEA TYPE VII GAUZE",
              "DERMACEA X-RAY SPONGES": "DERMACEA X-RAY SPONGES",
              "EXCILON AMD DRAIN SPONGES": "EXCILON AMD DRAIN SPONGES",
              "EXCILON AMD NON-WOVEN SPONGES": "EXCILON AMD NON-WOVEN SPONGES",
              "EXCILON DRAIN SPONGES": "EXCILON DRAIN SPONGES",
              "EXCILON IV SPONGES": "EXCILON IV SPONGES",
              "INTERSORB GAUZE 24\"\"X36\"\"": "INTERSORB GAUZE 24\"\"X36\"\"",
              "KENDALL HYDROPHILIC FOAM DRESS": "KENDALL HYDROPHILIC FOAM DRESS",
              "KENDALL HYDROPHILIC FOAM PLUS": "KENDALL HYDROPHILIC FOAM PLUS",
              "KENDALL TRANSPARENT FILM DRESS": "KENDALL TRANSPARENT FILM DRESS",
              "KERLIX AMD ANTIMICROBIAL": "KERLIX AMD ANTIMICROBIAL",
              "KERLIX AMD SUPER SPONGES": "KERLIX AMD SUPER SPONGES",
              "KERLIX BANDAGE ROLL": "KERLIX BANDAGE ROLL",
              "KERLIX BANDAGE ROLL 2-1/4\"\"X9\\'": "KERLIX BANDAGE ROLL 2-1/4\"\"X9\\'",
              "KERLIX BANDAGE ROLL 4.5\"\"X9.3\\'": "KERLIX BANDAGE ROLL 4.5\"\"X9.3\\'",
              "KERLIX BANDAGE ROLL BURN PACK": "KERLIX BANDAGE ROLL BURN PACK",
              "KERLIX GAUZE ROLL LARGE": "KERLIX GAUZE ROLL LARGE",
              "KERLIX GAUZE ROLL MEDIUM": "KERLIX GAUZE ROLL MEDIUM",
              "KERLIX GAUZE ROLL SMALL": "KERLIX GAUZE ROLL SMALL",
              "KERLIX SPONGES": "KERLIX SPONGES",
              "KERLIX SUPER SPONGES LARGE": "KERLIX SUPER SPONGES LARGE",
              "KERLIX SUPER SPONGES MEDIUM": "KERLIX SUPER SPONGES MEDIUM",
              "KERLIX SUPER SPONGES XL": "KERLIX SUPER SPONGES XL",
              "KERLIX WASHED GAUZE BOLT": "KERLIX WASHED GAUZE BOLT",
              "KERLIX X-RAY DETECTABLE SPONGE": "KERLIX X-RAY DETECTABLE SPONGE",
              "POLYSKIN II DRESSING 2\"\"X2.75\"\"": "POLYSKIN II DRESSING 2\"\"X2.75\"\"",
              "TELFA ADHESIVE DRESSING": "TELFA ADHESIVE DRESSING",
              "TELFA AMD ADHESIVE BANDAGE": "TELFA AMD ADHESIVE BANDAGE",
              "TELFA AMD ISLAND DRESSING": "TELFA AMD ISLAND DRESSING",
              "TELFA AMD NON-ADHERENT": "TELFA AMD NON-ADHERENT",
              "TELFA CLEAR DRESSING 39\"\"X25YD": "TELFA CLEAR DRESSING 39\"\"X25YD",
              "TELFA CLEAR WOUND 12\"\"X12\"\"": "TELFA CLEAR WOUND 12\"\"X12\"\"",
              "TELFA CLEAR WOUND 12\"\"X24\"\"": "TELFA CLEAR WOUND 12\"\"X24\"\"",
              "TELFA CLEAR WOUND 3\"\"X3\"\"": "TELFA CLEAR WOUND 3\"\"X3\"\"",
              "TELFA CLEAR WOUND 4\"\"X5\"\"": "TELFA CLEAR WOUND 4\"\"X5\"\"",
              "TELFA ISLAND DRESSING": "TELFA ISLAND DRESSING",
              "TELFA NON-ADHERENT": "TELFA NON-ADHERENT",
              "TELFA NON-ADHERENT DESSING": "TELFA NON-ADHERENT DESSING",
              "TELFA PLUS BARRIER ISLAND": "TELFA PLUS BARRIER ISLAND",
              "TELFA PLUS BARRIER ISLAND DRES": "TELFA PLUS BARRIER ISLAND DRES",
              "TENDEROL UNDERCAST PADDING": "TENDEROL UNDERCAST PADDING",
              "VIASORB DRESSING": "VIASORB DRESSING",
              "VISTEC X-RAY DETECTABLE SPONGE": "VISTEC X-RAY DETECTABLE SPONGE",
              "WEBRIL COTTON UNDERCAST 2\"\"X12\\'": "WEBRIL COTTON UNDERCAST 2\"\"X12\\'",
              "WEBRIL COTTON UNDERCAST 3\"\"X12\\'": "WEBRIL COTTON UNDERCAST 3\"\"X12\\'",
              "WEBRIL COTTON UNDERCAST 4\"\"X12\\'": "WEBRIL COTTON UNDERCAST 4\"\"X12\\'",
              "WEBRIL COTTON UNDERCAST 6\"\"X12\\'": "WEBRIL COTTON UNDERCAST 6\"\"X12\\'"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS ADHESIVE GAUZE": "CVS ADHESIVE GAUZE",
              "CVS DRESSING 4\"\"X4-3/4\"\"": "CVS DRESSING 4\"\"X4-3/4\"\"",
              "CVS EYE": "CVS EYE",
              "CVS GAUZE": "CVS GAUZE",
              "CVS GAUZE PAD STERILE": "CVS GAUZE PAD STERILE",
              "CVS GAUZE STERILE": "CVS GAUZE STERILE",
              "CVS MEPITEL TRANSPARENT FILM": "CVS MEPITEL TRANSPARENT FILM",
              "CVS NON-STICK": "CVS NON-STICK",
              "CVS NON-STICK PADS": "CVS NON-STICK PADS",
              "CVS SURGICAL": "CVS SURGICAL",
              "CVS TUBULAR GAUZE": "CVS TUBULAR GAUZE",
              "CVS WINDOW BANDAGES": "CVS WINDOW BANDAGES"
            },
            "DAVOL": {
              "COMPEED SKIN PROTECTOR DRESS": "COMPEED SKIN PROTECTOR DRESS"
            },
            "DERMARITE": {
              "BORDERED GAUZE": "BORDERED GAUZE",
              "DERMADRESS WATERPROOF DRESSING": "DERMADRESS WATERPROOF DRESSING",
              "DERMALEVIN ADHESIVE": "DERMALEVIN ADHESIVE",
              "DERMAVIEW DRESSING": "DERMAVIEW DRESSING",
              "DERMAVIEW II DRESSING": "DERMAVIEW II DRESSING"
            },
            "DUKAL CORPORATION": {
              "GAUZE PADS": "GAUZE PADS"
            },
            "DYNAREX CORPORATION": {
              "COMBINE": "COMBINE",
              "COMBINE PAD": "COMBINE PAD",
              "COMBINE PADS": "COMBINE PADS",
              "SURGICAL GAUZE SPONGE": "SURGICAL GAUZE SPONGE"
            },
            "EQUALINE": {
              "EQL BANDAGE ROLL 4.5\"\"X3YDS": "EQL BANDAGE ROLL 4.5\"\"X3YDS",
              "EQL GAUZE": "EQL GAUZE",
              "EQL GAUZE STERILE": "EQL GAUZE STERILE",
              "EQL NON-STICK": "EQL NON-STICK"
            },
            "FERRIS CORP": {
              "POLYMAX DRESSING": "POLYMAX DRESSING",
              "POLYMEM CLOTH DOT DRESSING": "POLYMEM CLOTH DOT DRESSING",
              "POLYMEM CLOTH ISLAND DRESSING": "POLYMEM CLOTH ISLAND DRESSING",
              "POLYMEM CLOTH STRIP DRESSING": "POLYMEM CLOTH STRIP DRESSING",
              "POLYMEM DOT DRESSING": "POLYMEM DOT DRESSING",
              "POLYMEM DRESSING": "POLYMEM DRESSING",
              "POLYMEM ISLAND DRESSING": "POLYMEM ISLAND DRESSING",
              "POLYMEM ROLL DRESSING": "POLYMEM ROLL DRESSING",
              "POLYMEM STRIP DRESSING": "POLYMEM STRIP DRESSING",
              "POLYMEM SURGICAL DRESSING": "POLYMEM SURGICAL DRESSING",
              "POLYMEM TRACH DRESSING": "POLYMEM TRACH DRESSING",
              "POLYWIC WOUND FILLER": "POLYWIC WOUND FILLER"
            },
            "FERRIS MFG. CORP.": {
              "POLYMEM NON-ADHESIVE": "POLYMEM NON-ADHESIVE"
            },
            "GERITREX CORPORATION": {
              "FOAM DRESSING": "FOAM DRESSING",
              "ISLAND GARD-GRX": "ISLAND GARD-GRX"
            },
            "HOLLISTER WOUND CARE": {
              "RESTORE CONTACT LAYER": "RESTORE CONTACT LAYER",
              "RESTORE DUO DRESSING": "RESTORE DUO DRESSING",
              "RESTORE FOAM DRESSING": "RESTORE FOAM DRESSING",
              "RESTORE FOAM HEEL DRESSING": "RESTORE FOAM HEEL DRESSING",
              "RESTORE LITE FOAM DRESSING": "RESTORE LITE FOAM DRESSING",
              "RESTORE ODOR ABSORBING DRESS": "RESTORE ODOR ABSORBING DRESS",
              "RESTORE TRIO ABSORBENT DRESS": "RESTORE TRIO ABSORBENT DRESS"
            },
            "INTEGRA LIFESCIENCES": {
              "BIOGUARD BARRIER DRESSING": "BIOGUARD BARRIER DRESSING",
              "BIOGUARD GAUZE SPONGES": "BIOGUARD GAUZE SPONGES",
              "BIOGUARD ISLAND DRESSINGS": "BIOGUARD ISLAND DRESSINGS",
              "BIOGUARD NON-ADHERENT DRESSING": "BIOGUARD NON-ADHERENT DRESSING",
              "COMPDRESS ISLAND DRESSING 2\"\"X2": "COMPDRESS ISLAND DRESSING 2\"\"X2",
              "COMPDRESS ISLAND DRESSING 4\"\"X4": "COMPDRESS ISLAND DRESSING 4\"\"X4",
              "COMPDRESS ISLAND DRESSING 4\"\"X6": "COMPDRESS ISLAND DRESSING 4\"\"X6",
              "COMPDRESS ISLAND DRESSING 4\"\"X8": "COMPDRESS ISLAND DRESSING 4\"\"X8",
              "COMPDRESS ISLAND DRESSING 4X10": "COMPDRESS ISLAND DRESSING 4X10",
              "COMPDRESS ISLAND DRESSING 4X14": "COMPDRESS ISLAND DRESSING 4X14",
              "COMPDRESS ISLAND DRESSING 6\"\"X6": "COMPDRESS ISLAND DRESSING 6\"\"X6",
              "COMPDRESS ISLAND DRESSING 6\"\"X8": "COMPDRESS ISLAND DRESSING 6\"\"X8",
              "HYDROCELL ADHESIVE DRESSING": "HYDROCELL ADHESIVE DRESSING",
              "HYDROCELL DRESSING": "HYDROCELL DRESSING"
            },
            "J & J CONSUMER PRODUCTS": {
              "BAND-AID GAUZE LARGE": "BAND-AID GAUZE LARGE",
              "BAND-AID GAUZE MEDIUM": "BAND-AID GAUZE MEDIUM",
              "BAND-AID GAUZE SMALL": "BAND-AID GAUZE SMALL",
              "BAND-AID KLING ROLLED GAUZE LG": "BAND-AID KLING ROLLED GAUZE LG",
              "BAND-AID KLING ROLLED GAUZE MD": "BAND-AID KLING ROLLED GAUZE MD",
              "BAND-AID KLING ROLLED GAUZE SM": "BAND-AID KLING ROLLED GAUZE SM",
              "BAND-AID MIRASORB GAUZE SPONGE": "BAND-AID MIRASORB GAUZE SPONGE",
              "BAND-AID SURGIPAD SURGICAL": "BAND-AID SURGIPAD SURGICAL"
            },
            "J & J MEDICAL": {
              "BAND-AID ISLAND SURG DRESSING": "BAND-AID ISLAND SURG DRESSING",
              "BIOCLUSIVE DRESSING": "BIOCLUSIVE DRESSING",
              "BIOCLUSIVE MVP SELECT": "BIOCLUSIVE MVP SELECT",
              "BIOCLUSIVE SELECT": "BIOCLUSIVE SELECT",
              "BIOCLUSIVE TRANSPARENT": "BIOCLUSIVE TRANSPARENT",
              "J & J EYE PADS OVAL SMALL": "J & J EYE PADS OVAL SMALL",
              "J & J GAUZE": "J & J GAUZE",
              "J & J GAUZE SPONGES 12-PLY": "J & J GAUZE SPONGES 12-PLY",
              "J & J GAUZE SPONGES 16-PLY": "J & J GAUZE SPONGES 16-PLY",
              "J & J GAUZE SPONGES 8-PLY": "J & J GAUZE SPONGES 8-PLY",
              "J & J OVAL EYE PADS": "J & J OVAL EYE PADS",
              "J & J STERILE EYE PADS": "J & J STERILE EYE PADS",
              "JOHNSONS STERILE EYE PADS": "JOHNSONS STERILE EYE PADS",
              "KLING FLUFF": "KLING FLUFF",
              "KLING FLUFF SPONGE": "KLING FLUFF SPONGE",
              "MIRASORB SPONGES": "MIRASORB SPONGES",
              "NU GAUZE 4PLY": "NU GAUZE 4PLY",
              "NU GAUZE GENERAL-USE SPONGES": "NU GAUZE GENERAL-USE SPONGES",
              "NU GAUZE PACKING STRIPS": "NU GAUZE PACKING STRIPS",
              "NU GAUZE UTERINE PACKING STRIP": "NU GAUZE UTERINE PACKING STRIP",
              "RAY-TEC X-RAY DETECTABLE SPNGE": "RAY-TEC X-RAY DETECTABLE SPNGE",
              "RELEASE NON-ADHERING DRESSING": "RELEASE NON-ADHERING DRESSING",
              "SOF-WICK": "SOF-WICK",
              "SOF-WIK": "SOF-WIK",
              "TIELLE": "TIELLE",
              "TOPPER DRESSING SPONGES": "TOPPER DRESSING SPONGES"
            },
            "LEADER BRAND PRODUCTS": {
              "BANDAGE NEW GENERATION LARGE": "BANDAGE NEW GENERATION LARGE",
              "GAUZE BANDAGE": "GAUZE BANDAGE",
              "GAUZE BANDAGE 3\"\"": "GAUZE BANDAGE 3\"\"",
              "NON-STICK": "NON-STICK",
              "STERILE": "STERILE"
            },
            "MCKESSON": {
              "HM NON-STICK 3\"\"X4\"\"": "HM NON-STICK 3\"\"X4\"\"",
              "HM STERILE PADS": "HM STERILE PADS"
            },
            "MCKESSON SUNMARK": {
              "COMBINE ABD": "COMBINE ABD",
              "GAUZE TYPE VII MEDI-PAK": "GAUZE TYPE VII MEDI-PAK",
              "SM BANDAGE ROLL": "SM BANDAGE ROLL",
              "SM GAUZE": "SM GAUZE",
              "SM ROLLED GAUZE 2\"\"X4.1YD": "SM ROLLED GAUZE 2\"\"X4.1YD",
              "SM ROLLED GAUZE 3\"\"X4.1YD": "SM ROLLED GAUZE 3\"\"X4.1YD",
              "SM STERILE": "SM STERILE",
              "STERILE": "STERILE"
            },
            "MEDICINE SHOPPE": {
              "NON-STICK": "NON-STICK",
              "STERILE": "STERILE"
            },
            "MEDLINE INDUSTRIES": {
              "CURAD GAUZE": "CURAD GAUZE",
              "CURAD HOLD TITE BANDAGE": "CURAD HOLD TITE BANDAGE",
              "CURAD NON-STICK": "CURAD NON-STICK"
            },
            "MEDLINE/DERMAL MANAGEMENT": {
              "BORDERED GAUZE": "BORDERED GAUZE",
              "CARRAFILM TRANSPARENT DRESSING": "CARRAFILM TRANSPARENT DRESSING",
              "CARRASMART": "CARRASMART",
              "CARRASMART FILM": "CARRASMART FILM",
              "CARRASMART FOAM": "CARRASMART FOAM",
              "OPTIFOAM": "OPTIFOAM"
            },
            "MEDTRONIC MINIMED": {
              "OPSITE IV 3000": "OPSITE IV 3000",
              "POLYSKIN TRANSPARENT DRESSING": "POLYSKIN TRANSPARENT DRESSING"
            },
            "MPM MEDICAL, INC.": {
              "DRYMAX EXTRA": "DRYMAX EXTRA"
            },
            "MYLAN INSTITUTIONAL": {
              "FLEXZAN": "FLEXZAN"
            },
            "QUALITY CARE": {
              "TEGADERM FILM 2-3/8\"\"X2-3/4\"\"": "TEGADERM FILM 2-3/8\"\"X2-3/4\"\"",
              "TEGADERM FILM 4\"\"X4-3/4\"\"": "TEGADERM FILM 4\"\"X4-3/4\"\""
            },
            "RELIAMED": {
              "GAUZE SPONGE": "GAUZE SPONGE",
              "TRANSPARENT I.V. SITE DRESSING": "TRANSPARENT I.V. SITE DRESSING"
            },
            "RITE AID CORPORATION": {
              "RA ALL PURPOSE DRESSINGS": "RA ALL PURPOSE DRESSINGS",
              "RA CONFORMED BANDAGE": "RA CONFORMED BANDAGE",
              "RA DRESSING SPONGES": "RA DRESSING SPONGES",
              "RA GAUZE BANDAGE": "RA GAUZE BANDAGE",
              "RA GAUZE SPONGES": "RA GAUZE SPONGES",
              "RA STERILE": "RA STERILE",
              "RA SURGICAL DRESSING": "RA SURGICAL DRESSING",
              "RA TUBULAR GAUZE/FINGER": "RA TUBULAR GAUZE/FINGER"
            },
            "ROCHE INSULIN DELIVERY SYSTEMS": {
              "OPSITE IV 3000": "OPSITE IV 3000",
              "TEGADERM": "TEGADERM",
              "TEGADERM HP": "TEGADERM HP"
            },
            "SMITH & NEPHEW UNITED": {
              "ALLEVYN NON-ADHESIVE": "ALLEVYN NON-ADHESIVE",
              "ALLEVYN PLUS CAVITY": "ALLEVYN PLUS CAVITY",
              "ALLEVYN THIN": "ALLEVYN THIN",
              "COVRSITE COVER DRESSING": "COVRSITE COVER DRESSING",
              "COVRSITE PLUS COMPOSITE DRESS": "COVRSITE PLUS COMPOSITE DRESS",
              "IV3000 1-HAND": "IV3000 1-HAND",
              "IV3000 1-HAND PEDIATRIC": "IV3000 1-HAND PEDIATRIC",
              "IV3000 FRAME DELIVERY": "IV3000 FRAME DELIVERY",
              "IV3000 STANDARD": "IV3000 STANDARD",
              "OPSITE 11\"\"X11-3/4\"\"": "OPSITE 11\"\"X11-3/4\"\"",
              "OPSITE 11\"\"X17-3/4\"\"": "OPSITE 11\"\"X17-3/4\"\"",
              "OPSITE 11\"\"X6\"\"": "OPSITE 11\"\"X6\"\"",
              "OPSITE 17-3/4\"\"X21-5/8\"\"": "OPSITE 17-3/4\"\"X21-5/8\"\"",
              "OPSITE 4\"\"X5-1/2\"\"": "OPSITE 4\"\"X5-1/2\"\"",
              "OPSITE FLEXIGRID 2-3/8\"\"X2-3/4\"\"": "OPSITE FLEXIGRID 2-3/8\"\"X2-3/4\"\"",
              "OPSITE FLEXIGRID 4\"\"X4-3/4\"\"": "OPSITE FLEXIGRID 4\"\"X4-3/4\"\"",
              "OPSITE FLEXIGRID 4-3/4\"\"X10\"\"": "OPSITE FLEXIGRID 4-3/4\"\"X10\"\"",
              "OPSITE FLEXIGRID 6\"\"X8\"\"": "OPSITE FLEXIGRID 6\"\"X8\"\"",
              "OPSITE POST-OP 10\"\"X4\"\"": "OPSITE POST-OP 10\"\"X4\"\"",
              "OPSITE POST-OP 13-3/4\"\"X4\"\"": "OPSITE POST-OP 13-3/4\"\"X4\"\"",
              "OPSITE POST-OP 4-3/4\"\"X4\"\"": "OPSITE POST-OP 4-3/4\"\"X4\"\"",
              "OPSITE POST-OP 8\"\"X4\"\"": "OPSITE POST-OP 8\"\"X4\"\"",
              "OPSITE POST-OP VISIBLE": "OPSITE POST-OP VISIBLE",
              "OPSITE POST-OP VISIBLE 10\"\"X4\"\"": "OPSITE POST-OP VISIBLE 10\"\"X4\"\"",
              "OPSITE POST-OP VISIBLE 4X3-1/8": "OPSITE POST-OP VISIBLE 4X3-1/8",
              "OPSITE POST-OP VISIBLE 6\"\"X4\"\"": "OPSITE POST-OP VISIBLE 6\"\"X4\"\"",
              "PRIMAPORE 11-3/4\"\"X4\"\"": "PRIMAPORE 11-3/4\"\"X4\"\"",
              "PRIMAPORE 13-3/4\"\"X4\"\"": "PRIMAPORE 13-3/4\"\"X4\"\"",
              "PRIMAPORE 2-7/8\"\"X2\"\"": "PRIMAPORE 2-7/8\"\"X2\"\"",
              "PRIMAPORE 4\"\"X3-1/8\"\"": "PRIMAPORE 4\"\"X3-1/8\"\"",
              "PRIMAPORE 6\"\"X3-1/8\"\"": "PRIMAPORE 6\"\"X3-1/8\"\"",
              "PRIMAPORE 8\"\"X4\"\"": "PRIMAPORE 8\"\"X4\"\""
            },
            "SOLUBLE SYSTEMS": {
              "THERAGAUZE": "THERAGAUZE"
            },
            "WALGREENS": {
              "GAUZE DRESSING": "GAUZE DRESSING",
              "GAUZE PADS": "GAUZE PADS",
              "GAUZE STRETCH BANDAGE": "GAUZE STRETCH BANDAGE",
              "NON-STICK": "NON-STICK",
              "STERILE BANDAGE ROLL 2.25\"\"X3YD": "STERILE BANDAGE ROLL 2.25\"\"X3YD",
              "STERILE GAUZE": "STERILE GAUZE",
              "STRETCH GAUZE BANDAGE": "STRETCH GAUZE BANDAGE",
              "SURGICAL DRESSING": "SURGICAL DRESSING",
              "TRANS WATERPROOF DRESSINGS": "TRANS WATERPROOF DRESSINGS",
              "TRANSPARENT FILM DRESSING": "TRANSPARENT FILM DRESSING",
              "WOUND TREATMENT": "WOUND TREATMENT"
            }
          },
          "Nasal Dilators": {
            "BERGEN BRUNSWIG": {
              "GNP NASAL STRIPS MED/LG": "GNP NASAL STRIPS MED/LG"
            },
            "CHAIN DRUG CONSORTIUM": {
              "NASAL STRIPS MED/LG": "NASAL STRIPS MED/LG",
              "NASAL STRIPS SM/MED": "NASAL STRIPS SM/MED"
            },
            "EQUALINE": {
              "EQL NASAL STRIPS LARGE": "EQL NASAL STRIPS LARGE",
              "EQL NASAL STRIPS MEDIUM": "EQL NASAL STRIPS MEDIUM"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "BREATHE RIGHT": "BREATHE RIGHT",
              "BREATHE RIGHT ADVANCED": "BREATHE RIGHT ADVANCED",
              "BREATHE RIGHT EXTRA": "BREATHE RIGHT EXTRA",
              "BREATHE RIGHT EXTRA STRENGTH": "BREATHE RIGHT EXTRA STRENGTH",
              "BREATHE RIGHT LARGE": "BREATHE RIGHT LARGE",
              "BREATHE RIGHT SMALL/MEDIUM": "BREATHE RIGHT SMALL/MEDIUM",
              "BREATHE RIGHT VAPOR SM/MED": "BREATHE RIGHT VAPOR SM/MED"
            },
            "LEADER BRAND PRODUCTS": {
              "NASAL STRIPS": "NASAL STRIPS",
              "NASAL STRIPS MED/LG": "NASAL STRIPS MED/LG"
            },
            "MCKESSON SUNMARK": {
              "SM NASAL LARGE": "SM NASAL LARGE"
            },
            "MEDICINE SHOPPE": {
              "NASAL STRIPS LARGE": "NASAL STRIPS LARGE"
            },
            "QUEST PRODUCTS": {
              "SLEEP-SNORE INTERNAL NASAL": "SLEEP-SNORE INTERNAL NASAL"
            },
            "RITE AID CORPORATION": {
              "RA NASAL LARGE": "RA NASAL LARGE",
              "RA NASAL MEDIUM": "RA NASAL MEDIUM",
              "RA NASAL MEDIUM/LARGE": "RA NASAL MEDIUM/LARGE",
              "RA NASAL STRIP/DRUG FREE/LARGE": "RA NASAL STRIP/DRUG FREE/LARGE"
            },
            "THERAVENT": {
              "PROVENT SLEEP APNEA THERAPY": "PROVENT SLEEP APNEA THERAPY",
              "PROVENT SR SLEEP APNEA THERAPY": "PROVENT SR SLEEP APNEA THERAPY"
            },
            "WALGREENS": {
              "NASAL DILATORS SMALL/MEDIUM": "NASAL DILATORS SMALL/MEDIUM",
              "NASAL STRIPS": "NASAL STRIPS",
              "NASAL STRIPS SM/MED": "NASAL STRIPS SM/MED",
              "REST EASY LARGE": "REST EASY LARGE",
              "REST EASY SMALL/MEDIUM": "REST EASY SMALL/MEDIUM"
            }
          },
          "Nasal Filters": {
            "QUEST PRODUCTS": {
              "NASAL FILTER": "NASAL FILTER"
            }
          },
          "Scar Treatments": {
            "AMELLA PHARMA": {
              "KELOTOP": "KELOTOP"
            },
            "BLAINE LABORATORIES": {
              "CICASIL": "CICASIL"
            },
            "CHAIN DRUG CONSORTIUM": {
              "SCAR TREATMENT": "SCAR TREATMENT"
            },
            "HUDSON SCIENTIFIC": {
              "NUVAZIL": "NUVAZIL"
            },
            "KAISER FOUNDATION HOSPITAL": {
              "KP SILICONE SCAR THERAPY GEL": "KP SILICONE SCAR THERAPY GEL"
            },
            "MESORA PHARMA": {
              "COATAMAX PATCH": "COATAMAX PATCH"
            },
            "NATIONAL BIO GREEN SCIENCES": {
              "CELLPAD": "CELLPAD",
              "PROSILK": "PROSILK",
              "SCARSILK": "SCARSILK"
            },
            "NEOMED PHARMACEUTICAL": {
              "DERM-SILK": "DERM-SILK"
            },
            "PERRIGO": {
              "SCARAWAY": "SCARAWAY"
            },
            "RICHMARK INTERNATIONAL CORP": {
              "REJUVENESS": "REJUVENESS"
            },
            "SA3": {
              "SCARCINPAD": "SCARCINPAD"
            },
            "SOLUTECH PHARMACEUTICALS": {
              "CRYSTASIL": "CRYSTASIL"
            },
            "STERLING KNIGHT PHARMACEUTICAL": {
              "POLYTOZA PATCH": "POLYTOZA PATCH",
              "SILIVEX": "SILIVEX"
            },
            "TMIG": {
              "CICATRACE PAD": "CICATRACE PAD"
            },
            "VIVERA PHARMACEUTICALS": {
              "SILTREX": "SILTREX",
              "SZOSIL 15 DAY SUPPLY": "SZOSIL 15 DAY SUPPLY",
              "SZOSIL 8 DAY SUPPLY": "SZOSIL 8 DAY SUPPLY"
            },
            "WALGREENS": {
              "SCAR TREATMENT": "SCAR TREATMENT",
              "SILICONE SCAR SHEETS": "SILICONE SCAR SHEETS"
            }
          }
        },
        "Blood Monitoring Supplies": {
          "Blood Cholesterol Monitoring Supplies": {
            "ROCHE DIAGNOSTICS": {
              "ACCUTREND CHOLESTEROL CONTROL": "ACCUTREND CHOLESTEROL CONTROL"
            }
          }
        },
        "Blood Pressure Devices": {
          "Blood Pressure Device Combinations": {
            "INTERCURE, INC.": {
              "RESPERATE 1.0": "RESPERATE 1.0",
              "RESPERATE 1.1": "RESPERATE 1.1"
            }
          },
          "Blood Pressure Devices": {
            "A & D MEDICAL": {
              "AC POWER ADAPTER": "AC POWER ADAPTER",
              "BLOOD PRESS MONITOR/M-L CUFF": "BLOOD PRESS MONITOR/M-L CUFF",
              "BLOOD PRESSURE CUFF": "BLOOD PRESSURE CUFF",
              "BLOOD PRESSURE MONITOR": "BLOOD PRESSURE MONITOR",
              "BLOOD PRESSURE MONITOR/L CUFF": "BLOOD PRESSURE MONITOR/L CUFF",
              "BLOOD PRESSURE MONITOR/M CUFF": "BLOOD PRESSURE MONITOR/M CUFF",
              "BLOOD PRESSURE MONITOR/S CUFF": "BLOOD PRESSURE MONITOR/S CUFF",
              "BP MONITOR-STETHOSCOPE": "BP MONITOR-STETHOSCOPE",
              "MULTI-USER BLOOD PRESSURE": "MULTI-USER BLOOD PRESSURE",
              "SLIMFIT LARGE CUFF": "SLIMFIT LARGE CUFF",
              "SLIMFIT MEDIUM CUFF": "SLIMFIT MEDIUM CUFF",
              "SLIMFIT SMALL CUFF": "SLIMFIT SMALL CUFF",
              "SMOOTHFIT CUFF": "SMOOTHFIT CUFF",
              "SPHYGMOMANOMETER": "SPHYGMOMANOMETER",
              "TALKING BLOOD PRESSURE MONITOR": "TALKING BLOOD PRESSURE MONITOR",
              "WRIST BLOOD PRESSURE MONITOR": "WRIST BLOOD PRESSURE MONITOR"
            },
            "ARISE MEDICAL": {
              "PROCARE UPPER ARM BP MONITOR": "PROCARE UPPER ARM BP MONITOR",
              "PROCARE WRIST BP MONITOR": "PROCARE WRIST BP MONITOR"
            },
            "BD DIABETES CARE": {
              "BD ASSURE BPM/AUTO ARM CUFF": "BD ASSURE BPM/AUTO ARM CUFF",
              "BD ASSURE BPM/AUTO WRIST CUFF": "BD ASSURE BPM/AUTO WRIST CUFF",
              "BD ASSURE BPM/DELUXE ARM CUFF": "BD ASSURE BPM/DELUXE ARM CUFF",
              "BD ASSURE BPM/MANUAL ARM CUFF": "BD ASSURE BPM/MANUAL ARM CUFF",
              "BD ASSURE BPM/PORT WRISTWATCH": "BD ASSURE BPM/PORT WRISTWATCH"
            },
            "BERGEN BRUNSWIG": {
              "GNP BLOOD PRESSURE MONITOR": "GNP BLOOD PRESSURE MONITOR"
            },
            "CHAIN DRUG CONSORTIUM": {
              "BLOOD PRESSURE CUFF": "BLOOD PRESSURE CUFF",
              "BLOOD PRESSURE MONITOR": "BLOOD PRESSURE MONITOR",
              "BLOOD PRESSURE MONITOR/WRIST": "BLOOD PRESSURE MONITOR/WRIST",
              "PREMIUM AUTOMATIC BP MONITOR": "PREMIUM AUTOMATIC BP MONITOR"
            },
            "COVIDIEN MEDICAL SUPPLIES": {
              "KENDALL SCD EXPRESS FOOT CUFF": "KENDALL SCD EXPRESS FOOT CUFF",
              "SCD EXPRESS FOOT CUFFS LARGE": "SCD EXPRESS FOOT CUFFS LARGE"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS ADVANCED AUTOMATIC BP": "CVS ADVANCED AUTOMATIC BP",
              "CVS BLOOD PRESSURE CUFF": "CVS BLOOD PRESSURE CUFF",
              "CVS BLOOD PRESSURE MONITOR": "CVS BLOOD PRESSURE MONITOR",
              "CVS MANUAL BLOOD PRESSURE": "CVS MANUAL BLOOD PRESSURE",
              "CVS SERIES 100 BLOOD PRESSURE": "CVS SERIES 100 BLOOD PRESSURE",
              "CVS SERIES 400 BLOOD PRESSURE": "CVS SERIES 400 BLOOD PRESSURE",
              "CVS SERIES 400W BLOOD PRESSURE": "CVS SERIES 400W BLOOD PRESSURE",
              "CVS SERIES 600 BLOOD PRESSURE": "CVS SERIES 600 BLOOD PRESSURE",
              "CVS SERIES 800 BLOOD PRESSURE": "CVS SERIES 800 BLOOD PRESSURE"
            },
            "DIABETIC SUPPLY OF SUNCOAST": {
              "ADVOCATE ARM BPM": "ADVOCATE ARM BPM",
              "ADVOCATE MEMORY BP MONITOR": "ADVOCATE MEMORY BP MONITOR",
              "ADVOCATE WRIST BP MONITOR": "ADVOCATE WRIST BP MONITOR"
            },
            "EQUALINE": {
              "EQL BLOOD PRESSURE MONITOR": "EQL BLOOD PRESSURE MONITOR"
            },
            "FORA CARE": {
              "FORA P20 BP MONITOR SYSTEM": "FORA P20 BP MONITOR SYSTEM",
              "FORA TEST N\\' GO BP": "FORA TEST N\\' GO BP"
            },
            "FUTURE DIAGNOSTICS": {
              "CARETOUCH BP ARM MONITOR": "CARETOUCH BP ARM MONITOR",
              "CARETOUCH BP WRIST MONITOR": "CARETOUCH BP WRIST MONITOR"
            },
            "GOJJI": {
              "GOJJI BLOOD PRESSURE MONITOR": "GOJJI BLOOD PRESSURE MONITOR"
            },
            "H E BUTT GROCERY COMPANY": {
              "H-E-B INCONTROL DELUXE AUTO BP": "H-E-B INCONTROL DELUXE AUTO BP",
              "H-E-B INCONTROL PREMIUM BP": "H-E-B INCONTROL PREMIUM BP"
            },
            "HOME AIDE DIAGNOSTICS": {
              "BLOOD PRESSURE MONITOR/WRIST": "BLOOD PRESSURE MONITOR/WRIST",
              "HEALTH SENSE BP MONITOR": "HEALTH SENSE BP MONITOR",
              "TALKING SENSE BP MONITOR": "TALKING SENSE BP MONITOR"
            },
            "J & J MEDICAL": {
              "CRITIKON CUFF": "CRITIKON CUFF",
              "CRITIKON CUFF COMPLETE SYSTEM": "CRITIKON CUFF COMPLETE SYSTEM",
              "CRITIKON CUFF NEO SOFT #2": "CRITIKON CUFF NEO SOFT #2",
              "CRITIKON CUFF NEO SOFT #3": "CRITIKON CUFF NEO SOFT #3",
              "CRITIKON CUFF NEO SOFT #4": "CRITIKON CUFF NEO SOFT #4",
              "CRITIKON CUFF NEO SOFT #5": "CRITIKON CUFF NEO SOFT #5",
              "CRITIKON CUFF NEONATAL #1": "CRITIKON CUFF NEONATAL #1",
              "CRITIKON CUFF NEONATAL #2": "CRITIKON CUFF NEONATAL #2",
              "CRITIKON CUFF NEONATAL #3": "CRITIKON CUFF NEONATAL #3",
              "CRITIKON CUFF NEONATAL #4": "CRITIKON CUFF NEONATAL #4",
              "CRITIKON CUFF NEONATAL #5": "CRITIKON CUFF NEONATAL #5",
              "DURA-CUF": "DURA-CUF",
              "DURA-CUF COMPLETE SYSTEM": "DURA-CUF COMPLETE SYSTEM"
            },
            "LEADER BRAND PRODUCTS": {
              "BLOOD PRESSURE MON/AUTO/WRIST": "BLOOD PRESSURE MON/AUTO/WRIST",
              "BLOOD PRESSURE MONITOR": "BLOOD PRESSURE MONITOR",
              "BLOOD PRESSURE MONITOR AUTOMAT": "BLOOD PRESSURE MONITOR AUTOMAT",
              "BLOOD PRESSURE MONITOR/WRIST": "BLOOD PRESSURE MONITOR/WRIST"
            },
            "LUMISCOPE": {
              "BLOOD PRESSURE": "BLOOD PRESSURE",
              "BLOOD PRESSURE MONITOR": "BLOOD PRESSURE MONITOR",
              "SPHYGMOMANOMETER": "SPHYGMOMANOMETER"
            },
            "MABIS HEALTHCARE": {
              "HEALTHSMART BP MONITOR/WRIST": "HEALTHSMART BP MONITOR/WRIST"
            },
            "MCKESSON HOME HEALTH CARE": {
              "BLOOD PRESSURE KIT": "BLOOD PRESSURE KIT",
              "BLOOD PRESSURE UNIT": "BLOOD PRESSURE UNIT",
              "SM BLOOD PRESSURE MONITOR": "SM BLOOD PRESSURE MONITOR",
              "SM WRIST CUFF BP MONITOR": "SM WRIST CUFF BP MONITOR"
            },
            "MHC MEDICAL PRODUCTS": {
              "SURELIFE BP MONITOR/ARM": "SURELIFE BP MONITOR/ARM",
              "SURELIFE BP MONITOR/WRIST": "SURELIFE BP MONITOR/WRIST"
            },
            "MICROLIFE": {
              "BLOOD PRESSURE MONITOR": "BLOOD PRESSURE MONITOR",
              "BLOOD PRESSURE MONITOR/ARM": "BLOOD PRESSURE MONITOR/ARM",
              "CVS ADVANCED BP MONITOR": "CVS ADVANCED BP MONITOR",
              "CVS BLOOD PRESSURE MONITOR": "CVS BLOOD PRESSURE MONITOR",
              "H-E-B INCONTROL BP MONITOR": "H-E-B INCONTROL BP MONITOR",
              "HM BLOOD PRESSURE MONITOR": "HM BLOOD PRESSURE MONITOR",
              "KROGER BLOOD PRESSURE MONITOR": "KROGER BLOOD PRESSURE MONITOR",
              "MICROLIFE BP MONITOR": "MICROLIFE BP MONITOR",
              "MICROLIFE BPM1 BP MONITOR": "MICROLIFE BPM1 BP MONITOR",
              "MICROLIFE BPM2 BP MONITOR": "MICROLIFE BPM2 BP MONITOR",
              "MICROLIFE BPM3 DELUXE MONITOR": "MICROLIFE BPM3 DELUXE MONITOR",
              "MICROLIFE BPM6 PREMIUM MONITOR": "MICROLIFE BPM6 PREMIUM MONITOR",
              "MICROLIFE DELUXE BP MONITOR": "MICROLIFE DELUXE BP MONITOR",
              "MICROLIFE WRIST BP MONITOR": "MICROLIFE WRIST BP MONITOR",
              "QC BLOOD PRESSURE MONITOR": "QC BLOOD PRESSURE MONITOR",
              "RA BLOOD PRESSURE CUFF MONITOR": "RA BLOOD PRESSURE CUFF MONITOR",
              "SM BLOOD PRESSURE MONITOR": "SM BLOOD PRESSURE MONITOR",
              "TGT BLOOD PRESSURE MONITOR": "TGT BLOOD PRESSURE MONITOR"
            },
            "OMRON MANAGED HEALTHCARE": {
              "10 SERIES BP MONITOR/UPPER ARM": "10 SERIES BP MONITOR/UPPER ARM",
              "10 SERIES+ BP MONITR/UPPER ARM": "10 SERIES+ BP MONITR/UPPER ARM",
              "3 SERIES BP MONITOR/UPPER ARM": "3 SERIES BP MONITOR/UPPER ARM",
              "3 SERIES BP MONITOR/WRIST": "3 SERIES BP MONITOR/WRIST",
              "5 SERIES BP MONITOR": "5 SERIES BP MONITOR",
              "5 SERIES BP MONITOR/UPPER ARM": "5 SERIES BP MONITOR/UPPER ARM",
              "7 SERIES BP MONITOR/UPPER ARM": "7 SERIES BP MONITOR/UPPER ARM",
              "7 SERIES BP MONITOR/WRIST": "7 SERIES BP MONITOR/WRIST",
              "ADULT BLOOD PRESSURE CUFF LG": "ADULT BLOOD PRESSURE CUFF LG",
              "BLOOD PRESSURE CUFF": "BLOOD PRESSURE CUFF",
              "BLOOD PRESSURE MONITOR": "BLOOD PRESSURE MONITOR",
              "BLOOD PRESSURE MONITOR 3": "BLOOD PRESSURE MONITOR 3",
              "BLOOD PRESSURE MONITOR 7": "BLOOD PRESSURE MONITOR 7",
              "OMRON 10 SERIES BP MONITOR": "OMRON 10 SERIES BP MONITOR",
              "OMRON 3 SERIES BP MONITOR": "OMRON 3 SERIES BP MONITOR",
              "OMRON 5 SERIES BP MONITOR": "OMRON 5 SERIES BP MONITOR",
              "OMRON 7 SERIES BP MONITOR": "OMRON 7 SERIES BP MONITOR",
              "OMRON WRIST BP MONITOR": "OMRON WRIST BP MONITOR",
              "SELF-TAKING BLOOD PRESSURE": "SELF-TAKING BLOOD PRESSURE",
              "SPHYGMOMANOMETER": "SPHYGMOMANOMETER",
              "TWO PARTY BLOOD PRESSURE": "TWO PARTY BLOOD PRESSURE",
              "WOMENS ADV BP MONITOR/UPPR ARM": "WOMENS ADV BP MONITOR/UPPR ARM",
              "WRIST BLOOD PRESSURE MONITOR": "WRIST BLOOD PRESSURE MONITOR"
            },
            "RELIAMED": {
              "BLOOD PRESSURE MONITOR AUTOMAT": "BLOOD PRESSURE MONITOR AUTOMAT"
            },
            "RITE AID CORPORATION": {
              "RA BLOOD PRESSURE CUFF MONITOR": "RA BLOOD PRESSURE CUFF MONITOR",
              "RA BP WRIST MONITOR AUTOMATIC": "RA BP WRIST MONITOR AUTOMATIC"
            },
            "SIMPLE DIAGNOSTICS": {
              "CLEVER CHOICE BP MONITOR/ARM": "CLEVER CHOICE BP MONITOR/ARM",
              "CLEVER CHOICE BP MONITOR/WRIST": "CLEVER CHOICE BP MONITOR/WRIST"
            },
            "SUNBEAM": {
              "BLOOD PRESSURE MONITOR": "BLOOD PRESSURE MONITOR"
            },
            "TOPCO": {
              "BLOOD PRESSURE MONITOR": "BLOOD PRESSURE MONITOR"
            },
            "WAL-MART": {
              "RELION BLOOD PRESSURE CUFF": "RELION BLOOD PRESSURE CUFF",
              "RELION BLOOD PRESSURE MONITOR": "RELION BLOOD PRESSURE MONITOR",
              "RELION LARGE CUFF": "RELION LARGE CUFF",
              "RELION PREMIUM MONITOR": "RELION PREMIUM MONITOR"
            },
            "WALGREENS": {
              "BLOOD PRESSURE CUFF": "BLOOD PRESSURE CUFF",
              "BLOOD PRESSURE KIT": "BLOOD PRESSURE KIT",
              "BLOOD PRESSURE MONITOR": "BLOOD PRESSURE MONITOR",
              "BLOOD PRESSURE MONITOR AUTOMAT": "BLOOD PRESSURE MONITOR AUTOMAT",
              "BLOOD PRESSURE MONITOR DELUXE": "BLOOD PRESSURE MONITOR DELUXE",
              "BLOOD PRESSURE MONITOR/ARM": "BLOOD PRESSURE MONITOR/ARM",
              "BLOOD PRESSURE MONITOR/PRM ARM": "BLOOD PRESSURE MONITOR/PRM ARM",
              "BLOOD PRESSURE MONITOR/WRIST": "BLOOD PRESSURE MONITOR/WRIST"
            },
            "XPRESS MEDICAL SUPPLY": {
              "CLEVER CHOICE BP MONITOR/ARM": "CLEVER CHOICE BP MONITOR/ARM"
            },
            "ZEWA": {
              "BLOOD PRESSURE MONITOR": "BLOOD PRESSURE MONITOR"
            }
          }
        },
        "Cardiology Supplies": {
          "Cardiac Defibrillators": {
            "PHILIPS MEDICAL": {
              "HEARTSTART HOME DEFIBRILLATOR": "HEARTSTART HOME DEFIBRILLATOR"
            }
          }
        },
        "Conception Assistance Supplies": {
          "Conception Assistance Supplies": {
            "CONCEIVEX": {
              "CONCEPTION KIT": "CONCEPTION KIT"
            },
            "P & G HEALTH": {
              "CLEARBLUE FERTILITY": "CLEARBLUE FERTILITY"
            },
            "UNIPATH DIAGNOSTIC": {
              "CLEARBLUE EASY FERTILITY": "CLEARBLUE EASY FERTILITY"
            }
          }
        },
        "Contraceptives": {
          "Cervical Caps": {
            "FEMCAP": {
              "FEMCAP": "FEMCAP"
            }
          },
          "Condoms - Female": {
            "FEMALE HEALTH COMPANY": {
              "FC2 FEMALE CONDOM": "FC2 FEMALE CONDOM"
            },
            "MAYER LABORATORIES": {
              "FC FEMALE CONDOM": "FC FEMALE CONDOM"
            },
            "THE FEMALE HEALTH COMPANY": {
              "FC2 FEMALE CONDOM": "FC2 FEMALE CONDOM"
            },
            "VERU": {
              "FC2 FEMALE CONDOM": "FC2 FEMALE CONDOM"
            }
          },
          "Condoms - Male": {
            "AIMSCO/DELTA HI-TECH": {
              "AIMSCO LUBRICATED": "AIMSCO LUBRICATED"
            },
            "ANSELL": {
              "LIFESTYLES ASSORTED COLORS": "LIFESTYLES ASSORTED COLORS",
              "LIFESTYLES EXTRA STRENGTH": "LIFESTYLES EXTRA STRENGTH",
              "LIFESTYLES FORM FITTING": "LIFESTYLES FORM FITTING",
              "LIFESTYLES LUBRICATED": "LIFESTYLES LUBRICATED",
              "LIFESTYLES RIBBED": "LIFESTYLES RIBBED",
              "LIFESTYLES SKYN ORIGINAL": "LIFESTYLES SKYN ORIGINAL",
              "LIFESTYLES SPERMICIDAL LUBE": "LIFESTYLES SPERMICIDAL LUBE",
              "LIFESTYLES STUDDED": "LIFESTYLES STUDDED",
              "LIFESTYLES ULTRA SENSITIVE": "LIFESTYLES ULTRA SENSITIVE",
              "LIFESTYLES VIBRA-RIBBED": "LIFESTYLES VIBRA-RIBBED",
              "LIFESTYLES XTRA PLEASURE": "LIFESTYLES XTRA PLEASURE"
            },
            "CHURCH DWIGHT": {
              "CLASS ACT LUBRICATED": "CLASS ACT LUBRICATED",
              "ELEXA NATURAL FEEL": "ELEXA NATURAL FEEL",
              "ELEXA STIMULATING": "ELEXA STIMULATING",
              "ELEXA ULTRA SENSITIVE": "ELEXA ULTRA SENSITIVE",
              "TROJAN": "TROJAN",
              "TROJAN ASSORTMENT PACK": "TROJAN ASSORTMENT PACK",
              "TROJAN EXTENDED PLEASURE/LUBE": "TROJAN EXTENDED PLEASURE/LUBE",
              "TROJAN EXTRA STRENGTH": "TROJAN EXTRA STRENGTH",
              "TROJAN MAGNUM": "TROJAN MAGNUM",
              "TROJAN MAGNUM WARM SENSATIONS": "TROJAN MAGNUM WARM SENSATIONS",
              "TROJAN MAGNUM XL LUBRICATED": "TROJAN MAGNUM XL LUBRICATED",
              "TROJAN NATURALAMB": "TROJAN NATURALAMB",
              "TROJAN NATURALAMB/SPERMICIDE": "TROJAN NATURALAMB/SPERMICIDE",
              "TROJAN PLEASURE MESH/SPERMICID": "TROJAN PLEASURE MESH/SPERMICID",
              "TROJAN PLUS": "TROJAN PLUS",
              "TROJAN REGULAR": "TROJAN REGULAR",
              "TROJAN RIBBED": "TROJAN RIBBED",
              "TROJAN RIBBED/SPERMICIDAL": "TROJAN RIBBED/SPERMICIDAL",
              "TROJAN SHARED SENSATION/LUBE": "TROJAN SHARED SENSATION/LUBE",
              "TROJAN SUPRAS SPERMICIDAL": "TROJAN SUPRAS SPERMICIDAL",
              "TROJAN TWISTED PLEASURE": "TROJAN TWISTED PLEASURE",
              "TROJAN ULTRA PLEASURE LUBRICAT": "TROJAN ULTRA PLEASURE LUBRICAT",
              "TROJAN VERY SENSITIVE LUBRICAT": "TROJAN VERY SENSITIVE LUBRICAT",
              "TROJAN VERY SENSITIVE SPERMICI": "TROJAN VERY SENSITIVE SPERMICI",
              "TROJAN VERY THIN LUBRICATED": "TROJAN VERY THIN LUBRICATED",
              "TROJAN VERY THIN SPERMICIDE": "TROJAN VERY THIN SPERMICIDE",
              "TROJAN-ENZ LUBRICATED": "TROJAN-ENZ LUBRICATED",
              "TROJAN-ENZ/SPERMICIDAL": "TROJAN-ENZ/SPERMICIDAL"
            },
            "DUREX CONSUMER PRODUCTS": {
              "EXTRA SENSITIVE SPERMICIDAL": "EXTRA SENSITIVE SPERMICIDAL",
              "HIGH SENSATION SPERMICIDAL": "HIGH SENSATION SPERMICIDAL",
              "INTENSE SENSATION": "INTENSE SENSATION",
              "ULTIMATE FEELING": "ULTIMATE FEELING"
            },
            "GLOBAL PROTECTION COMPANY": {
              "ATLAS COLOR CONDOM/SPERMICIDE": "ATLAS COLOR CONDOM/SPERMICIDE",
              "ATLAS COLOR LUBRICATED CONDOM": "ATLAS COLOR LUBRICATED CONDOM",
              "ATLAS LUB CONDOM/SPERMICIDE": "ATLAS LUB CONDOM/SPERMICIDE",
              "ATLAS LUBRICATED CONDOM": "ATLAS LUBRICATED CONDOM"
            },
            "LINE ONE LABORATORIES": {
              "FANTASY LUBRICATED": "FANTASY LUBRICATED",
              "FANTASY LUBRICATED/SPERMICIDE": "FANTASY LUBRICATED/SPERMICIDE",
              "KAMELEON LUBRICATED": "KAMELEON LUBRICATED",
              "TRUSTEX COLOR CONDOMS + LUBE": "TRUSTEX COLOR CONDOMS + LUBE",
              "TRUSTEX LUB/RIBBED/STUDDED": "TRUSTEX LUB/RIBBED/STUDDED",
              "TRUSTEX LUB/SPERMICIDE EX ST": "TRUSTEX LUB/SPERMICIDE EX ST",
              "TRUSTEX LUB/SPERMICIDE XL": "TRUSTEX LUB/SPERMICIDE XL",
              "TRUSTEX LUBRICATED": "TRUSTEX LUBRICATED",
              "TRUSTEX LUBRICATED EX LARGE": "TRUSTEX LUBRICATED EX LARGE",
              "TRUSTEX LUBRICATED EXTRA ST": "TRUSTEX LUBRICATED EXTRA ST",
              "TRUSTEX LUBRICATED/SPERMICIDE": "TRUSTEX LUBRICATED/SPERMICIDE",
              "TRUSTEX NATURAL CONDOMS + LUBE": "TRUSTEX NATURAL CONDOMS + LUBE",
              "TRUSTEX NON-LUBRICATED": "TRUSTEX NON-LUBRICATED",
              "TRUSTEX RIA LUB/SPERMICIDE": "TRUSTEX RIA LUB/SPERMICIDE",
              "TRUSTEX RIA LUBRICATED": "TRUSTEX RIA LUBRICATED",
              "TRUSTEX RIA NON-LUBRICATED": "TRUSTEX RIA NON-LUBRICATED",
              "TRUSTEX-NONOXYNOL-9/RIB/STUD": "TRUSTEX-NONOXYNOL-9/RIB/STUD"
            },
            "MAYER LABORATORIES": {
              "KIMONO": "KIMONO",
              "KIMONO COLORS": "KIMONO COLORS",
              "KIMONO MICRO THIN": "KIMONO MICRO THIN",
              "KIMONO MICRO THIN PLUS": "KIMONO MICRO THIN PLUS",
              "KIMONO PLUS": "KIMONO PLUS",
              "KIMONO PS": "KIMONO PS",
              "KIMONO PS PLUS": "KIMONO PS PLUS",
              "KIMONO SENSATION": "KIMONO SENSATION",
              "KIMONO SENSATION PLUS": "KIMONO SENSATION PLUS",
              "KIMONO SPECIAL": "KIMONO SPECIAL",
              "MAXX": "MAXX",
              "MAXX PLUS": "MAXX PLUS"
            },
            "MEIJER": {
              "REALITY LATEX CONDOMS": "REALITY LATEX CONDOMS",
              "REALITY LATEX/ULTRA TEXTURED": "REALITY LATEX/ULTRA TEXTURED",
              "REALITY LATEX/ULTRA THIN": "REALITY LATEX/ULTRA THIN"
            },
            "NNODUM CORPORATION": {
              "CONDOMS": "CONDOMS"
            },
            "RECKITT BENCKISER": {
              "DUREX EXTRA SENSITIVE": "DUREX EXTRA SENSITIVE",
              "DUREX REALFEEL": "DUREX REALFEEL",
              "K-Y ME & YOU EXTRA LUBRICATED": "K-Y ME & YOU EXTRA LUBRICATED",
              "K-Y ME & YOU INTENSE": "K-Y ME & YOU INTENSE"
            },
            "RUGBY LABORATORIES": {
              "PREMIUM CONDOMS LUBRICATED": "PREMIUM CONDOMS LUBRICATED"
            }
          },
          "Diaphragms": {
            "MILEX": {
              "OMNIFLEX DIAPHRAGM": "OMNIFLEX DIAPHRAGM",
              "WIDE-SEAL DIAPHRAGM 60": "WIDE-SEAL DIAPHRAGM 60",
              "WIDE-SEAL DIAPHRAGM 65": "WIDE-SEAL DIAPHRAGM 65",
              "WIDE-SEAL DIAPHRAGM 70": "WIDE-SEAL DIAPHRAGM 70",
              "WIDE-SEAL DIAPHRAGM 75": "WIDE-SEAL DIAPHRAGM 75",
              "WIDE-SEAL DIAPHRAGM 80": "WIDE-SEAL DIAPHRAGM 80",
              "WIDE-SEAL DIAPHRAGM 85": "WIDE-SEAL DIAPHRAGM 85",
              "WIDE-SEAL DIAPHRAGM 90": "WIDE-SEAL DIAPHRAGM 90",
              "WIDE-SEAL DIAPHRAGM 95": "WIDE-SEAL DIAPHRAGM 95"
            },
            "PHARMACIST PHARMACEUTICAL LLC": {
              "CAYA": "CAYA"
            }
          }
        },
        "Diabetic Supplies": {
          "Glucose Monitor & Blood Pressure Monitor Combinations": {
            "CITIHEALTH": {
              "NEUTEK 2TEK GLUCOSE/PRESSURE": "NEUTEK 2TEK GLUCOSE/PRESSURE"
            },
            "DIABETIC SUPPLY OF SUNCOAST": {
              "ADVOCATE DUO": "ADVOCATE DUO"
            },
            "FORA CARE": {
              "FORA D10 2-IN-1 MONITOR": "FORA D10 2-IN-1 MONITOR",
              "FORA D15G 2-IN-1 MONITOR": "FORA D15G 2-IN-1 MONITOR",
              "FORA D20 2-IN-1 MONITOR": "FORA D20 2-IN-1 MONITOR",
              "FORA D40 GLUCOSE/PRESSURE": "FORA D40 GLUCOSE/PRESSURE",
              "FORA D40G GLUCOSE/PRESSURE": "FORA D40G GLUCOSE/PRESSURE"
            },
            "GENEXEL-SEIN": {
              "DUO-CARE": "DUO-CARE"
            },
            "SIMPLE DIAGNOSTICS": {
              "CLEVER CHEK AUTO-CODE": "CLEVER CHEK AUTO-CODE"
            }
          },
          "Glucose Monitor & Cholesterol Monitor Combinations": {
            "ROCHE DIAGNOSTICS": {
              "ACCUTREND PLUS": "ACCUTREND PLUS"
            }
          },
          "Glucose Monitoring Test Supplies": {
            "ABBOTT DIABETES CARE": {
              "FREESTYLE CONTROL SOLUTION": "FREESTYLE CONTROL SOLUTION",
              "FREESTYLE FLASH SYSTEM": "FREESTYLE FLASH SYSTEM",
              "FREESTYLE FREEDOM": "FREESTYLE FREEDOM",
              "FREESTYLE FREEDOM LITE": "FREESTYLE FREEDOM LITE",
              "FREESTYLE INSULINX SYSTEM": "FREESTYLE INSULINX SYSTEM",
              "FREESTYLE LANCETS": "FREESTYLE LANCETS",
              "FREESTYLE LIBRE 14 DAY READER": "FREESTYLE LIBRE 14 DAY READER",
              "FREESTYLE LIBRE 14 DAY SENSOR": "FREESTYLE LIBRE 14 DAY SENSOR",
              "FREESTYLE LIBRE 2 READER SYSTM": "FREESTYLE LIBRE 2 READER SYSTM",
              "FREESTYLE LIBRE 2 SENSOR SYSTM": "FREESTYLE LIBRE 2 SENSOR SYSTM",
              "FREESTYLE LIBRE READER": "FREESTYLE LIBRE READER",
              "FREESTYLE LIBRE SENSOR SYSTEM": "FREESTYLE LIBRE SENSOR SYSTEM",
              "FREESTYLE LITE": "FREESTYLE LITE",
              "FREESTYLE PRECISION NEO SYSTEM": "FREESTYLE PRECISION NEO SYSTEM",
              "FREESTYLE SIDEKICK II": "FREESTYLE SIDEKICK II",
              "FREESTYLE SYSTEM": "FREESTYLE SYSTEM",
              "FREESTYLE UNISTICK II LANCETS": "FREESTYLE UNISTICK II LANCETS",
              "KROGER BLOOD GLUCOSE": "KROGER BLOOD GLUCOSE",
              "MEDISENSE GLUCOSE KETONE CONTR": "MEDISENSE GLUCOSE KETONE CONTR",
              "MEDISENSE HI/MID/LOW CONTROL": "MEDISENSE HI/MID/LOW CONTROL",
              "MEDISENSE HIGH/LOW CONTROL": "MEDISENSE HIGH/LOW CONTROL",
              "MEDISENSE MID CONTROL": "MEDISENSE MID CONTROL",
              "MEDISENSE THIN LANCETS": "MEDISENSE THIN LANCETS",
              "OPTIUM BLOOD GLUCOSE MONITOR": "OPTIUM BLOOD GLUCOSE MONITOR",
              "OPTIUM GLUCOSE MONITOR SYSTEM": "OPTIUM GLUCOSE MONITOR SYSTEM",
              "PRECISION GLUCOSE CONTROL": "PRECISION GLUCOSE CONTROL",
              "PRECISION GLUCOSE CONTROL SOLN": "PRECISION GLUCOSE CONTROL SOLN",
              "PRECISION GLUCOSE KETONE CONTR": "PRECISION GLUCOSE KETONE CONTR",
              "PRECISION GLUCOSE/KETONE CONTR": "PRECISION GLUCOSE/KETONE CONTR",
              "PRECISION LINK": "PRECISION LINK",
              "PRECISION QID MONITOR": "PRECISION QID MONITOR",
              "PRECISION SOF-TACT MONITOR": "PRECISION SOF-TACT MONITOR",
              "PRECISION XTRA": "PRECISION XTRA",
              "PRECISION XTRA MONITOR": "PRECISION XTRA MONITOR",
              "SOLARTEK GLUCOSE CONTROL": "SOLARTEK GLUCOSE CONTROL",
              "ULTIMA": "ULTIMA"
            },
            "ABLE DIAGNOSTICS": {
              "VIVAGUARD INO CONTROL SOLUTION": "VIVAGUARD INO CONTROL SOLUTION",
              "VIVAGUARD INO GLUCOSE METER": "VIVAGUARD INO GLUCOSE METER",
              "VIVAGUARD LANCETS": "VIVAGUARD LANCETS",
              "VIVAGUARD LANCING DEVICE": "VIVAGUARD LANCING DEVICE"
            },
            "ACCESS DIABETIC SUPPLY": {
              "LANCETS": "LANCETS"
            },
            "ACON LABORATORIES": {
              "ON CALL EXPRESS GLUCOSE CONTR": "ON CALL EXPRESS GLUCOSE CONTR",
              "ON CALL EXPRESS GLUCOSE METER": "ON CALL EXPRESS GLUCOSE METER",
              "ON CALL EXPRESS MONITORING SYS": "ON CALL EXPRESS MONITORING SYS",
              "ON CALL LANCETS": "ON CALL LANCETS",
              "ON CALL LANCING DEVICE": "ON CALL LANCING DEVICE",
              "ON CALL PLUS GLUCOSE CONTROL": "ON CALL PLUS GLUCOSE CONTROL",
              "ON CALL PLUS LANCETS": "ON CALL PLUS LANCETS",
              "ON CALL PLUS LANCING DEVICE": "ON CALL PLUS LANCING DEVICE",
              "ON CALL PLUS METER": "ON CALL PLUS METER",
              "ON CALL PLUS MONITORING SYSTEM": "ON CALL PLUS MONITORING SYSTEM",
              "ON CALL VIVID GLUCOSE CONTROL": "ON CALL VIVID GLUCOSE CONTROL",
              "ON CALL VIVID GLUCOSE METER": "ON CALL VIVID GLUCOSE METER",
              "ON CALL VIVID METER": "ON CALL VIVID METER",
              "ON CALL VIVID MONITORING": "ON CALL VIVID MONITORING",
              "ON CALL VIVID PAL METER": "ON CALL VIVID PAL METER",
              "OPTUMRX BLOOD GLUCOSE METER": "OPTUMRX BLOOD GLUCOSE METER",
              "OPTUMRX BLOOD GLUCOSE SYSTEM": "OPTUMRX BLOOD GLUCOSE SYSTEM",
              "OPTUMRX GLUCOSE CONTROL": "OPTUMRX GLUCOSE CONTROL"
            },
            "AGAMATRIX": {
              "AGAMATRIX AMP": "AGAMATRIX AMP",
              "AGAMATRIX CONTROL": "AGAMATRIX CONTROL",
              "AGAMATRIX CONTROL LEVEL 2": "AGAMATRIX CONTROL LEVEL 2",
              "AGAMATRIX CONTROL LEVEL 4": "AGAMATRIX CONTROL LEVEL 4",
              "AGAMATRIX JAZZ WIRELESS 2": "AGAMATRIX JAZZ WIRELESS 2",
              "AGAMATRIX PRESTO": "AGAMATRIX PRESTO",
              "AGAMATRIX PRESTO PRO METER": "AGAMATRIX PRESTO PRO METER",
              "AGAMATRIX ULTRA-THIN LANCETS": "AGAMATRIX ULTRA-THIN LANCETS",
              "LIBERTY GLUCOSE CONTROL": "LIBERTY GLUCOSE CONTROL",
              "LIBERTY NXT GENERATION MONITOR": "LIBERTY NXT GENERATION MONITOR",
              "WAVESENSE AMP": "WAVESENSE AMP",
              "WAVESENSE KEYNOTE": "WAVESENSE KEYNOTE",
              "WAVESENSE KEYNOTE PRO METER": "WAVESENSE KEYNOTE PRO METER"
            },
            "AIMSCO/DELTA HI-TECH": {
              "LANCET DEVICE": "LANCET DEVICE",
              "ULTRA-THIN II AUTO LANCET": "ULTRA-THIN II AUTO LANCET"
            },
            "AKORN CONSUMER": {
              "CHOICE DM DIABETES RISK TEST": "CHOICE DM DIABETES RISK TEST"
            },
            "ALLISON MEDICAL": {
              "HEALTHWISE LANCETS 30G": "HEALTHWISE LANCETS 30G",
              "HEALTHWISE LANCING PEN": "HEALTHWISE LANCING PEN",
              "SURE COMFORT LANCETS 18G": "SURE COMFORT LANCETS 18G",
              "SURE COMFORT LANCETS 21G": "SURE COMFORT LANCETS 21G",
              "SURE COMFORT LANCETS 23G": "SURE COMFORT LANCETS 23G",
              "SURE COMFORT LANCETS 28G": "SURE COMFORT LANCETS 28G",
              "SURE COMFORT LANCETS 30G": "SURE COMFORT LANCETS 30G",
              "SURE COMFORT LANCING PEN": "SURE COMFORT LANCING PEN"
            },
            "AMBIMEDINC": {
              "AUTO-LANCET": "AUTO-LANCET",
              "AUTO-LANCET MINI": "AUTO-LANCET MINI",
              "EZ-LETS LANCETS 21G": "EZ-LETS LANCETS 21G",
              "EZ-LETS LANCETS 23G": "EZ-LETS LANCETS 23G",
              "EZ-LETS LANCETS 26G": "EZ-LETS LANCETS 26G",
              "EZ-LETS LANCETS 28G": "EZ-LETS LANCETS 28G",
              "EZ-LETS LANCETS 30G": "EZ-LETS LANCETS 30G",
              "INSUL-TOTE": "INSUL-TOTE",
              "INSUL-TOTE JR": "INSUL-TOTE JR",
              "PEN-TOTE": "PEN-TOTE"
            },
            "AMERICAN SCREENING CORP": {
              "REVEAL BLOOD GLUCOSE MONITOR": "REVEAL BLOOD GLUCOSE MONITOR"
            },
            "AMIRA MEDICAL": {
              "AT LAST BLOOD GLUCOSE SYSTEM": "AT LAST BLOOD GLUCOSE SYSTEM",
              "AT LAST CONTROL": "AT LAST CONTROL",
              "AT LAST LANCETS": "AT LAST LANCETS"
            },
            "ANIMAS DIABETES": {
              "ONETOUCH PING METER REMOTE": "ONETOUCH PING METER REMOTE"
            },
            "ARISE MEDICAL": {
              "ULTRA-CARE LANCETS 30G": "ULTRA-CARE LANCETS 30G"
            },
            "ARKRAY USA": {
              "ADVANCE INTUITION CONTROL": "ADVANCE INTUITION CONTROL",
              "ADVANCE INTUITION METER": "ADVANCE INTUITION METER",
              "ADVANCE INTUITION MONITOR": "ADVANCE INTUITION MONITOR",
              "ADVANCE MICRO-DRAW CONTROL": "ADVANCE MICRO-DRAW CONTROL",
              "ADVANCE MICRO-DRAW METER": "ADVANCE MICRO-DRAW METER",
              "ADVANCE MICRO-DRAW NORMAL": "ADVANCE MICRO-DRAW NORMAL",
              "ASSURE 3 CONTROL": "ASSURE 3 CONTROL",
              "ASSURE 3 METER": "ASSURE 3 METER",
              "ASSURE 4 CONTROL LEVEL 1 & 2": "ASSURE 4 CONTROL LEVEL 1 & 2",
              "ASSURE 4 METER": "ASSURE 4 METER",
              "ASSURE DOSE CONTROL": "ASSURE DOSE CONTROL",
              "ASSURE DOSE NORM/HIGH CONTROL": "ASSURE DOSE NORM/HIGH CONTROL",
              "ASSURE HAEMOLANCE PLUS HIGH": "ASSURE HAEMOLANCE PLUS HIGH",
              "ASSURE HAEMOLANCE PLUS LOW": "ASSURE HAEMOLANCE PLUS LOW",
              "ASSURE HAEMOLANCE PLUS MICRO": "ASSURE HAEMOLANCE PLUS MICRO",
              "ASSURE HAEMOLANCE PLUS NORMAL": "ASSURE HAEMOLANCE PLUS NORMAL",
              "ASSURE HAEMOLANCE PLUS PED": "ASSURE HAEMOLANCE PLUS PED",
              "ASSURE II CONTROL": "ASSURE II CONTROL",
              "ASSURE II CONTROL LEVEL 1 & 2": "ASSURE II CONTROL LEVEL 1 & 2",
              "ASSURE LANCE LANCETS": "ASSURE LANCE LANCETS",
              "ASSURE LANCE LANCETS 21G": "ASSURE LANCE LANCETS 21G",
              "ASSURE LANCE PLUS SAFETY 25G": "ASSURE LANCE PLUS SAFETY 25G",
              "ASSURE LANCE PLUS SAFETY 30G": "ASSURE LANCE PLUS SAFETY 30G",
              "ASSURE LANCE SAFETY LANCET 28G": "ASSURE LANCE SAFETY LANCET 28G",
              "ASSURE PLATINUM METER": "ASSURE PLATINUM METER",
              "ASSURE PRISM CONTROL LEVEL 1&2": "ASSURE PRISM CONTROL LEVEL 1&2",
              "ASSURE PRISM MULTI METER": "ASSURE PRISM MULTI METER",
              "ASSURE PRO BLOOD GLUCOSE METER": "ASSURE PRO BLOOD GLUCOSE METER",
              "ASSURE PRO CONTROL LEVEL 1 & 2": "ASSURE PRO CONTROL LEVEL 1 & 2",
              "DIASCREEN 10": "DIASCREEN 10",
              "DIASCREEN 1B": "DIASCREEN 1B",
              "DIASCREEN 1G": "DIASCREEN 1G",
              "DIASCREEN 1K": "DIASCREEN 1K",
              "DIASCREEN 2GK": "DIASCREEN 2GK",
              "DIASCREEN 2GP": "DIASCREEN 2GP",
              "DIASCREEN 3": "DIASCREEN 3",
              "DIASCREEN 4NL": "DIASCREEN 4NL",
              "DIASCREEN 4OBL": "DIASCREEN 4OBL",
              "DIASCREEN 4PH": "DIASCREEN 4PH",
              "DIASCREEN 5": "DIASCREEN 5",
              "DIASCREEN 6": "DIASCREEN 6",
              "DIASCREEN 7": "DIASCREEN 7",
              "DIASCREEN 8": "DIASCREEN 8",
              "DIASCREEN 9": "DIASCREEN 9",
              "DIASCREEN LIQUID URINE CONTROL": "DIASCREEN LIQUID URINE CONTROL",
              "EASYPRO BLOOD GLUCOSE MONITOR": "EASYPRO BLOOD GLUCOSE MONITOR",
              "EASYPRO PLUS": "EASYPRO PLUS",
              "GLUCOCARD 01 BLOOD GLUCOSE": "GLUCOCARD 01 BLOOD GLUCOSE",
              "GLUCOCARD 01 CONTROL": "GLUCOCARD 01 CONTROL",
              "GLUCOCARD 01-MINI GLUCOSE": "GLUCOCARD 01-MINI GLUCOSE",
              "GLUCOCARD EXPRESSION CONTROL": "GLUCOCARD EXPRESSION CONTROL",
              "GLUCOCARD EXPRESSION MONITOR": "GLUCOCARD EXPRESSION MONITOR",
              "GLUCOCARD SHINE": "GLUCOCARD SHINE",
              "GLUCOCARD SHINE CONNEX": "GLUCOCARD SHINE CONNEX",
              "GLUCOCARD SHINE CONTROL": "GLUCOCARD SHINE CONTROL",
              "GLUCOCARD SHINE EXPRESS": "GLUCOCARD SHINE EXPRESS",
              "GLUCOCARD SHINE XL": "GLUCOCARD SHINE XL",
              "GLUCOCARD VITAL MONITOR": "GLUCOCARD VITAL MONITOR",
              "GLUCOCARD X-METER": "GLUCOCARD X-METER",
              "GLUCOCARD X-SENSOR CONTROL": "GLUCOCARD X-SENSOR CONTROL",
              "HAEMOLANCE": "HAEMOLANCE",
              "HAEMOLANCE LOW FLOW LANCETS": "HAEMOLANCE LOW FLOW LANCETS",
              "HAEMOLANCE PLUS": "HAEMOLANCE PLUS",
              "HAEMOLANCE PLUS HIGH FLOW": "HAEMOLANCE PLUS HIGH FLOW",
              "HAEMOLANCE PLUS LOW FLOW": "HAEMOLANCE PLUS LOW FLOW",
              "HAEMOLANCE PLUS MAX FLOW": "HAEMOLANCE PLUS MAX FLOW",
              "HAEMOLANCE PLUS PEDIATRIC FLOW": "HAEMOLANCE PLUS PEDIATRIC FLOW",
              "HYPOLANCE AST LANCING": "HYPOLANCE AST LANCING",
              "LANZO": "LANZO",
              "MULTI-LANCET DEVICE": "MULTI-LANCET DEVICE",
              "MULTI-LANCET DEVICE 2": "MULTI-LANCET DEVICE 2",
              "POCKETCHEM EZ CONTROL": "POCKETCHEM EZ CONTROL",
              "POCKETCHEM EZ SYSTEM": "POCKETCHEM EZ SYSTEM",
              "QUICKTEK": "QUICKTEK",
              "QUICKTEK CONTROL SOLUTION": "QUICKTEK CONTROL SOLUTION",
              "QUICKTEK/METER": "QUICKTEK/METER",
              "RELION MICRO": "RELION MICRO",
              "RELION PREMIER BLU MONITOR": "RELION PREMIER BLU MONITOR",
              "RELION PREMIER CLASSIC": "RELION PREMIER CLASSIC",
              "RELION PREMIER COMPACT SYSTEM": "RELION PREMIER COMPACT SYSTEM",
              "RELION PREMIER VOICE MONITOR": "RELION PREMIER VOICE MONITOR",
              "SELECT-LITE DEVICE/LANCETS": "SELECT-LITE DEVICE/LANCETS",
              "SELECT-LITE LANCING DEVICE": "SELECT-LITE LANCING DEVICE",
              "SUPREME II CONFIDENCE PADDLES": "SUPREME II CONFIDENCE PADDLES",
              "SUPREME II HIGH/LOW CONTROL": "SUPREME II HIGH/LOW CONTROL",
              "TECHLITE AST LANCETS": "TECHLITE AST LANCETS",
              "TECHLITE LANCETS": "TECHLITE LANCETS",
              "TECHLITE LANCETS 30G": "TECHLITE LANCETS 30G"
            },
            "ASCENSIA DIABETES CARE": {
              "BAYER BREEZE 2 CONTROL": "BAYER BREEZE 2 CONTROL",
              "BAYER BREEZE 2 SYSTEM": "BAYER BREEZE 2 SYSTEM",
              "BAYER CONTOUR LINK MONITOR": "BAYER CONTOUR LINK MONITOR",
              "BAYER CONTOUR MONITOR": "BAYER CONTOUR MONITOR",
              "BAYER MICROLET 2 LANCING DEVIC": "BAYER MICROLET 2 LANCING DEVIC",
              "BAYER MICROLET LANCETS": "BAYER MICROLET LANCETS",
              "BREEZE 2 BLOOD GLUCOSE SYSTEM": "BREEZE 2 BLOOD GLUCOSE SYSTEM",
              "CONTOUR BLOOD GLUCOSE SYSTEM": "CONTOUR BLOOD GLUCOSE SYSTEM",
              "CONTOUR CONTROL": "CONTOUR CONTROL",
              "CONTOUR MONITOR": "CONTOUR MONITOR",
              "CONTOUR NEXT CONTROL": "CONTOUR NEXT CONTROL",
              "CONTOUR NEXT EZ": "CONTOUR NEXT EZ",
              "CONTOUR NEXT LINK": "CONTOUR NEXT LINK",
              "CONTOUR NEXT MONITOR": "CONTOUR NEXT MONITOR",
              "CONTOUR NEXT ONE": "CONTOUR NEXT ONE",
              "CONTOUR NEXT USB MONITOR": "CONTOUR NEXT USB MONITOR",
              "FINGERSTIX LANCETS": "FINGERSTIX LANCETS",
              "GLUCOLET 2 AUTOMATIC LANCING": "GLUCOLET 2 AUTOMATIC LANCING",
              "MICROLET LANCETS": "MICROLET LANCETS",
              "MICROLET NEXT LANCING DEVICE": "MICROLET NEXT LANCING DEVICE",
              "SINGLE-LET": "SINGLE-LET"
            },
            "BD DIABETES CARE": {
              "BD LANCET DEVICE": "BD LANCET DEVICE",
              "BD LANCET ULTRAFINE 30G": "BD LANCET ULTRAFINE 30G",
              "BD LANCET ULTRAFINE 33G": "BD LANCET ULTRAFINE 33G",
              "BD LATITUDE DIABETES": "BD LATITUDE DIABETES",
              "BD LATITUDE DIABETES SYSTEM": "BD LATITUDE DIABETES SYSTEM",
              "BD LOGIC BLOOD GLUCOSE MONITOR": "BD LOGIC BLOOD GLUCOSE MONITOR",
              "BD MAGNI-GUIDE MAGNIFIER": "BD MAGNI-GUIDE MAGNIFIER",
              "BD MICROTAINER LANCETS": "BD MICROTAINER LANCETS"
            },
            "BD MEDICAL SURGICAL SYSTEMS": {
              "MICROTAINER SAFETY FLOW LANCET": "MICROTAINER SAFETY FLOW LANCET"
            },
            "BERGEN BRUNSWIG": {
              "GNP LANCETS": "GNP LANCETS",
              "GNP LANCETS THIN": "GNP LANCETS THIN",
              "GNP LANCETS THIN 26G": "GNP LANCETS THIN 26G",
              "GNP MICRO THIN LANCETS 33G": "GNP MICRO THIN LANCETS 33G",
              "GNP SUPER THIN LANCETS 30G": "GNP SUPER THIN LANCETS 30G"
            },
            "BIONIME USA CORPORATION": {
              "GE100 BLOOD GLUCOSE SYSTEM": "GE100 BLOOD GLUCOSE SYSTEM",
              "GE100 CONTROL": "GE100 CONTROL",
              "LANCING DEVICE": "LANCING DEVICE",
              "RIGHTEST ALTERNATE SITE ADAPT": "RIGHTEST ALTERNATE SITE ADAPT",
              "RIGHTEST GC300 CONTROL": "RIGHTEST GC300 CONTROL",
              "RIGHTEST GD500 LANCING DEVICE": "RIGHTEST GD500 LANCING DEVICE",
              "RIGHTEST GL300 LANCETS": "RIGHTEST GL300 LANCETS",
              "RIGHTEST GM100 BLOOD GLUCOSE": "RIGHTEST GM100 BLOOD GLUCOSE",
              "RIGHTEST GM300 BLOOD GLUCOSE": "RIGHTEST GM300 BLOOD GLUCOSE",
              "RIGHTEST GM550 BLOOD GLUCOSE": "RIGHTEST GM550 BLOOD GLUCOSE"
            },
            "BIOSENSE MEDICAL DEVICES": {
              "SOLUS V2 BLOOD GLUCOSE SYSTEM": "SOLUS V2 BLOOD GLUCOSE SYSTEM",
              "SOLUS V2 CONTROL": "SOLUS V2 CONTROL",
              "SOLUS V2 LANCETS 28G": "SOLUS V2 LANCETS 28G",
              "SOLUS V2 LANCING DEVICE": "SOLUS V2 LANCING DEVICE",
              "SOLUS V2 TWIST LANCETS 30G": "SOLUS V2 TWIST LANCETS 30G"
            },
            "BOCA MEDICAL PRODUCTS": {
              "LIBERTY MEDICAL LANCETS": "LIBERTY MEDICAL LANCETS",
              "ULTI-LANCE AUTOMATIC": "ULTI-LANCE AUTOMATIC",
              "ULTILET CLASSIC LANCETS": "ULTILET CLASSIC LANCETS",
              "ULTILET LANCETS": "ULTILET LANCETS",
              "ULTILET SAFETY LANCETS": "ULTILET SAFETY LANCETS",
              "ULTILET SAFETY LANCETS 23G": "ULTILET SAFETY LANCETS 23G"
            },
            "CAMBRIDGE SENSORS USA": {
              "MICRODOT BLOOD GLUCOSE SYSTEM": "MICRODOT BLOOD GLUCOSE SYSTEM",
              "MICRODOT CONTROL HIGH/LOW": "MICRODOT CONTROL HIGH/LOW"
            },
            "CARDIOCOM": {
              "CARDIOCOM LANCING DEVICE": "CARDIOCOM LANCING DEVICE",
              "GLUCOCOM AUTOLINK TELEMONITOR": "GLUCOCOM AUTOLINK TELEMONITOR",
              "GLUCOCOM BLOOD GLUCOSE MONITOR": "GLUCOCOM BLOOD GLUCOSE MONITOR",
              "GLUCOCOM CONTROL": "GLUCOCOM CONTROL",
              "GLUCOCOM LANCETS 28G": "GLUCOCOM LANCETS 28G",
              "GLUCOCOM LANCETS 30G": "GLUCOCOM LANCETS 30G",
              "GLUCOCOM LANCETS 33G": "GLUCOCOM LANCETS 33G",
              "GLUCOCOM MONITOR": "GLUCOCOM MONITOR"
            },
            "CHAIN DRUG CONSORTIUM": {
              "LANCING DEVICE": "LANCING DEVICE",
              "TRUETRACK SMART SYSTEM": "TRUETRACK SMART SYSTEM",
              "ULTRA THIN LANCETS 30G": "ULTRA THIN LANCETS 30G",
              "UNILET MICRO-THIN 33G": "UNILET MICRO-THIN 33G"
            },
            "CITIHEALTH": {
              "NEUTEK 2TEK CONTROL": "NEUTEK 2TEK CONTROL"
            },
            "CLOSER HEALTHCARE": {
              "CLOSERCARE": "CLOSERCARE",
              "NETGROUP LANCETS": "NETGROUP LANCETS"
            },
            "COVIDIEN MEDICAL SUPPLIES": {
              "MONOJECTOR END CAPS": "MONOJECTOR END CAPS",
              "MONOJECTOR OPD END CAPS": "MONOJECTOR OPD END CAPS",
              "MONOLET LANCETS": "MONOLET LANCETS",
              "MONOLET OPD LANCETS": "MONOLET OPD LANCETS",
              "MONOLETTOR SAFETY LANCETS": "MONOLETTOR SAFETY LANCETS"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS LANCETS ORIGINAL": "CVS LANCETS ORIGINAL",
              "CVS LANCETS THIN 26G": "CVS LANCETS THIN 26G",
              "CVS LANCING DEVICE": "CVS LANCING DEVICE",
              "CVS ULTRA THIN LANCETS": "CVS ULTRA THIN LANCETS"
            },
            "DELTA HI-TECH": {
              "AIMSCO TWIST LANCETS 32G": "AIMSCO TWIST LANCETS 32G",
              "AIMSCO TWIST LANCETS 33G": "AIMSCO TWIST LANCETS 33G",
              "MINI LANCING DEVICE": "MINI LANCING DEVICE",
              "ULTRA-THIN II AUTO LANCET": "ULTRA-THIN II AUTO LANCET",
              "ULTRA-THIN II LANCETS": "ULTRA-THIN II LANCETS"
            },
            "DEXCOM": {
              "DEXCOM G4 PLAT PED RCV/SHARE": "DEXCOM G4 PLAT PED RCV/SHARE",
              "DEXCOM G4 PLAT PED RECEIVER": "DEXCOM G4 PLAT PED RECEIVER",
              "DEXCOM G4 PLATINUM RCV/SHARE": "DEXCOM G4 PLATINUM RCV/SHARE",
              "DEXCOM G4 PLATINUM RECEIVER": "DEXCOM G4 PLATINUM RECEIVER",
              "DEXCOM G4 PLATINUM TRANSMITTER": "DEXCOM G4 PLATINUM TRANSMITTER",
              "DEXCOM G4 SENSOR": "DEXCOM G4 SENSOR",
              "DEXCOM G5 MOB/G4 PLAT SENSOR": "DEXCOM G5 MOB/G4 PLAT SENSOR",
              "DEXCOM G5 MOBILE RECEIVER": "DEXCOM G5 MOBILE RECEIVER",
              "DEXCOM G5 MOBILE TRANSMITTER": "DEXCOM G5 MOBILE TRANSMITTER",
              "DEXCOM G5 RECEIVER KIT": "DEXCOM G5 RECEIVER KIT",
              "DEXCOM G6 RECEIVER": "DEXCOM G6 RECEIVER",
              "DEXCOM G6 SENSOR": "DEXCOM G6 SENSOR",
              "DEXCOM G6 TRANSMITTER": "DEXCOM G6 TRANSMITTER"
            },
            "DIABETIC SUPPLY OF SUNCOAST": {
              "ADVOCATE BLOOD GLUCOSE MONITOR": "ADVOCATE BLOOD GLUCOSE MONITOR",
              "ADVOCATE BLOOD GLUCOSE SYSTEM": "ADVOCATE BLOOD GLUCOSE SYSTEM",
              "ADVOCATE CONTROL SOLUTION": "ADVOCATE CONTROL SOLUTION",
              "ADVOCATE LANCETS": "ADVOCATE LANCETS",
              "ADVOCATE LANCETS 30G": "ADVOCATE LANCETS 30G",
              "ADVOCATE LANCING DEVICE": "ADVOCATE LANCING DEVICE",
              "ADVOCATE RAPID-SAFE LANCING": "ADVOCATE RAPID-SAFE LANCING",
              "ADVOCATE REDI-CODE": "ADVOCATE REDI-CODE",
              "ADVOCATE REDI-CODE+": "ADVOCATE REDI-CODE+",
              "ADVOCATE REDI-CODE+ CONTROL": "ADVOCATE REDI-CODE+ CONTROL",
              "ADVOCATE REDI-CODE+ TALKING": "ADVOCATE REDI-CODE+ TALKING",
              "ADVOCATE SAFETY LANCETS": "ADVOCATE SAFETY LANCETS",
              "ADVOCATE SAFETY LANCETS 26G": "ADVOCATE SAFETY LANCETS 26G"
            },
            "DIATHRIVE": {
              "DIATHRIVE BLOOD GLUCOSE METER": "DIATHRIVE BLOOD GLUCOSE METER",
              "DIATHRIVE GLUCOSE CONTROL SOLN": "DIATHRIVE GLUCOSE CONTROL SOLN",
              "DIATHRIVE LANCET ULTRA THIN 30": "DIATHRIVE LANCET ULTRA THIN 30",
              "DIATHRIVE LANCETS": "DIATHRIVE LANCETS",
              "DIATHRIVE LANCING DEVICE": "DIATHRIVE LANCING DEVICE",
              "DIATHRIVE+ GLUCOSE MONITOR": "DIATHRIVE+ GLUCOSE MONITOR"
            },
            "ENTRA HEALTH SYSTEMS": {
              "MYGLUCOHEALTH BLOOD GLUCOSE": "MYGLUCOHEALTH BLOOD GLUCOSE",
              "MYGLUCOHEALTH CONTROL": "MYGLUCOHEALTH CONTROL",
              "MYGLUCOHEALTH LANCETS 30G": "MYGLUCOHEALTH LANCETS 30G"
            },
            "EOCENE HEALTH SYSTEMS": {
              "DIATRUE CONTROL LEVEL 1": "DIATRUE CONTROL LEVEL 1",
              "DIATRUE CONTROL LEVEL 2": "DIATRUE CONTROL LEVEL 2",
              "DIATRUE CONTROL LEVEL 3": "DIATRUE CONTROL LEVEL 3",
              "DIATRUE PLUS BLOOD GLUCOSE": "DIATRUE PLUS BLOOD GLUCOSE"
            },
            "EPS BIOTECHNOLOGY": {
              "EASYMAX 15 LEVEL 1 CONTROL": "EASYMAX 15 LEVEL 1 CONTROL",
              "EASYMAX 15 LEVEL 1-2 CONTROL": "EASYMAX 15 LEVEL 1-2 CONTROL",
              "EASYMAX 15 LEVEL 2 CONTROL": "EASYMAX 15 LEVEL 2 CONTROL",
              "EASYMAX 15 LEVEL 2-3 CONTROL": "EASYMAX 15 LEVEL 2-3 CONTROL",
              "EASYMAX CONTROL": "EASYMAX CONTROL",
              "EASYMAX CONTROL NORMAL/HIGH": "EASYMAX CONTROL NORMAL/HIGH",
              "EASYMAX CONTROL NORMAL/LOW": "EASYMAX CONTROL NORMAL/LOW",
              "EASYMAX L BLOOD GLUCOSE": "EASYMAX L BLOOD GLUCOSE",
              "EASYMAX N BLOOD GLUCOSE": "EASYMAX N BLOOD GLUCOSE",
              "EASYMAX NG BLOOD GLUCOSE": "EASYMAX NG BLOOD GLUCOSE",
              "EASYMAX V BLOOD GLUCOSE": "EASYMAX V BLOOD GLUCOSE",
              "EASYMAX V2 BLOOD GLUCOSE": "EASYMAX V2 BLOOD GLUCOSE",
              "EASYPLUS R13N BLOOD GLUCOSE": "EASYPLUS R13N BLOOD GLUCOSE",
              "EASYPLUS V BLOOD GLUCOSE": "EASYPLUS V BLOOD GLUCOSE",
              "FORTISCARE CONTROL": "FORTISCARE CONTROL",
              "FORTISCARE GLUCOSE SYSTEM": "FORTISCARE GLUCOSE SYSTEM",
              "FORTISCARE T1 GLUCOSE SYSTEM": "FORTISCARE T1 GLUCOSE SYSTEM"
            },
            "EQUALINE": {
              "EQL TRUE2GO BLOOD GLUCOSE": "EQL TRUE2GO BLOOD GLUCOSE"
            },
            "EZ UPC.INFO": {
              "REFUAH PLUS GLUCOSE CONTROL": "REFUAH PLUS GLUCOSE CONTROL",
              "REFUAH PLUS MONITORING SYSTEM": "REFUAH PLUS MONITORING SYSTEM"
            },
            "FACET TECHNOLOGIES": {
              "CLEANLET LANCETS 28G": "CLEANLET LANCETS 28G",
              "SURELITE LANCETS": "SURELITE LANCETS"
            },
            "FIFTY50 MEDICAL": {
              "FIFTY50 CONTROL 2.0": "FIFTY50 CONTROL 2.0",
              "FIFTY50 GLUCOSE METER 2.0": "FIFTY50 GLUCOSE METER 2.0",
              "FIFTY50 LANCING DEVICE": "FIFTY50 LANCING DEVICE",
              "FIFTY50 SAFETY SEAL LANCETS": "FIFTY50 SAFETY SEAL LANCETS",
              "FIFTY50 UNILET LANCETS 33G": "FIFTY50 UNILET LANCETS 33G"
            },
            "FORA CARE": {
              "CONTROL": "CONTROL",
              "FORA CONTROL": "FORA CONTROL",
              "FORA G20 BLOOD GLUCOSE SYSTEM": "FORA G20 BLOOD GLUCOSE SYSTEM",
              "FORA G30A BLOOD GLUCOSE SYSTEM": "FORA G30A BLOOD GLUCOSE SYSTEM",
              "FORA GD20 BLOOD GLUCOSE SYSTEM": "FORA GD20 BLOOD GLUCOSE SYSTEM",
              "FORA GD50 BLOOD GLUCOSE SYSTEM": "FORA GD50 BLOOD GLUCOSE SYSTEM",
              "FORA GTEL BLOOD GLUCOSE SYSTEM": "FORA GTEL BLOOD GLUCOSE SYSTEM",
              "FORA LANCETS": "FORA LANCETS",
              "FORA LANCING DEVICE": "FORA LANCING DEVICE",
              "FORA PREMIUM V10 BLE SYSTEM": "FORA PREMIUM V10 BLE SYSTEM",
              "FORA TEST N\\' GO MONITOR": "FORA TEST N\\' GO MONITOR",
              "FORA TN\\'G VOICE": "FORA TN\\'G VOICE",
              "FORA V10 BLOOD GLUCOSE SYSTEM": "FORA V10 BLOOD GLUCOSE SYSTEM",
              "FORA V10/V12/D10/D20 TEST": "FORA V10/V12/D10/D20 TEST",
              "FORA V12 BLOOD GLUCOSE SYSTEM": "FORA V12 BLOOD GLUCOSE SYSTEM",
              "FORA V20 BLOOD GLUCOSE SYSTEM": "FORA V20 BLOOD GLUCOSE SYSTEM",
              "FORA V30A BLOOD GLUCOSE SYSTEM": "FORA V30A BLOOD GLUCOSE SYSTEM",
              "FORACARE GD40 MONITOR": "FORACARE GD40 MONITOR",
              "FORACARE GDH CONTROL": "FORACARE GDH CONTROL",
              "FORACARE PREMIUM V10": "FORACARE PREMIUM V10",
              "FORACARE TEST N GO MONITOR": "FORACARE TEST N GO MONITOR",
              "PRO VOICE V8 GLUCOSE SYSTEM": "PRO VOICE V8 GLUCOSE SYSTEM",
              "PRO VOICE V9 GLUCOSE SYSTEM": "PRO VOICE V9 GLUCOSE SYSTEM"
            },
            "FUTURA MEDICAL CORPORATION": {
              "GENTLE-LET GP LANCETS": "GENTLE-LET GP LANCETS",
              "GENTLE-LET LANCETS": "GENTLE-LET LANCETS",
              "GENTLE-LET PLATFORMS": "GENTLE-LET PLATFORMS",
              "LANCET TRANSPORTER CASE": "LANCET TRANSPORTER CASE",
              "PRECISION THINS GP LANCETS": "PRECISION THINS GP LANCETS",
              "PSS SELECT GP LANCETS": "PSS SELECT GP LANCETS",
              "PSS SELECT PLATFORMS": "PSS SELECT PLATFORMS",
              "PSS SELECT SAFETY LANCETS": "PSS SELECT SAFETY LANCETS",
              "SAFE-T-LANCE": "SAFE-T-LANCE",
              "SAFE-T-LANCE PLUS": "SAFE-T-LANCE PLUS",
              "THINLETS GP LANCETS": "THINLETS GP LANCETS"
            },
            "FUTURE DIAGNOSTICS": {
              "CARETOUCH CONTROL SOL LEVEL 2": "CARETOUCH CONTROL SOL LEVEL 2",
              "CARETOUCH LANCING/EJECTOR": "CARETOUCH LANCING/EJECTOR",
              "CARETOUCH MONITOR SYSTEM": "CARETOUCH MONITOR SYSTEM",
              "CARETOUCH SAFETY LANCETS": "CARETOUCH SAFETY LANCETS",
              "CARETOUCH SAFETY LANCETS 26G": "CARETOUCH SAFETY LANCETS 26G",
              "CARETOUCH TWIST LANCETS 28G": "CARETOUCH TWIST LANCETS 28G",
              "CARETOUCH TWIST LANCETS 30G": "CARETOUCH TWIST LANCETS 30G",
              "CARETOUCH TWIST LANCETS 33G": "CARETOUCH TWIST LANCETS 33G"
            },
            "GENESIS HEALTH TECHNOLOGIES": {
              "GHT BLOOD GLUCOSE MONITOR": "GHT BLOOD GLUCOSE MONITOR"
            },
            "GENEXEL-SEIN": {
              "DUO-CARE CONTROL SOLUTION": "DUO-CARE CONTROL SOLUTION"
            },
            "GENTEEL": {
              "GENTEEL BUTTERFLY TOUCH LANCET": "GENTEEL BUTTERFLY TOUCH LANCET",
              "GENTEEL CONTACT TIPS (BLUE)": "GENTEEL CONTACT TIPS (BLUE)",
              "GENTEEL CONTACT TIPS (CLEAR)": "GENTEEL CONTACT TIPS (CLEAR)",
              "GENTEEL CONTACT TIPS (GREEN)": "GENTEEL CONTACT TIPS (GREEN)",
              "GENTEEL CONTACT TIPS (ORANGE)": "GENTEEL CONTACT TIPS (ORANGE)",
              "GENTEEL CONTACT TIPS (RAINBOW)": "GENTEEL CONTACT TIPS (RAINBOW)",
              "GENTEEL CONTACT TIPS (VIOLET)": "GENTEEL CONTACT TIPS (VIOLET)",
              "GENTEEL CONTACT TIPS (YELLOW)": "GENTEEL CONTACT TIPS (YELLOW)",
              "GENTEEL LANCING DEVICE (BLACK)": "GENTEEL LANCING DEVICE (BLACK)",
              "GENTEEL LANCING DEVICE (BLUE)": "GENTEEL LANCING DEVICE (BLUE)",
              "GENTEEL LANCING DEVICE (GOLD)": "GENTEEL LANCING DEVICE (GOLD)",
              "GENTEEL LANCING DEVICE (PINK)": "GENTEEL LANCING DEVICE (PINK)",
              "GENTEEL LANCING DEVICE (WHITE)": "GENTEEL LANCING DEVICE (WHITE)",
              "GENTEEL LANCING DEVICE(PLATNM)": "GENTEEL LANCING DEVICE(PLATNM)",
              "GENTEEL LANCING DEVICE(PURPLE)": "GENTEEL LANCING DEVICE(PURPLE)",
              "GENTEEL LANCING DEVICE(SILVER)": "GENTEEL LANCING DEVICE(SILVER)",
              "GENTEEL LANCING KIT (BLUE)": "GENTEEL LANCING KIT (BLUE)",
              "GENTEEL NOZZLES": "GENTEEL NOZZLES"
            },
            "GLOBAL MEDICAL PRODUCTS": {
              "GLOBAL INJECT EASE LANCETS 28G": "GLOBAL INJECT EASE LANCETS 28G",
              "GLOBAL INJECT EASE LANCETS 30G": "GLOBAL INJECT EASE LANCETS 30G",
              "GLOBAL LANCING DEVICE": "GLOBAL LANCING DEVICE",
              "LANCETS": "LANCETS"
            },
            "GLUCO PERFECT": {
              "GLUCO PERFECT 3 METER": "GLUCO PERFECT 3 METER",
              "PERFECT LANCETS 28G": "PERFECT LANCETS 28G",
              "PERFECT LANCETS 30G": "PERFECT LANCETS 30G"
            },
            "GOJJI": {
              "GOJJI CONTROL": "GOJJI CONTROL",
              "GOJJI LANCING DEVICE/CLEAR CAP": "GOJJI LANCING DEVICE/CLEAR CAP",
              "GOJJI STERILE LANCETS": "GOJJI STERILE LANCETS"
            },
            "H&H WHOLESALE INC": {
              "EASYTEST II LANCETS": "EASYTEST II LANCETS",
              "EASYTEST LANCETS": "EASYTEST LANCETS",
              "PRECISION THIN LANCETS": "PRECISION THIN LANCETS",
              "PRECISION ULTRA LANCET": "PRECISION ULTRA LANCET",
              "THINLETS LANCET": "THINLETS LANCET",
              "W&F LANCETS 26G": "W&F LANCETS 26G",
              "W&F LANCETS COLORED 21G": "W&F LANCETS COLORED 21G"
            },
            "HEALTH ALLIANCE": {
              "ADJUSTABLE LANCING DEVICE": "ADJUSTABLE LANCING DEVICE",
              "LANCETS": "LANCETS"
            },
            "HOME AIDE DIAGNOSTICS": {
              "ACTIVE 1ST BLOOD LANCETS 30G": "ACTIVE 1ST BLOOD LANCETS 30G",
              "EASY COMFORT LANCETS": "EASY COMFORT LANCETS",
              "EASY COMFORT LANCETS TWIST TOP": "EASY COMFORT LANCETS TWIST TOP",
              "EASY MINI EJECT LANCING DEVICE": "EASY MINI EJECT LANCING DEVICE",
              "EASY MINI LANCING DEVICE": "EASY MINI LANCING DEVICE",
              "EASY PLUS BLOOD GLUCOSE SYSTEM": "EASY PLUS BLOOD GLUCOSE SYSTEM",
              "EASY PLUS II CONTROL": "EASY PLUS II CONTROL",
              "EASY PLUS II GLUCOSE SYSTEM": "EASY PLUS II GLUCOSE SYSTEM",
              "EASY STEP CONTROL": "EASY STEP CONTROL",
              "EASY STEP GLUCOSE MONITOR": "EASY STEP GLUCOSE MONITOR",
              "EASY TALK BLOOD GLUCOSE SYSTEM": "EASY TALK BLOOD GLUCOSE SYSTEM",
              "EASY TALK CONTROL": "EASY TALK CONTROL",
              "EASY TRAK BLOOD GLUCOSE SYSTEM": "EASY TRAK BLOOD GLUCOSE SYSTEM",
              "EASY TRAK CONTROL": "EASY TRAK CONTROL",
              "EASY TRAK II BLOOD GLUCOSE SYS": "EASY TRAK II BLOOD GLUCOSE SYS",
              "EASY TRAK II CONTROL": "EASY TRAK II CONTROL",
              "LANCETS 30G": "LANCETS 30G",
              "PRO COMFORT LANCETS 30G": "PRO COMFORT LANCETS 30G",
              "PRO COMFORT LANCETS 31G": "PRO COMFORT LANCETS 31G",
              "SAFETY LANCET 30G/PRESSURE ACT": "SAFETY LANCET 30G/PRESSURE ACT",
              "SAPS HEALTH TWIST TOP LANCETS": "SAPS HEALTH TWIST TOP LANCETS",
              "SAPS TWIST TOP LANCETS": "SAPS TWIST TOP LANCETS",
              "SAPSCARE TWIST TOP LANCETS": "SAPSCARE TWIST TOP LANCETS",
              "TRUE COMFORT TWIST TOP LANCETS": "TRUE COMFORT TWIST TOP LANCETS"
            },
            "HTL-STREFA": {
              "ACTI-LANCE LITE LANCETS 28G": "ACTI-LANCE LITE LANCETS 28G",
              "ACTI-LANCE SPECIAL LANCETS 17G": "ACTI-LANCE SPECIAL LANCETS 17G",
              "ACTI-LANCE UNIVERSAL 23G": "ACTI-LANCE UNIVERSAL 23G",
              "DROPLET LANCETS ULTRA THIN 30G": "DROPLET LANCETS ULTRA THIN 30G",
              "DROPLET LANCING DEVICE": "DROPLET LANCING DEVICE",
              "MEDLANCE PLUS EXTRA 21G": "MEDLANCE PLUS EXTRA 21G",
              "MEDLANCE PLUS LITE 25G": "MEDLANCE PLUS LITE 25G",
              "MEDLANCE PLUS SPECIAL 0.8MM": "MEDLANCE PLUS SPECIAL 0.8MM",
              "MEDLANCE PLUS SUPERLITE 30G": "MEDLANCE PLUS SUPERLITE 30G",
              "MEDLANCE PLUS UNIVERSAL 21G": "MEDLANCE PLUS UNIVERSAL 21G",
              "RELION LANCING DEVICE": "RELION LANCING DEVICE"
            },
            "HUDSON SCIENTIFIC": {
              "D-CARE GLUCOMETER": "D-CARE GLUCOMETER"
            },
            "HY-VEE": {
              "HY-VEE LANCETS": "HY-VEE LANCETS",
              "HY-VEE THIN LANCETS": "HY-VEE THIN LANCETS"
            },
            "I-SENS": {
              "CARESENS CONTROL A": "CARESENS CONTROL A",
              "CARESENS LANCETS": "CARESENS LANCETS",
              "CARESENS N GLUCOSE SYSTEM": "CARESENS N GLUCOSE SYSTEM",
              "CARESENS N VOICE SYSTEM": "CARESENS N VOICE SYSTEM",
              "COOL CONTROL A": "COOL CONTROL A",
              "COOL CONTROL B": "COOL CONTROL B",
              "COOL MONITOR": "COOL MONITOR",
              "COOL MONITOR KIT": "COOL MONITOR KIT"
            },
            "INFOPIA USA": {
              "ELEMENT AUTOCODE SYSTEM": "ELEMENT AUTOCODE SYSTEM",
              "ELEMENT COMPACT CONTROL 2": "ELEMENT COMPACT CONTROL 2",
              "ELEMENT COMPACT CONTROL 3": "ELEMENT COMPACT CONTROL 3",
              "ELEMENT COMPACT GLUCOSE SYSTEM": "ELEMENT COMPACT GLUCOSE SYSTEM",
              "ELEMENT COMPACT V GLUCOSE SYS": "ELEMENT COMPACT V GLUCOSE SYS",
              "ELEMENT CONTROL": "ELEMENT CONTROL",
              "ELEMENT PLUS": "ELEMENT PLUS",
              "EVOLUTION AUTOCODE": "EVOLUTION AUTOCODE",
              "EVOLUTION CONTROL": "EVOLUTION CONTROL",
              "LANCET DEVICE WITH EJECTOR": "LANCET DEVICE WITH EJECTOR",
              "LANCETS": "LANCETS",
              "SMART DIABETES VANTAGE LANCING": "SMART DIABETES VANTAGE LANCING"
            },
            "INFORMED DATA SYSTEMS": {
              "ONE DROP BLOOD GLUCOSE MONITOR": "ONE DROP BLOOD GLUCOSE MONITOR"
            },
            "INVACARE SUPPLY GROUP": {
              "LANCETS 30G": "LANCETS 30G",
              "LANCING DEVICE": "LANCING DEVICE"
            },
            "INVERNESS MEDICAL": {
              "KROGER LANCETS": "KROGER LANCETS",
              "KROGER LANCETS SUPER THIN": "KROGER LANCETS SUPER THIN",
              "KROGER LANCETS THIN": "KROGER LANCETS THIN"
            },
            "LEADER BRAND PRODUCTS": {
              "BLOOD GLUCOSE MONITOR SYSTEM": "BLOOD GLUCOSE MONITOR SYSTEM",
              "LDR BLOOD GLUCOSE TRUETEST": "LDR BLOOD GLUCOSE TRUETEST",
              "TRUETRACK BLOOD GLUCOSE": "TRUETRACK BLOOD GLUCOSE"
            },
            "LIBERTY MEDICAL SUPPLY": {
              "GLUCOSE CONTROL": "GLUCOSE CONTROL",
              "LIBERTY BLOOD GLUCOSE METER": "LIBERTY BLOOD GLUCOSE METER",
              "LIBERTY GLUCOSE CONTROL": "LIBERTY GLUCOSE CONTROL",
              "LIBERTY GLUCOSE CONTROL MID": "LIBERTY GLUCOSE CONTROL MID",
              "LIBERTY MINI LANCING DEVICE": "LIBERTY MINI LANCING DEVICE",
              "TRUE METRIX METER": "TRUE METRIX METER"
            },
            "LIFESCAN INC": {
              "IN TOUCH": "IN TOUCH",
              "LIFESCAN UNISTIK 2": "LIFESCAN UNISTIK 2",
              "LIFESCAN UNISTIK II LANCETS": "LIFESCAN UNISTIK II LANCETS",
              "ONETOUCH CLUB LANCETS FINE PT": "ONETOUCH CLUB LANCETS FINE PT",
              "ONETOUCH COMBO PACK": "ONETOUCH COMBO PACK",
              "ONETOUCH DELICA LANCETS 30G": "ONETOUCH DELICA LANCETS 30G",
              "ONETOUCH DELICA LANCETS 33G": "ONETOUCH DELICA LANCETS 33G",
              "ONETOUCH DELICA LANCING DEV": "ONETOUCH DELICA LANCING DEV",
              "ONETOUCH DELICA PLUS LANCET30G": "ONETOUCH DELICA PLUS LANCET30G",
              "ONETOUCH DELICA PLUS LANCET33G": "ONETOUCH DELICA PLUS LANCET33G",
              "ONETOUCH DELICA PLUS LANCING": "ONETOUCH DELICA PLUS LANCING",
              "ONETOUCH FINEPOINT LANCETS": "ONETOUCH FINEPOINT LANCETS",
              "ONETOUCH LANCETS": "ONETOUCH LANCETS",
              "ONETOUCH SURESOFT LANCING DEV": "ONETOUCH SURESOFT LANCING DEV",
              "ONETOUCH ULTRA 2": "ONETOUCH ULTRA 2",
              "ONETOUCH ULTRA CONTROL": "ONETOUCH ULTRA CONTROL",
              "ONETOUCH ULTRA MINI": "ONETOUCH ULTRA MINI",
              "ONETOUCH ULTRALINK": "ONETOUCH ULTRALINK",
              "ONETOUCH ULTRASOFT LANCETS": "ONETOUCH ULTRASOFT LANCETS",
              "ONETOUCH VERIO": "ONETOUCH VERIO",
              "ONETOUCH VERIO FLEX SYSTEM": "ONETOUCH VERIO FLEX SYSTEM",
              "ONETOUCH VERIO IQ SYSTEM": "ONETOUCH VERIO IQ SYSTEM",
              "ONETOUCH VERIO REFLECT": "ONETOUCH VERIO REFLECT",
              "ONETOUCH VERIO SYNC SYSTEM": "ONETOUCH VERIO SYNC SYSTEM",
              "PENLET II BLOOD SAMPLER": "PENLET II BLOOD SAMPLER",
              "PENLET II REPLACEMENT CAP": "PENLET II REPLACEMENT CAP",
              "SURESTEP GLUCOSE CONTROL": "SURESTEP GLUCOSE CONTROL",
              "SURESTEP PRO HIGH GLUCOSE": "SURESTEP PRO HIGH GLUCOSE",
              "SURESTEP PRO LINEARITY": "SURESTEP PRO LINEARITY",
              "SURESTEP PRO LOW GLUCOSE": "SURESTEP PRO LOW GLUCOSE",
              "SURESTEP PRO NORMAL GLUCOSE": "SURESTEP PRO NORMAL GLUCOSE"
            },
            "LINKS MEDICAL PRODUCTS": {
              "ACTI-LANCE 28G": "ACTI-LANCE 28G",
              "MEDLANCE PLUS LITE 25G": "MEDLANCE PLUS LITE 25G",
              "MEDLANCE PLUS SUPERLITE 30G": "MEDLANCE PLUS SUPERLITE 30G",
              "MEDLANCE PLUS UNIVERSAL 21G": "MEDLANCE PLUS UNIVERSAL 21G",
              "MICRODOT BLOOD GLUCOSE SYSTEM": "MICRODOT BLOOD GLUCOSE SYSTEM",
              "MICRODOT CONTROL HIGH/LOW": "MICRODOT CONTROL HIGH/LOW"
            },
            "LIVONGO HEALTH": {
              "IN TOUCH": "IN TOUCH",
              "IN TOUCH GLUCOSE CONTROL": "IN TOUCH GLUCOSE CONTROL",
              "IN TOUCH LANCING DEVICE": "IN TOUCH LANCING DEVICE",
              "IN TOUCH STERILE LANCETS 30G": "IN TOUCH STERILE LANCETS 30G"
            },
            "LOGIMEDIX": {
              "LANCETS": "LANCETS"
            },
            "MALGAM ENTERPRISES INC.": {
              "GLUCOSOURCE LANCET DEVICE": "GLUCOSOURCE LANCET DEVICE",
              "GLUCOSOURCE LANCETS": "GLUCOSOURCE LANCETS"
            },
            "MATRIX DISTRIBUTORS": {
              "NEXGEN CONTROL": "NEXGEN CONTROL",
              "NEXGEN METER": "NEXGEN METER"
            },
            "MCKESSON SUNMARK": {
              "SM TRUEDRAW LANCING DEVICE": "SM TRUEDRAW LANCING DEVICE"
            },
            "MEDICAL PLASTIC DEVICES": {
              "DRUG MART LANCING DEVICE": "DRUG MART LANCING DEVICE",
              "FINE 30": "FINE 30",
              "LANCETS THIN": "LANCETS THIN",
              "LANCETS ULTRA THIN": "LANCETS ULTRA THIN",
              "LANCING DEVICE": "LANCING DEVICE",
              "MEDLANCE EXTRA 21G": "MEDLANCE EXTRA 21G",
              "MEDLANCE LITE 25G": "MEDLANCE LITE 25G",
              "MEDLANCE PLUS LANCETS": "MEDLANCE PLUS LANCETS",
              "MEDLANCE UNIVERSAL 21G": "MEDLANCE UNIVERSAL 21G",
              "MPD SAFETY LANCET 21G": "MPD SAFETY LANCET 21G",
              "MPD SAFETY LANCET 23G": "MPD SAFETY LANCET 23G",
              "MPD SAFETY LANCET 28G": "MPD SAFETY LANCET 28G",
              "MPD SAFETY LANCET 30G": "MPD SAFETY LANCET 30G",
              "VITALET PRO LANCETS": "VITALET PRO LANCETS",
              "VITALET PRO PLUS LANCETS": "VITALET PRO PLUS LANCETS"
            },
            "MEDICINE SHOPPE": {
              "BLOOD GLUCOSE SYSTEM PAK": "BLOOD GLUCOSE SYSTEM PAK",
              "LANCETS": "LANCETS",
              "LANCETS THIN": "LANCETS THIN"
            },
            "MEDICORE": {
              "LITE TOUCH LANCETS": "LITE TOUCH LANCETS",
              "LITE TOUCH LANCING DEVICE": "LITE TOUCH LANCING DEVICE",
              "LITE TOUCH LANCING PEN": "LITE TOUCH LANCING PEN",
              "LITETOUCH LANCETS": "LITETOUCH LANCETS",
              "PIP LANCETS 28G": "PIP LANCETS 28G",
              "PIP LANCETS 30G": "PIP LANCETS 30G",
              "READYLANCE SAFETY LANCETS": "READYLANCE SAFETY LANCETS"
            },
            "MEDLINE INDUS": {
              "ADJUSTABLE LANCING DEVICE": "ADJUSTABLE LANCING DEVICE",
              "EVENCARE CONTROL LOW/HIGH": "EVENCARE CONTROL LOW/HIGH",
              "EVENCARE G2 LOW/HIGH CONTROL": "EVENCARE G2 LOW/HIGH CONTROL",
              "EVENCARE G2 MONITOR": "EVENCARE G2 MONITOR",
              "EVENCARE G3 LOW/HIGH CONTROL": "EVENCARE G3 LOW/HIGH CONTROL",
              "EVENCARE G3 MONITOR": "EVENCARE G3 MONITOR",
              "EVENCARE GLUCOSE MONITORING": "EVENCARE GLUCOSE MONITORING",
              "EVENCARE MINI CONTROL": "EVENCARE MINI CONTROL",
              "EVENCARE MINI MONITOR": "EVENCARE MINI MONITOR",
              "LANCETS 30G": "LANCETS 30G",
              "PRESSURE ACTIVAT SAFETY LANCET": "PRESSURE ACTIVAT SAFETY LANCET",
              "PUSH BUTTON SAFETY LANCETS": "PUSH BUTTON SAFETY LANCETS",
              "PUSH BUTTON SAFETY LANCETS 28G": "PUSH BUTTON SAFETY LANCETS 28G",
              "SAFETY LANCET 21G/PRESSURE ACT": "SAFETY LANCET 21G/PRESSURE ACT",
              "SAFETY LANCET 23G/PRESSURE ACT": "SAFETY LANCET 23G/PRESSURE ACT",
              "SAFETY LANCET 28G/PRESSURE ACT": "SAFETY LANCET 28G/PRESSURE ACT",
              "SAFETY LANCETS 21G": "SAFETY LANCETS 21G",
              "SAFETY LANCETS 28G": "SAFETY LANCETS 28G",
              "SIDE BUTTON SAFETY LANCET": "SIDE BUTTON SAFETY LANCET"
            },
            "MEDTRONIC MINIMED": {
              "BAYER CONTOUR LINK 2.4": "BAYER CONTOUR LINK 2.4",
              "CGMS REPLACEMENT CABLE": "CGMS REPLACEMENT CABLE",
              "CGMS SOFTWARE": "CGMS SOFTWARE",
              "ENLITE GLUCOSE SENSOR": "ENLITE GLUCOSE SENSOR",
              "GUARDIAN CONNECT TRANSMITTER": "GUARDIAN CONNECT TRANSMITTER",
              "GUARDIAN LINK 3 TRANSMITTER": "GUARDIAN LINK 3 TRANSMITTER",
              "GUARDIAN REAL-TIME CHARGER": "GUARDIAN REAL-TIME CHARGER",
              "GUARDIAN REAL-TIME REPLACE PED": "GUARDIAN REAL-TIME REPLACE PED",
              "GUARDIAN REAL-TIME REPLACEMENT": "GUARDIAN REAL-TIME REPLACEMENT",
              "GUARDIAN REAL-TIME STARTER": "GUARDIAN REAL-TIME STARTER",
              "GUARDIAN REAL-TIME SYSTEM": "GUARDIAN REAL-TIME SYSTEM",
              "GUARDIAN REAL-TIME SYSTEM PED": "GUARDIAN REAL-TIME SYSTEM PED",
              "GUARDIAN REAL-TIME TEST PLUG": "GUARDIAN REAL-TIME TEST PLUG",
              "GUARDIAN RT SOFTWARE": "GUARDIAN RT SOFTWARE",
              "GUARDIAN RT STARTER": "GUARDIAN RT STARTER",
              "GUARDIAN RT SYSTEM": "GUARDIAN RT SYSTEM",
              "GUARDIAN SENSOR (3)": "GUARDIAN SENSOR (3)",
              "GUARDIAN TRANSMITTER": "GUARDIAN TRANSMITTER",
              "MINIMED GUARDIAN SENSOR 3": "MINIMED GUARDIAN SENSOR 3",
              "OVAL TAPE": "OVAL TAPE",
              "PARADIGM LINK GLUCOSE MONITOR": "PARADIGM LINK GLUCOSE MONITOR",
              "PARADIGM REAL-TIME STARTER": "PARADIGM REAL-TIME STARTER",
              "SOF-SENSOR": "SOF-SENSOR",
              "TEST PLUG": "TEST PLUG",
              "TRANSMITTER TAPE": "TRANSMITTER TAPE"
            },
            "MEIJER": {
              "LANCETS": "LANCETS",
              "MEIJER LANCETS": "MEIJER LANCETS",
              "MEIJER LANCETS UNIVERSAL 21G": "MEIJER LANCETS UNIVERSAL 21G",
              "MEIJER LANCETS UNIVERSAL 30G": "MEIJER LANCETS UNIVERSAL 30G",
              "MEIJER LANCETS UNIVERSAL 33G": "MEIJER LANCETS UNIVERSAL 33G",
              "MEIJER TRUE2GO BLOOD GLUCOSE": "MEIJER TRUE2GO BLOOD GLUCOSE",
              "MEIJER TRUERESULT GLUCOSE SYS": "MEIJER TRUERESULT GLUCOSE SYS",
              "MEIJER TRUETRACK GLUCOSE SYS": "MEIJER TRUETRACK GLUCOSE SYS",
              "REALITY LANCETS": "REALITY LANCETS",
              "REALITY TRIGGER LANCETS": "REALITY TRIGGER LANCETS"
            },
            "MHC MEDICAL PRODUCTS": {
              "EASY TOUCH CONTROL HIGH & LOW": "EASY TOUCH CONTROL HIGH & LOW",
              "EASY TOUCH GLUCOSE SYSTEM": "EASY TOUCH GLUCOSE SYSTEM",
              "EASY TOUCH LANCETS 21G": "EASY TOUCH LANCETS 21G",
              "EASY TOUCH LANCETS 23G": "EASY TOUCH LANCETS 23G",
              "EASY TOUCH LANCETS 26G": "EASY TOUCH LANCETS 26G",
              "EASY TOUCH LANCETS 28G": "EASY TOUCH LANCETS 28G",
              "EASY TOUCH LANCETS 28G/TWIST": "EASY TOUCH LANCETS 28G/TWIST",
              "EASY TOUCH LANCETS 30G": "EASY TOUCH LANCETS 30G",
              "EASY TOUCH LANCETS 30G/TWIST": "EASY TOUCH LANCETS 30G/TWIST",
              "EASY TOUCH LANCETS 32G": "EASY TOUCH LANCETS 32G",
              "EASY TOUCH LANCETS 32G/TWIST": "EASY TOUCH LANCETS 32G/TWIST",
              "EASY TOUCH LANCETS 33G/TWIST": "EASY TOUCH LANCETS 33G/TWIST",
              "EASY TOUCH LANCING DEVICE": "EASY TOUCH LANCING DEVICE",
              "EASY TOUCH SAFETY LANCETS 21G": "EASY TOUCH SAFETY LANCETS 21G",
              "EASY TOUCH SAFETY LANCETS 23G": "EASY TOUCH SAFETY LANCETS 23G",
              "EASY TOUCH SAFETY LANCETS 26G": "EASY TOUCH SAFETY LANCETS 26G",
              "EASY TOUCH SAFETY LANCETS 28G": "EASY TOUCH SAFETY LANCETS 28G",
              "GNP EASY TOUCH CONT HIGH/LOW": "GNP EASY TOUCH CONT HIGH/LOW",
              "GNP EASY TOUCH GLUCOSE METER": "GNP EASY TOUCH GLUCOSE METER",
              "KROGER HEALTHPRO CONTROL HI/LO": "KROGER HEALTHPRO CONTROL HI/LO",
              "KROGER HEALTHPRO GLUC MON SYS": "KROGER HEALTHPRO GLUC MON SYS",
              "KROGER HEALTHPRO LANCET 26G": "KROGER HEALTHPRO LANCET 26G"
            },
            "NOVA BIOMEDICAL": {
              "NOVA MAX BLOOD GLUCOSE SYSTEM": "NOVA MAX BLOOD GLUCOSE SYSTEM",
              "NOVA MAX PLUS GLU/KET CONTROL": "NOVA MAX PLUS GLU/KET CONTROL",
              "NOVA SAFETY LANCETS 23G": "NOVA SAFETY LANCETS 23G",
              "NOVA SAFETY LANCETS 28G": "NOVA SAFETY LANCETS 28G",
              "NOVA SUREFLEX LANCETS": "NOVA SUREFLEX LANCETS",
              "NOVA SUREFLEX LANCING DEVICE": "NOVA SUREFLEX LANCING DEVICE"
            },
            "OMNIS HEALTH": {
              "EMBRACE BLOOD GLUCOSE MONITOR": "EMBRACE BLOOD GLUCOSE MONITOR",
              "EMBRACE CONTROL": "EMBRACE CONTROL",
              "EMBRACE EVO CONTROL LEVEL 1": "EMBRACE EVO CONTROL LEVEL 1",
              "EMBRACE EVO CONTROL LEVEL 2": "EMBRACE EVO CONTROL LEVEL 2",
              "EMBRACE EVO GLUCOSE MONITORING": "EMBRACE EVO GLUCOSE MONITORING",
              "EMBRACE GLUCOSE CONTROL": "EMBRACE GLUCOSE CONTROL",
              "EMBRACE LANCETS ULTRA THIN 30G": "EMBRACE LANCETS ULTRA THIN 30G",
              "EMBRACE LANCING DEVICE/EJECTOR": "EMBRACE LANCING DEVICE/EJECTOR",
              "EMBRACE PRO GLUCOSE CONTROL": "EMBRACE PRO GLUCOSE CONTROL",
              "EMBRACE PRO GLUCOSE METER": "EMBRACE PRO GLUCOSE METER",
              "EMBRACE TALK BLOOD GLUCOSE": "EMBRACE TALK BLOOD GLUCOSE",
              "EMBRACE TALK GLUCOSE CONTROL": "EMBRACE TALK GLUCOSE CONTROL",
              "EMBRACE TALK MONITORING SYSTEM": "EMBRACE TALK MONITORING SYSTEM",
              "HM EMBRACE TALK SYSTEM": "HM EMBRACE TALK SYSTEM",
              "HW EMBRACE PRO GLUCOSE METER": "HW EMBRACE PRO GLUCOSE METER",
              "HW EMBRACE TALK BLOOD GLUCOSE": "HW EMBRACE TALK BLOOD GLUCOSE",
              "VICTORY BLOOD GLUCOSE SYSTEM": "VICTORY BLOOD GLUCOSE SYSTEM",
              "VICTORY CONTROL LEVEL 1/2": "VICTORY CONTROL LEVEL 1/2"
            },
            "ONE PHARMA & MEDICAL SUPPLY CO": {
              "SURE-LANCE FLAT LANCETS": "SURE-LANCE FLAT LANCETS",
              "SURE-LANCE LANCETS 26G": "SURE-LANCE LANCETS 26G",
              "SURE-LANCE THIN LANCETS 28G": "SURE-LANCE THIN LANCETS 28G",
              "SURE-LANCE ULTRA THIN LANCETS": "SURE-LANCE ULTRA THIN LANCETS",
              "SURE-PEN": "SURE-PEN",
              "SURE-TEST EASYPLUS MINI METER": "SURE-TEST EASYPLUS MINI METER",
              "SURE-TOUCH LANCETS UNIVERSAL": "SURE-TOUCH LANCETS UNIVERSAL"
            },
            "OWEN MUMFORD": {
              "1ST CHOICE LANCETS SUPER THIN": "1ST CHOICE LANCETS SUPER THIN",
              "1ST CHOICE LANCETS THIN": "1ST CHOICE LANCETS THIN",
              "1ST CHOICE LANCETS ULTRA THIN": "1ST CHOICE LANCETS ULTRA THIN",
              "1ST TIER UNILET COMFORTOUCH": "1ST TIER UNILET COMFORTOUCH",
              "AURORA LANCET SUPER THIN 30G": "AURORA LANCET SUPER THIN 30G",
              "AURORA LANCET THIN 23G": "AURORA LANCET THIN 23G",
              "AUTOLET II CLINISAFE": "AUTOLET II CLINISAFE",
              "AUTOLET LANCING DEVICE": "AUTOLET LANCING DEVICE",
              "AUTOLET LITE CLINISAFE": "AUTOLET LITE CLINISAFE",
              "AUTOLET LITE STARTER PACK": "AUTOLET LITE STARTER PACK",
              "AUTOLET MINI": "AUTOLET MINI",
              "AUTOLET PLATFORMS": "AUTOLET PLATFORMS",
              "AUTOLET PLUS": "AUTOLET PLUS",
              "CAREONE ADVANCED LANCING DEV": "CAREONE ADVANCED LANCING DEV",
              "CAREONE LANCET THIN 23G": "CAREONE LANCET THIN 23G",
              "CAREONE LANCET ULTRA THIN 28G": "CAREONE LANCET ULTRA THIN 28G",
              "COMFORT LANCETS": "COMFORT LANCETS",
              "DRUG MART ON-THE-GO LANCET 30G": "DRUG MART ON-THE-GO LANCET 30G",
              "DRUG MART UNILET LANCETS 28G": "DRUG MART UNILET LANCETS 28G",
              "DRUG MART UNILET LANCETS 30G": "DRUG MART UNILET LANCETS 30G",
              "DRUG MART UNILET LANCETS 33G": "DRUG MART UNILET LANCETS 33G",
              "DUANE READE LANCET ALTERN SITE": "DUANE READE LANCET ALTERN SITE",
              "DUANE READE LANCET SUPER THIN": "DUANE READE LANCET SUPER THIN",
              "DUANE READE LANCET ULTRA THIN": "DUANE READE LANCET ULTRA THIN",
              "FREDS PHARMACY AUTOLET LANCING": "FREDS PHARMACY AUTOLET LANCING",
              "FREDS PHARMACY UNILET LANC 28G": "FREDS PHARMACY UNILET LANC 28G",
              "FREDS PHARMACY UNILET LANC 30G": "FREDS PHARMACY UNILET LANC 30G",
              "H-E-B INCONTROL ADV LANCING": "H-E-B INCONTROL ADV LANCING",
              "H-E-B INCONTROL LANCETS 28G": "H-E-B INCONTROL LANCETS 28G",
              "H-E-B INCONTROL LANCETS 30G": "H-E-B INCONTROL LANCETS 30G",
              "H-E-B INCONTROL LANCETS 33G": "H-E-B INCONTROL LANCETS 33G",
              "HEALTHY ACCENTS LANCING DEVICE": "HEALTHY ACCENTS LANCING DEVICE",
              "HEALTHY ACCENTS UNILET LANCETS": "HEALTHY ACCENTS UNILET LANCETS",
              "KINNEY LANCETS": "KINNEY LANCETS",
              "KINNEY THIN LANCETS": "KINNEY THIN LANCETS",
              "KROGER AUTOLET LANCING DEVICE": "KROGER AUTOLET LANCING DEVICE",
              "LANCETS": "LANCETS",
              "LEADER ADVANCED LANCING DEVICE": "LEADER ADVANCED LANCING DEVICE",
              "LIVE BETTER ADV LANCING DEVICE": "LIVE BETTER ADV LANCING DEVICE",
              "LIVE BETTER LANCET SUPER THIN": "LIVE BETTER LANCET SUPER THIN",
              "LIVE BETTER LANCET ULTRA THIN": "LIVE BETTER LANCET ULTRA THIN",
              "MEDICHOICE SAFETY LANCET": "MEDICHOICE SAFETY LANCET",
              "MEDICHOICE SAFETY LANCET EXTRA": "MEDICHOICE SAFETY LANCET EXTRA",
              "MEDICHOICE SAFETY LANCET NORM": "MEDICHOICE SAFETY LANCET NORM",
              "PC LANCETS SUPER THIN 30G": "PC LANCETS SUPER THIN 30G",
              "PX ADVANCED LANCING DEVICE": "PX ADVANCED LANCING DEVICE",
              "PX LANCETS ULTRA THIN": "PX LANCETS ULTRA THIN",
              "PX LANCETS ULTRA THIN 28G": "PX LANCETS ULTRA THIN 28G",
              "QC ADVANCED LANCING DEVICE": "QC ADVANCED LANCING DEVICE",
              "QC LANCETS SUPER THIN 30G": "QC LANCETS SUPER THIN 30G",
              "QC LANCETS ULTRA THIN": "QC LANCETS ULTRA THIN",
              "QC UNILET LANCETS 28G": "QC UNILET LANCETS 28G",
              "QC UNILET LANCETS MICRO THIN": "QC UNILET LANCETS MICRO THIN",
              "SAFETY LANCETS 21G": "SAFETY LANCETS 21G",
              "SHOPKO AUTOLET LANCING DEVICE": "SHOPKO AUTOLET LANCING DEVICE",
              "SHOPKO ON-THE-GO LANCETS 30G": "SHOPKO ON-THE-GO LANCETS 30G",
              "SHOPKO UNILET LANCETS 28G": "SHOPKO UNILET LANCETS 28G",
              "SHOPKO UNILET LANCETS 30G": "SHOPKO UNILET LANCETS 30G",
              "TGT ADVANCED LANCING DEVICE": "TGT ADVANCED LANCING DEVICE",
              "TGT LANCET ALTERNATE SITE": "TGT LANCET ALTERNATE SITE",
              "TGT LANCET SUPER THIN 30G": "TGT LANCET SUPER THIN 30G",
              "TGT LANCET THIN 23G": "TGT LANCET THIN 23G",
              "TGT LANCET ULTRA THIN 28G": "TGT LANCET ULTRA THIN 28G",
              "TGT LANCING DEVICE": "TGT LANCING DEVICE",
              "TODAYS HEALTH LANCING DEVICE": "TODAYS HEALTH LANCING DEVICE",
              "TODAYS HEALTH THIN LANCETS 28G": "TODAYS HEALTH THIN LANCETS 28G",
              "TODAYS HEALTH THIN LANCETS 30G": "TODAYS HEALTH THIN LANCETS 30G",
              "TRAVEL LANCETS": "TRAVEL LANCETS",
              "ULTRA THIN LANCETS 28G": "ULTRA THIN LANCETS 28G",
              "UNILET COMFORTOUCH LANCET": "UNILET COMFORTOUCH LANCET",
              "UNILET EXCELITE": "UNILET EXCELITE",
              "UNILET EXCELITE II": "UNILET EXCELITE II",
              "UNILET G.P. LANCET": "UNILET G.P. LANCET",
              "UNILET G.P. SUPERLITE LANCET": "UNILET G.P. SUPERLITE LANCET",
              "UNILET GP 28 ULTRA THIN": "UNILET GP 28 ULTRA THIN",
              "UNILET LANCET": "UNILET LANCET",
              "UNILET MICRO-THIN 33G": "UNILET MICRO-THIN 33G",
              "UNILET SUPER-THIN 30G": "UNILET SUPER-THIN 30G",
              "UNILET SUPERLITE LANCET": "UNILET SUPERLITE LANCET",
              "UNILET ULTRA-THIN 28G": "UNILET ULTRA-THIN 28G",
              "UNISTIK 1": "UNISTIK 1",
              "UNISTIK 2": "UNISTIK 2",
              "UNISTIK 2 COMFORT": "UNISTIK 2 COMFORT",
              "UNISTIK 2 EXTRA": "UNISTIK 2 EXTRA",
              "UNISTIK 2 NEONATAL": "UNISTIK 2 NEONATAL",
              "UNISTIK 2 NORMAL": "UNISTIK 2 NORMAL",
              "UNISTIK 2 SUPER": "UNISTIK 2 SUPER",
              "UNISTIK 3": "UNISTIK 3",
              "UNISTIK 3 COMFORT": "UNISTIK 3 COMFORT",
              "UNISTIK 3 EXTRA": "UNISTIK 3 EXTRA",
              "UNISTIK 3 GENTLE": "UNISTIK 3 GENTLE",
              "UNISTIK 3 NEONATAL": "UNISTIK 3 NEONATAL",
              "UNISTIK 3 NORMAL": "UNISTIK 3 NORMAL",
              "UNISTIK CZT COMFORT": "UNISTIK CZT COMFORT",
              "UNISTIK CZT NORMAL": "UNISTIK CZT NORMAL",
              "UNISTIK PRO SAFETY LANCET": "UNISTIK PRO SAFETY LANCET",
              "UNISTIK SAFETY LANCETS 28G": "UNISTIK SAFETY LANCETS 28G",
              "UNISTIK SAFETY LANCETS 30G": "UNISTIK SAFETY LANCETS 30G",
              "UNISTIK TOUCH SAFETY LANC 21G": "UNISTIK TOUCH SAFETY LANC 21G",
              "UNISTIK TOUCH SAFETY LANC 23G": "UNISTIK TOUCH SAFETY LANC 23G",
              "UNISTIK TOUCH SAFETY LANC 28G": "UNISTIK TOUCH SAFETY LANC 28G",
              "UNISTIK TOUCH SAFETY LANC 30G": "UNISTIK TOUCH SAFETY LANC 30G",
              "VALUMARK LANCET SUPER THIN 30G": "VALUMARK LANCET SUPER THIN 30G",
              "VALUMARK LANCET ULTRA THIN 28G": "VALUMARK LANCET ULTRA THIN 28G",
              "VIDA MIA AUTOLET LANCING DEV": "VIDA MIA AUTOLET LANCING DEV",
              "VIDA MIA UNILET LANCETS 28G": "VIDA MIA UNILET LANCETS 28G",
              "VIDA MIA UNILET LANCETS 30G": "VIDA MIA UNILET LANCETS 30G",
              "WALGREENS ADV TRAVEL LANCETS": "WALGREENS ADV TRAVEL LANCETS"
            },
            "PAR PHARMACEUTICAL": {
              "DIASTAR EASY TEST II LANCETS": "DIASTAR EASY TEST II LANCETS",
              "DIASTAR EASY TEST LANCETS": "DIASTAR EASY TEST LANCETS"
            },
            "PERRIGO DIABETES CARE": {
              "BLOOD GLUCOSE MONITOR SYSTEM": "BLOOD GLUCOSE MONITOR SYSTEM",
              "CAREONE BLOOD GLUCOSE SYSTEM": "CAREONE BLOOD GLUCOSE SYSTEM",
              "CVS BLOOD GLUCOSE METER": "CVS BLOOD GLUCOSE METER",
              "CVS LANCETS 21G": "CVS LANCETS 21G",
              "CVS LANCETS MICRO THIN 33G": "CVS LANCETS MICRO THIN 33G",
              "CVS LANCETS THIN 26G": "CVS LANCETS THIN 26G",
              "CVS LANCETS ULTRA THIN 30G": "CVS LANCETS ULTRA THIN 30G",
              "CVS LANCETS ULTRA-THIN 30G": "CVS LANCETS ULTRA-THIN 30G",
              "CVS LANCING DEVICE": "CVS LANCING DEVICE",
              "DRUG MART LANCETS THIN 26G": "DRUG MART LANCETS THIN 26G",
              "E-Z JECT LANCET MICRO-THIN 33G": "E-Z JECT LANCET MICRO-THIN 33G",
              "E-Z JECT LANCET SUPER THIN 30G": "E-Z JECT LANCET SUPER THIN 30G",
              "E-Z JECT LANCETS": "E-Z JECT LANCETS",
              "E-Z JECT LANCETS 21G": "E-Z JECT LANCETS 21G",
              "E-Z JECT LANCETS THIN 26G": "E-Z JECT LANCETS THIN 26G",
              "EQL COLOR LANCETS 21G": "EQL COLOR LANCETS 21G",
              "EQL COLOR LANCETS MICRO 33G": "EQL COLOR LANCETS MICRO 33G",
              "EQL SUPER THIN LANCETS 30G": "EQL SUPER THIN LANCETS 30G",
              "EQL THIN LANCETS 26G": "EQL THIN LANCETS 26G",
              "GNP LANCETS 21G": "GNP LANCETS 21G",
              "GNP LANCETS MICRO THIN 33G": "GNP LANCETS MICRO THIN 33G",
              "GNP LANCETS SUPER THIN 30G": "GNP LANCETS SUPER THIN 30G",
              "GNP LANCETS THIN 26G": "GNP LANCETS THIN 26G",
              "GOODSENSE BLOOD GLUCOSE": "GOODSENSE BLOOD GLUCOSE",
              "GOODSENSE COLOR LANCETS 33G": "GOODSENSE COLOR LANCETS 33G",
              "GOODSENSE LANCETS 26G UNIV": "GOODSENSE LANCETS 26G UNIV",
              "GOODSENSE LANCETS 30G": "GOODSENSE LANCETS 30G",
              "GOODSENSE LANCETS 30G UNIV": "GOODSENSE LANCETS 30G UNIV",
              "GOODSENSE LANCETS 33G": "GOODSENSE LANCETS 33G",
              "GOODSENSE LANCETS 33G UNIV": "GOODSENSE LANCETS 33G UNIV",
              "GOODSENSE LANCING DEVICE": "GOODSENSE LANCING DEVICE",
              "HEALTH CARE LANCING DEVICE": "HEALTH CARE LANCING DEVICE",
              "KROGER BLOOD GLUCOSE": "KROGER BLOOD GLUCOSE",
              "KROGER LANCETS 21G": "KROGER LANCETS 21G",
              "KROGER LANCETS MICRO THIN 33G": "KROGER LANCETS MICRO THIN 33G",
              "KROGER LANCETS THIN 26G": "KROGER LANCETS THIN 26G",
              "KROGER LANCETS ULTRATHIN 30G": "KROGER LANCETS ULTRATHIN 30G",
              "KROGER LANCING DEVICE": "KROGER LANCING DEVICE",
              "KROGER PREMIUM BLOOD GLUCOSE": "KROGER PREMIUM BLOOD GLUCOSE",
              "LONGS LANCETS STANDARD": "LONGS LANCETS STANDARD",
              "LONGS LANCETS THIN": "LONGS LANCETS THIN",
              "LONGS LANCETS ULTRA THIN": "LONGS LANCETS ULTRA THIN",
              "MEIJER BLOOD GLUCOSE": "MEIJER BLOOD GLUCOSE",
              "MEIJER ESSENTIAL BLOOD GLUCOSE": "MEIJER ESSENTIAL BLOOD GLUCOSE",
              "MEIJER LANCETS": "MEIJER LANCETS",
              "MEIJER LANCETS THIN": "MEIJER LANCETS THIN",
              "MEIJER LANCETS UNIVERSAL 33G": "MEIJER LANCETS UNIVERSAL 33G",
              "MEIJER PREMIUM BLOOD GLUCOSE": "MEIJER PREMIUM BLOOD GLUCOSE",
              "MEIJER SUPER THIN LANCETS": "MEIJER SUPER THIN LANCETS",
              "PHARMACY COUNTER LANCETS": "PHARMACY COUNTER LANCETS",
              "PREFERRED PLUS LANCETS COLORED": "PREFERRED PLUS LANCETS COLORED",
              "PREFERRED PLUS LANCETS THIN": "PREFERRED PLUS LANCETS THIN",
              "RA E-ZJECT LANCETS 28G": "RA E-ZJECT LANCETS 28G",
              "RA E-ZJECT LANCETS THIN 26G": "RA E-ZJECT LANCETS THIN 26G",
              "RA E-ZJECT LANCETS THIN 28G": "RA E-ZJECT LANCETS THIN 28G",
              "RA E-ZJECT LANCETS ULTRA THIN": "RA E-ZJECT LANCETS ULTRA THIN",
              "RELION LANCETS THIN 26G": "RELION LANCETS THIN 26G",
              "RELION LANCETS ULTRA-THIN 30G": "RELION LANCETS ULTRA-THIN 30G",
              "RELION LANCING DEVICE": "RELION LANCING DEVICE",
              "RELION ULTRA THIN LANCETS 30G": "RELION ULTRA THIN LANCETS 30G",
              "RELION ULTRA THIN PLUS LANCETS": "RELION ULTRA THIN PLUS LANCETS",
              "REXALL BLOOD GLUCOSE SYSTEM": "REXALL BLOOD GLUCOSE SYSTEM",
              "REXALL LANCETS ULTRA THIN 30G": "REXALL LANCETS ULTRA THIN 30G",
              "SM LANCETS 33G": "SM LANCETS 33G",
              "SMART SENSE COLOR LANCETS 33G": "SMART SENSE COLOR LANCETS 33G",
              "SMART SENSE PREMIUM SYSTEM": "SMART SENSE PREMIUM SYSTEM",
              "SMART SENSE STANDARD LANCETS": "SMART SENSE STANDARD LANCETS",
              "SMART SENSE SUPER THIN LANCETS": "SMART SENSE SUPER THIN LANCETS",
              "SMART SENSE THIN LANCETS 26G": "SMART SENSE THIN LANCETS 26G",
              "SMART SENSE VALUE GLUCOSE SYS": "SMART SENSE VALUE GLUCOSE SYS",
              "TGT BLOOD GLUCOSE MONITORING": "TGT BLOOD GLUCOSE MONITORING",
              "TGT LANCET MICRO THIN 33G": "TGT LANCET MICRO THIN 33G",
              "TGT LANCET THIN 26G": "TGT LANCET THIN 26G",
              "TGT LANCET ULTRA THIN 30G": "TGT LANCET ULTRA THIN 30G",
              "TGT LANCING DEVICE": "TGT LANCING DEVICE",
              "TOPCARE LANCETS MICRO-THIN 33G": "TOPCARE LANCETS MICRO-THIN 33G",
              "UNIVERSAL 1 LANCETS THIN 26G": "UNIVERSAL 1 LANCETS THIN 26G",
              "UNIVERSAL 1 LANCETS THIN 33G": "UNIVERSAL 1 LANCETS THIN 33G",
              "UNIVERSAL 1 LANCETS ULTRA THIN": "UNIVERSAL 1 LANCETS ULTRA THIN",
              "VALUE PLUS LANCET STANDARD 21G": "VALUE PLUS LANCET STANDARD 21G",
              "VALUE PLUS LANCETS SUPER THIN": "VALUE PLUS LANCETS SUPER THIN",
              "VALUE PLUS LANCETS THIN 26G": "VALUE PLUS LANCETS THIN 26G",
              "VALUE PLUS LANCING DEVICE": "VALUE PLUS LANCING DEVICE",
              "WALGREENS LANCETS": "WALGREENS LANCETS",
              "WALGREENS LANCETS MICRO THIN": "WALGREENS LANCETS MICRO THIN",
              "WALGREENS LANCETS SUPER THIN": "WALGREENS LANCETS SUPER THIN",
              "WALGREENS THIN LANCETS": "WALGREENS THIN LANCETS",
              "WALGREENS ULTRA THIN LANCETS": "WALGREENS ULTRA THIN LANCETS"
            },
            "PHILOSYS": {
              "GMATE CONTROL LEVEL 2": "GMATE CONTROL LEVEL 2",
              "GMATE LANCETS 30G": "GMATE LANCETS 30G",
              "GMATE LANCING DEVICE": "GMATE LANCING DEVICE",
              "GMATE ORIGIN BLOOD GLUCOSE SYS": "GMATE ORIGIN BLOOD GLUCOSE SYS",
              "GMATE SMART METER KIT": "GMATE SMART METER KIT",
              "GMATE SMART STARTER": "GMATE SMART STARTER",
              "GMATE VOICE BLOOD GLUCOSE SYS": "GMATE VOICE BLOOD GLUCOSE SYS"
            },
            "PHOENIX HEALTHCARE SOLUTIONS": {
              "ADJUSTABLE LANCING DEVICE": "ADJUSTABLE LANCING DEVICE",
              "AQUALANCE LANCETS 30G": "AQUALANCE LANCETS 30G",
              "ASSURE COMFORT LANCETS 28G": "ASSURE COMFORT LANCETS 28G"
            },
            "POLYMER TECHNOLOGY SYSTEMS": {
              "LANCETS": "LANCETS"
            },
            "PRODIGY DIABETES CARE": {
              "PRODIGY AUTOCODE BLOOD GLUCOSE": "PRODIGY AUTOCODE BLOOD GLUCOSE",
              "PRODIGY CONTROL SOLUTION": "PRODIGY CONTROL SOLUTION",
              "PRODIGY LANCETS 28G": "PRODIGY LANCETS 28G",
              "PRODIGY LANCING DEVICE": "PRODIGY LANCING DEVICE",
              "PRODIGY NO CODING BLOOD GLUC": "PRODIGY NO CODING BLOOD GLUC",
              "PRODIGY POCKET BLOOD GLUCOSE": "PRODIGY POCKET BLOOD GLUCOSE",
              "PRODIGY SAFETY LANCETS 26G": "PRODIGY SAFETY LANCETS 26G",
              "PRODIGY TWIST TOP LANCETS 28G": "PRODIGY TWIST TOP LANCETS 28G",
              "PRODIGY VOICE BLOOD GLUCOSE": "PRODIGY VOICE BLOOD GLUCOSE"
            },
            "PROGRESSIVE HEALTH SUPPLY": {
              "SMARTEST CONTROL MEDIUM": "SMARTEST CONTROL MEDIUM",
              "SMARTEST EJECT": "SMARTEST EJECT",
              "SMARTEST EJECT STARTER": "SMARTEST EJECT STARTER",
              "SMARTEST LANCETS 28G": "SMARTEST LANCETS 28G",
              "SMARTEST PERSONA STARTER": "SMARTEST PERSONA STARTER",
              "SMARTEST PRONTO STARTER": "SMARTEST PRONTO STARTER",
              "SMARTEST PROTEGE": "SMARTEST PROTEGE",
              "SMARTEST PROTEGE STARTER": "SMARTEST PROTEGE STARTER"
            },
            "PSS WORLD MEDICAL": {
              "QUINTET AC BLOOD GLUCOSE": "QUINTET AC BLOOD GLUCOSE",
              "QUINTET BLOOD GLUCOSE SYSTEM": "QUINTET BLOOD GLUCOSE SYSTEM",
              "QUINTET CONTROL HIGH/NORMAL": "QUINTET CONTROL HIGH/NORMAL"
            },
            "PUBLIX SUPER MARKETS INC.": {
              "PX LANCET AUTO INJECTOR": "PX LANCET AUTO INJECTOR"
            },
            "RELIAMED": {
              "ADJUSTABLE LANCING DEVICE": "ADJUSTABLE LANCING DEVICE",
              "LANCETS 28G": "LANCETS 28G",
              "LANCETS 30G": "LANCETS 30G",
              "LANCETS ULTRA FINE": "LANCETS ULTRA FINE",
              "LANCETS ULTRA THIN 30G": "LANCETS ULTRA THIN 30G",
              "LANCING DEVICE": "LANCING DEVICE",
              "MINI LANCING DEVICE": "MINI LANCING DEVICE",
              "SAFETY SEAL LANCETS": "SAFETY SEAL LANCETS"
            },
            "RITE AID CORPORATION": {
              "RA BLOOD GLUCOSE MONITOR": "RA BLOOD GLUCOSE MONITOR",
              "RA E-ZJECT COLOR LANCETS 33G": "RA E-ZJECT COLOR LANCETS 33G",
              "RA LANCING DEVICE": "RA LANCING DEVICE",
              "RA TRUE2GO BLOOD GLUCOSE": "RA TRUE2GO BLOOD GLUCOSE",
              "RA TRUERESULT BLOOD GLUCOSE": "RA TRUERESULT BLOOD GLUCOSE"
            },
            "ROCHE DIAGNOSTICS": {
              "ACCU-CHEK ACTIVE GLUCOSE CONT": "ACCU-CHEK ACTIVE GLUCOSE CONT",
              "ACCU-CHEK AVIVA": "ACCU-CHEK AVIVA",
              "ACCU-CHEK AVIVA CONNECT": "ACCU-CHEK AVIVA CONNECT",
              "ACCU-CHEK AVIVA PLUS": "ACCU-CHEK AVIVA PLUS",
              "ACCU-CHEK COMPACT PLUS CARE": "ACCU-CHEK COMPACT PLUS CARE",
              "ACCU-CHEK COMPACT PLUS CONTROL": "ACCU-CHEK COMPACT PLUS CONTROL",
              "ACCU-CHEK FASTCLIX LANCET": "ACCU-CHEK FASTCLIX LANCET",
              "ACCU-CHEK FASTCLIX LANCETS": "ACCU-CHEK FASTCLIX LANCETS",
              "ACCU-CHEK GUIDE": "ACCU-CHEK GUIDE",
              "ACCU-CHEK GUIDE CONTROL": "ACCU-CHEK GUIDE CONTROL",
              "ACCU-CHEK GUIDE ME": "ACCU-CHEK GUIDE ME",
              "ACCU-CHEK MULTICLIX LANCET DEV": "ACCU-CHEK MULTICLIX LANCET DEV",
              "ACCU-CHEK MULTICLIX LANCETS": "ACCU-CHEK MULTICLIX LANCETS",
              "ACCU-CHEK NANO SMARTVIEW": "ACCU-CHEK NANO SMARTVIEW",
              "ACCU-CHEK SAFE-T PRO LANCETS": "ACCU-CHEK SAFE-T PRO LANCETS",
              "ACCU-CHEK SMARTVIEW CONTROL": "ACCU-CHEK SMARTVIEW CONTROL",
              "ACCU-CHEK SOFT TOUCH LANCETS": "ACCU-CHEK SOFT TOUCH LANCETS",
              "ACCU-CHEK SOFTCLIX LANCET DEV": "ACCU-CHEK SOFTCLIX LANCET DEV",
              "ACCU-CHEK SOFTCLIX LANCETS": "ACCU-CHEK SOFTCLIX LANCETS",
              "ACCUTREND GLUCOSE CONTROL": "ACCUTREND GLUCOSE CONTROL",
              "CHEMSTRIP BG LOG BOOK": "CHEMSTRIP BG LOG BOOK",
              "COAGUCHEK LANCETS": "COAGUCHEK LANCETS",
              "TRACER II 3 VOLT BATTERY": "TRACER II 3 VOLT BATTERY"
            },
            "SAM'S WEST": {
              "MM EASY TOUCH GLUCOSE METER": "MM EASY TOUCH GLUCOSE METER",
              "MM LANCING DEVICE": "MM LANCING DEVICE",
              "MM TWIST LANCETS": "MM TWIST LANCETS"
            },
            "SANOFI-AVENTIS U.S.": {
              "IBG STAR BLOOD GLUCOSE SYSTEM": "IBG STAR BLOOD GLUCOSE SYSTEM"
            },
            "SD BIOSENSOR": {
              "GLUCONAVII BLOOD GLUCOSE SYS": "GLUCONAVII BLOOD GLUCOSE SYS"
            },
            "SELECT BRAND": {
              "SB LANCETS THIN": "SB LANCETS THIN",
              "SB LANCETS ULTRA THIN": "SB LANCETS ULTRA THIN"
            },
            "SENSEONICS": {
              "EVERSENSE SENSOR": "EVERSENSE SENSOR",
              "EVERSENSE SENSOR/HOLDER": "EVERSENSE SENSOR/HOLDER",
              "EVERSENSE SMART TRANSMITTER": "EVERSENSE SMART TRANSMITTER"
            },
            "SIEMENS HEALTHCARE DIAGNOSTICS": {
              "URIN-TEK": "URIN-TEK"
            },
            "SIMPLE DIAGNOSTICS": {
              "CLEVER CHEK AUTO-CODE SYSTEM": "CLEVER CHEK AUTO-CODE SYSTEM",
              "CLEVER CHEK AUTO-CODE VOICE": "CLEVER CHEK AUTO-CODE VOICE",
              "CLEVER CHEK LANCETS": "CLEVER CHEK LANCETS",
              "CLEVER CHEK SYSTEM": "CLEVER CHEK SYSTEM",
              "CLEVER CHOICE AUTO-CODE SYSTEM": "CLEVER CHOICE AUTO-CODE SYSTEM",
              "CLEVER CHOICE GLUCOSE CONTROL": "CLEVER CHOICE GLUCOSE CONTROL",
              "CLEVER CHOICE LANCETS 21G": "CLEVER CHOICE LANCETS 21G",
              "CLEVER CHOICE LANCETS 23G": "CLEVER CHOICE LANCETS 23G",
              "CLEVER CHOICE LANCETS 28G": "CLEVER CHOICE LANCETS 28G",
              "CLEVER CHOICE MICRO SYSTEM": "CLEVER CHOICE MICRO SYSTEM",
              "CLEVER CHOICE MINI SYSTEM": "CLEVER CHOICE MINI SYSTEM",
              "CLEVER CHOICE TALK SYSTEM": "CLEVER CHOICE TALK SYSTEM",
              "PHARMACIST CHOICE AUTOCODE SYS": "PHARMACIST CHOICE AUTOCODE SYS",
              "PHARMACIST CHOICE LANCETS": "PHARMACIST CHOICE LANCETS",
              "PHARMACIST CHOICE MINI SYSTEM": "PHARMACIST CHOICE MINI SYSTEM",
              "SIMPLE DIAGNOSTICS LANCING DEV": "SIMPLE DIAGNOSTICS LANCING DEV",
              "TAI DOC CONTROL": "TAI DOC CONTROL"
            },
            "SMART METER": {
              "IGLUCOSE MONITORING SYSTEM": "IGLUCOSE MONITORING SYSTEM"
            },
            "SPECIALTY MEDICAL SUPPLIES": {
              "ALTERNATE SITE LANCING DEVICE": "ALTERNATE SITE LANCING DEVICE",
              "AQUA LANCE ADJUSTABLE LANCING": "AQUA LANCE ADJUSTABLE LANCING",
              "ASSURE LANCETS": "ASSURE LANCETS",
              "BULLSEYE MINI SAFETY LANCETS": "BULLSEYE MINI SAFETY LANCETS",
              "BULLSEYE SAFETY LANCETS": "BULLSEYE SAFETY LANCETS",
              "EASY TWIST & CAP LANCETS": "EASY TWIST & CAP LANCETS",
              "LANCETS": "LANCETS",
              "LANCING DEVICE": "LANCING DEVICE",
              "SAFETY LET LANCETS": "SAFETY LET LANCETS",
              "SURE EDGE GLUCOSE MONITOR": "SURE EDGE GLUCOSE MONITOR",
              "SURECHEK BLOOD GLUCOSE MONITOR": "SURECHEK BLOOD GLUCOSE MONITOR",
              "VOCAL POINT BLOOD GLUCOSE SYS": "VOCAL POINT BLOOD GLUCOSE SYS"
            },
            "STERILANCE MEDICAL": {
              "STERILANCE PA": "STERILANCE PA",
              "STERILANCE TL": "STERILANCE TL"
            },
            "TARGET": {
              "TGT BLOOD GLUCOSE MONITORING": "TGT BLOOD GLUCOSE MONITORING"
            },
            "TELCARE": {
              "BIOTEL CARE BLOOD GLUCOSE SYST": "BIOTEL CARE BLOOD GLUCOSE SYST",
              "LANCETS ULTRA THIN 30G": "LANCETS ULTRA THIN 30G",
              "LANCING DEVICE": "LANCING DEVICE",
              "TELCARE BLOOD GLUCOSE SYSTEM": "TELCARE BLOOD GLUCOSE SYSTEM",
              "TELCARE GLUCOSE CONTROL": "TELCARE GLUCOSE CONTROL"
            },
            "TRIVIDIA HEALTH": {
              "LIBERTY BLOOD GLUCOSE MONITOR": "LIBERTY BLOOD GLUCOSE MONITOR",
              "LIBERTY GLUCOSE CONTROL": "LIBERTY GLUCOSE CONTROL",
              "RIGHTSOURCE BLOOD GLUCOSE": "RIGHTSOURCE BLOOD GLUCOSE",
              "SIDEKICK BLOOD GLUCOSE SYSTEM": "SIDEKICK BLOOD GLUCOSE SYSTEM",
              "TRUE METRIX AIR GLUCOSE METER": "TRUE METRIX AIR GLUCOSE METER",
              "TRUE METRIX GO GLUCOSE METER": "TRUE METRIX GO GLUCOSE METER",
              "TRUE METRIX LEVEL 1": "TRUE METRIX LEVEL 1",
              "TRUE METRIX LEVEL 2": "TRUE METRIX LEVEL 2",
              "TRUE METRIX LEVEL 3": "TRUE METRIX LEVEL 3",
              "TRUE METRIX METER": "TRUE METRIX METER",
              "TRUE2GO BLOOD GLUCOSE MONITOR": "TRUE2GO BLOOD GLUCOSE MONITOR",
              "TRUECONTROL GLUCOSE CONT LEV 0": "TRUECONTROL GLUCOSE CONT LEV 0",
              "TRUECONTROL GLUCOSE CONT LEV 1": "TRUECONTROL GLUCOSE CONT LEV 1",
              "TRUEDRAW LANCING DEVICE": "TRUEDRAW LANCING DEVICE",
              "TRUEPLUS LANCETS 26G": "TRUEPLUS LANCETS 26G",
              "TRUEPLUS LANCETS 28G": "TRUEPLUS LANCETS 28G",
              "TRUEPLUS LANCETS 30G": "TRUEPLUS LANCETS 30G",
              "TRUEPLUS LANCETS 33G": "TRUEPLUS LANCETS 33G",
              "TRUEPLUS SAFETY LANCETS 28G": "TRUEPLUS SAFETY LANCETS 28G",
              "TRUERESULT BLOOD GLUCOSE": "TRUERESULT BLOOD GLUCOSE",
              "TRUETEST CONTROL LEVEL 1": "TRUETEST CONTROL LEVEL 1",
              "TRUETEST CONTROL LEVEL 2": "TRUETEST CONTROL LEVEL 2",
              "TRUETEST CONTROL LEVEL 3": "TRUETEST CONTROL LEVEL 3",
              "TRUETRACK BLOOD GLUCOSE": "TRUETRACK BLOOD GLUCOSE"
            },
            "ULTIMED": {
              "ULTICARE THIN LANCETS 30G": "ULTICARE THIN LANCETS 30G"
            },
            "UNISTRIP TECHNOLOGIES": {
              "UNISTRIP CONTROL": "UNISTRIP CONTROL"
            },
            "US DIAGNOSTICS": {
              "ACURA BLOOD GLUCOSE METER": "ACURA BLOOD GLUCOSE METER",
              "ACURA BLOOD GLUCOSE STARTER": "ACURA BLOOD GLUCOSE STARTER",
              "ACURA CONTROL": "ACURA CONTROL",
              "ACURA PLUS BLOOD GLUCOSE SYS": "ACURA PLUS BLOOD GLUCOSE SYS",
              "CONTROL BLOOD GLUCOSE SYSTEM": "CONTROL BLOOD GLUCOSE SYSTEM",
              "EASYGLUCO": "EASYGLUCO",
              "EASYGLUCO CONTROL": "EASYGLUCO CONTROL",
              "EASYGLUCO PLUS": "EASYGLUCO PLUS",
              "EASYGLUCO PLUS LEVEL 1": "EASYGLUCO PLUS LEVEL 1",
              "EASYGLUCO PLUS STARTER": "EASYGLUCO PLUS STARTER",
              "INFINITY BLOOD GLUCOSE SYSTEM": "INFINITY BLOOD GLUCOSE SYSTEM",
              "INFINITY CONTROL": "INFINITY CONTROL",
              "INFINITY VOICE": "INFINITY VOICE",
              "LANCETS 28G": "LANCETS 28G",
              "LANCETS 30G": "LANCETS 30G",
              "LANCING DEVICE": "LANCING DEVICE",
              "MAXIMA BLOOD GLUCOSE SYSTEM": "MAXIMA BLOOD GLUCOSE SYSTEM",
              "MAXIMA CONTROL": "MAXIMA CONTROL",
              "MAXIMA METER": "MAXIMA METER",
              "MAXIMA STARTER": "MAXIMA STARTER",
              "SAFETY LANCETS": "SAFETY LANCETS",
              "SAFETY LANCETS 28G": "SAFETY LANCETS 28G",
              "VERASENS BLOOD GLUCOSE METER": "VERASENS BLOOD GLUCOSE METER",
              "VERASENS BLOOD GLUCOSE SYSTEM": "VERASENS BLOOD GLUCOSE SYSTEM",
              "VERASENS GLUCOSE CONTROL": "VERASENS GLUCOSE CONTROL"
            },
            "VERTEX DIAGNOSTICS": {
              "ULTRA TRAK PRO BLOOD GLUCOSE": "ULTRA TRAK PRO BLOOD GLUCOSE",
              "ULTRALANCE": "ULTRALANCE",
              "ULTRATRAK ACTIVE": "ULTRATRAK ACTIVE",
              "ULTRATRAK PRO": "ULTRATRAK PRO",
              "ULTRATRAK PRO CONTROL": "ULTRATRAK PRO CONTROL",
              "ULTRATRAK ULTIMATE CONTROL": "ULTRATRAK ULTIMATE CONTROL",
              "ULTRATRAK ULTIMATE MONITOR": "ULTRATRAK ULTIMATE MONITOR"
            },
            "VIP INTERNATIONAL": {
              "EZ SMART BLOOD GLUCOSE LANCETS": "EZ SMART BLOOD GLUCOSE LANCETS",
              "EZ SMART MONITORING SYSTEM": "EZ SMART MONITORING SYSTEM",
              "EZ SMART PLUS MONITORING SYS": "EZ SMART PLUS MONITORING SYS"
            },
            "WAL-MART": {
              "RELION ALL-IN-ONE": "RELION ALL-IN-ONE",
              "RELION CONFIRM GLUCOSE MONITOR": "RELION CONFIRM GLUCOSE MONITOR",
              "RELION LANCET DEVICES 30G": "RELION LANCET DEVICES 30G",
              "RELION LANCETS MICRO-THIN 33G": "RELION LANCETS MICRO-THIN 33G",
              "RELION LANCETS STANDARD 21G": "RELION LANCETS STANDARD 21G",
              "RELION LANCETS THIN 26G": "RELION LANCETS THIN 26G",
              "RELION LANCETS ULTRA-THIN 30G": "RELION LANCETS ULTRA-THIN 30G",
              "RELION LANCING DEVICE": "RELION LANCING DEVICE",
              "RELION MICRO": "RELION MICRO",
              "RELION PRIME MONITOR": "RELION PRIME MONITOR",
              "RELION ULTIMA GLUCOSE SYSTEM": "RELION ULTIMA GLUCOSE SYSTEM",
              "RELION ULTRA THIN LANCETS 30G": "RELION ULTRA THIN LANCETS 30G",
              "RELION ULTRA THIN PLUS LANCETS": "RELION ULTRA THIN PLUS LANCETS"
            },
            "WALGREENS": {
              "ADVANCED MOBILE LANCET": "ADVANCED MOBILE LANCET",
              "COMFORT ASSURED LANCETS 28G": "COMFORT ASSURED LANCETS 28G",
              "COMFORT ASSURED LANCETS 33G": "COMFORT ASSURED LANCETS 33G",
              "LANCETS MICRO THIN 33G": "LANCETS MICRO THIN 33G",
              "LANCETS SUPER THIN 28G": "LANCETS SUPER THIN 28G",
              "LANCETS ULTRA THIN 30G": "LANCETS ULTRA THIN 30G",
              "LANCING DEVICE": "LANCING DEVICE",
              "SUPER THIN LANCETS": "SUPER THIN LANCETS",
              "TRAVEL LANCETS ADVANCED 28G": "TRAVEL LANCETS ADVANCED 28G",
              "TRUE FOCUS BLOOD GLUCOSE METER": "TRUE FOCUS BLOOD GLUCOSE METER",
              "TRUE METRIX AIR GLUCOSE METER": "TRUE METRIX AIR GLUCOSE METER",
              "TRUE METRIX METER": "TRUE METRIX METER",
              "TRUEPLUS LANCETS 28G": "TRUEPLUS LANCETS 28G",
              "TRUEPLUS LANCETS 30G": "TRUEPLUS LANCETS 30G",
              "TRUEPLUS LANCETS 33G": "TRUEPLUS LANCETS 33G",
              "TRUERESULT BLOOD GLUCOSE": "TRUERESULT BLOOD GLUCOSE",
              "TRUETRACK BLOOD GLUCOSE": "TRUETRACK BLOOD GLUCOSE",
              "TRUETRACK SMART SYSTEM": "TRUETRACK SMART SYSTEM"
            },
            "WELLDOC": {
              "BLUESTAR": "BLUESTAR"
            },
            "XPRESS MEDICAL SUPPLY": {
              "ULTRA THIN LANCETS 31G": "ULTRA THIN LANCETS 31G"
            }
          },
          "Glucose/Ketone Monitoring Test Supplies": {
            "GOJJI": {
              "GOJJI MULTI-FUNCTIONAL SYSTEM": "GOJJI MULTI-FUNCTIONAL SYSTEM"
            },
            "POLYMER TECHNOLOGY SYSTEMS": {
              "CARDIO CHEK": "CARDIO CHEK",
              "CARDIO CHEK PA": "CARDIO CHEK PA"
            }
          },
          "Insulin Administration Supplies": {
            "AMBIMEDINC": {
              "AMBI-TRAY": "AMBI-TRAY",
              "EZ-VAC": "EZ-VAC",
              "INSUL-CAP": "INSUL-CAP",
              "INSUL-EZE": "INSUL-EZE",
              "LOAD-MATIC": "LOAD-MATIC"
            },
            "ANIMAS DIABETES": {
              "ANIMAS VIBE INSULIN PUMP": "ANIMAS VIBE INSULIN PUMP",
              "CARTRIDGE IR 1000 3ML": "CARTRIDGE IR 1000 3ML",
              "CARTRIDGE IR 1200": "CARTRIDGE IR 1200",
              "CARTRIDGE PUMP": "CARTRIDGE PUMP",
              "COMFORT INFUSION SET 23\"\"/17MM": "COMFORT INFUSION SET 23\"\"/17MM",
              "COMFORT INFUSION SET 31\"\"/17MM": "COMFORT INFUSION SET 31\"\"/17MM",
              "COMFORT INFUSION SET 43\"\"/17MM": "COMFORT INFUSION SET 43\"\"/17MM",
              "COMFORT SHORT INF SET 23\"\"/13MM": "COMFORT SHORT INF SET 23\"\"/13MM",
              "COMFORT SHORT INF SET 31\"\"/13MM": "COMFORT SHORT INF SET 31\"\"/13MM",
              "COMFORT SHORT INF SET 43\"\"/13MM": "COMFORT SHORT INF SET 43\"\"/13MM",
              "CONTACT DETACH INF SET 23\"\" 6MM": "CONTACT DETACH INF SET 23\"\" 6MM",
              "CONTACT DETACH INF SET 23\"\" 8MM": "CONTACT DETACH INF SET 23\"\" 8MM",
              "CONTACT DETACH INF SET 43\"\" 6MM": "CONTACT DETACH INF SET 43\"\" 6MM",
              "CONTACT DETACH INF SET 43\"\" 8MM": "CONTACT DETACH INF SET 43\"\" 8MM",
              "INSET 30 INFUSION SET 23\"\"": "INSET 30 INFUSION SET 23\"\"",
              "INSET 30 INFUSION SET 43\"\"": "INSET 30 INFUSION SET 43\"\"",
              "INSET INFUSION SET 23\"\" 6MM": "INSET INFUSION SET 23\"\" 6MM",
              "INSET INFUSION SET 23\"\" 9MM": "INSET INFUSION SET 23\"\" 9MM",
              "INSET INFUSION SET 43\"\" 6MM": "INSET INFUSION SET 43\"\" 6MM",
              "INSET INFUSION SET 43\"\" 9MM": "INSET INFUSION SET 43\"\" 9MM",
              "INSULIN PUMP IR2020": "INSULIN PUMP IR2020",
              "ONETOUCH PING INSULIN PUMP": "ONETOUCH PING INSULIN PUMP"
            },
            "FIFTY50 MEDICAL": {
              "INSULIN PUMP SYRINGE RESERVOIR": "INSULIN PUMP SYRINGE RESERVOIR"
            },
            "INSULET": {
              "OMNIPOD 10 PACK": "OMNIPOD 10 PACK",
              "OMNIPOD 5 PACK": "OMNIPOD 5 PACK",
              "OMNIPOD DASH 5 PACK PODS": "OMNIPOD DASH 5 PACK PODS",
              "OMNIPOD DASH SYSTEM": "OMNIPOD DASH SYSTEM",
              "OMNIPOD STARTER": "OMNIPOD STARTER"
            },
            "MEDTRONIC MINIMED": {
              "ENLITE SERTER": "ENLITE SERTER",
              "MINILINK REAL-TIME REPLACEMENT": "MINILINK REAL-TIME REPLACEMENT",
              "MINILINK REAL-TIME TRANSMITTER": "MINILINK REAL-TIME TRANSMITTER",
              "MINILINK-REAL-TIME STARTER": "MINILINK-REAL-TIME STARTER",
              "MINIMED 530G INSULIN PUMP": "MINIMED 530G INSULIN PUMP",
              "MINIMED 630G GUARDIAN PRESS": "MINIMED 630G GUARDIAN PRESS",
              "MINIMED 630G INSULIN PUMP": "MINIMED 630G INSULIN PUMP",
              "MINIMED 670G INSULIN PUMP": "MINIMED 670G INSULIN PUMP",
              "MINIMED 770G INSULIN PUMP SYS": "MINIMED 770G INSULIN PUMP SYS",
              "MINIMED GUARDIAN LINK 3": "MINIMED GUARDIAN LINK 3",
              "MINIMED MIO ADVANCE INFUSE SET": "MINIMED MIO ADVANCE INFUSE SET",
              "MINIMED MIO INFUSION SET": "MINIMED MIO INFUSION SET",
              "MINIMED PRO-SET INFUSION 24\"\"": "MINIMED PRO-SET INFUSION 24\"\"",
              "MINIMED PRO-SET INFUSION 42\"\"": "MINIMED PRO-SET INFUSION 42\"\"",
              "MINIMED PUMP RESERVOIR 3ML": "MINIMED PUMP RESERVOIR 3ML",
              "MINIMED QUICK SET INF SET 18\"\"": "MINIMED QUICK SET INF SET 18\"\"",
              "MINIMED QUICK SET INF SET 23\"\"": "MINIMED QUICK SET INF SET 23\"\"",
              "MINIMED QUICK SET INF SET 32\"\"": "MINIMED QUICK SET INF SET 32\"\"",
              "MINIMED QUICK SET INF SET 43\"\"": "MINIMED QUICK SET INF SET 43\"\"",
              "MINIMED QUICK-SERTER": "MINIMED QUICK-SERTER",
              "MINIMED RESERVOIR 1.8ML": "MINIMED RESERVOIR 1.8ML",
              "MINIMED RESERVOIR 3ML": "MINIMED RESERVOIR 3ML",
              "MINIMED SILHOUETTE INF SET 32\"\"": "MINIMED SILHOUETTE INF SET 32\"\"",
              "MINIMED SILHOUETTE INF SET 43\"\"": "MINIMED SILHOUETTE INF SET 43\"\"",
              "MIO INFUSION SET 18\"\" 6MM": "MIO INFUSION SET 18\"\" 6MM",
              "MIO INFUSION SET 23\"\" 6MM": "MIO INFUSION SET 23\"\" 6MM",
              "MIO INFUSION SET 32\"\" 6MM": "MIO INFUSION SET 32\"\" 6MM",
              "MIO INFUSION SET 32\"\" 9MM": "MIO INFUSION SET 32\"\" 9MM",
              "PARADIGM INSULIN PUMP": "PARADIGM INSULIN PUMP",
              "PARADIGM INSULIN PUMP PED": "PARADIGM INSULIN PUMP PED",
              "PARADIGM PATHWAY UPGRADE": "PARADIGM PATHWAY UPGRADE",
              "PARADIGM POLYFIN QR/WINGS 24\"\"": "PARADIGM POLYFIN QR/WINGS 24\"\"",
              "PARADIGM POLYFIN QR/WINGS 42\"\"": "PARADIGM POLYFIN QR/WINGS 42\"\"",
              "PARADIGM PUMP REMOTE PROGRAM": "PARADIGM PUMP REMOTE PROGRAM",
              "PARADIGM PUMP RESERVOIR 1.8ML": "PARADIGM PUMP RESERVOIR 1.8ML",
              "PARADIGM PUMP RESERVOIR 3ML": "PARADIGM PUMP RESERVOIR 3ML",
              "PARADIGM QUICK-SET 18\"\" 6MM": "PARADIGM QUICK-SET 18\"\" 6MM",
              "PARADIGM QUICK-SET 23\"\" 6MM": "PARADIGM QUICK-SET 23\"\" 6MM",
              "PARADIGM QUICK-SET 23\"\" 9MM": "PARADIGM QUICK-SET 23\"\" 9MM",
              "PARADIGM QUICK-SET 32\"\" 6MM": "PARADIGM QUICK-SET 32\"\" 6MM",
              "PARADIGM QUICK-SET 32\"\" 9MM": "PARADIGM QUICK-SET 32\"\" 9MM",
              "PARADIGM QUICK-SET 43\"\" 6MM": "PARADIGM QUICK-SET 43\"\" 6MM",
              "PARADIGM QUICK-SET 43\"\" 9MM": "PARADIGM QUICK-SET 43\"\" 9MM",
              "PARADIGM REAL-TIME TRANSMITTER": "PARADIGM REAL-TIME TRANSMITTER",
              "PARADIGM REVEL INSULIN PUMP": "PARADIGM REVEL INSULIN PUMP",
              "PARADIGM SILHOUETTE 18\"\" 13MM": "PARADIGM SILHOUETTE 18\"\" 13MM",
              "PARADIGM SILHOUETTE 32\"\" 13MM": "PARADIGM SILHOUETTE 32\"\" 13MM",
              "PARADIGM SILHOUETTE 32\"\" 17MM": "PARADIGM SILHOUETTE 32\"\" 17MM",
              "PARADIGM SILHOUETTE COMBO 23\"\"": "PARADIGM SILHOUETTE COMBO 23\"\"",
              "PARADIGM SILHOUETTE COMBO 43\"\"": "PARADIGM SILHOUETTE COMBO 43\"\"",
              "PARADIGM SILHOUETTE FULL 23\"\"": "PARADIGM SILHOUETTE FULL 23\"\"",
              "PARADIGM SILHOUETTE FULL 43\"\"": "PARADIGM SILHOUETTE FULL 43\"\"",
              "PARADIGM SOF-SET MICRO QR 24\"\"": "PARADIGM SOF-SET MICRO QR 24\"\"",
              "PARADIGM SOF-SET MICRO QR 42\"\"": "PARADIGM SOF-SET MICRO QR 42\"\"",
              "PARADIGM SOF-SET ULT QR 24\"\"": "PARADIGM SOF-SET ULT QR 24\"\"",
              "PARADIGM SOF-SET ULT QR 42\"\"": "PARADIGM SOF-SET ULT QR 42\"\"",
              "PARADIGM SURE-T 23\"\" 6MM": "PARADIGM SURE-T 23\"\" 6MM",
              "PARADIGM SURE-T 23\"\" 8MM": "PARADIGM SURE-T 23\"\" 8MM",
              "POLYFIN INFUSION SET 24\"\"": "POLYFIN INFUSION SET 24\"\"",
              "POLYFIN INFUSION SET 42\"\"": "POLYFIN INFUSION SET 42\"\"",
              "POLYFIN QR INFUSION SET 24\"\"": "POLYFIN QR INFUSION SET 24\"\"",
              "POLYFIN QR INFUSION SET 42\"\"": "POLYFIN QR INFUSION SET 42\"\"",
              "POLYFIN TUBING SET 60\"\"": "POLYFIN TUBING SET 60\"\"",
              "PUMP REMOTE PROGRAMMER": "PUMP REMOTE PROGRAMMER",
              "QUICK-SERTER INSERTION DEVICE": "QUICK-SERTER INSERTION DEVICE",
              "QUICK-SET INFUSION 23\"\" 6MM": "QUICK-SET INFUSION 23\"\" 6MM",
              "QUICK-SET INFUSION 23\"\" 9MM": "QUICK-SET INFUSION 23\"\" 9MM",
              "QUICK-SET INFUSION 43\"\" 6MM": "QUICK-SET INFUSION 43\"\" 6MM",
              "QUICK-SET INFUSION 43\"\" 9MM": "QUICK-SET INFUSION 43\"\" 9MM",
              "SEN-SERTER": "SEN-SERTER",
              "SIL-SERTER INSERTION DEVICE": "SIL-SERTER INSERTION DEVICE",
              "SILHOUETTE 13MM": "SILHOUETTE 13MM",
              "SILHOUETTE 17MM": "SILHOUETTE 17MM",
              "SILHOUETTE 23\"\" INFUSION SET": "SILHOUETTE 23\"\" INFUSION SET",
              "SILHOUETTE 43\"\" INFUSION SET": "SILHOUETTE 43\"\" INFUSION SET",
              "SILHOUETTE INFUSION SET 18\"\"": "SILHOUETTE INFUSION SET 18\"\"",
              "SILHOUETTE INFUSION SET 23\"\"": "SILHOUETTE INFUSION SET 23\"\"",
              "SILHOUETTE INFUSION SET 43\"\"": "SILHOUETTE INFUSION SET 43\"\"",
              "SOF-SERTER INSERTION DEVICE": "SOF-SERTER INSERTION DEVICE",
              "SOF-SET INFUSION SET 24\"\"": "SOF-SET INFUSION SET 24\"\"",
              "SOF-SET INFUSION SET 42\"\"": "SOF-SET INFUSION SET 42\"\"",
              "SOF-SET MICRO QR INFUSION 24\"\"": "SOF-SET MICRO QR INFUSION 24\"\"",
              "SOF-SET MICRO QR INFUSION 42\"\"": "SOF-SET MICRO QR INFUSION 42\"\"",
              "SOF-SET ULTIMATE QR 24\"\"": "SOF-SET ULTIMATE QR 24\"\"",
              "SOF-SET ULTIMATE QR 42\"\"": "SOF-SET ULTIMATE QR 42\"\"",
              "SURE T INFUSION SET 18\"\"/6MM": "SURE T INFUSION SET 18\"\"/6MM",
              "SURE T INFUSION SET 23\"\"/10MM": "SURE T INFUSION SET 23\"\"/10MM",
              "SURE T INFUSION SET 23\"\"/6MM": "SURE T INFUSION SET 23\"\"/6MM",
              "SURE T INFUSION SET 23\"\"/8MM": "SURE T INFUSION SET 23\"\"/8MM",
              "SURE T INFUSION SET 32\"\"/10MM": "SURE T INFUSION SET 32\"\"/10MM",
              "SURE T INFUSION SET 32\"\"/6MM": "SURE T INFUSION SET 32\"\"/6MM",
              "SURE T INFUSION SET 32\"\"/8MM": "SURE T INFUSION SET 32\"\"/8MM",
              "SURE-T INFUSION SET 18\"\"": "SURE-T INFUSION SET 18\"\"",
              "SURE-T INFUSION SET 23\"\"": "SURE-T INFUSION SET 23\"\"",
              "SURE-T INFUSION SET 32\"\"": "SURE-T INFUSION SET 32\"\"",
              "SURE-T INFUSION SET 6MM": "SURE-T INFUSION SET 6MM",
              "SURE-T INFUSION SET 8MM": "SURE-T INFUSION SET 8MM"
            },
            "MHC MEDICAL PRODUCTS": {
              "EASY TOUCH INSULIN BARRELS 1ML": "EASY TOUCH INSULIN BARRELS 1ML"
            },
            "NIPRO DIAGNOSTICS": {
              "AMIGO INSULIN PUMP": "AMIGO INSULIN PUMP",
              "GLUCOPRO SYR RES 3ML 22GX3/8\"\"": "GLUCOPRO SYR RES 3ML 22GX3/8\"\""
            },
            "PRODIGY DIABETES CARE": {
              "PRODIGY COUNT-A-DOSE": "PRODIGY COUNT-A-DOSE"
            },
            "ROCHE INSULIN DELIVERY SYSTEMS": {
              "ACCU-CHEK CLIP FOR PUMP SKIN": "ACCU-CHEK CLIP FOR PUMP SKIN",
              "ACCU-CHEK COMBO": "ACCU-CHEK COMBO",
              "ACCU-CHEK FLEXLINK PLUS 10MM": "ACCU-CHEK FLEXLINK PLUS 10MM",
              "ACCU-CHEK FLEXLINK PLUS 6MM": "ACCU-CHEK FLEXLINK PLUS 6MM",
              "ACCU-CHEK FLEXLINK PLUS 8MM": "ACCU-CHEK FLEXLINK PLUS 8MM",
              "ACCU-CHEK LINKASSIST": "ACCU-CHEK LINKASSIST",
              "ACCU-CHEK PLASTIC CARTRIDGE": "ACCU-CHEK PLASTIC CARTRIDGE",
              "ACCU-CHEK RAPID-D INFUSION SET": "ACCU-CHEK RAPID-D INFUSION SET",
              "ACCU-CHEK RAPID-D LINK": "ACCU-CHEK RAPID-D LINK",
              "ACCU-CHEK SPIRIT ADAPTER KIT": "ACCU-CHEK SPIRIT ADAPTER KIT",
              "ACCU-CHEK SPIRIT BATTERY KIT": "ACCU-CHEK SPIRIT BATTERY KIT",
              "ACCU-CHEK SPIRIT CARTRIDGE": "ACCU-CHEK SPIRIT CARTRIDGE",
              "ACCU-CHEK SPIRIT COMBO PACK": "ACCU-CHEK SPIRIT COMBO PACK",
              "ACCU-CHEK SPIRIT INSULIN PUMP": "ACCU-CHEK SPIRIT INSULIN PUMP",
              "ACCU-CHEK TENDER I SET 24\"\"": "ACCU-CHEK TENDER I SET 24\"\"",
              "ACCU-CHEK TENDER I SET 31\"\"": "ACCU-CHEK TENDER I SET 31\"\"",
              "ACCU-CHEK TENDER I SET 43\"\"": "ACCU-CHEK TENDER I SET 43\"\"",
              "ACCU-CHEK TENDER II SET 24\"\"": "ACCU-CHEK TENDER II SET 24\"\"",
              "ACCU-CHEK TENDER II SET 31\"\"": "ACCU-CHEK TENDER II SET 31\"\"",
              "ACCU-CHEK TENDER II SET 43\"\"": "ACCU-CHEK TENDER II SET 43\"\"",
              "ACCU-CHEK ULTRAFLEX II INF SET": "ACCU-CHEK ULTRAFLEX II INF SET",
              "ACCU-CHEK ULTRAFLEX INF SET": "ACCU-CHEK ULTRAFLEX INF SET",
              "ACCU-CHEK ULTRAFLEX-1 INF SET": "ACCU-CHEK ULTRAFLEX-1 INF SET",
              "D-TRON ADAPTER": "D-TRON ADAPTER",
              "D-TRON FILLING SET": "D-TRON FILLING SET",
              "D-TRON GLASS CARTRIDGE": "D-TRON GLASS CARTRIDGE",
              "D-TRON PLUS": "D-TRON PLUS",
              "D-TRON POWER PACK": "D-TRON POWER PACK",
              "H-TRON PLUS INSULIN PUMP": "H-TRON PLUS INSULIN PUMP",
              "H-TRON V100 INSULIN PUMP": "H-TRON V100 INSULIN PUMP",
              "H-TRONPLUS POWER PACK": "H-TRONPLUS POWER PACK",
              "INSULIN PUMP RECORD BOOK": "INSULIN PUMP RECORD BOOK",
              "PLASTIC CARTRIDGE 3 ML": "PLASTIC CARTRIDGE 3 ML",
              "PUMPING INSULIN BOOK VOLUME 2": "PUMPING INSULIN BOOK VOLUME 2",
              "RAPID-D INFUSION SET 24\"\"/10MM": "RAPID-D INFUSION SET 24\"\"/10MM",
              "RAPID-D INFUSION SET 24\"\"/6MM": "RAPID-D INFUSION SET 24\"\"/6MM",
              "RAPID-D INFUSION SET 24\"\"/8MM": "RAPID-D INFUSION SET 24\"\"/8MM",
              "RAPID-D INFUSION SET 31\"\"/10MM": "RAPID-D INFUSION SET 31\"\"/10MM",
              "RAPID-D INFUSION SET 31\"\"/6MM": "RAPID-D INFUSION SET 31\"\"/6MM",
              "RAPID-D INFUSION SET 31\"\"/8MM": "RAPID-D INFUSION SET 31\"\"/8MM",
              "RAPID-D INFUSION SET 43\"\"/10MM": "RAPID-D INFUSION SET 43\"\"/10MM",
              "RAPID-D INFUSION SET 43\"\"/6MM": "RAPID-D INFUSION SET 43\"\"/6MM",
              "RAPID-D INFUSION SET 43\"\"/8MM": "RAPID-D INFUSION SET 43\"\"/8MM"
            },
            "SMITHS MEDICAL MD, INC": {
              "COMFORT SHORT INF SET 23\"\"/13MM": "COMFORT SHORT INF SET 23\"\"/13MM",
              "COMFORT SHORT INF SET 31\"\"/13MM": "COMFORT SHORT INF SET 31\"\"/13MM",
              "COMFORT SHORT INF SET 43\"\"/13MM": "COMFORT SHORT INF SET 43\"\"/13MM",
              "DELTEC COZMO CLEO SET 24\"\" 6MM": "DELTEC COZMO CLEO SET 24\"\" 6MM",
              "DELTEC COZMO CLEO SET 24\"\" 9MM": "DELTEC COZMO CLEO SET 24\"\" 9MM",
              "DELTEC COZMO CLEO SET 31\"\" 6MM": "DELTEC COZMO CLEO SET 31\"\" 6MM",
              "DELTEC COZMO CLEO SET 31\"\" 9MM": "DELTEC COZMO CLEO SET 31\"\" 9MM",
              "DELTEC COZMO CLEO SET 42\"\" 6MM": "DELTEC COZMO CLEO SET 42\"\" 6MM",
              "DELTEC COZMO CLEO SET 42\"\" 9MM": "DELTEC COZMO CLEO SET 42\"\" 9MM",
              "DELTEC COZMO INSULIN PUMP": "DELTEC COZMO INSULIN PUMP",
              "INFUSION CATHETER SOFT 23\"\"": "INFUSION CATHETER SOFT 23\"\"",
              "INFUSION CATHETER SOFT 31\"\"": "INFUSION CATHETER SOFT 31\"\"",
              "INFUSION CATHETER SOFT 43\"\"": "INFUSION CATHETER SOFT 43\"\"",
              "INSULIN CARTRIDGE 3ML": "INSULIN CARTRIDGE 3ML"
            },
            "TANDEM DIABETES CARE": {
              "AUTOSOFT 30 INFUSION SET": "AUTOSOFT 30 INFUSION SET",
              "AUTOSOFT 90 INFUSION SET": "AUTOSOFT 90 INFUSION SET",
              "AUTOSOFT XC INFUSION SET": "AUTOSOFT XC INFUSION SET",
              "CLEO 90 INFUSION SET 24\"\"/6MM": "CLEO 90 INFUSION SET 24\"\"/6MM",
              "CLEO 90 INFUSION SET 24\"\"/9MM": "CLEO 90 INFUSION SET 24\"\"/9MM",
              "CLEO 90 INFUSION SET 31\"\"/6MM": "CLEO 90 INFUSION SET 31\"\"/6MM",
              "CLEO 90 INFUSION SET 31\"\"/9MM": "CLEO 90 INFUSION SET 31\"\"/9MM",
              "COMFORT INFUSION SET 23\"\"/17MM": "COMFORT INFUSION SET 23\"\"/17MM",
              "COMFORT INFUSION SET 32\"\"/17MM": "COMFORT INFUSION SET 32\"\"/17MM",
              "COMFORT INFUSION SET 43\"\"/17MM": "COMFORT INFUSION SET 43\"\"/17MM",
              "COMFORT SHORT INF SET 23\"\"/13MM": "COMFORT SHORT INF SET 23\"\"/13MM",
              "COMFORT SHORT INF SET 32\"\"/13MM": "COMFORT SHORT INF SET 32\"\"/13MM",
              "COMFORT SHORT INF SET 43\"\"/13MM": "COMFORT SHORT INF SET 43\"\"/13MM",
              "CONTACT DETACH INF SET 23\"\"/6MM": "CONTACT DETACH INF SET 23\"\"/6MM",
              "CONTACT DETACH INF SET 23\"\"/8MM": "CONTACT DETACH INF SET 23\"\"/8MM",
              "CONTACT DETACH INF SET 32\"\"/6MM": "CONTACT DETACH INF SET 32\"\"/6MM",
              "CONTACT DETACH INF SET 32\"\"/8MM": "CONTACT DETACH INF SET 32\"\"/8MM",
              "T: SLIM X2 INS PMP/CONTROL 7.4": "T: SLIM X2 INS PMP/CONTROL 7.4",
              "T:30 INFUSION SET": "T:30 INFUSION SET",
              "T:90 INFUSION SET": "T:90 INFUSION SET",
              "T:FLEX INSULIN CARTRIDGE 4.8ML": "T:FLEX INSULIN CARTRIDGE 4.8ML",
              "T:FLEX INSULIN PUMP": "T:FLEX INSULIN PUMP",
              "T:FLEX T:LOCK CARTRIDGE 4.8ML": "T:FLEX T:LOCK CARTRIDGE 4.8ML",
              "T:SLIM G4 INSULIN CARTRIDGE": "T:SLIM G4 INSULIN CARTRIDGE",
              "T:SLIM G4 INSULIN PUMP": "T:SLIM G4 INSULIN PUMP",
              "T:SLIM INSULIN CARTRIDGE 3ML": "T:SLIM INSULIN CARTRIDGE 3ML",
              "T:SLIM INSULIN DELIVERY SYSTEM": "T:SLIM INSULIN DELIVERY SYSTEM",
              "T:SLIM INSULIN PUMP": "T:SLIM INSULIN PUMP",
              "T:SLIM T:LOCK INSULIN CART 3ML": "T:SLIM T:LOCK INSULIN CART 3ML",
              "T:SLIM X2": "T:SLIM X2",
              "T:SLIM X2 3ML CARTRIDGE": "T:SLIM X2 3ML CARTRIDGE",
              "T:SLIM X2 INS PUMP/CONTROL-IQ": "T:SLIM X2 INS PUMP/CONTROL-IQ",
              "T:SLIM X2 INSULIN PMP BASAL IQ": "T:SLIM X2 INSULIN PMP BASAL IQ",
              "T:SLIM X2 INSULIN PMP BASAL6.4": "T:SLIM X2 INSULIN PMP BASAL6.4",
              "T:SLIM X2 INSULIN PMP/RFURB IQ": "T:SLIM X2 INSULIN PMP/RFURB IQ",
              "T:SLIM X2 INSULIN PUMP": "T:SLIM X2 INSULIN PUMP",
              "T:SLIM X2 INSULIN PUMP/REFURB": "T:SLIM X2 INSULIN PUMP/REFURB",
              "T:SLIM X2/BASAL-IQ/ACC/INSTR": "T:SLIM X2/BASAL-IQ/ACC/INSTR",
              "T:SLIM X2/CONTROL-IQ/ACC/INSTR": "T:SLIM X2/CONTROL-IQ/ACC/INSTR",
              "T:SLIM X2/CONTROL-IQ/REFURB": "T:SLIM X2/CONTROL-IQ/REFURB",
              "TRUSTEEL INFUSION SET": "TRUSTEEL INFUSION SET",
              "VARISOFT INFUSION SET": "VARISOFT INFUSION SET"
            },
            "ZEALAND PHARMA": {
              "V-GO 20": "V-GO 20",
              "V-GO 30": "V-GO 30",
              "V-GO 40": "V-GO 40"
            }
          },
          "Ketone Monitoring Test Supplies": {
            "GOJJI": {
              "GOJJI CONTROL LEVEL 1": "GOJJI CONTROL LEVEL 1"
            }
          }
        },
        "Elastic Bandages & Supports": {
          "Elastic Bandages & Supports": {
            "21ST CENTURY HEALTHCARE": {
              "EVERSHEER STOCKINGS": "EVERSHEER STOCKINGS"
            },
            "3M CONSUMER HEALTH CARE": {
              "ACE ANKLE BRACE W/STABILIZERS": "ACE ANKLE BRACE W/STABILIZERS",
              "ACE ARM SLING": "ACE ARM SLING",
              "ACE BANDAGE SELF-ADHERING": "ACE BANDAGE SELF-ADHERING",
              "ACE CONTOURED BACK SUPPORT": "ACE CONTOURED BACK SUPPORT",
              "ACE DELUXE WRIST STABILIZER": "ACE DELUXE WRIST STABILIZER",
              "ACE ELASTIC BANDAGE 4\"\"": "ACE ELASTIC BANDAGE 4\"\"",
              "ACE ELASTIC BANDAGE/CLIPS": "ACE ELASTIC BANDAGE/CLIPS",
              "ACE ELBOW SUPPORT MEDIUM": "ACE ELBOW SUPPORT MEDIUM",
              "ACE KNEE BRACE HINGED": "ACE KNEE BRACE HINGED",
              "ACE KNEE BRACE W/STABILIZERS": "ACE KNEE BRACE W/STABILIZERS",
              "ACE KNEE SUPPORT ADJUSTABLE": "ACE KNEE SUPPORT ADJUSTABLE",
              "ACE KNEE SUPPORT KNITTED": "ACE KNEE SUPPORT KNITTED",
              "ACE KNEE SUPPORT KNITTED XL": "ACE KNEE SUPPORT KNITTED XL",
              "ACE NEOPRENE ELBOW SUPPORT": "ACE NEOPRENE ELBOW SUPPORT",
              "ACE TENNIS ELBOW STRAP": "ACE TENNIS ELBOW STRAP",
              "ACE TENNIS ELBOW SUPPORT": "ACE TENNIS ELBOW SUPPORT",
              "ACE THUMB STABILIZER LEFT/RGHT": "ACE THUMB STABILIZER LEFT/RGHT",
              "ACE WRIST BRACE/SPLINT": "ACE WRIST BRACE/SPLINT",
              "ACE WRIST SLEEP SUPPORT/NIGHT": "ACE WRIST SLEEP SUPPORT/NIGHT",
              "ACE WRIST STABILIZER DELUXE": "ACE WRIST STABILIZER DELUXE",
              "ACE WRIST STABILIZER/DIAL SYS": "ACE WRIST STABILIZER/DIAL SYS",
              "COBAN ACTION WRAP": "COBAN ACTION WRAP",
              "FUTURO ABDOMINAL SUPPORT": "FUTURO ABDOMINAL SUPPORT",
              "FUTURO ADJUST ANKLE SUPPORT": "FUTURO ADJUST ANKLE SUPPORT",
              "FUTURO ADJUST ELBOW SUPPORT": "FUTURO ADJUST ELBOW SUPPORT",
              "FUTURO ADJUST WRIST SUPPORT": "FUTURO ADJUST WRIST SUPPORT",
              "FUTURO ANKLE BRACE": "FUTURO ANKLE BRACE",
              "FUTURO ANKLE SUPPORT": "FUTURO ANKLE SUPPORT",
              "FUTURO ANTI-EMBOLISM STOCKINGS": "FUTURO ANTI-EMBOLISM STOCKINGS",
              "FUTURO ATHLETIC SOCKS": "FUTURO ATHLETIC SOCKS",
              "FUTURO COMFORT LIFT ANKLE SUPP": "FUTURO COMFORT LIFT ANKLE SUPP",
              "FUTURO CUP SUPPORTER": "FUTURO CUP SUPPORTER",
              "FUTURO DELUXE ANKLE STABILIZER": "FUTURO DELUXE ANKLE STABILIZER",
              "FUTURO DELUXE WRIST STABILIZER": "FUTURO DELUXE WRIST STABILIZER",
              "FUTURO DRESS SOCKS FOR MEN": "FUTURO DRESS SOCKS FOR MEN",
              "FUTURO ELASTIC BANDAGE": "FUTURO ELASTIC BANDAGE",
              "FUTURO ELBOW BRACE": "FUTURO ELBOW BRACE",
              "FUTURO ELBOW SUPPORT": "FUTURO ELBOW SUPPORT",
              "FUTURO ENERGIZ TROUSER SOCK LG": "FUTURO ENERGIZ TROUSER SOCK LG",
              "FUTURO ENERGIZ TROUSER SOCK M": "FUTURO ENERGIZ TROUSER SOCK M",
              "FUTURO ENERGIZING KNEE HIGH SM": "FUTURO ENERGIZING KNEE HIGH SM",
              "FUTURO FEMALE RIB BELT": "FUTURO FEMALE RIB BELT",
              "FUTURO FIRM COMPRESSION HOSE": "FUTURO FIRM COMPRESSION HOSE",
              "FUTURO HERNIA AID": "FUTURO HERNIA AID",
              "FUTURO KNEE BRACE": "FUTURO KNEE BRACE",
              "FUTURO KNEE HI SHEER HOSE": "FUTURO KNEE HI SHEER HOSE",
              "FUTURO KNEE HIGHS": "FUTURO KNEE HIGHS",
              "FUTURO KNEE STABILIZER": "FUTURO KNEE STABILIZER",
              "FUTURO KNEE SUPPORT ADJUSTABLE": "FUTURO KNEE SUPPORT ADJUSTABLE",
              "FUTURO KNEE SUPPORT LARGE": "FUTURO KNEE SUPPORT LARGE",
              "FUTURO KNEE SUPPORT MEDIUM": "FUTURO KNEE SUPPORT MEDIUM",
              "FUTURO KNEE SUPPORT SMALL": "FUTURO KNEE SUPPORT SMALL",
              "FUTURO LACE-UP ANKLE BRACE": "FUTURO LACE-UP ANKLE BRACE",
              "FUTURO LEFT HAND WRIST BRACE": "FUTURO LEFT HAND WRIST BRACE",
              "FUTURO MENS DRESS SOCKS": "FUTURO MENS DRESS SOCKS",
              "FUTURO MENS SUPER SOX": "FUTURO MENS SUPER SOX",
              "FUTURO OVER KNEE FULL FOOT": "FUTURO OVER KNEE FULL FOOT",
              "FUTURO OVER KNEE OPEN TOE": "FUTURO OVER KNEE OPEN TOE",
              "FUTURO PANTYHOSE": "FUTURO PANTYHOSE",
              "FUTURO PATELLA KNEE SUPPORT": "FUTURO PATELLA KNEE SUPPORT",
              "FUTURO PATTERNED TIGHTS": "FUTURO PATTERNED TIGHTS",
              "FUTURO POUCH ARM SLING": "FUTURO POUCH ARM SLING",
              "FUTURO RESTORING DRESS SOCKS": "FUTURO RESTORING DRESS SOCKS",
              "FUTURO REVERSIBLE WRIST BRACE": "FUTURO REVERSIBLE WRIST BRACE",
              "FUTURO RIGHT HAND WRIST BRACE": "FUTURO RIGHT HAND WRIST BRACE",
              "FUTURO SACROILIAC BRACE": "FUTURO SACROILIAC BRACE",
              "FUTURO SHEER SUPPORT HOSE": "FUTURO SHEER SUPPORT HOSE",
              "FUTURO SHEEREST SUPPORT P/HOSE": "FUTURO SHEEREST SUPPORT P/HOSE",
              "FUTURO SPORT ADJUST KNEE STRAP": "FUTURO SPORT ADJUST KNEE STRAP",
              "FUTURO SPORT ADJUSTABLE KNEE": "FUTURO SPORT ADJUSTABLE KNEE",
              "FUTURO SPORT ANKLE STABILIZER": "FUTURO SPORT ANKLE STABILIZER",
              "FUTURO SPORT ELASTIC BANDAGE": "FUTURO SPORT ELASTIC BANDAGE",
              "FUTURO SPORT KNEE STABILIZER": "FUTURO SPORT KNEE STABILIZER",
              "FUTURO SPORT KNEE STRAP": "FUTURO SPORT KNEE STRAP",
              "FUTURO SPORT KNEE SUPPORT": "FUTURO SPORT KNEE SUPPORT",
              "FUTURO SPORT SHIN SPLINT": "FUTURO SPORT SHIN SPLINT",
              "FUTURO SPORT TENNIS ELBOW SUP": "FUTURO SPORT TENNIS ELBOW SUP",
              "FUTURO SPORT WRIST SUPPORT/ADJ": "FUTURO SPORT WRIST SUPPORT/ADJ",
              "FUTURO SPORTS-3 SUPPORTER": "FUTURO SPORTS-3 SUPPORTER",
              "FUTURO STABILIZ BACK SUPPORT": "FUTURO STABILIZ BACK SUPPORT",
              "FUTURO STABILIZ KNEE SUPPORT": "FUTURO STABILIZ KNEE SUPPORT",
              "FUTURO SUPPORT GLOVE LARGE": "FUTURO SUPPORT GLOVE LARGE",
              "FUTURO SUPPORT GLOVE MEDIUM": "FUTURO SUPPORT GLOVE MEDIUM",
              "FUTURO SUPPORT GLOVE SMALL": "FUTURO SUPPORT GLOVE SMALL",
              "FUTURO SUSPENSORY": "FUTURO SUSPENSORY",
              "FUTURO TENNIS ELBOW SUPPORT": "FUTURO TENNIS ELBOW SUPPORT",
              "FUTURO THERAPEUTIC STOCKING": "FUTURO THERAPEUTIC STOCKING",
              "FUTURO THUMB STABILIZER": "FUTURO THUMB STABILIZER",
              "FUTURO TROUSER SOCKS": "FUTURO TROUSER SOCKS",
              "FUTURO UNDER KNEE OPEN TOE": "FUTURO UNDER KNEE OPEN TOE",
              "FUTURO UNDER KNEE OPEN TOEHEEL": "FUTURO UNDER KNEE OPEN TOEHEEL",
              "FUTURO WRIST BRACE": "FUTURO WRIST BRACE",
              "FUTURO WRIST BRACE WATER RES": "FUTURO WRIST BRACE WATER RES",
              "FUTURO WRIST SLEEP SUPP/NIGHT": "FUTURO WRIST SLEEP SUPP/NIGHT",
              "FUTURO WRIST STABILIZER/DIAL": "FUTURO WRIST STABILIZER/DIAL",
              "FUTURO WRIST SUPPORT L-XL": "FUTURO WRIST SUPPORT L-XL",
              "FUTURO YOUTH CUP SUPPORT": "FUTURO YOUTH CUP SUPPORT",
              "FUTURO YOUTH SPORTS-3 SUPPORT": "FUTURO YOUTH SPORTS-3 SUPPORT",
              "NEXCARE COBAN WRAP 1\"\"X5YD": "NEXCARE COBAN WRAP 1\"\"X5YD",
              "NEXCARE COBAN WRAP 2\"\"X5YD": "NEXCARE COBAN WRAP 2\"\"X5YD",
              "NEXCARE COBAN WRAP 3\"\"X5YD": "NEXCARE COBAN WRAP 3\"\"X5YD",
              "PLANTAR FASCIITIS SUPPORT": "PLANTAR FASCIITIS SUPPORT"
            },
            "3M MEDICAL": {
              "COBAN 2 LAYER COMPRESSION SYST": "COBAN 2 LAYER COMPRESSION SYST",
              "COBAN LF SELF-ADHERENT WRAP": "COBAN LF SELF-ADHERENT WRAP",
              "COBAN SELF-ADHERENT WRAP": "COBAN SELF-ADHERENT WRAP",
              "RESTON HIGH SUPT 7-7/8\"\"X11-3/4": "RESTON HIGH SUPT 7-7/8\"\"X11-3/4",
              "RESTON LIGHT SUPT ROLL 4\"\"X196": "RESTON LIGHT SUPT ROLL 4\"\"X196",
              "RESTON MED SUPT 7-7/8\"\"X11-3/4\"\"": "RESTON MED SUPT 7-7/8\"\"X11-3/4\"\""
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP ADJUSTABLE THUMB SUPPORT": "GNP ADJUSTABLE THUMB SUPPORT",
              "GNP ANKLE SUPPORT": "GNP ANKLE SUPPORT",
              "GNP KNEE STRAP UNIVERSAL": "GNP KNEE STRAP UNIVERSAL"
            },
            "ARISE MEDICAL": {
              "FITRITE BACK BRACE WITH PULLEY": "FITRITE BACK BRACE WITH PULLEY",
              "FITRITE HINGED KNEE SUPPORT": "FITRITE HINGED KNEE SUPPORT",
              "FITRITE LUMBAR BACK BRACE": "FITRITE LUMBAR BACK BRACE"
            },
            "BD DIABETES CARE": {
              "A-4 HIGH COMPRESSION MENS HOSE": "A-4 HIGH COMPRESSION MENS HOSE",
              "ACE NEOPRENE THIGH BRACE": "ACE NEOPRENE THIGH BRACE",
              "B & B A-2 ATHLETIC SUPPORTER": "B & B A-2 ATHLETIC SUPPORTER",
              "B & B A-3 ATHLETIC SUPPORTER": "B & B A-3 ATHLETIC SUPPORTER",
              "B & B ARM SLING ADULT": "B & B ARM SLING ADULT",
              "B & B ARM SLING YOUTH": "B & B ARM SLING YOUTH",
              "B & B CARPAL TUNNEL BRACE": "B & B CARPAL TUNNEL BRACE",
              "B & B DELUXE WRISTLET": "B & B DELUXE WRISTLET",
              "B & B ELASTIC ANKLE BRACE": "B & B ELASTIC ANKLE BRACE",
              "B & B ELASTIC KNEE BRACE": "B & B ELASTIC KNEE BRACE",
              "B & B ELBOW BRACE": "B & B ELBOW BRACE",
              "B & B HERNIA BELT": "B & B HERNIA BELT",
              "B & B KNEE BRACE/SUPPORTS": "B & B KNEE BRACE/SUPPORTS",
              "B & B NYLON ANKLE BRACE": "B & B NYLON ANKLE BRACE",
              "B & B NYLON ELBOW BRACE": "B & B NYLON ELBOW BRACE",
              "B & B NYLON KNEE BRACE": "B & B NYLON KNEE BRACE",
              "B & B O-16 SUSPENSORY": "B & B O-16 SUSPENSORY",
              "B & B RIB BELT": "B & B RIB BELT",
              "B & B RIGID WRIST BRACE": "B & B RIGID WRIST BRACE",
              "B & B S-10 SWIMMER SUPPORTER": "B & B S-10 SWIMMER SUPPORTER",
              "B & B SACROILIAC BELT": "B & B SACROILIAC BELT",
              "B & B SNAP-FASTENER WRISTLET": "B & B SNAP-FASTENER WRISTLET",
              "B-1 EXTRA HIGH COMPRESS HOSE": "B-1 EXTRA HIGH COMPRESS HOSE",
              "B-2 EXTRA HIGH COMP HOSE WOMEN": "B-2 EXTRA HIGH COMP HOSE WOMEN",
              "B-3 EXTRA HIGH COMP HOSE WOMEN": "B-3 EXTRA HIGH COMP HOSE WOMEN",
              "B-4 MED COMPRESSION HOSE MENS": "B-4 MED COMPRESSION HOSE MENS",
              "ELASTIC BACK SUPPORT": "ELASTIC BACK SUPPORT",
              "G-4 SUPPORT HOSE MENS": "G-4 SUPPORT HOSE MENS",
              "M-4 KNEE HIGH STOCKINGS": "M-4 KNEE HIGH STOCKINGS",
              "M-5 MATERNITY PANTY HOSE WOMEN": "M-5 MATERNITY PANTY HOSE WOMEN",
              "M-5 PANTY HOSE": "M-5 PANTY HOSE",
              "M-5 PANTY HOSE WOMENS": "M-5 PANTY HOSE WOMENS",
              "O-2 SUSPENSORY": "O-2 SUSPENSORY",
              "P-5 PANTY HOSE": "P-5 PANTY HOSE",
              "P-5 PANTY HOSE WOMENS": "P-5 PANTY HOSE WOMENS",
              "TRU-FIT AIR ANKLE": "TRU-FIT AIR ANKLE",
              "TRU-FIT AIR ANKLE/WRAP": "TRU-FIT AIR ANKLE/WRAP",
              "TRU-FIT AIR ELBOW": "TRU-FIT AIR ELBOW",
              "TRU-FIT AIR KNEE": "TRU-FIT AIR KNEE",
              "TRU-FIT AIR KNEE STABILIZER": "TRU-FIT AIR KNEE STABILIZER",
              "TRU-FIT AIR LEFT WRIST SPLINT": "TRU-FIT AIR LEFT WRIST SPLINT",
              "TRU-FIT AIR OPEN BUTTRESS KNEE": "TRU-FIT AIR OPEN BUTTRESS KNEE",
              "TRU-FIT AIR RIGHT WRIST SPLINT": "TRU-FIT AIR RIGHT WRIST SPLINT",
              "TRU-FIT AIR WAIST STRAP": "TRU-FIT AIR WAIST STRAP",
              "TRU-FIT AIR WRIST/TENNIS ELBOW": "TRU-FIT AIR WRIST/TENNIS ELBOW",
              "TRU-FIT ANKLE SUPPORT": "TRU-FIT ANKLE SUPPORT",
              "TRU-FIT ANKLE SUPPORT/WRAP": "TRU-FIT ANKLE SUPPORT/WRAP",
              "TRU-FIT CONTOURED BACK": "TRU-FIT CONTOURED BACK",
              "TRU-FIT CONTOURED THIGH": "TRU-FIT CONTOURED THIGH",
              "TRU-FIT DELUXE ANKLE SUPPORT": "TRU-FIT DELUXE ANKLE SUPPORT",
              "TRU-FIT DUAL ADJUSTABLE BACK": "TRU-FIT DUAL ADJUSTABLE BACK",
              "TRU-FIT ELASTO-PREENE ANKLE": "TRU-FIT ELASTO-PREENE ANKLE",
              "TRU-FIT ELASTO-PREENE ELBOW": "TRU-FIT ELASTO-PREENE ELBOW",
              "TRU-FIT ELASTO-PREENE KNEE": "TRU-FIT ELASTO-PREENE KNEE",
              "TRU-FIT ELBOW SUPPORT": "TRU-FIT ELBOW SUPPORT",
              "TRU-FIT KNEE STABILIZER": "TRU-FIT KNEE STABILIZER",
              "TRU-FIT KNEE SUPPORT": "TRU-FIT KNEE SUPPORT",
              "TRU-FIT KNEE SUPPORT OPEN": "TRU-FIT KNEE SUPPORT OPEN",
              "TRU-FIT KNEE SUPPORT XL": "TRU-FIT KNEE SUPPORT XL",
              "TRU-FIT LEFT WRIST SPLINT": "TRU-FIT LEFT WRIST SPLINT",
              "TRU-FIT OPEN BUTTRESS KNEE": "TRU-FIT OPEN BUTTRESS KNEE",
              "TRU-FIT OPEN KNEE STABILIZER": "TRU-FIT OPEN KNEE STABILIZER",
              "TRU-FIT RIGHT WRIST SPLINT": "TRU-FIT RIGHT WRIST SPLINT",
              "TRU-FIT WAIST TRIMMER": "TRU-FIT WAIST TRIMMER",
              "TRU-FIT WRIST": "TRU-FIT WRIST",
              "TRU-FIT WRIST SPLINT": "TRU-FIT WRIST SPLINT",
              "TRU-FIT WRIST/TENNIS ELBOW": "TRU-FIT WRIST/TENNIS ELBOW",
              "V-2 HIGH COMPRESSION HOSE": "V-2 HIGH COMPRESSION HOSE",
              "V-4 HIGH COMPRESSION HOSE": "V-4 HIGH COMPRESSION HOSE",
              "V-5 HIGH COMPRESSION HOSE": "V-5 HIGH COMPRESSION HOSE"
            },
            "BD MEDICAL SURGICAL SYSTEMS": {
              "PEG SELF-ADHERING BANDAGE": "PEG SELF-ADHERING BANDAGE"
            },
            "BEIERSDORF DERMA": {
              "FUTURO DELUXE WRIST STABILIZER": "FUTURO DELUXE WRIST STABILIZER"
            },
            "BEIERSDORF-FUTURO": {
              "BACK SUPPORT L/XL": "BACK SUPPORT L/XL",
              "BACK SUPPORT M/L": "BACK SUPPORT M/L",
              "BACK SUPPORT S/M": "BACK SUPPORT S/M",
              "BEYOND SUPPORT PANTYHOSE": "BEYOND SUPPORT PANTYHOSE",
              "FUTURO ADJUST WRIST SUPPORT": "FUTURO ADJUST WRIST SUPPORT",
              "FUTURO FEMALE RIB BELT": "FUTURO FEMALE RIB BELT",
              "FUTURO MALE RIB BELT": "FUTURO MALE RIB BELT",
              "FUTURO REVERSIBLE WRIST BRACE": "FUTURO REVERSIBLE WRIST BRACE",
              "FUTURO SOFT CERVICAL COLLAR": "FUTURO SOFT CERVICAL COLLAR",
              "FUTURO STABILIZ BACK SUPPORT": "FUTURO STABILIZ BACK SUPPORT",
              "NEOPRENE ANKLE SUPPORT": "NEOPRENE ANKLE SUPPORT",
              "NEOPRENE ELBOW SUPPORT": "NEOPRENE ELBOW SUPPORT",
              "NEOPRENE KNEE STABILIZER": "NEOPRENE KNEE STABILIZER",
              "NEOPRENE PATELLA KNEE SUPPORT": "NEOPRENE PATELLA KNEE SUPPORT",
              "POUCH ARM SLING": "POUCH ARM SLING",
              "T.E.D. BELOW KNEE/L X-LGTH": "T.E.D. BELOW KNEE/L X-LGTH",
              "T.E.D. BELOW KNEE/L-REGULAR": "T.E.D. BELOW KNEE/L-REGULAR",
              "T.E.D. BELOW KNEE/LARGE": "T.E.D. BELOW KNEE/LARGE",
              "T.E.D. BELOW KNEE/M X-LGTH": "T.E.D. BELOW KNEE/M X-LGTH",
              "T.E.D. BELOW KNEE/M-REGULAR": "T.E.D. BELOW KNEE/M-REGULAR",
              "T.E.D. BELOW KNEE/MEDIUM": "T.E.D. BELOW KNEE/MEDIUM",
              "T.E.D. BELOW KNEE/S X-LGTH": "T.E.D. BELOW KNEE/S X-LGTH",
              "T.E.D. BELOW KNEE/S-REGULAR": "T.E.D. BELOW KNEE/S-REGULAR",
              "T.E.D. BELOW KNEE/SMALL": "T.E.D. BELOW KNEE/SMALL",
              "T.E.D. BELOW KNEE/X-LARGE": "T.E.D. BELOW KNEE/X-LARGE",
              "T.E.D. BELOW KNEE/XL": "T.E.D. BELOW KNEE/XL",
              "T.E.D. BELOW KNEE/XL X-LGTH": "T.E.D. BELOW KNEE/XL X-LGTH",
              "T.E.D. THIGH LENGTH/L-LONG": "T.E.D. THIGH LENGTH/L-LONG",
              "T.E.D. THIGH LENGTH/L-REGULAR": "T.E.D. THIGH LENGTH/L-REGULAR",
              "T.E.D. THIGH LENGTH/L-SHORT": "T.E.D. THIGH LENGTH/L-SHORT",
              "T.E.D. THIGH LENGTH/M-LONG": "T.E.D. THIGH LENGTH/M-LONG",
              "T.E.D. THIGH LENGTH/M-REGULAR": "T.E.D. THIGH LENGTH/M-REGULAR",
              "T.E.D. THIGH LENGTH/M-SHORT": "T.E.D. THIGH LENGTH/M-SHORT",
              "T.E.D. THIGH LENGTH/S-REGULAR": "T.E.D. THIGH LENGTH/S-REGULAR",
              "T.E.D. THIGH LENGTH/S-SHORT": "T.E.D. THIGH LENGTH/S-SHORT",
              "TENNIS ELBOW SUPPORT": "TENNIS ELBOW SUPPORT",
              "THERMO KNEE COMFORTER": "THERMO KNEE COMFORTER",
              "VENES CUSTOM 1-LEG LEOTARD": "VENES CUSTOM 1-LEG LEOTARD",
              "VENES CUSTOM FEMALE LEOTARD": "VENES CUSTOM FEMALE LEOTARD",
              "VENES CUSTOM MALE LEOTARD": "VENES CUSTOM MALE LEOTARD",
              "VENES CUSTOM MATERNITY LEOTARD": "VENES CUSTOM MATERNITY LEOTARD",
              "VENES CUSTOM MEDICAL STOCKINGS": "VENES CUSTOM MEDICAL STOCKINGS"
            },
            "BELL-HORN": {
              "ABDOMINAL SUPPORT 2X/3X LARGE": "ABDOMINAL SUPPORT 2X/3X LARGE",
              "ANTI-EMBOLISM STOCKINGS MEDIUM": "ANTI-EMBOLISM STOCKINGS MEDIUM",
              "AQUA ARMOR ADULT/SHORT LEG": "AQUA ARMOR ADULT/SHORT LEG",
              "AQUA ARMOR PEDI/MED ARM": "AQUA ARMOR PEDI/MED ARM"
            },
            "BERGEN BRUNSWIG": {
              "GNP ANKLE BRACE": "GNP ANKLE BRACE",
              "GNP ANKLE BRACE LARGE/X-LARGE": "GNP ANKLE BRACE LARGE/X-LARGE",
              "GNP ANKLE BRACE SMALL/MEDIUM": "GNP ANKLE BRACE SMALL/MEDIUM",
              "GNP BANDAGE SUPORT WRAP 3\"\"": "GNP BANDAGE SUPORT WRAP 3\"\"",
              "GNP ELBOW BRACE": "GNP ELBOW BRACE",
              "GNP KNEE BRACE LARGE/X-LARGE": "GNP KNEE BRACE LARGE/X-LARGE",
              "GNP KNEE BRACE SMALL/MEDIUM": "GNP KNEE BRACE SMALL/MEDIUM",
              "GNP WRIST BRACE": "GNP WRIST BRACE",
              "GNP WRIST BRACE/SM-MED": "GNP WRIST BRACE/SM-MED",
              "GNP WRIST SPLINT": "GNP WRIST SPLINT"
            },
            "BROWNMED": {
              "IMAK COMPRESSION GLOVES": "IMAK COMPRESSION GLOVES"
            },
            "BSN MEDICAL": {
              "ABDOMINAL BINDER 10\"\"": "ABDOMINAL BINDER 10\"\"",
              "ACTIVA ANTI-EMBOLISM STOCKINGS": "ACTIVA ANTI-EMBOLISM STOCKINGS",
              "ACTIVA CASUAL SOCKS LARGE": "ACTIVA CASUAL SOCKS LARGE",
              "ACTIVA CASUAL SOCKS MEDIUM": "ACTIVA CASUAL SOCKS MEDIUM",
              "ACTIVA CASUAL SOCKS SMALL": "ACTIVA CASUAL SOCKS SMALL",
              "ACTIVA CASUAL SOCKS X-LARGE": "ACTIVA CASUAL SOCKS X-LARGE",
              "ACTIVA COOLMAX SOCKS LARGE": "ACTIVA COOLMAX SOCKS LARGE",
              "ACTIVA COOLMAX SOCKS MEDIUM": "ACTIVA COOLMAX SOCKS MEDIUM",
              "ACTIVA COOLMAX SOCKS SMALL": "ACTIVA COOLMAX SOCKS SMALL",
              "ACTIVA COOLMAX SOCKS X-LARGE": "ACTIVA COOLMAX SOCKS X-LARGE",
              "ACTIVA DRESS SOCKS LARGE": "ACTIVA DRESS SOCKS LARGE",
              "ACTIVA DRESS SOCKS MEDIUM": "ACTIVA DRESS SOCKS MEDIUM",
              "ACTIVA DRESS SOCKS SMALL": "ACTIVA DRESS SOCKS SMALL",
              "ACTIVA DRESS SOCKS X-LARGE": "ACTIVA DRESS SOCKS X-LARGE",
              "ACTIVA DRESS TROUSER SOCKS LG": "ACTIVA DRESS TROUSER SOCKS LG",
              "ACTIVA DRESS TROUSER SOCKS MD": "ACTIVA DRESS TROUSER SOCKS MD",
              "ACTIVA DRESS TROUSER SOCKS SM": "ACTIVA DRESS TROUSER SOCKS SM",
              "ACTIVA DRESS TROUSER SOCKS XL": "ACTIVA DRESS TROUSER SOCKS XL",
              "ACTIVA GRADUATED THERAPY": "ACTIVA GRADUATED THERAPY",
              "ACTIVA MICROFIBER DRESS SOCKS": "ACTIVA MICROFIBER DRESS SOCKS",
              "ACTIVA SHEER THERAPY 15-20MMHG": "ACTIVA SHEER THERAPY 15-20MMHG",
              "ACTIVA SHEER THERAPY PANTYHOSE": "ACTIVA SHEER THERAPY PANTYHOSE",
              "ACTIVA SHEER THERAPY SOCKS LG": "ACTIVA SHEER THERAPY SOCKS LG",
              "ACTIVA SHEER THERAPY SOCKS MD": "ACTIVA SHEER THERAPY SOCKS MD",
              "ACTIVA SHEER THERAPY SOCKS SM": "ACTIVA SHEER THERAPY SOCKS SM",
              "ACTIVA SHEER THERAPY SOCKS XL": "ACTIVA SHEER THERAPY SOCKS XL",
              "ACTIVA SOFT FIT 20-30MMHG": "ACTIVA SOFT FIT 20-30MMHG",
              "ACTIVA SOFT FIT PANTYHOSE": "ACTIVA SOFT FIT PANTYHOSE",
              "ACTIVA SURGICAL WEIGHT": "ACTIVA SURGICAL WEIGHT",
              "ACTIVA ULTRA SHEER 9-12MMHG": "ACTIVA ULTRA SHEER 9-12MMHG",
              "ACTIVA ULTRA SHEER PANTYHOSE": "ACTIVA ULTRA SHEER PANTYHOSE",
              "FLEXLITE KNEE BRACE": "FLEXLITE KNEE BRACE",
              "INNER LOK8 ANKLE BRACE": "INNER LOK8 ANKLE BRACE",
              "J-HOOK ARMSLEEVE": "J-HOOK ARMSLEEVE",
              "J-HOOK CT MEN SIZE 5": "J-HOOK CT MEN SIZE 5",
              "J-HOOK KNEE CT": "J-HOOK KNEE CT",
              "J-HOOK KNEE HINGE": "J-HOOK KNEE HINGE",
              "J-HOOK ULTRASHEER THIGH HIGH": "J-HOOK ULTRASHEER THIGH HIGH",
              "JOBST 20-30MMHG COMPRESSION SM": "JOBST 20-30MMHG COMPRESSION SM",
              "JOBST ACTIVE 15-20MMHG MEDIUM": "JOBST ACTIVE 15-20MMHG MEDIUM",
              "JOBST ACTIVE 15-20MMHG X-LARGE": "JOBST ACTIVE 15-20MMHG X-LARGE",
              "JOBST ACTIVE 20-30MMHG MEDIUM": "JOBST ACTIVE 20-30MMHG MEDIUM",
              "JOBST ACTIVEWEAR 15-20MMHG": "JOBST ACTIVEWEAR 15-20MMHG",
              "JOBST ANTI-EM KNEE HIGH MED": "JOBST ANTI-EM KNEE HIGH MED",
              "JOBST ANTI-EM KNEE LENGTH LG": "JOBST ANTI-EM KNEE LENGTH LG",
              "JOBST ANTI-EM KNEE LENGTH MED": "JOBST ANTI-EM KNEE LENGTH MED",
              "JOBST ANTI-EM THIGH HIGH LARGE": "JOBST ANTI-EM THIGH HIGH LARGE",
              "JOBST ANTI-EM THIGH HIGH MED": "JOBST ANTI-EM THIGH HIGH MED",
              "JOBST ANTI-EM THIGH HIGH SMALL": "JOBST ANTI-EM THIGH HIGH SMALL",
              "JOBST ANTI-EM THIGH HIGH XL": "JOBST ANTI-EM THIGH HIGH XL",
              "JOBST CASUAL 8-15MMHG MEDIUM": "JOBST CASUAL 8-15MMHG MEDIUM",
              "JOBST FOR MEN 15-20MMHG LG": "JOBST FOR MEN 15-20MMHG LG",
              "JOBST FOR MEN 15-20MMHG MED": "JOBST FOR MEN 15-20MMHG MED",
              "JOBST FOR MEN 15-20MMHG XL": "JOBST FOR MEN 15-20MMHG XL",
              "JOBST FOR MEN 20-30MMHG LG": "JOBST FOR MEN 20-30MMHG LG",
              "JOBST FOR MEN 30-40MMHG LG": "JOBST FOR MEN 30-40MMHG LG",
              "JOBST FOR MEN 30-40MMHG MED": "JOBST FOR MEN 30-40MMHG MED",
              "JOBST FOR MEN 30-40MMHG SM": "JOBST FOR MEN 30-40MMHG SM",
              "JOBST FOR MEN 8-15MMHG LG": "JOBST FOR MEN 8-15MMHG LG",
              "JOBST OPAQUE KNEE 15-20MMHG SM": "JOBST OPAQUE KNEE 15-20MMHG SM",
              "JOBST OPAQUE KNEE 15-20MMHG XL": "JOBST OPAQUE KNEE 15-20MMHG XL",
              "JOBST OPAQUE KNEE 20-30MMHG LG": "JOBST OPAQUE KNEE 20-30MMHG LG",
              "JOBST OPAQUE KNEE 20-30MMHG MD": "JOBST OPAQUE KNEE 20-30MMHG MD",
              "JOBST OPAQUE KNEE 20-30MMHG SM": "JOBST OPAQUE KNEE 20-30MMHG SM",
              "JOBST OPAQUE KNEE 20-30MMHG XL": "JOBST OPAQUE KNEE 20-30MMHG XL",
              "JOBST OPAQUE SOFTFIT KNEE SM": "JOBST OPAQUE SOFTFIT KNEE SM",
              "JOBST RELIEF 15-20MMHG LG": "JOBST RELIEF 15-20MMHG LG",
              "JOBST RELIEF 20-30MMHG LG": "JOBST RELIEF 20-30MMHG LG",
              "JOBST RELIEF 20-30MMHG MEDIUM": "JOBST RELIEF 20-30MMHG MEDIUM",
              "JOBST RELIEF 20-30MMHG SMALL": "JOBST RELIEF 20-30MMHG SMALL",
              "JOBST RELIEF 20-30MMHG XL": "JOBST RELIEF 20-30MMHG XL",
              "JOBST RELIEF 30-40MMHG LARGE": "JOBST RELIEF 30-40MMHG LARGE",
              "JOBST RELIEF 30-40MMHG MEDIUM": "JOBST RELIEF 30-40MMHG MEDIUM",
              "JOBST RELIEF 30-40MMHG XL": "JOBST RELIEF 30-40MMHG XL",
              "JOBST RIB BELT/MEN/ELASTIC/XXL": "JOBST RIB BELT/MEN/ELASTIC/XXL",
              "JOBST SENSIFOOT CREW MEDIUM": "JOBST SENSIFOOT CREW MEDIUM",
              "JOBST SENSIFOOT KNEE": "JOBST SENSIFOOT KNEE",
              "JOBST SPORT 15-20MMHG SMALL": "JOBST SPORT 15-20MMHG SMALL",
              "JOBST SPORT 20-30MMHG SMALL": "JOBST SPORT 20-30MMHG SMALL",
              "JOBST ULTRASHEER 15-20MMHG LG": "JOBST ULTRASHEER 15-20MMHG LG",
              "JOBST ULTRASHEER 15-20MMHG SM": "JOBST ULTRASHEER 15-20MMHG SM",
              "JOBST ULTRASHEER 15-20MMHG XL": "JOBST ULTRASHEER 15-20MMHG XL",
              "JOBST ULTRASHEER 20-30MMHG": "JOBST ULTRASHEER 20-30MMHG",
              "JOBST ULTRASHEER 20-30MMHG LG": "JOBST ULTRASHEER 20-30MMHG LG",
              "JOBST ULTRASHEER 20-30MMHG MED": "JOBST ULTRASHEER 20-30MMHG MED",
              "JOBST ULTRASHEER 20-30MMHG SM": "JOBST ULTRASHEER 20-30MMHG SM",
              "JOBST ULTRASHEER 20-30MMHG XL": "JOBST ULTRASHEER 20-30MMHG XL",
              "JOBST ULTRASHEER 30-40MMHG MED": "JOBST ULTRASHEER 30-40MMHG MED",
              "JOBST ULTRASHEER 30-40MMHG SM": "JOBST ULTRASHEER 30-40MMHG SM",
              "JOBST ULTRASHEER 30-40MMHG XL": "JOBST ULTRASHEER 30-40MMHG XL",
              "JOBST ULTRASHEER 8-15MMHG LG": "JOBST ULTRASHEER 8-15MMHG LG",
              "JOBST ULTRASHEER 8-15MMHG SM": "JOBST ULTRASHEER 8-15MMHG SM",
              "JOBST ULTRASHEER THIGH HIGH MD": "JOBST ULTRASHEER THIGH HIGH MD",
              "KNEE IMMOBILIZER 22\"\"": "KNEE IMMOBILIZER 22\"\"",
              "KNEE SLEEVE/MEDIUM": "KNEE SLEEVE/MEDIUM",
              "MEDICAL LEGWEAR/KNEE HIGH": "MEDICAL LEGWEAR/KNEE HIGH",
              "MEDICAL LEGWEAR/WAIST HIGH": "MEDICAL LEGWEAR/WAIST HIGH",
              "PROLITE 3D ANKLE SUPPORT": "PROLITE 3D ANKLE SUPPORT",
              "PROLITE ANKLE STIRRUP BRACE": "PROLITE ANKLE STIRRUP BRACE",
              "PROLITE ARM SLING": "PROLITE ARM SLING",
              "PROLITE LUMBAR SUPPORT": "PROLITE LUMBAR SUPPORT",
              "PROLITE WRIST SPLINT": "PROLITE WRIST SPLINT",
              "RELIEF KNEE": "RELIEF KNEE",
              "RELIEF KNEE STOCKING": "RELIEF KNEE STOCKING",
              "RELIEF MEDICAL LEG KNEE HIGH": "RELIEF MEDICAL LEG KNEE HIGH",
              "SAFE-T-SPORT KNEE SLEEVE SMALL": "SAFE-T-SPORT KNEE SLEEVE SMALL",
              "SAFE-T-SPORT KNEE SUPPORT 2XL": "SAFE-T-SPORT KNEE SUPPORT 2XL",
              "SENSIFOOT CREW SOCKS LARGE": "SENSIFOOT CREW SOCKS LARGE",
              "SUPPORT COMPRESSION SOCK MENS": "SUPPORT COMPRESSION SOCK MENS",
              "WRIST SUPPORT/ELASTIC/PULLOVER": "WRIST SUPPORT/ELASTIC/PULLOVER"
            },
            "CHAIN DRUG CONSORTIUM": {
              "BACK SUPPORT/LUMBAR TENSION": "BACK SUPPORT/LUMBAR TENSION",
              "ELASTIC BANDAGE 3\"\"": "ELASTIC BANDAGE 3\"\"",
              "ELASTIC BANDAGE 4\"\"": "ELASTIC BANDAGE 4\"\"",
              "FINGER COTS": "FINGER COTS",
              "FINGER SPLINT": "FINGER SPLINT",
              "KNEE STABILIZER/ELASTIC/SM/MED": "KNEE STABILIZER/ELASTIC/SM/MED",
              "KNEE STRAP/UNIVERSAL": "KNEE STRAP/UNIVERSAL",
              "KNEE SUPPORT ADJUSTABLE": "KNEE SUPPORT ADJUSTABLE",
              "KNEE SUPPORT/OPEN PATELLA SM": "KNEE SUPPORT/OPEN PATELLA SM",
              "NEOPRENE ANKLE SUPPORT": "NEOPRENE ANKLE SUPPORT",
              "NEOPRENE DELUXE ANKLE BRACE": "NEOPRENE DELUXE ANKLE BRACE",
              "NEOPRENE KNEE SUPPORT": "NEOPRENE KNEE SUPPORT",
              "NEOPRENE WRIST SPLINT": "NEOPRENE WRIST SPLINT",
              "SELF-ADHERING ELASTIC BANDAGE": "SELF-ADHERING ELASTIC BANDAGE",
              "SELF-GRIP SELF ADH BANDAGE 2\"\"": "SELF-GRIP SELF ADH BANDAGE 2\"\"",
              "SELF-GRIP SELF ADH BANDAGE 3\"\"": "SELF-GRIP SELF ADH BANDAGE 3\"\"",
              "SELF-GRIP SELF ADH BANDAGE 4\"\"": "SELF-GRIP SELF ADH BANDAGE 4\"\"",
              "SLIP-ON KNEE BRACE": "SLIP-ON KNEE BRACE",
              "TENNIS ELBOW NEOPRENE BRACE": "TENNIS ELBOW NEOPRENE BRACE",
              "THUMB SUPPORT/ADJUSTABLE": "THUMB SUPPORT/ADJUSTABLE",
              "WRIST BRACE DELUXE/LEFT L-XL": "WRIST BRACE DELUXE/LEFT L-XL",
              "WRIST BRACE DELUXE/LEFT S/M": "WRIST BRACE DELUXE/LEFT S/M",
              "WRIST BRACE DELUXE/RIGHT L-XL": "WRIST BRACE DELUXE/RIGHT L-XL",
              "WRIST BRACE DELUXE/RIGHT S/M": "WRIST BRACE DELUXE/RIGHT S/M",
              "WRIST SPLINT ELASTIC/LARGE-XL": "WRIST SPLINT ELASTIC/LARGE-XL",
              "WRIST SPLINT ELASTIC/SMALL/MED": "WRIST SPLINT ELASTIC/SMALL/MED",
              "WRIST SPLINT LEFT/RIGHT": "WRIST SPLINT LEFT/RIGHT"
            },
            "COMPASS HEALTH BRANDS": {
              "ANKLE LACE-UP BRACE": "ANKLE LACE-UP BRACE",
              "ANKLE WRAP": "ANKLE WRAP",
              "BACK SUPPORT": "BACK SUPPORT",
              "CERVICAL COLLAR": "CERVICAL COLLAR",
              "COOL-WEAVE ANKLE SUPPORT L/XL": "COOL-WEAVE ANKLE SUPPORT L/XL",
              "COOL-WEAVE ANKLE SUPPORT S/M": "COOL-WEAVE ANKLE SUPPORT S/M",
              "COOL-WEAVE ELBOW SUPPORT LARGE": "COOL-WEAVE ELBOW SUPPORT LARGE",
              "COOL-WEAVE ELBOW SUPPORT MED": "COOL-WEAVE ELBOW SUPPORT MED",
              "COOL-WEAVE ELBOW SUPPORT SMALL": "COOL-WEAVE ELBOW SUPPORT SMALL",
              "COOL-WEAVE KNEE SUPPORT LARGE": "COOL-WEAVE KNEE SUPPORT LARGE",
              "COOL-WEAVE KNEE SUPPORT MEDIUM": "COOL-WEAVE KNEE SUPPORT MEDIUM",
              "COOL-WEAVE KNEE SUPPORT SMALL": "COOL-WEAVE KNEE SUPPORT SMALL",
              "COOL-WEAVE KNEE SUPPORT XL": "COOL-WEAVE KNEE SUPPORT XL",
              "COOL-WEAVE TENNIS ELBOW SUPPRT": "COOL-WEAVE TENNIS ELBOW SUPPRT",
              "COOL-WEAVE WRIST SUPPORT L/XL": "COOL-WEAVE WRIST SUPPORT L/XL",
              "COOL-WEAVE WRIST SUPPORT MED": "COOL-WEAVE WRIST SUPPORT MED",
              "COOL-WEAVE WRIST SUPPORT S/M": "COOL-WEAVE WRIST SUPPORT S/M",
              "COOL-WEAVE WRIST SUPPORT SMALL": "COOL-WEAVE WRIST SUPPORT SMALL",
              "ELBOW BRACE": "ELBOW BRACE",
              "KNEE BRACE": "KNEE BRACE",
              "TENNIS ELBOW SUPPORT": "TENNIS ELBOW SUPPORT",
              "UNIVERSAL ARM SLING": "UNIVERSAL ARM SLING",
              "WRIST SPLINT": "WRIST SPLINT",
              "WRIST WRAP": "WRIST WRAP"
            },
            "COVIDIEN MEDICAL SUPPLIES": {
              "ANTI-EMBOLISM STOCKINGS MEDIUM": "ANTI-EMBOLISM STOCKINGS MEDIUM",
              "CURITY ELASTIC BANDAGE W/CLIP": "CURITY ELASTIC BANDAGE W/CLIP",
              "CURITY STRETCH BANDAGE": "CURITY STRETCH BANDAGE",
              "T.E.D. BELTED THIGH/L-LONG": "T.E.D. BELTED THIGH/L-LONG",
              "T.E.D. BELTED THIGH/M-REGULAR": "T.E.D. BELTED THIGH/M-REGULAR",
              "T.E.D. BELTED THIGH/S-LONG": "T.E.D. BELTED THIGH/S-LONG",
              "T.E.D. BELTED THIGH/XL-LONG": "T.E.D. BELTED THIGH/XL-LONG",
              "T.E.D. BELTED THIGH/XL-REGULAR": "T.E.D. BELTED THIGH/XL-REGULAR",
              "T.E.D. BELTED THIGH/XS-LONG": "T.E.D. BELTED THIGH/XS-LONG",
              "T.E.D. BELTED THIGH/XS-REGULAR": "T.E.D. BELTED THIGH/XS-REGULAR",
              "T.E.D. KNEE LENGTH/L-LONG": "T.E.D. KNEE LENGTH/L-LONG",
              "T.E.D. KNEE LENGTH/L-REGULAR": "T.E.D. KNEE LENGTH/L-REGULAR",
              "T.E.D. KNEE LENGTH/LARGE": "T.E.D. KNEE LENGTH/LARGE",
              "T.E.D. KNEE LENGTH/M-LONG": "T.E.D. KNEE LENGTH/M-LONG",
              "T.E.D. KNEE LENGTH/M-REGULAR": "T.E.D. KNEE LENGTH/M-REGULAR",
              "T.E.D. KNEE LENGTH/MEDIUM": "T.E.D. KNEE LENGTH/MEDIUM",
              "T.E.D. KNEE LENGTH/S-LONG": "T.E.D. KNEE LENGTH/S-LONG",
              "T.E.D. KNEE LENGTH/S-REGULAR": "T.E.D. KNEE LENGTH/S-REGULAR",
              "T.E.D. KNEE LENGTH/XL-LONG": "T.E.D. KNEE LENGTH/XL-LONG",
              "T.E.D. KNEE LENGTH/XL-REGULAR": "T.E.D. KNEE LENGTH/XL-REGULAR",
              "T.E.D. THIGH LENGTH/L-LONG": "T.E.D. THIGH LENGTH/L-LONG",
              "T.E.D. THIGH LENGTH/L-REGULAR": "T.E.D. THIGH LENGTH/L-REGULAR",
              "T.E.D. THIGH LENGTH/L-SHORT": "T.E.D. THIGH LENGTH/L-SHORT",
              "T.E.D. THIGH LENGTH/M-LONG": "T.E.D. THIGH LENGTH/M-LONG",
              "T.E.D. THIGH LENGTH/M-REGULAR": "T.E.D. THIGH LENGTH/M-REGULAR",
              "T.E.D. THIGH LENGTH/M-SHORT": "T.E.D. THIGH LENGTH/M-SHORT",
              "T.E.D. THIGH LENGTH/S-LONG": "T.E.D. THIGH LENGTH/S-LONG",
              "T.E.D. THIGH LENGTH/S-REGULAR": "T.E.D. THIGH LENGTH/S-REGULAR",
              "T.E.D. THIGH LENGTH/S-SHORT": "T.E.D. THIGH LENGTH/S-SHORT",
              "TENSOR ELASTIC BANDAGE": "TENSOR ELASTIC BANDAGE"
            },
            "CURAMEDICA, LLC": {
              "VASCURA ANTI-EMBOLISM/KNEE LG": "VASCURA ANTI-EMBOLISM/KNEE LG"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS ANKLE SUPPORT SLEEVE": "CVS ANKLE SUPPORT SLEEVE",
              "CVS ARM SLING/ADULT": "CVS ARM SLING/ADULT",
              "CVS ARM SLING/UNIVERSAL": "CVS ARM SLING/UNIVERSAL",
              "CVS BANDAGE ROLL 4.5\"\"X108\"\"": "CVS BANDAGE ROLL 4.5\"\"X108\"\"",
              "CVS CAST & WOUND PROTECTOR": "CVS CAST & WOUND PROTECTOR",
              "CVS COPPER COMPRESSION SLEVE": "CVS COPPER COMPRESSION SLEVE",
              "CVS ELASTIC BANDAGE 4\"\"": "CVS ELASTIC BANDAGE 4\"\"",
              "CVS ELASTIC BANDAGE 6\"\"": "CVS ELASTIC BANDAGE 6\"\"",
              "CVS FINGER INJURY KIT": "CVS FINGER INJURY KIT",
              "CVS FINGER SPLINT": "CVS FINGER SPLINT",
              "CVS FIRM COMPRESSION SOCKS": "CVS FIRM COMPRESSION SOCKS",
              "CVS KNEE SUP SLEEVE MILD COMPR": "CVS KNEE SUP SLEEVE MILD COMPR",
              "CVS KNEE SUPPORT SLEEVE": "CVS KNEE SUPPORT SLEEVE",
              "CVS LUMBAR/BACK SUPPORT BRACE": "CVS LUMBAR/BACK SUPPORT BRACE",
              "CVS SELF-GRIP ATHLETIC TAPE": "CVS SELF-GRIP ATHLETIC TAPE",
              "CVS WRIST SUPPORT STRAP": "CVS WRIST SUPPORT STRAP"
            },
            "DJO GLOBAL": {
              "AIRCAST AIRSPORT ANKLE BRACE": "AIRCAST AIRSPORT ANKLE BRACE"
            },
            "DURO-MED INDUSTRIES": {
              "DMI DELUXE CAST-BAND PROTECTOR": "DMI DELUXE CAST-BAND PROTECTOR"
            },
            "HEALTH ENTERPRISES": {
              "ACU-STRAP": "ACU-STRAP",
              "COT FINGER SPLINT": "COT FINGER SPLINT",
              "GUTTER FINGER SPLINT": "GUTTER FINGER SPLINT",
              "RUBBER FINGER PROTECTORS": "RUBBER FINGER PROTECTORS"
            },
            "HOME AIDE DIAGNOSTICS": {
              "KNEE SUPPORT": "KNEE SUPPORT",
              "LIFESTYLECOMFORT COMPRES STOCK": "LIFESTYLECOMFORT COMPRES STOCK",
              "PRO COMFORT LUMB SPIN ORTHOSIS": "PRO COMFORT LUMB SPIN ORTHOSIS",
              "TRUE SUPPORT WRIST BRACE/LEFT": "TRUE SUPPORT WRIST BRACE/LEFT",
              "ULTRA SUPPORT SPINAL ORTHOSIS": "ULTRA SUPPORT SPINAL ORTHOSIS"
            },
            "J & J CONSUMER PRODUCTS": {
              "BAND-AID SECURE-FLEX WRAP": "BAND-AID SECURE-FLEX WRAP",
              "COACH ELASTIC BANDAGE": "COACH ELASTIC BANDAGE"
            },
            "J & J MEDICAL": {
              "ELASTIKON ELASTIC": "ELASTIKON ELASTIC",
              "J & J TRIANGULAR BANDAGE": "J & J TRIANGULAR BANDAGE",
              "SOF-BAND": "SOF-BAND"
            },
            "LEADER BRAND PRODUCTS": {
              "ANKLE KNITTED COMPRESSION": "ANKLE KNITTED COMPRESSION",
              "ANKLE WRAP": "ANKLE WRAP",
              "ATHLETIC BANDAGE": "ATHLETIC BANDAGE",
              "BANDAGE ROLL 4.5\"\" X 144\"\"": "BANDAGE ROLL 4.5\"\" X 144\"\"",
              "CARPAL TUNNEL WRIST DELUXE": "CARPAL TUNNEL WRIST DELUXE",
              "CLIP BANDAGE": "CLIP BANDAGE",
              "DIABETIC SOCKS LARGE": "DIABETIC SOCKS LARGE",
              "DIABETIC SOCKS MEDIUM": "DIABETIC SOCKS MEDIUM",
              "ELBOW COMPRESSION": "ELBOW COMPRESSION",
              "KNEE COMPRESSION XL": "KNEE COMPRESSION XL",
              "KNEE SLIP-ON OPEN PATELLA": "KNEE SLIP-ON OPEN PATELLA",
              "KNEE STRAP/UNIVERSAL": "KNEE STRAP/UNIVERSAL",
              "NEOPRENE KNEE BRACE": "NEOPRENE KNEE BRACE",
              "TENNIS ELBOW STRAP": "TENNIS ELBOW STRAP",
              "WRIST BRACE DELUXE": "WRIST BRACE DELUXE",
              "WRIST BRACE DELUXE/LEFT L-XL": "WRIST BRACE DELUXE/LEFT L-XL",
              "WRIST BRACE DELUXE/LEFT S/M": "WRIST BRACE DELUXE/LEFT S/M",
              "WRIST BRACE DELUXE/RIGHT L-XL": "WRIST BRACE DELUXE/RIGHT L-XL",
              "WRIST BRACE DELUXE/RIGHT S/M": "WRIST BRACE DELUXE/RIGHT S/M"
            },
            "LEGENDS AND HEROES": {
              "SKINEEZ SKIN-REPARATIVE SOCKS": "SKINEEZ SKIN-REPARATIVE SOCKS",
              "VITAWEAR COMPRESSION SOCKS": "VITAWEAR COMPRESSION SOCKS",
              "VITAWEAR DIABETIC DRESS SOCKS": "VITAWEAR DIABETIC DRESS SOCKS"
            },
            "MCKESSON HOME HEALTH CARE": {
              "BASEBALL SPLINT": "BASEBALL SPLINT",
              "CAST AND BANDAGE COVER": "CAST AND BANDAGE COVER",
              "CERVICAL COLLAR": "CERVICAL COLLAR",
              "CURVED FINGER SPLINT": "CURVED FINGER SPLINT",
              "FINGER-TOE SPLINT": "FINGER-TOE SPLINT",
              "FOUR-SIDED FINGER PROTECTOR": "FOUR-SIDED FINGER PROTECTOR",
              "FROG SPLINT": "FROG SPLINT",
              "HEEL/ANKLE PROTECTOR": "HEEL/ANKLE PROTECTOR",
              "OPEN NAIL FINGER PROTECTOR": "OPEN NAIL FINGER PROTECTOR",
              "PLASTIC FINGER GUARD LARGE": "PLASTIC FINGER GUARD LARGE",
              "PLASTIC FINGER GUARD MEDIUM": "PLASTIC FINGER GUARD MEDIUM",
              "PLASTIC FINGER GUARD SMALL": "PLASTIC FINGER GUARD SMALL",
              "SACRO-CUSHION": "SACRO-CUSHION",
              "SM KNEE HIGH 20-30MMHG": "SM KNEE HIGH 20-30MMHG",
              "SM MEDICAL STOCKINGS/20-30MMHG": "SM MEDICAL STOCKINGS/20-30MMHG"
            },
            "MCKESSON SUNMARK": {
              "SM ELASTIC BANDAGE": "SM ELASTIC BANDAGE",
              "SM ELASTIC BANDAGE SELF ADH": "SM ELASTIC BANDAGE SELF ADH",
              "SM TENDER 2\"\"X5YD": "SM TENDER 2\"\"X5YD"
            },
            "MEDICINE SHOPPE": {
              "BANDAGE ROLL 4.5\"\"X4YD": "BANDAGE ROLL 4.5\"\"X4YD"
            },
            "MEDLINE INDUS": {
              "EMS ANTI-EMBOLISM STOCKINGS": "EMS ANTI-EMBOLISM STOCKINGS"
            },
            "MEDLINE INDUSTRIES": {
              "CERVICAL COLLAR": "CERVICAL COLLAR",
              "CURAD BACK SUPPORT": "CURAD BACK SUPPORT",
              "SURE-WRAP ELASTIC BANDAGE": "SURE-WRAP ELASTIC BANDAGE"
            },
            "MEDLINE/DERMAL MANAGEMENT": {
              "MEDIGRIP": "MEDIGRIP"
            },
            "MUELLER": {
              "ADJUSTABLE ARM SLING": "ADJUSTABLE ARM SLING",
              "ADJUSTABLE WRIST BRACE": "ADJUSTABLE WRIST BRACE",
              "AIRCAST SPORT ANKLE BRACE/LEFT": "AIRCAST SPORT ANKLE BRACE/LEFT",
              "AIRCAST SPORT ANKLE BRACE/RGHT": "AIRCAST SPORT ANKLE BRACE/RGHT",
              "AIRCAST TENNIS ELBOW SUPPORT": "AIRCAST TENNIS ELBOW SUPPORT",
              "ANKLE BRACE ADJUST-TO-FIT": "ANKLE BRACE ADJUST-TO-FIT",
              "ANKLE STABILIZER": "ANKLE STABILIZER",
              "ANKLE SUPPORT": "ANKLE SUPPORT",
              "ANKLE SUPPORT 4-WAY STRETCH": "ANKLE SUPPORT 4-WAY STRETCH",
              "CARPAL TUNNEL WRIST STABILIZER": "CARPAL TUNNEL WRIST STABILIZER",
              "COMFORT WRIST STABILIZER": "COMFORT WRIST STABILIZER",
              "COMPRESSION & SUPPORT GLOVES L": "COMPRESSION & SUPPORT GLOVES L",
              "ELASTIC BANDAGE": "ELASTIC BANDAGE",
              "ELASTIC BANDAGE 3\"\"": "ELASTIC BANDAGE 3\"\"",
              "ELASTIC BANDAGE 4\"\"": "ELASTIC BANDAGE 4\"\"",
              "ELBOW SUPPORT": "ELBOW SUPPORT",
              "HG80 TENNIS ELBOW SUPPORT": "HG80 TENNIS ELBOW SUPPORT",
              "KNEE BRACE ADJUSTABLE HINGED": "KNEE BRACE ADJUSTABLE HINGED",
              "KNEE BRACE/HINGED/LARGE": "KNEE BRACE/HINGED/LARGE",
              "KNEE BRACE/HINGED/REGULAR": "KNEE BRACE/HINGED/REGULAR",
              "KNEE STABILIZER/SELF-ADJUSTING": "KNEE STABILIZER/SELF-ADJUSTING",
              "KNEE SUPPORT": "KNEE SUPPORT",
              "KNEE SUPPORT 4-WAY STRETCH": "KNEE SUPPORT 4-WAY STRETCH",
              "KNEE SUPPORT ADJUSTABLE": "KNEE SUPPORT ADJUSTABLE",
              "LUMBAR BACK BRACE/SUPPORT PAD": "LUMBAR BACK BRACE/SUPPORT PAD",
              "MUELLER ADJUSTABLE BACK BRACE": "MUELLER ADJUSTABLE BACK BRACE",
              "MUELLER ADJUSTABLE ELBOW": "MUELLER ADJUSTABLE ELBOW",
              "MUELLER COMPRESSION & RECOVERY": "MUELLER COMPRESSION & RECOVERY",
              "MUELLER FASCIADERM": "MUELLER FASCIADERM",
              "MUELLER JUMPERS KNEE STRAP": "MUELLER JUMPERS KNEE STRAP",
              "MWRAP MULTI-PURPOSE WRAP": "MWRAP MULTI-PURPOSE WRAP",
              "REVERSIBLE WRIST BRACE": "REVERSIBLE WRIST BRACE",
              "TENNIS ELBOW ANTIMICROBIAL": "TENNIS ELBOW ANTIMICROBIAL",
              "THUMB STABILIZER/ADJUSTABLE": "THUMB STABILIZER/ADJUSTABLE",
              "THUMB STABILIZER/REVERSIBLE": "THUMB STABILIZER/REVERSIBLE",
              "WRIST BRACE ULTRA-LITE": "WRIST BRACE ULTRA-LITE"
            },
            "RITE AID CORPORATION": {
              "RA ATHLETIC BANDAGE": "RA ATHLETIC BANDAGE",
              "RA ATHLETIC BANDAGE ULTRAWRAP": "RA ATHLETIC BANDAGE ULTRAWRAP",
              "RA ATHLETIC BNDG SELF GRIP 2\"\"": "RA ATHLETIC BNDG SELF GRIP 2\"\"",
              "RA ATHLETIC BNDG SELF GRIP 3\"\"": "RA ATHLETIC BNDG SELF GRIP 3\"\"",
              "RA ATHLETIC BNDG SELF GRIP 4\"\"": "RA ATHLETIC BNDG SELF GRIP 4\"\"",
              "RA FINGER COTS": "RA FINGER COTS",
              "RA FINGER INJURY KIT": "RA FINGER INJURY KIT",
              "RA FINGER SLEEVES": "RA FINGER SLEEVES",
              "RA FINGER SPLINT": "RA FINGER SPLINT",
              "RA FIRST AID SELF GRIP": "RA FIRST AID SELF GRIP",
              "RA INSTY-SPLINT MEDIUM/LARGE": "RA INSTY-SPLINT MEDIUM/LARGE",
              "RA MULTI-PURPOSE SPORTS WRAP": "RA MULTI-PURPOSE SPORTS WRAP",
              "RA SELF GRIP ATHLETIC BANDAGE": "RA SELF GRIP ATHLETIC BANDAGE",
              "RA SELFGRIP ATHLETIC BANDAGE": "RA SELFGRIP ATHLETIC BANDAGE",
              "RA WRIST BRACE ADJ LEFT L/XL": "RA WRIST BRACE ADJ LEFT L/XL",
              "RA WRIST BRACE ADJ RIGHT L/XL": "RA WRIST BRACE ADJ RIGHT L/XL"
            },
            "SDI USA": {
              "CLEVER CHOICE ANKLE BRACE": "CLEVER CHOICE ANKLE BRACE",
              "CLEVER CHOICE COMFORT FIT": "CLEVER CHOICE COMFORT FIT",
              "CLEVER CHOICE COMFORT GLOVES": "CLEVER CHOICE COMFORT GLOVES",
              "CLEVER CHOICE COPPER GLOVES": "CLEVER CHOICE COPPER GLOVES"
            },
            "SIGVARIS": {
              "ATHLETIC RECOVERY SOCKS": "ATHLETIC RECOVERY SOCKS",
              "CASUAL SOCKS": "CASUAL SOCKS",
              "COTTON SOCKS/CUSHIONED": "COTTON SOCKS/CUSHIONED",
              "COTTON SOCKS/GOLF": "COTTON SOCKS/GOLF",
              "COTTON SOCKS/MATERNITY": "COTTON SOCKS/MATERNITY",
              "COTTON THIGH-HIGH": "COTTON THIGH-HIGH",
              "DRESS SOCKS": "DRESS SOCKS",
              "EVERSHEER PANTYHOSE": "EVERSHEER PANTYHOSE",
              "EVERSHEER STOCKINGS": "EVERSHEER STOCKINGS",
              "EVERSHEER THIGH HIGH": "EVERSHEER THIGH HIGH",
              "JEWEL SOCKS": "JEWEL SOCKS",
              "MEDICAL COMPRESSION PANTYHOSE": "MEDICAL COMPRESSION PANTYHOSE",
              "MEDICAL COMPRESSION SOCKS": "MEDICAL COMPRESSION SOCKS",
              "MEDICAL COMPRESSION STOCKINGS": "MEDICAL COMPRESSION STOCKINGS",
              "MEDICAL COMPRESSION THIGH HIGH": "MEDICAL COMPRESSION THIGH HIGH",
              "MEDICAL THERAPY SOCKS": "MEDICAL THERAPY SOCKS",
              "NATURAL RUBBER PANTYHOSE": "NATURAL RUBBER PANTYHOSE",
              "NATURAL RUBBER STOCKINGS": "NATURAL RUBBER STOCKINGS",
              "PERFORMANCE SOCKS": "PERFORMANCE SOCKS",
              "SELECT COMFORT PANTYHOSE": "SELECT COMFORT PANTYHOSE",
              "SELECT COMFORT SOCKS": "SELECT COMFORT SOCKS",
              "SELECT COMFORT THIGH HIGH": "SELECT COMFORT THIGH HIGH",
              "SHEER FASHION MATERNITY": "SHEER FASHION MATERNITY",
              "SHEER FASHION PANTYHOSE": "SHEER FASHION PANTYHOSE",
              "SHEER FASHION STOCKINGS": "SHEER FASHION STOCKINGS",
              "TRULY TRANSPARENT PANTYHOSE": "TRULY TRANSPARENT PANTYHOSE",
              "TRULY TRANSPARENT STOCKINGS": "TRULY TRANSPARENT STOCKINGS",
              "TRULY TRANSPARENT THIGH HIGH": "TRULY TRANSPARENT THIGH HIGH"
            },
            "SURGICAL APPLIANCE INDUSTRIES": {
              "ABDOMINAL BINDER/ELASTIC 2XL": "ABDOMINAL BINDER/ELASTIC 2XL",
              "ABDOMINAL BINDER/ELASTIC 3XL": "ABDOMINAL BINDER/ELASTIC 3XL",
              "ABDOMINAL BINDER/ELASTIC LARGE": "ABDOMINAL BINDER/ELASTIC LARGE",
              "ABDOMINAL BINDER/ELASTIC MED": "ABDOMINAL BINDER/ELASTIC MED",
              "ABDOMINAL BINDER/ELASTIC SMALL": "ABDOMINAL BINDER/ELASTIC SMALL",
              "ABDOMINAL BINDER/ELASTIC XL": "ABDOMINAL BINDER/ELASTIC XL",
              "ANKLE BRACE/FLEXIBLE STAYS LG": "ANKLE BRACE/FLEXIBLE STAYS LG",
              "ANKLE BRACE/FLEXIBLE STAYS MED": "ANKLE BRACE/FLEXIBLE STAYS MED",
              "ANKLE BRACE/FLEXIBLE STAYS SM": "ANKLE BRACE/FLEXIBLE STAYS SM",
              "ANKLE BRACE/FLEXIBLE STAYS XL": "ANKLE BRACE/FLEXIBLE STAYS XL",
              "ANKLE BRACE/HIGH PERFORM 2XL": "ANKLE BRACE/HIGH PERFORM 2XL",
              "ANKLE BRACE/HIGH PERFORMANCE L": "ANKLE BRACE/HIGH PERFORMANCE L",
              "ANKLE BRACE/HIGH PERFORMANCE M": "ANKLE BRACE/HIGH PERFORMANCE M",
              "ANKLE BRACE/HIGH PERFORMANCE S": "ANKLE BRACE/HIGH PERFORMANCE S",
              "ANKLE BRACE/HIGH PERFORMNCE XL": "ANKLE BRACE/HIGH PERFORMNCE XL",
              "ANKLE BRACE/HIGH PERFORMNCE XS": "ANKLE BRACE/HIGH PERFORMNCE XS",
              "ANKLE SPLINT/CANVAS LARGE": "ANKLE SPLINT/CANVAS LARGE",
              "ANKLE SPLINT/CANVAS MEDIUM": "ANKLE SPLINT/CANVAS MEDIUM",
              "ANKLE SPLINT/CANVAS SMALL": "ANKLE SPLINT/CANVAS SMALL",
              "ANKLE SPLINT/CANVAS XL": "ANKLE SPLINT/CANVAS XL",
              "ANKLE SPLINT/CANVAS XS": "ANKLE SPLINT/CANVAS XS",
              "ANKLE SPLINT/NIGHT AIRFORM": "ANKLE SPLINT/NIGHT AIRFORM",
              "ANKLE STABILIZER": "ANKLE STABILIZER",
              "ANKLE STABILIZER 2XL": "ANKLE STABILIZER 2XL",
              "ANKLE STABILIZER LARGE": "ANKLE STABILIZER LARGE",
              "ANKLE STABILIZER MEDIUM": "ANKLE STABILIZER MEDIUM",
              "ANKLE STABILIZER SMALL": "ANKLE STABILIZER SMALL",
              "ANKLE STABILIZER XL": "ANKLE STABILIZER XL",
              "ANKLE STABILIZER XS": "ANKLE STABILIZER XS",
              "ANKLE STIRRUP BRACE/LEFT": "ANKLE STIRRUP BRACE/LEFT",
              "ANKLE STIRRUP BRACE/PONY/LEFT": "ANKLE STIRRUP BRACE/PONY/LEFT",
              "ANKLE STIRRUP BRACE/PONY/RIGHT": "ANKLE STIRRUP BRACE/PONY/RIGHT",
              "ANKLE STIRRUP BRACE/RIGHT": "ANKLE STIRRUP BRACE/RIGHT",
              "ANKLE SUPPORT": "ANKLE SUPPORT",
              "ANKLE SUPPORT/ELASTIC/FIRM LG": "ANKLE SUPPORT/ELASTIC/FIRM LG",
              "ANKLE SUPPORT/ELASTIC/FIRM MED": "ANKLE SUPPORT/ELASTIC/FIRM MED",
              "ANKLE SUPPORT/ELASTIC/FIRM SM": "ANKLE SUPPORT/ELASTIC/FIRM SM",
              "ANKLE SUPPORT/ELASTIC/FIRM XL": "ANKLE SUPPORT/ELASTIC/FIRM XL",
              "ANKLE SUPPORT/ELASTIC/SHEER LG": "ANKLE SUPPORT/ELASTIC/SHEER LG",
              "ANKLE SUPPORT/ELASTIC/SHEER M": "ANKLE SUPPORT/ELASTIC/SHEER M",
              "ANKLE SUPPORT/ELASTIC/SHEER SM": "ANKLE SUPPORT/ELASTIC/SHEER SM",
              "ANKLE SUPPORT/ELASTIC/SHEER XL": "ANKLE SUPPORT/ELASTIC/SHEER XL",
              "ANKLE SUPPORT/FIGURE-8 LARGE": "ANKLE SUPPORT/FIGURE-8 LARGE",
              "ANKLE SUPPORT/FIGURE-8 MEDIUM": "ANKLE SUPPORT/FIGURE-8 MEDIUM",
              "ANKLE SUPPORT/FIGURE-8 SMALL": "ANKLE SUPPORT/FIGURE-8 SMALL",
              "ANKLE SUPPORT/FIGURE-8 VL": "ANKLE SUPPORT/FIGURE-8 VL",
              "ANKLE SUPPORT/FIGURE-8 XL": "ANKLE SUPPORT/FIGURE-8 XL",
              "ANKLE SUPPORT/SLIP ON/PED": "ANKLE SUPPORT/SLIP ON/PED",
              "ANKLE SUPPORT/SLIP ON/YOUTH": "ANKLE SUPPORT/SLIP ON/YOUTH",
              "ANKLE SUPPORT/SLIP-ON LARGE": "ANKLE SUPPORT/SLIP-ON LARGE",
              "ANKLE SUPPORT/SLIP-ON MEDIUM": "ANKLE SUPPORT/SLIP-ON MEDIUM",
              "ANKLE SUPPORT/SLIP-ON SMALL": "ANKLE SUPPORT/SLIP-ON SMALL",
              "ANKLE SUPPORT/SLIP-ON XL": "ANKLE SUPPORT/SLIP-ON XL",
              "ANKLE SUPPORT/SPIRAL STAYS LG": "ANKLE SUPPORT/SPIRAL STAYS LG",
              "ANKLE SUPPORT/SPIRAL STAYS MED": "ANKLE SUPPORT/SPIRAL STAYS MED",
              "ANKLE SUPPORT/SPIRAL STAYS SM": "ANKLE SUPPORT/SPIRAL STAYS SM",
              "ANKLE SUPPORT/SPIRAL STAYS XL": "ANKLE SUPPORT/SPIRAL STAYS XL",
              "ANKLE SUPPORT/SPIRAL STAYS XS": "ANKLE SUPPORT/SPIRAL STAYS XS",
              "ANKLE SUPPORT/VISCOELASTIC LG": "ANKLE SUPPORT/VISCOELASTIC LG",
              "ANKLE SUPPORT/VISCOELASTIC MED": "ANKLE SUPPORT/VISCOELASTIC MED",
              "ANKLE SUPPORT/VISCOELASTIC SM": "ANKLE SUPPORT/VISCOELASTIC SM",
              "ANKLE SUPPORT/VISCOELASTIC XL": "ANKLE SUPPORT/VISCOELASTIC XL",
              "ANKLE SUPPORT/WRAPAROUND LG": "ANKLE SUPPORT/WRAPAROUND LG",
              "ANKLE SUPPORT/WRAPAROUND MED": "ANKLE SUPPORT/WRAPAROUND MED",
              "ANKLE SUPPORT/WRAPAROUND SMALL": "ANKLE SUPPORT/WRAPAROUND SMALL",
              "ANKLE SUPPORT/WRAPAROUND XL": "ANKLE SUPPORT/WRAPAROUND XL",
              "ARTHRITIS GLOVE LARGE": "ARTHRITIS GLOVE LARGE",
              "ARTHRITIS GLOVE MEDIUM": "ARTHRITIS GLOVE MEDIUM",
              "ARTHRITIS GLOVE SMALL": "ARTHRITIS GLOVE SMALL",
              "ARTHRITIS GLOVE XL": "ARTHRITIS GLOVE XL",
              "ARTHRITIS GLOVE XSMALL": "ARTHRITIS GLOVE XSMALL",
              "BAND-IT FOREARM BAND": "BAND-IT FOREARM BAND",
              "BILATERAL HERNIA SUPPORT LARGE": "BILATERAL HERNIA SUPPORT LARGE",
              "BILATERAL HERNIA SUPPORT MED": "BILATERAL HERNIA SUPPORT MED",
              "BILATERAL HERNIA SUPPORT SMALL": "BILATERAL HERNIA SUPPORT SMALL",
              "BILATERAL HERNIA SUPPORT XL": "BILATERAL HERNIA SUPPORT XL",
              "CAST PROTECTOR/FULL-ARM ADULT": "CAST PROTECTOR/FULL-ARM ADULT",
              "CAST PROTECTOR/FULL-ARM YOUTH": "CAST PROTECTOR/FULL-ARM YOUTH",
              "CAST PROTECTOR/FULL-LEG ADULT": "CAST PROTECTOR/FULL-LEG ADULT",
              "CAST PROTECTOR/FULL-LEG YOUTH": "CAST PROTECTOR/FULL-LEG YOUTH",
              "CAST PROTECTOR/HALF-ARM ADULT": "CAST PROTECTOR/HALF-ARM ADULT",
              "CAST PROTECTOR/HALF-ARM YOUTH": "CAST PROTECTOR/HALF-ARM YOUTH",
              "CAST PROTECTOR/HALF-LEG ADULT": "CAST PROTECTOR/HALF-LEG ADULT",
              "CAST PROTECTOR/HALF-LEG YOUTH": "CAST PROTECTOR/HALF-LEG YOUTH",
              "CAST SHOE INFANT": "CAST SHOE INFANT",
              "CAST SHOE PEDIATRIC": "CAST SHOE PEDIATRIC",
              "CERVICAL COLLAR INFANT": "CERVICAL COLLAR INFANT",
              "CERVICAL COLLAR PEDIATRIC": "CERVICAL COLLAR PEDIATRIC",
              "CERVICAL COLLAR YOUTH": "CERVICAL COLLAR YOUTH",
              "CERVICAL COLLAR/AVERAGE LARGE": "CERVICAL COLLAR/AVERAGE LARGE",
              "CERVICAL COLLAR/AVERAGE MEDIUM": "CERVICAL COLLAR/AVERAGE MEDIUM",
              "CERVICAL COLLAR/AVERAGE SMALL": "CERVICAL COLLAR/AVERAGE SMALL",
              "CERVICAL COLLAR/AVERAGE UNIV": "CERVICAL COLLAR/AVERAGE UNIV",
              "CERVICAL COLLAR/AVERAGE XL": "CERVICAL COLLAR/AVERAGE XL",
              "CERVICAL COLLAR/AVERAGE XS": "CERVICAL COLLAR/AVERAGE XS",
              "CERVICAL COLLAR/NARROW LARGE": "CERVICAL COLLAR/NARROW LARGE",
              "CERVICAL COLLAR/NARROW MEDIUM": "CERVICAL COLLAR/NARROW MEDIUM",
              "CERVICAL COLLAR/NARROW SMALL": "CERVICAL COLLAR/NARROW SMALL",
              "CERVICAL COLLAR/NARROW UNIV": "CERVICAL COLLAR/NARROW UNIV",
              "CERVICAL COLLAR/NARROW XL": "CERVICAL COLLAR/NARROW XL",
              "CERVICAL COLLAR/NARROW XS": "CERVICAL COLLAR/NARROW XS",
              "CERVICAL COLLAR/WIDE LARGE": "CERVICAL COLLAR/WIDE LARGE",
              "CERVICAL COLLAR/WIDE MEDIUM": "CERVICAL COLLAR/WIDE MEDIUM",
              "CERVICAL COLLAR/WIDE SMALL": "CERVICAL COLLAR/WIDE SMALL",
              "CERVICAL COLLAR/WIDE UNIVERSAL": "CERVICAL COLLAR/WIDE UNIVERSAL",
              "CERVICAL COLLAR/WIDE XL": "CERVICAL COLLAR/WIDE XL",
              "CERVICAL COLLAR/WIDE XS": "CERVICAL COLLAR/WIDE XS",
              "CLAVICLE STRAP/FIGURE-8 LARGE": "CLAVICLE STRAP/FIGURE-8 LARGE",
              "CLAVICLE STRAP/FIGURE-8 MEDIUM": "CLAVICLE STRAP/FIGURE-8 MEDIUM",
              "CLAVICLE STRAP/FIGURE-8 SMALL": "CLAVICLE STRAP/FIGURE-8 SMALL",
              "CLAVICLE STRAP/FIGURE-8 XL": "CLAVICLE STRAP/FIGURE-8 XL",
              "CLAVICLE STRAP/FIGURE-8 XS": "CLAVICLE STRAP/FIGURE-8 XS",
              "COMFORT FIT MATERNITY SUPP LG": "COMFORT FIT MATERNITY SUPP LG",
              "COMFORT FIT MATERNITY SUPP MED": "COMFORT FIT MATERNITY SUPP MED",
              "COMFORT FIT MATERNITY SUPP SM": "COMFORT FIT MATERNITY SUPP SM",
              "CRADLE ARM SLING": "CRADLE ARM SLING",
              "CRADLE ARM SLING INFANT": "CRADLE ARM SLING INFANT",
              "CRADLE ARM SLING PEDIATRIC": "CRADLE ARM SLING PEDIATRIC",
              "CRADLE ARM SLING YOUTH": "CRADLE ARM SLING YOUTH",
              "ELASTIC BANDAGE 2\"\"": "ELASTIC BANDAGE 2\"\"",
              "ELASTIC BANDAGE 3\"\"": "ELASTIC BANDAGE 3\"\"",
              "ELASTIC BANDAGE 4\"\"": "ELASTIC BANDAGE 4\"\"",
              "ELBOW SUPPORT": "ELBOW SUPPORT",
              "ELBOW SUPPORT/COUNTOUR LG": "ELBOW SUPPORT/COUNTOUR LG",
              "ELBOW SUPPORT/COUNTOUR MED": "ELBOW SUPPORT/COUNTOUR MED",
              "ELBOW SUPPORT/COUNTOUR SMALL": "ELBOW SUPPORT/COUNTOUR SMALL",
              "ELBOW SUPPORT/COUNTOUR XL": "ELBOW SUPPORT/COUNTOUR XL",
              "ELBOW SUPPORT/FIRM LARGE": "ELBOW SUPPORT/FIRM LARGE",
              "ELBOW SUPPORT/FIRM MEDIUM": "ELBOW SUPPORT/FIRM MEDIUM",
              "ELBOW SUPPORT/FIRM SMALL": "ELBOW SUPPORT/FIRM SMALL",
              "ELBOW SUPPORT/FIRM XL": "ELBOW SUPPORT/FIRM XL",
              "ELBOW SUPPORT/NEOPRENE LARGE": "ELBOW SUPPORT/NEOPRENE LARGE",
              "ELBOW SUPPORT/NEOPRENE MEDIUM": "ELBOW SUPPORT/NEOPRENE MEDIUM",
              "ELBOW SUPPORT/NEOPRENE SMALL": "ELBOW SUPPORT/NEOPRENE SMALL",
              "ELBOW SUPPORT/NEOPRENE XL": "ELBOW SUPPORT/NEOPRENE XL",
              "ELBOW SUPPORT/VISCOELASTIC LG": "ELBOW SUPPORT/VISCOELASTIC LG",
              "ELBOW SUPPORT/VISCOELASTIC MED": "ELBOW SUPPORT/VISCOELASTIC MED",
              "ELBOW SUPPORT/VISCOELASTIC SM": "ELBOW SUPPORT/VISCOELASTIC SM",
              "ELBOW SUPPORT/VISCOELASTIC XL": "ELBOW SUPPORT/VISCOELASTIC XL",
              "EXOLITE WRIST BRACE LEFT": "EXOLITE WRIST BRACE LEFT",
              "EXOLITE WRIST BRACE RIGHT": "EXOLITE WRIST BRACE RIGHT",
              "GAIT/TRANSFER BELT": "GAIT/TRANSFER BELT",
              "GARTER BELT 2\"\" WOMENS LARGE": "GARTER BELT 2\"\" WOMENS LARGE",
              "GARTER BELT 2\"\" WOMENS MEDIUM": "GARTER BELT 2\"\" WOMENS MEDIUM",
              "GARTER BELT 2\"\" WOMENS SMALL": "GARTER BELT 2\"\" WOMENS SMALL",
              "GARTER BELT 2\"\" WOMENS XL": "GARTER BELT 2\"\" WOMENS XL",
              "GARTER BELT 3\"\" LARGE": "GARTER BELT 3\"\" LARGE",
              "GARTER BELT 3\"\" MEDIUM": "GARTER BELT 3\"\" MEDIUM",
              "GARTER BELT 3\"\" MEDIUM-LARGE": "GARTER BELT 3\"\" MEDIUM-LARGE",
              "GARTER BELT 3\"\" SMALL": "GARTER BELT 3\"\" SMALL",
              "GARTER BELT 3\"\" XL": "GARTER BELT 3\"\" XL",
              "GARTER BELT 3\"\" XS": "GARTER BELT 3\"\" XS",
              "GARTER BELT 6\"\" LARGE": "GARTER BELT 6\"\" LARGE",
              "GARTER BELT 6\"\" MEDIUM": "GARTER BELT 6\"\" MEDIUM",
              "GARTER BELT 6\"\" MEDIUM-LARGE": "GARTER BELT 6\"\" MEDIUM-LARGE",
              "GARTER BELT 6\"\" SMALL": "GARTER BELT 6\"\" SMALL",
              "GARTER BELT 6\"\" X-LARGE": "GARTER BELT 6\"\" X-LARGE",
              "GARTER BELT 6\"\" X-SMALL": "GARTER BELT 6\"\" X-SMALL",
              "HERNIA BELT DOUBLE LARGE": "HERNIA BELT DOUBLE LARGE",
              "HERNIA BELT DOUBLE MEDIUM": "HERNIA BELT DOUBLE MEDIUM",
              "HERNIA BELT DOUBLE SMALL": "HERNIA BELT DOUBLE SMALL",
              "HERNIA BELT DOUBLE XL": "HERNIA BELT DOUBLE XL",
              "HERNIA SUPPORT LEFT LARGE": "HERNIA SUPPORT LEFT LARGE",
              "HERNIA SUPPORT LEFT MEDIUM": "HERNIA SUPPORT LEFT MEDIUM",
              "HERNIA SUPPORT LEFT SMALL": "HERNIA SUPPORT LEFT SMALL",
              "HERNIA SUPPORT LEFT XL": "HERNIA SUPPORT LEFT XL",
              "HERNIA SUPPORT RIGHT LARGE": "HERNIA SUPPORT RIGHT LARGE",
              "HERNIA SUPPORT RIGHT MEDIUM": "HERNIA SUPPORT RIGHT MEDIUM",
              "HERNIA SUPPORT RIGHT SMALL": "HERNIA SUPPORT RIGHT SMALL",
              "HERNIA SUPPORT RIGHT XL": "HERNIA SUPPORT RIGHT XL",
              "INDUSTRIAL BELT EX SM": "INDUSTRIAL BELT EX SM",
              "INDUSTRIAL BELT REGULAR": "INDUSTRIAL BELT REGULAR",
              "INDUSTRIAL BELT XL": "INDUSTRIAL BELT XL",
              "INDUSTRIAL BELT XXL": "INDUSTRIAL BELT XXL",
              "INDUSTRIAL BELT/SHOULDER STRAP": "INDUSTRIAL BELT/SHOULDER STRAP",
              "KNEE BRACE/FLEX STAYS LARGE": "KNEE BRACE/FLEX STAYS LARGE",
              "KNEE BRACE/FLEX STAYS MEDIUM": "KNEE BRACE/FLEX STAYS MEDIUM",
              "KNEE BRACE/FLEX STAYS SMALL": "KNEE BRACE/FLEX STAYS SMALL",
              "KNEE BRACE/FLEX STAYS XL": "KNEE BRACE/FLEX STAYS XL",
              "KNEE BRACE/HINGED BARS 2XL": "KNEE BRACE/HINGED BARS 2XL",
              "KNEE BRACE/HINGED BARS 3XL": "KNEE BRACE/HINGED BARS 3XL",
              "KNEE BRACE/HINGED BARS LARGE": "KNEE BRACE/HINGED BARS LARGE",
              "KNEE BRACE/HINGED BARS MEDIUM": "KNEE BRACE/HINGED BARS MEDIUM",
              "KNEE BRACE/HINGED BARS SMALL": "KNEE BRACE/HINGED BARS SMALL",
              "KNEE BRACE/HINGED BARS XL": "KNEE BRACE/HINGED BARS XL",
              "KNEE BRACE/HINGED BARS XS": "KNEE BRACE/HINGED BARS XS",
              "KNEE BRACE/HOR-SHU SUPP PAD LG": "KNEE BRACE/HOR-SHU SUPP PAD LG",
              "KNEE BRACE/HOR-SHU SUPP PAD M": "KNEE BRACE/HOR-SHU SUPP PAD M",
              "KNEE BRACE/HOR-SHU SUPP PAD SM": "KNEE BRACE/HOR-SHU SUPP PAD SM",
              "KNEE BRACE/HOR-SHU SUPP PAD XL": "KNEE BRACE/HOR-SHU SUPP PAD XL",
              "KNEE SLEEVE/KIDS LARGE": "KNEE SLEEVE/KIDS LARGE",
              "KNEE SLEEVE/KIDS MEDIUM": "KNEE SLEEVE/KIDS MEDIUM",
              "KNEE SLEEVE/KIDS SMALL": "KNEE SLEEVE/KIDS SMALL",
              "KNEE STABILIZER W/HINGED BARS": "KNEE STABILIZER W/HINGED BARS",
              "KNEE STABILIZER WRAP 2XL": "KNEE STABILIZER WRAP 2XL",
              "KNEE STABILIZER WRAP 3XL": "KNEE STABILIZER WRAP 3XL",
              "KNEE STABILIZER WRAP 4XL": "KNEE STABILIZER WRAP 4XL",
              "KNEE STABILIZER WRAP 5XL": "KNEE STABILIZER WRAP 5XL",
              "KNEE STABILIZER WRAP LARGE": "KNEE STABILIZER WRAP LARGE",
              "KNEE STABILIZER WRAP MEDIUM": "KNEE STABILIZER WRAP MEDIUM",
              "KNEE STABILIZER WRAP SMALL": "KNEE STABILIZER WRAP SMALL",
              "KNEE STABILIZER WRAP XL": "KNEE STABILIZER WRAP XL",
              "KNEE STABILIZER WRAP XS": "KNEE STABILIZER WRAP XS",
              "KNEE STABILIZER/SPIRAL STAYS": "KNEE STABILIZER/SPIRAL STAYS",
              "KNEE SUPPORT": "KNEE SUPPORT",
              "KNEE SUPPORT W/STABILIZER PAD": "KNEE SUPPORT W/STABILIZER PAD",
              "KNEE SUPPORT/CONTOUR LARGE": "KNEE SUPPORT/CONTOUR LARGE",
              "KNEE SUPPORT/CONTOUR MEDIUM": "KNEE SUPPORT/CONTOUR MEDIUM",
              "KNEE SUPPORT/CONTOUR SMALL": "KNEE SUPPORT/CONTOUR SMALL",
              "KNEE SUPPORT/CONTOUR V-LARGE": "KNEE SUPPORT/CONTOUR V-LARGE",
              "KNEE SUPPORT/CONTOUR XL": "KNEE SUPPORT/CONTOUR XL",
              "KNEE SUPPORT/ELASTIC/FIRM LG": "KNEE SUPPORT/ELASTIC/FIRM LG",
              "KNEE SUPPORT/ELASTIC/FIRM MED": "KNEE SUPPORT/ELASTIC/FIRM MED",
              "KNEE SUPPORT/ELASTIC/FIRM SM": "KNEE SUPPORT/ELASTIC/FIRM SM",
              "KNEE SUPPORT/ELASTIC/FIRM XL": "KNEE SUPPORT/ELASTIC/FIRM XL",
              "KNEE SUPPORT/ELASTIC/SHEER LG": "KNEE SUPPORT/ELASTIC/SHEER LG",
              "KNEE SUPPORT/ELASTIC/SHEER MED": "KNEE SUPPORT/ELASTIC/SHEER MED",
              "KNEE SUPPORT/ELASTIC/SHEER SM": "KNEE SUPPORT/ELASTIC/SHEER SM",
              "KNEE SUPPORT/ELASTIC/SHEER XL": "KNEE SUPPORT/ELASTIC/SHEER XL",
              "KNEE SUPPORT/HINGED BARS LG": "KNEE SUPPORT/HINGED BARS LG",
              "KNEE SUPPORT/HINGED BARS MED": "KNEE SUPPORT/HINGED BARS MED",
              "KNEE SUPPORT/HINGED BARS SM": "KNEE SUPPORT/HINGED BARS SM",
              "KNEE SUPPORT/HINGED BARS XL": "KNEE SUPPORT/HINGED BARS XL",
              "KNEE SUPPORT/HOR-SHU STABILIZE": "KNEE SUPPORT/HOR-SHU STABILIZE",
              "KNEE SUPPORT/NEOPRENE/HOR-SHU": "KNEE SUPPORT/NEOPRENE/HOR-SHU",
              "KNEE SUPPORT/NEOPRENE/OVAL LG": "KNEE SUPPORT/NEOPRENE/OVAL LG",
              "KNEE SUPPORT/NEOPRENE/OVAL MED": "KNEE SUPPORT/NEOPRENE/OVAL MED",
              "KNEE SUPPORT/NEOPRENE/OVAL SM": "KNEE SUPPORT/NEOPRENE/OVAL SM",
              "KNEE SUPPORT/NEOPRENE/OVAL XL": "KNEE SUPPORT/NEOPRENE/OVAL XL",
              "KNEE SUPPORT/OPEN PATELLA 4-XL": "KNEE SUPPORT/OPEN PATELLA 4-XL",
              "KNEE SUPPORT/OPEN PATELLA LG": "KNEE SUPPORT/OPEN PATELLA LG",
              "KNEE SUPPORT/OPEN PATELLA MED": "KNEE SUPPORT/OPEN PATELLA MED",
              "KNEE SUPPORT/OPEN PATELLA SM": "KNEE SUPPORT/OPEN PATELLA SM",
              "KNEE SUPPORT/OPEN PATELLA XL": "KNEE SUPPORT/OPEN PATELLA XL",
              "KNEE SUPPORT/OPEN PATELLA XXL": "KNEE SUPPORT/OPEN PATELLA XXL",
              "KNEE SUPPORT/OPEN PATELLA XXXL": "KNEE SUPPORT/OPEN PATELLA XXXL",
              "KNEE SUPPORT/PRESSURE PADS 2XL": "KNEE SUPPORT/PRESSURE PADS 2XL",
              "KNEE SUPPORT/PRESSURE PADS 3XL": "KNEE SUPPORT/PRESSURE PADS 3XL",
              "KNEE SUPPORT/PRESSURE PADS LG": "KNEE SUPPORT/PRESSURE PADS LG",
              "KNEE SUPPORT/PRESSURE PADS MED": "KNEE SUPPORT/PRESSURE PADS MED",
              "KNEE SUPPORT/PRESSURE PADS SM": "KNEE SUPPORT/PRESSURE PADS SM",
              "KNEE SUPPORT/PRESSURE PADS XL": "KNEE SUPPORT/PRESSURE PADS XL",
              "KNEE SUPPORT/PRESSURE PADS XS": "KNEE SUPPORT/PRESSURE PADS XS",
              "KNEE SUPPORT/SPIRAL STAYS LG": "KNEE SUPPORT/SPIRAL STAYS LG",
              "KNEE SUPPORT/SPIRAL STAYS MED": "KNEE SUPPORT/SPIRAL STAYS MED",
              "KNEE SUPPORT/SPIRAL STAYS SM": "KNEE SUPPORT/SPIRAL STAYS SM",
              "KNEE SUPPORT/SPIRAL STAYS XL": "KNEE SUPPORT/SPIRAL STAYS XL",
              "KNEE SUPPORT/VISCOELASTIC LG": "KNEE SUPPORT/VISCOELASTIC LG",
              "KNEE SUPPORT/VISCOELASTIC MED": "KNEE SUPPORT/VISCOELASTIC MED",
              "KNEE SUPPORT/VISCOELASTIC SM": "KNEE SUPPORT/VISCOELASTIC SM",
              "KNEE SUPPORT/VISCOELASTIC XL": "KNEE SUPPORT/VISCOELASTIC XL",
              "KNEE WRAP/PATELLAR STABLIZING": "KNEE WRAP/PATELLAR STABLIZING",
              "KNEED-IT KNEE GUARD": "KNEED-IT KNEE GUARD",
              "LUMBOSACRAL SUPP ABDOMINAL 2XL": "LUMBOSACRAL SUPP ABDOMINAL 2XL",
              "LUMBOSACRAL SUPP ABDOMINAL L": "LUMBOSACRAL SUPP ABDOMINAL L",
              "LUMBOSACRAL SUPP ABDOMINAL M": "LUMBOSACRAL SUPP ABDOMINAL M",
              "LUMBOSACRAL SUPP ABDOMINAL S": "LUMBOSACRAL SUPP ABDOMINAL S",
              "LUMBOSACRAL SUPP ABDOMINAL UNI": "LUMBOSACRAL SUPP ABDOMINAL UNI",
              "LUMBOSACRAL SUPP ABDOMINAL XL": "LUMBOSACRAL SUPP ABDOMINAL XL",
              "LUMBOSACRAL SUPP ABDOMINAL XS": "LUMBOSACRAL SUPP ABDOMINAL XS",
              "LUMBOSACRAL SUPP ELASTIC 2XL": "LUMBOSACRAL SUPP ELASTIC 2XL",
              "LUMBOSACRAL SUPP ELASTIC UNIV": "LUMBOSACRAL SUPP ELASTIC UNIV",
              "LUMBOSACRAL SUPP LIGHTWEIGHT L": "LUMBOSACRAL SUPP LIGHTWEIGHT L",
              "LUMBOSACRAL SUPP LIGHTWEIGHT M": "LUMBOSACRAL SUPP LIGHTWEIGHT M",
              "LUMBOSACRAL SUPP LIGHTWEIGHT S": "LUMBOSACRAL SUPP LIGHTWEIGHT S",
              "LUMBOSACRAL SUPP LIGHTWEIGHT U": "LUMBOSACRAL SUPP LIGHTWEIGHT U",
              "LUMBOSACRAL SUPP LIGHTWGHT XL": "LUMBOSACRAL SUPP LIGHTWGHT XL",
              "LUMBOSACRAL SUPP PETITE UNIV": "LUMBOSACRAL SUPP PETITE UNIV",
              "LUMBOSACRAL SUPPORT ELASTIC L": "LUMBOSACRAL SUPPORT ELASTIC L",
              "LUMBOSACRAL SUPPORT ELASTIC M": "LUMBOSACRAL SUPPORT ELASTIC M",
              "LUMBOSACRAL SUPPORT ELASTIC S": "LUMBOSACRAL SUPPORT ELASTIC S",
              "LUMBOSACRAL SUPPORT ELASTIC XL": "LUMBOSACRAL SUPPORT ELASTIC XL",
              "LUMBOSACRAL SUPPORT PETITE": "LUMBOSACRAL SUPPORT PETITE",
              "LUMBOSACRAL SUPPORT PETITE L": "LUMBOSACRAL SUPPORT PETITE L",
              "LUMBOSACRAL SUPPORT PETITE M": "LUMBOSACRAL SUPPORT PETITE M",
              "LUMBOSACRAL SUPPORT PETITE S": "LUMBOSACRAL SUPPORT PETITE S",
              "LUMBOTEK LUMBOSACRAL SUPP 2XL": "LUMBOTEK LUMBOSACRAL SUPP 2XL",
              "LUMBOTEK LUMBOSACRAL SUPP 3XL": "LUMBOTEK LUMBOSACRAL SUPP 3XL",
              "LUMBOTEK LUMBOSACRAL SUPP L": "LUMBOTEK LUMBOSACRAL SUPP L",
              "LUMBOTEK LUMBOSACRAL SUPP M": "LUMBOTEK LUMBOSACRAL SUPP M",
              "LUMBOTEK LUMBOSACRAL SUPP S": "LUMBOTEK LUMBOSACRAL SUPP S",
              "LUMBOTEK LUMBOSACRAL SUPP XL": "LUMBOTEK LUMBOSACRAL SUPP XL",
              "LUMBOTEK2 LUMBOSAC SUPP 2XL": "LUMBOTEK2 LUMBOSAC SUPP 2XL",
              "LUMBOTEK2 LUMBOSAC SUPP 3XL": "LUMBOTEK2 LUMBOSAC SUPP 3XL",
              "LUMBOTEK2 LUMBOSAC SUPP L": "LUMBOTEK2 LUMBOSAC SUPP L",
              "LUMBOTEK2 LUMBOSAC SUPP M": "LUMBOTEK2 LUMBOSAC SUPP M",
              "LUMBOTEK2 LUMBOSAC SUPP S": "LUMBOTEK2 LUMBOSAC SUPP S",
              "LUMBOTEK2 LUMBOSAC SUPP XL": "LUMBOTEK2 LUMBOSAC SUPP XL",
              "LUMBOTEK3 LUMBOSAC SUPP 2XL": "LUMBOTEK3 LUMBOSAC SUPP 2XL",
              "LUMBOTEK3 LUMBOSAC SUPP 3XL": "LUMBOTEK3 LUMBOSAC SUPP 3XL",
              "LUMBOTEK3 LUMBOSAC SUPP L": "LUMBOTEK3 LUMBOSAC SUPP L",
              "LUMBOTEK3 LUMBOSAC SUPP M": "LUMBOTEK3 LUMBOSAC SUPP M",
              "LUMBOTEK3 LUMBOSAC SUPP S": "LUMBOTEK3 LUMBOSAC SUPP S",
              "LUMBOTEK3 LUMBOSAC SUPP XL": "LUMBOTEK3 LUMBOSAC SUPP XL",
              "NEOPRENE ANKLE WRAP": "NEOPRENE ANKLE WRAP",
              "NEOPRENE KNEE WRAP/STABILIZER": "NEOPRENE KNEE WRAP/STABILIZER",
              "NEOPRENE WRIST SUPPORT": "NEOPRENE WRIST SUPPORT",
              "OCCUPATIONAL WRIST SUPPRT/L LG": "OCCUPATIONAL WRIST SUPPRT/L LG",
              "OCCUPATIONAL WRIST SUPPRT/L MD": "OCCUPATIONAL WRIST SUPPRT/L MD",
              "OCCUPATIONAL WRIST SUPPRT/L SM": "OCCUPATIONAL WRIST SUPPRT/L SM",
              "OCCUPATIONAL WRIST SUPPRT/L XL": "OCCUPATIONAL WRIST SUPPRT/L XL",
              "OCCUPATIONAL WRIST SUPPRT/L XS": "OCCUPATIONAL WRIST SUPPRT/L XS",
              "OCCUPATIONAL WRIST SUPPRT/R LG": "OCCUPATIONAL WRIST SUPPRT/R LG",
              "OCCUPATIONAL WRIST SUPPRT/R MD": "OCCUPATIONAL WRIST SUPPRT/R MD",
              "OCCUPATIONAL WRIST SUPPRT/R SM": "OCCUPATIONAL WRIST SUPPRT/R SM",
              "OCCUPATIONAL WRIST SUPPRT/R XL": "OCCUPATIONAL WRIST SUPPRT/R XL",
              "OCCUPATIONAL WRIST SUPPRT/R XS": "OCCUPATIONAL WRIST SUPPRT/R XS",
              "ORTHOTEX KNEE WRAP/HINGED 2XL": "ORTHOTEX KNEE WRAP/HINGED 2XL",
              "ORTHOTEX KNEE WRAP/HINGED 3XL": "ORTHOTEX KNEE WRAP/HINGED 3XL",
              "ORTHOTEX KNEE WRAP/HINGED 4XL": "ORTHOTEX KNEE WRAP/HINGED 4XL",
              "ORTHOTEX KNEE WRAP/HINGED 5XL": "ORTHOTEX KNEE WRAP/HINGED 5XL",
              "ORTHOTEX KNEE WRAP/HINGED LG": "ORTHOTEX KNEE WRAP/HINGED LG",
              "ORTHOTEX KNEE WRAP/HINGED MED": "ORTHOTEX KNEE WRAP/HINGED MED",
              "ORTHOTEX KNEE WRAP/HINGED SM": "ORTHOTEX KNEE WRAP/HINGED SM",
              "ORTHOTEX KNEE WRAP/HINGED XL": "ORTHOTEX KNEE WRAP/HINGED XL",
              "ORTHOTEX KNEE WRAP/HINGED XS": "ORTHOTEX KNEE WRAP/HINGED XS",
              "ORTHOTEX KNEE WRAP/SPIRAL 2XL": "ORTHOTEX KNEE WRAP/SPIRAL 2XL",
              "ORTHOTEX KNEE WRAP/SPIRAL 3XL": "ORTHOTEX KNEE WRAP/SPIRAL 3XL",
              "ORTHOTEX KNEE WRAP/SPIRAL 4XL": "ORTHOTEX KNEE WRAP/SPIRAL 4XL",
              "ORTHOTEX KNEE WRAP/SPIRAL 5XL": "ORTHOTEX KNEE WRAP/SPIRAL 5XL",
              "ORTHOTEX KNEE WRAP/SPIRAL LG": "ORTHOTEX KNEE WRAP/SPIRAL LG",
              "ORTHOTEX KNEE WRAP/SPIRAL MED": "ORTHOTEX KNEE WRAP/SPIRAL MED",
              "ORTHOTEX KNEE WRAP/SPIRAL SM": "ORTHOTEX KNEE WRAP/SPIRAL SM",
              "ORTHOTEX KNEE WRAP/SPIRAL XL": "ORTHOTEX KNEE WRAP/SPIRAL XL",
              "ORTHOTEX KNEE WRAP/SPIRAL XS": "ORTHOTEX KNEE WRAP/SPIRAL XS",
              "ORTHOTEX KNEE/HINGED BARS 2XL": "ORTHOTEX KNEE/HINGED BARS 2XL",
              "ORTHOTEX KNEE/HINGED BARS 3XL": "ORTHOTEX KNEE/HINGED BARS 3XL",
              "ORTHOTEX KNEE/HINGED BARS 4XL": "ORTHOTEX KNEE/HINGED BARS 4XL",
              "ORTHOTEX KNEE/HINGED BARS LG": "ORTHOTEX KNEE/HINGED BARS LG",
              "ORTHOTEX KNEE/HINGED BARS MED": "ORTHOTEX KNEE/HINGED BARS MED",
              "ORTHOTEX KNEE/HINGED BARS SM": "ORTHOTEX KNEE/HINGED BARS SM",
              "ORTHOTEX KNEE/HINGED BARS XL": "ORTHOTEX KNEE/HINGED BARS XL",
              "ORTHOTEX KNEE/SPIRAL STAYS 2XL": "ORTHOTEX KNEE/SPIRAL STAYS 2XL",
              "ORTHOTEX KNEE/SPIRAL STAYS 3XL": "ORTHOTEX KNEE/SPIRAL STAYS 3XL",
              "ORTHOTEX KNEE/SPIRAL STAYS 4XL": "ORTHOTEX KNEE/SPIRAL STAYS 4XL",
              "ORTHOTEX KNEE/SPIRAL STAYS LG": "ORTHOTEX KNEE/SPIRAL STAYS LG",
              "ORTHOTEX KNEE/SPIRAL STAYS MED": "ORTHOTEX KNEE/SPIRAL STAYS MED",
              "ORTHOTEX KNEE/SPIRAL STAYS SM": "ORTHOTEX KNEE/SPIRAL STAYS SM",
              "ORTHOTEX KNEE/SPIRAL STAYS XL": "ORTHOTEX KNEE/SPIRAL STAYS XL",
              "ORTHOTEX KNEE/SPIRAL STAYS XS": "ORTHOTEX KNEE/SPIRAL STAYS XS",
              "ORTHOTEX KNEE/STABLZR PAD 2XL": "ORTHOTEX KNEE/STABLZR PAD 2XL",
              "ORTHOTEX KNEE/STABLZR PAD 3XL": "ORTHOTEX KNEE/STABLZR PAD 3XL",
              "ORTHOTEX KNEE/STABLZR PAD 4XL": "ORTHOTEX KNEE/STABLZR PAD 4XL",
              "ORTHOTEX KNEE/STABLZR PAD LG": "ORTHOTEX KNEE/STABLZR PAD LG",
              "ORTHOTEX KNEE/STABLZR PAD MED": "ORTHOTEX KNEE/STABLZR PAD MED",
              "ORTHOTEX KNEE/STABLZR PAD SM": "ORTHOTEX KNEE/STABLZR PAD SM",
              "ORTHOTEX KNEE/STABLZR PAD XL": "ORTHOTEX KNEE/STABLZR PAD XL",
              "ORTHOTEX STABILIZER WRAP/L 2XL": "ORTHOTEX STABILIZER WRAP/L 2XL",
              "ORTHOTEX STABILIZER WRAP/L 3XL": "ORTHOTEX STABILIZER WRAP/L 3XL",
              "ORTHOTEX STABILIZER WRAP/L 4XL": "ORTHOTEX STABILIZER WRAP/L 4XL",
              "ORTHOTEX STABILIZER WRAP/L 5XL": "ORTHOTEX STABILIZER WRAP/L 5XL",
              "ORTHOTEX STABILIZER WRAP/L LG": "ORTHOTEX STABILIZER WRAP/L LG",
              "ORTHOTEX STABILIZER WRAP/L MED": "ORTHOTEX STABILIZER WRAP/L MED",
              "ORTHOTEX STABILIZER WRAP/L SM": "ORTHOTEX STABILIZER WRAP/L SM",
              "ORTHOTEX STABILIZER WRAP/L XL": "ORTHOTEX STABILIZER WRAP/L XL",
              "ORTHOTEX STABILIZER WRAP/L XS": "ORTHOTEX STABILIZER WRAP/L XS",
              "ORTHOTEX STABILIZER WRAP/R 2XL": "ORTHOTEX STABILIZER WRAP/R 2XL",
              "ORTHOTEX STABILIZER WRAP/R 3XL": "ORTHOTEX STABILIZER WRAP/R 3XL",
              "ORTHOTEX STABILIZER WRAP/R 4XL": "ORTHOTEX STABILIZER WRAP/R 4XL",
              "ORTHOTEX STABILIZER WRAP/R 5XL": "ORTHOTEX STABILIZER WRAP/R 5XL",
              "ORTHOTEX STABILIZER WRAP/R LG": "ORTHOTEX STABILIZER WRAP/R LG",
              "ORTHOTEX STABILIZER WRAP/R MED": "ORTHOTEX STABILIZER WRAP/R MED",
              "ORTHOTEX STABILIZER WRAP/R SM": "ORTHOTEX STABILIZER WRAP/R SM",
              "ORTHOTEX STABILIZER WRAP/R XL": "ORTHOTEX STABILIZER WRAP/R XL",
              "ORTHOTEX STABILIZER WRAP/R XS": "ORTHOTEX STABILIZER WRAP/R XS",
              "POST-OP SHOE/SOFT TOP MEN": "POST-OP SHOE/SOFT TOP MEN",
              "POST-OP SHOE/SOFT TOP WOMEN": "POST-OP SHOE/SOFT TOP WOMEN",
              "POSTURE SUPPORT/ELASTIC LARGE": "POSTURE SUPPORT/ELASTIC LARGE",
              "POSTURE SUPPORT/ELASTIC MEDIUM": "POSTURE SUPPORT/ELASTIC MEDIUM",
              "POSTURE SUPPORT/ELASTIC SMALL": "POSTURE SUPPORT/ELASTIC SMALL",
              "POSTURE SUPPORT/ELASTIC XL": "POSTURE SUPPORT/ELASTIC XL",
              "POSTURE SUPPORT/ELASTIC XS": "POSTURE SUPPORT/ELASTIC XS",
              "RIB BELT/MENS LARGE": "RIB BELT/MENS LARGE",
              "RIB BELT/MENS MEDIUM": "RIB BELT/MENS MEDIUM",
              "RIB BELT/MENS SMALL": "RIB BELT/MENS SMALL",
              "RIB BELT/MENS XL": "RIB BELT/MENS XL",
              "RIB BELT/MENS/ELASTIC LARGE": "RIB BELT/MENS/ELASTIC LARGE",
              "RIB BELT/MENS/ELASTIC REGULAR": "RIB BELT/MENS/ELASTIC REGULAR",
              "RIB BELT/WOMENS LARGE": "RIB BELT/WOMENS LARGE",
              "RIB BELT/WOMENS MEDIUM": "RIB BELT/WOMENS MEDIUM",
              "RIB BELT/WOMENS SMALL": "RIB BELT/WOMENS SMALL",
              "RIB BELT/WOMENS XL": "RIB BELT/WOMENS XL",
              "RIB BELT/WOMENS/ELASTIC LARGE": "RIB BELT/WOMENS/ELASTIC LARGE",
              "RIB BELT/WOMENS/ELASTIC REG": "RIB BELT/WOMENS/ELASTIC REG",
              "SACRO BRACE": "SACRO BRACE",
              "SACRO BRACE/THERMO-PAD": "SACRO BRACE/THERMO-PAD",
              "SELF-ADHERING ELASTIC BANDAGE": "SELF-ADHERING ELASTIC BANDAGE",
              "SHOULDER BRACE LARGE": "SHOULDER BRACE LARGE",
              "SHOULDER BRACE MEDIUM": "SHOULDER BRACE MEDIUM",
              "SHOULDER BRACE SMALL": "SHOULDER BRACE SMALL",
              "SHOULDER BRACE XL": "SHOULDER BRACE XL",
              "SHOULDER SUPPORT/NEOPRENE LG": "SHOULDER SUPPORT/NEOPRENE LG",
              "SHOULDER SUPPORT/NEOPRENE MED": "SHOULDER SUPPORT/NEOPRENE MED",
              "SHOULDER SUPPORT/NEOPRENE SM": "SHOULDER SUPPORT/NEOPRENE SM",
              "SLING & SWATHE SHLDR IMMOBILIZ": "SLING & SWATHE SHLDR IMMOBILIZ",
              "SPORTS BRIEF LARGE": "SPORTS BRIEF LARGE",
              "SPORTS BRIEF REGULAR": "SPORTS BRIEF REGULAR",
              "SPORTS SUPPORT LARGE": "SPORTS SUPPORT LARGE",
              "SPORTS SUPPORT MEDIUM": "SPORTS SUPPORT MEDIUM",
              "SPORTS SUPPORT SMALL": "SPORTS SUPPORT SMALL",
              "SPORTS SUPPORT XL": "SPORTS SUPPORT XL",
              "SPORTS SUPPORT YOUTH": "SPORTS SUPPORT YOUTH",
              "SUSPENSORY LARGE": "SUSPENSORY LARGE",
              "SUSPENSORY MEDIUM": "SUSPENSORY MEDIUM",
              "SUSPENSORY SMALL": "SUSPENSORY SMALL",
              "SUSPENSORY XL": "SUSPENSORY XL",
              "SUSPENSORY XXL": "SUSPENSORY XXL",
              "TENNIS ELBOW STRAP": "TENNIS ELBOW STRAP",
              "TENNIS ELBOW STRAP/AIR PAD": "TENNIS ELBOW STRAP/AIR PAD",
              "TENNIS ELBOW STRAP/GEL PAD": "TENNIS ELBOW STRAP/GEL PAD",
              "TENNIS ELBOW STRAP/SUPPORT PAD": "TENNIS ELBOW STRAP/SUPPORT PAD",
              "THERAPEUTIC KNEE GUARD": "THERAPEUTIC KNEE GUARD",
              "THIGH SUPPORT W/OVAL PATCH LG": "THIGH SUPPORT W/OVAL PATCH LG",
              "THIGH SUPPORT W/OVAL PATCH MED": "THIGH SUPPORT W/OVAL PATCH MED",
              "THIGH SUPPORT W/OVAL PATCH SM": "THIGH SUPPORT W/OVAL PATCH SM",
              "THIGH SUPPORT W/OVAL PATCH XL": "THIGH SUPPORT W/OVAL PATCH XL",
              "THREE-PANEL BINDER FOR MEN 2XL": "THREE-PANEL BINDER FOR MEN 2XL",
              "THREE-PANEL BINDER FOR MEN LG": "THREE-PANEL BINDER FOR MEN LG",
              "THREE-PANEL BINDER FOR MEN MED": "THREE-PANEL BINDER FOR MEN MED",
              "THREE-PANEL BINDER FOR MEN SM": "THREE-PANEL BINDER FOR MEN SM",
              "THREE-PANEL BINDER FOR MEN XL": "THREE-PANEL BINDER FOR MEN XL",
              "THREE-PANEL BINDER/WOMEN 2XL": "THREE-PANEL BINDER/WOMEN 2XL",
              "THREE-PANEL BINDER/WOMEN LG": "THREE-PANEL BINDER/WOMEN LG",
              "THREE-PANEL BINDER/WOMEN MED": "THREE-PANEL BINDER/WOMEN MED",
              "THREE-PANEL BINDER/WOMEN SMALL": "THREE-PANEL BINDER/WOMEN SMALL",
              "THREE-PANEL BINDER/WOMEN XL": "THREE-PANEL BINDER/WOMEN XL",
              "THUMB SPLINT/LEFT LARGE": "THUMB SPLINT/LEFT LARGE",
              "THUMB SPLINT/LEFT MEDIUM": "THUMB SPLINT/LEFT MEDIUM",
              "THUMB SPLINT/LEFT SMALL": "THUMB SPLINT/LEFT SMALL",
              "THUMB SPLINT/LEFT XL": "THUMB SPLINT/LEFT XL",
              "THUMB SPLINT/NEOPRENE LARGE": "THUMB SPLINT/NEOPRENE LARGE",
              "THUMB SPLINT/NEOPRENE MEDIUM": "THUMB SPLINT/NEOPRENE MEDIUM",
              "THUMB SPLINT/NEOPRENE SMALL": "THUMB SPLINT/NEOPRENE SMALL",
              "THUMB SPLINT/RIGHT LARGE": "THUMB SPLINT/RIGHT LARGE",
              "THUMB SPLINT/RIGHT MEDIUM": "THUMB SPLINT/RIGHT MEDIUM",
              "THUMB SPLINT/RIGHT SMALL": "THUMB SPLINT/RIGHT SMALL",
              "THUMB SPLINT/RIGHT XL": "THUMB SPLINT/RIGHT XL",
              "THUMB STABILIZER/LEFT LARGE": "THUMB STABILIZER/LEFT LARGE",
              "THUMB STABILIZER/LEFT MEDIUM": "THUMB STABILIZER/LEFT MEDIUM",
              "THUMB STABILIZER/LEFT SMALL": "THUMB STABILIZER/LEFT SMALL",
              "THUMB STABILIZER/LEFT XL": "THUMB STABILIZER/LEFT XL",
              "THUMB STABILIZER/LEFT XSMALL": "THUMB STABILIZER/LEFT XSMALL",
              "THUMB STABILIZER/RIGHT LARGE": "THUMB STABILIZER/RIGHT LARGE",
              "THUMB STABILIZER/RIGHT MEDIUM": "THUMB STABILIZER/RIGHT MEDIUM",
              "THUMB STABILIZER/RIGHT SMALL": "THUMB STABILIZER/RIGHT SMALL",
              "THUMB STABILIZER/RIGHT XL": "THUMB STABILIZER/RIGHT XL",
              "THUMB STABILIZER/RIGHT XSMALL": "THUMB STABILIZER/RIGHT XSMALL",
              "TRUFORM ARM SLEEVE L 15-20MMHG": "TRUFORM ARM SLEEVE L 15-20MMHG",
              "TRUFORM ARM SLEEVE L 20-30MMHG": "TRUFORM ARM SLEEVE L 20-30MMHG",
              "TRUFORM ARM SLEEVE M 15-20MMHG": "TRUFORM ARM SLEEVE M 15-20MMHG",
              "TRUFORM ARM SLEEVE M 20-30MMHG": "TRUFORM ARM SLEEVE M 20-30MMHG",
              "TRUFORM ARM SLEEVE S 15-20MMHG": "TRUFORM ARM SLEEVE S 15-20MMHG",
              "TRUFORM ARM SLEEVE S 20-30MMHG": "TRUFORM ARM SLEEVE S 20-30MMHG",
              "TRUFORM ATHLETIC CREW MENS": "TRUFORM ATHLETIC CREW MENS",
              "TRUFORM GAUNTLET L 15-20MMHG": "TRUFORM GAUNTLET L 15-20MMHG",
              "TRUFORM GAUNTLET L 20-30MMHG": "TRUFORM GAUNTLET L 20-30MMHG",
              "TRUFORM GAUNTLET M 15-20MMHG": "TRUFORM GAUNTLET M 15-20MMHG",
              "TRUFORM GAUNTLET M 20-30MMHG": "TRUFORM GAUNTLET M 20-30MMHG",
              "TRUFORM GAUNTLET S 15-20MMHG": "TRUFORM GAUNTLET S 15-20MMHG",
              "TRUFORM GAUNTLET S 20-30MMHG": "TRUFORM GAUNTLET S 20-30MMHG",
              "TRUFORM LITES KNEE HIGH": "TRUFORM LITES KNEE HIGH",
              "TRUFORM LITES MATERNITY HOSE": "TRUFORM LITES MATERNITY HOSE",
              "TRUFORM LITES PANTYHOSE": "TRUFORM LITES PANTYHOSE",
              "TRUFORM LITES THIGH HIGH": "TRUFORM LITES THIGH HIGH",
              "TRUFORM MATERNITY PANTYHOSE": "TRUFORM MATERNITY PANTYHOSE",
              "TRUFORM PANTYHOSE 20-30MMHG": "TRUFORM PANTYHOSE 20-30MMHG",
              "TRUFORM STOCKINGS 10-20MMHG": "TRUFORM STOCKINGS 10-20MMHG",
              "TRUFORM STOCKINGS 18MMHG": "TRUFORM STOCKINGS 18MMHG",
              "TRUFORM STOCKINGS 20-30MMHG": "TRUFORM STOCKINGS 20-30MMHG",
              "TRUFORM STOCKINGS 30-40MMHG": "TRUFORM STOCKINGS 30-40MMHG",
              "TRUFORM SUPPORT SOCK 10-20MMHG": "TRUFORM SUPPORT SOCK 10-20MMHG",
              "TRUFORM SUPPORT SOCK 15-20MMHG": "TRUFORM SUPPORT SOCK 15-20MMHG",
              "TRUFORM SUPPORT SOCK 20-30MMHG": "TRUFORM SUPPORT SOCK 20-30MMHG",
              "TRUFORM SUPPORT SOCK 30-40MMHG": "TRUFORM SUPPORT SOCK 30-40MMHG",
              "TRUFORM SUPPORT SOCK 8-15MMHG": "TRUFORM SUPPORT SOCK 8-15MMHG",
              "TRUFORM TRUSHEER MATERN HOSE": "TRUFORM TRUSHEER MATERN HOSE",
              "TRUSHEER PANTYHOSE 20-30MMHG": "TRUSHEER PANTYHOSE 20-30MMHG",
              "TRUSHEER PANTYHOSE 30-40MMHG": "TRUSHEER PANTYHOSE 30-40MMHG",
              "TRUSHEER STOCKINGS 20-30MMHG": "TRUSHEER STOCKINGS 20-30MMHG",
              "TRUSHEER STOCKINGS 30-40MMHG": "TRUSHEER STOCKINGS 30-40MMHG",
              "TRUSOFT CALF LENGTH 8-15MMHG": "TRUSOFT CALF LENGTH 8-15MMHG",
              "TRUSOFT SOCK CREW 8-15MMHG": "TRUSOFT SOCK CREW 8-15MMHG",
              "TRUSOFT SOCK MINI-CREW/LARGE": "TRUSOFT SOCK MINI-CREW/LARGE",
              "TRUSOFT SOCK MINI-CREW/MEDIUM": "TRUSOFT SOCK MINI-CREW/MEDIUM",
              "TRUSOFT SOCK MINI-CREW/SMALL": "TRUSOFT SOCK MINI-CREW/SMALL",
              "TRUSOFT SOCK MINI-CREW/XLARGE": "TRUSOFT SOCK MINI-CREW/XLARGE",
              "TRUSOFT SOCK MINI-CREW/XSMALL": "TRUSOFT SOCK MINI-CREW/XSMALL",
              "WRAPAROUND WRIST SUPPORT": "WRAPAROUND WRIST SUPPORT",
              "WRIST BRACE//LEFT LARGE": "WRIST BRACE//LEFT LARGE",
              "WRIST BRACE/LEFT MEDIUM": "WRIST BRACE/LEFT MEDIUM",
              "WRIST BRACE/LEFT SMALL": "WRIST BRACE/LEFT SMALL",
              "WRIST BRACE/LEFT XL": "WRIST BRACE/LEFT XL",
              "WRIST BRACE/LEFT XSMALL": "WRIST BRACE/LEFT XSMALL",
              "WRIST BRACE/RIGHT LARGE": "WRIST BRACE/RIGHT LARGE",
              "WRIST BRACE/RIGHT MEDIUM": "WRIST BRACE/RIGHT MEDIUM",
              "WRIST BRACE/RIGHT SMALL": "WRIST BRACE/RIGHT SMALL",
              "WRIST BRACE/RIGHT XL": "WRIST BRACE/RIGHT XL",
              "WRIST BRACE/RIGHT XSMALL": "WRIST BRACE/RIGHT XSMALL",
              "WRIST SPLINT/COCK-UP/LEFT 2XSM": "WRIST SPLINT/COCK-UP/LEFT 2XSM",
              "WRIST SPLINT/COCK-UP/LEFT L": "WRIST SPLINT/COCK-UP/LEFT L",
              "WRIST SPLINT/COCK-UP/LEFT M": "WRIST SPLINT/COCK-UP/LEFT M",
              "WRIST SPLINT/COCK-UP/LEFT SM": "WRIST SPLINT/COCK-UP/LEFT SM",
              "WRIST SPLINT/COCK-UP/LEFT XL": "WRIST SPLINT/COCK-UP/LEFT XL",
              "WRIST SPLINT/COCK-UP/LEFT XSM": "WRIST SPLINT/COCK-UP/LEFT XSM",
              "WRIST SPLINT/COCK-UP/RIGHT 2XS": "WRIST SPLINT/COCK-UP/RIGHT 2XS",
              "WRIST SPLINT/COCK-UP/RIGHT L": "WRIST SPLINT/COCK-UP/RIGHT L",
              "WRIST SPLINT/COCK-UP/RIGHT M": "WRIST SPLINT/COCK-UP/RIGHT M",
              "WRIST SPLINT/COCK-UP/RIGHT SM": "WRIST SPLINT/COCK-UP/RIGHT SM",
              "WRIST SPLINT/COCK-UP/RIGHT XL": "WRIST SPLINT/COCK-UP/RIGHT XL",
              "WRIST SPLINT/COCK-UP/RIGHT XSM": "WRIST SPLINT/COCK-UP/RIGHT XSM",
              "WRIST SPLINT/ELASTIC LEFT LG": "WRIST SPLINT/ELASTIC LEFT LG",
              "WRIST SPLINT/ELASTIC LEFT MED": "WRIST SPLINT/ELASTIC LEFT MED",
              "WRIST SPLINT/ELASTIC LEFT SM": "WRIST SPLINT/ELASTIC LEFT SM",
              "WRIST SPLINT/ELASTIC LEFT XL": "WRIST SPLINT/ELASTIC LEFT XL",
              "WRIST SPLINT/ELASTIC LEFT XS": "WRIST SPLINT/ELASTIC LEFT XS",
              "WRIST SPLINT/ELASTIC RIGHT LG": "WRIST SPLINT/ELASTIC RIGHT LG",
              "WRIST SPLINT/ELASTIC RIGHT MED": "WRIST SPLINT/ELASTIC RIGHT MED",
              "WRIST SPLINT/ELASTIC RIGHT SM": "WRIST SPLINT/ELASTIC RIGHT SM",
              "WRIST SPLINT/ELASTIC RIGHT XL": "WRIST SPLINT/ELASTIC RIGHT XL",
              "WRIST SPLINT/ELASTIC RIGHT XS": "WRIST SPLINT/ELASTIC RIGHT XS",
              "WRIST SPLINT/LEFT INFANT": "WRIST SPLINT/LEFT INFANT",
              "WRIST SPLINT/LEFT LARGE": "WRIST SPLINT/LEFT LARGE",
              "WRIST SPLINT/LEFT MEDIUM": "WRIST SPLINT/LEFT MEDIUM",
              "WRIST SPLINT/LEFT PEDIATRIC": "WRIST SPLINT/LEFT PEDIATRIC",
              "WRIST SPLINT/LEFT SMALL": "WRIST SPLINT/LEFT SMALL",
              "WRIST SPLINT/LEFT XL": "WRIST SPLINT/LEFT XL",
              "WRIST SPLINT/LEFT XSMALL": "WRIST SPLINT/LEFT XSMALL",
              "WRIST SPLINT/LEFT YOUTH": "WRIST SPLINT/LEFT YOUTH",
              "WRIST SPLINT/NEOPRENE LEFT LG": "WRIST SPLINT/NEOPRENE LEFT LG",
              "WRIST SPLINT/NEOPRENE LEFT MED": "WRIST SPLINT/NEOPRENE LEFT MED",
              "WRIST SPLINT/NEOPRENE LEFT SM": "WRIST SPLINT/NEOPRENE LEFT SM",
              "WRIST SPLINT/NEOPRENE LEFT XL": "WRIST SPLINT/NEOPRENE LEFT XL",
              "WRIST SPLINT/NEOPRENE RIGHT LG": "WRIST SPLINT/NEOPRENE RIGHT LG",
              "WRIST SPLINT/NEOPRENE RIGHT MD": "WRIST SPLINT/NEOPRENE RIGHT MD",
              "WRIST SPLINT/NEOPRENE RIGHT SM": "WRIST SPLINT/NEOPRENE RIGHT SM",
              "WRIST SPLINT/NEOPRENE RIGHT XL": "WRIST SPLINT/NEOPRENE RIGHT XL",
              "WRIST SPLINT/NEOPRENE RIGHT XS": "WRIST SPLINT/NEOPRENE RIGHT XS",
              "WRIST SPLINT/NEOPRENE/LEFT XSM": "WRIST SPLINT/NEOPRENE/LEFT XSM",
              "WRIST SPLINT/REVERSIBLE LARGE": "WRIST SPLINT/REVERSIBLE LARGE",
              "WRIST SPLINT/REVERSIBLE MEDIUM": "WRIST SPLINT/REVERSIBLE MEDIUM",
              "WRIST SPLINT/REVERSIBLE SMALL": "WRIST SPLINT/REVERSIBLE SMALL",
              "WRIST SPLINT/REVERSIBLE XL": "WRIST SPLINT/REVERSIBLE XL",
              "WRIST SPLINT/RIGHT INFANT": "WRIST SPLINT/RIGHT INFANT",
              "WRIST SPLINT/RIGHT LARGE": "WRIST SPLINT/RIGHT LARGE",
              "WRIST SPLINT/RIGHT MEDIUM": "WRIST SPLINT/RIGHT MEDIUM",
              "WRIST SPLINT/RIGHT PEDIATRIC": "WRIST SPLINT/RIGHT PEDIATRIC",
              "WRIST SPLINT/RIGHT SMALL": "WRIST SPLINT/RIGHT SMALL",
              "WRIST SPLINT/RIGHT XL": "WRIST SPLINT/RIGHT XL",
              "WRIST SPLINT/RIGHT XSMALL": "WRIST SPLINT/RIGHT XSMALL",
              "WRIST SPLINT/RIGHT YOUTH": "WRIST SPLINT/RIGHT YOUTH",
              "WRIST SUPPORT/ELASTIC LARGE": "WRIST SUPPORT/ELASTIC LARGE",
              "WRIST SUPPORT/ELASTIC MEDIUM": "WRIST SUPPORT/ELASTIC MEDIUM",
              "WRIST SUPPORT/ELASTIC SMALL": "WRIST SUPPORT/ELASTIC SMALL",
              "WRIST SUPPORT/ELASTIC XL": "WRIST SUPPORT/ELASTIC XL",
              "WRIST SUPPORT/ELASTIC/FIRM LG": "WRIST SUPPORT/ELASTIC/FIRM LG",
              "WRIST SUPPORT/ELASTIC/FIRM MED": "WRIST SUPPORT/ELASTIC/FIRM MED",
              "WRIST SUPPORT/ELASTIC/FIRM SM": "WRIST SUPPORT/ELASTIC/FIRM SM",
              "WRIST SUPPORT/ELASTIC/FIRM XL": "WRIST SUPPORT/ELASTIC/FIRM XL",
              "WRIST WRAP/ADJUSTABLE KIDS": "WRIST WRAP/ADJUSTABLE KIDS",
              "WRIST-THUMB SUPPORT/NEOPRENE L": "WRIST-THUMB SUPPORT/NEOPRENE L",
              "WRIST-THUMB SUPPORT/NEOPRENE M": "WRIST-THUMB SUPPORT/NEOPRENE M",
              "WRIST-THUMB SUPPORT/NEOPRENE S": "WRIST-THUMB SUPPORT/NEOPRENE S",
              "WRIST/THUMB SPLINT/LEFT LARGE": "WRIST/THUMB SPLINT/LEFT LARGE",
              "WRIST/THUMB SPLINT/LEFT MEDIUM": "WRIST/THUMB SPLINT/LEFT MEDIUM",
              "WRIST/THUMB SPLINT/LEFT SMALL": "WRIST/THUMB SPLINT/LEFT SMALL",
              "WRIST/THUMB SPLINT/LEFT UNIV": "WRIST/THUMB SPLINT/LEFT UNIV",
              "WRIST/THUMB SPLINT/LEFT XL": "WRIST/THUMB SPLINT/LEFT XL",
              "WRIST/THUMB SPLINT/LEFT XSMALL": "WRIST/THUMB SPLINT/LEFT XSMALL",
              "WRIST/THUMB SPLINT/RIGHT LARGE": "WRIST/THUMB SPLINT/RIGHT LARGE",
              "WRIST/THUMB SPLINT/RIGHT MED": "WRIST/THUMB SPLINT/RIGHT MED",
              "WRIST/THUMB SPLINT/RIGHT SMALL": "WRIST/THUMB SPLINT/RIGHT SMALL",
              "WRIST/THUMB SPLINT/RIGHT UNIV": "WRIST/THUMB SPLINT/RIGHT UNIV",
              "WRIST/THUMB SPLINT/RIGHT XL": "WRIST/THUMB SPLINT/RIGHT XL",
              "WRIST/THUMB SPLINT/RIGHT XSM": "WRIST/THUMB SPLINT/RIGHT XSM"
            },
            "SWEDE-O": {
              "SWEDE-O INNER LOK 8 ANKLE": "SWEDE-O INNER LOK 8 ANKLE"
            },
            "TOPCO": {
              "CARPAL TUNNEL WRIST STABILIZER": "CARPAL TUNNEL WRIST STABILIZER",
              "KNEE STABILIZER/ELASTIC/SM/MED": "KNEE STABILIZER/ELASTIC/SM/MED"
            },
            "UPSPRING": {
              "C-SECTION RECOVERY 1X/2X": "C-SECTION RECOVERY 1X/2X",
              "C-SECTION RECOVERY L/XL": "C-SECTION RECOVERY L/XL",
              "C-SECTION RECOVERY S/M": "C-SECTION RECOVERY S/M",
              "HIGH WAIST INCISION CARE 1X/2X": "HIGH WAIST INCISION CARE 1X/2X",
              "HIGH WAIST INCISION CARE L/XL": "HIGH WAIST INCISION CARE L/XL",
              "HIGH WAIST INCISION CARE S/M": "HIGH WAIST INCISION CARE S/M",
              "UPSPRING POST OP PANTY": "UPSPRING POST OP PANTY"
            },
            "UROCARE PRODUCTS, INC.": {
              "CONSTAY ELASTICATED ADHES TAPE": "CONSTAY ELASTICATED ADHES TAPE",
              "FINGER COTS LARGE 22MM": "FINGER COTS LARGE 22MM",
              "FINGER COTS MEDIUM 20MM": "FINGER COTS MEDIUM 20MM",
              "FINGER COTS SMALL 18MM": "FINGER COTS SMALL 18MM"
            },
            "WALGREENS": {
              "ABDOMINAL SUPPORT/L-XL": "ABDOMINAL SUPPORT/L-XL",
              "ACE ABDOMEN SURGICAL BINDER": "ACE ABDOMEN SURGICAL BINDER",
              "ACE ANKLE BRACE": "ACE ANKLE BRACE",
              "ACE ANKLE BRACE DELUXE LACED": "ACE ANKLE BRACE DELUXE LACED",
              "ACE ANKLE BRACE LARGE": "ACE ANKLE BRACE LARGE",
              "ACE ANKLE BRACE LEFT/RIGHT": "ACE ANKLE BRACE LEFT/RIGHT",
              "ACE ANKLE BRACE MEDIUM": "ACE ANKLE BRACE MEDIUM",
              "ACE ANKLE BRACE W/STABILIZERS": "ACE ANKLE BRACE W/STABILIZERS",
              "ACE ANKLE STABILIZER DELUXE": "ACE ANKLE STABILIZER DELUXE",
              "ACE ANKLE SUPPORT SPANDEX LG": "ACE ANKLE SUPPORT SPANDEX LG",
              "ACE ANKLE SUPPORT SPANDEX MED": "ACE ANKLE SUPPORT SPANDEX MED",
              "ACE ARM SLING": "ACE ARM SLING",
              "ACE BACK STABILIZER": "ACE BACK STABILIZER",
              "ACE BACK SUPPORT": "ACE BACK SUPPORT",
              "ACE BANDAGE SELF-ADHERING": "ACE BANDAGE SELF-ADHERING",
              "ACE COMFORT LIFT ANKLE SUPP/SM": "ACE COMFORT LIFT ANKLE SUPP/SM",
              "ACE ELASTIC BANDAGE/E-Z CLIPS": "ACE ELASTIC BANDAGE/E-Z CLIPS",
              "ACE ELBOW BRACE": "ACE ELBOW BRACE",
              "ACE ELBOW BRACE LARGE/X-LARGE": "ACE ELBOW BRACE LARGE/X-LARGE",
              "ACE ELBOW BRACE SMALL/MEDIUM": "ACE ELBOW BRACE SMALL/MEDIUM",
              "ACE ELBOW STRAP": "ACE ELBOW STRAP",
              "ACE ELBOW STRAP ONE SIZE": "ACE ELBOW STRAP ONE SIZE",
              "ACE ELBOW SUPPORT LARGE": "ACE ELBOW SUPPORT LARGE",
              "ACE ELBOW SUPPORT MEDIUM": "ACE ELBOW SUPPORT MEDIUM",
              "ACE ELBOW SUPPORT ONE SIZE": "ACE ELBOW SUPPORT ONE SIZE",
              "ACE HAND SUPPORT GLOVE": "ACE HAND SUPPORT GLOVE",
              "ACE HAND SUPPORT GLOVE SM/MED": "ACE HAND SUPPORT GLOVE SM/MED",
              "ACE KNEE BRACE LARGE": "ACE KNEE BRACE LARGE",
              "ACE KNEE BRACE LARGE/X-LARGE": "ACE KNEE BRACE LARGE/X-LARGE",
              "ACE KNEE BRACE MEDIUM": "ACE KNEE BRACE MEDIUM",
              "ACE KNEE BRACE SMALL/MEDIUM": "ACE KNEE BRACE SMALL/MEDIUM",
              "ACE KNEE BRACE W/STABILIZERS": "ACE KNEE BRACE W/STABILIZERS",
              "ACE KNEE STABILIZER": "ACE KNEE STABILIZER",
              "ACE KNEE STABILIZER LARGE": "ACE KNEE STABILIZER LARGE",
              "ACE KNEE STABILIZER MEDIUM": "ACE KNEE STABILIZER MEDIUM",
              "ACE KNEE SUPPORT LARGE/XL": "ACE KNEE SUPPORT LARGE/XL",
              "ACE KNEE SUPPORT SM/MED": "ACE KNEE SUPPORT SM/MED",
              "ACE TENNIS ELBOW BRACE": "ACE TENNIS ELBOW BRACE",
              "ACE TENNIS ELBOW STRAP": "ACE TENNIS ELBOW STRAP",
              "ACE THUMB STABILIZER LEFT/RGHT": "ACE THUMB STABILIZER LEFT/RGHT",
              "ACE WRIST BRACE": "ACE WRIST BRACE",
              "ACE WRIST BRACE DELUXE LEFT": "ACE WRIST BRACE DELUXE LEFT",
              "ACE WRIST BRACE DELUXE RIGHT": "ACE WRIST BRACE DELUXE RIGHT",
              "ACE WRIST BRACE/REVERSIBLE": "ACE WRIST BRACE/REVERSIBLE",
              "ACE WRIST IMMOBILIZER": "ACE WRIST IMMOBILIZER",
              "ACE WRIST STABILIZER DELUXE": "ACE WRIST STABILIZER DELUXE",
              "ACE WRIST SUPPORT": "ACE WRIST SUPPORT",
              "ANKLE BRACE DELUXE LACED/L-XL": "ANKLE BRACE DELUXE LACED/L-XL",
              "ANKLE BRACE DELUXE LACED/S-M": "ANKLE BRACE DELUXE LACED/S-M",
              "ANKLE BRACE/SIDE STABILIZERS": "ANKLE BRACE/SIDE STABILIZERS",
              "ANKLE STABILIZER ADJUSTABLE": "ANKLE STABILIZER ADJUSTABLE",
              "ANKLE SUPPORT ADJUSTABLE": "ANKLE SUPPORT ADJUSTABLE",
              "ARCH BANDAGE": "ARCH BANDAGE",
              "ARM SLING/THUMB LOOP/ONE SIZE": "ARM SLING/THUMB LOOP/ONE SIZE",
              "BACK SUPPORT": "BACK SUPPORT",
              "CAST/BANDAGE PROTECTOR": "CAST/BANDAGE PROTECTOR",
              "CERVICAL COLLAR ADJUSTABLE": "CERVICAL COLLAR ADJUSTABLE",
              "DIABETIC CREW SOCKS/MENS/XL": "DIABETIC CREW SOCKS/MENS/XL",
              "DIABETIC CREW SOCKS/WOMEN/XL": "DIABETIC CREW SOCKS/WOMEN/XL",
              "ELASTIC BANDAGE 3\"\"": "ELASTIC BANDAGE 3\"\"",
              "ELASTIC BANDAGE 4\"\"": "ELASTIC BANDAGE 4\"\"",
              "ELASTIC BANDAGE 6\"\"": "ELASTIC BANDAGE 6\"\"",
              "ELBOW COPPER-INFUSED SLEEVE": "ELBOW COPPER-INFUSED SLEEVE",
              "ELBOW STRAP LEFT/RIGHT": "ELBOW STRAP LEFT/RIGHT",
              "ELBOW SUPPORT L-R/LARGE-XL": "ELBOW SUPPORT L-R/LARGE-XL",
              "ELBOW SUPPORT L-R/SMALL-MEDIUM": "ELBOW SUPPORT L-R/SMALL-MEDIUM",
              "ELBOW SUPPORT LEFT/RIGHT": "ELBOW SUPPORT LEFT/RIGHT",
              "ELBOW SUPPORT/PRESSURE PADS": "ELBOW SUPPORT/PRESSURE PADS",
              "FINGER COTS": "FINGER COTS",
              "FINGER SPLINT MEDIUM": "FINGER SPLINT MEDIUM",
              "FINGER SPLINT SMALL": "FINGER SPLINT SMALL",
              "KNEE BRACE/CUSHION/L-XL": "KNEE BRACE/CUSHION/L-XL",
              "KNEE BRACE/CUSHION/S/M": "KNEE BRACE/CUSHION/S/M",
              "KNEE BRACE/HINGED L/XL": "KNEE BRACE/HINGED L/XL",
              "KNEE BRACE/HINGED S/M": "KNEE BRACE/HINGED S/M",
              "KNEE COMPRESSION SLEEVE/L/XL": "KNEE COMPRESSION SLEEVE/L/XL",
              "KNEE COMPRESSION SLEEVE/S/M": "KNEE COMPRESSION SLEEVE/S/M",
              "KNEE STABILIZER ONE SIZE": "KNEE STABILIZER ONE SIZE",
              "PLANTAR FASCIITIS ARCH SLEEVE": "PLANTAR FASCIITIS ARCH SLEEVE",
              "SELF ADHERENT WRAP": "SELF ADHERENT WRAP",
              "SELFGRIP 2\"\"X1.9YD": "SELFGRIP 2\"\"X1.9YD",
              "SELFGRIP 3\"\"X1.9YD": "SELFGRIP 3\"\"X1.9YD",
              "SPLINT WRIST BRACE/LEFT-RIGHT": "SPLINT WRIST BRACE/LEFT-RIGHT",
              "SPORT WRAP REVERSIBLE 3\"\"X2.2YD": "SPORT WRAP REVERSIBLE 3\"\"X2.2YD",
              "STIRRUP ANKLE BRACE/LEFT-RIGHT": "STIRRUP ANKLE BRACE/LEFT-RIGHT",
              "TENDER 1\"\"X6YD": "TENDER 1\"\"X6YD",
              "TENDER 2\"\"X6YD": "TENDER 2\"\"X6YD",
              "TENNIS ELBOW SUPPORT/L-R": "TENNIS ELBOW SUPPORT/L-R",
              "THUMB BRACE": "THUMB BRACE",
              "TUBULAR STRETCH BANDAGE": "TUBULAR STRETCH BANDAGE",
              "WRAP/MULTIPURPOSE 2.75\"\"X21.4YD": "WRAP/MULTIPURPOSE 2.75\"\"X21.4YD",
              "WRAP/PAIN FREE": "WRAP/PAIN FREE",
              "WRIST SPLINT/RIGHT/ONE SIZE": "WRIST SPLINT/RIGHT/ONE SIZE",
              "WRIST STABILIZER LEFT L-XL": "WRIST STABILIZER LEFT L-XL",
              "WRIST STABILIZER RIGHT L/XL": "WRIST STABILIZER RIGHT L/XL",
              "WRIST SUPPORT": "WRIST SUPPORT"
            }
          }
        },
        "Enteral Nutrition Supplies": {
          "Digestive Enzyme Cartridge": {
            "ALCRESTA PHARMACEUTICALS": {
              "RELIZORB": "RELIZORB"
            }
          },
          "Enteral Nutrition Supplies": {
            "ABBOTT NUTRITION": {
              "HIDE-A-PORT STOMA MEASURING": "HIDE-A-PORT STOMA MEASURING"
            },
            "BARD": {
              "BARD PISTON ENT IRRIGATION SYR": "BARD PISTON ENT IRRIGATION SYR"
            },
            "COVIDIEN MEDICAL SUPPLIES": {
              "CONFIRM NOW CO2 DETECTOR": "CONFIRM NOW CO2 DETECTOR",
              "KANGAROO PET CHARGING CORD": "KANGAROO PET CHARGING CORD",
              "KANGAROO PET FRAME FOR PUMP": "KANGAROO PET FRAME FOR PUMP",
              "KANGAROO PET PUMP CARRY CASE": "KANGAROO PET PUMP CARRY CASE",
              "KANGAROO STOMA MEASURING DEV": "KANGAROO STOMA MEASURING DEV",
              "MONOJECT ENTERAL SYRINGE CAP": "MONOJECT ENTERAL SYRINGE CAP",
              "MONOJECT ENTERAL SYRINGE/12ML": "MONOJECT ENTERAL SYRINGE/12ML",
              "MONOJECT ENTERAL SYRINGE/1ML": "MONOJECT ENTERAL SYRINGE/1ML",
              "MONOJECT ENTERAL SYRINGE/35ML": "MONOJECT ENTERAL SYRINGE/35ML",
              "MONOJECT ENTERAL SYRINGE/3ML": "MONOJECT ENTERAL SYRINGE/3ML",
              "MONOJECT ENTERAL SYRINGE/60ML": "MONOJECT ENTERAL SYRINGE/60ML",
              "MONOJECT ENTERAL SYRINGE/6ML": "MONOJECT ENTERAL SYRINGE/6ML"
            },
            "DYNAREX CORPORATION": {
              "ENTERAL DELIVERY GRAVITY BAG": "ENTERAL DELIVERY GRAVITY BAG",
              "ENTERAL FEEDING PISTON SYRINGE": "ENTERAL FEEDING PISTON SYRINGE"
            },
            "NESTLE HEALTHCARE NUTRITION": {
              "COMPAT 10FR N/G WTD TIP/STYLET": "COMPAT 10FR N/G WTD TIP/STYLET",
              "COMPAT 12FR N/G WTD TIP/STYLET": "COMPAT 12FR N/G WTD TIP/STYLET",
              "COMPAT 8FR N/G WTD TIP/STYLET": "COMPAT 8FR N/G WTD TIP/STYLET",
              "COMPAT CONTAINER/GRAVITY SET": "COMPAT CONTAINER/GRAVITY SET",
              "COMPAT CONTAINER/PUMP SET": "COMPAT CONTAINER/PUMP SET",
              "COMPAT DUAL-FLO PUMP": "COMPAT DUAL-FLO PUMP",
              "COMPAT DUAL-FLO SPIKE SET": "COMPAT DUAL-FLO SPIKE SET",
              "COMPAT DUAL-FLO VINYL BAG": "COMPAT DUAL-FLO VINYL BAG",
              "COMPAT ENTERAL FEEDING PUMP": "COMPAT ENTERAL FEEDING PUMP",
              "COMPAT PUMP SPIKE SET": "COMPAT PUMP SPIKE SET",
              "COMPAT PUMP/DOSE LIMIT/MEMORY": "COMPAT PUMP/DOSE LIMIT/MEMORY",
              "COMPAT PUMP/VINYL BAG SET": "COMPAT PUMP/VINYL BAG SET",
              "COMPAT SELECT FLO BAGGLE CASE": "COMPAT SELECT FLO BAGGLE CASE",
              "COMPAT SELECT FLO PUMP": "COMPAT SELECT FLO PUMP",
              "COMPAT SELECT TRANSPORT CASE": "COMPAT SELECT TRANSPORT CASE",
              "COMPAT STAY-PUT NJ DOUBLE LUME": "COMPAT STAY-PUT NJ DOUBLE LUME",
              "COMPAT UNIVERSAL Y ADAPTER/LG": "COMPAT UNIVERSAL Y ADAPTER/LG",
              "COMPAT UNIVERSAL Y ADAPTER/MED": "COMPAT UNIVERSAL Y ADAPTER/MED",
              "COMPAT UNIVERSAL Y ADAPTER/SM": "COMPAT UNIVERSAL Y ADAPTER/SM",
              "COMPAT VINYL BAG GRAVITY SET": "COMPAT VINYL BAG GRAVITY SET",
              "COMPAT YSET": "COMPAT YSET",
              "ENTERALITE INFINITY BACKPACK": "ENTERALITE INFINITY BACKPACK",
              "ENTERALITE INFINITY CHARGER": "ENTERALITE INFINITY CHARGER",
              "ENTERALITE INFINITY WAIST PACK": "ENTERALITE INFINITY WAIST PACK"
            }
          },
          "Feeding Tubes": {
            "ABBOTT NUTRITION": {
              "CLEARSTAR PUMP": "CLEARSTAR PUMP",
              "DURA-G GASTROSTOMY TUBE 18FR": "DURA-G GASTROSTOMY TUBE 18FR",
              "EMBRACE ENTERAL PUMP SET": "EMBRACE ENTERAL PUMP SET",
              "FLEXIFLO BAG-PUMP SET": "FLEXIFLO BAG-PUMP SET",
              "FLEXIFLO COMPANION COLORMARK": "FLEXIFLO COMPANION COLORMARK",
              "FLEXIFLO COMPANION PUMP SET": "FLEXIFLO COMPANION PUMP SET",
              "FLEXIFLO EASY-FEED BAG SET": "FLEXIFLO EASY-FEED BAG SET",
              "FLEXIFLO ENTERAL PUMP": "FLEXIFLO ENTERAL PUMP",
              "FLEXIFLO FEEDING TUBE": "FLEXIFLO FEEDING TUBE",
              "FLEXIFLO FEEDING TUBE 10FR": "FLEXIFLO FEEDING TUBE 10FR",
              "FLEXIFLO GASTROSTOMY TUBE": "FLEXIFLO GASTROSTOMY TUBE",
              "FLEXIFLO INVERTA-PEG GASTRO": "FLEXIFLO INVERTA-PEG GASTRO",
              "FLEXIFLO IRRIGATION ADAPTOR": "FLEXIFLO IRRIGATION ADAPTOR",
              "FLEXIFLO JEJUNAL FEEDING TUBE": "FLEXIFLO JEJUNAL FEEDING TUBE",
              "FLEXIFLO MAGNA-PORT": "FLEXIFLO MAGNA-PORT",
              "FLEXIFLO OVER-THE-GUIDEWIRE": "FLEXIFLO OVER-THE-GUIDEWIRE",
              "FLEXIFLO PATROL COLORMARK": "FLEXIFLO PATROL COLORMARK",
              "FLEXIFLO QUANTUM": "FLEXIFLO QUANTUM",
              "FLEXIFLO QUANTUM 40MM PUMP SET": "FLEXIFLO QUANTUM 40MM PUMP SET",
              "FLEXIFLO QUANTUM COLORMARK": "FLEXIFLO QUANTUM COLORMARK",
              "FLEXIFLO STOMATE": "FLEXIFLO STOMATE",
              "FLEXIFLO STOMATE 26 KIT": "FLEXIFLO STOMATE 26 KIT",
              "FLEXIFLO STOMATE DECOMP TUBE": "FLEXIFLO STOMATE DECOMP TUBE",
              "FLEXIFLO STOMATE EXT TUBE": "FLEXIFLO STOMATE EXT TUBE",
              "FLEXIFLO STOMATE TUBE": "FLEXIFLO STOMATE TUBE",
              "FLEXIFLO T-FASTENER SET": "FLEXIFLO T-FASTENER SET",
              "FLEXIFLO TOPTAINER ENTERAL BAG": "FLEXIFLO TOPTAINER ENTERAL BAG",
              "FLEXIFLO TOPTAINER FEED SET": "FLEXIFLO TOPTAINER FEED SET",
              "FLEXIFLO TOPTAINER/ATTACH PUMP": "FLEXIFLO TOPTAINER/ATTACH PUMP",
              "FLEXIFLO TOPTAINER/PUMP SET": "FLEXIFLO TOPTAINER/PUMP SET",
              "FLEXIFLO VERSA-PED KIT": "FLEXIFLO VERSA-PED KIT",
              "FLEXIFLO Y-PORT CONNECTOR": "FLEXIFLO Y-PORT CONNECTOR",
              "FLEXIFLO-III COLORMARK": "FLEXIFLO-III COLORMARK",
              "FLEXIFLO-III ENTERAL PUMP": "FLEXIFLO-III ENTERAL PUMP",
              "FLEXIFLO-III ENTERAL PUMP SET": "FLEXIFLO-III ENTERAL PUMP SET",
              "FLEXIFLO/PIERCING PIN": "FLEXIFLO/PIERCING PIN",
              "FLEXIFLO/PREATTACHED FLEXICO": "FLEXIFLO/PREATTACHED FLEXICO",
              "FLEXITAINER ENTERAL CONTAINER": "FLEXITAINER ENTERAL CONTAINER",
              "HIDE-A-PORT CUSTOM 14FR/0.8CM": "HIDE-A-PORT CUSTOM 14FR/0.8CM",
              "HIDE-A-PORT CUSTOM 14FR/1.2CM": "HIDE-A-PORT CUSTOM 14FR/1.2CM",
              "HIDE-A-PORT CUSTOM 14FR/1.5CM": "HIDE-A-PORT CUSTOM 14FR/1.5CM",
              "HIDE-A-PORT CUSTOM 14FR/1.7CM": "HIDE-A-PORT CUSTOM 14FR/1.7CM",
              "HIDE-A-PORT CUSTOM 14FR/2.3CM": "HIDE-A-PORT CUSTOM 14FR/2.3CM",
              "HIDE-A-PORT CUSTOM 14FR/2.5CM": "HIDE-A-PORT CUSTOM 14FR/2.5CM",
              "HIDE-A-PORT CUSTOM 14FR/2.7CM": "HIDE-A-PORT CUSTOM 14FR/2.7CM",
              "HIDE-A-PORT CUSTOM 14FR/3.5CM": "HIDE-A-PORT CUSTOM 14FR/3.5CM",
              "HIDE-A-PORT CUSTOM 14FR/4.5CM": "HIDE-A-PORT CUSTOM 14FR/4.5CM",
              "HIDE-A-PORT CUSTOM 14FR/5.5CM": "HIDE-A-PORT CUSTOM 14FR/5.5CM",
              "HIDE-A-PORT CUSTOM 18FR/0.8CM": "HIDE-A-PORT CUSTOM 18FR/0.8CM",
              "HIDE-A-PORT CUSTOM 18FR/1.2CM": "HIDE-A-PORT CUSTOM 18FR/1.2CM",
              "HIDE-A-PORT CUSTOM 18FR/1.5CM": "HIDE-A-PORT CUSTOM 18FR/1.5CM",
              "HIDE-A-PORT CUSTOM 18FR/1.7CM": "HIDE-A-PORT CUSTOM 18FR/1.7CM",
              "HIDE-A-PORT CUSTOM 18FR/5.5CM": "HIDE-A-PORT CUSTOM 18FR/5.5CM",
              "HIDE-A-PORT CUSTOM 24FR/1.5CM": "HIDE-A-PORT CUSTOM 24FR/1.5CM",
              "HIDE-A-PORT CUSTOM 24FR/1.7CM": "HIDE-A-PORT CUSTOM 24FR/1.7CM",
              "HIDE-A-PORT CUSTOM 24FR/2.3CM": "HIDE-A-PORT CUSTOM 24FR/2.3CM",
              "HIDE-A-PORT CUSTOM 24FR/2.5CM": "HIDE-A-PORT CUSTOM 24FR/2.5CM",
              "HIDE-A-PORT CUSTOM 24FR/2.7CM": "HIDE-A-PORT CUSTOM 24FR/2.7CM",
              "HIDE-A-PORT CUSTOM 24FR/2CM": "HIDE-A-PORT CUSTOM 24FR/2CM",
              "HIDE-A-PORT CUSTOM 24FR/3.5CM": "HIDE-A-PORT CUSTOM 24FR/3.5CM",
              "HIDE-A-PORT CUSTOM 24FR/3CM": "HIDE-A-PORT CUSTOM 24FR/3CM",
              "HIDE-A-PORT CUSTOM 24FR/4.5CM": "HIDE-A-PORT CUSTOM 24FR/4.5CM",
              "HIDE-A-PORT CUSTOM 24FR/4CM": "HIDE-A-PORT CUSTOM 24FR/4CM",
              "HIDE-A-PORT CUSTOM KIT 14F/1CM": "HIDE-A-PORT CUSTOM KIT 14F/1CM",
              "HIDE-A-PORT CUSTOM KIT 14F/2CM": "HIDE-A-PORT CUSTOM KIT 14F/2CM",
              "HIDE-A-PORT CUSTOM KIT 14F/3CM": "HIDE-A-PORT CUSTOM KIT 14F/3CM",
              "HIDE-A-PORT CUSTOM KIT 14F/4CM": "HIDE-A-PORT CUSTOM KIT 14F/4CM",
              "HIDE-A-PORT CUSTOM KIT 14F/5CM": "HIDE-A-PORT CUSTOM KIT 14F/5CM",
              "HIDE-A-PORT CUSTOM KIT 18F/1CM": "HIDE-A-PORT CUSTOM KIT 18F/1CM",
              "HIDE-A-PORT CUSTOM KIT 18F/2CM": "HIDE-A-PORT CUSTOM KIT 18F/2CM",
              "HIDE-A-PORT CUSTOM KIT 18F/5CM": "HIDE-A-PORT CUSTOM KIT 18F/5CM",
              "HIDE-A-PORT EXTENSION TUBE": "HIDE-A-PORT EXTENSION TUBE",
              "HIDE-A-PORT FLUSH 20FR/3.5CM": "HIDE-A-PORT FLUSH 20FR/3.5CM",
              "HIDE-A-PORT FLUSH KIT 14FR/1.2": "HIDE-A-PORT FLUSH KIT 14FR/1.2",
              "HIDE-A-PORT FLUSH KIT 16FR/1.2": "HIDE-A-PORT FLUSH KIT 16FR/1.2",
              "HIDE-A-PORT FLUSH KIT 16FR/1.5": "HIDE-A-PORT FLUSH KIT 16FR/1.5",
              "HIDE-A-PORT FLUSH KIT 16FR/1CM": "HIDE-A-PORT FLUSH KIT 16FR/1CM",
              "HIDE-A-PORT FLUSH KIT 16FR/2.3": "HIDE-A-PORT FLUSH KIT 16FR/2.3",
              "HIDE-A-PORT FLUSH KIT 16FR/2.7": "HIDE-A-PORT FLUSH KIT 16FR/2.7",
              "HIDE-A-PORT FLUSH KIT 16FR/2CM": "HIDE-A-PORT FLUSH KIT 16FR/2CM",
              "HIDE-A-PORT FLUSH KIT 16FR/3CM": "HIDE-A-PORT FLUSH KIT 16FR/3CM",
              "HIDE-A-PORT FLUSH KIT 16FR/4CM": "HIDE-A-PORT FLUSH KIT 16FR/4CM",
              "HIDE-A-PORT FLUSH KIT 18FR/1.7": "HIDE-A-PORT FLUSH KIT 18FR/1.7",
              "HIDE-A-PORT FLUSH KIT 20FR/0.8": "HIDE-A-PORT FLUSH KIT 20FR/0.8",
              "HIDE-A-PORT FLUSH KIT 20FR/1.2": "HIDE-A-PORT FLUSH KIT 20FR/1.2",
              "HIDE-A-PORT FLUSH KIT 20FR/1.5": "HIDE-A-PORT FLUSH KIT 20FR/1.5",
              "HIDE-A-PORT FLUSH KIT 20FR/1.7": "HIDE-A-PORT FLUSH KIT 20FR/1.7",
              "HIDE-A-PORT FLUSH KIT 20FR/1CM": "HIDE-A-PORT FLUSH KIT 20FR/1CM",
              "HIDE-A-PORT FLUSH KIT 20FR/2.3": "HIDE-A-PORT FLUSH KIT 20FR/2.3",
              "HIDE-A-PORT FLUSH KIT 20FR/2.5": "HIDE-A-PORT FLUSH KIT 20FR/2.5",
              "HIDE-A-PORT FLUSH KIT 20FR/2.7": "HIDE-A-PORT FLUSH KIT 20FR/2.7",
              "HIDE-A-PORT FLUSH KIT 20FR/2CM": "HIDE-A-PORT FLUSH KIT 20FR/2CM",
              "HIDE-A-PORT FLUSH KIT 20FR/3CM": "HIDE-A-PORT FLUSH KIT 20FR/3CM",
              "HIDE-A-PORT FLUSH KIT 20FR/4.5": "HIDE-A-PORT FLUSH KIT 20FR/4.5",
              "HIDE-A-PORT FLUSH KIT 20FR/4CM": "HIDE-A-PORT FLUSH KIT 20FR/4CM",
              "HIDE-A-PORT FLUSH TIP 16F/1.7": "HIDE-A-PORT FLUSH TIP 16F/1.7",
              "HIDE-A-PORT FLUSH TIP 16F/2.3": "HIDE-A-PORT FLUSH TIP 16F/2.3",
              "HIDE-A-PORT FLUSH TIP 16F/2.5": "HIDE-A-PORT FLUSH TIP 16F/2.5",
              "HIDE-A-PORT FLUSH TIP 16FR/0.8": "HIDE-A-PORT FLUSH TIP 16FR/0.8",
              "HIDE-A-PORT FLUSH TIP 16FR/1.5": "HIDE-A-PORT FLUSH TIP 16FR/1.5",
              "HIDE-A-PORT FLUSH TIP 16FR/1.7": "HIDE-A-PORT FLUSH TIP 16FR/1.7",
              "HIDE-A-PORT FLUSH TIP 16FR/1CM": "HIDE-A-PORT FLUSH TIP 16FR/1CM",
              "HIDE-A-PORT FLUSH TIP 16FR/2.5": "HIDE-A-PORT FLUSH TIP 16FR/2.5",
              "HIDE-A-PORT FLUSH TIP 16FR/2CM": "HIDE-A-PORT FLUSH TIP 16FR/2CM",
              "HIDE-A-PORT FLUSH TIP 16FR/3.5": "HIDE-A-PORT FLUSH TIP 16FR/3.5",
              "HIDE-A-PORT FLUSH TIP 16FR/4.5": "HIDE-A-PORT FLUSH TIP 16FR/4.5",
              "HIDE-A-PORT FLUSH TIP 18FR/1CM": "HIDE-A-PORT FLUSH TIP 18FR/1CM",
              "HIDE-A-PORT FLUSH TIP 18FR/2.3": "HIDE-A-PORT FLUSH TIP 18FR/2.3",
              "HIDE-A-PORT KIT 18FR/2.3CM": "HIDE-A-PORT KIT 18FR/2.3CM",
              "HIDE-A-PORT KIT 18FR/2.5CM": "HIDE-A-PORT KIT 18FR/2.5CM",
              "HIDE-A-PORT KIT 18FR/2.7CM": "HIDE-A-PORT KIT 18FR/2.7CM",
              "HIDE-A-PORT KIT 18FR/3.5CM": "HIDE-A-PORT KIT 18FR/3.5CM",
              "HIDE-A-PORT KIT 18FR/3CM": "HIDE-A-PORT KIT 18FR/3CM",
              "HIDE-A-PORT KIT 18FR/4.5CM": "HIDE-A-PORT KIT 18FR/4.5CM",
              "HIDE-A-PORT KIT 18FR/4CM": "HIDE-A-PORT KIT 18FR/4CM",
              "INVERTA-PEG GASTROSTOMY": "INVERTA-PEG GASTROSTOMY",
              "INVERTA-PEG GASTROSTOMY 20FR": "INVERTA-PEG GASTROSTOMY 20FR",
              "INVERTA-PEG KIT": "INVERTA-PEG KIT",
              "INVERTA-PEG KIT OVER THE WIRE": "INVERTA-PEG KIT OVER THE WIRE",
              "MAGNA-PORT ADAPTER": "MAGNA-PORT ADAPTER",
              "MAGNA-PORT GASTROSTOMY TUBE": "MAGNA-PORT GASTROSTOMY TUBE",
              "PATROL PUMP SET/40MM SCREW CAP": "PATROL PUMP SET/40MM SCREW CAP"
            },
            "COVIDIEN MEDICAL SUPPLIES": {
              "ARGYLE FEEDING SET 5FR/16\"\"": "ARGYLE FEEDING SET 5FR/16\"\"",
              "ARGYLE FEEDING SET 8FR/16\"\"": "ARGYLE FEEDING SET 8FR/16\"\"",
              "ARGYLE FEEDING TUBE 10FR/42\"\"": "ARGYLE FEEDING TUBE 10FR/42\"\"",
              "ARGYLE FEEDING TUBE 3.5FR/12\"\"": "ARGYLE FEEDING TUBE 3.5FR/12\"\"",
              "ARGYLE FEEDING TUBE 5FR/16\"\"": "ARGYLE FEEDING TUBE 5FR/16\"\"",
              "ARGYLE FEEDING TUBE 5FR/36\"\"": "ARGYLE FEEDING TUBE 5FR/36\"\"",
              "ARGYLE FEEDING TUBE 8FR/16\"\"": "ARGYLE FEEDING TUBE 8FR/16\"\"",
              "ARGYLE FEEDING TUBE 8FR/42\"\"": "ARGYLE FEEDING TUBE 8FR/42\"\"",
              "ARGYLE SUCTION TUBING 3/16\"\"X6\\'": "ARGYLE SUCTION TUBING 3/16\"\"X6\\'",
              "ENTRIFLEX FEEDING TB 10FR/43\"\"": "ENTRIFLEX FEEDING TB 10FR/43\"\"",
              "ENTRIFLEX FEEDING TB 10FR/55\"\"": "ENTRIFLEX FEEDING TB 10FR/55\"\"",
              "ENTRIFLEX FEEDING TB 12FR/36\"\"": "ENTRIFLEX FEEDING TB 12FR/36\"\"",
              "ENTRIFLEX FEEDING TB 12FR/43\"\"": "ENTRIFLEX FEEDING TB 12FR/43\"\"",
              "ENTRIFLEX FEEDING TB 12FR/55\"\"": "ENTRIFLEX FEEDING TB 12FR/55\"\"",
              "ENTRIFLEX FEEDING TUBE 8FR/36\"\"": "ENTRIFLEX FEEDING TUBE 8FR/36\"\"",
              "ENTRIFLEX FEEDING TUBE 8FR/43\"\"": "ENTRIFLEX FEEDING TUBE 8FR/43\"\"",
              "ENTRIFLEX FEEDING TUBE 8FR/55\"\"": "ENTRIFLEX FEEDING TUBE 8FR/55\"\"",
              "ENTRISTAR PEG ENTERAL CONNECT": "ENTRISTAR PEG ENTERAL CONNECT",
              "ENTRISTAR SAFETY PEG KIT 16FR": "ENTRISTAR SAFETY PEG KIT 16FR",
              "ENTRISTAR SAFETY PEG KIT 20FR": "ENTRISTAR SAFETY PEG KIT 20FR",
              "ENTRISTAR/NUTRIPORT BOLUS FEED": "ENTRISTAR/NUTRIPORT BOLUS FEED",
              "ENTRISTAR/NUTRIPORT CONTINUOUS": "ENTRISTAR/NUTRIPORT CONTINUOUS",
              "KANGAROO 924 SAFETY SCREW SET": "KANGAROO 924 SAFETY SCREW SET",
              "KANGAROO BALLOON 16FR/2.5CM": "KANGAROO BALLOON 16FR/2.5CM",
              "KANGAROO BALLOON 20FR/2.5CM": "KANGAROO BALLOON 20FR/2.5CM",
              "KANGAROO BALLOON 20FR/2.7CM": "KANGAROO BALLOON 20FR/2.7CM",
              "KANGAROO BALLOON 20FR/3.5CM": "KANGAROO BALLOON 20FR/3.5CM",
              "KANGAROO BALLOON 20FR/3CM": "KANGAROO BALLOON 20FR/3CM",
              "KANGAROO BALLOON 20FR/4.5CM": "KANGAROO BALLOON 20FR/4.5CM",
              "KANGAROO BALLOON 20FR/4CM": "KANGAROO BALLOON 20FR/4CM",
              "KANGAROO BALLOON 20FR/5CM": "KANGAROO BALLOON 20FR/5CM",
              "KANGAROO BALLOON 24FR/0.8CM": "KANGAROO BALLOON 24FR/0.8CM",
              "KANGAROO BALLOON 24FR/1.2CM": "KANGAROO BALLOON 24FR/1.2CM",
              "KANGAROO BALLOON 24FR/1.5CM": "KANGAROO BALLOON 24FR/1.5CM",
              "KANGAROO BALLOON 24FR/1.7CM": "KANGAROO BALLOON 24FR/1.7CM",
              "KANGAROO BALLOON 24FR/1CM": "KANGAROO BALLOON 24FR/1CM",
              "KANGAROO BALLOON 24FR/2.3CM": "KANGAROO BALLOON 24FR/2.3CM",
              "KANGAROO BALLOON 24FR/2.5CM": "KANGAROO BALLOON 24FR/2.5CM",
              "KANGAROO BALLOON 24FR/2.7CM": "KANGAROO BALLOON 24FR/2.7CM",
              "KANGAROO BALLOON 24FR/2CM": "KANGAROO BALLOON 24FR/2CM",
              "KANGAROO BALLOON 24FR/3.5CM": "KANGAROO BALLOON 24FR/3.5CM",
              "KANGAROO BALLOON 24FR/3CM": "KANGAROO BALLOON 24FR/3CM",
              "KANGAROO DELUXE EZ CAP PUMP": "KANGAROO DELUXE EZ CAP PUMP",
              "KANGAROO DELUXE EZ PUMP SET": "KANGAROO DELUXE EZ PUMP SET",
              "KANGAROO ENTERAL PUMP K224": "KANGAROO ENTERAL PUMP K224",
              "KANGAROO ENTERAL PUMP K324": "KANGAROO ENTERAL PUMP K324",
              "KANGAROO ENTRIFLUSH PUMP SET": "KANGAROO ENTRIFLUSH PUMP SET",
              "KANGAROO EPUMP JOEY BURETTE": "KANGAROO EPUMP JOEY BURETTE",
              "KANGAROO EPUMP PROXIMAL SET": "KANGAROO EPUMP PROXIMAL SET",
              "KANGAROO EPUMP PUMP SET/1000ML": "KANGAROO EPUMP PUMP SET/1000ML",
              "KANGAROO EPUMP PUMP SET/100ML": "KANGAROO EPUMP PUMP SET/100ML",
              "KANGAROO EPUMP SET 1000ML": "KANGAROO EPUMP SET 1000ML",
              "KANGAROO EPUMP SET 500ML": "KANGAROO EPUMP SET 500ML",
              "KANGAROO EXTENSION SET": "KANGAROO EXTENSION SET",
              "KANGAROO EZ CAP BAG": "KANGAROO EZ CAP BAG",
              "KANGAROO EZ CAP PUMP SET": "KANGAROO EZ CAP PUMP SET",
              "KANGAROO EZ CAP PUMP SET/500ML": "KANGAROO EZ CAP PUMP SET/500ML",
              "KANGAROO FEED/IRRIGATION KIT": "KANGAROO FEED/IRRIGATION KIT",
              "KANGAROO FEEDING TUBE 10FR/36\"\"": "KANGAROO FEEDING TUBE 10FR/36\"\"",
              "KANGAROO FEEDING TUBE 10FR/42\"\"": "KANGAROO FEEDING TUBE 10FR/42\"\"",
              "KANGAROO FEEDING TUBE 10FR/43\"\"": "KANGAROO FEEDING TUBE 10FR/43\"\"",
              "KANGAROO FEEDING TUBE 12FR/36\"\"": "KANGAROO FEEDING TUBE 12FR/36\"\"",
              "KANGAROO FEEDING TUBE 12FR/43\"\"": "KANGAROO FEEDING TUBE 12FR/43\"\"",
              "KANGAROO FEEDING TUBE 14FR/36\"\"": "KANGAROO FEEDING TUBE 14FR/36\"\"",
              "KANGAROO FEEDING TUBE 3.5FR/12": "KANGAROO FEEDING TUBE 3.5FR/12",
              "KANGAROO FEEDING TUBE 5FR/20\"\"": "KANGAROO FEEDING TUBE 5FR/20\"\"",
              "KANGAROO FEEDING TUBE 5FR/36\"\"": "KANGAROO FEEDING TUBE 5FR/36\"\"",
              "KANGAROO FEEDING TUBE 6.5FR/20": "KANGAROO FEEDING TUBE 6.5FR/20",
              "KANGAROO FEEDING TUBE 6.5FR/36": "KANGAROO FEEDING TUBE 6.5FR/36",
              "KANGAROO FEEDING TUBE 6FR/20\"\"": "KANGAROO FEEDING TUBE 6FR/20\"\"",
              "KANGAROO FEEDING TUBE 6FR/36\"\"": "KANGAROO FEEDING TUBE 6FR/36\"\"",
              "KANGAROO FEEDING TUBE 8FR/20\"\"": "KANGAROO FEEDING TUBE 8FR/20\"\"",
              "KANGAROO FEEDING TUBE 8FR/36\"\"": "KANGAROO FEEDING TUBE 8FR/36\"\"",
              "KANGAROO FEEDING TUBE 8FR/42\"\"": "KANGAROO FEEDING TUBE 8FR/42\"\"",
              "KANGAROO FEEDING TUBE 8FR/43\"\"": "KANGAROO FEEDING TUBE 8FR/43\"\"",
              "KANGAROO FEEDING TUBE 8FR/55\"\"": "KANGAROO FEEDING TUBE 8FR/55\"\"",
              "KANGAROO GASTROSTOMY TUBE": "KANGAROO GASTROSTOMY TUBE",
              "KANGAROO GASTROSTOMY TUBE/12FR": "KANGAROO GASTROSTOMY TUBE/12FR",
              "KANGAROO GASTROSTOMY TUBE/14FR": "KANGAROO GASTROSTOMY TUBE/14FR",
              "KANGAROO GASTROSTOMY TUBE/16FR": "KANGAROO GASTROSTOMY TUBE/16FR",
              "KANGAROO GASTROSTOMY TUBE/18FR": "KANGAROO GASTROSTOMY TUBE/18FR",
              "KANGAROO GASTROSTOMY TUBE/20FR": "KANGAROO GASTROSTOMY TUBE/20FR",
              "KANGAROO GASTROSTOMY TUBE/22FR": "KANGAROO GASTROSTOMY TUBE/22FR",
              "KANGAROO GASTROSTOMY TUBE/24FR": "KANGAROO GASTROSTOMY TUBE/24FR",
              "KANGAROO GASTROSTOMY TUBE/26FR": "KANGAROO GASTROSTOMY TUBE/26FR",
              "KANGAROO GASTROSTOMY TUBE/28FR": "KANGAROO GASTROSTOMY TUBE/28FR",
              "KANGAROO GRAVITY FEEDING BAG": "KANGAROO GRAVITY FEEDING BAG",
              "KANGAROO GRAVITY FEEDING SET": "KANGAROO GRAVITY FEEDING SET",
              "KANGAROO GRAVITY SET": "KANGAROO GRAVITY SET",
              "KANGAROO JEJUNAL FEED TUBE 9FR": "KANGAROO JEJUNAL FEED TUBE 9FR",
              "KANGAROO JOEY ENTERAL PUMP": "KANGAROO JOEY ENTERAL PUMP",
              "KANGAROO JOEY PUMP SET": "KANGAROO JOEY PUMP SET",
              "KANGAROO JOEY PUMP SET/500ML": "KANGAROO JOEY PUMP SET/500ML",
              "KANGAROO MULTI-FUNCTIONAL PORT": "KANGAROO MULTI-FUNCTIONAL PORT",
              "KANGAROO NASO-JEJUNAL TB 12FR": "KANGAROO NASO-JEJUNAL TB 12FR",
              "KANGAROO NASO-JEJUNAL TB 16FR": "KANGAROO NASO-JEJUNAL TB 16FR",
              "KANGAROO PED NG STYLET 20\"\"": "KANGAROO PED NG STYLET 20\"\"",
              "KANGAROO PED NG STYLET 36\"\"": "KANGAROO PED NG STYLET 36\"\"",
              "KANGAROO PROXIMAL SPIKE PUMP": "KANGAROO PROXIMAL SPIKE PUMP",
              "KANGAROO PROXIMAL SPIKE SET": "KANGAROO PROXIMAL SPIKE SET",
              "KANGAROO PUMP SET 1000ML": "KANGAROO PUMP SET 1000ML",
              "KANGAROO PUMP SET 1600ML": "KANGAROO PUMP SET 1600ML",
              "KANGAROO PUMP SET 600ML": "KANGAROO PUMP SET 600ML",
              "KANGAROO PUMP SET DELUXE BAG": "KANGAROO PUMP SET DELUXE BAG",
              "KANGAROO PUMP SET EXT TUBING": "KANGAROO PUMP SET EXT TUBING",
              "KANGAROO PUMP SET SCREW CAP": "KANGAROO PUMP SET SCREW CAP",
              "KANGAROO PUMP SET/ICE POUCH": "KANGAROO PUMP SET/ICE POUCH",
              "KANGAROO PVC FEEDING TUBE": "KANGAROO PVC FEEDING TUBE",
              "KANGAROO RIGID CONTAINER PUMP": "KANGAROO RIGID CONTAINER PUMP",
              "KANGAROO SAFETY PEG KIT 16FR": "KANGAROO SAFETY PEG KIT 16FR",
              "KANGAROO SAFETY PEG KIT 20FR": "KANGAROO SAFETY PEG KIT 20FR",
              "KANGAROO SCREW CAP PUMP SET": "KANGAROO SCREW CAP PUMP SET",
              "KANGAROO SPIKE PUMP SET": "KANGAROO SPIKE PUMP SET",
              "KANGAROO Y-SITE EXTENSION": "KANGAROO Y-SITE EXTENSION",
              "KANGAROO Y-SITE EXTENSION SET": "KANGAROO Y-SITE EXTENSION SET",
              "SALEM SUMP SILICONE TUBE 10FR": "SALEM SUMP SILICONE TUBE 10FR",
              "SALEM SUMP SILICONE TUBE 12FR": "SALEM SUMP SILICONE TUBE 12FR",
              "SALEM SUMP SILICONE TUBE 14FR": "SALEM SUMP SILICONE TUBE 14FR",
              "SALEM SUMP SILICONE TUBE 16FR": "SALEM SUMP SILICONE TUBE 16FR",
              "SALEM SUMP SILICONE TUBE 18FR": "SALEM SUMP SILICONE TUBE 18FR",
              "SALEM SUMP TUBE 10FR": "SALEM SUMP TUBE 10FR",
              "SALEM SUMP TUBE 12FR": "SALEM SUMP TUBE 12FR",
              "SALEM SUMP TUBE 14FR": "SALEM SUMP TUBE 14FR",
              "SALEM SUMP TUBE 16FR": "SALEM SUMP TUBE 16FR",
              "SALEM SUMP TUBE 18FR": "SALEM SUMP TUBE 18FR"
            },
            "HOLLISTER": {
              "FEEDING TUBE ATTACHMENT DEVICE": "FEEDING TUBE ATTACHMENT DEVICE"
            },
            "KIMBERLY-CLARK DIGESTIVE": {
              "MIC GASTROSTOMY TUBE 20FR": "MIC GASTROSTOMY TUBE 20FR",
              "MIC-KEY GASTROSTOMY KIT 12FR": "MIC-KEY GASTROSTOMY KIT 12FR",
              "MIC-KEY GASTROSTOMY KIT 14FR": "MIC-KEY GASTROSTOMY KIT 14FR",
              "MIC-KEY GASTROSTOMY KIT 16FR": "MIC-KEY GASTROSTOMY KIT 16FR",
              "MIC-KEY GASTROSTOMY KIT 18FR": "MIC-KEY GASTROSTOMY KIT 18FR",
              "MIC-KEY GASTROSTOMY KIT 20FR": "MIC-KEY GASTROSTOMY KIT 20FR",
              "MIC-KEY GASTROSTOMY KIT 24FR": "MIC-KEY GASTROSTOMY KIT 24FR"
            },
            "NESTLE HEALTHCARE NUTRITION": {
              "COMPAT 7FR NCJ KIT": "COMPAT 7FR NCJ KIT",
              "COMPAT GASTR TUBE/15ML BALLOON": "COMPAT GASTR TUBE/15ML BALLOON",
              "COMPAT GASTRO TUBE/5ML BALLOON": "COMPAT GASTRO TUBE/5ML BALLOON",
              "COMPAT GASTROJEJUNOSTOMY TUBE": "COMPAT GASTROJEJUNOSTOMY TUBE",
              "COMPAT GASTROTUBE 14FR/5ML": "COMPAT GASTROTUBE 14FR/5ML",
              "COMPAT GASTROTUBE 16FR/10ML": "COMPAT GASTROTUBE 16FR/10ML",
              "COMPAT GASTROTUBE 18FR/15ML": "COMPAT GASTROTUBE 18FR/15ML",
              "COMPAT GASTROTUBE 20FR/15ML": "COMPAT GASTROTUBE 20FR/15ML",
              "COMPAT GASTROTUBE 22FR/15ML": "COMPAT GASTROTUBE 22FR/15ML",
              "COMPAT GASTROTUBE 24FR/15ML": "COMPAT GASTROTUBE 24FR/15ML",
              "ENTERALITE INFINITY PUMP": "ENTERALITE INFINITY PUMP",
              "ENTERALITE INFINITY PUMP SET": "ENTERALITE INFINITY PUMP SET",
              "VIVONEX JEJUNOSTOMY KIT": "VIVONEX JEJUNOSTOMY KIT"
            }
          }
        },
        "Female Personal Care Products": {
          "Sanitary Napkins & Tampons": {
            "CHAIN DRUG CONSORTIUM": {
              "EVERYDAY PANTILINERS LONG": "EVERYDAY PANTILINERS LONG",
              "MAXI PAD LONG ULTRA THIN": "MAXI PAD LONG ULTRA THIN",
              "MAXI PAD OVERNIGHT": "MAXI PAD OVERNIGHT",
              "MAXI PAD OVERNIGHT THIN": "MAXI PAD OVERNIGHT THIN",
              "MAXI PAD REGULAR": "MAXI PAD REGULAR",
              "MAXI PAD SUPER": "MAXI PAD SUPER",
              "MAXI PAD SUPER LONG": "MAXI PAD SUPER LONG",
              "MAXI PAD ULTRA THIN": "MAXI PAD ULTRA THIN",
              "PANTILINERS": "PANTILINERS",
              "PANTILINERS CONTOURED": "PANTILINERS CONTOURED",
              "PANTILINERS LONG": "PANTILINERS LONG",
              "PANTILINERS THONG": "PANTILINERS THONG",
              "TAMPONS MULTI ABSORBENCY": "TAMPONS MULTI ABSORBENCY",
              "TAMPONS REGULAR ABSORBENCY": "TAMPONS REGULAR ABSORBENCY",
              "TAMPONS SUPER ABSORBENCY": "TAMPONS SUPER ABSORBENCY",
              "TAMPONS SUPER PLUS ABSORBENCY": "TAMPONS SUPER PLUS ABSORBENCY"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS MAXI OVERNIGHT": "CVS MAXI OVERNIGHT"
            },
            "EQUALINE": {
              "EQL MAXI FLEX-WINGS/LONG/SUPER": "EQL MAXI FLEX-WINGS/LONG/SUPER",
              "EQL MAXI FLEX-WINGS/MODERATE": "EQL MAXI FLEX-WINGS/MODERATE",
              "EQL MAXI FLEX-WINGS/OVERNIGHT": "EQL MAXI FLEX-WINGS/OVERNIGHT",
              "EQL MAXI MODERATE": "EQL MAXI MODERATE",
              "EQL MAXI REGULAR": "EQL MAXI REGULAR",
              "EQL PANTILINERS": "EQL PANTILINERS",
              "EQL PERSONAL CONFIDENCE LINER": "EQL PERSONAL CONFIDENCE LINER",
              "EQL PERSONAL CONFIDENCE MAXI": "EQL PERSONAL CONFIDENCE MAXI",
              "EQL SUPER MAXI": "EQL SUPER MAXI",
              "EQL TAMPONS MULTIPACK": "EQL TAMPONS MULTIPACK",
              "EQL TAMPONS REGULAR": "EQL TAMPONS REGULAR",
              "EQL TAMPONS REGULAR ABSORBENCY": "EQL TAMPONS REGULAR ABSORBENCY",
              "EQL TAMPONS SUPER": "EQL TAMPONS SUPER",
              "EQL TAMPONS SUPER ABSORBENCY": "EQL TAMPONS SUPER ABSORBENCY",
              "EQL THIN LINERS REGULAR": "EQL THIN LINERS REGULAR",
              "EQL ULTRA THIN/FLEX-WINGS REG": "EQL ULTRA THIN/FLEX-WINGS REG",
              "EQL ULTRA THIN/FLEXI-WINGS": "EQL ULTRA THIN/FLEXI-WINGS"
            },
            "INTERNATIONAL PLAYTEX": {
              "STAYFREE ULTRA THIN WING NIGHT": "STAYFREE ULTRA THIN WING NIGHT"
            },
            "KIMBERLY-CLARK": {
              "KOTEX CURVED MAXI": "KOTEX CURVED MAXI",
              "KOTEX LIGHTDAYS PANTILINERS": "KOTEX LIGHTDAYS PANTILINERS",
              "KOTEX MAXI": "KOTEX MAXI",
              "KOTEX MAXI OVERNITE": "KOTEX MAXI OVERNITE",
              "KOTEX MAXI WITH WINGS": "KOTEX MAXI WITH WINGS",
              "KOTEX OVERNITE": "KOTEX OVERNITE",
              "KOTEX SECURITY TAMPONS MLTIPK": "KOTEX SECURITY TAMPONS MLTIPK",
              "KOTEX SECURITY TAMPONS REGULAR": "KOTEX SECURITY TAMPONS REGULAR",
              "KOTEX SECURITY TAMPONS SUPER": "KOTEX SECURITY TAMPONS SUPER",
              "KOTEX SECURITY TAMPONS SUPR PL": "KOTEX SECURITY TAMPONS SUPR PL",
              "KOTEX SUPER MAXI": "KOTEX SUPER MAXI",
              "KOTEX THIN MAXI": "KOTEX THIN MAXI",
              "KOTEX ULTRA COMPACT MAXI": "KOTEX ULTRA COMPACT MAXI",
              "KOTEX ULTRA MAXI OVERNIGHT": "KOTEX ULTRA MAXI OVERNIGHT",
              "KOTEX ULTRA THIN MAXI": "KOTEX ULTRA THIN MAXI",
              "KOTEX ULTRA THIN MAXI LONG": "KOTEX ULTRA THIN MAXI LONG",
              "LIGHTDAYS LONG PANTYLINER": "LIGHTDAYS LONG PANTYLINER"
            },
            "P & G PAPER PRODUCTS": {
              "ALWAYS MAXI MAXIMUM PROTECTION": "ALWAYS MAXI MAXIMUM PROTECTION",
              "ALWAYS PANTILINERS/THONG": "ALWAYS PANTILINERS/THONG",
              "ALWAYS ULTRA OVERNIGHT/WINGS": "ALWAYS ULTRA OVERNIGHT/WINGS",
              "ALWAYS ULTRA THIN": "ALWAYS ULTRA THIN",
              "TAMPAX": "TAMPAX"
            },
            "RITE AID CORPORATION": {
              "RA MAXI PADS LONG": "RA MAXI PADS LONG",
              "RA MAXI PADS REGULAR": "RA MAXI PADS REGULAR",
              "RA MAXI SUPER": "RA MAXI SUPER",
              "RA PANTILINERS": "RA PANTILINERS",
              "RA PANTILINERS LONG": "RA PANTILINERS LONG",
              "RA PANTY LINERS": "RA PANTY LINERS",
              "RA PANTY SHIELDS": "RA PANTY SHIELDS"
            },
            "WAL-MART": {
              "EQ EVERYDAY LINERS LONG": "EQ EVERYDAY LINERS LONG",
              "EQ MAXI LONG SUPER": "EQ MAXI LONG SUPER",
              "EQ MAXI OVERNIGHT": "EQ MAXI OVERNIGHT",
              "EQ MAXI OVERNIGHT EXTRA HEAVY": "EQ MAXI OVERNIGHT EXTRA HEAVY",
              "EQ MAXI REGULAR": "EQ MAXI REGULAR",
              "EQ MAXI SUPER": "EQ MAXI SUPER",
              "EQ PANTILINERS": "EQ PANTILINERS",
              "EQ TAMPONS MULTI ABSORBENCY": "EQ TAMPONS MULTI ABSORBENCY",
              "EQ TAMPONS REGULAR": "EQ TAMPONS REGULAR",
              "EQ TAMPONS SUPER": "EQ TAMPONS SUPER",
              "EQ TAMPONS SUPER PLUS": "EQ TAMPONS SUPER PLUS",
              "EQ THIN LINER REGULAR": "EQ THIN LINER REGULAR",
              "EQ ULTRA THIN OVERNIGHT": "EQ ULTRA THIN OVERNIGHT",
              "EQ ULTRA THIN REGULAR": "EQ ULTRA THIN REGULAR",
              "EQ ULTRA THIN SUPER LONG": "EQ ULTRA THIN SUPER LONG"
            },
            "WALGREENS": {
              "MAXI PAD DEODORANT": "MAXI PAD DEODORANT",
              "MAXI PAD OVERNIGHT": "MAXI PAD OVERNIGHT",
              "MAXI PAD REGULAR": "MAXI PAD REGULAR",
              "MAXI PAD SUPER": "MAXI PAD SUPER",
              "MAXI PAD ULTRA THIN": "MAXI PAD ULTRA THIN",
              "MAXI PAD ULTRA THIN OVERNIGHT": "MAXI PAD ULTRA THIN OVERNIGHT",
              "MAXI WITH FLEX-WINGS": "MAXI WITH FLEX-WINGS",
              "MAXI WITH FLEXI-WINGS": "MAXI WITH FLEXI-WINGS",
              "PANTILINERS": "PANTILINERS",
              "PANTILINERS LONG": "PANTILINERS LONG",
              "PANTILINERS THONG": "PANTILINERS THONG",
              "PANTILINERS TO GO": "PANTILINERS TO GO",
              "PANTILINERS UNSCENTED LONG": "PANTILINERS UNSCENTED LONG",
              "PERFECTION MULTIPACK": "PERFECTION MULTIPACK",
              "PERFECTION SILK SUPER ABSORB": "PERFECTION SILK SUPER ABSORB",
              "TAMPONS MULTI ABSORBENCY": "TAMPONS MULTI ABSORBENCY",
              "TAMPONS MULTIPACK": "TAMPONS MULTIPACK",
              "TAMPONS SUPER ABSORBENCY": "TAMPONS SUPER ABSORBENCY",
              "TAMPONS SUPER PLUS ABSORBENCY": "TAMPONS SUPER PLUS ABSORBENCY",
              "ULTRA THIN WITH FLEXI-WINGS": "ULTRA THIN WITH FLEXI-WINGS"
            }
          }
        },
        "First Aid Kits": {
          "First Aid Kits": {
            "BEIERSDORF-FUTURO": {
              "CURAD EMERGENCY FIRST AID KIT": "CURAD EMERGENCY FIRST AID KIT",
              "MCKIDS FIRST AID": "MCKIDS FIRST AID"
            },
            "BERGEN BRUNSWIG": {
              "GNP FIRST AID/MINI": "GNP FIRST AID/MINI"
            },
            "CHAIN DRUG CONSORTIUM": {
              "FIRST AID KIT GENERAL PURPOSE": "FIRST AID KIT GENERAL PURPOSE"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS FIRST AID": "CVS FIRST AID",
              "CVS READINESS ESSENTIALS": "CVS READINESS ESSENTIALS",
              "CVS TRAVEL FIRST AID KIT": "CVS TRAVEL FIRST AID KIT"
            },
            "J & J CONSUMER PRODUCTS": {
              "J & J ALL-PURPOSE FIRST AID": "J & J ALL-PURPOSE FIRST AID",
              "J & J FIRST AID SAFE TRAVELS": "J & J FIRST AID SAFE TRAVELS",
              "J & J FIRST AID TO GO": "J & J FIRST AID TO GO"
            },
            "J & J MEDICAL": {
              "FIRST AID KIT INDUSTRIAL": "FIRST AID KIT INDUSTRIAL",
              "FIRST AID KIT METAL": "FIRST AID KIT METAL",
              "FIRST AID REFILL PACK": "FIRST AID REFILL PACK"
            },
            "LEADER BRAND PRODUCTS": {
              "FIRST AID": "FIRST AID",
              "FIRST AID KIT ALL PURPOSE": "FIRST AID KIT ALL PURPOSE"
            },
            "MAJESTIC DRUG": {
              "KUTKIT": "KUTKIT"
            },
            "MEDISCA": {
              "FIRST AID KIT/10 PERSON": "FIRST AID KIT/10 PERSON"
            },
            "RITE AID CORPORATION": {
              "RA ALL-PURPOSE FIRST AID KIT": "RA ALL-PURPOSE FIRST AID KIT"
            },
            "WALGREENS": {
              "FIRST AID BUILD YOUR OWN": "FIRST AID BUILD YOUR OWN"
            }
          }
        },
        "Fixed (Rigid) Bandages/Supports & Accessories": {
          "Casts, Splints & Accessories": {
            "INTEGRA LIFESCIENCES": {
              "TCC-EZ TOTAL CONTACT CAST SYS": "TCC-EZ TOTAL CONTACT CAST SYS"
            }
          }
        },
        "Foot Care Products": {
          "Foot Care Products": {
            "3M CONSUMER HEALTH CARE": {
              "FUTURO THERAPEUTIC ARCH SUPPRT": "FUTURO THERAPEUTIC ARCH SUPPRT"
            },
            "APOTHECARY PRODUCTS, INC.": {
              "SOFT FOAM TOE SEPARATORS MED": "SOFT FOAM TOE SEPARATORS MED"
            },
            "BAUSCH HEALTH": {
              "SLEEP-N-HEEL NGHT HEEL SLEEVES": "SLEEP-N-HEEL NGHT HEEL SLEEVES"
            },
            "BAYER CONSUMER": {
              "DR SCHOLLS CORN CUSHION": "DR SCHOLLS CORN CUSHION",
              "DR SCHOLLS CUSTOM FIT ORTHOTIC": "DR SCHOLLS CUSTOM FIT ORTHOTIC",
              "DYNASTEP/MEN": "DYNASTEP/MEN",
              "DYNASTEP/WOMEN": "DYNASTEP/WOMEN",
              "GEL HEEL CUSHIONS": "GEL HEEL CUSHIONS",
              "MOLDED HEEL CUSHIONS/MEN": "MOLDED HEEL CUSHIONS/MEN",
              "MOLDED HEEL CUSHIONS/WOMEN": "MOLDED HEEL CUSHIONS/WOMEN",
              "MOLESKIN PLUS ROLL": "MOLESKIN PLUS ROLL",
              "SUEDE HEEL GRIPS": "SUEDE HEEL GRIPS"
            },
            "CHAIN DRUG CONSORTIUM": {
              "AIR FOAM INSOLES MENS": "AIR FOAM INSOLES MENS",
              "AIR FOAM INSOLES WOMENS": "AIR FOAM INSOLES WOMENS",
              "CALLUS CUSHIONS": "CALLUS CUSHIONS",
              "CORN CUSHIONS": "CORN CUSHIONS",
              "DIABETIC INSOLES": "DIABETIC INSOLES",
              "DOUBLE AIR FOAM INSOLES MENS": "DOUBLE AIR FOAM INSOLES MENS",
              "FOAM TOE BANDAGES": "FOAM TOE BANDAGES",
              "FOOT POWDER": "FOOT POWDER",
              "FOOT SOAK": "FOOT SOAK",
              "GEL HEEL CUSHIONS MENS": "GEL HEEL CUSHIONS MENS",
              "GEL HEEL CUSHIONS WOMENS": "GEL HEEL CUSHIONS WOMENS",
              "GEL INSOLES MENS": "GEL INSOLES MENS",
              "GEL INSOLES WOMENS": "GEL INSOLES WOMENS",
              "LAMBS WOOL": "LAMBS WOOL",
              "SUPER MOLESKIN": "SUPER MOLESKIN",
              "TRI-BALANCE ORTHOTICS MENS": "TRI-BALANCE ORTHOTICS MENS",
              "TRI-BALANCE ORTHOTICS WOMENS": "TRI-BALANCE ORTHOTICS WOMENS",
              "WORK INSOLES MENS": "WORK INSOLES MENS"
            },
            "CHATTEM INC": {
              "GOLD BOND FOOT POWDER MAX ST": "GOLD BOND FOOT POWDER MAX ST",
              "GOLD BOND ULTIM HEALING FOOT": "GOLD BOND ULTIM HEALING FOOT"
            },
            "COMBE": {
              "JOHNSONS FOOT SOAP": "JOHNSONS FOOT SOAP",
              "JOHNSONS FOOT SPRAY": "JOHNSONS FOOT SPRAY",
              "ODOR EATERS": "ODOR EATERS",
              "ODOR EATERS FOOT": "ODOR EATERS FOOT",
              "ODOR EATERS SUPER TUFF": "ODOR EATERS SUPER TUFF",
              "ODOR EATERS ULTRA COMFORT": "ODOR EATERS ULTRA COMFORT",
              "ODOR EATERS ULTRA DURABLE": "ODOR EATERS ULTRA DURABLE"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS ADVANCED GEL ORTHOTICS": "CVS ADVANCED GEL ORTHOTICS",
              "CVS CALLUS CUSHION": "CVS CALLUS CUSHION",
              "CVS CALLUS REMOVER": "CVS CALLUS REMOVER",
              "CVS CORN CUSHION": "CVS CORN CUSHION",
              "CVS FOOT POWDER": "CVS FOOT POWDER",
              "CVS GEL HEEL CUSHION WOMENS": "CVS GEL HEEL CUSHION WOMENS",
              "CVS MOLESKIN PADDING": "CVS MOLESKIN PADDING",
              "CVS MOLESKIN PLUS": "CVS MOLESKIN PLUS",
              "CVS ODOR ABSORBING INSOLES": "CVS ODOR ABSORBING INSOLES"
            },
            "EQUALINE": {
              "EQL DUAL GEL INSOLES WOMENS": "EQL DUAL GEL INSOLES WOMENS",
              "EQL PUMICE STONE/ROPE": "EQL PUMICE STONE/ROPE",
              "EQL TOENAIL CLIPPER": "EQL TOENAIL CLIPPER"
            },
            "ESSENTIAL MEDICAL SUPPLY": {
              "SHOE HORN": "SHOE HORN",
              "SOCK AID": "SOCK AID"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "DESENEX FOOT/SNEAKER SPRAY": "DESENEX FOOT/SNEAKER SPRAY"
            },
            "GORDON": {
              "BROMI-TALC": "BROMI-TALC",
              "BROMI-TALC PLUS": "BROMI-TALC PLUS",
              "GORDOMATIC": "GORDOMATIC",
              "MYCOMIST": "MYCOMIST"
            },
            "HOME AIDE DIAGNOSTICS": {
              "FOOT COMFORT STABILIZER": "FOOT COMFORT STABILIZER"
            },
            "J & J CONSUMER PRODUCTS": {
              "BAND-AID FRICTION BLOCK": "BAND-AID FRICTION BLOCK",
              "BAND-AID HYDRO SEAL CORN CUSH": "BAND-AID HYDRO SEAL CORN CUSH",
              "BAND-AID HYDRO SEAL HEELS": "BAND-AID HYDRO SEAL HEELS",
              "BAND-AID HYDRO SEAL TOES": "BAND-AID HYDRO SEAL TOES"
            },
            "J & J MEDICAL": {
              "BAND-AID MOLESKIN ADHESIVE": "BAND-AID MOLESKIN ADHESIVE"
            },
            "LEADER BRAND PRODUCTS": {
              "CALLUS REMOVER": "CALLUS REMOVER",
              "CALLUS/CORN SHAVER": "CALLUS/CORN SHAVER",
              "ODOR CONTROL INSOLES MEN/WOMEN": "ODOR CONTROL INSOLES MEN/WOMEN",
              "SUPER MOLESKIN": "SUPER MOLESKIN"
            },
            "MCKESSON SUNMARK": {
              "SM BUNION CUSHIONS": "SM BUNION CUSHIONS",
              "SM CALLUS CUSHIONS": "SM CALLUS CUSHIONS",
              "SM CORN CUSHIONS": "SM CORN CUSHIONS",
              "SM LAMBS WOOL": "SM LAMBS WOOL",
              "SM MOLESKIN PLUS": "SM MOLESKIN PLUS"
            },
            "MEDICINE SHOPPE": {
              "MEDICOOLS DIASOX": "MEDICOOLS DIASOX"
            },
            "PEDINOL": {
              "SLEEP-N-HEEL+ HEEL SLEEVES": "SLEEP-N-HEEL+ HEEL SLEEVES"
            },
            "PROFOOT": {
              "PROFOOT PLANTAR FASCIITIS": "PROFOOT PLANTAR FASCIITIS"
            },
            "RITE AID CORPORATION": {
              "RA ARCH SLEEVE": "RA ARCH SLEEVE",
              "RA BUNION CUSHION": "RA BUNION CUSHION",
              "RA CALLUS CUSHIONS": "RA CALLUS CUSHIONS",
              "RA CORN CUSHIONS SMALL": "RA CORN CUSHIONS SMALL",
              "RA CUSHION INSOLES MENS": "RA CUSHION INSOLES MENS",
              "RA CUSHION INSOLES WOMENS": "RA CUSHION INSOLES WOMENS",
              "RA FOAM INSOLES WOMENS": "RA FOAM INSOLES WOMENS",
              "RA FOOT POWDER": "RA FOOT POWDER",
              "RA GEL HAMMER TOE CUSHION": "RA GEL HAMMER TOE CUSHION",
              "RA GEL HEEL CUSHIONS MENS": "RA GEL HEEL CUSHIONS MENS",
              "RA GEL TOE SEPARATOR": "RA GEL TOE SEPARATOR",
              "RA LAMBS WOOL": "RA LAMBS WOOL",
              "RA NAIL CLIPPER": "RA NAIL CLIPPER",
              "RA PUMICE STONE": "RA PUMICE STONE",
              "RA RENEWAL TOENAIL NIPPER": "RA RENEWAL TOENAIL NIPPER",
              "RA SUPER MOLESKIN": "RA SUPER MOLESKIN",
              "RA TOENAIL CLIPPER": "RA TOENAIL CLIPPER"
            },
            "SAGE PRODUCTS": {
              "PREVALON": "PREVALON",
              "PREVALON FOOT/LEG WEDGE": "PREVALON FOOT/LEG WEDGE",
              "PREVALON HEEL PROTECTOR": "PREVALON HEEL PROTECTOR",
              "PREVALON HEEL PROTECTOR/PETITE": "PREVALON HEEL PROTECTOR/PETITE",
              "PREVALON HEEL PROTECTOR/WEDGE": "PREVALON HEEL PROTECTOR/WEDGE"
            },
            "SOLUBIOMIX LLC": {
              "BIOFREQUENCY INSOLES": "BIOFREQUENCY INSOLES"
            },
            "SPENCO MEDICAL CORPORATION": {
              "SPENCO ARCH SUPPORT INSOLES": "SPENCO ARCH SUPPORT INSOLES",
              "SPENCO ARTHRITIS FOOT CRADLES": "SPENCO ARTHRITIS FOOT CRADLES",
              "SPENCO COMFORT INSOLES": "SPENCO COMFORT INSOLES",
              "SPENCO FOR HER INSOLES": "SPENCO FOR HER INSOLES",
              "SPENCO GEL ARCH INSOLES LARGE": "SPENCO GEL ARCH INSOLES LARGE",
              "SPENCO GEL ARCH INSOLES MEDIUM": "SPENCO GEL ARCH INSOLES MEDIUM",
              "SPENCO GEL ARCH INSOLES SMALL": "SPENCO GEL ARCH INSOLES SMALL",
              "SPENCO GEL BALL OF FOOT ONE SZ": "SPENCO GEL BALL OF FOOT ONE SZ",
              "SPENCO GEL HEEL CUP MED/LG": "SPENCO GEL HEEL CUP MED/LG",
              "SPENCO GEL HEEL CUP SM/MED": "SPENCO GEL HEEL CUP SM/MED",
              "SPENCO GEL HEEL CUSHIONS": "SPENCO GEL HEEL CUSHIONS",
              "SPENCO GEL HEEL INSOLES ONE SZ": "SPENCO GEL HEEL INSOLES ONE SZ",
              "SPENCO GEL INSOLES": "SPENCO GEL INSOLES",
              "SPENCO IRONMAN GEL INSOLES LG": "SPENCO IRONMAN GEL INSOLES LG",
              "SPENCO IRONMAN GEL INSOLES SM": "SPENCO IRONMAN GEL INSOLES SM",
              "SPENCO IRONMAN PLUS INSOLES": "SPENCO IRONMAN PLUS INSOLES",
              "SPENCO KIDS COMFORT INSOLES": "SPENCO KIDS COMFORT INSOLES",
              "SPENCO KIDS POLYSORB INSOLES": "SPENCO KIDS POLYSORB INSOLES",
              "SPENCO ORTHOTIC ARCH SUPPORTS": "SPENCO ORTHOTIC ARCH SUPPORTS",
              "SPENCO POLYSORB INSOLES": "SPENCO POLYSORB INSOLES",
              "SPENCO POLYSORB OS INSOLES": "SPENCO POLYSORB OS INSOLES",
              "SPENCO RX ARCH INSOLES": "SPENCO RX ARCH INSOLES",
              "SPENCO RX BALL OF FOOT LARGE": "SPENCO RX BALL OF FOOT LARGE",
              "SPENCO RX BALL OF FOOT MEDIUM": "SPENCO RX BALL OF FOOT MEDIUM",
              "SPENCO RX BALL OF FOOT SMALL": "SPENCO RX BALL OF FOOT SMALL",
              "SPENCO RX DIABETIC SUPPORT": "SPENCO RX DIABETIC SUPPORT",
              "SPENCO RX HEEL CUSHIONS LARGE": "SPENCO RX HEEL CUSHIONS LARGE",
              "SPENCO RX HEEL CUSHIONS MEDIUM": "SPENCO RX HEEL CUSHIONS MEDIUM",
              "SPENCO RX HEEL CUSHIONS SMALL": "SPENCO RX HEEL CUSHIONS SMALL",
              "SPENCO RX HEEL INSOLES": "SPENCO RX HEEL INSOLES",
              "SPENCO RX INSOLES": "SPENCO RX INSOLES",
              "SPENCO SLIM FIT INSOLES": "SPENCO SLIM FIT INSOLES"
            },
            "SURGICAL APPLIANCE INDUSTRIES": {
              "VALULINE PNEUMATIC LEG WLKR LG": "VALULINE PNEUMATIC LEG WLKR LG",
              "VALULINE PNEUMATIC LEG WLKR MD": "VALULINE PNEUMATIC LEG WLKR MD",
              "VALULINE PNEUMATIC LEG WLKR SM": "VALULINE PNEUMATIC LEG WLKR SM",
              "VALULINE SHORT LEG WALKER LG": "VALULINE SHORT LEG WALKER LG",
              "VALULINE SHORT LEG WALKER MED": "VALULINE SHORT LEG WALKER MED",
              "VALULINE SHORT LEG WALKER SM": "VALULINE SHORT LEG WALKER SM",
              "VALULINE SHORT LEG WALKER XL": "VALULINE SHORT LEG WALKER XL",
              "VALULINE SHORT LEG WALKER XSM": "VALULINE SHORT LEG WALKER XSM"
            },
            "UROCARE PRODUCTS, INC.": {
              "ROLLING FOOT MASSAGER": "ROLLING FOOT MASSAGER"
            },
            "WAL-MART": {
              "EQ FOOT POWDER": "EQ FOOT POWDER"
            },
            "WALGREENS": {
              "AIR FOAM INSOLES MENS": "AIR FOAM INSOLES MENS",
              "AIR FOAM INSOLES WOMENS": "AIR FOAM INSOLES WOMENS",
              "ALL GEL BUNION TOE SPREADER": "ALL GEL BUNION TOE SPREADER",
              "BUNION GUARD": "BUNION GUARD",
              "CALLUS CUSHIONS": "CALLUS CUSHIONS",
              "CALLUS REMOVER": "CALLUS REMOVER",
              "CALLUS/CORN SHAVER": "CALLUS/CORN SHAVER",
              "CALLUS/CORN SHAVER BLADES": "CALLUS/CORN SHAVER BLADES",
              "COMFORT INSOLES WOMENS": "COMFORT INSOLES WOMENS",
              "CORN CUSHIONS": "CORN CUSHIONS",
              "DOUBLE AIR FOAM INSOLES MENS": "DOUBLE AIR FOAM INSOLES MENS",
              "DOUBLE AIR FOAM INSOLES WOMENS": "DOUBLE AIR FOAM INSOLES WOMENS",
              "EASY GRIP CALLUS REMOVER": "EASY GRIP CALLUS REMOVER",
              "EXFOLIATING STONE FILE": "EXFOLIATING STONE FILE",
              "FOAM TOE SEPARATORS": "FOAM TOE SEPARATORS",
              "FOOT POWDER": "FOOT POWDER",
              "FOOT SLEEP SUPPORT": "FOOT SLEEP SUPPORT",
              "FOOT SMOOTHER DUAL SURFACE": "FOOT SMOOTHER DUAL SURFACE",
              "GEL BALL OF FOOT CUSHIONS": "GEL BALL OF FOOT CUSHIONS",
              "GEL CORN CUSHIONS": "GEL CORN CUSHIONS",
              "GEL CORN PROTECTORS": "GEL CORN PROTECTORS",
              "GEL INSOLES MENS": "GEL INSOLES MENS",
              "GEL INSOLES WOMENS": "GEL INSOLES WOMENS",
              "GEL TOE PROTECTOR": "GEL TOE PROTECTOR",
              "GEL TOE SEPARATORS": "GEL TOE SEPARATORS",
              "GEL TOE SPACERS": "GEL TOE SPACERS",
              "HAMMER TOE GEL CUSHION": "HAMMER TOE GEL CUSHION",
              "HEALTH SLIPPERS/UNISEX": "HEALTH SLIPPERS/UNISEX",
              "HEEL LINERS": "HEEL LINERS",
              "MEMORY WORK/SPORT INSOLES": "MEMORY WORK/SPORT INSOLES",
              "MOLESKIN PLUS": "MOLESKIN PLUS",
              "PUMICE STONE": "PUMICE STONE",
              "SPORT & WORK CUSHION INSOLES": "SPORT & WORK CUSHION INSOLES",
              "TOENAIL CLIPPER": "TOENAIL CLIPPER",
              "TOENAIL CLIPPER/FILE DELUXE": "TOENAIL CLIPPER/FILE DELUXE",
              "TOENAIL NIPPER": "TOENAIL NIPPER"
            }
          }
        },
        "GI-GU Ostomy & Irrigation Supplies": {
          "Catheters": {
            "BARD": {
              "BARD CLEAN-CATH": "BARD CLEAN-CATH",
              "BARD COUDE TIP CATHETER": "BARD COUDE TIP CATHETER",
              "BARD DISPOSABLE URO-SHEATH": "BARD DISPOSABLE URO-SHEATH",
              "BARD FEMALE INTERMITTENT CATH": "BARD FEMALE INTERMITTENT CATH",
              "BARD LUBRICATH FOLEY TRAY": "BARD LUBRICATH FOLEY TRAY",
              "BARD LUBRICATH FOLEY TRAY 16FR": "BARD LUBRICATH FOLEY TRAY 16FR",
              "BARD LUBRICATH FOLEY TRAY 18FR": "BARD LUBRICATH FOLEY TRAY 18FR",
              "BARD MALE EXTERNAL CATHETER": "BARD MALE EXTERNAL CATHETER",
              "BARD RUBBER UTILITY CATHETER": "BARD RUBBER UTILITY CATHETER",
              "BARD TIEMANN MODEL CATHETER": "BARD TIEMANN MODEL CATHETER",
              "BARD UNIVERSAL FOLEY CATH TRAY": "BARD UNIVERSAL FOLEY CATH TRAY",
              "BARD URETHRAL CATHETER": "BARD URETHRAL CATHETER",
              "BARD URETHRAL CATHETER 16\"\"": "BARD URETHRAL CATHETER 16\"\"",
              "BARD URETHRAL CATHETER TRAY": "BARD URETHRAL CATHETER TRAY",
              "BARDEX ALL-SILICONE FOLEY CATH": "BARDEX ALL-SILICONE FOLEY CATH",
              "BARDEX FOLEY CATHETER": "BARDEX FOLEY CATHETER",
              "BARDEX I.C. FOLEY CATH 14FR": "BARDEX I.C. FOLEY CATH 14FR",
              "BARDEX LUBRICATH CATHETER": "BARDEX LUBRICATH CATHETER",
              "BARDEX LUBRICATH FOLEY CATH": "BARDEX LUBRICATH FOLEY CATH",
              "BARDEX URETHRAL CATH TRAY": "BARDEX URETHRAL CATH TRAY",
              "BARDIA ALL-SILICONE FOLEY CATH": "BARDIA ALL-SILICONE FOLEY CATH",
              "BARDIA COMPLETE FOLEY": "BARDIA COMPLETE FOLEY",
              "BARDIA FOLEY CATH INSERT TRAY": "BARDIA FOLEY CATH INSERT TRAY",
              "BARDIA FOLEY CATHETER TRAY": "BARDIA FOLEY CATHETER TRAY",
              "BARDIA SILICONE FOLEY CATHETER": "BARDIA SILICONE FOLEY CATHETER",
              "BARDIA UNIVERSAL CATH TRAY": "BARDIA UNIVERSAL CATH TRAY",
              "BARDIA URETHRAL CATH TRAY": "BARDIA URETHRAL CATH TRAY",
              "BARDIA URETHRAL CATHETER 12FR": "BARDIA URETHRAL CATHETER 12FR",
              "BARDIA URETHRAL CATHETER 14FR": "BARDIA URETHRAL CATHETER 14FR",
              "BARDIA URETHRAL CATHETER 16FR": "BARDIA URETHRAL CATHETER 16FR",
              "BARDIC URO-SHEATH EXT CATHETER": "BARDIC URO-SHEATH EXT CATHETER",
              "CLEAN-CATH/COUDE TIP/14FR": "CLEAN-CATH/COUDE TIP/14FR",
              "DAVOL URETHRAL CATHETER": "DAVOL URETHRAL CATHETER",
              "FOLEY CATHETER 2-WAY": "FOLEY CATHETER 2-WAY",
              "MAGIC3 GO INTERMITTENT CATH": "MAGIC3 GO INTERMITTENT CATH",
              "MAGIC3 INTERMITTENT CATHETER": "MAGIC3 INTERMITTENT CATHETER",
              "PERSONAL INTERMITTENT CATHETER": "PERSONAL INTERMITTENT CATHETER",
              "SILASTIC FOLEY CATHETER": "SILASTIC FOLEY CATHETER",
              "SPIRIT HYDROCOLLOID ADH SHEATH": "SPIRIT HYDROCOLLOID ADH SHEATH",
              "URETHRAL CATHETER": "URETHRAL CATHETER",
              "VINYL CATHETER": "VINYL CATHETER",
              "WIDE BAND MALE CATHETER": "WIDE BAND MALE CATHETER"
            },
            "BIODERM": {
              "MEN\\'S LIBERTY MALE EXT CATH": "MEN\\'S LIBERTY MALE EXT CATH"
            },
            "COLOPLAST": {
              "ACTIVE CATH MALE EXTERNAL": "ACTIVE CATH MALE EXTERNAL",
              "CATHETER INSERTION TRAY": "CATHETER INSERTION TRAY",
              "CATHETER NELATION STRAIGHT TIP": "CATHETER NELATION STRAIGHT TIP",
              "CATHETER RED RUBBER COATED": "CATHETER RED RUBBER COATED",
              "CATHETER RED RUBBER COUDE TIP": "CATHETER RED RUBBER COUDE TIP",
              "CATHETER RED RUBBER PEDIATRIC": "CATHETER RED RUBBER PEDIATRIC",
              "CLEAR ADVANTAGE SILICONE CATH": "CLEAR ADVANTAGE SILICONE CATH",
              "CONVEEN CATHETER INSERTION SET": "CONVEEN CATHETER INSERTION SET",
              "CONVEEN INTERMITTENT CATHETER": "CONVEEN INTERMITTENT CATHETER",
              "CONVEEN MALE EXTERNAL": "CONVEEN MALE EXTERNAL",
              "CONVEEN MALE EXTERNAL CATHETER": "CONVEEN MALE EXTERNAL CATHETER",
              "FOLEY CATHETER 2-WAY PEDIATRIC": "FOLEY CATHETER 2-WAY PEDIATRIC",
              "FOLEY CATHETER COUDE TIP": "FOLEY CATHETER COUDE TIP",
              "FOLEY CATHETER LUBRICIOUS": "FOLEY CATHETER LUBRICIOUS",
              "FOLEY CATHETER SILICONE COATED": "FOLEY CATHETER SILICONE COATED",
              "FOLEY CATHETER TIEMANN TIP": "FOLEY CATHETER TIEMANN TIP",
              "FREEDOM CATH MALE EXT CATHETER": "FREEDOM CATH MALE EXT CATHETER",
              "FREEDOM CLEAR LS MALE EXT CATH": "FREEDOM CLEAR LS MALE EXT CATH",
              "FREEDOM CLEAR MALE EXT CATH": "FREEDOM CLEAR MALE EXT CATH",
              "FREEDOM CLEAR SS MALE EXT CATH": "FREEDOM CLEAR SS MALE EXT CATH",
              "GIZMO CONDOM CATHETER": "GIZMO CONDOM CATHETER",
              "SECURITY + MALE EXTERNAL": "SECURITY + MALE EXTERNAL",
              "SELF-CATH CLOSED SYSTEM": "SELF-CATH CLOSED SYSTEM",
              "SELF-CATH CLOSED SYSTEM FEMALE": "SELF-CATH CLOSED SYSTEM FEMALE",
              "SELF-CATH COUDE TIP": "SELF-CATH COUDE TIP",
              "SELF-CATH HYDROGEL SYSTEM": "SELF-CATH HYDROGEL SYSTEM",
              "SELF-CATH PLUS COUDE TIP": "SELF-CATH PLUS COUDE TIP",
              "SELF-CATH PLUS PEDIATRIC": "SELF-CATH PLUS PEDIATRIC",
              "SELF-CATH PLUS STRAIGHT TIP": "SELF-CATH PLUS STRAIGHT TIP",
              "SELF-CATH STRAIGHT TIP": "SELF-CATH STRAIGHT TIP",
              "SELF-CATH STRAIGHT TIP PED": "SELF-CATH STRAIGHT TIP PED",
              "SELF-CATH STRAIGHT TIP SOFT": "SELF-CATH STRAIGHT TIP SOFT",
              "SELF-CATH SYSTEM COUDE TIP": "SELF-CATH SYSTEM COUDE TIP",
              "SELF-CATH SYSTEM STRAIGHT TIP": "SELF-CATH SYSTEM STRAIGHT TIP",
              "SILICONE CATHETER 2-WAY": "SILICONE CATHETER 2-WAY",
              "SILICONE CATHETER 2-WAY OPEN": "SILICONE CATHETER 2-WAY OPEN",
              "SILICONE NEOBLADDER 3-WAY": "SILICONE NEOBLADDER 3-WAY",
              "SPECIMEN CATHETER FEMALE": "SPECIMEN CATHETER FEMALE",
              "SPEEDICATH COMPACT 12FR": "SPEEDICATH COMPACT 12FR",
              "SPEEDICATH COMPACT 14FR": "SPEEDICATH COMPACT 14FR",
              "SPEEDICATH COMPLETE": "SPEEDICATH COMPLETE",
              "SPEEDICATH INTERMITTENT": "SPEEDICATH INTERMITTENT",
              "STANDARD CARE EXTERNAL CATH": "STANDARD CARE EXTERNAL CATH",
              "SURECATH INTERMITTENT": "SURECATH INTERMITTENT",
              "URO SAN PLUS MALE EXT CATHETER": "URO SAN PLUS MALE EXT CATHETER"
            },
            "CONVATEC": {
              "GENTLECATH URINARY CATHETER": "GENTLECATH URINARY CATHETER"
            },
            "COVIDIEN MEDICAL SUPPLIES": {
              "ARGYLE OPEN SUCTION MINI TRAY": "ARGYLE OPEN SUCTION MINI TRAY",
              "ARGYLE SUCTION CATHETER 14FR": "ARGYLE SUCTION CATHETER 14FR",
              "ARGYLE SUCTION CATHETER 18FR": "ARGYLE SUCTION CATHETER 18FR",
              "ARGYLE SUCTION CATHETER 6FR": "ARGYLE SUCTION CATHETER 6FR",
              "ARGYLE SUCTION CATHETER 8FR": "ARGYLE SUCTION CATHETER 8FR",
              "CURITY ADD-A-FOLEY CATH TRAY": "CURITY ADD-A-FOLEY CATH TRAY",
              "CURITY FOLEY CATHETER TRAY": "CURITY FOLEY CATHETER TRAY",
              "DOVER 3-WAY FOLEY CATHETER": "DOVER 3-WAY FOLEY CATHETER",
              "DOVER ADD-A-FOLEY TRAY": "DOVER ADD-A-FOLEY TRAY",
              "DOVER ALL SILICONE FOLEY CATH": "DOVER ALL SILICONE FOLEY CATH",
              "DOVER CLOSED URETHRAL TRAY": "DOVER CLOSED URETHRAL TRAY",
              "DOVER ELASTOMER FOLEY CATHETER": "DOVER ELASTOMER FOLEY CATHETER",
              "DOVER FOLEY INSERTION TRAY": "DOVER FOLEY INSERTION TRAY",
              "DOVER HYDROGEL FOLEY CATH 12FR": "DOVER HYDROGEL FOLEY CATH 12FR",
              "DOVER HYDROGEL FOLEY CATH 14FR": "DOVER HYDROGEL FOLEY CATH 14FR",
              "DOVER HYDROGEL FOLEY CATH 16FR": "DOVER HYDROGEL FOLEY CATH 16FR",
              "DOVER HYDROGEL FOLEY CATH 18FR": "DOVER HYDROGEL FOLEY CATH 18FR",
              "DOVER HYDROGEL FOLEY CATH 20FR": "DOVER HYDROGEL FOLEY CATH 20FR",
              "DOVER HYDROGEL FOLEY CATH 22FR": "DOVER HYDROGEL FOLEY CATH 22FR",
              "DOVER HYDROGEL FOLEY CATH 24FR": "DOVER HYDROGEL FOLEY CATH 24FR",
              "DOVER HYDROGEL FOLEY CATH 26FR": "DOVER HYDROGEL FOLEY CATH 26FR",
              "DOVER HYDROGEL FOLEY CATH 28FR": "DOVER HYDROGEL FOLEY CATH 28FR",
              "DOVER HYDROGEL FOLEY CATH 30FR": "DOVER HYDROGEL FOLEY CATH 30FR",
              "DOVER HYDROGEL FOLEY CATH KIT": "DOVER HYDROGEL FOLEY CATH KIT",
              "DOVER HYDROGEL FOLEY TRAY 14FR": "DOVER HYDROGEL FOLEY TRAY 14FR",
              "DOVER HYDROGEL FOLEY TRAY 16FR": "DOVER HYDROGEL FOLEY TRAY 16FR",
              "DOVER HYDROGEL FOLEY TRAY 18FR": "DOVER HYDROGEL FOLEY TRAY 18FR",
              "DOVER HYDROGEL INSERTION TRAY": "DOVER HYDROGEL INSERTION TRAY",
              "DOVER MALE EXTERNAL CATHETER": "DOVER MALE EXTERNAL CATHETER",
              "DOVER OPEN URETHRAL TRAY 14FR": "DOVER OPEN URETHRAL TRAY 14FR",
              "DOVER PVC URETHRAL CATH 10FR": "DOVER PVC URETHRAL CATH 10FR",
              "DOVER PVC URETHRAL CATH 12FR": "DOVER PVC URETHRAL CATH 12FR",
              "DOVER PVC URETHRAL CATH 14FR": "DOVER PVC URETHRAL CATH 14FR",
              "DOVER PVC URETHRAL CATH 16FR": "DOVER PVC URETHRAL CATH 16FR",
              "DOVER ROBINSON CATHETER": "DOVER ROBINSON CATHETER",
              "DOVER SILICONE FOLEY": "DOVER SILICONE FOLEY",
              "DOVER SILICONE FOLEY CATH 14FR": "DOVER SILICONE FOLEY CATH 14FR",
              "DOVER SILICONE FOLEY CATH 16FR": "DOVER SILICONE FOLEY CATH 16FR",
              "DOVER SILICONE FOLEY CATH 18FR": "DOVER SILICONE FOLEY CATH 18FR",
              "DOVER SILICONE FOLEY CATH 20FR": "DOVER SILICONE FOLEY CATH 20FR",
              "DOVER SILICONE FOLEY CATH 22FR": "DOVER SILICONE FOLEY CATH 22FR",
              "DOVER SILICONE FOLEY CATH 24FR": "DOVER SILICONE FOLEY CATH 24FR",
              "DOVER SILICONE FOLEY CATH 26FR": "DOVER SILICONE FOLEY CATH 26FR",
              "DOVER SILICONE FOLEY CATH 28FR": "DOVER SILICONE FOLEY CATH 28FR",
              "DOVER SILICONE FOLEY CATH 30FR": "DOVER SILICONE FOLEY CATH 30FR",
              "DOVER SILICONE FOLEY CATH TRAY": "DOVER SILICONE FOLEY CATH TRAY",
              "DOVER SILICONE FOLEY CATHETER": "DOVER SILICONE FOLEY CATHETER",
              "DOVER SILICONE FOLEY TRAY 18FR": "DOVER SILICONE FOLEY TRAY 18FR",
              "DOVER SILICONE URINE METER": "DOVER SILICONE URINE METER",
              "DOVER SILICONE/LATEX CATHETER": "DOVER SILICONE/LATEX CATHETER",
              "DOVER SILVER CATH TRAY": "DOVER SILVER CATH TRAY",
              "DOVER TEXAS MALE EXTERNAL CATH": "DOVER TEXAS MALE EXTERNAL CATH",
              "DOVER UNI CATHETERIZATION TRAY": "DOVER UNI CATHETERIZATION TRAY",
              "DOVER UNIVERSAL FOLEY TRAY": "DOVER UNIVERSAL FOLEY TRAY",
              "DOVER UNIVERSAL TRAY": "DOVER UNIVERSAL TRAY",
              "DOVER URETHRAL CATH TRAY": "DOVER URETHRAL CATH TRAY",
              "DOVER URETHRAL CATHETER": "DOVER URETHRAL CATHETER",
              "DOVER URETHRAL PVC CATH 18FR": "DOVER URETHRAL PVC CATH 18FR",
              "DOVER URETHRAL UNIVERSAL TRAY": "DOVER URETHRAL UNIVERSAL TRAY",
              "DOVER URI-DRAIN MALE EXT CATH": "DOVER URI-DRAIN MALE EXT CATH",
              "DOVER VINYL CATHETER 14FR": "DOVER VINYL CATHETER 14FR",
              "DOVER VINYL URETHRAL CATH 14FR": "DOVER VINYL URETHRAL CATH 14FR",
              "DOVER VINYL URETHRAL CATH 16FR": "DOVER VINYL URETHRAL CATH 16FR",
              "DOVER VINYL URETHRAL CATH 8FR": "DOVER VINYL URETHRAL CATH 8FR",
              "KENGUARD ADD-A-FOLEY CATH TRAY": "KENGUARD ADD-A-FOLEY CATH TRAY",
              "KENGUARD CATH INSERTION TRAY": "KENGUARD CATH INSERTION TRAY",
              "KENGUARD UNIVERSAL CATH TRAY": "KENGUARD UNIVERSAL CATH TRAY",
              "PRECISION 200 CATH TRAY": "PRECISION 200 CATH TRAY",
              "PRECISION 400 CATH TRAY": "PRECISION 400 CATH TRAY",
              "PRECISION FOLEY CATH TRAY": "PRECISION FOLEY CATH TRAY",
              "TEXAS CATHETER EXTERNAL MALE": "TEXAS CATHETER EXTERNAL MALE",
              "TOUCH-TROL SUCTION CATHETER": "TOUCH-TROL SUCTION CATHETER"
            },
            "DENTSPLY IH AB": {
              "LOFRIC COUDE URINARY CATHETER": "LOFRIC COUDE URINARY CATHETER",
              "LOFRIC NELATON PAEDIATRIC CATH": "LOFRIC NELATON PAEDIATRIC CATH",
              "LOFRIC NELATON URINARY CATH": "LOFRIC NELATON URINARY CATH",
              "LOFRIC ORIGO PAED CATHETER": "LOFRIC ORIGO PAED CATHETER",
              "LOFRIC ORIGO URINARY CATHETER": "LOFRIC ORIGO URINARY CATHETER",
              "LOFRIC PRIMO NELATON CATHETER": "LOFRIC PRIMO NELATON CATHETER"
            },
            "HOLLISTER": {
              "ADVANCE EX EXTERNAL CATHETER": "ADVANCE EX EXTERNAL CATHETER",
              "ADVANCE HYDRO SOFT 10F/15CM": "ADVANCE HYDRO SOFT 10F/15CM",
              "ADVANCE HYDRO SOFT 10F/25CM": "ADVANCE HYDRO SOFT 10F/25CM",
              "ADVANCE HYDRO SOFT 10F/40CM": "ADVANCE HYDRO SOFT 10F/40CM",
              "ADVANCE HYDRO SOFT 12F/15CM": "ADVANCE HYDRO SOFT 12F/15CM",
              "ADVANCE HYDRO SOFT 12F/40CM": "ADVANCE HYDRO SOFT 12F/40CM",
              "ADVANCE HYDRO SOFT 14F/15CM": "ADVANCE HYDRO SOFT 14F/15CM",
              "ADVANCE HYDRO SOFT 14F/40CM": "ADVANCE HYDRO SOFT 14F/40CM",
              "ADVANCE HYDRO SOFT 16F/15CM": "ADVANCE HYDRO SOFT 16F/15CM",
              "ADVANCE HYDRO SOFT 16F/40CM": "ADVANCE HYDRO SOFT 16F/40CM",
              "ADVANCE HYDRO SOFT 18F/15CM": "ADVANCE HYDRO SOFT 18F/15CM",
              "ADVANCE HYDRO SOFT 18F/40CM": "ADVANCE HYDRO SOFT 18F/40CM",
              "ADVANCE HYDRO SOFT 8F/25CM": "ADVANCE HYDRO SOFT 8F/25CM",
              "ADVANCE PLUS COUDE 12FR/40CM": "ADVANCE PLUS COUDE 12FR/40CM",
              "ADVANCE PLUS COUDE 14FR/40CM": "ADVANCE PLUS COUDE 14FR/40CM",
              "ADVANCE PLUS COUDE 16FR/40CM": "ADVANCE PLUS COUDE 16FR/40CM",
              "ADVANCE PLUS STRAIGHT 10FR/40": "ADVANCE PLUS STRAIGHT 10FR/40",
              "ADVANCE PLUS STRAIGHT 12FR/40": "ADVANCE PLUS STRAIGHT 12FR/40",
              "ADVANCE PLUS STRAIGHT 14FR/40": "ADVANCE PLUS STRAIGHT 14FR/40",
              "ADVANCE PLUS STRAIGHT 16FR/40": "ADVANCE PLUS STRAIGHT 16FR/40",
              "ADVANCE PLUS STRAIGHT 18FR/40": "ADVANCE PLUS STRAIGHT 18FR/40",
              "ADVANCE PLUS STRAIGHT 6FR/40CM": "ADVANCE PLUS STRAIGHT 6FR/40CM",
              "ADVANCE PLUS STRAIGHT 8FR/40CM": "ADVANCE PLUS STRAIGHT 8FR/40CM",
              "APOGEE HC CATHETER 10FR/10\"\"": "APOGEE HC CATHETER 10FR/10\"\"",
              "APOGEE HC CATHETER 10FR/6\"\"": "APOGEE HC CATHETER 10FR/6\"\"",
              "APOGEE HC CATHETER 12FR/16\"\"": "APOGEE HC CATHETER 12FR/16\"\"",
              "APOGEE HC CATHETER 12FR/6\"\"": "APOGEE HC CATHETER 12FR/6\"\"",
              "APOGEE HC CATHETER 14FR/16\"\"": "APOGEE HC CATHETER 14FR/16\"\"",
              "APOGEE HC CATHETER 14FR/6\"\"": "APOGEE HC CATHETER 14FR/6\"\"",
              "APOGEE HC CATHETER 16FR/16\"\"": "APOGEE HC CATHETER 16FR/16\"\"",
              "APOGEE HC CATHETER 18FR/16\"\"": "APOGEE HC CATHETER 18FR/16\"\"",
              "APOGEE HC CATHETER 8FR/10\"\"": "APOGEE HC CATHETER 8FR/10\"\"",
              "APOGEE IC CATHETER 14FR/6\"\"": "APOGEE IC CATHETER 14FR/6\"\"",
              "CATHETER SELF-ADHESIVE URINARY": "CATHETER SELF-ADHESIVE URINARY",
              "EVERYDAY MALE EXTERNAL CATH/SM": "EVERYDAY MALE EXTERNAL CATH/SM",
              "EXTENDED WEAR SELF-ADHESIVE": "EXTENDED WEAR SELF-ADHESIVE",
              "EXTENDED WEAR URINARY CATHETER": "EXTENDED WEAR URINARY CATHETER",
              "IN CARE STRAIGHT 6FR/20CM": "IN CARE STRAIGHT 6FR/20CM",
              "INCARE STRAIGHT 10FR/20CM": "INCARE STRAIGHT 10FR/20CM",
              "INCARE STRAIGHT 10FR/40CM": "INCARE STRAIGHT 10FR/40CM",
              "INCARE STRAIGHT 12FR/20CM": "INCARE STRAIGHT 12FR/20CM",
              "INCARE STRAIGHT 12FR/40CM": "INCARE STRAIGHT 12FR/40CM",
              "INCARE STRAIGHT 14FR/20CM": "INCARE STRAIGHT 14FR/20CM",
              "INCARE STRAIGHT 14FR/40CM": "INCARE STRAIGHT 14FR/40CM",
              "INCARE STRAIGHT 16FR/40CM": "INCARE STRAIGHT 16FR/40CM",
              "INCARE STRAIGHT 18FR/40CM": "INCARE STRAIGHT 18FR/40CM",
              "INCARE STRAIGHT 8FR/20CM": "INCARE STRAIGHT 8FR/20CM",
              "INTERMITTENT 14FR/40CM": "INTERMITTENT 14FR/40CM",
              "INVIEW EXTRA EXTERNAL 25MM": "INVIEW EXTRA EXTERNAL 25MM",
              "INVIEW EXTRA EXTERNAL 29MM": "INVIEW EXTRA EXTERNAL 29MM",
              "INVIEW EXTRA EXTERNAL 32MM": "INVIEW EXTRA EXTERNAL 32MM",
              "INVIEW EXTRA EXTERNAL 36MM": "INVIEW EXTRA EXTERNAL 36MM",
              "INVIEW EXTRA EXTERNAL 41MM": "INVIEW EXTRA EXTERNAL 41MM",
              "INVIEW SPECIAL EXTERNAL 25MM": "INVIEW SPECIAL EXTERNAL 25MM",
              "INVIEW SPECIAL EXTERNAL 29MM": "INVIEW SPECIAL EXTERNAL 29MM",
              "INVIEW SPECIAL EXTERNAL 32MM": "INVIEW SPECIAL EXTERNAL 32MM",
              "INVIEW SPECIAL EXTERNAL 36MM": "INVIEW SPECIAL EXTERNAL 36MM",
              "INVIEW SPECIAL EXTERNAL 41MM": "INVIEW SPECIAL EXTERNAL 41MM",
              "INVIEW STANDARD EXTERNAL 25MM": "INVIEW STANDARD EXTERNAL 25MM",
              "INVIEW STANDARD EXTERNAL 29MM": "INVIEW STANDARD EXTERNAL 29MM",
              "INVIEW STANDARD EXTERNAL 32MM": "INVIEW STANDARD EXTERNAL 32MM",
              "INVIEW STANDARD EXTERNAL 36MM": "INVIEW STANDARD EXTERNAL 36MM",
              "INVIEW STANDARD EXTERNAL 41MM": "INVIEW STANDARD EXTERNAL 41MM",
              "MALE EXTERNAL CATHETER MEDIUM": "MALE EXTERNAL CATHETER MEDIUM",
              "VAPRO PLUS CATHETER 12FR/8\"\"": "VAPRO PLUS CATHETER 12FR/8\"\"",
              "VAPRO PLUS CATHETER 14FR/16\"\"": "VAPRO PLUS CATHETER 14FR/16\"\"",
              "VAPRO PLUS CATHETER 14FR/8\"\"": "VAPRO PLUS CATHETER 14FR/8\"\""
            },
            "TELEFLEX MEDICAL": {
              "RUSCH FLOCATH QUICK 16FR": "RUSCH FLOCATH QUICK 16FR",
              "RUSCH MMG CATHETER SYSTEM": "RUSCH MMG CATHETER SYSTEM",
              "RUSCH TIEMANN PVC CATHETER": "RUSCH TIEMANN PVC CATHETER"
            },
            "UROCARE PRODUCTS, INC.": {
              "URO-CATH II MALE EXT CATHETER": "URO-CATH II MALE EXT CATHETER",
              "URO-CATH MALE EXT CATH": "URO-CATH MALE EXT CATH",
              "URO-CON MALE EXT CATH": "URO-CON MALE EXT CATH",
              "URO-CON PLASTIC CATH INSERT": "URO-CON PLASTIC CATH INSERT"
            }
          },
          "Incontinence Supplies": {
            "ATTENDS HEALTHCARE PRODUCTS": {
              "ADHERES INCONTINENCE PAD": "ADHERES INCONTINENCE PAD",
              "ADHERES UNDERPAD": "ADHERES UNDERPAD",
              "AIR-DRI BREATHABLES": "AIR-DRI BREATHABLES",
              "AIR-DRI BREATHABLES PLUS": "AIR-DRI BREATHABLES PLUS",
              "ATTENDS BARIATRIC BRIEF XXL": "ATTENDS BARIATRIC BRIEF XXL",
              "ATTENDS BOOSTER PAD": "ATTENDS BOOSTER PAD",
              "ATTENDS BREATHABLE BRIEFS LG": "ATTENDS BREATHABLE BRIEFS LG",
              "ATTENDS BREATHABLE BRIEFS MED": "ATTENDS BREATHABLE BRIEFS MED",
              "ATTENDS BREATHABLE BRIEFS REG": "ATTENDS BREATHABLE BRIEFS REG",
              "ATTENDS BREATHABLE BRIEFS SM": "ATTENDS BREATHABLE BRIEFS SM",
              "ATTENDS BREATHABLE BRIEFS XL": "ATTENDS BREATHABLE BRIEFS XL",
              "ATTENDS BREATHABLE BRIEFS XXL": "ATTENDS BREATHABLE BRIEFS XXL",
              "ATTENDS BRIEFS CLASSIC LARGE": "ATTENDS BRIEFS CLASSIC LARGE",
              "ATTENDS BRIEFS CLASSIC MEDIUM": "ATTENDS BRIEFS CLASSIC MEDIUM",
              "ATTENDS BRIEFS CLASSIC REGULAR": "ATTENDS BRIEFS CLASSIC REGULAR",
              "ATTENDS BRIEFS CLASSIC SMALL": "ATTENDS BRIEFS CLASSIC SMALL",
              "ATTENDS BRIEFS CLASSIC X-LARGE": "ATTENDS BRIEFS CLASSIC X-LARGE",
              "ATTENDS BRIEFS CLASSIC YOUTH": "ATTENDS BRIEFS CLASSIC YOUTH",
              "ATTENDS BRIEFS LARGE": "ATTENDS BRIEFS LARGE",
              "ATTENDS BRIEFS LARGE WAIST": "ATTENDS BRIEFS LARGE WAIST",
              "ATTENDS BRIEFS MEDIUM": "ATTENDS BRIEFS MEDIUM",
              "ATTENDS BRIEFS MEDIUM WAIST": "ATTENDS BRIEFS MEDIUM WAIST",
              "ATTENDS BRIEFS REGULAR": "ATTENDS BRIEFS REGULAR",
              "ATTENDS BRIEFS SMALL": "ATTENDS BRIEFS SMALL",
              "ATTENDS BRIEFS X-LARGE": "ATTENDS BRIEFS X-LARGE",
              "ATTENDS CLASSIC UNDERPAD": "ATTENDS CLASSIC UNDERPAD",
              "ATTENDS GUARDS FOR MEN UNISIZE": "ATTENDS GUARDS FOR MEN UNISIZE",
              "ATTENDS INSERT PAD": "ATTENDS INSERT PAD",
              "ATTENDS LIGHT EXTRA PROTECTION": "ATTENDS LIGHT EXTRA PROTECTION",
              "ATTENDS LIGHT PADS EXTRA PLUS": "ATTENDS LIGHT PADS EXTRA PLUS",
              "ATTENDS LIGHT PADS ULTRA PLUS": "ATTENDS LIGHT PADS ULTRA PLUS",
              "ATTENDS LIGHT REG PROTECTION": "ATTENDS LIGHT REG PROTECTION",
              "ATTENDS MESH PANTS BARIATRIC": "ATTENDS MESH PANTS BARIATRIC",
              "ATTENDS MESH PANTS LARGE": "ATTENDS MESH PANTS LARGE",
              "ATTENDS MESH PANTS X-LARGE": "ATTENDS MESH PANTS X-LARGE",
              "ATTENDS MESH PANTS XX-LARGE": "ATTENDS MESH PANTS XX-LARGE",
              "ATTENDS NIGHT PRESERVER": "ATTENDS NIGHT PRESERVER",
              "ATTENDS SHAPED PAD": "ATTENDS SHAPED PAD",
              "ATTENDS SHAPED PADS EXTENDED": "ATTENDS SHAPED PADS EXTENDED",
              "ATTENDS UNDERGARMENTS BELTED": "ATTENDS UNDERGARMENTS BELTED",
              "ATTENDS UNDERPADS MOD ABSORB": "ATTENDS UNDERPADS MOD ABSORB",
              "ATTENDS UNDERWEAR 7 LARGE": "ATTENDS UNDERWEAR 7 LARGE",
              "ATTENDS UNDERWEAR 7 MEDIUM": "ATTENDS UNDERWEAR 7 MEDIUM",
              "ATTENDS UNDERWEAR 7 SM YOUTH": "ATTENDS UNDERWEAR 7 SM YOUTH",
              "ATTENDS UNDERWEAR 7 X-LARGE": "ATTENDS UNDERWEAR 7 X-LARGE",
              "ATTENDS WASHCLOTHS": "ATTENDS WASHCLOTHS",
              "CAIRPAD": "CAIRPAD",
              "CONFIDENCE FITTED BRIEF LARGE": "CONFIDENCE FITTED BRIEF LARGE",
              "CONFIDENCE FITTED BRIEF MEDIUM": "CONFIDENCE FITTED BRIEF MEDIUM",
              "CONFIDENCE FITTED BRIEF REG": "CONFIDENCE FITTED BRIEF REG",
              "CONFIDENCE FITTED BRIEF XL": "CONFIDENCE FITTED BRIEF XL",
              "CONFIDENCE WINGFOLD BRIEF": "CONFIDENCE WINGFOLD BRIEF",
              "DRI-SORB PLUS UNDERPAD": "DRI-SORB PLUS UNDERPAD",
              "DRI-SORB UNDERPAD": "DRI-SORB UNDERPAD",
              "ECONOLINE PLUS UNDERPAD": "ECONOLINE PLUS UNDERPAD",
              "EZ-SORB UNDERPAD": "EZ-SORB UNDERPAD",
              "QUICKABLES DRY WIPES": "QUICKABLES DRY WIPES",
              "SECURELY YOURS OB PAD": "SECURELY YOURS OB PAD",
              "SECURELY YOURS STRETCH BRIEF": "SECURELY YOURS STRETCH BRIEF",
              "SUPERSORB UNDERPAD": "SUPERSORB UNDERPAD",
              "TUCKABLES UNDERPAD": "TUCKABLES UNDERPAD",
              "TUFFSORB UNDERPAD": "TUFFSORB UNDERPAD",
              "ULTIMA INCONTINENCE PAD": "ULTIMA INCONTINENCE PAD",
              "ULTIMA TUCKABLES UNDERPAD": "ULTIMA TUCKABLES UNDERPAD",
              "ULTIMA UNDERPAD": "ULTIMA UNDERPAD"
            },
            "BARD": {
              "BARD BASIC IRRIGATION TRAY": "BARD BASIC IRRIGATION TRAY",
              "BARD CATHETER STRAP": "BARD CATHETER STRAP",
              "BARD CENTER ENTRY CLOSE SYSTEM": "BARD CENTER ENTRY CLOSE SYSTEM",
              "BARD CUNNINGHAM INCONTIN CLAMP": "BARD CUNNINGHAM INCONTIN CLAMP",
              "BARD DELUXE FABRIC LEG STRAPS": "BARD DELUXE FABRIC LEG STRAPS",
              "BARD DISPOZ-A-BAG LEG BAG": "BARD DISPOZ-A-BAG LEG BAG",
              "BARD DISPOZ-A-BAG/FLIP-FLO VLV": "BARD DISPOZ-A-BAG/FLIP-FLO VLV",
              "BARD DRAINAGE BAG FLOOR STAND": "BARD DRAINAGE BAG FLOOR STAND",
              "BARD EXTENSION TUBING/CONNECT": "BARD EXTENSION TUBING/CONNECT",
              "BARD INFECT CONT TRAY W/O CATH": "BARD INFECT CONT TRAY W/O CATH",
              "BARD INFECTION CONTROL BAG": "BARD INFECTION CONTROL BAG",
              "BARD IRRIGATION TRAY": "BARD IRRIGATION TRAY",
              "BARD LEG BAG HOLDER": "BARD LEG BAG HOLDER",
              "BARD LEG BAG STRAPS/FABRIC": "BARD LEG BAG STRAPS/FABRIC",
              "BARD LUBRICATH FOLEY W/O CATH": "BARD LUBRICATH FOLEY W/O CATH",
              "BARD PLASTIC TUBING/ADAPTER": "BARD PLASTIC TUBING/ADAPTER",
              "BARD PROTECTIVE BARRIER FILM": "BARD PROTECTIVE BARRIER FILM",
              "BARD TWO-SIDED ADHESIVE": "BARD TWO-SIDED ADHESIVE",
              "BARD URINARY DRAINAGE BAG": "BARD URINARY DRAINAGE BAG",
              "BARD URINARY DRAINAGE SYSTEM": "BARD URINARY DRAINAGE SYSTEM",
              "BARDIA IRRIGATION TRAY": "BARDIA IRRIGATION TRAY",
              "BARDIA LEG BAG": "BARDIA LEG BAG",
              "BARDIA URINARY DRAINAGE BAG": "BARDIA URINARY DRAINAGE BAG",
              "CONTIGEN TREATMENT SYRINGE": "CONTIGEN TREATMENT SYRINGE",
              "INFANT URINE COLLECTOR": "INFANT URINE COLLECTOR",
              "LATEX LEG BAG STRAPS": "LATEX LEG BAG STRAPS"
            },
            "BAYER CONSUMER": {
              "A + D PERSONAL CARE WIPES": "A + D PERSONAL CARE WIPES"
            },
            "BEIERSDORF-FUTURO": {
              "BEDPAN": "BEDPAN",
              "CURITY UNDERPADS": "CURITY UNDERPADS",
              "FEMALE URINAL": "FEMALE URINAL",
              "MALE URINAL": "MALE URINAL"
            },
            "BERGEN BRUNSWIG": {
              "GNP BATHING CLOTHS": "GNP BATHING CLOTHS",
              "GNP PERSONAL CLEANSING WIPES": "GNP PERSONAL CLEANSING WIPES",
              "GNP UNDERWEAR SM/MED": "GNP UNDERWEAR SM/MED"
            },
            "BIODERM": {
              "CATHGRIP DOUBLE STRAP": "CATHGRIP DOUBLE STRAP",
              "KINDKLAMP PENILE CLAMP": "KINDKLAMP PENILE CLAMP"
            },
            "CHAIN DRUG CONSORTIUM": {
              "BLADDER CONTROL PADS EXTRA": "BLADDER CONTROL PADS EXTRA",
              "BLADDER CONTROL PADS EXTRA PLS": "BLADDER CONTROL PADS EXTRA PLS",
              "BLADDER CONTROL PADS REGULAR": "BLADDER CONTROL PADS REGULAR",
              "FITTED BRIEFS LARGE": "FITTED BRIEFS LARGE",
              "FITTED BRIEFS MEDIUM": "FITTED BRIEFS MEDIUM",
              "PROTECTIVE UNDERWEAR LARGE": "PROTECTIVE UNDERWEAR LARGE",
              "PROTECTIVE UNDERWEAR MEDIUM": "PROTECTIVE UNDERWEAR MEDIUM",
              "PROTECTIVE UNDERWEAR XL": "PROTECTIVE UNDERWEAR XL",
              "UNDERGARMENT EXTRA ABSORBENT": "UNDERGARMENT EXTRA ABSORBENT",
              "UNDERGARMENT REG ABSORBANCY": "UNDERGARMENT REG ABSORBANCY",
              "UNDERPADS EXTRA LARGE": "UNDERPADS EXTRA LARGE",
              "UNDERPADS REGULAR": "UNDERPADS REGULAR"
            },
            "COLOPLAST": {
              "ALIPINE QUICK VALVE": "ALIPINE QUICK VALVE",
              "ALPINE DELUXE LEG BAG STRAPS": "ALPINE DELUXE LEG BAG STRAPS",
              "ALPINE LEG BAG": "ALPINE LEG BAG",
              "BEDSIDE DRAINAGE BAG": "BEDSIDE DRAINAGE BAG",
              "CATHETER EXTENSION TUBING": "CATHETER EXTENSION TUBING",
              "CATHETER IRRIGATION TRAY": "CATHETER IRRIGATION TRAY",
              "COMFORT LEG BAG STRAPS": "COMFORT LEG BAG STRAPS",
              "COMFORT MATE ADJUSTABLE STRAP": "COMFORT MATE ADJUSTABLE STRAP",
              "CONVEEN DRIP COLLECTOR": "CONVEEN DRIP COLLECTOR",
              "CONVEEN NET PANTS": "CONVEEN NET PANTS",
              "EASICLEANSE BATH": "EASICLEANSE BATH",
              "EXTENSION TUBING/CONNECTOR": "EXTENSION TUBING/CONNECTOR",
              "FREEDOM DELUXE LEG BAG STRAPS": "FREEDOM DELUXE LEG BAG STRAPS",
              "FREEDOM FAS-TAP LEG BAG": "FREEDOM FAS-TAP LEG BAG",
              "FREEDOM PAK SEVEN MALE CATH": "FREEDOM PAK SEVEN MALE CATH",
              "FREEDOM T-TAP LEG BAG": "FREEDOM T-TAP LEG BAG",
              "FREEDOM TWIST PORT LEG BAG": "FREEDOM TWIST PORT LEG BAG",
              "FREEDOM URINARY LEG BAG": "FREEDOM URINARY LEG BAG",
              "GIZMO FOAM STRAP": "GIZMO FOAM STRAP",
              "LEG BAG HANGER": "LEG BAG HANGER",
              "MANHOOD ABSORBENT POUCH": "MANHOOD ABSORBENT POUCH",
              "MCGUIRE CONNECTOR/TUBING": "MCGUIRE CONNECTOR/TUBING",
              "MCGUIRE MALE URINAL": "MCGUIRE MALE URINAL",
              "MCGUIRE REPLACEMENT SHEATH": "MCGUIRE REPLACEMENT SHEATH",
              "MOVEEN SYPHON URINE BAG": "MOVEEN SYPHON URINE BAG",
              "SECURITY + LEG BAG": "SECURITY + LEG BAG",
              "SECURITY + LEG BAG STRAPS": "SECURITY + LEG BAG STRAPS",
              "URINARY LEG BAG": "URINARY LEG BAG",
              "URO-TEX LEG BAG": "URO-TEX LEG BAG"
            },
            "COMBE": {
              "VAGISIL INTIMATE WIPES": "VAGISIL INTIMATE WIPES",
              "VAGISIL ODOR BLOCK DAILY WIPES": "VAGISIL ODOR BLOCK DAILY WIPES"
            },
            "COMPASS HEALTH BRANDS": {
              "BEDPAN": "BEDPAN",
              "FEMALE URINAL": "FEMALE URINAL",
              "MALE URINAL": "MALE URINAL"
            },
            "CONVATEC": {
              "FLEXI-SEAL FECAL COLLECTOR": "FLEXI-SEAL FECAL COLLECTOR",
              "FLEXI-SEAL FMS": "FLEXI-SEAL FMS",
              "FLEXI-SEAL FMS BAGS/FILTER": "FLEXI-SEAL FMS BAGS/FILTER",
              "FLEXI-SEAL FMS COLLECTION BAGS": "FLEXI-SEAL FMS COLLECTION BAGS",
              "FLEXI-SEAL SIGNAL FMS": "FLEXI-SEAL SIGNAL FMS"
            },
            "COVIDIEN MEDICAL SUPPLIES": {
              "CURITY CLEANERS": "CURITY CLEANERS",
              "CURITY INFANT CRIB LINER": "CURITY INFANT CRIB LINER",
              "CURITY PANTLINERS": "CURITY PANTLINERS",
              "CURITY YOUTH PANTS": "CURITY YOUTH PANTS",
              "DOVER ADHESIVE STRAP 2-SIDED": "DOVER ADHESIVE STRAP 2-SIDED",
              "DOVER ADVANTAGE DRAINAGE BAG": "DOVER ADVANTAGE DRAINAGE BAG",
              "DOVER ADVANTAGE URINE METER": "DOVER ADVANTAGE URINE METER",
              "DOVER CATHETER LEG STRAP": "DOVER CATHETER LEG STRAP",
              "DOVER CATHETER STRIP": "DOVER CATHETER STRIP",
              "DOVER DRAIN BAG/ANTI-REFLUX": "DOVER DRAIN BAG/ANTI-REFLUX",
              "DOVER DRAINAGE BAG": "DOVER DRAINAGE BAG",
              "DOVER ELASTIC FOAM STRAP": "DOVER ELASTIC FOAM STRAP",
              "DOVER ELASTIC FOAM TAPE": "DOVER ELASTIC FOAM TAPE",
              "DOVER EXT TUBE/CONNECTOR": "DOVER EXT TUBE/CONNECTOR",
              "DOVER IRRIGATION TRAY": "DOVER IRRIGATION TRAY",
              "DOVER PEDIATRIC URINE METER": "DOVER PEDIATRIC URINE METER",
              "DOVER PREMIUM DRAINAGE BAG": "DOVER PREMIUM DRAINAGE BAG",
              "DOVER PREMIUM URINE METER": "DOVER PREMIUM URINE METER",
              "DOVER TWO-SIDED ADHESIVE STRAP": "DOVER TWO-SIDED ADHESIVE STRAP",
              "DOVER UNIVERSAL CATH PREP TRAY": "DOVER UNIVERSAL CATH PREP TRAY",
              "DOVER URI-DRAIN DELUXE LEG BAG": "DOVER URI-DRAIN DELUXE LEG BAG",
              "DOVER URINARY EXTENSION TUBING": "DOVER URINARY EXTENSION TUBING",
              "DOVER URINARY LEG BAG": "DOVER URINARY LEG BAG",
              "DOVER URINE DRAINAGE BAG": "DOVER URINE DRAINAGE BAG",
              "DOVER URINE LEG BAG": "DOVER URINE LEG BAG",
              "DOVER URINE LEG BAG/EXT TUBE": "DOVER URINE LEG BAG/EXT TUBE",
              "DURASORB UNDERPAD": "DURASORB UNDERPAD",
              "KENDALL WASHCLOTHS": "KENDALL WASHCLOTHS",
              "KENDALL WASHCLOTHS/NOVONETTE": "KENDALL WASHCLOTHS/NOVONETTE",
              "MAXICARE BELTED UNDERGARMENT": "MAXICARE BELTED UNDERGARMENT",
              "PRECISION 400 URINE METER": "PRECISION 400 URINE METER",
              "PRECISION DRAINAGE BAG": "PRECISION DRAINAGE BAG",
              "SIMPLICITY ADULT BRIEF 31\"\"-48\"\"": "SIMPLICITY ADULT BRIEF 31\"\"-48\"\"",
              "SIMPLICITY ADULT BRIEF 31\"\"-52\"\"": "SIMPLICITY ADULT BRIEF 31\"\"-52\"\"",
              "SIMPLICITY ADULT BRIEF 31\"\"-56\"\"": "SIMPLICITY ADULT BRIEF 31\"\"-56\"\"",
              "SIMPLICITY ADULT BRIEF 32\"\"-44\"\"": "SIMPLICITY ADULT BRIEF 32\"\"-44\"\"",
              "SIMPLICITY ADULT BRIEF 45\"\"-58\"\"": "SIMPLICITY ADULT BRIEF 45\"\"-58\"\"",
              "SIMPLICITY ADULT BRIEF XL": "SIMPLICITY ADULT BRIEF XL",
              "SIMPLICITY FLUFF UNDERPAD": "SIMPLICITY FLUFF UNDERPAD",
              "SIMPLICITY GARMENT LINER": "SIMPLICITY GARMENT LINER",
              "SIMPLICITY INSERT PAD 12\"\"-21\"\"": "SIMPLICITY INSERT PAD 12\"\"-21\"\"",
              "SIMPLICITY INSERT PAD 12\"\"-24\"\"": "SIMPLICITY INSERT PAD 12\"\"-24\"\"",
              "SIMPLICITY INSERT PAD 12\"\"-28\"\"": "SIMPLICITY INSERT PAD 12\"\"-28\"\"",
              "SIMPLICITY INSERT PAD 14\"\"-30\"\"": "SIMPLICITY INSERT PAD 14\"\"-30\"\"",
              "SIMPLICITY INSERT PAD 6.5\"\"X17\"\"": "SIMPLICITY INSERT PAD 6.5\"\"X17\"\"",
              "SIMPLICITY PANT LINER 8\"\"X24\"\"": "SIMPLICITY PANT LINER 8\"\"X24\"\"",
              "SIMPLICITY POLY BRIEF 32\"\"-44\"\"": "SIMPLICITY POLY BRIEF 32\"\"-44\"\"",
              "SIMPLICITY POLY BRIEF 45\"\"-58\"\"": "SIMPLICITY POLY BRIEF 45\"\"-58\"\"",
              "SIMPLICITY POLY BRIEF 59\"\"-64\"\"": "SIMPLICITY POLY BRIEF 59\"\"-64\"\"",
              "SIMPLICITY SNAP PANTS 22\"\"-28\"\"": "SIMPLICITY SNAP PANTS 22\"\"-28\"\"",
              "SIMPLICITY SNAP PANTS 30\"\"-36\"\"": "SIMPLICITY SNAP PANTS 30\"\"-36\"\"",
              "SIMPLICITY SNAP PANTS 38\"\"-44\"\"": "SIMPLICITY SNAP PANTS 38\"\"-44\"\"",
              "SIMPLICITY SNAP PANTS 46\"\"-52\"\"": "SIMPLICITY SNAP PANTS 46\"\"-52\"\"",
              "SIMPLICITY SNAP PANTS 52\"\"-58\"\"": "SIMPLICITY SNAP PANTS 52\"\"-58\"\"",
              "SIMPLICITY UNDERPADS": "SIMPLICITY UNDERPADS",
              "SURE CARE BELTED UNDERGARMENT": "SURE CARE BELTED UNDERGARMENT",
              "SURE CARE BELTLESS UNDERGARMNT": "SURE CARE BELTLESS UNDERGARMNT",
              "SURE CARE UNDERGARMENT": "SURE CARE UNDERGARMENT",
              "SURE CARE UNDERPADS 17\"\"X24\"\"": "SURE CARE UNDERPADS 17\"\"X24\"\"",
              "SURE CARE UNDERPADS 23\"\"X24\"\"": "SURE CARE UNDERPADS 23\"\"X24\"\"",
              "SURE CARE UNDERPADS 23\"\"X36\"\"": "SURE CARE UNDERPADS 23\"\"X36\"\"",
              "SURE CARE UNDERPADS 30\"\"X36\"\"": "SURE CARE UNDERPADS 30\"\"X36\"\"",
              "SURE CARE UNDERWEAR 34\"\"-46\"\"": "SURE CARE UNDERWEAR 34\"\"-46\"\"",
              "SURE CARE UNDERWEAR 44\"\"-54\"\"": "SURE CARE UNDERWEAR 44\"\"-54\"\"",
              "SURE CARE UNDERWEAR 48\"\"-66\"\"": "SURE CARE UNDERWEAR 48\"\"-66\"\"",
              "TENDERSORB UNDERPAD": "TENDERSORB UNDERPAD",
              "WINGS ADULT BRIEFS MEDIUM": "WINGS ADULT BRIEFS MEDIUM",
              "WINGS ADULT BRIEFS XXL": "WINGS ADULT BRIEFS XXL",
              "WINGS ADULT QUILTED BRIEFS/LG": "WINGS ADULT QUILTED BRIEFS/LG",
              "WINGS ADULT QUILTED BRIEFS/MED": "WINGS ADULT QUILTED BRIEFS/MED",
              "WINGS ADULT QUILTED BRIEFS/S": "WINGS ADULT QUILTED BRIEFS/S",
              "WINGS ADULT QUILTED BRIEFS/XL": "WINGS ADULT QUILTED BRIEFS/XL",
              "WINGS BARIATRIC ADULT BRIEFS": "WINGS BARIATRIC ADULT BRIEFS",
              "WINGS BREATHABLE UNDERPAD": "WINGS BREATHABLE UNDERPAD",
              "WINGS CHOICE PLUS ADULT BRIEFS": "WINGS CHOICE PLUS ADULT BRIEFS",
              "WINGS CHOICE PLUS YOUTH BRIEFS": "WINGS CHOICE PLUS YOUTH BRIEFS",
              "WINGS CLASSIC ADULT BRIEFS": "WINGS CLASSIC ADULT BRIEFS",
              "WINGS CLASSIC ADULT BRIEFS X-L": "WINGS CLASSIC ADULT BRIEFS X-L",
              "WINGS CONTOURED PAD 13\"\"X24\"\"": "WINGS CONTOURED PAD 13\"\"X24\"\"",
              "WINGS CONTOURED PAD 14\"\"X26\"\"": "WINGS CONTOURED PAD 14\"\"X26\"\"",
              "WINGS CONTOURED PAD 14\"\"X27\"\"": "WINGS CONTOURED PAD 14\"\"X27\"\"",
              "WINGS FLUFF UNDERPAD 17\"\"X24\"\"": "WINGS FLUFF UNDERPAD 17\"\"X24\"\"",
              "WINGS FLUFF UNDERPAD 23\"\"X24\"\"": "WINGS FLUFF UNDERPAD 23\"\"X24\"\"",
              "WINGS FLUFF UNDERPAD 23\"\"X36\"\"": "WINGS FLUFF UNDERPAD 23\"\"X36\"\"",
              "WINGS FLUFF UNDERPAD 30\"\"X30\"\"": "WINGS FLUFF UNDERPAD 30\"\"X30\"\"",
              "WINGS FLUFF UNDERPAD 30\"\"X36\"\"": "WINGS FLUFF UNDERPAD 30\"\"X36\"\"",
              "WINGS HL ADULT BRIEFS/LARGE": "WINGS HL ADULT BRIEFS/LARGE",
              "WINGS HL ADULT BRIEFS/MEDIUM": "WINGS HL ADULT BRIEFS/MEDIUM",
              "WINGS HL ADULT BRIEFS/SMALL": "WINGS HL ADULT BRIEFS/SMALL",
              "WINGS HL ADULT BRIEFS/XL": "WINGS HL ADULT BRIEFS/XL",
              "WINGS HL ULTRA ADULT BRIEFS/L": "WINGS HL ULTRA ADULT BRIEFS/L",
              "WINGS HL ULTRA ADULT BRIEFS/M": "WINGS HL ULTRA ADULT BRIEFS/M",
              "WINGS HL ULTRA ADULT BRIEFS/S": "WINGS HL ULTRA ADULT BRIEFS/S",
              "WINGS HL ULTRA ADULT BRIEFS/XL": "WINGS HL ULTRA ADULT BRIEFS/XL",
              "WINGS INCONTINENCE PANTS 4XL": "WINGS INCONTINENCE PANTS 4XL",
              "WINGS INCONTINENCE PANTS L/XL": "WINGS INCONTINENCE PANTS L/XL",
              "WINGS INCONTINENCE PANTS S/M": "WINGS INCONTINENCE PANTS S/M",
              "WINGS KNIT PANTS XXL/XXXL": "WINGS KNIT PANTS XXL/XXXL",
              "WINGS MATERNITY PANTS 2X-3X": "WINGS MATERNITY PANTS 2X-3X",
              "WINGS MATERNITY PANTS 4X": "WINGS MATERNITY PANTS 4X",
              "WINGS MATERNITY PANTS LG-1X": "WINGS MATERNITY PANTS LG-1X",
              "WINGS SUPREME ADULT BRIEFS": "WINGS SUPREME ADULT BRIEFS",
              "WINGS UNDERPAD 30\"\"X30\"\"": "WINGS UNDERPAD 30\"\"X30\"\"",
              "WINGS UNDERPAD 30\"\"X36\"\"": "WINGS UNDERPAD 30\"\"X36\"\"",
              "WINGS UNDERPAD 36\"\"X36\"\"": "WINGS UNDERPAD 36\"\"X36\"\"",
              "WINGS UNDERPAD 36\"\"X70\"\"": "WINGS UNDERPAD 36\"\"X70\"\"",
              "WINGS WASHCLOTHS": "WINGS WASHCLOTHS"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS ADJUSTABLE UNDERWEAR": "CVS ADJUSTABLE UNDERWEAR",
              "CVS BELTED SHIELDS UNISEX": "CVS BELTED SHIELDS UNISEX",
              "CVS BLADDER CONTROL PAD": "CVS BLADDER CONTROL PAD",
              "CVS BLADDER CONTROL PAD ULTRA": "CVS BLADDER CONTROL PAD ULTRA",
              "CVS BLADDER CONTROL PAD X-ABS": "CVS BLADDER CONTROL PAD X-ABS",
              "CVS BRIEFS OVERNIGHT MEDIUM": "CVS BRIEFS OVERNIGHT MEDIUM",
              "CVS BRIEFS X-LARGE": "CVS BRIEFS X-LARGE",
              "CVS CLEANSING WIPES": "CVS CLEANSING WIPES",
              "CVS CLEANSING WIPES SENSITIVE": "CVS CLEANSING WIPES SENSITIVE",
              "CVS DAY & NIGHT UNDERPADS": "CVS DAY & NIGHT UNDERPADS",
              "CVS DISPOSABLE BED MATS": "CVS DISPOSABLE BED MATS",
              "CVS FITTED BRIEFS DAY/NIGHT LG": "CVS FITTED BRIEFS DAY/NIGHT LG",
              "CVS FITTED BRIEFS DAY/NIGHT MD": "CVS FITTED BRIEFS DAY/NIGHT MD",
              "CVS FITTED BRIEFS DAY/NIGHT XL": "CVS FITTED BRIEFS DAY/NIGHT XL",
              "CVS FITTED BRIEFS UNISEX XL": "CVS FITTED BRIEFS UNISEX XL",
              "CVS GUARD MALE BRIEF": "CVS GUARD MALE BRIEF",
              "CVS MENS GUARD": "CVS MENS GUARD",
              "CVS MENS GUARDS MAX ABSORBENCY": "CVS MENS GUARDS MAX ABSORBENCY",
              "CVS MENS UNDERWEAR LARGE/XL": "CVS MENS UNDERWEAR LARGE/XL",
              "CVS MENS UNDERWEAR SM/MED": "CVS MENS UNDERWEAR SM/MED",
              "CVS OVERNIGHT PADS-WOMENS": "CVS OVERNIGHT PADS-WOMENS",
              "CVS OVERNIGHT UNDERWEAR": "CVS OVERNIGHT UNDERWEAR",
              "CVS OVERNIGHT UNDERWEAR S/M": "CVS OVERNIGHT UNDERWEAR S/M",
              "CVS OVERNIGHT UNDERWEAR XL": "CVS OVERNIGHT UNDERWEAR XL",
              "CVS PREMIUM ADULT WASH CLOTHS": "CVS PREMIUM ADULT WASH CLOTHS",
              "CVS PREMIUM UNDERPADS": "CVS PREMIUM UNDERPADS",
              "CVS PROCTIVE PAD": "CVS PROCTIVE PAD",
              "CVS PROTECTIVE MAX ABSORB/LONG": "CVS PROTECTIVE MAX ABSORB/LONG",
              "CVS PROTECTIVE MAXIMUM ABSORB": "CVS PROTECTIVE MAXIMUM ABSORB",
              "CVS PROTECTIVE ULT ABSORB/LONG": "CVS PROTECTIVE ULT ABSORB/LONG",
              "CVS PROTECTIVE ULTIMATE ABSORB": "CVS PROTECTIVE ULTIMATE ABSORB",
              "CVS PROTECTIVE UNDERWEAR LGE": "CVS PROTECTIVE UNDERWEAR LGE",
              "CVS PROTECTIVE UNDERWEAR MED": "CVS PROTECTIVE UNDERWEAR MED",
              "CVS PROTECTIVE UNDERWEAR S/M": "CVS PROTECTIVE UNDERWEAR S/M",
              "CVS STRETCH FIT ADJ BRIEF L/XL": "CVS STRETCH FIT ADJ BRIEF L/XL",
              "CVS STRETCH FIT ADJ BRIEF MED": "CVS STRETCH FIT ADJ BRIEF MED",
              "CVS UNDERGARMENT X-ABSORB BELT": "CVS UNDERGARMENT X-ABSORB BELT",
              "CVS UNDERPADS 22\"\"X35\"\"": "CVS UNDERPADS 22\"\"X35\"\"",
              "CVS UNDERPADS DAY/NGHT 30\"\"X36\"\"": "CVS UNDERPADS DAY/NGHT 30\"\"X36\"\"",
              "CVS WOMENS PROTECT PADS LONG": "CVS WOMENS PROTECT PADS LONG",
              "CVS WOMENS PROTECTIVE PAD": "CVS WOMENS PROTECTIVE PAD",
              "CVS WOMENS PROTECTIVE PAD LONG": "CVS WOMENS PROTECTIVE PAD LONG",
              "CVS WOMENS UNDERWEAR LARGE": "CVS WOMENS UNDERWEAR LARGE",
              "CVS WOMENS UNDERWEAR SM/MED": "CVS WOMENS UNDERWEAR SM/MED",
              "CVS WOMENS UNDERWEAR X-LARGE": "CVS WOMENS UNDERWEAR X-LARGE",
              "CVS WOMENS UNDERWEAR X-SMALL": "CVS WOMENS UNDERWEAR X-SMALL",
              "CVS WOMENS UNDERWEAR XS/SMALL": "CVS WOMENS UNDERWEAR XS/SMALL"
            },
            "CYMED OSTOMY CO.": {
              "URINARY DRAINAGE BAG": "URINARY DRAINAGE BAG"
            },
            "DIABETIC SUPPLY OF SUNCOAST": {
              "ADVOCATE UNDERPADS": "ADVOCATE UNDERPADS"
            },
            "DYNAREX CORPORATION": {
              "DISPOS UNDERPADS/105G/30\"\"X30\"\"": "DISPOS UNDERPADS/105G/30\"\"X30\"\"",
              "DISPOS UNDERPADS/22G/17\"\"X24\"\"": "DISPOS UNDERPADS/22G/17\"\"X24\"\"",
              "DISPOS UNDERPADS/31G/23\"\"X24\"\"": "DISPOS UNDERPADS/31G/23\"\"X24\"\"",
              "DISPOS UNDERPADS/45G/23\"\"X36\"\"": "DISPOS UNDERPADS/45G/23\"\"X36\"\"",
              "DISPOS UNDERPADS/60G/23\"\"X36\"\"": "DISPOS UNDERPADS/60G/23\"\"X36\"\"",
              "DISPOS UNDERPADS/90G/30\"\"X36\"\"": "DISPOS UNDERPADS/90G/30\"\"X36\"\"",
              "DISPOSABLE UNDERPADS 17\"\"X24\"\"": "DISPOSABLE UNDERPADS 17\"\"X24\"\""
            },
            "EQUALINE": {
              "EQL BELTED UNDERGARMENTS": "EQL BELTED UNDERGARMENTS",
              "EQL BELTED UNDERGARMENTS EXTRA": "EQL BELTED UNDERGARMENTS EXTRA",
              "EQL BELTLESS UNDERGARMENTS": "EQL BELTLESS UNDERGARMENTS",
              "EQL BLADDER CONTROL MEDIUM": "EQL BLADDER CONTROL MEDIUM",
              "EQL FITTED BRIEFS EXTRA LARGE": "EQL FITTED BRIEFS EXTRA LARGE",
              "EQL LINERS LONG/VERY LIGHT ABS": "EQL LINERS LONG/VERY LIGHT ABS",
              "EQL PADS BLADDER PROTECT MAX": "EQL PADS BLADDER PROTECT MAX",
              "EQL PADS BLADDER PROTECT MOD": "EQL PADS BLADDER PROTECT MOD",
              "EQL PADS BLADDER PROTECT ULTIM": "EQL PADS BLADDER PROTECT ULTIM",
              "EQL PERSONAL CONFIDENCE": "EQL PERSONAL CONFIDENCE",
              "EQL PERSONAL CONFIDENCE BRIEFS": "EQL PERSONAL CONFIDENCE BRIEFS",
              "EQL PERSONAL CONFIDENCE GUARD": "EQL PERSONAL CONFIDENCE GUARD",
              "EQL PERSONAL CONFIDENCE LINER": "EQL PERSONAL CONFIDENCE LINER",
              "EQL PROTECTIVE UNDERWEAR ONITE": "EQL PROTECTIVE UNDERWEAR ONITE",
              "EQL PROTECTIVE UNDERWEAR SUPER": "EQL PROTECTIVE UNDERWEAR SUPER",
              "EQL PROTECTIVE UNDERWWAR ONITE": "EQL PROTECTIVE UNDERWWAR ONITE",
              "EQL PURSEREADY UNDERWEAR LARGE": "EQL PURSEREADY UNDERWEAR LARGE",
              "EQL PURSEREADY UNDERWEAR S/M": "EQL PURSEREADY UNDERWEAR S/M",
              "EQL SLIP-ON UNDERGARMENT": "EQL SLIP-ON UNDERGARMENT",
              "EQL ULTRA THIN PADS/LIGHT ABS": "EQL ULTRA THIN PADS/LIGHT ABS",
              "EQL UNDERWEAR MEN 28\"\"-40\"\"": "EQL UNDERWEAR MEN 28\"\"-40\"\"",
              "EQL UNDERWEAR MEN 38\"\"-64\"\"": "EQL UNDERWEAR MEN 38\"\"-64\"\"",
              "EQL UNDERWEAR MEN 48\"\"-64\"\"": "EQL UNDERWEAR MEN 48\"\"-64\"\"",
              "EQL UNDERWEAR WOMEN 28\"\"-40\"\"": "EQL UNDERWEAR WOMEN 28\"\"-40\"\"",
              "EQL UNDERWEAR WOMEN 38\"\"-50\"\"": "EQL UNDERWEAR WOMEN 38\"\"-50\"\""
            },
            "ESSENTIAL MEDICAL SUPPLY": {
              "QUIK-SORB UNDERPAD": "QUIK-SORB UNDERPAD"
            },
            "ESSITY": {
              "DRY COMFORT EXTRA BRIEF LARGE": "DRY COMFORT EXTRA BRIEF LARGE",
              "DRY COMFORT EXTRA BRIEF MEDIUM": "DRY COMFORT EXTRA BRIEF MEDIUM",
              "DRY COMFORT EXTRA BRIEF SMALL": "DRY COMFORT EXTRA BRIEF SMALL",
              "DRY COMFORT EXTRA BRIEF XL": "DRY COMFORT EXTRA BRIEF XL",
              "DRY COMFORT WASHCLOTHS": "DRY COMFORT WASHCLOTHS",
              "HARMONIE UNDERPAD": "HARMONIE UNDERPAD",
              "HARMONIE UNDERPAD EXTRA": "HARMONIE UNDERPAD EXTRA",
              "HARMONIE UNDERPAD REGULAR": "HARMONIE UNDERPAD REGULAR",
              "HARMONIE UNDERPAD ULTRA": "HARMONIE UNDERPAD ULTRA",
              "PROMISE DAY LIGHT PAD": "PROMISE DAY LIGHT PAD",
              "PROVIDE UNDERPAD": "PROVIDE UNDERPAD",
              "PROVIDE UNDERPAD ULTRA": "PROVIDE UNDERPAD ULTRA",
              "TENA BELTED BRIEFS": "TENA BELTED BRIEFS",
              "TENA CLASSIC BRIEFS/LARGE": "TENA CLASSIC BRIEFS/LARGE",
              "TENA CLASSIC BRIEFS/MEDIUM": "TENA CLASSIC BRIEFS/MEDIUM",
              "TENA CLASSIC BRIEFS/X-LARGE": "TENA CLASSIC BRIEFS/X-LARGE",
              "TENA COMPLETE + CARE LARGE": "TENA COMPLETE + CARE LARGE",
              "TENA COMPLETE + CARE MEDIUM": "TENA COMPLETE + CARE MEDIUM",
              "TENA COMPLETE + CARE XL": "TENA COMPLETE + CARE XL",
              "TENA COMPLETE BRIEF LARGE": "TENA COMPLETE BRIEF LARGE",
              "TENA COMPLETE BRIEF MEDIUM": "TENA COMPLETE BRIEF MEDIUM",
              "TENA COMPLETE BRIEF XL": "TENA COMPLETE BRIEF XL",
              "TENA DAY DRY COMFORT HEAVY": "TENA DAY DRY COMFORT HEAVY",
              "TENA DAY DRY COMFORT LIGHT": "TENA DAY DRY COMFORT LIGHT",
              "TENA DAY DRY COMFORT MODERATE": "TENA DAY DRY COMFORT MODERATE",
              "TENA DAY LIGHT PAD": "TENA DAY LIGHT PAD",
              "TENA DAY PLUS PAD": "TENA DAY PLUS PAD",
              "TENA DAY REGULAR PAD": "TENA DAY REGULAR PAD",
              "TENA DISCREET SUPER/LARGE": "TENA DISCREET SUPER/LARGE",
              "TENA DISCREET SUPER/MEDIUM": "TENA DISCREET SUPER/MEDIUM",
              "TENA DISCREET ULTRA/LARGE": "TENA DISCREET ULTRA/LARGE",
              "TENA DISCREET ULTRA/MEDIUM": "TENA DISCREET ULTRA/MEDIUM",
              "TENA DISCREET XLARGE": "TENA DISCREET XLARGE",
              "TENA DISCREET/LARGE": "TENA DISCREET/LARGE",
              "TENA DISCREET/MEDIUM": "TENA DISCREET/MEDIUM",
              "TENA DISCREET/SMALL": "TENA DISCREET/SMALL",
              "TENA FLEX 12 MAXI": "TENA FLEX 12 MAXI",
              "TENA FLEX 12 PLUS": "TENA FLEX 12 PLUS",
              "TENA FLEX 12 SUPER": "TENA FLEX 12 SUPER",
              "TENA FLEX 16 MAXI": "TENA FLEX 16 MAXI",
              "TENA FLEX 16 PLUS": "TENA FLEX 16 PLUS",
              "TENA FLEX 16 SUPER": "TENA FLEX 16 SUPER",
              "TENA FLEX 20": "TENA FLEX 20",
              "TENA FLEX 8 SUPER": "TENA FLEX 8 SUPER",
              "TENA FOR MEN": "TENA FOR MEN",
              "TENA KNIT PANTS/PLUS": "TENA KNIT PANTS/PLUS",
              "TENA KNIT PANTS/REGULAR": "TENA KNIT PANTS/REGULAR",
              "TENA MEN UNDERWEAR CULOTTE M/L": "TENA MEN UNDERWEAR CULOTTE M/L",
              "TENA MEN UNDERWEAR CULOTTE XL": "TENA MEN UNDERWEAR CULOTTE XL",
              "TENA MESH PANTS/LARGE": "TENA MESH PANTS/LARGE",
              "TENA MESH PANTS/MEDIUM": "TENA MESH PANTS/MEDIUM",
              "TENA MESH PANTS/SMALL": "TENA MESH PANTS/SMALL",
              "TENA MESH PANTS/X-LARGE": "TENA MESH PANTS/X-LARGE",
              "TENA NIGHT DRY COMFORT": "TENA NIGHT DRY COMFORT",
              "TENA NIGHT SUPER PAD": "TENA NIGHT SUPER PAD",
              "TENA PANTS SUPER/MEDIUM": "TENA PANTS SUPER/MEDIUM",
              "TENA PANTS ULTRA/LARGE": "TENA PANTS ULTRA/LARGE",
              "TENA PANTS ULTRA/MEDIUM": "TENA PANTS ULTRA/MEDIUM",
              "TENA PANTS/SMALL": "TENA PANTS/SMALL",
              "TENA PANTS/XL-XXL": "TENA PANTS/XL-XXL",
              "TENA PANTS/YOUTH": "TENA PANTS/YOUTH",
              "TENA PROTECT UNDERWEAR EXT/LG": "TENA PROTECT UNDERWEAR EXT/LG",
              "TENA PROTECT UNDERWEAR EXT/MED": "TENA PROTECT UNDERWEAR EXT/MED",
              "TENA PROTECT UNDERWEAR EXT/SM": "TENA PROTECT UNDERWEAR EXT/SM",
              "TENA PROTECT UNDERWEAR PLS/LG": "TENA PROTECT UNDERWEAR PLS/LG",
              "TENA PROTECT UNDERWEAR PLS/MED": "TENA PROTECT UNDERWEAR PLS/MED",
              "TENA PROTECT UNDERWEAR PLS/XL": "TENA PROTECT UNDERWEAR PLS/XL",
              "TENA PROTECT UNDERWEAR/LARGE": "TENA PROTECT UNDERWEAR/LARGE",
              "TENA PROTECTIVE UNDERWEAR/2XL": "TENA PROTECTIVE UNDERWEAR/2XL",
              "TENA PROTECTIVE UNDERWEAR/LG": "TENA PROTECTIVE UNDERWEAR/LG",
              "TENA PROTECTIVE UNDERWEAR/MED": "TENA PROTECTIVE UNDERWEAR/MED",
              "TENA PROTECTIVE UNDERWEAR/XL": "TENA PROTECTIVE UNDERWEAR/XL",
              "TENA SERENITY OVERNIGHT PADS": "TENA SERENITY OVERNIGHT PADS",
              "TENA SERENITY PADS ULTIMATE": "TENA SERENITY PADS ULTIMATE",
              "TENA SKIN-CARING WASHCLOTHS": "TENA SKIN-CARING WASHCLOTHS",
              "TENA STRETCH BRIEF 2XL": "TENA STRETCH BRIEF 2XL",
              "TENA SUPER BRIEF/LARGE": "TENA SUPER BRIEF/LARGE",
              "TENA WOMEN UNDERWEAR CULOTTE": "TENA WOMEN UNDERWEAR CULOTTE",
              "TENA YOUTH BRIEF EXTRA SMALL": "TENA YOUTH BRIEF EXTRA SMALL"
            },
            "FIRST CHOICE": {
              "BELTED UNDERGARMENT XTRA": "BELTED UNDERGARMENT XTRA",
              "INCONTINENCE BRIEF LARGE": "INCONTINENCE BRIEF LARGE",
              "INCONTINENCE BRIEF MEDIUM": "INCONTINENCE BRIEF MEDIUM",
              "UNDERPADS": "UNDERPADS"
            },
            "FIRST QUALITY PRODUCTS": {
              "AIR PERMEABLE UNDERPAD": "AIR PERMEABLE UNDERPAD",
              "COTTON SNAP PANTS": "COTTON SNAP PANTS",
              "FIRST QUALITY PERSONAL CLOTHS": "FIRST QUALITY PERSONAL CLOTHS",
              "FIRST QUALITY WASHCLOTHS": "FIRST QUALITY WASHCLOTHS",
              "FQ AIR PERMEABLE UNDERPAD": "FQ AIR PERMEABLE UNDERPAD",
              "FQ BELTED UNDERGARMENT": "FQ BELTED UNDERGARMENT",
              "FQ FRONTAL TAPE BRIEF XL": "FQ FRONTAL TAPE BRIEF XL",
              "FQ FULL MAT BRIEF ADULT SMALL": "FQ FULL MAT BRIEF ADULT SMALL",
              "FQ FULL MAT BRIEF ADULT XL": "FQ FULL MAT BRIEF ADULT XL",
              "FQ FULL MAT BRIEF YOUTH": "FQ FULL MAT BRIEF YOUTH",
              "FQ NU-FIT ADULT BRIEF XL": "FQ NU-FIT ADULT BRIEF XL",
              "FQ PANT LINER": "FQ PANT LINER",
              "FQ PANT LINER LARGE": "FQ PANT LINER LARGE",
              "FQ PANT LINER MEDIUM": "FQ PANT LINER MEDIUM",
              "FQ PANT LINER SMALL": "FQ PANT LINER SMALL",
              "FQ PERFIT LMTD MAT ADULT BRIEF": "FQ PERFIT LMTD MAT ADULT BRIEF",
              "FQ PREMIUM UNDERPAD": "FQ PREMIUM UNDERPAD",
              "FQ PREVAIL BELTED UNDERGARMENT": "FQ PREVAIL BELTED UNDERGARMENT",
              "FQ PREVAIL BLADDER CONTROL PAD": "FQ PREVAIL BLADDER CONTROL PAD",
              "FQ PROTECTIVE UNDERWEAR": "FQ PROTECTIVE UNDERWEAR",
              "FULL MAT BRIEF": "FULL MAT BRIEF",
              "IB FULL MAT BRIEF LARGE": "IB FULL MAT BRIEF LARGE",
              "IB FULL MAT BRIEF MEDIUM": "IB FULL MAT BRIEF MEDIUM",
              "MESH PANTS": "MESH PANTS",
              "NU-FIT ADULT BRIEF LARGE": "NU-FIT ADULT BRIEF LARGE",
              "NU-FIT ADULT BRIEF MEDIUM": "NU-FIT ADULT BRIEF MEDIUM",
              "PER-FIT BRIEF LARGE": "PER-FIT BRIEF LARGE",
              "PER-FIT BRIEF MEDIUM": "PER-FIT BRIEF MEDIUM",
              "PER-FIT UNDERWEAR EXTRA LARGE": "PER-FIT UNDERWEAR EXTRA LARGE",
              "PER-FIT UNDERWEAR LARGE": "PER-FIT UNDERWEAR LARGE",
              "PER-FIT UNDERWEAR MEDIUM": "PER-FIT UNDERWEAR MEDIUM",
              "PREVAIL ADJ UNDERWEAR LARGE": "PREVAIL ADJ UNDERWEAR LARGE",
              "PREVAIL ADJ UNDERWEAR SM/MED": "PREVAIL ADJ UNDERWEAR SM/MED",
              "PREVAIL ADULT BRIEF LARGE": "PREVAIL ADULT BRIEF LARGE",
              "PREVAIL ADULT BRIEF MEDIUM": "PREVAIL ADULT BRIEF MEDIUM",
              "PREVAIL ADULT WASHCLOTHS": "PREVAIL ADULT WASHCLOTHS",
              "PREVAIL AIR BRIEFS": "PREVAIL AIR BRIEFS",
              "PREVAIL AIR PLUS BRIEFS": "PREVAIL AIR PLUS BRIEFS",
              "PREVAIL ALL NITES MEDIUM/LARGE": "PREVAIL ALL NITES MEDIUM/LARGE",
              "PREVAIL BARIATRIC A": "PREVAIL BARIATRIC A",
              "PREVAIL BARIATRIC B": "PREVAIL BARIATRIC B",
              "PREVAIL BLADDER CONT ULTRA PLS": "PREVAIL BLADDER CONT ULTRA PLS",
              "PREVAIL BLADDER CONTROL PADS": "PREVAIL BLADDER CONTROL PADS",
              "PREVAIL BLADDER CONTROL ULTRA": "PREVAIL BLADDER CONTROL ULTRA",
              "PREVAIL BOXERS FOR MEN L-XL": "PREVAIL BOXERS FOR MEN L-XL",
              "PREVAIL BOXERS FOR MEN S-M": "PREVAIL BOXERS FOR MEN S-M",
              "PREVAIL BREEZERS 360-SIZE 1": "PREVAIL BREEZERS 360-SIZE 1",
              "PREVAIL BREEZERS 360-SIZE 2": "PREVAIL BREEZERS 360-SIZE 2",
              "PREVAIL BREEZERS 360-SIZE 3": "PREVAIL BREEZERS 360-SIZE 3",
              "PREVAIL BREEZERS LARGE": "PREVAIL BREEZERS LARGE",
              "PREVAIL BREEZERS MEDIUM": "PREVAIL BREEZERS MEDIUM",
              "PREVAIL BREEZERS REGULAR": "PREVAIL BREEZERS REGULAR",
              "PREVAIL BREEZERS XL": "PREVAIL BREEZERS XL",
              "PREVAIL BRIEF LARGE": "PREVAIL BRIEF LARGE",
              "PREVAIL BRIEF MEDIUM": "PREVAIL BRIEF MEDIUM",
              "PREVAIL BRIEF SMALL": "PREVAIL BRIEF SMALL",
              "PREVAIL BRIEF X-LARGE": "PREVAIL BRIEF X-LARGE",
              "PREVAIL BRIEF YOUTH": "PREVAIL BRIEF YOUTH",
              "PREVAIL CURVE PADS": "PREVAIL CURVE PADS",
              "PREVAIL EXTRA": "PREVAIL EXTRA",
              "PREVAIL EXTRA PLUS": "PREVAIL EXTRA PLUS",
              "PREVAIL FITS TO A T LARGE/XL": "PREVAIL FITS TO A T LARGE/XL",
              "PREVAIL FITS TO A T MEDIUM/REG": "PREVAIL FITS TO A T MEDIUM/REG",
              "PREVAIL FLUFF UNDERPADS": "PREVAIL FLUFF UNDERPADS",
              "PREVAIL FOR WOMEN LARGE": "PREVAIL FOR WOMEN LARGE",
              "PREVAIL FOR WOMEN SMALL/MEDIUM": "PREVAIL FOR WOMEN SMALL/MEDIUM",
              "PREVAIL FOR WOMEN X-LARGE": "PREVAIL FOR WOMEN X-LARGE",
              "PREVAIL FULL COVER UNDERWEAR": "PREVAIL FULL COVER UNDERWEAR",
              "PREVAIL GUARDS FOR MEN": "PREVAIL GUARDS FOR MEN",
              "PREVAIL IB FULL MAT BRIEF 2XL": "PREVAIL IB FULL MAT BRIEF 2XL",
              "PREVAIL IBF FULL MAT BRIEF LG": "PREVAIL IBF FULL MAT BRIEF LG",
              "PREVAIL IBF FULL MAT BRIEF MED": "PREVAIL IBF FULL MAT BRIEF MED",
              "PREVAIL MAXIMUM ABSORBENCY": "PREVAIL MAXIMUM ABSORBENCY",
              "PREVAIL MAXIMUM ABSORBENCY/LNG": "PREVAIL MAXIMUM ABSORBENCY/LNG",
              "PREVAIL MEN UNDERWEAR 2XL": "PREVAIL MEN UNDERWEAR 2XL",
              "PREVAIL MEN UNDERWEAR LARGE-XL": "PREVAIL MEN UNDERWEAR LARGE-XL",
              "PREVAIL MEN UNDERWEAR SM-MED": "PREVAIL MEN UNDERWEAR SM-MED",
              "PREVAIL MESH PANTS 2XL": "PREVAIL MESH PANTS 2XL",
              "PREVAIL MODERATE ABSORBENCY": "PREVAIL MODERATE ABSORBENCY",
              "PREVAIL NEXUS UNDERPAD 30\"\"X36\"\"": "PREVAIL NEXUS UNDERPAD 30\"\"X36\"\"",
              "PREVAIL PADS": "PREVAIL PADS",
              "PREVAIL PANT LINER SMALL": "PREVAIL PANT LINER SMALL",
              "PREVAIL PANTILINER": "PREVAIL PANTILINER",
              "PREVAIL PER-FIT 360 SIZE 1": "PREVAIL PER-FIT 360 SIZE 1",
              "PREVAIL PER-FIT 360 SIZE 2": "PREVAIL PER-FIT 360 SIZE 2",
              "PREVAIL PER-FIT 360 SIZE 3": "PREVAIL PER-FIT 360 SIZE 3",
              "PREVAIL PER-FIT MEN LARGE": "PREVAIL PER-FIT MEN LARGE",
              "PREVAIL PER-FIT MEN MEDIUM": "PREVAIL PER-FIT MEN MEDIUM",
              "PREVAIL PER-FIT MEN X-LARGE": "PREVAIL PER-FIT MEN X-LARGE",
              "PREVAIL PER-FIT WOMEN LARGE": "PREVAIL PER-FIT WOMEN LARGE",
              "PREVAIL PER-FIT WOMEN MEDIUM": "PREVAIL PER-FIT WOMEN MEDIUM",
              "PREVAIL PER-FIT WOMEN X-LARGE": "PREVAIL PER-FIT WOMEN X-LARGE",
              "PREVAIL PREMIUM UNDERPAD SUPER": "PREVAIL PREMIUM UNDERPAD SUPER",
              "PREVAIL PREMIUM WASHCLOTHS": "PREVAIL PREMIUM WASHCLOTHS",
              "PREVAIL SIMPLY STRETCHFIT/A": "PREVAIL SIMPLY STRETCHFIT/A",
              "PREVAIL SIMPLY STRETCHFIT/B": "PREVAIL SIMPLY STRETCHFIT/B",
              "PREVAIL SMOOTH FIT UNDERWEAR": "PREVAIL SMOOTH FIT UNDERWEAR",
              "PREVAIL STRETCHFIT SIZE A": "PREVAIL STRETCHFIT SIZE A",
              "PREVAIL STRETCHFIT SIZE B": "PREVAIL STRETCHFIT SIZE B",
              "PREVAIL SUPER PLUS LARGE": "PREVAIL SUPER PLUS LARGE",
              "PREVAIL SUPER PLUS SM/MED": "PREVAIL SUPER PLUS SM/MED",
              "PREVAIL SUPER PLUS XLARGE": "PREVAIL SUPER PLUS XLARGE",
              "PREVAIL ULTIMATE ABSORBENCY": "PREVAIL ULTIMATE ABSORBENCY",
              "PREVAIL ULTRATHIN UNDERWEAR LG": "PREVAIL ULTRATHIN UNDERWEAR LG",
              "PREVAIL ULTRATHIN UNDERWEAR MD": "PREVAIL ULTRATHIN UNDERWEAR MD",
              "PREVAIL UNDERPADS": "PREVAIL UNDERPADS",
              "PREVAIL UNDERWEAR 2XL": "PREVAIL UNDERWEAR 2XL",
              "PREVAIL WASHCLOTHS": "PREVAIL WASHCLOTHS",
              "PREVAIL WET WIPES": "PREVAIL WET WIPES",
              "PREVAIL WOMEN UNDERWEAR 2XL": "PREVAIL WOMEN UNDERWEAR 2XL",
              "PREVAIL WOMEN UNDERWEAR LARGE": "PREVAIL WOMEN UNDERWEAR LARGE",
              "PREVAIL WOMEN UNDERWEAR SM/MED": "PREVAIL WOMEN UNDERWEAR SM/MED",
              "PREVAIL WOMEN UNDERWEAR XL": "PREVAIL WOMEN UNDERWEAR XL",
              "PREVAIL XTREME FIT PLUS": "PREVAIL XTREME FIT PLUS",
              "PREVAIL XTREME FIT REGULAR": "PREVAIL XTREME FIT REGULAR",
              "PROCARE ADULT BRIEFS LARGE": "PROCARE ADULT BRIEFS LARGE",
              "PROCARE ADULT BRIEFS MEDIUM": "PROCARE ADULT BRIEFS MEDIUM",
              "PROCARE ADULT BRIEFS X-LARGE": "PROCARE ADULT BRIEFS X-LARGE",
              "PROCARE ADULT WASHCLOTHS/LG": "PROCARE ADULT WASHCLOTHS/LG",
              "PROCARE BARIATRIC BRIEFS": "PROCARE BARIATRIC BRIEFS",
              "PROCARE PLUS UNDERWEAR LARGE": "PROCARE PLUS UNDERWEAR LARGE",
              "PROCARE PLUS UNDERWEAR MEDIUM": "PROCARE PLUS UNDERWEAR MEDIUM",
              "PROCARE PLUS UNDERWEAR X-LARGE": "PROCARE PLUS UNDERWEAR X-LARGE",
              "PROCARE UNDERPADS": "PROCARE UNDERPADS",
              "PROCARE UNDERWEAR LARGE": "PROCARE UNDERWEAR LARGE",
              "PROCARE UNDERWEAR MEDIUM": "PROCARE UNDERWEAR MEDIUM",
              "PROCARE UNDERWEAR X-LARGE": "PROCARE UNDERWEAR X-LARGE",
              "UNDERPADS": "UNDERPADS",
              "VALUE BUY ADULT BRIEF X-LARGE": "VALUE BUY ADULT BRIEF X-LARGE",
              "VALUE BUY BRIEF LARGE": "VALUE BUY BRIEF LARGE",
              "VALUE BUY BRIEF MEDIUM": "VALUE BUY BRIEF MEDIUM"
            },
            "G. HIRSCH & CO.": {
              "SECURE BELTED UNDERGARMENT": "SECURE BELTED UNDERGARMENT",
              "SECURE BELTLESS UNDERGARMENT": "SECURE BELTLESS UNDERGARMENT",
              "SECURE FEMALE PANTS LARGE": "SECURE FEMALE PANTS LARGE",
              "SECURE FEMALE PANTS MEDIUM": "SECURE FEMALE PANTS MEDIUM",
              "SECURE FEMALE PANTS SMALL": "SECURE FEMALE PANTS SMALL",
              "SECURE FEMALE PANTS XL": "SECURE FEMALE PANTS XL",
              "SECURE FITTED BRIEFS LARGE": "SECURE FITTED BRIEFS LARGE",
              "SECURE FITTED BRIEFS MEDIUM": "SECURE FITTED BRIEFS MEDIUM",
              "SECURE FITTED BRIEFS SMALL": "SECURE FITTED BRIEFS SMALL",
              "SECURE FITTED BRIEFS XL": "SECURE FITTED BRIEFS XL",
              "SECURE FITTED BRIEFS YOUTH": "SECURE FITTED BRIEFS YOUTH",
              "SECURE GUARDS": "SECURE GUARDS",
              "SECURE LINERS": "SECURE LINERS",
              "SECURE MALE PANTS LARGE": "SECURE MALE PANTS LARGE",
              "SECURE MALE PANTS MEDIUM": "SECURE MALE PANTS MEDIUM",
              "SECURE MALE PANTS SMALL": "SECURE MALE PANTS SMALL",
              "SECURE MALE PANTS XL": "SECURE MALE PANTS XL",
              "SECURE MATTRESS COVER": "SECURE MATTRESS COVER",
              "SECURE PADS": "SECURE PADS",
              "SECURE PLUS BELTED UNDERGARMNT": "SECURE PLUS BELTED UNDERGARMNT",
              "SECURE UNDERGARMENT STRAPS LG": "SECURE UNDERGARMENT STRAPS LG",
              "SECURE UNDERGARMENT STRAPS MED": "SECURE UNDERGARMENT STRAPS MED",
              "SECURE UNDERGARMENT STRAPS SM": "SECURE UNDERGARMENT STRAPS SM",
              "SECURE UNDERPADS": "SECURE UNDERPADS"
            },
            "HOLLISTER": {
              "BEDSIDE DRAINAGE COLLECT SYSTM": "BEDSIDE DRAINAGE COLLECT SYSTM",
              "DRAINABLE FECAL COLLECTOR": "DRAINABLE FECAL COLLECTOR",
              "EXTENSION TUBING/CONNECTOR": "EXTENSION TUBING/CONNECTOR",
              "FEMALE URINARY POUCH": "FEMALE URINARY POUCH",
              "FIRST-TIME URINE SPEC COLLECT": "FIRST-TIME URINE SPEC COLLECT",
              "RETRACTED PENIS POUCH": "RETRACTED PENIS POUCH",
              "U-BAG URINE SPECIMEN COLLECTOR": "U-BAG URINE SPECIMEN COLLECTOR",
              "URINARY LEG BAG": "URINARY LEG BAG",
              "URINARY LEG BAG STRAPS": "URINARY LEG BAG STRAPS",
              "VENTED URINARY LEG BAG": "VENTED URINARY LEG BAG"
            },
            "HOME AIDE DIAGNOSTICS": {
              "COMFORT SHIELD ADULT DIAPERS": "COMFORT SHIELD ADULT DIAPERS",
              "COMFORT SHIELD UNDERPADS ADULT": "COMFORT SHIELD UNDERPADS ADULT",
              "SAPS HEALTH INCONTINENCE PADS": "SAPS HEALTH INCONTINENCE PADS"
            },
            "HUMANICARE INTERNATIONAL": {
              "DIGNITY BRIEFMATES MENS POUCH": "DIGNITY BRIEFMATES MENS POUCH",
              "DIGNITY BRIEFMATES SUPERGUARD": "DIGNITY BRIEFMATES SUPERGUARD",
              "DIGNITY BRIEFMATES ULTRA": "DIGNITY BRIEFMATES ULTRA",
              "DIGNITY DERMATEAM UNDERPAD": "DIGNITY DERMATEAM UNDERPAD",
              "DIGNITY DERMATEAM WIPES": "DIGNITY DERMATEAM WIPES",
              "DIGNITY DRY WIPES": "DIGNITY DRY WIPES",
              "DIGNITY DUCHESS PANTY LARGE": "DIGNITY DUCHESS PANTY LARGE",
              "DIGNITY DUCHESS PANTY MED": "DIGNITY DUCHESS PANTY MED",
              "DIGNITY DUCHESS PANTY SMALL": "DIGNITY DUCHESS PANTY SMALL",
              "DIGNITY DUCHESS PANTY XL": "DIGNITY DUCHESS PANTY XL",
              "DIGNITY EXTRA-DUTY DOUBLE": "DIGNITY EXTRA-DUTY DOUBLE",
              "DIGNITY LITES": "DIGNITY LITES",
              "DIGNITY LITES LONG PADS": "DIGNITY LITES LONG PADS",
              "DIGNITY LITES THINSERTS": "DIGNITY LITES THINSERTS",
              "DIGNITY MENS BOXER SHORTS L": "DIGNITY MENS BOXER SHORTS L",
              "DIGNITY MENS BOXER SHORTS M": "DIGNITY MENS BOXER SHORTS M",
              "DIGNITY MENS BOXER SHORTS XL": "DIGNITY MENS BOXER SHORTS XL",
              "DIGNITY MENS BOXER SHORTS XXL": "DIGNITY MENS BOXER SHORTS XXL",
              "DIGNITY MINI PADS": "DIGNITY MINI PADS",
              "DIGNITY NATURALS PADS": "DIGNITY NATURALS PADS",
              "DIGNITY PANT REGULAR": "DIGNITY PANT REGULAR",
              "DIGNITY PANT REGULAR LARGE": "DIGNITY PANT REGULAR LARGE",
              "DIGNITY PANT REGULAR MED": "DIGNITY PANT REGULAR MED",
              "DIGNITY PANT REGULAR SMALL": "DIGNITY PANT REGULAR SMALL",
              "DIGNITY PANT REGULAR XL": "DIGNITY PANT REGULAR XL",
              "DIGNITY PLUS BRIEFMATES": "DIGNITY PLUS BRIEFMATES",
              "DIGNITY PLUS DERMATEAM LARGE": "DIGNITY PLUS DERMATEAM LARGE",
              "DIGNITY PLUS DERMATEAM MEDIUM": "DIGNITY PLUS DERMATEAM MEDIUM",
              "DIGNITY PLUS DERMATEAM XL": "DIGNITY PLUS DERMATEAM XL",
              "DIGNITY PLUS DISPOSABLE LG": "DIGNITY PLUS DISPOSABLE LG",
              "DIGNITY PLUS DISPOSABLE MED": "DIGNITY PLUS DISPOSABLE MED",
              "DIGNITY PLUS DISPOSABLE SM": "DIGNITY PLUS DISPOSABLE SM",
              "DIGNITY PLUS DISPOSABLE XL": "DIGNITY PLUS DISPOSABLE XL",
              "DIGNITY PLUS DISPOSABLE XXL": "DIGNITY PLUS DISPOSABLE XXL",
              "DIGNITY PLUS DISPOSABLE YOUTH": "DIGNITY PLUS DISPOSABLE YOUTH",
              "DIGNITY PLUS LARGE": "DIGNITY PLUS LARGE",
              "DIGNITY PLUS MEDIUM": "DIGNITY PLUS MEDIUM",
              "DIGNITY PLUS OVERNIGHT LARGE": "DIGNITY PLUS OVERNIGHT LARGE",
              "DIGNITY PLUS OVERNIGHT MEDIUM": "DIGNITY PLUS OVERNIGHT MEDIUM",
              "DIGNITY PLUS OVERNIGHT XL": "DIGNITY PLUS OVERNIGHT XL",
              "DIGNITY PLUS REGULAR LG": "DIGNITY PLUS REGULAR LG",
              "DIGNITY PLUS REGULAR MED": "DIGNITY PLUS REGULAR MED",
              "DIGNITY PLUS REGULAR SM": "DIGNITY PLUS REGULAR SM",
              "DIGNITY PLUS REGULAR XL": "DIGNITY PLUS REGULAR XL",
              "DIGNITY PLUS SHEET/LINEN PROT": "DIGNITY PLUS SHEET/LINEN PROT",
              "DIGNITY PLUS SMALL": "DIGNITY PLUS SMALL",
              "DIGNITY PLUS SUPER LINERS": "DIGNITY PLUS SUPER LINERS",
              "DIGNITY PLUS XL": "DIGNITY PLUS XL",
              "DIGNITY PREVENT PLUS": "DIGNITY PREVENT PLUS",
              "DIGNITY PROTECTIVE UNDERWEAR": "DIGNITY PROTECTIVE UNDERWEAR",
              "DIGNITY REGULAR CLASSIC": "DIGNITY REGULAR CLASSIC",
              "DIGNITY REGULAR-DUTY": "DIGNITY REGULAR-DUTY",
              "DIGNITY REUSABLE PAD": "DIGNITY REUSABLE PAD",
              "DIGNITY SHEET/LINEN PROTECTORS": "DIGNITY SHEET/LINEN PROTECTORS",
              "DIGNITY SPARTAN LINERS": "DIGNITY SPARTAN LINERS",
              "DIGNITY SUPER NATURALS": "DIGNITY SUPER NATURALS",
              "DIGNITY ULTRA LARGE": "DIGNITY ULTRA LARGE",
              "DIGNITY ULTRA MEDIUM": "DIGNITY ULTRA MEDIUM",
              "DIGNITY ULTRA SMALL": "DIGNITY ULTRA SMALL",
              "DIGNITY ULTRA WOMENS GUARDS": "DIGNITY ULTRA WOMENS GUARDS",
              "DIGNITY ULTRA XL": "DIGNITY ULTRA XL",
              "DIGNITY WINGFOLD": "DIGNITY WINGFOLD",
              "FREE & ACTIVE FEMALE PANTY": "FREE & ACTIVE FEMALE PANTY",
              "FREE & ACTIVE FEMALE PANTY LG": "FREE & ACTIVE FEMALE PANTY LG",
              "FREE & ACTIVE FEMALE PANTY MED": "FREE & ACTIVE FEMALE PANTY MED",
              "FREE & ACTIVE FEMALE PANTY XL": "FREE & ACTIVE FEMALE PANTY XL",
              "FREE & ACTIVE MALE BRIEF": "FREE & ACTIVE MALE BRIEF",
              "FREE & ACTIVE MALE BRIEF LG": "FREE & ACTIVE MALE BRIEF LG",
              "FREE & ACTIVE MALE BRIEF MED": "FREE & ACTIVE MALE BRIEF MED",
              "FREE & ACTIVE MALE BRIEF XL": "FREE & ACTIVE MALE BRIEF XL",
              "FREE & ACTIVE PADS": "FREE & ACTIVE PADS",
              "LADY DIGNITY PLUS PANTIES": "LADY DIGNITY PLUS PANTIES",
              "LADY DIGNITY PLUS PANTIES LG": "LADY DIGNITY PLUS PANTIES LG",
              "LADY DIGNITY PLUS PANTIES MED": "LADY DIGNITY PLUS PANTIES MED",
              "LADY DIGNITY PLUS PANTIES SM": "LADY DIGNITY PLUS PANTIES SM",
              "LADY DIGNITY PLUS PANTIES XL": "LADY DIGNITY PLUS PANTIES XL",
              "LADY DIGNITY WOMENS PANTY": "LADY DIGNITY WOMENS PANTY",
              "LADY DIGNITY WOMENS PANTY LG": "LADY DIGNITY WOMENS PANTY LG",
              "LADY DIGNITY WOMENS PANTY MED": "LADY DIGNITY WOMENS PANTY MED",
              "LADY DIGNITY WOMENS PANTY SM": "LADY DIGNITY WOMENS PANTY SM",
              "LADY DIGNITY WOMENS PANTY XL": "LADY DIGNITY WOMENS PANTY XL",
              "PREVENT PLUS SUPERGUARD": "PREVENT PLUS SUPERGUARD",
              "SIR DIGNITY MENS BRIEFS LG": "SIR DIGNITY MENS BRIEFS LG",
              "SIR DIGNITY MENS BRIEFS MED": "SIR DIGNITY MENS BRIEFS MED",
              "SIR DIGNITY MENS BRIEFS SM": "SIR DIGNITY MENS BRIEFS SM",
              "SIR DIGNITY MENS BRIEFS XL": "SIR DIGNITY MENS BRIEFS XL",
              "SIR DIGNITY MENS BRIEFS XXL": "SIR DIGNITY MENS BRIEFS XXL",
              "SIR DIGNITY PLUS BRIEFS LG": "SIR DIGNITY PLUS BRIEFS LG",
              "SIR DIGNITY PLUS BRIEFS MED": "SIR DIGNITY PLUS BRIEFS MED",
              "SIR DIGNITY PLUS BRIEFS SM": "SIR DIGNITY PLUS BRIEFS SM",
              "SIR DIGNITY PLUS BRIEFS XL": "SIR DIGNITY PLUS BRIEFS XL",
              "SIR DIGNITY PLUS BRIEFS XXL": "SIR DIGNITY PLUS BRIEFS XXL",
              "SPARTAN WATERPROOF PANT EXTRA": "SPARTAN WATERPROOF PANT EXTRA",
              "SPARTAN WATERPROOF PANT LARGE": "SPARTAN WATERPROOF PANT LARGE",
              "SPARTAN WATERPROOF PANT S/M": "SPARTAN WATERPROOF PANT S/M",
              "WATERPROOF SHEETING 36\"\"X54\"\"": "WATERPROOF SHEETING 36\"\"X54\"\"",
              "WATERPROOF SHEETING 36\"\"X80\"\"": "WATERPROOF SHEETING 36\"\"X80\"\"",
              "WATERPROOF SHEETING 39\"\"X75\"\"": "WATERPROOF SHEETING 39\"\"X75\"\""
            },
            "INBRAND": {
              "ASSURANCE BELTED UNDERGARMENT": "ASSURANCE BELTED UNDERGARMENT",
              "ASSURANCE BLADDER CONTROL PAD": "ASSURANCE BLADDER CONTROL PAD",
              "ASSURANCE FITTED BRIEF LARGE": "ASSURANCE FITTED BRIEF LARGE",
              "ASSURANCE FITTED BRIEF MEDIUM": "ASSURANCE FITTED BRIEF MEDIUM",
              "ASSURANCE FITTED BRIEF SMALL": "ASSURANCE FITTED BRIEF SMALL",
              "ASSURANCE UNDERGARMENT": "ASSURANCE UNDERGARMENT",
              "ASSURANCE UNDERPADS": "ASSURANCE UNDERPADS",
              "SURE CARE UNDERGARMENT": "SURE CARE UNDERGARMENT"
            },
            "JOHNSON&JOHNSON HEALTHCARE": {
              "TENA INSTADRY PADS": "TENA INSTADRY PADS",
              "TENA MEN HEAVY PROTECTION M/L": "TENA MEN HEAVY PROTECTION M/L",
              "TENA MEN HEAVY PROTECTION XL": "TENA MEN HEAVY PROTECTION XL",
              "TENA OVERNIGHT": "TENA OVERNIGHT",
              "TENA SERENITY": "TENA SERENITY",
              "TENA SERENITY ACTIVE PADS": "TENA SERENITY ACTIVE PADS",
              "TENA SERENITY ANYWHERE PADS": "TENA SERENITY ANYWHERE PADS",
              "TENA SERENITY INSTADRY": "TENA SERENITY INSTADRY",
              "TENA SERENITY LONG": "TENA SERENITY LONG",
              "TENA SERENITY OVERNIGHT PADS": "TENA SERENITY OVERNIGHT PADS",
              "TENA SERENITY PANTILINERS": "TENA SERENITY PANTILINERS",
              "TENA SERENITY STYLISH": "TENA SERENITY STYLISH",
              "TENA WOMEN HEAVY PROTECT S/M": "TENA WOMEN HEAVY PROTECT S/M",
              "TENA WOMEN HEAVY PROTECTION LG": "TENA WOMEN HEAVY PROTECTION LG",
              "TENA WOMEN HEAVY PROTECTION XL": "TENA WOMEN HEAVY PROTECTION XL"
            },
            "KIMBERLY-CLARK": {
              "COTTONELLE FRESH CARE": "COTTONELLE FRESH CARE",
              "COTTONELLE FRESH MOIST WIPES": "COTTONELLE FRESH MOIST WIPES",
              "COTTONELLE MOIST WIPE FOR KIDS": "COTTONELLE MOIST WIPE FOR KIDS",
              "COTTONELLE MOIST WIPES": "COTTONELLE MOIST WIPES",
              "DEPEND ADJUSTABLE UNDERWEAR": "DEPEND ADJUSTABLE UNDERWEAR",
              "DEPEND ADJUSTABLE UNDERWEAR LG": "DEPEND ADJUSTABLE UNDERWEAR LG",
              "DEPEND BOOST INSERT": "DEPEND BOOST INSERT",
              "DEPEND EASY FIT UNDERGARMENTS": "DEPEND EASY FIT UNDERGARMENTS",
              "DEPEND FIT-FLEX FOR MEN": "DEPEND FIT-FLEX FOR MEN",
              "DEPEND FIT-FLEX WOMENS/SMALL": "DEPEND FIT-FLEX WOMENS/SMALL",
              "DEPEND FIT-FLEX-WOMEN-M": "DEPEND FIT-FLEX-WOMEN-M",
              "DEPEND FITTED BRIEFS SM/MED": "DEPEND FITTED BRIEFS SM/MED",
              "DEPEND GUARDS FOR MEN": "DEPEND GUARDS FOR MEN",
              "DEPEND PANT EXTRA LARGE": "DEPEND PANT EXTRA LARGE",
              "DEPEND PANT LARGE": "DEPEND PANT LARGE",
              "DEPEND PANT SM/MED": "DEPEND PANT SM/MED",
              "DEPEND PROTECTION BRIEFS LARGE": "DEPEND PROTECTION BRIEFS LARGE",
              "DEPEND REAL FIT/BRIEF/MEN/L-XL": "DEPEND REAL FIT/BRIEF/MEN/L-XL",
              "DEPEND REAL FIT/BRIEF/MEN/S-M": "DEPEND REAL FIT/BRIEF/MEN/S-M",
              "DEPEND REAL-FIT BRIEFS FOR MEN": "DEPEND REAL-FIT BRIEFS FOR MEN",
              "DEPEND SHIELDS FOR MEN": "DEPEND SHIELDS FOR MEN",
              "DEPEND SILHOUETTE ACTIVE FIT": "DEPEND SILHOUETTE ACTIVE FIT",
              "DEPEND SILHOUETTE BRIEFS L/XL": "DEPEND SILHOUETTE BRIEFS L/XL",
              "DEPEND SILHOUETTE BRIEFS S/M": "DEPEND SILHOUETTE BRIEFS S/M",
              "DEPEND SILHOUETTE UNDERWEAR": "DEPEND SILHOUETTE UNDERWEAR",
              "DEPEND UNDERGARMENT EX ABSORB": "DEPEND UNDERGARMENT EX ABSORB",
              "DEPEND UNDERGARMENTS": "DEPEND UNDERGARMENTS",
              "DEPEND UNDERWEAR LARGE": "DEPEND UNDERWEAR LARGE",
              "DEPEND UNDERWEAR LARGE/XL": "DEPEND UNDERWEAR LARGE/XL",
              "DEPEND UNDERWEAR SM/MED": "DEPEND UNDERWEAR SM/MED",
              "DEPEND UNDERWEAR X-LARGE": "DEPEND UNDERWEAR X-LARGE",
              "POISE HOURGLASS SHAPE": "POISE HOURGLASS SHAPE",
              "POISE LINERS": "POISE LINERS",
              "POISE MAXIMUM ABSORBENCY": "POISE MAXIMUM ABSORBENCY",
              "POISE MICROLINERS LONG": "POISE MICROLINERS LONG",
              "POISE MODERATE ABSORBENCY": "POISE MODERATE ABSORBENCY",
              "POISE PAD": "POISE PAD",
              "POISE THIN-SHAPE": "POISE THIN-SHAPE",
              "POISE THIN-SHAPE MODERATE": "POISE THIN-SHAPE MODERATE",
              "POISE ULTIMATE ABSORBENCY": "POISE ULTIMATE ABSORBENCY",
              "POISE ULTRA THIN PADS": "POISE ULTRA THIN PADS",
              "POISE ULTRA THINS": "POISE ULTRA THINS",
              "SCOTT MOIST WIPES": "SCOTT MOIST WIPES"
            },
            "KROGER COMPANY": {
              "OVERNIGHT SLEEP GUARD LARGE": "OVERNIGHT SLEEP GUARD LARGE"
            },
            "LEADER BRAND PRODUCTS": {
              "PROTECTIVE UNDERWEAR SUPER LG": "PROTECTIVE UNDERWEAR SUPER LG",
              "PROTECTIVE UNDERWEAR SUPER XL": "PROTECTIVE UNDERWEAR SUPER XL",
              "UNDERPADS EXTRA LARGE": "UNDERPADS EXTRA LARGE"
            },
            "M.C. JOHNSON COMPANY": {
              "CATH-SECURE": "CATH-SECURE",
              "CATH-SECURE DUAL TAB": "CATH-SECURE DUAL TAB",
              "CATH-SECURE FOR KIDS": "CATH-SECURE FOR KIDS",
              "CATH-SECURE PLUS": "CATH-SECURE PLUS"
            },
            "MCKESSON": {
              "HM ENTRUST PLUS BLADDER CNTRL": "HM ENTRUST PLUS BLADDER CNTRL",
              "HM ENTRUST PLUS DISP UNDERWEAR": "HM ENTRUST PLUS DISP UNDERWEAR",
              "HM ENTRUST PLUS UNDERPADS": "HM ENTRUST PLUS UNDERPADS",
              "HM MENS UNDERWEAR": "HM MENS UNDERWEAR",
              "HM UNDERPADS 23\"\" X 36\"\"": "HM UNDERPADS 23\"\" X 36\"\""
            },
            "MCKESSON HOME HEALTH CARE": {
              "BEDPAN": "BEDPAN",
              "DISPOSABLE LINERS": "DISPOSABLE LINERS",
              "DISPOSABLE PANTS": "DISPOSABLE PANTS",
              "DISPOSABLE POLYMER PANTS LARGE": "DISPOSABLE POLYMER PANTS LARGE",
              "DISPOSABLE POLYMER PANTS MED": "DISPOSABLE POLYMER PANTS MED",
              "ENTRUST PLUS BLDR CNTRL PADS": "ENTRUST PLUS BLDR CNTRL PADS",
              "ENTRUST PLUS BRIEFS": "ENTRUST PLUS BRIEFS",
              "ENTRUST PLUS BRIEFS LARGE": "ENTRUST PLUS BRIEFS LARGE",
              "ENTRUST PLUS BRIEFS MEDIUM": "ENTRUST PLUS BRIEFS MEDIUM",
              "ENTRUST PLUS BRIEFS SMALL": "ENTRUST PLUS BRIEFS SMALL",
              "ENTRUST PLUS DISP UNDERPADS": "ENTRUST PLUS DISP UNDERPADS",
              "ENTRUST PLUS DISP UNDERWEAR": "ENTRUST PLUS DISP UNDERWEAR",
              "ENTRUST PLUS FITTED BRIEFS LG": "ENTRUST PLUS FITTED BRIEFS LG",
              "ENTRUST PLUS FITTED BRIEFS MED": "ENTRUST PLUS FITTED BRIEFS MED",
              "ENTRUST PLUS SHIELD": "ENTRUST PLUS SHIELD",
              "ENTRUST PLUS UNDERGARMENTS": "ENTRUST PLUS UNDERGARMENTS",
              "ENTRUST PLUS UNDERPADS 23\"\"X36\"\"": "ENTRUST PLUS UNDERPADS 23\"\"X36\"\"",
              "ENTRUST PLUS UNDERWEAR S/M": "ENTRUST PLUS UNDERWEAR S/M",
              "ENTRUST PLUS UNDERWEAR XL": "ENTRUST PLUS UNDERWEAR XL",
              "REUSABLE PANTS W/LINER": "REUSABLE PANTS W/LINER",
              "REUSABLE PANTS W/O LINER": "REUSABLE PANTS W/O LINER",
              "REUSABLE SNAP-IN LINERS": "REUSABLE SNAP-IN LINERS",
              "SM INCONTINENT LINER DISP": "SM INCONTINENT LINER DISP",
              "SM UNDERPADS MEDIUM": "SM UNDERPADS MEDIUM",
              "UNDERPADS EXTRA LARGE": "UNDERPADS EXTRA LARGE",
              "UNDERPADS MEDIUM": "UNDERPADS MEDIUM",
              "UNDERPADS SMALL": "UNDERPADS SMALL"
            },
            "MCKESSON SUNMARK": {
              "ENTRUST PLUS BRIEFS MEDIUM": "ENTRUST PLUS BRIEFS MEDIUM",
              "ENTRUST PLUS BRIEFS SMALL": "ENTRUST PLUS BRIEFS SMALL",
              "ENTRUST PLUS UNDERGARMENTS": "ENTRUST PLUS UNDERGARMENTS"
            },
            "MEDICINE SHOPPE": {
              "BELTED UNDERGARMENT": "BELTED UNDERGARMENT",
              "CONTROL PADS EXTRA ABSORBENCY": "CONTROL PADS EXTRA ABSORBENCY"
            },
            "MEDLINE INDUSTRIES": {
              "ALOETOUCH CLEANSING CLOTHS": "ALOETOUCH CLEANSING CLOTHS",
              "PROTECTION PLUS CLASSIC LARGE": "PROTECTION PLUS CLASSIC LARGE",
              "PROTECTION PLUS CLASSIC MEDIUM": "PROTECTION PLUS CLASSIC MEDIUM",
              "PROTECTION PLUS CLASSIC SMALL": "PROTECTION PLUS CLASSIC SMALL",
              "PROTECTION PLUS CLASSIC XL": "PROTECTION PLUS CLASSIC XL",
              "PROTECTION PLUS CLASSIC XXL": "PROTECTION PLUS CLASSIC XXL",
              "PROTECTION PLUS DELUXE": "PROTECTION PLUS DELUXE",
              "PROTECTION PLUS UNDERWEAR XL": "PROTECTION PLUS UNDERWEAR XL",
              "ULTRA-SOFT CLASSIC BRIEFS LG": "ULTRA-SOFT CLASSIC BRIEFS LG",
              "ULTRA-SOFT CLASSIC BRIEFS MED": "ULTRA-SOFT CLASSIC BRIEFS MED",
              "ULTRA-SOFT CLASSIC BRIEFS REG": "ULTRA-SOFT CLASSIC BRIEFS REG",
              "ULTRA-SOFT CLASSIC BRIEFS XL": "ULTRA-SOFT CLASSIC BRIEFS XL",
              "ULTRA-SOFT PLUS BRIEFS LARGE": "ULTRA-SOFT PLUS BRIEFS LARGE",
              "ULTRA-SOFT PLUS BRIEFS MEDIUM": "ULTRA-SOFT PLUS BRIEFS MEDIUM",
              "ULTRA-SOFT PLUS BRIEFS REGULAR": "ULTRA-SOFT PLUS BRIEFS REGULAR",
              "ULTRA-SOFT PLUS BRIEFS XL": "ULTRA-SOFT PLUS BRIEFS XL",
              "ULTRA-SOFT PLUS BRIEFS XXL": "ULTRA-SOFT PLUS BRIEFS XXL"
            },
            "MEDLINE/DERMAL MANAGEMENT": {
              "CARRINGTON INCONT SKIN CARE": "CARRINGTON INCONT SKIN CARE"
            },
            "MEIJER": {
              "REALITY INCONTINENT BRIEFS SM": "REALITY INCONTINENT BRIEFS SM"
            },
            "P & G HEALTH": {
              "ALWAYS DISCREET": "ALWAYS DISCREET",
              "ALWAYS DISCREET UNDERWEAR": "ALWAYS DISCREET UNDERWEAR"
            },
            "P & G PAPER PRODUCTS": {
              "ALWAYS DISCREET": "ALWAYS DISCREET",
              "ALWAYS DISCREET UNDERWEAR": "ALWAYS DISCREET UNDERWEAR",
              "ALWAYS FEMININE WIPES": "ALWAYS FEMININE WIPES",
              "ALWAYS WIPES-TO-GO": "ALWAYS WIPES-TO-GO",
              "ATTENDS BRIEFS SMALL": "ATTENDS BRIEFS SMALL",
              "ATTENDS UNDERPADS L EX-ABSORB": "ATTENDS UNDERPADS L EX-ABSORB",
              "ATTENDS UNDERPADS LARGE REG": "ATTENDS UNDERPADS LARGE REG",
              "ATTENDS WASHCLOTHS": "ATTENDS WASHCLOTHS"
            },
            "PRINCIPLE BUSINESS ENTERPRISES": {
              "HIGH CAPACITY PADS": "HIGH CAPACITY PADS",
              "PADSORBER BED PAN LINERS": "PADSORBER BED PAN LINERS",
              "PERSONAL CLEANSING WASHCLOTHS": "PERSONAL CLEANSING WASHCLOTHS",
              "REGULAR STRAP": "REGULAR STRAP",
              "SELECT BELTED UNDERGARMENTS": "SELECT BELTED UNDERGARMENTS",
              "SELECT BOOSTER PADS": "SELECT BOOSTER PADS",
              "SELECT CONTOURED SHIELD LINERS": "SELECT CONTOURED SHIELD LINERS",
              "SELECT DAYTIME LINERS": "SELECT DAYTIME LINERS",
              "SELECT DAYTIME PLUS LINERS": "SELECT DAYTIME PLUS LINERS",
              "SELECT DISPOSABLE BRIEF LARGE": "SELECT DISPOSABLE BRIEF LARGE",
              "SELECT DISPOSABLE BRIEF MEDIUM": "SELECT DISPOSABLE BRIEF MEDIUM",
              "SELECT DISPOSABLE BRIEF SMALL": "SELECT DISPOSABLE BRIEF SMALL",
              "SELECT DISPOSABLE BRIEF XL": "SELECT DISPOSABLE BRIEF XL",
              "SELECT DISPOSABLE BRIEF XS": "SELECT DISPOSABLE BRIEF XS",
              "SELECT DISPOSABLE UNDERWEAR LG": "SELECT DISPOSABLE UNDERWEAR LG",
              "SELECT DISPOSABLE UNDERWEAR M": "SELECT DISPOSABLE UNDERWEAR M",
              "SELECT DISPOSABLE UNDERWEAR SM": "SELECT DISPOSABLE UNDERWEAR SM",
              "SELECT DISPOSABLE UNDERWEAR XL": "SELECT DISPOSABLE UNDERWEAR XL",
              "SELECT DISPOSABLE UNDERWEAR XS": "SELECT DISPOSABLE UNDERWEAR XS",
              "SELECT KIDS DISPOSABLE BRIEFS": "SELECT KIDS DISPOSABLE BRIEFS",
              "SELECT MESH PANTS EXTRA LARGE": "SELECT MESH PANTS EXTRA LARGE",
              "SELECT MESH PANTS LARGE": "SELECT MESH PANTS LARGE",
              "SELECT MESH PANTS MEDIUM": "SELECT MESH PANTS MEDIUM",
              "SELECT MESH PANTS XXL": "SELECT MESH PANTS XXL",
              "SELECT NIGHTTIME LINERS": "SELECT NIGHTTIME LINERS",
              "SELECT PERSONAL CARE PADS": "SELECT PERSONAL CARE PADS",
              "SELECT SHIELD PAD": "SELECT SHIELD PAD",
              "SELECT SLIM N\\' SOFT GUARD": "SELECT SLIM N\\' SOFT GUARD",
              "SELECT SLIM N\\' SOFT GUARD REG": "SELECT SLIM N\\' SOFT GUARD REG",
              "SELECT SLIM N\\' SOFT PAD": "SELECT SLIM N\\' SOFT PAD",
              "SELECT SOFT N\\' BREATHABLE LG": "SELECT SOFT N\\' BREATHABLE LG",
              "SELECT SOFT N\\' BREATHABLE MED": "SELECT SOFT N\\' BREATHABLE MED",
              "SELECT SOFT N\\' BREATHABLE XL": "SELECT SOFT N\\' BREATHABLE XL",
              "SELECT ULTRA UNDERPADS LARGE": "SELECT ULTRA UNDERPADS LARGE",
              "SELECT UNDERGARMENT STRAPS": "SELECT UNDERGARMENT STRAPS",
              "SELECT UNDERPADS EXTRA LARGE": "SELECT UNDERPADS EXTRA LARGE",
              "SELECT UNDERPADS LARGE": "SELECT UNDERPADS LARGE",
              "SLIMLINE ADJUSTABLE BRIEFS": "SLIMLINE ADJUSTABLE BRIEFS",
              "SLIMLINE CONTOURED LINERS": "SLIMLINE CONTOURED LINERS",
              "SLIMLINE DISPOSABLE BRIEFS LG": "SLIMLINE DISPOSABLE BRIEFS LG",
              "SLIMLINE DISPOSABLE BRIEFS MED": "SLIMLINE DISPOSABLE BRIEFS MED",
              "SLIMLINE DISPOSABLE BRIEFS SM": "SLIMLINE DISPOSABLE BRIEFS SM",
              "SLIMLINE DISPOSABLE BRIEFS XL": "SLIMLINE DISPOSABLE BRIEFS XL",
              "SLIMLINE DISPOSABLE BRIEFS YTH": "SLIMLINE DISPOSABLE BRIEFS YTH",
              "SLIMLINE FITTED LINERS": "SLIMLINE FITTED LINERS",
              "SLIMLINE PEACH SHEET CARE PADS": "SLIMLINE PEACH SHEET CARE PADS",
              "TOPLINERS": "TOPLINERS",
              "TRANQUILITY ADULT LINER": "TRANQUILITY ADULT LINER",
              "TRANQUILITY AIR-PLUS BARIATRIC": "TRANQUILITY AIR-PLUS BARIATRIC",
              "TRANQUILITY ATN BRIEF EX SM": "TRANQUILITY ATN BRIEF EX SM",
              "TRANQUILITY ATN BRIEF LARGE": "TRANQUILITY ATN BRIEF LARGE",
              "TRANQUILITY ATN BRIEF MED": "TRANQUILITY ATN BRIEF MED",
              "TRANQUILITY ATN BRIEF SMALL": "TRANQUILITY ATN BRIEF SMALL",
              "TRANQUILITY ATN BRIEF XL": "TRANQUILITY ATN BRIEF XL",
              "TRANQUILITY BARIATRIC PANT": "TRANQUILITY BARIATRIC PANT",
              "TRANQUILITY BRIEFS": "TRANQUILITY BRIEFS",
              "TRANQUILITY CLEANSING WIPES": "TRANQUILITY CLEANSING WIPES",
              "TRANQUILITY FLEXTENDER STRAP": "TRANQUILITY FLEXTENDER STRAP",
              "TRANQUILITY HI-RISE BARIATRIC": "TRANQUILITY HI-RISE BARIATRIC",
              "TRANQUILITY HIGH CAPACITY PAD": "TRANQUILITY HIGH CAPACITY PAD",
              "TRANQUILITY MINI TRIMSHIELD": "TRANQUILITY MINI TRIMSHIELD",
              "TRANQUILITY PREMIUM DAYTIME": "TRANQUILITY PREMIUM DAYTIME",
              "TRANQUILITY PREMIUM OVERNIGHT": "TRANQUILITY PREMIUM OVERNIGHT",
              "TRANQUILITY PREMIUM PROTECTION": "TRANQUILITY PREMIUM PROTECTION",
              "TRANQUILITY PREMIUN DAYTIME": "TRANQUILITY PREMIUN DAYTIME",
              "TRANQUILITY SLIMLINE BRIEF": "TRANQUILITY SLIMLINE BRIEF",
              "TRANQUILITY SLIMLINE BRIEF ADJ": "TRANQUILITY SLIMLINE BRIEF ADJ",
              "TRANQUILITY SLIMLINE BRIEF JR": "TRANQUILITY SLIMLINE BRIEF JR",
              "TRANQUILITY SLIMLINE BRIEF LG": "TRANQUILITY SLIMLINE BRIEF LG",
              "TRANQUILITY SLIMLINE BRIEF MED": "TRANQUILITY SLIMLINE BRIEF MED",
              "TRANQUILITY SLIMLINE BRIEF SM": "TRANQUILITY SLIMLINE BRIEF SM",
              "TRANQUILITY SLIMLINE BRIEF YTH": "TRANQUILITY SLIMLINE BRIEF YTH",
              "TRANQUILITY SLIMLINE FIT LINER": "TRANQUILITY SLIMLINE FIT LINER",
              "TRANQUILITY SLIMLINE SHEET PAD": "TRANQUILITY SLIMLINE SHEET PAD",
              "TRANQUILITY SMARTCORE BRIEFS": "TRANQUILITY SMARTCORE BRIEFS",
              "TRANQUILITY SUPER PLUS LINER": "TRANQUILITY SUPER PLUS LINER",
              "TRANQUILITY THINLINER 2022": "TRANQUILITY THINLINER 2022",
              "TRANQUILITY THINLINER 610": "TRANQUILITY THINLINER 610",
              "TRANQUILITY THINLINER 714": "TRANQUILITY THINLINER 714",
              "TRANQUILITY TOPLINER": "TRANQUILITY TOPLINER",
              "TRANQUILITY TOPLINER BOOSTER": "TRANQUILITY TOPLINER BOOSTER",
              "TRANQUILITY TRIMSHIELD FOR MEN": "TRANQUILITY TRIMSHIELD FOR MEN",
              "TRANQUILITY TRIMSHIELD REGULAR": "TRANQUILITY TRIMSHIELD REGULAR",
              "TRANQUILITY TRIMSHIELD SUPR-PL": "TRANQUILITY TRIMSHIELD SUPR-PL",
              "TRANQUILITY UNDERGARMENTS": "TRANQUILITY UNDERGARMENTS",
              "TRANQUILITY UNDERPADS": "TRANQUILITY UNDERPADS",
              "TRANQUILITY WASHABLE PANT L": "TRANQUILITY WASHABLE PANT L",
              "TRANQUILITY WASHABLE PANT M": "TRANQUILITY WASHABLE PANT M",
              "TRANQUILITY WASHABLE PANT S": "TRANQUILITY WASHABLE PANT S",
              "TRANQUILITY WASHABLE PANT XL": "TRANQUILITY WASHABLE PANT XL",
              "TRANQUILITY WASHABLE PANT XS": "TRANQUILITY WASHABLE PANT XS",
              "TRANQUILITY XL+ BARIATRIC": "TRANQUILITY XL+ BARIATRIC",
              "TRIMSHIELD": "TRIMSHIELD",
              "WASHABLE PANTS": "WASHABLE PANTS"
            },
            "RITE AID CORPORATION": {
              "RA ADULT WIPES": "RA ADULT WIPES",
              "RA BELTED UNDERGARM EX ABSORB": "RA BELTED UNDERGARM EX ABSORB",
              "RA BELTED UNDERGARMENT REGULAR": "RA BELTED UNDERGARMENT REGULAR",
              "RA BLADDER CONTROL LINERS": "RA BLADDER CONTROL LINERS",
              "RA BLADDER CONTROL PADS": "RA BLADDER CONTROL PADS",
              "RA BLADDER CONTROL PADS LONG": "RA BLADDER CONTROL PADS LONG",
              "RA BOXERS MEN MED ABSORBANCY": "RA BOXERS MEN MED ABSORBANCY",
              "RA BRIEFS FOR MEN & WOMEN/XL": "RA BRIEFS FOR MEN & WOMEN/XL",
              "RA CONTOURED BED PAN": "RA CONTOURED BED PAN",
              "RA DELUXE UNDERPADS MEN/WOMEN": "RA DELUXE UNDERPADS MEN/WOMEN",
              "RA GUARDS FOR MEN": "RA GUARDS FOR MEN",
              "RA MALE URINAL REUSABLE": "RA MALE URINAL REUSABLE",
              "RA OVERNIGHT PADS FOR WOMEN": "RA OVERNIGHT PADS FOR WOMEN",
              "RA PADS FOR WOMEN": "RA PADS FOR WOMEN",
              "RA PREMIUMPLUS FITTED BRIEFS": "RA PREMIUMPLUS FITTED BRIEFS",
              "RA PROTECTIVE UNDERWEAR": "RA PROTECTIVE UNDERWEAR",
              "RA PROTECTIVE UNDERWEAR SM/MED": "RA PROTECTIVE UNDERWEAR SM/MED",
              "RA SURECHOICE BLADDER CONTROL": "RA SURECHOICE BLADDER CONTROL",
              "RA SURECHOICE PADS/EXTRA PLUS": "RA SURECHOICE PADS/EXTRA PLUS",
              "RA SURECHOICE PADS/LIGHT": "RA SURECHOICE PADS/LIGHT",
              "RA SURECHOICE PADS/MAXIMUM": "RA SURECHOICE PADS/MAXIMUM",
              "RA SURECHOICE PADS/MODERATE": "RA SURECHOICE PADS/MODERATE",
              "RA SURECHOICE PANTILINERS": "RA SURECHOICE PANTILINERS",
              "RA UNDERPADS": "RA UNDERPADS",
              "RA UNDERPADS EXTRA LARGE": "RA UNDERPADS EXTRA LARGE",
              "RA UNDERPADS SUPER LARGE": "RA UNDERPADS SUPER LARGE",
              "RA UNDERWEAR FOR MEN & WOMEN": "RA UNDERWEAR FOR MEN & WOMEN",
              "RA UNDERWEAR FOR MEN LG-XL": "RA UNDERWEAR FOR MEN LG-XL",
              "RA UNDERWEAR FOR MEN XL": "RA UNDERWEAR FOR MEN XL",
              "RA UNDERWEAR FOR MEN/SM-MED": "RA UNDERWEAR FOR MEN/SM-MED",
              "RA UNDERWEAR FOR WOMEN LG": "RA UNDERWEAR FOR WOMEN LG",
              "RA UNDERWEAR FOR WOMEN SM-MED": "RA UNDERWEAR FOR WOMEN SM-MED",
              "RA UNDERWEAR FOR WOMEN XLARGE": "RA UNDERWEAR FOR WOMEN XLARGE",
              "RA UNDERWEAR MEN/WOMEN S/M": "RA UNDERWEAR MEN/WOMEN S/M",
              "RA UNDERWEAR MEN/WOMEN XL": "RA UNDERWEAR MEN/WOMEN XL",
              "RA WIPES FLUSHABLE/MOIST": "RA WIPES FLUSHABLE/MOIST"
            },
            "SDI USA": {
              "CLEVER CHOICE COMFORT PROTECT": "CLEVER CHOICE COMFORT PROTECT"
            },
            "SIMPLE DIAGNOSTICS": {
              "COMFORT PROTECT ADULT DIAP XL": "COMFORT PROTECT ADULT DIAP XL"
            },
            "SMART CHOICE MEDICAL": {
              "HEXASORB DISPOSABLE UNDERPADS": "HEXASORB DISPOSABLE UNDERPADS"
            },
            "SMITH & NEPHEW UNITED": {
              "EXU-DRY CRIB SHEET 20\"\"X28\"\"": "EXU-DRY CRIB SHEET 20\"\"X28\"\""
            },
            "STEADMED MEDICAL": {
              "ELTA CLEANSE ODOR ELIMINATOR": "ELTA CLEANSE ODOR ELIMINATOR"
            },
            "SUNBEAM": {
              "SUNBEAM INCONTINENT UNDER PAD": "SUNBEAM INCONTINENT UNDER PAD"
            },
            "UROCARE PRODUCTS, INC.": {
              "ANTI-REFLUX VALVE": "ANTI-REFLUX VALVE",
              "CATHETER CONNECTOR": "CATHETER CONNECTOR",
              "CLEAR-VINYL DRAINAGE TUBE": "CLEAR-VINYL DRAINAGE TUBE",
              "CLEAR-VINYL EXTENSION TUBE": "CLEAR-VINYL EXTENSION TUBE",
              "EXT TUBE 18\"\"/CONNECTOR": "EXT TUBE 18\"\"/CONNECTOR",
              "EXT TUBE 18\"\"/CONNECTOR/CLAMP": "EXT TUBE 18\"\"/CONNECTOR/CLAMP",
              "EXT TUBE 18\"\"/CONNECTOR/STERILE": "EXT TUBE 18\"\"/CONNECTOR/STERILE",
              "FABRIC LEG BAG HOLDER": "FABRIC LEG BAG HOLDER",
              "FITZ-ALL FABRIC LEG STRAP": "FITZ-ALL FABRIC LEG STRAP",
              "GASKET-RING LARGE": "GASKET-RING LARGE",
              "GASKET-RING SMALL": "GASKET-RING SMALL",
              "LATEX LEG STRAP SET/BUTTONS": "LATEX LEG STRAP SET/BUTTONS",
              "LATEX LEG STRAP/BUTTON": "LATEX LEG STRAP/BUTTON",
              "LATEX SHEATHS EXTRA LARGE 40MM": "LATEX SHEATHS EXTRA LARGE 40MM",
              "LATEX SHEATHS LARGE 35MM": "LATEX SHEATHS LARGE 35MM",
              "LATEX SHEATHS SMALL 25MM": "LATEX SHEATHS SMALL 25MM",
              "LATEX SHEATHS STANDARD 30MM": "LATEX SHEATHS STANDARD 30MM",
              "QUICK DRAIN CLAMP": "QUICK DRAIN CLAMP",
              "THUMB CLAMP/ADJUSTABLE": "THUMB CLAMP/ADJUSTABLE",
              "THUMB CLAMP/TWO POSITION": "THUMB CLAMP/TWO POSITION",
              "TUBING CONNECTOR": "TUBING CONNECTOR",
              "URINAL TO LEG BAG ADAPTOR": "URINAL TO LEG BAG ADAPTOR",
              "URO-BOND 5000 SKIN ADHESIVE": "URO-BOND 5000 SKIN ADHESIVE",
              "URO-BOND 5010 THINNER": "URO-BOND 5010 THINNER",
              "URO-CUP VAGINAL URINARY SYSTEM": "URO-CUP VAGINAL URINARY SYSTEM",
              "URO-KIT LATEX LEG BAG": "URO-KIT LATEX LEG BAG",
              "URO-SAFE VINYL LEG BAG/CLAMP": "URO-SAFE VINYL LEG BAG/CLAMP",
              "URO-SAFE VINYL LEG BAG/TWIST": "URO-SAFE VINYL LEG BAG/TWIST",
              "URO-STRAP MALE EXT CATH STRAP": "URO-STRAP MALE EXT CATH STRAP",
              "UROCARE ADHESIVE REMOVER PADS": "UROCARE ADHESIVE REMOVER PADS",
              "UROCARE BIDET TOILET INSERT": "UROCARE BIDET TOILET INSERT",
              "UROCARE CLEANSING BRUSH": "UROCARE CLEANSING BRUSH",
              "UROCARE FABRIC LEG STRAP": "UROCARE FABRIC LEG STRAP",
              "UROCARE FABRIC LEG STRAPS": "UROCARE FABRIC LEG STRAPS",
              "UROCARE FOLEY CATHETER STRAP": "UROCARE FOLEY CATHETER STRAP",
              "UROCARE LARGE TOP ADAPTOR": "UROCARE LARGE TOP ADAPTOR",
              "UROCARE LATEX LEG BAG": "UROCARE LATEX LEG BAG",
              "UROCARE LEG BAG": "UROCARE LEG BAG",
              "UROCARE MALE URINAL GARMENT": "UROCARE MALE URINAL GARMENT",
              "UROCARE MALE URINAL KIT": "UROCARE MALE URINAL KIT",
              "UROCARE MALE URINAL LARGE": "UROCARE MALE URINAL LARGE",
              "UROCARE MALE URINAL SHEATH": "UROCARE MALE URINAL SHEATH",
              "UROCARE MALE URINAL SMALL": "UROCARE MALE URINAL SMALL",
              "UROCARE MALE URINAL STANDARD": "UROCARE MALE URINAL STANDARD",
              "UROCARE NIGHT DR BOTTLE ADAPTR": "UROCARE NIGHT DR BOTTLE ADAPTR",
              "UROCARE NIGHT DRAIN BOTTLE": "UROCARE NIGHT DRAIN BOTTLE",
              "UROCARE NIGHT DRAIN BOTTLE CAP": "UROCARE NIGHT DRAIN BOTTLE CAP",
              "UROCARE NIGHT DRAINAGE BAG": "UROCARE NIGHT DRAINAGE BAG",
              "UROCARE SPORT BAG FABRIC STRAP": "UROCARE SPORT BAG FABRIC STRAP",
              "UROCARE STANDARD TOP ADAPTOR": "UROCARE STANDARD TOP ADAPTOR",
              "UROCARE STNDRD BOTTOM DR VALVE": "UROCARE STNDRD BOTTOM DR VALVE",
              "UROCARE STRAIGHT-THRU ADAPTOR": "UROCARE STRAIGHT-THRU ADAPTOR",
              "UROCARE UTILITY STRAP/FASTENER": "UROCARE UTILITY STRAP/FASTENER",
              "UROFOAM-1 SINGLE-SIDED STRIPS": "UROFOAM-1 SINGLE-SIDED STRIPS",
              "UROFOAM-2 2-SIDED ADHES FOAM": "UROFOAM-2 2-SIDED ADHES FOAM",
              "WHITE-RUBBER TUBING": "WHITE-RUBBER TUBING"
            },
            "WAL-MART": {
              "ASSURANCE BELTED SHIELD": "ASSURANCE BELTED SHIELD",
              "ASSURANCE BOXERS MENS 28\"\"-40\"\"": "ASSURANCE BOXERS MENS 28\"\"-40\"\"",
              "ASSURANCE BOXERS MENS 38\"\"-64\"\"": "ASSURANCE BOXERS MENS 38\"\"-64\"\"",
              "ASSURANCE FITTED BRIEF LARGE": "ASSURANCE FITTED BRIEF LARGE",
              "ASSURANCE FITTED BRIEF MEDIUM": "ASSURANCE FITTED BRIEF MEDIUM",
              "ASSURANCE GUARDS FOR MEN": "ASSURANCE GUARDS FOR MEN",
              "ASSURANCE PREMIUM WASHCLOTHS": "ASSURANCE PREMIUM WASHCLOTHS",
              "ASSURANCE UNDERPADS": "ASSURANCE UNDERPADS",
              "ASSURANCE UNDERWEAR MENS": "ASSURANCE UNDERWEAR MENS",
              "ASSURANCE UNDERWEAR WOMENS": "ASSURANCE UNDERWEAR WOMENS",
              "EQ BODY CURVE PADS REGULAR": "EQ BODY CURVE PADS REGULAR",
              "EQ OPTIONS LINERS LONG": "EQ OPTIONS LINERS LONG",
              "EQ OPTIONS LINERS REGULAR": "EQ OPTIONS LINERS REGULAR",
              "EQ OPTIONS PADS LONG": "EQ OPTIONS PADS LONG",
              "EQ OPTIONS PADS REGULAR": "EQ OPTIONS PADS REGULAR",
              "EQ OPTIONS ULTRA THINS LINERS": "EQ OPTIONS ULTRA THINS LINERS",
              "EQ PERSONAL CLEANSING CLOTHS": "EQ PERSONAL CLEANSING CLOTHS"
            },
            "WALGREENS": {
              "BELTED UNDERGARMENT": "BELTED UNDERGARMENT",
              "BELTED UNDERGARMENT XTRA": "BELTED UNDERGARMENT XTRA",
              "BLADDER CONTROL PADS EX ABSORB": "BLADDER CONTROL PADS EX ABSORB",
              "BLADDER CONTROL PADS EXTRA PLS": "BLADDER CONTROL PADS EXTRA PLS",
              "BLADDER CONTROL PADS REGULAR": "BLADDER CONTROL PADS REGULAR",
              "BLADDER CONTROL PADS ULTRA": "BLADDER CONTROL PADS ULTRA",
              "BLADDER CONTROL PADS ULTRA PLS": "BLADDER CONTROL PADS ULTRA PLS",
              "BRIEFS OVERNIGHT LARGE": "BRIEFS OVERNIGHT LARGE",
              "BRIEFS OVERNIGHT MEDIUM": "BRIEFS OVERNIGHT MEDIUM",
              "CERTAINTY ADJ UNDERWEAR LARGE": "CERTAINTY ADJ UNDERWEAR LARGE",
              "CERTAINTY ADJ UNDERWEAR SM/MED": "CERTAINTY ADJ UNDERWEAR SM/MED",
              "CERTAINTY ADULT WASHCLOTHS": "CERTAINTY ADULT WASHCLOTHS",
              "CERTAINTY BELTED SHIELDS": "CERTAINTY BELTED SHIELDS",
              "CERTAINTY BOXERS 28\"\"-40\"\"": "CERTAINTY BOXERS 28\"\"-40\"\"",
              "CERTAINTY BOXERS 38\"\"-64\"\"": "CERTAINTY BOXERS 38\"\"-64\"\"",
              "CERTAINTY CLEANSING WIPES": "CERTAINTY CLEANSING WIPES",
              "CERTAINTY FITTED BRIEFS LARGE": "CERTAINTY FITTED BRIEFS LARGE",
              "CERTAINTY FITTED BRIEFS MEDIUM": "CERTAINTY FITTED BRIEFS MEDIUM",
              "CERTAINTY FITTED BRIEFS XL": "CERTAINTY FITTED BRIEFS XL",
              "CERTAINTY GUARDS FOR MEN": "CERTAINTY GUARDS FOR MEN",
              "CERTAINTY LINERS/WOMEN": "CERTAINTY LINERS/WOMEN",
              "CERTAINTY MAXIMUM": "CERTAINTY MAXIMUM",
              "CERTAINTY OVERNIGHT UNDERWEAR": "CERTAINTY OVERNIGHT UNDERWEAR",
              "CERTAINTY PADS/WOMEN": "CERTAINTY PADS/WOMEN",
              "CERTAINTY PURSEREADY UNDERWEAR": "CERTAINTY PURSEREADY UNDERWEAR",
              "CERTAINTY ULTIMATE": "CERTAINTY ULTIMATE",
              "CERTAINTY ULTRA THIN UNDERWEAR": "CERTAINTY ULTRA THIN UNDERWEAR",
              "CERTAINTY UNDERPADS 17\"\"X24\"\"": "CERTAINTY UNDERPADS 17\"\"X24\"\"",
              "CERTAINTY UNDERPADS 23\"\"X36\"\"": "CERTAINTY UNDERPADS 23\"\"X36\"\"",
              "CERTAINTY UNDERPADS 30\"\"X36\"\"": "CERTAINTY UNDERPADS 30\"\"X36\"\"",
              "CERTAINTY UNDERWEAR 20\"\"-34\"\"": "CERTAINTY UNDERWEAR 20\"\"-34\"\"",
              "CERTAINTY UNDERWEAR 28\"\"-40\"\"": "CERTAINTY UNDERWEAR 28\"\"-40\"\"",
              "CERTAINTY UNDERWEAR 32\"\"-44\"\"": "CERTAINTY UNDERWEAR 32\"\"-44\"\"",
              "CERTAINTY UNDERWEAR 34\"\"-46\"\"": "CERTAINTY UNDERWEAR 34\"\"-46\"\"",
              "CERTAINTY UNDERWEAR 38\"\"-50\"\"": "CERTAINTY UNDERWEAR 38\"\"-50\"\"",
              "CERTAINTY UNDERWEAR 38\"\"-64\"\"": "CERTAINTY UNDERWEAR 38\"\"-64\"\"",
              "CERTAINTY UNDERWEAR 44\"\"-58\"\"": "CERTAINTY UNDERWEAR 44\"\"-58\"\"",
              "CERTAINTY UNDERWEAR 48\"\"-64\"\"": "CERTAINTY UNDERWEAR 48\"\"-64\"\"",
              "CERTAINTY UNDERWEAR 58\"\"-63\"\"": "CERTAINTY UNDERWEAR 58\"\"-63\"\"",
              "CERTAINTY UNDERWEAR 58\"\"-68\"\"": "CERTAINTY UNDERWEAR 58\"\"-68\"\"",
              "CERTAINTY UNDERWEAR 68\"\"-80\"\"": "CERTAINTY UNDERWEAR 68\"\"-80\"\"",
              "CLEANSING CLOTHS FLUSHABLE": "CLEANSING CLOTHS FLUSHABLE",
              "DISPOSABLE BRIEF LARGE": "DISPOSABLE BRIEF LARGE",
              "DISPOSABLE BRIEF MEDIUM": "DISPOSABLE BRIEF MEDIUM",
              "DISPOSABLE BRIEF SMALL": "DISPOSABLE BRIEF SMALL",
              "DISPOSABLE BRIEF X-LARGE": "DISPOSABLE BRIEF X-LARGE",
              "DISPOSABLE UNDERPADS 30\"\"X36\"\"": "DISPOSABLE UNDERPADS 30\"\"X36\"\"",
              "FEMININE WIPES": "FEMININE WIPES",
              "FITTED BRIEFS MAXIMUM LARGE": "FITTED BRIEFS MAXIMUM LARGE",
              "FITTED BRIEFS MAXIMUM MEDIUM": "FITTED BRIEFS MAXIMUM MEDIUM",
              "FITTED BRIEFS MAXIMUM XL": "FITTED BRIEFS MAXIMUM XL",
              "FITTED BRIEFS MEDIUM": "FITTED BRIEFS MEDIUM",
              "FITTED BRIEFS SMALL": "FITTED BRIEFS SMALL",
              "FITTED BRIEFS/ALOE LARGE": "FITTED BRIEFS/ALOE LARGE",
              "GUARDS": "GUARDS",
              "LAND BEFORE TIME BRIEF JUMBO": "LAND BEFORE TIME BRIEF JUMBO",
              "MALE URINAL/ODOR SHIELD": "MALE URINAL/ODOR SHIELD",
              "MOIST WIPES FLUSHABLE": "MOIST WIPES FLUSHABLE",
              "PROTECT UNDERWEAR SUPER SM/MED": "PROTECT UNDERWEAR SUPER SM/MED",
              "PROTECT UNDERWEAR XTRA LARGE": "PROTECT UNDERWEAR XTRA LARGE",
              "PROTECT UNDERWEAR XTRA SM/MED": "PROTECT UNDERWEAR XTRA SM/MED",
              "PROTECTIVE UNDERWEAR LARGE": "PROTECTIVE UNDERWEAR LARGE",
              "PROTECTIVE UNDERWEAR MEDIUM": "PROTECTIVE UNDERWEAR MEDIUM",
              "PROTECTIVE UNDERWEAR SM/MED": "PROTECTIVE UNDERWEAR SM/MED",
              "PROTECTIVE UNDERWEAR SMALL": "PROTECTIVE UNDERWEAR SMALL",
              "PROTECTIVE UNDERWEAR SUPER LG": "PROTECTIVE UNDERWEAR SUPER LG",
              "PROTECTIVE UNDERWEAR SUPER PLS": "PROTECTIVE UNDERWEAR SUPER PLS",
              "PROTECTIVE UNDERWEAR SUPER XL": "PROTECTIVE UNDERWEAR SUPER XL",
              "PROTECTIVE UNDERWEAR XL": "PROTECTIVE UNDERWEAR XL",
              "SLIP-ON UNDERGARMENTS": "SLIP-ON UNDERGARMENTS",
              "UNDERGARMENT": "UNDERGARMENT",
              "UNDERPADS EXTRA LARGE": "UNDERPADS EXTRA LARGE",
              "UNDERPADS REGULAR": "UNDERPADS REGULAR"
            }
          },
          "Irrigation-Type Syringes": {
            "BD DIABETES CARE": {
              "ASEPTO BULB SYR 1-1/2OZ CATH": "ASEPTO BULB SYR 1-1/2OZ CATH",
              "ASEPTO BULB SYR 1/2OZ": "ASEPTO BULB SYR 1/2OZ",
              "ASEPTO BULB SYR 1OZ CATH TIP": "ASEPTO BULB SYR 1OZ CATH TIP",
              "ASEPTO BULB SYR 2OZ CATH TIP": "ASEPTO BULB SYR 2OZ CATH TIP",
              "ASEPTO BULB SYR 3OZ CATH TIP": "ASEPTO BULB SYR 3OZ CATH TIP",
              "ASEPTO BULB SYR 4OZ CATH TIP": "ASEPTO BULB SYR 4OZ CATH TIP"
            },
            "BD MEDICAL SURGICAL SYSTEMS": {
              "BD CATHETER TIP SYRINGE": "BD CATHETER TIP SYRINGE",
              "REUSABLE GLASS CATH SYRINGE": "REUSABLE GLASS CATH SYRINGE"
            },
            "COVIDIEN MEDICAL SUPPLIES": {
              "DOVER BULB SYRINGE": "DOVER BULB SYRINGE"
            }
          },
          "Ostomy Supplies": {
            "11 HEALTH & TECHNOLOGIES": {
              "ALFRED ALERT": "ALFRED ALERT",
              "ALFRED POST-OPERATIVE KIT": "ALFRED POST-OPERATIVE KIT",
              "ALFRED SMART 30 DAY": "ALFRED SMART 30 DAY",
              "ALFRED SMARTBAG": "ALFRED SMARTBAG",
              "ALFRED SMARTHUB": "ALFRED SMARTHUB",
              "ALFRED SMARTWAFER/BASEPLATE": "ALFRED SMARTWAFER/BASEPLATE"
            },
            "3M CONSUMER HEALTH CARE": {
              "NEXCARE STOMASEAL 4\"\"X4\"\"": "NEXCARE STOMASEAL 4\"\"X4\"\""
            },
            "3M MEDICAL": {
              "STOMASEAL DRESSING 4\"\"X4\"\"": "STOMASEAL DRESSING 4\"\"X4\"\""
            },
            "BARD": {
              "BARD ADHESIVE/FILM REMOVER": "BARD ADHESIVE/FILM REMOVER",
              "CLOSED-END COLOSTOMY POUCH": "CLOSED-END COLOSTOMY POUCH",
              "FISTULA POUCH": "FISTULA POUCH",
              "ILEO B DRAINABLE POUCH": "ILEO B DRAINABLE POUCH",
              "IRRIGATION SLEEVES": "IRRIGATION SLEEVES",
              "OPEN END DRAIN": "OPEN END DRAIN",
              "RELIASEAL SKIN BARRIER DISC": "RELIASEAL SKIN BARRIER DISC",
              "SECURITY POUCH": "SECURITY POUCH",
              "STOMA URINE BAG": "STOMA URINE BAG"
            },
            "COLOPLAST": {
              "ADHESIVE REMOVER": "ADHESIVE REMOVER",
              "ADHESIVE REMOVER WIPES": "ADHESIVE REMOVER WIPES",
              "ALPINE FRESH CLEANER": "ALPINE FRESH CLEANER",
              "ASSURA AC CLOSED": "ASSURA AC CLOSED",
              "ASSURA AC CONVEX LT BASEPLATE": "ASSURA AC CONVEX LT BASEPLATE",
              "ASSURA AC EASICLOSE DRAINABLE": "ASSURA AC EASICLOSE DRAINABLE",
              "ASSURA AC NON-CONVEX BASEPLATE": "ASSURA AC NON-CONVEX BASEPLATE",
              "ASSURA AC PEDIATRIC DRAINABLE": "ASSURA AC PEDIATRIC DRAINABLE",
              "ASSURA AC SKIN BARRIER": "ASSURA AC SKIN BARRIER",
              "ASSURA AC UROSTOMY": "ASSURA AC UROSTOMY",
              "ASSURA CLOSED": "ASSURA CLOSED",
              "ASSURA CONVEX STANDARD 1-1/4\"\"": "ASSURA CONVEX STANDARD 1-1/4\"\"",
              "ASSURA DRAINABLE 2PC": "ASSURA DRAINABLE 2PC",
              "ASSURA DRAINABLE PEDIATRIC": "ASSURA DRAINABLE PEDIATRIC",
              "ASSURA DRAINABLE POUCH 2PC": "ASSURA DRAINABLE POUCH 2PC",
              "ASSURA DRAINABLE SMALL": "ASSURA DRAINABLE SMALL",
              "ASSURA DRAINABLE STANDARD": "ASSURA DRAINABLE STANDARD",
              "ASSURA EASICLOSE DRAINABLE": "ASSURA EASICLOSE DRAINABLE",
              "ASSURA EXTRA DRAINABLE": "ASSURA EXTRA DRAINABLE",
              "ASSURA HIGH OUTPUT": "ASSURA HIGH OUTPUT",
              "ASSURA ILEO NIGHT": "ASSURA ILEO NIGHT",
              "ASSURA IRRIG FCE PLT 1/2-2 1/4": "ASSURA IRRIG FCE PLT 1/2-2 1/4",
              "ASSURA NON-CONVEX POUCH 23CM": "ASSURA NON-CONVEX POUCH 23CM",
              "ASSURA OSTOMY BELT": "ASSURA OSTOMY BELT",
              "ASSURA PEDIATRIC CLOSED": "ASSURA PEDIATRIC CLOSED",
              "ASSURA PEDIATRIC DRAINABLE": "ASSURA PEDIATRIC DRAINABLE",
              "ASSURA PEDIATRIC UROSTOMY": "ASSURA PEDIATRIC UROSTOMY",
              "ASSURA SKIN BARRIER EXTENDED": "ASSURA SKIN BARRIER EXTENDED",
              "ASSURA SKIN BARRIER PEDIATRIC": "ASSURA SKIN BARRIER PEDIATRIC",
              "ASSURA SKIN BARRIER STANDARD": "ASSURA SKIN BARRIER STANDARD",
              "ASSURA SKIN BARRIER/FLANGE": "ASSURA SKIN BARRIER/FLANGE",
              "ASSURA STOMA CAP": "ASSURA STOMA CAP",
              "ASSURA STOMA CONE": "ASSURA STOMA CONE",
              "ASSURA URO MINICAP": "ASSURA URO MINICAP",
              "ASSURA UROSTOMY": "ASSURA UROSTOMY",
              "ASSURA UROSTOMY SMALL": "ASSURA UROSTOMY SMALL",
              "ASSURA UROSTOMY STANDARD": "ASSURA UROSTOMY STANDARD",
              "ASSURA WOUND MANAGER 1/2-2 3/4": "ASSURA WOUND MANAGER 1/2-2 3/4",
              "ASSURA WOUND MANAGER 1/2-4\"\"": "ASSURA WOUND MANAGER 1/2-4\"\"",
              "BRAVA ADHESIVE REMOVER WIPE": "BRAVA ADHESIVE REMOVER WIPE",
              "BRAVA MOLDABLE RING": "BRAVA MOLDABLE RING",
              "COLOPLAST": "COLOPLAST",
              "COLOPLAST BARRIER RING": "COLOPLAST BARRIER RING",
              "COLOPLAST BELT RETAINER RING": "COLOPLAST BELT RETAINER RING",
              "COLOPLAST CLOSED": "COLOPLAST CLOSED",
              "COLOPLAST DRAINABLE": "COLOPLAST DRAINABLE",
              "COLOPLAST DRAINABLE SMALL": "COLOPLAST DRAINABLE SMALL",
              "COLOPLAST DRAINABLE STANDARD": "COLOPLAST DRAINABLE STANDARD",
              "COLOPLAST IRRIGATION FACEPLATE": "COLOPLAST IRRIGATION FACEPLATE",
              "COLOPLAST IRRIGATION SET": "COLOPLAST IRRIGATION SET",
              "COLOPLAST IRRIGATION SLEEVES": "COLOPLAST IRRIGATION SLEEVES",
              "COLOPLAST OSTOMY BELT": "COLOPLAST OSTOMY BELT",
              "COLOPLAST PASTE": "COLOPLAST PASTE",
              "COLOPLAST POST OP": "COLOPLAST POST OP",
              "COLOPLAST POST OP SET": "COLOPLAST POST OP SET",
              "COLOPLAST POST OP SKIN BARRIER": "COLOPLAST POST OP SKIN BARRIER",
              "COLOPLAST POUCH CLAMP": "COLOPLAST POUCH CLAMP",
              "COLOPLAST SKIN BARRIER": "COLOPLAST SKIN BARRIER",
              "COLOPLAST STOMA CAP": "COLOPLAST STOMA CAP",
              "COLOPLAST UROSTOMY SMALL": "COLOPLAST UROSTOMY SMALL",
              "COLOPLAST UROSTOMY STANDARD": "COLOPLAST UROSTOMY STANDARD",
              "DERMA-GUARD": "DERMA-GUARD",
              "E-Z HOLD CATH TUBE HOLDER PAD": "E-Z HOLD CATH TUBE HOLDER PAD",
              "E-Z HOLD CATHETER TUBE HOLDER": "E-Z HOLD CATHETER TUBE HOLDER",
              "FILTRODOR": "FILTRODOR",
              "FISTULA BED DRAINAGE BAG": "FISTULA BED DRAINAGE BAG",
              "FISTULA DRAIN PORT": "FISTULA DRAIN PORT",
              "FISTULA WOUND MANAGEMENT": "FISTULA WOUND MANAGEMENT",
              "FISTULA WOUND MANAGEMENT SYS": "FISTULA WOUND MANAGEMENT SYS",
              "HEX-ON LIGHT ODOR": "HEX-ON LIGHT ODOR",
              "MOVEEN NIGHT BAG": "MOVEEN NIGHT BAG",
              "O.A.D.": "O.A.D.",
              "OSTOMY": "OSTOMY",
              "SENSURA CLICK XPRO BASE PLATE": "SENSURA CLICK XPRO BASE PLATE",
              "SENSURA CLOSED": "SENSURA CLOSED",
              "SENSURA DRAINABLE": "SENSURA DRAINABLE",
              "SENSURA DRAINABLE BAG/SOFT TAP": "SENSURA DRAINABLE BAG/SOFT TAP",
              "SENSURA FLEX OSTOMY": "SENSURA FLEX OSTOMY",
              "SENSURA STANDARD WEAR": "SENSURA STANDARD WEAR",
              "SENSURA XPRO MAXI DRAINABLE": "SENSURA XPRO MAXI DRAINABLE",
              "SENSURA XPRO OSTOMY BASE PLATE": "SENSURA XPRO OSTOMY BASE PLATE",
              "UROSTOMY NIGHT BAG": "UROSTOMY NIGHT BAG"
            },
            "CONVATEC": {
              "ACTIVE LIFE 1-PC CLOSED-END": "ACTIVE LIFE 1-PC CLOSED-END",
              "ACTIVE LIFE 1-PC DRAIN 19-64MM": "ACTIVE LIFE 1-PC DRAIN 19-64MM",
              "ACTIVE LIFE 1-PC DRAIN 19MM": "ACTIVE LIFE 1-PC DRAIN 19MM",
              "ACTIVE LIFE 1-PC DRAIN 25MM": "ACTIVE LIFE 1-PC DRAIN 25MM",
              "ACTIVE LIFE 1-PC DRAIN 32MM": "ACTIVE LIFE 1-PC DRAIN 32MM",
              "ACTIVE LIFE 1-PC DRAIN 38MM": "ACTIVE LIFE 1-PC DRAIN 38MM",
              "ACTIVE LIFE 1-PC DRAIN 45MM": "ACTIVE LIFE 1-PC DRAIN 45MM",
              "ACTIVE LIFE 1-PC DRAIN 50MM": "ACTIVE LIFE 1-PC DRAIN 50MM",
              "ACTIVE LIFE 1-PC DRAIN 64MM": "ACTIVE LIFE 1-PC DRAIN 64MM",
              "ACTIVE LIFE 1-PC STOMA CAP": "ACTIVE LIFE 1-PC STOMA CAP",
              "ACTIVE LIFE CLOSED-END 19-50MM": "ACTIVE LIFE CLOSED-END 19-50MM",
              "ACTIVE LIFE CLOSED-END 19-64MM": "ACTIVE LIFE CLOSED-END 19-64MM",
              "ACTIVE LIFE CLOSED-END 19MM": "ACTIVE LIFE CLOSED-END 19MM",
              "ACTIVE LIFE CLOSED-END 25MM": "ACTIVE LIFE CLOSED-END 25MM",
              "ACTIVE LIFE CLOSED-END 32MM": "ACTIVE LIFE CLOSED-END 32MM",
              "ACTIVE LIFE CLOSED-END 38MM": "ACTIVE LIFE CLOSED-END 38MM",
              "ACTIVE LIFE CLOSED-END 45MM": "ACTIVE LIFE CLOSED-END 45MM",
              "ACTIVE LIFE CLOSED-END 50MM": "ACTIVE LIFE CLOSED-END 50MM",
              "ACTIVE LIFE CONVEX 1-PC 13MM": "ACTIVE LIFE CONVEX 1-PC 13MM",
              "ACTIVE LIFE CONVEX 1-PC 16MM": "ACTIVE LIFE CONVEX 1-PC 16MM",
              "ACTIVE LIFE CONVEX 1-PC 19MM": "ACTIVE LIFE CONVEX 1-PC 19MM",
              "ACTIVE LIFE CONVEX 1-PC 22MM": "ACTIVE LIFE CONVEX 1-PC 22MM",
              "ACTIVE LIFE CONVEX 1-PC 25MM": "ACTIVE LIFE CONVEX 1-PC 25MM",
              "ACTIVE LIFE CONVEX 1-PC 28MM": "ACTIVE LIFE CONVEX 1-PC 28MM",
              "ACTIVE LIFE CONVEX 1-PC 32MM": "ACTIVE LIFE CONVEX 1-PC 32MM",
              "ACTIVE LIFE CONVEX 1-PC 35MM": "ACTIVE LIFE CONVEX 1-PC 35MM",
              "ACTIVE LIFE CONVEX 1-PC 38MM": "ACTIVE LIFE CONVEX 1-PC 38MM",
              "ACTIVE LIFE CONVEX 1-PC 45MM": "ACTIVE LIFE CONVEX 1-PC 45MM",
              "ACTIVE LIFE CONVEX 1-PC 50MM": "ACTIVE LIFE CONVEX 1-PC 50MM",
              "ACTIVE LIFE UROSTOMY/BARRIER": "ACTIVE LIFE UROSTOMY/BARRIER",
              "ALLKARE ADHESIVE REMOVER WIPES": "ALLKARE ADHESIVE REMOVER WIPES",
              "ALLKARE PROTECT BARRIER WIPES": "ALLKARE PROTECT BARRIER WIPES",
              "DUOLOCK CURVED TAIL CLOSURE": "DUOLOCK CURVED TAIL CLOSURE",
              "EAKIN COHESIVE SEALS": "EAKIN COHESIVE SEALS",
              "EAKIN COHESIVE SKIN BARRIER": "EAKIN COHESIVE SKIN BARRIER",
              "EAKIN COHESIVE SLIMS": "EAKIN COHESIVE SLIMS",
              "EAKIN COHESIVE STOMAWRAP SEAL": "EAKIN COHESIVE STOMAWRAP SEAL",
              "EAKIN FISTULA-WOUND": "EAKIN FISTULA-WOUND",
              "ESTEEM": "ESTEEM",
              "ESTEEM SYNERGY CLOSED-END": "ESTEEM SYNERGY CLOSED-END",
              "ESTEEM SYNERGY CLOSED/STANDARD": "ESTEEM SYNERGY CLOSED/STANDARD",
              "ESTEEM SYNERGY DRAINABLE": "ESTEEM SYNERGY DRAINABLE",
              "ESTEEM SYNERGY DURAHESIVE": "ESTEEM SYNERGY DURAHESIVE",
              "ESTEEM SYNERGY STOMAHESIVE": "ESTEEM SYNERGY STOMAHESIVE",
              "ESTEEM SYNERGY UROSTOMY": "ESTEEM SYNERGY UROSTOMY",
              "ESTEEM SYNERGY UROSTOMY/SHORT": "ESTEEM SYNERGY UROSTOMY/SHORT",
              "ESTEEM SYNERGY UROSTOMY/STD": "ESTEEM SYNERGY UROSTOMY/STD",
              "ESTEEM SYNERGY+ CLOSED-END": "ESTEEM SYNERGY+ CLOSED-END",
              "ESTEEM SYNERGY+ CLOSED-END STD": "ESTEEM SYNERGY+ CLOSED-END STD",
              "ESTEEM SYNERGY+ DRAINABLE": "ESTEEM SYNERGY+ DRAINABLE",
              "ESTEEM+ CLOSED-END/STOMAHESIVE": "ESTEEM+ CLOSED-END/STOMAHESIVE",
              "ESTEEM+ DRAINABLE": "ESTEEM+ DRAINABLE",
              "FLEXI-TRAK ANCHORING DEVICE": "FLEXI-TRAK ANCHORING DEVICE",
              "LITTLE ONES CLOSED END": "LITTLE ONES CLOSED END",
              "LITTLE ONES ONE-PIECE DRAIN": "LITTLE ONES ONE-PIECE DRAIN",
              "LITTLE ONES SKIN BARRIER": "LITTLE ONES SKIN BARRIER",
              "LITTLE ONES TWO-PIECE CLOSED": "LITTLE ONES TWO-PIECE CLOSED",
              "LITTLE ONES TWO-PIECE DRAIN": "LITTLE ONES TWO-PIECE DRAIN",
              "LITTLE ONES TWO-PIECE UROSTOMY": "LITTLE ONES TWO-PIECE UROSTOMY",
              "LITTLE ONES UROSTOMY": "LITTLE ONES UROSTOMY",
              "NATURA DRAINABLE/HIGH OUTPUT": "NATURA DRAINABLE/HIGH OUTPUT",
              "NATURA DURAHESIVE MOLDABLE": "NATURA DURAHESIVE MOLDABLE",
              "NATURA STOMAHESIVE MOLDABLE": "NATURA STOMAHESIVE MOLDABLE",
              "NATURA+ CLOSED END/STANDARD": "NATURA+ CLOSED END/STANDARD",
              "NATURA+ CLOSED-END": "NATURA+ CLOSED-END",
              "NATURA+ DRAINABLE": "NATURA+ DRAINABLE",
              "NATURA+ DRAINABLE/FILTER": "NATURA+ DRAINABLE/FILTER",
              "NIGHT DRAINAGE SYSTEM": "NIGHT DRAINAGE SYSTEM",
              "NIGHT DRAINAGE TUBING": "NIGHT DRAINAGE TUBING",
              "OSTOMY APPLIANCE BELT": "OSTOMY APPLIANCE BELT",
              "STOMAHESIVE MOLDABLE ADHESIVE": "STOMAHESIVE MOLDABLE ADHESIVE",
              "STOMAHESIVE PASTE": "STOMAHESIVE PASTE",
              "STOMAHESIVE PROTECTIVE": "STOMAHESIVE PROTECTIVE",
              "STOMAHESIVE SKIN BARRIER": "STOMAHESIVE SKIN BARRIER",
              "STOMAHESIVE STERILE": "STOMAHESIVE STERILE",
              "SUR-FIT NATURA CLOSED-END": "SUR-FIT NATURA CLOSED-END",
              "SUR-FIT NATURA CLOSED-END MINI": "SUR-FIT NATURA CLOSED-END MINI",
              "SUR-FIT NATURA CONVEX INSERTS": "SUR-FIT NATURA CONVEX INSERTS",
              "SUR-FIT NATURA DRAINABLE": "SUR-FIT NATURA DRAINABLE",
              "SUR-FIT NATURA DRAINABLE 32MM": "SUR-FIT NATURA DRAINABLE 32MM",
              "SUR-FIT NATURA DRAINABLE 38MM": "SUR-FIT NATURA DRAINABLE 38MM",
              "SUR-FIT NATURA DRAINABLE 45MM": "SUR-FIT NATURA DRAINABLE 45MM",
              "SUR-FIT NATURA DRAINABLE 57MM": "SUR-FIT NATURA DRAINABLE 57MM",
              "SUR-FIT NATURA DRAINABLE 70MM": "SUR-FIT NATURA DRAINABLE 70MM",
              "SUR-FIT NATURA DURAHESIVE": "SUR-FIT NATURA DURAHESIVE",
              "SUR-FIT NATURA FLANGE CAP": "SUR-FIT NATURA FLANGE CAP",
              "SUR-FIT NATURA HIGH OUTPUT": "SUR-FIT NATURA HIGH OUTPUT",
              "SUR-FIT NATURA LITTLE ONES": "SUR-FIT NATURA LITTLE ONES",
              "SUR-FIT NATURA LOW-PRESSURE": "SUR-FIT NATURA LOW-PRESSURE",
              "SUR-FIT NATURA STOMAHESIVE": "SUR-FIT NATURA STOMAHESIVE",
              "SUR-FIT NATURA UROSTOMY": "SUR-FIT NATURA UROSTOMY",
              "SUR-FIT NATURA WAFER/FLANGE": "SUR-FIT NATURA WAFER/FLANGE",
              "SUR-FIT UROSTOMY POUCH": "SUR-FIT UROSTOMY POUCH",
              "TAIL CLOSURES": "TAIL CLOSURES",
              "VISI-FLOW IRRIGATION CLOSURE": "VISI-FLOW IRRIGATION CLOSURE",
              "VISI-FLOW IRRIGATION FACEPLATE": "VISI-FLOW IRRIGATION FACEPLATE",
              "VISI-FLOW IRRIGATION STARTER": "VISI-FLOW IRRIGATION STARTER",
              "VISI-FLOW IRRIGATOR/STOMA CONE": "VISI-FLOW IRRIGATOR/STOMA CONE",
              "VISI-FLOW STOMA CONE": "VISI-FLOW STOMA CONE"
            },
            "COVIDIEN MEDICAL SUPPLIES": {
              "KANGAROO BALLOON 12FR/0.8CM": "KANGAROO BALLOON 12FR/0.8CM",
              "KANGAROO BALLOON 12FR/1.2CM": "KANGAROO BALLOON 12FR/1.2CM",
              "KANGAROO BALLOON 12FR/1.5CM": "KANGAROO BALLOON 12FR/1.5CM",
              "KANGAROO BALLOON 12FR/1.7CM": "KANGAROO BALLOON 12FR/1.7CM",
              "KANGAROO BALLOON 12FR/1CM": "KANGAROO BALLOON 12FR/1CM",
              "KANGAROO BALLOON 12FR/2.3CM": "KANGAROO BALLOON 12FR/2.3CM",
              "KANGAROO BALLOON 12FR/2.5CM": "KANGAROO BALLOON 12FR/2.5CM",
              "KANGAROO BALLOON 12FR/2.7CM": "KANGAROO BALLOON 12FR/2.7CM",
              "KANGAROO BALLOON 12FR/2CM": "KANGAROO BALLOON 12FR/2CM",
              "KANGAROO BALLOON 12FR/3.5CM": "KANGAROO BALLOON 12FR/3.5CM",
              "KANGAROO BALLOON 12FR/3CM": "KANGAROO BALLOON 12FR/3CM",
              "KANGAROO BALLOON 12FR/4.5CM": "KANGAROO BALLOON 12FR/4.5CM",
              "KANGAROO BALLOON 12FR/4CM": "KANGAROO BALLOON 12FR/4CM",
              "KANGAROO BALLOON 12FR/5CM": "KANGAROO BALLOON 12FR/5CM",
              "KANGAROO BALLOON 14FR/0.8CM": "KANGAROO BALLOON 14FR/0.8CM",
              "KANGAROO BALLOON 14FR/1.2CM": "KANGAROO BALLOON 14FR/1.2CM",
              "KANGAROO BALLOON 14FR/1.5CM": "KANGAROO BALLOON 14FR/1.5CM",
              "KANGAROO BALLOON 14FR/1.7CM": "KANGAROO BALLOON 14FR/1.7CM",
              "KANGAROO BALLOON 14FR/1CM": "KANGAROO BALLOON 14FR/1CM",
              "KANGAROO BALLOON 14FR/2.3CM": "KANGAROO BALLOON 14FR/2.3CM",
              "KANGAROO BALLOON 14FR/2.5CM": "KANGAROO BALLOON 14FR/2.5CM",
              "KANGAROO BALLOON 14FR/2.7CM": "KANGAROO BALLOON 14FR/2.7CM",
              "KANGAROO BALLOON 14FR/2CM": "KANGAROO BALLOON 14FR/2CM",
              "KANGAROO BALLOON 14FR/3.5CM": "KANGAROO BALLOON 14FR/3.5CM",
              "KANGAROO BALLOON 14FR/3CM": "KANGAROO BALLOON 14FR/3CM",
              "KANGAROO BALLOON 14FR/4.5CM": "KANGAROO BALLOON 14FR/4.5CM",
              "KANGAROO BALLOON 14FR/4CM": "KANGAROO BALLOON 14FR/4CM",
              "KANGAROO BALLOON 14FR/5CM": "KANGAROO BALLOON 14FR/5CM",
              "KANGAROO BALLOON 16FR/0.8CM": "KANGAROO BALLOON 16FR/0.8CM",
              "KANGAROO BALLOON 16FR/1.2CM": "KANGAROO BALLOON 16FR/1.2CM",
              "KANGAROO BALLOON 16FR/1.5CM": "KANGAROO BALLOON 16FR/1.5CM",
              "KANGAROO BALLOON 16FR/1.7CM": "KANGAROO BALLOON 16FR/1.7CM",
              "KANGAROO BALLOON 16FR/1CM": "KANGAROO BALLOON 16FR/1CM",
              "KANGAROO BALLOON 16FR/2.3CM": "KANGAROO BALLOON 16FR/2.3CM",
              "KANGAROO BALLOON 16FR/2.5CM": "KANGAROO BALLOON 16FR/2.5CM",
              "KANGAROO BALLOON 16FR/2.7CM": "KANGAROO BALLOON 16FR/2.7CM",
              "KANGAROO BALLOON 16FR/2CM": "KANGAROO BALLOON 16FR/2CM",
              "KANGAROO BALLOON 16FR/3.5CM": "KANGAROO BALLOON 16FR/3.5CM",
              "KANGAROO BALLOON 16FR/3CM": "KANGAROO BALLOON 16FR/3CM",
              "KANGAROO BALLOON 16FR/4.5CM": "KANGAROO BALLOON 16FR/4.5CM",
              "KANGAROO BALLOON 16FR/4CM": "KANGAROO BALLOON 16FR/4CM",
              "KANGAROO BALLOON 16FR/5CM": "KANGAROO BALLOON 16FR/5CM",
              "KANGAROO BALLOON 18FR/0.8CM": "KANGAROO BALLOON 18FR/0.8CM",
              "KANGAROO BALLOON 18FR/1.2CM": "KANGAROO BALLOON 18FR/1.2CM",
              "KANGAROO BALLOON 18FR/1.5CM": "KANGAROO BALLOON 18FR/1.5CM",
              "KANGAROO BALLOON 18FR/1.7CM": "KANGAROO BALLOON 18FR/1.7CM",
              "KANGAROO BALLOON 18FR/1CM": "KANGAROO BALLOON 18FR/1CM",
              "KANGAROO BALLOON 18FR/2.3CM": "KANGAROO BALLOON 18FR/2.3CM",
              "KANGAROO BALLOON 18FR/2.5CM": "KANGAROO BALLOON 18FR/2.5CM",
              "KANGAROO BALLOON 18FR/2.7CM": "KANGAROO BALLOON 18FR/2.7CM",
              "KANGAROO BALLOON 18FR/2CM": "KANGAROO BALLOON 18FR/2CM",
              "KANGAROO BALLOON 18FR/3.5CM": "KANGAROO BALLOON 18FR/3.5CM",
              "KANGAROO BALLOON 18FR/3CM": "KANGAROO BALLOON 18FR/3CM",
              "KANGAROO BALLOON 18FR/4.5CM": "KANGAROO BALLOON 18FR/4.5CM",
              "KANGAROO BALLOON 18FR/4CM": "KANGAROO BALLOON 18FR/4CM",
              "KANGAROO BALLOON 18FR/5CM": "KANGAROO BALLOON 18FR/5CM",
              "KANGAROO BALLOON 20FR/0.8CM": "KANGAROO BALLOON 20FR/0.8CM",
              "KANGAROO BALLOON 20FR/1.2CM": "KANGAROO BALLOON 20FR/1.2CM",
              "KANGAROO BALLOON 20FR/1.5CM": "KANGAROO BALLOON 20FR/1.5CM",
              "KANGAROO BALLOON 20FR/1.7CM": "KANGAROO BALLOON 20FR/1.7CM",
              "KANGAROO BALLOON 20FR/1CM": "KANGAROO BALLOON 20FR/1CM",
              "KANGAROO BALLOON 20FR/2.3CM": "KANGAROO BALLOON 20FR/2.3CM",
              "KANGAROO BALLOON 20FR/2CM": "KANGAROO BALLOON 20FR/2CM",
              "NUTRIPORT BALLOON 16FR/2.5CM": "NUTRIPORT BALLOON 16FR/2.5CM",
              "NUTRIPORT BALLOON 20FR/2.5CM": "NUTRIPORT BALLOON 20FR/2.5CM",
              "NUTRIPORT BALLOON 20FR/2.7CM": "NUTRIPORT BALLOON 20FR/2.7CM",
              "NUTRIPORT BALLOON 20FR/3.5CM": "NUTRIPORT BALLOON 20FR/3.5CM",
              "NUTRIPORT BALLOON 20FR/3CM": "NUTRIPORT BALLOON 20FR/3CM",
              "NUTRIPORT BALLOON 20FR/4.5CM": "NUTRIPORT BALLOON 20FR/4.5CM",
              "NUTRIPORT BALLOON 20FR/4CM": "NUTRIPORT BALLOON 20FR/4CM",
              "NUTRIPORT BALLOON 20FR/5CM": "NUTRIPORT BALLOON 20FR/5CM",
              "NUTRIPORT BALLOON 24FR/0.8CM": "NUTRIPORT BALLOON 24FR/0.8CM",
              "NUTRIPORT BALLOON 24FR/1.2CM": "NUTRIPORT BALLOON 24FR/1.2CM",
              "NUTRIPORT BALLOON 24FR/1.5CM": "NUTRIPORT BALLOON 24FR/1.5CM",
              "NUTRIPORT BALLOON 24FR/1.7CM": "NUTRIPORT BALLOON 24FR/1.7CM",
              "NUTRIPORT BALLOON 24FR/1CM": "NUTRIPORT BALLOON 24FR/1CM",
              "NUTRIPORT BALLOON 24FR/2.3CM": "NUTRIPORT BALLOON 24FR/2.3CM",
              "NUTRIPORT BALLOON 24FR/2.5CM": "NUTRIPORT BALLOON 24FR/2.5CM",
              "NUTRIPORT BALLOON 24FR/2.7CM": "NUTRIPORT BALLOON 24FR/2.7CM",
              "NUTRIPORT BALLOON 24FR/2CM": "NUTRIPORT BALLOON 24FR/2CM",
              "NUTRIPORT BALLOON 24FR/3.5CM": "NUTRIPORT BALLOON 24FR/3.5CM",
              "NUTRIPORT BALLOON 24FR/3CM": "NUTRIPORT BALLOON 24FR/3CM",
              "NUTRIPORT BALLOON 24FR/4.5CM": "NUTRIPORT BALLOON 24FR/4.5CM",
              "NUTRIPORT BALLOON 24FR/4CM": "NUTRIPORT BALLOON 24FR/4CM",
              "NUTRIPORT BALLOON 24FR/5CM": "NUTRIPORT BALLOON 24FR/5CM",
              "WEBCOL ADHESIVE REMOVER": "WEBCOL ADHESIVE REMOVER",
              "WEBCOL SKIN BARRIER WIPE": "WEBCOL SKIN BARRIER WIPE"
            },
            "CYMED OSTOMY CO.": {
              "CLOSED-END POUCH ONE-PC 1\"\"": "CLOSED-END POUCH ONE-PC 1\"\"",
              "CLOSED-END POUCH ONE-PC 1-1/2\"\"": "CLOSED-END POUCH ONE-PC 1-1/2\"\"",
              "CLOSED-END POUCH ONE-PC 1-1/4\"\"": "CLOSED-END POUCH ONE-PC 1-1/4\"\"",
              "CLOSED-END POUCH ONE-PC 1-1/8\"\"": "CLOSED-END POUCH ONE-PC 1-1/8\"\"",
              "CLOSED-END POUCH ONE-PC 1-3/4\"\"": "CLOSED-END POUCH ONE-PC 1-3/4\"\"",
              "CLOSED-END POUCH ONE-PC 1-3/8\"\"": "CLOSED-END POUCH ONE-PC 1-3/8\"\"",
              "CLOSED-END POUCH ONE-PC 7/8\"\"": "CLOSED-END POUCH ONE-PC 7/8\"\"",
              "DRAINABLE POUCH CLAMP": "DRAINABLE POUCH CLAMP",
              "DRAINABLE POUCH CLOSURE": "DRAINABLE POUCH CLOSURE",
              "ILE-SORB": "ILE-SORB",
              "MICRODERM PLUS WASHERS 1\"\"": "MICRODERM PLUS WASHERS 1\"\"",
              "MICRODERM PLUS WASHERS 1-1/2\"\"": "MICRODERM PLUS WASHERS 1-1/2\"\"",
              "MICRODERM PLUS WASHERS 1-1/4\"\"": "MICRODERM PLUS WASHERS 1-1/4\"\"",
              "MICRODERM PLUS WASHERS 1-1/8\"\"": "MICRODERM PLUS WASHERS 1-1/8\"\"",
              "MICRODERM PLUS WASHERS 1-3/8\"\"": "MICRODERM PLUS WASHERS 1-3/8\"\"",
              "MICRODERM PLUS WASHERS 7/8\"\"": "MICRODERM PLUS WASHERS 7/8\"\"",
              "MICRODERM WASHERS 1\"\"": "MICRODERM WASHERS 1\"\"",
              "MICRODERM WASHERS 1-1/2\"\"": "MICRODERM WASHERS 1-1/2\"\"",
              "MICRODERM WASHERS 1-1/4\"\"": "MICRODERM WASHERS 1-1/4\"\"",
              "MICRODERM WASHERS 1-1/8\"\"": "MICRODERM WASHERS 1-1/8\"\"",
              "MICRODERM WASHERS 1-3/8\"\"": "MICRODERM WASHERS 1-3/8\"\"",
              "MICRODERM WASHERS 7/8\"\"": "MICRODERM WASHERS 7/8\"\"",
              "MICROHESIVE STOMA": "MICROHESIVE STOMA",
              "NIGHT DRAIN ADAPTER": "NIGHT DRAIN ADAPTER",
              "ONE-PC MINI DRAIN POUCH 38MM": "ONE-PC MINI DRAIN POUCH 38MM",
              "ONE-PC MINI DRAIN POUCH 45MM": "ONE-PC MINI DRAIN POUCH 45MM",
              "ONE-PIECE DRAINABLE POUCH 22MM": "ONE-PIECE DRAINABLE POUCH 22MM",
              "ONE-PIECE DRAINABLE POUCH 25MM": "ONE-PIECE DRAINABLE POUCH 25MM",
              "ONE-PIECE DRAINABLE POUCH 29MM": "ONE-PIECE DRAINABLE POUCH 29MM",
              "ONE-PIECE DRAINABLE POUCH 32MM": "ONE-PIECE DRAINABLE POUCH 32MM",
              "ONE-PIECE DRAINABLE POUCH 35MM": "ONE-PIECE DRAINABLE POUCH 35MM",
              "ONE-PIECE DRAINABLE POUCH 38MM": "ONE-PIECE DRAINABLE POUCH 38MM",
              "ONE-PIECE DRAINABLE POUCH 45MM": "ONE-PIECE DRAINABLE POUCH 45MM",
              "TWO PIECE CLOSED-END POUCH": "TWO PIECE CLOSED-END POUCH",
              "TWO PIECE DRAINABLE POUCH": "TWO PIECE DRAINABLE POUCH",
              "TWO PIECE POUCH MID-SIZE": "TWO PIECE POUCH MID-SIZE",
              "TWO-PIECE ADHESIVE BARRIERS": "TWO-PIECE ADHESIVE BARRIERS",
              "TWO-PIECE IRRIGATION SLEEVE": "TWO-PIECE IRRIGATION SLEEVE",
              "UROSTOMY POUCH 1\"\"/25MM": "UROSTOMY POUCH 1\"\"/25MM",
              "UROSTOMY POUCH 1-1/2\"\"/45MM": "UROSTOMY POUCH 1-1/2\"\"/45MM",
              "UROSTOMY POUCH 1-1/4\"\"/32MM": "UROSTOMY POUCH 1-1/4\"\"/32MM",
              "UROSTOMY POUCH 1-1/8\"\"/29MM": "UROSTOMY POUCH 1-1/8\"\"/29MM",
              "UROSTOMY POUCH 1-3/4\"\"/45MM": "UROSTOMY POUCH 1-3/4\"\"/45MM",
              "UROSTOMY POUCH 3/4\"\"/19MM": "UROSTOMY POUCH 3/4\"\"/19MM",
              "UROSTOMY POUCH 7/8\"\"/22MM": "UROSTOMY POUCH 7/8\"\"/22MM",
              "UROSTOMY POUCH/INTEGRAL 1\"\"": "UROSTOMY POUCH/INTEGRAL 1\"\"",
              "UROSTOMY POUCH/INTEGRAL 1-1/2\"\"": "UROSTOMY POUCH/INTEGRAL 1-1/2\"\"",
              "UROSTOMY POUCH/INTEGRAL 1-1/4\"\"": "UROSTOMY POUCH/INTEGRAL 1-1/4\"\"",
              "UROSTOMY POUCH/INTEGRAL 1-1/8\"\"": "UROSTOMY POUCH/INTEGRAL 1-1/8\"\"",
              "UROSTOMY POUCH/INTEGRAL 1-3/8\"\"": "UROSTOMY POUCH/INTEGRAL 1-3/8\"\"",
              "UROSTOMY POUCH/INTEGRAL 7/8\"\"": "UROSTOMY POUCH/INTEGRAL 7/8\"\""
            },
            "GENAIREX": {
              "SECURI-T 1-PC CLOSED-END 8\"\"": "SECURI-T 1-PC CLOSED-END 8\"\"",
              "SECURI-T 1-PC DRAINABLE 12\"\"": "SECURI-T 1-PC DRAINABLE 12\"\"",
              "SECURI-T 1-PC DRAINABLE 6\"\"": "SECURI-T 1-PC DRAINABLE 6\"\"",
              "SECURI-T 1-PC STOMA CAP 50MM": "SECURI-T 1-PC STOMA CAP 50MM",
              "SECURI-T 1-PC UROSTOMY/STOMA": "SECURI-T 1-PC UROSTOMY/STOMA",
              "SECURI-T 2-PC CLOSED-END 38MM": "SECURI-T 2-PC CLOSED-END 38MM",
              "SECURI-T 2-PC CLOSED-END 45MM": "SECURI-T 2-PC CLOSED-END 45MM",
              "SECURI-T 2-PC CLOSED-END 57MM": "SECURI-T 2-PC CLOSED-END 57MM",
              "SECURI-T 2-PC CLOSED-END 70MM": "SECURI-T 2-PC CLOSED-END 70MM",
              "SECURI-T 2-PC CONVEXITY": "SECURI-T 2-PC CONVEXITY",
              "SECURI-T 2-PC DRAIN 32MM": "SECURI-T 2-PC DRAIN 32MM",
              "SECURI-T 2-PC DRAIN 38MM": "SECURI-T 2-PC DRAIN 38MM",
              "SECURI-T 2-PC DRAIN 45MM": "SECURI-T 2-PC DRAIN 45MM",
              "SECURI-T 2-PC DRAIN 57MM": "SECURI-T 2-PC DRAIN 57MM",
              "SECURI-T 2-PC DRAIN 70MM": "SECURI-T 2-PC DRAIN 70MM",
              "SECURI-T 2-PC UROSTOMY 38MM": "SECURI-T 2-PC UROSTOMY 38MM",
              "SECURI-T 2-PC UROSTOMY 45MM": "SECURI-T 2-PC UROSTOMY 45MM",
              "SECURI-T 2-PC UROSTOMY 57MM": "SECURI-T 2-PC UROSTOMY 57MM",
              "SECURI-T CONFORMABLE SEALS": "SECURI-T CONFORMABLE SEALS",
              "SECURI-T CONVEX INSERTS 19MM": "SECURI-T CONVEX INSERTS 19MM",
              "SECURI-T CONVEX INSERTS 22MM": "SECURI-T CONVEX INSERTS 22MM",
              "SECURI-T CONVEX INSERTS 25MM": "SECURI-T CONVEX INSERTS 25MM",
              "SECURI-T CONVEX INSERTS 29MM": "SECURI-T CONVEX INSERTS 29MM",
              "SECURI-T CONVEX INSERTS 32MM": "SECURI-T CONVEX INSERTS 32MM",
              "SECURI-T CONVEX INSERTS 35MM": "SECURI-T CONVEX INSERTS 35MM",
              "SECURI-T CONVEX INSERTS 38MM": "SECURI-T CONVEX INSERTS 38MM",
              "SECURI-T CONVEX INSERTS 41MM": "SECURI-T CONVEX INSERTS 41MM",
              "SECURI-T CURVED TAIL CLOSURE": "SECURI-T CURVED TAIL CLOSURE",
              "SECURI-T FLEXIBLE WAFER/FLANGE": "SECURI-T FLEXIBLE WAFER/FLANGE",
              "SECURI-T NIGHT DRAIN ADAPTER": "SECURI-T NIGHT DRAIN ADAPTER",
              "SECURI-T NO STING WIPE": "SECURI-T NO STING WIPE",
              "SECURI-T OSTOMY BELT": "SECURI-T OSTOMY BELT",
              "SECURI-T OSTOMY DEODORANT": "SECURI-T OSTOMY DEODORANT",
              "SECURI-T SECU-RINGS 32MM": "SECURI-T SECU-RINGS 32MM",
              "SECURI-T SECU-RINGS 38MM": "SECURI-T SECU-RINGS 38MM",
              "SECURI-T SECU-RINGS 45MM": "SECURI-T SECU-RINGS 45MM",
              "SECURI-T SECU-RINGS 57MM": "SECURI-T SECU-RINGS 57MM",
              "SECURI-T SECU-RINGS 70MM": "SECURI-T SECU-RINGS 70MM",
              "SECURI-T SKIN BARRIER": "SECURI-T SKIN BARRIER",
              "SECURI-T STOMA": "SECURI-T STOMA",
              "SECURI-T WAFER/FLANGE": "SECURI-T WAFER/FLANGE"
            },
            "HOLLISTER": {
              "ADAPT": "ADAPT",
              "ADAPT BARRIER": "ADAPT BARRIER",
              "ADAPT BARRIER RING 1-3/16\"\"": "ADAPT BARRIER RING 1-3/16\"\"",
              "ADAPT CERARING": "ADAPT CERARING",
              "ADAPT CONVEX BARRIER RING": "ADAPT CONVEX BARRIER RING",
              "ADAPT LUBRICATING DEODORANT": "ADAPT LUBRICATING DEODORANT",
              "ADAPT MEDICAL ADHESIVE": "ADAPT MEDICAL ADHESIVE",
              "ADAPT STOMA": "ADAPT STOMA",
              "CENTERPOINTLOCK CLOSED POUCH": "CENTERPOINTLOCK CLOSED POUCH",
              "CENTERPOINTLOCK DRAIN POUCH": "CENTERPOINTLOCK DRAIN POUCH",
              "CENTERPOINTLOCK IRRIG DRAIN": "CENTERPOINTLOCK IRRIG DRAIN",
              "CENTERPOINTLOCK SKIN BARRIER": "CENTERPOINTLOCK SKIN BARRIER",
              "CENTERPOINTLOCK STOMA CAP": "CENTERPOINTLOCK STOMA CAP",
              "CERAPLUS UROSTOMY POUCH": "CERAPLUS UROSTOMY POUCH",
              "CLOSED POUCH/FILTER/FLANGE": "CLOSED POUCH/FILTER/FLANGE",
              "COMBINATION CONE/TUBE DRAIN": "COMBINATION CONE/TUBE DRAIN",
              "CONE/TUBE IRRIGATION SET": "CONE/TUBE IRRIGATION SET",
              "CONTOUR I STOMA CAP": "CONTOUR I STOMA CAP",
              "DRAIN/TUBE ATTACHMENT DEVICE": "DRAIN/TUBE ATTACHMENT DEVICE",
              "DRAINABLE POUCH CLAMP": "DRAINABLE POUCH CLAMP",
              "DRAINABLE POUCH/FILTER/FLANGE": "DRAINABLE POUCH/FILTER/FLANGE",
              "FILTER SECURITY CLOSED POUCH": "FILTER SECURITY CLOSED POUCH",
              "FIRSTCHOICE CLOSED POUCH": "FIRSTCHOICE CLOSED POUCH",
              "FIRSTCHOICE DRAINABLE": "FIRSTCHOICE DRAINABLE",
              "FIRSTCHOICE DRAINABLE POUCH": "FIRSTCHOICE DRAINABLE POUCH",
              "FIRSTCHOICE POST-OP DRAINABLE": "FIRSTCHOICE POST-OP DRAINABLE",
              "FIRSTCHOICE UROSTOMY": "FIRSTCHOICE UROSTOMY",
              "FIRSTCHOICE UROSTOMY POUCH": "FIRSTCHOICE UROSTOMY POUCH",
              "FLEXTEND PREMIER": "FLEXTEND PREMIER",
              "FLEXTEND PREMIER 1 1/2\"\"": "FLEXTEND PREMIER 1 1/2\"\"",
              "FLEXTEND PREMIER 1 1/4\"\"": "FLEXTEND PREMIER 1 1/4\"\"",
              "FLEXTEND PREMIER 1 1/8\"\"": "FLEXTEND PREMIER 1 1/8\"\"",
              "FLEXTEND PREMIER 1 3/4\"\"": "FLEXTEND PREMIER 1 3/4\"\"",
              "FLEXTEND PREMIER 1\"\"": "FLEXTEND PREMIER 1\"\"",
              "FLEXTEND PREMIER 2 1/2\"\"": "FLEXTEND PREMIER 2 1/2\"\"",
              "FLEXTEND PREMIER 2 1/8\"\"": "FLEXTEND PREMIER 2 1/8\"\"",
              "FLEXTEND PREMIER 2\"\"": "FLEXTEND PREMIER 2\"\"",
              "FLEXTEND PREMIER 7/8\"\"": "FLEXTEND PREMIER 7/8\"\"",
              "FLEXTEND SKIN BARRIER": "FLEXTEND SKIN BARRIER",
              "FLEXWEAR PREMIER 2 1/2\"\"": "FLEXWEAR PREMIER 2 1/2\"\"",
              "HOLLIHESIVE SKIN BARRIER": "HOLLIHESIVE SKIN BARRIER",
              "HOLLISTER REPLACEMENT FILTERS": "HOLLISTER REPLACEMENT FILTERS",
              "KARAYA 5 SEAL DRAINABLE POUCH": "KARAYA 5 SEAL DRAINABLE POUCH",
              "KARAYA PASTE": "KARAYA PASTE",
              "KARAYA POWDER": "KARAYA POWDER",
              "KARAYA SEAL CLOSED STOMA POUCH": "KARAYA SEAL CLOSED STOMA POUCH",
              "KARAYA SEAL DRAINABLE POUCH": "KARAYA SEAL DRAINABLE POUCH",
              "LO-PROFILE UROSTOMY": "LO-PROFILE UROSTOMY",
              "LOOP OSTOMY BRIDGE STERILE": "LOOP OSTOMY BRIDGE STERILE",
              "M9 CLEANER/DECRYSTALLIZER": "M9 CLEANER/DECRYSTALLIZER",
              "M9 ODOR ELIMINATOR": "M9 ODOR ELIMINATOR",
              "MEDICAL ADHESIVE": "MEDICAL ADHESIVE",
              "MEDICAL ADHESIVE REMOVER": "MEDICAL ADHESIVE REMOVER",
              "NEW IMAGE CERAPLUS": "NEW IMAGE CERAPLUS",
              "NEW IMAGE CLOSED 1-3/4\"\"": "NEW IMAGE CLOSED 1-3/4\"\"",
              "NEW IMAGE CLOSED 2-1/4\"\"": "NEW IMAGE CLOSED 2-1/4\"\"",
              "NEW IMAGE CLOSED 2-3/4\"\"": "NEW IMAGE CLOSED 2-3/4\"\"",
              "NEW IMAGE CLOSED MINI 1-3/4\"\"": "NEW IMAGE CLOSED MINI 1-3/4\"\"",
              "NEW IMAGE CLOSED MINI 2-1/4\"\"": "NEW IMAGE CLOSED MINI 2-1/4\"\"",
              "NEW IMAGE CLOSED MINI 2-3/4\"\"": "NEW IMAGE CLOSED MINI 2-3/4\"\"",
              "NEW IMAGE COLOSTOMY 1-3/4\"\"": "NEW IMAGE COLOSTOMY 1-3/4\"\"",
              "NEW IMAGE COLOSTOMY 2-3/4\"\"": "NEW IMAGE COLOSTOMY 2-3/4\"\"",
              "NEW IMAGE CONVEX BARRIER 44MM": "NEW IMAGE CONVEX BARRIER 44MM",
              "NEW IMAGE CONVEX BARRIER 57MM": "NEW IMAGE CONVEX BARRIER 57MM",
              "NEW IMAGE CONVEX BARRIER 70MM": "NEW IMAGE CONVEX BARRIER 70MM",
              "NEW IMAGE DRAIN MINI-POUCH": "NEW IMAGE DRAIN MINI-POUCH",
              "NEW IMAGE DRAIN POUCH 102MM": "NEW IMAGE DRAIN POUCH 102MM",
              "NEW IMAGE DRAINABLE 1-3/4\"\"": "NEW IMAGE DRAINABLE 1-3/4\"\"",
              "NEW IMAGE DRAINABLE 102MM": "NEW IMAGE DRAINABLE 102MM",
              "NEW IMAGE DRAINABLE 57MM": "NEW IMAGE DRAINABLE 57MM",
              "NEW IMAGE DRAINABLE 70MM": "NEW IMAGE DRAINABLE 70MM",
              "NEW IMAGE DRAINABLE MINI 44MM": "NEW IMAGE DRAINABLE MINI 44MM",
              "NEW IMAGE DRAINABLE MINI 57MM": "NEW IMAGE DRAINABLE MINI 57MM",
              "NEW IMAGE DRAINABLE MINI 70MM": "NEW IMAGE DRAINABLE MINI 70MM",
              "NEW IMAGE DRAINABLE POUCH": "NEW IMAGE DRAINABLE POUCH",
              "NEW IMAGE DRAINABLE POUCH 44MM": "NEW IMAGE DRAINABLE POUCH 44MM",
              "NEW IMAGE DRAINABLE POUCH 57MM": "NEW IMAGE DRAINABLE POUCH 57MM",
              "NEW IMAGE DRAINABLE POUCH 70MM": "NEW IMAGE DRAINABLE POUCH 70MM",
              "NEW IMAGE FLAT BARRIER 57MM": "NEW IMAGE FLAT BARRIER 57MM",
              "NEW IMAGE FLAT BARRIER 70MM": "NEW IMAGE FLAT BARRIER 70MM",
              "NEW IMAGE FLEXTEND BARRIER": "NEW IMAGE FLEXTEND BARRIER",
              "NEW IMAGE FLEXWEAR BARRIER": "NEW IMAGE FLEXWEAR BARRIER",
              "NEW IMAGE IRRIG SLEEVE 44MM": "NEW IMAGE IRRIG SLEEVE 44MM",
              "NEW IMAGE IRRIG SLEEVE 57MM": "NEW IMAGE IRRIG SLEEVE 57MM",
              "NEW IMAGE IRRIG SLEEVE 70MM": "NEW IMAGE IRRIG SLEEVE 70MM",
              "NEW IMAGE OSTOMY SYSTEM": "NEW IMAGE OSTOMY SYSTEM",
              "NEW IMAGE SKIN BARRIER/FLANGE": "NEW IMAGE SKIN BARRIER/FLANGE",
              "NEW IMAGE SKIN/FLANGE/TAPE": "NEW IMAGE SKIN/FLANGE/TAPE",
              "NEW IMAGE UROSTOMY 1-3/4\"\"": "NEW IMAGE UROSTOMY 1-3/4\"\"",
              "NEW IMAGE UROSTOMY 2-1/4\"\"": "NEW IMAGE UROSTOMY 2-1/4\"\"",
              "NEW IMAGE UROSTOMY 2-3/4\"\"": "NEW IMAGE UROSTOMY 2-3/4\"\"",
              "NEW IMAGE UROSTOMY 70MM": "NEW IMAGE UROSTOMY 70MM",
              "NEW IMAGE UROSTOMY POUCH 44MM": "NEW IMAGE UROSTOMY POUCH 44MM",
              "NEW IMAGE UROSTOMY POUCH 57MM": "NEW IMAGE UROSTOMY POUCH 57MM",
              "OSTOMY BELT LARGE": "OSTOMY BELT LARGE",
              "OSTOMY BELT MEDIUM": "OSTOMY BELT MEDIUM",
              "OSTOMY DRAIN/MINI-POUCH/FLANGE": "OSTOMY DRAIN/MINI-POUCH/FLANGE",
              "OSTOMY DRAINABLE POUCH/FLANGE": "OSTOMY DRAINABLE POUCH/FLANGE",
              "PEDIATRIC DRAINABLE POUCH": "PEDIATRIC DRAINABLE POUCH",
              "PEDIATRIC UROSTOMY POUCH": "PEDIATRIC UROSTOMY POUCH",
              "POUCHKINS 1-PC DRAINABLE 2\"\"": "POUCHKINS 1-PC DRAINABLE 2\"\"",
              "POUCHKINS 2-PC DRAINABLE POUCH": "POUCHKINS 2-PC DRAINABLE POUCH",
              "POUCHKINS 2-PC UROSTOMY POUCH": "POUCHKINS 2-PC UROSTOMY POUCH",
              "POUCHKINS DRAIN TUBE": "POUCHKINS DRAIN TUBE",
              "POUCHKINS OSTOMY SYSTEM": "POUCHKINS OSTOMY SYSTEM",
              "POUCHKINS PED 1-PC DRAINABLE": "POUCHKINS PED 1-PC DRAINABLE",
              "POUCHKINS PED 1-PC UROSTOMY": "POUCHKINS PED 1-PC UROSTOMY",
              "POUCHKINS PED 2-PC DRAINABLE": "POUCHKINS PED 2-PC DRAINABLE",
              "POUCHKINS PED OSTOMY BELT": "POUCHKINS PED OSTOMY BELT",
              "POUCHKINS PED SKIN BARRIER": "POUCHKINS PED SKIN BARRIER",
              "PREMIER CERAPLUS 1 1/2\"\"": "PREMIER CERAPLUS 1 1/2\"\"",
              "PREMIER CERAPLUS 1 1/8\"\"": "PREMIER CERAPLUS 1 1/8\"\"",
              "PREMIER CERAPLUS 1 3/16\"\"": "PREMIER CERAPLUS 1 3/16\"\"",
              "PREMIER CERAPLUS 1 3/8\"\"": "PREMIER CERAPLUS 1 3/8\"\"",
              "PREMIER CERAPLUS 1 9/16\"\"": "PREMIER CERAPLUS 1 9/16\"\"",
              "PREMIER CERAPLUS 1\"\"": "PREMIER CERAPLUS 1\"\"",
              "PREMIER CERAPLUS 1\\'": "PREMIER CERAPLUS 1\\'",
              "PREMIER CERAPLUS 2 1/2\"\"": "PREMIER CERAPLUS 2 1/2\"\"",
              "PREMIER CERAPLUS 2 1/8\"\"": "PREMIER CERAPLUS 2 1/8\"\"",
              "PREMIER CERAPLUS 2\"\"": "PREMIER CERAPLUS 2\"\"",
              "PREMIER CERAPLUS 3/4\"\"": "PREMIER CERAPLUS 3/4\"\"",
              "PREMIER CERAPLUS 7/8\"\"": "PREMIER CERAPLUS 7/8\"\"",
              "PREMIER CLOSED MINI-POUCH 25MM": "PREMIER CLOSED MINI-POUCH 25MM",
              "PREMIER CLOSED MINI-POUCH 30MM": "PREMIER CLOSED MINI-POUCH 30MM",
              "PREMIER CLOSED MINI-POUCH 35MM": "PREMIER CLOSED MINI-POUCH 35MM",
              "PREMIER CLOSED MINI-POUCH 55MM": "PREMIER CLOSED MINI-POUCH 55MM",
              "PREMIER CLOSED POUCH 25MM": "PREMIER CLOSED POUCH 25MM",
              "PREMIER CLOSED POUCH 30MM": "PREMIER CLOSED POUCH 30MM",
              "PREMIER CLOSED POUCH 35MM": "PREMIER CLOSED POUCH 35MM",
              "PREMIER CLOSED POUCH 55MM": "PREMIER CLOSED POUCH 55MM",
              "PREMIER COLOSTOMY/ILEOSTOMY": "PREMIER COLOSTOMY/ILEOSTOMY",
              "PREMIER CONVEX SKIN BARRIER": "PREMIER CONVEX SKIN BARRIER",
              "PREMIER DRAINABLE": "PREMIER DRAINABLE",
              "PREMIER DRAINABLE 110MM": "PREMIER DRAINABLE 110MM",
              "PREMIER DRAINABLE 2-1/2\"\"": "PREMIER DRAINABLE 2-1/2\"\"",
              "PREMIER DRAINABLE 25MM": "PREMIER DRAINABLE 25MM",
              "PREMIER DRAINABLE 3\"\"X2.5\"\"": "PREMIER DRAINABLE 3\"\"X2.5\"\"",
              "PREMIER DRAINABLE 70MM": "PREMIER DRAINABLE 70MM",
              "PREMIER DRAINABLE MINI 25MM": "PREMIER DRAINABLE MINI 25MM",
              "PREMIER DRAINABLE MINI 30MM": "PREMIER DRAINABLE MINI 30MM",
              "PREMIER DRAINABLE MINI 35MM": "PREMIER DRAINABLE MINI 35MM",
              "PREMIER DRAINABLE MINI 40MM": "PREMIER DRAINABLE MINI 40MM",
              "PREMIER DRAINABLE MINI 55MM": "PREMIER DRAINABLE MINI 55MM",
              "PREMIER DRAINABLE MINI-POUCH": "PREMIER DRAINABLE MINI-POUCH",
              "PREMIER DRAINABLE POUCH 19MM": "PREMIER DRAINABLE POUCH 19MM",
              "PREMIER DRAINABLE POUCH 22MM": "PREMIER DRAINABLE POUCH 22MM",
              "PREMIER DRAINABLE POUCH 29MM": "PREMIER DRAINABLE POUCH 29MM",
              "PREMIER DRAINABLE POUCH 30MM": "PREMIER DRAINABLE POUCH 30MM",
              "PREMIER DRAINABLE POUCH 32MM": "PREMIER DRAINABLE POUCH 32MM",
              "PREMIER DRAINABLE POUCH 35MM": "PREMIER DRAINABLE POUCH 35MM",
              "PREMIER DRAINABLE POUCH 38MM": "PREMIER DRAINABLE POUCH 38MM",
              "PREMIER DRAINABLE POUCH 40MM": "PREMIER DRAINABLE POUCH 40MM",
              "PREMIER DRAINABLE POUCH 44MM": "PREMIER DRAINABLE POUCH 44MM",
              "PREMIER DRAINABLE POUCH 51MM": "PREMIER DRAINABLE POUCH 51MM",
              "PREMIER DRAINABLE POUCH 55MM": "PREMIER DRAINABLE POUCH 55MM",
              "PREMIER DRAINABLE POUCH 64MM": "PREMIER DRAINABLE POUCH 64MM",
              "PREMIER DRAINABLE SOFTFLEX": "PREMIER DRAINABLE SOFTFLEX",
              "PREMIER DRAINABLE/FILTER": "PREMIER DRAINABLE/FILTER",
              "PREMIER SKIN BARRIER": "PREMIER SKIN BARRIER",
              "PREMIER UROSTOMY": "PREMIER UROSTOMY",
              "PREMIER UROSTOMY 1\"\"": "PREMIER UROSTOMY 1\"\"",
              "PREMIER UROSTOMY 1-1/2\"\"": "PREMIER UROSTOMY 1-1/2\"\"",
              "PREMIER UROSTOMY 1-3/4\"\"": "PREMIER UROSTOMY 1-3/4\"\"",
              "PREMIER UROSTOMY 2-1/2\"\"": "PREMIER UROSTOMY 2-1/2\"\"",
              "PREMIER UROSTOMY KIT 64MM": "PREMIER UROSTOMY KIT 64MM",
              "PREMIUM DRAINABLE POUCH/KARAYA": "PREMIUM DRAINABLE POUCH/KARAYA",
              "PREMIUM SKIN BARRIER": "PREMIUM SKIN BARRIER",
              "RESTORE BARRIER": "RESTORE BARRIER",
              "SKIN GEL PROTECT DRESSING WIPE": "SKIN GEL PROTECT DRESSING WIPE",
              "SOFTFLEX SKIN BARRIER RING": "SOFTFLEX SKIN BARRIER RING",
              "STOMA CAP/ADHESIVE/FILTER": "STOMA CAP/ADHESIVE/FILTER",
              "STOMA CONE IRRIGATOR": "STOMA CONE IRRIGATOR",
              "STOMA CONE/CONNECTOR REPL UNIT": "STOMA CONE/CONNECTOR REPL UNIT",
              "STOMA LUBRICANT": "STOMA LUBRICANT",
              "UROSTOMY DRAIN TUBE ADAPTER": "UROSTOMY DRAIN TUBE ADAPTER",
              "UROSTOMY POUCH/FLANGE/ADAPTERS": "UROSTOMY POUCH/FLANGE/ADAPTERS"
            },
            "HOLLISTER WOUND CARE": {
              "MOISTURE BARRIER SKIN": "MOISTURE BARRIER SKIN",
              "RESTORE WOUND CLEANSER": "RESTORE WOUND CLEANSER",
              "SKIN CLEANSER": "SKIN CLEANSER",
              "SKIN CONDITIONING": "SKIN CONDITIONING"
            },
            "MEDLINE INDUSTRIES": {
              "SUREPREP NO-STING BARRIER WIPE": "SUREPREP NO-STING BARRIER WIPE"
            },
            "MEDLINE/DERMAL MANAGEMENT": {
              "CARRAFREE ODOR ELIMINATOR": "CARRAFREE ODOR ELIMINATOR",
              "CARRASCENT": "CARRASCENT"
            },
            "PCCA": {
              "KARAYA GUM": "KARAYA GUM"
            },
            "RELIAMED": {
              "ADHESIVE REMOVER WIPES": "ADHESIVE REMOVER WIPES",
              "ADHESIVE REMOVER WIPES XL": "ADHESIVE REMOVER WIPES XL",
              "ODOR ELIMINATOR": "ODOR ELIMINATOR",
              "OSTOMY PROTECTIVE": "OSTOMY PROTECTIVE",
              "PROTECTIVE BARRIER WIPES": "PROTECTIVE BARRIER WIPES"
            },
            "SMITH & NEPHEW BIOTHERAPEUTICS": {
              "FRESHNET": "FRESHNET"
            },
            "SMITH & NEPHEW UNITED": {
              "BANISH II APPLIANCE DEODORANT": "BANISH II APPLIANCE DEODORANT",
              "SKIN PREP SPRAY": "SKIN PREP SPRAY",
              "SKIN PREP WIPES": "SKIN PREP WIPES"
            },
            "TORBOT GROUP": {
              "FEATHER-LITE UROSTOMY": "FEATHER-LITE UROSTOMY",
              "SKIN TAC ADHESIVE BARRIER WIPE": "SKIN TAC ADHESIVE BARRIER WIPE",
              "TORBOT BONDING CEMENT": "TORBOT BONDING CEMENT"
            },
            "UROCARE PRODUCTS, INC.": {
              "URO-WASH PERINEAL/OSTOMY CLN": "URO-WASH PERINEAL/OSTOMY CLN",
              "UROLUX URINARY/OSTOMY CLEANS": "UROLUX URINARY/OSTOMY CLEANS"
            },
            "WALGREENS": {
              "ODOR SPRAY": "ODOR SPRAY"
            }
          },
          "Urinary Drainage & Irrigation Supplies": {
            "BARD": {
              "BARD IRRIGATION SYRINGE/BULB": "BARD IRRIGATION SYRINGE/BULB"
            },
            "COVIDIEN MEDICAL SUPPLIES": {
              "DOVER PISTON SYRINGE": "DOVER PISTON SYRINGE",
              "KANGAROO IRRIGATION KIT": "KANGAROO IRRIGATION KIT",
              "KANGAROO IRRIGATION SYRINGE": "KANGAROO IRRIGATION SYRINGE"
            },
            "RELIAMED": {
              "PISTON IRRIGATION SYRINGE": "PISTON IRRIGATION SYRINGE"
            }
          }
        },
        "Heating/Cooling Aids": {
          "Cold Packs": {
            "3M CONSUMER HEALTH CARE": {
              "ACE COLD COMPRESS REUSABLE": "ACE COLD COMPRESS REUSABLE",
              "FUTURO REUSABLE COLD PACK": "FUTURO REUSABLE COLD PACK",
              "NEXCARE INSTANT COLD PACK": "NEXCARE INSTANT COLD PACK",
              "NEXCARE INSTANT/REUSE COLD PAK": "NEXCARE INSTANT/REUSE COLD PAK",
              "NEXCARE REUSABLE COLD PACK": "NEXCARE REUSABLE COLD PACK"
            },
            "BATTLE CREEK": {
              "ICE IT 11\"\"X14\"\"": "ICE IT 11\"\"X14\"\"",
              "ICE IT 4.25\"\"X8.25\"\"": "ICE IT 4.25\"\"X8.25\"\"",
              "ICE IT 6\"\"X9\"\"": "ICE IT 6\"\"X9\"\"",
              "ICE IT COLD COMFORT SYS/KNEE": "ICE IT COLD COMFORT SYS/KNEE",
              "ICE IT COLD COMFORT SYS/SHOULD": "ICE IT COLD COMFORT SYS/SHOULD",
              "ICE IT COLD COMFORT SYSTEM": "ICE IT COLD COMFORT SYSTEM",
              "ICE IT COLLAR W/COVER 4.5\"\"X10\"\"": "ICE IT COLLAR W/COVER 4.5\"\"X10\"\"",
              "ICE IT DOUBLE PACK 4.25\"\"X4.5\"\"": "ICE IT DOUBLE PACK 4.25\"\"X4.5\"\"",
              "ICE IT REUSABLE COLD/B-PK DOUB": "ICE IT REUSABLE COLD/B-PK DOUB",
              "ICE IT REUSABLE COLD/C-PACK": "ICE IT REUSABLE COLD/C-PACK",
              "ICE IT REUSABLE COLD/D-PACK": "ICE IT REUSABLE COLD/D-PACK",
              "ICE IT REUSABLE COLD/E-PACK": "ICE IT REUSABLE COLD/E-PACK",
              "ICE IT REUSABLE COLD/E-PK DOUB": "ICE IT REUSABLE COLD/E-PK DOUB",
              "ICE IT REUSABLE COLD/F-PACK": "ICE IT REUSABLE COLD/F-PACK",
              "ICE IT W/COVER/STRAP 6\"\"X18\"\"": "ICE IT W/COVER/STRAP 6\"\"X18\"\"",
              "ICE IT W/COVER/STRAP 6\"\"X9\"\"": "ICE IT W/COVER/STRAP 6\"\"X9\"\"",
              "ICE IT W/COVER/STRAP 9\"\"X20\"\"": "ICE IT W/COVER/STRAP 9\"\"X20\"\"",
              "ICE IT WRAP/WRIST 4.25\"\"X8.25\"\"": "ICE IT WRAP/WRIST 4.25\"\"X8.25\"\""
            },
            "BEIERSDORF-FUTURO": {
              "CURAD FOR KIDS/COLD PACK": "CURAD FOR KIDS/COLD PACK"
            },
            "CHAIN DRUG CONSORTIUM": {
              "ICE BAG": "ICE BAG",
              "INSTANT COLD PACK": "INSTANT COLD PACK"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS COLD PACK": "CVS COLD PACK",
              "CVS CRYOMAX COLD PACK": "CVS CRYOMAX COLD PACK",
              "CVS INSTANT COLD PACK": "CVS INSTANT COLD PACK",
              "CVS INSTANT COLD PAIN RELIEF": "CVS INSTANT COLD PAIN RELIEF",
              "CVS INSTANT COLD THERAPY WRAP": "CVS INSTANT COLD THERAPY WRAP"
            },
            "DAVOL": {
              "MEDI-BEAR FREEZER FRIEND": "MEDI-BEAR FREEZER FRIEND"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "THERMACARE COLD JOINT THERAPY": "THERMACARE COLD JOINT THERAPY",
              "THERMACARE COLD MUSCLE THERAPY": "THERMACARE COLD MUSCLE THERAPY"
            },
            "KAZ": {
              "DUNLAP ICE BAG": "DUNLAP ICE BAG",
              "DUNLAP INSTANT ICE PACK": "DUNLAP INSTANT ICE PACK",
              "SMARTTEMP INSTANT ICE": "SMARTTEMP INSTANT ICE"
            },
            "LEADER BRAND PRODUCTS": {
              "COLD PACKS": "COLD PACKS",
              "INSTANT COLD PACK": "INSTANT COLD PACK"
            },
            "MCKESSON HOME HEALTH CARE": {
              "COLD PAK": "COLD PAK",
              "ICE BAG": "ICE BAG"
            },
            "PCCA": {
              "POLAR PACKS": "POLAR PACKS"
            },
            "RITE AID CORPORATION": {
              "RA BACK & NECK COLD PAD": "RA BACK & NECK COLD PAD",
              "RA ICE BAG": "RA ICE BAG",
              "RA ICE CAP": "RA ICE CAP",
              "RA INSTANT COLD PACK": "RA INSTANT COLD PACK",
              "RA MULTI-USE COLD PAD REUSABLE": "RA MULTI-USE COLD PAD REUSABLE"
            },
            "SUNBEAM": {
              "SUNBEAM COLD PACK DUO": "SUNBEAM COLD PACK DUO",
              "SUNBEAM ELASTIC WRAP/COLD PACK": "SUNBEAM ELASTIC WRAP/COLD PACK",
              "SUNBEAM ICE BAG": "SUNBEAM ICE BAG",
              "SUNBEAM INSTANT COLD": "SUNBEAM INSTANT COLD",
              "SUNBEAM LARGE COLD PACK": "SUNBEAM LARGE COLD PACK"
            },
            "WAL-MART": {
              "EQ COLD COMPRESS": "EQ COLD COMPRESS",
              "EQ COLD COMPRESS REUSABLE": "EQ COLD COMPRESS REUSABLE"
            },
            "WALGREENS": {
              "BACK PAD COLD PACK": "BACK PAD COLD PACK",
              "COLD PACK REUSABLE": "COLD PACK REUSABLE",
              "ICE BAG": "ICE BAG",
              "MULTI-PURPOSE COLD COMPRESS": "MULTI-PURPOSE COLD COMPRESS"
            }
          },
          "Heating Pads": {
            "BATTLE CREEK": {
              "THERMOPHORE ARTHRITIS LARGE": "THERMOPHORE ARTHRITIS LARGE",
              "THERMOPHORE ARTHRITIS MEDIUM": "THERMOPHORE ARTHRITIS MEDIUM",
              "THERMOPHORE ARTHRITIS MUFF": "THERMOPHORE ARTHRITIS MUFF",
              "THERMOPHORE ARTHRITIS PETITE": "THERMOPHORE ARTHRITIS PETITE",
              "THERMOPHORE BED WARMER": "THERMOPHORE BED WARMER",
              "THERMOPHORE BED WARMER DELUXE": "THERMOPHORE BED WARMER DELUXE",
              "THERMOPHORE CLASSIC PLS PETITE": "THERMOPHORE CLASSIC PLS PETITE",
              "THERMOPHORE CLASSIC PLUS LARGE": "THERMOPHORE CLASSIC PLUS LARGE",
              "THERMOPHORE CLASSIC PLUS MED": "THERMOPHORE CLASSIC PLUS MED",
              "THERMOPHORE MAXHEAT PLS/PETITE": "THERMOPHORE MAXHEAT PLS/PETITE",
              "THERMOPHORE MAXHEAT PLUS/LARGE": "THERMOPHORE MAXHEAT PLUS/LARGE",
              "THERMOPHORE MAXHEAT PLUS/MED": "THERMOPHORE MAXHEAT PLUS/MED",
              "THERMOPHORE MEDIUM": "THERMOPHORE MEDIUM",
              "THERMOPHORE MUFF": "THERMOPHORE MUFF",
              "THERMOPHORE PETITE": "THERMOPHORE PETITE",
              "THERMOPHORE STANDARD": "THERMOPHORE STANDARD"
            },
            "BERGEN BRUNSWIG": {
              "GNP HEATING PAD": "GNP HEATING PAD"
            },
            "CHAIN DRUG CONSORTIUM": {
              "HEATING PAD DRY HEAT": "HEATING PAD DRY HEAT",
              "HEATING PAD MOIST/DRY": "HEATING PAD MOIST/DRY",
              "HEATING PAD MOIST/DRY KING SZ": "HEATING PAD MOIST/DRY KING SZ"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS HEATING PAD": "CVS HEATING PAD",
              "CVS MOIST/DRY HEAT HEATING": "CVS MOIST/DRY HEAT HEATING"
            },
            "DAVOL": {
              "DUNLAP CUSTOM DRY HEAT": "DUNLAP CUSTOM DRY HEAT",
              "DUNLAP DELUXE DRY HEAT": "DUNLAP DELUXE DRY HEAT",
              "DUNLAP DELUXE MOIST/DRY": "DUNLAP DELUXE MOIST/DRY",
              "DUNLAP HOSPITAL HEAT THERAPY": "DUNLAP HOSPITAL HEAT THERAPY",
              "DUNLAP MOIST HEAT HUGGER WRAP": "DUNLAP MOIST HEAT HUGGER WRAP",
              "DUNLAP MOIST HEAT THERAPY": "DUNLAP MOIST HEAT THERAPY"
            },
            "DURO-MED INDUSTRIES": {
              "DRY HEAT HEATING PAD DELUXE": "DRY HEAT HEATING PAD DELUXE",
              "DRY HEAT HEATING PAD STANDARD": "DRY HEAT HEATING PAD STANDARD"
            },
            "HOME AIDE DIAGNOSTICS": {
              "COMFORT-HEAL HEATING PAD": "COMFORT-HEAL HEATING PAD"
            },
            "KAZ": {
              "HEAT PAD MOIST/DRY MASSAGING": "HEAT PAD MOIST/DRY MASSAGING",
              "KAZ HEAT PAD/SMARTHEAT/HP710": "KAZ HEAT PAD/SMARTHEAT/HP710",
              "KAZ HEAT PAD/SMARTHEAT/HP910": "KAZ HEAT PAD/SMARTHEAT/HP910",
              "KAZ HEATING PAD/HP110/DRY HEAT": "KAZ HEATING PAD/HP110/DRY HEAT",
              "KAZ PAD/SMARTHEAT PRO/HP750": "KAZ PAD/SMARTHEAT PRO/HP750",
              "KAZ PAD/SMARTHEAT PRO/HP950": "KAZ PAD/SMARTHEAT PRO/HP950",
              "SOFTHEAT MOIST/DRY": "SOFTHEAT MOIST/DRY",
              "SOFTHEAT ULTRA MOIST/DRY": "SOFTHEAT ULTRA MOIST/DRY"
            },
            "MCKESSON HOME HEALTH CARE": {
              "HEATING PAD": "HEATING PAD",
              "HEATING PAD MOIST/DRY": "HEATING PAD MOIST/DRY",
              "MOIST HEAT PACK": "MOIST HEAT PACK",
              "SINUS MASK": "SINUS MASK",
              "SM HEATING PAD": "SM HEATING PAD"
            },
            "SIMPLE DIAGNOSTICS": {
              "CLEVER CHOICE COMFORT HEAT": "CLEVER CHOICE COMFORT HEAT"
            },
            "SUNBEAM": {
              "SUNBEAM ARTHRO MUFF": "SUNBEAM ARTHRO MUFF",
              "SUNBEAM D.C.TRAVEL HEATING PAD": "SUNBEAM D.C.TRAVEL HEATING PAD",
              "SUNBEAM FITTED HEATING PAD": "SUNBEAM FITTED HEATING PAD",
              "SUNBEAM HEAT PLUS GEL": "SUNBEAM HEAT PLUS GEL",
              "SUNBEAM HEAT WRAP": "SUNBEAM HEAT WRAP",
              "SUNBEAM HOSPITAL HEATING PAD": "SUNBEAM HOSPITAL HEATING PAD",
              "SUNBEAM HOT/COLD WRAP": "SUNBEAM HOT/COLD WRAP",
              "SUNBEAM KING HEATING PAD": "SUNBEAM KING HEATING PAD",
              "SUNBEAM SINUS MASK": "SUNBEAM SINUS MASK",
              "SUNBEAM SOFT TOUCH HEATING PAD": "SUNBEAM SOFT TOUCH HEATING PAD",
              "SUNBEAM STANDARD HEATING PAD": "SUNBEAM STANDARD HEATING PAD",
              "SUNBEAM STD HEATING PAD MOIST": "SUNBEAM STD HEATING PAD MOIST",
              "SUNBEAM VIROTHERM HEATING PAD": "SUNBEAM VIROTHERM HEATING PAD"
            },
            "WALGREENS": {
              "FAST-HEAT HEATING PAD": "FAST-HEAT HEATING PAD",
              "FAST-HEAT HEATING PAD KING SZ": "FAST-HEAT HEATING PAD KING SZ",
              "HEATING PAD MOIST/DRY": "HEATING PAD MOIST/DRY",
              "HEATING PAD MOIST/DRY KING SZ": "HEATING PAD MOIST/DRY KING SZ",
              "HEATING PAD/XPRESS HEAT": "HEATING PAD/XPRESS HEAT",
              "MOIST HEAT BACK WRAP": "MOIST HEAT BACK WRAP",
              "MOIST HEAT NECK WRAP": "MOIST HEAT NECK WRAP"
            }
          },
          "Hot Packs": {
            "BATTLE CREEK": {
              "GOOD2GO HEAT PACK POLYBAG/CERV": "GOOD2GO HEAT PACK POLYBAG/CERV",
              "GOOD2GO HEAT PACK POLYBAG/LG": "GOOD2GO HEAT PACK POLYBAG/LG",
              "GOOD2GO HEAT PACK POLYBAG/MED": "GOOD2GO HEAT PACK POLYBAG/MED",
              "GOOD2GO HEAT PACK/CERVICAL": "GOOD2GO HEAT PACK/CERVICAL",
              "GOOD2GO HEAT PACK/LARGE": "GOOD2GO HEAT PACK/LARGE",
              "GOOD2GO HEAT PACK/MEDIUM": "GOOD2GO HEAT PACK/MEDIUM",
              "GOOD2GO HEAT PACK/SHOULDER": "GOOD2GO HEAT PACK/SHOULDER",
              "GOOD2GO HEAT POLYBAG/SHOULDER": "GOOD2GO HEAT POLYBAG/SHOULDER"
            },
            "BERGEN BRUNSWIG": {
              "GNP HEATWRAPS": "GNP HEATWRAPS"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS ARTHRITIS HEATWRAPS": "CVS ARTHRITIS HEATWRAPS",
              "CVS HEAT THERAPY/BACK": "CVS HEAT THERAPY/BACK",
              "CVS HEATWRAPS": "CVS HEATWRAPS",
              "CVS HEATWRAPS/ANKLE": "CVS HEATWRAPS/ANKLE",
              "CVS HEATWRAPS/BACK/HIP": "CVS HEATWRAPS/BACK/HIP",
              "CVS MOIST HEAT PAIN RELIEF": "CVS MOIST HEAT PAIN RELIEF"
            },
            "DAVOL": {
              "DUNLAP FACIAL COMPRESS": "DUNLAP FACIAL COMPRESS",
              "DUNLAP MOIST HEAT COLLAR": "DUNLAP MOIST HEAT COLLAR",
              "DUNLAP MOIST HEAT PACK": "DUNLAP MOIST HEAT PACK",
              "DUNLAP MOIST HEAT PACK COVER": "DUNLAP MOIST HEAT PACK COVER",
              "DUNLAP MOIST HEAT SINUS COMP": "DUNLAP MOIST HEAT SINUS COMP",
              "DUNLAP MOIST HEAT THERAPY WRAP": "DUNLAP MOIST HEAT THERAPY WRAP",
              "MEDI-BEAR CRIB WARMER": "MEDI-BEAR CRIB WARMER"
            },
            "DURO-MED INDUSTRIES": {
              "THERA BEADS FACIAL COMPRESS": "THERA BEADS FACIAL COMPRESS",
              "THERA BEADS MOIST HEAT COLLAR": "THERA BEADS MOIST HEAT COLLAR"
            },
            "EQUALINE": {
              "EQL HEAT WRAPS": "EQL HEAT WRAPS",
              "EQL HEAT WRAPS BACK/HIP": "EQL HEAT WRAPS BACK/HIP"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "THERMACARE": "THERMACARE",
              "THERMACARE ARTHRITIS HAND": "THERMACARE ARTHRITIS HAND",
              "THERMACARE ARTHRITIS KNEE/ELBW": "THERMACARE ARTHRITIS KNEE/ELBW",
              "THERMACARE ARTHRITIS NECK": "THERMACARE ARTHRITIS NECK",
              "THERMACARE BACK/HIP": "THERMACARE BACK/HIP",
              "THERMACARE JOINT PAIN THERAPY": "THERMACARE JOINT PAIN THERAPY",
              "THERMACARE KNEE/ELBOW": "THERMACARE KNEE/ELBOW",
              "THERMACARE MENSTRUAL": "THERMACARE MENSTRUAL",
              "THERMACARE MUSCLE/JOINT": "THERMACARE MUSCLE/JOINT"
            },
            "KAZ": {
              "SOFTHEAT HEATING WRAP ULTRA": "SOFTHEAT HEATING WRAP ULTRA"
            },
            "LEADER BRAND PRODUCTS": {
              "HEATWRAPS BACK/HIP": "HEATWRAPS BACK/HIP",
              "HEATWRAPS NECK/SHOULDER/WRIST": "HEATWRAPS NECK/SHOULDER/WRIST"
            },
            "MCKESSON": {
              "HM HEAT WRAPS": "HM HEAT WRAPS",
              "HM HEAT WRAPS BACK/HIP": "HM HEAT WRAPS BACK/HIP"
            },
            "MCKESSON HOME HEALTH CARE": {
              "HEAT AND GO SPOT HEAT": "HEAT AND GO SPOT HEAT",
              "HEAT AND GO SPOT HOT PAK": "HEAT AND GO SPOT HOT PAK"
            },
            "MCKESSON SUNMARK": {
              "SM HEATWRAPS BACK/HIP": "SM HEATWRAPS BACK/HIP"
            },
            "PUBLIX SUPER MARKETS INC.": {
              "PX HEATWRAPS BACK/HIP": "PX HEATWRAPS BACK/HIP"
            },
            "RITE AID CORPORATION": {
              "RA HEAT THERAPY PATCHES/BACK": "RA HEAT THERAPY PATCHES/BACK",
              "RA HEATWRAPS": "RA HEATWRAPS",
              "RA HEATWRAPS BACK/HIP": "RA HEATWRAPS BACK/HIP",
              "RA INSTANT HEAT THERAPY/BACK": "RA INSTANT HEAT THERAPY/BACK",
              "RA INSTANT HEAT THERAPY/NECK": "RA INSTANT HEAT THERAPY/NECK",
              "RA INSTANT WARM PACK": "RA INSTANT WARM PACK",
              "RA PIN POINT HEAT THERAPY": "RA PIN POINT HEAT THERAPY"
            },
            "SUNBEAM": {
              "SUNBEAM HEAT WRAP": "SUNBEAM HEAT WRAP",
              "SUNBEAM INSTANT WARM PACK": "SUNBEAM INSTANT WARM PACK",
              "SUNBEAM SPOT HEAT": "SUNBEAM SPOT HEAT"
            },
            "WALGREENS": {
              "HEAT THERAPY PATCHES": "HEAT THERAPY PATCHES",
              "HEAT WRAPS BACK/HIP": "HEAT WRAPS BACK/HIP",
              "HEATWRAPS BACK/HIP": "HEATWRAPS BACK/HIP"
            }
          },
          "Hot/Cold Combination Therapy Aids": {
            "3M CONSUMER HEALTH CARE": {
              "FUTURO HOT/COLD PACK": "FUTURO HOT/COLD PACK",
              "NEXCARE COLD/HOT PACK REUSABLE": "NEXCARE COLD/HOT PACK REUSABLE"
            },
            "BATTLE CREEK": {
              "GOOD2GO HOT/COLD PACK/MITTEN": "GOOD2GO HOT/COLD PACK/MITTEN",
              "GOOD2GO HOT/COLD POLYBAG/MITT": "GOOD2GO HOT/COLD POLYBAG/MITT",
              "ICE IT DELUXE HEADACHE/MIGRAIN": "ICE IT DELUXE HEADACHE/MIGRAIN",
              "ICE IT HEADACHE AND MIGRAINE": "ICE IT HEADACHE AND MIGRAINE"
            },
            "BD DIABETES CARE": {
              "TRU-FIT ICE/HEAT ANKLE/GEL PAK": "TRU-FIT ICE/HEAT ANKLE/GEL PAK",
              "TRU-FIT ICE/HEAT BACK/GEL PACK": "TRU-FIT ICE/HEAT BACK/GEL PACK",
              "TRU-FIT ICE/HEAT GEL PACK": "TRU-FIT ICE/HEAT GEL PACK",
              "TRU-FIT ICE/HEAT GEL PAK POUCH": "TRU-FIT ICE/HEAT GEL PAK POUCH",
              "TRU-FIT ICE/HEAT KNEE/GEL PACK": "TRU-FIT ICE/HEAT KNEE/GEL PACK",
              "TRU-FIT ICE/HEAT MEDICINE BALL": "TRU-FIT ICE/HEAT MEDICINE BALL",
              "TRU-FIT ICE/HEAT MULTI WRAP": "TRU-FIT ICE/HEAT MULTI WRAP",
              "TRU-FIT ICE/HEAT SHOULDER": "TRU-FIT ICE/HEAT SHOULDER"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS DELUXE HOT/COLD COMPRESS": "CVS DELUXE HOT/COLD COMPRESS",
              "CVS HOT/COLD COMPRESS": "CVS HOT/COLD COMPRESS"
            },
            "EQUALINE": {
              "EQL COMFORT PACK HOT/COLD": "EQL COMFORT PACK HOT/COLD"
            },
            "KAZ": {
              "KAZ COMPLETE INJURY/RECOVERY": "KAZ COMPLETE INJURY/RECOVERY",
              "KAZ THERMIPAQ HOT/COLD": "KAZ THERMIPAQ HOT/COLD",
              "SMARTTEMP HOT/COLD": "SMARTTEMP HOT/COLD",
              "SMARTTEMP MOIST HEAT": "SMARTTEMP MOIST HEAT"
            },
            "LANSINOH LABORATORIES": {
              "THERA PEARL BREAST THERAPY": "THERA PEARL BREAST THERAPY"
            },
            "LEADER BRAND PRODUCTS": {
              "HOT & COLD COMPRESS": "HOT & COLD COMPRESS",
              "HOT & COLD WRAP HEAD/NECK": "HOT & COLD WRAP HEAD/NECK",
              "HOT & COLD WRAP UNIVERSAL": "HOT & COLD WRAP UNIVERSAL",
              "HOT & COLD WRAP UNIVERSAL MED": "HOT & COLD WRAP UNIVERSAL MED",
              "HOT & COLD WRAP UNIVERSAL PLUS": "HOT & COLD WRAP UNIVERSAL PLUS",
              "HOT COLD GELPACK": "HOT COLD GELPACK"
            },
            "MCKESSON HOME HEALTH CARE": {
              "HOT/COLD PAK": "HOT/COLD PAK",
              "HOT/COLD WRAP": "HOT/COLD WRAP"
            },
            "MEDICINE SHOPPE": {
              "HOT/COLD PACK": "HOT/COLD PACK"
            },
            "MUELLER": {
              "COLD-HOT THERAPY WRAP": "COLD-HOT THERAPY WRAP"
            },
            "RITE AID CORPORATION": {
              "RA COMFORT CARE PLUS HOT/COLD": "RA COMFORT CARE PLUS HOT/COLD"
            },
            "SEA-BAND": {
              "BREAST SOOTHER GEL PAD": "BREAST SOOTHER GEL PAD",
              "FEME PAD": "FEME PAD",
              "FEME PAD GAUZE SLEEVES": "FEME PAD GAUZE SLEEVES"
            },
            "SUNBEAM": {
              "SUNBEAM HOT/COLD DUO": "SUNBEAM HOT/COLD DUO"
            },
            "SURGICAL APPLIANCE INDUSTRIES": {
              "THERMA-KOOL HOT/COLD COMPRESS": "THERMA-KOOL HOT/COLD COMPRESS"
            },
            "THERAPEARL": {
              "THERA-PEARL CONTOUR PACK": "THERA-PEARL CONTOUR PACK"
            },
            "TOPCO": {
              "HOT/COLD COMPRESS": "HOT/COLD COMPRESS"
            },
            "WAL-MART": {
              "EQ HOT/COLD COMPRESS": "EQ HOT/COLD COMPRESS",
              "EQ HOT/COLD COMPRESSION SLEEVE": "EQ HOT/COLD COMPRESSION SLEEVE"
            },
            "WALGREENS": {
              "COLD WRAP REUSABLE": "COLD WRAP REUSABLE",
              "HOT & COLD BEAD BACK WRAP": "HOT & COLD BEAD BACK WRAP",
              "HOT & COLD BEAD EYE MASK": "HOT & COLD BEAD EYE MASK",
              "HOT & COLD BEAD NECK WRAP": "HOT & COLD BEAD NECK WRAP",
              "HOT & COLD BEAD OVAL PACK": "HOT & COLD BEAD OVAL PACK",
              "HOT & COLD CLAY BACK THERAPY": "HOT & COLD CLAY BACK THERAPY",
              "HOT & COLD CLAY MULTI THERAPY": "HOT & COLD CLAY MULTI THERAPY",
              "HOT & COLD NECK & SHOULDER PAD": "HOT & COLD NECK & SHOULDER PAD",
              "HOT & COLD WRAP/GEL PACK": "HOT & COLD WRAP/GEL PACK",
              "HOT/COLD PACK": "HOT/COLD PACK",
              "HOT/COLD PACK/STRAP": "HOT/COLD PACK/STRAP",
              "ICE & HEAT WRAP/GEL PACK": "ICE & HEAT WRAP/GEL PACK"
            }
          }
        },
        "Impotence Aids": {
          "Impotence Aids - Male": {
            "OWEN MUMFORD": {
              "RAPPORT RLS": "RAPPORT RLS",
              "RAPPORT VTD": "RAPPORT VTD"
            },
            "SUBURBAN OSTOMY": {
              "ERECAID CLASSIC": "ERECAID CLASSIC",
              "ERECAID ESTEEM": "ERECAID ESTEEM"
            }
          },
          "Sexual Dysfunction Devices - Female": {
            "UROMETRICS INC": {
              "EROS-CTD": "EROS-CTD"
            }
          }
        },
        "Infant Care Products": {
          "Diapers": {
            "ATTENDS HEALTHCARE PRODUCTS": {
              "COMFEES DRI NITE JR YOUTH PANT": "COMFEES DRI NITE JR YOUTH PANT",
              "COMFEES GIRLS TRAIN PANTS 2-3T": "COMFEES GIRLS TRAIN PANTS 2-3T",
              "COMFEES PREMIUM DIAPERS SIZE 1": "COMFEES PREMIUM DIAPERS SIZE 1",
              "COMFEES PREMIUM DIAPERS SIZE 2": "COMFEES PREMIUM DIAPERS SIZE 2",
              "COMFEES PREMIUM DIAPERS SIZE 3": "COMFEES PREMIUM DIAPERS SIZE 3",
              "COMFEES PREMIUM DIAPERS SIZE 4": "COMFEES PREMIUM DIAPERS SIZE 4",
              "COMFEES PREMIUM DIAPERS SIZE 5": "COMFEES PREMIUM DIAPERS SIZE 5",
              "COMFEES PREMIUM DIAPERS SIZE 6": "COMFEES PREMIUM DIAPERS SIZE 6",
              "COMFEES PREMIUM DIAPERS SIZE 7": "COMFEES PREMIUM DIAPERS SIZE 7",
              "COMFEES PREMIUM DIAPERS SIZENB": "COMFEES PREMIUM DIAPERS SIZENB",
              "COMFEES TRAINING PANTS 2T-3T": "COMFEES TRAINING PANTS 2T-3T",
              "COMFEES TRAINING PANTS 3T-4T": "COMFEES TRAINING PANTS 3T-4T",
              "COMFEES TRAINING PANTS 4T-5T": "COMFEES TRAINING PANTS 4T-5T"
            },
            "CHAIN DRUG CONSORTIUM": {
              "BABY DARLINGS SIZE 2": "BABY DARLINGS SIZE 2",
              "BABY DARLINGS SIZE 3": "BABY DARLINGS SIZE 3",
              "BABY DARLINGS SIZE 4": "BABY DARLINGS SIZE 4",
              "BABY DARLINGS SIZE 5": "BABY DARLINGS SIZE 5",
              "BABY DARLINGS SIZE 6": "BABY DARLINGS SIZE 6",
              "LITTLE DARLINGS TRAINING PANTS": "LITTLE DARLINGS TRAINING PANTS",
              "TRAINING PANTS UNISEX 2T/3T": "TRAINING PANTS UNISEX 2T/3T",
              "TRAINING PANTS UNISEX 3T/4T": "TRAINING PANTS UNISEX 3T/4T",
              "TRAINING PANTS UNISEX 4T/5T": "TRAINING PANTS UNISEX 4T/5T"
            },
            "COVIDIEN MEDICAL SUPPLIES": {
              "CURITY BABY DIAPERS": "CURITY BABY DIAPERS",
              "CURITY TRAINING PANTS": "CURITY TRAINING PANTS",
              "YOUTH PANTS UNISEX XL": "YOUTH PANTS UNISEX XL"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS I AM A CAREFREE KID 4T-5T": "CVS I AM A CAREFREE KID 4T-5T",
              "CVS I CRAWL AND SCOOT SIZE 3": "CVS I CRAWL AND SCOOT SIZE 3",
              "CVS I LIKE TO PLAY SIZE 5": "CVS I LIKE TO PLAY SIZE 5",
              "CVS I LOVE TO CUDDLE SIZE 1": "CVS I LOVE TO CUDDLE SIZE 1",
              "CVS I LOVE TO DANCE SIZE 4T-5T": "CVS I LOVE TO DANCE SIZE 4T-5T",
              "CVS I LOVE TO ROCK SIZE 3T-4T": "CVS I LOVE TO ROCK SIZE 3T-4T",
              "CVS I LOVE TO RUN SIZE 6": "CVS I LOVE TO RUN SIZE 6",
              "CVS I PLAY SPORTS SIZE 3T-4T": "CVS I PLAY SPORTS SIZE 3T-4T",
              "CVS I PLAYED ALL DAY BOYS L/XL": "CVS I PLAYED ALL DAY BOYS L/XL",
              "CVS I PLAYED ALL DAY L/XL": "CVS I PLAYED ALL DAY L/XL",
              "CVS I TUCK MYSELF IN S/M": "CVS I TUCK MYSELF IN S/M",
              "CVS IM A BUSY BABY SIZE 2": "CVS IM A BUSY BABY SIZE 2",
              "CVS IM A CAREFREE KID SZ 4T-5T": "CVS IM A CAREFREE KID SZ 4T-5T",
              "CVS IM ON THE MOVE SIZE 3": "CVS IM ON THE MOVE SIZE 3",
              "CVS IM ON THE MOVE SIZE 4": "CVS IM ON THE MOVE SIZE 4",
              "CVS ITS TIME TO SLEEP SIZE 3": "CVS ITS TIME TO SLEEP SIZE 3",
              "CVS ITS TIME TO SLEEP SIZE 4": "CVS ITS TIME TO SLEEP SIZE 4",
              "CVS ITS TIME TO SLEEP SIZE 5": "CVS ITS TIME TO SLEEP SIZE 5",
              "CVS ITS TIME TO SLEEP SMARTFIT": "CVS ITS TIME TO SLEEP SMARTFIT",
              "CVS KIDPANT BOYS LARGE": "CVS KIDPANT BOYS LARGE",
              "CVS KIDPANT BOYS MEDIUM": "CVS KIDPANT BOYS MEDIUM",
              "CVS KIDPANT GIRLS MEDIUM": "CVS KIDPANT GIRLS MEDIUM",
              "CVS SLEEP COMFORTS UNISEX L/XL": "CVS SLEEP COMFORTS UNISEX L/XL",
              "CVS SLEEP COMFORTS UNISEX S/M": "CVS SLEEP COMFORTS UNISEX S/M",
              "CVS TRAINING PANTS BOYS 4T-5T": "CVS TRAINING PANTS BOYS 4T-5T",
              "CVS TRAINING PANTS GIRLS 3T-4T": "CVS TRAINING PANTS GIRLS 3T-4T",
              "CVS TRAINING PANTS GIRLS 4T-5T": "CVS TRAINING PANTS GIRLS 4T-5T"
            },
            "EQUALINE": {
              "EQL BABY BASICS SIZE 6/XXL": "EQL BABY BASICS SIZE 6/XXL",
              "EQL BASICS FOR KIDS TRAIN PANT": "EQL BASICS FOR KIDS TRAIN PANT",
              "EQL SLEEP PANTS OVERNIGHT LG": "EQL SLEEP PANTS OVERNIGHT LG",
              "EQL SLEEP PANTS OVERNIGHT XL": "EQL SLEEP PANTS OVERNIGHT XL"
            },
            "FIRST QUALITY PRODUCTS": {
              "CUTIE PANTS-BOYS SIZE 2T-3T": "CUTIE PANTS-BOYS SIZE 2T-3T",
              "CUTIE PANTS-BOYS SIZE 3T-4T": "CUTIE PANTS-BOYS SIZE 3T-4T",
              "CUTIE PANTS-BOYS SIZE 4T-5T": "CUTIE PANTS-BOYS SIZE 4T-5T",
              "CUTIE PANTS-GIRLS SIZE 2T-3T": "CUTIE PANTS-GIRLS SIZE 2T-3T",
              "CUTIE PANTS-GIRLS SIZE 3T-4T": "CUTIE PANTS-GIRLS SIZE 3T-4T",
              "CUTIE PANTS-GIRLS SIZE 4T-5T": "CUTIE PANTS-GIRLS SIZE 4T-5T",
              "CUTIES COMPLETE CARE NEWBORN": "CUTIES COMPLETE CARE NEWBORN",
              "CUTIES COMPLETE CARE SIZE 1": "CUTIES COMPLETE CARE SIZE 1",
              "CUTIES COMPLETE CARE SIZE 2": "CUTIES COMPLETE CARE SIZE 2",
              "CUTIES COMPLETE CARE SIZE 3": "CUTIES COMPLETE CARE SIZE 3",
              "CUTIES COMPLETE CARE SIZE 4": "CUTIES COMPLETE CARE SIZE 4",
              "CUTIES COMPLETE CARE SIZE 5": "CUTIES COMPLETE CARE SIZE 5",
              "CUTIES COMPLETE CARE SIZE 6": "CUTIES COMPLETE CARE SIZE 6",
              "CUTIES COMPLETE CARE SIZE 7": "CUTIES COMPLETE CARE SIZE 7",
              "CUTIES NEWBORN": "CUTIES NEWBORN",
              "CUTIES SIZE 1": "CUTIES SIZE 1",
              "CUTIES SIZE 2": "CUTIES SIZE 2",
              "CUTIES SIZE 3": "CUTIES SIZE 3",
              "CUTIES SIZE 4": "CUTIES SIZE 4",
              "CUTIES SIZE 5": "CUTIES SIZE 5",
              "CUTIES SIZE 6": "CUTIES SIZE 6",
              "CUTIES SIZE 7": "CUTIES SIZE 7",
              "FQ CUTIES BABY DIAPERS LARGE": "FQ CUTIES BABY DIAPERS LARGE",
              "FQ CUTIES BABY DIAPERS MEDIUM": "FQ CUTIES BABY DIAPERS MEDIUM",
              "FQ CUTIES BABY DIAPERS XL": "FQ CUTIES BABY DIAPERS XL",
              "SLEEP OVERS LARGE/X-LARGE": "SLEEP OVERS LARGE/X-LARGE",
              "SLEEP OVERS SMALL/MEDIUM": "SLEEP OVERS SMALL/MEDIUM",
              "SLEEP OVERS YOUTH PANTS XL": "SLEEP OVERS YOUTH PANTS XL",
              "WONDERPANTS UNISEX LG 3T-4T": "WONDERPANTS UNISEX LG 3T-4T",
              "WONDERPANTS UNISEX MED 2T-3T": "WONDERPANTS UNISEX MED 2T-3T",
              "WONDERPANTS UNISEX XL 4T-5T": "WONDERPANTS UNISEX XL 4T-5T"
            },
            "G. HIRSCH & CO.": {
              "SECURE BOY BABY DIAPERS": "SECURE BOY BABY DIAPERS",
              "SECURE BOY TRAINING PANTS": "SECURE BOY TRAINING PANTS",
              "SECURE GIRL BABY DIAPERS": "SECURE GIRL BABY DIAPERS",
              "SECURE GIRL TRAINING PANTS": "SECURE GIRL TRAINING PANTS"
            },
            "HUMANICARE INTERNATIONAL": {
              "ALL-DRY SIZE 3": "ALL-DRY SIZE 3",
              "ALL-DRY SIZE 4": "ALL-DRY SIZE 4",
              "ALL-DRY SIZE 5": "ALL-DRY SIZE 5",
              "ALL-DRY SIZE 6": "ALL-DRY SIZE 6",
              "PREVENT PLUS BOYS": "PREVENT PLUS BOYS",
              "PREVENT PLUS GIRLS": "PREVENT PLUS GIRLS",
              "PREVENT PLUS SUPER TRAINING": "PREVENT PLUS SUPER TRAINING"
            },
            "KIMBERLY-CLARK": {
              "GOODNITES BED MATS": "GOODNITES BED MATS",
              "GOODNITES BEDTIME GIRLS S/M": "GOODNITES BEDTIME GIRLS S/M",
              "GOODNITES BEDTIME UNDERWEAR": "GOODNITES BEDTIME UNDERWEAR",
              "GOODNITES NIGHTTIME UNDERWEAR": "GOODNITES NIGHTTIME UNDERWEAR",
              "GOODNITES SLEEP BOXER BOY L/XL": "GOODNITES SLEEP BOXER BOY L/XL",
              "GOODNITES SLEEP BOXER BOYS S/M": "GOODNITES SLEEP BOXER BOYS S/M",
              "GOODNITES SLEEP SHORT GIRL S/M": "GOODNITES SLEEP SHORT GIRL S/M",
              "GOODNITES SLEEP SHORT GRL L/XL": "GOODNITES SLEEP SHORT GRL L/XL",
              "GOODNITES TRUFIT": "GOODNITES TRUFIT",
              "GOODNITES TRUFIT BOYS L/XL": "GOODNITES TRUFIT BOYS L/XL",
              "GOODNITES TRUFIT GIRLS L/XL": "GOODNITES TRUFIT GIRLS L/XL",
              "GOODNITES UNDERPANTS BOYS L/XL": "GOODNITES UNDERPANTS BOYS L/XL",
              "GOODNITES UNDERPANTS BOYS S/M": "GOODNITES UNDERPANTS BOYS S/M",
              "GOODNITES UNDERPANTS GIRL L/XL": "GOODNITES UNDERPANTS GIRL L/XL",
              "GOODNITES UNDERPANTS GIRLS S/M": "GOODNITES UNDERPANTS GIRLS S/M",
              "GOODNITES UNDERWEAR BOYS L/XL": "GOODNITES UNDERWEAR BOYS L/XL",
              "GOODNITES UNDERWEAR BOYS S/M": "GOODNITES UNDERWEAR BOYS S/M",
              "GOODNITES UNDERWEAR GIRL L/XL": "GOODNITES UNDERWEAR GIRL L/XL",
              "GOODNITES UNDERWEAR GIRL S/M": "GOODNITES UNDERWEAR GIRL S/M",
              "GOODNITES YOUTH PANTS BOYS L/X": "GOODNITES YOUTH PANTS BOYS L/X",
              "GOODNITES YOUTH PANTS BOYS S/M": "GOODNITES YOUTH PANTS BOYS S/M",
              "GOODNITES YOUTH PANTS GIRL L/X": "GOODNITES YOUTH PANTS GIRL L/X",
              "GOODNITES YOUTH PANTS GIRL S/M": "GOODNITES YOUTH PANTS GIRL S/M",
              "HUGGIES CHANGING PADS": "HUGGIES CHANGING PADS",
              "HUGGIES CONVERTIBLES SIZE 4": "HUGGIES CONVERTIBLES SIZE 4",
              "HUGGIES LITTLE MOVERS SIZE 3": "HUGGIES LITTLE MOVERS SIZE 3",
              "HUGGIES LITTLE MOVERS SIZE 4": "HUGGIES LITTLE MOVERS SIZE 4",
              "HUGGIES LITTLE MOVERS SIZE 5": "HUGGIES LITTLE MOVERS SIZE 5",
              "HUGGIES LITTLE MOVERS SIZE 6": "HUGGIES LITTLE MOVERS SIZE 6",
              "HUGGIES LITTLE MOVERS STEP 3": "HUGGIES LITTLE MOVERS STEP 3",
              "HUGGIES LITTLE MOVERS STEP 5": "HUGGIES LITTLE MOVERS STEP 5",
              "HUGGIES LITTLE MOVERS STEP 6": "HUGGIES LITTLE MOVERS STEP 6",
              "HUGGIES LITTLE SNUGGLERS": "HUGGIES LITTLE SNUGGLERS",
              "HUGGIES LITTLE SNUGGLERS SZ 1": "HUGGIES LITTLE SNUGGLERS SZ 1",
              "HUGGIES LITTLE SNUGGLERS SZ 2": "HUGGIES LITTLE SNUGGLERS SZ 2",
              "HUGGIES LITTLE SNUGGLERS SZ3": "HUGGIES LITTLE SNUGGLERS SZ3",
              "HUGGIES LITTLE SWIMMERS LARGE": "HUGGIES LITTLE SWIMMERS LARGE",
              "HUGGIES LITTLE SWIMMERS MEDIUM": "HUGGIES LITTLE SWIMMERS MEDIUM",
              "HUGGIES LITTLE SWIMMERS SMALL": "HUGGIES LITTLE SWIMMERS SMALL",
              "HUGGIES OVERNITES SIZE 5": "HUGGIES OVERNITES SIZE 5",
              "HUGGIES OVERNITES SIZE 6": "HUGGIES OVERNITES SIZE 6",
              "HUGGIES OVERNITES STEP 3": "HUGGIES OVERNITES STEP 3",
              "HUGGIES OVERNITES STEP 4": "HUGGIES OVERNITES STEP 4",
              "HUGGIES OVERNITES STEP 5": "HUGGIES OVERNITES STEP 5",
              "HUGGIES OVERNITES STEP 6": "HUGGIES OVERNITES STEP 6",
              "HUGGIES PULL-UPS": "HUGGIES PULL-UPS",
              "HUGGIES PULL-UPS 2T-3T": "HUGGIES PULL-UPS 2T-3T",
              "HUGGIES PULL-UPS 3T-4T": "HUGGIES PULL-UPS 3T-4T",
              "HUGGIES PULL-UPS 4T-5T": "HUGGIES PULL-UPS 4T-5T",
              "HUGGIES PULL-UPS BOYS 2T-3T": "HUGGIES PULL-UPS BOYS 2T-3T",
              "HUGGIES PULL-UPS BOYS 3T-4T": "HUGGIES PULL-UPS BOYS 3T-4T",
              "HUGGIES PULL-UPS BOYS 4T-5T": "HUGGIES PULL-UPS BOYS 4T-5T",
              "HUGGIES PULL-UPS GIRLS 2T-3T": "HUGGIES PULL-UPS GIRLS 2T-3T",
              "HUGGIES PULL-UPS GIRLS 3T-4T": "HUGGIES PULL-UPS GIRLS 3T-4T",
              "HUGGIES PULL-UPS GIRLS 4T-5T": "HUGGIES PULL-UPS GIRLS 4T-5T",
              "HUGGIES PULL-UPS PROTECT 3T-4T": "HUGGIES PULL-UPS PROTECT 3T-4T",
              "HUGGIES PULL-UPS PROTECT 4T-5T": "HUGGIES PULL-UPS PROTECT 4T-5T",
              "HUGGIES PURE & NATURAL NEWBORN": "HUGGIES PURE & NATURAL NEWBORN",
              "HUGGIES PURE & NATURAL SIZE 1": "HUGGIES PURE & NATURAL SIZE 1",
              "HUGGIES PURE & NATURAL SIZE 2": "HUGGIES PURE & NATURAL SIZE 2",
              "HUGGIES PURE & NATURAL SIZE 3": "HUGGIES PURE & NATURAL SIZE 3",
              "HUGGIES PURE & NATURAL SIZE 4": "HUGGIES PURE & NATURAL SIZE 4",
              "HUGGIES PURE & NATURAL SIZE 5": "HUGGIES PURE & NATURAL SIZE 5",
              "HUGGIES SNUG & DRY SIZE 4": "HUGGIES SNUG & DRY SIZE 4",
              "HUGGIES SNUG & DRY SIZE 6": "HUGGIES SNUG & DRY SIZE 6",
              "HUGGIES SNUG & DRY STEP 1": "HUGGIES SNUG & DRY STEP 1",
              "HUGGIES SNUG & DRY STEP 2": "HUGGIES SNUG & DRY STEP 2",
              "HUGGIES SNUG & DRY STEP 3": "HUGGIES SNUG & DRY STEP 3",
              "HUGGIES SNUG & DRY STEP 4": "HUGGIES SNUG & DRY STEP 4",
              "HUGGIES SNUG & DRY STEP 5": "HUGGIES SNUG & DRY STEP 5",
              "HUGGIES SNUG & DRY STEP 6": "HUGGIES SNUG & DRY STEP 6",
              "HUGGIES SOFT SKIN WASHCLOTHS": "HUGGIES SOFT SKIN WASHCLOTHS",
              "HUGGIES SUPREME GENTLE NEWBORN": "HUGGIES SUPREME GENTLE NEWBORN",
              "HUGGIES SUPREME GENTLE SIZE 1": "HUGGIES SUPREME GENTLE SIZE 1",
              "HUGGIES SUPREME GENTLE SIZE 2": "HUGGIES SUPREME GENTLE SIZE 2",
              "HUGGIES SUPREME NAT FIT SIZE 3": "HUGGIES SUPREME NAT FIT SIZE 3",
              "HUGGIES SUPREME NAT FIT SIZE 4": "HUGGIES SUPREME NAT FIT SIZE 4",
              "HUGGIES SUPREME NAT FIT SIZE 5": "HUGGIES SUPREME NAT FIT SIZE 5",
              "HUGGIES SUPREME NAT FIT SIZE 6": "HUGGIES SUPREME NAT FIT SIZE 6",
              "HUGGIES SUPREME STEP 5": "HUGGIES SUPREME STEP 5",
              "HUGGIES ULTRATRIM PREEMIE": "HUGGIES ULTRATRIM PREEMIE",
              "HUGGIES ULTRATRIM STEP 1": "HUGGIES ULTRATRIM STEP 1",
              "HUGGIES ULTRATRIM STEP 1/2": "HUGGIES ULTRATRIM STEP 1/2",
              "HUGGIES ULTRATRIM STEP 2": "HUGGIES ULTRATRIM STEP 2",
              "HUGGIES ULTRATRIM STEP 3": "HUGGIES ULTRATRIM STEP 3",
              "HUGGIES ULTRATRIM STEP 4": "HUGGIES ULTRATRIM STEP 4",
              "HUGGIES ULTRATRIM STEP 5": "HUGGIES ULTRATRIM STEP 5",
              "HUGGIES ULTRATRIM STEP 6": "HUGGIES ULTRATRIM STEP 6",
              "HUGGIES WASHCLOTHS": "HUGGIES WASHCLOTHS"
            },
            "P & G HEALTH": {
              "LUVS ULTRA LEAKGUARDS SIZE 5": "LUVS ULTRA LEAKGUARDS SIZE 5",
              "PAMPERS BABY DRY SIZE 6": "PAMPERS BABY DRY SIZE 6",
              "PAMPERS BABY-DRY SIZE 4": "PAMPERS BABY-DRY SIZE 4",
              "PAMPERS BABY-DRY SIZE 5": "PAMPERS BABY-DRY SIZE 5",
              "PAMPERS CRUISERS SIZE 5": "PAMPERS CRUISERS SIZE 5",
              "PAMPERS CRUISERS SIZE 6": "PAMPERS CRUISERS SIZE 6",
              "PAMPERS SWADDLERS SIZE 2": "PAMPERS SWADDLERS SIZE 2",
              "PAMPERS SWADDLERS SIZE 4": "PAMPERS SWADDLERS SIZE 4",
              "PAMPERS SWADDLERS SIZE 6": "PAMPERS SWADDLERS SIZE 6"
            },
            "P & G PAPER PRODUCTS": {
              "LUVS SIZE 3": "LUVS SIZE 3",
              "LUVS SIZE 4": "LUVS SIZE 4",
              "LUVS SIZE 5": "LUVS SIZE 5",
              "LUVS SIZE 6": "LUVS SIZE 6",
              "LUVS ULTRA LEAKGUARDS NEWBORN": "LUVS ULTRA LEAKGUARDS NEWBORN",
              "LUVS ULTRA LEAKGUARDS SIZE 1": "LUVS ULTRA LEAKGUARDS SIZE 1",
              "LUVS ULTRA LEAKGUARDS SIZE 2": "LUVS ULTRA LEAKGUARDS SIZE 2",
              "LUVS ULTRA LEAKGUARDS SIZE 3": "LUVS ULTRA LEAKGUARDS SIZE 3",
              "LUVS ULTRA LEAKGUARDS SIZE 4": "LUVS ULTRA LEAKGUARDS SIZE 4",
              "LUVS ULTRA LEAKGUARDS SIZE 5": "LUVS ULTRA LEAKGUARDS SIZE 5",
              "LUVS ULTRA LEAKGUARDS SIZE 6": "LUVS ULTRA LEAKGUARDS SIZE 6",
              "PAMPERS BABY DRY SIZE 3": "PAMPERS BABY DRY SIZE 3",
              "PAMPERS BABY DRY SIZE 5": "PAMPERS BABY DRY SIZE 5",
              "PAMPERS BABY DRY SIZE 6": "PAMPERS BABY DRY SIZE 6",
              "PAMPERS BABY-DRY NEWBORN": "PAMPERS BABY-DRY NEWBORN",
              "PAMPERS BABY-DRY SIZE 1": "PAMPERS BABY-DRY SIZE 1",
              "PAMPERS BABY-DRY SIZE 2": "PAMPERS BABY-DRY SIZE 2",
              "PAMPERS BABY-DRY SIZE 3": "PAMPERS BABY-DRY SIZE 3",
              "PAMPERS BABY-DRY SIZE 4": "PAMPERS BABY-DRY SIZE 4",
              "PAMPERS BABY-DRY SIZE 5": "PAMPERS BABY-DRY SIZE 5",
              "PAMPERS BABY-DRY SIZE 6": "PAMPERS BABY-DRY SIZE 6",
              "PAMPERS CRUISERS SIZE 3": "PAMPERS CRUISERS SIZE 3",
              "PAMPERS CRUISERS SIZE 4": "PAMPERS CRUISERS SIZE 4",
              "PAMPERS CRUISERS SIZE 5": "PAMPERS CRUISERS SIZE 5",
              "PAMPERS CRUISERS SIZE 6": "PAMPERS CRUISERS SIZE 6",
              "PAMPERS CRUISERS SIZE 7": "PAMPERS CRUISERS SIZE 7",
              "PAMPERS EASY UPS BOYS SIZE 4": "PAMPERS EASY UPS BOYS SIZE 4",
              "PAMPERS EASY UPS BOYS SIZE 5": "PAMPERS EASY UPS BOYS SIZE 5",
              "PAMPERS EASY UPS BOYS SZ 2T-3T": "PAMPERS EASY UPS BOYS SZ 2T-3T",
              "PAMPERS EASY UPS BOYS SZ 3T-4T": "PAMPERS EASY UPS BOYS SZ 3T-4T",
              "PAMPERS EASY UPS BOYS SZ 4T-5T": "PAMPERS EASY UPS BOYS SZ 4T-5T",
              "PAMPERS EASY UPS GIRL SZ 2T-3T": "PAMPERS EASY UPS GIRL SZ 2T-3T",
              "PAMPERS EASY UPS GIRL SZ 3T-4T": "PAMPERS EASY UPS GIRL SZ 3T-4T",
              "PAMPERS EASY UPS GIRL SZ 4T-5T": "PAMPERS EASY UPS GIRL SZ 4T-5T",
              "PAMPERS EASY UPS GIRLS SIZE 4": "PAMPERS EASY UPS GIRLS SIZE 4",
              "PAMPERS EXTRA PROTECTION SZ 3": "PAMPERS EXTRA PROTECTION SZ 3",
              "PAMPERS EXTRA PROTECTION SZ 4": "PAMPERS EXTRA PROTECTION SZ 4",
              "PAMPERS EXTRA PROTECTION SZ 5": "PAMPERS EXTRA PROTECTION SZ 5",
              "PAMPERS EXTRA PROTECTION SZ 6": "PAMPERS EXTRA PROTECTION SZ 6",
              "PAMPERS PREMIE": "PAMPERS PREMIE",
              "PAMPERS SPLASHERS SIZE 3-4": "PAMPERS SPLASHERS SIZE 3-4",
              "PAMPERS SPLASHERS SIZE 5": "PAMPERS SPLASHERS SIZE 5",
              "PAMPERS SPLASHERS SIZE 6": "PAMPERS SPLASHERS SIZE 6",
              "PAMPERS SWADDLERS NEWBORN": "PAMPERS SWADDLERS NEWBORN",
              "PAMPERS SWADDLERS PREEMIE": "PAMPERS SWADDLERS PREEMIE",
              "PAMPERS SWADDLERS SENS SZ 1": "PAMPERS SWADDLERS SENS SZ 1",
              "PAMPERS SWADDLERS SENS SZ 2": "PAMPERS SWADDLERS SENS SZ 2",
              "PAMPERS SWADDLERS SENS SZ 3": "PAMPERS SWADDLERS SENS SZ 3",
              "PAMPERS SWADDLERS SENS SZ 4": "PAMPERS SWADDLERS SENS SZ 4",
              "PAMPERS SWADDLERS SENSITIVE NB": "PAMPERS SWADDLERS SENSITIVE NB",
              "PAMPERS SWADDLERS SIZE 1": "PAMPERS SWADDLERS SIZE 1",
              "PAMPERS SWADDLERS SIZE 2": "PAMPERS SWADDLERS SIZE 2",
              "PAMPERS SWADDLERS SIZE 3": "PAMPERS SWADDLERS SIZE 3",
              "PAMPERS SWADDLERS SIZE 4": "PAMPERS SWADDLERS SIZE 4",
              "PAMPERS UNDERJAMS BOYS L/XL": "PAMPERS UNDERJAMS BOYS L/XL",
              "PAMPERS UNDERJAMS BOYS S/M": "PAMPERS UNDERJAMS BOYS S/M",
              "PAMPERS UNDERJAMS GIRLS L/XL": "PAMPERS UNDERJAMS GIRLS L/XL",
              "PAMPERS UNDERJAMS GIRLS S/M": "PAMPERS UNDERJAMS GIRLS S/M",
              "PAMPERS UNDERJAMS L/XL": "PAMPERS UNDERJAMS L/XL",
              "PAMPERS UNDERJAMS S/M": "PAMPERS UNDERJAMS S/M",
              "PAMPERS UNISEX BABY-DRY": "PAMPERS UNISEX BABY-DRY"
            },
            "PRINCIPLE BUSINESS ENTERPRISES": {
              "SWIMMATES DISPOSABLE SWIMWEAR": "SWIMMATES DISPOSABLE SWIMWEAR"
            },
            "RITE AID CORPORATION": {
              "RA DIAPERS ULTRA SOFT UNISEX": "RA DIAPERS ULTRA SOFT UNISEX",
              "RA KID PANTS BOYS": "RA KID PANTS BOYS",
              "RA KID PANTS GIRLS": "RA KID PANTS GIRLS",
              "RA KID PANTS GIRLS MEDIUM": "RA KID PANTS GIRLS MEDIUM",
              "RA OVER NITES LARGE/XLARGE": "RA OVER NITES LARGE/XLARGE",
              "RA OVER NITES MEDIUM": "RA OVER NITES MEDIUM",
              "RA TRAINING PANTS SIZE 4 BOYS": "RA TRAINING PANTS SIZE 4 BOYS",
              "RA TUGABOOS BOYS 2T-3T": "RA TUGABOOS BOYS 2T-3T",
              "RA TUGABOOS BOYS 3T-4T": "RA TUGABOOS BOYS 3T-4T",
              "RA TUGABOOS BOYS 4T-5T": "RA TUGABOOS BOYS 4T-5T",
              "RA TUGABOOS DIAPERS NEWBORN": "RA TUGABOOS DIAPERS NEWBORN",
              "RA TUGABOOS DIAPERS SIZE 1": "RA TUGABOOS DIAPERS SIZE 1",
              "RA TUGABOOS DIAPERS SIZE 2": "RA TUGABOOS DIAPERS SIZE 2",
              "RA TUGABOOS DIAPERS SIZE 3": "RA TUGABOOS DIAPERS SIZE 3",
              "RA TUGABOOS DIAPERS SIZE 4": "RA TUGABOOS DIAPERS SIZE 4",
              "RA TUGABOOS DIAPERS SIZE 5": "RA TUGABOOS DIAPERS SIZE 5",
              "RA TUGABOOS DIAPERS SIZE 6": "RA TUGABOOS DIAPERS SIZE 6",
              "RA TUGABOOS GIRLS 2T-3T": "RA TUGABOOS GIRLS 2T-3T",
              "RA TUGABOOS GIRLS 3T-4T": "RA TUGABOOS GIRLS 3T-4T",
              "RA TUGABOOS GIRLS 4T-5T": "RA TUGABOOS GIRLS 4T-5T",
              "RA TUGABOOS OVERNIGHTS UNISEX": "RA TUGABOOS OVERNIGHTS UNISEX",
              "RA ULTRAFITS DIAPER SIZE 1/SM": "RA ULTRAFITS DIAPER SIZE 1/SM",
              "RA ULTRAFITS DIAPER SIZE 2/S-M": "RA ULTRAFITS DIAPER SIZE 2/S-M",
              "RA ULTRAFITS DIAPER SIZE 3": "RA ULTRAFITS DIAPER SIZE 3",
              "RA ULTRAFITS DIAPER SIZE 3/MED": "RA ULTRAFITS DIAPER SIZE 3/MED",
              "RA ULTRAFITS DIAPER SIZE 4": "RA ULTRAFITS DIAPER SIZE 4",
              "RA ULTRAFITS DIAPER SIZE 4/L": "RA ULTRAFITS DIAPER SIZE 4/L",
              "RA ULTRAFITS DIAPER SIZE 5": "RA ULTRAFITS DIAPER SIZE 5",
              "RA ULTRAFITS DIAPER SIZE 5/XL": "RA ULTRAFITS DIAPER SIZE 5/XL",
              "RA ULTRAFITS DIAPER SIZE 6/XXL": "RA ULTRAFITS DIAPER SIZE 6/XXL"
            },
            "TARGET": {
              "TGT BABY DIAPERS NEWBORN": "TGT BABY DIAPERS NEWBORN",
              "TGT BABY DIAPERS SIZE 1": "TGT BABY DIAPERS SIZE 1",
              "TGT BABY DIAPERS SIZE 2": "TGT BABY DIAPERS SIZE 2",
              "TGT BABY DIAPERS SIZE 3": "TGT BABY DIAPERS SIZE 3",
              "TGT BABY DIAPERS SIZE 4": "TGT BABY DIAPERS SIZE 4",
              "TGT BABY DIAPERS SIZE 5": "TGT BABY DIAPERS SIZE 5",
              "TGT BABY DIAPERS SIZE 6": "TGT BABY DIAPERS SIZE 6"
            },
            "WALGREENS": {
              "COMFORT-STRETCH CLOTH NEWBORN": "COMFORT-STRETCH CLOTH NEWBORN",
              "COMFORT-STRETCH CLOTH SIZE 4": "COMFORT-STRETCH CLOTH SIZE 4",
              "COMFORT-STRETCH CLOTH SIZE 6": "COMFORT-STRETCH CLOTH SIZE 6",
              "COMFORT-STRETCH PREMIUM SIZE 3": "COMFORT-STRETCH PREMIUM SIZE 3",
              "COMFORT-STRETCH PREMIUM SIZE 4": "COMFORT-STRETCH PREMIUM SIZE 4",
              "COMFORT-STRETCH PREMIUM SIZE 5": "COMFORT-STRETCH PREMIUM SIZE 5",
              "COMFORT-STRETCH PREMIUM SIZE 6": "COMFORT-STRETCH PREMIUM SIZE 6",
              "COMFORT-STRETCH SIZE 1": "COMFORT-STRETCH SIZE 1",
              "COMFORT-STRETCH SIZE 2": "COMFORT-STRETCH SIZE 2",
              "COMFORT-STRETCH SIZE 3": "COMFORT-STRETCH SIZE 3",
              "COMFORT-STRETCH SIZE 4": "COMFORT-STRETCH SIZE 4",
              "COMFORT-STRETCH SIZE 5": "COMFORT-STRETCH SIZE 5",
              "COMFORT-STRETCH SIZE 6": "COMFORT-STRETCH SIZE 6",
              "COMFORT-STRETCH TRAINING PANTS": "COMFORT-STRETCH TRAINING PANTS",
              "LAND BEFORE TIME DIAPER 2T/3T": "LAND BEFORE TIME DIAPER 2T/3T",
              "LAND BEFORE TIME DIAPER 3T/4T": "LAND BEFORE TIME DIAPER 3T/4T",
              "LAND BEFORE TIME DIAPER SIZE 1": "LAND BEFORE TIME DIAPER SIZE 1",
              "LAND BEFORE TIME DIAPER SIZE 3": "LAND BEFORE TIME DIAPER SIZE 3",
              "LAND BEFORE TIME DIAPER SIZE 4": "LAND BEFORE TIME DIAPER SIZE 4",
              "LAND BEFORE TIME DIAPER SIZE 5": "LAND BEFORE TIME DIAPER SIZE 5",
              "LAND BEFORE TIME DIAPER SIZE 6": "LAND BEFORE TIME DIAPER SIZE 6",
              "PREMIUM BABY DIAPERS NEWBORN": "PREMIUM BABY DIAPERS NEWBORN",
              "PREMIUM BABY DIAPERS SIZE 1": "PREMIUM BABY DIAPERS SIZE 1",
              "PREMIUM BABY DIAPERS SIZE 2": "PREMIUM BABY DIAPERS SIZE 2",
              "PREMIUM BABY DIAPERS SIZE 3": "PREMIUM BABY DIAPERS SIZE 3",
              "PREMIUM BABY DIAPERS SIZE 4": "PREMIUM BABY DIAPERS SIZE 4",
              "PREMIUM BABY DIAPERS SIZE 5": "PREMIUM BABY DIAPERS SIZE 5",
              "PREMIUM BABY DIAPERS SIZE 6": "PREMIUM BABY DIAPERS SIZE 6",
              "PREMIUM COMFORT-STRETCH 2T/3T": "PREMIUM COMFORT-STRETCH 2T/3T",
              "PREMIUM COMFORT-STRETCH 3T/4T": "PREMIUM COMFORT-STRETCH 3T/4T",
              "PREMIUM COMFORT-STRETCH 4T/5T": "PREMIUM COMFORT-STRETCH 4T/5T",
              "PREMIUM COMFORT-STRETCH SIZE 3": "PREMIUM COMFORT-STRETCH SIZE 3",
              "PREMIUM COMFORT-STRETCH SIZE 4": "PREMIUM COMFORT-STRETCH SIZE 4",
              "PREMIUM COMFORT-STRETCH SIZE 5": "PREMIUM COMFORT-STRETCH SIZE 5",
              "PREMIUM COMFORT-STRETCH SIZE 6": "PREMIUM COMFORT-STRETCH SIZE 6",
              "PREMIUM TRAINING PANTS 2T-3T": "PREMIUM TRAINING PANTS 2T-3T",
              "PREMIUM TRAINING PANTS 3T-4T": "PREMIUM TRAINING PANTS 3T-4T",
              "PREMIUM TRAINING PANTS 4T-5T": "PREMIUM TRAINING PANTS 4T-5T",
              "SLEEP PANTS FOR BOYS L/XL": "SLEEP PANTS FOR BOYS L/XL",
              "SLEEP PANTS FOR BOYS S/M": "SLEEP PANTS FOR BOYS S/M",
              "SLEEP PANTS FOR GIRLS L/XL": "SLEEP PANTS FOR GIRLS L/XL",
              "SLEEP PANTS FOR GIRLS S/M": "SLEEP PANTS FOR GIRLS S/M",
              "TRAINING PANTS HIS/HER XL": "TRAINING PANTS HIS/HER XL",
              "WELL BEGINNINGS SIZE 3": "WELL BEGINNINGS SIZE 3",
              "WELL BEGINNINGS SIZE 4": "WELL BEGINNINGS SIZE 4",
              "WELL BEGINNINGS SIZE 6": "WELL BEGINNINGS SIZE 6",
              "WELL BEGINNINGS SIZE 7": "WELL BEGINNINGS SIZE 7",
              "WELL BEGINNINGS SLEEP PANTS": "WELL BEGINNINGS SLEEP PANTS",
              "WELL BEGINNINGSTRAINING PANTS": "WELL BEGINNINGSTRAINING PANTS"
            },
            "WEGMANS": {
              "WEGMANS DIAPERS SIZE 1": "WEGMANS DIAPERS SIZE 1",
              "WEGMANS DIAPERS SIZE 2": "WEGMANS DIAPERS SIZE 2",
              "WEGMANS DIAPERS SIZE 3": "WEGMANS DIAPERS SIZE 3",
              "WEGMANS DIAPERS SIZE 4": "WEGMANS DIAPERS SIZE 4",
              "WEGMANS DIAPERS SIZE 5": "WEGMANS DIAPERS SIZE 5",
              "WEGMANS DIAPERS SIZE 6": "WEGMANS DIAPERS SIZE 6",
              "WEGMANS SUPREME DIAPERS SIZE 3": "WEGMANS SUPREME DIAPERS SIZE 3",
              "WEGMANS SUPREME DIAPERS SIZE 4": "WEGMANS SUPREME DIAPERS SIZE 4",
              "WEGMANS SUPREME DIAPERS SIZE 5": "WEGMANS SUPREME DIAPERS SIZE 5",
              "WEGMANS SUPREME DIAPERS SIZE 6": "WEGMANS SUPREME DIAPERS SIZE 6",
              "WEGMANS TRAINING PANTS 2T-3T": "WEGMANS TRAINING PANTS 2T-3T",
              "WEGMANS TRAINING PANTS 3T-4T": "WEGMANS TRAINING PANTS 3T-4T",
              "WEGMANS TRAINING PANTS 4T-5T": "WEGMANS TRAINING PANTS 4T-5T"
            }
          },
          "Feeding Supplies": {
            "AMEDA": {
              "AMEDA BREAST MILK STORAGE": "AMEDA BREAST MILK STORAGE",
              "AMEDA LOCKING RING/DISC": "AMEDA LOCKING RING/DISC",
              "AMEDA MILK STORAGE BAGS": "AMEDA MILK STORAGE BAGS",
              "AMEDA NIPPLE SHIELD": "AMEDA NIPPLE SHIELD",
              "AMEDA PURELY YOURS TOTE BAG": "AMEDA PURELY YOURS TOTE BAG",
              "AMEDA STORE N POUR STARTER": "AMEDA STORE N POUR STARTER",
              "AMEDA STORE N POUR STORAGE BAG": "AMEDA STORE N POUR STORAGE BAG"
            },
            "LANSINOH LABORATORIES": {
              "BREASTMILK STORAGE BAGS": "BREASTMILK STORAGE BAGS",
              "BREASTMILK STORAGE BOTTLES": "BREASTMILK STORAGE BOTTLES",
              "LANSINOH BREASTMILK STORAGEBAG": "LANSINOH BREASTMILK STORAGEBAG",
              "LANSINOH CONTACT NIPPLE SHIELD": "LANSINOH CONTACT NIPPLE SHIELD",
              "LANSINOH NATURALWAVE NIPPLES": "LANSINOH NATURALWAVE NIPPLES",
              "LANSINOH PRIMO-LACTO COLOSTRUM": "LANSINOH PRIMO-LACTO COLOSTRUM",
              "LANSINOH PRIMO-LACTO HAND EXPR": "LANSINOH PRIMO-LACTO HAND EXPR",
              "LANSINOH PRIMO-LACTO SLIP TIP": "LANSINOH PRIMO-LACTO SLIP TIP",
              "MOMMA BOTTLE 5OZ": "MOMMA BOTTLE 5OZ",
              "MOMMA BOTTLE 8OZ": "MOMMA BOTTLE 8OZ",
              "MOMMA NATURALWAVE/MEDIUM FLOW": "MOMMA NATURALWAVE/MEDIUM FLOW",
              "MOMMA NATURALWAVE/SLOW FLOW": "MOMMA NATURALWAVE/SLOW FLOW"
            },
            "M J NUTR": {
              "DISPOSABLE GRADUATED NURSER": "DISPOSABLE GRADUATED NURSER",
              "ENFAMIL CROSS-CUT TPE NIPPLE": "ENFAMIL CROSS-CUT TPE NIPPLE",
              "ENFAMIL NEONATAL TPE NIPPLE": "ENFAMIL NEONATAL TPE NIPPLE",
              "ENFAMIL SLOW-FLOW NIPPLE": "ENFAMIL SLOW-FLOW NIPPLE",
              "ENFAMIL STANDARD-FLOW NIPPLE": "ENFAMIL STANDARD-FLOW NIPPLE",
              "GRAD-U-FEED CAP": "GRAD-U-FEED CAP",
              "NUK NEWBORN ORTHODONTIC NIPPLE": "NUK NEWBORN ORTHODONTIC NIPPLE",
              "PLASTIC BOTTLE/NIPPLE": "PLASTIC BOTTLE/NIPPLE",
              "SNAPPIES STORAGE CONTAINERS": "SNAPPIES STORAGE CONTAINERS"
            },
            "NESTLE HEALTHCARE NUTRITION": {
              "GERBER GOOD START NURSER": "GERBER GOOD START NURSER",
              "GOOD START NURSER NIPPLES": "GOOD START NURSER NIPPLES",
              "GOOD START PREMATURE NIPPLES": "GOOD START PREMATURE NIPPLES",
              "NESTLE GOOD START DUAL FEEDER": "NESTLE GOOD START DUAL FEEDER",
              "NESTLE GOOD START PREM NIPPLE": "NESTLE GOOD START PREM NIPPLE"
            },
            "PHARMICS": {
              "O-CAL-ETTE NURSING CUP": "O-CAL-ETTE NURSING CUP"
            },
            "WALGREENS": {
              "FEEDING BOTTLES/STANDARD NECK": "FEEDING BOTTLES/STANDARD NECK"
            }
          },
          "Infant Care Products": {
            "AMERICAN SALES COMPANY": {
              "COTTONTAILS BABY COLD": "COTTONTAILS BABY COLD"
            },
            "BERGEN BRUNSWIG": {
              "GNP BABY WIPES": "GNP BABY WIPES"
            },
            "CHAIN DRUG CONSORTIUM": {
              "BABY DARLINGS BABY": "BABY DARLINGS BABY",
              "BABY DARLINGS WIPES": "BABY DARLINGS WIPES"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC BABY WIPES TRAV PACK/LOTION": "QC BABY WIPES TRAV PACK/LOTION",
              "QC BABY WIPES TRAVEL PACK": "QC BABY WIPES TRAVEL PACK",
              "QC BABY WIPES/LOTION": "QC BABY WIPES/LOTION"
            },
            "CMP PHARMA": {
              "DERMACLOUD": "DERMACLOUD"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS BABY SHAMPOO": "CVS BABY SHAMPOO",
              "CVS RASH GUARD CLEANSING WIPES": "CVS RASH GUARD CLEANSING WIPES",
              "CVS SOFT CLOTHS SUPREME": "CVS SOFT CLOTHS SUPREME"
            },
            "FIRST QUALITY PRODUCTS": {
              "CUTIES BABY WIPES/ALOE": "CUTIES BABY WIPES/ALOE",
              "CUTIES PREMIUM WIPES": "CUTIES PREMIUM WIPES",
              "CUTIES PREMIUM WIPES/SENSITIVE": "CUTIES PREMIUM WIPES/SENSITIVE",
              "CUTIES SENSITIVE WIPES": "CUTIES SENSITIVE WIPES"
            },
            "FLEET PHARMACEUTICALS": {
              "BOUDREAUXS BABY KISSES": "BOUDREAUXS BABY KISSES"
            },
            "J & J CONSUMER PRODUCTS": {
              "AVEENO BABY BATHTIME SOLUTIONS": "AVEENO BABY BATHTIME SOLUTIONS",
              "AVEENO BABY ESSENTIAL CARE": "AVEENO BABY ESSENTIAL CARE",
              "AVEENO BABY GENTLE CONDITION": "AVEENO BABY GENTLE CONDITION",
              "AVEENO BABY SENSITIVE WIPES": "AVEENO BABY SENSITIVE WIPES",
              "AVEENO BABY SHAMPOO/CONDITION": "AVEENO BABY SHAMPOO/CONDITION",
              "AVEENO BABY SOOTHING RELIEF": "AVEENO BABY SOOTHING RELIEF",
              "AVEENO BABY SOOTHING WASH": "AVEENO BABY SOOTHING WASH",
              "AVEENO BABY WASH & SHAMPOO": "AVEENO BABY WASH & SHAMPOO",
              "JOHNSONS BABY": "JOHNSONS BABY",
              "JOHNSONS BABY BATH & WASH": "JOHNSONS BABY BATH & WASH",
              "JOHNSONS BABY BATH CARE": "JOHNSONS BABY BATH CARE",
              "JOHNSONS BABY BEDTIME": "JOHNSONS BABY BEDTIME",
              "JOHNSONS BABY BEDTIME BATH": "JOHNSONS BABY BEDTIME BATH",
              "JOHNSONS BABY BUBBLE BATH": "JOHNSONS BABY BUBBLE BATH",
              "JOHNSONS BABY CREAM": "JOHNSONS BABY CREAM",
              "JOHNSONS BABY FIRST TOUCH": "JOHNSONS BABY FIRST TOUCH",
              "JOHNSONS BABY FRESHNER": "JOHNSONS BABY FRESHNER",
              "JOHNSONS BABY HAND/FACE WIPES": "JOHNSONS BABY HAND/FACE WIPES",
              "JOHNSONS BABY HEAD-TO-TOE": "JOHNSONS BABY HEAD-TO-TOE",
              "JOHNSONS BABY LOTION": "JOHNSONS BABY LOTION",
              "JOHNSONS BABY OIL GEL": "JOHNSONS BABY OIL GEL",
              "JOHNSONS BABY SHAMPOO": "JOHNSONS BABY SHAMPOO",
              "JOHNSONS BABY SHEA/COCOA": "JOHNSONS BABY SHEA/COCOA",
              "JOHNSONS BABY TAKE ALONG PACK": "JOHNSONS BABY TAKE ALONG PACK",
              "JOHNSONS BABY VANILLA OATMEAL": "JOHNSONS BABY VANILLA OATMEAL",
              "JOHNSONS BABY WASH": "JOHNSONS BABY WASH",
              "JOHNSONS BABY WASH HEAD-TO-TOE": "JOHNSONS BABY WASH HEAD-TO-TOE",
              "JOHNSONS BABY WASH MOISTURE": "JOHNSONS BABY WASH MOISTURE",
              "JOHNSONS BABY WASH OATMEAL": "JOHNSONS BABY WASH OATMEAL",
              "JOHNSONS BABY WASH SHEA/COCOA": "JOHNSONS BABY WASH SHEA/COCOA",
              "JOHNSONS BEDTIME": "JOHNSONS BEDTIME",
              "JOHNSONS BEDTIME BABY WASH": "JOHNSONS BEDTIME BABY WASH",
              "JOHNSONS BEDTIME BATH": "JOHNSONS BEDTIME BATH",
              "JOHNSONS BEDTIME BUBBLE BATH": "JOHNSONS BEDTIME BUBBLE BATH",
              "JOHNSONS BEDTIME MOISTURE WASH": "JOHNSONS BEDTIME MOISTURE WASH",
              "JOHNSONS COTTONTOUCH": "JOHNSONS COTTONTOUCH",
              "JOHNSONS GENTLE CARE GIFT SET": "JOHNSONS GENTLE CARE GIFT SET",
              "JOHNSONS HEAD-TO-TOE": "JOHNSONS HEAD-TO-TOE",
              "JOHNSONS KIDS NO MORE TANGLES": "JOHNSONS KIDS NO MORE TANGLES",
              "JOHNSONS NATURAL BABY": "JOHNSONS NATURAL BABY",
              "JOHNSONS NO MORE TANGLES": "JOHNSONS NO MORE TANGLES",
              "JOHNSONS SHEA & COCOA BUTTER": "JOHNSONS SHEA & COCOA BUTTER",
              "JOHNSONS SOOTHING VAPOR BATH": "JOHNSONS SOOTHING VAPOR BATH"
            },
            "JOHNSON&JOHNSON HEALTHCARE": {
              "AVEENO BABY WASH & SHAMPOO": "AVEENO BABY WASH & SHAMPOO",
              "JOHNSONS BATH DISCOVERY SET": "JOHNSONS BATH DISCOVERY SET"
            },
            "KIMBERLY-CLARK": {
              "HUGGIES BABY WASH": "HUGGIES BABY WASH",
              "HUGGIES BABY WIPES CALMING": "HUGGIES BABY WIPES CALMING",
              "HUGGIES BABY WIPES GENTLE CARE": "HUGGIES BABY WIPES GENTLE CARE",
              "HUGGIES BABY WIPES SHEA BUTTER": "HUGGIES BABY WIPES SHEA BUTTER",
              "HUGGIES BABY WIPES SUPREME": "HUGGIES BABY WIPES SUPREME",
              "HUGGIES CALMING TOUCH": "HUGGIES CALMING TOUCH",
              "HUGGIES CALMING TOUCH WASH": "HUGGIES CALMING TOUCH WASH",
              "HUGGIES CALMING TOUCH WIPES": "HUGGIES CALMING TOUCH WIPES",
              "HUGGIES CLEAN TEAM CLEANSING": "HUGGIES CLEAN TEAM CLEANSING",
              "HUGGIES CLEAN TEAM MOIST WIPES": "HUGGIES CLEAN TEAM MOIST WIPES",
              "HUGGIES NATURAL CARE": "HUGGIES NATURAL CARE",
              "HUGGIES NATURAL CARE WASH": "HUGGIES NATURAL CARE WASH",
              "HUGGIES NATURAL CARE WIPES": "HUGGIES NATURAL CARE WIPES",
              "HUGGIES NATURALLY REFRESH WASH": "HUGGIES NATURALLY REFRESH WASH",
              "HUGGIES NATURALLY REFRESH WIPE": "HUGGIES NATURALLY REFRESH WIPE",
              "HUGGIES NATURALLY REFRESHING": "HUGGIES NATURALLY REFRESHING",
              "HUGGIES ONE AND DONE WIPES": "HUGGIES ONE AND DONE WIPES",
              "HUGGIES SIMPLY CLEAN WIPES": "HUGGIES SIMPLY CLEAN WIPES",
              "HUGGIES SOFT SKIN": "HUGGIES SOFT SKIN",
              "HUGGIES SOFT SKIN BABY WIPES": "HUGGIES SOFT SKIN BABY WIPES",
              "HUGGIES SOFT SKIN WASH": "HUGGIES SOFT SKIN WASH",
              "HUGGIES SUPREME BABY WIPES": "HUGGIES SUPREME BABY WIPES"
            },
            "LANSINOH LABORATORIES": {
              "BREASTFEEDING STARTER SET": "BREASTFEEDING STARTER SET",
              "LANSINOH CLEAN & COND BABYWIPE": "LANSINOH CLEAN & COND BABYWIPE"
            },
            "LEADER BRAND PRODUCTS": {
              "BABY SHAMPOO": "BABY SHAMPOO",
              "BABY WIPES": "BABY WIPES"
            },
            "M J NUTR": {
              "CLEFT LIP/PALATE NURSER": "CLEFT LIP/PALATE NURSER",
              "NUK PACIFIER/HANDLE": "NUK PACIFIER/HANDLE"
            },
            "MCKESSON": {
              "HM BABY SHAMPOO": "HM BABY SHAMPOO"
            },
            "MCKESSON SUNMARK": {
              "SM BABY SHAMPOO": "SM BABY SHAMPOO"
            },
            "MEDICINE SHOPPE": {
              "BABY WIPES": "BABY WIPES"
            },
            "OVERTURE PROMOTIONS": {
              "PREGNANCY CARE BOX": "PREGNANCY CARE BOX"
            },
            "P & G HEALTH": {
              "PAMPERS BABY FRESH WIPES": "PAMPERS BABY FRESH WIPES"
            },
            "P & G PAPER PRODUCTS": {
              "PAMPERS SENSITIVE WIPES": "PAMPERS SENSITIVE WIPES"
            },
            "RITE AID CORPORATION": {
              "RA BABY WIPES": "RA BABY WIPES",
              "RA BABY WIPES SENSITIVE": "RA BABY WIPES SENSITIVE",
              "RA BABY WIPES/ALOE": "RA BABY WIPES/ALOE",
              "RA TUGABOOS BABY": "RA TUGABOOS BABY",
              "RA TUGABOOS BABY OIL GEL": "RA TUGABOOS BABY OIL GEL",
              "RA TUGABOOS BABY WASH": "RA TUGABOOS BABY WASH",
              "RA TUGABOOS BABY WIPES": "RA TUGABOOS BABY WIPES",
              "RA TUGABOOS SOOTHING VAPOR": "RA TUGABOOS SOOTHING VAPOR"
            },
            "SAGE PRODUCTS": {
              "COMFORT BATH BABY WASHCLOTHS": "COMFORT BATH BABY WASHCLOTHS"
            },
            "WALGREENS": {
              "BABY LOTION": "BABY LOTION",
              "BABY SHAMPOO": "BABY SHAMPOO",
              "BABY WASH": "BABY WASH",
              "COMFORT-SMOOTH BABY WIPES": "COMFORT-SMOOTH BABY WIPES",
              "COMFORT-SMOOTH BABY WIPES/ALOE": "COMFORT-SMOOTH BABY WIPES/ALOE",
              "PREMIUM BABY WIPES": "PREMIUM BABY WIPES",
              "PREMIUM BABY WIPES/SENSITIVE": "PREMIUM BABY WIPES/SENSITIVE",
              "WELL BEGINNINGS SCENTED WIPES": "WELL BEGINNINGS SCENTED WIPES"
            }
          },
          "Nursing Pads": {
            "AMEDA": {
              "AMEDA COMFORTGEL": "AMEDA COMFORTGEL",
              "AMEDA MOISTUREGUARD": "AMEDA MOISTUREGUARD",
              "AMEDA NOSHOW PREMIUM NURSING": "AMEDA NOSHOW PREMIUM NURSING",
              "AMEDA WASHABLE NURSING": "AMEDA WASHABLE NURSING"
            },
            "BEIERSDORF-FUTURO": {
              "CURITY NURSING": "CURITY NURSING"
            },
            "COVIDIEN MEDICAL SUPPLIES": {
              "CURITY NURSING": "CURITY NURSING"
            },
            "HOLLISTER": {
              "COMFORTGEL HYDROGEL NURSING": "COMFORTGEL HYDROGEL NURSING"
            },
            "J & J CONSUMER PRODUCTS": {
              "JOHNSONS NURSING PADS": "JOHNSONS NURSING PADS"
            },
            "LANSINOH LABORATORIES": {
              "LANSINOH ULTIMATE NURSING PADS": "LANSINOH ULTIMATE NURSING PADS",
              "LANSINOH WASHABLE NURSING PADS": "LANSINOH WASHABLE NURSING PADS",
              "NURSING PADS/DISPOSABLE": "NURSING PADS/DISPOSABLE"
            }
          }
        },
        "Misc. Devices": {
          "Acupressure Therapy Supplies": {
            "SEA-BAND": {
              "SEA-BAND ADULT": "SEA-BAND ADULT",
              "SEA-BAND CHILD": "SEA-BAND CHILD"
            },
            "WALGREENS": {
              "MOTION SICKNESS RELIEF BAND": "MOTION SICKNESS RELIEF BAND"
            }
          },
          "Applicators,Cotton Balls,etc": {
            "ALLISON MEDICAL": {
              "HEALTHWISE ALCOHOL PREP": "HEALTHWISE ALCOHOL PREP",
              "SURE COMFORT ALCOHOL PREP": "SURE COMFORT ALCOHOL PREP"
            },
            "AMERICAN SALES COMPANY": {
              "ALCOHOL PREP": "ALCOHOL PREP"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP ALCOHOL SWABS": "GNP ALCOHOL SWABS"
            },
            "ARISE MEDICAL": {
              "ULTRA-CARE ALCOHOL PREP PADS": "ULTRA-CARE ALCOHOL PREP PADS"
            },
            "ARISE PHARMACEUTICALS": {
              "ALCOHOL PREP": "ALCOHOL PREP"
            },
            "BD DIABETES CARE": {
              "BD SWABS SINGLE USE BUTTERFLY": "BD SWABS SINGLE USE BUTTERFLY"
            },
            "BD MEDICAL SURGICAL SYSTEMS": {
              "BD SWAB SINGLE USE REGULAR": "BD SWAB SINGLE USE REGULAR"
            },
            "BEIERSDORF-FUTURO": {
              "CURITY ALCOHOL SWABS": "CURITY ALCOHOL SWABS",
              "CURITY SUPER SOFT PUFFS": "CURITY SUPER SOFT PUFFS"
            },
            "BERGEN BRUNSWIG": {
              "COTTON BALLS": "COTTON BALLS",
              "GNP COTTON ROUNDS QUILTED PADS": "GNP COTTON ROUNDS QUILTED PADS",
              "GNP COTTON SQUARES QUILTED PAD": "GNP COTTON SQUARES QUILTED PAD"
            },
            "BOCA MEDICAL PRODUCTS": {
              "ULTILET ALCOHOL SWABS": "ULTILET ALCOHOL SWABS"
            },
            "BRITE LIFE": {
              "COTTON SQUARES": "COTTON SQUARES"
            },
            "CARDINAL HEALTH": {
              "ALCOHOL PREP": "ALCOHOL PREP"
            },
            "CHAIN DRUG CONSORTIUM": {
              "ALCOHOL PREP": "ALCOHOL PREP",
              "COTTON BALLS": "COTTON BALLS",
              "PURE COTTON": "PURE COTTON"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC ALCOHOL SWABS": "QC ALCOHOL SWABS"
            },
            "COVIDIEN MEDICAL SUPPLIES": {
              "CURITY ALCOHOL PREPS": "CURITY ALCOHOL PREPS",
              "CURITY COTTON PREP BALLS LG": "CURITY COTTON PREP BALLS LG",
              "CURITY COTTON PREP BALLS MED": "CURITY COTTON PREP BALLS MED",
              "CURITY COTTON ROLL 1 LB": "CURITY COTTON ROLL 1 LB",
              "CURITY COTTON TIPPED APPLIC 6\"\"": "CURITY COTTON TIPPED APPLIC 6\"\"",
              "CURITY COTTON TIPPED APPLICATR": "CURITY COTTON TIPPED APPLICATR",
              "CURITY LAP SPONGES 12\"\"X12\"\"": "CURITY LAP SPONGES 12\"\"X12\"\"",
              "CURITY LAP SPONGES 18\"\"X18\"\"": "CURITY LAP SPONGES 18\"\"X18\"\"",
              "CURITY LAP SPONGES 4\"\"X18\"\"": "CURITY LAP SPONGES 4\"\"X18\"\"",
              "CURITY LAP SPONGES 8\"\"X36\"\"": "CURITY LAP SPONGES 8\"\"X36\"\"",
              "DEVON OR TOWEL": "DEVON OR TOWEL",
              "KERLIX LAP SPONGES 16\"\"X17\"\"": "KERLIX LAP SPONGES 16\"\"X17\"\"",
              "KERLIX LAP SPONGES 17\"\"X26\"\"": "KERLIX LAP SPONGES 17\"\"X26\"\"",
              "KERLIX LAP SPONGES 3\"\"X17\"\"": "KERLIX LAP SPONGES 3\"\"X17\"\"",
              "KERLIX LAP SPONGES 8\"\"X11\"\"": "KERLIX LAP SPONGES 8\"\"X11\"\"",
              "KERLIX LAP SPONGES 8\"\"X32\"\"": "KERLIX LAP SPONGES 8\"\"X32\"\"",
              "Q-TIPS/SINGLE-TIP": "Q-TIPS/SINGLE-TIP",
              "Q-TIPS/SINGLE-TIP APPLICATOR": "Q-TIPS/SINGLE-TIP APPLICATOR",
              "SURECARE OB SPONGES 4\"\"X4\"\"": "SURECARE OB SPONGES 4\"\"X4\"\"",
              "WEBCOL ALCOHOL PREP LARGE": "WEBCOL ALCOHOL PREP LARGE",
              "WEBCOL ALCOHOL PREP MEDIUM": "WEBCOL ALCOHOL PREP MEDIUM"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS ABSORBENT COTTON BALLS": "CVS ABSORBENT COTTON BALLS",
              "CVS ABSORBENT COTTON/ROLLED": "CVS ABSORBENT COTTON/ROLLED",
              "CVS ALCOHOL PREP PADS": "CVS ALCOHOL PREP PADS",
              "CVS ALCOHOL PREP SWABS": "CVS ALCOHOL PREP SWABS",
              "CVS ALCOHOL SWABS": "CVS ALCOHOL SWABS",
              "CVS BABY SAFETY SWABS": "CVS BABY SAFETY SWABS",
              "CVS BEAUTY 360 COTTON BALLS": "CVS BEAUTY 360 COTTON BALLS",
              "CVS COTTON BALLS": "CVS COTTON BALLS",
              "CVS COTTON SWABS": "CVS COTTON SWABS",
              "CVS PLASTIC SWABS": "CVS PLASTIC SWABS",
              "CVS PREP": "CVS PREP",
              "CVS STERILE COTTON BALLS": "CVS STERILE COTTON BALLS"
            },
            "DYNAREX CORPORATION": {
              "ALCOHOL PREP": "ALCOHOL PREP"
            },
            "EQUALINE": {
              "EQL ALCOHOL SWABS": "EQL ALCOHOL SWABS",
              "EQL COTTON BALLS": "EQL COTTON BALLS",
              "EQL COTTON BALLS JUMBO": "EQL COTTON BALLS JUMBO",
              "EQL COTTON OVALS": "EQL COTTON OVALS",
              "EQL COTTON ROUNDS": "EQL COTTON ROUNDS",
              "EQL COTTON SQUARES": "EQL COTTON SQUARES",
              "EQL COTTON SWABS": "EQL COTTON SWABS"
            },
            "FIFTY50 MEDICAL": {
              "FIFTY50 ALCOHOL PREP": "FIFTY50 ALCOHOL PREP"
            },
            "FUTURE DIAGNOSTICS": {
              "CARETOUCH ALCOHOL PREP": "CARETOUCH ALCOHOL PREP"
            },
            "GLOBAL LIFE TECHNOLOGIES": {
              "NOZIN NASAL SANITIZER POPSWAB": "NOZIN NASAL SANITIZER POPSWAB"
            },
            "GLOBAL MEDICAL PRODUCTS": {
              "GLOBAL ALCOHOL PREP EASE": "GLOBAL ALCOHOL PREP EASE"
            },
            "H E BUTT GROCERY COMPANY": {
              "H-E-B INCONTROL ALCOHOL": "H-E-B INCONTROL ALCOHOL"
            },
            "HOME AIDE DIAGNOSTICS": {
              "ALCOHOL PADS": "ALCOHOL PADS",
              "EASY COMFORT ALCOHOL PADS": "EASY COMFORT ALCOHOL PADS",
              "PRO COMFORT ALCOHOL": "PRO COMFORT ALCOHOL",
              "PURE COMFORT ALCOHOL PREP": "PURE COMFORT ALCOHOL PREP",
              "SAPS CARE ALCOHOL PREP": "SAPS CARE ALCOHOL PREP",
              "SAPS HEALTH ALCOHOL PREP": "SAPS HEALTH ALCOHOL PREP",
              "SAPS HEALTH CARE ALCOHOL PREP": "SAPS HEALTH CARE ALCOHOL PREP",
              "TRUE COMFORT ALCOHOL PREP PADS": "TRUE COMFORT ALCOHOL PREP PADS"
            },
            "J & J CONSUMER PRODUCTS": {
              "JOHNSONS SAFETY SWABS": "JOHNSONS SAFETY SWABS"
            },
            "J & J MEDICAL": {
              "RED CROSS COTTON STERILE": "RED CROSS COTTON STERILE"
            },
            "LEADER BRAND PRODUCTS": {
              "ALCOHOL SWABS": "ALCOHOL SWABS",
              "COTTON BALLS": "COTTON BALLS",
              "COTTON SWABS": "COTTON SWABS"
            },
            "MCKESSON": {
              "HM COTTON BALLS": "HM COTTON BALLS",
              "HM COTTON SWABS": "HM COTTON SWABS",
              "HM STERILE ALCOHOL PREP": "HM STERILE ALCOHOL PREP"
            },
            "MCKESSON SUNMARK": {
              "SM ALCOHOL PREP": "SM ALCOHOL PREP",
              "SM COSMETIC PUFFS JUMBO": "SM COSMETIC PUFFS JUMBO",
              "SM COTTON BALLS": "SM COTTON BALLS",
              "SM COTTON SQUARES": "SM COTTON SQUARES",
              "SM COTTON SWABS": "SM COTTON SWABS"
            },
            "MEDICINE SHOPPE": {
              "ALCOHOL WIPES": "ALCOHOL WIPES",
              "COTTON BALLS": "COTTON BALLS",
              "COTTON SWABS": "COTTON SWABS"
            },
            "MEDISCA": {
              "ALCOH-GLOVE CONTOURED WIPE": "ALCOH-GLOVE CONTOURED WIPE",
              "ALCOH-WIPE": "ALCOH-WIPE"
            },
            "MEDLINE/DERMAL MANAGEMENT": {
              "ALCOHOL SWABSTICK": "ALCOHOL SWABSTICK",
              "APLICARE ALCOHOL SWABSTICK": "APLICARE ALCOHOL SWABSTICK"
            },
            "MEIJER": {
              "ALCOHOL SWABS": "ALCOHOL SWABS",
              "MEIJER ALCOHOL SWABS": "MEIJER ALCOHOL SWABS",
              "REALITY SWABS": "REALITY SWABS"
            },
            "MHC MEDICAL PRODUCTS": {
              "EASY TOUCH ALCOHOL PREP MEDIUM": "EASY TOUCH ALCOHOL PREP MEDIUM"
            },
            "ONE PHARMA & MEDICAL SUPPLY CO": {
              "SURE-PREP ALCOHOL PREP": "SURE-PREP ALCOHOL PREP"
            },
            "PERRIGO DIABETES CARE": {
              "ALCOHOL SWABS": "ALCOHOL SWABS"
            },
            "PHOENIX HEALTHCARE SOLUTIONS": {
              "ALCOHOL PREP": "ALCOHOL PREP"
            },
            "PRESCRIPTION SOLUTIONS, INC.": {
              "ALCOHOL SWABS": "ALCOHOL SWABS"
            },
            "RELIAMED": {
              "ALCOHOL PREP": "ALCOHOL PREP"
            },
            "RITE AID CORPORATION": {
              "RA ALCOHOL SWABS": "RA ALCOHOL SWABS",
              "RA COTTON BALLS": "RA COTTON BALLS",
              "RA COTTON SWABS": "RA COTTON SWABS"
            },
            "RUGBY LABORATORIES": {
              "ALCOHOL PREP": "ALCOHOL PREP"
            },
            "SELECT BRAND": {
              "SB ALCOHOL PREP": "SB ALCOHOL PREP"
            },
            "SHOPKO STORES OPERATING CO": {
              "SHOPKO ALCOHOL SWABS": "SHOPKO ALCOHOL SWABS"
            },
            "SIMPLE DIAGNOSTICS": {
              "PHARMACIST CHOICE ALCOHOL": "PHARMACIST CHOICE ALCOHOL"
            },
            "SPECIALTY MEDICAL SUPPLIES": {
              "ALCOHOL PREP": "ALCOHOL PREP"
            },
            "TARGET": {
              "TGT ALCOHOL SWABS": "TGT ALCOHOL SWABS"
            },
            "ULTIMED": {
              "ULTICARE ALCOHOL SWABS": "ULTICARE ALCOHOL SWABS"
            },
            "US DIAGNOSTICS": {
              "ALCOHOL PADS": "ALCOHOL PADS"
            },
            "WAL-MART": {
              "RELION ALCOHOL SWABS": "RELION ALCOHOL SWABS"
            },
            "WALGREENS": {
              "ALCOHOL PREP": "ALCOHOL PREP",
              "ALCOHOL SWABS": "ALCOHOL SWABS",
              "COTTON BALLS": "COTTON BALLS",
              "COTTON BANDAGE ROLL 4.5\"\"X4YD": "COTTON BANDAGE ROLL 4.5\"\"X4YD",
              "COTTON SWABS": "COTTON SWABS"
            },
            "XPRESS MEDICAL SUPPLY": {
              "ALCOHOL PREP": "ALCOHOL PREP"
            }
          },
          "Blood Coagulation Test Supplies": {
            "ROCHE DIAGNOSTICS": {
              "COAGUCHEK XS PLUS SYSTEM": "COAGUCHEK XS PLUS SYSTEM",
              "COAGUCHEK XS PRO SYSTEM": "COAGUCHEK XS PRO SYSTEM",
              "COAGUCHEK XS SYSTEM": "COAGUCHEK XS SYSTEM"
            }
          },
          "Disposable Gloves": {
            "ANSELL": {
              "MEDI-TOUCH GLOVES": "MEDI-TOUCH GLOVES"
            },
            "BD DIABETES CARE": {
              "BD SENSICARE MEDICAL GLOVES": "BD SENSICARE MEDICAL GLOVES",
              "BD TRU-TOUCH GLOVES": "BD TRU-TOUCH GLOVES",
              "BD VINYL GLOVES LARGE/X-LARGE": "BD VINYL GLOVES LARGE/X-LARGE"
            },
            "BD MEDICAL SURGICAL SYSTEMS": {
              "BD SENSICARE MEDICAL GLOVES": "BD SENSICARE MEDICAL GLOVES",
              "BD TRU-TOUCH GLOVES": "BD TRU-TOUCH GLOVES",
              "DURALL VINYL UTILITY GLOVES": "DURALL VINYL UTILITY GLOVES"
            },
            "CHAIN DRUG CONSORTIUM": {
              "LATEX EXAM GLOVES": "LATEX EXAM GLOVES",
              "LATEX GLOVES": "LATEX GLOVES",
              "NITRILE EXAM GLOVES MEDIUM": "NITRILE EXAM GLOVES MEDIUM",
              "NITRILE GLOVES/ONE SIZE": "NITRILE GLOVES/ONE SIZE",
              "VINYL GLOVES": "VINYL GLOVES",
              "VINYL GLOVES LARGE": "VINYL GLOVES LARGE"
            },
            "COVIDIEN MEDICAL SUPPLIES": {
              "CHEMOPLUS LATEX GLOVES": "CHEMOPLUS LATEX GLOVES"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS GLOVES": "CVS GLOVES",
              "CVS GLOVES VINYL": "CVS GLOVES VINYL",
              "CVS LATEX GLOVES SMALL": "CVS LATEX GLOVES SMALL",
              "CVS NITRILE EXAM GLOVES": "CVS NITRILE EXAM GLOVES",
              "CVS NYPLEX GLOVES": "CVS NYPLEX GLOVES",
              "CVS SUPER-SOFT VINYL GLOVES": "CVS SUPER-SOFT VINYL GLOVES"
            },
            "EQUALINE": {
              "EQL LATEX EXAM GLOVES": "EQL LATEX EXAM GLOVES",
              "EQL NITRILE EXAM GLOVES": "EQL NITRILE EXAM GLOVES",
              "EQL VINYL EXAM GLOVES": "EQL VINYL EXAM GLOVES",
              "EQL VINYL GLOVES ONE SIZE": "EQL VINYL GLOVES ONE SIZE"
            },
            "G. HIRSCH & CO.": {
              "SECURE GLOVES": "SECURE GLOVES"
            },
            "HOME AIDE DIAGNOSTICS": {
              "PRO COMFORT GLOVES LARGE": "PRO COMFORT GLOVES LARGE",
              "PRO COMFORT GLOVES MEDIUM": "PRO COMFORT GLOVES MEDIUM",
              "PRO COMFORT GLOVES X-LARGE": "PRO COMFORT GLOVES X-LARGE",
              "PRO-COMFORT EXAMINATION GLOVES": "PRO-COMFORT EXAMINATION GLOVES"
            },
            "HUDSON SCIENTIFIC": {
              "POWDER FREE NITRILE GLOVES LG": "POWDER FREE NITRILE GLOVES LG",
              "POWDER FREE NITRILE GLOVES MED": "POWDER FREE NITRILE GLOVES MED",
              "POWDER FREE NITRILE GLOVES SM": "POWDER FREE NITRILE GLOVES SM",
              "POWDER FREE NITRILE GLOVES XL": "POWDER FREE NITRILE GLOVES XL"
            },
            "J & J MEDICAL": {
              "ALLERGARD SURGICAL GLOVES": "ALLERGARD SURGICAL GLOVES",
              "J & J HEALTH CARE GLOVES": "J & J HEALTH CARE GLOVES",
              "MAXXUS ORTHO SURGICAL GLOVES": "MAXXUS ORTHO SURGICAL GLOVES",
              "MICRO-TOUCH GLOVES": "MICRO-TOUCH GLOVES",
              "MICRO-TOUCH XP GLOVES": "MICRO-TOUCH XP GLOVES",
              "NEUTRALON 50 BROWN LATEX GLOVE": "NEUTRALON 50 BROWN LATEX GLOVE",
              "NEUTRALON BROWN SURGICAL GLOVE": "NEUTRALON BROWN SURGICAL GLOVE",
              "SURGIKOS LATEX SURGICAL GLOVES": "SURGIKOS LATEX SURGICAL GLOVES"
            },
            "KIMBERLY-CLARK": {
              "LAVENDER NITRILE GLOVES/MEDIUM": "LAVENDER NITRILE GLOVES/MEDIUM"
            },
            "KIMBERLY-CLARK HEALTHCARE": {
              "NITRILE EXAM GLOVES LARGE": "NITRILE EXAM GLOVES LARGE",
              "SAFESKIN NITRILE EXAM GLOVES": "SAFESKIN NITRILE EXAM GLOVES",
              "SYNTHETIC VINYL EXAM GLOVES": "SYNTHETIC VINYL EXAM GLOVES"
            },
            "LEADER BRAND PRODUCTS": {
              "POWDER FREE VINYL EXAM GLOVES": "POWDER FREE VINYL EXAM GLOVES"
            },
            "MAYER LABORATORIES": {
              "DIGITEX EXAM GLOVES": "DIGITEX EXAM GLOVES"
            },
            "MCKESSON SUNMARK": {
              "LATEX GLOVES": "LATEX GLOVES",
              "VINYL GLOVES": "VINYL GLOVES"
            },
            "MEDICINE SHOPPE": {
              "LATEX GLOVES": "LATEX GLOVES",
              "VINYL GLOVES": "VINYL GLOVES"
            },
            "MEDISCA": {
              "CHEMOPLUS LATEX GLOVES": "CHEMOPLUS LATEX GLOVES",
              "CHEMOPLUS NEOPRENE GLOVE": "CHEMOPLUS NEOPRENE GLOVE",
              "CHEMOPLUS NITRILE GLOVES": "CHEMOPLUS NITRILE GLOVES",
              "LATEX GLOVES MEDIUM": "LATEX GLOVES MEDIUM",
              "NITRILE GLOVES LARGE": "NITRILE GLOVES LARGE",
              "NITRILE GLOVES MEDIUM": "NITRILE GLOVES MEDIUM",
              "NITRILE GLOVES SMALL": "NITRILE GLOVES SMALL",
              "NITRILE GLOVES X-LARGE": "NITRILE GLOVES X-LARGE",
              "NITRILE GLOVES/SIZE 10": "NITRILE GLOVES/SIZE 10",
              "NITRILE GLOVES/SIZE 6": "NITRILE GLOVES/SIZE 6",
              "NITRILE GLOVES/SIZE 6.5": "NITRILE GLOVES/SIZE 6.5",
              "NITRILE GLOVES/SIZE 7": "NITRILE GLOVES/SIZE 7",
              "NITRILE GLOVES/SIZE 7.5": "NITRILE GLOVES/SIZE 7.5",
              "NITRILE GLOVES/SIZE 8": "NITRILE GLOVES/SIZE 8",
              "NITRILE GLOVES/SIZE 8.5": "NITRILE GLOVES/SIZE 8.5",
              "NITRILE GLOVES/SIZE 9": "NITRILE GLOVES/SIZE 9",
              "NITRILE GLOVES/SIZE 9.5 MEDIUM": "NITRILE GLOVES/SIZE 9.5 MEDIUM",
              "SAFE-SENSE GLOVE-BLK-NITRL-L": "SAFE-SENSE GLOVE-BLK-NITRL-L",
              "SAFE-SENSE GLOVE-BLK-NITRL-M": "SAFE-SENSE GLOVE-BLK-NITRL-M",
              "SAFE-SENSE GLOVE-BLK-NITRL-S": "SAFE-SENSE GLOVE-BLK-NITRL-S",
              "SAFE-SENSE GLOVE-BLK-NITRL-XL": "SAFE-SENSE GLOVE-BLK-NITRL-XL",
              "SAFE-SENSE GLOVES-NITRILE-L": "SAFE-SENSE GLOVES-NITRILE-L",
              "SAFE-SENSE GLOVES-NITRILE-M": "SAFE-SENSE GLOVES-NITRILE-M",
              "SAFE-SENSE GLOVES-NITRILE-S": "SAFE-SENSE GLOVES-NITRILE-S",
              "SAFE-SENSE GLOVES-NITRILE-XL": "SAFE-SENSE GLOVES-NITRILE-XL"
            },
            "PCCA": {
              "SAFESKIN GLOVES/SIZE 6.5": "SAFESKIN GLOVES/SIZE 6.5"
            },
            "PRINCIPLE BUSINESS ENTERPRISES": {
              "TRANQUILITY VINYL GLOVES LARGE": "TRANQUILITY VINYL GLOVES LARGE",
              "TRANQUILITY VINYL GLOVES MED": "TRANQUILITY VINYL GLOVES MED",
              "TRANQUILITY VINYL GLOVES SMALL": "TRANQUILITY VINYL GLOVES SMALL"
            },
            "RITE AID CORPORATION": {
              "RA COTTON GLOVES MEDIUM": "RA COTTON GLOVES MEDIUM",
              "RA EXTENDED CUFF NITRILE GLOVE": "RA EXTENDED CUFF NITRILE GLOVE",
              "RA HEAVY DUTY LATEX GLOVES": "RA HEAVY DUTY LATEX GLOVES",
              "RA LATEX MEDICAL GLOVES": "RA LATEX MEDICAL GLOVES",
              "RA NITRILE MEDICAL GLOVES": "RA NITRILE MEDICAL GLOVES",
              "RA VINYL GLOVES": "RA VINYL GLOVES",
              "RA VINYL MEDICAL GLOVES": "RA VINYL MEDICAL GLOVES"
            },
            "SDI USA": {
              "CLEVER CHOICE COMFORT EZ GLOVE": "CLEVER CHOICE COMFORT EZ GLOVE"
            },
            "SHAMROCK MARKETING COMPANY": {
              "SHAMROCK LATEX EXAM GLOVES": "SHAMROCK LATEX EXAM GLOVES",
              "SHAMROCK VINYL EXAM GLOVES": "SHAMROCK VINYL EXAM GLOVES"
            },
            "SHEPARD MEDICAL PRODUCTS": {
              "CAREMATES LATEX-PF GLOVE LARGE": "CAREMATES LATEX-PF GLOVE LARGE",
              "CAREMATES LATEX-PF GLOVE MED": "CAREMATES LATEX-PF GLOVE MED",
              "CAREMATES LATEX-PF GLOVE SMALL": "CAREMATES LATEX-PF GLOVE SMALL",
              "CAREMATES LATEX-PF GLOVE XL": "CAREMATES LATEX-PF GLOVE XL",
              "CAREMATES NITRILE GLOVES LARGE": "CAREMATES NITRILE GLOVES LARGE",
              "CAREMATES NITRILE GLOVES MED": "CAREMATES NITRILE GLOVES MED",
              "CAREMATES NITRILE GLOVES SMALL": "CAREMATES NITRILE GLOVES SMALL",
              "CAREMATES NITRILE GLOVES XL": "CAREMATES NITRILE GLOVES XL"
            },
            "WAL-MART": {
              "ASSURANCE VINYL EXAM GLOVES": "ASSURANCE VINYL EXAM GLOVES",
              "RELION NITRILE EXAM GLOVES": "RELION NITRILE EXAM GLOVES"
            },
            "WALGREENS": {
              "COTTON GLOVES MEDIUM": "COTTON GLOVES MEDIUM",
              "LATEX GLOVES LARGE": "LATEX GLOVES LARGE",
              "LATEX GLOVES MEDIUM": "LATEX GLOVES MEDIUM",
              "LATEX GLOVES ONE SIZE": "LATEX GLOVES ONE SIZE",
              "LATEX GLOVES SMALL": "LATEX GLOVES SMALL",
              "NITRILE EXAM GLOVES MEDIUM": "NITRILE EXAM GLOVES MEDIUM",
              "NITRILE GLOVES/ONE SIZE": "NITRILE GLOVES/ONE SIZE",
              "ULTRA-SOFT GLOVES": "ULTRA-SOFT GLOVES",
              "VINYL GLOVES": "VINYL GLOVES",
              "VINYL GLOVES MEDIUM": "VINYL GLOVES MEDIUM",
              "VINYL GLOVES ONE SIZE": "VINYL GLOVES ONE SIZE"
            }
          },
          "Medical Identification Supplies": {
            "AMERICAN MEDICAL ID": {
              "MEDICAL ID BRACELET": "MEDICAL ID BRACELET",
              "MEDICAL ID PLATE FOR BRACELET": "MEDICAL ID PLATE FOR BRACELET",
              "MEDICAL ID PLATE FOR NECKLACE": "MEDICAL ID PLATE FOR NECKLACE"
            },
            "WALGREENS": {
              "MEDICAL ID BRACELET": "MEDICAL ID BRACELET"
            }
          },
          "Medication Disposal Systems": {
            "DISPOSERX": {
              "DISPOSERX": "DISPOSERX"
            },
            "VERDE ENVIRONMENTAL TECH": {
              "DETERRA 3.5 GALLON": "DETERRA 3.5 GALLON",
              "DETERRA 5 GALLON": "DETERRA 5 GALLON",
              "DETERRA LP": "DETERRA LP",
              "DETERRA MP": "DETERRA MP",
              "DETERRA SP": "DETERRA SP",
              "DETERRA XL": "DETERRA XL"
            }
          },
          "Misc. Devices": {
            "3M CONSUMER HEALTH CARE": {
              "NEXCARE COMFORT FOAM EAR PLUGS": "NEXCARE COMFORT FOAM EAR PLUGS",
              "NEXCARE REUSABLE EAR PLUGS": "NEXCARE REUSABLE EAR PLUGS"
            },
            "A & D MEDICAL": {
              "DUAL HEAD STETHOSCOPE": "DUAL HEAD STETHOSCOPE",
              "PRECISION SCALE": "PRECISION SCALE",
              "PROFIT PRECISION SCALE": "PROFIT PRECISION SCALE",
              "SINGLE HEAD STETHOSCOPE": "SINGLE HEAD STETHOSCOPE",
              "SPRAGUE RAPPAPORT STETHOSCOPE": "SPRAGUE RAPPAPORT STETHOSCOPE"
            },
            "A-S MEDICATION SOLUTIONS": {
              "ALLIGATOR SPOON": "ALLIGATOR SPOON",
              "RECONSTITUBE": "RECONSTITUBE"
            },
            "AKRON PHARMA": {
              "PROTECTIVE SAFETY EYEWARE": "PROTECTIVE SAFETY EYEWARE"
            },
            "ALCON SURGICAL": {
              "BSS/BSS PLUS ADMIN SET": "BSS/BSS PLUS ADMIN SET"
            },
            "ALLIANCE TECH MEDICAL": {
              "FLEX SHIELD WITH EAR LOOPS": "FLEX SHIELD WITH EAR LOOPS",
              "FLEX SHIELD WITH TIE STRINGS": "FLEX SHIELD WITH TIE STRINGS",
              "SPLASH SHIELD FULL FACE": "SPLASH SHIELD FULL FACE",
              "SPLASH SHIELD SHORT FACE": "SPLASH SHIELD SHORT FACE"
            },
            "AMEDA": {
              "AMEDA ADAPTER CAP": "AMEDA ADAPTER CAP",
              "AMEDA BREAST FLANGE INSERT": "AMEDA BREAST FLANGE INSERT",
              "AMEDA CUSTOMFIT BREAST FLANGE": "AMEDA CUSTOMFIT BREAST FLANGE",
              "AMEDA DIAPHRAGMS": "AMEDA DIAPHRAGMS",
              "AMEDA DUAL HYGIENIKIT SYSTEM": "AMEDA DUAL HYGIENIKIT SYSTEM",
              "AMEDA DUAL HYGIENIKIT W/ADAPT": "AMEDA DUAL HYGIENIKIT W/ADAPT",
              "AMEDA ELITE BREAST PUMP": "AMEDA ELITE BREAST PUMP",
              "AMEDA FINESSE BREAST PUMP": "AMEDA FINESSE BREAST PUMP",
              "AMEDA FLEXISHIELD": "AMEDA FLEXISHIELD",
              "AMEDA MYA JOY BREAST PUMP": "AMEDA MYA JOY BREAST PUMP",
              "AMEDA MYA JOY BREAST PUMP/TOTE": "AMEDA MYA JOY BREAST PUMP/TOTE",
              "AMEDA ONE-HAND BREAST PUMP": "AMEDA ONE-HAND BREAST PUMP",
              "AMEDA PLATINUM BREAST PUMP": "AMEDA PLATINUM BREAST PUMP",
              "AMEDA PURELY YOURS BREAST PUMP": "AMEDA PURELY YOURS BREAST PUMP",
              "AMEDA SILICONE TUBING": "AMEDA SILICONE TUBING",
              "AMEDA TUBING ADAPTER": "AMEDA TUBING ADAPTER",
              "AMEDA VALVES": "AMEDA VALVES"
            },
            "AMNEAL PHARMACEUTICALS": {
              "TRAINER FOR EPINEPHRINE": "TRAINER FOR EPINEPHRINE"
            },
            "APEX MEDICAL": {
              "ULTRA PILL CRUSHER": "ULTRA PILL CRUSHER"
            },
            "APOTHECARY PRODUCTS, INC.": {
              "ADULT-LOCK TABLET CUTTER": "ADULT-LOCK TABLET CUTTER",
              "DELUXE SAFETY TABLET CUTTER": "DELUXE SAFETY TABLET CUTTER",
              "DELUXE TABLET CUTTER": "DELUXE TABLET CUTTER",
              "EZY DOSE CUT N CRUSH": "EZY DOSE CUT N CRUSH",
              "EZY DOSE MEDICINE CUPS": "EZY DOSE MEDICINE CUPS",
              "EZY DOSE MEMORY PAC SYSTEM": "EZY DOSE MEMORY PAC SYSTEM",
              "EZY DOSE TABLET CUTTER": "EZY DOSE TABLET CUTTER",
              "FLENTS EAR STOPPLES": "FLENTS EAR STOPPLES",
              "ONE-DAY-AT-A-TIME PLANNER": "ONE-DAY-AT-A-TIME PLANNER",
              "PILL CRUSHER/BUILT IN STORAGE": "PILL CRUSHER/BUILT IN STORAGE",
              "PRESCRIPTION BOTTLE MAGNIFIER": "PRESCRIPTION BOTTLE MAGNIFIER",
              "QUIET PLEASE FOAM EAR PLUGS": "QUIET PLEASE FOAM EAR PLUGS",
              "RECONSTITUBE": "RECONSTITUBE",
              "SAFETY-SHIELD TABLET CUTTER": "SAFETY-SHIELD TABLET CUTTER",
              "SEAL-RITE SILICONE EAR PLUGS": "SEAL-RITE SILICONE EAR PLUGS",
              "TRAVEL BOTTLES": "TRAVEL BOTTLES"
            },
            "ARISE MEDICAL": {
              "LULLABY DBL ELECT BREAST PUMP": "LULLABY DBL ELECT BREAST PUMP"
            },
            "ATHENA FEMININE TECHNOLOGIES": {
              "PELVIC MUSCLE TRAINER": "PELVIC MUSCLE TRAINER"
            },
            "AUM PHARMACEUTICALS": {
              "BMI DIGITAL SMART SCALE": "BMI DIGITAL SMART SCALE",
              "FETAL DOPPLER": "FETAL DOPPLER"
            },
            "BARD": {
              "2-WAY FOLEY STABILIZATION DEV": "2-WAY FOLEY STABILIZATION DEV"
            },
            "BATTLE CREEK": {
              "MOIST-SURE REPLACEMENT COVER/L": "MOIST-SURE REPLACEMENT COVER/L",
              "MOIST-SURE REPLACEMENT COVER/M": "MOIST-SURE REPLACEMENT COVER/M",
              "MOIST-SURE REPLACEMENT COVER/P": "MOIST-SURE REPLACEMENT COVER/P",
              "MOISTUREPLUS COVER LARGE": "MOISTUREPLUS COVER LARGE",
              "MOISTUREPLUS COVER/MEDIUM": "MOISTUREPLUS COVER/MEDIUM",
              "MOISTUREPLUS COVER/PETITE": "MOISTUREPLUS COVER/PETITE"
            },
            "BAYER CONSUMER": {
              "ALEVE TENS REFILL PADS": "ALEVE TENS REFILL PADS",
              "RIDVANTAGE LICE COMB": "RIDVANTAGE LICE COMB"
            },
            "BD DIABETES CARE": {
              "BD SAFE CLIP NEEDLE CLIPPER": "BD SAFE CLIP NEEDLE CLIPPER",
              "BD SAFE-CLIP BY MAIL": "BD SAFE-CLIP BY MAIL",
              "CORNWALL FILLING OUTFIT": "CORNWALL FILLING OUTFIT",
              "CORNWALL RUBBER WASHERS": "CORNWALL RUBBER WASHERS",
              "PLASTIC ADAPTER KIT/AUTOINJECT": "PLASTIC ADAPTER KIT/AUTOINJECT",
              "TRU-FIT MAGNETIX ANKLE": "TRU-FIT MAGNETIX ANKLE",
              "TRU-FIT MAGNETIX BACK": "TRU-FIT MAGNETIX BACK",
              "TRU-FIT MAGNETIX DISKS": "TRU-FIT MAGNETIX DISKS",
              "TRU-FIT MAGNETIX ELBOW": "TRU-FIT MAGNETIX ELBOW",
              "TRU-FIT MAGNETIX MAGNETS": "TRU-FIT MAGNETIX MAGNETS",
              "TRU-FIT MAGNETIX MULTI-PURPOSE": "TRU-FIT MAGNETIX MULTI-PURPOSE",
              "TRU-FIT MAGNETIX OPEN KNEE": "TRU-FIT MAGNETIX OPEN KNEE",
              "TRU-FIT MAGNETIX WRIST": "TRU-FIT MAGNETIX WRIST"
            },
            "BD MEDICAL SURGICAL SYSTEMS": {
              "BD PHASEAL DRUG VIAL PROTECTOR": "BD PHASEAL DRUG VIAL PROTECTOR",
              "BD PHASEAL INFUSION ADAPTER": "BD PHASEAL INFUSION ADAPTER",
              "BD PHASEAL LUER LOCK CONNECTOR": "BD PHASEAL LUER LOCK CONNECTOR",
              "BD PHASEAL SYRINGE TRAY M15": "BD PHASEAL SYRINGE TRAY M15",
              "BD PHASEAL TRANSFER DEVICE": "BD PHASEAL TRANSFER DEVICE",
              "BD PHASEAL Y-SITE CONNECTOR": "BD PHASEAL Y-SITE CONNECTOR",
              "BONANNO SUPRAPUBIC CATH TRAY": "BONANNO SUPRAPUBIC CATH TRAY",
              "CATHETER INTRODUCER": "CATHETER INTRODUCER",
              "SAFETY-LOK COLLECTION SET": "SAFETY-LOK COLLECTION SET"
            },
            "BEIERSDORF-FUTURO": {
              "CANE": "CANE",
              "CANE TIPS": "CANE TIPS",
              "CERVICAL PILLOW/COVER": "CERVICAL PILLOW/COVER",
              "CERVICAL TRACTION": "CERVICAL TRACTION",
              "CRUTCH": "CRUTCH",
              "CRUTCH HANDGRIPS": "CRUTCH HANDGRIPS",
              "CRUTCH SET": "CRUTCH SET",
              "CRUTCH TIPS": "CRUTCH TIPS",
              "CRUTCH UNDERARM PADS": "CRUTCH UNDERARM PADS",
              "LUMBAR SUPPORT CUSHION": "LUMBAR SUPPORT CUSHION",
              "QUAD CANE": "QUAD CANE",
              "QUAD CANE TIPS": "QUAD CANE TIPS",
              "SITZ BATH": "SITZ BATH",
              "WALKER TIPS": "WALKER TIPS",
              "WHEELCHAIR CUSHION": "WHEELCHAIR CUSHION",
              "WHEELCHAIR INVALID RING": "WHEELCHAIR INVALID RING"
            },
            "BEUTLICH PHARMA": {
              "BEUTLICH PH TEST ROLL": "BEUTLICH PH TEST ROLL",
              "HURRICAINE DISPENSING CAP": "HURRICAINE DISPENSING CAP",
              "HURRICAINE LIQUID DISPENSER": "HURRICAINE LIQUID DISPENSER",
              "HURRICAINE SPR EXTENSION TUBES": "HURRICAINE SPR EXTENSION TUBES",
              "HURRIPAK PERIO IRRIGATION TIPS": "HURRIPAK PERIO IRRIGATION TIPS",
              "HURRIPAK PERIODONTAL ANESTHETI": "HURRIPAK PERIODONTAL ANESTHETI"
            },
            "BIOCOMPATIBLES": {
              "VARITHENA ADMINISTRATION PACK": "VARITHENA ADMINISTRATION PACK"
            },
            "BIOGEN IDEC": {
              "AVOSTARTGRIP": "AVOSTARTGRIP"
            },
            "BROWNMED": {
              "SEAL-TIGHT CAST/BANDAGE": "SEAL-TIGHT CAST/BANDAGE",
              "SEAL-TIGHT MID-ARM PROTECTOR": "SEAL-TIGHT MID-ARM PROTECTOR"
            },
            "BSN MEDICAL": {
              "FLA ADJUST AIR ANKLE WALKER": "FLA ADJUST AIR ANKLE WALKER",
              "FLAORTHO WALKER": "FLAORTHO WALKER"
            },
            "CHAIN DRUG CONSORTIUM": {
              "FOLDING CANE": "FOLDING CANE",
              "GLASS DROPPERS": "GLASS DROPPERS",
              "LICE REMOVAL COMBS": "LICE REMOVAL COMBS",
              "MEDICINE DROPPER": "MEDICINE DROPPER",
              "MEDICINE SPOON": "MEDICINE SPOON",
              "OXYCHECK PULSE OXIMETER": "OXYCHECK PULSE OXIMETER",
              "ROLLATOR": "ROLLATOR",
              "TABLET CRUSHER": "TABLET CRUSHER",
              "TABLET CUTTER/SAFETY": "TABLET CUTTER/SAFETY",
              "TRANSPORT CHAIR": "TRANSPORT CHAIR"
            },
            "CHATTEM INC": {
              "ICY HOT TENS THERAPY REFILL": "ICY HOT TENS THERAPY REFILL"
            },
            "COLOPLAST": {
              "CUBE PESSARY": "CUBE PESSARY",
              "CUBE PESSARY/DRAINS": "CUBE PESSARY/DRAINS",
              "DISH PESSARY": "DISH PESSARY",
              "DISH PESSARY/SUPPORT": "DISH PESSARY/SUPPORT",
              "DONUT PESSARY": "DONUT PESSARY",
              "GEHRUNG PESSARY/SUPPORT": "GEHRUNG PESSARY/SUPPORT",
              "GELLHORN PESSARY": "GELLHORN PESSARY",
              "HODGE PESSARY": "HODGE PESSARY",
              "HODGE PESSARY/SUPPORT": "HODGE PESSARY/SUPPORT",
              "MAR-LAND PESSARY": "MAR-LAND PESSARY",
              "MAR-LAND PESSARY/SUPPORT": "MAR-LAND PESSARY/SUPPORT",
              "OVAL PESSARY/SUPPORT": "OVAL PESSARY/SUPPORT",
              "RING PESSARY": "RING PESSARY",
              "RING PESSARY/SUPPORT": "RING PESSARY/SUPPORT",
              "SHAATZ PESSARY": "SHAATZ PESSARY"
            },
            "COMPASS HEALTH BRANDS": {
              "ADJUST BATH/SHOWER SEAT": "ADJUST BATH/SHOWER SEAT",
              "ADJUST BATH/SHOWER SEAT/BACK": "ADJUST BATH/SHOWER SEAT/BACK",
              "ADJUST FOLD CANE/YORK HANDLE": "ADJUST FOLD CANE/YORK HANDLE",
              "ADJUSTABLE ALUMINUM CANE": "ADJUSTABLE ALUMINUM CANE",
              "ADJUSTABLE ALUMINUM CANE 3/4\"\"": "ADJUSTABLE ALUMINUM CANE 3/4\"\"",
              "ADJUSTABLE ALUMINUM CANE 5/8\"\"": "ADJUSTABLE ALUMINUM CANE 5/8\"\"",
              "ADJUSTABLE ALUMINUM CANE 7/8\"\"": "ADJUSTABLE ALUMINUM CANE 7/8\"\"",
              "ADJUSTABLE FOLDING CANE": "ADJUSTABLE FOLDING CANE",
              "ADULT PUSH BUTTON ALUM CRUTCH": "ADULT PUSH BUTTON ALUM CRUTCH",
              "BATH/SHOWER SEAT": "BATH/SHOWER SEAT",
              "BATHTUB SAFETY RAIL": "BATHTUB SAFETY RAIL",
              "CANE": "CANE",
              "CANE HOLDER": "CANE HOLDER",
              "CANE TIPS": "CANE TIPS",
              "CANE TIPS FOR ALUM 3/4\"\"": "CANE TIPS FOR ALUM 3/4\"\"",
              "CANE TIPS FOR WOOD 3/4\"\"": "CANE TIPS FOR WOOD 3/4\"\"",
              "CANE TIPS FOR WOOD 5/8\"\"": "CANE TIPS FOR WOOD 5/8\"\"",
              "CANE TIPS FOR WOOD 7/8\"\"": "CANE TIPS FOR WOOD 7/8\"\"",
              "CANE WRIST STRAP": "CANE WRIST STRAP",
              "CAREX COCCYX CUSHION": "CAREX COCCYX CUSHION",
              "CAREX ULTRA GRABBER 32\"\"": "CAREX ULTRA GRABBER 32\"\"",
              "CAREX WHEELCHAIR": "CAREX WHEELCHAIR",
              "CERVICAL PILLOW": "CERVICAL PILLOW",
              "CLASSICS ROLLING WALKER": "CLASSICS ROLLING WALKER",
              "COMMODE": "COMMODE",
              "COMMODE BEDSIDE": "COMMODE BEDSIDE",
              "COMMODE PAIL": "COMMODE PAIL",
              "COMMODE SPLASH GUARD": "COMMODE SPLASH GUARD",
              "CONTOUR BACK CUSHION": "CONTOUR BACK CUSHION",
              "CONTOUR MATTRESS COVER": "CONTOUR MATTRESS COVER",
              "CRUTCH": "CRUTCH",
              "CRUTCH HANDGRIPS": "CRUTCH HANDGRIPS",
              "CRUTCH TIPS": "CRUTCH TIPS",
              "DIVERTER VALVE": "DIVERTER VALVE",
              "DUAL PADDLE FOLDING WALKER": "DUAL PADDLE FOLDING WALKER",
              "E-Z LOCK RAISED TOILET SEAT": "E-Z LOCK RAISED TOILET SEAT",
              "ELONGATED TOILET SEAT ELEVATOR": "ELONGATED TOILET SEAT ELEVATOR",
              "FOAM CRUTCH PAD": "FOAM CRUTCH PAD",
              "FOAM INVALID CUSHION": "FOAM INVALID CUSHION",
              "FOLDING COMMODE": "FOLDING COMMODE",
              "FOLDING REACHER": "FOLDING REACHER",
              "FOLDING SEAT CANE": "FOLDING SEAT CANE",
              "HAND HELD SHOWER SPRAY": "HAND HELD SHOWER SPRAY",
              "HEAD HALTER OVER DOOR TRACTION": "HEAD HALTER OVER DOOR TRACTION",
              "HEAD HALTER OVERDOOR TRACTION": "HEAD HALTER OVERDOOR TRACTION",
              "HOME STYLE BED RAILS": "HOME STYLE BED RAILS",
              "INFLATABLE NECK REST": "INFLATABLE NECK REST",
              "METAL REACHER": "METAL REACHER",
              "PEDAL EXERCISER": "PEDAL EXERCISER",
              "PLASTIC BED PAN": "PLASTIC BED PAN",
              "PLATFORM WALKER ATTACHMENT": "PLATFORM WALKER ATTACHMENT",
              "QUAD CANE": "QUAD CANE",
              "QUAD CANE TIPS": "QUAD CANE TIPS",
              "QUAD CANE/SMALL BASE": "QUAD CANE/SMALL BASE",
              "RAISED TOILET SEAT": "RAISED TOILET SEAT",
              "ROLLER WALKER": "ROLLER WALKER",
              "ROLLING WALKER/BURGUNDY": "ROLLING WALKER/BURGUNDY",
              "ROUND SHOWER STOOL": "ROUND SHOWER STOOL",
              "RUBBER BATH MAT": "RUBBER BATH MAT",
              "RUBBER INFLATABLE CUSHION": "RUBBER INFLATABLE CUSHION",
              "SITZ BATH": "SITZ BATH",
              "SOFT HANDS COTTON GLOVE": "SOFT HANDS COTTON GLOVE",
              "SPLIT HANDGRIPS": "SPLIT HANDGRIPS",
              "STANDARD CRUTCH TIP": "STANDARD CRUTCH TIP",
              "STEEL ROLLING WALKER": "STEEL ROLLING WALKER",
              "STEP N REST II WALKER": "STEP N REST II WALKER",
              "STEP N REST WALKER": "STEP N REST WALKER",
              "SUCTION TIPS": "SUCTION TIPS",
              "TOILET SAFETY FRAME": "TOILET SAFETY FRAME",
              "TOILET SEAT ELEVATOR": "TOILET SEAT ELEVATOR",
              "TRANSFER BENCH": "TRANSFER BENCH",
              "TRI-GRIP BATHTUB RAIL": "TRI-GRIP BATHTUB RAIL",
              "TRIO ROLLING WALKER": "TRIO ROLLING WALKER",
              "TUB TRANSFER BOARD": "TUB TRANSFER BOARD",
              "UNIVERSAL TIPS": "UNIVERSAL TIPS",
              "UNIVERSAL WALKER ORGANIZER": "UNIVERSAL WALKER ORGANIZER",
              "VINYL INFLATABLE CUSHION": "VINYL INFLATABLE CUSHION",
              "WALKER": "WALKER",
              "WALKER AUTO GLIDES": "WALKER AUTO GLIDES",
              "WALKER BASKET": "WALKER BASKET",
              "WALKER GLIDE WHEELS": "WALKER GLIDE WHEELS",
              "WALKER SKI GLIDES": "WALKER SKI GLIDES",
              "WALKER SWIVEL WHEELS": "WALKER SWIVEL WHEELS",
              "WALKER TALL EXTENSION LEGS": "WALKER TALL EXTENSION LEGS",
              "WALKER TIPS 1-1/8\"\"": "WALKER TIPS 1-1/8\"\"",
              "WALKER WHEELS": "WALKER WHEELS",
              "WALL GRAB BAR": "WALL GRAB BAR",
              "WOODEN CANE 7/8\"\"": "WOODEN CANE 7/8\"\"",
              "YOUTH PUSH BUTTON ALUM CRUTCH": "YOUTH PUSH BUTTON ALUM CRUTCH",
              "ZIPPERED MATTRESS COVER": "ZIPPERED MATTRESS COVER"
            },
            "CONTOURMED": {
              "ILLUSIONS AA BREAST PROSTHESIS": "ILLUSIONS AA BREAST PROSTHESIS",
              "ILLUSIONS C BREAST PROSTHESIS": "ILLUSIONS C BREAST PROSTHESIS",
              "REFLECTIONS AA BREAST PROSTHES": "REFLECTIONS AA BREAST PROSTHES",
              "REFLECTIONS C BREAST PROSTHES": "REFLECTIONS C BREAST PROSTHES",
              "SHAPERS LAYERED BREAST SHAPER": "SHAPERS LAYERED BREAST SHAPER",
              "VIRAGE CUSTOM BREAST PROSTHES": "VIRAGE CUSTOM BREAST PROSTHES"
            },
            "COVIDIEN MEDICAL SUPPLIES": {
              "A-V IMPULSE IMPAD FOOT COVER": "A-V IMPULSE IMPAD FOOT COVER",
              "A-V IMPULSE TUBING ASSEMBLY": "A-V IMPULSE TUBING ASSEMBLY",
              "ANGEL WING BLOOD COLLECT SET": "ANGEL WING BLOOD COLLECT SET",
              "ANGEL WING LUER ADAPTER/HOLDER": "ANGEL WING LUER ADAPTER/HOLDER",
              "ANGEL WING TRANSFER DEVICE": "ANGEL WING TRANSFER DEVICE",
              "ANGEL WING TUBE HOLDER": "ANGEL WING TUBE HOLDER",
              "AQUA-SEAL CHEST DRAINAGE UNIT": "AQUA-SEAL CHEST DRAINAGE UNIT",
              "ARGYLE AUTOTRANSFUSION ACC UNT": "ARGYLE AUTOTRANSFUSION ACC UNT",
              "ARGYLE CAROTID ARTERY SHUNT": "ARGYLE CAROTID ARTERY SHUNT",
              "ARGYLE FERGUSON THORACIC CATH": "ARGYLE FERGUSON THORACIC CATH",
              "ARGYLE INFUSION PLUG": "ARGYLE INFUSION PLUG",
              "ARGYLE LEFT VENTRICULAR CATH": "ARGYLE LEFT VENTRICULAR CATH",
              "ARGYLE ONE BOTTLE DRAINAGE SYS": "ARGYLE ONE BOTTLE DRAINAGE SYS",
              "ARGYLE PARALLEL Y CONNECTOR": "ARGYLE PARALLEL Y CONNECTOR",
              "ARGYLE SARATOGA SUMP DRAIN": "ARGYLE SARATOGA SUMP DRAIN",
              "ARGYLE SIL RT ANGLE THOR CATH": "ARGYLE SIL RT ANGLE THOR CATH",
              "ARGYLE SILICONE MEDIASTN DRAIN": "ARGYLE SILICONE MEDIASTN DRAIN",
              "ARGYLE SILICONE STR THOR CATH": "ARGYLE SILICONE STR THOR CATH",
              "ARGYLE SILICONE THOR CATH 20FR": "ARGYLE SILICONE THOR CATH 20FR",
              "ARGYLE SILICONE THOR CATH 24FR": "ARGYLE SILICONE THOR CATH 24FR",
              "ARGYLE SILICONE THOR CATH 28FR": "ARGYLE SILICONE THOR CATH 28FR",
              "ARGYLE SILICONE THOR CATH 32FR": "ARGYLE SILICONE THOR CATH 32FR",
              "ARGYLE SILICONE THOR CATH 36FR": "ARGYLE SILICONE THOR CATH 36FR",
              "ARGYLE SILICONE THOR CATH 40FR": "ARGYLE SILICONE THOR CATH 40FR",
              "ARGYLE THORACIC CATH 12FR/20\"\"": "ARGYLE THORACIC CATH 12FR/20\"\"",
              "ARGYLE THORACIC CATH 16FR/20\"\"": "ARGYLE THORACIC CATH 16FR/20\"\"",
              "ARGYLE THORACIC CATH 20FR/20\"\"": "ARGYLE THORACIC CATH 20FR/20\"\"",
              "ARGYLE THORACIC CATH 24FR/20\"\"": "ARGYLE THORACIC CATH 24FR/20\"\"",
              "ARGYLE THORACIC CATH 28FR/20\"\"": "ARGYLE THORACIC CATH 28FR/20\"\"",
              "ARGYLE THORACIC CATH 32FR/20\"\"": "ARGYLE THORACIC CATH 32FR/20\"\"",
              "ARGYLE THORACIC CATH 36FR/20\"\"": "ARGYLE THORACIC CATH 36FR/20\"\"",
              "ARGYLE THORACIC CATH 40FR/20\"\"": "ARGYLE THORACIC CATH 40FR/20\"\"",
              "ARGYLE TRACH TUBE HOLDER": "ARGYLE TRACH TUBE HOLDER",
              "ARGYLE TROCAR CATH 20FR/15-3/4": "ARGYLE TROCAR CATH 20FR/15-3/4",
              "ARGYLE TROCAR CATH 24FR/15-3/4": "ARGYLE TROCAR CATH 24FR/15-3/4",
              "ARGYLE TROCAR CATH 28FR/15-3/4": "ARGYLE TROCAR CATH 28FR/15-3/4",
              "ARGYLE TROCAR CATH 32FR/15-3/4": "ARGYLE TROCAR CATH 32FR/15-3/4",
              "ARGYLE TROCAR CATH16FR/9-8/10\"\"": "ARGYLE TROCAR CATH16FR/9-8/10\"\"",
              "ARGYLE TROCAR CATHETER 10FR/9\"\"": "ARGYLE TROCAR CATHETER 10FR/9\"\"",
              "ARGYLE TROCAR CATHETER 12FR/9\"\"": "ARGYLE TROCAR CATHETER 12FR/9\"\"",
              "ARGYLE TROCAR CATHETER 8FR": "ARGYLE TROCAR CATHETER 8FR",
              "ARGYLE TUBING EXTENSION SET": "ARGYLE TUBING EXTENSION SET",
              "ARGYLE TWO BOTTLE DRAINAGE SYS": "ARGYLE TWO BOTTLE DRAINAGE SYS",
              "ARGYLE VASCULAR TOURNIQUET": "ARGYLE VASCULAR TOURNIQUET",
              "CHEMOPLUS CHEMO SPILL": "CHEMOPLUS CHEMO SPILL",
              "CHEMOPLUS SOFT WASTE BAG": "CHEMOPLUS SOFT WASTE BAG",
              "CORVAC SERUM SEPARATOR TUBE": "CORVAC SERUM SEPARATOR TUBE",
              "CURITY SUTURE REMOVAL": "CURITY SUTURE REMOVAL",
              "DEVON ANDREWS FRAME": "DEVON ANDREWS FRAME",
              "DEVON ANDREWS TABLE": "DEVON ANDREWS TABLE",
              "DEVON JACKSON TABLE": "DEVON JACKSON TABLE",
              "DEVON SCHLEIN BEACH CHAIR": "DEVON SCHLEIN BEACH CHAIR",
              "DEVON SPONGE COUNTING BAG": "DEVON SPONGE COUNTING BAG",
              "DEVON WILSON FRAME": "DEVON WILSON FRAME",
              "DOVER COMMODE SPECIMEN COLLECT": "DOVER COMMODE SPECIMEN COLLECT",
              "DOVER MIDSTREAM SPECIMEN CATCH": "DOVER MIDSTREAM SPECIMEN CATCH",
              "KANGAROO RIGID CONTAINER": "KANGAROO RIGID CONTAINER",
              "KANGAROO RIGID CONTAINER 600ML": "KANGAROO RIGID CONTAINER 600ML",
              "MAGELLAN BLOOD COLLECT DEVICE": "MAGELLAN BLOOD COLLECT DEVICE",
              "MONOJECT BLOOD COLLECTION SET": "MONOJECT BLOOD COLLECTION SET",
              "MONOJECT BLOOD COLLECTION TUBE": "MONOJECT BLOOD COLLECTION TUBE",
              "MONOJECT BLOOD TUBE HOLDER": "MONOJECT BLOOD TUBE HOLDER",
              "MONOJECT BLUNTITE IV CLIP": "MONOJECT BLUNTITE IV CLIP",
              "MONOJECT LUER ADAPTER": "MONOJECT LUER ADAPTER",
              "MONOJECT MULT-SAMP COLLECT SET": "MONOJECT MULT-SAMP COLLECT SET",
              "PRECISION CATHETER URINE SYS": "PRECISION CATHETER URINE SYS",
              "PRECISION MIDSTREAM KIT": "PRECISION MIDSTREAM KIT",
              "PRECISION MIDSTREAM KIT/FUNNEL": "PRECISION MIDSTREAM KIT/FUNNEL",
              "PRECISION SPECIMEN CONTAINER": "PRECISION SPECIMEN CONTAINER",
              "PRECISION SPUTUM COLLECTOR": "PRECISION SPUTUM COLLECTOR",
              "PRECISION STOOL COLLECTOR": "PRECISION STOOL COLLECTOR",
              "PRECISION TISSUE GRINDER": "PRECISION TISSUE GRINDER",
              "PRECISION TISSUE GRINDER 15ML": "PRECISION TISSUE GRINDER 15ML",
              "PRECISION TISSUE GRINDER 50ML": "PRECISION TISSUE GRINDER 50ML",
              "PRECISION URINE SPECIMEN SYS": "PRECISION URINE SPECIMEN SYS",
              "PROGUARD II NEEDLE HOLDER": "PROGUARD II NEEDLE HOLDER",
              "SCD SOFT SLEEVES/KNEE LENGTH": "SCD SOFT SLEEVES/KNEE LENGTH",
              "SCD SOFT SLEEVES/THIGH LENGTH": "SCD SOFT SLEEVES/THIGH LENGTH",
              "SENTINEL SEAL CHEST DRAIN UNIT": "SENTINEL SEAL CHEST DRAIN UNIT",
              "SHARPSAFETY RCRA WASTE CON": "SHARPSAFETY RCRA WASTE CON",
              "THORA-SEAL CHEST DRAINAGE UNIT": "THORA-SEAL CHEST DRAINAGE UNIT",
              "THORA-SEAL COLLECTION CHAMBER": "THORA-SEAL COLLECTION CHAMBER"
            },
            "CUSTOM RX TDA": {
              "TOPI-CLICK APPLICATOR": "TOPI-CLICK APPLICATOR"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS ALKALINE BATTERIES SIZE AA": "CVS ALKALINE BATTERIES SIZE AA",
              "CVS CANE": "CVS CANE",
              "CVS CRUTCHES": "CVS CRUTCHES",
              "CVS DIABETIC ORGANIZER": "CVS DIABETIC ORGANIZER",
              "CVS EAR PLUGS": "CVS EAR PLUGS",
              "CVS GEL GRIP FOLDING CANE": "CVS GEL GRIP FOLDING CANE",
              "CVS NEEDLE COLLECTION/DISPOSAL": "CVS NEEDLE COLLECTION/DISPOSAL",
              "CVS PILL SPLITTER": "CVS PILL SPLITTER",
              "CVS PULSE OXIMETER": "CVS PULSE OXIMETER",
              "CVS QUAD CANE": "CVS QUAD CANE",
              "CVS READY SET GO BATH BENCH": "CVS READY SET GO BATH BENCH",
              "CVS REUSABLE SHEET PROTECTOR": "CVS REUSABLE SHEET PROTECTOR",
              "CVS RUBBER CUSHION": "CVS RUBBER CUSHION"
            },
            "DARTMOUTH": {
              "ELON PROFESSIONAL NAIL CARE": "ELON PROFESSIONAL NAIL CARE"
            },
            "DAVOL": {
              "BREAST PUMP": "BREAST PUMP",
              "DUNLAP FOAM RING CUSHION": "DUNLAP FOAM RING CUSHION",
              "DUNLAP INFLATABLE VINYL RING": "DUNLAP INFLATABLE VINYL RING",
              "ENDOSCOPIC DELIVERY SYSTEM": "ENDOSCOPIC DELIVERY SYSTEM"
            },
            "DISC DISEASE SOLUTIONS": {
              "DDS 100 CERVICAL TRACTION": "DDS 100 CERVICAL TRACTION",
              "DDS 300 LUMBAR TRACTION BELT": "DDS 300 LUMBAR TRACTION BELT",
              "DDS 500 LUMBAR TRACTION BELT": "DDS 500 LUMBAR TRACTION BELT"
            },
            "DRIVE DEVILBISS HEALTHCARE": {
              "HURRYCANE FREEDOM EDITION CANE": "HURRYCANE FREEDOM EDITION CANE",
              "UNIVERSAL QUICK ADJUST CRUTCH": "UNIVERSAL QUICK ADJUST CRUTCH"
            },
            "DURO-MED INDUSTRIES": {
              "RING CUSHION 14\"\"": "RING CUSHION 14\"\"",
              "RING CUSHION 16\"\"": "RING CUSHION 16\"\"",
              "RING CUSHION 18\"\"": "RING CUSHION 18\"\""
            },
            "DYNAREX CORPORATION": {
              "BLOOD COLLECTION TUBE HOLDER": "BLOOD COLLECTION TUBE HOLDER"
            },
            "EQUALINE": {
              "EQL EAR PLUGS/SILICONE": "EQL EAR PLUGS/SILICONE",
              "EQL MUSTACHE/BEARD SCISSORS": "EQL MUSTACHE/BEARD SCISSORS",
              "EQL SKIN CARE TOOL": "EQL SKIN CARE TOOL"
            },
            "ESSENTIAL MEDICAL SUPPLY": {
              "ENDURANCE FOUR LEG SEAT CANE": "ENDURANCE FOUR LEG SEAT CANE",
              "ENDURANCE HD COMMODE": "ENDURANCE HD COMMODE",
              "EVERYDAY PICK": "EVERYDAY PICK",
              "FREE SPIRIT KNEE/LEG WALKER": "FREE SPIRIT KNEE/LEG WALKER",
              "ONE OUNCE MEDICINE CUPS": "ONE OUNCE MEDICINE CUPS",
              "TRIGGER RELEASE JUNIOR WALKER": "TRIGGER RELEASE JUNIOR WALKER"
            },
            "EVENFLO COMPANY": {
              "SIMPLYGO BREAST PUMP": "SIMPLYGO BREAST PUMP"
            },
            "FAGRON": {
              "SUPPOSITORY MOLD 2GM": "SUPPOSITORY MOLD 2GM",
              "SUPPOSITORY MOLDS 1.3 ML": "SUPPOSITORY MOLDS 1.3 ML",
              "SUPPOSITORY MOLDS 2 CC/V-NOTCH": "SUPPOSITORY MOLDS 2 CC/V-NOTCH",
              "SUPPOSITORY MOLDS 2 ML": "SUPPOSITORY MOLDS 2 ML",
              "SUPPOSITORY MOLDS 2.25 ML": "SUPPOSITORY MOLDS 2.25 ML",
              "SUPPOSITORY MOLDS 3 ML": "SUPPOSITORY MOLDS 3 ML"
            },
            "FIRST CHOICE": {
              "MEDICINE SPOON": "MEDICINE SPOON",
              "ORAL DOSE SYRINGE": "ORAL DOSE SYRINGE",
              "ORAL MEDICINE DROPPER": "ORAL MEDICINE DROPPER",
              "PILL BOX 7 DAY": "PILL BOX 7 DAY",
              "PILL SPLITTER": "PILL SPLITTER",
              "TWIN MEDICINE SPOON": "TWIN MEDICINE SPOON"
            },
            "FORA CARE": {
              "FORA GATEWAY": "FORA GATEWAY",
              "FORA GW9014 TELEHEALTH GATEWAY": "FORA GW9014 TELEHEALTH GATEWAY",
              "FORA TN\\'G SCALE 550": "FORA TN\\'G SCALE 550"
            },
            "FREEDOM PHARMACEUTICALS": {
              "PRECISION AIRLESS PUMP": "PRECISION AIRLESS PUMP"
            },
            "FRESENIUS KABI USA": {
              "CHEMO TRANSFER PIN": "CHEMO TRANSFER PIN",
              "MINI TRANSFER PIN": "MINI TRANSFER PIN",
              "TRANSFER PIN": "TRANSFER PIN"
            },
            "G. HIRSCH & CO.": {
              "O-RING CUSHION": "O-RING CUSHION"
            },
            "GATEKEEPER INNOVATION": {
              "RX LOCKING CAP": "RX LOCKING CAP"
            },
            "GERITREX CORPORATION": {
              "BODI LINE DISPENSER": "BODI LINE DISPENSER",
              "ERASE": "ERASE",
              "ERASE RAS-POURI": "ERASE RAS-POURI"
            },
            "GLENWOOD": {
              "BOULES QUIES EAR PLUGS": "BOULES QUIES EAR PLUGS"
            },
            "GOJJI": {
              "GOJJI WEIGHT SCALE": "GOJJI WEIGHT SCALE"
            },
            "HEALTH ENTERPRISES": {
              "ACU-LIFE CRUSHER/CONTAINER": "ACU-LIFE CRUSHER/CONTAINER",
              "PILL SPLITTER": "PILL SPLITTER"
            },
            "HOLLISTER": {
              "DUAL HYGIENIKIT MILK COLLECT": "DUAL HYGIENIKIT MILK COLLECT",
              "HEELBOOT LARGE": "HEELBOOT LARGE",
              "HEELBOOT LAUNDRY BAG": "HEELBOOT LAUNDRY BAG",
              "HEELBOOT LINER LARGE": "HEELBOOT LINER LARGE",
              "HEELBOOT LINER REGULAR": "HEELBOOT LINER REGULAR",
              "HEELBOOT REGULAR": "HEELBOOT REGULAR",
              "HEELBOOT WALK PAD": "HEELBOOT WALK PAD",
              "ORAL ENDOTRACHEAL DEVICE": "ORAL ENDOTRACHEAL DEVICE",
              "REPLACEMENT NECKBAND STRAPS": "REPLACEMENT NECKBAND STRAPS"
            },
            "HOME AIDE DIAGNOSTICS": {
              "EASY FEED ELECTRIC BREAST PUMP": "EASY FEED ELECTRIC BREAST PUMP",
              "NUASKIN VACUUM PRO": "NUASKIN VACUUM PRO",
              "PRO COMFORT FOOT BATH": "PRO COMFORT FOOT BATH",
              "PRO COMFORT PULSE OXIMETER": "PRO COMFORT PULSE OXIMETER",
              "TOTAL COMFORT CHAIR CUSHION": "TOTAL COMFORT CHAIR CUSHION",
              "TOTAL COMFORT SEAT CUSHION": "TOTAL COMFORT SEAT CUSHION"
            },
            "HOSPIRA": {
              "ADD-VANTAGE ADDAPTOR CONNECTOR": "ADD-VANTAGE ADDAPTOR CONNECTOR"
            },
            "HUDSON SCIENTIFIC": {
              "BREATHE EASE PULSE OXIMETER": "BREATHE EASE PULSE OXIMETER",
              "NVZZLER PRO DOUBLE BREAST PUMP": "NVZZLER PRO DOUBLE BREAST PUMP",
              "NVZZLER SINGLE BREAST PUMP": "NVZZLER SINGLE BREAST PUMP"
            },
            "ICY DIAMOND TOTES": {
              "ICY DIAMOND TOTE CANVAS": "ICY DIAMOND TOTE CANVAS",
              "ICY DIAMOND TOTE NON LEATHER": "ICY DIAMOND TOTE NON LEATHER"
            },
            "INVACARE CORPORATION": {
              "ESSENTIAL CUSHION": "ESSENTIAL CUSHION"
            },
            "J & J MEDICAL": {
              "DINAMAP MONITOR PROBE COVERS": "DINAMAP MONITOR PROBE COVERS",
              "DISP SINGLE HEAD STETHOSCOPE": "DISP SINGLE HEAD STETHOSCOPE",
              "DISPOSABLE BULB/VALVE": "DISPOSABLE BULB/VALVE",
              "J & J ANTISEPTIC WIPES": "J & J ANTISEPTIC WIPES",
              "J & J INSTANT COLD PACK": "J & J INSTANT COLD PACK",
              "J & J TOURNIQUET": "J & J TOURNIQUET",
              "MICROCLENS WALL MOUNT BRACKET": "MICROCLENS WALL MOUNT BRACKET"
            },
            "LADYCARE LIFETIME": {
              "LADYCARE MENOPAUSE": "LADYCARE MENOPAUSE",
              "MN8": "MN8"
            },
            "LANSINOH LABORATORIES": {
              "AFFINITY PRO ELEC BREAST PUMP": "AFFINITY PRO ELEC BREAST PUMP",
              "COMFORT FIT FLANGES LARGE": "COMFORT FIT FLANGES LARGE",
              "LANSINOH BREASTFEEDING PILLOW": "LANSINOH BREASTFEEDING PILLOW",
              "LANSINOH BREASTMILK COLLECTOR": "LANSINOH BREASTMILK COLLECTOR",
              "LANSINOH EXTRA PUMPING SET": "LANSINOH EXTRA PUMPING SET",
              "LANSINOH MANUAL BREAST PUMP": "LANSINOH MANUAL BREAST PUMP",
              "LANSINOH PUMP ADAPTERS": "LANSINOH PUMP ADAPTERS",
              "LANSINOH SMART PUMP TOTE BAGS": "LANSINOH SMART PUMP TOTE BAGS",
              "LANSINOH SMARTPUMP": "LANSINOH SMARTPUMP",
              "LANSINOH SMARTPUMP 2.0": "LANSINOH SMARTPUMP 2.0",
              "LATCH ASSIST NIPPLE EVERTER": "LATCH ASSIST NIPPLE EVERTER",
              "SIGNATURE PRO ELEC BREAST PUMP": "SIGNATURE PRO ELEC BREAST PUMP",
              "SIMPLE WISHES PUMPING BRA": "SIMPLE WISHES PUMPING BRA",
              "SOOTHIES COOLING GEL PADS": "SOOTHIES COOLING GEL PADS",
              "SOOTHIES GEL PADS": "SOOTHIES GEL PADS"
            },
            "LEADER BRAND PRODUCTS": {
              "CRUTCHES-ALUMINUM": "CRUTCHES-ALUMINUM",
              "EARPLUGS": "EARPLUGS",
              "FOAM EAR PLUGS": "FOAM EAR PLUGS",
              "HEAD LICE COMB": "HEAD LICE COMB",
              "MEDICINE DROPPER": "MEDICINE DROPPER",
              "MEDICINE SPOON": "MEDICINE SPOON",
              "PULSE OXIMETER DELUXE": "PULSE OXIMETER DELUXE",
              "SILICONE EAR PLUGS": "SILICONE EAR PLUGS",
              "SILICONE EAR PLUGS-KIDS": "SILICONE EAR PLUGS-KIDS",
              "SILICONE EARPLUGS CHILDRENS": "SILICONE EARPLUGS CHILDRENS",
              "TABLET CRUSHER-CONTAINER": "TABLET CRUSHER-CONTAINER",
              "TABLET CUTTER": "TABLET CUTTER",
              "TABLET CUTTER/SAFETY SHIELD": "TABLET CUTTER/SAFETY SHIELD"
            },
            "LINKS MEDICAL PRODUCTS": {
              "CLIP & STOR": "CLIP & STOR"
            },
            "LIONHEARTED INDUSTRIES": {
              "CINIS PREEMIE HALO LARGE": "CINIS PREEMIE HALO LARGE",
              "CINIS PREEMIE HALO MEDIUM": "CINIS PREEMIE HALO MEDIUM",
              "CINIS PREEMIE HALO SMALL": "CINIS PREEMIE HALO SMALL"
            },
            "LUMISCOPE": {
              "BREAST PUMP": "BREAST PUMP",
              "STETHOSCOPE": "STETHOSCOPE",
              "STETHOSCOPE DUAL HEAD": "STETHOSCOPE DUAL HEAD",
              "STETHOSCOPE SINGLE HEAD": "STETHOSCOPE SINGLE HEAD"
            },
            "M.C. JOHNSON COMPANY": {
              "NG SECURE": "NG SECURE"
            },
            "MALLINCKRODT BRAND PHARMA": {
              "SPRAY APPLICATOR KIT": "SPRAY APPLICATOR KIT"
            },
            "MALLINCKRODT HOSPITAL PRODUCTS": {
              "RAPLIXA DELIVERY KIT": "RAPLIXA DELIVERY KIT"
            },
            "MCKESSON": {
              "HM COMFORT FOAM EAR PLUGS": "HM COMFORT FOAM EAR PLUGS"
            },
            "MCKESSON HOME HEALTH CARE": {
              "ADJUSTABLE COMMODE 3-IN-1": "ADJUSTABLE COMMODE 3-IN-1",
              "ALUMINUM BLANKET SUPPORT": "ALUMINUM BLANKET SUPPORT",
              "BED WEDGE": "BED WEDGE",
              "CANE": "CANE",
              "CANE FOR BLIND FOLDING": "CANE FOR BLIND FOLDING",
              "CANE TIPS": "CANE TIPS",
              "CANE TIPS FOR ALUM 3/4\"\"": "CANE TIPS FOR ALUM 3/4\"\"",
              "CERVICAL PILLOW": "CERVICAL PILLOW",
              "COMFORT CURVE MASSAGE CUSHION": "COMFORT CURVE MASSAGE CUSHION",
              "COMMODE 3-IN-1": "COMMODE 3-IN-1",
              "CONTOUR FITTED SHEETS": "CONTOUR FITTED SHEETS",
              "CRUTCH": "CRUTCH",
              "CRUTCH ACCESSORY KIT": "CRUTCH ACCESSORY KIT",
              "CRUTCH HANDGRIPS": "CRUTCH HANDGRIPS",
              "CRUTCH TIPS": "CRUTCH TIPS",
              "CRUTCH UNDERARM PADS": "CRUTCH UNDERARM PADS",
              "EGG CRATE BED PAD": "EGG CRATE BED PAD",
              "FOLDING WALKING CANE": "FOLDING WALKING CANE",
              "FOOT MASSAGER": "FOOT MASSAGER",
              "HEAD HALTER": "HEAD HALTER",
              "KANESON BREAST PUMP/NURSER": "KANESON BREAST PUMP/NURSER",
              "MASSAGER": "MASSAGER",
              "MATTRESS COVER": "MATTRESS COVER",
              "MATTRESS PAD": "MATTRESS PAD",
              "POSTURE SEAT": "POSTURE SEAT",
              "QUAD CANE": "QUAD CANE",
              "QUAD CANE TIPS": "QUAD CANE TIPS",
              "QUAD CANE/SMALL BASE": "QUAD CANE/SMALL BASE",
              "SITZ BATH": "SITZ BATH",
              "SM WALKER/YOUTH": "SM WALKER/YOUTH",
              "STETHOSCOPE": "STETHOSCOPE",
              "TRACTION FLOOR STAND": "TRACTION FLOOR STAND",
              "TRACTION HEAD HALTER ROPE": "TRACTION HEAD HALTER ROPE",
              "TRACTION KIT": "TRACTION KIT",
              "TRACTION PELVIC BELT": "TRACTION PELVIC BELT",
              "TRACTION WEIGHT BAG": "TRACTION WEIGHT BAG",
              "TRANSFER BENCH": "TRANSFER BENCH",
              "TRANSFER BOARD": "TRANSFER BOARD",
              "VIBRATING FOOT BATH": "VIBRATING FOOT BATH",
              "WATERPROOF SHEETING": "WATERPROOF SHEETING",
              "WHEELCHAIR": "WHEELCHAIR"
            },
            "MCKESSON SUNMARK": {
              "CANE": "CANE",
              "CRUTCH": "CRUTCH",
              "QUAD CANE": "QUAD CANE",
              "SM FOAM EAR PLUGS": "SM FOAM EAR PLUGS"
            },
            "MEDELA": {
              "FREESTYLE DOUBLE BREASTPUMP": "FREESTYLE DOUBLE BREASTPUMP",
              "HARMONY BREASTPUMP": "HARMONY BREASTPUMP",
              "MEDELA DOUBLE BREAST PUMP": "MEDELA DOUBLE BREAST PUMP",
              "MEDELA LACTINA DOUBLE PUMPING": "MEDELA LACTINA DOUBLE PUMPING",
              "MEDELA PUMP IN STYLE": "MEDELA PUMP IN STYLE",
              "PUMP IN STYLE ADVANCED": "PUMP IN STYLE ADVANCED",
              "SYMPHONY DOUBLE PUMPING SYSTEM": "SYMPHONY DOUBLE PUMPING SYSTEM"
            },
            "MEDI-FRIDGE": {
              "BABY FRIDGE": "BABY FRIDGE",
              "MEDI-COOLER": "MEDI-COOLER",
              "MEDI-FRIDGE IIX": "MEDI-FRIDGE IIX",
              "TRAVEL POUCH": "TRAVEL POUCH"
            },
            "MEDICAL DEVELOPMENTS INTERNATI": {
              "MY MDI FINGER PULSE OXIMETER": "MY MDI FINGER PULSE OXIMETER"
            },
            "MEDICINE SHOPPE": {
              "PILL SPLITTER": "PILL SPLITTER"
            },
            "MEDISCA": {
              "ADAPTER CAP": "ADAPTER CAP",
              "ALPHAMOP FOAM REPLACEMENT PADS": "ALPHAMOP FOAM REPLACEMENT PADS",
              "ALUMINUM FLIP OFF SEALS 13MM": "ALUMINUM FLIP OFF SEALS 13MM",
              "ALUMINUM FLIP OFF SEALS 20MM": "ALUMINUM FLIP OFF SEALS 20MM",
              "AMBER GLASS BOTTLE": "AMBER GLASS BOTTLE",
              "AMBER GLASS VIALS 2ML": "AMBER GLASS VIALS 2ML",
              "AMBER GLASS VIALS 2ML/13MM": "AMBER GLASS VIALS 2ML/13MM",
              "AMBER GLASS VIALS 30ML/20MM": "AMBER GLASS VIALS 30ML/20MM",
              "AUTOCLAVE AIR FILTER": "AUTOCLAVE AIR FILTER",
              "AUTOCLAVE PAPER 36\"\" X 36\"\"": "AUTOCLAVE PAPER 36\"\" X 36\"\"",
              "AUTOCLAVE PRINTER PAPER": "AUTOCLAVE PRINTER PAPER",
              "BOTTLE AMBER GLASS 33OZ": "BOTTLE AMBER GLASS 33OZ",
              "BOTTLE AMBER GRADUATED 16OZ": "BOTTLE AMBER GRADUATED 16OZ",
              "BOTTLE AMBER GRADUATED 8OZ": "BOTTLE AMBER GRADUATED 8OZ",
              "BOTTLE/WHITE 6OZ W/TWIST TOP": "BOTTLE/WHITE 6OZ W/TWIST TOP",
              "BOTTLETOP DISPENSER": "BOTTLETOP DISPENSER",
              "BOTTLETOP DISPENSER ADAPTER": "BOTTLETOP DISPENSER ADAPTER",
              "BUBBLE POINT TESTER KIT/WIZARD": "BUBBLE POINT TESTER KIT/WIZARD",
              "CLEANROOM TACKY MAT 18\"\"X36\"\"": "CLEANROOM TACKY MAT 18\"\"X36\"\"",
              "CLEAR GLASS VIAL 10ML": "CLEAR GLASS VIAL 10ML",
              "CLEAR GLASS VIALS 2ML": "CLEAR GLASS VIALS 2ML",
              "COMAR PRESS-IN BOTTLE ADAPTERS": "COMAR PRESS-IN BOTTLE ADAPTERS",
              "COVERALL BOOTS/DISPOSABLE/UNIV": "COVERALL BOOTS/DISPOSABLE/UNIV",
              "COVERALL W/HOOD/3XL": "COVERALL W/HOOD/3XL",
              "COVERALL W/HOOD/SMALL": "COVERALL W/HOOD/SMALL",
              "COVERALL W/HOOD/XL": "COVERALL W/HOOD/XL",
              "COVERALL W/HOOD/XXL": "COVERALL W/HOOD/XXL",
              "COVERALLS ELAST BACK/WRST/ANKL": "COVERALLS ELAST BACK/WRST/ANKL",
              "DEODORANT TUBES 2.65OZ-CAPS": "DEODORANT TUBES 2.65OZ-CAPS",
              "DIAL-A-DOSE SYRINGE 15ML": "DIAL-A-DOSE SYRINGE 15ML",
              "DIAL-A-DOSE SYRINGE 30ML": "DIAL-A-DOSE SYRINGE 30ML",
              "DIAL-A-DOSE SYRINGE 60ML": "DIAL-A-DOSE SYRINGE 60ML",
              "DISPENSER 50ML/FOAMER PUMP": "DISPENSER 50ML/FOAMER PUMP",
              "DISPENSER MD JAR 50ML": "DISPENSER MD JAR 50ML",
              "DISPENSER MD PEN 6.5ML": "DISPENSER MD PEN 6.5ML",
              "DISPENSER MD PUMP 0.5ML": "DISPENSER MD PUMP 0.5ML",
              "DISPENSER MD PUMP 1.0ML": "DISPENSER MD PUMP 1.0ML",
              "DISPENSER MD PUMP 1.5ML": "DISPENSER MD PUMP 1.5ML",
              "DISPENSER MD PUMP BOTTLE 100ML": "DISPENSER MD PUMP BOTTLE 100ML",
              "DISPENSER MD PUMP BOTTLE 150ML": "DISPENSER MD PUMP BOTTLE 150ML",
              "DISPENSER MD PUMP BOTTLE 15ML": "DISPENSER MD PUMP BOTTLE 15ML",
              "DISPENSER MD PUMP BOTTLE 200ML": "DISPENSER MD PUMP BOTTLE 200ML",
              "DISPENSER MD PUMP BOTTLE 240ML": "DISPENSER MD PUMP BOTTLE 240ML",
              "DISPENSER MD PUMP BOTTLE 30ML": "DISPENSER MD PUMP BOTTLE 30ML",
              "DISPENSER MD PUMP BOTTLE 50ML": "DISPENSER MD PUMP BOTTLE 50ML",
              "DISPENSER MD PUMP BOTTLE 80ML": "DISPENSER MD PUMP BOTTLE 80ML",
              "DISPENSER MD SYRINGE 10ML": "DISPENSER MD SYRINGE 10ML",
              "DISPENSER MD SYRINGE 5ML": "DISPENSER MD SYRINGE 5ML",
              "DISPENSER MEGAPUMP AIRLESS": "DISPENSER MEGAPUMP AIRLESS",
              "DISPENSER MEGAPUMP MEZZO RND": "DISPENSER MEGAPUMP MEZZO RND",
              "DISPENSER TIP CAP/PRECISEDOSE": "DISPENSER TIP CAP/PRECISEDOSE",
              "DISPENSER/MD FOAMER": "DISPENSER/MD FOAMER",
              "DOPRTAINERS 10ML": "DOPRTAINERS 10ML",
              "DROPPER & SCREW CAP 4OZ": "DROPPER & SCREW CAP 4OZ",
              "DROPPING BOTTLE 30ML": "DROPPING BOTTLE 30ML",
              "DROPTAINERS OPHTHALMIC 15ML": "DROPTAINERS OPHTHALMIC 15ML",
              "DROPTAINERS OPHTHALMIC 3ML": "DROPTAINERS OPHTHALMIC 3ML",
              "DROPTAINERS OPHTHALMIC 7ML": "DROPTAINERS OPHTHALMIC 7ML",
              "ECO-SMARTFUNNEL 186ML": "ECO-SMARTFUNNEL 186ML",
              "EMPTY VIAL 3ML": "EMPTY VIAL 3ML",
              "FILTER 0.2 MICRON/25MM": "FILTER 0.2 MICRON/25MM",
              "FILTER 0.2 MICRON/32MM": "FILTER 0.2 MICRON/32MM",
              "FILTER 0.2 MICRON/47MM": "FILTER 0.2 MICRON/47MM",
              "FILTER 0.22 MICRON/73MM/1000ML": "FILTER 0.22 MICRON/73MM/1000ML",
              "FILTER ATTACHMENT": "FILTER ATTACHMENT",
              "FILTER FLUORODYNE/0.22 MICRON": "FILTER FLUORODYNE/0.22 MICRON",
              "FILTER/MILLEX-GP/50MM/CLEAR": "FILTER/MILLEX-GP/50MM/CLEAR",
              "FOIL WRAPPER 3\"\" X 3\"\"": "FOIL WRAPPER 3\"\" X 3\"\"",
              "GLASS BOTTLE 15ML": "GLASS BOTTLE 15ML",
              "GLASS BOTTLE 30ML": "GLASS BOTTLE 30ML",
              "GLASS BOTTLE 30ML/BRUSH CAP": "GLASS BOTTLE 30ML/BRUSH CAP",
              "GLASS BOTTLE 30ML/PHENOLIC CAP": "GLASS BOTTLE 30ML/PHENOLIC CAP",
              "GLASS BOTTLE 60ML": "GLASS BOTTLE 60ML",
              "GLASS SERUM BOTTLES 20ML": "GLASS SERUM BOTTLES 20ML",
              "GLASS SERUM BOTTLES 2ML": "GLASS SERUM BOTTLES 2ML",
              "GLASS SERUM BOTTLES 30ML": "GLASS SERUM BOTTLES 30ML",
              "GLASS SERUM BOTTLES 5ML": "GLASS SERUM BOTTLES 5ML",
              "GLASS VIAL 2ML": "GLASS VIAL 2ML",
              "GLASS VIAL AMBER 3ML": "GLASS VIAL AMBER 3ML",
              "GRADUATED BOTTLE 2OZ": "GRADUATED BOTTLE 2OZ",
              "GRADUATED BOTTLE 4OZ": "GRADUATED BOTTLE 4OZ",
              "HEAD COVERS 24\"\"": "HEAD COVERS 24\"\"",
              "INDICATOR/BIOLOGICAL TEST": "INDICATOR/BIOLOGICAL TEST",
              "INHALATION VIAL CAP/BLUE": "INHALATION VIAL CAP/BLUE",
              "INHALATION VIAL CAP/GREEN": "INHALATION VIAL CAP/GREEN",
              "INHALATION VIAL CAP/ORANGE": "INHALATION VIAL CAP/ORANGE",
              "INHALATION VIAL CAP/RED": "INHALATION VIAL CAP/RED",
              "INHALATION VIAL CAP/WHITE": "INHALATION VIAL CAP/WHITE",
              "INHALATION VIAL CAP/YELLOW": "INHALATION VIAL CAP/YELLOW",
              "INHALATION VIAL W/ CAP/ORANGE": "INHALATION VIAL W/ CAP/ORANGE",
              "INHALATION VIAL W/CAP/BLUE": "INHALATION VIAL W/CAP/BLUE",
              "INHALATION VIAL W/CAP/GREEN": "INHALATION VIAL W/CAP/GREEN",
              "INHALATION VIAL W/CAP/RED": "INHALATION VIAL W/CAP/RED",
              "INHALATION VIAL W/CAP/WHITE": "INHALATION VIAL W/CAP/WHITE",
              "INHALATION VIAL W/CAP/YELLOW": "INHALATION VIAL W/CAP/YELLOW",
              "INHALATION VIAL W/O CAP/AMBER": "INHALATION VIAL W/O CAP/AMBER",
              "INHALATION WORK STAT/50 HOLES": "INHALATION WORK STAT/50 HOLES",
              "JAR/8OZ/WHITE LID": "JAR/8OZ/WHITE LID",
              "JUG AMBER GLASS 4L": "JUG AMBER GLASS 4L",
              "LAB COAT-DISPOSABLE LARGE": "LAB COAT-DISPOSABLE LARGE",
              "LAB COAT-DISPOSABLE MEDIUM": "LAB COAT-DISPOSABLE MEDIUM",
              "LAB COAT-DISPOSABLE SMALL": "LAB COAT-DISPOSABLE SMALL",
              "LAB COAT-DISPOSABLE XL": "LAB COAT-DISPOSABLE XL",
              "LAB COAT-DISPOSABLE XXL": "LAB COAT-DISPOSABLE XXL",
              "LUER TIP CAP TRAY": "LUER TIP CAP TRAY",
              "MAZERUSTAR MIXER/MIX CONTAINER": "MAZERUSTAR MIXER/MIX CONTAINER",
              "MEDI-RDT BLISTER PACKS": "MEDI-RDT BLISTER PACKS",
              "METERED NASAL SPRAY PUMP 15ML": "METERED NASAL SPRAY PUMP 15ML",
              "MINI MALLET 3/4\"\" PLASTIC": "MINI MALLET 3/4\"\" PLASTIC",
              "MIXER/MAZERUSTAR EMP JAR ADP": "MIXER/MAZERUSTAR EMP JAR ADP",
              "MIXER/MAZERUSTAR KK-300SS": "MIXER/MAZERUSTAR KK-300SS",
              "MIXER/MAZERUSTAR KK-400W": "MIXER/MAZERUSTAR KK-400W",
              "MIXER/MAZERUSTAR MD PUMP ADP": "MIXER/MAZERUSTAR MD PUMP ADP",
              "MIXER/MAZERUSTAR/JAR ADP SET": "MIXER/MAZERUSTAR/JAR ADP SET",
              "MIXER/MAZERUSTAR/JAR MXING ADP": "MIXER/MAZERUSTAR/JAR MXING ADP",
              "NAIL POLISH BOTTLE/BRUSH 15ML": "NAIL POLISH BOTTLE/BRUSH 15ML",
              "NASAL SPRAY METERED PUMP": "NASAL SPRAY METERED PUMP",
              "OINTMENT TUBE/METAL 1OZ": "OINTMENT TUBE/METAL 1OZ",
              "OINTMENT TUBE/METAL 2OZ": "OINTMENT TUBE/METAL 2OZ",
              "OINTMENT TUBE/METAL 4OZ": "OINTMENT TUBE/METAL 4OZ",
              "OINTMENT TUBE/OPHTH TIP 1/8OZ": "OINTMENT TUBE/OPHTH TIP 1/8OZ",
              "OINTMENT TUBE/PLASTIC 1OZ": "OINTMENT TUBE/PLASTIC 1OZ",
              "OINTMENT TUBE/PLASTIC 2OZ": "OINTMENT TUBE/PLASTIC 2OZ",
              "OINTMENT TUBE/PLASTIC 4OZ": "OINTMENT TUBE/PLASTIC 4OZ",
              "OINTMENT TUBE/PLASTIC 6OZ": "OINTMENT TUBE/PLASTIC 6OZ",
              "OINTMENT TUBE/PLASTIC 8OZ": "OINTMENT TUBE/PLASTIC 8OZ",
              "PH ACCESSORIES STORAGE SOL": "PH ACCESSORIES STORAGE SOL",
              "PLASTIC BOTTLES 30ML": "PLASTIC BOTTLES 30ML",
              "PLASTIC BOTTLES 90ML": "PLASTIC BOTTLES 90ML",
              "PLASTIC ENEMA BOTTLE": "PLASTIC ENEMA BOTTLE",
              "PLASTIC JAR 6OZ": "PLASTIC JAR 6OZ",
              "PLASTIC SCOOP 1ML": "PLASTIC SCOOP 1ML",
              "POCKET PRO+ REPLACEMENT SENSOR": "POCKET PRO+ REPLACEMENT SENSOR",
              "POLYPROPYLENE CAP-LINER": "POLYPROPYLENE CAP-LINER",
              "POSIDYNE ELD FILTER/0.2UM": "POSIDYNE ELD FILTER/0.2UM",
              "PRESS-IN BOTTLE ADAPTERS": "PRESS-IN BOTTLE ADAPTERS",
              "SAFE-SENSE BEARD NET": "SAFE-SENSE BEARD NET",
              "SAFE-SENSE HEAD COVER 21\"\"": "SAFE-SENSE HEAD COVER 21\"\"",
              "SAFE-SENSE HEAD COVER CIRC 21\"\"": "SAFE-SENSE HEAD COVER CIRC 21\"\"",
              "SAFE-SENSE SHOE COVER NON-SKID": "SAFE-SENSE SHOE COVER NON-SKID",
              "SERUM BOTTLE": "SERUM BOTTLE",
              "SERUM BOTTLE STOPPER 20MM": "SERUM BOTTLE STOPPER 20MM",
              "SERUM BOTTLES/AMBER GLASS 20ML": "SERUM BOTTLES/AMBER GLASS 20ML",
              "SERUM BOTTLES/AMBER GLASS 30ML": "SERUM BOTTLES/AMBER GLASS 30ML",
              "SETTLING PLATE SDA/29ML/100X15": "SETTLING PLATE SDA/29ML/100X15",
              "SETTLING PLATE TSA/25ML/100X15": "SETTLING PLATE TSA/25ML/100X15",
              "SNAP-ON CHLOROBUTYL STOPPER": "SNAP-ON CHLOROBUTYL STOPPER",
              "SPRAY BOTTLE/PLASTIC 120ML": "SPRAY BOTTLE/PLASTIC 120ML",
              "STIRRING ROD/GLASS 12X1/4\"\"": "STIRRING ROD/GLASS 12X1/4\"\"",
              "STRAINER/STAINLESS STEEL/2.5\"\"": "STRAINER/STAINLESS STEEL/2.5\"\"",
              "SUPPOSITORY MOLD/ALUMINUM 2 GM": "SUPPOSITORY MOLD/ALUMINUM 2 GM",
              "SUPPOSITORY SHELL RACK": "SUPPOSITORY SHELL RACK",
              "SUPPOSITORY SHELLS 2.0 ML": "SUPPOSITORY SHELLS 2.0 ML",
              "SUPPOSITORY SHELLS 2.4ML": "SUPPOSITORY SHELLS 2.4ML",
              "SYRINGE DIAL-A-DOSE": "SYRINGE DIAL-A-DOSE",
              "TAP-N-CLICK SILICONE PAD": "TAP-N-CLICK SILICONE PAD",
              "TIP RECTAL/VAG W/PERFORATIONS": "TIP RECTAL/VAG W/PERFORATIONS",
              "TONGUE DEPRESSORS": "TONGUE DEPRESSORS",
              "TOPI-CLICK APPLICATOR": "TOPI-CLICK APPLICATOR",
              "TOPI-CLICK APPLICATOR MICRO": "TOPI-CLICK APPLICATOR MICRO",
              "TOPI-CLICK NOZZLE": "TOPI-CLICK NOZZLE",
              "TOPI-CLICK PERL APPLICATOR 4ML": "TOPI-CLICK PERL APPLICATOR 4ML",
              "TOPI-CLICK PERL DOSE LOAD 35ML": "TOPI-CLICK PERL DOSE LOAD 35ML",
              "TOPI-CLICK PERL VAGINAL DOSING": "TOPI-CLICK PERL VAGINAL DOSING",
              "TROCHE MOLD 30 CAVITY": "TROCHE MOLD 30 CAVITY",
              "TYVEK PROTECTIVE SLEEVES": "TYVEK PROTECTIVE SLEEVES",
              "UNGUATOR 100/200/57MM": "UNGUATOR 100/200/57MM",
              "UNGUATOR 15/20/30/36MM": "UNGUATOR 15/20/30/36MM",
              "UNGUATOR 50/43MM/DISP BLADES": "UNGUATOR 50/43MM/DISP BLADES",
              "UNGUATOR APPLICATOR 1\"\"-SHORT": "UNGUATOR APPLICATOR 1\"\"-SHORT",
              "UNGUATOR APPLICATOR 2.5\"\"-LONG": "UNGUATOR APPLICATOR 2.5\"\"-LONG",
              "UNGUATOR EXACTDOSE 0.5ML": "UNGUATOR EXACTDOSE 0.5ML",
              "UNGUATOR JAR 100/140 BLUE LID": "UNGUATOR JAR 100/140 BLUE LID",
              "UNGUATOR JAR 100/140 RED LID": "UNGUATOR JAR 100/140 RED LID",
              "UNGUATOR JAR 15/20 BLUE LID": "UNGUATOR JAR 15/20 BLUE LID",
              "UNGUATOR JAR 15/20 GREEN LID": "UNGUATOR JAR 15/20 GREEN LID",
              "UNGUATOR JAR 15/20 RED LID": "UNGUATOR JAR 15/20 RED LID",
              "UNGUATOR JAR 15/28 BLUE LID": "UNGUATOR JAR 15/28 BLUE LID",
              "UNGUATOR JAR 20/33 BLUE": "UNGUATOR JAR 20/33 BLUE",
              "UNGUATOR JAR 20/33 RED LID": "UNGUATOR JAR 20/33 RED LID",
              "UNGUATOR JAR 20/33 WHITE": "UNGUATOR JAR 20/33 WHITE",
              "UNGUATOR JAR 200/280 BLUE LID": "UNGUATOR JAR 200/280 BLUE LID",
              "UNGUATOR JAR 200/280 GREEN LID": "UNGUATOR JAR 200/280 GREEN LID",
              "UNGUATOR JAR 200/280 RED LID": "UNGUATOR JAR 200/280 RED LID",
              "UNGUATOR JAR 200/280 WHITE": "UNGUATOR JAR 200/280 WHITE",
              "UNGUATOR JAR 30/42 BLUE": "UNGUATOR JAR 30/42 BLUE",
              "UNGUATOR JAR 30/42 BLUE LID": "UNGUATOR JAR 30/42 BLUE LID",
              "UNGUATOR JAR 30/42 GREEN LID": "UNGUATOR JAR 30/42 GREEN LID",
              "UNGUATOR JAR 30/42 RED LID": "UNGUATOR JAR 30/42 RED LID",
              "UNGUATOR JAR 30/42 TURQUOISE": "UNGUATOR JAR 30/42 TURQUOISE",
              "UNGUATOR JAR 30/42 WHITE LID": "UNGUATOR JAR 30/42 WHITE LID",
              "UNGUATOR JAR 30/42 YELLOW": "UNGUATOR JAR 30/42 YELLOW",
              "UNGUATOR JAR 50/70 BLUE": "UNGUATOR JAR 50/70 BLUE",
              "UNGUATOR JAR 50/70 BLUE LID": "UNGUATOR JAR 50/70 BLUE LID",
              "UNGUATOR JAR 50/70 GREEN LID": "UNGUATOR JAR 50/70 GREEN LID",
              "UNGUATOR JAR 50/70 PINK": "UNGUATOR JAR 50/70 PINK",
              "UNGUATOR JAR 50/70 RED LID": "UNGUATOR JAR 50/70 RED LID",
              "UNGUATOR JAR 50/70 TURQUOISE": "UNGUATOR JAR 50/70 TURQUOISE",
              "UNGUATOR JAR 50/70 WHITE LID": "UNGUATOR JAR 50/70 WHITE LID",
              "UNGUATOR JAR 50/70 YELLOW": "UNGUATOR JAR 50/70 YELLOW",
              "UNGUATOR JAR AIRDYNAMIK": "UNGUATOR JAR AIRDYNAMIK",
              "UNGUATOR JAR W/SPINDLE 300/390": "UNGUATOR JAR W/SPINDLE 300/390",
              "UNGUATOR JAR W/SPINDLE 500/600": "UNGUATOR JAR W/SPINDLE 500/600",
              "UNGUATOR LID 1000ML": "UNGUATOR LID 1000ML",
              "UNGUATOR LID 500ML": "UNGUATOR LID 500ML",
              "UNGUATOR VARIONOZZLE 1MM": "UNGUATOR VARIONOZZLE 1MM",
              "UNGUATOR VARIONOZZLE 4MM": "UNGUATOR VARIONOZZLE 4MM",
              "VAGINAL SUPPOSITORY APPLICATOR": "VAGINAL SUPPOSITORY APPLICATOR",
              "WEIGH BOAT": "WEIGH BOAT"
            },
            "MEDLINE INDUSTRIES": {
              "ADJUSTABLE ALUMINUM CANE": "ADJUSTABLE ALUMINUM CANE",
              "BARIATRIC CANE": "BARIATRIC CANE",
              "BARIATRIC QUAD CANE": "BARIATRIC QUAD CANE",
              "BARIATRIC ROLLATOR": "BARIATRIC ROLLATOR",
              "CANE OFFSET HANDLE": "CANE OFFSET HANDLE",
              "CANE/T-HANDLE": "CANE/T-HANDLE",
              "CRUTCHES-ALUMINUM": "CRUTCHES-ALUMINUM",
              "FOAM CUSHION THERAPEUTIC RING": "FOAM CUSHION THERAPEUTIC RING",
              "GEL-FOAM CUSHION": "GEL-FOAM CUSHION",
              "PADDLE WALKER": "PADDLE WALKER",
              "STEEL COMMODE BEDSIDE": "STEEL COMMODE BEDSIDE",
              "TRANSFER BENCH": "TRANSFER BENCH",
              "WALKER/TWO-BUTTON FOLDING": "WALKER/TWO-BUTTON FOLDING",
              "WHEEL CHAIR K1 BASIC DESK ARM": "WHEEL CHAIR K1 BASIC DESK ARM"
            },
            "MEDQUIP": {
              "FINGERTIP PULSE OXIMETER": "FINGERTIP PULSE OXIMETER"
            },
            "MEDTECH": {
              "THE DOCTORS NIGHTGUARD": "THE DOCTORS NIGHTGUARD"
            },
            "MEDTRONIC MINIMED": {
              "DISPOSABLE POWER": "DISPOSABLE POWER",
              "SHOWER-PAK": "SHOWER-PAK"
            },
            "MHC MEDICAL PRODUCTS": {
              "SURELIFE CLEARWAVE II OXIMETER": "SURELIFE CLEARWAVE II OXIMETER",
              "SURELIFE CLEARWAVE OXIMETER": "SURELIFE CLEARWAVE OXIMETER"
            },
            "MICROMEDICS": {
              "EARPOPPER MIDDLE EAR INFLATION": "EARPOPPER MIDDLE EAR INFLATION"
            },
            "MOLNLYCKE HEALTHCARE": {
              "HIBICLENS DISPENSER NOZZLE": "HIBICLENS DISPENSER NOZZLE",
              "HIBICLENS FOOT PEDAL": "HIBICLENS FOOT PEDAL",
              "HIBICLENS HAND PUMP 16OZ": "HIBICLENS HAND PUMP 16OZ",
              "HIBICLENS HAND PUMP 32OZ": "HIBICLENS HAND PUMP 32OZ",
              "HIBICLENS HAND PUMP GALLON": "HIBICLENS HAND PUMP GALLON",
              "HIBICLENS HAND PUMP NON FOAM": "HIBICLENS HAND PUMP NON FOAM",
              "HIBICLENS PUMP ASSEMBLY": "HIBICLENS PUMP ASSEMBLY",
              "HIBICLENS WALL DISPENSER/FOOT": "HIBICLENS WALL DISPENSER/FOOT",
              "HIBICLENS WALL DISPENSER/HAND": "HIBICLENS WALL DISPENSER/HAND"
            },
            "NEILMED PHARMACEUTICALS": {
              "NASADOCK": "NASADOCK"
            },
            "NESTLE HEALTHCARE NUTRITION": {
              "ENTERALITE INFINITY POLE CLAMP": "ENTERALITE INFINITY POLE CLAMP"
            },
            "NOVA ORTHO-MED": {
              "ADJUSTABLE ALUMINUM CANE 7/8\"\"": "ADJUSTABLE ALUMINUM CANE 7/8\"\"",
              "BARIATRIC ALUMINUM CANE": "BARIATRIC ALUMINUM CANE",
              "CANE/OFFSET HANDLE": "CANE/OFFSET HANDLE",
              "CANE/T-HANDLE": "CANE/T-HANDLE",
              "COMMODE BEDSIDE/BACK": "COMMODE BEDSIDE/BACK",
              "CRUTCH": "CRUTCH",
              "FOLDING WALKER": "FOLDING WALKER",
              "FOLDING WALKER/ADULT": "FOLDING WALKER/ADULT",
              "GETGO ROLLING WALKER": "GETGO ROLLING WALKER",
              "GROOVE ROLLING WALKER": "GROOVE ROLLING WALKER",
              "JOURNEY SERIES ROLLING WALKER": "JOURNEY SERIES ROLLING WALKER",
              "NOVA BATH SEAT": "NOVA BATH SEAT",
              "NOVA CUSHION GEL SEAT PAD": "NOVA CUSHION GEL SEAT PAD",
              "NOVA QUAD TIP-FOUR PRONGS": "NOVA QUAD TIP-FOUR PRONGS",
              "OFFSET CANE": "OFFSET CANE",
              "PULSE OXIMETER FOR FINGER": "PULSE OXIMETER FOR FINGER",
              "TRAVELER 3 WHEEL ROLL WALKER": "TRAVELER 3 WHEEL ROLL WALKER",
              "VIBE 6": "VIBE 6",
              "ZOOM 20 ROLLING WALKER": "ZOOM 20 ROLLING WALKER"
            },
            "OMRON MANAGED HEALTHCARE": {
              "DUAL HEAD STETHOSCOPE": "DUAL HEAD STETHOSCOPE",
              "ELECTROTHERAPY PAIN RELIEF": "ELECTROTHERAPY PAIN RELIEF",
              "HEART RATE MONITOR": "HEART RATE MONITOR",
              "HEART RATE MONITOR STRAPLESS": "HEART RATE MONITOR STRAPLESS",
              "STETHOSCOPE LIGHTWEIGHT": "STETHOSCOPE LIGHTWEIGHT",
              "STETHOSCOPE SINGLE HEAD": "STETHOSCOPE SINGLE HEAD"
            },
            "OPTP": {
              "ORIG MCKENZIE CERVICAL ROLL": "ORIG MCKENZIE CERVICAL ROLL"
            },
            "OWEN MUMFORD": {
              "AMIELLE RESTORE VAG EXERCISERS": "AMIELLE RESTORE VAG EXERCISERS",
              "AMIELLE VAGINAL TRAINER": "AMIELLE VAGINAL TRAINER"
            },
            "PCCA": {
              "AMBER GLASS BOTTLE": "AMBER GLASS BOTTLE",
              "AUTOCLAVE POUCH": "AUTOCLAVE POUCH",
              "BLISTER PACK ADHESIVE LABEL": "BLISTER PACK ADHESIVE LABEL",
              "BLISTER PACK-AMBER 12 CAVITY": "BLISTER PACK-AMBER 12 CAVITY",
              "BOTTLE/BOSTON ROUND NATURAL": "BOTTLE/BOSTON ROUND NATURAL",
              "BOTTLE/THIN WALL": "BOTTLE/THIN WALL",
              "DROPTAINER TIP CAPS": "DROPTAINER TIP CAPS",
              "DROPTAINERS OPHTHALMIC 15ML": "DROPTAINERS OPHTHALMIC 15ML",
              "FILTER 0.20 MICRON/1000ML": "FILTER 0.20 MICRON/1000ML",
              "FOAM RING 2\"\"": "FOAM RING 2\"\"",
              "LAB COAT-DISPOSABLE": "LAB COAT-DISPOSABLE",
              "LUER LOCK ADAPTER": "LUER LOCK ADAPTER",
              "MEGAPUMP": "MEGAPUMP",
              "METERED NASAL SPRAY PUMP": "METERED NASAL SPRAY PUMP",
              "MUCOSAL ATOMIZATION DEVICE": "MUCOSAL ATOMIZATION DEVICE",
              "NASAL SPRAY BOTTLE": "NASAL SPRAY BOTTLE",
              "OINTMENT JAR": "OINTMENT JAR",
              "PCCA ACCUPEN-15": "PCCA ACCUPEN-15",
              "POWDER INSUFFLATOR": "POWDER INSUFFLATOR",
              "SERUM BOTTLE": "SERUM BOTTLE",
              "SUPPOSISTRIP MOLD 1.4GM": "SUPPOSISTRIP MOLD 1.4GM",
              "SUPPOSISTRIP MOLD 1.9GM": "SUPPOSISTRIP MOLD 1.9GM",
              "SUPPOSITORY MOLD": "SUPPOSITORY MOLD",
              "SUPPOSITORY SHELL MED 2.25GM": "SUPPOSITORY SHELL MED 2.25GM",
              "SUPPOSITORY SHELL SMALL 1.46GM": "SUPPOSITORY SHELL SMALL 1.46GM",
              "SYRINGE TIP-RECTAL-VAGINAL": "SYRINGE TIP-RECTAL-VAGINAL",
              "TIP CAPS": "TIP CAPS",
              "TROCHE MOLD 30 CAVITY": "TROCHE MOLD 30 CAVITY",
              "UNIVERSAL SLEEVE COVERS": "UNIVERSAL SLEEVE COVERS",
              "VAGINAL CREAM APPLICATOR": "VAGINAL CREAM APPLICATOR",
              "VAGINAL SUPPOSITORY APPLICATOR": "VAGINAL SUPPOSITORY APPLICATOR",
              "VIAL-SCREW TOP": "VIAL-SCREW TOP"
            },
            "PHARMASMART INTERNATIONAL": {
              "BLOOD PRESSURE SMART CARD": "BLOOD PRESSURE SMART CARD"
            },
            "PILLGUARD MEDICATION": {
              "PILLGUARD DISPENSER": "PILLGUARD DISPENSER",
              "PILLGUARD REFILL CARTRIDGE": "PILLGUARD REFILL CARTRIDGE"
            },
            "POTTYMD": {
              "WET-STOP 3": "WET-STOP 3"
            },
            "PRINCETON RESEARCH": {
              "DIFFUSER ULTRA SONIC": "DIFFUSER ULTRA SONIC",
              "MINI DIFFUSER": "MINI DIFFUSER"
            },
            "PRINCIPLE BUSINESS ENTERPRISES": {
              "THE SIDE RESTER CUSHION": "THE SIDE RESTER CUSHION"
            },
            "QUEST PRODUCTS": {
              "CLINERE EARWAX CLEANERS": "CLINERE EARWAX CLEANERS",
              "CLINERE EARWAX REMOVER": "CLINERE EARWAX REMOVER"
            },
            "RESPIRONICS": {
              "RINOFLOW CHAMBER TUBING": "RINOFLOW CHAMBER TUBING",
              "RINOFLOW NASAL SYSTEM": "RINOFLOW NASAL SYSTEM"
            },
            "RETRACTABLE TECHNOLOGIES": {
              "BLOOD COLLECTION TUBE HOLDER": "BLOOD COLLECTION TUBE HOLDER",
              "VANISHPOINT BLOOD COLLECT SET": "VANISHPOINT BLOOD COLLECT SET",
              "VANISHPOINT TUBE HOLDER": "VANISHPOINT TUBE HOLDER"
            },
            "RITE AID CORPORATION": {
              "RA BANDAGE SCISSORS": "RA BANDAGE SCISSORS",
              "RA COMFORT-FOAM EAR PLUGS": "RA COMFORT-FOAM EAR PLUGS",
              "RA DELUXE CUT N CRUSH": "RA DELUXE CUT N CRUSH",
              "RA EXTRA COMFORT NIGHT PROTECT": "RA EXTRA COMFORT NIGHT PROTECT",
              "RA FOAM EAR PLUGS": "RA FOAM EAR PLUGS",
              "RA FOLDING SYRINGE": "RA FOLDING SYRINGE",
              "RA GLASS DROPPERS": "RA GLASS DROPPERS",
              "RA HAIR STYLING SHEARS": "RA HAIR STYLING SHEARS",
              "RA MEDICINE DROPPER/SPOON": "RA MEDICINE DROPPER/SPOON",
              "RA QUIET PLEASE FOAM EAR PLUGS": "RA QUIET PLEASE FOAM EAR PLUGS",
              "RA REST ASSURED NITE PROTECTOR": "RA REST ASSURED NITE PROTECTOR",
              "RA SITZ BATH": "RA SITZ BATH",
              "RA TABLET CUTTER": "RA TABLET CUTTER"
            },
            "ROCHE INSULIN DELIVERY SYSTEMS": {
              "ADAPTER 2 RED TAPPETS": "ADAPTER 2 RED TAPPETS",
              "ARM STRAP": "ARM STRAP",
              "BATTERY KEY": "BATTERY KEY",
              "BRA/PANT POUCH": "BRA/PANT POUCH",
              "CARTRIDGE FILLING AID 3 ML": "CARTRIDGE FILLING AID 3 ML",
              "CLIP CASE": "CLIP CASE",
              "GREY ADAPTER/2 RED TAPPETS": "GREY ADAPTER/2 RED TAPPETS",
              "LEATHER CASE": "LEATHER CASE",
              "NEOPRENE BELT CASE": "NEOPRENE BELT CASE",
              "NYLON BELT CASE": "NYLON BELT CASE",
              "PISTON ROD": "PISTON ROD",
              "PLASTIC LOCKING CASE CLIP": "PLASTIC LOCKING CASE CLIP",
              "SEW-IN POCKET": "SEW-IN POCKET",
              "SPORT/BATHING BELT": "SPORT/BATHING BELT",
              "WAIST BELT": "WAIST BELT",
              "WATERPROOF POWER PACK": "WATERPROOF POWER PACK"
            },
            "RX TIMER CAP": {
              "RX TIMER CAP 13/16 DRAM": "RX TIMER CAP 13/16 DRAM",
              "RX TIMER CAP 30/40/60 DRAM": "RX TIMER CAP 30/40/60 DRAM"
            },
            "SAGE PRODUCTS": {
              "14-COUNT WARMER": "14-COUNT WARMER",
              "COMFORT PERSONAL CLEANS CART": "COMFORT PERSONAL CLEANS CART",
              "COMFORT PERSONAL MICROWAVE": "COMFORT PERSONAL MICROWAVE",
              "COMFORT PERSONAL SHAMPOO CAP": "COMFORT PERSONAL SHAMPOO CAP",
              "COMFORT PERSONAL WARMER 14-CT": "COMFORT PERSONAL WARMER 14-CT",
              "COMFORT PERSONAL WARMER 28-CT": "COMFORT PERSONAL WARMER 28-CT",
              "LOS YANKAUER HOLDER": "LOS YANKAUER HOLDER",
              "TOOTHETTE BITE BLOCK": "TOOTHETTE BITE BLOCK"
            },
            "SDI USA": {
              "CLEVER CHOICE BMI SCALE": "CLEVER CHOICE BMI SCALE"
            },
            "SHARPS COMPLIANCE": {
              "TAKEAWAY ENVIRONMENTAL RETURN": "TAKEAWAY ENVIRONMENTAL RETURN"
            },
            "SHEFFIELD PHARMACEUTICALS": {
              "ONE STEP AT A TIME FILTERS": "ONE STEP AT A TIME FILTERS"
            },
            "SIMPLE DIAGNOSTICS": {
              "CLEVER CHOICE BREAST PUMP": "CLEVER CHOICE BREAST PUMP",
              "CLEVER CHOICE PULSE OXIMETER": "CLEVER CHOICE PULSE OXIMETER"
            },
            "SIMPRO": {
              "NATURESPIRIT": "NATURESPIRIT"
            },
            "SLP LTD": {
              "APNEASTRIP": "APNEASTRIP"
            },
            "SMITH & NEPHEW UNITED": {
              "VERSAJET EXACT 14MM": "VERSAJET EXACT 14MM",
              "VERSAJET EXACT 8MM": "VERSAJET EXACT 8MM",
              "VERSAJET II EXACT 14MM": "VERSAJET II EXACT 14MM",
              "VERSAJET II EXACT 8MM": "VERSAJET II EXACT 8MM",
              "VERSAJET II PLUS 14MM": "VERSAJET II PLUS 14MM",
              "VERSAJET II PLUS 8MM": "VERSAJET II PLUS 8MM",
              "VERSAJET PLUS 14MM": "VERSAJET PLUS 14MM",
              "VERSAJET PLUS 8MM": "VERSAJET PLUS 8MM"
            },
            "SMITHS MEDICAL MD, INC": {
              "BELT CLIP": "BELT CLIP",
              "IR ADAPTER": "IR ADAPTER"
            },
            "SOUTHWEST TECHNOLOGIES": {
              "CRUTCH-MATE ADULT ARM": "CRUTCH-MATE ADULT ARM",
              "CRUTCH-MATE ADULT FOREARM": "CRUTCH-MATE ADULT FOREARM",
              "CRUTCH-MATE ADULT HAND GRIP LG": "CRUTCH-MATE ADULT HAND GRIP LG",
              "CRUTCH-MATE ADULT HAND GRIPS": "CRUTCH-MATE ADULT HAND GRIPS"
            },
            "SPECTRUM": {
              "TOPI-CLICK 1 PORT": "TOPI-CLICK 1 PORT",
              "TOPI-CLICK 140": "TOPI-CLICK 140",
              "TOPI-CLICK 3 PORT": "TOPI-CLICK 3 PORT",
              "TOPI-CLICK DOSE CHECK": "TOPI-CLICK DOSE CHECK",
              "TOPI-CLICK MICRO ANGLED AA": "TOPI-CLICK MICRO ANGLED AA",
              "TOPI-CLICK MICRO PIN POINT AA": "TOPI-CLICK MICRO PIN POINT AA",
              "TOPI-CLICK MICRO ROUNDED AA": "TOPI-CLICK MICRO ROUNDED AA",
              "TOPI-CLICK UV BLOCKING": "TOPI-CLICK UV BLOCKING",
              "TOPI-CLICK VAGINAL APPLICATOR": "TOPI-CLICK VAGINAL APPLICATOR",
              "TOPI-CLICK VAGINAL DOSE LOADER": "TOPI-CLICK VAGINAL DOSE LOADER",
              "TOPI-CLICK VAGINAL DOSING": "TOPI-CLICK VAGINAL DOSING"
            },
            "SPENCO MEDICAL CORPORATION": {
              "SPENCO SILICORE BED PAD": "SPENCO SILICORE BED PAD",
              "SPENCO SILICORE CHAIR PAD": "SPENCO SILICORE CHAIR PAD",
              "SPENCO SILICORE FOOT PILLOWS": "SPENCO SILICORE FOOT PILLOWS",
              "SPENCO SILICORE FOOT POSITION": "SPENCO SILICORE FOOT POSITION",
              "SPENCO SILICORE WHEELCHAIR PAD": "SPENCO SILICORE WHEELCHAIR PAD"
            },
            "SPLINTEK": {
              "SLEEPRIGHT BREATHE AID": "SLEEPRIGHT BREATHE AID",
              "SLEEPRIGHT DENTAL GUARD": "SLEEPRIGHT DENTAL GUARD",
              "SLEEPRIGHT DENTAL GUARD DURA": "SLEEPRIGHT DENTAL GUARD DURA",
              "SLEEPRIGHT DENTAL GUARD SLIM": "SLEEPRIGHT DENTAL GUARD SLIM",
              "SLEEPRIGHT SPORT BREATHE AID": "SLEEPRIGHT SPORT BREATHE AID",
              "SLEEPRIGHT VAPOR INHALER": "SLEEPRIGHT VAPOR INHALER",
              "TONGUE CLEANER/COMFORT CURVE": "TONGUE CLEANER/COMFORT CURVE"
            },
            "SPRY HEALTH": {
              "LOOP": "LOOP"
            },
            "SUNBEAM": {
              "BREAST SELF EXAM PROGRAM": "BREAST SELF EXAM PROGRAM",
              "STETHOSCOPE": "STETHOSCOPE",
              "SUNBEAM KING COVER": "SUNBEAM KING COVER",
              "SUNBEAM STANDARD COVER": "SUNBEAM STANDARD COVER"
            },
            "SUNRISE MEDICAL HHG INC.": {
              "GLASS POWDER BLOWER": "GLASS POWDER BLOWER"
            },
            "SURGICAL APPLIANCE INDUSTRIES": {
              "CERVICAL TRACTION": "CERVICAL TRACTION",
              "STOCKING APPLICATOR PETITE": "STOCKING APPLICATOR PETITE",
              "STOCKING APPLICATOR REGULAR": "STOCKING APPLICATOR REGULAR"
            },
            "TELEFLEX MEDICAL": {
              "LMA MAD NASAL": "LMA MAD NASAL",
              "MAD NASAL": "MAD NASAL"
            },
            "THE HYGENIC CORP": {
              "THERA-BAND EXERCISE BANDS": "THERA-BAND EXERCISE BANDS"
            },
            "UROCARE PRODUCTS, INC.": {
              "DRI-SLEEPER": "DRI-SLEEPER",
              "TRACHO-FOAM DISCS EXTRA-LARGE": "TRACHO-FOAM DISCS EXTRA-LARGE",
              "TRACHO-FOAM DISCS LARGE": "TRACHO-FOAM DISCS LARGE",
              "TRACHO-FOAM DISCS STANDARD": "TRACHO-FOAM DISCS STANDARD"
            },
            "VIDA HEALTH": {
              "VIDA CELLULAR SCALE": "VIDA CELLULAR SCALE"
            },
            "VIVONEX": {
              "NEXDOSE DIGITAL ASSISTANT": "NEXDOSE DIGITAL ASSISTANT"
            },
            "WAHL CLIPPER": {
              "ALL-BODY MASSAGE": "ALL-BODY MASSAGE",
              "CUSTOM-FLEX": "CUSTOM-FLEX",
              "DEEP-TISSUE": "DEEP-TISSUE",
              "FLEX THERAPY": "FLEX THERAPY",
              "HEAT THERAPY": "HEAT THERAPY",
              "HOT-COLD THERAPY": "HOT-COLD THERAPY"
            },
            "WAL-MART": {
              "EQ BATH & SHOWER SEAT/BACK": "EQ BATH & SHOWER SEAT/BACK",
              "EQ FOLDING WALKER": "EQ FOLDING WALKER",
              "RELION PULSE OXIMETER": "RELION PULSE OXIMETER"
            },
            "WALGREENS": {
              "3-IN-1 BEDSIDE TOILET": "3-IN-1 BEDSIDE TOILET",
              "BAMBOO CANE": "BAMBOO CANE",
              "BANDAGE SCISSORS": "BANDAGE SCISSORS",
              "BATH BENCH WITH BACK": "BATH BENCH WITH BACK",
              "BI-FOCAL MAGNIFIER": "BI-FOCAL MAGNIFIER",
              "CANE TIPS 3/4\"\"": "CANE TIPS 3/4\"\"",
              "CANE TIPS 7/8\"\"": "CANE TIPS 7/8\"\"",
              "CRUTCH ACCESSORY KIT": "CRUTCH ACCESSORY KIT",
              "CRUTCH PILLOWS/ARM/HAND": "CRUTCH PILLOWS/ARM/HAND",
              "DENTAL GUARD": "DENTAL GUARD",
              "DIGITAL GLASS SCALE": "DIGITAL GLASS SCALE",
              "EAR WAX REMOVAL/TRI-STREAM TIP": "EAR WAX REMOVAL/TRI-STREAM TIP",
              "EXTENDABLE BEDSIDE RAIL": "EXTENDABLE BEDSIDE RAIL",
              "EYE/EAR DROPPER": "EYE/EAR DROPPER",
              "FASHION CANE/T-HANDLE": "FASHION CANE/T-HANDLE",
              "FLEX & GO FOLDING CANE": "FLEX & GO FOLDING CANE",
              "FLIGHT EAR PLUGS": "FLIGHT EAR PLUGS",
              "FOAM CHAIR CUSHION": "FOAM CHAIR CUSHION",
              "FOAM CUSHION": "FOAM CUSHION",
              "FOAM EAR PLUGS": "FOAM EAR PLUGS",
              "FOLDING CANE": "FOLDING CANE",
              "FOLDING PADDLE WALKER": "FOLDING PADDLE WALKER",
              "KEGEL BALL TRAINER": "KEGEL BALL TRAINER",
              "LUMBAR CUSHION": "LUMBAR CUSHION",
              "MAGNIFIER HANDS-FREE": "MAGNIFIER HANDS-FREE",
              "MASSAGER": "MASSAGER",
              "MEDICINE DROPPER": "MEDICINE DROPPER",
              "MEDICINE DROPPER/CALIBRATED": "MEDICINE DROPPER/CALIBRATED",
              "MEDICINE SPOON": "MEDICINE SPOON",
              "NATURAL WOOD CANE": "NATURAL WOOD CANE",
              "NATURAL WOOD WALKING STICK": "NATURAL WOOD WALKING STICK",
              "NOSEBLEED PLUGS/TOWELETTES": "NOSEBLEED PLUGS/TOWELETTES",
              "OFFSET CANE": "OFFSET CANE",
              "ORAL SYRINGE/BRUSH": "ORAL SYRINGE/BRUSH",
              "PILL CRUSHER": "PILL CRUSHER",
              "PILL POUCH": "PILL POUCH",
              "PLATINUM REACHER 31\"\"": "PLATINUM REACHER 31\"\"",
              "POCKET MAGNIFIER": "POCKET MAGNIFIER",
              "PREMIUM PILL CRUSHER": "PREMIUM PILL CRUSHER",
              "PULSE OXIMETER": "PULSE OXIMETER",
              "QUAD CANE TIPS": "QUAD CANE TIPS",
              "QUAD CANE/SMALL BASE": "QUAD CANE/SMALL BASE",
              "QUICK-FIT CRUTCHES": "QUICK-FIT CRUTCHES",
              "RAISED TOILET SEAT/LOCK": "RAISED TOILET SEAT/LOCK",
              "RAISED TOILET SEAT/LOCK & ARMS": "RAISED TOILET SEAT/LOCK & ARMS",
              "ROLLATOR ULTRA-LIGHT": "ROLLATOR ULTRA-LIGHT",
              "SILICONE EAR PLUGS": "SILICONE EAR PLUGS",
              "SILICONE EAR PLUGS FOR KIDS": "SILICONE EAR PLUGS FOR KIDS",
              "SITZ BATH": "SITZ BATH",
              "STEP COUNTER": "STEP COUNTER",
              "STOP LICE EGG & NIT REMOVAL": "STOP LICE EGG & NIT REMOVAL",
              "SUCTION GRAB BAR": "SUCTION GRAB BAR",
              "SWIM EARPLUGS": "SWIM EARPLUGS",
              "TABLET CUTTER-CRUSHER": "TABLET CUTTER-CRUSHER",
              "TABLET CUTTER/CRUSHER": "TABLET CUTTER/CRUSHER",
              "TABLET CUTTER/DELUXE SAFETY": "TABLET CUTTER/DELUXE SAFETY",
              "TABLET CUTTER/SAFETY SHIELD": "TABLET CUTTER/SAFETY SHIELD",
              "TRANSFER BENCH": "TRANSFER BENCH",
              "TRANSPORT CHAIR": "TRANSPORT CHAIR",
              "VINYL INFLATABLE CUSHION": "VINYL INFLATABLE CUSHION",
              "WASH GLOVES PRE-MOISTENED": "WASH GLOVES PRE-MOISTENED",
              "WORK BELT": "WORK BELT",
              "WRIST BRACE": "WRIST BRACE",
              "WRIST SLEEP SUPPORT": "WRIST SLEEP SUPPORT"
            },
            "ZEWA": {
              "ELECTRODES 2\"\"X2\"\"/REUSABLE": "ELECTRODES 2\"\"X2\"\"/REUSABLE",
              "ZEWA ELECTRODES": "ZEWA ELECTRODES"
            }
          },
          "Nerve Stimulators": {
            "ARISE MEDICAL": {
              "PROCARE TENS & EMS": "PROCARE TENS & EMS"
            },
            "BAYER CONSUMER": {
              "ALEVE DIRECT THERAPY TENS": "ALEVE DIRECT THERAPY TENS"
            },
            "CEFALY TECHNOLOGY": {
              "CEFALY ELECTRODE": "CEFALY ELECTRODE",
              "CEFALY KIT": "CEFALY KIT"
            },
            "CHATTEM INC": {
              "ICY HOT SMART RELIEF TENS": "ICY HOT SMART RELIEF TENS"
            },
            "ELECTROCORE": {
              "GAMMACORE": "GAMMACORE",
              "GAMMACORE SAPPHIRE": "GAMMACORE SAPPHIRE",
              "GAMMACORE SAPPHIRE 31-DAY": "GAMMACORE SAPPHIRE 31-DAY",
              "GAMMACORE SAPPHIRE REFILL KIT": "GAMMACORE SAPPHIRE REFILL KIT"
            },
            "EMJOI": {
              "EMJOI TENS": "EMJOI TENS"
            },
            "ESSENTIAL MEDICAL SUPPLY": {
              "PAIN RELIEF WITH TENS S2000": "PAIN RELIEF WITH TENS S2000",
              "STANDARD TENS": "STANDARD TENS"
            },
            "HOME AIDE DIAGNOSTICS": {
              "PRO COMFORT TENS ELECTRODES": "PRO COMFORT TENS ELECTRODES",
              "PRO COMFORT TENS UNIT": "PRO COMFORT TENS UNIT"
            },
            "NEUROSIGMA": {
              "MONARCH ETNS SYSTEM": "MONARCH ETNS SYSTEM",
              "NS-2 ELECTRIC PATCH POUCH": "NS-2 ELECTRIC PATCH POUCH"
            },
            "OMRON MANAGED HEALTHCARE": {
              "PAIN RELIEF PRO": "PAIN RELIEF PRO",
              "TENS THERAPY PAIN RELIEF": "TENS THERAPY PAIN RELIEF"
            },
            "RITE AID CORPORATION": {
              "RA SINGLE CHANNEL PAIN RELIEF": "RA SINGLE CHANNEL PAIN RELIEF"
            },
            "SIMPLE DIAGNOSTICS": {
              "CLEVER CHOICE TENS UNIT": "CLEVER CHOICE TENS UNIT"
            },
            "THERANICA BIO-ELECTRONICS": {
              "NERIVIO": "NERIVIO"
            },
            "VERIDIAN HEALTHCARE": {
              "TENS WIRED PAIN MANAGEMENT": "TENS WIRED PAIN MANAGEMENT"
            },
            "WALGREENS": {
              "TENS THERAPY PAIN RELIEF": "TENS THERAPY PAIN RELIEF",
              "TENS THERAPY REPLACE BACK PADS": "TENS THERAPY REPLACE BACK PADS",
              "TENS THERAPY REPLACE BODY PADS": "TENS THERAPY REPLACE BODY PADS"
            },
            "ZEWA": {
              "ZEWA DIGITAL TENS UNIT": "ZEWA DIGITAL TENS UNIT",
              "ZEWA TENS/EMS COMBO UNIT": "ZEWA TENS/EMS COMBO UNIT"
            }
          },
          "Neurological Diagnostic Supplies": {
            "MEDIPIN LIMITED": {
              "DIABETIC TOES TEST/MEDIPIN": "DIABETIC TOES TEST/MEDIPIN",
              "MEDIPIN": "MEDIPIN"
            },
            "OWEN MUMFORD": {
              "NEUROPEN": "NEUROPEN",
              "NEUROPEN MONOFILAMENTS": "NEUROPEN MONOFILAMENTS",
              "NEUROTIPS": "NEUROTIPS"
            }
          },
          "Oral Dosing Devices": {
            "APOTHECARY PRODUCTS, INC.": {
              "EZY DOSE 30 DAY PILL REMINDER": "EZY DOSE 30 DAY PILL REMINDER",
              "EZY DOSE 7-DAY PILL REMINDER": "EZY DOSE 7-DAY PILL REMINDER",
              "EZY DOSE MEDTIME PLANNER": "EZY DOSE MEDTIME PLANNER",
              "EZY DOSE VITAMIN ORGANIZER": "EZY DOSE VITAMIN ORGANIZER",
              "PILL PLANNER": "PILL PLANNER"
            },
            "BD MEDICAL SURGICAL SYSTEMS": {
              "ORAL SYRINGE BOTTLE ADAPTER": "ORAL SYRINGE BOTTLE ADAPTER",
              "ORAL SYRINGE FILLING CONNECTOR": "ORAL SYRINGE FILLING CONNECTOR",
              "ORAL SYRINGE/TIP CAP 10ML": "ORAL SYRINGE/TIP CAP 10ML",
              "ORAL SYRINGE/TIP CAP 1ML": "ORAL SYRINGE/TIP CAP 1ML",
              "ORAL SYRINGE/TIP CAP 3ML": "ORAL SYRINGE/TIP CAP 3ML",
              "ORAL SYRINGE/TIP CAP 5ML": "ORAL SYRINGE/TIP CAP 5ML",
              "TAMPER-TUF CONTAINER CLOSER": "TAMPER-TUF CONTAINER CLOSER",
              "TAMPER-TUF ORAL MED CONT 15CC": "TAMPER-TUF ORAL MED CONT 15CC",
              "TAMPER-TUF ORAL MED CONT 30CC": "TAMPER-TUF ORAL MED CONT 30CC"
            },
            "CHAIN DRUG CONSORTIUM": {
              "AM/PM PILL BOX": "AM/PM PILL BOX",
              "CONTOUR PILL BOX": "CONTOUR PILL BOX",
              "DAILY PILL BOX": "DAILY PILL BOX",
              "ORAL SYRINGE": "ORAL SYRINGE",
              "PILL REMINDER": "PILL REMINDER",
              "WEEKLY MEDICATION ORGANIZER": "WEEKLY MEDICATION ORGANIZER"
            },
            "COVIDIEN MEDICAL SUPPLIES": {
              "MONOJECT MONODOSE ORAL MED SYR": "MONOJECT MONODOSE ORAL MED SYR",
              "MONOJECT ORAL SYRINGE": "MONOJECT ORAL SYRINGE"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS WEEKLY PILL PLANNER": "CVS WEEKLY PILL PLANNER",
              "CVS WEEKLY VITAMIN PLANNER": "CVS WEEKLY VITAMIN PLANNER"
            },
            "GATEKEEPER INNOVATION": {
              "SAFER LOCK": "SAFER LOCK",
              "SAFER LOCK BOX": "SAFER LOCK BOX"
            },
            "LEADER BRAND PRODUCTS": {
              "MEDTIME PLANNER": "MEDTIME PLANNER",
              "PILL REMINDER": "PILL REMINDER",
              "POCKET PILL CADDY": "POCKET PILL CADDY",
              "TRAVEL POD-AM/PM PILL REMINDER": "TRAVEL POD-AM/PM PILL REMINDER"
            },
            "MEDISCA": {
              "SYRINGE PRECISEDOSE DISPENSER": "SYRINGE PRECISEDOSE DISPENSER"
            },
            "PCCA": {
              "EXACTA-MED ORAL SYRINGE 5ML": "EXACTA-MED ORAL SYRINGE 5ML"
            },
            "RITE AID CORPORATION": {
              "RA AM/PM PILL REMINDER 7 DAY": "RA AM/PM PILL REMINDER 7 DAY",
              "RA ORAL SYRINGE": "RA ORAL SYRINGE",
              "RA PHARMADOSE ORGANIZER": "RA PHARMADOSE ORGANIZER",
              "RA PILL REMINDER 7-DAY AM/PM": "RA PILL REMINDER 7-DAY AM/PM",
              "RA PUSH N POP PILL REMINDER": "RA PUSH N POP PILL REMINDER",
              "RA WEEKLY PILL REMINDER XLARGE": "RA WEEKLY PILL REMINDER XLARGE"
            },
            "WALGREENS": {
              "ADULT-LOCK WEEKLY PILL REMIND": "ADULT-LOCK WEEKLY PILL REMIND",
              "ALARM CLOCK PILL BOX": "ALARM CLOCK PILL BOX",
              "DAILY MEDICATION CONTAINER": "DAILY MEDICATION CONTAINER",
              "DIAMOND CUT PILL CASE": "DIAMOND CUT PILL CASE",
              "MEDICATION CONTAINER/LARGE": "MEDICATION CONTAINER/LARGE",
              "MEDICATION CONTAINER/SMALL": "MEDICATION CONTAINER/SMALL",
              "MEDTIME PLANNER": "MEDTIME PLANNER",
              "ONE DAY AT A TIME PILL REMIND": "ONE DAY AT A TIME PILL REMIND",
              "PILL CASE": "PILL CASE",
              "PILL CASE/LOCK": "PILL CASE/LOCK",
              "PILL FOB KEY RING": "PILL FOB KEY RING",
              "PILL ORGANIZER 7-DAY LARGE": "PILL ORGANIZER 7-DAY LARGE",
              "PILL ORGANIZER EXTRA LARGE": "PILL ORGANIZER EXTRA LARGE",
              "PILL REMINDER": "PILL REMINDER",
              "PILL REMINDER 7-DAY": "PILL REMINDER 7-DAY",
              "PILL REMINDER SEVEN SIDED": "PILL REMINDER SEVEN SIDED",
              "PILL REMINDER STACKABLE": "PILL REMINDER STACKABLE",
              "PILL REMINDER/PEN HOLDER": "PILL REMINDER/PEN HOLDER",
              "POCKET/PURSE PILL CASE": "POCKET/PURSE PILL CASE"
            }
          },
          "Razors and Blades": {
            "BD MEDICAL SURGICAL SYSTEMS": {
              "SCHICK DISPOSABLE RAZORS": "SCHICK DISPOSABLE RAZORS",
              "SCHICK KIT": "SCHICK KIT",
              "SCHICK SHAVE PREP TRAY": "SCHICK SHAVE PREP TRAY"
            },
            "BERGEN BRUNSWIG": {
              "GNP FOUR BLADE DISPOSABLE": "GNP FOUR BLADE DISPOSABLE",
              "GNP SIX BLADE": "GNP SIX BLADE",
              "GNP SIX BLADE CARTRIDGES": "GNP SIX BLADE CARTRIDGES",
              "GNP TWIN BLADE DISPOSABLE": "GNP TWIN BLADE DISPOSABLE"
            },
            "EQUALINE": {
              "EQL 5 BLADE CARTRIDGES/TRIMMER": "EQL 5 BLADE CARTRIDGES/TRIMMER",
              "EQL 5 BLADE RAZOR SYSTEM": "EQL 5 BLADE RAZOR SYSTEM",
              "EQL MENS TWIN BLADE/DISPOSABLE": "EQL MENS TWIN BLADE/DISPOSABLE",
              "EQL TRIPLE BLADE DISPOSABLE": "EQL TRIPLE BLADE DISPOSABLE"
            }
          },
          "Rubber Goods": {
            "APOTHECARY PRODUCTS, INC.": {
              "EAR SYRINGE": "EAR SYRINGE"
            },
            "BERGEN BRUNSWIG": {
              "GNP NASAL ASPIRATOR INFANT": "GNP NASAL ASPIRATOR INFANT"
            },
            "CHAIN DRUG CONSORTIUM": {
              "COMBINATION WATER BOTTLE": "COMBINATION WATER BOTTLE",
              "EAR SYRINGE": "EAR SYRINGE",
              "WATER BOTTLE": "WATER BOTTLE"
            },
            "DAVOL": {
              "NIPPLE SHIELDS": "NIPPLE SHIELDS"
            },
            "HEALTH ENTERPRISES": {
              "EAR SYRINGE": "EAR SYRINGE",
              "NASAL ASPIRATOR": "NASAL ASPIRATOR"
            },
            "KAZ": {
              "DUNLAP COMBINATION BOTTLE/SYR": "DUNLAP COMBINATION BOTTLE/SYR",
              "DUNLAP EAR SYRINGE": "DUNLAP EAR SYRINGE",
              "DUNLAP ENEMA SYRINGE": "DUNLAP ENEMA SYRINGE",
              "DUNLAP FOUNTAIN SYRINGE": "DUNLAP FOUNTAIN SYRINGE",
              "DUNLAP WATER BOTTLE": "DUNLAP WATER BOTTLE",
              "EAR SYRINGE": "EAR SYRINGE",
              "ENEMA SYRINGE": "ENEMA SYRINGE",
              "NASAL ASPIRATOR": "NASAL ASPIRATOR"
            },
            "LEADER BRAND PRODUCTS": {
              "NASAL ASPIRATOR": "NASAL ASPIRATOR"
            },
            "MCKESSON": {
              "HM EAR SYRINGE CHILD": "HM EAR SYRINGE CHILD",
              "HM EAR SYRINGE INFANT": "HM EAR SYRINGE INFANT",
              "HM NASAL ASPIRATOR": "HM NASAL ASPIRATOR"
            },
            "MCKESSON HOME HEALTH CARE": {
              "EAR SYRINGE": "EAR SYRINGE",
              "ENEMA SYRINGE": "ENEMA SYRINGE",
              "FEMININE BULB SYRINGE": "FEMININE BULB SYRINGE",
              "FEMININE COMPACT TRAVEL SYRNGE": "FEMININE COMPACT TRAVEL SYRNGE",
              "FEMININE FOLDING SYRINGE": "FEMININE FOLDING SYRINGE",
              "FOUNTAIN SYRINGE": "FOUNTAIN SYRINGE",
              "NASAL ASPIRATOR": "NASAL ASPIRATOR",
              "RECTAL SYRINGE": "RECTAL SYRINGE",
              "WATER BOTTLE": "WATER BOTTLE"
            },
            "MEDISCA": {
              "ENEMA BOTTLE": "ENEMA BOTTLE"
            },
            "RITE AID CORPORATION": {
              "RA EAR SYRINGE": "RA EAR SYRINGE",
              "RA FEMININE BULB SYRINGE": "RA FEMININE BULB SYRINGE",
              "RA INFANT CARE EAR SYRINGE": "RA INFANT CARE EAR SYRINGE",
              "RA WATER BOTTLE/SYRINGE": "RA WATER BOTTLE/SYRINGE"
            },
            "SUNBEAM": {
              "COMBINATION SYRINGE 2QT": "COMBINATION SYRINGE 2QT",
              "COMPACT DOUCHE SYSTEM 16OZ": "COMPACT DOUCHE SYSTEM 16OZ",
              "DOUCHE BULB/ATTACHMENT 10OZ": "DOUCHE BULB/ATTACHMENT 10OZ",
              "EAR SYRINGE": "EAR SYRINGE",
              "ENEMA SYRINGE": "ENEMA SYRINGE",
              "FOLDING DOUCHE SYSTEM 2QT": "FOLDING DOUCHE SYSTEM 2QT",
              "FOUNTAIN SYRINGE 2QT": "FOUNTAIN SYRINGE 2QT",
              "NASAL ASPIRATOR": "NASAL ASPIRATOR",
              "SUNBEAM WATER BOTTLE": "SUNBEAM WATER BOTTLE"
            },
            "WALGREENS": {
              "DOUCHE/ENEMA/WATER BOTTLE SYS": "DOUCHE/ENEMA/WATER BOTTLE SYS",
              "EAR SYRINGE": "EAR SYRINGE",
              "TRAVEL DOUCHE SYSTEM": "TRAVEL DOUCHE SYSTEM",
              "WAGI BABY NASAL ASPIRATOR": "WAGI BABY NASAL ASPIRATOR",
              "WATER BOTTLE": "WATER BOTTLE"
            }
          },
          "Seizure Monitoring Products": {
            "EMPATICA": {
              "EMBRACE SEIZURE MONITORING SYS": "EMBRACE SEIZURE MONITORING SYS"
            }
          },
          "Thermometers": {
            "A & D MEDICAL": {
              "DIGITAL THERMOMETER": "DIGITAL THERMOMETER"
            },
            "AKRON PHARMA": {
              "NEODOT THERMOMETER": "NEODOT THERMOMETER"
            },
            "ARISE MEDICAL": {
              "PROCARE INFRARED THERMOMETER": "PROCARE INFRARED THERMOMETER"
            },
            "AUM PHARMACEUTICALS": {
              "NEODOT INFRARED THERMOMETER": "NEODOT INFRARED THERMOMETER"
            },
            "BD DIABETES CARE": {
              "ASEPTO FEVER THERMOMETER/ORAL": "ASEPTO FEVER THERMOMETER/ORAL",
              "ASEPTO FEVER THERMOMETER/RECT": "ASEPTO FEVER THERMOMETER/RECT",
              "BASAL THERMOMETER": "BASAL THERMOMETER",
              "BD ASSURE IR THERMOMETER": "BD ASSURE IR THERMOMETER",
              "BD THERMOMETER": "BD THERMOMETER",
              "BD THERMOMETER BABY/CASE": "BD THERMOMETER BABY/CASE",
              "BD THERMOMETER BASAL": "BD THERMOMETER BASAL",
              "BD THERMOMETER ORAL": "BD THERMOMETER ORAL",
              "BD THERMOMETER ORAL SHEATHS": "BD THERMOMETER ORAL SHEATHS",
              "BD THERMOMETER RECTAL": "BD THERMOMETER RECTAL",
              "BD THERMOMETER RECTAL SHEATH": "BD THERMOMETER RECTAL SHEATH",
              "DIGITAL FEVER THERMOMETER": "DIGITAL FEVER THERMOMETER",
              "HYPOTHERM FARENHEIT THERMOMET": "HYPOTHERM FARENHEIT THERMOMET",
              "SENTINEL THERMOMETER BABY": "SENTINEL THERMOMETER BABY",
              "SENTINEL THERMOMETER ORAL": "SENTINEL THERMOMETER ORAL"
            },
            "BD MEDICAL SURGICAL SYSTEMS": {
              "ASEPTO ORAL THERMOMETER": "ASEPTO ORAL THERMOMETER",
              "ASEPTO RECTAL THERMOMETER": "ASEPTO RECTAL THERMOMETER",
              "BRITE SIGHT ORAL THERMOMETER": "BRITE SIGHT ORAL THERMOMETER",
              "BRITE SIGHT RECTAL THERMOMETER": "BRITE SIGHT RECTAL THERMOMETER",
              "BRITE SIGHT THERMOMETER": "BRITE SIGHT THERMOMETER",
              "DIGITAL THERMOM PROBE COVERS": "DIGITAL THERMOM PROBE COVERS",
              "GLASS THERMOMETER": "GLASS THERMOMETER",
              "HYPOTHERM CELCIUS THERMOMETER": "HYPOTHERM CELCIUS THERMOMETER",
              "TEMP-AWAY SHEATHS": "TEMP-AWAY SHEATHS",
              "THERMOMETER SHEATHS": "THERMOMETER SHEATHS"
            },
            "BERGEN BRUNSWIG": {
              "GNP DIGITAL THERMOMETER": "GNP DIGITAL THERMOMETER",
              "GNP FEVER FLASH THERMOMETER": "GNP FEVER FLASH THERMOMETER",
              "GNP ORAL THERMOMETER": "GNP ORAL THERMOMETER"
            },
            "CHAIN DRUG CONSORTIUM": {
              "DELUXE INSTANT EAR THERMOMETER": "DELUXE INSTANT EAR THERMOMETER",
              "DIGITAL TEMPLE THERMOMETER": "DIGITAL TEMPLE THERMOMETER",
              "DIGITAL THERMOM PROBE COVERS": "DIGITAL THERMOM PROBE COVERS",
              "DIGITAL THERMOMETER": "DIGITAL THERMOMETER",
              "EAR THERMOMETER": "EAR THERMOMETER",
              "PROBE COVERS": "PROBE COVERS"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS DIGITAL EAR THERMOMETER": "CVS DIGITAL EAR THERMOMETER",
              "CVS DIGITAL THERM FLEXIBLE TIP": "CVS DIGITAL THERM FLEXIBLE TIP",
              "CVS DIGITAL THERM MICRO TEMPLE": "CVS DIGITAL THERM MICRO TEMPLE",
              "CVS DIGITAL THERM MULTI-TIP": "CVS DIGITAL THERM MULTI-TIP",
              "CVS DIGITAL THERMOMETER": "CVS DIGITAL THERMOMETER",
              "CVS DIGITAL THERMOMETER BASAL": "CVS DIGITAL THERMOMETER BASAL",
              "CVS DIGITAL THERMOMETER TEMPLE": "CVS DIGITAL THERMOMETER TEMPLE",
              "CVS PROBE COVERS": "CVS PROBE COVERS"
            },
            "DYNAREX CORPORATION": {
              "DIGITAL THERMOMETER/FLEX TIP": "DIGITAL THERMOMETER/FLEX TIP",
              "ECONOMY DIGITAL THERMOMETER": "ECONOMY DIGITAL THERMOMETER",
              "INFRARED EAR THERMOMETER": "INFRARED EAR THERMOMETER",
              "INFRARED FOREHEAD THERMOMETER": "INFRARED FOREHEAD THERMOMETER"
            },
            "EQUALINE": {
              "EQL DIGITAL THERMOMETER": "EQL DIGITAL THERMOMETER",
              "EQL DIGITAL THERMOMETER/FLEX": "EQL DIGITAL THERMOMETER/FLEX",
              "EQL DIGITAL THERMOMETER/RIGID": "EQL DIGITAL THERMOMETER/RIGID",
              "EQL FEVERFLASH DIGITAL": "EQL FEVERFLASH DIGITAL",
              "EQL INSTANT EAR DIGITAL": "EQL INSTANT EAR DIGITAL",
              "EQL PROBE COVERS": "EQL PROBE COVERS"
            },
            "HEALTH ENTERPRISES": {
              "FEVER STRIP THERMOMETER": "FEVER STRIP THERMOMETER"
            },
            "HOME AIDE DIAGNOSTICS": {
              "ORAL TEMP DIGITAL THERMOMETER": "ORAL TEMP DIGITAL THERMOMETER",
              "PRO COMFORT TEMPA CHECK": "PRO COMFORT TEMPA CHECK",
              "PRO TEMP ORAL THERMOMETER": "PRO TEMP ORAL THERMOMETER",
              "PURE COMFORT TEMPA THERMOMETER": "PURE COMFORT TEMPA THERMOMETER",
              "QUICK TEMP INFRARED THERM": "QUICK TEMP INFRARED THERM",
              "QUICK TEMP PRO INFRARED": "QUICK TEMP PRO INFRARED",
              "TEMPA CHECK": "TEMPA CHECK"
            },
            "KAISER FOUNDATION HOSPITAL": {
              "KP FAST READ FLEX-TIP THERM": "KP FAST READ FLEX-TIP THERM"
            },
            "KAZ": {
              "BRAUN NO TOUCH + FOREHEAD": "BRAUN NO TOUCH + FOREHEAD",
              "BRAUN THERMOSCAN EAR": "BRAUN THERMOSCAN EAR",
              "BRAUN THERMOSCAN LENS FILTERS": "BRAUN THERMOSCAN LENS FILTERS",
              "BRAUN THERMOSCAN5 EAR": "BRAUN THERMOSCAN5 EAR",
              "KAZ DIGITAL THERMOMETER": "KAZ DIGITAL THERMOMETER",
              "KAZ THERMOMETER PROBE COVERS": "KAZ THERMOMETER PROBE COVERS",
              "KAZ VICKS DIGITAL THERMOMETER": "KAZ VICKS DIGITAL THERMOMETER",
              "VICKS BABY THERMOMETER": "VICKS BABY THERMOMETER",
              "VICKS BASAL THERMOMETER": "VICKS BASAL THERMOMETER",
              "VICKS EAR THERMOM PROBE COVERS": "VICKS EAR THERMOM PROBE COVERS",
              "VICKS EAR THERMOMETER": "VICKS EAR THERMOMETER",
              "VICKS FOREHEAD THERMOMETER": "VICKS FOREHEAD THERMOMETER",
              "VICKS PACIFIER THERMOMETER": "VICKS PACIFIER THERMOMETER",
              "VICKS THERMOMETER COMFORT-FLEX": "VICKS THERMOMETER COMFORT-FLEX",
              "VICKS THERMOMETER DISPOSABLE": "VICKS THERMOMETER DISPOSABLE",
              "VICKS THERMOMETER SPEED READ": "VICKS THERMOMETER SPEED READ",
              "VICKS THERMOMETER UNDERARM": "VICKS THERMOMETER UNDERARM",
              "VICKS WEARABLE THERMOMETER": "VICKS WEARABLE THERMOMETER"
            },
            "LEADER BRAND PRODUCTS": {
              "DIGITAL EAR THERMOMETER": "DIGITAL EAR THERMOMETER",
              "DIGITAL FLEX-TIP THERMOMETER": "DIGITAL FLEX-TIP THERMOMETER",
              "DIGITAL TEMPLE THERMOMETER": "DIGITAL TEMPLE THERMOMETER",
              "DIGITAL THERMOMETER": "DIGITAL THERMOMETER",
              "FEVER FLASH TEMPLE THERMOMETER": "FEVER FLASH TEMPLE THERMOMETER",
              "PROBE COVERS": "PROBE COVERS",
              "QUICK READ DIGITAL THERMOMETER": "QUICK READ DIGITAL THERMOMETER",
              "TEMPLE THERMOMETER": "TEMPLE THERMOMETER"
            },
            "LUMISCOPE": {
              "DIGITAL PACIFIER THERMOMETER": "DIGITAL PACIFIER THERMOMETER",
              "DIGITAL THERMOMETER": "DIGITAL THERMOMETER",
              "EAR THERMOMETER": "EAR THERMOMETER",
              "THERMOMETER PROBE COVERS": "THERMOMETER PROBE COVERS"
            },
            "MCKESSON": {
              "HM DIGITAL THERMOMETER": "HM DIGITAL THERMOMETER",
              "HM FEVER FLASH THERMOMETER": "HM FEVER FLASH THERMOMETER",
              "HM TEMPLE THERMOMETER": "HM TEMPLE THERMOMETER",
              "HM THERMOMETER FLEXIBLE-TIP": "HM THERMOMETER FLEXIBLE-TIP"
            },
            "MCKESSON HOME HEALTH CARE": {
              "BABY THERMOMETER": "BABY THERMOMETER",
              "BASAL THERMOMETER": "BASAL THERMOMETER",
              "DIGITAL PACIFIER THERMOMETER": "DIGITAL PACIFIER THERMOMETER",
              "DIGITAL THERMOMETER": "DIGITAL THERMOMETER",
              "DIGITAL THERMOMETER/BEEPER": "DIGITAL THERMOMETER/BEEPER",
              "FOREHEAD THERMOMETER": "FOREHEAD THERMOMETER",
              "ORAL THERMOMETER": "ORAL THERMOMETER",
              "PACIFIER PLUS": "PACIFIER PLUS",
              "RECTAL THERMOMETER": "RECTAL THERMOMETER",
              "SM DIGITAL THERMOMETER": "SM DIGITAL THERMOMETER",
              "THERMOMETER COVERS": "THERMOMETER COVERS"
            },
            "MCKESSON SUNMARK": {
              "SM DIGITAL THERMOMETER DELUXE": "SM DIGITAL THERMOMETER DELUXE",
              "SM PROBE COVERS": "SM PROBE COVERS",
              "SM TEMPLE THERMOMETER": "SM TEMPLE THERMOMETER"
            },
            "MEDICINE SHOPPE": {
              "THERMOMETER PROBE COVERS": "THERMOMETER PROBE COVERS"
            },
            "OMRON MANAGED HEALTHCARE": {
              "EAR THERMOMETER": "EAR THERMOMETER"
            },
            "RITE AID CORPORATION": {
              "RA DIGITAL THERMOMETER": "RA DIGITAL THERMOMETER",
              "RA FEVER FLASH EAR THERMOMETER": "RA FEVER FLASH EAR THERMOMETER",
              "RA FEVER FLASH THERMOMETER": "RA FEVER FLASH THERMOMETER",
              "RA INSTANT EAR THERMOMETER": "RA INSTANT EAR THERMOMETER",
              "RA PROBE COVERS": "RA PROBE COVERS",
              "RA SOFT-TIP/FEVER ALARM": "RA SOFT-TIP/FEVER ALARM"
            },
            "SIMPLE DIAGNOSTICS": {
              "CLEVER CHOICE DIGITAL60": "CLEVER CHOICE DIGITAL60",
              "CLEVER CHOICE DUO THERMOMETER": "CLEVER CHOICE DUO THERMOMETER",
              "CLEVER CHOICE THERMOMETER": "CLEVER CHOICE THERMOMETER"
            },
            "SUNBEAM": {
              "BABY RECTAL THERMOMETER": "BABY RECTAL THERMOMETER",
              "BASAL THERMOMETER": "BASAL THERMOMETER",
              "DIGITAL THERMOMETER": "DIGITAL THERMOMETER",
              "GLASS ORAL THERMOMETER": "GLASS ORAL THERMOMETER",
              "THERMOMETER COVERS": "THERMOMETER COVERS",
              "THERMOMETER DELUXE/SMALL CARD": "THERMOMETER DELUXE/SMALL CARD",
              "THERMOMETER STANDARD UNIT": "THERMOMETER STANDARD UNIT",
              "THERMOMETER/MEMORY": "THERMOMETER/MEMORY"
            },
            "WAL-MART": {
              "RELION BASAL THERMOMETER": "RELION BASAL THERMOMETER",
              "RELION DIGITAL THERMOMETER": "RELION DIGITAL THERMOMETER",
              "RELION INSTANT EAR THERMOMETER": "RELION INSTANT EAR THERMOMETER",
              "RELION THERMOMETER TIP COVERS": "RELION THERMOMETER TIP COVERS"
            },
            "WALGREENS": {
              "2-SECOND DIGITAL THERMOMETER": "2-SECOND DIGITAL THERMOMETER",
              "30 SECOND DIGITAL THERMOMETER": "30 SECOND DIGITAL THERMOMETER",
              "BASAL THERMOMETER": "BASAL THERMOMETER",
              "DIGITAL EAR THERMOMETER": "DIGITAL EAR THERMOMETER",
              "DIGITAL THERMOMETER": "DIGITAL THERMOMETER",
              "DIGITAL THERMOMETER/30 SECOND": "DIGITAL THERMOMETER/30 SECOND",
              "DIGITAL THERMOMETER/TEMPLE": "DIGITAL THERMOMETER/TEMPLE",
              "INFARED EAR THERMOMETER": "INFARED EAR THERMOMETER",
              "PROBE COVERS": "PROBE COVERS"
            }
          },
          "Transcranial Magnetic Stimulators": {
            "ENEURA": {
              "SAVI": "SAVI"
            }
          }
        },
        "Optical and Ophthalmic Supplies": {
          "Contact Lens Care Supplies": {
            "AKORN CONSUMER": {
              "THERATEARS": "THERATEARS"
            },
            "CIBA VISION CARE": {
              "AO-DISC CATALYST": "AO-DISC CATALYST"
            },
            "LOBOB": {
              "SOF/PRO CLEANER": "SOF/PRO CLEANER"
            }
          },
          "Eye Glass Care Supplies": {
            "APOTHECARY PRODUCTS, INC.": {
              "WIPE N CLEAR LENS WIPES": "WIPE N CLEAR LENS WIPES"
            },
            "BAUSCH & LOMB CONS": {
              "B & L ACCESSORIES LENS CASE": "B & L ACCESSORIES LENS CASE",
              "SIGHT SAVERS LENS CLEANER CLOT": "SIGHT SAVERS LENS CLEANER CLOT",
              "SIGHT SAVERS LIQUID CLEANER": "SIGHT SAVERS LIQUID CLEANER",
              "SIGHT SAVERS PRE-MOISTENED TIS": "SIGHT SAVERS PRE-MOISTENED TIS",
              "SIGHT SAVERS REPAIR": "SIGHT SAVERS REPAIR",
              "SIGHT SAVERS SUPER SOFT CLOTH": "SIGHT SAVERS SUPER SOFT CLOTH"
            },
            "BLAIREX LABS": {
              "CLEAN SIGHTS": "CLEAN SIGHTS"
            },
            "RITE AID CORPORATION": {
              "RA LENS CLEANING SOFT WIPES": "RA LENS CLEANING SOFT WIPES"
            },
            "WALGREENS": {
              "CLEANING CLOTH UNIVERSAL": "CLEANING CLOTH UNIVERSAL",
              "EYEGLASS SCREWDRIVER": "EYEGLASS SCREWDRIVER",
              "LENS CLEANER": "LENS CLEANER",
              "LENS TOWELETTES": "LENS TOWELETTES",
              "LENS WIPES": "LENS WIPES",
              "LENS-CLEAR": "LENS-CLEAR",
              "LENS-CLEAR CLOTHS": "LENS-CLEAR CLOTHS",
              "MICROFIBER CLEANING CLOTHS": "MICROFIBER CLEANING CLOTHS"
            }
          },
          "Eye Patches": {
            "3M CONSUMER HEALTH CARE": {
              "NEXCARE OPTICLUDE EYE PATCH JR": "NEXCARE OPTICLUDE EYE PATCH JR",
              "NEXCARE OPTICLUDE EYE PTCH REG": "NEXCARE OPTICLUDE EYE PTCH REG",
              "OPTICLUDE EYE PATCH JUNIOR": "OPTICLUDE EYE PATCH JUNIOR",
              "OPTICLUDE EYE PATCH REGULAR": "OPTICLUDE EYE PATCH REGULAR"
            },
            "APOTHECARY PRODUCTS, INC.": {
              "EYE PATCH": "EYE PATCH"
            },
            "BEIERSDORF DERMA": {
              "COVERLET EYE OCCLUSORS": "COVERLET EYE OCCLUSORS"
            },
            "BSN MEDICAL": {
              "COVERLET EYE OCCLUSOR JUNIOR": "COVERLET EYE OCCLUSOR JUNIOR"
            },
            "COVIDIEN MEDICAL SUPPLIES": {
              "CURITY EYE": "CURITY EYE"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS EYE PATCH": "CVS EYE PATCH"
            },
            "RITE AID CORPORATION": {
              "RA EYE PATCH": "RA EYE PATCH"
            },
            "WALGREENS": {
              "CONVEX EYE PROTECTOR": "CONVEX EYE PROTECTOR",
              "EYE PATCH": "EYE PATCH",
              "SLEEP EYE SHIELD": "SLEEP EYE SHIELD"
            }
          },
          "Ophthalmic Pressure Monitors": {
            "BAUSCH & LOMB": {
              "PROVIEW": "PROVIEW"
            }
          },
          "Optical Supplies": {
            "ALCON VISION": {
              "OPTI-FREE REPLENISH LENS CASE": "OPTI-FREE REPLENISH LENS CASE",
              "SCHIRMER TEAR TEST": "SCHIRMER TEAR TEST"
            },
            "APOTHECARY PRODUCTS, INC.": {
              "EYE CUP": "EYE CUP",
              "EZY-DROP GUIDE AND EYEWASH CUP": "EZY-DROP GUIDE AND EYEWASH CUP"
            },
            "BAUSCH & LOMB CONS": {
              "SIGHT SAVERS ADJ ACTION CORD": "SIGHT SAVERS ADJ ACTION CORD",
              "SIGHT SAVERS CLEAR NOSE PADS": "SIGHT SAVERS CLEAR NOSE PADS",
              "SIGHT SAVERS FASHION CHAIN": "SIGHT SAVERS FASHION CHAIN",
              "SIGHT SAVERS HINGE RINGS": "SIGHT SAVERS HINGE RINGS",
              "SIGHT SAVERS NOSE COMFORT PADS": "SIGHT SAVERS NOSE COMFORT PADS",
              "SIGHT SAVERS SPORT BAND": "SIGHT SAVERS SPORT BAND",
              "SIGHT SAVERS TEMPLE PADS": "SIGHT SAVERS TEMPLE PADS"
            },
            "BLAIREX LABS": {
              "LENS CASE": "LENS CASE",
              "LENS HOLDERS": "LENS HOLDERS"
            },
            "CIBA VISION CARE": {
              "AO-SEPT CONTACT LENS HOLDER": "AO-SEPT CONTACT LENS HOLDER"
            },
            "OCUSOFT": {
              "OCUSOFT DRY EYE": "OCUSOFT DRY EYE",
              "OCUSOFT LID SCRUB COMPLIANCE": "OCUSOFT LID SCRUB COMPLIANCE"
            },
            "OWEN MUMFORD": {
              "AUTODROP": "AUTODROP",
              "AUTOSQUEEZE": "AUTOSQUEEZE"
            },
            "RITE AID CORPORATION": {
              "RA CONTACT LENS CASES": "RA CONTACT LENS CASES"
            },
            "ROSE STONE ENTERPRISES": {
              "TEAR FLO": "TEAR FLO"
            },
            "WAL-MART": {
              "EQ CONTACT LENS CASES": "EQ CONTACT LENS CASES"
            },
            "WALGREENS": {
              "CONTACT LENS CASE": "CONTACT LENS CASE",
              "NOSE PADS": "NOSE PADS"
            }
          }
        },
        "Oral Hygiene Products": {
          "Dental Desensitizing Products": {
            "BEUTLICH PHARMA": {
              "HURRISEAL": "HURRISEAL",
              "HURRISEAL SNAP-N-GO": "HURRISEAL SNAP-N-GO"
            },
            "NULINE PHARMACEUTICALS": {
              "REMESENSE": "REMESENSE"
            }
          },
          "Dental Supplies": {
            "CHAIN DRUG CONSORTIUM": {
              "WAX FOR BRACES": "WAX FOR BRACES"
            },
            "EXEL INTERNATIONAL": {
              "DENTAL NEEDLE": "DENTAL NEEDLE"
            },
            "SHEFFIELD PHARMACEUTICALS": {
              "WAX FOR BRACES": "WAX FOR BRACES"
            }
          },
          "Dental Whitening Products": {
            "EQUALINE": {
              "EQL XTREME WHITENING WRAPS": "EQL XTREME WHITENING WRAPS"
            },
            "JOHNSON&JOHNSON HEALTHCARE": {
              "LISTERINE WHITENING": "LISTERINE WHITENING",
              "REMBRANDT WHITENING": "REMBRANDT WHITENING"
            },
            "ORAL ESSENTIALS": {
              "LUMINEUX WHITENING": "LUMINEUX WHITENING"
            },
            "P & G HEALTH": {
              "CREST WHITESTRIPS": "CREST WHITESTRIPS"
            }
          },
          "Dentifrices": {
            "CHURCH DWIGHT": {
              "PEARL DROPS": "PEARL DROPS",
              "PEARL DROPS EXTRA STRENGTH": "PEARL DROPS EXTRA STRENGTH"
            },
            "COLGATE ORAL PHARMACEUTICALS": {
              "COLGATE PLATINUM": "COLGATE PLATINUM",
              "VIADENT": "VIADENT",
              "VIADENT ADVANCED CARE": "VIADENT ADVANCED CARE",
              "VIADENT FLUORIDE": "VIADENT FLUORIDE",
              "VIADENT PLAQUE": "VIADENT PLAQUE"
            },
            "EQUALINE": {
              "EQL DRYTOUCH WHITENING TRAYS": "EQL DRYTOUCH WHITENING TRAYS"
            },
            "G C AMERICA": {
              "MI PASTE": "MI PASTE",
              "MI PASTE PLUS": "MI PASTE PLUS"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "AQUAFRESH": "AQUAFRESH",
              "AQUAFRESH CAVITY PROTECTION": "AQUAFRESH CAVITY PROTECTION",
              "AQUAFRESH EXTREME CLEAN": "AQUAFRESH EXTREME CLEAN",
              "AQUAFRESH FOR KIDS": "AQUAFRESH FOR KIDS",
              "AQUAFRESH SENSITIVE": "AQUAFRESH SENSITIVE",
              "AQUAFRESH TARTAR CONTROL": "AQUAFRESH TARTAR CONTROL",
              "AQUAFRESH WHITE TRAYS": "AQUAFRESH WHITE TRAYS",
              "AQUAFRESH WHITENING": "AQUAFRESH WHITENING",
              "BIOTENE DRY MOUTH": "BIOTENE DRY MOUTH",
              "BIOTENE DRY MOUTH GENTLE": "BIOTENE DRY MOUTH GENTLE",
              "BIOTENE PBF DRY MOUTH": "BIOTENE PBF DRY MOUTH",
              "SENSODYNE ISO-ACTIVE MULTI ACT": "SENSODYNE ISO-ACTIVE MULTI ACT",
              "SENSODYNE ISO-ACTIVE WHITENING": "SENSODYNE ISO-ACTIVE WHITENING",
              "SENSODYNE MAX ST/FLUORIDE": "SENSODYNE MAX ST/FLUORIDE",
              "SENSODYNE MAXIMUM STRENGTH": "SENSODYNE MAXIMUM STRENGTH",
              "SENSODYNE PRONAMEL": "SENSODYNE PRONAMEL",
              "SENSODYNE TARTAR CONTROL": "SENSODYNE TARTAR CONTROL"
            },
            "ORAL ESSENTIALS": {
              "LUMINEUX CLEAN/FRESH TOOTHPSTE": "LUMINEUX CLEAN/FRESH TOOTHPSTE",
              "LUMINEUX KIDS TOOTHPASTE": "LUMINEUX KIDS TOOTHPASTE",
              "LUMINEUX SENSITIVITY TOOTHPSTE": "LUMINEUX SENSITIVITY TOOTHPSTE",
              "LUMINEUX WHITENING TOOTHPASTE": "LUMINEUX WHITENING TOOTHPASTE"
            },
            "ORAL-B": {
              "AMOSAN": "AMOSAN"
            },
            "P & G HEALTH": {
              "CREST": "CREST",
              "CREST TARTAR CONTROL": "CREST TARTAR CONTROL",
              "CREST TC/BAKING SODA": "CREST TC/BAKING SODA"
            },
            "RITE AID CORPORATION": {
              "RA FLUORIDE TOOTHPASTE": "RA FLUORIDE TOOTHPASTE",
              "RA SENSITIVE TOOTHPASTE/FL": "RA SENSITIVE TOOTHPASTE/FL",
              "RA WHITENING TOOTHPASTE/FL": "RA WHITENING TOOTHPASTE/FL"
            },
            "SHEFFIELD PHARMACEUTICALS": {
              "VINCE": "VINCE"
            },
            "WALGREENS": {
              "SENSITIVE EXTRA WHITENING": "SENSITIVE EXTRA WHITENING",
              "SENSITIVE TOOTHPASTE/FLUORIDE": "SENSITIVE TOOTHPASTE/FLUORIDE"
            }
          },
          "Denture Care Products": {
            "AMERISOURCE BERGEN DRUGS": {
              "GNP DENTURE ADHESIVE": "GNP DENTURE ADHESIVE",
              "GNP DENTURE CLEANSER": "GNP DENTURE CLEANSER",
              "GNP DENTURE CLEANSER DUAL ACT": "GNP DENTURE CLEANSER DUAL ACT"
            },
            "CHAIN DRUG CONSORTIUM": {
              "DENTURE ADHESIVE": "DENTURE ADHESIVE",
              "DENTURE CLEANSER": "DENTURE CLEANSER"
            },
            "CHURCH DWIGHT": {
              "RIGIDENT DENTURE RETAINER": "RIGIDENT DENTURE RETAINER"
            },
            "COMBE": {
              "SEA BOND": "SEA BOND",
              "SEA BOND BRIGHTENING CLEANSER": "SEA BOND BRIGHTENING CLEANSER",
              "SEA BOND DENTURE BATH": "SEA BOND DENTURE BATH",
              "SEA BOND DENTURE BRUSH": "SEA BOND DENTURE BRUSH",
              "SEA BOND QUICK FIX": "SEA BOND QUICK FIX"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS DENTURE ADHESIVE CREAM": "CVS DENTURE ADHESIVE CREAM"
            },
            "EQUALINE": {
              "EQ DENTURE ADHESIVE": "EQ DENTURE ADHESIVE",
              "EQL DENTURE BRUSH/PICK": "EQL DENTURE BRUSH/PICK",
              "EQL DENTURE CLEANSER": "EQL DENTURE CLEANSER"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "POLIDENT 3 MINUTE": "POLIDENT 3 MINUTE",
              "POLIDENT DENTU-CREME": "POLIDENT DENTU-CREME",
              "POLIGRIP SUPER STRONG": "POLIGRIP SUPER STRONG",
              "POLIGRIP SUPER STRONG EX ST": "POLIGRIP SUPER STRONG EX ST",
              "POLIGRIP SUPER STRONG FREE": "POLIGRIP SUPER STRONG FREE",
              "SUPER POLIGRIP": "SUPER POLIGRIP",
              "SUPER POLIGRIP COMFORT SEAL": "SUPER POLIGRIP COMFORT SEAL"
            },
            "HOGIL PHARMACEUTICAL CORP": {
              "ORAFIX SPECIAL": "ORAFIX SPECIAL",
              "ORAFIX ULTRA": "ORAFIX ULTRA"
            },
            "MAJOR PHARMACEUTICALS": {
              "DENTURE TABLETS": "DENTURE TABLETS"
            },
            "MCKESSON SUNMARK": {
              "SM DENTURE CLEANSER": "SM DENTURE CLEANSER"
            },
            "MEDTECH": {
              "EFFERDENT DENTURE CLEANSER": "EFFERDENT DENTURE CLEANSER",
              "EFFERDENT PLUS": "EFFERDENT PLUS",
              "EFFERDENT PM": "EFFERDENT PM",
              "EFFERDENT POWER CLEAN CRYSTALS": "EFFERDENT POWER CLEAN CRYSTALS",
              "EFFERGRIP": "EFFERGRIP",
              "EZO CUSHIONS LOWER HEAVY": "EZO CUSHIONS LOWER HEAVY",
              "EZO CUSHIONS LOWER REGULAR": "EZO CUSHIONS LOWER REGULAR",
              "EZO CUSHIONS UPPER HEAVY": "EZO CUSHIONS UPPER HEAVY",
              "EZO CUSHIONS UPPER REGULAR": "EZO CUSHIONS UPPER REGULAR"
            },
            "OAKHURST": {
              "KLUTCH": "KLUTCH",
              "PLATE-WELD DENTURE REPAIR KIT": "PLATE-WELD DENTURE REPAIR KIT"
            },
            "P & G HEALTH": {
              "FIXODENT COMPLETE": "FIXODENT COMPLETE",
              "FIXODENT EXTRA HOLD": "FIXODENT EXTRA HOLD",
              "FIXODENT FREE": "FIXODENT FREE",
              "FIXODENT ORIGINAL": "FIXODENT ORIGINAL"
            },
            "RITE AID CORPORATION": {
              "RA DENTURE BATH": "RA DENTURE BATH",
              "RA DENTURE BRUSH": "RA DENTURE BRUSH",
              "RA DENTURE CLEANSER": "RA DENTURE CLEANSER"
            },
            "SHEFFIELD PHARMACEUTICALS": {
              "ACRYLINE 2": "ACRYLINE 2",
              "PERMA-GRIP": "PERMA-GRIP",
              "PLATE-WELD DENTURE REPAIR KIT": "PLATE-WELD DENTURE REPAIR KIT"
            },
            "WALGREENS": {
              "CONTROL DENTURE ADHESIVE": "CONTROL DENTURE ADHESIVE",
              "DENTURE ADHESIVE": "DENTURE ADHESIVE"
            }
          },
          "Oral Hygiene Products": {
            "MAJESTIC DRUG": {
              "DENTEMP": "DENTEMP",
              "DENTEMP O.S.": "DENTEMP O.S."
            },
            "SAGE PRODUCTS": {
              "ADVANCED ORAL MOISTURIZER SPR": "ADVANCED ORAL MOISTURIZER SPR",
              "DAILY ORAL CLEANSING/PETITE SZ": "DAILY ORAL CLEANSING/PETITE SZ",
              "EXTENDED TERM ORAL CARE SYSTEM": "EXTENDED TERM ORAL CARE SYSTEM",
              "ORAL SWAB PETITE": "ORAL SWAB PETITE",
              "ORAL SWAB TIPS W/PEROX-A-MINT": "ORAL SWAB TIPS W/PEROX-A-MINT",
              "OROPHARYNGEAL SUCTION CATHETER": "OROPHARYNGEAL SUCTION CATHETER",
              "Q-CARE COVERD YANKAUER/SUCTION": "Q-CARE COVERD YANKAUER/SUCTION",
              "Q-CARE COVERED YANKAUER/HANDLE": "Q-CARE COVERED YANKAUER/HANDLE",
              "Q-CARE ORAL CLEANSING/SUCTION": "Q-CARE ORAL CLEANSING/SUCTION",
              "Q-CARE Q2 ORAL CLEANS/SUCTION": "Q-CARE Q2 ORAL CLEANS/SUCTION",
              "Q-CARE Q4 ORAL CLEANS/SUCTION": "Q-CARE Q4 ORAL CLEANS/SUCTION",
              "Q-CARE Q8 ORAL CLEANS/SUCTION": "Q-CARE Q8 ORAL CLEANS/SUCTION",
              "Q-CARE SUCTION CATHETER": "Q-CARE SUCTION CATHETER",
              "SHORT TERM ORAL CARE SYSTEM": "SHORT TERM ORAL CARE SYSTEM",
              "SINGLE USE SUCTION SWAB SYSTEM": "SINGLE USE SUCTION SWAB SYSTEM",
              "SINGLE USE SUCTION TOOTHBRUSH": "SINGLE USE SUCTION TOOTHBRUSH",
              "SINGLE USE SWAB SYSTEM": "SINGLE USE SWAB SYSTEM",
              "SINGLE USE SYSTEM": "SINGLE USE SYSTEM",
              "SUCTION ORAL SWAB SYSTEM": "SUCTION ORAL SWAB SYSTEM",
              "SUCTION TOOTHBRUSH": "SUCTION TOOTHBRUSH",
              "SUCTION TOOTHBRUSH SYSTEM": "SUCTION TOOTHBRUSH SYSTEM",
              "TOOTHETTE PLUS UNTREATED": "TOOTHETTE PLUS UNTREATED",
              "TOOTHETTE SWABS UNTREATED": "TOOTHETTE SWABS UNTREATED",
              "TOOTHETTE SWABS/DENTIFRICE": "TOOTHETTE SWABS/DENTIFRICE"
            },
            "WALGREENS": {
              "POWER ORAL CARE SYSTEM": "POWER ORAL CARE SYSTEM"
            }
          },
          "Tooth Brushes-Floss": {
            "BERGEN BRUNSWIG": {
              "GNP DENTAL FLOSS WAXED": "GNP DENTAL FLOSS WAXED",
              "GNP MINT FLOSSERS": "GNP MINT FLOSSERS"
            },
            "COLGATE ORAL PHARMACEUTICALS": {
              "COLGATE TOTAL FLOSS": "COLGATE TOTAL FLOSS"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS DENTAL FLOSS": "CVS DENTAL FLOSS",
              "CVS DENTAL FLOSS UNWAXED": "CVS DENTAL FLOSS UNWAXED",
              "CVS DENTAL FLOSS WAXED": "CVS DENTAL FLOSS WAXED"
            },
            "DR.FRESH": {
              "J & J DENTAL FLOSS FOR KIDS": "J & J DENTAL FLOSS FOR KIDS",
              "J & J DENTAL FLOSS UNWAXED": "J & J DENTAL FLOSS UNWAXED",
              "REACH ADVANCED DESIGN": "REACH ADVANCED DESIGN",
              "REACH CRYSTAL CLEAN": "REACH CRYSTAL CLEAN",
              "REACH FRESH & CLEAN": "REACH FRESH & CLEAN",
              "REACH TOTAL CARE + WHITENING": "REACH TOTAL CARE + WHITENING",
              "REACH TOTAL CARE MULTI-ACTION": "REACH TOTAL CARE MULTI-ACTION",
              "REACH TOTAL CARE SENSITIVE": "REACH TOTAL CARE SENSITIVE",
              "REACH ULTRACLEAN": "REACH ULTRACLEAN",
              "REACH WONDER GRIP": "REACH WONDER GRIP",
              "REACH YOUTH SOFT": "REACH YOUTH SOFT"
            },
            "EQUALINE": {
              "EQL ANGLE EDGE+ DEEP CLEAN": "EQL ANGLE EDGE+ DEEP CLEAN",
              "EQL CLEAN+ ANGLED END-TUFT": "EQL CLEAN+ ANGLED END-TUFT",
              "EQL CLEAN+ SOFT/FULL HEAD": "EQL CLEAN+ SOFT/FULL HEAD",
              "EQL DENTAL FLOSS EXTRA COMFORT": "EQL DENTAL FLOSS EXTRA COMFORT",
              "EQL DENTAL FLOSS SUPERSLIP": "EQL DENTAL FLOSS SUPERSLIP",
              "EQL DENTAL FLOSS UNWAXED": "EQL DENTAL FLOSS UNWAXED",
              "EQL DENTAL FLOSS WAXED": "EQL DENTAL FLOSS WAXED",
              "EQL EASYFLEX BRUSH HEADS": "EQL EASYFLEX BRUSH HEADS",
              "EQL FOLDING TRAVEL TOOTHBRUSH": "EQL FOLDING TRAVEL TOOTHBRUSH",
              "EQL GEM GRIP BI-LEVEL BRISTLES": "EQL GEM GRIP BI-LEVEL BRISTLES",
              "EQL GEM GRIP MED/DUAL ANGLED": "EQL GEM GRIP MED/DUAL ANGLED",
              "EQL GEM GRIP MEDIUM/FULL HEAD": "EQL GEM GRIP MEDIUM/FULL HEAD",
              "EQL GEM GRIP SOFT/DUAL ANGLED": "EQL GEM GRIP SOFT/DUAL ANGLED",
              "EQL MULTIFIT CONTOUR PLUS SOFT": "EQL MULTIFIT CONTOUR PLUS SOFT",
              "EQL ORBIT TOOTHBRUSH SOFT": "EQL ORBIT TOOTHBRUSH SOFT",
              "EQL SMARTGRIP CONTOUR SOFT": "EQL SMARTGRIP CONTOUR SOFT",
              "EQL SMILESONIC BRUSH HEADS": "EQL SMILESONIC BRUSH HEADS",
              "EQL TOOTH FLOSSERS": "EQL TOOTH FLOSSERS",
              "EQL XTREME WHITE/SOFT": "EQL XTREME WHITE/SOFT"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "AQUAFRESH DR SEUSS EXTRA SOFT": "AQUAFRESH DR SEUSS EXTRA SOFT"
            },
            "JOHNSON&JOHNSON HEALTHCARE": {
              "J & J DENTAL FLOSS WAXED": "J & J DENTAL FLOSS WAXED",
              "J & J DENTOTAPE": "J & J DENTOTAPE",
              "LISTERINE GENTLE GUM CARE": "LISTERINE GENTLE GUM CARE",
              "LISTERINE HEALTHY WHITE FLOSS": "LISTERINE HEALTHY WHITE FLOSS",
              "LISTERINE ULTRACLEAN ACCESS": "LISTERINE ULTRACLEAN ACCESS",
              "LISTERINE ULTRACLEAN FLOSS": "LISTERINE ULTRACLEAN FLOSS"
            },
            "LACLEDE": {
              "TINY TEETH SOFT FINGER BRUSH": "TINY TEETH SOFT FINGER BRUSH"
            },
            "LEADER BRAND PRODUCTS": {
              "COMFORT ANGLED TOOTHBRUSH SOFT": "COMFORT ANGLED TOOTHBRUSH SOFT",
              "CONFIDENT TOOTHBRUSH SOFT": "CONFIDENT TOOTHBRUSH SOFT",
              "DENTAL FLOSS WAXED": "DENTAL FLOSS WAXED",
              "SONIC TOOTHBRUSH HEADS": "SONIC TOOTHBRUSH HEADS"
            },
            "MCKESSON SUNMARK": {
              "SM DENTAL FLOSS UNWAXED": "SM DENTAL FLOSS UNWAXED",
              "SM DENTAL FLOSS WAXED": "SM DENTAL FLOSS WAXED"
            },
            "MEDTECH": {
              "THE DOCTORS BRUSHPICKS": "THE DOCTORS BRUSHPICKS",
              "THE DOCTORS ORAPIK TRAVELER": "THE DOCTORS ORAPIK TRAVELER"
            },
            "RITE AID CORPORATION": {
              "RA DENTAL FLOSS WAXED": "RA DENTAL FLOSS WAXED",
              "RA TOOTHBRUSH ANGLE EDGE": "RA TOOTHBRUSH ANGLE EDGE"
            },
            "SAGE PRODUCTS": {
              "ULTRA-SOFT TOOTHBRUSH": "ULTRA-SOFT TOOTHBRUSH"
            },
            "WALGREENS": {
              "BRUSH & TOOTHPICK/INTERDENTAL": "BRUSH & TOOTHPICK/INTERDENTAL",
              "CLEAN+ TOOTHBRUSH MEDIUM": "CLEAN+ TOOTHBRUSH MEDIUM",
              "CLEAN+ TOOTHBRUSH SOFT": "CLEAN+ TOOTHBRUSH SOFT",
              "DENTAL TAPE WAXED": "DENTAL TAPE WAXED",
              "HONEYCOMB TONGUE/CHEEK CLEANER": "HONEYCOMB TONGUE/CHEEK CLEANER",
              "TOOTHBRUSH MEDIUM": "TOOTHBRUSH MEDIUM",
              "TOOTHBRUSH/CROSS ACTION": "TOOTHBRUSH/CROSS ACTION",
              "TOOTHBRUSH/TONGUE CLEANER": "TOOTHBRUSH/TONGUE CLEANER",
              "XTREME MULTI PRO MEDIUM": "XTREME MULTI PRO MEDIUM",
              "XTREME MULTI PRO SOFT": "XTREME MULTI PRO SOFT"
            }
          }
        },
        "Parenteral Therapy Supplies": {
          "IV Sets/Tubing": {
            "ABBVIE": {
              "LTXF PRIM CNV PIN MICRODRIP": "LTXF PRIM CNV PIN MICRODRIP",
              "LTXF PRIM IV SET/CNVT PIN": "LTXF PRIM IV SET/CNVT PIN",
              "LTXF SECONDARY/CNV PIN/32INC": "LTXF SECONDARY/CNV PIN/32INC"
            },
            "ANIMAS DIABETES": {
              "INFUSION SET": "INFUSION SET",
              "INFUSION SET 23\"\"": "INFUSION SET 23\"\"",
              "INFUSION SET 23\"\" 10MM": "INFUSION SET 23\"\" 10MM",
              "INFUSION SET 23\"\" 8MM": "INFUSION SET 23\"\" 8MM",
              "INFUSION SET 23\"\" COMFORT": "INFUSION SET 23\"\" COMFORT",
              "INFUSION SET 24\"\" 6MM": "INFUSION SET 24\"\" 6MM",
              "INFUSION SET 42\"\" 6MM": "INFUSION SET 42\"\" 6MM",
              "INFUSION SET 42\"\" 9MM": "INFUSION SET 42\"\" 9MM",
              "INFUSION SET 43\"\"": "INFUSION SET 43\"\"",
              "INFUSION SET 43\"\" 10MM": "INFUSION SET 43\"\" 10MM"
            },
            "BAXTER HEALTHCARE CORP": {
              "ADMINISTRATION SET": "ADMINISTRATION SET",
              "AIR ELIMINATING TPN IV SET": "AIR ELIMINATING TPN IV SET"
            },
            "BD DIABETES CARE": {
              "BD SAF-T E-Z SET": "BD SAF-T E-Z SET",
              "BD SAFETY-LOK SET": "BD SAFETY-LOK SET",
              "BD VACUTAINER SET": "BD VACUTAINER SET"
            },
            "BD MEDICAL SURGICAL SYSTEMS": {
              "ANESTHESIA EXTENSION TUBING": "ANESTHESIA EXTENSION TUBING",
              "BD SAFETY-LOK SET": "BD SAFETY-LOK SET",
              "BD SAFETY-LOK VACUTAINER SET": "BD SAFETY-LOK VACUTAINER SET",
              "BD VACUTAINER SET": "BD VACUTAINER SET",
              "H 468L TUBING": "H 468L TUBING",
              "L606 TUBING": "L606 TUBING",
              "L609 TUBING": "L609 TUBING",
              "VACUTAINER TUBING": "VACUTAINER TUBING"
            },
            "BRACCO DIAGNOSTICS": {
              "FLUID ADMINISTRATION SET": "FLUID ADMINISTRATION SET",
              "SOLUTION TRANSFER DEVICE": "SOLUTION TRANSFER DEVICE"
            },
            "COVIDIEN MEDICAL SUPPLIES": {
              "ARGYLE EXTENSION TUBE 20\"\"": "ARGYLE EXTENSION TUBE 20\"\"",
              "KANGAROO BURETTE SET": "KANGAROO BURETTE SET"
            },
            "DYNAREX CORPORATION": {
              "IV ADMINISTRATION SET": "IV ADMINISTRATION SET",
              "IV EXTENSION SET": "IV EXTENSION SET"
            },
            "EXEL INTERNATIONAL": {
              "HUBER INFUSION SET": "HUBER INFUSION SET",
              "SAFELET IV CATHETER": "SAFELET IV CATHETER",
              "SCALP VEIN SET": "SCALP VEIN SET"
            },
            "INTRA PUMP INFUSION SYSTEMS": {
              "NERIA 29G INFUSION SET 10MM": "NERIA 29G INFUSION SET 10MM",
              "NERIA 29G INFUSION SET 6MM": "NERIA 29G INFUSION SET 6MM",
              "NERIA 29G INFUSION SET 8MM": "NERIA 29G INFUSION SET 8MM",
              "NERIA MULTI 27G 2X10MM": "NERIA MULTI 27G 2X10MM",
              "NERIA MULTI 27G 3X10MM": "NERIA MULTI 27G 3X10MM",
              "NERIA MULTI 27G 4X12MM": "NERIA MULTI 27G 4X12MM",
              "NERIA SOFT 25G INFUSION 13MM": "NERIA SOFT 25G INFUSION 13MM",
              "NERIA SOFT 25G INFUSION 17MM": "NERIA SOFT 25G INFUSION 17MM"
            },
            "ROCHE INSULIN DELIVERY SYSTEMS": {
              "ACCU-CHEK TENDER 1 INFUSION": "ACCU-CHEK TENDER 1 INFUSION",
              "TENDER 1": "TENDER 1",
              "TENDER 2": "TENDER 2"
            }
          },
          "Medical Waste Disposal Systems": {
            "BD DIABETES CARE": {
              "BD SHARPS COLLECTOR": "BD SHARPS COLLECTOR",
              "BD SHARPS CONTAINER HOME": "BD SHARPS CONTAINER HOME",
              "BD SHARPS DISPOSAL BY MAIL": "BD SHARPS DISPOSAL BY MAIL"
            },
            "BD MEDICAL SURGICAL SYSTEMS": {
              "BD NESTABLE SHARPS COLLECTOR": "BD NESTABLE SHARPS COLLECTOR",
              "BD SHARPS COLLECTOR": "BD SHARPS COLLECTOR",
              "BD SHARPS COLLECTOR SMALL": "BD SHARPS COLLECTOR SMALL",
              "BD SHARPS COLLECTOR XL": "BD SHARPS COLLECTOR XL",
              "GUARDIAN SHARPS COLLECTOR": "GUARDIAN SHARPS COLLECTOR"
            },
            "BOCA MEDICAL PRODUCTS": {
              "ULTILET SHARPS CONTAINER 1QT": "ULTILET SHARPS CONTAINER 1QT",
              "ULTILET SHARPS CONTAINER 2QT": "ULTILET SHARPS CONTAINER 2QT"
            },
            "COVIDIEN MEDICAL SUPPLIES": {
              "MONOJECT SHARPS CONTAINER": "MONOJECT SHARPS CONTAINER",
              "SHARPS-A-GATOR": "SHARPS-A-GATOR",
              "SHARPS-A-GATOR ALUM BRACKET": "SHARPS-A-GATOR ALUM BRACKET",
              "SHARPS-A-GATOR LOCKING BRACKET": "SHARPS-A-GATOR LOCKING BRACKET",
              "SHARPS-A-GATOR SHARPS CONTAIN": "SHARPS-A-GATOR SHARPS CONTAIN",
              "SHARPS-A-GATOR TORTUOUS PATH": "SHARPS-A-GATOR TORTUOUS PATH",
              "SHARPSAFETY TRANSPORTABLE CON": "SHARPSAFETY TRANSPORTABLE CON"
            },
            "MEDISCA": {
              "SHARPS CONTAINER": "SHARPS CONTAINER"
            },
            "MEDLINE INDUSTRIES": {
              "PHLEBOTOMY SHARPS CONTAINER": "PHLEBOTOMY SHARPS CONTAINER"
            },
            "ROCHE INSULIN DELIVERY SYSTEMS": {
              "SHARPS CONTAINER": "SHARPS CONTAINER"
            },
            "SHARPS COMPLIANCE": {
              "COMPLETE NEEDLE COLLECTION SYS": "COMPLETE NEEDLE COLLECTION SYS",
              "SHARPS DISPOSAL BY MAIL SYSTEM": "SHARPS DISPOSAL BY MAIL SYSTEM"
            }
          },
          "Needles & Syringes": {
            "1ST CLASS PHARMACEUTICALS": {
              "UNIFINE PENTIPS": "UNIFINE PENTIPS"
            },
            "A-S MEDICATION SOLUTIONS": {
              "INSULIN SYRINGE/NEEDLE": "INSULIN SYRINGE/NEEDLE",
              "ULTICARE SHORT PEN NEEDLES": "ULTICARE SHORT PEN NEEDLES"
            },
            "ABBOTT DIABETES CARE": {
              "FREESTYLE PRECISION INS SYR": "FREESTYLE PRECISION INS SYR",
              "PRECISION SURE-DOSE SYRINGE": "PRECISION SURE-DOSE SYRINGE",
              "PRECISION SUREDOSE PLUS SYR": "PRECISION SUREDOSE PLUS SYR"
            },
            "ABBVIE": {
              "PRECISION SURE-DOSE SYRINGE": "PRECISION SURE-DOSE SYRINGE"
            },
            "AIR-TITE PRODUCTS": {
              "NORM-JECT LUER LOCK SYRINGE": "NORM-JECT LUER LOCK SYRINGE",
              "NORM-JECT LUER SLIP SYRINGE": "NORM-JECT LUER SLIP SYRINGE"
            },
            "ALLISON MEDICAL": {
              "CAREONE INSULIN SYRINGE": "CAREONE INSULIN SYRINGE",
              "HEALTHWISE INSULIN SYR/NEEDLE": "HEALTHWISE INSULIN SYR/NEEDLE",
              "HEALTHWISE MICRON PEN NEEDLES": "HEALTHWISE MICRON PEN NEEDLES",
              "HEALTHWISE SHORT PEN NEEDLES": "HEALTHWISE SHORT PEN NEEDLES",
              "POLY HUB NEEDLE": "POLY HUB NEEDLE",
              "PX INSULIN SYRINGE": "PX INSULIN SYRINGE",
              "SURE COMFORT INSULIN SYRINGE": "SURE COMFORT INSULIN SYRINGE",
              "SURE COMFORT PEN NEEDLES": "SURE COMFORT PEN NEEDLES",
              "SYRINGE LUER LOCK": "SYRINGE LUER LOCK",
              "SYRINGE LUER SLIP": "SYRINGE LUER SLIP"
            },
            "AMBIMEDINC": {
              "INJECT-EASE": "INJECT-EASE"
            },
            "AMERICAN SALES COMPANY": {
              "CAREONE INSULIN SYRINGE": "CAREONE INSULIN SYRINGE"
            },
            "ARISE MEDICAL": {
              "ULTRACARE INSULIN SYRINGE": "ULTRACARE INSULIN SYRINGE",
              "ULTRACARE PEN NEEDLES": "ULTRACARE PEN NEEDLES"
            },
            "ARKRAY USA": {
              "ASSURE ID INSULIN SAFETY SYR": "ASSURE ID INSULIN SAFETY SYR",
              "ASSURE ID SAFETY PEN NEEDLES": "ASSURE ID SAFETY PEN NEEDLES",
              "TECHLITE INSULIN SYRINGE": "TECHLITE INSULIN SYRINGE",
              "TECHLITE PEN NEEDLES": "TECHLITE PEN NEEDLES"
            },
            "ARTSANA SPA": {
              "INSUPEN PEN NEEDLES": "INSUPEN PEN NEEDLES"
            },
            "BARD": {
              "BARDIA BULB IRRIGATION SYRINGE": "BARDIA BULB IRRIGATION SYRINGE",
              "BARDIA PISTON IRRIGATION SYR": "BARDIA PISTON IRRIGATION SYR",
              "TOOMEY SYRINGE": "TOOMEY SYRINGE"
            },
            "BD DIABETES CARE": {
              "ALLERGIST PACKAGE": "ALLERGIST PACKAGE",
              "ALLERGIST TRAY": "ALLERGIST TRAY",
              "BD AUTOSHIELD": "BD AUTOSHIELD",
              "BD AUTOSHIELD DUO": "BD AUTOSHIELD DUO",
              "BD BLUNT FILL NEEDLE": "BD BLUNT FILL NEEDLE",
              "BD DISP NEEDLE": "BD DISP NEEDLE",
              "BD DISP NEEDLES": "BD DISP NEEDLES",
              "BD HYPODERMIC NEEDLE": "BD HYPODERMIC NEEDLE",
              "BD INSULIN SYR ULTRAFINE II": "BD INSULIN SYR ULTRAFINE II",
              "BD INSULIN SYRINGE": "BD INSULIN SYRINGE",
              "BD INSULIN SYRINGE HALF-UNIT": "BD INSULIN SYRINGE HALF-UNIT",
              "BD INSULIN SYRINGE MICROFINE": "BD INSULIN SYRINGE MICROFINE",
              "BD INSULIN SYRINGE U-40": "BD INSULIN SYRINGE U-40",
              "BD INSULIN SYRINGE U-500": "BD INSULIN SYRINGE U-500",
              "BD INSULIN SYRINGE U/F": "BD INSULIN SYRINGE U/F",
              "BD INSULIN SYRINGE U/F 1/2UNIT": "BD INSULIN SYRINGE U/F 1/2UNIT",
              "BD INSULIN SYRINGE ULTRAFINE": "BD INSULIN SYRINGE ULTRAFINE",
              "BD INTEGRA SYRINGE": "BD INTEGRA SYRINGE",
              "BD LUER-LOK SYRINGE": "BD LUER-LOK SYRINGE",
              "BD PEN": "BD PEN",
              "BD PEN MINI": "BD PEN MINI",
              "BD PEN NEEDLE MICRO U/F": "BD PEN NEEDLE MICRO U/F",
              "BD PEN NEEDLE MINI U/F": "BD PEN NEEDLE MINI U/F",
              "BD PEN NEEDLE NANO 2ND GEN": "BD PEN NEEDLE NANO 2ND GEN",
              "BD PEN NEEDLE NANO U/F": "BD PEN NEEDLE NANO U/F",
              "BD PEN NEEDLE ORIGINAL U/F": "BD PEN NEEDLE ORIGINAL U/F",
              "BD PEN NEEDLE SHORT U/F": "BD PEN NEEDLE SHORT U/F",
              "BD PRECISIONGLIDE NEEDLE": "BD PRECISIONGLIDE NEEDLE",
              "BD SAFETY-LOK INSULIN SYRINGE": "BD SAFETY-LOK INSULIN SYRINGE",
              "BD SAFETYGLIDE INSULIN SYRINGE": "BD SAFETYGLIDE INSULIN SYRINGE",
              "BD SAFETYGLIDE NEEDLE": "BD SAFETYGLIDE NEEDLE",
              "BD SAFETYGLIDE SHIELDED NEEDLE": "BD SAFETYGLIDE SHIELDED NEEDLE",
              "BD SYRINGE": "BD SYRINGE",
              "BD SYRINGE 50-60 ML": "BD SYRINGE 50-60 ML",
              "BD SYRINGE BLUNT CANNULA 17G": "BD SYRINGE BLUNT CANNULA 17G",
              "BD SYRINGE CATH TIP": "BD SYRINGE CATH TIP",
              "BD SYRINGE DUAL CANNULA": "BD SYRINGE DUAL CANNULA",
              "BD SYRINGE LUER SLIP TIP": "BD SYRINGE LUER SLIP TIP",
              "BD SYRINGE LUER-LOK": "BD SYRINGE LUER-LOK",
              "BD SYRINGE/NEEDLE": "BD SYRINGE/NEEDLE",
              "BD TB SYRINGE": "BD TB SYRINGE",
              "BD VEO INSULIN SYR U/F 1/2UNIT": "BD VEO INSULIN SYR U/F 1/2UNIT",
              "BD VEO INSULIN SYRINGE U/F": "BD VEO INSULIN SYRINGE U/F",
              "BD YALE LNR REUSABLE NEEDLE": "BD YALE LNR REUSABLE NEEDLE",
              "CONTROL SYRINGE DISPOSABLE": "CONTROL SYRINGE DISPOSABLE",
              "CORNWALL CONTROL SYRINGE": "CORNWALL CONTROL SYRINGE",
              "CORNWALL METAL PIPETTING": "CORNWALL METAL PIPETTING",
              "MEDSAVER SYRINGE": "MEDSAVER SYRINGE",
              "MULTIFIT REUSABLE TB SYRINGE": "MULTIFIT REUSABLE TB SYRINGE",
              "PLASTIC ADAPTER": "PLASTIC ADAPTER",
              "SYRINGE DISPOSABLE": "SYRINGE DISPOSABLE",
              "SYRINGE DISPOSABLE 50-60ML": "SYRINGE DISPOSABLE 50-60ML",
              "SYRINGE ECCENTRIC TIP": "SYRINGE ECCENTRIC TIP",
              "TUBERCULIN SYRINGE": "TUBERCULIN SYRINGE",
              "YALE DISP NEEDLES": "YALE DISP NEEDLES",
              "YALE REUSABLE NEEDLE": "YALE REUSABLE NEEDLE",
              "YALE REUSABLE SYRINGE": "YALE REUSABLE SYRINGE",
              "YALE REUSABLE TB SYRINGE": "YALE REUSABLE TB SYRINGE"
            },
            "BD MEDICAL SURGICAL SYSTEMS": {
              "ALLERGIST SYRINGE": "ALLERGIST SYRINGE",
              "ALLERGIST TRAY": "ALLERGIST TRAY",
              "ANESTHESIA NEEDLE 23GX1-3/8\"\"": "ANESTHESIA NEEDLE 23GX1-3/8\"\"",
              "AXILLARY BLOCK NEEDLE": "AXILLARY BLOCK NEEDLE",
              "BD ALLERGY SYRINGE": "BD ALLERGY SYRINGE",
              "BD BLUNT FILTER NEEDLE": "BD BLUNT FILTER NEEDLE",
              "BD DISP NEEDLE": "BD DISP NEEDLE",
              "BD DISP NEEDLE TW": "BD DISP NEEDLE TW",
              "BD ECLIPSE NEEDLE": "BD ECLIPSE NEEDLE",
              "BD ECLIPSE SHIELDED NEEDLE": "BD ECLIPSE SHIELDED NEEDLE",
              "BD ECLIPSE SYRINGE": "BD ECLIPSE SYRINGE",
              "BD FILTER NEEDLE/5 MICRON": "BD FILTER NEEDLE/5 MICRON",
              "BD HYPODERMIC NEEDLE": "BD HYPODERMIC NEEDLE",
              "BD INSULIN SYRINGE": "BD INSULIN SYRINGE",
              "BD INSULIN SYRINGE MICROFINE": "BD INSULIN SYRINGE MICROFINE",
              "BD INSULIN SYRINGE ULTRAFINE": "BD INSULIN SYRINGE ULTRAFINE",
              "BD INTEGRA INSULIN SYRINGE": "BD INTEGRA INSULIN SYRINGE",
              "BD INTEGRA NEEDLE": "BD INTEGRA NEEDLE",
              "BD INTEGRA SYRINGE": "BD INTEGRA SYRINGE",
              "BD KLATSKIN BIOPSY NEEDLE": "BD KLATSKIN BIOPSY NEEDLE",
              "BD LUER-LOCK SYRINGE": "BD LUER-LOCK SYRINGE",
              "BD LUER-LOK SYRINGE": "BD LUER-LOK SYRINGE",
              "BD MULTIFIT REUSABLE SYRINGE": "BD MULTIFIT REUSABLE SYRINGE",
              "BD OSGOOD BIOPSY NEEDLE": "BD OSGOOD BIOPSY NEEDLE",
              "BD ROSENTHAL BIOPSY NEEDLE": "BD ROSENTHAL BIOPSY NEEDLE",
              "BD SAFETYGLIDE ALLERGY SYRINGE": "BD SAFETYGLIDE ALLERGY SYRINGE",
              "BD SAFETYGLIDE NEEDLE": "BD SAFETYGLIDE NEEDLE",
              "BD SAFETYGLIDE SHIELDED NEEDLE": "BD SAFETYGLIDE SHIELDED NEEDLE",
              "BD SAFETYGLIDE SYRINGE/NEEDLE": "BD SAFETYGLIDE SYRINGE/NEEDLE",
              "BD SYRINGE": "BD SYRINGE",
              "BD SYRINGE BLUNT CANNULA 17G": "BD SYRINGE BLUNT CANNULA 17G",
              "BD SYRINGE CATH TIP": "BD SYRINGE CATH TIP",
              "BD SYRINGE LUER-LOK": "BD SYRINGE LUER-LOK",
              "BD SYRINGE SLIP TIP": "BD SYRINGE SLIP TIP",
              "BD SYRINGE/NEEDLE": "BD SYRINGE/NEEDLE",
              "BD SYRINGE/NEEDLE SLIP TIP": "BD SYRINGE/NEEDLE SLIP TIP",
              "BD TB SYRINGE": "BD TB SYRINGE",
              "BD TUBERCULIN SYRINGE": "BD TUBERCULIN SYRINGE",
              "BD YALE LNR REUSABLE NEEDLE": "BD YALE LNR REUSABLE NEEDLE",
              "BD YALE REUSABLE TB SYRINGE": "BD YALE REUSABLE TB SYRINGE",
              "CORNWALL DISPENSING SYRINGE": "CORNWALL DISPENSING SYRINGE",
              "CORNWALL SYR PIPET OUTFIT 10ML": "CORNWALL SYR PIPET OUTFIT 10ML",
              "CORNWALL SYR PIPET OUTFIT 1ML": "CORNWALL SYR PIPET OUTFIT 1ML",
              "CORNWALL SYR PIPET OUTFIT 2ML": "CORNWALL SYR PIPET OUTFIT 2ML",
              "CORNWALL SYR PIPET OUTFIT 5ML": "CORNWALL SYR PIPET OUTFIT 5ML",
              "EPIDURAL NEEDLE": "EPIDURAL NEEDLE",
              "EPIDURAL NEEDLE TW": "EPIDURAL NEEDLE TW",
              "EPILOR SYRINGE": "EPILOR SYRINGE",
              "FILTER NEEDLE": "FILTER NEEDLE",
              "GLASPAK DISP SYRINGE": "GLASPAK DISP SYRINGE",
              "GLASPAK TB SYRINGE": "GLASPAK TB SYRINGE",
              "GREENE POINT SPINAL NEEDLE": "GREENE POINT SPINAL NEEDLE",
              "INFUSION NEEDLE 15GX2\"\"": "INFUSION NEEDLE 15GX2\"\"",
              "INJECT-EASE AUTOMATIC INJECTOR": "INJECT-EASE AUTOMATIC INJECTOR",
              "LLX EXTENSION NEEDLE": "LLX EXTENSION NEEDLE",
              "LOCAL ANESTHESIA NEEDLE": "LOCAL ANESTHESIA NEEDLE",
              "MEDSAVER SYRINGE": "MEDSAVER SYRINGE",
              "MULTIFIT REUSABLE SYRINGE": "MULTIFIT REUSABLE SYRINGE",
              "MULTIFIT REUSABLE TB SYRINGE": "MULTIFIT REUSABLE TB SYRINGE",
              "NOKOR ADMIX NEEDLE": "NOKOR ADMIX NEEDLE",
              "NOKOR VENTED NEEDLE": "NOKOR VENTED NEEDLE",
              "PARASYMPATH NERVE NDL 20GX6\"\"": "PARASYMPATH NERVE NDL 20GX6\"\"",
              "REGIONAL BLOCK NEEDLE": "REGIONAL BLOCK NEEDLE",
              "RETROBULBAR NEEDLE": "RETROBULBAR NEEDLE",
              "REUSABLE SYRINGE BARREL 1.5OZ": "REUSABLE SYRINGE BARREL 1.5OZ",
              "REUSABLE SYRINGE BARREL 1OZ": "REUSABLE SYRINGE BARREL 1OZ",
              "REUSABLE SYRINGE BARREL 2OZ": "REUSABLE SYRINGE BARREL 2OZ",
              "REUSABLE SYRINGE BARREL 3OZ": "REUSABLE SYRINGE BARREL 3OZ",
              "REUSABLE SYRINGE BARREL 4OZ": "REUSABLE SYRINGE BARREL 4OZ",
              "ROBB INFUSION SYRINGE": "ROBB INFUSION SYRINGE",
              "ROBB-TYPE ANGIO SYRINGE 80CC": "ROBB-TYPE ANGIO SYRINGE 80CC",
              "SAFETY-GARD NEEDLE 18G": "SAFETY-GARD NEEDLE 18G",
              "SAFETY-GARD NEEDLE 20G": "SAFETY-GARD NEEDLE 20G",
              "SAFETY-LOK SYRINGE": "SAFETY-LOK SYRINGE",
              "SAFETY-LOK TB SYRINGE": "SAFETY-LOK TB SYRINGE",
              "SANA-LOK CONTROL SYRINGE": "SANA-LOK CONTROL SYRINGE",
              "SLIP TIP SYRINGE": "SLIP TIP SYRINGE",
              "SPINAL NEEDLE": "SPINAL NEEDLE",
              "SPINAL NEEDLE TW": "SPINAL NEEDLE TW",
              "SPINAL NEEDLE/STYLET TW": "SPINAL NEEDLE/STYLET TW",
              "STIMEX INSULATED NEEDLE": "STIMEX INSULATED NEEDLE",
              "SYMPATHETIC NERVE NEEDLE": "SYMPATHETIC NERVE NEEDLE",
              "SYRINGE": "SYRINGE",
              "SYRINGE DISPOSABLE": "SYRINGE DISPOSABLE",
              "SYRINGE/CANNULA": "SYRINGE/CANNULA",
              "TITUS NEEDLE 18GX1-1/4\"\"": "TITUS NEEDLE 18GX1-1/4\"\"",
              "TONSIL NDL CURVED 23GX1/2\"\"": "TONSIL NDL CURVED 23GX1/2\"\"",
              "TONSIL NDL STRAIGHT 23GX3/4": "TONSIL NDL STRAIGHT 23GX3/4",
              "TUOHY EPIDURAL NEEDLE TW": "TUOHY EPIDURAL NEEDLE TW",
              "WEISS EPIDURAL NEEDLE": "WEISS EPIDURAL NEEDLE",
              "WEISS EPIDURAL NEEDLE TW": "WEISS EPIDURAL NEEDLE TW",
              "WHITACRE SPINAL NEEDLE": "WHITACRE SPINAL NEEDLE",
              "WHITE PED ARTERIAL NEEDLE": "WHITE PED ARTERIAL NEEDLE",
              "YALE REUSABLE NEEDLE": "YALE REUSABLE NEEDLE",
              "YALE REUSABLE SYRINGE": "YALE REUSABLE SYRINGE",
              "YALE REUSABLE TB SYRINGE": "YALE REUSABLE TB SYRINGE",
              "YALE SPINAL NEEDLE": "YALE SPINAL NEEDLE"
            },
            "BERGEN BRUNSWIG": {
              "GNP CLICKFINE PEN NEEDLES": "GNP CLICKFINE PEN NEEDLES",
              "GNP INSULIN SYRINGE": "GNP INSULIN SYRINGE",
              "GNP ULTRA COM INSULIN SYRINGE": "GNP ULTRA COM INSULIN SYRINGE"
            },
            "BOCA MEDICAL PRODUCTS": {
              "ULTILET INSULIN SYRINGE": "ULTILET INSULIN SYRINGE",
              "ULTILET INSULIN SYRINGE SHORT": "ULTILET INSULIN SYRINGE SHORT",
              "ULTILET PEN NEEDLE": "ULTILET PEN NEEDLE"
            },
            "CAMBRIDGE SENSORS USA": {
              "MICRODOT PEN NEEDLE": "MICRODOT PEN NEEDLE"
            },
            "CAN-AM": {
              "MONOJECT INSULIN SYRINGE": "MONOJECT INSULIN SYRINGE",
              "MONOJECT ULTRA COMFORT SYRINGE": "MONOJECT ULTRA COMFORT SYRINGE"
            },
            "CEQUR CORPORATION": {
              "CEQUR SIMPLICITY 2U": "CEQUR SIMPLICITY 2U",
              "CEQUR SIMPLICITY INSERTER": "CEQUR SIMPLICITY INSERTER",
              "CEQUR SIMPLICITY STARTER": "CEQUR SIMPLICITY STARTER"
            },
            "CHAIN DRUG CONSORTIUM": {
              "INSULIN SYRINGE": "INSULIN SYRINGE",
              "PEN NEEDLES": "PEN NEEDLES",
              "UNIFINE PENTIPS": "UNIFINE PENTIPS"
            },
            "COMPANION MEDICAL": {
              "INPEN 100-BLUE-LILLY": "INPEN 100-BLUE-LILLY",
              "INPEN 100-BLUE-NOVO": "INPEN 100-BLUE-NOVO",
              "INPEN 100-GRAY-LILLY": "INPEN 100-GRAY-LILLY",
              "INPEN 100-GREY-NOVO": "INPEN 100-GREY-NOVO",
              "INPEN 100-PINK-LILLY": "INPEN 100-PINK-LILLY",
              "INPEN 100-PINK-NOVO": "INPEN 100-PINK-NOVO"
            },
            "COVIDIEN MEDICAL SUPPLIES": {
              "KINRAY INSULIN SYRINGE": "KINRAY INSULIN SYRINGE",
              "MAGELLAN INSULIN SAFETY SYR": "MAGELLAN INSULIN SAFETY SYR",
              "MAGELLAN SYRINGE-SAFETY NEEDLE": "MAGELLAN SYRINGE-SAFETY NEEDLE",
              "MAGELLAN TUBERCULIN SYRINGE": "MAGELLAN TUBERCULIN SYRINGE",
              "MEDIC INSULIN SYRINGE": "MEDIC INSULIN SYRINGE",
              "MONOJECT ALLERGIST TRAY": "MONOJECT ALLERGIST TRAY",
              "MONOJECT BLOOD COLLECTION NDL": "MONOJECT BLOOD COLLECTION NDL",
              "MONOJECT BLUNT CANNULA": "MONOJECT BLUNT CANNULA",
              "MONOJECT BLUNTIP CANNULA": "MONOJECT BLUNTIP CANNULA",
              "MONOJECT BLUNTIP SYR/CANNULA": "MONOJECT BLUNTIP SYR/CANNULA",
              "MONOJECT CONTROL SYRINGE": "MONOJECT CONTROL SYRINGE",
              "MONOJECT FILTER ASPIRATOR": "MONOJECT FILTER ASPIRATOR",
              "MONOJECT FILTER NEEDLE": "MONOJECT FILTER NEEDLE",
              "MONOJECT HYPODERMIC NEEDLE": "MONOJECT HYPODERMIC NEEDLE",
              "MONOJECT INSULIN SYRINGE": "MONOJECT INSULIN SYRINGE",
              "MONOJECT INTRODUCER NEEDLE": "MONOJECT INTRODUCER NEEDLE",
              "MONOJECT LIFESHIELD SYRINGE": "MONOJECT LIFESHIELD SYRINGE",
              "MONOJECT MAGELLAN SAFETY NDL": "MONOJECT MAGELLAN SAFETY NDL",
              "MONOJECT MAGELLAN SYRINGE": "MONOJECT MAGELLAN SYRINGE",
              "MONOJECT MEDICATION TRANSF NDL": "MONOJECT MEDICATION TRANSF NDL",
              "MONOJECT PHARMACY TRAY": "MONOJECT PHARMACY TRAY",
              "MONOJECT PISTON SYRINGE": "MONOJECT PISTON SYRINGE",
              "MONOJECT SAFETY SYRINGE/SHIELD": "MONOJECT SAFETY SYRINGE/SHIELD",
              "MONOJECT SOFTPACK/CATHTIP": "MONOJECT SOFTPACK/CATHTIP",
              "MONOJECT SOFTPACK/LLOCK": "MONOJECT SOFTPACK/LLOCK",
              "MONOJECT SOFTPACK/LTIP": "MONOJECT SOFTPACK/LTIP",
              "MONOJECT SOFTPACK/RG LOCK": "MONOJECT SOFTPACK/RG LOCK",
              "MONOJECT SOFTPACK/RG LUER": "MONOJECT SOFTPACK/RG LUER",
              "MONOJECT SYRINGE": "MONOJECT SYRINGE",
              "MONOJECT SYRINGE CATH TIP": "MONOJECT SYRINGE CATH TIP",
              "MONOJECT SYRINGE ECC LUER": "MONOJECT SYRINGE ECC LUER",
              "MONOJECT SYRINGE ECCENTRIC TIP": "MONOJECT SYRINGE ECCENTRIC TIP",
              "MONOJECT SYRINGE LUER LOCK": "MONOJECT SYRINGE LUER LOCK",
              "MONOJECT SYRINGE LUER-LOCK TIP": "MONOJECT SYRINGE LUER-LOCK TIP",
              "MONOJECT SYRINGE PHARMACY TRAY": "MONOJECT SYRINGE PHARMACY TRAY",
              "MONOJECT SYRINGE REG LUER": "MONOJECT SYRINGE REG LUER",
              "MONOJECT SYRINGE REGULAR TIP": "MONOJECT SYRINGE REGULAR TIP",
              "MONOJECT SYRINGE TOOMEY TYPE": "MONOJECT SYRINGE TOOMEY TYPE",
              "MONOJECT TB SAFETY SYRINGE": "MONOJECT TB SAFETY SYRINGE",
              "MONOJECT TB SYRINGE": "MONOJECT TB SYRINGE",
              "MONOJECT ULTRA COMFORT SYRINGE": "MONOJECT ULTRA COMFORT SYRINGE",
              "MOORE MONO INSULIN SYRINGE": "MOORE MONO INSULIN SYRINGE",
              "TOPCO INSULIN SYRINGE": "TOPCO INSULIN SYRINGE",
              "V-R MONO INSULIN SYRINGE": "V-R MONO INSULIN SYRINGE"
            },
            "DELTA HI-TECH": {
              "MAXI-COMFORT INSULIN SYRINGE": "MAXI-COMFORT INSULIN SYRINGE",
              "MAXI-COMFORT SAFETY PEN NEEDLE": "MAXI-COMFORT SAFETY PEN NEEDLE",
              "MAXICOMFORT II PEN NEEDLE": "MAXICOMFORT II PEN NEEDLE",
              "MAXICOMFORT SYR 27G X 1/2\"\"": "MAXICOMFORT SYR 27G X 1/2\"\"",
              "ULTRA THIN PEN NEEDLES": "ULTRA THIN PEN NEEDLES",
              "ULTRA-THIN II INS SYR SHORT": "ULTRA-THIN II INS SYR SHORT",
              "ULTRA-THIN II INSULIN SYRINGE": "ULTRA-THIN II INSULIN SYRINGE",
              "ULTRA-THIN II MINI PEN NEEDLE": "ULTRA-THIN II MINI PEN NEEDLE",
              "ULTRA-THIN II PEN NEEDLE SHORT": "ULTRA-THIN II PEN NEEDLE SHORT",
              "ULTRA-THIN II PEN NEEDLES": "ULTRA-THIN II PEN NEEDLES"
            },
            "DIABETIC SUPPLY OF SUNCOAST": {
              "ADVOCATE INSULIN PEN NEEDLES": "ADVOCATE INSULIN PEN NEEDLES",
              "ADVOCATE INSULIN SYRINGE": "ADVOCATE INSULIN SYRINGE"
            },
            "DIATHRIVE": {
              "DIATHRIVE PEN NEEDLE": "DIATHRIVE PEN NEEDLE"
            },
            "DINORIO": {
              "INSULIN SYRINGE": "INSULIN SYRINGE"
            },
            "DYNAREX CORPORATION": {
              "DIALYSIS SAFETY SYRINGE/NEEDLE": "DIALYSIS SAFETY SYRINGE/NEEDLE",
              "HYPODERMIC NEEDLE": "HYPODERMIC NEEDLE",
              "INSULIN SYRINGE": "INSULIN SYRINGE",
              "PEN NEEDLES": "PEN NEEDLES",
              "SAFETY INSULIN SYRINGES": "SAFETY INSULIN SYRINGES",
              "SAFETY SYRINGE/NEEDLE": "SAFETY SYRINGE/NEEDLE",
              "SAFETY SYRINGES/NEEDLE": "SAFETY SYRINGES/NEEDLE",
              "SECURE SAFE ALLERGY TRAY": "SECURE SAFE ALLERGY TRAY",
              "SECURESAFE HYPODERMIC NEEDLE": "SECURESAFE HYPODERMIC NEEDLE",
              "SECURESAFE INSULIN SYRINGE": "SECURESAFE INSULIN SYRINGE",
              "SECURESAFE SYRINGE/NEEDLE": "SECURESAFE SYRINGE/NEEDLE",
              "SECURESAFE TUBERCULIN SYRINGE": "SECURESAFE TUBERCULIN SYRINGE",
              "SYRINGE LUER LOCK": "SYRINGE LUER LOCK",
              "SYRINGE LUER SLIP": "SYRINGE LUER SLIP"
            },
            "EQUALINE": {
              "EQL INSULIN SYRINGE": "EQL INSULIN SYRINGE",
              "EQL ULTRA COMFORT INSULIN SYR": "EQL ULTRA COMFORT INSULIN SYR"
            },
            "EXEL INTERNATIONAL": {
              "ALLERGY SYRINGE": "ALLERGY SYRINGE",
              "EXEL COMFORT POINT INSULIN SYR": "EXEL COMFORT POINT INSULIN SYR",
              "EXEL COMFORT POINT PEN NEEDLE": "EXEL COMFORT POINT PEN NEEDLE",
              "HUBER NEEDLE": "HUBER NEEDLE",
              "HYPODERMIC NEEDLE": "HYPODERMIC NEEDLE",
              "INSULIN SYRINGE/NEEDLE": "INSULIN SYRINGE/NEEDLE",
              "MULTI-DRAW NEEDLE": "MULTI-DRAW NEEDLE",
              "SYRINGE": "SYRINGE",
              "SYRINGE 10-12 ML": "SYRINGE 10-12 ML",
              "SYRINGE 2-3 ML": "SYRINGE 2-3 ML",
              "SYRINGE 20-25 ML": "SYRINGE 20-25 ML",
              "SYRINGE 30-35 ML": "SYRINGE 30-35 ML",
              "SYRINGE 5-6 ML": "SYRINGE 5-6 ML",
              "SYRINGE 50-60 ML": "SYRINGE 50-60 ML",
              "TB SYRINGE 1 ML": "TB SYRINGE 1 ML",
              "TUBERCULIN SYRINGE": "TUBERCULIN SYRINGE"
            },
            "FACET TECHNOLOGIES": {
              "CAREFINE PEN NEEDLES": "CAREFINE PEN NEEDLES"
            },
            "FIFTY50 MEDICAL": {
              "FIFTY50 PEN NEEDLES": "FIFTY50 PEN NEEDLES",
              "FIFTY50 SUPERIOR COMFORT SYR": "FIFTY50 SUPERIOR COMFORT SYR"
            },
            "FRESENIUS KABI USA": {
              "FLOW-EZE VENTED NEEDLE": "FLOW-EZE VENTED NEEDLE"
            },
            "FUTURE DIAGNOSTICS": {
              "CARETOUCH INSULIN SYRINGE": "CARETOUCH INSULIN SYRINGE",
              "CARETOUCH PEN NEEDLES": "CARETOUCH PEN NEEDLES"
            },
            "GLOBAL MEDICAL PRODUCTS": {
              "EASY GLIDE LUER LOCK SYRINGE": "EASY GLIDE LUER LOCK SYRINGE",
              "EASY GLIDE PEN NEEDLES": "EASY GLIDE PEN NEEDLES",
              "GLOBAL EASE INJECT PEN NEEDLES": "GLOBAL EASE INJECT PEN NEEDLES",
              "GLOBAL EASY GLIDE INSULIN SYR": "GLOBAL EASY GLIDE INSULIN SYR",
              "GLOBAL EASY GLIDE PEN NEEDLES": "GLOBAL EASY GLIDE PEN NEEDLES",
              "GLOBAL INJECT EASE INSULIN SYR": "GLOBAL INJECT EASE INSULIN SYR",
              "GLOBAL INSULIN SYRINGES": "GLOBAL INSULIN SYRINGES"
            },
            "HOME AIDE DIAGNOSTICS": {
              "EASY COMFORT INSULIN SYRINGE": "EASY COMFORT INSULIN SYRINGE",
              "EASY COMFORT PEN NEEDLES": "EASY COMFORT PEN NEEDLES",
              "PRO COMFORT INSULIN SYRINGE": "PRO COMFORT INSULIN SYRINGE",
              "PRO COMFORT PEN NEEDLES": "PRO COMFORT PEN NEEDLES",
              "PURE COMFORT PEN NEEDLE": "PURE COMFORT PEN NEEDLE",
              "TRUE COMFORT INSULIN SYRINGE": "TRUE COMFORT INSULIN SYRINGE",
              "TRUE COMFORT PEN NEEDLES": "TRUE COMFORT PEN NEEDLES"
            },
            "HTL-STREFA": {
              "DROPLET INSULIN SYRINGE": "DROPLET INSULIN SYRINGE",
              "DROPLET MICRON": "DROPLET MICRON",
              "DROPLET PEN NEEDLES": "DROPLET PEN NEEDLES",
              "DROPSAFE SAFETY PEN NEEDLES": "DROPSAFE SAFETY PEN NEEDLES",
              "PEN NEEDLES": "PEN NEEDLES"
            },
            "INTRA PUMP INFUSION SYSTEMS": {
              "CRONO SYRINGE": "CRONO SYRINGE"
            },
            "LEADER BRAND PRODUCTS": {
              "PEN NEEDLES": "PEN NEEDLES",
              "PEN NEEDLES 1/2\"\"": "PEN NEEDLES 1/2\"\"",
              "PEN NEEDLES 5/16\"\"": "PEN NEEDLES 5/16\"\""
            },
            "LILLY": {
              "HUMAPEN LUXURA HD": "HUMAPEN LUXURA HD",
              "HUMATROPEN FOR 12MG": "HUMATROPEN FOR 12MG",
              "HUMATROPEN FOR 24MG": "HUMATROPEN FOR 24MG",
              "HUMATROPEN FOR 6MG": "HUMATROPEN FOR 6MG"
            },
            "MCKESSON": {
              "HM ULTICARE INSULIN SYRINGE": "HM ULTICARE INSULIN SYRINGE",
              "HM ULTICARE SHORT PEN NEEDLES": "HM ULTICARE SHORT PEN NEEDLES"
            },
            "MCKESSON MEDICAL-SURGICAL": {
              "PREVENT SAFETY PEN NEEDLES": "PREVENT SAFETY PEN NEEDLES"
            },
            "MEDICAL PLASTIC DEVICES": {
              "ABOUTTIME PEN NEEDLE": "ABOUTTIME PEN NEEDLE",
              "INSULIN SYRINGE": "INSULIN SYRINGE",
              "INSUPEN PEN NEEDLES": "INSUPEN PEN NEEDLES",
              "INSUPEN SENSITIVE": "INSUPEN SENSITIVE",
              "INSUPEN ULTRAFIN": "INSUPEN ULTRAFIN"
            },
            "MEDICINE SHOPPE": {
              "ULTRA COMFORT INSULIN SYRINGE": "ULTRA COMFORT INSULIN SYRINGE"
            },
            "MEDICORE": {
              "LITETOUCH INSULIN SYRINGE": "LITETOUCH INSULIN SYRINGE",
              "LITETOUCH PEN NEEDLES": "LITETOUCH PEN NEEDLES"
            },
            "MEDISCA": {
              "MONOJECT FILTER NEEDLE": "MONOJECT FILTER NEEDLE",
              "MONOJECT HYPODERMIC NEEDLE": "MONOJECT HYPODERMIC NEEDLE",
              "MONOJECT SYRINGE": "MONOJECT SYRINGE",
              "SYRINGE LUER LOCK": "SYRINGE LUER LOCK",
              "SYRINGE LUER SLIP": "SYRINGE LUER SLIP",
              "SYRINGE/HYPODERMIC SAFETY": "SYRINGE/HYPODERMIC SAFETY"
            },
            "MEDLINE INDUS": {
              "PEN NEEDLES": "PEN NEEDLES"
            },
            "MEIJER": {
              "REALITY INSULIN SYRINGE": "REALITY INSULIN SYRINGE"
            },
            "MHC MEDICAL PRODUCTS": {
              "EASY TOUCH ALLERGY SYRINGE": "EASY TOUCH ALLERGY SYRINGE",
              "EASY TOUCH FLIPLOCK INSULIN SY": "EASY TOUCH FLIPLOCK INSULIN SY",
              "EASY TOUCH FLIPLOCK NEEDLES": "EASY TOUCH FLIPLOCK NEEDLES",
              "EASY TOUCH FLIPLOCK SAFETY SYR": "EASY TOUCH FLIPLOCK SAFETY SYR",
              "EASY TOUCH FLURINGE": "EASY TOUCH FLURINGE",
              "EASY TOUCH FLURINGE FLIPLOCK": "EASY TOUCH FLURINGE FLIPLOCK",
              "EASY TOUCH FLURINGE SHEATHLOCK": "EASY TOUCH FLURINGE SHEATHLOCK",
              "EASY TOUCH HYPODERMIC NEEDLE": "EASY TOUCH HYPODERMIC NEEDLE",
              "EASY TOUCH INSULIN SAFETY SYR": "EASY TOUCH INSULIN SAFETY SYR",
              "EASY TOUCH INSULIN SYRINGE": "EASY TOUCH INSULIN SYRINGE",
              "EASY TOUCH PEN NEEDLES": "EASY TOUCH PEN NEEDLES",
              "EASY TOUCH SAFETY PEN NEEDLES": "EASY TOUCH SAFETY PEN NEEDLES",
              "EASY TOUCH SAFETY SYRINGE": "EASY TOUCH SAFETY SYRINGE",
              "EASY TOUCH SHEATHLOCK SYRINGE": "EASY TOUCH SHEATHLOCK SYRINGE",
              "EASY TOUCH SYRINGE BARREL 10ML": "EASY TOUCH SYRINGE BARREL 10ML",
              "EASY TOUCH SYRINGE BARREL 1ML": "EASY TOUCH SYRINGE BARREL 1ML",
              "EASY TOUCH SYRINGE BARREL 3ML": "EASY TOUCH SYRINGE BARREL 3ML",
              "EASY TOUCH SYRINGE BARREL 5ML": "EASY TOUCH SYRINGE BARREL 5ML",
              "EASY TOUCH TB FLIPLOCK SYRINGE": "EASY TOUCH TB FLIPLOCK SYRINGE",
              "EASY TOUCH TB SHEATHLOCK SYR": "EASY TOUCH TB SHEATHLOCK SYR"
            },
            "NATIONAL MEDICAL PRODUCTS": {
              "J-TIP KIT W/VIAL ADAPTERS": "J-TIP KIT W/VIAL ADAPTERS"
            },
            "NIPRO MEDICAL CORPORATION": {
              "GLUCOPRO INSULIN SYRINGE": "GLUCOPRO INSULIN SYRINGE"
            },
            "NOVO NORDISK": {
              "NORDIPEN 5 INJECTION DEVICE": "NORDIPEN 5 INJECTION DEVICE",
              "NORDIPEN DELIVERY SYSTEM": "NORDIPEN DELIVERY SYSTEM",
              "NOVOFINE": "NOVOFINE",
              "NOVOFINE AUTOCOVER": "NOVOFINE AUTOCOVER",
              "NOVOFINE PLUS": "NOVOFINE PLUS",
              "NOVOPEN ECHO": "NOVOPEN ECHO",
              "NOVOTWIST": "NOVOTWIST"
            },
            "ONE PHARMA & MEDICAL SUPPLY CO": {
              "SURE-FINE PEN NEEDLES": "SURE-FINE PEN NEEDLES",
              "SURE-JECT INSULIN SYRINGE": "SURE-JECT INSULIN SYRINGE"
            },
            "OWEN MUMFORD": {
              "1ST TIER UNIFINE PENTIPS": "1ST TIER UNIFINE PENTIPS",
              "1ST TIER UNIFINE PENTIPS PLUS": "1ST TIER UNIFINE PENTIPS PLUS",
              "AURORA PEN NEEDLES": "AURORA PEN NEEDLES",
              "AURORA UNIFINE PENTIPS": "AURORA UNIFINE PENTIPS",
              "AUTOJECT 2": "AUTOJECT 2",
              "AUTOPEN": "AUTOPEN",
              "CAREONE UNIFINE PENTIPS": "CAREONE UNIFINE PENTIPS",
              "CAREONE UNIFINE PENTIPS PLUS": "CAREONE UNIFINE PENTIPS PLUS",
              "DRUG MART UNIFINE PENTIPS": "DRUG MART UNIFINE PENTIPS",
              "DRUG MART UNIFINE PENTIPS PLUS": "DRUG MART UNIFINE PENTIPS PLUS",
              "DUANE READE UNIFINE PENTIPS": "DUANE READE UNIFINE PENTIPS",
              "EQL SHORT PEN NEEDLE": "EQL SHORT PEN NEEDLE",
              "EQL ULTRA SHORT PEN NEEDLE": "EQL ULTRA SHORT PEN NEEDLE",
              "FREDS PHARMACY UNIFINE PENTIP+": "FREDS PHARMACY UNIFINE PENTIP+",
              "FREDS PHARMACY UNIFINE PENTIPS": "FREDS PHARMACY UNIFINE PENTIPS",
              "H-E-B INCONTROL PEN NEEDLES": "H-E-B INCONTROL PEN NEEDLES",
              "H-E-B INCONTROL UNIFINE PENTIP": "H-E-B INCONTROL UNIFINE PENTIP",
              "HEALTHWISE MINI PEN NEEDLES": "HEALTHWISE MINI PEN NEEDLES",
              "HEALTHWISE PEN NEEDLES": "HEALTHWISE PEN NEEDLES",
              "HEALTHWISE SHORT PEN NEEDLES": "HEALTHWISE SHORT PEN NEEDLES",
              "HEALTHWISE UNIFINE PENTIPS": "HEALTHWISE UNIFINE PENTIPS",
              "HEALTHY ACCENTS UNIFINE PENTIP": "HEALTHY ACCENTS UNIFINE PENTIP",
              "KROGER PEN NEEDLES": "KROGER PEN NEEDLES",
              "LEADER UNIFINE PENTIPS": "LEADER UNIFINE PENTIPS",
              "LEADER UNIFINE PENTIPS PLUS": "LEADER UNIFINE PENTIPS PLUS",
              "LIVE BETTER PEN NEEDLES": "LIVE BETTER PEN NEEDLES",
              "MARATHON MEDICAL PENTIPS": "MARATHON MEDICAL PENTIPS",
              "MEDICINE SHOPPE PEN NEEDLES": "MEDICINE SHOPPE PEN NEEDLES",
              "MEIJER PEN NEEDLES": "MEIJER PEN NEEDLES",
              "PC UNIFINE PENTIPS": "PC UNIFINE PENTIPS",
              "PENTIPS": "PENTIPS",
              "PREFERRED PLUS UNIFINE PENTIPS": "PREFERRED PLUS UNIFINE PENTIPS",
              "PX MINI PEN NEEDLES": "PX MINI PEN NEEDLES",
              "QC PEN NEEDLES": "QC PEN NEEDLES",
              "QC UNIFINE PENTIPS": "QC UNIFINE PENTIPS",
              "SHOPKO UNIFINE PENTIPS": "SHOPKO UNIFINE PENTIPS",
              "SHOPKO UNIFINE PENTIPS PLUS": "SHOPKO UNIFINE PENTIPS PLUS",
              "TODAYS HEALTH MINI PEN NEEDLES": "TODAYS HEALTH MINI PEN NEEDLES",
              "TODAYS HEALTH PEN NEEDLES": "TODAYS HEALTH PEN NEEDLES",
              "TODAYS HEALTH SHORT PEN NEEDLE": "TODAYS HEALTH SHORT PEN NEEDLE",
              "UNIFINE PENTIPS": "UNIFINE PENTIPS",
              "UNIFINE PENTIPS PLUS": "UNIFINE PENTIPS PLUS",
              "UNIFINE SAFECONTROL PEN NEEDLE": "UNIFINE SAFECONTROL PEN NEEDLE",
              "VALUMARK PEN NEEDLES": "VALUMARK PEN NEEDLES",
              "VIDA MIA UNIFINE PENTIPS": "VIDA MIA UNIFINE PENTIPS",
              "WEGMANS UNIFINE PENTIPS PLUS": "WEGMANS UNIFINE PENTIPS PLUS"
            },
            "PCCA": {
              "SYRINGE LUER LOCK": "SYRINGE LUER LOCK"
            },
            "PERRIGO DIABETES CARE": {
              "CLICKFINE PEN NEEDLES": "CLICKFINE PEN NEEDLES",
              "COMFORT ASSIST INSULIN SYRINGE": "COMFORT ASSIST INSULIN SYRINGE",
              "EQL INSULIN SYRINGE": "EQL INSULIN SYRINGE",
              "GNP CLICKFINE PEN NEEDLES": "GNP CLICKFINE PEN NEEDLES",
              "GNP INSULIN SYRINGE": "GNP INSULIN SYRINGE",
              "GOODSENSE CLICKFINE PEN NEEDLE": "GOODSENSE CLICKFINE PEN NEEDLE",
              "GOODSENSE PEN NEEDLE PENFINE": "GOODSENSE PEN NEEDLE PENFINE",
              "KINRAY INSULIN SYRINGE": "KINRAY INSULIN SYRINGE",
              "KMART VALU INSULIN SYRINGE 29G": "KMART VALU INSULIN SYRINGE 29G",
              "KMART VALU INSULIN SYRINGE 30G": "KMART VALU INSULIN SYRINGE 30G",
              "KROGER INSULIN SYRINGE": "KROGER INSULIN SYRINGE",
              "KROGER PEN NEEDLES": "KROGER PEN NEEDLES",
              "LEADER INSULIN SYRINGE": "LEADER INSULIN SYRINGE",
              "LONGS INSULIN SYRINGE": "LONGS INSULIN SYRINGE",
              "MS INSULIN SYRINGE": "MS INSULIN SYRINGE",
              "PEN NEEDLES": "PEN NEEDLES",
              "PEN NEEDLES 5/16\"\"": "PEN NEEDLES 5/16\"\"",
              "PREFERRED PLUS INSULIN SYRINGE": "PREFERRED PLUS INSULIN SYRINGE",
              "PX EXTRA SHORT PEN NEEDLES": "PX EXTRA SHORT PEN NEEDLES",
              "PX PEN NEEDLE": "PX PEN NEEDLE",
              "RELION MINI PEN NEEDLES": "RELION MINI PEN NEEDLES",
              "RELION PEN NEEDLES": "RELION PEN NEEDLES",
              "RELION SHORT PEN NEEDLES": "RELION SHORT PEN NEEDLES",
              "SM INSULIN SYRINGE": "SM INSULIN SYRINGE",
              "TOPCARE CLICKFINE PEN NEEDLES": "TOPCARE CLICKFINE PEN NEEDLES",
              "TOPCARE ULTRA COMFORT INS SYR": "TOPCARE ULTRA COMFORT INS SYR",
              "ULTRA-COMFORT INSULIN SYRINGE": "ULTRA-COMFORT INSULIN SYRINGE",
              "VALUE HEALTH INSULIN SYRINGE": "VALUE HEALTH INSULIN SYRINGE",
              "VP INSULIN SYRINGE": "VP INSULIN SYRINGE"
            },
            "PLATINUM VENTURE GROUP": {
              "PENTIPS": "PENTIPS"
            },
            "PRESCRIPTION SOLUTIONS, INC.": {
              "INSULIN SYRINGE": "INSULIN SYRINGE"
            },
            "PRODIGY DIABETES CARE": {
              "PRODIGY INSULIN SYRINGE": "PRODIGY INSULIN SYRINGE"
            },
            "PUBLIX SUPER MARKETS INC.": {
              "PX SHORTLENGTH PEN NEEDLES": "PX SHORTLENGTH PEN NEEDLES"
            },
            "QUALITY MEDICAL PRODUCTS USA": {
              "ULTRA FLO INSULIN PEN NEEDLES": "ULTRA FLO INSULIN PEN NEEDLES",
              "ULTRA FLO INSULIN SYRINGE": "ULTRA FLO INSULIN SYRINGE"
            },
            "RETRACTABLE TECHNOLOGIES": {
              "EASYPOINT NEEDLE": "EASYPOINT NEEDLE",
              "PATIENT SAFE SYRINGE": "PATIENT SAFE SYRINGE",
              "VANISHPOINT ALLERGY TRAY": "VANISHPOINT ALLERGY TRAY",
              "VANISHPOINT INSULIN SYRINGE": "VANISHPOINT INSULIN SYRINGE",
              "VANISHPOINT SAFETY SYRINGE": "VANISHPOINT SAFETY SYRINGE",
              "VANISHPOINT SYRINGE": "VANISHPOINT SYRINGE",
              "VANISHPOINT TUBERCULIN SYRINGE": "VANISHPOINT TUBERCULIN SYRINGE"
            },
            "RITE AID CORPORATION": {
              "RA INSULIN SYRINGE": "RA INSULIN SYRINGE",
              "RA PEN NEEDLES": "RA PEN NEEDLES"
            },
            "RUGBY LABORATORIES": {
              "EASY TOUCH INSULIN SYRINGE": "EASY TOUCH INSULIN SYRINGE",
              "INSULIN SYRINGE/NEEDLE": "INSULIN SYRINGE/NEEDLE"
            },
            "RX ELITE": {
              "ELITE-THIN INSULIN SYRINGE": "ELITE-THIN INSULIN SYRINGE"
            },
            "SAM'S WEST": {
              "MM INSULIN SYRINGE/NEEDLE": "MM INSULIN SYRINGE/NEEDLE",
              "MM PEN NEEDLES": "MM PEN NEEDLES"
            },
            "SANDOZ": {
              "OMNITROPE PEN 10 INJ DEVICE": "OMNITROPE PEN 10 INJ DEVICE",
              "OMNITROPE PEN 5 INJ DEVICE": "OMNITROPE PEN 5 INJ DEVICE"
            },
            "SDI USA": {
              "COMFORT EZ INSULIN SYRINGE": "COMFORT EZ INSULIN SYRINGE",
              "COMFORT EZ MICRO PEN NEEDLES": "COMFORT EZ MICRO PEN NEEDLES",
              "COMFORT EZ PEN NEEDLES": "COMFORT EZ PEN NEEDLES",
              "COMFORT EZ SHORT PEN NEEDLES": "COMFORT EZ SHORT PEN NEEDLES"
            },
            "SELECT BRAND": {
              "SB INSULIN SYRINGE": "SB INSULIN SYRINGE"
            },
            "SIMPLE DIAGNOSTICS": {
              "CLEVER CHOICE COMFORT EZ": "CLEVER CHOICE COMFORT EZ",
              "COMFORT EZ INSULIN SYRINGE": "COMFORT EZ INSULIN SYRINGE",
              "COMFORT EZ PEN NEEDLES": "COMFORT EZ PEN NEEDLES"
            },
            "SPECIALTY MEDICAL SUPPLIES": {
              "INSULIN SYRINGE": "INSULIN SYRINGE",
              "PEN NEEDLES 3/16\"\"": "PEN NEEDLES 3/16\"\"",
              "PEN NEEDLES 5/16\"\"": "PEN NEEDLES 5/16\"\""
            },
            "TRIVIDIA HEALTH": {
              "TRUEPLUS 5-BEVEL PEN NEEDLES": "TRUEPLUS 5-BEVEL PEN NEEDLES",
              "TRUEPLUS INSULIN SYRINGE": "TRUEPLUS INSULIN SYRINGE",
              "TRUEPLUS PEN NEEDLES": "TRUEPLUS PEN NEEDLES"
            },
            "ULTIMED": {
              "GNP ULTICARE PEN NEEDLES": "GNP ULTICARE PEN NEEDLES",
              "INSULIN SYRINGE": "INSULIN SYRINGE",
              "INSULIN SYRINGE-NEEDLE U-100": "INSULIN SYRINGE-NEEDLE U-100",
              "LUER LOCK SAFETY SYRINGES": "LUER LOCK SAFETY SYRINGES",
              "SCHNUCKS INSULIN SYRINGE": "SCHNUCKS INSULIN SYRINGE",
              "ULTICARE INSULIN SAFETY SYR": "ULTICARE INSULIN SAFETY SYR",
              "ULTICARE INSULIN SYRINGE": "ULTICARE INSULIN SYRINGE",
              "ULTICARE MICRO PEN NEEDLES": "ULTICARE MICRO PEN NEEDLES",
              "ULTICARE MINI PEN NEEDLES": "ULTICARE MINI PEN NEEDLES",
              "ULTICARE PEN NEEDLES": "ULTICARE PEN NEEDLES",
              "ULTICARE SAFETY SYRINGE": "ULTICARE SAFETY SYRINGE",
              "ULTICARE SHORT PEN NEEDLES": "ULTICARE SHORT PEN NEEDLES",
              "ULTICARE SYRINGE": "ULTICARE SYRINGE",
              "ULTICARE TUBERCULIN SAFETY SYR": "ULTICARE TUBERCULIN SAFETY SYR",
              "ULTIGUARD SAFEPACK PEN NEEDLE": "ULTIGUARD SAFEPACK PEN NEEDLE"
            },
            "UNIVEC": {
              "ANTI-STICK ALLERGY SYRINGE": "ANTI-STICK ALLERGY SYRINGE",
              "ANTI-STICK IMMUN SYRINGE": "ANTI-STICK IMMUN SYRINGE",
              "ANTI-STICK INSULIN SYRINGE": "ANTI-STICK INSULIN SYRINGE",
              "ANTI-STICK LUER LOCK SYRINGE": "ANTI-STICK LUER LOCK SYRINGE",
              "ANTI-STICK TUBERCULIN SYRINGE": "ANTI-STICK TUBERCULIN SYRINGE",
              "INSULIN SYRINGE": "INSULIN SYRINGE"
            },
            "US DIAGNOSTICS": {
              "INSULIN SYRINGE-NEEDLE U-100": "INSULIN SYRINGE-NEEDLE U-100",
              "PEN NEEDLES": "PEN NEEDLES"
            },
            "US MEDICAL INSTRUMENTS": {
              "SAFESNAP ALLERGY SYRINGE": "SAFESNAP ALLERGY SYRINGE",
              "SAFESNAP INSULIN SYRINGE": "SAFESNAP INSULIN SYRINGE",
              "SAFESNAP SYRINGE": "SAFESNAP SYRINGE",
              "SAFESNAP TUBERCULIN SYRINGE": "SAFESNAP TUBERCULIN SYRINGE"
            },
            "WAL-MART": {
              "RELI-ON INSULIN SYRINGE": "RELI-ON INSULIN SYRINGE",
              "RELION INSULIN SYRINGE": "RELION INSULIN SYRINGE",
              "RELION PEN NEEDLES": "RELION PEN NEEDLES",
              "RELION SHORT PEN NEEDLES": "RELION SHORT PEN NEEDLES"
            },
            "WALGREENS": {
              "INSULIN SYRINGE": "INSULIN SYRINGE"
            }
          },
          "Parenteral Catheters": {
            "BD DIABETES CARE": {
              "BD SAF-T-INTIMA 18G X 1\"\"": "BD SAF-T-INTIMA 18G X 1\"\"",
              "BD SAF-T-INTIMA 20GX1\"\"": "BD SAF-T-INTIMA 20GX1\"\"",
              "BD SAF-T-INTIMA 22GX3/4\"\"": "BD SAF-T-INTIMA 22GX3/4\"\"",
              "BD SAF-T-INTIMA 24GX3/4\"\"": "BD SAF-T-INTIMA 24GX3/4\"\""
            },
            "BD MEDICAL SURGICAL SYSTEMS": {
              "BD ANGIOCATH AUTOGUARD": "BD ANGIOCATH AUTOGUARD",
              "BD FIRST MIDCATH 18G/4FRX20CM": "BD FIRST MIDCATH 18G/4FRX20CM",
              "BD FIRST MIDCATH 20G/3FRX20CM": "BD FIRST MIDCATH 20G/3FRX20CM",
              "BD FIRST PICC 16G/5FRX65CM": "BD FIRST PICC 16G/5FRX65CM",
              "BD FIRST PICC 18G/4FRX65CM": "BD FIRST PICC 18G/4FRX65CM",
              "BD FIRST PICC 20G/3FRX65CM": "BD FIRST PICC 20G/3FRX65CM",
              "BD FIRST PICC 22G/2.8FRX50CM": "BD FIRST PICC 22G/2.8FRX50CM",
              "BD FIRST PICC 26G/1.9FRX50CM": "BD FIRST PICC 26G/1.9FRX50CM",
              "BD L-CATH 16G/5FR": "BD L-CATH 16G/5FR",
              "BD L-CATH 16G/5FRX60CM": "BD L-CATH 16G/5FRX60CM",
              "BD L-CATH 18G/3.5FR": "BD L-CATH 18G/3.5FR",
              "BD L-CATH 18G/3.5FRX60CM": "BD L-CATH 18G/3.5FRX60CM",
              "BD L-CATH 20G/2.6FR": "BD L-CATH 20G/2.6FR",
              "BD L-CATH 20G/2.6FRX60CM": "BD L-CATH 20G/2.6FRX60CM",
              "BD L-CATH 20G/3FR": "BD L-CATH 20G/3FR",
              "BD L-CATH 20G/3FRX60CM": "BD L-CATH 20G/3FRX60CM",
              "BD L-CATH 28G/1.2FRX25CM": "BD L-CATH 28G/1.2FRX25CM",
              "BD L-CATH MIDLINE 18G/3.5FRX20": "BD L-CATH MIDLINE 18G/3.5FRX20",
              "BD L-CATH MIDLINE 20G/3FRX20CM": "BD L-CATH MIDLINE 20G/3FRX20CM",
              "BD NEXIVA 18GX1.25\"\"": "BD NEXIVA 18GX1.25\"\"",
              "BD NEXIVA 18GX1.75\"\"": "BD NEXIVA 18GX1.75\"\"",
              "BD NEXIVA 20GX1\"\"": "BD NEXIVA 20GX1\"\"",
              "BD NEXIVA 20GX1.25\"\"": "BD NEXIVA 20GX1.25\"\"",
              "BD NEXIVA 20GX1.75\"\"": "BD NEXIVA 20GX1.75\"\"",
              "BD NEXIVA 22GX1\"\"": "BD NEXIVA 22GX1\"\"",
              "BD NEXIVA 24GX0.56\"\"": "BD NEXIVA 24GX0.56\"\"",
              "BD NEXIVA 24GX0.75\"\"": "BD NEXIVA 24GX0.75\"\"",
              "BD SAF-T-INTIMA 22G X 0.75\"\"": "BD SAF-T-INTIMA 22G X 0.75\"\"",
              "BD SAF-T-INTIMA 24G X 0.75\"\"": "BD SAF-T-INTIMA 24G X 0.75\"\"",
              "INSYTE AUTOGUARD 14GX1.75\"\"": "INSYTE AUTOGUARD 14GX1.75\"\"",
              "INSYTE AUTOGUARD 18GX1.16\"\"": "INSYTE AUTOGUARD 18GX1.16\"\"",
              "INSYTE AUTOGUARD 20GX1\"\"": "INSYTE AUTOGUARD 20GX1\"\"",
              "INSYTE AUTOGUARD 20GX1.16\"\"": "INSYTE AUTOGUARD 20GX1.16\"\"",
              "INSYTE AUTOGUARD 22GX1\"\"": "INSYTE AUTOGUARD 22GX1\"\"",
              "INSYTE AUTOGUARD 24GX3/4\"\"": "INSYTE AUTOGUARD 24GX3/4\"\""
            },
            "RETRACTABLE TECHNOLOGIES": {
              "VANISHPOINT 18GX1-1/4\"\" FEP": "VANISHPOINT 18GX1-1/4\"\" FEP",
              "VANISHPOINT 18GX1-1/4\"\" PUR": "VANISHPOINT 18GX1-1/4\"\" PUR",
              "VANISHPOINT 20GX1-1/4\"\" FEP": "VANISHPOINT 20GX1-1/4\"\" FEP",
              "VANISHPOINT 20GX1-1/4\"\" PUR": "VANISHPOINT 20GX1-1/4\"\" PUR",
              "VANISHPOINT 22GX1\"\" FEP": "VANISHPOINT 22GX1\"\" FEP",
              "VANISHPOINT 22GX1\"\" PUR": "VANISHPOINT 22GX1\"\" PUR",
              "VANISHPOINT 24GX3/4\"\" FEP": "VANISHPOINT 24GX3/4\"\" FEP",
              "VANISHPOINT 24GX3/4\"\" PUR": "VANISHPOINT 24GX3/4\"\" PUR"
            }
          },
          "Parenteral Therapy Supplies": {
            "A-S MEDICATION SOLUTIONS": {
              "WINTHROP CARPUJECT HOLDER": "WINTHROP CARPUJECT HOLDER"
            },
            "ABBVIE": {
              "LTXF 3-PORT ADAPTER/INJ SITE": "LTXF 3-PORT ADAPTER/INJ SITE"
            },
            "BD DIABETES CARE": {
              "BD LUER-LOK ACCESS DEVICE": "BD LUER-LOK ACCESS DEVICE",
              "BD Q-STYLE ACCESS DEVICE": "BD Q-STYLE ACCESS DEVICE",
              "BD Q-STYLE EXTENSION SET": "BD Q-STYLE EXTENSION SET",
              "BD Q-STYLE VIAL ADAPTER": "BD Q-STYLE VIAL ADAPTER"
            },
            "BD MEDICAL SURGICAL SYSTEMS": {
              "BD INTROSYTE INTRODUCER 16G": "BD INTROSYTE INTRODUCER 16G",
              "BD INTROSYTE INTRODUCER 18G": "BD INTROSYTE INTRODUCER 18G",
              "BD INTROSYTE INTRODUCER 20G": "BD INTROSYTE INTRODUCER 20G",
              "BD PUDENDAL/LOCAL BLOCK TRAY": "BD PUDENDAL/LOCAL BLOCK TRAY",
              "BD SYRINGE TIP CAP": "BD SYRINGE TIP CAP",
              "BLUNT PLASTIC CANNULA": "BLUNT PLASTIC CANNULA",
              "CANNULAE ABRAHAM FLEXIBLE": "CANNULAE ABRAHAM FLEXIBLE",
              "CATHETER ADAPTER": "CATHETER ADAPTER",
              "DURASAFE SPINAL/EPIDURAL": "DURASAFE SPINAL/EPIDURAL",
              "DURASAFE SPINAL/EPIDURAL SET": "DURASAFE SPINAL/EPIDURAL SET",
              "FEMALE CAP LUER-LOK": "FEMALE CAP LUER-LOK",
              "FEMALE LUER-LOK ADAPTER": "FEMALE LUER-LOK ADAPTER",
              "GUARDIAN CHEMO COLLECTOR": "GUARDIAN CHEMO COLLECTOR",
              "GUARDIAN OFFICE SAFETY": "GUARDIAN OFFICE SAFETY",
              "INTERLINK BLOOD COLLECT ASSEMB": "INTERLINK BLOOD COLLECT ASSEMB",
              "INTERLINK LEVER LOCK CANNULA": "INTERLINK LEVER LOCK CANNULA",
              "INTERLINK SYRINGE CANNULA": "INTERLINK SYRINGE CANNULA",
              "INTERLINK THREADED LOCK CANNUL": "INTERLINK THREADED LOCK CANNUL",
              "INTERLINK VIAL ACCESS CANNULA": "INTERLINK VIAL ACCESS CANNULA",
              "INTERLINK Y-LOCK CANNULA": "INTERLINK Y-LOCK CANNULA",
              "L/10 ADAPTER": "L/10 ADAPTER",
              "LABORATORY CANNULA 14GX4\"\"": "LABORATORY CANNULA 14GX4\"\"",
              "LABORATORY CANNULA 23GX2\"\"": "LABORATORY CANNULA 23GX2\"\"",
              "LACRIMAL CANNULAE 23GX1/2\"\"": "LACRIMAL CANNULAE 23GX1/2\"\"",
              "LACRIMAL CANNULAE 23GX5/8\"\"": "LACRIMAL CANNULAE 23GX5/8\"\"",
              "LL/LL ADAPTER": "LL/LL ADAPTER",
              "LL/MLL ADAPTER FEMALE TO MALE": "LL/MLL ADAPTER FEMALE TO MALE",
              "LL/SL ADAPTER": "LL/SL ADAPTER",
              "LONGDWEL TW CATH NDL 15GX2-1/2": "LONGDWEL TW CATH NDL 15GX2-1/2",
              "LONGDWEL TW CATH NDL 16GX2-1/2": "LONGDWEL TW CATH NDL 16GX2-1/2",
              "LONGDWEL TW CATH NDL 16GX8\"\"": "LONGDWEL TW CATH NDL 16GX8\"\"",
              "LONGDWEL TW CATH NDL 18GX2-1/2": "LONGDWEL TW CATH NDL 18GX2-1/2",
              "LONGDWEL TW CATH NDL 18GX4\"\"": "LONGDWEL TW CATH NDL 18GX4\"\"",
              "LONGDWEL TW CATH NDL 18GX6\"\"": "LONGDWEL TW CATH NDL 18GX6\"\"",
              "LONGDWEL TW CATH NDL 18GX8\"\"": "LONGDWEL TW CATH NDL 18GX8\"\"",
              "LONGDWEL TW CATH NDL 20GX4\"\"": "LONGDWEL TW CATH NDL 20GX4\"\"",
              "LONGDWEL TW CATH NDL 20GX8\"\"": "LONGDWEL TW CATH NDL 20GX8\"\"",
              "MALE CAP LUER-LOK": "MALE CAP LUER-LOK",
              "MALE LUER-LOK ADAPTER": "MALE LUER-LOK ADAPTER",
              "ML/ML ADAPTER": "ML/ML ADAPTER",
              "MLL/MLL ADAPTER": "MLL/MLL ADAPTER",
              "MS01 SPRING CLIP": "MS01 SPRING CLIP",
              "MS01-T SCREW TYPE": "MS01-T SCREW TYPE",
              "MS01-T1 SCREW TYPE": "MS01-T1 SCREW TYPE",
              "MS02 SPRING CLIP AYER STOPCOCK": "MS02 SPRING CLIP AYER STOPCOCK",
              "MS02-T SCREW TYPE AYER STOPCOC": "MS02-T SCREW TYPE AYER STOPCOC",
              "MS08 SPRING CLIP": "MS08 SPRING CLIP",
              "MS09 SPRING CLIP": "MS09 SPRING CLIP",
              "MS09-T SCREW TYPE": "MS09-T SCREW TYPE",
              "MS09-T1 SCREW TYPE": "MS09-T1 SCREW TYPE",
              "MS10 SPRING CLIP": "MS10 SPRING CLIP",
              "MS10-T SCREW TYPE": "MS10-T SCREW TYPE",
              "MS10-T1 SCREW TYPE": "MS10-T1 SCREW TYPE",
              "MS10-T2 SCREW TYPE": "MS10-T2 SCREW TYPE",
              "MS11 SPRING CLIP": "MS11 SPRING CLIP",
              "NEEDLE TUBING ADAPTER 14GX1/2\"\"": "NEEDLE TUBING ADAPTER 14GX1/2\"\"",
              "NEEDLE TUBING ADAPTER 15GX1/2\"\"": "NEEDLE TUBING ADAPTER 15GX1/2\"\"",
              "NEEDLE TUBING ADAPTER 16GX1/2\"\"": "NEEDLE TUBING ADAPTER 16GX1/2\"\"",
              "NEEDLE TUBING ADAPTER 17GX1/2\"\"": "NEEDLE TUBING ADAPTER 17GX1/2\"\"",
              "NEEDLE TUBING ADAPTER 18GX1/2\"\"": "NEEDLE TUBING ADAPTER 18GX1/2\"\"",
              "NEEDLE TUBING ADAPTER 19GX1/2\"\"": "NEEDLE TUBING ADAPTER 19GX1/2\"\"",
              "NEEDLE TUBING ADAPTER 20GX1/2\"\"": "NEEDLE TUBING ADAPTER 20GX1/2\"\"",
              "NEEDLE TUBING ADAPTER 22GX1/2\"\"": "NEEDLE TUBING ADAPTER 22GX1/2\"\"",
              "NEEDLE TUBING ADAPTER 23GX1/2\"\"": "NEEDLE TUBING ADAPTER 23GX1/2\"\"",
              "NEEDLE TUBING ADAPTER 24GX1/2\"\"": "NEEDLE TUBING ADAPTER 24GX1/2\"\"",
              "PERISAFE EPIDURAL CATH 19GX36": "PERISAFE EPIDURAL CATH 19GX36",
              "PERISAFE EPIDURAL CATH 20GX36": "PERISAFE EPIDURAL CATH 20GX36",
              "PERISAFE EPIDURAL MINI-KIT": "PERISAFE EPIDURAL MINI-KIT",
              "PHASEAL ASSEMBLY FIXTURE": "PHASEAL ASSEMBLY FIXTURE",
              "PHASEAL CONNECTOR LUER LOCK": "PHASEAL CONNECTOR LUER LOCK",
              "PHASEAL PROTECTOR 14": "PHASEAL PROTECTOR 14",
              "PHASEAL PROTECTOR 21": "PHASEAL PROTECTOR 21",
              "PHASEAL SECONDARY SET": "PHASEAL SECONDARY SET",
              "S/D FEMALE L/L CAP FEMALE CONN": "S/D FEMALE L/L CAP FEMALE CONN",
              "S/D MALE L/L CAP MALE CONNECT": "S/D MALE L/L CAP MALE CONNECT",
              "SAFETYCONE ABG NEEDLE ENCAPSUL": "SAFETYCONE ABG NEEDLE ENCAPSUL",
              "SPINAL INTRODUCER 18GX1-1/4\"\"": "SPINAL INTRODUCER 18GX1-1/4\"\"",
              "SPINAL INTRODUCER 20GX1-1/4\"\"": "SPINAL INTRODUCER 20GX1-1/4\"\"",
              "SPINAL INTRODUCER 22GX1-1/4\"\"": "SPINAL INTRODUCER 22GX1-1/4\"\"",
              "SPRING CLIP": "SPRING CLIP",
              "SURE-MED DISP PIN .22 MICRON": "SURE-MED DISP PIN .22 MICRON",
              "SURE-MED DISP PIN .45 MICRON": "SURE-MED DISP PIN .45 MICRON",
              "SYRINGE ACCESS POMEROY EAR TIP": "SYRINGE ACCESS POMEROY EAR TIP",
              "SYRINGE ACCESSORY FISTULA TIP": "SYRINGE ACCESSORY FISTULA TIP",
              "SYRINGE ADAPTER CATHETER TIP": "SYRINGE ADAPTER CATHETER TIP",
              "SYRINGE LUER TIP CAP": "SYRINGE LUER TIP CAP",
              "SYRINGE STORAGE BIN": "SYRINGE STORAGE BIN",
              "SYRINGE TIP CONNECTOR": "SYRINGE TIP CONNECTOR"
            },
            "CARMEL PHARMA": {
              "PHASEAL ASSEMBLY FIXTURE": "PHASEAL ASSEMBLY FIXTURE",
              "PHASEAL CAP FOR INJECTOR": "PHASEAL CAP FOR INJECTOR",
              "PHASEAL CONNECTOR LUER LOCK": "PHASEAL CONNECTOR LUER LOCK",
              "PHASEAL INFUSION ADAPTER": "PHASEAL INFUSION ADAPTER",
              "PHASEAL INFUSION CLAMP": "PHASEAL INFUSION CLAMP",
              "PHASEAL INJECTOR LUER LOCK": "PHASEAL INJECTOR LUER LOCK",
              "PHASEAL IV BAG HANGER": "PHASEAL IV BAG HANGER",
              "PHASEAL PROTECTOR 14": "PHASEAL PROTECTOR 14",
              "PHASEAL PROTECTOR 21": "PHASEAL PROTECTOR 21",
              "PHASEAL PROTECTOR 28": "PHASEAL PROTECTOR 28",
              "PHASEAL PROTECTOR 50": "PHASEAL PROTECTOR 50",
              "PHASEAL SECONDARY SET": "PHASEAL SECONDARY SET",
              "PHASEAL SYRINGE TRAY": "PHASEAL SYRINGE TRAY",
              "PHASEAL Y-SITE CONNECTOR": "PHASEAL Y-SITE CONNECTOR"
            },
            "COMPASS HEALTH BRANDS": {
              "IV POLE": "IV POLE"
            },
            "COVIDIEN MEDICAL SUPPLIES": {
              "ARGYLE EZ-FLO STOPCOCK": "ARGYLE EZ-FLO STOPCOCK",
              "ARGYLE THI AORTIC CANNULA": "ARGYLE THI AORTIC CANNULA",
              "CURITY LUMBAR PUNCTURE TRAY": "CURITY LUMBAR PUNCTURE TRAY",
              "CURITY THORACENTESIS TRAY": "CURITY THORACENTESIS TRAY",
              "IN-ROOM CONTAINER MAILBOX": "IN-ROOM CONTAINER MAILBOX",
              "IV ACCESS CANNULA": "IV ACCESS CANNULA",
              "KENDALL LUER DISINFECTANT CAP": "KENDALL LUER DISINFECTANT CAP",
              "LL SYRINGE/IV ACCESS CANNULA": "LL SYRINGE/IV ACCESS CANNULA",
              "MONOJECT BLOOD NEEDLE HOLDER": "MONOJECT BLOOD NEEDLE HOLDER",
              "MONOJECT BLUNT TIP SYRINGE": "MONOJECT BLUNT TIP SYRINGE",
              "MONOJECT BLUNTIP CANNULA": "MONOJECT BLUNTIP CANNULA",
              "MONOJECT FILTER CONNECTOR": "MONOJECT FILTER CONNECTOR",
              "MONOJECT FINGER GRIP EXTENDERS": "MONOJECT FINGER GRIP EXTENDERS",
              "MONOJECT LIFESHIELD CANNULA": "MONOJECT LIFESHIELD CANNULA",
              "MONOJECT MED PREP CANNULA": "MONOJECT MED PREP CANNULA",
              "MONOJECT SAFETY SYR TIP CAPS": "MONOJECT SAFETY SYR TIP CAPS",
              "MONOJECT SMARTIP SYR/CANNULA": "MONOJECT SMARTIP SYR/CANNULA",
              "MONOJECT SYRINGE TIP CAPS": "MONOJECT SYRINGE TIP CAPS",
              "MONOJECT TIP CAPS": "MONOJECT TIP CAPS",
              "MONOJECT VIAL ACCESS CANNULA": "MONOJECT VIAL ACCESS CANNULA",
              "SHARPS-A-GATOR GLOVE BOX HOLDR": "SHARPS-A-GATOR GLOVE BOX HOLDR",
              "SHARPSAFETY DOOR/WALL ENCLOSUR": "SHARPSAFETY DOOR/WALL ENCLOSUR",
              "SHARPSAFETY GLOVE BOX DISPENSR": "SHARPSAFETY GLOVE BOX DISPENSR",
              "SHARPSAFETY GLOVE BOX/BRACKET": "SHARPSAFETY GLOVE BOX/BRACKET",
              "SHARPSAFETY IN ROOM SHARPS CON": "SHARPSAFETY IN ROOM SHARPS CON",
              "SHARPSAFETY PHLEBOTOMY SHARPS": "SHARPSAFETY PHLEBOTOMY SHARPS",
              "SHARPSAFETY SAFETY IN ROOM": "SHARPSAFETY SAFETY IN ROOM",
              "SHARPSAFETY SHARPS CONTAINER": "SHARPSAFETY SHARPS CONTAINER",
              "SHARPSAFETY WALL ENCLOSURE": "SHARPSAFETY WALL ENCLOSURE",
              "SOLUTION-PLUS STOPCOCK": "SOLUTION-PLUS STOPCOCK"
            },
            "DYNAREX CORPORATION": {
              "NEEDLELESS PRN CONNECTORS": "NEEDLELESS PRN CONNECTORS",
              "NEEDLELESS PRN PORT CONVERTER": "NEEDLELESS PRN PORT CONVERTER"
            },
            "EXEL INTERNATIONAL": {
              "AV FISTULA": "AV FISTULA",
              "LUER HOLDER": "LUER HOLDER"
            },
            "FRESENIUS KABI USA": {
              "EMPTY STERILIZED": "EMPTY STERILIZED",
              "HI-VOLUME PUMPING CHAMBER SET": "HI-VOLUME PUMPING CHAMBER SET",
              "IV TRANSFER SPIKE": "IV TRANSFER SPIKE",
              "STERI-TAMP SEALS BOTTLES/VIALS": "STERI-TAMP SEALS BOTTLES/VIALS",
              "STERI-TAMP SEALS/BAGS": "STERI-TAMP SEALS/BAGS",
              "STERI-TAMP SEALS/SYRINGES": "STERI-TAMP SEALS/SYRINGES",
              "UNIVERSAL SYRINGE TIP ADAPTOR": "UNIVERSAL SYRINGE TIP ADAPTOR"
            },
            "HOSPIRA": {
              "EMPTY 3-IN-1 MIXING CONTAINER": "EMPTY 3-IN-1 MIXING CONTAINER",
              "NUTRI-CLAMP/EMPTY LIFECARE": "NUTRI-CLAMP/EMPTY LIFECARE",
              "WINTHROP CARPUJECT HOLDER": "WINTHROP CARPUJECT HOLDER"
            },
            "MEDISCA": {
              "CLEAR GLASS VIALS 5ML": "CLEAR GLASS VIALS 5ML",
              "MONOJECT HYPODERMIC NEEDLE TIP": "MONOJECT HYPODERMIC NEEDLE TIP",
              "STERI-TAMP SEALS 13MM": "STERI-TAMP SEALS 13MM",
              "STERI-TAMP SEALS 20MM": "STERI-TAMP SEALS 20MM",
              "STERI-TAMP SEALS 28MM": "STERI-TAMP SEALS 28MM",
              "SYRINGE FILTER 0.2 MICRON/25MM": "SYRINGE FILTER 0.2 MICRON/25MM",
              "SYRINGE FILTER 0.2 MICRON/32MM": "SYRINGE FILTER 0.2 MICRON/32MM",
              "SYRINGE FILTER 0.45 MICRON": "SYRINGE FILTER 0.45 MICRON",
              "SYRINGE FILTER/0.2 MICRON/25MM": "SYRINGE FILTER/0.2 MICRON/25MM",
              "SYRINGE FILTER/0.2 MICRON/30MM": "SYRINGE FILTER/0.2 MICRON/30MM",
              "SYRINGE FILTER/MILLEX-GS/25MM": "SYRINGE FILTER/MILLEX-GS/25MM",
              "SYRINGE FILTER/MILLEX-GV/33MM": "SYRINGE FILTER/MILLEX-GV/33MM",
              "SYRINGE FILTER/MILLEX/25MM": "SYRINGE FILTER/MILLEX/25MM",
              "VACUUM FILTER 0.20UM/150ML": "VACUUM FILTER 0.20UM/150ML",
              "VIAL STOPPER": "VIAL STOPPER"
            },
            "MEDTRONIC MINIMED": {
              "I-PORT ADVANCE 6MM": "I-PORT ADVANCE 6MM",
              "I-PORT ADVANCE 9MM": "I-PORT ADVANCE 9MM",
              "SPORTSGUARD": "SPORTSGUARD"
            },
            "NATIONAL MEDICAL PRODUCTS": {
              "J-TIP NEEDLE/FREE INJECTOR": "J-TIP NEEDLE/FREE INJECTOR",
              "SMALL VIAL LUER ADAPTER": "SMALL VIAL LUER ADAPTER",
              "UNIVERSAL VIAL LUER ADAPTER": "UNIVERSAL VIAL LUER ADAPTER"
            },
            "NSPIRE HEALTH": {
              "KOKO PEAK FILTER ADAPTER": "KOKO PEAK FILTER ADAPTER",
              "KOKO PEAK PRO 6 MONITOR": "KOKO PEAK PRO 6 MONITOR",
              "KOKO PEAK PRO 6 SOFTWARE-CRADL": "KOKO PEAK PRO 6 SOFTWARE-CRADL"
            },
            "PATTON MEDICAL DEVICES": {
              "I-PORT 6MM": "I-PORT 6MM",
              "I-PORT 9MM": "I-PORT 9MM"
            },
            "PCCA": {
              "ALUMINUM BOTTLE SEAL": "ALUMINUM BOTTLE SEAL",
              "MILLEX-FG FILTER/TEFLON": "MILLEX-FG FILTER/TEFLON",
              "MILLEX-OR FILTER": "MILLEX-OR FILTER",
              "MIXING JAR/SPINDLE CAP/300GM": "MIXING JAR/SPINDLE CAP/300GM",
              "SYRINGE CAPS": "SYRINGE CAPS"
            },
            "ROCHE INSULIN DELIVERY SYSTEMS": {
              "FILLING AID": "FILLING AID",
              "PLASTIC CARTRIDGE 10 ML": "PLASTIC CARTRIDGE 10 ML"
            }
          },
          "Subcutaneous Administration Supplies": {
            "INTRA PUMP INFUSION SYSTEMS": {
              "INSUFLON": "INSUFLON"
            }
          }
        },
        "Respiratory Aids": {
          "Air Cleaners": {
            "KAZ": {
              "KAZ REPLACEMENT FILTER": "KAZ REPLACEMENT FILTER",
              "VICKS AIR PURIFIER/HEPA": "VICKS AIR PURIFIER/HEPA"
            },
            "SUNBEAM": {
              "SUNBEAM REPLACEMENT FILTER": "SUNBEAM REPLACEMENT FILTER",
              "SUNBEAM TABLETOP AIR CLEANER": "SUNBEAM TABLETOP AIR CLEANER"
            }
          },
          "Masks": {
            "3M CONSUMER HEALTH CARE": {
              "NEXCARE ALL PURPOSE MASK": "NEXCARE ALL PURPOSE MASK",
              "NEXCARE EARLOOP MASK": "NEXCARE EARLOOP MASK"
            },
            "AKRON PHARMA": {
              "DISPOSABLE FACE MASK 3-PLY": "DISPOSABLE FACE MASK 3-PLY",
              "KN95 MEDICAL PROTECTIVE MASK": "KN95 MEDICAL PROTECTIVE MASK"
            },
            "APOTHECARY PRODUCTS, INC.": {
              "CPR MICROSHIELD": "CPR MICROSHIELD",
              "LIGHT SHIELD": "LIGHT SHIELD",
              "LIGHT SHIELD DELUXE SLEEP MASK": "LIGHT SHIELD DELUXE SLEEP MASK",
              "MAXI-MASK": "MAXI-MASK",
              "SIESTA MASK": "SIESTA MASK"
            },
            "AUM PHARMACEUTICALS": {
              "ACTEEV PROTECT FACE MASK": "ACTEEV PROTECT FACE MASK",
              "FACE MASK": "FACE MASK",
              "KN95 MEDICAL PROTECTIVE MASK": "KN95 MEDICAL PROTECTIVE MASK"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS PROCEDURAL MASK": "CVS PROCEDURAL MASK"
            },
            "HOME AIDE DIAGNOSTICS": {
              "FACE MASK": "FACE MASK",
              "N95 FACE MASK": "N95 FACE MASK"
            },
            "HUDSON SCIENTIFIC": {
              "DISPOSABLE FACE MASK": "DISPOSABLE FACE MASK",
              "DISPOSABLE FACE MASK 3-PLY": "DISPOSABLE FACE MASK 3-PLY",
              "KN95 DISPOSABLE MASK": "KN95 DISPOSABLE MASK"
            },
            "J & J MEDICAL": {
              "J & J GERM FILTER MASK": "J & J GERM FILTER MASK"
            },
            "KITSCH": {
              "FACE MASKS 3 LAYER NON-MEDICAL": "FACE MASKS 3 LAYER NON-MEDICAL"
            },
            "LEADER BRAND PRODUCTS": {
              "N95 MASKS": "N95 MASKS"
            },
            "M.C. JOHNSON COMPANY": {
              "SHIELD-SECURE FULL FACE SHIELD": "SHIELD-SECURE FULL FACE SHIELD"
            },
            "MEDISCA": {
              "FACE MASK EARLOOP-STYLE": "FACE MASK EARLOOP-STYLE",
              "FACE MASK RESP N-100 PART": "FACE MASK RESP N-100 PART",
              "FACE MASK RESPIRATOR R-95 PART": "FACE MASK RESPIRATOR R-95 PART"
            },
            "PARI RESPIRATORY": {
              "MASK PEDIATRIC SIZE 1\"\"": "MASK PEDIATRIC SIZE 1\"\"",
              "MASK PEDIATRIC SIZE 3\"\"": "MASK PEDIATRIC SIZE 3\"\""
            },
            "RESPIRONICS": {
              "PEDIATRIC MEDIUM MASK": "PEDIATRIC MEDIUM MASK",
              "PEDIATRIC SMALL MASK": "PEDIATRIC SMALL MASK"
            },
            "RITE AID CORPORATION": {
              "RA EAR-LOOP MASK": "RA EAR-LOOP MASK",
              "RA SIESTA MASK": "RA SIESTA MASK"
            },
            "SDI USA": {
              "CLEVER CHOICE FACE MASK": "CLEVER CHOICE FACE MASK"
            },
            "SIMPLE DIAGNOSTICS": {
              "CLEVER CHOICE DISPOSABLE MASK": "CLEVER CHOICE DISPOSABLE MASK"
            },
            "WALGREENS": {
              "EAR-LOOP MASK SMALL": "EAR-LOOP MASK SMALL",
              "FACE MASK EARLOOP-STYLE": "FACE MASK EARLOOP-STYLE"
            }
          }
        },
        "Respiratory Therapy Supplies": {
          "Humidifiers": {
            "ADVANTICE HEALTH": {
              "PEDIACARE GENTLE VAPORS REFILL": "PEDIACARE GENTLE VAPORS REFILL"
            },
            "ARISE MEDICAL": {
              "PROCARE HUMIDIFIER": "PROCARE HUMIDIFIER"
            },
            "CHAIN DRUG CONSORTIUM": {
              "COOL MIST HUMIDIFIER": "COOL MIST HUMIDIFIER",
              "VAPORIZER REFILL": "VAPORIZER REFILL",
              "VAPORIZER REFILL CHILDRENS": "VAPORIZER REFILL CHILDRENS"
            },
            "COLOPLAST": {
              "WHIRL-SOL": "WHIRL-SOL"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS COOL MIST HUMIDIFER": "CVS COOL MIST HUMIDIFER"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "FLOWING VAPORS": "FLOWING VAPORS",
              "FLOWING VAPORS/VAPOR FAN": "FLOWING VAPORS/VAPOR FAN"
            },
            "GORDON": {
              "GORDO-POOL": "GORDO-POOL"
            },
            "HOME AIDE DIAGNOSTICS": {
              "PURE COMFORT HUMIDIFIER": "PURE COMFORT HUMIDIFIER"
            },
            "HUDSON SCIENTIFIC": {
              "BREATHE EASE HUMIDIFIER": "BREATHE EASE HUMIDIFIER"
            },
            "KAZ": {
              "HUMIDIFIER": "HUMIDIFIER",
              "KAZ AROMATIC INHALANT": "KAZ AROMATIC INHALANT",
              "KAZ BACTERIOSTATIC TREATMENT": "KAZ BACTERIOSTATIC TREATMENT",
              "KAZ DEMINERALIZATION CARTRIDGE": "KAZ DEMINERALIZATION CARTRIDGE",
              "KAZ DYNAFILTER K14-3P": "KAZ DYNAFILTER K14-3P",
              "KAZ DYNAFILTER K14-S": "KAZ DYNAFILTER K14-S",
              "KAZ HEALTHMIST HUMIDIFIER": "KAZ HEALTHMIST HUMIDIFIER",
              "KAZ HUMIDIFIER EVAPORATIV 3000": "KAZ HUMIDIFIER EVAPORATIV 3000",
              "KAZ HUMIDIFIER EVAPORATIV 3300": "KAZ HUMIDIFIER EVAPORATIV 3300",
              "KAZ HUMIDIFIER EVAPORATIV 3400": "KAZ HUMIDIFIER EVAPORATIV 3400",
              "KAZ INHALANT": "KAZ INHALANT",
              "KAZ ULTRASONIC HUMIDIFIER": "KAZ ULTRASONIC HUMIDIFIER",
              "KAZ WATER TREATMENT": "KAZ WATER TREATMENT",
              "KAZ WICKING FILTER WF1": "KAZ WICKING FILTER WF1",
              "NATURAL MIST WICKING FILTER": "NATURAL MIST WICKING FILTER",
              "ULTRASONIC HUMIDIFIER": "ULTRASONIC HUMIDIFIER",
              "VICKS COOL MIST HUMIDIFIER": "VICKS COOL MIST HUMIDIFIER",
              "VICKS GERMFREE HUMIDIFIER": "VICKS GERMFREE HUMIDIFIER",
              "VICKS HEALTHCHECK MONITOR": "VICKS HEALTHCHECK MONITOR",
              "VICKS HUMIDIFIER": "VICKS HUMIDIFIER",
              "VICKS MINI COOLMIST HUMIDIFIER": "VICKS MINI COOLMIST HUMIDIFIER",
              "VICKS PUREMIST HUMIDIFIER": "VICKS PUREMIST HUMIDIFIER",
              "VICKS ULTRASONIC HUMIDIFIER": "VICKS ULTRASONIC HUMIDIFIER",
              "VICKS VAPOPADS PEDIATRIC": "VICKS VAPOPADS PEDIATRIC",
              "VICKS VAPORIZER SCENT": "VICKS VAPORIZER SCENT",
              "VICKS WARM MIST HUMIDIFIER": "VICKS WARM MIST HUMIDIFIER"
            },
            "LEADER BRAND PRODUCTS": {
              "COOL MIST HUMIDIFIER": "COOL MIST HUMIDIFIER"
            },
            "MCKESSON HOME HEALTH CARE": {
              "CHARCOAL FILTER": "CHARCOAL FILTER",
              "COOL MIST HUMIDIFIER 1 GALLON": "COOL MIST HUMIDIFIER 1 GALLON",
              "COOL MIST HUMIDIFIER 2 GALLON": "COOL MIST HUMIDIFIER 2 GALLON",
              "HUMIDIFIER": "HUMIDIFIER",
              "SM HUMIDIFIER/COOL MIST": "SM HUMIDIFIER/COOL MIST",
              "SM VAPORIZER CLEANING": "SM VAPORIZER CLEANING",
              "SM VAPORIZER INHALANT": "SM VAPORIZER INHALANT",
              "ULTRASONIC FILTER": "ULTRASONIC FILTER",
              "WICKING FILTER": "WICKING FILTER"
            },
            "SDI USA": {
              "CLEVER CHOICE HUMIDIFIER": "CLEVER CHOICE HUMIDIFIER"
            },
            "SUNBEAM": {
              "CLEAN START VAPORIZER": "CLEAN START VAPORIZER",
              "HANKSCRAFT HUMIDIFIER": "HANKSCRAFT HUMIDIFIER",
              "HANKSCRAFT REPLACEMENT FILTER": "HANKSCRAFT REPLACEMENT FILTER",
              "HANKSCRAFT VAPORIZER INHALANT": "HANKSCRAFT VAPORIZER INHALANT",
              "SUNBEAM BACTERIASTAT": "SUNBEAM BACTERIASTAT",
              "SUNBEAM COOL SPRAY HUMIDIFIER": "SUNBEAM COOL SPRAY HUMIDIFIER",
              "SUNBEAM EVAP HUMIDIFIER": "SUNBEAM EVAP HUMIDIFIER",
              "SUNBEAM HUMID/VAPOR CLEANER": "SUNBEAM HUMID/VAPOR CLEANER",
              "SUNBEAM HUMIDIFIER": "SUNBEAM HUMIDIFIER",
              "SUNBEAM REPLACEMENT AIR FILTER": "SUNBEAM REPLACEMENT AIR FILTER",
              "SUNBEAM REPLACEMENT WICK": "SUNBEAM REPLACEMENT WICK",
              "SUNBEAM ULTRA-SONIC HUMIDIFIER": "SUNBEAM ULTRA-SONIC HUMIDIFIER",
              "SUNBEAM VAPORIZER INHALANT": "SUNBEAM VAPORIZER INHALANT",
              "WATER FILTER CARTRIDGE #694": "WATER FILTER CARTRIDGE #694"
            },
            "WALGREENS": {
              "COOL MIST HUMIDIFIER": "COOL MIST HUMIDIFIER",
              "COOL MIST HUMIDIFIER 0.8 GAL": "COOL MIST HUMIDIFIER 0.8 GAL",
              "COOL MIST HUMIDIFIER 1.2 GAL": "COOL MIST HUMIDIFIER 1.2 GAL",
              "COOL MIST HUMIDIFIER 1.3 GAL": "COOL MIST HUMIDIFIER 1.3 GAL",
              "DUAL ULTRASONIC HUMIDIFIER": "DUAL ULTRASONIC HUMIDIFIER",
              "HUMIDIFIER FILTER REPLACEMENT": "HUMIDIFIER FILTER REPLACEMENT",
              "PERSONAL ULTRASONIC HUMIDIFIER": "PERSONAL ULTRASONIC HUMIDIFIER",
              "ULTRASONIC HUMIDIFIER": "ULTRASONIC HUMIDIFIER"
            }
          },
          "Nasal Nebulizers": {
            "HOME AIDE DIAGNOSTICS": {
              "EASY AIR MINI SINUS IRRIG SYS": "EASY AIR MINI SINUS IRRIG SYS"
            },
            "NASONEB": {
              "NASONEB NEBULIZER REPLACEMENT": "NASONEB NEBULIZER REPLACEMENT",
              "NASONEB NEBULIZER STARTER": "NASONEB NEBULIZER STARTER",
              "NASONEB SINUS THERAPY SYSTEM": "NASONEB SINUS THERAPY SYSTEM",
              "NASONEB STS SUPPLY KIT": "NASONEB STS SUPPLY KIT"
            },
            "PARI RESPIRATORY": {
              "PARI SINUSTAR DELIVERY SYSTEM": "PARI SINUSTAR DELIVERY SYSTEM",
              "PARI SINUSTAR NASAL NEBULIZER": "PARI SINUSTAR NASAL NEBULIZER"
            }
          },
          "Nebulizers": {
            "AKRON PHARMA": {
              "AERIVA CONCENTRATOR NEBULIZER": "AERIVA CONCENTRATOR NEBULIZER"
            },
            "ALC ENTERPRISES": {
              "AURA PORTANEB": "AURA PORTANEB"
            },
            "ARISE MEDICAL": {
              "BENTLEY THE BEAR PED NEBULIZER": "BENTLEY THE BEAR PED NEBULIZER",
              "CAPTAIN EAGLE PED NEBULIZER": "CAPTAIN EAGLE PED NEBULIZER",
              "PROCARE COMPRESSOR NEBULIZER": "PROCARE COMPRESSOR NEBULIZER",
              "SPARKY THE DOG PED NEBULIZER": "SPARKY THE DOG PED NEBULIZER"
            },
            "BESTMED": {
              "BESTMED COMPRESSOR NEBULIZER": "BESTMED COMPRESSOR NEBULIZER",
              "BESTMED ULTRASONIC NEBULIZER": "BESTMED ULTRASONIC NEBULIZER"
            },
            "BESTMED, LLC": {
              "VERSA-NEB COMPRESSOR/NEBULIZER": "VERSA-NEB COMPRESSOR/NEBULIZER"
            },
            "BLAIREX LABS": {
              "PARI BABY": "PARI BABY",
              "PARI LC PLUS": "PARI LC PLUS",
              "PARI LC STAR": "PARI LC STAR"
            },
            "CHAIN DRUG CONSORTIUM": {
              "COMPMIST COMPRESSOR NEBULIZER": "COMPMIST COMPRESSOR NEBULIZER",
              "MINICOMP COMPRESSOR NEBULIZER": "MINICOMP COMPRESSOR NEBULIZER"
            },
            "COMPASS HEALTH BRANDS": {
              "NEB-RITE4": "NEB-RITE4",
              "NEBULIZER PED FROG": "NEBULIZER PED FROG",
              "NEBULIZER PED FROG KIT": "NEBULIZER PED FROG KIT"
            },
            "CONVEXITY SCIENTIFIC": {
              "FLYP NEBULIZER": "FLYP NEBULIZER"
            },
            "DEVILBISS HEALTHCARE": {
              "DEVILBISS DISPOSABLE NEBULIZER": "DEVILBISS DISPOSABLE NEBULIZER",
              "DEVILBISS GLASS NEBULIZER": "DEVILBISS GLASS NEBULIZER",
              "DEVILBISS NEBULIZER": "DEVILBISS NEBULIZER",
              "DEVILBISS PULMO-AIDE": "DEVILBISS PULMO-AIDE",
              "DEVILBISS REUSABLE NEBULIZER": "DEVILBISS REUSABLE NEBULIZER",
              "DEVILBISS TRAVELER NEBULIZER": "DEVILBISS TRAVELER NEBULIZER",
              "LEXAN POCKET NEBULIZER": "LEXAN POCKET NEBULIZER",
              "NEBULIZER COMPRESSOR": "NEBULIZER COMPRESSOR",
              "PULMOMATE COMP/NEB DISPOSABLE": "PULMOMATE COMP/NEB DISPOSABLE",
              "PULMONEB COMPACT COMP/NEB SYS": "PULMONEB COMPACT COMP/NEB SYS",
              "VIXONE DISPOSABLE NEBULIZER": "VIXONE DISPOSABLE NEBULIZER"
            },
            "DYNAREX CORPORATION": {
              "ELITE COMPRESSOR NEBULIZER": "ELITE COMPRESSOR NEBULIZER",
              "PORTABLE COMPRESSOR NEBULIZER": "PORTABLE COMPRESSOR NEBULIZER"
            },
            "FIRST CHOICE": {
              "MINI COMPRESSOR": "MINI COMPRESSOR"
            },
            "FORA CARE": {
              "SOOTHE NEB MESH NEBULIZER": "SOOTHE NEB MESH NEBULIZER",
              "SOOTHENEB COMPRESSOR NEBULIZER": "SOOTHENEB COMPRESSOR NEBULIZER"
            },
            "HOME AIDE DIAGNOSTICS": {
              "EASY AIR COMPRESSOR NEBULIZER": "EASY AIR COMPRESSOR NEBULIZER",
              "EASY NEB": "EASY NEB",
              "PURE AIR MINI NEBULIZER": "PURE AIR MINI NEBULIZER"
            },
            "LEADER BRAND PRODUCTS": {
              "ALL-IN-ONE NEBULIZER SYSTEM": "ALL-IN-ONE NEBULIZER SYSTEM",
              "NEBULIZER SYSTEM ALL-IN-ONE": "NEBULIZER SYSTEM ALL-IN-ONE",
              "PULMONEB LT": "PULMONEB LT"
            },
            "LUMISCOPE": {
              "LUMINEB II PISTON NEBULIZER": "LUMINEB II PISTON NEBULIZER",
              "NEBULIZER": "NEBULIZER"
            },
            "MABIS HEALTHCARE": {
              "COMPMIST COMPRESSOR NEBULIZER": "COMPMIST COMPRESSOR NEBULIZER",
              "MABIS COMPXP NEBULIZER": "MABIS COMPXP NEBULIZER",
              "MABIS COSMOCOMP NEBULIZER": "MABIS COSMOCOMP NEBULIZER",
              "MARGO MOO COMPRESSOR NEBULIZER": "MARGO MOO COMPRESSOR NEBULIZER",
              "MINIBREEZE ULTRASONIC NEBULIZE": "MINIBREEZE ULTRASONIC NEBULIZE"
            },
            "MCKESSON HOME HEALTH CARE": {
              "COMPRESSOR/NEBULIZER": "COMPRESSOR/NEBULIZER"
            },
            "MEDICAL DEVELOPMENTS INTERNATI": {
              "MY MDI PORTABLE NEBULISER": "MY MDI PORTABLE NEBULISER"
            },
            "MEDQUIP": {
              "AIRIAL COMPACT COMPRESSOR NEB": "AIRIAL COMPACT COMPRESSOR NEB",
              "AIRIAL COMPACT MINI NEBULIZER": "AIRIAL COMPACT MINI NEBULIZER",
              "AIRIAL COMPRESS PED NEBULIZER": "AIRIAL COMPRESS PED NEBULIZER",
              "AIRIAL PEDIATRIC NEBULIZER": "AIRIAL PEDIATRIC NEBULIZER",
              "AIRIAL VOYAGER NEBULIZER": "AIRIAL VOYAGER NEBULIZER"
            },
            "MONAGHAN MEDICAL CORPORATION": {
              "AEROECLIPSE II NEBULIZER": "AEROECLIPSE II NEBULIZER"
            },
            "OMRON MANAGED HEALTHCARE": {
              "AIRS DISPOSABLE NEBULIZER": "AIRS DISPOSABLE NEBULIZER",
              "COMP A-I-R NEBULIZER": "COMP A-I-R NEBULIZER",
              "COMP AIR COMPRESSOR NEBULIZER": "COMP AIR COMPRESSOR NEBULIZER",
              "COMP AIR ELITE COMPACT NEB": "COMP AIR ELITE COMPACT NEB",
              "COMP-AIR ELITE COMPACT NEB": "COMP-AIR ELITE COMPACT NEB",
              "COMPAIR NEBULIZER": "COMPAIR NEBULIZER",
              "COMPAIR XL NEBULIZER": "COMPAIR XL NEBULIZER",
              "COMPAIR XLT NEBULIZER": "COMPAIR XLT NEBULIZER",
              "ELITE NEBULIZER SYSTEM": "ELITE NEBULIZER SYSTEM",
              "MICRO AIR NEBULIZER": "MICRO AIR NEBULIZER",
              "MICROAIR VIBRATING MESH NEBUL": "MICROAIR VIBRATING MESH NEBUL"
            },
            "PARI RESPIRATORY": {
              "EFLOW SCF ELECTRONIC NEBULIZER": "EFLOW SCF ELECTRONIC NEBULIZER",
              "EFLOW SCF NEBULIZER HANDSET": "EFLOW SCF NEBULIZER HANDSET",
              "PARI ALTERA NEBULIZER SYSTEM": "PARI ALTERA NEBULIZER SYSTEM",
              "PARI BABY SIZE 1/PARI LC PLUS": "PARI BABY SIZE 1/PARI LC PLUS",
              "PARI ERAPID NEBULIZER SYSTEM": "PARI ERAPID NEBULIZER SYSTEM",
              "PARI LC D NEBULIZER": "PARI LC D NEBULIZER",
              "PARI LC PLUS NEB SET PED MASK": "PARI LC PLUS NEB SET PED MASK",
              "PARI LC PLUS NEBULIZER": "PARI LC PLUS NEBULIZER",
              "PARI LC PLUS VIOS PRO NEB": "PARI LC PLUS VIOS PRO NEB",
              "PARI LC SPRINT NEBULIZER SET": "PARI LC SPRINT NEBULIZER SET",
              "PARI LC STAR NEBULIZER": "PARI LC STAR NEBULIZER",
              "PARI PRONEB ULTRA II": "PARI PRONEB ULTRA II",
              "PARI SINUS AEROSOL SYSTEM": "PARI SINUS AEROSOL SYSTEM",
              "PARI TREK S W/12V DC ADAPTOR": "PARI TREK S W/12V DC ADAPTOR",
              "PARI VIOS PRO LC PLUS SYSTEM": "PARI VIOS PRO LC PLUS SYSTEM",
              "PARI VIOS PRO LC SPRINT SYSTEM": "PARI VIOS PRO LC SPRINT SYSTEM",
              "PRONEB ULTRA II DELUXE/LC STAR": "PRONEB ULTRA II DELUXE/LC STAR",
              "PRONEB ULTRA II DELUXE/LCD": "PRONEB ULTRA II DELUXE/LCD",
              "PRONEB ULTRA II DELX/LC SPRINT": "PRONEB ULTRA II DELX/LC SPRINT",
              "PRONEB ULTRA II PEDIATRIC": "PRONEB ULTRA II PEDIATRIC",
              "PRONEB ULTRA II/LC PLUS": "PRONEB ULTRA II/LC PLUS",
              "PRONEB ULTRA II/LC SPRINT": "PRONEB ULTRA II/LC SPRINT",
              "VIOS AEROSOL DELIVERY SYSTEM": "VIOS AEROSOL DELIVERY SYSTEM",
              "VIOS LC PLUS": "VIOS LC PLUS",
              "VIOS LC PLUS DELUXE": "VIOS LC PLUS DELUXE",
              "VIOS LC PLUS PEDIATRIC": "VIOS LC PLUS PEDIATRIC",
              "VIOS LC SPRINT": "VIOS LC SPRINT",
              "VIOS LC SPRINT DELUXE": "VIOS LC SPRINT DELUXE",
              "VIOS LC SPRINT PEDIATRIC": "VIOS LC SPRINT PEDIATRIC"
            },
            "PRODIGY DIABETES CARE": {
              "PRODIGY MINI-MIST NEBULIZIER": "PRODIGY MINI-MIST NEBULIZIER"
            },
            "RELIAMED": {
              "COMPRESSOR NEBULIZER": "COMPRESSOR NEBULIZER"
            },
            "RESPIRONICS": {
              "AERONEB GO COMPLETE SYSTEM": "AERONEB GO COMPLETE SYSTEM",
              "AERONEB GO CONVENIENCE UNIT": "AERONEB GO CONVENIENCE UNIT",
              "AERONEB GO HANDSET/CABLE": "AERONEB GO HANDSET/CABLE",
              "AERONEB GO NEBULIZER HANDSET": "AERONEB GO NEBULIZER HANDSET",
              "HOMENEB WITH SIDESTREAM": "HOMENEB WITH SIDESTREAM",
              "INNOSPIRE DELUXE NEBULIZER": "INNOSPIRE DELUXE NEBULIZER",
              "INNOSPIRE ELEGANCE NEBULIZER": "INNOSPIRE ELEGANCE NEBULIZER",
              "INNOSPIRE ESSENCE NEBULIZER": "INNOSPIRE ESSENCE NEBULIZER",
              "INNOSPIRE GO PORTABLE MESH NEB": "INNOSPIRE GO PORTABLE MESH NEB",
              "INNOSPIRE MINI COMPRESSOR NEB": "INNOSPIRE MINI COMPRESSOR NEB",
              "INSPIRATION ELITE COMPRESS/NEB": "INSPIRATION ELITE COMPRESS/NEB",
              "INSPIRATION ELITE NEBULIZER": "INSPIRATION ELITE NEBULIZER",
              "INSPIRATION NEBULIZER SYSTEM": "INSPIRATION NEBULIZER SYSTEM",
              "MICRO PLUS NEBULIZER": "MICRO PLUS NEBULIZER",
              "MICROELITE COMPRESSOR NEB SYS": "MICROELITE COMPRESSOR NEB SYS",
              "MINI PLUS NEBULIZER": "MINI PLUS NEBULIZER",
              "MINIELITE COMPRESSOR NEB SYS": "MINIELITE COMPRESSOR NEB SYS",
              "MISTERNEB COMPRESSOR NEBULIZER": "MISTERNEB COMPRESSOR NEBULIZER",
              "OPTIONHOME NEBULIZER SYSTEM": "OPTIONHOME NEBULIZER SYSTEM",
              "PHILLIPS WILLIS THE WHALE NEB": "PHILLIPS WILLIS THE WHALE NEB",
              "SIDESTREAM NEBULIZER-DISP": "SIDESTREAM NEBULIZER-DISP",
              "SIDESTREAM NEBULIZER-REUSABLE": "SIDESTREAM NEBULIZER-REUSABLE",
              "SIDESTREAM PLUS NEBULIZER": "SIDESTREAM PLUS NEBULIZER"
            },
            "SAMSUNG": {
              "HEALTHY LIVING COMPRESSOR/NEB": "HEALTHY LIVING COMPRESSOR/NEB"
            },
            "SDI USA": {
              "CLEVER CHOICE WHIS AIR PED NEB": "CLEVER CHOICE WHIS AIR PED NEB",
              "CLEVER CHOICE WHISPER AIRE NEB": "CLEVER CHOICE WHISPER AIRE NEB"
            },
            "SIMPLE DIAGNOSTICS": {
              "CLEVER CHOICE NEBULIZER": "CLEVER CHOICE NEBULIZER",
              "CLEVER CHOICE WHISPER AIRE PED": "CLEVER CHOICE WHISPER AIRE PED"
            },
            "SUNBEAM": {
              "NEBULIZER": "NEBULIZER"
            },
            "SUNRISE MEDICAL HHG INC.": {
              "DEVILBISS DISPOSABLE NEBULIZER": "DEVILBISS DISPOSABLE NEBULIZER",
              "DEVILBISS NEBULIZER": "DEVILBISS NEBULIZER",
              "LEXAN POCKET NEBULIZER": "LEXAN POCKET NEBULIZER",
              "NEBULIZER": "NEBULIZER",
              "NEBULIZER UPDRAFT-STYLE": "NEBULIZER UPDRAFT-STYLE",
              "PULMOMATE COMP/MICRO-MIST NEB": "PULMOMATE COMP/MICRO-MIST NEB"
            },
            "VERIDIAN HEALTHCARE": {
              "MICRONEB": "MICRONEB"
            }
          },
          "Peak Flow Meters": {
            "ALLIANCE TECH MEDICAL": {
              "AIRZONE PEAK FLOW METER": "AIRZONE PEAK FLOW METER",
              "MINI WRIGHT PEAK FLOW METER": "MINI WRIGHT PEAK FLOW METER"
            },
            "BLAIREX LABS": {
              "ASSESS PEAK FLOW METER": "ASSESS PEAK FLOW METER",
              "PEAK FLOW METER": "PEAK FLOW METER",
              "PERSONAL BEST FULL RANGE": "PERSONAL BEST FULL RANGE",
              "POCKETPEAK PEAK FLOW METER": "POCKETPEAK PEAK FLOW METER"
            },
            "FERRARIS MEDICAL": {
              "POCKETPEAK PEAK FLOW METER": "POCKETPEAK PEAK FLOW METER"
            },
            "HOME AIDE DIAGNOSTICS": {
              "PURE COMFORT FLOW METER ADULT": "PURE COMFORT FLOW METER ADULT",
              "PURE COMFORT FLOW METER CHILD": "PURE COMFORT FLOW METER CHILD"
            },
            "HUDSON RCI": {
              "PEAK FLOW METER UNIVERSAL RANG": "PEAK FLOW METER UNIVERSAL RANG"
            },
            "HUDSON SCIENTIFIC": {
              "BREATHE EASE PEAK FLOW METER": "BREATHE EASE PEAK FLOW METER"
            },
            "MICROLIFE": {
              "MICROLIFE DIGITAL PEAK FLOW": "MICROLIFE DIGITAL PEAK FLOW"
            },
            "MONAGHAN MEDICAL CORPORATION": {
              "AEROGEAR ACTION ASTHMA KIT": "AEROGEAR ACTION ASTHMA KIT",
              "TRUZONE PEAK FLOW METER": "TRUZONE PEAK FLOW METER"
            },
            "NSPIRE HEALTH": {
              "PIKO 1": "PIKO 1",
              "POCKET PEAK FLOW METER": "POCKET PEAK FLOW METER"
            },
            "OMRON MANAGED HEALTHCARE": {
              "PEAK A-I-R FLOW METER": "PEAK A-I-R FLOW METER",
              "PEAK AIR PEAK FLOW METER": "PEAK AIR PEAK FLOW METER"
            },
            "PARI RESPIRATORY": {
              "AIRZONE PEAK FLOW METER": "AIRZONE PEAK FLOW METER",
              "MINI WRIGHT PEAK FLOW METER": "MINI WRIGHT PEAK FLOW METER"
            },
            "QUEST PRODUCTS": {
              "LUNG PERFORM PEAK FLOW METER": "LUNG PERFORM PEAK FLOW METER"
            },
            "RESPIRONICS": {
              "ASSESS FULL RANGE PEAK METER": "ASSESS FULL RANGE PEAK METER",
              "ASSESS LOW RANGE PEAK METER": "ASSESS LOW RANGE PEAK METER",
              "ASTHMA CHECK METER-ZONE SYSTEM": "ASTHMA CHECK METER-ZONE SYSTEM",
              "ASTHMAMENTOR": "ASTHMAMENTOR",
              "ASTHMAPACK FOR CHILDREN": "ASTHMAPACK FOR CHILDREN",
              "ASTHMAPACK I": "ASTHMAPACK I",
              "ASTHMAPACK II": "ASTHMAPACK II",
              "ASTHMAPACK III": "ASTHMAPACK III",
              "PERSONAL BEST FULL RANGE": "PERSONAL BEST FULL RANGE",
              "PERSONAL BEST LOW RANGE": "PERSONAL BEST LOW RANGE"
            },
            "SDI USA": {
              "CLEVER CHOICE PEAK FLOW METER": "CLEVER CHOICE PEAK FLOW METER"
            }
          },
          "Respiratory Therapy Supplies": {
            "ALLIANCE TECH MEDICAL": {
              "ADAPTER PED DISPOSABLE": "ADAPTER PED DISPOSABLE",
              "ADULT DISPOSABLE": "ADULT DISPOSABLE",
              "ALL FLOW 1000 PFT FILTER": "ALL FLOW 1000 PFT FILTER",
              "ALL FLOW 2000 PFT FILTER": "ALL FLOW 2000 PFT FILTER",
              "ALL FLOW 3000 PFT FILTER": "ALL FLOW 3000 PFT FILTER",
              "ALL FLOW 4000 PFT FILTER": "ALL FLOW 4000 PFT FILTER",
              "ALL FLOW 5000 PFT FILTER": "ALL FLOW 5000 PFT FILTER",
              "ALL FLOW 6000 PFT FILTER": "ALL FLOW 6000 PFT FILTER",
              "ALL FLOW 7000 PFT FILTER": "ALL FLOW 7000 PFT FILTER",
              "BREATHERITE VALVED MDI CHAMBER": "BREATHERITE VALVED MDI CHAMBER",
              "CO MONITOR": "CO MONITOR",
              "CO MONITOR CALIBRATION": "CO MONITOR CALIBRATION",
              "CO MONITOR REPLACEMENT PIECES": "CO MONITOR REPLACEMENT PIECES",
              "DISPOSABLE PAPER": "DISPOSABLE PAPER",
              "IN-CHECK DIAL FLOW TRAINER": "IN-CHECK DIAL FLOW TRAINER",
              "IN-CHECK INSPIRATORY FLOW MTR": "IN-CHECK INSPIRATORY FLOW MTR",
              "NOSE CLIP": "NOSE CLIP",
              "ONE FLOW SPIROMETER": "ONE FLOW SPIROMETER",
              "ONE FLOW TESTER": "ONE FLOW TESTER",
              "ONE-WAY VALVED EXPIRATORY": "ONE-WAY VALVED EXPIRATORY",
              "ONE-WAY VALVED INSPIRATORY": "ONE-WAY VALVED INSPIRATORY",
              "PED DISPOSABLE": "PED DISPOSABLE",
              "PILLOW MASK/ADULT": "PILLOW MASK/ADULT",
              "PILLOW MASK/CHILD": "PILLOW MASK/CHILD",
              "PILLOW MASK/PEDIATRIC": "PILLOW MASK/PEDIATRIC",
              "SILICONE MASK/ADULT": "SILICONE MASK/ADULT",
              "SILICONE MASK/INFANT": "SILICONE MASK/INFANT",
              "SILICONE MASK/PEDIATRIC": "SILICONE MASK/PEDIATRIC",
              "WINDMILL TRAINER": "WINDMILL TRAINER"
            },
            "APTALIS PHARMA": {
              "FLUTTER": "FLUTTER"
            },
            "BLAIREX LABS": {
              "DISPOSABLE FULL RANGE": "DISPOSABLE FULL RANGE",
              "DISPOSABLE LOW RANGE": "DISPOSABLE LOW RANGE",
              "DISPOSABLE LOW RANGE/PEDIATRIC": "DISPOSABLE LOW RANGE/PEDIATRIC",
              "DISPOSABLE UNIVERSAL RANGE": "DISPOSABLE UNIVERSAL RANGE",
              "NEBULIZER": "NEBULIZER",
              "NEBULIZER/ADULT MASK": "NEBULIZER/ADULT MASK",
              "NEBULIZER/PEDIATRIC MASK": "NEBULIZER/PEDIATRIC MASK",
              "PARI EXPIRATORY FILTER SET": "PARI EXPIRATORY FILTER SET",
              "PARI LC PLUS PEDIATRIC": "PARI LC PLUS PEDIATRIC",
              "PARI MANUAL INTERRUPTER": "PARI MANUAL INTERRUPTER",
              "PARI MASK SET": "PARI MASK SET",
              "PARI SOFT PLASTIC ADULT MASK": "PARI SOFT PLASTIC ADULT MASK",
              "PARI SOFT PLASTIC PED MASK": "PARI SOFT PLASTIC PED MASK",
              "PRONEB TURBO DELUXE/LC STAR NB": "PRONEB TURBO DELUXE/LC STAR NB",
              "REPLACEMENT AIR FILTER": "REPLACEMENT AIR FILTER"
            },
            "CHAIN DRUG CONSORTIUM": {
              "CONE MASK": "CONE MASK",
              "EARLOOP MASK": "EARLOOP MASK"
            },
            "CONVEXITY SCIENTIFIC": {
              "FLYP HYPERSONIQ CARTRIDGE": "FLYP HYPERSONIQ CARTRIDGE"
            },
            "DEVILBISS HEALTHCARE": {
              "ADULT AEROSOL MASK": "ADULT AEROSOL MASK",
              "DEVILBISS COMPACT COMPRESSOR": "DEVILBISS COMPACT COMPRESSOR",
              "DEVILBISS TRAVEL-AIRE": "DEVILBISS TRAVEL-AIRE",
              "PEDIATRIC AEROSOL MASK": "PEDIATRIC AEROSOL MASK",
              "PULMONEB LT": "PULMONEB LT"
            },
            "FORA CARE": {
              "SOOTHENEB NBL 100 ADULT MASK": "SOOTHENEB NBL 100 ADULT MASK",
              "SOOTHENEB NBL 100 CHILD MASK": "SOOTHENEB NBL 100 CHILD MASK",
              "SOOTHENEB NBL 100 MED CUP": "SOOTHENEB NBL 100 MED CUP",
              "SOOTHENEB NBL 100 MESH CAP": "SOOTHENEB NBL 100 MESH CAP"
            },
            "FUTURE DIAGNOSTICS": {
              "CARETOUCH 2 CPAP HOSE HANGER": "CARETOUCH 2 CPAP HOSE HANGER",
              "CARETOUCH CPAP & BIPAP HOSE": "CARETOUCH CPAP & BIPAP HOSE",
              "CARETOUCH CPAP MASK WIPES": "CARETOUCH CPAP MASK WIPES",
              "CARETOUCH CPAP PRE-WASH SOLN": "CARETOUCH CPAP PRE-WASH SOLN",
              "CARETOUCH CPAP TUBE BRUSH": "CARETOUCH CPAP TUBE BRUSH",
              "CARETOUCH UNIVERSL CPAP FILTER": "CARETOUCH UNIVERSL CPAP FILTER"
            },
            "HUDSON SCIENTIFIC": {
              "BREATHE EASE NEB MASK/CHILD": "BREATHE EASE NEB MASK/CHILD",
              "BREATHE EASE NEB MASK/INFANT": "BREATHE EASE NEB MASK/INFANT"
            },
            "LUMISCOPE": {
              "NEBULIZER COMPRESSOR": "NEBULIZER COMPRESSOR",
              "NEBULIZER MASK PEDIATRIC": "NEBULIZER MASK PEDIATRIC",
              "REPLACEMENT DISP NEBULIZER": "REPLACEMENT DISP NEBULIZER",
              "REPLACEMENT FILTERS": "REPLACEMENT FILTERS"
            },
            "MONAGHAN MEDICAL CORPORATION": {
              "AEROBIKA": "AEROBIKA",
              "AEROTRACH PLUS": "AEROTRACH PLUS",
              "OMBRA TABLE TOP COMPRESSOR": "OMBRA TABLE TOP COMPRESSOR"
            },
            "MYLAN SPECIALTY L.P.": {
              "ACE AEROSOL CLOUD ENHANCER": "ACE AEROSOL CLOUD ENHANCER"
            },
            "NSPIRE HEALTH": {
              "ACTIVITY POUCH": "ACTIVITY POUCH",
              "ADULT DISPOSABLE": "ADULT DISPOSABLE",
              "EXPIRATORY MOUTHPIECE": "EXPIRATORY MOUTHPIECE",
              "KOKO PEAK PRO MOUTHPIECE": "KOKO PEAK PRO MOUTHPIECE",
              "PEDIATRIC MOUTHPIECE": "PEDIATRIC MOUTHPIECE"
            },
            "OMRON MANAGED HEALTHCARE": {
              "ADULT MASK": "ADULT MASK",
              "AIRS DISPOSABLE NEBULIZER": "AIRS DISPOSABLE NEBULIZER",
              "AIRS PEDIATRIC AEROSOL MASK": "AIRS PEDIATRIC AEROSOL MASK",
              "ELITE DC AUTO ADAPTER": "ELITE DC AUTO ADAPTER",
              "JET-AIR REUSABLE NEBULIZER SYS": "JET-AIR REUSABLE NEBULIZER SYS",
              "NEBULIZER AIR TUBE/PLUGS": "NEBULIZER AIR TUBE/PLUGS",
              "NEBULIZER MASK PEDIATRIC": "NEBULIZER MASK PEDIATRIC",
              "NEBULIZER/TUBING/MOUTHPIECE": "NEBULIZER/TUBING/MOUTHPIECE"
            },
            "PARI RESPIRATORY": {
              "ADULT AEROSOL MASK": "ADULT AEROSOL MASK",
              "ADULT MASK": "ADULT MASK",
              "BUBBLES THE FISH II PEDI MASK": "BUBBLES THE FISH II PEDI MASK",
              "EBASE CONTROLLER KIT": "EBASE CONTROLLER KIT",
              "EFLOW SCF AEROSOL HEAD": "EFLOW SCF AEROSOL HEAD",
              "FILTER AIR PP": "FILTER AIR PP",
              "PARI ALTERA NEBULIZER HANDSET": "PARI ALTERA NEBULIZER HANDSET",
              "PARI BABY CONVERSION KIT": "PARI BABY CONVERSION KIT",
              "PARI ERAPID NEBULIZER HANDSET": "PARI ERAPID NEBULIZER HANDSET",
              "PARI TREK S COMBO PACK": "PARI TREK S COMBO PACK",
              "PARI TREK S PORTABLE POWER": "PARI TREK S PORTABLE POWER",
              "PARI VORTEX ADULT MASK": "PARI VORTEX ADULT MASK",
              "PRONEB ULTRA FILTER SET": "PRONEB ULTRA FILTER SET",
              "REPLACEMENT FILTERS": "REPLACEMENT FILTERS",
              "TUBING/WING TIP": "TUBING/WING TIP",
              "VORTEX HOLDING CHAMBER/MASK": "VORTEX HOLDING CHAMBER/MASK"
            },
            "PMD HEALTHCARE": {
              "SPIRO PD": "SPIRO PD"
            },
            "RESPIRONICS": {
              "ADULT MASK LARGE": "ADULT MASK LARGE",
              "INNOSPIRE REPLACEMENT FILTER": "INNOSPIRE REPLACEMENT FILTER",
              "LITETOUCH MASK LARGE": "LITETOUCH MASK LARGE",
              "LITETOUCH MASK MEDIUM": "LITETOUCH MASK MEDIUM",
              "LITETOUCH MASK SMALL": "LITETOUCH MASK SMALL",
              "MICROELITE BATTERY": "MICROELITE BATTERY",
              "MICROELITE FILTER REPLACEMENTS": "MICROELITE FILTER REPLACEMENTS",
              "MINIELITE FILTER REPLACEMENTS": "MINIELITE FILTER REPLACEMENTS",
              "MINIELITE RECHARGEABLE BATTERY": "MINIELITE RECHARGEABLE BATTERY",
              "MISTERNEB NEBULIZER ASSEMBLY": "MISTERNEB NEBULIZER ASSEMBLY",
              "PFLEX": "PFLEX",
              "REPLACEMENT FILTERS": "REPLACEMENT FILTERS",
              "SAMI THE SEAL FILTERS": "SAMI THE SEAL FILTERS",
              "SAMI THE SEAL NEBULIZER SYSTEM": "SAMI THE SEAL NEBULIZER SYSTEM",
              "SIDESTREAM ADULT FACE MASK": "SIDESTREAM ADULT FACE MASK",
              "SIDESTREAM CUSTOM NEBULIZER": "SIDESTREAM CUSTOM NEBULIZER",
              "SIDESTREAM PEDIATRIC FACE MASK": "SIDESTREAM PEDIATRIC FACE MASK",
              "SIDESTREAM PLS ADULT FACE MASK": "SIDESTREAM PLS ADULT FACE MASK",
              "SIDESTREAM REUSABLE PEDIATRIC": "SIDESTREAM REUSABLE PEDIATRIC",
              "THRESHOLD IMT": "THRESHOLD IMT",
              "THRESHOLD PEP": "THRESHOLD PEP"
            },
            "SALTER LABS": {
              "FULL KIT NEBULIZER SET": "FULL KIT NEBULIZER SET",
              "SPIROMETER": "SPIROMETER"
            },
            "SAMSUNG": {
              "HEALTHY LIVING FILTERS": "HEALTHY LIVING FILTERS",
              "HEALTHY LIVING MASKS": "HEALTHY LIVING MASKS",
              "HEALTHY LIVING REPLACEMENT KIT": "HEALTHY LIVING REPLACEMENT KIT"
            },
            "SIMPLE DIAGNOSTICS": {
              "PHARMACIST CHOICE MASK WIPES": "PHARMACIST CHOICE MASK WIPES"
            },
            "STERLING KNIGHT PHARMACEUTICAL": {
              "MISTASSIST": "MISTASSIST"
            },
            "SUNRISE MEDICAL HHG INC.": {
              "DEVILBISS COMPRESSOR": "DEVILBISS COMPRESSOR",
              "PEDIATRIC AEROSOL MASK": "PEDIATRIC AEROSOL MASK",
              "PULMO-AIDE COMP/PULMO-NEB DISP": "PULMO-AIDE COMP/PULMO-NEB DISP",
              "PULMO-AIDE LT COMPRESS/NEBULIZ": "PULMO-AIDE LT COMPRESS/NEBULIZ",
              "PULMO-AIDE TRAVELER/FILTERS": "PULMO-AIDE TRAVELER/FILTERS"
            },
            "TELEFLEX MEDICAL": {
              "HUDSON RCI AEROSOL MASK ADULT": "HUDSON RCI AEROSOL MASK ADULT"
            },
            "THAYER MEDICAL": {
              "PRIMEAIRE HOLDING CHAMBER": "PRIMEAIRE HOLDING CHAMBER",
              "QUAKE": "QUAKE"
            },
            "VERIDIAN HEALTHCARE": {
              "PEDIATRIC COMPRESSOR/NEBULIZER": "PEDIATRIC COMPRESSOR/NEBULIZER"
            }
          },
          "Spacer/Aerosol-Holding Chambers & Supplies": {
            "A-S MEDICATION SOLUTIONS": {
              "AEROCHAMBER PLUS": "AEROCHAMBER PLUS",
              "AEROCHAMBER PLUS FLO-VU": "AEROCHAMBER PLUS FLO-VU",
              "AEROCHAMBER PLUS W/MASK SMALL": "AEROCHAMBER PLUS W/MASK SMALL"
            },
            "ALLERGAN": {
              "AEROCHAMBER PLUS FLO-VU": "AEROCHAMBER PLUS FLO-VU",
              "AEROCHAMBER PLUS FLO-VU LARGE": "AEROCHAMBER PLUS FLO-VU LARGE",
              "AEROCHAMBER PLUS FLO-VU SMALL": "AEROCHAMBER PLUS FLO-VU SMALL",
              "AEROCHAMBER PLUS FLO-VU W/MASK": "AEROCHAMBER PLUS FLO-VU W/MASK"
            },
            "ARISE MEDICAL": {
              "PROCARE SPACER/ADULT MASK": "PROCARE SPACER/ADULT MASK",
              "PROCARE SPACER/CHILD MASK": "PROCARE SPACER/CHILD MASK"
            },
            "AVADEL PHARMACEUTICALS USA": {
              "E-Z SPACER": "E-Z SPACER",
              "E-Z SPACER THE BODY GUARDS PK": "E-Z SPACER THE BODY GUARDS PK",
              "FLEXICHAMBER": "FLEXICHAMBER",
              "FLEXICHAMBER ADULT MASK/SMALL": "FLEXICHAMBER ADULT MASK/SMALL",
              "FLEXICHAMBER CHILD MASK/LARGE": "FLEXICHAMBER CHILD MASK/LARGE",
              "FLEXICHAMBER CHILD MASK/SMALL": "FLEXICHAMBER CHILD MASK/SMALL"
            },
            "DESTAL INDUSTRIES": {
              "RITEFLO": "RITEFLO"
            },
            "HOME AIDE DIAGNOSTICS": {
              "PRO COMFORT SPACER ADULT": "PRO COMFORT SPACER ADULT",
              "PRO COMFORT SPACER CHILD": "PRO COMFORT SPACER CHILD",
              "PRO COMFORT SPACER INFANT": "PRO COMFORT SPACER INFANT"
            },
            "HUDSON SCIENTIFIC": {
              "BREATHE EASE LARGE": "BREATHE EASE LARGE",
              "BREATHE EASE MEDIUM": "BREATHE EASE MEDIUM",
              "BREATHE EASE SMALL": "BREATHE EASE SMALL"
            },
            "KEY": {
              "INSPIREASE": "INSPIREASE",
              "INSPIREASE RESERVOIR BAGS": "INSPIREASE RESERVOIR BAGS"
            },
            "LUPIN PHARMACEUTICALS": {
              "INSPIRACHAMBER/LARGE": "INSPIRACHAMBER/LARGE",
              "INSPIRACHAMBER/MEDIUM": "INSPIRACHAMBER/MEDIUM",
              "INSPIRACHAMBER/MOUTHPIECE": "INSPIRACHAMBER/MOUTHPIECE",
              "INSPIRACHAMBER/SMALL": "INSPIRACHAMBER/SMALL"
            },
            "MEDICAL DEVELOPMENTS INTERNATI": {
              "COMPACT SPACE CHAMBER": "COMPACT SPACE CHAMBER",
              "COMPACT SPACE CHAMBER/LG MASK": "COMPACT SPACE CHAMBER/LG MASK",
              "COMPACT SPACE CHAMBER/MED MASK": "COMPACT SPACE CHAMBER/MED MASK",
              "COMPACT SPACE CHAMBER/SM MASK": "COMPACT SPACE CHAMBER/SM MASK"
            },
            "MEDQUIP": {
              "ARIAL CHAMBER": "ARIAL CHAMBER"
            },
            "MONAGHAN MEDICAL CORPORATION": {
              "AEROCHAMBER MINI CHAMBER": "AEROCHAMBER MINI CHAMBER",
              "AEROCHAMBER MV": "AEROCHAMBER MV",
              "AEROCHAMBER PLUS FLO-VU LARGE": "AEROCHAMBER PLUS FLO-VU LARGE",
              "AEROCHAMBER PLUS FLO-VU MEDIUM": "AEROCHAMBER PLUS FLO-VU MEDIUM",
              "AEROCHAMBER PLUS FLO-VU SMALL": "AEROCHAMBER PLUS FLO-VU SMALL",
              "AEROCHAMBER PLUS FLOW VU": "AEROCHAMBER PLUS FLOW VU",
              "AEROCHAMBER W/FLOWSIGNAL": "AEROCHAMBER W/FLOWSIGNAL",
              "AEROCHAMBER Z-STAT PLUS": "AEROCHAMBER Z-STAT PLUS",
              "AEROCHAMBER Z-STAT PLUS CHAMBR": "AEROCHAMBER Z-STAT PLUS CHAMBR",
              "AEROCHAMBER Z-STAT PLUS/LARGE": "AEROCHAMBER Z-STAT PLUS/LARGE",
              "AEROCHAMBER Z-STAT PLUS/MEDIUM": "AEROCHAMBER Z-STAT PLUS/MEDIUM",
              "AEROCHAMBER Z-STAT PLUS/SMALL": "AEROCHAMBER Z-STAT PLUS/SMALL",
              "AEROVENT PLUS": "AEROVENT PLUS"
            },
            "MYLAN SPECIALTY L.P.": {
              "EASIVENT": "EASIVENT",
              "EASIVENT MASK LARGE": "EASIVENT MASK LARGE",
              "EASIVENT MASK MEDIUM": "EASIVENT MASK MEDIUM",
              "EASIVENT MASK SMALL": "EASIVENT MASK SMALL"
            },
            "NSPIRE HEALTH": {
              "PANDA MASK LARGE": "PANDA MASK LARGE",
              "PANDA MASK MEDIUM": "PANDA MASK MEDIUM",
              "PANDA MASK SMALL": "PANDA MASK SMALL",
              "PEDIATRIC PANDA MASK": "PEDIATRIC PANDA MASK",
              "POCKET CHAMBER": "POCKET CHAMBER",
              "POCKET SPACER": "POCKET SPACER"
            },
            "PARI RESPIRATORY": {
              "MASK VORTEX": "MASK VORTEX",
              "VORTEX VALVED HOLDING CHAMBER": "VORTEX VALVED HOLDING CHAMBER"
            },
            "RESPIRATORY DELIVERY SYSTEMS": {
              "MICROCHAMBER": "MICROCHAMBER",
              "MICROSPACER": "MICROSPACER"
            },
            "RESPIRONICS": {
              "OPTICHAMBER ADVANTAGE": "OPTICHAMBER ADVANTAGE",
              "OPTICHAMBER ADVANTAGE-LG MASK": "OPTICHAMBER ADVANTAGE-LG MASK",
              "OPTICHAMBER ADVANTAGE-MED MASK": "OPTICHAMBER ADVANTAGE-MED MASK",
              "OPTICHAMBER ADVANTAGE-SM MASK": "OPTICHAMBER ADVANTAGE-SM MASK",
              "OPTICHAMBER DIAMOND": "OPTICHAMBER DIAMOND",
              "OPTICHAMBER DIAMOND-LG MASK": "OPTICHAMBER DIAMOND-LG MASK",
              "OPTICHAMBER DIAMOND-MD MASK": "OPTICHAMBER DIAMOND-MD MASK",
              "OPTICHAMBER DIAMOND-SM MASK": "OPTICHAMBER DIAMOND-SM MASK",
              "OPTICHAMBER FACE MASK-LARGE": "OPTICHAMBER FACE MASK-LARGE",
              "OPTICHAMBER FACE MASK-MEDIUM": "OPTICHAMBER FACE MASK-MEDIUM",
              "OPTICHAMBER FACE MASK-SMALL": "OPTICHAMBER FACE MASK-SMALL",
              "OPTIHALER": "OPTIHALER",
              "VALVED HOLDING CHAMBER": "VALVED HOLDING CHAMBER"
            },
            "SDI USA": {
              "CLEVER CHOICE HOLDING CHAMBER": "CLEVER CHOICE HOLDING CHAMBER"
            },
            "SIMPLE DIAGNOSTICS": {
              "CLEVER CHOICE HOLDING CHAMBER": "CLEVER CHOICE HOLDING CHAMBER"
            },
            "THAYER MEDICAL": {
              "LITEAIRE": "LITEAIRE"
            },
            "U S PHARMACEUTICAL": {
              "WATCHHALER": "WATCHHALER"
            },
            "VENTLAB CORPORATION": {
              "BREATHERITE": "BREATHERITE",
              "BREATHERITE COLL SPACER ADULT": "BREATHERITE COLL SPACER ADULT",
              "BREATHERITE COLL SPACER CHILD": "BREATHERITE COLL SPACER CHILD",
              "BREATHERITE COLL SPACER INFANT": "BREATHERITE COLL SPACER INFANT",
              "BREATHERITE RIGID SPACER/MASK": "BREATHERITE RIGID SPACER/MASK",
              "BREATHERITE SPACER NEONATE": "BREATHERITE SPACER NEONATE",
              "BREATHERITE SPACER SMALL CHILD": "BREATHERITE SPACER SMALL CHILD",
              "BREATHERITE/LARGE MASK": "BREATHERITE/LARGE MASK",
              "BREATHERITE/MEDIUM MASK": "BREATHERITE/MEDIUM MASK",
              "BREATHERITE/SMALL MASK": "BREATHERITE/SMALL MASK"
            },
            "WAL-MART": {
              "EQ SPACE CHAMBER ANTI-STATIC": "EQ SPACE CHAMBER ANTI-STATIC",
              "EQ SPACE CHAMBER ANTI-STATIC L": "EQ SPACE CHAMBER ANTI-STATIC L",
              "EQ SPACE CHAMBER ANTI-STATIC M": "EQ SPACE CHAMBER ANTI-STATIC M",
              "EQ SPACE CHAMBER ANTI-STATIC S": "EQ SPACE CHAMBER ANTI-STATIC S"
            },
            "ZYLERA PHARMACEUTICALS": {
              "FLEXICHAMBER": "FLEXICHAMBER",
              "FLEXICHAMBER ADULT MASK/SMALL": "FLEXICHAMBER ADULT MASK/SMALL",
              "FLEXICHAMBER CHILD MASK/LARGE": "FLEXICHAMBER CHILD MASK/LARGE",
              "FLEXICHAMBER CHILD MASK/SMALL": "FLEXICHAMBER CHILD MASK/SMALL"
            }
          },
          "Steam Inhalers": {
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS STEAM INHALER": "CVS STEAM INHALER"
            },
            "KAZ": {
              "VICKS VAPO THERAPY STEAM INHAL": "VICKS VAPO THERAPY STEAM INHAL"
            },
            "WALGREENS": {
              "PERSONAL CGH/CLD STEAM INHALER": "PERSONAL CGH/CLD STEAM INHALER",
              "PERSONAL STEAM INHALER": "PERSONAL STEAM INHALER"
            }
          },
          "Tracheostomy Care & Supplies": {
            "COVIDIEN MEDICAL SUPPLIES": {
              "ARGYLE TRACHEOSTOMY CARE TRAY": "ARGYLE TRACHEOSTOMY CARE TRAY"
            }
          },
          "Vaporizers": {
            "ADVANTICE HEALTH": {
              "PEDIACARE GENTLE VAPORS": "PEDIACARE GENTLE VAPORS"
            },
            "CHAIN DRUG CONSORTIUM": {
              "VAPORIZER": "VAPORIZER",
              "WARM MIST VAPORIZER": "WARM MIST VAPORIZER"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS VAPORIZER WARM STEAM": "CVS VAPORIZER WARM STEAM"
            },
            "HOME AIDE DIAGNOSTICS": {
              "LIFESTYLECOMFORT VAPORIZER": "LIFESTYLECOMFORT VAPORIZER"
            },
            "KAZ": {
              "KAZ VAPORIZER": "KAZ VAPORIZER",
              "KAZ VICKS VAPORIZER V150": "KAZ VICKS VAPORIZER V150",
              "VICKS NURSERY VAPORIZER": "VICKS NURSERY VAPORIZER",
              "VICKS VAPORIZER": "VICKS VAPORIZER",
              "VICKS WATERLESS VAPORIZER": "VICKS WATERLESS VAPORIZER"
            },
            "LEADER BRAND PRODUCTS": {
              "VAPORIZER": "VAPORIZER"
            },
            "MCKESSON HOME HEALTH CARE": {
              "VAPORIZER": "VAPORIZER"
            },
            "SUNBEAM": {
              "HANKSCRAFT VAPORIZER": "HANKSCRAFT VAPORIZER",
              "SUNBEAM PORTABLE VAPORIZER": "SUNBEAM PORTABLE VAPORIZER",
              "SUNBEAM VAPORIZER": "SUNBEAM VAPORIZER"
            },
            "WALGREENS": {
              "VAPORIZER": "VAPORIZER",
              "WARM MIST VAPORIZER": "WARM MIST VAPORIZER"
            }
          }
        },
        "Surgical Supplies": {
          "Skin Adhesives": {
            "PFIZER U.S.": {
              "SURGISEAL": "SURGISEAL",
              "SURGISEAL STYLUS": "SURGISEAL STYLUS",
              "SURGISEAL TWIST": "SURGISEAL TWIST"
            },
            "TISSUE SEAL": {
              "HISTOACRYL": "HISTOACRYL"
            }
          },
          "Surgical Scalpels & Blades": {
            "BD MEDICAL SURGICAL SYSTEMS": {
              "DISPOSABLE MINI SCALPEL/BLADE": "DISPOSABLE MINI SCALPEL/BLADE",
              "DISPOSABLE SCALPEL/BLADE": "DISPOSABLE SCALPEL/BLADE",
              "RIB-BACK CARBON STEEL BLADES": "RIB-BACK CARBON STEEL BLADES",
              "RIB-BACK STAINLESS STEEL BLADE": "RIB-BACK STAINLESS STEEL BLADE",
              "SAFETYLOCK BLADE HANDLE": "SAFETYLOCK BLADE HANDLE",
              "SAFETYLOCK CARBON STEEL BLADES": "SAFETYLOCK CARBON STEEL BLADES",
              "SAFETYLOCK DISP SCALPEL/BLADE": "SAFETYLOCK DISP SCALPEL/BLADE",
              "SAFETYLOCK STAINL STEEL BLADES": "SAFETYLOCK STAINL STEEL BLADES",
              "SPECIAL STAINLESS BLADES": "SPECIAL STAINLESS BLADES",
              "STAINLESS STEEL BLADES": "STAINLESS STEEL BLADES",
              "SURGICAL BLADE HANDLE": "SURGICAL BLADE HANDLE"
            },
            "COVIDIEN MEDICAL SUPPLIES": {
              "KENDALL SCALPEL SIZE 10": "KENDALL SCALPEL SIZE 10",
              "KENDALL SCALPEL SIZE 11": "KENDALL SCALPEL SIZE 11",
              "KENDALL SCALPEL SIZE 15": "KENDALL SCALPEL SIZE 15"
            }
          },
          "Surgical Sealants": {
            "SPECGX": {
              "PREVELEAK SURGICAL SEALANT": "PREVELEAK SURGICAL SEALANT"
            }
          }
        }
      },
      "MIGRAINE PRODUCTS": {
        "Calcitonin Gene-Related Peptide (CGRP) Receptor Antag": {
          "CGRP Receptor Antagonists - Monocolonal Antibodies": {
            "AMGEN": {
              "AIMOVIG": "AIMOVIG",
              "AIMOVIG (140 MG DOSE)": "AIMOVIG (140 MG DOSE)"
            },
            "LILLY": {
              "EMGALITY": "EMGALITY",
              "EMGALITY (300 MG DOSE)": "EMGALITY (300 MG DOSE)"
            },
            "LUNDBECK": {
              "VYEPTI": "VYEPTI"
            },
            "TEVA PHARMACEUTICALS USA": {
              "AJOVY": "AJOVY"
            }
          },
          "Calcitonin Gene-Related Peptide Receptor Antag (CGRP)": {
            "ALLERGAN": {
              "UBRELVY": "UBRELVY"
            },
            "BIOHAVEN PHARMACEUTICALS": {
              "NURTEC": "NURTEC"
            }
          }
        },
        "Migraine Combinations": {
          "Ergot Combinations": {
            "ALTURA PHARMACEUTICALS": {
              "CAFERGOT": "CAFERGOT"
            },
            "CINTEX": {
              "ERGOTAMINE-CAFFEINE": "ERGOTAMINE-CAFFEINE"
            },
            "COSETTE PHARMACEUTICALS": {
              "MIGERGOT": "MIGERGOT"
            },
            "HORIZON PHARMA": {
              "MIGERGOT": "MIGERGOT"
            },
            "PDRX PHARMACEUTICAL": {
              "CAFERGOT": "CAFERGOT"
            },
            "SANDOZ": {
              "CAFERGOT": "CAFERGOT"
            }
          },
          "Migraine Combinations": {
            "AIDAREX PHARMACEUTICALS": {
              "ISOMETHEPTENE-DICHLORAL-APAP": "ISOMETHEPTENE-DICHLORAL-APAP"
            },
            "AVKARE": {
              "ISOMETHEPTENE-DICHLORAL-APAP": "ISOMETHEPTENE-DICHLORAL-APAP"
            },
            "BASIEM": {
              "SUMACHIP": "SUMACHIP"
            },
            "BUREL PHARMACEUTICALS": {
              "ISOMETHEPTENE-CAFFEINE-APAP": "ISOMETHEPTENE-CAFFEINE-APAP",
              "ISOMETHEPTENE-DICHLORAL-APAP": "ISOMETHEPTENE-DICHLORAL-APAP"
            },
            "GENTEX PHARMA LLC": {
              "PRODRIN": "PRODRIN"
            },
            "MACOVEN PHARMACEUTICALS": {
              "ISOMETHEPTENE-DICHLORAL-APAP": "ISOMETHEPTENE-DICHLORAL-APAP",
              "NODOLOR": "NODOLOR"
            },
            "METHOD PHARMACEUTICALS": {
              "ISOMETHEPTENE-DICHLORAL-APAP": "ISOMETHEPTENE-DICHLORAL-APAP"
            },
            "PURE TEK": {
              "MIGRANOW": "MIGRANOW"
            },
            "TMIG": {
              "MIGRAINE PACK": "MIGRAINE PACK"
            },
            "VILVET PHARMACEUTICAL": {
              "ISOMETHEPTENE-DICHLORAL-APAP": "ISOMETHEPTENE-DICHLORAL-APAP"
            },
            "XSPIRE PHARMA": {
              "ISOMETHEPTENE-CAFFEINE-APAP": "ISOMETHEPTENE-CAFFEINE-APAP"
            }
          },
          "Selective Serotonin Agonist-NSAID Combinations": {
            "AUROBINDO PHARMA": {
              "SUMATRIPTAN-NAPROXEN SODIUM": "SUMATRIPTAN-NAPROXEN SODIUM"
            },
            "CURRAX PHARMACEUTICALS": {
              "TREXIMET": "TREXIMET"
            },
            "MACOVEN PHARMACEUTICALS": {
              "SUMATRIPTAN-NAPROXEN SODIUM": "SUMATRIPTAN-NAPROXEN SODIUM"
            },
            "MYLAN": {
              "SUMATRIPTAN-NAPROXEN SODIUM": "SUMATRIPTAN-NAPROXEN SODIUM"
            },
            "NORTHSTAR RX": {
              "SUMATRIPTAN-NAPROXEN SODIUM": "SUMATRIPTAN-NAPROXEN SODIUM"
            },
            "QUALITY CARE": {
              "TREXIMET": "TREXIMET"
            },
            "SUN PHARMACEUTICALS": {
              "SUMATRIPTAN-NAPROXEN SODIUM": "SUMATRIPTAN-NAPROXEN SODIUM"
            }
          }
        },
        "Migraine Products": {
          "Migraine Products": {
            "APOLLO PHARMACEUTICALS": {
              "DIHYDROERGOTAMINE MESYLATE": "DIHYDROERGOTAMINE MESYLATE"
            },
            "APOTHECA SUPPLY": {
              "ERGOTAMINE TARTRATE": "ERGOTAMINE TARTRATE"
            },
            "BAUSCH HEALTH": {
              "D.H.E. 45": "D.H.E. 45",
              "MIGRANAL": "MIGRANAL"
            },
            "CIPLA USA": {
              "DIHYDROERGOTAMINE MESYLATE": "DIHYDROERGOTAMINE MESYLATE"
            },
            "HIKMA": {
              "DIHYDROERGOTAMINE MESYLATE": "DIHYDROERGOTAMINE MESYLATE"
            },
            "LETCO MEDICAL": {
              "DIHYDROERGOTAMINE MESYLATE": "DIHYDROERGOTAMINE MESYLATE"
            },
            "LEUCADIA PHARMACEUTICALS": {
              "DIHYDROERGOTAMINE MESYLATE": "DIHYDROERGOTAMINE MESYLATE"
            },
            "MEDISCA": {
              "DIHYDROERGOTAMINE MESYLATE": "DIHYDROERGOTAMINE MESYLATE",
              "ERGOTAMINE TARTRATE": "ERGOTAMINE TARTRATE"
            },
            "NOVAPLUS/PERRIGO": {
              "DIHYDROERGOTAMINE MESYLATE": "DIHYDROERGOTAMINE MESYLATE"
            },
            "OCEANSIDE PHARMACEUTICALS": {
              "DIHYDROERGOTAMINE MESYLATE": "DIHYDROERGOTAMINE MESYLATE"
            },
            "PCCA": {
              "DIHYDROERGOTAMINE MESYLATE": "DIHYDROERGOTAMINE MESYLATE",
              "ERGOTAMINE TARTRATE": "ERGOTAMINE TARTRATE"
            },
            "PERRIGO": {
              "DIHYDROERGOTAMINE MESYLATE": "DIHYDROERGOTAMINE MESYLATE"
            },
            "SPECTRUM": {
              "DIHYDROERGOTAMINE MESYLATE": "DIHYDROERGOTAMINE MESYLATE"
            },
            "TERSERA THERAPEUTICS": {
              "ERGOMAR": "ERGOMAR"
            }
          }
        },
        "Migraine Products - NSAIDs": {
          "Migraine Products - NSAIDs": {
            "ASSERTIO THERAPEUTICS": {
              "CAMBIA": "CAMBIA"
            }
          }
        },
        "Serotonin Agonists": {
          "Selective Serotonin Agonists 5-HT(1)": {
            "A-S MEDICATION SOLUTIONS": {
              "IMITREX": "IMITREX",
              "RIZATRIPTAN BENZOATE": "RIZATRIPTAN BENZOATE",
              "SUMATRIPTAN SUCCINATE": "SUMATRIPTAN SUCCINATE"
            },
            "ACTAVIS PHARMA": {
              "SUMATRIPTAN SUCCINATE": "SUMATRIPTAN SUCCINATE"
            },
            "AIDAREX PHARMACEUTICALS": {
              "SUMATRIPTAN SUCCINATE": "SUMATRIPTAN SUCCINATE"
            },
            "AJANTA PHARMA LIMITED": {
              "ALMOTRIPTAN MALATE": "ALMOTRIPTAN MALATE",
              "ELETRIPTAN HYDROBROMIDE": "ELETRIPTAN HYDROBROMIDE",
              "ZOLMITRIPTAN": "ZOLMITRIPTAN"
            },
            "AMNEAL PHARMACEUTICALS": {
              "ELETRIPTAN HYDROBROMIDE": "ELETRIPTAN HYDROBROMIDE",
              "FROVATRIPTAN SUCCINATE": "FROVATRIPTAN SUCCINATE",
              "ZOLMITRIPTAN": "ZOLMITRIPTAN"
            },
            "AMNEAL SPECIALTY": {
              "ZOMIG": "ZOMIG",
              "ZOMIG ZMT": "ZOMIG ZMT"
            },
            "APOTEX": {
              "RIZATRIPTAN BENZOATE": "RIZATRIPTAN BENZOATE",
              "ZOLMITRIPTAN": "ZOLMITRIPTAN"
            },
            "ASCEND LABORATORIES": {
              "RIZATRIPTAN BENZOATE": "RIZATRIPTAN BENZOATE"
            },
            "AUROBINDO PHARMA": {
              "ELETRIPTAN HYDROBROMIDE": "ELETRIPTAN HYDROBROMIDE",
              "RIZATRIPTAN BENZOATE": "RIZATRIPTAN BENZOATE",
              "SUMATRIPTAN SUCCINATE": "SUMATRIPTAN SUCCINATE",
              "ZOLMITRIPTAN": "ZOLMITRIPTAN"
            },
            "AUROMEDICS PHARMA": {
              "SUMATRIPTAN SUCCINATE": "SUMATRIPTAN SUCCINATE"
            },
            "AVET PHARMACEUTICALS": {
              "NARATRIPTAN HCL": "NARATRIPTAN HCL",
              "RIZATRIPTAN BENZOATE": "RIZATRIPTAN BENZOATE"
            },
            "BIONPHARMA": {
              "RIZATRIPTAN BENZOATE": "RIZATRIPTAN BENZOATE"
            },
            "BLUE POINT LABORATORIES": {
              "ZOLMITRIPTAN": "ZOLMITRIPTAN"
            },
            "BRECKENRIDGE": {
              "RIZATRIPTAN BENZOATE": "RIZATRIPTAN BENZOATE"
            },
            "BRYANT RANCH PREPACK": {
              "RIZATRIPTAN BENZOATE": "RIZATRIPTAN BENZOATE",
              "SUMATRIPTAN SUCCINATE": "SUMATRIPTAN SUCCINATE"
            },
            "CADISTA": {
              "RIZATRIPTAN BENZOATE": "RIZATRIPTAN BENZOATE",
              "ZOLMITRIPTAN": "ZOLMITRIPTAN"
            },
            "CELLTRION USA": {
              "ZOLMITRIPTAN": "ZOLMITRIPTAN"
            },
            "CIPLA USA": {
              "RIZATRIPTAN BENZOATE": "RIZATRIPTAN BENZOATE",
              "ZOLMITRIPTAN": "ZOLMITRIPTAN"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "SUMATRIPTAN SUCCINATE": "SUMATRIPTAN SUCCINATE"
            },
            "CURRAX PHARMACEUTICALS": {
              "ONZETRA XSAIL": "ONZETRA XSAIL"
            },
            "DIRECT RX": {
              "RIZATRIPTAN BENZOATE": "RIZATRIPTAN BENZOATE",
              "SUMATRIPTAN SUCCINATE": "SUMATRIPTAN SUCCINATE"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "SUMATRIPTAN SUCCINATE": "SUMATRIPTAN SUCCINATE"
            },
            "ENDO PHARMACEUTICALS": {
              "FROVA": "FROVA",
              "SUMAVEL DOSEPRO": "SUMAVEL DOSEPRO"
            },
            "EXELAN PHARMACEUTICALS": {
              "RIZATRIPTAN BENZOATE": "RIZATRIPTAN BENZOATE"
            },
            "FRESENIUS KABI USA": {
              "SUMATRIPTAN SUCCINATE": "SUMATRIPTAN SUCCINATE"
            },
            "GLAXO SMITH KLINE": {
              "AMERGE": "AMERGE",
              "IMITREX": "IMITREX",
              "IMITREX STATDOSE REFILL": "IMITREX STATDOSE REFILL",
              "IMITREX STATDOSE SYSTEM": "IMITREX STATDOSE SYSTEM"
            },
            "GLENMARK PHARMACEUTICALS": {
              "FROVATRIPTAN SUCCINATE": "FROVATRIPTAN SUCCINATE",
              "RIZATRIPTAN BENZOATE": "RIZATRIPTAN BENZOATE",
              "ZOLMITRIPTAN": "ZOLMITRIPTAN"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "SUMATRIPTAN SUCCINATE": "SUMATRIPTAN SUCCINATE"
            },
            "GREENSTONE": {
              "ELETRIPTAN HYDROBROMIDE": "ELETRIPTAN HYDROBROMIDE"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "IMITREX": "IMITREX",
              "MAXALT": "MAXALT",
              "SUMATRIPTAN SUCCINATE": "SUMATRIPTAN SUCCINATE"
            },
            "HIKMA": {
              "NARATRIPTAN HCL": "NARATRIPTAN HCL",
              "SUMATRIPTAN SUCCINATE": "SUMATRIPTAN SUCCINATE"
            },
            "IMPAX GENERICS": {
              "ZOLMITRIPTAN": "ZOLMITRIPTAN"
            },
            "INGENUS PHARMACEUTICALS": {
              "ZOLMITRIPTAN": "ZOLMITRIPTAN"
            },
            "JANSSEN": {
              "AXERT": "AXERT"
            },
            "LANNETT": {
              "SUMATRIPTAN": "SUMATRIPTAN"
            },
            "MACLEODS PHARMACEUTICALS": {
              "RIZATRIPTAN BENZOATE": "RIZATRIPTAN BENZOATE",
              "ZOLMITRIPTAN": "ZOLMITRIPTAN"
            },
            "MEDSTONE PHARMA": {
              "ZOLMITRIPTAN": "ZOLMITRIPTAN"
            },
            "MERCK SHARP & DOHME": {
              "MAXALT": "MAXALT",
              "MAXALT-MLT": "MAXALT-MLT"
            },
            "MYLAN": {
              "ALMOTRIPTAN MALATE": "ALMOTRIPTAN MALATE",
              "ELETRIPTAN HYDROBROMIDE": "ELETRIPTAN HYDROBROMIDE",
              "FROVATRIPTAN SUCCINATE": "FROVATRIPTAN SUCCINATE",
              "NARATRIPTAN HCL": "NARATRIPTAN HCL",
              "RIZATRIPTAN BENZOATE": "RIZATRIPTAN BENZOATE",
              "SUMATRIPTAN SUCCINATE": "SUMATRIPTAN SUCCINATE",
              "ZOLMITRIPTAN": "ZOLMITRIPTAN"
            },
            "MYLAN INSTITUTIONAL": {
              "SUMATRIPTAN SUCCINATE": "SUMATRIPTAN SUCCINATE"
            },
            "NORTHSTAR RX": {
              "SUMATRIPTAN SUCCINATE": "SUMATRIPTAN SUCCINATE"
            },
            "NOVAPLUS/AUROMEDICS": {
              "SUMATRIPTAN SUCCINATE": "SUMATRIPTAN SUCCINATE"
            },
            "NUCARE PHARMACEUTICALS": {
              "MAXALT": "MAXALT",
              "SUMATRIPTAN SUCCINATE": "SUMATRIPTAN SUCCINATE"
            },
            "ORCHIDPHARMA": {
              "NARATRIPTAN HCL": "NARATRIPTAN HCL",
              "SUMATRIPTAN SUCCINATE": "SUMATRIPTAN SUCCINATE"
            },
            "PAR PHARMACEUTICALS": {
              "FROVATRIPTAN SUCCINATE": "FROVATRIPTAN SUCCINATE"
            },
            "PATRIOT PHARMACEUTICALS LLC": {
              "ALMOTRIPTAN MALATE": "ALMOTRIPTAN MALATE"
            },
            "PERRIGO": {
              "NARATRIPTAN HCL": "NARATRIPTAN HCL"
            },
            "PERRIGO PHARMACEUTICALS": {
              "SUMATRIPTAN": "SUMATRIPTAN"
            },
            "PFIZER U.S.": {
              "RELPAX": "RELPAX"
            },
            "PHARMEDIX": {
              "SUMATRIPTAN SUCCINATE": "SUMATRIPTAN SUCCINATE"
            },
            "PRASCO LABORATORIES": {
              "SUMATRIPTAN": "SUMATRIPTAN",
              "SUMATRIPTAN SUCCINATE": "SUMATRIPTAN SUCCINATE",
              "SUMATRIPTAN SUCCINATE REFILL": "SUMATRIPTAN SUCCINATE REFILL"
            },
            "PREFERRED PHARMACEUTICALS": {
              "RIZATRIPTAN BENZOATE": "RIZATRIPTAN BENZOATE",
              "SUMATRIPTAN SUCCINATE": "SUMATRIPTAN SUCCINATE"
            },
            "PROFICIENT RX": {
              "RIZATRIPTAN BENZOATE": "RIZATRIPTAN BENZOATE",
              "SUMATRIPTAN SUCCINATE": "SUMATRIPTAN SUCCINATE"
            },
            "QUALITY CARE": {
              "FROVA": "FROVA",
              "IMITREX": "IMITREX",
              "MAXALT": "MAXALT",
              "MAXALT-MLT": "MAXALT-MLT",
              "SUMATRIPTAN SUCCINATE": "SUMATRIPTAN SUCCINATE",
              "ZOMIG": "ZOMIG"
            },
            "QUALITY CARE PRODUCTS": {
              "ELETRIPTAN HYDROBROMIDE": "ELETRIPTAN HYDROBROMIDE",
              "SUMATRIPTAN SUCCINATE": "SUMATRIPTAN SUCCINATE"
            },
            "RISING PHARMACEUTICALS": {
              "RIZATRIPTAN BENZOATE": "RIZATRIPTAN BENZOATE",
              "ZOLMITRIPTAN": "ZOLMITRIPTAN"
            },
            "SAGENT PHARMACEUTICAL": {
              "SUMATRIPTAN SUCCINATE": "SUMATRIPTAN SUCCINATE"
            },
            "SANDOZ": {
              "NARATRIPTAN HCL": "NARATRIPTAN HCL",
              "RIZATRIPTAN BENZOATE": "RIZATRIPTAN BENZOATE",
              "SUMATRIPTAN": "SUMATRIPTAN",
              "SUMATRIPTAN SUCCINATE": "SUMATRIPTAN SUCCINATE",
              "SUMATRIPTAN SUCCINATE REFILL": "SUMATRIPTAN SUCCINATE REFILL"
            },
            "SUN PHARMACEUTICAL": {
              "RIZATRIPTAN BENZOATE": "RIZATRIPTAN BENZOATE"
            },
            "SUN PHARMACEUTICALS": {
              "NARATRIPTAN HCL": "NARATRIPTAN HCL",
              "SUMATRIPTAN SUCCINATE": "SUMATRIPTAN SUCCINATE",
              "ZOLMITRIPTAN": "ZOLMITRIPTAN"
            },
            "TEVA NEUROSCIENCE": {
              "ZECUITY": "ZECUITY"
            },
            "TEVA PHARMACEUTICALS USA": {
              "ALMOTRIPTAN MALATE": "ALMOTRIPTAN MALATE",
              "ELETRIPTAN HYDROBROMIDE": "ELETRIPTAN HYDROBROMIDE",
              "RIZATRIPTAN BENZOATE": "RIZATRIPTAN BENZOATE",
              "SUMATRIPTAN SUCCINATE": "SUMATRIPTAN SUCCINATE"
            },
            "UNICHEM PHARMACEUTICALS": {
              "RIZATRIPTAN BENZOATE": "RIZATRIPTAN BENZOATE"
            },
            "UNIT DOSE SERVICES": {
              "FROVA": "FROVA",
              "SUMATRIPTAN SUCCINATE": "SUMATRIPTAN SUCCINATE"
            },
            "UPSHER-SMITH": {
              "TOSYMRA": "TOSYMRA",
              "ZEMBRACE SYMTOUCH": "ZEMBRACE SYMTOUCH"
            },
            "WOCKHARDT USA": {
              "SUMATRIPTAN SUCCINATE": "SUMATRIPTAN SUCCINATE"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "ELETRIPTAN HYDROBROMIDE": "ELETRIPTAN HYDROBROMIDE",
              "ZOLMITRIPTAN": "ZOLMITRIPTAN"
            }
          },
          "Selective Serotonin Agonists 5-HT(1F)": {
            "LILLY": {
              "REYVOW": "REYVOW"
            }
          }
        }
      },
      "MINERALS & ELECTROLYTES": {
        "Bicarbonates": {
          "Bicarbonates": {
            "A-S MEDICATION SOLUTIONS": {
              "SODIUM BICARBONATE": "SODIUM BICARBONATE"
            },
            "AMERICAN REGENT": {
              "SODIUM ACETATE": "SODIUM ACETATE"
            },
            "ATHENEX PHARMACEUTICAL": {
              "SODIUM BICARBONATE": "SODIUM BICARBONATE"
            },
            "CIVICA": {
              "SODIUM BICARBONATE": "SODIUM BICARBONATE"
            },
            "EXELA PHARMA SCIENCES": {
              "SODIUM BICARBONATE": "SODIUM BICARBONATE"
            },
            "FAGRON": {
              "SODIUM ACETATE": "SODIUM ACETATE"
            },
            "FRESENIUS KABI USA": {
              "SODIUM ACETATE": "SODIUM ACETATE",
              "SODIUM BICARBONATE": "SODIUM BICARBONATE"
            },
            "HOSPIRA": {
              "NEUT": "NEUT",
              "SODIUM ACETATE": "SODIUM ACETATE",
              "SODIUM BICARBONATE": "SODIUM BICARBONATE",
              "SODIUM LACTATE": "SODIUM LACTATE",
              "THAM": "THAM"
            },
            "INTRNTL MEDICATION SYSTEM": {
              "SODIUM BICARBONATE": "SODIUM BICARBONATE"
            },
            "LEITERS HEALTH": {
              "SODIUM BICARBONATE": "SODIUM BICARBONATE"
            },
            "LETCO MEDICAL": {
              "SODIUM ACETATE": "SODIUM ACETATE"
            },
            "NEPHRON STERILE COMPOUNDING": {
              "SODIUM BICARBONATE": "SODIUM BICARBONATE"
            },
            "NOVAPLUS/HOSPIRA": {
              "SODIUM ACETATE": "SODIUM ACETATE",
              "SODIUM BICARBONATE": "SODIUM BICARBONATE"
            },
            "SPECTRUM": {
              "SODIUM ACETATE": "SODIUM ACETATE"
            },
            "STERRX": {
              "SODIUM BICARBONATE": "SODIUM BICARBONATE",
              "SODIUM BICARBONATE-DEXTROSE": "SODIUM BICARBONATE-DEXTROSE"
            }
          }
        },
        "Calcium": {
          "Calcium": {
            "21ST CENTURY HEALTHCARE": {
              "CALCIUM 600": "CALCIUM 600"
            },
            "A-S MEDICATION SOLUTIONS": {
              "CALCIUM CHLORIDE": "CALCIUM CHLORIDE",
              "CALCIUM GLUCONATE": "CALCIUM GLUCONATE"
            },
            "AMERICAN REGENT": {
              "CALCIUM CHLORIDE": "CALCIUM CHLORIDE",
              "CALCIUM GLUCONATE": "CALCIUM GLUCONATE"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP CALCIUM": "GNP CALCIUM"
            },
            "AMNEAL PHARMACEUTICALS": {
              "CALCIUM CHLORIDE": "CALCIUM CHLORIDE"
            },
            "APHENA PHARMA SOLUTIONS": {
              "CALCIUM CARBONATE": "CALCIUM CARBONATE",
              "OYSTER CALCIUM": "OYSTER CALCIUM",
              "OYSTER SHELL CALCIUM": "OYSTER SHELL CALCIUM"
            },
            "AUBURN PHARMACEUTICAL": {
              "CALCIUM 600": "CALCIUM 600",
              "OYSTER SHELL CALCIUM": "OYSTER SHELL CALCIUM"
            },
            "AVPAK": {
              "CALCIUM CARBONATE": "CALCIUM CARBONATE"
            },
            "BASIC DRUGS": {
              "CALCIUM CITRATE": "CALCIUM CITRATE",
              "CALCIUM OYSTER SHELL": "CALCIUM OYSTER SHELL"
            },
            "BERGEN BRUNSWIG": {
              "GNP CALCIUM": "GNP CALCIUM"
            },
            "BIO-TECH": {
              "CAL-CITRATE": "CAL-CITRATE",
              "CAL-GLU": "CAL-GLU",
              "CAL-LAC": "CAL-LAC"
            },
            "BRYANT RANCH PREPACK": {
              "CALCIUM": "CALCIUM"
            },
            "CHAIN DRUG CONSORTIUM": {
              "CALCIUM CARBONATE": "CALCIUM CARBONATE",
              "CORAL CALCIUM": "CORAL CALCIUM",
              "OYSTER SHELL CALCIUM": "OYSTER SHELL CALCIUM"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC CALCIUM FAST DISSOLUTION": "QC CALCIUM FAST DISSOLUTION"
            },
            "CHRONOHEALTH": {
              "CORAL CALCIUM": "CORAL CALCIUM"
            },
            "CIVICA": {
              "CALCIUM CHLORIDE": "CALCIUM CHLORIDE"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS CALCIUM": "CVS CALCIUM",
              "CVS CALCIUM CARBONATE": "CVS CALCIUM CARBONATE"
            },
            "CYPRESS PHARMACEUTICAL": {
              "CALCIUM CARBONATE": "CALCIUM CARBONATE"
            },
            "EMPOWER PHARMACY": {
              "CALCIUM GLUCONATE": "CALCIUM GLUCONATE"
            },
            "EQUALINE": {
              "EQL CALCIUM": "EQL CALCIUM"
            },
            "FAGRON": {
              "CA PHOSPHATE DIBASIC DIHYD": "CA PHOSPHATE DIBASIC DIHYD",
              "CALCIUM CARBONATE": "CALCIUM CARBONATE",
              "CALCIUM CARBONATE EXTRA LIGHT": "CALCIUM CARBONATE EXTRA LIGHT",
              "CALCIUM GLUCONATE ANHYDROUS": "CALCIUM GLUCONATE ANHYDROUS",
              "CALCIUM PHOSPHATE TRIBASIC": "CALCIUM PHOSPHATE TRIBASIC"
            },
            "FREEDA VITAMINS": {
              "CAL-MINT": "CAL-MINT",
              "CALCIUM CARBONATE": "CALCIUM CARBONATE",
              "CALCIUM CITRATE": "CALCIUM CITRATE",
              "CALCIUM GLUCONATE": "CALCIUM GLUCONATE",
              "CALCIUM LACTATE": "CALCIUM LACTATE",
              "CHELATED CALCIUM": "CHELATED CALCIUM"
            },
            "FRESENIUS KABI USA": {
              "CALCIUM GLUCONATE": "CALCIUM GLUCONATE"
            },
            "GERI-CARE": {
              "CALCIUM 600": "CALCIUM 600",
              "OYSTER SHELL CALCIUM": "OYSTER SHELL CALCIUM"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "CALTRATE 600": "CALTRATE 600"
            },
            "HIKMA": {
              "CALCIUM CARBONATE": "CALCIUM CARBONATE",
              "CALCIUM GLUCONATE": "CALCIUM GLUCONATE"
            },
            "HILLESTAD PHARMACEUTICALS": {
              "CALCIUM ACETATE": "CALCIUM ACETATE"
            },
            "HOSPIRA": {
              "CALCIUM CHLORIDE": "CALCIUM CHLORIDE"
            },
            "HUMCO": {
              "CALCIUM CARBONATE": "CALCIUM CARBONATE"
            },
            "INTEGRATIVE THERAPEUTICS": {
              "CALCIUM CITRATE": "CALCIUM CITRATE"
            },
            "INTRNTL MEDICATION SYSTEM": {
              "CALCIUM CHLORIDE": "CALCIUM CHLORIDE"
            },
            "KALCHEM INTERNATIONAL": {
              "CALCIUM GLUCONATE MONOHYDRATE": "CALCIUM GLUCONATE MONOHYDRATE"
            },
            "LEADER BRAND PRODUCTS": {
              "CALCIUM CARBONATE": "CALCIUM CARBONATE",
              "OYSTER SHELL CALCIUM": "OYSTER SHELL CALCIUM"
            },
            "LETCO MEDICAL": {
              "CALCIUM CARBONATE": "CALCIUM CARBONATE",
              "CALCIUM CHLORIDE DIHYDRATE": "CALCIUM CHLORIDE DIHYDRATE",
              "CALCIUM GLUCONATE ANHYDROUS": "CALCIUM GLUCONATE ANHYDROUS",
              "CALCIUM GLUCONATE MONOHYDRATE": "CALCIUM GLUCONATE MONOHYDRATE",
              "CALCIUM LACTATE PENTAHYDRATE": "CALCIUM LACTATE PENTAHYDRATE"
            },
            "LIBERTY PHARMACEUTICAL": {
              "CALCIUM CARBONATE": "CALCIUM CARBONATE"
            },
            "MAGNO-HUMPHRIES LABORATORIES": {
              "CALCIUM 600": "CALCIUM 600",
              "CALCIUM CARBONATE": "CALCIUM CARBONATE",
              "CALCIUM CITRATE": "CALCIUM CITRATE",
              "CALCIUM LACTATE": "CALCIUM LACTATE",
              "OYSTER SHELL CALCIUM": "OYSTER SHELL CALCIUM"
            },
            "MAJOR PHARMACEUTICALS": {
              "CALCITRATE": "CALCITRATE",
              "CALCIUM CARBONATE": "CALCIUM CARBONATE",
              "OYSTER SHELL CALCIUM": "OYSTER SHELL CALCIUM"
            },
            "MARLEX PHARMACEUTICALS": {
              "CALCIUM OYSTER SHELL": "CALCIUM OYSTER SHELL"
            },
            "MASON VITAMINS": {
              "OYSTER SHELL CALCIUM": "OYSTER SHELL CALCIUM",
              "SUPER CALCIUM": "SUPER CALCIUM"
            },
            "MCKESSON SUNMARK": {
              "SM CORAL CALCIUM": "SM CORAL CALCIUM"
            },
            "MEDEFIL, INC": {
              "CALCIUM CHLORIDE": "CALCIUM CHLORIDE"
            },
            "MEDICINE SHOPPE": {
              "HIGH POTENCY CALCIUM": "HIGH POTENCY CALCIUM"
            },
            "MEDISCA": {
              "CALCIUM CARBONATE": "CALCIUM CARBONATE",
              "CALCIUM GLUCONATE ANHYDROUS": "CALCIUM GLUCONATE ANHYDROUS",
              "CALCIUM PHOSPHATE DIBASIC": "CALCIUM PHOSPHATE DIBASIC"
            },
            "MILLER": {
              "CALCIUM": "CALCIUM",
              "CALCIUM ASPARTATE": "CALCIUM ASPARTATE",
              "ELITE CALCIUM": "ELITE CALCIUM"
            },
            "NAT-RUL HEALTH PRODUCTS": {
              "CALCITRATE": "CALCITRATE",
              "CALCIUM 600": "CALCIUM 600",
              "CALCIUM-CARB 600": "CALCIUM-CARB 600",
              "OYSTER-CAL 500": "OYSTER-CAL 500"
            },
            "NATIONAL VITAMIN": {
              "CALCIUM CARBONATE": "CALCIUM CARBONATE",
              "OYSTER SHELL CALCIUM": "OYSTER SHELL CALCIUM"
            },
            "NATURES BOUNTY": {
              "CALCIUM 600": "CALCIUM 600",
              "CORAL CALCIUM": "CORAL CALCIUM",
              "OYSTERCAL": "OYSTERCAL"
            },
            "NEPHRON STERILE COMPOUNDING": {
              "CALCIUM CHLORIDE": "CALCIUM CHLORIDE",
              "CALCIUM GLUCONATE": "CALCIUM GLUCONATE"
            },
            "PCCA": {
              "CALCIUM CARBONATE": "CALCIUM CARBONATE",
              "CALCIUM CARBONATE LIGHT": "CALCIUM CARBONATE LIGHT",
              "CALCIUM CHLORIDE DIHYDRATE": "CALCIUM CHLORIDE DIHYDRATE",
              "CALCIUM GLUCONATE": "CALCIUM GLUCONATE",
              "CALCIUM GLUCONATE ANHYDROUS": "CALCIUM GLUCONATE ANHYDROUS",
              "CALCIUM LACTATE PENTAHYDRATE": "CALCIUM LACTATE PENTAHYDRATE",
              "CALCIUM PHOSPHATE DIBASIC": "CALCIUM PHOSPHATE DIBASIC",
              "CALCIUM PHOSPHATE TRIBASIC": "CALCIUM PHOSPHATE TRIBASIC"
            },
            "PDRX PHARMACEUTICAL": {
              "OYSTER SHELL CALCIUM": "OYSTER SHELL CALCIUM"
            },
            "PHARBEST PHARMACEUTICALS": {
              "CALCIUM HIGH POTENCY": "CALCIUM HIGH POTENCY",
              "OYSTER SHELL CALCIUM": "OYSTER SHELL CALCIUM"
            },
            "PHARMASSURE, INC.": {
              "CALCIUM": "CALCIUM",
              "CALCIUM CARBONATE": "CALCIUM CARBONATE"
            },
            "PLUS PHARMA": {
              "CALCIUM 600": "CALCIUM 600",
              "CALCIUM CARBONATE": "CALCIUM CARBONATE",
              "OYSTER SHELL CALCIUM": "OYSTER SHELL CALCIUM"
            },
            "PURE ENCAPSULATIONS": {
              "CALCIUM": "CALCIUM"
            },
            "QUALITY CARE": {
              "CALCIUM CARBONATE": "CALCIUM CARBONATE"
            },
            "REESE PHARMACEUTICAL": {
              "OYSTER SHELL CALCIUM": "OYSTER SHELL CALCIUM"
            },
            "RELIABLE 1 LABS": {
              "OYSTER SHELL CALCIUM": "OYSTER SHELL CALCIUM"
            },
            "REXALL SUNDOWN": {
              "OYSTER SHELL CALCIUM": "OYSTER SHELL CALCIUM"
            },
            "RITE AID CORPORATION": {
              "RA CALCIUM": "RA CALCIUM",
              "RA CALCIUM 600": "RA CALCIUM 600",
              "RA CALCIUM HI-CAL": "RA CALCIUM HI-CAL",
              "RA CALCIUM HIGH POTENCY": "RA CALCIUM HIGH POTENCY",
              "RA HI-CAL": "RA HI-CAL",
              "RA OYSTER SHELL CALCIUM": "RA OYSTER SHELL CALCIUM"
            },
            "RUGBY LABORATORIES": {
              "CALCI-CHEW": "CALCI-CHEW",
              "CALCIONATE": "CALCIONATE",
              "CALCIUM 600": "CALCIUM 600",
              "CALCIUM CARBONATE": "CALCIUM CARBONATE",
              "CALCIUM LACTATE": "CALCIUM LACTATE",
              "OYSCO 500": "OYSCO 500",
              "OYSTER SHELL CALCIUM": "OYSTER SHELL CALCIUM"
            },
            "RXCHANGE CO": {
              "OYSTER SHELL CALCIUM": "OYSTER SHELL CALCIUM"
            },
            "SELECT BRAND": {
              "SB OYSTER SHELL CALCIUM": "SB OYSTER SHELL CALCIUM"
            },
            "SPECTRUM": {
              "CA PHOSPHATE DIBASIC DIHYD": "CA PHOSPHATE DIBASIC DIHYD",
              "CALCIUM CARBONATE": "CALCIUM CARBONATE",
              "CALCIUM CARBONATE LIGHT": "CALCIUM CARBONATE LIGHT",
              "CALCIUM CHLORIDE ANHYDROUS": "CALCIUM CHLORIDE ANHYDROUS",
              "CALCIUM CHLORIDE DIHYDRATE": "CALCIUM CHLORIDE DIHYDRATE",
              "CALCIUM GLUCONATE ANHYDROUS": "CALCIUM GLUCONATE ANHYDROUS",
              "CALCIUM GLUCONATE MONOHYDRATE": "CALCIUM GLUCONATE MONOHYDRATE",
              "CALCIUM LACTATE PENTAHYDRATE": "CALCIUM LACTATE PENTAHYDRATE",
              "CALCIUM PHOSPHATE DIBASIC": "CALCIUM PHOSPHATE DIBASIC",
              "CALCIUM PHOSPHATE TRIBASIC": "CALCIUM PHOSPHATE TRIBASIC"
            },
            "TEVA PHARMACEUTICALS USA": {
              "CALCIUM CARBONATE": "CALCIUM CARBONATE"
            },
            "THRESHOLD ENTERPRISES": {
              "CALCIUM CITRATE": "CALCIUM CITRATE"
            },
            "VA CMOP DALLAS": {
              "OYSTER SHELL CALCIUM": "OYSTER SHELL CALCIUM"
            },
            "VITALINE": {
              "CAL-CARB FORTE": "CAL-CARB FORTE"
            },
            "WAL-MART": {
              "CALCIUM CARBONATE": "CALCIUM CARBONATE"
            },
            "WALGREENS": {
              "CALCIUM": "CALCIUM",
              "CALCIUM 600": "CALCIUM 600",
              "CALCIUM 600 HIGH POTENCY": "CALCIUM 600 HIGH POTENCY",
              "CALCIUM CARBONATE": "CALCIUM CARBONATE"
            },
            "WINDMILL CONSUMER PRODUCTS": {
              "CALCIUM CARBONATE": "CALCIUM CARBONATE",
              "CALCIUM HIGH POTENCY": "CALCIUM HIGH POTENCY",
              "OYSTER SHELL CALCIUM": "OYSTER SHELL CALCIUM"
            }
          },
          "Calcium Combinations": {
            "21ST CENTURY HEALTHCARE": {
              "CALCIUM + D": "CALCIUM + D",
              "CALCIUM 1000 + D": "CALCIUM 1000 + D",
              "CALCIUM 500 + D3": "CALCIUM 500 + D3",
              "CALCIUM 500 +D": "CALCIUM 500 +D",
              "CALCIUM 600+D": "CALCIUM 600+D",
              "CALCIUM 600+D3": "CALCIUM 600+D3",
              "CALCIUM 600+D3 PLUS MINERALS": "CALCIUM 600+D3 PLUS MINERALS",
              "CALCIUM CARBONATE-VITAMIN D": "CALCIUM CARBONATE-VITAMIN D",
              "CALCIUM CITRATE + D3": "CALCIUM CITRATE + D3",
              "CALCIUM CITRATE + D3 MAXIMUM": "CALCIUM CITRATE + D3 MAXIMUM",
              "CALCIUM CITRATE +D": "CALCIUM CITRATE +D",
              "CALCIUM CITRATE+D3 PETITES": "CALCIUM CITRATE+D3 PETITES",
              "CORAL CALCIUM": "CORAL CALCIUM"
            },
            "A-S MEDICATION SOLUTIONS": {
              "CALCIUM 500/D": "CALCIUM 500/D",
              "CALCIUM CARBONATE-VITAMIN D": "CALCIUM CARBONATE-VITAMIN D",
              "CALCIUM HIGH POTENCY/VITAMIN D": "CALCIUM HIGH POTENCY/VITAMIN D"
            },
            "ACELLA PHARMACEUTICALS": {
              "CALCIUM-FOLIC ACID PLUS D": "CALCIUM-FOLIC ACID PLUS D"
            },
            "AIDAREX PHARMACEUTICALS": {
              "CITRUS CALCIUM/VITAMIN D": "CITRUS CALCIUM/VITAMIN D"
            },
            "AMERICAN HEALTH PACKAGING": {
              "CALCIUM 600+D3": "CALCIUM 600+D3",
              "CALCIUM CITRATE + D3 MAXIMUM": "CALCIUM CITRATE + D3 MAXIMUM"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP CALCIUM 500 +D3": "GNP CALCIUM 500 +D3",
              "GNP CALCIUM 600 +D3/MINERALS": "GNP CALCIUM 600 +D3/MINERALS",
              "GNP CALCIUM CITRATE +D3": "GNP CALCIUM CITRATE +D3",
              "GNP CALCIUM PLUS 600 +D": "GNP CALCIUM PLUS 600 +D"
            },
            "APHENA PHARMA SOLUTIONS": {
              "OYSTER SHELL CALCIUM/D": "OYSTER SHELL CALCIUM/D"
            },
            "ATLANTIC BIOLOGICALS": {
              "CALCITRATE": "CALCITRATE",
              "CALCIUM CARBONATE-VITAMIN D": "CALCIUM CARBONATE-VITAMIN D"
            },
            "AUBURN PHARMACEUTICAL": {
              "OYSTER SHELL CALCIUM/D": "OYSTER SHELL CALCIUM/D"
            },
            "AVPAK": {
              "CALCIUM 600+D3": "CALCIUM 600+D3",
              "CALCIUM CARB-CHOLECALCIFEROL": "CALCIUM CARB-CHOLECALCIFEROL",
              "CALCIUM CITRATE + D3 MAXIMUM": "CALCIUM CITRATE + D3 MAXIMUM"
            },
            "BASIC DRUGS": {
              "CALCIUM CITRATE-VITAMIN D": "CALCIUM CITRATE-VITAMIN D",
              "CALCIUM-MAGNESIUM-ZINC": "CALCIUM-MAGNESIUM-ZINC",
              "CALCIUM-VITAMIN D": "CALCIUM-VITAMIN D"
            },
            "BASIC ORGANICS": {
              "CALCIUM-MAGNESIUM-VITAMIN D": "CALCIUM-MAGNESIUM-VITAMIN D"
            },
            "BAYER CONSUMER": {
              "CITRACAL +D3": "CITRACAL +D3",
              "CITRACAL CALCIUM GUMMIES": "CITRACAL CALCIUM GUMMIES",
              "CITRACAL MAXIMUM": "CITRACAL MAXIMUM",
              "CITRACAL PETITES/VITAMIN D": "CITRACAL PETITES/VITAMIN D",
              "CITRACAL SLOW RELEASE": "CITRACAL SLOW RELEASE"
            },
            "BENE HEALTH OTC": {
              "CALCIUM PLUS VITAMIN D": "CALCIUM PLUS VITAMIN D"
            },
            "BERGEN BRUNSWIG": {
              "GNP CALCIUM 1200": "GNP CALCIUM 1200",
              "GNP CALCIUM 500/D": "GNP CALCIUM 500/D",
              "GNP CALCIUM 600 +D/MINERALS": "GNP CALCIUM 600 +D/MINERALS",
              "GNP CALCIUM 600 +D3": "GNP CALCIUM 600 +D3",
              "GNP CALCIUM 600 PLUS D/MINERAL": "GNP CALCIUM 600 PLUS D/MINERAL",
              "GNP CALCIUM 600/D": "GNP CALCIUM 600/D",
              "GNP CALCIUM CITRATE +D3": "GNP CALCIUM CITRATE +D3",
              "GNP CALCIUM CITRATE+D MAXIMUM": "GNP CALCIUM CITRATE+D MAXIMUM",
              "GNP CALCIUM-MAGNESIUM-ZINC": "GNP CALCIUM-MAGNESIUM-ZINC",
              "GNP CALCIUM/VITAMIN D/MINERALS": "GNP CALCIUM/VITAMIN D/MINERALS"
            },
            "BLUEBONNET NUTRITION": {
              "CALCIUM-MAGNESIUM-VITAMIN D": "CALCIUM-MAGNESIUM-VITAMIN D"
            },
            "CHAIN DRUG CONSORTIUM": {
              "CALCIUM 1200+D3": "CALCIUM 1200+D3",
              "CALCIUM 600+D": "CALCIUM 600+D",
              "CALCIUM CARBONATE-VITAMIN D": "CALCIUM CARBONATE-VITAMIN D",
              "CALCIUM CITRATE + D": "CALCIUM CITRATE + D",
              "CALCIUM SOFT CHEWS": "CALCIUM SOFT CHEWS",
              "CALCIUM-MAGNESIUM-ZINC": "CALCIUM-MAGNESIUM-ZINC",
              "CALCIUM/VITAMIN D/MINERALS": "CALCIUM/VITAMIN D/MINERALS"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC CALCIUM 500MG-D3": "QC CALCIUM 500MG-D3",
              "QC CALCIUM 600 +D3/MINERALS": "QC CALCIUM 600 +D3/MINERALS",
              "QC CALCIUM-MAGNESIUM-ZINC-D3": "QC CALCIUM-MAGNESIUM-ZINC-D3",
              "QC CALCIUM/MINERALS/VITAMIN D": "QC CALCIUM/MINERALS/VITAMIN D"
            },
            "CONTRACT PHARMACAL CORPORATION": {
              "OSCAL 500/200 D-3": "OSCAL 500/200 D-3"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS CALCIUM": "CVS CALCIUM",
              "CVS CALCIUM + D3": "CVS CALCIUM + D3",
              "CVS CALCIUM 600 & VITAMIN D3": "CVS CALCIUM 600 & VITAMIN D3",
              "CVS CALCIUM 600 + D/MINERALS": "CVS CALCIUM 600 + D/MINERALS",
              "CVS CALCIUM 600+D": "CVS CALCIUM 600+D",
              "CVS CALCIUM CITRATE +D": "CVS CALCIUM CITRATE +D",
              "CVS CALCIUM CITRATE +D3 MINI": "CVS CALCIUM CITRATE +D3 MINI",
              "CVS CALCIUM CITRATE+D3": "CVS CALCIUM CITRATE+D3",
              "CVS CALCIUM CITRATE+D3 PETITES": "CVS CALCIUM CITRATE+D3 PETITES",
              "CVS CALCIUM SOFT CHEWS": "CVS CALCIUM SOFT CHEWS",
              "CVS CALCIUM-MAGNESIUM-ZINC": "CVS CALCIUM-MAGNESIUM-ZINC",
              "CVS CALCIUM-VITAMIN D": "CVS CALCIUM-VITAMIN D",
              "CVS OYSTER SHELL CALCIUM+VIT D": "CVS OYSTER SHELL CALCIUM+VIT D",
              "CVS OYSTER SHELL CALCIUM-VIT D": "CVS OYSTER SHELL CALCIUM-VIT D",
              "CVS YOGURT + CALCIUM": "CVS YOGURT + CALCIUM",
              "CVS YOGURT+CALCIUM GUMMIES": "CVS YOGURT+CALCIUM GUMMIES"
            },
            "CYPRESS PHARMACEUTICAL": {
              "CALCIUM-VITAMIN D": "CALCIUM-VITAMIN D",
              "CALVITE P&D": "CALVITE P&D",
              "OYSTER CALCIUM + D": "OYSTER CALCIUM + D"
            },
            "ENDURANCE PRODUCTS": {
              "CAL-MAG COMPLEX": "CAL-MAG COMPLEX"
            },
            "ENOVACHEM MANUFACTURING": {
              "LOCALNESIUM": "LOCALNESIUM",
              "LOCALNESIUM-C": "LOCALNESIUM-C"
            },
            "EQUALINE": {
              "EQL CALCIUM CITRATE/VITAMIN D": "EQL CALCIUM CITRATE/VITAMIN D",
              "EQL CALCIUM CITRATE/VITAMIN D3": "EQL CALCIUM CITRATE/VITAMIN D3",
              "EQL CALCIUM GUMMIES": "EQL CALCIUM GUMMIES",
              "EQL CALCIUM SOFT CHEWS": "EQL CALCIUM SOFT CHEWS",
              "EQL CALCIUM-MAGNESIUM-ZINC": "EQL CALCIUM-MAGNESIUM-ZINC",
              "EQL CALCIUM/VITAMIN D": "EQL CALCIUM/VITAMIN D",
              "EQL CALCIUM/VITAMIN D/MINERALS": "EQL CALCIUM/VITAMIN D/MINERALS",
              "EQL CALCIUM/VITAMIN D3": "EQL CALCIUM/VITAMIN D3",
              "EQL CHILDRENS CALCIUM GUMMIES": "EQL CHILDRENS CALCIUM GUMMIES",
              "EQL OYSTER SHELL CALCIUM/D": "EQL OYSTER SHELL CALCIUM/D",
              "EQL VITAMIN D-3 HIGH POTENCY": "EQL VITAMIN D-3 HIGH POTENCY"
            },
            "EXELTIS USA": {
              "CALCIFOL": "CALCIFOL"
            },
            "FREEDA VITAMINS": {
              "ADVANCED CALCIUM FORMULA": "ADVANCED CALCIUM FORMULA",
              "CAL-CITRATE PLUS VITAMIN D": "CAL-CITRATE PLUS VITAMIN D",
              "CAL/MAG": "CAL/MAG",
              "PARVA-CAL": "PARVA-CAL",
              "SUPER CAL/MAG": "SUPER CAL/MAG"
            },
            "GENDOSE PHARMACEUTICALS": {
              "CALCIUM CARBONATE-VITAMIN D": "CALCIUM CARBONATE-VITAMIN D",
              "CALCIUM CITRATE + D3 MAXIMUM": "CALCIUM CITRATE + D3 MAXIMUM"
            },
            "GENERAL NUTRITION CORP": {
              "CALCIUM-MAGNESIUM-VITAMIN D": "CALCIUM-MAGNESIUM-VITAMIN D"
            },
            "GERI-CARE": {
              "CALCIUM 600+D": "CALCIUM 600+D",
              "OYSTER SHELL CALCIUM PLUS D": "OYSTER SHELL CALCIUM PLUS D",
              "OYSTER SHELL CALCIUM/VITAMIN D": "OYSTER SHELL CALCIUM/VITAMIN D"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "CALTRATE 600+D PLUS MINERALS": "CALTRATE 600+D PLUS MINERALS",
              "CALTRATE 600+D3": "CALTRATE 600+D3",
              "CALTRATE 600+D3 SOFT": "CALTRATE 600+D3 SOFT",
              "CALTRATE GUMMY BITES": "CALTRATE GUMMY BITES",
              "CALTRATE MINIS PLUS MINERALS": "CALTRATE MINIS PLUS MINERALS",
              "OS-CAL": "OS-CAL",
              "OS-CAL CALCIUM + D3": "OS-CAL CALCIUM + D3",
              "OS-CAL EXTRA D3": "OS-CAL EXTRA D3",
              "OS-CAL ULTRA": "OS-CAL ULTRA",
              "PRONUTRIENTS CALCIUM+D3": "PRONUTRIENTS CALCIUM+D3"
            },
            "GLOBAL HEALTH INC": {
              "UPCAL D": "UPCAL D"
            },
            "INTEGRATIVE THERAPEUTICS": {
              "CAL-MAG ASPARTATE": "CAL-MAG ASPARTATE",
              "CAL-MAG CHELA-MAX": "CAL-MAG CHELA-MAX",
              "CALCIUM": "CALCIUM"
            },
            "INTERNATIONAL VITAMIN CORP": {
              "POSTURE": "POSTURE",
              "POSTURE-D CALCIUM/MAGNESIUM": "POSTURE-D CALCIUM/MAGNESIUM"
            },
            "KAISER FOUNDATION HOSPITAL": {
              "KP CALCIUM 600+D": "KP CALCIUM 600+D",
              "KP CALCIUM 600+D3": "KP CALCIUM 600+D3",
              "KP CALCIUM CITRATE+D": "KP CALCIUM CITRATE+D",
              "KP CALCIUM-MAGNESIUM-ZINC": "KP CALCIUM-MAGNESIUM-ZINC"
            },
            "LEADER BRAND PRODUCTS": {
              "CALCIUM": "CALCIUM",
              "CALCIUM 500/D": "CALCIUM 500/D",
              "CALCIUM 600+D": "CALCIUM 600+D",
              "CALCIUM 600+D3": "CALCIUM 600+D3",
              "CALCIUM 600+D3 PLUS MINERALS": "CALCIUM 600+D3 PLUS MINERALS",
              "CALCIUM CITRATE + D": "CALCIUM CITRATE + D",
              "CALCIUM CITRATE + D3": "CALCIUM CITRATE + D3",
              "CALCIUM CITRATE+D3 PETITES": "CALCIUM CITRATE+D3 PETITES",
              "CALCIUM CITRATE-VITAMIN D3": "CALCIUM CITRATE-VITAMIN D3",
              "CALCIUM EXTRA D3": "CALCIUM EXTRA D3",
              "CALCIUM+D3": "CALCIUM+D3",
              "CALCIUM-MAGNESIUM-ZINC": "CALCIUM-MAGNESIUM-ZINC",
              "CALCIUM-VITAMIN D": "CALCIUM-VITAMIN D",
              "CALCIUM-VITAMIN D-MINERALS": "CALCIUM-VITAMIN D-MINERALS",
              "OYSTER SHELL CALCIUM/D": "OYSTER SHELL CALCIUM/D"
            },
            "LIBERTY PHARMACEUTICAL": {
              "CALCIUM CARBONATE-VITAMIN D": "CALCIUM CARBONATE-VITAMIN D",
              "OYSTER SHELL CALCIUM/D": "OYSTER SHELL CALCIUM/D"
            },
            "MAGNO-HUMPHRIES LABORATORIES": {
              "BONE MEAL": "BONE MEAL",
              "CALCIUM 600/VITAMIN D": "CALCIUM 600/VITAMIN D",
              "CALCIUM CITRATE-VITAMIN D": "CALCIUM CITRATE-VITAMIN D",
              "CALCIUM/C/D": "CALCIUM/C/D",
              "DOLOMITE": "DOLOMITE",
              "OYSTER SHELL CALCIUM/D": "OYSTER SHELL CALCIUM/D"
            },
            "MAINPOINTE PHARMACEUTICALS": {
              "CALCET PETITES": "CALCET PETITES"
            },
            "MAJOR PHARMACEUTICALS": {
              "CALCITRATE": "CALCITRATE",
              "CALCIUM": "CALCIUM",
              "CALCIUM + D3": "CALCIUM + D3",
              "CALCIUM + VITAMIN D3": "CALCIUM + VITAMIN D3",
              "CALCIUM 500/D": "CALCIUM 500/D",
              "CALCIUM CARB-CHOLECALCIFEROL": "CALCIUM CARB-CHOLECALCIFEROL",
              "CALCIUM CITRATE-VITAMIN D": "CALCIUM CITRATE-VITAMIN D",
              "CALCIUM-MAGNESIUM-ZINC": "CALCIUM-MAGNESIUM-ZINC",
              "CALCIUM-VITAMIN D3": "CALCIUM-VITAMIN D3",
              "OYSTER SHELL CALCIUM/D": "OYSTER SHELL CALCIUM/D",
              "OYSTER SHELL CALCIUM/VIT D3": "OYSTER SHELL CALCIUM/VIT D3",
              "OYSTER SHELL CALCIUM/VITAMIN D": "OYSTER SHELL CALCIUM/VITAMIN D"
            },
            "MASON VITAMINS": {
              "CALCIUM 600+D3": "CALCIUM 600+D3",
              "CALCIUM 600/VITAMIN D": "CALCIUM 600/VITAMIN D",
              "CALCIUM CITRATE-VITAMIN D": "CALCIUM CITRATE-VITAMIN D",
              "CALCIUM MAGNESIUM 750": "CALCIUM MAGNESIUM 750",
              "CALCIUM-MAGNESIUM-ZINC": "CALCIUM-MAGNESIUM-ZINC",
              "CALCIUM-VITAMIN D3": "CALCIUM-VITAMIN D3",
              "CORAL CALCIUM PLUS": "CORAL CALCIUM PLUS",
              "LIQUID CALCIUM WITH D3": "LIQUID CALCIUM WITH D3",
              "LIQUID CALCIUM/VITAMIN D": "LIQUID CALCIUM/VITAMIN D",
              "OYSTER SHELL CALCIUM/D": "OYSTER SHELL CALCIUM/D",
              "OYSTER SHELL CALCIUM/D3": "OYSTER SHELL CALCIUM/D3",
              "SUPER CALCIUM 600 + D 400": "SUPER CALCIUM 600 + D 400",
              "SUPER CALCIUM 600 + D3": "SUPER CALCIUM 600 + D3"
            },
            "MCKESSON": {
              "HM CALCIUM 600 & VITAMIN D3": "HM CALCIUM 600 & VITAMIN D3",
              "HM CALCIUM 600+D PLUS MINERALS": "HM CALCIUM 600+D PLUS MINERALS",
              "HM CALCIUM CITRATE+D3 PETITE": "HM CALCIUM CITRATE+D3 PETITE",
              "HM CALCIUM CITRATE+VITAMIN D": "HM CALCIUM CITRATE+VITAMIN D",
              "HM CALCIUM MAGNESIUM & ZINC": "HM CALCIUM MAGNESIUM & ZINC",
              "HM CALCIUM-VITAMIN D": "HM CALCIUM-VITAMIN D",
              "HM CALCIUM-VITAMIN D-MINERALS": "HM CALCIUM-VITAMIN D-MINERALS"
            },
            "MCKESSON SUNMARK": {
              "SM CALCIUM 500/VITAMIN D3": "SM CALCIUM 500/VITAMIN D3",
              "SM CALCIUM 600+D PLUS MINERALS": "SM CALCIUM 600+D PLUS MINERALS",
              "SM CALCIUM 600+D3": "SM CALCIUM 600+D3",
              "SM CALCIUM 600/VITAMIN D": "SM CALCIUM 600/VITAMIN D",
              "SM CALCIUM CITRATE W/VIT D3": "SM CALCIUM CITRATE W/VIT D3",
              "SM CALCIUM CITRATE+/VIT D3": "SM CALCIUM CITRATE+/VIT D3",
              "SM CALCIUM CITRATE+D3 PETITE": "SM CALCIUM CITRATE+D3 PETITE",
              "SM CALCIUM CITRATE-VIT D": "SM CALCIUM CITRATE-VIT D",
              "SM CALCIUM SOFT CHEWS": "SM CALCIUM SOFT CHEWS",
              "SM CALCIUM-MAGNESIUM-ZINC": "SM CALCIUM-MAGNESIUM-ZINC",
              "SM CALCIUM-VITAMIN D": "SM CALCIUM-VITAMIN D",
              "SM CALCIUM/VITAMIN D": "SM CALCIUM/VITAMIN D",
              "SM CALCIUM/VITAMIN D3": "SM CALCIUM/VITAMIN D3",
              "SM OYSTER SHELL CALCIUM/VIT D": "SM OYSTER SHELL CALCIUM/VIT D",
              "SM OYSTER SHELL CALCIUM/VIT D3": "SM OYSTER SHELL CALCIUM/VIT D3"
            },
            "MEDICINE SHOPPE": {
              "CALCIUM 500/D": "CALCIUM 500/D",
              "OYSTER SHELL CALCIUM": "OYSTER SHELL CALCIUM"
            },
            "MEIJER": {
              "CALCIUM CITRATE +": "CALCIUM CITRATE +"
            },
            "METAGENICS": {
              "CALCIUM CITRATE CHEWY BITE": "CALCIUM CITRATE CHEWY BITE"
            },
            "MILLER": {
              "ELITE CA/MG": "ELITE CA/MG"
            },
            "MISSION": {
              "CALCET CREAMY BITES": "CALCET CREAMY BITES"
            },
            "NAT-RUL HEALTH PRODUCTS": {
              "CAL-MAG": "CAL-MAG",
              "CALCITRATE PLUS D": "CALCITRATE PLUS D",
              "CALCIUM 600 + D": "CALCIUM 600 + D",
              "CALCIUM-CARB 600 + D": "CALCIUM-CARB 600 + D",
              "CALCIUM-MAGNESIUM-ZINC": "CALCIUM-MAGNESIUM-ZINC",
              "NAT-RUL OYSTER CALCIUM+VIT D": "NAT-RUL OYSTER CALCIUM+VIT D",
              "OYSTER-CAL 250 + D": "OYSTER-CAL 250 + D",
              "OYSTER-CAL 500 + D": "OYSTER-CAL 500 + D",
              "SUPER CAL-MAG-D": "SUPER CAL-MAG-D"
            },
            "NATIONAL VITAMIN": {
              "CALCIUM CARBONATE-VITAMIN D": "CALCIUM CARBONATE-VITAMIN D",
              "CALCIUM CARBONATE-VITAMIN D3": "CALCIUM CARBONATE-VITAMIN D3",
              "CALCIUM CITRATE MALATE-VIT D": "CALCIUM CITRATE MALATE-VIT D",
              "CALCIUM CITRATE-VITAMIN D": "CALCIUM CITRATE-VITAMIN D",
              "CALCIUM CITRATE-VITAMIN D3": "CALCIUM CITRATE-VITAMIN D3",
              "CALCIUM-MAGNESIUM": "CALCIUM-MAGNESIUM",
              "CALCIUM-MAGNESIUM-ZINC": "CALCIUM-MAGNESIUM-ZINC",
              "CORAL CALCIUM": "CORAL CALCIUM",
              "LIQUID CALCIUM WITH D3": "LIQUID CALCIUM WITH D3",
              "LIQUID CALCIUM/VITAMIN D": "LIQUID CALCIUM/VITAMIN D",
              "OYSTER SHELL CALCIUM + D": "OYSTER SHELL CALCIUM + D",
              "OYSTER SHELL CALCIUM + D3": "OYSTER SHELL CALCIUM + D3",
              "OYSTER SHELL CALCIUM 500 + D": "OYSTER SHELL CALCIUM 500 + D",
              "OYSTER SHELL CALCIUM/D": "OYSTER SHELL CALCIUM/D"
            },
            "NATROL": {
              "CORAL CALCIUM": "CORAL CALCIUM"
            },
            "NATURES BOUNTY": {
              "CAL/MAG CITRATE": "CAL/MAG CITRATE",
              "CALCIUM 600/VITAMIN D3": "CALCIUM 600/VITAMIN D3",
              "CALCIUM CITRATE-VITAMIN D": "CALCIUM CITRATE-VITAMIN D",
              "CALCIUM PLUS VITAMIN D": "CALCIUM PLUS VITAMIN D",
              "CALCIUM PLUS VITAMIN D3": "CALCIUM PLUS VITAMIN D3",
              "CALCIUM-MAGNESIUM": "CALCIUM-MAGNESIUM",
              "CALCIUM-MAGNESIUM-ZINC": "CALCIUM-MAGNESIUM-ZINC",
              "CALCIUM-PHOSPHORUS-VITAMIN D": "CALCIUM-PHOSPHORUS-VITAMIN D",
              "CALCIUM-VITAMIN D": "CALCIUM-VITAMIN D",
              "CALCIUM-VITAMIN D3": "CALCIUM-VITAMIN D3",
              "CALCIUM/D3 ADULT GUMMIES": "CALCIUM/D3 ADULT GUMMIES",
              "CORAL CALCIUM PLUS": "CORAL CALCIUM PLUS",
              "DISNEY CALCIUM + VITAMIN D3": "DISNEY CALCIUM + VITAMIN D3",
              "LIQUID CALCIUM/VITAMINS A & D": "LIQUID CALCIUM/VITAMINS A & D",
              "OYSTERCAL-D": "OYSTERCAL-D"
            },
            "NEPHRO-TECH": {
              "MAGNEBIND 200": "MAGNEBIND 200",
              "MAGNEBIND 300": "MAGNEBIND 300"
            },
            "NUTRACEUTICS": {
              "AVORIA CAL+D": "AVORIA CAL+D"
            },
            "PDRX PHARMACEUTICAL": {
              "CALCIUM/VITAMIN D": "CALCIUM/VITAMIN D",
              "OSTEO-PORETICAL": "OSTEO-PORETICAL",
              "OYST-CAL-D 500": "OYST-CAL-D 500"
            },
            "PHARBEST PHARMACEUTICALS": {
              "CALCIUM HIGH POTENCY/VITAMIN D": "CALCIUM HIGH POTENCY/VITAMIN D",
              "CALCIUM-VITAMIN D": "CALCIUM-VITAMIN D",
              "OYSTER SHELL CALCIUM/VITAMIN D": "OYSTER SHELL CALCIUM/VITAMIN D"
            },
            "PHARMA NATURAL": {
              "CALCIUM 600+D": "CALCIUM 600+D"
            },
            "PHARMASSURE, INC.": {
              "CALCIUM": "CALCIUM"
            },
            "PHARMAVITE": {
              "CALCIUM + D + K": "CALCIUM + D + K",
              "CALCIUM CARB-CHOLECALCIFEROL": "CALCIUM CARB-CHOLECALCIFEROL",
              "CALCIUM CARBONATE-VITAMIN D": "CALCIUM CARBONATE-VITAMIN D",
              "CALCIUM CITRATE-VITAMIN D": "CALCIUM CITRATE-VITAMIN D",
              "CALCIUM-MAGNESIUM-ZINC": "CALCIUM-MAGNESIUM-ZINC",
              "CALCIUM-VITAMIN D": "CALCIUM-VITAMIN D",
              "CALCIUM-VITAMIN D3": "CALCIUM-VITAMIN D3",
              "CALCIUM/VITAMIN D3 GUMMIES": "CALCIUM/VITAMIN D3 GUMMIES"
            },
            "PHARMEDIUM SERVICES": {
              "CALCIUM GLUCONATE-DEXTROSE": "CALCIUM GLUCONATE-DEXTROSE",
              "CALCIUM GLUCONATE-NACL": "CALCIUM GLUCONATE-NACL"
            },
            "PLUS PHARMA": {
              "CALCIUM 600+D": "CALCIUM 600+D",
              "CALCIUM CITRATE-VITAMIN D": "CALCIUM CITRATE-VITAMIN D",
              "CALCIUM+D3": "CALCIUM+D3",
              "CALCIUM-D": "CALCIUM-D",
              "OYSTER SHELL CALCIUM 250+D": "OYSTER SHELL CALCIUM 250+D",
              "OYSTER SHELL CALCIUM 500+D": "OYSTER SHELL CALCIUM 500+D",
              "OYSTER SHELL CALCIUM/D": "OYSTER SHELL CALCIUM/D"
            },
            "PRINCETON RESEARCH": {
              "CALCIUM + D3": "CALCIUM + D3",
              "CALCIUM 600+D3": "CALCIUM 600+D3",
              "CALCIUM CITRATE + D3": "CALCIUM CITRATE + D3",
              "CALCIUM PLUS D3 ABSORBABLE": "CALCIUM PLUS D3 ABSORBABLE",
              "OYSTER CALCIUM/D3": "OYSTER CALCIUM/D3"
            },
            "PROSYNTHESIS LABORATORIES": {
              "OPURITY CALCIUM CITRATE PLUS": "OPURITY CALCIUM CITRATE PLUS"
            },
            "PUBLIX SUPER MARKETS INC.": {
              "PX CALCIUM&D": "PX CALCIUM&D"
            },
            "QUVA PHARMA": {
              "CALCIUM GLUCONATE-NACL": "CALCIUM GLUCONATE-NACL"
            },
            "REESE PHARMACEUTICAL": {
              "OYSTER SHELL/VITAMIN D": "OYSTER SHELL/VITAMIN D"
            },
            "RELIABLE 1 LABS": {
              "CALCIUM CITRATE MAXIMUM/VIT D": "CALCIUM CITRATE MAXIMUM/VIT D",
              "CALCIUM CITRATE PETITE/VIT D": "CALCIUM CITRATE PETITE/VIT D",
              "OYSTER SHELL CALCIUM + D": "OYSTER SHELL CALCIUM + D"
            },
            "REXALL SUNDOWN": {
              "CALCIUM CITRATE-VITAMIN D": "CALCIUM CITRATE-VITAMIN D",
              "CALCIUM PLUS VITAMIN D": "CALCIUM PLUS VITAMIN D",
              "CALCIUM PLUS VITAMIN D3": "CALCIUM PLUS VITAMIN D3",
              "CALCIUM-MAGNESIUM": "CALCIUM-MAGNESIUM",
              "CALCIUM-MAGNESIUM-ZINC": "CALCIUM-MAGNESIUM-ZINC",
              "CALCIUM-VITAMIN D": "CALCIUM-VITAMIN D",
              "CALCIUM-VITAMIN D3": "CALCIUM-VITAMIN D3",
              "CALCIUM/VITAMIN D3 GUMMIES": "CALCIUM/VITAMIN D3 GUMMIES",
              "CORAL CALCIUM": "CORAL CALCIUM",
              "OYSTER SHELL CALCIUM PLUS D": "OYSTER SHELL CALCIUM PLUS D"
            },
            "RISING PHARMACEUTICALS": {
              "RISACAL-D": "RISACAL-D"
            },
            "RITE AID CORPORATION": {
              "PA CALCIUM/VITAMIN D": "PA CALCIUM/VITAMIN D",
              "PA OYSTER SHELL CALCIUM": "PA OYSTER SHELL CALCIUM",
              "RA CALCIUM 600/VIT D/MINERALS": "RA CALCIUM 600/VIT D/MINERALS",
              "RA CALCIUM 600/VITAMIN D-3": "RA CALCIUM 600/VITAMIN D-3",
              "RA CALCIUM CIT PLUS VIT D-3": "RA CALCIUM CIT PLUS VIT D-3",
              "RA CALCIUM CIT-VIT D-3 PETITES": "RA CALCIUM CIT-VIT D-3 PETITES",
              "RA CALCIUM CITRATE PLUS VIT D": "RA CALCIUM CITRATE PLUS VIT D",
              "RA CALCIUM HI-CAL/VITAMIN D": "RA CALCIUM HI-CAL/VITAMIN D",
              "RA CALCIUM PLUS VITAMIN D": "RA CALCIUM PLUS VITAMIN D",
              "RA CALCIUM PLUS VITAMIN D3": "RA CALCIUM PLUS VITAMIN D3",
              "RA CALCIUM SOFT CHEWS": "RA CALCIUM SOFT CHEWS",
              "RA CALCIUM-BORON": "RA CALCIUM-BORON",
              "RA CALCIUM-MAGNESIUM-ZINC": "RA CALCIUM-MAGNESIUM-ZINC",
              "RA CALCIUM/VITAMIN D/MINERALS": "RA CALCIUM/VITAMIN D/MINERALS",
              "RA CORAL CALCIUM": "RA CORAL CALCIUM",
              "RA HI CAL": "RA HI CAL",
              "RA HI-CAL PLUS VITAMIN D": "RA HI-CAL PLUS VITAMIN D",
              "RA OYSTER SHELL CALCIUM/D": "RA OYSTER SHELL CALCIUM/D"
            },
            "RUGBY LABORATORIES": {
              "CALCIUM": "CALCIUM",
              "CALCIUM + VITAMIN D3": "CALCIUM + VITAMIN D3",
              "CALCIUM 600+D": "CALCIUM 600+D",
              "CALCIUM 600-D": "CALCIUM 600-D",
              "CALCIUM CARB-CHOLECALCIFEROL": "CALCIUM CARB-CHOLECALCIFEROL",
              "CALCIUM CARBONATE-VITAMIN D3": "CALCIUM CARBONATE-VITAMIN D3",
              "CALCIUM CITRATE-VITAMIN D": "CALCIUM CITRATE-VITAMIN D",
              "CALCIUM-VITAMIN D3": "CALCIUM-VITAMIN D3",
              "CITRUS CALCIUM +D": "CITRUS CALCIUM +D",
              "CITRUS CALCIUM/VITAMIN D": "CITRUS CALCIUM/VITAMIN D",
              "OYSCO 500+D": "OYSCO 500+D",
              "OYSCO D": "OYSCO D",
              "VITAMIN D3/CALCIUM/PHOSPHORUS": "VITAMIN D3/CALCIUM/PHOSPHORUS"
            },
            "SAFECOR HEALTH": {
              "CALCIUM-VITAMIN D3": "CALCIUM-VITAMIN D3"
            },
            "SECOND WIND VITAMINS": {
              "CELEBRATE CALCIUM CITRATE": "CELEBRATE CALCIUM CITRATE",
              "CELEBRATE CALCIUM PLUS 500": "CELEBRATE CALCIUM PLUS 500"
            },
            "SELECT BRAND": {
              "SB CALCIUM + D": "SB CALCIUM + D"
            },
            "TARGET": {
              "TGT CALCIUM + VITAMIN D3": "TGT CALCIUM + VITAMIN D3",
              "TGT CALCIUM DIETARY SUPPLEMENT": "TGT CALCIUM DIETARY SUPPLEMENT"
            },
            "TEVA PHARMACEUTICALS USA": {
              "OYST-CAL D": "OYST-CAL D"
            },
            "THERALOGIX": {
              "CALMAG THINS": "CALMAG THINS"
            },
            "TOPCO": {
              "CALCIUM-VITAMIN D-MINERALS": "CALCIUM-VITAMIN D-MINERALS"
            },
            "TRIMARC LABORATORIES": {
              "CALCIUM CARBONATE-VITAMIN D": "CALCIUM CARBONATE-VITAMIN D",
              "OSTEO-PORETICAL": "OSTEO-PORETICAL"
            },
            "TWIN LABS": {
              "CAL-QUICK": "CAL-QUICK",
              "CALCIUM-MAGNESIUM-VITAMIN D": "CALCIUM-MAGNESIUM-VITAMIN D"
            },
            "U.S. FOODS & PHARMACEUTICALS": {
              "OSTIGEN": "OSTIGEN"
            },
            "VANGARD": {
              "OYSTER SHELL CALCIUM/D": "OYSTER SHELL CALCIUM/D"
            },
            "VIACTIV LIFESTYLE": {
              "VIACTIV CALCIUM PLUS D": "VIACTIV CALCIUM PLUS D"
            },
            "VITALINE": {
              "VITA-CALCIUM": "VITA-CALCIUM"
            },
            "WAL-MART": {
              "CALCIUM 600+D3": "CALCIUM 600+D3",
              "CALCIUM 600/VITAMIN D3": "CALCIUM 600/VITAMIN D3",
              "CALCIUM CITRATE-VITAMIN D3": "CALCIUM CITRATE-VITAMIN D3",
              "CALCIUM PLUS VITAMIN D3": "CALCIUM PLUS VITAMIN D3",
              "CALCIUM/VITAMIN D3/ADULT GUMMY": "CALCIUM/VITAMIN D3/ADULT GUMMY",
              "EQ CALCIUM 500+D": "EQ CALCIUM 500+D",
              "EQ CALCIUM 600+D": "EQ CALCIUM 600+D",
              "EQ CALCIUM 600+D+MINERALS": "EQ CALCIUM 600+D+MINERALS",
              "EQ CALCIUM CITRATE+D": "EQ CALCIUM CITRATE+D",
              "EQ CALCIUM CITRATE+D3": "EQ CALCIUM CITRATE+D3",
              "EQ CALCIUM CITRATE+D3 PETITES": "EQ CALCIUM CITRATE+D3 PETITES"
            },
            "WALGREENS": {
              "BONE DENSITY": "BONE DENSITY",
              "CALCIUM + D": "CALCIUM + D",
              "CALCIUM 1200": "CALCIUM 1200",
              "CALCIUM 500 + D": "CALCIUM 500 + D",
              "CALCIUM 500 + D3": "CALCIUM 500 + D3",
              "CALCIUM 500+D": "CALCIUM 500+D",
              "CALCIUM 500+D HIGH POTENCY": "CALCIUM 500+D HIGH POTENCY",
              "CALCIUM 500+D3": "CALCIUM 500+D3",
              "CALCIUM 500/D": "CALCIUM 500/D",
              "CALCIUM 500/VITAMIN D": "CALCIUM 500/VITAMIN D",
              "CALCIUM 600 + MINERALS": "CALCIUM 600 + MINERALS",
              "CALCIUM 600+D": "CALCIUM 600+D",
              "CALCIUM 600+D HIGH POTENCY": "CALCIUM 600+D HIGH POTENCY",
              "CALCIUM 600+D PLUS MINERALS": "CALCIUM 600+D PLUS MINERALS",
              "CALCIUM 600+D3": "CALCIUM 600+D3",
              "CALCIUM 600+D3 PLUS MINERALS": "CALCIUM 600+D3 PLUS MINERALS",
              "CALCIUM CITRATE + D": "CALCIUM CITRATE + D",
              "CALCIUM CITRATE + D3": "CALCIUM CITRATE + D3",
              "CALCIUM CITRATE + D3 MAXIMUM": "CALCIUM CITRATE + D3 MAXIMUM",
              "CALCIUM CITRATE+D3": "CALCIUM CITRATE+D3",
              "CALCIUM CITRATE+D3 PETITES": "CALCIUM CITRATE+D3 PETITES",
              "CALCIUM CITRATE-VITAMIN D": "CALCIUM CITRATE-VITAMIN D",
              "CALCIUM CREAMIES": "CALCIUM CREAMIES",
              "CALCIUM FOR WOMEN": "CALCIUM FOR WOMEN",
              "CALCIUM GUMMIES": "CALCIUM GUMMIES",
              "CALCIUM PLUS VITAMIN D3": "CALCIUM PLUS VITAMIN D3",
              "CALCIUM SOFT CHEWS": "CALCIUM SOFT CHEWS",
              "CALCIUM+D3 GRADUAL RELEASE": "CALCIUM+D3 GRADUAL RELEASE",
              "CALCIUM+MENAQ7": "CALCIUM+MENAQ7",
              "CALCIUM-MAGNESIUM-ZINC": "CALCIUM-MAGNESIUM-ZINC",
              "CHEWABLE CALCIUM": "CHEWABLE CALCIUM"
            },
            "WG CRITICAL CARE": {
              "CALCIUM GLUCONATE-NACL": "CALCIUM GLUCONATE-NACL"
            },
            "WINDMILL CONSUMER PRODUCTS": {
              "CALCIUM CARBONATE-VITAMIN D": "CALCIUM CARBONATE-VITAMIN D",
              "CALCIUM CITRATE + D": "CALCIUM CITRATE + D",
              "CALCIUM-MAGNESIUM": "CALCIUM-MAGNESIUM",
              "CALCIUM-MAGNESIUM-ZINC": "CALCIUM-MAGNESIUM-ZINC",
              "OYSTER SHELL CALCIUM/D": "OYSTER SHELL CALCIUM/D"
            }
          }
        },
        "Electrolyte Mixtures": {
          "Electrolytes & Dextrose": {
            "B. BRAUN MEDICAL": {
              "DEXTROSE IN LACTATED RINGERS": "DEXTROSE IN LACTATED RINGERS",
              "DEXTROSE-NACL": "DEXTROSE-NACL",
              "ISOLYTE-P IN D5W": "ISOLYTE-P IN D5W",
              "KCL IN DEXTROSE-NACL": "KCL IN DEXTROSE-NACL",
              "POTASSIUM CHLORIDE IN DEXTROSE": "POTASSIUM CHLORIDE IN DEXTROSE"
            },
            "BAXTER HEALTHCARE CORP": {
              "DEXTROSE 5%/ELECTROLYTE #48": "DEXTROSE 5%/ELECTROLYTE #48",
              "DEXTROSE IN LACTATED RINGERS": "DEXTROSE IN LACTATED RINGERS",
              "DEXTROSE-NACL": "DEXTROSE-NACL",
              "KCL IN DEXTROSE-NACL": "KCL IN DEXTROSE-NACL",
              "KCL-LACTATED RINGERS-D5W": "KCL-LACTATED RINGERS-D5W",
              "PLASMA-LYTE-56 IN D5W": "PLASMA-LYTE-56 IN D5W",
              "POTASSIUM CHLORIDE IN DEXTROSE": "POTASSIUM CHLORIDE IN DEXTROSE"
            },
            "HOSPIRA": {
              "DEXTROSE IN LACTATED RINGERS": "DEXTROSE IN LACTATED RINGERS",
              "DEXTROSE-NACL": "DEXTROSE-NACL",
              "IONOSOL-B IN D5W": "IONOSOL-B IN D5W",
              "IONOSOL-MB IN D5W": "IONOSOL-MB IN D5W",
              "KCL IN D5W LACTATED RINGERS": "KCL IN D5W LACTATED RINGERS",
              "KCL IN DEXTROSE-NACL": "KCL IN DEXTROSE-NACL",
              "KCL-LACTATED RINGERS-D5W": "KCL-LACTATED RINGERS-D5W",
              "NORMOSOL-M IN D5W": "NORMOSOL-M IN D5W",
              "NORMOSOL-R IN D5W": "NORMOSOL-R IN D5W",
              "POTASSIUM CHLORIDE IN DEXTROSE": "POTASSIUM CHLORIDE IN DEXTROSE"
            },
            "ICU MEDICAL": {
              "DEXTROSE IN LACTATED RINGERS": "DEXTROSE IN LACTATED RINGERS",
              "DEXTROSE-SODIUM CHLORIDE": "DEXTROSE-SODIUM CHLORIDE",
              "IONOSOL-MB IN D5W": "IONOSOL-MB IN D5W",
              "KCL IN DEXTROSE-NACL": "KCL IN DEXTROSE-NACL",
              "KCL-LACTATED RINGERS-D5W": "KCL-LACTATED RINGERS-D5W",
              "NORMOSOL-R IN D5W": "NORMOSOL-R IN D5W"
            },
            "LUKARE MEDICAL": {
              "ELLIOTTS B": "ELLIOTTS B"
            },
            "PHARMEDIUM SERVICES": {
              "KCL-LIDOCAINE IN D5W": "KCL-LIDOCAINE IN D5W",
              "POTASSIUM CHLORIDE IN D5W": "POTASSIUM CHLORIDE IN D5W"
            }
          },
          "Electrolytes Oral": {
            "ABBOTT NUTRITION": {
              "EQUALYTE": "EQUALYTE",
              "PEDIALYTE": "PEDIALYTE",
              "PEDIALYTE ADVANCED CARE": "PEDIALYTE ADVANCED CARE",
              "PEDIALYTE FREEZER POPS": "PEDIALYTE FREEZER POPS",
              "PEDIALYTE SINGLES": "PEDIALYTE SINGLES",
              "PEDIALYTE SPARKLING RUSH": "PEDIALYTE SPARKLING RUSH",
              "REHYDRALYTE": "REHYDRALYTE"
            },
            "ABBVIE": {
              "PEDIALYTE SINGLES": "PEDIALYTE SINGLES"
            },
            "BERGEN BRUNSWIG": {
              "GNP PEDIATRIC ELECTROLYTE": "GNP PEDIATRIC ELECTROLYTE"
            },
            "CERA PRODUCTS": {
              "CERALYTE 50": "CERALYTE 50",
              "CERALYTE 50 POTASSIUM FREE": "CERALYTE 50 POTASSIUM FREE",
              "CERALYTE 70": "CERALYTE 70",
              "CERALYTE 70 POTASSIUM FREE": "CERALYTE 70 POTASSIUM FREE",
              "CERALYTE 90": "CERALYTE 90",
              "CERAORS 75 NATURAL": "CERAORS 75 NATURAL",
              "CERASPORT": "CERASPORT",
              "CERASPORT EX1": "CERASPORT EX1"
            },
            "CHAIN DRUG CONSORTIUM": {
              "BABY DARLINGS PED ELECTROLYTE": "BABY DARLINGS PED ELECTROLYTE",
              "PEDIATRIC ELECTROLYTE": "PEDIATRIC ELECTROLYTE"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS ELECTROLYTE SOLUTION": "CVS ELECTROLYTE SOLUTION",
              "CVS PED ELECTROLYTE FREEZE POP": "CVS PED ELECTROLYTE FREEZE POP",
              "CVS PEDIATRIC ELECTROLYTE": "CVS PEDIATRIC ELECTROLYTE"
            },
            "DRIP DROP": {
              "DRIPDROP": "DRIPDROP",
              "DRIPDROP HYDRATION": "DRIPDROP HYDRATION"
            },
            "DSE HEALTHCARE SOLUTIONS": {
              "THERMOTABS": "THERMOTABS"
            },
            "ENDURANCE PRODUCTS": {
              "REPLACE SR": "REPLACE SR"
            },
            "EQUALINE": {
              "EQL PEDIATRIC ELECTROLYTE": "EQL PEDIATRIC ELECTROLYTE"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "EMERGEN-C ELECTRO MIX": "EMERGEN-C ELECTRO MIX"
            },
            "H E BUTT GROCERY COMPANY": {
              "H-E-B ORAL ELECTROLYTE": "H-E-B ORAL ELECTROLYTE"
            },
            "HYDRALYTE": {
              "HYDRALYTE": "HYDRALYTE",
              "HYDRALYTE FREEZER POPS": "HYDRALYTE FREEZER POPS"
            },
            "KINDERFARMS": {
              "KINDERLYTE": "KINDERLYTE",
              "KINDERLYTE PREMAX": "KINDERLYTE PREMAX"
            },
            "LCM PHAMACEUTICAL": {
              "PEDIA-POP": "PEDIA-POP"
            },
            "LEADER BRAND PRODUCTS": {
              "ORAL ELECTROLYTES": "ORAL ELECTROLYTES",
              "PED ELECTROLYTE FREEZER POPS": "PED ELECTROLYTE FREEZER POPS",
              "PEDIATRIC ELECTROLYTE": "PEDIATRIC ELECTROLYTE",
              "PEDIATRIC ELECTROLYTE-ZINC": "PEDIATRIC ELECTROLYTE-ZINC"
            },
            "M J NUTR": {
              "ENFAMIL ENFALYTE": "ENFAMIL ENFALYTE"
            },
            "MCKESSON": {
              "HM PEDIATRIC ELECTROLYTE": "HM PEDIATRIC ELECTROLYTE"
            },
            "MCKESSON SUNMARK": {
              "SM PEDIATRIC ELECTROLYTE": "SM PEDIATRIC ELECTROLYTE"
            },
            "MEDICINE SHOPPE": {
              "PEDIATRIC ELECTROLYTE": "PEDIATRIC ELECTROLYTE"
            },
            "MEDIQUE PRODUCTS": {
              "MEDI-LYTE": "MEDI-LYTE"
            },
            "MEDTRITION NATIONAL NUTRITION": {
              "GERIAIDE": "GERIAIDE"
            },
            "MEIJER": {
              "PEDIATRIC ELECTROLYTE": "PEDIATRIC ELECTROLYTE"
            },
            "NATURE'S ONE": {
              "PEDIA VANCE": "PEDIA VANCE"
            },
            "PERFECT CHOICE": {
              "PC PEDIATRIC ELECTROLYTE": "PC PEDIATRIC ELECTROLYTE"
            },
            "PERRIGO": {
              "PEDIATRIC ELECTROLYTE-ZINC": "PEDIATRIC ELECTROLYTE-ZINC"
            },
            "PTS LABS": {
              "REVITAL FREEZER POPS": "REVITAL FREEZER POPS",
              "REVITAL JELL CUPS": "REVITAL JELL CUPS",
              "REVITAL LIQUID SQUEEZERS": "REVITAL LIQUID SQUEEZERS"
            },
            "RITE AID CORPORATION": {
              "RA PED ELECTROLYTE FREEZER POP": "RA PED ELECTROLYTE FREEZER POP",
              "RA PEDIATRIC ELECTROLYTE": "RA PEDIATRIC ELECTROLYTE"
            },
            "RUGBY LABORATORIES": {
              "ORALYTE": "ORALYTE",
              "ORALYTE FREEZER POPS": "ORALYTE FREEZER POPS"
            },
            "SELECT BRAND": {
              "SB PEDIATRIC ELECTROLYTE": "SB PEDIATRIC ELECTROLYTE"
            },
            "SIX SIGMA LABORATORIES": {
              "NORMALYTE": "NORMALYTE"
            },
            "UNICO HOLDINGS, INC": {
              "NATURALYTE": "NATURALYTE"
            },
            "WALGREENS": {
              "ADVANTAGE CARE ELECTROLYTE PED": "ADVANTAGE CARE ELECTROLYTE PED",
              "ORAL ELECTROLYTE FREEZER POPS": "ORAL ELECTROLYTE FREEZER POPS",
              "ORAL ELECTROLYTES": "ORAL ELECTROLYTES",
              "PED ELECTROLYTE FREEZE POPS": "PED ELECTROLYTE FREEZE POPS",
              "PED ELECTROLYTE FREEZER POPS": "PED ELECTROLYTE FREEZER POPS",
              "PEDIATRIC ELECTROLYTE": "PEDIATRIC ELECTROLYTE"
            }
          },
          "Electrolytes Parenteral": {
            "AMERICAN REGENT": {
              "NUTRILYTE": "NUTRILYTE"
            },
            "B. BRAUN MEDICAL": {
              "HYPERLYTE-CR": "HYPERLYTE-CR",
              "ISOLYTE-S": "ISOLYTE-S",
              "ISOLYTE-S PH 7.4": "ISOLYTE-S PH 7.4",
              "LACTATED RINGERS": "LACTATED RINGERS",
              "POTASSIUM CHLORIDE IN NACL": "POTASSIUM CHLORIDE IN NACL",
              "RINGERS": "RINGERS"
            },
            "BAXTER HEALTHCARE CORP": {
              "LACTATED RINGERS": "LACTATED RINGERS",
              "PLASMA-LYTE 148": "PLASMA-LYTE 148",
              "PLASMA-LYTE A": "PLASMA-LYTE A",
              "POTASSIUM CHLORIDE IN NACL": "POTASSIUM CHLORIDE IN NACL",
              "RINGERS": "RINGERS"
            },
            "BECTON DICKINSON AND COMPANY": {
              "LACTATED RINGERS": "LACTATED RINGERS"
            },
            "FRESENIUS KABI USA": {
              "LACTATED RINGERS": "LACTATED RINGERS"
            },
            "HOSPIRA": {
              "LACTATED RINGERS": "LACTATED RINGERS",
              "NORMOSOL-R": "NORMOSOL-R",
              "NORMOSOL-R PH 7.4": "NORMOSOL-R PH 7.4",
              "POTASSIUM CHLORIDE IN NACL": "POTASSIUM CHLORIDE IN NACL",
              "RINGERS": "RINGERS",
              "TPN ELECTROLYTES": "TPN ELECTROLYTES"
            },
            "ICU MEDICAL": {
              "LACTATED RINGERS": "LACTATED RINGERS",
              "NORMOSOL-R": "NORMOSOL-R",
              "NORMOSOL-R PH 7.4": "NORMOSOL-R PH 7.4",
              "POTASSIUM CHLORIDE IN NACL": "POTASSIUM CHLORIDE IN NACL",
              "RINGERS": "RINGERS"
            },
            "INTEGRADOSE COMPOUNDING SERVIC": {
              "KCL-LIDOCAINE-NACL": "KCL-LIDOCAINE-NACL"
            },
            "PHARMEDIUM SERVICES": {
              "KCL (IN NACL 0.9%)": "KCL (IN NACL 0.9%)",
              "KCL IN LACTATED RINGERS": "KCL IN LACTATED RINGERS",
              "KCL-LIDOCAINE IN NACL": "KCL-LIDOCAINE IN NACL",
              "KCL-LIDOCAINE-NACL": "KCL-LIDOCAINE-NACL",
              "POTASSIUM CHLORIDE IN NACL": "POTASSIUM CHLORIDE IN NACL"
            },
            "QUVA PHARMA": {
              "KCL-LIDOCAINE-NACL": "KCL-LIDOCAINE-NACL"
            }
          }
        },
        "Fluoride": {
          "Fluoride": {
            "A-S MEDICATION SOLUTIONS": {
              "SODIUM FLUORIDE": "SODIUM FLUORIDE"
            },
            "AIDAREX PHARMACEUTICALS": {
              "LUDENT": "LUDENT"
            },
            "ARBOR PHARMACEUTICALS": {
              "FLUOR-A-DAY": "FLUOR-A-DAY"
            },
            "COLGATE ORAL PHARMACEUTICALS": {
              "LURIDE": "LURIDE"
            },
            "CONTRACT PHARMACAL CORPORATION": {
              "SODIUM FLUORIDE": "SODIUM FLUORIDE"
            },
            "CYPRESS PHARMACEUTICAL": {
              "SODIUM FLUORIDE": "SODIUM FLUORIDE"
            },
            "DREIR PHARMACEUTICALS": {
              "LOZI-FLUR": "LOZI-FLUR"
            },
            "FLUORITAB": {
              "FLUORITAB": "FLUORITAB"
            },
            "H2 PHARMA": {
              "SODIUM FLUORIDE": "SODIUM FLUORIDE"
            },
            "KIRKMAN SALES COMPANY": {
              "FLUORABON": "FLUORABON",
              "FLURA-DROPS": "FLURA-DROPS",
              "SODIUM FLUORIDE": "SODIUM FLUORIDE"
            },
            "MAYNE PHARMA": {
              "SODIUM FLUORIDE": "SODIUM FLUORIDE"
            },
            "MEDICAL PRODUCTS LABS": {
              "NAFRINSE": "NAFRINSE",
              "NAFRINSE DROPS": "NAFRINSE DROPS"
            },
            "METHOD PHARMACEUTICALS": {
              "SODIUM FLUORIDE": "SODIUM FLUORIDE"
            },
            "PDRX PHARMACEUTICAL": {
              "SODIUM FLUORIDE": "SODIUM FLUORIDE"
            },
            "PURE TEK": {
              "SODIUM FLUORIDE": "SODIUM FLUORIDE"
            },
            "REGENT LABS, INC": {
              "SODIUM FLUORIDE": "SODIUM FLUORIDE"
            },
            "SANCILIO": {
              "LUDENT": "LUDENT",
              "SODIUM FLUORIDE": "SODIUM FLUORIDE"
            },
            "YOUNG DENTAL": {
              "KARIDIUM": "KARIDIUM"
            }
          },
          "Fluoride Combinations": {
            "ARBOR PHARMACEUTICALS": {
              "FLUOR-A-DAY": "FLUOR-A-DAY"
            },
            "BONGEO PHARMACEUTICALS": {
              "FLORIVA": "FLORIVA"
            },
            "MERICON": {
              "FLORICAL": "FLORICAL",
              "MONOCAL": "MONOCAL"
            }
          }
        },
        "Iodine Products": {
          "Iodine Combinations": {
            "BIO-TECH": {
              "ALGA": "ALGA"
            }
          },
          "Iodine Products": {
            "CHAIN DRUG CONSORTIUM": {
              "POTASSIUM IODIDE": "POTASSIUM IODIDE"
            },
            "FREEDA VITAMINS": {
              "KELP": "KELP"
            },
            "FRESENIUS KABI USA": {
              "IODOPEN": "IODOPEN"
            },
            "HUMCO": {
              "IODINE STRONG": "IODINE STRONG"
            },
            "MASON VITAMINS": {
              "KELP": "KELP"
            },
            "NATIONAL VITAMIN": {
              "KELP": "KELP"
            },
            "SAFECOR HEALTH": {
              "IODINE STRONG": "IODINE STRONG"
            }
          }
        },
        "Magnesium": {
          "Magnesium": {
            "21ST CENTURY HEALTHCARE": {
              "MGO": "MGO"
            },
            "A-S MEDICATION SOLUTIONS": {
              "MAGNESIUM": "MAGNESIUM",
              "MAGNESIUM OXIDE": "MAGNESIUM OXIDE"
            },
            "AIDAREX PHARMACEUTICALS": {
              "MAGNESIUM OXIDE": "MAGNESIUM OXIDE"
            },
            "AKORN": {
              "MAGINEX": "MAGINEX",
              "MAGOX 400": "MAGOX 400"
            },
            "AKORN CONSUMER": {
              "MAGINEX": "MAGINEX",
              "MAGINEX DS": "MAGINEX DS",
              "MAGOX 400": "MAGOX 400"
            },
            "AMERICAN HEALTH PACKAGING": {
              "MAGONATE": "MAGONATE"
            },
            "BAUSCH HEALTH": {
              "MAGONATE": "MAGONATE"
            },
            "BERGEN BRUNSWIG": {
              "GNP MAGNESIUM": "GNP MAGNESIUM"
            },
            "BIO-TECH": {
              "MAGNESIUM SULFATE": "MAGNESIUM SULFATE"
            },
            "BRANDYWINE PHARMACEUTICALS": {
              "MAGNESIUM LACTATE": "MAGNESIUM LACTATE"
            },
            "BRECKENRIDGE": {
              "MAGNESIUM OXIDE": "MAGNESIUM OXIDE"
            },
            "BRYANT RANCH PREPACK": {
              "MAGNESIUM OXIDE": "MAGNESIUM OXIDE"
            },
            "CHAIN DRUG CONSORTIUM": {
              "MAGNESIUM": "MAGNESIUM"
            },
            "CHRONOHEALTH": {
              "MAGNESIUM": "MAGNESIUM"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS MAGNESIUM": "CVS MAGNESIUM",
              "CVS MAGNESIUM CITRATE": "CVS MAGNESIUM CITRATE",
              "CVS MAGNESIUM OXIDE": "CVS MAGNESIUM OXIDE",
              "CVS TRIPLE MAGNESIUM COMPLEX": "CVS TRIPLE MAGNESIUM COMPLEX"
            },
            "CYPRESS PHARMACEUTICAL": {
              "MAG-G": "MAG-G",
              "MAGNESIUM OXIDE": "MAGNESIUM OXIDE"
            },
            "EXELA PHARMA SCIENCES": {
              "MAGNESIUM SULFATE": "MAGNESIUM SULFATE"
            },
            "FAGRON": {
              "MAGNESIUM CARBONATE HEAVY": "MAGNESIUM CARBONATE HEAVY",
              "MAGNESIUM CHLORIDE": "MAGNESIUM CHLORIDE"
            },
            "FREEDA VITAMINS": {
              "CHELATED MAGNESIUM": "CHELATED MAGNESIUM",
              "MAGNESIUM CARBONATE": "MAGNESIUM CARBONATE",
              "MAGNESIUM CITRATE": "MAGNESIUM CITRATE",
              "MAGNESIUM GLUCONATE": "MAGNESIUM GLUCONATE",
              "MAGNESIUM OXIDE": "MAGNESIUM OXIDE"
            },
            "FREEDOM PHARMACEUTICALS": {
              "MAGNESIUM CHLORIDE": "MAGNESIUM CHLORIDE"
            },
            "FRESENIUS KABI USA": {
              "MAGNESIUM SULFATE": "MAGNESIUM SULFATE",
              "MAGNESIUM SULFATE IN D5W": "MAGNESIUM SULFATE IN D5W"
            },
            "GERI-CARE": {
              "MAGNESIUM OXIDE": "MAGNESIUM OXIDE",
              "MAGNESIUM OXIDE 400": "MAGNESIUM OXIDE 400"
            },
            "HOSPIRA": {
              "MAGNESIUM SULFATE": "MAGNESIUM SULFATE",
              "MAGNESIUM SULFATE IN D5W": "MAGNESIUM SULFATE IN D5W"
            },
            "INTEGRADOSE COMPOUNDING SERVIC": {
              "MAGNESIUM SULFATE-NACL": "MAGNESIUM SULFATE-NACL"
            },
            "KAISER FOUNDATION HOSPITAL": {
              "KP MAG-OXIDE MAGNESIUM": "KP MAG-OXIDE MAGNESIUM"
            },
            "LEADER BRAND PRODUCTS": {
              "MAGNESIUM": "MAGNESIUM",
              "MAGNESIUM CHLORIDE": "MAGNESIUM CHLORIDE",
              "MAGNESIUM OXIDE": "MAGNESIUM OXIDE"
            },
            "LETCO MEDICAL": {
              "MAGNESIUM CHLORIDE": "MAGNESIUM CHLORIDE"
            },
            "LOGAN PHARMACEUTICALS": {
              "MAGINEX": "MAGINEX"
            },
            "MAGNO-HUMPHRIES LABORATORIES": {
              "MAGNESIUM GLUCONATE": "MAGNESIUM GLUCONATE",
              "MAGNESIUM OXIDE": "MAGNESIUM OXIDE"
            },
            "MAJOR PHARMACEUTICALS": {
              "MAGDELAY": "MAGDELAY",
              "MAGNESIUM OXIDE": "MAGNESIUM OXIDE"
            },
            "MARLEX PHARMACEUTICALS": {
              "MAGNESIUM OXIDE": "MAGNESIUM OXIDE"
            },
            "MASON VITAMINS": {
              "MAGNESIUM": "MAGNESIUM",
              "MAGNESIUM GLUCONATE": "MAGNESIUM GLUCONATE",
              "MAGNESIUM OXIDE": "MAGNESIUM OXIDE"
            },
            "MCGUFF PHARMACEUTICALS": {
              "MAGNESIUM CHLORIDE": "MAGNESIUM CHLORIDE"
            },
            "MCKESSON SUNMARK": {
              "SM MAGNESIUM": "SM MAGNESIUM",
              "SM MAGNESIUM OXIDE": "SM MAGNESIUM OXIDE"
            },
            "MEDICINE SHOPPE": {
              "MAGNESIUM": "MAGNESIUM"
            },
            "MEDISCA": {
              "MAGNESIUM CARBONATE HEAVY": "MAGNESIUM CARBONATE HEAVY",
              "MAGNESIUM CHLORIDE": "MAGNESIUM CHLORIDE"
            },
            "MERIT": {
              "CHLOROMAG": "CHLOROMAG"
            },
            "METAGENICS": {
              "MAG GLYCINATE": "MAG GLYCINATE",
              "MAGNESIUM BISGLYCINATE": "MAGNESIUM BISGLYCINATE"
            },
            "MILLER": {
              "ELITE MAGNESIUM": "ELITE MAGNESIUM",
              "M2 MAGNESIUM": "M2 MAGNESIUM",
              "MAGNESIUM ASPARTATE": "MAGNESIUM ASPARTATE",
              "MG ASPARTATE": "MG ASPARTATE",
              "MP MAGNESIUM": "MP MAGNESIUM"
            },
            "MYLAN INSTITUTIONAL": {
              "MAGNESIUM CHLORIDE": "MAGNESIUM CHLORIDE",
              "MAGNESIUM SULFATE": "MAGNESIUM SULFATE",
              "MAGNESIUM SULFATE IN D5W": "MAGNESIUM SULFATE IN D5W"
            },
            "NAT-RUL HEALTH PRODUCTS": {
              "CHELATED MAGNESIUM": "CHELATED MAGNESIUM",
              "MAGNESIUM GLUCONATE": "MAGNESIUM GLUCONATE",
              "NATRUL MAGNESIUM": "NATRUL MAGNESIUM"
            },
            "NATIONAL VITAMIN": {
              "MAG-OXIDE": "MAG-OXIDE",
              "MAGNESIUM": "MAGNESIUM"
            },
            "NATURES BOUNTY": {
              "MAGNESIUM OXIDE": "MAGNESIUM OXIDE",
              "MAGNESIUM OXIDE -MG SUPPLEMENT": "MAGNESIUM OXIDE -MG SUPPLEMENT"
            },
            "NICHE PHARMACEUTICALS": {
              "MAG-TAB SR": "MAG-TAB SR"
            },
            "NOVAPLUS/FRESENIUS KABI": {
              "MAGNESIUM SULFATE": "MAGNESIUM SULFATE"
            },
            "NOW HEALTH GROUP": {
              "MAGNESIUM CITRATE": "MAGNESIUM CITRATE"
            },
            "OPTIMOX": {
              "MAG-200": "MAG-200"
            },
            "PCCA": {
              "MAGNESIUM CARBONATE": "MAGNESIUM CARBONATE",
              "MAGNESIUM CARBONATE HEAVY": "MAGNESIUM CARBONATE HEAVY",
              "MAGNESIUM CHLORIDE": "MAGNESIUM CHLORIDE"
            },
            "PENTEC HEALTH": {
              "MAGNESIUM SULFATE": "MAGNESIUM SULFATE"
            },
            "PHARMASSURE, INC.": {
              "MAGNESIUM": "MAGNESIUM"
            },
            "PHARMAVITE": {
              "MAGNESIUM": "MAGNESIUM",
              "MAGNESIUM CITRATE": "MAGNESIUM CITRATE",
              "MAGNESIUM OXIDE -MG SUPPLEMENT": "MAGNESIUM OXIDE -MG SUPPLEMENT"
            },
            "PHARMEDIUM SERVICES": {
              "MAGNESIUM SULFATE IN D5W": "MAGNESIUM SULFATE IN D5W",
              "MAGNESIUM SULFATE-LACT RINGERS": "MAGNESIUM SULFATE-LACT RINGERS",
              "MAGNESIUM SULFATE-NACL": "MAGNESIUM SULFATE-NACL"
            },
            "PLUS PHARMA": {
              "MAGNESIUM OXIDE": "MAGNESIUM OXIDE"
            },
            "PRINCETON RESEARCH": {
              "MAGNESIUM GLYCINATE": "MAGNESIUM GLYCINATE",
              "MAGNESIUM OXIDE": "MAGNESIUM OXIDE",
              "MAGNESIUM OXIDE -MG SUPPLEMENT": "MAGNESIUM OXIDE -MG SUPPLEMENT"
            },
            "QUVA PHARMA": {
              "MAGNESIUM SULFATE-NACL": "MAGNESIUM SULFATE-NACL"
            },
            "RELIABLE 1 LABS": {
              "MAGNESIUM OXIDE": "MAGNESIUM OXIDE"
            },
            "REXALL SUNDOWN": {
              "ESSENTIAL MAGNESIUM": "ESSENTIAL MAGNESIUM",
              "MAGNESIUM": "MAGNESIUM",
              "MAGNESIUM OXIDE": "MAGNESIUM OXIDE"
            },
            "RISING PHARMACEUTICALS": {
              "MAG64": "MAG64",
              "MAGNESIUM-OXIDE": "MAGNESIUM-OXIDE"
            },
            "RITE AID CORPORATION": {
              "RA MAGNESIUM": "RA MAGNESIUM",
              "RA NATURAL MAGNESIUM": "RA NATURAL MAGNESIUM"
            },
            "RUGBY LABORATORIES": {
              "MAGNESIUM 27": "MAGNESIUM 27",
              "MAGNESIUM GLUCONATE": "MAGNESIUM GLUCONATE",
              "MAGNESIUM OXIDE": "MAGNESIUM OXIDE"
            },
            "SKY PACKAGING": {
              "MAGNESIUM OXIDE": "MAGNESIUM OXIDE"
            },
            "SPECTRUM": {
              "MAGNESIUM CARBONATE": "MAGNESIUM CARBONATE",
              "MAGNESIUM CARBONATE HEAVY": "MAGNESIUM CARBONATE HEAVY",
              "MAGNESIUM CHLORIDE": "MAGNESIUM CHLORIDE"
            },
            "STERRX": {
              "MAGNESIUM SULFATE": "MAGNESIUM SULFATE"
            },
            "THE GENERIC PHARMACEUTICAL COM": {
              "MAGNESIUM OXIDE": "MAGNESIUM OXIDE"
            },
            "THE KEY COMPANY": {
              "MAGIMIN": "MAGIMIN",
              "MAGNACAPS": "MAGNACAPS"
            },
            "THRESHOLD ENTERPRISES": {
              "MAGNESIUM MALATE": "MAGNESIUM MALATE"
            },
            "WAL-MART": {
              "MAGNESIUM": "MAGNESIUM"
            },
            "WALGREENS": {
              "MAGNESIUM": "MAGNESIUM",
              "MAGNESIUM OXIDE -MG SUPPLEMENT": "MAGNESIUM OXIDE -MG SUPPLEMENT"
            },
            "WESTMINSTER PHARMACEUTICALS": {
              "MAGNESIUM CHLORIDE": "MAGNESIUM CHLORIDE",
              "MAGNESIUM OXIDE": "MAGNESIUM OXIDE"
            },
            "WG CRITICAL CARE": {
              "MAGNESIUM SULFATE": "MAGNESIUM SULFATE",
              "MAGNESIUM SULFATE IN D5W": "MAGNESIUM SULFATE IN D5W"
            },
            "WINDMILL CONSUMER PRODUCTS": {
              "MAGNESIUM GLUCONATE": "MAGNESIUM GLUCONATE",
              "MAGNESIUM OXIDE": "MAGNESIUM OXIDE"
            },
            "X-GEN PHARMACEUTICALS, INC": {
              "MAGNESIUM SULFATE": "MAGNESIUM SULFATE"
            },
            "XYMOGEN": {
              "OPTIMAG 125": "OPTIMAG 125",
              "OPTIMAG NEURO": "OPTIMAG NEURO"
            }
          },
          "Magnesium Combinations": {
            "AVRIO HEALTH LP": {
              "SLOW-MAG": "SLOW-MAG",
              "SLOWMAG MG MUSCLE/HEART": "SLOWMAG MG MUSCLE/HEART"
            },
            "BEACH": {
              "BEELITH": "BEELITH"
            },
            "BIO-TECH": {
              "K-MAG": "K-MAG",
              "K-MAG-70": "K-MAG-70"
            },
            "CYPRESS PHARMACEUTICAL": {
              "MAG-SR PLUS CALCIUM": "MAG-SR PLUS CALCIUM"
            },
            "ENDURANCE PRODUCTS": {
              "MAG-AMIDE": "MAG-AMIDE"
            },
            "GENERAL NUTRITION CORP": {
              "POTASSIUM & MAGNESIUM ASPARTAT": "POTASSIUM & MAGNESIUM ASPARTAT"
            },
            "GERI-CARE": {
              "SLOW MAGNESIUM/CALCIUM": "SLOW MAGNESIUM/CALCIUM"
            },
            "INTEGRATIVE THERAPEUTICS": {
              "KREBS MAGNESIUM-POTASSIUM": "KREBS MAGNESIUM-POTASSIUM"
            },
            "LEADER BRAND PRODUCTS": {
              "SLOW MAGNESIUM/CALCIUM": "SLOW MAGNESIUM/CALCIUM"
            },
            "LEADING PHARMA": {
              "NU-MAG": "NU-MAG"
            },
            "MILLER": {
              "PYRIDOXINE/MINERALS": "PYRIDOXINE/MINERALS"
            },
            "NATIONAL VITAMIN": {
              "MAGNESIUM FIZZ-PLUS": "MAGNESIUM FIZZ-PLUS"
            },
            "NEPHRO-TECH": {
              "MAGNEBIND 400": "MAGNEBIND 400"
            },
            "RITE AID CORPORATION": {
              "RA POTASSIUM/MAGNESIUM": "RA POTASSIUM/MAGNESIUM"
            },
            "THE KEY COMPANY": {
              "ASPARTATE MG & K": "ASPARTATE MG & K"
            },
            "VITALINE": {
              "K-MAG ASPARTATE": "K-MAG ASPARTATE"
            },
            "WAL-MART": {
              "MAGNESIUM-CHELATED ZINC": "MAGNESIUM-CHELATED ZINC"
            }
          }
        },
        "Manganese": {
          "Manganese": {
            "AMERICAN REGENT": {
              "MANGANESE SULFATE": "MANGANESE SULFATE"
            },
            "BIO-TECH": {
              "MN-50": "MN-50"
            },
            "FREEDA VITAMINS": {
              "MANGANESE AMINO ACID CHELATE": "MANGANESE AMINO ACID CHELATE",
              "MANGANESE GLUCONATE": "MANGANESE GLUCONATE"
            },
            "HOSPIRA": {
              "MANGANESE CHLORIDE": "MANGANESE CHLORIDE"
            },
            "MAGNO-HUMPHRIES LABORATORIES": {
              "MANGANESE GLUCONATE": "MANGANESE GLUCONATE"
            },
            "MILLER": {
              "MANGANESE ASPARTATE": "MANGANESE ASPARTATE"
            },
            "THE KEY COMPANY": {
              "MANGIMIN": "MANGIMIN"
            },
            "VITALINE": {
              "MANGANESE": "MANGANESE"
            }
          }
        },
        "Mineral Combinations": {
          "Mineral Combinations": {
            "21ST CENTURY HEALTHCARE": {
              "CAL MAG ZINC +D3": "CAL MAG ZINC +D3"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP CAL MAG ZINC +D3": "GNP CAL MAG ZINC +D3"
            },
            "BAYER CONSUMER": {
              "CITRACAL MAXIMUM PLUS": "CITRACAL MAXIMUM PLUS",
              "CITRACAL PLUS": "CITRACAL PLUS"
            },
            "BERGEN BRUNSWIG": {
              "GNP CAL MAG ZINC +D3": "GNP CAL MAG ZINC +D3"
            },
            "BIO-TECH": {
              "COLAMINE": "COLAMINE"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS CA CITRATE+D/MAGNESIUM": "CVS CA CITRATE+D/MAGNESIUM",
              "CVS CALCIUM 600 PLUS": "CVS CALCIUM 600 PLUS",
              "CVS CALCIUM CITRATE+D3 W/MAGNE": "CVS CALCIUM CITRATE+D3 W/MAGNE",
              "CVS CALCIUM+D/MAGNESIUM": "CVS CALCIUM+D/MAGNESIUM"
            },
            "FREEDA VITAMINS": {
              "FEM-CAL CITRATE": "FEM-CAL CITRATE",
              "MULTI-MINERALS": "MULTI-MINERALS"
            },
            "GENERAL NUTRITION CORP": {
              "MULTI MEGA MINERALS": "MULTI MEGA MINERALS"
            },
            "GOOD THINGS HEALTH": {
              "BONE ESSENTIALS": "BONE ESSENTIALS"
            },
            "INTEGRATIVE THERAPEUTICS": {
              "CAL-MAG-K CHELA-MAX": "CAL-MAG-K CHELA-MAX",
              "NF FORMULAS CALCIUM MAGNESIUM": "NF FORMULAS CALCIUM MAGNESIUM"
            },
            "J R CARLSON LABORATORIES": {
              "NUTRA-SUPPORT BONE": "NUTRA-SUPPORT BONE"
            },
            "MAGNA PHARMACEUTICALS, INC": {
              "LEG-GESIC": "LEG-GESIC"
            },
            "MAGNO-HUMPHRIES LABORATORIES": {
              "CAL-MAG-ZINC-D": "CAL-MAG-ZINC-D"
            },
            "MEDICINE SHOPPE": {
              "CALCIUM CITRATE +": "CALCIUM CITRATE +"
            },
            "MILLER": {
              "MG/TAURINE FORTE": "MG/TAURINE FORTE"
            },
            "NAT-RUL HEALTH PRODUCTS": {
              "NATRUL-CAL": "NATRUL-CAL"
            },
            "NATURES BOUNTY": {
              "CALCIUM-MAGNESIUM-ZINC-D3": "CALCIUM-MAGNESIUM-ZINC-D3"
            },
            "PHARMASSURE, INC.": {
              "CALCIUM-MAGNESIUM-ZINC": "CALCIUM-MAGNESIUM-ZINC"
            },
            "PHARMAVITE": {
              "ADVANCED CALCIUM/D/MAGNESIUM": "ADVANCED CALCIUM/D/MAGNESIUM",
              "CALCIUM-MAGNESIUM-ZINC-D3": "CALCIUM-MAGNESIUM-ZINC-D3"
            },
            "RITE AID CORPORATION": {
              "RA CA/MG/ZN/CU": "RA CA/MG/ZN/CU"
            },
            "SCHWABE NORTH AMERICA": {
              "CALCIUM & VIT D3 BONE HEALTH": "CALCIUM & VIT D3 BONE HEALTH"
            },
            "THERALOGIX": {
              "PROSTEON": "PROSTEON",
              "THERACAL D2000": "THERACAL D2000",
              "THERACAL D4000": "THERACAL D4000",
              "THERACAL RAPID REPLETION": "THERACAL RAPID REPLETION"
            },
            "WAL-MART": {
              "CALCIUM-MAGNESIUM-ZINC-D3": "CALCIUM-MAGNESIUM-ZINC-D3"
            },
            "WALGREENS": {
              "BONE DENSITY BUILDER": "BONE DENSITY BUILDER",
              "CALCIUM CITRATE PLUS": "CALCIUM CITRATE PLUS",
              "CALCIUM CITRATE PLUS/MAGNESIUM": "CALCIUM CITRATE PLUS/MAGNESIUM",
              "CALCIUM CITRATE-MAG-MINERALS": "CALCIUM CITRATE-MAG-MINERALS"
            },
            "WHOLE LIFE NUTRITIONAL SUPP": {
              "CAL-MAG ZINC II": "CAL-MAG ZINC II",
              "CAL-MAG ZINC III": "CAL-MAG ZINC III"
            },
            "WINDMILL CONSUMER PRODUCTS": {
              "MULTISOURCE CALCIUM MAG/D": "MULTISOURCE CALCIUM MAG/D"
            }
          }
        },
        "Phosphate": {
          "Phosphate": {
            "AMERICAN HEALTH PACKAGING": {
              "PHOSPHA 250 NEUTRAL": "PHOSPHA 250 NEUTRAL"
            },
            "AMERICAN REGENT": {
              "SODIUM PHOSPHATES": "SODIUM PHOSPHATES"
            },
            "AVKARE": {
              "AV-PHOS 250 NEUTRAL": "AV-PHOS 250 NEUTRAL"
            },
            "BEACH": {
              "K-PHOS": "K-PHOS",
              "K-PHOS-NEUTRAL": "K-PHOS-NEUTRAL"
            },
            "BROOKFIELD PHARMACEUTICALS": {
              "PHOSPHOROUS": "PHOSPHOROUS",
              "PHOSPHORUS SUPPLEMENT": "PHOSPHORUS SUPPLEMENT"
            },
            "CMP PHARMA": {
              "POTASSIUM PHOSPHATES(71 MEQ K)": "POTASSIUM PHOSPHATES(71 MEQ K)"
            },
            "CYPRESS PHARMACEUTICAL": {
              "PHOS-NAK": "PHOS-NAK"
            },
            "FRESENIUS KABI USA": {
              "GLYCOPHOS": "GLYCOPHOS",
              "POTASSIUM PHOSPHATES": "POTASSIUM PHOSPHATES",
              "POTASSIUM PHOSPHATES(66 MEQ K)": "POTASSIUM PHOSPHATES(66 MEQ K)",
              "SODIUM PHOSPHATES": "SODIUM PHOSPHATES"
            },
            "HOSPIRA": {
              "POTASSIUM PHOSPHATES": "POTASSIUM PHOSPHATES",
              "SODIUM PHOSPHATES": "SODIUM PHOSPHATES"
            },
            "PATRIN PHARMA": {
              "PHOSPHO-TRIN 250 NEUTRAL": "PHOSPHO-TRIN 250 NEUTRAL"
            },
            "PHARMEDIUM SERVICES": {
              "POTASSIUM PHOSPHATE-NACL": "POTASSIUM PHOSPHATE-NACL",
              "POTASSIUM PHOSPHATES-DEXTROSE": "POTASSIUM PHOSPHATES-DEXTROSE",
              "POTASSIUM PHOSPHATES-NACL": "POTASSIUM PHOSPHATES-NACL",
              "SODIUM PHOSPHATE-NACL": "SODIUM PHOSPHATE-NACL",
              "SODIUM PHOSPHATES-DEXTROSE": "SODIUM PHOSPHATES-DEXTROSE"
            },
            "RISING PHARMACEUTICALS": {
              "PHOSPHA 250 NEUTRAL": "PHOSPHA 250 NEUTRAL"
            },
            "RUGBY LABORATORIES": {
              "SODIUM, POTASSIUM, PHOSPHORUS": "SODIUM, POTASSIUM, PHOSPHORUS"
            },
            "VIRTUS PHARMACEUTICALS": {
              "VIRT-PHOS 250 NEUTRAL": "VIRT-PHOS 250 NEUTRAL"
            },
            "VIRTUS PHARMACEUTICALS OPCO": {
              "VIRT-PHOS 250 NEUTRAL": "VIRT-PHOS 250 NEUTRAL"
            }
          }
        },
        "Potassium": {
          "Potassium": {
            "21ST CENTURY HEALTHCARE": {
              "POTASSIUM GLUCONATE": "POTASSIUM GLUCONATE"
            },
            "A-S MEDICATION SOLUTIONS": {
              "POTASSIUM CHLORIDE CRYS ER": "POTASSIUM CHLORIDE CRYS ER",
              "POTASSIUM CHLORIDE ER": "POTASSIUM CHLORIDE ER"
            },
            "ABBVIE": {
              "K-TAB": "K-TAB"
            },
            "ACTAVIS PHARMA": {
              "POTASSIUM CHLORIDE CRYS ER": "POTASSIUM CHLORIDE CRYS ER",
              "POTASSIUM CHLORIDE ER": "POTASSIUM CHLORIDE ER"
            },
            "ADARE PHARMACEUTICALS": {
              "POTASSIUM CHLORIDE ER": "POTASSIUM CHLORIDE ER"
            },
            "AIDAREX PHARMACEUTICALS": {
              "POTASSIUM CHLORIDE CRYS ER": "POTASSIUM CHLORIDE CRYS ER",
              "POTASSIUM CHLORIDE ER": "POTASSIUM CHLORIDE ER"
            },
            "AMERICAN HEALTH PACKAGING": {
              "POTASSIUM CHLORIDE": "POTASSIUM CHLORIDE",
              "POTASSIUM CHLORIDE CRYS ER": "POTASSIUM CHLORIDE CRYS ER",
              "POTASSIUM CHLORIDE ER": "POTASSIUM CHLORIDE ER"
            },
            "AMNEAL PHARMACEUTICALS": {
              "POTASSIUM CHLORIDE": "POTASSIUM CHLORIDE",
              "POTASSIUM CHLORIDE CRYS ER": "POTASSIUM CHLORIDE CRYS ER",
              "POTASSIUM CHLORIDE ER": "POTASSIUM CHLORIDE ER"
            },
            "APACE PACKAGING": {
              "KLOR-CON M10": "KLOR-CON M10",
              "KLOR-CON M20": "KLOR-CON M20"
            },
            "APHENA PHARMA SOLUTIONS": {
              "KLOR-CON": "KLOR-CON",
              "KLOR-CON 10": "KLOR-CON 10",
              "KLOR-CON M10": "KLOR-CON M10",
              "KLOR-CON M20": "KLOR-CON M20",
              "POTASSIUM CHLORIDE CRYS ER": "POTASSIUM CHLORIDE CRYS ER",
              "POTASSIUM CHLORIDE ER": "POTASSIUM CHLORIDE ER"
            },
            "APOTEX": {
              "POTASSIUM CHLORIDE": "POTASSIUM CHLORIDE"
            },
            "APOTHECA SUPPLY": {
              "POTASSIUM BICARBONATE": "POTASSIUM BICARBONATE"
            },
            "ATHENEX PHARMACEUTICAL": {
              "POTASSIUM CHLORIDE": "POTASSIUM CHLORIDE"
            },
            "ATLANTIC BIOLOGICALS": {
              "POTASSIUM CHLORIDE": "POTASSIUM CHLORIDE"
            },
            "AUROBINDO PHARMA": {
              "POTASSIUM CHLORIDE ER": "POTASSIUM CHLORIDE ER"
            },
            "AVKARE": {
              "POTASSIUM CHLORIDE CRYS ER": "POTASSIUM CHLORIDE CRYS ER",
              "POTASSIUM CHLORIDE ER": "POTASSIUM CHLORIDE ER"
            },
            "AVPAK": {
              "POTASSIUM CHLORIDE": "POTASSIUM CHLORIDE",
              "POTASSIUM CHLORIDE ER": "POTASSIUM CHLORIDE ER"
            },
            "B. BRAUN MEDICAL": {
              "POTASSIUM CHLORIDE": "POTASSIUM CHLORIDE"
            },
            "BASIC DRUGS": {
              "POTASSIUM GLUCONATE": "POTASSIUM GLUCONATE"
            },
            "BASIC ORGANICS": {
              "POTASSIUM": "POTASSIUM"
            },
            "BAXTER HEALTHCARE CORP": {
              "POTASSIUM CHLORIDE": "POTASSIUM CHLORIDE"
            },
            "BERGEN BRUNSWIG": {
              "GNP POTASSIUM": "GNP POTASSIUM",
              "GNP POTASSIUM GLUCONATE": "GNP POTASSIUM GLUCONATE"
            },
            "BIO-TECH": {
              "K-99": "K-99",
              "K-BICARB": "K-BICARB"
            },
            "BLENHEIM PHARMACAL": {
              "POTASSIUM CHLORIDE CRYS ER": "POTASSIUM CHLORIDE CRYS ER",
              "POTASSIUM CHLORIDE ER": "POTASSIUM CHLORIDE ER"
            },
            "BLUE POINT LABORATORIES": {
              "POTASSIUM CHLORIDE CRYS ER": "POTASSIUM CHLORIDE CRYS ER",
              "POTASSIUM CHLORIDE ER": "POTASSIUM CHLORIDE ER"
            },
            "BPI LABS LLC": {
              "POTASSIUM CHLORIDE": "POTASSIUM CHLORIDE"
            },
            "BRYANT RANCH PREPACK": {
              "POTASSIUM CHLORIDE": "POTASSIUM CHLORIDE",
              "POTASSIUM CHLORIDE CRYS ER": "POTASSIUM CHLORIDE CRYS ER",
              "POTASSIUM CHLORIDE ER": "POTASSIUM CHLORIDE ER"
            },
            "CHAIN DRUG CONSORTIUM": {
              "POTASSIUM": "POTASSIUM"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC POTASSIUM": "QC POTASSIUM"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "POTASSIUM CHLORIDE CRYS ER": "POTASSIUM CHLORIDE CRYS ER"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS POTASSIUM GLUCONATE": "CVS POTASSIUM GLUCONATE"
            },
            "DIRECT RX": {
              "POTASSIUM CHLORIDE ER": "POTASSIUM CHLORIDE ER"
            },
            "EPIC PHARMA": {
              "POTASSIUM CHLORIDE": "POTASSIUM CHLORIDE"
            },
            "EQUALINE": {
              "EQL POTASSIUM GLUCONATE": "EQL POTASSIUM GLUCONATE"
            },
            "EXELA PHARMA SCIENCES": {
              "POTASSIUM ACETATE": "POTASSIUM ACETATE"
            },
            "FAGRON": {
              "POTASSIUM CHLORIDE": "POTASSIUM CHLORIDE"
            },
            "FREEDA VITAMINS": {
              "CHELATED POTASSIUM": "CHELATED POTASSIUM",
              "POTASSIUM GLUCONATE": "POTASSIUM GLUCONATE"
            },
            "FREEDOM PHARMACEUTICALS": {
              "POTASSIUM CHLORIDE": "POTASSIUM CHLORIDE"
            },
            "FRESENIUS KABI USA": {
              "POTASSIUM ACETATE": "POTASSIUM ACETATE",
              "POTASSIUM CHLORIDE": "POTASSIUM CHLORIDE"
            },
            "GENERAL NUTRITION CORP": {
              "GNP POTASSIUM GLUCONATE": "GNP POTASSIUM GLUCONATE"
            },
            "GENUS LIFESCIENCES": {
              "POTASSIUM CHLORIDE": "POTASSIUM CHLORIDE"
            },
            "GLENMARK PHARMACEUTICALS": {
              "POTASSIUM CHLORIDE CRYS ER": "POTASSIUM CHLORIDE CRYS ER",
              "POTASSIUM CHLORIDE ER": "POTASSIUM CHLORIDE ER"
            },
            "GRANULES PHARMACEUTICALS": {
              "POTASSIUM CHLORIDE ER": "POTASSIUM CHLORIDE ER"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "POTASSIUM CHLORIDE CRYS ER": "POTASSIUM CHLORIDE CRYS ER",
              "POTASSIUM CHLORIDE ER": "POTASSIUM CHLORIDE ER"
            },
            "HOSPIRA": {
              "POTASSIUM ACETATE": "POTASSIUM ACETATE",
              "POTASSIUM CHLORIDE": "POTASSIUM CHLORIDE"
            },
            "HUMCO": {
              "POTASSIUM CHLORIDE": "POTASSIUM CHLORIDE"
            },
            "ICU MEDICAL": {
              "POTASSIUM CHLORIDE": "POTASSIUM CHLORIDE"
            },
            "J R CARLSON LABORATORIES": {
              "POTASSIUM GLUCONATE": "POTASSIUM GLUCONATE"
            },
            "LABORATOIRE AGUETTANT": {
              "POTASSIUM CHLORIDE PROAMP": "POTASSIUM CHLORIDE PROAMP"
            },
            "LASER PHARMACEUTICALS": {
              "K-SOL": "K-SOL"
            },
            "LEADER BRAND PRODUCTS": {
              "POTASSIUM": "POTASSIUM",
              "POTASSIUM GLUCONATE": "POTASSIUM GLUCONATE"
            },
            "LETCO MEDICAL": {
              "POTASSIUM BICARBONATE": "POTASSIUM BICARBONATE",
              "POTASSIUM CHLORIDE": "POTASSIUM CHLORIDE"
            },
            "LEVERAGE PHARMA": {
              "K-PRIME": "K-PRIME"
            },
            "LIBERTY PHARMACEUTICAL": {
              "POTASSIUM CHLORIDE CRYS ER": "POTASSIUM CHLORIDE CRYS ER",
              "POTASSIUM CHLORIDE ER": "POTASSIUM CHLORIDE ER"
            },
            "LUMARA HEALTH": {
              "MICRO-K": "MICRO-K"
            },
            "LUPIN PHARMACEUTICALS": {
              "POTASSIUM CHLORIDE": "POTASSIUM CHLORIDE",
              "POTASSIUM CHLORIDE CRYS ER": "POTASSIUM CHLORIDE CRYS ER",
              "POTASSIUM CHLORIDE ER": "POTASSIUM CHLORIDE ER"
            },
            "MAGNO-HUMPHRIES LABORATORIES": {
              "POTASSIUM GLUCONATE": "POTASSIUM GLUCONATE"
            },
            "MAJOR PHARMACEUTICALS": {
              "K-VESCENT": "K-VESCENT",
              "POTASSIUM": "POTASSIUM",
              "POTASSIUM CHLORIDE ER": "POTASSIUM CHLORIDE ER"
            },
            "MASON VITAMINS": {
              "POTASSIUM GLUCONATE": "POTASSIUM GLUCONATE",
              "POTASSIUM GLUCONATE ER": "POTASSIUM GLUCONATE ER"
            },
            "MAYNE PHARMA": {
              "POTASSIUM CHLORIDE": "POTASSIUM CHLORIDE"
            },
            "MCKESSON": {
              "HM POTASSIUM": "HM POTASSIUM"
            },
            "MCKESSON SUNMARK": {
              "SM POTASSIUM": "SM POTASSIUM"
            },
            "MEDICINE SHOPPE": {
              "POTASSIUM": "POTASSIUM"
            },
            "MEDISCA": {
              "POTASSIUM CHLORIDE": "POTASSIUM CHLORIDE"
            },
            "MEDVANTX": {
              "POTASSIUM CHLORIDE ER": "POTASSIUM CHLORIDE ER"
            },
            "MILLER": {
              "POTASSIUM": "POTASSIUM"
            },
            "MYLAN": {
              "POTASSIUM CHLORIDE ER": "POTASSIUM CHLORIDE ER"
            },
            "MYLAN INSTITUTIONAL": {
              "POTASSIUM CHLORIDE ER": "POTASSIUM CHLORIDE ER"
            },
            "NAT-RUL HEALTH PRODUCTS": {
              "CHELATED POTASSIUM": "CHELATED POTASSIUM",
              "POTASSIUM": "POTASSIUM"
            },
            "NATIONAL VITAMIN": {
              "POTASSIUM GLUCONATE": "POTASSIUM GLUCONATE"
            },
            "NATURES BOUNTY": {
              "POTASSIUM GLUCONATE": "POTASSIUM GLUCONATE"
            },
            "NEPHRON STERILE COMPOUNDING": {
              "POTASSIUM CHLORIDE": "POTASSIUM CHLORIDE"
            },
            "NOMAX INC": {
              "EFFER-K": "EFFER-K"
            },
            "NOVAPLUS/HOSPIRA": {
              "POTASSIUM ACETATE": "POTASSIUM ACETATE"
            },
            "NOW HEALTH GROUP": {
              "POTASSIUM CITRATE": "POTASSIUM CITRATE"
            },
            "PACK PHARMACEUTICALS, LLC": {
              "POTASSIUM CHLORIDE": "POTASSIUM CHLORIDE"
            },
            "PAR PHARMACEUTICAL": {
              "POTASSIUM CHLORIDE ER": "POTASSIUM CHLORIDE ER"
            },
            "PAR PHARMACEUTICALS": {
              "K-EFFERVESCENT": "K-EFFERVESCENT",
              "POTASSIUM CHLORIDE": "POTASSIUM CHLORIDE"
            },
            "PCCA": {
              "POTASSIUM BICARBONATE": "POTASSIUM BICARBONATE",
              "POTASSIUM CHLORIDE": "POTASSIUM CHLORIDE"
            },
            "PDRX PHARMACEUTICAL": {
              "K-TAB": "K-TAB",
              "KLOR-CON 10": "KLOR-CON 10",
              "POTASSIUM CHLORIDE CRYS ER": "POTASSIUM CHLORIDE CRYS ER",
              "POTASSIUM CHLORIDE ER": "POTASSIUM CHLORIDE ER"
            },
            "PDRX PHARMACEUTICALS": {
              "POTASSIUM CHLORIDE CRYS ER": "POTASSIUM CHLORIDE CRYS ER",
              "POTASSIUM CHLORIDE ER": "POTASSIUM CHLORIDE ER"
            },
            "PERRIGO": {
              "POTASSIUM CHLORIDE ER": "POTASSIUM CHLORIDE ER"
            },
            "PHARMACEUTICAL ASSOCIATES": {
              "POTASSIUM CHLORIDE": "POTASSIUM CHLORIDE"
            },
            "PHARMASSURE, INC.": {
              "POTASSIUM GLUCONATE": "POTASSIUM GLUCONATE"
            },
            "PHARMAVITE": {
              "POTASSIUM GLUCONATE": "POTASSIUM GLUCONATE"
            },
            "PHARMEDIUM SERVICES": {
              "POTASSIUM ACETATE-NACL": "POTASSIUM ACETATE-NACL"
            },
            "PHARMPAK": {
              "POTASSIUM CHLORIDE ER": "POTASSIUM CHLORIDE ER"
            },
            "PREFERRED PHARMACEUTICALS": {
              "POTASSIUM CHLORIDE CRYS ER": "POTASSIUM CHLORIDE CRYS ER",
              "POTASSIUM CHLORIDE ER": "POTASSIUM CHLORIDE ER"
            },
            "PRESCRIPTION SOLUTIONS, INC.": {
              "POTASSIUM CHLORIDE CRYS ER": "POTASSIUM CHLORIDE CRYS ER",
              "POTASSIUM CHLORIDE ER": "POTASSIUM CHLORIDE ER"
            },
            "PRINCETON RESEARCH": {
              "POTASSIUM GLUCONATE": "POTASSIUM GLUCONATE"
            },
            "PROFICIENT RX": {
              "POTASSIUM CHLORIDE CRYS ER": "POTASSIUM CHLORIDE CRYS ER"
            },
            "QUALITY CARE": {
              "POTASSIUM CHLORIDE CRYS ER": "POTASSIUM CHLORIDE CRYS ER",
              "POTASSIUM CHLORIDE ER": "POTASSIUM CHLORIDE ER"
            },
            "QUALITY CARE PRODUCTS": {
              "POTASSIUM CHLORIDE CRYS ER": "POTASSIUM CHLORIDE CRYS ER",
              "POTASSIUM CHLORIDE ER": "POTASSIUM CHLORIDE ER"
            },
            "REXALL SUNDOWN": {
              "POTASSIUM": "POTASSIUM",
              "POTASSIUM GLUCONATE": "POTASSIUM GLUCONATE"
            },
            "RITE AID CORPORATION": {
              "RA POTASSIUM GLUCONATE": "RA POTASSIUM GLUCONATE"
            },
            "SANDOZ": {
              "KLOR-CON": "KLOR-CON",
              "KLOR-CON 10": "KLOR-CON 10",
              "KLOR-CON M10": "KLOR-CON M10",
              "KLOR-CON M15": "KLOR-CON M15",
              "KLOR-CON M20": "KLOR-CON M20",
              "KLOR-CON SPRINKLE": "KLOR-CON SPRINKLE",
              "KLOR-CON/EF": "KLOR-CON/EF",
              "POTASSIUM CHLORIDE CRYS ER": "POTASSIUM CHLORIDE CRYS ER",
              "POTASSIUM CHLORIDE ER": "POTASSIUM CHLORIDE ER"
            },
            "SKY PACKAGING": {
              "POTASSIUM CHLORIDE CRYS ER": "POTASSIUM CHLORIDE CRYS ER"
            },
            "SLATE RUN PHARMACEUTICALS": {
              "POTASSIUM CHLORIDE ER": "POTASSIUM CHLORIDE ER"
            },
            "SPECTRUM": {
              "POTASSIUM BICARBONATE": "POTASSIUM BICARBONATE",
              "POTASSIUM CHLORIDE": "POTASSIUM CHLORIDE"
            },
            "ST MARY'S MPP": {
              "POTASSIUM CHLORIDE CRYS ER": "POTASSIUM CHLORIDE CRYS ER",
              "POTASSIUM CHLORIDE ER": "POTASSIUM CHLORIDE ER"
            },
            "STERRX": {
              "POTASSIUM CHLORIDE": "POTASSIUM CHLORIDE"
            },
            "STRIDES PHARMA": {
              "POTASSIUM CHLORIDE ER": "POTASSIUM CHLORIDE ER"
            },
            "SUN PHARMACEUTICALS": {
              "POTASSIUM CHLORIDE CRYS ER": "POTASSIUM CHLORIDE CRYS ER",
              "POTASSIUM CHLORIDE ER": "POTASSIUM CHLORIDE ER"
            },
            "THE KEY COMPANY": {
              "POTASSIMIN": "POTASSIMIN",
              "POTASSIUM": "POTASSIUM"
            },
            "TOWER LABORATORIES": {
              "POTASSIUM BICARBONATE": "POTASSIUM BICARBONATE"
            },
            "UPSHER-SMITH": {
              "KLOR-CON": "KLOR-CON",
              "KLOR-CON 10": "KLOR-CON 10",
              "KLOR-CON M10": "KLOR-CON M10",
              "KLOR-CON M15": "KLOR-CON M15",
              "KLOR-CON M20": "KLOR-CON M20",
              "KLOR-CON/EF": "KLOR-CON/EF",
              "POTASSIUM CHLORIDE CRYS ER": "POTASSIUM CHLORIDE CRYS ER",
              "POTASSIUM CHLORIDE ER": "POTASSIUM CHLORIDE ER"
            },
            "VA CMOP DALLAS": {
              "POTASSIUM CHLORIDE CRYS ER": "POTASSIUM CHLORIDE CRYS ER",
              "POTASSIUM CHLORIDE ER": "POTASSIUM CHLORIDE ER"
            },
            "VIRTUS PHARMACEUTICALS": {
              "POTASSIUM CHLORIDE": "POTASSIUM CHLORIDE"
            },
            "VIRTUS PHARMACEUTICALS OPCO": {
              "POTASSIUM CHLORIDE": "POTASSIUM CHLORIDE"
            },
            "VISTAPHARM INC.": {
              "POTASSIUM CHLORIDE": "POTASSIUM CHLORIDE"
            },
            "VITRUVIAS THERAPEUTICS": {
              "POTASSIUM CHLORIDE ER": "POTASSIUM CHLORIDE ER"
            },
            "WAL-MART": {
              "POTASSIUM GLUCONATE": "POTASSIUM GLUCONATE"
            },
            "WALGREENS": {
              "POTASSIUM GLUCONATE": "POTASSIUM GLUCONATE"
            },
            "WINDMILL CONSUMER PRODUCTS": {
              "POTASSIUM GLUCONATE": "POTASSIUM GLUCONATE"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "POTASSIUM CHLORIDE ER": "POTASSIUM CHLORIDE ER"
            }
          },
          "Potassium Combinations": {
            "BIO-TECH": {
              "K-III": "K-III"
            },
            "NOMAX INC": {
              "EFFER-K": "EFFER-K"
            },
            "PAR PHARMACEUTICALS": {
              "EFFERVESCENT POT CHLORIDE": "EFFERVESCENT POT CHLORIDE"
            },
            "TOWER LABORATORIES": {
              "POT BICARB-POT CHLORIDE": "POT BICARB-POT CHLORIDE"
            }
          }
        },
        "Sodium": {
          "Sodium": {
            "A-S MEDICATION SOLUTIONS": {
              "SODIUM CHLORIDE": "SODIUM CHLORIDE"
            },
            "AMERICAN HEALTH PACKAGING": {
              "SODIUM CHLORIDE": "SODIUM CHLORIDE"
            },
            "AMERICAN PHARMA INGREDIENTS": {
              "SODIUM CHLORIDE": "SODIUM CHLORIDE"
            },
            "AMERICAN REGENT": {
              "SODIUM CHLORIDE (PF)": "SODIUM CHLORIDE (PF)"
            },
            "AMSINO MEDICAL USA": {
              "SODIUM CHLORIDE FLUSH": "SODIUM CHLORIDE FLUSH"
            },
            "B. BRAUN MEDICAL": {
              "SODIUM CHLORIDE": "SODIUM CHLORIDE"
            },
            "BAXTER HEALTHCARE CORP": {
              "SODIUM CHLORIDE": "SODIUM CHLORIDE"
            },
            "BD MEDICAL SURGICAL SYSTEMS": {
              "BD POSIFLUSH": "BD POSIFLUSH",
              "NORMAL SALINE FLUSH": "NORMAL SALINE FLUSH",
              "SODIUM CHLORIDE FLUSH": "SODIUM CHLORIDE FLUSH"
            },
            "BECTON DICKINSON AND COMPANY": {
              "SODIUM CHLORIDE": "SODIUM CHLORIDE"
            },
            "CARDINAL HEALTH REPACKAGING SO": {
              "SODIUM CHLORIDE": "SODIUM CHLORIDE"
            },
            "CONSOLIDATED MIDLAND CORP": {
              "SODIUM CHLORIDE": "SODIUM CHLORIDE"
            },
            "COVIDIEN MEDICAL SUPPLIES": {
              "KENDALL SODIUM CHLORIDE FLUSH": "KENDALL SODIUM CHLORIDE FLUSH",
              "MONOJECT FLUSH SYRINGE": "MONOJECT FLUSH SYRINGE",
              "MONOJECT SODIUM CHLORIDE FLUSH": "MONOJECT SODIUM CHLORIDE FLUSH",
              "SODIUM CHLORIDE FLUSH": "SODIUM CHLORIDE FLUSH"
            },
            "ENOVACHEM MANUFACTURING": {
              "SODIUM CHLORIDE (PF)": "SODIUM CHLORIDE (PF)"
            },
            "FAGRON": {
              "SODIUM CHLORIDE": "SODIUM CHLORIDE"
            },
            "FREEDOM PHARMACEUTICALS": {
              "SODIUM CHLORIDE": "SODIUM CHLORIDE"
            },
            "FRESENIUS KABI USA": {
              "SODIUM CHLORIDE": "SODIUM CHLORIDE",
              "SODIUM CHLORIDE (PF)": "SODIUM CHLORIDE (PF)"
            },
            "GENDOSE PHARMACEUTICALS": {
              "SODIUM CHLORIDE": "SODIUM CHLORIDE"
            },
            "GRAXCELL PHARMACEUTICAL": {
              "SODIUM CHLORIDE": "SODIUM CHLORIDE"
            },
            "GRIFOLS USA": {
              "SODIUM CHLORIDE": "SODIUM CHLORIDE"
            },
            "GUERBET": {
              "SODIUM CHLORIDE FLUSH": "SODIUM CHLORIDE FLUSH"
            },
            "HIKMA": {
              "SODIUM CHLORIDE (PF)": "SODIUM CHLORIDE (PF)"
            },
            "HOSPIRA": {
              "SODIUM CHLORIDE": "SODIUM CHLORIDE",
              "SODIUM CHLORIDE (PF)": "SODIUM CHLORIDE (PF)"
            },
            "ICU MEDICAL": {
              "SODIUM CHLORIDE": "SODIUM CHLORIDE"
            },
            "KALCHEM INTERNATIONAL": {
              "SODIUM CHLORIDE": "SODIUM CHLORIDE"
            },
            "LANNETT": {
              "SODIUM CHLORIDE": "SODIUM CHLORIDE"
            },
            "LETCO MEDICAL": {
              "SODIUM CHLORIDE": "SODIUM CHLORIDE"
            },
            "MEDEFIL, INC": {
              "NORMAL SALINE FLUSH": "NORMAL SALINE FLUSH",
              "SODIUM CHLORIDE (PF)": "SODIUM CHLORIDE (PF)"
            },
            "MEDISCA": {
              "SODIUM CHLORIDE": "SODIUM CHLORIDE"
            },
            "MEDLINE INDUSTRIES": {
              "SALINE FLUSH": "SALINE FLUSH",
              "SALINE FLUSH ZR": "SALINE FLUSH ZR",
              "SODIUM CHLORIDE FLUSH": "SODIUM CHLORIDE FLUSH",
              "SWABFLUSH SALINE FLUSH": "SWABFLUSH SALINE FLUSH"
            },
            "NEPHRON PHARMACEUTICALS CORP.": {
              "SODIUM CHLORIDE": "SODIUM CHLORIDE"
            },
            "NEPHRON STERILE COMPOUNDING": {
              "SODIUM CHLORIDE": "SODIUM CHLORIDE"
            },
            "NEXTGEN PHARMACEUTICALS": {
              "SODIUM CHLORIDE": "SODIUM CHLORIDE"
            },
            "PCCA": {
              "SODIUM CHLORIDE": "SODIUM CHLORIDE"
            },
            "RX PHARMA PACK": {
              "LIQUIVIDA HYDRATION": "LIQUIVIDA HYDRATION"
            },
            "SAFECOR HEALTH": {
              "SODIUM CHLORIDE": "SODIUM CHLORIDE"
            },
            "SPECTRUM": {
              "SODIUM CHLORIDE": "SODIUM CHLORIDE"
            },
            "STERRX": {
              "SODIUM CHLORIDE": "SODIUM CHLORIDE"
            },
            "WESTMINSTER PHARMACEUTICALS": {
              "SODIUM CHLORIDE": "SODIUM CHLORIDE"
            }
          }
        },
        "Trace Minerals": {
          "Trace Mineral Combinations": {
            "AMERICAN REGENT": {
              "MULTITRACE-4": "MULTITRACE-4",
              "MULTITRACE-4 CONCENTRATE": "MULTITRACE-4 CONCENTRATE",
              "MULTITRACE-4 NEONATAL": "MULTITRACE-4 NEONATAL",
              "MULTITRACE-4 PEDIATRIC": "MULTITRACE-4 PEDIATRIC",
              "MULTITRACE-5": "MULTITRACE-5",
              "MULTITRACE-5 CONCENTRATE": "MULTITRACE-5 CONCENTRATE",
              "TRACE ELEMENTS 4/PEDIATRIC": "TRACE ELEMENTS 4/PEDIATRIC",
              "TRALEMENT": "TRALEMENT"
            },
            "FREEDA VITAMINS": {
              "CRO-MAN-ZIN": "CRO-MAN-ZIN"
            },
            "FRESENIUS KABI USA": {
              "ADDAMEL N": "ADDAMEL N",
              "PEDITRACE": "PEDITRACE"
            },
            "RX PHARMA PACK": {
              "THE LIQUILIFT TRACE": "THE LIQUILIFT TRACE"
            },
            "WALGREENS": {
              "SELENIUM-YEAST": "SELENIUM-YEAST"
            }
          },
          "Trace Minerals": {
            "21ST CENTURY HEALTHCARE": {
              "CHROMIUM PICOLINATE": "CHROMIUM PICOLINATE",
              "SELENIUM": "SELENIUM"
            },
            "AMERICAN REGENT": {
              "SELENIOUS ACID": "SELENIOUS ACID",
              "SELENIUM": "SELENIUM"
            },
            "BASIC DRUGS": {
              "SELENIUM": "SELENIUM"
            },
            "BASIC ORGANICS": {
              "CHROMIUM PICOLINATE": "CHROMIUM PICOLINATE",
              "SELENIUM": "SELENIUM"
            },
            "BIO-TECH": {
              "BORON": "BORON",
              "CESIUM": "CESIUM",
              "CESIUM 1000": "CESIUM 1000",
              "CESIUM 500": "CESIUM 500",
              "CR-GTF": "CR-GTF",
              "CRM": "CRM",
              "CU-25": "CU-25",
              "CU-5": "CU-5",
              "GE-150": "GE-150",
              "MOLYBDENUM": "MOLYBDENUM",
              "SE-100": "SE-100",
              "VANADIUM": "VANADIUM"
            },
            "BIOTICS RESEARCH": {
              "AQUEOUS SELENIUM": "AQUEOUS SELENIUM"
            },
            "BRYANT RANCH PREPACK": {
              "CHROMIUM PICOLINATE": "CHROMIUM PICOLINATE"
            },
            "CHAIN DRUG CONSORTIUM": {
              "CHROMIUM PICOLINATE": "CHROMIUM PICOLINATE",
              "SELENIUM": "SELENIUM"
            },
            "CHRONOHEALTH": {
              "CHROMIUM GTF": "CHROMIUM GTF"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS SELENIUM": "CVS SELENIUM"
            },
            "DIRECT RX": {
              "CHROMIUM PICOLINATE": "CHROMIUM PICOLINATE"
            },
            "EQUALINE": {
              "EQL CHROMIUM PICOLINATE PLUS": "EQL CHROMIUM PICOLINATE PLUS"
            },
            "FAGRON": {
              "COPPER SULFATE": "COPPER SULFATE"
            },
            "FREEDA VITAMINS": {
              "CHROMIUM": "CHROMIUM",
              "COPPER GLUCONATE": "COPPER GLUCONATE",
              "OCEANIC SELENIUM": "OCEANIC SELENIUM"
            },
            "HOSPIRA": {
              "CHROMIC CHLORIDE": "CHROMIC CHLORIDE",
              "CUPRIC CHLORIDE": "CUPRIC CHLORIDE"
            },
            "INTEGRATIVE THERAPEUTICS": {
              "SELENIUM": "SELENIUM",
              "VITALINE SELENIUM": "VITALINE SELENIUM"
            },
            "J R CARLSON LABORATORIES": {
              "SELENIUM": "SELENIUM"
            },
            "LEADER BRAND PRODUCTS": {
              "CHROMIUM PICOLINATE": "CHROMIUM PICOLINATE"
            },
            "LEINER HEALTH PRODUCTS": {
              "YL CHROMIUM PICOLINATE": "YL CHROMIUM PICOLINATE"
            },
            "MAGNO-HUMPHRIES LABORATORIES": {
              "CHROMIUM PICOLINATE": "CHROMIUM PICOLINATE",
              "SELENIUM": "SELENIUM"
            },
            "MAJOR PHARMACEUTICALS": {
              "CHROMIUM": "CHROMIUM",
              "SELENIUM": "SELENIUM",
              "SELENIUM ER": "SELENIUM ER"
            },
            "MASON VITAMINS": {
              "CHROMIUM GTF": "CHROMIUM GTF",
              "CHROMIUM PICOLINATE": "CHROMIUM PICOLINATE",
              "SELENIUM": "SELENIUM"
            },
            "MCKESSON": {
              "HM SELENIUM": "HM SELENIUM"
            },
            "MCKESSON SUNMARK": {
              "SM CHROMIUM PICOLINATE": "SM CHROMIUM PICOLINATE",
              "SM SELENIUM": "SM SELENIUM"
            },
            "MILLER": {
              "CHROMIUM ASPARTATE": "CHROMIUM ASPARTATE",
              "CR-PLUS": "CR-PLUS",
              "NIA-CHROM": "NIA-CHROM",
              "SE ASPARTATE": "SE ASPARTATE",
              "SE-PLUS PROTEIN": "SE-PLUS PROTEIN"
            },
            "NAT-RUL HEALTH PRODUCTS": {
              "CHROMIUM GTF": "CHROMIUM GTF",
              "CHROMIUM PICOLINATE": "CHROMIUM PICOLINATE",
              "SELENIUM": "SELENIUM"
            },
            "NATIONAL VITAMIN": {
              "CHROMIUM PICOLINATE": "CHROMIUM PICOLINATE",
              "SELENIUM": "SELENIUM"
            },
            "NATURES BOUNTY": {
              "CHROMIUM PICOLINATE": "CHROMIUM PICOLINATE",
              "CHROMIUM PICOLINATE ULTRA": "CHROMIUM PICOLINATE ULTRA",
              "SELENIUM": "SELENIUM"
            },
            "NOVAPLUS/HOSPIRA": {
              "CHROMIC CHLORIDE": "CHROMIC CHLORIDE"
            },
            "PCCA": {
              "CUPRIC SULFATE": "CUPRIC SULFATE"
            },
            "PDRX PHARMACEUTICAL": {
              "CHROMIUM PICOLINATE": "CHROMIUM PICOLINATE"
            },
            "PHARMASSURE, INC.": {
              "CHROMIUM PICOLINATE": "CHROMIUM PICOLINATE",
              "SELENIUM": "SELENIUM"
            },
            "PHARMAVITE": {
              "SELENIUM": "SELENIUM"
            },
            "PRINCETON RESEARCH": {
              "CHROMIUM PICOLINATE ULTRA": "CHROMIUM PICOLINATE ULTRA",
              "SELENIUM": "SELENIUM"
            },
            "REXALL SUNDOWN": {
              "CHROMIUM PICOLINATE": "CHROMIUM PICOLINATE",
              "CHROMIUM PICOLINATE MEGA": "CHROMIUM PICOLINATE MEGA",
              "CHROMIUM PICOLINATE ULTRA": "CHROMIUM PICOLINATE ULTRA",
              "SELENIUM": "SELENIUM"
            },
            "RITE AID CORPORATION": {
              "RA CHROMIUM PICOLINATE": "RA CHROMIUM PICOLINATE",
              "RA SELENIUM": "RA SELENIUM",
              "RA SELENIUM NATURAL": "RA SELENIUM NATURAL"
            },
            "RUGBY LABORATORIES": {
              "CHROMIUM PICOLINATE": "CHROMIUM PICOLINATE",
              "CHROMIUM PICOLINATE W/CALCIUM": "CHROMIUM PICOLINATE W/CALCIUM"
            },
            "SPECTRUM": {
              "CUPRIC SULFATE": "CUPRIC SULFATE"
            },
            "THE KEY COMPANY": {
              "CHROMACAPS": "CHROMACAPS",
              "COPPERMIN": "COPPERMIN",
              "SELENICAPS-200": "SELENICAPS-200",
              "SELENIMIN": "SELENIMIN",
              "SELENIMIN-200": "SELENIMIN-200"
            },
            "TWIN LABS": {
              "COPPER CAPS": "COPPER CAPS"
            },
            "VITALINE": {
              "BORON": "BORON",
              "CHROMIUM": "CHROMIUM",
              "CHROMIUM PICOLINATE": "CHROMIUM PICOLINATE"
            },
            "WAL-MART": {
              "CHROMIUM PICOLINATE": "CHROMIUM PICOLINATE",
              "SELENIUM": "SELENIUM"
            },
            "WALGREENS": {
              "CHROMIUM PICOLINATE": "CHROMIUM PICOLINATE",
              "SELENIUM": "SELENIUM"
            },
            "WINDMILL CONSUMER PRODUCTS": {
              "CHROMIUM PICOLINATE": "CHROMIUM PICOLINATE",
              "GTF CHROMIUM": "GTF CHROMIUM",
              "SELENIUM": "SELENIUM"
            }
          }
        },
        "Zinc": {
          "Zinc": {
            "21ST CENTURY HEALTHCARE": {
              "ZINC CHELATED": "ZINC CHELATED"
            },
            "ALTO": {
              "ZINC-220": "ZINC-220"
            },
            "AMERICAN REGENT": {
              "ZINC SULFATE": "ZINC SULFATE"
            },
            "AVPAK": {
              "ZINC CHELATED": "ZINC CHELATED"
            },
            "BASIC DRUGS": {
              "ZINC": "ZINC"
            },
            "BERGEN BRUNSWIG": {
              "GNP ZINC": "GNP ZINC",
              "GNP ZINC CHELATED": "GNP ZINC CHELATED"
            },
            "BIO-TECH": {
              "ZINC METHIONATE": "ZINC METHIONATE",
              "ZN-50": "ZN-50"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC ZINC": "QC ZINC"
            },
            "CHRONOHEALTH": {
              "ZINC": "ZINC"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS ZINC": "CVS ZINC"
            },
            "ENOVACHEM MANUFACTURING": {
              "IS-ZC 50": "IS-ZC 50"
            },
            "EQUALINE": {
              "EQL NATURAL ZINC": "EQL NATURAL ZINC",
              "EQL ZINC": "EQL ZINC"
            },
            "FAGRON": {
              "ZINC SULFATE HEPTAHYDRATE": "ZINC SULFATE HEPTAHYDRATE",
              "ZINC SULFATE MONOHYDRATE": "ZINC SULFATE MONOHYDRATE"
            },
            "FREEDA VITAMINS": {
              "CHELATED ZINC": "CHELATED ZINC",
              "ZINC": "ZINC",
              "ZINC CHELATED": "ZINC CHELATED",
              "ZINC GLUCONATE": "ZINC GLUCONATE"
            },
            "FREEDOM PHARMACEUTICALS": {
              "ZINC SULFATE HEPTAHYDRATE": "ZINC SULFATE HEPTAHYDRATE"
            },
            "GENDOSE PHARMACEUTICALS": {
              "ZINC SULFATE": "ZINC SULFATE"
            },
            "GERI-CARE": {
              "ZINC SULFATE": "ZINC SULFATE"
            },
            "HOSPIRA": {
              "ZINC CHLORIDE": "ZINC CHLORIDE"
            },
            "INTEGRATIVE THERAPEUTICS": {
              "VITALINE BUFFERED ZINC-220": "VITALINE BUFFERED ZINC-220"
            },
            "LEADER BRAND PRODUCTS": {
              "ZINC CHELATED": "ZINC CHELATED"
            },
            "LETCO MEDICAL": {
              "ZINC SULFATE HEPTAHYDRATE": "ZINC SULFATE HEPTAHYDRATE"
            },
            "MAGNO-HUMPHRIES LABORATORIES": {
              "ZINC": "ZINC"
            },
            "MAJOR PHARMACEUTICALS": {
              "ZINC GLUCONATE": "ZINC GLUCONATE",
              "ZINC SULFATE": "ZINC SULFATE"
            },
            "MASON VITAMINS": {
              "ZINC GLUCONATE": "ZINC GLUCONATE"
            },
            "MCKESSON": {
              "HM ZINC": "HM ZINC"
            },
            "MCKESSON SUNMARK": {
              "SM ZINC": "SM ZINC"
            },
            "MEDICINE SHOPPE": {
              "ZINC": "ZINC"
            },
            "MEDISCA": {
              "ZINC SULFATE": "ZINC SULFATE"
            },
            "MERICON": {
              "ORAZINC": "ORAZINC",
              "ZINC 15": "ZINC 15"
            },
            "MILLER": {
              "ELITE ZINC": "ELITE ZINC",
              "M2 ZINC-50": "M2 ZINC-50",
              "ZINC ASPARTATE": "ZINC ASPARTATE"
            },
            "NAT-RUL HEALTH PRODUCTS": {
              "MEGA ZINC-100": "MEGA ZINC-100",
              "ZINC": "ZINC",
              "ZINC PICOLINATE": "ZINC PICOLINATE"
            },
            "NATIONAL VITAMIN": {
              "ZINC GLUCONATE": "ZINC GLUCONATE"
            },
            "NATIONWIDE LABORATORIES": {
              "ZINC SULFATE": "ZINC SULFATE"
            },
            "NATURES BOUNTY": {
              "CHELATED ZINC": "CHELATED ZINC",
              "ZINC": "ZINC"
            },
            "PCCA": {
              "ZINC SULFATE HEPTAHYDRATE": "ZINC SULFATE HEPTAHYDRATE",
              "ZINC SULFATE MONOHYDRATE": "ZINC SULFATE MONOHYDRATE"
            },
            "PHARMASSURE, INC.": {
              "ZINC GLUCONATE": "ZINC GLUCONATE"
            },
            "PHARMAVITE": {
              "VITAMELTS ZINC FAST DISSOLVE": "VITAMELTS ZINC FAST DISSOLVE",
              "ZINC GLUCONATE": "ZINC GLUCONATE"
            },
            "PLUS PHARMA": {
              "ZINC SULFATE": "ZINC SULFATE"
            },
            "PRINCETON RESEARCH": {
              "ZINC GLUCONATE": "ZINC GLUCONATE"
            },
            "REESE PHARMACEUTICAL": {
              "ZINC": "ZINC"
            },
            "RISING PHARMACEUTICALS": {
              "ZINC SULFATE": "ZINC SULFATE"
            },
            "RITE AID CORPORATION": {
              "RA ZINC": "RA ZINC"
            },
            "RUGBY LABORATORIES": {
              "ZINC": "ZINC",
              "ZINC GLUCONATE": "ZINC GLUCONATE",
              "ZINC SULFATE": "ZINC SULFATE"
            },
            "SPECTRUM": {
              "ZINC SULFATE": "ZINC SULFATE"
            },
            "TEVA SELECT BRANDS": {
              "GALZIN": "GALZIN"
            },
            "TWIN LABS": {
              "ZINC": "ZINC"
            },
            "WINDMILL CONSUMER PRODUCTS": {
              "ZINC GLUCONATE": "ZINC GLUCONATE",
              "ZINC SULFATE": "ZINC SULFATE"
            }
          },
          "Zinc Combinations": {
            "CHAIN DRUG CONSORTIUM": {
              "ZINC-VITAMIN C": "ZINC-VITAMIN C"
            },
            "GENERAL NUTRITION CORP": {
              "ZINC MAGNESIUM ASPARTATE": "ZINC MAGNESIUM ASPARTATE"
            },
            "NATIONAL VITAMIN": {
              "ZINC-C-B6": "ZINC-C-B6"
            },
            "NATURES BOUNTY": {
              "ZINC-VITAMIN C": "ZINC-VITAMIN C"
            },
            "PHARMASSURE, INC.": {
              "ZINC-VITAMIN C": "ZINC-VITAMIN C"
            },
            "RUGBY LABORATORIES": {
              "ZINC + VITAMIN C": "ZINC + VITAMIN C"
            },
            "WINDMILL CONSUMER PRODUCTS": {
              "ZINC-VITAMIN C": "ZINC-VITAMIN C"
            }
          }
        }
      },
      "MISCELLANEOUS THERAPEUTIC CLASSES": {
        "Chelating Agents": {
          "Chelating Agents": {
            "ACTAVIS PHARMA": {
              "PENICILLAMINE": "PENICILLAMINE",
              "TRIENTINE HCL": "TRIENTINE HCL"
            },
            "AMERIGEN PHARMACEUTICALS": {
              "PENICILLAMINE": "PENICILLAMINE"
            },
            "AMNEAL PHARMACEUTICALS": {
              "TRIENTINE HCL": "TRIENTINE HCL"
            },
            "APOTEX": {
              "PENICILLAMINE": "PENICILLAMINE"
            },
            "BAUSCH HEALTH": {
              "CUPRIMINE": "CUPRIMINE",
              "PENICILLAMINE": "PENICILLAMINE",
              "SYPRINE": "SYPRINE"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "PENICILLAMINE": "PENICILLAMINE",
              "TRIENTINE HCL": "TRIENTINE HCL"
            },
            "EMPOWER PHARMACY": {
              "EDETATE DISODIUM": "EDETATE DISODIUM"
            },
            "KADMON PHARMACEUTICALS": {
              "CLOVIQUE": "CLOVIQUE",
              "TRIENTINE HCL": "TRIENTINE HCL"
            },
            "LANNETT": {
              "TRIENTINE HCL": "TRIENTINE HCL"
            },
            "MYLAN": {
              "D-PENAMINE": "D-PENAMINE"
            },
            "MYLAN SPECIALTY L.P.": {
              "DEPEN TITRATABS": "DEPEN TITRATABS"
            },
            "NOVADOZ PHARMACEUTICALS": {
              "TRIENTINE HCL": "TRIENTINE HCL"
            },
            "OCEANSIDE PHARMACEUTICALS": {
              "TRIENTINE HCL": "TRIENTINE HCL"
            },
            "PAR PHARMACEUTICAL": {
              "PENICILLAMINE": "PENICILLAMINE",
              "TRIENTINE HCL": "TRIENTINE HCL"
            },
            "RISING PHARMACEUTICALS": {
              "TRIENTINE HCL": "TRIENTINE HCL"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "TRIENTINE HCL": "TRIENTINE HCL"
            }
          }
        },
        "Continuous Renal Replacement Therapy (CRRT) Solutions": {
          "Continuous Renal Replacement Therapy (CRRT) Solutions": {
            "CENTRAL ADMIXTURE PHARMACY SER": {
              "TRISODIUM CITRATE/CRRT": "TRISODIUM CITRATE/CRRT"
            },
            "GAMBRO RENAL PRODUCTS": {
              "PHOXILLUM B22K4/0": "PHOXILLUM B22K4/0",
              "PHOXILLUM BK4/2.5": "PHOXILLUM BK4/2.5",
              "PRISMASOL B22GK 4/0": "PRISMASOL B22GK 4/0",
              "PRISMASOL BGK 0/2.5": "PRISMASOL BGK 0/2.5",
              "PRISMASOL BGK 2/0": "PRISMASOL BGK 2/0",
              "PRISMASOL BGK 2/3.5": "PRISMASOL BGK 2/3.5",
              "PRISMASOL BGK 4/0/1.2": "PRISMASOL BGK 4/0/1.2",
              "PRISMASOL BGK 4/2.5": "PRISMASOL BGK 4/2.5",
              "PRISMASOL BK 0/0/1.2": "PRISMASOL BK 0/0/1.2"
            },
            "NEPHRON STERILE COMPOUNDING": {
              "TRISODIUM CITRATE/CRRT": "TRISODIUM CITRATE/CRRT"
            }
          }
        },
        "Digital Therapy": {
          "Digital Therapy": {
            "AKILI INTERACTIVE LABS": {
              "ENDEAVORRX": "ENDEAVORRX"
            },
            "BIG HEALTH": {
              "DAYLIGHT APP": "DAYLIGHT APP",
              "SLEEPIO": "SLEEPIO",
              "SLEEPIO/DAYLIGHT APP BUNDLE": "SLEEPIO/DAYLIGHT APP BUNDLE"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS BEHAVIORAL HEALTH APP": "CVS BEHAVIORAL HEALTH APP",
              "CVS METABOLIC DISEASE APP": "CVS METABOLIC DISEASE APP",
              "CVS SLEEP APPLICATION": "CVS SLEEP APPLICATION",
              "CVS WELLNESS APPLICATION": "CVS WELLNESS APPLICATION"
            },
            "HELLO HEART": {
              "HELLO HEART": "HELLO HEART"
            },
            "HINGE HEALTH": {
              "HINGE HEALTH": "HINGE HEALTH"
            },
            "NATURALLY SLIM": {
              "NATURALLY SLIM FOUNDATIONS": "NATURALLY SLIM FOUNDATIONS",
              "NATURALLY SLIM NS4YOU": "NATURALLY SLIM NS4YOU"
            },
            "PEAR THERAPEUTICS": {
              "RESET FOR ANDROID APP": "RESET FOR ANDROID APP",
              "RESET FOR IOS APP": "RESET FOR IOS APP",
              "RESET FOR IOS OR ANDROID APP": "RESET FOR IOS OR ANDROID APP",
              "RESET-O FOR ANDROID APP": "RESET-O FOR ANDROID APP",
              "RESET-O FOR IOS APP": "RESET-O FOR IOS APP",
              "RESET-O FOR IOS OR ANDROID APP": "RESET-O FOR IOS OR ANDROID APP",
              "SOMRYST": "SOMRYST"
            },
            "SILVERCLOUD HEALTH": {
              "SILVERCLOUD COACHED USER": "SILVERCLOUD COACHED USER",
              "SILVERCLOUD COACHING TRUE UP": "SILVERCLOUD COACHING TRUE UP",
              "SILVERCLOUD SELF GUIDED": "SILVERCLOUD SELF GUIDED"
            },
            "TORCHLIGHT": {
              "TORCHLIGHT CHILD": "TORCHLIGHT CHILD",
              "TORCHLIGHT COMPLETE SUITE": "TORCHLIGHT COMPLETE SUITE",
              "TORCHLIGHT ELDER": "TORCHLIGHT ELDER"
            },
            "VIDA HEALTH": {
              "VIDA HEALTH": "VIDA HEALTH"
            },
            "WHIL CONCEPTS": {
              "WHIL APP": "WHIL APP"
            },
            "WW INTERNATIONAL": {
              "KURBO BY WW": "KURBO BY WW",
              "WW DIGITAL": "WW DIGITAL"
            }
          }
        },
        "Enzymes": {
          "Enzymes": {
            "AMPHASTAR-IMS": {
              "AMPHADASE": "AMPHADASE"
            },
            "BAUSCH HEALTH": {
              "VITRASE": "VITRASE"
            },
            "ENDO PHARMACEUTICALS": {
              "XIAFLEX": "XIAFLEX"
            },
            "FAGRON": {
              "BROMELAIN": "BROMELAIN"
            },
            "FREEDA VITAMINS": {
              "BROMELAINS": "BROMELAINS",
              "PAPAYA": "PAPAYA"
            },
            "FREEDOM PHARMACEUTICALS": {
              "BROMELAIN": "BROMELAIN"
            },
            "GENERAL NUTRITION CORP": {
              "PAPAYA ENZYME": "PAPAYA ENZYME"
            },
            "HALOZYME THERAPEUTICS": {
              "HYLENEX": "HYLENEX"
            },
            "INTEGRATIVE THERAPEUTICS": {
              "BROMELAIN": "BROMELAIN"
            },
            "MASON VITAMINS": {
              "PAPAYA": "PAPAYA",
              "PINEAPPLE EXTRACT": "PINEAPPLE EXTRACT"
            },
            "MEDISCA": {
              "BROMELAIN": "BROMELAIN"
            },
            "NAT-RUL HEALTH PRODUCTS": {
              "BROMELAIN": "BROMELAIN",
              "PAPAYA ENZYMES": "PAPAYA ENZYMES"
            },
            "NATURES BOUNTY": {
              "PAPAYA": "PAPAYA"
            },
            "PCCA": {
              "BROMELAIN": "BROMELAIN",
              "CHYMOTRYPSIN (ALPHA)": "CHYMOTRYPSIN (ALPHA)"
            },
            "RUGBY LABORATORIES": {
              "PAPAYA ENZYME": "PAPAYA ENZYME"
            },
            "SPECTRUM": {
              "BROMELAIN": "BROMELAIN"
            }
          }
        },
        "Fecal Incontinence Bulking Agents": {
          "Fecal Incontinence Bulking Agent - Combinations": {
            "BAUSCH HEALTH": {
              "SOLESTA": "SOLESTA"
            },
            "PALETTE LIFE SCIENCES": {
              "SOLESTA": "SOLESTA"
            }
          }
        },
        "Homeopathic Products": {
          "Homeopathic Products": {
            "A-S MEDICATION SOLUTIONS": {
              "COLD-EEZE": "COLD-EEZE",
              "SPEEDGEL RX": "SPEEDGEL RX",
              "TRANZGEL": "TRANZGEL"
            },
            "AIDAREX PHARMACEUTICALS": {
              "TRAUMANIL": "TRAUMANIL"
            },
            "AKORN CONSUMER": {
              "ALLERGY BUSTER": "ALLERGY BUSTER",
              "NASAL EASE ALLERGY RELIEVER": "NASAL EASE ALLERGY RELIEVER",
              "SINUS BUSTER": "SINUS BUSTER"
            },
            "ALEXSO": {
              "MORCIN": "MORCIN"
            },
            "ALVA-AMCO": {
              "PROSACEA": "PROSACEA"
            },
            "AMERICAN SALES COMPANY": {
              "ZINC COLD RELIEF": "ZINC COLD RELIEF",
              "ZINC COLD THERAPY": "ZINC COLD THERAPY"
            },
            "APOTHECUS": {
              "PURALIN ARTHRITIS FORMULA": "PURALIN ARTHRITIS FORMULA",
              "PURALIN DECONGESTANT": "PURALIN DECONGESTANT",
              "PURALIN PM NIGHTTIME SLEEP-AID": "PURALIN PM NIGHTTIME SLEEP-AID",
              "PURALIN WEIGHT LOSS": "PURALIN WEIGHT LOSS"
            },
            "ATLANTIC BIOLOGICALS": {
              "TRAUMANIL": "TRAUMANIL"
            },
            "AVADIM": {
              "THERAWORX GLOVE + FOAM": "THERAWORX GLOVE + FOAM",
              "THERAWORX KNEE SLEEVE + FOAM": "THERAWORX KNEE SLEEVE + FOAM",
              "THERAWORX RELIEF": "THERAWORX RELIEF"
            },
            "BERGEN BRUNSWIG": {
              "GNP COLD REMEDY": "GNP COLD REMEDY"
            },
            "BIORX LABS": {
              "BIORX SPONIX NAIL": "BIORX SPONIX NAIL"
            },
            "BOIRON LABORATORIES": {
              "ARNICA MONTANA": "ARNICA MONTANA",
              "ARNICARE": "ARNICARE",
              "ARNICARE ARNICA": "ARNICARE ARNICA",
              "ARNICARE ARTHRITIS": "ARNICARE ARTHRITIS",
              "ARNICARE BRUISE": "ARNICARE BRUISE",
              "ARNICARE PAIN RELIEF": "ARNICARE PAIN RELIEF",
              "AVENOC": "AVENOC",
              "CALENDULA": "CALENDULA",
              "COFFEA CRUDA": "COFFEA CRUDA",
              "COLDCALM": "COLDCALM",
              "CYCLEASE CRAMP": "CYCLEASE CRAMP",
              "OPTIQUE1": "OPTIQUE1",
              "OSCILLOCOCCINUM": "OSCILLOCOCCINUM",
              "PULSATILLA": "PULSATILLA",
              "ROXALIA": "ROXALIA"
            },
            "CHAIN DRUG CONSORTIUM": {
              "EARACHE RELIEF": "EARACHE RELIEF",
              "FLU RELIEF": "FLU RELIEF",
              "IRRITATED EYE RELIEF": "IRRITATED EYE RELIEF"
            },
            "CLAIRE ELLEN PRODUCTS": {
              "MENASTIL": "MENASTIL"
            },
            "CLEAR PRODUCTS": {
              "CLEAR TINNITUS": "CLEAR TINNITUS"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS COLD REMEDY": "CVS COLD REMEDY",
              "CVS EAR PAIN RELIEF": "CVS EAR PAIN RELIEF",
              "CVS EAR RELIEF": "CVS EAR RELIEF",
              "CVS FLU RELIEF": "CVS FLU RELIEF",
              "CVS IRRITATED EYE": "CVS IRRITATED EYE",
              "CVS LEG CRAMPS PAIN RELIEF": "CVS LEG CRAMPS PAIN RELIEF",
              "CVS NERVE PAIN RELIEF": "CVS NERVE PAIN RELIEF"
            },
            "EQUALINE": {
              "EQL EAR DROPS": "EQL EAR DROPS",
              "EQL ZINC COLD RELIEF": "EQL ZINC COLD RELIEF",
              "EQL ZINC COLD REMEDY": "EQL ZINC COLD REMEDY"
            },
            "FREEDOM LASER THERAPY": {
              "FREEDOM QUIT SMOKING SYSTEM": "FREEDOM QUIT SMOKING SYSTEM"
            },
            "GENSCO LABORATORIES": {
              "COLCIGEL": "COLCIGEL",
              "SPEEDGEL RX": "SPEEDGEL RX",
              "TRANZGEL": "TRANZGEL"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "TRAUMANIL": "TRAUMANIL"
            },
            "HUMCO": {
              "ARNICA": "ARNICA"
            },
            "HUMPHREYS": {
              "HUMPHREYS #1 SIMPLE FEVER": "HUMPHREYS #1 SIMPLE FEVER",
              "HUMPHREYS DELAYED MENSES #11": "HUMPHREYS DELAYED MENSES #11"
            },
            "HYLAND'S": {
              "EARACHE DROPS": "EARACHE DROPS",
              "LEG CRAMPS": "LEG CRAMPS",
              "LEG CRAMPS PM": "LEG CRAMPS PM",
              "MUSCLE THERAPY/ARNICA": "MUSCLE THERAPY/ARNICA",
              "PRID": "PRID",
              "RESTFUL LEGS": "RESTFUL LEGS"
            },
            "I-HEALTH": {
              "AZO YEAST PLUS": "AZO YEAST PLUS"
            },
            "LAKE CONSUMER PRODUCTS": {
              "BV TREATMENT": "BV TREATMENT",
              "VH ESSENTIALS BV TREATMENT": "VH ESSENTIALS BV TREATMENT",
              "YEAST-GARD ADV HOMEOPATHIC": "YEAST-GARD ADV HOMEOPATHIC",
              "YEAST-GARD ADVANCED DOUCHE": "YEAST-GARD ADVANCED DOUCHE",
              "YEAST-GARD FEMININE WASH": "YEAST-GARD FEMININE WASH",
              "YEAST-GARD HOMEOPATHIC": "YEAST-GARD HOMEOPATHIC"
            },
            "LEADER BRAND PRODUCTS": {
              "LICE CURE": "LICE CURE",
              "ZINC COLD THERAPY": "ZINC COLD THERAPY"
            },
            "LOMA LUX LABORATORIES": {
              "ACUNOL": "ACUNOL",
              "ECZEMOL": "ECZEMOL",
              "LOMA ASTHMA": "LOMA ASTHMA",
              "LOMA ECZEMA": "LOMA ECZEMA",
              "LOMA LUX ACNEPILL": "LOMA LUX ACNEPILL",
              "LOMA LUX PSORIASIS": "LOMA LUX PSORIASIS",
              "LOMA SINUS/ALLERGY": "LOMA SINUS/ALLERGY",
              "PSORIZIDE FORTE": "PSORIZIDE FORTE",
              "PSORIZIDE ULTRA": "PSORIZIDE ULTRA"
            },
            "MATRIXX INITIATIVES": {
              "ZICAM ALLERGY RELIEF": "ZICAM ALLERGY RELIEF",
              "ZICAM COLD REMEDY": "ZICAM COLD REMEDY"
            },
            "MEDA CONSUMER HEALTHCARE": {
              "COLD-EEZE": "COLD-EEZE",
              "COLD-EEZE DAYTIME NIGHTTIME": "COLD-EEZE DAYTIME NIGHTTIME",
              "COLD-EEZE NATURAL MULTI-SYMP": "COLD-EEZE NATURAL MULTI-SYMP",
              "COLD-EEZE PLUS DEFENSE": "COLD-EEZE PLUS DEFENSE",
              "COLD-EEZE SUGAR FREE": "COLD-EEZE SUGAR FREE"
            },
            "MEDINATURA": {
              "APPETITE CONTROL": "APPETITE CONTROL",
              "ARNICA MONTANA": "ARNICA MONTANA",
              "ARTHRITIS PAIN RELIEF": "ARTHRITIS PAIN RELIEF",
              "BACK PAIN RELIEF": "BACK PAIN RELIEF",
              "BARIJODEEL": "BARIJODEEL",
              "BERBERIS HOMACCORD": "BERBERIS HOMACCORD",
              "BHI ACNE RELIEF": "BHI ACNE RELIEF",
              "BHI ALLERGY RELIEF": "BHI ALLERGY RELIEF",
              "BHI ARNICA+ PAIN RELIEF": "BHI ARNICA+ PAIN RELIEF",
              "BHI ARTHRITIS PAIN RELIEF": "BHI ARTHRITIS PAIN RELIEF",
              "BHI BACK PAIN RELIEF": "BHI BACK PAIN RELIEF",
              "BHI BODY PURE CLEANSING": "BHI BODY PURE CLEANSING",
              "BHI CALMING": "BHI CALMING",
              "BHI COLD SYMPTOM RELIEF": "BHI COLD SYMPTOM RELIEF",
              "BHI CONSTIPATION RELIEF": "BHI CONSTIPATION RELIEF",
              "BHI COUGH RELIEF": "BHI COUGH RELIEF",
              "BHI DIARRHEA RELIEF": "BHI DIARRHEA RELIEF",
              "BHI FLU+ MULTI-SYMPTOM RELIEF": "BHI FLU+ MULTI-SYMPTOM RELIEF",
              "BHI HEMORRHOID RELIEF": "BHI HEMORRHOID RELIEF",
              "BHI MIGRAINE RELIEF": "BHI MIGRAINE RELIEF",
              "BHI MUCUS RELIEF": "BHI MUCUS RELIEF",
              "BHI NAUSEA RELIEF": "BHI NAUSEA RELIEF",
              "BHI RESTORE MUSCLE RECOVERY": "BHI RESTORE MUSCLE RECOVERY",
              "BHI SINUS RELIEF": "BHI SINUS RELIEF",
              "BHI SKIN ECZEMA RELIEF": "BHI SKIN ECZEMA RELIEF",
              "BHI SPASM PAIN CRAMP/SPASM": "BHI SPASM PAIN CRAMP/SPASM",
              "BHI THROAT SORE THROAT RELIEF": "BHI THROAT SORE THROAT RELIEF",
              "BHI URI-CONTROL": "BHI URI-CONTROL",
              "BODYANEW CLEANSE MULTIPACK": "BODYANEW CLEANSE MULTIPACK",
              "BODYANEW CLEANSE/DETOX MULTIPK": "BODYANEW CLEANSE/DETOX MULTIPK",
              "BODYANEW FATIGUE": "BODYANEW FATIGUE",
              "BODYANEW FATIGUE ALERTNESS AID": "BODYANEW FATIGUE ALERTNESS AID",
              "BODYANEW PURITY": "BODYANEW PURITY",
              "BODYANEW REJUVEO": "BODYANEW REJUVEO",
              "CALM PLUS": "CALM PLUS",
              "CANTHARIS COMPOSITUM": "CANTHARIS COMPOSITUM",
              "CEREBRUM COMPOSITUM": "CEREBRUM COMPOSITUM",
              "CHELIDONIUM HOMACCORD": "CHELIDONIUM HOMACCORD",
              "CIRCULO-HEEL": "CIRCULO-HEEL",
              "CLEARLIFE ALLERGY RELIEF": "CLEARLIFE ALLERGY RELIEF",
              "COLD/SORE THROAT RELIEF": "COLD/SORE THROAT RELIEF",
              "DISCUS COMPOSITUM": "DISCUS COMPOSITUM",
              "ENGYSTOL": "ENGYSTOL",
              "FIBROMYALGIA SYMPTOM RELIEF": "FIBROMYALGIA SYMPTOM RELIEF",
              "GALIUM HEEL": "GALIUM HEEL",
              "GLONOIN HOMACCORD": "GLONOIN HOMACCORD",
              "GRAPHITES HOMACCORD": "GRAPHITES HOMACCORD",
              "HEPEEL": "HEPEEL",
              "HOMEOPATHIC CALM": "HOMEOPATHIC CALM",
              "INDIGESTION/BLOATING RELIEF": "INDIGESTION/BLOATING RELIEF",
              "INSOMNIA RELIEF": "INSOMNIA RELIEF",
              "LEG CRAMP RELIEF": "LEG CRAMP RELIEF",
              "LIVER SUPPORT": "LIVER SUPPORT",
              "LUFFEEL": "LUFFEEL",
              "LYMPHOMYOSOT": "LYMPHOMYOSOT",
              "LYMPHOMYOSOT X": "LYMPHOMYOSOT X",
              "NERVE PAIN RELIEF": "NERVE PAIN RELIEF",
              "NEURALGO-RHEUM": "NEURALGO-RHEUM",
              "OCULOHEEL": "OCULOHEEL",
              "PAIN/INJURY RELIEF": "PAIN/INJURY RELIEF",
              "PERIODONTAL/TOOTH PAIN RELIEF": "PERIODONTAL/TOOTH PAIN RELIEF",
              "PSORIASIS/ECZEMA/SEBORRHEA REL": "PSORIASIS/ECZEMA/SEBORRHEA REL",
              "RANUNCULUS HOMACCORD": "RANUNCULUS HOMACCORD",
              "REBOOST COLD/FLU RELIEF": "REBOOST COLD/FLU RELIEF",
              "REBOOST COUGH RELIEF": "REBOOST COUGH RELIEF",
              "REBOOST DECONGESTION": "REBOOST DECONGESTION",
              "REBOOST IMMUNE SUPPORT": "REBOOST IMMUNE SUPPORT",
              "REBOOST THROAT RELIEF SPRAY": "REBOOST THROAT RELIEF SPRAY",
              "RHUS TOX": "RHUS TOX",
              "SABAL HOMACCORD": "SABAL HOMACCORD",
              "SCHUESSLER CELL SALTS": "SCHUESSLER CELL SALTS",
              "SINUS RELIEF": "SINUS RELIEF",
              "SINUSIN": "SINUSIN",
              "SPASCUPREEL": "SPASCUPREEL",
              "STRESS/EXHAUSTION RELIEF": "STRESS/EXHAUSTION RELIEF",
              "T-RELIEF ARTHRITIS MOBILITY": "T-RELIEF ARTHRITIS MOBILITY",
              "T-RELIEF EXTRA STRENGTH": "T-RELIEF EXTRA STRENGTH",
              "T-RELIEF PAIN ARNICA + 12": "T-RELIEF PAIN ARNICA + 12",
              "T-RELIEF PAIN RELIEF": "T-RELIEF PAIN RELIEF",
              "T-RELIEF PRO": "T-RELIEF PRO",
              "T-RELIEF PRO ARTHRITIS": "T-RELIEF PRO ARTHRITIS",
              "T-RELIEF PRO SPASM/CRAMP": "T-RELIEF PRO SPASM/CRAMP",
              "T-RELIEF SAFE RELIEF": "T-RELIEF SAFE RELIEF",
              "T-RELIEF SPASM RELIEF": "T-RELIEF SPASM RELIEF",
              "THALAMUS COMPOSITUM": "THALAMUS COMPOSITUM",
              "TRAUMEEL": "TRAUMEEL",
              "TRAUMEEL X": "TRAUMEEL X",
              "WELLMIND MENTAL FOCUS": "WELLMIND MENTAL FOCUS",
              "WELLMIND TENSION RELIEF": "WELLMIND TENSION RELIEF",
              "WELLMIND VERTIGO": "WELLMIND VERTIGO",
              "ZEEL": "ZEEL"
            },
            "MEDTECH": {
              "LITTLE TEETHERS TEETHING": "LITTLE TEETHERS TEETHING",
              "MURINE EAR WAX RELIEF": "MURINE EAR WAX RELIEF"
            },
            "NATURE'S WAY PRODUCTS": {
              "UMCKA COLD+FLU": "UMCKA COLD+FLU",
              "UMCKA COLDCARE": "UMCKA COLDCARE",
              "UMCKA ELDERBERRY COLD+FLU": "UMCKA ELDERBERRY COLD+FLU",
              "UMCKA FASTACTIVES COLD+FLU": "UMCKA FASTACTIVES COLD+FLU"
            },
            "NATUROPATHIX": {
              "ZYMADERM": "ZYMADERM"
            },
            "NEXCO PHARMA": {
              "NEXCO 75": "NEXCO 75"
            },
            "NOVA HOMEOPATHIC THERAPEUTICS": {
              "LEG CRAMP COMPLEX": "LEG CRAMP COMPLEX",
              "MUSCLE CRAMP COMPLEX": "MUSCLE CRAMP COMPLEX"
            },
            "NUCARE PHARMACEUTICALS": {
              "TRAUMANIL": "TRAUMANIL"
            },
            "ORIGIN BIOMED": {
              "NEURAGEN": "NEURAGEN",
              "NEURAGEN PAIN RELIEF": "NEURAGEN PAIN RELIEF",
              "NEURAGEN PN": "NEURAGEN PN"
            },
            "PHARMACEUTICA NORTH AMERICA": {
              "STERYMINE": "STERYMINE"
            },
            "PRINCETON RESEARCH": {
              "4 THRIVE CLEANSING": "4 THRIVE CLEANSING",
              "AVOCADO REVITALIZING": "AVOCADO REVITALIZING",
              "BERGAMOT SERENITY": "BERGAMOT SERENITY",
              "BREATHE EASY PURIFYING": "BREATHE EASY PURIFYING",
              "CALMING": "CALMING",
              "ENERGY": "ENERGY",
              "EUCALYPTUS INVIGORATING": "EUCALYPTUS INVIGORATING",
              "FRANKINCENSE UPLIFTING": "FRANKINCENSE UPLIFTING",
              "GOOD NITE": "GOOD NITE",
              "LAVENDER REJUVENATING": "LAVENDER REJUVENATING",
              "MENTAL CLARITY": "MENTAL CLARITY",
              "PEPPERMINT REFRESHING": "PEPPERMINT REFRESHING",
              "SWEET ALMOND": "SWEET ALMOND",
              "TEA TREE PURIFYING": "TEA TREE PURIFYING"
            },
            "PROFICIENT RX": {
              "SPEEDGEL RX": "SPEEDGEL RX"
            },
            "PROPHASE LABS": {
              "COLD-EEZE": "COLD-EEZE"
            },
            "QUALITY CARE": {
              "SPEEDGEL RX": "SPEEDGEL RX",
              "TRAUMANIL": "TRAUMANIL"
            },
            "QUEST PRODUCTS": {
              "COPPERFIXX PAIN RELIEF": "COPPERFIXX PAIN RELIEF",
              "COPPERFIXX PAIN THERAPY": "COPPERFIXX PAIN THERAPY",
              "SKIN TAG REMOVER": "SKIN TAG REMOVER"
            },
            "REESE PHARMACEUTICAL": {
              "ACHES/PAINS MEDICINE": "ACHES/PAINS MEDICINE",
              "ANTACID MEDICINE": "ANTACID MEDICINE",
              "SLEEP MEDICINE": "SLEEP MEDICINE"
            },
            "RITE AID CORPORATION": {
              "RA COLD RELIEF SPRAY": "RA COLD RELIEF SPRAY",
              "RA COLD REMEDY MULTI-SYMPTOM": "RA COLD REMEDY MULTI-SYMPTOM",
              "RA EAR DROPS HOMEOPATHIC": "RA EAR DROPS HOMEOPATHIC",
              "RA EARACHE DROPS": "RA EARACHE DROPS",
              "RA YEAST RELIEF PLUS": "RA YEAST RELIEF PLUS"
            },
            "SIMILASAN": {
              "SIMILASAN ALLERGY EYE RELIEF": "SIMILASAN ALLERGY EYE RELIEF",
              "SIMILASAN COLD & MUCUS RELIEF": "SIMILASAN COLD & MUCUS RELIEF",
              "SIMILASAN COMPUTER EYE RELIEF": "SIMILASAN COMPUTER EYE RELIEF",
              "SIMILASAN DRY EYE RELIEF": "SIMILASAN DRY EYE RELIEF",
              "SIMILASAN EAR WAX RELIEF": "SIMILASAN EAR WAX RELIEF",
              "SIMILASAN EARACHE RELIEF": "SIMILASAN EARACHE RELIEF",
              "SIMILASAN KIDS EAR RELIEF": "SIMILASAN KIDS EAR RELIEF",
              "SIMILASAN NASAL ALLERGY RELIEF": "SIMILASAN NASAL ALLERGY RELIEF",
              "SIMILASAN PINK EYE RELIEF": "SIMILASAN PINK EYE RELIEF",
              "SIMILASAN SINUS RELIEF": "SIMILASAN SINUS RELIEF",
              "SIMILASAN STYE EYE RELIEF": "SIMILASAN STYE EYE RELIEF"
            },
            "STELLALIFE": {
              "VEGA ORAL CARE GEL": "VEGA ORAL CARE GEL",
              "VEGA ORAL CARE RECOVERY KIT": "VEGA ORAL CARE RECOVERY KIT",
              "VEGA ORAL CARE RINSE": "VEGA ORAL CARE RINSE"
            },
            "TARGET": {
              "TGT ZINC COLD REMEDY": "TGT ZINC COLD REMEDY"
            },
            "TEC LABS": {
              "LICEFREEE": "LICEFREEE",
              "TECNU EXTREME MED POISON IVY": "TECNU EXTREME MED POISON IVY",
              "TECNU RASH REL MED ANTI-ITCH": "TECNU RASH REL MED ANTI-ITCH"
            },
            "THE MAGNI GROUP": {
              "LEG CRAMP RELIEF": "LEG CRAMP RELIEF"
            },
            "TMIG": {
              "RAPID GEL RX": "RAPID GEL RX"
            },
            "TOPCO": {
              "COLD REMEDY": "COLD REMEDY"
            },
            "TRIFECTA PHARMACEUTICALS": {
              "ICHTHAMMOL DRAWING SALVE": "ICHTHAMMOL DRAWING SALVE"
            },
            "TRUCEUTICALS": {
              "NOXICARE": "NOXICARE"
            },
            "TYRATECH": {
              "VAMOUSSE LICE TREATMENT": "VAMOUSSE LICE TREATMENT"
            },
            "U S PHARMACEUTICAL": {
              "HYLAFEM": "HYLAFEM",
              "HYLAFEM PH": "HYLAFEM PH"
            },
            "UNIT DOSE SERVICES": {
              "SPEEDGEL": "SPEEDGEL",
              "SPEEDGEL RX": "SPEEDGEL RX",
              "TRANZGEL": "TRANZGEL",
              "TRAUMANIL": "TRAUMANIL"
            },
            "WAL-MART": {
              "EQ ZINC COLD THERAPY": "EQ ZINC COLD THERAPY"
            },
            "WALGREENS": {
              "EAR PAIN RELIEF HOMEOPATHIC": "EAR PAIN RELIEF HOMEOPATHIC",
              "EARACHE DROPS": "EARACHE DROPS",
              "LICE TREATMENT SPRAY": "LICE TREATMENT SPRAY",
              "ZINC": "ZINC"
            },
            "WELEDA": {
              "ARGENTUM-D20": "ARGENTUM-D20",
              "DERMATODORON": "DERMATODORON",
              "DIGESTODORON": "DIGESTODORON",
              "ERYSIDORON #1": "ERYSIDORON #1",
              "PNEUMODORON #1": "PNEUMODORON #1",
              "PNEUMODORON #2": "PNEUMODORON #2",
              "RHEUMADORON 102A": "RHEUMADORON 102A"
            }
          }
        },
        "Immunomodulators": {
          "Antileprotics": {
            "CELGENE CORP": {
              "THALOMID": "THALOMID"
            }
          },
          "Immunomodulators for Myelodysplastic Syndromes": {
            "CELGENE CORP": {
              "REVLIMID": "REVLIMID"
            }
          }
        },
        "Immunosuppressive Agents": {
          "Cyclosporine Analogs": {
            "ABBVIE": {
              "GENGRAF": "GENGRAF"
            },
            "ACTAVIS PHARMA": {
              "CYCLOSPORINE MODIFIED": "CYCLOSPORINE MODIFIED"
            },
            "AMERICAN HEALTH PACKAGING": {
              "CYCLOSPORINE": "CYCLOSPORINE"
            },
            "AMERICAN REGENT": {
              "CYCLOSPORINE": "CYCLOSPORINE"
            },
            "APOTEX": {
              "CYCLOSPORINE": "CYCLOSPORINE",
              "CYCLOSPORINE MODIFIED": "CYCLOSPORINE MODIFIED"
            },
            "MAYNE PHARMA": {
              "CYCLOSPORINE MODIFIED": "CYCLOSPORINE MODIFIED"
            },
            "NOVARTIS": {
              "NEORAL": "NEORAL",
              "SANDIMMUNE": "SANDIMMUNE"
            },
            "PERRIGO": {
              "CYCLOSPORINE": "CYCLOSPORINE"
            },
            "SANDOZ": {
              "CYCLOSPORINE MODIFIED": "CYCLOSPORINE MODIFIED"
            },
            "TEVA PHARMACEUTICALS USA": {
              "CYCLOSPORINE MODIFIED": "CYCLOSPORINE MODIFIED"
            }
          },
          "Immune Globulin Immunosuppressants": {
            "GENZYME": {
              "THYMOGLOBULIN": "THYMOGLOBULIN"
            },
            "PFIZER U.S.": {
              "ATGAM": "ATGAM"
            }
          },
          "Inosine Monophosphate Dehydrogenase Inhibitors": {
            "A-S MEDICATION SOLUTIONS": {
              "MYCOPHENOLATE MOFETIL": "MYCOPHENOLATE MOFETIL"
            },
            "ACCORD HEALTHCARE": {
              "MYCOPHENOLATE MOFETIL": "MYCOPHENOLATE MOFETIL",
              "MYCOPHENOLATE SODIUM": "MYCOPHENOLATE SODIUM"
            },
            "AKORN": {
              "MYCOPHENOLATE MOFETIL HCL": "MYCOPHENOLATE MOFETIL HCL"
            },
            "AMERICAN HEALTH PACKAGING": {
              "MYCOPHENOLATE MOFETIL": "MYCOPHENOLATE MOFETIL",
              "MYCOPHENOLATE SODIUM": "MYCOPHENOLATE SODIUM"
            },
            "APHENA PHARMA SOLUTIONS": {
              "MYCOPHENOLATE MOFETIL": "MYCOPHENOLATE MOFETIL"
            },
            "APOTEX": {
              "MYCOPHENOLATE MOFETIL": "MYCOPHENOLATE MOFETIL",
              "MYCOPHENOLATE SODIUM": "MYCOPHENOLATE SODIUM"
            },
            "ARCHIS PHARMA": {
              "MYCOPHENOLATE SODIUM": "MYCOPHENOLATE SODIUM"
            },
            "ASCEND LABORATORIES": {
              "MYCOPHENOLATE MOFETIL": "MYCOPHENOLATE MOFETIL"
            },
            "AVPAK": {
              "MYCOPHENOLATE MOFETIL": "MYCOPHENOLATE MOFETIL",
              "MYCOPHENOLATE SODIUM": "MYCOPHENOLATE SODIUM"
            },
            "GENENTECH": {
              "CELLCEPT": "CELLCEPT",
              "CELLCEPT INTRAVENOUS": "CELLCEPT INTRAVENOUS"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "MYCOPHENOLATE MOFETIL": "MYCOPHENOLATE MOFETIL",
              "MYCOPHENOLATE SODIUM": "MYCOPHENOLATE SODIUM"
            },
            "GREENSTONE": {
              "MYCOPHENOLATE MOFETIL": "MYCOPHENOLATE MOFETIL"
            },
            "HIKMA": {
              "MYCOPHENOLATE MOFETIL": "MYCOPHENOLATE MOFETIL"
            },
            "LUPIN PHARMACEUTICALS": {
              "MYCOPHENOLATE MOFETIL": "MYCOPHENOLATE MOFETIL",
              "MYCOPHENOLATE SODIUM": "MYCOPHENOLATE SODIUM"
            },
            "MAJOR PHARMACEUTICALS": {
              "MYCOPHENOLATE SODIUM": "MYCOPHENOLATE SODIUM"
            },
            "MYLAN": {
              "MYCOPHENOLATE MOFETIL": "MYCOPHENOLATE MOFETIL"
            },
            "MYLAN INSTITUTIONAL": {
              "MYCOPHENOLATE MOFETIL": "MYCOPHENOLATE MOFETIL",
              "MYCOPHENOLATE SODIUM": "MYCOPHENOLATE SODIUM"
            },
            "NOVAPLUS / AKORN": {
              "MYCOPHENOLATE MOFETIL HCL": "MYCOPHENOLATE MOFETIL HCL"
            },
            "NOVARTIS": {
              "MYFORTIC": "MYFORTIC"
            },
            "PAR STERILE PRODUCTS": {
              "MYCOPHENOLATE MOFETIL HCL": "MYCOPHENOLATE MOFETIL HCL"
            },
            "QUALITY CARE": {
              "CELLCEPT": "CELLCEPT"
            },
            "SANDOZ": {
              "MYCOPHENOLATE MOFETIL": "MYCOPHENOLATE MOFETIL"
            },
            "STRIDES PHARMA": {
              "MYCOPHENOLATE MOFETIL": "MYCOPHENOLATE MOFETIL"
            },
            "TEVA PHARMACEUTICALS USA": {
              "MYCOPHENOLATE MOFETIL": "MYCOPHENOLATE MOFETIL",
              "MYCOPHENOLATE SODIUM": "MYCOPHENOLATE SODIUM"
            },
            "VISTAPHARM INC.": {
              "MYCOPHENOLATE MOFETIL": "MYCOPHENOLATE MOFETIL"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "MYCOPHENOLATE MOFETIL": "MYCOPHENOLATE MOFETIL"
            }
          },
          "Macrolide Immunosuppressants": {
            "A-S MEDICATION SOLUTIONS": {
              "TACROLIMUS": "TACROLIMUS"
            },
            "ACCORD HEALTHCARE": {
              "TACROLIMUS": "TACROLIMUS"
            },
            "AMERICAN HEALTH PACKAGING": {
              "SIROLIMUS": "SIROLIMUS",
              "TACROLIMUS": "TACROLIMUS"
            },
            "AMNEAL PHARMACEUTICALS": {
              "SIROLIMUS": "SIROLIMUS"
            },
            "APHENA PHARMA SOLUTIONS": {
              "PROGRAF": "PROGRAF",
              "TACROLIMUS": "TACROLIMUS"
            },
            "APOTEX": {
              "SIROLIMUS": "SIROLIMUS"
            },
            "ASTELLAS": {
              "ASTAGRAF XL": "ASTAGRAF XL",
              "PROGRAF": "PROGRAF"
            },
            "AVKARE": {
              "TACROLIMUS": "TACROLIMUS"
            },
            "AVPAK": {
              "SIROLIMUS": "SIROLIMUS"
            },
            "BIONPHARMA": {
              "TACROLIMUS": "TACROLIMUS"
            },
            "BPI LABS LLC": {
              "TACROLIMUS": "TACROLIMUS"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "SIROLIMUS": "SIROLIMUS",
              "TACROLIMUS": "TACROLIMUS"
            },
            "GLENMARK PHARMACEUTICALS": {
              "SIROLIMUS": "SIROLIMUS"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "TACROLIMUS": "TACROLIMUS"
            },
            "GREENSTONE": {
              "SIROLIMUS": "SIROLIMUS"
            },
            "HIKMA": {
              "EVEROLIMUS": "EVEROLIMUS"
            },
            "INGENUS PHARMACEUTICALS": {
              "TACROLIMUS": "TACROLIMUS"
            },
            "KREMERS URBAN": {
              "TACROLIMUS": "TACROLIMUS"
            },
            "MAJOR PHARMACEUTICALS": {
              "TACROLIMUS": "TACROLIMUS"
            },
            "MYLAN": {
              "TACROLIMUS": "TACROLIMUS"
            },
            "MYLAN INSTITUTIONAL": {
              "TACROLIMUS": "TACROLIMUS"
            },
            "NOVAPLUS/SANDOZ": {
              "TACROLIMUS": "TACROLIMUS"
            },
            "NOVARTIS": {
              "ZORTRESS": "ZORTRESS"
            },
            "PFIZER U.S.": {
              "RAPAMUNE": "RAPAMUNE"
            },
            "QUALITY CARE": {
              "RAPAMUNE": "RAPAMUNE"
            },
            "SANDOZ": {
              "TACROLIMUS": "TACROLIMUS"
            },
            "STRIDES PHARMA": {
              "TACROLIMUS": "TACROLIMUS"
            },
            "VELOXIS PHARMACEUTICALS": {
              "ENVARSUS XR": "ENVARSUS XR"
            },
            "VISTAPHARM INC.": {
              "SIROLIMUS": "SIROLIMUS"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "SIROLIMUS": "SIROLIMUS"
            }
          },
          "Monoclonal Antibodies": {
            "GENENTECH": {
              "ENSPRYNG": "ENSPRYNG"
            },
            "NOVARTIS": {
              "SIMULECT": "SIMULECT"
            },
            "SOBI": {
              "GAMIFANT": "GAMIFANT"
            },
            "VIELA BIO": {
              "UPLIZNA": "UPLIZNA"
            }
          },
          "Purine Analogs": {
            "AIDAREX PHARMACEUTICALS": {
              "AZATHIOPRINE": "AZATHIOPRINE"
            },
            "AMERICAN HEALTH PACKAGING": {
              "AZATHIOPRINE": "AZATHIOPRINE"
            },
            "AMNEAL PHARMACEUTICALS": {
              "AZATHIOPRINE": "AZATHIOPRINE"
            },
            "APHENA PHARMA SOLUTIONS": {
              "AZATHIOPRINE": "AZATHIOPRINE"
            },
            "ASCEND LABORATORIES": {
              "AZATHIOPRINE": "AZATHIOPRINE"
            },
            "BAUSCH HEALTH": {
              "AZASAN": "AZASAN"
            },
            "FAGRON": {
              "AZATHIOPRINE": "AZATHIOPRINE"
            },
            "FREEDOM PHARMACEUTICALS": {
              "AZATHIOPRINE": "AZATHIOPRINE"
            },
            "GLENMARK PHARMACEUTICALS": {
              "AZATHIOPRINE": "AZATHIOPRINE"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "AZATHIOPRINE": "AZATHIOPRINE"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "IMURAN": "IMURAN"
            },
            "HIKMA": {
              "AZATHIOPRINE": "AZATHIOPRINE",
              "AZATHIOPRINE SODIUM": "AZATHIOPRINE SODIUM"
            },
            "LETCO MEDICAL": {
              "AZATHIOPRINE": "AZATHIOPRINE"
            },
            "MEDISCA": {
              "AZATHIOPRINE": "AZATHIOPRINE"
            },
            "MYLAN": {
              "AZATHIOPRINE": "AZATHIOPRINE"
            },
            "MYLAN INSTITUTIONAL": {
              "AZATHIOPRINE": "AZATHIOPRINE"
            },
            "PCCA": {
              "AZATHIOPRINE": "AZATHIOPRINE"
            },
            "SEBELA PHARMACEUTICALS": {
              "IMURAN": "IMURAN"
            },
            "SPECTRUM": {
              "AZATHIOPRINE": "AZATHIOPRINE"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "AZATHIOPRINE": "AZATHIOPRINE"
            }
          },
          "Selective T-Cell Costimulation Blockers": {
            "B-M SQUIBB U.S. (PRIMARY CARE)": {
              "NULOJIX": "NULOJIX"
            }
          }
        },
        "Irrigation Solutions": {
          "Irrigation Solutions": {
            "B. BRAUN MEDICAL": {
              "LACTATED RINGERS": "LACTATED RINGERS",
              "PHYSIOLYTE": "PHYSIOLYTE",
              "RINGERS IRRIGATION": "RINGERS IRRIGATION",
              "STERILE WATER FOR IRRIGATION": "STERILE WATER FOR IRRIGATION"
            },
            "BAXTER HEALTHCARE CORP": {
              "LACTATED RINGERS": "LACTATED RINGERS",
              "RINGERS IRRIGATION": "RINGERS IRRIGATION",
              "STERILE WATER FOR IRRIGATION": "STERILE WATER FOR IRRIGATION",
              "TIS-U-SOL": "TIS-U-SOL"
            },
            "COVIDIEN MEDICAL SUPPLIES": {
              "ARGYLE STERILE WATER": "ARGYLE STERILE WATER"
            },
            "GERI-CARE": {
              "STERILE WATER FOR IRRIGATION": "STERILE WATER FOR IRRIGATION"
            },
            "HOSPIRA": {
              "LACTATED RINGERS": "LACTATED RINGERS",
              "PHYSIOSOL IRRIGATION": "PHYSIOSOL IRRIGATION",
              "RINGERS IRRIGATION": "RINGERS IRRIGATION",
              "STERILE WATER FOR IRRIGATION": "STERILE WATER FOR IRRIGATION"
            },
            "ICU MEDICAL": {
              "LACTATED RINGERS": "LACTATED RINGERS",
              "PHYSIOSOL IRRIGATION": "PHYSIOSOL IRRIGATION",
              "STERILE WATER FOR IRRIGATION": "STERILE WATER FOR IRRIGATION",
              "WATER FOR IRRIGATION, STERILE": "WATER FOR IRRIGATION, STERILE"
            }
          }
        },
        "Lymphatic Agents": {
          "Interleukin-6 (IL-6) Antagonists": {
            "EUSA PHARMA US": {
              "SYLVANT": "SYLVANT"
            }
          }
        },
        "Misc Natural Products": {
          "Misc Natural Products": {
            "21ST CENTURY HEALTHCARE": {
              "ARTHRI-FLEX ADVANTAGE": "ARTHRI-FLEX ADVANTAGE",
              "RED WINE EXTRACT": "RED WINE EXTRACT"
            },
            "AG MARIN PHARMACEUTICALS": {
              "HYPROST": "HYPROST",
              "VIVABEN": "VIVABEN"
            },
            "AIDAREX PHARMACEUTICALS": {
              "NARCOSOFT HERBAL LAX": "NARCOSOFT HERBAL LAX",
              "TRU-MICIN": "TRU-MICIN"
            },
            "ATLANTIC HEALTH CARE": {
              "VARIVODA": "VARIVODA"
            },
            "AVADIM": {
              "THERAWORX PROTECT U-PAK": "THERAWORX PROTECT U-PAK"
            },
            "BASIC DRUGS": {
              "SLEEP TONITE": "SLEEP TONITE"
            },
            "BASIEM": {
              "AZALGIA": "AZALGIA",
              "XYZMUNE": "XYZMUNE"
            },
            "BELMORA": {
              "ELAVIL OTC SLEEP": "ELAVIL OTC SLEEP"
            },
            "BERGEN BRUNSWIG": {
              "GNP GLUCOSAME CHONDROITIN DS": "GNP GLUCOSAME CHONDROITIN DS",
              "GNP GLUCOSAMINE CHONDROIT DS": "GNP GLUCOSAMINE CHONDROIT DS",
              "GNP GLUCOSAMINE CHONDROIT TS": "GNP GLUCOSAMINE CHONDROIT TS",
              "GNP GLUCOSAMINE CHONDROITIN": "GNP GLUCOSAMINE CHONDROITIN",
              "GNP GLUCOSAMINE COMPLEX": "GNP GLUCOSAMINE COMPLEX",
              "GNP MENS PROSTATE HEALTH": "GNP MENS PROSTATE HEALTH",
              "GNP RESVERATROL RED WINE EXT": "GNP RESVERATROL RED WINE EXT"
            },
            "BIO-TECH": {
              "ADRENAL": "ADRENAL",
              "ARTEMISIA": "ARTEMISIA",
              "BIO-FLAV": "BIO-FLAV",
              "CHLORELLA": "CHLORELLA",
              "PROANTHO": "PROANTHO",
              "THISILIBIN": "THISILIBIN"
            },
            "BIONEURIX": {
              "BLOOD SUGAR 360": "BLOOD SUGAR 360",
              "SEREDYN": "SEREDYN"
            },
            "BODY CHOICE": {
              "BODY CHOICE HOODIA WEIGHT LOSS": "BODY CHOICE HOODIA WEIGHT LOSS"
            },
            "CHAIN DRUG CONSORTIUM": {
              "COLON CLEANSER": "COLON CLEANSER",
              "COLON-AID": "COLON-AID",
              "DIABETES SUPPORT": "DIABETES SUPPORT",
              "ENERGY FOCUS": "ENERGY FOCUS"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC GLUCOSAMINE-CHONDROITIN-MSM": "QC GLUCOSAMINE-CHONDROITIN-MSM"
            },
            "CLARION BRANDS": {
              "CYSTEX URINARY HEALTH": "CYSTEX URINARY HEALTH"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS GLUCOS-CHONDROIT-MSM TS": "CVS GLUCOS-CHONDROIT-MSM TS"
            },
            "DOMEL LABORATORIES": {
              "ALZ": "ALZ",
              "SKELAGESIC": "SKELAGESIC"
            },
            "DSE HEALTHCARE SOLUTIONS": {
              "LYDIA PINKHAM": "LYDIA PINKHAM",
              "URINOZINC PLUS": "URINOZINC PLUS",
              "URINOZINC PROSTATE": "URINOZINC PROSTATE"
            },
            "ENDURANCE PRODUCTS": {
              "MIXED TOCOTRIENOLS W/ VITA E": "MIXED TOCOTRIENOLS W/ VITA E"
            },
            "ENOVACHEM MANUFACTURING": {
              "COLOX": "COLOX",
              "DETOXAREX": "DETOXAREX",
              "GLUCOLESS": "GLUCOLESS",
              "MISEFLEX": "MISEFLEX",
              "MISEFLEX-C": "MISEFLEX-C",
              "NARCOSOFT HERBAL LAX": "NARCOSOFT HERBAL LAX",
              "NARCOSOFT II": "NARCOSOFT II",
              "ORTHODIET": "ORTHODIET",
              "REFEX": "REFEX",
              "TOPROPHAN": "TOPROPHAN",
              "TUMERSAID": "TUMERSAID",
              "VALINEX": "VALINEX"
            },
            "EQUALINE": {
              "EQL GLUCOSAMINE CHONDROITIN": "EQL GLUCOSAMINE CHONDROITIN",
              "EQL GLUCOSAMINE-CHONDROIT-MSM": "EQL GLUCOSAMINE-CHONDROIT-MSM",
              "EQL GREEN TEA COMPLEX": "EQL GREEN TEA COMPLEX",
              "EQL SUPER OMEGA-3": "EQL SUPER OMEGA-3"
            },
            "GENERAL NUTRITION CORP": {
              "ENERGEL": "ENERGEL",
              "EPHADRENE": "EPHADRENE",
              "PRO CUT": "PRO CUT"
            },
            "GINESIS NATURAL PRODUCTS": {
              "NOT NICE TO LICE": "NOT NICE TO LICE"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "PRO NUTRIENTS FRUIT & VEGGIE": "PRO NUTRIENTS FRUIT & VEGGIE"
            },
            "HERBS ETC": {
              "ALLERGY RELEAF SYSTEM": "ALLERGY RELEAF SYSTEM",
              "BUG ITCH RELEAF": "BUG ITCH RELEAF",
              "CRAMP RELEAF": "CRAMP RELEAF",
              "CRANBLADDER RELEAF": "CRANBLADDER RELEAF",
              "DEEP HEALTH": "DEEP HEALTH",
              "DEEP SLEEP": "DEEP SLEEP",
              "EARLY ALERT": "EARLY ALERT",
              "ESSIAC TONIC": "ESSIAC TONIC",
              "HERBAPROFEN": "HERBAPROFEN",
              "IVY ITCH RELEAF": "IVY ITCH RELEAF",
              "LOVIRAL": "LOVIRAL",
              "LUNG TONIC": "LUNG TONIC",
              "LYMPHATONIC": "LYMPHATONIC",
              "MENOPAUTONIC": "MENOPAUTONIC",
              "MOUTH TONIC": "MOUTH TONIC",
              "MULLEIN GARLIC EAR DROPS": "MULLEIN GARLIC EAR DROPS",
              "OSHA ROOT COUGH": "OSHA ROOT COUGH",
              "PHYTOCILLIN": "PHYTOCILLIN",
              "RESPIRATONIC": "RESPIRATONIC",
              "SINGERS SAVING GRACE THROAT": "SINGERS SAVING GRACE THROAT",
              "STRESS RELEAF": "STRESS RELEAF"
            },
            "HIGH CHEMICAL COMPANY": {
              "SARAPIN": "SARAPIN"
            },
            "I-HEALTH": {
              "ESTROVEN + ENERGY MAX STRENGTH": "ESTROVEN + ENERGY MAX STRENGTH",
              "ESTROVEN ENERGY": "ESTROVEN ENERGY"
            },
            "INTEGRATIVE THERAPEUTICS": {
              "7-KETO LEAN": "7-KETO LEAN",
              "AM/PM MENOPAUSE FORMULA": "AM/PM MENOPAUSE FORMULA",
              "AM/PM PERIMENOPAUSE FORMULA": "AM/PM PERIMENOPAUSE FORMULA",
              "ARTERIAL THERAPY": "ARTERIAL THERAPY",
              "CORTISOL MANAGER": "CORTISOL MANAGER",
              "CURCUMAX PRO": "CURCUMAX PRO",
              "END FATIGUE REVITALIZING SLEEP": "END FATIGUE REVITALIZING SLEEP",
              "ESBERITOX": "ESBERITOX",
              "HDL RX": "HDL RX",
              "ITI MAN": "ITI MAN",
              "ITI WOMAN": "ITI WOMAN",
              "LAXATIVE FORMULA": "LAXATIVE FORMULA",
              "NEURO-ESSENTIALS": "NEURO-ESSENTIALS",
              "NF FORMULAS CA MG/BOTANICALS": "NF FORMULAS CA MG/BOTANICALS",
              "NF FORMULAS CHROMIUM FORTE": "NF FORMULAS CHROMIUM FORTE",
              "NF FORMULAS CRANBERRY FORTE": "NF FORMULAS CRANBERRY FORTE",
              "NF FORMULAS TESTOSTERONE": "NF FORMULAS TESTOSTERONE",
              "PANTETHINE PLUS": "PANTETHINE PLUS",
              "PARA-GARD": "PARA-GARD",
              "PMS FORMULA INDOLPLEX": "PMS FORMULA INDOLPLEX",
              "PURE HARVEST GREENS": "PURE HARVEST GREENS",
              "PURE HARVEST GREENS INFUSION": "PURE HARVEST GREENS INFUSION",
              "RESVERATROL ULTRA": "RESVERATROL ULTRA",
              "SEASONAL IC": "SEASONAL IC",
              "TYLER INDOLPLEX": "TYLER INDOLPLEX",
              "WHOLE BODY CLEANSING SYSTEM": "WHOLE BODY CLEANSING SYSTEM",
              "YEAST BALANCE ICS": "YEAST BALANCE ICS"
            },
            "KRS GLOBAL BIOTECHNOLOGY": {
              "MELLOW-TONE": "MELLOW-TONE"
            },
            "LEADER BRAND PRODUCTS": {
              "GLUCOSAMINE CHOND COMPLEX/MSM": "GLUCOSAMINE CHOND COMPLEX/MSM",
              "GLUCOSAMINE CHONDROITIN ADV": "GLUCOSAMINE CHONDROITIN ADV",
              "RED WINE EXTRACT": "RED WINE EXTRACT"
            },
            "LINPHARMA": {
              "PETADOLEX 50": "PETADOLEX 50",
              "PETADOLEX 75": "PETADOLEX 75"
            },
            "LIVING WELL PHARMACY": {
              "IMUHANCE": "IMUHANCE"
            },
            "MAGNO-HUMPHRIES LABORATORIES": {
              "PAU D ARCO": "PAU D ARCO"
            },
            "MAJOR PHARMACEUTICALS": {
              "CALCIUM PYRUVATE": "CALCIUM PYRUVATE",
              "CHOLESTATIN": "CHOLESTATIN",
              "GARLIN": "GARLIN",
              "GERMANIUM": "GERMANIUM",
              "OCTACOSANOL": "OCTACOSANOL",
              "PUMPKIN SEED OIL": "PUMPKIN SEED OIL"
            },
            "MASON VITAMINS": {
              "BEAUTY & SKIN THERAPY": "BEAUTY & SKIN THERAPY",
              "BLOOD SUGAR BALANCE": "BLOOD SUGAR BALANCE",
              "CALCIUM PLUS ADVANCED": "CALCIUM PLUS ADVANCED",
              "CALMME": "CALMME",
              "CHROMIUM PICOLINATE KLB6": "CHROMIUM PICOLINATE KLB6",
              "COLON HERBAL CLEANSER": "COLON HERBAL CLEANSER",
              "CRANBERRY/PROBIOTIC": "CRANBERRY/PROBIOTIC",
              "GLUCOSAMINE CHONDROITIN ADV": "GLUCOSAMINE CHONDROITIN ADV",
              "GLUCOSAMINE CHONDROITIN VIT D3": "GLUCOSAMINE CHONDROITIN VIT D3",
              "GREEN TEA SLIM": "GREEN TEA SLIM",
              "HORNY GOAT WEED PLUS": "HORNY GOAT WEED PLUS",
              "IN-FLA-MEND": "IN-FLA-MEND",
              "LEG VEIN & CIRCULATION": "LEG VEIN & CIRCULATION",
              "MAXIMUM ENERGY": "MAXIMUM ENERGY",
              "MENS POTENT FORMULA": "MENS POTENT FORMULA",
              "PROSTATE": "PROSTATE",
              "PROSTATE THERAPY COMPLEX": "PROSTATE THERAPY COMPLEX",
              "RED WINE EXTRACT PLUS": "RED WINE EXTRACT PLUS",
              "RELAX & SLEEP": "RELAX & SLEEP",
              "SOUTH AFRICAN HOODIA PLUS": "SOUTH AFRICAN HOODIA PLUS",
              "SUPER ENERGY": "SUPER ENERGY",
              "TART CHERRY ADVANCED": "TART CHERRY ADVANCED"
            },
            "MCKESSON": {
              "HM ESTROPLUS": "HM ESTROPLUS",
              "HM GLUCOSAMINE CHONDROITIN": "HM GLUCOSAMINE CHONDROITIN",
              "HM JOINT HEALTH ULTRA": "HM JOINT HEALTH ULTRA",
              "HM SAW PALMETTO COMPLEX": "HM SAW PALMETTO COMPLEX"
            },
            "MCKESSON SUNMARK": {
              "SM ECHINACEA-GOLDENSEAL": "SM ECHINACEA-GOLDENSEAL",
              "SM GLUCOSAMINE CHONDROITIN": "SM GLUCOSAMINE CHONDROITIN",
              "SM GLUCOSAMINE CHONDROITIN MSM": "SM GLUCOSAMINE CHONDROITIN MSM",
              "SM SAW PALMETTO COMPLEX": "SM SAW PALMETTO COMPLEX"
            },
            "MEDA CONSUMER HEALTHCARE": {
              "COLONAIDE": "COLONAIDE",
              "MIDNITE": "MIDNITE",
              "MIDNITE FOR MENOPAUSE": "MIDNITE FOR MENOPAUSE",
              "MIDNITE PM": "MIDNITE PM"
            },
            "MEDINATURA": {
              "T-RELIEF CBD+13": "T-RELIEF CBD+13"
            },
            "MEDTECH": {
              "MURINE EARIGATE ADULT": "MURINE EARIGATE ADULT",
              "MURINE EARIGATE KIDS": "MURINE EARIGATE KIDS"
            },
            "MILLER": {
              "DMG": "DMG",
              "DUODENUM": "DUODENUM",
              "ISO-PH": "ISO-PH",
              "M2 MANGANESE": "M2 MANGANESE",
              "PLACENTA": "PLACENTA"
            },
            "NAT-RUL HEALTH PRODUCTS": {
              "ALFALFA": "ALFALFA",
              "DANDELION ROOT": "DANDELION ROOT",
              "GINSENG-COMPLEX": "GINSENG-COMPLEX",
              "GINSENG-SIBERIAN": "GINSENG-SIBERIAN",
              "LECI-THIN DIET AID": "LECI-THIN DIET AID",
              "MATURE MANS HERBAL REMEDY": "MATURE MANS HERBAL REMEDY",
              "MAXIMUM MEMORY": "MAXIMUM MEMORY",
              "MAXIMUM VIRILITY TR": "MAXIMUM VIRILITY TR",
              "NATURAL HERBAL DIURETIC PLUS": "NATURAL HERBAL DIURETIC PLUS",
              "POMEGRANATE-ACAI BERRY": "POMEGRANATE-ACAI BERRY",
              "SILYMARIN": "SILYMARIN",
              "SUPER CRANBERRY/C": "SUPER CRANBERRY/C",
              "SUPER GRAPEFRUIT N FIBER DIET": "SUPER GRAPEFRUIT N FIBER DIET",
              "SUPER OAT BRAN": "SUPER OAT BRAN"
            },
            "NATIONAL VITAMIN": {
              "COLD DEFENSE FIGHTER": "COLD DEFENSE FIGHTER",
              "COLON CLEANSE": "COLON CLEANSE",
              "METABO-STYLE": "METABO-STYLE",
              "NATURAL EMU RELIEF": "NATURAL EMU RELIEF",
              "PROSTATE CONTROL": "PROSTATE CONTROL",
              "RECHARGE": "RECHARGE",
              "REST/RELAXATION": "REST/RELAXATION"
            },
            "NATROL": {
              "HORNY GOAT WEED": "HORNY GOAT WEED",
              "HOT FLASHEX": "HOT FLASHEX",
              "RESVERATROL DIET": "RESVERATROL DIET"
            },
            "NATURE'S VISION": {
              "LUTEIN 20": "LUTEIN 20"
            },
            "NATURES BOUNTY": {
              "APPLE CIDER VINEGAR DIET": "APPLE CIDER VINEGAR DIET",
              "BREAST SOLUTIONS": "BREAST SOLUTIONS",
              "COLON CLEANSER": "COLON CLEANSER",
              "ECHINACEA GOLDENSEAL PLUS": "ECHINACEA GOLDENSEAL PLUS",
              "FLEX-A-MIN BONE SHIELD": "FLEX-A-MIN BONE SHIELD",
              "FLEX-A-MIN DOUBLE STRENGTH": "FLEX-A-MIN DOUBLE STRENGTH",
              "FLEX-A-MIN JOINT FLEX": "FLEX-A-MIN JOINT FLEX",
              "FLEX-A-MIN SUPER GLUCOSAMINE": "FLEX-A-MIN SUPER GLUCOSAMINE",
              "HIMALAYAN GOJI": "HIMALAYAN GOJI",
              "JOINT SUPPORT COMPLEX": "JOINT SUPPORT COMPLEX",
              "POWER-V": "POWER-V",
              "RED WINE COMPLEX": "RED WINE COMPLEX",
              "RED WINE EXTRACT PLUS": "RED WINE EXTRACT PLUS",
              "SAW PALMETTO COMPLEX EX ST": "SAW PALMETTO COMPLEX EX ST",
              "SAW PALMETTO PLUS": "SAW PALMETTO PLUS",
              "SUPER GREEN TEA DIET": "SUPER GREEN TEA DIET",
              "SUPER GREENS": "SUPER GREENS",
              "TURMERIC CURCUMIN": "TURMERIC CURCUMIN",
              "XTREME LEAN": "XTREME LEAN"
            },
            "NEW WORLD HOLDINGS": {
              "GLUCOSA FACTOR HIGH BLOOD GLUC": "GLUCOSA FACTOR HIGH BLOOD GLUC",
              "GLUCOSA IMMUNE BOOSTER": "GLUCOSA IMMUNE BOOSTER"
            },
            "NOW HEALTH GROUP": {
              "BETA-SITOSTEROL PLANT STEROLS": "BETA-SITOSTEROL PLANT STEROLS"
            },
            "NUTRAMAX LABORATORIES": {
              "COSAMIN ASU ADVANCED FORMULA": "COSAMIN ASU ADVANCED FORMULA",
              "COSAMIN ASU FOR JOINT HEALTH": "COSAMIN ASU FOR JOINT HEALTH",
              "COSAMIN VERDE FOR JOINT HEALTH": "COSAMIN VERDE FOR JOINT HEALTH"
            },
            "NUTRITIONAL DESIGNS": {
              "CRAN-B-OTC": "CRAN-B-OTC",
              "FIBER 7": "FIBER 7",
              "FIBER SUPREME": "FIBER SUPREME"
            },
            "OAKHURST": {
              "SWAMP ROOT": "SWAMP ROOT"
            },
            "PERRIGO": {
              "GOODSENSE GLUCOSAMINE COMPLEX": "GOODSENSE GLUCOSAMINE COMPLEX"
            },
            "PHARMACEUTICA NORTH AMERICA": {
              "SINTRALYNE-PM": "SINTRALYNE-PM"
            },
            "PHARMANEX": {
              "CORDYMAX CS-4": "CORDYMAX CS-4"
            },
            "PHARMAVITE": {
              "ACAI+SUPERFRUIT/GREEN TEA": "ACAI+SUPERFRUIT/GREEN TEA",
              "TRIPLE FLEX": "TRIPLE FLEX"
            },
            "PREFERRED PHARMACEUTICALS": {
              "NARCOSOFT HERBAL LAX": "NARCOSOFT HERBAL LAX"
            },
            "PRINCETON RESEARCH": {
              "GLUCOSAMINE CHONDROITIN MSM": "GLUCOSAMINE CHONDROITIN MSM"
            },
            "PROFICIENT RX": {
              "NARCOSOFT HERBAL LAX": "NARCOSOFT HERBAL LAX"
            },
            "PUBLIX SUPER MARKETS INC.": {
              "PX GLUCOSAMINE-CHONDROITIN": "PX GLUCOSAMINE-CHONDROITIN"
            },
            "QUEST PRODUCTS": {
              "PROVENT SKIN TAG REMOVER": "PROVENT SKIN TAG REMOVER",
              "PROVENT VERTIGO X": "PROVENT VERTIGO X"
            },
            "REESE PHARMACEUTICAL": {
              "CHOLESTEROL SUPPORT": "CHOLESTEROL SUPPORT",
              "DETOX": "DETOX",
              "GINKGO BILOBA": "GINKGO BILOBA",
              "INNERMOST NATURE FOR MEN": "INNERMOST NATURE FOR MEN",
              "PROSTATE SUPPORT": "PROSTATE SUPPORT",
              "PYCNOGENOL PLUS": "PYCNOGENOL PLUS"
            },
            "REXALL SUNDOWN": {
              "BLACK CHERRY CONCENTRATE": "BLACK CHERRY CONCENTRATE",
              "CHROMIUM PICOLINATE FORTIFIED": "CHROMIUM PICOLINATE FORTIFIED",
              "CHROMIUM XTRA": "CHROMIUM XTRA",
              "ECHINACEA GOLDENSEAL PLUS": "ECHINACEA GOLDENSEAL PLUS",
              "GINKGO BILOBA XTRA": "GINKGO BILOBA XTRA",
              "GINSENG XTRA": "GINSENG XTRA",
              "GLUCOSAMINE CHONDROITIN ADV": "GLUCOSAMINE CHONDROITIN ADV",
              "GRAPE SEED XTRA": "GRAPE SEED XTRA",
              "HIMALAYAN GOJI": "HIMALAYAN GOJI",
              "LUTEIN VISION BLEND": "LUTEIN VISION BLEND",
              "OSTEO BI-FLEX ADV DOUBLE ST": "OSTEO BI-FLEX ADV DOUBLE ST",
              "OSTEO BI-FLEX ADV JOINT SHIELD": "OSTEO BI-FLEX ADV JOINT SHIELD",
              "OSTEO BI-FLEX ADV TRIPLE ST": "OSTEO BI-FLEX ADV TRIPLE ST",
              "OSTEO BI-FLEX JOINT SHIELD": "OSTEO BI-FLEX JOINT SHIELD",
              "OSTEO BI-FLEX TRIPLE STRENGTH": "OSTEO BI-FLEX TRIPLE STRENGTH",
              "OSTEO BI-FLEX/5-LOXIN ADVANCED": "OSTEO BI-FLEX/5-LOXIN ADVANCED",
              "PROSTATE HEALTH": "PROSTATE HEALTH",
              "PYCNOGENOL COMPLEX": "PYCNOGENOL COMPLEX",
              "RED WINE EXTRACT PLUS": "RED WINE EXTRACT PLUS",
              "SAW PALMETTO XTRA": "SAW PALMETTO XTRA",
              "ST JOHNS WORT XTRA": "ST JOHNS WORT XTRA",
              "SUPER SNOOZE": "SUPER SNOOZE"
            },
            "RITE AID CORPORATION": {
              "RA ESTROPLUS MAX STRENGTH": "RA ESTROPLUS MAX STRENGTH",
              "RA GLUCOSAMINE-CHONDROIT-MSM": "RA GLUCOSAMINE-CHONDROIT-MSM",
              "RA GLUCOSAMINE-CHONDROIT-MSM-D": "RA GLUCOSAMINE-CHONDROIT-MSM-D",
              "RA GLUCOSAMINE-CHONDROITIN": "RA GLUCOSAMINE-CHONDROITIN",
              "RA XYDRA EF": "RA XYDRA EF"
            },
            "SEA-BAND": {
              "SPATONE IRON PLUS": "SPATONE IRON PLUS"
            },
            "TARGET": {
              "TGT GLUCOSAMINE CHONDR COMPLEX": "TGT GLUCOSAMINE CHONDR COMPLEX"
            },
            "TEVA PHARMACEUTICALS USA": {
              "SINUS FORMULA": "SINUS FORMULA"
            },
            "THE KEY COMPANY": {
              "ADRENAL": "ADRENAL",
              "ALGA-K": "ALGA-K",
              "PINEAPPA": "PINEAPPA",
              "R.N.A. - 180": "R.N.A. - 180",
              "SPLEEN": "SPLEEN",
              "WABANA": "WABANA"
            },
            "TRUCEUTICALS": {
              "NOXICARE NATURAL PAIN RELIEF": "NOXICARE NATURAL PAIN RELIEF"
            },
            "TYSON": {
              "THERADOPHILUS": "THERADOPHILUS"
            },
            "UPSPRING": {
              "MILKFLOW": "MILKFLOW",
              "MILKFLOW MAX": "MILKFLOW MAX",
              "MORNING SICKLESS": "MORNING SICKLESS",
              "MORNINGSICKLESS": "MORNINGSICKLESS",
              "STOMACH SETTLE": "STOMACH SETTLE"
            },
            "WAL-MART": {
              "BLACK COHOSH MENOPAUSE COMPLEX": "BLACK COHOSH MENOPAUSE COMPLEX",
              "EQ GLUCOSAMINE CHONDR/MSM/D": "EQ GLUCOSAMINE CHONDR/MSM/D",
              "EQ GLUCOSAMINE-CHONDROITIN-MSM": "EQ GLUCOSAMINE-CHONDROITIN-MSM",
              "GRAPE SEED COMPLEX": "GRAPE SEED COMPLEX",
              "SAW PALMETTO": "SAW PALMETTO"
            },
            "WALGREENS": {
              "ADVANCED JOINT RELIEF": "ADVANCED JOINT RELIEF",
              "CHOLESTEROL RELIEF": "CHOLESTEROL RELIEF",
              "ECZEMA & PSORIASIS SPRAY": "ECZEMA & PSORIASIS SPRAY",
              "GLUCOS-CHONDROIT-MSM COMPLEX": "GLUCOS-CHONDROIT-MSM COMPLEX",
              "GLUCOSAMINE CHOND COMPLEX/MSM": "GLUCOSAMINE CHOND COMPLEX/MSM",
              "GLUCOSAMINE CHOND DOUBLE STR": "GLUCOSAMINE CHOND DOUBLE STR",
              "GLUCOSAMINE CHOND MSM FORMULA": "GLUCOSAMINE CHOND MSM FORMULA",
              "GLUCOSAMINE CHONDROITIN ADV": "GLUCOSAMINE CHONDROITIN ADV",
              "GLUCOSAMINE CHONDROITIN MSM": "GLUCOSAMINE CHONDROITIN MSM",
              "GLUCOSAMINE CHONDROITIN TRIPLE": "GLUCOSAMINE CHONDROITIN TRIPLE",
              "GLUCOSAMINE-CHONDROITIN DS": "GLUCOSAMINE-CHONDROITIN DS",
              "GREEN TEA": "GREEN TEA",
              "JOINT HEALTH": "JOINT HEALTH",
              "JOINT SUPPORT": "JOINT SUPPORT",
              "SUPER-D3+": "SUPER-D3+",
              "TOTAL BODY CLEANSE": "TOTAL BODY CLEANSE",
              "VERTIGOX": "VERTIGOX"
            },
            "WELEDA": {
              "AURUM": "AURUM",
              "CHELIDONIUM COMPOUND": "CHELIDONIUM COMPOUND",
              "ECHINACEA COMPOUND": "ECHINACEA COMPOUND",
              "ERYSIDORON #2": "ERYSIDORON #2"
            },
            "WINDMILL CONSUMER PRODUCTS": {
              "COMPLETE MENOPAUSE HEALTH": "COMPLETE MENOPAUSE HEALTH",
              "COMPLETE PROSTATE HEALTH": "COMPLETE PROSTATE HEALTH",
              "GLUCOSAMINE-CHONDROITIN SULF": "GLUCOSAMINE-CHONDROITIN SULF",
              "JOINT LUBE": "JOINT LUBE",
              "OMEGA 3-GREEN TEA-CLA": "OMEGA 3-GREEN TEA-CLA",
              "TOTAL CARDIO HEALTH FORMULA": "TOTAL CARDIO HEALTH FORMULA",
              "TOTAL MEMORY & FOCUS FORMULA": "TOTAL MEMORY & FOCUS FORMULA"
            },
            "WYNNPHARM": {
              "ESTROMINERAL": "ESTROMINERAL"
            },
            "XYMOGEN": {
              "CORTISOLV": "CORTISOLV",
              "RELAXMAX": "RELAXMAX"
            }
          }
        },
        "Miscellaneous Therapeutic Classes": {
          "Miscellaneous Therapeutic Classes": {
            "21ST CENTURY HEALTHCARE": {
              "GELATIN": "GELATIN"
            },
            "A-S MEDICATION SOLUTIONS": {
              "AMMONIA AROMATIC": "AMMONIA AROMATIC"
            },
            "BASIC DRUGS": {
              "GELATIN": "GELATIN"
            },
            "CHAIN DRUG CONSORTIUM": {
              "GELATIN": "GELATIN"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC AROMATIC AMMONIA": "QC AROMATIC AMMONIA"
            },
            "DYNAREX CORPORATION": {
              "AMMONIA INHALANTS": "AMMONIA INHALANTS"
            },
            "FAGRON COMPOUNDING SERVICES": {
              "PHENOL": "PHENOL"
            },
            "FREEDA VITAMINS": {
              "CHLOROPHYLL-ALFALFA": "CHLOROPHYLL-ALFALFA"
            },
            "GLENWOOD": {
              "AMMONIA AROMATIC": "AMMONIA AROMATIC"
            },
            "HERBS ETC": {
              "CHLOROXYGEN": "CHLOROXYGEN"
            },
            "HUMCO": {
              "AMMONIA AROMATIC": "AMMONIA AROMATIC"
            },
            "MASON VITAMINS": {
              "NAIL STRENGTHENER/GELATIN": "NAIL STRENGTHENER/GELATIN"
            },
            "NAT-RUL HEALTH PRODUCTS": {
              "GELATIN": "GELATIN"
            },
            "NATIONAL VITAMIN": {
              "GELATIN": "GELATIN"
            },
            "NATURES BOUNTY": {
              "GELATIN": "GELATIN"
            },
            "NEXCO PHARMA": {
              "NEXAVIR": "NEXAVIR"
            },
            "OAKHURST": {
              "ENNDS": "ENNDS"
            },
            "PCCA": {
              "ADENOSINE-5-MONOPHOSPHATE": "ADENOSINE-5-MONOPHOSPHATE",
              "ADENOSINE-5-TRIPHOSPHATE": "ADENOSINE-5-TRIPHOSPHATE"
            },
            "REXALL SUNDOWN": {
              "GELATIN": "GELATIN"
            },
            "RUGBY LABORATORIES": {
              "CHLOROPHYLL": "CHLOROPHYLL"
            },
            "SHEFFIELD PHARMACEUTICALS": {
              "NULLO": "NULLO"
            },
            "WAL-MART": {
              "GELATIN": "GELATIN"
            },
            "WINDMILL CONSUMER PRODUCTS": {
              "GELATIN": "GELATIN"
            },
            "X-GEN PHARMACEUTICALS, INC": {
              "AMMONIA AROMATIC": "AMMONIA AROMATIC"
            },
            "YOUNG DENTAL": {
              "AMMONIA AROMATIC": "AMMONIA AROMATIC"
            }
          }
        },
        "Organ Preservation Solutions": {
          "Organ Preservation Solutions": {
            "TEVA PHARMACEUTICALS USA": {
              "VIASPAN": "VIASPAN"
            }
          }
        },
        "Peritoneal Dialysis Solutions": {
          "Peritoneal Dialysis Solutions": {
            "BAXTER HEALTHCARE CORP": {
              "DIANEAL LOW CALCIUM/1.5% DEX": "DIANEAL LOW CALCIUM/1.5% DEX",
              "DIANEAL LOW CALCIUM/2.5% DEX": "DIANEAL LOW CALCIUM/2.5% DEX",
              "DIANEAL LOW CALCIUM/4.25% DEX": "DIANEAL LOW CALCIUM/4.25% DEX",
              "DIANEAL PD-2/1.5% DEXTROSE": "DIANEAL PD-2/1.5% DEXTROSE",
              "DIANEAL PD-2/2.5% DEXTROSE": "DIANEAL PD-2/2.5% DEXTROSE",
              "DIANEAL PD-2/4.25% DEXTROSE": "DIANEAL PD-2/4.25% DEXTROSE",
              "EXTRANEAL": "EXTRANEAL",
              "ULTRABAG/DIANEAL PD-2/1.5% DEX": "ULTRABAG/DIANEAL PD-2/1.5% DEX",
              "ULTRABAG/DIANEAL PD-2/2.5% DEX": "ULTRABAG/DIANEAL PD-2/2.5% DEX",
              "ULTRABAG/DIANEAL PD-2/4.25%DEX": "ULTRABAG/DIANEAL PD-2/4.25%DEX",
              "ULTRABAG/DIANEAL/1.5% DEXTROSE": "ULTRABAG/DIANEAL/1.5% DEXTROSE",
              "ULTRABAG/DIANEAL/2.5% DEXTROSE": "ULTRABAG/DIANEAL/2.5% DEXTROSE",
              "ULTRABAG/DIANEAL/4.25% DEX": "ULTRABAG/DIANEAL/4.25% DEX"
            },
            "FRESENIUS": {
              "DELFLEX-LC/1.5% DEXTROSE": "DELFLEX-LC/1.5% DEXTROSE",
              "DELFLEX-LC/2.5% DEXTROSE": "DELFLEX-LC/2.5% DEXTROSE",
              "DELFLEX-LC/4.25% DEXTROSE": "DELFLEX-LC/4.25% DEXTROSE",
              "DELFLEX-SM/1.5% DEXTROSE": "DELFLEX-SM/1.5% DEXTROSE",
              "DELFLEX-SM/2.5% DEXTROSE": "DELFLEX-SM/2.5% DEXTROSE"
            }
          }
        },
        "Potassium Removing Agents": {
          "Potassium Removing Agents": {
            "ALEXSO": {
              "SODIUM POLYSTYRENE SULFONATE": "SODIUM POLYSTYRENE SULFONATE"
            },
            "ASTRAZENECA": {
              "LOKELMA": "LOKELMA"
            },
            "ATLANTIC BIOLOGICALS": {
              "KALEXATE": "KALEXATE"
            },
            "BLU PHARMACEUTICALS": {
              "SODIUM POLYSTYRENE SULFONATE": "SODIUM POLYSTYRENE SULFONATE"
            },
            "CARDINAL HEALTH REPACKAGING SO": {
              "SPS": "SPS"
            },
            "CMP PHARMA": {
              "SODIUM POLYSTYRENE SULFONATE": "SODIUM POLYSTYRENE SULFONATE",
              "SPS": "SPS"
            },
            "CONCORDIA PHARMACEUTICALS": {
              "KAYEXALATE": "KAYEXALATE"
            },
            "ECI PHARMACEUTICALS": {
              "SODIUM POLYSTYRENE SULFONATE": "SODIUM POLYSTYRENE SULFONATE"
            },
            "EPIC PHARMA": {
              "SODIUM POLYSTYRENE SULFONATE": "SODIUM POLYSTYRENE SULFONATE"
            },
            "EXACT-RX": {
              "SODIUM POLYSTYRENE SULFONATE": "SODIUM POLYSTYRENE SULFONATE"
            },
            "HIKMA": {
              "SODIUM POLYSTYRENE SULFONATE": "SODIUM POLYSTYRENE SULFONATE"
            },
            "KVK TECH": {
              "SODIUM POLYSTYRENE SULFONATE": "SODIUM POLYSTYRENE SULFONATE"
            },
            "LEADING PHARMA": {
              "SODIUM POLYSTYRENE SULFONATE": "SODIUM POLYSTYRENE SULFONATE"
            },
            "LOHXA": {
              "SODIUM POLYSTYRENE SULFONATE": "SODIUM POLYSTYRENE SULFONATE"
            },
            "MARLEX PHARMACEUTICALS": {
              "SODIUM POLYSTYRENE SULFONATE": "SODIUM POLYSTYRENE SULFONATE"
            },
            "PERRIGO": {
              "KIONEX": "KIONEX",
              "SODIUM POLYSTYRENE SULFONATE": "SODIUM POLYSTYRENE SULFONATE"
            },
            "RELYPSA": {
              "VELTASSA": "VELTASSA"
            },
            "SUNRISE PHARMACEUTICAL": {
              "SODIUM POLYSTYRENE SULFONATE": "SODIUM POLYSTYRENE SULFONATE"
            },
            "TRIGEN LABORATORIES": {
              "SODIUM POLYSTYRENE SULFONATE": "SODIUM POLYSTYRENE SULFONATE"
            }
          }
        },
        "Prostaglandins": {
          "Prostaglandins": {
            "PFIZER U.S.": {
              "PROSTIN VR": "PROSTIN VR"
            },
            "TEVA PARENTERAL MEDICINES": {
              "ALPROSTADIL": "ALPROSTADIL"
            }
          }
        },
        "Sclerosing Agents": {
          "Sclerosing Agents": {
            "AXIA PHARMACEUTICAL": {
              "POLIDOCANOL": "POLIDOCANOL"
            },
            "BIOCOMPATIBLES": {
              "VARITHENA": "VARITHENA"
            },
            "LEUCADIA PHARMACEUTICALS": {
              "SODIUM TETRADECYL SULFATE": "SODIUM TETRADECYL SULFATE"
            },
            "MERZ NORTH AMERICA": {
              "ASCLERA": "ASCLERA"
            },
            "MYLAN INSTITUTIONAL": {
              "SOTRADECOL": "SOTRADECOL"
            },
            "QOL MEDICAL": {
              "ETHAMOLIN": "ETHAMOLIN"
            },
            "QUESTCOR": {
              "ETHAMOLIN": "ETHAMOLIN"
            }
          }
        },
        "Systemic Lupus Erythematosus Agents": {
          "B-Lymphocyte Stimulator (BLyS)-Specific Inhibitors": {
            "GLAXO SMITH KLINE": {
              "BENLYSTA": "BENLYSTA"
            }
          }
        },
        "Thickened Products": {
          "Thickened Products": {
            "KENT PRECISION FOODS": {
              "THICK-IT": "THICK-IT"
            }
          }
        }
      },
      "MOUTH/THROAT/DENTAL AGENTS": {
        "Anesthetics Topical Oral": {
          "Anesthetics Topical Oral": {
            "A-S MEDICATION SOLUTIONS": {
              "BENZODENT": "BENZODENT",
              "LIDOCAINE VISCOUS HCL": "LIDOCAINE VISCOUS HCL"
            },
            "AKORN": {
              "LIDOCAINE VISCOUS HCL": "LIDOCAINE VISCOUS HCL"
            },
            "AMERICAN SALES COMPANY": {
              "ANESTHETIC MAXIMUM STRENGTH": "ANESTHETIC MAXIMUM STRENGTH",
              "ORAL PAIN RELIEF MAX ST": "ORAL PAIN RELIEF MAX ST"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP ORAL PAIN RELIEF": "GNP ORAL PAIN RELIEF"
            },
            "APOTHECUS": {
              "ORA-FILM": "ORA-FILM"
            },
            "ATLANTIC BIOLOGICALS": {
              "LIDOCAINE VISCOUS HCL": "LIDOCAINE VISCOUS HCL"
            },
            "BEUTLICH PHARMA": {
              "HURRICAINE": "HURRICAINE",
              "HURRICAINE ONE": "HURRICAINE ONE",
              "HURRIPAK STARTER KIT": "HURRIPAK STARTER KIT"
            },
            "BLAIREX LABS": {
              "ZILACTIN": "ZILACTIN",
              "ZILACTIN BABY": "ZILACTIN BABY",
              "ZILACTIN-B": "ZILACTIN-B"
            },
            "BLISTEX": {
              "KANK-A MOUTH PAIN": "KANK-A MOUTH PAIN"
            },
            "C S  DENT": {
              "DENTS TOOTHACHE": "DENTS TOOTHACHE"
            },
            "CAMELLIX": {
              "AVERTEAX": "AVERTEAX"
            },
            "CETYLITE": {
              "ALLEVACAINE": "ALLEVACAINE"
            },
            "CHAIN DRUG CONSORTIUM": {
              "ORAL PAIN RELIEF": "ORAL PAIN RELIEF",
              "ORAL PAIN RELIEF MAX ST": "ORAL PAIN RELIEF MAX ST"
            },
            "CHATTEM INC": {
              "BENZODENT": "BENZODENT"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "LIDOCAINE VISCOUS HCL": "LIDOCAINE VISCOUS HCL"
            },
            "COLGATE ORAL PHARMACEUTICALS": {
              "ORABASE": "ORABASE",
              "ORABASE MAXIMUM STRENGTH": "ORABASE MAXIMUM STRENGTH",
              "ORABASE-B": "ORABASE-B"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS BABY TEETHING ORAL PAIN": "CVS BABY TEETHING ORAL PAIN",
              "CVS ORAL ANESTHETIC MAX STR": "CVS ORAL ANESTHETIC MAX STR",
              "CVS ORAL PAIN RELIEVER": "CVS ORAL PAIN RELIEVER",
              "CVS ORAL PAIN RELIEVER MAX ST": "CVS ORAL PAIN RELIEVER MAX ST"
            },
            "DIRECT RX": {
              "LIDOCAINE VISCOUS HCL": "LIDOCAINE VISCOUS HCL"
            },
            "GEISS DESTIN & DUNN": {
              "GOODSENSE ORAL PAIN RELIEF": "GOODSENSE ORAL PAIN RELIEF"
            },
            "GERITREX CORPORATION": {
              "BENZ-O-STHETIC": "BENZ-O-STHETIC",
              "DENT-O-KAIN/20": "DENT-O-KAIN/20"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "ANBESOL": "ANBESOL",
              "ANBESOL JR": "ANBESOL JR",
              "ANBESOL MAXIMUM STRENGTH": "ANBESOL MAXIMUM STRENGTH",
              "BABY ANBESOL": "BABY ANBESOL"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "LIDOCAINE VISCOUS HCL": "LIDOCAINE VISCOUS HCL"
            },
            "HIKMA": {
              "LIDOCAINE VISCOUS HCL": "LIDOCAINE VISCOUS HCL"
            },
            "INTRNTL MEDICATION SYSTEM": {
              "LIDOCAINE HCL": "LIDOCAINE HCL"
            },
            "LANNETT": {
              "LIDOCAINE VISCOUS HCL": "LIDOCAINE VISCOUS HCL"
            },
            "LEADER BRAND PRODUCTS": {
              "ORAL ANALGESIC MAX ST": "ORAL ANALGESIC MAX ST"
            },
            "MEDTECH": {
              "LITTLE TEETHERS PAIN RELIEF": "LITTLE TEETHERS PAIN RELIEF"
            },
            "MPM MEDICAL, INC.": {
              "ORAMAGIC PLUS": "ORAMAGIC PLUS"
            },
            "NUCARE PHARMACEUTICALS": {
              "LIDOCAINE VISCOUS HCL": "LIDOCAINE VISCOUS HCL"
            },
            "PAR PHARMACEUTICALS": {
              "LIDOCAINE VISCOUS HCL": "LIDOCAINE VISCOUS HCL"
            },
            "QUALITY CARE": {
              "LIDOCAINE VISCOUS HCL": "LIDOCAINE VISCOUS HCL"
            },
            "QUALITY CARE PRODUCTS": {
              "LIDOCAINE VISCOUS HCL": "LIDOCAINE VISCOUS HCL"
            },
            "RITE AID CORPORATION": {
              "RA ANESTHETIC ORAL": "RA ANESTHETIC ORAL",
              "RA BABY CARE ORAL PAIN RELIEF": "RA BABY CARE ORAL PAIN RELIEF",
              "RA MOUTH PAIN ANESTHETIC": "RA MOUTH PAIN ANESTHETIC",
              "RA ORASOL": "RA ORASOL"
            },
            "SHEFFIELD PHARMACEUTICALS": {
              "ORASTAT MAXIMUM STRENGTH": "ORASTAT MAXIMUM STRENGTH"
            },
            "SKY PACKAGING": {
              "LIDOCAINE VISCOUS HCL": "LIDOCAINE VISCOUS HCL"
            },
            "SSS": {
              "CURASORE": "CURASORE"
            },
            "SULTAN CHEMISTS": {
              "TOPEX TOPICAL ANESTHETIC": "TOPEX TOPICAL ANESTHETIC"
            },
            "UNIT DOSE SERVICES": {
              "LIDOCAINE VISCOUS HCL": "LIDOCAINE VISCOUS HCL"
            },
            "WALGREENS": {
              "BABY TEETHING": "BABY TEETHING",
              "BABY TEETHING PAIN MEDICINE": "BABY TEETHING PAIN MEDICINE",
              "COMPLETE TOOTHACHE KIT": "COMPLETE TOOTHACHE KIT",
              "INTENSE TOOTHACHE PAIN RELIEF": "INTENSE TOOTHACHE PAIN RELIEF",
              "ORAL ANALGESIC MAX ST": "ORAL ANALGESIC MAX ST",
              "ORAL ANESTHETIC": "ORAL ANESTHETIC"
            }
          },
          "Anesthetics Topical Oral - Combinations": {
            "A-S MEDICATION SOLUTIONS": {
              "SORE THROAT": "SORE THROAT"
            },
            "ACTIPHARMA": {
              "ACTISEP": "ACTISEP",
              "ACTISEP (SPRAY)": "ACTISEP (SPRAY)"
            },
            "AIDAREX PHARMACEUTICALS": {
              "SORE THROAT": "SORE THROAT"
            },
            "AZURITY PHARMACEUTICALS": {
              "FIRST-MOUTHWASH BLM": "FIRST-MOUTHWASH BLM"
            },
            "BERGEN BRUNSWIG": {
              "GNP SORE THROAT": "GNP SORE THROAT"
            },
            "BLISTEX": {
              "KANK-A SOFTBRUSH": "KANK-A SOFTBRUSH"
            },
            "CHAIN DRUG CONSORTIUM": {
              "SORE THROAT": "SORE THROAT",
              "SORE THROAT LOZENGES": "SORE THROAT LOZENGES"
            },
            "COMBE": {
              "CEPACOL DUAL RELIEF": "CEPACOL DUAL RELIEF",
              "CEPACOL SORE THROAT": "CEPACOL SORE THROAT",
              "CEPACOL SORE THROAT MAX NUMB": "CEPACOL SORE THROAT MAX NUMB",
              "CEPACOL SORE THROAT SPRAY": "CEPACOL SORE THROAT SPRAY"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS SORE THROAT MAX STRENGTH": "CVS SORE THROAT MAX STRENGTH",
              "CVS TOOTHACHE RELIEF": "CVS TOOTHACHE RELIEF"
            },
            "GERI-CARE": {
              "SORE THROAT RELIEF": "SORE THROAT RELIEF"
            },
            "GIL PHARMACEUTICAL CORP.": {
              "GILTUSS BUCALSEP": "GILTUSS BUCALSEP"
            },
            "KRAMER-NOVIS": {
              "GUMSOL": "GUMSOL"
            },
            "LLORENS PHARMACEUTICAL": {
              "ORASEP": "ORASEP"
            },
            "MAJOR PHARMACEUTICALS": {
              "SORE THROAT": "SORE THROAT"
            },
            "MCLAREN MEDICAL": {
              "G-BUCAL-C": "G-BUCAL-C"
            },
            "MEDICINE SHOPPE": {
              "SORE THROAT LOZENGES": "SORE THROAT LOZENGES"
            },
            "MEDTECH": {
              "CHLORASEPTIC": "CHLORASEPTIC",
              "CHLORASEPTIC KIDS": "CHLORASEPTIC KIDS",
              "CHLORASEPTIC MAX SORE THROAT": "CHLORASEPTIC MAX SORE THROAT",
              "CHLORASEPTIC SORE THROAT": "CHLORASEPTIC SORE THROAT"
            },
            "RECKITT BENCKISER": {
              "CEPACOL": "CEPACOL",
              "CEPACOL EXTRA STRENGTH": "CEPACOL EXTRA STRENGTH",
              "CEPACOL INSTAMAX": "CEPACOL INSTAMAX",
              "CEPACOL SORE THROAT": "CEPACOL SORE THROAT",
              "CEPACOL SORE THROAT EX ST": "CEPACOL SORE THROAT EX ST"
            },
            "REESE PHARMACEUTICAL": {
              "DENTAPAINE": "DENTAPAINE"
            },
            "RITE AID CORPORATION": {
              "RA THROAT LOZENGES": "RA THROAT LOZENGES"
            },
            "SAFECOR HEALTH": {
              "SORE THROAT": "SORE THROAT"
            },
            "TARGET": {
              "TGT LOZENGES": "TGT LOZENGES"
            },
            "TRITON CONSUMER PRODUCTS": {
              "RETRE-GEL": "RETRE-GEL"
            },
            "VETCO, INC.": {
              "MEDADYNE ORAL PAIN RELIEF": "MEDADYNE ORAL PAIN RELIEF"
            },
            "WALGREENS": {
              "SORE THROAT": "SORE THROAT",
              "SORE THROAT LOZENGES": "SORE THROAT LOZENGES",
              "ULTRA THROAT": "ULTRA THROAT"
            }
          }
        },
        "Anti-infectives - Throat": {
          "Anti-infective Combinations - Throat": {
            "AZURITY PHARMACEUTICALS": {
              "FIRST-BXN MOUTHWASH": "FIRST-BXN MOUTHWASH",
              "FIRST-DUKES MOUTHWASH": "FIRST-DUKES MOUTHWASH",
              "FIRST-MARYS MOUTHWASH": "FIRST-MARYS MOUTHWASH"
            },
            "CROWN LABORATORIES": {
              "ULCEREASE": "ULCEREASE"
            }
          },
          "Anti-infectives - Throat": {
            "A-S MEDICATION SOLUTIONS": {
              "NYSTATIN": "NYSTATIN"
            },
            "AIDAREX PHARMACEUTICALS": {
              "NYSTATIN": "NYSTATIN"
            },
            "AMERICAN PHARMA INGREDIENTS": {
              "AMPHOTERICIN B": "AMPHOTERICIN B"
            },
            "APOTHECA SUPPLY": {
              "AMPHOTERICIN B": "AMPHOTERICIN B"
            },
            "BRYANT RANCH PREPACK": {
              "NYSTATIN": "NYSTATIN"
            },
            "CARDINAL HEALTH REPACKAGING SO": {
              "CLOTRIMAZOLE": "CLOTRIMAZOLE",
              "NYSTATIN": "NYSTATIN"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "NYSTATIN": "NYSTATIN"
            },
            "COLGATE ORAL PHARMACEUTICALS": {
              "PEROXYL": "PEROXYL",
              "PEROXYL SPOT TREATMENT": "PEROXYL SPOT TREATMENT"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS PEROXIDE SORE MOUTH CLEANS": "CVS PEROXIDE SORE MOUTH CLEANS"
            },
            "FAGRON": {
              "AMPHOTERICIN B": "AMPHOTERICIN B"
            },
            "FORTOVIA THERAPEUTICS": {
              "ORAVIG": "ORAVIG"
            },
            "FREEDOM PHARMACEUTICALS": {
              "AMPHOTERICIN B": "AMPHOTERICIN B"
            },
            "GENUS LIFESCIENCES": {
              "NYSTATIN": "NYSTATIN"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "NYSTATIN": "NYSTATIN"
            },
            "HI-TECH": {
              "NYSTATIN": "NYSTATIN"
            },
            "HIKMA": {
              "CLOTRIMAZOLE": "CLOTRIMAZOLE"
            },
            "LANNETT": {
              "NYSTATIN": "NYSTATIN"
            },
            "LETCO MEDICAL": {
              "AMPHOTERICIN B": "AMPHOTERICIN B"
            },
            "LOHXA": {
              "NYSTATIN": "NYSTATIN"
            },
            "MEDISCA": {
              "AMPHOTERICIN B": "AMPHOTERICIN B"
            },
            "MEDTECH": {
              "GLY-OXIDE": "GLY-OXIDE"
            },
            "MORTON GROVE PHARMACEUTICALS": {
              "NYSTATIN": "NYSTATIN"
            },
            "NIVAGEN PHARMACEUTICALS": {
              "NYSTATIN": "NYSTATIN"
            },
            "NUCARE PHARMACEUTICALS": {
              "NYSTATIN": "NYSTATIN"
            },
            "PAR PHARMACEUTICALS": {
              "NYSTATIN": "NYSTATIN"
            },
            "PCCA": {
              "AMPHOTERICIN B": "AMPHOTERICIN B"
            },
            "PDRX PHARMACEUTICAL": {
              "CLOTRIMAZOLE": "CLOTRIMAZOLE"
            },
            "PERRIGO": {
              "CLOTRIMAZOLE": "CLOTRIMAZOLE"
            },
            "PHARMACEUTICAL ASSOCIATES": {
              "NYSTATIN": "NYSTATIN"
            },
            "PRECISION DOSE, INC": {
              "NYSTATIN": "NYSTATIN"
            },
            "PREFERRED PHARMACEUTICALS": {
              "NYSTATIN": "NYSTATIN"
            },
            "QUALITY CARE": {
              "NYSTATIN": "NYSTATIN"
            },
            "RITE AID CORPORATION": {
              "RA ANTISEPTIC MOUTH CLEANSER": "RA ANTISEPTIC MOUTH CLEANSER"
            },
            "SAGE PRODUCTS": {
              "PEROX-A-MINT": "PEROX-A-MINT"
            },
            "SHEFFIELD PHARMACEUTICALS": {
              "CANKAID": "CANKAID"
            },
            "SKY PACKAGING": {
              "NYSTATIN": "NYSTATIN"
            },
            "TARO": {
              "NYSTATIN": "NYSTATIN"
            },
            "VISTAPHARM INC.": {
              "NYSTATIN": "NYSTATIN"
            }
          }
        },
        "Antiseptics - Mouth/Throat": {
          "Antiseptic Combinations - Mouth/Throat": {
            "EPIEN MEDICAL": {
              "DEBACTEROL": "DEBACTEROL"
            },
            "MEDTECH": {
              "CHLORASEPTIC MAX SORE THROAT": "CHLORASEPTIC MAX SORE THROAT"
            }
          },
          "Antiseptics - Mouth/Throat": {
            "3M ESPE DENTAL PRODUCTS": {
              "PERIDEX": "PERIDEX"
            },
            "A-S MEDICATION SOLUTIONS": {
              "CHLORHEXIDINE GLUCONATE": "CHLORHEXIDINE GLUCONATE"
            },
            "AIDAREX PHARMACEUTICALS": {
              "CHLORHEXIDINE GLUCONATE": "CHLORHEXIDINE GLUCONATE"
            },
            "AKORN CONSUMER": {
              "DIABETIC TUSSIN SORE THROAT": "DIABETIC TUSSIN SORE THROAT"
            },
            "AMERICAN SALES COMPANY": {
              "SORE THROAT SPRAY": "SORE THROAT SPRAY"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP SORE THROAT SPRAY": "GNP SORE THROAT SPRAY"
            },
            "ATLANTIC BIOLOGICALS": {
              "CHLORHEXIDINE GLUCONATE": "CHLORHEXIDINE GLUCONATE"
            },
            "AURORA HEALTHCARE": {
              "ORALSEPTIC": "ORALSEPTIC"
            },
            "AVRIO HEALTH LP": {
              "BETADINE ANTISEPTIC GARGLE": "BETADINE ANTISEPTIC GARGLE"
            },
            "BERGEN BRUNSWIG": {
              "GNP SORE THROAT": "GNP SORE THROAT"
            },
            "CHAIN DRUG CONSORTIUM": {
              "SORE THROAT SPRAY": "SORE THROAT SPRAY"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC SORE THROAT SPRAY": "QC SORE THROAT SPRAY",
              "SORE THROAT": "SORE THROAT"
            },
            "COLGATE ORAL PHARMACEUTICALS": {
              "PERIOGARD": "PERIOGARD"
            },
            "CROWN LABORATORIES": {
              "ULCEREASE": "ULCEREASE"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS SORE THROAT SPRAY": "CVS SORE THROAT SPRAY"
            },
            "DASH PHARMACEUTICALS": {
              "CHLORHEXIDINE GLUCONATE": "CHLORHEXIDINE GLUCONATE"
            },
            "DSE HEALTHCARE SOLUTIONS": {
              "ST-37": "ST-37"
            },
            "EQUALINE": {
              "EQL SORE THROAT": "EQL SORE THROAT",
              "EQL SORE THROAT SPRAY": "EQL SORE THROAT SPRAY"
            },
            "GEISS DESTIN & DUNN": {
              "GOODSENSE SORE THROAT SPRAY": "GOODSENSE SORE THROAT SPRAY"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "TRIAMINIC SORE THROAT": "TRIAMINIC SORE THROAT"
            },
            "HANNAFORD BROTHERS": {
              "SORE THROAT SPRAY": "SORE THROAT SPRAY"
            },
            "HI-TECH": {
              "CHLORHEXIDINE GLUCONATE": "CHLORHEXIDINE GLUCONATE"
            },
            "KROGER COMPANY": {
              "SORE THROAT SPRAY": "SORE THROAT SPRAY"
            },
            "LEADER BRAND PRODUCTS": {
              "SORE THROAT": "SORE THROAT",
              "SORE THROAT SPRAY": "SORE THROAT SPRAY"
            },
            "LOHXA": {
              "CHLORHEXIDINE GLUCONATE": "CHLORHEXIDINE GLUCONATE"
            },
            "MAJOR PHARMACEUTICALS": {
              "SORE THROAT SPRAY": "SORE THROAT SPRAY"
            },
            "MCKESSON": {
              "HM SORE THROAT SPRAY": "HM SORE THROAT SPRAY"
            },
            "MCKESSON SUNMARK": {
              "SM SORE THROAT SPRAY": "SM SORE THROAT SPRAY"
            },
            "MEDTECH": {
              "CHLORASEPTIC": "CHLORASEPTIC",
              "CHLORASEPTIC GARGLE": "CHLORASEPTIC GARGLE",
              "CHLORASEPTIC KIDS": "CHLORASEPTIC KIDS",
              "CHLORASEPTIC MOUTH PAIN": "CHLORASEPTIC MOUTH PAIN",
              "CHLORASEPTIC WARM SORE THROAT": "CHLORASEPTIC WARM SORE THROAT"
            },
            "PERRIGO PHARMACEUTICALS": {
              "SORE THROAT SPRAY": "SORE THROAT SPRAY"
            },
            "PHARMACEUTICAL ASSOCIATES": {
              "CHLORHEXIDINE GLUCONATE": "CHLORHEXIDINE GLUCONATE"
            },
            "PRECISION DOSE, INC": {
              "PAROEX": "PAROEX"
            },
            "PUBLIX SUPER MARKETS INC.": {
              "PX SORE THROAT": "PX SORE THROAT"
            },
            "QUALITY CARE": {
              "PERIDEX": "PERIDEX"
            },
            "RITE AID CORPORATION": {
              "RA SORE THROAT": "RA SORE THROAT"
            },
            "RUGBY LABORATORIES": {
              "PHENASEPTIC": "PHENASEPTIC"
            },
            "SELECT BRAND": {
              "SB SORE THROAT SPRAY": "SB SORE THROAT SPRAY"
            },
            "SHEFFIELD PHARMACEUTICALS": {
              "CHERACOL SORE THROAT": "CHERACOL SORE THROAT",
              "PAIN-A-LAY": "PAIN-A-LAY"
            },
            "SUNSTAR AMERICAS": {
              "PAROEX": "PAROEX"
            },
            "TARGET": {
              "TGT SORE THROAT SPRAY": "TGT SORE THROAT SPRAY"
            },
            "TOPCO": {
              "SORE THROAT SPRAY": "SORE THROAT SPRAY"
            },
            "WAL-MART": {
              "EQ SORE THROAT SPRAY": "EQ SORE THROAT SPRAY"
            },
            "WALGREENS": {
              "ORA RELIEF": "ORA RELIEF",
              "ORAL RELIEF": "ORAL RELIEF"
            },
            "XTTRIUM": {
              "CHLORHEXIDINE GLUCONATE": "CHLORHEXIDINE GLUCONATE"
            }
          }
        },
        "Dental Products": {
          "Dental Aids": {
            "BEUTLICH PHARMA": {
              "HURRIVIEW": "HURRIVIEW",
              "HURRIVIEW II": "HURRIVIEW II"
            },
            "FAGRON": {
              "ZINC ACETATE": "ZINC ACETATE"
            },
            "SPECTRUM": {
              "ZINC ACETATE": "ZINC ACETATE"
            }
          },
          "Dental Products": {
            "CHAIN DRUG CONSORTIUM": {
              "ANTI-PLAQUE": "ANTI-PLAQUE"
            },
            "COLGATE ORAL PHARMACEUTICALS": {
              "VIADENT ADVANCED CARE": "VIADENT ADVANCED CARE",
              "VIADENT RINSE": "VIADENT RINSE"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS ANTI-PLAQUE": "CVS ANTI-PLAQUE"
            },
            "JOHNSON&JOHNSON HEALTHCARE": {
              "PLAX": "PLAX"
            },
            "MCKESSON SUNMARK": {
              "SM DENTAL RINSE ANTI-PLAQUE": "SM DENTAL RINSE ANTI-PLAQUE"
            }
          },
          "Dental Products - Combinations": {
            "COLGATE ORAL PHARMACEUTICALS": {
              "PREVIDENT 5000 ENAMEL PROTECT": "PREVIDENT 5000 ENAMEL PROTECT",
              "PREVIDENT 5000 SENSITIVE": "PREVIDENT 5000 SENSITIVE"
            },
            "DEN-MAT HOLDINGS": {
              "FLUORIDEX SENSITIVITY RELIEF": "FLUORIDEX SENSITIVITY RELIEF"
            },
            "DISCUS DENTAL": {
              "FLUORIDEX SENSITIVITY RELIEF": "FLUORIDEX SENSITIVITY RELIEF"
            },
            "JOHNSON&JOHNSON HEALTHCARE": {
              "LISTERINE ESSENTIAL CARE": "LISTERINE ESSENTIAL CARE"
            },
            "MEDICAL PRODUCTS LABS": {
              "NAFRINSE DAILY ACIDULATED": "NAFRINSE DAILY ACIDULATED"
            },
            "SHEFFIELD PHARMACEUTICALS": {
              "SODIUM FLUORIDE 5000 SENSITIVE": "SODIUM FLUORIDE 5000 SENSITIVE"
            }
          },
          "Dental Whiteners": {
            "NUVORA": {
              "NUVORAWHITE": "NUVORAWHITE"
            }
          },
          "Fluoride Dental Products": {
            "3M ESPE DENTAL PRODUCTS": {
              "CAVAREST": "CAVAREST",
              "CAVIRINSE": "CAVIRINSE",
              "CLINPRO 5000": "CLINPRO 5000",
              "CONTROLRX": "CONTROLRX",
              "JUST FOR KIDS": "JUST FOR KIDS",
              "OMNI GEL": "OMNI GEL",
              "PERIOMED": "PERIOMED"
            },
            "AVKARE": {
              "SODIUM FLUORIDE": "SODIUM FLUORIDE"
            },
            "BUREL PHARMACEUTICALS": {
              "SODIUM FLUORIDE": "SODIUM FLUORIDE",
              "SODIUM FLUORIDE 5000 PPM": "SODIUM FLUORIDE 5000 PPM"
            },
            "CHATTEM INC": {
              "ACT ANTICAVITY FLUORIDE RINSE": "ACT ANTICAVITY FLUORIDE RINSE",
              "ACT KIDS ANTICAVITY FLUORIDE": "ACT KIDS ANTICAVITY FLUORIDE",
              "ACT RESTORING FLUORIDE RINSE": "ACT RESTORING FLUORIDE RINSE",
              "ACT TOTAL CARE": "ACT TOTAL CARE",
              "ACT TOTAL CARE DRY MOUTH": "ACT TOTAL CARE DRY MOUTH"
            },
            "COLGATE ORAL PHARMACEUTICALS": {
              "COLGATE DRY MOUTH RELIEF": "COLGATE DRY MOUTH RELIEF",
              "FLUORIGARD": "FLUORIGARD",
              "GEL-KAM": "GEL-KAM",
              "GEL-KAM ORAL CARE RINSE": "GEL-KAM ORAL CARE RINSE",
              "PHOS FLUR": "PHOS FLUR",
              "PHOS-FLUR": "PHOS-FLUR",
              "PHOS-FLUR ORTHO DEFENSE": "PHOS-FLUR ORTHO DEFENSE",
              "PREVIDENT": "PREVIDENT",
              "PREVIDENT 5000 BOOSTER": "PREVIDENT 5000 BOOSTER",
              "PREVIDENT 5000 BOOSTER PLUS": "PREVIDENT 5000 BOOSTER PLUS",
              "PREVIDENT 5000 DRY MOUTH": "PREVIDENT 5000 DRY MOUTH",
              "PREVIDENT 5000 ORTHO DEFENSE": "PREVIDENT 5000 ORTHO DEFENSE",
              "PREVIDENT 5000 PLUS": "PREVIDENT 5000 PLUS",
              "THERA-FLUR-N": "THERA-FLUR-N"
            },
            "CYPRESS PHARMACEUTICAL": {
              "SF": "SF",
              "SF 5000 PLUS": "SF 5000 PLUS"
            },
            "DEN-MAT HOLDINGS": {
              "FLUORIDEX": "FLUORIDEX",
              "FLUORIDEX DAILY RENEWAL": "FLUORIDEX DAILY RENEWAL",
              "FLUORIDEX ENHANCED WHITENING": "FLUORIDEX ENHANCED WHITENING"
            },
            "DISCUS DENTAL": {
              "FLUORIDEX DAILY DEFENSE": "FLUORIDEX DAILY DEFENSE",
              "FLUORIDEX ENHANCED WHITENING": "FLUORIDEX ENHANCED WHITENING"
            },
            "DR. HAROLD KATZ": {
              "THERABREATH ORAL RINSE": "THERABREATH ORAL RINSE"
            },
            "DU-MORE": {
              "EASYGEL": "EASYGEL"
            },
            "EQUALINE": {
              "EQL ANTICAVITY FLUORIDE RINSE": "EQL ANTICAVITY FLUORIDE RINSE",
              "EQL ANTICAVITY MOUTHWASH": "EQL ANTICAVITY MOUTHWASH",
              "EQL DENTAL TRAVEL PACK": "EQL DENTAL TRAVEL PACK",
              "EQL TOOTHBRUSH/TOOTHPASTE": "EQL TOOTHBRUSH/TOOTHPASTE"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "PARODONTAX": "PARODONTAX",
              "SENSODYNE COMPLETE PROTECTION": "SENSODYNE COMPLETE PROTECTION",
              "SENSODYNE RAPID RELIEF": "SENSODYNE RAPID RELIEF",
              "SENSODYNE REPAIR & PROTECT": "SENSODYNE REPAIR & PROTECT"
            },
            "INTEGRATE ORAL CARE": {
              "STANNOUS FLUORIDE RINSE": "STANNOUS FLUORIDE RINSE"
            },
            "J & J CONSUMER PRODUCTS": {
              "LISTERINE SMART RINSE": "LISTERINE SMART RINSE"
            },
            "JOHNSON&JOHNSON HEALTHCARE": {
              "LISTERINE HEALTHY WHITE GENTLE": "LISTERINE HEALTHY WHITE GENTLE",
              "LISTERINE HEALTHYWHITE VIBRANT": "LISTERINE HEALTHYWHITE VIBRANT",
              "LISTERINE NATURALS/FLUORIDE": "LISTERINE NATURALS/FLUORIDE",
              "LISTERINE NIGHTLY RESET": "LISTERINE NIGHTLY RESET",
              "LISTERINE RESTORING": "LISTERINE RESTORING",
              "LISTERINE SMART RINSE": "LISTERINE SMART RINSE",
              "LISTERINE TOTAL CARE": "LISTERINE TOTAL CARE",
              "LISTERINE TOTAL CARE WHITENING": "LISTERINE TOTAL CARE WHITENING",
              "LISTERINE TOTAL CARE ZERO": "LISTERINE TOTAL CARE ZERO",
              "LISTERINE WHITENING/RESTORING": "LISTERINE WHITENING/RESTORING",
              "REMBRANDT DEEPLY WHITE+PEROX": "REMBRANDT DEEPLY WHITE+PEROX",
              "REMBRANDT INTENSE STAIN": "REMBRANDT INTENSE STAIN",
              "REMBRANDT WHITENING": "REMBRANDT WHITENING"
            },
            "MCKESSON SUNMARK": {
              "SM ANTICAVITY FLUORIDE RINSE": "SM ANTICAVITY FLUORIDE RINSE"
            },
            "MEDICAL PRODUCTS LABS": {
              "NAFRINSE DAILY/NEUTRAL": "NAFRINSE DAILY/NEUTRAL",
              "NAFRINSE WEEKLY": "NAFRINSE WEEKLY"
            },
            "METHOD PHARMACEUTICALS": {
              "SODIUM FLUORIDE": "SODIUM FLUORIDE",
              "SODIUM FLUORIDE 5000 PLUS": "SODIUM FLUORIDE 5000 PLUS"
            },
            "P & G HEALTH": {
              "CREST COMPLETE": "CREST COMPLETE",
              "CREST PRO-HEALTH COMPLETE": "CREST PRO-HEALTH COMPLETE"
            },
            "PASCAL COMPANY": {
              "NEUTRAGARD ADVANCED": "NEUTRAGARD ADVANCED"
            },
            "REGENT LABS, INC": {
              "DENTALL 1100 PLUS": "DENTALL 1100 PLUS"
            },
            "RISING PHARMACEUTICALS": {
              "DENTA 5000 PLUS": "DENTA 5000 PLUS",
              "DENTAGEL": "DENTAGEL"
            },
            "RITE AID CORPORATION": {
              "RA ANTICAVITY FLUORIDE RINSE": "RA ANTICAVITY FLUORIDE RINSE",
              "RA TOOTH SHIELD": "RA TOOTH SHIELD"
            },
            "SANCILIO": {
              "NEUTRAL SODIUM FLUORIDE": "NEUTRAL SODIUM FLUORIDE"
            },
            "SHEFFIELD PHARMACEUTICALS": {
              "SODIUM FLUORIDE 5000 PPM": "SODIUM FLUORIDE 5000 PPM"
            },
            "WALGREENS": {
              "FLUORIDE MOUTH RINSE": "FLUORIDE MOUTH RINSE"
            },
            "YOUNG DENTAL": {
              "GEL-TIN": "GEL-TIN",
              "KARIGEL": "KARIGEL",
              "KARIGEL-N": "KARIGEL-N"
            }
          }
        },
        "Lozenges": {
          "Lozenge - Combinations": {
            "EQUALINE": {
              "EQL SORE THROAT & COUGH": "EQL SORE THROAT & COUGH"
            },
            "INSIGHT PHARMACEUTICALS": {
              "CEPASTAT": "CEPASTAT"
            },
            "MCNEIL CONSUMER": {
              "LUDENS COUGH DROPS": "LUDENS COUGH DROPS"
            },
            "MEDTECH": {
              "CHLORASEPTIC TOTAL": "CHLORASEPTIC TOTAL"
            },
            "PFIZER CONSUMER HEALTHCARE": {
              "ROBITUSSIN MEDI-SOOTHERS": "ROBITUSSIN MEDI-SOOTHERS"
            },
            "RECKITT BENCKISER": {
              "CEPACOL SORE THROAT & COUGH": "CEPACOL SORE THROAT & COUGH",
              "DELSYM COUGH RELIEF": "DELSYM COUGH RELIEF",
              "DELSYM COUGH+ SOOTHING ACTION": "DELSYM COUGH+ SOOTHING ACTION"
            },
            "REESE PHARMACEUTICAL": {
              "TETRA-FORMULA CGH/SORE THROAT": "TETRA-FORMULA CGH/SORE THROAT"
            },
            "RUGBY LABORATORIES": {
              "SORE THROAT & COUGH LOZENGES": "SORE THROAT & COUGH LOZENGES"
            },
            "WALGREENS": {
              "SORE THROAT & COUGH LOZENGES": "SORE THROAT & COUGH LOZENGES"
            }
          },
          "Lozenges": {
            "A-S MEDICATION SOLUTIONS": {
              "COUGH DROPS": "COUGH DROPS",
              "HALLS COUGH DROPS": "HALLS COUGH DROPS"
            },
            "AKORN CONSUMER": {
              "DIABETIC TUSSIN COUGH DROPS": "DIABETIC TUSSIN COUGH DROPS"
            },
            "AMERICAN SALES COMPANY": {
              "COUGH DROPS": "COUGH DROPS"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP COUGH DROPS": "GNP COUGH DROPS",
              "GNP HERBAL": "GNP HERBAL",
              "GNP THROAT DROPS": "GNP THROAT DROPS"
            },
            "BELMORA": {
              "FLANAX COUGH RELIEF": "FLANAX COUGH RELIEF"
            },
            "BERGEN BRUNSWIG": {
              "GNP COUGH DROPS": "GNP COUGH DROPS",
              "GNP NATURAL HERB": "GNP NATURAL HERB"
            },
            "CHAIN DRUG CONSORTIUM": {
              "COUGH DROPS": "COUGH DROPS",
              "NATURAL HERB COUGH DROPS": "NATURAL HERB COUGH DROPS",
              "THROAT DROPS": "THROAT DROPS"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC SORE THROAT": "QC SORE THROAT"
            },
            "COMBE": {
              "CEPACOL FIZZLERS": "CEPACOL FIZZLERS",
              "CEPACOL REGULAR STRENGTH": "CEPACOL REGULAR STRENGTH",
              "CEPACOL SORE THROAT": "CEPACOL SORE THROAT"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS CHERRY MENTHOL DROPS": "CVS CHERRY MENTHOL DROPS",
              "CVS COUGH DROPS SUGAR FREE": "CVS COUGH DROPS SUGAR FREE",
              "CVS HONEY LEMON DROPS": "CVS HONEY LEMON DROPS",
              "CVS MENTHOL DROPS": "CVS MENTHOL DROPS",
              "CVS SORE THROAT RELIEF POPS": "CVS SORE THROAT RELIEF POPS"
            },
            "DOVER PHARMACEUTICALS": {
              "LARYNEX": "LARYNEX",
              "NYCOFF": "NYCOFF"
            },
            "EQUALINE": {
              "EQL COUGH DROPS": "EQL COUGH DROPS"
            },
            "INSIGHT PHARMACEUTICALS": {
              "CEPASTAT": "CEPASTAT",
              "SUCRETS SORE THROAT": "SUCRETS SORE THROAT"
            },
            "LEADER BRAND PRODUCTS": {
              "COUGH DROPS": "COUGH DROPS"
            },
            "MAGNO-HUMPHRIES LABORATORIES": {
              "ZINC W/A&C": "ZINC W/A&C"
            },
            "MCKESSON SUNMARK": {
              "SM COUGH DROPS": "SM COUGH DROPS",
              "SM FRUIT COOLERS": "SM FRUIT COOLERS",
              "SM NATURAL HERB COUGH DROPS": "SM NATURAL HERB COUGH DROPS"
            },
            "MCNEIL CONSUMER": {
              "LUDENS THROAT DROPS": "LUDENS THROAT DROPS"
            },
            "MEDICINE SHOPPE": {
              "COUGH DROPS": "COUGH DROPS",
              "COUGH DROPS MENTHOL": "COUGH DROPS MENTHOL",
              "MENTHOL COUGH DROPS": "MENTHOL COUGH DROPS",
              "NATURAL HERB COUGH DROPS": "NATURAL HERB COUGH DROPS"
            },
            "MEDIQUE PRODUCTS": {
              "CHERRY COUGH DROPS": "CHERRY COUGH DROPS",
              "MEDIKOFF DROPS": "MEDIKOFF DROPS",
              "SORE THROAT RELIEF": "SORE THROAT RELIEF",
              "TROCAINE THROAT": "TROCAINE THROAT"
            },
            "MEDTECH": {
              "CHLORASEPTIC WARM SORE THROAT": "CHLORASEPTIC WARM SORE THROAT",
              "LITTLE COLDS COLD RELIEF": "LITTLE COLDS COLD RELIEF",
              "LITTLE COLDS THROAT": "LITTLE COLDS THROAT",
              "LUDENS THROAT DROPS": "LUDENS THROAT DROPS"
            },
            "MONDELEZ INTERNATIONAL": {
              "HALLS COUGH DROPS": "HALLS COUGH DROPS"
            },
            "MONTICELLO DRUG": {
              "THROAT DISCS": "THROAT DISCS"
            },
            "NUVORA": {
              "DENTIVA": "DENTIVA",
              "SALESE": "SALESE"
            },
            "P & G HEALTH": {
              "VICKS VAPODROPS": "VICKS VAPODROPS"
            },
            "REESE PHARMACEUTICAL": {
              "BI-ZETS/BENZOTROCHES": "BI-ZETS/BENZOTROCHES"
            },
            "RICOLA USA, INC.": {
              "RICOLA": "RICOLA",
              "RICOLA CHERRY HONEY HERB": "RICOLA CHERRY HONEY HERB",
              "RICOLA HERB": "RICOLA HERB",
              "RICOLA HONEY HERB": "RICOLA HONEY HERB",
              "RICOLA LEMON MINT": "RICOLA LEMON MINT",
              "RICOLA MOUNTAIN HERB": "RICOLA MOUNTAIN HERB",
              "RICOLA NATURAL HERB": "RICOLA NATURAL HERB",
              "RICOLA W/ECHINACEA": "RICOLA W/ECHINACEA"
            },
            "RITE AID CORPORATION": {
              "RA COUGH DROPS": "RA COUGH DROPS"
            },
            "TARGET": {
              "TGT COUGH DROPS": "TGT COUGH DROPS",
              "TGT THROAT DROPS": "TGT THROAT DROPS"
            },
            "WAL-MART": {
              "EQ COUGH SUGAR FREE": "EQ COUGH SUGAR FREE"
            },
            "WALGREENS": {
              "COUGH DROPS": "COUGH DROPS",
              "DADS MENTHOL THROAT DROP": "DADS MENTHOL THROAT DROP",
              "FRUIT FROSTERS": "FRUIT FROSTERS",
              "SORE THROAT LOLLIPOPS": "SORE THROAT LOLLIPOPS"
            }
          }
        },
        "Mouthwashes": {
          "Mouthwashes": {
            "AMERISOURCE BERGEN DRUGS": {
              "GNP DRY MOUTH MOUTHWASH": "GNP DRY MOUTH MOUTHWASH"
            },
            "CHAIN DRUG CONSORTIUM": {
              "ANTISEPTIC MOUTH RINSE": "ANTISEPTIC MOUTH RINSE",
              "DRY MOUTH MOUTHWASH": "DRY MOUTH MOUTHWASH",
              "MOUTHWASH/GARGLE": "MOUTHWASH/GARGLE",
              "ORAL RINSE": "ORAL RINSE",
              "XTREME PREMIER": "XTREME PREMIER"
            },
            "COMBE": {
              "CEPACOL MOUTHWASH/GARGLE": "CEPACOL MOUTHWASH/GARGLE"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS ANTISEPTIC MOUTH RINSE": "CVS ANTISEPTIC MOUTH RINSE",
              "CVS MOUTHWASH": "CVS MOUTHWASH",
              "CVS ORAL RINSE": "CVS ORAL RINSE"
            },
            "DISCUS DENTAL": {
              "BREATH RX": "BREATH RX"
            },
            "DR. HAROLD KATZ": {
              "THERABREATH HEALTHY GUMS RINSE": "THERABREATH HEALTHY GUMS RINSE",
              "THERABREATH ORAL RINSE": "THERABREATH ORAL RINSE"
            },
            "EQUALINE": {
              "EQL ANTISEPTIC MOUTHRINSE": "EQL ANTISEPTIC MOUTHRINSE",
              "EQL ANTISEPTIC RINSE": "EQL ANTISEPTIC RINSE",
              "EQL MOUTHWASH/GARGLE": "EQL MOUTHWASH/GARGLE"
            },
            "GEISS DESTIN & DUNN": {
              "GOODSENSE MOUTH RINSE": "GOODSENSE MOUTH RINSE",
              "GOODSENSE MOUTHWASH": "GOODSENSE MOUTHWASH",
              "GOODSENSE ORAL RINSE": "GOODSENSE ORAL RINSE"
            },
            "GERITREX CORPORATION": {
              "CLEAN ZING": "CLEAN ZING"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "BIOTENE DRY MOUTH": "BIOTENE DRY MOUTH",
              "BIOTENE DRY MOUTH GENTLE": "BIOTENE DRY MOUTH GENTLE",
              "BIOTENE PBF DRY MOUTH": "BIOTENE PBF DRY MOUTH"
            },
            "HUMCO": {
              "DOBELLS": "DOBELLS"
            },
            "J & J CONSUMER PRODUCTS": {
              "LISTERINE": "LISTERINE",
              "LISTERINE ULTRACLEAN": "LISTERINE ULTRACLEAN"
            },
            "JOHNSON&JOHNSON HEALTHCARE": {
              "LISTERINE": "LISTERINE",
              "LISTERINE ANTISEPTIC": "LISTERINE ANTISEPTIC",
              "LISTERINE GUM THERAPY": "LISTERINE GUM THERAPY",
              "LISTERINE NATURALS ANTISEPTIC": "LISTERINE NATURALS ANTISEPTIC",
              "LISTERINE POCKETMIST": "LISTERINE POCKETMIST",
              "LISTERINE POCKETPAKS": "LISTERINE POCKETPAKS",
              "LISTERINE POCKETPAKS COOL HEAT": "LISTERINE POCKETPAKS COOL HEAT",
              "LISTERINE READY TABS": "LISTERINE READY TABS",
              "LISTERINE ULTRACLEAN": "LISTERINE ULTRACLEAN",
              "LISTERINE ZERO": "LISTERINE ZERO"
            },
            "LEADER BRAND PRODUCTS": {
              "ANTISEPTIC MOUTH RINSE": "ANTISEPTIC MOUTH RINSE",
              "ANTISEPTIC MOUTHRINSE": "ANTISEPTIC MOUTHRINSE"
            },
            "MCKESSON SUNMARK": {
              "SM ANTISEPTIC MOUTH RINSE": "SM ANTISEPTIC MOUTH RINSE",
              "SM MOUTH RINSE ANTISEPTIC": "SM MOUTH RINSE ANTISEPTIC"
            },
            "OAKHURST": {
              "ASTRING-O-SOL": "ASTRING-O-SOL",
              "TONSILINE": "TONSILINE"
            },
            "ORAL ESSENTIALS": {
              "LUMINEUX CLEAN/FRESH MOUTHWASH": "LUMINEUX CLEAN/FRESH MOUTHWASH",
              "LUMINEUX HYDRATING MOUTHWASH": "LUMINEUX HYDRATING MOUTHWASH",
              "LUMINEUX SENSITIVITY MOUTHWASH": "LUMINEUX SENSITIVITY MOUTHWASH",
              "LUMINEUX WHITENING MOUTHWASH": "LUMINEUX WHITENING MOUTHWASH"
            },
            "P & G HEALTH": {
              "CREST PRO-HEALTH": "CREST PRO-HEALTH",
              "SCOPE": "SCOPE"
            },
            "RECKITT BENCKISER": {
              "CEPACOL ANTIBACTERIAL": "CEPACOL ANTIBACTERIAL"
            },
            "RITE AID CORPORATION": {
              "RA ANTISEPTIC MOUTH RINSE": "RA ANTISEPTIC MOUTH RINSE",
              "RA ANTISEPTIC RINSE": "RA ANTISEPTIC RINSE",
              "RA DRY MOUTH MOUTHWASH": "RA DRY MOUTH MOUTHWASH",
              "RA MOUTHWASH ALCOHOL FREE": "RA MOUTHWASH ALCOHOL FREE",
              "RA MOUTHWASH/GARGLE": "RA MOUTHWASH/GARGLE"
            },
            "SAGE PRODUCTS": {
              "ANTISEPTIC ORAL RINSE": "ANTISEPTIC ORAL RINSE"
            },
            "WALGREENS": {
              "ANTISEPTIC MOUTH RINSE": "ANTISEPTIC MOUTH RINSE",
              "MOUTH RINSE": "MOUTH RINSE"
            }
          }
        },
        "Periodontal Products": {
          "Periodontal Anti-infectives": {
            "ORAPHARMA, INC": {
              "ARESTIN": "ARESTIN"
            }
          }
        },
        "Steroids - Mouth/Throat/Dental": {
          "Steroids - Mouth/Throat/Dental": {
            "A-S MEDICATION SOLUTIONS": {
              "TRIAMCINOLONE ACETONIDE": "TRIAMCINOLONE ACETONIDE"
            },
            "AVKARE": {
              "TRIAMCINOLONE ACETONIDE": "TRIAMCINOLONE ACETONIDE"
            },
            "BUREL PHARMACEUTICALS": {
              "TRIAMCINOLONE ACETONIDE": "TRIAMCINOLONE ACETONIDE"
            },
            "COSETTE PHARMACEUTICALS": {
              "TRIAMCINOLONE ACETONIDE": "TRIAMCINOLONE ACETONIDE"
            },
            "PREFERRED PHARMACEUTICALS": {
              "TRIAMCINOLONE ACETONIDE": "TRIAMCINOLONE ACETONIDE"
            },
            "QUALITY CARE": {
              "TRIAMCINOLONE ACETONIDE": "TRIAMCINOLONE ACETONIDE"
            },
            "RISING PHARMACEUTICALS": {
              "TRIAMCINOLONE ACETONIDE": "TRIAMCINOLONE ACETONIDE"
            },
            "TARO": {
              "ORALONE": "ORALONE",
              "TRIAMCINOLONE ACETONIDE": "TRIAMCINOLONE ACETONIDE"
            }
          }
        },
        "Throat Products - Misc.": {
          "Dry Mouth Agents and Artificial Saliva": {
            "3M ESPE DENTAL PRODUCTS": {
              "XEROSTOMIA RELIEF SPRAY": "XEROSTOMIA RELIEF SPRAY"
            },
            "ADVANCED PHARMACEUTICAL TECHNO": {
              "BOCASAL": "BOCASAL"
            },
            "ALIGN PHARMACEUTICALS": {
              "NUMOISYN": "NUMOISYN"
            },
            "BAUSCH HEALTH": {
              "NEUTRASAL": "NEUTRASAL"
            },
            "CAMELLIX": {
              "MIGHTEAFLOW": "MIGHTEAFLOW"
            },
            "CHAIN DRUG CONSORTIUM": {
              "DRY MOUTH SPRAY": "DRY MOUTH SPRAY"
            },
            "CHATTEM INC": {
              "ACT DRY MOUTH": "ACT DRY MOUTH"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS DRY MOUTH": "CVS DRY MOUTH"
            },
            "DR. HAROLD KATZ": {
              "THERABREATH DRY MOUTH": "THERABREATH DRY MOUTH"
            },
            "EQUALINE": {
              "EQL DRY MOUTH ORAL RINSE": "EQL DRY MOUTH ORAL RINSE"
            },
            "EUSA PHARMA US": {
              "CAPHOSOL": "CAPHOSOL"
            },
            "FORWARD SCIENCE TECHNOLOGIES": {
              "MUCOSITISRX": "MUCOSITISRX",
              "SALIVAMAX": "SALIVAMAX",
              "SALIVATE RX": "SALIVATE RX"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "BIOTENE DRY MOUTH": "BIOTENE DRY MOUTH",
              "BIOTENE DRY MOUTH MOISTURIZING": "BIOTENE DRY MOUTH MOISTURIZING",
              "BIOTENE ORALBALANCE DRY MOUTH": "BIOTENE ORALBALANCE DRY MOUTH",
              "BIOTENE PBF DRY MOUTH": "BIOTENE PBF DRY MOUTH"
            },
            "INTEGRATE ORAL CARE": {
              "ORAL RELIEF FOR DRY MOUTH": "ORAL RELIEF FOR DRY MOUTH",
              "ORAL RELIEF SPRAY": "ORAL RELIEF SPRAY"
            },
            "INVADO PHARMACEUTICALS": {
              "NEUTRASAL": "NEUTRASAL"
            },
            "KINGSWOOD LABORATORIES": {
              "MOI-STIR": "MOI-STIR"
            },
            "MISSION": {
              "AQUORAL": "AQUORAL"
            },
            "NUVORA": {
              "SALESE/XYLITOL": "SALESE/XYLITOL"
            },
            "ORAHEALTH CORPORATION": {
              "XYLIMELTS": "XYLIMELTS"
            },
            "PARNELL": {
              "MOUTH KOTE": "MOUTH KOTE",
              "MOUTH KOTE REMINT": "MOUTH KOTE REMINT"
            },
            "RITE AID CORPORATION": {
              "RA DRY MOUTH": "RA DRY MOUTH"
            },
            "SCANDINAVIAN FORMULAS": {
              "SALIVASURE": "SALIVASURE"
            },
            "WISCONSIN PHARMACAL COMPANY": {
              "BOCASAL": "BOCASAL"
            }
          },
          "Protectants - Mouth/Throat": {
            "ACCESS PHARMACEUTICALS": {
              "MUGARD": "MUGARD"
            },
            "AMAG PHARMACEUTICALS": {
              "MUGARD": "MUGARD"
            },
            "BASIC ORGANICS": {
              "L-LYSINE": "L-LYSINE"
            },
            "BERGEN BRUNSWIG": {
              "GNP COLD SORE TREATMENT": "GNP COLD SORE TREATMENT"
            },
            "BLISTEX": {
              "BLISTEX MEDICATED": "BLISTEX MEDICATED",
              "LIP MEDEX": "LIP MEDEX"
            },
            "CHAIN DRUG CONSORTIUM": {
              "COLD SORE TREATMENT": "COLD SORE TREATMENT",
              "LIP BALM MEDICATED": "LIP BALM MEDICATED"
            },
            "CHATTEM INC": {
              "HERPECIN-L": "HERPECIN-L"
            },
            "EMERGENT BIOSOLUTIONS": {
              "EPISIL": "EPISIL"
            },
            "EQUALINE": {
              "EQL COLD SORE TREATMENT": "EQL COLD SORE TREATMENT"
            },
            "FOCUS CONSUMER HEALTHCARE": {
              "HERPECIN-L": "HERPECIN-L"
            },
            "FORTOVIA THERAPEUTICS": {
              "GELCLAIR": "GELCLAIR"
            },
            "FORWARD SCIENCE TECHNOLOGIES": {
              "ORAPEUTIC": "ORAPEUTIC"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "ANBESOL COLD SORE THERAPY": "ANBESOL COLD SORE THERAPY"
            },
            "KEY THERAPEUTICS": {
              "GELX": "GELX"
            },
            "MEDIQUE PRODUCTS": {
              "LIP-GUARD": "LIP-GUARD"
            },
            "MEDLINE/DERMAL MANAGEMENT": {
              "SALICEPT": "SALICEPT"
            },
            "MPM MEDICAL, INC.": {
              "ORAMAGICRX": "ORAMAGICRX"
            },
            "MUELLER MEDICAL INTERNATIONAL": {
              "ORAFATE": "ORAFATE",
              "PROTHELIAL": "PROTHELIAL"
            },
            "QUADEX PHARMACEUTICALS": {
              "VIROXYN": "VIROXYN"
            },
            "R-PHARM": {
              "EPISIL": "EPISIL"
            },
            "RITE AID CORPORATION": {
              "RA COLD SORE TREATMENT": "RA COLD SORE TREATMENT"
            },
            "US WORLDMEDS": {
              "GELX": "GELX"
            },
            "WALGREENS": {
              "COLD SORE TREATMENT": "COLD SORE TREATMENT"
            },
            "WOMEN'S CHOICE PHARMACEUTICALS": {
              "MUCOTROL": "MUCOTROL"
            }
          },
          "Saliva Stimulants": {
            "ACTAVIS ELIZABETH": {
              "PILOCARPINE HCL": "PILOCARPINE HCL"
            },
            "AMERICAN HEALTH PACKAGING": {
              "CEVIMELINE HCL": "CEVIMELINE HCL",
              "PILOCARPINE HCL": "PILOCARPINE HCL"
            },
            "AMICI PHARMACEUTICALS": {
              "PILOCARPINE HCL": "PILOCARPINE HCL"
            },
            "APOTEX": {
              "CEVIMELINE HCL": "CEVIMELINE HCL"
            },
            "BRYANT RANCH PREPACK": {
              "EVOXAC": "EVOXAC"
            },
            "CONCORDIA PHARMACEUTICALS": {
              "SALAGEN": "SALAGEN"
            },
            "DAIICHI PHARMACEUTICAL CORP": {
              "EVOXAC": "EVOXAC"
            },
            "EISAI": {
              "SALAGEN": "SALAGEN"
            },
            "HIKMA": {
              "CEVIMELINE HCL": "CEVIMELINE HCL",
              "PILOCARPINE HCL": "PILOCARPINE HCL"
            },
            "IMPAX GENERICS": {
              "PILOCARPINE HCL": "PILOCARPINE HCL"
            },
            "LANNETT": {
              "PILOCARPINE HCL": "PILOCARPINE HCL"
            },
            "LUPIN PHARMACEUTICALS": {
              "CEVIMELINE HCL": "CEVIMELINE HCL"
            },
            "MARLEX PHARMACEUTICALS": {
              "PILOCARPINE HCL": "PILOCARPINE HCL"
            },
            "NORTHSTAR RX": {
              "PILOCARPINE HCL": "PILOCARPINE HCL"
            },
            "NUCARE PHARMACEUTICALS": {
              "EVOXAC": "EVOXAC"
            },
            "PACK PHARMACEUTICALS, LLC": {
              "CEVIMELINE HCL": "CEVIMELINE HCL"
            },
            "PERRIGO": {
              "PILOCARPINE HCL": "PILOCARPINE HCL"
            },
            "SUN PHARMACEUTICALS": {
              "CEVIMELINE HCL": "CEVIMELINE HCL"
            }
          },
          "Throat Products - Misc.": {
            "B.F. ASCHER": {
              "AYR THROAT SPRAY": "AYR THROAT SPRAY"
            },
            "COLGATE ORAL PHARMACEUTICALS": {
              "OPTIMOIST": "OPTIMOIST",
              "XERO-LUBE": "XERO-LUBE"
            },
            "DYNAREX CORPORATION": {
              "LEMON-GLYCERIN": "LEMON-GLYCERIN"
            },
            "KLI CORP": {
              "ENTERTAINERS SECRET THROAT": "ENTERTAINERS SECRET THROAT"
            },
            "MEDTECH": {
              "CHLORASEPTIC SALT WATER GARGLE": "CHLORASEPTIC SALT WATER GARGLE"
            },
            "OASIS CONSUMER HEALTH": {
              "OASIS MOISTURIZING MOUTH SPRAY": "OASIS MOISTURIZING MOUTH SPRAY",
              "OASIS MOISTURIZING MOUTHWASH": "OASIS MOISTURIZING MOUTHWASH"
            },
            "WALGREENS": {
              "ANTI-SNORE THROAT SPRAY": "ANTI-SNORE THROAT SPRAY"
            }
          }
        }
      },
      "MULTIVITAMINS": {
        "B-Complex Vitamins": {
          "B-Complex Vitamins": {
            "ADVANCED GENERIC": {
              "BIOPETIT": "BIOPETIT"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS BALANCED B100": "CVS BALANCED B100"
            },
            "EMPOWER PHARMACY": {
              "B-COMPLEX": "B-COMPLEX"
            },
            "FLON LABORATORIES": {
              "VITAMIN B COMPLEX 100": "VITAMIN B COMPLEX 100"
            },
            "IMPRIMIS NJOF": {
              "VITAMIN B COMPLEX-HYDROXOCOBAL": "VITAMIN B COMPLEX-HYDROXOCOBAL"
            },
            "JJ BALAN": {
              "B COMPLEX": "B COMPLEX"
            },
            "KRAMER-NOVIS": {
              "APETEX": "APETEX",
              "APETIGEN": "APETIGEN"
            },
            "MAGNO-HUMPHRIES LABORATORIES": {
              "VITAMIN B COMPLEX": "VITAMIN B COMPLEX"
            },
            "MAJOR PHARMACEUTICALS": {
              "B-COMPLEX/B-12": "B-COMPLEX/B-12"
            },
            "MARLEX PHARMACEUTICALS": {
              "VITAMIN-B COMPLEX": "VITAMIN-B COMPLEX"
            },
            "MASON VITAMINS": {
              "B COMPLEX": "B COMPLEX"
            },
            "MERIT": {
              "B COMPLEX VITAMINS": "B COMPLEX VITAMINS"
            },
            "MYLAN INSTITUTIONAL": {
              "VITAMIN B-COMPLEX 100": "VITAMIN B-COMPLEX 100"
            },
            "NATIONAL VITAMIN": {
              "B COMPLEX VITAMINS": "B COMPLEX VITAMINS"
            },
            "NATURES BOUNTY": {
              "B COMPLEX-B12": "B COMPLEX-B12",
              "B-COMPLEX/B-12": "B-COMPLEX/B-12",
              "VITAMIN B COMPLEX": "VITAMIN B COMPLEX"
            },
            "PLUS PHARMA": {
              "VITAMIN B-COMPLEX": "VITAMIN B-COMPLEX"
            },
            "REXALL SUNDOWN": {
              "B-COMPLEX/B-12": "B-COMPLEX/B-12"
            },
            "RITE AID CORPORATION": {
              "PA B-COMPLEX WITH B-12": "PA B-COMPLEX WITH B-12",
              "RA B-COMPLEX": "RA B-COMPLEX",
              "RA B-COMPLEX WITH B-12": "RA B-COMPLEX WITH B-12"
            },
            "RUGBY LABORATORIES": {
              "B COMPLEX": "B COMPLEX"
            },
            "VITALINE": {
              "B COMPLEX 50": "B COMPLEX 50"
            },
            "WAL-MART": {
              "B-COMPLEX/B-12": "B-COMPLEX/B-12"
            },
            "WINDMILL CONSUMER PRODUCTS": {
              "B-COMPLEX HIGH POTENCY": "B-COMPLEX HIGH POTENCY"
            }
          },
          "Brewers Yeast": {
            "MAGNO-HUMPHRIES LABORATORIES": {
              "BREWERS YEAST": "BREWERS YEAST"
            },
            "MAJOR PHARMACEUTICALS": {
              "BREWERS YEAST": "BREWERS YEAST"
            },
            "MASON VITAMINS": {
              "BREWERS YEAST": "BREWERS YEAST"
            },
            "NAT-RUL HEALTH PRODUCTS": {
              "BREWERS YEAST": "BREWERS YEAST"
            },
            "NATIONAL VITAMIN": {
              "BREWERS YEAST": "BREWERS YEAST"
            },
            "NATURES BOUNTY": {
              "BREWERS YEAST": "BREWERS YEAST"
            },
            "WAL-MART": {
              "BREWERS YEAST": "BREWERS YEAST"
            }
          }
        },
        "B-Complex w/ C": {
          "B-Complex w/ C": {
            "21ST CENTURY HEALTHCARE": {
              "B COMPLEX-C": "B COMPLEX-C"
            },
            "BASIC DRUGS": {
              "B COMPLEX-C": "B COMPLEX-C"
            },
            "CHAIN DRUG CONSORTIUM": {
              "B-COMPLEX-C": "B-COMPLEX-C"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS B COMPLEX PLUS C": "CVS B COMPLEX PLUS C",
              "CVS SUPER B COMPLEX/C": "CVS SUPER B COMPLEX/C"
            },
            "GERI-CARE": {
              "SUPER B COMPLEX/VITAMIN C": "SUPER B COMPLEX/VITAMIN C"
            },
            "GIL PHARMACEUTICAL CORP.": {
              "VITAJECT": "VITAJECT"
            },
            "INTERNATIONAL VITAMIN CORP": {
              "ALLBEE/C": "ALLBEE/C"
            },
            "LEADER BRAND PRODUCTS": {
              "B COMPLEX-C": "B COMPLEX-C",
              "SUPER B-COMPLEX + VITAMIN C": "SUPER B-COMPLEX + VITAMIN C"
            },
            "MAGNO-HUMPHRIES LABORATORIES": {
              "B COMPLEX-C": "B COMPLEX-C",
              "BETTER B COMPLEX": "BETTER B COMPLEX"
            },
            "MAJOR PHARMACEUTICALS": {
              "SUPERPLEX-T": "SUPERPLEX-T",
              "TOTAL B/C": "TOTAL B/C"
            },
            "MARLEX PHARMACEUTICALS": {
              "VITAMIN B COMPLEX-C": "VITAMIN B COMPLEX-C"
            },
            "MASON VITAMINS": {
              "B COMPLEX-VITAMIN C": "B COMPLEX-VITAMIN C"
            },
            "MCKESSON": {
              "HM B COMPLEX/C": "HM B COMPLEX/C"
            },
            "MCKESSON SUNMARK": {
              "SM SUPER B COMPLEX/C": "SM SUPER B COMPLEX/C",
              "SM VITAMIN B COMPLEX/VITAMIN C": "SM VITAMIN B COMPLEX/VITAMIN C"
            },
            "MEDICINE SHOPPE": {
              "B COMPLEX-C": "B COMPLEX-C"
            },
            "NATIONAL VITAMIN": {
              "SUPER B/C": "SUPER B/C"
            },
            "PHARMASSURE, INC.": {
              "BALANCED B COMPLEX CR": "BALANCED B COMPLEX CR"
            },
            "PHARMAVITE": {
              "B COMPLEX-C": "B COMPLEX-C"
            },
            "RITE AID CORPORATION": {
              "RA B-COMPLEX/VITAMIN C CR": "RA B-COMPLEX/VITAMIN C CR"
            },
            "RUGBY LABORATORIES": {
              "B COMPLEX-C": "B COMPLEX-C",
              "VITAMIN B + C COMPLEX": "VITAMIN B + C COMPLEX"
            },
            "WALGREENS": {
              "B COMPLEX-C": "B COMPLEX-C",
              "B-COMPLEX-C": "B-COMPLEX-C",
              "SUPER B COMPLEX/VITAMIN C": "SUPER B COMPLEX/VITAMIN C"
            }
          },
          "B-Complex w/ C & Calcium": {
            "BERGEN BRUNSWIG": {
              "GNP B-COMPLEX PLUS VITAMIN C": "GNP B-COMPLEX PLUS VITAMIN C"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC B-COMPLEX/VITAMIN C": "QC B-COMPLEX/VITAMIN C"
            }
          },
          "B-Complex w/ C & E": {
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "PRONUTRIENTS SUPER B COMPLEX": "PRONUTRIENTS SUPER B COMPLEX"
            }
          },
          "B-Complex w/ C & E + Zn": {
            "21ST CENTURY HEALTHCARE": {
              "STRESS B/ZINC": "STRESS B/ZINC"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS STRESS FORMULA/ZINC": "CVS STRESS FORMULA/ZINC"
            },
            "EQUALINE": {
              "EQL STRESS B-COMPLEX C/ZINC": "EQL STRESS B-COMPLEX C/ZINC"
            },
            "GERI-CARE": {
              "ADVANCED STRESS FORMULA/ZINC": "ADVANCED STRESS FORMULA/ZINC"
            },
            "MAGNO-HUMPHRIES LABORATORIES": {
              "BEC/ZINC": "BEC/ZINC",
              "STRESS PLUS ZINC": "STRESS PLUS ZINC",
              "ZINC-VITES": "ZINC-VITES"
            },
            "MAJOR PHARMACEUTICALS": {
              "STRESS FORMULA/ZINC": "STRESS FORMULA/ZINC"
            },
            "MEDICINE SHOPPE": {
              "STRESS FORMULA/ZINC": "STRESS FORMULA/ZINC"
            },
            "NATIONAL VITAMIN": {
              "STRESS FORMULA/ZINC": "STRESS FORMULA/ZINC"
            },
            "NATURES BOUNTY": {
              "STRESS FORMULA/ZINC": "STRESS FORMULA/ZINC"
            },
            "PHARMAVITE": {
              "STRESS B-COMPLEX/VIT C/ZINC": "STRESS B-COMPLEX/VIT C/ZINC"
            },
            "RUGBY LABORATORIES": {
              "BEE ZEE": "BEE ZEE"
            }
          }
        },
        "B-Complex w/ Folic Acid": {
          "B-Complex w/ C & Folic Acid": {
            "7T PHARMA": {
              "GENICIN VITA-S": "GENICIN VITA-S"
            },
            "ALLERGAN": {
              "NEPHRO-VITE RX": "NEPHRO-VITE RX"
            },
            "AMELLA PHARMA": {
              "HYLAVITE": "HYLAVITE",
              "TRONVITE": "TRONVITE"
            },
            "AMERICAN HEALTH PACKAGING": {
              "NEPHROCAPS": "NEPHROCAPS"
            },
            "ARISE PHARMACEUTICALS": {
              "ACTIVITE": "ACTIVITE"
            },
            "AUM PHARMACEUTICALS": {
              "DAVITE": "DAVITE"
            },
            "BAUSCH HEALTH": {
              "NEPHROCAPS": "NEPHROCAPS"
            },
            "BRECKENRIDGE": {
              "FOLBEE PLUS": "FOLBEE PLUS"
            },
            "CHAIN DRUG CONSORTIUM": {
              "STRESS 500 B-COMPLEX": "STRESS 500 B-COMPLEX"
            },
            "CONTRACT PHARMACAL CORPORATION": {
              "B-PLEX": "B-PLEX"
            },
            "CYPRESS PHARMACEUTICAL": {
              "RENA-VITE": "RENA-VITE",
              "RENA-VITE RX": "RENA-VITE RX",
              "RENAL": "RENAL"
            },
            "EQUALINE": {
              "EQL B COMPLEX": "EQL B COMPLEX",
              "EQL SUPER B COMPLEX/VITAMIN C": "EQL SUPER B COMPLEX/VITAMIN C"
            },
            "FOXLAND PHARMACEUTICALS": {
              "XVITE": "XVITE"
            },
            "GERI-CARE": {
              "NEPHRO VITAMINS": "NEPHRO VITAMINS"
            },
            "HILLESTAD PHARMACEUTICALS": {
              "DIALYVITE": "DIALYVITE",
              "DIALYVITE 800": "DIALYVITE 800"
            },
            "INNOVIDA PHARMACEUTIQUE": {
              "FOLIKA-NC": "FOLIKA-NC",
              "FOLIKA-T": "FOLIKA-T"
            },
            "INTEGRATIVE THERAPEUTICS": {
              "RENAL MULTIVITAMIN FORMULA": "RENAL MULTIVITAMIN FORMULA",
              "VITALINE BIOTIN FORTE": "VITALINE BIOTIN FORTE"
            },
            "KAISER FOUNDATION HOSPITAL": {
              "KP B COMPLEX-C": "KP B COMPLEX-C"
            },
            "LLORENS PHARMACEUTICAL": {
              "NEPHRONEX": "NEPHRONEX"
            },
            "MCKESSON": {
              "HM SUPER VITAMIN B COMPLEX/C": "HM SUPER VITAMIN B COMPLEX/C",
              "HM VITAMIN B COMPLEX/VITAMIN C": "HM VITAMIN B COMPLEX/VITAMIN C"
            },
            "MCKESSON SUNMARK": {
              "SM B SUPER VITAMIN COMPLEX": "SM B SUPER VITAMIN COMPLEX",
              "SM B-COMPLEX/VITAMIN C": "SM B-COMPLEX/VITAMIN C"
            },
            "ME PHARMACEUTICALS": {
              "MYNEPHROCAPS": "MYNEPHROCAPS",
              "MYNEPHRON": "MYNEPHRON"
            },
            "MILLER": {
              "MILCO-B-FORTE": "MILCO-B-FORTE"
            },
            "NATIONAL BIO GREEN SCIENCES": {
              "VITASURE": "VITASURE"
            },
            "NATIONAL VITAMIN": {
              "FULL SPECTRUM B/VITAMIN C": "FULL SPECTRUM B/VITAMIN C"
            },
            "NATURES BOUNTY": {
              "B-COMPLEX/FOLIC ACID/VITAMIN C": "B-COMPLEX/FOLIC ACID/VITAMIN C",
              "SUPER B COMPLEX/FA/VIT C": "SUPER B COMPLEX/FA/VIT C"
            },
            "NNODUM CORPORATION": {
              "RENO CAPS": "RENO CAPS"
            },
            "PHARMAVITE": {
              "SUPER B-COMPLEX/VIT C/FA": "SUPER B-COMPLEX/VIT C/FA"
            },
            "PLUS PHARMA": {
              "RENAL-VITE": "RENAL-VITE"
            },
            "PRINCETON RESEARCH": {
              "B COMPLEX-C-FOLIC ACID": "B COMPLEX-C-FOLIC ACID"
            },
            "PUBLIX SUPER MARKETS INC.": {
              "PX B COMPLEX/VITAMIN C": "PX B COMPLEX/VITAMIN C"
            },
            "RELIABLE 1 LABS": {
              "RENAL VITAMIN": "RENAL VITAMIN"
            },
            "REXALL SUNDOWN": {
              "B COMPLEX + C TR": "B COMPLEX + C TR"
            },
            "RUGBY LABORATORIES": {
              "NEPHRO-VITE": "NEPHRO-VITE",
              "VITA-BEE/C": "VITA-BEE/C"
            },
            "TALEOS PHARMA": {
              "DEXIFOL": "DEXIFOL"
            },
            "TMIG": {
              "LORID": "LORID"
            },
            "TRIGEN LABORATORIES": {
              "TRIPHROCAPS": "TRIPHROCAPS",
              "VOL-CARE RX": "VOL-CARE RX"
            },
            "VIRTUS PHARMACEUTICALS": {
              "VIRT-CAPS": "VIRT-CAPS",
              "VP-VITE RX": "VP-VITE RX"
            },
            "VIRTUS PHARMACEUTICALS OPCO": {
              "VIRT-CAPS": "VIRT-CAPS",
              "VIRT-VITE PLUS": "VIRT-VITE PLUS"
            },
            "WALGREENS": {
              "B COMPLEX-C-FOLIC ACID": "B COMPLEX-C-FOLIC ACID",
              "B-COMPLEX BALANCED": "B-COMPLEX BALANCED",
              "B-COMPLEX/VITAMIN C": "B-COMPLEX/VITAMIN C",
              "STRESS FORMULA": "STRESS FORMULA"
            },
            "WESTMINSTER PHARMACEUTICALS": {
              "WEST-VITE W/FOLIC ACID": "WEST-VITE W/FOLIC ACID"
            }
          },
          "B-Complex w/ C-Biotin-D & Folic Acid": {
            "HILLESTAD PHARMACEUTICALS": {
              "DIALYVITE 800 PLUS D": "DIALYVITE 800 PLUS D"
            }
          },
          "B-Complex w/ C-Biotin-D-Zinc & Folic Acid": {
            "NEPHRO-TECH": {
              "VITAL-D RX": "VITAL-D RX"
            }
          },
          "B-Complex w/ C-Biotin-E & Folic Acid": {
            "HAWTHORN PHARMACEUTICALS": {
              "RENATABS": "RENATABS"
            },
            "WALGREENS": {
              "B COMPLEX-C-BIOTIN-E-FA": "B COMPLEX-C-BIOTIN-E-FA"
            }
          },
          "B-Complex w/ C-Biotin-E-Folic Acid & Iron": {
            "HAWTHORN PHARMACEUTICALS": {
              "RENATABS WITH IRON": "RENATABS WITH IRON"
            }
          },
          "B-Complex w/ C-Biotin-E-Minerals & Folic Acid": {
            "HILLESTAD PHARMACEUTICALS": {
              "DIALYVITE 3000": "DIALYVITE 3000",
              "DIALYVITE 5000": "DIALYVITE 5000"
            }
          },
          "B-Complex w/ C-Biotin-Fe & Folic Acid": {
            "HILLESTAD PHARMACEUTICALS": {
              "DIALYVITE 800/IRON": "DIALYVITE 800/IRON"
            }
          },
          "B-Complex w/ C-Biotin-Minerals & Folic Acid": {
            "BRECKENRIDGE": {
              "FOLBEE PLUS CZ": "FOLBEE PLUS CZ"
            }
          },
          "B-Complex w/ C-Zn & Folic Acid": {
            "HILLESTAD PHARMACEUTICALS": {
              "DIALYVITE 800-ZINC 15": "DIALYVITE 800-ZINC 15",
              "DIALYVITE 800/ZINC": "DIALYVITE 800/ZINC",
              "DIALYVITE/ZINC": "DIALYVITE/ZINC"
            },
            "INTEGRATIVE THERAPEUTICS": {
              "VITALINE BIOTIN FORTE/ZINC": "VITALINE BIOTIN FORTE/ZINC"
            },
            "NEPHRO-TECH": {
              "NEPHPLEX RX": "NEPHPLEX RX"
            }
          },
          "B-Complex w/ E & Folic Acid": {
            "AXIS PHARMACEUTICS": {
              "FOLICA-V": "FOLICA-V"
            },
            "FOXLAND PHARMACEUTICALS": {
              "FOLIC-K": "FOLIC-K"
            },
            "KEY THERAPEUTICS": {
              "FIBRIK": "FIBRIK"
            }
          },
          "B-Complex w/ Folic Acid": {
            "BENFOTIAMINE INC": {
              "BENFOTIAMINE MULTI-B": "BENFOTIAMINE MULTI-B"
            },
            "BERGEN BRUNSWIG": {
              "GNP B-100 BALANCED TR": "GNP B-100 BALANCED TR"
            },
            "CHAIN DRUG CONSORTIUM": {
              "B-COMPLEX/AMINO ACID": "B-COMPLEX/AMINO ACID"
            },
            "FREEDA VITAMINS": {
              "KOBEE": "KOBEE"
            },
            "MCKESSON SUNMARK": {
              "SM BALANCED B-100": "SM BALANCED B-100",
              "SM BALANCED B-50": "SM BALANCED B-50"
            },
            "MILLER": {
              "M2 B125": "M2 B125"
            },
            "NATIONAL VITAMIN": {
              "B COMPLEX FORMULA 1": "B COMPLEX FORMULA 1"
            },
            "NATURES BOUNTY": {
              "B-50 CR": "B-50 CR"
            },
            "PHARMAVITE": {
              "BALANCED B-50": "BALANCED B-50"
            },
            "REXALL SUNDOWN": {
              "B COMPLEX PLUS": "B COMPLEX PLUS",
              "SUPER B COMPLEX MAXI": "SUPER B COMPLEX MAXI"
            }
          },
          "B-Complex w/ Lysine-Min-Fe & Folic Acid": {
            "ACTIPHARMA": {
              "ACTRIVIT": "ACTRIVIT"
            },
            "LLORENS PHARMACEUTICAL": {
              "NUTRIVIT": "NUTRIVIT"
            }
          },
          "B-Complex w/ Lysine-Zn & Folic Acid": {
            "ADVANCED GENERIC": {
              "BIOTALAN CLEAR": "BIOTALAN CLEAR"
            },
            "SEYER PHARMATEC": {
              "SUPERVITE": "SUPERVITE"
            }
          },
          "B-Complex w/Biotin & Folic Acid": {
            "21ST CENTURY HEALTHCARE": {
              "COMPLEX B-50 PROLONGED RELEASE": "COMPLEX B-50 PROLONGED RELEASE"
            },
            "AMERICAN HEALTH PACKAGING": {
              "B-100 COMPLEX": "B-100 COMPLEX"
            },
            "AVPAK": {
              "BALANCE B-50": "BALANCE B-50"
            },
            "BASIC DRUGS": {
              "B-100 COMPLEX CR": "B-100 COMPLEX CR",
              "SUPER B-50 B COMPLEX": "SUPER B-50 B COMPLEX"
            },
            "BERGEN BRUNSWIG": {
              "GNP B-100 COMPLEX": "GNP B-100 COMPLEX",
              "GNP B-50 BALANCED": "GNP B-50 BALANCED",
              "GNP B-50 COMPLEX": "GNP B-50 COMPLEX"
            },
            "CHAIN DRUG CONSORTIUM": {
              "B-COMPLEX HIGH STRENGTH 10": "B-COMPLEX HIGH STRENGTH 10"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC B50 PROLONGED RELEASE": "QC B50 PROLONGED RELEASE"
            },
            "CHRONOHEALTH": {
              "B-COMPLEX": "B-COMPLEX"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS BALANCED B-100": "CVS BALANCED B-100"
            },
            "ENDURANCE PRODUCTS": {
              "ENDUR-B": "ENDUR-B"
            },
            "EQUALINE": {
              "EQL B COMPLEX 100": "EQL B COMPLEX 100",
              "EQL B COMPLEX 50": "EQL B COMPLEX 50",
              "EQL B-100 COMPLEX": "EQL B-100 COMPLEX"
            },
            "FREEDA VITAMINS": {
              "QUIN B STRONG B-25": "QUIN B STRONG B-25",
              "SUPER DEC B-100": "SUPER DEC B-100",
              "SUPER QUINTS B-50": "SUPER QUINTS B-50"
            },
            "GENERAL NUTRITION CORP": {
              "BIG 100": "BIG 100"
            },
            "GERI-CARE": {
              "B COMPLEX-BIOTIN-FA": "B COMPLEX-BIOTIN-FA"
            },
            "HANNAFORD BROTHERS": {
              "B-COMPLEX": "B-COMPLEX"
            },
            "INTEGRATIVE THERAPEUTICS": {
              "B COMPLEX 100 TR": "B COMPLEX 100 TR",
              "MULTI-B COMPLEX": "MULTI-B COMPLEX"
            },
            "J R CARLSON LABORATORIES": {
              "B-COMPLEET-100": "B-COMPLEET-100",
              "B-COMPLEET-50": "B-COMPLEET-50"
            },
            "LEINER HEALTH PRODUCTS": {
              "YL BALANCED B-100": "YL BALANCED B-100"
            },
            "MAGNO-HUMPHRIES LABORATORIES": {
              "BALANCED B COMPLEX": "BALANCED B COMPLEX"
            },
            "MASON VITAMINS": {
              "SUPER B-50 COMPLEX": "SUPER B-50 COMPLEX"
            },
            "MCKESSON": {
              "HM VITAMIN B100 COMPLEX": "HM VITAMIN B100 COMPLEX",
              "HM VITAMIN B50 COMPLEX": "HM VITAMIN B50 COMPLEX"
            },
            "MCKESSON SUNMARK": {
              "SM B-COMPLEX": "SM B-COMPLEX",
              "SM B100 COMPLEX": "SM B100 COMPLEX"
            },
            "MEIJER": {
              "B COMPLEX 100 TR": "B COMPLEX 100 TR"
            },
            "NATIONAL VITAMIN": {
              "BALANCE B-50": "BALANCE B-50"
            },
            "PHARMASSURE, INC.": {
              "B COMPLETE": "B COMPLETE",
              "B-50 COMPLEX": "B-50 COMPLEX",
              "POTEN B-150 CR": "POTEN B-150 CR"
            },
            "PHARMAVITE": {
              "BALANCED B-100": "BALANCED B-100",
              "SUPER B-COMPLEX": "SUPER B-COMPLEX"
            },
            "PRINCETON RESEARCH": {
              "B-100 HIGH POTENCY BALANCED": "B-100 HIGH POTENCY BALANCED"
            },
            "PURE TEK": {
              "VITAMIN B50 COMPLEX": "VITAMIN B50 COMPLEX"
            },
            "REXALL SUNDOWN": {
              "B-50 COMPLEX": "B-50 COMPLEX"
            },
            "RITE AID CORPORATION": {
              "RA BALANCED B-100": "RA BALANCED B-100",
              "RA BALANCED B-100 CR": "RA BALANCED B-100 CR",
              "RA BALANCED B-50": "RA BALANCED B-50",
              "RA BALANCED B-50 TR": "RA BALANCED B-50 TR"
            },
            "TEVA PHARMACEUTICALS USA": {
              "SUPER B-100": "SUPER B-100"
            },
            "THE KEY COMPANY": {
              "EXTRESS": "EXTRESS",
              "EXTRESS-30": "EXTRESS-30",
              "EXTRESS-SUPER": "EXTRESS-SUPER"
            },
            "WAL-MART": {
              "B-100 COMPLEX CR": "B-100 COMPLEX CR",
              "B-COMPLEX": "B-COMPLEX",
              "B50 COMPLEX TR": "B50 COMPLEX TR",
              "SUPER B-COMPLEX": "SUPER B-COMPLEX"
            },
            "WALGREENS": {
              "B-100 B-COMPLEX": "B-100 B-COMPLEX",
              "B-100 TR": "B-100 TR",
              "BALANCED B-100": "BALANCED B-100"
            },
            "WINDMILL CONSUMER PRODUCTS": {
              "B-COMPLEX": "B-COMPLEX",
              "SUPER B-100": "SUPER B-100",
              "SUPER B-50": "SUPER B-50"
            }
          }
        },
        "B-Complex w/ Iron": {
          "B-Complex w/ Iron": {
            "GIL PHARMACEUTICAL CORP.": {
              "SUPLEVIT": "SUPLEVIT"
            },
            "KRAMER-NOVIS": {
              "APETIGEN-PLUS": "APETIGEN-PLUS"
            },
            "NAT-RUL HEALTH PRODUCTS": {
              "B COMPLEX-IRON": "B COMPLEX-IRON"
            },
            "WALGREENS": {
              "SUPER B-COMPLEX/IRON/VITAMIN C": "SUPER B-COMPLEX/IRON/VITAMIN C"
            },
            "WINDMILL CONSUMER PRODUCTS": {
              "B COMPLEX-C-IRON": "B COMPLEX-C-IRON"
            }
          }
        },
        "B-Complex w/ Minerals": {
          "B-Complex w/ Minerals": {
            "ADVANCED GENERIC": {
              "RABANO YODADO": "RABANO YODADO"
            },
            "BIO-TECH": {
              "GLYCO-TECH": "GLYCO-TECH"
            },
            "CYPRESS PHARMACEUTICAL": {
              "GERIATRIC VITAMIN": "GERIATRIC VITAMIN",
              "VITONIC": "VITONIC"
            },
            "DSE HEALTHCARE SOLUTIONS": {
              "ELDERTONIC": "ELDERTONIC"
            },
            "GERI-CARE": {
              "SENIOR TONIC": "SENIOR TONIC"
            },
            "HRA PHARMA RARE DISEASES": {
              "ELDERTONIC": "ELDERTONIC"
            },
            "KRAMER-NOVIS": {
              "APETIGEN-PLUS": "APETIGEN-PLUS"
            },
            "MAJOR PHARMACEUTICALS": {
              "GERAVIM": "GERAVIM"
            },
            "SILARX": {
              "GERIATON": "GERIATON"
            }
          }
        },
        "Bioflavonoid Products": {
          "Bioflavonoid Products": {
            "ACTIPHARMA": {
              "ACTITROM": "ACTITROM",
              "ACTITROM-D": "ACTITROM-D"
            },
            "ADVANCED GENERIC": {
              "BIOFLEX": "BIOFLEX"
            },
            "BEUTLICH PHARMA": {
              "PERIDIN-C": "PERIDIN-C"
            },
            "BIO-TECH": {
              "C-PLEX": "C-PLEX"
            },
            "CHAIN DRUG CONSORTIUM": {
              "ESTER-C/BIOFLAVONOIDS": "ESTER-C/BIOFLAVONOIDS",
              "VITAMIN C-ROSE HIPS-ACEROLA": "VITAMIN C-ROSE HIPS-ACEROLA"
            },
            "DOMEL LABORATORIES": {
              "DAFLONEX-XL": "DAFLONEX-XL"
            },
            "EQUALINE": {
              "EQL ESTER-C": "EQL ESTER-C"
            },
            "FREEDA VITAMINS": {
              "ANTI-ALLERGY": "ANTI-ALLERGY",
              "CITRUS BIOFLAVONOIDS": "CITRUS BIOFLAVONOIDS",
              "FRUIT C 200": "FRUIT C 200",
              "PAN-C 500/BIOFLAVONOIDS": "PAN-C 500/BIOFLAVONOIDS",
              "SPAN C": "SPAN C",
              "TRI SUPER FLAVONS": "TRI SUPER FLAVONS"
            },
            "GENERAL NUTRITION CORP": {
              "VITAMIN C-BIOFLAVONOIDS": "VITAMIN C-BIOFLAVONOIDS"
            },
            "INTEGRATIVE THERAPEUTICS": {
              "VITAMIN C/BIOFLAVONOIDS": "VITAMIN C/BIOFLAVONOIDS"
            },
            "KRAMER-NOVIS": {
              "FLEXGEN": "FLEXGEN",
              "VASOFLEX": "VASOFLEX",
              "VASOFLEX FORTE": "VASOFLEX FORTE",
              "VASOFLEX HD": "VASOFLEX HD"
            },
            "LLORENS PHARMACEUTICAL": {
              "TROMBONEX": "TROMBONEX",
              "TROMBONEX-D": "TROMBONEX-D"
            },
            "MAGNO-HUMPHRIES LABORATORIES": {
              "C1000 TR/ROSE HIP/BIOFLAVONOID": "C1000 TR/ROSE HIP/BIOFLAVONOID",
              "C1500 TR/ROSE HIP/BIOFLAVONOID": "C1500 TR/ROSE HIP/BIOFLAVONOID",
              "HI C-500": "HI C-500"
            },
            "MAJOR PHARMACEUTICALS": {
              "ESTER-C": "ESTER-C"
            },
            "MASON VITAMINS": {
              "C1500/ROSE HIPS/BIOFLAVONOID": "C1500/ROSE HIPS/BIOFLAVONOID",
              "ESTER-C": "ESTER-C",
              "SUPER C-500 COMPLEX": "SUPER C-500 COMPLEX"
            },
            "MILLER": {
              "CEMILL PLUS": "CEMILL PLUS",
              "CEMILL/BIOFLAVONOIDS": "CEMILL/BIOFLAVONOIDS",
              "M2 C": "M2 C"
            },
            "NAT-RUL HEALTH PRODUCTS": {
              "ESTER-C": "ESTER-C",
              "NAT-RUL C-COMPLEX": "NAT-RUL C-COMPLEX",
              "NAT-RUL C-COMPLEX TR": "NAT-RUL C-COMPLEX TR"
            },
            "NATIONAL VITAMIN": {
              "C 1000-BIOFLAVONOIDS-ROSE HIPS": "C 1000-BIOFLAVONOIDS-ROSE HIPS",
              "C COMPLEX": "C COMPLEX",
              "VITAMIN C": "VITAMIN C"
            },
            "NATROL": {
              "EASY-C": "EASY-C"
            },
            "NATURES BOUNTY": {
              "ESTER-C": "ESTER-C"
            },
            "PCCA": {
              "ADRENAL C FORMULA": "ADRENAL C FORMULA"
            },
            "PHARMASSURE, INC.": {
              "VITAMIN C ER": "VITAMIN C ER"
            },
            "PHARMAVITE": {
              "ADVANCED C PLUS": "ADVANCED C PLUS",
              "ESTER-C": "ESTER-C"
            },
            "PRINCETON RESEARCH": {
              "VITA C/BIOFLAVONOIDS/ROSE HIPS": "VITA C/BIOFLAVONOIDS/ROSE HIPS"
            },
            "RELIABLE 1 LABS": {
              "LIQUID C": "LIQUID C"
            },
            "REXALL SUNDOWN": {
              "VITAMIN C PLUS": "VITAMIN C PLUS"
            },
            "RITE AID CORPORATION": {
              "RA VITAMIN C CR": "RA VITAMIN C CR"
            },
            "THE KEY COMPANY": {
              "ASCOCID-1000": "ASCOCID-1000",
              "ASCOCID-500-D": "ASCOCID-500-D"
            },
            "THORNE RESEARCH": {
              "THORNE VITAMIN C-FLAVONOIDS": "THORNE VITAMIN C-FLAVONOIDS"
            },
            "TWIN LABS": {
              "LIQUID C": "LIQUID C"
            },
            "WALGREENS": {
              "ESTER-C": "ESTER-C",
              "GRAPE SEED": "GRAPE SEED"
            },
            "WINDMILL CONSUMER PRODUCTS": {
              "SUPER C-500": "SUPER C-500",
              "SUPER-C 1000": "SUPER-C 1000",
              "VITAMIN C": "VITAMIN C"
            }
          }
        },
        "Biotin w/ Vitamins C & E": {
          "Biotin w/ Vitamins C & E": {
            "NATURES BOUNTY": {
              "HAIR SKIN & NAILS GUMMIES": "HAIR SKIN & NAILS GUMMIES"
            },
            "PRINCETON RESEARCH": {
              "HAIR/SKIN/NAILS": "HAIR/SKIN/NAILS"
            }
          }
        },
        "Iron w/ Vitamins": {
          "Iron w/ Vitamins": {
            "EXELTIS USA": {
              "VITAFOL": "VITAFOL"
            },
            "MEDA CONSUMER HEALTHCARE": {
              "GERITOL COMPLETE": "GERITOL COMPLETE",
              "GERITOL TONIC": "GERITOL TONIC"
            },
            "SCOT-TUSSIN": {
              "VITA-PLUS H": "VITA-PLUS H",
              "VITALIZE": "VITALIZE"
            },
            "SSS": {
              "S.S.S. TONIC": "S.S.S. TONIC"
            }
          }
        },
        "Multiple Vitamins & Fluoride-Folic Acid": {
          "Multiple Vitamins & Fluoride-Folic Acid": {
            "NATIONWIDE LABORATORIES": {
              "MULTIVITAMIN/FLUORIDE": "MULTIVITAMIN/FLUORIDE"
            }
          }
        },
        "Multiple Vitamins w/ Calcium": {
          "Multiple Vitamins w/ Calcium": {
            "AMERISOURCE BERGEN DRUGS": {
              "GNP ONE DAILY WOMENS HEALTH": "GNP ONE DAILY WOMENS HEALTH"
            },
            "BAYER CONSUMER": {
              "ONE-A-DAY WOMENS FORMULA": "ONE-A-DAY WOMENS FORMULA"
            },
            "DOMEL LABORATORIES": {
              "CALCI-MAX": "CALCI-MAX"
            },
            "EQUALINE": {
              "EQL ONE DAILY WOMENS": "EQL ONE DAILY WOMENS"
            },
            "MCKESSON": {
              "HM ONE DAILY ESSENTIAL": "HM ONE DAILY ESSENTIAL",
              "HM VITA-MINI MULTI COMPLETE": "HM VITA-MINI MULTI COMPLETE"
            },
            "MCKESSON SUNMARK": {
              "SM ONE DAILY ESSENTIAL": "SM ONE DAILY ESSENTIAL"
            },
            "NAT-RUL HEALTH PRODUCTS": {
              "SIGNACAL": "SIGNACAL"
            },
            "NATURES BOUNTY": {
              "MULTI-DAY/CALCIUM/EXTRA IRON": "MULTI-DAY/CALCIUM/EXTRA IRON"
            },
            "TARGET": {
              "TGT DAILY MULTIVITAMIN WOMENS": "TGT DAILY MULTIVITAMIN WOMENS"
            },
            "TRIGEN LABORATORIES": {
              "ADVANCED AM/PM": "ADVANCED AM/PM"
            },
            "WALGREENS": {
              "ESSENTIAL ONE DAILY MULTIVIT": "ESSENTIAL ONE DAILY MULTIVIT"
            }
          }
        },
        "Multiple Vitamins w/ Iron": {
          "Multiple Vitamins w/ Iron": {
            "A-S MEDICATION SOLUTIONS": {
              "DAILY MULTIPLE VITAMINS/IRON": "DAILY MULTIPLE VITAMINS/IRON",
              "MULTIPLE VITAMINS-IRON": "MULTIPLE VITAMINS-IRON"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP ONE DAILY PLUS IRON": "GNP ONE DAILY PLUS IRON"
            },
            "AUBURN PHARMACEUTICAL": {
              "ONCE DAILY/IRON": "ONCE DAILY/IRON"
            },
            "BASIC DRUGS": {
              "MULTI-VITAMIN/IRON": "MULTI-VITAMIN/IRON"
            },
            "BERGEN BRUNSWIG": {
              "GNP ONE DAILY PLUS IRON": "GNP ONE DAILY PLUS IRON"
            },
            "BIOTICS RESEARCH": {
              "CHLORELLA": "CHLORELLA"
            },
            "BRYANT RANCH PREPACK": {
              "DAILY VITAMIN/IRON": "DAILY VITAMIN/IRON"
            },
            "CHAIN DRUG CONSORTIUM": {
              "DAILY MULTIPLE VITAMIN/IRON": "DAILY MULTIPLE VITAMIN/IRON",
              "DAILY MULTIPLE VITAMINS/IRON": "DAILY MULTIPLE VITAMINS/IRON"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC DAILY MULTIVITAMINS/IRON": "QC DAILY MULTIVITAMINS/IRON"
            },
            "GERI-CARE": {
              "ONE-DAILY MULTI-VITAMIN/IRON": "ONE-DAILY MULTI-VITAMIN/IRON",
              "ONE-DAILY/IRON": "ONE-DAILY/IRON"
            },
            "LEADER BRAND PRODUCTS": {
              "ONE DAILY MULTIVITAMIN/IRON": "ONE DAILY MULTIVITAMIN/IRON"
            },
            "MAGNO-HUMPHRIES LABORATORIES": {
              "MULTI-VITAMIN/IRON": "MULTI-VITAMIN/IRON"
            },
            "MAJOR PHARMACEUTICALS": {
              "STRESS FORMULA/IRON": "STRESS FORMULA/IRON",
              "TAB-A-VITE/IRON": "TAB-A-VITE/IRON"
            },
            "MCKESSON": {
              "HM ONE DAILY/IRON": "HM ONE DAILY/IRON"
            },
            "MCKESSON SUNMARK": {
              "SM MULTIPLE VITAMINS/IRON": "SM MULTIPLE VITAMINS/IRON"
            },
            "MEDICINE SHOPPE": {
              "MULTIPLE VITAMINS/IRON": "MULTIPLE VITAMINS/IRON"
            },
            "NAT-RUL HEALTH PRODUCTS": {
              "NAT-RUL DAILY-VITE+IRON": "NAT-RUL DAILY-VITE+IRON"
            },
            "NATIONAL VITAMIN": {
              "DAILY VITE MULTIVITAMIN/IRON": "DAILY VITE MULTIVITAMIN/IRON",
              "STRESS FORMULA/IRON": "STRESS FORMULA/IRON"
            },
            "NATURES BOUNTY": {
              "MULTI-DAY PLUS IRON": "MULTI-DAY PLUS IRON"
            },
            "PHARMAVITE": {
              "STRESS B COMPLEX/IRON": "STRESS B COMPLEX/IRON"
            },
            "PLUS PHARMA": {
              "DAILY VITAMIN FORMULA+IRON": "DAILY VITAMIN FORMULA+IRON"
            },
            "REXALL SUNDOWN": {
              "DAILY MULTIPLE VITAMINS/IRON": "DAILY MULTIPLE VITAMINS/IRON"
            },
            "RITE AID CORPORATION": {
              "RA ONE DAILY MULTI-VIT PLUS FE": "RA ONE DAILY MULTI-VIT PLUS FE"
            },
            "RUGBY LABORATORIES": {
              "DAILY-VITE/IRON/BETA-CAROTENE": "DAILY-VITE/IRON/BETA-CAROTENE",
              "TAB-A-VITE/IRON": "TAB-A-VITE/IRON"
            },
            "VANGARD": {
              "DAILY MULTIPLE VITAMINS/IRON": "DAILY MULTIPLE VITAMINS/IRON"
            },
            "WALGREENS": {
              "MULTIPLE VITAMINS/IRON": "MULTIPLE VITAMINS/IRON",
              "ONE DAILY MULTIVITAMIN/IRON": "ONE DAILY MULTIVITAMIN/IRON"
            },
            "WINDMILL CONSUMER PRODUCTS": {
              "DAILY-VITAMIN/IRON": "DAILY-VITAMIN/IRON",
              "STRESS FORMULA/IRON": "STRESS FORMULA/IRON"
            }
          }
        },
        "Multiple Vitamins w/ Minerals": {
          "Multiple Vitamins w/ Minerals": {
            "21ST CENTURY HEALTHCARE": {
              "HAIR SKIN & NAILS ADVANCED": "HAIR SKIN & NAILS ADVANCED",
              "HEALTHY EYES": "HEALTHY EYES",
              "MEGA MULTI FOR WOMEN": "MEGA MULTI FOR WOMEN",
              "MEGA MULTI MEN": "MEGA MULTI MEN",
              "ONE DAILY ADULTS 50+": "ONE DAILY ADULTS 50+",
              "ONE DAILY MAXIMUM": "ONE DAILY MAXIMUM",
              "ONE DAILY MENS HEALTH": "ONE DAILY MENS HEALTH",
              "ONE DAILY WOMENS": "ONE DAILY WOMENS",
              "ONE DAILY WOMENS 50+": "ONE DAILY WOMENS 50+",
              "SENTRY": "SENTRY",
              "SENTRY SENIOR": "SENTRY SENIOR",
              "THERAPEUTIC-M/LUTEIN": "THERAPEUTIC-M/LUTEIN"
            },
            "A-S MEDICATION SOLUTIONS": {
              "THERA-M": "THERA-M",
              "VICAP FORTE": "VICAP FORTE"
            },
            "ACELLA PHARMACEUTICALS": {
              "AP-ZEL": "AP-ZEL"
            },
            "ADVANCED GENERIC": {
              "BIOCAL": "BIOCAL",
              "BIOCEL": "BIOCEL",
              "BIOSUPP": "BIOSUPP",
              "BIOTECT PLUS": "BIOTECT PLUS",
              "BIOVOL": "BIOVOL"
            },
            "ADVANCED MEDICAL ENTERPRISES": {
              "VITABEX": "VITABEX"
            },
            "AG MARIN PHARMACEUTICALS": {
              "ACTICAL": "ACTICAL",
              "PREVENT": "PREVENT",
              "SIDEROL": "SIDEROL",
              "SUPPORT": "SUPPORT"
            },
            "AKORN CONSUMER": {
              "MULTI-BETIC DIABETES": "MULTI-BETIC DIABETES"
            },
            "ALCON VISION": {
              "ICAPS": "ICAPS",
              "ICAPS AREDS FORMULA": "ICAPS AREDS FORMULA",
              "ICAPS LUTEIN & OMEGA-3": "ICAPS LUTEIN & OMEGA-3",
              "ICAPS MV": "ICAPS MV",
              "ICAPS PLUS": "ICAPS PLUS",
              "SYSTANE ICAPS AREDS2": "SYSTANE ICAPS AREDS2"
            },
            "ALFASIGMA USA": {
              "PROXEED PLUS": "PROXEED PLUS"
            },
            "ALLEGIS PHARMACEUTICALS": {
              "BACMIN": "BACMIN"
            },
            "ALLERGAN": {
              "AQUADEKS": "AQUADEKS"
            },
            "AMELLA PHARMA": {
              "HYLAZINC": "HYLAZINC"
            },
            "AMERICAN HEALTH PACKAGING": {
              "HEALTHY EYES": "HEALTHY EYES",
              "ONE DAILY PLUS IRON": "ONE DAILY PLUS IRON"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP CENTURY": "GNP CENTURY",
              "GNP CENTURY ADULTS 50+ SENIOR": "GNP CENTURY ADULTS 50+ SENIOR",
              "GNP CENTURY ULTIMATE MENS": "GNP CENTURY ULTIMATE MENS",
              "GNP CENTURY ULTIMATE WOMENS": "GNP CENTURY ULTIMATE WOMENS",
              "GNP HEALTHY EYES": "GNP HEALTHY EYES",
              "GNP HEALTHY EYES SUPERVISION": "GNP HEALTHY EYES SUPERVISION",
              "GNP MEGA MULTI FOR MEN": "GNP MEGA MULTI FOR MEN",
              "GNP MEGA MULTI FOR WOMEN": "GNP MEGA MULTI FOR WOMEN",
              "GNP ONE DAILY MAXIMUM": "GNP ONE DAILY MAXIMUM",
              "GNP ONE DAILY MENS HEALTH 50+": "GNP ONE DAILY MENS HEALTH 50+",
              "GNP ONE DAILY MENS/LYCOPENE": "GNP ONE DAILY MENS/LYCOPENE",
              "GNP ONE DAILY WOMENS": "GNP ONE DAILY WOMENS",
              "GNP ONE DAILY WOMENS 50+": "GNP ONE DAILY WOMENS 50+",
              "GNP THERAPEUTIC-M": "GNP THERAPEUTIC-M"
            },
            "APHENA PHARMA SOLUTIONS": {
              "CERTAVITE/ANTIOXIDANTS": "CERTAVITE/ANTIOXIDANTS"
            },
            "APTALIS PHARMA": {
              "AQUADEKS": "AQUADEKS"
            },
            "ATLANTIC BIOLOGICALS": {
              "CERTA-VITE": "CERTA-VITE"
            },
            "AUBURN PHARMACEUTICAL": {
              "COMPLETE": "COMPLETE",
              "COMPLETE SENIOR": "COMPLETE SENIOR",
              "THERA-M": "THERA-M"
            },
            "AVPAK": {
              "SENTRY SENIOR": "SENTRY SENIOR"
            },
            "BARIATRIC FUSION": {
              "BARIATRIC FUSION": "BARIATRIC FUSION"
            },
            "BASIC DRUGS": {
              "EYE VITAMINS": "EYE VITAMINS",
              "SUPER ANTIOXIDANTS PROTECTOR": "SUPER ANTIOXIDANTS PROTECTOR",
              "THERABASIC-M": "THERABASIC-M",
              "VITABASIC COMPLETE": "VITABASIC COMPLETE",
              "VITABASIC SENIOR": "VITABASIC SENIOR"
            },
            "BASIC ORGANICS": {
              "ZINC": "ZINC"
            },
            "BAUSCH & LOMB": {
              "OCUVITE ADULT 50+": "OCUVITE ADULT 50+",
              "PRESERVISION AREDS 2": "PRESERVISION AREDS 2"
            },
            "BAUSCH & LOMB CONS": {
              "OCUVITE ADULT FORMULA": "OCUVITE ADULT FORMULA",
              "OCUVITE EXTRA": "OCUVITE EXTRA",
              "OCUVITE EYE + MULTI": "OCUVITE EYE + MULTI",
              "OCUVITE EYE HEALTH FORMULA": "OCUVITE EYE HEALTH FORMULA",
              "OCUVITE-LUTEIN": "OCUVITE-LUTEIN",
              "PRESERVISION AREDS": "PRESERVISION AREDS",
              "PRESERVISION AREDS 2": "PRESERVISION AREDS 2",
              "PRESERVISION/LUTEIN": "PRESERVISION/LUTEIN"
            },
            "BAUSCH HEALTH": {
              "OCUVITE EYE HEATLH GUMMIES": "OCUVITE EYE HEATLH GUMMIES",
              "OCUVITE-LUTEIN": "OCUVITE-LUTEIN",
              "PRESERVISION AREDS 2": "PRESERVISION AREDS 2",
              "PRESERVISION AREDS 2+MULTI VIT": "PRESERVISION AREDS 2+MULTI VIT"
            },
            "BAYER CONSUMER": {
              "BEROCCA": "BEROCCA",
              "ONE A DAY MENS VITACRAVES": "ONE A DAY MENS VITACRAVES",
              "ONE-A-DAY ENERGY": "ONE-A-DAY ENERGY",
              "ONE-A-DAY FOR HER VITACRAVES": "ONE-A-DAY FOR HER VITACRAVES",
              "ONE-A-DAY FOR HIM VITACRAVES": "ONE-A-DAY FOR HIM VITACRAVES",
              "ONE-A-DAY MENOPAUSE FORMULA": "ONE-A-DAY MENOPAUSE FORMULA",
              "ONE-A-DAY MENS 50+": "ONE-A-DAY MENS 50+",
              "ONE-A-DAY MENS 50+ ADVANTAGE": "ONE-A-DAY MENS 50+ ADVANTAGE",
              "ONE-A-DAY MENS HEALTH FORMULA": "ONE-A-DAY MENS HEALTH FORMULA",
              "ONE-A-DAY MENS PRO EDGE": "ONE-A-DAY MENS PRO EDGE",
              "ONE-A-DAY MENS VITACRAVES": "ONE-A-DAY MENS VITACRAVES",
              "ONE-A-DAY PROACTIVE 65+": "ONE-A-DAY PROACTIVE 65+",
              "ONE-A-DAY TEEN ADVANTAGE/HER": "ONE-A-DAY TEEN ADVANTAGE/HER",
              "ONE-A-DAY TEEN ADVANTAGE/HIM": "ONE-A-DAY TEEN ADVANTAGE/HIM",
              "ONE-A-DAY VITACRAVES": "ONE-A-DAY VITACRAVES",
              "ONE-A-DAY VITACRAVES ADULT": "ONE-A-DAY VITACRAVES ADULT",
              "ONE-A-DAY VITACRAVES IMMUNITY": "ONE-A-DAY VITACRAVES IMMUNITY",
              "ONE-A-DAY VITACRAVES SOUR": "ONE-A-DAY VITACRAVES SOUR",
              "ONE-A-DAY WEIGHT SMART ADVANCE": "ONE-A-DAY WEIGHT SMART ADVANCE",
              "ONE-A-DAY WOMENS": "ONE-A-DAY WOMENS",
              "ONE-A-DAY WOMENS 50 PLUS": "ONE-A-DAY WOMENS 50 PLUS",
              "ONE-A-DAY WOMENS 50+ ADVANTAGE": "ONE-A-DAY WOMENS 50+ ADVANTAGE",
              "ONE-A-DAY WOMENS HEALTHY SKIN": "ONE-A-DAY WOMENS HEALTHY SKIN",
              "ONE-A-DAY WOMENS MIND & BODY": "ONE-A-DAY WOMENS MIND & BODY",
              "ONE-A-DAY WOMENS PETITES": "ONE-A-DAY WOMENS PETITES",
              "ONE-A-DAY WOMENS VITACRAVES": "ONE-A-DAY WOMENS VITACRAVES"
            },
            "BAYSHORE PHARMACEUTICALS": {
              "BPROTECTED MULTI-VITE": "BPROTECTED MULTI-VITE"
            },
            "BERGEN BRUNSWIG": {
              "GNP CENTURY": "GNP CENTURY",
              "GNP CENTURY ACTIVE PERFORMANCE": "GNP CENTURY ACTIVE PERFORMANCE",
              "GNP CENTURY ADULT FORMULA": "GNP CENTURY ADULT FORMULA",
              "GNP CENTURY ADULTS 50+ SENIOR": "GNP CENTURY ADULTS 50+ SENIOR",
              "GNP CENTURY CARDIO HEALTH": "GNP CENTURY CARDIO HEALTH",
              "GNP CENTURY MATURE": "GNP CENTURY MATURE",
              "GNP CENTURY MATURE WOMEN\\'S 50+": "GNP CENTURY MATURE WOMEN\\'S 50+",
              "GNP DIABETIC SUPPORT FORMULA": "GNP DIABETIC SUPPORT FORMULA",
              "GNP HAIR/SKIN/NAILS": "GNP HAIR/SKIN/NAILS",
              "GNP HEALTHY EYES": "GNP HEALTHY EYES",
              "GNP HEALTHY EYES SUPERVISION": "GNP HEALTHY EYES SUPERVISION",
              "GNP HEALTHY EYES SUPERVISION 2": "GNP HEALTHY EYES SUPERVISION 2",
              "GNP IMMUNE SUPPORT": "GNP IMMUNE SUPPORT",
              "GNP MAXIMUM ONE DAILY": "GNP MAXIMUM ONE DAILY",
              "GNP MEGA MULTI FOR MEN": "GNP MEGA MULTI FOR MEN",
              "GNP MEGA MULTI FOR WOMEN": "GNP MEGA MULTI FOR WOMEN",
              "GNP ONE DAILY MENS 50+ADVANCED": "GNP ONE DAILY MENS 50+ADVANCED",
              "GNP ONE DAILY MENS/LYCOPENE": "GNP ONE DAILY MENS/LYCOPENE",
              "GNP ONE DAILY WOMENS": "GNP ONE DAILY WOMENS",
              "GNP ONE DAILY WOMENS 50+": "GNP ONE DAILY WOMENS 50+",
              "GNP OPTI-VITAMINS": "GNP OPTI-VITAMINS",
              "GNP THERAPEUTIC-M": "GNP THERAPEUTIC-M",
              "GNP WOMENS ONE DAILY": "GNP WOMENS ONE DAILY"
            },
            "BIO-TECH": {
              "C-BUFF": "C-BUFF",
              "QUENCH": "QUENCH",
              "VITA-MIN": "VITA-MIN"
            },
            "BIONEURIX": {
              "AMORYN MOOD BOOSTER": "AMORYN MOOD BOOSTER"
            },
            "BRECKENRIDGE": {
              "ELLIS TONIC": "ELLIS TONIC",
              "V-C FORTE": "V-C FORTE"
            },
            "CALLION PHARMA": {
              "DEKAS BARIATRIC": "DEKAS BARIATRIC",
              "DEKAS PLUS": "DEKAS PLUS"
            },
            "CAPELLON PHARMACEUTICALS": {
              "REQ 49+": "REQ 49+"
            },
            "CHAIN DRUG CONSORTIUM": {
              "ANTIOXIDANT VITAMIN/MINERAL": "ANTIOXIDANT VITAMIN/MINERAL",
              "DAILY MULTIPLE VITAMINS/MIN": "DAILY MULTIPLE VITAMINS/MIN",
              "DAILY MULTIPLE WEIGHT LOSS": "DAILY MULTIPLE WEIGHT LOSS",
              "EYE SUPPORT": "EYE SUPPORT",
              "EYE-VITE EXTRA": "EYE-VITE EXTRA",
              "EYE-VITE EXTRA PLUS LUTEIN": "EYE-VITE EXTRA PLUS LUTEIN",
              "EYE-VITE PLUS LUTEIN": "EYE-VITE PLUS LUTEIN",
              "HAIR/SKIN/NAILS": "HAIR/SKIN/NAILS",
              "MEGA MULTI MEN": "MEGA MULTI MEN",
              "MEGA MULTI WOMEN": "MEGA MULTI WOMEN",
              "MEMORY VITE": "MEMORY VITE",
              "MENS MULTIPLE VITAMIN/LYCOPENE": "MENS MULTIPLE VITAMIN/LYCOPENE",
              "MENS MULTIVITAMIN PLUS": "MENS MULTIVITAMIN PLUS",
              "MULTIPLE VITAMINS/WOMENS": "MULTIPLE VITAMINS/WOMENS",
              "MULTIVITAL": "MULTIVITAL",
              "MULTIVITAL PERFORMANCE": "MULTIVITAL PERFORMANCE",
              "MULTIVITAL PLATINUM": "MULTIVITAL PLATINUM",
              "MULTIVITAL PLATINUM SILVER": "MULTIVITAL PLATINUM SILVER",
              "MULTIVITAL-M": "MULTIVITAL-M",
              "MULTIVITAMIN GUMMIES ADULTS": "MULTIVITAMIN GUMMIES ADULTS",
              "SENIOR MULTIVITAMIN PLUS": "SENIOR MULTIVITAMIN PLUS",
              "STRESS 500 B-COMPLEX/ZINC": "STRESS 500 B-COMPLEX/ZINC",
              "SUPER ANTIOXIDANT": "SUPER ANTIOXIDANT",
              "WOMENS MULTIVITAMIN PLUS": "WOMENS MULTIVITAMIN PLUS"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC DAILY MULTIVIT/MULTIMINERAL": "QC DAILY MULTIVIT/MULTIMINERAL",
              "QC HAIR SKIN & NAILS": "QC HAIR SKIN & NAILS",
              "QC MENS DAILY MULTIVITAMIN": "QC MENS DAILY MULTIVITAMIN",
              "QC MULTI-VITE": "QC MULTI-VITE",
              "QC MULTI-VITE 50 & OVER": "QC MULTI-VITE 50 & OVER",
              "QC MULTI-VITE PLUS": "QC MULTI-VITE PLUS",
              "QC OCUHEALTH VISION SUPPORT 2": "QC OCUHEALTH VISION SUPPORT 2",
              "QC THERIN-M": "QC THERIN-M",
              "QC WOMENS DAILY MULTIVITAMIN": "QC WOMENS DAILY MULTIVITAMIN"
            },
            "CHRONOHEALTH": {
              "BASIC AM": "BASIC AM",
              "BASIC PM": "BASIC PM"
            },
            "CONTRACT PHARMACAL CORPORATION": {
              "B-PLEX PLUS": "B-PLEX PLUS",
              "THERAPEUTIC FORMULA/HEMATINICS": "THERAPEUTIC FORMULA/HEMATINICS"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS ADULT 50+ EYE HEALTH": "CVS ADULT 50+ EYE HEALTH",
              "CVS AIRSHIELD": "CVS AIRSHIELD",
              "CVS AIRSHIELD IMMUNITY SUPPORT": "CVS AIRSHIELD IMMUNITY SUPPORT",
              "CVS DAILY GUMMIES": "CVS DAILY GUMMIES",
              "CVS DAILY MULTIPLE FE/CA/ZN": "CVS DAILY MULTIPLE FE/CA/ZN",
              "CVS DAILY MULTIPLE FOR MEN": "CVS DAILY MULTIPLE FOR MEN",
              "CVS DAILY MULTIPLE FOR MEN 50+": "CVS DAILY MULTIPLE FOR MEN 50+",
              "CVS DAILY MULTIPLE FOR WOMEN": "CVS DAILY MULTIPLE FOR WOMEN",
              "CVS DAILY MULTIPLE WOMEN 50+": "CVS DAILY MULTIPLE WOMEN 50+",
              "CVS DIABETES HEALTH SUPPORT": "CVS DIABETES HEALTH SUPPORT",
              "CVS EYE HEALTH & LUTEIN": "CVS EYE HEALTH & LUTEIN",
              "CVS EYE HEALTH ADULT 50+": "CVS EYE HEALTH ADULT 50+",
              "CVS IMMUNE SUPPORT VITAMIN C": "CVS IMMUNE SUPPORT VITAMIN C",
              "CVS MENS DAILY GUMMIES": "CVS MENS DAILY GUMMIES",
              "CVS ONE DAILY ESSENTIAL": "CVS ONE DAILY ESSENTIAL",
              "CVS ONE DAILY MENS 50+ ADV": "CVS ONE DAILY MENS 50+ ADV",
              "CVS ONE DAILY WOMENS FORMULA": "CVS ONE DAILY WOMENS FORMULA",
              "CVS SPECTRAVITE ADULT 50+": "CVS SPECTRAVITE ADULT 50+",
              "CVS SPECTRAVITE ADULT GUMMIES": "CVS SPECTRAVITE ADULT GUMMIES",
              "CVS SPECTRAVITE ADVANCED": "CVS SPECTRAVITE ADVANCED",
              "CVS SPECTRAVITE SENIOR": "CVS SPECTRAVITE SENIOR",
              "CVS SPECTRAVITE ULTRA MEN 50+": "CVS SPECTRAVITE ULTRA MEN 50+",
              "CVS SPECTRAVITE ULTRA MENS": "CVS SPECTRAVITE ULTRA MENS",
              "CVS SPECTRAVITE ULTRA WOMEN": "CVS SPECTRAVITE ULTRA WOMEN",
              "CVS SPECTRAVITE WOMENS SENIOR": "CVS SPECTRAVITE WOMENS SENIOR",
              "CVS VISION FORMULA": "CVS VISION FORMULA",
              "CVS VISION HEALTH": "CVS VISION HEALTH",
              "CVS WOMENS ACTIVE DAILY": "CVS WOMENS ACTIVE DAILY",
              "CVS WOMENS DAILY GUMMIES": "CVS WOMENS DAILY GUMMIES",
              "IMMUNE SUPPORT VITAMIN C": "IMMUNE SUPPORT VITAMIN C",
              "SPECTRAVITE": "SPECTRAVITE"
            },
            "CYPRESS PHARMACEUTICAL": {
              "CENTAVITE": "CENTAVITE"
            },
            "ELORAC": {
              "NICAZEL": "NICAZEL",
              "NICAZEL FORTE": "NICAZEL FORTE"
            },
            "ENDURANCE PRODUCTS": {
              "ENDUR-VM": "ENDUR-VM",
              "ENDUR-VM WITH IRON": "ENDUR-VM WITH IRON"
            },
            "ENZYMATIC THERAPY": {
              "DOCTORS CHOICE MEN": "DOCTORS CHOICE MEN",
              "OSTEOPRIME PLUS": "OSTEOPRIME PLUS"
            },
            "EQUALINE": {
              "EQL AIR PROTECTOR": "EQL AIR PROTECTOR",
              "EQL CENTRAL-VITE": "EQL CENTRAL-VITE",
              "EQL CENTRAL-VITE PERFORMANCE": "EQL CENTRAL-VITE PERFORMANCE",
              "EQL CENTRAL-VITE SELECT": "EQL CENTRAL-VITE SELECT",
              "EQL CENTURY": "EQL CENTURY",
              "EQL CENTURY CARDIO HEALTH": "EQL CENTURY CARDIO HEALTH",
              "EQL CENTURY MATURE": "EQL CENTURY MATURE",
              "EQL CENTURY MATURE ADULTS 50+": "EQL CENTURY MATURE ADULTS 50+",
              "EQL CENTURY MATURE MEN 50+": "EQL CENTURY MATURE MEN 50+",
              "EQL CENTURY MATURE WOMEN 50+": "EQL CENTURY MATURE WOMEN 50+",
              "EQL CENTURY MENS": "EQL CENTURY MENS",
              "EQL CENTURY WOMENS": "EQL CENTURY WOMENS",
              "EQL GUMMY ADULT": "EQL GUMMY ADULT",
              "EQL MEGA SELECT MENS": "EQL MEGA SELECT MENS",
              "EQL MEGA SELECT WOMENS": "EQL MEGA SELECT WOMENS",
              "EQL ONE DAILY 50 PLUS": "EQL ONE DAILY 50 PLUS",
              "EQL ONE DAILY ADULT GUMMIES": "EQL ONE DAILY ADULT GUMMIES",
              "EQL ONE DAILY DIET SUPPORT": "EQL ONE DAILY DIET SUPPORT",
              "EQL ONE DAILY DIETERS SUPPORT": "EQL ONE DAILY DIETERS SUPPORT",
              "EQL ONE DAILY ENERGY": "EQL ONE DAILY ENERGY",
              "EQL ONE DAILY MAXIMUM": "EQL ONE DAILY MAXIMUM",
              "EQL ONE DAILY MENS": "EQL ONE DAILY MENS",
              "EQL ONE DAILY MENS 50+ ADVANCE": "EQL ONE DAILY MENS 50+ ADVANCE",
              "EQL ONE DAILY MENS HEALTH": "EQL ONE DAILY MENS HEALTH",
              "EQL ONE DAILY WOMENS 50+ ADV": "EQL ONE DAILY WOMENS 50+ ADV",
              "EQL PROTECTAVISION": "EQL PROTECTAVISION",
              "EQL SUPER ENERGY BOOSTER": "EQL SUPER ENERGY BOOSTER",
              "EQL ULTIMATE MENS CENTURY": "EQL ULTIMATE MENS CENTURY",
              "EQL ULTIMATE WOMENS CENTURY": "EQL ULTIMATE WOMENS CENTURY",
              "EQL VISION FORMULA": "EQL VISION FORMULA"
            },
            "EXELTIS USA": {
              "EVOLUTION60": "EVOLUTION60",
              "STROVITE FORTE": "STROVITE FORTE",
              "STROVITE ONE": "STROVITE ONE"
            },
            "EYESCIENCE LABS": {
              "DRY EYE FORMULA": "DRY EYE FORMULA",
              "MACULAR HEALTH FORMULA": "MACULAR HEALTH FORMULA"
            },
            "FOUNDATION CARE PHARMACY": {
              "CHOICEFUL MULTIVITAMIN": "CHOICEFUL MULTIVITAMIN"
            },
            "FREEDA VITAMINS": {
              "FREEDAVITE": "FREEDAVITE",
              "GERI-FREEDA SENIOR FORMULA": "GERI-FREEDA SENIOR FORMULA",
              "HI-KOVITE 2-PART FORMULA": "HI-KOVITE 2-PART FORMULA",
              "MULTI-VITAMIN MONOCAPS": "MULTI-VITAMIN MONOCAPS",
              "PARVLEX": "PARVLEX",
              "QUIN B STRONG": "QUIN B STRONG",
              "QUINTABS-M": "QUINTABS-M",
              "T-VITES": "T-VITES",
              "ULTRA FREEDA": "ULTRA FREEDA",
              "ULTRA FREEDA/IRON": "ULTRA FREEDA/IRON",
              "VITAMINS A-D-E/SELENIUM": "VITAMINS A-D-E/SELENIUM",
              "YELETS TEENAGE FORMULA": "YELETS TEENAGE FORMULA"
            },
            "GENDOSE PHARMACEUTICALS": {
              "CENTRAVITES 50 PLUS": "CENTRAVITES 50 PLUS",
              "CENTRAVITES ADULTS": "CENTRAVITES ADULTS"
            },
            "GENERAL NUTRITION CORP": {
              "ULTRA MEGA": "ULTRA MEGA",
              "ULTRA MEGA GOLD": "ULTRA MEGA GOLD",
              "ULTRA MEGA TWO": "ULTRA MEGA TWO"
            },
            "GERI-CARE": {
              "MULTIVITAMINS/MINERALS ADULT": "MULTIVITAMINS/MINERALS ADULT",
              "OCULAR VITAMINS": "OCULAR VITAMINS",
              "ONE DAILY PLUS MINERALS": "ONE DAILY PLUS MINERALS",
              "ONE-DAILY MULTI-VIT/MINERAL": "ONE-DAILY MULTI-VIT/MINERAL",
              "SENIOR TABS": "SENIOR TABS",
              "THERA-TABS M": "THERA-TABS M"
            },
            "GIL PHARMACEUTICAL CORP.": {
              "BIOTECT PLUS": "BIOTECT PLUS",
              "PROTECT CARDIO AF": "PROTECT CARDIO AF",
              "PROTECT PLUS": "PROTECT PLUS",
              "PROTECT PLUS NF": "PROTECT PLUS NF",
              "PROTECT PLUS SO": "PROTECT PLUS SO"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "CENTRUM": "CENTRUM",
              "CENTRUM ADULTS": "CENTRUM ADULTS",
              "CENTRUM CARDIO": "CENTRUM CARDIO",
              "CENTRUM FLAVOR BURST": "CENTRUM FLAVOR BURST",
              "CENTRUM FLAVOR BURST ADULT": "CENTRUM FLAVOR BURST ADULT",
              "CENTRUM FLAVOR BURST DRINK": "CENTRUM FLAVOR BURST DRINK",
              "CENTRUM FRESH/FRUITY 50+": "CENTRUM FRESH/FRUITY 50+",
              "CENTRUM MEN": "CENTRUM MEN",
              "CENTRUM MULTI + OMEGA 3": "CENTRUM MULTI + OMEGA 3",
              "CENTRUM MULTIGUMMIES": "CENTRUM MULTIGUMMIES",
              "CENTRUM SILVER": "CENTRUM SILVER",
              "CENTRUM SILVER 50+MEN": "CENTRUM SILVER 50+MEN",
              "CENTRUM SILVER 50+WOMEN": "CENTRUM SILVER 50+WOMEN",
              "CENTRUM SILVER ADULT 50+": "CENTRUM SILVER ADULT 50+",
              "CENTRUM SILVER ULTRA WOMENS": "CENTRUM SILVER ULTRA WOMENS",
              "CENTRUM SPECIALIST HEART": "CENTRUM SPECIALIST HEART",
              "CENTRUM SPECIALIST IMMUNE": "CENTRUM SPECIALIST IMMUNE",
              "CENTRUM SPECIALIST VISION": "CENTRUM SPECIALIST VISION",
              "CENTRUM ULTRA WOMENS": "CENTRUM ULTRA WOMENS",
              "CENTRUM VITAMINTS": "CENTRUM VITAMINTS",
              "CENTRUM WOMEN": "CENTRUM WOMEN",
              "EMERGEN-C BLUE": "EMERGEN-C BLUE",
              "EMERGEN-C FIVE": "EMERGEN-C FIVE",
              "EMERGEN-C HEART HEALTH": "EMERGEN-C HEART HEALTH",
              "EMERGEN-C IMMUNE": "EMERGEN-C IMMUNE",
              "EMERGEN-C IMMUNE PLUS": "EMERGEN-C IMMUNE PLUS",
              "EMERGEN-C IMMUNE PLUS/VIT D": "EMERGEN-C IMMUNE PLUS/VIT D",
              "EMERGEN-C IMMUNE+WARMERS": "EMERGEN-C IMMUNE+WARMERS",
              "EMERGEN-C JOINT HEALTH": "EMERGEN-C JOINT HEALTH",
              "EMERGEN-C KIDZ": "EMERGEN-C KIDZ",
              "EMERGEN-C MSM LITE": "EMERGEN-C MSM LITE",
              "EMERGEN-C PINK": "EMERGEN-C PINK",
              "EMERGEN-C SUPER FRUIT": "EMERGEN-C SUPER FRUIT",
              "EMERGEN-C VITAMIN C": "EMERGEN-C VITAMIN C",
              "EMERGEN-C VITAMIN C LITE": "EMERGEN-C VITAMIN C LITE",
              "EMERGEN-C VITAMIN D/CALCIUM": "EMERGEN-C VITAMIN D/CALCIUM"
            },
            "GLAXO SMITH KLINE": {
              "DERMAVITE": "DERMAVITE"
            },
            "GOOD THINGS HEALTH": {
              "DAILY MULTIVITAMIN": "DAILY MULTIVITAMIN"
            },
            "H2 PHARMA": {
              "ABDEK": "ABDEK"
            },
            "HILLESTAD PHARMACEUTICALS": {
              "DIALYVITE 800/ULTRA D": "DIALYVITE 800/ULTRA D"
            },
            "I-HEALTH": {
              "CULTURELLE PROBIOTICS + MULTIV": "CULTURELLE PROBIOTICS + MULTIV"
            },
            "INNOVATIVE APOTHECARY SOLUTION": {
              "MULTIPRO": "MULTIPRO"
            },
            "INNOVIDA PHARMACEUTIQUE": {
              "FOLIKA-CI": "FOLIKA-CI",
              "FOLIKA-MG": "FOLIKA-MG"
            },
            "INTEGRATIVE THERAPEUTICS": {
              "CLINICAL NUTRIENTS 45-PLUS WMN": "CLINICAL NUTRIENTS 45-PLUS WMN",
              "CLINICAL NUTRIENTS 50-PLUS MEN": "CLINICAL NUTRIENTS 50-PLUS MEN",
              "CLINICAL NUTRIENTS ANTIOXIDANT": "CLINICAL NUTRIENTS ANTIOXIDANT",
              "CLINICAL NUTRIENTS FEMALE TEEN": "CLINICAL NUTRIENTS FEMALE TEEN",
              "CLINICAL NUTRIENTS FOR MEN": "CLINICAL NUTRIENTS FOR MEN",
              "CLINICAL NUTRIENTS FOR WOMEN": "CLINICAL NUTRIENTS FOR WOMEN",
              "CLINICAL NUTRIENTS MALE TEEN": "CLINICAL NUTRIENTS MALE TEEN",
              "DAILY ENERGY ENFUSION": "DAILY ENERGY ENFUSION",
              "MEGA MULTIVITAMIN": "MEGA MULTIVITAMIN",
              "OSTEOPRIME ULTRA": "OSTEOPRIME ULTRA",
              "VITALINE TOTAL FORMULA 2": "VITALINE TOTAL FORMULA 2",
              "VITALINE TOTAL FORMULA 3": "VITALINE TOTAL FORMULA 3"
            },
            "INTERNATIONAL VITAMIN CORP": {
              "PROTEGRA": "PROTEGRA",
              "STRESSTABS ADVANCED": "STRESSTABS ADVANCED"
            },
            "K-PAX  VITAMINS": {
              "K-PAX DOUBLE STRENGTH": "K-PAX DOUBLE STRENGTH",
              "K-PAX IMMUNE PROFESSIONAL ST": "K-PAX IMMUNE PROFESSIONAL ST",
              "K-PAX SINGLE STRENGTH": "K-PAX SINGLE STRENGTH"
            },
            "KAISER FOUNDATION HOSPITAL": {
              "KP ADULTS 50+ DAILY FORMULA": "KP ADULTS 50+ DAILY FORMULA",
              "KP ADULTS DAILY FORMULA": "KP ADULTS DAILY FORMULA",
              "KP MENS 50+ DAILY FORMULA": "KP MENS 50+ DAILY FORMULA",
              "KP MENS DAILY FORMULA": "KP MENS DAILY FORMULA",
              "KP MENS DAILY PACK": "KP MENS DAILY PACK",
              "KP VISION FORMULA": "KP VISION FORMULA",
              "KP VISION FORMULA/LUTEIN": "KP VISION FORMULA/LUTEIN",
              "KP WOMENS 50+ DAILY FORMULA": "KP WOMENS 50+ DAILY FORMULA",
              "KP WOMENS DAILY": "KP WOMENS DAILY",
              "KP WOMENS DAILY FORMULA": "KP WOMENS DAILY FORMULA"
            },
            "KIRKMAN SALES COMPANY": {
              "SUPER NU-THERA": "SUPER NU-THERA"
            },
            "KRAMER-NOVIS": {
              "LYSIPLEX PLUS": "LYSIPLEX PLUS",
              "PROVIT": "PROVIT"
            },
            "LEADER BRAND PRODUCTS": {
              "EYE HEALTH + LUTEIN": "EYE HEALTH + LUTEIN",
              "HAIR/SKIN/NAILS": "HAIR/SKIN/NAILS",
              "HEALTHY EYES": "HEALTHY EYES",
              "HEALTHY EYES/LUTEIN": "HEALTHY EYES/LUTEIN",
              "IMMUNE SUPPORT": "IMMUNE SUPPORT",
              "MEGA MULTIVITAMIN FOR MEN": "MEGA MULTIVITAMIN FOR MEN",
              "MEGA MULTIVITAMIN FOR WOMEN": "MEGA MULTIVITAMIN FOR WOMEN",
              "MENS MULTI VITAMIN & MINERAL": "MENS MULTI VITAMIN & MINERAL",
              "MENS MULTIVITAMIN": "MENS MULTIVITAMIN",
              "MULTIVITAMIN ADULT": "MULTIVITAMIN ADULT",
              "MULTIVITAMIN ADULT EXTRA C": "MULTIVITAMIN ADULT EXTRA C",
              "MULTIVITAMIN ADULTS": "MULTIVITAMIN ADULTS",
              "MULTIVITAMIN ADULTS 50+": "MULTIVITAMIN ADULTS 50+",
              "MULTIVITAMIN MEN": "MULTIVITAMIN MEN",
              "MULTIVITAMIN MEN 50+": "MULTIVITAMIN MEN 50+",
              "MULTIVITAMIN WOMEN": "MULTIVITAMIN WOMEN",
              "MULTIVITAMIN WOMEN 50+": "MULTIVITAMIN WOMEN 50+",
              "MULTIVITAMIN WOMENS 50+ ADV": "MULTIVITAMIN WOMENS 50+ ADV",
              "MULTIVITAMIN/EXTRA VITAMIN D3": "MULTIVITAMIN/EXTRA VITAMIN D3",
              "ONE DAILY MENS": "ONE DAILY MENS",
              "ONE DAILY MULTIVITAMIN ADULT": "ONE DAILY MULTIVITAMIN ADULT",
              "ONE DAILY PLUS IRON": "ONE DAILY PLUS IRON",
              "SENTRY": "SENTRY",
              "SENTRY ADULT": "SENTRY ADULT",
              "SENTRY SENIOR": "SENTRY SENIOR",
              "THERAPEUTIC M": "THERAPEUTIC M",
              "THERAPEUTIC-M": "THERAPEUTIC-M",
              "VISION FORMULA 2": "VISION FORMULA 2",
              "VISION FORMULA EYE HEALTH": "VISION FORMULA EYE HEALTH",
              "WOMENS MULTIVITAMIN": "WOMENS MULTIVITAMIN",
              "WOMENS ONE DAILY": "WOMENS ONE DAILY"
            },
            "LIBERTY PHARMACEUTICAL": {
              "THERAPEUTIC MULTIVIT/MINERAL": "THERAPEUTIC MULTIVIT/MINERAL"
            },
            "LIFE LINE FOODS": {
              "BURIED TREASURE ACTIVE 55 PLUS": "BURIED TREASURE ACTIVE 55 PLUS"
            },
            "LLORENS PHARMACEUTICAL": {
              "GLUCOTEN": "GLUCOTEN",
              "NUTRICAP": "NUTRICAP"
            },
            "MAGNO-HUMPHRIES LABORATORIES": {
              "CENTRAVITES": "CENTRAVITES",
              "CENTRAVITES 50 PLUS": "CENTRAVITES 50 PLUS",
              "DAILY BETIC": "DAILY BETIC",
              "EYE-VITES": "EYE-VITES",
              "GERIVITE COMPLETE": "GERIVITE COMPLETE",
              "MEGA-MARATHON 100 TR": "MEGA-MARATHON 100 TR",
              "MYAMULTI": "MYAMULTI",
              "ONE DAILY CALCIUM/IRON": "ONE DAILY CALCIUM/IRON",
              "ONE DAILY COMPLETE": "ONE DAILY COMPLETE",
              "ONE DAILY COMPLETE FOR MEN": "ONE DAILY COMPLETE FOR MEN",
              "ONE DAILY MEN FORMULA W/O IRON": "ONE DAILY MEN FORMULA W/O IRON",
              "ONE DAILY/MINERALS": "ONE DAILY/MINERALS",
              "OPTIC-VITES": "OPTIC-VITES",
              "OPTIC-VITES WITH LUTEIN": "OPTIC-VITES WITH LUTEIN",
              "OPTIMUM AIRVITES": "OPTIMUM AIRVITES",
              "OPTIMUM PMS": "OPTIMUM PMS",
              "SUPER VITA-MINS": "SUPER VITA-MINS",
              "THERA VITAL M": "THERA VITAL M",
              "THERA VITAL-M": "THERA VITAL-M",
              "VITA HAIR": "VITA HAIR",
              "ZINC": "ZINC"
            },
            "MAINPOINTE PHARMACEUTICALS": {
              "FOSFREE": "FOSFREE"
            },
            "MAJOR PHARMACEUTICALS": {
              "CERTAVITE SENIOR": "CERTAVITE SENIOR",
              "CERTAVITE SENIOR/ANTIOXIDANT": "CERTAVITE SENIOR/ANTIOXIDANT",
              "CERTAVITE/ANTIOXIDANTS": "CERTAVITE/ANTIOXIDANTS",
              "EYE MULTIVITAMIN/LUTEIN": "EYE MULTIVITAMIN/LUTEIN",
              "PROSIGHT": "PROSIGHT",
              "TAB-A-VITE MAXIMUM": "TAB-A-VITE MAXIMUM",
              "THERA M PLUS": "THERA M PLUS",
              "THERA-M": "THERA-M",
              "ULTRA-MEGA": "ULTRA-MEGA",
              "VITAMINS/MINERALS": "VITAMINS/MINERALS"
            },
            "MARLEX PHARMACEUTICALS": {
              "CENTAVITE A-Z COMPLETE-MINERAL": "CENTAVITE A-Z COMPLETE-MINERAL"
            },
            "MASON VITAMINS": {
              "BIOTIN PLUS/CALCIUM/VIT D3": "BIOTIN PLUS/CALCIUM/VIT D3",
              "BODY/HAIR/SKIN/NAILS": "BODY/HAIR/SKIN/NAILS",
              "DAILY MULTIPLE VITAMINS/MIN": "DAILY MULTIPLE VITAMINS/MIN",
              "HAIR FORMULA EXTRA STRENGTH": "HAIR FORMULA EXTRA STRENGTH",
              "HAIR VITAMINS": "HAIR VITAMINS",
              "LUTEIN-ZEAXANTHIN": "LUTEIN-ZEAXANTHIN",
              "MEGAVITE FRUITS & VEGGIES": "MEGAVITE FRUITS & VEGGIES",
              "MEGAVITE GOLDEN YEARS 55+": "MEGAVITE GOLDEN YEARS 55+",
              "MENS DAILY FORMULA/LYCOPENE": "MENS DAILY FORMULA/LYCOPENE",
              "MULTIVITAMIN & MINERAL": "MULTIVITAMIN & MINERAL",
              "SAVISION": "SAVISION",
              "SUPER MULTIPLE": "SUPER MULTIPLE",
              "SUPERIOR 35": "SUPERIOR 35",
              "THERADEX M": "THERADEX M",
              "THERADEX M/BETA CAROTENE": "THERADEX M/BETA CAROTENE",
              "ULTRA ANTIOXIDANT FORMULA": "ULTRA ANTIOXIDANT FORMULA",
              "VISION VITAMINS": "VISION VITAMINS",
              "VITAMIN D3 COMPLETE": "VITAMIN D3 COMPLETE",
              "VITATRUM": "VITATRUM",
              "VITATRUM COMPLETE": "VITATRUM COMPLETE",
              "VITRUM 50+ ADULT-MULTI": "VITRUM 50+ ADULT-MULTI",
              "VITRUM 50+ SENIOR MULTI": "VITRUM 50+ SENIOR MULTI",
              "VITRUM SENIOR": "VITRUM SENIOR",
              "WOMENS DAILY FORM/FA/CA/FE": "WOMENS DAILY FORM/FA/CA/FE",
              "WOMENS DAILY FORMULA": "WOMENS DAILY FORMULA"
            },
            "MAYNE PHARMA": {
              "MULTIVITAMINS": "MULTIVITAMINS",
              "MULTIVITAMINS PLUS ZINC": "MULTIVITAMINS PLUS ZINC"
            },
            "MCKESSON": {
              "HM ANTIOXIDANT VITAMINS": "HM ANTIOXIDANT VITAMINS",
              "HM COMPLETE": "HM COMPLETE",
              "HM COMPLETE 50+": "HM COMPLETE 50+",
              "HM COMPLETE 50+ MENS ULTIMATE": "HM COMPLETE 50+ MENS ULTIMATE",
              "HM COMPLETE 50+ WOMEN ULTIMATE": "HM COMPLETE 50+ WOMEN ULTIMATE",
              "HM COMPLETE MEN": "HM COMPLETE MEN",
              "HM COMPLETE WOMEN": "HM COMPLETE WOMEN",
              "HM HAIR/SKIN/NAILS": "HM HAIR/SKIN/NAILS",
              "HM MENS 50+ ADVANCED ONE DAILY": "HM MENS 50+ ADVANCED ONE DAILY",
              "HM MULTIVITAMIN ADULT GUMMY": "HM MULTIVITAMIN ADULT GUMMY",
              "HM ONE DAILY MENS": "HM ONE DAILY MENS",
              "HM ONE DAILY WOMENS": "HM ONE DAILY WOMENS",
              "HM WOMENS 50+ ADVANCED DAILY": "HM WOMENS 50+ ADVANCED DAILY"
            },
            "MCKESSON SUNMARK": {
              "SM ANTIOXIDANT VITAMINS": "SM ANTIOXIDANT VITAMINS",
              "SM COMPLETE": "SM COMPLETE",
              "SM COMPLETE 50+": "SM COMPLETE 50+",
              "SM COMPLETE 50+ ULTIMATE MENS": "SM COMPLETE 50+ ULTIMATE MENS",
              "SM COMPLETE 50+ ULTIMATE WOMEN": "SM COMPLETE 50+ ULTIMATE WOMEN",
              "SM COMPLETE ADVANCED FORMULA": "SM COMPLETE ADVANCED FORMULA",
              "SM COMPLETE SENIOR FORMULA": "SM COMPLETE SENIOR FORMULA",
              "SM DAILY DIET SUPPORT": "SM DAILY DIET SUPPORT",
              "SM HAIR/SKIN/NAILS": "SM HAIR/SKIN/NAILS",
              "SM ONE DAILY MENS": "SM ONE DAILY MENS",
              "SM ONE DAILY WOMENS": "SM ONE DAILY WOMENS",
              "SM OPTI-VITAMINS": "SM OPTI-VITAMINS"
            },
            "MDR FITNESS": {
              "FITNESS TABS FOR MEN AM/PM": "FITNESS TABS FOR MEN AM/PM",
              "FITNESS TABS FOR WOMEN AM/PM": "FITNESS TABS FOR WOMEN AM/PM"
            },
            "ME PHARMACEUTICALS": {
              "MY-VITALIFE": "MY-VITALIFE"
            },
            "MEDICINE SHOPPE": {
              "ANTIOXIDANT VITAMINS": "ANTIOXIDANT VITAMINS",
              "CENTURY": "CENTURY",
              "CENTURY MATURE": "CENTURY MATURE",
              "MULTIPLE VITAMINS/WOMENS": "MULTIPLE VITAMINS/WOMENS"
            },
            "MEDIMETRIKS PHARMACEUTICALS": {
              "NICADAN": "NICADAN"
            },
            "MEDINICHE": {
              "ORTHOVITE": "ORTHOVITE"
            },
            "MEDLINE/DERMAL MANAGEMENT": {
              "CARRAVITE": "CARRAVITE"
            },
            "MEDTRITION NATIONAL NUTRITION": {
              "VITAMENT": "VITAMENT"
            },
            "MEIJER": {
              "MEIJER ADVANCED FORMULA": "MEIJER ADVANCED FORMULA"
            },
            "MERIT": {
              "ECOLOVIT": "ECOLOVIT"
            },
            "METAGENICS": {
              "ADVANCED MULTI EA": "ADVANCED MULTI EA",
              "PHYTOMULTI": "PHYTOMULTI",
              "ULTRA MULTI FORMULA/IRON": "ULTRA MULTI FORMULA/IRON"
            },
            "MILLER": {
              "ANTIOXIDANT FORTE": "ANTIOXIDANT FORTE",
              "COMPLERE": "COMPLERE",
              "HYALEX": "HYALEX",
              "M2 B-60": "M2 B-60",
              "RAGUS": "RAGUS",
              "SCLEREX": "SCLEREX",
              "THERAMILL FORTE": "THERAMILL FORTE",
              "THERAMILL PLUS": "THERAMILL PLUS"
            },
            "MISSION": {
              "COMPETE": "COMPETE",
              "ONCOVITE": "ONCOVITE"
            },
            "MVW NUTRITIONALS": {
              "GENADEK STEP 1": "GENADEK STEP 1",
              "GENADEK STEP 2": "GENADEK STEP 2",
              "MVW COMPLETE FORMULATION": "MVW COMPLETE FORMULATION",
              "MVW COMPLETE FORMULATION D3000": "MVW COMPLETE FORMULATION D3000",
              "MVW COMPLETE FORMULATION D5000": "MVW COMPLETE FORMULATION D5000",
              "MVW COMPLETE FORMULATION MINIS": "MVW COMPLETE FORMULATION MINIS"
            },
            "NAT-RUL HEALTH PRODUCTS": {
              "HAIR-VITES": "HAIR-VITES",
              "NAT-RUL THERAVITE-M": "NAT-RUL THERAVITE-M",
              "NATRUL-100": "NATRUL-100",
              "NATRUL-CHEWS": "NATRUL-CHEWS",
              "NATRUL-MEGA-75": "NATRUL-MEGA-75",
              "NATRUL-VITES": "NATRUL-VITES",
              "OPTI-WOMAN": "OPTI-WOMAN",
              "SENIOR VITES": "SENIOR VITES",
              "SENTRY SENIOR/LUTEIN": "SENTRY SENIOR/LUTEIN",
              "SUPER NATRUL-100": "SUPER NATRUL-100",
              "SUPERB NAILS": "SUPERB NAILS",
              "SURE GUARD ANTI-OXIDANT PLUS": "SURE GUARD ANTI-OXIDANT PLUS",
              "SURE GUARD MULTI VIT/MINERAL": "SURE GUARD MULTI VIT/MINERAL"
            },
            "NATIONAL VITAMIN": {
              "ANTIOXIDANT": "ANTIOXIDANT",
              "ANTIOXIDANT A/C/E/SELENIUM": "ANTIOXIDANT A/C/E/SELENIUM",
              "LIFE PACK MENS": "LIFE PACK MENS",
              "LIFE PACK WOMENS": "LIFE PACK WOMENS",
              "MACUVITE": "MACUVITE",
              "MACUVITE EYE CARE": "MACUVITE EYE CARE",
              "MACUVITE/LUTEIN": "MACUVITE/LUTEIN",
              "MAXIMUM DAILY GREEN": "MAXIMUM DAILY GREEN",
              "MENS LIFE PACK": "MENS LIFE PACK",
              "MULTI VITAMIN/MINERALS": "MULTI VITAMIN/MINERALS",
              "MULTI-VITAMIN/MINERALS": "MULTI-VITAMIN/MINERALS",
              "MULTIPLE VIT/MINERALS/NO IRON": "MULTIPLE VIT/MINERALS/NO IRON",
              "NO IRON MULT VITAMIN-MINERALS": "NO IRON MULT VITAMIN-MINERALS",
              "PREMIUM PACKETS": "PREMIUM PACKETS",
              "SUPER ANTIOXIDANT": "SUPER ANTIOXIDANT",
              "SUPER THERA VITE M": "SUPER THERA VITE M",
              "THERATRUM COMPLETE": "THERATRUM COMPLETE",
              "THERATRUM COMPLETE 50 PLUS": "THERATRUM COMPLETE 50 PLUS",
              "TOTALDAY MULTIPLE": "TOTALDAY MULTIPLE",
              "TROPICAL LIQUID NUTRITION": "TROPICAL LIQUID NUTRITION",
              "VISION PLUS": "VISION PLUS",
              "WOMENS LIFE PACK": "WOMENS LIFE PACK",
              "ZINC": "ZINC"
            },
            "NATURES BOUNTY": {
              "ABC PLUS": "ABC PLUS",
              "ABC PLUS SENIOR": "ABC PLUS SENIOR",
              "ABC PLUS SENIOR ADULTS 50+": "ABC PLUS SENIOR ADULTS 50+",
              "ANTI-OXIDANT FORMULA": "ANTI-OXIDANT FORMULA",
              "ANTIOXIN 4000": "ANTIOXIN 4000",
              "COMPLETE PMS SUPPORT COMPLEX": "COMPLETE PMS SUPPORT COMPLEX",
              "DIABETES SUPPORT": "DIABETES SUPPORT",
              "EYE-VITES": "EYE-VITES",
              "HAIR/SKIN/NAILS": "HAIR/SKIN/NAILS",
              "MEGA VM-80": "MEGA VM-80",
              "MENS HAIR FORMULA ULTRA MAN": "MENS HAIR FORMULA ULTRA MAN",
              "MULTI-DAY PLUS MINERALS": "MULTI-DAY PLUS MINERALS",
              "MULTI-DAY WEIGHT TRIM": "MULTI-DAY WEIGHT TRIM",
              "PRESCRIPTIVE FORMULAS MENS": "PRESCRIPTIVE FORMULAS MENS",
              "PRESCRIPTIVE FORMULAS WOMENS": "PRESCRIPTIVE FORMULAS WOMENS",
              "STRESS B-COMPLEX/C/ZINC": "STRESS B-COMPLEX/C/ZINC",
              "THERAVIM-M": "THERAVIM-M",
              "ULTRA VITA-TIME": "ULTRA VITA-TIME",
              "YOUR LIFE MULTI ADULT GUMMIES": "YOUR LIFE MULTI ADULT GUMMIES",
              "YOUR LIFE MULTI MENS 50+": "YOUR LIFE MULTI MENS 50+",
              "YOUR LIFE MULTI WOMENS 50+": "YOUR LIFE MULTI WOMENS 50+",
              "YOUR LIFE TEEN MULTI GUMMIES": "YOUR LIFE TEEN MULTI GUMMIES"
            },
            "NEOMED PHARMACEUTICAL": {
              "NEOVITE": "NEOVITE"
            },
            "NEPHRO-TECH": {
              "RENAPLEX": "RENAPLEX",
              "RENAPLEX-D": "RENAPLEX-D"
            },
            "NESTLE HEALTHCARE NUTRITION": {
              "OPTISOURCE POST BARIATRIC SURG": "OPTISOURCE POST BARIATRIC SURG"
            },
            "NNODUM CORPORATION": {
              "VIC-FORTE": "VIC-FORTE",
              "VITAROCA PLUS": "VITAROCA PLUS"
            },
            "NUTRICIA NORTH AMERICA": {
              "PHLEXY-VITS": "PHLEXY-VITS"
            },
            "OPTIMOX": {
              "OPTIVITE P.M.T.": "OPTIVITE P.M.T."
            },
            "PEGASUS": {
              "RENAL": "RENAL"
            },
            "PHARMADERM": {
              "APATATE FORTE": "APATATE FORTE"
            },
            "PHARMASSURE, INC.": {
              "WOMENS BIOMULTIPLE": "WOMENS BIOMULTIPLE"
            },
            "PHARMATON": {
              "VITASANA": "VITASANA"
            },
            "PHARMAVITE": {
              "DAILY COMBO MULTI VITAMINS": "DAILY COMBO MULTI VITAMINS",
              "DIABETES HEALTH": "DIABETES HEALTH",
              "ESSENTIAL BALANCE": "ESSENTIAL BALANCE",
              "HAIR/SKIN/NAILS": "HAIR/SKIN/NAILS",
              "MAXIMIN PACK": "MAXIMIN PACK",
              "MENS PACK": "MENS PACK",
              "MULTI + OMEGA-3 ADULT GUMMIES": "MULTI + OMEGA-3 ADULT GUMMIES",
              "MULTI ADULT GUMMIES": "MULTI ADULT GUMMIES",
              "MULTI COMPLETE": "MULTI COMPLETE",
              "MULTI COMPLETE/IRON": "MULTI COMPLETE/IRON",
              "MULTI FOR HER": "MULTI FOR HER",
              "MULTI FOR HER 50+": "MULTI FOR HER 50+",
              "MULTI FOR HIM": "MULTI FOR HIM",
              "MULTI FOR HIM 50+": "MULTI FOR HIM 50+",
              "SKIN BEAUTY & WELLNESS": "SKIN BEAUTY & WELLNESS",
              "WOMENS PACK": "WOMENS PACK"
            },
            "PHYSICIAN RECOMMENDED NUTR": {
              "MACULAR VITAMIN BENEFIT": "MACULAR VITAMIN BENEFIT",
              "MH MACULAR HEALTH": "MH MACULAR HEALTH"
            },
            "PLUS PHARMA": {
              "CERTA PLUS": "CERTA PLUS",
              "DAILY VITAMIN FORMULA+MINERALS": "DAILY VITAMIN FORMULA+MINERALS",
              "EYEPROTECT": "EYEPROTECT",
              "THERA-M": "THERA-M"
            },
            "PORTAL PHARMACEUTICALS": {
              "FORTAVIT": "FORTAVIT"
            },
            "PREMIER MICRONUTRIENT": {
              "CELLULAR SECURITY": "CELLULAR SECURITY"
            },
            "PRINCETON RESEARCH": {
              "ABC COMPLETE SENIOR WOMENS 50+": "ABC COMPLETE SENIOR WOMENS 50+",
              "HAIR SKIN NAILS": "HAIR SKIN NAILS"
            },
            "PRO-BIOTIKS": {
              "BIO-35 GLUTEN-FREE": "BIO-35 GLUTEN-FREE",
              "BIO-35 IRON FREE": "BIO-35 IRON FREE",
              "PRO-CAL": "PRO-CAL"
            },
            "PROCARE HEALTH": {
              "BARIATRIC MULTIVITAMINS/IRON": "BARIATRIC MULTIVITAMINS/IRON"
            },
            "PROSYNTHESIS LABORATORIES": {
              "OPURITY": "OPURITY",
              "OPURITY BYPASS OPTIMIZED": "OPURITY BYPASS OPTIMIZED"
            },
            "PUBLIX SUPER MARKETS INC.": {
              "PX ADVANCED FORMULA MULTIVITS": "PX ADVANCED FORMULA MULTIVITS",
              "PX COMPLETE SENIOR MULTIVITS": "PX COMPLETE SENIOR MULTIVITS",
              "PX MENS MULTIVITAMINS": "PX MENS MULTIVITAMINS"
            },
            "PURE TEK": {
              "VITREXYL": "VITREXYL",
              "VITREXYL + IRON": "VITREXYL + IRON"
            },
            "QCE LABORATORIES": {
              "DECUBI-VITE": "DECUBI-VITE"
            },
            "RECKITT BENCKISER": {
              "AIRBORNE": "AIRBORNE",
              "AIRBORNE GUMMIES": "AIRBORNE GUMMIES",
              "AIRBORNE KIDS": "AIRBORNE KIDS"
            },
            "REESE PHARMACEUTICAL": {
              "B-REDI/RED HEARTS/RED ROOSTERS": "B-REDI/RED HEARTS/RED ROOSTERS",
              "SUPER 28 FORMULA": "SUPER 28 FORMULA",
              "SUPER VIKAPS": "SUPER VIKAPS"
            },
            "RELIABLE 1 LABS": {
              "EYE VITAMINS & MINERALS": "EYE VITAMINS & MINERALS",
              "MULTI-VITE": "MULTI-VITE"
            },
            "REXALL SUNDOWN": {
              "ADULT GUMMY": "ADULT GUMMY",
              "COMPLETE DAILY/LUTEIN": "COMPLETE DAILY/LUTEIN",
              "COMPLETE ENERGY": "COMPLETE ENERGY",
              "COMPLETE WOMENS": "COMPLETE WOMENS",
              "DAILY MULTI": "DAILY MULTI",
              "DAILY MULTI 50+": "DAILY MULTI 50+",
              "HAIR/SKIN/NAILS": "HAIR/SKIN/NAILS",
              "MULTIVITAMIN GUMMIES ADULT": "MULTIVITAMIN GUMMIES ADULT",
              "ONE DAILY WOMENS": "ONE DAILY WOMENS",
              "SUNVITE ACTIVE ADULT 50+": "SUNVITE ACTIVE ADULT 50+",
              "SUNVITE ADVANCED": "SUNVITE ADVANCED",
              "VITAMIN C-ELECTROLYTES": "VITAMIN C-ELECTROLYTES",
              "VITAMINS TO GO MAXIMUM": "VITAMINS TO GO MAXIMUM",
              "VITAMINS TO GO MEN": "VITAMINS TO GO MEN",
              "VITAMINS TO GO WOMEN": "VITAMINS TO GO WOMEN",
              "WHOLE FOOD MULTIVITAMIN": "WHOLE FOOD MULTIVITAMIN",
              "WOMENS MULTI GUMMIES": "WOMENS MULTI GUMMIES"
            },
            "RISING PHARMACEUTICALS": {
              "NUTRIFAC ZX": "NUTRIFAC ZX",
              "VITA S FORTE": "VITA S FORTE"
            },
            "RITE AID CORPORATION": {
              "PA MENS 50 PLUS VITAPAK": "PA MENS 50 PLUS VITAPAK",
              "PA MENS VITAPAK": "PA MENS VITAPAK",
              "PA WOMENS 50 PLUS VITAPAK": "PA WOMENS 50 PLUS VITAPAK",
              "PA WOMENS VITAPAK": "PA WOMENS VITAPAK",
              "RA CENTRAL-VITE": "RA CENTRAL-VITE",
              "RA CENTRAL-VITE ENERGY": "RA CENTRAL-VITE ENERGY",
              "RA CENTRAL-VITE MENS MATURE": "RA CENTRAL-VITE MENS MATURE",
              "RA CENTRAL-VITE SELECT": "RA CENTRAL-VITE SELECT",
              "RA CENTRAL-VITE SENIOR": "RA CENTRAL-VITE SENIOR",
              "RA CENTRAL-VITE UNDER 50 MENS": "RA CENTRAL-VITE UNDER 50 MENS",
              "RA CENTRAL-VITE UNDER 50 WOMEN": "RA CENTRAL-VITE UNDER 50 WOMEN",
              "RA CENTRAL-VITE WOMENS MATURE": "RA CENTRAL-VITE WOMENS MATURE",
              "RA CENTRAL-VITE/ANTIOXIDANTS": "RA CENTRAL-VITE/ANTIOXIDANTS",
              "RA ESSENCE-C": "RA ESSENCE-C",
              "RA HAIR/SKIN/NAILS": "RA HAIR/SKIN/NAILS",
              "RA MATURE WOMENS DIETARY SUPP": "RA MATURE WOMENS DIETARY SUPP",
              "RA ONE DAILY ENERGY FORMULA": "RA ONE DAILY ENERGY FORMULA",
              "RA ONE DAILY GUMMY VITES": "RA ONE DAILY GUMMY VITES",
              "RA ONE DAILY MAXIMUM": "RA ONE DAILY MAXIMUM",
              "RA ONE DAILY MENS 50+ W/VIT D3": "RA ONE DAILY MENS 50+ W/VIT D3",
              "RA ONE DAILY MENS MULTI": "RA ONE DAILY MENS MULTI",
              "RA ONE DAILY MENS/VIT D-3": "RA ONE DAILY MENS/VIT D-3",
              "RA ONE DAILY WOMENS": "RA ONE DAILY WOMENS",
              "RA STRESS FORMULA ADVANCED": "RA STRESS FORMULA ADVANCED",
              "RA STRESS FORMULA ENERGY": "RA STRESS FORMULA ENERGY",
              "RA THERAPEUTIC M PLUS BETA CAR": "RA THERAPEUTIC M PLUS BETA CAR",
              "RA VISION VITE PLUS ZINC": "RA VISION VITE PLUS ZINC",
              "RA WHOLE SOURCE DIETARY": "RA WHOLE SOURCE DIETARY",
              "RA WHOLE SOURCE DIETARY MATURE": "RA WHOLE SOURCE DIETARY MATURE",
              "RA WHOLE SOURCE DIETARY MEN": "RA WHOLE SOURCE DIETARY MEN",
              "RA WHOLE SOURCE FOR MEN": "RA WHOLE SOURCE FOR MEN",
              "RA WHOLE SOURCE WOMENS": "RA WHOLE SOURCE WOMENS"
            },
            "RUGBY LABORATORIES": {
              "ANTIOXIDANT FORMULA": "ANTIOXIDANT FORMULA",
              "CEROVITE ADVANCED FORMULA": "CEROVITE ADVANCED FORMULA",
              "CEROVITE SENIOR": "CEROVITE SENIOR",
              "CERTAVITE/ANTIOXIDANTS": "CERTAVITE/ANTIOXIDANTS",
              "EYE MULTIVITAMIN/SODIUM": "EYE MULTIVITAMIN/SODIUM",
              "I-VITE": "I-VITE",
              "I-VITE PROTECT": "I-VITE PROTECT",
              "MULTILEX": "MULTILEX",
              "MULTILEX-T&M": "MULTILEX-T&M",
              "MULTIVITAMIN/ZINC STRESS": "MULTIVITAMIN/ZINC STRESS",
              "TAB-A-VITE/IRON/BETA CAROTENE": "TAB-A-VITE/IRON/BETA CAROTENE",
              "THEREMS-H": "THEREMS-H",
              "THEREMS-M": "THEREMS-M",
              "UNICOMPLEX-M": "UNICOMPLEX-M",
              "VITAMIN-MINERAL SUPPLEMENT": "VITAMIN-MINERAL SUPPLEMENT"
            },
            "RV NUTRITIONAL": {
              "VITABEX PLUS": "VITABEX PLUS"
            },
            "SCHWABE NORTH AMERICA": {
              "ALIVE ENERGY 50+": "ALIVE ENERGY 50+",
              "ALIVE MENS ENERGY": "ALIVE MENS ENERGY",
              "ALIVE MULTI-VITAMIN": "ALIVE MULTI-VITAMIN",
              "ALIVE ONCE DAILY WOMENS": "ALIVE ONCE DAILY WOMENS",
              "ALIVE ONCE DAILY WOMENS 50+": "ALIVE ONCE DAILY WOMENS 50+",
              "ALIVE WOMENS 50+": "ALIVE WOMENS 50+",
              "ALIVE WOMENS ENERGY": "ALIVE WOMENS ENERGY",
              "ALIVE WOMENS GUMMY": "ALIVE WOMENS GUMMY"
            },
            "SCOT-TUSSIN": {
              "VITA-BOB": "VITA-BOB"
            },
            "SECOND WIND VITAMINS": {
              "CELEBRATE MULTI-COMPLETE 18": "CELEBRATE MULTI-COMPLETE 18",
              "CELEBRATE MULTI-COMPLETE 36": "CELEBRATE MULTI-COMPLETE 36",
              "CELEBRATE MULTI-COMPLETE 45": "CELEBRATE MULTI-COMPLETE 45",
              "CELEBRATE MULTI-COMPLETE 60": "CELEBRATE MULTI-COMPLETE 60"
            },
            "SILARX": {
              "CENTAMIN": "CENTAMIN"
            },
            "SOLACE NUTRITION": {
              "NANOVM ADULT": "NANOVM ADULT",
              "NANOVM SENIOR +": "NANOVM SENIOR +"
            },
            "SORTER LABS": {
              "VITACEL": "VITACEL"
            },
            "TARGET": {
              "TGT MULTIVITAMIN/MULTIMINERAL": "TGT MULTIVITAMIN/MULTIMINERAL"
            },
            "TEVA PHARMACEUTICALS USA": {
              "ANTIOXIDANT FORMULA SG": "ANTIOXIDANT FORMULA SG",
              "CERTAGEN": "CERTAGEN",
              "PRORENAL + D": "PRORENAL + D",
              "PRORENAL + D W/ OMEGA-3": "PRORENAL + D W/ OMEGA-3",
              "THERAPEUTIC-M": "THERAPEUTIC-M"
            },
            "THE KEY COMPANY": {
              "REPLACE": "REPLACE"
            },
            "THERALOGIX": {
              "COMPANION": "COMPANION",
              "CONCEPTIONXR MOTILITY SUPPORT": "CONCEPTIONXR MOTILITY SUPPORT",
              "ESSENTIA": "ESSENTIA",
              "PROCERV HP": "PROCERV HP",
              "SOLO": "SOLO",
              "THERANATAL LACTATION COMPLETE": "THERANATAL LACTATION COMPLETE",
              "THERANATAL LACTATION ONE": "THERANATAL LACTATION ONE",
              "THERANATAL LACTATION SUPPORT": "THERANATAL LACTATION SUPPORT"
            },
            "TRIVIDIA HEALTH": {
              "TRUEPLUS DIABETIC MULTIVITAMIN": "TRUEPLUS DIABETIC MULTIVITAMIN"
            },
            "U S PHARMACEUTICAL": {
              "MEDIPLEX PLUS": "MEDIPLEX PLUS"
            },
            "VERTICAL PHARMACEUTICAL": {
              "CORVITE FREE": "CORVITE FREE"
            },
            "VIRTUS PHARMACEUTICALS": {
              "VP-ZEL": "VP-ZEL"
            },
            "VIRTUS PHARMACEUTICALS OPCO": {
              "VIT B3-AZELAC-TURM-FA-B6-ZN-CU": "VIT B3-AZELAC-TURM-FA-B6-ZN-CU"
            },
            "VITALINE": {
              "ENVIRO-STRESS": "ENVIRO-STRESS",
              "MAXIMUM BLUE LABEL": "MAXIMUM BLUE LABEL",
              "MAXIMUM GREEN LABEL": "MAXIMUM GREEN LABEL",
              "MAXIMUM RED LABEL": "MAXIMUM RED LABEL",
              "MULTIMINERAL PLUS": "MULTIMINERAL PLUS",
              "TOTAL FORMULA": "TOTAL FORMULA",
              "TOTAL FORMULA 2": "TOTAL FORMULA 2",
              "TOTAL FORMULA 3": "TOTAL FORMULA 3"
            },
            "VITAMIN HEALTH": {
              "VITEYES AREDS ADVANCED": "VITEYES AREDS ADVANCED",
              "VITEYES AREDS FORMULA": "VITEYES AREDS FORMULA",
              "VITEYES AREDS FORMULA/LUTEIN": "VITEYES AREDS FORMULA/LUTEIN",
              "VITEYES COMPANION/LYCOPENE": "VITEYES COMPANION/LYCOPENE",
              "VITEYES COMPLETE": "VITEYES COMPLETE",
              "VITEYES SMOKERS ADVANCED": "VITEYES SMOKERS ADVANCED",
              "VITEYES SMOKERS FORMULA/LUTEIN": "VITEYES SMOKERS FORMULA/LUTEIN"
            },
            "WAL-MART": {
              "ANTIOXIDANT FORMULA/MINERALS": "ANTIOXIDANT FORMULA/MINERALS",
              "DAILY PAK MAXIMUM MULTIVITAMIN": "DAILY PAK MAXIMUM MULTIVITAMIN",
              "EQ COMPLETE MULTIVIT ADULT 50+": "EQ COMPLETE MULTIVIT ADULT 50+",
              "EQ COMPLETE MULTIVITAMIN-ADULT": "EQ COMPLETE MULTIVITAMIN-ADULT",
              "EQ MULTIVITAMINS ADULT GUMMY": "EQ MULTIVITAMINS ADULT GUMMY",
              "EQ ONE DAILY MENS 50+": "EQ ONE DAILY MENS 50+",
              "EQ ONE DAILY MENS HEALTH": "EQ ONE DAILY MENS HEALTH",
              "EQ ONE DAILY WOMENS 50+": "EQ ONE DAILY WOMENS 50+",
              "EQ ONE DAILY WOMENS HEALTH": "EQ ONE DAILY WOMENS HEALTH",
              "EQ ONE DAILY WOMENS PRO-ACTIVE": "EQ ONE DAILY WOMENS PRO-ACTIVE",
              "EQ VISION FORMULA 50+": "EQ VISION FORMULA 50+",
              "HAIR/SKIN/NAILS/BIOTIN": "HAIR/SKIN/NAILS/BIOTIN",
              "ULTRA MENS PACK": "ULTRA MENS PACK",
              "ULTRA WOMENS PACK": "ULTRA WOMENS PACK",
              "VISION FORMULA/LUTEIN": "VISION FORMULA/LUTEIN"
            },
            "WALGREENS": {
              "50+ ADULT EYE HEALTH": "50+ ADULT EYE HEALTH",
              "A THRU Z ADVANCED": "A THRU Z ADVANCED",
              "A THRU Z ADVANCED ADULT": "A THRU Z ADVANCED ADULT",
              "A THRU Z HIGH POTENCY": "A THRU Z HIGH POTENCY",
              "A THRU Z SELECT": "A THRU Z SELECT",
              "A THRU Z SELECT 50+ ADVANCED": "A THRU Z SELECT 50+ ADVANCED",
              "A THRU Z SELECT 50+ MENS": "A THRU Z SELECT 50+ MENS",
              "A THRU Z SELECT ADVANCED": "A THRU Z SELECT ADVANCED",
              "A THRU Z SELECT ULTIMATE WOMEN": "A THRU Z SELECT ULTIMATE WOMEN",
              "A THRU Z ULTIMATE MENS": "A THRU Z ULTIMATE MENS",
              "ADULT ONE DAILY GUMMIES": "ADULT ONE DAILY GUMMIES",
              "ADVANCED EYE HEALTH": "ADVANCED EYE HEALTH",
              "COMPLETE MULTIVITAMIN/MINERAL": "COMPLETE MULTIVITAMIN/MINERAL",
              "CORAL CALCIUM PLUS": "CORAL CALCIUM PLUS",
              "DAILY HEART HEALTH SUPPORT": "DAILY HEART HEALTH SUPPORT",
              "DIABETES HEALTH FORMULA": "DIABETES HEALTH FORMULA",
              "ENERGY BOOSTER": "ENERGY BOOSTER",
              "EYE HEALTH": "EYE HEALTH",
              "HAIR SKIN AND NAILS FORMULA": "HAIR SKIN AND NAILS FORMULA",
              "MENS 50+ ADVANCED": "MENS 50+ ADVANCED",
              "MULTI FOR HER": "MULTI FOR HER",
              "MULTI FOR HIM": "MULTI FOR HIM",
              "MULTI-VITAMIN GUMMIES": "MULTI-VITAMIN GUMMIES",
              "MULTIVITAMIN": "MULTIVITAMIN",
              "MULTIVITAMIN ADULT": "MULTIVITAMIN ADULT",
              "MULTIVITAMIN ADULTS 50+": "MULTIVITAMIN ADULTS 50+",
              "MULTIVITAMIN GUMMIES ADULT": "MULTIVITAMIN GUMMIES ADULT",
              "MULTIVITAMIN GUMMIES MENS": "MULTIVITAMIN GUMMIES MENS",
              "MULTIVITAMIN GUMMIES WOMENS": "MULTIVITAMIN GUMMIES WOMENS",
              "MULTIVITAMIN MEN": "MULTIVITAMIN MEN",
              "MULTIVITAMIN MEN 50+": "MULTIVITAMIN MEN 50+",
              "MULTIVITAMIN WOMEN": "MULTIVITAMIN WOMEN",
              "MULTIVITAMIN WOMEN 50+": "MULTIVITAMIN WOMEN 50+",
              "OCUTABS": "OCUTABS",
              "OCUTABS-LUTEIN": "OCUTABS-LUTEIN",
              "ONE DAILY 50 PLUS": "ONE DAILY 50 PLUS",
              "ONE DAILY FOR MEN 50+ ADVANCED": "ONE DAILY FOR MEN 50+ ADVANCED",
              "ONE DAILY FOR MEN/LYCOPENE": "ONE DAILY FOR MEN/LYCOPENE",
              "ONE DAILY FOR WOMEN": "ONE DAILY FOR WOMEN",
              "ONE DAILY FOR WOMEN 50+ ADV": "ONE DAILY FOR WOMEN 50+ ADV",
              "ONE DAILY HEALTHY WEIGHT": "ONE DAILY HEALTHY WEIGHT",
              "ONE DAILY HEALTHY WEIGHT ADV": "ONE DAILY HEALTHY WEIGHT ADV",
              "ONE DAILY MENS": "ONE DAILY MENS",
              "ONE DAILY MENS 50+ MULTIVIT": "ONE DAILY MENS 50+ MULTIVIT",
              "ONE DAILY MENS 50+/LYCOPENE": "ONE DAILY MENS 50+/LYCOPENE",
              "ONE DAILY MULTIVIT/IRON-FREE": "ONE DAILY MULTIVIT/IRON-FREE",
              "ONE DAILY MULTIVITAMIN MEN": "ONE DAILY MULTIVITAMIN MEN",
              "ONE DAILY MULTIVITAMIN WOMEN": "ONE DAILY MULTIVITAMIN WOMEN",
              "ONE DAILY WOMENS": "ONE DAILY WOMENS",
              "ONE DAILY WOMENS 50 PLUS": "ONE DAILY WOMENS 50 PLUS",
              "SUPER AYTINAL": "SUPER AYTINAL",
              "SUPER AYTINAL 50 PLUS": "SUPER AYTINAL 50 PLUS",
              "THERAGRAN-M": "THERAGRAN-M",
              "THERAGRAN-M ADVANCED": "THERAGRAN-M ADVANCED",
              "THERAGRAN-M ADVANCED 50 PLUS": "THERAGRAN-M ADVANCED 50 PLUS",
              "THERAGRAN-M PREMIER": "THERAGRAN-M PREMIER",
              "THERAGRAN-M PREMIER 50 PLUS": "THERAGRAN-M PREMIER 50 PLUS",
              "THRIVE FOR LIFE WOMENS": "THRIVE FOR LIFE WOMENS",
              "ULTRACHOICE ADV FORMULA MATURE": "ULTRACHOICE ADV FORMULA MATURE",
              "ULTRACHOICE ADVANCED FORMULA": "ULTRACHOICE ADVANCED FORMULA",
              "VITAMIN C EFFERVESCENT BLEND": "VITAMIN C EFFERVESCENT BLEND",
              "WAL-BORN VITAMIN C": "WAL-BORN VITAMIN C",
              "WOMENS 50+ ADVANCED": "WOMENS 50+ ADVANCED",
              "WOMENS MULTI": "WOMENS MULTI"
            },
            "WINDMILL CONSUMER PRODUCTS": {
              "ADVANCED DIABETIC MULTIVITAMIN": "ADVANCED DIABETIC MULTIVITAMIN",
              "ANTIOXIDANT PROTECTION FORMULA": "ANTIOXIDANT PROTECTION FORMULA",
              "CAL-DAY 1000": "CAL-DAY 1000",
              "DAILY MENS HEALTH FORMULA": "DAILY MENS HEALTH FORMULA",
              "DAILY VITAMIN PLUS": "DAILY VITAMIN PLUS",
              "DAILY WOMENS HEALTH FORMULA": "DAILY WOMENS HEALTH FORMULA",
              "DAILY-VITAMIN MAXIMUM FORMULA": "DAILY-VITAMIN MAXIMUM FORMULA",
              "HI-POTENCY MULTI-VITAMIN": "HI-POTENCY MULTI-VITAMIN",
              "MAGNUM-75": "MAGNUM-75",
              "MENS 50+ MULTI VITAMIN/MIN": "MENS 50+ MULTI VITAMIN/MIN",
              "MENS MULTI VITAMIN & MINERAL": "MENS MULTI VITAMIN & MINERAL",
              "MILLTRIUM ADVANCED FORMULA": "MILLTRIUM ADVANCED FORMULA",
              "MILLTRIUM CARDIO": "MILLTRIUM CARDIO",
              "MILLTRIUM SENIOR": "MILLTRIUM SENIOR",
              "MULTI-LEAN": "MULTI-LEAN",
              "MULTI-VITAMIN MENOPAUSAL": "MULTI-VITAMIN MENOPAUSAL",
              "THERA-MILL M": "THERA-MILL M",
              "THERABETIC MULTI-VITAMIN": "THERABETIC MULTI-VITAMIN",
              "VISIVITES": "VISIVITES",
              "VISIVITES/LUTEIN": "VISIVITES/LUTEIN",
              "WOMENS 50+ MULTI VITAMIN/MIN": "WOMENS 50+ MULTI VITAMIN/MIN",
              "WOMENS MULTI VITAMIN & MINERAL": "WOMENS MULTI VITAMIN & MINERAL"
            },
            "XYMOGEN": {
              "ACTIVESSENTIALS": "ACTIVESSENTIALS",
              "ACTIVNUTRIENTS": "ACTIVNUTRIENTS"
            }
          },
          "Multiple Vitamins w/ Minerals & Folic Acid": {
            "ACTIPHARMA": {
              "ACTIVITE EC": "ACTIVITE EC"
            },
            "ADLER-STERN": {
              "OCUVEL": "OCUVEL"
            },
            "EXELTIS USA": {
              "STROVITE FORTE": "STROVITE FORTE"
            },
            "HILLESTAD PHARMACEUTICALS": {
              "DIALYVITE SUPREME D": "DIALYVITE SUPREME D"
            },
            "LEVINS PHARMACEUTICALS": {
              "REMEDIENT": "REMEDIENT"
            },
            "METHOD PHARMACEUTICALS": {
              "THRIVITE 19": "THRIVITE 19"
            },
            "NEOMED PHARMACEUTICAL": {
              "ONEVITE": "ONEVITE"
            },
            "POLY PHARMACEUTICALS": {
              "UDAMIN SP": "UDAMIN SP"
            },
            "SEYER PHARMATEC": {
              "SUPERVITE EC": "SUPERVITE EC"
            },
            "SIRCLE LABORATORIES": {
              "SYNAGEX": "SYNAGEX",
              "SYNATEK": "SYNATEK"
            },
            "TRIGEN LABORATORIES": {
              "CORVITA": "CORVITA"
            },
            "VERTICAL PHARMACEUTICAL": {
              "CORVITE": "CORVITE"
            }
          }
        },
        "Multiple Vitamins w/ Minerals & Calcium-Folic Acid": {
          "Multiple Vitamins w/ Minerals & Calcium-Folic Acid": {
            "TRIGEN LABORATORIES": {
              "TL G-FOL OS": "TL G-FOL OS"
            },
            "UPSHER-SMITH": {
              "FOLGARD OS": "FOLGARD OS"
            }
          }
        },
        "Multiple Vitamins w/ Minerals & Fluoride-Iron-Folic Acid": {
          "Multiple Vitamins w/ Minerals & Fluoride-Iron-Folic Acid": {
            "CARWIN ASSOCIATES": {
              "QUFLORA FE": "QUFLORA FE"
            }
          }
        },
        "Multivitamins": {
          "Multiple Vitamins with Folic Acid": {
            "7T PHARMA": {
              "GENICIN VITA-Q": "GENICIN VITA-Q"
            },
            "AXIS PHARMACEUTICS": {
              "VITAXYME": "VITAXYME"
            },
            "INNOVIDA PHARMACEUTIQUE": {
              "FOLIKA-V": "FOLIKA-V"
            }
          },
          "Multivitamins": {
            "A-S MEDICATION SOLUTIONS": {
              "DAILY MULTIPLE VITAMINS": "DAILY MULTIPLE VITAMINS",
              "MULTI-VITAMINS": "MULTI-VITAMINS",
              "TAB-A-VITE/BETA CAROTENE": "TAB-A-VITE/BETA CAROTENE"
            },
            "AIDAREX PHARMACEUTICALS": {
              "MULTI-VITAMIN": "MULTI-VITAMIN"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP ESSENTIAL ONE DAILY": "GNP ESSENTIAL ONE DAILY"
            },
            "AUBURN PHARMACEUTICAL": {
              "ONCE DAILY": "ONCE DAILY",
              "THERA": "THERA"
            },
            "BASIC DRUGS": {
              "MULTI VITAMIN": "MULTI VITAMIN",
              "MULTI VITAMIN W/D-3": "MULTI VITAMIN W/D-3"
            },
            "BAXTER HEALTHCARE CORP": {
              "INFUVITE ADULT": "INFUVITE ADULT"
            },
            "BAYER CONSUMER": {
              "ONE-A-DAY ADULT VITACRAVES+DHA": "ONE-A-DAY ADULT VITACRAVES+DHA",
              "ONE-A-DAY ESSENTIAL": "ONE-A-DAY ESSENTIAL",
              "ONE-A-DAY MENS": "ONE-A-DAY MENS"
            },
            "BERGEN BRUNSWIG": {
              "GNP ESSENTIAL ONE DAILY": "GNP ESSENTIAL ONE DAILY"
            },
            "BIOTICS RESEARCH": {
              "CHLOROCAPS": "CHLOROCAPS"
            },
            "BONITA PHARMACEUTICALS": {
              "ONE DAILY": "ONE DAILY"
            },
            "CALLION PHARMA": {
              "DEKAS ESSENTIAL": "DEKAS ESSENTIAL"
            },
            "CHAIN DRUG CONSORTIUM": {
              "DAILY MULTIPLE VITAMINS": "DAILY MULTIPLE VITAMINS"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC ESSENTIALS": "QC ESSENTIALS"
            },
            "CONTRACT PHARMACAL CORPORATION": {
              "DAILY VITAMINS": "DAILY VITAMINS"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS DAILY MULTIPLE": "CVS DAILY MULTIPLE"
            },
            "DSE HEALTHCARE SOLUTIONS": {
              "MULTIVITAMINS": "MULTIVITAMINS"
            },
            "ENOVACHEM MANUFACTURING": {
              "VITALEE": "VITALEE"
            },
            "EQUALINE": {
              "EQL ONE DAILY ESSENTIAL": "EQL ONE DAILY ESSENTIAL"
            },
            "EXELTIS USA": {
              "ZE-PLUS": "ZE-PLUS"
            },
            "FREEDA VITAMINS": {
              "DAILY VALUE MULTIVITAMIN": "DAILY VALUE MULTIVITAMIN",
              "QUINTABS": "QUINTABS"
            },
            "GERI-CARE": {
              "ONE-DAILY MULTI VITAMINS": "ONE-DAILY MULTI VITAMINS",
              "ONE-DAILY MULTI-VITAMIN": "ONE-DAILY MULTI-VITAMIN",
              "THERA-TABS": "THERA-TABS"
            },
            "HANNAFORD BROTHERS": {
              "ONE DAILY ESSENTIAL": "ONE DAILY ESSENTIAL"
            },
            "HOSPIRA": {
              "M.V.I.-12 (WITHOUT VITAMIN K)": "M.V.I.-12 (WITHOUT VITAMIN K)"
            },
            "INTERNATIONAL VITAMIN CORP": {
              "STRESSTABS ENERGY": "STRESSTABS ENERGY"
            },
            "LEADER BRAND PRODUCTS": {
              "ONE DAILY": "ONE DAILY"
            },
            "LIBERTY PHARMACEUTICAL": {
              "MULTIPLE VITAMIN": "MULTIPLE VITAMIN"
            },
            "LIVING WELL PHARMACY": {
              "OMNICAP": "OMNICAP"
            },
            "MAGNO-HUMPHRIES LABORATORIES": {
              "ANTI-OXIDANT": "ANTI-OXIDANT",
              "MULTI VITAMIN": "MULTI VITAMIN"
            },
            "MAJOR PHARMACEUTICALS": {
              "HIGH POTENCY MULTIVITAMIN": "HIGH POTENCY MULTIVITAMIN",
              "TAB-A-VITE": "TAB-A-VITE",
              "TAB-A-VITE/BETA CAROTENE": "TAB-A-VITE/BETA CAROTENE",
              "THERA": "THERA"
            },
            "MARLEX PHARMACEUTICALS": {
              "DAILY MULTIPLE VITAMINS": "DAILY MULTIPLE VITAMINS",
              "ONCE DAILY": "ONCE DAILY"
            },
            "MASON VITAMINS": {
              "DAILY MULTIPLE VITAMINS": "DAILY MULTIPLE VITAMINS",
              "VIT E-VIT C-BETA CAROTENE": "VIT E-VIT C-BETA CAROTENE"
            },
            "MCKESSON SUNMARK": {
              "SM MULTIPLE VITAMINS ESSENTIAL": "SM MULTIPLE VITAMINS ESSENTIAL"
            },
            "MEDICINE SHOPPE": {
              "MULTI VITAMIN MENS": "MULTI VITAMIN MENS",
              "MULTIPLE VITAMINS ESSENTIAL": "MULTIPLE VITAMINS ESSENTIAL"
            },
            "METAGENICS": {
              "ESTROFACTORS": "ESTROFACTORS"
            },
            "MILLER": {
              "CARDENZ": "CARDENZ",
              "MIL-A-MULSION": "MIL-A-MULSION"
            },
            "MOM ENTERPRISES": {
              "MOMMY\\'S BLISS MV ORGANIC DROPS": "MOMMY\\'S BLISS MV ORGANIC DROPS"
            },
            "NAT-RUL HEALTH PRODUCTS": {
              "DAILY VITE": "DAILY VITE"
            },
            "NATIONAL VITAMIN": {
              "DAILY VITE": "DAILY VITE",
              "DAILY VITES": "DAILY VITES",
              "HEALTHY HAIR/SKIN/NAILS": "HEALTHY HAIR/SKIN/NAILS",
              "MULTI-VITAMIN": "MULTI-VITAMIN",
              "MULTIPLE VITAMIN-FOLIC ACID": "MULTIPLE VITAMIN-FOLIC ACID"
            },
            "NATURES BOUNTY": {
              "MULTI-DAY": "MULTI-DAY"
            },
            "NEOMED PHARMACEUTICAL": {
              "NEOMULTIVITE": "NEOMULTIVITE"
            },
            "NEPHRIAN": {
              "MV-ONE": "MV-ONE"
            },
            "NOVAPLUS/BAXTER HEALTHCARE": {
              "INFUVITE ADULT": "INFUVITE ADULT"
            },
            "NUCARE PHARMACEUTICALS": {
              "MULTI-VITAMINS": "MULTI-VITAMINS"
            },
            "NUMARK LABS": {
              "MULTIVITAMINS": "MULTIVITAMINS"
            },
            "PDRX PHARMACEUTICAL": {
              "MULTIPLE VITAMINS": "MULTIPLE VITAMINS"
            },
            "PFIZER U.S.": {
              "M.V.I. ADULT": "M.V.I. ADULT"
            },
            "PHARMAVITE": {
              "ANTIOXIDANT FORMULA": "ANTIOXIDANT FORMULA"
            },
            "PLUS PHARMA": {
              "DAILY VITAMIN": "DAILY VITAMIN",
              "MULTI VITAMIN DAILY": "MULTI VITAMIN DAILY",
              "MULTI-VITAMIN DAILY": "MULTI-VITAMIN DAILY"
            },
            "PRINCETON RESEARCH": {
              "ONE DAILY ESSENTIAL": "ONE DAILY ESSENTIAL"
            },
            "QUALITY CARE": {
              "MULTIPLE VITAMINS": "MULTIPLE VITAMINS"
            },
            "REXALL SUNDOWN": {
              "VITAMIN E/FOLIC ACID/B-6/B-12": "VITAMIN E/FOLIC ACID/B-6/B-12"
            },
            "RITE AID CORPORATION": {
              "RA ONE DAILY ESSENTIAL": "RA ONE DAILY ESSENTIAL",
              "RA ONE DAILY MULTI-VITAMIN": "RA ONE DAILY MULTI-VITAMIN"
            },
            "RUGBY LABORATORIES": {
              "DAILY-VITE": "DAILY-VITE",
              "DAILY-VITE MULTIVITAMIN": "DAILY-VITE MULTIVITAMIN",
              "MULTI-VITAMINS": "MULTI-VITAMINS",
              "MULTIVITAMIN": "MULTIVITAMIN",
              "THEREMS": "THEREMS"
            },
            "RXCHANGE CO": {
              "MULTI-DAY VITAMINS": "MULTI-DAY VITAMINS"
            },
            "SCHWABE NORTH AMERICA": {
              "MULTIVITAMIN+": "MULTIVITAMIN+"
            },
            "SHEFFIELD PHARMACEUTICALS": {
              "SIGTAB": "SIGTAB"
            },
            "TEVA PHARMACEUTICALS USA": {
              "THERAPEUTIC": "THERAPEUTIC"
            },
            "THE KEY COMPANY": {
              "CHEW-12": "CHEW-12"
            },
            "VITALINE": {
              "RENAL MULTIVITAMIN/ZINC": "RENAL MULTIVITAMIN/ZINC"
            },
            "WALGREENS": {
              "MULTIPLE VITAMINS": "MULTIPLE VITAMINS",
              "MULTIVITAMIN IRON-FREE": "MULTIVITAMIN IRON-FREE"
            },
            "WINDMILL CONSUMER PRODUCTS": {
              "DAILY-VITAMIN": "DAILY-VITAMIN",
              "THERA-MILL": "THERA-MILL"
            }
          }
        },
        "Ped MV w/ Fluoride": {
          "Ped MV w/ Fluoride": {
            "A-S MEDICATION SOLUTIONS": {
              "MULT-VITAMIN/FLUORIDE": "MULT-VITAMIN/FLUORIDE",
              "MULTI VIT/FL": "MULTI VIT/FL",
              "MULTI-VIT/FLUORIDE": "MULTI-VIT/FLUORIDE",
              "MULTIPLE VITAMINS/FLUORIDE": "MULTIPLE VITAMINS/FLUORIDE",
              "MULTIVITAMIN/FLUORIDE": "MULTIVITAMIN/FLUORIDE"
            },
            "AIDAREX PHARMACEUTICALS": {
              "MULTI-VITAMINS/FLUORIDE": "MULTI-VITAMINS/FLUORIDE"
            },
            "BONGEO PHARMACEUTICALS": {
              "FLORIVA PLUS": "FLORIVA PLUS"
            },
            "CARWIN ASSOCIATES": {
              "QUFLORA GUMMIES": "QUFLORA GUMMIES",
              "QUFLORA PEDIATRIC": "QUFLORA PEDIATRIC"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "POLY-VITAMIN/FLUORIDE": "POLY-VITAMIN/FLUORIDE",
              "POLYVITAMIN/FLUORIDE": "POLYVITAMIN/FLUORIDE"
            },
            "H2 PHARMA": {
              "MULTIVITAMIN/FLUORIDE": "MULTIVITAMIN/FLUORIDE"
            },
            "MAYNE PHARMA": {
              "MULTI VITAMIN/FLUORIDE": "MULTI VITAMIN/FLUORIDE",
              "MULTI-VIT/FLUORIDE": "MULTI-VIT/FLUORIDE",
              "MULTI-VITAMIN/FLUORIDE": "MULTI-VITAMIN/FLUORIDE"
            },
            "MEDVANTX": {
              "MULTI VITA-BETS/FLUORIDE": "MULTI VITA-BETS/FLUORIDE"
            },
            "METHOD PHARMACEUTICALS": {
              "MULTI-VITAMIN/FLUORIDE": "MULTI-VITAMIN/FLUORIDE",
              "MULTIVITAMIN/FLUORIDE": "MULTIVITAMIN/FLUORIDE"
            },
            "PAR PHARMACEUTICAL": {
              "MULTI-VITAMIN/FLUORIDE": "MULTI-VITAMIN/FLUORIDE"
            },
            "PAR PHARMACEUTICALS": {
              "MULTI-VIT/FLUORIDE": "MULTI-VIT/FLUORIDE"
            },
            "PURE TEK": {
              "MULTIVITAMIN/FLUORIDE": "MULTIVITAMIN/FLUORIDE"
            },
            "REGENT LABS, INC": {
              "MULTI-VITAMIN/FLUORIDE": "MULTI-VITAMIN/FLUORIDE"
            },
            "SANCILIO": {
              "MULTIVITAMIN/FLUORIDE": "MULTIVITAMIN/FLUORIDE",
              "MVC-FLUORIDE": "MVC-FLUORIDE"
            },
            "SDA LABORATORIES INC": {
              "MULTIVITAMINS/FLUORIDE": "MULTIVITAMINS/FLUORIDE"
            },
            "SETON PHARMACEUTICALS": {
              "MULTI-VIT/FLUORIDE": "MULTI-VIT/FLUORIDE"
            },
            "ZYLERA PHARMACEUTICALS": {
              "POLY-VI-FLOR": "POLY-VI-FLOR",
              "POLY-VI-FLOR FS": "POLY-VI-FLOR FS"
            }
          },
          "Ped Vitamins ACD & FA w/ Fluoride": {
            "DESTON THERAPEUTICS": {
              "TRI-VI-FLORO": "TRI-VI-FLORO"
            },
            "ZYLERA PHARMACEUTICALS": {
              "TRI-VI-FLOR": "TRI-VI-FLOR"
            }
          },
          "Ped Vitamins ACD w/ Fluoride": {
            "A-S MEDICATION SOLUTIONS": {
              "VITAMINS ACD-FLUORIDE": "VITAMINS ACD-FLUORIDE"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "TRI-VITAMIN/FLUORIDE": "TRI-VITAMIN/FLUORIDE"
            },
            "H2 PHARMA": {
              "ADC/F (0.5MG/ML)": "ADC/F (0.5MG/ML)",
              "VITAMINS ACD-FLUORIDE": "VITAMINS ACD-FLUORIDE"
            },
            "MAYNE PHARMA": {
              "VITAMINS ACD-FLUORIDE": "VITAMINS ACD-FLUORIDE"
            },
            "METHOD PHARMACEUTICALS": {
              "TRI-VITE/FLUORIDE": "TRI-VITE/FLUORIDE"
            },
            "PAR PHARMACEUTICAL": {
              "TRIPLE-VITAMIN/FLUORIDE": "TRIPLE-VITAMIN/FLUORIDE"
            },
            "PAR PHARMACEUTICALS": {
              "TRI-VIT/FLUORIDE": "TRI-VIT/FLUORIDE"
            },
            "SANCILIO": {
              "TRI-VITAMIN/FLUORIDE": "TRI-VITAMIN/FLUORIDE"
            },
            "SETON PHARMACEUTICALS": {
              "TRIPLE-VITAMIN/FLUORIDE": "TRIPLE-VITAMIN/FLUORIDE"
            }
          }
        },
        "Ped MV w/ Iron": {
          "Ped MV w/ Iron": {
            "A-S MEDICATION SOLUTIONS": {
              "CEROVITE JR": "CEROVITE JR"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP CHILDRENS CHEWABLES/IRON": "GNP CHILDRENS CHEWABLES/IRON"
            },
            "ATLANTIC BIOLOGICALS": {
              "POLY-VITAMIN/IRON": "POLY-VITAMIN/IRON"
            },
            "AUBURN PHARMACEUTICAL": {
              "CHILD CHEWABLE VITAMINS/IRON": "CHILD CHEWABLE VITAMINS/IRON"
            },
            "BAYER CONSUMER": {
              "FLINTSTONES COMPLETE": "FLINTSTONES COMPLETE",
              "FLINTSTONES W/IRON": "FLINTSTONES W/IRON",
              "SCOOBY-DOO ONE A DAY": "SCOOBY-DOO ONE A DAY"
            },
            "BAYSHORE PHARMACEUTICALS": {
              "BPROTECTED PEDIA POLY-VITE/FE": "BPROTECTED PEDIA POLY-VITE/FE"
            },
            "BERGEN BRUNSWIG": {
              "GNP ANIMAL SHAPES PLUS IRON": "GNP ANIMAL SHAPES PLUS IRON",
              "GNP CHILDRENS CHEWABLES/IRON": "GNP CHILDRENS CHEWABLES/IRON"
            },
            "CHAIN DRUG CONSORTIUM": {
              "KIDS VITAMINS COMPLETE": "KIDS VITAMINS COMPLETE",
              "KIDS VITAMINS PLUS IRON": "KIDS VITAMINS PLUS IRON"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC CHILDRENS COMPLETE": "QC CHILDRENS COMPLETE",
              "QC CHILDRENS VITAMINS/IRON": "QC CHILDRENS VITAMINS/IRON"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS CHEWABLE CHILDRENS VITAMIN": "CVS CHEWABLE CHILDRENS VITAMIN",
              "CVS CHILDREN MULTIVITAMIN/IRON": "CVS CHILDREN MULTIVITAMIN/IRON",
              "CVS CHILDRENS COMPLETE": "CVS CHILDRENS COMPLETE"
            },
            "EQUALINE": {
              "EQL CHILD MULTIVIT/MINERALS": "EQL CHILD MULTIVIT/MINERALS",
              "EQL CHILDREN MULTIVITAMIN/IRON": "EQL CHILDREN MULTIVITAMIN/IRON"
            },
            "GENSAVIS PHARMACEUTICALS": {
              "NOVAFERRUM PED MULTI VIT-IRON": "NOVAFERRUM PED MULTI VIT-IRON"
            },
            "GERI-CARE": {
              "CHILDRENS VITAMINS/IRON": "CHILDRENS VITAMINS/IRON"
            },
            "HANNAFORD BROTHERS": {
              "CHILDRENS MULTIVITAMIN": "CHILDRENS MULTIVITAMIN"
            },
            "LEADER BRAND PRODUCTS": {
              "CHILDRENS ANIMAL SHAPES": "CHILDRENS ANIMAL SHAPES",
              "MULTIVITAMINS PLUS IRON CHILD": "MULTIVITAMINS PLUS IRON CHILD",
              "ZOO FRIENDS PLUS IRON": "ZOO FRIENDS PLUS IRON"
            },
            "M J NUTR": {
              "POLY-VI-SOL/IRON": "POLY-VI-SOL/IRON"
            },
            "MAGNO-HUMPHRIES LABORATORIES": {
              "FRUITY CHEWS/IRON": "FRUITY CHEWS/IRON"
            },
            "MAJOR PHARMACEUTICALS": {
              "ANIMAL SHAPES/IRON": "ANIMAL SHAPES/IRON",
              "POLY-VITA/IRON": "POLY-VITA/IRON"
            },
            "MASON VITAMINS": {
              "LITTLE ANIMALS PLUS IRON": "LITTLE ANIMALS PLUS IRON"
            },
            "MCKESSON": {
              "HM ANIMAL SHAPES": "HM ANIMAL SHAPES"
            },
            "MCKESSON SUNMARK": {
              "SM ANIMAL SHAPES COMPLETE": "SM ANIMAL SHAPES COMPLETE"
            },
            "NATIONAL VITAMIN": {
              "DINO-LIFE W/IRON-ZINC": "DINO-LIFE W/IRON-ZINC",
              "HONEY BEARS W/IRON-ZINC": "HONEY BEARS W/IRON-ZINC"
            },
            "PDRX PHARMACEUTICAL": {
              "MULTIPLE VITAMINS-IRON": "MULTIPLE VITAMINS-IRON"
            },
            "PLUS PHARMA": {
              "CHILDRENS MULTIVITAMIN/IRON": "CHILDRENS MULTIVITAMIN/IRON"
            },
            "PUBLIX SUPER MARKETS INC.": {
              "PX CHILDRENS VITAMIN": "PX CHILDRENS VITAMIN"
            },
            "RELIABLE 1 LABS": {
              "POLY-VITA/IRON": "POLY-VITA/IRON"
            },
            "RITE AID CORPORATION": {
              "RA CHILDRENS CHEWABLE VIT/IRON": "RA CHILDRENS CHEWABLE VIT/IRON",
              "RA VITAMINS COMPLETE CHILDRENS": "RA VITAMINS COMPLETE CHILDRENS"
            },
            "RUGBY LABORATORIES": {
              "CEROVITE JR": "CEROVITE JR",
              "CHEWABLE VITE/IRON CHILDRENS": "CHEWABLE VITE/IRON CHILDRENS",
              "MULTIVITAMIN DROPS/IRON": "MULTIVITAMIN DROPS/IRON",
              "POLYVITAMIN/IRON": "POLYVITAMIN/IRON"
            },
            "SILARX": {
              "MULTI-DELYN/IRON": "MULTI-DELYN/IRON"
            },
            "SIMPLE DIAGNOSTICS": {
              "PC PEDIATRIC POLY-VITA/FE DROP": "PC PEDIATRIC POLY-VITA/FE DROP"
            },
            "WAL-MART": {
              "EQ COMPLETE MULTIVITAMIN CHILD": "EQ COMPLETE MULTIVITAMIN CHILD"
            },
            "WALGREENS": {
              "LAND BEFORE TIME MULTIVITAMIN": "LAND BEFORE TIME MULTIVITAMIN",
              "ULTRA CHOICE MULTIVITAMIN KIDS": "ULTRA CHOICE MULTIVITAMIN KIDS"
            },
            "WINDMILL CONSUMER PRODUCTS": {
              "BITE-A-MINS/IRON": "BITE-A-MINS/IRON"
            }
          },
          "Ped Vitamins ACD w/ Iron": {
            "TRIS PHARMA": {
              "MYKIDZ IRON": "MYKIDZ IRON"
            }
          }
        },
        "Ped Multi Vitamins w/Fl & FE": {
          "Ped Multi Vitamins w/Fl & FE": {
            "CARWIN ASSOCIATES": {
              "QUFLORA FE PEDIATRIC": "QUFLORA FE PEDIATRIC"
            },
            "GM PHARMACEUTICALS": {
              "ESCAVITE": "ESCAVITE",
              "ESCAVITE D": "ESCAVITE D",
              "ESCAVITE LQ": "ESCAVITE LQ"
            },
            "H2 PHARMA": {
              "MULTI-VIT/IRON/FLUORIDE": "MULTI-VIT/IRON/FLUORIDE"
            },
            "MAYNE PHARMA": {
              "MULTI-VIT/FLUORIDE/IRON": "MULTI-VIT/FLUORIDE/IRON"
            },
            "METHOD PHARMACEUTICALS": {
              "MULTI-VITAMIN/FLUORIDE/IRON": "MULTI-VITAMIN/FLUORIDE/IRON"
            },
            "PAR PHARMACEUTICALS": {
              "MULTI-VIT/FLUORIDE/IRON": "MULTI-VIT/FLUORIDE/IRON"
            },
            "SANCILIO": {
              "MULTIVITAMIN/FLUORIDE/IRON": "MULTIVITAMIN/FLUORIDE/IRON"
            },
            "SETON PHARMACEUTICALS": {
              "MULTI-VITAMIN/FLUORIDE/IRON": "MULTI-VITAMIN/FLUORIDE/IRON"
            },
            "TRIGEN LABORATORIES": {
              "TL-FLUORIVITE": "TL-FLUORIVITE"
            },
            "ZYLERA PHARMACEUTICALS": {
              "POLY-VI-FLOR/IRON": "POLY-VI-FLOR/IRON"
            }
          },
          "Ped Vitamins ACD Fluoride & Iron": {
            "PAR PHARMACEUTICALS": {
              "TRI-VIT/FLUORIDE/IRON": "TRI-VIT/FLUORIDE/IRON"
            },
            "TRIS PHARMA": {
              "MYKIDZ IRON FL": "MYKIDZ IRON FL"
            }
          }
        },
        "Ped Multiple Vitamins w/ Minerals": {
          "Ped Multiple Vitamins w/ Minerals": {
            "CALLION PHARMA": {
              "DEKAS PLUS": "DEKAS PLUS"
            },
            "SOLACE NUTRITION": {
              "NANOVM 1-3 YEARS": "NANOVM 1-3 YEARS",
              "NANOVM 4-8 YEARS": "NANOVM 4-8 YEARS",
              "NANOVM 9-18 YEARS": "NANOVM 9-18 YEARS",
              "NANOVM T/F": "NANOVM T/F"
            },
            "UPSPRING": {
              "UPSPRINGBABY MULTIVITAMIN/IRON": "UPSPRINGBABY MULTIVITAMIN/IRON"
            }
          },
          "Ped Multiple Vitamins w/ Minerals & C": {
            "21ST CENTURY HEALTHCARE": {
              "ZOO FRIENDS COMPLETE": "ZOO FRIENDS COMPLETE",
              "ZOO FRIENDS MULTI GUMMIES": "ZOO FRIENDS MULTI GUMMIES"
            },
            "ALLERGAN": {
              "AQUADEKS": "AQUADEKS"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP CHILDRENS COMPLETE": "GNP CHILDRENS COMPLETE"
            },
            "BAYER CONSUMER": {
              "FLINTSTONES GUMMIES": "FLINTSTONES GUMMIES",
              "FLINTSTONES GUMMIES BONE BUILD": "FLINTSTONES GUMMIES BONE BUILD",
              "FLINTSTONES GUMMIES COMPLETE": "FLINTSTONES GUMMIES COMPLETE",
              "FLINTSTONES GUMMIES PLUS": "FLINTSTONES GUMMIES PLUS",
              "FLINTSTONES SOUR GUMMIES": "FLINTSTONES SOUR GUMMIES",
              "FLINTSTONES TODDLER": "FLINTSTONES TODDLER",
              "ONE-A-DAY JOLLY RANCHER": "ONE-A-DAY JOLLY RANCHER",
              "ONE-A-DAY SCOOBY-DOO GUMMIES": "ONE-A-DAY SCOOBY-DOO GUMMIES"
            },
            "BERGEN BRUNSWIG": {
              "GNP CHILDRENS COMPLETE": "GNP CHILDRENS COMPLETE",
              "GNP ZOOCHEWS GUMMIES": "GNP ZOOCHEWS GUMMIES"
            },
            "CHAIN DRUG CONSORTIUM": {
              "KIDS GUMMY BEAR VITAMINS": "KIDS GUMMY BEAR VITAMINS",
              "MULTIVITAMIN GUMMIES CHILDRENS": "MULTIVITAMIN GUMMIES CHILDRENS"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS GUMMY DINOS": "CVS GUMMY DINOS",
              "CVS GUMMY MULTIVITAMIN KIDS": "CVS GUMMY MULTIVITAMIN KIDS"
            },
            "ENZYMATIC THERAPY": {
              "SEA BUDDIES DAILY MULTIPLE": "SEA BUDDIES DAILY MULTIPLE"
            },
            "EQUALINE": {
              "EQL GUMMIES CHILDRENS": "EQL GUMMIES CHILDRENS",
              "EQL IMMUNITY C GUMMIES CHILD": "EQL IMMUNITY C GUMMIES CHILD",
              "EQL SOUR GUMMIES CHILDRENS": "EQL SOUR GUMMIES CHILDRENS"
            },
            "FREEDA VITAMINS": {
              "VITALETS CHILDRENS": "VITALETS CHILDRENS"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "CENTRUM FLAVOR BURST KIDS": "CENTRUM FLAVOR BURST KIDS",
              "CENTRUM KIDS": "CENTRUM KIDS"
            },
            "H2 PHARMA": {
              "ABDEK": "ABDEK",
              "ABDEK PEDIATRIC": "ABDEK PEDIATRIC"
            },
            "INTEGRATIVE THERAPEUTICS": {
              "NF FORMULAS CHILDRENS": "NF FORMULAS CHILDRENS"
            },
            "INTERNATIONAL VITAMIN CORP": {
              "SPONGEBOB SQUAREPANTS GUMMIES": "SPONGEBOB SQUAREPANTS GUMMIES"
            },
            "LEADER BRAND PRODUCTS": {
              "ZOO FRIENDS": "ZOO FRIENDS",
              "ZOO FRIENDS COMPLETE": "ZOO FRIENDS COMPLETE",
              "ZOO FRIENDS GUMMIES": "ZOO FRIENDS GUMMIES",
              "ZOO FRIENDS GUMMIES PLUS D": "ZOO FRIENDS GUMMIES PLUS D"
            },
            "MASON VITAMINS": {
              "HEALTHY KIDS GUMMIES": "HEALTHY KIDS GUMMIES",
              "HEALTHY KIDS OVERALL HEALTH": "HEALTHY KIDS OVERALL HEALTH"
            },
            "MAYNE PHARMA": {
              "MULTIVITAMINS PEDIATRIC": "MULTIVITAMINS PEDIATRIC"
            },
            "MVW NUTRITIONALS": {
              "MVW COMPLETE FORMULATION": "MVW COMPLETE FORMULATION",
              "MVW COMPLETE FORMULATION D3000": "MVW COMPLETE FORMULATION D3000",
              "MVW COMPLETE FORMULATION D5000": "MVW COMPLETE FORMULATION D5000"
            },
            "NATIONAL VITAMIN": {
              "VITACHEW MULTIPLE VITAMIN": "VITACHEW MULTIPLE VITAMIN"
            },
            "NATURES BOUNTY": {
              "COMPLETE MULTI-VITAMIN": "COMPLETE MULTI-VITAMIN",
              "DISNEY CARS GUMMIES": "DISNEY CARS GUMMIES",
              "DISNEY PRINCESS GUMMIES": "DISNEY PRINCESS GUMMIES"
            },
            "REXALL SUNDOWN": {
              "SPIDER-MAN COMPLETE MULTI-VIT": "SPIDER-MAN COMPLETE MULTI-VIT"
            },
            "RITE AID CORPORATION": {
              "RA GUMMY VITAMINS & MINERALS": "RA GUMMY VITAMINS & MINERALS"
            },
            "RUGBY LABORATORIES": {
              "POLYVITAMIN/IRON": "POLYVITAMIN/IRON"
            },
            "SCHWABE NORTH AMERICA": {
              "ALIVE GUMMIES FOR CHILDREN": "ALIVE GUMMIES FOR CHILDREN",
              "ALIVE MULTI-VITAMIN CHILDRENS": "ALIVE MULTI-VITAMIN CHILDRENS"
            },
            "SHEAR KERSHMAN LABS": {
              "VITAMAX": "VITAMAX",
              "VITAMAX PEDIATRIC": "VITAMAX PEDIATRIC"
            },
            "SMARTYPANTS": {
              "SMARTY PANTS KIDS COMPLETE": "SMARTY PANTS KIDS COMPLETE"
            },
            "WAL-MART": {
              "EQ MULTIVITAMIN GUMMIES": "EQ MULTIVITAMIN GUMMIES",
              "EQ MULTIVITAMINS GUMMY CHILD": "EQ MULTIVITAMINS GUMMY CHILD"
            },
            "WALGREENS": {
              "CHILDRENS GUMMIES": "CHILDRENS GUMMIES",
              "GUMMI BEAR MULTIVITAMIN/MIN": "GUMMI BEAR MULTIVITAMIN/MIN",
              "MULTIVITAMIN GUMMIES CHILDRENS": "MULTIVITAMIN GUMMIES CHILDRENS"
            }
          }
        },
        "Pediatric Multiple Vitamins": {
          "Pediatric Multiple Vitamins": {
            "ATLANTIC BIOLOGICALS": {
              "MULTI-DELYN": "MULTI-DELYN"
            },
            "BAXTER HEALTHCARE CORP": {
              "INFUVITE PEDIATRIC": "INFUVITE PEDIATRIC"
            },
            "DELIZ PHARMACEUTICAL CORP": {
              "PEDIAVIT": "PEDIAVIT"
            },
            "EQUALINE": {
              "EQL CHILDRENS MULTIVITAMINS": "EQL CHILDRENS MULTIVITAMINS"
            },
            "GENSAVIS PHARMACEUTICALS": {
              "NOVAMV PEDIATRIC MULTI-VITAMIN": "NOVAMV PEDIATRIC MULTI-VITAMIN"
            },
            "I-HEALTH": {
              "CULTURELLE KIDS COMPLETE": "CULTURELLE KIDS COMPLETE"
            },
            "NOVAPLUS/BAXTER HEALTHCARE": {
              "INFUVITE PEDIATRIC": "INFUVITE PEDIATRIC"
            },
            "PFIZER U.S.": {
              "M.V.I. PEDIATRIC": "M.V.I. PEDIATRIC"
            },
            "RUGBY LABORATORIES": {
              "MULTIVITAMIN CHILDRENS": "MULTIVITAMIN CHILDRENS",
              "MULTIVITAMIN INFANT & TODDLER": "MULTIVITAMIN INFANT & TODDLER"
            },
            "SILARX": {
              "MULTI-DELYN": "MULTI-DELYN"
            },
            "SIMPLE DIAGNOSTICS": {
              "PC PEDIATRIC POLY-VITAMIN DROP": "PC PEDIATRIC POLY-VITAMIN DROP"
            }
          },
          "Pediatric Multiple Vitamins w/ C": {
            "AIDAREX PHARMACEUTICALS": {
              "POLYVITAMIN": "POLYVITAMIN"
            },
            "AKRON PHARMA": {
              "POLY-VITE PEDIATRIC": "POLY-VITE PEDIATRIC"
            },
            "BAYSHORE PHARMACEUTICALS": {
              "BPROTECTED PEDIA POLY-VITE": "BPROTECTED PEDIA POLY-VITE"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "POLYVITAMIN": "POLYVITAMIN"
            },
            "M J NUTR": {
              "POLY-VI-SOL": "POLY-VI-SOL"
            },
            "MAJOR PHARMACEUTICALS": {
              "POLY-VITA": "POLY-VITA"
            },
            "NAT-RUL HEALTH PRODUCTS": {
              "AMINALS": "AMINALS"
            },
            "RELIABLE 1 LABS": {
              "POLY-VITA": "POLY-VITA"
            },
            "RUGBY LABORATORIES": {
              "POLYVITAMIN": "POLYVITAMIN"
            }
          },
          "Pediatric Multiple Vitamins w/ C & FA": {
            "AMERISOURCE BERGEN DRUGS": {
              "GNP LITTLE ONES CHILDRENS": "GNP LITTLE ONES CHILDRENS"
            },
            "AUBURN PHARMACEUTICAL": {
              "CHILDRENS CHEWABLE VITAMINS": "CHILDRENS CHEWABLE VITAMINS"
            },
            "BAYER CONSUMER": {
              "FLINSTONES GUMMIES OMEGA-3 DHA": "FLINSTONES GUMMIES OMEGA-3 DHA",
              "FLINTSTONES PLUS CALCIUM": "FLINTSTONES PLUS CALCIUM",
              "FLINTSTONES/MY FIRST": "FLINTSTONES/MY FIRST",
              "ONE-A-DAY VITACRAVES+OMEGA-3": "ONE-A-DAY VITACRAVES+OMEGA-3"
            },
            "BERGEN BRUNSWIG": {
              "GNP ANIMAL SHAPES": "GNP ANIMAL SHAPES",
              "GNP LITTLE ONES CHILDRENS": "GNP LITTLE ONES CHILDRENS"
            },
            "CHAIN DRUG CONSORTIUM": {
              "KIDS VITAMINS": "KIDS VITAMINS"
            },
            "GENDOSE PHARMACEUTICALS": {
              "CHILDRENS CHEWABLE VITAMINS": "CHILDRENS CHEWABLE VITAMINS"
            },
            "GERI-CARE": {
              "CHILDRENS CHEWABLE VITAMINS": "CHILDRENS CHEWABLE VITAMINS"
            },
            "LEADER BRAND PRODUCTS": {
              "CHILDRENS MULTIVITAMIN": "CHILDRENS MULTIVITAMIN",
              "MULTIVITAMIN CHILDRENS": "MULTIVITAMIN CHILDRENS"
            },
            "MAGNO-HUMPHRIES LABORATORIES": {
              "FRUITY CHEWS": "FRUITY CHEWS"
            },
            "MAJOR PHARMACEUTICALS": {
              "ANIMAL SHAPES": "ANIMAL SHAPES"
            },
            "MARLEX PHARMACEUTICALS": {
              "CHILDRENS CHEWABLE VITAMINS": "CHILDRENS CHEWABLE VITAMINS"
            },
            "MASON VITAMINS": {
              "LITTLE ANIMALS": "LITTLE ANIMALS"
            },
            "MCKESSON SUNMARK": {
              "SM ANIMAL SHAPES KIDS FIRST": "SM ANIMAL SHAPES KIDS FIRST"
            },
            "NATIONAL VITAMIN": {
              "CHILDRENS CHEWABLE VITAMINS": "CHILDRENS CHEWABLE VITAMINS",
              "DINO-LIFE": "DINO-LIFE"
            },
            "NATURES BOUNTY": {
              "BOUNTY BEARS/C": "BOUNTY BEARS/C"
            },
            "PDRX PHARMACEUTICAL": {
              "FRUITY CHEWABLES MULTIVITAMIN": "FRUITY CHEWABLES MULTIVITAMIN"
            },
            "PLUS PHARMA": {
              "ANIMAL CHEWS": "ANIMAL CHEWS",
              "CHILDRENS CHEWABLE MULTI VITS": "CHILDRENS CHEWABLE MULTI VITS"
            },
            "RUGBY LABORATORIES": {
              "CHEWABLE VITE CHILDRENS": "CHEWABLE VITE CHILDRENS",
              "POLY VITAMIN": "POLY VITAMIN"
            },
            "WINDMILL CONSUMER PRODUCTS": {
              "BITE-A-MINS": "BITE-A-MINS"
            }
          },
          "Pediatric Multiple Vitamins w/ Extra C & FA": {
            "21ST CENTURY HEALTHCARE": {
              "ZOO FRIENDS/EXTRA C": "ZOO FRIENDS/EXTRA C"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP CHILDRENS CHEWABLES/EX C": "GNP CHILDRENS CHEWABLES/EX C"
            },
            "BAYER CONSUMER": {
              "FLINTSTONES/EXTRA C": "FLINTSTONES/EXTRA C"
            },
            "BERGEN BRUNSWIG": {
              "GNP ANIMAL SHAPES PLUS EXTRA C": "GNP ANIMAL SHAPES PLUS EXTRA C",
              "GNP CHILDRENS CHEWABLES/EX C": "GNP CHILDRENS CHEWABLES/EX C"
            },
            "CHAIN DRUG CONSORTIUM": {
              "KIDS VITAMINS PLUS EXTRA C": "KIDS VITAMINS PLUS EXTRA C"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC CHILDRENS VITAMINS/EXTRA C": "QC CHILDRENS VITAMINS/EXTRA C"
            },
            "EQUALINE": {
              "EQL CHILDRENS C": "EQL CHILDRENS C"
            },
            "LEADER BRAND PRODUCTS": {
              "ZOO FRIENDS PLUS EXTRA C": "ZOO FRIENDS PLUS EXTRA C"
            },
            "NATIONAL VITAMIN": {
              "DINO-LIFE W/EXTRA C": "DINO-LIFE W/EXTRA C"
            },
            "WALGREENS": {
              "LAND BEFORE TIME MULTIVITAMIN": "LAND BEFORE TIME MULTIVITAMIN"
            }
          }
        },
        "Pediatric Multiple Vitamins & Minerals w/ Fluoride": {
          "Pediatric Multiple Vitamins & Minerals w/ Fluoride": {
            "BONGEO PHARMACEUTICALS": {
              "FLORIVA": "FLORIVA"
            }
          },
          "Pediatric Multiple Vitamins w/Fluoride-Iron-Zinc": {
            "GM PHARMACEUTICALS": {
              "TEXAVITE LQ": "TEXAVITE LQ"
            }
          }
        },
        "Pediatric Vitamins": {
          "Pediatric Vitamins": {
            "NATIONAL VITAMIN": {
              "HONEY BEARS": "HONEY BEARS"
            }
          },
          "Pediatric Vitamins A & D w/ C": {
            "AKRON PHARMA": {
              "TRI-VITE PEDIATRIC": "TRI-VITE PEDIATRIC"
            },
            "BAYSHORE PHARMACEUTICALS": {
              "BPROTECTED PEDIA TRI-VITE": "BPROTECTED PEDIA TRI-VITE"
            },
            "M J NUTR": {
              "TRI-VI-SOL A/C/D": "TRI-VI-SOL A/C/D"
            },
            "MAJOR PHARMACEUTICALS": {
              "TRI-VITA": "TRI-VITA"
            },
            "RUGBY LABORATORIES": {
              "TRI-VITAMIN": "TRI-VITAMIN",
              "TRI-VITAMIN INFANT & TODDLER": "TRI-VITAMIN INFANT & TODDLER"
            },
            "SIMPLE DIAGNOSTICS": {
              "PC PEDIATRIC TRI-VITAMIN DROPS": "PC PEDIATRIC TRI-VITAMIN DROPS"
            }
          }
        },
        "Prenatal Vitamins": {
          "Prenatal MV & Min w/FE-FA": {
            "21ST CENTURY HEALTHCARE": {
              "PRENATAL": "PRENATAL"
            },
            "A-S MEDICATION SOLUTIONS": {
              "PNV PRENATAL PLUS MULTIVITAMIN": "PNV PRENATAL PLUS MULTIVITAMIN",
              "PRENATAL": "PRENATAL",
              "PRENATAL PLUS": "PRENATAL PLUS"
            },
            "ACELLA PHARMACEUTICALS": {
              "BP MULTINATAL PLUS": "BP MULTINATAL PLUS",
              "PNV-OMEGA": "PNV-OMEGA",
              "PNV-SELECT": "PNV-SELECT",
              "PNV-TOTAL": "PNV-TOTAL",
              "PRENAISSANCE HARMONY DHA": "PRENAISSANCE HARMONY DHA"
            },
            "ADVANCED MEDICAL ENTERPRISES": {
              "ATABEX": "ATABEX",
              "ATABEX EC": "ATABEX EC",
              "ATABEX OB": "ATABEX OB"
            },
            "AIDAREX PHARMACEUTICALS": {
              "PRENATAL LOW IRON": "PRENATAL LOW IRON"
            },
            "AKRON PHARMA": {
              "ONE VITE WOMENS": "ONE VITE WOMENS",
              "ONE VITE WOMENS PLUS": "ONE VITE WOMENS PLUS"
            },
            "ALFASIGMA USA": {
              "NEEVO DHA": "NEEVO DHA"
            },
            "ALLEGIS PHARMACEUTICALS": {
              "MARNATAL-F": "MARNATAL-F"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP DAILY PRENATAL": "GNP DAILY PRENATAL",
              "GNP PRENATAL": "GNP PRENATAL"
            },
            "ARCHON": {
              "NUTRICION PORVIDA": "NUTRICION PORVIDA"
            },
            "ASCEND THERAPEUTICS": {
              "VITATHELY WITH GINGER": "VITATHELY WITH GINGER"
            },
            "AUBURN PHARMACEUTICAL": {
              "PRENATAL LOW IRON": "PRENATAL LOW IRON"
            },
            "AUM PHARMACEUTICALS": {
              "NEONATAL COMPLETE": "NEONATAL COMPLETE",
              "NEONATAL FE": "NEONATAL FE",
              "PRENATVITE COMPLETE": "PRENATVITE COMPLETE",
              "PRENATVITE PLUS": "PRENATVITE PLUS",
              "PRENATVITE RX": "PRENATVITE RX"
            },
            "AVION PHARMACEUTICALS": {
              "PRENATE ELITE": "PRENATE ELITE",
              "PRENATE ESSENTIAL": "PRENATE ESSENTIAL",
              "PRENATE STAR": "PRENATE STAR",
              "PRIMACARE": "PRIMACARE"
            },
            "AVKARE": {
              "PRENATAL PLUS": "PRENATAL PLUS"
            },
            "BASIC DRUGS": {
              "PRENATAL VITAMINS": "PRENATAL VITAMINS"
            },
            "BASIEM": {
              "AZESCHEW PRENATAL/POSTNATAL": "AZESCHEW PRENATAL/POSTNATAL"
            },
            "BAYER CONSUMER": {
              "ONE-A-DAY WOMENS PRENATAL": "ONE-A-DAY WOMENS PRENATAL",
              "ONE-A-DAY WOMENS PRENATAL 1": "ONE-A-DAY WOMENS PRENATAL 1"
            },
            "BERGEN BRUNSWIG": {
              "GNP PRENATAL": "GNP PRENATAL"
            },
            "BLENHEIM PHARMACAL": {
              "PRENATAL PLUS/IRON": "PRENATAL PLUS/IRON"
            },
            "BOCAGREENMD": {
              "PRENA1 PEARL": "PRENA1 PEARL"
            },
            "BRECKENRIDGE": {
              "VINATE CARE": "VINATE CARE",
              "VINATE DHA RF": "VINATE DHA RF",
              "VINATE II": "VINATE II",
              "VINATE M": "VINATE M",
              "VINATE ONE": "VINATE ONE"
            },
            "BUREL PHARMACEUTICALS": {
              "RELNATE DHA": "RELNATE DHA"
            },
            "CARDINAL HEALTH REPACKAGING SO": {
              "PRENATAL": "PRENATAL"
            },
            "CENTURION LABS": {
              "C-NATE DHA": "C-NATE DHA",
              "RELNATE DHA": "RELNATE DHA"
            },
            "CHAIN DRUG CONSORTIUM": {
              "PRENATAL FORMULA": "PRENATAL FORMULA"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC PRENATAL": "QC PRENATAL"
            },
            "CONTRACT PHARMACAL CORPORATION": {
              "CO-NATAL FA": "CO-NATAL FA"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS PRENATAL": "CVS PRENATAL"
            },
            "CYPRESS PHARMACEUTICAL": {
              "PNV PRENATAL PLUS MULTIVITAMIN": "PNV PRENATAL PLUS MULTIVITAMIN",
              "PRENATABS FA": "PRENATABS FA",
              "PRENATABS RX": "PRENATABS RX",
              "PRENATAL 19": "PRENATAL 19",
              "PRENATAL-U": "PRENATAL-U",
              "TRINATE": "TRINATE"
            },
            "DUCHESNAY USA": {
              "MTERYTI": "MTERYTI",
              "MTERYTI FOLIC 5": "MTERYTI FOLIC 5"
            },
            "ECKSON LABS": {
              "DUET DHA 400": "DUET DHA 400",
              "DUET DHA BALANCED": "DUET DHA BALANCED",
              "NATACHEW": "NATACHEW"
            },
            "EQUALINE": {
              "EQL PRENATAL FORMULA": "EQL PRENATAL FORMULA"
            },
            "EXELTIS USA": {
              "SELECT-OB": "SELECT-OB",
              "VITAFOL GUMMIES": "VITAFOL GUMMIES",
              "VITAFOL-NANO": "VITAFOL-NANO",
              "VITAFOL-OB": "VITAFOL-OB"
            },
            "FOXLAND PHARMACEUTICALS": {
              "TRINAZ": "TRINAZ"
            },
            "FREEDA VITAMINS": {
              "KPN PRENATAL": "KPN PRENATAL",
              "PRENATAL": "PRENATAL",
              "PRENATAL FORMULA A-FREE": "PRENATAL FORMULA A-FREE",
              "PRENATAL ONE DAILY": "PRENATAL ONE DAILY"
            },
            "GEISS DESTIN & DUNN": {
              "GOODSENSE PRENATAL VITAMINS": "GOODSENSE PRENATAL VITAMINS"
            },
            "GENDOSE PHARMACEUTICALS": {
              "PRENATAL": "PRENATAL"
            },
            "GERI-CARE": {
              "PRENATAL": "PRENATAL"
            },
            "INTEGRATIVE THERAPEUTICS": {
              "CLINICAL NUTRIENTS PRENATAL": "CLINICAL NUTRIENTS PRENATAL"
            },
            "JAYMAC PHARMACEUTICAL LLC": {
              "ENBRACE HR": "ENBRACE HR",
              "VIVA DHA": "VIVA DHA"
            },
            "KAISER FOUNDATION HOSPITAL": {
              "KP PRENATAL MULTIVITAMINS": "KP PRENATAL MULTIVITAMINS"
            },
            "KIRKMAN SALES COMPANY": {
              "PERRY PRENATAL": "PERRY PRENATAL"
            },
            "LEADER BRAND PRODUCTS": {
              "PRENATAL": "PRENATAL"
            },
            "LLORENS PHARMACEUTICAL": {
              "NATALVIT": "NATALVIT"
            },
            "MAGNO-HUMPHRIES LABORATORIES": {
              "PRENATAL FORTE": "PRENATAL FORTE"
            },
            "MAJOR PHARMACEUTICALS": {
              "PRENATAL": "PRENATAL"
            },
            "MATERNAL SCIENCE": {
              "HEALTHY MAMA BE WELL ROUNDED": "HEALTHY MAMA BE WELL ROUNDED"
            },
            "MAYNE PHARMA": {
              "FOLCAPS OMEGA 3": "FOLCAPS OMEGA 3"
            },
            "MCKESSON": {
              "HM ONE DAILY PRENATAL": "HM ONE DAILY PRENATAL",
              "HM PRENATAL": "HM PRENATAL"
            },
            "MCKESSON SUNMARK": {
              "SM ONE DAILY PRENATAL": "SM ONE DAILY PRENATAL",
              "SM PRENATAL VITAMINS": "SM PRENATAL VITAMINS"
            },
            "ME PHARMACEUTICALS": {
              "MYNATAL": "MYNATAL",
              "MYNATAL ADVANCE": "MYNATAL ADVANCE",
              "MYNATAL PLUS": "MYNATAL PLUS",
              "MYNATAL-Z": "MYNATAL-Z",
              "MYNATE 90 PLUS": "MYNATE 90 PLUS"
            },
            "MEDA PHARMACEUTICALS": {
              "PREFERA OB": "PREFERA OB"
            },
            "MEDECOR PHARMA": {
              "TRICARE": "TRICARE",
              "TRICARE PRENATAL": "TRICARE PRENATAL",
              "TRICARE PRENATAL 1": "TRICARE PRENATAL 1",
              "TRICARE PRENATAL COMPLEAT": "TRICARE PRENATAL COMPLEAT",
              "TRICARE PRENATAL DHA ONE": "TRICARE PRENATAL DHA ONE"
            },
            "MEDICINE SHOPPE": {
              "PRENATAL VITAMINS": "PRENATAL VITAMINS"
            },
            "METHOD PHARMACEUTICALS": {
              "DOTHELLE DHA": "DOTHELLE DHA",
              "M-NATAL PLUS": "M-NATAL PLUS",
              "THRIVITE RX": "THRIVITE RX"
            },
            "MISSION": {
              "CITRANATAL B-CALM": "CITRANATAL B-CALM",
              "CITRANATAL BLOOM": "CITRANATAL BLOOM",
              "CITRANATAL RX": "CITRANATAL RX"
            },
            "MYLAN SPECIALTY L.P.": {
              "NATELLE ONE": "NATELLE ONE",
              "PREFERA OB": "PREFERA OB"
            },
            "NAT-RUL HEALTH PRODUCTS": {
              "NAT-RUL PRENATAL VITAMINS": "NAT-RUL PRENATAL VITAMINS"
            },
            "NATIONAL BIO GREEN SCIENCES": {
              "ZALVIT": "ZALVIT"
            },
            "NATIONAL VITAMIN": {
              "PRENATAL FORMULA": "PRENATAL FORMULA",
              "PRENATAL/IRON": "PRENATAL/IRON"
            },
            "NATIONWIDE LABORATORIES": {
              "KOSHER PRENATAL PLUS IRON": "KOSHER PRENATAL PLUS IRON",
              "PRENATAL 19": "PRENATAL 19",
              "PRENATAL PLUS IRON": "PRENATAL PLUS IRON"
            },
            "NATURES BOUNTY": {
              "PRE-NATAL FORMULA": "PRE-NATAL FORMULA",
              "YOUR LIFE MULTI PRENATAL": "YOUR LIFE MULTI PRENATAL"
            },
            "NEOMED PHARMACEUTICAL": {
              "NEONATAL COMPLETE": "NEONATAL COMPLETE",
              "NEONATAL PLUS": "NEONATAL PLUS",
              "NEONATAL VITAMIN": "NEONATAL VITAMIN"
            },
            "NIVAGEN PHARMACEUTICALS": {
              "NIVA-PLUS": "NIVA-PLUS"
            },
            "NNODUM CORPORATION": {
              "INATAL ADVANCE": "INATAL ADVANCE",
              "INATAL GT": "INATAL GT",
              "INATAL ULTRA": "INATAL ULTRA",
              "PRENATAL VITAMIN PLUS LOW IRON": "PRENATAL VITAMIN PLUS LOW IRON"
            },
            "PATRIN PHARMA": {
              "PRENATAL": "PRENATAL"
            },
            "PDRX PHARMACEUTICAL": {
              "PRENATAL": "PRENATAL",
              "PRENATAL FORMULA": "PRENATAL FORMULA",
              "RIGHT STEP PRENATAL": "RIGHT STEP PRENATAL"
            },
            "PHARMAVITE": {
              "MULTI PRENATAL": "MULTI PRENATAL",
              "PRENATAL MULTI +DHA": "PRENATAL MULTI +DHA"
            },
            "PHARMEDIX": {
              "PRENATAL PLUS/IRON": "PRENATAL PLUS/IRON"
            },
            "PHARMICS": {
              "O-CAL FA": "O-CAL FA",
              "O-CAL PRENATAL": "O-CAL PRENATAL"
            },
            "PLUS PHARMA": {
              "PRENATAL": "PRENATAL",
              "PRENATAL VITAMIN": "PRENATAL VITAMIN"
            },
            "PREFERRED PHARMACEUTICALS": {
              "PRENATAL PLUS IRON": "PRENATAL PLUS IRON"
            },
            "PRO-PHARMA LLC": {
              "PROFE FORTE": "PROFE FORTE"
            },
            "PRONOVA CORPORATION": {
              "OBTREX": "OBTREX",
              "OBTREX DHA": "OBTREX DHA"
            },
            "PRUGEN": {
              "BAL-CARE DHA": "BAL-CARE DHA",
              "NEWGEN": "NEWGEN"
            },
            "PUBLIX SUPER MARKETS INC.": {
              "PX PRENATAL MULTIVITAMINS": "PX PRENATAL MULTIVITAMINS"
            },
            "PURE TEK": {
              "PRENATRIX": "PRENATRIX",
              "PUREFE OB PLUS": "PUREFE OB PLUS"
            },
            "QUALITY CARE": {
              "PRENATAL": "PRENATAL",
              "PRENATAL/FOLIC ACID": "PRENATAL/FOLIC ACID"
            },
            "R A MCNEIL": {
              "EZFE FORTE": "EZFE FORTE",
              "M-VIT": "M-VIT"
            },
            "RE-POR LABS": {
              "VITA-PREN": "VITA-PREN"
            },
            "REDMONT PHARMACEUTICALS": {
              "PREGENNA": "PREGENNA"
            },
            "REXALL SUNDOWN": {
              "PRENATAL": "PRENATAL",
              "PRENATAL/OMEGA-3/FA/IRON": "PRENATAL/OMEGA-3/FA/IRON"
            },
            "RICHMOND PHARMACEUTICALS": {
              "PRENATAL VITAMIN AND MINERAL": "PRENATAL VITAMIN AND MINERAL"
            },
            "RITE AID CORPORATION": {
              "PA PRENATAL FORMULA": "PA PRENATAL FORMULA",
              "RA ONE DAILY": "RA ONE DAILY",
              "RA PRENATAL": "RA PRENATAL",
              "RA PRENATAL FORMULA": "RA PRENATAL FORMULA"
            },
            "RUGBY LABORATORIES": {
              "CLASSIC PRENATAL": "CLASSIC PRENATAL",
              "PRENATAL VITAMINS": "PRENATAL VITAMINS"
            },
            "SAFECOR HEALTH": {
              "PRENATAL/IRON": "PRENATAL/IRON"
            },
            "SANCILIO": {
              "PNV FOLIC ACID + IRON": "PNV FOLIC ACID + IRON",
              "PNV PRENATAL PLUS MULTIVIT+DHA": "PNV PRENATAL PLUS MULTIVIT+DHA",
              "PNV PRENATAL PLUS MULTIVITAMIN": "PNV PRENATAL PLUS MULTIVITAMIN",
              "PRENATA": "PRENATA"
            },
            "SETON PHARMACEUTICALS": {
              "PRENATAL PLUS": "PRENATAL PLUS",
              "SE-NATAL 19": "SE-NATAL 19",
              "SE-TAN DHA": "SE-TAN DHA"
            },
            "SEYER PHARMATEC": {
              "OBSTETRIX DHA": "OBSTETRIX DHA",
              "OBSTETRIX EC": "OBSTETRIX EC"
            },
            "SOLUBIOMIX LLC": {
              "AZESCO": "AZESCO"
            },
            "STERLING KNIGHT PHARMACEUTICAL": {
              "PRENARA": "PRENARA"
            },
            "THERALOGIX": {
              "THERANATAL CORE NUTRITION": "THERANATAL CORE NUTRITION"
            },
            "TRIGEN LABORATORIES": {
              "COMPLETENATE": "COMPLETENATE",
              "ELITE-OB": "ELITE-OB",
              "FOLIVANE-OB": "FOLIVANE-OB",
              "TARON-BC": "TARON-BC",
              "TARON-C DHA": "TARON-C DHA",
              "TL FOLATE": "TL FOLATE",
              "TL-CARE DHA": "TL-CARE DHA",
              "TRI-TABS DHA": "TRI-TABS DHA",
              "TRIADVANCE": "TRIADVANCE",
              "TRINATAL GT": "TRINATAL GT",
              "TRINATAL RX 1": "TRINATAL RX 1",
              "ULTIMATECARE ONE": "ULTIMATECARE ONE",
              "ULTIMATECARE ONE NF": "ULTIMATECARE ONE NF",
              "VENA-BAL DHA": "VENA-BAL DHA",
              "VOL-NATE": "VOL-NATE",
              "VOL-PLUS": "VOL-PLUS",
              "VOL-TAB RX": "VOL-TAB RX",
              "ZATEAN-PN PLUS": "ZATEAN-PN PLUS"
            },
            "TRIMARC LABORATORIES": {
              "RIGHT STEP PRENATAL": "RIGHT STEP PRENATAL"
            },
            "U S PHARMACEUTICAL": {
              "CONCEPT DHA": "CONCEPT DHA",
              "CONCEPT OB": "CONCEPT OB",
              "PROVIDA OB": "PROVIDA OB"
            },
            "UPSPRING": {
              "UPSPRING PRENATAL COMPLETE": "UPSPRING PRENATAL COMPLETE"
            },
            "VERTICAL PHARMACEUTICAL": {
              "OB COMPLETE": "OB COMPLETE",
              "OB COMPLETE ADVANCED": "OB COMPLETE ADVANCED",
              "OB COMPLETE ONE": "OB COMPLETE ONE",
              "OB COMPLETE PETITE": "OB COMPLETE PETITE",
              "OB COMPLETE PREMIER": "OB COMPLETE PREMIER",
              "OB COMPLETE/DHA": "OB COMPLETE/DHA"
            },
            "VILVET PHARMACEUTICAL": {
              "VIL-RX": "VIL-RX"
            },
            "VIRTUS PHARMACEUTICALS": {
              "PNV TABS 29-1": "PNV TABS 29-1",
              "PREPLUS": "PREPLUS",
              "PRETAB": "PRETAB",
              "VIRT NATE": "VIRT NATE",
              "VIRT-NATE DHA": "VIRT-NATE DHA",
              "VIRT-PN": "VIRT-PN",
              "VIRT-PN PLUS": "VIRT-PN PLUS",
              "VP-HEME OB": "VP-HEME OB",
              "VP-PNV-DHA": "VP-PNV-DHA"
            },
            "VIRTUS PHARMACEUTICALS OPCO": {
              "CALCIUM PNV": "CALCIUM PNV",
              "PNV FE FUM/DOCUSATE/FOLIC ACID": "PNV FE FUM/DOCUSATE/FOLIC ACID",
              "PNV TABS 29-1": "PNV TABS 29-1",
              "PNV-VP-U": "PNV-VP-U",
              "PREPLUS": "PREPLUS",
              "PRETAB": "PRETAB",
              "VIRT NATE": "VIRT NATE",
              "VIRT-ADVANCE": "VIRT-ADVANCE",
              "VIRT-C DHA": "VIRT-C DHA",
              "VIRT-PN": "VIRT-PN",
              "VIRT-PN PLUS": "VIRT-PN PLUS",
              "VIRT-VITE GT": "VIRT-VITE GT",
              "VP-HEME OB + DHA": "VP-HEME OB + DHA"
            },
            "VITAMEDMD": {
              "VITAPEARL": "VITAPEARL"
            },
            "WAL-MART": {
              "PRENATAL": "PRENATAL"
            },
            "WALGREENS": {
              "PRENATAL": "PRENATAL",
              "PRENATAL COMPLETE": "PRENATAL COMPLETE"
            },
            "WESTMINSTER PHARMACEUTICALS": {
              "WESTAB PLUS": "WESTAB PLUS"
            },
            "WH NUTRITIONALS": {
              "HEMENATAL OB": "HEMENATAL OB",
              "HEMENATAL OB + DHA": "HEMENATAL OB + DHA"
            },
            "WOMEN'S CHOICE PHARMACEUTICALS": {
              "NESTABS": "NESTABS",
              "NESTABS DHA": "NESTABS DHA"
            }
          },
          "Prenatal MV & Min w/FE-FA & Coenzyme Q10": {
            "THERALOGIX": {
              "THERANATAL OVAVITE": "THERANATAL OVAVITE"
            }
          },
          "Prenatal MV & Min w/FE-FA-CA-Omega 3 Fish Oil": {
            "PRUGEN": {
              "PR NATAL 400": "PR NATAL 400",
              "PR NATAL 400 EC": "PR NATAL 400 EC",
              "PR NATAL 430": "PR NATAL 430",
              "PR NATAL 430 EC": "PR NATAL 430 EC"
            },
            "TRIGEN LABORATORIES": {
              "COMPLETE NATAL DHA": "COMPLETE NATAL DHA",
              "TRIVEEN-DUO DHA": "TRIVEEN-DUO DHA"
            },
            "UPSPRING": {
              "PRENATAL + COMPLETE MULTI": "PRENATAL + COMPLETE MULTI"
            },
            "WOMEN'S CHOICE PHARMACEUTICALS": {
              "NESTABS ABC": "NESTABS ABC"
            }
          },
          "Prenatal MV & Min w/FE-FA-DHA": {
            "21ST CENTURY HEALTHCARE": {
              "PRENATAL MULTIVITAMIN + DHA": "PRENATAL MULTIVITAMIN + DHA"
            },
            "ABBOTT NUTRITION": {
              "SIMILAC PRENATAL EARLY SHIELD": "SIMILAC PRENATAL EARLY SHIELD"
            },
            "ACELLA PHARMACEUTICALS": {
              "INFANATE BALANCE": "INFANATE BALANCE",
              "PNV-DHA": "PNV-DHA",
              "PNV-DHA+DOCUSATE": "PNV-DHA+DOCUSATE",
              "PRENAISSANCE": "PRENAISSANCE",
              "PRENAISSANCE BALANCE": "PRENAISSANCE BALANCE",
              "PRENAISSANCE PLUS": "PRENAISSANCE PLUS"
            },
            "ACTAVIS PHARMA": {
              "PREQUE 10": "PREQUE 10"
            },
            "AUM PHARMACEUTICALS": {
              "NEONATAL + DHA": "NEONATAL + DHA"
            },
            "AVION PHARMACEUTICALS": {
              "PRENATE DHA": "PRENATE DHA",
              "PRENATE ENHANCE": "PRENATE ENHANCE",
              "PRENATE ESSENTIAL": "PRENATE ESSENTIAL",
              "PRENATE MINI": "PRENATE MINI",
              "PRENATE PIXIE": "PRENATE PIXIE",
              "PRENATE RESTORE": "PRENATE RESTORE"
            },
            "BOCAGREENMD": {
              "PRENA 1 TRUE": "PRENA 1 TRUE"
            },
            "CARWIN ASSOCIATES": {
              "TRISTART DHA": "TRISTART DHA",
              "TRISTART ONE": "TRISTART ONE"
            },
            "CHAIN DRUG CONSORTIUM": {
              "PRENATAL PLUS DHA": "PRENATAL PLUS DHA"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS PRENATAL MULTI+DHA": "CVS PRENATAL MULTI+DHA",
              "CVS WOMENS PRENATAL+DHA": "CVS WOMENS PRENATAL+DHA"
            },
            "EXELTIS USA": {
              "SELECT-OB+DHA": "SELECT-OB+DHA",
              "STUART ONE": "STUART ONE",
              "VITAFOL FE+": "VITAFOL FE+",
              "VITAFOL ULTRA": "VITAFOL ULTRA",
              "VITAFOL-OB+DHA": "VITAFOL-OB+DHA",
              "VITAFOL-ONE": "VITAFOL-ONE"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "CENTRUM SPECIALIST PRENATAL": "CENTRUM SPECIALIST PRENATAL"
            },
            "GM PHARMACEUTICALS": {
              "ACTIVE OB": "ACTIVE OB"
            },
            "I-HEALTH": {
              "BRAINSTRONG PRENATAL": "BRAINSTRONG PRENATAL"
            },
            "M J NUTR": {
              "ENFAMIL EXPECTA": "ENFAMIL EXPECTA"
            },
            "MACOVEN PHARMACEUTICALS": {
              "MACNATAL CN DHA": "MACNATAL CN DHA"
            },
            "MAYNE PHARMA": {
              "FOLCAL DHA": "FOLCAL DHA"
            },
            "MEDECOR PHARMA": {
              "TRICARE PRENATAL": "TRICARE PRENATAL"
            },
            "METHOD PHARMACEUTICALS": {
              "FOCALGIN 90 DHA": "FOCALGIN 90 DHA",
              "FOCALGIN CA": "FOCALGIN CA",
              "RULAVITE DHA": "RULAVITE DHA"
            },
            "MISSION": {
              "CITRANATAL 90 DHA": "CITRANATAL 90 DHA",
              "CITRANATAL ASSURE": "CITRANATAL ASSURE",
              "CITRANATAL BLOOM DHA": "CITRANATAL BLOOM DHA",
              "CITRANATAL DHA": "CITRANATAL DHA",
              "CITRANATAL ESSENCE": "CITRANATAL ESSENCE",
              "CITRANATAL HARMONY": "CITRANATAL HARMONY",
              "CITRANATAL MEDLEY": "CITRANATAL MEDLEY"
            },
            "MYLAN SPECIALTY L.P.": {
              "PREFERAOB +DHA": "PREFERAOB +DHA",
              "PREFERAOB ONE": "PREFERAOB ONE"
            },
            "PHARMA ASSOCIATE CONSULTANTS": {
              "CADEAU DHA": "CADEAU DHA"
            },
            "PHARMAVITE": {
              "PRENATAL MULTI +DHA": "PRENATAL MULTI +DHA"
            },
            "PRINCETON RESEARCH": {
              "ULTRA PRENATAL + DHA": "ULTRA PRENATAL + DHA"
            },
            "PRONOVA CORPORATION": {
              "FOLET DHA": "FOLET DHA",
              "FOLET ONE": "FOLET ONE"
            },
            "PURE TEK": {
              "PNV OB+DHA": "PNV OB+DHA",
              "PRENATAL + DHA": "PRENATAL + DHA"
            },
            "R3 PHAMACEUTICALS": {
              "R-NATAL OB": "R-NATAL OB"
            },
            "SEYER PHARMATEC": {
              "OBSTETRIX ONE": "OBSTETRIX ONE"
            },
            "THERALOGIX": {
              "THERANATAL COMPLETE": "THERANATAL COMPLETE",
              "THERANATAL ONE": "THERANATAL ONE"
            },
            "TRIGEN LABORATORIES": {
              "TARON-PREX": "TARON-PREX",
              "TL-SELECT": "TL-SELECT",
              "TRIVEEN-PRX RNF": "TRIVEEN-PRX RNF",
              "VEMAVITE-PRX 2": "VEMAVITE-PRX 2",
              "ZATEAN-CH": "ZATEAN-CH",
              "ZATEAN-PN DHA": "ZATEAN-PN DHA"
            },
            "U S PHARMACEUTICAL": {
              "PROVIDA DHA": "PROVIDA DHA"
            },
            "VERTICAL PHARMACEUTICAL": {
              "NEXA PLUS": "NEXA PLUS",
              "OB COMPLETE GOLD": "OB COMPLETE GOLD"
            },
            "VIRTUS PHARMACEUTICALS": {
              "VIRT-PN DHA": "VIRT-PN DHA",
              "VP-CH-PNV": "VP-CH-PNV",
              "VP-HEME ONE": "VP-HEME ONE"
            },
            "VIRTUS PHARMACEUTICALS OPCO": {
              "EXTRA-VIRT PLUS DHA": "EXTRA-VIRT PLUS DHA",
              "VIRT-PN DHA": "VIRT-PN DHA",
              "VIRT-SELECT": "VIRT-SELECT",
              "VIRTPREX": "VIRTPREX",
              "VP-CH PLUS": "VP-CH PLUS",
              "VP-HEME ONE": "VP-HEME ONE"
            },
            "VITAMEDMD": {
              "COMPLETE PRENATAL/DHA": "COMPLETE PRENATAL/DHA",
              "PRENATAL 1": "PRENATAL 1",
              "VITAMEDMD ONE RX/QUATREFOLIC": "VITAMEDMD ONE RX/QUATREFOLIC",
              "VITAMEDMD PLUS RX/QUATREFOLIC": "VITAMEDMD PLUS RX/QUATREFOLIC",
              "VITATRUE": "VITATRUE"
            },
            "WALGREENS": {
              "PRENATAL MULTI +DHA": "PRENATAL MULTI +DHA",
              "PRENATAL MULTIVITAMIN PLUS DHA": "PRENATAL MULTIVITAMIN PLUS DHA",
              "PRENATAL+DHA": "PRENATAL+DHA"
            },
            "WEGMANS": {
              "WEGMANS COMPLETE PRENATAL+DHA": "WEGMANS COMPLETE PRENATAL+DHA"
            },
            "WESTMINSTER PHARMACEUTICALS": {
              "WESTGEL DHA": "WESTGEL DHA"
            },
            "WOMEN'S CHOICE PHARMACEUTICALS": {
              "NESTABS ONE": "NESTABS ONE"
            },
            "ZERXIS": {
              "LEVOMEFOLATE DHA": "LEVOMEFOLATE DHA"
            }
          },
          "Prenatal MV & Minerals w/ FA without Iron": {
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS PRENATAL GUMMY": "CVS PRENATAL GUMMY"
            }
          },
          "Prenatal MV & Minerals w/ FA-Omega Fatty Acids w/o Iron": {
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS PRENATAL GUMMY": "CVS PRENATAL GUMMY"
            }
          },
          "Prenatal MV & Minerals w/FA without Iron": {
            "AVION PHARMACEUTICALS": {
              "PRENATE": "PRENATE"
            },
            "BAYER CONSUMER": {
              "ONE A DAY PRENATAL": "ONE A DAY PRENATAL"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS PRENATAL GUMMY": "CVS PRENATAL GUMMY"
            },
            "SCHWABE NORTH AMERICA": {
              "ALIVE PRENATAL": "ALIVE PRENATAL"
            },
            "UPSPRING": {
              "PRENATAL + COMPLETE MULTI": "PRENATAL + COMPLETE MULTI"
            },
            "WAL-MART": {
              "PRENATAL ADULT GUMMY/DHA/FA": "PRENATAL ADULT GUMMY/DHA/FA"
            },
            "WALGREENS": {
              "PRENATAL GUMMIES/DHA & FA": "PRENATAL GUMMIES/DHA & FA"
            }
          },
          "Prenatal Vitamins": {
            "ACELLA PHARMACEUTICALS": {
              "BP FOLINATAL PLUS B": "BP FOLINATAL PLUS B",
              "PRENAISSANCE NEXT": "PRENAISSANCE NEXT",
              "PRENAISSANCE NEXT-B": "PRENAISSANCE NEXT-B"
            },
            "AUM PHARMACEUTICALS": {
              "NEONATAL 19": "NEONATAL 19"
            },
            "AVION PHARMACEUTICALS": {
              "PREMESISRX": "PREMESISRX",
              "PRENATE AM": "PRENATE AM"
            },
            "BOCAGREENMD": {
              "PRENA1": "PRENA1"
            },
            "EXELTIS USA": {
              "VITAFOL STRIPS": "VITAFOL STRIPS"
            },
            "THE KEY COMPANY": {
              "CALNA": "CALNA"
            },
            "VIRTUS PHARMACEUTICALS OPCO": {
              "VP-GGR-B6 PRENATAL": "VP-GGR-B6 PRENATAL"
            },
            "VITAMEDMD": {
              "VITAMEDMD REDICHEW RX": "VITAMEDMD REDICHEW RX"
            }
          }
        },
        "Specialty Vitamins Products": {
          "Specialty Vitamins Products": {
            "AG MARIN PHARMACEUTICALS": {
              "SUPPORT-500": "SUPPORT-500"
            },
            "ALCON VISION": {
              "ICAPS LUTEIN & ZEAXANTHIN": "ICAPS LUTEIN & ZEAXANTHIN"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP CENTURY ENERGY METABOLISM": "GNP CENTURY ENERGY METABOLISM"
            },
            "BASIC DRUGS": {
              "VITAMINS FOR HAIR": "VITAMINS FOR HAIR"
            },
            "BIO-TECH": {
              "CAL-CO3Y": "CAL-CO3Y",
              "LONGEVITY": "LONGEVITY",
              "LONGEVITY PLUS": "LONGEVITY PLUS",
              "MPS": "MPS",
              "THYMUS": "THYMUS",
              "VIGOR": "VIGOR"
            },
            "CHAIN DRUG CONSORTIUM": {
              "AIRSHIELD": "AIRSHIELD",
              "HEALTHY HEART": "HEALTHY HEART",
              "MENOPAUSE SUPPORT": "MENOPAUSE SUPPORT",
              "VITAMINS FOR HAIR": "VITAMINS FOR HAIR"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS HAIR/SKIN/NAILS": "CVS HAIR/SKIN/NAILS",
              "CVS MENOPAUSE SUPPORT": "CVS MENOPAUSE SUPPORT"
            },
            "DARTMOUTH": {
              "ELON MATRIX 5000": "ELON MATRIX 5000",
              "ELON MATRIX 5000 COMPLETE": "ELON MATRIX 5000 COMPLETE",
              "ELON MATRIX COMPLETE": "ELON MATRIX COMPLETE",
              "ELON MATRIX PLUS": "ELON MATRIX PLUS",
              "ELON R3": "ELON R3"
            },
            "ENOVACHEM MANUFACTURING": {
              "ADRENOID": "ADRENOID",
              "CARDIOPRESS": "CARDIOPRESS",
              "CHOLASE CONTROL": "CHOLASE CONTROL",
              "IMMUNICARE": "IMMUNICARE",
              "INULOSE BLOOD SUGAR SUPPORT": "INULOSE BLOOD SUGAR SUPPORT",
              "SYNERTROPIN": "SYNERTROPIN"
            },
            "GENERAL NUTRITION CORP": {
              "WOMENS MENOPAUSE VITA PAK": "WOMENS MENOPAUSE VITA PAK",
              "WOMENS VITA PAK": "WOMENS VITA PAK"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "CENTRUM PERFORMANCE": "CENTRUM PERFORMANCE",
              "CENTRUM SPECIALIST ENERGY": "CENTRUM SPECIALIST ENERGY"
            },
            "KRAMER-NOVIS": {
              "VARISAN VITALITY": "VARISAN VITALITY"
            },
            "LIDTKE TECHNOLOGIES": {
              "GLYCOTROL": "GLYCOTROL",
              "GLYCOTROL COMPLETE": "GLYCOTROL COMPLETE"
            },
            "LIPOTRIAD": {
              "LIPOTRIAD VISION SUPPORT": "LIPOTRIAD VISION SUPPORT",
              "LIPOTRIAD VISION SUPPORT PLUS": "LIPOTRIAD VISION SUPPORT PLUS",
              "LIPOTRIAD VISIONARY": "LIPOTRIAD VISIONARY"
            },
            "LLORENS PHARMACEUTICAL": {
              "UROSEX": "UROSEX"
            },
            "MAGNO-HUMPHRIES LABORATORIES": {
              "BILBERRY PLUS": "BILBERRY PLUS"
            },
            "MAJOR PHARMACEUTICALS": {
              "TAB-A-VITE WOMENS": "TAB-A-VITE WOMENS"
            },
            "MAS MANAGEMENT GROUP": {
              "VITA-RX DIABETIC VITAMIN": "VITA-RX DIABETIC VITAMIN"
            },
            "MASON VITAMINS": {
              "ADRENAL STRESS CALM": "ADRENAL STRESS CALM",
              "BRAIN MIGHT/DHA & CO Q10": "BRAIN MIGHT/DHA & CO Q10",
              "COLLAGEN ULTRA": "COLLAGEN ULTRA",
              "HEALTHY HEART COMPLEX": "HEALTHY HEART COMPLEX",
              "WEIGHT LOSS DAILY MULTI": "WEIGHT LOSS DAILY MULTI"
            },
            "MC COR": {
              "GLUCO COR": "GLUCO COR"
            },
            "MDR FITNESS": {
              "HEART TABS": "HEART TABS"
            },
            "MEDICINE SHOPPE": {
              "GERM DEFENSE PM": "GERM DEFENSE PM"
            },
            "MILLER": {
              "BRAIN": "BRAIN",
              "CA-PLUS": "CA-PLUS",
              "CAROZYME": "CAROZYME",
              "CU-PLUS": "CU-PLUS",
              "HEART": "HEART",
              "KIDNEY": "KIDNEY",
              "LUNG": "LUNG",
              "LYMPH": "LYMPH",
              "M2 CALCIUM": "M2 CALCIUM",
              "M2 CHROMIUM": "M2 CHROMIUM",
              "MAMMARY": "MAMMARY",
              "MG PLUS PROTEIN": "MG PLUS PROTEIN",
              "MG-PLUS PROTEIN": "MG-PLUS PROTEIN",
              "MIL ADREGEN": "MIL ADREGEN",
              "MILADRENE FORTE": "MILADRENE FORTE",
              "MILPAN": "MILPAN",
              "MULTI-GLAN": "MULTI-GLAN",
              "ORCHIC": "ORCHIC",
              "OVARY": "OVARY",
              "PARATHYROID": "PARATHYROID",
              "PITUITARY": "PITUITARY",
              "PROSTATE": "PROSTATE",
              "SPLEEN": "SPLEEN",
              "STOMACH": "STOMACH",
              "THYMUS": "THYMUS",
              "UTERUS": "UTERUS",
              "ZN-PLUS": "ZN-PLUS"
            },
            "NATIONAL VITAMIN": {
              "ALLERWELL ALLERGY FORMULA": "ALLERWELL ALLERGY FORMULA",
              "ULTIMATE FAT BURNER": "ULTIMATE FAT BURNER",
              "VITAMINS FOR HAIR": "VITAMINS FOR HAIR"
            },
            "NATURES BOUNTY": {
              "ADVANCED COLLAGEN": "ADVANCED COLLAGEN",
              "COMPLETE MENOPAUSE AM/PM": "COMPLETE MENOPAUSE AM/PM",
              "GREEN SOURCE": "GREEN SOURCE",
              "NUTRAMIX": "NUTRAMIX",
              "ULTRA MAN": "ULTRA MAN",
              "ULTRA WOMAN": "ULTRA WOMAN",
              "VITAMINS FOR THE HAIR": "VITAMINS FOR THE HAIR"
            },
            "NEW HEALTH CORP": {
              "HEART SAVIOR": "HEART SAVIOR",
              "LIPIDSHIELD PLUS": "LIPIDSHIELD PLUS"
            },
            "OCUSOFT": {
              "RETAINE VISION": "RETAINE VISION"
            },
            "REXALL SUNDOWN": {
              "NEW LIFE HAIR": "NEW LIFE HAIR"
            },
            "RITE AID CORPORATION": {
              "RA CENTRAL-VITE CARDIO": "RA CENTRAL-VITE CARDIO",
              "RA CENTRAL-VITE PERFORMANCE": "RA CENTRAL-VITE PERFORMANCE",
              "RA EAR CARE": "RA EAR CARE",
              "RA EFFERVESCENT FORMULA": "RA EFFERVESCENT FORMULA",
              "RA GERM DEFENSE": "RA GERM DEFENSE",
              "RA MENOPAUSE SUPPORT": "RA MENOPAUSE SUPPORT"
            },
            "THORNE RESEARCH": {
              "METHYL-GUARD": "METHYL-GUARD",
              "METHYL-GUARD PLUS": "METHYL-GUARD PLUS"
            },
            "VITAMEDMD": {
              "MENOPAUSE RELIEF": "MENOPAUSE RELIEF"
            },
            "WAL-MART": {
              "MM BIOTIN/KERATIN": "MM BIOTIN/KERATIN"
            },
            "WALGREENS": {
              "A THRU Z ADVANTAGE": "A THRU Z ADVANTAGE",
              "BIOTIN PLUS KERATIN": "BIOTIN PLUS KERATIN",
              "HAIR NOURISHING SUPPLEMENT": "HAIR NOURISHING SUPPLEMENT",
              "WAL-BORN": "WAL-BORN"
            },
            "WINDMILL CONSUMER PRODUCTS": {
              "HAIR FARE": "HAIR FARE",
              "MILLTRIUM STAMINA PLUS": "MILLTRIUM STAMINA PLUS",
              "THERABETIC EYE HEALTH": "THERABETIC EYE HEALTH"
            }
          }
        },
        "Vitamin Mixtures": {
          "Niacin w/ Inositol": {
            "21ST CENTURY HEALTHCARE": {
              "NIACIN FLUSH FREE": "NIACIN FLUSH FREE"
            },
            "AIDAREX PHARMACEUTICALS": {
              "NIACIN FLUSH FREE": "NIACIN FLUSH FREE"
            },
            "BASIC DRUGS": {
              "NIACIN FLUSH FREE": "NIACIN FLUSH FREE"
            },
            "BERGEN BRUNSWIG": {
              "GNP NIACIN FLUSH FREE": "GNP NIACIN FLUSH FREE"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS NIACIN FLUSH FREE": "CVS NIACIN FLUSH FREE"
            },
            "LEADER BRAND PRODUCTS": {
              "NIACIN FLUSH FREE": "NIACIN FLUSH FREE"
            },
            "NATIONAL VITAMIN": {
              "NO FLUSH NIACIN": "NO FLUSH NIACIN"
            },
            "WAL-MART": {
              "NIACIN FLUSH FREE": "NIACIN FLUSH FREE"
            },
            "WALGREENS": {
              "NIACIN FLUSH FREE": "NIACIN FLUSH FREE"
            }
          },
          "Niacinamide w/ Zinc-Copper & Folic Acid": {
            "AVION PHARMACEUTICALS": {
              "NICOMIDE": "NICOMIDE"
            }
          },
          "Vitamin C & Vitamin D": {
            "NATURES BOUNTY": {
              "VITAMIN C & D3/ROSE HIPS": "VITAMIN C & D3/ROSE HIPS"
            },
            "REXALL SUNDOWN": {
              "VITAMIN D3-VITAMIN C": "VITAMIN D3-VITAMIN C"
            }
          },
          "Vitamin C, Vitamin D & Zinc": {
            "PHARMAVITE": {
              "D3/VITAMIN C/ZINC": "D3/VITAMIN C/ZINC"
            }
          },
          "Vitamin D & K": {
            "CAPITAL PHARMACEUTICAL": {
              "DOSOQUIN": "DOSOQUIN"
            },
            "MASON VITAMINS": {
              "K2 PLUS D3": "K2 PLUS D3"
            },
            "TWIN LABS": {
              "D3 + K2 DOTS": "D3 + K2 DOTS"
            },
            "WAL-MART": {
              "VITAMIN K2-VITAMIN D3": "VITAMIN K2-VITAMIN D3"
            }
          },
          "Vitamin Mixtures": {
            "CHAIN DRUG CONSORTIUM": {
              "VITAMIN E-SELENIUM": "VITAMIN E-SELENIUM"
            },
            "MILLER": {
              "MILTAB #1": "MILTAB #1"
            },
            "NAT-RUL HEALTH PRODUCTS": {
              "ESTER-C": "ESTER-C",
              "SELENIUM-VITAMIN E": "SELENIUM-VITAMIN E"
            },
            "NATURES BOUNTY": {
              "VITAMIN E COMPLETE": "VITAMIN E COMPLETE",
              "VITAMIN E/SELENIUM": "VITAMIN E/SELENIUM"
            },
            "VITALINE": {
              "VITA-MAG": "VITA-MAG"
            },
            "WALGREENS": {
              "VITAMIN C": "VITAMIN C"
            },
            "WINDMILL CONSUMER PRODUCTS": {
              "E-400/SELENIUM": "E-400/SELENIUM"
            },
            "WOMEN'S CHOICE PHARMACEUTICALS": {
              "ECEE PLUS": "ECEE PLUS"
            }
          },
          "Vitamins A & C": {
            "MASON VITAMINS": {
              "HEALTHY KIDS VIT C/ROSE HIPS": "HEALTHY KIDS VIT C/ROSE HIPS"
            }
          },
          "Vitamins A & D": {
            "21ST CENTURY HEALTHCARE": {
              "NORWEGIAN COD LIVER OIL": "NORWEGIAN COD LIVER OIL"
            },
            "BASIC DRUGS": {
              "COD LIVER OIL": "COD LIVER OIL"
            },
            "BERGEN BRUNSWIG": {
              "GNP COD LIVER OIL": "GNP COD LIVER OIL",
              "GNP NORWEGIAN COD LIVER OIL": "GNP NORWEGIAN COD LIVER OIL"
            },
            "CHAIN DRUG CONSORTIUM": {
              "COD LIVER OIL": "COD LIVER OIL",
              "VITAMIN A & D": "VITAMIN A & D"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC COD LIVER OIL": "QC COD LIVER OIL"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS COD LIVER OIL": "CVS COD LIVER OIL"
            },
            "FAGRON": {
              "COD LIVER OIL": "COD LIVER OIL"
            },
            "FREEDA VITAMINS": {
              "VITAMIN A & D": "VITAMIN A & D"
            },
            "HUMCO": {
              "COD LIVER OIL": "COD LIVER OIL"
            },
            "J R CARLSON LABORATORIES": {
              "COD LIVER OIL": "COD LIVER OIL",
              "COD LIVER OIL FOR KIDS": "COD LIVER OIL FOR KIDS",
              "COD LIVER OIL/LOW VITAMIN A": "COD LIVER OIL/LOW VITAMIN A",
              "NORWEGIAN COD LIVER OIL": "NORWEGIAN COD LIVER OIL"
            },
            "JJ BALAN": {
              "COD LIVER OIL": "COD LIVER OIL"
            },
            "KALCHEM INTERNATIONAL": {
              "COD LIVER OIL": "COD LIVER OIL"
            },
            "LEINER HEALTH PRODUCTS": {
              "YL NATURAL VITAMIN A & D": "YL NATURAL VITAMIN A & D"
            },
            "MAGNO-HUMPHRIES LABORATORIES": {
              "COD LIVER OIL": "COD LIVER OIL",
              "VITAMIN A & D": "VITAMIN A & D",
              "VITAMINS A & D": "VITAMINS A & D"
            },
            "MAJOR PHARMACEUTICALS": {
              "COD LIVER OIL": "COD LIVER OIL"
            },
            "MASON VITAMINS": {
              "COD LIVER OIL W/VIT A & D": "COD LIVER OIL W/VIT A & D",
              "VITAMINS A & D": "VITAMINS A & D"
            },
            "MCKESSON SUNMARK": {
              "SM COD LIVER OIL": "SM COD LIVER OIL"
            },
            "MEDISCA": {
              "COD LIVER OIL": "COD LIVER OIL"
            },
            "NAT-RUL HEALTH PRODUCTS": {
              "NATURAL COD LIVER OIL": "NATURAL COD LIVER OIL"
            },
            "NATIONAL VITAMIN": {
              "COD LIVER OIL": "COD LIVER OIL"
            },
            "NATURES BOUNTY": {
              "COD LIVER OIL": "COD LIVER OIL",
              "VITAMIN A & D": "VITAMIN A & D",
              "VITAMINS A & D": "VITAMINS A & D"
            },
            "PCCA": {
              "COD LIVER OIL": "COD LIVER OIL"
            },
            "PHARMASSURE, INC.": {
              "COD LIVER OIL": "COD LIVER OIL"
            },
            "PHARMAVITE": {
              "COD LIVER OIL": "COD LIVER OIL"
            },
            "PRINCETON RESEARCH": {
              "NORWEGIAN COD LIVER OIL": "NORWEGIAN COD LIVER OIL"
            },
            "REXALL SUNDOWN": {
              "COD LIVER OIL": "COD LIVER OIL",
              "D 400": "D 400"
            },
            "RITE AID CORPORATION": {
              "RA COD LIVER OIL": "RA COD LIVER OIL",
              "RA VITAMIN A & D": "RA VITAMIN A & D"
            },
            "RUGBY LABORATORIES": {
              "COD LIVER OIL": "COD LIVER OIL"
            },
            "SPECTRUM": {
              "COD LIVER OIL": "COD LIVER OIL"
            },
            "THE KEY COMPANY": {
              "D-NATURAL-5": "D-NATURAL-5"
            },
            "WAL-MART": {
              "COD LIVER OIL/VITAMINS A & D": "COD LIVER OIL/VITAMINS A & D",
              "NORWEGIAN COD LIVER OIL": "NORWEGIAN COD LIVER OIL",
              "VITAMINS A & D": "VITAMINS A & D"
            },
            "WALGREENS": {
              "COD LIVER OIL": "COD LIVER OIL",
              "VITAMIN A & D": "VITAMIN A & D"
            },
            "WINDMILL CONSUMER PRODUCTS": {
              "A & D": "A & D",
              "COD LIVER OIL": "COD LIVER OIL"
            }
          },
          "Vitamins A & D w/ C": {
            "CHAIN DRUG CONSORTIUM": {
              "COD LIVER OIL PLUS VITAMIN C": "COD LIVER OIL PLUS VITAMIN C"
            },
            "MASON VITAMINS": {
              "COD LIVER OIL W/VIT A, C & D": "COD LIVER OIL W/VIT A, C & D",
              "HEALTHY KIDS COD LIVER/VIT D": "HEALTHY KIDS COD LIVER/VIT D"
            }
          },
          "Vitamins A & D w/ K": {
            "FOODSCIENCE LABORATORIES": {
              "ADK": "ADK"
            }
          },
          "Vitamins A & D w/ Minerals": {
            "PHARMAVITE": {
              "SUPER D3 COMPLEX": "SUPER D3 COMPLEX"
            }
          },
          "Vitamins ACE & Zn": {
            "BIO-TECH": {
              "AZEC": "AZEC"
            }
          },
          "Vitamins B1,B6 & B12": {
            "CHAIN DRUG CONSORTIUM": {
              "NEURO VITE": "NEURO VITE"
            },
            "PHARMADERM": {
              "APATATE": "APATATE"
            }
          },
          "Vitamins C & E": {
            "MASON VITAMINS": {
              "CRANBERRY URINARY COMFORT": "CRANBERRY URINARY COMFORT"
            },
            "NAT-RUL HEALTH PRODUCTS": {
              "NAT-RUL ANTIOXIDANTS C+E": "NAT-RUL ANTIOXIDANTS C+E"
            },
            "NATURES BOUNTY": {
              "VITAMINS C E": "VITAMINS C E"
            },
            "TEVA PHARMACEUTICALS USA": {
              "VITAMIN C & E COMBINATION": "VITAMIN C & E COMBINATION"
            },
            "WAL-MART": {
              "C & E": "C & E"
            }
          }
        },
        "Vitamins w/ Lipotropics": {
          "Vitamins w/ Lipotropics": {
            "ACTIPHARMA": {
              "ACTIFLOVIT EAR HEALTH": "ACTIFLOVIT EAR HEALTH"
            },
            "BIO-TECH": {
              "B-STRESS": "B-STRESS"
            },
            "CHAIN DRUG CONSORTIUM": {
              "B-100 COMPLEX": "B-100 COMPLEX",
              "B-50 COMPLEX": "B-50 COMPLEX",
              "MEGA-CHOL": "MEGA-CHOL"
            },
            "CLARION BRANDS": {
              "LIPO FLAVONOID PLUS": "LIPO FLAVONOID PLUS"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS BALANCED B50": "CVS BALANCED B50",
              "CVS INNER EAR PLUS": "CVS INNER EAR PLUS"
            },
            "LEADER BRAND PRODUCTS": {
              "INNER EAR PLUS": "INNER EAR PLUS"
            },
            "LIPOTRIAD": {
              "LIPOTRIAD": "LIPOTRIAD"
            },
            "LLORENS PHARMACEUTICAL": {
              "LIPOFLAVOVIT": "LIPOFLAVOVIT"
            },
            "MAGNO-HUMPHRIES LABORATORIES": {
              "BALANCED B-50 COMPLEX": "BALANCED B-50 COMPLEX"
            },
            "MAJOR PHARMACEUTICALS": {
              "BALANCED B-100": "BALANCED B-100",
              "BALANCED B-50": "BALANCED B-50",
              "GERAVINE": "GERAVINE"
            },
            "MASON VITAMINS": {
              "EAR HEALTH FORMULA": "EAR HEALTH FORMULA",
              "METHACHOLINE/LIVER": "METHACHOLINE/LIVER",
              "ULTRA B-100 COMPLEX": "ULTRA B-100 COMPLEX"
            },
            "MILLER": {
              "LIPOCOMPLEX": "LIPOCOMPLEX"
            },
            "NAT-RUL HEALTH PRODUCTS": {
              "MEGA STRESS 600/60": "MEGA STRESS 600/60",
              "NAT-RUL B-100": "NAT-RUL B-100",
              "NAT-RUL B-125": "NAT-RUL B-125",
              "NAT-RUL B-50": "NAT-RUL B-50"
            },
            "NATIONAL VITAMIN": {
              "BALANCE B-100": "BALANCE B-100",
              "COMPLEX B-100": "COMPLEX B-100",
              "MEGA MULTIPLE/CHELATED MINERAL": "MEGA MULTIPLE/CHELATED MINERAL",
              "MULTI-VITAMIN HP/MINERALS": "MULTI-VITAMIN HP/MINERALS"
            },
            "NATURES BOUNTY": {
              "B-100": "B-100",
              "B-100 CR": "B-100 CR",
              "B-50": "B-50",
              "BALANCED B-100 COMPLEX CR": "BALANCED B-100 COMPLEX CR",
              "SUPER STRESS B-COMPLEX CR": "SUPER STRESS B-COMPLEX CR"
            },
            "NUMARK LABS": {
              "LIPOFLAVONOID": "LIPOFLAVONOID"
            },
            "PUBLIX SUPER MARKETS INC.": {
              "PX B-50": "PX B-50"
            },
            "REXALL SUNDOWN": {
              "B-100 COMPLEX": "B-100 COMPLEX",
              "B-100 CR": "B-100 CR"
            },
            "RISING PHARMACEUTICALS": {
              "RISANOID PLUS": "RISANOID PLUS"
            },
            "RUGBY LABORATORIES": {
              "LIPOGEN SG": "LIPOGEN SG"
            },
            "SCOT-TUSSIN": {
              "VITA-PLUS G": "VITA-PLUS G"
            },
            "THE KEY COMPANY": {
              "LIPO-KEY": "LIPO-KEY"
            },
            "WALGREENS": {
              "EAR HEALTH PLUS": "EAR HEALTH PLUS"
            }
          }
        }
      },
      "MUSCULOSKELETAL THERAPY AGENTS": {
        "Articular Cartilage Repair Therapy": {
          "Articular Cartilage Repair Therapy": {
            "GENZYME": {
              "CARTICEL": "CARTICEL"
            },
            "VERICEL CORPORATION": {
              "CARTICEL": "CARTICEL",
              "MACI": "MACI"
            }
          }
        },
        "Central Muscle Relaxants": {
          "Central Muscle Relaxants": {
            "A-S MEDICATION SOLUTIONS": {
              "AMRIX": "AMRIX",
              "BACLOFEN": "BACLOFEN",
              "CARISOPRODOL": "CARISOPRODOL",
              "CHLORZOXAZONE": "CHLORZOXAZONE",
              "CYCLOBENZAPRINE HCL": "CYCLOBENZAPRINE HCL",
              "LORZONE": "LORZONE",
              "METAXALONE": "METAXALONE",
              "METHOCARBAMOL": "METHOCARBAMOL",
              "ORPHENADRINE CITRATE": "ORPHENADRINE CITRATE",
              "ORPHENADRINE CITRATE ER": "ORPHENADRINE CITRATE ER",
              "SKELAXIN": "SKELAXIN",
              "TIZANIDINE HCL": "TIZANIDINE HCL",
              "ZANAFLEX": "ZANAFLEX"
            },
            "ACI HEALTHCARE USA": {
              "METHOCARBAMOL": "METHOCARBAMOL"
            },
            "ACTAVIS PHARMA": {
              "CARISOPRODOL": "CARISOPRODOL",
              "CHLORZOXAZONE": "CHLORZOXAZONE",
              "CYCLOBENZAPRINE HCL": "CYCLOBENZAPRINE HCL",
              "METAXALONE": "METAXALONE",
              "ORPHENADRINE CITRATE": "ORPHENADRINE CITRATE",
              "TIZANIDINE HCL": "TIZANIDINE HCL"
            },
            "AIDAREX PHARMACEUTICALS": {
              "BACLOFEN": "BACLOFEN",
              "CARISOPRODOL": "CARISOPRODOL",
              "CHLORZOXAZONE": "CHLORZOXAZONE",
              "CYCLOBENZAPRINE HCL": "CYCLOBENZAPRINE HCL",
              "ENOVARX-CYCLOBENZAPRINE HCL": "ENOVARX-CYCLOBENZAPRINE HCL",
              "METAXALONE": "METAXALONE",
              "METHOCARBAMOL": "METHOCARBAMOL",
              "ORPHENADRINE CITRATE ER": "ORPHENADRINE CITRATE ER",
              "SKELAXIN": "SKELAXIN",
              "TIZANIDINE HCL": "TIZANIDINE HCL"
            },
            "AKORN": {
              "ORPHENADRINE CITRATE": "ORPHENADRINE CITRATE"
            },
            "ALEMBIC PHARMACEUTICALS": {
              "TIZANIDINE HCL": "TIZANIDINE HCL"
            },
            "ALEXSO": {
              "BACLOFEN": "BACLOFEN"
            },
            "ALIVIO MEDICAL PRODUCTS": {
              "CYCLOBENZAPRINE HCL": "CYCLOBENZAPRINE HCL"
            },
            "ALL PHARMA": {
              "CYCLOBENZAPRINE HCL": "CYCLOBENZAPRINE HCL",
              "FEXMID": "FEXMID"
            },
            "ALTURA PHARMACEUTICALS": {
              "BACLOFEN": "BACLOFEN",
              "CARISOPRODOL": "CARISOPRODOL",
              "CHLORZOXAZONE": "CHLORZOXAZONE",
              "CYCLOBENZAPRINE HCL": "CYCLOBENZAPRINE HCL",
              "METHOCARBAMOL": "METHOCARBAMOL",
              "ORPHENADRINE CITRATE ER": "ORPHENADRINE CITRATE ER",
              "SKELAXIN": "SKELAXIN",
              "TIZANIDINE HCL": "TIZANIDINE HCL"
            },
            "ALVIX LABORATORIES": {
              "BACLOFEN": "BACLOFEN",
              "ORPHENADRINE CITRATE": "ORPHENADRINE CITRATE"
            },
            "AMERICAN HEALTH PACKAGING": {
              "BACLOFEN": "BACLOFEN",
              "CARISOPRODOL": "CARISOPRODOL",
              "CYCLOBENZAPRINE HCL": "CYCLOBENZAPRINE HCL",
              "METAXALONE": "METAXALONE",
              "METHOCARBAMOL": "METHOCARBAMOL",
              "ORPHENADRINE CITRATE ER": "ORPHENADRINE CITRATE ER",
              "TIZANIDINE HCL": "TIZANIDINE HCL"
            },
            "AMERICAN PHARMA INGREDIENTS": {
              "BACLOFEN": "BACLOFEN",
              "ORPHENADRINE CITRATE": "ORPHENADRINE CITRATE"
            },
            "AMERICAN REGENT": {
              "METHOCARBAMOL": "METHOCARBAMOL"
            },
            "AMNEAL PHARMACEUTICALS": {
              "CYCLOBENZAPRINE HCL": "CYCLOBENZAPRINE HCL",
              "CYCLOBENZAPRINE HCL ER": "CYCLOBENZAPRINE HCL ER",
              "METAXALONE": "METAXALONE",
              "ORPHENADRINE CITRATE ER": "ORPHENADRINE CITRATE ER"
            },
            "AMNEAL SPECIALTY": {
              "METAXALONE": "METAXALONE"
            },
            "APHENA PHARMA SOLUTIONS": {
              "BACLOFEN": "BACLOFEN",
              "CARISOPRODOL": "CARISOPRODOL",
              "CYCLOBENZAPRINE HCL": "CYCLOBENZAPRINE HCL",
              "METHOCARBAMOL": "METHOCARBAMOL",
              "TIZANIDINE HCL": "TIZANIDINE HCL"
            },
            "API SOLUTIONS": {
              "BACLOFEN": "BACLOFEN"
            },
            "APOTEX": {
              "CYCLOBENZAPRINE HCL ER": "CYCLOBENZAPRINE HCL ER",
              "TIZANIDINE HCL": "TIZANIDINE HCL"
            },
            "APOTHECA": {
              "CARISOPRODOL": "CARISOPRODOL",
              "CYCLOBENZAPRINE HCL": "CYCLOBENZAPRINE HCL",
              "METHOCARBAMOL": "METHOCARBAMOL",
              "TIZANIDINE HCL": "TIZANIDINE HCL"
            },
            "APOTHECA SUPPLY": {
              "BACLOFEN": "BACLOFEN",
              "ORPHENADRINE CITRATE": "ORPHENADRINE CITRATE"
            },
            "ASCEND LABORATORIES": {
              "TIZANIDINE HCL": "TIZANIDINE HCL"
            },
            "ATHENEX PHARMACEUTICAL": {
              "METHOCARBAMOL": "METHOCARBAMOL"
            },
            "ATTIX PHARMACEUTICALS": {
              "BACLOFEN": "BACLOFEN"
            },
            "AUROBINDO PHARMA": {
              "CARISOPRODOL": "CARISOPRODOL",
              "CYCLOBENZAPRINE HCL": "CYCLOBENZAPRINE HCL",
              "TIZANIDINE HCL": "TIZANIDINE HCL"
            },
            "AUROMEDICS PHARMA": {
              "METHOCARBAMOL": "METHOCARBAMOL"
            },
            "AUSTARPHARMA": {
              "METHOCARBAMOL": "METHOCARBAMOL"
            },
            "AVKARE": {
              "BACLOFEN": "BACLOFEN",
              "CYCLOBENZAPRINE HCL": "CYCLOBENZAPRINE HCL",
              "METAXALONE": "METAXALONE",
              "ORPHENADRINE CITRATE ER": "ORPHENADRINE CITRATE ER",
              "TIZANIDINE HCL": "TIZANIDINE HCL"
            },
            "AVPAK": {
              "CYCLOBENZAPRINE HCL": "CYCLOBENZAPRINE HCL",
              "METAXALONE": "METAXALONE",
              "METHOCARBAMOL": "METHOCARBAMOL",
              "TIZANIDINE HCL": "TIZANIDINE HCL"
            },
            "AXIS PHARMACEUTICS": {
              "CHLORZOXAZONE": "CHLORZOXAZONE"
            },
            "AZURITY PHARMACEUTICALS": {
              "FIRST-BACLOFEN": "FIRST-BACLOFEN"
            },
            "B & B PHARMACEUTICALS": {
              "BACLOFEN": "BACLOFEN",
              "ORPHENADRINE CITRATE": "ORPHENADRINE CITRATE"
            },
            "BAYSHORE PHARMACEUTICALS": {
              "METHOCARBAMOL": "METHOCARBAMOL"
            },
            "BLENHEIM PHARMACAL": {
              "BACLOFEN": "BACLOFEN",
              "CARISOPRODOL": "CARISOPRODOL",
              "CYCLOBENZAPRINE HCL": "CYCLOBENZAPRINE HCL",
              "METAXALONE": "METAXALONE",
              "METHOCARBAMOL": "METHOCARBAMOL",
              "ORPHENADRINE CITRATE ER": "ORPHENADRINE CITRATE ER",
              "SKELAXIN": "SKELAXIN",
              "TIZANIDINE HCL": "TIZANIDINE HCL"
            },
            "BLUE POINT LABORATORIES": {
              "METAXALONE": "METAXALONE"
            },
            "BPI LABS LLC": {
              "BACLOFEN": "BACLOFEN"
            },
            "BRYANT RANCH PREPACK": {
              "BACLOFEN": "BACLOFEN",
              "CARISOPRODOL": "CARISOPRODOL",
              "CHLORZOXAZONE": "CHLORZOXAZONE",
              "CYCLOBENZAPRINE HCL": "CYCLOBENZAPRINE HCL",
              "FEXMID": "FEXMID",
              "METAXALONE": "METAXALONE",
              "METHOCARBAMOL": "METHOCARBAMOL",
              "ORPHENADRINE CITRATE ER": "ORPHENADRINE CITRATE ER",
              "SKELAXIN": "SKELAXIN",
              "TIZANIDINE HCL": "TIZANIDINE HCL"
            },
            "BUREL PHARMACEUTICALS": {
              "METHOCARBAMOL": "METHOCARBAMOL"
            },
            "CADISTA": {
              "CARISOPRODOL": "CARISOPRODOL",
              "CYCLOBENZAPRINE HCL": "CYCLOBENZAPRINE HCL",
              "TIZANIDINE HCL": "TIZANIDINE HCL"
            },
            "CALIFORNIA PHARMACEUTICALS": {
              "CYCLOPHENE RAPIDPAQ": "CYCLOPHENE RAPIDPAQ",
              "METAXALONE": "METAXALONE",
              "TABRADOL RAPIDPAQ": "TABRADOL RAPIDPAQ"
            },
            "CAMBER PHARMACEUTICALS": {
              "METHOCARBAMOL": "METHOCARBAMOL"
            },
            "CAMERON PHARMACEUTICALS": {
              "CHLORZOXAZONE": "CHLORZOXAZONE"
            },
            "CARDINAL HEALTH REPACKAGING SO": {
              "BACLOFEN": "BACLOFEN"
            },
            "CARLSBAD TECHNOLOGIES": {
              "CARISOPRODOL": "CARISOPRODOL"
            },
            "CASPER PHARMA": {
              "FEXMID": "FEXMID"
            },
            "CIPLA USA": {
              "CYCLOBENZAPRINE HCL": "CYCLOBENZAPRINE HCL"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "BACLOFEN": "BACLOFEN",
              "CARISOPRODOL": "CARISOPRODOL",
              "CHLORZOXAZONE": "CHLORZOXAZONE",
              "CYCLOBENZAPRINE HCL": "CYCLOBENZAPRINE HCL",
              "METAXALONE": "METAXALONE",
              "METHOCARBAMOL": "METHOCARBAMOL",
              "ORPHENADRINE CITRATE ER": "ORPHENADRINE CITRATE ER",
              "TIZANIDINE HCL": "TIZANIDINE HCL"
            },
            "COVIS PHARMA": {
              "ZANAFLEX": "ZANAFLEX"
            },
            "DENTON PHARMA": {
              "CYCLOBENZAPRINE HCL": "CYCLOBENZAPRINE HCL",
              "METAXALONE": "METAXALONE"
            },
            "DIRECT RX": {
              "BACLOFEN": "BACLOFEN",
              "CARISOPRODOL": "CARISOPRODOL",
              "CHLORZOXAZONE": "CHLORZOXAZONE",
              "CYCLOBENZAPRINE HCL": "CYCLOBENZAPRINE HCL",
              "CYCLOBENZAPRINE HCL ER": "CYCLOBENZAPRINE HCL ER",
              "METAXALONE": "METAXALONE",
              "METHOCARBAMOL": "METHOCARBAMOL",
              "ORPHENADRINE CITRATE ER": "ORPHENADRINE CITRATE ER",
              "TIZANIDINE HCL": "TIZANIDINE HCL"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "METAXALONE": "METAXALONE",
              "METHOCARBAMOL": "METHOCARBAMOL",
              "TIZANIDINE HCL": "TIZANIDINE HCL"
            },
            "DSQUARED PHARMACEUTICALS": {
              "BACLOFEN": "BACLOFEN"
            },
            "ENCORE SCIENTIFIC": {
              "BACLOFEN": "BACLOFEN"
            },
            "ENDO PHARMACEUTICALS": {
              "ROBAXIN": "ROBAXIN",
              "ROBAXIN-750": "ROBAXIN-750"
            },
            "ENOVACHEM MANUFACTURING": {
              "BACLOFEN": "BACLOFEN",
              "CYCLOBENZAPRINE HCL": "CYCLOBENZAPRINE HCL",
              "ENOVARX-CYCLOBENZAPRINE HCL": "ENOVARX-CYCLOBENZAPRINE HCL"
            },
            "EYWA PHARMA": {
              "BACLOFEN": "BACLOFEN"
            },
            "FAGRON": {
              "BACLOFEN": "BACLOFEN",
              "ORPHENADRINE CITRATE": "ORPHENADRINE CITRATE"
            },
            "FREEDOM PHARMACEUTICALS": {
              "BACLOFEN": "BACLOFEN",
              "ORPHENADRINE CITRATE": "ORPHENADRINE CITRATE"
            },
            "FRESENIUS KABI USA": {
              "METHOCARBAMOL": "METHOCARBAMOL"
            },
            "FUSION PHARMACEUTICALS": {
              "TABRADOL FUSEPAQ": "TABRADOL FUSEPAQ"
            },
            "GENPAK SOLUTIONS": {
              "BACLOFEN": "BACLOFEN"
            },
            "GLENMARK PHARMACEUTICALS": {
              "CHLORZOXAZONE": "CHLORZOXAZONE"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "CARISOPRODOL": "CARISOPRODOL",
              "CYCLOBENZAPRINE HCL": "CYCLOBENZAPRINE HCL",
              "METHOCARBAMOL": "METHOCARBAMOL",
              "TIZANIDINE HCL": "TIZANIDINE HCL"
            },
            "GRANULES PHARMACEUTICALS": {
              "METHOCARBAMOL": "METHOCARBAMOL"
            },
            "H.J. HARKINS COMPANY": {
              "CYCLOBENZAPRINE HCL": "CYCLOBENZAPRINE HCL"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "BACLOFEN": "BACLOFEN",
              "CARISOPRODOL": "CARISOPRODOL",
              "CYCLOBENZAPRINE HCL": "CYCLOBENZAPRINE HCL",
              "METAXALONE": "METAXALONE",
              "METHOCARBAMOL": "METHOCARBAMOL",
              "ORPHENADRINE CITRATE ER": "ORPHENADRINE CITRATE ER",
              "SKELAXIN": "SKELAXIN",
              "TIZANIDINE HCL": "TIZANIDINE HCL",
              "ZANAFLEX": "ZANAFLEX"
            },
            "HIKMA": {
              "CARISOPRODOL": "CARISOPRODOL",
              "METHOCARBAMOL": "METHOCARBAMOL",
              "ORPHENADRINE CITRATE": "ORPHENADRINE CITRATE",
              "ROBAXIN": "ROBAXIN"
            },
            "HUDSON SCIENTIFIC": {
              "CYCLOTENS REFILL PAK": "CYCLOTENS REFILL PAK",
              "CYCLOTENS STARTER PAK": "CYCLOTENS STARTER PAK"
            },
            "HUMCO": {
              "BACLOFEN": "BACLOFEN",
              "ORPHENADRINE CITRATE": "ORPHENADRINE CITRATE"
            },
            "HURON PHARMACEUTICALS": {
              "BACLOFEN": "BACLOFEN"
            },
            "IMPAX GENERICS": {
              "METAXALONE": "METAXALONE"
            },
            "INGENUS PHARMACEUTICALS": {
              "CARISOPRODOL": "CARISOPRODOL",
              "TIZANIDINE HCL": "TIZANIDINE HCL"
            },
            "INNOVIDA PHARMACEUTIQUE": {
              "CYCLOBENZAPRINE HCL": "CYCLOBENZAPRINE HCL"
            },
            "IPG PHARMACEUTICALS": {
              "CYCLOBENZAPRINE HCL": "CYCLOBENZAPRINE HCL"
            },
            "JANSSEN": {
              "PARAFON FORTE DSC": "PARAFON FORTE DSC"
            },
            "KAISER FOUNDATION HOSPITAL": {
              "CYCLOBENZAPRINE HCL": "CYCLOBENZAPRINE HCL"
            },
            "KEY THERAPEUTICS": {
              "METHOCARBAMOL": "METHOCARBAMOL"
            },
            "KLE 2": {
              "CYCLOBENZAPRINE HCL": "CYCLOBENZAPRINE HCL"
            },
            "KVK TECH": {
              "CYCLOBENZAPRINE HCL": "CYCLOBENZAPRINE HCL"
            },
            "LANNETT": {
              "BACLOFEN": "BACLOFEN",
              "METAXALONE": "METAXALONE",
              "METHOCARBAMOL": "METHOCARBAMOL"
            },
            "LEGACY PHARMACEUTICAL PKG": {
              "CYCLOBENZAPRINE HCL": "CYCLOBENZAPRINE HCL"
            },
            "LETCO MEDICAL": {
              "BACLOFEN": "BACLOFEN",
              "CARISOPRODOL": "CARISOPRODOL"
            },
            "LIBERTY PHARMACEUTICAL": {
              "BACLOFEN": "BACLOFEN",
              "CARISOPRODOL": "CARISOPRODOL",
              "CHLORZOXAZONE": "CHLORZOXAZONE",
              "CYCLOBENZAPRINE HCL": "CYCLOBENZAPRINE HCL",
              "METHOCARBAMOL": "METHOCARBAMOL",
              "ORPHENADRINE CITRATE ER": "ORPHENADRINE CITRATE ER",
              "TIZANIDINE HCL": "TIZANIDINE HCL"
            },
            "LUPIN PHARMACEUTICALS": {
              "ORPHENADRINE CITRATE ER": "ORPHENADRINE CITRATE ER"
            },
            "MAJOR PHARMACEUTICALS": {
              "BACLOFEN": "BACLOFEN",
              "CYCLOBENZAPRINE HCL": "CYCLOBENZAPRINE HCL",
              "METAXALONE": "METAXALONE",
              "TIZANIDINE HCL": "TIZANIDINE HCL"
            },
            "MALLINCKRODT BRAND PHARMA": {
              "GABLOFEN": "GABLOFEN"
            },
            "MARLEX PHARMACEUTICALS": {
              "BACLOFEN": "BACLOFEN",
              "CYCLOBENZAPRINE HCL": "CYCLOBENZAPRINE HCL",
              "METHOCARBAMOL": "METHOCARBAMOL"
            },
            "MAYNE PHARMA": {
              "CHLORZOXAZONE": "CHLORZOXAZONE"
            },
            "MEDISCA": {
              "BACLOFEN": "BACLOFEN",
              "ORPHENADRINE CITRATE": "ORPHENADRINE CITRATE"
            },
            "MEDVANTX": {
              "CARISOPRODOL": "CARISOPRODOL",
              "CHLORZOXAZONE": "CHLORZOXAZONE",
              "CYCLOBENZAPRINE HCL": "CYCLOBENZAPRINE HCL",
              "METHOCARBAMOL": "METHOCARBAMOL",
              "ORPHENADRINE CITRATE ER": "ORPHENADRINE CITRATE ER"
            },
            "MEITHEAL PHARMACEUTICALS": {
              "METHOCARBAMOL": "METHOCARBAMOL"
            },
            "METACEL PHARMACEUTICALS": {
              "OZOBAX": "OZOBAX"
            },
            "METHOD PHARMACEUTICALS": {
              "CARISOPRODOL": "CARISOPRODOL"
            },
            "MIKART": {
              "CHLORZOXAZONE": "CHLORZOXAZONE"
            },
            "MYLAN": {
              "BACLOFEN": "BACLOFEN",
              "CYCLOBENZAPRINE HCL": "CYCLOBENZAPRINE HCL",
              "TIZANIDINE HCL": "TIZANIDINE HCL"
            },
            "MYLAN INSTITUTIONAL": {
              "BACLOFEN": "BACLOFEN",
              "CYCLOBENZAPRINE HCL": "CYCLOBENZAPRINE HCL",
              "METHOCARBAMOL": "METHOCARBAMOL",
              "TIZANIDINE HCL": "TIZANIDINE HCL"
            },
            "MYLAN SPECIALTY L.P.": {
              "SOMA": "SOMA"
            },
            "NEW HORIZON RX GROUP": {
              "CYCLOBENZAPRINE HCL": "CYCLOBENZAPRINE HCL",
              "TIZANIDINE HCL": "TIZANIDINE HCL"
            },
            "NIVAGEN PHARMACEUTICALS": {
              "TIZANIDINE HCL": "TIZANIDINE HCL"
            },
            "NORTHSTAR RX": {
              "BACLOFEN": "BACLOFEN",
              "CARISOPRODOL": "CARISOPRODOL"
            },
            "NOSTRUM LABORATORIES": {
              "CARISOPRODOL": "CARISOPRODOL"
            },
            "NOVAPLUS/HIKMA": {
              "ROBAXIN": "ROBAXIN"
            },
            "NOVITIUM PHARMA": {
              "CHLORZOXAZONE": "CHLORZOXAZONE"
            },
            "NUCARE PHARMACEUTICALS": {
              "BACLOFEN": "BACLOFEN",
              "CARISOPRODOL": "CARISOPRODOL",
              "CHLORZOXAZONE": "CHLORZOXAZONE",
              "CYCLOBENZAPRINE HCL": "CYCLOBENZAPRINE HCL",
              "METAXALONE": "METAXALONE",
              "METHOCARBAMOL": "METHOCARBAMOL",
              "ORPHENADRINE CITRATE ER": "ORPHENADRINE CITRATE ER",
              "SKELAXIN": "SKELAXIN",
              "SOMA": "SOMA",
              "TIZANIDINE HCL": "TIZANIDINE HCL"
            },
            "OXFORD PHARMACEUTICALS": {
              "CARISOPRODOL": "CARISOPRODOL",
              "METHOCARBAMOL": "METHOCARBAMOL"
            },
            "PAR PHARMACEUTICAL": {
              "TIZANIDINE HCL": "TIZANIDINE HCL"
            },
            "PAR PHARMACEUTICALS": {
              "BACLOFEN": "BACLOFEN",
              "CARISOPRODOL": "CARISOPRODOL",
              "CYCLOBENZAPRINE HCL": "CYCLOBENZAPRINE HCL",
              "METHOCARBAMOL": "METHOCARBAMOL"
            },
            "PCCA": {
              "BACLOFEN": "BACLOFEN",
              "CARISOPRODOL": "CARISOPRODOL",
              "ORPHENADRINE CITRATE": "ORPHENADRINE CITRATE"
            },
            "PDRX PHARMACEUTICAL": {
              "BACLOFEN": "BACLOFEN",
              "CARISOPRODOL": "CARISOPRODOL",
              "CHLORZOXAZONE": "CHLORZOXAZONE",
              "CYCLOBENZAPRINE HCL": "CYCLOBENZAPRINE HCL",
              "METAXALONE": "METAXALONE",
              "METHOCARBAMOL": "METHOCARBAMOL",
              "ORPHENADRINE CITRATE ER": "ORPHENADRINE CITRATE ER",
              "PARAFON FORTE DSC": "PARAFON FORTE DSC",
              "ROBAXIN-750": "ROBAXIN-750",
              "SKELAXIN": "SKELAXIN",
              "SOMA": "SOMA",
              "TIZANIDINE HCL": "TIZANIDINE HCL",
              "ZANAFLEX": "ZANAFLEX"
            },
            "PDRX PHARMACEUTICALS": {
              "CARISOPRODOL": "CARISOPRODOL",
              "CYCLOBENZAPRINE HCL": "CYCLOBENZAPRINE HCL",
              "METAXALONE": "METAXALONE",
              "METHOCARBAMOL": "METHOCARBAMOL"
            },
            "PFIZER U.S.": {
              "SKELAXIN": "SKELAXIN"
            },
            "PHARMACEUTICA NORTH AMERICA": {
              "ACTIVE-CYCLOBENZAPRINE": "ACTIVE-CYCLOBENZAPRINE",
              "BACLOFEN": "BACLOFEN",
              "CYCLOBENZAPRINE HCL": "CYCLOBENZAPRINE HCL",
              "ORPHENADRINE CITRATE": "ORPHENADRINE CITRATE"
            },
            "PHARMEDIX": {
              "CARISOPRODOL": "CARISOPRODOL",
              "CHLORZOXAZONE": "CHLORZOXAZONE",
              "CYCLOBENZAPRINE HCL": "CYCLOBENZAPRINE HCL",
              "METHOCARBAMOL": "METHOCARBAMOL",
              "TIZANIDINE HCL": "TIZANIDINE HCL"
            },
            "PHARMPAK": {
              "CYCLOBENZAPRINE HCL": "CYCLOBENZAPRINE HCL",
              "METHOCARBAMOL": "METHOCARBAMOL"
            },
            "PIRAMAL CRITICAL CARE": {
              "GABLOFEN": "GABLOFEN"
            },
            "PREFERRED PHARMACEUTICALS": {
              "BACLOFEN": "BACLOFEN",
              "CARISOPRODOL": "CARISOPRODOL",
              "CYCLOBENZAPRINE HCL": "CYCLOBENZAPRINE HCL",
              "ENOVARX-CYCLOBENZAPRINE HCL": "ENOVARX-CYCLOBENZAPRINE HCL",
              "METAXALONE": "METAXALONE",
              "METHOCARBAMOL": "METHOCARBAMOL",
              "ORPHENADRINE CITRATE ER": "ORPHENADRINE CITRATE ER",
              "TIZANIDINE HCL": "TIZANIDINE HCL"
            },
            "PROFICIENT RX": {
              "BACLOFEN": "BACLOFEN",
              "CARISOPRODOL": "CARISOPRODOL",
              "CHLORZOXAZONE": "CHLORZOXAZONE",
              "CYCLOBENZAPRINE HCL": "CYCLOBENZAPRINE HCL",
              "CYCLOBENZAPRINE HCL ER": "CYCLOBENZAPRINE HCL ER",
              "FEXMID": "FEXMID",
              "METAXALONE": "METAXALONE",
              "METHOCARBAMOL": "METHOCARBAMOL",
              "ORPHENADRINE CITRATE ER": "ORPHENADRINE CITRATE ER",
              "TIZANIDINE HCL": "TIZANIDINE HCL"
            },
            "QUALITY CARE": {
              "AMRIX": "AMRIX",
              "BACLOFEN": "BACLOFEN",
              "CARISOPRODOL": "CARISOPRODOL",
              "CHLORZOXAZONE": "CHLORZOXAZONE",
              "CYCLOBENZAPRINE HCL": "CYCLOBENZAPRINE HCL",
              "METAXALONE": "METAXALONE",
              "METHOCARBAMOL": "METHOCARBAMOL",
              "ORPHENADRINE CITRATE ER": "ORPHENADRINE CITRATE ER",
              "SKELAXIN": "SKELAXIN",
              "TIZANIDINE HCL": "TIZANIDINE HCL",
              "ZANAFLEX": "ZANAFLEX"
            },
            "QUALITY CARE PRODUCTS": {
              "BACLOFEN": "BACLOFEN",
              "CARISOPRODOL": "CARISOPRODOL",
              "CYCLOBENZAPRINE HCL": "CYCLOBENZAPRINE HCL",
              "CYCLOBENZAPRINE HCL ER": "CYCLOBENZAPRINE HCL ER",
              "FEXMID": "FEXMID",
              "LORZONE": "LORZONE",
              "METAXALONE": "METAXALONE",
              "METHOCARBAMOL": "METHOCARBAMOL",
              "TIZANIDINE HCL": "TIZANIDINE HCL"
            },
            "READYMEDS": {
              "CYCLOBENZAPRINE HCL": "CYCLOBENZAPRINE HCL"
            },
            "RENAISSANCE LAKEWOOD": {
              "METHOCARBAMOL": "METHOCARBAMOL"
            },
            "RISING PHARMACEUTICALS": {
              "CARISOPRODOL": "CARISOPRODOL",
              "CYCLOBENZAPRINE HCL": "CYCLOBENZAPRINE HCL",
              "METAXALONE": "METAXALONE"
            },
            "RXCHANGE CO": {
              "BACLOFEN": "BACLOFEN",
              "CARISOPRODOL": "CARISOPRODOL",
              "CHLORZOXAZONE": "CHLORZOXAZONE",
              "CYCLOBENZAPRINE HCL": "CYCLOBENZAPRINE HCL",
              "METHOCARBAMOL": "METHOCARBAMOL",
              "ORPHENADRINE CITRATE ER": "ORPHENADRINE CITRATE ER",
              "SKELAXIN": "SKELAXIN",
              "TIZANIDINE HCL": "TIZANIDINE HCL"
            },
            "SA3": {
              "CYCLOBENZAPRINE HCL": "CYCLOBENZAPRINE HCL",
              "TIZANIDINE HCL": "TIZANIDINE HCL"
            },
            "SAGENT PHARMACEUTICAL": {
              "METHOCARBAMOL": "METHOCARBAMOL",
              "ORPHENADRINE CITRATE": "ORPHENADRINE CITRATE"
            },
            "SALLUS LABORATORIES": {
              "METHOCARBAMOL": "METHOCARBAMOL",
              "VANADOM": "VANADOM"
            },
            "SANDOZ": {
              "CARISOPRODOL": "CARISOPRODOL",
              "METAXALONE": "METAXALONE",
              "ORPHENADRINE CITRATE ER": "ORPHENADRINE CITRATE ER",
              "TIZANIDINE HCL": "TIZANIDINE HCL"
            },
            "SAOL THERAPEUTICS": {
              "LIORESAL": "LIORESAL"
            },
            "SCIEGEN PHARMACEUTICALS": {
              "CARISOPRODOL": "CARISOPRODOL"
            },
            "SIRCLE LABORATORIES": {
              "METAXALL": "METAXALL"
            },
            "SKY PACKAGING": {
              "BACLOFEN": "BACLOFEN",
              "CARISOPRODOL": "CARISOPRODOL",
              "CYCLOBENZAPRINE HCL": "CYCLOBENZAPRINE HCL",
              "METHOCARBAMOL": "METHOCARBAMOL"
            },
            "SOLCO HEALTHCARE": {
              "CYCLOBENZAPRINE HCL": "CYCLOBENZAPRINE HCL",
              "METHOCARBAMOL": "METHOCARBAMOL"
            },
            "SOLUBIOMIX LLC": {
              "CHLORZOXAZONE": "CHLORZOXAZONE"
            },
            "SOMERSET THERAPEUTICS": {
              "METHOCARBAMOL": "METHOCARBAMOL"
            },
            "SPECTRUM": {
              "BACLOFEN": "BACLOFEN",
              "CARISOPRODOL": "CARISOPRODOL",
              "ORPHENADRINE CITRATE": "ORPHENADRINE CITRATE"
            },
            "ST MARY'S MPP": {
              "BACLOFEN": "BACLOFEN",
              "CARISOPRODOL": "CARISOPRODOL",
              "CHLORZOXAZONE": "CHLORZOXAZONE",
              "CYCLOBENZAPRINE HCL": "CYCLOBENZAPRINE HCL",
              "METAXALONE": "METAXALONE",
              "METHOCARBAMOL": "METHOCARBAMOL",
              "ORPHENADRINE CITRATE ER": "ORPHENADRINE CITRATE ER",
              "SKELAXIN": "SKELAXIN",
              "TIZANIDINE HCL": "TIZANIDINE HCL"
            },
            "STERLING KNIGHT PHARMACEUTICAL": {
              "CYCLOBENZAPRINE HCL": "CYCLOBENZAPRINE HCL",
              "METAXALONE": "METAXALONE"
            },
            "SUN PHARMACEUTICALS": {
              "CARISOPRODOL": "CARISOPRODOL",
              "TIZANIDINE HCL": "TIZANIDINE HCL"
            },
            "TEVA PHARMACEUTICALS USA": {
              "AMRIX": "AMRIX",
              "BACLOFEN": "BACLOFEN",
              "CYCLOBENZAPRINE HCL": "CYCLOBENZAPRINE HCL",
              "CYCLOBENZAPRINE HCL ER": "CYCLOBENZAPRINE HCL ER"
            },
            "TOTAL PHARMACY SUPPLY": {
              "BACLOFEN": "BACLOFEN"
            },
            "TRIGEN LABORATORIES": {
              "CHLORZOXAZONE": "CHLORZOXAZONE"
            },
            "TRIOVA PHARMACEUTICALS": {
              "BACLOFEN": "BACLOFEN"
            },
            "TRUPHARMA": {
              "BACLOFEN": "BACLOFEN",
              "CHLORZOXAZONE": "CHLORZOXAZONE",
              "CYCLOBENZAPRINE HCL": "CYCLOBENZAPRINE HCL"
            },
            "TWI PHARMACEUTICALS": {
              "CYCLOBENZAPRINE HCL ER": "CYCLOBENZAPRINE HCL ER"
            },
            "UNICHEM PHARMACEUTICALS": {
              "BACLOFEN": "BACLOFEN",
              "CYCLOBENZAPRINE HCL": "CYCLOBENZAPRINE HCL",
              "TIZANIDINE HCL": "TIZANIDINE HCL"
            },
            "UNIT DOSE SERVICES": {
              "BACLOFEN": "BACLOFEN",
              "CARISOPRODOL": "CARISOPRODOL",
              "CHLORZOXAZONE": "CHLORZOXAZONE",
              "CYCLOBENZAPRINE HCL": "CYCLOBENZAPRINE HCL",
              "FEXMID": "FEXMID",
              "METAXALONE": "METAXALONE",
              "METHOCARBAMOL": "METHOCARBAMOL",
              "ORPHENADRINE CITRATE ER": "ORPHENADRINE CITRATE ER",
              "TIZANIDINE HCL": "TIZANIDINE HCL"
            },
            "UPSHER-SMITH": {
              "BACLOFEN": "BACLOFEN"
            },
            "VA CMOP DALLAS": {
              "CYCLOBENZAPRINE HCL": "CYCLOBENZAPRINE HCL",
              "METHOCARBAMOL": "METHOCARBAMOL"
            },
            "VENSUN PHARMACEUTICALS": {
              "CARISOPRODOL": "CARISOPRODOL"
            },
            "VERTICAL PHARMACEUTICAL": {
              "LORZONE": "LORZONE"
            },
            "VIRTUE RX": {
              "CYCLOBENZAPRINE HCL": "CYCLOBENZAPRINE HCL"
            },
            "VIRTUS PHARMACEUTICALS": {
              "METHOCARBAMOL": "METHOCARBAMOL"
            },
            "VIRTUS PHARMACEUTICALS OPCO": {
              "METHOCARBAMOL": "METHOCARBAMOL"
            },
            "WALLACE PHARMACEUTICALS": {
              "CARISOPRODOL": "CARISOPRODOL"
            },
            "WILLOW BIRCH PHARMA": {
              "BACLOFEN": "BACLOFEN"
            },
            "WILSHIRE PHARMACEUTICALS": {
              "CARISOPRODOL": "CARISOPRODOL"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "BACLOFEN": "BACLOFEN",
              "TIZANIDINE HCL": "TIZANIDINE HCL"
            }
          }
        },
        "Direct Muscle Relaxants": {
          "Direct Muscle Relaxants": {
            "AMERICAN HEALTH PACKAGING": {
              "DANTROLENE SODIUM": "DANTROLENE SODIUM"
            },
            "AMNEAL PHARMACEUTICALS": {
              "DANTROLENE SODIUM": "DANTROLENE SODIUM"
            },
            "EAGLE PHARMACEUTICALS": {
              "RYANODEX": "RYANODEX"
            },
            "HIKMA": {
              "DANTROLENE SODIUM": "DANTROLENE SODIUM"
            },
            "LANNETT": {
              "DANTROLENE SODIUM": "DANTROLENE SODIUM"
            },
            "NOVA PLUS/US WORLDMEDS": {
              "REVONTO": "REVONTO"
            },
            "PAR PHARMACEUTICAL": {
              "DANTROLENE SODIUM": "DANTROLENE SODIUM"
            },
            "PAR STERILE PRODUCTS": {
              "DANTRIUM": "DANTRIUM",
              "DANTROLENE SODIUM": "DANTROLENE SODIUM"
            },
            "US WORLDMEDS": {
              "REVONTO": "REVONTO"
            }
          }
        },
        "Muscle Relaxant Combinations": {
          "Muscle Relaxant Combinations": {
            "ATLAND PHARMACEUTICALS": {
              "ORPHENADRINE-ASPIRIN-CAFFEINE": "ORPHENADRINE-ASPIRIN-CAFFEINE"
            },
            "AVET PHARMACEUTICALS": {
              "CARISOPRODOL-ASPIRIN": "CARISOPRODOL-ASPIRIN"
            },
            "GALT PHARMACEUTICALS": {
              "ORPHENGESIC FORTE": "ORPHENGESIC FORTE"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "CARISOPRODOL-ASPIRIN": "CARISOPRODOL-ASPIRIN",
              "CARISOPRODOL-ASPIRIN-CODEINE": "CARISOPRODOL-ASPIRIN-CODEINE"
            },
            "INA PHARMACEUTICS": {
              "ORPHENADRINE-ASA-CAFFEINE": "ORPHENADRINE-ASA-CAFFEINE"
            },
            "INGENUS PHARMACEUTICALS": {
              "CARISOPRODOL-ASPIRIN-CODEINE": "CARISOPRODOL-ASPIRIN-CODEINE"
            },
            "LIBERTY PHARMACEUTICAL": {
              "CARISOPRODOL-ASPIRIN": "CARISOPRODOL-ASPIRIN"
            },
            "PDRX PHARMACEUTICAL": {
              "CARISOPRODOL-ASPIRIN": "CARISOPRODOL-ASPIRIN"
            },
            "POLY PHARMACEUTICALS": {
              "NORGESIC FORTE": "NORGESIC FORTE"
            },
            "RISING PHARMACEUTICALS": {
              "CARISOPRODOL-ASPIRIN": "CARISOPRODOL-ASPIRIN",
              "CARISOPRODOL-ASPIRIN-CODEINE": "CARISOPRODOL-ASPIRIN-CODEINE"
            },
            "SANDOZ": {
              "CARISOPRODOL-ASPIRIN": "CARISOPRODOL-ASPIRIN",
              "CARISOPRODOL-ASPIRIN-CODEINE": "CARISOPRODOL-ASPIRIN-CODEINE"
            },
            "SIRCLE LABORATORIES": {
              "LORVATUS PHARMAPAK": "LORVATUS PHARMAPAK",
              "METAXALL CP": "METAXALL CP"
            },
            "TMIG": {
              "CYCLO/GABA 10/300": "CYCLO/GABA 10/300"
            }
          },
          "Muscle Relaxant-Liniments Combinations": {
            "BASIEM": {
              "FLEXEPAX": "FLEXEPAX"
            },
            "PDRX PHARMACEUTICAL": {
              "TIZANIDINE COMFORT PAC": "TIZANIDINE COMFORT PAC"
            },
            "SKYA HEALTH": {
              "NOPIOID-LMC KIT": "NOPIOID-LMC KIT",
              "NOPIOID-TC KIT": "NOPIOID-TC KIT"
            },
            "SOLUBIOMIX LLC": {
              "CYCLOBENZAPRINEPAX": "CYCLOBENZAPRINEPAX"
            }
          }
        },
        "Viscosupplements": {
          "Viscosupplements": {
            "A-S MEDICATION SOLUTIONS": {
              "HYALGAN": "HYALGAN"
            },
            "BIOVENTUS": {
              "DUROLANE": "DUROLANE",
              "GELSYN-3": "GELSYN-3",
              "SUPARTZ": "SUPARTZ",
              "SUPARTZ FX": "SUPARTZ FX"
            },
            "DEPUY MITEK": {
              "MONOVISC": "MONOVISC",
              "ORTHOVISC": "ORTHOVISC"
            },
            "FERRING": {
              "EUFLEXXA": "EUFLEXXA"
            },
            "FIDIA PHARMACEUTICAL USA": {
              "HYALGAN": "HYALGAN",
              "HYMOVIS": "HYMOVIS",
              "TRILURON": "TRILURON"
            },
            "GENZYME": {
              "SYNVISC": "SYNVISC",
              "SYNVISC ONE": "SYNVISC ONE"
            },
            "NUCARE PHARMACEUTICALS": {
              "SYNVISC": "SYNVISC"
            },
            "ORTHOGENRX": {
              "GENVISC 850": "GENVISC 850",
              "TRIVISC": "TRIVISC"
            },
            "QUALITY CARE": {
              "HYALGAN": "HYALGAN",
              "ORTHOVISC": "ORTHOVISC",
              "SUPARTZ": "SUPARTZ",
              "SYNVISC": "SYNVISC"
            },
            "TEVA SELECT BRANDS": {
              "SODIUM HYALURONATE": "SODIUM HYALURONATE"
            },
            "ZIMMER": {
              "GEL-ONE": "GEL-ONE",
              "VISCO-3": "VISCO-3"
            }
          }
        }
      },
      "NASAL AGENTS - SYSTEMIC AND TOPICAL": {
        "Nasal Agent Combinations": {
          "Antihistamine-Steroid": {
            "APOTEX": {
              "AZELASTINE-FLUTICASONE": "AZELASTINE-FLUTICASONE"
            },
            "MYLAN": {
              "AZELASTINE-FLUTICASONE": "AZELASTINE-FLUTICASONE"
            },
            "MYLAN SPECIALTY L.P.": {
              "DYMISTA": "DYMISTA"
            },
            "PURE TEK": {
              "DERMACINRX AZENASE PAK": "DERMACINRX AZENASE PAK"
            },
            "SHORELINE PHARMACEUTICALS": {
              "TICALAST": "TICALAST"
            }
          },
          "Decongestant Combination Other": {
            "BAYER CONSUMER": {
              "AFRIN MENTHOL SPRAY": "AFRIN MENTHOL SPRAY"
            }
          }
        },
        "Nasal Agents - Misc.": {
          "Nasal Agents - Misc.": {
            "A-S MEDICATION SOLUTIONS": {
              "AYR": "AYR",
              "SODIUM CHLORIDE": "SODIUM CHLORIDE"
            },
            "AIDAREX PHARMACEUTICALS": {
              "SALINE MIST SPRAY": "SALINE MIST SPRAY"
            },
            "ALTAIRE": {
              "ALTAMIST SPRAY": "ALTAMIST SPRAY"
            },
            "ARBOR PHARMACEUTICALS": {
              "RHINARIS": "RHINARIS"
            },
            "AURORA HEALTHCARE": {
              "SALINE NASAL SPRAY": "SALINE NASAL SPRAY"
            },
            "B.F. ASCHER": {
              "AYR": "AYR",
              "AYR NASAL MIST ALLERGY/SINUS": "AYR NASAL MIST ALLERGY/SINUS",
              "AYR SALINE NASAL": "AYR SALINE NASAL",
              "AYR SALINE NASAL DROPS": "AYR SALINE NASAL DROPS",
              "AYR SALINE NASAL NO-DRIP": "AYR SALINE NASAL NO-DRIP",
              "BABY AYR SALINE": "BABY AYR SALINE"
            },
            "BAUSCH HEALTH": {
              "OCEAN FOR KIDS": "OCEAN FOR KIDS",
              "OCEAN NASAL SPRAY": "OCEAN NASAL SPRAY"
            },
            "BAYER CONSUMER": {
              "AFRIN SALINE NASAL MIST": "AFRIN SALINE NASAL MIST"
            },
            "BERGEN BRUNSWIG": {
              "GNP NASAL MOISTURIZING": "GNP NASAL MOISTURIZING"
            },
            "BLAIREX LABS": {
              "NASAL MOIST": "NASAL MOIST",
              "SIMPLY SALINE": "SIMPLY SALINE"
            },
            "CHAIN DRUG CONSORTIUM": {
              "SALINE NASAL SPRAY": "SALINE NASAL SPRAY"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC SALINE NASAL RELIEF": "QC SALINE NASAL RELIEF"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS NASAL MIST": "CVS NASAL MIST",
              "CVS SALINE NASAL SPRAY": "CVS SALINE NASAL SPRAY",
              "CVS SALINE NOSE SPRAY": "CVS SALINE NOSE SPRAY"
            },
            "EQUALINE": {
              "EQL SALINE NASAL SPRAY": "EQL SALINE NASAL SPRAY"
            },
            "GERI-CARE": {
              "SALINE NASAL SPRAY": "SALINE NASAL SPRAY"
            },
            "GRAXCELL PHARMACEUTICAL": {
              "SALINE NASAL SPRAY": "SALINE NASAL SPRAY"
            },
            "HANNAFORD BROTHERS": {
              "SALINE NASAL SPRAY": "SALINE NASAL SPRAY"
            },
            "HUDSON SCIENTIFIC": {
              "ALZAIR ALLERGY NASAL SPRAY": "ALZAIR ALLERGY NASAL SPRAY"
            },
            "LEADER BRAND PRODUCTS": {
              "SALINE NASAL SPRAY": "SALINE NASAL SPRAY"
            },
            "MAJOR PHARMACEUTICALS": {
              "DEEP SEA NASAL SPRAY": "DEEP SEA NASAL SPRAY"
            },
            "MCKESSON": {
              "HM SALINE NASAL SPRAY": "HM SALINE NASAL SPRAY"
            },
            "MCKESSON SUNMARK": {
              "SM NASAL SPRAY SALINE": "SM NASAL SPRAY SALINE"
            },
            "MEDTECH": {
              "LITTLE NOSES SALINE": "LITTLE NOSES SALINE",
              "LITTLE NOSES SALINE NASAL MIST": "LITTLE NOSES SALINE NASAL MIST",
              "LITTLE NOSES STUFFY NOSE KIT": "LITTLE NOSES STUFFY NOSE KIT",
              "LITTLE REMEDIES SALINE MIST": "LITTLE REMEDIES SALINE MIST"
            },
            "MEIJER": {
              "MEIJER SALINE NASAL SPRAY": "MEIJER SALINE NASAL SPRAY"
            },
            "NEILMED PHARMACEUTICALS": {
              "NASADROPS SALINE ON THE GO": "NASADROPS SALINE ON THE GO",
              "NASOGEL": "NASOGEL"
            },
            "PAR PHARMACEUTICAL": {
              "SEA SOFT NASAL MIST": "SEA SOFT NASAL MIST"
            },
            "PERRIGO": {
              "NASAL MOISTURIZING SPRAY": "NASAL MOISTURIZING SPRAY",
              "SALINE MIST SPRAY": "SALINE MIST SPRAY"
            },
            "PROFICIENT RX": {
              "DEEP SEA NASAL SPRAY": "DEEP SEA NASAL SPRAY"
            },
            "PUBLIX SUPER MARKETS INC.": {
              "PX SALINE NASAL SPRAY": "PX SALINE NASAL SPRAY"
            },
            "RELIABLE 1 LABS": {
              "SALINE NASAL SPRAY": "SALINE NASAL SPRAY"
            },
            "RITE AID CORPORATION": {
              "RA SALINE NASAL SPRAY": "RA SALINE NASAL SPRAY",
              "RA STERILE SALINE NASAL MIST": "RA STERILE SALINE NASAL MIST"
            },
            "RUGBY LABORATORIES": {
              "SALINE MIST SPRAY": "SALINE MIST SPRAY"
            },
            "SCHERER": {
              "HUMIST": "HUMIST"
            },
            "SELECT BRAND": {
              "SB SALINE NOSE": "SB SALINE NOSE"
            },
            "SHEFFIELD PHARMACEUTICALS": {
              "SALINE NASAL SPRAY": "SALINE NASAL SPRAY"
            },
            "SNUVA": {
              "NA-ZONE": "NA-ZONE"
            },
            "TARGET": {
              "TGT NASAL SPRAY": "TGT NASAL SPRAY",
              "TGT SALINE NASAL SPRAY": "TGT SALINE NASAL SPRAY"
            },
            "TOPCO": {
              "NASAL MOISTURIZING SPRAY": "NASAL MOISTURIZING SPRAY"
            },
            "WAL-MART": {
              "EQ SALINE NASAL SPRAY": "EQ SALINE NASAL SPRAY"
            },
            "WALGREENS": {
              "SALINE": "SALINE",
              "SALINE NASAL SPRAY": "SALINE NASAL SPRAY",
              "SINUS WASH SALT": "SINUS WASH SALT"
            }
          },
          "Nasal Agents Misc. - Combinations": {
            "ARBOR PHARMACEUTICALS": {
              "SALTAIRE": "SALTAIRE"
            },
            "B.F. ASCHER": {
              "AYR SALINE NASAL GEL": "AYR SALINE NASAL GEL",
              "AYR SALINE NASAL NETI RINSE": "AYR SALINE NASAL NETI RINSE",
              "AYR SALINE NASAL RINSE": "AYR SALINE NASAL RINSE"
            },
            "BAUSCH HEALTH": {
              "OCEAN COMPLETE SINUS RINSE": "OCEAN COMPLETE SINUS RINSE",
              "OCEAN NASAL MOISTURIZER": "OCEAN NASAL MOISTURIZER",
              "OCEAN ULTRA SALINE MIST": "OCEAN ULTRA SALINE MIST"
            },
            "BERGEN BRUNSWIG": {
              "GNP SINUS WASH NETI POT": "GNP SINUS WASH NETI POT",
              "GNP SINUS WASH REFILL": "GNP SINUS WASH REFILL"
            },
            "BLAIREX LABS": {
              "NASAL MOIST": "NASAL MOIST"
            },
            "CHAIN DRUG CONSORTIUM": {
              "KETTLE NETI POT SINUS WASH": "KETTLE NETI POT SINUS WASH",
              "SINUS WASH SQUEEZE BOTTLE": "SINUS WASH SQUEEZE BOTTLE"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS SINUS WASH NETI POT": "CVS SINUS WASH NETI POT",
              "CVS SINUS WASH REFILL": "CVS SINUS WASH REFILL",
              "CVS SINUS WASH SYSTEM": "CVS SINUS WASH SYSTEM"
            },
            "DRNATURALHEALING": {
              "NASALCARE": "NASALCARE",
              "NASALCARE FOR KIDS": "NASALCARE FOR KIDS",
              "NASALCARE FOR KIDS STARTER KIT": "NASALCARE FOR KIDS STARTER KIT",
              "NASALCARE NASALCLEANSE KIT": "NASALCARE NASALCLEANSE KIT"
            },
            "EURUS PHARMA": {
              "GELONASAL": "GELONASAL"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "4-WAY SALINE": "4-WAY SALINE"
            },
            "JAMOL LABS": {
              "PONARIS": "PONARIS"
            },
            "LEADER BRAND PRODUCTS": {
              "NASAL CLEANSE RINSE MIX": "NASAL CLEANSE RINSE MIX",
              "SINUS WASH KETTLE NETI POT": "SINUS WASH KETTLE NETI POT",
              "SINUS WASH SALINE REFILLS": "SINUS WASH SALINE REFILLS"
            },
            "MCKESSON SUNMARK": {
              "SM SINUS WASH": "SM SINUS WASH",
              "SM SINUS WASH NETI POT": "SM SINUS WASH NETI POT"
            },
            "MED-SYSTEMS INC": {
              "SINUCLEANSE NETI POT": "SINUCLEANSE NETI POT",
              "SINUCLEANSE REFILL": "SINUCLEANSE REFILL",
              "SINUCLEANSE SQUEEZE": "SINUCLEANSE SQUEEZE"
            },
            "MEDTECH": {
              "LITTLE NOSES MOISTURIZING": "LITTLE NOSES MOISTURIZING",
              "LITTLE REMEDIES FOR NOSES": "LITTLE REMEDIES FOR NOSES"
            },
            "NEILMED PHARMACEUTICALS": {
              "NASADOCK PLUS": "NASADOCK PLUS",
              "NASAFLO NETI POT NASAL WASH": "NASAFLO NETI POT NASAL WASH",
              "NASAFLO PORCELAIN NASAL RINSE": "NASAFLO PORCELAIN NASAL RINSE",
              "NASAMIST ALL-IN-ONE": "NASAMIST ALL-IN-ONE",
              "NASAMIST HYPERTONIC": "NASAMIST HYPERTONIC",
              "NASAMIST ISOTONIC": "NASAMIST ISOTONIC",
              "SINUFLO READYRINSE": "SINUFLO READYRINSE",
              "SINUGATOR NASAL WASH": "SINUGATOR NASAL WASH",
              "SINUS RINSE": "SINUS RINSE",
              "SINUS RINSE BOTTLE KIT": "SINUS RINSE BOTTLE KIT",
              "SINUS RINSE KIT": "SINUS RINSE KIT",
              "SINUS RINSE KIT PEDIATRIC": "SINUS RINSE KIT PEDIATRIC",
              "SINUS RINSE PEDIATRIC STARTER": "SINUS RINSE PEDIATRIC STARTER",
              "SINUS RINSE REFILL": "SINUS RINSE REFILL",
              "SINUS RINSE REFILL PEDIATRIC": "SINUS RINSE REFILL PEDIATRIC"
            },
            "OAKHURST": {
              "NOSE BETTER": "NOSE BETTER",
              "NOSE BETTER NATURAL MIST": "NOSE BETTER NATURAL MIST"
            },
            "PARNELL": {
              "PRETZ": "PRETZ",
              "PRETZ IRRIGATION": "PRETZ IRRIGATION",
              "PRETZ NATUR MOIST NASAL SPRAY": "PRETZ NATUR MOIST NASAL SPRAY"
            },
            "PHARMADERM": {
              "ENTSOL NASAL": "ENTSOL NASAL"
            },
            "PROFOUNDA": {
              "RHINASE": "RHINASE"
            },
            "PURE TEK": {
              "DERMACINRX TICANASE PAK": "DERMACINRX TICANASE PAK"
            },
            "RITE AID CORPORATION": {
              "RA MICRO-FILTERED SINUS WASH": "RA MICRO-FILTERED SINUS WASH",
              "RA SINUS WASH NASAL RELIEF": "RA SINUS WASH NASAL RELIEF",
              "RA SINUS WASH NETI POT": "RA SINUS WASH NETI POT"
            },
            "SHORELINE PHARMACEUTICALS": {
              "TICASPRAY": "TICASPRAY"
            },
            "THE ALKALOL COMPANY": {
              "ALKALOL": "ALKALOL"
            },
            "WALGREENS": {
              "CLASSIC NETI POT SINUS WASH": "CLASSIC NETI POT SINUS WASH",
              "KETTLE NETI POT SINUS WASH": "KETTLE NETI POT SINUS WASH",
              "NETI POT SINUS WASH": "NETI POT SINUS WASH",
              "SALINE": "SALINE",
              "SINUS WASH SQUEEZE BOTTLE": "SINUS WASH SQUEEZE BOTTLE",
              "SQUEEZE BOTTLE SINUS WASH": "SQUEEZE BOTTLE SINUS WASH"
            },
            "XLEAR": {
              "XLEAR SINUS CARE SPRAY": "XLEAR SINUS CARE SPRAY",
              "XLEAR SINUS CARE SPRAY/KIDS": "XLEAR SINUS CARE SPRAY/KIDS"
            }
          }
        },
        "Nasal Anesthetics": {
          "Nasal Anesthetics": {
            "GENUS LIFESCIENCES": {
              "COCAINE HCL": "COCAINE HCL",
              "GOPRELTO": "GOPRELTO"
            },
            "LANNETT": {
              "COCAINE HCL": "COCAINE HCL",
              "NUMBRINO": "NUMBRINO"
            }
          }
        },
        "Nasal Anti-infectives": {
          "Nasal Antibiotics": {
            "AIDAREX PHARMACEUTICALS": {
              "BACTROBAN NASAL": "BACTROBAN NASAL"
            },
            "GLAXO SMITH KLINE": {
              "BACTROBAN NASAL": "BACTROBAN NASAL"
            },
            "QUALITY CARE": {
              "BACTROBAN NASAL": "BACTROBAN NASAL"
            }
          }
        },
        "Nasal Antiallergy": {
          "Nasal Antihistamines": {
            "A-S MEDICATION SOLUTIONS": {
              "AZELASTINE HCL": "AZELASTINE HCL"
            },
            "AKORN": {
              "AZELASTINE HCL": "AZELASTINE HCL",
              "OLOPATADINE HCL": "OLOPATADINE HCL"
            },
            "AMNEAL PHARMACEUTICALS": {
              "AZELASTINE HCL": "AZELASTINE HCL"
            },
            "APOTEX": {
              "AZELASTINE HCL": "AZELASTINE HCL",
              "OLOPATADINE HCL": "OLOPATADINE HCL"
            },
            "ASCEND LABORATORIES": {
              "AZELASTINE HCL": "AZELASTINE HCL"
            },
            "AUROBINDO PHARMA": {
              "AZELASTINE HCL": "AZELASTINE HCL"
            },
            "AVKARE": {
              "AZELASTINE HCL": "AZELASTINE HCL"
            },
            "BRECKENRIDGE": {
              "AZELASTINE HCL": "AZELASTINE HCL"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "PATANASE": "PATANASE"
            },
            "HIKMA": {
              "AZELASTINE HCL": "AZELASTINE HCL"
            },
            "MYLAN SPECIALTY L.P.": {
              "ASTEPRO": "ASTEPRO"
            },
            "NOVARTIS": {
              "PATANASE": "PATANASE"
            },
            "PERRIGO PHARMACEUTICALS": {
              "AZELASTINE HCL": "AZELASTINE HCL",
              "OLOPATADINE HCL": "OLOPATADINE HCL"
            },
            "PROFICIENT RX": {
              "AZELASTINE HCL": "AZELASTINE HCL"
            },
            "SANDOZ": {
              "OLOPATADINE HCL": "OLOPATADINE HCL"
            },
            "SUN PHARMACEUTICALS": {
              "AZELASTINE HCL": "AZELASTINE HCL"
            },
            "WALLACE PHARMACEUTICALS": {
              "AZELASTINE HCL": "AZELASTINE HCL"
            }
          },
          "Nasal Mast Cell Stabilizers": {
            "BAUSCH HEALTH": {
              "CROMOLYN SODIUM": "CROMOLYN SODIUM"
            },
            "CHAIN DRUG CONSORTIUM": {
              "NASAL ALLERGY": "NASAL ALLERGY"
            },
            "MAJOR PHARMACEUTICALS": {
              "CROMOLYN SODIUM": "CROMOLYN SODIUM"
            },
            "MEDICINE SHOPPE": {
              "CROMOLYN SODIUM": "CROMOLYN SODIUM"
            },
            "MEDTECH": {
              "NASALCROM": "NASALCROM"
            }
          }
        },
        "Nasal Anticholinergics": {
          "Nasal Anticholinergics": {
            "APOTEX": {
              "IPRATROPIUM BROMIDE": "IPRATROPIUM BROMIDE"
            },
            "BAUSCH HEALTH": {
              "IPRATROPIUM BROMIDE": "IPRATROPIUM BROMIDE"
            },
            "BOEHRINGER INGELHEIM": {
              "ATROVENT": "ATROVENT"
            },
            "HIKMA": {
              "IPRATROPIUM BROMIDE": "IPRATROPIUM BROMIDE"
            },
            "PRESCRIPTION SOLUTIONS, INC.": {
              "IPRATROPIUM BROMIDE": "IPRATROPIUM BROMIDE"
            },
            "QUALITY CARE": {
              "IPRATROPIUM BROMIDE": "IPRATROPIUM BROMIDE"
            }
          }
        },
        "Nasal Steroids": {
          "Nasal Steroids": {
            "A-S MEDICATION SOLUTIONS": {
              "BECONASE AQ": "BECONASE AQ",
              "FLONASE": "FLONASE",
              "FLONASE ALLERGY RELIEF": "FLONASE ALLERGY RELIEF",
              "FLUTICASONE PROPIONATE": "FLUTICASONE PROPIONATE",
              "NASACORT ALLERGY 24HR": "NASACORT ALLERGY 24HR",
              "NASONEX": "NASONEX"
            },
            "AIDAREX PHARMACEUTICALS": {
              "NASONEX": "NASONEX"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP 24 HOUR NASAL ALLERGY": "GNP 24 HOUR NASAL ALLERGY",
              "GNP BUDESONIDE NASAL SPRAY": "GNP BUDESONIDE NASAL SPRAY",
              "GNP FLUTICASONE PROPIONATE": "GNP FLUTICASONE PROPIONATE",
              "GNP FLUTICASONE PROPIONATE CHL": "GNP FLUTICASONE PROPIONATE CHL"
            },
            "AMNEAL PHARMACEUTICALS": {
              "MOMETASONE FUROATE": "MOMETASONE FUROATE"
            },
            "APOTEX": {
              "BUDESONIDE": "BUDESONIDE",
              "FLUTICASONE PROPIONATE": "FLUTICASONE PROPIONATE",
              "MOMETASONE FUROATE": "MOMETASONE FUROATE"
            },
            "APOTEX CONSUMER": {
              "FLUTICASONE PROPIONATE": "FLUTICASONE PROPIONATE"
            },
            "ASTRAZENECA LP": {
              "RHINOCORT AQUA": "RHINOCORT AQUA"
            },
            "AVKARE": {
              "MOMETASONE FUROATE": "MOMETASONE FUROATE"
            },
            "BAUSCH HEALTH": {
              "FLUNISOLIDE": "FLUNISOLIDE"
            },
            "BAYER CONSUMER": {
              "CLARISPRAY": "CLARISPRAY"
            },
            "CHAIN DRUG CONSORTIUM": {
              "FLUTICASONE PROPIONATE": "FLUTICASONE PROPIONATE"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC ALLERGY RELIEF": "QC ALLERGY RELIEF",
              "QC FLUTICASONE PROPIONATE": "QC FLUTICASONE PROPIONATE"
            },
            "CHATTEM INC": {
              "NASACORT ALLERGY 24HR": "NASACORT ALLERGY 24HR",
              "NASACORT ALLERGY 24HR CHILDREN": "NASACORT ALLERGY 24HR CHILDREN"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "FLUTICASONE PROPIONATE": "FLUTICASONE PROPIONATE"
            },
            "COSTCO WHOLESALE": {
              "KLS ALLER-CORT": "KLS ALLER-CORT",
              "KLS ALLER-FLO": "KLS ALLER-FLO"
            },
            "COVIS PHARMA": {
              "OMNARIS": "OMNARIS",
              "ZETONNA": "ZETONNA"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS BUDESONIDE": "CVS BUDESONIDE",
              "CVS FLUTICASONE PROPIONATE": "CVS FLUTICASONE PROPIONATE",
              "CVS NASAL ALLERGY SPRAY": "CVS NASAL ALLERGY SPRAY"
            },
            "DIRECT RX": {
              "FLUTICASONE PROPIONATE": "FLUTICASONE PROPIONATE"
            },
            "EQUALINE": {
              "EQL FLUTICASONE CHILDRENS": "EQL FLUTICASONE CHILDRENS",
              "EQL FLUTICASONE PROPIONATE": "EQL FLUTICASONE PROPIONATE"
            },
            "GEISS DESTIN & DUNN": {
              "GOODSENSE NASAL ALLERGY SPRAY": "GOODSENSE NASAL ALLERGY SPRAY",
              "GOODSENSE NASOFLOW": "GOODSENSE NASOFLOW",
              "NASAL ALLERGY 24 HOUR": "NASAL ALLERGY 24 HOUR"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "FLONASE ALLERGY RELIEF": "FLONASE ALLERGY RELIEF",
              "FLONASE SENSIMIST": "FLONASE SENSIMIST"
            },
            "GLAXO SMITH KLINE": {
              "BECONASE AQ": "BECONASE AQ",
              "VERAMYST": "VERAMYST"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "FLUTICASONE PROPIONATE": "FLUTICASONE PROPIONATE",
              "MOMETASONE FUROATE": "MOMETASONE FUROATE"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "FLUTICASONE PROPIONATE": "FLUTICASONE PROPIONATE",
              "NASONEX": "NASONEX"
            },
            "HI-TECH": {
              "FLUTICASONE PROPIONATE": "FLUTICASONE PROPIONATE"
            },
            "HIKMA": {
              "FLUTICASONE PROPIONATE": "FLUTICASONE PROPIONATE"
            },
            "INTERSECT ENT": {
              "PROPEL": "PROPEL",
              "PROPEL MINI": "PROPEL MINI",
              "SINUVA": "SINUVA"
            },
            "KAISER FOUNDATION HOSPITAL": {
              "KP FLUTICASONE PROPIONATE": "KP FLUTICASONE PROPIONATE"
            },
            "LEADER BRAND PRODUCTS": {
              "ALLERGY RELIEF": "ALLERGY RELIEF",
              "NASAL ALLERGY 24 HOUR": "NASAL ALLERGY 24 HOUR"
            },
            "MCKESSON": {
              "HM ALLERGY RELIEF": "HM ALLERGY RELIEF"
            },
            "MCKESSON SUNMARK": {
              "SM ALLERGY RELIEF": "SM ALLERGY RELIEF"
            },
            "MCNEIL CONSUMER": {
              "RHINOCORT ALLERGY": "RHINOCORT ALLERGY"
            },
            "MERCK SHARP & DOHME": {
              "NASONEX": "NASONEX"
            },
            "MORTON GROVE PHARMACEUTICALS": {
              "FLUTICASONE PROPIONATE": "FLUTICASONE PROPIONATE"
            },
            "NOVAPLUS/APOTEX USA": {
              "FLUTICASONE PROPIONATE": "FLUTICASONE PROPIONATE"
            },
            "OPTINOSE US": {
              "XHANCE": "XHANCE"
            },
            "PAR PHARMACEUTICAL": {
              "BUDESONIDE": "BUDESONIDE"
            },
            "PERRIGO PHARMACEUTICALS": {
              "FLUTICASONE PROPIONATE": "FLUTICASONE PROPIONATE",
              "TRIAMCINOLONE ACETONIDE": "TRIAMCINOLONE ACETONIDE"
            },
            "PHARMEDIX": {
              "FLUTICASONE PROPIONATE": "FLUTICASONE PROPIONATE"
            },
            "PREFERRED PHARMACEUTICALS": {
              "FLUTICASONE PROPIONATE": "FLUTICASONE PROPIONATE"
            },
            "PROFICIENT RX": {
              "FLUTICASONE PROPIONATE": "FLUTICASONE PROPIONATE"
            },
            "QUALITY CARE": {
              "FLUTICASONE PROPIONATE": "FLUTICASONE PROPIONATE",
              "NASONEX": "NASONEX",
              "RHINOCORT AQUA": "RHINOCORT AQUA",
              "VERAMYST": "VERAMYST"
            },
            "QUALITY CARE PRODUCTS": {
              "FLUTICASONE PROPIONATE": "FLUTICASONE PROPIONATE"
            },
            "RITE AID CORPORATION": {
              "RA BUDESONIDE": "RA BUDESONIDE",
              "RA NASAL ALLERGY": "RA NASAL ALLERGY"
            },
            "RUGBY LABORATORIES": {
              "BUDESONIDE": "BUDESONIDE",
              "FLUTICASONE PROPIONATE": "FLUTICASONE PROPIONATE"
            },
            "SANDOZ": {
              "MOMETASONE FUROATE": "MOMETASONE FUROATE"
            },
            "TEVA RESPIRATORY": {
              "QNASL": "QNASL",
              "QNASL CHILDRENS": "QNASL CHILDRENS"
            },
            "TOPCO": {
              "FLUTICASONE PROPIONATE": "FLUTICASONE PROPIONATE"
            },
            "UNIT DOSE SERVICES": {
              "FLUTICASONE PROPIONATE": "FLUTICASONE PROPIONATE"
            },
            "WAL-MART": {
              "EQ ALLERGY RELIEF": "EQ ALLERGY RELIEF",
              "EQ BUDESONIDE NASAL": "EQ BUDESONIDE NASAL",
              "EQ NASAL ALLERGY": "EQ NASAL ALLERGY"
            },
            "WALGREENS": {
              "ALLERGY SPRAY 24 HOUR": "ALLERGY SPRAY 24 HOUR",
              "BUDESONIDE": "BUDESONIDE",
              "FLUTICASONE PROPIONATE": "FLUTICASONE PROPIONATE",
              "NASAL ALLERGY 24 HOUR": "NASAL ALLERGY 24 HOUR"
            },
            "WINTHROP, US": {
              "TRIAMCINOLONE ACETONIDE": "TRIAMCINOLONE ACETONIDE"
            }
          }
        },
        "Sympathomimetic Decongestants": {
          "Nasal Decongestant Inhalers": {
            "B.F. ASCHER": {
              "BENZEDREX": "BENZEDREX"
            }
          },
          "Systemic Decongestants": {
            "A-S MEDICATION SOLUTIONS": {
              "NASAL DECONGESTANT": "NASAL DECONGESTANT",
              "PHENYLEPHRINE HCL": "PHENYLEPHRINE HCL",
              "PSEUDOEPHEDRINE HCL": "PSEUDOEPHEDRINE HCL",
              "SUDAFED 12 HOUR": "SUDAFED 12 HOUR",
              "SUDOGEST": "SUDOGEST",
              "SUDOGEST PE": "SUDOGEST PE"
            },
            "AIDAREX PHARMACEUTICALS": {
              "PSEUDOEPHEDRINE HCL": "PSEUDOEPHEDRINE HCL",
              "SUDOGEST": "SUDOGEST"
            },
            "ALTURA PHARMACEUTICALS": {
              "PSEUDOEPHEDRINE HCL": "PSEUDOEPHEDRINE HCL"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP NASAL DECONGESTANT": "GNP NASAL DECONGESTANT",
              "GNP PSEUDOEPHEDRINE HCL 12 HR": "GNP PSEUDOEPHEDRINE HCL 12 HR"
            },
            "APHENA PHARMA SOLUTIONS": {
              "SUDOGEST": "SUDOGEST"
            },
            "BERGEN BRUNSWIG": {
              "GNP NASAL DECONGESTANT": "GNP NASAL DECONGESTANT",
              "GNP NASAL DECONGESTANT PE": "GNP NASAL DECONGESTANT PE",
              "GNP PSEUDOEPHEDRINE HCL 12 HR": "GNP PSEUDOEPHEDRINE HCL 12 HR",
              "GNP SUPHEDRIN": "GNP SUPHEDRIN"
            },
            "BRYANT RANCH PREPACK": {
              "PSEUDOEPHEDRINE HCL": "PSEUDOEPHEDRINE HCL"
            },
            "CHAIN DRUG CONSORTIUM": {
              "NASAL DECONGESTANT": "NASAL DECONGESTANT",
              "SINUS & ALLERGY 12 HOUR": "SINUS & ALLERGY 12 HOUR"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC NASAL DECONGESTANT PE": "QC NASAL DECONGESTANT PE",
              "QC SUPHEDRINE": "QC SUPHEDRINE",
              "QC SUPHEDRINE MAXIMUM STRENGTH": "QC SUPHEDRINE MAXIMUM STRENGTH",
              "QC SUPHEDRINE PE": "QC SUPHEDRINE PE"
            },
            "CONTRACT PHARMACAL CORPORATION": {
              "PSEUDOEPHEDRINE HCL": "PSEUDOEPHEDRINE HCL"
            },
            "COSTCO WHOLESALE": {
              "KLS SUPHEDRINE PE": "KLS SUPHEDRINE PE"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS 12 HOUR COLD RELIEF": "CVS 12 HOUR COLD RELIEF",
              "CVS 12 HOUR NASAL DECONGESTANT": "CVS 12 HOUR NASAL DECONGESTANT",
              "CVS NASAL DECONGESTANT": "CVS NASAL DECONGESTANT",
              "CVS NASAL DECONGESTANT PE": "CVS NASAL DECONGESTANT PE",
              "CVS SINUS PE DECONGESTANT": "CVS SINUS PE DECONGESTANT"
            },
            "EQUALINE": {
              "EQL 12 HOUR COLD": "EQL 12 HOUR COLD",
              "EQL 12 HOUR DECONGESTANT": "EQL 12 HOUR DECONGESTANT",
              "EQL CHILDRENS NASAL DECONGEST": "EQL CHILDRENS NASAL DECONGEST",
              "EQL NASAL DECONGESTANT": "EQL NASAL DECONGESTANT",
              "EQL NASAL DECONGESTANT PE": "EQL NASAL DECONGESTANT PE"
            },
            "GEISS DESTIN & DUNN": {
              "DECONGESTANT 12HOUR MAX ST": "DECONGESTANT 12HOUR MAX ST",
              "NASAL DECONGESTANT": "NASAL DECONGESTANT"
            },
            "GERI-CARE": {
              "NASAL DECONGESTANT": "NASAL DECONGESTANT"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "PSEUDOEPHEDRINE HCL": "PSEUDOEPHEDRINE HCL"
            },
            "KAISER FOUNDATION HOSPITAL": {
              "KP PSEUDOEPHEDRINE HCL": "KP PSEUDOEPHEDRINE HCL"
            },
            "LEADER BRAND PRODUCTS": {
              "12 HOUR DECONGESTANT": "12 HOUR DECONGESTANT",
              "12 HOUR NASAL DECONGESTANT": "12 HOUR NASAL DECONGESTANT",
              "NASAL DECONGESTANT": "NASAL DECONGESTANT",
              "NASAL DECONGESTANT MAX ST": "NASAL DECONGESTANT MAX ST",
              "NASAL DECONGESTANT PE": "NASAL DECONGESTANT PE",
              "PSEUDOEPHEDRINE HCL": "PSEUDOEPHEDRINE HCL"
            },
            "LIBERTY PHARMACEUTICAL": {
              "PSEUDOEPHEDRINE HCL": "PSEUDOEPHEDRINE HCL",
              "PSEUDOEPHEDRINE HCL ER": "PSEUDOEPHEDRINE HCL ER"
            },
            "LNK INTERNATIONAL": {
              "PSEUDOEPHEDRINE HCL": "PSEUDOEPHEDRINE HCL"
            },
            "MAGNO-HUMPHRIES LABORATORIES": {
              "DECONGESTANT": "DECONGESTANT"
            },
            "MAINPOINTE PHARMACEUTICALS": {
              "NEXAFED": "NEXAFED"
            },
            "MAJOR PHARMACEUTICALS": {
              "PSEUDOEPHEDRINE HCL": "PSEUDOEPHEDRINE HCL",
              "SUDOGEST": "SUDOGEST",
              "SUDOGEST 12 HOUR": "SUDOGEST 12 HOUR",
              "SUDOGEST MAXIMUM STRENGTH": "SUDOGEST MAXIMUM STRENGTH",
              "SUDOGEST PE": "SUDOGEST PE"
            },
            "MCKESSON": {
              "HM NASAL DECONGESTANT": "HM NASAL DECONGESTANT",
              "HM NASAL DECONGESTANT 12 HOUR": "HM NASAL DECONGESTANT 12 HOUR",
              "HM NASAL DECONGESTANT PE": "HM NASAL DECONGESTANT PE"
            },
            "MCKESSON SUNMARK": {
              "SM 12 HOUR SINUS DECONGESTANT": "SM 12 HOUR SINUS DECONGESTANT",
              "SM NASAL DECONGESTANT": "SM NASAL DECONGESTANT",
              "SM NASAL DECONGESTANT MAX ST": "SM NASAL DECONGESTANT MAX ST",
              "SM NASAL DECONGESTANT PE": "SM NASAL DECONGESTANT PE"
            },
            "MCNEIL CONSUMER": {
              "SUDAFED": "SUDAFED",
              "SUDAFED 12 HOUR": "SUDAFED 12 HOUR",
              "SUDAFED CHILDRENS": "SUDAFED CHILDRENS",
              "SUDAFED CONGESTION": "SUDAFED CONGESTION",
              "SUDAFED PE CHILDRENS": "SUDAFED PE CHILDRENS",
              "SUDAFED PE SINUS CONGESTION": "SUDAFED PE SINUS CONGESTION",
              "SUDAFED SINUS CONGESTION": "SUDAFED SINUS CONGESTION",
              "SUDAFED SINUS CONGESTION 12HR": "SUDAFED SINUS CONGESTION 12HR",
              "SUDAFED SINUS CONGESTION 24HR": "SUDAFED SINUS CONGESTION 24HR"
            },
            "MEDICINE SHOPPE": {
              "12 HOUR DECONGESTANT": "12 HOUR DECONGESTANT",
              "NASAL DECONGESTANT PE MAX ST": "NASAL DECONGESTANT PE MAX ST"
            },
            "MEDIQUE PRODUCTS": {
              "MEDI-PHENYL": "MEDI-PHENYL",
              "NON-PSEUDO SINUS DECONGESTANT": "NON-PSEUDO SINUS DECONGESTANT"
            },
            "MEDVANTX": {
              "SUDOGEST": "SUDOGEST"
            },
            "MEIJER": {
              "12 HOUR DECONGESTANT": "12 HOUR DECONGESTANT",
              "MEIJER NASAL DECONGESTANT": "MEIJER NASAL DECONGESTANT",
              "NASAL DECONGESTANT 12HR": "NASAL DECONGESTANT 12HR"
            },
            "NUCARE PHARMACEUTICALS": {
              "PSEUDOEPHEDRINE HCL": "PSEUDOEPHEDRINE HCL"
            },
            "OHM LABS": {
              "PSEUDOEPHEDRINE HCL ER": "PSEUDOEPHEDRINE HCL ER"
            },
            "PCCA": {
              "PHENYLPROPANOLAMINE HCL": "PHENYLPROPANOLAMINE HCL",
              "PSEUDOEPHEDRINE HCL": "PSEUDOEPHEDRINE HCL"
            },
            "PDRX PHARMACEUTICAL": {
              "PSEUDOEPHEDRINE HCL": "PSEUDOEPHEDRINE HCL"
            },
            "PERRIGO": {
              "12 HOUR DECONGESTANT": "12 HOUR DECONGESTANT",
              "NASAL DECONGESTANT": "NASAL DECONGESTANT",
              "NASAL DECONGESTANT PE MAX ST": "NASAL DECONGESTANT PE MAX ST",
              "SINUS 12 HOUR": "SINUS 12 HOUR"
            },
            "PERRIGO PHARMACEUTICALS": {
              "PSEUDOEPHEDRINE HCL": "PSEUDOEPHEDRINE HCL",
              "PSEUDOEPHEDRINE HCL ER": "PSEUDOEPHEDRINE HCL ER"
            },
            "PROFICIENT RX": {
              "PSEUDOEPHEDRINE HCL": "PSEUDOEPHEDRINE HCL",
              "SUDOGEST": "SUDOGEST"
            },
            "PUBLIX SUPER MARKETS INC.": {
              "PX NASAL DECONGESTANT": "PX NASAL DECONGESTANT",
              "PX NASAL DECONGESTANT PE": "PX NASAL DECONGESTANT PE"
            },
            "QUALITY CARE": {
              "PSEUDOEPHEDRINE HCL": "PSEUDOEPHEDRINE HCL",
              "SUDAFED PE MAXIMUM STRENGTH": "SUDAFED PE MAXIMUM STRENGTH"
            },
            "RICHMOND PHARMACEUTICALS": {
              "PSEUDOEPHEDRINE HCL": "PSEUDOEPHEDRINE HCL"
            },
            "RITE AID CORPORATION": {
              "RA NASAL DECONGESTANT PE": "RA NASAL DECONGESTANT PE",
              "RA SINUS/CONGESTION RELIEF PE": "RA SINUS/CONGESTION RELIEF PE",
              "RA SUPHEDRINE": "RA SUPHEDRINE"
            },
            "RUGBY LABORATORIES": {
              "NASAL DECONGESTANT": "NASAL DECONGESTANT",
              "PHENYLEPHRINE HCL": "PHENYLEPHRINE HCL"
            },
            "RXCHANGE CO": {
              "PSEUDOEPHEDRINE HCL": "PSEUDOEPHEDRINE HCL"
            },
            "SAFEWAY": {
              "SW NASAL DECONGESTANT MAX ST": "SW NASAL DECONGESTANT MAX ST",
              "SW SUPHEDRINE 12 HR DECONGEST": "SW SUPHEDRINE 12 HR DECONGEST"
            },
            "SHOPKO STORES OPERATING CO": {
              "SHOPKO NASAL DECONGESTANT": "SHOPKO NASAL DECONGESTANT",
              "SHOPKO NASAL DECONGESTANT MAX": "SHOPKO NASAL DECONGESTANT MAX"
            },
            "SILARX": {
              "CHILDRENS SILFEDRINE": "CHILDRENS SILFEDRINE"
            },
            "SPECTRUM": {
              "PSEUDOEPHEDRINE HCL": "PSEUDOEPHEDRINE HCL"
            },
            "TARGET": {
              "TGT 12 HOUR NASAL DECONGESTANT": "TGT 12 HOUR NASAL DECONGESTANT",
              "TGT NASAL DECONGESTANT": "TGT NASAL DECONGESTANT",
              "TGT NASAL DECONGESTANT PE": "TGT NASAL DECONGESTANT PE",
              "TGT SINUS 12 HOUR": "TGT SINUS 12 HOUR"
            },
            "TEVA PHARMACEUTICALS USA": {
              "GENAPHED": "GENAPHED"
            },
            "TIME-CAP LABS": {
              "PSEUDOEPHEDRINE HCL": "PSEUDOEPHEDRINE HCL"
            },
            "TOPCO": {
              "12 HOUR DECONGESTANT": "12 HOUR DECONGESTANT",
              "NASAL DECONGESTANT": "NASAL DECONGESTANT",
              "NASAL DECONGESTANT PE": "NASAL DECONGESTANT PE"
            },
            "VETCO, INC.": {
              "LITTLE COLDS DECONGESTANT": "LITTLE COLDS DECONGESTANT"
            },
            "WAL-MART": {
              "EQ NASAL DECONGESTANT PE": "EQ NASAL DECONGESTANT PE",
              "EQ SUPHEDRINE": "EQ SUPHEDRINE",
              "EQ SUPHEDRINE PE": "EQ SUPHEDRINE PE"
            },
            "WALGREENS": {
              "WAL-PHED": "WAL-PHED",
              "WAL-PHED 12 HOUR": "WAL-PHED 12 HOUR",
              "WAL-PHED D": "WAL-PHED D",
              "WAL-PHED PE": "WAL-PHED PE"
            },
            "WESTPORT PHARMACEUTICALS": {
              "ZEPHREX-D": "ZEPHREX-D"
            }
          },
          "Topical Decongestants": {
            "A-S MEDICATION SOLUTIONS": {
              "12 HOUR NASAL SPRAY": "12 HOUR NASAL SPRAY",
              "NASAL DECONGESTANT SPRAY": "NASAL DECONGESTANT SPRAY",
              "NEO-SYNEPHRINE": "NEO-SYNEPHRINE"
            },
            "AIDAREX PHARMACEUTICALS": {
              "NASAL DECONGESTANT SPRAY": "NASAL DECONGESTANT SPRAY"
            },
            "ALTAIRE": {
              "12 HOUR NASAL RELIEF SPRAY": "12 HOUR NASAL RELIEF SPRAY"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP NASAL SPRAY": "GNP NASAL SPRAY",
              "GNP NASAL SPRAY FAST ACTING": "GNP NASAL SPRAY FAST ACTING"
            },
            "APOTHECA SUPPLY": {
              "PHENYLEPHRINE HCL": "PHENYLEPHRINE HCL"
            },
            "AURORA HEALTHCARE": {
              "12 HOUR NASAL SPRAY": "12 HOUR NASAL SPRAY",
              "LONG LASTING NASAL SPRAY": "LONG LASTING NASAL SPRAY",
              "NASAL SPRAY": "NASAL SPRAY"
            },
            "B & B PHARMACEUTICALS": {
              "PHENYLEPHRINE HCL": "PHENYLEPHRINE HCL"
            },
            "B.F. ASCHER": {
              "NEO-SYNEPHRINE COLD/ALLRG MILD": "NEO-SYNEPHRINE COLD/ALLRG MILD",
              "NEO-SYNEPHRINE COLD/ALLRGY EXT": "NEO-SYNEPHRINE COLD/ALLRGY EXT",
              "NEO-SYNEPHRINE COLD/ALLRGY REG": "NEO-SYNEPHRINE COLD/ALLRGY REG"
            },
            "BAYER CONSUMER": {
              "AFRIN 12 HOUR": "AFRIN 12 HOUR",
              "AFRIN ALL NIGHT NODRIP": "AFRIN ALL NIGHT NODRIP",
              "AFRIN CHILDRENS": "AFRIN CHILDRENS",
              "AFRIN NASAL SPRAY": "AFRIN NASAL SPRAY",
              "AFRIN NODRIP EXTRA MOISTURE": "AFRIN NODRIP EXTRA MOISTURE",
              "AFRIN NODRIP ORIGINAL": "AFRIN NODRIP ORIGINAL",
              "AFRIN NODRIP SEVERE CONGEST": "AFRIN NODRIP SEVERE CONGEST",
              "AFRIN NODRIP SINUS": "AFRIN NODRIP SINUS",
              "AFRIN PUMP MIST": "AFRIN PUMP MIST",
              "DURATION 12 HOUR NASAL SPRAY": "DURATION 12 HOUR NASAL SPRAY",
              "DURATION SPRAY": "DURATION SPRAY",
              "NEO-SYNEPHRINE 12 HOUR SPRAY": "NEO-SYNEPHRINE 12 HOUR SPRAY"
            },
            "BERGEN BRUNSWIG": {
              "GNP 12 HOUR NASAL SPRAY": "GNP 12 HOUR NASAL SPRAY",
              "GNP NASAL SPRAY EXTRA MOIST": "GNP NASAL SPRAY EXTRA MOIST",
              "GNP NO DRIP NASAL SPRAY": "GNP NO DRIP NASAL SPRAY",
              "GNP NOSE DROPS EXTRA STRENGTH": "GNP NOSE DROPS EXTRA STRENGTH"
            },
            "BPI LABS LLC": {
              "EPINEPHRINE HCL (NASAL)": "EPINEPHRINE HCL (NASAL)"
            },
            "CHAIN DRUG CONSORTIUM": {
              "NASAL SPRAY": "NASAL SPRAY",
              "NASAL SPRAY EXTRA MOISTURIZING": "NASAL SPRAY EXTRA MOISTURIZING",
              "NASAL SPRAY MOISTURIZING 12 HR": "NASAL SPRAY MOISTURIZING 12 HR",
              "NO DRIP NASAL SPRAY": "NO DRIP NASAL SPRAY",
              "NU-WAY NASAL DECONGESTANT": "NU-WAY NASAL DECONGESTANT",
              "SEVERE CONGESTION": "SEVERE CONGESTION",
              "SINUS NASAL SPRAY": "SINUS NASAL SPRAY",
              "ULTRA FINE MIST NASAL SPRAY": "ULTRA FINE MIST NASAL SPRAY"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "LONG ACTING NASAL SPRAY": "LONG ACTING NASAL SPRAY",
              "QC NASAL RELIEF MOISTURIZING": "QC NASAL RELIEF MOISTURIZING",
              "QC NASAL RELIEF SINUS": "QC NASAL RELIEF SINUS",
              "QC NASAL SPRAY": "QC NASAL SPRAY",
              "QC NO DRIP NASAL RELIEF": "QC NO DRIP NASAL RELIEF"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "NRS NASAL RELIEF": "NRS NASAL RELIEF"
            },
            "COSTCO WHOLESALE": {
              "KLS NASAL DECONGESTANT SPRAY": "KLS NASAL DECONGESTANT SPRAY"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS NASAL MIST": "CVS NASAL MIST",
              "CVS NASAL SPRAY": "CVS NASAL SPRAY",
              "CVS NOSE DROPS": "CVS NOSE DROPS"
            },
            "EQUALINE": {
              "EQL NASAL FOUR": "EQL NASAL FOUR",
              "EQL NASAL SPRAY 12 HOUR": "EQL NASAL SPRAY 12 HOUR",
              "EQL NASAL SPRAY FAST ACTING": "EQL NASAL SPRAY FAST ACTING",
              "EQL NASAL SPRAY NO DRIP": "EQL NASAL SPRAY NO DRIP",
              "EQL SINUS NASAL SPRAY": "EQL SINUS NASAL SPRAY",
              "EQL SINUS RELIEF 12 HOUR": "EQL SINUS RELIEF 12 HOUR"
            },
            "FAGRON": {
              "PHENYLEPHRINE HCL": "PHENYLEPHRINE HCL"
            },
            "FOUNDATION CONSUMER HEALTHCARE": {
              "NEO-SYNEPHRINE": "NEO-SYNEPHRINE",
              "NEO-SYNEPHRINE COLD & SINUS": "NEO-SYNEPHRINE COLD & SINUS"
            },
            "GEISS DESTIN & DUNN": {
              "12 HOUR NASAL SPRAY": "12 HOUR NASAL SPRAY",
              "NASAL FOUR": "NASAL FOUR",
              "NASAL SPRAY 12 HOUR": "NASAL SPRAY 12 HOUR"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "4-WAY FAST ACTING": "4-WAY FAST ACTING",
              "4-WAY MENTHOL": "4-WAY MENTHOL",
              "DRISTAN SPRAY": "DRISTAN SPRAY"
            },
            "GLAXO SMITH KLINE": {
              "AFRIN SINUS": "AFRIN SINUS"
            },
            "HUMCO": {
              "PHENYLEPHRINE HCL": "PHENYLEPHRINE HCL"
            },
            "HY-VEE": {
              "NASAL SPRAY MAX STRENGTH": "NASAL SPRAY MAX STRENGTH"
            },
            "KALCHEM INTERNATIONAL": {
              "PHENYLEPHRINE HCL": "PHENYLEPHRINE HCL"
            },
            "KROGER COMPANY": {
              "NASAL FOUR": "NASAL FOUR",
              "NASAL SPRAY": "NASAL SPRAY",
              "NASAL SPRAY NO DRIP": "NASAL SPRAY NO DRIP",
              "NASAL SPRAY SINUS": "NASAL SPRAY SINUS"
            },
            "LEADER BRAND PRODUCTS": {
              "NASAL DECONGESTANT": "NASAL DECONGESTANT",
              "NASAL SPRAY ANTI-DRIP": "NASAL SPRAY ANTI-DRIP",
              "NASAL SPRAY NO DRIP": "NASAL SPRAY NO DRIP",
              "SINUS RELIEF EXTRA STRENGTH": "SINUS RELIEF EXTRA STRENGTH"
            },
            "LETCO MEDICAL": {
              "PHENYLEPHRINE HCL": "PHENYLEPHRINE HCL"
            },
            "MAJOR PHARMACEUTICALS": {
              "12 HOUR NASAL DECONGESTANT": "12 HOUR NASAL DECONGESTANT",
              "NASAL DECONGESTANT SPRAY": "NASAL DECONGESTANT SPRAY"
            },
            "MCKESSON": {
              "HM NASAL SPRAY": "HM NASAL SPRAY",
              "HM NOSE DROPS": "HM NOSE DROPS",
              "HM SINUS NASAL SPRAY": "HM SINUS NASAL SPRAY"
            },
            "MCKESSON SUNMARK": {
              "SM 12-HOUR NO DRIP": "SM 12-HOUR NO DRIP",
              "SM NASAL PUMP SPRAY MIST": "SM NASAL PUMP SPRAY MIST",
              "SM NASAL SPRAY": "SM NASAL SPRAY",
              "SM NASAL SPRAY 12 HOUR": "SM NASAL SPRAY 12 HOUR",
              "SM NASAL SPRAY MOISTURIZING": "SM NASAL SPRAY MOISTURIZING",
              "SM NASAL SPRAY SINUS": "SM NASAL SPRAY SINUS",
              "SM NOSE DROPS NASAL DECONGEST": "SM NOSE DROPS NASAL DECONGEST"
            },
            "MEDISCA": {
              "PHENYLEPHRINE HCL": "PHENYLEPHRINE HCL"
            },
            "MEDTECH": {
              "LITTLE NOSES DECONGESTANT": "LITTLE NOSES DECONGESTANT"
            },
            "P & G HEALTH": {
              "QLEARQUIL": "QLEARQUIL",
              "VICKS SINEX": "VICKS SINEX",
              "VICKS SINEX 12 HOUR DECONGEST": "VICKS SINEX 12 HOUR DECONGEST",
              "VICKS SINEX MOISTURIZING": "VICKS SINEX MOISTURIZING",
              "VICKS SINEX SEVERE": "VICKS SINEX SEVERE",
              "VICKS SINEX SEVERE DECONGEST": "VICKS SINEX SEVERE DECONGEST"
            },
            "PAR STERILE PRODUCTS": {
              "ADRENALIN": "ADRENALIN"
            },
            "PCCA": {
              "PHENYLEPHRINE HCL": "PHENYLEPHRINE HCL"
            },
            "PERRIGO": {
              "NASAL RELIEF": "NASAL RELIEF",
              "NASAL SPRAY EXTRA MOISTURIZING": "NASAL SPRAY EXTRA MOISTURIZING",
              "SINUS NASAL SPRAY": "SINUS NASAL SPRAY"
            },
            "PHARMADERM": {
              "TYZINE": "TYZINE"
            },
            "PROFICIENT RX": {
              "12 HOUR DECONGESTANT": "12 HOUR DECONGESTANT"
            },
            "PUBLIX SUPER MARKETS INC.": {
              "PX NASAL FOUR": "PX NASAL FOUR",
              "PX NASAL SPRAY MOISTURIZING": "PX NASAL SPRAY MOISTURIZING",
              "PX NO DRIP NASAL SPRAY": "PX NO DRIP NASAL SPRAY",
              "PX ORIGINAL NASAL SPRAY": "PX ORIGINAL NASAL SPRAY"
            },
            "QUALITY CARE PRODUCTS": {
              "NASAL DECONGESTANT SPRAY": "NASAL DECONGESTANT SPRAY"
            },
            "RECKITT BENCKISER": {
              "MUCINEX CHILDRENS STUFFY NOSE": "MUCINEX CHILDRENS STUFFY NOSE",
              "MUCINEX NASAL SPRAY FULL FORCE": "MUCINEX NASAL SPRAY FULL FORCE",
              "MUCINEX NASAL SPRAY MOISTURE": "MUCINEX NASAL SPRAY MOISTURE",
              "MUCINEX SINUS-MAX CLEAR & COOL": "MUCINEX SINUS-MAX CLEAR & COOL",
              "MUCINEX SINUS-MAX FULL FORCE": "MUCINEX SINUS-MAX FULL FORCE",
              "MUCINEX SINUS-MAX SINUS/ALLRGY": "MUCINEX SINUS-MAX SINUS/ALLRGY"
            },
            "RELIABLE 1 LABS": {
              "12 HOUR DECONGESTANT": "12 HOUR DECONGESTANT"
            },
            "RITE AID CORPORATION": {
              "RA 12 HOUR NASAL SPRAY": "RA 12 HOUR NASAL SPRAY",
              "RA NASAL DECONGESTANT": "RA NASAL DECONGESTANT",
              "RA NASAL SPRAY": "RA NASAL SPRAY",
              "RA NASAL SPRAY MAX ST": "RA NASAL SPRAY MAX ST",
              "RA NASAL SPRAY/MOISTURIZING": "RA NASAL SPRAY/MOISTURIZING",
              "RA NASAL SPRAY/SINUS": "RA NASAL SPRAY/SINUS",
              "RA NOSE DROPS EXTRA STRENGTH": "RA NOSE DROPS EXTRA STRENGTH",
              "RA SEVERE CONGESTION SPRAY": "RA SEVERE CONGESTION SPRAY"
            },
            "RUGBY LABORATORIES": {
              "NRS NASAL RELIEF": "NRS NASAL RELIEF"
            },
            "SCHERER": {
              "RHINALL": "RHINALL"
            },
            "SELECT BRAND": {
              "SB 12HR NASAL SPRAY": "SB 12HR NASAL SPRAY",
              "SB NASAL SPRAY NO-DRIP": "SB NASAL SPRAY NO-DRIP",
              "SB SINUS RELIEF": "SB SINUS RELIEF"
            },
            "SHEFFIELD PHARMACEUTICALS": {
              "NASAL SPRAY 12 HOUR": "NASAL SPRAY 12 HOUR",
              "NASAL SPRAY MOISTURIZING 12 HR": "NASAL SPRAY MOISTURIZING 12 HR",
              "SINUS NASAL SPRAY 12 HOUR": "SINUS NASAL SPRAY 12 HOUR"
            },
            "SPECTRUM": {
              "PHENYLEPHRINE HCL": "PHENYLEPHRINE HCL"
            },
            "TARGET": {
              "TGT NASAL DECONGESTANT 12-HOUR": "TGT NASAL DECONGESTANT 12-HOUR",
              "TGT NASAL SPRAY": "TGT NASAL SPRAY"
            },
            "TARO": {
              "NASAL DECONGESTANT SPRAY": "NASAL DECONGESTANT SPRAY"
            },
            "TOPCO": {
              "NASAL FOUR": "NASAL FOUR",
              "NASAL SPRAY 12 HOUR": "NASAL SPRAY 12 HOUR",
              "NO DRIP NASAL SPRAY": "NO DRIP NASAL SPRAY",
              "NOSE DROPS EXTRA STRENGTH": "NOSE DROPS EXTRA STRENGTH",
              "SINUS RELIEF NASAL SPRAY": "SINUS RELIEF NASAL SPRAY"
            },
            "WAL-MART": {
              "EQ NASAL SPRAY": "EQ NASAL SPRAY",
              "EQ NASAL SPRAY FAST ACTING": "EQ NASAL SPRAY FAST ACTING"
            },
            "WALGREENS": {
              "ANEFRIN SPRAY": "ANEFRIN SPRAY",
              "ANEFRIN SPRAY/MOISTURIZING": "ANEFRIN SPRAY/MOISTURIZING",
              "EPHRINE NOSE DROPS": "EPHRINE NOSE DROPS",
              "SINUS RELIEF": "SINUS RELIEF",
              "SINUS RELIEF MIST": "SINUS RELIEF MIST",
              "WAL-FOUR": "WAL-FOUR"
            },
            "WILLOW BIRCH PHARMA": {
              "PHENYLEPHRINE HCL": "PHENYLEPHRINE HCL"
            }
          }
        }
      },
      "NEUROMUSCULAR AGENTS": {
        "ALS Agents": {
          "ALS Agents - Miscellaneous": {
            "MITSUBISHI TANABE PHARMA AMERI": {
              "RADICAVA": "RADICAVA"
            }
          },
          "Benzathiazoles": {
            "AMERICAN HEALTH PACKAGING": {
              "RILUZOLE": "RILUZOLE"
            },
            "APOTEX": {
              "RILUZOLE": "RILUZOLE"
            },
            "ASCEND LABORATORIES": {
              "RILUZOLE": "RILUZOLE"
            },
            "AVKARE": {
              "RILUZOLE": "RILUZOLE"
            },
            "COVIS PHARMA": {
              "RILUTEK": "RILUTEK"
            },
            "GLENMARK PHARMACEUTICALS": {
              "RILUZOLE": "RILUZOLE"
            },
            "IMPAX GENERICS": {
              "RILUZOLE": "RILUZOLE"
            },
            "ITF PHARMA": {
              "TIGLUTIK": "TIGLUTIK"
            },
            "MYLAN": {
              "RILUZOLE": "RILUZOLE"
            },
            "RISING PHARMACEUTICALS": {
              "RILUZOLE": "RILUZOLE"
            },
            "SUN PHARMACEUTICALS": {
              "RILUZOLE": "RILUZOLE"
            }
          }
        },
        "Depolarizing Muscle Relaxants": {
          "Depolarizing Muscle Relaxants": {
            "AMNEAL BIOSCIENCES": {
              "SUCCINYLCHOLINE CHLORIDE": "SUCCINYLCHOLINE CHLORIDE"
            },
            "AMRING PHARMACEUTICALS": {
              "SUCCINYLCHOLINE CHLORIDE": "SUCCINYLCHOLINE CHLORIDE"
            },
            "BLUE POINT LABORATORIES": {
              "SUCCINYLCHOLINE CHLORIDE": "SUCCINYLCHOLINE CHLORIDE"
            },
            "BRECKENRIDGE": {
              "SUCCINYLCHOLINE CHLORIDE": "SUCCINYLCHOLINE CHLORIDE"
            },
            "CIVICA": {
              "ANECTINE": "ANECTINE"
            },
            "FAGRON COMPOUNDING SERVICES": {
              "SUCCINYLCHOLINE CHLORIDE": "SUCCINYLCHOLINE CHLORIDE"
            },
            "HOSPIRA": {
              "QUELICIN": "QUELICIN"
            },
            "INTEGRADOSE COMPOUNDING SERVIC": {
              "SUCCINYLCHOLINE CHLORIDE": "SUCCINYLCHOLINE CHLORIDE"
            },
            "LEITERS HEALTH": {
              "SUCCINYLCHOLINE CHLORIDE": "SUCCINYLCHOLINE CHLORIDE"
            },
            "NEPHRON STERILE COMPOUNDING": {
              "SUCCINYLCHOLINE CHLORIDE": "SUCCINYLCHOLINE CHLORIDE"
            },
            "NIVAGEN PHARMACEUTICALS": {
              "SUCCINYLCHOLINE CHLORIDE": "SUCCINYLCHOLINE CHLORIDE"
            },
            "NOVAPLUS/HOSPIRA": {
              "QUELICIN": "QUELICIN"
            },
            "NOVAPLUS/SANDOZ": {
              "ANECTINE": "ANECTINE"
            },
            "PHARMEDIUM SERVICES": {
              "SUCCINYLCHOLINE CHLORIDE": "SUCCINYLCHOLINE CHLORIDE"
            },
            "QUVA PHARMA": {
              "SUCCINYLCHOLINE CHLORIDE": "SUCCINYLCHOLINE CHLORIDE"
            },
            "SANDOZ": {
              "ANECTINE": "ANECTINE"
            },
            "SCA PHARMACEUTICALS": {
              "SUCCINYLCHOLINE CHLORIDE": "SUCCINYLCHOLINE CHLORIDE"
            },
            "SOMERSET THERAPEUTICS": {
              "SUCCINYLCHOLINE CHLORIDE": "SUCCINYLCHOLINE CHLORIDE"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "SUCCINYLCHOLINE CHLORIDE": "SUCCINYLCHOLINE CHLORIDE"
            }
          }
        },
        "Muscular Dystrophy Agents": {
          "Muscular Dystrophy Agents": {
            "NS PHARMA": {
              "VILTEPSO": "VILTEPSO"
            },
            "SAREPTA THERAPEUTICS": {
              "EXONDYS 51": "EXONDYS 51",
              "VYONDYS 53": "VYONDYS 53"
            }
          }
        },
        "Neuromuscular Blocking Agent - Neurotoxins": {
          "Neuromuscular Blocking Agent - Neurotoxins": {
            "ALLERGAN": {
              "BOTOX": "BOTOX"
            },
            "IPSEN BIOPHARMACEUTICALS": {
              "DYSPORT": "DYSPORT"
            },
            "MERZ PHARMACEUTICAL": {
              "XEOMIN": "XEOMIN"
            },
            "SOLSTICE NEUROSCIENCES": {
              "MYOBLOC": "MYOBLOC"
            }
          }
        },
        "Nondepolarizing Muscle Relaxants": {
          "Nondepolarizing Muscle Relaxants": {
            "ABBVIE": {
              "MIVACRON": "MIVACRON",
              "NIMBEX": "NIMBEX"
            },
            "ALMAJECT": {
              "ROCURONIUM BROMIDE": "ROCURONIUM BROMIDE"
            },
            "ALVOGEN": {
              "ROCURONIUM BROMIDE": "ROCURONIUM BROMIDE"
            },
            "ATHENEX PHARMACEUTICAL": {
              "ROCURONIUM BROMIDE": "ROCURONIUM BROMIDE"
            },
            "AUROMEDICS PHARMA": {
              "ATRACURIUM BESYLATE": "ATRACURIUM BESYLATE",
              "CISATRACURIUM BESYLATE": "CISATRACURIUM BESYLATE",
              "ROCURONIUM BROMIDE": "ROCURONIUM BROMIDE",
              "VECURONIUM BROMIDE": "VECURONIUM BROMIDE"
            },
            "BAXTER HEALTHCARE CORP": {
              "ROCURONIUM BROMIDE": "ROCURONIUM BROMIDE"
            },
            "CENTRAL ADMIXTURE PHARMACY SER": {
              "ROCURONIUM BROMIDE": "ROCURONIUM BROMIDE"
            },
            "CIVICA": {
              "ROCURONIUM BROMIDE": "ROCURONIUM BROMIDE"
            },
            "FAGRON COMPOUNDING SERVICES": {
              "ROCURONIUM BROMIDE": "ROCURONIUM BROMIDE"
            },
            "FRESENIUS KABI USA": {
              "CISATRACURIUM BESYLATE": "CISATRACURIUM BESYLATE",
              "CISATRACURIUM BESYLATE (PF)": "CISATRACURIUM BESYLATE (PF)",
              "ROCURONIUM BROMIDE": "ROCURONIUM BROMIDE",
              "VECURONIUM BROMIDE": "VECURONIUM BROMIDE"
            },
            "HIKMA": {
              "ROCURONIUM BROMIDE": "ROCURONIUM BROMIDE",
              "VECURONIUM BROMIDE": "VECURONIUM BROMIDE"
            },
            "HOSPIRA": {
              "ATRACURIUM BESYLATE": "ATRACURIUM BESYLATE",
              "CISATRACURIUM BESYLATE": "CISATRACURIUM BESYLATE",
              "CISATRACURIUM BESYLATE (PF)": "CISATRACURIUM BESYLATE (PF)",
              "PANCURONIUM BROMIDE": "PANCURONIUM BROMIDE",
              "ROCURONIUM BROMIDE": "ROCURONIUM BROMIDE",
              "VECURONIUM BROMIDE": "VECURONIUM BROMIDE"
            },
            "LEITERS HEALTH": {
              "ROCURONIUM BROMIDE": "ROCURONIUM BROMIDE"
            },
            "MEITHEAL PHARMACEUTICALS": {
              "ATRACURIUM BESYLATE": "ATRACURIUM BESYLATE",
              "CISATRACURIUM BESYLATE": "CISATRACURIUM BESYLATE",
              "CISATRACURIUM BESYLATE (PF)": "CISATRACURIUM BESYLATE (PF)"
            },
            "MYLAN INSTITUTIONAL": {
              "ATRACURIUM BESYLATE": "ATRACURIUM BESYLATE",
              "ROCURONIUM BROMIDE": "ROCURONIUM BROMIDE",
              "VECURONIUM BROMIDE": "VECURONIUM BROMIDE"
            },
            "NEPHRON STERILE COMPOUNDING": {
              "ROCURONIUM BROMIDE": "ROCURONIUM BROMIDE",
              "VECURONIUM BROMIDE": "VECURONIUM BROMIDE"
            },
            "NOVAPLUS/HOSPIRA": {
              "ROCURONIUM BROMIDE": "ROCURONIUM BROMIDE"
            },
            "NOVAPLUS/MYLAN INSTITUTIONAL": {
              "VECURONIUM BROMIDE": "VECURONIUM BROMIDE"
            },
            "NOVAPLUS/SAGENT PHARMACEUTICAL": {
              "ATRACURIUM BESYLATE": "ATRACURIUM BESYLATE"
            },
            "ORGANON": {
              "ZEMURON": "ZEMURON"
            },
            "PFIZER U.S.": {
              "VECURONIUM BROMIDE": "VECURONIUM BROMIDE"
            },
            "PHARMEDIUM SERVICES": {
              "CISATRACURIUM BESYLATE": "CISATRACURIUM BESYLATE",
              "ROCURONIUM BROMIDE": "ROCURONIUM BROMIDE",
              "VECURONIUM BROMIDE": "VECURONIUM BROMIDE",
              "VECURONIUM BROMIDE-NACL": "VECURONIUM BROMIDE-NACL"
            },
            "PIRAMAL CRITICAL CARE": {
              "ROCURONIUM BROMIDE": "ROCURONIUM BROMIDE"
            },
            "QUVA PHARMA": {
              "ROCURONIUM BROMIDE": "ROCURONIUM BROMIDE",
              "VECURONIUM BROMIDE": "VECURONIUM BROMIDE"
            },
            "SAGENT PHARMACEUTICAL": {
              "ATRACURIUM BESYLATE": "ATRACURIUM BESYLATE",
              "ROCURONIUM BROMIDE": "ROCURONIUM BROMIDE",
              "VECURONIUM BROMIDE": "VECURONIUM BROMIDE"
            },
            "SANDOZ": {
              "CISATRACURIUM BESYLATE": "CISATRACURIUM BESYLATE",
              "CISATRACURIUM BESYLATE (PF)": "CISATRACURIUM BESYLATE (PF)",
              "ROCURONIUM BROMIDE": "ROCURONIUM BROMIDE"
            },
            "SCA PHARMACEUTICALS": {
              "ROCURONIUM BROMIDE": "ROCURONIUM BROMIDE"
            },
            "SOMERSET THERAPEUTICS": {
              "CISATRACURIUM BESYLATE": "CISATRACURIUM BESYLATE",
              "CISATRACURIUM BESYLATE (PF)": "CISATRACURIUM BESYLATE (PF)"
            },
            "SUN PHARMACEUTICAL": {
              "VECURONIUM BROMIDE": "VECURONIUM BROMIDE"
            },
            "SUN PHARMACEUTICALS": {
              "VECURONIUM BROMIDE": "VECURONIUM BROMIDE"
            },
            "TEVA PARENTERAL MEDICINES": {
              "CISATRACURIUM BESYLATE": "CISATRACURIUM BESYLATE",
              "CISATRACURIUM BESYLATE (PF)": "CISATRACURIUM BESYLATE (PF)",
              "PANCURONIUM BROMIDE": "PANCURONIUM BROMIDE",
              "VECURONIUM BROMIDE": "VECURONIUM BROMIDE"
            },
            "THE MEDICINES COMPANY": {
              "ROCURONIUM BROMIDE": "ROCURONIUM BROMIDE"
            },
            "X-GEN PHARMACEUTICALS, INC": {
              "ROCURONIUM BROMIDE": "ROCURONIUM BROMIDE"
            }
          }
        },
        "Spinal Muscular Atrophy Agents (SMA)": {
          "Spinal Muscular Atrophy-Antisense Oligonucleotides": {
            "BIOGEN IDEC": {
              "SPINRAZA": "SPINRAZA"
            }
          },
          "Spinal Muscular Atrophy-Gene Therapy Agents": {
            "AVEXIS": {
              "ZOLGENSMA 10.1-10.5 KG": "ZOLGENSMA 10.1-10.5 KG",
              "ZOLGENSMA 10.6-11.0 KG": "ZOLGENSMA 10.6-11.0 KG",
              "ZOLGENSMA 11.1-11.5 KG": "ZOLGENSMA 11.1-11.5 KG",
              "ZOLGENSMA 11.6-12.0 KG": "ZOLGENSMA 11.6-12.0 KG",
              "ZOLGENSMA 12.1-12.5 KG": "ZOLGENSMA 12.1-12.5 KG",
              "ZOLGENSMA 12.6-13.0 KG": "ZOLGENSMA 12.6-13.0 KG",
              "ZOLGENSMA 13.1-13.5 KG": "ZOLGENSMA 13.1-13.5 KG",
              "ZOLGENSMA 2.6-3.0 KG": "ZOLGENSMA 2.6-3.0 KG",
              "ZOLGENSMA 3.1-3.5 KG": "ZOLGENSMA 3.1-3.5 KG",
              "ZOLGENSMA 3.6-4.0 KG": "ZOLGENSMA 3.6-4.0 KG",
              "ZOLGENSMA 4.1-4.5 KG": "ZOLGENSMA 4.1-4.5 KG",
              "ZOLGENSMA 4.6-5.0 KG": "ZOLGENSMA 4.6-5.0 KG",
              "ZOLGENSMA 5.1-5.5 KG": "ZOLGENSMA 5.1-5.5 KG",
              "ZOLGENSMA 5.6-6.0 KG": "ZOLGENSMA 5.6-6.0 KG",
              "ZOLGENSMA 6.1-6.5 KG": "ZOLGENSMA 6.1-6.5 KG",
              "ZOLGENSMA 6.6-7.0 KG": "ZOLGENSMA 6.6-7.0 KG",
              "ZOLGENSMA 7.1-7.5 KG": "ZOLGENSMA 7.1-7.5 KG",
              "ZOLGENSMA 7.6-8.0 KG": "ZOLGENSMA 7.6-8.0 KG",
              "ZOLGENSMA 8.1-8.5 KG": "ZOLGENSMA 8.1-8.5 KG",
              "ZOLGENSMA 8.6-9.0 KG": "ZOLGENSMA 8.6-9.0 KG",
              "ZOLGENSMA 9.1-9.5 KG": "ZOLGENSMA 9.1-9.5 KG",
              "ZOLGENSMA 9.6-10.0 KG": "ZOLGENSMA 9.6-10.0 KG"
            }
          },
          "Spinal Muscular Atrophy-SMN2 Splicing Modifiers": {
            "GENENTECH": {
              "EVRYSDI": "EVRYSDI"
            }
          }
        }
      },
      "NUTRIENTS": {
        "Carbohydrates": {
          "Carbohydrates": {
            "A-S MEDICATION SOLUTIONS": {
              "DEXTROSE": "DEXTROSE"
            },
            "ABBOTT NUTRITION": {
              "POLYCOSE": "POLYCOSE",
              "SIMILAC GLUCOSE WATER": "SIMILAC GLUCOSE WATER"
            },
            "AKORN": {
              "ALCOHOL": "ALCOHOL"
            },
            "AMERICAN REGENT": {
              "ALCOHOL": "ALCOHOL"
            },
            "B. BRAUN MEDICAL": {
              "DEXTROSE": "DEXTROSE"
            },
            "BAXTER HEALTHCARE CORP": {
              "DEXTROSE": "DEXTROSE",
              "GLUCOSE": "GLUCOSE"
            },
            "BECTON DICKINSON AND COMPANY": {
              "DEXTROSE": "DEXTROSE"
            },
            "BPI LABS LLC": {
              "DEXTROSE": "DEXTROSE"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS GLUCOSE SHOT": "CVS GLUCOSE SHOT"
            },
            "FAGRON": {
              "DEXTROSE ANHYDROUS": "DEXTROSE ANHYDROUS",
              "DEXTROSE MONOHYDRATE": "DEXTROSE MONOHYDRATE",
              "FRUCTOSE": "FRUCTOSE"
            },
            "FLON LABORATORIES": {
              "DEHYDRATED ALCOHOL": "DEHYDRATED ALCOHOL"
            },
            "FREEDOM PHARMACEUTICALS": {
              "FRUCTOSE": "FRUCTOSE"
            },
            "FRESENIUS KABI USA": {
              "DEXTROSE": "DEXTROSE"
            },
            "HOSPIRA": {
              "DEXTROSE": "DEXTROSE"
            },
            "ICU MEDICAL": {
              "DEXTROSE": "DEXTROSE"
            },
            "INTRNTL MEDICATION SYSTEM": {
              "DEXTROSE": "DEXTROSE"
            },
            "KALCHEM INTERNATIONAL": {
              "DEXTROSE": "DEXTROSE"
            },
            "LETCO MEDICAL": {
              "DEXTROSE ANHYDROUS": "DEXTROSE ANHYDROUS",
              "FRUCTOSE": "FRUCTOSE"
            },
            "M J NUTR": {
              "GLUCOSE NURSETTE": "GLUCOSE NURSETTE"
            },
            "MEDISCA": {
              "DEXTROSE": "DEXTROSE"
            },
            "NEPHRON STERILE COMPOUNDING": {
              "DEXTROSE": "DEXTROSE"
            },
            "NESTLE HEALTHCARE NUTRITION": {
              "GERBER GOOD START WATER": "GERBER GOOD START WATER",
              "GOOD START 5% GLUCOSE WATER": "GOOD START 5% GLUCOSE WATER"
            },
            "PCCA": {
              "DEXTROSE ANHYDROUS": "DEXTROSE ANHYDROUS",
              "FRUCTOSE": "FRUCTOSE"
            },
            "PERRIGO DIABETES CARE": {
              "GLUCOSE": "GLUCOSE"
            },
            "QUVA PHARMA": {
              "DEXTROSE": "DEXTROSE"
            },
            "SETON PHARMACEUTICALS": {
              "DEHYDRATED ALCOHOL": "DEHYDRATED ALCOHOL"
            },
            "SPECTRUM": {
              "DEXTROSE ANHYDROUS": "DEXTROSE ANHYDROUS",
              "DEXTROSE MONOHYDRATE": "DEXTROSE MONOHYDRATE",
              "FRUCTOSE": "FRUCTOSE"
            },
            "VALESCO PHARMACEUTICALS": {
              "DEHYDRATED ALCOHOL": "DEHYDRATED ALCOHOL"
            },
            "WALGREENS": {
              "GLUCO SHOT": "GLUCO SHOT"
            }
          }
        },
        "Lipids": {
          "Fatty Acids": {
            "RITE AID CORPORATION": {
              "PA CLA": "PA CLA"
            }
          },
          "Lipids": {
            "B. BRAUN MEDICAL": {
              "NUTRILIPID": "NUTRILIPID"
            },
            "BAXTER HEALTHCARE CORP": {
              "CLINOLIPID": "CLINOLIPID",
              "INTRALIPID": "INTRALIPID"
            },
            "FRESENIUS KABI USA": {
              "OMEGAVEN": "OMEGAVEN",
              "SMOFLIPID": "SMOFLIPID"
            },
            "NECESE": {
              "NEOKE MCT70": "NEOKE MCT70"
            },
            "NESTLE HEALTHCARE NUTRITION": {
              "MCT OIL": "MCT OIL",
              "MICROLIPID": "MICROLIPID"
            },
            "NUTRICIA NORTH AMERICA": {
              "LIQUIGEN": "LIQUIGEN"
            },
            "SOLACE NUTRITION": {
              "NEOKE MCT70": "NEOKE MCT70"
            },
            "ULTRAGENYX PHARMACEUTICAL": {
              "DOJOLVI": "DOJOLVI"
            },
            "VITAFLO": {
              "BETAQUIK": "BETAQUIK",
              "CARBZERO": "CARBZERO"
            }
          }
        },
        "Lipotropics": {
          "Lipotropic Combinations": {
            "ACTIPHARMA": {
              "ACTIPOCHOL PLUS": "ACTIPOCHOL PLUS"
            },
            "BASIC DRUGS": {
              "LECITHIN": "LECITHIN"
            },
            "BPI LABS LLC": {
              "LECITHIN": "LECITHIN"
            },
            "CHAIN DRUG CONSORTIUM": {
              "SOYA LECITHIN": "SOYA LECITHIN"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS LECITHIN": "CVS LECITHIN"
            },
            "EMPOWER PHARMACY": {
              "LIPO": "LIPO",
              "LIPO-C": "LIPO-C"
            },
            "EQUALINE": {
              "EQL LECITHIN": "EQL LECITHIN"
            },
            "EXELTIS USA": {
              "PREGNITUDE": "PREGNITUDE"
            },
            "FAGRON": {
              "LECITHIN": "LECITHIN"
            },
            "FREEDA VITAMINS": {
              "GRAM-O-LECI": "GRAM-O-LECI",
              "LECITHIN": "LECITHIN"
            },
            "IMPRIMIS NJOF": {
              "MIC-L-CARNITINE": "MIC-L-CARNITINE"
            },
            "LIVERITE PRODUCTS": {
              "LIVERITE": "LIVERITE"
            },
            "MAGNO-HUMPHRIES LABORATORIES": {
              "SOYA LECITHIN": "SOYA LECITHIN"
            },
            "MAJOR PHARMACEUTICALS": {
              "LECITHIN": "LECITHIN"
            },
            "MASON VITAMINS": {
              "LECITHIN 3500": "LECITHIN 3500",
              "SOYA LECITHIN": "SOYA LECITHIN"
            },
            "MCKESSON SUNMARK": {
              "SM SOYA LECITHIN": "SM SOYA LECITHIN"
            },
            "MEDISCA": {
              "LECITHIN": "LECITHIN"
            },
            "NAT-RUL HEALTH PRODUCTS": {
              "LECITHIN": "LECITHIN"
            },
            "NATIONAL VITAMIN": {
              "LECITHIN": "LECITHIN"
            },
            "NATURES BOUNTY": {
              "LECITHIN": "LECITHIN"
            },
            "PHARMASSURE, INC.": {
              "LECITHIN": "LECITHIN"
            },
            "REXALL SUNDOWN": {
              "LECITHIN": "LECITHIN",
              "SOYA LECITHIN": "SOYA LECITHIN"
            },
            "TERAL": {
              "LIPOCHOL NR": "LIPOCHOL NR",
              "LIPOCHOL PLUS": "LIPOCHOL PLUS"
            },
            "THE KEY COMPANY": {
              "LECITHIN-19": "LECITHIN-19"
            },
            "THERALOGIX": {
              "OVASITOL": "OVASITOL"
            },
            "WAL-MART": {
              "LECITHIN CONCENTRATE": "LECITHIN CONCENTRATE"
            },
            "WALGREENS": {
              "LECITHIN": "LECITHIN"
            },
            "WINDMILL CONSUMER PRODUCTS": {
              "LECITHIN": "LECITHIN"
            }
          },
          "Lipotropics": {
            "BIO-TECH": {
              "CHOLINE": "CHOLINE"
            },
            "ENDURANCE PRODUCTS": {
              "CHOLINE SR": "CHOLINE SR"
            },
            "FREEDA VITAMINS": {
              "CHOLINE CITRATE": "CHOLINE CITRATE",
              "INOSITOL": "INOSITOL"
            },
            "FREEDOM PHARMACEUTICALS": {
              "CHOLINE BITARTRATE": "CHOLINE BITARTRATE"
            },
            "J R CARLSON LABORATORIES": {
              "PHOSPHATIDYLSERINE": "PHOSPHATIDYLSERINE"
            },
            "LETCO MEDICAL": {
              "CHOLINE BITARTRATE": "CHOLINE BITARTRATE"
            },
            "MILLER": {
              "PHOSPHATIDYL CHOLINE": "PHOSPHATIDYL CHOLINE"
            },
            "NAT-RUL HEALTH PRODUCTS": {
              "INOSITOL": "INOSITOL"
            },
            "NATURES BOUNTY": {
              "INOSITOL": "INOSITOL",
              "NEURO-PS": "NEURO-PS"
            },
            "NOW HEALTH GROUP": {
              "INOSITOL": "INOSITOL"
            },
            "PCCA": {
              "CHOLINE BITARTRATE": "CHOLINE BITARTRATE"
            },
            "RUGBY LABORATORIES": {
              "INOSITOL": "INOSITOL"
            },
            "SPECTRUM": {
              "CHOLINE BITARTRATE": "CHOLINE BITARTRATE"
            },
            "THE KEY COMPANY": {
              "INOSITOL-5": "INOSITOL-5"
            }
          }
        },
        "Misc. Nutritional Substances": {
          "Misc. Nutritional Substances": {
            "21ST CENTURY HEALTHCARE": {
              "FISH OIL": "FISH OIL",
              "FISH OIL MAXIMUM STRENGTH": "FISH OIL MAXIMUM STRENGTH",
              "GINSENG EXTRACT": "GINSENG EXTRACT",
              "OMEGA-3 FISH OIL": "OMEGA-3 FISH OIL",
              "PRENATAL DHA": "PRENATAL DHA"
            },
            "A-S MEDICATION SOLUTIONS": {
              "FISH OIL": "FISH OIL"
            },
            "ADVANCED MEDICAL ENTERPRISES": {
              "ATABEX DHA 200": "ATABEX DHA 200"
            },
            "ALP LIFE SCIENCES": {
              "ALP HIGH3": "ALP HIGH3"
            },
            "ATLANTIC BIOLOGICALS": {
              "FISH OIL": "FISH OIL"
            },
            "AVPAK": {
              "FISH OIL": "FISH OIL"
            },
            "BASIC DRUGS": {
              "FISH OIL": "FISH OIL",
              "FISH OIL CONCENTRATE": "FISH OIL CONCENTRATE",
              "ODORLESS COATED FISH OIL": "ODORLESS COATED FISH OIL"
            },
            "BASIC ORGANICS": {
              "GINSENG": "GINSENG",
              "OMEGA 3": "OMEGA 3",
              "OMEGA-3 FISH OIL": "OMEGA-3 FISH OIL"
            },
            "BERGEN BRUNSWIG": {
              "GNP EVENING PRIMROSE OIL": "GNP EVENING PRIMROSE OIL",
              "GNP FISH OIL": "GNP FISH OIL",
              "GNP FISH OIL MAX ST": "GNP FISH OIL MAX ST",
              "GNP GINSENG": "GNP GINSENG"
            },
            "BIO-TECH": {
              "CREATINE": "CREATINE",
              "GLA-45": "GLA-45",
              "SAM-E.P.A.": "SAM-E.P.A."
            },
            "BRYANT RANCH PREPACK": {
              "OMEGA III EPA+DHA": "OMEGA III EPA+DHA"
            },
            "CHAIN DRUG CONSORTIUM": {
              "EVENING PRIMROSE OIL": "EVENING PRIMROSE OIL",
              "FISH OIL": "FISH OIL",
              "KOREAN GINSENG": "KOREAN GINSENG",
              "PANAX GINSENG": "PANAX GINSENG"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC FISH OIL": "QC FISH OIL"
            },
            "CHRONOHEALTH": {
              "EVENING PRIMROSE OIL": "EVENING PRIMROSE OIL"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS ALGAL-900 DHA": "CVS ALGAL-900 DHA",
              "CVS FISH OIL": "CVS FISH OIL",
              "CVS FISH OIL HALF-THE-SIZE": "CVS FISH OIL HALF-THE-SIZE",
              "CVS NATURAL FISH OIL": "CVS NATURAL FISH OIL",
              "CVS OMEGA-3 GUMMY FISH/DHA": "CVS OMEGA-3 GUMMY FISH/DHA"
            },
            "EQUALINE": {
              "EQL EVENING PRIMROSE OIL": "EQL EVENING PRIMROSE OIL",
              "EQL FISH OIL": "EQL FISH OIL",
              "EQL OMEGA 3 FISH OIL": "EQL OMEGA 3 FISH OIL",
              "EQL OMEGA-3 GUMMIES CHILD": "EQL OMEGA-3 GUMMIES CHILD",
              "EQL PANAX GINSENG": "EQL PANAX GINSENG"
            },
            "GENDOSE PHARMACEUTICALS": {
              "FISH OIL": "FISH OIL"
            },
            "GENERAL NUTRITION CORP": {
              "MULTI GINSENG": "MULTI GINSENG"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "PRO NUTRIENTS OMEGA 3": "PRO NUTRIENTS OMEGA 3"
            },
            "GOOD THINGS HEALTH": {
              "OMEGA-3": "OMEGA-3"
            },
            "HILLESTAD PHARMACEUTICALS": {
              "DIALYVITE OMEGA-3 CONCENTRATE": "DIALYVITE OMEGA-3 CONCENTRATE"
            },
            "I-HEALTH": {
              "OVEGA-3": "OVEGA-3"
            },
            "INTEGRATIVE THERAPEUTICS": {
              "ESKIMO PUREFA": "ESKIMO PUREFA",
              "TYLER ESKIMO-3": "TYLER ESKIMO-3"
            },
            "J R CARLSON LABORATORIES": {
              "SUPER DHA GEMS": "SUPER DHA GEMS",
              "THE VERY FINEST FISH OIL": "THE VERY FINEST FISH OIL",
              "THE VERY FINEST FISH OIL/KIDS": "THE VERY FINEST FISH OIL/KIDS"
            },
            "KAISER FOUNDATION HOSPITAL": {
              "KP FISH OIL": "KP FISH OIL",
              "KP OMEGA-3 FISH OIL": "KP OMEGA-3 FISH OIL"
            },
            "LEADER BRAND PRODUCTS": {
              "FISH OIL": "FISH OIL",
              "GINSENG": "GINSENG",
              "PRENATAL DHA": "PRENATAL DHA"
            },
            "LIBERTY BIOSCIENCE": {
              "ULTRA OMEGA 3": "ULTRA OMEGA 3"
            },
            "M J NUTR": {
              "EXPECTA LIPIL": "EXPECTA LIPIL"
            },
            "MAGNA PHARMACEUTICALS, INC": {
              "OMEGA-3": "OMEGA-3"
            },
            "MAGNO-HUMPHRIES LABORATORIES": {
              "EVENING PRIMROSE OIL": "EVENING PRIMROSE OIL",
              "MAXIMUM EPA": "MAXIMUM EPA",
              "SIBERIAN GINSENG": "SIBERIAN GINSENG"
            },
            "MAJOR PHARMACEUTICALS": {
              "FISH OIL": "FISH OIL"
            },
            "MARLEX PHARMACEUTICALS": {
              "OMEGA-3 FISH OIL": "OMEGA-3 FISH OIL"
            },
            "MASON VITAMINS": {
              "BEE POLLEN": "BEE POLLEN",
              "DHA OMEGA 3": "DHA OMEGA 3",
              "EVENING PRIMROSE OIL": "EVENING PRIMROSE OIL",
              "FISH OIL": "FISH OIL",
              "FISH OIL/SUPER POTENT/NO BURP": "FISH OIL/SUPER POTENT/NO BURP",
              "GINSENG": "GINSENG",
              "HEALTHY KIDS GUMMIES": "HEALTHY KIDS GUMMIES",
              "KOREAN GINSENG": "KOREAN GINSENG",
              "MAXEPA": "MAXEPA",
              "OMEGA-3": "OMEGA-3",
              "OMEGA-3 FISH OIL": "OMEGA-3 FISH OIL",
              "SUPER OMEGA-3": "SUPER OMEGA-3"
            },
            "MCKESSON": {
              "HM FISH OIL": "HM FISH OIL"
            },
            "MCKESSON SUNMARK": {
              "SM FISH OIL": "SM FISH OIL",
              "SM OMEGA-3 FISH OIL": "SM OMEGA-3 FISH OIL"
            },
            "MEDECOR PHARMA": {
              "OMEGA-3 IQ": "OMEGA-3 IQ"
            },
            "MEDICINE SHOPPE": {
              "FISH OIL": "FISH OIL",
              "FISH OIL EXTRA STRENGTH": "FISH OIL EXTRA STRENGTH"
            },
            "MEDTRITION NATIONAL NUTRITION": {
              "HYFIBER": "HYFIBER"
            },
            "NAT-RUL HEALTH PRODUCTS": {
              "BEE POLLEN": "BEE POLLEN",
              "EVENING PRIMROSE OIL": "EVENING PRIMROSE OIL",
              "KOREAN GINSENG": "KOREAN GINSENG",
              "OMEGA-3": "OMEGA-3",
              "OMEGA-3 CF": "OMEGA-3 CF",
              "OMEGA-3 FISH OIL CONCENTRATE": "OMEGA-3 FISH OIL CONCENTRATE",
              "SUPER OMEGA-EPA": "SUPER OMEGA-EPA"
            },
            "NATIONAL VITAMIN": {
              "BEE POLLEN": "BEE POLLEN",
              "DHA": "DHA",
              "EVENING PRIMROSE OIL": "EVENING PRIMROSE OIL",
              "KOREAN GINSENG": "KOREAN GINSENG",
              "OMEGA-3": "OMEGA-3",
              "OMEGA-3 FISH OIL": "OMEGA-3 FISH OIL",
              "OMEGA-3 FISH OIL EX ST": "OMEGA-3 FISH OIL EX ST"
            },
            "NATROL": {
              "OMEGA-3": "OMEGA-3",
              "OMEGA-3 FISH OIL": "OMEGA-3 FISH OIL"
            },
            "NATURES BOUNTY": {
              "BEE POLLEN": "BEE POLLEN",
              "DHA NATURAL OMEGA-3": "DHA NATURAL OMEGA-3",
              "EPA": "EPA",
              "EVENING PRIMROSE OIL": "EVENING PRIMROSE OIL",
              "FISH OIL": "FISH OIL",
              "FISH OIL TRIPLE STRENGTH": "FISH OIL TRIPLE STRENGTH",
              "KOREAN GINSENG": "KOREAN GINSENG",
              "MANCHURIAN GINSENG": "MANCHURIAN GINSENG",
              "MINI FISH OIL": "MINI FISH OIL",
              "SALMON OIL": "SALMON OIL"
            },
            "NUTRAMAX LABORATORIES": {
              "FISH OIL": "FISH OIL"
            },
            "PHARMA NATURAL": {
              "OMEGA 3": "OMEGA 3",
              "OMEGA-3 PLUS": "OMEGA-3 PLUS"
            },
            "PHARMASSURE, INC.": {
              "EVENING PRIMROSE OIL": "EVENING PRIMROSE OIL",
              "FISH OIL": "FISH OIL",
              "SIBERIAN ROOT": "SIBERIAN ROOT"
            },
            "PHARMAVITE": {
              "FISH OIL": "FISH OIL",
              "FISH OIL ADULT GUMMIES": "FISH OIL ADULT GUMMIES",
              "FISH OIL BURP-LESS": "FISH OIL BURP-LESS",
              "FISH OIL DOUBLE STRENGTH": "FISH OIL DOUBLE STRENGTH",
              "FISH OIL MAXIMUM STRENGTH": "FISH OIL MAXIMUM STRENGTH",
              "FISH OIL PEARLS": "FISH OIL PEARLS",
              "FISH OIL ULTRA": "FISH OIL ULTRA",
              "GINSENG": "GINSENG",
              "OMEGA-3 EPA FISH OIL": "OMEGA-3 EPA FISH OIL",
              "ULTRA OMEGA-3 FISH OIL": "ULTRA OMEGA-3 FISH OIL"
            },
            "PHYSICIAN RECOMMENDED NUTR": {
              "OMEGA ESSENTIALS BASIC": "OMEGA ESSENTIALS BASIC"
            },
            "PRECISION DOSE, INC": {
              "ENTERIC FISH OIL": "ENTERIC FISH OIL"
            },
            "PRINCETON RESEARCH": {
              "FISH OIL": "FISH OIL",
              "FISH OIL TRIPLE STRENGTH": "FISH OIL TRIPLE STRENGTH"
            },
            "PROFICIENT RX": {
              "FISH OIL": "FISH OIL"
            },
            "PUBLIX SUPER MARKETS INC.": {
              "PX FISH OIL": "PX FISH OIL"
            },
            "RELIABLE 1 LABS": {
              "OMEGA 3 500": "OMEGA 3 500"
            },
            "REXALL SUNDOWN": {
              "EVENING PRIMROSE OIL": "EVENING PRIMROSE OIL",
              "FISH OIL": "FISH OIL",
              "FISH OIL EXTRA STRENGTH": "FISH OIL EXTRA STRENGTH",
              "FISH OIL ODOR-LESS": "FISH OIL ODOR-LESS",
              "KOREAN GINSENG": "KOREAN GINSENG",
              "OMEGA-3": "OMEGA-3"
            },
            "RITE AID CORPORATION": {
              "PA FISH OIL": "PA FISH OIL",
              "RA BEE POLLEN": "RA BEE POLLEN",
              "RA EXPECTING DHA": "RA EXPECTING DHA",
              "RA FISH OIL": "RA FISH OIL",
              "RA FISH OIL EXTRA STRENGTH": "RA FISH OIL EXTRA STRENGTH"
            },
            "RUGBY LABORATORIES": {
              "FISH OIL": "FISH OIL",
              "SEA-OMEGA": "SEA-OMEGA",
              "SEA-OMEGA 30": "SEA-OMEGA 30"
            },
            "RV NUTRITIONAL": {
              "OMERA": "OMERA"
            },
            "SAFECOR HEALTH": {
              "OMEGA-3 FISH OIL": "OMEGA-3 FISH OIL"
            },
            "SANCILIO": {
              "OCEAN BLUE MINICAPS OMEGA-3": "OCEAN BLUE MINICAPS OMEGA-3",
              "OCEAN BLUE OPTIMAL HEALTH": "OCEAN BLUE OPTIMAL HEALTH",
              "OMEGA POWER": "OMEGA POWER",
              "OMEGA-3 2100": "OMEGA-3 2100"
            },
            "SELECT BRAND": {
              "SB OMEGA-3 FISH OIL": "SB OMEGA-3 FISH OIL"
            },
            "SOLACE NUTRITION": {
              "CYTOTINE": "CYTOTINE"
            },
            "THE COROMEGA COMPANY": {
              "COROMEGA OMEGA 3 KIDS": "COROMEGA OMEGA 3 KIDS",
              "COROMEGA OMEGA 3 SQUEEZE": "COROMEGA OMEGA 3 SQUEEZE",
              "PRENATAL OMEGA BABY": "PRENATAL OMEGA BABY"
            },
            "THE KEY COMPANY": {
              "SALMON OIL-1000": "SALMON OIL-1000"
            },
            "THERALOGIX": {
              "THEROMEGA": "THEROMEGA",
              "THEROMEGA SPORT": "THEROMEGA SPORT"
            },
            "TWIN LABS": {
              "EMULSIFIED OMEGA-3": "EMULSIFIED OMEGA-3",
              "OMEGA-3": "OMEGA-3",
              "SUPER TWIN EPA/DHA": "SUPER TWIN EPA/DHA"
            },
            "VITAFLO": {
              "CREATINE5000": "CREATINE5000",
              "DOC OMEGA": "DOC OMEGA"
            },
            "WAL-MART": {
              "ALGAL OMEGA-3 DHA": "ALGAL OMEGA-3 DHA",
              "ALGAL-900 DHA": "ALGAL-900 DHA",
              "BEE POLLEN": "BEE POLLEN",
              "EVENING PRIMROSE OIL": "EVENING PRIMROSE OIL",
              "FISH OIL": "FISH OIL",
              "KOREAN PANAX GINSENG": "KOREAN PANAX GINSENG",
              "MINI FISH OIL": "MINI FISH OIL",
              "NORWEGIAN SALMON OIL": "NORWEGIAN SALMON OIL",
              "SV FISH OIL": "SV FISH OIL"
            },
            "WALGREENS": {
              "ALGAL-900 DHA": "ALGAL-900 DHA",
              "DHA ALGAL-900": "DHA ALGAL-900",
              "DHA COMPLETE": "DHA COMPLETE",
              "FISH OIL": "FISH OIL",
              "FISH OIL CONCENTRATE": "FISH OIL CONCENTRATE",
              "FISH OIL DOUBLE STRENGTH": "FISH OIL DOUBLE STRENGTH",
              "FISH OIL OMEGA-3": "FISH OIL OMEGA-3",
              "FISH OIL PEARLS": "FISH OIL PEARLS",
              "FISH OIL TRIPLE STRENGTH": "FISH OIL TRIPLE STRENGTH",
              "GIN-ZING": "GIN-ZING",
              "OMEGA 3": "OMEGA 3",
              "OMEGA-3": "OMEGA-3",
              "REALROOT GINSENG": "REALROOT GINSENG",
              "THERAGRAN-M FISH OIL CONC": "THERAGRAN-M FISH OIL CONC"
            },
            "WINDMILL CONSUMER PRODUCTS": {
              "EVENING PRIMROSE OIL": "EVENING PRIMROSE OIL",
              "OMEGA 3": "OMEGA 3",
              "OMEGA III EPA+DHA": "OMEGA III EPA+DHA",
              "SALMON": "SALMON",
              "SUPER OMEGA 3": "SUPER OMEGA 3",
              "SUPER OMEGA 3 EPA/DHA": "SUPER OMEGA 3 EPA/DHA",
              "ULTRA OMEGA 3": "ULTRA OMEGA 3"
            },
            "XYMOGEN": {
              "OMEGAPURE 780 EC": "OMEGAPURE 780 EC",
              "OMEGAPURE 900 EC": "OMEGAPURE 900 EC"
            }
          },
          "Misc. Nutritional Substances Combinations": {
            "ADVANCED MEDICAL ENTERPRISES": {
              "ATABEX DHA": "ATABEX DHA"
            },
            "AG MARIN PHARMACEUTICALS": {
              "CARDIOVID PLUS": "CARDIOVID PLUS"
            },
            "AKORN CONSUMER": {
              "THERATEARS NUTRITION": "THERATEARS NUTRITION"
            },
            "BAUSCH HEALTH": {
              "NITEBITE": "NITEBITE"
            },
            "BERGEN BRUNSWIG": {
              "GNP COQ-10 & FISH OIL": "GNP COQ-10 & FISH OIL"
            },
            "BIO-TECH": {
              "BORAGE": "BORAGE",
              "SAM-G.L.A.": "SAM-G.L.A.",
              "ZINCOLATE": "ZINCOLATE"
            },
            "CHAIN DRUG CONSORTIUM": {
              "GARLIC-PARSLEY": "GARLIC-PARSLEY"
            },
            "CHRONOHEALTH": {
              "OMEGA-3 COMPLEX": "OMEGA-3 COMPLEX"
            },
            "FREEDA VITAMINS": {
              "GARLIC-PARSLEY": "GARLIC-PARSLEY"
            },
            "GENERAL NUTRITION CORP": {
              "CLA": "CLA"
            },
            "GM PHARMACEUTICALS": {
              "VANACHOL": "VANACHOL"
            },
            "GOOD THINGS HEALTH": {
              "OMEGA-3 + VITAMIN D3 ULTRA STR": "OMEGA-3 + VITAMIN D3 ULTRA STR"
            },
            "LIPOTRIAD": {
              "LIPOTRIAD DRY EYE": "LIPOTRIAD DRY EYE"
            },
            "MAGNO-HUMPHRIES LABORATORIES": {
              "GARLIC-PARSLEY": "GARLIC-PARSLEY"
            },
            "MASON VITAMINS": {
              "CHOLESTEROL TRIO": "CHOLESTEROL TRIO",
              "CO Q-10 VITAMIN E FISH OIL": "CO Q-10 VITAMIN E FISH OIL",
              "GARLIC-PARSLEY": "GARLIC-PARSLEY",
              "LECITHIN W/KELP/B-6": "LECITHIN W/KELP/B-6",
              "LECITHIN-KELP-B6-CIDER VINEGAR": "LECITHIN-KELP-B6-CIDER VINEGAR",
              "PYRIDOXINE-KELP-LECT-VINEGAR": "PYRIDOXINE-KELP-LECT-VINEGAR",
              "THE ELIMINATOR": "THE ELIMINATOR"
            },
            "MILLER": {
              "EPA/GLA": "EPA/GLA",
              "GARLIC-LECITHIN": "GARLIC-LECITHIN",
              "GARLIC/EPA": "GARLIC/EPA"
            },
            "NAT-RUL HEALTH PRODUCTS": {
              "GARLIC-PARSLEY": "GARLIC-PARSLEY"
            },
            "NATIONAL VITAMIN": {
              "KELP-B6-LECITHIN-VINEGAR": "KELP-B6-LECITHIN-VINEGAR"
            },
            "NATROL": {
              "FISH OIL-VITAMIN D": "FISH OIL-VITAMIN D"
            },
            "NATURES BOUNTY": {
              "GARLIC-PARSLEY": "GARLIC-PARSLEY",
              "KLB6": "KLB6"
            },
            "NUTRACEUTICS": {
              "EQUAZEN PRO": "EQUAZEN PRO"
            },
            "PHARMAVITE": {
              "EVENING PRIMROSE OIL-VIT E": "EVENING PRIMROSE OIL-VIT E"
            },
            "PHYSICIAN RECOMMENDED NUTR": {
              "CARDIO OMEGA BENEFITS/VIT D-3": "CARDIO OMEGA BENEFITS/VIT D-3",
              "DRY EYE OMEGA BENEFITS/VIT D-3": "DRY EYE OMEGA BENEFITS/VIT D-3",
              "FLEX OMEGA BENEFITS/VIT D-3": "FLEX OMEGA BENEFITS/VIT D-3",
              "MOMS OMEGA ADVANTAGE/VIT D-3": "MOMS OMEGA ADVANTAGE/VIT D-3",
              "OMEGA ESSENTIALS/VIT D3": "OMEGA ESSENTIALS/VIT D3"
            },
            "RITE AID CORPORATION": {
              "PA FISH OIL/VIT D3 TRIPLE ST": "PA FISH OIL/VIT D3 TRIPLE ST"
            },
            "SANCILIO": {
              "OCEAN BLUE MINICAPS D/OMEGA3": "OCEAN BLUE MINICAPS D/OMEGA3"
            },
            "TERAL": {
              "ALBA-LYBE NR": "ALBA-LYBE NR"
            },
            "THE COROMEGA COMPANY": {
              "COROMEGA": "COROMEGA"
            },
            "TRIVIDIA HEALTH": {
              "TRUEPLUS GLUCOSE": "TRUEPLUS GLUCOSE",
              "TRUEPLUS GLUCOSE ON THE GO": "TRUEPLUS GLUCOSE ON THE GO",
              "TRUEPLUS GLUCOSE SHOT": "TRUEPLUS GLUCOSE SHOT"
            },
            "TWIN LABS": {
              "OMEGA-3": "OMEGA-3"
            },
            "UPSPRING": {
              "UPSPRING BABY VIT D + BRAIN": "UPSPRING BABY VIT D + BRAIN"
            },
            "VITAFLO": {
              "KEY OMEGA": "KEY OMEGA"
            },
            "VITAMIN HEALTH": {
              "VITEYES OMEGA-3": "VITEYES OMEGA-3"
            },
            "WAL-MART": {
              "RELION GLUCOSE SHOT": "RELION GLUCOSE SHOT"
            }
          }
        },
        "Protein-Carbohydrate-Lipid Combinations": {
          "Protein-Carbohydrate-Lipid with Electrolyte Combinations": {
            "FRESENIUS KABI USA": {
              "KABIVEN": "KABIVEN",
              "PERIKABIVEN": "PERIKABIVEN"
            }
          }
        },
        "Proteins": {
          "Amino Acid Mixtures": {
            "ANAZAOHEALTH CORPORATION": {
              "AMINOPROTECT": "AMINOPROTECT"
            },
            "APPLIED NUTRITION CORPORATION": {
              "COMPLEX ESSENTIAL MSD": "COMPLEX ESSENTIAL MSD",
              "COMPLEX JUNIOR MSD": "COMPLEX JUNIOR MSD",
              "COMPLEX MSUD": "COMPLEX MSUD",
              "GLUTARADE AMINO ACID BLEND": "GLUTARADE AMINO ACID BLEND",
              "GLUTARADE ESSENTIAL GA-1": "GLUTARADE ESSENTIAL GA-1",
              "GLUTARADE JUNIOR GA-1": "GLUTARADE JUNIOR GA-1",
              "PHENYLADE": "PHENYLADE",
              "PHENYLADE AMINO ACID": "PHENYLADE AMINO ACID",
              "PHENYLADE AMINO ACID BLEND": "PHENYLADE AMINO ACID BLEND",
              "PHENYLADE MTE": "PHENYLADE MTE",
              "PHENYLADE MTE AMINO ACID BLEND": "PHENYLADE MTE AMINO ACID BLEND",
              "PHENYLADE PHEBLOC": "PHENYLADE PHEBLOC",
              "PHENYLADE40 DRINK MIX": "PHENYLADE40 DRINK MIX"
            },
            "B. BRAUN MEDICAL": {
              "FREAMINE HBC": "FREAMINE HBC",
              "FREAMINE III": "FREAMINE III",
              "HEPATAMINE": "HEPATAMINE",
              "NEPHRAMINE": "NEPHRAMINE",
              "PLENAMINE": "PLENAMINE",
              "PROCALAMINE": "PROCALAMINE",
              "TROPHAMINE": "TROPHAMINE"
            },
            "BAXTER HEALTHCARE CORP": {
              "CLINIMIX E/DEXTROSE (2.75/10)": "CLINIMIX E/DEXTROSE (2.75/10)",
              "CLINIMIX E/DEXTROSE (2.75/5)": "CLINIMIX E/DEXTROSE (2.75/5)",
              "CLINIMIX E/DEXTROSE (4.25/10)": "CLINIMIX E/DEXTROSE (4.25/10)",
              "CLINIMIX E/DEXTROSE (4.25/25)": "CLINIMIX E/DEXTROSE (4.25/25)",
              "CLINIMIX E/DEXTROSE (4.25/5)": "CLINIMIX E/DEXTROSE (4.25/5)",
              "CLINIMIX E/DEXTROSE (5/15)": "CLINIMIX E/DEXTROSE (5/15)",
              "CLINIMIX E/DEXTROSE (5/20)": "CLINIMIX E/DEXTROSE (5/20)",
              "CLINIMIX E/DEXTROSE (5/25)": "CLINIMIX E/DEXTROSE (5/25)",
              "CLINIMIX E/DEXTROSE (8/10)": "CLINIMIX E/DEXTROSE (8/10)",
              "CLINIMIX E/DEXTROSE (8/14)": "CLINIMIX E/DEXTROSE (8/14)",
              "CLINIMIX N14G30E": "CLINIMIX N14G30E",
              "CLINIMIX N9G15E": "CLINIMIX N9G15E",
              "CLINIMIX N9G20E": "CLINIMIX N9G20E",
              "CLINIMIX/DEXTROSE (2.75/5)": "CLINIMIX/DEXTROSE (2.75/5)",
              "CLINIMIX/DEXTROSE (4.25/10)": "CLINIMIX/DEXTROSE (4.25/10)",
              "CLINIMIX/DEXTROSE (4.25/20)": "CLINIMIX/DEXTROSE (4.25/20)",
              "CLINIMIX/DEXTROSE (4.25/25)": "CLINIMIX/DEXTROSE (4.25/25)",
              "CLINIMIX/DEXTROSE (4.25/5)": "CLINIMIX/DEXTROSE (4.25/5)",
              "CLINIMIX/DEXTROSE (5/15)": "CLINIMIX/DEXTROSE (5/15)",
              "CLINIMIX/DEXTROSE (5/20)": "CLINIMIX/DEXTROSE (5/20)",
              "CLINIMIX/DEXTROSE (5/25)": "CLINIMIX/DEXTROSE (5/25)",
              "CLINIMIX/DEXTROSE (6/5)": "CLINIMIX/DEXTROSE (6/5)",
              "CLINIMIX/DEXTROSE (8/10)": "CLINIMIX/DEXTROSE (8/10)",
              "CLINIMIX/DEXTROSE (8/14)": "CLINIMIX/DEXTROSE (8/14)",
              "CLINISOL SF": "CLINISOL SF",
              "PREMASOL": "PREMASOL",
              "PROSOL": "PROSOL",
              "SYNTHAMIN 17": "SYNTHAMIN 17",
              "TRAVASOL": "TRAVASOL"
            },
            "BIO-TECH": {
              "AMINOS": "AMINOS"
            },
            "CALWOOD NUTRITIONALS": {
              "NUTRASENTIALS": "NUTRASENTIALS"
            },
            "EMPOWER PHARMACY": {
              "BCAA": "BCAA"
            },
            "FREEDA VITAMINS": {
              "TRIAMINO": "TRIAMINO"
            },
            "GENERAL NUTRITION CORP": {
              "AMINO 4800": "AMINO 4800"
            },
            "GLEN VENTURES": {
              "AMINOAMRMS": "AMINOAMRMS",
              "AMINORELIEFRMS": "AMINORELIEFRMS"
            },
            "GLOBAL HEALTH INC": {
              "LIQUACEL": "LIQUACEL",
              "LIQUACEL PUMP + GO": "LIQUACEL PUMP + GO"
            },
            "HOSPIRA": {
              "AMINOSYN": "AMINOSYN",
              "AMINOSYN II": "AMINOSYN II",
              "AMINOSYN II/ELECTROLYTES": "AMINOSYN II/ELECTROLYTES",
              "AMINOSYN M": "AMINOSYN M",
              "AMINOSYN-HBC": "AMINOSYN-HBC",
              "AMINOSYN-PF": "AMINOSYN-PF",
              "AMINOSYN-RF": "AMINOSYN-RF",
              "AMINOSYN/ELECTROLYTES": "AMINOSYN/ELECTROLYTES"
            },
            "ICU MEDICAL": {
              "AMINOSYN II": "AMINOSYN II",
              "AMINOSYN-PF": "AMINOSYN-PF"
            },
            "KRAMER-NOVIS": {
              "G-PREPROTEIN": "G-PREPROTEIN",
              "PRE PROTEIN": "PRE PROTEIN",
              "PREPROTEIN": "PREPROTEIN",
              "PREPROTEIN 20": "PREPROTEIN 20"
            },
            "LLORENS PHARMACEUTICAL": {
              "PROTEINEX": "PROTEINEX"
            },
            "MASON VITAMINS": {
              "DAILY AMINO 6000": "DAILY AMINO 6000",
              "SUPER AMINO ACIDS": "SUPER AMINO ACIDS"
            },
            "MEDTRITION NATIONAL NUTRITION": {
              "ARGIMENT": "ARGIMENT",
              "ARGIMENT AT": "ARGIMENT AT",
              "HEPAMENT": "HEPAMENT"
            },
            "MILLER": {
              "AMINA-21": "AMINA-21",
              "DEQUASINE": "DEQUASINE"
            },
            "NAT-RUL HEALTH PRODUCTS": {
              "SUPER-AMINO 1600": "SUPER-AMINO 1600"
            },
            "NATIONAL VITAMIN": {
              "AMINO ACTION": "AMINO ACTION",
              "L-CARNITINE": "L-CARNITINE"
            },
            "NEPHRON STERILE COMPOUNDING": {
              "AMINO ACID": "AMINO ACID"
            },
            "NUTRICIA NORTH AMERICA": {
              "ADD-INS COMPLETE": "ADD-INS COMPLETE",
              "COMPLETE AMINO ACID MIX": "COMPLETE AMINO ACID MIX",
              "ESSENTIAL AMINO ACID MIX": "ESSENTIAL AMINO ACID MIX",
              "PERIFLEX LQ PKU": "PERIFLEX LQ PKU",
              "PHLEXY-10": "PHLEXY-10",
              "XPHE MAXAMUM": "XPHE MAXAMUM"
            },
            "QCE LABORATORIES": {
              "DECUBAMINE": "DECUBAMINE"
            },
            "REXALL SUNDOWN": {
              "AMINO ACIDS COMPLEX": "AMINO ACIDS COMPLEX"
            },
            "THE KEY COMPANY": {
              "KAMINOS": "KAMINOS"
            },
            "VITAFLO": {
              "SOLVIL": "SOLVIL"
            }
          },
          "Amino Acids-Single": {
            "21ST CENTURY HEALTHCARE": {
              "L-ARGININE MAXIMUM STRENGTH": "L-ARGININE MAXIMUM STRENGTH",
              "LYSINE HCL": "LYSINE HCL"
            },
            "ACTIPHARMA": {
              "ACTICARNITINE SF": "ACTICARNITINE SF",
              "LEVOCARNITINE (DIETARY)": "LEVOCARNITINE (DIETARY)"
            },
            "AMERICAN PHARMA INGREDIENTS": {
              "L-HISTIDINE": "L-HISTIDINE",
              "TAURINE": "TAURINE"
            },
            "APOTHECA SUPPLY": {
              "L-ISOLEUCINE": "L-ISOLEUCINE",
              "L-TRYPTOPHAN": "L-TRYPTOPHAN"
            },
            "APPLIED NUTRITION CORPORATION": {
              "GLYCINE": "GLYCINE",
              "L-ARGININE": "L-ARGININE",
              "L-ISOLEUCINE": "L-ISOLEUCINE",
              "L-LEUCINE": "L-LEUCINE",
              "L-TYROSINE": "L-TYROSINE",
              "L-VALINE": "L-VALINE"
            },
            "BASIC DRUGS": {
              "L-ARGININE": "L-ARGININE",
              "L-CARNITINE": "L-CARNITINE",
              "L-LYSINE HCL": "L-LYSINE HCL"
            },
            "BERGEN BRUNSWIG": {
              "GNP L-LYSINE": "GNP L-LYSINE"
            },
            "BIO-TECH": {
              "CYSTECH": "CYSTECH",
              "DL-PA-500": "DL-PA-500",
              "GLUTAMINE": "GLUTAMINE",
              "L-CARNITINE": "L-CARNITINE",
              "LEVOCARNITINE FUMARATE": "LEVOCARNITINE FUMARATE",
              "LYSINE": "LYSINE",
              "ME-500": "ME-500",
              "ORNITHINE": "ORNITHINE",
              "TAURINE": "TAURINE",
              "TRYPTOPHAN": "TRYPTOPHAN",
              "TYROSINE": "TYROSINE"
            },
            "BIOMOLECULAR SCIENCES, INC.": {
              "GLUTIMMUNE": "GLUTIMMUNE"
            },
            "BIOTICS RESEARCH": {
              "L-TYROSINE": "L-TYROSINE"
            },
            "BRONSON PHARMACEUTICALS": {
              "GLUTAMINE": "GLUTAMINE"
            },
            "CHAIN DRUG CONSORTIUM": {
              "L-ARGININE": "L-ARGININE",
              "L-LYSINE HCL": "L-LYSINE HCL"
            },
            "CHRONOHEALTH": {
              "L-LYSINE": "L-LYSINE",
              "L-PROLINE": "L-PROLINE",
              "LEVOCARNITINE": "LEVOCARNITINE"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS L-LYSINE": "CVS L-LYSINE",
              "CVS LYSINE": "CVS LYSINE"
            },
            "EMMAUS MEDICAL": {
              "NUTRESTORE": "NUTRESTORE"
            },
            "EMPOWER PHARMACY": {
              "ARGININE HCL": "ARGININE HCL",
              "GABA": "GABA",
              "GLUTATHIONE": "GLUTATHIONE",
              "GLYCINE": "GLYCINE",
              "LYSINE HCL": "LYSINE HCL",
              "TAURINE": "TAURINE"
            },
            "ENCORE SCIENTIFIC": {
              "GLUTATHIONE": "GLUTATHIONE",
              "L-TRYPTOPHAN": "L-TRYPTOPHAN"
            },
            "ENDURANCE PRODUCTS": {
              "L-ARGININE": "L-ARGININE"
            },
            "ENZYMATIC THERAPY": {
              "L-THEANINE": "L-THEANINE"
            },
            "EQUALINE": {
              "EQL L-LYSINE": "EQL L-LYSINE"
            },
            "EXELA PHARMA SCIENCES": {
              "ELCYS": "ELCYS"
            },
            "FAGRON": {
              "DL-METHIONINE": "DL-METHIONINE",
              "GLUTATHIONE": "GLUTATHIONE",
              "L-ALANINE": "L-ALANINE",
              "L-ARGININE": "L-ARGININE",
              "L-CYSTINE": "L-CYSTINE",
              "L-GLUTAMINE": "L-GLUTAMINE",
              "L-GLUTATHIONE": "L-GLUTATHIONE",
              "L-ISOLEUCINE": "L-ISOLEUCINE",
              "L-LEUCINE": "L-LEUCINE",
              "L-METHIONINE": "L-METHIONINE",
              "L-TYROSINE": "L-TYROSINE",
              "L-VALINE": "L-VALINE",
              "THREONINE": "THREONINE",
              "TRYPTOPHAN": "TRYPTOPHAN"
            },
            "FREEDA VITAMINS": {
              "GLYCINE": "GLYCINE",
              "L-GLUTAMINE": "L-GLUTAMINE",
              "L-LYSINE HCL": "L-LYSINE HCL",
              "L-PHENYLALANINE": "L-PHENYLALANINE",
              "L-THREONINE": "L-THREONINE",
              "L-TRYPTOPHAN": "L-TRYPTOPHAN",
              "L-TYROSINE": "L-TYROSINE"
            },
            "FREEDOM PHARMACEUTICALS": {
              "GLUTATHIONE-L REDUCED": "GLUTATHIONE-L REDUCED",
              "L-ARGININE": "L-ARGININE",
              "L-ISOLEUCINE": "L-ISOLEUCINE",
              "L-METHIONINE": "L-METHIONINE",
              "L-PROLINE": "L-PROLINE",
              "L-TRYPTOPHAN": "L-TRYPTOPHAN",
              "L-TYROSINE": "L-TYROSINE",
              "LEUCINE": "LEUCINE"
            },
            "GENERAL NUTRITION CORP": {
              "L-ARGININE": "L-ARGININE",
              "NAC 600": "NAC 600"
            },
            "IMPRIMIS NJOF": {
              "GLUTATHIONE": "GLUTATHIONE"
            },
            "INTEGRATIVE THERAPEUTICS": {
              "L-CARNITINE": "L-CARNITINE",
              "L-THEANINE": "L-THEANINE",
              "NF FORMULAS NAC": "NF FORMULAS NAC"
            },
            "JARROW FORMULAS": {
              "N-A-C SUSTAIN": "N-A-C SUSTAIN"
            },
            "KALCHEM INTERNATIONAL": {
              "METHIONINE": "METHIONINE"
            },
            "LEADER BRAND PRODUCTS": {
              "L-LYSINE": "L-LYSINE",
              "LYSINE": "LYSINE"
            },
            "LETCO MEDICAL": {
              "DL-PHENYLALANINE": "DL-PHENYLALANINE",
              "GLUTATHIONE-L": "GLUTATHIONE-L",
              "L-ALANINE": "L-ALANINE",
              "L-ARGININE": "L-ARGININE",
              "L-GLUTAMINE": "L-GLUTAMINE",
              "L-HISTIDINE MONOHYDROCHLORIDE": "L-HISTIDINE MONOHYDROCHLORIDE",
              "L-ISOLEUCINE": "L-ISOLEUCINE",
              "L-LEUCINE": "L-LEUCINE",
              "L-ORNITHINE": "L-ORNITHINE",
              "L-PHENYLALANINE": "L-PHENYLALANINE",
              "L-TRYPTOPHAN": "L-TRYPTOPHAN",
              "L-TYROSINE": "L-TYROSINE",
              "L-VALINE": "L-VALINE",
              "METHIONINE": "METHIONINE",
              "THREONINE": "THREONINE"
            },
            "MAGNO-HUMPHRIES LABORATORIES": {
              "L-LYSINE": "L-LYSINE",
              "L-PHENYLALANINE": "L-PHENYLALANINE",
              "L-TYROSINE": "L-TYROSINE"
            },
            "MAJOR PHARMACEUTICALS": {
              "ARGININE": "ARGININE",
              "L-LYSINE HCL": "L-LYSINE HCL"
            },
            "MARLEX PHARMACEUTICALS": {
              "L-LYSINE": "L-LYSINE"
            },
            "MASON VITAMINS": {
              "L-ARGININE": "L-ARGININE",
              "L-LYSINE": "L-LYSINE",
              "L-TRYPTOPHAN": "L-TRYPTOPHAN",
              "LEVOCARNITINE FUMARATE": "LEVOCARNITINE FUMARATE",
              "NAC": "NAC"
            },
            "MCKESSON SUNMARK": {
              "SM L-LYSINE": "SM L-LYSINE"
            },
            "MCLAREN MEDICAL": {
              "G-LEVOCARNITINE S/F": "G-LEVOCARNITINE S/F"
            },
            "MEDISCA": {
              "GLUTAMINE": "GLUTAMINE",
              "GLUTATHIONE-L": "GLUTATHIONE-L",
              "GLUTATHIONE-L REDUCED": "GLUTATHIONE-L REDUCED",
              "L-ALANINE": "L-ALANINE",
              "L-ARGININE": "L-ARGININE",
              "L-GLUTAMIC ACID": "L-GLUTAMIC ACID",
              "L-GLUTAMINE": "L-GLUTAMINE",
              "L-HISTIDINE MONOHYDROCHLORIDE": "L-HISTIDINE MONOHYDROCHLORIDE",
              "L-ISOLEUCINE": "L-ISOLEUCINE",
              "L-LEUCINE": "L-LEUCINE",
              "L-METHIONINE": "L-METHIONINE",
              "L-PROLINE": "L-PROLINE",
              "L-TYROSINE": "L-TYROSINE",
              "TAURINE": "TAURINE",
              "THREONINE": "THREONINE",
              "VALINE": "VALINE"
            },
            "MEDTRITION NATIONAL NUTRITION": {
              "GLUTAMENT": "GLUTAMENT"
            },
            "MILLER": {
              "GLUTAMINE": "GLUTAMINE",
              "GLUTATHIONE": "GLUTATHIONE",
              "L-LYSINE HCL": "L-LYSINE HCL",
              "L-PHENYLALANINE": "L-PHENYLALANINE",
              "L-TYROSINE": "L-TYROSINE",
              "LEVOCARNITINE": "LEVOCARNITINE",
              "TAURINE": "TAURINE"
            },
            "MONTIFF INC": {
              "PURE L-ARGININE HCL": "PURE L-ARGININE HCL",
              "PURE L-CITRULLINE": "PURE L-CITRULLINE",
              "PURE L-TYROSINE": "PURE L-TYROSINE"
            },
            "NAT-RUL HEALTH PRODUCTS": {
              "L-ARGININE": "L-ARGININE",
              "L-CARNITINE": "L-CARNITINE",
              "L-GLUTAMINE": "L-GLUTAMINE",
              "L-LYSINE": "L-LYSINE"
            },
            "NATIONAL VITAMIN": {
              "L-LYSINE HCL": "L-LYSINE HCL",
              "LYSINE HCL": "LYSINE HCL",
              "NAC": "NAC"
            },
            "NATURES BOUNTY": {
              "ACETYL L-CARNITINE": "ACETYL L-CARNITINE",
              "ARGININE": "ARGININE",
              "L-ARGININE": "L-ARGININE",
              "L-CARNITINE": "L-CARNITINE",
              "L-GLUTAMINE": "L-GLUTAMINE",
              "L-LYSINE": "L-LYSINE",
              "L-TYROSINE": "L-TYROSINE"
            },
            "NECESE": {
              "NEOKE ALCAR": "NEOKE ALCAR"
            },
            "NESTLE HEALTHCARE NUTRITION": {
              "GLUTASOLVE": "GLUTASOLVE"
            },
            "NOW HEALTH GROUP": {
              "L-ARGININE DOUBLE STRENGTH": "L-ARGININE DOUBLE STRENGTH",
              "L-GLUTAMINE": "L-GLUTAMINE"
            },
            "NUTRICIA NORTH AMERICA": {
              "L-METHIONINE": "L-METHIONINE",
              "L-THREONINE": "L-THREONINE"
            },
            "PCCA": {
              "ALANINE": "ALANINE",
              "DL-ALANINE": "DL-ALANINE",
              "DL-LEUCINE": "DL-LEUCINE",
              "DL-METHIONINE": "DL-METHIONINE",
              "DL-PHENYLALANINE": "DL-PHENYLALANINE",
              "GLUTATHIONE-L": "GLUTATHIONE-L",
              "GLUTATHIONE-L REDUCED": "GLUTATHIONE-L REDUCED",
              "L-ARGININE": "L-ARGININE",
              "L-CYSTINE": "L-CYSTINE",
              "L-GLUTAMINE": "L-GLUTAMINE",
              "L-HISTIDINE": "L-HISTIDINE",
              "L-HISTIDINE MONOHYDROCHLORIDE": "L-HISTIDINE MONOHYDROCHLORIDE",
              "L-ISOLEUCINE": "L-ISOLEUCINE",
              "L-LEUCINE": "L-LEUCINE",
              "L-METHIONINE": "L-METHIONINE",
              "L-PHENYLALANINE": "L-PHENYLALANINE",
              "L-PROLINE": "L-PROLINE",
              "L-TYROSINE": "L-TYROSINE",
              "N-ACETYL-L-CYSTEINE": "N-ACETYL-L-CYSTEINE",
              "TAURINE": "TAURINE",
              "THREONINE": "THREONINE",
              "TRYPTOPHAN": "TRYPTOPHAN",
              "VALINE": "VALINE"
            },
            "PHARMASSURE, INC.": {
              "L-LYSINE": "L-LYSINE"
            },
            "PHARMAVITE": {
              "L-LYSINE": "L-LYSINE",
              "VITAMELTS RELAX L-THEANINE": "VITAMELTS RELAX L-THEANINE"
            },
            "PRINCETON RESEARCH": {
              "LYSINE HCL": "LYSINE HCL",
              "NAC": "NAC",
              "SP L-LYSINE": "SP L-LYSINE"
            },
            "PURE ENCAPSULATIONS": {
              "L-GLUTAMINE": "L-GLUTAMINE",
              "NAC": "NAC"
            },
            "RELIABLE 1 LABS": {
              "LEVOCARNITINE L-TARTRATE": "LEVOCARNITINE L-TARTRATE"
            },
            "REXALL SUNDOWN": {
              "ACETYL L-CARNITINE": "ACETYL L-CARNITINE",
              "L-ARGININE": "L-ARGININE",
              "L-CARNITINE": "L-CARNITINE",
              "L-LYSINE HCL": "L-LYSINE HCL"
            },
            "RITE AID CORPORATION": {
              "PA L-ARGININE": "PA L-ARGININE",
              "RA L-ARGININE": "RA L-ARGININE",
              "RA L-CARNITINE": "RA L-CARNITINE",
              "RA L-LYSINE": "RA L-LYSINE"
            },
            "RUGBY LABORATORIES": {
              "L-CARNITINE": "L-CARNITINE",
              "L-TYROSINE": "L-TYROSINE",
              "LEVOCARNITINE L-TARTRATE": "LEVOCARNITINE L-TARTRATE"
            },
            "SANDOZ": {
              "L-CYSTEINE HCL": "L-CYSTEINE HCL"
            },
            "SOLACE NUTRITION": {
              "CYTO ARG": "CYTO ARG",
              "CYTO CARN": "CYTO CARN",
              "CYTOLLINE": "CYTOLLINE",
              "GLYMAX": "GLYMAX"
            },
            "SOLUTECH PHARMACEUTICALS": {
              "GLUTAMINE": "GLUTAMINE"
            },
            "SPECTRUM": {
              "DL-METHIONINE": "DL-METHIONINE",
              "DL-PHENYLALANINE": "DL-PHENYLALANINE",
              "GLUTATHIONE-L REDUCED": "GLUTATHIONE-L REDUCED",
              "L-ALANINE": "L-ALANINE",
              "L-ARGININE": "L-ARGININE",
              "L-CYSTINE": "L-CYSTINE",
              "L-GLUTAMIC ACID": "L-GLUTAMIC ACID",
              "L-GLUTAMINE": "L-GLUTAMINE",
              "L-HISTIDINE": "L-HISTIDINE",
              "L-HISTIDINE MONOHYDROCHLORIDE": "L-HISTIDINE MONOHYDROCHLORIDE",
              "L-ISOLEUCINE": "L-ISOLEUCINE",
              "L-METHIONINE": "L-METHIONINE",
              "L-PHENYLALANINE": "L-PHENYLALANINE",
              "L-PROLINE": "L-PROLINE",
              "L-THREONINE": "L-THREONINE",
              "L-TRYPTOPHAN": "L-TRYPTOPHAN",
              "L-TYROSINE": "L-TYROSINE",
              "L-VALINE": "L-VALINE",
              "LEUCINE": "LEUCINE",
              "TAURINE": "TAURINE"
            },
            "THE KEY COMPANY": {
              "L-ARGININE-500": "L-ARGININE-500",
              "METHIONINE": "METHIONINE"
            },
            "TWIN LABS": {
              "L-ARGININE": "L-ARGININE",
              "L-CYSTEINE": "L-CYSTEINE",
              "MEGA TAURINE": "MEGA TAURINE",
              "NAC 600": "NAC 600"
            },
            "TYSON": {
              "GAMMA AMINOBUTYRIC ACID": "GAMMA AMINOBUTYRIC ACID",
              "L-ALANINE": "L-ALANINE",
              "L-ARGININE": "L-ARGININE",
              "L-CITRULLINE": "L-CITRULLINE",
              "L-CYSTINE": "L-CYSTINE",
              "L-GLUTAMIC ACID": "L-GLUTAMIC ACID",
              "L-GLUTAMINE": "L-GLUTAMINE",
              "L-HISTIDINE": "L-HISTIDINE",
              "L-ISOLEUCINE": "L-ISOLEUCINE",
              "L-LEUCINE": "L-LEUCINE",
              "L-METHIONINE": "L-METHIONINE",
              "L-ORNITHINE": "L-ORNITHINE",
              "L-PHENYLALANINE": "L-PHENYLALANINE",
              "L-TAURINE": "L-TAURINE",
              "L-VALINE": "L-VALINE",
              "LYSINYL": "LYSINYL",
              "PROLINE": "PROLINE",
              "RXOSINE": "RXOSINE",
              "THREOSTAT": "THREOSTAT"
            },
            "VITAFLO": {
              "ALANINE 1000": "ALANINE 1000",
              "ARGININE": "ARGININE",
              "ARGININE2000": "ARGININE2000",
              "CITRULLINE 1000": "CITRULLINE 1000",
              "CITRULLINE200": "CITRULLINE200",
              "CYSTINE": "CYSTINE",
              "GLYCINE500": "GLYCINE500",
              "ISOLEUCINE": "ISOLEUCINE",
              "ISOLEUCINE 1000": "ISOLEUCINE 1000",
              "LEUCINE": "LEUCINE",
              "LYSINE4000": "LYSINE4000",
              "METHIONINE": "METHIONINE",
              "PHENYLALANINE": "PHENYLALANINE",
              "TYROSINE": "TYROSINE",
              "VALINE": "VALINE",
              "VALINE 1000": "VALINE 1000"
            },
            "VITALINE": {
              "L-CARNITINE": "L-CARNITINE",
              "L-LYSINE": "L-LYSINE"
            },
            "WAL-MART": {
              "L-ARGININE": "L-ARGININE",
              "L-CARNITINE": "L-CARNITINE",
              "L-GLUTAMINE": "L-GLUTAMINE",
              "L-LYSINE HCL": "L-LYSINE HCL"
            },
            "WALGREENS": {
              "L-CARNITINE": "L-CARNITINE",
              "L-FORMULA LYSINE HCL": "L-FORMULA LYSINE HCL",
              "L-LYSINE": "L-LYSINE",
              "L-LYSINE HCL": "L-LYSINE HCL"
            },
            "WINDMILL CONSUMER PRODUCTS": {
              "L-ARGININE": "L-ARGININE",
              "L-CARNITINE": "L-CARNITINE",
              "L-GLUTAMINE": "L-GLUTAMINE",
              "L-LYSINE HCL": "L-LYSINE HCL"
            }
          },
          "Protein Combinations": {
            "ALFASIGMA USA": {
              "PROXEED": "PROXEED"
            },
            "CHEMI-SOURCE": {
              "L-CARNITINE 500": "L-CARNITINE 500"
            },
            "EMPOWER PHARMACY": {
              "CALM": "CALM",
              "TRI-AMINO": "TRI-AMINO"
            },
            "LLORENS PHARMACEUTICAL": {
              "PROTEINEX": "PROTEINEX",
              "PROTEINEX P100": "PROTEINEX P100",
              "PROTEINEX P18": "PROTEINEX P18",
              "PROTEINEX-18": "PROTEINEX-18"
            },
            "NATIONAL VITAMIN": {
              "LEVOCARNITINE-B5-TAURINE": "LEVOCARNITINE-B5-TAURINE"
            },
            "NUTRICIA NA": {
              "PRO-STAT": "PRO-STAT",
              "PRO-STAT 101": "PRO-STAT 101",
              "PRO-STAT 64": "PRO-STAT 64",
              "PRO-STAT AWC": "PRO-STAT AWC",
              "PRO-STAT MAX": "PRO-STAT MAX",
              "PRO-STAT PROFILE": "PRO-STAT PROFILE",
              "PRO-STAT RC": "PRO-STAT RC",
              "PRO-STAT RENAL CARE": "PRO-STAT RENAL CARE",
              "PRO-STAT SUGAR FREE": "PRO-STAT SUGAR FREE",
              "PRO-STAT/FIBER": "PRO-STAT/FIBER"
            },
            "REXALL SUNDOWN": {
              "L-CARNITINE TRIPLE STRENGTH": "L-CARNITINE TRIPLE STRENGTH"
            }
          },
          "Protein Products": {
            "21ST CENTURY HEALTHCARE": {
              "WHEY PROTEIN": "WHEY PROTEIN"
            },
            "CHAIN DRUG CONSORTIUM": {
              "WHEY PROTEIN": "WHEY PROTEIN"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS WHEY PROTEIN": "CVS WHEY PROTEIN"
            },
            "ENOVACHEM MANUFACTURING": {
              "PROSYNMINIC": "PROSYNMINIC"
            },
            "GLOBAL HEALTH INC": {
              "PROCEL": "PROCEL",
              "PROCEL 100": "PROCEL 100"
            },
            "GOOD THINGS HEALTH": {
              "CARDIOWHEY": "CARDIOWHEY"
            },
            "KRAMER-NOVIS": {
              "PRE PROTEIN": "PRE PROTEIN"
            },
            "MAGNO-HUMPHRIES LABORATORIES": {
              "PROTEIN": "PROTEIN"
            },
            "MAJOR PHARMACEUTICALS": {
              "PROTEIN": "PROTEIN"
            },
            "MASON VITAMINS": {
              "PROTEIN": "PROTEIN"
            },
            "MEDTRITION NATIONAL NUTRITION": {
              "ARGITEIN": "ARGITEIN",
              "PROSOURCE": "PROSOURCE",
              "PROSOURCE GELATEIN20": "PROSOURCE GELATEIN20",
              "RENAMENT": "RENAMENT"
            },
            "NAT-RUL HEALTH PRODUCTS": {
              "WELLNESS PROTEIN SHAKE": "WELLNESS PROTEIN SHAKE"
            },
            "NATIONAL VITAMIN": {
              "PROTEIN": "PROTEIN",
              "WHEY PROTEIN CONCENTRATE": "WHEY PROTEIN CONCENTRATE"
            },
            "NESTLE HEALTHCARE NUTRITION": {
              "ADDITIONS FOOD ENHANCER": "ADDITIONS FOOD ENHANCER",
              "BENEPROTEIN": "BENEPROTEIN",
              "BOOST SOOTHE": "BOOST SOOTHE"
            },
            "NUTRA BALANCE": {
              "NUTRA/PRO CHOCOLATE": "NUTRA/PRO CHOCOLATE",
              "NUTRA/PRO STRAWBERRY": "NUTRA/PRO STRAWBERRY",
              "NUTRA/PRO VANILLA": "NUTRA/PRO VANILLA"
            },
            "PROSYNTHESIS LABORATORIES": {
              "UNJURY": "UNJURY"
            },
            "THE WINNING COMBINATION": {
              "NATURAL WHEY": "NATURAL WHEY"
            },
            "WALGREENS": {
              "WHEY PROTEIN": "WHEY PROTEIN"
            }
          }
        }
      },
      "OPHTHALMIC AGENTS": {
        "Artificial Tears and Lubricants": {
          "Artificial Tear Inserts": {
            "BAUSCH HEALTH": {
              "LACRISERT": "LACRISERT"
            }
          },
          "Artificial Tear Solutions": {
            "A-S MEDICATION SOLUTIONS": {
              "TEARS NATURALE II": "TEARS NATURALE II"
            },
            "ALCON VISION": {
              "GENTEAL TEARS": "GENTEAL TEARS",
              "SYSTANE CONTACTS": "SYSTANE CONTACTS"
            },
            "BAUSCH & LOMB CONS": {
              "SOOTHE HYDRATION": "SOOTHE HYDRATION",
              "SOOTHE XP": "SOOTHE XP",
              "SOOTHE XP XTRA PROTECTION": "SOOTHE XP XTRA PROTECTION"
            },
            "BLAIREX LABS": {
              "JUST TEARS EYE DROPS": "JUST TEARS EYE DROPS"
            },
            "MCKESSON SUNMARK": {
              "SM ARTIFICIAL TEARS": "SM ARTIFICIAL TEARS"
            },
            "OCUSOFT": {
              "TEARS AGAIN ADVANCED EYELID": "TEARS AGAIN ADVANCED EYELID"
            }
          },
          "Artificial Tear and Lubricant Combinations": {
            "A-S MEDICATION SOLUTIONS": {
              "GENTEAL TEARS": "GENTEAL TEARS",
              "REFRESH": "REFRESH",
              "SYSTANE ULTRA": "SYSTANE ULTRA",
              "WH PETROL-MINERAL OIL-LANOLIN": "WH PETROL-MINERAL OIL-LANOLIN"
            },
            "AACE PHARMACEUTICALS": {
              "LUBRICANT PM": "LUBRICANT PM"
            },
            "AKORN": {
              "AKWA TEARS": "AKWA TEARS"
            },
            "ALCON VISION": {
              "BION TEARS PF": "BION TEARS PF",
              "GENTEAL": "GENTEAL",
              "GENTEAL TEARS": "GENTEAL TEARS",
              "GENTEAL TEARS MODERATE PF": "GENTEAL TEARS MODERATE PF",
              "GENTEAL TEARS NIGHT-TIME": "GENTEAL TEARS NIGHT-TIME",
              "GENTEAL TEARS PF": "GENTEAL TEARS PF",
              "HYPOTEARS": "HYPOTEARS",
              "SYSTANE": "SYSTANE",
              "SYSTANE HYDRATION PF": "SYSTANE HYDRATION PF",
              "SYSTANE NIGHTTIME": "SYSTANE NIGHTTIME",
              "SYSTANE PRESERVATIVE FREE": "SYSTANE PRESERVATIVE FREE",
              "SYSTANE ULTRA": "SYSTANE ULTRA",
              "SYSTANE ULTRA HOME-AWAY PACK": "SYSTANE ULTRA HOME-AWAY PACK",
              "SYSTANE ULTRA PF": "SYSTANE ULTRA PF",
              "TEARS NATURALE FREE": "TEARS NATURALE FREE",
              "TEARS NATURALE PM": "TEARS NATURALE PM"
            },
            "ALLERGAN": {
              "REFRESH": "REFRESH",
              "REFRESH DIGITAL": "REFRESH DIGITAL",
              "REFRESH DIGITAL PF": "REFRESH DIGITAL PF",
              "REFRESH LACRI-LUBE": "REFRESH LACRI-LUBE",
              "REFRESH OPTIVE": "REFRESH OPTIVE",
              "REFRESH OPTIVE ADVANCED": "REFRESH OPTIVE ADVANCED",
              "REFRESH OPTIVE ADVANCED PF": "REFRESH OPTIVE ADVANCED PF",
              "REFRESH OPTIVE MEGA-3": "REFRESH OPTIVE MEGA-3",
              "REFRESH OPTIVE PF": "REFRESH OPTIVE PF",
              "REFRESH P.M.": "REFRESH P.M.",
              "REFRESH RELIEVA": "REFRESH RELIEVA",
              "REFRESH RELIEVA PF": "REFRESH RELIEVA PF",
              "REFRESH REPAIR": "REFRESH REPAIR"
            },
            "ALTAIRE": {
              "ALTALUBE": "ALTALUBE",
              "FOR STY RELIEF": "FOR STY RELIEF",
              "ULTRA FRESH PM": "ULTRA FRESH PM"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP EYE DROPS": "GNP EYE DROPS",
              "GNP EYE DROPS LONG LASTING": "GNP EYE DROPS LONG LASTING",
              "GNP LUBRICANT EYE DROPS": "GNP LUBRICANT EYE DROPS",
              "GNP LUBRICANT PM": "GNP LUBRICANT PM",
              "GNP ULTRA LUBRICANT EYE DROPS": "GNP ULTRA LUBRICANT EYE DROPS"
            },
            "AUTOMATIC LIQUID PACKAGING": {
              "GOODSENSE ARTIFICIAL TEARS": "GOODSENSE ARTIFICIAL TEARS"
            },
            "BAUSCH & LOMB CONS": {
              "MOISTURE EYES": "MOISTURE EYES",
              "SOOTHE": "SOOTHE",
              "SOOTHE NIGHT TIME": "SOOTHE NIGHT TIME"
            },
            "BAUSCH HEALTH": {
              "SOOTHE NIGHTTIME": "SOOTHE NIGHTTIME"
            },
            "BERGEN BRUNSWIG": {
              "GNP ARTIFICIAL TEARS": "GNP ARTIFICIAL TEARS"
            },
            "CHAIN DRUG CONSORTIUM": {
              "ARTIFICIAL TEARS": "ARTIFICIAL TEARS",
              "LUBRICANT EYE DROPS": "LUBRICANT EYE DROPS",
              "ULTRA LUBRICANT EYE DROPS": "ULTRA LUBRICANT EYE DROPS"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC ARTIFICIAL TEARS": "QC ARTIFICIAL TEARS"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS ARTIFICIAL TEARS": "CVS ARTIFICIAL TEARS",
              "CVS DRY EYE RELIEF": "CVS DRY EYE RELIEF",
              "CVS DRY-EYE RELIEF NIGHTTIME": "CVS DRY-EYE RELIEF NIGHTTIME",
              "CVS EYE LUBRICANT": "CVS EYE LUBRICANT",
              "CVS EYE LUBRICANT NIGHTTIME": "CVS EYE LUBRICANT NIGHTTIME",
              "CVS LUBRICANT DROPS": "CVS LUBRICANT DROPS",
              "CVS LUBRICANT DROPS FAST ACT": "CVS LUBRICANT DROPS FAST ACT",
              "CVS LUBRICANT DROPS LONG LAST": "CVS LUBRICANT DROPS LONG LAST",
              "CVS LUBRICANT EYE DROPS": "CVS LUBRICANT EYE DROPS",
              "CVS LUBRICANT EYE DROPS (PF)": "CVS LUBRICANT EYE DROPS (PF)",
              "CVS LUBRICATING EYE/OVERNIGHT": "CVS LUBRICATING EYE/OVERNIGHT",
              "CVS LUBRICATING/DRY EYE": "CVS LUBRICATING/DRY EYE",
              "CVS NATURAL TEARS": "CVS NATURAL TEARS",
              "CVS NATURAL TEARS PF": "CVS NATURAL TEARS PF",
              "CVS NIGHTTIME DRY-EYE RELIEF": "CVS NIGHTTIME DRY-EYE RELIEF"
            },
            "EYEVANCE PHARMACEUTICALS": {
              "FRESHKOTE PF": "FRESHKOTE PF"
            },
            "FERA PHARMACEUTICALS": {
              "STERILUBE": "STERILUBE"
            },
            "FOCUS LABORATORIES": {
              "FRESHKOTE": "FRESHKOTE",
              "FRESHKOTE PF": "FRESHKOTE PF"
            },
            "GEISS DESTIN & DUNN": {
              "GOODSENSE ARTIFICIAL TEARS": "GOODSENSE ARTIFICIAL TEARS",
              "GOODSENSE LUBRICANT EYE DROPS": "GOODSENSE LUBRICANT EYE DROPS",
              "GOODSENSE ULTRA LUBRICANT DROP": "GOODSENSE ULTRA LUBRICANT DROP"
            },
            "GERI-CARE": {
              "ARTIFICIAL TEARS": "ARTIFICIAL TEARS"
            },
            "HY-VEE": {
              "ARTIFICIAL TEARS": "ARTIFICIAL TEARS"
            },
            "INSIGHT PHARMACEUTICALS": {
              "STYE": "STYE"
            },
            "JOHNSON&JOHNSON HEALTHCARE": {
              "VISINE DRY EYE": "VISINE DRY EYE",
              "VISINE TEARS": "VISINE TEARS",
              "VISINE TIRED EYE RELIEF": "VISINE TIRED EYE RELIEF"
            },
            "LEADER BRAND PRODUCTS": {
              "ARTIFICIAL TEARS": "ARTIFICIAL TEARS",
              "DRY EYE RELIEF DROPS": "DRY EYE RELIEF DROPS",
              "LUBRICANT EYE DROPS": "LUBRICANT EYE DROPS",
              "LUBRICANT EYE DROPS (PF)": "LUBRICANT EYE DROPS (PF)",
              "LUBRICANT EYE NIGHTTIME": "LUBRICANT EYE NIGHTTIME",
              "LUBRICATING EYE DROPS": "LUBRICATING EYE DROPS",
              "TEARS PURE": "TEARS PURE",
              "ULTRA LUBRICATING EYE DROPS": "ULTRA LUBRICATING EYE DROPS"
            },
            "MAJOR PHARMACEUTICALS": {
              "LUBRIFRESH P.M.": "LUBRIFRESH P.M.",
              "NATURAL BALANCE TEARS": "NATURAL BALANCE TEARS"
            },
            "MCKESSON": {
              "HM ARTIFICIAL TEARS": "HM ARTIFICIAL TEARS",
              "HM DRY EYE RELIEF": "HM DRY EYE RELIEF",
              "HM LUBRICATING TEARS": "HM LUBRICATING TEARS"
            },
            "MCKESSON SUNMARK": {
              "SM DRY EYE RELIEF": "SM DRY EYE RELIEF",
              "SM LUBRICANT EYE DROPS": "SM LUBRICANT EYE DROPS",
              "SM LUBRICATING TEARS": "SM LUBRICATING TEARS"
            },
            "MEDTECH": {
              "ARTIFICIAL TEARS": "ARTIFICIAL TEARS",
              "CLEAR EYES ALL SEASONS": "CLEAR EYES ALL SEASONS",
              "CLEAR EYES FOR DRY EYES": "CLEAR EYES FOR DRY EYES",
              "CLEAR EYES NATURAL TEARS": "CLEAR EYES NATURAL TEARS",
              "MURINE TEARS FOR DRY EYES": "MURINE TEARS FOR DRY EYES"
            },
            "NOVARTIS OPTHALMICS": {
              "HYPOTEARS": "HYPOTEARS"
            },
            "OCUSOFT": {
              "RETAINE MGD": "RETAINE MGD",
              "RETAINE PM": "RETAINE PM",
              "TEARS AGAIN": "TEARS AGAIN"
            },
            "OPTICS LABORATORY": {
              "OPTICS MINI DROPS": "OPTICS MINI DROPS"
            },
            "PERRIGO": {
              "PURALUBE": "PURALUBE"
            },
            "PREFERRED PHARMACEUTICALS": {
              "ARTIFICIAL TEARS": "ARTIFICIAL TEARS",
              "LUBRIFRESH P.M.": "LUBRIFRESH P.M.",
              "NATURAL BALANCE TEARS": "NATURAL BALANCE TEARS"
            },
            "PROFICIENT RX": {
              "ARTIFICIAL TEARS": "ARTIFICIAL TEARS"
            },
            "PUBLIX SUPER MARKETS INC.": {
              "PX ARTIFICIAL TEARS": "PX ARTIFICIAL TEARS"
            },
            "RELIABLE 1 LABS": {
              "LUBRICANT EYE PM": "LUBRICANT EYE PM"
            },
            "RITE AID CORPORATION": {
              "RA ARTIFICIAL TEARS": "RA ARTIFICIAL TEARS",
              "RA LUBRICANT EYE": "RA LUBRICANT EYE"
            },
            "RUGBY LABORATORIES": {
              "ARTIFICIAL TEARS": "ARTIFICIAL TEARS",
              "LUBRICANT EYE DROPS": "LUBRICANT EYE DROPS",
              "LUBRICATING EYE DROPS": "LUBRICATING EYE DROPS",
              "NATURES TEARS": "NATURES TEARS",
              "ULTRA LUBRICANT EYE DROPS": "ULTRA LUBRICANT EYE DROPS"
            },
            "TARGET": {
              "TGT LUBRICANT EYE DROPS": "TGT LUBRICANT EYE DROPS",
              "TGT LUBRICANT EYE NIGHTTIME": "TGT LUBRICANT EYE NIGHTTIME"
            },
            "THE DRY EYE COMPANY": {
              "DAKRINA": "DAKRINA",
              "DWELLE": "DWELLE"
            },
            "TOPCO": {
              "LUBRICANT EYE DROPS (PF)": "LUBRICANT EYE DROPS (PF)"
            },
            "WAL-MART": {
              "EQ ARTIFICIAL TEARS": "EQ ARTIFICIAL TEARS",
              "EQ LUBRICANT EYE DROPS": "EQ LUBRICANT EYE DROPS",
              "EQ LUBRICATING EYE DROPS": "EQ LUBRICATING EYE DROPS",
              "EQ RESTORE PM": "EQ RESTORE PM"
            },
            "WALGREENS": {
              "ARTIFICIAL TEARS": "ARTIFICIAL TEARS",
              "ARTIFICIAL TEARS PF": "ARTIFICIAL TEARS PF",
              "EYE LUBRICANT": "EYE LUBRICANT",
              "LUBRICANT DROPS/DUAL-ACTION": "LUBRICANT DROPS/DUAL-ACTION",
              "LUBRICANT EYE": "LUBRICANT EYE",
              "LUBRICANT EYE DROPS": "LUBRICANT EYE DROPS",
              "LUBRICANT EYE FAST ACTING": "LUBRICANT EYE FAST ACTING",
              "LUBRICANT EYE NIGHTTIME": "LUBRICANT EYE NIGHTTIME"
            }
          },
          "Artificial Tears and Lubricants": {
            "A-S MEDICATION SOLUTIONS": {
              "ARTIFICIAL TEARS": "ARTIFICIAL TEARS",
              "BLINK TEARS": "BLINK TEARS",
              "POLYVINYL ALCOHOL": "POLYVINYL ALCOHOL",
              "SYSTANE BALANCE": "SYSTANE BALANCE"
            },
            "ADVANCED VISION RESEARCH": {
              "THERATEARS NIGHTTIME": "THERATEARS NIGHTTIME"
            },
            "AIDAREX PHARMACEUTICALS": {
              "NATURAL BALANCE TEARS": "NATURAL BALANCE TEARS",
              "POLYVINYL ALCOHOL": "POLYVINYL ALCOHOL"
            },
            "AKORN": {
              "ARTIFICIAL TEARS": "ARTIFICIAL TEARS"
            },
            "AKORN CONSUMER": {
              "THERATEARS": "THERATEARS",
              "THERATEARS PF": "THERATEARS PF"
            },
            "ALCON VISION": {
              "GENTEAL MILD": "GENTEAL MILD",
              "GENTEAL MILD TO MODERATE": "GENTEAL MILD TO MODERATE",
              "GENTEAL SEVERE": "GENTEAL SEVERE",
              "ISOPTO TEARS": "ISOPTO TEARS",
              "SYSTANE BALANCE": "SYSTANE BALANCE",
              "SYSTANE COMPLETE": "SYSTANE COMPLETE",
              "SYSTANE OVERNIGHT THERAPY": "SYSTANE OVERNIGHT THERAPY"
            },
            "ALLERGAN": {
              "REFRESH CELLUVISC": "REFRESH CELLUVISC",
              "REFRESH LIQUIGEL": "REFRESH LIQUIGEL",
              "REFRESH PLUS": "REFRESH PLUS",
              "REFRESH TEARS": "REFRESH TEARS"
            },
            "ALTAIRE": {
              "POLYVINYL ALCOHOL": "POLYVINYL ALCOHOL",
              "PURE & GENTLE LUBRICANT": "PURE & GENTLE LUBRICANT",
              "STERILE LUBRICANT": "STERILE LUBRICANT",
              "ULTRA FRESH": "ULTRA FRESH"
            },
            "AMERICAN SALES COMPANY": {
              "REVIVE TEARS": "REVIVE TEARS"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP EYE DROPS": "GNP EYE DROPS",
              "GNP LUBRICANT EYE DROPS": "GNP LUBRICANT EYE DROPS",
              "GNP LUBRICATING PLUS EYE DROPS": "GNP LUBRICATING PLUS EYE DROPS"
            },
            "ARU PHARMA": {
              "ARTIFICIAL TEARS": "ARTIFICIAL TEARS"
            },
            "BAUSCH & LOMB CONS": {
              "COMPUTER EYE DROPS": "COMPUTER EYE DROPS"
            },
            "CHAIN DRUG CONSORTIUM": {
              "ARTIFICIAL TEARS": "ARTIFICIAL TEARS",
              "LUBRICANT DROPS": "LUBRICANT DROPS",
              "MOISTURIZING LUBRICANT EYE": "MOISTURIZING LUBRICANT EYE",
              "PURE & GENTLE LUBRICANT": "PURE & GENTLE LUBRICANT",
              "ULTRA FRESH": "ULTRA FRESH"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS LUBRICANT DROPS": "CVS LUBRICANT DROPS",
              "CVS LUBRICANT EYE DROPS": "CVS LUBRICANT EYE DROPS",
              "CVS LUBRICANT EYE DROPS (PF)": "CVS LUBRICANT EYE DROPS (PF)",
              "CVS LUBRICANT EYE DROPS PF": "CVS LUBRICANT EYE DROPS PF"
            },
            "DAKOTA LABORATORIES": {
              "VIVA DROPS": "VIVA DROPS"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "ARTIFICIAL TEARS": "ARTIFICIAL TEARS"
            },
            "HUB PHARMACEUTICALS": {
              "GONIOVISC": "GONIOVISC"
            },
            "J & J CONSUMER PRODUCTS": {
              "VISINE DRY EYE RELIEF": "VISINE DRY EYE RELIEF"
            },
            "J & J SURGICAL VISION": {
              "BLINK TEARS": "BLINK TEARS"
            },
            "JOHNSON&JOHNSON HEALTHCARE": {
              "VISINE DRY EYE RELIEF": "VISINE DRY EYE RELIEF"
            },
            "LEADER BRAND PRODUCTS": {
              "LUBRICANT EYE DROPS PF": "LUBRICANT EYE DROPS PF",
              "LUBRICATING PLUS EYE DROPS": "LUBRICATING PLUS EYE DROPS"
            },
            "MAJOR PHARMACEUTICALS": {
              "LIQUITEARS": "LIQUITEARS",
              "LUBRICATING PLUS EYE DROPS": "LUBRICATING PLUS EYE DROPS",
              "NATURAL BALANCE TEARS": "NATURAL BALANCE TEARS"
            },
            "MCKESSON": {
              "HM LUBRICATING PLUS": "HM LUBRICATING PLUS"
            },
            "MCKESSON SUNMARK": {
              "SM LUBRICATING PLUS": "SM LUBRICATING PLUS"
            },
            "MEDTECH": {
              "CLEAR EYES PURE RELIEF PF": "CLEAR EYES PURE RELIEF PF"
            },
            "NUCARE PHARMACEUTICALS": {
              "ARTIFICIAL TEARS": "ARTIFICIAL TEARS"
            },
            "OCULUS SURGICAL": {
              "IMPROVUE": "IMPROVUE"
            },
            "OCUSOFT": {
              "RETAINE CMC": "RETAINE CMC",
              "RETAINE HPMC": "RETAINE HPMC",
              "TEARS AGAIN": "TEARS AGAIN"
            },
            "PERRIGO": {
              "GOODSENSE LUBRICATING EYE DROP": "GOODSENSE LUBRICATING EYE DROP"
            },
            "PREFERRED PHARMACEUTICALS": {
              "ARTIFICIAL TEARS": "ARTIFICIAL TEARS"
            },
            "PROFICIENT RX": {
              "EYE DROPS": "EYE DROPS",
              "LIQUITEARS": "LIQUITEARS"
            },
            "RITE AID CORPORATION": {
              "RA LUBRICANT EYE DROPS": "RA LUBRICANT EYE DROPS"
            },
            "RUGBY LABORATORIES": {
              "ARTIFICIAL TEARS": "ARTIFICIAL TEARS",
              "NATURES TEARS": "NATURES TEARS"
            },
            "SAGE ETHNOGRAPHIC RESEARCH": {
              "BIOLLE GEL TEARS": "BIOLLE GEL TEARS",
              "BIOLLE TEARS": "BIOLLE TEARS"
            },
            "TARGET": {
              "TGT LUBRICANT EYE DROPS": "TGT LUBRICANT EYE DROPS"
            },
            "THE DRY EYE COMPANY": {
              "NUTRATEAR": "NUTRATEAR"
            },
            "WAL-MART": {
              "EQ GENTLE LUBRICANT": "EQ GENTLE LUBRICANT",
              "EQ RESTORE PLUS LUBRICANT EYE": "EQ RESTORE PLUS LUBRICANT EYE",
              "EQ RESTORE TEARS": "EQ RESTORE TEARS",
              "EQ REVIVE PLUS": "EQ REVIVE PLUS",
              "EQ REVIVE PLUS LUBRICANT EYE": "EQ REVIVE PLUS LUBRICANT EYE"
            },
            "WALGREENS": {
              "LUBRICANT EYE DROPS": "LUBRICANT EYE DROPS",
              "LUBRICANT EYE DROPS PF": "LUBRICANT EYE DROPS PF",
              "MOISTURIZING LUBRICANT EYE": "MOISTURIZING LUBRICANT EYE"
            }
          },
          "Gonioscopic Solutions": {
            "AKORN": {
              "GONAK": "GONAK"
            },
            "ALTAIRE": {
              "GONIOTAIRE": "GONIOTAIRE"
            },
            "HUB PHARMACEUTICALS": {
              "GONIOVISC": "GONIOVISC"
            },
            "NOVARTIS OPTHALMICS": {
              "GONIOSOL": "GONIOSOL"
            },
            "OCUSOFT": {
              "GONIOSOFT": "GONIOSOFT"
            }
          }
        },
        "Beta-blockers - Ophthalmic": {
          "Beta-blockers - Ophthalmic": {
            "A-S MEDICATION SOLUTIONS": {
              "TIMOLOL MALEATE": "TIMOLOL MALEATE"
            },
            "AIDAREX PHARMACEUTICALS": {
              "TIMOLOL MALEATE": "TIMOLOL MALEATE"
            },
            "AKORN": {
              "BETAXOLOL HCL": "BETAXOLOL HCL",
              "BETIMOL": "BETIMOL",
              "TIMOLOL MALEATE": "TIMOLOL MALEATE"
            },
            "ALLERGAN": {
              "BETAGAN": "BETAGAN"
            },
            "APOTEX": {
              "TIMOLOL MALEATE": "TIMOLOL MALEATE"
            },
            "BAUSCH HEALTH": {
              "CARTEOLOL HCL": "CARTEOLOL HCL",
              "ISTALOL": "ISTALOL",
              "LEVOBUNOLOL HCL": "LEVOBUNOLOL HCL",
              "TIMOLOL MALEATE": "TIMOLOL MALEATE",
              "TIMOPTIC": "TIMOPTIC",
              "TIMOPTIC OCUDOSE": "TIMOPTIC OCUDOSE",
              "TIMOPTIC-XE": "TIMOPTIC-XE"
            },
            "GREENSTONE": {
              "LEVOBUNOLOL HCL": "LEVOBUNOLOL HCL",
              "TIMOLOL MALEATE": "TIMOLOL MALEATE"
            },
            "HI-TECH": {
              "TIMOLOL MALEATE": "TIMOLOL MALEATE"
            },
            "NOVARTIS": {
              "BETOPTIC-S": "BETOPTIC-S"
            },
            "OCEANSIDE PHARMACEUTICALS": {
              "TIMOLOL MALEATE": "TIMOLOL MALEATE"
            },
            "PRESCRIPTION SOLUTIONS, INC.": {
              "LEVOBUNOLOL HCL": "LEVOBUNOLOL HCL"
            },
            "RISING PHARMACEUTICALS": {
              "TIMOLOL MALEATE": "TIMOLOL MALEATE"
            },
            "SANDOZ": {
              "BETAXOLOL HCL": "BETAXOLOL HCL",
              "CARTEOLOL HCL": "CARTEOLOL HCL",
              "LEVOBUNOLOL HCL": "LEVOBUNOLOL HCL",
              "METIPRANOLOL": "METIPRANOLOL",
              "TIMOLOL MALEATE": "TIMOLOL MALEATE"
            }
          },
          "Beta-blockers - Ophthalmic Combinations": {
            "A-S MEDICATION SOLUTIONS": {
              "COMBIGAN": "COMBIGAN",
              "COSOPT PF": "COSOPT PF",
              "DORZOLAMIDE HCL-TIMOLOL MAL": "DORZOLAMIDE HCL-TIMOLOL MAL"
            },
            "ACTAVIS PHARMA": {
              "DORZOLAMIDE HCL-TIMOLOL MAL": "DORZOLAMIDE HCL-TIMOLOL MAL"
            },
            "AKORN": {
              "COSOPT": "COSOPT",
              "COSOPT PF": "COSOPT PF",
              "DORZOLAMIDE HCL-TIMOLOL MAL PF": "DORZOLAMIDE HCL-TIMOLOL MAL PF"
            },
            "ALLERGAN": {
              "COMBIGAN": "COMBIGAN"
            },
            "AUROBINDO PHARMA": {
              "DORZOLAMIDE HCL-TIMOLOL MAL PF": "DORZOLAMIDE HCL-TIMOLOL MAL PF"
            },
            "BAUSCH HEALTH": {
              "DORZOLAMIDE HCL-TIMOLOL MAL": "DORZOLAMIDE HCL-TIMOLOL MAL"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "DORZOLAMIDE HCL-TIMOLOL MAL": "DORZOLAMIDE HCL-TIMOLOL MAL"
            },
            "HI-TECH": {
              "DORZOLAMIDE HCL-TIMOLOL MAL": "DORZOLAMIDE HCL-TIMOLOL MAL"
            },
            "IMPRIMIS NJOF": {
              "DORZOLAMIDE HCL-TIMOLOL MAL": "DORZOLAMIDE HCL-TIMOLOL MAL",
              "LATANOPROST-TIMOLOL MALEATE": "LATANOPROST-TIMOLOL MALEATE",
              "TIMOLOL-BRIMON-DORZOL-LATANOPR": "TIMOLOL-BRIMON-DORZOL-LATANOPR",
              "TIMOLOL-BRIMONIDINE-DORZOLAMID": "TIMOLOL-BRIMONIDINE-DORZOLAMID",
              "TIMOLOL-DORZOLAMID-LATANOPROST": "TIMOLOL-DORZOLAMID-LATANOPROST"
            },
            "MERCK SHARP & DOHME": {
              "COSOPT PF": "COSOPT PF"
            },
            "MICRO LABORATORIES": {
              "DORZOLAMIDE HCL-TIMOLOL MAL": "DORZOLAMIDE HCL-TIMOLOL MAL"
            },
            "PRASCO LABORATORIES": {
              "DORZOLAMIDE HCL-TIMOLOL MAL": "DORZOLAMIDE HCL-TIMOLOL MAL"
            },
            "QUALITY CARE": {
              "COSOPT": "COSOPT"
            },
            "SANDOZ": {
              "DORZOLAMIDE HCL-TIMOLOL MAL": "DORZOLAMIDE HCL-TIMOLOL MAL"
            },
            "SOMERSET THERAPEUTICS": {
              "DORZOLAMIDE HCL-TIMOLOL MAL": "DORZOLAMIDE HCL-TIMOLOL MAL"
            }
          }
        },
        "Contact Lens Solutions": {
          "Hard Lens Products": {
            "LOBOB": {
              "LOBOB HARD CONTACT LENS CLEAN": "LOBOB HARD CONTACT LENS CLEAN",
              "LOBOB HARD CONTACT LENS WET": "LOBOB HARD CONTACT LENS WET",
              "LOBOB HARD LENS SOAKING": "LOBOB HARD LENS SOAKING"
            }
          },
          "Hard/Soft/Gas Permeable Products": {
            "ALCON VISION": {
              "CLERZ PLUS": "CLERZ PLUS"
            }
          },
          "Oxygen Permeable Lens Products": {
            "BAUSCH & LOMB CONS": {
              "BOSTON ADVANCE CLEANER": "BOSTON ADVANCE CLEANER",
              "BOSTON ADVANCE CONDITIONING": "BOSTON ADVANCE CONDITIONING",
              "BOSTON CLEANER": "BOSTON CLEANER",
              "BOSTON CONDITIONING": "BOSTON CONDITIONING",
              "BOSTON ONE STEP CLEANER": "BOSTON ONE STEP CLEANER",
              "BOSTON REWETTING": "BOSTON REWETTING",
              "BOSTON SIMPLUS": "BOSTON SIMPLUS",
              "RENU REWETTING DROPS": "RENU REWETTING DROPS"
            },
            "LOBOB": {
              "LOBOB GAS PERM WETTING/REWET": "LOBOB GAS PERM WETTING/REWET",
              "LOBOB GAS PERM/CLEAN/DISINFECT": "LOBOB GAS PERM/CLEAN/DISINFECT",
              "OPTIMUM CLEAN/DISINFECT": "OPTIMUM CLEAN/DISINFECT",
              "OPTIMUM EXTRA STRENGTH RGP": "OPTIMUM EXTRA STRENGTH RGP",
              "OPTIMUM WETTING/REWETTING DROP": "OPTIMUM WETTING/REWETTING DROP"
            },
            "WAL-MART": {
              "EQ CONTACT LENS CLEANER": "EQ CONTACT LENS CLEANER"
            }
          },
          "Soft Lens Products": {
            "ABBOTT MEDICAL OPTICS": {
              "REVITALENS OCUTEC": "REVITALENS OCUTEC"
            },
            "ALCON VISION": {
              "AOSEPT": "AOSEPT",
              "AQUIFY COMFORT DROPS": "AQUIFY COMFORT DROPS",
              "AQUIFY MULTI-PURPOSE": "AQUIFY MULTI-PURPOSE",
              "CLEAR CARE": "CLEAR CARE",
              "CLEAR CARE PLUS/HYDRAGLYDE": "CLEAR CARE PLUS/HYDRAGLYDE",
              "OPTI-CLEAN II CLEANER": "OPTI-CLEAN II CLEANER",
              "OPTI-FREE DAILY CLEANER": "OPTI-FREE DAILY CLEANER",
              "OPTI-FREE EXPRESS": "OPTI-FREE EXPRESS",
              "OPTI-FREE EXPRESS REWETTING": "OPTI-FREE EXPRESS REWETTING",
              "OPTI-FREE FOR DISINFECTION": "OPTI-FREE FOR DISINFECTION",
              "OPTI-FREE PUREMOIST": "OPTI-FREE PUREMOIST",
              "OPTI-FREE PUREMOIST REWETTING": "OPTI-FREE PUREMOIST REWETTING",
              "OPTI-FREE REPLENISH": "OPTI-FREE REPLENISH",
              "OPTI-FREE REPLENISH PACK": "OPTI-FREE REPLENISH PACK",
              "OPTI-FREE REPLENISH REWETTING": "OPTI-FREE REPLENISH REWETTING",
              "OPTI-FREE SUPRA CLENS": "OPTI-FREE SUPRA CLENS",
              "OPTI-ONE": "OPTI-ONE",
              "OPTI-ONE REWETTING": "OPTI-ONE REWETTING",
              "PLIAGEL": "PLIAGEL",
              "UNISOL 4": "UNISOL 4"
            },
            "ALLERGAN": {
              "REFRESH CONTACTS DROPS": "REFRESH CONTACTS DROPS"
            },
            "BAUSCH & LOMB CONS": {
              "B&L SENSITIVE EYES": "B&L SENSITIVE EYES",
              "B&L SENSITIVE EYES DAILY CLEAN": "B&L SENSITIVE EYES DAILY CLEAN",
              "B&L SENSITIVE EYES SALINE": "B&L SENSITIVE EYES SALINE",
              "BIOTRUE": "BIOTRUE",
              "RENU 1 STEP ENZYMATIC CLEANER": "RENU 1 STEP ENZYMATIC CLEANER",
              "RENU DISINFECTING": "RENU DISINFECTING",
              "RENU MULTIPLUS": "RENU MULTIPLUS",
              "RENU MULTIPLUS LUB/REWETTING": "RENU MULTIPLUS LUB/REWETTING",
              "RENU SALINE": "RENU SALINE",
              "SENSITIVE EYES ENZYME CLEANER": "SENSITIVE EYES ENZYME CLEANER",
              "SENSITIVE EYES PLUS SALINE": "SENSITIVE EYES PLUS SALINE",
              "SENSITIVE EYES SALINE": "SENSITIVE EYES SALINE",
              "STERILE DAILY CLEANER": "STERILE DAILY CLEANER"
            },
            "BAUSCH HEALTH": {
              "PEROXICLEAR": "PEROXICLEAR"
            },
            "BERGEN BRUNSWIG": {
              "GNP MULTI-PURPOSE": "GNP MULTI-PURPOSE"
            },
            "BLAIREX LABS": {
              "MULTI-PURPOSE SOLUTION": "MULTI-PURPOSE SOLUTION",
              "STERILE PRESERVED SALINE": "STERILE PRESERVED SALINE",
              "STERILE SALINE": "STERILE SALINE"
            },
            "CHAIN DRUG CONSORTIUM": {
              "LUBRICATING & REWETTING": "LUBRICATING & REWETTING",
              "MULTI-PURPOSE SOLUTION": "MULTI-PURPOSE SOLUTION",
              "SALINE SENSITIVE EYES": "SALINE SENSITIVE EYES"
            },
            "CIBA VISION CARE": {
              "AO-SEPT DISINFECTION/NEUTRAL": "AO-SEPT DISINFECTION/NEUTRAL",
              "CIBA VISION SALINE": "CIBA VISION SALINE",
              "CLEANER": "CLEANER",
              "MIRAFLOW": "MIRAFLOW",
              "PURE EYES 1 CLEANER/RINSE": "PURE EYES 1 CLEANER/RINSE",
              "PURE EYES 2 DISINFECTANT/SOAK": "PURE EYES 2 DISINFECTANT/SOAK",
              "QUICKCARE FINISHING": "QUICKCARE FINISHING",
              "QUICKCARE STARTING": "QUICKCARE STARTING",
              "SOFTWEAR SALINE": "SOFTWEAR SALINE",
              "SOLOCARE": "SOLOCARE",
              "UNIZYME ENZYMATIC CLEANER": "UNIZYME ENZYMATIC CLEANER"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS CONTACT LENS RELIEF/REWET": "CVS CONTACT LENS RELIEF/REWET",
              "CVS MULTI-PURPOSE": "CVS MULTI-PURPOSE",
              "CVS MULTI-PURPOSE NO-RUB": "CVS MULTI-PURPOSE NO-RUB",
              "CVS SALINE": "CVS SALINE"
            },
            "EQUALINE": {
              "EQL CLEAN/DISINFECT LENS CARE": "EQL CLEAN/DISINFECT LENS CARE",
              "EQL MULTI-PURPOSE": "EQL MULTI-PURPOSE",
              "EQL MULTI-PURPOSE DISINFECTING": "EQL MULTI-PURPOSE DISINFECTING"
            },
            "GEISS DESTIN & DUNN": {
              "GOODSENSE MULTI-PURPOSE SOLN": "GOODSENSE MULTI-PURPOSE SOLN"
            },
            "JOHNSON&JOHNSON HEALTHCARE": {
              "VISINE FOR CONTACTS": "VISINE FOR CONTACTS"
            },
            "LEADER BRAND PRODUCTS": {
              "CLEANING & DISINFECTING LENS": "CLEANING & DISINFECTING LENS",
              "LUBRICATING/REWETTING": "LUBRICATING/REWETTING",
              "MULTI-PURPOSE SOLUTION": "MULTI-PURPOSE SOLUTION"
            },
            "LOBOB": {
              "LOBOB SOFT LENS CLEANER": "LOBOB SOFT LENS CLEANER",
              "RAININ LENS GUIDE": "RAININ LENS GUIDE"
            },
            "MCKESSON": {
              "HM MULTI-PURPOSE NO RUB": "HM MULTI-PURPOSE NO RUB"
            },
            "MCKESSON SUNMARK": {
              "SM MULTI-PURPOSE": "SM MULTI-PURPOSE",
              "SM SALINE SOLUTION": "SM SALINE SOLUTION"
            },
            "MEDICINE SHOPPE": {
              "SALINE": "SALINE"
            },
            "MEDTECH": {
              "CLEAR EYES CONTACT LENS RELIEF": "CLEAR EYES CONTACT LENS RELIEF"
            },
            "RITE AID CORPORATION": {
              "RA CLEANING/DISINFECTING LENS": "RA CLEANING/DISINFECTING LENS",
              "RA MULTI-PURPOSE NO RUB": "RA MULTI-PURPOSE NO RUB",
              "RA MULTI-PURPOSE SOLUTION": "RA MULTI-PURPOSE SOLUTION",
              "RA REWETTING DROPS": "RA REWETTING DROPS",
              "RA SALINE SOLUTION": "RA SALINE SOLUTION"
            },
            "SELECT BRAND": {
              "SB MULTI-PURPOSE": "SB MULTI-PURPOSE",
              "SB SALINE SENSITIVE": "SB SALINE SENSITIVE"
            },
            "WAL-MART": {
              "EQ HYDROGEN PEROXIDE LENS CARE": "EQ HYDROGEN PEROXIDE LENS CARE",
              "EQ LUBRICATING/REWETTING DROPS": "EQ LUBRICATING/REWETTING DROPS",
              "EQ MULTI-PURPOSE SOLUTION": "EQ MULTI-PURPOSE SOLUTION",
              "EQ SALINE SOLUTION/SENSITIVE": "EQ SALINE SOLUTION/SENSITIVE"
            },
            "WALGREENS": {
              "DAILY CLEANER": "DAILY CLEANER",
              "MULTI-PURPOSE NO-RUB": "MULTI-PURPOSE NO-RUB",
              "MULTI-PURPOSE SOLUTION": "MULTI-PURPOSE SOLUTION",
              "REWETTING DROPS": "REWETTING DROPS",
              "SALINE": "SALINE"
            }
          }
        },
        "Cycloplegic Mydriatics": {
          "Cycloplegic Mydriatic Combinations": {
            "ALCON SURGICAL": {
              "CYCLOMYDRIL": "CYCLOMYDRIL"
            },
            "FAGRON COMPOUNDING SERVICES": {
              "TROPIC-CYCLOP-PE-KETO-PROPAR": "TROPIC-CYCLOP-PE-KETO-PROPAR",
              "TROPIC-CYCLOPENT-PE-KETOROLAC": "TROPIC-CYCLOPENT-PE-KETOROLAC",
              "TROPICAMIDE-CYCLOPENTOLATE-PE": "TROPICAMIDE-CYCLOPENTOLATE-PE"
            },
            "IMPRIMIS NJOF": {
              "TROPIC-PROPARACA-PE-KETOROLAC": "TROPIC-PROPARACA-PE-KETOROLAC",
              "TROPICAMIDE-CYCLOPENTOLATE-PE": "TROPICAMIDE-CYCLOPENTOLATE-PE"
            },
            "LEITER'S ENTERPRISES": {
              "TROPICAMIDE-CYCLOPENTOLATE-PE": "TROPICAMIDE-CYCLOPENTOLATE-PE"
            },
            "LEITERS HEALTH": {
              "TROPICAMIDE-CYCLOPENTOLATE-PE": "TROPICAMIDE-CYCLOPENTOLATE-PE",
              "TROPICAMIDE-PHENYLEPHRINE": "TROPICAMIDE-PHENYLEPHRINE"
            }
          },
          "Cycloplegic Mydriatics": {
            "A-S MEDICATION SOLUTIONS": {
              "ATROPINE SULFATE": "ATROPINE SULFATE",
              "CYCLOGYL": "CYCLOGYL",
              "CYCLOPENTOLATE HCL": "CYCLOPENTOLATE HCL",
              "ISOPTO HOMATROPINE": "ISOPTO HOMATROPINE",
              "PHENYLEPHRINE HCL": "PHENYLEPHRINE HCL",
              "TROPICAMIDE": "TROPICAMIDE"
            },
            "AKORN": {
              "ATROPINE SULFATE": "ATROPINE SULFATE",
              "CYCLOPENTOLATE HCL": "CYCLOPENTOLATE HCL",
              "PHENYLEPHRINE HCL": "PHENYLEPHRINE HCL",
              "TROPICAMIDE": "TROPICAMIDE"
            },
            "ALCON SURGICAL": {
              "CYCLOGYL": "CYCLOGYL",
              "ISOPTO ATROPINE": "ISOPTO ATROPINE",
              "MYDRIACYL": "MYDRIACYL"
            },
            "ALCON VISION": {
              "MYDRIACYL": "MYDRIACYL"
            },
            "ALTAIRE": {
              "ALTAFRIN": "ALTAFRIN",
              "HOMATROPAIRE": "HOMATROPAIRE"
            },
            "BAUSCH HEALTH": {
              "ATROPINE SULFATE": "ATROPINE SULFATE",
              "CYCLOPENTOLATE HCL": "CYCLOPENTOLATE HCL",
              "PHENYLEPHRINE HCL": "PHENYLEPHRINE HCL",
              "TROPICAMIDE": "TROPICAMIDE"
            },
            "FAGRON": {
              "TROPICAMIDE": "TROPICAMIDE"
            },
            "FREEDOM PHARMACEUTICALS": {
              "TROPICAMIDE": "TROPICAMIDE"
            },
            "HENRY SCHEIN": {
              "TROPICAMIDE": "TROPICAMIDE"
            },
            "IMPRIMIS NJOF": {
              "ATROPINE SULFATE": "ATROPINE SULFATE"
            },
            "INVISION PHARMACEUTICALS": {
              "PHENYLEPHRINE HCL": "PHENYLEPHRINE HCL"
            },
            "LEITER'S ENTERPRISES": {
              "ATROPINE SULFATE-NACL": "ATROPINE SULFATE-NACL"
            },
            "LETCO MEDICAL": {
              "TROPICAMIDE": "TROPICAMIDE"
            },
            "MEDISCA": {
              "TROPICAMIDE": "TROPICAMIDE"
            },
            "NUCARE PHARMACEUTICALS": {
              "CYCLOPENTOLATE HCL": "CYCLOPENTOLATE HCL",
              "TROPICAMIDE": "TROPICAMIDE"
            },
            "OCUSOFT": {
              "HOMATROPINE HBR": "HOMATROPINE HBR"
            },
            "PCCA": {
              "TROPICAMIDE": "TROPICAMIDE"
            },
            "QUALITY CARE": {
              "ISOPTO HOMATROPINE": "ISOPTO HOMATROPINE"
            },
            "SANDOZ": {
              "CYCLOPENTOLATE HCL": "CYCLOPENTOLATE HCL",
              "PHENYLEPHRINE HCL": "PHENYLEPHRINE HCL",
              "TROPICAMIDE": "TROPICAMIDE"
            },
            "SOMERSET THERAPEUTICS": {
              "TROPICAMIDE": "TROPICAMIDE"
            },
            "SPECTRUM": {
              "TROPICAMIDE": "TROPICAMIDE"
            }
          }
        },
        "Miotics": {
          "Miotics - Cholinesterase Inhibitors": {
            "PFIZER U.S.": {
              "PHOSPHOLINE IODIDE": "PHOSPHOLINE IODIDE"
            }
          },
          "Miotics - Direct Acting": {
            "AKORN": {
              "PILOCARPINE HCL": "PILOCARPINE HCL"
            },
            "ALCON SURGICAL": {
              "MIOSTAT": "MIOSTAT"
            },
            "AMNEAL PHARMACEUTICALS": {
              "PILOCARPINE HCL": "PILOCARPINE HCL"
            },
            "BAUSCH HEALTH": {
              "MIOCHOL-E": "MIOCHOL-E"
            },
            "NOVARTIS": {
              "ISOPTO CARPINE": "ISOPTO CARPINE"
            },
            "SANDOZ": {
              "PILOCARPINE HCL": "PILOCARPINE HCL"
            },
            "SOMERSET THERAPEUTICS": {
              "PILOCARPINE HCL": "PILOCARPINE HCL"
            }
          }
        },
        "Ophthalmic - Angiogenesis Inhibitors": {
          "Vascular Endothelial Growth Factor (VEGF) Antagonists": {
            "BAUSCH HEALTH": {
              "MACUGEN": "MACUGEN"
            },
            "FAGRON COMPOUNDING SERVICES": {
              "BEVACIZUMAB": "BEVACIZUMAB"
            },
            "GENENTECH": {
              "LUCENTIS": "LUCENTIS"
            },
            "LEITERS HEALTH": {
              "BEVACIZUMAB": "BEVACIZUMAB"
            },
            "NOVARTIS": {
              "BEOVU": "BEOVU"
            },
            "REGENERON PHARMACEUTICALS": {
              "EYLEA": "EYLEA"
            }
          }
        },
        "Ophthalmic Adrenergic Agents": {
          "Alpha Adrenergic Agonist & Carbonic Anhydrase Inhib Comb": {
            "IMPRIMIS NJOF": {
              "BRIMONIDINE-DORZOLAMIDE": "BRIMONIDINE-DORZOLAMIDE"
            },
            "NOVARTIS": {
              "SIMBRINZA": "SIMBRINZA"
            }
          },
          "Ophthalmic Selective Alpha Adrenergic Agonists": {
            "A-S MEDICATION SOLUTIONS": {
              "ALPHAGAN P": "ALPHAGAN P",
              "BRIMONIDINE TARTRATE": "BRIMONIDINE TARTRATE"
            },
            "AKORN": {
              "APRACLONIDINE HCL": "APRACLONIDINE HCL",
              "BRIMONIDINE TARTRATE": "BRIMONIDINE TARTRATE"
            },
            "ALLERGAN": {
              "ALPHAGAN P": "ALPHAGAN P"
            },
            "BAUSCH HEALTH": {
              "BRIMONIDINE TARTRATE": "BRIMONIDINE TARTRATE",
              "LUMIFY": "LUMIFY"
            },
            "NOVARTIS": {
              "IOPIDINE": "IOPIDINE"
            },
            "SANDOZ": {
              "APRACLONIDINE HCL": "APRACLONIDINE HCL",
              "BRIMONIDINE TARTRATE": "BRIMONIDINE TARTRATE"
            },
            "SOMERSET THERAPEUTICS": {
              "BRIMONIDINE TARTRATE": "BRIMONIDINE TARTRATE"
            },
            "WESTMINSTER PHARMACEUTICALS": {
              "BRIMONIDINE TARTRATE": "BRIMONIDINE TARTRATE"
            }
          }
        },
        "Ophthalmic Anti-infectives": {
          "Ophthalmic Anti-infective Combinations": {
            "A-S MEDICATION SOLUTIONS": {
              "BACITRACIN-POLYMYXIN B": "BACITRACIN-POLYMYXIN B",
              "NEOMYCIN-BACITRACIN ZN-POLYMYX": "NEOMYCIN-BACITRACIN ZN-POLYMYX",
              "NEOMYCIN-POLYMYXIN-GRAMICIDIN": "NEOMYCIN-POLYMYXIN-GRAMICIDIN",
              "NEOSPORIN": "NEOSPORIN",
              "POLYCIN": "POLYCIN",
              "POLYMYXIN B-TRIMETHOPRIM": "POLYMYXIN B-TRIMETHOPRIM",
              "POLYTRIM": "POLYTRIM"
            },
            "AIDAREX PHARMACEUTICALS": {
              "POLYMYXIN B-TRIMETHOPRIM": "POLYMYXIN B-TRIMETHOPRIM"
            },
            "AKORN": {
              "AK-POLY-BAC": "AK-POLY-BAC",
              "NEOMYCIN-BACITRACIN ZN-POLYMYX": "NEOMYCIN-BACITRACIN ZN-POLYMYX",
              "POLYMYXIN B-TRIMETHOPRIM": "POLYMYXIN B-TRIMETHOPRIM"
            },
            "ALLERGAN": {
              "POLYTRIM": "POLYTRIM"
            },
            "BAUSCH HEALTH": {
              "BACITRACIN-POLYMYXIN B": "BACITRACIN-POLYMYXIN B",
              "NEOMYCIN-BACITRACIN ZN-POLYMYX": "NEOMYCIN-BACITRACIN ZN-POLYMYX",
              "NEOMYCIN-POLYMYXIN-GRAMICIDIN": "NEOMYCIN-POLYMYXIN-GRAMICIDIN",
              "POLYMYXIN B-TRIMETHOPRIM": "POLYMYXIN B-TRIMETHOPRIM"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "POLYMYXIN B-TRIMETHOPRIM": "POLYMYXIN B-TRIMETHOPRIM"
            },
            "DIRECT RX": {
              "AK-POLY-BAC": "AK-POLY-BAC",
              "BACITRACIN-POLYMYXIN B": "BACITRACIN-POLYMYXIN B",
              "POLYCIN": "POLYCIN",
              "POLYMYXIN B-TRIMETHOPRIM": "POLYMYXIN B-TRIMETHOPRIM"
            },
            "GREENSTONE": {
              "POLYMYXIN B-TRIMETHOPRIM": "POLYMYXIN B-TRIMETHOPRIM"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "NEOMYCIN-BACITRACIN ZN-POLYMYX": "NEOMYCIN-BACITRACIN ZN-POLYMYX",
              "NEOMYCIN-POLYMYXIN-GRAMICIDIN": "NEOMYCIN-POLYMYXIN-GRAMICIDIN",
              "POLYMYXIN B-TRIMETHOPRIM": "POLYMYXIN B-TRIMETHOPRIM"
            },
            "LEITER'S ENTERPRISES": {
              "MOXIFLOXACIN HCL-BSS": "MOXIFLOXACIN HCL-BSS"
            },
            "LEITERS HEALTH": {
              "MOXIFLOXACIN HCL-BSS": "MOXIFLOXACIN HCL-BSS"
            },
            "MEDVANTX": {
              "POLYMYXIN B-TRIMETHOPRIM": "POLYMYXIN B-TRIMETHOPRIM"
            },
            "NUCARE PHARMACEUTICALS": {
              "BACITRACIN-POLYMYXIN B": "BACITRACIN-POLYMYXIN B",
              "NEOMYCIN-POLYMYXIN-GRAMICIDIN": "NEOMYCIN-POLYMYXIN-GRAMICIDIN"
            },
            "PERRIGO": {
              "NEO-POLYCIN": "NEO-POLYCIN",
              "POLYCIN": "POLYCIN"
            },
            "PFIZER U.S.": {
              "NEOSPORIN": "NEOSPORIN"
            },
            "PHARMEDIX": {
              "POLYMYXIN B-TRIMETHOPRIM": "POLYMYXIN B-TRIMETHOPRIM"
            },
            "PREFERRED PHARMACEUTICALS": {
              "BACITRACIN-POLYMYXIN B": "BACITRACIN-POLYMYXIN B",
              "POLYMYXIN B-TRIMETHOPRIM": "POLYMYXIN B-TRIMETHOPRIM"
            },
            "PROFICIENT RX": {
              "POLYMYXIN B-TRIMETHOPRIM": "POLYMYXIN B-TRIMETHOPRIM"
            },
            "QUALITY CARE": {
              "NEOMYCIN-POLYMYXIN-GRAMICIDIN": "NEOMYCIN-POLYMYXIN-GRAMICIDIN",
              "POLYMYXIN B-TRIMETHOPRIM": "POLYMYXIN B-TRIMETHOPRIM",
              "POLYTRIM": "POLYTRIM",
              "TRIPLE ANTIBIOTIC": "TRIPLE ANTIBIOTIC"
            },
            "QUALITY CARE PRODUCTS": {
              "POLYMYXIN B-TRIMETHOPRIM": "POLYMYXIN B-TRIMETHOPRIM"
            },
            "RISING PHARMACEUTICALS": {
              "NEOMYCIN-BACITRACIN ZN-POLYMYX": "NEOMYCIN-BACITRACIN ZN-POLYMYX"
            },
            "SANDOZ": {
              "POLYMYXIN B-TRIMETHOPRIM": "POLYMYXIN B-TRIMETHOPRIM"
            }
          },
          "Ophthalmic Antibiotics": {
            "A-S MEDICATION SOLUTIONS": {
              "BESIVANCE": "BESIVANCE",
              "CILOXAN": "CILOXAN",
              "CIPROFLOXACIN HCL": "CIPROFLOXACIN HCL",
              "ERYTHROMYCIN": "ERYTHROMYCIN",
              "GENTAK": "GENTAK",
              "GENTAMICIN SULFATE": "GENTAMICIN SULFATE",
              "MOXEZA": "MOXEZA",
              "MOXIFLOXACIN HCL": "MOXIFLOXACIN HCL",
              "OFLOXACIN": "OFLOXACIN",
              "TOBRAMYCIN": "TOBRAMYCIN",
              "TOBREX": "TOBREX",
              "VIGAMOX": "VIGAMOX",
              "ZYMAXID": "ZYMAXID"
            },
            "AIDAREX PHARMACEUTICALS": {
              "CIPROFLOXACIN HCL": "CIPROFLOXACIN HCL",
              "ERYTHROMYCIN": "ERYTHROMYCIN",
              "GENTAMICIN SULFATE": "GENTAMICIN SULFATE",
              "OFLOXACIN": "OFLOXACIN",
              "TOBRAMYCIN": "TOBRAMYCIN",
              "VIGAMOX": "VIGAMOX"
            },
            "AKORN": {
              "AZASITE": "AZASITE",
              "CIPROFLOXACIN HCL": "CIPROFLOXACIN HCL",
              "ERYTHROMYCIN": "ERYTHROMYCIN",
              "GENTAK": "GENTAK",
              "GENTAMICIN SULFATE": "GENTAMICIN SULFATE",
              "LEVOFLOXACIN": "LEVOFLOXACIN",
              "MOXIFLOXACIN HCL": "MOXIFLOXACIN HCL",
              "OFLOXACIN": "OFLOXACIN",
              "TOBRAMYCIN": "TOBRAMYCIN"
            },
            "ALEMBIC PHARMACEUTICALS": {
              "MOXIFLOXACIN HCL": "MOXIFLOXACIN HCL",
              "TOBRAMYCIN": "TOBRAMYCIN"
            },
            "ALLERGAN": {
              "OCUFLOX": "OCUFLOX",
              "ZYMAXID": "ZYMAXID"
            },
            "ALTAIRE": {
              "CIPROFLOXACIN HCL": "CIPROFLOXACIN HCL",
              "OFLOXACIN": "OFLOXACIN"
            },
            "ALTURA PHARMACEUTICALS": {
              "CIPROFLOXACIN HCL": "CIPROFLOXACIN HCL",
              "OCUFLOX": "OCUFLOX",
              "TOBRAMYCIN": "TOBRAMYCIN"
            },
            "AMERICAN REGENT": {
              "CIPROFLOXACIN HCL": "CIPROFLOXACIN HCL"
            },
            "APOTEX": {
              "MOXIFLOXACIN HCL": "MOXIFLOXACIN HCL",
              "OFLOXACIN": "OFLOXACIN"
            },
            "AUROBINDO PHARMA": {
              "MOXIFLOXACIN HCL": "MOXIFLOXACIN HCL"
            },
            "BAUSCH & LOMB": {
              "CIPROFLOXACIN HCL": "CIPROFLOXACIN HCL"
            },
            "BAUSCH HEALTH": {
              "BESIVANCE": "BESIVANCE",
              "ERYTHROMYCIN": "ERYTHROMYCIN",
              "GENTAMICIN SULFATE": "GENTAMICIN SULFATE",
              "OFLOXACIN": "OFLOXACIN",
              "TOBRAMYCIN": "TOBRAMYCIN"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "BESIVANCE": "BESIVANCE",
              "CILOXAN": "CILOXAN",
              "CIPROFLOXACIN HCL": "CIPROFLOXACIN HCL",
              "ERYTHROMYCIN": "ERYTHROMYCIN",
              "GATIFLOXACIN": "GATIFLOXACIN",
              "GENTAMICIN SULFATE": "GENTAMICIN SULFATE",
              "OFLOXACIN": "OFLOXACIN",
              "TOBRAMYCIN": "TOBRAMYCIN",
              "VIGAMOX": "VIGAMOX"
            },
            "DIRECT RX": {
              "CIPROFLOXACIN HCL": "CIPROFLOXACIN HCL",
              "ERYTHROMYCIN": "ERYTHROMYCIN",
              "GENTAMICIN SULFATE": "GENTAMICIN SULFATE",
              "MOXIFLOXACIN HCL": "MOXIFLOXACIN HCL",
              "OFLOXACIN": "OFLOXACIN",
              "TOBRAMYCIN": "TOBRAMYCIN"
            },
            "FAGRON COMPOUNDING SERVICES": {
              "CEFUROXIME SODIUM-NACL": "CEFUROXIME SODIUM-NACL",
              "MOXIFLOXACIN HCL": "MOXIFLOXACIN HCL"
            },
            "FOSUN PHARMA USA": {
              "MOXIFLOXACIN HCL": "MOXIFLOXACIN HCL"
            },
            "GREENSTONE": {
              "GATIFLOXACIN": "GATIFLOXACIN",
              "GENTAMICIN SULFATE": "GENTAMICIN SULFATE"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "CILOXAN": "CILOXAN",
              "CIPROFLOXACIN HCL": "CIPROFLOXACIN HCL",
              "ERYTHROMYCIN": "ERYTHROMYCIN",
              "GENTAMICIN SULFATE": "GENTAMICIN SULFATE",
              "OCUFLOX": "OCUFLOX",
              "OFLOXACIN": "OFLOXACIN",
              "TOBRAMYCIN": "TOBRAMYCIN",
              "TOBREX": "TOBREX",
              "VIGAMOX": "VIGAMOX"
            },
            "HI-TECH": {
              "CIPROFLOXACIN HCL": "CIPROFLOXACIN HCL",
              "GATIFLOXACIN": "GATIFLOXACIN",
              "LEVOFLOXACIN": "LEVOFLOXACIN"
            },
            "IMPRIMIS NJOF": {
              "KLARITY-A": "KLARITY-A",
              "MOXIFLOXACIN HCL": "MOXIFLOXACIN HCL"
            },
            "LEADING PHARMA": {
              "CIPROFLOXACIN HCL": "CIPROFLOXACIN HCL"
            },
            "LEITER'S ENTERPRISES": {
              "CEFUROXIME SODIUM-NACL": "CEFUROXIME SODIUM-NACL",
              "MOXIFLOXACIN HCL": "MOXIFLOXACIN HCL"
            },
            "LEITERS HEALTH": {
              "MOXIFLOXACIN HCL": "MOXIFLOXACIN HCL"
            },
            "LUPIN PHARMACEUTICALS": {
              "GATIFLOXACIN": "GATIFLOXACIN",
              "MOXIFLOXACIN HCL": "MOXIFLOXACIN HCL",
              "MOXIFLOXACIN HCL (2X DAY)": "MOXIFLOXACIN HCL (2X DAY)"
            },
            "MEDVANTX": {
              "GENTAMICIN SULFATE": "GENTAMICIN SULFATE",
              "TOBRAMYCIN": "TOBRAMYCIN"
            },
            "MOBIUS THERAPEUTICS": {
              "MITOSOL": "MITOSOL"
            },
            "MYLAN": {
              "GATIFLOXACIN": "GATIFLOXACIN",
              "MOXIFLOXACIN HCL": "MOXIFLOXACIN HCL"
            },
            "NOVARTIS": {
              "CILOXAN": "CILOXAN",
              "MOXEZA": "MOXEZA",
              "TOBREX": "TOBREX",
              "VIGAMOX": "VIGAMOX"
            },
            "NUCARE PHARMACEUTICALS": {
              "BESIVANCE": "BESIVANCE",
              "CIPROFLOXACIN HCL": "CIPROFLOXACIN HCL",
              "ERYTHROMYCIN": "ERYTHROMYCIN",
              "GENTAMICIN SULFATE": "GENTAMICIN SULFATE",
              "TOBRAMYCIN": "TOBRAMYCIN",
              "TOBREX": "TOBREX"
            },
            "PACIFIC PHARMA": {
              "OFLOXACIN": "OFLOXACIN"
            },
            "PACK PHARMACEUTICALS, LLC": {
              "CIPROFLOXACIN HCL": "CIPROFLOXACIN HCL",
              "LEVOFLOXACIN": "LEVOFLOXACIN"
            },
            "PERRIGO": {
              "BACITRACIN": "BACITRACIN",
              "ERYTHROMYCIN": "ERYTHROMYCIN",
              "GENTAMICIN SULFATE": "GENTAMICIN SULFATE",
              "ILOTYCIN": "ILOTYCIN"
            },
            "PHARMEDIX": {
              "CIPROFLOXACIN HCL": "CIPROFLOXACIN HCL",
              "ERYTHROMYCIN": "ERYTHROMYCIN",
              "GENTAMICIN SULFATE": "GENTAMICIN SULFATE",
              "OFLOXACIN": "OFLOXACIN",
              "TOBRAMYCIN": "TOBRAMYCIN"
            },
            "PREFERRED PHARMACEUTICALS": {
              "CIPROFLOXACIN HCL": "CIPROFLOXACIN HCL",
              "ERYTHROMYCIN": "ERYTHROMYCIN",
              "GENTAMICIN SULFATE": "GENTAMICIN SULFATE",
              "MOXIFLOXACIN HCL": "MOXIFLOXACIN HCL",
              "OFLOXACIN": "OFLOXACIN",
              "TOBRAMYCIN": "TOBRAMYCIN"
            },
            "PROFICIENT RX": {
              "CIPROFLOXACIN HCL": "CIPROFLOXACIN HCL",
              "ERYTHROMYCIN": "ERYTHROMYCIN",
              "GENTAK": "GENTAK",
              "GENTAMICIN SULFATE": "GENTAMICIN SULFATE",
              "OFLOXACIN": "OFLOXACIN",
              "TOBRAMYCIN": "TOBRAMYCIN"
            },
            "QUALITY CARE": {
              "CILOXAN": "CILOXAN",
              "CIPROFLOXACIN HCL": "CIPROFLOXACIN HCL",
              "ERYTHROMYCIN": "ERYTHROMYCIN",
              "GENTAK": "GENTAK",
              "GENTAMICIN SULFATE": "GENTAMICIN SULFATE",
              "OCUFLOX": "OCUFLOX",
              "OFLOXACIN": "OFLOXACIN",
              "TOBRAMYCIN": "TOBRAMYCIN",
              "VIGAMOX": "VIGAMOX"
            },
            "QUALITY CARE PRODUCTS": {
              "CIPROFLOXACIN HCL": "CIPROFLOXACIN HCL",
              "ERYTHROMYCIN": "ERYTHROMYCIN",
              "MOXIFLOXACIN HCL": "MOXIFLOXACIN HCL",
              "OFLOXACIN": "OFLOXACIN",
              "TOBRAMYCIN": "TOBRAMYCIN"
            },
            "RISING PHARMACEUTICALS": {
              "OFLOXACIN": "OFLOXACIN"
            },
            "RXCHANGE CO": {
              "ERYTHROMYCIN": "ERYTHROMYCIN",
              "GENTAMICIN SULFATE": "GENTAMICIN SULFATE"
            },
            "SANDOZ": {
              "CIPROFLOXACIN HCL": "CIPROFLOXACIN HCL",
              "GATIFLOXACIN": "GATIFLOXACIN",
              "GENTAMICIN SULFATE": "GENTAMICIN SULFATE",
              "MOXIFLOXACIN HCL": "MOXIFLOXACIN HCL",
              "OFLOXACIN": "OFLOXACIN",
              "TOBRAMYCIN": "TOBRAMYCIN"
            },
            "SOMERSET THERAPEUTICS": {
              "TOBRAMYCIN": "TOBRAMYCIN"
            },
            "ST MARY'S MPP": {
              "TOBRAMYCIN": "TOBRAMYCIN"
            },
            "UNIT DOSE SERVICES": {
              "CIPROFLOXACIN HCL": "CIPROFLOXACIN HCL",
              "GENTAMICIN SULFATE": "GENTAMICIN SULFATE",
              "OFLOXACIN": "OFLOXACIN",
              "TOBRAMYCIN": "TOBRAMYCIN"
            },
            "WOODWARD PHARMA SERVICES": {
              "BACIGUENT": "BACIGUENT"
            }
          },
          "Ophthalmic Antifungal": {
            "EYEVANCE PHARMACEUTICALS": {
              "NATACYN": "NATACYN"
            }
          },
          "Ophthalmic Antiseptics": {
            "ALCON SURGICAL": {
              "BETADINE OPHTHALMIC PREP": "BETADINE OPHTHALMIC PREP"
            },
            "IMPRIMIS NJOF": {
              "POVIDONE-IODINE": "POVIDONE-IODINE"
            }
          },
          "Ophthalmic Antivirals": {
            "A-S MEDICATION SOLUTIONS": {
              "ZIRGAN": "ZIRGAN"
            },
            "BAUSCH HEALTH": {
              "ZIRGAN": "ZIRGAN"
            },
            "GREENSTONE": {
              "TRIFLURIDINE": "TRIFLURIDINE"
            },
            "PFIZER U.S.": {
              "VIROPTIC": "VIROPTIC"
            },
            "SANDOZ": {
              "TRIFLURIDINE": "TRIFLURIDINE"
            }
          },
          "Ophthalmic Sulfonamides": {
            "A-S MEDICATION SOLUTIONS": {
              "SULFACETAMIDE SODIUM": "SULFACETAMIDE SODIUM"
            },
            "AIDAREX PHARMACEUTICALS": {
              "SULFACETAMIDE SODIUM": "SULFACETAMIDE SODIUM"
            },
            "AKORN": {
              "SULFACETAMIDE SODIUM": "SULFACETAMIDE SODIUM"
            },
            "ALLERGAN": {
              "BLEPH-10": "BLEPH-10"
            },
            "BAUSCH HEALTH": {
              "SULFACETAMIDE SODIUM": "SULFACETAMIDE SODIUM"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "SULFACETAMIDE SODIUM": "SULFACETAMIDE SODIUM"
            },
            "DIRECT RX": {
              "SULFACETAMIDE SODIUM": "SULFACETAMIDE SODIUM"
            },
            "GREENSTONE": {
              "SULFACETAMIDE SODIUM": "SULFACETAMIDE SODIUM"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "BLEPH-10": "BLEPH-10",
              "SULFACETAMIDE SODIUM": "SULFACETAMIDE SODIUM"
            },
            "MEDVANTX": {
              "SULFACETAMIDE SODIUM": "SULFACETAMIDE SODIUM"
            },
            "NUCARE PHARMACEUTICALS": {
              "SULFACETAMIDE SODIUM": "SULFACETAMIDE SODIUM"
            },
            "PERRIGO": {
              "SULFACETAMIDE SODIUM": "SULFACETAMIDE SODIUM"
            },
            "PHARMEDIX": {
              "SULFACETAMIDE SODIUM": "SULFACETAMIDE SODIUM"
            },
            "PREFERRED PHARMACEUTICALS": {
              "SULFACETAMIDE SODIUM": "SULFACETAMIDE SODIUM"
            },
            "QUALITY CARE": {
              "SULFACETAMIDE SODIUM": "SULFACETAMIDE SODIUM"
            },
            "RXCHANGE CO": {
              "SULFACETAMIDE SODIUM": "SULFACETAMIDE SODIUM"
            },
            "SANDOZ": {
              "SULFACETAMIDE SODIUM": "SULFACETAMIDE SODIUM"
            }
          }
        },
        "Ophthalmic Decongestants": {
          "Ophthalmic Decongestant Combinations": {
            "A-S MEDICATION SOLUTIONS": {
              "CLEAR EYES REDNESS RELIEF": "CLEAR EYES REDNESS RELIEF",
              "NAPHCON-A": "NAPHCON-A"
            },
            "AIDAREX PHARMACEUTICALS": {
              "NAPHCON-A": "NAPHCON-A"
            },
            "ALCON VISION": {
              "NAPHCON-A": "NAPHCON-A"
            },
            "AMERICAN SALES COMPANY": {
              "EYE DROPS ADVANCED RELIEF": "EYE DROPS ADVANCED RELIEF"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP REDNESS RELIEF": "GNP REDNESS RELIEF"
            },
            "BAUSCH & LOMB CONS": {
              "OPCON-A": "OPCON-A"
            },
            "BERGEN BRUNSWIG": {
              "GNP EYE DROPS": "GNP EYE DROPS"
            },
            "CHAIN DRUG CONSORTIUM": {
              "ALLERGY EYE": "ALLERGY EYE",
              "EYE DROPS EXTRA": "EYE DROPS EXTRA",
              "EYE DROPS RELIEF": "EYE DROPS RELIEF"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC EYE DROPS": "QC EYE DROPS"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS ASTRINGENT EYE DROPS": "CVS ASTRINGENT EYE DROPS",
              "CVS EYE ALLERGY RELIEF": "CVS EYE ALLERGY RELIEF",
              "CVS EYE DROPS": "CVS EYE DROPS",
              "CVS EYE DROPS DUAL ACTION": "CVS EYE DROPS DUAL ACTION",
              "CVS MAXIMUM REDNESS RELIEF": "CVS MAXIMUM REDNESS RELIEF",
              "CVS REDNESS RELIEF": "CVS REDNESS RELIEF"
            },
            "EQUALINE": {
              "EQL ADVANCED RELIEF": "EQL ADVANCED RELIEF",
              "EQL EYE DROPS AC": "EQL EYE DROPS AC",
              "EQL REDNESS RELIEF": "EQL REDNESS RELIEF"
            },
            "GEISS DESTIN & DUNN": {
              "GOODSENSE EYE DROPS": "GOODSENSE EYE DROPS",
              "GOODSENSE REDNESS RELIEF PLUS": "GOODSENSE REDNESS RELIEF PLUS",
              "GOODSENSE RELIEF EYE DROPS": "GOODSENSE RELIEF EYE DROPS"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "NAPHCON-A": "NAPHCON-A"
            },
            "J & J CONSUMER PRODUCTS": {
              "VISINE RED EYE HYDRATING COMF": "VISINE RED EYE HYDRATING COMF",
              "VISINE RED EYE TOTAL COMFORT": "VISINE RED EYE TOTAL COMFORT"
            },
            "JOHNSON&JOHNSON HEALTHCARE": {
              "VISINE": "VISINE",
              "VISINE ADVANCED RELIEF": "VISINE ADVANCED RELIEF",
              "VISINE MAXIMUM REDNESS RELIEF": "VISINE MAXIMUM REDNESS RELIEF",
              "VISINE TOTALITY MULTI-SYMPTOM": "VISINE TOTALITY MULTI-SYMPTOM",
              "VISINE-A": "VISINE-A",
              "VISINE-AC": "VISINE-AC"
            },
            "LEADER BRAND PRODUCTS": {
              "ADVANCED FORMULA EYE DROPS": "ADVANCED FORMULA EYE DROPS",
              "EYE DROPS ADVANCED RELIEF": "EYE DROPS ADVANCED RELIEF",
              "EYE DROPS ALLERGY RELIEF": "EYE DROPS ALLERGY RELIEF",
              "REDNESS RELIEF": "REDNESS RELIEF"
            },
            "MCKESSON": {
              "HM EYE DROPS": "HM EYE DROPS",
              "HM EYE DROPS ADVANCED RELIEF": "HM EYE DROPS ADVANCED RELIEF"
            },
            "MCKESSON SUNMARK": {
              "SM EYE DROPS": "SM EYE DROPS",
              "SM REDNESS RELIEF": "SM REDNESS RELIEF"
            },
            "MEDICINE SHOPPE": {
              "EYE DROPS AR": "EYE DROPS AR"
            },
            "MEDTECH": {
              "CLEAR EYES COMPLETE": "CLEAR EYES COMPLETE",
              "CLEAR EYES COOLING COMFORT": "CLEAR EYES COOLING COMFORT",
              "CLEAR EYES FOR DRY EYES PLUS": "CLEAR EYES FOR DRY EYES PLUS",
              "CLEAR EYES MAX REDNESS RELIEF": "CLEAR EYES MAX REDNESS RELIEF",
              "CLEAR EYES MAXIMUM ITCHY EYE": "CLEAR EYES MAXIMUM ITCHY EYE",
              "CLEAR EYES PURE RELIEF MS PF": "CLEAR EYES PURE RELIEF MS PF",
              "CLEAR EYES REDNESS RELIEF": "CLEAR EYES REDNESS RELIEF",
              "CLEAR EYES SEASONAL RELIEF": "CLEAR EYES SEASONAL RELIEF",
              "CLEAR EYES TRIPLE ACTION": "CLEAR EYES TRIPLE ACTION",
              "MURINE TEARS PLUS": "MURINE TEARS PLUS"
            },
            "NOVARTIS OPTHALMICS": {
              "VASOCLEAR-A": "VASOCLEAR-A"
            },
            "PREFERRED PHARMACEUTICALS": {
              "CLEAR EYES REDNESS RELIEF": "CLEAR EYES REDNESS RELIEF"
            },
            "RITE AID CORPORATION": {
              "RA EYE ALLERGY RELIEF": "RA EYE ALLERGY RELIEF",
              "RA EYE DROPS EXTRA": "RA EYE DROPS EXTRA",
              "RA STERILE EYE DROPS": "RA STERILE EYE DROPS"
            },
            "TARGET": {
              "TGT EYE ALLERGY RELIEF": "TGT EYE ALLERGY RELIEF",
              "TGT LUBRICANT REDNESS RELIEVER": "TGT LUBRICANT REDNESS RELIEVER",
              "TGT REDNESS RELIEVER": "TGT REDNESS RELIEVER"
            },
            "WAL-MART": {
              "EQ EYE ALLERGY RELIEF": "EQ EYE ALLERGY RELIEF"
            },
            "WALGREENS": {
              "ADVANCED LUBRICANT": "ADVANCED LUBRICANT",
              "ALLERGY EYE": "ALLERGY EYE",
              "EYE ALLERGY RELIEF": "EYE ALLERGY RELIEF",
              "EYE DROPS": "EYE DROPS",
              "EYE DROPS MAXIMUM RELIEF": "EYE DROPS MAXIMUM RELIEF",
              "REDNESS RELIEF": "REDNESS RELIEF",
              "REDNESS RELIEF MAX STRENGTH": "REDNESS RELIEF MAX STRENGTH",
              "RELIEF DROPS": "RELIEF DROPS",
              "RELIEF EYE DROPS": "RELIEF EYE DROPS"
            }
          },
          "Ophthalmic Decongestants": {
            "A-S MEDICATION SOLUTIONS": {
              "NAPHAZOLINE HCL": "NAPHAZOLINE HCL"
            },
            "AKORN": {
              "NAPHAZOLINE HCL": "NAPHAZOLINE HCL"
            },
            "AMERICAN SALES COMPANY": {
              "EYE DROPS": "EYE DROPS"
            },
            "BERGEN BRUNSWIG": {
              "GNP EYE DROPS": "GNP EYE DROPS"
            },
            "CHAIN DRUG CONSORTIUM": {
              "EYE DROPS": "EYE DROPS"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS EYE DROPS": "CVS EYE DROPS"
            },
            "EQUALINE": {
              "EQL EYE DROPS": "EQL EYE DROPS"
            },
            "GEISS DESTIN & DUNN": {
              "GOODSENSE EYE DROPS": "GOODSENSE EYE DROPS"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "TETRAHYDROZOLINE HCL": "TETRAHYDROZOLINE HCL"
            },
            "J & J CONSUMER PRODUCTS": {
              "VISINE RED EYE COMFORT": "VISINE RED EYE COMFORT"
            },
            "JOHNSON&JOHNSON HEALTHCARE": {
              "VISINE": "VISINE"
            },
            "LEADER BRAND PRODUCTS": {
              "EYE DROPS": "EYE DROPS"
            },
            "MAJOR PHARMACEUTICALS": {
              "OPTI-CLEAR": "OPTI-CLEAR"
            },
            "MCKESSON": {
              "HM EYE DROPS": "HM EYE DROPS"
            },
            "MCKESSON SUNMARK": {
              "SM EYE DROPS": "SM EYE DROPS"
            },
            "NEW WORLD IMPORTS": {
              "EYE DROPS": "EYE DROPS"
            },
            "PUBLIX SUPER MARKETS INC.": {
              "PX STERILE EYE DROPS": "PX STERILE EYE DROPS"
            },
            "RUGBY LABORATORIES": {
              "EYE DROPS": "EYE DROPS"
            },
            "WAL-MART": {
              "EQ EYE DROPS": "EQ EYE DROPS"
            },
            "WALGREENS": {
              "REDNESS RELIEVER EYE DROPS": "REDNESS RELIEVER EYE DROPS"
            }
          }
        },
        "Ophthalmic Gene Therapy": {
          "Ophthalmic Gene Therapy": {
            "SPARK THERAPEUTICS": {
              "LUXTURNA": "LUXTURNA"
            }
          }
        },
        "Ophthalmic Immunomodulators": {
          "Ophthalmic Immunomodulators": {
            "A-S MEDICATION SOLUTIONS": {
              "RESTASIS": "RESTASIS"
            },
            "ALLERGAN": {
              "RESTASIS": "RESTASIS",
              "RESTASIS MULTIDOSE": "RESTASIS MULTIDOSE"
            },
            "IMPRIMIS NJOF": {
              "CYCLOSPORINE IN KLARITY": "CYCLOSPORINE IN KLARITY"
            },
            "SUN PHARMACEUTICALS": {
              "CEQUA": "CEQUA"
            }
          }
        },
        "Ophthalmic Integrin Antagonists": {
          "Lymphocyte Function-Associated Antigen-1 (LFA-1) Antag": {
            "NOVARTIS": {
              "XIIDRA": "XIIDRA"
            }
          }
        },
        "Ophthalmic Kinase Inhibitors": {
          "Ophthalmic Kinase Inhibitors - Combinations": {
            "AERIE PHARMACEUTICALS": {
              "ROCKLATAN": "ROCKLATAN"
            }
          },
          "Ophthalmic Rho Kinase Inhibitors": {
            "AERIE PHARMACEUTICALS": {
              "RHOPRESSA": "RHOPRESSA"
            }
          }
        },
        "Ophthalmic Local Anesthetics": {
          "Ophthalmic Local Anesthetic - Combinations": {
            "FAGRON COMPOUNDING SERVICES": {
              "LIDOCAINE-PHENYLEPHRINE-BSS": "LIDOCAINE-PHENYLEPHRINE-BSS"
            },
            "IMPRIMIS NJOF": {
              "LIDOCAINE-EPINEPHRINE": "LIDOCAINE-EPINEPHRINE",
              "LIDOCAINE-PHENYLEPHRINE": "LIDOCAINE-PHENYLEPHRINE"
            },
            "LEITER'S ENTERPRISES": {
              "LIDOCAINE-PHENYLEPHRINE": "LIDOCAINE-PHENYLEPHRINE"
            },
            "LEITERS HEALTH": {
              "LIDOCAINE-PHENYLEPHRINE": "LIDOCAINE-PHENYLEPHRINE"
            }
          },
          "Ophthalmic Local Anesthetics": {
            "A-S MEDICATION SOLUTIONS": {
              "ALCAINE": "ALCAINE",
              "PROPARACAINE HCL": "PROPARACAINE HCL",
              "TETRACAINE HCL": "TETRACAINE HCL"
            },
            "AIDAREX PHARMACEUTICALS": {
              "PROPARACAINE HCL": "PROPARACAINE HCL"
            },
            "AKORN": {
              "AKTEN": "AKTEN",
              "PROPARACAINE HCL": "PROPARACAINE HCL"
            },
            "ALCON SURGICAL": {
              "ALCAINE": "ALCAINE"
            },
            "ALCON VISION": {
              "TETRACAINE HCL": "TETRACAINE HCL"
            },
            "ALTAIRE": {
              "ALTACAINE": "ALTACAINE"
            },
            "ALTURA PHARMACEUTICALS": {
              "PROPARACAINE HCL": "PROPARACAINE HCL"
            },
            "AMICI PHARMACEUTICALS": {
              "TETRACAINE HCL": "TETRACAINE HCL"
            },
            "BAUSCH HEALTH": {
              "PROPARACAINE HCL": "PROPARACAINE HCL",
              "TETRACAINE HCL": "TETRACAINE HCL"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "PROPARACAINE HCL": "PROPARACAINE HCL"
            },
            "HENRY SCHEIN": {
              "PROPARACAINE HCL": "PROPARACAINE HCL"
            },
            "NOVARTIS OPTHALMICS": {
              "TETRACAINE HCL": "TETRACAINE HCL"
            },
            "NUCARE PHARMACEUTICALS": {
              "PROPARACAINE HCL": "PROPARACAINE HCL",
              "TETRACAINE HCL": "TETRACAINE HCL"
            },
            "OCEANSIDE PHARMACEUTICALS": {
              "TETRACAINE HCL": "TETRACAINE HCL"
            },
            "OCUSOFT": {
              "TETCAINE": "TETCAINE",
              "TETRAVISC": "TETRAVISC",
              "TETRAVISC FORTE": "TETRAVISC FORTE"
            },
            "QUALITY CARE": {
              "ALCAINE": "ALCAINE",
              "PROPARACAINE HCL": "PROPARACAINE HCL",
              "TETRACAINE HCL": "TETRACAINE HCL"
            },
            "RXCHANGE CO": {
              "PROPARACAINE HCL": "PROPARACAINE HCL"
            },
            "SANDOZ": {
              "PROPARACAINE HCL": "PROPARACAINE HCL"
            }
          }
        },
        "Ophthalmic Nerve Growth Factors": {
          "Ophthalmic Nerve Growth Factors": {
            "DOMPE U.S.": {
              "OXERVATE": "OXERVATE"
            }
          }
        },
        "Ophthalmic Photodynamic Therapy Agents": {
          "Ophthalmic Photodynamic Therapy Agents": {
            "BAUSCH HEALTH": {
              "VISUDYNE": "VISUDYNE"
            }
          }
        },
        "Ophthalmic Photoenhancers": {
          "Ophthalmic Photoenhancer Combinations": {
            "AVEDRO": {
              "PHOTREXA VISCOUS": "PHOTREXA VISCOUS",
              "PHOTREXA-PHOTREXA VISCOUS KIT": "PHOTREXA-PHOTREXA VISCOUS KIT"
            }
          }
        },
        "Ophthalmic Steroids": {
          "Ophthalmic Steroid Combinations": {
            "A-S MEDICATION SOLUTIONS": {
              "BACITRA-NEOMYCIN-POLYMYXIN-HC": "BACITRA-NEOMYCIN-POLYMYXIN-HC",
              "BLEPHAMIDE": "BLEPHAMIDE",
              "BLEPHAMIDE S.O.P.": "BLEPHAMIDE S.O.P.",
              "NEOMYCIN-POLYMYXIN-DEXAMETH": "NEOMYCIN-POLYMYXIN-DEXAMETH",
              "NEOMYCIN-POLYMYXIN-HC": "NEOMYCIN-POLYMYXIN-HC",
              "TOBRADEX": "TOBRADEX",
              "TOBRAMYCIN-DEXAMETHASONE": "TOBRAMYCIN-DEXAMETHASONE"
            },
            "AIDAREX PHARMACEUTICALS": {
              "NEOMYCIN-POLYMYXIN-DEXAMETH": "NEOMYCIN-POLYMYXIN-DEXAMETH",
              "TOBRAMYCIN-DEXAMETHASONE": "TOBRAMYCIN-DEXAMETHASONE"
            },
            "AKORN": {
              "BACITRA-NEOMYCIN-POLYMYXIN-HC": "BACITRA-NEOMYCIN-POLYMYXIN-HC"
            },
            "ALLERGAN": {
              "BLEPHAMIDE": "BLEPHAMIDE",
              "BLEPHAMIDE S.O.P.": "BLEPHAMIDE S.O.P.",
              "PRED-G": "PRED-G",
              "PRED-G S.O.P.": "PRED-G S.O.P."
            },
            "ALTURA PHARMACEUTICALS": {
              "NEOMYCIN-POLYMYXIN-DEXAMETH": "NEOMYCIN-POLYMYXIN-DEXAMETH"
            },
            "BAUSCH HEALTH": {
              "BACITRA-NEOMYCIN-POLYMYXIN-HC": "BACITRA-NEOMYCIN-POLYMYXIN-HC",
              "NEOMYCIN-POLYMYXIN-DEXAMETH": "NEOMYCIN-POLYMYXIN-DEXAMETH",
              "SULFACETAMIDE-PREDNISOLONE": "SULFACETAMIDE-PREDNISOLONE",
              "TOBRAMYCIN-DEXAMETHASONE": "TOBRAMYCIN-DEXAMETHASONE",
              "ZYLET": "ZYLET"
            },
            "BPI LABS LLC": {
              "DOUBLE PM": "DOUBLE PM",
              "TRIPLE PMB": "TRIPLE PMB",
              "TRIPLE PMK": "TRIPLE PMK"
            },
            "DIRECT RX": {
              "NEOMYCIN-POLYMYXIN-DEXAMETH": "NEOMYCIN-POLYMYXIN-DEXAMETH"
            },
            "EYEVANCE PHARMACEUTICALS": {
              "TOBRADEX ST": "TOBRADEX ST"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "BACITRA-NEOMYCIN-POLYMYXIN-HC": "BACITRA-NEOMYCIN-POLYMYXIN-HC",
              "BLEPHAMIDE S.O.P.": "BLEPHAMIDE S.O.P.",
              "NEOMYCIN-POLYMYXIN-DEXAMETH": "NEOMYCIN-POLYMYXIN-DEXAMETH",
              "NEOMYCIN-POLYMYXIN-HC": "NEOMYCIN-POLYMYXIN-HC",
              "PRED-G": "PRED-G",
              "SULFACETAMIDE-PREDNISOLONE": "SULFACETAMIDE-PREDNISOLONE",
              "TOBRADEX": "TOBRADEX",
              "TOBRAMYCIN-DEXAMETHASONE": "TOBRAMYCIN-DEXAMETHASONE"
            },
            "IMPRIMIS NJOF": {
              "DEXAMETH-MOXIFLOX-KETOROLAC": "DEXAMETH-MOXIFLOX-KETOROLAC",
              "DEXAMETHASONE-MOXIFLOXACIN": "DEXAMETHASONE-MOXIFLOXACIN",
              "GATIFLOXACIN-DEXAMETHASONE": "GATIFLOXACIN-DEXAMETHASONE",
              "PREDNISOL ACE-MOXIFLOX-BROMFEN": "PREDNISOL ACE-MOXIFLOX-BROMFEN",
              "PREDNISOLON-GATIFLOX-BROMFENAC": "PREDNISOLON-GATIFLOX-BROMFENAC",
              "PREDNISOLON-MOXIFLOX-BROMFENAC": "PREDNISOLON-MOXIFLOX-BROMFENAC",
              "PREDNISOLON-MOXIFLOX-NEPAFENAC": "PREDNISOLON-MOXIFLOX-NEPAFENAC",
              "PREDNISOLONE ACET-MOXIFLOXACIN": "PREDNISOLONE ACET-MOXIFLOXACIN",
              "PREDNISOLONE ACETATE-NEPAFENAC": "PREDNISOLONE ACETATE-NEPAFENAC",
              "PREDNISOLONE-BROMFENAC": "PREDNISOLONE-BROMFENAC",
              "PREDNISOLONE-GATIFLOXACIN": "PREDNISOLONE-GATIFLOXACIN",
              "PREDNISOLONE-MOXIFLOXACIN": "PREDNISOLONE-MOXIFLOXACIN",
              "TRIAMCINOLONE-MOXIFLOXACIN": "TRIAMCINOLONE-MOXIFLOXACIN"
            },
            "NOVARTIS": {
              "MAXITROL": "MAXITROL",
              "TOBRADEX": "TOBRADEX"
            },
            "NUCARE PHARMACEUTICALS": {
              "BACITRA-NEOMYCIN-POLYMYXIN-HC": "BACITRA-NEOMYCIN-POLYMYXIN-HC",
              "BLEPHAMIDE": "BLEPHAMIDE",
              "NEOMYCIN-POLYMYXIN-DEXAMETH": "NEOMYCIN-POLYMYXIN-DEXAMETH",
              "TOBRADEX": "TOBRADEX"
            },
            "PDRX PHARMACEUTICAL": {
              "TOBRADEX": "TOBRADEX"
            },
            "PERRIGO": {
              "NEO-POLYCIN HC": "NEO-POLYCIN HC",
              "NEOMYCIN-POLYMYXIN-DEXAMETH": "NEOMYCIN-POLYMYXIN-DEXAMETH"
            },
            "PHARMEDIX": {
              "TOBRAMYCIN-DEXAMETHASONE": "TOBRAMYCIN-DEXAMETHASONE"
            },
            "PREFERRED PHARMACEUTICALS": {
              "NEOMYCIN-POLYMYXIN-DEXAMETH": "NEOMYCIN-POLYMYXIN-DEXAMETH",
              "TOBRAMYCIN-DEXAMETHASONE": "TOBRAMYCIN-DEXAMETHASONE"
            },
            "PROFICIENT RX": {
              "NEOMYCIN-POLYMYXIN-DEXAMETH": "NEOMYCIN-POLYMYXIN-DEXAMETH"
            },
            "QUALITY CARE": {
              "BACITRA-NEOMYCIN-POLYMYXIN-HC": "BACITRA-NEOMYCIN-POLYMYXIN-HC",
              "BLEPHAMIDE": "BLEPHAMIDE",
              "MAXITROL": "MAXITROL",
              "NEOMYCIN-POLYMYXIN-DEXAMETH": "NEOMYCIN-POLYMYXIN-DEXAMETH",
              "SULFACETAMIDE-PREDNISOLONE": "SULFACETAMIDE-PREDNISOLONE",
              "TOBRADEX": "TOBRADEX"
            },
            "RXCHANGE CO": {
              "TOBRADEX": "TOBRADEX"
            },
            "SANDOZ": {
              "NEOMYCIN-POLYMYXIN-DEXAMETH": "NEOMYCIN-POLYMYXIN-DEXAMETH",
              "NEOMYCIN-POLYMYXIN-HC": "NEOMYCIN-POLYMYXIN-HC",
              "SULFACETAMIDE-PREDNISOLONE": "SULFACETAMIDE-PREDNISOLONE",
              "TOBRAMYCIN-DEXAMETHASONE": "TOBRAMYCIN-DEXAMETHASONE"
            },
            "T & L INC.": {
              "PREDNISOLON-MOXIFLOX-BROMFENAC": "PREDNISOLON-MOXIFLOX-BROMFENAC",
              "PREDNISOLON-MOXIFLOX-KETOROLAC": "PREDNISOLON-MOXIFLOX-KETOROLAC",
              "PREDNISOLONE-MOXIFLOXACIN": "PREDNISOLONE-MOXIFLOXACIN"
            }
          },
          "Ophthalmic Steroids": {
            "A-S MEDICATION SOLUTIONS": {
              "ALREX": "ALREX",
              "DEXAMETHASONE SODIUM PHOSPHATE": "DEXAMETHASONE SODIUM PHOSPHATE",
              "DUREZOL": "DUREZOL",
              "FLUOROMETHOLONE": "FLUOROMETHOLONE",
              "FML LIQUIFILM": "FML LIQUIFILM",
              "LOTEMAX": "LOTEMAX",
              "PRED FORTE": "PRED FORTE",
              "PREDNISOLONE ACETATE": "PREDNISOLONE ACETATE",
              "VEXOL": "VEXOL"
            },
            "AIDAREX PHARMACEUTICALS": {
              "DEXAMETHASONE SODIUM PHOSPHATE": "DEXAMETHASONE SODIUM PHOSPHATE",
              "PREDNISOLONE ACETATE": "PREDNISOLONE ACETATE"
            },
            "AKORN": {
              "LOTEPREDNOL ETABONATE": "LOTEPREDNOL ETABONATE"
            },
            "ALCON VISION": {
              "VEXOL": "VEXOL"
            },
            "ALIMERA SCIENCES": {
              "ILUVIEN": "ILUVIEN"
            },
            "ALLERGAN": {
              "FML": "FML",
              "FML FORTE": "FML FORTE",
              "FML LIQUIFILM": "FML LIQUIFILM",
              "OZURDEX": "OZURDEX",
              "PRED FORTE": "PRED FORTE",
              "PRED MILD": "PRED MILD"
            },
            "BAUSCH HEALTH": {
              "ALREX": "ALREX",
              "DEXAMETHASONE SODIUM PHOSPHATE": "DEXAMETHASONE SODIUM PHOSPHATE",
              "LOTEMAX": "LOTEMAX",
              "LOTEMAX SM": "LOTEMAX SM",
              "PREDNISOLONE SODIUM PHOSPHATE": "PREDNISOLONE SODIUM PHOSPHATE",
              "RETISERT": "RETISERT"
            },
            "BRYANT RANCH PREPACK": {
              "PRED MILD": "PRED MILD"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "PRED FORTE": "PRED FORTE"
            },
            "DIRECT RX": {
              "PREDNISOLONE ACETATE": "PREDNISOLONE ACETATE"
            },
            "EYEPOINT PHARMACEUTICALS": {
              "DEXYCU": "DEXYCU",
              "YUTIQ": "YUTIQ"
            },
            "EYEVANCE PHARMACEUTICALS": {
              "FLAREX": "FLAREX"
            },
            "GREENSTONE": {
              "FLUOROMETHOLONE": "FLUOROMETHOLONE",
              "PREDNISOLONE ACETATE": "PREDNISOLONE ACETATE"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "DEXAMETHASONE SODIUM PHOSPHATE": "DEXAMETHASONE SODIUM PHOSPHATE",
              "FLUOROMETHOLONE": "FLUOROMETHOLONE",
              "PRED MILD": "PRED MILD",
              "PREDNISOLONE ACETATE": "PREDNISOLONE ACETATE"
            },
            "IMPRIMIS NJOF": {
              "KLARITY-B": "KLARITY-B",
              "KLARITY-L": "KLARITY-L",
              "PREDNISOLONE ACETATE P-F": "PREDNISOLONE ACETATE P-F"
            },
            "KALA PHARMACEUTICALS": {
              "INVELTYS": "INVELTYS"
            },
            "NOVARTIS": {
              "DUREZOL": "DUREZOL",
              "MAXIDEX": "MAXIDEX",
              "OMNIPRED": "OMNIPRED",
              "TRIESENCE": "TRIESENCE"
            },
            "OCEANSIDE PHARMACEUTICALS": {
              "LOTEPREDNOL ETABONATE": "LOTEPREDNOL ETABONATE"
            },
            "OCULAR THERAPEUTIX": {
              "DEXTENZA": "DEXTENZA"
            },
            "QUALITY CARE": {
              "MAXIDEX": "MAXIDEX"
            },
            "SANDOZ": {
              "PREDNISOLONE ACETATE": "PREDNISOLONE ACETATE"
            }
          }
        },
        "Ophthalmic Surgical Aids": {
          "Ophthalmic Surgical Aids": {
            "ALCON SURGICAL": {
              "CELLUGEL": "CELLUGEL",
              "PROVISC": "PROVISC"
            },
            "AMRING PHARMACEUTICALS": {
              "BIOLON": "BIOLON"
            },
            "BAUSCH & LOMB": {
              "OCUCOAT VISCOADHERENT": "OCUCOAT VISCOADHERENT"
            },
            "BAUSCH & LOMB SURGICAL": {
              "AMVISC": "AMVISC",
              "AMVISC PLUS": "AMVISC PLUS"
            },
            "BAUSCH HEALTH": {
              "AMVISC": "AMVISC"
            },
            "CYTOSOL OPHTHALMICS": {
              "SHELLGEL": "SHELLGEL"
            },
            "DUTCH OPHTHALMIC": {
              "MEMBRANEBLUE": "MEMBRANEBLUE",
              "TISSUEBLUE": "TISSUEBLUE",
              "VISIONBLUE": "VISIONBLUE"
            },
            "IMPRIMIS NJOF": {
              "HYALURONIDASE (INTRAOCULAR)": "HYALURONIDASE (INTRAOCULAR)"
            },
            "J & J VISION": {
              "HEALON": "HEALON",
              "HEALON GV": "HEALON GV",
              "HEALON PRO": "HEALON PRO",
              "HEALON5": "HEALON5",
              "HEALON5 PRO": "HEALON5 PRO"
            },
            "PFIZER U.S.": {
              "GELFILM": "GELFILM"
            }
          },
          "Ophthalmic Surgical Aids - Combinations": {
            "ALCON SURGICAL": {
              "DISCOVISC": "DISCOVISC",
              "DUOVISC": "DUOVISC",
              "VISCOAT": "VISCOAT"
            },
            "OMEROS": {
              "OMIDRIA": "OMIDRIA"
            }
          }
        },
        "Ophthalmics - Misc.": {
          "Ophthalmic Antiallergic": {
            "A-S MEDICATION SOLUTIONS": {
              "ALAWAY": "ALAWAY",
              "BEPREVE": "BEPREVE",
              "CROMOLYN SODIUM": "CROMOLYN SODIUM",
              "EPINASTINE HCL": "EPINASTINE HCL",
              "KETOTIFEN FUMARATE": "KETOTIFEN FUMARATE",
              "LASTACAFT": "LASTACAFT",
              "OLOPATADINE HCL": "OLOPATADINE HCL",
              "PATADAY": "PATADAY",
              "PATANOL": "PATANOL",
              "ZADITOR": "ZADITOR"
            },
            "ADVANCED VISION RESEARCH": {
              "THERATEARS ALLERGY": "THERATEARS ALLERGY"
            },
            "AKORN": {
              "AZELASTINE HCL": "AZELASTINE HCL",
              "CROMOLYN SODIUM": "CROMOLYN SODIUM",
              "KETOTIFEN FUMARATE": "KETOTIFEN FUMARATE",
              "OLOPATADINE HCL": "OLOPATADINE HCL"
            },
            "ALCON VISION": {
              "PATADAY": "PATADAY",
              "ZADITOR": "ZADITOR"
            },
            "ALEMBIC PHARMACEUTICALS": {
              "AZELASTINE HCL": "AZELASTINE HCL",
              "OLOPATADINE HCL": "OLOPATADINE HCL"
            },
            "ALLERGAN": {
              "ALOCRIL": "ALOCRIL",
              "ELESTAT": "ELESTAT",
              "LASTACAFT": "LASTACAFT"
            },
            "ALVOGEN": {
              "OLOPATADINE HCL": "OLOPATADINE HCL"
            },
            "AMERICAN SALES COMPANY": {
              "EYE ITCH RELIEF": "EYE ITCH RELIEF"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP EYE ITCH RELIEF": "GNP EYE ITCH RELIEF",
              "GNP ITCHY EYE": "GNP ITCHY EYE"
            },
            "APOTEX": {
              "AZELASTINE HCL": "AZELASTINE HCL",
              "EPINASTINE HCL": "EPINASTINE HCL",
              "KETOTIFEN FUMARATE": "KETOTIFEN FUMARATE",
              "OLOPATADINE HCL": "OLOPATADINE HCL"
            },
            "AUROBINDO PHARMA": {
              "OLOPATADINE HCL": "OLOPATADINE HCL"
            },
            "AUROHEALTH": {
              "OLOPATADINE HCL": "OLOPATADINE HCL"
            },
            "BAUSCH & LOMB CONS": {
              "ALAWAY": "ALAWAY",
              "ALAWAY CHILDRENS ALLERGY": "ALAWAY CHILDRENS ALLERGY"
            },
            "BAUSCH HEALTH": {
              "BEPREVE": "BEPREVE",
              "CROMOLYN SODIUM": "CROMOLYN SODIUM"
            },
            "BAYER CONSUMER": {
              "CLARITIN EYE": "CLARITIN EYE"
            },
            "BERGEN BRUNSWIG": {
              "GNP EYE ITCH RELIEF": "GNP EYE ITCH RELIEF"
            },
            "BRECKENRIDGE": {
              "EPINASTINE HCL": "EPINASTINE HCL"
            },
            "CHAIN DRUG CONSORTIUM": {
              "EYE ITCH RELIEF": "EYE ITCH RELIEF"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "PATADAY": "PATADAY"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS ALLERGY EYE DROPS": "CVS ALLERGY EYE DROPS",
              "CVS EYE ITCH RELIEF": "CVS EYE ITCH RELIEF"
            },
            "DIRECT RX": {
              "KETOTIFEN FUMARATE": "KETOTIFEN FUMARATE",
              "OLOPATADINE HCL": "OLOPATADINE HCL"
            },
            "ECI PHARMACEUTICALS": {
              "OLOPATADINE HCL": "OLOPATADINE HCL"
            },
            "EYEVANCE PHARMACEUTICALS": {
              "ZERVIATE": "ZERVIATE"
            },
            "GEISS DESTIN & DUNN": {
              "GOODSENSE ITCHY EYE": "GOODSENSE ITCHY EYE"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "OLOPATADINE HCL": "OLOPATADINE HCL"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "KETOTIFEN FUMARATE": "KETOTIFEN FUMARATE",
              "PATANOL": "PATANOL"
            },
            "KAISER FOUNDATION HOSPITAL": {
              "KP KETOTIFEN FUMARATE": "KP KETOTIFEN FUMARATE"
            },
            "LEADER BRAND PRODUCTS": {
              "ALLERGY EYE DROPS": "ALLERGY EYE DROPS",
              "EYE ITCH RELIEF": "EYE ITCH RELIEF"
            },
            "MCKESSON": {
              "HM EYE ITCH RELIEF": "HM EYE ITCH RELIEF"
            },
            "MCKESSON SUNMARK": {
              "SM EYE ITCH RELIEF": "SM EYE ITCH RELIEF"
            },
            "MYLAN": {
              "OLOPATADINE HCL": "OLOPATADINE HCL"
            },
            "NOVARTIS": {
              "ALOMIDE": "ALOMIDE",
              "EMADINE": "EMADINE",
              "PATADAY": "PATADAY",
              "PATANOL": "PATANOL",
              "PAZEO": "PAZEO"
            },
            "PREFERRED PHARMACEUTICALS": {
              "OLOPATADINE HCL": "OLOPATADINE HCL"
            },
            "PROFICIENT RX": {
              "CROMOLYN SODIUM": "CROMOLYN SODIUM",
              "KETOTIFEN FUMARATE": "KETOTIFEN FUMARATE"
            },
            "QUALITY CARE": {
              "CROMOLYN SODIUM": "CROMOLYN SODIUM"
            },
            "RISING PHARMACEUTICALS": {
              "OLOPATADINE HCL": "OLOPATADINE HCL"
            },
            "RITE AID CORPORATION": {
              "RA ANTIHISTAMINE EYE DROPS": "RA ANTIHISTAMINE EYE DROPS",
              "RA EYE ITCH RELIEF": "RA EYE ITCH RELIEF"
            },
            "RUGBY LABORATORIES": {
              "EYE ITCH RELIEF": "EYE ITCH RELIEF"
            },
            "SANDOZ": {
              "AZELASTINE HCL": "AZELASTINE HCL",
              "CROMOLYN SODIUM": "CROMOLYN SODIUM",
              "OLOPATADINE HCL": "OLOPATADINE HCL"
            },
            "SOMERSET THERAPEUTICS": {
              "AZELASTINE HCL": "AZELASTINE HCL",
              "EPINASTINE HCL": "EPINASTINE HCL",
              "OLOPATADINE HCL": "OLOPATADINE HCL"
            },
            "SUN PHARMACEUTICALS": {
              "AZELASTINE HCL": "AZELASTINE HCL",
              "EPINASTINE HCL": "EPINASTINE HCL"
            },
            "TARGET": {
              "TGT EYE ITCH RELIEF": "TGT EYE ITCH RELIEF"
            },
            "TEVA PHARMACEUTICALS USA": {
              "CROMOLYN SODIUM": "CROMOLYN SODIUM",
              "OLOPATADINE HCL": "OLOPATADINE HCL"
            },
            "UNIT DOSE SERVICES": {
              "EPINASTINE HCL": "EPINASTINE HCL"
            },
            "WAL-MART": {
              "EQ EYE ITCH RELIEF": "EQ EYE ITCH RELIEF",
              "EQ ITCHY EYE DROPS": "EQ ITCHY EYE DROPS"
            },
            "WALGREENS": {
              "EYE ITCH RELIEF": "EYE ITCH RELIEF",
              "WAL-ZYR": "WAL-ZYR"
            },
            "WALLACE PHARMACEUTICALS": {
              "AZELASTINE HCL": "AZELASTINE HCL"
            },
            "WESTMINSTER PHARMACEUTICALS": {
              "OLOPATADINE HCL": "OLOPATADINE HCL"
            }
          },
          "Ophthalmic Carbonic Anhydrase Inhibitors": {
            "A-S MEDICATION SOLUTIONS": {
              "AZOPT": "AZOPT",
              "DORZOLAMIDE HCL": "DORZOLAMIDE HCL"
            },
            "ACTAVIS PHARMA": {
              "DORZOLAMIDE HCL": "DORZOLAMIDE HCL"
            },
            "BAUSCH HEALTH": {
              "DORZOLAMIDE HCL": "DORZOLAMIDE HCL"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "DORZOLAMIDE HCL": "DORZOLAMIDE HCL"
            },
            "HI-TECH": {
              "DORZOLAMIDE HCL": "DORZOLAMIDE HCL"
            },
            "IMPRIMIS NJOF": {
              "DORZOLAMIDE HCL": "DORZOLAMIDE HCL"
            },
            "LEADING PHARMA": {
              "DORZOLAMIDE HCL": "DORZOLAMIDE HCL"
            },
            "MERCK SHARP & DOHME": {
              "TRUSOPT": "TRUSOPT"
            },
            "MICRO LABORATORIES": {
              "DORZOLAMIDE HCL": "DORZOLAMIDE HCL"
            },
            "NOVARTIS": {
              "AZOPT": "AZOPT"
            },
            "PRASCO LABORATORIES": {
              "DORZOLAMIDE HCL": "DORZOLAMIDE HCL"
            },
            "SANDOZ": {
              "DORZOLAMIDE HCL": "DORZOLAMIDE HCL"
            },
            "TEVA PHARMACEUTICALS USA": {
              "DORZOLAMIDE HCL": "DORZOLAMIDE HCL"
            }
          },
          "Ophthalmic Diagnostic Products": {
            "A-S MEDICATION SOLUTIONS": {
              "FUL-GLO": "FUL-GLO"
            },
            "AKORN": {
              "AK-FLUOR": "AK-FLUOR",
              "FUL-GLO": "FUL-GLO",
              "PAREMYD": "PAREMYD"
            },
            "ALCON SURGICAL": {
              "FLUORESCITE": "FLUORESCITE"
            },
            "ALTAIRE": {
              "ALTAFLUOR": "ALTAFLUOR",
              "ALTAFLUOR BENOX": "ALTAFLUOR BENOX",
              "FLUCAINE": "FLUCAINE",
              "FLURA-SAFE": "FLURA-SAFE"
            },
            "BAUSCH HEALTH": {
              "FLUOR-I-STRIPS A.T.": "FLUOR-I-STRIPS A.T.",
              "FLUORESCEIN-BENOXINATE": "FLUORESCEIN-BENOXINATE"
            },
            "HUB PHARMACEUTICALS": {
              "BIO GLO": "BIO GLO",
              "FLUORESCEIN-BENOXINATE": "FLUORESCEIN-BENOXINATE",
              "GREEN GLO LISSAMINE GREEN": "GREEN GLO LISSAMINE GREEN",
              "PROPARACAINE-FLUORESCEIN": "PROPARACAINE-FLUORESCEIN"
            },
            "NOMAX INC": {
              "GLOSTRIPS": "GLOSTRIPS"
            },
            "OCUSOFT": {
              "FLUCAINE": "FLUCAINE",
              "FLUROX": "FLUROX"
            },
            "ROSE STONE ENTERPRISES": {
              "LISSAMINE GREEN": "LISSAMINE GREEN",
              "ROSE GLO": "ROSE GLO"
            }
          },
          "Ophthalmic Enzymes": {
            "THROMBOGENICS": {
              "JETREA": "JETREA"
            }
          },
          "Ophthalmic Hyperosmolar Products": {
            "AACE PHARMACEUTICALS": {
              "SODIUM CHLORIDE (HYPERTONIC)": "SODIUM CHLORIDE (HYPERTONIC)"
            },
            "AKORN": {
              "SODIUM CHLORIDE (HYPERTONIC)": "SODIUM CHLORIDE (HYPERTONIC)"
            },
            "ALTAIRE": {
              "ALTACHLORE": "ALTACHLORE"
            },
            "BAUSCH HEALTH": {
              "MURO 128": "MURO 128"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS SODIUM CHLORIDE": "CVS SODIUM CHLORIDE"
            },
            "HUB PHARMACEUTICALS": {
              "SODIUM CHLORIDE (HYPERTONIC)": "SODIUM CHLORIDE (HYPERTONIC)"
            },
            "MAJOR PHARMACEUTICALS": {
              "SODIUM CHLORIDE (HYPERTONIC)": "SODIUM CHLORIDE (HYPERTONIC)"
            },
            "OCUSOFT": {
              "SOCHLOR": "SOCHLOR",
              "SODIUM CHLORIDE (HYPERTONIC)": "SODIUM CHLORIDE (HYPERTONIC)"
            },
            "RITE AID CORPORATION": {
              "RA OPHTHALMIC": "RA OPHTHALMIC"
            },
            "RUGBY LABORATORIES": {
              "SODIUM CHLORIDE (HYPERTONIC)": "SODIUM CHLORIDE (HYPERTONIC)"
            },
            "WALGREENS": {
              "SODIUM CHLORIDE (HYPERTONIC)": "SODIUM CHLORIDE (HYPERTONIC)"
            }
          },
          "Ophthalmic Irrigation Solutions": {
            "A-S MEDICATION SOLUTIONS": {
              "EYE WASH": "EYE WASH",
              "IRRIGATING EYEWASH": "IRRIGATING EYEWASH"
            },
            "ALCON SURGICAL": {
              "BSS": "BSS",
              "BSS PLUS": "BSS PLUS"
            },
            "ALCON VISION": {
              "EYE STREAM": "EYE STREAM"
            },
            "ALTAIRE": {
              "EYE WASH": "EYE WASH"
            },
            "APOTHECARY PRODUCTS, INC.": {
              "EYE WASH": "EYE WASH"
            },
            "B. BRAUN MEDICAL": {
              "BALANCED SALT": "BALANCED SALT"
            },
            "BAUSCH & LOMB CONS": {
              "COLLYRIUM EYE WASH": "COLLYRIUM EYE WASH",
              "COLLYRIUM FOR FRESH EYES": "COLLYRIUM FOR FRESH EYES",
              "EYE WASH": "EYE WASH"
            },
            "BERGEN BRUNSWIG": {
              "GNP EYE WASH": "GNP EYE WASH"
            },
            "CHAIN DRUG CONSORTIUM": {
              "EYE WASH": "EYE WASH"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS EYE WASH": "CVS EYE WASH"
            },
            "CYTOSOL OPHTHALMICS": {
              "BALANCED SALT": "BALANCED SALT"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "EYE WASH": "EYE WASH"
            },
            "HOSPIRA": {
              "BALANCED SALT": "BALANCED SALT"
            },
            "J & J MEDICAL": {
              "EYEAID IRRIGATING": "EYEAID IRRIGATING"
            },
            "LEADER BRAND PRODUCTS": {
              "EYE WASH": "EYE WASH"
            },
            "MAJOR PHARMACEUTICALS": {
              "EYE WASH": "EYE WASH"
            },
            "MCKESSON SUNMARK": {
              "SM EYE WASH": "SM EYE WASH"
            },
            "MEDIQUE PRODUCTS": {
              "MEDIWASH EYE IRRIGANT": "MEDIWASH EYE IRRIGANT"
            },
            "NUCARE PHARMACEUTICALS": {
              "EYE WASH": "EYE WASH"
            },
            "OCUSOFT": {
              "OCUSOFT EYE WASH": "OCUSOFT EYE WASH",
              "OCUSOFT IRRIGATING II": "OCUSOFT IRRIGATING II"
            },
            "PREFERRED PHARMACEUTICALS": {
              "EYE IRRIGATING": "EYE IRRIGATING"
            },
            "QUALITY CARE": {
              "EYE WASH": "EYE WASH"
            },
            "RITE AID CORPORATION": {
              "RA STERILE EYE WASH": "RA STERILE EYE WASH"
            },
            "RUGBY LABORATORIES": {
              "EYE IRRIGATING": "EYE IRRIGATING",
              "EYE WASH": "EYE WASH"
            },
            "WALGREENS": {
              "EYE WASH": "EYE WASH"
            }
          },
          "Ophthalmic Nonsteroidal Anti-inflammatory Agents": {
            "A-S MEDICATION SOLUTIONS": {
              "ACULAR": "ACULAR",
              "ACUVAIL": "ACUVAIL",
              "BROMFENAC SODIUM": "BROMFENAC SODIUM",
              "DICLOFENAC SODIUM": "DICLOFENAC SODIUM",
              "FLURBIPROFEN SODIUM": "FLURBIPROFEN SODIUM",
              "ILEVRO": "ILEVRO",
              "KETOROLAC TROMETHAMINE": "KETOROLAC TROMETHAMINE",
              "NEVANAC": "NEVANAC",
              "PROLENSA": "PROLENSA"
            },
            "AIDAREX PHARMACEUTICALS": {
              "DICLOFENAC SODIUM": "DICLOFENAC SODIUM",
              "KETOROLAC TROMETHAMINE": "KETOROLAC TROMETHAMINE"
            },
            "AKORN": {
              "DICLOFENAC SODIUM": "DICLOFENAC SODIUM",
              "KETOROLAC TROMETHAMINE": "KETOROLAC TROMETHAMINE"
            },
            "ALEMBIC PHARMACEUTICALS": {
              "BROMFENAC SODIUM (ONCE-DAILY)": "BROMFENAC SODIUM (ONCE-DAILY)"
            },
            "ALLERGAN": {
              "ACULAR": "ACULAR",
              "ACULAR LS": "ACULAR LS",
              "ACUVAIL": "ACUVAIL",
              "OCUFEN": "OCUFEN"
            },
            "AMICI PHARMACEUTICALS": {
              "FLURBIPROFEN SODIUM": "FLURBIPROFEN SODIUM"
            },
            "APOTEX": {
              "BROMFENAC SODIUM": "BROMFENAC SODIUM",
              "BROMFENAC SODIUM (ONCE-DAILY)": "BROMFENAC SODIUM (ONCE-DAILY)",
              "KETOROLAC TROMETHAMINE": "KETOROLAC TROMETHAMINE"
            },
            "BAUSCH HEALTH": {
              "DICLOFENAC SODIUM": "DICLOFENAC SODIUM",
              "FLURBIPROFEN SODIUM": "FLURBIPROFEN SODIUM",
              "PROLENSA": "PROLENSA"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "BROMFENAC SODIUM": "BROMFENAC SODIUM",
              "ILEVRO": "ILEVRO",
              "NEVANAC": "NEVANAC",
              "PROLENSA": "PROLENSA"
            },
            "DIRECT RX": {
              "KETOROLAC TROMETHAMINE": "KETOROLAC TROMETHAMINE"
            },
            "FOSUN PHARMA USA": {
              "BROMFENAC SODIUM (ONCE-DAILY)": "BROMFENAC SODIUM (ONCE-DAILY)"
            },
            "GREENSTONE": {
              "FLURBIPROFEN SODIUM": "FLURBIPROFEN SODIUM",
              "KETOROLAC TROMETHAMINE": "KETOROLAC TROMETHAMINE"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "ACULAR": "ACULAR"
            },
            "HI-TECH": {
              "BROMFENAC SODIUM (ONCE-DAILY)": "BROMFENAC SODIUM (ONCE-DAILY)"
            },
            "MYLAN": {
              "BROMFENAC SODIUM": "BROMFENAC SODIUM"
            },
            "NOVARTIS": {
              "ILEVRO": "ILEVRO",
              "NEVANAC": "NEVANAC"
            },
            "PACK PHARMACEUTICALS, LLC": {
              "DICLOFENAC SODIUM": "DICLOFENAC SODIUM"
            },
            "PHARMEDIX": {
              "DICLOFENAC SODIUM": "DICLOFENAC SODIUM"
            },
            "PREFERRED PHARMACEUTICALS": {
              "KETOROLAC TROMETHAMINE": "KETOROLAC TROMETHAMINE"
            },
            "PROFICIENT RX": {
              "FLURBIPROFEN SODIUM": "FLURBIPROFEN SODIUM",
              "KETOROLAC TROMETHAMINE": "KETOROLAC TROMETHAMINE"
            },
            "QUALITY CARE": {
              "ACULAR": "ACULAR",
              "DICLOFENAC SODIUM": "DICLOFENAC SODIUM",
              "KETOROLAC TROMETHAMINE": "KETOROLAC TROMETHAMINE"
            },
            "SANDOZ": {
              "DICLOFENAC SODIUM": "DICLOFENAC SODIUM",
              "KETOROLAC TROMETHAMINE": "KETOROLAC TROMETHAMINE"
            },
            "SUN PHARMACEUTICAL": {
              "KETOROLAC TROMETHAMINE": "KETOROLAC TROMETHAMINE"
            },
            "SUN PHARMACEUTICALS": {
              "BROMSITE": "BROMSITE",
              "KETOROLAC TROMETHAMINE": "KETOROLAC TROMETHAMINE"
            },
            "UNIT DOSE SERVICES": {
              "KETOROLAC TROMETHAMINE": "KETOROLAC TROMETHAMINE"
            }
          },
          "Ophthalmics - Blepharoptosis Agent": {
            "RVL PHARMACEUTICALS": {
              "UPNEEQ": "UPNEEQ"
            }
          },
          "Ophthalmics - Cystinosis Agent": {
            "LEADIANT BIOSCIENCES": {
              "CYSTARAN": "CYSTARAN"
            },
            "RECORDATI RARE DISEASES": {
              "CYSTADROPS": "CYSTADROPS"
            }
          },
          "Ophthalmics Misc. - Other": {
            "IMPRIMIS NJOF": {
              "CHONDROITIN SULFATE": "CHONDROITIN SULFATE"
            },
            "SCHERER": {
              "EYE-SED": "EYE-SED"
            }
          }
        },
        "Prostaglandins - Ophthalmic": {
          "Prostaglandins - Ophthalmic": {
            "A-S MEDICATION SOLUTIONS": {
              "LATANOPROST": "LATANOPROST",
              "LUMIGAN": "LUMIGAN",
              "TRAVATAN Z": "TRAVATAN Z",
              "ZIOPTAN": "ZIOPTAN"
            },
            "AKORN": {
              "LATANOPROST": "LATANOPROST",
              "ZIOPTAN": "ZIOPTAN"
            },
            "ALEMBIC PHARMACEUTICALS": {
              "BIMATOPROST": "BIMATOPROST"
            },
            "ALLERGAN": {
              "DURYSTA": "DURYSTA",
              "LUMIGAN": "LUMIGAN"
            },
            "AMERICAN REGENT": {
              "LATANOPROST": "LATANOPROST"
            },
            "APOTEX": {
              "BIMATOPROST": "BIMATOPROST",
              "TRAVOPROST (BAK FREE)": "TRAVOPROST (BAK FREE)"
            },
            "AUROBINDO PHARMA": {
              "LATANOPROST": "LATANOPROST"
            },
            "BAUSCH HEALTH": {
              "LATANOPROST": "LATANOPROST",
              "VYZULTA": "VYZULTA"
            },
            "FOSUN PHARMA USA": {
              "BIMATOPROST": "BIMATOPROST"
            },
            "GREENSTONE": {
              "LATANOPROST": "LATANOPROST"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "XALATAN": "XALATAN"
            },
            "IMPRIMIS NJOF": {
              "LATANOPROST": "LATANOPROST"
            },
            "LUPIN PHARMACEUTICALS": {
              "BIMATOPROST": "BIMATOPROST"
            },
            "MYLAN": {
              "TRAVOPROST (BAK FREE)": "TRAVOPROST (BAK FREE)"
            },
            "NOVARTIS": {
              "TRAVATAN Z": "TRAVATAN Z"
            },
            "PAR PHARMACEUTICAL": {
              "TRAVOPROST": "TRAVOPROST"
            },
            "PFIZER U.S.": {
              "XALATAN": "XALATAN"
            },
            "PROFICIENT RX": {
              "LATANOPROST": "LATANOPROST"
            },
            "QUALITY CARE": {
              "XALATAN": "XALATAN"
            },
            "RISING PHARMACEUTICALS": {
              "LATANOPROST": "LATANOPROST"
            },
            "SANDOZ": {
              "LATANOPROST": "LATANOPROST",
              "TRAVOPROST (BAK FREE)": "TRAVOPROST (BAK FREE)"
            },
            "SOMERSET THERAPEUTICS": {
              "BIMATOPROST": "BIMATOPROST",
              "LATANOPROST": "LATANOPROST"
            },
            "SUCAMPO PHARMACEUTICALS, INC": {
              "RESCULA": "RESCULA"
            },
            "SUN PHARMACEUTICALS": {
              "XELPROS": "XELPROS"
            }
          }
        }
      },
      "OTIC AGENTS": {
        "Otic Agents - Miscellaneous": {
          "Otic Agents - Miscellaneous": {
            "A-S MEDICATION SOLUTIONS": {
              "ACETIC ACID": "ACETIC ACID",
              "EAR DROPS": "EAR DROPS",
              "EARWAX TREATMENT DROPS": "EARWAX TREATMENT DROPS"
            },
            "AIDAREX PHARMACEUTICALS": {
              "EAR DROPS EARWAX AID": "EAR DROPS EARWAX AID",
              "SWIM EAR": "SWIM EAR"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP EAR DROPS": "GNP EAR DROPS",
              "GNP EARWAX REMOVAL DROPS": "GNP EARWAX REMOVAL DROPS",
              "GNP EARWAX REMOVAL KIT": "GNP EARWAX REMOVAL KIT"
            },
            "APOTHECARY PRODUCTS, INC.": {
              "EAR WAX REMOVAL SYSTEM": "EAR WAX REMOVAL SYSTEM"
            },
            "BAUSCH HEALTH": {
              "ACETIC ACID-ALUMINUM ACETATE": "ACETIC ACID-ALUMINUM ACETATE"
            },
            "BERGEN BRUNSWIG": {
              "GNP EAR DROPS": "GNP EAR DROPS",
              "GNP EAR SYSTEMS": "GNP EAR SYSTEMS"
            },
            "CHAIN DRUG CONSORTIUM": {
              "EAR DRY": "EAR DRY",
              "EAR WAX REMOVAL AID": "EAR WAX REMOVAL AID",
              "EAR WAX REMOVAL SYSTEM": "EAR WAX REMOVAL SYSTEM"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC EAR WAX REMOVAL": "QC EAR WAX REMOVAL",
              "QC EARWAX REMOVAL": "QC EARWAX REMOVAL",
              "QC EARWAX REMOVAL KIT": "QC EARWAX REMOVAL KIT"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS EAR DROPS": "CVS EAR DROPS",
              "CVS EAR WAX CLEANSING SYSTEM": "CVS EAR WAX CLEANSING SYSTEM",
              "CVS EAR WAX REMOVAL SYSTEM": "CVS EAR WAX REMOVAL SYSTEM",
              "CVS EARWAX REMOVAL KIT": "CVS EARWAX REMOVAL KIT",
              "CVS SWIMMERS EAR DROPS": "CVS SWIMMERS EAR DROPS"
            },
            "EQUALINE": {
              "EQL EAR DROPS": "EQL EAR DROPS"
            },
            "FOUGERA": {
              "SWIM EAR": "SWIM EAR"
            },
            "GEISS DESTIN & DUNN": {
              "GOODSENSE EAR WAX KIT": "GOODSENSE EAR WAX KIT",
              "GOODSENSE EAR WAX REMOVAL": "GOODSENSE EAR WAX REMOVAL"
            },
            "GERI-CARE": {
              "EAR DROPS EARWAX AID": "EAR DROPS EARWAX AID"
            },
            "GERITREX CORPORATION": {
              "MIN-O-EAR": "MIN-O-EAR"
            },
            "HI-TECH": {
              "ACETIC ACID": "ACETIC ACID"
            },
            "HUMCO": {
              "SWIMMERS EAR DROPS": "SWIMMERS EAR DROPS"
            },
            "LEADER BRAND PRODUCTS": {
              "EAR DRY": "EAR DRY",
              "EAR WAX CLEANSING": "EAR WAX CLEANSING",
              "EAR WAX REMOVAL DROPS": "EAR WAX REMOVAL DROPS",
              "EAR WAX REMOVAL KIT": "EAR WAX REMOVAL KIT",
              "EARWAX REMOVAL": "EARWAX REMOVAL"
            },
            "MAJOR PHARMACEUTICALS": {
              "EAR DROPS": "EAR DROPS"
            },
            "MCKESSON": {
              "HM EARWAX REMOVAL AID": "HM EARWAX REMOVAL AID",
              "HM EARWAX REMOVAL KIT": "HM EARWAX REMOVAL KIT"
            },
            "MCKESSON SUNMARK": {
              "SM EAR DROPS": "SM EAR DROPS",
              "SM SWIMMERS EAR DROPS": "SM SWIMMERS EAR DROPS"
            },
            "MEDICINE SHOPPE": {
              "EAR DROPS": "EAR DROPS",
              "EAR WAX REMOVAL SYSTEM": "EAR WAX REMOVAL SYSTEM"
            },
            "MEDTECH": {
              "DEBROX": "DEBROX",
              "MURINE EAR": "MURINE EAR",
              "MURINE EAR WAX REMOVAL SYSTEM": "MURINE EAR WAX REMOVAL SYSTEM"
            },
            "MORTON GROVE PHARMACEUTICALS": {
              "ACETIC ACID": "ACETIC ACID"
            },
            "NEILMED PHARMACEUTICALS": {
              "CLEARCANAL EAR WAX REMOVAL": "CLEARCANAL EAR WAX REMOVAL",
              "CLEARCANAL EARWAX SOFTENER": "CLEARCANAL EARWAX SOFTENER"
            },
            "NUCARE PHARMACEUTICALS": {
              "EAR WAX DROPS": "EAR WAX DROPS"
            },
            "PAR PHARMACEUTICALS": {
              "ACETIC ACID": "ACETIC ACID"
            },
            "PREFERRED PHARMACEUTICALS": {
              "ACETIC ACID": "ACETIC ACID",
              "EARWAX TREATMENT DROPS": "EARWAX TREATMENT DROPS"
            },
            "PROFICIENT RX": {
              "EARWAX REMOVAL": "EARWAX REMOVAL",
              "EARWAX REMOVAL AID": "EARWAX REMOVAL AID"
            },
            "QUALITY CARE": {
              "EAR WAX DROPS": "EAR WAX DROPS"
            },
            "QUALITY CARE PRODUCTS": {
              "ACETIC ACID": "ACETIC ACID"
            },
            "QUEST PRODUCTS": {
              "CLINERE EARWAX REMOVAL KIT": "CLINERE EARWAX REMOVAL KIT"
            },
            "REESE PHARMACEUTICAL": {
              "AURAPHENE-B": "AURAPHENE-B"
            },
            "RISING PHARMACEUTICALS": {
              "ACETIC ACID": "ACETIC ACID"
            },
            "RITE AID CORPORATION": {
              "RA EAR DROPS": "RA EAR DROPS",
              "RA EAR DRYING AGENT": "RA EAR DRYING AGENT",
              "RA EAR WAX CLEANSING SYSTEM": "RA EAR WAX CLEANSING SYSTEM",
              "RA EARWAX REMOVAL KIT": "RA EARWAX REMOVAL KIT"
            },
            "RUGBY LABORATORIES": {
              "EARWAX TREATMENT DROPS": "EARWAX TREATMENT DROPS"
            },
            "SCHERER": {
              "E-R-O EAR DROPS": "E-R-O EAR DROPS",
              "E-R-O EAR WAX REMOVAL SYSTEM": "E-R-O EAR WAX REMOVAL SYSTEM"
            },
            "SELECT BRAND": {
              "SB EAR WAX REMOVER": "SB EAR WAX REMOVER"
            },
            "SHEFFIELD PHARMACEUTICALS": {
              "EAR WAX REMOVAL DROPS": "EAR WAX REMOVAL DROPS",
              "OTIX": "OTIX"
            },
            "TOPCO": {
              "EARWAX REMOVAL": "EARWAX REMOVAL",
              "EARWAX REMOVAL KIT": "EARWAX REMOVAL KIT"
            },
            "TRUPHARMA": {
              "ACETIC ACID": "ACETIC ACID"
            },
            "WAL-MART": {
              "EQ EAR DROPS": "EQ EAR DROPS",
              "EQ EAR WAX REMOVAL AID": "EQ EAR WAX REMOVAL AID",
              "SWIMMERS INSTANT EAR DRY": "SWIMMERS INSTANT EAR DRY"
            },
            "WALGREENS": {
              "EAR DROPS": "EAR DROPS",
              "EAR WAX CLEANSING": "EAR WAX CLEANSING",
              "EAR WAX REMOVAL DROPS": "EAR WAX REMOVAL DROPS",
              "EAR WAX REMOVAL KIT": "EAR WAX REMOVAL KIT",
              "INSTANT EAR-DRY": "INSTANT EAR-DRY"
            }
          }
        },
        "Otic Anti-infectives": {
          "Otic Anti-infectives": {
            "A-S MEDICATION SOLUTIONS": {
              "OFLOXACIN": "OFLOXACIN"
            },
            "AIDAREX PHARMACEUTICALS": {
              "OFLOXACIN": "OFLOXACIN"
            },
            "ALMATICA": {
              "FLOXIN OTIC": "FLOXIN OTIC"
            },
            "AMNEAL PHARMACEUTICALS": {
              "OFLOXACIN": "OFLOXACIN"
            },
            "APOTEX": {
              "OFLOXACIN": "OFLOXACIN"
            },
            "BAUSCH HEALTH": {
              "OFLOXACIN": "OFLOXACIN"
            },
            "DIRECT RX": {
              "OFLOXACIN": "OFLOXACIN"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "OFLOXACIN": "OFLOXACIN"
            },
            "HI-TECH": {
              "OFLOXACIN": "OFLOXACIN"
            },
            "OTONOMY": {
              "OTIPRIO": "OTIPRIO"
            },
            "PHARMEDIX": {
              "OFLOXACIN": "OFLOXACIN"
            },
            "QUALITY CARE PRODUCTS": {
              "OFLOXACIN": "OFLOXACIN"
            },
            "SANDOZ": {
              "OFLOXACIN": "OFLOXACIN"
            },
            "WRASER PHARMACEUTICALS": {
              "CETRAXAL": "CETRAXAL"
            },
            "XSPIRE PHARMA": {
              "CIPROFLOXACIN HCL": "CIPROFLOXACIN HCL"
            }
          }
        },
        "Otic Combinations": {
          "Otic Analgesic Combinations": {
            "AG MARIN PHARMACEUTICALS": {
              "EXOTIC-HC": "EXOTIC-HC",
              "OTOMAX-HC": "OTOMAX-HC"
            },
            "BLANSETT PHARMACAL": {
              "CORTANE-B": "CORTANE-B",
              "CORTANE-B AQUEOUS": "CORTANE-B AQUEOUS"
            },
            "CYPRESS PHARMACEUTICAL": {
              "CYOTIC": "CYOTIC"
            },
            "EXELTIS USA": {
              "CORTIC-ND": "CORTIC-ND"
            },
            "HAWTHORN PHARMACEUTICALS": {
              "PRAMOTIC": "PRAMOTIC"
            },
            "TERAL": {
              "OTICIN HC NR": "OTICIN HC NR"
            }
          },
          "Otic Antifungal Combinations": {
            "PFEIFFER": {
              "DRI-EAR": "DRI-EAR"
            },
            "SCHERER": {
              "EAR DRY": "EAR DRY"
            }
          },
          "Otic Steroid-Anti-infective Combinations": {
            "A-S MEDICATION SOLUTIONS": {
              "CIPRO HC": "CIPRO HC",
              "CIPRODEX": "CIPRODEX",
              "NEOMYCIN-POLYMYXIN-HC": "NEOMYCIN-POLYMYXIN-HC"
            },
            "AIDAREX PHARMACEUTICALS": {
              "CIPRODEX": "CIPRODEX",
              "NEOMYCIN-POLYMYXIN-HC": "NEOMYCIN-POLYMYXIN-HC"
            },
            "ARBOR PHARMACEUTICALS": {
              "OTOVEL": "OTOVEL"
            },
            "BAUSCH HEALTH": {
              "NEOMYCIN-POLYMYXIN-HC": "NEOMYCIN-POLYMYXIN-HC"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "CIPRODEX": "CIPRODEX",
              "NEOMYCIN-POLYMYXIN-HC": "NEOMYCIN-POLYMYXIN-HC"
            },
            "DIRECT RX": {
              "NEOMYCIN-POLYMYXIN-HC": "NEOMYCIN-POLYMYXIN-HC"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "CIPROFLOXACIN-DEXAMETHASONE": "CIPROFLOXACIN-DEXAMETHASONE"
            },
            "ENDO PHARMACEUTICALS": {
              "COLY-MYCIN S": "COLY-MYCIN S",
              "CORTISPORIN-TC": "CORTISPORIN-TC"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "CIPRO HC": "CIPRO HC",
              "NEOMYCIN-POLYMYXIN-HC": "NEOMYCIN-POLYMYXIN-HC"
            },
            "MEDVANTX": {
              "NEOMYCIN-POLYMYXIN-HC": "NEOMYCIN-POLYMYXIN-HC"
            },
            "NOVARTIS": {
              "CIPRO HC": "CIPRO HC",
              "CIPRODEX": "CIPRODEX"
            },
            "NUCARE PHARMACEUTICALS": {
              "NEOMYCIN-POLYMYXIN-HC": "NEOMYCIN-POLYMYXIN-HC"
            },
            "PAR STERILE PRODUCTS": {
              "COLY-MYCIN S": "COLY-MYCIN S",
              "CORTISPORIN-TC": "CORTISPORIN-TC"
            },
            "PHARMEDIX": {
              "NEOMYCIN-POLYMYXIN-HC": "NEOMYCIN-POLYMYXIN-HC"
            },
            "PREFERRED PHARMACEUTICALS": {
              "NEOMYCIN-POLYMYXIN-HC": "NEOMYCIN-POLYMYXIN-HC"
            },
            "PROFICIENT RX": {
              "CIPRODEX": "CIPRODEX"
            },
            "QUALITY CARE": {
              "ANTIBIOTIC EAR": "ANTIBIOTIC EAR",
              "CIPRO HC": "CIPRO HC",
              "CORTISPORIN": "CORTISPORIN",
              "NEOMYCIN-POLYMYXIN-HC": "NEOMYCIN-POLYMYXIN-HC"
            },
            "QUALITY CARE PRODUCTS": {
              "NEOMYCIN-POLYMYXIN-HC": "NEOMYCIN-POLYMYXIN-HC"
            },
            "RISING PHARMACEUTICALS": {
              "NEOMYCIN-POLYMYXIN-HC": "NEOMYCIN-POLYMYXIN-HC"
            },
            "SANDOZ": {
              "CIPROFLOXACIN-DEXAMETHASONE": "CIPROFLOXACIN-DEXAMETHASONE",
              "NEOMYCIN-POLYMYXIN-HC": "NEOMYCIN-POLYMYXIN-HC"
            },
            "UNIT DOSE SERVICES": {
              "NEOMYCIN-POLYMYXIN-HC": "NEOMYCIN-POLYMYXIN-HC"
            },
            "WILSHIRE PHARMACEUTICALS": {
              "CIPROFLOXACIN-FLUOCINOLONE PF": "CIPROFLOXACIN-FLUOCINOLONE PF"
            }
          }
        },
        "Otic Steroids": {
          "Otic Steroids": {
            "ACTAVIS": {
              "HYDROCORTISONE-ACETIC ACID": "HYDROCORTISONE-ACETIC ACID"
            },
            "ACTAVIS MID ATLANTIC": {
              "ACETASOL HC": "ACETASOL HC"
            },
            "AMNEAL PHARMACEUTICALS": {
              "FLUOCINOLONE ACETONIDE": "FLUOCINOLONE ACETONIDE"
            },
            "AVKARE": {
              "FLUOCINOLONE ACETONIDE": "FLUOCINOLONE ACETONIDE"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "FLUOCINOLONE ACETONIDE": "FLUOCINOLONE ACETONIDE"
            },
            "GLENMARK PHARMACEUTICALS": {
              "FLUOCINOLONE ACETONIDE": "FLUOCINOLONE ACETONIDE"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "ACETASOL HC": "ACETASOL HC"
            },
            "HI-TECH": {
              "HYDROCORTISONE-ACETIC ACID": "HYDROCORTISONE-ACETIC ACID"
            },
            "NUCARE PHARMACEUTICALS": {
              "ACETASOL HC": "ACETASOL HC"
            },
            "PATRIN PHARMA": {
              "FLAC": "FLAC"
            },
            "PERRIGO PHARMACEUTICALS": {
              "FLUOCINOLONE ACETONIDE": "FLUOCINOLONE ACETONIDE"
            },
            "RISING PHARMACEUTICALS": {
              "FLUOCINOLONE ACETONIDE": "FLUOCINOLONE ACETONIDE"
            },
            "ROYAL PHARMACEUTICALS": {
              "DERMOTIC": "DERMOTIC"
            },
            "SETON PHARMACEUTICALS": {
              "FLUOCINOLONE ACETONIDE": "FLUOCINOLONE ACETONIDE"
            },
            "TARO": {
              "HYDROCORTISONE-ACETIC ACID": "HYDROCORTISONE-ACETIC ACID"
            }
          }
        }
      },
      "OXYTOCICS": {
        "Abortifacients/Agents for Cervical Ripening": {
          "Abortifacients/Cervical Ripening - Prostaglandins": {
            "DR.REDDY'S LABORATORIES, INC.": {
              "CARBOPROST TROMETHAMINE": "CARBOPROST TROMETHAMINE"
            },
            "FERRING": {
              "CERVIDIL": "CERVIDIL"
            },
            "NOVAPLUS/DR.REDDY'S": {
              "CARBOPROST TROMETHAMINE": "CARBOPROST TROMETHAMINE"
            },
            "PFIZER U.S.": {
              "HEMABATE": "HEMABATE",
              "PREPIDIL": "PREPIDIL",
              "PROSTIN E2": "PROSTIN E2"
            }
          }
        },
        "Oxytocics": {
          "Oxytocics": {
            "A-S MEDICATION SOLUTIONS": {
              "PITOCIN": "PITOCIN"
            },
            "AKORN": {
              "METHYLERGONOVINE MALEATE": "METHYLERGONOVINE MALEATE"
            },
            "AMERICAN HEALTH PACKAGING": {
              "METHYLERGONOVINE MALEATE": "METHYLERGONOVINE MALEATE"
            },
            "AMERICAN REGENT": {
              "METHYLERGONOVINE MALEATE": "METHYLERGONOVINE MALEATE"
            },
            "AMNEAL PHARMACEUTICALS": {
              "METHYLERGONOVINE MALEATE": "METHYLERGONOVINE MALEATE"
            },
            "BRECKENRIDGE": {
              "METHYLERGONOVINE MALEATE": "METHYLERGONOVINE MALEATE"
            },
            "CENTRAL ADMIXTURE PHARMACY SER": {
              "OXYTOCIN-SODIUM CHLORIDE": "OXYTOCIN-SODIUM CHLORIDE"
            },
            "FRESENIUS KABI USA": {
              "OXYTOCIN": "OXYTOCIN"
            },
            "HIKMA": {
              "METHYLERGONOVINE MALEATE": "METHYLERGONOVINE MALEATE",
              "OXYTOCIN": "OXYTOCIN"
            },
            "LUPIN PHARMACEUTICALS": {
              "METHERGINE": "METHERGINE",
              "METHYLERGONOVINE MALEATE": "METHYLERGONOVINE MALEATE"
            },
            "NOVAPLUS/FRESENIUS KABI": {
              "OXYTOCIN": "OXYTOCIN"
            },
            "PAR STERILE PRODUCTS": {
              "PITOCIN": "PITOCIN"
            },
            "PDRX PHARMACEUTICAL": {
              "METHYLERGONOVINE MALEATE": "METHYLERGONOVINE MALEATE",
              "OXYTOCIN": "OXYTOCIN"
            },
            "PHARMACIST PHARMACEUTICAL LLC": {
              "METHYLERGONOVINE MALEATE": "METHYLERGONOVINE MALEATE"
            },
            "PHARMEDIUM SERVICES": {
              "OXYTOCIN-DEXTROSE": "OXYTOCIN-DEXTROSE",
              "OXYTOCIN-DEXTROSE-LACT RINGERS": "OXYTOCIN-DEXTROSE-LACT RINGERS",
              "OXYTOCIN-LACTATED RINGERS": "OXYTOCIN-LACTATED RINGERS",
              "OXYTOCIN-SODIUM CHLORIDE": "OXYTOCIN-SODIUM CHLORIDE"
            },
            "QUVA PHARMA": {
              "OXYTOCIN-LACTATED RINGERS": "OXYTOCIN-LACTATED RINGERS",
              "OXYTOCIN-SODIUM CHLORIDE": "OXYTOCIN-SODIUM CHLORIDE"
            },
            "SCA PHARMACEUTICALS": {
              "OXYTOCIN-SODIUM CHLORIDE": "OXYTOCIN-SODIUM CHLORIDE"
            },
            "TEVA PHARMACEUTICALS USA": {
              "METHYLERGONOVINE MALEATE": "METHYLERGONOVINE MALEATE"
            }
          }
        }
      },
      "PASSIVE IMMUNIZING AND TREATMENT AGENTS": {
        "Antitoxins-Antivenins": {
          "Antitoxins-Antivenins": {
            "BTG INTERNATIONAL": {
              "CROFAB": "CROFAB"
            },
            "MERCK SHARP & DOHME": {
              "ANTIVENIN LATRODECTUS MACTANS": "ANTIVENIN LATRODECTUS MACTANS"
            },
            "PFIZER U.S.": {
              "ANTIVENIN MICRURUS FULVIUS": "ANTIVENIN MICRURUS FULVIUS"
            },
            "RARE DISEASE THERAPEUTICS, INC": {
              "ANASCORP": "ANASCORP",
              "ANAVIP": "ANAVIP"
            }
          }
        },
        "Immune Serums": {
          "Immune Serums": {
            "A-S MEDICATION SOLUTIONS": {
              "GAMASTAN S/D": "GAMASTAN S/D",
              "HYPERRHO S/D": "HYPERRHO S/D",
              "NABI-HB": "NABI-HB"
            },
            "ADMA BIOLOGICS": {
              "ASCENIV": "ASCENIV",
              "BIVIGAM": "BIVIGAM",
              "NABI-HB": "NABI-HB"
            },
            "BAXALTA": {
              "CUVITRU": "CUVITRU",
              "GAMMAGARD": "GAMMAGARD",
              "GAMMAGARD S/D LESS IGA": "GAMMAGARD S/D LESS IGA",
              "WINRHO SDF": "WINRHO SDF"
            },
            "BIO PRODUCTS LABORATORY": {
              "GAMMAPLEX": "GAMMAPLEX"
            },
            "BIOTEST PHARMACEUTICALS": {
              "BIVIGAM": "BIVIGAM"
            },
            "CSL BEHRING": {
              "CARIMUNE NF": "CARIMUNE NF",
              "HIZENTRA": "HIZENTRA",
              "PRIVIGEN": "PRIVIGEN",
              "RHOPHYLAC": "RHOPHYLAC"
            },
            "EMERGENT BIOSOLUTIONS": {
              "HEPAGAM B": "HEPAGAM B",
              "VARIZIG": "VARIZIG",
              "WINRHO SDF": "WINRHO SDF"
            },
            "GRIFOLS USA": {
              "FLEBOGAMMA DIF": "FLEBOGAMMA DIF",
              "GAMASTAN": "GAMASTAN",
              "GAMUNEX-C": "GAMUNEX-C",
              "HYPERHEP B S/D": "HYPERHEP B S/D",
              "HYPERRAB": "HYPERRAB",
              "HYPERRAB S/D": "HYPERRAB S/D",
              "HYPERRHO S/D": "HYPERRHO S/D",
              "HYPERTET S/D": "HYPERTET S/D",
              "XEMBIFY": "XEMBIFY"
            },
            "KEDRION BIOPHARMA": {
              "GAMMAKED": "GAMMAKED",
              "KEDRAB": "KEDRAB",
              "MICRHOGAM ULTRA-FILTERED PLUS": "MICRHOGAM ULTRA-FILTERED PLUS",
              "RHOGAM ULTRA-FILTERED PLUS": "RHOGAM ULTRA-FILTERED PLUS"
            },
            "NOVAPLUS/CANGENE BIOPHARMA": {
              "HEPAGAM B": "HEPAGAM B"
            },
            "NOVAPLUS/SAOL THERAPEUTICS": {
              "HEPAGAM B": "HEPAGAM B"
            },
            "OCTAPHARMA USA": {
              "CUTAQUIG": "CUTAQUIG",
              "OCTAGAM": "OCTAGAM",
              "PANZYGA": "PANZYGA"
            },
            "PFIZER U.S.": {
              "CUTAQUIG": "CUTAQUIG",
              "PANZYGA": "PANZYGA"
            },
            "QUALITY CARE": {
              "NABI-HB": "NABI-HB"
            },
            "SANOFI PASTEUR": {
              "IMOGAM RABIES-HT": "IMOGAM RABIES-HT"
            },
            "SAOL THERAPEUTICS": {
              "CYTOGAM": "CYTOGAM",
              "HEPAGAM B": "HEPAGAM B",
              "VARIZIG": "VARIZIG",
              "WINRHO SDF": "WINRHO SDF"
            }
          }
        },
        "Monoclonal Antibodies": {
          "Antiviral Monoclonal Antibodies": {
            "SOBI": {
              "SYNAGIS": "SYNAGIS"
            }
          },
          "Bacterial Monoclonal Antibodies": {
            "MERCK SHARP & DOHME": {
              "ZINPLAVA": "ZINPLAVA"
            }
          }
        },
        "Passive Immunizing Agents - Combinations": {
          "Passive Immunizing Agents - Combinations": {
            "BAXALTA": {
              "HYQVIA": "HYQVIA"
            }
          }
        }
      },
      "PENICILLINS": {
        "Aminopenicillins": {
          "Aminopenicillins": {
            "A-S MEDICATION SOLUTIONS": {
              "AMOXICILLIN": "AMOXICILLIN",
              "AMPICILLIN": "AMPICILLIN"
            },
            "AIDAREX PHARMACEUTICALS": {
              "AMOXICILLIN": "AMOXICILLIN"
            },
            "ALTURA PHARMACEUTICALS": {
              "AMOXICILLIN": "AMOXICILLIN",
              "AMPICILLIN": "AMPICILLIN"
            },
            "AMERICAN ANTIBIOTICS": {
              "AMOXICILLIN": "AMOXICILLIN"
            },
            "AMERICAN HEALTH PACKAGING": {
              "AMOXICILLIN": "AMOXICILLIN"
            },
            "ATHENEX PHARMACEUTICAL": {
              "AMPICILLIN SODIUM": "AMPICILLIN SODIUM"
            },
            "AUROBINDO PHARMA": {
              "AMOXICILLIN": "AMOXICILLIN"
            },
            "AUROMEDICS PHARMA": {
              "AMPICILLIN SODIUM": "AMPICILLIN SODIUM"
            },
            "AVKARE": {
              "AMOXICILLIN": "AMOXICILLIN"
            },
            "BAXTER HEALTHCARE CORP": {
              "AMPICILLIN SODIUM": "AMPICILLIN SODIUM"
            },
            "BI COASTAL PHARMACEUTICAL": {
              "AMOXICILLIN": "AMOXICILLIN"
            },
            "BLENHEIM PHARMACAL": {
              "AMOXICILLIN": "AMOXICILLIN",
              "AMPICILLIN": "AMPICILLIN"
            },
            "BRYANT RANCH PREPACK": {
              "AMOXICILLIN": "AMOXICILLIN",
              "AMPICILLIN": "AMPICILLIN"
            },
            "CAMBRIDGE THERAPEUTICS TECHN": {
              "AMOXICILLIN": "AMOXICILLIN"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "AMOXICILLIN": "AMOXICILLIN"
            },
            "DENTON PHARMA": {
              "AMOXICILLIN": "AMOXICILLIN",
              "AMPICILLIN": "AMPICILLIN"
            },
            "DIRECT RX": {
              "AMOXICILLIN": "AMOXICILLIN"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "AMOXICILLIN": "AMOXICILLIN"
            },
            "FERA PHARMACEUTICALS": {
              "AMOXICILLIN ER": "AMOXICILLIN ER"
            },
            "FRESENIUS KABI USA": {
              "AMPICILLIN SODIUM": "AMPICILLIN SODIUM"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "AMOXICILLIN": "AMOXICILLIN",
              "AMPICILLIN": "AMPICILLIN"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "AMOXICILLIN": "AMOXICILLIN",
              "AMPICILLIN": "AMPICILLIN"
            },
            "HIKMA": {
              "AMOXICILLIN": "AMOXICILLIN"
            },
            "HOSPIRA": {
              "AMPICILLIN SODIUM": "AMPICILLIN SODIUM"
            },
            "LETCO MEDICAL": {
              "AMOXICILLIN TRIHYDRATE": "AMOXICILLIN TRIHYDRATE"
            },
            "LIBERTY PHARMACEUTICAL": {
              "AMOXICILLIN": "AMOXICILLIN",
              "AMPICILLIN": "AMPICILLIN"
            },
            "MEDISCA": {
              "AMOXICILLIN TRIHYDRATE": "AMOXICILLIN TRIHYDRATE"
            },
            "MEDVANTX": {
              "AMOXICILLIN": "AMOXICILLIN"
            },
            "METHAPHARM": {
              "AMPICILLIN SODIUM": "AMPICILLIN SODIUM"
            },
            "MYLAN INSTITUTIONAL": {
              "AMPICILLIN SODIUM": "AMPICILLIN SODIUM"
            },
            "NEOPHARMA": {
              "AMOXICILLIN": "AMOXICILLIN"
            },
            "NORTHSTAR RX": {
              "AMOXICILLIN": "AMOXICILLIN"
            },
            "NOVAPLUS/SANDOZ": {
              "AMPICILLIN SODIUM": "AMPICILLIN SODIUM"
            },
            "NUCARE PHARMACEUTICALS": {
              "AMOXICILLIN": "AMOXICILLIN",
              "AMPICILLIN": "AMPICILLIN"
            },
            "PAR PHARMACEUTICAL": {
              "AMOXICILLIN": "AMOXICILLIN",
              "AMPICILLIN": "AMPICILLIN"
            },
            "PDRX PHARMACEUTICAL": {
              "AMOXICILLIN": "AMOXICILLIN",
              "AMPICILLIN": "AMPICILLIN"
            },
            "PDRX PHARMACEUTICALS": {
              "AMOXICILLIN": "AMOXICILLIN"
            },
            "PHARMEDIX": {
              "AMOXICILLIN": "AMOXICILLIN"
            },
            "PHARMPAK": {
              "AMOXICILLIN": "AMOXICILLIN"
            },
            "PIRAMAL CRITICAL CARE": {
              "AMPICILLIN SODIUM": "AMPICILLIN SODIUM"
            },
            "PRAGMA PHARMACEUTICALS": {
              "MOXATAG": "MOXATAG"
            },
            "PREFERRED PHARMACEUTICALS": {
              "AMOXICILLIN": "AMOXICILLIN",
              "AMPICILLIN": "AMPICILLIN"
            },
            "PROFICIENT RX": {
              "AMOXICILLIN": "AMOXICILLIN"
            },
            "QUALITY CARE": {
              "AMOXICILLIN": "AMOXICILLIN",
              "AMPICILLIN": "AMPICILLIN"
            },
            "QUALITY CARE PRODUCTS": {
              "AMOXICILLIN": "AMOXICILLIN"
            },
            "RISING PHARMACEUTICALS": {
              "AMOXICILLIN": "AMOXICILLIN"
            },
            "RXCHANGE CO": {
              "AMOXICILLIN": "AMOXICILLIN",
              "AMPICILLIN": "AMPICILLIN"
            },
            "SAGENT PHARMACEUTICAL": {
              "AMPICILLIN SODIUM": "AMPICILLIN SODIUM"
            },
            "SANDOZ": {
              "AMOXICILLIN": "AMOXICILLIN",
              "AMPICILLIN": "AMPICILLIN",
              "AMPICILLIN SODIUM": "AMPICILLIN SODIUM"
            },
            "TEVA PHARMACEUTICALS USA": {
              "AMOXICILLIN": "AMOXICILLIN"
            },
            "UNIT DOSE SERVICES": {
              "AMOXICILLIN": "AMOXICILLIN"
            },
            "VERNALIS THERAPEUTICS": {
              "MOXATAG": "MOXATAG"
            },
            "VIRTUS PHARMACEUTICALS": {
              "AMOXICILLIN": "AMOXICILLIN"
            },
            "VIRTUS PHARMACEUTICALS OPCO": {
              "AMOXICILLIN": "AMOXICILLIN"
            },
            "WG CRITICAL CARE": {
              "AMPICILLIN SODIUM": "AMPICILLIN SODIUM"
            }
          }
        },
        "Natural Penicillins": {
          "Natural Penicillins": {
            "A-S MEDICATION SOLUTIONS": {
              "BICILLIN L-A": "BICILLIN L-A",
              "PENICILLIN V POTASSIUM": "PENICILLIN V POTASSIUM"
            },
            "AIDAREX PHARMACEUTICALS": {
              "PENICILLIN V POTASSIUM": "PENICILLIN V POTASSIUM"
            },
            "ALTURA PHARMACEUTICALS": {
              "PENICILLIN V POTASSIUM": "PENICILLIN V POTASSIUM"
            },
            "ATHENEX PHARMACEUTICAL": {
              "PENICILLIN G POTASSIUM": "PENICILLIN G POTASSIUM"
            },
            "AUROBINDO PHARMA": {
              "PENICILLIN V POTASSIUM": "PENICILLIN V POTASSIUM"
            },
            "BAXTER HEALTHCARE CORP": {
              "PENICILLIN G POT IN DEXTROSE": "PENICILLIN G POT IN DEXTROSE"
            },
            "BLENHEIM PHARMACAL": {
              "PENICILLIN V POTASSIUM": "PENICILLIN V POTASSIUM"
            },
            "BRYANT RANCH PREPACK": {
              "PENICILLIN V POTASSIUM": "PENICILLIN V POTASSIUM"
            },
            "DENTON PHARMA": {
              "PENICILLIN V POTASSIUM": "PENICILLIN V POTASSIUM"
            },
            "DIRECT RX": {
              "PENICILLIN V POTASSIUM": "PENICILLIN V POTASSIUM"
            },
            "FRESENIUS KABI USA": {
              "PENICILLIN G POTASSIUM": "PENICILLIN G POTASSIUM"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "PENICILLIN V POTASSIUM": "PENICILLIN V POTASSIUM"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "PENICILLIN V POTASSIUM": "PENICILLIN V POTASSIUM"
            },
            "HIKMA": {
              "PENICILLIN V POTASSIUM": "PENICILLIN V POTASSIUM"
            },
            "LIBERTY PHARMACEUTICAL": {
              "PENICILLIN V POTASSIUM": "PENICILLIN V POTASSIUM"
            },
            "MEDVANTX": {
              "PENICILLIN V POTASSIUM": "PENICILLIN V POTASSIUM"
            },
            "NORTHSTAR RX": {
              "PENICILLIN V POTASSIUM": "PENICILLIN V POTASSIUM"
            },
            "NOVAPLUS/PFIZER U.S.": {
              "PFIZERPEN": "PFIZERPEN"
            },
            "NUCARE PHARMACEUTICALS": {
              "PENICILLIN V POTASSIUM": "PENICILLIN V POTASSIUM"
            },
            "PAR PHARMACEUTICAL": {
              "PENICILLIN V POTASSIUM": "PENICILLIN V POTASSIUM"
            },
            "PDRX PHARMACEUTICAL": {
              "PENICILLIN V POTASSIUM": "PENICILLIN V POTASSIUM"
            },
            "PFIZER U.S.": {
              "BICILLIN L-A": "BICILLIN L-A",
              "PENICILLIN G PROCAINE": "PENICILLIN G PROCAINE",
              "PFIZERPEN": "PFIZERPEN"
            },
            "PHARMEDIX": {
              "PENICILLIN V POTASSIUM": "PENICILLIN V POTASSIUM"
            },
            "PHARMPAK": {
              "PENICILLIN V POTASSIUM": "PENICILLIN V POTASSIUM"
            },
            "PREFERRED PHARMACEUTICALS": {
              "PENICILLIN V POTASSIUM": "PENICILLIN V POTASSIUM"
            },
            "PROFICIENT RX": {
              "PENICILLIN V POTASSIUM": "PENICILLIN V POTASSIUM"
            },
            "QUALITY CARE": {
              "PENICILLIN V POTASSIUM": "PENICILLIN V POTASSIUM"
            },
            "QUALITY CARE PRODUCTS": {
              "PENICILLIN V POTASSIUM": "PENICILLIN V POTASSIUM"
            },
            "RISING PHARMACEUTICALS": {
              "PENICILLIN V POTASSIUM": "PENICILLIN V POTASSIUM"
            },
            "RXCHANGE CO": {
              "PENICILLIN V POTASSIUM": "PENICILLIN V POTASSIUM"
            },
            "SANDOZ": {
              "PENICILLIN G POTASSIUM": "PENICILLIN G POTASSIUM",
              "PENICILLIN G SODIUM": "PENICILLIN G SODIUM",
              "PENICILLIN V POTASSIUM": "PENICILLIN V POTASSIUM"
            },
            "TEVA PHARMACEUTICALS USA": {
              "PENICILLIN V POTASSIUM": "PENICILLIN V POTASSIUM"
            },
            "UNIT DOSE SERVICES": {
              "PENICILLIN V POTASSIUM": "PENICILLIN V POTASSIUM"
            },
            "WG CRITICAL CARE": {
              "PENICILLIN G POTASSIUM": "PENICILLIN G POTASSIUM"
            }
          }
        },
        "Penicillin Combinations": {
          "Penicillin Combinations": {
            "A-S MEDICATION SOLUTIONS": {
              "AMOXICILLIN-POT CLAVULANATE": "AMOXICILLIN-POT CLAVULANATE",
              "AUGMENTIN": "AUGMENTIN",
              "BICILLIN C-R": "BICILLIN C-R",
              "BICILLIN C-R 900/300": "BICILLIN C-R 900/300"
            },
            "AIDAREX PHARMACEUTICALS": {
              "AMOXICILLIN-POT CLAVULANATE": "AMOXICILLIN-POT CLAVULANATE"
            },
            "ALTURA PHARMACEUTICALS": {
              "AMOXICILLIN-POT CLAVULANATE": "AMOXICILLIN-POT CLAVULANATE",
              "AUGMENTIN": "AUGMENTIN"
            },
            "AMERINET/WEST-WARD": {
              "AMPICILLIN-SULBACTAM SODIUM": "AMPICILLIN-SULBACTAM SODIUM"
            },
            "APOLLO PHARMACEUTICALS": {
              "PIPERACILLIN SOD-TAZOBACTAM SO": "PIPERACILLIN SOD-TAZOBACTAM SO"
            },
            "APOTEX": {
              "PIPERACILLIN SOD-TAZOBACTAM SO": "PIPERACILLIN SOD-TAZOBACTAM SO"
            },
            "APOTHECA": {
              "AMOXICILLIN-POT CLAVULANATE": "AMOXICILLIN-POT CLAVULANATE"
            },
            "ATHENEX PHARMACEUTICAL": {
              "PIPERACILLIN SOD-TAZOBACTAM SO": "PIPERACILLIN SOD-TAZOBACTAM SO"
            },
            "AUROBINDO PHARMA": {
              "AMOXICILLIN-POT CLAVULANATE": "AMOXICILLIN-POT CLAVULANATE"
            },
            "AUROMEDICS PHARMA": {
              "AMPICILLIN-SULBACTAM SODIUM": "AMPICILLIN-SULBACTAM SODIUM",
              "PIPERACILLIN SOD-TAZOBACTAM SO": "PIPERACILLIN SOD-TAZOBACTAM SO"
            },
            "BLENHEIM PHARMACAL": {
              "AMOXICILLIN-POT CLAVULANATE": "AMOXICILLIN-POT CLAVULANATE"
            },
            "BRYANT RANCH PREPACK": {
              "AMOXICILLIN-POT CLAVULANATE": "AMOXICILLIN-POT CLAVULANATE"
            },
            "CAMBRIDGE THERAPEUTICS TECHN": {
              "AMOXICILLIN-POT CLAVULANATE": "AMOXICILLIN-POT CLAVULANATE"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "AMOXICILLIN-POT CLAVULANATE": "AMOXICILLIN-POT CLAVULANATE"
            },
            "DENTON PHARMA": {
              "AMOXICILLIN-POT CLAVULANATE": "AMOXICILLIN-POT CLAVULANATE"
            },
            "DIRECT RX": {
              "AMOXICILLIN-POT CLAVULANATE": "AMOXICILLIN-POT CLAVULANATE"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "AMOXICILLIN-POT CLAVULANATE": "AMOXICILLIN-POT CLAVULANATE",
              "AUGMENTIN": "AUGMENTIN",
              "AUGMENTIN ES-600": "AUGMENTIN ES-600"
            },
            "FRESENIUS KABI USA": {
              "AMPICILLIN-SULBACTAM SODIUM": "AMPICILLIN-SULBACTAM SODIUM",
              "PIPERACILLIN SOD-TAZOBACTAM SO": "PIPERACILLIN SOD-TAZOBACTAM SO"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "AMOXICILLIN-POT CLAVULANATE": "AMOXICILLIN-POT CLAVULANATE",
              "AUGMENTIN": "AUGMENTIN",
              "AUGMENTIN XR": "AUGMENTIN XR"
            },
            "HIKMA": {
              "AMOXICILLIN-POT CLAVULANATE": "AMOXICILLIN-POT CLAVULANATE",
              "AMPICILLIN-SULBACTAM SODIUM": "AMPICILLIN-SULBACTAM SODIUM"
            },
            "HOSPIRA": {
              "AMPICILLIN-SULBACTAM SODIUM": "AMPICILLIN-SULBACTAM SODIUM",
              "PIPERACILLIN SOD-TAZOBACTAM SO": "PIPERACILLIN SOD-TAZOBACTAM SO"
            },
            "LIBERTY PHARMACEUTICAL": {
              "AMOXICILLIN-POT CLAVULANATE": "AMOXICILLIN-POT CLAVULANATE"
            },
            "MEDVANTX": {
              "AUGMENTIN": "AUGMENTIN"
            },
            "MEITHEAL PHARMACEUTICALS": {
              "AMPICILLIN-SULBACTAM SODIUM": "AMPICILLIN-SULBACTAM SODIUM",
              "PIPERACILLIN SOD-TAZOBACTAM SO": "PIPERACILLIN SOD-TAZOBACTAM SO"
            },
            "MICRO LABORATORIES": {
              "AMOXICILLIN-POT CLAVULANATE": "AMOXICILLIN-POT CLAVULANATE"
            },
            "MORTON GROVE PHARMACEUTICALS": {
              "AMOXICILLIN-POT CLAVULANATE": "AMOXICILLIN-POT CLAVULANATE"
            },
            "MYLAN": {
              "AMOXICILLIN-POT CLAVULANATE": "AMOXICILLIN-POT CLAVULANATE"
            },
            "MYLAN INSTITUTIONAL": {
              "AMPICILLIN-SULBACTAM SODIUM": "AMPICILLIN-SULBACTAM SODIUM",
              "PIPERACILLIN SOD-TAZOBACTAM SO": "PIPERACILLIN SOD-TAZOBACTAM SO"
            },
            "NEOPHARMA": {
              "AMOXICILLIN-POT CLAVULANATE": "AMOXICILLIN-POT CLAVULANATE",
              "AMOXICILLIN-POT CLAVULANATE ER": "AMOXICILLIN-POT CLAVULANATE ER",
              "AUGMENTIN": "AUGMENTIN",
              "AUGMENTIN XR": "AUGMENTIN XR"
            },
            "NORTHSTAR RX": {
              "AMOXICILLIN-POT CLAVULANATE": "AMOXICILLIN-POT CLAVULANATE"
            },
            "NOVAPLUS/FRESENIUS KABI": {
              "PIPERACILLIN SOD-TAZOBACTAM SO": "PIPERACILLIN SOD-TAZOBACTAM SO"
            },
            "NOVAPLUS/HIKMA": {
              "AMPICILLIN-SULBACTAM SODIUM": "AMPICILLIN-SULBACTAM SODIUM"
            },
            "NOVAPLUS/PFIZER U.S.": {
              "ZOSYN": "ZOSYN"
            },
            "NOVAPLUS/SANDOZ": {
              "PIPERACILLIN SOD-TAZOBACTAM SO": "PIPERACILLIN SOD-TAZOBACTAM SO"
            },
            "NUCARE PHARMACEUTICALS": {
              "AMOXICILLIN-POT CLAVULANATE": "AMOXICILLIN-POT CLAVULANATE",
              "AUGMENTIN": "AUGMENTIN"
            },
            "PDRX PHARMACEUTICAL": {
              "AMOXICILLIN-POT CLAVULANATE": "AMOXICILLIN-POT CLAVULANATE",
              "AUGMENTIN": "AUGMENTIN"
            },
            "PDRX PHARMACEUTICALS": {
              "AMOXICILLIN-POT CLAVULANATE": "AMOXICILLIN-POT CLAVULANATE"
            },
            "PFIZER U.S.": {
              "BICILLIN C-R": "BICILLIN C-R",
              "BICILLIN C-R 900/300": "BICILLIN C-R 900/300",
              "UNASYN": "UNASYN",
              "ZOSYN": "ZOSYN"
            },
            "PHARMEDIX": {
              "AMOXICILLIN-POT CLAVULANATE": "AMOXICILLIN-POT CLAVULANATE"
            },
            "PIRAMAL CRITICAL CARE": {
              "AMPICILLIN-SULBACTAM SODIUM": "AMPICILLIN-SULBACTAM SODIUM",
              "PIPERACILLIN SOD-TAZOBACTAM SO": "PIPERACILLIN SOD-TAZOBACTAM SO"
            },
            "PREFERRED PHARMACEUTICALS": {
              "AMOXICILLIN-POT CLAVULANATE": "AMOXICILLIN-POT CLAVULANATE"
            },
            "PROFICIENT RX": {
              "AMOXICILLIN-POT CLAVULANATE": "AMOXICILLIN-POT CLAVULANATE"
            },
            "QUALITY CARE": {
              "AMOXICILLIN-POT CLAVULANATE": "AMOXICILLIN-POT CLAVULANATE",
              "AUGMENTIN": "AUGMENTIN",
              "AUGMENTIN XR": "AUGMENTIN XR"
            },
            "QUALITY CARE PRODUCTS": {
              "AMOXICILLIN-POT CLAVULANATE": "AMOXICILLIN-POT CLAVULANATE"
            },
            "RXCHANGE CO": {
              "AMOXICILLIN-POT CLAVULANATE": "AMOXICILLIN-POT CLAVULANATE"
            },
            "SAGENT PHARMACEUTICAL": {
              "AMPICILLIN-SULBACTAM SODIUM": "AMPICILLIN-SULBACTAM SODIUM",
              "PIPERACILLIN SOD-TAZOBACTAM SO": "PIPERACILLIN SOD-TAZOBACTAM SO"
            },
            "SANDOZ": {
              "AMOXICILLIN-POT CLAVULANATE": "AMOXICILLIN-POT CLAVULANATE",
              "AMOXICILLIN-POT CLAVULANATE ER": "AMOXICILLIN-POT CLAVULANATE ER",
              "AMPICILLIN-SULBACTAM SODIUM": "AMPICILLIN-SULBACTAM SODIUM",
              "PIPERACILLIN SOD-TAZOBACTAM SO": "PIPERACILLIN SOD-TAZOBACTAM SO"
            },
            "TEVA PHARMACEUTICALS USA": {
              "AMOXICILLIN-POT CLAVULANATE": "AMOXICILLIN-POT CLAVULANATE"
            },
            "UNIT DOSE SERVICES": {
              "AMOXICILLIN-POT CLAVULANATE": "AMOXICILLIN-POT CLAVULANATE"
            },
            "WG CRITICAL CARE": {
              "AMPICILLIN-SULBACTAM SODIUM": "AMPICILLIN-SULBACTAM SODIUM",
              "PIPERACILLIN SOD-TAZOBACTAM SO": "PIPERACILLIN SOD-TAZOBACTAM SO"
            },
            "WOCKHARDT USA": {
              "PIPERACILLIN SOD-TAZOBACTAM SO": "PIPERACILLIN SOD-TAZOBACTAM SO"
            },
            "X-GEN PHARMACEUTICALS, INC": {
              "PIPERACILLIN SOD-TAZOBACTAM SO": "PIPERACILLIN SOD-TAZOBACTAM SO"
            }
          }
        },
        "Penicillinase-Resistant Penicillins": {
          "Penicillinase-Resistant Penicillins": {
            "A-S MEDICATION SOLUTIONS": {
              "DICLOXACILLIN SODIUM": "DICLOXACILLIN SODIUM"
            },
            "AIDAREX PHARMACEUTICALS": {
              "DICLOXACILLIN SODIUM": "DICLOXACILLIN SODIUM"
            },
            "ALTURA PHARMACEUTICALS": {
              "DICLOXACILLIN SODIUM": "DICLOXACILLIN SODIUM"
            },
            "ATHENEX PHARMACEUTICAL": {
              "NAFCILLIN SODIUM": "NAFCILLIN SODIUM"
            },
            "AUROMEDICS PHARMA": {
              "NAFCILLIN SODIUM": "NAFCILLIN SODIUM",
              "OXACILLIN SODIUM": "OXACILLIN SODIUM"
            },
            "AVKARE": {
              "DICLOXACILLIN SODIUM": "DICLOXACILLIN SODIUM"
            },
            "BAXTER HEALTHCARE CORP": {
              "NAFCILLIN SODIUM IN DEXTROSE": "NAFCILLIN SODIUM IN DEXTROSE",
              "OXACILLIN SODIUM IN DEXTROSE": "OXACILLIN SODIUM IN DEXTROSE"
            },
            "BRYANT RANCH PREPACK": {
              "DICLOXACILLIN SODIUM": "DICLOXACILLIN SODIUM"
            },
            "FRESENIUS KABI USA": {
              "NAFCILLIN SODIUM": "NAFCILLIN SODIUM"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "DICLOXACILLIN SODIUM": "DICLOXACILLIN SODIUM"
            },
            "HOSPIRA": {
              "NAFCILLIN SODIUM": "NAFCILLIN SODIUM"
            },
            "LIBERTY PHARMACEUTICAL": {
              "DICLOXACILLIN SODIUM": "DICLOXACILLIN SODIUM"
            },
            "MEDVANTX": {
              "DICLOXACILLIN SODIUM": "DICLOXACILLIN SODIUM"
            },
            "METHAPHARM": {
              "NAFCILLIN SODIUM": "NAFCILLIN SODIUM"
            },
            "NOVAPLUS/AUROMEDICS": {
              "NAFCILLIN SODIUM": "NAFCILLIN SODIUM",
              "OXACILLIN SODIUM": "OXACILLIN SODIUM"
            },
            "NOVAPLUS/SAGENT PHARMACEUTICAL": {
              "OXACILLIN SODIUM": "OXACILLIN SODIUM"
            },
            "NOVAPLUS/SANDOZ": {
              "NAFCILLIN SODIUM": "NAFCILLIN SODIUM",
              "OXACILLIN SODIUM": "OXACILLIN SODIUM"
            },
            "NUCARE PHARMACEUTICALS": {
              "DICLOXACILLIN SODIUM": "DICLOXACILLIN SODIUM"
            },
            "PDRX PHARMACEUTICAL": {
              "DICLOXACILLIN SODIUM": "DICLOXACILLIN SODIUM"
            },
            "PIRAMAL CRITICAL CARE": {
              "OXACILLIN SODIUM": "OXACILLIN SODIUM"
            },
            "QUALITY CARE": {
              "DICLOXACILLIN SODIUM": "DICLOXACILLIN SODIUM"
            },
            "QUALITY CARE PRODUCTS": {
              "DICLOXACILLIN SODIUM": "DICLOXACILLIN SODIUM"
            },
            "RENAISSANCE LAKEWOOD": {
              "OXACILLIN SODIUM": "OXACILLIN SODIUM"
            },
            "RXCHANGE CO": {
              "DICLOXACILLIN SODIUM": "DICLOXACILLIN SODIUM"
            },
            "SAGENT PHARMACEUTICAL": {
              "NAFCILLIN SODIUM": "NAFCILLIN SODIUM",
              "OXACILLIN SODIUM": "OXACILLIN SODIUM"
            },
            "SANDOZ": {
              "DICLOXACILLIN SODIUM": "DICLOXACILLIN SODIUM",
              "NAFCILLIN SODIUM": "NAFCILLIN SODIUM",
              "OXACILLIN SODIUM": "OXACILLIN SODIUM"
            },
            "TEVA PARENTERAL MEDICINES": {
              "NAFCILLIN SODIUM": "NAFCILLIN SODIUM"
            },
            "TEVA PHARMACEUTICALS USA": {
              "DICLOXACILLIN SODIUM": "DICLOXACILLIN SODIUM"
            },
            "WG CRITICAL CARE": {
              "NAFCILLIN SODIUM": "NAFCILLIN SODIUM"
            },
            "WOCKHARDT USA": {
              "OXACILLIN SODIUM": "OXACILLIN SODIUM"
            }
          }
        }
      },
      "PHARMACEUTICAL ADJUVANTS": {
        "Alkalizing Agents": {
          "Alkalizing Agents": {
            "FAGRON": {
              "TROLAMINE": "TROLAMINE"
            },
            "KALCHEM INTERNATIONAL": {
              "TROLAMINE": "TROLAMINE"
            },
            "PCCA": {
              "TROLAMINE": "TROLAMINE"
            },
            "SPECTRUM": {
              "TROLAMINE": "TROLAMINE"
            }
          }
        },
        "Antimicrobial Agents": {
          "Antimicrobial Agents": {
            "ENCORE SCIENTIFIC": {
              "METHYLPARABEN": "METHYLPARABEN",
              "POTASSIUM SORBATE": "POTASSIUM SORBATE",
              "PROPYLPARABEN": "PROPYLPARABEN",
              "SORBIC ACID": "SORBIC ACID"
            },
            "FAGRON": {
              "BENZYL ALCOHOL": "BENZYL ALCOHOL",
              "BUTYLPARABEN": "BUTYLPARABEN",
              "CHLOROBUTANOL ANHYDROUS": "CHLOROBUTANOL ANHYDROUS",
              "METHYLPARABEN": "METHYLPARABEN",
              "POTASSIUM SORBATE": "POTASSIUM SORBATE",
              "PROPYLPARABEN": "PROPYLPARABEN",
              "SORBIC ACID": "SORBIC ACID"
            },
            "FREEDOM PHARMACEUTICALS": {
              "BENZYL ALCOHOL": "BENZYL ALCOHOL"
            },
            "KALCHEM INTERNATIONAL": {
              "BENZYL ALCOHOL": "BENZYL ALCOHOL",
              "METHYLPARABEN": "METHYLPARABEN",
              "POTASSIUM SORBATE": "POTASSIUM SORBATE",
              "PROPYLPARABEN": "PROPYLPARABEN",
              "SORBIC ACID": "SORBIC ACID"
            },
            "LETCO MEDICAL": {
              "BENZYL ALCOHOL": "BENZYL ALCOHOL",
              "CHLOROBUTANOL ANHYDROUS": "CHLOROBUTANOL ANHYDROUS",
              "METHYLPARABEN": "METHYLPARABEN",
              "POTASSIUM SORBATE": "POTASSIUM SORBATE",
              "PROPYLPARABEN": "PROPYLPARABEN",
              "SORBIC ACID": "SORBIC ACID"
            },
            "MEDISCA": {
              "BENZYL ALCOHOL": "BENZYL ALCOHOL",
              "METHYLPARABEN": "METHYLPARABEN",
              "POTASSIUM SORBATE": "POTASSIUM SORBATE",
              "PROPYLPARABEN": "PROPYLPARABEN",
              "SORBIC ACID": "SORBIC ACID"
            },
            "PCCA": {
              "BENZYL ALCOHOL": "BENZYL ALCOHOL",
              "CHLOROBUTANOL": "CHLOROBUTANOL",
              "CHLOROBUTANOL ANHYDROUS": "CHLOROBUTANOL ANHYDROUS",
              "METHYLPARABEN": "METHYLPARABEN",
              "POTASSIUM SORBATE": "POTASSIUM SORBATE",
              "PROPYLPARABEN": "PROPYLPARABEN",
              "SORBIC ACID": "SORBIC ACID"
            },
            "SPECTRUM": {
              "BENZYL ALCOHOL": "BENZYL ALCOHOL",
              "CHLOROBUTANOL": "CHLOROBUTANOL",
              "CHLOROBUTANOL HEMIHYDRATE": "CHLOROBUTANOL HEMIHYDRATE",
              "METHYLPARABEN": "METHYLPARABEN",
              "METHYLPARABEN SODIUM": "METHYLPARABEN SODIUM",
              "POTASSIUM SORBATE": "POTASSIUM SORBATE",
              "PROPYLPARABEN": "PROPYLPARABEN",
              "PROPYLPARABEN SODIUM": "PROPYLPARABEN SODIUM",
              "SORBIC ACID": "SORBIC ACID"
            }
          }
        },
        "Coloring Agents": {
          "Coloring Agents": {
            "FAGRON": {
              "FD&C RED #40 ALUMINUM LAKE": "FD&C RED #40 ALUMINUM LAKE",
              "FD&C YELLOW #5": "FD&C YELLOW #5",
              "FDC BLUE 1": "FDC BLUE 1",
              "FDC BLUE 1 ALUMINUM LAKE": "FDC BLUE 1 ALUMINUM LAKE",
              "FDC BLUE 2": "FDC BLUE 2",
              "FDC GREEN #3": "FDC GREEN #3",
              "FDC RED #3": "FDC RED #3",
              "FDC RED 40": "FDC RED 40",
              "FDC YELLOW 5 ALUMINUM LAKE": "FDC YELLOW 5 ALUMINUM LAKE",
              "FDC YELLOW 6": "FDC YELLOW 6"
            },
            "FREEDOM PHARMACEUTICALS": {
              "TRYPAN BLUE": "TRYPAN BLUE"
            },
            "LETCO MEDICAL": {
              "FOOD COLOR BLACK": "FOOD COLOR BLACK",
              "FOOD COLOR BLUE": "FOOD COLOR BLUE",
              "FOOD COLOR BLUE ROYAL": "FOOD COLOR BLUE ROYAL",
              "FOOD COLOR BROWN": "FOOD COLOR BROWN",
              "FOOD COLOR FLESH": "FOOD COLOR FLESH",
              "FOOD COLOR GREEN": "FOOD COLOR GREEN",
              "FOOD COLOR LIME GREEN": "FOOD COLOR LIME GREEN",
              "FOOD COLOR RED": "FOOD COLOR RED"
            },
            "MEDISCA": {
              "FD&C YELLOW #5": "FD&C YELLOW #5",
              "FD&C YELLOW #6 ALUMINUM LAKE": "FD&C YELLOW #6 ALUMINUM LAKE",
              "FDC BLUE 1": "FDC BLUE 1",
              "FDC BLUE 2": "FDC BLUE 2",
              "FDC GREEN #3": "FDC GREEN #3",
              "FDC RED #3": "FDC RED #3",
              "FDC RED 40": "FDC RED 40",
              "FDC YELLOW 6": "FDC YELLOW 6"
            },
            "PCCA": {
              "BRILLIANT BLUE G": "BRILLIANT BLUE G",
              "EVANS BLUE": "EVANS BLUE",
              "FDC BLUE 1": "FDC BLUE 1",
              "FDC BLUE 2": "FDC BLUE 2",
              "FDC GREEN #3": "FDC GREEN #3",
              "FDC RED #3": "FDC RED #3",
              "FDC RED 40": "FDC RED 40",
              "FOOD COLOR BLACK": "FOOD COLOR BLACK",
              "FOOD COLOR BLUE": "FOOD COLOR BLUE",
              "FOOD COLOR BROWN": "FOOD COLOR BROWN",
              "FOOD COLOR GREEN": "FOOD COLOR GREEN",
              "FOOD COLOR LIME GREEN": "FOOD COLOR LIME GREEN",
              "FOOD COLOR ORANGE": "FOOD COLOR ORANGE",
              "FOOD COLOR PINK": "FOOD COLOR PINK",
              "FOOD COLOR RED": "FOOD COLOR RED",
              "FOOD COLOR VIOLET": "FOOD COLOR VIOLET",
              "FOOD COLOR WHITE": "FOOD COLOR WHITE",
              "FOOD COLOR YELLOW": "FOOD COLOR YELLOW",
              "LISSAMINE GREEN B": "LISSAMINE GREEN B",
              "QUINIZARIN GREEN SS": "QUINIZARIN GREEN SS",
              "SULPHAN BLUE": "SULPHAN BLUE",
              "TRYPAN BLUE": "TRYPAN BLUE"
            },
            "SPECTRUM": {
              "AMARANTH": "AMARANTH",
              "FD&C BLUE #2": "FD&C BLUE #2",
              "FDC BLUE 2": "FDC BLUE 2",
              "FDC RED 40": "FDC RED 40",
              "TRYPAN BLUE": "TRYPAN BLUE"
            }
          }
        },
        "Delivery Devices": {
          "Transdermal Patches": {
            "OAKLOCK": {
              "EXYDERM": "EXYDERM"
            }
          }
        },
        "Flavoring Agents": {
          "Flavoring Agents": {
            "FAGRON": {
              "APPLE FLAVOR": "APPLE FLAVOR",
              "BACON FLAVOR": "BACON FLAVOR",
              "BANANA CONCENTRATE": "BANANA CONCENTRATE",
              "BEEF FLAVOR": "BEEF FLAVOR",
              "BUBBLE GUM CONCENTRATE": "BUBBLE GUM CONCENTRATE",
              "CHICKEN FLAVOR": "CHICKEN FLAVOR",
              "CHICKEN ROASTED CONCENTRATE": "CHICKEN ROASTED CONCENTRATE",
              "CHOCOLATE CONCENTRATE": "CHOCOLATE CONCENTRATE",
              "GRAPE FLAVOR": "GRAPE FLAVOR",
              "LIVER CONCENTRATE": "LIVER CONCENTRATE",
              "LIVER FLAVOR": "LIVER FLAVOR",
              "ORANGE CONCENTRATE": "ORANGE CONCENTRATE",
              "RASPBERRY FLAVOR": "RASPBERRY FLAVOR",
              "SARDINE FLAVOR": "SARDINE FLAVOR",
              "STRAWBERRY FLAVOR": "STRAWBERRY FLAVOR",
              "TUNA FLAVOR": "TUNA FLAVOR",
              "TUTTI FRUTTI CONCENTRATE": "TUTTI FRUTTI CONCENTRATE"
            },
            "FLAVORX": {
              "APPLE FLAVOR": "APPLE FLAVOR",
              "BANANA FLAVOR": "BANANA FLAVOR",
              "BITTERNESS SUPPRESSOR FLAVOR": "BITTERNESS SUPPRESSOR FLAVOR",
              "BUBBLE GUM FLAVOR": "BUBBLE GUM FLAVOR",
              "CHERRY FLAVOR": "CHERRY FLAVOR",
              "CHOCOLATE FLAVOR": "CHOCOLATE FLAVOR",
              "FLAVORX": "FLAVORX",
              "GRAPE FLAVOR": "GRAPE FLAVOR",
              "LEMON FLAVOR": "LEMON FLAVOR",
              "MANGO FLAVOR": "MANGO FLAVOR",
              "ORANGE FLAVOR": "ORANGE FLAVOR",
              "RASPBERRY FLAVOR": "RASPBERRY FLAVOR",
              "STRAWBERRY FLAVOR": "STRAWBERRY FLAVOR",
              "SWEETENING ENHANCER": "SWEETENING ENHANCER",
              "VANILLA FLAVOR": "VANILLA FLAVOR",
              "VITAMIN/IRON MASKING AGENT": "VITAMIN/IRON MASKING AGENT",
              "WATERMELON FLAVOR": "WATERMELON FLAVOR"
            },
            "LETCO MEDICAL": {
              "APPLE FLAVOR": "APPLE FLAVOR",
              "APPLE FLAVOR WATER MISCIBLE": "APPLE FLAVOR WATER MISCIBLE",
              "BACON FLAVOR": "BACON FLAVOR",
              "BANANA CREAM FLAVOR": "BANANA CREAM FLAVOR",
              "BEEF TYPE FLAVOR NATURAL": "BEEF TYPE FLAVOR NATURAL",
              "BEEF TYPE FLAVOR OS": "BEEF TYPE FLAVOR OS",
              "BITTERNESS MASK FLAVOR": "BITTERNESS MASK FLAVOR",
              "BLUEBERRY FLAVOR": "BLUEBERRY FLAVOR",
              "BUBBLE GUM FLAVOR": "BUBBLE GUM FLAVOR",
              "BUTTERSCOTCH FLAVOR": "BUTTERSCOTCH FLAVOR",
              "CARAMEL FLAVOR": "CARAMEL FLAVOR",
              "CHEESECAKE FLAVOR": "CHEESECAKE FLAVOR",
              "CHERRY FLAVOR": "CHERRY FLAVOR",
              "CHICKEN FLAVOR": "CHICKEN FLAVOR",
              "CHICKEN FLAVOR OIL SOLUBLE": "CHICKEN FLAVOR OIL SOLUBLE",
              "CHICKEN FLAVOR WATER MISCIBLE": "CHICKEN FLAVOR WATER MISCIBLE",
              "CHOCOLATE FLAVOR": "CHOCOLATE FLAVOR",
              "COTTON CANDY FLAVOR": "COTTON CANDY FLAVOR",
              "CREME DEMENTHE FLAVOR": "CREME DEMENTHE FLAVOR",
              "GRAPE FLAVOR": "GRAPE FLAVOR",
              "GUAVA FLAVOR": "GUAVA FLAVOR",
              "HONEY FLAVOR": "HONEY FLAVOR",
              "LIVER FLAVOR": "LIVER FLAVOR",
              "MARSHMALLOW FLAVOR": "MARSHMALLOW FLAVOR",
              "PEACH FLAVOR": "PEACH FLAVOR",
              "PEANUT BUTTER FLAVOR": "PEANUT BUTTER FLAVOR",
              "PINA COLADA FLAVOR": "PINA COLADA FLAVOR",
              "PINEAPPLE FLAVOR": "PINEAPPLE FLAVOR",
              "RASPBERRY FLAVOR": "RASPBERRY FLAVOR",
              "STRAWBERRY FLAVOR": "STRAWBERRY FLAVOR",
              "TROPICAL PUNCH FLAVOR": "TROPICAL PUNCH FLAVOR",
              "TUNA FLAVOR": "TUNA FLAVOR",
              "TUNA TYPE FLAVOR OS": "TUNA TYPE FLAVOR OS",
              "TUTTI FRUTTI FLAVOR": "TUTTI FRUTTI FLAVOR",
              "VANILLA BUTTERNUT FLAVOR": "VANILLA BUTTERNUT FLAVOR",
              "WATERMELON FLAVOR": "WATERMELON FLAVOR"
            },
            "LORANN OILS": {
              "CREME DE MENTHE FLAVOR": "CREME DE MENTHE FLAVOR"
            },
            "MEDISCA": {
              "APPLE FLAVOR": "APPLE FLAVOR",
              "BANANA CREAM FLAVOR": "BANANA CREAM FLAVOR",
              "BANANA FLAVOR": "BANANA FLAVOR",
              "BEEF FLAVOR": "BEEF FLAVOR",
              "BITTERNESS REDUCING AGENT": "BITTERNESS REDUCING AGENT",
              "BITTERNESS SUPPRESSOR FLAVOR": "BITTERNESS SUPPRESSOR FLAVOR",
              "BUBBLE GUM FLAVOR": "BUBBLE GUM FLAVOR",
              "CHERRY FLAVOR": "CHERRY FLAVOR",
              "CHICKEN FLAVOR": "CHICKEN FLAVOR",
              "CHICKEN FLAVOR OIL SOLUBLE": "CHICKEN FLAVOR OIL SOLUBLE",
              "CHOCOLATE FLAVOR": "CHOCOLATE FLAVOR",
              "CREME DEMENTHE FLAVOR": "CREME DEMENTHE FLAVOR",
              "GRAPE FLAVOR": "GRAPE FLAVOR",
              "LEMON FLAVOR": "LEMON FLAVOR",
              "MANGO FLAVOR": "MANGO FLAVOR",
              "MARSHMALLOW FLAVOR": "MARSHMALLOW FLAVOR",
              "ORANGE CREAM FLAVOR": "ORANGE CREAM FLAVOR",
              "ORANGE FLAVOR": "ORANGE FLAVOR",
              "PASSION FRUIT FLAVOR": "PASSION FRUIT FLAVOR",
              "RASPBERRY CONCENTRATE": "RASPBERRY CONCENTRATE",
              "RASPBERRY FLAVOR": "RASPBERRY FLAVOR",
              "STEVIA GLYCERITE EXTRACT": "STEVIA GLYCERITE EXTRACT",
              "STRAWBERRY FLAVOR": "STRAWBERRY FLAVOR",
              "SWEETENING ENHANCER": "SWEETENING ENHANCER",
              "TUNA FLAVOR": "TUNA FLAVOR",
              "TUTTI-FRUTTI FLAVOR": "TUTTI-FRUTTI FLAVOR",
              "VANILLA BUTTERNUT FLAVOR": "VANILLA BUTTERNUT FLAVOR",
              "VANILLA FLAVOR": "VANILLA FLAVOR",
              "WATERMELON FLAVOR": "WATERMELON FLAVOR",
              "WILD CHERRY FLAVOR": "WILD CHERRY FLAVOR"
            },
            "PCCA": {
              "ALFALFA FLAVOR": "ALFALFA FLAVOR",
              "ALMOND OIL BITTER FLAVOR": "ALMOND OIL BITTER FLAVOR",
              "ANISE EXTRACT": "ANISE EXTRACT",
              "ANISE FLAVOR": "ANISE FLAVOR",
              "APPLE FLAVOR": "APPLE FLAVOR",
              "APRICOT FLAVOR": "APRICOT FLAVOR",
              "BACON FLAVOR": "BACON FLAVOR",
              "BANANA CONCENTRATE": "BANANA CONCENTRATE",
              "BANANA CREME FLAVOR": "BANANA CREME FLAVOR",
              "BEEF (GRILLED) FLAVOR OIL SOL": "BEEF (GRILLED) FLAVOR OIL SOL",
              "BEEF FLAVOR": "BEEF FLAVOR",
              "BEEF-ADE": "BEEF-ADE",
              "BITTER STOP FLAVOR": "BITTER STOP FLAVOR",
              "BLACKBERRY FLAVOR": "BLACKBERRY FLAVOR",
              "BLUEBERRY FLAVOR": "BLUEBERRY FLAVOR",
              "BUBBLE GUM CONCENTRATE": "BUBBLE GUM CONCENTRATE",
              "BUBBLE GUM FLAVOR": "BUBBLE GUM FLAVOR",
              "BUTTER FLAVOR": "BUTTER FLAVOR",
              "BUTTER RUM FLAVOR": "BUTTER RUM FLAVOR",
              "BUTTERSCOTCH FLAVOR": "BUTTERSCOTCH FLAVOR",
              "CARAMEL FLAVOR": "CARAMEL FLAVOR",
              "CHEESE-ADE FLAVOR": "CHEESE-ADE FLAVOR",
              "CHEESECAKE FLAVOR": "CHEESECAKE FLAVOR",
              "CHERRY FLAVOR": "CHERRY FLAVOR",
              "CHERRY-ADE FLAVOR": "CHERRY-ADE FLAVOR",
              "CHICKEN (GRILLED) FLAVOR": "CHICKEN (GRILLED) FLAVOR",
              "CHICKEN BROTH FLAVOR": "CHICKEN BROTH FLAVOR",
              "CHICKEN FLAVOR OIL MISCIBLE": "CHICKEN FLAVOR OIL MISCIBLE",
              "CHOCOLATE FLAVOR": "CHOCOLATE FLAVOR",
              "CHOCOLATE HAZELNUT FLAVOR": "CHOCOLATE HAZELNUT FLAVOR",
              "CINNAMON FLAVOR": "CINNAMON FLAVOR",
              "COCONUT FLAVOR": "COCONUT FLAVOR",
              "COFFEE FLAVOR": "COFFEE FLAVOR",
              "COLA FLAVOR": "COLA FLAVOR",
              "COTTON CANDY FLAVOR": "COTTON CANDY FLAVOR",
              "CRAN-RASPBERRY FLAVOR": "CRAN-RASPBERRY FLAVOR",
              "CREME DEMENTHE FLAVOR": "CREME DEMENTHE FLAVOR",
              "ENGLISH TOFFEE FLAVOR": "ENGLISH TOFFEE FLAVOR",
              "EUCALYPTUS FLAVOR": "EUCALYPTUS FLAVOR",
              "EUGENOL FLAVOR": "EUGENOL FLAVOR",
              "FISH FLAVOR": "FISH FLAVOR",
              "FLAVOR CONC-CHLORHEXIDINE": "FLAVOR CONC-CHLORHEXIDINE",
              "GRAPE FLAVOR": "GRAPE FLAVOR",
              "GRAPEFRUIT FLAVOR": "GRAPEFRUIT FLAVOR",
              "GUAVA FLAVOR": "GUAVA FLAVOR",
              "HAM FLAVOR": "HAM FLAVOR",
              "HONEY FLAVOR": "HONEY FLAVOR",
              "KAHLUA FLAVOR": "KAHLUA FLAVOR",
              "LEMON EXTRACT": "LEMON EXTRACT",
              "LEMON FLAVOR": "LEMON FLAVOR",
              "LEMONADE FLAVOR": "LEMONADE FLAVOR",
              "LICORICE FLAVOR": "LICORICE FLAVOR",
              "LIME FLAVOR": "LIME FLAVOR",
              "LIVER FLAVOR": "LIVER FLAVOR",
              "MANGO FLAVOR": "MANGO FLAVOR",
              "MANGO FLAVOR SWEETENED": "MANGO FLAVOR SWEETENED",
              "MAPLE FLAVOR": "MAPLE FLAVOR",
              "MARSHMALLOW FLAVOR": "MARSHMALLOW FLAVOR",
              "MINT CHOCOLATE CHIP FLAVOR": "MINT CHOCOLATE CHIP FLAVOR",
              "MOLASSES FLAVOR": "MOLASSES FLAVOR",
              "NATURAL CARAMEL": "NATURAL CARAMEL",
              "ORANGE CREAM FLAVOR": "ORANGE CREAM FLAVOR",
              "ORANGE FLAVOR": "ORANGE FLAVOR",
              "ORANGE OIL FLAVOR": "ORANGE OIL FLAVOR",
              "PASSION FRUIT FLAVOR": "PASSION FRUIT FLAVOR",
              "PASSION FRUIT FLAVOR SWEETENED": "PASSION FRUIT FLAVOR SWEETENED",
              "PCCA SWEETNESS ENHANCER": "PCCA SWEETNESS ENHANCER",
              "PEACH FLAVOR": "PEACH FLAVOR",
              "PEANUT BUTTER FLAVOR": "PEANUT BUTTER FLAVOR",
              "PEPPERMINT FLAVOR": "PEPPERMINT FLAVOR",
              "PINA COLADA FLAVOR": "PINA COLADA FLAVOR",
              "PINEAPPLE FLAVOR": "PINEAPPLE FLAVOR",
              "PRALINES AND CREAM FLAVOR": "PRALINES AND CREAM FLAVOR",
              "PUMPKIN FLAVOR": "PUMPKIN FLAVOR",
              "RASPBERRY FLAVOR": "RASPBERRY FLAVOR",
              "ROOT BEER FLAVOR": "ROOT BEER FLAVOR",
              "SHRIMP FLAVOR": "SHRIMP FLAVOR",
              "SPEARMINT FLAVOR": "SPEARMINT FLAVOR",
              "STRAWBERRY FLAVOR": "STRAWBERRY FLAVOR",
              "SUPER SYNERSWEET FLAVOR": "SUPER SYNERSWEET FLAVOR",
              "SWEET CORN FLAVOR": "SWEET CORN FLAVOR",
              "TANGERINE FLAVOR": "TANGERINE FLAVOR",
              "TANGERINE FLAVOR SWEETENED": "TANGERINE FLAVOR SWEETENED",
              "TEABERRY FLAVOR": "TEABERRY FLAVOR",
              "TROPICAL PUNCH FLAVOR": "TROPICAL PUNCH FLAVOR",
              "TUNA FLAVOR": "TUNA FLAVOR",
              "TUTTI FRUTTI FLAVOR": "TUTTI FRUTTI FLAVOR",
              "VANILLA BUTTERNUT FLAVOR": "VANILLA BUTTERNUT FLAVOR",
              "VANILLA FLAVOR": "VANILLA FLAVOR",
              "VANILLIN FLAVOR": "VANILLIN FLAVOR",
              "WATERMELON FLAVOR": "WATERMELON FLAVOR",
              "WILD CHERRY FLAVOR": "WILD CHERRY FLAVOR"
            },
            "SPECTRUM": {
              "APPLE FLAVOR": "APPLE FLAVOR",
              "BANANA CREME FLAVOR": "BANANA CREME FLAVOR",
              "BEEF BRAISED NATURAL FLAVOR": "BEEF BRAISED NATURAL FLAVOR",
              "BENZALDEHYDE": "BENZALDEHYDE",
              "BITTERNESS SUPPRESSOR FLAVOR": "BITTERNESS SUPPRESSOR FLAVOR",
              "BUBBLE GUM CONCENTRATE": "BUBBLE GUM CONCENTRATE",
              "CHERRY FLAVOR": "CHERRY FLAVOR",
              "CHICKEN FLAVOR OIL SOLUBLE": "CHICKEN FLAVOR OIL SOLUBLE",
              "CHOCOLATE NATURAL & ARTIFICAL": "CHOCOLATE NATURAL & ARTIFICAL",
              "CREME DE MENTHE FLAVOR": "CREME DE MENTHE FLAVOR",
              "GRAPE FLAVOR": "GRAPE FLAVOR",
              "LEMON FLAVOR": "LEMON FLAVOR",
              "MARSHMALLOW ARTIFICIAL FLAVOR": "MARSHMALLOW ARTIFICIAL FLAVOR",
              "PEANUT BUTTER FLAVOR": "PEANUT BUTTER FLAVOR",
              "PINEAPPLE FLAVOR": "PINEAPPLE FLAVOR",
              "RASPBERRY FLAVOR ARTIFICIAL": "RASPBERRY FLAVOR ARTIFICIAL",
              "STRAWBERRY FLAVOR": "STRAWBERRY FLAVOR",
              "TANGERINE FLAVOR": "TANGERINE FLAVOR",
              "TROPICAL PUNCH FLAVOR": "TROPICAL PUNCH FLAVOR",
              "TUTTI FRUTTI FLAVOR": "TUTTI FRUTTI FLAVOR",
              "WATERMELON FLAVOR": "WATERMELON FLAVOR"
            }
          }
        },
        "Gelatin Capsules (Empty)": {
          "Gelatin Capsules (Empty)": {
            "APOTHECARY PRODUCTS, INC.": {
              "EMPTY CAPSULE SIZE 3 CLEAR": "EMPTY CAPSULE SIZE 3 CLEAR"
            },
            "FAGRON": {
              "CAPSULE SIZE 1 LACTOSE": "CAPSULE SIZE 1 LACTOSE",
              "DRCAPS SIZE 1": "DRCAPS SIZE 1",
              "EMPTY CAPSULE": "EMPTY CAPSULE",
              "EMPTY CAPSULE SIZE 0": "EMPTY CAPSULE SIZE 0",
              "EMPTY CAPSULE SIZE 0 BLUE": "EMPTY CAPSULE SIZE 0 BLUE",
              "EMPTY CAPSULE SIZE 0 RED/WHITE": "EMPTY CAPSULE SIZE 0 RED/WHITE",
              "EMPTY CAPSULE SIZE 0 WHITE": "EMPTY CAPSULE SIZE 0 WHITE",
              "EMPTY CAPSULE SIZE 00 CLEAR": "EMPTY CAPSULE SIZE 00 CLEAR",
              "EMPTY CAPSULE SIZE 00 WHITE": "EMPTY CAPSULE SIZE 00 WHITE",
              "EMPTY CAPSULE SIZE 000 CLEAR": "EMPTY CAPSULE SIZE 000 CLEAR",
              "EMPTY CAPSULE SIZE 1 BLUE": "EMPTY CAPSULE SIZE 1 BLUE",
              "EMPTY CAPSULE SIZE 1 BLUE/PINK": "EMPTY CAPSULE SIZE 1 BLUE/PINK",
              "EMPTY CAPSULE SIZE 1 BLUE/WHT": "EMPTY CAPSULE SIZE 1 BLUE/WHT",
              "EMPTY CAPSULE SIZE 1 CLEAR": "EMPTY CAPSULE SIZE 1 CLEAR",
              "EMPTY CAPSULE SIZE 1 GREEN": "EMPTY CAPSULE SIZE 1 GREEN",
              "EMPTY CAPSULE SIZE 1 GRN/YLLW": "EMPTY CAPSULE SIZE 1 GRN/YLLW",
              "EMPTY CAPSULE SIZE 1 ORANGE": "EMPTY CAPSULE SIZE 1 ORANGE",
              "EMPTY CAPSULE SIZE 1 ORNGE/WHT": "EMPTY CAPSULE SIZE 1 ORNGE/WHT",
              "EMPTY CAPSULE SIZE 1 PINK": "EMPTY CAPSULE SIZE 1 PINK",
              "EMPTY CAPSULE SIZE 1 PNK/WHITE": "EMPTY CAPSULE SIZE 1 PNK/WHITE",
              "EMPTY CAPSULE SIZE 1 PURPLE": "EMPTY CAPSULE SIZE 1 PURPLE",
              "EMPTY CAPSULE SIZE 1 PWDR BLUE": "EMPTY CAPSULE SIZE 1 PWDR BLUE",
              "EMPTY CAPSULE SIZE 1 RED": "EMPTY CAPSULE SIZE 1 RED",
              "EMPTY CAPSULE SIZE 1 RED/WHITE": "EMPTY CAPSULE SIZE 1 RED/WHITE",
              "EMPTY CAPSULE SIZE 1 WHITE": "EMPTY CAPSULE SIZE 1 WHITE",
              "EMPTY CAPSULE SIZE 1 WHT/CLEAR": "EMPTY CAPSULE SIZE 1 WHT/CLEAR",
              "EMPTY CAPSULE SIZE 11 CLEAR": "EMPTY CAPSULE SIZE 11 CLEAR",
              "EMPTY CAPSULE SIZE 2 BLUE": "EMPTY CAPSULE SIZE 2 BLUE",
              "EMPTY CAPSULE SIZE 2 CLEAR": "EMPTY CAPSULE SIZE 2 CLEAR",
              "EMPTY CAPSULE SIZE 2 GREEN": "EMPTY CAPSULE SIZE 2 GREEN",
              "EMPTY CAPSULE SIZE 3 BLUE": "EMPTY CAPSULE SIZE 3 BLUE",
              "EMPTY CAPSULE SIZE 3 CLEAR": "EMPTY CAPSULE SIZE 3 CLEAR",
              "EMPTY CAPSULE SIZE 3 GREEN": "EMPTY CAPSULE SIZE 3 GREEN",
              "EMPTY CAPSULE SIZE 3 GRN/BLUE": "EMPTY CAPSULE SIZE 3 GRN/BLUE",
              "EMPTY CAPSULE SIZE 3 PINK": "EMPTY CAPSULE SIZE 3 PINK",
              "EMPTY CAPSULE SIZE 3 PNK/CLEAR": "EMPTY CAPSULE SIZE 3 PNK/CLEAR",
              "EMPTY CAPSULE SIZE 3 RED/CLEAR": "EMPTY CAPSULE SIZE 3 RED/CLEAR",
              "EMPTY CAPSULE SIZE 3 WHITE": "EMPTY CAPSULE SIZE 3 WHITE",
              "EMPTY CAPSULE SIZE 3 WHITE/CLR": "EMPTY CAPSULE SIZE 3 WHITE/CLR",
              "EMPTY CAPSULE SIZE 4 BLUE/WHIT": "EMPTY CAPSULE SIZE 4 BLUE/WHIT",
              "EMPTY CAPSULE SIZE 4 CLEAR": "EMPTY CAPSULE SIZE 4 CLEAR",
              "EMPTY CAPSULE SIZE 5 CLEAR": "EMPTY CAPSULE SIZE 5 CLEAR"
            },
            "FREEDOM PHARMACEUTICALS": {
              "CAPSULE #1-DRCAPS": "CAPSULE #1-DRCAPS",
              "CAPSULE LOCKING #0 CLEAR": "CAPSULE LOCKING #0 CLEAR",
              "CAPSULE LOCKING #00 CLEAR": "CAPSULE LOCKING #00 CLEAR",
              "CAPSULE LOCKING #1 CLEAR": "CAPSULE LOCKING #1 CLEAR",
              "CAPSULE LOCKING #3 CLEAR": "CAPSULE LOCKING #3 CLEAR",
              "GELATIN CAPSULE EMPTY": "GELATIN CAPSULE EMPTY"
            },
            "LETCO MEDICAL": {
              "EMPTY CAPSULE #0 RED/WHITE": "EMPTY CAPSULE #0 RED/WHITE",
              "EMPTY CAPSULE #00 BLACK/RED": "EMPTY CAPSULE #00 BLACK/RED",
              "EMPTY CAPSULE #00 BLUE/WHITE": "EMPTY CAPSULE #00 BLUE/WHITE",
              "EMPTY CAPSULE #00 PINK/PINK": "EMPTY CAPSULE #00 PINK/PINK",
              "EMPTY CAPSULE #00 PURPLE": "EMPTY CAPSULE #00 PURPLE",
              "EMPTY CAPSULE #00 PURPLE/WHITE": "EMPTY CAPSULE #00 PURPLE/WHITE",
              "EMPTY CAPSULE #00 RED/WHITE": "EMPTY CAPSULE #00 RED/WHITE",
              "EMPTY CAPSULE #00 YELLOW/YELLO": "EMPTY CAPSULE #00 YELLOW/YELLO",
              "EMPTY CAPSULE SIZE 0 BLUE": "EMPTY CAPSULE SIZE 0 BLUE",
              "EMPTY CAPSULE SIZE 0 BLUE/WHT": "EMPTY CAPSULE SIZE 0 BLUE/WHT",
              "EMPTY CAPSULE SIZE 0 CLEAR": "EMPTY CAPSULE SIZE 0 CLEAR",
              "EMPTY CAPSULE SIZE 0 GREEN/CLR": "EMPTY CAPSULE SIZE 0 GREEN/CLR",
              "EMPTY CAPSULE SIZE 0 ORANGE": "EMPTY CAPSULE SIZE 0 ORANGE",
              "EMPTY CAPSULE SIZE 0 PINK": "EMPTY CAPSULE SIZE 0 PINK",
              "EMPTY CAPSULE SIZE 0 PURP/WHT": "EMPTY CAPSULE SIZE 0 PURP/WHT",
              "EMPTY CAPSULE SIZE 0 PURPLE": "EMPTY CAPSULE SIZE 0 PURPLE",
              "EMPTY CAPSULE SIZE 0 RED": "EMPTY CAPSULE SIZE 0 RED",
              "EMPTY CAPSULE SIZE 0 RED/CLEAR": "EMPTY CAPSULE SIZE 0 RED/CLEAR",
              "EMPTY CAPSULE SIZE 0 RED/WHITE": "EMPTY CAPSULE SIZE 0 RED/WHITE",
              "EMPTY CAPSULE SIZE 0 WHITE": "EMPTY CAPSULE SIZE 0 WHITE",
              "EMPTY CAPSULE SIZE 0 WHITE/CLR": "EMPTY CAPSULE SIZE 0 WHITE/CLR",
              "EMPTY CAPSULE SIZE 0 YELLOW": "EMPTY CAPSULE SIZE 0 YELLOW",
              "EMPTY CAPSULE SIZE 00 BLUE OPQ": "EMPTY CAPSULE SIZE 00 BLUE OPQ",
              "EMPTY CAPSULE SIZE 00 CLEAR": "EMPTY CAPSULE SIZE 00 CLEAR",
              "EMPTY CAPSULE SIZE 00 DRK GRN": "EMPTY CAPSULE SIZE 00 DRK GRN",
              "EMPTY CAPSULE SIZE 00 ORANGE": "EMPTY CAPSULE SIZE 00 ORANGE",
              "EMPTY CAPSULE SIZE 00 RED": "EMPTY CAPSULE SIZE 00 RED",
              "EMPTY CAPSULE SIZE 00 WHITE": "EMPTY CAPSULE SIZE 00 WHITE",
              "EMPTY CAPSULE SIZE 000 CLEAR": "EMPTY CAPSULE SIZE 000 CLEAR",
              "EMPTY CAPSULE SIZE 1 AQUA BLUE": "EMPTY CAPSULE SIZE 1 AQUA BLUE",
              "EMPTY CAPSULE SIZE 1 BLUE": "EMPTY CAPSULE SIZE 1 BLUE",
              "EMPTY CAPSULE SIZE 1 BLUE/PINK": "EMPTY CAPSULE SIZE 1 BLUE/PINK",
              "EMPTY CAPSULE SIZE 1 BLUE/WHT": "EMPTY CAPSULE SIZE 1 BLUE/WHT",
              "EMPTY CAPSULE SIZE 1 BLUECLEAR": "EMPTY CAPSULE SIZE 1 BLUECLEAR",
              "EMPTY CAPSULE SIZE 1 BRN/IVORY": "EMPTY CAPSULE SIZE 1 BRN/IVORY",
              "EMPTY CAPSULE SIZE 1 CLEAR": "EMPTY CAPSULE SIZE 1 CLEAR",
              "EMPTY CAPSULE SIZE 1 DRK GREEN": "EMPTY CAPSULE SIZE 1 DRK GREEN",
              "EMPTY CAPSULE SIZE 1 GREY/PINK": "EMPTY CAPSULE SIZE 1 GREY/PINK",
              "EMPTY CAPSULE SIZE 1 GRN/ORNGE": "EMPTY CAPSULE SIZE 1 GRN/ORNGE",
              "EMPTY CAPSULE SIZE 1 GRN/WHITE": "EMPTY CAPSULE SIZE 1 GRN/WHITE",
              "EMPTY CAPSULE SIZE 1 GRN/YLLW": "EMPTY CAPSULE SIZE 1 GRN/YLLW",
              "EMPTY CAPSULE SIZE 1 IVORY": "EMPTY CAPSULE SIZE 1 IVORY",
              "EMPTY CAPSULE SIZE 1 LGHT BLUE": "EMPTY CAPSULE SIZE 1 LGHT BLUE",
              "EMPTY CAPSULE SIZE 1 MAROON/CL": "EMPTY CAPSULE SIZE 1 MAROON/CL",
              "EMPTY CAPSULE SIZE 1 MINT GRN": "EMPTY CAPSULE SIZE 1 MINT GRN",
              "EMPTY CAPSULE SIZE 1 ORANGE": "EMPTY CAPSULE SIZE 1 ORANGE",
              "EMPTY CAPSULE SIZE 1 ORGE/CLR": "EMPTY CAPSULE SIZE 1 ORGE/CLR",
              "EMPTY CAPSULE SIZE 1 ORGE/YLLW": "EMPTY CAPSULE SIZE 1 ORGE/YLLW",
              "EMPTY CAPSULE SIZE 1 PINK": "EMPTY CAPSULE SIZE 1 PINK",
              "EMPTY CAPSULE SIZE 1 PINK/BLUE": "EMPTY CAPSULE SIZE 1 PINK/BLUE",
              "EMPTY CAPSULE SIZE 1 PINK/CLR": "EMPTY CAPSULE SIZE 1 PINK/CLR",
              "EMPTY CAPSULE SIZE 1 PINK/YLLW": "EMPTY CAPSULE SIZE 1 PINK/YLLW",
              "EMPTY CAPSULE SIZE 1 PNK/WHITE": "EMPTY CAPSULE SIZE 1 PNK/WHITE",
              "EMPTY CAPSULE SIZE 1 PURPLE": "EMPTY CAPSULE SIZE 1 PURPLE",
              "EMPTY CAPSULE SIZE 1 PWDR BLUE": "EMPTY CAPSULE SIZE 1 PWDR BLUE",
              "EMPTY CAPSULE SIZE 1 RED": "EMPTY CAPSULE SIZE 1 RED",
              "EMPTY CAPSULE SIZE 1 RED/BLUE": "EMPTY CAPSULE SIZE 1 RED/BLUE",
              "EMPTY CAPSULE SIZE 1 RED/WHITE": "EMPTY CAPSULE SIZE 1 RED/WHITE",
              "EMPTY CAPSULE SIZE 1 WHITE": "EMPTY CAPSULE SIZE 1 WHITE",
              "EMPTY CAPSULE SIZE 1 WHT/CLEAR": "EMPTY CAPSULE SIZE 1 WHT/CLEAR",
              "EMPTY CAPSULE SIZE 1 YELLOW": "EMPTY CAPSULE SIZE 1 YELLOW",
              "EMPTY CAPSULE SIZE 2 CLEAR": "EMPTY CAPSULE SIZE 2 CLEAR",
              "EMPTY CAPSULE SIZE 3 BLACK/GRN": "EMPTY CAPSULE SIZE 3 BLACK/GRN",
              "EMPTY CAPSULE SIZE 3 BLUE OPQ": "EMPTY CAPSULE SIZE 3 BLUE OPQ",
              "EMPTY CAPSULE SIZE 3 BLUE/CLR": "EMPTY CAPSULE SIZE 3 BLUE/CLR",
              "EMPTY CAPSULE SIZE 3 BLUE/WHT": "EMPTY CAPSULE SIZE 3 BLUE/WHT",
              "EMPTY CAPSULE SIZE 3 CLEAR": "EMPTY CAPSULE SIZE 3 CLEAR",
              "EMPTY CAPSULE SIZE 3 DARK GRN": "EMPTY CAPSULE SIZE 3 DARK GRN",
              "EMPTY CAPSULE SIZE 3 GREY/PINK": "EMPTY CAPSULE SIZE 3 GREY/PINK",
              "EMPTY CAPSULE SIZE 3 GREY/YLLW": "EMPTY CAPSULE SIZE 3 GREY/YLLW",
              "EMPTY CAPSULE SIZE 3 GRN/BLUE": "EMPTY CAPSULE SIZE 3 GRN/BLUE",
              "EMPTY CAPSULE SIZE 3 MARN/BLUE": "EMPTY CAPSULE SIZE 3 MARN/BLUE",
              "EMPTY CAPSULE SIZE 3 MARN/CLR": "EMPTY CAPSULE SIZE 3 MARN/CLR",
              "EMPTY CAPSULE SIZE 3 MINT GRN": "EMPTY CAPSULE SIZE 3 MINT GRN",
              "EMPTY CAPSULE SIZE 3 OLIVE/CLR": "EMPTY CAPSULE SIZE 3 OLIVE/CLR",
              "EMPTY CAPSULE SIZE 3 ORANGE": "EMPTY CAPSULE SIZE 3 ORANGE",
              "EMPTY CAPSULE SIZE 3 ORANGE/WH": "EMPTY CAPSULE SIZE 3 ORANGE/WH",
              "EMPTY CAPSULE SIZE 3 PINK": "EMPTY CAPSULE SIZE 3 PINK",
              "EMPTY CAPSULE SIZE 3 PINK/BLUE": "EMPTY CAPSULE SIZE 3 PINK/BLUE",
              "EMPTY CAPSULE SIZE 3 PINK/WH": "EMPTY CAPSULE SIZE 3 PINK/WH",
              "EMPTY CAPSULE SIZE 3 PINK/YLLW": "EMPTY CAPSULE SIZE 3 PINK/YLLW",
              "EMPTY CAPSULE SIZE 3 PNK/CLEAR": "EMPTY CAPSULE SIZE 3 PNK/CLEAR",
              "EMPTY CAPSULE SIZE 3 PRPLE/CLR": "EMPTY CAPSULE SIZE 3 PRPLE/CLR",
              "EMPTY CAPSULE SIZE 3 PURPLE": "EMPTY CAPSULE SIZE 3 PURPLE",
              "EMPTY CAPSULE SIZE 3 PWDR BLUE": "EMPTY CAPSULE SIZE 3 PWDR BLUE",
              "EMPTY CAPSULE SIZE 3 RED": "EMPTY CAPSULE SIZE 3 RED",
              "EMPTY CAPSULE SIZE 3 RED/CLEAR": "EMPTY CAPSULE SIZE 3 RED/CLEAR",
              "EMPTY CAPSULE SIZE 3 RED/WHITE": "EMPTY CAPSULE SIZE 3 RED/WHITE",
              "EMPTY CAPSULE SIZE 3 WHITE": "EMPTY CAPSULE SIZE 3 WHITE",
              "EMPTY CAPSULE SIZE 3 WHITE/CLR": "EMPTY CAPSULE SIZE 3 WHITE/CLR",
              "EMPTY CAPSULE SIZE 3 YELLOW": "EMPTY CAPSULE SIZE 3 YELLOW",
              "EMPTY CAPSULE SIZE 3 YELLW/CLR": "EMPTY CAPSULE SIZE 3 YELLW/CLR",
              "EMPTY CAPSULE SIZE 4 CLEAR": "EMPTY CAPSULE SIZE 4 CLEAR",
              "EMPTY CAPSULE SIZE 4 PURPLE": "EMPTY CAPSULE SIZE 4 PURPLE",
              "EMPTY CAPSULE SIZE 4 RED/WHITE": "EMPTY CAPSULE SIZE 4 RED/WHITE",
              "EMPTY CAPSULE SIZE 4 WHITE": "EMPTY CAPSULE SIZE 4 WHITE",
              "EMPTY CAPSULE SIZE 4 YELLOW": "EMPTY CAPSULE SIZE 4 YELLOW",
              "EMPTY CAPSULE SIZE 5 CLEAR": "EMPTY CAPSULE SIZE 5 CLEAR",
              "EMPTY CAPSULE SIZE 7 CLEAR": "EMPTY CAPSULE SIZE 7 CLEAR"
            },
            "MEDISCA": {
              "CAPSULE CONI-SNAP #0 BLU/WHITE": "CAPSULE CONI-SNAP #0 BLU/WHITE",
              "CAPSULE CONI-SNAP #0 CLEAR": "CAPSULE CONI-SNAP #0 CLEAR",
              "CAPSULE CONI-SNAP #0 DARK BLUE": "CAPSULE CONI-SNAP #0 DARK BLUE",
              "CAPSULE CONI-SNAP #0 GREEN/CLR": "CAPSULE CONI-SNAP #0 GREEN/CLR",
              "CAPSULE CONI-SNAP #0 PINK": "CAPSULE CONI-SNAP #0 PINK",
              "CAPSULE CONI-SNAP #0 PURPLE": "CAPSULE CONI-SNAP #0 PURPLE",
              "CAPSULE CONI-SNAP #0 RED/WHITE": "CAPSULE CONI-SNAP #0 RED/WHITE",
              "CAPSULE CONI-SNAP #0 WHITE": "CAPSULE CONI-SNAP #0 WHITE",
              "CAPSULE CONI-SNAP #00 CLEAR": "CAPSULE CONI-SNAP #00 CLEAR",
              "CAPSULE CONI-SNAP #00 WHITE": "CAPSULE CONI-SNAP #00 WHITE",
              "CAPSULE CONI-SNAP #000 CLEAR": "CAPSULE CONI-SNAP #000 CLEAR",
              "CAPSULE CONI-SNAP #1 AQUA BLUE": "CAPSULE CONI-SNAP #1 AQUA BLUE",
              "CAPSULE CONI-SNAP #1 BLUE": "CAPSULE CONI-SNAP #1 BLUE",
              "CAPSULE CONI-SNAP #1 BLUE/PINK": "CAPSULE CONI-SNAP #1 BLUE/PINK",
              "CAPSULE CONI-SNAP #1 BLUE/WHT": "CAPSULE CONI-SNAP #1 BLUE/WHT",
              "CAPSULE CONI-SNAP #1 BROWN": "CAPSULE CONI-SNAP #1 BROWN",
              "CAPSULE CONI-SNAP #1 BRWN/IVRY": "CAPSULE CONI-SNAP #1 BRWN/IVRY",
              "CAPSULE CONI-SNAP #1 CLEAR": "CAPSULE CONI-SNAP #1 CLEAR",
              "CAPSULE CONI-SNAP #1 DK GRN/OR": "CAPSULE CONI-SNAP #1 DK GRN/OR",
              "CAPSULE CONI-SNAP #1 DRK GREEN": "CAPSULE CONI-SNAP #1 DRK GREEN",
              "CAPSULE CONI-SNAP #1 GREY/PINK": "CAPSULE CONI-SNAP #1 GREY/PINK",
              "CAPSULE CONI-SNAP #1 GRN/YLW": "CAPSULE CONI-SNAP #1 GRN/YLW",
              "CAPSULE CONI-SNAP #1 ORANGE": "CAPSULE CONI-SNAP #1 ORANGE",
              "CAPSULE CONI-SNAP #1 PINK": "CAPSULE CONI-SNAP #1 PINK",
              "CAPSULE CONI-SNAP #1 PINK/BLUE": "CAPSULE CONI-SNAP #1 PINK/BLUE",
              "CAPSULE CONI-SNAP #1 PINK/CLR": "CAPSULE CONI-SNAP #1 PINK/CLR",
              "CAPSULE CONI-SNAP #1 PINK/WHIT": "CAPSULE CONI-SNAP #1 PINK/WHIT",
              "CAPSULE CONI-SNAP #1 PINK/YLLW": "CAPSULE CONI-SNAP #1 PINK/YLLW",
              "CAPSULE CONI-SNAP #1 PURPLE": "CAPSULE CONI-SNAP #1 PURPLE",
              "CAPSULE CONI-SNAP #1 RED/BLUE": "CAPSULE CONI-SNAP #1 RED/BLUE",
              "CAPSULE CONI-SNAP #1 RED/WHITE": "CAPSULE CONI-SNAP #1 RED/WHITE",
              "CAPSULE CONI-SNAP #1 WHITE": "CAPSULE CONI-SNAP #1 WHITE",
              "CAPSULE CONI-SNAP #1 WHITE/GRN": "CAPSULE CONI-SNAP #1 WHITE/GRN",
              "CAPSULE CONI-SNAP #1 WHT/CLR": "CAPSULE CONI-SNAP #1 WHT/CLR",
              "CAPSULE CONI-SNAP #1 YELLOW": "CAPSULE CONI-SNAP #1 YELLOW",
              "CAPSULE CONI-SNAP #1 YELLOW/GR": "CAPSULE CONI-SNAP #1 YELLOW/GR",
              "CAPSULE CONI-SNAP #2 CLEAR": "CAPSULE CONI-SNAP #2 CLEAR",
              "CAPSULE CONI-SNAP #2 WHITE": "CAPSULE CONI-SNAP #2 WHITE",
              "CAPSULE CONI-SNAP #3 BLU/CLEAR": "CAPSULE CONI-SNAP #3 BLU/CLEAR",
              "CAPSULE CONI-SNAP #3 BRN/BLUE": "CAPSULE CONI-SNAP #3 BRN/BLUE",
              "CAPSULE CONI-SNAP #3 GRAY/YLW": "CAPSULE CONI-SNAP #3 GRAY/YLW",
              "CAPSULE CONI-SNAP #3 GREEN/BLU": "CAPSULE CONI-SNAP #3 GREEN/BLU",
              "CAPSULE CONI-SNAP #3 GREY/PINK": "CAPSULE CONI-SNAP #3 GREY/PINK",
              "CAPSULE CONI-SNAP #3 MARON/BLU": "CAPSULE CONI-SNAP #3 MARON/BLU",
              "CAPSULE CONI-SNAP #3 MINT GRN": "CAPSULE CONI-SNAP #3 MINT GRN",
              "CAPSULE CONI-SNAP #3 OLIVE/CLR": "CAPSULE CONI-SNAP #3 OLIVE/CLR",
              "CAPSULE CONI-SNAP #3 ORANGE": "CAPSULE CONI-SNAP #3 ORANGE",
              "CAPSULE CONI-SNAP #3 PINK/PINK": "CAPSULE CONI-SNAP #3 PINK/PINK",
              "CAPSULE CONI-SNAP #3 PNK/CLEAR": "CAPSULE CONI-SNAP #3 PNK/CLEAR",
              "CAPSULE CONI-SNAP #3 RED/CLEAR": "CAPSULE CONI-SNAP #3 RED/CLEAR",
              "CAPSULE CONI-SNAP #3 RED/RED": "CAPSULE CONI-SNAP #3 RED/RED",
              "CAPSULE CONI-SNAP #3 WHITE": "CAPSULE CONI-SNAP #3 WHITE",
              "CAPSULE CONI-SNAP #3 WHT/CLR": "CAPSULE CONI-SNAP #3 WHT/CLR",
              "CAPSULE CONI-SNAP #3 YELLOW": "CAPSULE CONI-SNAP #3 YELLOW",
              "CAPSULE CONI-SNAP #4 BLACK/GRN": "CAPSULE CONI-SNAP #4 BLACK/GRN",
              "CAPSULE CONI-SNAP #4 CLEAR": "CAPSULE CONI-SNAP #4 CLEAR",
              "CAPSULE CONI-SNAP #4 WHITE": "CAPSULE CONI-SNAP #4 WHITE",
              "CAPSULE EZEEFIT #0 CLEAR": "CAPSULE EZEEFIT #0 CLEAR",
              "CAPSULE EZEEFIT #00 CLEAR": "CAPSULE EZEEFIT #00 CLEAR",
              "EMPTY CAPSULE SIZE 0 CLEAR": "EMPTY CAPSULE SIZE 0 CLEAR",
              "EMPTY CAPSULE SIZE 0 WHITE/OPA": "EMPTY CAPSULE SIZE 0 WHITE/OPA",
              "EMPTY CAPSULE SIZE 1 CLEAR": "EMPTY CAPSULE SIZE 1 CLEAR",
              "EMPTY CAPSULE SIZE 1 WHITE/OPA": "EMPTY CAPSULE SIZE 1 WHITE/OPA",
              "EMPTY CAPSULE SIZE 3 CLEAR": "EMPTY CAPSULE SIZE 3 CLEAR",
              "EMPTY CAPSULE SIZE 3 WHITE/OPA": "EMPTY CAPSULE SIZE 3 WHITE/OPA"
            },
            "PCCA": {
              "DRCAPS SIZE 0": "DRCAPS SIZE 0",
              "DRCAPS SIZE 00": "DRCAPS SIZE 00",
              "DRCAPS SIZE 1": "DRCAPS SIZE 1",
              "EMPTY CAPSULE SIZE 0 CLEAR": "EMPTY CAPSULE SIZE 0 CLEAR",
              "EMPTY CAPSULE SIZE 0 FUN CAPS": "EMPTY CAPSULE SIZE 0 FUN CAPS",
              "EMPTY CAPSULE SIZE 0 GREEN": "EMPTY CAPSULE SIZE 0 GREEN",
              "EMPTY CAPSULE SIZE 0 GRN/CLEAR": "EMPTY CAPSULE SIZE 0 GRN/CLEAR",
              "EMPTY CAPSULE SIZE 0 MAROON": "EMPTY CAPSULE SIZE 0 MAROON",
              "EMPTY CAPSULE SIZE 0 ORANGE": "EMPTY CAPSULE SIZE 0 ORANGE",
              "EMPTY CAPSULE SIZE 0 PINK": "EMPTY CAPSULE SIZE 0 PINK",
              "EMPTY CAPSULE SIZE 0 PURPLE": "EMPTY CAPSULE SIZE 0 PURPLE",
              "EMPTY CAPSULE SIZE 0 RED/WHITE": "EMPTY CAPSULE SIZE 0 RED/WHITE",
              "EMPTY CAPSULE SIZE 0 WHITE": "EMPTY CAPSULE SIZE 0 WHITE",
              "EMPTY CAPSULE SIZE 0 YELLOW": "EMPTY CAPSULE SIZE 0 YELLOW",
              "EMPTY CAPSULE SIZE 00 BLUE": "EMPTY CAPSULE SIZE 00 BLUE",
              "EMPTY CAPSULE SIZE 00 CLEAR": "EMPTY CAPSULE SIZE 00 CLEAR",
              "EMPTY CAPSULE SIZE 00 GREEN": "EMPTY CAPSULE SIZE 00 GREEN",
              "EMPTY CAPSULE SIZE 00 ORANGE": "EMPTY CAPSULE SIZE 00 ORANGE",
              "EMPTY CAPSULE SIZE 00 WHITE": "EMPTY CAPSULE SIZE 00 WHITE",
              "EMPTY CAPSULE SIZE 000 CLEAR": "EMPTY CAPSULE SIZE 000 CLEAR",
              "EMPTY CAPSULE SIZE 000 WHITE": "EMPTY CAPSULE SIZE 000 WHITE",
              "EMPTY CAPSULE SIZE 1 BLUE": "EMPTY CAPSULE SIZE 1 BLUE",
              "EMPTY CAPSULE SIZE 1 BLUE/PINK": "EMPTY CAPSULE SIZE 1 BLUE/PINK",
              "EMPTY CAPSULE SIZE 1 BLUE/RED": "EMPTY CAPSULE SIZE 1 BLUE/RED",
              "EMPTY CAPSULE SIZE 1 BRN/IVORY": "EMPTY CAPSULE SIZE 1 BRN/IVORY",
              "EMPTY CAPSULE SIZE 1 CLEAR": "EMPTY CAPSULE SIZE 1 CLEAR",
              "EMPTY CAPSULE SIZE 1 GREEN": "EMPTY CAPSULE SIZE 1 GREEN",
              "EMPTY CAPSULE SIZE 1 GRN/YLLW": "EMPTY CAPSULE SIZE 1 GRN/YLLW",
              "EMPTY CAPSULE SIZE 1 ORANGE": "EMPTY CAPSULE SIZE 1 ORANGE",
              "EMPTY CAPSULE SIZE 1 PINK": "EMPTY CAPSULE SIZE 1 PINK",
              "EMPTY CAPSULE SIZE 1 PURPLE": "EMPTY CAPSULE SIZE 1 PURPLE",
              "EMPTY CAPSULE SIZE 1 PWDR BLUE": "EMPTY CAPSULE SIZE 1 PWDR BLUE",
              "EMPTY CAPSULE SIZE 1 RED": "EMPTY CAPSULE SIZE 1 RED",
              "EMPTY CAPSULE SIZE 1 WHITE": "EMPTY CAPSULE SIZE 1 WHITE",
              "EMPTY CAPSULE SIZE 10 CLEAR": "EMPTY CAPSULE SIZE 10 CLEAR",
              "EMPTY CAPSULE SIZE 13 CLEAR": "EMPTY CAPSULE SIZE 13 CLEAR",
              "EMPTY CAPSULE SIZE 2 CLEAR": "EMPTY CAPSULE SIZE 2 CLEAR",
              "EMPTY CAPSULE SIZE 2 WHITE": "EMPTY CAPSULE SIZE 2 WHITE",
              "EMPTY CAPSULE SIZE 3 BLUE": "EMPTY CAPSULE SIZE 3 BLUE",
              "EMPTY CAPSULE SIZE 3 CLEAR": "EMPTY CAPSULE SIZE 3 CLEAR",
              "EMPTY CAPSULE SIZE 3 GRAY/PINK": "EMPTY CAPSULE SIZE 3 GRAY/PINK",
              "EMPTY CAPSULE SIZE 3 GRAY/YLLW": "EMPTY CAPSULE SIZE 3 GRAY/YLLW",
              "EMPTY CAPSULE SIZE 3 GREEN": "EMPTY CAPSULE SIZE 3 GREEN",
              "EMPTY CAPSULE SIZE 3 MAROON": "EMPTY CAPSULE SIZE 3 MAROON",
              "EMPTY CAPSULE SIZE 3 OLIVE": "EMPTY CAPSULE SIZE 3 OLIVE",
              "EMPTY CAPSULE SIZE 3 ORANGE": "EMPTY CAPSULE SIZE 3 ORANGE",
              "EMPTY CAPSULE SIZE 3 PINK": "EMPTY CAPSULE SIZE 3 PINK",
              "EMPTY CAPSULE SIZE 3 PNK/CLEAR": "EMPTY CAPSULE SIZE 3 PNK/CLEAR",
              "EMPTY CAPSULE SIZE 3 RED": "EMPTY CAPSULE SIZE 3 RED",
              "EMPTY CAPSULE SIZE 3 WHITE": "EMPTY CAPSULE SIZE 3 WHITE",
              "EMPTY CAPSULE SIZE 3 WHITE/CLR": "EMPTY CAPSULE SIZE 3 WHITE/CLR",
              "EMPTY CAPSULE SIZE 3 YELLOW": "EMPTY CAPSULE SIZE 3 YELLOW",
              "EMPTY CAPSULE SIZE 4 BLACK": "EMPTY CAPSULE SIZE 4 BLACK",
              "EMPTY CAPSULE SIZE 4 CLEAR": "EMPTY CAPSULE SIZE 4 CLEAR",
              "EMPTY CAPSULE SIZE 4 DARK BLUE": "EMPTY CAPSULE SIZE 4 DARK BLUE",
              "EMPTY CAPSULE SIZE 4 PURPLE": "EMPTY CAPSULE SIZE 4 PURPLE",
              "EMPTY CAPSULE SIZE 4 WHITE": "EMPTY CAPSULE SIZE 4 WHITE",
              "EMPTY CAPSULE SIZE 5 CLEAR": "EMPTY CAPSULE SIZE 5 CLEAR",
              "EMPTY CAPSULE SIZE 7 CLEAR": "EMPTY CAPSULE SIZE 7 CLEAR"
            },
            "SPECTRUM": {
              "EMPTY CAPSULE SIZE 0 CLEAR": "EMPTY CAPSULE SIZE 0 CLEAR",
              "EMPTY CAPSULE SIZE 0 WHITE": "EMPTY CAPSULE SIZE 0 WHITE",
              "EMPTY CAPSULE SIZE 0 WHITE/CLR": "EMPTY CAPSULE SIZE 0 WHITE/CLR",
              "EMPTY CAPSULE SIZE 00 CLEAR": "EMPTY CAPSULE SIZE 00 CLEAR",
              "EMPTY CAPSULE SIZE 00 WHITE": "EMPTY CAPSULE SIZE 00 WHITE",
              "EMPTY CAPSULE SIZE 00 WHT/CLR": "EMPTY CAPSULE SIZE 00 WHT/CLR",
              "EMPTY CAPSULE SIZE 000 CLEAR": "EMPTY CAPSULE SIZE 000 CLEAR",
              "EMPTY CAPSULE SIZE 1 CLEAR": "EMPTY CAPSULE SIZE 1 CLEAR",
              "EMPTY CAPSULE SIZE 1 GRN/YLLW": "EMPTY CAPSULE SIZE 1 GRN/YLLW",
              "EMPTY CAPSULE SIZE 1 MINT GRN": "EMPTY CAPSULE SIZE 1 MINT GRN",
              "EMPTY CAPSULE SIZE 1 ORGE/CLR": "EMPTY CAPSULE SIZE 1 ORGE/CLR",
              "EMPTY CAPSULE SIZE 1 ORNGE/GRN": "EMPTY CAPSULE SIZE 1 ORNGE/GRN",
              "EMPTY CAPSULE SIZE 1 WHITE": "EMPTY CAPSULE SIZE 1 WHITE",
              "EMPTY CAPSULE SIZE 1 WHT/CLEAR": "EMPTY CAPSULE SIZE 1 WHT/CLEAR",
              "EMPTY CAPSULE SIZE 2 CLEAR": "EMPTY CAPSULE SIZE 2 CLEAR",
              "EMPTY CAPSULE SIZE 2 WHITE": "EMPTY CAPSULE SIZE 2 WHITE",
              "EMPTY CAPSULE SIZE 3 BLUE/CLR": "EMPTY CAPSULE SIZE 3 BLUE/CLR",
              "EMPTY CAPSULE SIZE 3 BLUE/MARN": "EMPTY CAPSULE SIZE 3 BLUE/MARN",
              "EMPTY CAPSULE SIZE 3 CLEAR": "EMPTY CAPSULE SIZE 3 CLEAR",
              "EMPTY CAPSULE SIZE 3 DARK ORNG": "EMPTY CAPSULE SIZE 3 DARK ORNG",
              "EMPTY CAPSULE SIZE 3 PINK": "EMPTY CAPSULE SIZE 3 PINK",
              "EMPTY CAPSULE SIZE 3 PNK/CLEAR": "EMPTY CAPSULE SIZE 3 PNK/CLEAR",
              "EMPTY CAPSULE SIZE 3 RED": "EMPTY CAPSULE SIZE 3 RED",
              "EMPTY CAPSULE SIZE 3 WHITE": "EMPTY CAPSULE SIZE 3 WHITE",
              "EMPTY CAPSULE SIZE 3 WHITE/CLR": "EMPTY CAPSULE SIZE 3 WHITE/CLR",
              "EMPTY CAPSULE SIZE 4 CLEAR": "EMPTY CAPSULE SIZE 4 CLEAR",
              "EMPTY CAPSULE SIZE 4 WHITE/CLR": "EMPTY CAPSULE SIZE 4 WHITE/CLR"
            }
          }
        },
        "Internal Vehicle Ingredients/Agents": {
          "Thickening Agents": {
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS INSTANT FOOD THICKENER": "CVS INSTANT FOOD THICKENER"
            },
            "HORMEL FOODS": {
              "THICK & EASY": "THICK & EASY"
            },
            "HORMEL HEALTH LABS": {
              "THICK & EASY": "THICK & EASY"
            },
            "KENT PRECISION FOODS": {
              "THICK-IT": "THICK-IT",
              "THICK-IT #2": "THICK-IT #2"
            },
            "NESTLE HEALTHCARE NUTRITION": {
              "RESOURCE THICKENUP": "RESOURCE THICKENUP",
              "RESOURCE THICKENUP CLEAR": "RESOURCE THICKENUP CLEAR",
              "RESOURCE THICKENUP WATER": "RESOURCE THICKENUP WATER"
            },
            "NUTRA BALANCE": {
              "THIK & CLEAR": "THIK & CLEAR"
            },
            "PARAPHARMA TECH": {
              "GELMIX INFANT THICKENER": "GELMIX INFANT THICKENER",
              "PURATHICK": "PURATHICK"
            },
            "SIMPLYTHICK": {
              "SIMPLYTHICK": "SIMPLYTHICK",
              "SIMPLYTHICK EASY MIX": "SIMPLYTHICK EASY MIX"
            },
            "WALGREENS": {
              "THICK NOW": "THICK NOW"
            }
          }
        },
        "Liquid Vehicles": {
          "External Vehicles": {
            "AAPER ALCOHOL & CHEMICAL CO.": {
              "ETHYL ALCOHOL": "ETHYL ALCOHOL",
              "REGENT ALCOHOL": "REGENT ALCOHOL"
            },
            "FAGRON": {
              "COLLODION": "COLLODION",
              "COLLODION FLEXIBLE": "COLLODION FLEXIBLE",
              "ETHYL ALCOHOL": "ETHYL ALCOHOL"
            },
            "HUMCO": {
              "COLLODION FLEXIBLE": "COLLODION FLEXIBLE",
              "ROSE WATER": "ROSE WATER"
            },
            "LETCO MEDICAL": {
              "ETHYL ALCOHOL": "ETHYL ALCOHOL",
              "SUPPOSIBASE F": "SUPPOSIBASE F"
            },
            "MEDISCA": {
              "ADA": "ADA",
              "COLLODION FLEXIBLE": "COLLODION FLEXIBLE",
              "ETHYL ALCOHOL": "ETHYL ALCOHOL",
              "FOAMIL": "FOAMIL",
              "U-MILD": "U-MILD",
              "VERSAPRO": "VERSAPRO"
            },
            "PCCA": {
              "COLLODION": "COLLODION",
              "COLLODION FLEXIBLE": "COLLODION FLEXIBLE",
              "ETHYL ALCOHOL": "ETHYL ALCOHOL",
              "RHEOSPRAY": "RHEOSPRAY"
            },
            "PERRIGO": {
              "SUSPENDOL-S": "SUSPENDOL-S"
            },
            "PURE TEK": {
              "DERMACINRX TOPICAL BASE": "DERMACINRX TOPICAL BASE",
              "DERMACINRX TSS BASE": "DERMACINRX TSS BASE",
              "TDM SOLUTION": "TDM SOLUTION"
            },
            "SPECTRUM": {
              "COLLODION": "COLLODION",
              "COLLODION FLEXIBLE": "COLLODION FLEXIBLE",
              "ETHYL ALCOHOL": "ETHYL ALCOHOL"
            }
          },
          "Misc. Vehicles": {
            "FAGRON": {
              "SERAQUA": "SERAQUA",
              "SOLYDRA": "SOLYDRA"
            },
            "HUMCO": {
              "FIXED OIL SUSPENSION": "FIXED OIL SUSPENSION"
            },
            "PCCA": {
              "PCCA FIXED OIL BASE": "PCCA FIXED OIL BASE",
              "PCCA PRACAMAC BASE": "PCCA PRACAMAC BASE"
            }
          },
          "Oral Vehicles": {
            "ABBOTT NUTRITION": {
              "SIMILAC STERILIZED WATER": "SIMILAC STERILIZED WATER"
            },
            "AMERICAN SALES COMPANY": {
              "COLA SYRUP": "COLA SYRUP"
            },
            "APOTHECA SUPPLY": {
              "DENTAL BASE": "DENTAL BASE"
            },
            "CAMBER PHARMACEUTICALS": {
              "CHERRY": "CHERRY",
              "SIMPLE SYRUP": "SIMPLE SYRUP",
              "SOSWEET": "SOSWEET"
            },
            "CMP PHARMA": {
              "SORBITOL": "SORBITOL"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS DISTILLED WATER": "CVS DISTILLED WATER",
              "CVS PURIFIED WATER": "CVS PURIFIED WATER"
            },
            "DISTILLATA COMPANY": {
              "DISTILLATA DISTILLED WATER": "DISTILLATA DISTILLED WATER"
            },
            "FAGRON": {
              "CHERRY": "CHERRY",
              "CHERRY CONCENTRATE": "CHERRY CONCENTRATE",
              "FREEDOM PEG TROCHE BASE": "FREEDOM PEG TROCHE BASE",
              "GRAPE SYRUP": "GRAPE SYRUP",
              "LOZIBASE": "LOZIBASE",
              "LOZIBASE S": "LOZIBASE S",
              "RASPBERRY SYRUP": "RASPBERRY SYRUP",
              "SIMPLE SYRUP": "SIMPLE SYRUP",
              "SORBITOL": "SORBITOL",
              "SYRSPEND SF": "SYRSPEND SF",
              "SYRSPEND SF ALKA": "SYRSPEND SF ALKA",
              "SYRSPEND SF PH4": "SYRSPEND SF PH4",
              "TROCHIBASE": "TROCHIBASE",
              "TROCHIBASE S": "TROCHIBASE S",
              "TROCHIBASE S CLASSIC": "TROCHIBASE S CLASSIC",
              "UNISPEND ANHYDROUS SWEETENED": "UNISPEND ANHYDROUS SWEETENED",
              "UNISPEND ANHYDROUS UNSWEETENED": "UNISPEND ANHYDROUS UNSWEETENED"
            },
            "FREEDOM PHARMACEUTICALS": {
              "FREEDOM PEG TROCHE BASE": "FREEDOM PEG TROCHE BASE"
            },
            "HUMCO": {
              "CHERRY": "CHERRY",
              "COLA SYRUP": "COLA SYRUP",
              "FLAVOR BLEND": "FLAVOR BLEND",
              "FLAVOR PLUS": "FLAVOR PLUS",
              "FLAVOR SWEET": "FLAVOR SWEET",
              "FLAVOR SWEET-SF": "FLAVOR SWEET-SF",
              "MOUTH WASH-GP": "MOUTH WASH-GP",
              "MOUTHWASH-AF": "MOUTHWASH-AF",
              "MOUTHWASH-OM": "MOUTHWASH-OM",
              "PEG TROCHE BASE": "PEG TROCHE BASE",
              "RASPBERRY SYRUP": "RASPBERRY SYRUP",
              "SIMPLE SYRUP": "SIMPLE SYRUP",
              "SORBITOL": "SORBITOL",
              "SYRPALTA": "SYRPALTA",
              "SYRPALTA (RED)": "SYRPALTA (RED)",
              "VERSAFREE": "VERSAFREE",
              "VERSAPLUS": "VERSAPLUS"
            },
            "LETCO MEDICAL": {
              "BASE GELATIN GUMMY TROCHE": "BASE GELATIN GUMMY TROCHE",
              "SORBITOL": "SORBITOL",
              "SUSPENSION VEHICLE": "SUSPENSION VEHICLE",
              "SYRUP VEHICLE": "SYRUP VEHICLE",
              "SYRUP VEHICLE SF": "SYRUP VEHICLE SF",
              "TROCHE BASE": "TROCHE BASE",
              "TROCHE BASE SF W/BITTER-BLOC": "TROCHE BASE SF W/BITTER-BLOC"
            },
            "MAJOR PHARMACEUTICALS": {
              "BLENDED SUSPENDING COMPOUND": "BLENDED SUSPENDING COMPOUND",
              "ORAL SUSPENDING COMPOUND PLUS": "ORAL SUSPENDING COMPOUND PLUS",
              "SWEETENING SUSPENDING COMPOUND": "SWEETENING SUSPENDING COMPOUND"
            },
            "MEDISCA": {
              "GUM BASE (GELATIN)": "GUM BASE (GELATIN)",
              "ORA-BLEND": "ORA-BLEND",
              "ORA-BLEND SF": "ORA-BLEND SF",
              "ORA-PLUS": "ORA-PLUS",
              "ORA-SWEET": "ORA-SWEET",
              "ORA-SWEET SF": "ORA-SWEET SF",
              "ORAL MIX": "ORAL MIX",
              "ORAL MIX DRY ALKA SF": "ORAL MIX DRY ALKA SF",
              "ORAL MIX DRY ALKA/CHERRY": "ORAL MIX DRY ALKA/CHERRY",
              "ORAL MIX SF": "ORAL MIX SF",
              "ORAL SUSPEND": "ORAL SUSPEND",
              "ORAL SYRUP": "ORAL SYRUP",
              "ORAL SYRUP SF": "ORAL SYRUP SF",
              "PURIFIED WATER": "PURIFIED WATER",
              "SIMPLE SYRUP": "SIMPLE SYRUP",
              "SORBITOL": "SORBITOL",
              "TROCHE BASE": "TROCHE BASE"
            },
            "MEIJER": {
              "INFANT DRINKING WATER": "INFANT DRINKING WATER"
            },
            "NESTLE HEALTHCARE NUTRITION": {
              "GERBER GOOD START WATER": "GERBER GOOD START WATER",
              "GOOD START STERILE WATER": "GOOD START STERILE WATER"
            },
            "PATRIN PHARMA": {
              "MX-SOL": "MX-SOL",
              "MX-SOL BLEND": "MX-SOL BLEND",
              "MX-SOL BLEND SF": "MX-SOL BLEND SF",
              "MX-SOL SF": "MX-SOL SF",
              "MX-SOL SUSPEND": "MX-SOL SUSPEND"
            },
            "PCCA": {
              "CORN (SYRUP)": "CORN (SYRUP)",
              "CUSTOM POLYGLYCOL TROCHE BASE": "CUSTOM POLYGLYCOL TROCHE BASE",
              "PCCA ACACIA SYRUP BASE": "PCCA ACACIA SYRUP BASE",
              "PCCA CUSTOM NATATROCHE HMP": "PCCA CUSTOM NATATROCHE HMP",
              "PCCA CUSTOM TROCHE BASE": "PCCA CUSTOM TROCHE BASE",
              "PCCA NATATROCHE BASE": "PCCA NATATROCHE BASE",
              "PCCA POLYGLYCOL TROCHE": "PCCA POLYGLYCOL TROCHE",
              "PCCA SWEET-SF": "PCCA SWEET-SF",
              "PCCA SYRUP VEHICLE": "PCCA SYRUP VEHICLE",
              "PCCA-PLUS": "PCCA-PLUS",
              "SORBITOL": "SORBITOL"
            },
            "PERRIGO PHARMACEUTICALS": {
              "ORA-BLEND": "ORA-BLEND",
              "ORA-BLEND SF": "ORA-BLEND SF",
              "ORA-PLUS": "ORA-PLUS",
              "ORA-SWEET": "ORA-SWEET",
              "ORA-SWEET SF": "ORA-SWEET SF"
            },
            "PUBLIX SUPER MARKETS INC.": {
              "PX PURIFIED WATER": "PX PURIFIED WATER"
            },
            "REESE PHARMACEUTICAL": {
              "COLA SYRUP": "COLA SYRUP"
            },
            "RITE AID CORPORATION": {
              "RA CRYSTAL LAKE DIST WATER": "RA CRYSTAL LAKE DIST WATER"
            },
            "SOLUTECH PHARMACEUTICALS": {
              "SOLVATECH PLUS": "SOLVATECH PLUS",
              "SOLVATECH SWEET SF": "SOLVATECH SWEET SF"
            },
            "SPECIALIZED RX PRODUCTS": {
              "SUSPENDRX W/BITTERBLOC SWEET": "SUSPENDRX W/BITTERBLOC SWEET",
              "SUSPENDRX W/BITTERBLOC UNSWEET": "SUSPENDRX W/BITTERBLOC UNSWEET",
              "TECHNA 20 SF TROCHE BASE": "TECHNA 20 SF TROCHE BASE",
              "TECHNA NATURAL SF TROCHE BASE": "TECHNA NATURAL SF TROCHE BASE",
              "VITATROCHE PLUS BASE SF": "VITATROCHE PLUS BASE SF"
            },
            "SPECTRUM": {
              "CHERRY": "CHERRY",
              "PURIFIED WATER": "PURIFIED WATER",
              "SORBITOL": "SORBITOL",
              "SYRUP NF": "SYRUP NF"
            },
            "WAL-MART": {
              "DISTILLED WATER": "DISTILLED WATER"
            },
            "WALGREENS": {
              "NICE DISTILLED WATER": "NICE DISTILLED WATER"
            }
          },
          "Parenteral Vehicles": {
            "A-S MEDICATION SOLUTIONS": {
              "BACTERIOSTATIC WATER(BENZ ALC)": "BACTERIOSTATIC WATER(BENZ ALC)",
              "SODIUM CHLORIDE BACTERIOSTATIC": "SODIUM CHLORIDE BACTERIOSTATIC",
              "STERILE WATER FOR INJECTION": "STERILE WATER FOR INJECTION"
            },
            "ALK LABS": {
              "HSA STERILE DILUENT": "HSA STERILE DILUENT"
            },
            "AMERICAN REGENT": {
              "STERILE WATER FOR INJECTION": "STERILE WATER FOR INJECTION"
            },
            "ASTRAZENECA": {
              "IV STABILIZER FOR LUMOXITI": "IV STABILIZER FOR LUMOXITI"
            },
            "B. BRAUN MEDICAL": {
              "STERILE WATER FOR INJECTION": "STERILE WATER FOR INJECTION"
            },
            "BAXTER HEALTHCARE CORP": {
              "STERILE WATER FOR INJECTION": "STERILE WATER FOR INJECTION"
            },
            "EMPOWER PHARMACY": {
              "BACTERIOSTATIC WATER(BENZ ALC)": "BACTERIOSTATIC WATER(BENZ ALC)"
            },
            "ENOVACHEM MANUFACTURING": {
              "STERILE WATER FOR INJECTION": "STERILE WATER FOR INJECTION"
            },
            "FRESENIUS KABI USA": {
              "BACTERIOSTATIC WATER(PARABENS)": "BACTERIOSTATIC WATER(PARABENS)",
              "SALINE BACTERIOSTATIC": "SALINE BACTERIOSTATIC",
              "STERILE WATER FOR INJECTION": "STERILE WATER FOR INJECTION"
            },
            "GLAXO SMITH KLINE": {
              "STERILE DILUENT FLOLAN PH 12": "STERILE DILUENT FLOLAN PH 12",
              "STERILE DILUENT FOR FLOLAN": "STERILE DILUENT FOR FLOLAN"
            },
            "HIKMA": {
              "STERILE WATER FOR INJECTION": "STERILE WATER FOR INJECTION"
            },
            "HOSPIRA": {
              "BACTERIOSTATIC WATER(BENZ ALC)": "BACTERIOSTATIC WATER(BENZ ALC)",
              "SODIUM CHLORIDE BACTERIOSTATIC": "SODIUM CHLORIDE BACTERIOSTATIC",
              "STERILE WATER FOR INJECTION": "STERILE WATER FOR INJECTION"
            },
            "ICU MEDICAL": {
              "STERILE WATER FOR INJECTION": "STERILE WATER FOR INJECTION"
            },
            "INNATE PHARMA": {
              "IV STABILIZER FOR LUMOXITI": "IV STABILIZER FOR LUMOXITI"
            },
            "JUBILANT HOLLISTERSTIER": {
              "SALINE-PHENOL": "SALINE-PHENOL"
            },
            "MEDEFIL, INC": {
              "STERILE WATER FOR INJECTION": "STERILE WATER FOR INJECTION"
            },
            "NABRIVA THERAPEUTICS": {
              "DILUENT FOR LEFAMULIN": "DILUENT FOR LEFAMULIN"
            },
            "NEPHRON STERILE COMPOUNDING": {
              "STERILE WATER FOR INJECTION": "STERILE WATER FOR INJECTION"
            },
            "QUALITY CARE": {
              "SODIUM CHLORIDE BACTERIOSTATIC": "SODIUM CHLORIDE BACTERIOSTATIC"
            },
            "SANDOZ": {
              "DILUENT FOR TREPROSTINIL": "DILUENT FOR TREPROSTINIL"
            },
            "STERRX": {
              "STERILE WATER FOR INJECTION": "STERILE WATER FOR INJECTION"
            },
            "TEVA PARENTERAL MEDICINES": {
              "STERILE DILUENT/EPOPROSTENOL": "STERILE DILUENT/EPOPROSTENOL"
            },
            "UNITED THERAPEUTICS CORP": {
              "STERILE DILUENT FOR REMODULIN": "STERILE DILUENT FOR REMODULIN"
            }
          }
        },
        "Non Gelatin Capsules (Empty)": {
          "Non Gelatin Capsules (Empty)": {
            "FAGRON": {
              "CAPSULE 0 CLEAR DR": "CAPSULE 0 CLEAR DR",
              "CAPSULE 0 CLEAR VEGGIE": "CAPSULE 0 CLEAR VEGGIE",
              "CAPSULE 1 CLEAR VEGGIE": "CAPSULE 1 CLEAR VEGGIE",
              "CAPSULE 3 CLEAR VEGGIE": "CAPSULE 3 CLEAR VEGGIE"
            },
            "FREEDOM PHARMACEUTICALS": {
              "CAPSULE 0 CLEAR VEGGIE": "CAPSULE 0 CLEAR VEGGIE"
            },
            "LETCO MEDICAL": {
              "CAPSULE 0 CLEAR VEGGIE": "CAPSULE 0 CLEAR VEGGIE",
              "EMPTY CAPSULE SIZE 1 VEG CLEAR": "EMPTY CAPSULE SIZE 1 VEG CLEAR",
              "VEGETABLE CAPSULE #0 GREEN": "VEGETABLE CAPSULE #0 GREEN",
              "VEGETABLE CAPSULE #0 WHITE": "VEGETABLE CAPSULE #0 WHITE",
              "VEGETABLE CAPSULE #00 WHITE": "VEGETABLE CAPSULE #00 WHITE",
              "VEGETABLE CAPSULE #1 WHITE": "VEGETABLE CAPSULE #1 WHITE",
              "VEGETABLE CAPSULE #2 WHITE": "VEGETABLE CAPSULE #2 WHITE",
              "VEGETABLE CAPSULE #3 WHITE": "VEGETABLE CAPSULE #3 WHITE",
              "VEGETABLE CAPSULE #4 WHITE": "VEGETABLE CAPSULE #4 WHITE"
            },
            "MEDISCA": {
              "CAPSULE CONI-SNAP #1 VEGGIE": "CAPSULE CONI-SNAP #1 VEGGIE",
              "CAPSULE CONI-SNAP #3 CLEAR": "CAPSULE CONI-SNAP #3 CLEAR"
            },
            "PCCA": {
              "AR CAPS #1 ACID RESISTANT": "AR CAPS #1 ACID RESISTANT",
              "CAPSULE 0 CLEAR VEGGIE": "CAPSULE 0 CLEAR VEGGIE",
              "CAPSULE 00 CLEAR VEGGIE": "CAPSULE 00 CLEAR VEGGIE",
              "CAPSULE 1 CLEAR VEGGIE": "CAPSULE 1 CLEAR VEGGIE",
              "CAPSULE 3 CLEAR VEGGIE": "CAPSULE 3 CLEAR VEGGIE"
            },
            "SPECTRUM": {
              "CAPSULE 0 CLEAR VEGGIE": "CAPSULE 0 CLEAR VEGGIE",
              "CAPSULE 00 CLEAR VEGGIE": "CAPSULE 00 CLEAR VEGGIE",
              "CAPSULE 1 CLEAR VEGGIE": "CAPSULE 1 CLEAR VEGGIE"
            }
          }
        },
        "Pharmaceutical Adjuvants Miscellanous": {
          "Buffer Reference Standards": {
            "MEDISCA": {
              "PH BUFFER": "PH BUFFER"
            },
            "PCCA": {
              "METER BUFFER": "METER BUFFER"
            }
          }
        },
        "Pharmaceutical Excipients": {
          "External Vehicle Ingredients": {
            "FAGRON": {
              "ACACIA": "ACACIA",
              "BEESWAX": "BEESWAX",
              "BENTONITE": "BENTONITE",
              "CETYL ALCOHOL": "CETYL ALCOHOL",
              "EMULSIFYING WAX": "EMULSIFYING WAX",
              "GELATIN": "GELATIN",
              "LIPOIL": "LIPOIL",
              "METHYLCELLULOSE": "METHYLCELLULOSE",
              "PARAFFIN": "PARAFFIN"
            },
            "FREEDOM PHARMACEUTICALS": {
              "ACACIA": "ACACIA",
              "METHYLCELLULOSE": "METHYLCELLULOSE"
            },
            "HUMCO": {
              "CAMPHOR BLOCKS": "CAMPHOR BLOCKS"
            },
            "KALCHEM INTERNATIONAL": {
              "STEARYL ALCOHOL": "STEARYL ALCOHOL",
              "WHITE BEES WAX": "WHITE BEES WAX"
            },
            "LETCO MEDICAL": {
              "ACACIA": "ACACIA",
              "CETYL ALCOHOL": "CETYL ALCOHOL",
              "GELATIN": "GELATIN",
              "METHYLCELLULOSE": "METHYLCELLULOSE",
              "PARAFFIN WAX BEADS": "PARAFFIN WAX BEADS",
              "STEARYL ALCOHOL": "STEARYL ALCOHOL",
              "WAX, WHITE": "WAX, WHITE"
            },
            "MEDISCA": {
              "ACACIA": "ACACIA",
              "BEES WAX": "BEES WAX",
              "CETYL ALCOHOL": "CETYL ALCOHOL",
              "GELATIN": "GELATIN",
              "LIPMAX": "LIPMAX",
              "METHYLCELLULOSE": "METHYLCELLULOSE",
              "SODIUM LAURYL SULFATE": "SODIUM LAURYL SULFATE",
              "STEARYL ALCOHOL": "STEARYL ALCOHOL"
            },
            "PCCA": {
              "ARLASILK PHOSPHOLIPID PLN": "ARLASILK PHOSPHOLIPID PLN",
              "ASTRAGALUS ROOT": "ASTRAGALUS ROOT",
              "BEESWAX": "BEESWAX",
              "BEESWAX (YELLOW)": "BEESWAX (YELLOW)",
              "BENTONITE": "BENTONITE",
              "CETYL ALCOHOL": "CETYL ALCOHOL",
              "EMULSIFYING WAX": "EMULSIFYING WAX",
              "GELATIN": "GELATIN",
              "GUM ARABIC": "GUM ARABIC",
              "METHYLCELLULOSE": "METHYLCELLULOSE",
              "OLEIC ACID": "OLEIC ACID",
              "PARAFFIN": "PARAFFIN",
              "PCCA LECITHIN ISOPROPYL PALM": "PCCA LECITHIN ISOPROPYL PALM",
              "SODIUM LAURYL SULFATE": "SODIUM LAURYL SULFATE",
              "STEARYL ALCOHOL": "STEARYL ALCOHOL",
              "TRAGACANTH": "TRAGACANTH"
            },
            "SPECTRUM": {
              "ACACIA": "ACACIA",
              "BENTONITE": "BENTONITE",
              "CETYL ALCOHOL": "CETYL ALCOHOL",
              "EMULSIFYING WAX": "EMULSIFYING WAX",
              "GELATIN": "GELATIN",
              "GUM ARABIC": "GUM ARABIC",
              "LECITHIN-ISOPROPYL PALMITATE": "LECITHIN-ISOPROPYL PALMITATE",
              "METHYLCELLULOSE": "METHYLCELLULOSE",
              "PARAFFIN": "PARAFFIN",
              "SODIUM LAURYL SULFATE": "SODIUM LAURYL SULFATE",
              "STEARYL ALCOHOL": "STEARYL ALCOHOL",
              "TRAGACANTH": "TRAGACANTH",
              "WAX, WHITE": "WAX, WHITE",
              "WHITE WAX": "WHITE WAX",
              "YELLOW WAX": "YELLOW WAX",
              "ZINC STEARATE": "ZINC STEARATE"
            }
          },
          "Pharmaceutical Excipients": {
            "AMERICAN PHARMA INGREDIENTS": {
              "LACTOSE MONOHYDRATE": "LACTOSE MONOHYDRATE"
            },
            "APOTHECA SUPPLY": {
              "LACTOSE MONOHYDRATE": "LACTOSE MONOHYDRATE"
            },
            "B & B PHARMACEUTICALS": {
              "SUPPOSI-PURE": "SUPPOSI-PURE"
            },
            "BPI LABS LLC": {
              "LACTOSE ANHYDROUS": "LACTOSE ANHYDROUS",
              "LACTOSE MONOHYDRATE": "LACTOSE MONOHYDRATE"
            },
            "ENCORE SCIENTIFIC": {
              "LACTOSE MONOHYDRATE": "LACTOSE MONOHYDRATE",
              "MAGNESIUM STEARATE": "MAGNESIUM STEARATE",
              "XANTHAN GUM": "XANTHAN GUM"
            },
            "FAGRON": {
              "COCOA BUTTER": "COCOA BUTTER",
              "FAGRON CAPFILL PRO": "FAGRON CAPFILL PRO",
              "FAGRON DISPERSAPRO": "FAGRON DISPERSAPRO",
              "FATTYBLEND": "FATTYBLEND",
              "FREEDOM LOLLIPOP BASE": "FREEDOM LOLLIPOP BASE",
              "FREEDOM ODT BASE": "FREEDOM ODT BASE",
              "FREEDOM SIMPLECAP": "FREEDOM SIMPLECAP",
              "KARAYA GUM": "KARAYA GUM",
              "LACTOSE": "LACTOSE",
              "LACTOSE ANHYDROUS": "LACTOSE ANHYDROUS",
              "LACTOSE MONOHYDRATE": "LACTOSE MONOHYDRATE",
              "LOLLIBASE": "LOLLIBASE",
              "MAGNESIUM STEARATE": "MAGNESIUM STEARATE",
              "NATURAL BITTERNESS": "NATURAL BITTERNESS",
              "POLOX": "POLOX",
              "POLOXAMER 407": "POLOXAMER 407",
              "POLYMATRIX": "POLYMATRIX",
              "RDT BASE": "RDT BASE",
              "SHEA BUTTER": "SHEA BUTTER",
              "SODIUM BENZOATE": "SODIUM BENZOATE",
              "STEARIC ACID": "STEARIC ACID",
              "SUPPOSIBLEND": "SUPPOSIBLEND",
              "WITEPSOL": "WITEPSOL",
              "XANTHAN GUM": "XANTHAN GUM"
            },
            "FREEDOM PHARMACEUTICALS": {
              "FREEDOM LOLLIPOP BASE": "FREEDOM LOLLIPOP BASE",
              "FREEDOM ODT BASE": "FREEDOM ODT BASE",
              "FREEDOM POLOXITOL": "FREEDOM POLOXITOL",
              "FREEDOM POLYMATRIX": "FREEDOM POLYMATRIX",
              "FREEDOM SIMPLECAP": "FREEDOM SIMPLECAP",
              "FREEDOM TRIBLEND BASE": "FREEDOM TRIBLEND BASE",
              "LACTOSE MONOHYDRATE": "LACTOSE MONOHYDRATE",
              "MAGNESIUM STEARATE": "MAGNESIUM STEARATE",
              "POLOXAMER 407": "POLOXAMER 407",
              "SODIUM THIOSULFATE": "SODIUM THIOSULFATE",
              "STEARIC ACID": "STEARIC ACID",
              "XANTHAN GUM": "XANTHAN GUM"
            },
            "HUMCO": {
              "BUFFER CREAM": "BUFFER CREAM",
              "COCOA BUTTER": "COCOA BUTTER",
              "EMULSION CONCENTRATE": "EMULSION CONCENTRATE",
              "KARAYA GUM": "KARAYA GUM",
              "LACTOSE HYDROUS": "LACTOSE HYDROUS",
              "SUPPOSITORY BLEND": "SUPPOSITORY BLEND"
            },
            "KALCHEM INTERNATIONAL": {
              "LACTOSE MONOHYDRATE": "LACTOSE MONOHYDRATE",
              "PLURONIC F127": "PLURONIC F127",
              "STEARIC ACID": "STEARIC ACID"
            },
            "LETCO MEDICAL": {
              "COCOA BUTTER": "COCOA BUTTER",
              "ISOMALT LOLLIPOP BASE": "ISOMALT LOLLIPOP BASE",
              "LACTOSE ANHYDROUS": "LACTOSE ANHYDROUS",
              "LACTOSE MONOHYDRATE": "LACTOSE MONOHYDRATE",
              "MAGNESIUM STEARATE": "MAGNESIUM STEARATE",
              "PLURONIC F127": "PLURONIC F127",
              "SEPINEO P 600": "SEPINEO P 600",
              "SHEA BUTTER ORGANIC": "SHEA BUTTER ORGANIC",
              "SODIUM BENZOATE": "SODIUM BENZOATE",
              "SODIUM THIOSULFATE": "SODIUM THIOSULFATE",
              "SORBITOL CANDY BASE": "SORBITOL CANDY BASE",
              "STEARIC ACID": "STEARIC ACID",
              "WITEPSOL": "WITEPSOL",
              "XANTHAN GUM": "XANTHAN GUM"
            },
            "MEDISCA": {
              "CAPSUBLEND-H": "CAPSUBLEND-H",
              "CAPSUBLEND-P": "CAPSUBLEND-P",
              "CAPSUBLEND-S": "CAPSUBLEND-S",
              "COLLASIL OSA": "COLLASIL OSA",
              "F-MELT": "F-MELT",
              "FIZZMIX BASE": "FIZZMIX BASE",
              "KARAYA GUM": "KARAYA GUM",
              "LACTOSE ANHYDROUS": "LACTOSE ANHYDROUS",
              "LACTOSE MONOHYDRATE": "LACTOSE MONOHYDRATE",
              "LIQUIGEL COMPLEX": "LIQUIGEL COMPLEX",
              "MEDI-RDT": "MEDI-RDT",
              "MEDI-RDT BASE": "MEDI-RDT BASE",
              "POLOXAMER 407": "POLOXAMER 407",
              "POLYPEG": "POLYPEG",
              "SODIUM BENZOATE": "SODIUM BENZOATE",
              "SPG SUPPOSI-BASE": "SPG SUPPOSI-BASE",
              "STEARIC ACID": "STEARIC ACID",
              "WITEPSOL": "WITEPSOL",
              "XANTHAN GUM": "XANTHAN GUM"
            },
            "PCCA": {
              "ALKYL ACRYLATE CROSSPOLYMER": "ALKYL ACRYLATE CROSSPOLYMER",
              "BACOCALMINE": "BACOCALMINE",
              "BASE X": "BASE X",
              "BITTER DRUG": "BITTER DRUG",
              "CARRAGEENAN": "CARRAGEENAN",
              "COCOA BUTTER": "COCOA BUTTER",
              "EFFERVESCENT BASE": "EFFERVESCENT BASE",
              "EMULGADE CM": "EMULGADE CM",
              "ETHYL ACETATE": "ETHYL ACETATE",
              "LACTOSE ANHYDROUS": "LACTOSE ANHYDROUS",
              "LACTOSE MONOHYDRATE": "LACTOSE MONOHYDRATE",
              "LOXORAL BASE": "LOXORAL BASE",
              "MAGNESIUM STEARATE": "MAGNESIUM STEARATE",
              "MUCOLOX": "MUCOLOX",
              "PCCA CUSTOM RDT": "PCCA CUSTOM RDT",
              "PCCA EMULSIFIX-205 BASE": "PCCA EMULSIFIX-205 BASE",
              "PCCA LOXASPERSE BASE": "PCCA LOXASPERSE BASE",
              "PCCA MBK (FATTY ACID) BASE": "PCCA MBK (FATTY ACID) BASE",
              "PCCA RAPID DISSOLVE TABLET": "PCCA RAPID DISSOLVE TABLET",
              "PCCA SORBITOL LOLLIPOP BASE": "PCCA SORBITOL LOLLIPOP BASE",
              "PCCA XYLIFOS BASE": "PCCA XYLIFOS BASE",
              "PLURONIC L64": "PLURONIC L64",
              "POLOXAMER 188": "POLOXAMER 188",
              "POLOXAMER 407": "POLOXAMER 407",
              "RDT-PLUS": "RDT-PLUS",
              "SHEA BUTTER": "SHEA BUTTER",
              "SODIUM BENZOATE": "SODIUM BENZOATE",
              "SODIUM THIOSULFATE": "SODIUM THIOSULFATE",
              "STEARIC ACID": "STEARIC ACID",
              "SYNAPSIN": "SYNAPSIN",
              "UCARE POLYMER JR-400": "UCARE POLYMER JR-400",
              "WITEPSOL": "WITEPSOL",
              "XANTHAN GUM": "XANTHAN GUM"
            },
            "SPECIALIZED RX PRODUCTS": {
              "KRYSTAL SF": "KRYSTAL SF",
              "NEXTOL SF": "NEXTOL SF",
              "SUPPOSI-PLEX R36": "SUPPOSI-PLEX R36",
              "SUPPOSI-PLEX V33": "SUPPOSI-PLEX V33"
            },
            "SPECTRUM": {
              "COCOA BUTTER": "COCOA BUTTER",
              "ETHYL ACETATE": "ETHYL ACETATE",
              "KARAYA GUM": "KARAYA GUM",
              "LACTOSE ANHYDROUS": "LACTOSE ANHYDROUS",
              "LACTOSE MONOHYDRATE": "LACTOSE MONOHYDRATE",
              "MAGNESIUM STEARATE": "MAGNESIUM STEARATE",
              "PLURONIC": "PLURONIC",
              "POLOXAMER 188": "POLOXAMER 188",
              "POLOXAMER 407": "POLOXAMER 407",
              "SEPINEO P 600": "SEPINEO P 600",
              "SHEA BUTTER": "SHEA BUTTER",
              "SODIUM BENZOATE": "SODIUM BENZOATE",
              "SODIUM THIOSULFATE": "SODIUM THIOSULFATE",
              "SORBITOL CANDY BASE": "SORBITOL CANDY BASE",
              "STEARIC ACID": "STEARIC ACID",
              "WITEPSOL": "WITEPSOL",
              "XANTHAN GUM": "XANTHAN GUM"
            },
            "TOTAL PHARMACY SUPPLY": {
              "LACTOSE MONOHYDRATE": "LACTOSE MONOHYDRATE"
            },
            "WALGREENS": {
              "COCOA BUTTER": "COCOA BUTTER"
            }
          }
        },
        "Placebos": {
          "Placebos": {
            "MALLINCKRODT PHARM": {
              "CHERRY CONCENTRATE": "CHERRY CONCENTRATE"
            },
            "PCCA": {
              "PLACEBO #00": "PLACEBO #00"
            }
          }
        },
        "Semi Solid Vehicles": {
          "Semi Solid Vehicle Combination": {
            "HUMCO": {
              "LIDOCAINE-PRILOCAINE (BULK)": "LIDOCAINE-PRILOCAINE (BULK)"
            },
            "PIERSON LABORATORIES": {
              "LIDOCAINE-PRILOCAINE (BULK)": "LIDOCAINE-PRILOCAINE (BULK)"
            }
          },
          "Semi Solid Vehicles": {
            "1ST CLASS PHARMACEUTICALS": {
              "1ST BASE": "1ST BASE"
            },
            "ALEXSO": {
              "HRT BASE": "HRT BASE",
              "TERODERM": "TERODERM",
              "TERODERM-PLUS": "TERODERM-PLUS"
            },
            "ALVIX LABORATORIES": {
              "TDC MAX": "TDC MAX"
            },
            "ALWAYS 30 LLC": {
              "HRT BASE": "HRT BASE",
              "MICROSOME BASE": "MICROSOME BASE"
            },
            "API SOLUTIONS": {
              "CUTIS PLUS": "CUTIS PLUS"
            },
            "APOTHECA SUPPLY": {
              "APOTHEDERM": "APOTHEDERM",
              "APOTHESAR": "APOTHESAR",
              "APOTHESAR 2": "APOTHESAR 2",
              "APOTHESAR PLUS": "APOTHESAR PLUS",
              "APOTHESIL": "APOTHESIL",
              "DELIVRA": "DELIVRA",
              "PLO PREMIUM LECITH ORGANOGEL": "PLO PREMIUM LECITH ORGANOGEL",
              "PLO ULTRAMAX": "PLO ULTRAMAX",
              "REJUVACARE PLUS": "REJUVACARE PLUS",
              "TRANS D HORMONE BASE": "TRANS D HORMONE BASE",
              "TRANS-PEN": "TRANS-PEN"
            },
            "B & B PHARMACEUTICALS": {
              "BRAVURA ALL-IN-ONE": "BRAVURA ALL-IN-ONE",
              "EXQUISITE HRT": "EXQUISITE HRT",
              "PERFORMAX SALT SUPPORTIVE BASE": "PERFORMAX SALT SUPPORTIVE BASE"
            },
            "BERGEN BRUNSWIG": {
              "GNP PETROLEUM JELLY": "GNP PETROLEUM JELLY"
            },
            "BPI LABS LLC": {
              "LIPOFOAM RX": "LIPOFOAM RX",
              "LIPOZYME": "LIPOZYME",
              "P-SILOXAN DS": "P-SILOXAN DS",
              "POLYETHYLENE GLYCOL 300": "POLYETHYLENE GLYCOL 300",
              "SILPROTEX PLUS": "SILPROTEX PLUS"
            },
            "CELTIC BIOTECH": {
              "EPISORB": "EPISORB"
            },
            "COVIDIEN MEDICAL SUPPLIES": {
              "VASELINE": "VASELINE",
              "VASELINE PURE ULTRA WHITE": "VASELINE PURE ULTRA WHITE"
            },
            "CROWN LABORATORIES": {
              "DELBASE COMPOUNDING": "DELBASE COMPOUNDING"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS PETROLEUM JELLY": "CVS PETROLEUM JELLY"
            },
            "CYCLIN PHARMACEUTICALS": {
              "SUPPOSIBASE": "SUPPOSIBASE"
            },
            "DENISON LABS": {
              "WHITE PETROLATUM": "WHITE PETROLATUM"
            },
            "DYNAREX CORPORATION": {
              "WHITE PETROLATUM": "WHITE PETROLATUM"
            },
            "ENCORE SCIENTIFIC": {
              "BASE A POLYETHYLENE GLYCOL": "BASE A POLYETHYLENE GLYCOL",
              "DERMASHIELD HYDROGEL": "DERMASHIELD HYDROGEL",
              "PENSOMAL": "PENSOMAL",
              "POLYETHYLENE GLYCOL 300": "POLYETHYLENE GLYCOL 300",
              "POLYMAC PROGEL": "POLYMAC PROGEL",
              "RENEWCREAM HRT": "RENEWCREAM HRT"
            },
            "ENOVACHEM MANUFACTURING": {
              "MICRODERM BASE": "MICRODERM BASE"
            },
            "FAGRON": {
              "ANHYDROUS BASE": "ANHYDROUS BASE",
              "ANHYDROUS GEL BASE": "ANHYDROUS GEL BASE",
              "AUXIPRO VANISHING": "AUXIPRO VANISHING",
              "AZ CREAM": "AZ CREAM",
              "CARBOGEL 940": "CARBOGEL 940",
              "CARBOHOL 940": "CARBOHOL 940",
              "CHEW-HESIVE": "CHEW-HESIVE",
              "CLEODERM": "CLEODERM",
              "CLOVAGEL": "CLOVAGEL",
              "EMOLIVAN": "EMOLIVAN",
              "EMOLLIENT BASE": "EMOLLIENT BASE",
              "ESPUMIL": "ESPUMIL",
              "FAGRON LS PLUS": "FAGRON LS PLUS",
              "FAGRON NATURAL": "FAGRON NATURAL",
              "FAGRON SUPREME": "FAGRON SUPREME",
              "FAGRON VERSIFIX": "FAGRON VERSIFIX",
              "FITALITE": "FITALITE",
              "FREEDOM ADAPTADERM": "FREEDOM ADAPTADERM",
              "FREEDOM CEPAPRO": "FREEDOM CEPAPRO",
              "FREEDOM DERMA SERUM": "FREEDOM DERMA SERUM",
              "FREEDOM DERMA-D": "FREEDOM DERMA-D",
              "FREEDOM DERMA-N": "FREEDOM DERMA-N",
              "FREEDOM SILOMAC ANHYDROUS": "FREEDOM SILOMAC ANHYDROUS",
              "HRT NATURAL": "HRT NATURAL",
              "HYDROPHILIC": "HYDROPHILIC",
              "HYDROPHILIC PETROLATUM": "HYDROPHILIC PETROLATUM",
              "HYDROUS EMULSIFIED BASE": "HYDROUS EMULSIFIED BASE",
              "JELENE": "JELENE",
              "LANOLIN ANHYDROUS": "LANOLIN ANHYDROUS",
              "LIOPEN ABSORPTION ENHANCING": "LIOPEN ABSORPTION ENHANCING",
              "LIP BALM BASE": "LIP BALM BASE",
              "LIP BALM BASE NATURAL": "LIP BALM BASE NATURAL",
              "LIPOBASE": "LIPOBASE",
              "LIPOPEN ANHYDROUS": "LIPOPEN ANHYDROUS",
              "LIPOPEN ULTRA BASE": "LIPOPEN ULTRA BASE",
              "LIPOVAN BASE": "LIPOVAN BASE",
              "NOURILITE": "NOURILITE",
              "NOURIVAN ANTIOX BASE": "NOURIVAN ANTIOX BASE",
              "OCCLUVAN": "OCCLUVAN",
              "ORA-HESIVE BASE": "ORA-HESIVE BASE",
              "PEG 300": "PEG 300",
              "PEG BLEND": "PEG BLEND",
              "PEG OINTMENT BASE": "PEG OINTMENT BASE",
              "PFCB": "PFCB",
              "PHARMABASE ANTIOXIDANT": "PHARMABASE ANTIOXIDANT",
              "PHARMABASE COSMETIC": "PHARMABASE COSMETIC",
              "PHARMABASE COSMETIC NATURAL": "PHARMABASE COSMETIC NATURAL",
              "PHARMABASE HEAVY": "PHARMABASE HEAVY",
              "PHARMABASE LIGHT": "PHARMABASE LIGHT",
              "PHARMABASE VAGINAL": "PHARMABASE VAGINAL",
              "PHYTOBASE": "PHYTOBASE",
              "POLYETHYLENE GLYCOL 1000": "POLYETHYLENE GLYCOL 1000",
              "POLYETHYLENE GLYCOL 1450": "POLYETHYLENE GLYCOL 1450",
              "POLYETHYLENE GLYCOL 3350": "POLYETHYLENE GLYCOL 3350",
              "POLYETHYLENE GLYCOL 400": "POLYETHYLENE GLYCOL 400",
              "POLYETHYLENE GLYCOL 8000": "POLYETHYLENE GLYCOL 8000",
              "U-BASE": "U-BASE",
              "UNIBASE": "UNIBASE",
              "VANIBASE": "VANIBASE",
              "VERSATILE CREAM BASE": "VERSATILE CREAM BASE",
              "VERSATILE RICH BASE": "VERSATILE RICH BASE",
              "VERSIGEL": "VERSIGEL",
              "WHITE PETROLATUM": "WHITE PETROLATUM",
              "YELLOW PETROLATUM": "YELLOW PETROLATUM"
            },
            "FOCUS HEALTH GROUP": {
              "WHITE PETROLATUM": "WHITE PETROLATUM"
            },
            "FOUGERA": {
              "WHITE PETROLATUM": "WHITE PETROLATUM"
            },
            "FREEDOM PHARMACEUTICALS": {
              "AUXIPRO EMOLLIENT": "AUXIPRO EMOLLIENT",
              "AUXIPRO PLASTICIZED": "AUXIPRO PLASTICIZED",
              "AUXIPRO VANISHING": "AUXIPRO VANISHING",
              "BASE A POLYETHYLENE GLYCOL": "BASE A POLYETHYLENE GLYCOL",
              "ESPUMIL": "ESPUMIL",
              "FP CEPA": "FP CEPA",
              "FP DERM": "FP DERM",
              "FP ENHANCING": "FP ENHANCING",
              "FP NATURAL": "FP NATURAL",
              "FP PLUS": "FP PLUS",
              "FP SILO": "FP SILO",
              "FP SUPREME": "FP SUPREME",
              "FP ULTRA": "FP ULTRA",
              "FREEDOM ADAPTADERM": "FREEDOM ADAPTADERM",
              "FREEDOM ALCOHOL GEL": "FREEDOM ALCOHOL GEL",
              "FREEDOM CEPAPRO": "FREEDOM CEPAPRO",
              "FREEDOM DERMA SERUM": "FREEDOM DERMA SERUM",
              "FREEDOM DERMA-D": "FREEDOM DERMA-D",
              "FREEDOM DERMA-N": "FREEDOM DERMA-N",
              "FREEDOM HRT ULTRA": "FREEDOM HRT ULTRA",
              "FREEDOM SET-GEL": "FREEDOM SET-GEL",
              "FREEDOM SILOMAC ANHYDROUS": "FREEDOM SILOMAC ANHYDROUS",
              "FREEDOM SILOMAC ULTRA": "FREEDOM SILOMAC ULTRA",
              "FREEDOM ULTRAPEN": "FREEDOM ULTRAPEN",
              "HRT NATURAL BASE": "HRT NATURAL BASE",
              "HRT NATURAL CREAM BASE": "HRT NATURAL CREAM BASE",
              "HRT SUPREME CREAM BASE": "HRT SUPREME CREAM BASE",
              "LIPOPEN": "LIPOPEN",
              "LIPOPEN ANHYDROUS": "LIPOPEN ANHYDROUS",
              "LIPOPEN PLUS": "LIPOPEN PLUS",
              "LIPOPEN ULTRA": "LIPOPEN ULTRA",
              "PETROLATUM WHITE": "PETROLATUM WHITE",
              "POLYETHYLENE GLYCOL 300": "POLYETHYLENE GLYCOL 300"
            },
            "GEISS DESTIN & DUNN": {
              "GOODSENSE PETROLEUM JELLY": "GOODSENSE PETROLEUM JELLY"
            },
            "GERI-CARE": {
              "WHITE PETROLATUM": "WHITE PETROLATUM"
            },
            "GERITREX CORPORATION": {
              "GRX WHITE PETROLATUM": "GRX WHITE PETROLATUM",
              "HYDROPHILIC": "HYDROPHILIC",
              "LANOLIN ANHYDROUS-GRX": "LANOLIN ANHYDROUS-GRX"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "POLYETHYLENE GLYCOL 3350": "POLYETHYLENE GLYCOL 3350"
            },
            "HUMCO": {
              "ADVANCED BASE PLUS": "ADVANCED BASE PLUS",
              "ANHYDROUS BASE": "ANHYDROUS BASE",
              "BASE FOR SALTS": "BASE FOR SALTS",
              "BASE FOR SCARS": "BASE FOR SCARS",
              "BASE FOR WOUNDS": "BASE FOR WOUNDS",
              "BHRT BASE": "BHRT BASE",
              "H20 GEL BASE": "H20 GEL BASE",
              "HRT BOTANICAL": "HRT BOTANICAL",
              "HRT HEAVY": "HRT HEAVY",
              "LIPOSOMAL HEAVY": "LIPOSOMAL HEAVY",
              "LIPOSOMAL REGULAR": "LIPOSOMAL REGULAR",
              "MULTIBASE": "MULTIBASE",
              "PENCREAM": "PENCREAM",
              "PLASTICIZED BASE": "PLASTICIZED BASE",
              "PLO20 BASE": "PLO20 BASE",
              "PLO20 FLOWABLE": "PLO20 FLOWABLE",
              "POLYETHYLENE GLYCOL 300": "POLYETHYLENE GLYCOL 300",
              "SALT STABLE LS ADVANCED": "SALT STABLE LS ADVANCED",
              "SALTSTABLE LO": "SALTSTABLE LO",
              "SANARE ADVANCED SCAR THERAPY": "SANARE ADVANCED SCAR THERAPY",
              "SANARE SCAR THERAPY": "SANARE SCAR THERAPY",
              "SEDANARE": "SEDANARE",
              "SILOSOME TRANSDERMAL": "SILOSOME TRANSDERMAL",
              "TIGHTENING BASE": "TIGHTENING BASE",
              "UNIVERSAL WATER": "UNIVERSAL WATER",
              "WILEY BASIC ELEMENTS BHRT BASE": "WILEY BASIC ELEMENTS BHRT BASE",
              "WOUND WASH BASE": "WOUND WASH BASE",
              "ZOE SCRIPTS IDEALBASE": "ZOE SCRIPTS IDEALBASE"
            },
            "INNOVATIVE COSMECEUTICALS": {
              "ARBEM H-COSMETIC": "ARBEM H-COSMETIC",
              "ARBEM LIPOPEN": "ARBEM LIPOPEN"
            },
            "INTAPORT COMPANY": {
              "PLASTIBASE": "PLASTIBASE"
            },
            "KALCHEM INTERNATIONAL": {
              "BASE A POLYETHYLENE GLYCOL": "BASE A POLYETHYLENE GLYCOL",
              "BASE C POLYETHYLENE GLYCOL 300": "BASE C POLYETHYLENE GLYCOL 300",
              "LANOLIN": "LANOLIN",
              "WHITE PETROLATUM": "WHITE PETROLATUM"
            },
            "LEADER BRAND PRODUCTS": {
              "PETROLEUM JELLY": "PETROLEUM JELLY"
            },
            "LETCO MEDICAL": {
              "ANHYDROUS CREAM BASE": "ANHYDROUS CREAM BASE",
              "CARBOMER AQUEOUS": "CARBOMER AQUEOUS",
              "CARBOMER HYDROALCOHOLIC": "CARBOMER HYDROALCOHOLIC",
              "CREAM BASE": "CREAM BASE",
              "CREAM CONCENTRATE": "CREAM CONCENTRATE",
              "EMOLLIENT BASE": "EMOLLIENT BASE",
              "HORMONE CREAM BASE": "HORMONE CREAM BASE",
              "HRT CREAM BASE": "HRT CREAM BASE",
              "LANOLIN ANHYDROUS": "LANOLIN ANHYDROUS",
              "LECITHIN ORGANOGEL": "LECITHIN ORGANOGEL",
              "LIPOLAYER": "LIPOLAYER",
              "PETROLATUM WHITE": "PETROLATUM WHITE",
              "PLASTICIZED BASE": "PLASTICIZED BASE",
              "PLURONIC F127": "PLURONIC F127",
              "POLYETHYLENE GLYCOL 1450": "POLYETHYLENE GLYCOL 1450",
              "POLYETHYLENE GLYCOL 300": "POLYETHYLENE GLYCOL 300",
              "POLYETHYLENE GLYCOL 3350": "POLYETHYLENE GLYCOL 3350",
              "POLYETHYLENE GLYCOL 400": "POLYETHYLENE GLYCOL 400",
              "POLYETHYLENE GLYCOL 8000": "POLYETHYLENE GLYCOL 8000",
              "SALT DURABLE CREAM": "SALT DURABLE CREAM",
              "SCAR CARE": "SCAR CARE",
              "SCAR CARE BASE ENHANCED": "SCAR CARE BASE ENHANCED",
              "SIMPLGEL 30": "SIMPLGEL 30",
              "TOMMY GEL": "TOMMY GEL",
              "VANISHING": "VANISHING",
              "VANISHING CREAM BOTANICAL BASE": "VANISHING CREAM BOTANICAL BASE",
              "WOUND CARE": "WOUND CARE"
            },
            "LIL DRUG STORE PRODUCTS": {
              "PETROLEUM JELLY": "PETROLEUM JELLY"
            },
            "MAJOR PHARMACEUTICALS": {
              "WHITE PETROLEUM JELLY": "WHITE PETROLEUM JELLY"
            },
            "MCKESSON": {
              "HM PETROLEUM JELLY": "HM PETROLEUM JELLY"
            },
            "MCKESSON SUNMARK": {
              "SM PETROLEUM JELLY": "SM PETROLEUM JELLY"
            },
            "MEDCO LAB": {
              "CREAM BASE": "CREAM BASE",
              "PEG": "PEG"
            },
            "MEDISCA": {
              "ALPAWASH": "ALPAWASH",
              "HRT BASE (MEN)": "HRT BASE (MEN)",
              "HRT CREAM BASE WOMEN": "HRT CREAM BASE WOMEN",
              "HYDROGEL": "HYDROGEL",
              "LANOLIN ANHYDROUS": "LANOLIN ANHYDROUS",
              "LIPO CREAM BASE": "LIPO CREAM BASE",
              "MEDIBASE C": "MEDIBASE C",
              "MEDIDERM": "MEDIDERM",
              "MEDIHOL BASE": "MEDIHOL BASE",
              "OINTMENT BASE (EMULSIFYING)": "OINTMENT BASE (EMULSIFYING)",
              "OLEABASE PLASTICIZED": "OLEABASE PLASTICIZED",
              "PENDERM": "PENDERM",
              "PLO GEL - MEDIFLO": "PLO GEL - MEDIFLO",
              "PLO GEL - MEDIFLO PRE-MIXED": "PLO GEL - MEDIFLO PRE-MIXED",
              "PLO TRANSDERMAL": "PLO TRANSDERMAL",
              "PLURONIC F127": "PLURONIC F127",
              "POLYETHYLENE GLYCOL": "POLYETHYLENE GLYCOL",
              "POLYETHYLENE GLYCOL 3350": "POLYETHYLENE GLYCOL 3350",
              "POLYETHYLENE GLYCOL 400": "POLYETHYLENE GLYCOL 400",
              "POLYETHYLENE GLYCOL 8000": "POLYETHYLENE GLYCOL 8000",
              "TRANSDERMAL PAIN BASE": "TRANSDERMAL PAIN BASE",
              "VANISH-PEN": "VANISH-PEN",
              "VERSAPRO": "VERSAPRO",
              "WHITE PETROLATUM": "WHITE PETROLATUM"
            },
            "MEDLINE/DERMAL MANAGEMENT": {
              "WHITE PETROLATUM": "WHITE PETROLATUM"
            },
            "NEW WORLD IMPORTS": {
              "PETROLEUM JELLY": "PETROLEUM JELLY"
            },
            "NGC GROUP": {
              "XCEL 100": "XCEL 100"
            },
            "PCCA": {
              "ATREVIS HYDROGEL": "ATREVIS HYDROGEL",
              "BASE A POLYETHYLENE GLYCOL": "BASE A POLYETHYLENE GLYCOL",
              "BASE C POLYETHYLENE GLYCOL 300": "BASE C POLYETHYLENE GLYCOL 300",
              "BASE D POLYETHYLENE GLYCOL": "BASE D POLYETHYLENE GLYCOL",
              "BASE PCCA CLARIFYING": "BASE PCCA CLARIFYING",
              "BASE W301": "BASE W301",
              "CHEMSIL K-12": "CHEMSIL K-12",
              "CHEMSIL K-51": "CHEMSIL K-51",
              "CHRYSADERM DAY": "CHRYSADERM DAY",
              "CHRYSADERM NIGHT": "CHRYSADERM NIGHT",
              "CUSTOM LIPOTHENE 110": "CUSTOM LIPOTHENE 110",
              "CUSTOM LIPOTHENE 133": "CUSTOM LIPOTHENE 133",
              "KRIS-ESTER 236": "KRIS-ESTER 236",
              "KRISGEL 100": "KRISGEL 100",
              "LANOLIN": "LANOLIN",
              "LANOLIN ALCOHOL": "LANOLIN ALCOHOL",
              "LANOLIN ANHYDROUS": "LANOLIN ANHYDROUS",
              "LUBRAJEL NP": "LUBRAJEL NP",
              "PCCA ALADERM BASE": "PCCA ALADERM BASE",
              "PCCA ANHYDROUS BASE": "PCCA ANHYDROUS BASE",
              "PCCA ANHYDROUS LIPODERM BASE": "PCCA ANHYDROUS LIPODERM BASE",
              "PCCA BASE 7542": "PCCA BASE 7542",
              "PCCA BIOPEPTIDE BASE": "PCCA BIOPEPTIDE BASE",
              "PCCA CANNIDEX CUSTOM BASE": "PCCA CANNIDEX CUSTOM BASE",
              "PCCA COBASE #1": "PCCA COBASE #1",
              "PCCA COSMETIC HRT BASE": "PCCA COSMETIC HRT BASE",
              "PCCA CUSTOM LIPO-MAX": "PCCA CUSTOM LIPO-MAX",
              "PCCA ELLAGE VAGINAL": "PCCA ELLAGE VAGINAL",
              "PCCA EMOLLIENT CREAM BASE": "PCCA EMOLLIENT CREAM BASE",
              "PCCA GELATIN BASE": "PCCA GELATIN BASE",
              "PCCA LIPODERM BASE": "PCCA LIPODERM BASE",
              "PCCA LIPODERM HMW": "PCCA LIPODERM HMW",
              "PCCA LIPOSOMIC BASE DRY": "PCCA LIPOSOMIC BASE DRY",
              "PCCA LIPOSOMIC BASE NORMAL": "PCCA LIPOSOMIC BASE NORMAL",
              "PCCA LIPOSOMIC BASE OILY": "PCCA LIPOSOMIC BASE OILY",
              "PCCA LIPOSOMIC BASE SENSITIVE": "PCCA LIPOSOMIC BASE SENSITIVE",
              "PCCA MVC BASE": "PCCA MVC BASE",
              "PCCA NATACREAM": "PCCA NATACREAM",
              "PCCA OCCLUSADERM": "PCCA OCCLUSADERM",
              "PCCA PERME8 ANHYDROUS": "PCCA PERME8 ANHYDROUS",
              "PCCA PLASTICIZED BASE": "PCCA PLASTICIZED BASE",
              "PCCA PLURONIC F127 BASE": "PCCA PLURONIC F127 BASE",
              "PCCA POLOXAMER 407": "PCCA POLOXAMER 407",
              "PCCA POLYPEG BASE": "PCCA POLYPEG BASE",
              "PCCA PRACASIL TM-PLUS BASE": "PCCA PRACASIL TM-PLUS BASE",
              "PCCA SPIRA-WASH BASE": "PCCA SPIRA-WASH BASE",
              "PCCA VANISHING CREAM BASE": "PCCA VANISHING CREAM BASE",
              "PCCA VANISHING CREAM LIGHT": "PCCA VANISHING CREAM LIGHT",
              "PCCA VANPEN BASE": "PCCA VANPEN BASE",
              "PCCA W06 ANHYDROUS TOPICAL": "PCCA W06 ANHYDROUS TOPICAL",
              "PCCA WAV CUSTOM BASE": "PCCA WAV CUSTOM BASE",
              "POLYETHYLENE GLYCOL 3350": "POLYETHYLENE GLYCOL 3350",
              "POLYETHYLENE GLYCOL 600": "POLYETHYLENE GLYCOL 600",
              "POLYETHYLENE GLYCOL 8000": "POLYETHYLENE GLYCOL 8000",
              "SUSPENDIT": "SUSPENDIT",
              "VERSABASE": "VERSABASE",
              "VERSABASE HRT": "VERSABASE HRT",
              "WHITE PETROLATUM": "WHITE PETROLATUM",
              "XEMATOP BASE": "XEMATOP BASE",
              "YELLOW PETROLATUM": "YELLOW PETROLATUM",
              "ZOSIL": "ZOSIL"
            },
            "PERRIGO": {
              "AQUABASE": "AQUABASE",
              "FATTIBASE": "FATTIBASE",
              "POLYBASE": "POLYBASE"
            },
            "PHARMACEUTICA NORTH AMERICA": {
              "ALBA-DERM": "ALBA-DERM",
              "PENTAPHENE BASE": "PENTAPHENE BASE",
              "PNA-HRT BASE": "PNA-HRT BASE",
              "Q-DERM": "Q-DERM",
              "STERA BASE": "STERA BASE",
              "V-MAX": "V-MAX"
            },
            "PHARMACEUTICS CORP": {
              "ULTRADERM": "ULTRADERM"
            },
            "PHOENIX PHARMA": {
              "ALTADERM": "ALTADERM"
            },
            "RITE AID CORPORATION": {
              "RA PETROLEUM JELLY": "RA PETROLEUM JELLY",
              "RA TUGABOOS PETROLEUM JELLY": "RA TUGABOOS PETROLEUM JELLY"
            },
            "RUGBY LABORATORIES": {
              "PETROLATUM": "PETROLATUM"
            },
            "SA3": {
              "SA3 DERM": "SA3 DERM",
              "SKYY DERM": "SKYY DERM"
            },
            "SMITH & NEPHEW UNITED": {
              "SECURA PROTECTIVE": "SECURA PROTECTIVE"
            },
            "SOLUTECH PHARMACEUTICALS": {
              "DIMETHIPAC": "DIMETHIPAC",
              "NOXI-K": "NOXI-K",
              "PICODERM": "PICODERM",
              "SOLU-K": "SOLU-K"
            },
            "SOUTHEASTERN MEDICAL TECH": {
              "LIPOGEL CR BASE": "LIPOGEL CR BASE"
            },
            "SPECTRUM": {
              "ALCOHOL BASE GEL": "ALCOHOL BASE GEL",
              "ANHYDROUS BASE": "ANHYDROUS BASE",
              "CREAM BASE NIOSOMES": "CREAM BASE NIOSOMES",
              "CREAM BASE WITH LIPOSOME": "CREAM BASE WITH LIPOSOME",
              "CREAM-HEAVY BASE NIOSOMES": "CREAM-HEAVY BASE NIOSOMES",
              "DURABASE": "DURABASE",
              "DURABASE ADVANCED": "DURABASE ADVANCED",
              "HORMONE CR HEAVY BASE NIOSOMES": "HORMONE CR HEAVY BASE NIOSOMES",
              "HORMONE CREAM BASE": "HORMONE CREAM BASE",
              "HORMONE CREAM BASE NIOSOMES": "HORMONE CREAM BASE NIOSOMES",
              "HRT BOTANICAL": "HRT BOTANICAL",
              "HRT CREAM": "HRT CREAM",
              "LANOLIN": "LANOLIN",
              "LIPOCREAM BASE": "LIPOCREAM BASE",
              "OMNIBASE": "OMNIBASE",
              "PETROLATUM": "PETROLATUM",
              "PETROLATUM WHITE": "PETROLATUM WHITE",
              "PLASTICIZED BASE": "PLASTICIZED BASE",
              "POLYETHYLENE GLYCOL 1000": "POLYETHYLENE GLYCOL 1000",
              "POLYETHYLENE GLYCOL 1450": "POLYETHYLENE GLYCOL 1450",
              "POLYETHYLENE GLYCOL 1500": "POLYETHYLENE GLYCOL 1500",
              "POLYETHYLENE GLYCOL 300": "POLYETHYLENE GLYCOL 300",
              "POLYETHYLENE GLYCOL 3350": "POLYETHYLENE GLYCOL 3350",
              "POLYETHYLENE GLYCOL 400": "POLYETHYLENE GLYCOL 400",
              "POLYETHYLENE GLYCOL 4500": "POLYETHYLENE GLYCOL 4500",
              "POLYETHYLENE GLYCOL 8000": "POLYETHYLENE GLYCOL 8000",
              "VANISHING": "VANISHING",
              "WATER BASE GEL": "WATER BASE GEL"
            },
            "STRATUS PHARMACEUTICALS": {
              "WHITE PETROLATUM": "WHITE PETROLATUM"
            },
            "TOPCO": {
              "PETROLEUM JELLY": "PETROLEUM JELLY"
            },
            "TOTAL PHARMACY SUPPLY": {
              "POLYETHYLENE GLYCOL 300": "POLYETHYLENE GLYCOL 300"
            },
            "TRIOVA PHARMACEUTICALS": {
              "HRT ESSENTIAL": "HRT ESSENTIAL"
            },
            "VI-JON": {
              "PETROLEUM JELLY": "PETROLEUM JELLY"
            },
            "VILLAGE PHARMA": {
              "VP DERMABASE": "VP DERMABASE"
            },
            "WAL-MART": {
              "EQ PETROLEUM JELLY": "EQ PETROLEUM JELLY"
            },
            "WALGREENS": {
              "COLD CREAM": "COLD CREAM",
              "PETROLEUM JELLY": "PETROLEUM JELLY"
            },
            "WHYTEMAN LABS": {
              "CELA BASE": "CELA BASE"
            }
          }
        },
        "Surfactants": {
          "Surfactants": {
            "FAGRON": {
              "POLYOXYL 40 STEARATE": "POLYOXYL 40 STEARATE"
            },
            "KALCHEM INTERNATIONAL": {
              "MYRJ 53": "MYRJ 53"
            },
            "LETCO MEDICAL": {
              "POLYOXYL 40 STEARATE": "POLYOXYL 40 STEARATE"
            },
            "PCCA": {
              "POLYOXYL 40 STEARATE": "POLYOXYL 40 STEARATE"
            },
            "SPECTRUM": {
              "POLYOXYL 40 STEARATE": "POLYOXYL 40 STEARATE"
            }
          }
        }
      },
      "PROGESTINS": {
        "Progestins": {
          "Progestins": {
            "A-S MEDICATION SOLUTIONS": {
              "MEDROXYPROGESTERONE ACETATE": "MEDROXYPROGESTERONE ACETATE",
              "MEGACE ES": "MEGACE ES",
              "PROGESTERONE MICRONIZED": "PROGESTERONE MICRONIZED"
            },
            "ABBVIE": {
              "PROMETRIUM": "PROMETRIUM"
            },
            "ACTAVIS PHARMA": {
              "PROGESTERONE": "PROGESTERONE",
              "PROGESTERONE MICRONIZED": "PROGESTERONE MICRONIZED"
            },
            "AIDAREX PHARMACEUTICALS": {
              "MEDROXYPROGESTERONE ACETATE": "MEDROXYPROGESTERONE ACETATE",
              "NORETHINDRONE ACETATE": "NORETHINDRONE ACETATE"
            },
            "AKORN": {
              "PROGESTERONE MICRONIZED": "PROGESTERONE MICRONIZED"
            },
            "ALEXSO": {
              "PROGESTERONE MICRONIZED": "PROGESTERONE MICRONIZED"
            },
            "AMAG PHARMACEUTICALS": {
              "MAKENA": "MAKENA"
            },
            "AMERICAN HEALTH PACKAGING": {
              "MEDROXYPROGESTERONE ACETATE": "MEDROXYPROGESTERONE ACETATE"
            },
            "AMERICAN REGENT": {
              "HYDROXYPROGESTERONE CAPROATE": "HYDROXYPROGESTERONE CAPROATE",
              "PROGESTERONE": "PROGESTERONE"
            },
            "AMNEAL PHARMACEUTICALS": {
              "HYDROXYPROGESTERONE CAPROATE": "HYDROXYPROGESTERONE CAPROATE",
              "NORETHINDRONE ACETATE": "NORETHINDRONE ACETATE",
              "PROGESTERONE MICRONIZED": "PROGESTERONE MICRONIZED"
            },
            "AUROBINDO PHARMA": {
              "NORETHINDRONE ACETATE": "NORETHINDRONE ACETATE",
              "PROGESTERONE MICRONIZED": "PROGESTERONE MICRONIZED"
            },
            "AUROMEDICS PHARMA": {
              "HYDROXYPROGESTERONE CAPROATE": "HYDROXYPROGESTERONE CAPROATE",
              "PROGESTERONE": "PROGESTERONE"
            },
            "AVKARE": {
              "MEGESTROL ACETATE": "MEGESTROL ACETATE",
              "NORETHINDRONE ACETATE": "NORETHINDRONE ACETATE",
              "PROGESTERONE MICRONIZED": "PROGESTERONE MICRONIZED"
            },
            "AVPAK": {
              "NORETHINDRONE ACETATE": "NORETHINDRONE ACETATE"
            },
            "BIONPHARMA": {
              "PROGESTERONE MICRONIZED": "PROGESTERONE MICRONIZED"
            },
            "BLENHEIM PHARMACAL": {
              "MEDROXYPROGESTERONE ACETATE": "MEDROXYPROGESTERONE ACETATE"
            },
            "BRECKENRIDGE": {
              "MEGESTROL ACETATE": "MEGESTROL ACETATE"
            },
            "BRYANT RANCH PREPACK": {
              "MEDROXYPROGESTERONE ACETATE": "MEDROXYPROGESTERONE ACETATE",
              "PROGESTERONE MICRONIZED": "PROGESTERONE MICRONIZED"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "PROGESTERONE MICRONIZED": "PROGESTERONE MICRONIZED"
            },
            "ENDO PHARMACEUTICALS": {
              "MEGACE ES": "MEGACE ES"
            },
            "ENOVACHEM MANUFACTURING": {
              "EC-RX PROGESTERONE": "EC-RX PROGESTERONE"
            },
            "FAGRON": {
              "NORETHINDRONE ACETATE": "NORETHINDRONE ACETATE"
            },
            "FRESENIUS KABI USA": {
              "PROGESTERONE": "PROGESTERONE"
            },
            "GLENMARK PHARMACEUTICALS": {
              "NORETHINDRONE ACETATE": "NORETHINDRONE ACETATE"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "PROGESTERONE MICRONIZED": "PROGESTERONE MICRONIZED"
            },
            "GREENSTONE": {
              "MEDROXYPROGESTERONE ACETATE": "MEDROXYPROGESTERONE ACETATE"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "MEDROXYPROGESTERONE ACETATE": "MEDROXYPROGESTERONE ACETATE"
            },
            "HIKMA": {
              "PROGESTERONE": "PROGESTERONE"
            },
            "HUMCO": {
              "PROGESTERONE COMPOUNDING KIT": "PROGESTERONE COMPOUNDING KIT"
            },
            "INGENUS PHARMACEUTICALS": {
              "NORETHINDRONE ACETATE": "NORETHINDRONE ACETATE"
            },
            "LIBERTY PHARMACEUTICAL": {
              "MEDROXYPROGESTERONE ACETATE": "MEDROXYPROGESTERONE ACETATE"
            },
            "MEDVANTX": {
              "MEDROXYPROGESTERONE ACETATE": "MEDROXYPROGESTERONE ACETATE"
            },
            "MYLAN": {
              "NORETHINDRONE ACETATE": "NORETHINDRONE ACETATE"
            },
            "MYLAN INSTITUTIONAL": {
              "HYDROXYPROGESTERONE CAPROATE": "HYDROXYPROGESTERONE CAPROATE"
            },
            "NOVAPLUS/AMER REGENT": {
              "HYDROXYPROGESTERONE CAPROATE": "HYDROXYPROGESTERONE CAPROATE"
            },
            "NUCARE PHARMACEUTICALS": {
              "MEDROXYPROGESTERONE ACETATE": "MEDROXYPROGESTERONE ACETATE"
            },
            "PAR PHARMACEUTICAL": {
              "MEGESTROL ACETATE": "MEGESTROL ACETATE"
            },
            "PCCA": {
              "NORETHINDRONE ACETATE": "NORETHINDRONE ACETATE"
            },
            "PDRX PHARMACEUTICAL": {
              "MEDROXYPROGESTERONE ACETATE": "MEDROXYPROGESTERONE ACETATE",
              "PROGESTERONE MICRONIZED": "PROGESTERONE MICRONIZED",
              "PROVERA": "PROVERA"
            },
            "PDRX PHARMACEUTICALS": {
              "MEDROXYPROGESTERONE ACETATE": "MEDROXYPROGESTERONE ACETATE"
            },
            "PFIZER U.S.": {
              "PROVERA": "PROVERA"
            },
            "PHARMACEUTICAL ASSOCIATES": {
              "MEGESTROL ACETATE": "MEGESTROL ACETATE"
            },
            "PHARMPAK": {
              "MEDROXYPROGESTERONE ACETATE": "MEDROXYPROGESTERONE ACETATE"
            },
            "PRASCO LABORATORIES": {
              "HYDROXYPROGESTERONE CAPROATE": "HYDROXYPROGESTERONE CAPROATE"
            },
            "PREFERRED PHARMACEUTICALS": {
              "MEDROXYPROGESTERONE ACETATE": "MEDROXYPROGESTERONE ACETATE",
              "PROGESTERONE MICRONIZED": "PROGESTERONE MICRONIZED"
            },
            "PROFICIENT RX": {
              "MEDROXYPROGESTERONE ACETATE": "MEDROXYPROGESTERONE ACETATE",
              "PROGESTERONE MICRONIZED": "PROGESTERONE MICRONIZED"
            },
            "QUALITY CARE": {
              "MEDROXYPROGESTERONE ACETATE": "MEDROXYPROGESTERONE ACETATE",
              "PROVERA": "PROVERA"
            },
            "QUALITY CARE PRODUCTS": {
              "MEDROXYPROGESTERONE ACETATE": "MEDROXYPROGESTERONE ACETATE"
            },
            "READYMEDS": {
              "MEDROXYPROGESTERONE ACETATE": "MEDROXYPROGESTERONE ACETATE"
            },
            "RXCHANGE CO": {
              "NORETHINDRONE ACETATE": "NORETHINDRONE ACETATE"
            },
            "SLAYBACK PHARMA": {
              "HYDROXYPROGESTERONE CAPROATE": "HYDROXYPROGESTERONE CAPROATE"
            },
            "TEVA PHARMACEUTICALS USA": {
              "MEDROXYPROGESTERONE ACETATE": "MEDROXYPROGESTERONE ACETATE",
              "NORETHINDRONE ACETATE": "NORETHINDRONE ACETATE",
              "PROGESTERONE MICRONIZED": "PROGESTERONE MICRONIZED"
            },
            "TEVA/WOMENS HEALTH": {
              "AYGESTIN": "AYGESTIN"
            },
            "TWI PHARMACEUTICALS": {
              "MEGESTROL ACETATE": "MEGESTROL ACETATE"
            },
            "VIRTUS PHARMACEUTICALS": {
              "PROGESTERONE MICRONIZED": "PROGESTERONE MICRONIZED",
              "PROMETRIUM": "PROMETRIUM"
            }
          }
        }
      },
      "PSYCHOTHERAPEUTIC AND NEUROLOGICAL AGENTS - MISC.": {
        "Agents for Chemical Dependency": {
          "Agents for Opioid Withdrawal": {
            "US WORLDMEDS": {
              "LUCEMYRA": "LUCEMYRA"
            }
          },
          "Alcohol Deterrents": {
            "ALVOGEN": {
              "DISULFIRAM": "DISULFIRAM"
            },
            "AMERICAN HEALTH PACKAGING": {
              "ACAMPROSATE CALCIUM": "ACAMPROSATE CALCIUM"
            },
            "AVKARE": {
              "ACAMPROSATE CALCIUM": "ACAMPROSATE CALCIUM"
            },
            "GLENMARK PHARMACEUTICALS": {
              "ACAMPROSATE CALCIUM": "ACAMPROSATE CALCIUM"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "DISULFIRAM": "DISULFIRAM"
            },
            "HIKMA": {
              "DISULFIRAM": "DISULFIRAM"
            },
            "MARLEX PHARMACEUTICALS": {
              "ACAMPROSATE CALCIUM": "ACAMPROSATE CALCIUM"
            },
            "MYLAN": {
              "ACAMPROSATE CALCIUM": "ACAMPROSATE CALCIUM",
              "DISULFIRAM": "DISULFIRAM"
            },
            "MYLAN INSTITUTIONAL": {
              "ACAMPROSATE CALCIUM": "ACAMPROSATE CALCIUM"
            },
            "PAR PHARMACEUTICALS": {
              "DISULFIRAM": "DISULFIRAM"
            },
            "RISING PHARMACEUTICALS": {
              "DISULFIRAM": "DISULFIRAM"
            },
            "TEVA PHARMACEUTICALS USA": {
              "ACAMPROSATE CALCIUM": "ACAMPROSATE CALCIUM",
              "DISULFIRAM": "DISULFIRAM"
            },
            "TEVA/WOMENS HEALTH": {
              "ANTABUSE": "ANTABUSE"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "ACAMPROSATE CALCIUM": "ACAMPROSATE CALCIUM"
            }
          }
        },
        "Anti-Cataplectic Agents": {
          "Anti-Cataplectic Agents": {
            "JAZZ PHARMACEUTICALS": {
              "XYREM": "XYREM"
            }
          },
          "Anti-Cataplectic Combinations": {
            "JAZZ PHARMACEUTICALS": {
              "XYWAV": "XYWAV"
            }
          }
        },
        "Antidementia Agents": {
          "Antidementia Agent Combinations": {
            "ALLERGAN": {
              "NAMZARIC": "NAMZARIC"
            }
          },
          "Cholinomimetics - ACHE Inhibitors": {
            "A-S MEDICATION SOLUTIONS": {
              "DONEPEZIL HCL": "DONEPEZIL HCL"
            },
            "ACETRIS HEALTH": {
              "DONEPEZIL HCL": "DONEPEZIL HCL"
            },
            "ACTAVIS ELIZABETH": {
              "DONEPEZIL HCL": "DONEPEZIL HCL"
            },
            "ACTAVIS PHARMA": {
              "GALANTAMINE HYDROBROMIDE ER": "GALANTAMINE HYDROBROMIDE ER",
              "RIVASTIGMINE TARTRATE": "RIVASTIGMINE TARTRATE"
            },
            "AIDAREX PHARMACEUTICALS": {
              "DONEPEZIL HCL": "DONEPEZIL HCL"
            },
            "ALEMBIC PHARMACEUTICALS": {
              "DONEPEZIL HCL": "DONEPEZIL HCL"
            },
            "ALVOGEN": {
              "RIVASTIGMINE": "RIVASTIGMINE"
            },
            "AMERICAN HEALTH PACKAGING": {
              "DONEPEZIL HCL": "DONEPEZIL HCL",
              "GALANTAMINE HYDROBROMIDE": "GALANTAMINE HYDROBROMIDE",
              "RIVASTIGMINE TARTRATE": "RIVASTIGMINE TARTRATE"
            },
            "AMNEAL PHARMACEUTICALS": {
              "RIVASTIGMINE": "RIVASTIGMINE"
            },
            "APHENA PHARMA SOLUTIONS": {
              "ARICEPT": "ARICEPT",
              "GALANTAMINE HYDROBROMIDE ER": "GALANTAMINE HYDROBROMIDE ER"
            },
            "APOTEX": {
              "GALANTAMINE HYDROBROMIDE": "GALANTAMINE HYDROBROMIDE",
              "RIVASTIGMINE TARTRATE": "RIVASTIGMINE TARTRATE"
            },
            "AQ PHARMACEUTICALS": {
              "ARICEPT": "ARICEPT"
            },
            "AUROBINDO PHARMA": {
              "DONEPEZIL HCL": "DONEPEZIL HCL",
              "GALANTAMINE HYDROBROMIDE": "GALANTAMINE HYDROBROMIDE",
              "GALANTAMINE HYDROBROMIDE ER": "GALANTAMINE HYDROBROMIDE ER",
              "RIVASTIGMINE TARTRATE": "RIVASTIGMINE TARTRATE"
            },
            "AVKARE": {
              "DONEPEZIL HCL": "DONEPEZIL HCL"
            },
            "BI COASTAL PHARMACEUTICAL": {
              "DONEPEZIL HCL": "DONEPEZIL HCL"
            },
            "BIOGEN PHARMACEUTICALS": {
              "ARICEPT": "ARICEPT"
            },
            "BIOMES PHARMACEUTICALS": {
              "DONEPEZIL HCL": "DONEPEZIL HCL"
            },
            "BIONPHARMA": {
              "DONEPEZIL HCL": "DONEPEZIL HCL"
            },
            "BRECKENRIDGE": {
              "RIVASTIGMINE": "RIVASTIGMINE",
              "RIVASTIGMINE TARTRATE": "RIVASTIGMINE TARTRATE"
            },
            "BRYANT RANCH PREPACK": {
              "ARICEPT": "ARICEPT",
              "DONEPEZIL HCL": "DONEPEZIL HCL"
            },
            "CADISTA": {
              "DONEPEZIL HCL": "DONEPEZIL HCL"
            },
            "CAMBER PHARMACEUTICALS": {
              "DONEPEZIL HCL": "DONEPEZIL HCL"
            },
            "DIRECT RX": {
              "DONEPEZIL HCL": "DONEPEZIL HCL"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "DONEPEZIL HCL": "DONEPEZIL HCL",
              "GALANTAMINE HYDROBROMIDE": "GALANTAMINE HYDROBROMIDE",
              "RIVASTIGMINE TARTRATE": "RIVASTIGMINE TARTRATE"
            },
            "EISAI": {
              "ARICEPT": "ARICEPT"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "DONEPEZIL HCL": "DONEPEZIL HCL",
              "RIVASTIGMINE TARTRATE": "RIVASTIGMINE TARTRATE"
            },
            "GREENSTONE": {
              "DONEPEZIL HCL": "DONEPEZIL HCL"
            },
            "HIKMA": {
              "GALANTAMINE HYDROBROMIDE": "GALANTAMINE HYDROBROMIDE"
            },
            "IMPAX GENERICS": {
              "GALANTAMINE HYDROBROMIDE ER": "GALANTAMINE HYDROBROMIDE ER"
            },
            "JANSSEN": {
              "RAZADYNE": "RAZADYNE",
              "RAZADYNE ER": "RAZADYNE ER"
            },
            "LUPIN PHARMACEUTICALS": {
              "DONEPEZIL HCL": "DONEPEZIL HCL"
            },
            "MACLEODS PHARMACEUTICALS": {
              "DONEPEZIL HCL": "DONEPEZIL HCL",
              "RIVASTIGMINE TARTRATE": "RIVASTIGMINE TARTRATE"
            },
            "MAJOR PHARMACEUTICALS": {
              "DONEPEZIL HCL": "DONEPEZIL HCL",
              "RIVASTIGMINE TARTRATE": "RIVASTIGMINE TARTRATE"
            },
            "MYLAN": {
              "DONEPEZIL HCL": "DONEPEZIL HCL",
              "GALANTAMINE HYDROBROMIDE": "GALANTAMINE HYDROBROMIDE",
              "GALANTAMINE HYDROBROMIDE ER": "GALANTAMINE HYDROBROMIDE ER",
              "RIVASTIGMINE": "RIVASTIGMINE"
            },
            "MYLAN INSTITUTIONAL": {
              "GALANTAMINE HYDROBROMIDE": "GALANTAMINE HYDROBROMIDE"
            },
            "NIVAGEN PHARMACEUTICALS": {
              "RIVASTIGMINE TARTRATE": "RIVASTIGMINE TARTRATE"
            },
            "NORTHSTAR RX": {
              "RIVASTIGMINE": "RIVASTIGMINE"
            },
            "NOVARTIS": {
              "EXELON": "EXELON"
            },
            "NUCARE PHARMACEUTICALS": {
              "DONEPEZIL HCL": "DONEPEZIL HCL"
            },
            "PAR PHARMACEUTICAL": {
              "DONEPEZIL HCL": "DONEPEZIL HCL"
            },
            "PATRIOT PHARMACEUTICALS LLC": {
              "GALANTAMINE HYDROBROMIDE": "GALANTAMINE HYDROBROMIDE",
              "GALANTAMINE HYDROBROMIDE ER": "GALANTAMINE HYDROBROMIDE ER"
            },
            "PDRX PHARMACEUTICAL": {
              "ARICEPT": "ARICEPT",
              "DONEPEZIL HCL": "DONEPEZIL HCL"
            },
            "PREFERRED PHARMACEUTICALS": {
              "DONEPEZIL HCL": "DONEPEZIL HCL"
            },
            "QUALITY CARE": {
              "ARICEPT": "ARICEPT",
              "EXELON": "EXELON"
            },
            "RISING PHARMACEUTICALS": {
              "GALANTAMINE HYDROBROMIDE": "GALANTAMINE HYDROBROMIDE"
            },
            "SANDOZ": {
              "DONEPEZIL HCL": "DONEPEZIL HCL",
              "RIVASTIGMINE": "RIVASTIGMINE",
              "RIVASTIGMINE TARTRATE": "RIVASTIGMINE TARTRATE"
            },
            "SKY PACKAGING": {
              "DONEPEZIL HCL": "DONEPEZIL HCL",
              "GALANTAMINE HYDROBROMIDE": "GALANTAMINE HYDROBROMIDE",
              "RIVASTIGMINE TARTRATE": "RIVASTIGMINE TARTRATE"
            },
            "SLATE RUN PHARMACEUTICALS": {
              "GALANTAMINE HYDROBROMIDE": "GALANTAMINE HYDROBROMIDE"
            },
            "SOLCO HEALTHCARE": {
              "DONEPEZIL HCL": "DONEPEZIL HCL"
            },
            "ST MARY'S MPP": {
              "DONEPEZIL HCL": "DONEPEZIL HCL"
            },
            "STRIDES PHARMA": {
              "DONEPEZIL HCL": "DONEPEZIL HCL"
            },
            "SUN PHARMACEUTICAL": {
              "DONEPEZIL HCL": "DONEPEZIL HCL"
            },
            "SUN PHARMACEUTICALS": {
              "DONEPEZIL HCL": "DONEPEZIL HCL",
              "GALANTAMINE HYDROBROMIDE ER": "GALANTAMINE HYDROBROMIDE ER",
              "RIVASTIGMINE TARTRATE": "RIVASTIGMINE TARTRATE"
            },
            "TEVA PHARMACEUTICALS USA": {
              "GALANTAMINE HYDROBROMIDE": "GALANTAMINE HYDROBROMIDE",
              "GALANTAMINE HYDROBROMIDE ER": "GALANTAMINE HYDROBROMIDE ER"
            },
            "TORRENT PHARMACEUTICALS": {
              "DONEPEZIL HCL": "DONEPEZIL HCL"
            },
            "TWI PHARMACEUTICALS": {
              "DONEPEZIL HCL": "DONEPEZIL HCL"
            },
            "UNICHEM PHARMACEUTICALS": {
              "DONEPEZIL HCL": "DONEPEZIL HCL"
            },
            "UNIT DOSE SERVICES": {
              "DONEPEZIL HCL": "DONEPEZIL HCL"
            },
            "VENSUN PHARMACEUTICALS": {
              "DONEPEZIL HCL": "DONEPEZIL HCL"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "DONEPEZIL HCL": "DONEPEZIL HCL",
              "GALANTAMINE HYDROBROMIDE": "GALANTAMINE HYDROBROMIDE",
              "RIVASTIGMINE": "RIVASTIGMINE"
            }
          },
          "N-Methyl-D-Aspartate (NMDA) Receptor Antagonists": {
            "ACTAVIS PHARMA": {
              "MEMANTINE HCL": "MEMANTINE HCL"
            },
            "AJANTA PHARMA LIMITED": {
              "MEMANTINE HCL": "MEMANTINE HCL"
            },
            "ALEMBIC PHARMACEUTICALS": {
              "MEMANTINE HCL": "MEMANTINE HCL"
            },
            "ALLERGAN": {
              "NAMENDA": "NAMENDA",
              "NAMENDA TITRATION PAK": "NAMENDA TITRATION PAK",
              "NAMENDA XR": "NAMENDA XR",
              "NAMENDA XR TITRATION PACK": "NAMENDA XR TITRATION PACK"
            },
            "AMERICAN HEALTH PACKAGING": {
              "MEMANTINE HCL": "MEMANTINE HCL"
            },
            "AMNEAL PHARMACEUTICALS": {
              "MEMANTINE HCL": "MEMANTINE HCL",
              "MEMANTINE HCL ER": "MEMANTINE HCL ER"
            },
            "ANI  PHARMACEUTICALS": {
              "MEMANTINE HCL ER": "MEMANTINE HCL ER"
            },
            "APHENA PHARMA SOLUTIONS": {
              "MEMANTINE HCL": "MEMANTINE HCL",
              "NAMENDA": "NAMENDA"
            },
            "APOTEX": {
              "MEMANTINE HCL": "MEMANTINE HCL",
              "MEMANTINE HCL ER": "MEMANTINE HCL ER"
            },
            "AUROBINDO PHARMA": {
              "MEMANTINE HCL": "MEMANTINE HCL"
            },
            "AVKARE": {
              "MEMANTINE HCL": "MEMANTINE HCL"
            },
            "BRYANT RANCH PREPACK": {
              "MEMANTINE HCL": "MEMANTINE HCL"
            },
            "CELLTRION USA": {
              "MEMANTINE HCL": "MEMANTINE HCL"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "MEMANTINE HCL": "MEMANTINE HCL"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "MEMANTINE HCL": "MEMANTINE HCL",
              "MEMANTINE HCL ER": "MEMANTINE HCL ER"
            },
            "LANNETT": {
              "MEMANTINE HCL": "MEMANTINE HCL"
            },
            "LUPIN PHARMACEUTICALS": {
              "MEMANTINE HCL": "MEMANTINE HCL",
              "MEMANTINE HCL ER": "MEMANTINE HCL ER"
            },
            "MACLEODS PHARMACEUTICALS": {
              "MEMANTINE HCL": "MEMANTINE HCL"
            },
            "MAJOR PHARMACEUTICALS": {
              "MEMANTINE HCL": "MEMANTINE HCL",
              "MEMANTINE HCL ER": "MEMANTINE HCL ER"
            },
            "MYLAN": {
              "MEMANTINE HCL": "MEMANTINE HCL",
              "MEMANTINE HCL ER": "MEMANTINE HCL ER"
            },
            "MYLAN INSTITUTIONAL": {
              "MEMANTINE HCL": "MEMANTINE HCL"
            },
            "NORTHSTAR RX": {
              "MEMANTINE HCL ER": "MEMANTINE HCL ER"
            },
            "PAR PHARMACEUTICAL": {
              "MEMANTINE HCL ER": "MEMANTINE HCL ER"
            },
            "PATRIN PHARMA": {
              "MEMANTINE HCL": "MEMANTINE HCL"
            },
            "PHARMACEUTICAL ASSOCIATES": {
              "MEMANTINE HCL": "MEMANTINE HCL"
            },
            "PREFERRED PHARMACEUTICALS": {
              "MEMANTINE HCL": "MEMANTINE HCL"
            },
            "SUN PHARMACEUTICALS": {
              "MEMANTINE HCL": "MEMANTINE HCL"
            },
            "TORRENT PHARMACEUTICALS": {
              "MEMANTINE HCL": "MEMANTINE HCL"
            },
            "UNICHEM PHARMACEUTICALS": {
              "MEMANTINE HCL": "MEMANTINE HCL"
            },
            "UPSHER-SMITH": {
              "MEMANTINE HCL": "MEMANTINE HCL"
            },
            "VIONA PHARMACEUTICALS": {
              "MEMANTINE HCL": "MEMANTINE HCL"
            },
            "WOCKHARDT USA": {
              "MEMANTINE HCL": "MEMANTINE HCL"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "MEMANTINE HCL ER": "MEMANTINE HCL ER"
            }
          },
          "Nootropics": {
            "TWIN LABS": {
              "ACETYL L-CARNITINE": "ACETYL L-CARNITINE"
            }
          }
        },
        "Combination Psychotherapeutics": {
          "Benzodiazepines & Tricyclic Agents": {
            "MYLAN": {
              "CHLORDIAZEPOXIDE-AMITRIPTYLINE": "CHLORDIAZEPOXIDE-AMITRIPTYLINE"
            }
          },
          "Phenothiazines & Tricyclic Agents": {
            "MYLAN": {
              "PERPHENAZINE-AMITRIPTYLINE": "PERPHENAZINE-AMITRIPTYLINE"
            },
            "QUALITY CARE": {
              "PERPHENAZINE-AMITRIPTYLINE": "PERPHENAZINE-AMITRIPTYLINE"
            }
          },
          "SNRIs & Anesthetics/Analgesics": {
            "PURE TEK": {
              "DERMACINRX DPN PAK": "DERMACINRX DPN PAK"
            }
          },
          "Thienbenzodiazepines & SSRIs": {
            "AVKARE": {
              "OLANZAPINE-FLUOXETINE HCL": "OLANZAPINE-FLUOXETINE HCL"
            },
            "LILLY": {
              "SYMBYAX": "SYMBYAX"
            },
            "PAR PHARMACEUTICAL": {
              "OLANZAPINE-FLUOXETINE HCL": "OLANZAPINE-FLUOXETINE HCL"
            },
            "SANDOZ": {
              "OLANZAPINE-FLUOXETINE HCL": "OLANZAPINE-FLUOXETINE HCL"
            },
            "TEVA PHARMACEUTICALS USA": {
              "OLANZAPINE-FLUOXETINE HCL": "OLANZAPINE-FLUOXETINE HCL"
            }
          }
        },
        "Fibromyalgia Agents": {
          "Fibromyalgia Agent - SNRIs": {
            "ALLERGAN": {
              "SAVELLA": "SAVELLA",
              "SAVELLA TITRATION PACK": "SAVELLA TITRATION PACK"
            },
            "QUALITY CARE": {
              "SAVELLA": "SAVELLA"
            },
            "UNIT DOSE SERVICES": {
              "SAVELLA": "SAVELLA"
            }
          }
        },
        "Hypoactive Sexual Desire Disorder (HSDD) Agents": {
          "Melanocortin Receptor Agonists": {
            "AMAG PHARMACEUTICALS": {
              "VYLEESI": "VYLEESI"
            }
          },
          "Serotonin 1A Recept Agonist/Serotonin 2A Recept Antag": {
            "SPROUT PHARMACEUTICALS": {
              "ADDYI": "ADDYI"
            }
          }
        },
        "Movement Disorder Drug Therapy": {
          "Movement Disorder Drug Therapy": {
            "APOTEX": {
              "TETRABENAZINE": "TETRABENAZINE"
            },
            "AVKARE": {
              "TETRABENAZINE": "TETRABENAZINE"
            },
            "BIONPHARMA": {
              "TETRABENAZINE": "TETRABENAZINE"
            },
            "CAMBER PHARMACEUTICALS": {
              "TETRABENAZINE": "TETRABENAZINE"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "TETRABENAZINE": "TETRABENAZINE"
            },
            "HIKMA": {
              "TETRABENAZINE": "TETRABENAZINE"
            },
            "LUNDBECK": {
              "XENAZINE": "XENAZINE"
            },
            "LUPIN PHARMACEUTICALS": {
              "TETRABENAZINE": "TETRABENAZINE"
            },
            "NEUROCRINE BIOSCIENCES": {
              "INGREZZA": "INGREZZA"
            },
            "OCEANSIDE PHARMACEUTICALS": {
              "TETRABENAZINE": "TETRABENAZINE"
            },
            "SLATE RUN PHARMACEUTICALS": {
              "TETRABENAZINE": "TETRABENAZINE"
            },
            "SUN PHARMACEUTICALS": {
              "TETRABENAZINE": "TETRABENAZINE"
            },
            "TAGI PHARMA": {
              "TETRABENAZINE": "TETRABENAZINE"
            },
            "TEVA NEUROSCIENCE": {
              "AUSTEDO": "AUSTEDO"
            }
          }
        },
        "Multiple Sclerosis Agents": {
          "MS Agents - Pyrimidine Synthesis Inhibitors": {
            "GENZYME": {
              "AUBAGIO": "AUBAGIO"
            }
          },
          "Multiple Sclerosis Agents": {
            "MYLAN": {
              "GLATIRAMER ACETATE": "GLATIRAMER ACETATE"
            },
            "SANDOZ": {
              "GLATOPA": "GLATOPA"
            },
            "TEVA NEUROSCIENCE": {
              "COPAXONE": "COPAXONE"
            }
          },
          "Multiple Sclerosis Agents - Antimetabolites": {
            "SERONO": {
              "MAVENCLAD (10 TABS)": "MAVENCLAD (10 TABS)",
              "MAVENCLAD (4 TABS)": "MAVENCLAD (4 TABS)",
              "MAVENCLAD (5 TABS)": "MAVENCLAD (5 TABS)",
              "MAVENCLAD (6 TABS)": "MAVENCLAD (6 TABS)",
              "MAVENCLAD (7 TABS)": "MAVENCLAD (7 TABS)",
              "MAVENCLAD (8 TABS)": "MAVENCLAD (8 TABS)",
              "MAVENCLAD (9 TABS)": "MAVENCLAD (9 TABS)"
            }
          },
          "Multiple Sclerosis Agents - Interferons": {
            "BAYER HEALTHCARE PHARMA": {
              "BETASERON": "BETASERON"
            },
            "BIOGEN IDEC": {
              "AVONEX": "AVONEX",
              "AVONEX PEN": "AVONEX PEN",
              "AVONEX PREFILLED": "AVONEX PREFILLED",
              "PLEGRIDY": "PLEGRIDY",
              "PLEGRIDY STARTER PACK": "PLEGRIDY STARTER PACK"
            },
            "NOVARTIS": {
              "EXTAVIA": "EXTAVIA"
            },
            "SERONO": {
              "REBIF": "REBIF",
              "REBIF REBIDOSE": "REBIF REBIDOSE",
              "REBIF REBIDOSE TITRATION PACK": "REBIF REBIDOSE TITRATION PACK",
              "REBIF TITRATION PACK": "REBIF TITRATION PACK"
            }
          },
          "Multiple Sclerosis Agents - Monoclonal Antibodies": {
            "ABBVIE": {
              "ZINBRYTA": "ZINBRYTA"
            },
            "BIOGEN IDEC": {
              "TYSABRI": "TYSABRI"
            },
            "GENENTECH": {
              "OCREVUS": "OCREVUS"
            },
            "GENZYME": {
              "LEMTRADA": "LEMTRADA"
            },
            "NOVARTIS": {
              "KESIMPTA": "KESIMPTA"
            }
          },
          "Multiple Sclerosis Agents - Nrf2 Pathway Activators": {
            "AMNEAL PHARMACEUTICALS": {
              "DIMETHYL FUMARATE": "DIMETHYL FUMARATE",
              "DIMETHYL FUMARATE STARTER PACK": "DIMETHYL FUMARATE STARTER PACK"
            },
            "ASCEND LABORATORIES": {
              "DIMETHYL FUMARATE": "DIMETHYL FUMARATE",
              "DIMETHYL FUMARATE STARTER PACK": "DIMETHYL FUMARATE STARTER PACK"
            },
            "BANNER LIFE SCIENCES": {
              "BAFIERTAM": "BAFIERTAM"
            },
            "BIOGEN IDEC": {
              "TECFIDERA": "TECFIDERA",
              "VUMERITY": "VUMERITY",
              "VUMERITY (STARTER)": "VUMERITY (STARTER)"
            },
            "CAMBER PHARMACEUTICALS": {
              "DIMETHYL FUMARATE": "DIMETHYL FUMARATE",
              "DIMETHYL FUMARATE STARTER PACK": "DIMETHYL FUMARATE STARTER PACK"
            },
            "CIPLA USA": {
              "DIMETHYL FUMARATE": "DIMETHYL FUMARATE",
              "DIMETHYL FUMARATE STARTER PACK": "DIMETHYL FUMARATE STARTER PACK"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "DIMETHYL FUMARATE": "DIMETHYL FUMARATE"
            },
            "LUPIN PHARMACEUTICALS": {
              "DIMETHYL FUMARATE": "DIMETHYL FUMARATE",
              "DIMETHYL FUMARATE STARTER PACK": "DIMETHYL FUMARATE STARTER PACK"
            },
            "MYLAN": {
              "DIMETHYL FUMARATE": "DIMETHYL FUMARATE"
            },
            "TEVA PHARMACEUTICALS USA": {
              "DIMETHYL FUMARATE": "DIMETHYL FUMARATE"
            }
          },
          "Multiple Sclerosis Agents - Potassium Channel Blockers": {
            "ACCORD HEALTHCARE": {
              "DALFAMPRIDINE ER": "DALFAMPRIDINE ER"
            },
            "ACORDA THERAPEUTICS": {
              "AMPYRA": "AMPYRA"
            },
            "ASCEND LABORATORIES": {
              "DALFAMPRIDINE ER": "DALFAMPRIDINE ER"
            },
            "AUROBINDO PHARMA": {
              "DALFAMPRIDINE ER": "DALFAMPRIDINE ER"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "DALFAMPRIDINE ER": "DALFAMPRIDINE ER"
            },
            "HIKMA": {
              "DALFAMPRIDINE ER": "DALFAMPRIDINE ER"
            },
            "MICRO LABORATORIES": {
              "DALFAMPRIDINE ER": "DALFAMPRIDINE ER"
            },
            "MYLAN": {
              "DALFAMPRIDINE ER": "DALFAMPRIDINE ER"
            },
            "SUN PHARMACEUTICALS": {
              "DALFAMPRIDINE ER": "DALFAMPRIDINE ER"
            }
          },
          "Sphingosine 1-Phosphate (S1P) Receptor Modulators": {
            "CELGENE CORP": {
              "ZEPOSIA": "ZEPOSIA",
              "ZEPOSIA 7-DAY STARTER PACK": "ZEPOSIA 7-DAY STARTER PACK",
              "ZEPOSIA STARTER KIT": "ZEPOSIA STARTER KIT"
            },
            "NOVARTIS": {
              "GILENYA": "GILENYA",
              "MAYZENT": "MAYZENT",
              "MAYZENT STARTER PACK": "MAYZENT STARTER PACK"
            }
          }
        },
        "Postherpetic Neuralgia (PHN)/Neuropathic Pain Agents": {
          "Postherpetic Neuralgia (PHN)/Neuropathic Pain Agents": {
            "A-S MEDICATION SOLUTIONS": {
              "GRALISE": "GRALISE",
              "GRALISE STARTER": "GRALISE STARTER"
            },
            "ALMATICA": {
              "GRALISE": "GRALISE"
            },
            "ASSERTIO THERAPEUTICS": {
              "GRALISE STARTER": "GRALISE STARTER"
            },
            "PFIZER U.S.": {
              "LYRICA CR": "LYRICA CR"
            },
            "UNIT DOSE SERVICES": {
              "GRALISE": "GRALISE"
            }
          },
          "Postherpetic Neuralgia(PHN)/Neuropathic Pain Comb Agents": {
            "MAS MANAGEMENT GROUP": {
              "SMARTRX GABA": "SMARTRX GABA",
              "SMARTRX GABA-V": "SMARTRX GABA-V"
            },
            "PHARMACEUTICA NORTH AMERICA": {
              "ACTIVE-PAC/GABAPENTIN": "ACTIVE-PAC/GABAPENTIN"
            },
            "PURE TEK": {
              "GABACAINE": "GABACAINE",
              "GABAPAL": "GABAPAL",
              "GPL PAK": "GPL PAK",
              "LIDO GB-300": "LIDO GB-300",
              "LIDOTIN": "LIDOTIN",
              "LIPRITIN": "LIPRITIN",
              "LIPRITIN II": "LIPRITIN II",
              "PENTICAN": "PENTICAN",
              "PRILOPENTIN": "PRILOPENTIN"
            },
            "SALLUS LABORATORIES": {
              "CONVENIENCE PAK": "CONVENIENCE PAK"
            }
          }
        },
        "Premenstrual Dysphoric Disorder (PMDD) Agents": {
          "Premenstrual Dysphoric Disorder (PMDD) Agents - SSRIs": {
            "ALLERGAN": {
              "SARAFEM": "SARAFEM"
            },
            "MYLAN": {
              "FLUOXETINE HCL (PMDD)": "FLUOXETINE HCL (PMDD)"
            },
            "TORRENT PHARMACEUTICALS": {
              "FLUOXETINE HCL (PMDD)": "FLUOXETINE HCL (PMDD)"
            }
          }
        },
        "Pseudobulbar Affect (PBA) Agents": {
          "Pseudobulbar Affect Agent Combinations": {
            "AVANIR PHARMACEUTICALS, LLC": {
              "NUEDEXTA": "NUEDEXTA"
            }
          }
        },
        "Psychotherapeutic and Neurological Agents - Misc.": {
          "Psychotherapeutic and Neurological Agents - Misc.": {
            "PAR PHARMACEUTICAL": {
              "PIMOZIDE": "PIMOZIDE"
            },
            "SUN PHARMACEUTICALS": {
              "ERGOLOID MESYLATES": "ERGOLOID MESYLATES"
            },
            "TEVA SELECT BRANDS": {
              "ORAP": "ORAP"
            }
          }
        },
        "Restless Leg Syndrome (RLS) Agents": {
          "Restless Leg Syndrome (RLS) Agents": {
            "ARBOR PHARMACEUTICALS": {
              "HORIZANT": "HORIZANT"
            }
          }
        },
        "Smoking Deterrents": {
          "Smoking Deterrents": {
            "A-S MEDICATION SOLUTIONS": {
              "BUPROPION HCL ER (SMOKING DET)": "BUPROPION HCL ER (SMOKING DET)",
              "CHANTIX CONTINUING MONTH PAK": "CHANTIX CONTINUING MONTH PAK",
              "CHANTIX STARTING MONTH PAK": "CHANTIX STARTING MONTH PAK",
              "COMMIT": "COMMIT",
              "NICODERM CQ": "NICODERM CQ",
              "NICORELIEF": "NICORELIEF",
              "NICORETTE": "NICORETTE",
              "NICORETTE STARTER KIT": "NICORETTE STARTER KIT",
              "NICOTINE": "NICOTINE",
              "NICOTINE POLACRILEX": "NICOTINE POLACRILEX",
              "NICOTROL": "NICOTROL",
              "NICOTROL NS": "NICOTROL NS",
              "SM NICOTINE": "SM NICOTINE",
              "SM NICOTINE POLACRILEX": "SM NICOTINE POLACRILEX"
            },
            "ACTAVIS PHARMA": {
              "BUPROPION HCL ER (SMOKING DET)": "BUPROPION HCL ER (SMOKING DET)"
            },
            "AIDAREX PHARMACEUTICALS": {
              "NICOTINE": "NICOTINE"
            },
            "AMERICAN SALES COMPANY": {
              "NICOTINE POLACRILEX": "NICOTINE POLACRILEX",
              "NICOTINE STEP 1": "NICOTINE STEP 1",
              "NICOTINE STEP 2": "NICOTINE STEP 2",
              "NICOTINE STEP 3": "NICOTINE STEP 3"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP NICOTINE": "GNP NICOTINE",
              "GNP NICOTINE MINI": "GNP NICOTINE MINI",
              "GNP NICOTINE POLACRILEX": "GNP NICOTINE POLACRILEX",
              "NICOTINE": "NICOTINE"
            },
            "APHENA PHARMA SOLUTIONS": {
              "CHANTIX CONTINUING MONTH PAK": "CHANTIX CONTINUING MONTH PAK"
            },
            "APOTEX CONSUMER": {
              "NICOTINE STEP 1": "NICOTINE STEP 1",
              "NICOTINE STEP 2": "NICOTINE STEP 2",
              "NICOTINE STEP 3": "NICOTINE STEP 3"
            },
            "BERGEN BRUNSWIG": {
              "GNP NICOTINE POLACRILEX": "GNP NICOTINE POLACRILEX"
            },
            "BLUE POINT LABORATORIES": {
              "NICOTINE STEP 1": "NICOTINE STEP 1",
              "NICOTINE STEP 2": "NICOTINE STEP 2",
              "NICOTINE STEP 3": "NICOTINE STEP 3"
            },
            "CHAIN DRUG CONSORTIUM": {
              "NICOTINE POLACRILEX": "NICOTINE POLACRILEX",
              "NICOTINE STEP 1": "NICOTINE STEP 1",
              "NICOTINE STEP 2": "NICOTINE STEP 2",
              "NICOTINE STEP 3": "NICOTINE STEP 3"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC NICOTINE POLACRILEX": "QC NICOTINE POLACRILEX"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "CHANTIX STARTING MONTH PAK": "CHANTIX STARTING MONTH PAK"
            },
            "COSTCO WHOLESALE": {
              "KLS QUIT2": "KLS QUIT2",
              "KLS QUIT4": "KLS QUIT4"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS NICOTINE": "CVS NICOTINE",
              "CVS NICOTINE POLACRILEX": "CVS NICOTINE POLACRILEX",
              "CVS NTS STEP 1": "CVS NTS STEP 1"
            },
            "DR.REDDY'S LABORATORIES": {
              "NICOTINE": "NICOTINE"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "BUPROPION HCL ER (SMOKING DET)": "BUPROPION HCL ER (SMOKING DET)",
              "NICOTINE": "NICOTINE",
              "NICOTINE POLACRILEX": "NICOTINE POLACRILEX"
            },
            "EQUALINE": {
              "EQL NICOTINE": "EQL NICOTINE",
              "EQL NICOTINE POLACRILEX": "EQL NICOTINE POLACRILEX"
            },
            "GEISS DESTIN & DUNN": {
              "GOODSENSE NICOTINE": "GOODSENSE NICOTINE"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "NICODERM CQ": "NICODERM CQ",
              "NICORETTE": "NICORETTE",
              "NICORETTE MINI": "NICORETTE MINI",
              "NICORETTE STARTER KIT": "NICORETTE STARTER KIT",
              "THRIVE": "THRIVE"
            },
            "GLAXO SMITH KLINE": {
              "ZYBAN": "ZYBAN"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "NICOTINE": "NICOTINE"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "CHANTIX CONTINUING MONTH PAK": "CHANTIX CONTINUING MONTH PAK",
              "ZYBAN": "ZYBAN"
            },
            "KROGER COMPANY": {
              "NICOTINE": "NICOTINE",
              "NICOTINE POLACRILEX": "NICOTINE POLACRILEX"
            },
            "LEADER BRAND PRODUCTS": {
              "NICOTINE": "NICOTINE",
              "NICOTINE POLACRILEX": "NICOTINE POLACRILEX",
              "NICOTINE STEP 1": "NICOTINE STEP 1",
              "NICOTINE STEP 2": "NICOTINE STEP 2",
              "NICOTINE STEP 3": "NICOTINE STEP 3"
            },
            "MAJOR PHARMACEUTICALS": {
              "NICORELIEF": "NICORELIEF"
            },
            "MCKESSON": {
              "HM NICOTINE": "HM NICOTINE",
              "HM NICOTINE POLACRILEX": "HM NICOTINE POLACRILEX"
            },
            "MCKESSON SUNMARK": {
              "SM NICOTINE": "SM NICOTINE",
              "SM NICOTINE POLACRILEX": "SM NICOTINE POLACRILEX"
            },
            "MEDICINE SHOPPE": {
              "NICOTINE": "NICOTINE"
            },
            "MEIJER": {
              "NICOTINE POLACRILEX": "NICOTINE POLACRILEX",
              "NICOTINE STEP 1": "NICOTINE STEP 1",
              "NICOTINE STEP 2": "NICOTINE STEP 2",
              "NICOTINE STEP 3": "NICOTINE STEP 3"
            },
            "MYLAN": {
              "BUPROPION HCL ER (SMOKING DET)": "BUPROPION HCL ER (SMOKING DET)"
            },
            "PERRIGO": {
              "GOODSENSE NICOTINE": "GOODSENSE NICOTINE",
              "NICOTINE POLACRILEX": "NICOTINE POLACRILEX"
            },
            "PERRIGO PHARMACEUTICALS": {
              "GOODSENSE NICOTINE": "GOODSENSE NICOTINE",
              "NICOTINE POLACRILEX": "NICOTINE POLACRILEX"
            },
            "PFIZER U.S.": {
              "CHANTIX": "CHANTIX",
              "CHANTIX CONTINUING MONTH PAK": "CHANTIX CONTINUING MONTH PAK",
              "CHANTIX STARTING MONTH PAK": "CHANTIX STARTING MONTH PAK",
              "NICOTROL": "NICOTROL",
              "NICOTROL NS": "NICOTROL NS"
            },
            "PROFICIENT RX": {
              "CHANTIX CONTINUING MONTH PAK": "CHANTIX CONTINUING MONTH PAK"
            },
            "PUBLIX SUPER MARKETS INC.": {
              "PX STOP SMOKING AID": "PX STOP SMOKING AID"
            },
            "QUALITY CARE": {
              "CHANTIX": "CHANTIX",
              "CHANTIX CONTINUING MONTH PAK": "CHANTIX CONTINUING MONTH PAK",
              "CHANTIX STARTING MONTH PAK": "CHANTIX STARTING MONTH PAK",
              "NICOTINE": "NICOTINE"
            },
            "RITE AID CORPORATION": {
              "RA MINI NICOTINE": "RA MINI NICOTINE",
              "RA NICOTINE": "RA NICOTINE",
              "RA NICOTINE GUM": "RA NICOTINE GUM",
              "RA NICOTINE POLACRILEX": "RA NICOTINE POLACRILEX"
            },
            "RUGBY LABORATORIES": {
              "NICOTINE": "NICOTINE",
              "NICOTINE MINI": "NICOTINE MINI",
              "NICOTINE POLACRILEX": "NICOTINE POLACRILEX",
              "NICOTINE STEP 1": "NICOTINE STEP 1",
              "NICOTINE STEP 2": "NICOTINE STEP 2",
              "NICOTINE STEP 3": "NICOTINE STEP 3"
            },
            "RXPAK MCKESSON": {
              "BUPROPION HCL ER (SMOKING DET)": "BUPROPION HCL ER (SMOKING DET)"
            },
            "SAFEWAY": {
              "SW NICOTINE POLACRILEX": "SW NICOTINE POLACRILEX"
            },
            "SANDOZ": {
              "BUPROPION HCL ER (SMOKING DET)": "BUPROPION HCL ER (SMOKING DET)"
            },
            "SKY PACKAGING": {
              "NICOTINE POLACRILEX": "NICOTINE POLACRILEX"
            },
            "TARGET": {
              "TGT NICOTINE": "TGT NICOTINE",
              "TGT NICOTINE POLACRILEX": "TGT NICOTINE POLACRILEX",
              "TGT NICOTINE STEP ONE": "TGT NICOTINE STEP ONE",
              "TGT NICOTINE STEP THREE": "TGT NICOTINE STEP THREE",
              "TGT NICOTINE STEP TWO": "TGT NICOTINE STEP TWO"
            },
            "TEVA PHARMACEUTICALS USA": {
              "BUPROBAN": "BUPROBAN"
            },
            "TOPCO": {
              "NICOTINE POLACRILEX": "NICOTINE POLACRILEX",
              "NICOTINE STEP 1": "NICOTINE STEP 1",
              "NICOTINE STEP 2": "NICOTINE STEP 2",
              "NICOTINE STEP 3": "NICOTINE STEP 3"
            },
            "WAL-MART": {
              "EQ NICOTINE": "EQ NICOTINE",
              "EQ NICOTINE POLACRILEX": "EQ NICOTINE POLACRILEX",
              "EQ NICOTINE STEP 3": "EQ NICOTINE STEP 3",
              "NICOTINE POLACRILEX": "NICOTINE POLACRILEX",
              "SR NICOTINE": "SR NICOTINE"
            },
            "WALGREENS": {
              "NICOTINE": "NICOTINE",
              "NICOTINE MINI": "NICOTINE MINI",
              "NICOTINE POLACRILEX": "NICOTINE POLACRILEX",
              "NICOTINE STEP 1": "NICOTINE STEP 1",
              "NICOTINE STEP 2": "NICOTINE STEP 2",
              "NICOTINE STEP 3": "NICOTINE STEP 3"
            }
          }
        },
        "Transthyretin Amyloidosis Agents": {
          "Antisense Oligonucleotide (ASO) Inhibitor Agents": {
            "AKCEA THERAPEUTICS": {
              "TEGSEDI": "TEGSEDI"
            }
          },
          "Small Interfering Ribonucleic Acid (siRNA) Agents": {
            "ALNYLAM PHARMACEUTICALS": {
              "ONPATTRO": "ONPATTRO"
            }
          }
        },
        "Vasomotor Symptom Agents": {
          "Vasomotor Symptom Agents - SSRIs": {
            "PERRIGO": {
              "PAROXETINE MESYLATE": "PAROXETINE MESYLATE"
            },
            "SEBELA PHARMACEUTICALS": {
              "BRISDELLE": "BRISDELLE"
            },
            "SOLCO HEALTHCARE": {
              "PAROXETINE MESYLATE": "PAROXETINE MESYLATE"
            }
          }
        }
      },
      "RESPIRATORY AGENTS - MISC.": {
        "Alpha-Proteinase Inhibitor (Human)": {
          "Alpha-Proteinase Inhibitor (Human)": {
            "BAXALTA": {
              "ARALAST NP": "ARALAST NP",
              "GLASSIA": "GLASSIA"
            },
            "CSL BEHRING": {
              "ZEMAIRA": "ZEMAIRA"
            },
            "GRIFOLS USA": {
              "PROLASTIN-C": "PROLASTIN-C"
            }
          }
        },
        "Cystic Fibrosis Agents": {
          "CFTR Potentiators": {
            "VERTEX PHARMACEUTICALS, INC.": {
              "KALYDECO": "KALYDECO"
            }
          },
          "Cystic Fibrosis Agent - Combinations": {
            "VERTEX PHARMACEUTICALS, INC.": {
              "ORKAMBI": "ORKAMBI",
              "SYMDEKO": "SYMDEKO",
              "TRIKAFTA": "TRIKAFTA"
            }
          },
          "Hydrolytic Enzymes": {
            "GENENTECH": {
              "PULMOZYME": "PULMOZYME"
            }
          }
        },
        "Pleural Sclerosing Agents": {
          "Pleural Sclerosing Agents": {
            "BRYAN CORPORATION": {
              "SCLEROSOL INTRAPLEURAL": "SCLEROSOL INTRAPLEURAL",
              "STERILE TALC POWDER": "STERILE TALC POWDER"
            },
            "NOVATECH S.A.": {
              "STERITALC": "STERITALC"
            }
          }
        },
        "Pulmonary Fibrosis Agents": {
          "Pulmonary Fibrosis Agents": {
            "GENENTECH": {
              "ESBRIET": "ESBRIET"
            }
          },
          "Pulmonary Fibrosis Agents - Kinase Inhibitors": {
            "BOEHRINGER INGELHEIM": {
              "OFEV": "OFEV"
            }
          }
        },
        "Respiratory Agents - Misc.": {
          "Respiratory Agents - Misc.": {
            "ABBVIE": {
              "SURVANTA": "SURVANTA"
            },
            "CHIESI USA": {
              "CUROSURF": "CUROSURF"
            },
            "ONY": {
              "INFASURF": "INFASURF"
            }
          }
        }
      },
      "SULFONAMIDES": {
        "Sulfonamides": {
          "Sulfonamides": {
            "APOTHECA SUPPLY": {
              "SULFAMETHOXAZOLE": "SULFAMETHOXAZOLE"
            },
            "FAGRON": {
              "SULFADIAZINE": "SULFADIAZINE",
              "SULFAPYRIDINE": "SULFAPYRIDINE"
            },
            "LETCO MEDICAL": {
              "SULFADIAZINE": "SULFADIAZINE",
              "SULFADIAZINE SODIUM": "SULFADIAZINE SODIUM",
              "SULFAMETHOXAZOLE MICRO": "SULFAMETHOXAZOLE MICRO"
            },
            "MEDISCA": {
              "SULFADIAZINE": "SULFADIAZINE",
              "SULFADIAZINE SODIUM": "SULFADIAZINE SODIUM",
              "SULFAMETHOXAZOLE": "SULFAMETHOXAZOLE"
            },
            "PCCA": {
              "SULFADIAZINE": "SULFADIAZINE",
              "SULFADIAZINE SODIUM": "SULFADIAZINE SODIUM",
              "SULFAMETHOXAZOLE": "SULFAMETHOXAZOLE",
              "SULFAPYRIDINE": "SULFAPYRIDINE",
              "SULFATHIAZOLE": "SULFATHIAZOLE",
              "SULFISOXIZOLE": "SULFISOXIZOLE"
            },
            "SANDOZ": {
              "SULFADIAZINE": "SULFADIAZINE"
            },
            "SPECTRUM": {
              "SULFADIAZINE": "SULFADIAZINE",
              "SULFADIAZINE SODIUM": "SULFADIAZINE SODIUM",
              "SULFAMETHOXAZOLE": "SULFAMETHOXAZOLE",
              "SULFAPYRIDINE": "SULFAPYRIDINE"
            }
          }
        }
      },
      "TETRACYCLINES": {
        "Aminomethylcyclines": {
          "Aminomethylcyclines": {
            "PARATEK PHARMACEUTICALS": {
              "NUZYRA": "NUZYRA"
            }
          }
        },
        "Fluorocyclines": {
          "Fluorocyclines": {
            "TETRAPHASE PHARMACEUTICALS": {
              "XERAVA": "XERAVA"
            }
          }
        },
        "Glycylcyclines": {
          "Glycylcyclines": {
            "ACCORD HEALTHCARE": {
              "TIGECYCLINE": "TIGECYCLINE"
            },
            "AMNEAL BIOSCIENCES": {
              "TIGECYCLINE": "TIGECYCLINE"
            },
            "APOTEX": {
              "TIGECYCLINE": "TIGECYCLINE"
            },
            "AUROMEDICS PHARMA": {
              "TIGECYCLINE": "TIGECYCLINE"
            },
            "FRESENIUS KABI USA": {
              "TIGECYCLINE": "TIGECYCLINE"
            },
            "PFIZER U.S.": {
              "TYGACIL": "TYGACIL"
            },
            "SANDOZ": {
              "TIGECYCLINE": "TIGECYCLINE"
            },
            "XELLIA PHARMACEUTICALS USA": {
              "TIGECYCLINE": "TIGECYCLINE"
            }
          }
        },
        "Tetracycline Combinations": {
          "Tetracycline Combinations": {
            "AVIDAS PHARMACEUTICALS": {
              "AVIDOXY DK": "AVIDOXY DK"
            },
            "ELORAC": {
              "BENZODOX": "BENZODOX"
            }
          }
        },
        "Tetracyclines": {
          "Tetracyclines": {
            "A-S MEDICATION SOLUTIONS": {
              "DOXYCYCLINE HYCLATE": "DOXYCYCLINE HYCLATE",
              "DOXYCYCLINE MONOHYDRATE": "DOXYCYCLINE MONOHYDRATE",
              "MINOCYCLINE HCL": "MINOCYCLINE HCL"
            },
            "ACTAVIS": {
              "DORYX": "DORYX"
            },
            "ACTAVIS ELIZABETH": {
              "DOXYCYCLINE HYCLATE": "DOXYCYCLINE HYCLATE"
            },
            "ACTAVIS PHARMA": {
              "DOXYCYCLINE HYCLATE": "DOXYCYCLINE HYCLATE",
              "DOXYCYCLINE MONOHYDRATE": "DOXYCYCLINE MONOHYDRATE",
              "MINOCYCLINE HCL": "MINOCYCLINE HCL",
              "TETRACYCLINE HCL": "TETRACYCLINE HCL"
            },
            "ADVANCED VISION RESEARCH": {
              "NUTRIDOX": "NUTRIDOX"
            },
            "AIDAREX PHARMACEUTICALS": {
              "DOXYCYCLINE HYCLATE": "DOXYCYCLINE HYCLATE",
              "DOXYCYCLINE MONOHYDRATE": "DOXYCYCLINE MONOHYDRATE",
              "MINOCYCLINE HCL": "MINOCYCLINE HCL"
            },
            "AJANTA PHARMA LIMITED": {
              "DOXYCYCLINE HYCLATE": "DOXYCYCLINE HYCLATE"
            },
            "ALEMBIC PHARMACEUTICALS": {
              "DOXYCYCLINE HYCLATE": "DOXYCYCLINE HYCLATE",
              "DOXYCYCLINE MONOHYDRATE": "DOXYCYCLINE MONOHYDRATE"
            },
            "ALMIRALL": {
              "ACTICLATE": "ACTICLATE",
              "MONODOX": "MONODOX",
              "SEYSARA": "SEYSARA"
            },
            "ALTURA PHARMACEUTICALS": {
              "DOXYCYCLINE HYCLATE": "DOXYCYCLINE HYCLATE"
            },
            "ALVOGEN": {
              "DOXYCYCLINE HYCLATE": "DOXYCYCLINE HYCLATE",
              "MINOCYCLINE HCL": "MINOCYCLINE HCL",
              "TETRACYCLINE HCL": "TETRACYCLINE HCL"
            },
            "AMERICAN HEALTH PACKAGING": {
              "DEMECLOCYCLINE HCL": "DEMECLOCYCLINE HCL",
              "DOXYCYCLINE HYCLATE": "DOXYCYCLINE HYCLATE",
              "DOXYCYCLINE MONOHYDRATE": "DOXYCYCLINE MONOHYDRATE",
              "MINOCYCLINE HCL": "MINOCYCLINE HCL"
            },
            "AMERICAN PHARMA INGREDIENTS": {
              "DOXYCYCLINE HYCLATE": "DOXYCYCLINE HYCLATE"
            },
            "AMNEAL PHARMACEUTICALS": {
              "DEMECLOCYCLINE HCL": "DEMECLOCYCLINE HCL",
              "DOXYCYCLINE HYCLATE": "DOXYCYCLINE HYCLATE",
              "DOXYCYCLINE MONOHYDRATE": "DOXYCYCLINE MONOHYDRATE",
              "MINOCYCLINE HCL ER": "MINOCYCLINE HCL ER",
              "TETRACYCLINE HCL": "TETRACYCLINE HCL"
            },
            "APACE PACKAGING": {
              "DOXYCYCLINE HYCLATE": "DOXYCYCLINE HYCLATE"
            },
            "APHENA PHARMA SOLUTIONS": {
              "DOXYCYCLINE HYCLATE": "DOXYCYCLINE HYCLATE",
              "DOXYCYCLINE MONOHYDRATE": "DOXYCYCLINE MONOHYDRATE",
              "MINOCIN": "MINOCIN",
              "MINOCYCLINE HCL": "MINOCYCLINE HCL"
            },
            "APOTEX": {
              "DOXYCYCLINE HYCLATE": "DOXYCYCLINE HYCLATE"
            },
            "ASCEND LABORATORIES": {
              "MINOCYCLINE HCL ER": "MINOCYCLINE HCL ER"
            },
            "AUROBINDO PHARMA": {
              "MINOCYCLINE HCL": "MINOCYCLINE HCL",
              "MINOCYCLINE HCL ER": "MINOCYCLINE HCL ER"
            },
            "AVET PHARMACEUTICALS": {
              "DOXYCYCLINE HYCLATE": "DOXYCYCLINE HYCLATE",
              "DOXYCYCLINE MONOHYDRATE": "DOXYCYCLINE MONOHYDRATE",
              "TETRACYCLINE HCL": "TETRACYCLINE HCL"
            },
            "AVIDAS PHARMACEUTICALS": {
              "AVIDOXY": "AVIDOXY"
            },
            "AVKARE": {
              "DEMECLOCYCLINE HCL": "DEMECLOCYCLINE HCL",
              "DOXYCYCLINE HYCLATE": "DOXYCYCLINE HYCLATE",
              "DOXYCYCLINE MONOHYDRATE": "DOXYCYCLINE MONOHYDRATE",
              "MINOCYCLINE HCL": "MINOCYCLINE HCL",
              "TETRACYCLINE HCL": "TETRACYCLINE HCL"
            },
            "AVPAK": {
              "DOXYCYCLINE HYCLATE": "DOXYCYCLINE HYCLATE",
              "DOXYCYCLINE MONOHYDRATE": "DOXYCYCLINE MONOHYDRATE",
              "MINOCYCLINE HCL": "MINOCYCLINE HCL"
            },
            "BASIEM": {
              "DOXYCYCLINE HYCLATE": "DOXYCYCLINE HYCLATE"
            },
            "BAUSCH HEALTH": {
              "MINOCIN": "MINOCIN",
              "SOLODYN": "SOLODYN"
            },
            "BI COASTAL PHARMACEUTICAL": {
              "DOXYCYCLINE HYCLATE": "DOXYCYCLINE HYCLATE"
            },
            "BLENHEIM PHARMACAL": {
              "DOXYCYCLINE HYCLATE": "DOXYCYCLINE HYCLATE"
            },
            "BLU PHARMACEUTICALS": {
              "DEMECLOCYCLINE HCL": "DEMECLOCYCLINE HCL",
              "DOXYCYCLINE HYCLATE": "DOXYCYCLINE HYCLATE"
            },
            "BPI LABS LLC": {
              "DOXYCYCLINE HYCLATE": "DOXYCYCLINE HYCLATE"
            },
            "BRECKENRIDGE": {
              "TETRACYCLINE HCL": "TETRACYCLINE HCL"
            },
            "BRYANT RANCH PREPACK": {
              "DOXYCYCLINE HYCLATE": "DOXYCYCLINE HYCLATE",
              "DOXYCYCLINE MONOHYDRATE": "DOXYCYCLINE MONOHYDRATE",
              "MINOCYCLINE HCL": "MINOCYCLINE HCL"
            },
            "BURKE THERAPEUTICS": {
              "DOXYCYCLINE HYCLATE": "DOXYCYCLINE HYCLATE"
            },
            "CAMBRIDGE THERAPEUTICS TECHN": {
              "DOXYCYCLINE HYCLATE": "DOXYCYCLINE HYCLATE"
            },
            "CHARTWELL RX": {
              "DOXYCYCLINE HYCLATE": "DOXYCYCLINE HYCLATE",
              "DOXYCYCLINE MONOHYDRATE": "DOXYCYCLINE MONOHYDRATE",
              "TETRACYCLINE HCL": "TETRACYCLINE HCL"
            },
            "CIPLA USA": {
              "DOXYCYCLINE HYCLATE": "DOXYCYCLINE HYCLATE",
              "DOXYCYCLINE MONOHYDRATE": "DOXYCYCLINE MONOHYDRATE"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "DOXYCYCLINE HYCLATE": "DOXYCYCLINE HYCLATE",
              "MINOCYCLINE HCL": "MINOCYCLINE HCL"
            },
            "COSETTE PHARMACEUTICALS": {
              "DOXYCYCLINE MONOHYDRATE": "DOXYCYCLINE MONOHYDRATE"
            },
            "DENTON PHARMA": {
              "DOXYCYCLINE HYCLATE": "DOXYCYCLINE HYCLATE",
              "MINOCYCLINE HCL": "MINOCYCLINE HCL"
            },
            "DIRECT RX": {
              "DOXYCYCLINE HYCLATE": "DOXYCYCLINE HYCLATE",
              "DOXYCYCLINE MONOHYDRATE": "DOXYCYCLINE MONOHYDRATE",
              "MINOCYCLINE HCL": "MINOCYCLINE HCL"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "MINOCYCLINE HCL": "MINOCYCLINE HCL"
            },
            "ENCORE DERMATOLOGY": {
              "COREMINO": "COREMINO",
              "OKEBO": "OKEBO"
            },
            "ENCORE SCIENTIFIC": {
              "DOXYCYCLINE HYCLATE": "DOXYCYCLINE HYCLATE"
            },
            "ENOVACHEM MANUFACTURING": {
              "DOXYCYCLINE HYCLATE": "DOXYCYCLINE HYCLATE"
            },
            "EPI HEALTH": {
              "MINOLIRA": "MINOLIRA"
            },
            "EPIC PHARMA": {
              "DEMECLOCYCLINE HCL": "DEMECLOCYCLINE HCL",
              "DOXYCYCLINE HYCLATE": "DOXYCYCLINE HYCLATE"
            },
            "FAGRON": {
              "DOXYCYCLINE HYCLATE": "DOXYCYCLINE HYCLATE",
              "MINOCYCLINE HCL": "MINOCYCLINE HCL",
              "OXYTETRACYCLINE HCL": "OXYTETRACYCLINE HCL"
            },
            "FREEDOM PHARMACEUTICALS": {
              "DOXYCYCLINE HYCLATE": "DOXYCYCLINE HYCLATE"
            },
            "FRESENIUS KABI USA": {
              "DOXY 100": "DOXY 100"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "DEMECLOCYCLINE HCL": "DEMECLOCYCLINE HCL",
              "DOXYCYCLINE HYCLATE": "DOXYCYCLINE HYCLATE",
              "DOXYCYCLINE MONOHYDRATE": "DOXYCYCLINE MONOHYDRATE"
            },
            "GREENSTONE": {
              "DOXYCYCLINE HYCLATE": "DOXYCYCLINE HYCLATE"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "DOXYCYCLINE HYCLATE": "DOXYCYCLINE HYCLATE"
            },
            "HARRIS PHARMACEUTICAL": {
              "DOXYCYCLINE HYCLATE": "DOXYCYCLINE HYCLATE",
              "DOXYCYCLINE MONOHYDRATE": "DOXYCYCLINE MONOHYDRATE",
              "TETRACYCLINE HCL": "TETRACYCLINE HCL"
            },
            "HIKMA": {
              "DOXYCYCLINE HYCLATE": "DOXYCYCLINE HYCLATE"
            },
            "HUMCO": {
              "DOXYCYCLINE HYCLATE": "DOXYCYCLINE HYCLATE"
            },
            "IMPAX GENERICS": {
              "MINOCYCLINE HCL": "MINOCYCLINE HCL"
            },
            "JG PHARMA": {
              "DOXYCYCLINE HYCLATE": "DOXYCYCLINE HYCLATE",
              "MINOCYCLINE HCL ER": "MINOCYCLINE HCL ER"
            },
            "JOURNEY MEDICAL CORPORATION": {
              "TARGADOX": "TARGADOX",
              "XIMINO": "XIMINO"
            },
            "KAISER FOUNDATION HOSPITAL": {
              "DOXYCYCLINE MONOHYDRATE": "DOXYCYCLINE MONOHYDRATE",
              "MINOCYCLINE HCL": "MINOCYCLINE HCL"
            },
            "KALCHEM INTERNATIONAL": {
              "DOXYCYCLINE HYCLATE": "DOXYCYCLINE HYCLATE"
            },
            "LANNETT": {
              "DOXYCYCLINE HYCLATE": "DOXYCYCLINE HYCLATE",
              "DOXYCYCLINE MONOHYDRATE": "DOXYCYCLINE MONOHYDRATE"
            },
            "LARKEN LABORATORIES, INC.": {
              "DOXYCYCLINE HYCLATE": "DOXYCYCLINE HYCLATE"
            },
            "LETCO MEDICAL": {
              "DOXYCYCLINE HYCLATE": "DOXYCYCLINE HYCLATE"
            },
            "LIBERTY PHARMACEUTICAL": {
              "DOXYCYCLINE HYCLATE": "DOXYCYCLINE HYCLATE",
              "MINOCYCLINE HCL": "MINOCYCLINE HCL",
              "TETRACYCLINE HCL": "TETRACYCLINE HCL"
            },
            "LUPIN PHARMACEUTICALS": {
              "DOXYCYCLINE HYCLATE": "DOXYCYCLINE HYCLATE",
              "DOXYCYCLINE MONOHYDRATE": "DOXYCYCLINE MONOHYDRATE",
              "MINOCYCLINE HCL ER": "MINOCYCLINE HCL ER"
            },
            "MAJOR PHARMACEUTICALS": {
              "DEMECLOCYCLINE HCL": "DEMECLOCYCLINE HCL",
              "DOXYCYCLINE HYCLATE": "DOXYCYCLINE HYCLATE",
              "MINOCYCLINE HCL": "MINOCYCLINE HCL"
            },
            "MARLEX PHARMACEUTICALS": {
              "DEMECLOCYCLINE HCL": "DEMECLOCYCLINE HCL",
              "DOXYCYCLINE HYCLATE": "DOXYCYCLINE HYCLATE"
            },
            "MAYNE PHARMA": {
              "DORYX": "DORYX",
              "DORYX MPC": "DORYX MPC",
              "DOXYCYCLINE HYCLATE": "DOXYCYCLINE HYCLATE",
              "DOXYCYCLINE MONOHYDRATE": "DOXYCYCLINE MONOHYDRATE"
            },
            "MEDIMETRIKS PHARMACEUTICALS": {
              "MORGIDOX": "MORGIDOX"
            },
            "MEDISCA": {
              "DOXYCYCLINE HYCLATE": "DOXYCYCLINE HYCLATE",
              "OXYTETRACYCLINE HCL": "OXYTETRACYCLINE HCL"
            },
            "MEDVANTX": {
              "DOXYCYCLINE HYCLATE": "DOXYCYCLINE HYCLATE"
            },
            "MELINTA THERAPEUTICS": {
              "MINOCIN": "MINOCIN"
            },
            "MHC PHARMA": {
              "DOXYCYCLINE HYCLATE": "DOXYCYCLINE HYCLATE"
            },
            "MYLAN": {
              "DOXYCYCLINE HYCLATE": "DOXYCYCLINE HYCLATE",
              "DOXYCYCLINE MONOHYDRATE": "DOXYCYCLINE MONOHYDRATE",
              "MINOCYCLINE HCL ER": "MINOCYCLINE HCL ER"
            },
            "MYLAN INSTITUTIONAL": {
              "DOXYCYCLINE HYCLATE": "DOXYCYCLINE HYCLATE"
            },
            "NORTHSTAR RX": {
              "MINOCYCLINE HCL ER": "MINOCYCLINE HCL ER"
            },
            "NOVAPLUS/FRESENIUS KABI": {
              "DOXY 100": "DOXY 100"
            },
            "NUCARE PHARMACEUTICALS": {
              "DOXYCYCLINE HYCLATE": "DOXYCYCLINE HYCLATE"
            },
            "OCEANSIDE PHARMACEUTICALS": {
              "MINOCYCLINE HCL ER": "MINOCYCLINE HCL ER"
            },
            "OCULUS INNOVATIVE SERVICES": {
              "MONDOXYNE NL": "MONDOXYNE NL",
              "SOLOXIDE": "SOLOXIDE"
            },
            "PAR PHARMACEUTICAL": {
              "DOXYCYCLINE HYCLATE": "DOXYCYCLINE HYCLATE",
              "DOXYCYCLINE MONOHYDRATE": "DOXYCYCLINE MONOHYDRATE",
              "MINOCYCLINE HCL": "MINOCYCLINE HCL"
            },
            "PCCA": {
              "DOXYCYCLINE HYCLATE": "DOXYCYCLINE HYCLATE",
              "MINOCYCLINE HCL": "MINOCYCLINE HCL",
              "OXYTETRACYCLINE HCL": "OXYTETRACYCLINE HCL"
            },
            "PDRX PHARMACEUTICAL": {
              "DOXYCYCLINE HYCLATE": "DOXYCYCLINE HYCLATE",
              "DOXYCYCLINE MONOHYDRATE": "DOXYCYCLINE MONOHYDRATE",
              "MINOCYCLINE HCL": "MINOCYCLINE HCL",
              "VIBRAMYCIN": "VIBRAMYCIN"
            },
            "PDRX PHARMACEUTICALS": {
              "DOXYCYCLINE MONOHYDRATE": "DOXYCYCLINE MONOHYDRATE"
            },
            "PFIZER U.S.": {
              "VIBRAMYCIN": "VIBRAMYCIN"
            },
            "PHARMADERM": {
              "ADOXA": "ADOXA",
              "ADOXA PAK 1/100": "ADOXA PAK 1/100",
              "ADOXA PAK 1/150": "ADOXA PAK 1/150",
              "ADOXA PAK 2/100": "ADOXA PAK 2/100"
            },
            "PHARMEDIX": {
              "DOXYCYCLINE HYCLATE": "DOXYCYCLINE HYCLATE"
            },
            "PREFERRED PHARMACEUTICALS": {
              "DOXYCYCLINE HYCLATE": "DOXYCYCLINE HYCLATE",
              "MINOCYCLINE HCL": "MINOCYCLINE HCL"
            },
            "PROFICIENT RX": {
              "DOXYCYCLINE HYCLATE": "DOXYCYCLINE HYCLATE",
              "DOXYCYCLINE MONOHYDRATE": "DOXYCYCLINE MONOHYDRATE",
              "MINOCYCLINE HCL": "MINOCYCLINE HCL"
            },
            "PRUGEN": {
              "DOXYCYCLINE HYCLATE": "DOXYCYCLINE HYCLATE",
              "MINOCYCLINE HCL ER": "MINOCYCLINE HCL ER"
            },
            "QUALITY CARE": {
              "DOXYCYCLINE HYCLATE": "DOXYCYCLINE HYCLATE",
              "MINOCYCLINE HCL": "MINOCYCLINE HCL"
            },
            "QUALITY CARE PRODUCTS": {
              "DOXYCYCLINE HYCLATE": "DOXYCYCLINE HYCLATE",
              "DOXYCYCLINE MONOHYDRATE": "DOXYCYCLINE MONOHYDRATE",
              "MINOCYCLINE HCL": "MINOCYCLINE HCL"
            },
            "RISING PHARMACEUTICALS": {
              "DOXYCYCLINE HYCLATE": "DOXYCYCLINE HYCLATE"
            },
            "RXCHANGE CO": {
              "DOXYCYCLINE HYCLATE": "DOXYCYCLINE HYCLATE"
            },
            "SANDOZ": {
              "MINOCYCLINE HCL ER": "MINOCYCLINE HCL ER"
            },
            "SKY PACKAGING": {
              "DOXYCYCLINE HYCLATE": "DOXYCYCLINE HYCLATE"
            },
            "SOLCO HEALTHCARE": {
              "DOXYCYCLINE HYCLATE": "DOXYCYCLINE HYCLATE"
            },
            "SOLUBIOMIX LLC": {
              "DOXYCYCLINE HYCLATE": "DOXYCYCLINE HYCLATE"
            },
            "ST MARY'S MPP": {
              "DOXYCYCLINE HYCLATE": "DOXYCYCLINE HYCLATE",
              "DOXYCYCLINE MONOHYDRATE": "DOXYCYCLINE MONOHYDRATE"
            },
            "STI PHARMA": {
              "DOXYCYCLINE HYCLATE": "DOXYCYCLINE HYCLATE"
            },
            "STRIDES PHARMA": {
              "TETRACYCLINE HCL": "TETRACYCLINE HCL"
            },
            "SUN PHARMACEUTICALS": {
              "DOXYCYCLINE HYCLATE": "DOXYCYCLINE HYCLATE",
              "DOXYCYCLINE MONOHYDRATE": "DOXYCYCLINE MONOHYDRATE",
              "MINOCYCLINE HCL": "MINOCYCLINE HCL"
            },
            "TEVA PHARMACEUTICALS USA": {
              "DEMECLOCYCLINE HCL": "DEMECLOCYCLINE HCL",
              "DOXYCYCLINE HYCLATE": "DOXYCYCLINE HYCLATE",
              "MINOCYCLINE HCL": "MINOCYCLINE HCL",
              "MINOCYCLINE HCL ER": "MINOCYCLINE HCL ER"
            },
            "TORRENT PHARMACEUTICALS": {
              "MINOCYCLINE HCL": "MINOCYCLINE HCL"
            },
            "TOTAL PHARMACY SUPPLY": {
              "DOXYCYCLINE HYCLATE": "DOXYCYCLINE HYCLATE"
            },
            "UNIT DOSE SERVICES": {
              "DOXYCYCLINE HYCLATE": "DOXYCYCLINE HYCLATE",
              "MINOCYCLINE HCL": "MINOCYCLINE HCL"
            },
            "VERSAPHARM": {
              "DEMECLOCYCLINE HCL": "DEMECLOCYCLINE HCL",
              "DOXYCYCLINE HYCLATE": "DOXYCYCLINE HYCLATE"
            },
            "VIONA PHARMACEUTICALS": {
              "DOXYCYCLINE HYCLATE": "DOXYCYCLINE HYCLATE"
            },
            "WILLOW BIRCH PHARMA": {
              "DOXYCYCLINE HYCLATE": "DOXYCYCLINE HYCLATE",
              "MINOCYCLINE HCL": "MINOCYCLINE HCL"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "DOXYCYCLINE HYCLATE": "DOXYCYCLINE HYCLATE",
              "DOXYCYCLINE MONOHYDRATE": "DOXYCYCLINE MONOHYDRATE",
              "MINOCYCLINE HCL": "MINOCYCLINE HCL"
            }
          }
        }
      },
      "THYROID AGENTS": {
        "Antithyroid Agents": {
          "Antithyroid Agents": {
            "A-S MEDICATION SOLUTIONS": {
              "METHIMAZOLE": "METHIMAZOLE"
            },
            "ACTAVIS ELIZABETH": {
              "PROPYLTHIOURACIL": "PROPYLTHIOURACIL"
            },
            "AMERICAN HEALTH PACKAGING": {
              "METHIMAZOLE": "METHIMAZOLE",
              "PROPYLTHIOURACIL": "PROPYLTHIOURACIL"
            },
            "AMERICAN PHARMA INGREDIENTS": {
              "METHIMAZOLE": "METHIMAZOLE"
            },
            "AVET PHARMACEUTICALS": {
              "METHIMAZOLE": "METHIMAZOLE"
            },
            "AVKARE": {
              "PROPYLTHIOURACIL": "PROPYLTHIOURACIL"
            },
            "ECI PHARMACEUTICALS": {
              "METHIMAZOLE": "METHIMAZOLE"
            },
            "EXACT-RX": {
              "METHIMAZOLE": "METHIMAZOLE"
            },
            "FAGRON": {
              "METHIMAZOLE": "METHIMAZOLE"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "METHIMAZOLE": "METHIMAZOLE"
            },
            "HUMCO": {
              "METHIMAZOLE": "METHIMAZOLE"
            },
            "HURON PHARMACEUTICALS": {
              "METHIMAZOLE": "METHIMAZOLE"
            },
            "LETCO MEDICAL": {
              "METHIMAZOLE": "METHIMAZOLE"
            },
            "MEDISCA": {
              "METHIMAZOLE": "METHIMAZOLE"
            },
            "PAR PHARMACEUTICAL": {
              "METHIMAZOLE": "METHIMAZOLE",
              "PROPYLTHIOURACIL": "PROPYLTHIOURACIL"
            },
            "PCCA": {
              "METHIMAZOLE": "METHIMAZOLE"
            },
            "PDRX PHARMACEUTICAL": {
              "METHIMAZOLE": "METHIMAZOLE"
            },
            "PFIZER U.S.": {
              "TAPAZOLE": "TAPAZOLE"
            },
            "PREFERRED PHARMACEUTICALS": {
              "METHIMAZOLE": "METHIMAZOLE"
            },
            "RISING PHARMACEUTICALS": {
              "METHIMAZOLE": "METHIMAZOLE"
            },
            "SANDOZ": {
              "METHIMAZOLE": "METHIMAZOLE"
            },
            "SPECTRUM": {
              "METHIMAZOLE": "METHIMAZOLE"
            },
            "TOTAL PHARMACY SUPPLY": {
              "METHIMAZOLE": "METHIMAZOLE"
            }
          }
        },
        "Thyroid Hormones": {
          "Thyroid Hormones": {
            "A-S MEDICATION SOLUTIONS": {
              "ARMOUR THYROID": "ARMOUR THYROID",
              "CYTOMEL": "CYTOMEL",
              "LEVOTHYROXINE SODIUM": "LEVOTHYROXINE SODIUM",
              "LIOTHYRONINE SODIUM": "LIOTHYRONINE SODIUM",
              "NATURE-THROID": "NATURE-THROID",
              "SYNTHROID": "SYNTHROID"
            },
            "ABBVIE": {
              "SYNTHROID": "SYNTHROID"
            },
            "ACELLA PHARMACEUTICALS": {
              "NP THYROID": "NP THYROID"
            },
            "AIDAREX PHARMACEUTICALS": {
              "LEVOTHYROXINE SODIUM": "LEVOTHYROXINE SODIUM",
              "LIOTHYRONINE SODIUM": "LIOTHYRONINE SODIUM"
            },
            "ALLERGAN": {
              "ARMOUR THYROID": "ARMOUR THYROID",
              "THYROLAR-1": "THYROLAR-1",
              "THYROLAR-1/2": "THYROLAR-1/2",
              "THYROLAR-1/4": "THYROLAR-1/4",
              "THYROLAR-2": "THYROLAR-2",
              "THYROLAR-3": "THYROLAR-3"
            },
            "ALTURA PHARMACEUTICALS": {
              "LEVOTHYROXINE SODIUM": "LEVOTHYROXINE SODIUM"
            },
            "ALVOGEN": {
              "LEVOTHYROXINE SODIUM": "LEVOTHYROXINE SODIUM"
            },
            "AMERICAN HEALTH PACKAGING": {
              "LEVOTHYROXINE SODIUM": "LEVOTHYROXINE SODIUM"
            },
            "AMNEAL PHARMACEUTICALS": {
              "LEVOTHYROXINE SODIUM": "LEVOTHYROXINE SODIUM"
            },
            "AMNEAL SPECIALTY": {
              "UNITHROID": "UNITHROID"
            },
            "APHENA PHARMA SOLUTIONS": {
              "LEVOTHYROXINE SODIUM": "LEVOTHYROXINE SODIUM",
              "SYNTHROID": "SYNTHROID"
            },
            "ATHENEX PHARMACEUTICAL": {
              "LEVOTHYROXINE SODIUM": "LEVOTHYROXINE SODIUM"
            },
            "AVKARE": {
              "LIOTHYRONINE SODIUM": "LIOTHYRONINE SODIUM"
            },
            "BLENHEIM PHARMACAL": {
              "LEVOTHYROXINE SODIUM": "LEVOTHYROXINE SODIUM"
            },
            "BRYANT RANCH PREPACK": {
              "ARMOUR THYROID": "ARMOUR THYROID",
              "LEVOTHYROXINE SODIUM": "LEVOTHYROXINE SODIUM"
            },
            "CARDINAL HEALTH REPACKAGING SO": {
              "LEVOTHYROXINE SODIUM": "LEVOTHYROXINE SODIUM"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "LEVOTHYROXINE SODIUM": "LEVOTHYROXINE SODIUM"
            },
            "DENTON PHARMA": {
              "LEVOTHYROXINE SODIUM": "LEVOTHYROXINE SODIUM"
            },
            "DIRECT RX": {
              "LEVOTHYROXINE SODIUM": "LEVOTHYROXINE SODIUM"
            },
            "FAGRON": {
              "THYROID": "THYROID"
            },
            "FREEDOM PHARMACEUTICALS": {
              "THYROID": "THYROID"
            },
            "FRESENIUS KABI USA": {
              "LEVOTHYROXINE SODIUM": "LEVOTHYROXINE SODIUM"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "LIOTHYRONINE SODIUM": "LIOTHYRONINE SODIUM"
            },
            "GREENSTONE": {
              "LIOTHYRONINE SODIUM": "LIOTHYRONINE SODIUM"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "LEVOTHYROXINE SODIUM": "LEVOTHYROXINE SODIUM",
              "SYNTHROID": "SYNTHROID"
            },
            "IBSA PHARMA": {
              "TIROSINT": "TIROSINT",
              "TIROSINT-SOL": "TIROSINT-SOL"
            },
            "LANNETT": {
              "LEVOTHYROXINE SODIUM": "LEVOTHYROXINE SODIUM",
              "UNITHROID DIRECT": "UNITHROID DIRECT"
            },
            "LIBERTY PHARMACEUTICAL": {
              "LEVOTHYROXINE SODIUM": "LEVOTHYROXINE SODIUM"
            },
            "LUPIN PHARMACEUTICALS": {
              "LEVOTHYROXINE SODIUM": "LEVOTHYROXINE SODIUM"
            },
            "MAJOR PHARMACEUTICALS": {
              "LEVOTHYROXINE SODIUM": "LEVOTHYROXINE SODIUM"
            },
            "MAYNE PHARMA": {
              "LIOTHYRONINE SODIUM": "LIOTHYRONINE SODIUM"
            },
            "MEDISCA": {
              "THYROID": "THYROID"
            },
            "MEDVANTX": {
              "LEVOTHYROXINE SODIUM": "LEVOTHYROXINE SODIUM"
            },
            "MYLAN": {
              "LEVOTHYROXINE SODIUM": "LEVOTHYROXINE SODIUM",
              "LIOTHYRONINE SODIUM": "LIOTHYRONINE SODIUM"
            },
            "MYLAN INSTITUTIONAL": {
              "LEVOTHYROXINE SODIUM": "LEVOTHYROXINE SODIUM"
            },
            "NEOLPHARMA": {
              "LEVO-T": "LEVO-T"
            },
            "NORTHWIND PHARMACEUTICALS": {
              "LEVOTHYROXINE SODIUM": "LEVOTHYROXINE SODIUM"
            },
            "NOVAPLUS/FRESENIUS KABI": {
              "LEVOTHYROXINE SODIUM": "LEVOTHYROXINE SODIUM"
            },
            "NUCARE PHARMACEUTICALS": {
              "CYTOMEL": "CYTOMEL",
              "LEVOTHYROXINE SODIUM": "LEVOTHYROXINE SODIUM"
            },
            "PAR STERILE PRODUCTS": {
              "LEVOTHYROXINE SODIUM": "LEVOTHYROXINE SODIUM",
              "TRIOSTAT": "TRIOSTAT"
            },
            "PCCA": {
              "THYROID": "THYROID"
            },
            "PDRX PHARMACEUTICAL": {
              "ARMOUR THYROID": "ARMOUR THYROID",
              "LEVOTHYROXINE SODIUM": "LEVOTHYROXINE SODIUM",
              "LIOTHYRONINE SODIUM": "LIOTHYRONINE SODIUM",
              "NATURE-THROID": "NATURE-THROID",
              "SYNTHROID": "SYNTHROID",
              "THYROID": "THYROID"
            },
            "PDRX PHARMACEUTICALS": {
              "LEVOTHYROXINE SODIUM": "LEVOTHYROXINE SODIUM"
            },
            "PERRIGO": {
              "LIOTHYRONINE SODIUM": "LIOTHYRONINE SODIUM"
            },
            "PFIZER U.S.": {
              "CYTOMEL": "CYTOMEL",
              "LEVOXYL": "LEVOXYL"
            },
            "PIRAMAL CRITICAL CARE": {
              "LEVOTHYROXINE SODIUM": "LEVOTHYROXINE SODIUM"
            },
            "PREFERRED PHARMACEUTICALS": {
              "LEVOTHYROXINE SODIUM": "LEVOTHYROXINE SODIUM",
              "LIOTHYRONINE SODIUM": "LIOTHYRONINE SODIUM",
              "NATURE-THROID": "NATURE-THROID",
              "NP THYROID": "NP THYROID"
            },
            "PROFICIENT RX": {
              "LEVOTHYROXINE SODIUM": "LEVOTHYROXINE SODIUM",
              "LIOTHYRONINE SODIUM": "LIOTHYRONINE SODIUM"
            },
            "PROVELL PHARMACEUTICALS": {
              "EUTHYROX": "EUTHYROX"
            },
            "QUALITY CARE": {
              "ARMOUR THYROID": "ARMOUR THYROID",
              "CYTOMEL": "CYTOMEL",
              "LEVOTHYROXINE SODIUM": "LEVOTHYROXINE SODIUM",
              "SYNTHROID": "SYNTHROID"
            },
            "QUALITY CARE PRODUCTS": {
              "LEVOTHYROXINE SODIUM": "LEVOTHYROXINE SODIUM"
            },
            "RLC LABS": {
              "NATURE-THROID": "NATURE-THROID",
              "WESTHROID": "WESTHROID",
              "WP THYROID": "WP THYROID"
            },
            "RXCHANGE CO": {
              "ARMOUR THYROID": "ARMOUR THYROID",
              "LEVOTHYROXINE SODIUM": "LEVOTHYROXINE SODIUM"
            },
            "SANDOZ": {
              "LEVOTHYROXINE SODIUM": "LEVOTHYROXINE SODIUM"
            },
            "SIGMAPHARM LABORATORIES": {
              "LIOTHYRONINE SODIUM": "LIOTHYRONINE SODIUM"
            },
            "SPECTRUM": {
              "THYROID": "THYROID"
            },
            "ST MARY'S MPP": {
              "LEVOTHYROXINE SODIUM": "LEVOTHYROXINE SODIUM"
            },
            "SUN PHARMACEUTICALS": {
              "LIOTHYRONINE SODIUM": "LIOTHYRONINE SODIUM"
            },
            "VA CMOP DALLAS": {
              "LEVOTHYROXINE SODIUM": "LEVOTHYROXINE SODIUM"
            },
            "WESTMINSTER PHARMACEUTICALS": {
              "THYROID": "THYROID"
            },
            "WILLOW BIRCH PHARMA": {
              "THYROID": "THYROID"
            },
            "X-GEN PHARMACEUTICALS, INC": {
              "LIOTHYRONINE SODIUM": "LIOTHYRONINE SODIUM"
            }
          }
        }
      },
      "TOXOIDS": {
        "Toxoid Combinations": {
          "Toxoid Combinations": {
            "A-S MEDICATION SOLUTIONS": {
              "ADACEL": "ADACEL",
              "BOOSTRIX": "BOOSTRIX",
              "DIPHTHERIA-TETANUS TOXOIDS": "DIPHTHERIA-TETANUS TOXOIDS",
              "INFANRIX": "INFANRIX",
              "PEDIARIX": "PEDIARIX",
              "TETANUS-DIPHTHERIA TOXOIDS TD": "TETANUS-DIPHTHERIA TOXOIDS TD"
            },
            "GLAXO SMITH KLINE": {
              "BOOSTRIX": "BOOSTRIX",
              "INFANRIX": "INFANRIX",
              "KINRIX": "KINRIX",
              "PEDIARIX": "PEDIARIX"
            },
            "GRIFOLS USA": {
              "TDVAX": "TDVAX"
            },
            "MERCK SHARP & DOHME": {
              "TETANUS-DIPHTHERIA TOXOIDS TD": "TETANUS-DIPHTHERIA TOXOIDS TD"
            },
            "SANOFI PASTEUR": {
              "ADACEL": "ADACEL",
              "DAPTACEL": "DAPTACEL",
              "DIPHTHERIA-TETANUS TOXOIDS DT": "DIPHTHERIA-TETANUS TOXOIDS DT",
              "PENTACEL": "PENTACEL",
              "QUADRACEL": "QUADRACEL",
              "TENIVAC": "TENIVAC"
            }
          }
        }
      },
      "ULCER DRUGS/ANTISPASMODICS/ANTICHOLINERGICS": {
        "Antispasmodics": {
          "Anticholinergic Combinations": {
            "A-S MEDICATION SOLUTIONS": {
              "CHLORDIAZEPOXIDE-CLIDINIUM": "CHLORDIAZEPOXIDE-CLIDINIUM"
            },
            "ACELLA PHARMACEUTICALS": {
              "CHLORDIAZEPOXIDE-CLIDINIUM": "CHLORDIAZEPOXIDE-CLIDINIUM"
            },
            "APOTHECON FLORIDA": {
              "PHENOBARBITAL-BELLADONNA ALK": "PHENOBARBITAL-BELLADONNA ALK"
            },
            "ATLANTIC BIOLOGICALS": {
              "DONNATAL": "DONNATAL",
              "PHENOBARBITAL-BELLADONNA ALK": "PHENOBARBITAL-BELLADONNA ALK",
              "PHENOHYTRO": "PHENOHYTRO"
            },
            "AVKARE": {
              "DONNATAL": "DONNATAL"
            },
            "BAUSCH HEALTH": {
              "LIBRAX": "LIBRAX"
            },
            "BI COASTAL PHARMACEUTICAL": {
              "CHLORDIAZEPOXIDE-CLIDINIUM": "CHLORDIAZEPOXIDE-CLIDINIUM"
            },
            "BRYANT RANCH PREPACK": {
              "CHLORDIAZEPOXIDE-CLIDINIUM": "CHLORDIAZEPOXIDE-CLIDINIUM"
            },
            "BUREL PHARMACEUTICALS": {
              "CHLORDIAZEPOXIDE-CLIDINIUM": "CHLORDIAZEPOXIDE-CLIDINIUM"
            },
            "CAMERON PHARMACEUTICALS": {
              "CHLORDIAZEPOXIDE-CLIDINIUM": "CHLORDIAZEPOXIDE-CLIDINIUM"
            },
            "CONCORDIA PHARMACEUTICALS": {
              "DONNATAL": "DONNATAL"
            },
            "ECI PHARMACEUTICALS": {
              "CHLORDIAZEPOXIDE-CLIDINIUM": "CHLORDIAZEPOXIDE-CLIDINIUM"
            },
            "HIKMA": {
              "PB-HYOSCY-ATROPINE-SCOPOLAMINE": "PB-HYOSCY-ATROPINE-SCOPOLAMINE"
            },
            "LAZARUS PHARMACEUTICALS INC": {
              "PHENOBARBITAL-BELLADONNA ALK": "PHENOBARBITAL-BELLADONNA ALK"
            },
            "LIBERTY PHARMACEUTICAL": {
              "CHLORDIAZEPOXIDE-CLIDINIUM": "CHLORDIAZEPOXIDE-CLIDINIUM"
            },
            "LLORENS PHARMACEUTICAL": {
              "CHLORDIAZEPOXIDE-CLIDINIUM": "CHLORDIAZEPOXIDE-CLIDINIUM"
            },
            "OCEANSIDE PHARMACEUTICALS": {
              "CHLORDIAZEPOXIDE-CLIDINIUM": "CHLORDIAZEPOXIDE-CLIDINIUM"
            },
            "PDRX PHARMACEUTICAL": {
              "DONNATAL": "DONNATAL"
            },
            "PERRIGO": {
              "BELLADONNA ALKALOIDS-OPIUM": "BELLADONNA ALKALOIDS-OPIUM"
            },
            "PHARMADERM": {
              "PAMINE FQ": "PAMINE FQ"
            },
            "PRASCO LABORATORIES": {
              "PB-HYOSCY-ATROPINE-SCOPOLAMINE": "PB-HYOSCY-ATROPINE-SCOPOLAMINE"
            },
            "SUNRISE PHARMACEUTICAL": {
              "CHLORDIAZEPOXIDE-CLIDINIUM": "CHLORDIAZEPOXIDE-CLIDINIUM"
            },
            "VILVET PHARMACEUTICAL": {
              "CHLORDIAZEPOXIDE-CLIDINIUM": "CHLORDIAZEPOXIDE-CLIDINIUM"
            },
            "VIRTUS PHARMACEUTICALS OPCO": {
              "CHLORDIAZEPOXIDE-CLIDINIUM": "CHLORDIAZEPOXIDE-CLIDINIUM"
            },
            "VISTAPHARM INC.": {
              "DONNATAL": "DONNATAL"
            },
            "WINDER LABORATORIES": {
              "PHENOHYTRO": "PHENOHYTRO"
            }
          },
          "Antispasmodics": {
            "A-S MEDICATION SOLUTIONS": {
              "DICYCLOMINE HCL": "DICYCLOMINE HCL"
            },
            "ACTAVIS PHARMA": {
              "DICYCLOMINE HCL": "DICYCLOMINE HCL"
            },
            "AIDAREX PHARMACEUTICALS": {
              "DICYCLOMINE HCL": "DICYCLOMINE HCL"
            },
            "AKORN": {
              "DICYCLOMINE HCL": "DICYCLOMINE HCL"
            },
            "ALLERGAN": {
              "BENTYL": "BENTYL"
            },
            "AMERICAN HEALTH PACKAGING": {
              "DICYCLOMINE HCL": "DICYCLOMINE HCL"
            },
            "AMERICAN REGENT": {
              "DICYCLOMINE HCL": "DICYCLOMINE HCL"
            },
            "APHENA PHARMA SOLUTIONS": {
              "DICYCLOMINE HCL": "DICYCLOMINE HCL"
            },
            "APTALIS PHARMA": {
              "BENTYL": "BENTYL"
            },
            "ATLANTIC BIOLOGICALS": {
              "DICYCLOMINE HCL": "DICYCLOMINE HCL"
            },
            "BLENHEIM PHARMACAL": {
              "DICYCLOMINE HCL": "DICYCLOMINE HCL"
            },
            "BRYANT RANCH PREPACK": {
              "DICYCLOMINE HCL": "DICYCLOMINE HCL"
            },
            "CAMBER PHARMACEUTICALS": {
              "DICYCLOMINE HCL": "DICYCLOMINE HCL"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "DICYCLOMINE HCL": "DICYCLOMINE HCL"
            },
            "DIRECT RX": {
              "DICYCLOMINE HCL": "DICYCLOMINE HCL"
            },
            "ENTERA HEALTH": {
              "DICYCLOMINE HCL": "DICYCLOMINE HCL"
            },
            "FOSUN PHARMA USA": {
              "DICYCLOMINE HCL": "DICYCLOMINE HCL"
            },
            "FRESENIUS KABI USA": {
              "DICYCLOMINE HCL": "DICYCLOMINE HCL"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "DICYCLOMINE HCL": "DICYCLOMINE HCL"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "BENTYL": "BENTYL",
              "DICYCLOMINE HCL": "DICYCLOMINE HCL"
            },
            "HIKMA": {
              "DICYCLOMINE HCL": "DICYCLOMINE HCL"
            },
            "LANNETT": {
              "DICYCLOMINE HCL": "DICYCLOMINE HCL"
            },
            "LEUCADIA PHARMACEUTICALS": {
              "DICYCLOMINE HCL": "DICYCLOMINE HCL"
            },
            "LIBERTY PHARMACEUTICAL": {
              "DICYCLOMINE HCL": "DICYCLOMINE HCL"
            },
            "MAJOR PHARMACEUTICALS": {
              "DICYCLOMINE HCL": "DICYCLOMINE HCL"
            },
            "MARLEX PHARMACEUTICALS": {
              "DICYCLOMINE HCL": "DICYCLOMINE HCL"
            },
            "MEDVANTX": {
              "DICYCLOMINE HCL": "DICYCLOMINE HCL"
            },
            "MYLAN": {
              "DICYCLOMINE HCL": "DICYCLOMINE HCL"
            },
            "MYLAN INSTITUTIONAL": {
              "DICYCLOMINE HCL": "DICYCLOMINE HCL"
            },
            "NEXUS PHARMA": {
              "DICYCLOMINE HCL": "DICYCLOMINE HCL"
            },
            "NUCARE PHARMACEUTICALS": {
              "DICYCLOMINE HCL": "DICYCLOMINE HCL"
            },
            "PAR PHARMACEUTICALS": {
              "DICYCLOMINE HCL": "DICYCLOMINE HCL"
            },
            "PDRX PHARMACEUTICAL": {
              "DICYCLOMINE HCL": "DICYCLOMINE HCL"
            },
            "PHARMEDIX": {
              "DICYCLOMINE HCL": "DICYCLOMINE HCL"
            },
            "PREFERRED PHARMACEUTICALS": {
              "DICYCLOMINE HCL": "DICYCLOMINE HCL"
            },
            "PROFICIENT RX": {
              "DICYCLOMINE HCL": "DICYCLOMINE HCL"
            },
            "QUALITY CARE": {
              "DICYCLOMINE HCL": "DICYCLOMINE HCL"
            },
            "QUALITY CARE PRODUCTS": {
              "DICYCLOMINE HCL": "DICYCLOMINE HCL"
            },
            "ST MARY'S MPP": {
              "DICYCLOMINE HCL": "DICYCLOMINE HCL"
            },
            "UNIT DOSE SERVICES": {
              "DICYCLOMINE HCL": "DICYCLOMINE HCL"
            }
          },
          "Belladonna Alkaloids": {
            "A-S MEDICATION SOLUTIONS": {
              "ATROPINE SULFATE": "ATROPINE SULFATE",
              "HYOSCYAMINE SULFATE": "HYOSCYAMINE SULFATE"
            },
            "ACELLA PHARMACEUTICALS": {
              "HYOSCYAMINE SULFATE": "HYOSCYAMINE SULFATE"
            },
            "AIDAREX PHARMACEUTICALS": {
              "HYOSCYAMINE SULFATE": "HYOSCYAMINE SULFATE"
            },
            "ALVOGEN": {
              "HYOSCYAMINE SULFATE": "HYOSCYAMINE SULFATE",
              "HYOSCYAMINE SULFATE ER": "HYOSCYAMINE SULFATE ER"
            },
            "AMERICAN REGENT": {
              "ATROPINE SULFATE": "ATROPINE SULFATE",
              "ATROPINE SULFATE (PF)": "ATROPINE SULFATE (PF)"
            },
            "ANDAPHARM": {
              "HYOSCYAMINE SULFATE": "HYOSCYAMINE SULFATE"
            },
            "API SOLUTIONS": {
              "ATROPINE SULFATE MONOHYDRATE": "ATROPINE SULFATE MONOHYDRATE"
            },
            "B.F. ASCHER": {
              "ANASPAZ": "ANASPAZ"
            },
            "BPI LABS LLC": {
              "HYOSCYAMINE SULFATE": "HYOSCYAMINE SULFATE"
            },
            "BRYANT RANCH PREPACK": {
              "HYOSCYAMINE SULFATE": "HYOSCYAMINE SULFATE",
              "HYOSCYAMINE SULFATE ER": "HYOSCYAMINE SULFATE ER"
            },
            "CAPELLON PHARMACEUTICALS": {
              "SYMAX DUOTAB": "SYMAX DUOTAB",
              "SYMAX-SL": "SYMAX-SL",
              "SYMAX-SR": "SYMAX-SR"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "HYOSCYAMINE SULFATE": "HYOSCYAMINE SULFATE"
            },
            "CYPRESS PHARMACEUTICAL": {
              "HYOSCYAMINE SULFATE": "HYOSCYAMINE SULFATE"
            },
            "DIRECT RX": {
              "HYOSCYAMINE SULFATE": "HYOSCYAMINE SULFATE"
            },
            "FAGRON": {
              "ATROPINE SULFATE": "ATROPINE SULFATE",
              "ATROPINE SULFATE MONOHYDRATE": "ATROPINE SULFATE MONOHYDRATE",
              "SCOPOLAMINE HBR": "SCOPOLAMINE HBR"
            },
            "FREEDOM PHARMACEUTICALS": {
              "ATROPINE SULFATE MONOHYDRATE": "ATROPINE SULFATE MONOHYDRATE"
            },
            "FRESENIUS KABI USA": {
              "ATROPINE SULFATE": "ATROPINE SULFATE"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "HYOSCYAMINE SULFATE": "HYOSCYAMINE SULFATE"
            },
            "HIKMA": {
              "ATROPINE SULFATE": "ATROPINE SULFATE"
            },
            "HOSPIRA": {
              "ATROPINE SULFATE": "ATROPINE SULFATE"
            },
            "INTRNTL MEDICATION SYSTEM": {
              "ATROPINE SULFATE": "ATROPINE SULFATE"
            },
            "KMR PHARMACEUTICALS": {
              "HYOSCYAMINE SULFATE": "HYOSCYAMINE SULFATE",
              "HYOSCYAMINE SULFATE ER": "HYOSCYAMINE SULFATE ER"
            },
            "LARKEN LABORATORIES, INC.": {
              "OSCIMIN": "OSCIMIN",
              "OSCIMIN SR": "OSCIMIN SR"
            },
            "LETCO MEDICAL": {
              "ATROPINE SULFATE MONOHYDRATE": "ATROPINE SULFATE MONOHYDRATE",
              "SCOPOLAMINE HBR": "SCOPOLAMINE HBR"
            },
            "MARLEX PHARMACEUTICALS": {
              "HYOSCYAMINE SULFATE": "HYOSCYAMINE SULFATE"
            },
            "MEDISCA": {
              "ATROPINE SULFATE MONOHYDRATE": "ATROPINE SULFATE MONOHYDRATE"
            },
            "MERIDIAN MEDICAL TECHNOLOGIES": {
              "ATROPEN": "ATROPEN"
            },
            "METHOD PHARMACEUTICALS": {
              "HYOSCYAMINE SULFATE": "HYOSCYAMINE SULFATE"
            },
            "MYLAN SPECIALTY L.P.": {
              "LEVBID": "LEVBID",
              "LEVSIN": "LEVSIN",
              "LEVSIN/SL": "LEVSIN/SL",
              "NULEV": "NULEV"
            },
            "NEPHRON STERILE COMPOUNDING": {
              "ATROPINE SULFATE": "ATROPINE SULFATE"
            },
            "NOBLE PHARMACEUTICALS": {
              "HYOSCYAMINE SULFATE": "HYOSCYAMINE SULFATE"
            },
            "NUCARE PHARMACEUTICALS": {
              "HYOSCYAMINE SULFATE": "HYOSCYAMINE SULFATE"
            },
            "PATRIN PHARMA": {
              "HYOSCYAMINE SULFATE": "HYOSCYAMINE SULFATE"
            },
            "PCCA": {
              "ATROPINE SULFATE MONOHYDRATE": "ATROPINE SULFATE MONOHYDRATE",
              "HYOSCYAMINE SULFATE": "HYOSCYAMINE SULFATE",
              "SCOPOLAMINE HBR": "SCOPOLAMINE HBR"
            },
            "PDRX PHARMACEUTICAL": {
              "HYOSCYAMINE SULFATE": "HYOSCYAMINE SULFATE"
            },
            "PHARMEDIUM SERVICES": {
              "ATROPINE SULFATE": "ATROPINE SULFATE"
            },
            "PREFERRED PHARMACEUTICALS": {
              "HYOSCYAMINE SULFATE": "HYOSCYAMINE SULFATE"
            },
            "QUVA PHARMA": {
              "ATROPINE SULFATE": "ATROPINE SULFATE"
            },
            "RXCHANGE CO": {
              "HYOSCYAMINE SULFATE": "HYOSCYAMINE SULFATE"
            },
            "SILARX": {
              "HYOSYNE": "HYOSYNE"
            },
            "TRIGEN LABORATORIES": {
              "HYOSCYAMINE SULFATE": "HYOSCYAMINE SULFATE"
            },
            "VILVET PHARMACEUTICAL": {
              "HYOSCYAMINE SULFATE": "HYOSCYAMINE SULFATE",
              "HYOSCYAMINE SULFATE SL": "HYOSCYAMINE SULFATE SL"
            },
            "VIRTUS PHARMACEUTICALS OPCO": {
              "HYOSCYAMINE SULFATE": "HYOSCYAMINE SULFATE"
            },
            "WALLACE PHARMACEUTICALS": {
              "HYOSCYAMINE SULFATE": "HYOSCYAMINE SULFATE",
              "HYOSCYAMINE SULFATE ER": "HYOSCYAMINE SULFATE ER"
            },
            "WILLOW BIRCH PHARMA": {
              "ATROPINE SULFATE": "ATROPINE SULFATE"
            },
            "WOMEN'S CHOICE PHARMACEUTICALS": {
              "ED-SPAZ": "ED-SPAZ"
            }
          },
          "Quaternary Anticholinergics": {
            "A-S MEDICATION SOLUTIONS": {
              "GLYCOPYRROLATE": "GLYCOPYRROLATE"
            },
            "AMERICAN HEALTH PACKAGING": {
              "GLYCOPYRROLATE": "GLYCOPYRROLATE"
            },
            "AMERICAN REGENT": {
              "GLYCOPYRROLATE": "GLYCOPYRROLATE"
            },
            "AMNEAL BIOSCIENCES": {
              "GLYCOPYRROLATE": "GLYCOPYRROLATE"
            },
            "APOTEX": {
              "GLYCOPYRROLATE": "GLYCOPYRROLATE"
            },
            "APOTHECA SUPPLY": {
              "PROPANTHELINE BROMIDE": "PROPANTHELINE BROMIDE"
            },
            "AUROBINDO PHARMA USA": {
              "GLYCOPYRROLATE": "GLYCOPYRROLATE"
            },
            "AUROMEDICS PHARMA": {
              "GLYCOPYRROLATE": "GLYCOPYRROLATE"
            },
            "AVET PHARMACEUTICALS": {
              "GLYCOPYRROLATE": "GLYCOPYRROLATE"
            },
            "AVKARE": {
              "GLYCOPYRROLATE": "GLYCOPYRROLATE"
            },
            "BAYSHORE PHARMACEUTICALS": {
              "METHSCOPOLAMINE BROMIDE": "METHSCOPOLAMINE BROMIDE"
            },
            "BLUE POINT LABORATORIES": {
              "GLYCOPYRROLATE": "GLYCOPYRROLATE"
            },
            "BRECKENRIDGE": {
              "METHSCOPOLAMINE BROMIDE": "METHSCOPOLAMINE BROMIDE"
            },
            "CARWIN ASSOCIATES": {
              "GLYCATE": "GLYCATE"
            },
            "CASPER PHARMA": {
              "ROBINUL": "ROBINUL",
              "ROBINUL-FORTE": "ROBINUL-FORTE"
            },
            "CIVICA": {
              "GLYCOPYRROLATE": "GLYCOPYRROLATE"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "GLYCOPYRROLATE": "GLYCOPYRROLATE"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "GLYCOPYRROLATE": "GLYCOPYRROLATE"
            },
            "EXELA PHARMA SCIENCES": {
              "GLYRX-PF": "GLYRX-PF"
            },
            "FAGRON COMPOUNDING SERVICES": {
              "GLYCOPYRROLATE": "GLYCOPYRROLATE"
            },
            "FOUGERA": {
              "METHSCOPOLAMINE BROMIDE": "METHSCOPOLAMINE BROMIDE"
            },
            "FOXLAND PHARMACEUTICALS": {
              "GLYCOPYRROLATE": "GLYCOPYRROLATE"
            },
            "FRESENIUS KABI USA": {
              "GLYCOPYRROLATE": "GLYCOPYRROLATE",
              "GLYCOPYRROLATE PF": "GLYCOPYRROLATE PF"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "GLYCOPYRROLATE": "GLYCOPYRROLATE"
            },
            "HIKMA": {
              "GLYCOPYRROLATE": "GLYCOPYRROLATE",
              "PROPANTHELINE BROMIDE": "PROPANTHELINE BROMIDE",
              "ROBINUL": "ROBINUL"
            },
            "LEADING PHARMA": {
              "GLYCOPYRROLATE": "GLYCOPYRROLATE"
            },
            "LEITERS HEALTH": {
              "GLYCOPYRROLATE": "GLYCOPYRROLATE"
            },
            "MAJOR PHARMACEUTICALS": {
              "GLYCOPYRROLATE": "GLYCOPYRROLATE"
            },
            "MEITHEAL PHARMACEUTICALS": {
              "GLYCOPYRROLATE": "GLYCOPYRROLATE"
            },
            "MERZ PHARMACEUTICAL": {
              "CUVPOSA": "CUVPOSA"
            },
            "MYLAN INSTITUTIONAL": {
              "GLYCOPYRROLATE": "GLYCOPYRROLATE"
            },
            "NEPHRON STERILE COMPOUNDING": {
              "GLYCOPYRROLATE": "GLYCOPYRROLATE"
            },
            "NORTHSTAR RX": {
              "GLYCOPYRROLATE": "GLYCOPYRROLATE"
            },
            "NOVAPLUS/HIKMA": {
              "GLYCOPYRROLATE": "GLYCOPYRROLATE"
            },
            "PAR PHARMACEUTICAL": {
              "GLYCOPYRROLATE": "GLYCOPYRROLATE",
              "METHSCOPOLAMINE BROMIDE": "METHSCOPOLAMINE BROMIDE"
            },
            "PCCA": {
              "ISOPROPAMIDE IODIDE": "ISOPROPAMIDE IODIDE",
              "PROPANTHELINE BROMIDE": "PROPANTHELINE BROMIDE"
            },
            "PDRX PHARMACEUTICAL": {
              "METHSCOPOLAMINE BROMIDE": "METHSCOPOLAMINE BROMIDE"
            },
            "PHARMADERM": {
              "PAMINE": "PAMINE",
              "PAMINE FORTE": "PAMINE FORTE"
            },
            "PHARMEDIUM SERVICES": {
              "GLYCOPYRROLATE": "GLYCOPYRROLATE"
            },
            "PIRAMAL CRITICAL CARE": {
              "GLYCOPYRROLATE": "GLYCOPYRROLATE"
            },
            "QUINN PHARMACEUTICALS": {
              "GLYCOPYRROLATE": "GLYCOPYRROLATE"
            },
            "QUVA PHARMA": {
              "GLYCOPYRROLATE": "GLYCOPYRROLATE"
            },
            "RISING PHARMACEUTICALS": {
              "GLYCOPYRROLATE": "GLYCOPYRROLATE"
            },
            "SANDOZ": {
              "GLYCOPYRROLATE": "GLYCOPYRROLATE"
            },
            "SANOFI-AVENTIS U.S.": {
              "CANTIL": "CANTIL"
            },
            "SOLCO HEALTHCARE": {
              "GLYCOPYRROLATE": "GLYCOPYRROLATE"
            },
            "SOMERSET THERAPEUTICS": {
              "GLYCOPYRROLATE": "GLYCOPYRROLATE"
            },
            "SPECTRUM": {
              "PROPANTHELINE BROMIDE": "PROPANTHELINE BROMIDE"
            },
            "XIROMED": {
              "GLYCOPYRROLATE": "GLYCOPYRROLATE"
            }
          }
        },
        "H-2 Antagonists": {
          "H-2 Antagonists": {
            "A-S MEDICATION SOLUTIONS": {
              "CIMETIDINE": "CIMETIDINE",
              "FAMOTIDINE": "FAMOTIDINE",
              "NIZATIDINE": "NIZATIDINE",
              "PEPCID": "PEPCID",
              "RANITIDINE HCL": "RANITIDINE HCL"
            },
            "ACELLA PHARMACEUTICALS": {
              "RANITIDINE HCL": "RANITIDINE HCL"
            },
            "ACTAVIS PHARMA": {
              "NIZATIDINE": "NIZATIDINE"
            },
            "AIDAREX PHARMACEUTICALS": {
              "CIMETIDINE": "CIMETIDINE",
              "FAMOTIDINE": "FAMOTIDINE",
              "RANITIDINE HCL": "RANITIDINE HCL"
            },
            "AJANTA PHARMA LIMITED": {
              "RANITIDINE HCL": "RANITIDINE HCL"
            },
            "ALEMBIC PHARMACEUTICALS": {
              "FAMOTIDINE": "FAMOTIDINE"
            },
            "ALTURA PHARMACEUTICALS": {
              "CIMETIDINE": "CIMETIDINE",
              "NIZATIDINE": "NIZATIDINE",
              "RANITIDINE HCL": "RANITIDINE HCL"
            },
            "AMERICAN HEALTH PACKAGING": {
              "RANITIDINE HCL": "RANITIDINE HCL"
            },
            "AMERICAN SALES COMPANY": {
              "ACID REDUCER": "ACID REDUCER",
              "ACID REDUCER MAXIMUM STRENGTH": "ACID REDUCER MAXIMUM STRENGTH",
              "HEARTBURN RELIEF": "HEARTBURN RELIEF"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP ACID CONTROL 150 MAX ST": "GNP ACID CONTROL 150 MAX ST",
              "GNP ACID REDUCER": "GNP ACID REDUCER",
              "GNP ACID REDUCER MAX ST": "GNP ACID REDUCER MAX ST",
              "GNP HEARTBURN RELIEF 200": "GNP HEARTBURN RELIEF 200"
            },
            "AMNEAL PHARMACEUTICALS": {
              "NIZATIDINE": "NIZATIDINE",
              "RANITIDINE HCL": "RANITIDINE HCL"
            },
            "AMNEAL SPECIALTY": {
              "NIZATIDINE": "NIZATIDINE"
            },
            "ANI  PHARMACEUTICALS": {
              "RANITIDINE HCL": "RANITIDINE HCL"
            },
            "APHENA PHARMA SOLUTIONS": {
              "FAMOTIDINE": "FAMOTIDINE",
              "RANITIDINE HCL": "RANITIDINE HCL"
            },
            "APOTHECA": {
              "RANITIDINE HCL": "RANITIDINE HCL"
            },
            "APOTHECA SUPPLY": {
              "CIMETIDINE": "CIMETIDINE"
            },
            "AQ PHARMACEUTICALS": {
              "ZANTAC": "ZANTAC"
            },
            "ATLANTIC BIOLOGICALS": {
              "RANITIDINE HCL": "RANITIDINE HCL"
            },
            "AUROBINDO PHARMA": {
              "FAMOTIDINE": "FAMOTIDINE",
              "RANITIDINE HCL": "RANITIDINE HCL"
            },
            "AUROHEALTH": {
              "ACID REDUCER": "ACID REDUCER",
              "FAMOTIDINE": "FAMOTIDINE"
            },
            "AURORA HEALTHCARE": {
              "ACID REDUCER": "ACID REDUCER",
              "CIMETIDINE ACID REDUCER": "CIMETIDINE ACID REDUCER",
              "RANITIDINE ACID REDUCER": "RANITIDINE ACID REDUCER"
            },
            "AVET PHARMACEUTICALS": {
              "RANITIDINE HCL": "RANITIDINE HCL"
            },
            "AVKARE": {
              "CIMETIDINE": "CIMETIDINE",
              "FAMOTIDINE": "FAMOTIDINE",
              "RANITIDINE HCL": "RANITIDINE HCL"
            },
            "AVPAK": {
              "FAMOTIDINE": "FAMOTIDINE",
              "RANITIDINE HCL": "RANITIDINE HCL"
            },
            "BAUSCH HEALTH": {
              "PEPCID": "PEPCID"
            },
            "BAXTER HEALTHCARE CORP": {
              "FAMOTIDINE PREMIXED": "FAMOTIDINE PREMIXED"
            },
            "BERGEN BRUNSWIG": {
              "GNP ACID CONTROL 150 MAX ST": "GNP ACID CONTROL 150 MAX ST",
              "GNP ACID CONTROL 75": "GNP ACID CONTROL 75",
              "GNP ACID REDUCER": "GNP ACID REDUCER",
              "GNP ACID REDUCER MAX ST": "GNP ACID REDUCER MAX ST",
              "GNP HEARTBURN RELIEF": "GNP HEARTBURN RELIEF"
            },
            "BLENHEIM PHARMACAL": {
              "CIMETIDINE": "CIMETIDINE",
              "RANITIDINE HCL": "RANITIDINE HCL"
            },
            "BLUE POINT LABORATORIES": {
              "FAMOTIDINE": "FAMOTIDINE"
            },
            "BRYANT RANCH PREPACK": {
              "CIMETIDINE": "CIMETIDINE",
              "FAMOTIDINE": "FAMOTIDINE",
              "NIZATIDINE": "NIZATIDINE",
              "RANITIDINE HCL": "RANITIDINE HCL"
            },
            "CALIFORNIA PHARMACEUTICALS": {
              "DEPRIZINE RAPIDPAQ": "DEPRIZINE RAPIDPAQ"
            },
            "CARLSBAD TECHNOLOGIES": {
              "FAMOTIDINE": "FAMOTIDINE"
            },
            "CELLTRION USA": {
              "FAMOTIDINE": "FAMOTIDINE"
            },
            "CHAIN DRUG CONSORTIUM": {
              "ACID REDUCER": "ACID REDUCER",
              "ACID REDUCER MAXIMUM STRENGTH": "ACID REDUCER MAXIMUM STRENGTH",
              "ACID RELIEF": "ACID RELIEF"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC ACID CONTROLLER": "QC ACID CONTROLLER",
              "QC ACID CONTROLLER MAX ST": "QC ACID CONTROLLER MAX ST"
            },
            "CHATTEM INC": {
              "ZANTAC 150 MAXIMUM STRENGTH": "ZANTAC 150 MAXIMUM STRENGTH",
              "ZANTAC 75": "ZANTAC 75"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "FAMOTIDINE": "FAMOTIDINE",
              "RANITIDINE HCL": "RANITIDINE HCL"
            },
            "COSTCO WHOLESALE": {
              "KLS ACID CONTROLLER MAX ST": "KLS ACID CONTROLLER MAX ST",
              "KLS ACID REDUCER MAX ST": "KLS ACID REDUCER MAX ST"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS ACID CONTROLLER": "CVS ACID CONTROLLER",
              "CVS ACID CONTROLLER MAX ST": "CVS ACID CONTROLLER MAX ST",
              "CVS ACID REDUCER": "CVS ACID REDUCER",
              "CVS ACID REDUCER MAX ST": "CVS ACID REDUCER MAX ST",
              "CVS HEARTBURN RELIEF": "CVS HEARTBURN RELIEF",
              "CVS RANITIDINE": "CVS RANITIDINE"
            },
            "DARMERICA": {
              "CIMETIDINE": "CIMETIDINE"
            },
            "DIRECT RX": {
              "FAMOTIDINE": "FAMOTIDINE",
              "RANITIDINE HCL": "RANITIDINE HCL"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "FAMOTIDINE": "FAMOTIDINE",
              "NIZATIDINE": "NIZATIDINE",
              "RANITIDINE HCL": "RANITIDINE HCL"
            },
            "EQUALINE": {
              "EQL ACID REDUCER": "EQL ACID REDUCER",
              "EQL HEARTBURN PREVENTION": "EQL HEARTBURN PREVENTION",
              "EQL HEARTBURN RELIEF": "EQL HEARTBURN RELIEF",
              "EQL HEARTBURN RELIEF MAX ST": "EQL HEARTBURN RELIEF MAX ST"
            },
            "FAGRON": {
              "CIMETIDINE": "CIMETIDINE"
            },
            "FREEDOM PHARMACEUTICALS": {
              "CIMETIDINE": "CIMETIDINE"
            },
            "FRESENIUS KABI USA": {
              "FAMOTIDINE": "FAMOTIDINE"
            },
            "FUSION PHARMACEUTICALS": {
              "DEPRIZINE FUSEPAQ": "DEPRIZINE FUSEPAQ"
            },
            "GEISS DESTIN & DUNN": {
              "GOODSENSE ACID REDUCER": "GOODSENSE ACID REDUCER"
            },
            "GERI-CARE": {
              "ACID REDUCER": "ACID REDUCER",
              "FAMOTIDINE": "FAMOTIDINE"
            },
            "GLAXO SMITH KLINE": {
              "ZANTAC": "ZANTAC"
            },
            "GLENMARK PHARMACEUTICALS": {
              "NIZATIDINE": "NIZATIDINE",
              "RANITIDINE HCL": "RANITIDINE HCL"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "FAMOTIDINE": "FAMOTIDINE",
              "RANITIDINE HCL": "RANITIDINE HCL"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "CIMETIDINE": "CIMETIDINE",
              "FAMOTIDINE": "FAMOTIDINE",
              "NIZATIDINE": "NIZATIDINE",
              "PEPCID": "PEPCID",
              "RANITIDINE HCL": "RANITIDINE HCL",
              "ZANTAC": "ZANTAC"
            },
            "HANNAFORD BROTHERS": {
              "RANITIDINE HCL": "RANITIDINE HCL"
            },
            "HEALTH RESOURCES": {
              "RANITIDINE HCL": "RANITIDINE HCL"
            },
            "HI-TECH": {
              "CIMETIDINE HCL": "CIMETIDINE HCL",
              "RANITIDINE HCL": "RANITIDINE HCL"
            },
            "HIKMA": {
              "FAMOTIDINE": "FAMOTIDINE"
            },
            "HUMCO": {
              "CIMETIDINE": "CIMETIDINE"
            },
            "LEADER BRAND PRODUCTS": {
              "ACID CONTROL": "ACID CONTROL",
              "ACID CONTROL MAXIMUM STRENGTH": "ACID CONTROL MAXIMUM STRENGTH",
              "ACID REDUCER": "ACID REDUCER",
              "ACID REDUCER MAXIMUM STRENGTH": "ACID REDUCER MAXIMUM STRENGTH"
            },
            "LEGACY PHARMACEUTICAL PKG": {
              "FAMOTIDINE": "FAMOTIDINE"
            },
            "LETCO MEDICAL": {
              "CIMETIDINE": "CIMETIDINE"
            },
            "LIBERTY PHARMACEUTICAL": {
              "CIMETIDINE": "CIMETIDINE",
              "FAMOTIDINE": "FAMOTIDINE",
              "NIZATIDINE": "NIZATIDINE",
              "RANITIDINE HCL": "RANITIDINE HCL"
            },
            "LUPIN PHARMACEUTICALS": {
              "FAMOTIDINE": "FAMOTIDINE"
            },
            "MAGNO-HUMPHRIES LABORATORIES": {
              "ACID REDUCER": "ACID REDUCER",
              "ACID REDUCER MAXIMUM STRENGTH": "ACID REDUCER MAXIMUM STRENGTH"
            },
            "MAJOR PHARMACEUTICALS": {
              "HEARTBURN RELIEF": "HEARTBURN RELIEF",
              "HEARTBURN RELIEF MAX ST": "HEARTBURN RELIEF MAX ST",
              "RANITIDINE 150 MAX STRENGTH": "RANITIDINE 150 MAX STRENGTH",
              "RANITIDINE HCL": "RANITIDINE HCL"
            },
            "MCKESSON": {
              "HM ACID REDUCER": "HM ACID REDUCER",
              "HM ACID REDUCER MAX STRENGTH": "HM ACID REDUCER MAX STRENGTH",
              "HM FAMOTIDINE": "HM FAMOTIDINE"
            },
            "MCKESSON SUNMARK": {
              "SM ACID REDUCER": "SM ACID REDUCER",
              "SM ACID REDUCER MAX ST": "SM ACID REDUCER MAX ST"
            },
            "MCNEIL CONSUMER PHARMACEUTICAL": {
              "PEPCID AC": "PEPCID AC",
              "PEPCID AC MAXIMUM STRENGTH": "PEPCID AC MAXIMUM STRENGTH"
            },
            "MEDICINE SHOPPE": {
              "ACID CONTROL MAXIMUM STRENGTH": "ACID CONTROL MAXIMUM STRENGTH",
              "ACID REDUCER": "ACID REDUCER",
              "ACID REDUCER MAXIMUM STRENGTH": "ACID REDUCER MAXIMUM STRENGTH",
              "CIMETIDINE": "CIMETIDINE"
            },
            "MEDISCA": {
              "CIMETIDINE": "CIMETIDINE"
            },
            "MEDTECH": {
              "TAGAMET HB": "TAGAMET HB"
            },
            "MEDVANTX": {
              "RANITIDINE HCL": "RANITIDINE HCL"
            },
            "MOREPENMAX": {
              "RANITIDINE HCL": "RANITIDINE HCL"
            },
            "MYLAN": {
              "CIMETIDINE": "CIMETIDINE",
              "FAMOTIDINE": "FAMOTIDINE",
              "NIZATIDINE": "NIZATIDINE"
            },
            "MYLAN INSTITUTIONAL": {
              "FAMOTIDINE": "FAMOTIDINE",
              "RANITIDINE HCL": "RANITIDINE HCL"
            },
            "NOSTRUM LABORATORIES": {
              "RANITIDINE HCL": "RANITIDINE HCL"
            },
            "NOVITIUM PHARMA": {
              "RANITIDINE HCL": "RANITIDINE HCL"
            },
            "NUCARE PHARMACEUTICALS": {
              "CIMETIDINE": "CIMETIDINE",
              "FAMOTIDINE": "FAMOTIDINE",
              "RANITIDINE HCL": "RANITIDINE HCL"
            },
            "OHM LABS": {
              "ACID CONTROLLER MAX ST": "ACID CONTROLLER MAX ST",
              "ACID CONTROLLER ORIGINAL STR": "ACID CONTROLLER ORIGINAL STR",
              "ACID REDUCER": "ACID REDUCER"
            },
            "PCCA": {
              "CIMETIDINE": "CIMETIDINE"
            },
            "PDRX PHARMACEUTICAL": {
              "CIMETIDINE": "CIMETIDINE",
              "FAMOTIDINE": "FAMOTIDINE",
              "PEPCID": "PEPCID",
              "RANITIDINE HCL": "RANITIDINE HCL",
              "ZANTAC": "ZANTAC"
            },
            "PDRX PHARMACEUTICALS": {
              "FAMOTIDINE": "FAMOTIDINE"
            },
            "PENN LABS, INC.": {
              "CIMETIDINE": "CIMETIDINE"
            },
            "PERRIGO": {
              "FAMOTIDINE": "FAMOTIDINE",
              "GOODSENSE ACID REDUCER": "GOODSENSE ACID REDUCER",
              "KLS ACID CONTROLLER MAX ST": "KLS ACID CONTROLLER MAX ST",
              "KLS ACID REDUCER": "KLS ACID REDUCER",
              "KLS ACID REDUCER MAX ST": "KLS ACID REDUCER MAX ST"
            },
            "PERRIGO PHARMACEUTICALS": {
              "ACID REDUCER": "ACID REDUCER",
              "ACID REDUCER MAXIMUM STRENGTH": "ACID REDUCER MAXIMUM STRENGTH"
            },
            "PHARMACEUTICAL ASSOCIATES": {
              "RANITIDINE HCL": "RANITIDINE HCL"
            },
            "PHARMEDIUM SERVICES": {
              "FAMOTIDINE IN NACL": "FAMOTIDINE IN NACL"
            },
            "PHARMEDIX": {
              "FAMOTIDINE": "FAMOTIDINE",
              "RANITIDINE HCL": "RANITIDINE HCL"
            },
            "PRECISION DOSE, INC": {
              "RANITIDINE HCL": "RANITIDINE HCL"
            },
            "PREFERRED PHARMACEUTICALS": {
              "CIMETIDINE": "CIMETIDINE",
              "FAMOTIDINE": "FAMOTIDINE",
              "NIZATIDINE": "NIZATIDINE",
              "RANITIDINE HCL": "RANITIDINE HCL"
            },
            "PROFICIENT RX": {
              "FAMOTIDINE": "FAMOTIDINE",
              "RANITIDINE HCL": "RANITIDINE HCL"
            },
            "PUBLIX SUPER MARKETS INC.": {
              "PX ACID REDUCER": "PX ACID REDUCER",
              "PX ACID REDUCER MAX ST": "PX ACID REDUCER MAX ST",
              "PX RANITIDINE": "PX RANITIDINE"
            },
            "QUALITY CARE": {
              "CIMETIDINE": "CIMETIDINE",
              "FAMOTIDINE": "FAMOTIDINE",
              "PEPCID": "PEPCID",
              "RANITIDINE HCL": "RANITIDINE HCL",
              "ZANTAC": "ZANTAC"
            },
            "RITE AID CORPORATION": {
              "RA ACID REDUCER": "RA ACID REDUCER",
              "RA ACID REDUCER MAX ST": "RA ACID REDUCER MAX ST"
            },
            "RUGBY LABORATORIES": {
              "FAMOTIDINE MAXIMUM STRENGTH": "FAMOTIDINE MAXIMUM STRENGTH"
            },
            "RXCHANGE CO": {
              "CIMETIDINE": "CIMETIDINE",
              "FAMOTIDINE": "FAMOTIDINE",
              "RANITIDINE HCL": "RANITIDINE HCL"
            },
            "SAFEWAY": {
              "SW ACID REDUCER 150 MAX ST": "SW ACID REDUCER 150 MAX ST"
            },
            "SAM'S WEST": {
              "MM FAMOTIDINE": "MM FAMOTIDINE"
            },
            "SANDOZ": {
              "CIMETIDINE": "CIMETIDINE",
              "NIZATIDINE": "NIZATIDINE",
              "RANITIDINE HCL": "RANITIDINE HCL"
            },
            "SELECT BRAND": {
              "SB ACID CONTROLLER": "SB ACID CONTROLLER",
              "SB ACID CONTROLLER MAX ST": "SB ACID CONTROLLER MAX ST",
              "SB ACID REDUCER": "SB ACID REDUCER",
              "SB ACID REDUCER RANITIDINE": "SB ACID REDUCER RANITIDINE",
              "SB CIMETIDINE": "SB CIMETIDINE"
            },
            "SILARX": {
              "RANITIDINE HCL": "RANITIDINE HCL"
            },
            "SKY PACKAGING": {
              "FAMOTIDINE": "FAMOTIDINE",
              "RANITIDINE HCL": "RANITIDINE HCL"
            },
            "SPECTRUM": {
              "CIMETIDINE": "CIMETIDINE"
            },
            "ST MARY'S MPP": {
              "CIMETIDINE": "CIMETIDINE",
              "FAMOTIDINE": "FAMOTIDINE",
              "RANITIDINE HCL": "RANITIDINE HCL"
            },
            "STRIDES PHARMA": {
              "RANITIDINE HCL": "RANITIDINE HCL"
            },
            "SUN PHARMACEUTICALS": {
              "RANITIDINE HCL": "RANITIDINE HCL"
            },
            "TARGET": {
              "TGT ACID REDUCER": "TGT ACID REDUCER"
            },
            "TELIGENT": {
              "ZANTAC": "ZANTAC"
            },
            "TEVA PHARMACEUTICALS USA": {
              "CIMETIDINE": "CIMETIDINE",
              "CIMETIDINE HCL": "CIMETIDINE HCL",
              "FAMOTIDINE": "FAMOTIDINE",
              "RANITIDINE HCL": "RANITIDINE HCL"
            },
            "TOPCO": {
              "ACID REDUCER": "ACID REDUCER",
              "ACID REDUCER MAXIMUM STRENGTH": "ACID REDUCER MAXIMUM STRENGTH",
              "HEARTBURN RELIEF": "HEARTBURN RELIEF",
              "HEARTBURN RELIEF 150 MAX ST": "HEARTBURN RELIEF 150 MAX ST",
              "HEARTBURN RELIEF MAX ST": "HEARTBURN RELIEF MAX ST"
            },
            "UNIT DOSE SERVICES": {
              "FAMOTIDINE": "FAMOTIDINE",
              "RANITIDINE HCL": "RANITIDINE HCL"
            },
            "VA CMOP DALLAS": {
              "RANITIDINE HCL": "RANITIDINE HCL"
            },
            "WAL-MART": {
              "EQ ACID REDUCER": "EQ ACID REDUCER",
              "EQ ACID REDUCER MAX ST": "EQ ACID REDUCER MAX ST",
              "EQ CIMETIDINE": "EQ CIMETIDINE",
              "EQ FAMOTIDINE MAX ST": "EQ FAMOTIDINE MAX ST",
              "EQ HEARTBURN RELIEF": "EQ HEARTBURN RELIEF",
              "EQ RANITIDINE": "EQ RANITIDINE"
            },
            "WALGREENS": {
              "ACID CONTROLLER": "ACID CONTROLLER",
              "ACID CONTROLLER MAX ST": "ACID CONTROLLER MAX ST",
              "CIMETIDINE 200": "CIMETIDINE 200",
              "WAL-ZAN 150 MAXIMUM STRENGTH": "WAL-ZAN 150 MAXIMUM STRENGTH",
              "WAL-ZAN 75": "WAL-ZAN 75"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "FAMOTIDINE": "FAMOTIDINE",
              "RANITIDINE HCL": "RANITIDINE HCL"
            }
          }
        },
        "Misc. Anti-Ulcer": {
          "Misc. Anti-Ulcer": {
            "A-S MEDICATION SOLUTIONS": {
              "SUCRALFATE": "SUCRALFATE"
            },
            "AIDAREX PHARMACEUTICALS": {
              "SUCRALFATE": "SUCRALFATE"
            },
            "ALLERGAN": {
              "CARAFATE": "CARAFATE"
            },
            "AMNEAL PHARMACEUTICALS": {
              "SUCRALFATE": "SUCRALFATE"
            },
            "APHENA PHARMA SOLUTIONS": {
              "SUCRALFATE": "SUCRALFATE"
            },
            "ATLANTIC BIOLOGICALS": {
              "CARAFATE": "CARAFATE"
            },
            "AVKARE": {
              "SUCRALFATE": "SUCRALFATE"
            },
            "AVPAK": {
              "SUCRALFATE": "SUCRALFATE"
            },
            "BRYANT RANCH PREPACK": {
              "SUCRALFATE": "SUCRALFATE"
            },
            "CARDINAL HEALTH REPACKAGING SO": {
              "SUCRALFATE": "SUCRALFATE"
            },
            "FAGRON": {
              "SUCRALFATE": "SUCRALFATE"
            },
            "FREEDOM PHARMACEUTICALS": {
              "SUCRALFATE": "SUCRALFATE"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "SUCRALFATE": "SUCRALFATE"
            },
            "GREENSTONE": {
              "SUCRALFATE": "SUCRALFATE"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "CARAFATE": "CARAFATE"
            },
            "HUMCO": {
              "SUCRALFATE": "SUCRALFATE"
            },
            "KALCHEM INTERNATIONAL": {
              "SUCRALFATE": "SUCRALFATE"
            },
            "LETCO MEDICAL": {
              "SUCRALFATE": "SUCRALFATE"
            },
            "LIBERTY PHARMACEUTICAL": {
              "SUCRALFATE": "SUCRALFATE"
            },
            "MEDISCA": {
              "SUCRALFATE": "SUCRALFATE"
            },
            "MYLAN INSTITUTIONAL": {
              "SUCRALFATE": "SUCRALFATE"
            },
            "NOSTRUM LABORATORIES": {
              "SUCRALFATE": "SUCRALFATE"
            },
            "PAR PHARMACEUTICAL": {
              "SUCRALFATE": "SUCRALFATE"
            },
            "PDRX PHARMACEUTICAL": {
              "CARAFATE": "CARAFATE",
              "SUCRALFATE": "SUCRALFATE"
            },
            "PHARMACEUTICAL ASSOCIATES": {
              "SUCRALFATE": "SUCRALFATE"
            },
            "PRECISION DOSE, INC": {
              "SUCRALFATE": "SUCRALFATE"
            },
            "PREFERRED PHARMACEUTICALS": {
              "SUCRALFATE": "SUCRALFATE"
            },
            "QUALITY CARE": {
              "CARAFATE": "CARAFATE",
              "SUCRALFATE": "SUCRALFATE"
            },
            "SKY PACKAGING": {
              "SUCRALFATE": "SUCRALFATE"
            },
            "SPECTRUM": {
              "SUCRALFATE": "SUCRALFATE"
            },
            "TEVA PHARMACEUTICALS USA": {
              "SUCRALFATE": "SUCRALFATE"
            },
            "VISTAPHARM INC.": {
              "SUCRALFATE": "SUCRALFATE"
            }
          }
        },
        "Proton Pump Inhibitors": {
          "Proton Pump Inhibitors": {
            "A-S MEDICATION SOLUTIONS": {
              "ACIPHEX": "ACIPHEX",
              "ESOMEPRAZOLE MAGNESIUM": "ESOMEPRAZOLE MAGNESIUM",
              "LANSOPRAZOLE": "LANSOPRAZOLE",
              "NEXIUM": "NEXIUM",
              "NEXIUM 24HR": "NEXIUM 24HR",
              "OMEPRAZOLE": "OMEPRAZOLE",
              "PANTOPRAZOLE SODIUM": "PANTOPRAZOLE SODIUM",
              "PREVACID": "PREVACID",
              "PRILOSEC OTC": "PRILOSEC OTC",
              "PROTONIX": "PROTONIX",
              "RABEPRAZOLE SODIUM": "RABEPRAZOLE SODIUM"
            },
            "ACCORD HEALTHCARE": {
              "ESOMEPRAZOLE SODIUM": "ESOMEPRAZOLE SODIUM"
            },
            "ACTAVIS": {
              "LANSOPRAZOLE": "LANSOPRAZOLE",
              "PANTOPRAZOLE SODIUM": "PANTOPRAZOLE SODIUM"
            },
            "ACTAVIS PHARMA": {
              "LANSOPRAZOLE": "LANSOPRAZOLE",
              "OMEPRAZOLE": "OMEPRAZOLE"
            },
            "AIDAREX PHARMACEUTICALS": {
              "LANSOPRAZOLE": "LANSOPRAZOLE",
              "NEXIUM": "NEXIUM",
              "OMEPRAZOLE": "OMEPRAZOLE",
              "PANTOPRAZOLE SODIUM": "PANTOPRAZOLE SODIUM",
              "RABEPRAZOLE SODIUM": "RABEPRAZOLE SODIUM"
            },
            "AJANTA PHARMA LIMITED": {
              "LANSOPRAZOLE": "LANSOPRAZOLE"
            },
            "AKORN": {
              "ESOMEPRAZOLE SODIUM": "ESOMEPRAZOLE SODIUM"
            },
            "ALTURA PHARMACEUTICALS": {
              "OMEPRAZOLE": "OMEPRAZOLE"
            },
            "AMERICAN HEALTH PACKAGING": {
              "ESOMEPRAZOLE MAGNESIUM": "ESOMEPRAZOLE MAGNESIUM",
              "LANSOPRAZOLE": "LANSOPRAZOLE",
              "OMEPRAZOLE": "OMEPRAZOLE",
              "PANTOPRAZOLE SODIUM": "PANTOPRAZOLE SODIUM"
            },
            "AMERICAN SALES COMPANY": {
              "OMEPRAZOLE": "OMEPRAZOLE"
            },
            "AMERINET/PFIZER": {
              "PROTONIX": "PROTONIX"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP ESOMEPRAZOLE MAGNESIUM": "GNP ESOMEPRAZOLE MAGNESIUM",
              "GNP LANSOPRAZOLE": "GNP LANSOPRAZOLE",
              "GNP OMEPRAZOLE": "GNP OMEPRAZOLE"
            },
            "AMNEAL PHARMACEUTICALS": {
              "PANTOPRAZOLE SODIUM": "PANTOPRAZOLE SODIUM",
              "RABEPRAZOLE SODIUM": "RABEPRAZOLE SODIUM"
            },
            "APHENA PHARMA SOLUTIONS": {
              "ACIPHEX": "ACIPHEX",
              "OMEPRAZOLE": "OMEPRAZOLE",
              "PANTOPRAZOLE SODIUM": "PANTOPRAZOLE SODIUM",
              "PREVACID": "PREVACID",
              "PROTONIX": "PROTONIX"
            },
            "APOTEX": {
              "OMEPRAZOLE": "OMEPRAZOLE"
            },
            "APOTHECA": {
              "OMEPRAZOLE": "OMEPRAZOLE"
            },
            "AQ PHARMACEUTICALS": {
              "NEXIUM": "NEXIUM",
              "PREVACID": "PREVACID",
              "PROTONIX": "PROTONIX"
            },
            "ASCEND LABORATORIES": {
              "ESOMEPRAZOLE MAGNESIUM": "ESOMEPRAZOLE MAGNESIUM",
              "LANSOPRAZOLE": "LANSOPRAZOLE",
              "RABEPRAZOLE SODIUM": "RABEPRAZOLE SODIUM"
            },
            "ASTRAZENECA LP": {
              "NEXIUM": "NEXIUM",
              "NEXIUM I.V.": "NEXIUM I.V.",
              "PRILOSEC": "PRILOSEC"
            },
            "AUROBINDO PHARMA": {
              "ESOMEPRAZOLE MAGNESIUM": "ESOMEPRAZOLE MAGNESIUM",
              "OMEPRAZOLE": "OMEPRAZOLE",
              "PANTOPRAZOLE SODIUM": "PANTOPRAZOLE SODIUM",
              "RABEPRAZOLE SODIUM": "RABEPRAZOLE SODIUM"
            },
            "AUROHEALTH": {
              "ACID REDUCER": "ACID REDUCER"
            },
            "AUROMEDICS PHARMA": {
              "ESOMEPRAZOLE SODIUM": "ESOMEPRAZOLE SODIUM",
              "PANTOPRAZOLE SODIUM": "PANTOPRAZOLE SODIUM"
            },
            "AVADEL PHARMACEUTICALS USA": {
              "ACIPHEX SPRINKLE": "ACIPHEX SPRINKLE"
            },
            "AVKARE": {
              "LANSOPRAZOLE": "LANSOPRAZOLE",
              "OMEPRAZOLE": "OMEPRAZOLE",
              "PANTOPRAZOLE SODIUM": "PANTOPRAZOLE SODIUM",
              "RABEPRAZOLE SODIUM": "RABEPRAZOLE SODIUM"
            },
            "AVPAK": {
              "LANSOPRAZOLE": "LANSOPRAZOLE",
              "OMEPRAZOLE": "OMEPRAZOLE",
              "PANTOPRAZOLE SODIUM": "PANTOPRAZOLE SODIUM"
            },
            "AZURITY PHARMACEUTICALS": {
              "FIRST-LANSOPRAZOLE": "FIRST-LANSOPRAZOLE",
              "FIRST-OMEPRAZOLE": "FIRST-OMEPRAZOLE"
            },
            "BLENHEIM PHARMACAL": {
              "OMEPRAZOLE": "OMEPRAZOLE"
            },
            "BLUE POINT LABORATORIES": {
              "LANSOPRAZOLE": "LANSOPRAZOLE",
              "OMEPRAZOLE": "OMEPRAZOLE"
            },
            "BRECKENRIDGE": {
              "LANSOPRAZOLE": "LANSOPRAZOLE",
              "OMEPRAZOLE": "OMEPRAZOLE",
              "RABEPRAZOLE SODIUM": "RABEPRAZOLE SODIUM"
            },
            "BRYANT RANCH PREPACK": {
              "ESOMEPRAZOLE MAGNESIUM": "ESOMEPRAZOLE MAGNESIUM",
              "LANSOPRAZOLE": "LANSOPRAZOLE",
              "NEXIUM": "NEXIUM",
              "OMEPRAZOLE": "OMEPRAZOLE",
              "PANTOPRAZOLE SODIUM": "PANTOPRAZOLE SODIUM",
              "PREVACID": "PREVACID",
              "RABEPRAZOLE SODIUM": "RABEPRAZOLE SODIUM"
            },
            "BUREL PHARMACEUTICALS": {
              "PANTOPRAZOLE SODIUM": "PANTOPRAZOLE SODIUM"
            },
            "CADISTA": {
              "PANTOPRAZOLE SODIUM": "PANTOPRAZOLE SODIUM"
            },
            "CALIFORNIA PHARMACEUTICALS": {
              "PANTOPRAZOLE SODIUM": "PANTOPRAZOLE SODIUM"
            },
            "CAMBER PHARMACEUTICALS": {
              "ESOMEPRAZOLE MAGNESIUM": "ESOMEPRAZOLE MAGNESIUM",
              "PANTOPRAZOLE SODIUM": "PANTOPRAZOLE SODIUM"
            },
            "CARDINAL HEALTH REPACKAGING SO": {
              "OMEPRAZOLE": "OMEPRAZOLE",
              "PANTOPRAZOLE SODIUM": "PANTOPRAZOLE SODIUM"
            },
            "CHAIN DRUG CONSORTIUM": {
              "LANSOPRAZOLE": "LANSOPRAZOLE",
              "OMEPRAZOLE": "OMEPRAZOLE",
              "OMEPRAZOLE MAGNESIUM": "OMEPRAZOLE MAGNESIUM"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC ESOMEPRAZOLE MAGNESIUM": "QC ESOMEPRAZOLE MAGNESIUM",
              "QC LANSOPRAZOLE": "QC LANSOPRAZOLE",
              "QC OMEPRAZOLE MAGNESIUM": "QC OMEPRAZOLE MAGNESIUM"
            },
            "CIPLA USA": {
              "ESOMEPRAZOLE MAGNESIUM": "ESOMEPRAZOLE MAGNESIUM"
            },
            "CIVICA": {
              "PANTOPRAZOLE SODIUM": "PANTOPRAZOLE SODIUM"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "NEXIUM": "NEXIUM",
              "OMEPRAZOLE": "OMEPRAZOLE",
              "PANTOPRAZOLE SODIUM": "PANTOPRAZOLE SODIUM"
            },
            "COSTCO WHOLESALE": {
              "KLS ESOMEPRAZOLE MAGNESIUM": "KLS ESOMEPRAZOLE MAGNESIUM",
              "KLS LANSOPRAZOLE": "KLS LANSOPRAZOLE",
              "KLS OMEPRAZOLE": "KLS OMEPRAZOLE"
            },
            "COVIS PHARMA": {
              "PRILOSEC": "PRILOSEC"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS ESOMEPRAZOLE MAGNESIUM": "CVS ESOMEPRAZOLE MAGNESIUM",
              "CVS LANSOPRAZOLE": "CVS LANSOPRAZOLE",
              "CVS OMEPRAZOLE": "CVS OMEPRAZOLE"
            },
            "DENTON PHARMA": {
              "OMEPRAZOLE": "OMEPRAZOLE",
              "PANTOPRAZOLE SODIUM": "PANTOPRAZOLE SODIUM"
            },
            "DIRECT RX": {
              "ESOMEPRAZOLE MAGNESIUM": "ESOMEPRAZOLE MAGNESIUM",
              "LANSOPRAZOLE": "LANSOPRAZOLE",
              "NEXIUM": "NEXIUM",
              "OMEPRAZOLE": "OMEPRAZOLE",
              "PANTOPRAZOLE SODIUM": "PANTOPRAZOLE SODIUM",
              "RABEPRAZOLE SODIUM": "RABEPRAZOLE SODIUM"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "ESOMEPRAZOLE MAGNESIUM": "ESOMEPRAZOLE MAGNESIUM",
              "LANSOPRAZOLE": "LANSOPRAZOLE",
              "OMEPRAZOLE": "OMEPRAZOLE",
              "OMEPRAZOLE MAGNESIUM": "OMEPRAZOLE MAGNESIUM",
              "PANTOPRAZOLE SODIUM": "PANTOPRAZOLE SODIUM"
            },
            "EISAI": {
              "ACIPHEX": "ACIPHEX"
            },
            "ENOVACHEM MANUFACTURING": {
              "RABEPRAZOLE SODIUM": "RABEPRAZOLE SODIUM"
            },
            "EQUALINE": {
              "EQL LANSOPRAZOLE": "EQL LANSOPRAZOLE",
              "EQL OMEPRAZOLE": "EQL OMEPRAZOLE"
            },
            "FAGRON": {
              "OMEPRAZOLE+SYRSPEND SF ALKA": "OMEPRAZOLE+SYRSPEND SF ALKA"
            },
            "GEISS DESTIN & DUNN": {
              "GOODSENSE ESOMEPRAZOLE": "GOODSENSE ESOMEPRAZOLE",
              "GOODSENSE LANSOPRAZOLE": "GOODSENSE LANSOPRAZOLE"
            },
            "GERI-CARE": {
              "OMEPRAZOLE": "OMEPRAZOLE",
              "OMEPRAZOLE MAGNESIUM": "OMEPRAZOLE MAGNESIUM"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "NEXIUM 24HR": "NEXIUM 24HR",
              "NEXIUM 24HR CLEAR MINIS": "NEXIUM 24HR CLEAR MINIS",
              "PREVACID 24HR": "PREVACID 24HR"
            },
            "GLENMARK PHARMACEUTICALS": {
              "OMEPRAZOLE": "OMEPRAZOLE"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "LANSOPRAZOLE": "LANSOPRAZOLE",
              "OMEPRAZOLE": "OMEPRAZOLE",
              "PANTOPRAZOLE SODIUM": "PANTOPRAZOLE SODIUM",
              "RABEPRAZOLE SODIUM": "RABEPRAZOLE SODIUM"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "NEXIUM": "NEXIUM",
              "OMEPRAZOLE": "OMEPRAZOLE",
              "PREVACID": "PREVACID",
              "PRILOSEC": "PRILOSEC",
              "PROTONIX": "PROTONIX"
            },
            "HIKMA": {
              "PANTOPRAZOLE SODIUM": "PANTOPRAZOLE SODIUM"
            },
            "INGENUS PHARMACEUTICALS": {
              "PANTOPRAZOLE SODIUM": "PANTOPRAZOLE SODIUM"
            },
            "KAISER FOUNDATION HOSPITAL": {
              "KP OMEPRAZOLE MAGNESIUM": "KP OMEPRAZOLE MAGNESIUM"
            },
            "KREMERS URBAN": {
              "ESOMEPRAZOLE MAGNESIUM": "ESOMEPRAZOLE MAGNESIUM",
              "LANSOPRAZOLE": "LANSOPRAZOLE",
              "OMEPRAZOLE": "OMEPRAZOLE",
              "PANTOPRAZOLE SODIUM": "PANTOPRAZOLE SODIUM",
              "RABEPRAZOLE SODIUM": "RABEPRAZOLE SODIUM"
            },
            "KROGER COMPANY": {
              "OMEPRAZOLE": "OMEPRAZOLE"
            },
            "LEADER BRAND PRODUCTS": {
              "ACID REDUCER": "ACID REDUCER",
              "ESOMEPRAZOLE MAGNESIUM": "ESOMEPRAZOLE MAGNESIUM",
              "HEARTBURN TREATMENT 24 HOUR": "HEARTBURN TREATMENT 24 HOUR",
              "LANSOPRAZOLE": "LANSOPRAZOLE",
              "OMEPRAZOLE": "OMEPRAZOLE",
              "OMEPRAZOLE MAGNESIUM": "OMEPRAZOLE MAGNESIUM"
            },
            "LEGACY PHARMACEUTICAL PKG": {
              "OMEPRAZOLE": "OMEPRAZOLE",
              "PANTOPRAZOLE SODIUM": "PANTOPRAZOLE SODIUM"
            },
            "LIBERTY PHARMACEUTICAL": {
              "NEXIUM": "NEXIUM",
              "OMEPRAZOLE": "OMEPRAZOLE",
              "PANTOPRAZOLE SODIUM": "PANTOPRAZOLE SODIUM"
            },
            "LIFESTAR PHARMA": {
              "LANSOPRAZOLE": "LANSOPRAZOLE"
            },
            "LUPIN PHARMACEUTICALS": {
              "RABEPRAZOLE SODIUM": "RABEPRAZOLE SODIUM"
            },
            "MACLEODS PHARMACEUTICALS": {
              "PANTOPRAZOLE SODIUM": "PANTOPRAZOLE SODIUM"
            },
            "MAJOR PHARMACEUTICALS": {
              "ESOMEPRAZOLE MAGNESIUM": "ESOMEPRAZOLE MAGNESIUM",
              "LANSOPRAZOLE": "LANSOPRAZOLE",
              "OMEPRAZOLE": "OMEPRAZOLE",
              "PANTOPRAZOLE SODIUM": "PANTOPRAZOLE SODIUM",
              "RABEPRAZOLE SODIUM": "RABEPRAZOLE SODIUM"
            },
            "MAS MANAGEMENT GROUP": {
              "OMEPRAZOLE": "OMEPRAZOLE"
            },
            "MCKESSON": {
              "HM ESOMEPRAZOLE MAGNESIUM DR": "HM ESOMEPRAZOLE MAGNESIUM DR",
              "HM LANSOPRAZOLE": "HM LANSOPRAZOLE",
              "HM OMEPRAZOLE": "HM OMEPRAZOLE"
            },
            "MCKESSON SUNMARK": {
              "SM ESOMEPRAZOLE MAGNESIUM": "SM ESOMEPRAZOLE MAGNESIUM",
              "SM LANSOPRAZOLE": "SM LANSOPRAZOLE",
              "SM OMEPRAZOLE": "SM OMEPRAZOLE"
            },
            "MEDICINE SHOPPE": {
              "OMEPRAZOLE": "OMEPRAZOLE"
            },
            "MEDVANTX": {
              "PROTONIX": "PROTONIX"
            },
            "MYLAN": {
              "ESOMEPRAZOLE MAGNESIUM": "ESOMEPRAZOLE MAGNESIUM",
              "LANSOPRAZOLE": "LANSOPRAZOLE",
              "OMEPRAZOLE": "OMEPRAZOLE",
              "PANTOPRAZOLE SODIUM": "PANTOPRAZOLE SODIUM",
              "RABEPRAZOLE SODIUM": "RABEPRAZOLE SODIUM"
            },
            "MYLAN INSTITUTIONAL": {
              "ESOMEPRAZOLE MAGNESIUM": "ESOMEPRAZOLE MAGNESIUM",
              "ESOMEPRAZOLE SODIUM": "ESOMEPRAZOLE SODIUM",
              "LANSOPRAZOLE": "LANSOPRAZOLE",
              "OMEPRAZOLE": "OMEPRAZOLE",
              "PANTOPRAZOLE SODIUM": "PANTOPRAZOLE SODIUM"
            },
            "NEW HORIZON RX GROUP": {
              "OMEPRAZOLE": "OMEPRAZOLE",
              "PANTOPRAZOLE SODIUM": "PANTOPRAZOLE SODIUM"
            },
            "NORTHSTAR RX": {
              "ESOMEPRAZOLE MAGNESIUM": "ESOMEPRAZOLE MAGNESIUM",
              "OMEPRAZOLE": "OMEPRAZOLE"
            },
            "NOVAPLUS/HIKMA": {
              "PANTOPRAZOLE SODIUM": "PANTOPRAZOLE SODIUM"
            },
            "NOVAPLUS/PFIZER U.S.": {
              "PROTONIX": "PROTONIX"
            },
            "NUCARE PHARMACEUTICALS": {
              "NEXIUM": "NEXIUM",
              "OMEPRAZOLE": "OMEPRAZOLE",
              "PANTOPRAZOLE SODIUM": "PANTOPRAZOLE SODIUM",
              "PREVACID": "PREVACID",
              "PRILOSEC OTC": "PRILOSEC OTC",
              "PROTONIX": "PROTONIX"
            },
            "OHM LABS": {
              "OMEPRAZOLE": "OMEPRAZOLE",
              "OMEPRAZOLE MAGNESIUM": "OMEPRAZOLE MAGNESIUM"
            },
            "P & G HEALTH": {
              "PRILOSEC OTC": "PRILOSEC OTC"
            },
            "PARAPRO": {
              "ESOMEPRAZOLE STRONTIUM": "ESOMEPRAZOLE STRONTIUM"
            },
            "PDRX PHARMACEUTICAL": {
              "NEXIUM": "NEXIUM",
              "OMEPRAZOLE": "OMEPRAZOLE",
              "PANTOPRAZOLE SODIUM": "PANTOPRAZOLE SODIUM",
              "PREVACID": "PREVACID",
              "PRILOSEC": "PRILOSEC",
              "PROTONIX": "PROTONIX"
            },
            "PDRX PHARMACEUTICALS": {
              "PANTOPRAZOLE SODIUM": "PANTOPRAZOLE SODIUM"
            },
            "PERRIGO": {
              "OMEPRAZOLE": "OMEPRAZOLE"
            },
            "PERRIGO PHARMACEUTICALS": {
              "ESOMEPRAZOLE MAGNESIUM": "ESOMEPRAZOLE MAGNESIUM",
              "LANSOPRAZOLE": "LANSOPRAZOLE",
              "OMEPRAZOLE": "OMEPRAZOLE"
            },
            "PFIZER U.S.": {
              "PROTONIX": "PROTONIX"
            },
            "PRASCO LABORATORIES": {
              "PANTOPRAZOLE SODIUM": "PANTOPRAZOLE SODIUM"
            },
            "PREFERRED PHARMACEUTICALS": {
              "ESOMEPRAZOLE MAGNESIUM": "ESOMEPRAZOLE MAGNESIUM",
              "LANSOPRAZOLE": "LANSOPRAZOLE",
              "OMEPRAZOLE": "OMEPRAZOLE",
              "PANTOPRAZOLE SODIUM": "PANTOPRAZOLE SODIUM",
              "RABEPRAZOLE SODIUM": "RABEPRAZOLE SODIUM"
            },
            "PROFICIENT RX": {
              "ESOMEPRAZOLE MAGNESIUM": "ESOMEPRAZOLE MAGNESIUM",
              "LANSOPRAZOLE": "LANSOPRAZOLE",
              "OMEPRAZOLE": "OMEPRAZOLE",
              "PANTOPRAZOLE SODIUM": "PANTOPRAZOLE SODIUM",
              "RABEPRAZOLE SODIUM": "RABEPRAZOLE SODIUM"
            },
            "PUBLIX SUPER MARKETS INC.": {
              "PX OMEPRAZOLE": "PX OMEPRAZOLE"
            },
            "PURE TEK": {
              "ESOMEP-EZS": "ESOMEP-EZS"
            },
            "QUALITY CARE": {
              "ACIPHEX": "ACIPHEX",
              "DEXILANT": "DEXILANT",
              "LANSOPRAZOLE": "LANSOPRAZOLE",
              "NEXIUM": "NEXIUM",
              "OMEPRAZOLE": "OMEPRAZOLE",
              "PANTOPRAZOLE SODIUM": "PANTOPRAZOLE SODIUM",
              "PREVACID": "PREVACID",
              "PRILOSEC OTC": "PRILOSEC OTC",
              "PROTONIX": "PROTONIX"
            },
            "QUALITY CARE PRODUCTS": {
              "ESOMEPRAZOLE MAGNESIUM": "ESOMEPRAZOLE MAGNESIUM",
              "LANSOPRAZOLE": "LANSOPRAZOLE",
              "NEXIUM": "NEXIUM",
              "OMEPRAZOLE": "OMEPRAZOLE",
              "PANTOPRAZOLE SODIUM": "PANTOPRAZOLE SODIUM",
              "RABEPRAZOLE SODIUM": "RABEPRAZOLE SODIUM"
            },
            "R2 PHARMA": {
              "ESOMEPRAZOLE STRONTIUM": "ESOMEPRAZOLE STRONTIUM"
            },
            "RELIABLE 1 LABS": {
              "ESOMEPRAZOLE MAGNESIUM": "ESOMEPRAZOLE MAGNESIUM",
              "LANSOPRAZOLE": "LANSOPRAZOLE",
              "OMEPRAZOLE MAGNESIUM": "OMEPRAZOLE MAGNESIUM"
            },
            "RISING PHARMACEUTICALS": {
              "OMEPRAZOLE": "OMEPRAZOLE"
            },
            "RITE AID CORPORATION": {
              "RA ESOMEPRAZOLE MAGNESIUM": "RA ESOMEPRAZOLE MAGNESIUM",
              "RA LANSOPRAZOLE": "RA LANSOPRAZOLE",
              "RA OMEPRAZOLE": "RA OMEPRAZOLE"
            },
            "RUGBY LABORATORIES": {
              "ESOMEPRAZOLE MAGNESIUM": "ESOMEPRAZOLE MAGNESIUM",
              "HEARTBURN TREATMENT 24 HOUR": "HEARTBURN TREATMENT 24 HOUR",
              "LANSOPRAZOLE": "LANSOPRAZOLE"
            },
            "RXCHANGE CO": {
              "ACIPHEX": "ACIPHEX",
              "NEXIUM": "NEXIUM",
              "OMEPRAZOLE": "OMEPRAZOLE",
              "PANTOPRAZOLE SODIUM": "PANTOPRAZOLE SODIUM",
              "PREVACID": "PREVACID"
            },
            "SAFEWAY": {
              "SW OMEPRAZOLE": "SW OMEPRAZOLE"
            },
            "SANDOZ": {
              "LANSOPRAZOLE": "LANSOPRAZOLE",
              "OMEPRAZOLE": "OMEPRAZOLE",
              "PANTOPRAZOLE SODIUM": "PANTOPRAZOLE SODIUM"
            },
            "SARRAS HEALTH": {
              "RABEPRAZOLE SODIUM": "RABEPRAZOLE SODIUM"
            },
            "SELECT BRAND": {
              "SB OMEPRAZOLE": "SB OMEPRAZOLE"
            },
            "SKY PACKAGING": {
              "LANSOPRAZOLE": "LANSOPRAZOLE",
              "OMEPRAZOLE": "OMEPRAZOLE",
              "PANTOPRAZOLE SODIUM": "PANTOPRAZOLE SODIUM"
            },
            "ST MARY'S MPP": {
              "ESOMEPRAZOLE MAGNESIUM": "ESOMEPRAZOLE MAGNESIUM",
              "LANSOPRAZOLE": "LANSOPRAZOLE",
              "OMEPRAZOLE": "OMEPRAZOLE",
              "PANTOPRAZOLE SODIUM": "PANTOPRAZOLE SODIUM",
              "RABEPRAZOLE SODIUM": "RABEPRAZOLE SODIUM"
            },
            "STERLING KNIGHT PHARMACEUTICAL": {
              "ESOMEPRAZOLE STRONTIUM": "ESOMEPRAZOLE STRONTIUM",
              "LANSOPRAZOLE": "LANSOPRAZOLE"
            },
            "SUN PHARMACEUTICAL": {
              "LANSOPRAZOLE": "LANSOPRAZOLE"
            },
            "SUN PHARMACEUTICALS": {
              "ESOMEPRAZOLE MAGNESIUM": "ESOMEPRAZOLE MAGNESIUM",
              "ESOMEPRAZOLE SODIUM": "ESOMEPRAZOLE SODIUM",
              "LANSOPRAZOLE": "LANSOPRAZOLE",
              "PANTOPRAZOLE SODIUM": "PANTOPRAZOLE SODIUM"
            },
            "TAKEDA PHARMACEUTICALS": {
              "DEXILANT": "DEXILANT",
              "PREVACID": "PREVACID",
              "PREVACID SOLUTAB": "PREVACID SOLUTAB"
            },
            "TARGET": {
              "TGT OMEPRAZOLE": "TGT OMEPRAZOLE"
            },
            "TEVA PHARMACEUTICALS USA": {
              "ESOMEPRAZOLE MAGNESIUM": "ESOMEPRAZOLE MAGNESIUM",
              "LANSOPRAZOLE": "LANSOPRAZOLE",
              "OMEPRAZOLE": "OMEPRAZOLE",
              "PANTOPRAZOLE SODIUM": "PANTOPRAZOLE SODIUM",
              "RABEPRAZOLE SODIUM": "RABEPRAZOLE SODIUM"
            },
            "TOPCO": {
              "ESOMEPRAZOLE MAGNESIUM": "ESOMEPRAZOLE MAGNESIUM",
              "LANSOPRAZOLE": "LANSOPRAZOLE",
              "OMEPRAZOLE": "OMEPRAZOLE"
            },
            "TORRENT PHARMACEUTICALS": {
              "ESOMEPRAZOLE MAGNESIUM": "ESOMEPRAZOLE MAGNESIUM",
              "PANTOPRAZOLE SODIUM": "PANTOPRAZOLE SODIUM",
              "RABEPRAZOLE SODIUM": "RABEPRAZOLE SODIUM"
            },
            "UNIT DOSE SERVICES": {
              "ESOMEPRAZOLE MAGNESIUM": "ESOMEPRAZOLE MAGNESIUM",
              "LANSOPRAZOLE": "LANSOPRAZOLE",
              "NEXIUM": "NEXIUM",
              "OMEPRAZOLE": "OMEPRAZOLE",
              "PANTOPRAZOLE SODIUM": "PANTOPRAZOLE SODIUM",
              "RABEPRAZOLE SODIUM": "RABEPRAZOLE SODIUM"
            },
            "WAL-MART": {
              "EQ LANSOPRAZOLE": "EQ LANSOPRAZOLE",
              "EQ OMEPRAZOLE": "EQ OMEPRAZOLE",
              "EQ OMEPRAZOLE MAGNESIUM": "EQ OMEPRAZOLE MAGNESIUM"
            },
            "WALGREENS": {
              "ESOMEPRAZOLE MAGNESIUM": "ESOMEPRAZOLE MAGNESIUM",
              "LANSOPRAZOLE": "LANSOPRAZOLE",
              "OMEPRAZOLE": "OMEPRAZOLE",
              "OMEPRAZOLE MAGNESIUM": "OMEPRAZOLE MAGNESIUM"
            },
            "XIROMED": {
              "LANSOPRAZOLE": "LANSOPRAZOLE"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "ESOMEPRAZOLE MAGNESIUM": "ESOMEPRAZOLE MAGNESIUM",
              "LANSOPRAZOLE": "LANSOPRAZOLE",
              "OMEPRAZOLE": "OMEPRAZOLE"
            },
            "ZYLERA PHARMACEUTICALS": {
              "ACIPHEX SPRINKLE": "ACIPHEX SPRINKLE"
            }
          }
        },
        "Ulcer Drugs - Prostaglandins": {
          "Ulcer Drugs - Prostaglandins": {
            "A-S MEDICATION SOLUTIONS": {
              "CYTOTEC": "CYTOTEC"
            },
            "AIDAREX PHARMACEUTICALS": {
              "MISOPROSTOL": "MISOPROSTOL"
            },
            "AMERICAN HEALTH PACKAGING": {
              "MISOPROSTOL": "MISOPROSTOL"
            },
            "BRYANT RANCH PREPACK": {
              "MISOPROSTOL": "MISOPROSTOL"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "MISOPROSTOL": "MISOPROSTOL"
            },
            "GENBIOPRO": {
              "MISOPROSTOL": "MISOPROSTOL"
            },
            "GREENSTONE": {
              "MISOPROSTOL": "MISOPROSTOL"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "CYTOTEC": "CYTOTEC",
              "MISOPROSTOL": "MISOPROSTOL"
            },
            "LIBERTY PHARMACEUTICAL": {
              "MISOPROSTOL": "MISOPROSTOL"
            },
            "LUPIN PHARMACEUTICALS": {
              "MISOPROSTOL": "MISOPROSTOL"
            },
            "NUCARE PHARMACEUTICALS": {
              "CYTOTEC": "CYTOTEC",
              "MISOPROSTOL": "MISOPROSTOL"
            },
            "PDRX PHARMACEUTICAL": {
              "CYTOTEC": "CYTOTEC",
              "MISOPROSTOL": "MISOPROSTOL"
            },
            "PDRX PHARMACEUTICALS": {
              "MISOPROSTOL": "MISOPROSTOL"
            },
            "PFIZER U.S.": {
              "CYTOTEC": "CYTOTEC"
            },
            "PHARMACIST PHARMACEUTICAL LLC": {
              "MISOPROSTOL": "MISOPROSTOL"
            },
            "QUALITY CARE": {
              "MISOPROSTOL": "MISOPROSTOL"
            },
            "QUALITY CARE PRODUCTS": {
              "MISOPROSTOL": "MISOPROSTOL"
            },
            "READYMEDS": {
              "MISOPROSTOL": "MISOPROSTOL"
            },
            "RXCHANGE CO": {
              "MISOPROSTOL": "MISOPROSTOL"
            }
          }
        },
        "Ulcer Therapy Combinations": {
          "H-2 Antagonist-Antacid Combinations": {
            "AMERICAN SALES COMPANY": {
              "ACID REDUCER + ANTACID": "ACID REDUCER + ANTACID"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP DUAL ACTION COMPLETE": "GNP DUAL ACTION COMPLETE"
            },
            "BERGEN BRUNSWIG": {
              "GNP DUAL ACTION COMPLETE": "GNP DUAL ACTION COMPLETE"
            },
            "CHATTEM INC": {
              "DUO FUSION": "DUO FUSION"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS DUAL ACTION COMPLETE": "CVS DUAL ACTION COMPLETE"
            },
            "EQUALINE": {
              "EQL DUAL ACTION COMPLETE": "EQL DUAL ACTION COMPLETE"
            },
            "LEADER BRAND PRODUCTS": {
              "ACID REDUCER COMPLETE": "ACID REDUCER COMPLETE"
            },
            "MCKESSON": {
              "HM COMPLETE DUAL ACTION": "HM COMPLETE DUAL ACTION"
            },
            "MCKESSON SUNMARK": {
              "SM COMPLETE DUAL ACTION": "SM COMPLETE DUAL ACTION"
            },
            "MCNEIL CONSUMER PHARMACEUTICAL": {
              "PEPCID COMPLETE": "PEPCID COMPLETE"
            },
            "PERRIGO": {
              "KLS ACID CONTROLLER COMPLETE": "KLS ACID CONTROLLER COMPLETE"
            },
            "PERRIGO PHARMACEUTICALS": {
              "DUAL ACTION COMPLETE": "DUAL ACTION COMPLETE"
            },
            "PUBLIX SUPER MARKETS INC.": {
              "PX DUAL ACTION": "PX DUAL ACTION"
            },
            "RITE AID CORPORATION": {
              "RA ACID REDUCER PLUS ANTACID": "RA ACID REDUCER PLUS ANTACID",
              "RA DUAL ACTION COMPLETE": "RA DUAL ACTION COMPLETE"
            },
            "TARGET": {
              "TGT ACID CONTROLLER COMPLETE": "TGT ACID CONTROLLER COMPLETE",
              "TGT ACID REDUCER COMPLETE": "TGT ACID REDUCER COMPLETE"
            },
            "TOPCO": {
              "DUAL ACTION COMPLETE": "DUAL ACTION COMPLETE"
            },
            "WAL-MART": {
              "EQ ACID REDUCER COMPLETE": "EQ ACID REDUCER COMPLETE"
            },
            "WALGREENS": {
              "ACID CONTROLLER COMPLETE": "ACID CONTROLLER COMPLETE"
            }
          },
          "Proton Pump Inhibitor-Antacid Combinations": {
            "AJANTA PHARMA LIMITED": {
              "OMEPRAZOLE-SODIUM BICARBONATE": "OMEPRAZOLE-SODIUM BICARBONATE"
            },
            "AKRON PHARMA": {
              "OMEPRAZOLE-SODIUM BICARBONATE": "OMEPRAZOLE-SODIUM BICARBONATE"
            },
            "AUROBINDO PHARMA USA": {
              "OMEPRAZOLE-SODIUM BICARBONATE": "OMEPRAZOLE-SODIUM BICARBONATE"
            },
            "AVKARE": {
              "OMEPRAZOLE-SODIUM BICARBONATE": "OMEPRAZOLE-SODIUM BICARBONATE"
            },
            "BAUSCH HEALTH": {
              "ZEGERID": "ZEGERID"
            },
            "BAYER CONSUMER": {
              "ZEGERID OTC": "ZEGERID OTC"
            },
            "CHAIN DRUG CONSORTIUM": {
              "OMEPRAZOLE-SODIUM BICARBONATE": "OMEPRAZOLE-SODIUM BICARBONATE"
            },
            "CIPLA USA": {
              "OMEPRAZOLE-SODIUM BICARBONATE": "OMEPRAZOLE-SODIUM BICARBONATE"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS OMEPRAZOLE-SOD BICARBONATE": "CVS OMEPRAZOLE-SOD BICARBONATE"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "OMEPRAZOLE-SODIUM BICARBONATE": "OMEPRAZOLE-SODIUM BICARBONATE"
            },
            "MEDARBOR": {
              "OMEPPI": "OMEPPI",
              "OMEPRAZOLE-SODIUM BICARBONATE": "OMEPRAZOLE-SODIUM BICARBONATE"
            },
            "NORTHSTAR RX": {
              "OMEPRAZOLE-SODIUM BICARBONATE": "OMEPRAZOLE-SODIUM BICARBONATE"
            },
            "OCEANSIDE PHARMACEUTICALS": {
              "OMEPRAZOLE-SODIUM BICARBONATE": "OMEPRAZOLE-SODIUM BICARBONATE"
            },
            "PAR PHARMACEUTICAL": {
              "OMEPRAZOLE-SODIUM BICARBONATE": "OMEPRAZOLE-SODIUM BICARBONATE"
            },
            "PRASCO LABORATORIES": {
              "OMEPRAZOLE-SODIUM BICARBONATE": "OMEPRAZOLE-SODIUM BICARBONATE"
            },
            "RITE AID CORPORATION": {
              "RA OMEPRAZOLE-SODIUM BICARB": "RA OMEPRAZOLE-SODIUM BICARB"
            },
            "STERLING KNIGHT PHARMACEUTICAL": {
              "OMEPRAZOLE-SODIUM BICARBONATE": "OMEPRAZOLE-SODIUM BICARBONATE"
            },
            "WALGREENS": {
              "OMEPRAZOLE-SODIUM BICARBONATE": "OMEPRAZOLE-SODIUM BICARBONATE"
            },
            "WESTMINSTER PHARMACEUTICALS": {
              "OMEPRAZOLE-SODIUM BICARBONATE": "OMEPRAZOLE-SODIUM BICARBONATE"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "OMEPRAZOLE-SODIUM BICARBONATE": "OMEPRAZOLE-SODIUM BICARBONATE"
            }
          },
          "Ulcer Anti-Infective w/ Bismuth Combinations": {
            "ALLERGAN": {
              "PYLERA": "PYLERA"
            },
            "CASPER PHARMA": {
              "HELIDAC THERAPY": "HELIDAC THERAPY"
            }
          },
          "Ulcer Anti-Infective w/ Proton Pump Inhibitors": {
            "A-S MEDICATION SOLUTIONS": {
              "PREVPAC": "PREVPAC"
            },
            "CUMBERLAND PHARMACEUTICALS": {
              "OMECLAMOX-PAK": "OMECLAMOX-PAK"
            },
            "PRASCO LABORATORIES": {
              "AMOXICILL-CLARITHRO-LANSOPRAZ": "AMOXICILL-CLARITHRO-LANSOPRAZ"
            },
            "REDHILL BIOPHARMA": {
              "TALICIA": "TALICIA"
            },
            "RISING PHARMACEUTICALS": {
              "AMOXICILL-CLARITHRO-LANSOPRAZ": "AMOXICILL-CLARITHRO-LANSOPRAZ"
            },
            "SANDOZ": {
              "AMOXICILL-CLARITHRO-LANSOPRAZ": "AMOXICILL-CLARITHRO-LANSOPRAZ"
            },
            "TAKEDA PHARMACEUTICALS": {
              "PREVPAC": "PREVPAC"
            }
          }
        }
      },
      "URINARY ANTI-INFECTIVES": {
        "Urinary Anti-infective Combinations": {
          "Urinary Antiseptic-Antispasmodic &/or Analgesics": {
            "METHOD PHARMACEUTICALS": {
              "URO-BLUE": "URO-BLUE",
              "URO-L": "URO-L"
            }
          }
        },
        "Urinary Anti-infectives": {
          "Urinary Anti-infectives": {
            "CLINICAL SOLUTIONS WHOLESALE": {
              "NITROFURANTOIN MONOHYD MACRO": "NITROFURANTOIN MONOHYD MACRO"
            },
            "SANOFI-AVENTIS U.S.": {
              "HIPREX": "HIPREX"
            },
            "WINTHROP, US": {
              "METHENAMINE HIPPURATE": "METHENAMINE HIPPURATE"
            }
          }
        }
      },
      "URINARY ANTISPASMODICS": {
        "Urinary Antispasmodic - Antimuscarinics (Anticholinergic)": {
          "Urinary Antispasmodic - Antimuscarinic (Anticholinergic)": {
            "A-S MEDICATION SOLUTIONS": {
              "DETROL LA": "DETROL LA",
              "OXYBUTYNIN CHLORIDE": "OXYBUTYNIN CHLORIDE",
              "TOLTERODINE TARTRATE ER": "TOLTERODINE TARTRATE ER",
              "VESICARE": "VESICARE"
            },
            "ACCORD HEALTHCARE": {
              "OXYBUTYNIN CHLORIDE ER": "OXYBUTYNIN CHLORIDE ER"
            },
            "ACTAVIS PHARMA": {
              "DARIFENACIN HYDROBROMIDE ER": "DARIFENACIN HYDROBROMIDE ER",
              "GELNIQUE": "GELNIQUE",
              "SOLIFENACIN SUCCINATE": "SOLIFENACIN SUCCINATE",
              "TROSPIUM CHLORIDE ER": "TROSPIUM CHLORIDE ER"
            },
            "AIDAREX PHARMACEUTICALS": {
              "OXYBUTYNIN CHLORIDE": "OXYBUTYNIN CHLORIDE",
              "OXYBUTYNIN CHLORIDE ER": "OXYBUTYNIN CHLORIDE ER"
            },
            "AJANTA PHARMA LIMITED": {
              "OXYBUTYNIN CHLORIDE ER": "OXYBUTYNIN CHLORIDE ER",
              "SOLIFENACIN SUCCINATE": "SOLIFENACIN SUCCINATE",
              "TOLTERODINE TARTRATE ER": "TOLTERODINE TARTRATE ER"
            },
            "ALEMBIC PHARMACEUTICALS": {
              "SOLIFENACIN SUCCINATE": "SOLIFENACIN SUCCINATE"
            },
            "ALLERGAN": {
              "ENABLEX": "ENABLEX",
              "GELNIQUE": "GELNIQUE",
              "GELNIQUE PUMP": "GELNIQUE PUMP",
              "OXYTROL": "OXYTROL",
              "OXYTROL FOR WOMEN": "OXYTROL FOR WOMEN"
            },
            "AMERICAN HEALTH PACKAGING": {
              "OXYBUTYNIN CHLORIDE": "OXYBUTYNIN CHLORIDE",
              "OXYBUTYNIN CHLORIDE ER": "OXYBUTYNIN CHLORIDE ER",
              "SOLIFENACIN SUCCINATE": "SOLIFENACIN SUCCINATE",
              "TOLTERODINE TARTRATE ER": "TOLTERODINE TARTRATE ER"
            },
            "AMNEAL PHARMACEUTICALS": {
              "OXYBUTYNIN CHLORIDE ER": "OXYBUTYNIN CHLORIDE ER",
              "SOLIFENACIN SUCCINATE": "SOLIFENACIN SUCCINATE"
            },
            "ANI  PHARMACEUTICALS": {
              "TOLTERODINE TARTRATE ER": "TOLTERODINE TARTRATE ER"
            },
            "APHENA PHARMA SOLUTIONS": {
              "OXYBUTYNIN CHLORIDE": "OXYBUTYNIN CHLORIDE",
              "OXYBUTYNIN CHLORIDE ER": "OXYBUTYNIN CHLORIDE ER",
              "TOLTERODINE TARTRATE ER": "TOLTERODINE TARTRATE ER"
            },
            "APOTEX": {
              "SOLIFENACIN SUCCINATE": "SOLIFENACIN SUCCINATE",
              "TOLTERODINE TARTRATE": "TOLTERODINE TARTRATE",
              "TROSPIUM CHLORIDE": "TROSPIUM CHLORIDE"
            },
            "ASCEND LABORATORIES": {
              "SOLIFENACIN SUCCINATE": "SOLIFENACIN SUCCINATE"
            },
            "ASTELLAS": {
              "VESICARE": "VESICARE"
            },
            "AUROBINDO PHARMA": {
              "DARIFENACIN HYDROBROMIDE ER": "DARIFENACIN HYDROBROMIDE ER"
            },
            "AUSTARPHARMA": {
              "SOLIFENACIN SUCCINATE": "SOLIFENACIN SUCCINATE"
            },
            "AVET PHARMACEUTICALS": {
              "OXYBUTYNIN CHLORIDE": "OXYBUTYNIN CHLORIDE",
              "TROSPIUM CHLORIDE": "TROSPIUM CHLORIDE"
            },
            "AVKARE": {
              "DARIFENACIN HYDROBROMIDE ER": "DARIFENACIN HYDROBROMIDE ER",
              "OXYBUTYNIN CHLORIDE ER": "OXYBUTYNIN CHLORIDE ER",
              "SOLIFENACIN SUCCINATE": "SOLIFENACIN SUCCINATE",
              "TROSPIUM CHLORIDE": "TROSPIUM CHLORIDE"
            },
            "AVPAK": {
              "OXYBUTYNIN CHLORIDE ER": "OXYBUTYNIN CHLORIDE ER"
            },
            "BIONPHARMA": {
              "OXYBUTYNIN CHLORIDE ER": "OXYBUTYNIN CHLORIDE ER"
            },
            "BLENHEIM PHARMACAL": {
              "OXYBUTYNIN CHLORIDE": "OXYBUTYNIN CHLORIDE"
            },
            "BLUE POINT LABORATORIES": {
              "TROSPIUM CHLORIDE ER": "TROSPIUM CHLORIDE ER"
            },
            "BRECKENRIDGE": {
              "SOLIFENACIN SUCCINATE": "SOLIFENACIN SUCCINATE"
            },
            "BRYANT RANCH PREPACK": {
              "OXYBUTYNIN CHLORIDE": "OXYBUTYNIN CHLORIDE",
              "OXYBUTYNIN CHLORIDE ER": "OXYBUTYNIN CHLORIDE ER"
            },
            "CADISTA": {
              "DARIFENACIN HYDROBROMIDE ER": "DARIFENACIN HYDROBROMIDE ER"
            },
            "CAMBER PHARMACEUTICALS": {
              "TOLTERODINE TARTRATE ER": "TOLTERODINE TARTRATE ER"
            },
            "CARDINAL HEALTH REPACKAGING SO": {
              "OXYBUTYNIN CHLORIDE": "OXYBUTYNIN CHLORIDE"
            },
            "CELLTRION USA": {
              "SOLIFENACIN SUCCINATE": "SOLIFENACIN SUCCINATE"
            },
            "CIPLA USA": {
              "DARIFENACIN HYDROBROMIDE ER": "DARIFENACIN HYDROBROMIDE ER",
              "SOLIFENACIN SUCCINATE": "SOLIFENACIN SUCCINATE",
              "TROSPIUM CHLORIDE": "TROSPIUM CHLORIDE"
            },
            "DIRECT RX": {
              "OXYBUTYNIN CHLORIDE": "OXYBUTYNIN CHLORIDE",
              "OXYBUTYNIN CHLORIDE ER": "OXYBUTYNIN CHLORIDE ER"
            },
            "EXELAN PHARMACEUTICALS": {
              "TROSPIUM CHLORIDE": "TROSPIUM CHLORIDE"
            },
            "EYWA PHARMA": {
              "OXYBUTYNIN CHLORIDE": "OXYBUTYNIN CHLORIDE"
            },
            "GLENMARK PHARMACEUTICALS": {
              "SOLIFENACIN SUCCINATE": "SOLIFENACIN SUCCINATE",
              "TROSPIUM CHLORIDE": "TROSPIUM CHLORIDE"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "DARIFENACIN HYDROBROMIDE ER": "DARIFENACIN HYDROBROMIDE ER",
              "OXYBUTYNIN CHLORIDE": "OXYBUTYNIN CHLORIDE",
              "SOLIFENACIN SUCCINATE": "SOLIFENACIN SUCCINATE",
              "TOLTERODINE TARTRATE ER": "TOLTERODINE TARTRATE ER",
              "TROSPIUM CHLORIDE": "TROSPIUM CHLORIDE",
              "TROSPIUM CHLORIDE ER": "TROSPIUM CHLORIDE ER"
            },
            "GRANULES PHARMACEUTICALS": {
              "TROSPIUM CHLORIDE ER": "TROSPIUM CHLORIDE ER"
            },
            "GREENSTONE": {
              "TOLTERODINE TARTRATE": "TOLTERODINE TARTRATE",
              "TOLTERODINE TARTRATE ER": "TOLTERODINE TARTRATE ER"
            },
            "JANSSEN": {
              "DITROPAN XL": "DITROPAN XL"
            },
            "KREMERS URBAN": {
              "OXYBUTYNIN CHLORIDE ER": "OXYBUTYNIN CHLORIDE ER"
            },
            "KVK TECH": {
              "OXYBUTYNIN CHLORIDE": "OXYBUTYNIN CHLORIDE"
            },
            "LIBERTY PHARMACEUTICAL": {
              "OXYBUTYNIN CHLORIDE": "OXYBUTYNIN CHLORIDE",
              "OXYBUTYNIN CHLORIDE ER": "OXYBUTYNIN CHLORIDE ER"
            },
            "MACLEODS PHARMACEUTICALS": {
              "DARIFENACIN HYDROBROMIDE ER": "DARIFENACIN HYDROBROMIDE ER",
              "TOLTERODINE TARTRATE": "TOLTERODINE TARTRATE"
            },
            "MAJOR PHARMACEUTICALS": {
              "OXYBUTYNIN CHLORIDE": "OXYBUTYNIN CHLORIDE",
              "OXYBUTYNIN CHLORIDE ER": "OXYBUTYNIN CHLORIDE ER",
              "TOLTERODINE TARTRATE ER": "TOLTERODINE TARTRATE ER",
              "TROSPIUM CHLORIDE": "TROSPIUM CHLORIDE"
            },
            "MARLEX PHARMACEUTICALS": {
              "OXYBUTYNIN CHLORIDE": "OXYBUTYNIN CHLORIDE",
              "OXYBUTYNIN CHLORIDE ER": "OXYBUTYNIN CHLORIDE ER",
              "TOLTERODINE TARTRATE": "TOLTERODINE TARTRATE"
            },
            "MEDVANTX": {
              "DETROL": "DETROL",
              "DITROPAN XL": "DITROPAN XL",
              "OXYBUTYNIN CHLORIDE": "OXYBUTYNIN CHLORIDE"
            },
            "MORTON GROVE PHARMACEUTICALS": {
              "OXYBUTYNIN CHLORIDE": "OXYBUTYNIN CHLORIDE"
            },
            "MYLAN": {
              "OXYBUTYNIN CHLORIDE ER": "OXYBUTYNIN CHLORIDE ER",
              "TOLTERODINE TARTRATE": "TOLTERODINE TARTRATE",
              "TOLTERODINE TARTRATE ER": "TOLTERODINE TARTRATE ER"
            },
            "MYLAN INSTITUTIONAL": {
              "OXYBUTYNIN CHLORIDE ER": "OXYBUTYNIN CHLORIDE ER",
              "TOLTERODINE TARTRATE": "TOLTERODINE TARTRATE",
              "TOLTERODINE TARTRATE ER": "TOLTERODINE TARTRATE ER"
            },
            "NOVADOZ PHARMACEUTICALS": {
              "SOLIFENACIN SUCCINATE": "SOLIFENACIN SUCCINATE"
            },
            "NOVITIUM PHARMA": {
              "OXYBUTYNIN CHLORIDE": "OXYBUTYNIN CHLORIDE"
            },
            "NUCARE PHARMACEUTICALS": {
              "ENABLEX": "ENABLEX",
              "OXYBUTYNIN CHLORIDE": "OXYBUTYNIN CHLORIDE"
            },
            "PAR PHARMACEUTICAL": {
              "DARIFENACIN HYDROBROMIDE ER": "DARIFENACIN HYDROBROMIDE ER"
            },
            "PAR PHARMACEUTICALS": {
              "OXYBUTYNIN CHLORIDE": "OXYBUTYNIN CHLORIDE"
            },
            "PATRIOT PHARMACEUTICALS LLC": {
              "OXYBUTYNIN CHLORIDE ER": "OXYBUTYNIN CHLORIDE ER"
            },
            "PDRX PHARMACEUTICAL": {
              "DETROL LA": "DETROL LA",
              "OXYBUTYNIN CHLORIDE": "OXYBUTYNIN CHLORIDE",
              "OXYBUTYNIN CHLORIDE ER": "OXYBUTYNIN CHLORIDE ER"
            },
            "PDRX PHARMACEUTICALS": {
              "OXYBUTYNIN CHLORIDE ER": "OXYBUTYNIN CHLORIDE ER"
            },
            "PERRIGO": {
              "TROSPIUM CHLORIDE": "TROSPIUM CHLORIDE",
              "TROSPIUM CHLORIDE ER": "TROSPIUM CHLORIDE ER"
            },
            "PFIZER U.S.": {
              "DETROL": "DETROL",
              "DETROL LA": "DETROL LA",
              "TOVIAZ": "TOVIAZ"
            },
            "PREFERRED PHARMACEUTICALS": {
              "OXYBUTYNIN CHLORIDE": "OXYBUTYNIN CHLORIDE",
              "OXYBUTYNIN CHLORIDE ER": "OXYBUTYNIN CHLORIDE ER"
            },
            "PROFICIENT RX": {
              "OXYBUTYNIN CHLORIDE": "OXYBUTYNIN CHLORIDE",
              "TOLTERODINE TARTRATE": "TOLTERODINE TARTRATE"
            },
            "QUALITY CARE": {
              "DETROL LA": "DETROL LA",
              "OXYBUTYNIN CHLORIDE": "OXYBUTYNIN CHLORIDE",
              "OXYBUTYNIN CHLORIDE ER": "OXYBUTYNIN CHLORIDE ER"
            },
            "QUALITY CARE PRODUCTS": {
              "OXYBUTYNIN CHLORIDE ER": "OXYBUTYNIN CHLORIDE ER"
            },
            "RISING PHARMACEUTICALS": {
              "OXYBUTYNIN CHLORIDE": "OXYBUTYNIN CHLORIDE",
              "OXYBUTYNIN CHLORIDE ER": "OXYBUTYNIN CHLORIDE ER",
              "TOLTERODINE TARTRATE": "TOLTERODINE TARTRATE"
            },
            "SCHERING-PLOUGH HEALTHCARE": {
              "OXYTROL FOR WOMEN": "OXYTROL FOR WOMEN"
            },
            "SCIEGEN PHARMACEUTICALS": {
              "SOLIFENACIN SUCCINATE": "SOLIFENACIN SUCCINATE"
            },
            "SILARX": {
              "OXYBUTYNIN CHLORIDE": "OXYBUTYNIN CHLORIDE"
            },
            "SKY PACKAGING": {
              "OXYBUTYNIN CHLORIDE ER": "OXYBUTYNIN CHLORIDE ER"
            },
            "ST MARY'S MPP": {
              "OXYBUTYNIN CHLORIDE": "OXYBUTYNIN CHLORIDE"
            },
            "TEVA PHARMACEUTICALS USA": {
              "OXYBUTYNIN CHLORIDE": "OXYBUTYNIN CHLORIDE",
              "OXYBUTYNIN CHLORIDE ER": "OXYBUTYNIN CHLORIDE ER",
              "SOLIFENACIN SUCCINATE": "SOLIFENACIN SUCCINATE",
              "TOLTERODINE TARTRATE": "TOLTERODINE TARTRATE",
              "TOLTERODINE TARTRATE ER": "TOLTERODINE TARTRATE ER"
            },
            "TORRENT PHARMACEUTICALS": {
              "DARIFENACIN HYDROBROMIDE ER": "DARIFENACIN HYDROBROMIDE ER",
              "TOLTERODINE TARTRATE ER": "TOLTERODINE TARTRATE ER"
            },
            "TRUPHARMA": {
              "OXYBUTYNIN CHLORIDE": "OXYBUTYNIN CHLORIDE"
            },
            "UNICHEM PHARMACEUTICALS": {
              "SOLIFENACIN SUCCINATE": "SOLIFENACIN SUCCINATE",
              "TOLTERODINE TARTRATE": "TOLTERODINE TARTRATE"
            },
            "UPSHER-SMITH": {
              "OXYBUTYNIN CHLORIDE": "OXYBUTYNIN CHLORIDE"
            },
            "VANGARD": {
              "OXYBUTYNIN CHLORIDE": "OXYBUTYNIN CHLORIDE"
            },
            "WESTMINSTER PHARMACEUTICALS": {
              "SOLIFENACIN SUCCINATE": "SOLIFENACIN SUCCINATE"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "OXYBUTYNIN CHLORIDE ER": "OXYBUTYNIN CHLORIDE ER"
            }
          }
        },
        "Urinary Antispasmodics - Beta-3 Adrenergic Agonists": {
          "Urinary Antispasmodics - Beta-3 Adrenergic Agonists": {
            "ASTELLAS": {
              "MYRBETRIQ": "MYRBETRIQ"
            }
          }
        },
        "Urinary Antispasmodics - Cholinergic Agonists": {
          "Urinary Antispasmodics - Cholinergic Agonists": {
            "AMERICAN HEALTH PACKAGING": {
              "BETHANECHOL CHLORIDE": "BETHANECHOL CHLORIDE"
            },
            "AMNEAL PHARMACEUTICALS": {
              "BETHANECHOL CHLORIDE": "BETHANECHOL CHLORIDE"
            },
            "AVKARE": {
              "BETHANECHOL CHLORIDE": "BETHANECHOL CHLORIDE"
            },
            "AVPAK": {
              "BETHANECHOL CHLORIDE": "BETHANECHOL CHLORIDE"
            },
            "BRYANT RANCH PREPACK": {
              "BETHANECHOL CHLORIDE": "BETHANECHOL CHLORIDE"
            },
            "ECI PHARMACEUTICALS": {
              "BETHANECHOL CHLORIDE": "BETHANECHOL CHLORIDE"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "BETHANECHOL CHLORIDE": "BETHANECHOL CHLORIDE"
            },
            "LANNETT": {
              "BETHANECHOL CHLORIDE": "BETHANECHOL CHLORIDE"
            },
            "MAJOR PHARMACEUTICALS": {
              "BETHANECHOL CHLORIDE": "BETHANECHOL CHLORIDE"
            },
            "MARLEX PHARMACEUTICALS": {
              "BETHANECHOL CHLORIDE": "BETHANECHOL CHLORIDE"
            },
            "PDRX PHARMACEUTICAL": {
              "BETHANECHOL CHLORIDE": "BETHANECHOL CHLORIDE"
            },
            "QUALITY CARE PRODUCTS": {
              "BETHANECHOL CHLORIDE": "BETHANECHOL CHLORIDE"
            },
            "RISING PHARMACEUTICALS": {
              "BETHANECHOL CHLORIDE": "BETHANECHOL CHLORIDE"
            },
            "TEVA PHARMACEUTICALS USA": {
              "BETHANECHOL CHLORIDE": "BETHANECHOL CHLORIDE"
            },
            "TEVA/WOMENS HEALTH": {
              "URECHOLINE": "URECHOLINE"
            },
            "UPSHER-SMITH": {
              "BETHANECHOL CHLORIDE": "BETHANECHOL CHLORIDE"
            }
          }
        },
        "Urinary Antispasmodics - Direct Muscle Relaxants": {
          "Urinary Antispasmodics - Direct Muscle Relaxants": {
            "AVPAK": {
              "FLAVOXATE HCL": "FLAVOXATE HCL"
            },
            "BLU PHARMACEUTICALS": {
              "FLAVOXATE HCL": "FLAVOXATE HCL"
            },
            "EPIC PHARMA": {
              "FLAVOXATE HCL": "FLAVOXATE HCL"
            },
            "IMPAX GENERICS": {
              "FLAVOXATE HCL": "FLAVOXATE HCL"
            },
            "PERRIGO": {
              "FLAVOXATE HCL": "FLAVOXATE HCL"
            },
            "TAGI PHARMA": {
              "FLAVOXATE HCL": "FLAVOXATE HCL"
            }
          }
        }
      },
      "VACCINES": {
        "Bacterial Vaccines": {
          "Bacterial Vaccine Combination": {
            "GLAXO SMITH KLINE": {
              "MENHIBRIX": "MENHIBRIX"
            }
          },
          "Bacterial Vaccines": {
            "A-S MEDICATION SOLUTIONS": {
              "MENACTRA": "MENACTRA",
              "PEDVAX HIB": "PEDVAX HIB",
              "PNEUMOVAX 23": "PNEUMOVAX 23",
              "PREVNAR 13": "PREVNAR 13",
              "TYPHIM VI": "TYPHIM VI",
              "VIVOTIF": "VIVOTIF"
            },
            "EMERGENT BIODEFENSE OPERATIONS": {
              "BIOTHRAX": "BIOTHRAX"
            },
            "GLAXO SMITH KLINE": {
              "BEXSERO": "BEXSERO",
              "HIBERIX": "HIBERIX",
              "MENVEO": "MENVEO"
            },
            "MERCK SHARP & DOHME": {
              "PEDVAX HIB": "PEDVAX HIB",
              "PNEUMOVAX 23": "PNEUMOVAX 23"
            },
            "NUCARE PHARMACEUTICALS": {
              "VIVOTIF": "VIVOTIF"
            },
            "ORGANON": {
              "BCG VACCINE": "BCG VACCINE"
            },
            "PAXVAX": {
              "VAXCHORA": "VAXCHORA",
              "VIVOTIF": "VIVOTIF"
            },
            "PFIZER U.S.": {
              "PREVNAR 13": "PREVNAR 13",
              "TRUMENBA": "TRUMENBA"
            },
            "QUALITY CARE": {
              "VIVOTIF": "VIVOTIF"
            },
            "SANOFI PASTEUR": {
              "ACTHIB": "ACTHIB",
              "MENACTRA": "MENACTRA",
              "MENOMUNE": "MENOMUNE",
              "MENQUADFI": "MENQUADFI",
              "TYPHIM VI": "TYPHIM VI"
            }
          }
        },
        "Mixed Vaccine Combinations": {
          "Mixed Vaccine Combinations": {
            "MERCK SHARP & DOHME": {
              "COMVAX": "COMVAX"
            }
          }
        },
        "Viral Vaccines": {
          "Viral Vaccine Combinations": {
            "A-S MEDICATION SOLUTIONS": {
              "M-M-R II": "M-M-R II",
              "TWINRIX": "TWINRIX"
            },
            "GLAXO SMITH KLINE": {
              "TWINRIX": "TWINRIX"
            },
            "MERCK SHARP & DOHME": {
              "M-M-R II": "M-M-R II",
              "PROQUAD": "PROQUAD"
            },
            "QUALITY CARE": {
              "M-M-R II": "M-M-R II"
            }
          },
          "Viral Vaccines": {
            "A-S MEDICATION SOLUTIONS": {
              "ENGERIX-B": "ENGERIX-B",
              "GARDASIL": "GARDASIL",
              "GARDASIL 9": "GARDASIL 9",
              "HAVRIX": "HAVRIX",
              "HEPLISAV-B": "HEPLISAV-B",
              "IPOL": "IPOL",
              "RABAVERT": "RABAVERT",
              "RECOMBIVAX HB": "RECOMBIVAX HB",
              "VARIVAX": "VARIVAX"
            },
            "ASTRAZENECA": {
              "FLUMIST QUADRIVALENT": "FLUMIST QUADRIVALENT"
            },
            "BAVARIAN NORDIC": {
              "RABAVERT": "RABAVERT"
            },
            "DYNAVAX TECHNOLOGIES": {
              "HEPLISAV-B": "HEPLISAV-B"
            },
            "ENOVACHEM MANUFACTURING": {
              "EZ FLU SHOT-FLUCELVAX": "EZ FLU SHOT-FLUCELVAX",
              "EZ FLU SHOT-FLUCELVAX QUAD": "EZ FLU SHOT-FLUCELVAX QUAD",
              "EZ FLU SHOT-FLUVIRIN": "EZ FLU SHOT-FLUVIRIN",
              "MEDICAL PROVIDER EZ AFLURIA PF": "MEDICAL PROVIDER EZ AFLURIA PF",
              "MEDICAL PROVIDER EZ FLU PF": "MEDICAL PROVIDER EZ FLU PF",
              "MEDICAL PROVIDER EZ FLU SHOT": "MEDICAL PROVIDER EZ FLU SHOT",
              "MEDICAL PROVIDER EZ FLUVIRIN": "MEDICAL PROVIDER EZ FLUVIRIN"
            },
            "GLAXO SMITH KLINE": {
              "CERVARIX": "CERVARIX",
              "ENGERIX-B": "ENGERIX-B",
              "FLUARIX QUADRIVALENT": "FLUARIX QUADRIVALENT",
              "FLULAVAL QUADRIVALENT": "FLULAVAL QUADRIVALENT",
              "HAVRIX": "HAVRIX",
              "RABAVERT": "RABAVERT",
              "ROTARIX": "ROTARIX",
              "SHINGRIX": "SHINGRIX"
            },
            "MEDIMMUNE": {
              "FLUMIST QUADRIVALENT": "FLUMIST QUADRIVALENT"
            },
            "MERCK SHARP & DOHME": {
              "GARDASIL": "GARDASIL",
              "GARDASIL 9": "GARDASIL 9",
              "RECOMBIVAX HB": "RECOMBIVAX HB",
              "ROTATEQ": "ROTATEQ",
              "VAQTA": "VAQTA",
              "VARIVAX": "VARIVAX",
              "ZOSTAVAX": "ZOSTAVAX"
            },
            "MODERNA US INC": {
              "MODERNA COVID-19 VACCINE": "MODERNA COVID-19 VACCINE"
            },
            "NOVARTIS VACCINES & DIAGNOSTIC": {
              "FLUCELVAX": "FLUCELVAX",
              "FLUVIRIN": "FLUVIRIN"
            },
            "PROTEIN SCIENCES CORPORATION": {
              "FLUBLOK": "FLUBLOK"
            },
            "QUALITY CARE": {
              "HAVRIX": "HAVRIX"
            },
            "SANOFI PASTEUR": {
              "FLUBLOK": "FLUBLOK",
              "FLUBLOK QUADRIVALENT": "FLUBLOK QUADRIVALENT",
              "FLUZONE": "FLUZONE",
              "FLUZONE HIGH-DOSE": "FLUZONE HIGH-DOSE",
              "FLUZONE HIGH-DOSE QUADRIVALENT": "FLUZONE HIGH-DOSE QUADRIVALENT",
              "FLUZONE QUADRIVALENT": "FLUZONE QUADRIVALENT",
              "IMOVAX RABIES": "IMOVAX RABIES",
              "IPOL": "IPOL",
              "STAMARIL": "STAMARIL",
              "YF-VAX": "YF-VAX"
            },
            "SEQIRUS": {
              "AFLURIA": "AFLURIA",
              "AFLURIA PRESERVATIVE FREE": "AFLURIA PRESERVATIVE FREE",
              "AFLURIA QUADRIVALENT": "AFLURIA QUADRIVALENT",
              "FLUAD": "FLUAD",
              "FLUAD QUADRIVALENT": "FLUAD QUADRIVALENT",
              "FLUCELVAX QUADRIVALENT": "FLUCELVAX QUADRIVALENT",
              "FLUVIRIN": "FLUVIRIN"
            },
            "VALNEVA USA": {
              "IXIARO": "IXIARO"
            }
          }
        }
      },
      "VAGINAL AND RELATED PRODUCTS": {
        "Douche Products": {
          "Douche Products": {
            "CHAIN DRUG CONSORTIUM": {
              "FRESH SCENT": "FRESH SCENT",
              "OCEAN BREEZE DOUCHE": "OCEAN BREEZE DOUCHE",
              "ROMANTIC MIST": "ROMANTIC MIST",
              "TRIPLE CLEANSING DOUCHE": "TRIPLE CLEANSING DOUCHE"
            },
            "FLEET PHARMACEUTICALS": {
              "SUMMERS EVE": "SUMMERS EVE",
              "SUMMERS EVE FRESH": "SUMMERS EVE FRESH",
              "SUMMERS EVE ISLAND SPLASH": "SUMMERS EVE ISLAND SPLASH",
              "SUMMERS EVE ULTRA": "SUMMERS EVE ULTRA"
            },
            "WAL-MART": {
              "EQ FEMININE DOUCHE": "EQ FEMININE DOUCHE"
            }
          }
        },
        "Miscellaneous Vaginal Products": {
          "Fertility Enhancers": {
            "MILEX": {
              "PRO-CEPTION": "PRO-CEPTION"
            }
          },
          "Miscellaneous Vaginal Combinations": {
            "BLANSETT PHARMACAL": {
              "RELAGARD": "RELAGARD"
            },
            "CHAIN DRUG CONSORTIUM": {
              "VAGICREAM": "VAGICREAM"
            },
            "COMBE": {
              "VAGISIL BATH BOMB": "VAGISIL BATH BOMB"
            },
            "COOPER SURGICAL": {
              "TRIMO-SAN": "TRIMO-SAN"
            },
            "LAKE CONSUMER PRODUCTS": {
              "VH ESSENTIALS MEDICATED": "VH ESSENTIALS MEDICATED"
            },
            "MEDTECH": {
              "MASSENGILL": "MASSENGILL",
              "MASSENGILL SOFT CLOTH TOWELETT": "MASSENGILL SOFT CLOTH TOWELETT"
            },
            "WOMEN'S CHOICE PHARMACEUTICALS": {
              "FEM PH": "FEM PH"
            }
          },
          "Miscellaneous Vaginal Products": {
            "CHAIN DRUG CONSORTIUM": {
              "DOUCHE VINEGAR/WATER": "DOUCHE VINEGAR/WATER",
              "EXTRA CLEANSING DOUCHE": "EXTRA CLEANSING DOUCHE",
              "FEMININE WASH NORMAL": "FEMININE WASH NORMAL",
              "FEMININE WASH SENSITIVE": "FEMININE WASH SENSITIVE",
              "VAGICREAM": "VAGICREAM"
            },
            "COMBE": {
              "VAGISIL": "VAGISIL",
              "VAGISIL FEMININE MOISTURIZER": "VAGISIL FEMININE MOISTURIZER",
              "VAGISIL FEMININE WASH": "VAGISIL FEMININE WASH",
              "VAGISIL INTIMATE WASH": "VAGISIL INTIMATE WASH",
              "VAGISIL LUBRICANT": "VAGISIL LUBRICANT",
              "VAGISIL MAXIMUM STRENGTH": "VAGISIL MAXIMUM STRENGTH",
              "VAGISIL PH BALANCE": "VAGISIL PH BALANCE",
              "VAGISIL SENSITIVE PLUS WASH": "VAGISIL SENSITIVE PLUS WASH",
              "VAGISIL YEAST-CONTROL": "VAGISIL YEAST-CONTROL"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS CLEANSING WASH ODOR BLOCK": "CVS CLEANSING WASH ODOR BLOCK",
              "CVS CLEANSING WASH/SENSITIVE": "CVS CLEANSING WASH/SENSITIVE",
              "CVS FEMININE MOISTURIZER": "CVS FEMININE MOISTURIZER",
              "CVS VAGICAINE": "CVS VAGICAINE",
              "CVS VAGINAL MOISTURIZER": "CVS VAGINAL MOISTURIZER"
            },
            "EQUALINE": {
              "EQL VAGICAINE MAXIMUM STRENGTH": "EQL VAGICAINE MAXIMUM STRENGTH"
            },
            "FLEET PHARMACEUTICALS": {
              "NORFORMS": "NORFORMS",
              "SUMMERS EVE CLEANSING WASH": "SUMMERS EVE CLEANSING WASH",
              "SUMMERS EVE EXTRA CLEANSING": "SUMMERS EVE EXTRA CLEANSING",
              "SUMMERS EVE FEMININE WASH": "SUMMERS EVE FEMININE WASH",
              "SUMMERS EVE NIGHT-TIME": "SUMMERS EVE NIGHT-TIME"
            },
            "J R CARLSON LABORATORIES": {
              "KEY-E": "KEY-E"
            },
            "LACLEDE": {
              "LUVENA DAILY THERAPEUTIC WASH": "LUVENA DAILY THERAPEUTIC WASH",
              "LUVENA PREBIOTIC LUBRICANT": "LUVENA PREBIOTIC LUBRICANT",
              "LUVENA RESTORATIVE RINSE": "LUVENA RESTORATIVE RINSE",
              "LUVENA VAGINAL MOISTURIZER": "LUVENA VAGINAL MOISTURIZER"
            },
            "LAKE CONSUMER PRODUCTS": {
              "VH ESSENTIALS MOISTURIZER": "VH ESSENTIALS MOISTURIZER",
              "VH ESSENTIALS PREBIOTIC": "VH ESSENTIALS PREBIOTIC"
            },
            "LEADER BRAND PRODUCTS": {
              "ANTI-ITCH": "ANTI-ITCH",
              "ANTI-ITCH VAGINAL": "ANTI-ITCH VAGINAL"
            },
            "LIL DRUG STORE PRODUCTS": {
              "REPHRESH": "REPHRESH",
              "REPHRESH CLEAN BALANCE": "REPHRESH CLEAN BALANCE",
              "REPLENS": "REPLENS"
            },
            "MEDTECH": {
              "MASSENGILL FEMININE WASH": "MASSENGILL FEMININE WASH",
              "MASSENGILL VINEGAR AND WATER": "MASSENGILL VINEGAR AND WATER"
            },
            "MILEX": {
              "TRIMO-SAN": "TRIMO-SAN"
            },
            "MILLICENT U.S.": {
              "INTRAROSA": "INTRAROSA"
            },
            "PARNELL": {
              "FEMINEASE": "FEMINEASE"
            },
            "PHARMADERM": {
              "LUBRIN": "LUBRIN"
            },
            "RECKITT BENCKISER": {
              "K-Y LIQUIBEADS": "K-Y LIQUIBEADS"
            },
            "RITE AID CORPORATION": {
              "RA FEMININE CARE DOUCHE": "RA FEMININE CARE DOUCHE",
              "RA FEMININE CLEANSING WASH": "RA FEMININE CLEANSING WASH",
              "RA FEMININE WASH SENSITIVE": "RA FEMININE WASH SENSITIVE",
              "RA SILKY LUBRICANT MOISTURIZER": "RA SILKY LUBRICANT MOISTURIZER",
              "RA VAGICAINE": "RA VAGICAINE",
              "RA VAGICAINE MAXIMUM STRENGTH": "RA VAGICAINE MAXIMUM STRENGTH"
            },
            "SELECT BRAND": {
              "SB EX CLEANSING VINEGAR/WATER": "SB EX CLEANSING VINEGAR/WATER"
            },
            "TARGET": {
              "TGT VAGICAINE MAXIMUM STRENGTH": "TGT VAGICAINE MAXIMUM STRENGTH"
            },
            "WAL-MART": {
              "EQ FEMININE WASH SENSITIVE": "EQ FEMININE WASH SENSITIVE",
              "VAGICAINE": "VAGICAINE"
            },
            "WALGREENS": {
              "ANTI-ITCH": "ANTI-ITCH",
              "DOUCHE VINEGAR/WATER": "DOUCHE VINEGAR/WATER"
            },
            "WYNNPHARM": {
              "SAUGELLA FEMININE WASH": "SAUGELLA FEMININE WASH"
            }
          }
        },
        "Spermicides": {
          "Spermicides": {
            "APOTHECUS": {
              "VCF VAGINAL CONTRACEPTIVE": "VCF VAGINAL CONTRACEPTIVE"
            },
            "BLAIREX LABS": {
              "ENCARE": "ENCARE"
            },
            "MAYER LABORATORIES": {
              "TODAY SPONGE": "TODAY SPONGE"
            },
            "MILEX": {
              "SHUR-SEAL CONTRACEPTIVE": "SHUR-SEAL CONTRACEPTIVE"
            },
            "REVIVE PERSONAL PRODUCTS": {
              "OPTIONS CONCEPTROL": "OPTIONS CONCEPTROL",
              "OPTIONS GYNOL II CONTRACEPTIVE": "OPTIONS GYNOL II CONTRACEPTIVE"
            }
          }
        },
        "Vaginal Anti-infectives": {
          "Imidazole-Related Antifungals": {
            "A-S MEDICATION SOLUTIONS": {
              "CLOTRIMAZOLE": "CLOTRIMAZOLE",
              "MICONAZOLE NITRATE": "MICONAZOLE NITRATE",
              "TERAZOL 3": "TERAZOL 3",
              "TERCONAZOLE": "TERCONAZOLE"
            },
            "ACTAVIS MID ATLANTIC": {
              "CLOTRIMAZOLE": "CLOTRIMAZOLE",
              "MICONAZOLE 3": "MICONAZOLE 3",
              "MICONAZOLE 7": "MICONAZOLE 7",
              "MICONAZOLE NITRATE": "MICONAZOLE NITRATE"
            },
            "ACTAVIS PHARMA": {
              "TERCONAZOLE": "TERCONAZOLE"
            },
            "AIDAREX PHARMACEUTICALS": {
              "CLOTRIMAZOLE": "CLOTRIMAZOLE",
              "MICONAZOLE NITRATE": "MICONAZOLE NITRATE",
              "TERCONAZOLE": "TERCONAZOLE"
            },
            "AMERICAN SALES COMPANY": {
              "MICONAZOLE 3 COMBO PACK": "MICONAZOLE 3 COMBO PACK",
              "MICONAZOLE 7": "MICONAZOLE 7",
              "TIOCONAZOLE-1": "TIOCONAZOLE-1"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP MICONAZOLE 1": "GNP MICONAZOLE 1",
              "GNP MICONAZOLE 3 APPLICATOR": "GNP MICONAZOLE 3 APPLICATOR"
            },
            "BAYER CONSUMER": {
              "GYNE-LOTRIMIN": "GYNE-LOTRIMIN",
              "GYNE-LOTRIMIN 3": "GYNE-LOTRIMIN 3"
            },
            "BERGEN BRUNSWIG": {
              "GNP CLOTRIMAZOLE 3": "GNP CLOTRIMAZOLE 3",
              "GNP MICONAZOLE 3": "GNP MICONAZOLE 3",
              "GNP MICONAZOLE 7": "GNP MICONAZOLE 7",
              "GNP TIOCONAZOLE 1": "GNP TIOCONAZOLE 1"
            },
            "CHAIN DRUG CONSORTIUM": {
              "CLOTRIMAZOLE": "CLOTRIMAZOLE",
              "MICONAZOLE 3": "MICONAZOLE 3",
              "MICONAZOLE 3 COMBO PACK": "MICONAZOLE 3 COMBO PACK",
              "MICONAZOLE 7": "MICONAZOLE 7"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC 3 DAY": "QC 3 DAY",
              "QC MICONAZOLE 7": "QC MICONAZOLE 7"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "TERCONAZOLE": "TERCONAZOLE"
            },
            "COMBE": {
              "VAGISTAT-3": "VAGISTAT-3"
            },
            "COSETTE PHARMACEUTICALS": {
              "MICONAZOLE NITRATE": "MICONAZOLE NITRATE",
              "TERCONAZOLE": "TERCONAZOLE"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS 3-DAY VAGINAL": "CVS 3-DAY VAGINAL",
              "CVS CLOTRIMAZOLE 3": "CVS CLOTRIMAZOLE 3",
              "CVS MICONAZOLE 1 COMBO PACK": "CVS MICONAZOLE 1 COMBO PACK",
              "CVS MICONAZOLE 3 COMBO PACK": "CVS MICONAZOLE 3 COMBO PACK",
              "CVS MICONAZOLE 3-DAY COMBO APP": "CVS MICONAZOLE 3-DAY COMBO APP",
              "CVS MICONAZOLE 7": "CVS MICONAZOLE 7",
              "CVS TIOCONAZOLE 1": "CVS TIOCONAZOLE 1"
            },
            "EQUALINE": {
              "EQL MICONAZOLE 1": "EQL MICONAZOLE 1",
              "EQL MICONAZOLE 3": "EQL MICONAZOLE 3",
              "EQL MICONAZOLE 3 APPLICATOR": "EQL MICONAZOLE 3 APPLICATOR",
              "EQL MICONAZOLE 7": "EQL MICONAZOLE 7",
              "EQL TIOCONAZOLE-1": "EQL TIOCONAZOLE-1"
            },
            "FOUGERA": {
              "TERCONAZOLE": "TERCONAZOLE"
            },
            "GEISS DESTIN & DUNN": {
              "GOODSENSE MICONAZOLE 1": "GOODSENSE MICONAZOLE 1",
              "MICONAZOLE 3 COMBO PACK": "MICONAZOLE 3 COMBO PACK",
              "MICONAZOLE 7": "MICONAZOLE 7"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "VAGISTAT-1": "VAGISTAT-1",
              "VAGISTAT-3": "VAGISTAT-3"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "CLOTRIMAZOLE": "CLOTRIMAZOLE",
              "MICONAZOLE NITRATE": "MICONAZOLE NITRATE",
              "TERAZOL 7": "TERAZOL 7",
              "TERCONAZOLE": "TERCONAZOLE"
            },
            "INSIGHT PHARMACEUTICALS": {
              "MONISTAT 1 COMBO PACK": "MONISTAT 1 COMBO PACK",
              "MONISTAT 1 DAY OR NIGHT": "MONISTAT 1 DAY OR NIGHT",
              "MONISTAT 1-DAY": "MONISTAT 1-DAY",
              "MONISTAT 3": "MONISTAT 3",
              "MONISTAT 3 COMBINATION PACK": "MONISTAT 3 COMBINATION PACK",
              "MONISTAT 3 COMBO PACK APP": "MONISTAT 3 COMBO PACK APP",
              "MONISTAT 7 COMBO PACK APP": "MONISTAT 7 COMBO PACK APP",
              "MONISTAT 7 COMPLETE THERAPY": "MONISTAT 7 COMPLETE THERAPY",
              "MONISTAT 7 SIMPLY CURE": "MONISTAT 7 SIMPLY CURE"
            },
            "JANSSEN": {
              "TERAZOL 3": "TERAZOL 3",
              "TERAZOL 7": "TERAZOL 7"
            },
            "KROGER COMPANY": {
              "MICONAZOLE 3 COMBO PACK APP": "MICONAZOLE 3 COMBO PACK APP",
              "MICONAZOLE 7": "MICONAZOLE 7"
            },
            "LEADER BRAND PRODUCTS": {
              "MICONAZOLE 1": "MICONAZOLE 1",
              "MICONAZOLE 3 COMBO PACK": "MICONAZOLE 3 COMBO PACK",
              "MICONAZOLE 7": "MICONAZOLE 7",
              "TIOCONAZOLE-1": "TIOCONAZOLE-1"
            },
            "MAJOR PHARMACEUTICALS": {
              "MICONAZOLE 3 COMBO PACK": "MICONAZOLE 3 COMBO PACK",
              "MICONAZOLE 7": "MICONAZOLE 7"
            },
            "MCKESSON SUNMARK": {
              "SM 3-DAY VAGINAL": "SM 3-DAY VAGINAL",
              "SM CLOTRIMAZOLE VAGINAL": "SM CLOTRIMAZOLE VAGINAL",
              "SM MICONAZOLE 3": "SM MICONAZOLE 3",
              "SM MICONAZOLE 3 APPLICATOR": "SM MICONAZOLE 3 APPLICATOR",
              "SM MICONAZOLE 7": "SM MICONAZOLE 7",
              "SM TIOCONAZOLE-1": "SM TIOCONAZOLE-1"
            },
            "MEDICINE SHOPPE": {
              "MICONAZOLE 7": "MICONAZOLE 7"
            },
            "NUCARE PHARMACEUTICALS": {
              "MICONAZOLE NITRATE": "MICONAZOLE NITRATE"
            },
            "PERRIGO PHARMACEUTICALS": {
              "GYNAZOLE-1": "GYNAZOLE-1",
              "TERCONAZOLE": "TERCONAZOLE"
            },
            "PHARMADERM": {
              "ZAZOLE": "ZAZOLE"
            },
            "PHARMEDIX": {
              "TERCONAZOLE": "TERCONAZOLE"
            },
            "PREFERRED PHARMACEUTICALS": {
              "MICONAZOLE NITRATE": "MICONAZOLE NITRATE"
            },
            "PUBLIX SUPER MARKETS INC.": {
              "PX MICONAZOLE 3-DAY COMBO": "PX MICONAZOLE 3-DAY COMBO"
            },
            "QUALITY CARE": {
              "CLOTRIMAZOLE": "CLOTRIMAZOLE",
              "MICONAZOLE NITRATE": "MICONAZOLE NITRATE",
              "TERCONAZOLE": "TERCONAZOLE"
            },
            "RITE AID CORPORATION": {
              "RA CLOTRIMAZOLE 3": "RA CLOTRIMAZOLE 3",
              "RA CLOTRIMAZOLE 7": "RA CLOTRIMAZOLE 7",
              "RA MICONAZOLE 3 COMBO PACK": "RA MICONAZOLE 3 COMBO PACK",
              "RA MICONAZOLE 3 COMBO PACK APP": "RA MICONAZOLE 3 COMBO PACK APP",
              "RA MICONAZOLE 7": "RA MICONAZOLE 7",
              "RA TIOCONAZOLE 1": "RA TIOCONAZOLE 1"
            },
            "TARGET": {
              "TGT MICONAZOLE 1": "TGT MICONAZOLE 1",
              "TGT MICONAZOLE 3 COMBO PACK": "TGT MICONAZOLE 3 COMBO PACK",
              "TGT MICONAZOLE 7": "TGT MICONAZOLE 7",
              "TGT TIOCONAZOLE 1": "TGT TIOCONAZOLE 1",
              "TGT TIOCONAZOLE 1DAY": "TGT TIOCONAZOLE 1DAY"
            },
            "TARO": {
              "3 DAY VAGINAL": "3 DAY VAGINAL",
              "CLOTRIMAZOLE": "CLOTRIMAZOLE",
              "MICONAZOLE NITRATE": "MICONAZOLE NITRATE",
              "TERCONAZOLE": "TERCONAZOLE"
            },
            "TOPCO": {
              "CLOTRIMAZOLE 3": "CLOTRIMAZOLE 3",
              "MICONAZOLE 1": "MICONAZOLE 1",
              "MICONAZOLE 3": "MICONAZOLE 3",
              "MICONAZOLE 3 APPLICATOR": "MICONAZOLE 3 APPLICATOR",
              "MICONAZOLE 3 COMBO PACK": "MICONAZOLE 3 COMBO PACK",
              "MICONAZOLE 7": "MICONAZOLE 7",
              "TIOCONAZOLE-1": "TIOCONAZOLE-1"
            },
            "WAL-MART": {
              "EQ MICONAZOLE 1": "EQ MICONAZOLE 1",
              "EQ MICONAZOLE 3 COMBO PACK": "EQ MICONAZOLE 3 COMBO PACK",
              "EQ MICONAZOLE 7": "EQ MICONAZOLE 7",
              "EQ MICONAZOLE 7 DAY TREATMENT": "EQ MICONAZOLE 7 DAY TREATMENT",
              "EQ TIOCONAZOLE 1": "EQ TIOCONAZOLE 1"
            },
            "WALGREENS": {
              "CLOTRIMAZOLE 3": "CLOTRIMAZOLE 3",
              "CLOTRIMAZOLE-7": "CLOTRIMAZOLE-7",
              "MICONAZOLE 1": "MICONAZOLE 1",
              "MICONAZOLE 3": "MICONAZOLE 3",
              "MICONAZOLE 3 APPLICATOR": "MICONAZOLE 3 APPLICATOR",
              "MICONAZOLE 3 COMBO PACK": "MICONAZOLE 3 COMBO PACK",
              "MICONAZOLE 7": "MICONAZOLE 7",
              "TIOCONAZOLE-1": "TIOCONAZOLE-1"
            }
          },
          "Vaginal Anti-infectives": {
            "A-S MEDICATION SOLUTIONS": {
              "CLEOCIN": "CLEOCIN",
              "CLINDAMYCIN PHOSPHATE": "CLINDAMYCIN PHOSPHATE",
              "METROGEL-VAGINAL": "METROGEL-VAGINAL",
              "METRONIDAZOLE": "METRONIDAZOLE"
            },
            "AIDAREX PHARMACEUTICALS": {
              "METRONIDAZOLE": "METRONIDAZOLE"
            },
            "ALLERGAN": {
              "NUVESSA": "NUVESSA"
            },
            "BAUSCH HEALTH": {
              "METROGEL-VAGINAL": "METROGEL-VAGINAL"
            },
            "CHAIN DRUG CONSORTIUM": {
              "MEDICATED DOUCHE": "MEDICATED DOUCHE"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS DISPOSABLE DOUCHE": "CVS DISPOSABLE DOUCHE"
            },
            "EXELTIS USA": {
              "NUVESSA": "NUVESSA"
            },
            "FLEET PHARMACEUTICALS": {
              "SUMMERS EVE DISP MEDICATED": "SUMMERS EVE DISP MEDICATED"
            },
            "FOUGERA": {
              "CLINDAMYCIN PHOSPHATE": "CLINDAMYCIN PHOSPHATE"
            },
            "GREENSTONE": {
              "CLINDAMYCIN PHOSPHATE": "CLINDAMYCIN PHOSPHATE"
            },
            "LAKE CONSUMER PRODUCTS": {
              "VH ESSENTIALS MEDICATED DOUCHE": "VH ESSENTIALS MEDICATED DOUCHE"
            },
            "MYLAN SPECIALTY L.P.": {
              "AVC VAGINAL": "AVC VAGINAL"
            },
            "OCEANSIDE PHARMACEUTICALS": {
              "METRONIDAZOLE": "METRONIDAZOLE"
            },
            "PERRIGO PHARMACEUTICALS": {
              "CLINDESSE": "CLINDESSE",
              "METRONIDAZOLE": "METRONIDAZOLE"
            },
            "PFIZER U.S.": {
              "CLEOCIN": "CLEOCIN"
            },
            "PHARMEDIX": {
              "METRONIDAZOLE": "METRONIDAZOLE"
            },
            "PRASCO LABORATORIES": {
              "METRONIDAZOLE": "METRONIDAZOLE"
            },
            "QUALITY CARE": {
              "METROGEL-VAGINAL": "METROGEL-VAGINAL"
            },
            "RXCHANGE CO": {
              "VANDAZOLE": "VANDAZOLE"
            },
            "SANDOZ": {
              "METRONIDAZOLE": "METRONIDAZOLE"
            },
            "TOPCO": {
              "MEDICATED FEMININE DOUCHE": "MEDICATED FEMININE DOUCHE"
            },
            "UPSHER-SMITH": {
              "VANDAZOLE": "VANDAZOLE"
            },
            "WALGREENS": {
              "MEDICATED DOUCHE": "MEDICATED DOUCHE"
            }
          }
        },
        "Vaginal Contraceptive - pH Modulators": {
          "Vaginal Contraceptive pH Modulator - Combinations": {
            "EVOFEM": {
              "PHEXXI": "PHEXXI"
            }
          }
        },
        "Vaginal Estrogens": {
          "Vaginal Estrogens": {
            "A-S MEDICATION SOLUTIONS": {
              "ESTRACE": "ESTRACE",
              "ESTRADIOL": "ESTRADIOL"
            },
            "ALLERGAN": {
              "ESTRACE": "ESTRACE",
              "FEMRING": "FEMRING"
            },
            "ALVOGEN": {
              "ESTRADIOL": "ESTRADIOL"
            },
            "AMNEAL PHARMACEUTICALS": {
              "ESTRADIOL": "ESTRADIOL",
              "YUVAFEM": "YUVAFEM"
            },
            "AVKARE": {
              "YUVAFEM": "YUVAFEM"
            },
            "GLENMARK PHARMACEUTICALS": {
              "ESTRADIOL": "ESTRADIOL"
            },
            "MILLICENT U.S.": {
              "FEMRING": "FEMRING"
            },
            "MYLAN": {
              "ESTRADIOL": "ESTRADIOL"
            },
            "NOVO NORDISK": {
              "VAGIFEM": "VAGIFEM"
            },
            "PFIZER U.S.": {
              "ESTRING": "ESTRING",
              "PREMARIN": "PREMARIN"
            },
            "TEVA PHARMACEUTICALS USA": {
              "ESTRADIOL": "ESTRADIOL"
            },
            "THERAPEUTICSMD": {
              "IMVEXXY MAINTENANCE PACK": "IMVEXXY MAINTENANCE PACK",
              "IMVEXXY STARTER PACK": "IMVEXXY STARTER PACK"
            }
          }
        },
        "Vaginal Progestins": {
          "Vaginal Progestins": {
            "ALLERGAN": {
              "CRINONE": "CRINONE"
            },
            "AZURITY PHARMACEUTICALS": {
              "FIRST-PROGESTERONE VGS": "FIRST-PROGESTERONE VGS"
            },
            "CUTIS PHARMA, INC": {
              "FIRST-PROGESTERONE VGS": "FIRST-PROGESTERONE VGS"
            },
            "FERRING": {
              "ENDOMETRIN": "ENDOMETRIN"
            }
          }
        }
      },
      "VASOPRESSORS": {
        "Anaphylaxis Therapy Agents": {
          "Anaphylaxis Therapy Agents": {
            "A-S MEDICATION SOLUTIONS": {
              "EPINEPHRINE": "EPINEPHRINE",
              "EPIPEN 2-PAK": "EPIPEN 2-PAK",
              "EPIPEN JR 2-PAK": "EPIPEN JR 2-PAK"
            },
            "AMNEAL PHARMACEUTICALS": {
              "EPINEPHRINE": "EPINEPHRINE"
            },
            "AMNEAL SPECIALTY": {
              "ADRENACLICK": "ADRENACLICK"
            },
            "ENOVACHEM MANUFACTURING": {
              "ADYPHREN": "ADYPHREN",
              "ADYPHREN AMP": "ADYPHREN AMP",
              "ADYPHREN AMP II": "ADYPHREN AMP II",
              "ADYPHREN II": "ADYPHREN II",
              "EPY": "EPY",
              "EPY II": "EPY II"
            },
            "FOCUS HEALTH GROUP": {
              "EPINEPHRINE PROFESSIONAL": "EPINEPHRINE PROFESSIONAL",
              "EPINEPHRINESNAP-V": "EPINEPHRINESNAP-V",
              "EPISNAP": "EPISNAP"
            },
            "IMPAX GENERICS": {
              "EPINEPHRINE": "EPINEPHRINE"
            },
            "KALEO": {
              "AUVI-Q": "AUVI-Q"
            },
            "MYLAN SPECIALTY L.P.": {
              "EPINEPHRINE": "EPINEPHRINE",
              "EPIPEN 2-PAK": "EPIPEN 2-PAK",
              "EPIPEN JR 2-PAK": "EPIPEN JR 2-PAK"
            },
            "PAR STERILE PRODUCTS": {
              "ADRENALIN": "ADRENALIN"
            },
            "PREFERRED PHARMACEUTICALS": {
              "EPINEPHRINE": "EPINEPHRINE"
            },
            "SANDOZ": {
              "SYMJEPI": "SYMJEPI"
            },
            "SNAP MEDICAL INDUSTRIES": {
              "EPINEPHRINESNAP-EMS": "EPINEPHRINESNAP-EMS",
              "EPINEPHRINESNAP-V": "EPINEPHRINESNAP-V"
            },
            "TEVA PHARMACEUTICALS USA": {
              "EPINEPHRINE": "EPINEPHRINE"
            },
            "USWM": {
              "SYMJEPI": "SYMJEPI"
            }
          }
        },
        "Neurogenic Orthostatic Hypotension (NOH) - Agents": {
          "Neurogenic Orthostatic Hypotension (NOH) - Agents": {
            "LUNDBECK": {
              "NORTHERA": "NORTHERA"
            }
          }
        },
        "Vasopressors": {
          "Vasopressors": {
            "A-S MEDICATION SOLUTIONS": {
              "EPINEPHRINE": "EPINEPHRINE",
              "EPINEPHRINE PF": "EPINEPHRINE PF"
            },
            "AKORN": {
              "EPHEDRINE SULFATE": "EPHEDRINE SULFATE"
            },
            "AMERICAN HEALTH PACKAGING": {
              "MIDODRINE HCL": "MIDODRINE HCL"
            },
            "AMERICAN REGENT": {
              "DOPAMINE HCL": "DOPAMINE HCL"
            },
            "AMNEAL BIOSCIENCES": {
              "EPHEDRINE SULFATE": "EPHEDRINE SULFATE",
              "NOREPINEPHRINE BITARTRATE": "NOREPINEPHRINE BITARTRATE",
              "PHENYLEPHRINE HCL": "PHENYLEPHRINE HCL"
            },
            "AMNEAL PHARMACEUTICALS": {
              "MIDODRINE HCL": "MIDODRINE HCL"
            },
            "APOLLO PHARMACEUTICALS": {
              "PHENYLEPHRINE HCL": "PHENYLEPHRINE HCL"
            },
            "APOTEX": {
              "MIDODRINE HCL": "MIDODRINE HCL"
            },
            "AUROBINDO PHARMA": {
              "MIDODRINE HCL": "MIDODRINE HCL"
            },
            "AVADEL LEGACY PHARMACEUTICALS": {
              "AKOVAZ": "AKOVAZ",
              "VAZCULEP": "VAZCULEP"
            },
            "AVKARE": {
              "MIDODRINE HCL": "MIDODRINE HCL"
            },
            "AVPAK": {
              "MIDODRINE HCL": "MIDODRINE HCL"
            },
            "BAXTER HEALTHCARE CORP": {
              "DOBUTAMINE IN D5W": "DOBUTAMINE IN D5W",
              "DOPAMINE IN D5W": "DOPAMINE IN D5W",
              "NOREPINEPHRINE BITARTRATE": "NOREPINEPHRINE BITARTRATE"
            },
            "BELCHER PHARMACEUTICALS": {
              "EPINEPHRINE PF": "EPINEPHRINE PF"
            },
            "BLUE POINT LABORATORIES": {
              "AKOVAZ": "AKOVAZ"
            },
            "BPI LABS LLC": {
              "EPINEPHRINE PF": "EPINEPHRINE PF"
            },
            "CENTRAL ADMIXTURE PHARMACY SER": {
              "EPHEDRINE SULFATE-NACL": "EPHEDRINE SULFATE-NACL",
              "EPINEPHRINE HCL-DEXTROSE": "EPINEPHRINE HCL-DEXTROSE",
              "NOREPINEPHRINE-DEXTROSE": "NOREPINEPHRINE-DEXTROSE",
              "PHENYLEPHRINE HCL-NACL": "PHENYLEPHRINE HCL-NACL"
            },
            "CIPLA USA": {
              "PHENYLEPHRINE HCL": "PHENYLEPHRINE HCL"
            },
            "CIVICA": {
              "EPHEDRINE SULFATE": "EPHEDRINE SULFATE",
              "PHENYLEPHRINE HCL": "PHENYLEPHRINE HCL"
            },
            "ETON PHARMACEUTICALS": {
              "BIORPHEN": "BIORPHEN"
            },
            "FAGRON COMPOUNDING SERVICES": {
              "EPHEDRINE SULFATE": "EPHEDRINE SULFATE",
              "EPHEDRINE SULFATE-NACL": "EPHEDRINE SULFATE-NACL",
              "PHENYLEPHRINE HCL (PRESSORS)": "PHENYLEPHRINE HCL (PRESSORS)"
            },
            "FRESENIUS KABI USA": {
              "PHENYLEPHRINE HCL": "PHENYLEPHRINE HCL"
            },
            "HIKMA": {
              "DOPAMINE HCL": "DOPAMINE HCL",
              "NOREPINEPHRINE BITARTRATE": "NOREPINEPHRINE BITARTRATE",
              "PHENYLEPHRINE HCL": "PHENYLEPHRINE HCL"
            },
            "HOSPIRA": {
              "DOBUTAMINE HCL": "DOBUTAMINE HCL",
              "DOBUTAMINE IN D5W": "DOBUTAMINE IN D5W",
              "DOPAMINE HCL": "DOPAMINE HCL",
              "DOPAMINE IN D5W": "DOPAMINE IN D5W",
              "EPINEPHRINE": "EPINEPHRINE",
              "EPINEPHRINE PF": "EPINEPHRINE PF",
              "LEVOPHED": "LEVOPHED"
            },
            "INTEGRADOSE COMPOUNDING SERVIC": {
              "EPHEDRINE SULFATE-NACL": "EPHEDRINE SULFATE-NACL",
              "PHENYLEPHRINE HCL-NACL": "PHENYLEPHRINE HCL-NACL"
            },
            "INTRNTL MEDICATION SYSTEM": {
              "EPINEPHRINE": "EPINEPHRINE"
            },
            "LA JOLLA PHARMACEUTICAL COMPAN": {
              "GIAPREZA": "GIAPREZA"
            },
            "LEITERS HEALTH": {
              "EPHEDRINE SULFATE (PRESSORS)": "EPHEDRINE SULFATE (PRESSORS)",
              "EPINEPHRINE-NACL": "EPINEPHRINE-NACL",
              "PHENYLEPHRINE HCL (PRESSORS)": "PHENYLEPHRINE HCL (PRESSORS)"
            },
            "MAJOR PHARMACEUTICALS": {
              "MIDODRINE HCL": "MIDODRINE HCL"
            },
            "MEITHEAL PHARMACEUTICALS": {
              "PHENYLEPHRINE HCL": "PHENYLEPHRINE HCL"
            },
            "MYLAN": {
              "MIDODRINE HCL": "MIDODRINE HCL"
            },
            "MYLAN INSTITUTIONAL": {
              "MIDODRINE HCL": "MIDODRINE HCL",
              "NOREPINEPHRINE BITARTRATE": "NOREPINEPHRINE BITARTRATE"
            },
            "NEPHRON STERILE COMPOUNDING": {
              "EPHEDRINE SULFATE": "EPHEDRINE SULFATE",
              "EPINEPHRINE": "EPINEPHRINE",
              "NOREPINEPHRINE-SODIUM CHLORIDE": "NOREPINEPHRINE-SODIUM CHLORIDE",
              "PHENYLEPHRINE HCL": "PHENYLEPHRINE HCL",
              "PHENYLEPHRINE HCL (PRESSORS)": "PHENYLEPHRINE HCL (PRESSORS)"
            },
            "NEXUS PHARMA": {
              "EMERPHED": "EMERPHED",
              "EPHEDRINE SULFATE": "EPHEDRINE SULFATE"
            },
            "NOVAPLUS / AKORN": {
              "EPHEDRINE SULFATE": "EPHEDRINE SULFATE"
            },
            "NOVAPLUS/FRESENIUS KABI": {
              "PHENYLEPHRINE HCL": "PHENYLEPHRINE HCL"
            },
            "NOVAPLUS/HOSPIRA": {
              "EPINEPHRINE PF": "EPINEPHRINE PF",
              "LEVOPHED": "LEVOPHED"
            },
            "PAR PHARMACEUTICAL": {
              "MIDODRINE HCL": "MIDODRINE HCL"
            },
            "PAR STERILE PRODUCTS": {
              "EPHEDRINE SULFATE": "EPHEDRINE SULFATE",
              "PHENYLEPHRINE HCL": "PHENYLEPHRINE HCL"
            },
            "PFIZER U.S.": {
              "DOPAMINE IN D5W": "DOPAMINE IN D5W"
            },
            "PHARMEDIUM SERVICES": {
              "EPHEDRINE SULFATE": "EPHEDRINE SULFATE",
              "EPHEDRINE SULFATE-NACL": "EPHEDRINE SULFATE-NACL",
              "EPINEPHRINE HCL-DEXTROSE": "EPINEPHRINE HCL-DEXTROSE",
              "EPINEPHRINE HCL-NACL": "EPINEPHRINE HCL-NACL",
              "NOREPINEPHRINE-DEXTROSE": "NOREPINEPHRINE-DEXTROSE",
              "NOREPINEPHRINE-SODIUM CHLORIDE": "NOREPINEPHRINE-SODIUM CHLORIDE",
              "PHENYLEPHRINE HCL-DEXTROSE": "PHENYLEPHRINE HCL-DEXTROSE",
              "PHENYLEPHRINE HCL-NACL": "PHENYLEPHRINE HCL-NACL"
            },
            "QUVA PHARMA": {
              "EPHEDRINE SULFATE-NACL": "EPHEDRINE SULFATE-NACL",
              "EPINEPHRINE HCL-NACL": "EPINEPHRINE HCL-NACL",
              "EPINEPHRINE-DEXTROSE": "EPINEPHRINE-DEXTROSE",
              "EPINEPHRINE-NACL": "EPINEPHRINE-NACL",
              "NOREPINEPHRINE-DEXTROSE": "NOREPINEPHRINE-DEXTROSE",
              "NOREPINEPHRINE-SODIUM CHLORIDE": "NOREPINEPHRINE-SODIUM CHLORIDE",
              "PHENYLEPHRINE HCL-NACL": "PHENYLEPHRINE HCL-NACL"
            },
            "RISING PHARMACEUTICALS": {
              "MIDODRINE HCL": "MIDODRINE HCL"
            },
            "SANDOZ": {
              "EPHEDRINE SULFATE": "EPHEDRINE SULFATE",
              "MIDODRINE HCL": "MIDODRINE HCL",
              "NOREPINEPHRINE BITARTRATE": "NOREPINEPHRINE BITARTRATE",
              "PHENYLEPHRINE HCL": "PHENYLEPHRINE HCL"
            },
            "SCA PHARMACEUTICALS": {
              "EPHEDRINE SULFATE-NACL": "EPHEDRINE SULFATE-NACL",
              "NOREPINEPHRINE-SODIUM CHLORIDE": "NOREPINEPHRINE-SODIUM CHLORIDE",
              "PHENYLEPHRINE HCL-NACL": "PHENYLEPHRINE HCL-NACL"
            },
            "SKY PACKAGING": {
              "MIDODRINE HCL": "MIDODRINE HCL"
            },
            "STERRX": {
              "EPINEPHRINE-NACL": "EPINEPHRINE-NACL",
              "NOREPINEPHRINE-SODIUM CHLORIDE": "NOREPINEPHRINE-SODIUM CHLORIDE"
            },
            "TEVA PARENTERAL MEDICINES": {
              "NOREPINEPHRINE BITARTRATE": "NOREPINEPHRINE BITARTRATE"
            },
            "TRUPHARMA": {
              "MIDODRINE HCL": "MIDODRINE HCL"
            },
            "UPSHER-SMITH": {
              "MIDODRINE HCL": "MIDODRINE HCL"
            },
            "XIROMED": {
              "MIDODRINE HCL": "MIDODRINE HCL"
            }
          }
        }
      },
      "VITAMINS": {
        "Misc. Nutritional Factors": {
          "Bioflavonoid Combinations": {
            "ADVANCED GENERIC": {
              "BIOFLONEX": "BIOFLONEX"
            },
            "KRAMER-NOVIS": {
              "FLOGEN": "FLOGEN",
              "VASOFLEX D1": "VASOFLEX D1",
              "VENALIV": "VENALIV"
            }
          },
          "Bioflavonoids": {
            "BIO-TECH": {
              "P-1000": "P-1000"
            },
            "FREEDA VITAMINS": {
              "QUERCETIN": "QUERCETIN",
              "RUTIN": "RUTIN"
            },
            "MAGNO-HUMPHRIES LABORATORIES": {
              "RUTIN": "RUTIN"
            },
            "NAT-RUL HEALTH PRODUCTS": {
              "CITRUS BIOFLAVONOIDS": "CITRUS BIOFLAVONOIDS"
            },
            "THE KEY COMPANY": {
              "BIOFLAVONOID-1000": "BIOFLAVONOID-1000",
              "RUTIN-50": "RUTIN-50"
            }
          }
        },
        "Oil Soluble Vitamins": {
          "Vitamin A": {
            "21ST CENTURY HEALTHCARE": {
              "VITAMIN A": "VITAMIN A"
            },
            "AVPAK": {
              "VITAMIN A": "VITAMIN A"
            },
            "BASIC DRUGS": {
              "BETA CAROTENE": "BETA CAROTENE",
              "VITAMIN A": "VITAMIN A"
            },
            "BERGEN BRUNSWIG": {
              "GNP VITAMIN A": "GNP VITAMIN A"
            },
            "BIO-TECH": {
              "A-25": "A-25",
              "B-CARO-T": "B-CARO-T",
              "CAROGUARD": "CAROGUARD"
            },
            "CASPER PHARMA": {
              "AQUASOL A": "AQUASOL A"
            },
            "CHAIN DRUG CONSORTIUM": {
              "BETA CAROTENE": "BETA CAROTENE",
              "VITAMIN A": "VITAMIN A"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS BETA CAROTENE": "CVS BETA CAROTENE",
              "CVS VITAMIN A": "CVS VITAMIN A"
            },
            "EQUALINE": {
              "EQL BETA CAROTENE": "EQL BETA CAROTENE"
            },
            "FREEDA VITAMINS": {
              "VITAMIN A PALMITATE": "VITAMIN A PALMITATE",
              "VITAMIN A-BETA CAROTENE": "VITAMIN A-BETA CAROTENE"
            },
            "J R CARLSON LABORATORIES": {
              "VITAMIN A": "VITAMIN A"
            },
            "LEINER HEALTH PRODUCTS": {
              "YL BETA CAROTENE": "YL BETA CAROTENE"
            },
            "MAGNO-HUMPHRIES LABORATORIES": {
              "VITAMIN A": "VITAMIN A"
            },
            "MAJOR PHARMACEUTICALS": {
              "VITAMIN A": "VITAMIN A",
              "VITAMIN A FISH": "VITAMIN A FISH"
            },
            "MASON VITAMINS": {
              "A-10000": "A-10000",
              "BETA CAROTENE": "BETA CAROTENE"
            },
            "MILLER": {
              "BETA CAROTENE": "BETA CAROTENE"
            },
            "NAT-RUL HEALTH PRODUCTS": {
              "BETA CAROTENE": "BETA CAROTENE",
              "NATURAL VITAMIN A": "NATURAL VITAMIN A"
            },
            "NATIONAL VITAMIN": {
              "BETA CAROTENE": "BETA CAROTENE",
              "VITAMIN A": "VITAMIN A"
            },
            "NATURES BOUNTY": {
              "BETA CAROTENE": "BETA CAROTENE",
              "VITAMIN A": "VITAMIN A"
            },
            "PHARMASSURE, INC.": {
              "BETA CAROTENE": "BETA CAROTENE",
              "VITAMIN A": "VITAMIN A"
            },
            "PHARMAVITE": {
              "BETA CAROTENE": "BETA CAROTENE",
              "VITAMIN A": "VITAMIN A",
              "VITAMIN A-BETA CAROTENE": "VITAMIN A-BETA CAROTENE"
            },
            "PRINCETON RESEARCH": {
              "BETA CAROTENE PROVITAMIN A": "BETA CAROTENE PROVITAMIN A"
            },
            "PUBLIX SUPER MARKETS INC.": {
              "PX VITAMIN A": "PX VITAMIN A"
            },
            "REXALL SUNDOWN": {
              "BETA CAROTENE": "BETA CAROTENE",
              "VITAMIN A": "VITAMIN A"
            },
            "RITE AID CORPORATION": {
              "RA BETA CAROTENE": "RA BETA CAROTENE",
              "RA VITAMIN A": "RA VITAMIN A"
            },
            "RUGBY LABORATORIES": {
              "BETA CAROTENE": "BETA CAROTENE"
            },
            "THE KEY COMPANY": {
              "A-CARO-25": "A-CARO-25"
            },
            "TISHCON CORP": {
              "LUMITENE": "LUMITENE"
            },
            "VITALINE": {
              "VITAMIN A-BETA CAROTENE": "VITAMIN A-BETA CAROTENE"
            },
            "WAL-MART": {
              "BETA CAROTENE": "BETA CAROTENE",
              "VITAMIN A": "VITAMIN A"
            },
            "WALGREENS": {
              "BETA CAROTENE": "BETA CAROTENE",
              "VITAMIN A": "VITAMIN A"
            },
            "WINDMILL CONSUMER PRODUCTS": {
              "A-10000": "A-10000"
            }
          },
          "Vitamin D": {
            "21ST CENTURY HEALTHCARE": {
              "D-1000 EXTRA STRENGTH": "D-1000 EXTRA STRENGTH",
              "D-2000 MAXIMUM STRENGTH": "D-2000 MAXIMUM STRENGTH",
              "D-400": "D-400",
              "D-5000": "D-5000",
              "D3 SUPER STRENGTH": "D3 SUPER STRENGTH",
              "D3-1000": "D3-1000",
              "VITAJOY DAILY D GUMMIES": "VITAJOY DAILY D GUMMIES",
              "VITAMIN D (ERGOCALCIFEROL)": "VITAMIN D (ERGOCALCIFEROL)",
              "VITAMIN D-1000 MAX ST": "VITAMIN D-1000 MAX ST",
              "VITAMIN D3": "VITAMIN D3"
            },
            "A-S MEDICATION SOLUTIONS": {
              "D3-50": "D3-50",
              "VITAMIN D (ERGOCALCIFEROL)": "VITAMIN D (ERGOCALCIFEROL)",
              "VITAMIN D3": "VITAMIN D3"
            },
            "AIDAREX PHARMACEUTICALS": {
              "VITAMIN D": "VITAMIN D",
              "VITAMIN D (ERGOCALCIFEROL)": "VITAMIN D (ERGOCALCIFEROL)"
            },
            "AKRON PHARMA": {
              "D-VITE PEDIATRIC": "D-VITE PEDIATRIC"
            },
            "ALEMBIC PHARMACEUTICALS": {
              "ERGOCALCIFEROL": "ERGOCALCIFEROL"
            },
            "ALVOGEN": {
              "ERGOCALCIFEROL": "ERGOCALCIFEROL"
            },
            "AMERICAN HEALTH PACKAGING": {
              "D 1000": "D 1000",
              "D 2000": "D 2000",
              "D 400": "D 400",
              "ERGOCALCIFEROL": "ERGOCALCIFEROL",
              "VITAMIN D (ERGOCALCIFEROL)": "VITAMIN D (ERGOCALCIFEROL)"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP VITAMIN D-400": "GNP VITAMIN D-400"
            },
            "APHENA PHARMA SOLUTIONS": {
              "ERGOCALCIFEROL": "ERGOCALCIFEROL",
              "VITAMIN D": "VITAMIN D",
              "VITAMIN D (CHOLECALCIFEROL)": "VITAMIN D (CHOLECALCIFEROL)",
              "VITAMIN D (ERGOCALCIFEROL)": "VITAMIN D (ERGOCALCIFEROL)",
              "VITAMIN D3": "VITAMIN D3"
            },
            "ATLANTIC BIOLOGICALS": {
              "VITAMIN D": "VITAMIN D"
            },
            "AVKARE": {
              "VITAMIN D (ERGOCALCIFEROL)": "VITAMIN D (ERGOCALCIFEROL)"
            },
            "AVPAK": {
              "VITAMIN D (ERGOCALCIFEROL)": "VITAMIN D (ERGOCALCIFEROL)",
              "VITAMIN D3": "VITAMIN D3",
              "VITAMIN D3 SUPER STRENGTH": "VITAMIN D3 SUPER STRENGTH",
              "VITAMIN D3 ULTRA STRENGTH": "VITAMIN D3 ULTRA STRENGTH"
            },
            "BASIC DRUGS": {
              "NATURAL VITAMIN D-3": "NATURAL VITAMIN D-3",
              "VITAMIN D-3": "VITAMIN D-3",
              "VITAMIN D3": "VITAMIN D3"
            },
            "BASIC ORGANICS": {
              "VITAMIN D": "VITAMIN D",
              "VITAMIN D (CHOLECALCIFEROL)": "VITAMIN D (CHOLECALCIFEROL)"
            },
            "BAYSHORE PHARMACEUTICALS": {
              "BPROTECTED PEDIA D-VITE": "BPROTECTED PEDIA D-VITE"
            },
            "BERGEN BRUNSWIG": {
              "GNP D 1000": "GNP D 1000",
              "GNP D 2000": "GNP D 2000",
              "GNP VITAMIN D": "GNP VITAMIN D",
              "GNP VITAMIN D MAXIMUM STRENGTH": "GNP VITAMIN D MAXIMUM STRENGTH",
              "GNP VITAMIN D SUPER STRENGTH": "GNP VITAMIN D SUPER STRENGTH",
              "GNP VITAMIN D3 EXTRA STRENGTH": "GNP VITAMIN D3 EXTRA STRENGTH"
            },
            "BIO-TECH": {
              "D-3-5": "D-3-5",
              "D3-50": "D3-50"
            },
            "BIONPHARMA": {
              "VITAMIN D (ERGOCALCIFEROL)": "VITAMIN D (ERGOCALCIFEROL)"
            },
            "BIOTICS RESEARCH": {
              "BIO-D-MULSION": "BIO-D-MULSION",
              "BIO-D-MULSION FORTE": "BIO-D-MULSION FORTE"
            },
            "BLENHEIM PHARMACAL": {
              "VITAMIN D (ERGOCALCIFEROL)": "VITAMIN D (ERGOCALCIFEROL)"
            },
            "BRECKENRIDGE": {
              "VITAMIN D (ERGOCALCIFEROL)": "VITAMIN D (ERGOCALCIFEROL)"
            },
            "BRONSON PHARMACEUTICALS": {
              "VITAMIN D (CHOLECALCIFEROL)": "VITAMIN D (CHOLECALCIFEROL)"
            },
            "BRYANT RANCH PREPACK": {
              "VITAMIN D (ERGOCALCIFEROL)": "VITAMIN D (ERGOCALCIFEROL)"
            },
            "CALLION PHARMA": {
              "AQUA-D": "AQUA-D"
            },
            "CHAIN DRUG CONSORTIUM": {
              "D 1000": "D 1000",
              "D 2000": "D 2000",
              "D 400": "D 400",
              "D 5000": "D 5000",
              "D-400": "D-400",
              "VITAMIN D": "VITAMIN D",
              "VITAMIN D3": "VITAMIN D3"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC VITAMIN D3": "QC VITAMIN D3"
            },
            "CHRONOHEALTH": {
              "VITAMIN D": "VITAMIN D",
              "VITAMIN D (CHOLECALCIFEROL)": "VITAMIN D (CHOLECALCIFEROL)"
            },
            "COSTCO WHOLESALE": {
              "KLS D3": "KLS D3"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS D3": "CVS D3",
              "CVS VITAMIN D CHILD GUMMIES": "CVS VITAMIN D CHILD GUMMIES",
              "CVS VITAMIN D3": "CVS VITAMIN D3",
              "CVS VITAMIN D3 DROPS/INFANT": "CVS VITAMIN D3 DROPS/INFANT",
              "RADIANCE PLATINUM VITAMIN D3": "RADIANCE PLATINUM VITAMIN D3"
            },
            "DDROPS COMPANY": {
              "BABY DDROPS": "BABY DDROPS",
              "DDROPS": "DDROPS",
              "DDROPS BOOSTER": "DDROPS BOOSTER"
            },
            "DIRECT RX": {
              "VITAMIN D (ERGOCALCIFEROL)": "VITAMIN D (ERGOCALCIFEROL)"
            },
            "ENOVACHEM MANUFACTURING": {
              "IS-D 10,000": "IS-D 10,000",
              "VITAMIN D3": "VITAMIN D3"
            },
            "ENZYMATIC THERAPY": {
              "VITAMIN D3": "VITAMIN D3"
            },
            "EPIC PHARMA": {
              "VITAMIN D (ERGOCALCIFEROL)": "VITAMIN D (ERGOCALCIFEROL)"
            },
            "EQUALINE": {
              "EQL VITAMIN D GUMMIES CHILD": "EQL VITAMIN D GUMMIES CHILD",
              "EQL VITAMIN D-3": "EQL VITAMIN D-3",
              "EQL VITAMIN D3": "EQL VITAMIN D3",
              "EQL VITAMIN D3 GUMMIES": "EQL VITAMIN D3 GUMMIES"
            },
            "FREEDA VITAMINS": {
              "DELTA D3": "DELTA D3",
              "VITAMIN D2": "VITAMIN D2",
              "VITAMIN D3": "VITAMIN D3"
            },
            "GENDOSE PHARMACEUTICALS": {
              "VITAMIN D3": "VITAMIN D3"
            },
            "GERI-CARE": {
              "VITAMIN D (CHOLECALCIFEROL)": "VITAMIN D (CHOLECALCIFEROL)",
              "VITAMIN D3 ULTRA POTENCY": "VITAMIN D3 ULTRA POTENCY"
            },
            "GLAXO CONSUMER HEALTHCARE L.P.": {
              "PRONUTRIENTS VITAMIN D3": "PRONUTRIENTS VITAMIN D3"
            },
            "GOLDEN STATE MEDICAL SUPPLY": {
              "ERGOCALCIFEROL": "ERGOCALCIFEROL",
              "VITAMIN D (ERGOCALCIFEROL)": "VITAMIN D (ERGOCALCIFEROL)"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "DRISDOL": "DRISDOL"
            },
            "HEALTHY ORIGINS": {
              "VITAMIN D3": "VITAMIN D3"
            },
            "HI-TECH": {
              "VITAMIN D": "VITAMIN D"
            },
            "HILLESTAD PHARMACEUTICALS": {
              "DIALYVITE VITAMIN D 5000": "DIALYVITE VITAMIN D 5000",
              "DIALYVITE VITAMIN D3 MAX": "DIALYVITE VITAMIN D3 MAX"
            },
            "INTEGRATIVE THERAPEUTICS": {
              "VITAMIN D3": "VITAMIN D3"
            },
            "J R CARLSON LABORATORIES": {
              "BABY DDROPS": "BABY DDROPS",
              "BABY SUPER DAILY D3": "BABY SUPER DAILY D3",
              "DDROPS": "DDROPS",
              "SUPER DAILY D3": "SUPER DAILY D3",
              "VITAMIN D3": "VITAMIN D3"
            },
            "KAISER FOUNDATION HOSPITAL": {
              "KP VITAMIN D": "KP VITAMIN D",
              "KP VITAMIN D3": "KP VITAMIN D3"
            },
            "LEADER BRAND PRODUCTS": {
              "D 1000": "D 1000",
              "D 400": "D 400",
              "D 5000": "D 5000",
              "D3 HIGH POTENCY": "D3 HIGH POTENCY",
              "D3 SUPER STRENGTH": "D3 SUPER STRENGTH",
              "VITAMIN D3": "VITAMIN D3"
            },
            "M J NUTR": {
              "D-VI-SOL": "D-VI-SOL"
            },
            "MAGNO-HUMPHRIES LABORATORIES": {
              "VITAMIN D": "VITAMIN D",
              "VITAMIN D (CHOLECALCIFEROL)": "VITAMIN D (CHOLECALCIFEROL)",
              "VITAMIN D3": "VITAMIN D3"
            },
            "MAJOR PHARMACEUTICALS": {
              "D-VITA": "D-VITA",
              "VITAMIN D": "VITAMIN D",
              "VITAMIN D3": "VITAMIN D3"
            },
            "MASON VITAMINS": {
              "D 1000": "D 1000",
              "D 10000": "D 10000",
              "D 400": "D 400",
              "D 5000": "D 5000",
              "D2000 ULTRA STRENGTH": "D2000 ULTRA STRENGTH",
              "HEALTHY KIDS VITAMIN D3": "HEALTHY KIDS VITAMIN D3",
              "VITAMIN D3": "VITAMIN D3"
            },
            "MCKESSON": {
              "HM VITAMIN D": "HM VITAMIN D",
              "HM VITAMIN D3": "HM VITAMIN D3"
            },
            "MCKESSON SUNMARK": {
              "SM VITAMIN D": "SM VITAMIN D",
              "SM VITAMIN D3": "SM VITAMIN D3"
            },
            "MCLAREN MEDICAL": {
              "VITAMIN D3": "VITAMIN D3"
            },
            "MEDECOR PHARMA": {
              "DECARA": "DECARA"
            },
            "MEDICINE SHOPPE": {
              "VITAMIN D (CHOLECALCIFEROL)": "VITAMIN D (CHOLECALCIFEROL)"
            },
            "MOM ENTERPRISES": {
              "MOMMY\\'S BLISS VIT D ORGANIC": "MOMMY\\'S BLISS VIT D ORGANIC"
            },
            "NAT-RUL HEALTH PRODUCTS": {
              "NAT-RUL VITAMIN D": "NAT-RUL VITAMIN D",
              "VITAMIN D (CHOLECALCIFEROL)": "VITAMIN D (CHOLECALCIFEROL)"
            },
            "NATIONAL VITAMIN": {
              "VITAMIN D3": "VITAMIN D3",
              "VITAMIN D3 SUPER STRENGTH": "VITAMIN D3 SUPER STRENGTH"
            },
            "NATROL": {
              "VITAMIN D3": "VITAMIN D3"
            },
            "NATURES BOUNTY": {
              "D3 MAXIMUM STRENGTH": "D3 MAXIMUM STRENGTH",
              "D3 SUPER STRENGTH": "D3 SUPER STRENGTH",
              "D3-1000": "D3-1000",
              "VITAMIN D-400": "VITAMIN D-400",
              "VITAMIN D3": "VITAMIN D3"
            },
            "NIVAGEN PHARMACEUTICALS": {
              "ERGOCALCIFEROL": "ERGOCALCIFEROL",
              "VITAMIN D3": "VITAMIN D3",
              "WEEKLY-D": "WEEKLY-D"
            },
            "NNODUM CORPORATION": {
              "VITAMIN D3": "VITAMIN D3"
            },
            "NOW HEALTH GROUP": {
              "VITAMIN D3": "VITAMIN D3"
            },
            "NUCARE PHARMACEUTICALS": {
              "VITAMIN D3": "VITAMIN D3"
            },
            "NUTRACEUTICS": {
              "REPLESTA": "REPLESTA",
              "REPLESTA CHILDRENS": "REPLESTA CHILDRENS",
              "REPLESTA NX": "REPLESTA NX"
            },
            "PATRIN PHARMA": {
              "CALCIDOL": "CALCIDOL"
            },
            "PCCA": {
              "ERGOCALCIFEROL": "ERGOCALCIFEROL"
            },
            "PDRX PHARMACEUTICAL": {
              "D3 SUPER STRENGTH": "D3 SUPER STRENGTH",
              "VITAMIN D (ERGOCALCIFEROL)": "VITAMIN D (ERGOCALCIFEROL)"
            },
            "PHARMA NATURAL": {
              "VITAMIN D3": "VITAMIN D3"
            },
            "PHARMAVITE": {
              "D3 ADULT": "D3 ADULT",
              "D3 KIDS": "D3 KIDS",
              "D3-1000": "D3-1000",
              "KIDS FIRST VITAMIN D3 GUMMIES": "KIDS FIRST VITAMIN D3 GUMMIES",
              "VITAMELTS VITAMIN D": "VITAMELTS VITAMIN D",
              "VITAMIN D": "VITAMIN D",
              "VITAMIN D (CHOLECALCIFEROL)": "VITAMIN D (CHOLECALCIFEROL)",
              "VITAMIN D3": "VITAMIN D3",
              "VITAMIN D3 ADULT GUMMIES": "VITAMIN D3 ADULT GUMMIES",
              "VITAMIN D3 GUMMIES ADULT": "VITAMIN D3 GUMMIES ADULT"
            },
            "PLUS PHARMA": {
              "VITAMIN D": "VITAMIN D",
              "VITAMIN D3": "VITAMIN D3"
            },
            "PREFERRED PHARMACEUTICALS": {
              "VITAMIN D (ERGOCALCIFEROL)": "VITAMIN D (ERGOCALCIFEROL)"
            },
            "PRINCETON RESEARCH": {
              "D3 HIGH POTENCY": "D3 HIGH POTENCY",
              "VITAMIN D3": "VITAMIN D3"
            },
            "PRO-PHARMA LLC": {
              "MAXIMUM D3": "MAXIMUM D3"
            },
            "PROFICIENT RX": {
              "VITAMIN D (ERGOCALCIFEROL)": "VITAMIN D (ERGOCALCIFEROL)"
            },
            "PROSYNTHESIS LABORATORIES": {
              "OPURITY VITAMIN D": "OPURITY VITAMIN D"
            },
            "PURE ENCAPSULATIONS": {
              "CHOLECALCIFEROL": "CHOLECALCIFEROL"
            },
            "QUALITY CARE": {
              "VITAMIN D (ERGOCALCIFEROL)": "VITAMIN D (ERGOCALCIFEROL)",
              "VITAMIN D3": "VITAMIN D3"
            },
            "RELIABLE 1 LABS": {
              "VITAMIN D INFANT": "VITAMIN D INFANT",
              "VITAMIN D3": "VITAMIN D3"
            },
            "REXALL SUNDOWN": {
              "D3 MAXIMUM STRENGTH": "D3 MAXIMUM STRENGTH",
              "VITAMIN D HIGH POTENCY": "VITAMIN D HIGH POTENCY",
              "VITAMIN D3": "VITAMIN D3",
              "VITAMIN D3 GUMMIES": "VITAMIN D3 GUMMIES",
              "VITAMIN D3 HIGH POTENCY": "VITAMIN D3 HIGH POTENCY"
            },
            "RISING PHARMACEUTICALS": {
              "VITAMIN D (ERGOCALCIFEROL)": "VITAMIN D (ERGOCALCIFEROL)"
            },
            "RITE AID CORPORATION": {
              "PA VITAMIN D-3": "PA VITAMIN D-3",
              "PA VITAMIN D-3 GUMMY": "PA VITAMIN D-3 GUMMY",
              "RA VITAMIN D-3": "RA VITAMIN D-3"
            },
            "RUGBY LABORATORIES": {
              "D3 VITAMIN": "D3 VITAMIN",
              "VITAMIN D": "VITAMIN D",
              "VITAMIN D3": "VITAMIN D3"
            },
            "RV NUTRITIONAL": {
              "OPTIMAL D3 M": "OPTIMAL D3 M",
              "OPTIMAL-D": "OPTIMAL-D",
              "VITAMIN D3": "VITAMIN D3"
            },
            "SAFECOR HEALTH": {
              "VITAMIN D3": "VITAMIN D3"
            },
            "SANOFI-AVENTIS U.S.": {
              "DRISDOL": "DRISDOL"
            },
            "SCHWABE NORTH AMERICA": {
              "VITAMIN D3 IMMUNE HEALTH": "VITAMIN D3 IMMUNE HEALTH"
            },
            "SECOND WIND VITAMINS": {
              "VITAMIN D3": "VITAMIN D3"
            },
            "SILARX": {
              "AQUEOUS VITAMIN D": "AQUEOUS VITAMIN D"
            },
            "SIMPLE DIAGNOSTICS": {
              "PHARMACIST CHOICE D-VITAMIN": "PHARMACIST CHOICE D-VITAMIN"
            },
            "ST MARY'S MPP": {
              "VITAMIN D (ERGOCALCIFEROL)": "VITAMIN D (ERGOCALCIFEROL)"
            },
            "STERLING KNIGHT PHARMACEUTICAL": {
              "ERGOCAL": "ERGOCAL"
            },
            "STRATUS PHARMACEUTICALS": {
              "ERGOCALCIFEROL": "ERGOCALCIFEROL"
            },
            "STRIDES PHARMA": {
              "VITAMIN D (ERGOCALCIFEROL)": "VITAMIN D (ERGOCALCIFEROL)"
            },
            "SUN PHARMACEUTICALS": {
              "VITAMIN D (ERGOCALCIFEROL)": "VITAMIN D (ERGOCALCIFEROL)"
            },
            "TEVA PHARMACEUTICALS USA": {
              "VITAMIN D (ERGOCALCIFEROL)": "VITAMIN D (ERGOCALCIFEROL)"
            },
            "THERALOGIX": {
              "THERA-D 2000": "THERA-D 2000",
              "THERA-D 4000": "THERA-D 4000",
              "THERA-D RAPID REPLETION": "THERA-D RAPID REPLETION",
              "THERA-D SPORT": "THERA-D SPORT"
            },
            "TRIGEN LABORATORIES": {
              "ERGOCALCIFEROL": "ERGOCALCIFEROL"
            },
            "TWIN LABS": {
              "D3 DOTS": "D3 DOTS"
            },
            "UCB PHARMA": {
              "CALCIFEROL": "CALCIFEROL"
            },
            "UNITDRUGCO": {
              "JUST D": "JUST D"
            },
            "UPSPRING": {
              "UPSPRING BABY VIT D": "UPSPRING BABY VIT D"
            },
            "VALIDUS PHARMACEUTICALS": {
              "DRISDOL": "DRISDOL"
            },
            "VIRTUS PHARMACEUTICALS": {
              "ERGOCALCIFEROL": "ERGOCALCIFEROL"
            },
            "VITAMEDMD": {
              "VITAMIN D3": "VITAMIN D3"
            },
            "WAL-MART": {
              "D3 ADULT": "D3 ADULT",
              "D3 MAXIMUM STRENGTH": "D3 MAXIMUM STRENGTH",
              "EQ D3 DROPS INFANTS/CHILDRENS": "EQ D3 DROPS INFANTS/CHILDRENS",
              "VITAMIN D-3": "VITAMIN D-3",
              "VITAMIN D3": "VITAMIN D3",
              "VITAMIN D3 MAXIMUM STRENGTH": "VITAMIN D3 MAXIMUM STRENGTH"
            },
            "WALGREENS": {
              "BABY VITAMIN D3": "BABY VITAMIN D3",
              "VITAMIN D (CHOLECALCIFEROL)": "VITAMIN D (CHOLECALCIFEROL)",
              "VITAMIN D3": "VITAMIN D3"
            },
            "WESTMINSTER PHARMACEUTICALS": {
              "ERGOCALCIFEROL": "ERGOCALCIFEROL"
            },
            "WINDMILL CONSUMER PRODUCTS": {
              "D-1000": "D-1000",
              "D-400": "D-400",
              "VITAMIN D (CHOLECALCIFEROL)": "VITAMIN D (CHOLECALCIFEROL)",
              "VITAMIN D-3": "VITAMIN D-3"
            }
          },
          "Vitamin E": {
            "21ST CENTURY HEALTHCARE": {
              "E-200": "E-200",
              "E-400": "E-400",
              "VITAMIN E": "VITAMIN E"
            },
            "A-S MEDICATION SOLUTIONS": {
              "VITAMIN E": "VITAMIN E"
            },
            "AUBURN PHARMACEUTICAL": {
              "VITAMIN E": "VITAMIN E"
            },
            "AVPAK": {
              "E-200": "E-200",
              "VITAMIN SUPPLEMENT E-1000": "VITAMIN SUPPLEMENT E-1000",
              "VITAMIN SUPPLEMENT E-400": "VITAMIN SUPPLEMENT E-400"
            },
            "BASIC DRUGS": {
              "VITAMIN E": "VITAMIN E"
            },
            "BERGEN BRUNSWIG": {
              "GNP VITAMIN E": "GNP VITAMIN E"
            },
            "BIO-TECH": {
              "E-400-CLEAR": "E-400-CLEAR",
              "E-400-MIXED": "E-400-MIXED",
              "E-MAX-1000": "E-MAX-1000"
            },
            "BIONPHARMA": {
              "NUTR-E-SOL": "NUTR-E-SOL"
            },
            "BRANDYWINE PHARMACEUTICALS": {
              "VITAMIN E": "VITAMIN E"
            },
            "BRONSON PHARMACEUTICALS": {
              "VITAMIN E": "VITAMIN E"
            },
            "CALLION PHARMA": {
              "AQUA-E": "AQUA-E"
            },
            "CHAIN DRUG CONSORTIUM": {
              "E400": "E400",
              "VITAMIN E": "VITAMIN E"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC VITAMIN E": "QC VITAMIN E"
            },
            "CHRONOHEALTH": {
              "VITAMIN E": "VITAMIN E"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS E": "CVS E",
              "CVS E OIL": "CVS E OIL",
              "CVS VITAMIN E": "CVS VITAMIN E"
            },
            "CYPRESS PHARMACEUTICAL": {
              "VITAMIN E": "VITAMIN E"
            },
            "ENCORE SCIENTIFIC": {
              "VITAMIN E": "VITAMIN E"
            },
            "EQUALINE": {
              "EQL VITAMIN E": "EQL VITAMIN E"
            },
            "FREEDA VITAMINS": {
              "NATURAL VITAMIN E": "NATURAL VITAMIN E",
              "VITAMIN E": "VITAMIN E"
            },
            "GENDOSE PHARMACEUTICALS": {
              "VITAMIN E": "VITAMIN E"
            },
            "GERI-CARE": {
              "VITAMIN E": "VITAMIN E"
            },
            "GERITREX CORPORATION": {
              "VITAMIN E": "VITAMIN E"
            },
            "HANNAFORD BROTHERS": {
              "VITAMIN E": "VITAMIN E"
            },
            "INTEGRATIVE THERAPEUTICS": {
              "VITAMIN E": "VITAMIN E"
            },
            "J R CARLSON LABORATORIES": {
              "KEY-E": "KEY-E"
            },
            "KAISER FOUNDATION HOSPITAL": {
              "KP VITAMIN E": "KP VITAMIN E"
            },
            "LEADER BRAND PRODUCTS": {
              "VITAMIN E": "VITAMIN E"
            },
            "LEINER HEALTH PRODUCTS": {
              "YL VITAMIN E": "YL VITAMIN E"
            },
            "LETCO MEDICAL": {
              "VITAMIN E": "VITAMIN E"
            },
            "LIBERTY PHARMACEUTICAL": {
              "VITAMIN E": "VITAMIN E"
            },
            "MAGNO-HUMPHRIES LABORATORIES": {
              "VITAMIN E": "VITAMIN E",
              "VITAMIN E-200": "VITAMIN E-200",
              "VITAMIN E-400": "VITAMIN E-400"
            },
            "MAJOR PHARMACEUTICALS": {
              "E-400": "E-400",
              "VITAMIN E": "VITAMIN E",
              "VITAMIN E-200": "VITAMIN E-200"
            },
            "MARLEX PHARMACEUTICALS": {
              "VITAMIN E": "VITAMIN E"
            },
            "MASON VITAMINS": {
              "E 1000": "E 1000",
              "E-OIL": "E-OIL",
              "E1000": "E1000",
              "E200": "E200",
              "E400": "E400",
              "E400 MIXED": "E400 MIXED",
              "E600": "E600",
              "VITAMIN E/D-ALPHA NATURAL": "VITAMIN E/D-ALPHA NATURAL"
            },
            "MCKESSON": {
              "HM E VITAMIN": "HM E VITAMIN",
              "HM VITAMIN E": "HM VITAMIN E"
            },
            "MCKESSON SUNMARK": {
              "SM VITAMIN E": "SM VITAMIN E"
            },
            "MEDICINE SHOPPE": {
              "VITAMIN E": "VITAMIN E"
            },
            "MEIJER": {
              "VITAMIN E": "VITAMIN E"
            },
            "MILLER": {
              "FORMULA E 400": "FORMULA E 400"
            },
            "NAT-RUL HEALTH PRODUCTS": {
              "DRY E-SYNTHETIC": "DRY E-SYNTHETIC",
              "NATURAL BLEND E-400": "NATURAL BLEND E-400",
              "NATURAL VITAMIN E": "NATURAL VITAMIN E",
              "VITAMIN E": "VITAMIN E"
            },
            "NATIONAL VITAMIN": {
              "VITAMIN E": "VITAMIN E"
            },
            "NATURES BOUNTY": {
              "E-OIL": "E-OIL",
              "ESTER-E": "ESTER-E",
              "VITAMIN E": "VITAMIN E",
              "VITAMIN E COMPLEX": "VITAMIN E COMPLEX",
              "VITAMIN E/D-ALPHA NATURAL": "VITAMIN E/D-ALPHA NATURAL"
            },
            "PATRIN PHARMA": {
              "SOLUVITA E": "SOLUVITA E"
            },
            "PCCA": {
              "VITAMIN E": "VITAMIN E",
              "WHEAT GERM OIL": "WHEAT GERM OIL"
            },
            "PDRX PHARMACEUTICAL": {
              "VITAMIN E": "VITAMIN E"
            },
            "PHARMASSURE, INC.": {
              "VITAMIN E": "VITAMIN E"
            },
            "PHARMAVITE": {
              "ADVANCED E": "ADVANCED E",
              "VITAMIN E": "VITAMIN E",
              "VITAMIN E/D-ALPHA NATURAL": "VITAMIN E/D-ALPHA NATURAL"
            },
            "PRINCETON RESEARCH": {
              "VITAMIN E HIGH POTENCY": "VITAMIN E HIGH POTENCY"
            },
            "PUBLIX SUPER MARKETS INC.": {
              "PX VITAMIN E": "PX VITAMIN E"
            },
            "REXALL SUNDOWN": {
              "VITAMIN E": "VITAMIN E"
            },
            "RICHMOND PHARMACEUTICALS": {
              "VITAMIN E": "VITAMIN E"
            },
            "RITE AID CORPORATION": {
              "PA VITAMIN E": "PA VITAMIN E",
              "RA NATURAL VITAMIN E": "RA NATURAL VITAMIN E",
              "RA VITAMIN E": "RA VITAMIN E",
              "RA VITAMIN E BLEND": "RA VITAMIN E BLEND",
              "RA VITAMIN E NATURAL": "RA VITAMIN E NATURAL"
            },
            "RUGBY LABORATORIES": {
              "E-400": "E-400",
              "VITAMIN E": "VITAMIN E"
            },
            "SCOT-TUSSIN": {
              "VITA-PLUS E": "VITA-PLUS E"
            },
            "SILARX": {
              "AQUEOUS VITAMIN E": "AQUEOUS VITAMIN E"
            },
            "SPECTRUM": {
              "WHEAT GERM OIL": "WHEAT GERM OIL"
            },
            "THE KEY COMPANY": {
              "ALPH-E": "ALPH-E",
              "ALPH-E-MIXED": "ALPH-E-MIXED",
              "ALPH-E-MIXED 1000": "ALPH-E-MIXED 1000",
              "VITAMIN E": "VITAMIN E"
            },
            "TWIN LABS": {
              "LIQUI-E": "LIQUI-E",
              "MAXILIFE RICE TOCOTRIENOLS": "MAXILIFE RICE TOCOTRIENOLS"
            },
            "VITALINE": {
              "E-PHEROL": "E-PHEROL",
              "VITAMIN E": "VITAMIN E"
            },
            "WAL-MART": {
              "NATURAL VITAMIN E": "NATURAL VITAMIN E",
              "VITAMIN E": "VITAMIN E",
              "VITAMIN E WATER SOLUBLE": "VITAMIN E WATER SOLUBLE"
            },
            "WALGREENS": {
              "VITAMIN E": "VITAMIN E",
              "VITAMIN E BLEND": "VITAMIN E BLEND",
              "VITAMIN E/D-ALPHA": "VITAMIN E/D-ALPHA"
            },
            "WINDMILL CONSUMER PRODUCTS": {
              "E-1000": "E-1000",
              "E-200": "E-200",
              "E-400": "E-400",
              "NATURAL VITAMIN E": "NATURAL VITAMIN E"
            }
          },
          "Vitamin K": {
            "A-S MEDICATION SOLUTIONS": {
              "MEPHYTON": "MEPHYTON"
            },
            "AMERICAN HEALTH PACKAGING": {
              "PHYTONADIONE": "PHYTONADIONE"
            },
            "AMNEAL PHARMACEUTICALS": {
              "PHYTONADIONE": "PHYTONADIONE"
            },
            "BAUSCH HEALTH": {
              "MEPHYTON": "MEPHYTON"
            },
            "BIO-TECH": {
              "K1-1000": "K1-1000"
            },
            "CALLION PHARMA": {
              "AQUA-K": "AQUA-K"
            },
            "CONTINENTAL VITAMIN COMPANY": {
              "SUPERIORSOURCE K1": "SUPERIORSOURCE K1"
            },
            "DR.REDDY'S LABORATORIES, INC.": {
              "PHYTONADIONE": "PHYTONADIONE"
            },
            "FREEDA VITAMINS": {
              "VITAMIN K2": "VITAMIN K2"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "MEPHYTON": "MEPHYTON"
            },
            "HOSPIRA": {
              "VITAMIN K1": "VITAMIN K1"
            },
            "INTRNTL MEDICATION SYSTEM": {
              "PHYTONADIONE": "PHYTONADIONE"
            },
            "MAJOR PHARMACEUTICALS": {
              "PHYTONADIONE": "PHYTONADIONE"
            },
            "MASON VITAMINS": {
              "K 100": "K 100",
              "VITAMIN K2": "VITAMIN K2"
            },
            "NATIONAL VITAMIN": {
              "VITAMIN K (PHYTONADIONE)": "VITAMIN K (PHYTONADIONE)"
            },
            "NORTHSTAR RX": {
              "PHYTONADIONE": "PHYTONADIONE"
            },
            "NOVAPLUS/HOSPIRA": {
              "VITAMIN K1": "VITAMIN K1"
            },
            "OCEANSIDE PHARMACEUTICALS": {
              "PHYTONADIONE": "PHYTONADIONE"
            },
            "PDRX PHARMACEUTICAL": {
              "MEPHYTON": "MEPHYTON"
            },
            "PHARMA NATURAL": {
              "VITAMIN K": "VITAMIN K"
            },
            "PHARMAVITE": {
              "VITAMIN K2": "VITAMIN K2"
            },
            "QUALITY CARE": {
              "MEPHYTON": "MEPHYTON"
            },
            "ZYDUS PHARMACEUTICALS (USA)": {
              "PHYTONADIONE": "PHYTONADIONE"
            }
          }
        },
        "Water Soluble Vitamins": {
          "Biotin": {
            "21ST CENTURY HEALTHCARE": {
              "BIOTIN": "BIOTIN",
              "BIOTIN MAXIMUM STRENGTH": "BIOTIN MAXIMUM STRENGTH"
            },
            "BASIC DRUGS": {
              "BIOTIN": "BIOTIN"
            },
            "BERGEN BRUNSWIG": {
              "GNP BIOTIN": "GNP BIOTIN"
            },
            "CHAIN DRUG CONSORTIUM": {
              "BIOTIN": "BIOTIN"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC BIOTIN": "QC BIOTIN"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS BIOTIN": "CVS BIOTIN",
              "CVS BIOTIN HIGH POTENCY": "CVS BIOTIN HIGH POTENCY"
            },
            "EQUALINE": {
              "EQL BIOTIN": "EQL BIOTIN"
            },
            "FREEDA VITAMINS": {
              "BIOTIN": "BIOTIN"
            },
            "HILLESTAD PHARMACEUTICALS": {
              "BIOTIN": "BIOTIN"
            },
            "J R CARLSON LABORATORIES": {
              "BIOTIN": "BIOTIN"
            },
            "LEADER BRAND PRODUCTS": {
              "BIOTIN": "BIOTIN"
            },
            "MAJOR PHARMACEUTICALS": {
              "BIOTIN": "BIOTIN"
            },
            "MASON VITAMINS": {
              "MEGA BIOTIN": "MEGA BIOTIN",
              "SUPER BIOTIN": "SUPER BIOTIN"
            },
            "MCKESSON": {
              "HM BIOTIN": "HM BIOTIN"
            },
            "MCKESSON SUNMARK": {
              "SM BIOTIN": "SM BIOTIN"
            },
            "MEDIMETRIKS PHARMACEUTICALS": {
              "LEXINAL": "LEXINAL"
            },
            "MERICON": {
              "HARD NAILS": "HARD NAILS",
              "MERIBIN": "MERIBIN"
            },
            "NAT-RUL HEALTH PRODUCTS": {
              "BIOTIN": "BIOTIN"
            },
            "NATIONAL VITAMIN": {
              "BIOTIN": "BIOTIN",
              "BIOTIN EXTRA STRENGTH": "BIOTIN EXTRA STRENGTH"
            },
            "NATROL": {
              "BIOTIN MAXIMUM STRENGTH": "BIOTIN MAXIMUM STRENGTH"
            },
            "NATURE'S WAY PRODUCTS": {
              "BIOTIN FORTE": "BIOTIN FORTE"
            },
            "NATURES BOUNTY": {
              "BIOTIN": "BIOTIN"
            },
            "PHARMA NATURAL": {
              "BIOTIN": "BIOTIN"
            },
            "PHARMASSURE, INC.": {
              "BIOTIN": "BIOTIN"
            },
            "PHARMAVITE": {
              "BIOTIN": "BIOTIN"
            },
            "PRINCETON RESEARCH": {
              "BIOTIN": "BIOTIN",
              "BIOTIN MAXIMUM": "BIOTIN MAXIMUM"
            },
            "REXALL SUNDOWN": {
              "BIOTIN": "BIOTIN",
              "BIOTIN 5000": "BIOTIN 5000"
            },
            "RISING PHARMACEUTICALS": {
              "NAIL-EX": "NAIL-EX"
            },
            "RITE AID CORPORATION": {
              "PA BIOTIN": "PA BIOTIN",
              "RA BIOTIN": "RA BIOTIN"
            },
            "RUGBY LABORATORIES": {
              "BIOTIN": "BIOTIN"
            },
            "SOLACE NUTRITION": {
              "CYTO B7": "CYTO B7",
              "VB7 MAX": "VB7 MAX"
            },
            "TOPCO": {
              "BIOTIN": "BIOTIN"
            },
            "WAL-MART": {
              "BIOTIN": "BIOTIN"
            },
            "WALGREENS": {
              "BIOTIN": "BIOTIN"
            },
            "WINDMILL CONSUMER PRODUCTS": {
              "BIOTIN": "BIOTIN"
            }
          },
          "PABA": {
            "B & B PHARMACEUTICALS": {
              "POTASSIUM AMINOBENZOATE": "POTASSIUM AMINOBENZOATE"
            },
            "CYPRESS PHARMACEUTICAL": {
              "AMINOBENZOATE POTASSIUM": "AMINOBENZOATE POTASSIUM"
            },
            "FREEDA VITAMINS": {
              "PABA": "PABA"
            },
            "GLENWOOD": {
              "POTABA": "POTABA"
            },
            "PCCA": {
              "PARA-AMINOBENZOIC ACID": "PARA-AMINOBENZOIC ACID",
              "POTASSIUM AMINOBENZOATE": "POTASSIUM AMINOBENZOATE"
            },
            "SPECTRUM": {
              "AMINOBENZOIC ACID": "AMINOBENZOIC ACID"
            }
          },
          "Vitamin B-1": {
            "21ST CENTURY HEALTHCARE": {
              "B-1": "B-1"
            },
            "A-S MEDICATION SOLUTIONS": {
              "THIAMINE HCL": "THIAMINE HCL",
              "THIAMINE MONONITRATE": "THIAMINE MONONITRATE"
            },
            "AMERICAN HEALTH PACKAGING": {
              "B-1": "B-1"
            },
            "AMERICAN PHARMA INGREDIENTS": {
              "THIAMINE HCL": "THIAMINE HCL"
            },
            "AUBURN PHARMACEUTICAL": {
              "THIAMINE HCL": "THIAMINE HCL",
              "VITAMIN B-1": "VITAMIN B-1"
            },
            "AVPAK": {
              "THIAMINE MONONITRATE": "THIAMINE MONONITRATE"
            },
            "BASIC DRUGS": {
              "THIAMINE HCL": "THIAMINE HCL"
            },
            "BENFOTIAMINE INC": {
              "BENFOTIAMINE": "BENFOTIAMINE",
              "BENFOTIAMINE-V": "BENFOTIAMINE-V"
            },
            "BERGEN BRUNSWIG": {
              "GNP VITAMIN B-1": "GNP VITAMIN B-1",
              "GNP VITAMIN B1": "GNP VITAMIN B1"
            },
            "BIO-TECH": {
              "THIAMINE": "THIAMINE"
            },
            "CHAIN DRUG CONSORTIUM": {
              "VITAMIN B-1": "VITAMIN B-1"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC VITAMIN B1": "QC VITAMIN B1"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS B-1": "CVS B-1"
            },
            "DOMEL LABORATORIES": {
              "ARKALIOX": "ARKALIOX"
            },
            "FAGRON": {
              "THIAMINE HCL": "THIAMINE HCL"
            },
            "FREEDA VITAMINS": {
              "THIAMINE HCL": "THIAMINE HCL",
              "VITAMIN B-1": "VITAMIN B-1"
            },
            "FREEDOM PHARMACEUTICALS": {
              "THIAMINE HCL": "THIAMINE HCL"
            },
            "FRESENIUS KABI USA": {
              "THIAMINE HCL": "THIAMINE HCL"
            },
            "GENDOSE PHARMACEUTICALS": {
              "THIAMINE MONONITRATE": "THIAMINE MONONITRATE"
            },
            "GERI-CARE": {
              "THIAMINE HCL": "THIAMINE HCL"
            },
            "JJ BALAN": {
              "THIAMINE HCL": "THIAMINE HCL",
              "VITAMIN B-1": "VITAMIN B-1"
            },
            "LEADER BRAND PRODUCTS": {
              "THIAMINE HCL": "THIAMINE HCL",
              "THIAMINE MONONITRATE": "THIAMINE MONONITRATE"
            },
            "LETCO MEDICAL": {
              "THIAMINE HCL": "THIAMINE HCL"
            },
            "LIBERTY PHARMACEUTICAL": {
              "THIAMINE HCL": "THIAMINE HCL"
            },
            "MAGNO-HUMPHRIES LABORATORIES": {
              "THIAMINE MONONITRATE": "THIAMINE MONONITRATE",
              "VITAMIN B-1": "VITAMIN B-1"
            },
            "MAJOR PHARMACEUTICALS": {
              "THIAMINE HCL": "THIAMINE HCL"
            },
            "MARLEX PHARMACEUTICALS": {
              "THIAMINE HCL": "THIAMINE HCL"
            },
            "MASON VITAMINS": {
              "B-1": "B-1"
            },
            "MCKESSON": {
              "HM VITAMIN B1": "HM VITAMIN B1"
            },
            "MCKESSON SUNMARK": {
              "SM VITAMIN B1": "SM VITAMIN B1"
            },
            "MEDISCA": {
              "THIAMINE HCL": "THIAMINE HCL"
            },
            "MYLAN INSTITUTIONAL": {
              "THIAMINE HCL": "THIAMINE HCL"
            },
            "NAT-RUL HEALTH PRODUCTS": {
              "THIAMINE HCL": "THIAMINE HCL",
              "VITAMIN B-1": "VITAMIN B-1"
            },
            "NATIONAL VITAMIN": {
              "THIAMINE MONONITRATE": "THIAMINE MONONITRATE",
              "VITAMIN B-1": "VITAMIN B-1"
            },
            "NATURES BOUNTY": {
              "THIAMINE HCL": "THIAMINE HCL"
            },
            "PCCA": {
              "THIAMINE HCL": "THIAMINE HCL"
            },
            "PDRX PHARMACEUTICAL": {
              "THIAMINE MONONITRATE": "THIAMINE MONONITRATE"
            },
            "PDRX PHARMACEUTICALS": {
              "THIAMINE MONONITRATE": "THIAMINE MONONITRATE"
            },
            "PHARMAVITE": {
              "THIAMINE HCL": "THIAMINE HCL"
            },
            "PLUS PHARMA": {
              "THIAMINE MONONITRATE": "THIAMINE MONONITRATE"
            },
            "PRINCETON RESEARCH": {
              "B-1": "B-1"
            },
            "REXALL SUNDOWN": {
              "B-1 HIGH POTENCY": "B-1 HIGH POTENCY"
            },
            "RITE AID CORPORATION": {
              "RA VITAMIN B-1": "RA VITAMIN B-1"
            },
            "RUGBY LABORATORIES": {
              "THIAMINE HCL": "THIAMINE HCL",
              "VITAMIN B-1": "VITAMIN B-1",
              "VITAMIN B1": "VITAMIN B1"
            },
            "SAFECOR HEALTH": {
              "THIAMINE HCL": "THIAMINE HCL"
            },
            "SOLACE NUTRITION": {
              "CYTO B1": "CYTO B1"
            },
            "SPECTRUM": {
              "THIAMINE HCL": "THIAMINE HCL",
              "THIAMINE MONONITRATE": "THIAMINE MONONITRATE"
            },
            "WAL-MART": {
              "B1 NATURAL": "B1 NATURAL"
            },
            "WALGREENS": {
              "THIAMINE HCL": "THIAMINE HCL",
              "THIAMINE MONONITRATE": "THIAMINE MONONITRATE"
            },
            "WINDMILL CONSUMER PRODUCTS": {
              "B-1": "B-1"
            }
          },
          "Vitamin B-2": {
            "BASIC DRUGS": {
              "VITAMIN B-2": "VITAMIN B-2"
            },
            "BIO-TECH": {
              "B-2-400": "B-2-400",
              "RIBOFLAVIN": "RIBOFLAVIN"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS VITAMIN B-2": "CVS VITAMIN B-2"
            },
            "ENZYMATIC THERAPY": {
              "RIBOFLAVIN": "RIBOFLAVIN"
            },
            "FREEDA VITAMINS": {
              "VITAMIN B-2": "VITAMIN B-2"
            },
            "GENERAL NUTRITION CORP": {
              "VITAMIN B-2": "VITAMIN B-2"
            },
            "J R CARLSON LABORATORIES": {
              "B-2": "B-2"
            },
            "MAGNO-HUMPHRIES LABORATORIES": {
              "VITAMIN B-2": "VITAMIN B-2"
            },
            "MASON VITAMINS": {
              "B-2": "B-2"
            },
            "NAT-RUL HEALTH PRODUCTS": {
              "VITAMIN B-2": "VITAMIN B-2"
            },
            "NATIONAL VITAMIN": {
              "VITAMIN B-2": "VITAMIN B-2"
            },
            "NATURES BOUNTY": {
              "VITAMIN B-2": "VITAMIN B-2"
            },
            "PLUS PHARMA": {
              "VITAMIN B-2": "VITAMIN B-2"
            },
            "PRINCETON RESEARCH": {
              "B-2": "B-2"
            },
            "REXALL SUNDOWN": {
              "B2": "B2"
            },
            "SOLACE NUTRITION": {
              "CYTO B2": "CYTO B2"
            },
            "TWIN LABS": {
              "RIBOFLAVIN": "RIBOFLAVIN"
            },
            "WINDMILL CONSUMER PRODUCTS": {
              "B-2": "B-2"
            }
          },
          "Vitamin B-3": {
            "21ST CENTURY HEALTHCARE": {
              "NIACIN": "NIACIN",
              "NIACIN ER": "NIACIN ER",
              "NIACINAMIDE": "NIACINAMIDE"
            },
            "A-S MEDICATION SOLUTIONS": {
              "NIACIN ER": "NIACIN ER"
            },
            "AIDAREX PHARMACEUTICALS": {
              "NIACIN ER": "NIACIN ER"
            },
            "AMERICAN PHARMA INGREDIENTS": {
              "NIACINAMIDE": "NIACINAMIDE"
            },
            "APHENA PHARMA SOLUTIONS": {
              "NIACIN ER": "NIACIN ER",
              "SLO-NIACIN": "SLO-NIACIN"
            },
            "APOTHECA SUPPLY": {
              "NIACIN": "NIACIN",
              "NIACINAMIDE": "NIACINAMIDE"
            },
            "AVPAK": {
              "NIACIN": "NIACIN",
              "NIACIN ER": "NIACIN ER"
            },
            "BASIC DRUGS": {
              "NIACIN": "NIACIN",
              "NIACIN ER": "NIACIN ER"
            },
            "BASIC ORGANICS": {
              "NIACINAMIDE": "NIACINAMIDE"
            },
            "BERGEN BRUNSWIG": {
              "GNP NIACIN": "GNP NIACIN",
              "GNP NIACIN TR": "GNP NIACIN TR"
            },
            "BRONSON PHARMACEUTICALS": {
              "NIACIN ER": "NIACIN ER"
            },
            "CHAIN DRUG CONSORTIUM": {
              "NIACIN": "NIACIN",
              "NIACIN ER": "NIACIN ER",
              "NIACINAMIDE": "NIACINAMIDE"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC NIACIN": "QC NIACIN"
            },
            "CLINICAL SOLUTIONS WHOLESALE": {
              "NIACIN ER": "NIACIN ER"
            },
            "CONTRACT PHARMACAL CORPORATION": {
              "NIACIN": "NIACIN"
            },
            "ENCORE SCIENTIFIC": {
              "NIACIN": "NIACIN"
            },
            "ENDURANCE PRODUCTS": {
              "ENDUR-ACIN": "ENDUR-ACIN",
              "ENDUR-AMIDE": "ENDUR-AMIDE",
              "NIACIN": "NIACIN",
              "PLAIN NIACIN": "PLAIN NIACIN"
            },
            "EQUALINE": {
              "EQL NIACIN": "EQL NIACIN"
            },
            "FAGRON": {
              "NIACIN": "NIACIN",
              "NIACINAMIDE": "NIACINAMIDE"
            },
            "FREEDA VITAMINS": {
              "NIACIN": "NIACIN",
              "NIACINAMIDE": "NIACINAMIDE"
            },
            "FREEDOM PHARMACEUTICALS": {
              "NIACIN": "NIACIN",
              "NIACINAMIDE": "NIACINAMIDE"
            },
            "GENERAL NUTRITION CORP": {
              "NIACIN": "NIACIN",
              "NIACINAMIDE": "NIACINAMIDE"
            },
            "HUMCO": {
              "NIACINAMIDE": "NIACINAMIDE"
            },
            "KAISER FOUNDATION HOSPITAL": {
              "KP NIACIN": "KP NIACIN"
            },
            "LETCO MEDICAL": {
              "NIACIN": "NIACIN",
              "NIACINAMIDE": "NIACINAMIDE"
            },
            "MAGNA PHARMACEUTICALS, INC": {
              "SLO-NIACIN": "SLO-NIACIN"
            },
            "MAGNO-HUMPHRIES LABORATORIES": {
              "NIACIN": "NIACIN",
              "NIACIN ER": "NIACIN ER",
              "NIACINAMIDE": "NIACINAMIDE"
            },
            "MAINPOINTE PHARMACEUTICALS": {
              "SLO-NIACIN": "SLO-NIACIN"
            },
            "MAJOR PHARMACEUTICALS": {
              "NIACIN": "NIACIN",
              "NIACIN ER": "NIACIN ER",
              "NIACINAMIDE": "NIACINAMIDE"
            },
            "MARLEX PHARMACEUTICALS": {
              "NIACIN": "NIACIN"
            },
            "MASON VITAMINS": {
              "NIACIN": "NIACIN",
              "NIACIN ER": "NIACIN ER",
              "NIACINAMIDE": "NIACINAMIDE"
            },
            "MCKESSON": {
              "HM NIACIN": "HM NIACIN",
              "HM NIACIN TR": "HM NIACIN TR"
            },
            "MCKESSON SUNMARK": {
              "SM NIACIN CR": "SM NIACIN CR"
            },
            "MEDISCA": {
              "NIACIN": "NIACIN",
              "NIACINAMIDE": "NIACINAMIDE"
            },
            "NAT-RUL HEALTH PRODUCTS": {
              "NIACIN": "NIACIN",
              "NIACIN ER": "NIACIN ER",
              "NIACINAMIDE": "NIACINAMIDE"
            },
            "NATIONAL VITAMIN": {
              "NIACIN": "NIACIN",
              "NIACIN ER": "NIACIN ER"
            },
            "NATROL": {
              "NIACIN ER": "NIACIN ER"
            },
            "NATURES BOUNTY": {
              "NIACIN": "NIACIN",
              "NIACIN ER": "NIACIN ER"
            },
            "PCCA": {
              "NIACIN": "NIACIN",
              "NIACINAMIDE": "NIACINAMIDE"
            },
            "PDRX PHARMACEUTICAL": {
              "NIACIN": "NIACIN"
            },
            "PHARMASSURE, INC.": {
              "NIACIN": "NIACIN"
            },
            "PHARMAVITE": {
              "NIACIN ER": "NIACIN ER"
            },
            "PLUS PHARMA": {
              "NIACIN": "NIACIN"
            },
            "PUBLIX SUPER MARKETS INC.": {
              "PX NIACIN": "PX NIACIN"
            },
            "QUALITY CARE": {
              "NIACIN ER": "NIACIN ER"
            },
            "REXALL SUNDOWN": {
              "NIACIN ER": "NIACIN ER"
            },
            "RITE AID CORPORATION": {
              "RA NIACIN": "RA NIACIN",
              "RA NO FLUSH NIACIN": "RA NO FLUSH NIACIN"
            },
            "RUGBY LABORATORIES": {
              "NIACIN": "NIACIN",
              "NIACIN ER": "NIACIN ER",
              "NIACINAMIDE": "NIACINAMIDE"
            },
            "SPECTRUM": {
              "NIACIN": "NIACIN",
              "NICOTINAMIDE": "NICOTINAMIDE"
            },
            "THE KEY COMPANY": {
              "NIACIN ER": "NIACIN ER",
              "NIACIN-50": "NIACIN-50"
            },
            "TIME-CAP LABS": {
              "NIACIN ER": "NIACIN ER"
            },
            "WALGREENS": {
              "NIACIN": "NIACIN",
              "NIACIN ER": "NIACIN ER"
            },
            "WINDMILL CONSUMER PRODUCTS": {
              "NIACIN": "NIACIN",
              "NIACIN ER": "NIACIN ER"
            }
          },
          "Vitamin B-5": {
            "BIO-TECH": {
              "PANTO-250": "PANTO-250"
            },
            "FREEDA VITAMINS": {
              "CALCIUM PANTOTHENATE": "CALCIUM PANTOTHENATE"
            },
            "INTEGRATIVE THERAPEUTICS": {
              "PANTOTHENIC ACID": "PANTOTHENIC ACID"
            },
            "LETCO MEDICAL": {
              "CALCIUM PANTOTHENATE": "CALCIUM PANTOTHENATE"
            },
            "MEDISCA": {
              "CALCIUM PANTOTHENATE": "CALCIUM PANTOTHENATE"
            },
            "PCCA": {
              "CALCIUM PANTOTHENATE": "CALCIUM PANTOTHENATE"
            },
            "RUGBY LABORATORIES": {
              "CALCIUM PANTOTHENATE": "CALCIUM PANTOTHENATE"
            },
            "SPECTRUM": {
              "CALCIUM PANTOTHENATE": "CALCIUM PANTOTHENATE"
            }
          },
          "Vitamin B-6": {
            "21ST CENTURY HEALTHCARE": {
              "B-6": "B-6"
            },
            "A-S MEDICATION SOLUTIONS": {
              "VITAMIN B-6": "VITAMIN B-6"
            },
            "AIDAREX PHARMACEUTICALS": {
              "PYRIDOXINE HCL": "PYRIDOXINE HCL"
            },
            "AKORN": {
              "PYRIDOXINE HCL": "PYRIDOXINE HCL"
            },
            "APOTHECA SUPPLY": {
              "PYRIDOXINE HCL": "PYRIDOXINE HCL"
            },
            "AUBURN PHARMACEUTICAL": {
              "VITAMIN B-6": "VITAMIN B-6"
            },
            "AVPAK": {
              "VITAMIN B-6": "VITAMIN B-6"
            },
            "BASIC DRUGS": {
              "VITAMIN B-6": "VITAMIN B-6",
              "VITAMIN B-6 ER": "VITAMIN B-6 ER"
            },
            "BERGEN BRUNSWIG": {
              "GNP VITAMIN B-6": "GNP VITAMIN B-6"
            },
            "BRYANT RANCH PREPACK": {
              "PYRIDOXINE HCL": "PYRIDOXINE HCL"
            },
            "CHAIN DRUG CONSORTIUM": {
              "VITAMIN B-6": "VITAMIN B-6"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC VITAMIN B6": "QC VITAMIN B6"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS B-6": "CVS B-6",
              "CVS B6": "CVS B6",
              "CVS VITAMIN B-6": "CVS VITAMIN B-6"
            },
            "EMPOWER PHARMACY": {
              "PYRIDOXINE HCL": "PYRIDOXINE HCL"
            },
            "EQUALINE": {
              "EQL B-6": "EQL B-6"
            },
            "FAGRON": {
              "PYRIDOXINE HCL": "PYRIDOXINE HCL"
            },
            "FREEDA VITAMINS": {
              "VITAMIN B-6": "VITAMIN B-6",
              "VITAMIN B6": "VITAMIN B6"
            },
            "FREEDOM PHARMACEUTICALS": {
              "PYRIDOXINE HCL": "PYRIDOXINE HCL"
            },
            "FRESENIUS KABI USA": {
              "PYRIDOXINE HCL": "PYRIDOXINE HCL"
            },
            "GENDOSE PHARMACEUTICALS": {
              "VITAMIN B-6": "VITAMIN B-6"
            },
            "GERI-CARE": {
              "VITAMIN B-6": "VITAMIN B-6"
            },
            "H.J. HARKINS COMPANY, INC.": {
              "PYRIDOXINE HCL": "PYRIDOXINE HCL",
              "VITAMIN B-6": "VITAMIN B-6"
            },
            "HUMCO": {
              "PYRIDOXINE HCL": "PYRIDOXINE HCL"
            },
            "IMPRIMIS NJOF": {
              "PYRIDOXAL-5 PHOSPHATE": "PYRIDOXAL-5 PHOSPHATE"
            },
            "JJ BALAN": {
              "VITAMIN B-6": "VITAMIN B-6"
            },
            "KAISER FOUNDATION HOSPITAL": {
              "KP VITAMIN B-6": "KP VITAMIN B-6"
            },
            "KALCHEM INTERNATIONAL": {
              "PYRIDOXINE HCL": "PYRIDOXINE HCL"
            },
            "LEADER BRAND PRODUCTS": {
              "VITAMIN B-6": "VITAMIN B-6",
              "VITAMIN B6": "VITAMIN B6"
            },
            "LEINER HEALTH PRODUCTS": {
              "YL VITAMIN B-6": "YL VITAMIN B-6"
            },
            "LETCO MEDICAL": {
              "PYRIDOXINE HCL": "PYRIDOXINE HCL"
            },
            "LIBERTY PHARMACEUTICAL": {
              "PYRIDOXINE HCL": "PYRIDOXINE HCL"
            },
            "MAJOR PHARMACEUTICALS": {
              "VITAMIN B-6": "VITAMIN B-6"
            },
            "MARLEX PHARMACEUTICALS": {
              "PYRIDOXINE HCL": "PYRIDOXINE HCL"
            },
            "MASON VITAMINS": {
              "B-6": "B-6"
            },
            "MCKESSON": {
              "HM VITAMIN B6": "HM VITAMIN B6"
            },
            "MCKESSON SUNMARK": {
              "SM VITAMIN B-6": "SM VITAMIN B-6",
              "SM VITAMIN B6": "SM VITAMIN B6"
            },
            "MEDICINE SHOPPE": {
              "VITAMIN B-6": "VITAMIN B-6"
            },
            "MEDISCA": {
              "PYRIDOXINE HCL": "PYRIDOXINE HCL"
            },
            "MILLER": {
              "PYRI 500": "PYRI 500"
            },
            "MYLAN INSTITUTIONAL": {
              "PYRIDOXINE HCL": "PYRIDOXINE HCL"
            },
            "NAT-RUL HEALTH PRODUCTS": {
              "VITAMIN B-6": "VITAMIN B-6"
            },
            "NATIONAL VITAMIN": {
              "VITAMIN B-6": "VITAMIN B-6"
            },
            "NATURES BOUNTY": {
              "VITAMIN B-6": "VITAMIN B-6"
            },
            "NUCARE PHARMACEUTICALS": {
              "VITAMIN B-6": "VITAMIN B-6"
            },
            "NUTRACEUTICS": {
              "B-NATAL": "B-NATAL"
            },
            "PCCA": {
              "PYRIDOXINE HCL": "PYRIDOXINE HCL"
            },
            "PDRX PHARMACEUTICAL": {
              "PYRIDOXINE HCL": "PYRIDOXINE HCL"
            },
            "PHARMASSURE, INC.": {
              "VITAMIN B-6": "VITAMIN B-6",
              "VITAMIN B-6 ER": "VITAMIN B-6 ER"
            },
            "PHARMAVITE": {
              "VITAMIN B-6": "VITAMIN B-6"
            },
            "PHARMPAK": {
              "VITAMIN B-6": "VITAMIN B-6"
            },
            "PLUS PHARMA": {
              "VITAMIN B-6": "VITAMIN B-6"
            },
            "PRINCETON RESEARCH": {
              "B-6": "B-6"
            },
            "QUALITY CARE": {
              "PYRIDOXINE HCL": "PYRIDOXINE HCL",
              "VITAMIN B-6": "VITAMIN B-6"
            },
            "REXALL SUNDOWN": {
              "VITAMIN B-6": "VITAMIN B-6"
            },
            "RITE AID CORPORATION": {
              "RA VITAMIN B-6": "RA VITAMIN B-6",
              "RA VITAMIN B-6 CR": "RA VITAMIN B-6 CR"
            },
            "RUGBY LABORATORIES": {
              "VITAMIN B-6": "VITAMIN B-6",
              "VITAMIN B6": "VITAMIN B6"
            },
            "RXCHANGE CO": {
              "PYRIDOXINE HCL": "PYRIDOXINE HCL",
              "VITAMIN B-6": "VITAMIN B-6"
            },
            "SPECTRUM": {
              "PYRIDOXINE HCL": "PYRIDOXINE HCL"
            },
            "THE KEY COMPANY": {
              "NEURO-K-250 T.D.": "NEURO-K-250 T.D.",
              "NEURO-K-250 VITAMIN B6": "NEURO-K-250 VITAMIN B6",
              "NEURO-K-50": "NEURO-K-50",
              "NEURO-K-500": "NEURO-K-500"
            },
            "VITALINE": {
              "VITAMIN B-6 ER": "VITAMIN B-6 ER"
            },
            "WAL-MART": {
              "B6 NATURAL": "B6 NATURAL",
              "VITAMIN B-6 ER": "VITAMIN B-6 ER",
              "VITAMIN B6": "VITAMIN B6"
            },
            "WALGREENS": {
              "B-6": "B-6",
              "VITAMIN B-6": "VITAMIN B-6"
            },
            "WINDMILL CONSUMER PRODUCTS": {
              "B-6": "B-6",
              "VITAMIN B-6": "VITAMIN B-6"
            }
          },
          "Vitamin C": {
            "21ST CENTURY HEALTHCARE": {
              "C-1000": "C-1000",
              "C-1000/ROSE HIPS": "C-1000/ROSE HIPS",
              "C-250": "C-250",
              "C-500": "C-500",
              "C-500/ROSE HIPS": "C-500/ROSE HIPS"
            },
            "A-S MEDICATION SOLUTIONS": {
              "VITAMIN C": "VITAMIN C"
            },
            "AIDAREX PHARMACEUTICALS": {
              "VITAMIN C": "VITAMIN C"
            },
            "AMERICAN HEALTH PACKAGING": {
              "C 500": "C 500"
            },
            "AMERICAN REGENT": {
              "ASCORBIC ACID": "ASCORBIC ACID"
            },
            "AMERISOURCE BERGEN DRUGS": {
              "GNP VITAMIN C DROPS": "GNP VITAMIN C DROPS"
            },
            "ANAZAOHEALTH CORPORATION": {
              "ASCORBIC ACID": "ASCORBIC ACID"
            },
            "ATLANTIC BIOLOGICALS": {
              "ASCORBIC ACID": "ASCORBIC ACID",
              "VITAMIN C": "VITAMIN C"
            },
            "AUBURN PHARMACEUTICAL": {
              "VITAMIN C": "VITAMIN C"
            },
            "AVPAK": {
              "C-500": "C-500",
              "VITAMIN C": "VITAMIN C"
            },
            "BASIC DRUGS": {
              "VITAMIN C": "VITAMIN C",
              "VITAMIN C ER": "VITAMIN C ER"
            },
            "BAYSHORE PHARMACEUTICALS": {
              "BPROTECTED VITAMIN C": "BPROTECTED VITAMIN C"
            },
            "BERGEN BRUNSWIG": {
              "GNP VITAMIN C": "GNP VITAMIN C",
              "GNP VITAMIN C CR": "GNP VITAMIN C CR",
              "GNP VITAMIN C DROPS": "GNP VITAMIN C DROPS",
              "GNP VITAMIN C W/ROSE HIPS": "GNP VITAMIN C W/ROSE HIPS",
              "GNP VITAMIN C/ROSE HIPS": "GNP VITAMIN C/ROSE HIPS",
              "GNP VITAMIN C/ROSE HIPS TR": "GNP VITAMIN C/ROSE HIPS TR"
            },
            "CHAIN DRUG CONSORTIUM": {
              "C 1000": "C 1000",
              "C 250": "C 250",
              "VITAMIN C": "VITAMIN C",
              "VITAMIN C DROPS": "VITAMIN C DROPS",
              "VITAMIN C ER": "VITAMIN C ER",
              "VITAMIN C-ROSE HIPS": "VITAMIN C-ROSE HIPS",
              "VITAMIN C-ROSE HIPS ER": "VITAMIN C-ROSE HIPS ER"
            },
            "CHAIN DRUG MARKETING ASSOC": {
              "QC VITAMIN C": "QC VITAMIN C",
              "QC VITAMIN C WITH ROSE HIPS": "QC VITAMIN C WITH ROSE HIPS"
            },
            "CVS PHARMACY HEALTHCARE SERV": {
              "CVS CHEWABLE C WITH ROSE HIPS": "CVS CHEWABLE C WITH ROSE HIPS",
              "CVS VITAMIN C": "CVS VITAMIN C",
              "CVS VITAMIN C-ROSE HIPS": "CVS VITAMIN C-ROSE HIPS"
            },
            "CYPRESS PHARMACEUTICAL": {
              "VITAMIN C": "VITAMIN C"
            },
            "DIRECT RX": {
              "VITAMIN C": "VITAMIN C"
            },
            "EMPOWER PHARMACY": {
              "ASCORBIC ACID": "ASCORBIC ACID"
            },
            "ENDURANCE PRODUCTS": {
              "ENDUR-C": "ENDUR-C"
            },
            "EQUALINE": {
              "EQL VITAMIN C": "EQL VITAMIN C",
              "EQL VITAMIN C DROPS": "EQL VITAMIN C DROPS",
              "EQL VITAMIN C GUMMIES": "EQL VITAMIN C GUMMIES",
              "EQL VITAMIN C/ROSE HIPS": "EQL VITAMIN C/ROSE HIPS"
            },
            "FAGRON": {
              "SODIUM ASCORBATE": "SODIUM ASCORBATE"
            },
            "FLON LABORATORIES": {
              "ASCORBIC ACID": "ASCORBIC ACID"
            },
            "FREEDA VITAMINS": {
              "CALCIUM ASCORBATE": "CALCIUM ASCORBATE",
              "FRUIT C": "FRUIT C",
              "FRUIT C 500": "FRUIT C 500",
              "VITA-C": "VITA-C",
              "VITAMIN C": "VITAMIN C",
              "VITAMIN C (CALCIUM ASCORBATE)": "VITAMIN C (CALCIUM ASCORBATE)",
              "VITAMIN C ER": "VITAMIN C ER",
              "VITAMIN C-ROSE HIPS": "VITAMIN C-ROSE HIPS"
            },
            "FREEDOM PHARMACEUTICALS": {
              "SODIUM ASCORBATE": "SODIUM ASCORBATE"
            },
            "GERI-CARE": {
              "VITAMIN C": "VITAMIN C"
            },
            "HANNAFORD BROTHERS": {
              "VITAMIN C": "VITAMIN C"
            },
            "HUMCO": {
              "ASCORBIC ACID": "ASCORBIC ACID"
            },
            "IMPRIMIS NJOF": {
              "ASCORBIC ACID": "ASCORBIC ACID"
            },
            "INTEGRATIVE THERAPEUTICS": {
              "BUFFERED VITAMIN C": "BUFFERED VITAMIN C"
            },
            "JJ BALAN": {
              "VITAMIN C": "VITAMIN C"
            },
            "LEADER BRAND PRODUCTS": {
              "C 250": "C 250",
              "C 500/ROSE HIPS": "C 500/ROSE HIPS",
              "C-1000/ROSE HIPS": "C-1000/ROSE HIPS",
              "VITAMIN C": "VITAMIN C",
              "VITAMIN C GUMMIES": "VITAMIN C GUMMIES"
            },
            "LEINER HEALTH PRODUCTS": {
              "YL VITAMIN C": "YL VITAMIN C",
              "YL VITAMIN C-ROSE HIPS": "YL VITAMIN C-ROSE HIPS"
            },
            "LETCO MEDICAL": {
              "SODIUM ASCORBATE": "SODIUM ASCORBATE"
            },
            "LIBERTY PHARMACEUTICAL": {
              "VITAMIN C": "VITAMIN C"
            },
            "MAGNO-HUMPHRIES LABORATORIES": {
              "ACEROLA C-500": "ACEROLA C-500",
              "C-250": "C-250",
              "C-500": "C-500",
              "VITAMIN C": "VITAMIN C"
            },
            "MAJOR PHARMACEUTICALS": {
              "C-250": "C-250",
              "C-500": "C-500",
              "VITAMIN C": "VITAMIN C",
              "VITAMIN C ER": "VITAMIN C ER"
            },
            "MARLEX PHARMACEUTICALS": {
              "ASCORBIC ACID": "ASCORBIC ACID"
            },
            "MASON VITAMINS": {
              "C 1000": "C 1000",
              "C 250": "C 250",
              "C 500": "C 500",
              "C 500/ROSE HIPS": "C 500/ROSE HIPS",
              "LIQUID C 500": "LIQUID C 500",
              "PURE C 500": "PURE C 500",
              "PUREWAY-C": "PUREWAY-C"
            },
            "MCGUFF PHARMACEUTICALS": {
              "ASCOR": "ASCOR"
            },
            "MCKESSON": {
              "HM VITAMIN C": "HM VITAMIN C",
              "HM VITAMIN C TR": "HM VITAMIN C TR",
              "HM VITAMIN C/ROSE HIPS": "HM VITAMIN C/ROSE HIPS"
            },
            "MCKESSON SUNMARK": {
              "SM CHEWABLE C": "SM CHEWABLE C",
              "SM CHEWABLE VITAMIN C": "SM CHEWABLE VITAMIN C",
              "SM VIT C/ROSE HIPS": "SM VIT C/ROSE HIPS",
              "SM VITAMIN C": "SM VITAMIN C",
              "SM VITAMIN C CR": "SM VITAMIN C CR",
              "SM VITAMIN C/ROSE HIPS": "SM VITAMIN C/ROSE HIPS"
            },
            "MEDICINE SHOPPE": {
              "VITAMIN C": "VITAMIN C"
            },
            "MEIJER": {
              "MEIJER C": "MEIJER C"
            },
            "MERIT": {
              "MEGA-C/A PLUS": "MEGA-C/A PLUS",
              "ORTHO-CS 250": "ORTHO-CS 250",
              "SODIUM ASCORBATE": "SODIUM ASCORBATE"
            },
            "MILLER": {
              "CALCIUM ASCORBATE": "CALCIUM ASCORBATE",
              "CEMILL": "CEMILL",
              "CEMILL SR": "CEMILL SR"
            },
            "MONDELEZ INTERNATIONAL": {
              "HALLS DEFENSE VITAMIN C DROPS": "HALLS DEFENSE VITAMIN C DROPS"
            },
            "MYLAN INSTITUTIONAL": {
              "ASCORBIC ACID": "ASCORBIC ACID"
            },
            "NAT-RUL HEALTH PRODUCTS": {
              "BUFFERED C": "BUFFERED C",
              "C-CAPS TR": "C-CAPS TR",
              "C-CHEWABLE": "C-CHEWABLE",
              "VITAMIN C": "VITAMIN C",
              "VITAMIN C-ROSE HIPS": "VITAMIN C-ROSE HIPS",
              "VITAMIN C-ROSE HIPS ER": "VITAMIN C-ROSE HIPS ER",
              "YUMMY-C": "YUMMY-C"
            },
            "NATIONAL VITAMIN": {
              "ACEROLA C 500": "ACEROLA C 500",
              "FRUITY C": "FRUITY C",
              "VITACHEW VIT C CITRUS BURST": "VITACHEW VIT C CITRUS BURST",
              "VITAMIN C": "VITAMIN C",
              "VITAMIN C ER": "VITAMIN C ER",
              "VITAMIN C-ROSE HIPS": "VITAMIN C-ROSE HIPS"
            },
            "NATURES BOUNTY": {
              "ASCORBIC ACID": "ASCORBIC ACID",
              "VITAMIN C": "VITAMIN C",
              "VITAMIN C ER": "VITAMIN C ER",
              "VITAMIN C-ACEROLA": "VITAMIN C-ACEROLA",
              "VITAMIN C-ROSE HIPS": "VITAMIN C-ROSE HIPS",
              "VITAMIN C-ROSE HIPS ER": "VITAMIN C-ROSE HIPS ER"
            },
            "PCCA": {
              "CALCIUM ASCORBATE": "CALCIUM ASCORBATE",
              "SODIUM ASCORBATE": "SODIUM ASCORBATE"
            },
            "PHARBEST PHARMACEUTICALS": {
              "VITAMIN C": "VITAMIN C"
            },
            "PHARMASSURE, INC.": {
              "VITAMIN C": "VITAMIN C",
              "VITAMIN C-ROSE HIPS": "VITAMIN C-ROSE HIPS",
              "VITAMIN C-ROSE HIPS ER": "VITAMIN C-ROSE HIPS ER"
            },
            "PHARMAVITE": {
              "VITAMELTS VITAMIN C": "VITAMELTS VITAMIN C",
              "VITAMIN C": "VITAMIN C",
              "VITAMIN C ADULT GUMMIES": "VITAMIN C ADULT GUMMIES",
              "VITAMIN C ER": "VITAMIN C ER",
              "VITAMIN C-ROSE HIPS": "VITAMIN C-ROSE HIPS",
              "VITAMIN C-ROSE HIPS ER": "VITAMIN C-ROSE HIPS ER"
            },
            "PLUS PHARMA": {
              "VITAMIN C": "VITAMIN C"
            },
            "PRINCETON RESEARCH": {
              "VITAMIN C": "VITAMIN C",
              "VITAMIN C PLUS WILD ROSE HIPS": "VITAMIN C PLUS WILD ROSE HIPS",
              "VITAMIN C/BIOFLAVONOIDS/ROSEHP": "VITAMIN C/BIOFLAVONOIDS/ROSEHP"
            },
            "PUBLIX SUPER MARKETS INC.": {
              "PX VITAMIN C": "PX VITAMIN C"
            },
            "QUALITY CARE": {
              "VITAMIN C": "VITAMIN C"
            },
            "REXALL SUNDOWN": {
              "VITAMIN C": "VITAMIN C",
              "VITAMIN C ER": "VITAMIN C ER",
              "VITAMIN C-ROSE HIPS ER": "VITAMIN C-ROSE HIPS ER"
            },
            "RITE AID CORPORATION": {
              "RA VITAMIN C": "RA VITAMIN C",
              "RA VITAMIN C CR": "RA VITAMIN C CR",
              "RA VITAMIN C DROPS": "RA VITAMIN C DROPS",
              "RA VITAMIN C/ACEROLA": "RA VITAMIN C/ACEROLA",
              "RA VITAMIN C/ROSE HIPS": "RA VITAMIN C/ROSE HIPS",
              "RA VITAMIN C/ROSE HIPS CR": "RA VITAMIN C/ROSE HIPS CR"
            },
            "RUGBY LABORATORIES": {
              "C-500": "C-500",
              "VITAMIN C": "VITAMIN C"
            },
            "RXCHANGE CO": {
              "VITAMIN C": "VITAMIN C"
            },
            "SELECT BRAND": {
              "SB VITAMIN C": "SB VITAMIN C"
            },
            "SPECTRUM": {
              "CALCIUM ASCORBATE": "CALCIUM ASCORBATE",
              "SODIUM ASCORBATE": "SODIUM ASCORBATE"
            },
            "TARGET": {
              "TGT VITAMIN C DROPS": "TGT VITAMIN C DROPS"
            },
            "THE KEY COMPANY": {
              "ASCO-TABS-1000": "ASCO-TABS-1000",
              "ASCOCID": "ASCOCID",
              "ASCOCID-ISO-PH": "ASCOCID-ISO-PH",
              "CHEW-C": "CHEW-C"
            },
            "TIME-CAP LABS": {
              "C-TIME": "C-TIME"
            },
            "VITALINE": {
              "VITAMIN C ER": "VITAMIN C ER"
            },
            "WAL-MART": {
              "C-CHEWABLE": "C-CHEWABLE",
              "NATURAL C/ROSE HIPS": "NATURAL C/ROSE HIPS",
              "VITAMIN C": "VITAMIN C",
              "VITAMIN C GUMMIE": "VITAMIN C GUMMIE",
              "VITAMIN C-ROSE HIPS": "VITAMIN C-ROSE HIPS",
              "VITAMIN C/ROSE HIPS TR": "VITAMIN C/ROSE HIPS TR"
            },
            "WALGREENS": {
              "CRUSH VITAMIN C DROPS": "CRUSH VITAMIN C DROPS",
              "VITAMIN C": "VITAMIN C",
              "VITAMIN C DROPS": "VITAMIN C DROPS",
              "VITAMIN C ER": "VITAMIN C ER",
              "VITAMIN C GUMMIES": "VITAMIN C GUMMIES",
              "VITAMIN C IMMUNE HEALTH": "VITAMIN C IMMUNE HEALTH",
              "VITAMIN C-ACEROLA": "VITAMIN C-ACEROLA",
              "VITAMIN C-ROSE HIPS": "VITAMIN C-ROSE HIPS",
              "VITAMIN C-ROSE HIPS ER": "VITAMIN C-ROSE HIPS ER",
              "VITAMIN C-ROSE HIPS TR": "VITAMIN C-ROSE HIPS TR",
              "VITAMIN C/ROSE HIPS": "VITAMIN C/ROSE HIPS"
            },
            "WINDMILL CONSUMER PRODUCTS": {
              "C-1000": "C-1000",
              "C-1000 SR": "C-1000 SR",
              "C-1000/ROSE HIPS": "C-1000/ROSE HIPS",
              "C-1000/ROSE HIPS SR": "C-1000/ROSE HIPS SR",
              "C-1500/ROSE HIPS SR": "C-1500/ROSE HIPS SR",
              "C-250": "C-250",
              "C-500": "C-500",
              "C-500 NON-ACID": "C-500 NON-ACID",
              "C-500 SR": "C-500 SR",
              "C-500/ROSE HIPS": "C-500/ROSE HIPS"
            },
            "WN PHARMACEUTICALS": {
              "SUNKIST VITAMIN C": "SUNKIST VITAMIN C"
            }
          }
        }
      },
    }