import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Accordion, Icon } from 'semantic-ui-react'

import '../styles.scss'
import BreadCrumb from '../../../../../components/breadCrumb'
import FliptGrid from '../../../../../components/fliptGrid'
import { Creators as AppActions } from '../../../../../redux/reducers/app'
import { Creators as MedicareSetupActions } from '../../../../../redux/reducers/api/medicareSetup'
import { filterRowData, parseQueryString, convertStringToSnakeCase } from '../../../../../utils/utilities'
import config from '../../../../../config'
import { InputTextEditor, DropdownEditor } from '../../../../../components/fliptGrid/cellEditors'
import FliptButton from '../../../../../components/form/fliptButton'
import initialData from './data'

class MedicarePlanDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeIndex: 0,
      medicareData: initialData,
    }
  }

  componentDidMount() {
    setTimeout(() => this._fetchMedicareSetupDetails(), config.COMPONENT_DATA_LOAD_TIME)
  }

  componentDidUpdate(prevProps) {
    const { props: { state } } = this
    if (prevProps.state !== state) {
      this._updateState(prevProps.state)
    }
  }

  _fetchMedicareSetupDetails = () => {
    const { props } = this
    const { history } = props
    if (!history?.location?.search) return
    props.actions.getMedicareSetupDetailData(parseQueryString(history?.location?.search))
  }

  _handleAccordianClick = (e, titleProps) => {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index

    this.setState({ activeIndex: newIndex })
  }

  // _handleLevelDataSetup = (data) => {

  // }

  _handleChange = (from, el, dropdown, rowIndex, gridApi) => {
    const { state: { medicareData } } = this
    const updatedMedicareData = { ...medicareData }
    const phaseToUpdate = updatedMedicareData.phases[from - 1]
    const rowToUpdate = phaseToUpdate.lics_level[rowIndex]
    const { name, value } = dropdown || el.currentTarget
    rowToUpdate[name] = value || null
    gridApi.refreshCells()
  }

  _saveMedicarePlan = () => {
    const { state, props: { actions } } = this
    const payload = {
      ...state.medicareData.lics_plan_info,
      phases: state.medicareData.phases
    }
    actions.saveMedicareSetupDetailData(payload)
  }

  _updateState() {
    const { props: { state } } = this
    if (state.medicareSetupDetailData?.length === 1) {
      this.setState({ medicareData: state.medicareSetupDetailData[0] })
    }
  }

  _goBack = () => {
    const { props: { history } } = this
    history.goBack()
  }

  render() {
    const { props } = this
    const {
      activeIndex,
      medicareData,
    } = this.state
    const { location: { state } } = props
    const headers = [
      'lics_level', 'lics_deductible',
    ]
    const costSharePhases = ['Deductible', 'Initial Coverage', 'Coverage Gap', 'Catastrophic']
    const costShareHeaders = [
      'lics_level', 'lics_brand_cost_share', 'lics_brand_cost_share_type', 'lics_minimum_brand_cost_share', 'lics_maximum_brand_cost_share', 'lics_generic_cost_share',
      'lics_generic_cost_share_type', 'lics_minimum_generic_cost_share', 'lics_maximum_generic_cost_share',
    ]

    const cellEditorParamsDeductible = {
      lics_deductible: {
        cellEditor: InputTextEditor,
        onChange: this._handleChange,
      },
    }

    const cellEditorParams = {
      lics_brand_cost_share: {
        cellEditor: InputTextEditor,
        onChange: this._handleChange,
      },
      lics_brand_cost_share_type: {
        cellEditor: DropdownEditor,
        onChange: this._handleChange,
      },
      lics_minimum_brand_cost_share: {
        cellEditor: InputTextEditor,
        onChange: this._handleChange,
      },
      lics_maximum_brand_cost_share: {
        cellEditor: InputTextEditor,
        onChange: this._handleChange,
      },
      lics_generic_cost_share: {
        cellEditor: InputTextEditor,
        onChange: this._handleChange,
      },
      lics_generic_cost_share_type: {
        cellEditor: DropdownEditor,
        onChange: this._handleChange,
      },
      lics_minimum_generic_cost_share: {
        cellEditor: InputTextEditor,
        onChange: this._handleChange,
      },
      lics_maximum_generic_cost_share: {
        cellEditor: InputTextEditor,
        onChange: this._handleChange,
      },
    }

    const cellRendererParams = {
      lics_level: {
        width: 425,
      },
      lics_deductible: {
        width: 425,
      },
    }

    const dropdownOptions = {}
    const rowData = {
      lics_brand_cost_share_type: ['Percentage', 'Fixed'],
      lics_generic_cost_share_type: ['Percentage', 'Fixed'],
    }
    costSharePhases.forEach((phase, idx) => {
      const key = convertStringToSnakeCase(phase.toLowerCase())
      const options = medicareData?.phases[idx]?.lics_level.map(() => {
        const data = {}
        Object.keys(rowData).forEach((col) => {
          data[col] = rowData[col].map((group, index) => ({
            key: index,
            text: group,
            value: group,
          }))
        })
        return data
      })
      dropdownOptions[key] = options
    })

    return (
      <div id="medicareSetup" className="medicare-setup">
        {/* <BreadCrumb {...this.props} /> */}
        <div className='ui small breadcrumb'>
          <a href='/home' className='section'>FAST</a>
          <div className='divider'>/</div>
          <a href='/global-settings' className='section'>Global Settings</a>
          <div className='divider'>/</div>
          <a href='/medicare-lics-setup' className='section'>Medicare D LICS Setup</a>
          <div className='divider'>/</div>
          <div className='active section'>{props.location.search && props.location.search.split('plan_name=')[1]}</div>
        </div>

        <div className="header">
          <h1>
            {' '}
            { props.location.search && props.location.search.split('plan_name=')[1] || 'Medicare D LICS Setup Details' }
            {' '}
          </h1>
        </div>
        {/* <div className="content">
          {
            !noPrescriber && (
            )
          }
        </div> */}
        {
          medicareData && medicareData?.phases && medicareData?.phases?.length ? (
            <>
              <div className="content">
                <FliptGrid
                  headers={headers}
                  data={medicareData.phases?.find((it) => it.phase === 'Deductible')?.lics_level?.map(({ lics_deductible, lics_level }) => ({
                    lics_deductible, lics_level,
                  }))?.map((d) => ({
                    ...filterRowData(d, headers),
                  }))}
                  cellEditorParams={cellEditorParamsDeductible}
                  cellRendererParams={cellRendererParams}
                  from={medicareData.phases?.findIndex((it) => it.phase === 'Deductible') + 1}
                />
              </div>
              <div className="content_acc">
                {
                  !!dropdownOptions && costSharePhases.map((item, idx) => (
                    <div key={idx} className="phaseWrapper">
                      <Accordion fluid styled>
                        <div className="mc_condition_level">
                          <Accordion.Title
                            active={activeIndex === idx}
                            index={idx}
                            onClick={this._handleAccordianClick}
                          >
                            {item}
                            <Icon name="dropdown" className="fl_right__icon" />
                          </Accordion.Title>
                          <Accordion.Content active={activeIndex === idx}>
                            <div className="mc_condition">
                              <FliptGrid
                                headers={costShareHeaders}
                                data={medicareData?.phases?.find((it) => it.phase === item)?.lics_level?.map((d) => ({
                                  ...filterRowData(d, costShareHeaders),
                                }))}
                                dropdownOptions={dropdownOptions[convertStringToSnakeCase(item.toLowerCase())]}
                                cellEditorParams={cellEditorParams}
                                from={idx + 1}
                              />
                            </div>
                          </Accordion.Content>
                        </div>
                      </Accordion>
                    </div>
                  ))
                }
              </div>
              <div className="buttonContainer button-row">
                <FliptButton name="Back" className="secondary backButton searchButton" onClick={this._goBack} />
                {/* <FliptButton name="Back" disabled={step === 1} className="primary searchButton" onClick={() => this.setState({ step: step - 1 })} /> */}
                <FliptButton disabled={!state?.editMode} name="Save" className="primary searchButton" onClick={this._saveMedicarePlan} />
              </div>
            </>
          ) : null
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  state: {
    medicareSetupDetailData: state.medicareSetup.medicareSetupDetailData,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
    ...MedicareSetupActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MedicarePlanDetails)
