import React, { useState, useEffect, useRef } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import FliptButton from '../../v2/fliptButton'
import FliptInput from "../../v2/fliptInput"
import FliptGrid from '../../fliptGrid';

import { Creators as QualifierActions } from '../../../redux/reducers/api/qualifiers'
import { Creators as AppActions } from '../../../redux/reducers/app'

import { filterRowData } from '../../../utils/utilities'

import './styles.scss'

// const search_params = [
//   { label: 'ICD-10 Code', name: 'icd_10_cm' },
//   { label: 'Diagnosis Description', name: 'long_description' },
// ]
const search_result_params = ['ICD-10-CM', 'long_description']

const QualifierLookup = (props) => {

  const [searchParams, setSearchParams] = useState([])
  const [form, setForm] = useState({})
  const [headers, setHeaders] = useState([])
  const [headerMapping, setHeaderMapping] = useState({})

  const [searchResult, setSearchResult] = useState([])
  const [selection, setSelection] = useState(null)
  const [submitParam, setSubmitParam] = useState(null)
  const gridRef = useRef(null)

  const setLookupValuesInState = () => {
    const lookupData = props.state.qualifierLookupModel[props.selectedQualifier]
    if (!lookupData) return;
    const initialFormValues = lookupData.search_params.reduce((acc, param) => {
      acc[param.name] = ''
      return acc
    }, {})
    setForm(initialFormValues)
    // set ag grid headers
    setHeaders(['Select', ...Object.values(lookupData.search_result_params)])
    setHeaderMapping({ ...lookupData.search_result_params })
    // set input fields
    setSearchParams(lookupData.search_params)
    setSubmitParam(lookupData.submit_param)
  }

  useEffect(() => {
    props.actions.clearQualifierLookupData()
  }, [])

  useEffect(() => {
    setLookupValuesInState()
  }, [props.state.qualifierLookupModel])

  useEffect(() => {
    const data = props.state.qualifierLookupData.map((obj) => {
      return { ...filterRowData(obj, headers, headerMapping) };
    })
    setSearchResult(data)
  }, [props.state.qualifierLookupData])

  const updateParam = (el) => {
    const { name, value } = el.currentTarget
    setForm({ ...form, [name]: value })
  }

  const getLookupResults = () => {
    const payload = {
      lookup_id: props.lookup_id,
    }
    searchParams.forEach((param) => { payload[param.name] = form[param.name] })
    props.actions.getQualifierLookupData(payload)
  }

  const onSelectionChanged = (data) => {
    const selectedRows = data.api.getSelectedRows();
    if (selectedRows.length) {
      setSelection([...selectedRows])
    } else {
      setSelection(null)
    }
  }

  const submitSelection = () => {
    // submit to parent component here
    const selectedParams = selection.map((selectedRow) => {
      return selectedRow[submitParam]
    })
    props.handleLookupSubmit(selectedParams)
    props.actions.toggleModal()
  }

  const cellRendererParams = {
    Select: {
      checkboxSelection: true,
      width: '100px'
    }
  }
  const qualifierLabel = ((props?.selectedQualifier || '').split('.')[1] || '').replace(/_/g, ' ');
  return (
    <div id='qualifier-lookup'>
      <section>
        <h2 className="qualifier-lookup-header" >{qualifierLabel} Lookup</h2>
      </section>
      <div className='input-container'>
        {searchParams.map((param, index) => (
          <FliptInput
            key={index}
            onChange={updateParam}
            label={param.label}
            name={param.name}
          />))}
      </div>
      <section>
        <FliptButton
          name='Search'
          className='primary'
          onClick={getLookupResults}
        />
      </section>
      {searchResult.length > 0 && <>
        <div className='grid-container'>
          <FliptGrid
            agGridRef={gridRef}
            headers={headers}
            data={searchResult}
            rowSelection={'multiple'}
            cellRendererParams={cellRendererParams}
            onSelectionChanged={onSelectionChanged} />
        </div>
        <FliptButton
          name='Submit'
          className='primary'
          onClick={submitSelection}
        />
      </>
      }
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    state: {
      qualifierLookupData: state.qualifiers.qualifierLookupData,
      qualifierLookupModel: state.qualifiers.qualifierLookupModel,
    },
  }
}

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...QualifierActions,
    ...AppActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(QualifierLookup)
