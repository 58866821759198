import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import "./styles.scss";
import FliptButton from "../../components/form/fliptButton";
import { Creators as MemberLookupActions } from "../../redux/reducers/api/memberLookup";
import { Creators as AppActions } from "../../redux/reducers/app";
import FliptGrid from "../fliptGrid";
import FliptDatePicker from "../form/fliptPaDatepicker";
import FliptInput from "../form/fliptInput";
import { isEmpty } from "lodash";
import {
  ModalHeader,
  ModalContent,
  ModalActions,
  Image,
  Modal,
  ModalDescription,
  Button,
} from "semantic-ui-react";
import Info from "../info";
import { LinkRenderer } from "../../components/fliptGrid/cellRenderers";

class FliptMemberSearch extends Component {
  constructor(props) {
    super(props);
    this.agGridRefMem = React.createRef();
    this.agGridRefEl = React.createRef();
    this.state = {
      memberList: [],
      eligibiltyList: [],
      selectedEligibilty: null,
      searchForm: {
        date_of_birth: "",
        first_name: "",
        last_name: "",
        memberId: "",
      },
      memberData: null,
      memberSearched: false,
    };
  }
  componentDidMount() {
    const { searchForm } = this.props;
    const { props } = this;
    const { memberLookup } = props.state;
    if (searchForm) {
      this.setState({
        searchForm: {
          date_of_birth: searchForm?.date_of_birth || "",
          first_name: searchForm?.first_name || "",
          last_name: searchForm?.last_name || "",
          memberId: searchForm.id || "",
        },
        selectedEligibilty: null,
      });
      if (Object.keys(memberLookup?.primary_member_details).length > 0) {
        this.setState({
          memberList: [memberLookup],
        });
        this.selectMemberEligibility(memberLookup);
      }
    }
  }

  _clearMemberFields = () => {
    const { props } = this;
    this.setState({
      searchForm: {
        date_of_birth: "",
        first_name: "",
        last_name: "",
        memberId: "",
      },
      memberList: [],
      memberSearched: false,
      eligibiltyList: [],
      selectedEligibilty: null,
    });
    props.actions.clearMemberLookupData();
  };

  _searchData() {
    const { actions } = this.props;
    const { props } = this;
    const { memberLookup } = props.state;
    const {
      state: { searchForm },
    } = this;
    if (
      !searchForm.memberId &&
      !searchForm.first_name &&
      !searchForm.last_name
    ) {
      const transitionalPortal = {
        header: "Fields are missing",
        copy: "Please add member Id/tpa Id, First Name or Last Name to search member",
      };
      actions.displayTransitionalPortal(transitionalPortal);
      return;
    }
    this.setState({ memberList: [], eligibiltyList: [] });
    let searchData = {};
    if (searchForm.memberId) {
      searchData = {
        member_identifier: "member_id",
        id: searchForm.memberId,
        noDataClearing: true,
      };
    }
    if (searchForm.first_name) searchData.first_name = searchForm.first_name;
    if (searchForm.last_name) searchData.last_name = searchForm.last_name;
    if (searchForm.date_of_birth)
      searchData.date_of_birth = searchForm.date_of_birth;
    actions.setMemberLookupFailure(false);
    actions.getMemberLookupData(searchData);
    this.setState({memberSearched : true })

  }

  componentDidUpdate(prevProps) {
    const { props } = this;
    const {  closeModal,updateMemberData } = this.props;
    const { searchForm,memberSearched } = this.state;
    const { memberLookup } = props.state;
   if(memberLookup?.multiMemberLookup?.length == 0 && memberSearched){
      updateMemberData('',searchForm);
      closeModal()
    } 
  }


  _updateSearchForm = (el, dropdown) => {
    const { searchForm } = this.state;
    const { name, value } = dropdown || el.currentTarget;
    if (name == "memberId") {
      searchForm.first_name = "";
      searchForm.last_name = "";
      searchForm.date_of_birth = "";
    }
    if (name === "first_name" || name === "last_name") {
      searchForm.memberId = "";
    }
    searchForm[name] = value;
    this.setState({
      searchForm,
    });
  };

  selectMemberEligibility = (data) => {
    const mergedEligibilities = [];
    data?.primary_member_details?.eligibility?.forEach((eligibilty) => {
      if (
        eligibilty?.group_details?.accumulation_list &&
        eligibilty?.group_details?.accumulation_list.length > 0
      ) {
        eligibilty.group_details.accumulation_list.forEach(
          (accumulationEntry) => {
            const mergedEntry = { ...eligibilty, ...accumulationEntry };
            mergedEligibilities.push(mergedEntry);
          }
        );
      } else {
        mergedEligibilities.push(eligibilty);
      }
    });
    this.setState({
      eligibiltyList: mergedEligibilities,
    });
  };

  // Update the input data
  onMemberSelectionChanged = (data) => {
    const { updateMemberData } = this.props;
    const { searchForm } = this.state;
    const selectedRows = data.api.getSelectedRows();
    if (selectedRows.length) {
      this.setState({ memberData: selectedRows[0] });
      updateMemberData(selectedRows[0],searchForm);
      this.selectMemberEligibility(selectedRows[0]);
    } else {
      this.setState({
        eligibiltyList: [],
        selectedEligibilty: null,
      });
    }
  };
  onMemberEligibilitySelectionChanged = (data) => {
    const selectedRows = data.api.getSelectedRows();
    if (selectedRows.length) {
      this.setState({
        selectedEligibilty: selectedRows[0],
      });
    } else {
      this.setState({
        selectedEligibilty: null,
      });
    }
  };

  _submitResult = () => {
    const { eligibilityUpdateMemberMode } = this.props;
    const { selectedEligibilty } = this.state;
    eligibilityUpdateMemberMode(selectedEligibilty);
  };

  onGridReady = () => {
    const { updateMemberData } = this.props;
    const { memberList,searchForm } = this.state;
    if (memberList.length == 1) {
      updateMemberData(memberList[0],searchForm);
      this.agGridRefMem.current.api.forEachNode(function (node) {
        node.setSelected(true);
      });
    }
  };

  onElGridReady = () => {
    const { eligibiltyList } = this.state;
    if (eligibiltyList.length == 1) {
      this.agGridRefEl.current.api.forEachNode(function (node) {
        node.setSelected(true);
      });
    }
  };

  render() {
    const { open, closeModal } = this.props;
    const { memberLookup } = this.props.state;
    const {
      memberList,
      searchForm,
      eligibiltyList,
      selectedEligibilty,
    } = this.state;
    const eligibiltyHeader = [
      "Select",

      {
        headerName: "Plan Name",
        field: "benefit_plan_name",
      },
      {
        headerName: "Accumulation Start Date",
        field: "accumulation_start_date",
      },
      {
        headerName: "Accumulation End Date",
        field: "accumulation_end_date",
      },
      {
        headerName: "Eligibility Start Date",
        field: "coverage_effective_date",
      },
      {
        headerName: "Eligibility Term Date",
        field: "coverage_termination_date",
      },
      {
        headerName: "Member Group Start Date",
        field: "member_group_effective_date",
      },
      {
        headerName: "Member Group Term Date",
        field: "member_group_termination_date",
      },
      {
        headerName: "Group Effective Date",
        field: "group_details.group_effective_date",
      },
      {
        headerName: "Group Termination Date",
        field: "group_details.group_termination_date",
      },
      {
        headerName: "Benefit Period Type",
        field: "group_details.benefit_period_type",
      },

      {
        headerName: "Individual Deductible ",
        field: "group_details.annual_deductible_individual",
      },
      {
        headerName: "Family Deductible ",
        field: "group_details.annual_deductible_family",
      },
      {
        headerName: "Individual OOP ",
        field: "group_details.annual_out_of_pocket_individual",
      },
      {
        headerName: "Family OOP ",
        field: "group_details.annual_out_of_pocket_family",
      },
      {
        headerName: "Deductible Satisfication",
        field: "group_details.deductible_satisfaction",
      },
      { headerName: "Type of OOP", field: "group_details.type_of_oop" },
      {
        headerName: "Deductible/OOP Logic ",
        field: "group_details.deductible_oop_logic",
      },
      { headerName: "Plan Id", field: "group_details.plan_id" },
      {
        headerName: "Group Plan Effective Date",
        field: "group_details.plan_start_date",
      },
      {
        headerName: "Group Plan Termination Date",
        field: "group_details.plan_end_date",
      },
      { headerName: "CMS Contract Id", field: "group_details.cms_contract_id" },
      { headerName: "CMS PBP", field: "group_details.cms_pbp" },
    ];

    const eligibilityCellRendererParams = {
      Select: {
        checkboxSelection: true,
        width: "120px",
      },
    };

    const headers = [
      "Select",
      { headerName: "Member id", field: "primary_member_details.member_id" },
      { headerName: "FN", field: "primary_member_details.first_name" },
      { headerName: "LN", field: "primary_member_details.last_name" },
      { headerName: "DOB", field: "primary_member_details.date_of_birth" },
      { headerName: "Carrier ", field: "primary_member_details.carrier" },
      { headerName: "Account", field: "primary_member_details.account" },
      { headerName: "Group", field: "primary_member_details.group" },
    ];
    const cellRendererParams = {
      Select: {
        checkboxSelection: true,
        width: "120px",
      },
    };

    const isMemberId = !!searchForm.memberId;
    const isName = searchForm.first_name && searchForm.last_name && !isMemberId;
    return (
      <Modal
        size={"large"}
        open={open}
        onClose={closeModal}
        closeIcon
        centered={false}
      >
        <ModalHeader>Please select a member</ModalHeader>
        <ModalContent image scrolling id="memberModal">
          <ModalDescription>
            <div>
              <div className="fields-container ">
                <div className="fields-client-header">
                  <div className="field-label">
                    <span>{"Member ID"}</span>
                  </div>
                  <FliptInput
                    value={searchForm.memberId}
                    disabled={isName}
                    name="memberId"
                    onChange={this._updateSearchForm}
                    label="Member Id / Tpa Id"
                    className="field-input"
                  />
                </div>
                <div className="gap">
                  <Button className="option" disabled={true}>
                    OR
                  </Button>
                </div>

                <div className="fields-client-header">
                  <div className="field-label">
                    <span>{"Member First Name"}</span>
                  </div>
                  <FliptInput
                    value={searchForm.first_name}
                    disabled={isMemberId}
                    name="first_name"
                    onChange={this._updateSearchForm}
                    label="First Name"
                    className="field-input"
                  />
                </div>
                <div className="gap"></div>
                <div className="fields-client-header">
                  <div className="field-label">
                    <span>{"Member Last Name"}</span>
                  </div>
                  <FliptInput
                    value={searchForm.last_name}
                    disabled={isMemberId}
                    name="last_name"
                    onChange={this._updateSearchForm}
                    label="Last Name"
                    className="field-input"
                  />
                </div>
                <div className="gap"></div>
                <div className="fields-client-header">
                  <div className="field-label">
                    <span>{"DOB"}</span>
                  </div>
                  <FliptDatePicker
                    value={searchForm.date_of_birth}
                    disabled={!isName}
                    name="date_of_birth"
                    className="field-date-picker"
                    labelText="DOB"
                    onChange={this._updateSearchForm}
                  />
                </div>

                <div className="searchBtn">
                  <Button
                    icon="search"
                    className="filterbtn"
                    color="white"
                    size="mini"
                    disabled={
                      searchForm?.memberId && searchForm?.memberId.length < 5
                    }
                    name="SEARCH"
                    onClick={() => this._searchData("MEMBER_SEARCH")}
                  />
                  <Button
                    icon="remove"
                    className="filterbtn"
                    color="white"
                    size="mini"
                    onClick={this._clearMemberFields}
                  />
                </div>
              </div>
              {memberLookup.multiMemberLookup?.length ? (
                <div className="grid-container">
                  <FliptGrid
                    agGridRef={this.agGridRefMem}
                    onGridReadyCustom={this.onGridReady}
                    headers={headers}
                    data={memberLookup.multiMemberLookup}
                    rowSelection="single"
                    cellRendererParams={cellRendererParams}
                    onSelectionChanged={(data) => {
                      this.onMemberSelectionChanged(data);
                    }}
                  />
                </div>
              ) : memberList?.length ? (
                <div className="grid-container">
                  <FliptGrid
                    agGridRef={this.agGridRefMem}
                    onGridReadyCustom={this.onGridReady}
                    headers={headers}
                    data={memberList}
                    rowSelection="single"
                    cellRendererParams={cellRendererParams}
                    onSelectionChanged={(data) => {
                      this.onMemberSelectionChanged(data);
                    }}
                  />
                </div>
              ) : (
                <></>
              )}
              {eligibiltyList?.length ? (
                <>
                  <div className="member-header">
                    Select a plan below to view the details
                  </div>
                  <div className="grid-container">
                    <FliptGrid
                      agGridRef={this.agGridRefEl}
                      onGridReadyCustom={this.onElGridReady}
                      headers={eligibiltyHeader}
                      data={eligibiltyList}
                      rowSelection="single"
                      cellRendererParams={eligibilityCellRendererParams}
                      onSelectionChanged={(data) => {
                        this.onMemberEligibilitySelectionChanged(data);
                      }}
                    />
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </ModalDescription>
        </ModalContent>
        <ModalActions>
          <div className="member-buttons-container">
            <FliptButton
              disabled={selectedEligibilty == null}
              className="primary"
              name="View Details"
              onClick={() => this._submitResult()}
            />
          </div>
        </ModalActions>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  state: {
    memberLookup: state.memberLookup,
  },
});

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...MemberLookupActions,
    ...AppActions,
  };
  return {
    actions: bindActionCreators(allActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FliptMemberSearch);
