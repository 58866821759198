import Immutable from 'seamless-immutable'
import { createActions, createReducer } from 'reduxsauce'

export const INITIAL_STATE = Immutable({
    clinicalProgramsData: [],
    drugListData: [],
    formularyExportConfigurationList: [],
    groupConfigurationData: {},
    prAlternatesData: [],
    prExclusionsData: [],
    specialtyProgramsData: [],
})

export const setClinicalProgramsDataReducer = (state = INITIAL_STATE, { payload }) => ({
    ...state,
    clinicalProgramsData: payload,
})

export const setSpecialtyProgramsDataReducer = (state = INITIAL_STATE, { payload }) => ({
    ...state,
    specialtyProgramsData: payload,
})

export const setDrugListDataReducer = (state = INITIAL_STATE, { payload }) => ({
    ...state,
    drugListData: payload,
})


export const setPrAlternatesDataReducer = (state = INITIAL_STATE, { payload }) => ({
    ...state,
    prAlternatesData: payload,
})

export const setPrExclusionsDataReducer = (state = INITIAL_STATE, { payload }) => ({
    ...state,
    prExclusionsData: payload,
})

export const setGroupConfigurationDataReducer = (state = INITIAL_STATE, { payload }) => ({
    ...state,
    groupConfigurationData: payload,
})

export const clearFormularyExportConfigurationListReducer = () => INITIAL_STATE

export const clearReducer = () => INITIAL_STATE


export const { Types, Creators } = createActions({
    clearFormularyExportConfigurationList: null,
    clearReducer: null,
    formularyExportConfigurationSendForReview: ['payload', 'data'],
    getDrugList: ['payload'],
    getFormularyExportConfigurationDocument: ['payload'],
    getFormularyExportConfigurationList: ['payload'],
    getGroupConfigurationData: ['payload'],
    getPrAlternates: null,
    getPrExclusions: null,
    getSpecialtyPrograms: null,
    getClinicalPrograms: null,
    saveFormularyExportConfigurationData: ['payload'],
    setClinicalProgramsData: ['payload'],
    setDrugListData: ['payload'],
    setGroupConfigurationData: ['payload'],
    setPrAlternatesData: ['payload'],
    setPrExclusionsData: ['payload'],
    setSpecialtyProgramsData: ['payload'],
})


const HANDLERS = {
    [Types.CLEAR_FORMULARY_EXPORT_CONFIGURATION_LIST]: clearFormularyExportConfigurationListReducer,
    [Types.CLEAR_REDUCER]: clearReducer,
    [Types.SET_CLINICAL_PROGRAMS_DATA]: setClinicalProgramsDataReducer,
    [Types.SET_DRUG_LIST_DATA]: setDrugListDataReducer,
    [Types.SET_GROUP_CONFIGURATION_DATA]: setGroupConfigurationDataReducer,
    [Types.SET_PR_ALTERNATES_DATA]: setPrAlternatesDataReducer,
    [Types.SET_PR_EXCLUSIONS_DATA]: setPrExclusionsDataReducer,
    [Types.SET_SPECIALTY_PROGRAMS_DATA]: setSpecialtyProgramsDataReducer,
}

export default createReducer(INITIAL_STATE, HANDLERS)
