import React, { useMemo } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Icon } from 'semantic-ui-react';
import _ from "lodash";

import { Creators as ClientConfigurationActions } from "../../../../../redux/reducers/api/clientConfiguration";

import FliptAccordion from '../../../../../components/fliptAccordion';
import FliptButton from '../../../../../components/v2/fliptButton';
import FliptMultiSelectToolTipDropDown from '../../../../../components/fliptMultiSelectToolTipDropDown';
import FliptRadio from '../../../../../components/form/fliptRadio';
import FliptToolTipInput from '../../../../../components/fliptToolTipInput';
import POSRebateGrid from "./grid";
import Info from '../../Info';
import './styles.scss';

const umSectionRefTable = {
    'pos_rebates': 'posRebates',
}

const sectionToKeyPrefix = {
    'pos_rebates': 'pos_rebate',
}

const defaultDeductibleAllocation = {
    day_supply: '',
    network_tier: '',
    member_pos_rebate_type: '',
    member_pos_rebate_factor: '',
}

const POSRebateConfig = (props) => {
    const { state, actions, updateFields } = props;
    const { singleLevelAttribute, publishedUmObjects } = state;

    const {
        bill_client_rebate_amount_at_adjudication,
        pos_rebates,
    } = singleLevelAttribute;
    const { posRebates = [] } = publishedUmObjects
    const nullOption = { text: '', value: '' };

    const posRebatesSortedOptions = useMemo(() => {
        return [nullOption, ..._.cloneDeep(posRebates).map((posRebate) => ({
            text: posRebate.doc_name,
            value: posRebate.doc_id,
        }))];
    }, [posRebates]);

    const handleBenefitOverrideSelection = (section, dropdown) => {
        const { name, value } = dropdown
        const selectedSection = (singleLevelAttribute[section] || {}).value || [];
        if (selectedSection.find((obj) => obj.doc_id === value)) return;
        const newList = [...selectedSection];
        const umObjectList = publishedUmObjects[umSectionRefTable[section]] || [];
        const record = umObjectList.find((obj) => obj.doc_id === value);
        if (!record) return;
        let newProgram = {
            doc_id: record.doc_id,
            [`${sectionToKeyPrefix[section]}_id`]: record.doc_id,
            [`${sectionToKeyPrefix[section]}_name`]: record.doc_name,
            rank: newList.length + 1,
            version: record.version,
        };

        if (section === 'pos_rebates') {
            newProgram = {
                ...newProgram,
                member_rebate_percentage_of_total_rebate: null,
                deductible_met_rebate_allocation: [defaultDeductibleAllocation],
                deductible_not_met_rebate_allocation: [defaultDeductibleAllocation],
            }
        }
        newList.push(newProgram);
        actions.updateAttributeFields({ name: section, value: newList });
    }

    const removeOptionFromList = (section, id) => {
        const selectedSection = (singleLevelAttribute[section] || {}).value || [];
        const newList = selectedSection.reduce((newList, record) => {
            if (record.doc_id !== id) {
                newList.push(record);
                record.rank = newList.length;
            }
            return newList;
        }, []);
        actions.updateAttributeFields({ name: section, value: newList });
    }

    const handleDeductibleMetRebateAllocation = (parentPOSRebateIndex) => (el, dropdown, rowIndex, gridApi) => {
        const { name, value } = dropdown || el.currentTarget
        const newPOSRebates = (singleLevelAttribute.pos_rebates || {}).value || [];
        const posRebate = newPOSRebates[parentPOSRebateIndex];
        const newDeductibleMetRebateAllocation = _.cloneDeep(posRebate.deductible_met_rebate_allocation || []);
        newDeductibleMetRebateAllocation[rowIndex][name] = value
        posRebate.deductible_met_rebate_allocation = newDeductibleMetRebateAllocation;
        newPOSRebates[parentPOSRebateIndex] = posRebate
        actions.updateAttributeFields({ name: 'pos_rebates', value: newPOSRebates })
        gridApi.refreshCells()
    }

    const handleDeductibleNotMetRebateAllocation = (parentPOSRebateIndex) => (el, dropdown, rowIndex, gridApi) => {
        const { name, value } = dropdown || el.currentTarget
        const newPOSRebates = (singleLevelAttribute.pos_rebates || {}).value || [];
        const posRebate = newPOSRebates[parentPOSRebateIndex];
        const newDeductibleNotMetRebateAllocation = _.cloneDeep(posRebate.deductible_not_met_rebate_allocation || []);
        newDeductibleNotMetRebateAllocation[rowIndex][name] = value
        posRebate.deductible_not_met_rebate_allocation = newDeductibleNotMetRebateAllocation;
        newPOSRebates[parentPOSRebateIndex] = posRebate
        actions.updateAttributeFields({ name: 'pos_rebates', value: newPOSRebates })
        gridApi.refreshCells()
    }

    const addRow = (parentPOSRebateIndex, gridSection) => {
        const newPOSRebates = (singleLevelAttribute.pos_rebates || {}).value || [];
        const posRebate = newPOSRebates[parentPOSRebateIndex];
        const newDeductibleMetRebateAllocation = _.cloneDeep(posRebate[gridSection] || []);
        newDeductibleMetRebateAllocation.push(defaultDeductibleAllocation);
        posRebate[gridSection] = newDeductibleMetRebateAllocation;
        newPOSRebates[parentPOSRebateIndex] = posRebate
        actions.updateAttributeFields({ name: 'pos_rebates', value: newPOSRebates })
    }

    const deleteRow = (parentPOSRebateIndex, gridSection) => (rowIndex) => {
        const newPOSRebates = (singleLevelAttribute.pos_rebates || {}).value || [];
        const posRebate = newPOSRebates[parentPOSRebateIndex];
        const newDeductibleMetRebateAllocation = _.cloneDeep(posRebate[gridSection] || []).filter((_, index) => index !== rowIndex);
        posRebate[gridSection] = newDeductibleMetRebateAllocation;
        newPOSRebates[parentPOSRebateIndex] = posRebate
        actions.updateAttributeFields({ name: 'pos_rebates', value: newPOSRebates })
    }


    const updatePOSRebateFields = (parentPOSRebateIndex) => (el, dropdown) => {
        let { name, value } = dropdown || el.currentTarget;
        const newPOSRebates = (singleLevelAttribute.pos_rebates || {}).value || [];
        const posRebate = newPOSRebates[parentPOSRebateIndex];
        posRebate[name] = value;
        actions.updateAttributeFields({ name: 'pos_rebates', value: newPOSRebates });
    }

    return (
        <section className='page-container pos-rebate-config'>
            <section>
                <section className='input-container'>
                    <FliptMultiSelectToolTipDropDown
                        label='POS Rebate'
                        tooltip=''
                        options={posRebatesSortedOptions}
                        name="pos_rebates"
                        dropdownPlaceholder='Select POS Rebate'
                        onChange={(_, dropdown) => handleBenefitOverrideSelection('pos_rebates', dropdown)}
                        removeOptionFromList={(id) => removeOptionFromList('pos_rebates', id)}
                        selectedOptions={pos_rebates?.value || []}
                        overrideTextKey='pos_rebate_name'
                    />
                </section>
            </section>
            <section>
                {
                    pos_rebates?.value?.map((posRebate, index) => (
                        <FliptAccordion
                            key={`rebate-accordion-${index}`}
                            title={posRebate.pos_rebate_name}
                            removeItem={() => removeOptionFromList('pos_rebates', posRebate.pos_rebate_id)}
                        >
                            <section className='page-container accordion-body'>
                                <section>
                                    <FliptToolTipInput
                                        label='Member Rebate Percentage of Total Rebate'
                                        type='number'
                                        tooltip=''
                                        name={'member_rebate_percentage_of_total_rebate'}
                                        value={posRebate.member_rebate_percentage_of_total_rebate}
                                        onChange={updatePOSRebateFields(index)}
                                    />
                                </section>
                                <section>
                                    <h3 className='page-h3'>Member Rebate Allocation Logic</h3>
                                </section>
                                <section className='split-section'>
                                    <section className='grid-container'>
                                        <h4 className='page-h4'>Deductible Met</h4>
                                        <section className='grid-sub-container'>
                                            <POSRebateGrid
                                                data={posRebate.deductible_met_rebate_allocation || []}
                                                delRow={deleteRow(index, 'deductible_met_rebate_allocation')}
                                                handleChange={handleDeductibleMetRebateAllocation(index)}
                                            />
                                        </section>
                                        <section className="grid-actions">
                                            <FliptButton
                                                className="button-primary"
                                                name="Add Condition"
                                                icon={<Icon name="add" />}
                                                onClick={() => addRow(index, 'deductible_met_rebate_allocation')}
                                            />
                                        </section>
                                    </section>
                                    <section className='grid-container'>
                                        <h4 className='page-h4'>Deductible Not Met</h4>
                                        <section className='grid-sub-container'>
                                            <POSRebateGrid
                                                data={posRebate.deductible_not_met_rebate_allocation || []}
                                                delRow={deleteRow(index, 'deductible_not_met_rebate_allocation')}
                                                handleChange={handleDeductibleNotMetRebateAllocation(index)}
                                            />
                                        </section>
                                        <section className="grid-actions">
                                            <FliptButton
                                                className="button-primary"
                                                name="Add Condition"
                                                icon={<Icon name="add" />}
                                                onClick={() => addRow(index, 'deductible_not_met_rebate_allocation')}
                                            />
                                        </section>
                                    </section>
                                </section>
                            </section>
                        </FliptAccordion>
                    ))
                }
            </section>
            <section>
                <div className='fields-container'>
                    <div className={'fields-client-header'}>
                        <div className='field-label'>
                            <span />
                            <Info content={''} />
                        </div>
                        <div className='field-stylized radio-outline'>
                            <FliptRadio
                                label={'Bill Client Rebate Amount At The Time of Adjudication '}
                                name={'bill_client_rebate_amount_at_adjudication'}
                                fullLabel={true}
                                toggle
                                className={'field-center'}
                                onChange={updateFields}
                                checked={bill_client_rebate_amount_at_adjudication?.value === 'yes'}
                                stylized='true'
                                value={bill_client_rebate_amount_at_adjudication?.value}
                            />
                        </div>
                    </div>
                </div>
            </section>
        </section>
    );
};

const mapStateToProps = (state) => {
    return {
        state: {
            singleLevelAttribute: state.clientConfiguration.singleLevelAttribute,
            addMode: state.clientConfiguration.addMode,
            publishedUmObjects: state.globalEdits.publishedUmObjects || {},
            nodeType: state.clientConfiguration.nodeType,
            companyTab: state.clientConfiguration.companyTab,
            validations: state.clientConfiguration.validations,
        }

    };
};

const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...ClientConfigurationActions,
    };

    return {
        actions: bindActionCreators(allActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(POSRebateConfig);