import {
  call,
  put,
  select,
  takeLatest,
  take
} from 'redux-saga/effects'

import { Types, Creators as ClientImplementationActions } from '../../reducers/api/clientImplementation'
import { Creators as AppActions, Types as AppTypes } from '../../reducers/app'
import { getAppState, getApiPath, getClientImplementation, getHistory, isUserEmpty, isMenuEmpty } from '../../reducers/selectors'
import { Types as UserTypes } from '../../reducers/user'
import { fetchGet, fetchPost, fetchPut } from '../../../utils/fetchApi'
import { createQueryString, filterRowData } from '../../../utils/utilities'
export default [
  createClientImplementationWatcher,
  editClientImplementationWatcher,
  getAllClientImplementationWatcher,
  getAllImplementationTasksWatcher,
  getClientImplementationWatcher,
  getImplementationTaskObjectListWatcher,
]


/* WATCHERS */
function* getClientImplementationWatcher() {
  yield takeLatest(Types.GET_CLIENT_IMPLEMENTATION, getClientImplementationHandler)
}
function* getImplementationTaskObjectListWatcher() {
  yield takeLatest(Types.GET_IMPLEMENTATION_TASK_OBJECT_LIST, getImplementationTaskObjectListHandler)
}

function* getAllClientImplementationWatcher() {
  yield takeLatest(Types.GET_ALL_CLIENT_IMPLEMENTATION, getAllClientImplementationHandler)
}

function* createClientImplementationWatcher() {
  yield takeLatest(Types.CREATE_CLIENT_IMPLEMENTATION, createClientImplementationHandler)
}

function* editClientImplementationWatcher() {
  yield takeLatest(Types.EDIT_CLIENT_IMPLEMENTATION, editClientImplementationHandler)
}

function* getAllImplementationTasksWatcher() {
  yield takeLatest(Types.GET_ALL_IMPLEMENTATION_TASKS, getAllImplementationTasksHandler)
}

/* HANDLERS */
function* getImplementationTaskObjectListHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const { api_path } = yield select(getApiPath, 'client-task-objects')
    const url = `${serviceUrl}${api_path}`
    const { data } = yield call(fetchGet, url, payload)
    yield put(ClientImplementationActions.setImplementationTaskObjectList({ data }))
  } catch (err) {
    console.log('getImplementationTaskObjectList Error >Data ', err)
    const transitionalPortal = {
      header: 'Retrieving Client Implementation Task Object List Failed',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* getClientImplementationHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const { api_path } = yield select(getApiPath, 'client-implementation')
    const url = `${serviceUrl}${api_path}`
    const { data } = yield call(fetchGet, url, payload)
    yield put(ClientImplementationActions.setClientImplementation({ data }))
  } catch (err) {
    console.log('getClientImplementationHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Retrieving Client Implementation Failed',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* getAllClientImplementationHandler({ payload }) {
  try {
    const { form, params, headers } = payload
    const history = yield select(getHistory)
    const { itemsPerPage } = yield select(getClientImplementation)
    const { serviceUrl } = yield select(getAppState)
    let tempForm = { ...form }
    history.location.search = createQueryString({
      ...tempForm,
      offset: params?.request.startRow,
    })
    const qs = history.location.search ? `${history.location.search}&limit=${itemsPerPage}` : ''
    if (!qs) {
      params?.success({
        rowData: [],
        rowCount: 0,
      })
    } else {
      const empty = yield select(isMenuEmpty)
      if (empty) {
        yield take([AppTypes.SET_APP_SETTINGS])
      }
      const { api_path } = yield select(getApiPath, 'all-client-implementation')
      const url = `${serviceUrl}${api_path}`
      let res
      if (headers) {
        const { data, last_row, totalRows } = yield call(fetchGet, url)
        let gridData = data
        gridData = data?.map((d) => ({
          ...filterRowData(d, headers, {})
        }))
        params?.success({
          rowData: gridData,
          rowCount: last_row,
        })
      }
    }


    // const { api_path } = yield select(getApiPath, 'all-client-implementation')
    // const url = `${serviceUrl}${api_path}`
    // const { data } = yield call(fetchGet, url, payload)
    // yield put(ClientImplementationActions.setAllClientImplementationReducer({ data }))
  } catch (err) {
    console.log('getAllClientImplementationHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Retrieving All Client Implementations Failed',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* createClientImplementationHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const { api_path } = yield select(getApiPath, 'client-implementation')
    const url = `${serviceUrl}${api_path}`
    const { data } = yield call(fetchPost, url, payload)
    console.log(` data: ${data}`)
    const transitionalPortal = {
      header: 'Client Implementation Document',
      copy: "Saved Successfully!!",
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    yield ClientImplementationActions.clearReducer()
  } catch (err) {
    console.error('createClientImplementationHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Create New Client Implementation Failed',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* editClientImplementationHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const { api_path } = yield select(getApiPath, 'client-implementation')
    const url = `${serviceUrl}${api_path}`
    const { data } = yield call(fetchPut, url, payload)
    yield put(ClientImplementationActions.setEditClientImplmentationReducer({ data }))
  } catch (err) {
    console.log('editClientImplementationHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Saving Edited Client Implementation Failed',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}


function* getAllImplementationTasksHandler() {
  try {
    const userEmpty = yield select(isUserEmpty)
    if (userEmpty) {
      yield take([UserTypes.USER_UPDATE])
    }
    const { serviceUrl } = yield select(getAppState)
    const { api_path } = yield select(getApiPath, 'get-all-implementation-tasks')
    const url = `${serviceUrl}${api_path}`
    const { data } = yield call(fetchGet, url)
    yield put(ClientImplementationActions.setAllImplementationTasks({ data }))
  } catch (err) {
    console.log('getAllImplementationTasksHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Get All Implementation Tasks Data Handler Failed',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}
