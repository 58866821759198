import {
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects'

import { Types, Creators as ManualClaimEntryActions } from '../../reducers/api/manualClaimEntry'
import { Creators as NavigationActions } from '../../reducers/navigation'
import { Creators as MemberLookupActions } from '../../reducers/api/memberLookup'
import { Creators as AppActions } from '../../reducers/app'
import { getAppState, getApiPath } from '../../reducers/selectors'
import { fetchPut, fetchGet, fetchPost } from '../../../utils/fetchApi'
import { createQueryString } from '../../../utils/utilities'

export default [
  fetchManualClaimEntryNDCWatcher,
  fetchManualClaimEntryNPIWatcher,
  getManualClaimEntryDataWatcher,
  putManualClaimEntryWatcher,
]

/* WATCHERS */
function* getManualClaimEntryDataWatcher() {
  yield takeLatest(Types.GET_MANUAL_CLAIM_ENTRY_DATA, getManualClaimEntryDataHandler)
}

function* fetchManualClaimEntryNDCWatcher() {
  yield takeLatest(Types.FETCH_MANUAL_CLAIM_ENTRY_NDC, fetchManualClaimEntryNDCHandler)
}

function* putManualClaimEntryWatcher() {
  yield takeLatest(Types.PUT_MANUAL_CLAIM_ENTRY, puttManualClaimEntryHandler)
}

function* fetchManualClaimEntryNPIWatcher() {
  yield takeLatest(Types.FETCH_MANUAL_CLAIM_ENTRY_NPI, fetchManualClaimEntryNPIHandler)
}

/* HANDLERS */
function* getManualClaimEntryDataHandler({ payload }) {
  try {
    if (payload) {
      const qs = createQueryString(payload)
      yield put(NavigationActions.navigateTo({
        pathname: '/manual-claim-entry',
        search: qs,
      }))
    }

    yield put(ManualClaimEntryActions.fetchManualClaimEntryNDC())
    yield put(MemberLookupActions.fetchMemberLookupData())
  } catch (err) {
    console.log('getManualClaimEntryDataHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Data Fetch failed',
      copy: 'Unable to fetch manual claim entry data',
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* fetchManualClaimEntryNDCHandler({ payload }) {
  try {
    if (!payload.ndc) return
    if (!payload.noDataClearing) {
      yield put(ManualClaimEntryActions.clearManualClaimEntryNDC())
      delete payload.noDataClearing
    }
    const { serviceUrl } = yield select(getAppState)
    const { api_path } = yield select(getApiPath, 'ndc-lookup')
    const url = `${serviceUrl}${api_path}?ndc=${payload.ndc}`
    const response = yield call(fetchGet, url)
    if (!response.data.length) {
      const transitionalPortal = {
        header: 'Data Fetch failed',
        copy: 'No drug found for given NDC',
      }
      yield put(ManualClaimEntryActions.setNdcLookupFailed(true))
      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
      return
    }
    const claimData = response.data[0]
    yield put(ManualClaimEntryActions.setManualClaimEntryNDC(claimData))
  } catch (err) {
    console.log('fetchManualClaimEntryNDCHandler Error >Data ', err)
    throw err
  }
}

function* puttManualClaimEntryHandler(data) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const { api_path } = yield select(getApiPath, 'manual-claim-entry')
    const url = `${serviceUrl}${api_path}`
    const response = yield call(fetchPut, url, data.payload)

    if (response.data && response.data.length > 0) {
      const qs = createQueryString({ auth_id: response.data })
      yield put(NavigationActions.navigateTo({
        pathname: '/claim-detail',
        search: qs,
      }))
    } else {
      const transitionalPortal = {
        header: 'Error in retreiving data',
        copy: response.message,
      }
      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    }
  } catch (err) {
    console.log('puttManualClaimEntryHandler Error >Data ', err)
    throw err
  }
}

function* fetchManualClaimEntryNPIHandler({ payload }) {
  try {
    if (!payload) return

    const { serviceUrl } = yield select(getAppState)
    const reqData = {
      include_mail_order: false,
      pharmacynpi: payload,
      networkStatus: 'ACTIVE',
    }

    const qs = createQueryString(reqData)
    const { api_path } = yield select(getApiPath, 'pharmacy-lookup')
    const url = `${serviceUrl}${api_path}${qs}`
    const { data } = yield call(fetchPost, url)

    yield put(ManualClaimEntryActions.setManualClaimEntryNPI(data[0]))
  } catch (err) {
    console.log('fetchManualClaimEntryNDCHandler Error >Data ', err)
    throw err
  }
}

export {
  getManualClaimEntryDataHandler,
  fetchManualClaimEntryNDCHandler,
  puttManualClaimEntryHandler,
  fetchManualClaimEntryNPIHandler,
}
