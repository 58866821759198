import React from 'react'

import './styles.scss'

export default function FliptSeparator() {
  return (
    <div className="fliptSeparator">
      <hr />
    </div>
  )
}
