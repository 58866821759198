import React, { useState, useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Creators as AppActions } from '../../../../../redux/reducers/app'
import { Creators as ClaimsPRPActions } from '../../../../../redux/reducers/api/claimsProcessingAndReprocessing'
import './styles.scss'
import FliptDropdown from '../../../../../components/v2/fliptDropdown'
import FliptGrid from '../../../../../components/fliptGrid'
import FliptButton from '../../../../../components/v2/fliptButton'
import PlanPicker from '../../components/planPicker'

function BenefitPlans(props) {
	const { state, actions, editable, testBatchConfig, setTestBatchConfig } = props
	const { claimsProcessingAndReprocessing } = state
	const isDisabled = !editable
	useEffect(() => {
		if (!(testBatchConfig.plans.length > 0)) {
			actions.clearPlans()
		}
	}, [])

	const setBenefitPlans = () => {
		actions.getTestBatchConfigPlans({
			type: 'benefit_plan',
			form: {
				hierarchy: testBatchConfig.hierarchyDropdown,
				status: ["Published", "TEST_READY"]
			}
		})
	}

	const handleSelectionChange = (selectedRows) => {
		setTestBatchConfig((prevForm) => ({
			...prevForm,
			plans: [
				...selectedRows,
			],
		}))
	}

	return (
		<div id="testReadyBenefitPlanScreen">
			<div className="section">
				<div className="get-plan-button-container">
					<FliptButton
						className="primary"
						name="Pull Benefit Plans"
						onClick={() => setBenefitPlans()}
						disabled={isDisabled}
					/>
				</div>
				<h2>Available Benefit Plans</h2>
				<PlanPicker
					selectedPlans={testBatchConfig.plans}
					allPlans={claimsProcessingAndReprocessing.plans}
					onSelectionChange={handleSelectionChange}
					isDisabled={isDisabled}
				/>
			</div>
		</div>
	)
}

const mapStateToProps = (state) => ({
	state: {
		user: state.user,
		claimsProcessingAndReprocessing: state.claimsProcessingAndReprocessing,
	},
})

const mapDispatchToProps = (dispatch) => {
	const allActions = {
		...AppActions,
		...ClaimsPRPActions,
	}

	return {
		actions: bindActionCreators(allActions, dispatch),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(BenefitPlans)
