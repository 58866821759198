import Immutable from 'seamless-immutable'
import { createActions, createReducer } from 'reduxsauce'

export const INITIAL_STATE = Immutable({
  loading: false,
  testClaimData: [],
})

export const clearTestClaimReducer = () => INITIAL_STATE

export const setTestClaimDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  testClaimData: payload,
})


export const setLoadingReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  loading: payload.data,
})

export const { Types, Creators } = createActions({
  clearTestClaimReducer: null,
  submitTestClaimData: ['payload'],
  setTestClaimData: ['payload'],
  setLoading: ['payload'],
})

const HANDLERS = {
  [Types.CLEAR_TEST_CLAIM_REDUCER]: clearTestClaimReducer,
  [Types.SET_LOADING]: setLoadingReducer,
  [Types.SET_TEST_CLAIM_DATA]: setTestClaimDataReducer,
}

export default createReducer(INITIAL_STATE, HANDLERS)
