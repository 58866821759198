export default [
  {
    program_qualifier: 'Reward Share',
    program_operator: '=',
    program_value: '',
  },
  {
    program_qualifier: 'Penalty Share',
    program_operator: '=',
    program_value: '',
  },
  {
    program_qualifier: 'RBP Baseline',
    program_operator: '=',
    program_value: '',
  },
]
