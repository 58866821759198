import React, { useEffect, useState } from 'react'
import FliptButton from '../../../../components/form/fliptButton'
import FormRenderer from '../../../../components/form/formRender.js'
import { Popup } from 'semantic-ui-react'
import sectionMappings from './sectionMappings'
import './styles.scss'

export default (props) => {
  const {
    planDetails,
    activeTabIndex,
    customData,
    getTabContents,
    copyFormData,
    onSave,
    onNext,
    onPrev,
    context,
    editMode,
    saveButtonParams = {},
  } = props

  const [tabList, setTabList] = useState([])

  useEffect(() => {
    let data = [];
    for (const key in planDetails.details) {
      data.push(planDetails.details[key]['display_name']);
    }
    setTabList(data);
  }, [planDetails.details])


  const ellipseClicked = (item, index) => {
    const srollableDiv = document.getElementsByClassName('ui attached tabular menu')
    srollableDiv[0].scrollLeft = 0
    const scrollOffset = 280 * index
    srollableDiv[0].scrollLeft += scrollOffset
    getTabContents(item);
    setEllipsisFlag(!ellipsisFlag)
  }
  const {
    disabled: saveButtonDisabled,
    disabledMessage: saveButtonDisabledMessage,
  } = saveButtonParams

  const [ellipsisFlag, setEllipsisFlag] = useState(false);
  return (
    <div id="planDetails">
      <div className='action'>
        <i className='ellipsis horizontal link icon' onClick={() => setEllipsisFlag(!ellipsisFlag)}></i>
        {ellipsisFlag && (
          <div className='ellipsis-width'>
            {
              tabList && tabList.length ? (
                <>
                  {
                    tabList.map((item, index) => {
                      return (
                        <div className='item' onClick={() => ellipseClicked(item, index)}>
                          {
                            item === 'DEDUCTIBLES' ? <span>ACCUMULATORS</span> : (
                              item === 'DAW SETTINGS' ? <span>PSC (DAW)</span> : <span>{item}</span>
                            )
                          }
                        </div>
                      )
                    })
                  }
                </>
              )
                :
                ("")
            }
          </div>
        )}
      </div>
      <FormRenderer
        formData={planDetails}
        copyFormData={copyFormData}
        context={context}
        activeTabIndex={activeTabIndex}
        getTabContents={getTabContents}
        sectionMappings={sectionMappings}
        renderActionButtons={(formData) => editMode && (
          <div className="footer">
            <FliptButton
              className="primary nextButton"
              onClick={() => onPrev()}
              name="PREVIOUS"
            />
            <Popup disabled={!saveButtonDisabled} content={saveButtonDisabledMessage} trigger={
              <span>
                <FliptButton
                  className="primary searchButton"
                  onClick={() => onSave(formData, customData)}
                  name="SAVE"
                  disabled={saveButtonDisabled}
                />
              </span>
            } />
            <FliptButton
              className="primary nextButton"
              onClick={() => onNext()}
              name="NEXT"
            />
          </div>
        )}
      />
    </div>
  )
}