import {
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects'
// import jwt_decode from 'jwt-decode'

import { Creators as AppActions } from '../../reducers/app'
import { Creators as NavigationActions } from '../../reducers/navigation'
import {
  Creators as UserActions,
  Types as UserTypes,
} from '../../reducers/user'
import { fetchPost, fetchGet, fetchPut } from '../../../utils/fetchApi'
import { getAppState, getUserState, isUserEmpty } from '../../reducers/selectors'
import AppLocalStorage from '../../../utils/localStorage'
import { validatePassword } from '../../../utils/validationHelpers'

export default [
  activateUserWatcher,
  changePasswordWatcher,
  resetPasswordWatcher,
  userGetWatcher,
  userLoginSuccessfulWatcher,
  userLoginWatcher,
  userSSOLoginWatcher,
  userLogoutWatcher,
  userMFALoginWatcher,
  userVerifyCodeWatcher,
  userVerifyResetPasswordCodeWatcher,
  userGetCAGWatcher,
]

/* WATCHERS */
function* userGetWatcher() {
  yield takeLatest(UserTypes.USER_GET, userGetHandler)
}

function* userLoginWatcher() {
  yield takeLatest(UserTypes.USER_LOGIN, userLoginHandler)
}

function* userSSOLoginWatcher() {
  yield takeLatest(UserTypes.USER_SSO_LOGIN, userSSOLoginHandler)
}

function* userLoginSuccessfulWatcher() {
  yield takeLatest(UserTypes.USER_LOGIN_SUCCESSFUL, userLoginSuccessfulHandler)
}

function* userLogoutWatcher() {
  yield takeLatest(UserTypes.USER_LOGOUT, userLogoutHandler)
}

function* userMFALoginWatcher() {
  yield takeLatest(UserTypes.USER_MFA_LOGIN, userMFALoginHandler)
}

function* userVerifyCodeWatcher() {
  yield takeLatest(UserTypes.USER_VERIFY_CODE, userVerifyCodeHandler)
}

function* activateUserWatcher() {
  yield takeLatest(UserTypes.ACTIVATE_USER, activateUserHandler)
}

function* resetPasswordWatcher() {
  yield takeLatest(UserTypes.RESET_PASSWORD, resetPasswordHandler)
}

function* userVerifyResetPasswordCodeWatcher() {
  yield takeLatest(UserTypes.USER_VERIFY_RESET_PASSWORD_CODE, userVerifyResetPasswordCodeHandler)
}

function* changePasswordWatcher() {
  yield takeLatest(UserTypes.CHANGE_PASSWORD, changePasswordHandler)
}

function* userGetCAGWatcher() {
  yield takeLatest(UserTypes.USER_GET_CAG, userGetCAGHandler)
}

/* HANDLERS */
function* userGetHandler({ payload }) {
  try {
    const appState = yield select(getAppState)
    let url = `${appState.apiUrl}/fast/user`
    const isUserSearch = payload?.id

    if (isUserSearch) {
      url += `?id=${payload.id}`
    }

    const response = yield fetchGet(url)

    if (isUserSearch) {
      yield put(UserActions.setUserData(response))
    } else {
      yield put(AppActions.setAsLoggedInUser())
      yield put(UserActions.userUpdate(response))
    }
  } catch (err) {
    const transitionalPortal = {
      header: 'Invalid User',
      copy: err,
    }

    // yield put (UserActions.setUserData(response))
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    yield put(UserActions.userLogout())
    // yield put(NavigationActions.navigateTo({ pathname: '/login' }))

  }
}

function* userLoginSuccessfulHandler({ payload }) {
  AppLocalStorage.setItem('jwt', payload.jwt)
  payload.id = payload?.uuid
  yield put(UserActions.userUpdate(payload))
  yield put(AppActions.initializeLoggedInUser())
}

function* userLoginHandler({ payload }) {
  try {
    const appState = yield select(getAppState)

    if (appState.isLoggedIn) {
      throw Error('Already logged in')
    }

    const response = yield fetchPost(`${appState.apiUrl}/fast/user/login`, payload)
    const isMFALogin = String(response.status) === '202'

    if (isMFALogin) {
      yield put(NavigationActions.navigateTo({
        search: `?email_address=${response.data.email_address}`,
        pathname: '/mfa-login',
      }))
    } else {
      yield put(UserActions.userLoginSuccessful(response))
    }
  } catch (err) {
    const transitionalPortal = {
      header: 'Login Error',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* userSSOLoginHandler({ payload }) {
  try {
    const appState = yield select(getAppState)

    if (appState.isLoggedIn) {
      throw Error('Already logged in')
    }

    const response = yield fetchPost(`${appState.apiUrl}/fast/user/ssoLogin`, payload)

    yield put(UserActions.userLoginSuccessful(response))
  } catch (err) {
    const transitionalPortal = {
      header: 'SSO Login Error',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* userLogoutHandler() {
  try {
    const jwt = AppLocalStorage.getItem('jwt')

    yield put(AppActions.clearReducer())
    yield put(AppActions.toggleDataLoaded(false))
    yield put(AppActions.initializeLoggedOutUser())

    if (jwt) {
      const appState = yield select(getAppState)
      yield fetchPost(`${appState.apiUrl}/fast/user/logout`, { jwt })
    }

    localStorage.clear()
    yield put(NavigationActions.navigateTo({ pathname: '/login' }))
  } catch (err) {
    localStorage.clear()
    yield put(AppActions.initializeLoggedOutUser())
  }
}

function* userMFALoginHandler({ payload }) {
  try {
    const appState = yield select(getAppState)
    const response = yield fetchPost(`${appState.apiUrl}/fast/user/mfa-login`, payload)

    yield put(UserActions.userLoginSuccessful(response))
  } catch (err) {
    const transitionalPortal = {
      header: 'Login Error',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* userVerifyCodeHandler({ payload }) {
  try {
    const appState = yield select(getAppState)
    const { jwt } = yield fetchPost(`${appState.apiUrl}/fast/user/verify-code`, payload)
    AppLocalStorage.setItem('jwt', jwt)

    yield put(NavigationActions.navigateTo({
      pathname: '/change-password',
    }))
  } catch (err) {
    console.log('userVerifyCodeHandleruserVerifyCodeHandler ERROR ', err)
    const transitionalPortal = {
      header: 'Invalid Data',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* activateUserHandler({ payload }) {
  try {
    const appState = yield select(getAppState)

    yield fetchPost(`${appState.apiUrl}/fast/user/activate-user`, payload)
  } catch (err) {
    const transitionalPortal = {
      header: 'Invalid Data',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    yield put(NavigationActions.navigateTo({
      pathname: '/login',
    }))
  }
}

function* changePasswordHandler({ payload }) {
  try {
    const { new_password, confirm_password } = payload

    if (!validatePassword(new_password, confirm_password)) throw 'Passwords are invalid or do not match'

    const appState = yield select(getAppState)
    yield fetchPut(`${appState.apiUrl}/fast/user/change-password`, payload)

    const transitionalPortal = {
      header: 'Change Password',
      copy: 'Change Password Successful',
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    yield put(UserActions.userLogout())
    yield put(NavigationActions.navigateTo({
      pathname: '/login',
    }))
  } catch (err) {
    console.log('changePasswordHandler Error: ', err)
    const transitionalPortal = {
      header: 'Change Password Failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* resetPasswordHandler({ payload }) {
  try {
    const appState = yield select(getAppState)

    yield fetchPost(`${appState.apiUrl}/fast/user/reset-password`, payload)

    const transitionalPortal = {
      header: 'Verify User',
      copy: 'A code has been sent to your phone. Please verify.',
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  } catch (err) {
    const transitionalPortal = {
      header: 'Reset Password Failed',
      copy: err.message || err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    yield put(NavigationActions.navigateTo({
      pathname: '/login',
    }))
  }
}

function* userVerifyResetPasswordCodeHandler({ payload }) {
  try {
    const appState = yield select(getAppState)
    const { jwt } = yield fetchPost(`${appState.apiUrl}/fast/user/verify-reset-password-code`, payload)

    AppLocalStorage.setItem('jwt', jwt)
    yield put(NavigationActions.navigateTo({
      pathname: '/change-password',
    }))
  } catch (err) {
    const transitionalPortal = {
      header: 'Reset Password Failed',
      copy: err.message || err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* userGetCAGHandler({ callback }) {
  try {
    const userState = yield select(getUserState)
    const userCAG = {
      organization: [],
      client: [],
      carrier: [],
      account: [],
      group: [],
    }
    userState?.hierarchyAccess.forEach(org => {
      if (org?.access) userCAG['organization'].push(org?.name)
      org?.client?.forEach(client => {
        if (client?.access) userCAG['client'].push(client?.name)
        client?.carrier?.forEach(carrier => {
          if (carrier?.access) userCAG['carrier'].push(carrier?.name)
          carrier?.account?.forEach(account => {
            if (account?.access) userCAG['account'].push(account?.name)
            account?.group?.forEach(group => {
              if (group?.access) userCAG['group'].push(group?.name)
            })
          })
        })
      })
    })
    if (callback) {
      yield call(callback, userCAG)
    } else {
      yield put(UserActions.userSetCAG(userCAG))
    }
  } catch (err) {
    const transitionalPortal = {
      header: 'userGetCAG Failed',
      copy: err.message || err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

export function* getUserHierarchyAccess() {
  const userEmpty = yield select(isUserEmpty)
  if (userEmpty) {
    yield take([UserTypes.USER_UPDATE])
  }
  const userState = yield select(getUserState)
  const { hierarchyAccess = [] } = userState
  return hierarchyAccess
}

// FOR TESTING
export {
  userGetHandler,
  userLoginHandler,
  userSSOLoginHandler,
  userLoginSuccessfulHandler,
  userLogoutHandler,
  userMFALoginHandler,
  activateUserHandler,
  userVerifyCodeHandler,
  userGetCAGHandler,
}
