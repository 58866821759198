import {
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects'

import { Types, Creators as PrescriberLookupActions } from '../../reducers/api/prescriberLookup'
import { Creators as AppActions } from '../../reducers/app'
import {
  getAppState, getApiPath, getPrescriberLookup, getHistory,
} from '../../reducers/selectors'
import { fetchGet, fetchPost } from '../../../utils/fetchApi'

export default [
  getPrescriberLookupDataWatcher,
  getPrescriberSuggestDataWatcher,
]

/* WATCHERS */
function* getPrescriberLookupDataWatcher() {
  yield takeLatest(Types.GET_PRESCRIBER_LOOKUP_DATA, getPrescriberLookupDataHandler)
}

function* getPrescriberSuggestDataWatcher() {
  yield takeLatest(Types.GET_PRESCRIBER_SUGGEST_DATA, getPrescriberSuggestDataHandler)
}

/* HANDLERS */
// This needs to be again changed for FT-546 so keeping comments
function* getPrescriberLookupDataHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const { api_path } = yield select(getApiPath, 'prescriber-lookup')
    const url = `${serviceUrl}${api_path}`
    const { data } = yield call(fetchPost, url, payload)
    if (!data?.length) {
      const transitionalPortal = {
        header: 'Prescriber Lookup Error',
        copy: 'Error - We are unable to to find a prescriber that matches the search criteria',
      }

      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
      return
    }
    yield put(PrescriberLookupActions.setPrescriberLookupData(data))
  } catch (err) {
    console.log('getPrescriberLookupDataHandler Error >Data ', err)

    const transitionalPortal = {
      header: 'Prescriber Lookup Failed',
      copy: err
    }
    yield put(PrescriberLookupActions.setPrescriberLookupFailed(true))
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* getPrescriberSuggestDataHandler() {
  try {
    yield put(PrescriberLookupActions.setLoading({ data: true }))
    const { serviceUrl } = yield select(getAppState)
    const history = yield select(getHistory)

    const { prescribersPerPage } = yield select(getPrescriberLookup)
    const qs = history.location.search ? `${history.location.search}&limit=${prescribersPerPage}` : ''

    if (!qs) return

    const cd = yield select(getApiPath, 'prescriber-lookup')
    const { api_path } = cd
    const url = `${serviceUrl}${api_path}${qs}`
    const { data } = yield call(fetchGet, url, {}, false)
    if (!data.length) throw Error('No Prescribers match that name')

    yield put(PrescriberLookupActions.setPrescriberSuggestData({ data }))
    yield put(PrescriberLookupActions.setLoading({ data: false }))
  } catch (err) {
    console.log('getPrescriberSuggestDataHandler Error >Data ', err)

    const transitionalPortal = {
      header: 'Prescriber Lookup Failed',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    yield put(PrescriberLookupActions.setLoading({ data: false }))
  }
}

export {
  getPrescriberLookupDataHandler,
  getPrescriberSuggestDataHandler,
}
