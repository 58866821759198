import React, { PureComponent } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import './styles.scss'
import BreadCrumb from '../../../../../components/breadCrumb'
import Config from '../../../../../config'
import FliptInput from '../../../../../components/form/fliptInput'
import FliptDiv from '../../../../../components/form/fliptDiv'
import SubmenuNav from '../shared/navigation'
import { Creators as ApiActions } from '../../../../../redux/reducers/api/claimLookupManagement'

class ClaimTransactions extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      isVisible: {
        submitted: false,
        calculated: false,
        client: false,
        response: false,
      }
    }
  }

  componentDidMount() {
    const { actions, state: { user } } = this.props
    // TODO FIX THIS. SO NO MORE SETTIMEOUT
    this._updateState()
    setTimeout(() => actions.getClaimTransactionData(), Config.COMPONENT_DATA_LOAD_TIME)
  }

  componentDidUpdate(prevProps) {
    const { props } = this
    const prevState = prevProps.state
    const currState = props.state

    if (_.isEqual(prevState, currState)) return
    this._updateState(prevProps)
  }

  _updateState() {
    const { props, state: { isVisible } } = this
    const { state: { user } } = props

    const visibilityTable = {
      'claim-transaction-pricing-submitted': 'submitted',
      'claim-transaction-pricing-calculated': 'calculated',
      'claim-transaction-pricing-client': 'client',
      'claim-transaction-pricing-response': 'response',
    }

    const permissions = user?.permissions

    permissions.forEach(permission => {
      const p = Object.entries(permission)[0]
      const key = p[0]
      const value = p[1]
      if (visibilityTable[key]) isVisible[visibilityTable[key]] = true
    })

    this.setState({
      isVisible,
    })
  }

  _renderContent = () => {
    const { state } = this.props
    const { isVisible } = this.state
    const {
      costs,
      pharmacy_response,
      patient_pay,
      calculated,
      client
    } = state.claimTransactionData?.pricing_section
    const {
      claim_request: { incentive_amount_submitted, patient_paid_amount_submitted, gross_amount_due, usual_and_customary_charge },
      claim_response: { patient_pay_amount, incentive_amount_paid } } = state.claimTransactionData?.claim_request_and_response_section

    const {
      member_choice_penalty_amount,
      rewards,
      incentive_credit_applied,
      calculated_disp_fee,
      calculated_ing_cost,
      calculated_patient_pay_amount,
      calculated_plan_pay_amount,
      calculated_sales_tax,
      client_disp_fee_paid,
      client_ing_cost,
      client_patient_pay_amount,
      client_sales_tax,
      client_plan_pay_amount,
      submitted_disp_fee,
      submitted_flat_sales_tax_amount,
      submitted_percentage_sales_tax,
      submitted_penalty_amount,
      submitted_reward_amount,
      submitted_app_incentive_credit,
      calculated_percentage_sales_tax_amount_paid,
      calculated_flat_sales_tax_amount_paid,
      client_percentage_sales_tax_amount_paid,
      client_flat_sales_tax_amount_paid,
      submitted_ing_cost,
      submitted_patient_pay_amount,
      submitted_plan_pay_amount,
      submitted_unc,
      response_ingredient_cost_paid,
      response_dispensing_fee_paid,
      response_patient_pay_amount,
      response_sales_tax,
      pharmacy_amt_due,
      admin_fee,
      calculated_drug_cost,
      client_drug_cost_paid,
      flat_sales_tax_amount_paid,
      member_choice_penalty,
      percentage_sales_tax_amount_paid,
      rbp_penalty,
      rra_penalty_amount,
      total_amount_paid,
      client_employee_paid
    } = costs
    const {
      price_type,
      calculated_price_type,
      client_price_type,
      pharmacy_price_type,
      calculated_unit_price,
      client_unit_price,
      pharmacy_unit_price,
      calculated_claim_processor,
      client_claim_processor,
      pharmacy_claim_processor,
      unit_price,
      client_maclistid,
      pharmacy_maclistid,
      calculated_maclistid,
      deductible_accumulator_amount,
      oop_accumulator_amount,
      plancap_accumulator_amount,
    } = pharmacy_response
    const {
      copay_amt,
      copay_type,
      copay
    } = patient_pay

    let penalty_amt = 0;
    if (member_choice_penalty && member_choice_penalty != "") penalty_amt += member_choice_penalty
    if (rbp_penalty && rbp_penalty != "") penalty_amt += rbp_penalty;
    if (rra_penalty_amount && rra_penalty_amount != "") penalty_amt += rra_penalty_amount;

    const formatAmt = (num, decimals = 2) => {
      let n = num;
      if (n) {
        if (typeof (n) == 'string') {
          n = parseFloat(num)
        }
      } else {
        return "0.00"
      }
      return n.toLocaleString('en-US', {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
      });
    }

    return (
      <>
        <div className="section">
          <div className="container-fields grid">
            <div className='fields'>
              <div className='title'>Pricing Segment</div>
              <FliptDiv className='title' value='Submitted' visibilityGroup={'submitted'} visibilityTable={isVisible} />
              <FliptDiv className='title' value='Calculated' visibilityGroup={'calculated'} visibilityTable={isVisible} />
              <FliptDiv className='title' value='Client' visibilityGroup={'client'} visibilityTable={isVisible} />
              <FliptDiv className='title' value='Response' visibilityGroup={'response'} visibilityTable={isVisible} />
            </div>
            <div className='fields'>
              <div className="field-label field-label-light">Ingredient Cost</div>
              <FliptInput value={formatAmt(submitted_ing_cost)} disabled visibilityGroup={'submitted'} visibilityTable={isVisible} />
              <FliptInput value={formatAmt(calculated_ing_cost)} disabled visibilityGroup={'calculated'} visibilityTable={isVisible} />
              <FliptInput value={formatAmt(client_ing_cost)} disabled visibilityGroup={'client'} visibilityTable={isVisible} />
              <FliptInput value={formatAmt(response_ingredient_cost_paid)} disabled visibilityGroup={'response'} visibilityTable={isVisible} />
            </div>
            <div className='fields'>
              <div className="field-label">Dispensing Fee</div>
              <FliptInput value={formatAmt(submitted_disp_fee)} disabled visibilityGroup={'submitted'} visibilityTable={isVisible} />
              <FliptInput value={formatAmt(calculated_disp_fee)} disabled visibilityGroup={'calculated'} visibilityTable={isVisible} />
              <FliptInput value={formatAmt(client_disp_fee_paid)} disabled visibilityGroup={'client'} visibilityTable={isVisible} />
              <FliptInput value={formatAmt(response_dispensing_fee_paid)} disabled visibilityGroup={'response'} visibilityTable={isVisible} />
            </div>
            <div className='fields'>
              <div className="field-label field-label-light">Sales Tax Flat</div>
              <FliptInput value={formatAmt(submitted_flat_sales_tax_amount)} disabled visibilityGroup={'submitted'} visibilityTable={isVisible} />
              <FliptInput disabled value={formatAmt(calculated_flat_sales_tax_amount_paid)} visibilityGroup={'calculated'} visibilityTable={isVisible} />
              <FliptInput disabled value={formatAmt(client_flat_sales_tax_amount_paid)} visibilityGroup={'client'} visibilityTable={isVisible} />
              <FliptInput value={formatAmt(flat_sales_tax_amount_paid)} disabled visibilityGroup={'response'} visibilityTable={isVisible} />
            </div>
            <div className='fields'>
              <div className="field-label">Sales Tax Percentage</div>
              <FliptInput value={formatAmt(submitted_percentage_sales_tax)} disabled visibilityGroup={'submitted'} visibilityTable={isVisible} />
              <FliptInput disabled value={formatAmt(calculated_percentage_sales_tax_amount_paid)} visibilityGroup={'calculated'} visibilityTable={isVisible} />
              <FliptInput disabled value={formatAmt(client_percentage_sales_tax_amount_paid)} visibilityGroup={'client'} visibilityTable={isVisible} />
              <FliptInput value={formatAmt(percentage_sales_tax_amount_paid)} disabled visibilityGroup={'response'} visibilityTable={isVisible} />
            </div>
            <div className='fields'>
              <div className="field-label field-label-light">Admin/Incentive Fee</div>
              <FliptInput value={formatAmt(incentive_amount_submitted)} disabled visibilityGroup={'submitted'} visibilityTable={isVisible} />
              <FliptInput value={formatAmt(admin_fee)} disabled visibilityGroup={'calculated'} visibilityTable={isVisible} />
              <FliptInput value={formatAmt(admin_fee)} disabled visibilityGroup={'client'} visibilityTable={isVisible} />
              <FliptInput value={formatAmt(incentive_amount_paid)} disabled visibilityGroup={'response'} visibilityTable={isVisible} />
            </div>
            <div className='fields'>
              <div className="field-label">Patient Pay</div>
              <FliptInput value={formatAmt(patient_paid_amount_submitted)} disabled visibilityGroup={'submitted'} visibilityTable={isVisible} />
              <FliptInput value={formatAmt(calculated_patient_pay_amount)} disabled visibilityGroup={'calculated'} visibilityTable={isVisible} />
              <FliptInput value={formatAmt(client_employee_paid)} disabled visibilityGroup={'client'} visibilityTable={isVisible} />
              <FliptInput value={formatAmt(patient_pay_amount)} disabled visibilityGroup={'response'} visibilityTable={isVisible} />
            </div>
            <div className='fields'>
              <div className="field-label field-label-light">Amount Due</div>
              <FliptInput value={formatAmt(gross_amount_due)} disabled visibilityGroup={'submitted'} visibilityTable={isVisible} />
              <FliptInput value={formatAmt(calculated_plan_pay_amount)} disabled visibilityGroup={'calculated'} visibilityTable={isVisible} />
              <FliptInput value={formatAmt(client_plan_pay_amount)} disabled visibilityGroup={'client'} visibilityTable={isVisible} />
              <FliptInput value={formatAmt(total_amount_paid)} disabled visibilityGroup={'response'} visibilityTable={isVisible} />
            </div>
            <div className='fields'>
              <div className="field-label">U &amp; C</div>
              <FliptInput value={formatAmt(usual_and_customary_charge)} disabled visibilityGroup={'submitted'} visibilityTable={isVisible} />
              <FliptInput disabled visibilityGroup={'calculated'} visibilityTable={isVisible} />
              <FliptInput disabled visibilityGroup={'client'} visibilityTable={isVisible} />
              <FliptInput disabled visibilityGroup={'response'} visibilityTable={isVisible} />
            </div>

          </div>
        </div>

        <div className="section">
          <div className="container-fields grid">
            <div className='fields'>
              <div className='title'>Patient Pay</div>
              <div className="fields-empty"></div>
              <div className="fields-empty"></div>
              <div className="fields-empty"></div>
              <div className="fields-empty"></div>
            </div>
            <div className='fields'>
              <div className="field-label field-label-light">Copay</div>
              <div className="fields-empty"></div>
              <FliptInput value={copay_type === 'Fixed' ? formatAmt(copay_amt) : formatAmt(copay)} disabled visibilityGroup={'calculated'} visibilityTable={isVisible} />
              <FliptInput value={copay_type === 'Fixed' ? formatAmt(copay_amt) : formatAmt(copay)} disabled visibilityGroup={'client'} visibilityTable={isVisible} />
              <FliptInput value={copay_type === 'Fixed' ? formatAmt(copay_amt) : formatAmt(copay)} disabled visibilityGroup={'response'} visibilityTable={isVisible} />
            </div>
            <div className='fields'>
              <div className="field-label">Copay Basis</div>
              <div className="fields-empty"></div>
              <FliptInput value={copay_type} disabled visibilityGroup={'calculated'} visibilityTable={isVisible} />
              <FliptInput value={copay_type} disabled visibilityGroup={'client'} visibilityTable={isVisible} />
              <FliptInput value={copay_type} disabled visibilityGroup={'response'} visibilityTable={isVisible} />
            </div>
            <div className='fields'>
              <div className="field-label field-label-light">Product Selection Penalty</div>
              <div className="fields-empty"></div>
              <FliptInput value={formatAmt(penalty_amt)} disabled visibilityGroup={'calculated'} visibilityTable={isVisible} />
              <FliptInput value={formatAmt(penalty_amt)} disabled visibilityGroup={'client'} visibilityTable={isVisible} />
              <FliptInput value={formatAmt(penalty_amt)} disabled visibilityGroup={'response'} visibilityTable={isVisible} />
            </div>
            <div className='fields'>
              <div className="field-label">Attributed to Deductible</div>
              <div className="fields-empty"></div>
              <FliptInput value={formatAmt(deductible_accumulator_amount)} disabled visibilityGroup={'calculated'} visibilityTable={isVisible} />
              <FliptInput value={formatAmt(deductible_accumulator_amount)} disabled visibilityGroup={'client'} visibilityTable={isVisible} />
              <FliptInput value={formatAmt(deductible_accumulator_amount)} disabled visibilityGroup={'response'} visibilityTable={isVisible} />
            </div>
            <div className='fields'>
              <div className="field-label field-label-light">Attributed to Benefit Maximum</div>
              <div className="fields-empty"></div>
              <FliptInput value={formatAmt(plancap_accumulator_amount)} disabled visibilityGroup={'calculated'} visibilityTable={isVisible} />
              <FliptInput value={formatAmt(plancap_accumulator_amount)} disabled visibilityGroup={'client'} visibilityTable={isVisible} />
              <FliptInput value={formatAmt(plancap_accumulator_amount)} disabled visibilityGroup={'response'} visibilityTable={isVisible} />
            </div>
          </div>
        </div>

        <div className="section">
          <div className="container-fields grid">
            <div className='fields'>
              <div className='title'>Pricing Information</div>
              <div className="fields-empty"></div>
              <div className="fields-empty"></div>
              <div className="fields-empty"></div>
              <div className="fields-empty"></div>
            </div>
            <div className='fields'>
              <div className="field-label field-label-light">Price Type</div>
              <div className="fields-empty"></div>
              <FliptInput value={calculated_price_type} disabled visibilityGroup={'calculated'} visibilityTable={isVisible} />
              <FliptInput value={client_price_type} disabled visibilityGroup={'client'} visibilityTable={isVisible} />
              <FliptInput value={pharmacy_price_type} disabled visibilityGroup={'response'} visibilityTable={isVisible} />
            </div>
            <div className='fields'>
              <div className="field-label">Unit Price</div>
              <div className="fields-empty"></div>
              <FliptInput value={calculated_unit_price} disabled visibilityGroup={'calculated'} visibilityTable={isVisible} />
              <FliptInput value={client_unit_price} disabled visibilityGroup={'client'} visibilityTable={isVisible} />
              <FliptInput value={pharmacy_unit_price} disabled visibilityGroup={'response'} visibilityTable={isVisible} />
            </div>
            <div className='fields'>
              <div className="field-label field-label-light">Payer</div>
              <div className="fields-empty"></div>
              <FliptInput value={calculated_claim_processor} disabled visibilityGroup={'calculated'} visibilityTable={isVisible} />
              <FliptInput value={client_claim_processor} disabled visibilityGroup={'client'} visibilityTable={isVisible} />
              <FliptInput value={pharmacy_claim_processor} disabled visibilityGroup={'response'} visibilityTable={isVisible} />
            </div>
            <div className='fields'>
              <div className="field-label">MAC List</div>
              <div className="fields-empty"></div>
              <FliptInput value={calculated_maclistid} disabled visibilityGroup={'calculated'} visibilityTable={isVisible} />
              <FliptInput value={client_maclistid} disabled visibilityGroup={'client'} visibilityTable={isVisible} />
              <FliptInput value={pharmacy_maclistid} disabled visibilityGroup={'response'} visibilityTable={isVisible} />
            </div>
            <div className='fields'>
              <div className="field-label field-label-light">Applied to Deductible</div>
              <div className="fields-empty"></div>
              <FliptInput value={formatAmt(deductible_accumulator_amount)} disabled visibilityGroup={'calculated'} visibilityTable={isVisible} />
              <FliptInput value={formatAmt(deductible_accumulator_amount)} disabled visibilityGroup={'client'} visibilityTable={isVisible} />
              <FliptInput value={formatAmt(deductible_accumulator_amount)} disabled visibilityGroup={'response'} visibilityTable={isVisible} />
            </div>
            <div className='fields'>
              <div className="field-label">Applied to Out of Pocket</div>
              <div className="fields-empty"></div>
              <FliptInput value={formatAmt(oop_accumulator_amount)} disabled visibilityGroup={'calculated'} visibilityTable={isVisible} />
              <FliptInput value={formatAmt(oop_accumulator_amount)} disabled visibilityGroup={'client'} visibilityTable={isVisible} />
              <FliptInput value={formatAmt(oop_accumulator_amount)} disabled visibilityGroup={'response'} visibilityTable={isVisible} />
            </div>
            <div className='fields'>
              <div className="field-label field-label-light">Applied to Benefit Maximum</div>
              <div className="fields-empty"></div>
              <FliptInput value={formatAmt(plancap_accumulator_amount)} disabled visibilityGroup={'calculated'} visibilityTable={isVisible} />
              <FliptInput value={formatAmt(plancap_accumulator_amount)} disabled visibilityGroup={'client'} visibilityTable={isVisible} />
              <FliptInput value={formatAmt(plancap_accumulator_amount)} disabled visibilityGroup={'response'} visibilityTable={isVisible} />
            </div>
            <div className='fields'>
              <div className="field-label">Reward Amount</div>
              <div className="fields-empty"></div>
              <FliptInput value={formatAmt(rewards)} disabled visibilityGroup={'calculated'} visibilityTable={isVisible} />
              <FliptInput value={formatAmt(rewards)} disabled visibilityGroup={'client'} visibilityTable={isVisible} />
              <FliptInput value={formatAmt(rewards)} disabled visibilityGroup={'response'} visibilityTable={isVisible} />
            </div>
            <div className='fields'>
              <div className="field-label field-label-light">App Incentive Credit</div>
              <div className="fields-empty"></div>
              <FliptInput value={formatAmt(incentive_credit_applied)} disabled visibilityGroup={'calculated'} visibilityTable={isVisible} />
              <FliptInput value={formatAmt(incentive_credit_applied)} disabled visibilityGroup={'client'} visibilityTable={isVisible} />
              <FliptInput value={formatAmt(incentive_credit_applied)} disabled visibilityGroup={'response'} visibilityTable={isVisible} />
            </div>
          </div>
        </div>


        {/* <div className="section">
          <div className="fields-container grid">
            <div className="fields">
              <div className="spacer field-label">&nbsp;</div>
              <div className="field-label"> Ingredient Cost</div>
              <div className="field-label"> Disp Fee</div>
              <div className="field-label"> Admin Fee</div>
              <div className="field-label"> Flat Sales Tax</div>
              <div className="field-label"> Percentage Sales Tax</div>
              <div className="field-label"> Penalty Amount</div>
              <div className="field-label"> Reward Amount</div>
              <div className="field-label"> App Incentive Credit</div>
              <div className="field-label">Pat Pay</div>
              <div className="field-label">U &amp; C</div>
              <div className="field-label">Amount Due</div>
              <div className="field-label">OOP Accumulator Amount</div>
            </div>
            <div className="fields">
              <div className="title">Submitted</div>
              <FliptInput value={submitted_ing_cost} disabled />
              <FliptInput value={submitted_disp_fee} disabled />
              <FliptInput disabled />
              <FliptInput value={submitted_flat_sales_tax_amount} disabled />
              <FliptInput value={submitted_percentage_sales_tax} disabled />
              <FliptInput disabled />
              <FliptInput disabled />
              <FliptInput disabled />
              <FliptInput value={submitted_patient_pay_amount} disabled />
              <FliptInput value={submitted_unc} disabled />
              <FliptInput value={submitted_plan_pay_amount} disabled />
              <FliptInput disabled />
            </div>
            <div className="fields">
              <div className="title">Calculated</div>
              <FliptInput value={calculated_ing_cost} disabled />
              <FliptInput value={calculated_disp_fee} disabled />
              <FliptInput value={admin_fee} disabled />
              <FliptInput disabled />
              <FliptInput disabled />
              <FliptInput value={penalty_amt} disabled />
              <FliptInput value={rewards} disabled />
              <FliptInput value={incentive_credit_applied} disabled />
              <FliptInput value={calculated_patient_pay_amount} disabled />
              <FliptInput disabled />
              <FliptInput value={calculated_drug_cost} disabled />
              <FliptInput value={oop_accumulator_amount} disabled />
            </div>
            <div className="fields">
              <div className="title">Client</div>
              <FliptInput value={client_ing_cost} disabled />
              <FliptInput value={client_disp_fee_paid} disabled />
              <FliptInput value={admin_fee} disabled />
              <FliptInput disabled />
              <FliptInput disabled />
              <FliptInput value={penalty_amt} disabled />
              <FliptInput value={rewards} disabled />
              <FliptInput value={incentive_credit_applied} disabled />
              <FliptInput value={client_employee_paid} disabled />
              <FliptInput disabled />
              <FliptInput value={client_drug_cost_paid} disabled />
              <FliptInput value={oop_accumulator_amount} disabled />
            </div>
            <div className="fields">
              <div className="title">Pharmacy Response</div>
              <FliptInput value={response_ingredient_cost_paid} disabled />
              <FliptInput value={response_dispensing_fee_paid} disabled />
              <FliptInput value={admin_fee} disabled />
              <FliptInput value={flat_sales_tax_amount_paid} disabled />
              <FliptInput value={percentage_sales_tax_amount_paid} disabled />
              <FliptInput value={penalty_amt} disabled />
              <FliptInput value={rewards} disabled />
              <FliptInput value={incentive_credit_applied} disabled />
              <FliptInput value={response_patient_pay_amount} disabled />
              <FliptInput disabled />
              <FliptInput disabled value={total_amount_paid} />
              <FliptInput value={oop_accumulator_amount} disabled />
            </div>
          </div>
        </div>
        <div className="section">
          <h2>Pharmacy Response</h2>
          <div className="fields-container">
            <div className="fields">
              <FliptInput value={price_type} label="Price Type" stylized />
            </div>
            <div className="fields">
              <FliptInput value={unit_price} label="Unit Price" stylized />
            </div>
            <div className="fields">
              <FliptInput value={maclistid} label="MAC List" stylized />
            </div>
          </div>
        </div>
        <div className="section">
          <h2>Client</h2>
          <div className="fields-container">
            <div className="fields client-fields">
              <FliptInput value={price_type} label="Price Type" stylized />
              <FliptInput value={deductible_accumulator_amount} label="AttribTODed" stylized />
            </div>
            <div className="fields client-fields">
              <FliptInput value={unit_price} label="Unit Price" stylized />
              <FliptInput value={oop_accumulator_amount} label="Attributed Out Of Pocket" stylized />
            </div>
            <div className="fields client-fields">
              <FliptInput value={maclistid} label="MAC List" stylized />
            </div>
          </div>
        </div>
        <div className="section">
          <h2>Calculated</h2>
          <div className="fields-container">
            <div className="fields">
              <FliptInput value={price_type} label="Price Type" stylized />
            </div>
            <div className="fields">
              <FliptInput value={unit_price} label="Unit Price" stylized />
            </div>
            <div className="fields">
              <FliptInput value={maclistid} label="MAC List" stylized />
            </div>
          </div>
        </div>
        <div className="section">
          <h2>Patient Pay</h2>
          <div className="fields-container">
            <div className="fields">
              <FliptInput value={copay_amt} label="Copay Amount" stylized />
            </div>
            <div className="fields">
              <FliptInput value={copay_type} label="Copay Type" stylized />
            </div>
            <div className="fields">
              <FliptInput value={deductible_accumulator_amount} label="Amount Towards Deductable" stylized />
            </div>
            <div className="fields">
              <FliptInput label="Penalties" stylized />
            </div>
          </div>
        </div> */}
      </>
    )
  }

  _renderEmptyContent = () => (
    <div>Unavailable Data</div>
  )

  render() {
    const { state, history } = this.props
    const { claimTransactionData } = state
    const hasContent = claimTransactionData?.pricing_section ? Object.keys(claimTransactionData.pricing_section) : []

    return (
      <div id="claimTransactionPricing">
        <BreadCrumb {...this.props} />
        <div className="header">
          <h1>Transaction</h1>
          <SubmenuNav history={history} active="pricing" />
        </div>
        <div className="subhead" />
        <div className="content">
          {hasContent.length ? this._renderContent() : this._renderEmptyContent()}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  state: {
    claimTransactionData: state.claimLookup.claimTransactionData,
    user: state.user,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...ApiActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClaimTransactions)
