import { call, put, select, take, takeLatest } from "redux-saga/effects";
import { v4 as uuidv4 } from "uuid";
import { cloneDeep, update } from "lodash";

import {
  Creators as UserActions,
  Types as UserTypes,
} from '../../reducers/user'
import {
  Types,
  Creators as ClientConfigurationActions,
} from "../../reducers/api/clientConfiguration";
import {
  Creators as ClientConfiguration,

} from "../../reducers/api/clientConfiguration";
import { Types as GlobalEditsTypes, Creators as GlobalEditsActions } from '../../reducers/api/globalEdits'
import { Creators as groupManagementActions } from '../../reducers/api/groupManagement'
import {
  getClientConfigurationTab,
  getClientConfiguration,
  getAppState,
  getApiPath,
  getUserState,
  getNodeType,
  getAllData,
  getGroupManagement,
} from "../../reducers/selectors";
import { Creators as AppActions, Types as AppTypes } from '../../reducers/app'
import { fetchGet, fetchPost, fetchPatch, fetchPut } from "../../../utils/fetchApi";
import { DEFAULT_ERROR_HEADER, formatErrorMessage } from "../../../utils/utilities";
import { getHierarchy } from "../../../pages/private/clientConfiguration/utils";

export default [testValidationWatcher, handleNextStepWatcher, getHierarchyDetailWatcher,
  handleSaveWatcher, getDefaultHierarchyListWatcher, createSingleLevelWatcher];

/* WATCHERS */
function* testValidationWatcher() {
  yield takeLatest(Types.TEST_VALIDATION, testValidationHandler);
}
function* handleNextStepWatcher() {
  yield takeLatest(Types.HANDLE_SUBMIT, handleSubmitHandler);
}
function* handleSaveWatcher() {
  yield takeLatest(Types.HANDLE_SAVE, handleSaveHandler);
}
function* createSingleLevelWatcher() {
  yield takeLatest(Types.CREATE_SINGLE_LEVEL, createSingleLevelHandler);
}

function* getHierarchyDetailWatcher() {
  yield takeLatest(Types.GET_HIERARCHY_DETAIL, getHierarchyDetailHandler)
}
function* getDefaultHierarchyListWatcher() {
  yield takeLatest(Types.GET_DEFAULT_HIERARCHY_LIST, getDefaultHierarchyListHandler)
}


function* getDefaultHierarchyListHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const { api_path } = yield select(getApiPath, 'hierarchy-get-default-attr')
    const url = `${serviceUrl}${api_path}`
    const { data } = yield call(fetchPost, url, payload)
    if (!data.length) {
      const transitionalPortal = {
        header: 'Data Not Found',
        copy: 'No default Hierarchy list Found',
      }
      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
      return
    }
    yield put(ClientConfiguration.setSingleLevelAttribute(data[0]))
  } catch (err) {
    console.log('getDefaultHierarchyListHandler Error > Data: ', err)
    const transitionalPortal = {
      header: 'get default Hierarchy  Failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}
function* getHierarchyDetailHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const { api_path } = yield select(getApiPath, 'hierarchy-configuration')
    const url = `${serviceUrl}${api_path}`
    const { data } = yield call(fetchPost, url, payload)
    if (!data.length) {
      const transitionalPortal = {
        header: 'Data Not Found',
        copy: 'No Hierarchy Found',
      }
      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
      return
    }
    yield put(ClientConfiguration.setAllData(data))
  } catch (err) {
    console.log('getHierarchyDetailHandler Error > Data: ', err)
    const transitionalPortal = {
      header: 'get Hierarchy  Failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* getTabName() {
  const tabName = yield select(getClientConfigurationTab);
  switch (tabName) {
    case "BILLING":
      return "billingTab";
    case "REQUIRED":
      return "requiredTab";
    case "COMPANY":
      return "companyTab";
    case "COMMUNICATION":
      return "communicationTab";
    case "APP/WEB":
      return "appWebTab";
    case "REWARDS":
      return "rewardTab";
    case "GLOBAL":
      return "globalEditTab";
    case "SYSTEM":
      return "systemTab";
    case "OTHER":
      return "otherTab";
    default:
      return "";
  }
}
//hierarchy-get-default-attr
/* HANDLERS */
function* testValidationHandler({ payload }) {
  const { screen } = payload;
  try {
    const rawFileConfiguration = yield select(getClientConfiguration);
    const tabName = yield call(getTabName);
    const sectionToTest = rawFileConfiguration.singleLevelAttribute;
    const validations = rawFileConfiguration.validations['requiredTab'] || {};
    const fields = Object.keys(sectionToTest);
    let isAllValid = true;
    const validationResults = {};
    validationResults['requiredTab'] = fields.reduce((acc, field) => {
      const fieldValidation = validations[field];
      const fieldValue = sectionToTest[field]?.value;
      // field by default is valid, set to true if not required and no value
      acc[field] = true;
      if (!fieldValidation) return acc;
      const { type, validation, required } = fieldValidation;
      if (required || fieldValue) {
        acc[field] = (validation(fieldValue) && typeof fieldValue === type)
      }
      if (!acc[field]) {
        isAllValid = false;
      }
      return acc;
    }, {});
    validationResults['requiredTab'].isAllValid = isAllValid;
    yield put(
      ClientConfigurationActions.updateValidationResult(validationResults)
    );
  } catch (err) {
    console.log(err);
  }
}

function* handleSubmitHandler() {
  try {
    const screen = yield select(getClientConfigurationTab);
    yield call(testValidationHandler, { payload: { screen } });
    const rawFileConfiguration = yield select(getClientConfiguration);
    const screenValidationResults =
      rawFileConfiguration.validationResults[screen];
    if (screenValidationResults.isAllValid) {
      // yield put(FileConfigurationActions.nextPage())
    }
  } catch (err) { }
}

// flattens payload from 
// payload = {
//   data: {
//     attributes: {
//       someAttribute: {
//         src: 'someSource',
//         value: 'actualAttributeValue'
//       }
//       // ...more properties like someAttribute
//     }
//   }
// }
// to
// flattenedPayloadData = {
//   data: {
//     attributes: {
//       someAttribute: 'actualAttributeValue'
//     }
//   }
// }
const flattenHierarchyPayload = (payloadData) => {
  const flattenedPayloadData = cloneDeep(payloadData)
  if (payloadData?.node_key?.value) {
    flattenedPayloadData.node_key = payloadData.node_key.value
  }
  if (payloadData?.node_name?.value) {
    flattenedPayloadData.node_name = payloadData.node_name.value
  }

  for (const key in payloadData.attributes) {
    const currentAttribute = payloadData.attributes[key]
    if (typeof currentAttribute === 'object' &&
      !Array.isArray(currentAttribute) &&
      currentAttribute !== null) {
      flattenedPayloadData.attributes[key] = currentAttribute.value
    }
  }
  return flattenedPayloadData
}

function* prepareCreateGroupPayload(hierarchyUpdatePayload) {
  const groupManagement = yield select(getGroupManagement)
  const effectiveGroupRecord = groupManagement.effectiveGroupRecord
  if (Object.keys(effectiveGroupRecord).length) {

    const readOnlyGroups = groupManagement.defaultGroupData.group
    let isGroupChanged = false
    if (readOnlyGroups.length) {
      const effectiveReadOnlyRecord = readOnlyGroups.at(-1)
      const differences = Object.keys(effectiveReadOnlyRecord).filter((key) => effectiveGroupRecord[key] != effectiveReadOnlyRecord[key])
      // doc_id and meta_id will always be different between effectiveReadOnlyRecord and effectiveGroupRecord(meta_id = '', doc_id = '')
      // if more than 1 values are different, group data has been edited by the user
      if (differences.length > 2) isGroupChanged = true
    } else {
      // if readOnlyGroups is empty, new group is being created
      isGroupChanged = true
    }
    if (isGroupChanged) {
      const updateAttributes = hierarchyUpdatePayload.data.attributes
      const groupDetails = {
        ...effectiveGroupRecord,
        group_address: (updateAttributes.address1 || '') + (updateAttributes.address2 || ''),
        group_city: updateAttributes.city,
        group_fax: updateAttributes.contact_fax,
        group_phone: updateAttributes.contact_phone1,
        group_state: updateAttributes.state,
        group_zip_code: updateAttributes.zip,
      }
      const groupHierarchyData = groupManagement?.defaultGroupData?.cag
      return {
        ...groupHierarchyData,
        ...groupDetails,
      }
    }
  }
  return null
}


function* createSingleLevelHandler({ payload }) {

  let node_type = payload.data.node_type;
  let pageStep = payload.pageToMove;
  let addAnother = payload.addAnother;
  const updatePayload = cloneDeep(payload)
  delete updatePayload.addAnother;
  delete updatePayload.pageToMove;
  updatePayload.data = flattenHierarchyPayload(payload.data)
  const { uuid } = yield select(getUserState)
  updatePayload.user_id = uuid;
  try {
    const screen = yield call(getTabName);
    yield call(testValidationHandler, { payload: { screen } });
    const rawFileConfigration = yield select(getClientConfiguration);
    if (rawFileConfigration.nodeId) {
      yield call(handleSaveHandler, {
        payload: {
          node_id: rawFileConfigration.nodeId,
          data: {
            attributes: updatePayload.data.attributes
          }
        }
      });
      if (addAnother) {
        yield put(ClientConfiguration.setSingleLevelAttribute({}))
        let data = {
          node_type: node_type == 1 ? 'organization' : node_type == 2 ? 'client' :
            node_type == 3 ? 'carrier' : node_type == 4 ? 'account' : 'group'
        }
        yield put(ClientConfigurationActions.getDefaultHierarchyList(data))
        yield put(ClientConfiguration.setNodeId(''))
      }
      else if (pageStep) {
        let node_Type = {
          node_type: node_type == 1 ? 'client' : node_type == 2 ? 'carrier' :
            node_type == 3 ? 'account' : node_type == 4 ? 'group' : 'group'
        }
        yield put(ClientConfiguration.setSingleLevelAttribute({}))
        yield put(ClientConfigurationActions.getDefaultHierarchyList(node_Type))
        yield put(ClientConfigurationActions.setStep(pageStep))
        yield put(ClientConfiguration.setParentId(rawFileConfigration.nodeId))
        yield put(ClientConfiguration.setNodeId(''))
      }
      return
    }
    const screenValidationResults =
      rawFileConfigration.validationResults['requiredTab'];
    if (screenValidationResults.isAllValid) {
      const { serviceUrl } = yield select(getAppState)
      const { api_path } = yield select(getApiPath, 'hierarchy-configuration-create')
      const url = `${serviceUrl}${api_path}`

      const userState = yield select(getUserState)
      let orgKey = null
      // TODO: If we're creating a child node immediately after creating the Organization, the org_key will not be in the User Access
      if (updatePayload?.parent_id) {
        orgKey = Object.entries(userState.newHierarchyAccess)?.find(([key, value]) => value?.includes(updatePayload?.parent_id))?.[0]
        if (!orgKey) {
          orgKey = userState?.userOrgKey
        }
      } else {
        orgKey = updatePayload?.data?.node_key
      }
      updatePayload.org_key = orgKey
      yield put(UserActions.userSetOrgKey(orgKey))
      const { data } = yield call(fetchPut, url, updatePayload);
      if (!data.length) {
        const transitionalPortal = {
          header: 'Data Not Found',
          copy: 'No Data Found',
        }
        yield put(AppActions.displayTransitionalPortal(transitionalPortal))
        return
      } else {
        const nodeType = yield select(getNodeType);
        const groupMgt = yield select(getGroupManagement);
        if (nodeType === 5 || nodeType === 4) {
          const createGroupPayload = yield call(prepareCreateGroupPayload, updatePayload)
          if (createGroupPayload) {
            yield put(groupManagementActions.createGroup({ ...createGroupPayload, fromHierarchy: true, node_id: data[0].node_id }))
          }
        }
        const transitionalPortal = {
          header: 'Created',
          copy: 'Created Successfully',
        }
        yield put(AppActions.displayTransitionalPortal(transitionalPortal))

        if (addAnother) {
          yield put(ClientConfiguration.setSingleLevelAttribute({}))
          let data = {
            node_type: node_type == 1 ? 'organization' : node_type == 2 ? 'client' :
              node_type == 3 ? 'carrier' : node_type == 4 ? 'account' : 'group'
          }
          yield put(ClientConfigurationActions.getDefaultHierarchyList(data))
        }
        else if (pageStep) {
          let node_Type = {
            node_type: node_type == 1 ? 'client' : node_type == 2 ? 'carrier' :
              node_type == 3 ? 'account' : node_type == 4 ? 'group' : 'group'
          }
          yield put(ClientConfiguration.setSingleLevelAttribute({}))
          yield put(ClientConfigurationActions.getDefaultHierarchyList(node_Type))
          yield put(ClientConfigurationActions.setStep(pageStep))
          yield put(ClientConfiguration.setParentId(data[0].node_id))
        } else {
          yield put(ClientConfiguration.setNodeId(data[0].node_id))
        }
        return
      }
    } else {
      const transitionalPortal = {
        header: 'Required Fields Missing',
        copy: 'Please Enter all Required fields in Required tab',
      }
      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    }

  } catch (err) {
    console.log('createSingleLevelHandler Error > Data: ', err)
    const transitionalPortal = {
      header: DEFAULT_ERROR_HEADER,
      copy: formatErrorMessage(err),
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* handleSaveHandler({ payload }) {
  try {
    const screen = yield call(getTabName);
    yield call(testValidationHandler, { payload: { screen } });
    const rawFileConfigration = yield select(getClientConfiguration);
    const screenValidationResults = rawFileConfigration.validationResults['requiredTab'];
    const updatePayload = cloneDeep(payload)
    updatePayload.data = flattenHierarchyPayload(payload.data)
    if (screenValidationResults.isAllValid) {
      // create group changes
      const nodeType = yield select(getNodeType)
      const groupMgt = yield select(getGroupManagement)
      // save the group details only when a group is selected
      if (nodeType === 5 || nodeType === 4) {
        const createGroupPayload = yield call(prepareCreateGroupPayload, updatePayload)
        if (createGroupPayload) {
          yield put(groupManagementActions.createGroup({ ...createGroupPayload, fromHierarchy: true, node_id: updatePayload.node_id }))
        }
      }

      const { serviceUrl } = yield select(getAppState)
      const { api_path } = yield select(getApiPath, 'hierarchy-configuration-upsert')
      const url = `${serviceUrl}${api_path}`
      const { data } = yield call(fetchPatch, url, updatePayload);
      if (!data.length) {
        const transitionalPortal = {
          header: 'Data Not Found',
          copy: 'No Data Found',
        }
        yield put(AppActions.displayTransitionalPortal(transitionalPortal))
        return
      } else {
        const transitionalPortal = {
          header: 'Data Updated',
          copy: 'Data Updated Successfully',
        }
        yield put(AppActions.displayTransitionalPortal(transitionalPortal))
        return
      }
    }
  } catch (err) {
    console.log('handleSaveHandler Error > Data: ', err)
    const transitionalPortal = {
      header: 'handle Save Handler Failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

/* UTILS */
function* handleGlobalEdits() {
  yield put(GlobalEditsActions.saveGlobalEdit())
  const successActionType = GlobalEditsTypes.SAVE_GLOBAL_EDIT_SUCCESS;
  const failureActionType = GlobalEditsTypes.SAVE_GLOBAL_EDIT_FAILURE;
  const response = yield take([successActionType, failureActionType]);
  if (response.type === successActionType) {
    return response.payload.configuration_id;
  }
  if (response.type === failureActionType) {
    return null;
  }
}
