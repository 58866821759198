import React from 'react'

import FliptInput from '../../../../../components/form/fliptInput'

export default ({ data }) => {
  const {
    claims_processor,
    erx_status,
    transfer_bin_number,
    transfer_pcn,
  } = data
  return (
    <div className="section">
      <h2>Transfer</h2>
      <div className="fields-container">
        <div className="fields">
          <FliptInput value={transfer_bin_number || ''} label="Bin" stylized />
          <FliptInput value={claims_processor || ''} label="Payer Name" stylized />
        </div>
        <div className="fields">
          <FliptInput value={transfer_pcn || ''} label="PCN" stylized />
          <FliptInput label="Payer Help Desk Number" stylized />
        </div>
        <div className="fields">
          <FliptInput value={erx_status || ''} label="Transfer Status" stylized />
          <FliptInput label="Payer Help Desk Email" stylized />
        </div>
      </div>
    </div>
  )
}
