import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import './styles.scss'
import FliptButton from '../../../../components/form/fliptButton'
import FliptGrid from '../../../../components/fliptGrid'
import FliptPaInput from '../../../../components/form/fliptPaInput'
import FliptPaDropdown from '../../../../components/form/fliptPaDropdown'
import { Creators as PriorAuthorizationCreationActions } from '../../../../redux/reducers/api/priorAuthorizationManagement'
import { Creators as AppActions } from '../../../../redux/reducers/app'
import * as Constants from '../../intakeQueue/constants'

import { LinkRenderer } from '../../../../components/fliptGrid/cellRenderers'

class FindCase extends Component {
    constructor(props) {
        super(props)

        this.state = {
            searchForm: {
                pa_case_id: '',
                member_id: '',
                first_name: '',
                last_name: '',
                status: '',
                npi: '',
                priority_indicator: ''
            }
        }
    }

    _updateSearchForm = (el, dropdown) => {
        const { searchForm } = this.state
        const { name, value } = dropdown || el.currentTarget
        searchForm[name] = value
        this.setState({ searchForm })
    }


    handleIdClicked = (data) => {
        const { actions, history } = this.props
        actions.clearCaseSearchDetails()
        actions.toggleModal()
        history.push('/pa-case-dashboard', { pa_case_id: data.value })
    }


    _searchData = () => {
        const { searchForm } = this.state
        const { actions: { getSearchCaseDetails } } = this.props
        const params = Object.fromEntries(Object.entries(searchForm).filter(([key]) =>
            searchForm[key] !== ''));
        getSearchCaseDetails(params)
    }

    render() {
        const priorityData = [{ key: '1', text: 'Standard', value: 'STANDARD' },
        { key: '2', text: 'Expedited', value: 'EXPEDITED' }]

        const requestStatusData = [
            Constants.RFI_OPTION,
            Constants.DENIED_OPTION,
            Constants.APPROVED_OPTION,
            Constants.VOID_CLOSE_OPTION,
            Constants.HOLD_PENDING_OPTION,
            Constants.IN_PROCESS_OPTION,
            Constants.REOPEN_APPEAL_1_OPTION,
            Constants.NEW_OPTION,
        ]
        const { searchForm } = this.state
        const { state } = this.props
        const {
            searchCaseDetails,
        } = state
        const headers = [
            'pa_case_id',
            'drug',
            'status',
            'date_time_received',
            'patient',
            'prescriber',
        ]
        const cellRendererParams = {
            pa_case_id: {
                cellRenderer: LinkRenderer,
                onCellClicked: this.handleIdClicked,
                searchArgs: {},
                overrideHeader: 'Case ID'
            },
            intake_type: {
                overrideHeader: 'Source'
            },
            date_time_recieved: {
                overrideHeader: 'Receive Date'
            },
            prescriber: {
                overrideHeader: 'Provider'
            },
        }
        const filteredSearchCaseDetails = searchCaseDetails.length && searchCaseDetails.map((data) => {
            const obj = { ...data }
            obj.drug = data?.medication ? data.medication.drug_name : ''
            return obj
        })
        return (
            <div className="find-case-section">
                <div className="find-case-header">
                    Search Criteria
                </div>
                <div className="inner-container">
                    <div className="fields-container">
                        <FliptPaInput className="margin-right" value={searchForm.pa_case_id} stylized name="pa_case_id" onChange={this._updateSearchForm} placeholder="Enter" label="Case ID" />
                        <FliptPaInput className="margin-right" value={searchForm.member_id} stylized name="member_id" onChange={this._updateSearchForm} placeholder="Enter" label="Member ID" />
                        <FliptPaInput className="margin-right" value={searchForm.first_name} stylized name="first_name" onChange={this._updateSearchForm} placeholder="Enter" label="Patient First Name" />
                        <FliptPaInput value={searchForm.last_name} stylized name="last_name" onChange={this._updateSearchForm} placeholder="Enter" label="Patient Last Name" />
                    </div>
                    <div className="fields-container margin-top">
                        <FliptPaDropdown options={requestStatusData} value={searchForm.status} stylized name="status" onChange={this._updateSearchForm} label="Status" />
                        <FliptPaInput value={searchForm.npi} stylized name="npi" onChange={this._updateSearchForm} placeholder="Enter" label="NPI" />
                        <FliptPaDropdown value={searchForm.priority_indicator} stylized name="priority_indicator" onChange={this._updateSearchForm} options={priorityData} label="Priority" />
                    </div>
                    <div className="prescriber-buttons-container">
                        <FliptButton className='primary' name='SEARCH' onClick={() => this._searchData()} />
                    </div>
                </div>
                {filteredSearchCaseDetails?.length ?
                    <>
                        <div className="prescriber-header">
                            Prior Authorization History
                        </div>
                        <div className="grid-container">
                            <FliptGrid
                                headers={headers}
                                data={filteredSearchCaseDetails || []}
                                cellRendererParams={cellRendererParams}
                            />
                        </div></> : <></>
                }

            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    state: {
        app: state.app,
        user: state.user,
        searchCaseDetails: state.priorAuthorizationCreation.searchCaseDetails,
    },
})


const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...PriorAuthorizationCreationActions,
        ...AppActions
    }

    return {
        actions: bindActionCreators(allActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FindCase)
