import React, { useState, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import './styles.scss'
import FliptButton from '../../v2/fliptButton'
import statesJSON from '../../../config/constants/states'
import FliptGrid from '../../fliptGrid'
import FliptInput from '../../v2/fliptInput'
import FliptDropdown from '../../v2/fliptDropdown'
import { Creators as PharmacyLookupActions } from '../../../redux/reducers/api/pharmacyLookup'
import { Creators as AppActions } from '../../../redux/reducers/app'

function FliptPharmacySearch(props) {
    const { actions, updatePharmacyData, state: { pharmacyLookupData } } = props

    const [searchForm, setSearchForm] = useState({
        pharmacyState: '',
        pharmacyCity: '',
        pharmacyName: '',
        pharmacyzip1: '',
        pharmacyNpi: '',
    })
    const [pharmacyList, setPharmacyList] = useState([])
    const [pharmacyData, setPharmacyData] = useState(null)


    useEffect(() => {
        setPharmacyList(pharmacyLookupData)
    }, [pharmacyLookupData])

    const _clearPharmacyFields = () => {
        setSearchForm({
            pharmacyNpi: '',
            pharmacyState: '',
            pharmacyCity: '',
            pharmacyName: '',
            pharmacyzip1: ''

        })
        setPharmacyData(null)
        updatePharmacyData(null)
        setPharmacyList([])
        actions.clearPharmacyLookupReducer()
    }

    const onSelectionChanged = (data) => {
        const selectedRows = data.api.getSelectedRows();
        if (selectedRows.length) {
            updatePharmacyData(selectedRows[0])
            setPharmacyData(selectedRows[0])
        }
        else {
            updatePharmacyData(null)
            setPharmacyData(null)
        }
    }

    useEffect(() => {
        actions.clearPharmacyLookupReducer()
    }, []);

    const _updateSearchForm = (el, dropdown) => {
        const { name, value } = dropdown || el.currentTarget
        const tempSearchForm = { ...searchForm }
        tempSearchForm[name] = value
        setSearchForm(tempSearchForm)
    }

    const _searchData = () => {
        if (!searchForm.pharmacyNpi && !searchForm.pharmacyName && !searchForm.pharmacyCity
            && !searchForm.pharmacyState && !searchForm.pharmacyzip1) {
            const transitionalPortal = {
                header: 'Fields are missing',
                copy: 'Please add NPI, Name, City, State or Zipcode to search Pharmacy detail',
            }
            actions.displayTransitionalPortal(transitionalPortal)
            return;
        }
        setPharmacyList([])
        let form = {
            pharmacytype: 'RETAIL',
            networkStatus: 'ACTIVE'
        }
        if (searchForm.pharmacyNpi) form.pharmacynpi = searchForm.pharmacyNpi;
        if (searchForm.pharmacyState) form.pharmacystate = searchForm.pharmacyState;
        if (searchForm.pharmacyName) form.pharmacyname = searchForm.pharmacyName.toUpperCase();
        if (searchForm.pharmacyCity) form.pharmacycity = searchForm.pharmacyCity.toUpperCase();
        if (searchForm.pharmacyzip1) form.pharmacyzip1 = searchForm.pharmacyzip1;
        actions.getPharmacyLookupData({ form, isTestClaim: true })
    }

    const _submitResult = () => {
        actions.toggleModal()
    }

    const statesData = Object.entries(statesJSON).map(([k, v]) => ({
        key: k,
        text: `${k} - ${v}`,
        value: k,
    }))
    const headers = [
        'Select', 'pharmacynpi', 'pharmacyname', 'pharmacytype', 'network_status', 'pharmacyphone', 'pharmacyaddress1', 'pharmacyaddress2',
        'pharmacycity', 'pharmacystate', 'pharmacyzip1', 'chaincode'
    ]
    const cellRendererParams = {
        Select: {
            checkboxSelection: true,
            width: '120px'
        },
        pharmacyname: {
            overrideHeader: 'Name',
        },
        pharmacytype: {
            overrideHeader: 'Type',
        },
        pharmacynpi: {
            overrideHeader: 'NPI',
        },
        pharmacyaddress1: {
            overrideHeader: 'Address 1',
        },
        pharmacyaddress2: {
            overrideHeader: 'Address 2',
        },
        pharmacycity: {
            overrideHeader: 'City',
        },
        pharmacystate: {
            overrideHeader: 'State',
        },
        pharmacyzip1: {
            overrideHeader: 'Zip',
        },
        pharmacyphone: {
            overrideHeader: 'Phone',
        },
    }
    return (
        <div className="pharmacy-section-v2">
            <div className="pharmacy-header">
                Find Pharmacy
            </div>
            <div className="fields-container">
                <FliptInput value={searchForm.pharmacyName} name="pharmacyName"
                    onChange={_updateSearchForm} placeholder="Enter" label="Pharmacy Name" />
                <FliptInput value={searchForm.pharmacyNpi} name="pharmacyNpi"
                    onChange={_updateSearchForm} placeholder="Enter" label="NPI" description="NPI" />
                <FliptInput value={searchForm.pharmacyCity} name="pharmacyCity"
                    onChange={_updateSearchForm} placeholder="Enter" label="Pharmacy City" />
            </div>
            <div className='fields-container'>
                <FliptDropdown search label="State" className='member-dropdown' name="pharmacyState" value={searchForm.pharmacyState}
                    options={statesData} stylized onChange={_updateSearchForm} />
                <FliptInput value={searchForm.pharmacyzip1} name="pharmacyzip1" onChange={_updateSearchForm}
                    placeholder="Enter" label="Pharmacy Zip Code" />
            </div>
            <div className="pharmacy-buttons-container">
                <FliptButton className='primary' name='SEARCH PHARMACY' onClick={() => _searchData()} />
                <FliptButton name="Clear" className="secondary" onClick={_clearPharmacyFields} />
            </div>
            {pharmacyList?.length ?
                <>
                    <div className="pharmacy-header">
                        Search Results
                    </div>
                    <div className="grid-container">
                        <FliptGrid
                            headers={headers}
                            cellRendererParams={cellRendererParams}
                            data={pharmacyList}
                            rowSelection='single'
                            onSelectionChanged={(data) => { onSelectionChanged(data) }}
                        />
                    </div></> : <></>}
            {pharmacyData ? <div className="pharmacy-buttons-container">
                <FliptButton className='primary' name='SUBMIT' onClick={() => _submitResult()} />
            </div> : null}
        </div>
    )
}

const mapStateToProps = (state) => ({
    state: {
        pharmacyLookupData: state.pharmacyLookup.pharmacyLookupData,
    },
})

const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...PharmacyLookupActions,
        ...AppActions
    }
    return {
        actions: bindActionCreators(allActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FliptPharmacySearch)
