import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import './styles.scss'
import FliptButton from '../../../../components/form/fliptButton'
import FliptPaInput from '../../../../components/form/fliptPaInput'
import FliptPaDropdown from '../../../../components/form/fliptPaDropdown'
import FliptPaDatepicker from '../../../../components/form/fliptPaDatepicker'
import { Creators as QueueConfigActions } from '../../../../redux/reducers/api/queueConfig'
import FliptGrid from '../../../../components/fliptGrid'
import { Creators as AppActions } from '../../../../redux/reducers/app'
import { statusType, timerType } from '../data/dataTypes'
import { convertStrToDateObj } from '../../../../utils/utilities'
import { DropdownEditor } from '../../../../components/fliptGrid/cellEditors'
import { DeleteRowRenderer } from '../../../../components/fliptGrid/cellRenderers'
import rowCellInfo from '../data/rowCellInfo'


class AddQueueConfig extends Component {
    constructor(props) {
        super(props)

        this.state = {
            queueInfo: {
                queue_name: '',
                status: '',
                effective_start_date: '',
                effective_end_date: '',
                timer_apply: '',
                next_workflow_status: [],
                applicable_workflow_status: [],
            },
            gridData: [{ next_workflow_status: '', applicable_workflow_status: '' }]

        }
    }

    componentDidMount() {
        const { buttonName, selectedData } = this.props
        if (buttonName === 'Edit') {
            const queueInfo = { ...selectedData }
            queueInfo.next_workflow_status = []
            queueInfo.applicable_workflow_status = []
            const gridData = []
            if (selectedData?.next_workflow_status?.length) {
                for (const status in selectedData.next_workflow_status) {
                    gridData.push({
                        next_workflow_status: selectedData.next_workflow_status[status].status,
                        applicable_workflow_status: selectedData.applicable_workflow_status[status].status,
                    })
                }
            }
            this.setState({ queueInfo, gridData })
        }
    }

    _updateSearchForm = (el, dropdown) => {
        const { queueInfo } = this.state
        const { name, value } = dropdown || el.currentTarget
        queueInfo[name] = value
        this.setState({ queueInfo })
    }

    componentDidUpdate(prevProps) {
        const { state: { saveQueueConfigData, updateQueueConfigData }, actions } = this.props
        if (saveQueueConfigData !== prevProps.state.saveQueueConfigData ||
            updateQueueConfigData !== prevProps.state.updateQueueConfigData) {
            actions.getQueueConfigData()
        }
    }

    _addUpdateQueueConfig = () => {
        const { buttonName, actions } = this.props
        const { queueInfo, gridData } = this.state
        let header = 'Fields Missing'
        let message = 'Please add Queue information to Proceed'
        const validateFields = Object.values(queueInfo).every((v) => v !== '')
        if (!validateFields || !gridData.length) {
            this._showTransitionalPortal(header, message);
            return;
        }
        let validateGridData = true
        queueInfo.applicable_workflow_status = []
        queueInfo.next_workflow_status = []
        for (const data in gridData) {
            if (!gridData[data].applicable_workflow_status || !gridData[data].next_workflow_status) {
                this._showTransitionalPortal(header, message);
                validateGridData = false
                break;
            }
            if (gridData[data].applicable_workflow_status === gridData[data].next_workflow_status) {
                header = 'Field Error'
                message = 'Applicable workflow status and Next workflow status can not be same.'
                this._showTransitionalPortal(header, message);
                validateGridData = false
                break;
            }
            queueInfo.applicable_workflow_status.push({ status: gridData[data].applicable_workflow_status })
            queueInfo.next_workflow_status.push({ status: gridData[data].next_workflow_status })
        }

        if (!validateGridData) return

        queueInfo.effective_start_date = convertStrToDateObj(queueInfo.effective_start_date, { toISO: false })
        queueInfo.effective_end_date = convertStrToDateObj(queueInfo.effective_end_date, { toISO: false })

        if (buttonName === 'Add') {
            actions.saveQueueConfigData(queueInfo);
        } else if (buttonName === 'Edit') {
            actions.updateQueueConfigData(queueInfo)
        }
    }

    _showTransitionalPortal = (header, message) => {
        const { props } = this;
        const transitionalPortal = {
            header: header,
            copy: message,
        }
        props.actions.displayTransitionalPortal(transitionalPortal)
    }

    delGridRow = (rowIndex) => {
        const { gridData } = this.state
        const newGridData = [...gridData]
        newGridData.splice(rowIndex, 1)
        this.setState({ gridData: newGridData })
    }

    handleChange = (el, dropdown, rowIndex, gridApi) => {
        const { gridData } = this.state
        const { name, value } = dropdown || el.currentTarget
        gridData[rowIndex][name] = value
        this.setState({ gridData })
        gridApi.refreshCells()
    }
    _addRow = () => {
        const { gridData } = this.state
        const newGridData = [...gridData, { next_workflow_status: '', applicable_workflow_status: '' }]
        this.setState({ gridData: newGridData })
    }

    render() {
        const { queueInfo, gridData } = this.state;
        const { buttonName } = this.props;
        const headers = ['next_workflow_status', 'applicable_workflow_status', 'action'];
        const cellRendererParams = {
            action: {
                cellRenderer: DeleteRowRenderer,
                state: {
                    onClick: this.delGridRow,
                },
                width: 95,
            },
        };
        const cellEditorParams = {
            next_workflow_status: {
                editable: true,
                cellEditor: DropdownEditor,
                onChange: this.handleChange,
            },
            applicable_workflow_status: {
                editable: true,
                cellEditor: DropdownEditor,
                onChange: this.handleChange,
            },
        }
        return (
            <div className="add-queue-section">
                <div className='content'>
                    <div className="add-queue-header">
                        {buttonName} Queue Information
                    </div>
                    <div className="inner-container">
                        <div className="fields-container">
                            <FliptPaInput value={queueInfo.queue_name} stylized name="queue_name" onChange={this._updateSearchForm} placeholder="Enter" label="Queue name" />
                            <FliptPaDatepicker className='margin-left' value={convertStrToDateObj(queueInfo.effective_start_date)} stylized name="effective_start_date" onChange={this._updateSearchForm} placeholder="Enter" labelText="Effective Start Date" />
                        </div>
                        <div className="fields-container margin-top">
                            <FliptPaDropdown options={statusType} value={queueInfo.status} stylized name="status" onChange={this._updateSearchForm} label="Status" />
                            <FliptPaDatepicker className='margin-left' value={convertStrToDateObj(queueInfo.effective_end_date)} stylized name="effective_end_date" onChange={this._updateSearchForm} placeholder="Enter" labelText="Effective End Date" />
                        </div>
                        <div className="fields-container margin-top">
                            <FliptPaDropdown options={timerType} value={queueInfo.timer_apply} stylized name="timer_apply" onChange={this._updateSearchForm} label="Timer Apply" />
                        </div>
                        <div className="grid-content">
                            <FliptGrid
                                data={gridData || []}
                                headers={headers}
                                cellRendererParams={cellRendererParams}
                                cellEditorParams={cellEditorParams}
                                rowCellInfo={rowCellInfo} />
                        </div>
                        <div className="buttons-container">
                            <FliptButton className='primary' name='Add Row' onClick={() => this._addRow()} />
                        </div>
                        <div className="buttons-container">
                            <FliptButton className='primary' name={buttonName === 'Edit' ? 'SAVE' : buttonName.toUpperCase()} onClick={() => this._addUpdateQueueConfig()} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    state: {
        app: state.app,
        user: state.user,
        saveQueueConfigData: state.queueConfig.saveQueueConfigData,
        updateQueueConfigData: state.queueConfig.updateQueueConfigData,
    },
})


const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...QueueConfigActions,
        ...AppActions
    }

    return {
        actions: bindActionCreators(allActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddQueueConfig)
