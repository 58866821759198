import Immutable from 'seamless-immutable'
import { createActions, createReducer } from 'reduxsauce'

export const INITIAL_STATE = Immutable({
  data: [],
})

export const clearUserManagementDataReducer = () => INITIAL_STATE

export const setUserManagementReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  data: payload.data,
})

export const { Types, Creators } = createActions({
  changeUserManagementPassword: ['payload'],
  clearUserManagementData: ['payload'],
  createUserManagementUser: ['payload'],
  editUserManagementUser: ['payload'],
  getUserManagementUsers: null,
  setUserManagementUsersList: ['payload'],
  getUserManagementSingleUser: ['payload'],
  updateUserManagementUsers: ['payload'],
})

const HANDLERS = {
  [Types.CLEAR_USER_MANAGEMENT_DATA]: clearUserManagementDataReducer,
  [Types.SET_USER_MANAGEMENT_USERS_LIST]: setUserManagementReducer,
}

export default createReducer(INITIAL_STATE, HANDLERS)
