export default {
  drug_list: {
    type: "dropdown",
    options: [],
    multiple: false
  },
  rebate_type: {
    type: "dropdown",
    options: [],
    multiple: false
  },
  rebate_factor: {
    type: "input"
  },
  pos_max_rebate_amt: {
    type: "input"
  }
};