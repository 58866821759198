import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Icon } from 'semantic-ui-react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import './styles.scss'
import BreadCrumb from '../../../../components/breadCrumb'
import FliptButton from '../../../../components/form/fliptButton'
import FliptGrid from '../../../../components/fliptGrid'
import FliptInput from '../../../../components/form/fliptInput'
import { Creators as AppActions } from '../../../../redux/reducers/app'
import { Creators as MedicareSetupActions } from '../../../../redux/reducers/api/medicareSetup'
import { convertStrToDateObj, filterRowData } from '../../../../utils/utilities'
import FliptDatePicker from '../../../../components/form/fliptDatePicker'
import config from '../../../../config'
import { ColorCodedStatusRenderer, LinkRenderer } from '../../../../components/fliptGrid/cellRenderers'
import PopupCellRenderer from './popup/popupCellRenderer'

class MedicareSetup extends Component {
  constructor(props) {
    super(props)
    this.state = {
      form: {
        doc_name: '',
        plan_year: '',
      },
    }
  }

  componentDidMount() {
    setTimeout(() => this._fetchMedicareSetup(), config.COMPONENT_DATA_LOAD_TIME)
  }

  checkActionType = (action, rowdata) => {
    /*const doc_id = rowdata['doc_id']
    let data = gridData.filter(obj => { return obj.plan_id === plan_id })
    if (action === 'copy_object')
      setShowPopupFlag(true);
    if (action === 'send_for_review')
      sendForReview(rowdata)
    if (action === 'create_new_version')
      createNewVersion(rowdata)*/
  }

  _updateFields = (el, dropdown) => {
    const { form } = this.state
    const { name, value } = dropdown || el.currentTarget
    const pharmacy_name_err = false

    form[name] = typeof value === 'string' ? value.toUpperCase() : value

    this.setState({ form, pharmacy_name_err })
  }

  _fetchMedicareSetup = (sendPayload) => {
    const { props, state } = this
    const { form } = state

    if (sendPayload) props.actions.getMedicareSetupData(form)
    props.actions.getMedicareSetupData()
  }

  render() {
    const { props } = this
    const {
      form, pharmacy_name_err, noPrescriber,
    } = this.state
    const { state: { medicareSetupData } } = props
    const headers = [
      'action', 'plan_name', 'version', 'plan_year', 'status', 'create_date', 'author', 'last_updated_date', 'doc_id',
    ]
    const headerMapping = {
      npi: 'npi',
      doc_name: 'plan_name',
      doc_version: 'version',
      update_date: 'last_updated_date',
      updated_by: 'author'
    }
    const cellRendererParams = {
      action: {
        cellRenderer: PopupCellRenderer,
        searchArgs: {
          onClick: (action, rowdata) => this.checkActionType(action, rowdata),
        },
      },
      doc_id: {
        hide: true,
      },
      plan_name: {
        cellRenderer: LinkRenderer,
        path: '/medicare-lics-setup-details',
        searchArgs: { doc_id: 'doc_id', plan_name: 'plan_name' },
        state: { editMode: false, name: null },
      },
      plan_year: {
        comparator: (valueA, valueB) => {
          let x = (valueA) ? parseInt(valueA) : Number.NEGATIVE_INFINITY
          let y = (valueB) ? parseInt(valueB) : Number.NEGATIVE_INFINITY
          if (x === y) return 0;
          return (x > y) ? 1 : -1;
        },
        sort: 'desc',
      },
      status: {
        cellRenderer: ColorCodedStatusRenderer,
      },
    }
    const gridData = medicareSetupData && medicareSetupData.length ? medicareSetupData?.map((d) => ({
      ...filterRowData(d, headers, headerMapping),
    })) : []

    return (
      <div id="medicareSetup" className="medicare-setup">
        <BreadCrumb {...this.props} />
        <div className="header">
          <h1> Medicare D LICS Setup </h1>
          <Link to={'/create-lics-setup'} className="link-router">
            <Icon name='plus circle' size='tiny' />
            <span className='link-text'>Create new medicare d lics setup</span>
          </Link>
        </div>
        <div className="subhead">
          <section className="prescriber-section">
            <div className="prescriber-input">
              <span>Name</span>
              <FliptInput placeholder="Name" defaultValue={form.name} name="doc_name" onChange={this._updateFields} required />
            </div>
            <div className="prescriber-input">
              <span>Plan Year</span>
              <FliptDatePicker className="create-tier-start-date" name="plan_year" value={convertStrToDateObj(form.plan_year)} onChange={this._updateFields} />

            </div>
            <div className="prescriber-input prescriber-btn">
              <FliptButton name="Search" className="primary searchButton" onClick={this._fetchMedicareSetup} />
            </div>
          </section>
          <section className="prescriber-section">
            <div className="prescriber-input">
              {pharmacy_name_err && <small className="errMessage">Invalid Pharmacy name Value.</small>}
            </div>
          </section>
        </div>
        <div className="content">
          {
            !noPrescriber && (
              <FliptGrid
                headers={headers}
                data={gridData}
                cellRendererParams={cellRendererParams}
              />
            )
          }
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  state: {
    medicareSetupData: state.medicareSetup.medicareSetupData,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
    ...MedicareSetupActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MedicareSetup)
