import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import './styles.scss'
import FliptButton from '../../components/form/fliptButton'
import { filterRowData } from '../../utils/utilities'
import FliptGrid from '../fliptGrid'
import FliptPaInput from '../form/fliptPaInput'
import { Creators as PrescriberManagementActions } from '../../redux/reducers/api/prescriberManagement'
import { Creators as PriorAuthorizationCreationActions } from '../../redux/reducers/api/priorAuthorizationManagement'
import statesJSON from '../../config/constants/states'
import { Creators as AppActions } from '../../redux/reducers/app'
import { InputTextEditor } from '../fliptGrid/cellEditors'
import FliptPaDropdown from '../form/fliptPaDropdown'

class FliptPrescriberSearch extends Component {
    constructor(props) {
        super(props)
        this.state = {
            memberList: [],
            searchForm: {
                npi: '',
                first_name: '',
                last_name: '',
            },
            addform: {
                secure_fax_number: '',
                npi: '',
                first_name: '',
                last_name: '',
                address: '',
                office_phone: '',
                city: '',
                state: '',
                zipcode: '',
            },
            prescriberList: [],
            prescriberData: null,
            validationNeeded: false,
            allValues: true,
            noPrescriberFound: false,
            addPrescriber: false
        }
        this.agGridRefPres = React.createRef()
    }

    componentDidMount() {
        const { searchForm } = this.props
        if (searchForm.npi) {
            this.setState({
                searchForm: {
                    first_name: '',
                    last_name: '',
                    npi: searchForm.npi,
                }
            })
        }
    }

    componentDidUpdate(prevProps) {
        const { props, state: { addform } } = this
        const { searchForm, editMode, isPa } = props
        const { prescriberSearchData, updatePrescriberData, addPrescriberData } = props.state
        if (prevProps.state.prescriberSearchData !== prescriberSearchData) {
            if (prescriberSearchData?.length === 1) {
                const validationNeeded = isPa && prescriberSearchData[0] && (!prescriberSearchData[0].editableNpi
                    || !prescriberSearchData[0].editableFirstName
                    || !prescriberSearchData[0].editableLastName || !prescriberSearchData[0].editableState
                    || !prescriberSearchData[0].editableCity || !prescriberSearchData[0].editableAddress
                    || !prescriberSearchData[0].editableZipCode)
                const allValues = isPa && prescriberSearchData[0] && (!prescriberSearchData[0].npi
                    || !prescriberSearchData[0].first_name
                    || !prescriberSearchData[0].last_name || !prescriberSearchData[0].state
                    || !prescriberSearchData[0].city || !prescriberSearchData[0].address
                    || !prescriberSearchData[0].zip_code)
                this.setState({
                    prescriberData: prescriberSearchData[0], prescriberList: prescriberSearchData,
                    validationNeeded,
                    allValues
                })
                return
            } else if (!prescriberSearchData.length && isPa) {
                this.setState({ noPrescriberFound: true })
            }
            this.setState({ prescriberList: prescriberSearchData })
        }
        if (prevProps.state.updatePrescriberData !== updatePrescriberData && updatePrescriberData.status === '200') {
            this.setState({ validationNeeded: false })
        }

        if (prevProps.state.addPrescriberData !== addPrescriberData && addPrescriberData.status === 200) {
            this.props.updatePrescriberData({
                npi: addform.npi,
                phone_number: addform.office_phone,
                first_name: addform.first_name,
                last_name: addform.last_name,
                zip_code: addform.zipcode,
                state: addform.state,
                city: addform.city,
                secure_fax_number: addform.secure_fax_number,
                address: addform.address,
            })
            props.actions.toggleModal()
        }
        if (prevProps.searchForm !== searchForm
            && !editMode && props.isPa) {
            this.setState({
                searchForm: searchForm
            })
        }
    }

    _onPrescriberSelectionChanged = (data) => {
        const { isPa } = this.props
        const selectedRows = data.api.getSelectedRows();
        const validationNeeded = isPa && selectedRows[0] && (!selectedRows[0].editableNpi || !selectedRows[0].editableFirstName
            || !selectedRows[0].editableLastName || !selectedRows[0].editableState
            || !selectedRows[0].editableCity || !selectedRows[0].editableAddress
            || !selectedRows[0].editableZipCode)
        this.setState({ prescriberData: selectedRows[0], validationNeeded })
    }

    _clearPrescriberFields = () => {
        this.setState({
            searchForm: {
                npi: '',
                first_name: '',
                last_name: '',
            },
            prescriberList: []
        })
    }

    _submitResult = () => {
        const { prescriberData } = this.state
        const { actions, updatePrescriberData, submitPrescriberData } = this.props
        const rows = this.agGridRefPres.current.api.getSelectedRows()
        submitPrescriberData && submitPrescriberData(rows)
        if (!prescriberData) {
            return;
        }
        updatePrescriberData && updatePrescriberData(prescriberData)
        actions.toggleModal()
    }

    _validate = () => {
        const { actions } = this.props
        const { prescriberData } = this.state
        if (prescriberData.secure_fax_number && prescriberData.secure_fax_number.length !== 10) {
            const transitionalPortal = {
                header: 'Field Error',
                copy: 'Secure Fax Number should be of 10 Digits',
            }
            actions.displayTransitionalPortal(transitionalPortal)
            return;
        }
        const params = {}
        if (prescriberData.editableNpi) {
            params.npi = prescriberData.npi
        } else {
            params.update_npi = prescriberData.npi
        }
        if (prescriberData.editableFirstName) {
            params.first_name = prescriberData.first_name
        } else {
            params.update_first_name = prescriberData.first_name
        }
        if (prescriberData.editableLastName) {
            params.last_name = prescriberData.last_name
        } else {
            params.update_last_name = prescriberData.last_name
        }
        if (prescriberData.editableAddress) {
            params.address = prescriberData.address
        } else {
            params.update_address = prescriberData.address
        }
        if (prescriberData.editableSecureFaxNo) {
            params.secure_fax_number = prescriberData.secure_fax_number
        } else {
            params.update_secure_fax_number = prescriberData.secure_fax_number
        }
        if (prescriberData.editablePhnNo) {
            params.office_phone = prescriberData.phone_number
        } else {
            params.update_office_phone = prescriberData.phone_number
        }
        if (prescriberData.editableCity) {
            params.city = prescriberData.city
        } else {
            params.update_city = prescriberData.city
        }
        if (prescriberData.editableState) {
            params.state = prescriberData.state
        } else {
            params.update_state = prescriberData.state
        }
        if (prescriberData.editableZipCode) {
            params.zipcode = prescriberData.zip_code
        } else {
            params.update_zipcode = prescriberData.zip_code
        }
        actions.updatePrescriber(params)
    }

    _searchData = () => {
        const { actions } = this.props
        const { state: { searchForm } } = this
        if ((!searchForm.npi && !searchForm.first_name
            && !searchForm.last_name)) {
            const transitionalPortal = {
                header: 'Fields are missing',
                copy: 'Please Add Details to search Prescriber detail',
            }
            actions.displayTransitionalPortal(transitionalPortal)
            return;
        }
        if (searchForm.npi && searchForm.npi.length !== 10) {
            const transitionalPortal = {
                header: 'Field Error',
                copy: 'Prescriber NPI should be of 10 Digits',
            }
            actions.displayTransitionalPortal(transitionalPortal)
            return;
        }
        this.setState({
            prescriberList: [], prescriberData: null,
            prescriberSearched: true, prescriberModalOpen: false
        })
        let params = {}
        if (searchForm.npi) {
            params.npi = searchForm.npi
            params.search_by = 'npi'
        }
        if (searchForm.first_name && searchForm.last_name) {
            params.first_name = searchForm.first_name
            params.last_name = searchForm.last_name
            params.search_by = 'name'
        }
        actions.setPrescriberSearchFailed(false)
        actions.searchPrescriber(params)
    }

    _updateSearchForm = (el, dropdown) => {
        const { searchForm } = this.state
        const { name, value } = dropdown || el.currentTarget
        searchForm[name] = value
        this.setState({
            searchForm, prescriberList: [], prescriberData: null,
            addPrescriber: false, noPrescriberFound: false
        })
    }

    _updateAddForm = (el, dropdown) => {
        const { addform } = this.state
        const { name, value } = dropdown || el.currentTarget
        addform[name] = value
        this.setState({
            addform
        })
    }

    _addPrescriber = () => {
        const { addform } = this.state
        const { actions, state: { user } } = this.props
        const test = Object.values(addform).every((value) => value !== '')
        if (!test) {
            const header = 'Fields Missing'
            const message = 'Please Fill All Fields to Add Prescriber'
            this._showTransitionalPortal(header, message);
            return;
        }
        if (addform.secure_fax_number && addform.secure_fax_number.length !== 10) {
            const transitionalPortal = {
                header: 'Field Error',
                copy: 'Secure Fax Number should be of 10 Digits',
            }
            actions.displayTransitionalPortal(transitionalPortal)
            return;
        }
        if (addform.npi && addform.npi.length !== 10) {
            const transitionalPortal = {
                header: 'Field Error',
                copy: 'NPI should be of 10 Digits',
            }
            actions.displayTransitionalPortal(transitionalPortal)
            return;
        }
        if (addform.office_phone && addform.office_phone.length !== 10) {
            const transitionalPortal = {
                header: 'Field Error',
                copy: 'Phone Number should be of 10 Digits',
            }
            actions.displayTransitionalPortal(transitionalPortal)
            return;
        }
        actions.addPrescriber({ ...addform, user: user.uuid })
    }

    _showTransitionalPortal = (header, message) => {
        const { props } = this;
        const transitionalPortal = {
            header: header,
            copy: message,
        }
        props.actions.displayTransitionalPortal(transitionalPortal)
    }

    handleChange = (el, dropdown) => {
        const { prescriberData } = this.state
        const { actions, updatePrescriberData, isPa } = this.props
        if (prescriberData) {
            const { name, value } = dropdown || el.currentTarget
            prescriberData[name] = value
            const allValues = isPa && (!prescriberData.npi
                || !prescriberData.first_name || !prescriberData.last_name || !prescriberData.state
                || !prescriberData.city || !prescriberData.address
                || !prescriberData.zip_code)
            this.setState({ prescriberData, allValues })
            updatePrescriberData && updatePrescriberData(prescriberData)
        } else {
            const transitionalPortal = {
                header: 'Fields are missing',
                copy: 'Please Select a Prescriber First',
            }
            actions.displayTransitionalPortal(transitionalPortal)
        }
    }

    render() {
        const { isPa, updatePrescriberData, editMode } = this.props
        const { searchForm, prescriberList, prescriberData, validationNeeded,
            allValues, noPrescriberFound, addPrescriber, addform } = this.state
        const headers = [
            'Select', 'npi', 'first_name', 'last_name', 'secure_fax_number', 'phone_number', 'address', 'city',
            'state', 'zip_code',
        ]
        const gridData = prescriberList && prescriberList.length ? prescriberList?.map((d) => {
            const obj = {
                ...filterRowData(d, headers),
                editableNpi: d.npi,
                editableFirstName: d.provider_first_name,
                editableLastName: d.provider_last_name_legal_name,
                editableState: d.provider_business_practice_location_address_state_name,
                editableCity: d.provider_business_practice_location_address_city_name,
                editableAddress: d.provider_first_line_business_practice_location_address,
                editableSecureFaxNo: d.secure_fax_number,
                editablePhnNo: d.provider_business_practice_location_address_telephone_number,
                editableZipCode: d.provider_business_practice_location_address_postal_code,
                address: d.address.address1,
                state: d.address.state,
                city: d.address.city,
                zip_code: d.address.zip,
                fax_number: d.address.secure_fax_number,
                phone_number: d.address.phone_number
            }
            return obj;
        }) : []
        const cellRendererParams = {
            Select: {
                checkboxSelection: true,
                width: '100px'
            },
            npi: {
                width: '120px'
            },
            first_name: {
                width: '120px'
            },
            last_name: {
                width: '120px'
            },
            secure_fax_number: {
                width: '120px'
            },
            address: {
                width: '120px'
            },
            city: {
                width: '120px'
            },
            state: {
                width: '100px'
            },
            zip_code: {
                width: '120px'
            },
            phone_number: {
                width: '120px'
            }
        }
        const cellEditorParams = {
            secure_fax_number: {
                cellEditor: InputTextEditor,
                onChange: this.handleChange,
                validation: 'numeric',
                disabled: true,
                isDisabled: function (data) {
                    const { editableSecureFaxNo = '' } = data
                    return editableSecureFaxNo !== ''
                },
            },
            zip_code: {
                cellEditor: InputTextEditor,
                onChange: this.handleChange,
                disabled: true,
                isDisabled: function (data) {
                    const { editableZipCode } = data
                    return editableZipCode !== ''
                },
            },
            address: {
                cellEditor: InputTextEditor,
                onChange: this.handleChange,
                disabled: true,
                isDisabled: function (data) {
                    const { editableAddress } = data
                    return editableAddress !== ''
                },
            },
            phone_number: {
                cellEditor: InputTextEditor,
                onChange: this.handleChange,
                disabled: true,
                validation: 'numeric',
                isDisabled: function (data) {
                    const { editablePhnNo } = data
                    return editablePhnNo !== ''
                },
            },
            state: {
                cellEditor: InputTextEditor,
                onChange: this.handleChange,
                disabled: true,
                isDisabled: function (data) {
                    const { editableState } = data
                    return editableState !== ''
                },
            },
            city: {
                cellEditor: InputTextEditor,
                onChange: this.handleChange,
                disabled: true,
                isDisabled: function (data) {
                    const { editableCity } = data
                    return editableCity !== ''
                },
            },
            npi: {
                cellEditor: InputTextEditor,
                onChange: this.handleChange,
                disabled: true,
                isDisabled: function (data) {
                    const { editableNpi } = data
                    return editableNpi !== ''
                },
            },
            first_name: {
                cellEditor: InputTextEditor,
                onChange: this.handleChange,
                disabled: true,
                isDisabled: function (data) {
                    const { editableFirstName } = data
                    return editableFirstName !== ''
                },
            },
            last_name: {
                cellEditor: InputTextEditor,
                onChange: this.handleChange,
                disabled: true,
                isDisabled: function (data) {
                    const { editableLastName } = data
                    return editableLastName !== ''
                },
            },
        }
        const onGridReady = () => {
            if (gridData.length == 1) {
                if (!validationNeeded) updatePrescriberData && updatePrescriberData(gridData[0])
                this.agGridRefPres.current.api.forEachNode(function (node) {
                    node.setSelected(true);
                });
            }
        }
        const statesData = Object.entries(statesJSON).map(([k, v]) => ({
            key: k,
            text: `${k} - ${v}`,
            value: k,
        }))
        return (
            <div className="prescriber-section">
                {!addPrescriber && <div className="prescriber-header">
                    Find Prescriber
                    {isPa && editMode ? <FliptButton className='primary' name='Add Prescriber'
                        onClick={() => this.setState({ addPrescriber: true })} /> : null}
                </div>}
                {!addPrescriber && <div className="inner-container">
                    <div className="fields-container">
                        <FliptPaInput validation='numeric' value={searchForm.npi} disabled={!editMode} stylized name="npi" onChange={this._updateSearchForm} placeholder="Enter" label="NPI" description="NPI" />
                        <FliptPaInput value={searchForm.first_name} disabled={!editMode} stylized name="first_name" onChange={this._updateSearchForm} placeholder="Enter" label="Prescriber First Name" />
                        <FliptPaInput value={searchForm.last_name} disabled={!editMode} stylized name="last_name" onChange={this._updateSearchForm} placeholder="Enter" label="Prescriber Last Name" />
                    </div>
                    {isPa && !editMode ? <div className="fields-container">
                        <FliptPaInput value={searchForm.address1} disabled stylized label="Address" />
                        <FliptPaInput value={searchForm.city} stylized disabled label="City" />
                        <FliptPaInput value={searchForm.state} stylized disabled label="State" />
                    </div> : null}
                    {isPa && !editMode ? <div className="fields-container">
                        <FliptPaInput value={searchForm.phone_number} stylized disabled label="Phone Number" />
                        <FliptPaInput value={searchForm.fax_number} stylized disabled label="Fax Number" />
                        <FliptPaInput value={searchForm.zip_code} disabled stylized label="Zip Code" />
                    </div> : null}
                    {editMode ? <div className="prescriber-buttons-container">
                        <FliptButton className='primary' name='SEARCH' onClick={() => this._searchData()} />
                        <FliptButton name="Clear" className="secondary" onClick={() => this._clearPrescriberFields()} />
                    </div> : null}
                </div>}
                {
                    addPrescriber && <div className='add-prescriber'>
                        <span className="add-prescriber-header">Add Prescriber</span>
                        <div className='add-outer-container'>
                            <div className='add-inner-container'>
                                <FliptPaInput value={addform.npi} stylized name="npi" validation='numeric'
                                    onChange={this._updateAddForm} label="NPI" />
                                <FliptPaInput value={addform.first_name} stylized name="first_name"
                                    onChange={this._updateAddForm} label="Prescriber First Name" />
                                <FliptPaInput value={addform.last_name} stylized name="last_name"
                                    onChange={this._updateAddForm} label="Prescriber Last Name" />
                            </div>
                            <div className='add-inner-container'>
                                <FliptPaInput value={addform.address} name="address" onChange={this._updateAddForm} stylized label="Address" />
                                <FliptPaInput value={addform.city} stylized name="city" onChange={this._updateAddForm} label="City" />
                                <FliptPaDropdown search label="State" name="state" value={addform.state}
                                    options={statesData} stylized onChange={this._updateAddForm} />
                            </div>
                            <div className='add-inner-container'>
                                <FliptPaInput value={addform.zipcode} onChange={this._updateAddForm} name='zipcode' stylized label="Zip Code" />
                                <FliptPaInput value={addform.secure_fax_number} validation='numeric'
                                    name='secure_fax_number' onChange={this._updateAddForm} stylized label="Secure Fax Number" />
                                <FliptPaInput value={addform.office_phone} validation='numeric' stylized name='office_phone' onChange={this._updateAddForm} label="Phone Number" />
                            </div>
                        </div>
                        {
                            <div className="prescriber-buttons-container">
                                <FliptButton className='primary' name='SAVE' onClick={() => this._addPrescriber()} />
                                <FliptButton name="Cancel" className="secondary" onClick={() => {
                                    this.setState({
                                        addPrescriber: false,
                                    })
                                }} />
                            </div>
                        }
                    </div>
                }
                {prescriberList.length ?
                    <>
                        <div className="prescriber-header">
                            Search Results
                        </div>
                        <div className="grid-container">
                            <FliptGrid
                                agGridRef={this.agGridRefPres}
                                headers={headers}
                                data={gridData}
                                rowSelection='multiple'
                                onSelectionChanged={(data) => this._onPrescriberSelectionChanged(data)}
                                cellRendererParams={cellRendererParams}
                                onGridReadyCustom={onGridReady}
                                cellEditorParams={isPa ? cellEditorParams : []}
                            />
                        </div></> : <></>
                }
                {noPrescriberFound && !addPrescriber && <div className='prescriber-not-found-container'>
                    <span className='prescriber-not-found-header'>Prescriber Not Found</span>
                    <span className='prescriber-not-found-text'>There is no prescriber with the search query. Please search again or add prescriber.</span>
                </div>}
                {
                    (!noPrescriberFound || !addPrescriber) && <div className="prescriber-buttons-container">
                        {validationNeeded && <FliptButton disabled={!prescriberData || allValues} className='primary' name='VALIDATE'
                            onClick={() => this._validate()} />}
                        {!addPrescriber ? <FliptButton disabled={validationNeeded || !prescriberData} className='primary' name='SUBMIT'
                            onClick={() => this._submitResult()} /> : <></>}
                    </div>
                }
            </div>
        )
    }

}

const mapStateToProps = (state) => ({
    state: {
        updatePrescriberData: state.priorAuthorizationCreation.updatePrescriberData,
        prescriberSearchData: state.prescriberManagement.prescriberSearchData,
        user: state.user,
        addPrescriberData: state.priorAuthorizationCreation.addPrescriberData,
    },
})

const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...PrescriberManagementActions,
        ...PriorAuthorizationCreationActions,
        ...AppActions
    }
    return {
        actions: bindActionCreators(allActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FliptPrescriberSearch)