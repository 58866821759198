
import React, { PureComponent } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import './styles.scss'
import BreadCrumb from '../../../components/breadCrumb'
import Config from "../../../config";
import FliptGrid from '../../../components/fliptGrid';
import FliptHierarchyDropdown from '../../../components/form/fliptHierarchyDropdown'
import { Creators as AppActions } from '../../../redux/reducers/app'
import { Creators as cmsFormualryBuilderActions } from '../../../redux/reducers/api/cmsFormualryBuilder'
import { LinkRenderer } from '../../../components/fliptGrid/cellRenderers'


class MedDFormulary extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            form: {
                start_date: '',
                end_date: '',
                hierarchy: [],
                is_hierarchy_global: false,
                hierarchyIDs: {},
                hideHierarchy: false,
            }
        }
    }

    _updateForm = (el, dropdown) => {
        const { form } = this.state
        const { name, value } = dropdown || el.currentTarget
        form[name] = value
        this.setState({ form })
    }

    componentDidMount() {
        const { actions } = this.props
        actions.clearDashboardData()

        setTimeout(actions.getCmsFormularyDashboard(), Config.COMPONENT_DATA_LOAD_TIME)
    }


    createNewGroup = () => {
        this.props.history.push('/create-formulary-export-builder')
    }


    render() {
        const { state: { dashboardData } } = this.props
        const headers = ['formulary_name', 'doc_id', 'doc_version', 'lob', 'effective_start', 'effective_end']
        const cellRendererParams = {
            formulary_name: {
                overrideHeader: 'Formulary Name',
                cellRenderer: LinkRenderer,
                path: "/create-formulary-export-builder",
                searchArgs: { doc_name: 'formulary_name', doc_id: 'doc_id', 'editMode': true },
                state: { editMode: true },
                minWidth: 300,
            },
            doc_id: {
                hide: true
            },
            doc_version: {
                hide: true
            },
            cnt: {
                overrideHeader: 'Group Count'
            },
            update_date: {
                overrideHeader: 'Load Date'
            },
            // action: {

            //   },
        }
        return (
            <div id="medD-formulary">
                <div className='breadcrum-container'>
                    <BreadCrumb {...this.props} />
                </div>
                <div className='header'>
                    Formulary Export Configurations
                    <span className='link-text' onClick={this.createNewGroup}>
                        + Add New Formulary Export Configuration
                    </span>
                </div>
                <div className='divider' />
                <div className='grid-container'>
                    <FliptGrid
                        headers={headers}
                        cellRendererParams={cellRendererParams}
                        data={dashboardData || []}
                    />
                </div>
            </div >
        )
    }
}

const mapStateToProps = (state) => ({
    state: {
        app: state.app,
        dashboardData: state.cmsFormualry.dashboardData,
    },
})

const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...AppActions,
        ...cmsFormualryBuilderActions
    }

    return {
        actions: bindActionCreators(allActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MedDFormulary)
