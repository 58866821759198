import React, { useState, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as _ from 'lodash'
import FliptGrid from '../../../components/fliptGrid'
import BreadCrumb from '../../../components/breadCrumb'
import { LinkContainerRenderer, ColorCodedStatusRenderer } from '../../../components/fliptGrid/cellRenderers'
import FliptButton from '../../../components/form/fliptButton'
import { Link } from 'react-router-dom'
import PopupCellRenderer from './popup/popupCellRenderer'
import { Creators as contractManagementActions } from '../../../redux/reducers/api/contractManagement'
import { Creators as NetworkCreationActions } from '../../../redux/reducers/api/networkCreationManagement'
import ContractPublishAlertPopup from './popup/alertPopup'
import { Creators as AppActions } from '../../../redux/reducers/app'
import { Creators as GlobalEditsActions } from '../../../redux/reducers/api/globalEdits'

import './styles.scss'
import TabBar from '../../../components/tabBar'
import { CM_LOCAL_STORAGE_KEY, cmHeaders, defaultTabConfigs } from './data/tabData'
import ProcessorTab from './processorTab'

const contractManagement = (props) => {
    const { state, actions, location } = props
    const { contractSummaryData: dat, selectedTab } = state
    const [data, setData] = useState(dat)

    useEffect(() => {
        if (location?.state?.isProcessor) actions.setSelectedTab(defaultTabConfigs[1].id)
        else actions.setSelectedTab(defaultTabConfigs[0].id)
    }, [])

    const cellRendererParams = {
        action: {
            cellRenderer: PopupCellRenderer,
            searchArgs: {
                onClick: (action, rowdata) => checkActionType(action, rowdata),
            },
            width: 100,
        },
        doc_name: {
            cellRenderer: LinkContainerRenderer,
            searchArgs: [
                {
                    path: '/contract-management',
                    searchArgs: { doc_id: 'doc_id', doc_version: 'doc_version' },
                    state: { editMode: false },
                    key: 'doc_name',
                    staticSearchArgs: {},
                },
            ],
            width: 100,
            overrideHeader: 'Contract Name'
        },
        status: {
            cellRenderer: ColorCodedStatusRenderer,
        },
        effective_start_date: {
            overrideHeader: 'Contract Start Date'
        },
        effective_end_date: {
            overrideHeader: 'Contract End Date'
        },
        doc_version: {
            overrideHeader: 'Version'
        },
    }
    const checkActionType = (action, rowdata) => {
        if (selectedTab === defaultTabConfigs[1].id) {
            if (action === 'publish_doc')
                publishProcessor(rowdata)
            if (action === 'create_new_version')
                createNewProcessorVersion(rowdata)
            return
        }
        if (action === 'publish_doc')
            publishDocPopup(rowdata)
        if (action === 'create_new_version')
            createNewVersion(rowdata)
    }
    const publishDocPopup = (rowdata) => {
        actions.setModalComponent({
            modalProperties: { size: 'large', scrolling: true },
            contents: <ContractPublishAlertPopup rowdata={rowdata} publishDoc={publishDoc} />,
        })
        actions.toggleModal()
    }
    const publishProcessor = (rowdata) => {
        delete rowdata.bin
        delete rowdata.pcn
        actions.updateProcessor({ ...rowdata, status: 'Published' })
    }

    const publishDoc = (form, auto_publish_pharmacy, selectedNetwork, gridData) => {
        actions.toggleModal()
        const payload = {
            doc_id: form?.doc_id,
            doc_name: form?.doc_name,
            doc_version: form?.doc_version,
            status: 'Published',
            doc_type: 'global_claim_processor',
        }
        if (auto_publish_pharmacy === 'Y') {
            const tier_details = selectedNetwork.length && selectedNetwork.map((netData) => ({
                doc_id: netData.doc_id,
                doc_type: 'global_network_tier',
                doc_version: netData.version
            }))
            const parsedPricingData = []
            if (gridData?.length) {
                gridData.map((pricingData) => {
                    const parsedFeeData = []
                    if (pricingData.fee_name?.length) {
                        pricingData.fee_name.map((feeData) => {
                            parsedFeeData.push({
                                fee_name: feeData,
                                fee_type: pricingData.fee_type[feeData],
                                fee_value: isNaN(pricingData?.fee_value[feeData]) ? parseFloat(pricingData?.fee_value[feeData]?.replace('%', '')) : pricingData?.fee_value[feeData],
                            })
                        })
                    }
                    pricingData.fees = parsedFeeData
                    pricingData.min_days_supply = pricingData.min_days_supply || pricingData.min_days_supply === '0' || pricingData.min_days_supply === 0 ? parseInt(pricingData.min_days_supply) : null
                    pricingData.max_days_supply = pricingData.max_days_supply || pricingData.max_days_supply === '0' || pricingData.max_days_supply === 0 ? parseInt(pricingData.max_days_supply) : null
                    pricingData.discount = pricingData.discount || pricingData.discount === '0' || pricingData.discount === 0 ? parseFloat(pricingData.discount) : null
                    delete pricingData.fee_name
                    delete pricingData.fee_type
                    delete pricingData.fee_value
                    parsedPricingData.push(pricingData)
                })
            }
            payload.network_tier_details = {
                auto_update: auto_publish_pharmacy === 'Y',
                pricing_conditions: parsedPricingData,
                tier_details
            }
        }
        actions.publishDoc(payload, form?.plan_id)
    }

    const setActiveTab = (id) => {
        if (id === 'contract_management') actions.getContractSummaryData()
        actions.setSelectedTab(id)
    }

    useEffect(() => {
        if (dat && !_.isEqual(data, dat)) {
            setData(dat)
        }
    }, [dat])

    const createNewVersion = (form) => {
        const payload = {
            doc_id: form?.doc_id,
            doc_name: form?.doc_name,
            doc_version: form?.doc_version,
            status: 'Published',
            doc_type: 'global_claim_processor',
        }
        actions.createNewContractVersion(payload)
    }

    const createNewProcessorVersion = (form) => {
        delete form.bin
        delete form.pcn
        actions.createProcessor(form)
    }

    return (
        <div id="durSummary">
            <div className='heading'>
                <BreadCrumb {...props} />
                <div className="header">
                    <div className='tab-bar'>
                        <TabBar
                            tabs={defaultTabConfigs}
                            setActiveTab={(id) => setActiveTab(id)}
                            localStorageKey={CM_LOCAL_STORAGE_KEY}
                            draghandle />
                    </div>
                    {selectedTab === defaultTabConfigs[0].id ? <div className="closeButton">
                        <Link to={{ pathname: '/contract-management', state: { editMode: true, create: true } }} className="link-router">
                            <FliptButton className="closeButton" compact name="Add New Contract Configuration" />
                        </Link>
                    </div> : <div className="closeButton"><Link to={{ pathname: '/create-processor', state: { editMode: false } }} className="link-router">
                        <FliptButton className="closeButton" compact name="Add New Processor" />
                    </Link></div>}
                </div>
            </div>
            {selectedTab === defaultTabConfigs[0].id ? <div className="content maxHeight">
                <FliptGrid data={data} headers={cmHeaders} cellRendererParams={cellRendererParams} />
            </div> :
                <ProcessorTab checkActionType={checkActionType} />}
        </div>
    )
}


const mapStateToProps = (state) => ({
    state: {
        contractSummaryData: state.contractManagement.contractSummaryData,
        selectedTab: state.contractManagement.selectedTab,
        contractData: state.contractManagement.contractData,
        allNetworkData: state.networkTierCreation.allNetworkTiersData,
    },
})

const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...contractManagementActions,
        ...NetworkCreationActions,
        ...AppActions,
        ...GlobalEditsActions
    }

    return {
        actions: bindActionCreators(allActions, dispatch),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(contractManagement)
