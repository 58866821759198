import _ from 'lodash'
import './styles.scss'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import React, { Component } from 'react'
import { Creators as AppActions } from '../../../../../redux/reducers/app'
import { Creators as NetworkTierCreationActions } from '../../../../../redux/reducers/api/networkCreationManagement'
// import { parseQueryString } from '../../../../../utils/utilities'
import BreadCrumb from '../../../../../components/breadCrumb'
import discountType from './data/discountType'
import drugTypes from './data/drugTypes'
import feeTypes from './data/feeTypes'
import priceType from './data/priceType'
import SaveTierModal from './saveTierModal'
import TierConditions from './tierConditions/index'

class CreateNetwork extends Component {
  constructor(props) {
    super(props)
    this.state = {
      form: {
        doc_id: '',
        name: '',
        version: '1.0',
        tiers: [],
        effective_start_date: '',
        hierarchy: [],
        is_hierarchy_global: false,
        hierarchyIDs: {},
        hideHierarchy: false,
      },
      dropdownValues: [{
        claim_processor: [], price_type: priceType, drug_type: drugTypes, discount_type: discountType, fee_type: feeTypes, mac_list_id: [],
      }],
      networkTierData: [],
      networkTiersDetail: [],
      rowUpdated: null,
    }
  }

  componentDidMount() {
    const { actions, history } = this.props
    actions.clearNetworkTier()
    actions.getClaimProcessorNetworkData()
    actions.getAllNetworkTiersData({ doc_type: 'global_network_tier' })
    if (!history?.location?.search) return

    this.setState({ editMode: true })
    this._handleEditData(history?.location?.state)
    // setTimeout(() => actions.getNetworkData(parseQueryString(history.location.search)), 250)
  }

  componentDidUpdate(prevProps) {
    const { props } = this
    const prevState = prevProps.state
    const currState = props.state
    if (_.isEqual(prevState, currState)) return
    this._updateState()
  }

  // eslint-disable-next-line react/destructuring-assignment
  _constructDropdownOptions = () => this.state.dropdownValues.map((row) => this._mapValueToOption(row))

  _mapValueToOption = (row) => {
    const data = {
      ...row,
    }
    return data
  }

  _handleTierChange = (val) => {
    const { state: { form: { tiers }, networkTiersDetail }, props: { actions, state: { allNetworkTiersData } } } = this
    if (val.length > tiers.length) {
      const unMatch = tiers?.length > 0 ? val.filter(function f(it) { return !this.has(it) }, new Set(tiers)) : val
      actions.getNetworkTierData({
        doc_id: allNetworkTiersData.find((it) => it.doc_name === unMatch[0])?.doc_id,
        doc_version: allNetworkTiersData.find((it) => it.doc_name === unMatch[0])?.doc_version,
        doc_type: 'global_network_tier'
      })
    } else if (val.length < tiers.length) {
      const unMatch = tiers?.length > 0 ? tiers.filter(function f(it) { return !this.has(it) }, new Set(val)) : val
      this.setState({ networkTiersDetail: networkTiersDetail.filter((tierData) => unMatch[0] !== tierData.doc_name) })
    }
  }

  setForm = (form) => {
    this.setState({ form })
  }

  _updateFields = (el, dateData) => {
    const { name, value } = dateData || el.currentTarget
    if (name === 'tiers') {
      this._handleTierChange(value)
    }
    this.setState((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        [name]: value,
      },
    }))
  }

  _generateNetwork = () => {
    const { props, state } = this
    const {
      form: {
        name, effective_start_date, version, tiers, doc_id, preferred_tier, display_on_top,
        hierarchy, hierarchyIDs, is_hierarchy_global
      },
    } = state
    const data = {
      doc_name: name,
      hierarchy,
      hierarchyIDs,
      is_hierarchy_global: is_hierarchy_global ? is_hierarchy_global : false,
      doc_version: version,
      effective_start_date,
      preferred_tier,
      display_on_top,
      tiers: tiers.map((tier) => ({ doc_name: tier })),
    }
    if (doc_id) {
      data.doc_id = doc_id
    }
    props.actions.generateNetwork({ ...data })
  }

  _saveNetwork = () => {
    const { props } = this
    const message = 'You are about to save the network. Do you wish to save?'
    props.actions.setModalComponent({
      modalProperties: {
        size: 'tiny',
      },
      contents: SaveTierModal({
        onSaveClick: () => this._generateNetwork(), toggleModal: props.actions.toggleModal, message,
      }),
    })
    props.actions.toggleModal()
  }

  _handleEditData(data) {
    const { props: { actions } } = this
    const form = {
      doc_id: data?.doc_id,
      name: data?.doc_name,
      version: data?.doc_version,
      effective_start_date: new Date(data?.effective_start_date),
      tiers: data?.tiers?.map((tier) => tier.doc_name),
      hierarchy: data.hierarchy,
      hierarchyIDs: data.hierarchyIDs,
      is_hierarchy_global: data.is_hierarchy_global,
    }
    this.setState({
      form: {
        ...form,
      },
    })
  }

  _updateState() {
    const { props, state } = this
    const { dropdownValues, networkTiersDetail } = state
    const { claimProcessorNetworkData, networkTierData, allNetworkTiersData } = props.state
    let updatedClaimDropdownValues = []
    let updatedNetworkTierDropdownValues = []
    if (claimProcessorNetworkData?.length > 0) {
      updatedClaimDropdownValues = claimProcessorNetworkData.map((it) => (
        {
          key: it,
          value: it,
          text: it,
        }
      ))
    }

    if (allNetworkTiersData && allNetworkTiersData.length > 0) {
      updatedNetworkTierDropdownValues = allNetworkTiersData.filter((tier) => tier.status === 'Published')?.map((it) => (
        {
          key: it.doc_name,
          value: it.doc_name,
          text: it.doc_name,
        }
      ))
    }
    let updatedNetworkDetails = networkTiersDetail
    if (networkTierData && Object.keys(networkTierData)?.length > 0) {
      if (networkTiersDetail?.length > 0) {
        if (networkTiersDetail.find((it) => it.doc_name !== networkTierData?.doc_name)) {
          updatedNetworkDetails = updatedNetworkDetails.concat(networkTierData)
        }
      } else {
        updatedNetworkDetails = [networkTierData]
      }
    }
    this.setState({
      dropdownValues: [{
        ...dropdownValues[0],
        claim_processor: updatedClaimDropdownValues,
      }],
      networkTierData: updatedNetworkTierDropdownValues,
      networkTiersDetail: updatedNetworkDetails,
    })
  }

  render() {
    const { props, state } = this
    const { allNetworkTiersData } = props.state
    const {
      form, networkTiersDetail, networkTierData,
    } = state
    let editMode = true

    if ('state' in props.history.location) {
      editMode = (props.history.location.state && 'editMode' in props.history.location.state) ? props.history.location.state.editMode : true
    }

    const dropdownOptions = this._constructDropdownOptions()

    return (
      <div id="createTier">
        <BreadCrumb {...this.props} />
        <div className="header">Create Network</div>
        <div className="content">
          <TierConditions
            actions={props.actions}
            allNetworkTiersData={allNetworkTiersData}
            dropdownOptions={dropdownOptions}
            editMode={editMode}
            form={form}
            generateNetwork={this._saveNetwork}
            networkTierDetail={networkTiersDetail}
            networkTierOptions={networkTierData}
            setForm={this.setForm}
            updateFields={this._updateFields}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  state: {
    claimProcessorNetworkData: state.networkTierCreation.claimProcessorNetworkData,
    networkTierData: state.networkTierCreation.networkTierData,
    allNetworkTiersData: state.networkTierCreation.allNetworkTiersData,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
    ...NetworkTierCreationActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateNetwork)
