import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Breadcrumb } from 'semantic-ui-react'
import FliptDivider from '../../../../components/fliptDivider'
import FliptButton from '../../../../components/form/fliptButton'
import FliptDatePicker from '../../../../components/form/fliptDatePicker'
import FliptDropdown from '../../../../components/form/fliptDropdown'
import FliptInput from '../../../../components/form/fliptInput'
import { Creators as ManualClaimEntryActions } from '../../../../redux/reducers/api/manualClaimEntry'
import { Creators as DrugLookupActions } from '../../../../redux/reducers/api/drugLookup'
import { Creators as ClaimsActions } from '../../../../redux/reducers/api/claimLookupManagement'
import { Creators as AppActions } from '../../../../redux/reducers/app'
import { Creators as AutoSuggestActions } from '../../../../redux/reducers/api/autoSuggest'
import { overrideTypeValues } from '../../../../utils/dropdownValues'
import { convertStrToDateObj } from '../../../../utils/utilities'
import { Link } from 'react-router-dom';

import './styles.scss'
import { copayOverrideTypeValues } from '../../claimLookupManagement/claimOverride/overrideDetails/overrideDataValues'
import FliptDrugSearch from '../../../../components/fliptDrugSearch'

class ProactiveOverrideDetails extends Component {
  constructor(props) {
    super(props)

    this.state = {
      searchNumber: 0,
      drugNameOptions: [],
      drugFormOptions: [],
      drugStrengthOptions: [],
      searchBy: null,
      customDrugData: null,
      form: {
        drug_name: '',
        drug_strength: '',
        drug_form: '',
        gpi: '',
        drug_name_gpi: '',
        ndc: '',
      },
      overrideForm: {
        start_date: '',
        end_date: '',
        payer_routing_override_bypass: '',
        override_type: 'ALL',
        copay_amount_30ds: '',
        copay_amount_60ds: '',
        copay_amount_90ds: '',
        copay_override_type: 'NA',
        override_percentage: '',
      },
      resultValues: [],
      loading: false,
      minCharacters: 3,
      drugDataList: []
    }
  }




  _updateResultSearchValues(drugsArray) {
    const resultValues = drugsArray ? drugsArray.map((d) => ({ title: d })) : []

    this.setState({ loading: false, resultValues })
  }

  _cancelOverride = () => {
    const { props: { history } } = this
    history.push({ pathname: '/member-support' })
  }


  _setOverride = () => {
    const {
      location,
      actions
    } = this.props
    const { overrideForm, form, customDrugData } = this.state
    const {
      domain_name,
      flipt_person_id,
    } = location?.state?.data?.member_details;
    const overrideParams = {
      brand_generic: customDrugData.brand_generic,
      ddid: customDrugData.ddid, // in drug search res
      gpi: customDrugData.gpi,
      drug_name: customDrugData.drug_name,
      dosage: customDrugData.dosage,
      strengths: customDrugData.strengths,
      flipt_person_id: flipt_person_id,
      domain_name: domain_name,
      override_from: overrideForm.start_date,
      override_thru: overrideForm.end_date,
      override_by_edit: overrideForm.override_type,
      copay_override_type: overrideForm.copay_override_type,
      copay_amount_30ds: overrideForm.copay_amount_30ds,
      copay_amount_60ds: overrideForm.copay_amount_60ds,
      copay_amount_90ds: overrideForm.copay_amount_90ds,
      override_percentage: overrideForm.override_percentage,
      claim_or_extended: 'extended',
      payer_routing_override_bypass: 'N',
      isProactive: true

    }
    actions.setClaimOverride(overrideParams);
  }



  _updateOverrideField = (el, dropdown) => {
    const { overrideForm } = this.state
    const { name, value } = dropdown || el.currentTarget

    overrideForm[name] = value
    this.setState({ overrideForm })
  }

  updateDrugData = (data) => {
    if (data?.ndc) {
      this.setState({
        customDrugData: data,
      })
    }
    else this.setState({ customDrugData: data })
  }

  render() {
    const {
      customDrugData,
      form,
      overrideForm,
    } = this.state
    const {
      location,
    } = this.props
    const {
      first_name,
      member_id,
      last_name
    } = location?.state?.data?.member_details

    const copayOverrideTypeOptions = Object.keys(copayOverrideTypeValues).map((d, index) => ({
      key: index + 1,
      text: copayOverrideTypeValues[d].text,
      value: copayOverrideTypeValues[d].value,
    }))

    const displayCoPayOverrideType = overrideForm.override_type === 'drug_coverage'
      || overrideForm.override_type === 'copay'
    const displayOverridePercentage = displayCoPayOverrideType && overrideForm.copay_override_type === copayOverrideTypeValues.percentage.value
    const displayDSCopay = displayCoPayOverrideType && overrideForm.copay_override_type === copayOverrideTypeValues.fixed.value
    // && copay_override_type === copayOverrideTypeValues.fixed.value
    return (
      <div id="PODetails">
        <div className='ui small breadcrumb'>
          <Link to="/home" className="section">FAST</Link>
          <div className='divider'>/</div>
          <Link to="/client-setup-management" className="section">Client Setup and Management</Link>
          <div className='divider'>/</div>
          <Link to="/member-lookup" className="section">Member Lookup</Link>
          <div className='divider'>/</div>
          <div className='active section'>Proactive Override</div>
        </div>
        {/* <Breadcrumb {...this.props} /> */}
        <div className="header">Proactive Override</div>
        <div className="content">
          <div className="section-contents">
            <h2>Member Details</h2>
            <div className="fields-container">
              <FliptInput value={first_name} label="First Name" disabled stylized />
              <FliptInput value={last_name} label="Last Name" disabled stylized />
              <FliptInput value={member_id} label="Member Id" disabled stylized />
            </div>
          </div>
          <FliptDrugSearch
            isPod={true}
            editMode={true}
            searchForm={form}
            updateDrugData={(data) => this.updateDrugData(data)} />
          {customDrugData?.drug_name &&
            <div>
              <div className="section-contents">
                <h2>Enter Prescription Override</h2>

                <div className="fields-container">
                  <FliptDropdown value={overrideForm.override_type} options={overrideTypeValues}
                    name="override_type" label="Override Type" stylized
                    onChange={this._updateOverrideField} />
                  {displayCoPayOverrideType && <FliptDropdown value={overrideForm.copay_override_type} options={copayOverrideTypeOptions} name="copay_override_type"
                    label="Copay Override Type" stylized size="4" onChange={this._updateOverrideField}
                  />}

                </div>
              </div>
              {displayOverridePercentage && <div className="fields-container">
                <div className="fields">
                  <FliptInput value={overrideForm.override_percentage} name="override_percentage" label="Copay Percentage" stylized onChange={this._updateOverrideField} />
                </div>
              </div>}
              {displayDSCopay && <div className="fields-container">
                <div className="fields">
                  <FliptInput value={overrideForm.copay_amount_30ds} name="copay_amount_30ds" label="30 DS Copay" stylized onChange={this._updateOverrideField} />
                </div>
                <div className="fields">
                  <FliptInput value={overrideForm.copay_amount_60ds} name="copay_amount_60ds" label="60 DS Copay" stylized onChange={this._updateOverrideField} />
                </div>
                <div className="fields">
                  <FliptInput value={overrideForm.copay_amount_90ds} name="copay_amount_90ds" label="90 DS Copay" stylized onChange={this._updateOverrideField} />
                </div>
              </div>}
              <div className="section-contents">
                <div className="date-container">
                  <FliptDatePicker label="Start Date" placeholder="Start Date" name="start_date" onChange={this._updateOverrideField} value={convertStrToDateObj(overrideForm.start_date)} stylized />
                  <FliptDatePicker label="End Date" placeholder="End Date" name="end_date" onChange={this._updateOverrideField} value={convertStrToDateObj(overrideForm.end_date)} stylized />
                </div>
              </div>

              {/* <div className="fields-container">
                <FliptDropdown value={overrideForm.payer_routing_override_bypass} options={copayOverrideValues}
                  name="payer_routing_override_bypass" label="Payer Routing Override Bypass" stylized
                  onChange={this._updateOverrideField} />
              </div> */}


              <div className="buttons-container">
                <div className="fields">
                  <FliptButton name="SET OVERRIDE" className="primary" onClick={this._setOverride} />
                </div>
                <div className="fields">
                  <FliptButton name="CANCEL" className="primary" onClick={this._cancelOverride} />
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  state: {
    manualClaimEntry: state.manualClaimEntry,
    drugLookup: state.drugLookup,
    autoSuggestData: state.autoSuggest.autoSuggestData,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...ManualClaimEntryActions,
    ...DrugLookupActions,
    ...ClaimsActions,
    ...AppActions,
    ...AutoSuggestActions
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProactiveOverrideDetails)
