export default function increaseRowHeight(props) {
  const { node, api } = props
  const currentRowHeight = node.rowHeight
  if (node.rowHeight !== 260) {
    api.resetRowHeights()
    node.setRowHeight(Math.max(currentRowHeight, 260))
  } else if(node.rowHeight < 260){
    const unitRowHeight = 42
    const totalRowHeight = unitRowHeight * (node.data?.tier_configuration?.length || unitRowHeight)
    api.resetRowHeights()
    node.setRowHeight(totalRowHeight || unitRowHeight)
  }
  api.onRowHeightChanged()
}
