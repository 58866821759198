import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import './styles.scss'
import BreadCrumb from '../../../components/breadCrumb'
import Config from '../../../config'
import FliptButton from '../../../components/form/fliptButton'
import FliptGrid from '../../../components/fliptGrid'
import { Creators as AppActions } from '../../../redux/reducers/app'
import { Creators as ClientImplementationActions } from '../../../redux/reducers/api/clientImplementation'
import { Creators as NavigationActions } from '../../../redux/reducers/navigation'
import { LinkRenderer, LinkContainerRenderer, ColorCodedStatusRenderer, MultiRowRenderer } from '../../../components/fliptGrid/cellRenderers'
import { createQueryString, parseQueryString } from '../../../utils/utilities'


class ClientImplementations extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      form:{
        
      },
      reRender: 0,
      agGridRef: React.createRef(),
    }
  }
  componentDidMount() {
    const { history } = this.props
    const { location } = history

    if (!location) return
    const { form, agGridRef } = this.state

    this._qsToFormData()
    history.location.search = createQueryString(form)

    agGridRef.current.api.refreshServerSideStore({ route: null, purge: true }) // to refresh the grid server side data
    // const { actions } = this.props
    // // TODO FIX THIS. SO NO MORE SETTIMEOUT
    // const payload = { offset: 0 , limit:20}
    // setTimeout(() => actions.getAllClientImplementation(payload), Config.COMPONENT_DATA_LOAD_TIME)
  }

  _qsToFormData = () => {
    const { history } = this.props
    const { form } = this.state
    const qs = parseQueryString(history.location.search)

    Object.keys(qs).forEach((key) => {
      form[key] = qs[key]
    })
    this.setState({ form })
  }

  render() {
    const { props } = this
    const { state, state: {itemsPerPage}, actions } = props
    const { allImplementationData } = state
    const { agGridRef , form} = this.state
    const headers = [
      'doc_id', 'doc_name', 'lob', , 'estimated_go_live', 'estimated_start_date', 'estimated_testing_date', 'status','hierarchy','hierarchyIDs', 'days_remaining','view_details'
    ]

    const serverSideGridConfig = {
      rowModel: 'serverSide',
      serverSideStoreType: 'partial',
      cacheBlockSize: itemsPerPage,
      pagination: true,
      paginationPageSize: itemsPerPage,
      maxBlocksInCache: 1
    }
    const serverSideGridParams = {
      form,
      apiId: 'all-client-implementation',
      sagaToCall: actions.getAllClientImplementation,
      headers,
    }
    const cellRendererParams = {
      doc_id: {
        hide: true,
      },
      doc_name: {
        cellRenderer: LinkRenderer,
        overrideHeader: 'Implementation Name',
        path: '/create-implementation',
        searchArgs: { doc_id: 'doc_id' },
        state: {
          doc_id: 'doc_id', editMode: false, doc_name: 'doc_name', lob: 'lob', hierarchyIDs: 'hierarchyIDs', is_hierarchy_global: 'is_hierarchy_global', hierarchy: 'hierarchy'
        },
      },
      lob: {
        overrideHeader: 'Line of Business',
        width: 300
      },
      estimated_go_live: {
        overrideHeader: 'Estimated Go Live Date',
      },
      estimated_start_date: {
        overrideHeader: 'Estimated Start Date',
      },
      estimated_testing_date: {
        overrideHeader: 'Estimated Testing Date',
      },
      status: {
        cellRenderer: ColorCodedStatusRenderer,
      },
      hierarchy: {
        hide: true,
      },
      hierarchyIDs: {
        hide: true,
      },
      view_details: {
        cellRenderer: LinkContainerRenderer,
        searchArgs: [
          {
            path: '/tasks-info',
            searchArgs: { doc_id: 'doc_id' },
            label: 'View Details',
            state: {
              doc_id: 'doc_id', editMode: false, doc_name: 'doc_name', lob: 'lob', hierarchyIDs: 'hierarchyIDs', is_hierarchy_global: 'is_hierarchy_global', hierarchy: 'hierarchy'
            },
          },
        ],
        overrideHeader: 'View Details',
        width: 200,
        minWidth: 200,
        maxWidth: 200,
        filter: false     
      },
      // action: {
      //   cellRenderer: NewDrugPopupCellRenderer,
      //   searchArgs: {
      //     approvalParamsMapping: { tier_id: 'id', name: 'tier_name', version: 'tier_version' },
      //     doc_type: 'global_pharmacy_network',
      //     moduleViewDrugs: 'tier',
      //     onApproveClick: actions.approveTier,
      //     onNewVersionClick: actions.createNewVersion,
      //     onPublishClick: actions.publishDocument,
      //     path: '/create-implementation',
      //     qsArgs: { doc_id: 'doc_id' },
      //     state: {
      //       editMode: true, doc_id: 'doc_id', doc_name: 'network_name', doc_version: 'version', tiers: 'tiers', effective_start_date: 'start_date',
      //     },
      //     screen: 'pharmacyNetwork'
      //   },
      // },
      days_remaining: {
        overrideHeader: 'Days to Go Live',
      },
    }
    const gridData = allImplementationData

    return (
      <div id="clientImplementationManagement">
        <BreadCrumb {...this.props} />

        <div className="buttonContainer" align="right">
          <div className="header">Client Implementation</div>
          <Link to="/create-implementation">
            <FliptButton
              name="CREATE NEW IMPLEMENTATION"
              className="primary searchButton"
            />
          </Link>
        </div>
        <div className="content">
          <FliptGrid
            headers={headers}
            cellRendererParams={cellRendererParams}
            serverSideGridConfig={serverSideGridConfig}
            serverSideGridParams={serverSideGridParams}
            agGridRef={agGridRef}
            doAutoSize={true}
            suppressColumnVirtualisation
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  state: {
    allImplementationData: state.clientImplementation.allImplementationData,
    itemsPerPage: state.clientImplementation.itemsPerPage,
    totalRows: state.clientImplementation.totalRows
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
    ...NavigationActions,
    ...ClientImplementationActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientImplementations)
