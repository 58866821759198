export default {
    formulary_name: {
        type: 'dropdown',
        disabled: false,
        options: [],
    },
    effective_begin_date: {
        type: 'date',
        disabled: false,
    },
    effective_end_date: {
        type: 'date',
        disabled: false,
    },
}