import Immutable from 'seamless-immutable'
import { createActions, createReducer } from 'reduxsauce'


export const INITIAL_STATE = Immutable({
  data: [],
  contracts: [],
  contractsDropdown: [],
})

export const clearDrugPriceLookupReducer = () => INITIAL_STATE

export const setDrugPriceResultsReducer = (state = INITIAL_STATE, { payload }) => {
  return {
    ...state,
    data: payload,
  }
}

export const setDrugPriceContractsReducer = (state = INITIAL_STATE, { payload }) => {
  const contractsDropdown = payload.map(el => {
    return {
      key: el.doc_id,
      text: el.doc_name,
      value: el.doc_id
    }
  })

  return {
    ...state,
    contracts: payload,
    contractsDropdown,
  }
}

export const { Types, Creators } = createActions({
  clearDrugPriceLookup: null,
  fetchContractNames: [' payload'],
  fetchDrugPriceLookup: ['payload'],
  setDrugPriceResults: ['payload'],
  setDrugPriceContracts: ['payload'],
})

const HANDLERS = {
  [Types.CLEAR_DRUG_PRICE_LOOKUP]: clearDrugPriceLookupReducer,
  [Types.SET_DRUG_PRICE_RESULTS]: setDrugPriceResultsReducer,
  [Types.SET_DRUG_PRICE_CONTRACTS]: setDrugPriceContractsReducer,
}

export default createReducer(INITIAL_STATE, HANDLERS)
