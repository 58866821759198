import React, { forwardRef } from "react"

import * as fieldValidators from '../../../utils/validationHelpers'
import "./styles.scss"
import { Icon } from "semantic-ui-react"

const NewFliptUploadInput = forwardRef((props = {}, ref) => {
	const {
		className,
		datatype,
		inputClass,
		label,
		required,
		stylized,
		validation,
		customValidation,
		onChange,
		onBlur,
	} = props

	const _onChange = (el) => {
		if (!onChange || typeof onChange !== "function") return

		const ct = el.currentTarget

		// if (validation && validators[validation]) {
		// 	if (validators[validation](ct.value)) {
		// 		onChange(el)
		// 	}
		// } else {
		onChange(el)
		// }
	}

	const inputProps = { ...props }

	if (inputProps.disabled) {
		inputProps.value = inputProps.value || ""
	}

	const inputType = !!datatype && datatype === "numeric" ? "number" : "text"
	return (
		<div className={`pa-stylized ${className || ""}`}>
			<div className="flipt-pa-label" hidden={inputProps.hidden || false}>
				<label htmlFor={label}>{label}</label>
			</div>
			<div className="upload-container">
				<div className="uploadInput">
					<div className="input-container">
						<label for="inputTag">
							<div className="featured-icon">
								<Icon className="folder open outline" /> <br />
							</div>
							<span className="actionText">Click to upload</span>{" "}
							or drag and drop file
							<input
								id="inputTag"
								type="file"
								onChange={_onChange}
							/>
							<br />
							<span id="imageName"></span>
						</label>
						<p className="helperText">
							xlsx, csv, or xml (max. 800x400px)
						</p>
					</div>
				</div>
			</div>
		</div>
	)

	// return <input {...inputProps} onKeyUp={validateField} ref={ref} />
})

export default NewFliptUploadInput
