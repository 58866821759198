import React from 'react'
import FliptGrid from '../../../../../components/fliptGrid'
import { LinkContainerRenderer } from '../../../../../components/fliptGrid/cellRenderers'
import './styles.scss'
import { filterRowData } from '../../../../../utils/utilities'
export default ({ data }) => {
  const { claim_override_details } = data
  const paDetails = claim_override_details.length ? claim_override_details : []
  const headers = [
    'Action', 'drug_name',
    'gpi', 'override_number', 'claim_only_flag', 'override_type', 'override_from',
    'override_thru', 'override_status', 'payer_routing_override_bypass', 'override_percentage',
    'sequenceNumber', 'auth_id', 'override_by_edit', 'copay_override',
    'routed_override_processor', 'override_amount'
  ]
  const paDetailsData = paDetails.map((d) => ({
    ...filterRowData(d, headers)
  }))
  const cellRendererParams = {
    override_from: {
      overrideHeader: 'Start Date',
    },
    override_thru: {
      overrideHeader: 'End Date',
    },
    routed_override_processor: {
      hide: true,
    },
    copay_override: {
      hide: true,
    },
    override_by_edit: {
      hide: true,
    },
    auth_id: {
      hide: true,
    },
    sequenceNumber: {
      hide: true,
    },
    payer_routing_override_bypass: {
      hide: true,
    },
    override_percentage: {
      hide: true,
    },
    override_amount: {
      hide: true,
    },
    Action: {
      // TODO LinkContainerRenderer does not work with state
      cellRenderer: LinkContainerRenderer,
      searchArgs: [
        {
          path: '/claim-override',
          searchArgs: { sequence_number: 'sequenceNumber', auth_id: 'auth_id' },
          staticSearchArgs: {},
          state: {
            gpi: 'gpi',
            override_thru: 'override_thru',
            override_type: 'override_type',
            override_from: 'override_from',
            override_status: 'override_status',
            payer_routing_override_bypass: 'payer_routing_override_bypass',
            override_by_edit: 'override_by_edit',
            override_percentage: 'override_percentage',
            routed_override_processor: 'routed_override_processor',
            override_number: 'override_number',
            copay_override: "copay_override",
            claim_only_flag: 'claim_only_flag',
            auth_id: 'auth_id',
            sequence_number: 'sequenceNumber',
            override_amount: 'override_amount'
          },
          staticState: {
            readOnly: true
          },
          label: 'View',
        },
        {
          path: '/claim-override',
          searchArgs: { sequence_number: 'sequenceNumber', auth_id: 'auth_id' },
          staticSearchArgs: {},
          state: {
            gpi: 'gpi',
            override_thru: 'override_thru',
            override_type: 'override_type',
            override_by_edit: 'override_by_edit',
            override_from: 'override_from',
            override_status: 'override_status',
            routed_override_processor: 'routed_override_processor',
            override_number: 'override_number',
            copay_override: "copay_override",
            payer_routing_override_bypass: 'payer_routing_override_bypass',
            override_percentage: 'override_percentage',
            claim_only_flag: 'claim_only_flag',
            auth_id: 'auth_id',
            sequence_number: 'sequenceNumber',
            override_amount: 'override_amount'
          },
          staticState: {
            editMode: true
          },

          label: 'Edit',
        },
      ],
    },
  }
  return (
    <div className="section" id="claim-override-section">
      <div className="section-header">
        <h2>Claim Overrides</h2>
      </div>

      <div className="content">
        <FliptGrid
          data={paDetailsData}
          headers={headers}
          cellRendererParams={cellRendererParams}
        />
      </div>
    </div>
  )
}
