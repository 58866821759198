import packageJSON from '../../package.json'

const {
  REACT_APP_API_URL,
  NODE_ENV,
} = process.env

export default {
  appVersion: packageJSON.version,
  ENVIRONMENT: NODE_ENV,
  apiUrl: REACT_APP_API_URL ? `${REACT_APP_API_URL}/api` : '/api',
  applicationId: 'FAST-APP',
  REACT_APP_AG_GRID_LICENSE_KEY: 'CompanyName=Flipt LLC,LicensedApplication=Fliptrx FAST Self Service,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-025749,ExpiryDate=31_March_2023_[v2]_MTY4MDIxNzIwMDAwMA==8a76e589509610931bd963b295bcc55c',
  COMPONENT_DATA_LOAD_TIME: 500,
  SENTRY_DSN: 'https://b23c3a95bbf74392a77a1f286e8061e0@o205460.ingest.sentry.io/5999034',
  FAST_SELF_SERVICE_URL: "https://fastselfservice.helpdocsonline.com/home",
  FAST_IT_EMAIL: "fliptitservices@fliptrx.com"
}
