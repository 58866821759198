import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import './styles.scss'
import BreadCrumb from '../../../components/breadCrumb'
import Config from '../../../config'
import FliptButton from '../../../components/form/fliptButton'
import FliptDropdown from '../../../components/form/fliptDropdown'
import FliptGrid from '../../../components/fliptGrid'
import FliptInput from '../../../components/v2/fliptInput'
import FliptSearch from '../../../components/form/fliptSearch'
import { Creators as DrugPriceLookupActions } from '../../../redux/reducers/api/drugPriceLookup'
import { Creators as AutoSuggestActions } from '../../../redux/reducers/api/autoSuggest'

class DrugPriceLookup extends Component {
  constructor(props) {
    super(props)

    this.agGridRef = React.createRef();
    const defaultPricetype = [
      {
        key: "",
        text: "None",
        value: "",
      },
      {
        key: "MAC",
        text: "MAC",
        value: "MAC",
      },
      {
        key: "NADAC",
        text: "NADAC",
        value: "NADAC",
      },
      {
        key: "AWP",
        text: "AWP",
        value: "AWP",
      },
      {
        key: "WAC",
        text: "WAC",
        value: "WAC",
      }
    ]

    this.state = {
      resultValues: [],
      priceType: defaultPricetype,
      loading: false,
      form: {
        contract_id: '',
        price_type: '',
        gpi: '',
        drug_name: '',
        effective_start_date: '',
        effective_end_date: ''
      },
    }
  }

  componentDidMount() {
    const { actions } = this.props

    setTimeout(() => {
      actions.clearDrugPriceLookup()
      actions.fetchContractNames()
    }, Config.COMPONENT_DATA_LOAD_TIME)
  }

  componentDidUpdate(prevProps) {
    const { props } = this
    const { state: { autoSuggestData } } = props
    const prevState = prevProps.state
    const currState = props.state
    if (prevState === currState) return
    this._updateResultSearchValues(autoSuggestData)
  }

  _updateResultSearchValues(drugsArray) {
    const suggestList = drugsArray['drug_name']
    const resultValues = suggestList ? suggestList.map((d) => ({ title: d })) : []

    this.setState({ loading: false, resultValues })
  }

  _findContractType = (el, dropdown) => {
    const { contracts } = this.props.state
    const { form } = this.state
    const { value } = dropdown || el.currentTarget
    const priceType = contracts.reduce((acc = [], curr) => {
      if (curr.doc_id === value) {
        curr.price_type.forEach(el => {
          acc.push({
            key: el,
            text: el,
            value: el
          })
        })
      }
      return acc
    }, [])
    const formData = {
      ...form,
      contract_id: value
    }

    if (priceType.length === 1) {
      formData['price_type'] = priceType[0].value
    }

    this.setState({
      form: formData,
      priceType,
    })
  }

  _setPriceType = (el, dropdown) => {
    const { form } = this.state
    const { value } = dropdown || el.currentTarget

    this.setState({
      form: {
        ...form,
        price_type: value,
      }
    })
  }

  _submitForm = () => {
    const { form } = this.state
    const { fetchDrugPriceLookup } = this.props.actions

    console.log(form)
    fetchDrugPriceLookup(form)
  }

  _setFormData = (el, dropdown) => {
    const { form } = this.state
    const { name, value } = dropdown || el.currentTarget

    form[name] = value
    this.setState({ form })
  }

  _onAutoSuggestionResultSelect = (e, data) => {
    const { form } = this.state
    const { name, result } = data
    form[name] = result.title

    this.setState({
      form,
      loading: false
    })
  }

  _updateAutoSuggestionResult(e, data) {
    const { actions } = this
    const { name } = data

    if (name === 'drug_name' && value.length >= minCharacters) {
      actions.getAutoSuggestData({ search_string: value, search_in: 'fts_ndc_drugs', search_for: 'drug_name' })
      this.setState({ loading: false, drugNames })
    }

  }

  _onResultSelect = (e, data) => {
    const { form } = this.state

    form['drug_name'] = data.result.title
    this.setState({ form })
  }

  _updateFields = (el, dropdown) => {
    const { form } = this.state
    const { actions } = this.props
    const { name, value } = dropdown || el.currentTarget

    form[name] = value
    this.setState({ form })

    if (name === 'drug_name') {
      if (value.length >= 3) {
        actions.clearAutoSuggestData()
        actions.getAutoSuggestData({ search_string: value, search_in: 'fts_ndc_drugs', search_for: 'drug_name' })

      }
    }
  }

  render() {
    const { priceType, resultValues, loading, form } = this.state
    const { drugPriceLookupData, contractsDropdown } = this.props.state
    const headers = [
      {
        headerName: "Contract Name",
        field: "contract_name",
      },
      {
        headerName: "Contract Type",
        field: "contract_type",
      },
      {
        headerName: "Price Type",
        field: "price_type",
      },
      {
        headerName: "Drug Name",
        field: "drug_name",
      },
      {
        headerName: "GPI",
        field: "gpi",
      },
      {
        headerName: "GPPC",
        field: "gppc",
      },
      // {
      //   headerName: "Dosage",
      //   field: "dosage",
      // },
      // {
      //   headerName: "Strength",
      //   field: "strength",
      // },
      {
        headerName: "Unit Price",
        field: "unitprice",
      },
      {
        headerName: "MAC List",
        field: "maclistid",
      },
      {
        headerName: "Effective Start Date",
        field: "effective_start_date",
      },
      {
        headerName: "Effective End Date",
        field: "effective_end_date",
      },
    ]
    const cellRendererParams = {
      contract_type: {
        overrideHeader: 'Contract Type',
      },
      contract_name: {
        overrideHeader: 'Contract Name'
      }
      // Action: {
      //   // TODO LinkContainerRenderer does not work with state
      //   cellRenderer: LinkContainerRenderer,
      //   searchArgs: [
      //     {
      //       path: '/role-management-edit',
      //       searchArgs: { id: 'id' },
      //       staticState: { editMode: false },
      //       label: 'View',
      //     },
      //     {
      //       path: '/role-management-edit',
      //       searchArgs: { id: 'id' },
      //       staticState: { editMode: true },
      //       label: 'Edit',
      //     },
      //   ],
      // },
      // id: {
      //   hide: true,
      // },
    }

    const serverSideGridConfig = {
      cacheBlockSize: 20,
      pagination: true,
      paginationPageSize: 100,
      // rowModel: 'serverSide',
      // serverSideStoreType: 'partial',
    }

    // const serverSideGridParams = {
    //   form: form || {},
    //   apiId: "drug-price-lookup",
    //   sagaToCall: fetchDrugPriceLookup,
    //   headers: headers
    // };



    // const serverSideGridParams = {
    //   form: updatedForm,
    //   apiId: 'view-tier-drugs',
    //   sagaToCall: actions.getFormularyTierDrugsViewData,
    //   headers,
    // }

    return (
      <div id="DrugPriceLookup">
        <BreadCrumb {...this.props} />
        <div className="header">Drug Price Lookup</div>
        <div className="content">
          <div className="section">
            <div className="fields-container">
              <div className="fields">
                <FliptDropdown
                  description="Contract Name"
                  label="Contract Name"
                  name="contract_name"
                  onChange={this._findContractType}
                  options={contractsDropdown}
                  placeholder="Contract Name"
                  stylized
                />
              </div>
              <div className="fields">
                <FliptDropdown
                  defaultValue={priceType[0]}
                  description="Price Type"
                  disabled={true}
                  label="Price Type"
                  name="price_type"
                  onChange={this._setPriceType}
                  options={priceType}
                  placeholder="Price Type"
                  stylized
                />
              </div>
              <div className="fields">
                <FliptInput name="gpi" onChange={this._setFormData} type="number" label="GPI" description="GPI" />
              </div>
            </div>
            <div className="fields-container">
              <div className="fields">
                <FliptSearch name="drug_name"
                  label="Drug Name" description="Drug Name" stylized="true"
                  minCharacters="3"
                  onResultSelect={(e, data) => this._onResultSelect(e, data)}
                  onSearchChange={(e, dropdown) => this._updateFields(e, dropdown)}
                  placeholder="Drug Name"
                  results={resultValues} loading={loading}
                  value={form.drug_name || form.ndc}
                />
              </div>
              <div className="fields">
                <FliptInput name="effective_start_date" onChange={this._setFormData} type="date" label="Effective Start Date" description="Effective Start Date" />
              </div>
              <div className="fields">
                <FliptInput name="effective_end_date" onChange={this._setFormData} type="date" label="Effective End Date" description="Effective End Date" />
              </div>
              <div className='fields button-container'>
                <FliptButton className="primary" name="Search" onClick={this._submitForm} />
              </div>
            </div>
          </div>
          <div className="section">
            <div className="grid-container">
              <FliptGrid
                // cellRendererParams={cellRendererParams}
                agGridRef={this.agGridRef}
                data={drugPriceLookupData}
                headers={headers}
                rowSelection="single"
                serverSideGridConfig={serverSideGridConfig}
              // serverSideGridParams={serverSideGridParams}
              />
            </div>
          </div>
        </div >
      </div >
    )
  }
}

const mapStateToProps = (state) => ({
  state: {
    autoSuggestData: state.autoSuggest.autoSuggestData,
    contracts: state.drugPriceLookup.contracts,
    contractsDropdown: state.drugPriceLookup.contractsDropdown,
    drugLookup: state.drugLookup,
    drugPriceLookupData: state.drugPriceLookup.data,
    isLoading: state.autoSuggest.isLoading,
  }
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    // ...AppActions,
    ...AutoSuggestActions,
    ...DrugPriceLookupActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DrugPriceLookup)
