import React, { PureComponent } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import './styles.scss'
import BreadCrumb from '../../../../../components/breadCrumb'
import ClaimSection from './claimSection'
import Config from '../../../../../config'
import OverrideSection from './overrideSection'
import PatientSection from './patientSection'
import PharmacySection from './pharmacySection'
import ProductSection from './productSection'
import SubmenuNav from '../shared/navigation'
import TransferSection from './transferSection'

import { Creators as ApiActions } from '../../../../../redux/reducers/api/claimLookupManagement'
import COBSection from './COBSection'

class ClaimTransactions extends PureComponent {
  componentDidMount() {
    // TODO FIX THIS. SO NO MORE SETTIMEOUT
    const { actions } = this.props
    setTimeout(() => actions.getClaimTransactionData(), Config.COMPONENT_DATA_LOAD_TIME)
  }

  _removeClaimOveride = () => {
    const { props } = this
    props.actions.removeClaimOverride()
  }

  render() {
    const { history, state } = this.props
    const { claimOverview, claimRequestData } = state

    return (
      <div id="claimTransactionOverview" className="content-co">
        <BreadCrumb {...this.props} />
        <div className="header">
          <h1>Transaction</h1>
          <SubmenuNav history={history} active="overview" />
        </div>
        <div className="subhead" />
        <div className="content">
          {claimOverview && claimOverview.member_section && <PatientSection data={claimOverview.member_section} claimData={claimOverview.claim_section} />}
          {claimOverview && claimOverview.claim_section && <ClaimSection data={claimOverview.claim_section} />}
          {claimRequestData && claimRequestData.claim_request && <COBSection data={claimRequestData.claim_request} />}
          {claimOverview && claimOverview.transfer_section && <TransferSection data={claimOverview.transfer_section} />}
          {claimOverview && claimOverview.pharmacy_section && <PharmacySection data={claimOverview.pharmacy_section} />}
          {claimOverview && claimOverview.product_section && <ProductSection data={claimOverview.product_section} />}
          {claimOverview && claimOverview.override_section && <OverrideSection data={claimOverview.override_section} removeClaimOveride={this._removeClaimOveride} />}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  state: {
    claimOverview: state.claimLookup.claimTransactionData.overview_section,
    claimRequestData: state.claimLookup.claimTransactionData.claim_request_and_response_section,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...ApiActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClaimTransactions)
