export default [
    {
        key: 'Processing fee',
        text: 'Processing fee',
        value: 'PROCESSING',
    },
    {
        key: 'Admin fee',
        text: 'Admin fee',
        value: 'ADMIN',
    },
    {
        key: 'Stock fee',
        text: 'Stock fee',
        value: 'STOCK',
    },
    {
        key: 'Dispensing fee',
        text: 'Dispensing Fee',
        value: 'DISPENSING',
    },
]