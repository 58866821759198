import React from 'react'

import './styles.scss'
import FliptGrid from '../../../../components/fliptGrid'
import { LinkRenderer } from '../../../../components/fliptGrid/cellRenderers'


export default function BatchClaims(props) {
    const { step } = props
    const onClaimSelectionChanged = (data) => {
        const { setSelectedRows } = props
        const selectedRows = data.api.getSelectedRows();
        setSelectedRows(selectedRows)
    }

    const getClaimSummary = (claimData) => {
        const { data } = claimData
        const { actions, batch_id, setSelectedClaim } = props
        setSelectedClaim(data)
        const apiParams = {
            batch_id,
            doc_id: data.id,
            sequence_number: data.sequenceNumber
        }
        actions.getClaimSummary(apiParams)
    }


    const { batch_id, actions, batchCompleted, batchDetailsData } = props
    const params = {
        batch_id: batch_id,
        batchDetailsData: batchDetailsData,
    }
    const serverSideGridConfig = {
        rowModel: 'serverSide',
        serverSideStoreType: 'partial',
        cacheBlockSize: 20,
        pagination: true,
        paginationPageSize: 20,
    }
    const authHeaders = ['select', 'auth_id', 'status', 'transaction_response_status']
    if (step >= 2) {
        authHeaders.push('patient_pay_amount_difference',
            'calculated_drug_cost_difference')
    }
    const authHeadersFilters = [...authHeaders]
    authHeadersFilters.shift()
    const serverSideGridParams = {
        form: params,
        apiId: 'get-claims-by-batch-id',
        sagaToCall: actions.getBatchClaims,
        headers: batchCompleted ? authHeadersFilters : authHeaders,
    }
    const authCellRendererParams = {
        select: {
            width: '120px',
            headerCheckboxSelection: true,
            checkboxSelection: true,
            showDisabledCheckboxes: true,
        },
        auth_id: {
            cellRenderer: LinkRenderer,
            onCellClicked: getClaimSummary,
            searchArgs: {},
        },
        status: {
            overrideHeader: 'Claim Status'
        },
        patient_pay_amount_difference: {
            width: 250
        },
        calculated_drug_cost_difference: {
            width: 250
        },
        transaction_response_status: {
            width: 250
        }
    }
    return (
        <FliptGrid
            headers={batchCompleted ? authHeadersFilters : authHeaders}
            rowSelection='multiple'
            serverSideGridConfig={serverSideGridConfig}
            serverSideGridParams={serverSideGridParams}
            cellRendererParams={authCellRendererParams}
            suppressRowClickSelection={true}
            onSelectionChanged={(data) => { onClaimSelectionChanged(data) }}
        />
    )
}
