import Immutable from 'seamless-immutable'
import { createActions, createReducer } from 'reduxsauce'

export const INITIAL_STATE = Immutable({
  accumulator_details: [],
  deductible_details: {},
  family_details: {},
  primary_member_details: {},
  member_details: {},
  multiMemberLookup: [],
  pa_details: {},
  plan_details: {},
  memberExistData: {},
  saveUserData: {},
  memberDetailsData: {},
  communicationHistory: [],
  saveCardData: {},
  medicare_details: [],
  memberLookupFailed: false,
  loading: false,
  passwordUpdateStatus: null,
  memberLogs: []
})

export const setMemberLookupDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  ...payload,
})

export const setMultiMemberLookupDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  multiMemberLookup: payload,
})

export const setMemberExistDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  memberExistData: payload,
})

export const setSaveUserDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  saveUserData: payload,
})

export const setMemberDetailsDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  memberDetailsData: payload,
})

export const setMemberLogsReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  memberLogs: payload,
})


export const clearMemberLookupDataReducer = () => INITIAL_STATE

export const setAccumulatorDetailsDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  accumulator_details: payload,
})

export const setMedicareDetailsDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  medicare_details: payload,
})

export const setMemberLookupFailureReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  memberLookupFailed: payload,
})

export const setMemberRestrictionsReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  memberLookupFailed: payload,
})

export const setCommunicationHistoryDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  communicationHistory: payload,
})

export const setSaveCardDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  saveCardData: payload,
})

export const setLoading = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  loading: payload,
})

export const setPasswordUpdateStatus = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  passwordUpdateStatus: payload,
})

export const { Types, Creators } = createActions({
  clearMemberLookupData: null,
  clearReducer: null,
  fetchAccumulatorDetailsData: null,
  fetchMemberLookupData: ['payload'],
  getAccumulatorDetailsData: null,
  getMemberDeductibleDetailsData: null,
  getMemberLookupData: ['payload'],
  getCommunicationHistoryData: ['payload'],
  setAccumulatorDetailsData: ['payload'],
  setMemberLookupData: ['payload'],
  setMultiMemberLookupData: ['payload'],
  updateMemberData: ['payload'],
  fetchMemberDetailsData: ['payload'],
  checkIfMemberExist: ['payload'],
  setMemberExistData: ['payload'],
  saveUser: ['payload'],
  setSaveUserData: ['payload'],
  setMemberDetailsData: ['payload'],
  setCommunicationHistoryData: ['payload'],
  saveCard: ['payload'],
  setSaveCardData: ['payload'],
  setLoading: ['payload'],
  getMemberMedicareDetailsData: null,
  setMedicareDetailsData: ['payload'],
  setMemberLookupFailure: ['payload'],
  setPasswordUpdateStatus: ['payload'],
  getMemberRestrictionsData: ['payload'],
  setMemberRestrictionsData: ['payload'],
  saveMemberRestrictionsData: ['payload'],
  updateMemberPassword: ['payload'],
  setMemberLogs: ['payload'],
  getMemberLogs: ['payload']
})

const HANDLERS = {
  [Types.CLEAR_MEMBER_LOOKUP_DATA]: clearMemberLookupDataReducer,
  [Types.CLEAR_REDUCER]: clearMemberLookupDataReducer,
  [Types.SET_ACCUMULATOR_DETAILS_DATA]: setAccumulatorDetailsDataReducer,
  [Types.SET_MEMBER_LOOKUP_DATA]: setMemberLookupDataReducer,
  [Types.SET_MULTI_MEMBER_LOOKUP_DATA]: setMultiMemberLookupDataReducer,
  [Types.SET_MEMBER_EXIST_DATA]: setMemberExistDataReducer,
  [Types.SET_SAVE_USER_DATA]: setSaveUserDataReducer,
  [Types.SET_MEMBER_DETAILS_DATA]: setMemberDetailsDataReducer,
  [Types.SET_COMMUNICATION_HISTORY_DATA]: setCommunicationHistoryDataReducer,
  [Types.SET_SAVE_CARD_DATA]: setSaveCardDataReducer,
  [Types.SET_MEDICARE_DETAILS_DATA]: setMedicareDetailsDataReducer,
  [Types.SET_MEMBER_LOOKUP_FAILURE]: setMemberLookupFailureReducer,
  [Types.SET_MEMBER_RESTRICTIONS_DATA]: setMemberRestrictionsReducer,
  [Types.SET_LOADING]: setLoading,
  [Types.SET_PASSWORD_UPDATE_STATUS]: setPasswordUpdateStatus,
  [Types.SET_MEMBER_LOGS]: setMemberLogsReducer,
}

export default createReducer(INITIAL_STATE, HANDLERS)
