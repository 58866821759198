import React, { useState, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import FliptGrid from '../../../../components/fliptGrid'
import BreadCrumb from '../../../../components/breadCrumb'
import FliptButton from '../../../../components/form/fliptButton'
import FliptTree from '../../../../components/fliptTree'
import FliptCheckbox from '../../../../components/form/fliptCheckbox'
import { validate, validateDuplicateRx, validateDuplicateTherapy, validateDosage, validateRestriction, validateOpioid, validateRegimenCompliance } from './data/validations'
import FliptRadio from '../../../../components/form/fliptRadio'
import FliptLabel from '../../../../components/form/fliptLabel'
import FliptInput from '../../../../components/form/fliptInput'
import { changeNodeAtPath } from '@nosferatu500/react-sortable-tree'
import PROGRESSION from '../../../../config/constants/progression'
import { Creators as DrugUtilizationReviewActions } from '../../../../redux/reducers/api/drugUtilizationReview'
import { Creators as AppActions } from '../../../../redux/reducers/app'
import { capitalizeStr, convertSnakeCaseToString, parseQueryString } from '../../../../utils/utilities'
import Config from '../../../../config'
import _, { isEqual } from 'lodash'
import './styles.scss'
import FliptDatePicker from "../../../../components/form/fliptDatePicker"
import { convertStrToDateObj } from '../../../../utils/utilities'
import DrugToDrugInteractionGrid from './gridData/drugToDrugInteractionGrid'
import DuplicateRxGrid from './gridData/duplicateRxGrid'
import DuplicateTherapyGrid from './gridData/duplicateTherapyGrid'
import DosageGrid from './gridData/dosageGrid'
import AgeAndGenderGrid from './gridData/ageAndGenderGrid'
import OpioidGrid from './gridData/opioidGrid'
import RegimenComplianceGrid from './gridData/regimenComplianceGrid'
import { dosageHeader, drugToDrugHeader, duplicateRxHeader, duplicateTherapyHeader, regimenComplianceHeader, opioidHeaders } from './gridData/data'
import { defaultTabConfigs } from '../tabData'
import { mmeHeaders, opioidTabConfigs } from '../../opioidManagement/tabData'
import { roaExclusionsOptions, fillsAllowedType } from './data/options'
import FliptDropdown from '../../../../components/form/fliptDropdown'

const buildTree = (hierarchy, selected = []) => {
    return (hierarchy).map(ele => ({
        ...ele,
        title: () => {
            return <div style={{ 'font-size': '10px', 'font-weight': 'normal' }}>{ele?.level.toUpperCase()}</div>
        },
        subtitle: () => {
            return <div style={{ 'font-size': '10px', 'font-weight': 'bold' }}>{ele[`${ele?.level}_name`]}</div>
        },
        node_key: ele?.key,
        selected: selected.includes(ele?.key),
        children: buildTree(ele?.children || [], selected)
    }))
}

const drugUtilizationReview = (props) => {
    const userHierarchy = props?.state?.userHierarchy || {}
    const { history = {} } = props
    const { location = {} } = history || {}
    const { search } = location
    const { create = false, selectedTab, isOpioid } = location?.state
    const [data, setData] = useState({ application: 'Application/Web', roa_exclusions: [], dur_type: selectedTab, opioid_program: 'Medispan Standard', is_global: 'Yes' })
    const [treeData, setTreeData] = useState([])
    const [searchString, setSearchString] = useState('')

    const delRow = (rowIndex) => {
        const conditionsOld = data?.conditions || []
        const conditions = [...conditionsOld]
        conditions.splice(rowIndex, 1)
        setData({ ...data, conditions })
    }

    const onChange = (el, dropdown) => {
        const { name, value, checked } = dropdown || el.currentTarget
        if (name === 'agree') {
            setData({ ...data, [name]: checked ? 'Y' : 'N' })
            return
        }
        if (name === 'is_global') {
            setData({ ...data, [name]: value })
            return
        }

        setData({ ...data, [name]: value })
    }
    const getSelectedHeader = () => {
        if (selectedTab === defaultTabConfigs[1].id) return drugToDrugHeader
        else if (selectedTab === defaultTabConfigs[2].id) return duplicateRxHeader
        else if (selectedTab === defaultTabConfigs[3].id) return duplicateTherapyHeader
        else if (selectedTab === defaultTabConfigs[4].id || selectedTab === defaultTabConfigs[5].id) return dosageHeader
        else if (selectedTab === defaultTabConfigs[6].id) return regimenComplianceHeader
        else return opioidHeaders
    }
    const addRow = (el) => {
        const selectedHeader = getSelectedHeader()
        const row = selectedHeader.reduce((previous, current) => {
            previous[current] = ''
            return previous
        }, {})
        const conditionsOld = data?.conditions || []
        setData({ ...data, conditions: [...conditionsOld, row] })
    }

    const _changeSelectedChildren = (node, selected) => {
        if (!node) return
        for (let i = 0; i < node.length; i++) {
            node[i].selected = selected
            _changeSelectedChildren(node[i].children, selected)
        }
    }

    const _changeSelectedNode = (leaf) => {
        const { path, node } = leaf
        const selected = !node.selected
        node.selected = selected
        _changeSelectedChildren(node.children, selected)
        setTreeData(changeNodeAtPath({
            treeData: treeData,
            path,
            getNodeKey: ({ treeIndex }) => treeIndex,
            newNode: node,
        })
        )
    }

    const _convertTreeToFlat = (data, newHierarchy) => {
        if (!data) return
        for (let i = 0; i < data.length; i++) {
            if (!data[i]) return
            const idx = PROGRESSION.indexOf(data[i]?.level)
            if (idx !== -1) {
                if (data[i]?.selected) newHierarchy.push(data[i]?.node_key)
                _convertTreeToFlat(data[i].children, newHierarchy)
            }
        }
    }

    const onTreeChange = (el) => {
        setTreeData(el)
    }

    const generateNodeProps = (leaf) => {
        const { node } = leaf
        return ({
            buttons: [<FliptCheckbox checked={node?.selected} disabled={data?.is_global === 'Yes'} onClick={() => { _changeSelectedNode(leaf) }} />]
        })
    }

    const customSearchMethod = ({ node, searchQuery }) => {
        return (
            searchQuery && node[`${node?.level}_name`]?.toLowerCase().indexOf(searchQuery?.toLowerCase()) > -1
        )
    }

    const getValidation = (key, payload) => {
        if (selectedTab === defaultTabConfigs[1].id) return validate[key](payload)
        if (selectedTab === defaultTabConfigs[2].id) return validateDuplicateRx[key](payload)
        if (selectedTab === defaultTabConfigs[3].id) return validateDuplicateTherapy[key](payload)
        if (selectedTab === defaultTabConfigs[4].id) return validateDosage[key](payload)
        if (selectedTab === defaultTabConfigs[5].id) return validateRestriction[key](payload)
        if (selectedTab === opioidTabConfigs[1].id) return validateOpioid[key](payload)
        if (selectedTab === defaultTabConfigs[6].id) return validateRegimenCompliance[key](payload)
        else return null
    }

    const getValidationObject = (key, payload) => {
        if (selectedTab === defaultTabConfigs[1].id) return validate
        if (selectedTab === defaultTabConfigs[2].id) return validateDuplicateRx
        if (selectedTab === defaultTabConfigs[3].id) return validateDuplicateTherapy
        if (selectedTab === defaultTabConfigs[4].id) return validateDosage
        if (selectedTab === defaultTabConfigs[5].id) return validateRestriction
        if (selectedTab === opioidTabConfigs[1].id) return validateOpioid
        if (selectedTab === defaultTabConfigs[6].id) return validateRegimenCompliance
        else return {}
    }

    const validations = (payload) => {
        const { actions } = props
        const isValid = Object.keys(getValidationObject()).every(key => {
            const error = getValidation(key, payload)
            if (error?.length) {
                const transitionalPortal = {
                    header: 'Validation Error',
                    copy: (Array.isArray(error) && error?.length && error[0]) || error
                }
                actions.displayTransitionalPortal(transitionalPortal)
                return false
            }
            return true
        })
        return isValid
    }

    const submitForm = () => {
        const { actions } = props
        let newHierarchy = {}
        treeData[0].children.forEach(org => {
            let newOrgHierarchy = []
            _convertTreeToFlat([org], newOrgHierarchy)
            if (newOrgHierarchy.length) {
                const title = org?.hierarchy_uuid
                newHierarchy[title] = newOrgHierarchy
            }
        })
        const payload = { ...data, hierarchy: newHierarchy }
        if (!('is_global' in payload)) {
            payload['is_global'] = 'No'
        }
        if (validations(payload)) actions?.putDurData({ ...data, hierarchy: newHierarchy })
    }

    useEffect(() => {
        const { dur } = props?.state || {}
        if (!isEqual(dur, data) && !create) {
            setData({ ...data, ...dur })
        }
        const selected = []
        Object.keys(dur?.hierarchy || {}).forEach(k => {
            selected.push(k)
            selected.push(...dur.hierarchy[k])
        })
        const childrenOfTop = Object.values(userHierarchy).map(x => x[0])
        const treeWithTop = [{
            access: true,
            children: _.cloneDeep(buildTree(childrenOfTop, selected || [])),
            node_key: 'topLevel',
            subtitle: '~',
            title: 'Available Organizations',
        }]
        setTreeData(treeWithTop)
    }, [userHierarchy, props?.state?.dur])

    useEffect(() => {
        const { actions } = props
        actions.clearDurData()
        if (search) {
            const qs = parseQueryString(search)
            setTimeout(() => actions.getDurData(qs), Config.COMPONENT_DATA_LOAD_TIME)
        }
    }, [])

    const renderGrid = () => {
        if (selectedTab === defaultTabConfigs[1].id) return <DrugToDrugInteractionGrid setData={setData} data={data} delRow={delRow} />
        if (selectedTab === defaultTabConfigs[2].id) return <DuplicateRxGrid setData={setData} data={data} delRow={delRow} />
        if (selectedTab === defaultTabConfigs[3].id) return <DuplicateTherapyGrid setData={setData} data={data} delRow={delRow} />
        if (selectedTab === defaultTabConfigs[4].id) return <DosageGrid setData={setData} data={data} delRow={delRow} />
        if (selectedTab === defaultTabConfigs[5].id) return <AgeAndGenderGrid setData={setData} data={data} delRow={delRow} />
        if (selectedTab === defaultTabConfigs[6].id) return <RegimenComplianceGrid setData={setData} data={data} delRow={delRow} />
        if (selectedTab === opioidTabConfigs[1].id) return <OpioidGrid setData={setData} data={data} delRow={delRow} />
    }

    const fillOptions = fillsAllowedType.map(e => ({ key: e, text: e, value: e }))
    return <div id="drugUtilizationReview">
        <BreadCrumb {...props} />
        <div className="header">
            {isOpioid ? 'Opioid Configuration' : 'DUR Configuration'}
        </div>
        <div className="content maxHeight">
            <div className='splitPlane'>
                <div className='leftPlane'>
                    <FliptInput value={data?.doc_name || ''} name='doc_name' disabled={false} label={isOpioid ? 'Opioid Name' : 'DUR Name'} onChange={onChange} stylized />
                    <FliptInput value={capitalizeStr(convertSnakeCaseToString(selectedTab))} name='dur_type' disabled label={isOpioid ? 'Opioid Type' : 'DUR Type'} stylized />
                    {
                        selectedTab === defaultTabConfigs[1].id ? <>  <FliptDropdown placeholder="" label="ROA Exclusions" name="roa_exclusions" value={data?.roa_exclusions || ''} options={roaExclusionsOptions} onChange={onChange} stylized multiple />
                            <FliptInput value={data?.lookback_period || ''} name='lookback_period' disabled={false} label='Lookback Period' placeholder='Lookback Period' onChange={onChange} stylized /> </> : null
                    }
                    {isOpioid ? <FliptInput value={data.opioid_program} disabled label='Opioid Program' stylized /> : null}
                    {isOpioid ? <FliptInput value={data.non_naive_lookback || ''} name='non_naive_lookback' onChange={onChange} validation='numeric' label='Naive LB period' stylized /> : null}
                    {isOpioid ? <FliptInput value={data.non_naive_threshold || ''} name='non_naive_threshold' onChange={onChange} validation='numeric' label='Naive LB Threshold%' stylized /> : null}
                    {isOpioid ? <FliptInput onChange={onChange} name='minimum_age' value={data.minimum_age || ''} validation='numeric' label='Minimum Age' stylized /> : null}
                    {isOpioid ? <FliptDropdown options={fillOptions} onChange={onChange} name='underage_fills_allowed' value={data.underage_fills_allowed || ''} label='Underage Fills Allowed' stylized /> : null}

                    {
                        (search?.doc_version === '1.0' || !search?.doc_version) ? (<FliptDatePicker
                            className="create-formulary-start-date"
                            name="effective_date"
                            value={convertStrToDateObj(
                                data?.effective_date || ''
                            )}
                            label="Effective Date"
                            onChange={onChange}
                            stylized
                        />) : ''
                    }
                    <div className='stylized column'>
                        <div className="flipt-label">
                            <FliptLabel
                                description={'Global Object'}
                                label={'Define as Global?'}
                            />
                        </div>
                        <div className='row'>
                            <FliptRadio
                                stylized
                                label="Yes"
                                checked={data?.is_global === "Yes"}
                                value='Yes'
                                name='is_global'
                                onChange={onChange}
                            />
                            <FliptRadio
                                stylized
                                label="No"
                                disabled
                                checked={(data?.is_global || 'No') === 'No'}
                                value='No'
                                name='is_global'
                                onChange={onChange}
                            />
                        </div >
                    </div >

                </div >
                <div className='rightPlane'>
                    {data?.is_global === 'Yes' ? (<div className='error'>
                        You can not edit the hierarchy as the object is set to global
                    </div>) : ''}
                    <div className="header">
                        Hierarchy
                    </div>
                    <div className='treeSearch'>
                        <FliptInput value={searchString} name='search_query' disabled={data?.is_global === 'Yes'} label='' onChange={e => setSearchString(e.currentTarget.value || '')} placeholder='Search' stylized />
                    </div>
                    <FliptTree
                        treeData={treeData}
                        onChange={onTreeChange}
                        canDrag={false}
                        generateNodeProps={generateNodeProps}
                        searchMethod={customSearchMethod}
                        searchQuery={searchString}
                        searchFocusOffset={undefined}
                        searchFinishCallback={undefined}
                    />
                </div>
            </div >
            <div className="grid-container">
                {renderGrid()}
            </div>
            <div id="add-condition-button">
                <FliptButton className="primary addButton" compact name="Add Condition" onClick={addRow} />
                <FliptButton className="primary" compact name="Save" onClick={submitForm} />
            </div>
        </div >
    </div >
}

const mapStateToProps = (state) => ({
    state: {
        userHierarchy: state?.user?.userHierarchy ?? {},
        durSummaryData: state.drugUtilizationReview.durSummaryData,
        dur: state.drugUtilizationReview.durData,
        carriers: state?.user?.carriers && state?.user?.carriers?.length > 0 ? state.user.carriers : [],
    },
})

const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...AppActions,
        ...DrugUtilizationReviewActions,
    }

    return {
        actions: bindActionCreators(allActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(drugUtilizationReview)
