import Immutable from 'seamless-immutable'
import { createActions, createReducer } from 'reduxsauce'

export const INITIAL_STATE = Immutable({
  drugModuleData: [],
  drugInfo: [],
  drugInfoWithUm: []
})

export const clearReducer = () => INITIAL_STATE

export const clearDrugModuleDataReducer = (state = INITIAL_STATE) => ({
  ...state,
  drugModuleData: [],
})

export const clearDrugInfoBySearchReducer = (state = INITIAL_STATE) => ({
  ...state,
  drugInfo: [],
})

export const clearDrugInfoWithUmReducer = (state = INITIAL_STATE) => ({
  ...state,
  drugInfoWithUm: [],
})


export const setDrugModuleDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  drugModuleData: payload,
})

export const setDrugInfoBySearchReducer = (state = INITIAL_STATE, { payload }) =>
({
  ...state,
  drugInfo: payload,
})

export const setDrugInfoWithUmReducer = (state = INITIAL_STATE, { payload }) =>
({
  ...state,
  drugInfoWithUm: payload,
})

export const { Types, Creators } = createActions({
  clearReducer: null,
  clearDrugModuleData: null,
  getDrugModuleData: ['payload'],
  setDrugModuleData: ['payload'],
  getDrugInfoBySearch: ['payload'],
  setDrugInfoBySearch: ['payload'],
  getDrugInfoWithUm: ['payload'],
  clearDrugInfoBySearch: null,
  clearDrugInfoWithUm: null,
  setDrugInfoWithUm: ['payload'],
})

const HANDLERS = {
  [Types.CLEAR_REDUCER]: clearReducer,
  [Types.CLEAR_DRUG_MODULE_DATA]: clearDrugModuleDataReducer,
  [Types.CLEAR_DRUG_INFO_BY_SEARCH]: clearDrugInfoBySearchReducer,
  [Types.CLEAR_DRUG_INFO_WITH_UM]: clearDrugInfoWithUmReducer,
  [Types.SET_DRUG_MODULE_DATA]: setDrugModuleDataReducer,
  [Types.SET_DRUG_INFO_BY_SEARCH]: setDrugInfoBySearchReducer,
  [Types.SET_DRUG_INFO_WITH_UM]: setDrugInfoWithUmReducer,
}

export default createReducer(INITIAL_STATE, HANDLERS)
