import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Creators as AppActions } from '../../../../redux/reducers/app'
import { Creators as NetworkTierCreationActions } from '../../../../redux/reducers/api/networkCreationManagement'
import CreateNetworkComponent from './create'

const CreateNetworkEdits = (props) => {
    return <CreateNetworkComponent
        {...props}
        screen='edits'
     />
}

const mapStateToProps = (state) => ({
    state: {
      claimProcessorNetworkData: state.networkTierCreation.claimProcessorNetworkData,
      networkEditData: state.networkTierCreation.networkEditData,
      networkEditCondData: state.networkTierCreation.networkEditCondData,
      chainCode: state.networkTierCreation.chainCode,
      specialtyDrug: state.networkTierCreation.specialtyDrug,
      corpName: state.networkTierCreation.corpName
    },
  })

const mapDispatchToProps = (dispatch) => {
    const allActions = {
      ...AppActions,
      ...NetworkTierCreationActions,
    }
  
    return {
      actions: bindActionCreators(allActions, dispatch),
    }
  }
export default connect(mapStateToProps, mapDispatchToProps)(CreateNetworkEdits)