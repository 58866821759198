import React, { useState, useEffect } from 'react'
import FliptGrid from '../../../../../components/fliptGrid'
import { dosageHeader } from './data'
import { restrictionRowCellInfo } from './rowCellInfo'
import { DropdownEditor, InputTextEditor } from '../../../../../components/fliptGrid/cellEditors'
import { DeleteRowRenderer } from '../../../../../components/fliptGrid/cellRenderers'
import { ageGendereditType, messageType, rejectCode, rejectType, standardMessage } from '../data/options'


export default function ageAndGenderGrid(props) {
    const { setData, data, delRow, viewOnly } = props
    const selectedEditType = data?.conditions?.length ? data?.conditions.map((e) => e.edit_type) : []

    restrictionRowCellInfo.reject_type.options = rejectType.map(e => ({ key: e, text: e, value: e }))
    restrictionRowCellInfo.reject_code.options = rejectCode.map(e => ({ key: e, text: e, value: e }))
    restrictionRowCellInfo.message_type.options = messageType.map(e => ({ key: e, text: e, value: e }))
    restrictionRowCellInfo.edit_type.options = ageGendereditType.map(e => ({ key: e, text: e, value: e, disabled: selectedEditType.includes(e) }))

    restrictionRowCellInfo.reject_type.options = rejectType.map(e => ({ key: e, text: e, value: e }))
    restrictionRowCellInfo.reject_code.options = rejectCode.map(e => ({ key: e, text: e, value: e }))
    restrictionRowCellInfo.message_type.options = messageType.map(e => ({ key: e, text: e, value: e }))
    restrictionRowCellInfo.edit_type.options = ageGendereditType.map(e => ({ key: e, text: e, value: e, disabled: selectedEditType.includes(e) }))
    if (viewOnly) dosageHeader.shift
    const handleChange = (el, dropdown, rowIndex) => {
        const { name, value } = dropdown || el.currentTarget
        const conditions = [...(data?.conditions || [])]
        let row = { ...(conditions[rowIndex] || {}), [name]: value }
        if (name === "message_type") {
            if (value === "Standard") {
                const { reject_code } = row
                const rejectMessage = (reject_code && standardMessage[reject_code]) || ''
                row = { ...row, reject_message: rejectMessage }
                restrictionRowCellInfo.reject_message.disabled = true
            } else {
                row = { ...row, reject_message: '' }
                restrictionRowCellInfo.reject_message.disabled = false
            }
        }
        conditions[rowIndex] = row
        if (restrictionRowCellInfo[name]?.type !== 'dropdown') {
            _.debounce(() => setData({ ...data, conditions }), 900)
            return
        }
        setData({ ...data, conditions })
    }
    const cellRendererParams = {
        action: {
            cellRenderer: DeleteRowRenderer,
            state: {
                onClick: delRow,
            },
            width: 95,
        },
        reject_type: {
            overrideHeader: 'Action Taken',
        },
    }

    const cellEditorParams = {
        edit_type: {
            cellEditor: DropdownEditor,
            onChange: handleChange,
            disabled: viewOnly
        },
        message_type: {
            cellEditor: DropdownEditor,
            onChange: handleChange,
            disabled: viewOnly
        },
        reject_code: {
            cellEditor: DropdownEditor,
            onChange: handleChange,
            isDisabled: function (data) {
                return data?.reject_type === 'Message'
            },
            disabled: viewOnly
        },
        reject_type: {
            cellEditor: DropdownEditor,
            onChange: handleChange,
            disabled: viewOnly
        },
        reject_message: {
            cellEditor: InputTextEditor,
            onChange: handleChange,
            isDisabled: function (data) {
                return data?.message_type === 'Standard'
            },
            disabled: viewOnly,
            value: function(data) {
                if(data.message_type === 'Standard') {
                    return data.message_type = "DUR Reject Error"
                }
            },
        },
    }
    return (
        <div className="grid-container">
            <FliptGrid data={data?.conditions || []} headers={dosageHeader} rowCellInfo={restrictionRowCellInfo} cellEditorParams={cellEditorParams} cellRendererParams={cellRendererParams} />
        </div>
    )
}