import React from "react"
import { NavLink } from "react-router-dom";

export default function LogoutButton (props) {
	const {
		userLogout,
		auth0Client,
		classAttributes	
	} = props

	const logoutUser = () => {
		if (auth0Client?.getUser()) {
			auth0Client.logout({
				logoutParams: {
					returnTo: window.location.origin
				}
			})
		}
		userLogout()
	}

	return (
		<NavLink
              as="a"
              className="preferencesContainer_topSection__navLink logout-text"
			  role="button"
			  onClick={logoutUser}
			  tabIndex={0}
			  to={"/"}
            >
                <img src="/i/LogOut.svg" className={classAttributes}/>
        </NavLink>
	)
}
