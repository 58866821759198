import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import FliptPopup from '../../../components/fliptPopup'
import './styles.scss'

const GroupMgmtPopup = (props) => {
    const { searchArgs, data } = props
    const { path, updateGroupStatus, state } = searchArgs
    const [visible, setVisible] = useState(false)
    const show = () => setVisible(true)
    const hide = () => setVisible(false)

    const updateStatus = (status) => {
        const params = {
            doc_id: data.doc_id,
            meta_id: data.meta_id,
            active_ind: status,
            group_name: data.group_name,
            client_name: state?.client_name,
            isDashboard: true
        }
        updateGroupStatus(params)
    }

    const dropDownContent = (
        <div className="menu-container">
            <Link to={{ pathname: path, state: { meta_id: data.meta_id, editable: true } }}>
                <div
                    role="link"
                    tabIndex={0}
                    className="menu-item"
                >
                    Edit
                </div>
            </Link>
            <Link to={{ pathname: path, state: { meta_id: data.meta_id, editable: false } }}>
                <div
                    role="link"
                    tabIndex={0}
                    className="menu-item"
                >
                    View
                </div>
            </Link>
            {!data.active_ind ? <Link>
                <div
                    role="link"
                    tabIndex={-1}
                    className="menu-item"
                    onClick={() => updateStatus(true)}
                >
                    Activate
                </div>
            </Link> : <Link>
                <div
                    role="link"
                    tabIndex={-1}
                    className="menu-item"
                    onClick={() => updateStatus(false)}
                >
                    Deactivate
                </div>
            </Link>}
        </div>
    )
    const popupStyle = {
        boxShadow: 'none',
        backgroundColor: 'transparent',
        border: 0,
    }
    return (
        <FliptPopup
            basic
            eventsEnabled
            onClose={hide}
            onOpen={show}
            open={visible}
            style={popupStyle}
            trigger={<button type="button" className="btn btn-primary" onClick={visible ? hide : show}> ... </button>}
        >
            {dropDownContent}
        </FliptPopup>
    )
}

export default GroupMgmtPopup
