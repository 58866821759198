
export const batchSummaryHeader = ['paid_claim_count', 'rejected_claim_count', 'total_member_cost_share', 'total_cost']


export const authHeaders = ['auth_id', 'claim_status', 'group_id', 'cardholder_id', 'transaction_response_status', 'message', 'reject_code',
    'patient_pay_amount', 'pharmacy_name', 'ndc', 'drug_name', 'days_supply', 'quantity_dispensed', 'date_of_service']

export const STATUS_COMPLETE = 'COMPLETE'

export const BATCH_APPROVE = 'BATCH_APPROVE'
export const BATCH_REJECT = 'BATCH_REJECT'

export const configValues = {
    um: 'Formulary/Utilization Management Object',
    benefit_plan: 'Benefits',
    pharmacy_network: 'Pharmacy Networks'
}
