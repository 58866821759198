import FliptLabel from "../../../../components/form/fliptLabel";
import FliptInput from "../../../../components/form/fliptInput";
import FliptRadio from "../../../../components/form/fliptRadio";
import FliptDropdown from "../../../../components/v2/fliptDropdown";
import Info from "../Info";
import FliptDatePicker from "../../../../components/form/fliptDatePicker";
import { convertStrToDateObj } from "../../../../utils/utilities";
function renderLabel(component, props) {
  if (!component.label) return null;
  return (
    <div className="field-label">
      <span>{component.type == 'radio' ? '' : component.label}</span>
      {component.info ? (
        <Info content={component.info} position="top right" />
      ) : null}
    </div>
  );
}

export function renderDynamicInput(component, props) {
  switch (component.type) {
    case "text" :
    case "number" :
    case "email":  
      return (
        <div className={props.className} key={component.name}>
          {renderLabel(component, props)}
          <FliptInput
            label={component.label}
            name={component.name}
            className={"field-input"}
            value={props[component?.name]?.value || ''}
            onChange={props.onChange}
            placeholder={component.placeholder}
            required={component.required}
            validation={component.validation}
            maxLength={component?.maxLength}
            disabled={disabledInput(props, component)}
            stylized={false}
            error={
              (props.showErrors &&
                !props.validationResults?.[component.name] &&
                props.validations?.[component.name]?.errorMessage) ||
              null
            }
          />
        </div>
      );
    case "radio":
      return (
        <div className={props.className} key={component.name}>
          {renderLabel(component, props)}
          <div className="field-stylized radio-outline">
            <FliptRadio
              label={component.label}
              name={component.name}
              fullLabel={true}
              toggle
              className={'field-center'}
              onChange={props.onChange}
              disabled={disabledInput(props, component)}
              checked={displayBooleanValue(props[component.name].value)}
              stylized="true"
              value={props[component.name] || component.value}
            />
          </div>

        </div>
      );
    case "dropdown":
      return (
        <div className={props.className} key={component.name}>
          {renderLabel(component, props)}
          <FliptDropdown
            name={component.name}
            onChange={props.onChange}
            value={props[component.name].value}
            placeholder={component.placeholder}
            options={component.optionList}
            disabled={disabledInput(props, component)}
            className="picker"
            error={
              (props.showErrors &&
                !props.validationResults?.[component.name] &&
                props.validations?.[component.name]?.errorMessage) ||
              null
            }
          />
        </div>
      );
      case "multi-dropdown":
        return (
          <div className={props.className} key={component.name}>
            {renderLabel(component, props)}
            <FliptDropdown
              name={component.name}
              multiple 
              onChange={props.onChange}
              value={props[component.name].value}
              disabled={disabledInput(props, component)}
              placeholder={component.placeholder}
              options={component.optionList}
              className="picker"
            />
          </div>
        );
        case "date":
          return (
            <div className={props.className} key={component.name}>
              {renderLabel(component, props)}
              <FliptDatePicker
                name={component.name}
                value={convertStrToDateObj(props[component.name].value)}
                disabled={disabledInput(props, component)}
                onChange={props.onChange}
                placeholder={component.placeholder}
              />
            </div>
          );
  

    default:
      return null;
  }
}

export function findSelectedFields(input, selected) {
  const selectedFields = [];
  for (const field of input) {
    if (field.name in selected) {
      selectedFields.push(field);
    }
  }

  return selectedFields;
}

function disabledInput(props, data) {
  let flag = false;
  if(data?.disabled && !props?.addMode){
    return true
  }
  const inputObj = props[data.name] || {}
  if (!inputObj.hasOwnProperty('src')) {
    return false
  }
  switch (props.nodeType) {
    case 1:
      flag = (props[data.name].src === 'organization' || props[data.name].is_def) ? false : true
      return flag
    case 2:
      flag = (props[data.name].src === 'client' || props[data.name].is_def)? false : true
      return flag
    case 3:
      flag = (props[data.name].src === 'carrier' || props[data.name].is_def)? false : true
      return flag
    case 4:
      flag = (props[data.name].src === 'account' || props[data.name].is_def)? false : true
      return flag;
    case 5:
      flag = (props[data.name].src === 'group' || props[data.name].is_def) ? false : true
      return flag
    default:
      return flag
  }
}

function displayBooleanValue (value){
  if(typeof value == 'string' &&  (value.toLowerCase() === 'y' || value.toLowerCase()=="yes")){
     return  true 
  }else if(typeof value == 'string' && (value.toLowerCase() === 'n' || value.toLowerCase()=="no")){
    return  false 
  }else{
    return value
  }
}


 export function sortByOrder (list){
      return list.sort((a, b) => {
        if (a.order === 0 && b.order !== 0) {
          return 1; // Move item with order 0 to the bottom
        } else if (a.order !== 0 && b.order === 0) {
          return -1; // Keep item with order 0 at the bottom
        } else {
          return a.order - b.order; // Sort based on order values for other items
        }
      });
     }

export function getCagHierarchyConfig({ node, org = '', ids = [], cag = {}}) {
  const meta = { ids, cag };
  let organization = org
  if (!node) return meta;
  if (node.node_type === "organization") {
    organization = node.node_key;
    cag[organization] = [];
  }
  ids.push(node.node_id);
  cag[organization].push(node.node_id);
  if (node.children) {
    for (const child of node.children) {
      getCagHierarchyConfig({ node: child, org: organization, ids, cag });
    }
  }
  return meta;
}

// get Hierarchy for group management
export const getHierarchy = (data, value) => {
  if (
    data?.node_type === "carrier" ||
    data?.node_type === "account" ||
    data?.node_type === "group"
  ) {
    Object.assign(value, { [`${data?.node_type}_name`]: data?.node_name });
  }
  if (data?.children?.length > 0) {
    return getHierarchy(data?.children?.[0], value);
  } else return value;
};

// get the key by merging the properties
const getMergeKey = (record, properties) => properties?.reduce((acc, newValue) => acc = `${acc}${record?.[newValue] || ''}`)

// merge all the records in data that have all the values same for properties
export const mergeIdenticalRecords = (data, properties) =>  {
  const mergedData = data.reduce((acc, record) => {
    const key = getMergeKey(record, properties);
    if (!acc[key]) {
      acc[key] = { ...record };
    } else {
      delete acc[key]
      Object.assign(acc, {[key]: record});
    }
    return acc;
  }, {});

  return Object.values(mergedData);
}

// check if all the keys specified in fields are defined
export const checkKeys = (data, fields) =>  fields?.every(each => data?.[each])
