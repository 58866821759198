import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Icon } from 'semantic-ui-react'

import { Creators as AccountManagementActions } from '../../../../redux/reducers/api/accountManagement'
import AccountDetails from '../accountDetails'
import BreadCrumb from '../../../../components/breadCrumb'

import './styles.scss'

class AddNewAccount extends Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  componentDidMount() {
    this._getAccountsOnceMenuDataIsFetched()
  }

  _getAccountsOnceMenuDataIsFetched = () => {
    const interval = setInterval(() => {
      const { actions, state: { app } } = this.props
      if (app && app.menu_data && app.menu_data.length) {
        // call add new account action here
        actions.getNewAccount()
        clearInterval(interval)
      }
    }, 250)
  }

  _addUpdatedByAndSave = (formData) => {
    const { actions } = this.props

    actions.saveNewAccount(formData)
  }

  render() {
    const { state } = this.props
    const { newAccountDetails } = state

    return (
      <div id="addNewAccount">
        <BreadCrumb {...this.props} />
        <div className="header">
          New Account
          <Link to={{ pathname: '/account-design-setup' }}>
            <div className="new-account" aria-hidden="true">
              <Icon name="chevron circle left" size="tiny" />
              Back
            </div>
          </Link>
        </div>
        {newAccountDetails && <AccountDetails accountDetails={newAccountDetails} cancelPath="/account-design-setup" onSave={(formData) => this._addUpdatedByAndSave(formData)} />}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  state: {
    app: state.app,
    newAccountDetails: state.accountManagement.newAccountDetails,
    user: state.user,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AccountManagementActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddNewAccount)
