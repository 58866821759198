import React, { useState, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as _ from 'lodash'
import FliptGrid from '../../../../components/fliptGrid'
import BreadCrumb from '../../../../components/breadCrumb'
import FliptButton from '../../../../components/form/fliptButton'
import { LinkContainerRenderer, ColorCodedStatusRenderer } from '../../../../components/fliptGrid/cellRenderers'
import { Creators as DrugManagementActions } from '../../../../redux/reducers/api/drugManagement'
import Config from '../../../../config'
import './styles.scss'
import { parseQueryString } from '../../../../utils/utilities'

const ViewChangesByObject = (props) => {
  const { history } = props
  const { file_id } = parseQueryString(history.location.search)
  const headers = [
    'object_name',
    'object_type',
    'object_status',
    'linked_to_plan',
    'total_changes',
    'review_status',
    'view_changes',
  ]
  const cellRendererParams = {
    object_name: {
      width: 200,
      overrideHeader: 'Object Name',
    },
    object_type: {
      width: 200,
      overrideHeader: 'Object Type',
    },
    object_status: {
      cellRenderer: ColorCodedStatusRenderer,
      width: 200,
      overrideHeader: 'Object Status',
    },
    linked_to_plan: {
      width: 200,
      overrideHeader: 'Linked To Plans?',
    },
    total_changes: {
      width: 200,
      overrideHeader: 'Total Changes',
    },
    review_status: {
      cellRenderer: ColorCodedStatusRenderer,
      width: 200,
      overrideHeader: 'Review Status',
    },
    view_changes: {
      cellRenderer: LinkContainerRenderer,
      searchArgs: [
        {
          path: '/drug-brand',
          searchArgs: { module: 'object_type', name: 'object_id' },
          staticSearchArgs: { file_id },
          label: 'View Changes',
        },
      ],
      width: 200,
      overrideHeader: 'View Changes',
    },
  }
  const { state, actions } = props
  const { changesByObjectSummary: dat } = state
  const [data, setData] = useState(dat)
  useEffect(() => {
    if (dat && !_.isEqual(data, dat)) {
      setData(dat)
    }
  }, [dat])
  useEffect(() => {
    setTimeout(() => actions.getDmChangesByObjectData({ file_id }), Config.COMPONENT_DATA_LOAD_TIME)
  }, [])
  return (
    <div id="planDesignManagement">
      <BreadCrumb {...props} />
      <div className="header">
        Object Change Processing
      </div>
      <div className="content maxHeight">
        <FliptGrid data={data} headers={headers} cellRendererParams={cellRendererParams} />
      </div>
      <div id="close-button">
        <FliptButton className="closeButton" compact name="Close" onClick={() => history?.goBack()} />
      </div>
    </div>
  )
}
const mapStateToProps = (state) => ({
  state: {
    changesByObjectSummary: state.drugManagement.changesByObjectSummary,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...DrugManagementActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewChangesByObject)
