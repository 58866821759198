export const CompanyAttributeFields = [
  {
    header: "",
    type: "text",
    label: "Fax",
    name: "contact_fax",
    value: "",
    info: "The fax number for the main contact for the account",
    validation: "numeric",
    placeholder: "Enter",
    maxLength:10,
    "subgroup": "Contact Details",
  },
  {
    header: "",
    type: "text",
    label: "Legal Name",
    name: "companylegalname",
    value: "",
    info: "Legal Name",
    validation: "",
    placeholder: "Enter",
    "subgroup": "Contact Details",
  },
  {
    header: "",
    type: "text",
    label: "Parent Company Name",
    name: "parentcompany",
    value: "",
    info: "Parent Company Name",
    validation: "",
    placeholder: "Enter",
    "subgroup": "Contact Details",
  },
  {
    header: "",
    type: "text",
    label: "Contact Email address",
    name: "contactemail",
    value: "",
    info: "email address for the main contact for the account",
    validation: "email_address",
    placeholder: "Enter",
    "subgroup": "Contact Details",
  },
  {
    header: "",
    type: "text",
    label: "Contact Phone 2",
    name: "contact_phone2",
    validation: "numeric",
    info: "A secondary phone number for the main contact for the account",
    placeholder: "Enter",
    maxLength:10,
    "subgroup": "Contact Details",
  },
  {
    header: "",
    type: "text",
    label: "Country",
    name: "country",
    value: "",
    info: "Country",
    validation: "",
    placeholder: "Enter",
    "subgroup": "Company Attributes",
  },
];
