/* eslint-disable */
export default {
  generate_drugs: {
    type: 'button',
    disabled: false,
  },
  include: {
    type: 'checkbox',
    disabled: false,
  },
  gpi: {
    type: 'input',
    disabled: false,
  },
  medicare_type: {
    type: 'dropdown',
    disabled: false,
  },
  drug_group: {
    type: 'dropdown',
    disabled: false,
  },
  drug_class: {
    type: 'dropdown',
    disabled: false,
  },
  drug_subclass: {
    type: 'dropdown',
    disabled: false,
  },
  manufacturer: {
    type: 'search',
    disabled: false,
  },
  drug_name: {
    type: 'search',
    disabled: false,
  },
  rxcui: {
    type: 'input',
    disabled: false,
  },
  frf_category: {
    type: 'dropdown',
    disabled: false,
  },
  usp_category: {
    type: 'input',
    disabled: false,
  },
  usp_class: {
    type: 'input',
    disabled: false,
  },
  usp_pharmacotherapeutic_group: {
    type: 'input',
    disabled: false,
  },
  multi_source: {
    type: 'dropdown',
    disabled: false,
    options: [],
  },
  ndc: {
    type: 'input',
    disabled: false,
  },
  ddid: {
    type: 'input',
    disabled: false,
  },
  item_status_flag:{
    type: 'dropdown',
    disabled: false,
  },
  brand_generic: {
    type: 'dropdown',
    disabled: false,
    options: [],
  },
  otc_indicator: {
    type: 'dropdown',
    disabled: false,
    options: [],
  },
  desi_code: {
    type: 'dropdown',
    disabled: false,
  },
  route_of_administration: {
    type: 'dropdown',
    disabled: false,
  },
  maintenance_drug_code: {
    type: 'dropdown',
    disabled: false,
    options: [],
  },
  application_type_flag: {
    type: 'dropdown',
    disabled: false,
  },
  mfg_labeler_id: {
    type: 'input',
    disabled: false,
  },
  repackaged_code: {
    type: 'dropdown',
    disabled: false,
  },
  third_party_restriction_code: {
    type: 'dropdown',
    disabled: false,
  },
  dosage_form_cd: {
    type: 'dropdown',
    disabled: false,
  },
  clinic_pack_code: {
    type: 'dropdown',
    disabled: false,
  },
  innerpack_code: {
    type: 'dropdown',
    disabled: false,
  },
  unit_dose: {
    type: 'dropdown',
    disabled: false,
  },
  dea_class_code: {
    type: 'dropdown',
    disabled: false,
  },
  fda_therapeutic_equivalence_code: {
    type: 'dropdown',
    disabled: false,
  },
  marketing_category: {
    hide: true,
    overrideHeader: 'Marketing Category',
  },
  storage_condition_code: {
    type: 'dropdown',
    disabled: false,
  },
  unit_dose_with_non_unit_dose_non_repackager_in_gpi: {
    type: 'dropdown',
    disabled: false,
  },
  rx_with_otc_in_gpi: {
    type: 'dropdown',
    disabled: false,
  },
  member_notes: {
    type: 'input',
    disabled: false,
  },
  agent_notes: {
    type: 'input',
    disabled: false,
  },
  internal_notes: {
    type: 'input',
    disabled: false,
  },
  claim_message_code: {
    type: 'input',
    disabled: false,
  },
  claim_message_type: {
    type: 'input',
    disabled: false,
  },
  target_system_formulary_tier: {
    type: 'input',
    disabled: false,
  },
  target_system_formulary_status: {
    type: 'input',
    disabled: false,
  },
  effective_start_date: {
    type: 'date',
    disabled: false,
  },
  effective_end_date: {
    type: 'date',
    disabled: false,
  },
  condition_id: {
    type: 'text',
    disabled: true,
  },
}
