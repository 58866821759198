import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import './styles.scss'
import BreadCrumb from '../../../components/breadCrumb'
import FliptInput from '../../../components/form/fliptInput'
import { Accordion } from 'semantic-ui-react'
import { Creators as AppActions } from '../../../redux/reducers/app'
import { Creators as GetPricingActions } from '../../../redux/reducers/api/getPricing'
import { Creators as MemberLookupActions } from '../../../redux/reducers/api/memberLookup'
import FliptGrid from '../../../components/fliptGrid'
import { Link } from 'react-router-dom';



class PriceCheck extends Component {
    constructor(props) {
        super(props)
        const { member_info } = props.location.state

        this.state = {
            member_info,
            activeIndex: 0,
            eligibiltyData: [],
            ohiData: []
        }
    }

    componentDidMount() {
        this.setEligibilityData();
        this.setOhiData();
    }

    setEligibilityData() {
        const {
            member_info,
        } = this.state
        const eligibiltyData = []
        member_info?.eligibility.forEach((eligibilty) => {
            let obj = { ...eligibilty }
            obj.member_id =  obj?.member_id || member_info.member_id;
            obj.tpa_member_id = obj?.tpa_member_id || member_info.tpa_member_id;
            obj.domain_name = obj?.domain_name || member_info.domain_name;
            obj.flipt_person_id = member_info.flipt_person_id;
            obj.secondary = member_info?.ohi_primary ? 'Y' : 'N';
            obj.group = obj?.group || member_info.group;
            obj.effective_date = eligibilty.coverage_effective_date
            obj.termination_date = eligibilty.coverage_termination_date
            eligibiltyData.push(obj);
        });
        this.setState({ eligibiltyData });
    }
    setOhiData() {
        const {
            member_info,
        } = this.state;
        const ohiData = []
        if (member_info?.ohi_primary?.length) {
            member_info.ohi_primary.forEach((ohi) => {
                let obj = { ...ohi }
                obj.record_type = 'Primary';
                ohiData.push(obj);
            });
        }
        if (member_info.ohi_secondary?.length) {
            member_info.ohi_secondary.forEach((ohi) => {
                let obj = { ...ohi }
                obj.record_type = 'Secondary';
                ohiData.push(obj);
            });
        }
        this.setState({ ohiData });
    }

    getPanelKeyData(panelKeyData) {
        switch (panelKeyData) {
            case 'Eligibility': {
                return (<section className="service-details spacing border-line shadow"><span>Eligibility</span></section>)
            }
            case 'OHI': {
                return (<section className="service-details spacing border-line shadow"><span>OHI</span></section>)
            }
            case 'LICS': {
                return (<section className="service-details spacing border-line shadow"><span>LICS</span></section>)
            }
        }
    }

    handleClick = (e, titleProps) => {
        const { index } = titleProps
        const { activeIndex } = this.state
        const newIndex = activeIndex === index ? -1 : index

        this.setState({ activeIndex: newIndex })
    }


    render() {
        const {
            member_info,
            activeIndex,
            eligibiltyData,
            ohiData
        } = this.state
        const panelKeys = ['Eligibility', 'OHI', 'LICS'];
        const eligibiltyHeader = ['member_id', 'tpa_member_id', 'effective_date', 'termination_date', 'domain_name','group', 'secondary',
            'display_plan_name', 'benefit_plan_name', 'coverage_effective_date', 'coverage_termination_date', 'flipt_person_id']
        const licsHeader = ['lics_effective_date', 'lics_termination_date', 'lics_level']
        const ohiHeader = ['record_type', 'rxid_number', 'rxgroup_number', 'benefit_identification_number_rx', 'processor_control_number_rx',
            'rx_plan_tollfree_number', 'sequence_number', 'cob_source_code',
            'msp_reason', 'coverage_code', 'insurer_name', 'cob_effective_date', 'termination_date',
            'person_code', 'payer_order', 'supplemental_type_code', 'relationship_code']
        const eligibilityCellRendererParams = {
            termination_date: {
                overrideHeader: 'Termination Date',
            },
            effective_date: {
                overrideHeader: 'Effective Date',
            },
            coverage_effective_date: {
                overrideHeader: 'Plan Effective Date',
            },
            coverage_termination_date: {
                overrideHeader: 'Plan Termination Date',
            },
            display_plan_name: {
                overrideHeader: 'Plan ID',
            },
            benefit_plan_name: {
                overrideHeader: 'Plan Name',
            },
            flipt_person_id: {
                overrideHeader: 'Person ID'
            },
            group: {
                overrideHeader: 'Group ID'
            }
        }
        const ohiCellRendererParams = {
            benefit_identification_number_rx: {
                overrideHeader: 'Benefit Identification Number (BIN)',
            },
            processor_control_number_rx: {
                overrideHeader: 'Processor Control Number (PCN)',
            },
            cob_effective_date: {
                overrideHeader: 'Effective Date of Other Drug Coverage',
            }
        }
        const licsCellRendererParams = {
            lics_termination_date: {
                overrideHeader: 'Subsidy End Date',
            },
            lics_effective_date: {
                overrideHeader: 'Subsidy Start Date',
            },
            lics_level: {
                overrideHeader: 'Low-Income Status',
            }
        }

        return (
            <div id="eligibility">
                <div className='ui small breadcrumb'>
                    <Link to="/home" className="section">FAST</Link>
                    <div className='divider'>/</div>
                    <Link to="/client-setup-management" className="section">Client Setup and Management</Link>
                    <div className='divider'>/</div>
                    <Link to="/member-lookup" className="section">Member Lookup</Link>
                    <div className='divider'>/</div>
                    <div className='active section'>Eligibility</div>
                </div>

                {/* <BreadCrumb {...this.props} /> */}
                <div className="header">
                    <h1>Eligibility</h1>
                </div>
                <div className="content">
                    <section className="details-section spacing">
                        <div className="price-inputs">
                            <span>Flipt Person Id</span>
                            <FliptInput name="member_id" disabled label="Member Id" value={member_info?.member_id || ''} />
                        </div>
                        <div className="price-inputs">
                            <span>First Name</span>
                            <FliptInput disabled label="First Name" value={member_info?.first_name || ''} />
                        </div>
                        <div className="price-inputs">
                            <span>Last Name</span>
                            <FliptInput disabled label="Last Name" value={member_info?.last_name || ''} />
                        </div>
                        <div className="price-inputs dob">
                            <span>Date of Birth</span>
                            <FliptInput name="Date of birth" disabled value={member_info?.date_of_birth || ''} />
                        </div>
                    </section>
                    {
                        panelKeys.map((panelKeysData, idx) => {
                            return (<Accordion className='eligibility-accordion' fluid activeIndex={activeIndex} styled><Accordion.Title
                                active={activeIndex === idx}
                                index={idx}
                                onClick={this.handleClick}
                            >
                                {panelKeysData}
                            </Accordion.Title>
                                <Accordion.Content
                                    active={activeIndex === idx}
                                >
                                    {panelKeysData === 'Eligibility' && <div className="accordian-content"><FliptGrid headers={eligibiltyHeader}
                                        data={eligibiltyData} cellRendererParams={eligibilityCellRendererParams} /></div>}
                                    {panelKeysData === 'LICS' && <div className="accordian-content"><FliptGrid headers={licsHeader}
                                        data={member_info.lics || []} cellRendererParams={licsCellRendererParams} /></div>}
                                    {panelKeysData === 'OHI' && <div className="accordian-content"><FliptGrid headers={ohiHeader}
                                        data={ohiData} cellRendererParams={ohiCellRendererParams} /></div>}
                                </Accordion.Content> </Accordion>)
                        })}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    state: {
    },
})

const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...AppActions,
        ...GetPricingActions,
        ...MemberLookupActions,
    }

    return {
        actions: bindActionCreators(allActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PriceCheck)
