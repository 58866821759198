import Immutable from 'seamless-immutable'
import { createActions, createReducer } from 'reduxsauce'
import { convertDateTimeToDate, convertToCurrency } from '../../../utils/utilities'

export const INITIAL_STATE = Immutable({
  planDetails: null,
  planID: null,
  approvalDocID: null,
  planName: null,
  planLOB: null,
  planDetailsFilters: {
    carrier: null,
    account: null,
    group: null,
  },
  sections: [],
  currentSection: null,
  utilization_management: null,
  formularyCopayData: [],
  compoundsCopayData: [],
  planDesignData: [],
  customCopayData: [],
  customMessagingData: {
    custom_messaging: [],
  },
  benefitLimitation: {
    limitations: [],
  },
  grandfatheringData: {
    grandfathering: [],
  },
  listingHierarchyData: {
    um_ranking: [],
    programs: [],
    clinical_pa: [],
    clinical_ql: [],
    clinical_step_therapy: [],
    tiers: [],
  },
  compoundsData: {
    compound_coverage: '',
    allow_scc8: '',
    minimum_compounds_price: 0.00,
    maximum_compounds_price: 0.00,
    compound_pricing_options: '',
    compound_pharmacy_exclusions: '',
    copay: []
  },
  pharmacy_network: {
    networks: [],
    network_edits: [],
    pharmacy_network_rank: [],
    pharmacy_network_edit_rank: [],
    pharmacy_network_tier_rank: []
  },
  accumulator_period: [{
    benefit_effective_start_date: '',
    benefit_effective_end_date: '',
    benefit_period_type: '',
    deductible_satisfaction: '',
    individual_deductible: '',
    family_deductible: '',
    oop_satisfaction: '',
    individual_oop: '',
    family_oop: '',
    deductible_oop_logic: ''
  }],
  rewardsPenaltiesData: {
    alternate_price_type: '',
    display_coinsurance_penalty_together: '',
    display_retail_drugsub_rewards: '',
    rbp_cp_apply_rewards_no_intent: '',
    rbp_fallback_zone_pricing: '',
    rbp_penalty_oop_accumulation: '',
    rbp_penalty_predeductible_accumulation: '',
    brand_covered_upto_generic_copay_flag: '',
  },
  dawSettingsData: {},
  pricingMixMaster: {
    price_source: [],
    otc_inclusion: 'N',
    otc_price_source: [],
  },
  benefitReview: [],
  planLookupData: [],
  phasesData: {
    deductiable: [],
    initialCoverage: [],
    coverageGap: [],
    catastrophic: []
  },
  claimProcessingConfigData: {
    carry_over_accumulation_settings: {
      carry_over_accumulations_med_ded: '',
      carry_over_accumulations_med_oop: '',
      carry_over_accumulations_rx_ded: '',
      carry_over_accumulations_rx_oop: ''
    }
  },
  transitionData: [],
  benefitPlanData: [],
  formularyCopayError: false,
  customCopayError: false,
})

export const setPlanReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  planDetails: payload.data,
})

export const setUtilizationManagementReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  utilization_management: payload.data,
})

export const setPharmacyNetworkReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  pharmacy_network: payload.data,
})

export const setAccumulatorPeriodReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  accumulator_period: payload.data,
})

export const setPlanIDReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  planID: payload.data,
})

export const setApprovalDocIDReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  approvalDocID: payload.data,
})

export const setPlanNameReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  planName: payload.data,
})

export const setPlanVersionReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  planVersion: payload.data,
})

export const setPlanStatusReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  planStatus: payload.data,
})

export const setPlanLOBReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  planLOB: payload.data,
})

export const setFiltersReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  planDetailsFilters: {
    ...state.planDetailsFilters,
    [payload.data.filterName]: payload.data.filterValue,
  },
})

export const clearPlanDetailsFiltersReducer = (state = INITIAL_STATE) => ({
  ...state,
  planDetailsFilters: {
    carrier: null,
    account: null,
    group: null,
  },
})

export const setFormSectionsReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  sections: payload.data,
})

export const setCurrentSectionReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  currentSection: payload.data,
})

export const setBenefitPlanDataReducer = (state = INITIAL_STATE, { payload }) => {
  const d = payload?.data.map((row) => ({
    ...row,
    effective_end_date: convertDateTimeToDate(row.effective_end_date),
    effective_start_date: convertDateTimeToDate(row.effective_start_date),
    individual_deductible_limit: convertToCurrency(row.individual_deductible_limit),
    family_deductible_limit: convertToCurrency(row.family_deductible_limit),
    individual_out_of_pocket_limit: convertToCurrency(row.individual_out_of_pocket_limit),
    family_out_of_pocket_limit: convertToCurrency(row.family_out_of_pocket_limit),
  }))
  return {
    ...state,
    benefitPlanData: d,
  }
}

// export const clearBenefitPlanDataReducer = (state = INITIAL_STATE) => {
//   return{
//     ...state,
//     benefitPlanData: [],
//   }
// }

export const setPlanDesignLookupDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  planLookupData: payload.data,
})

export const setFormularyCopayDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  formularyCopayData: payload.data,
})

// export const setCompoundsCopayDataReducer = (state = INITIAL_STATE, { payload }) => ({
//   ...state,
//   compoundsCopayData: payload.data,
// })

export const setPlanDesignDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  planDesignData: payload.data,
})

export const clearPlanDesignDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  planDesignData: [],
})

export const setCustomCopayErrorReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  customCopayError: payload,
})

export const setFormularyCopayErrorReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  formularyCopayError: payload,
})



export const setCustomCopayDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  customCopayData: payload.data,
})

export const setListingHierarchyDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  listingHierarchyData: payload.data,
})

export const setCompoundsDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  compoundsData: payload.data,
})
export const setRewardsPenaltiesReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  rewardsPenaltiesData: payload.data,
})
export const setPhasesDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  phasesData: payload.data,
})

export const setDawSettingsDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  dawSettingsData: payload.data,
})

export const setPricingMixMasterReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  pricingMixMaster: payload.data,
})

export const setTransistionReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  transitionData: payload.data,
})

export const setCustomMessagingDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  customMessagingData: payload.data,
})

export const setBenefitLimitationReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  benefitLimitation: payload.data,
})

export const setBenefitReviewDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  benefitReview: payload.data,
})

export const clearBenefitReviewDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  benefitReview: [],
})

export const setGrandfatheringDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  grandfatheringData: payload.data,
})

export const setClaimProcessingConfigDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  claimProcessingConfigData: payload.data,
})

export const setHierarchyDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  hierarchyData: payload.data,
})

export const setPlanDesignDetailDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  planDesignDetailData: payload.data,
})

export const clearPlanTabDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  planDesignDetailData: [],
  hierarchyData: [],
})

export const clearReducer = () => INITIAL_STATE

export const { Types, Creators } = createActions({
  clearBenefitReviewData: null,
  clearReducer: null,
  clearPlanDetailsFilters: null,
  getPlan: ['payload'],
  savePlan: ['payload'],
  copyPlan: ['payload'],
  createNewPlanVersion: ['payload'],
  benefitPlanSendForReview: ['doc_data', 'plan_id'],
  setFilters: ['payload'],
  setPlan: ['payload'],
  setPlanID: ['payload'],
  setApprovalDocID: ['payload'],
  setPlanName: ['payload'],
  setPlanVersion: ['payload'],
  setPlanStatus: ['payload'],
  setPlanLOB: ['payload'],
  setFormSections: ['payload'],
  setCurrentSection: ['payload'],
  setCompoundsData: ['payload'],
  setPhasesData: ['payload'],
  getBenefitPlanData: ['payload'],
  getPlanDesignLookupData: ['payload'],
  setBenefitPlanData: ['payload'],
  setPlanDesignLookupData: ['payload'],
  setFormularyCopayData: ['payload'],
  // setCompoundsCopayData: ['payload'],
  setPlanDesignData: ['payload'],
  setCustomCopayData: ['payload'],
  setRewardsPenaltiesData: ['payload'],
  setBenefitLimitation: ['payload'],
  setListingHierarchyData: ['payload'],
  setDawSettingsData: ['payload'],
  setPricingMixMaster: ['payload'],
  setTransitionData: ['payload'],
  setBenefitReviewData: ['payload'],
  setUtilizationManagementData: ['payload'],
  setPharmacyNetworkData: ['payload'],
  setAccumulatorPeriodData: ['payload'],
  setGrandfatheringData: ['payload'],
  setCustomMessagingData: ['payload'],
  setClaimProcessingConfigData: ['payload'],
  setHierarchyData: ['payload'],
  setPlanDesignDetailData: ['payload'],
  clearPlanTabData: null,
  clearPlanDesignData: ['payload'],
  setFormularyCopayError: ['payload'],
  setCustomCopayError: ['payload'],
  // benefitPlanUpdateSubdocs: ['payload'],
})

const HANDLERS = {
  [Types.CLEAR_BENEFIT_REVIEW_DATA]: clearBenefitReviewDataReducer,
  [Types.CLEAR_REDUCER]: clearReducer,
  [Types.CLEAR_PLAN_DETAILS_FILTERS]: clearPlanDetailsFiltersReducer,
  [Types.SET_FILTERS]: setFiltersReducer,
  [Types.SET_PLAN]: setPlanReducer,
  [Types.SET_PLAN_ID]: setPlanIDReducer,
  [Types.SET_APPROVAL_DOC_ID]: setApprovalDocIDReducer,
  [Types.SET_PLAN_NAME]: setPlanNameReducer,
  [Types.SET_PLAN_VERSION]: setPlanVersionReducer,
  [Types.SET_PLAN_STATUS]: setPlanStatusReducer,
  [Types.SET_PLAN_LOB]: setPlanLOBReducer,
  [Types.SET_FORM_SECTIONS]: setFormSectionsReducer,
  [Types.SET_CURRENT_SECTION]: setCurrentSectionReducer,
  [Types.SET_BENEFIT_PLAN_DATA]: setBenefitPlanDataReducer,
  [Types.SET_FORMULARY_COPAY_DATA]: setFormularyCopayDataReducer,
  // [Types.SET_COMPOUNDS_COPAY_DATA]: setCompoundsCopayDataReducer,
  [Types.SET_PLAN_DESIGN_DATA]: setPlanDesignDataReducer,
  [Types.SET_COMPOUNDS_DATA]: setCompoundsDataReducer,
  // [Types.SET_REWARDS_PENALTIES_DATA]: setRewardsPenaltiesReducer,
  [Types.SET_PHASES_DATA]: setPhasesDataReducer,
  [Types.SET_CUSTOM_COPAY_DATA]: setCustomCopayDataReducer,
  [Types.SET_BENEFIT_LIMITATION]: setBenefitLimitationReducer,
  [Types.SET_LISTING_HIERARCHY_DATA]: setListingHierarchyDataReducer,
  [Types.SET_DAW_SETTINGS_DATA]: setDawSettingsDataReducer,
  [Types.SET_PRICING_MIX_MASTER]: setPricingMixMasterReducer,
  [Types.SET_TRANSITION_DATA]: setTransistionReducer,
  [Types.SET_BENEFIT_REVIEW_DATA]: setBenefitReviewDataReducer,
  [Types.SET_PLAN_DESIGN_LOOKUP_DATA]: setPlanDesignLookupDataReducer,
  [Types.SET_UTILIZATION_MANAGEMENT_DATA]: setUtilizationManagementReducer,
  [Types.SET_PHARMACY_NETWORK_DATA]: setPharmacyNetworkReducer,
  [Types.SET_ACCUMULATOR_PERIOD_DATA]: setAccumulatorPeriodReducer,
  [Types.SET_GRANDFATHERING_DATA]: setGrandfatheringDataReducer,
  [Types.SET_CUSTOM_MESSAGING_DATA]: setCustomMessagingDataReducer,
  [Types.SET_CLAIM_PROCESSING_CONFIG_DATA]: setClaimProcessingConfigDataReducer,
  [Types.SET_HIERARCHY_DATA]: setHierarchyDataReducer,
  [Types.SET_PLAN_DESIGN_DETAIL_DATA]: setPlanDesignDetailDataReducer,
  [Types.CLEAR_PLAN_TAB_DATA]: clearPlanTabDataReducer,
  [Types.CLEAR_PLAN_DESIGN_DATA]: clearPlanDesignDataReducer,
  [Types.SET_CUSTOM_COPAY_ERROR]: setCustomCopayErrorReducer,
  [Types.SET_FORMULARY_COPAY_ERROR]: setFormularyCopayErrorReducer,
  // [Types.CLEAR_BENEFIT_PLAN_DATA]: clearBenefitPlanDataReducer,
}

export default createReducer(INITIAL_STATE, HANDLERS)
