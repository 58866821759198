import { createStore, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'

import rootReducers from './reducers'
import rootSagas from './sagas'

const sagaMiddleware = createSagaMiddleware()

const errorHandlerMiddleware = () => (next) => (action) => {
  try {
    next(action)
  } catch (err) {
    console.error('ERROR, errorHandler: ', err)
    global.userLogout()
  }
}

export default function configureStore() {
  const middlewares = [
    sagaMiddleware,
    errorHandlerMiddleware,
  ]
  const store = createStore(
    rootReducers(),
    applyMiddleware(...middlewares),
  )

  sagaMiddleware.run(rootSagas)

  global.toggleLoader = () => {
    store.dispatch({ type: 'TOGGLE_LOADER' })
  }

  global.userLogout = () => {
    store.dispatch({ type: 'USER_LOGOUT' })
  }

  global.getStore = () => store.getState()

  return store
}
