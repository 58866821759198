const LocalStorage = window.localStorage

export default {
  setItem: (storageName, data) => {
    if (storageName && data) LocalStorage.setItem(storageName, data)
  },
  getItem: (storageName) => LocalStorage.getItem(storageName),
  removeItem: (storageName) => LocalStorage.removeItem(storageName),
  clearLocalStorage: () => LocalStorage.clear(),
}
