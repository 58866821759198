import React, { useState, useEffect } from 'react'
import { format } from 'date-fns'
import { useIdleTimer } from 'react-idle-timer'
import { IdleTimeoutModal } from '../modals/idleModal'
const INPUT_DELAY = 1 * 1000 // seconds * 1000
const DEFAULT_TIMEOUT = 30 * 1000 * 60 // minutes * 1000 * 60
const DEFAULT_TIMEOUT_WARNING = 15 * 1000 * 60 // minutes * 1000 * 60

export default function FliptIdleTimerFunction (props) {
	const { 
		userLogout, 
		timeoutMaximum = DEFAULT_TIMEOUT, 
		timeoutWarning = DEFAULT_TIMEOUT_WARNING
	} = props
	const timeout = timeoutMaximum + INPUT_DELAY
	const [remaining, setRemaining] = useState(timeout)
	const [showModal, setShowModal] = useState(false)

	const handleOnIdle = () => handleLogout()

	const {
		getRemainingTime,
		reset,
	} = useIdleTimer({
		timeout,
		onIdle: handleOnIdle,
	})
	
	const handleLogout = () => {
		userLogout()
	}

	useEffect(() => {
    setRemaining(getRemainingTime())
    setInterval(() => {
      setRemaining(getRemainingTime())
    }, 1000)
  }, [])

	useEffect(() => {
		if (remaining < (timeoutWarning + INPUT_DELAY)) {
			setShowModal(true)
		}
	}, [remaining])

	const resetRemaining = () => {
		reset()
		setShowModal(false)
	}

	return (
		<>
		<IdleTimeoutModal
			show={showModal}
			remainingTime={remaining - INPUT_DELAY}
			resetRemaining={resetRemaining}
		/>
	</>
  )
}