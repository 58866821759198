import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { createQueryString } from '../../../../../utils/utilities'
import FliptPopup from '../../../../../components/fliptPopup'
import { CONFIG_ACTIONS, CONFIG_STATUS } from '../../constants'

const PopupCellRenderer = (props) => {
    const { data, searchArgs } = props
    const { onClick } = searchArgs
    const { file_status } = data
    const [visible, setVisible] = useState(false)
    const show = () => setVisible(true)
    const hide = () => setVisible(false)
    const params = { plan_id: 'plan_id', approval_doc_id: 'approval_doc_id', plan_name: 'plan_name', lob: 'lob', plan_version: 'version', status: 'status' }
    Object.keys(params).forEach((key) => params[key] = (params[key] in data) ? data[params[key]] : '')
    const editQs = createQueryString(params)
    const dropDownContent = (
        <div className="menu-container">
            {file_status == CONFIG_STATUS.READY && <>
                <div
                    role="button"
                    tabIndex={0}
                    onClick={() => hide() || onClick && onClick(CONFIG_ACTIONS.DELETE, data)}
                    className={`menu-item`}
                >
                    Delete
                </div>
                <div
                    role="button"
                    tabIndex={0}
                    onClick={() => hide() || onClick && onClick(CONFIG_ACTIONS.PUBLISH, data)}
                    className={`menu-item`}
                >
                    Publish
                </div>
                <div
                    role="button"
                    tabIndex={0}
                    onClick={() => hide() || onClick && onClick(CONFIG_ACTIONS.EDIT, data)}
                    className={`menu-item`}
                >
                    Edit
                </div>
                <div
                    role="button"
                    tabIndex={0}
                    onClick={() => hide() || onClick && onClick(CONFIG_ACTIONS.COPY, data)}
                    className={`menu-item`}
                >
                    Copy
                </div>
            </>
            }
            {file_status == 'Published' && <>
                <div
                    role="button"
                    tabIndex={0}
                    onClick={() => hide() || onClick && onClick(CONFIG_ACTIONS.COPY, data)}
                    className={`menu-item`}
                >
                    Copy
                </div>
                <div
                    role="button"
                    tabIndex={0}
                    onClick={() => hide() || onClick && onClick(CONFIG_ACTIONS.CANCEL, data)}
                    className={`menu-item`}
                >
                    Cancel
                </div>
            </>
            }
            {file_status == CONFIG_STATUS.DRAFT && <>
                <div
                    role="button"
                    tabIndex={0}
                    onClick={() => hide() || onClick && onClick(CONFIG_ACTIONS.DELETE, data)}
                    className={`menu-item`}
                >
                    Delete
                </div>
                <div
                    role="button"
                    tabIndex={0}
                    onClick={() => hide() || onClick && onClick(CONFIG_ACTIONS.SUBMIT, data)}
                    className={`menu-item`}
                >
                    Submit
                </div>
                <div
                    role="button"
                    tabIndex={0}
                    onClick={() => hide() || onClick && onClick(CONFIG_ACTIONS.EDIT, data)}
                    className={`menu-item`}
                >
                    Edit
                </div>
                <div
                    role="button"
                    tabIndex={0}
                    onClick={() => hide() || onClick && onClick(CONFIG_ACTIONS.COPY, data)}
                    className={`menu-item`}
                >
                    Copy
                </div>
            </>
            }
            {file_status == CONFIG_STATUS.CANCEL && <>
                <div
                    role="button"
                    tabIndex={0}
                    onClick={() => hide() || onClick && onClick(CONFIG_ACTIONS.COPY, data)}
                    className={`menu-item`}
                >
                    Copy
                </div>
                <div
                    role="button"
                    tabIndex={0}
                    onClick={() => hide() || onClick && onClick(CONFIG_ACTIONS.REPUBLISH, data)}
                    className={`menu-item`}
                >
                    Republish
                </div>
            </>
            }
        </div>
    )
    const popupStyle = {
        boxShadow: 'none !important',
        backgroundColor: 'transparent',
        border: 0,
    }
    return (
        <FliptPopup
            basic
            eventsEnabled
            id='drug-management-popup'
            onClose={hide}
            onOpen={show}
            open={visible}
            style={popupStyle}
            trigger={<button style={{ backgroundColor: 'transparent', color: 'red', fontSize: '1.5em' }} type="button" className="btn btn-primary" onClick={visible ? hide : show}> ... </button>}
        >
            {dropDownContent}
        </FliptPopup>
    )
}

export default PopupCellRenderer
