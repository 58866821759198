import React from 'react'

import FliptTable from '../../../../components/fliptTable'

import { buildDataForAccountsTable } from '../../../../utils/utilities'

import './styles.scss'

export default (props) => {
  const { accounts, rowClick } = props
  const headers = ['carrier', 'account', 'group', 'company_name', 'PEPM_rate', 'PMPM_rate', 'effective_date']
  const data = buildDataForAccountsTable(accounts)

  return (
    <div id="accountTable">
      <FliptTable headers={headers} data={data} rowClick={rowClick} />
    </div>
  )
}
