import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { createQueryString } from '../../../../utils/utilities'
import FliptPopup from '../../../../components/fliptPopup'
import './styles.scss'

const PopupCellRenderer = (props) => {
  const { data, searchArgs } = props
  const { onClick } = searchArgs
  const { status } = data

  // determine latest plan version, so that we can disable 
  // 'create new version' for older versions
  let latestVersion = 0
  let isLatestVersion = false
  const plan_name = data.plan_name
  const plan_version = Number(data.version) || 0
  props.api.forEachNode((node) => {
    if (node.data.plan_name === plan_name) {
      const node_plan_version = Number(node.data.version) || 0
      if (node_plan_version >= plan_version && node_plan_version >= latestVersion) {
        latestVersion = node_plan_version
      }
    }
  })
  if (latestVersion === plan_version) {
    isLatestVersion = true
  }

  const [visible, setVisible] = useState(false)
  const show = () => setVisible(true)
  const hide = () => setVisible(false)
  const params = { plan_id: 'plan_id', approval_doc_id: 'approval_doc_id', plan_name: 'plan_name', lob: 'lob', plan_version: 'version', status: 'status' }
  Object.keys(params).forEach((key) => params[key] = (params[key] in data) ? data[params[key]] : '')
  const editQs = createQueryString(params)
  const dropDownContent = (
    <div className="menu-container">
      <div
        role="button"
        tabIndex={0}
        onClick={() => hide() || onClick && onClick('copy_object', data)}
        className={`menu-item`}
      >
        Copy
      </div>
      {(status === 'Draft') ? (<Link to={{ pathname: '/create-benefit-plan', search: editQs, state: { editMode: true } }}>
        <div
          role="link"
          tabIndex={-1}
          disabled={status !== 'Draft'}
          className="menu-item"
        >
          Edit
        </div>
      </Link>) : (
        <div
          role="link"
          tabIndex={-1}
          disabled={status !== 'Draft'}
          className="menu-item"
        >
          Edit
        </div>
      )}
      <div
        role="button"
        tabIndex={-2}
        disabled={status !== 'Draft'}
        onClick={() => hide() || onClick && onClick('send_for_review', data)}
        className={`menu-item`}
      >
        Send For Review
      </div>
      <div
        role="button"
        tabIndex={-3}
        disabled={status !== 'Published' || (status === 'Published' && !isLatestVersion)}
        onClick={() => hide() || onClick && onClick('create_new_version', data)}
        className={`menu-item`}
      >
        Create New Version
      </div>
    </div>
  )
  const popupStyle = {
    boxShadow: 'none !important',
    backgroundColor: 'transparent',
    border: 0,
  }
  return (
    <FliptPopup
      basic
      eventsEnabled
      id='drug-management-popup'
      onClose={hide}
      onOpen={show}
      open={visible}
      style={popupStyle}
      trigger={<button style={{ backgroundColor: 'transparent', color: 'red', fontSize: '1.5em' }} type="button" className="btn btn-primary" onClick={visible ? hide : show}> ... </button>}
    >
      {dropDownContent}
    </FliptPopup>
  )
}

export default PopupCellRenderer
