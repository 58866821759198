import React, { useState } from 'react'
import FliptButton from '../form/fliptButton'
import './styles.scss'
import FliptCheckbox from '../form/fliptCheckbox'

const alertPopup = (props) => {
    const { alertType, batch_id, approvedClaims,
        rejectedClaims, closeModal, saveProgress, changeBatchStatus } = props
    const [selected, setSelected] = useState(false)
    if (alertType === 'CREATED') return (
        <div id='batch-alert-popup'>
            <img src='/i/featured_icon_green.png' width={40} height={40}></img>
            <h3>Batch Submitted</h3>
            <span>Claims are being Pulled for <span>Batch: {batch_id}</span></span>
            <span>We will notify you once all claims have been pulled. </span>
            <FliptButton name='Next' className='button-class' onClick={() => closeModal()} />
        </div>
    )
    if (alertType === 'BATCH_APPROVE') return (
        <div id='batch-alert-popup' >
            <img src='/i/featured_icon_yellow.png' width={40} height={40}></img>
            <h3>You are about to Approve the batch</h3>
            <span>This action is not revokable.</span>
            <div className='row-container'>
                <FliptCheckbox
                    checked={selected}
                    onChange={() => setSelected(!selected)} />
                <span >Yes! I agree to Approve the Batch</span>
            </div>
            <FliptButton name='Approve Batch' className='button-class' onClick={() => { selected ? changeBatchStatus('APPROVED') : null }} />
        </div>
    )
    if (alertType === 'BATCH_REJECT') return (
        <div id='batch-alert-popup' >
            <img src='/i/featured_icon_yellow.png' width={40} height={40}></img>
            <h3>You are about to Reject the batch</h3>
            <span>This action is not revokable.</span>
            <div className='row-container'>
                <FliptCheckbox
                    checked={selected}
                    onChange={() => setSelected(!selected)} />
                <span>Yes! I agree to Reject the Batch</span>
            </div>
            <FliptButton name='Reject Batch' className='primary red-button' onClick={() => { selected ? changeBatchStatus('REJECTED') : null }} />
        </div>
    )
    if (alertType === 'CLAIM_APPROVE') return (
        <div id='batch-alert-popup'>
            <img src='/i/featured_icon_yellow.png' width={40} height={40}></img>
            <h3>Are you sure! You are about to Approve all {rejectedClaims} claims</h3>
            <span>This action is not revokable.</span>
            <div className='row-container'>
                <FliptCheckbox
                    checked={selected}
                    onChange={() => setSelected(!selected)} />
                <span >Yes! I agree to Approve Claims</span>
            </div>
            <FliptButton name='Approve All' className='button-class' onClick={() => { selected ? saveProgress('APPROVED') : null }} />
        </div>
    )
    if (alertType === 'CLAIM_REJECT') return (
        <div id='batch-alert-popup'>
            <img src='/i/featured_icon_yellow.png' width={40} height={40}></img>
            <h3>Are you sure! You are about to Reject all {rejectedClaims} claims</h3>
            <span>This action is not revokable.</span>
            <div className='row-container'>
                <FliptCheckbox
                    checked={selected}
                    onChange={() => setSelected(!selected)} />
                <span >Yes! I agree to Reject Claims</span>
            </div>
            <FliptButton name='Reject All' className='primary red-button' onClick={() => { selected ? saveProgress('REJECTED') : null }} />
        </div>
    )
    if (alertType == 'FINALIZE') return (
        <div id='batch-alert-popup'>
            <img src='/i/featured_icon_yellow.png' width={40} height={40}></img>
            <h3>Please Review before you finalize</h3>
            <span>Below are the results of your batch review.</span>
            <p>Approve ({approvedClaims})</p>
            <p>Reject ({rejectedClaims})</p>
            <span>Are you sure you want to complete batch processing?</span>

            <div className='row-container'>
                <FliptCheckbox
                    checked={selected}
                    onChange={() => setSelected(!selected)} />
                <span>Yes! I agree to Finalize the Batch</span>
            </div>
            <FliptButton name='Finalize' className='button-class' onClick={() => { selected ? changeBatchStatus('APPROVED') : null }} />
        </div>
    )
}

export default alertPopup
