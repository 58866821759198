import React, { PureComponent } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Creators as AppActions } from '../../../../redux/reducers/app'
import { Creators as MemberLookupActions } from '../../../../redux/reducers/api/memberLookup'

import './styles.scss'
import BreadCrumb from '../../../../components/breadCrumb'
import FliptTable from '../../../../components/fliptTable'
import FliptInput from '../../../../components/form/fliptInput'
import FliptPaInput from '../../../../components/form/fliptPaInput'


class PlanDetails extends PureComponent {
  render() {
    const { state: { memberLookup: { plan_details, benefit_details } } } = this.props
    const headers = ['plan_name', 'plan_year', 'plan_start_date', 'plan_end_date', 'annual_deductible_individual', 'annual_deductible_family', 'annual_out_of_pocket_individual', 'annual_out_of_pocket_family', 'individual_deductible_embedded', 'individual_out_of_pocket_embedded', 'coverage_tier_name', 'otc_inclusion_flag', 'brand_covered_upto_generic_copay_flag', 'plan_coverage_per_drug']
    const isMedicare = benefit_details?.lob ? benefit_details?.lob.toLowerCase().includes('medicare') : false
    const {
      plan_name,
      display_plan_name,
      medicare_plan_type,
      medicare_pbp,
      special_program_type,
      phases,
    } = plan_details?.current_plan || {}
    const standard = phases?.standard ?? []
    return (
      <div id="PlanDetails">
        <BreadCrumb {...this.props} />
        <div className="header">Plan Details</div>
        {isMedicare ? <div className='plan-main-container'>
          <div className="fields-container">
            <div className="fields">
              <FliptInput value={plan_name} label="Plan Name" disabled stylized />
              <FliptInput value={medicare_plan_type || benefit_details?.medicare_plan_type} label="Medicare Plan Type" disabled stylized />
              <FliptInput value={benefit_details?.contract_id} label="Contract ID" disabled stylized />
            </div>
            <div className="fields">
              <FliptInput value={display_plan_name} label="Display Plan Name" disabled stylized />
              <FliptInput value={special_program_type || benefit_details?.special_program_type} label="Medicare Program" disabled stylized />
              <FliptInput value={benefit_details?.formulary} label="Formulary Name" disabled stylized />
            </div>
            <div className="fields">
              <FliptInput value={benefit_details.lob} label="LOB" disabled stylized />
            </div>
          </div>
          <div className='devider' />
          <div className="header">Medicare Phase Information</div>
          {standard?.length ? standard.map((phaseData) => {
            return (
              <div>
                <div className='phase-header'>{phaseData?.phase}</div>
                <div className='phase-box'>
                  <FliptPaInput label="Begin Amount" value={phaseData?.begin_amt} disabled stylized />
                  <FliptPaInput label="Begin Spend Type" value={phaseData?.begin_spend_type} disabled stylized />
                  <FliptPaInput label="End Amount" value={phaseData?.end_amt} disabled stylized />
                  <FliptPaInput label="End Spend Type" value={phaseData?.end_spend_type} disabled stylized />
                </div>
              </div>
            )
          }) : null}
        </div> :
          <div className="content">
            <FliptTable data={plan_details.historical_plans} headers={headers} />
          </div>}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  state: {
    app: state.app,
    memberLookup: state.memberLookup,
    user: state.user,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
    ...MemberLookupActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlanDetails)
