export const buttonOptions = [
    {
        key: 0,
        text: 'Production Claim Reprocessing',
        value: 'Production Claim Reprocessing',
        onClick: {}
    },
    {
        key: 1,
        text: 'Client Batch Claim Testing',
        value: 'Client Batch Claim Testing',
        onClick: {}
    }
]

export const TEST_BATCH_TAB_ID = 'Test Claim Batches'
export const PROD_BATCH_TAB_ID = 'Production Reprocessing Batches'

export const tabConfig = [
    {
        tabName: PROD_BATCH_TAB_ID,
        id: PROD_BATCH_TAB_ID,
    },
    {
        tabName: TEST_BATCH_TAB_ID,
        id: TEST_BATCH_TAB_ID,
    },
]

export const INT_MGMT_TABS_LOCAL_STORAGE_KEY = "intMgmtTabs";
