import React from 'react'
import './styles.scss'
import FliptButton from '../../../../components/form/fliptButton'

function batchModal(props) {
    return (
        <div id="batchModal">
            <span className='modal-text'>
                {`Reprocessing Batch ${props?.batchDetailData?.batch_id} has been successfully created. Claims that match criteria defined are being pulled for the batch`}
            </span>
            <FliptButton name='Ok' className='primary' onClick={() => props.goToDashboard()} />
        </div>
    )
}

export default batchModal