const dateInfo = {
    effective_begin_date: {
        type: 'date',
        disabled: false,
    },
    effective_end_date: {
        type: 'date',
        disabled: false,
    },
}
export const programRowCellInfo = {
    program_name: {
        type: 'dropdown',
        disabled: false,
        options: [],
    },
    ...dateInfo

}
export const qlRowCellInfo = {
    ql_name: {
        type: 'dropdown',
        disabled: false,
        options: [],
    },
    ...dateInfo

}
export const stRowCellInfo = {
    step_therapy_name: {
        type: 'dropdown',
        disabled: false,
        options: [],
    },
    ...dateInfo

}
export const paRowCellInfo = {
    pa_name: {
        type: 'dropdown',
        disabled: false,
        options: [],
    },
    ...dateInfo

}