import React from 'react'

import FliptInput from '../../../../../components/form/fliptInput'
import { convertStrUi } from '../../../../../utils/utilities'

export default ({ data }) => {
  const {
    cardholder_id,
    person_code,
    patient_gender_code,
    patient_relationship_code,
    first_name,
    last_name,
    benefit_plan_name,
    date_of_birth,
    group_id,
  } = data

  return (
    <div className="section">
      <h2>Patient</h2>
      <div className="fields-container">
        <div className="fields">
          <FliptInput value={cardholder_id || ''} label="Flipt Member Id" stylized />
          <FliptInput value={patient_relationship_code || ''} label="Relationship" stylized />
          <FliptInput clearable={false} value={convertStrUi(date_of_birth)} name="Date of birth" label="Date Of Birth" stylized />
        </div>
        <div className="fields">
          <FliptInput value={person_code || ''} label="Person Code" stylized />
          <FliptInput value={first_name || ''} label="First Name" stylized />
          <FliptInput value={last_name || ''} label="Last Name" stylized />
        </div>
        <div className="fields">
          <FliptInput value={patient_gender_code || ''} label="Gender" stylized />
          <FliptInput value={group_id || ''} label="Group Id" stylized />
          <FliptInput value={benefit_plan_name || ''} label="Benefit Plan Name" stylized />
        </div>
      </div>
    </div>
  )
}
