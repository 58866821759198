import React from 'react'
import { customComponent } from './mapper'
import './styles.scss'

export default function FliptCustom({ custom_type, ...others }) {
  const ComponentCustom = customComponent[custom_type]
  return (
    <div id="fliptCustom">
      <ComponentCustom
        {
        ...others
        }
      />
    </div>
  )
}