import React, { useState, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as _ from 'lodash'
import FliptGrid from '../../../../components/fliptGrid'
import BreadCrumb from '../../../../components/breadCrumb'
import FliptButton from '../../../../components/form/fliptButton'
import FliptDropdown from '../../../../components/form/fliptDropdown'
import { LinkContainerRenderer, ColorCodedStatusRenderer } from '../../../../components/fliptGrid/cellRenderers'
import { Creators as DrugManagementActions } from '../../../../redux/reducers/api/drugManagement'
import { Creators as AppActions } from '../../../../redux/reducers/app'
import ApproveWithNotes from './popup/approveWithNotes'
import './styles.scss'
import {
  parseQueryString,
} from '../../../../utils/utilities'
import Config from '../../../../config'

const changeTypeDetails = (props) => {
  const { history, state, actions } = props
  const { urlHistory } = state?.app || {}
  const { pathname, search } = window.location
  let srch = search || ''
  if (urlHistory[pathname] !== search && search)
    actions.setUrlHistory({ [pathname]: search })
  if (!srch && !_.isEmpty(urlHistory[pathname])) {
    srch = urlHistory[pathname]
    //window.location.search =srch
  }

  const { changeTypeDetails: dat, drugApprovalFreeze, reloadPages } = state
  const [data, setData] = useState(dat)
  const { file_id, change_type, change_name } = parseQueryString(srch)
  const [selections, setSelections] = useState([])
  const [bulkActions, setBulkActions] = useState(null)
  const bulkSelectOptions = ['approve without notes', 'approve with notes'].map(e => ({ key: e, text: e, value: e, disabled: !!drugApprovalFreeze }))

  const headers = [
    'select',
    'drug_name',
    'clinical_priority',
    'ndc',
    'gpi',
    'old_value',
    'new_value',
    'object_impacted',
    'status',
    'view_changes',
  ]
  const impactedObjectHeaders = [
    'object_name',
    'object_type',
    'object_status',
    'linked_to_plan',
    'view_changes',
  ]
  const cellRendererParams = {
    select: {
      overrideHeader: '',
      width: 5,
      headerCheckboxSelection: true,
      checkboxSelection: (row) => (row.data.status === 'Need Review'),
      showDisabledCheckboxes: true,
      headerCheckboxSelectionFilteredOnly: true,
    },
    drug_name: {
      width: 200,
      overrideHeader: 'Drug Name',
    },
    clinical_priority: {
      cellRenderer: ColorCodedStatusRenderer,
      width: 200,
      overrideHeader: 'Priority',
    },
    ndc: {
      width: 200,
      overrideHeader: 'NDC',
    },
    gpi: {
      width: 200,
      overrideHeader: 'GPI',
    },
    old_value: {
      width: 200,
      overrideHeader: 'Old Value',
    },
    new_value: {
      width: 200,
      overrideHeader: 'New Value',
    },
    object_impacted: {
      width: 200,
      overrideHeader: 'Object Impacted',
    },
    status: {
      cellRenderer: ColorCodedStatusRenderer,
      width: 200,
      overrideHeader: 'Review Status',
    },
    view_changes: {
      cellRenderer: LinkContainerRenderer,
      searchArgs: [
        {
          path: '/drug-information',
          searchArgs: { ndc: 'ndc' },
          staticSearchArgs: { file_id },
          label: 'View Changes',
        },
      ],
      width: 200,
      overrideHeader: 'View Changes',
    },
  }

  const impactedObjectRendererParams = {
    select: {
      overrideHeader: '',
      width: 5,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
    },
    object_name: {
      width: 200,
      overrideHeader: 'Object Name',
    },
    object_type: {
      width: 200,
      overrideHeader: 'Object Type',
    },
    object_status: {
      cellRenderer: ColorCodedStatusRenderer,
      width: 200,
      overrideHeader: 'Object Status',
    },
    linked_to_plan: {
      width: 200,
      overrideHeader: 'Linked to Plans?',
    },
    view_changes: {
      cellRenderer: LinkContainerRenderer,
      searchArgs: [
        {
          path: '/drug-brand',
          searchArgs: { module: 'object_type', name: 'object_id' },
          staticSearchArgs: { file_id },
          label: 'View Changes',
        },
      ],
      width: 200,
      overrideHeader: 'View Changes',
    },
  }

  useEffect(() => {
    if (dat && !_.isEqual(data, dat)) {
      setData(dat)
    }
  }, [dat])
  useEffect(() => {
    setTimeout(() => actions.getDmChangeType({ file_id, change_type }), Config.COMPONENT_DATA_LOAD_TIME)
  }, [reloadPages])

  const onSelectionChanged = (data) => {
    const selectedRows = data.api.getSelectedRows()
    const filterdRow = selectedRows.filter(x=> x.status === 'Need Review')
    setSelections(filterdRow)
  }
  const onSave = ({ ndc, ...others }) => {
    actions.dmApproveChanges({ file_id, ndc, ...others })
    actions.toggleModal()
  }

  const handleBulkActions = () => {
    const ndc = selections.map(x => ({
      change_type: x.change_type,
      drugName: x.drug_name,
      newValue: x.new_value,
      oldValue: x.old_value,
      ndc: x.ndc,
    }))
    if (bulkActions === 'approve with notes') {
      actions.setModalComponent({
        modalProperties: {
          size: 'medium',
        },
        contents: <ApproveWithNotes multipleChanges={true} ndc={ndc} onSave={onSave} />,
      })
      return actions.toggleModal()
    }
    setSelections([])
    return actions.dmApproveChanges({ file_id, notes: '', ndc })
  }


  return (
    <div id="planDesignManagement">
      <BreadCrumb {...props} />
      <div className="header">
        {change_name}
      </div>
      <div className="content">
        <FliptGrid rowSelection={'multiple'} onSelectionChanged={onSelectionChanged} data={data?.drugs || []} headers={headers} cellRendererParams={cellRendererParams} />
        <div className='header-actions'>
          {(!_.isEmpty(selections)) && <div className='header-bulkactions'>
            <FliptDropdown placeholder="Bulk Actions" label="" name="bulk_actions" value={bulkActions} options={bulkSelectOptions} onChange={(e, d) => setBulkActions(d.value)} stylized />
            <FliptButton disabled={(bulkActions === null || selections.length === 0)} className="closeButton" compact name="Approve" onClick={handleBulkActions} />
          </div>}
        </div>
      </div>
      <div className="header">
        Impacted Objects
      </div>
      <div className="content">
        <FliptGrid data={data?.objects || []} headers={impactedObjectHeaders} cellRendererParams={impactedObjectRendererParams} />
      </div>
      <div id="close-button">
        <FliptButton className="closeButton" compact name="Close" onClick={() => history?.goBack()} />
      </div>
    </div>
  )
}
const mapStateToProps = (state) => ({
  state: {
    changeTypeDetails: state.drugManagement.changeTypeDetails,
    drugApprovalFreeze: state.drugManagement.drugApprovalFreeze,
    reloadPages: state.drugManagement.reloadPages,
    app: state.app,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
    ...DrugManagementActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(changeTypeDetails)
