import { bindActionCreators } from "redux"
import React, { Component, useEffect, useState } from 'react'
import { Creators as AppActions } from '../../../../redux/reducers/app'
import { Creators as AuditManagementActions } from '../../../../redux/reducers/api/auditManagement'
import FliptDropdown from "../../../../components/v2/fliptDropdown"
import { connect } from "react-redux"
import FliptInput from "../../../../components/v2/fliptInput"
import FliptGrid from "../../../../components/fliptGrid"
import './styles.scss'
import BreadCrumb from "../../../../components/breadCrumb"
import FliptButton from "../../../../components/v2/fliptButton"
import { convertSnakeCaseToString } from "../../../../utils/utilities"

function AuditManagement(props) {
    const { actions, state: { documentNames, fieldNames, queryData } } = props
    const [documentNamesOptions, setDocumentNamesOptions] = useState([])
    const [fieldNamesOptions, setFieldNamesOptions] = useState([])
    const [parsedGridData, setParsedGridData] = useState([])

    const [form, setForm] = useState({ index: '', field: '', keyword: '' })

    useEffect(() => {
        actions.clearQueryData()
        actions.getDocument()
    }, [])

    const handleChange = (el, dropdown) => {
        const { name, value } = dropdown || el.currentTarget
        if (name === 'index') actions.getFields({ 'index': value })
        const newForm = {
            ...form,
            [name]: value,
        }
        setForm(newForm)
    }

    useEffect(() => {
        const documentNamesType = documentNames.map((data, i) => ({ key: i, text: data, value: data }));
        setDocumentNamesOptions(documentNamesType)
    }, [documentNames])

    useEffect(() => {
        const fieldNamesType = fieldNames.map((data, i) => ({ key: i, text: data, value: data }));
        setFieldNamesOptions(fieldNamesType)
    }, [fieldNames])

    useEffect(() => {
        const parsedData = []
        queryData.forEach((element, i) => {
            if (i === queryData.length - 1) return
            delete element.meta
            delete element.updateDate
            delete element.update_time
            const flatObj1 = getFlatObject(element)
            const flatObj2 = getFlatObject(queryData[i + 1])
            const objectKeys = Object.keys(flatObj1)
            objectKeys.forEach((objKey) => {
                if (flatObj2[objKey] && flatObj1[objKey] !== flatObj2[objKey]) {
                    parsedData.push({
                        difference: `Difference between Document ${i + 1} and Document ${i + 2}`,
                        field_name: convertSnakeCaseToString(objKey),
                        orignal_value: flatObj1[objKey],
                        updated_value: flatObj2[objKey],
                        update_time: flatObj2.update_time ? flatObj2.update_time?.split('::')[2] : ''
                    })
                }
            })
        });
        setParsedGridData(parsedData)
    }, [queryData])

    const getFlatObject = (input) => {
        let result = {};
        for (const [key, value] of Object.entries(input)) {
            if (value && typeof value === "object" && !Array.isArray(value)) {
                var subFlatObject = getFlatObject(value);
                for (const subkey in subFlatObject) {
                    result[subkey] = subFlatObject[subkey];
                }
            } else {
                result[key] = value;
            }
        }
        return result;
    }

    const searchData = () => {
        if (!form.field || !form.index || !form.keyword) {
            _showTransitionalPortal('Field Error', 'Please Add Index, Field and Keyword to Search Data')
            return
        }
        actions.getQueryData({
            ...form
        })
    }

    const _showTransitionalPortal = (header, message) => {
        const transitionalPortal = {
            header: header,
            copy: message,
        }
        actions.displayTransitionalPortal(transitionalPortal)
    }

    const gridHeaders = ['difference', 'field_name', 'orignal_value', 'updated_value', 'update_time']
    const cellRendererParams = {
        difference: {
            width: '350px'
        },
        update_time: {
            width: '300px'
        }
    }
    return (
        <div id="audit-mgmt">
            <BreadCrumb {...props} />
            <div className="field-container">
                <FliptDropdown options={documentNamesOptions} label='Index' name='index' value={form.index} onChange={handleChange} />
                <FliptDropdown options={fieldNamesOptions} label='Field' name='field' value={form.field} onChange={handleChange} />
                <FliptInput label='Keyword' name='keyword' value={form.keyword} onChange={handleChange} />
            </div>
            <FliptButton className='primary' name='Search' onClick={searchData} />
            <div className="grid-container">
                <FliptGrid
                    headers={gridHeaders}
                    data={parsedGridData}
                    cellRendererParams={cellRendererParams} />
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return ({
        state: {
            app: state.app,
            documentNames: state.auditManagement.documentNames,
            fieldNames: state.auditManagement.fieldNames,
            queryData: state.auditManagement.queryData,
        },
    })
}

const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...AppActions,
        ...AuditManagementActions
    }

    return {
        actions: bindActionCreators(allActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AuditManagement)