import React, { useState } from 'react'

import './styles.scss'
import FliptPopup from '../fliptPopup'
import { Link } from 'react-router-dom'
import FliptDropdown from '../form/fliptDropdown'
import FliptButton from '../form/fliptButton'
import * as ApprovalsConstants from '../../redux/sagas/rpm/constants'

export function FliptActionPopupCustom(props) {
  const {
    actionItems, displayValue, data, fontSize,
  } = props
  const [visible, setVisible] = useState(false)
  const show = () => setVisible(true)
  const hide = () => setVisible(false)

  const getDataValues = (str, aux_function) => {
    let inside = false
    let in_aux = false
    let currDataName = ''
    let ret = ''
    for (let i = 0; i < str.length; i++) {
      if (inside) {
        if (in_aux && str[i] === '}') {
          inside = false
        }
        else if (str[i] === '}') {
          inside = false
          if (data[currDataName]) ret = ret.concat(data[currDataName])
          currDataName = ''
        } else {
          currDataName = currDataName.concat(str[i])
        }
      } else if (in_aux) {
        if (str[i] === '}') {
          in_aux = false
          ret = ret.concat(aux_function(data[currDataName]) ?? data[currDataName])
          currDataName = ''
        }
        if (str[i] === '$' && str[i + 1] === '{') {
          inside = true
          i = i + 1
        }
      } else if (str[i] === '$' && str[i + 1] === '{') {
        inside = true
        i = i + 1
      } else if (str[i] === '$' && str[i + 1] === 'f' && str[i + 2] === '{') {
        in_aux = true
        i = i + 2
      }
      else {
        ret = ret.concat(str[i])
      }
    }
    return ret
  }

  const dropDownContent = (
    <div className="menu-container" style={{ display: "block", justifyContent: "center", alignItems: "center" }}>
      {actionItems.map((item, index) => {
        switch (item?.type) {
          case 'Text':
            return (
              <p onClick={item?.onClick} style={item?.style} key={index}>
                {getDataValues(item?.title, item?.aux_function)}
              </p>
            )
          case 'Button':
            return (
              <div key={index}>
                <FliptButton
                  className={item?.className}
                  name={item?.name}
                  onClick={item?.onClick}
                  style={item?.style}
                />
              </div>
            )
          case 'Dropdown':
            return (
              <FliptDropdown
                placeholder={item?.placeholder}
                label={item?.label}
                name={item?.name}
                value={item?.value}
                options={item?.options}
                onChange={item?.onChange}
                stylized={item?.stylized}
                multiple={item?.multiple}
                context={data}
                style={item?.style}
                key={index}
              />
            )
          case 'Link':
            return (
              <Link
                to={{
                  pathname: item?.pathname,
                  search: item?.search,
                  state: item?.state,
                }}
                onClick={item?.onClick}
                key={index}
              >
                <div
                  role="link"
                  tabIndex={0}
                  className="menu-item"
                >
                  {item?.title}
                </div>
              </Link>
            )
        }
      })}
    </div>
  )
  const popupStyle = {
    boxShadow: 'none !important',
    backgroundColor: 'transparent',
    border: 0,
  }
  return (
    <FliptPopup
      basic
      eventsEnabled
      id='action-popup'
      onClose={hide}
      onOpen={show}
      open={visible}
      style={popupStyle}
      trigger={<button style={{ backgroundColor: 'transparent', color: 'red', fontSize: fontSize || '1.5em' }} type="button" className="btn btn-primary" onClick={visible ? hide : show}> {displayValue || '...'}</button>}
    >
      {dropDownContent}
    </FliptPopup>)
}


export default function FliptActionPopup(props) {
  const {
    actionMenuStyle,
    approvalParamsMapping,
    data,
    menuClassName,
    pathDrugs,
    pathHierarchy,
    moduleViewDrugs,
    pathname,
    qsArgs,
    showActionsMenu,
    onClick
  } = props
  const [visible, setVisible] = useState(false)
  const show = () => setVisible(true)
  const hide = () => setVisible(false)
  const createApprovalData = () => {
    const approveData = {}
    Object.keys(approvalParamsMapping).forEach((param) => {
      approveData[approvalParamsMapping[param]] = data[param]
    })
    return approveData
  }

  function createQS(searchArgs, row) {
    const querystring = Object.keys(searchArgs).reduce((accum, key) => {
      const colName = searchArgs[key]
      const val = row[colName] && typeof row[colName] !== 'string' ? row[colName].toString() : row[colName]
      accum += row[colName] ? `${key}=${val.trim()}&` : ''
      return accum
    }, '?').slice(0, -1)
    return querystring
  }

  const _viewUrl = (module) => {
    const viewURlData = {
      FORMULARY: '/formulary',
      FORMULARY_EXPORT_CONFIGURATION: '/formulary-export-configuration-builder',
      FORMULARY_TIER: '/formulary-tier',
      PRIOR_AUTHORIZATION: '/prior-authorization',
      PROGRAM_LIST: '/program-list',
      QUANTITY_LIMIT: '/quantity-limit',
      STEP_THERAPY: '/step-therapy',
    }
    return viewURlData[module] || ''
  }

  const qs = createQS(qsArgs, data)
  const approvalData = approvalParamsMapping ? createApprovalData() : null

  let hasAction = false;
  if (menuClassName === 'ellipsisActionMenu' && qsArgs.hasOwnProperty('action')) {
    if (qsArgs['action'] === 'COPY_OBJECT') {
      hasAction = true;
    }
  }
  const { DRAFT, PUBLISHED, REJECTED } = ApprovalsConstants
  const isEditAllowed = [DRAFT, PUBLISHED, REJECTED].includes(data.status)
  const editLinkClass = isEditAllowed ? '' : 'disabled-link'
  const canSendForReview = data.status === DRAFT
  const reviewLinkClass = canSendForReview ? '' : 'disabled-link'
  const _onClick = (item) => {
    qsArgs['selected_action'] = item;
    data['qsArgs'] = qsArgs;
    onClick(data);
  }

  const dropDownContent = (
    <div className="menu-container">
      {!!hasAction && (
        <Link to={{
          pathname: _viewUrl(qsArgs['module']),
          search: qs,
          state: {
            editMode: false,
            doc_id: data.id,
            status: data.status
          },
        }}
          onClick={() => _onClick('view')}
        >
          <div
            role="link"
            tabIndex={0}
            className="menu-item"
          >
            View
          </div>
        </Link>
      )}
      {!!hasAction && (
        <Link to={{
          pathname: _viewUrl(qsArgs['module']),
          search: qs,
          state: {
            editMode: true,
            doc_id: data.id,
            status: data.status,
          },
        }}
          onClick={() => _onClick('edit')}
          className={editLinkClass}
        >
          <div
            role="link"
            tabIndex={0}
            className="menu-item"
          >
            Edit
          </div>
        </Link>
      )}
      {!!approvalData && qsArgs['module'] !== 'FORMULARY_EXPORT_CONFIGURATION' && (
        <div
          role="button"
          tabIndex={0}
          className="menu-item"
        >
          Check Linked Plans
        </div>
      )}
      {!!pathHierarchy && (
        <Link to={{
          pathname: pathHierarchy,
          search: qs,
        }}>
          <div
            role="link"
            tabIndex={0}
            className="menu-item"
          >
            Hierarchy
          </div>
        </Link>
      )}
      {!!hasAction && qsArgs['module'] !== 'EDIT' && (
        <Link to={{
          pathname,
          search: qs + "&action=send_for_review",
          state: {
            data
          },

        }}
          onClick={() => _onClick('send_for_review')}
          className={reviewLinkClass}
        >
          <div
            role="link"
            tabIndex={0}
            className="menu-item"
          >
            Send for Review
          </div>
        </Link>
      )
      }
      {
        !!hasAction && (qsArgs['module'] !== 'FORMULARY' && qsArgs['module'] !== 'FORMULARY_EXPORT_CONFIGURATION') &&
        <Link to={{
          pathname,
          // state: impactAnalysisArgs,
        }}
          onClick={() => _onClick('impact_analysis')}
        >
          <div
            role="link"
            tabIndex={0}
            className="menu-item"
          >
            Impact Analysis
          </div>
        </Link>
      }
      {/* need to removed when copy_object is implemented in formulary module  */}
      {!!hasAction && (qsArgs['module'] !== 'FORMULARY' && qsArgs['module'] !== 'FORMULARY_EXPORT_CONFIGURATION') &&
        (
          <Link to={{
            pathname,
            search: qs + "&action=copy_object",
            state: {}
          }}
            onClick={() => _onClick('copy_object')}
          >
            <div
              role="link"
              tabIndex={0}
              className="menu-item"
            >
              Copy Object
            </div>
          </Link>
        )
      }
      {!hasAction && (
        <Link to={{
          pathname,
          search: qs,
          state: {
            editMode: true,
            ...dataState,
          },
        }}
        >
          <div
            role="link"
            tabIndex={0}
            className="menu-item"
          >
            Edit
          </div>
        </Link>
      )}

      {!hasAction && data.status.toUpperCase() === 'PUBLISHED'
        && (
          <Link to={{
            pathname: pathDrugs,
            state: {
              data,
              moduleViewDrugs,
              state: {
                editMode: false,
                ...dataState,
              },
            },
          }}
          >
            <div
              role="link"
              tabIndex={0}
              className="menu-item"
            >
              View Drugs
            </div>
          </Link>
        )}
    </div>
  )
  const popupStyle = {
    boxShadow: 'none !important',
    backgroundColor: 'transparent',
    border: 0,
  }
  return (
    <FliptPopup
      basic
      eventsEnabled
      id='action-popup'
      onClose={hide}
      onOpen={show}
      open={visible}
      style={popupStyle}
      trigger={<button style={{ backgroundColor: 'transparent', color: 'red', fontSize: '1.5em' }} type="button" className="btn btn-primary" onClick={visible ? hide : show}> ... </button>}
    >
      {dropDownContent}
    </FliptPopup>)
}
