import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import './styles.scss'
import BreadCrumb from '../../../../../components/breadCrumb'
import FliptButton from '../../../../../components/form/fliptButton'
import FliptInput from '../../../../../components/form/fliptPaInput'
import FliptGrid from '../../../../../components/fliptGrid'
import { Creators as AppActions } from '../../../../../redux/reducers/app'
import { Creators as NetworkCreationActions } from '../../../../../redux/reducers/api/networkCreationManagement'
import { createQueryString, parseQueryString } from '../../../../../utils/utilities'
import ServerSideDatasource from '../../../../../components/fliptGrid/serverSideDataSource'
import { FeeGridRowTextRenderer, DiscountRowTextRenderer } from '../../../../../components/fliptGrid/cellRenderers'

function WarningModal(props) {
    const {
        toggleModal, message, onContinueClick, onCancelClick
    } = props

    const handleClick = () => {
        onContinueClick()
        toggleModal()
    }
    const handleCancel = () => {
        onCancelClick()
        toggleModal()
    }
    return (
        <div className="warningModal">
            <div className="header-text">
                <h3>{message}</h3>
            </div>
            <div className="buttons-container">
                <FliptButton className="primary" name="Continue" onClick={handleClick} />
                <FliptButton className="primary" name="Cancel" onClick={handleCancel} />
            </div>
        </div>
    )

}
class PharmacyNetworkTierLookup extends Component {
    constructor(props) {
        super(props)

        this.state = {
            form: {
                contract: '',
                pharmacyname: '',
                pharmacynpi: '',
                doc_id: '',
                doc_version: '',
                network_tier:'',
                // limit: 100,
                offset: 0,
            },
            // pharmacyTypeValues: ['Retail', 'All'],
            // networkStatusValues: ['Active', 'All'],
            agGridRef: React.createRef(),
        }
    }

    componentDidMount() {
        const { actions, history } = this.props
        const { location } = history

        if (!location.search) return
        this._qsToFormData()
        const { form, agGridRef } = this.state

        // history.location.search = createQueryString(form)
        const headers = [
            'network_tier', 'network_tier_type', 'claim_processor', 'pricing_status', 'pharmacynpi', 'pharmacyname', 'discount_type' , 'rate_type', 'discount',
            'start_days_supply',
            'end_days_supply', 'price_type', 'maclistid', 'program_drug_list',
            'fees', 'pharmacy_start_date', 'pharmacy_end_date',
            'pricing_start_date', 'pricing_end_date',
            'claim_reject_date',
                'ein',
                'ncpdp',
                'provider_reinstatement_date',
                'sanction_start_date',
                'sanction_term_date',
                'state_license'
        ]

        
        // const datasource = new ServerSideDatasource(form, 'pharmacy-network-tier-lookup', actions.getPharmacyNetworkTierLookup, headers)
        // agGridRef.current.api.setServerSideDatasource(datasource);
        agGridRef.current.api.refreshServerSideStore({ route: null, purge: true }) // to refresh the grid server side data
    }

    // componentDidUpdate(prevProps) {
    //     const { props, state } = this
    //     const { actions } = props
    //     const { agGridRef } = state
    //     agGridRef.current.api.refreshServerSideStore({ route: null, purge: true })
    // }

    _pharmacySearch() {
        const { state, props } = this
        const { form } = state
        const { history } = props
        // props.actions.clearPharmacyLookupReducer();
        history.location.search = createQueryString(form)
        props.actions.getPharmacyNetworkTierLookup({ form, getTotalRows: true })
    }

    _clearFormFilter = () => {
        const { state, props } = this
        const { agGridRef } = state
        // props.actions.clearPharmacyLookupReducer();
        const form = {
            contract: '',
            pharmacyname: '',
            pharmacynpi: '',
            doc_id: '',
            doc_version: '',
            limit: 100,
            offset: 0,
        }
        this.setState({ form })
        const headers = [
            'network_tier', 'network_tier_type', 'claim_processor', 'pricing_status', 'pharmacynpi', 'pharmacyname',  'discount_type' , 'rate_type','discount',
            'start_days_supply',
            'end_days_supply', 'price_type', 'maclistid', 'program_drug_list',
            'fees', 'pharmacy_start_date', 'pharmacy_end_date',
            'pricing_start_date', 'pricing_end_date',
            'claim_reject_date',
                'ein',
                'ncpdp',
                'provider_reinstatement_date',
                'sanction_start_date',
                'sanction_term_date',
                'state_license'
        ]
        const datasource = new ServerSideDatasource(form, 'pharmacy-network-tier-lookup', props.actions.getPharmacyNetworkTierLookup, headers)
        agGridRef.current.api.setServerSideDatasource(datasource);
    }

    _updateFields = (el, dropdown) => {
        const { form } = this.state
        const { name, value } = dropdown || el.currentTarget

        form[name] = value.toUpperCase()
        this.setState({ form })
    }

    _qsToFormData = () => {
        const { state, props } = this
        const qs = parseQueryString(props.history.location.search)
        const { form } = state
        const keys = Object.keys(qs)
        if (keys && !keys?.length) return

        keys.forEach((key) => {
            if (key) form[key] = qs[key]
        })
        this.setState({ form })
    }

    handleIdClicked = (pharmacyData) => {
        const { data } = pharmacyData
        this.props.history.push('/contract-discount-lookup', { data })
    }

    cellRendererParams = {
        network_tier: {
            autoWidth: true,
        },
        network_tier_type: {
            overrideHeader: 'Type',
            autoWidth: true,
            sortable: false,
            filter: false,
            resizable: true
        },
        claim_processor: {
            overrideHeader: 'Contract',
            autoWidth: true,
            resizable: true
        },
        pharmacynpi: {
            overrideHeader: 'NPI',
            autoWidth: true,
            resizable: true
        },
        maclistid: {
            overrideHeader: 'MAC List Id',
            autoWidth: true,
            sortable: false,
            filter: false ,
            resizable: true
        },
        pharmacyname: {
            overrideHeader: 'Pharmacy',
            autoWidth: true,
            resizable: true
        },
        program_drug_list: {
            overrideHeader: 'Drug List',
            autoWidth: true,
            sortable: false,
            filter: false,
            resizable: true
        },
        start_days_supply: {
            overrideHeader: 'DS Start',
            autoWidth: true,
            sortable: false,
            filter: false,
            resizable: true
        },
        pricing_status: {
            overrideHeader: 'Price Status',
            autoWidth: true,
            sortable: false,
            filter: false,
            resizable: true
        },
        end_days_supply: {
            overrideHeader: 'DS End',
            autoWidth: true,
            sortable: false,
            filter: false,
            resizable: true
        },
        discount_type: {
            hide:true
        },
        rate_type: {
            hide:true
        },
        discount: {
            overrideHeader: 'Discount',
            cellRenderer: DiscountRowTextRenderer,
            searchArgs: { discount_type: 'discount_type', rate_type: "rate_type"  },
            autoWidth: true,
            sortable: false,
            filter: false,
            resizable: true,
            
        },
        
        fees: {
            cellRenderer: FeeGridRowTextRenderer,
            autoHeight: true,
            sortable: false,
            filter: false ,
            resizable: true
        }
    }

    render() {
        const { state, props } = this
        const { actions } = props
        const { form, agGridRef } = state
        const { pharmacynpi, contract, pharmacyname } = form
        const {
            state: {
                pharmaciesPerPage,
            },
        } = props
        const headers = [
            'network_tier', 'network_tier_type', 'claim_processor', 'pricing_status', 'pharmacynpi', 'pharmacyname',
            'discount_type', 'rate_type', 'discount', 'start_days_supply',
            'end_days_supply', 'price_type', 'maclistid', 'program_drug_list',
            'fees', 'pharmacy_start_date', 'pharmacy_end_date',
            'pricing_start_date', 'pricing_end_date', 'claim_reject_date',
            'ein',
            'ncpdp',
            'provider_reinstatement_date',
            'sanction_start_date',
            'sanction_term_date',
            'state_license'
        ]
        let docName = form.network_tier
        const serverSideGridConfig = {
            cacheBlockSize: pharmaciesPerPage,
            pagination: true,
            paginationPageSize: pharmaciesPerPage,
            rowModel: 'serverSide',
            serverSideStoreType: 'partial',
        }
        const serverSideGridParams = {
            apiId: 'pharmacy-network-tier-lookup',
            form,
            headers,
            sagaToCall: actions.getPharmacyNetworkTierLookup,
        }
        const gridOptions = {
            domLayout: "autoHeight",
            autoSizeStrategy: {
              type: "fitCellContents",
              skipHeader: true
            },
            
          };

        return (
            <div id="pharmacyLookup">
                <BreadCrumb {...this.props} />
                <div className="header">{docName}</div>
                <div style={{ display: "none" }}>
                    <div className="horizontal-fields">
                        <FliptInput stylized label='Contract' placeholder="Contract Name" name="contract" value={contract} onChange={this._updateFields} />
                        <FliptInput stylized label='Pharmacy NPI' placeholder="NPI" name="pharmacynpi" value={pharmacynpi} onChange={this._updateFields} />
                        <FliptInput stylized label="Pharmacy Name" placeholder="Pharmacy Name" name="pharmacyname" value={pharmacyname} onChange={this._updateFields} />
                    </div>

                    <div className="button-container">
                        <FliptButton name="Search" className="primary searchButton" onClick={() => this._pharmacySearch(false)} />
                        <FliptButton name="Clear" className="secondary clearButton" onClick={() => this._clearFormFilter()} />
                    </div>
                </div>

                <div className="agGrid content">
                    <FliptGrid
                        agGridRef={agGridRef}
                        gridOptions={gridOptions}
                        enableAdvancedFilter={true}
                        cellRendererParams={this.cellRendererParams}
                        filterOptions={['contains', 'equals']}
                        headers={headers}
                        serverSideGridConfig={serverSideGridConfig}
                        serverSideGridParams={serverSideGridParams}
                        doAutoSize={true}
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    state: {
        app: state.app,
        // loading: state.networkTierCreation.loading,
        // pharmacyNetworkLookup: state.networkTierCreation.pharmacyNetworkLookup,
        // pharmaciesPerPage: state.networkTierCreation.pharmaciesPerPage,
        // totalRows: state.networkTierCreation.pharmacyTotalRows,
    },
})

const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...AppActions,
        ...NetworkCreationActions,
    }

    return {
        actions: bindActionCreators(allActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PharmacyNetworkTierLookup)
