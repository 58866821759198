import React, { Component } from 'react'
import { Modal, ModalContent, ModalHeader } from 'semantic-ui-react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import './styles.scss'
import BreadCrumb from '../../../components/breadCrumb'
import FliptButton from '../../../components/form/fliptButton'
import FliptGrid from '../../../components/fliptGrid'
import FliptInput from '../../../components/form/fliptInput'
import { Creators as AppActions } from '../../../redux/reducers/app'
import { Creators as PrescriberLookupActions } from '../../../redux/reducers/api/prescriberLookup'
import { filterRowData } from '../../../utils/utilities'

class PrescriberLookup extends Component {
  constructor(props) {
    super(props)
    this.state = {
      form: {
        prescriber_npi: '',
        prescriber_first_name: '',
        prescriber_last_name: '',
      },
    }
  }

  _updateFields = (el, dropdown) => {
    const { form } = this.state
    const { name, value } = dropdown || el.currentTarget
    const npiErr = false

    form[name] = value.toUpperCase()

    this.setState({ form, npiErr })
  }

  _fetchprescriberLookup = () => {
    const { state, props } = this
    const { form } = state
    if (!form.prescriber_npi && (!form.prescriber_first_name || !form.prescriber_last_name)) {
      const transitionalPortal = {
        header: 'Fields are missing',
        copy: 'Please add First Name and Last Name to search Prescriber',
      }
      props.actions.displayTransitionalPortal(transitionalPortal)
      return;
    }
    props.actions.getPrescriberLookupData(form)
  }

  _clearData = () => {
    const form = {
      prescriber_npi: '',
      prescriber_first_name: '',
      prescriber_last_name: '',
    };
    this.props.actions.clearPrescriberLookupReducer()
    this.setState({ form })
  }

  togglePrescriber = () => {
    const { noPrescriber } = this.state
    this.setState({ noPrescriber: !noPrescriber })
  }

  _prescriberHeader = () => (
    <ModalHeader>
      <p className="textCenter">No Prescriber Found</p>
    </ModalHeader>
  )

  _prescriberContent = () => (
    <ModalContent>
      <p className="textCenter contentModal">We are unable to find any result to your search. Please check and confirm the details you have entered and search again.</p>
    </ModalContent>
  )

  // _qsToFormData = () => {
  //   const { state, props } = this
  //   const qs = parseQueryString(props.history.location.search)
  //   const { form } = state

  //   Object.keys(qs).forEach((key) => {
  //     form[key] = qs[key].toUpperCase()
  //   })
  //   this.setState({ form })
  // }

  render() {
    const { props } = this
    const {
      form, npiErr, noPrescriber,
    } = this.state
    const { state: { prescriberLookupData } } = props
    const headers = [
      'first_name', 'last_name', 'address', 'city', 'zip_code',
      'state', 'fax_number', 'phone_number', 'npi',
    ]
    const headerMapping = {
      npi: 'npi',
      provider_first_name: 'first_name',
      provider_last_name_legal_name: 'last_name',
      provider_first_line_business_practice_location_address: 'address',
      provider_business_practice_location_address_city_name: 'city',
      provider_business_practice_location_address_state_name: 'state',
      provider_business_practice_location_address_postal_code: 'zip_code',
      provider_business_practice_location_address_fax_number: 'fax_number',
      provider_business_practice_location_address_telephone_number: 'phone_number',
    }
    const gridData = prescriberLookupData && prescriberLookupData.length ? prescriberLookupData?.map((d) => ({
      ...filterRowData(d, headers, headerMapping),
    })) : []

    return (
      <div id="PrescriberLookup" className="prescriber-lookup">
        <BreadCrumb {...this.props} />
        <div className="header">
          <h1> Prescriber Lookup </h1>
        </div>
        <div className="subhead">
          <section className="prescriber-section">
            <div className="prescriber-input">
              <span>NPI</span>
              <FliptInput placeholder="NPI" value={form.prescriber_npi} name="prescriber_npi" onChange={this._updateFields} />
            </div>
            <div className="prescriber-input">
              <span>Prescriber First Name</span>
              <FliptInput placeholder="First name" value={form.prescriber_first_name} onChange={this._updateFields} name="prescriber_first_name" />
            </div>
            <div className="prescriber-input">
              <span>Prescriber Last Name</span>
              <FliptInput placeholder="Last name" value={form.prescriber_last_name} onChange={this._updateFields} name="prescriber_last_name" />
            </div>
            <div className="prescriber-input prescriber-btn">
              <FliptButton name="Search" className="primary" onClick={this._fetchprescriberLookup} />
              <FliptButton name="Clear" className="primary clearButton" onClick={this._clearData} />
            </div>
          </section>
          <section className="prescriber-section">
            <div className="prescriber-input">
              {npiErr && <small className="errMessage">Invalid NPI Value. NPI is a uniqiue 10-digit numeric code and looks like 1861533879</small>}
            </div>
          </section>
        </div>
        <div className="content">
          {
            !noPrescriber && (
              <FliptGrid
                headers={headers}
                data={gridData}
              />
            )
          }
          <Modal
            closeIcon
            content={this._prescriberContent}
            header={this._prescriberHeader}
            onClose={this.togglePrescriber}
            open={noPrescriber}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  state: {
    prescriberLookupData: state.prescriberLookup.prescriberLookupData,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
    ...PrescriberLookupActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PrescriberLookup)
