const stockData = [
  {
  key: 1,
  text: 'Employer',
  value: 'Employer',
},
{
  key: 2,
  text: 'Employee',
  value:'Employee',
}
]
export const systemFieldAttribute = [
  {
    "header": "",
    "type": "text",
    "label": "Accumulate Deductible Flag",
    "name": "accumulate_flipt_deductible",
    "value": "",
    "info": "Accumulate the deductible expense",
    "validation": "",
    "placeholder": "Enter",
    "subgroup": "",
    "order": 0
  },
  {
    "header": "",
    "type": "text",
    "label": "Brand Drug Covered Upto Generic Copay Flag",
    "name": "brand_covered_upto_generic_copay_flag",
    "value": "",
    "info": "When set to Y, when member selects (or gets adjudicated) a Brand drug when the generic drug is available, the brand drug coverage will be limited to the copay of the generic drug if the member had selected generic drug ",
    "validation": "",
    "placeholder": "Enter",
    "subgroup": "",
    "order": 0
  },
  {
    "header": "",
    "type": "radio",
    "label": "Claim Processor: Enable Drug Interaction",
    "name": "enable_drug_interaction_cp",
    "value": "",
    "info": "Turn on Durg interation for Claims Processor",
    "validation": "",
    "placeholder": "Enter",
    "subgroup": "",
    "order": 0
  },
  {
    "header": "",
    "type": "radio",
    "label": "Claim Processor: Enable Emergency Pharmacy Override",
    "name": "enable_emergency_pharmacy_overrides",
    "value": "",
    "info": "Turn on Emergency pharmacy override for Claims Processor",
    "validation": "",
    "placeholder": "Enter",
    "subgroup": "",
    "order": 0
  },
  {
    "header": "",
    "type": "number",
    "label": "Claims Above This Days of Supply Will Be Rejected",
    "name": "cp_reject_retail_claim_above_dos",
    "value": "",
    "info": "",
    "validation": "numeric",
    "placeholder": "Enter",
    "subgroup": "",
    "order": 0
  },
  {
    "header": "",
    "type": "number",
    "label": "Claims Upto Days of Service Allowed",
    "name": "cp_claim_days_of_service_allowed",
    "value": "",
    "info": "how far back we allow claim to process",
    "validation": "numeric",
    "placeholder": "Enter",
    "subgroup": "",
    "order": 0
  },
  {
    "header": "",
    "type": "text",
    "label": "Compound Drugs Discount %",
    "name": "compound_discount_pct",
    "value": "",
    "info": "Discount % applicable for the compound drug adjudication by Script Claim",
    "validation": "",
    "placeholder": "Enter",
    "subgroup": "",
    "order": 0
  },
  {
    "header": "",
    "type": "radio",
    "label": "Concierge Override Flag",
    "name": "override_flag",
    "value": "",
    "info": "Concierge ability to override the PA",
    "validation": "",
    "placeholder": "Enter",
    "subgroup": "",
    "order": 0
  },
  {
    "header": "",
    "type": "text",
    "label": "Custom Message to Pharmacy when Eligibility is rejectedText",
    "name": "eligibility_custom_reject_message",
    "value": "",
    "info": "",
    "validation": "",
    "placeholder": "Enter",
    "subgroup": "",
    "order": 0
  },
  {
    "header": "",
    "type": "number",
    "label": "Default Brand Discount %",
    "name": "brand_discount",
    "value": "",
    "info": "How much discount to apply if brand name drug is not selected. This is when displaying the PBM Price",
    "validation": "numeric",
    "placeholder": "Enter",
    "subgroup": "",
    "order": 0
  },
  {
    "header": "",
    "type": "number",
    "label": "Default Generic Discount %",
    "name": "generic_discount",
    "value": "",
    "info": "discount for choosing generic when brand name drug is available",
    "validation": "numeric",
    "placeholder": "Enter",
    "subgroup": "",
    "order": 0
  },
  {
    "header": "",
    "type": "text",
    "label": "Elibility Email List",
    "name": "eligibility_mail_list",
    "value": "",
    "info": "email list if needed for eligibility processing",
    "validation": "",
    "placeholder": "Enter",
    "subgroup": "",
    "order": 0
  },
  {
    "header": "",
    "type": "text",
    "label": "Eligibility Change Threshold %",
    "name": "eligibility_change_threshold",
    "value": "",
    "info": "Percentage of eligibility changed records in a load",
    "validation": "",
    "placeholder": "Enter",
    "subgroup": "",
    "order": 0
  },
  {
    "header": "",
    "type": "text",
    "label": "Enable Pharmacy Transder based on Member Request via Text",
    "name": "enable_pharmacy_transfer_autoerx",
    "value": "",
    "info": "Enable the pharmacy transfer process when no intent exist, but cheaper pharmacy exists and member responds Y to the text message",
    "validation": "",
    "placeholder": "Enter",
    "subgroup": "",
    "order": 0
  },
  {
    "header": "",
    "type": "text",
    "label": "OTC Drug Covered?",
    "name": "otc_drug_covered",
    "value": "",
    "info": "over the counter drug is covered by plan for all the OTC Drugs. If this is checked yes, then all OTC drugs are covered.",
    "validation": "",
    "placeholder": "Enter",
    "subgroup": "",
    "order": 0
  },
  {
    "header": "",
    "type": "text",
    "label": "Default Private Account",
    "name": "default_private_account",
    "value": "",
    "info": "During Eligibility load, all the member account should be loaded as Private or not.",
    "validation": "",
    "placeholder": "Enter",
    "subgroup": "Misc Settings",
    "order": 0
  },
  {
    "header": "",
    "type": "text",
    "label": "Reject Message for Mail Order Pharmacy",
    "name": "humana_reject_message",
    "value": "",
    "info": "This has been configured for NMD drugs rejection for Novartis domain. Leave blank for other domains",
    "validation": "",
    "placeholder": "Enter",
    "subgroup": "",
    "order": 0
  },
  {
    "header": "",
    "type": "text",
    "label": "Tax Rate Threshold %",
    "name": "percentage_tax_rate_threshold",
    "value": "",
    "info": "Max tax rate applicable at adjudication, if pharmacy submits a higher rate, the adjudication will apply the tax at this rate. ",
    "validation": "",
    "placeholder": "Enter",
    "subgroup": "",
    "order": 0
  },
  {
    "header": "",
    "type": "dropdown",
    "label": "Who Should Pay Stocking Fee",
    "name": "stocking_fee_paidby",
    "value": "",
    "info": "Who will pay the stocking fee? Employer or the employee? ",
    "validation": "",
    "placeholder": "Enter",
    "subgroup": "",
    "order": 0,
    optionList: stockData || []
  }
]


