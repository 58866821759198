import React, { Component, useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment-timezone'

import { Creators as AppActions } from "../../../redux/reducers/app"
import { Creators as RPMActions } from "../../../redux/reducers/rpm"

import FliptGrid from '../../../components/fliptGrid';
import FliptDatePicker from '../../../components/v2/fliptDatePicker'
import FliptButton from '../../../components/v2/fliptButton'
import './styles.scss'

const FORMULARY_TIER = 'FORMULARY_TIER'
const PRIOR_AUTHORIZATION = 'PRIOR_AUTHORIZATION'
const QUANTITY_LIMIT = 'QUANTITY_LIMIT'
const PROGRAM_LIST = 'PROGRAM_LIST'
const STEP_THERAPY = 'STEP_THERAPY'
// to the future dev, change constants for networks when
// networks get integrated into approvals
const PHARMACY_NETWORK_TIER = 'global_network_tier'
const PHARMACY_NETWORK_EDIT = 'global_network_edit'

const apiKeyMapping = {
  // module = formulary and pharmacy network does not implement impact analysis
  [FORMULARY_TIER]: 'tiers',
  [PRIOR_AUTHORIZATION]: 'clinical_pa',
  [QUANTITY_LIMIT]: 'clinical_ql',
  [PROGRAM_LIST]: 'programs',
  [STEP_THERAPY]: 'clinical_step_therapy',
  [PHARMACY_NETWORK_TIER]: 'pharmacy_network_tiers',
  [PHARMACY_NETWORK_EDIT]: 'pharmacy_network_edits',
}

const objectTypeMapping = {
  // module = formulary and pharmacy network does not implement impact analysis
  [FORMULARY_TIER]: 'Drug List',
  [PRIOR_AUTHORIZATION]: 'Prior Authorization',
  [QUANTITY_LIMIT]: 'Quantity Limit',
  [PROGRAM_LIST]: 'Program List',
  [STEP_THERAPY]: 'Step Therapy',
  [PHARMACY_NETWORK_TIER]: 'Pharmacy Network Tier',
  [PHARMACY_NETWORK_EDIT]: 'Pharmacy Network Edit',
}

const defaultHeaders = ['plan_name', 'plan_version', 'historical_claims']

const cellRendererParams = {
  plan_name: {
    width: '400px'
  }
}

const ImpactAnalysis = (props) => {
  const { actions, data, state } = props
  const initialStartDate = moment().subtract(6, 'months').toDate()
  const initialEndDate = moment().toDate()
  const [startDate, setStartDate] = useState(initialStartDate)
  const [endDate, setEndDate] = useState(initialEndDate)

  const onChange = (el, dropdown) => {
    const { name, value } = dropdown || el.currentTarget
    if (name === 'start_date') {
      setStartDate(value)
    } else if (name === 'end_date') {
      setEndDate(value)
    }
  }

  const { module_name, module } = data[0]

  const getImpactAnalysis = () => {
    let temp_module = module
    let type = 'um'
    if ([PHARMACY_NETWORK_TIER, PHARMACY_NETWORK_EDIT].includes(module)) {
      type = 'pharmacy_network'
      temp_module = module === PHARMACY_NETWORK_TIER ? PHARMACY_NETWORK_TIER : PHARMACY_NETWORK_EDIT
    }

    actions.rpmGetImpactAnalysis({
      module: temp_module,
      form: {
        [apiKeyMapping[temp_module]]: [module_name],
      },
      doc_name: module_name,
      startDate,
      endDate,
      type
    })
  }

  useEffect(() => {
    getImpactAnalysis()
    return () => actions.rpmClearImpactAnalysis()
  }, [])

  const { impactAnalysis } = state

  return (<div id='impact-analysis'>
    <div className="impact-analysis-header">Impact Analysis</div>
    <div className='sub-header-container'>
      <div>{`Object Name: ${module_name}`}</div>
      <div>{`Object Type: ${objectTypeMapping[module]}`}</div>
    </div>
    <div className='form-container'>
      <FliptDatePicker onChange={onChange} value={startDate} name="start_date"
        label="Start Date" format='YYYY-MM-DD' />
      <FliptDatePicker onChange={onChange} format='YYYY-MM-DD' value={endDate} name="end_date"
        label="End Date" />
    </div>
    <div className='submit-button-container'>
      <FliptButton className='primary' name={'Submit'} onClick={getImpactAnalysis} />
    </div>
    <div className='grid-container'>
      <FliptGrid
        headers={[...defaultHeaders]}
        data={impactAnalysis}
        cellRendererParams={cellRendererParams}
      />
    </div>
  </div>)
}

const mapStateToProps = (state) => ({
  state: {
    impactAnalysis: state.rpm.impactAnalysis,
  },
});

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
    ...RPMActions,
  };

  return {
    actions: bindActionCreators(allActions, dispatch),
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(ImpactAnalysis)
