import Immutable from 'seamless-immutable'
import { createActions, createReducer } from 'reduxsauce'

export const INITIAL_STATE = Immutable({
    groupMgmt: [],
    createGroupData: {},
    groupMaintenance: [],
    groupData: {},
    effectiveGroupRecord: {},
    planList: [],
    clientName: null,
    defaultGroupData: {
        group: [],
        plan: [],
        cag: {}
    }
})


export const setGroupMgmtDashboardReducer = (state = INITIAL_STATE, { payload }) => ({
    ...state,
    groupMgmt: payload,
})

export const setClientNameReducer = (state = INITIAL_STATE, { payload }) => ({
    ...state,
    clientName: payload,
})

export const clearClientNameReducer = (state = INITIAL_STATE, { payload }) => ({
    ...state,
    clientName: null,
})

export const clearGroupMgmtDataReducer = (state = INITIAL_STATE, { payload }) => ({
    ...state,
    groupMgmt: [],
})

export const clearGroupMaintenanceDataReducer = (state = INITIAL_STATE, { payload }) => ({
    ...state,
    groupMaintenance: [],
})


export const setGroupMaintenanceDashboardReducer = (state = INITIAL_STATE, { payload }) => ({
    ...state,
    groupMaintenance: payload,
})

export const setCreateGroupDataReducer = (state = INITIAL_STATE, { payload }) => ({
    ...state,
    createGroupData: payload,
})

export const setGroupDataReducer = (state = INITIAL_STATE, { payload }) => ({
    ...state,
    groupData: payload,
})

export const setPlanListReducer = (state = INITIAL_STATE, { payload }) => ({
    ...state,
    planList: payload,
})

export const setDefaultGroupDataReducer = (state = INITIAL_STATE, { payload }) => ({
    ...state,
    defaultGroupData: payload,
})

export const setEffectiveGroupRecordReducer = (state = INITIAL_STATE, { payload }) => ({
    ...state,
    effectiveGroupRecord: {
        ...payload,
    }
})


export const { Types, Creators } = createActions({
    getGroupMgmtDashboard: ['payload'],
    setGroupMgmtDashboard: ['payload'],
    getGroupMaintenanceDashboard: ['payload'],
    setGroupMaintenanceDashboard: ['payload'],
    createGroup: ['payload'],
    setCreateGroupData: ['payload'],
    getGroupById: ['payload'],
    getGroupByHierarchy: ['payload'],
    setGroupData: ['payload'],
    setPlanList: ['payload'],
    clearGroupMgmtData: null,
    clearGroupMaintenanceData: null,
    setClientName: ['payload'],
    clearClientName: null,
    setDefaultGroupData: ['payload'],
    setEffectiveGroupRecord: ['payload'],
})

const HANDLERS = {
    [Types.SET_GROUP_MGMT_DASHBOARD]: setGroupMgmtDashboardReducer,
    [Types.CLEAR_GROUP_MGMT_DATA]: clearGroupMgmtDataReducer,
    [Types.SET_GROUP_MAINTENANCE_DASHBOARD]: setGroupMaintenanceDashboardReducer,
    [Types.SET_CREATE_GROUP_DATA]: setCreateGroupDataReducer,
    [Types.SET_GROUP_DATA]: setGroupDataReducer,
    [Types.SET_EFFECTIVE_GROUP_RECORD]: setEffectiveGroupRecordReducer,
    [Types.SET_PLAN_LIST]: setPlanListReducer,
    [Types.SET_DEFAULT_GROUP_DATA]: setDefaultGroupDataReducer,
    [Types.CLEAR_GROUP_MAINTENANCE_DATA]: clearGroupMaintenanceDataReducer,
    [Types.SET_CLIENT_NAME]: setClientNameReducer,
    [Types.CLEAR_CLIENT_NAME]: clearClientNameReducer,
}

export default createReducer(INITIAL_STATE, HANDLERS)
