import React, { useState, useEffect, useCallback } from "react";

import { Creators as AppActions } from "../../../../redux/reducers/app";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import FliptInput from "../../../../components/form/fliptInput";
import moment from "moment";
import { Button, Icon } from "semantic-ui-react";
import FliptPaDropdown from "../../../../components/form/fliptPaDropdown";
import FliptRadio from "../../../../components/form/fliptRadio";
import FliptLabel from "../../../../components/form/fliptLabel";
import { Creators as FileConfigurationActions } from "../../../../redux/reducers/api/fileConfiguration";
import Info from "./Info";
import { renderDynamicInput, sampleData } from "./utils";
const ResponseParameter = (props) => {
  const validations = props.state.validations;
  const validationResults = props.state.validationResults;
  const showErrors =
    validationResults && validationResults && !validationResults.isAllValid;

  const _updateFields = (el, dropdown) => {
    const { name, value } = dropdown || el.currentTarget;
    props.actions.updateResponseFileParameterForm({ name, value });
  };
  const { responseParameter } = props.state;
  const {
    criteria_notification_type,
    criteria_response_format,
    criteria_response_file_type,
    hold_file_processing,
    hold_file_processing_reason,

    criteria_other_delimiter,
    criteria_other_format,
    criteria_delimiter_value,
    criteria_delimiter_other_notes,
  } = responseParameter;

  const renderAdditionalFields = () => {
    const { additionalFields } = props.state;
    if (additionalFields?.length == 0) return null;
    const rowDivider = 2;
    const fieldProps = {
      ...responseParameter,
      onChange: _updateFields,
      className: "fields-header",
    };
    return (
      <>
        {Array.from(
          { length: Math.ceil(additionalFields.length / rowDivider) },
          (_, i) => (
            <div className="fields-container" key={`row${i}`}>
              {additionalFields
                .slice(i * rowDivider, (i + 1) * rowDivider)
                .map((movie) => renderDynamicInput(movie, fieldProps))}
            </div>
          )
        )}
      </>
    );
  };

  return (
    <div id="file-configuration">
      <div className="section-header">ResponseParameter</div>

      <div className="content shadow margin-bottom ">
        <div className="section-sub-header">
          Select the type of Response file for the Client
        </div>
        <div className="fields-container">
          <div className="fields-header">
            <div className="field-label">
              <span>
                Type of notifications being requested{" "}
                <span className="danger">*</span>
              </span>
              <Info
                content="For load notification, which type of error/failure/term records to return"
                position="top right"
              />
            </div>
            <div className="field-label-radio">
              <div className="divide-radio">
                <FliptRadio
                  className="radio-input"
                  name="criteria_notification_type"
                  value={"Both Successful and Error records from Import File"}
                  checked={
                    criteria_notification_type ===
                    "Both Successful and Error records from Import File"
                  }
                  onChange={_updateFields}
                />
                <FliptLabel
                  className="radio-label"
                  description=""
                  label="Both Successful and Error records from Import File"
                />
              </div>
            </div>
            <div className="field-label-radio">
              <div className="divide-radio">
                <FliptRadio
                  className="radio-input"
                  toggle={false}
                  value={"Only Error records from Import File"}
                  name="criteria_notification_type"
                  checked={
                    criteria_notification_type ===
                    "Only Error records from Import File"
                  }
                  onChange={_updateFields}
                />
                <FliptLabel
                  className="radio-label"
                  description=""
                  label="Only Error records from Import File"
                />
              </div>
            </div>
            <div className="field-label-radio">
              <div className="divide-radio">
                <FliptRadio
                  className="radio-input"
                  toggle={false}
                  name="criteria_notification_type"
                  value={"Only Error and Termed records from Import File"}
                  checked={
                    criteria_notification_type ===
                    "Only Error and Termed records from Import File"
                  }
                  onChange={_updateFields}
                />
                <FliptLabel
                  className="radio-label"
                  description=""
                  label="Only Error and Termed records from Import File"
                />
                {showErrors &&
                  !validationResults?.criteria_notification_type && (
                    <section className="flipt-input-error">
                      <em>
                        {validations.criteria_notification_type?.errorMessage ||
                          null}
                      </em>
                    </section>
                  )}
              </div>
            </div>
          </div>
          <div className="fields-header">
            <div className="field-label">
              <span>
                Please select the Response File Format{" "}
                <span className="danger">*</span>
              </span>
              <Info
                content="Format of load notification file delivered"
                position="top right"
              />
            </div>
            <div className="field-label-radio">
              <div className="divide-half-radio">
                <FliptRadio
                  className="radio-input"
                  name="criteria_response_format"
                  value={"Fixed Length"}
                  checked={criteria_response_format === "Fixed Length"}
                  onChange={_updateFields}
                />
                <FliptLabel
                  className="radio-label"
                  description=""
                  label="Fixed Length"
                />
              </div>
              <div className="divide-half-radio">
                <FliptRadio
                  className="radio-input"
                  toggle={false}
                  name="criteria_response_format"
                  value={"Delimited"}
                  checked={criteria_response_format === "Delimited"}
                  onChange={_updateFields}
                />
                <FliptLabel
                  className="radio-label"
                  description=""
                  label="Delimited"
                />
              </div>
            </div>
            <div className="field-label-radio">
              <div className="divide-half-radio">
                <FliptRadio
                  className="radio-input"
                  toggle={false}
                  name="criteria_response_format"
                  value={"Other Delimiter"}
                  checked={criteria_response_format === "Other Delimiter"}
                  onChange={_updateFields}
                />
                <FliptLabel
                  className="radio-label"
                  description=""
                  label="Other Delimiter"
                />
                {criteria_response_format === "Other Delimiter" && (
                  <div className="field-sub-label">
                    <div className="field-label-hint">
                      <span style={{ fontSize: "12px" }}>
                        System Enhancement Required: Please work with tech to
                        create a request for application change
                      </span>
                    </div>
                  </div>
                )}
                {showErrors && !validationResults?.criteria_response_format && (
                  <section className="flipt-input-error">
                    <em>
                      {validations.criteria_response_format?.errorMessage ||
                        null}
                    </em>
                  </section>
                )}
              </div>
              <div className="divide-half-radio">
                <FliptRadio
                  className="radio-input"
                  toggle={false}
                  name="criteria_response_format"
                  value={"Other Format"}
                  checked={criteria_response_format === "Other Format"}
                  onChange={_updateFields}
                />
                <FliptLabel
                  className="radio-label"
                  description=""
                  label="Other Format"
                />

                {criteria_response_format === "Other Format" && (
                  <div className="field-sub-label">
                    <div className="field-label-hint">
                      <span style={{ fontSize: "12px" }}>
                        System Enhancement Required: Please work with tech to
                        create a request for application change
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="field-label-radio">
              {criteria_response_format === "Other Delimiter" ? (
                <div className="divide-half">
                  <div className="field-label">
                    <span>Please describe Other Delimiter</span>
                  </div>
                  <FliptInput
                    className="field-input"
                    placeholder="Placeholder "
                    name="criteria_other_delimiter"
                    value={criteria_other_delimiter}
                    onChange={_updateFields}
                  />
                </div>
              ) : (
                <div className="divide-half"></div>
              )}
              {criteria_response_format === "Other Format" && (
                <div className="divide-half">
                  <div className="field-label">
                    <span>Please describe Other Delimiter</span>
                  </div>
                  <FliptInput
                    className="field-input"
                    placeholder="Placeholder "
                    name="criteria_other_format"
                    value={criteria_other_format}
                    onChange={_updateFields}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        {/* <div className="fields-container">
          <div className="fields-header">
           
          </div>
          <div className="fields-header">
           
          </div>
        </div> */}

        <div className="fields-container">
          <div className="fields-header">
            <div className="field-label">
              <span>
                Please select the Response File Type
                <span className="danger">*</span>
              </span>
              <Info
                content="Layout (standard or custom) of load notification file"
                position="top right"
              />
            </div>
            <div className="field-label-radio">
              <div className="divide-half-radio">
                <FliptRadio
                  className="radio-input"
                  name="criteria_response_file_type"
                  value={"Standard Response"}
                  checked={criteria_response_file_type === "Standard Response"}
                  onChange={_updateFields}
                />
                <FliptLabel
                  className="radio-label"
                  description=""
                  label="Standard Response"
                />
                {showErrors &&
                  !validationResults?.criteria_response_file_type && (
                    <section className="flipt-input-error">
                      <em>
                        {validations.criteria_response_file_type
                          ?.errorMessage || null}
                      </em>
                    </section>
                  )}
              </div>
              <div className="divide-half-radio">
                <FliptRadio
                  className="radio-input"
                  toggle={false}
                  name="criteria_response_file_type"
                  value={"Other Custom Type"}
                  checked={criteria_response_file_type === "Other Custom Type"}
                  onChange={_updateFields}
                />
                <FliptLabel
                  className="radio-label"
                  description=""
                  label="Other Custom Type"
                />
              </div>
            </div>
          </div>

          {criteria_response_format === "Delimited" ? (
            <div className="fields-header">
              <div className="field-label">
                <span>Delimiter Value</span>
                <Info
                  content="If delimited, what is the delimiter"
                  position="top right"
                />
              </div>

              <div className="field-label-radio">
                <div className="divide-half-radio">
                  <FliptRadio
                    className="radio-input"
                    name="criteria_delimiter_value"
                    value={"Comma"}
                    checked={criteria_delimiter_value === "Comma"}
                    onChange={_updateFields}
                  />
                  <FliptLabel
                    className="radio-label"
                    description=""
                    label="Comma"
                  />
                </div>
                <div className="divide-half-radio">
                  <FliptRadio
                    className="radio-input"
                    toggle={false}
                    name="criteria_delimiter_value"
                    value={"Pipe"}
                    checked={criteria_delimiter_value === "Pipe"}
                    onChange={_updateFields}
                  />
                  <FliptLabel
                    className="radio-label"
                    description=""
                    label="Pipe"
                  />
                </div>
              </div>
              <div className="field-label-radio">
                <div className="divide-half-radio">
                  <FliptRadio
                    className="radio-input"
                    toggle={false}
                    name="criteria_delimiter_value"
                    value={"Tab"}
                    checked={criteria_delimiter_value === "Tab"}
                    onChange={_updateFields}
                  />
                  <FliptLabel
                    className="radio-label"
                    description=""
                    label="Tab"
                  />
                </div>
                <div className="divide-half-radio">
                  <FliptRadio
                    className="radio-input"
                    toggle={false}
                    name="criteria_delimiter_value"
                    value={"Other"}
                    checked={criteria_delimiter_value === "Other"}
                    onChange={_updateFields}
                  />
                  <FliptLabel
                    className="radio-label"
                    description=""
                    label="Other"
                  />
                </div>
              </div>
              {criteria_delimiter_value === "Other" && (
                <div className="field-label-radio">
                  <div className="divide-half-radio">
                    <div className="field-label">
                      <span>Other </span>
                    </div>
                    <FliptInput
                      className="field-input"
                      name="criteria_delimiter_other_notes"
                      placeholder="placeholder "
                      value={criteria_delimiter_other_notes}
                      onChange={_updateFields}
                    />
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="fields-header"></div>
          )}
        </div>

        {/* <div className="section-header">
          Production Operational Processing Parameters
        </div> */}
        {renderAdditionalFields()}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    state: {
      app: state.app,
      responseParameter: state.fileConfiguration.responseParameter,
      validations: state.fileConfiguration.validations.responseParameter,
      validationResults:
        state.fileConfiguration.validationResults.responseParameter,
      additionalFields: state.fileConfiguration.additional_fields?.find(row => row.page === 'response_parameter')?.fields || [],
      // test data below, to be removed
      // additionalFields:
      //   sampleData?.find((row) => row.page === "response_parameter")?.fields ||
      //   [],
    },
  };
};

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
    ...FileConfigurationActions,
  };
  return {
    actions: bindActionCreators(allActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResponseParameter);
