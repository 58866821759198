import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import './styles.scss'
import BreadCrumb from '../../../components/breadCrumb'
import FliptGrid from '../../../components/fliptGrid'
import FliptButton from '../../../components/form/fliptButton'
import FliptDropdown from '../../../components/form/fliptDropdown'
import FliptInput from '../../../components/form/fliptInput'
import FliptCheckbox from '../../../components/form/fliptCheckbox'
import { DeleteRowRenderer } from '../../../components/fliptGrid/cellRenderers'
import { Creators as IntegrationManagementActions } from '../../../redux/reducers/api/integrationManagement'
import { Creators as AutoSuggestActions } from '../../../redux/reducers/api/autoSuggest'
import { Creators as ApiActions } from '../../../redux/reducers/api/bulkReprocessingRestacking'
import { Creators as AppActions } from '../../../redux/reducers/app'
import BatchInput from './batchInput'
import { getCAGOptions } from '../../../utils/utilities'
import * as Constants from './constants'
import rowCelInfo from './rowCelInfo'
import { DatePickerEditor, DropdownEditor, InputTextEditor, SearchEditor } from '../../../components/fliptGrid/cellEditors'
import FliptMultiMemberSearch from '../../../components/fliptMultiMemberSearch'
import FliptMultiPrescriberSearch from '../../../components/fliptMultiPrescriberSearch'
import FliptMultiPharmacySearch from '../../../components/fliptMultiPharmacySearch'
import moment from 'moment'
import FliptLabel from '../../../components/form/fliptLabel'
import BatchModal from './batchModal'


class BulkReprocessingRestacking extends Component {
  constructor(props) {
    super(props)
    this.state = {
      gridApi: null,
      rowIndex: null,
      name: null,
      memberData: [],
      prescriberData: [],
      pharmacyData: [],
      editDataState: [],
      searchData: [{
        carrier: '',
        account: '',
        group: '',
        auth_id: '',
        member_id: '',
        start_date: '',
        end_date: '',
        first_name: '',
        last_name: '',
        npi: '',
        include: true,
        drug_name: '',
        plan_name: '',
        gpi: '',
        ndc: ''
      }],
      autoSuggestionValues: [{
        drug_name: { values: [], loading: false }
      }],
      reprocessing_type: '',
      reprocessing_notes: '',
      columnUpdated: null,
      drug_cost: '',
      patient_pay: '',
      deductible_state: '',
      rowUpdated: null,
      reverse: 'N',
      reprocess: 'Y',
      restack: 'N',
      adjudication_services: ''
    }
    this.agGridRef = React.createRef()
  }

  componentDidMount() {
    const { actions } = this.props
    actions.getEdits()
  }

  componentDidUpdate(prevProps) {
    const { editsData, autoSuggestData, planLookupData, batchDetailData } = this.props.state
    if (prevProps.state.editsData !== editsData) {
      const editDataState = editsData?.map((edit) => {
        return { ...edit, checked: false }
      })
      this.setState({ editDataState: editDataState?.length > 4 ? _.chunk(editDataState, 4) : [editDataState] })
    }
    if ((prevProps.state.autoSuggestData !== autoSuggestData)) this._updateAutoSuggestions()
    if (prevProps.state.planLookupData !== planLookupData && planLookupData?.length) {
    }
    if ((prevProps.state.batchDetailData !== batchDetailData)) this.openBatchModal()
  }

  openBatchModal() {
    const { state: { batchDetailData }, actions } = this.props
    actions.setModalComponent({
      modalProperties: { size: 'small', className: 'bulk-reprocessing-restacking-modal', noShow: true },
      contents: <BatchModal batchDetailData={batchDetailData} goToDashboard={this.goToDashboard} />,
    })
    actions.toggleModal()
  }

  goToDashboard = () => {
    const { actions } = this.props
    const pathname = '/integration-management-dashboard'
    actions.toggleModal()
    this.props.history.push({ pathname })
  }

  _updateAutoSuggestions = () => {
    const { props, state: { columnUpdated, autoSuggestionValues, rowUpdated } } = this
    const { autoSuggestData } = props.state
    const newAutoSuggestionValues = [...autoSuggestionValues]
    newAutoSuggestionValues[rowUpdated][columnUpdated].values = autoSuggestData[columnUpdated].map((val) => ({
      title: val,
    }))
    newAutoSuggestionValues[rowUpdated].drug_name.loading = false
    if (this.setAutoSuggestionValues) this.setAutoSuggestionValues(newAutoSuggestionValues)

    this.setState({ autoSuggestionValues: newAutoSuggestionValues })
  }


  batchInput = () => {
    const { actions, history } = this.props
    actions.setModalComponent({
      modalProperties: { size: 'small', className: 'bulk-reprocessing-restacking-modal' },
      contents: <BatchInput history={history} updateForm={this.updateFrom} form={this.state.searchData} />,
    })
    actions.toggleModal()
  }


  updateFrom = (searchData) => {
    const newSearchData = [...searchData]
    const { actions } = this.props
    this.setState({ searchData: newSearchData })
    actions.toggleModal()
    this.agGridRef.current.api.refreshCells()
  }

  handleInputChange = (el, dropdown) => {
    const { name, value } = dropdown || el.currentTarget
    this.setState({ [name]: value })
  }

  handleCheckboxChange = (index, innerIndex) => {
    const { editDataState } = this.state
    editDataState[index][innerIndex].checked = !editDataState[index][innerIndex].checked
    this.setState({ editDataState })
  }

  _onComponentStateChanged = (params) => {
    params.api.forEachNodeAfterFilter(node => node.setSelected(node?.data?.include))
  }

  clearScreen = () => {
    this.setState({
      searchData: [{
        carrier: '',
        account: '',
        group: '',
        auth_id: '',
        member_id: '',
        start_date: '',
        end_date: '',
        first_name: '',
        last_name: '',
        npi: '',
        include: true,
        drug_name: '',
        plan_name: '',
        gpi: '',
        ndc: ''
      }],
      reprocessing_type: '',
      reprocessing_notes: '',
      drug_cost: '',
      patient_pay: '',
      deductible_state: '',
      reverse: 'N',
      reprocess: 'Y',
      restack: 'N',
      adjudication_services: ''
    })
    // this.agGridRef.current.api.refreshServerSideStore({ route: null, purge: true })
  }


  handleQueueClaims = () => {
    const { reprocessing_type,
      reprocessing_notes,
      reverse,
      reprocess,
      restack,
      drug_cost,
      patient_pay,
      deductible_state,
      editDataState,
      searchData } = this.state
    const { state: { user } } = this.props
    let rulesList = []
    searchData.forEach((data) => {
      const innerArray = []
      const keys = Object.keys(data)
      keys.forEach((keyData) => {
        if (data[keyData] && keyData !== 'include') {
          let finalValue = data[keyData]
          let condition_type = data.include ? 'IN' : 'NOT_IN'
          if (keyData === 'member_id' || keyData === 'npi' || keyData === 'prescriber_npi' || keyData === 'auth_id'
            || keyData === 'gpi' || keyData === 'ndc' || keyData === 'plan_name' || keyData === 'drug_name') {
            finalValue = data[keyData].split(",")
          }
          if (keyData === 'start_date' || keyData === 'end_date') {
            finalValue = moment(finalValue).format('YYYY-MM-DD')
            condition_type = keyData === 'start_date' ? 'GREATER_THAN_EQUAL_TO' : 'LESS_THAN_EQUAL_TO'
          }
          innerArray.push({
            field: this.getKeyName(keyData),
            condition_type,
            value: finalValue
          })
        }
      })
      rulesList.push(innerArray)
    })

    let editsData = editDataState.flat()
    let claimDetails = {
      type: "reprocessing_queue",
      reprocessing_type,
      reprocessing_notes,
      reverse,
      reprocess,
      restack,
      status: 'CRITERIA_SUBMITTED',
      rules: rulesList,
      edits_bypassed: editsData.filter((edits) => edits.checked).map(({ reject_code }) => reject_code).flat(),
      created_by: user.uuid,
      pricing_config: {
        drug_cost,
        patient_pay,
        deductible_state,
      }
    }
    this.props.actions.queueClaims({ claims: claimDetails, clearScreen: this.clearScreen })
  }

  getKeyName = (key) => {
    const keyObj = {
      'member_id': 'claim_request.cardholder_id',
      'prescriber_npi': 'claim_request.prescriber_id',
      'pharmacy_npi': 'claim_request.service_provider_id',
      'account': 'domain',
      'start_date': 'startDate',
      'end_date': 'endDate',
      'group': 'member_group',
      'carrier': 'carrier',
      'auth_id': 'auth_id',
      'drug_name': 'drug_name',
      'plan_name': 'benefit_plan_name',
      'gpi': 'claim_request.gpi',
      'ndc': 'claim_request.product_id'
    }
    return keyObj[key]
  }

  isQueueClaimsDisabled = () => {
    const { reprocessing_type,
      patient_pay,
      deductible_state,
      drug_cost,
      reprocess,
      restack,
      reverse,
      searchData } = this.state
    let isSearchDataEmpty = true
    searchData?.forEach((data) => {
      const keys = Object.keys(data)
      keys.forEach((keyData) => {
        if (data[keyData] && keyData !== 'include') {
          isSearchDataEmpty = false
          return
        }
      })
    })
    if (((reprocessing_type !== '' && patient_pay !== '' && deductible_state !== '' && drug_cost !== '') && (reprocess === "Y" || restack === "Y" || reverse === "Y")) && !isSearchDataEmpty)
      return false
    return true
  }

  delRow = (rowIndex) => {
    const { searchData, autoSuggestionValues } = this.state
    const newSearchData = [...searchData]
    newSearchData.splice(rowIndex, 1)
    this.setState({
      searchData: newSearchData,
      autoSuggestionValues: autoSuggestionValues.filter((value) => autoSuggestionValues.indexOf(value) !== rowIndex),
    })
  }

  addCondition = () => {
    const { searchData, autoSuggestionValues } = this.state
    const newSearchData = [...searchData]
    newSearchData.push({
      carrier: '',
      account: '',
      group: '',
      auth_id: '',
      member_id: '',
      start_date: '',
      end_date: '',
      first_name: '',
      last_name: '',
      npi: '',
      include: true,
      drug_name: '',
      plan_name: '',
      gpi: '',
      ndc: ''
    })
    this.setState({
      searchData: newSearchData, autoSuggestionValues: [...autoSuggestionValues, {
        drug_name: { values: [], loading: false }
      }]
    })
  }


  _clearExtraValue = (value, selectedData, key) => {
    let arrayData = value?.split(",")
    if (arrayData.length === 1 && arrayData[0].length === 1) {
      return '';
    } else {
      const idArray = selectedData.map((data) => { return data[key] })
      return idArray.join(',');
    }

  }

  handleChange = (el, dropdown, rowIndex, gridApi, stepLevel, setAutoSuggestionValues) => {
    const { searchData, prescriberData, pharmacyData, memberData, autoSuggestionValues, rowUpdated } = this.state
    const { actions } = this.props
    let newSearchData = searchData
    const newAutoSuggestionValues = [...autoSuggestionValues]
    const { name, value } = dropdown || el.currentTarget

    if (rowUpdated !== rowIndex) {
      actions.clearAutoSuggestSearchTerm()
    }
    if (name === 'member_id') {
      newSearchData = _.cloneDeep(searchData)
      newSearchData[rowIndex][name] = this._clearExtraValue(value, memberData, 'member_id')
      this.setState({ rowIndex, name, searchData: newSearchData })
      this.searchMember();
      return;
    }
    if (name === 'prescriber_npi') {
      newSearchData = _.cloneDeep(searchData)
      newSearchData[rowIndex][name] = this._clearExtraValue(value, prescriberData, 'npi')
      this.setState({ rowIndex, name, searchData: newSearchData })
      this.searchPrescriber();
      return;
    }
    if (name === 'pharmacy_npi') {
      newSearchData = _.cloneDeep(searchData)
      newSearchData[rowIndex][name] = this._clearExtraValue(value, pharmacyData, 'pharmacynpi')
      this.setState({ rowIndex, name, searchData: newSearchData })
      this.searchPharmacy();
      return;
    }
    if (name === 'drug_name' && value.length >= 3) {
      newAutoSuggestionValues[rowIndex].drug_name.loading = true
      actions.getAutoSuggestData({ search_string: value, search_in: 'fts_ndc_drugs', search_for: 'drug_name' })
      this.setAutoSuggestionValues = setAutoSuggestionValues
    }

    newSearchData[rowIndex][name] = value
    this.setState({ searchData: newSearchData, rowUpdated: rowIndex, columnUpdated: name, })
    gridApi.refreshCells()
  }

  updateMemberData = (data) => {
    const { searchData, rowIndex, name } = this.state
    const newSearchData = _.cloneDeep(searchData)
    if (!data.length) {
      newSearchData[rowIndex][name] = ''
      this.setState({ memberData: [], searchData: newSearchData })
      return
    }
    const idArray = data.map((member) => { return member.member_id })
    newSearchData[rowIndex][name] = idArray.join(',')
    this.setState({ searchData: newSearchData, memberData: data })
    this.agGridRef.current.api.refreshCells()
  }

  updatePharmacyData = (data) => {
    const { searchData, rowIndex, name } = this.state
    const newSearchData = _.cloneDeep(searchData)
    if (!data.length) {
      newSearchData[rowIndex][name] = ''
      this.setState({ pharmacyData: [], searchData: newSearchData })
      return
    }
    const idArray = data.map((pharmacy) => { return pharmacy.pharmacynpi })
    newSearchData[rowIndex][name] = idArray.join(',')
    this.setState({ searchData: newSearchData, pharmacyData: data })
    this.agGridRef.current.api.refreshCells()
  }

  updatePrescriberData = (data) => {
    const { searchData, rowIndex, name } = this.state
    const newSearchData = _.cloneDeep(searchData)
    if (!data.length) {
      newSearchData[rowIndex][name] = ''
      this.setState({ prescriberData: [], searchData: newSearchData })
      return
    }
    const idArray = data.map((prescriber) => { return prescriber.npi })
    newSearchData[rowIndex][name] = idArray.join(',')
    this.setState({ searchData: newSearchData, prescriberData: data })
    this.agGridRef.current.api.refreshCells()
  }


  searchMember = () => {
    const { actions } = this.props
    const { memberData } = this.state
    actions.setModalComponent({
      modalProperties: { size: 'small', className: 'bulk-reprocessing-restacking-modal', scrolling: true },
      contents: <FliptMultiMemberSearch updateMemberData={this.updateMemberData} memberData={memberData} />,
    })
    actions.toggleModal()
  }

  searchPrescriber = () => {
    const { actions } = this.props
    const { prescriberData } = this.state
    actions.setModalComponent({
      modalProperties: { size: 'small', className: 'bulk-reprocessing-restacking-modal', scrolling: true },
      contents: <FliptMultiPrescriberSearch updatePrescriberData={this.updatePrescriberData} prescriberData={prescriberData} />,
    })
    actions.toggleModal()
  }

  searchPharmacy = () => {
    const { actions } = this.props
    const { pharmacyData } = this.state
    actions.setModalComponent({
      modalProperties: { size: 'small', className: 'bulk-reprocessing-restacking-modal', scrolling: true },
      contents: <FliptMultiPharmacySearch updatePharmacyData={this.updatePharmacyData} pharmacyData={pharmacyData} />,
    })
    actions.toggleModal()
  }

  autoSuggestionResultSelect = (data, rowIndex) => {
    const { state } = this
    const searchData = [...state.searchData]
    const rowToUpdate = searchData[rowIndex]
    const { name, result } = data
    const value = result.title

    rowToUpdate[name] = value
    searchData[rowIndex] = rowToUpdate

    this.setState({
      searchData,
    })
  }
  onRowSelected = (e) => {
    if (e?.node?.data) e.node.data['include'] = e?.node?.selected
  }

  render() {
    const { reprocessing_type,
      editDataState,
      reprocessing_notes,
      reprocess,
      restack,
      reverse,
      drug_cost,
      patient_pay,
      deductible_state,
      searchData,
      autoSuggestionValues } = this.state
    const { state: { carriers } } = this.props

    const searchHeaders = ['action', 'include', 'carrier', 'account', 'group', 'plan_name', 'member_id', 'pharmacy_npi', 'prescriber_npi'
      , 'auth_id', 'start_date', 'end_date', 'drug_name', 'gpi', 'ndc']

    const searchCellRendererParams = {
      action: {
        cellRenderer: DeleteRowRenderer,
        state: {
          onClick: this.delRow,
        },
        width: 95,
      },
      include: {
        overrideHeader: 'Include',
        cellRenderer: () => {
          return <p />
        },
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
      },
      pharmacy_npi: {
        overrideHeader: 'Pharmacy NPI'
      },
      member_id: {
        overrideHeader: 'Member ID'
      },
      prescriber_npi: {
        overrideHeader: 'Prescriber NPI'
      },
      auth_id: {
        overrideHeader: 'Auth ID'
      },
    }
    const searchCellEditorParams = {
      carrier: {
        editable: true,
        cellEditor: DropdownEditor,
        onChange: this.handleChange,
      },
      account: {
        editable: true,
        cellEditor: DropdownEditor,
        onChange: this.handleChange,
      },
      group: {
        editable: true,
        cellEditor: DropdownEditor,
        onChange: this.handleChange,
      },
      member_id: {
        editable: true,
        cellEditor: InputTextEditor,
        onChange: this.handleChange,
      },
      pharmacy_npi: {
        editable: true,
        cellEditor: InputTextEditor,
        onChange: this.handleChange,
      },
      prescriber_npi: {
        editable: true,
        cellEditor: InputTextEditor,
        onChange: this.handleChange,
      },
      auth_id: {
        editable: true,
        cellEditor: InputTextEditor,
        onChange: this.handleChange,
      },
      start_date: {
        editable: true,
        cellEditor: DatePickerEditor,
        onChange: this.handleChange,
        isDisabled: (data) => {
          return !data.include
        }
      },
      drug_name: {
        cellEditor: SearchEditor,
        onChange: this.handleChange,
        autoSuggestionResultSelect: this.autoSuggestionResultSelect,
      },
      plan_name: {
        editable: true,
        cellEditor: InputTextEditor,
        onChange: this.handleChange,
      },
      gpi: {
        editable: true,
        cellEditor: InputTextEditor,
        onChange: this.handleChange,
      },
      ndc: {
        editable: true,
        cellEditor: InputTextEditor,
        onChange: this.handleChange,
      },
      end_date: {
        editable: true,
        cellEditor: DatePickerEditor,
        onChange: this.handleChange,
        isDisabled: (data) => {
          return !data.include
        }
      },
    }
    const filterOptions = getCAGOptions(carriers, searchData?.length && searchData[0].carrier, searchData?.length && searchData[0].account, true)
    rowCelInfo.carrier.options = filterOptions.carrierOptions
    rowCelInfo.account.options = filterOptions.accountOptions
    rowCelInfo.group.options = filterOptions.groupOptions
    return (
      <div id='bulk-reprocessing-restacking'>
        <BreadCrumb {...this.props} />
        <div className='header'>
          Batch Details
        </div>
        <section className='reprocessing-details-section'>
          <FliptDropdown
            stylized
            placeholder='Select'
            label='Reprocessing Type'
            name='reprocessing_type'
            value={reprocessing_type}
            options={Constants.REPROCESSING_TYPES}
            onChange={this.handleInputChange} />
          <FliptDropdown
            stylized
            placeholder='Select'
            label='Historical Pricing'
            name='drug_cost'
            value={drug_cost}
            options={Constants.TRUE_FALSE_TYPES}
            onChange={this.handleInputChange} />
          <FliptDropdown
            stylized
            placeholder='Select'
            label='Historical Patient Pay'
            name='patient_pay'
            value={patient_pay}
            options={Constants.TRUE_FALSE_TYPES}
            onChange={this.handleInputChange} />
          <FliptDropdown
            stylized
            placeholder='Select'
            label='Historical Deductible State'
            name='deductible_state'
            value={deductible_state}
            options={Constants.TRUE_FALSE_TYPES}
            onChange={this.handleInputChange} />
          <FliptInput
            stylized
            label='Reprocessing Notes'
            name='reprocessing_notes'
            value={reprocessing_notes}
            onChange={this.handleInputChange} />
        </section>
        <section className='service-section'>
          <span className="checkbox-label">Adjudication Services</span>
          <div className='list-container'>
            {editDataState?.map((innerData, index) => {
              return (<div className='checkbox-outer-container'>
                {innerData?.map((obj, innerIndex) => {
                  return (<div className='checkbox-container' >
                    <FliptCheckbox
                      disabled={!obj.default_bypass}
                      value={obj.reject_code}
                      checked={obj.checked}
                      onChange={() => this.handleCheckboxChange(index, innerIndex)}
                    />
                    <FliptLabel
                      description={obj.description}
                      label={obj.reject_code}
                      labelClass='checkbox-label'
                    />
                  </div>)
                })}
              </div>)
            })}
          </div>

        </section>
        <div className='header'>
          Action
        </div>
        <section className='action-needed-section'>
          <div>
            <FliptCheckbox
              className="classname"
              name='reverse'
              value={reverse === 'N' ? 'Y' : 'N'}
              checked={reverse === 'Y'}
              onChange={this.handleInputChange} />
            <span className="checkbox-label">Reverse</span>
          </div>
          <div>
            <FliptCheckbox
              className="classname"
              name='reprocess'
              value={reprocess === 'N' ? 'Y' : 'N'}
              checked={reprocess === 'Y'}
              onChange={this.handleInputChange} />
            <span className="checkbox-label">Reprocess</span>
          </div>
          <div>
            <FliptCheckbox
              className="classname"
              name='restack'
              value={restack === 'N' ? 'Y' : 'N'}
              checked={restack === 'Y'}
              onChange={this.handleInputChange} />
            <span className="checkbox-label">Restack</span>
          </div>
        </section>
        <div className='header'>
          Define Criteria or
          <FliptButton name='Upload File' className='primary batch-input-button' onClick={() => this.batchInput()} />
        </div>
        <section className='claim-information-section'>
          <FliptGrid
            agGridRef={this.agGridRef}
            headers={searchHeaders}
            cellEditorParams={searchCellEditorParams}
            rowCellInfo={rowCelInfo}
            autoSuggestionValues={autoSuggestionValues}
            cellRendererParams={searchCellRendererParams}
            suppressRowClickSelection={true}
            data={searchData}
            rowSelection='multiple'
            onRowSelected={this.onRowSelected}
            onComponentStateChanged={this._onComponentStateChanged}
          />
        </section>
        <div className='button-container'>
          <FliptButton name='Add Condition' className='primary batch-input-button' onClick={() => this.addCondition()} />
        </div>
        <div className='queue-claims-container'>
          <FliptButton
            name='Pull Claims'
            disabled={this.isQueueClaimsDisabled()}
            className='primary queue-claim-button'
            onClick={this.handleQueueClaims} />
        </div>
      </div>)
  }
}
const mapStateToProps = (state) => ({
  state: {
    user: state.user,
    carriers: state?.user?.carriers && state?.user?.carriers?.length > 0 ? state.user.carriers : [],
    editsData: state.integrationMgmt.editsData,
    autoSuggestData: state.autoSuggest.autoSuggestData,
    batchDetailData: state.bulkReprocessingRestacking.batchUpdateDetailData,
  },
})
const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...ApiActions,
    ...AppActions,
    ...IntegrationManagementActions,
    ...AutoSuggestActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BulkReprocessingRestacking)