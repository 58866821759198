export const claimTypeOptions = [
    {
        key: 0,
        text: 'Claim Submission',
        value: 'B1'
    },
    {
        key: 1,
        text: 'Claim Submission and Reversal',
        value: 'B4',
        disabled: true
    }
]
