import Immutable from "seamless-immutable";
import { createActions, createReducer } from "reduxsauce";
import moment from "moment";
export const INITIAL_STATE = Immutable({
    'configuration_type': 'Benefit',
    'configuration_name': '',
    'configuration_notes': '',
    'global_edit_type': '',
    'effective_begin_date': '',
    'effective_term_date': '',
    'status': '',
    'impacts_all_hierarchies': true,
    'hierarchy': [],
    'nationwide': true,
    'geographic_configuration': {},
    'state': [],
    'zip_code': '',
    'clinical_pa': [],
    'clinical_ql': [],
    'clinical_step_therapy': [],
    'programs': [],
    'formulary_name': '',
    'formulary_id': '',
    'tiers': [],
    'um_ranking': [],
    'custom_cost_share': '',
    'copays': [],
    'networks': [],
    'network_tiers': [],
    'price_source': [],
    'publishedUmObjects': {},
    'dashboard': [],
    'page': 'dashboard',
    'section': {},
    'validations': {
        'configuration_name': {
            required: true,
            validation: (val) => (val + '').length >= 2,
            errorMessage: 'Must enter a configuration name that is at least 2 characters long',
        },
        'configuration_notes': {
            required: false,
            validation: (val) => (val + '').length >= 2,
            errorMessage: 'Must enter a configuration note that is at least 2 characters long',
        },
        'effective_begin_date': {
            required: true,
            validation: (val) => (val + '').length >= 2,
            errorMessage: 'Must enter a begin date',
        },
        'effective_term_date': {
            required: false,
            validation: (val, state) => {
                const beginDate = moment(state.effective_begin_date);
                const endDate = moment(val);
                return endDate.isAfter(beginDate);
            },
            errorMessage: 'Must enter an end date that is after the begin date',
        },
    },
    'validationResults': {}
})

export const updateGlobalEditsField = (state = INITIAL_STATE, { payload }) => {
    const { name, value } = payload;
    return {
        ...state,
        [name]: value,
    };
};

export const setGlobalEdit = (state = INITIAL_STATE, { payload }) => {
    return {
        ...state,
        ...payload,
    };
};

export const setGlobalEditsDashboard = (state = INITIAL_STATE, { payload }) => {
    return {
        ...state,
        dashboard: payload,
    };
}

export const setPage = (state = INITIAL_STATE, { payload }) => {
    return {
        ...state,
        page: payload,
    };
};

export const setPublishedUmObjects = (state = INITIAL_STATE, { payload }) => {
    return {
        ...state,
        publishedUmObjects: payload,
    };
};

export const setSectionUI = (state = INITIAL_STATE, { payload = {} }) => {
    return {
        ...state,
        section: {
            ...state.section,
            ...payload
        },
    };
}

export const resetGlobalEdits = (state = INITIAL_STATE, { payload }) => {
    return INITIAL_STATE;
}
export const updateValidationResult = (state = INITIAL_STATE, { payload }) => ({
    ...state,
    validationResults: {
        ...state.validationResults,
        ...payload,
    },
})

export const backToDashboard = (state = INITIAL_STATE, { payload }) => {
    return INITIAL_STATE;
}
export const { Types, Creators } = createActions({
    getGlobalEditConfiguration : ['payload'],
    getGlobalEdit: ['payload'],
    getGlobalEditsDashboard: null,
    getPublishedUmObjects: ['payload'],
    getSectionUI: ['payload'],
    resetGlobalEdits: null,
    saveGlobalEdit: ['payload'],
    saveGlobalEditSuccess: ['payload'],
    saveGlobalEditFailure: ['error'],
    setGlobalEdit: ['payload'],
    setGlobalEditsDashboard: ['payload'],
    setPage: ['payload'],
    setPublishedUmObjects: ['payload'],
    setSectionUI: ['payload'],
    updateGlobalEditsField: ['payload'],
    updateValidationResult: ['payload'],
    validateSaveAndExit: null,
    backToDashboard: null,
});

const HANDLERS = {
    [Types.BACK_TO_DASHBOARD]: backToDashboard,
    [Types.RESET_GLOBAL_EDITS]: resetGlobalEdits,
    [Types.SET_GLOBAL_EDIT]: setGlobalEdit,
    [Types.SET_GLOBAL_EDITS_DASHBOARD]: setGlobalEditsDashboard,
    [Types.SET_PAGE]: setPage,
    [Types.SET_PUBLISHED_UM_OBJECTS]: setPublishedUmObjects,
    [Types.SET_SECTION_UI]: setSectionUI,
    [Types.UPDATE_GLOBAL_EDITS_FIELD]: updateGlobalEditsField,
    [Types.UPDATE_VALIDATION_RESULT]: updateValidationResult,
};

export default createReducer(INITIAL_STATE, HANDLERS);
