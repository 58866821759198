export default {
  party: {
    type: 'input',
    disabled: false,
  },
  brand_cost_share: {
    type: 'input',
    disabled: false,
  },
  brand_cost_share_type: {
    type: 'dropdown',
    disabled: false,
  },
  minimum_brand_cost_share: {
    type: 'input',
    disabled: false,
  },
  maximum_brand_cost_share: {
    type: 'input',
    disabled: false,
  },
  generic_cost_share: {
    type: 'input',
    disabled: false,
  },
  generic_cost_share_type: {
    type: 'dropdown',
    disabled: false,
  },
  minimum_generic_cost_share: {
    type: 'input',
    disabled: false,
  },
  maximum_generic_cost_share: {
    type: 'input',
    disabled: false,
  },
}
