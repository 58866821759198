/* eslint-disable */
export default {
    allow_tpa_id_processing: {
        type: 'dropdown',
        disabled: false,
        options: [],
    },
    end_date: {
        type: 'input',
        disabled: false,
    },
    message: {
        type: 'input',
        disabled: false,
    },
}
