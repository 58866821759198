import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

export default forwardRef((props = {}, ref) => {
  const { disabled, children, className, onClick, name, style, icon } = props;

  return (
    <button
      type="button"
      disabled={disabled}
      className={`${className || ''} flipt-button`}
      onClick={onClick}
      value={name}
      style={style || {}}
      ref={ref}
    >
      {children ?
        children : (
          icon && <span className="flipt-icon">{icon}</span>
        )}
      {name ? name : null}
    </button>
  );
})
