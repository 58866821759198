import React, { useState } from 'react'
import FliptPopup from '../../../../../components/fliptPopup'
import '../styles.scss'

const ApproveCellRenderer = (props) => {
  const { data, state } = props
  const { drugApprovalFreeze } = state
  const [visible, setVisible] = useState(false)
  const show = () => setVisible(true)
  const hide = () => setVisible(false)
  const { status } = data
  const docStatus = drugApprovalFreeze  || (status.toLowerCase() === 'approved' || status.toLowerCase() === 'published')
  const dropDownContent = (
    <div className="menu-container">
      <div
        role="button"
        disabled={docStatus}
        tabIndex={0}
        onClick={() => !docStatus && onClickHandler({ open: false, ...data }, 0)}
        className={`menu-item`}
      >
        Approve without notes
      </div>
      <div
        role="button"
        disabled={docStatus}
        tabIndex={-1}
        onClick={() => !docStatus && onClickHandler({ open: true, ...data }, 1)}
        className={`menu-item`}
      >
        Approve with notes
      </div>
    </div>
  )

  const onClickHandler = (rowData, type) => {
    hide()
    const { state, rowIndex } = props
    const { onClick } = state
    onClick(rowIndex, rowData, type)
  }

  const popupStyle = {
    boxShadow: 'none !important',
    backgroundColor: 'transparent',
    border: 0,
  }
  return (
    <FliptPopup
      basic
      id='drug-management-popup'
      eventsEnabled
      onClose={hide}
      onOpen={show}
      open={visible}
      style={popupStyle}
      trigger={<button style={{backgroundColor: 'transparent', color: 'red', fontSize: '1.5em'}} type="button" className="btn btn-primary" onClick={visible ? hide : show}> ... </button>}
    >
      {dropDownContent}
    </FliptPopup>
  )
}

export default ApproveCellRenderer
