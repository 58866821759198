import Immutable from 'seamless-immutable'
import { createActions, createReducer } from 'reduxsauce'

export const INITIAL_STATE = Immutable({
    loading: false,
    search_result: null,
    update_success: false,
})

export const setSearchResult = (state = INITIAL_STATE, { payload }) => ({
    ...state,
    search_result: payload,
})

export const setLoading = (state = INITIAL_STATE, { payload }) => ({
    ...state,
    loading: payload,
})

export const setUpdateSuccess = (state = INITIAL_STATE, { payload }) => ({
    ...state,
    update_success: payload,
})

export const { Types, Creators } = createActions({
    setLoading: ['payload'],
    setSearchResult: ['payload'],
    getUsers: ['payload'],
    setUpdateSuccess: ['payload'],
    updateUser: ['payload'],

})

const HANDLERS = {
    [Types.SET_LOADING]: setLoading,
    [Types.SET_SEARCH_RESULT]: setSearchResult,
    [Types.SET_UPDATE_SUCCESS]: setUpdateSuccess,
}

export default createReducer(INITIAL_STATE, HANDLERS)
