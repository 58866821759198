import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Icon } from 'semantic-ui-react'
import { cloneDeep } from 'lodash'

import './styles.scss'
import BreadCrumb from '../../../components/breadCrumb'
import FliptButton from '../../../components/form/fliptButton'
import FliptDropdown from '../../../components/form/fliptDropdown'
import FliptInput from '../../../components/form/fliptInput'
import FliptDatePicker from '../../../components/form/fliptDatePicker'
import FliptPaDatePicker from '../../../components/form/fliptPaDatepicker'
import FliptPaInput from '../../../components/form/fliptPaInput'
import FliptDrugSearch from '../../../components/fliptDrugSearch'
import FliptPrescriberSearch from '../../../components/fliptPrescriberSearch'
import FliptPharmacySearch from '../../../components/fliptPharmacySearch'
import FliptMemberSearch from '../../../components/fliptMemberSearch'
import { Creators as MemberLookupActions } from '../../../redux/reducers/api/memberLookup'
import { Creators as ManualClaimEntryActions } from '../../../redux/reducers/api/manualClaimEntry'
import { Creators as AppActions } from '../../../redux/reducers/app'
import { Creators as PrescriberLookupActions } from '../../../redux/reducers/api/prescriberLookup'
import { Creators as PharmacyLookupActions } from '../../../redux/reducers/api/pharmacyLookup'
import { Creators as DrugLookupActions } from '../../../redux/reducers/api/drugLookup'
import { generateClaimDictionary } from './generateClaimDictionary'

import BatchManualClaims from './batchManualClaims'

const otherCoverageCodeOptions = [
  {
    key: 0,
    text: "Not Specified by Patient",
    value: "00",
  }, {
    key: 1,
    text: "No Other Coverage",
    value: "01",
  },
  {
    key: 2,
    text: "Other Coverage Exists - Payment Collected",
    value: "02",
  },
  {
    key: 3,
    text: "Other Coverage Billed - Claim Not Covered",
    value: "03",
  },
  {
    key: 4,
    text: "Other Coverage Exists - Payment Not Collected",
    value: "04",
  },
  {
    key: 8,
    text: "Claim is billing for patient financial responsibility Only",
    value: "08",
  },
]
class ManualClaimEntry extends Component {
  constructor(props) {
    super(props)
    this.state = {
      searchForm: {
        memberId: '',
        memberDob: '',
        ndc: '',
        prescriberNpi: '',
        pharmacyNpi: '',
      },
      prescriptionForm: {
        prescription_reference_number: '',
        quantity: '',
        days_supply: '',
        fill_number: '',
        compound_code: '',
        date_of_service: '',
        date_prescription_written: '',
      },
      memberData: null,
      drugData: null,
      prescriberData: null,
      pharmacyData: null,
      memberValidated: false,
      drugValidated: false,
      pharmacyValidated: false,
      prescriberValidated: false,
      memberSearched: false,
      drugSearched: false,
      pharmacySearched: false,
      prescriberSearched: false,
      memberModalOpen: false,
      pharmacyModalOpen: false,
      prescriberModalOpen: false,
      drugModalOpen: false,
      form: {
        other_coverage_code: otherCoverageCodeOptions[0].value,
        other_payer_patient_responsibility_amount: '',
        other_payer_amount_paid: '',
      },
    }
  }

  _updateFields = (el, evt, formName) => {
    const { name, value } = evt || el.currentTarget
    this.setState((prevState) => ({
      ...prevState,
      [formName]: {
        ...prevState[formName],
        [name]: value,
      },
    }))
  }

  _submitForApproval = () => {
    const { memberData,
      drugData,
      prescriberData,
      pharmacyData,
      prescriptionForm,
      form } = this.state
    const { actions: { putManualClaimEntry, toggleModal } } = this.props
    const data = generateClaimDictionary(memberData,
      drugData,
      prescriberData,
      pharmacyData,
      prescriptionForm,
      form)
    putManualClaimEntry(data)
    toggleModal()
  }

  _cancelForApproval = () => {
    const { actions } = this.props
    actions.toggleModal()
  }

  _confirmForApproval = () => {
    const isValid = this._validateClaimInfo()
    if (!isValid) return
    const { actions: { setModalComponent, toggleModal } } = this.props
    const confirmationModal = (
      <div className="manual-claim-entry-approval-modal">
        <h3>Confirmation</h3>
        <FliptInput placeholder="Name" />
        <FliptInput placeholder="Email Address" />
        <FliptDatePicker />
        <div className="buttons-container">
          <FliptButton
            className="primary"
            name="Submit"
            onClick={() => this._submitForApproval()}
          />
          <FliptButton
            className="tertiary"
            name="Cancel"
            onClick={this._cancelForApproval}
          />
        </div>
      </div>
    )

    setModalComponent({
      modalProperties: { size: 'tiny' },
      contents: confirmationModal,
    })
    toggleModal()
  }

  _renderPricingInformation = () => {
    const {
      form,
    } = this.state
    const {
      usual_and_customary_charge,
      gross_amount_due,
      dispensing_fee_submitted,
      incentive_amount_submitted,
      ingredient_cost_submitted,
      patient_paid_amount_submitted,
      flat_sales_tax_amount_submitted,
      percentage_sales_tax_amount_submitted,
      percentage_sales_tax_rate_submitted,
      percentage_sales_tax_basis_submitted,
    } = form

    return (
      <div className="section">
        <h2>Pricing Information</h2>
        <div className="fields-container">
          <div className="fields">
            <FliptInput
              onChange={(el, evt) => this._updateFields(el, evt, 'form')}
              value={usual_and_customary_charge}
              name="usual_and_customary_charge"
              stylized
              label="Usual and Customary Charge"
              required
            />
            <FliptInput
              onChange={(el, evt) => this._updateFields(el, evt, 'form')}
              value={dispensing_fee_submitted}
              label="Dispensing Fee"
              stylized
              name="dispensing_fee_submitted"
            />
            <FliptInput
              onChange={(el, evt) => this._updateFields(el, evt, 'form')}
              value={incentive_amount_submitted}
              label="Incentive Amount Submitted "
              stylized
              name="incentive_amount_submitted"
            />
            <FliptInput
              onChange={(el, evt) => this._updateFields(el, evt, 'form')}
              value={ingredient_cost_submitted}
              label="Ingredient Cost"
              stylized
              name="ingredient_cost_submitted"
            />
            <FliptInput
              onChange={(el, evt) => this._updateFields(el, evt, 'form')}
              value={gross_amount_due}
              label="Gross Amount Due"
              stylized
              name="gross_amount_due"
            />
          </div>
          <div className="fields">
            <FliptInput
              onChange={(el, evt) => this._updateFields(el, evt, 'form')}
              value={flat_sales_tax_amount_submitted}
              name="flat_sales_tax_amount_submitted"
              label="Flat Sales Tax Amount"
              stylized
            />
            <FliptInput
              onChange={(el, evt) => this._updateFields(el, evt, 'form')}
              value={percentage_sales_tax_amount_submitted}
              name="percentage_sales_tax_amount_submitted"
              label="Percentage Sales Tax Amount"
              stylized
            />
            <FliptInput
              onChange={(el, evt) => this._updateFields(el, evt, 'form')}
              value={percentage_sales_tax_rate_submitted}
              name="percentage_sales_tax_rate_submitted"
              label="Percentage Sales Tax Rate"
              stylized
            />
            <FliptInput
              onChange={(el, evt) => this._updateFields(el, evt, 'form')}
              value={percentage_sales_tax_basis_submitted}
              name="percentage_sales_tax_basis_submitted"
              label="Percentage Sales Tax Basis"
              stylized
            />
            <FliptInput
              onChange={(el, evt) => this._updateFields(el, evt, 'form')}
              value={patient_paid_amount_submitted}
              label="Patient Pay Amount Submitted"
              stylized
              name="patient_paid_amount_submitted"
            />
          </div>
        </div>
      </div>
    )
  }

  _renderCoordinationOfBenefits = () => {
    const {
      form,
    } = this.state

    const {
      other_coverage_code,
      other_payer_patient_responsibility_amount,
      other_payer_amount_paid,
    } = form

    return (
      <div className="section">
        <h2>Coordination of Benefits</h2>
        <div className="fields-container">
          <div className="fields">
            <FliptDropdown
              value={other_coverage_code}
              options={otherCoverageCodeOptions}
              label="Other Coverage Code"
              onChange={(el, evt) => this._updateFields(el, evt, 'form')}
              stylized
              name="other_coverage_code"
            />
            {/*“Other Payer Patient Responsibility Amount” and “Other Payer Amount Paid” cannot both be populated.*/}
            {/* If “Other Coverage Code” value is “08” then “Other Payer Patient Responsibility Amount” is required */}
            <FliptInput
              disabled={other_payer_patient_responsibility_amount || other_coverage_code === "08"}
              onChange={(el, evt) => this._updateFields(el, evt, 'form')}
              value={other_payer_amount_paid}
              label="Other Payer Amount Paid"
              stylized
              name="other_payer_amount_paid"
            />
          </div>
          <div className="fields">
            {/* If “Other Coverage Code” value is “02” then “Other Payer Amount Paid” is required */}
            <FliptInput
              disabled={other_payer_amount_paid || other_coverage_code === "02"}
              onChange={(el, evt) => this._updateFields(el, evt, 'form')}
              value={other_payer_patient_responsibility_amount}
              label="Other Payer Patient Responsibility Amount"
              stylized
              name="other_payer_patient_responsibility_amount"
            />
          </div>
        </div>
      </div>
    )
  }

  isManualDisable = () => {
    const { form,
      prescriptionForm,
      memberValidated,
      drugValidated,
      prescriberValidated,
      pharmacyValidated
    } = this.state

    const mandatoryFormKeys = ['ingredient_cost_submitted', 'gross_amount_due', 'other_coverage_code']
    const mandatoryPrescriptionFormKeys = ['prescription_reference_number', 'quantity', 'days_supply',
      'fill_number', 'compound_code', 'date_prescription_written', 'date_of_service']

    const isFormValid = mandatoryFormKeys.every((key) => form[key])
    const isPrescriptionFormValid = mandatoryPrescriptionFormKeys.every((key) => prescriptionForm[key])

    let isManualDisable = !(memberValidated && drugValidated && prescriberValidated
      && pharmacyValidated && isFormValid && isPrescriptionFormValid)

    if (!isManualDisable) {
      /* 1 .If “Other Coverage Code” value is “08” then “Other Payer Patient Responsibility Amount” is required
         2. If “Other Coverage Code” value is “02” then “Other Payer Amount Paid” is required */
      if (form.other_coverage_code === "08" && !form.other_payer_patient_responsibility_amount) {
        isManualDisable = true
      } else if (form.other_coverage_code === "02" && !form.other_payer_amount_paid) {
        isManualDisable = true
      } else {
        isManualDisable = false
      }
    }
    return isManualDisable
  }

  componentDidMount() {
    // clear reducers to prevent redux state reuse from test claim
    const { actions } = this.props
    actions.clearPharmacyLookupReducer()
    actions.clearPrescriberLookupReducer()
    actions.clearMemberLookupData()
    actions.clearDrugInfoBySearch()
    actions.clearManualClaimEntryNDC()
  }

  componentDidUpdate(prevProps) {
    const { props } = this
    const { memberModalOpen, pharmacyModalOpen, prescriberModalOpen,
      drugModalOpen } = this.state
    const { memberLookup, manualClaimEntry, drugLookup,
      prescriberLookupData, pharmacyLookupData, memberLookupFailed, ndcLookupFailed,
      pharmacyLookupFailed, prescriberLookupFailed } = props.state

    if (prevProps.state.memberLookup?.member_details !== memberLookup?.member_details &&
      memberLookup?.member_details?.member_id) {
      this.setState({ memberData: memberLookup.member_details, memberValidated: true })
    }
    if (prevProps.state.memberLookupFailed !== memberLookupFailed &&
      memberLookupFailed && !memberModalOpen) {
      this.setState({ memberModalOpen: true })
      this._findMember()
    }
    if (prevProps.state.ndcLookupFailed !== ndcLookupFailed && ndcLookupFailed
      && !drugModalOpen) {
      this.setState({ drugModalOpen: true })
      this._findDrug()
    }
    if (prevProps.state.pharmacyLookupFailed !== pharmacyLookupFailed &&
      pharmacyLookupFailed && !pharmacyModalOpen) {
      this.setState({ pharmacyModalOpen: true })
      this._findPharmacy()
    }
    if (prevProps.state.prescriberLookupFailed !== prescriberLookupFailed &&
      prescriberLookupFailed && !prescriberModalOpen) {
      this.setState({ prescriberModalOpen: true })
      this._findPrescriber()
    }
    if (prevProps.state.prescriberLookupData !== prescriberLookupData && prescriberLookupData.length) {
      this.setState({ prescriberData: prescriberLookupData[0], prescriberValidated: true })
    }

    if ((prevProps.state?.drugLookup !== drugLookup) ||
      prevProps.state?.manualClaimEntry !== manualClaimEntry) {
      this._updateOnComponent()
    }
    if (prevProps.state.pharmacyLookupData !== pharmacyLookupData && pharmacyLookupData.length) {
      this.setState({ pharmacyData: pharmacyLookupData[0], pharmacyValidated: true })
    }
  }

  _updateOnComponent() {
    const { state } = this.props
    const { searchBy } = this.state
    switch (searchBy) {
      case 'NDC': {
        if (state.manualClaimEntry?.ndc?.drug_name) {
          this.setState({ drugData: state.manualClaimEntry.ndc, drugValidated: true })
        }
      }
        break
    }
  }

  _searchData(buttonType) {
    const { actions } = this.props
    const { state: { searchForm, pharmacyValidated, prescriberValidated } } = this
    const header = 'Fields Missing'
    switch (buttonType) {
      case 'MEMBER_SEARCH':
        {
          this.setState({
            memberData: null, memberSearched: true,
            memberValidated: false, memberModalOpen: false
          })
          if (!searchForm.memberId) {
            this._findMember()
            return
          }
          let searchData = {}
          searchData = {
            member_identifier: 'member_id',
            id: searchForm.memberId,
            noDataClearing: true
          }
          actions.setMemberLookupFailure(false)
          actions.getMemberLookupData(searchData)
        }
        break
      case 'DRUG_SEARCH': {
        this._searchDrug()
      }
        break
      case 'PRESCRIBER_SEARCH': {
        this.setState({
          prescriberData: null,
          prescriberSearched: true, prescriberModalOpen: false
        })
        if (!searchForm.prescriberNpi && !prescriberValidated) {
          this._findPrescriber()
          return
        }
        let params = { prescriber_npi: searchForm.prescriberNpi }
        actions.setPrescriberLookupFailed(false)
        actions.getPrescriberLookupData(params)
      }
        break
      case 'PHARMACY_SEARCH': {
        this.setState({
          pharmacyData: null, pharmacySearched: true,
          pharmacyValidated: false, pharmacyModalOpen: false
        })
        if (!searchForm.pharmacyNpi && !pharmacyValidated) {
          this._findPharmacy()
          return
        }
        let form = {
          pharmacytype: 'RETAIL',
          networkStatus: 'ACTIVE',
          pharmacynpi: searchForm.pharmacyNpi
        }
        actions.setPharmacyLookupFailed(false)
        actions.getPharmacyLookupData({ form, isTestClaim: true })
      }
        break
    }
  }

  _searchDrug = () => {
    const { actions } = this.props
    const { searchForm } = this.state
    if (searchForm.ndc.length) {
      this.setState({ searchBy: 'NDC', drugData: null, drugSearched: true, drugModalOpen: false })
      actions.setNdcLookupFailed(false)
      actions.clearDrugInfoBySearch()
      actions.clearManualClaimEntryNDC()
      actions.fetchManualClaimEntryNDC({ ndc: searchForm.ndc, noDataClearing: true })
    } else {
      this.setState({
        drugData: null, drugSearched: true,
        drugValidated: false, drugModalOpen: false
      })
      this._findDrug()
    }
  }

  _updateSearchForm = (el, dropdown) => {
    const { searchForm } = this.state
    const tempSearchForm = cloneDeep(searchForm)
    const { name, value } = dropdown || el.currentTarget
    if (name == 'memberDob') this.setState({ memberData: null, memberSearched: false, memberValidated: false, memberModalOpen: false })
    if (name == 'memberId') this.setState({ memberData: null, memberSearched: false, memberValidated: false, memberModalOpen: false })
    if (name == 'ndc') this.setState({ drugData: null, drugSearched: false, drugValidated: false, drugModalOpen: false })
    if (name == 'prescriberNpi') this.setState({ prescriberData: null, prescriberSearched: false, prescriberValidated: false, prescriberModalOpen: false })
    if (name == 'pharmacyNpi') this.setState({ pharmacyData: null, pharmacySearched: false, pharmacyValidated: false, pharmacyModalOpen: false })
    tempSearchForm[name] = value
    this.setState({ searchForm: tempSearchForm, responseData: null })
  }

  _updatePrescriptionForm = (el, dropdown) => {
    const { prescriptionForm } = this.state
    const { name, value } = dropdown || el.currentTarget
    if (name === 'compound_code' && (value !== "1" && value !== "2" && value !== "")) {
      const header = 'Fields Missing'
      const message = 'Compond Code can be either 1 or 2'
      this._showTransitionalPortal(header, message)
      return
    }
    prescriptionForm[name] = value
    this.setState({ prescriptionForm, responseData: null })
  }

  _showTransitionalPortal = (header, message) => {
    const { props } = this
    const transitionalPortal = {
      header: header,
      copy: message,
    }
    props.actions.displayTransitionalPortal(transitionalPortal)
  }

  _validateClaimInfo = () => {
    const { state: { prescriptionForm, memberValidated, drugValidated, prescriberValidated,
      pharmacyValidated } } = this

    let header = 'Fields are missing'
    let message = ''
    let fieldName = ''

    if (!pharmacyValidated) fieldName = 'Pharmacy'
    if (!prescriberValidated) fieldName = 'Prescriber'
    if (!drugValidated) fieldName = 'Drug'
    if (!memberValidated) fieldName = 'Member'

    if (fieldName) {
      message = `Please validate ${fieldName} details to submit the claim`
      this._showTransitionalPortal(header, message)
      return false
    }

    const test = Object.values(prescriptionForm).every((value) => value !== '')
    if (!test) {
      message = `Please add all mandatory fields in Claim Information section.`
      this._showTransitionalPortal(header, message)
      return false
    }

    header = 'Invalid Field Type'
    message = 'must be a number'
    const decimalMessage = 'must be a decimal upto 3 places'

    const numberRegex = new RegExp('^[0-9]+$')
    const referenceNumberIsNumber = numberRegex.test(prescriptionForm.prescription_reference_number)
    if (!referenceNumberIsNumber) {
      message = `Rx Number ${message}`
      this._showTransitionalPortal(header, message)
      return false
    }
    if (prescriptionForm.quantity.includes('.')) {
      const decimalRegex = new RegExp('^[0-9]+(\.[0-9]{1,3})?$')
      const quantityIsDecimal = decimalRegex.test(prescriptionForm.quantity)
      if (!quantityIsDecimal) {
        message = `Quantity ${decimalMessage}`
        this._showTransitionalPortal(header, message)
        return false
      }
    } else {
      const quantityIsNumber = numberRegex.test(prescriptionForm.quantity)
      if (!quantityIsNumber) {
        message = `Quantity ${message}`
        this._showTransitionalPortal(header, message)
        return false
      }
    }
    const daysOfSupplyIsNumber = numberRegex.test(prescriptionForm.days_supply)
    if (!daysOfSupplyIsNumber) {
      message = `Days of supply ${message}`
      this._showTransitionalPortal(header, message)
      return false
    }
    return true
  }

  _cancelClaim = () => {
    this.setState({
      searchForm: {
        date_of_birth: '',
        date_of_birth: '',
        drug_name: '',
        drug_strength: '',
        gpi: '',
        memberId: '',
        ndc: '',
        prescriberNpi: '',
        pharmacyNpi: '',
        prescriber_first_name: '',
        prescriber_last_name: '',
        pharmacyState: '',
        pharmacyCity: '',
        pharmacyName: '',
        pharmacyzip1: ''
      },
      prescriptionForm: {
        prescription_reference_number: '',
        quantity: '',
        days_supply: '',
        fill_number: '',
        compound_code: '',
      },
      memberData: null,
      drugData: null,
      prescriberData: null,
      pharmacyData: null,
      memberValidated: false,
      drugValidated: false,
      pharmacyValidated: false,
      prescriberValidated: false,
      memberSearched: false,
      drugSearched: false,
      pharmacySearched: false,
      prescriberSearched: false,
      form: {
        other_coverage_code: otherCoverageCodeOptions[0].value,
        other_payer_patient_responsibility_amount: '',
        other_payer_amount_paid: '',
      },
    })
  }

  updateMemberData = (data) => {
    if (data?.member_id) {
      const { searchForm } = this.state
      const tempSearchForm = { ...searchForm, memberId: data.member_id }
      this.setState({
        memberData: data,
        searchForm: tempSearchForm,
        memberValidated: true,
      })
    }
    else this.setState({ memberData: data, memberValidated: false })
  }

  updateDrugData = (data) => {
    if (data?.ndc) {
      const { searchForm } = this.state
      const tempSearchForm = { ...searchForm, ndc: data.ndc }
      this.setState({
        drugData: data,
        searchForm: tempSearchForm,
        drugValidated: true,
      })
    }
    else this.setState({ drugData: data, drugValidated: false })
  }

  updatePharmacyData = (data) => {
    if (data?.pharmacynpi) {
      const { searchForm } = this.state
      const tempSearchForm = { ...searchForm, pharmacyNpi: data.pharmacynpi }
      this.setState({
        pharmacyData: data,
        searchForm: tempSearchForm,
        pharmacyValidated: true,
      })
    }
    else this.setState({ pharmacyData: data, pharmacyValidated: false })
  }

  updatePrescriberData = (data) => {
    if (data?.npi) {
      const { searchForm } = this.state
      const tempSearchForm = { ...searchForm, prescriberNpi: data.npi }
      this.setState({
        prescriberData: data,
        searchForm: tempSearchForm,
        prescriberValidated: true,
      })
    }
    else this.setState({ prescriberData: data, prescriberValidated: false })
  }

  _findMember = () => {
    const { actions } = this.props
    const { searchForm } = this.state
    actions.setModalComponent({
      modalProperties: { size: 'large' },
      contents: <FliptMemberSearch
        updateMemberData={(data) => this.updateMemberData(data)}
        searchForm={searchForm} />,
    })
    actions.toggleModal()
  }

  _findDrug = () => {
    const { actions } = this.props
    const { searchForm } = this.state
    actions.setModalComponent({
      modalProperties: { size: 'large' },
      contents: <FliptDrugSearch
        editMode={true}
        searchForm={searchForm}
        updateDrugData={(data) => this.updateDrugData(data)}
      />,
    })
    actions.toggleModal()
  }

  _findPharmacy = () => {
    const { actions } = this.props
    const { searchForm } = this.state
    actions.setModalComponent({
      modalProperties: { size: 'large' },
      contents: <FliptPharmacySearch
        updatePharmacyData={(data) => this.updatePharmacyData(data)}
        searchForm={searchForm}
        editMode={true}
      />,
    })
    actions.toggleModal()
  }

  _findPrescriber = () => {
    const { actions } = this.props
    const { searchForm } = this.state
    actions.setModalComponent({
      modalProperties: { size: 'large' },
      contents: <FliptPrescriberSearch
        editMode
        updatePrescriberData={(data) => this.updatePrescriberData(data)}
        searchForm={searchForm} />,
    })
    actions.toggleModal()
  }

  _clearFields = (type) => {
    const { searchForm } = this.state
    const tempForm = { ...searchForm }
    switch (type) {
      case 'MEMBER': {
        tempForm.memberId = ''
        this.setState({ searchForm: tempForm, memberData: null, memberValidated: false })
      }
        break
      case 'DRUG': {
        tempForm.ndc = ''
        this.setState({ searchForm: tempForm, drugData: null, drugValidated: false })
      }
        break
      case 'PHARMACY': {
        tempForm.pharmacyNpi = ''
        this.setState({ searchForm: tempForm, pharmacyData: null, pharmacyValidated: false })
      }
        break
      case 'PRESCRIBER': {
        tempForm.prescriberNpi = ''
        this.setState({ searchForm: tempForm, prescriberData: null, prescriberValidated: false })
      }
        break
    }
  }

  batchManualClaims = () => {
    const { actions, history } = this.props
    actions.setModalComponent({
      modalProperties: { size: 'large' },
      contents: <BatchManualClaims />,
    })
    actions.toggleModal()
  }

  render() {
    const { state: {
      memberLookupFailed,
      ndcLookupFailed,
      prescriberLookupFailed,
      pharmacyLookupFailed,
      user
    } } = this.props
    const {
      searchForm,
      prescriptionForm,
      memberSearched,
      memberValidated,
      pharmacySearched,
      pharmacyValidated,
      prescriberSearched,
      prescriberValidated,
      drugSearched,
      drugValidated,
      responseData,
    } = this.state

    return (
      <div id="manualClaimEntry">
        <BreadCrumb {...this.props} />
        <div className="header">Manual Claim Entry
          <FliptButton name='Batch Input' className='primary batch-input-button' onClick={() => this.batchManualClaims()} />
        </div>
        <div className="content">
          <div className='test-main-container'>
            <div className='test-claim-header'>Manual Claim Build</div>
            <div className="search-container">
              <div>
                <div className='step-header'>Step 1</div>
                <div className='mini-header'>Find Member</div>
                <div className='icon-container'>
                  <FliptPaInput value={searchForm.memberId} stylized name="memberId" onChange={this._updateSearchForm}
                    placeholder="Enter" label="Cardholder ID" />
                  <Icon name='cancel circle' onClick={() => { this._clearFields('MEMBER') }} className='margin-left' color='grey' />
                </div>
                <FliptButton name="Search" className="primary margin-top" onClick={() => this._searchData('MEMBER_SEARCH')} />
                {memberSearched && memberValidated ?
                  <div className='validated-text' >
                    <Icon name='check circle' color='green' />
                    Validated
                  </div> : null}
                {memberLookupFailed ? <div className='not-validated-text' >
                  <Icon name='cancel circle' color='red' />
                  Not Validated
                </div> : null}
              </div>
              <div className='search-separator' />
              <div>
                <div className='step-header'>Step 2</div>
                <div className='mini-header'>Find Drug</div>
                <div className='icon-container'>
                  <FliptPaInput value={searchForm.ndc} stylized name="ndc" onChange={this._updateSearchForm} placeholder="Enter" label="NDC" />
                  <Icon name='cancel circle' onClick={() => { this._clearFields('DRUG') }} className='margin-left' color='grey' />
                </div>
                <FliptButton name="Search" className="primary margin-top" onClick={() => this._searchData('DRUG_SEARCH')} />
                {drugSearched && drugValidated ?
                  <div className='validated-text' >
                    <Icon name='check circle' color='green' />
                    Validated
                  </div> : null}
                {ndcLookupFailed ? <div className='not-validated-text' >
                  <Icon name='cancel circle' color='red' />
                  Not Validated
                </div> : null}
              </div>
              <div className='search-separator' />
              <div>
                <div className='step-header'>Step 3</div>
                <div className='mini-header'>Find Prescriber</div>
                <div className='icon-container'>
                  <FliptPaInput value={searchForm.prescriberNpi} stylized name="prescriberNpi"
                    onChange={this._updateSearchForm} placeholder="Enter" label="NPI" />
                  <Icon name='cancel circle' onClick={() => { this._clearFields('PRESCRIBER') }} className='margin-left' color='grey' />
                </div>
                <FliptButton name="Search" className="primary margin-top" onClick={() => this._searchData('PRESCRIBER_SEARCH')} />
                {prescriberSearched && prescriberValidated ?
                  <div className='validated-text' >
                    <Icon name='check circle' color='green' />
                    Validated
                  </div> : null}
                {prescriberLookupFailed ? <div className='not-validated-text' >
                  <Icon name='cancel circle' color='red' />
                  Not Validated
                </div> : null}
              </div>
              <div className='search-separator' />
              <div>
                <div className='step-header'>Step 4</div>
                <div className='mini-header'> Find Pharmacy</div>
                <div className='icon-container'>
                  <FliptPaInput value={searchForm.pharmacyNpi} stylized name="pharmacyNpi"
                    onChange={this._updateSearchForm} placeholder="Enter" label="NPI" />
                  <Icon name='cancel circle' onClick={() => { this._clearFields('PHARMACY') }} className='margin-left' color='grey' />
                </div>
                <FliptButton name="Search" className="primary margin-top" onClick={() => this._searchData('PHARMACY_SEARCH')} />
                {pharmacySearched && pharmacyValidated ?
                  <div className='validated-text' >
                    <Icon name='check circle' color='green' />
                    Validated
                  </div> : null}
                {pharmacyLookupFailed ? <div className='not-validated-text' >
                  <Icon name='cancel circle' color='red' />
                  Not Validated
                </div> : null}
              </div>
            </div>
            <div className='horizontal-separator' />
            <div className="section-header">
              Claim Information
            </div>
            <div className="content shadow margin-bottom">
              <div className="fields-container">
                <div className='fields-header'>
                  <FliptPaInput value={prescriptionForm.days_supply} stylized name="days_supply" onChange={this._updatePrescriptionForm} placeholder="Enter" label="Days Supply" />
                </div>
                <div className='fields-header'>
                  <FliptPaInput value={prescriptionForm.quantity} stylized name="quantity" onChange={this._updatePrescriptionForm} placeholder="Enter" label="Drug Quantity" />
                </div>
                <div className='fields-header'>
                  <FliptPaDatePicker className="margin-right" labelText="Date Of Service" placeholder="MM/DD/YYYY" name="date_of_service" onChange={this._updatePrescriptionForm} value={prescriptionForm.date_of_service} stylized />
                </div>
                <div className='fields-header'>
                  <FliptPaDatePicker className="margin-right" labelText="Date Prescription Written" placeholder="MM/DD/YYYY" name="date_prescription_written" onChange={this._updatePrescriptionForm} value={prescriptionForm.date_prescription_written} stylized />
                </div>
              </div>
              <div className="fields-container">
                <div className='fields-header'>
                  <FliptPaInput value={prescriptionForm.prescription_reference_number} stylized name="prescription_reference_number" onChange={this._updatePrescriptionForm} placeholder="Enter" label="Rx Number" />
                </div>
                <div className='fields-header'>
                  <FliptPaInput value={prescriptionForm.fill_number} stylized name="fill_number" onChange={this._updatePrescriptionForm} placeholder="Enter" label="Fill Number" />
                </div>
                <div className='fields-header'>
                  <FliptPaInput value={prescriptionForm.compound_code} stylized name="compound_code" onChange={this._updatePrescriptionForm} placeholder="Enter" label="Compound Code" />
                </div>
                {/* placeholder to maintain 4 fields fields-container */}
                <div className='fields-header'>
                </div>
              </div>
            </div>
          </div>

          {this._renderPricingInformation()}
          {this._renderCoordinationOfBenefits()}

          <div className="buttons-container">
            <FliptButton
              className="primary"
              name="Submit For Approval"
              onClick={this._confirmForApproval}
              disabled={this.isManualDisable()}
            />
            <FliptButton name="CLEAR" className="cancelButton" onClick={this._cancelClaim} />
          </div>

        </div>
      </div >
    )
  }
}

const mapStateToProps = (state) => ({
  state: {
    memberLookup: state.memberLookup,
    memberLookupFailed: state.memberLookup.memberLookupFailed,
    manualClaimEntry: state.manualClaimEntry,
    ndcLookupFailed: state.manualClaimEntry.ndcLookupFailed,
    prescriberLookupData: state.prescriberLookup.prescriberLookupData,
    prescriberLookupFailed: state.prescriberLookup.prescriberLookupFailed,
    pharmacyLookupData: state.pharmacyLookup.pharmacyLookupData,
    pharmacyLookupFailed: state.pharmacyLookup.pharmacyLookupFailed,
    drugLookup: state.drugLookup,
    user: state.user,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...MemberLookupActions,
    ...AppActions,
    ...ManualClaimEntryActions,
    ...PrescriberLookupActions,
    ...PharmacyLookupActions,
    ...DrugLookupActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManualClaimEntry)
