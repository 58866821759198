import React, { useEffect, useMemo } from 'react'
import FliptGrid from '../../../../../components/fliptGrid'
import './styles.scss'

const PlanPicker = (props) => {
    const { selectedPlans, allPlans, cellRendererParams, onSelectionChange, isDisabled } = props

    const planGridRef = React.createRef()
    const defaultColDef = useMemo(() => ({
        resizable: true,
        floatingFilter: true,
        outerHeight: 120,
        menuTabs: ['filterMenuTab'],
    }), [])
	
	useEffect(() => {
		const uniqueIdentifiersToSelect = new Set(selectedPlans.map((row) => row.plan_id))
		const api = planGridRef.current.api
		
		api.forEachNode((node) => {
			const isSelected = uniqueIdentifiersToSelect.has(node.data.plan_id)
			node.setSelected(isSelected)
		})
	}, [selectedPlans, planGridRef, allPlans])

    const _onSelectionChanged = (newData) => {
        const selectedRows = newData.api.getSelectedRows().map((row) => ({ ...row, selected: true }))
        if (onSelectionChange) {
            onSelectionChange(selectedRows)
        }
    }

	const defaultHeaders = ['Select', 'plan_id', 'plan_name', 'plan_version', 'plan_year', 'status', 'account', 'group']
	const defaultCellRendererParams = {
		Select: {
			checkboxSelection: !isDisabled,
			width: '120px',
			filter: false,
		},
		plan_id: {
			header: 'Plan ID',
			filter: 'agMultiColumnFilter',
		},
		plan_name: {
			header: 'Plan Name',
			filter: 'agMultiColumnFilter',
		},
		plan_version: {
			header: 'Plan Version',
			filter: 'agMultiColumnFilter',
		},
		plan_year: {
			header: 'Plan Year',
			filter: 'agMultiColumnFilter',
		},
		status: {
			header: 'Status',
			filter: 'agMultiColumnFilter',
		},
		account: {
			header: 'Account',
			filter: 'agMultiColumnFilter',
		},
		group: {
			header: 'Group',
			filter: 'agMultiColumnFilter',
		},
	}

	function gridHeight() {
		const length = isDisabled ? selectedPlans?.length : allPlans?.length
		if (length < 10) return 'plan-picker-small'
		else if (length < 20) return 'plan-picker-medium'
		else return 'plan-picker-large'
	}

    return (
        <div id='plan-picker-container'>
            <div className={gridHeight()}>
                <FliptGrid
                    defaultColDef={defaultColDef}
                    agGridRef={planGridRef}
                    headers={[...defaultHeaders]}
                    data={isDisabled ? selectedPlans : allPlans}
                    rowSelection="multiple"
                    onSelectionChanged={(data) => _onSelectionChanged(data)}
                    cellRendererParams={{ ...defaultCellRendererParams, ...cellRendererParams }}
                    suppressRowClickSelection={isDisabled}
                />
            </div>
        </div>
    )
}

export default PlanPicker
