import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import FliptButton from "../../../../components/form/fliptButton"
import { Creators as AppActions } from '../../../../redux/reducers/app'

import './styles.scss'

class ApprovalModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      disableButton: false,
      paOverride:false,
      file:""
    }

    this.statusTypes = {
      APPROVED: 'Approve',
      REJECTED: 'Reject',
      PUBLISH_READY: 'Publish Ready',
    }
    
  }



  _submitRequest = () => {
    const {
      submitType, toggleModal, clickHandler, data,
    } = this.props
    const { note } = this.state

    clickHandler({
      ...data,
      note,
      status: submitType,
    })

    toggleModal()
  }

  _updateNote = (el) => {
   
    const val = el.currentTarget.value.trim()
    this.setState({disableButton:!!val || this.state.disableButton})
    this.props.action({"note":val})
    
  }

  updatePaOverride=()=>{
    
    this.props.action({"paOverride":!this.props.data.paOverride})
    this.setState({paOverride:!this.props.data.paOverride})
  }

  _handleDateChange=(el)=>{

    const val = el.currentTarget.value
    const now = new Date();
    const selectedDate = new Date(val);
    if(now < selectedDate){
      this.props.action({"pa_override_end_date":selectedDate})
    }else{
      const transitionalPortal = {
        header: 'Choose date',
       
      }
      actions.displayTransitionalPortal(transitionalPortal) 
      el.currentTarget.value=""
    }

  }

  _fileUpload=(ev)=>{
    
    this.setState({disableButton:this.state.disableButton || (ev.currentTarget.files.length >0)})
    this.props.action({"file":ev.currentTarget.files})
  
   
  }

   bodyOption = () => {
    const { data, submitType, toggleModal } = this.props
    const body =data.status == "regenerate" || data.status=="delete" || data.status=="pa pending rfi" ? "" : <div className='inputField'> <input type="file" name="pdf" className="pdf-upload" onChange={this._fileUpload} />
      {!!data.pa_override && data.pa_override === 'Y' && (
        <div className="pa-modal-date-section">
          <div className="pa-moda-date-selector">
            <label className="pa-moda-date-selector-text" >Override end date:</label>
            <input type="checkbox" name="pa_override" value={data.paOverride} onChange={this.updatePaOverride} />
          </div>
          <input id="pa-modal" type="date" placeholder="Select A Date" className="date-picker" disabled={!this.state.paOverride} onChange={this._handleDateChange} />
        </div>
      )}
      <div className="note-box">
        <div><label for="note">Note:</label></div>
        <div className="textarea-box">
          <textarea id="note" onChange={(evt) => { this._updateNote(evt) }}>
          </textarea>
        </div>
      </div></div>
    
    return body;
  }


  render() {

    
    const { data, submitType, toggleModal } = this.props
    const text = this.statusTypes[submitType] || ''
    const header = `Are you sure you want to ${data.module_name}?`
    return (
      <div id="approvalModal">
        <div className="content">
          <h1>{header}</h1>
        
        
      <div className='bodyContain'>
      {this.bodyOption()}
        </div> 
          <div>
            <FliptButton
              // disabled={ !(data.status == "regenerate" || data.status=="delete" || data.status=="pa pending rfi") || !this.state.disableButton }
              name="Confirm"
              onClick={() => this._submitRequest()}
              style={{ marginRight: '10px' }}
            />

            <FliptButton
              name="Cancel"
              onClick={toggleModal}
            />
          </div>
        </div>
      </div>
    )
  }
}


const mapStateToProps = (state) => {
  return{ state: {
     app: state.app,
   
   },}
 }

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(ApprovalModal)
