export const PARENT = {
	QL: 'ql',
	REBATE: 'rebate',
}

export const getParentDefaultFields = (parentType) => {
    switch (parentType) {
        case PARENT.QL:
            return { lookback: "", ql_subtype: "", ql_type: "", value: "" }
        case PARENT.REBATE:
            return { pos_rebate_factor: "", pos_rebate_type: "" }
        default:
            return {}
    }
}

export const getParentLabel = (parentType) => {
    switch (parentType) {
        case PARENT.QL:
            return "QL"
        case PARENT.REBATE:
            return "Rebate Condition"
        default:
            return "Qualifier"
    }
}

export const selectModelsAndQualifiersByParentType = (parentType) => {
    switch (parentType) {
        case PARENT.REBATE:
            return { 'claim': { 'days_supply': true }, "pharmacy": { 'pharmacytype': true } }
        default:
            return null
    }
}

export default {
    getParentDefaultFields,
    getParentLabel,
    selectModelsAndQualifiersByParentType,
}