import React, { useState } from 'react'
import FliptGrid from '../../../../../components/fliptGrid'
import BreadCrumb from '../../../../../components/breadCrumb'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Creators as AppActions } from '../../../../../redux/reducers/app'
import { Creators as MemberLookupActions } from '../../../../../redux/reducers/api/memberLookup'
import FliptButton from '../../../../../components/form/fliptButton'
import FliptPrescriberSearch from '../../../../../components/fliptPrescriberSearch'
import FliptPharmacySearch from '../../../../../components/fliptPharmacySearch'
import { DeleteRowRenderer } from '../../../../../components/fliptGrid/cellRenderers'
import { validate } from './data/validation'
import { InputTextEditor, DropdownEditor, DatePickerEditor, SearchEditor } from '../../../../../components/fliptGrid/cellEditors'
import rowCellInfo from './data/rowCellInfo'
import { parseQueryString } from '../../../../../utils/utilities'
import './styles.scss'
import { isEmpty } from 'lodash'
import moment from 'moment'

const newRestrictions = (props) => {
    const { state, history, autoSuggestionResultSelect } = props
    const { memberLookup = {} } = state
    const { member_details, restriction_details: { edits = [] } = {} } = memberLookup
    const { flipt_person_id: id } = parseQueryString(history.location.search)
    const [flipt_person_id] = useState(id)
    const [gridData, setGridData] = useState(edits)
    rowCellInfo.id_type.options = ['Prescriber', 'Pharmacy'].map((code, index) => ({
        key: index,
        text: code,
        value: code,
    }))

    const isOverlapping = () => {
        const dataCopy = [...gridData]
        dataCopy.sort(function (a, b) { return a.effective_start_date - b.effective_start_date; });
        for (let i = 0; i < dataCopy.length - 1; i++) {
            if (moment(dataCopy[i].effective_end_date).isSameOrAfter(moment(dataCopy[i + 1].effective_start_date))) {
                if (dataCopy[i].id === dataCopy[i + 1].id)
                    return true; // dates overlap
                return false
            }
        }
        return false; // no dates overlap
    }

    const newRow = (obj) => {
        const { member_id = '' } = member_details

        const currentDate = new Date().toISOString()
        return { ...obj, flipt_person_id, member_id, effective_start_date: currentDate, effective_end_date: currentDate }
    }
    const delRow = (rowIndex) => {
        const newGridData = [...(gridData || [])]
        newGridData.splice(rowIndex, 1)
        setGridData(newGridData)
    }
    const updatePharmacyData = (data) => {
        const newGridData = [...(gridData || [])]
        data.forEach(selection => {
            newGridData.push(newRow({
                id: selection.pharmacynpi,
                name: selection.pharmacyname,
                id_type: 'Pharmacy',
                chaincode: selection.chaincode,
                ndc: "",
                gpi: ""
            }))
        })
        setGridData(newGridData)
    }

    const handleChange = (e, dropdown, rowIndex) => {
        const newGridData = [...(gridData || [])]
        const { name, value } = dropdown || e.currentTarget
        newGridData[rowIndex][name] = value
        setGridData(newGridData)
    }

    const updatePrescriberData = (data) => {
        const newGridData = [...(gridData || [])]
        data.forEach(selection => {
            newGridData.push(newRow({
                id: selection.npi,
                name: selection?.first_name + ' ' + selection?.last_name,
                id_type: 'Prescriber',
                ndc: "",
                gpi: ""
            }))
        })
        setGridData(newGridData)
    }

    const findPharmacy = () => {
        const { actions } = props
        actions.setModalComponent({
            modalProperties: { size: 'large' },
            contents: <FliptPharmacySearch
                submitPharmacyData={(data) => updatePharmacyData(data)}
                searchForm={{}}
                editMode
            />,
        })
        actions.toggleModal()
    }

    const findPrescriber = () => {
        const { actions } = props
        actions.setModalComponent({
            modalProperties: { size: 'large' },
            contents: <FliptPrescriberSearch
                submitPrescriberData={(data) => updatePrescriberData(data)}
                searchForm={{}}
                editMode
            />,
        })
        actions.toggleModal()
    }
    const headers = ['action', 'id', 'name', 'id_type', 'edit_type', 'ndc', 'gpi', 'drug_name', 'effective_start_date', 'effective_end_date', 'chaincode', "res_id"]

    const cellRendererParams = {
        action: {
            cellRenderer: DeleteRowRenderer,
            state: {
                onClick: delRow,
            },
            width: 50,
        },
        id: {
            overrideHeader: 'Provider Id',
        },
        name: {
            overrideHeader: 'Provider Name',
        },
        id_type: {
            overrideHeader: 'Provider Type',
        },
        edit_type: {
            overrideHeader: 'Restriction Type',
        },
        ndc: {
            overrideHeader: 'NDC',
        },
        gpi: {
            overrideHeader: 'GPI',
        },
        chaincode: {
            overrideHeader: 'Chain Code',
        },
        res_id: {
            overrideHeader: 'Restriction ID',
        }
    }

    const cellEditorParams = {
        id: {
            cellEditor: InputTextEditor,
            onChange: handleChange,
        },
        name: {
            cellEditor: InputTextEditor,
            onChange: handleChange,
        },
        id_type: {
            cellEditor: DropdownEditor,
            onChange: handleChange,
        },
        edit_type: {
            cellEditor: DropdownEditor,
            onChange: handleChange,
        },
        ndc: {
            cellEditor: InputTextEditor,
            onChange: handleChange,
        },
        gpi: {
            cellEditor: InputTextEditor,
            onChange: handleChange,
        },
        chaincode: {
            cellEditor: InputTextEditor,
            onChange: handleChange,
        },
        effective_start_date: {
            cellEditor: DatePickerEditor,
            onChange: handleChange,
        },
        effective_end_date: {
            cellEditor: DatePickerEditor,
            onChange: handleChange,
        },
        res_id: {
            cellEditor: InputTextEditor,
            hidden: true
        }
    }

    const save = e => {
        const { actions } = props
        if (isEmpty(gridData))
            return
        const data = {
            edits: gridData,
            flipt_person_id: flipt_person_id || gridData[0]?.flipt_person_id,
        }
        if (isOverlapping()) {
            const transitionalPortal = {
                header: 'Save Failed',
                copy: 'Overlaping Restrictions',
            }
            actions.displayTransitionalPortal(transitionalPortal)
            return
        }
        if (validations(data)) {
            actions.saveMemberRestrictionsData(data)
        }
    }

    const validations = (payload) => {
        const { actions } = props
        const isValid = Object.keys(validate).every(key => {
            const error = validate[key](payload)
            if (error?.length) {
                const transitionalPortal = {
                    header: 'Validation Error',
                    copy: (Array.isArray(error) && error?.length && error[0]) || error
                }
                actions.displayTransitionalPortal(transitionalPortal)
                return false
            }
            return true
        })
        return isValid
    }
    return (
        <div id='newRestrictions' >
            <BreadCrumb {...props} />
            <div className='header'>
                <span className="item">Add New Restriction</span>
            </div>
            <div className='content'>
                <FliptGrid
                    headers={headers}
                    data={gridData}
                    cellRendererParams={cellRendererParams}
                    cellEditorParams={cellEditorParams}
                    rowCellInfo={rowCellInfo}
                />
            </div>
            <div className='buttonContainer'>
                <FliptButton
                    name="Find a Prescriber"
                    className="primary"
                    onClick={findPrescriber}
                />
                <FliptButton
                    name="Find a Pharmacy"
                    className="primary"
                    onClick={findPharmacy}
                />
            </div>
            <div className='buttonContainer'>
                <FliptButton
                    name="Save"
                    className="primary"
                    onClick={save}
                />
            </div>
        </div >
    )
}
const mapStateToProps = (state) => ({
    state: {
        app: state.app,
        memberLookup: state.memberLookup,
    },
})

const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...AppActions,
        ...MemberLookupActions,
    }

    return {
        actions: bindActionCreators(allActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(newRestrictions)