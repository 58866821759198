//drug-utilization-review
import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import BreadCrumb from "../../../../components/breadCrumb";
import FliptButton from "../../../../components/form/fliptButton";
import FliptDatePicker from "../../../../components/form/fliptPaDatepicker";
import FliptDropdown from "../../../../components/form/fliptPaDropdown";
import { validate, validateSystem } from "./data/validations";
import FliptInput from "../../../../components/form/fliptPaInput";
import { Creators as RebateActions } from "../../../../redux/reducers/api/rebateManagement";
import { Creators as contractManagementActions } from "../../../../redux/reducers/api/contractManagement";
import { Creators as AppActions } from "../../../../redux/reducers/app";
import { parseQueryString } from "../../../../utils/utilities";
import Config from "../../../../config";
import _, { isEmpty } from "lodash";
import "./styles.scss";
import moment from "moment";
import FliptGrid from "../../../../components/fliptGrid";
import rowCellInfo from "./data/rowCellInfo";
import {
  DropdownEditor,
  InputTextEditor
} from "../../../../components/fliptGrid/cellEditors";
import { DeleteRowRenderer } from "../../../../components/fliptGrid/cellRenderers";
import { rebateFactorType, daysOfWeek } from "./data/dropdownData";
import Info from "../../clientConfiguration/Info";

const newRebate = (props) => {
  const [data, setData] = useState({});
  const [gridData, setGridData] = useState([]);
  const gridRef = React.createRef();
  const [options, setOptions] = useState({});
  const { history = {}, state = {} } = props;
  const { location = {} } = history || {};
  const { editMode = false, create = false } = location?.state || {};
  const drugListOptions = (options?.drugListSummaryData || []).map((e) => ({
    key: e,
    text: e,
    value: e
  }));
  const contractListOptions = (options?.contractList || []).map((e) => ({
    key: e,
    text: e,
    value: e
  }));

  const onChange = (el, dropdown) => {
    const { name, value } = dropdown || el.currentTarget;
    if (name.includes("_date")) {
      setData({ ...data, [name]: moment(value).toISOString() });
      return;
    }
    setData({ ...data, [name]: value });
  };

  const addCondition = () => {
    const tempGridData = [...gridData];
    const obj = {
      drug_list: "",
      rebate_type: "",
      rebate_factor: "",
      pos_max_rebate_amt: ""
    };
    tempGridData.push(obj);
    setGridData(tempGridData);
    gridRef.current.api.refreshCells();
  };

  const deleteCondition = (rowIndex) => {
    const tempGridData = [...gridData];
    tempGridData.splice(rowIndex, 1);
    setGridData(tempGridData);
    gridRef?.current?.api?.refreshCells();
  };

  const validations = (payload) => {
    const { actions } = props;
    const error = validate(payload);
    const sysError =
      payload?.system_settings && validateSystem(payload.system_settings);
    if (error || sysError) {
      const transitionalPortal = {
        header: "Validation Error",
        copy: error || sysError
      };
      actions.displayTransitionalPortal(transitionalPortal);
      return false;
    }
    return true;
  };

  const submitForm = () => {
    const {
      actions,
      state: { user }
    } = props;
    // let drug_list =  (gridData || []).map((item) => item.drug_list);
    // editMode ? drug_list = drug_list[0] : drug_list
    const basePayload = {
      ...data,
      drug_lists: gridData,
      effective_start_date: data.effective_start_date
        ? moment(data.effective_start_date).startOf("day")
        : "",
      effective_end_date: data.effective_end_date
        ? moment(data.effective_start_date).startOf("day")
        : "",
      type: "brand_rebate",
      updated_by: user?.uuid
    };
    const { search } = location;
    let qs = { x_id: "create" };
    if (search) qs = parseQueryString(search);
    let payload =
      editMode && !create
        ? { x_id: qs.x_id, payload: basePayload }
        : basePayload;
    if (validations(payload.payload || payload)) {
      if (create && !editMode) {
        actions?.saveRebateData({ ...payload });
      } else {
        delete payload?.payload?.id;
        actions?.putRebateData({ ...payload });
      }
    }
  };

  const handleChange = (el, dropdown, rowIndex, gridApi) => {
    const { name, value } = dropdown || el.currentTarget;
    gridData[rowIndex][name] = value;
    setGridData(gridData);
    gridApi.refreshCells();
  };

  useEffect(() => {
    const { rebateData = {} } = props?.state || {};
    const { drug_lists } = rebateData || {};
    if (!isEmpty(drug_lists)) setGridData(drug_lists || []);
    setData({
      ...rebateData
    });
  }, [props?.state?.rebateData]);

  useEffect(() => {
    const { drugListSummaryData = {}, contractSummaryData = {} } =
      props.state || {};
    const contractDocNames = (contractSummaryData || []).map((e) => e.doc_name);
    if (!isEmpty(drugListSummaryData) || !isEmpty(contractDocNames))
      setOptions((options) => ({
        ...options,
        drugListSummaryData,
        contractList: contractDocNames
      }));
  }, [props?.state?.drugListSummaryData, props?.state?.contractSummaryData]);

  useEffect(() => {
    const { actions } = props;
    const { search } = location;

    let qs = { x_id: "create" };
    if (search) qs = parseQueryString(search);
    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    Promise.all([
      actions.getRebateData(qs),
      actions.getContractSummaryData(),
      actions.getDrugListSummaryData()
    ])
      .then(() => delay(Config.COMPONENT_DATA_LOAD_TIME))
      .catch((error) => console.error("Error fetching data:", error));
    return () => actions.clearRebateData();
  }, []);

  const headers = [
    "drug_list",
    "rebate_type",
    "rebate_factor",
    "pos_max_rebate_amt",
    "action"
  ];
  const cellEditorParams = {
    drug_list: {
      editable: true,
      cellEditor: DropdownEditor,
      onChange: handleChange,
      readOnly: !editMode && !create
    },
    rebate_factor: {
      editable: true,
      cellEditor: InputTextEditor,
      onChange: handleChange,
      disabled: !editMode && !create
    },
    rebate_type: {
      editable: true,
      cellEditor: DropdownEditor,
      onChange: handleChange,
      readOnly: !editMode && !create
    },
    pos_max_rebate_amt: {
      editable: true,
      cellEditor: InputTextEditor,
      onChange: handleChange,
      disabled: !editMode && !create
    }
  };

  const cellRendererParams = {
    action: {
      cellRenderer: DeleteRowRenderer,
      state: {
        onClick: deleteCondition
      },
      disabled: !editMode && !create,
      width: 95
    },
    drug_list: {
      overrideHeader: "Drug List"
    },
    rebate_factor: {
      overrideHeader: "Rebate Factor"
    },
    rebate_type: {
      overrideHeader: "Rebate Type"
    },
    pos_max_rebate_amt: {
      overrideHeader: "POS Maximum Rebate Amount"
    }
  };

  rowCellInfo.drug_list.options = drugListOptions;
  rowCellInfo.rebate_type.options = rebateFactorType;

  return (
    <div id="rebateManagement">
      <BreadCrumb {...props} />
      <div className="header">
        <h1>POS Rebate Management</h1>
      </div>
      <div className="content maxHeight">
        <div className="card">
          <h3 className="header1">Rebate Details</h3>
          <div className="outer-container">
            <div className="field-inner-container half">
              <FliptInput
                value={data?.rebate_name || ""}
                className="full-width"
                label="Rebate Name"
                name="rebate_name"
                disabled={!editMode && !create}
                onChange={onChange}
                placeholder=""
                stylized
              />
            </div>
            <div className="field-inner-container half">
              <FliptDropdown
                value={data?.claim_processor || ""}
                className="full-width"
                label="Contract"
                name="claim_processor"
                readOnly={!editMode && !create}
                onChange={(e, d) => onChange(e, d)}
                options={contractListOptions}
                placeholder=""
                stylized
              />
            </div>
          </div>
          <div className="outer-container">
            <div className="field-inner-container half">
              <FliptDatePicker
                value={
                  data?.effective_start_date
                    ? moment(data?.effective_start_date).toDate()
                    : ""
                }
                className="full-width"
                labelText="Effective Start Date"
                name="effective_start_date"
                onChange={(e, d) => onChange(e, d)}
                disabled={!editMode && !create}
                placeholder=""
                stylized
              />
            </div>
            <div className="field-inner-container half">
              <FliptDatePicker
                value={
                  data?.effective_end_date
                    ? moment(data?.effective_end_date).toDate()
                    : ""
                }
                className="full-width"
                labelText="Effective End Date"
                name="effective_end_date"
                onChange={(e, d) => onChange(e, d)}
                disabled={!editMode && !create}
                placeholder=""
                stylized
              />
            </div>
          </div>

          <div className="grid-container">
            <FliptGrid
              headers={headers}
              data={gridData}
              rowCellInfo={rowCellInfo}
              cellRendererParams={cellRendererParams}
              cellEditorParams={cellEditorParams}
              agGridRef={gridRef}
            />
          </div>
          {!editMode && create && (
            <div className="close-button add">
              <FliptButton
                className="invisible"
                name="+ Add"
                onClick={addCondition}
              />
            </div>
          )}

          <div className="devider" />
          {editMode || create ? (
            <div className="close-button">
              <FliptButton
                className="primary"
                compact
                name="Save"
                onClick={submitForm}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  state: {
    rebateData: state.rebateManagement.rebateData,
    user: state.user,
    drugListSummaryData: state.rebateManagement.drugListSummaryData,
    contractSummaryData: state.contractManagement.contractSummaryData
  }
});

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...contractManagementActions,
    ...AppActions,
    ...RebateActions
  };

  return {
    actions: bindActionCreators(allActions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(newRebate);
