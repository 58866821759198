import React, { PureComponent } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Creators as groupManagementActions } from '../../../../redux/reducers/api/groupManagement'

import './styles.scss'
import { Creators as AppActions } from '../../../../redux/reducers/app'
import FliptGrid from '../../../../components/fliptGrid';
import { LinkRenderer } from '../../../../components/fliptGrid/cellRenderers'
import moment from 'moment'
import BreadCrumb from '../../../../components/breadCrumb'
import GroupMgmtPopup from '../popupCellRenderer'

class GroupManagement extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            form: {
                start_date: '',
                end_date: ''
            }
        }
    }

    _updateForm = (el, dropdown) => {
        const { form } = this.state
        const { name, value } = dropdown || el.currentTarget
        form[name] = value
        this.setState({ form })
    }

    componentDidMount() {
        const { actions, location: { state }, state: { clientName } } = this.props
        if (state?.client_name) (actions.setClientName(state?.client_name))
        actions.getGroupMaintenanceDashboard({ client_name: clientName || state?.client_name })
    }


    createNewGroup = () => {
        this.props.history.push('/create-group', { editable: true })
    }

    render() {
        const { state: { groupMaintenance }, actions, location: { state } } = this.props
        const headers = ['action', 'group_id', 'group_name', 'group_effective_date', 'group_termination_date', 'active_ind']
        const cellRendererParams = {
            action: {
                cellRenderer: GroupMgmtPopup,
                searchArgs: {
                    updateGroupStatus: actions.createGroup,
                    path: '/create-group',
                    state: { client_name: state?.client_name }
                },
            },
            group_id: {
                overrideHeader: 'Group ID'
            }
        }

        return (
            <div id="group-maintenance">
                <div className='breadcrum-container'>
                    <BreadCrumb {...this.props} />
                </div>
                <div className='header'>
                    Group Maintenance
                    <span className='link-text' onClick={this.createNewGroup}>
                        + Add New Manual Group
                    </span>
                </div>
                <div className='devider' />
                <div className='grid-container'>
                    <FliptGrid
                        headers={headers}
                        cellRendererParams={cellRendererParams}
                        data={groupMaintenance || []}
                    />
                </div>
            </div >
        )
    }
}

const mapStateToProps = (state) => ({
    state: {
        app: state.app,
        groupMaintenance: state.groupManagement.groupMaintenance,
        clientName: state.groupManagement.clientName
    },
})

const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...AppActions,
        ...groupManagementActions
    }

    return {
        actions: bindActionCreators(allActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupManagement)
