import React, { Component } from 'react'
import moment from 'moment'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import './styles.scss'
import ApprovalModal from '../../../components/approvalOptions/approvalModal'
import BreadCrumb from '../../../components/breadCrumb'
import Config from '../../../config'
import FliptGrid from '../../../components/fliptGrid'
import SubNav from './subNav'
import increaseRowHeight from '../../../components/fliptGrid/cellClickEvents'
import { Creators as ClientImplementationActions } from '../../../redux/reducers/api/clientImplementation'
import { Creators as RPMActions } from '../../../redux/reducers/rpm'
import { LinkRenderer, LinkContainerRenderer, ColorCodedStatusRenderer, EllipsisRpmRenderer } from '../../../components/fliptGrid/cellRenderers'
import { calcGridRowHeight } from '../../../utils/utilities'
import { filterRowData, capitalizeStr } from '../../../utils/utilities'
import { last } from 'lodash'

const APPROVED_STATUS_STR = 'APPROVED'
const REJECTED_STATUS_STR = 'REJECTED'
const TEST_APPROVED_STATUS_STR = 'TEST_APPROVED'

class UserQueue extends Component {
  constructor(props) {
    super(props)

    this.state = {
      active: 'myQueue',
      validGrids: ['userQueue', 'myQueue', 'myImplementation', 'userImplementation'],
      gridHeaders: [ 'assignee', 'assignment_type', 'name', 'module', 'status', 'date_begin', 'date_due', 'submitted_by'],
      approvalHeaderMappings: {
        task_assignee: 'assignee',
        create_date: 'date_begin',
        due_date: 'date_due',
        module_name: 'name',
        object_type: 'module',
        created_by: 'submitted_by',
        task_status: 'status',
      },
      implementationHeaderMappings: {
        task_owner: 'owner',
        implementation_name: 'implementation_name',
        task_assignee: 'assignee',
        doc_name: 'name',
        task_status: 'status',
        task_begin_date: 'date_begin',
        submitted_by: 'last_updated_by',
        // last_updated_by: 'submitted_by',
        task_end_date: 'date_due',
      },
      agGridRef: React.createRef(),
    }
  }

  formatQueueData(data) {
    const moduleName = {
      'BENEFIT_PLAN_DOC': 'Benefit Plan Design',
      'FORMULARY': 'Formulary',
      'FORMULARY_TIER': 'Formulary Drug List',
      'POS_REBATE': 'Program Edits',
      'PRIOR_AUTHORIZATION': 'PA Drug List',
      'PROGRAM_LIST': 'Program Edits',
      'QUANTITY_LIMIT': 'Quantity Limits',
      'STEP_THERAPY': 'Step Therapy',
    }


    return data.map((d) => {
      d.last_updated_by = String(d.last_updated_by).split('-')[2]
      d.module = moduleName[d.module] || d.module
      d.create_date = moment(d.create_date).format("MM/DD/YYYY h:MM:SS A")
      d.due_date = moment(d.last_updated).add(2, 'd').format("MM/DD/YYYY h:MM:SS A")
      d.assignment_type = 'Peer Review & Approval'
      d.user =  (`${d?.user_data?.first_name || ''} ${d?.user_data?.last_name}`) ? `${d?.user_data?.first_name || ''} ${d?.user_data?.last_name}` : ''
      d.task_assignee = String(d.assigned_to).replace('FAST-FLIPTRX-', '').split('-').slice(0,-1).join('-')
      return d
    })
  }
  componentDidMount() {
    const { actions } = this.props
    //actions.rpmLoadApprovalsQueue()
    setTimeout(() => {
      actions.getAllImplementationTasks()
      actions.rpmLoadApprovalsQueue()
    }, Config.COMPONENT_DATA_LOAD_TIME)
  }

  _viewUrl = (module) => {
    const viewURlData = {
      FORMULARY: '/formulary',
      FORMULARY_TIER: '/formulary-tier',
      POS_REBATE: '/pos-rebate',
      PRIOR_AUTHORIZATION: '/prior-authorization',
      PROGRAM_LIST: '/program-list',
      QUANTITY_LIMIT: '/quantity-limit',
      STEP_THERAPY: '/step-therapy',
    }
    return viewURlData[module] || ''
  }

  _getRowHeight = (params) => calcGridRowHeight(params.data?.approver)

  _showApprovalModal = (submitType, data) => {
    const { actions } = this.props

    actions.setModalComponent({
      modalProperties: {
        size: 'large',
      },
      contents: this._generateModal(submitType, data),
    })
    actions.toggleModal()
  }

  _clickHandler = (data) => {
    const { actions } = this.props
    const { status } = data

    // don't want to send keys to the api that were added by 
    // client and don't belong in the document
    let requestData = { ...data }
    delete requestData.object_name
    delete requestData.object_type

    if (status === 'PUBLISH_READY') {
      actions.rpmSendForPublish(requestData)
    }

    if ([APPROVED_STATUS_STR, TEST_APPROVED_STATUS_STR, REJECTED_STATUS_STR].includes(status)) {
      actions.rpmApproveRejectDocument(requestData)
    }
  }

  _calculateTimeElapsed = (data) => {
    const today = moment(new Date())
    const submittedDate = moment(data.date_submitted)

    return `${today.diff(submittedDate, 'days')} days`
  }

  _generateModal = (submitType, data) => {
    const { actions } = this.props

    return (
      <ApprovalModal
        clickHandler={this._clickHandler}
        data={data}
        submitType={submitType}
        toggleModal={actions.toggleModal}
      />
    )
  }

  _setActiveGrid = (active) => {
    const { validGrids } = this.state

    if (validGrids.find((e) => e === active)) {
      this.setState({ active })
    }


    let gridOptions = {
      domLayout: "autoHeight",
      autoSizeStrategy: {
        type: "fitCellContents",
      },
    };
  }

  _cellRendererParams = () => {
    return {
      // action: {
      //   cellRenderer: EllipsisRpmRenderer,
      //   searchArgs: {
      //     showApprovalModal: this._showApprovalModal,
      //     viewPath: this._viewUrl,
      //     history,
      //   },
      //   onCellClicked: increaseRowHeight,
      // },
      status: {
        cellRenderer: ColorCodedStatusRenderer,
      },
      doc_id: {
        hide: true,
      },
    }
  }

  _allApprovalsQueue = () => {
    const { gridHeaders, approvalHeaderMappings } = this.state
    const { state: { docs } } = this.props
    const cellRendererParams = this._cellRendererParams
    const gridData = docs.documents.length ? this.formatQueueData(docs.documents).map((d) => ({
      ...filterRowData(d, gridHeaders, approvalHeaderMappings),
      module: d.module,
      module_name: d.module_name,
      assignee: d.task_assignee
    })) : []

    gridData.forEach((row) => {
      row.time_elapsed = this._calculateTimeElapsed(row)
    })

    return (
        <FliptGrid
          cellRendererParams={cellRendererParams}
          data={gridData}
          headers={gridHeaders}
          gridOptions={this.gridOptions}
          gridTheme={"ag-theme-balham content"}
        />
    )
  }

  _myApprovalsQueue = () => {
    const { gridHeaders, approvalHeaderMappings } = this.state
    const { docs, user: { first_name, last_name } } = this.props.state
    const cellRendererParams = this._cellRendererParams
    const nameSearch = (first_name && last_name) ? `${first_name[0]}${last_name}`.toLowerCase() : ''
    console.log(nameSearch)
    const gridData = docs.documents.length ? this.formatQueueData(docs.documents).reduce((acc, curr) => {
    const currentData = filterRowData(curr, gridHeaders, approvalHeaderMappings)
      if (currentData.assignee?.toLowerCase() === nameSearch) {
        acc.push(currentData)
      }
      return acc
    }, []) : []
    return (
      <FliptGrid
        cellRendererParams={cellRendererParams}
        data={gridData}
        headers={gridHeaders}
        gridOptions={this.gridOptions}
        gridTheme={"ag-theme-balham content"}
      />
    )
  }

  _myImplementationQueue = () => {
    const impGridHeaders = [ 'assignee', 'owner', 'name',  'implementation_name', 'module', 'status','assignment_type',  'date_begin', 'date_due', 'submitted_by']
    const { gridHeaders, implementationHeaderMappings } = this.state
    const { state: { allImplemenationTasks, user: { first_name, last_name } } } = this.props
    //const cellRendererParams = this._cellRendererParams
    const nameSearch = (first_name && last_name) ? `${first_name} ${last_name}`.toLowerCase() : ''
    const gridData = allImplemenationTasks.length ? allImplemenationTasks.reduce((acc, curr) => {
      const currentData = filterRowData(curr, impGridHeaders, implementationHeaderMappings)
      if ((currentData.owner?.toLowerCase() === nameSearch) || (currentData.assignee?.toLowerCase() === nameSearch)) {
        currentData.owner = capitalizeStr(currentData.owner.toLowerCase())
        currentData.assignee = capitalizeStr(currentData.assignee.toLowerCase())
        acc.push(currentData)
      }
      return acc
    }, []) : []

    return (
        <FliptGrid
          cellRendererParams={this.implementationCellRendererParams}
          data={gridData}
          headers={impGridHeaders}
          gridOptions={this.gridOptions}
          gridTheme={"ag-theme-balham content"}
        />
    )
  }

  _allImplementationsQueue = () => {
    const impGridHeaders = [ 'assignee', 'owner', 'name',  'implementation_name', 'module', 'status','assignment_type',  'date_begin', 'date_due', 'submitted_by']
    const { gridHeaders, implementationHeaderMappings } = this.state
    const { state: { allImplemenationTasks } } = this.props
    const cellRendererParams = this._cellRendererParams
    const gridData = allImplemenationTasks.length ? allImplemenationTasks.map((d) => ({
      ...filterRowData(d, impGridHeaders, implementationHeaderMappings),
      module: d.module,
      module_name: d.module_name,
      owner: capitalizeStr(d.task_owner.toLowerCase()),
      assignee: capitalizeStr(d.task_assignee.toLowerCase())

    })) : []
    gridData.forEach((row) => {
      row.time_elapsed = this._calculateTimeElapsed(row)
    })
    return (
      <FliptGrid
        cellRendererParams={cellRendererParams}
        data={gridData}
        headers={impGridHeaders}
        gridOptions={this.gridOptions}
        gridTheme={"ag-theme-balham content"}
      />
    )
  }


  render() {
    const { active } = this.state
    let grid = <></>

    if (active === 'myQueue') grid = this._myApprovalsQueue()
    if (active === 'userQueue') grid = this._allApprovalsQueue()
    if (active === 'myImplementation') grid = this._myImplementationQueue()
    if (active === 'userImplementation') grid = this._allImplementationsQueue()

    return (
      <div id="userQueue">
        <BreadCrumb {...this.props} />
        <div className="header">
          <div>
            <h1>User Queue</h1>
            <SubNav active={active} setActive={this._setActiveGrid} />
          </div>
        </div>

          {grid}

      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    state: {
      docs: state.rpm,
      allImplemenationTasks: state.clientImplementation.allImplementationTasks,
      itemsPerPage: state.clientImplementation.itemsPerPage,
      totalRows: state.clientImplementation.totalRows,
      user: state.user,
    }
  }
}

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...RPMActions,
    ...ClientImplementationActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserQueue)
